/* eslint-disable max-len */

// Mobile hide
// if it is image and it is wrapped then not applicable
// if it is image and any of its parents is wrapped, then not applicable
// if it is image and any of its children is not mobile hide, then not applicable

// Mobile down
// if it is image and it is wrapped then not applicable
// if it is image and any of its parents is wrapped, then not applicable
// if it is image and if it has children then not applicable

import Image from '../../../oneweb/Image/kind';
import type { ComponentsMap } from "../../../../redux/modules/children/workspace/flowTypes";
import type { RelationsMap } from "../../../Workspace/epics/relations/flowTypes";
import type { MobileEnablement } from "../../flowTypes";

const createRelIns = (componentsMap, relations) => {
    return Object.keys(componentsMap).reduce((acc, id) => {
        if (relations[id]) {
            acc[id] = relations[id].relIn.id;// eslint-disable-line
        }
        return acc;
    }, {});
};

const isAnyParentWrapped = (id, relIns, componentsMap) => {
    let cmpId = id;
    while (relIns[cmpId] && componentsMap[relIns[cmpId]]) {
        if (componentsMap[relIns[cmpId]].wrap) {
            return true;
        }
        cmpId = relIns[cmpId];
    }
    return false;
};

const convertRelInsToParentChildrenMap = relIns => {
    return Object.keys(relIns).reduce((acc, id) => {
        const parentId = relIns[id];
        if (acc[parentId]) {
            acc[parentId].push(id);// eslint-disable-line
        } else {
            acc[parentId] = [id];// eslint-disable-line
        }
        return acc;
    }, {});
};

export default (
    componentsMap: ComponentsMap,
    relations: RelationsMap,
    selectedComponentId: string
): MobileEnablement => {
    const
        { inTemplate, wrap, kind, onHover } = componentsMap[selectedComponentId];
    let result = {
        enableMobileDown: inTemplate,
        mobileDownDisableReason: inTemplate
            ? ''
            : 'msg: mobileOptions.componentInPage {Only components placed in the template can be moved to bottom}'
    };
    if (inTemplate && onHover) {
        result.enableMobileDown = false;
        result.mobileDownDisableReason =
            'msg: mobileOptions.hoverboxChildrenNoMobileDown {Components inside hover box cannot be moved to bottom.}';
        return result;
    }

    if (kind !== Image) {
        return result;
    }
    const relIns = createRelIns(componentsMap, relations);
    if (wrap || isAnyParentWrapped(selectedComponentId, relIns, componentsMap)) {
        return {
            enableMobileDown: false,
            mobileDownDisableReason:
                'msg: mobileOptions.imageInsideTextNoDown {Image wrapped inside text cannot be moved down in mobile view}'
        };
    }
    const parentChildrenMap = convertRelInsToParentChildrenMap(relIns);
    if (inTemplate && parentChildrenMap[selectedComponentId]) {
        result.enableMobileDown = false;
        result.mobileDownDisableReason =
            'msg: mobileOptions.childrenWithNoMobileDown {Try moving down components on top of this component first}';
    }
    return result;
};
