import React from 'react';

import { GoogleAdsSettingsTextWithSwitchView } from './GoogleAdsSettingsTextWithSwitchView';
import { BgColorSet, BodyTextSet, GoToDashboardActionButton, HeaderTextSet, IconClassesSet } from './constants';

type GoogleAdsSettingsAdRemovedProps = {
    isCampaignEnabled: boolean;
    handleCampaignStatusChange: (enabled: boolean) => void;
};

export const GoogleAdsSettingsAdRemoved = (props: GoogleAdsSettingsAdRemovedProps) => (
    <GoogleAdsSettingsTextWithSwitchView
        bgColor={BgColorSet.NonActive}
        header={{ iconClass: IconClassesSet.adRemoved, text: HeaderTextSet.adRemoved }}
        text={BodyTextSet.adRemoved}
        isCampaignEnabled={props.isCampaignEnabled}
        handleCampaignStatusChange={props.handleCampaignStatusChange}
        actionButton={GoToDashboardActionButton}
    />
);
