/**
 * Inspired by jQueryUI.position(), this utility provides almost similar functionality.
 *
 * Read more at - http://api.jqueryui.com/position/
 *
 * Input parameters
 *      my: {
 *          position: <What point of the element should be picked for positioning>
 *          dimension: <Object containing width, height of "my" element, in pixels>
 *      },
 *      at: {
 *          position: <What point of the element should be picked for positioning>
 *          bbox: BBox of the "at" element
 *      },
 *      within: <BBox for container element>
 *      collision: <Strategy to handle if collision occurs with container element>
 *
 *
 *                             POSITIONS MAPPING
 *
 *     [ TopLeft ]***************[ TopCenter ]**************[ TopRight ]
 *            *                        .                        *
 *            *                        .                        *
 *            *                        .                        *
 *            *                        .                        *
 *     [ LeftCenter ]..........[ CenterCenter ]..........[ RightCenter ]
 *            *                        .                        *
 *            *                        .                        *
 *            *                        .                        *
 *            *                        .                        *
 *     [ BottomLeft ]**********[ BottomCenter ]**********[ BottomRight ]
 *
 *
 * Based on the positions specified for "my" and "at", "my" will be placed over "at"
 *
 */

import type { Position } from "../redux/modules/flowTypes";
import type { BBox, Dimensions } from "../redux/modules/children/workspace/flowTypes";

export const Positions = {
    TopLeft: 'top left',
    Top: 'top',
    TopCenter: 'top center',
    TopRight: 'top right',
    Left: 'left',
    LeftCenter: 'left center',
    Center: 'center',
    CenterCenter: 'center center',
    Right: 'right',
    RightCenter: 'right center',
    BottomLeft: 'bottom left',
    Bottom: 'bottom',
    BottomCenter: 'bottom center',
    BottomRight: 'bottom right'
};

export const CollisionAlignment = {
    Flip: 'flip',
    Fit: 'fit',
    FlipFit: 'flipfit',
    Intelligent: 'intelligent',
    None: 'none'
};

type MyPropTypes = {
    position: string,
    dimension: Dimensions
};

type AtPropTypes = {
    position: string,
    bbox: BBox
};

export default (my: MyPropTypes, at: AtPropTypes, within: BBox, collision: string) => {
    const
        { position: myPosition, dimension: myDimension } = my,
        { position: atPosition, bbox: atBBox } = at,

        atPoint = getReferencePointForAt(atPosition, atBBox),
        myPoint = getReferencePointForMy(myPosition, myDimension, atPoint),
        finalPoint = getFinalPoint(atPoint, myPoint, myDimension, collision, within);

    return finalPoint;
};

function getFinalPoint({ x: atX, y: atY }, { x: myX, y: myY }, { width, height }, collision, within) {
    const newPosition = { x: myX, y: myY };

    if (within) {
        const { top, right, bottom, left } = within;
        switch (collision) {
            case CollisionAlignment.Flip:
                newPosition.x = myX + width > right ? (myX - width) : myX;
                newPosition.y = myY + height > bottom ? (myY - height) : myY;
                break;
            case CollisionAlignment.Fit:
                if (myX < 0) {
                    newPosition.x = 0;
                } else if (myX + width > right) {
                    newPosition.x = right - width;
                }
                newPosition.y = myY + height > bottom ? (bottom - height) : myY;
                break;
            case CollisionAlignment.FlipFit:
                // flip
                newPosition.x = myX + width > right ? (myX - width) : myX;
                newPosition.y = myY + height > bottom ? (myY - height) : myY;
                // fit
                newPosition.x = newPosition.x < left ? left : newPosition.x;
                newPosition.y = newPosition.y < top ? top : newPosition.y;
                break;
            case CollisionAlignment.Intelligent:
                if (myX < 0) {
                    newPosition.x = 0;
                } else if (atX >= myX && atX <= (myX + width)) {
                    if (atY >= myY && (myY + height) > bottom) {
                        newPosition.x = atX;
                    }
                    if (atX + width > right) {
                        newPosition.x = atX - width;
                    }
                }
                if (myY < 0) {
                    newPosition.y = 0;
                } else if (myY + height > bottom) {
                    newPosition.y = (bottom - height);
                }
                break;
            case CollisionAlignment.None:
            default:
                break;
        }
    }

    return newPosition;
}

function getReferencePointForMy(position: string, { height, width }, { x, y }: Position): Position {
    switch (position) {
        case Positions.Top:
        case Positions.TopCenter:
            return { x: (x - Math.floor(width / 2)), y };
        case Positions.TopRight:
            return { x: (x - width), y };
        case Positions.Left:
        case Positions.LeftCenter:
            return { x, y: (y - Math.floor(height / 2)) };
        case Positions.Center:
        case Positions.CenterCenter:
            return { x: (x - Math.floor(width / 2)), y: (y - Math.floor(height / 2)) };
        case Positions.Right:
        case Positions.RightCenter:
            return { x: (x - width), y: (y - Math.floor(height / 2)) };
        case Positions.BottomLeft:
            return { x, y: y - height };
        case Positions.Bottom:
        case Positions.BottomCenter:
            return { x: x - Math.floor(width / 2), y: y - height };
        case Positions.BottomRight:
            return { x: x - width, y: y - height };
        case Positions.TopLeft:
        default:
            return { x, y };
    }
}

function getReferencePointForAt(position: string, { top, right, bottom, left }: BBox): Position {
    switch (position) {
        case Positions.Top:
        case Positions.TopCenter:
            return { x: Math.floor((left + right) / 2), y: top };
        case Positions.TopRight:
            return { x: right, y: top };
        case Positions.Left:
        case Positions.LeftCenter:
            return { x: left, y: Math.floor((top + bottom) / 2) };
        case Positions.Center:
        case Positions.CenterCenter:
            return { x: Math.floor((left + right) / 2), y: Math.floor((top + bottom) / 2) };
        case Positions.Right:
        case Positions.RightCenter:
            return { x: right, y: Math.floor((top + bottom) / 2) };
        case Positions.BottomLeft:
            return { x: left, y: bottom };
        case Positions.Bottom:
        case Positions.BottomCenter:
            return { x: Math.floor((left + right) / 2), y: bottom };
        case Positions.BottomRight:
            return { x: right, y: bottom };
        case Positions.TopLeft:
        default:
            return { x: left, y: top };
    }
}
