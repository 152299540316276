import cx from 'classnames';
import ctaFactory from './ctaFactory';
import appStyles from '../../../App.css';
import styles from './ComponentMainActions.css';
import type { CTAViewParams } from './flowTypes';
import type { AnyComponent } from '../../../../redux/modules/children/workspace/flowTypes';

export default (params: CTAViewParams<AnyComponent>) => ctaFactory({
    className:
        cx(
            appStyles.linkButtonText,
            styles.primaryToolBar,
            params.urgent ? styles.urgentButton : styles.ctaButton,
            { [styles.primaryButton]: params.showPrimaryButton }
        ),
    buttonText: params.ctaButtonText,
    onClickActionType: params.onCTAClickActionType,
    onClick: params.ctaOnClick,
    ...params
});
