import 'photoeditorsdk/js/PhotoEditorSDK';
import 'photoeditorsdk/css/PhotoEditorSDK.UI.DesktopUI.css';
import PhotoEditorUI from 'photoeditorsdk/desktop-ui';

interface PhotoEditorWSBInterface {
    export: () => Promise<any>;
    serialize: ({ image }: { image: boolean }) => Promise<any>;
    deserialize: (data: Object) => Promise<any>;
    dispose: () => Promise<any>;
    on: (e: string, cb: Function) => void;
}
class ImageEditor {
    editor: PhotoEditorWSBInterface;
    constructor(config: Object) {
        this.editor = new PhotoEditorUI(config);
    }

    export() {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                this.editor.export()
                    .then(resolve)
                    .catch(reject);
            });
            setTimeout(reject, 180000);
        });
    }

    serialize({ image }: { image: boolean }) {
        return this.editor.serialize({ image });
    }

    deserialize(serializeData: any) {
        if (!serializeData) return Promise.resolve();
        return this.editor.deserialize(serializeData);
    }

    dispose() {
        this.editor.dispose();
    }

    onReady(cb: Function) {
        this.editor.on(PhotoEditorUI.Events.EDITOR_READY, cb);
    }

    onOperationUpdate(cb: Function) {
        this.editor.on(PhotoEditorUI.Events.OPERATION_UPDATED, cb);
    }

    onUndo(cb: Function) {
        this.editor.on(PhotoEditorUI.Events.HISTORY_UNDO, cb);
    }

    onRedo(cb: Function) {
        this.editor.on(PhotoEditorUI.Events.HISTORY_REDO, cb);
    }
}

export default ImageEditor;
