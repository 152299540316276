import React from "react";
import * as R from 'ramda';
import cx from 'classnames';
import { injectIntl } from '../../../view/intl/index';
import Combobox from "../../../view/common/Combobox/index";
import { dispatchForwardToSelectedComponent } from '../../../redux/forwardTo';
import ButtonGlobalstyleKind from '../../oneweb/Button/globalStyle/kind';
import LinkGlobalstyleKind from '../../oneweb/Link/globalStyle/kind';
import MenuGlobalstyleKind from '../../oneweb/Menu/globalStyle/kind';
import TextGlobalstyleKind from '../../oneweb/Text/globalStyle/kind';
import TableGlobalstyleKind from '../../oneweb/Table/globalStyle/kind';
import getGlobalstyleClassName from '../../RenderGlobalstyles/getGlobalstyleClassName';
import { openGlobalStylesDialogAC } from '../GlobalstyleDialogInvoker/index';
import { GLOBAL_STYLE_SELECTED } from './actionTypes';
import type { GlobalstyleSelectorPropTypes } from './flowTypes';
import mctaComboboxStyles from '../../componentMainActions/MctaCombobox/MctaCombobox.css';
import appStyles from '../../../view/App.css';

const
    EDIT_GLOBALSTYLE = 'EDIT_GLOBALSTYLE',
    EditGlobalstyleLabels = {
        [ButtonGlobalstyleKind]: 'msg: gs.button.editStyle {Edit button styles}',
        [LinkGlobalstyleKind]: 'msg: gs.link.editStyle {Edit link styles}',
        [MenuGlobalstyleKind]: 'msg: gs.menu.editStyle {Edit menu styles}',
        [TextGlobalstyleKind]: 'msg: gs.text.editStyle {Edit text styles}',
        [TableGlobalstyleKind]: 'msg: gs.table.editStyle {Edit table styles}'
    },
    getEditGlobalstyleLabels = (kind, intl) => intl.msgJoint(EditGlobalstyleLabels[kind]),
    getCssClassname = (main, sub) => getGlobalstyleClassName(main) + '-' + sub.toLowerCase().replace(' ', '-'),
    renderLinkOption = label => (
        <div className={cx(appStyles.linkButtonText, mctaComboboxStyles.linkCombo, mctaComboboxStyles.linkDiv)}>
            <div className={mctaComboboxStyles.linkLabel}>{label}</div>
        </div>
    ),
    renderLabelOption = (value, label) => (<div className={getCssClassname(value, label)}>{label}</div>),
    sortGlobalstyles = (opt1, opt2) => {
        const
            match1 = opt1.label.toString().match(/(\d+)$/), //NOSONAR
            match2 = opt2.label.toString().match(/(\d+)$/), //NOSONAR
            idx1: number = match1 ? parseInt(match1[0], 10) : 0,
            idx2: number = match2 ? parseInt(match2[0], 10) : 0;

        if (idx1 < idx2) {
            return -1;
        } else if (idx1 > idx2) {
            return 1;
        }
        return 0;
    };

export default injectIntl(({
    selected,
    selectedKind,
    options,
    onChangeAction,
    onSelectAction,
    style,
    optionRenderer,
    disabled = false,
    dispatch,
    mcta,
    forwardToSelectedComponent,
    showEditLink,
    menuStyle,
    dropDownClassName,
    className,
    intl,
    colorThemeOnChangeAction,
    currentStylesheetId
}: GlobalstyleSelectorPropTypes) => {
    const
        forwardedDispatch = forwardToSelectedComponent ? dispatchForwardToSelectedComponent(dispatch) : dispatch,
        sortedOptions = R.sort(sortGlobalstyles, options),
        optRenderer = ({ value, label, name, link }) => {
            if (link) {
                return renderLinkOption(label);
            } else if (optionRenderer) {
                return optionRenderer(value, label, name);
            }
            return renderLabelOption(value, label);
        };

    if (showEditLink) {
        sortedOptions.push({
            value: EDIT_GLOBALSTYLE,
            label: getEditGlobalstyleLabels(selectedKind, intl),
            link: true
        });
    }

    const makeStyleSelectedHandler = action => ({ value: stylesheetId, name: stylesheetName }) => {
        if (stylesheetId === EDIT_GLOBALSTYLE) {
            dispatch(openGlobalStylesDialogAC(currentStylesheetId || selected, selectedKind as string));
        } else if (colorThemeOnChangeAction) {
            colorThemeOnChangeAction(stylesheetId);
        } else {
            forwardedDispatch({
                type: GLOBAL_STYLE_SELECTED, payload: { onChangeAction: action, stylesheetId, stylesheetName }
            });
        }
    };

    return (
        <div {...(mcta ? { className: mctaComboboxStyles.mctaCombobox } : {})}>
            <Combobox
                value={selected}
                searchable={false}
                disabled={disabled}
                style={{ width: 200, ...style }}
                menuStyle={menuStyle}
                optionRenderer={optRenderer}
                options={sortedOptions}
                onSelect={onSelectAction ? makeStyleSelectedHandler(onSelectAction) : undefined}
                onChange={onChangeAction ? makeStyleSelectedHandler(onChangeAction) : undefined}
                placeholder={intl.msgJoint('msg: common.select.placeholder {Select...}')}
                // @ts-ignore mostly we can remove this intl
                intl={intl}
                dropDownClassName={dropDownClassName}
                className={className}
            />
        </div>
    );
});
