import * as R from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import * as kinds from './kind';
import {
    BACK_TO_EDITOR,
    INPUT_BLUR,
    INPUT_FOCUSED,
    MOUSE_UP_ON_PREVIEW_BUTTON,
    WINDOW_MOUSE_UP
} from "../../actionTypes";
import { dialogId as backupIntroDialogId } from "../../../Backup/dialogs/intro";
import {
    EditModeComponentIdSelector,
    ReceiveOnlyComponentsMap
} from "../../../Workspace/epics/componentsEval/selectorActionTypes";
import { TextComponentKind } from "../../../oneweb/Text/kind";
import { COMBO_BOX_OPEN, COMBO_BOX_CLOSE } from '../../../../view/common/Combobox/actionTypes';
import { OpenedDialogsIdsSelector } from "../../../DialogManager/epic/selectorActionTypes";
import { MobileViewEditorVisible } from "../../../MobileViewEditor/epics/reorder/selectorActionTypes";
import { TableCellIsInEditModeSelector } from "../../../oneweb/Table/epics/tableEditMode/selectorActionTypes";
import { TableComponentKind } from "../../../oneweb/Table/kind";
import { IsPreviewVisibleSelector } from "../../../Preview/epics/preview/selectorActionTypes";
import { BACKUP_CLICKED, BACKUP_SET_PREVIEW_INTERFACE } from "../../../Backup/actionTypes";
import { IgnoreDialogIds } from '../../../DialogManager/constants';

type BaseState = {
    comboBoxOpen: boolean;
    inputInFocus: boolean;
};

type Workspace = BaseState & {
    kind: typeof kinds.WORKSPACE;
    data: null;
};

type Dialog = BaseState & {
    kind: typeof kinds.DIALOG;
    data: {
        dialogId: string;
    };
};

type Preview = BaseState & {
    kind: typeof kinds.PREVIEW;
};

type EditText = BaseState & {
    kind: typeof kinds.EDIT_TEXT_COMPONENT;
};

type EditTable = BaseState & {
    kind: typeof kinds.EDIT_TABLE_COMPONENT;
};

type EditTableCell = BaseState & {
    kind: typeof kinds.EDIT_TABLE_COMPONENT_CELL;
};

type BackUp = BaseState & {
    kind: typeof kinds.BACKUP;
};

type State = Workspace | Dialog | Preview | EditText | EditTableCell | EditTable | BackUp

const
    nonDialogTransformation = kind => ({
        kind: () => kind,
        data: () => null,
        comboBoxOpen: R.F,
        inputInFocus: R.F
    }),
    toWorkspaceTransformation = nonDialogTransformation(kinds.WORKSPACE),
    toMobileEditorTransformation = nonDialogTransformation(kinds.MOBILE_EDITOR),
    toPreviewTransformation = nonDialogTransformation(kinds.PREVIEW),
    toEditTextComponentTransformation = nonDialogTransformation(kinds.EDIT_TEXT_COMPONENT),
    toEditTableComponentTransformation = nonDialogTransformation(kinds.EDIT_TABLE_COMPONENT),
    toEditTableComponentTransformationCell = nonDialogTransformation(kinds.EDIT_TABLE_COMPONENT_CELL),
    toBackUpTransformation = nonDialogTransformation(kinds.BACKUP),
    toPreBackUpTransformation = nonDialogTransformation(kinds.PRE_BACKUP),
    transformToWorkspace = R.evolve(toWorkspaceTransformation),
    transformToMobileEditor = R.evolve(toMobileEditorTransformation),
    transformToEditTextComponent = R.evolve(toEditTextComponentTransformation),
    transformToEditTableComponent = R.evolve(toEditTableComponentTransformation),
    transformToEditTableComponentCell = R.evolve(toEditTableComponentTransformationCell),
    transformToPreview = R.evolve(toPreviewTransformation),
    transformToBackup = R.evolve(toBackUpTransformation),
    transformToPreBackup = R.evolve(toPreBackUpTransformation),
    setInputInFocus = R.assoc('inputInFocus');

const
    defaultState: State = {
        kind: kinds.WORKSPACE,
        data: null,
        comboBoxOpen: false,
        inputInFocus: false
    },
    isBackupIntroDialog = (userFocusState: State) => {
        return (userFocusState.kind === kinds.DIALOG && userFocusState.data.dialogId === backupIntroDialogId);
    },
    isAnyDialogOnFocus = (userFocusState: State) => userFocusState.kind === kinds.DIALOG,
    epic = makeEpic({
        defaultState,
        valueActionType,
        updaters: [
            {
                conditions: [MOUSE_UP_ON_PREVIEW_BUTTON],
                reducer: ({ state }) => ({
                    state: transformToPreview(state)
                })
            },
            {
                conditions: [BACK_TO_EDITOR],
                reducer: ({ state }) => ({
                    state: transformToWorkspace(state)
                })
            },
            {
                conditions: [COMBO_BOX_OPEN],
                reducer: ({ state }) => {
                    return {
                        state: { ...state, comboBoxOpen: true }
                    };
                }
            },
            // react-select does not fire always and stopPropagation not working for which we need to use Portals
            ...[COMBO_BOX_CLOSE, WINDOW_MOUSE_UP].map(action => ({
                conditions: [action],
                reducer: ({ state }) => {
                    if (!state.comboBoxOpen) {
                        return { state };
                    }
                    return {
                        state: { ...state, comboBoxOpen: false }
                    };
                }
            })),
            {
                conditions: [
                    ReceiveOnlyComponentsMap,
                    EditModeComponentIdSelector,
                    OpenedDialogsIdsSelector,
                    TableCellIsInEditModeSelector,
                    MobileViewEditorVisible,
                    IsPreviewVisibleSelector
                ],
                reducer: ({
                    state, values: [
                        componentsMap,
                        editModeComponentId,
                        _openedDialogIds,
                        tableCellIsInEditMode,
                        mobileEditorFocussed,
                        isPreviewVisible
                    ]
                }) => {
                    const openedDialogIds = _openedDialogIds.filter(x => !IgnoreDialogIds.includes(x));
                    if (openedDialogIds.length === 0) {
                        if (editModeComponentId !== null) {
                            const componentInEditModeKind = componentsMap[editModeComponentId].kind;

                            if (componentInEditModeKind === TextComponentKind) {
                                return { state: transformToEditTextComponent(state) };
                            } else if (componentInEditModeKind === TableComponentKind) {
                                if (tableCellIsInEditMode) {
                                    return { state: transformToEditTableComponentCell(state) };
                                }

                                return { state: transformToEditTableComponent(state) };
                            } else {
                                return { state: transformToWorkspace(state) };
                            }
                        } else if (isBackupIntroDialog(state)) {
                            return { state: transformToBackup(state) };
                        } else if (state.kind !== kinds.WORKSPACE && !mobileEditorFocussed && !isPreviewVisible) {
                            return { state: transformToWorkspace(state) };
                        } else if (isPreviewVisible) {
                            return { state: transformToPreview(state) };
                        } else if (mobileEditorFocussed) {
                            return { state: transformToMobileEditor(state) };
                        }
                        return ({ state });
                    } else {
                        return {
                            state: R.evolve({
                                kind: () => kinds.DIALOG,
                                data: () => ({ dialogId: R.last(openedDialogIds) }),
                                comboBoxOpen: R.F
                            })(state)
                        };
                    }
                }
            },
            {
                conditions: [INPUT_FOCUSED],
                reducer: ({ state }) => {
                    return {
                        state: setInputInFocus(true, state)
                    };
                }
            },
            {
                conditions: [INPUT_BLUR],
                reducer: ({ state }) => {
                    return {
                        state: setInputInFocus(false, state)
                    };
                }
            },
            {
                conditions: [MobileViewEditorVisible],
                reducer: ({ values: [show], state }) => {
                    return {
                        state: show ? transformToMobileEditor(state) : transformToWorkspace(state)
                    };
                }
            },
            {
                conditions: [BACKUP_SET_PREVIEW_INTERFACE],
                reducer: ({ state }) => {
                    if (isBackupIntroDialog(state)) {
                        return { state };
                    } else {
                        return { state: transformToBackup(state) };
                    }
                }
            },
            {
                conditions: [BACKUP_CLICKED],
                reducer: ({ state }) => {
                    return { state: transformToPreBackup(state) };
                }
            }
        ]
    });

export {
    epic as default,
    defaultState,
    isBackupIntroDialog,
    isAnyDialogOnFocus
};
