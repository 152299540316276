import React from "react";
import cx from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { ColoredSwatch } from "../../../ColorPicker/view/ColoredSwatch";
import * as styles from "../../AutoColorLeftPanel.css";
import ICONS from "../../../../view/Icons/index";
import type { Color } from "../../../../mappers/flowTypes";
import { ThemeHistoryIcon } from "../../ThemeHistory/ThemeHistoryView";
import { isNextGenPreviewSelector } from "../../../TemplateSelector_DEPRECATED/epics/preview/selector";
import { dynamicPreviewSectionsLoadingSelector } from "../../../Onboarding/Dynamic/Epic/selectors";

type CurrentThemeColorsProps = {
    themeColors: Array<Color>;
    turnOnEditModeActionType: string;
};

export const CurrentThemeColors = ({ themeColors, turnOnEditModeActionType }: CurrentThemeColorsProps) => {
    const dispatch = useDispatch();
    const isNextGenPreview = useSelector(isNextGenPreviewSelector);
    const sectionLoading = useSelector(dynamicPreviewSectionsLoadingSelector);

    return (
        <div
            className={cx(styles.currentColorsContainer, {
                [styles.disabledPalette]: sectionLoading
            })}
        >
            <div className={styles.currentColors}>
                {themeColors.map((color, index) => (
                    <div
                        key={index}
                        className={styles.cursorPointer}
                        onClick={() => {
                            if (!sectionLoading) {
                                dispatch({ type: turnOnEditModeActionType });
                            }
                        }}
                    >
                        <ColoredSwatch className={styles.currentThemeColoredSwatch} color={color} />
                    </div>
                ))}
            </div>
            <div className={styles.currentColorIcons}>
                {!isNextGenPreview && (
                    <div className={styles.historyIconContainer}>
                        <ThemeHistoryIcon dispatch={dispatch} />
                    </div>
                )}
                <ICONS.EDIT_ICON
                    className={styles.editColorTheme}
                    title="msg: common.editColourTheme {Edit colour theme}"
                    data-title-position="top"
                    onClick={() => {
                        if (!sectionLoading) {
                            dispatch({ type: turnOnEditModeActionType });
                        }
                    }}
                />
            </div>
        </div>
    );
};
