import React from "react";
import { Box } from "@mui/material";

import { PublishButton } from "../../Publish/PublishButton/PublishButton";
import { useStyles } from "./styles";
import { SaveButton } from "../../SaveStatus/SaveButton/SaveButton";

export const Footer = () => {
    const classes = useStyles();

    return (
        <Box className={classes.rootContainer}>
            <Box className={classes.actionsContainer}>
                <PublishButton />
                <SaveButton />
            </Box>
        </Box>
    );
};
