
import React from 'react';
import scrollEffectsPPPage from '../../../commonView/ScrollEffects/scrollEffectsPPPage';
import type { TemplatePageViewProps } from "../flowTypes";
import { getComponentImageSettings } from "../../../../../view/oneweb/commonComponentSelectors";
import { TEMPLATE_BG_IMAGE_SCROLL_EFFECT_CHANGE } from "../../actionTypes";
import styles from "../../../commonView/ScrollEffects/ScrollEffects.css";

const bgText = "msg: common.scrollEffects.websiteBgDescription {Add modern 3D effects to your website background when scrolling. Preview or publish your site to see them in action.}"; // eslint-disable-line max-len

export default {
    ...scrollEffectsPPPage,
    view: ({ selectedComponent, dispatch }: TemplatePageViewProps) => {
        const { asset, scrollEffect } = getComponentImageSettings(selectedComponent),
            PageView = scrollEffectsPPPage.view;
        return <PageView
            hasImage={!!asset}
            scrollEffect={scrollEffect}
            description={bgText}
            revealExtraProps={{ msg: 'msg: common.fixed {Fixed}' }}
            actionOnScrollEffectChange={TEMPLATE_BG_IMAGE_SCROLL_EFFECT_CHANGE}
            dispatch={dispatch}
            customClassName={styles.template}
        />;
    }
};
