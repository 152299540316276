// Helper to find items recursively, covering child page items as well.
// This is almost a dupliicate of wbtgen/dal/model/DataSite.getOptionalPageRefByPageId method,
// with the only difference of item here does not need to be an instance of DataPageRef
import { DATA_PAGE_REF_TYPE } from "../../../../../dal/model/utils/dataSiteItemTypes";

const findPageRecursively = (items: any, pageId: string, selector: string = 'id'): null | undefined | any => {
    for (let i = 0; i < items.length; i++) {
        const page = (items[i][selector] === pageId && items[i].type === DATA_PAGE_REF_TYPE) ?
            items[i] : findPageRecursively(items[i].items || [], pageId, selector);
        if (page) return page;
    }
    return null;
};

export default findPageRecursively;
