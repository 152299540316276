import cx from 'classnames';
import getCenteredDialog from "../../../DialogManager/getCenteredDialogConfig";
import { configurationDialogFactory } from '../configurationDialogFactory';
import { DialogWidth, PinterestDialogHeight } from '../constants';
import { WIDGETS_PINTEREST_SET_LINK } from "./actionTypes";
import { validateLinkOrCode } from '../utils';
import { isPinterestLinkValid, parsePinterestCode } from './utils';
import Icons from '../view/Icons.css';

const validatePinterestData = validateLinkOrCode([isPinterestLinkValid], parsePinterestCode);

const
    configurationDialogId = "WIDGETS_PINTEREST_CONFIG_DIALOG",
    configurationDialogView = configurationDialogFactory({
        saveAction: WIDGETS_PINTEREST_SET_LINK,
        dialogIconClass: cx(Icons.pinterest, Icons.dialog),
        dialogTitle: "msg: widgets.pinterest.configDialog.title {Pinterest}",
        info: "msg: widgets.pinterest.config.addPinterest {Add a Pinterest pin, board or profile to your page.}",
        subInfo: "msg: widgets.pinterest.config.info {Go to the Pinterest pin, board or profile that you want to add. Copy the URL from your browser and paste it below.}", // eslint-disable-line max-len
        inputPlaceHolder: "msg: widgets.pinterest.configDialog.placeHolder {Paste Pinterest URL}",
        // TODO FIX THIS
        learnMore: "msg: widgets.pinterest.config.learnMore {https://help.one.com/hc/en-us/articles/10135506551953}",
        isLinkValid: validatePinterestData,
        termsOfService: "msg: widgets.pinterest.config.termsOfService {By using this widget, you agree to Pinterest\'s {termsOfServiceLink}.}", // eslint-disable-line max-len
        termsOfServiceLink: "msg: widgets.pinterest.config.termsOfServiceLink {https://policy.pinterest.com/en/terms-of-service }",
    }),
    configurationDialog = getCenteredDialog({
        width: DialogWidth,
        height: PinterestDialogHeight,
        component: configurationDialogView,
    });

export { configurationDialogId, configurationDialog };

