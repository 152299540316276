import React from 'react';
import cx from "classnames";
import styles from '../../../App.css';
import { isShiftBarBottom } from '../../../../utils/handle/index';
import type { Handle } from '../../../../redux/modules/children/workspace/flowTypes';

type Props = {
    top: number,
    left: number,
    isHover?: boolean,
    isActive?: boolean,
    kind: string,
    handles?: Handle[]
}

const ShiftBarTemplate = ({ kind, top, left, isHover, isActive, handles }: Props) => {
    let style = { top, left };
    return (
        <div
            style={style}
            className={cx(
                cx(styles.shiftPoint, { [styles.hover]: isHover, [styles.active]: isActive }),
                { [styles.bottom]: isShiftBarBottom(kind), [styles.hoverShiftPoint]: isHover }
            )}
        >
            {handles && handles.map(({ bBox }, index) => (<div
                className={styles.shiftBarHandle}
                style={{
                    left: bBox.left - left,
                    top: bBox.top - top,
                    width: bBox.right - bBox.left,
                    height: bBox.bottom - bBox.top
                }}
                key={index}
                onMouseDown={e => e.preventDefault()}
            />))}
        </div>
    );
};

export default ShiftBarTemplate;
