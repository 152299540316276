
/* eslint-disable no-useless-escape */

import React from 'react';

import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import { PropertiesButton } from '../../../../../view/common/Button/index';
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';

import Page from "../../../../PropertiesPanel/view/PropertiesPage";

import PpOnClickActionComboBox from '../../../Gallery/PpOnClickActionComboBox';

import * as actionTypes from '../../actionTypes';
import { OnClickActions } from '../../constants';

import * as pageIds from '../pageIds';
import pageTitles from '../pageTitles';

import type { ImageSliderComponent } from '../../flowTypes';

type Props = {
    selectedComponent: ImageSliderComponent,
    dispatch: Dispatch
}

const
    id = pageIds.ON_CLICK,
    title = pageTitles[id],
    manageLinkLabel = 'msg: component.imageSlider.pp.onClick.manageLinks.title {Click \"Manage links\", then select each image and add a link.}', // eslint-disable-line max-len
    buttonText = 'msg: component.imageSlider.pp.onClick.manageLinks {Manage links}',
    view = ({ selectedComponent: { onClickAction }, dispatch }: Props) => (
        <Page>
            <VerticalSpacer />
            <PpOnClickActionComboBox
                actionType={actionTypes.IMAGE_SLIDER_ON_CLICK_ACTION_CHANGED}
                value={onClickAction}
                dispatch={dispatch}
            />
            {
                onClickAction === OnClickActions.OPEN_LINK &&
                <div>
                    <VerticalSpacer />
                    <PropertyContainer label={manageLinkLabel}>
                        <PropertiesButton
                            text={buttonText}
                            onClick={() => dispatch({ type: actionTypes.IMAGE_SLIDER_PP_MANAGE_LINKS_CLICK })}
                        />
                    </PropertyContainer>
                </div>
            }
        </Page>
    );

export { id, title, view };
