import React from "react";
export const SvgSocialPlatformNotRecognizedCircle = props => (
    <svg viewBox="0 0 20 20" {...props}>
        <path
            fill="none"
            stroke="#979797"
            strokeDasharray="2 2"
            d="M10 19a9 9 0 100-18 9 9 0 000 18z"
        />
    </svg>
);
