import React from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';
// @ts-ignore
import { CSSTransitionGroup } from 'react-transition-group';
import styles, { PageWidth, SettingsContainerHeight } from './PropertiesPanel.css';
import { injectIntl } from '../../../view/intl/index';
import type { GenericPropertiesPanelProps } from "../flowTypes";
import {
    mouseDownOnPropertiesPanelDraggableAreaAction,
    mouseUpOnPropertiesPanelDraggableAreaAction,
    navigatePropertiesPanelBackAction,
} from "../epic/actionTypes";
import { getCurrentPageId, shouldShowBackButton } from "../epic/utils/navigation";
import { DIRECTIONBACKWARD } from "../epic/constants";
import Settings from './SettingsContainer';

const
    PageWidthInt = parseInt(PageWidth, 10),
    HeaderHeightInt = parseInt(styles.HeaderHeight, 10),
    SettingsHeightInt = parseInt(SettingsContainerHeight, 10);

class GenericPropertiesPanelClass extends React.Component<GenericPropertiesPanelProps> {
    onHeaderMouseDown = (e: React.MouseEvent<any>) => {
        e.stopPropagation();
        this.props.dispatch(mouseDownOnPropertiesPanelDraggableAreaAction(this.positionFromEvent(e)));
    }

    onHeaderMouseUp = (e: React.MouseEvent<any>) => {
        e.stopPropagation();
        this.props.dispatch(mouseUpOnPropertiesPanelDraggableAreaAction(this.positionFromEvent(e)));
    }

    onHeaderBackButtonClick = () => {
        this.props.dispatch(navigatePropertiesPanelBackAction());
    }

    positionFromEvent = (e: React.MouseEvent<any>) => ({
        x: e.clientX,
        y: e.clientY,
    })

    render() {
        const
            {
                title,
                dimensions,
                navigation,
                children,

                containerClassName: propContainerClassName,
                animateMovement,
                position,
                draggable,
                settings,
                opacity,
                intl,
                selectedCmpPosition,
                isModernLayoutActive
            } = this.props,
            containerClassName = cx(
                styles.container,
                {
                    [styles.animateMovement]: animateMovement,
                    [styles.static]: !draggable,
                },
                propContainerClassName,
            ),
            canShowSettings = settings &&
                (
                    !(isModernLayoutActive && selectedCmpPosition) ||
                    (isModernLayoutActive ? !selectedCmpPosition.isInsideHeaderOrFooter : true)
                ),
            containerStyle = {
                ...(position && {
                    top: position.y,
                    left: position.x,
                }),
                height: dimensions.height + (canShowSettings ? SettingsHeightInt : 0),
                opacity,
            },
            HeaderEventHandlers = (draggable && {
                onMouseDown: this.onHeaderMouseDown,
                onMouseUp: this.onHeaderMouseUp,
            }) || {},
            { selectedComponent = {} } = children.props,
            showBackButton = shouldShowBackButton(navigation, isModernLayoutActive, selectedCmpPosition, selectedComponent),
            transitionName = navigation.direction === DIRECTIONBACKWARD ? 'ppTitleBackward' : 'ppTitleForward',
            containerHeight = dimensions.height - HeaderHeightInt,
            pagesContainerStyle = {
                height: containerHeight,
            },
            pagesStyle = {
                width: (navigation.sequence.length * PageWidthInt) + "px",
                left: (navigation.activePageIndex * PageWidthInt * -1) + "px",
                height: containerHeight,
            },
            currentPageId = getCurrentPageId(navigation),
            pages = navigation.sequence.map(pageId => (
                <div key={pageId} style={{ width: PageWidthInt, height: containerHeight }}>
                    { pageId === currentPageId && (children) }
                </div>
            ));

        return (
            <div className={containerClassName} style={containerStyle}>
                <div className={styles.header} {...HeaderEventHandlers}>
                    {showBackButton && (<div className={styles.backBtn} onClick={this.onHeaderBackButtonClick} />)}
                    <CSSTransitionGroup
                        transitionName={transitionName}
                        transitionEnterTimeout={0}
                        transitionLeaveTimeout={0}
                    >
                        {[title].map(title => (
                            <div key={title as string} className={styles.titleContainer}>
                                {intl.msgJoint(title)}
                            </div>
                        ))}
                    </CSSTransitionGroup>
                </div>
                {canShowSettings && (<Settings {...settings} />)}
                <div className={styles.pagesContainer} style={pagesContainerStyle}>
                    <div className={styles.pages} style={pagesStyle}>
                        {pages}
                    </div>
                </div>
            </div>
        );
    }
}

const GenericPropertiesPanelWrapper = (props: GenericPropertiesPanelProps) => {
    const
        { getPortalNode } = props,
        portalNode = (getPortalNode && getPortalNode()) || null;

    if (portalNode) {
        return ReactDOM.createPortal(
            <GenericPropertiesPanelClass {...props} />,
            portalNode,
        );
    }

    return <GenericPropertiesPanelClass {...props} />;
};

export const GenericPropertiesPanel = injectIntl(GenericPropertiesPanelWrapper);
