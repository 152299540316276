import * as R from 'ramda';
import { setTextColor } from "../setColor";
import * as color from "../../../../../mappers/color";
import { makeApplyEditorChangesReducer } from "./helpers/helpers";
import { getColorValue } from "./helpers/getColorValue";
import { updateTextShadow } from "../updateTextShadow";
import getFormattedColor from "./helpers/getFormattedColor";
import { applyFormatValue } from "../editorUtils/methods/helpers/applyFormatValue";

import {
    TINY_MCE_REMOVE_SHADOW, TINY_MCE_SET_HIGHLIGHT_COLOR,
    TINY_MCE_SET_SHADOW_BLUR_RADIUS,
    TINY_MCE_SET_SHADOW_COLOR,
    TINY_MCE_SET_SHADOW_OFFSET_X, TINY_MCE_SET_SHADOW_OFFSET_Y, TINY_MCE_SET_TEXT_COLOR
} from "../actionTypes";
import { HIGHLIGHT_COLOR } from "../editorUtils/editorCommands";

type MakeUpdaterShadowUpdaterProps = {
    prop: "shadowColor" | "shadowOffsetX" | "shadowOffsetY" | "blurRadius";
    payloadToValueMapper?: (arg0: AnyValue) => AnyValue;
    updateTextShadowValuePreProcessor?: (arg0: AnyValue) => AnyValue;
    triggerActionType: string;
};

const
    onSetColorUpdater = {
        conditions: [
            TINY_MCE_SET_TEXT_COLOR
        ],
        reducer: makeApplyEditorChangesReducer(
            ({ values: [payload], state, scope, editor }) => {
                const
                    { color } = payload,
                    cssColor = getColorValue(payload.color);

                setTextColor(editor, cssColor);

                return {
                    state: { ...state, color },
                    scope
                };
            }
        )
    },
    makeShadowUpdater = ({
        prop,
        payloadToValueMapper = R.identity,
        updateTextShadowValuePreProcessor = R.identity,
        triggerActionType
    }: MakeUpdaterShadowUpdaterProps) => ({
        conditions: [triggerActionType],
        reducer: makeApplyEditorChangesReducer(
            ({ state, scope, values: [payload], editor }) => {
                const value = payloadToValueMapper(payload);

                updateTextShadow({
                    editor,
                    shadowColor: R.pipe(getFormattedColor, R.when(R.identity, color.toCss))(state.shadowColor),
                    shadowOffsetX: state.shadowOffsetX,
                    shadowOffsetY: state.shadowOffsetY,
                    blurRadius: state.blurRadius,
                    [prop]: updateTextShadowValuePreProcessor(value)
                });

                return {
                    state: {
                        ...state,
                        [prop]: value
                    },
                    scope
                };
            }
        )
    }),
    onSetShadowColorUpdater = makeShadowUpdater({
        triggerActionType: TINY_MCE_SET_SHADOW_COLOR,
        payloadToValueMapper: R.pipe(R.prop('color'), getFormattedColor),
        updateTextShadowValuePreProcessor: color.toCss,
        prop: 'shadowColor'
    }),
    onSetBlurRadiusUpdater = makeShadowUpdater({
        triggerActionType: TINY_MCE_SET_SHADOW_BLUR_RADIUS,
        prop: 'blurRadius'
    }),
    onSetShadowOffsetXUpdater = makeShadowUpdater({
        triggerActionType: TINY_MCE_SET_SHADOW_OFFSET_X,
        prop: 'shadowOffsetX'
    }),
    onSetShadowOffsetYUpdater = makeShadowUpdater({
        triggerActionType: TINY_MCE_SET_SHADOW_OFFSET_Y,
        prop: 'shadowOffsetY'
    }),
    onRemoveShadowUpdater = {
        conditions: [TINY_MCE_REMOVE_SHADOW],
        reducer: makeApplyEditorChangesReducer(({ state, scope, editor }) => {
            applyFormatValue(editor, 'textshadow', 'none');

            return {
                state: {
                    ...state,
                    shadowColor: null
                },
                scope
            };
        })
    },
    onSetHighlightColorUpdater = {
        conditions: [
            TINY_MCE_SET_HIGHLIGHT_COLOR
        ],
        reducer: makeApplyEditorChangesReducer(
            ({ values: [payload], state, scope, editor }) => {
                const
                    { color } = payload,
                    cssColor = getColorValue(payload.color);

                editor.execCommand(HIGHLIGHT_COLOR, false, cssColor);

                return {
                    state: { ...state, color },
                    scope
                };
            }
        )
    };

export {
    onSetColorUpdater,
    onSetBlurRadiusUpdater,
    onSetShadowColorUpdater,
    onSetShadowOffsetXUpdater,
    onSetShadowOffsetYUpdater,
    onRemoveShadowUpdater,
    onSetHighlightColorUpdater
};
