import {
    configurationDialogId as WidgetPinterestConfigurationDialogId,
    configurationDialog as WidgetsPinterestConfigurationDialog,
} from "./Pinterest/configurationDialog";
import {
    configurationDialogId as WidgetTwitterConfigurationDialogId,
    configurationDialog as WidgetsTwitterConfigurationDialog,
} from "./Twitter/configurationDialog";
import {
    configurationDialogId as WidgetInstagramConfigurationDialogId,
    configurationDialog as WidgetsInstagramConfigurationDialog,
} from "./Instagram/configurationDialog";
import {
    configurationDialogId as WidgetFacebookConfigurationDialogId,
    configurationDialog as WidgetsFacebookConfigurationDialog,
} from "./Facebook/configurationDialog";
import {
    configurationDialogId as WidgetSoundcloudConfigurationDialogId,
    configurationDialog as WidgetsSoundcloudConfigurationDialog,
} from "./Soundcloud/configurationDialog";
import {
    configurationDialogId as WidgetSpotifyConfigurationDialogId,
    configurationDialog as WidgetsSpotifyConfigurationDialog,
} from "./Spotify/configurationDialog";
import {
    configurationDialogId as WidgetVimeoConfigurationDialogId,
    configurationDialog as WidgetsVimeoConfigurationDialog,
} from "./Vimeo/configurationDialog";
import {
    configurationDialogId as WidgetGoogleMapsConfigurationDialogId,
    configurationDialog as WidgetsGoogleMapsConfigurationDialog,
} from "./GoogleMaps/configurationDialog";
import {
    configurationDialogId as WidgetTiktokConfigurationDialogId,
    configurationDialog as WidgetsTiktokConfigurationDialog,
} from "./Tiktok/configurationDialog";
import {
    configurationDialogId as WidgetEventbriteConfigurationDialogId,
    configurationDialog as WidgetsEventbriteConfigurationDialog,
} from "./Eventbrite/configurationDialog";
import {
    configurationDialogId as WidgetBingMapsConfigurationDialogId,
    configurationDialog as WidgetsBingMapsConfigurationDialog,
} from "./BingMaps/configurationDialog";
import {
    configurationDialogId as WidgetGoFundMeConfigurationDialogId,
    configurationDialog as WidgetsGoFundMeConfigurationDialog,
} from "./GoFundMe/configurationDialog";
import {
    configurationDialogId as WidgetMailchimpConfigurationDialogId,
    configurationDialog as WidgetsMailchimpConfigurationDialog,
} from "./Mailchimp/configurationDialog";
import {
    configurationDialogId as WidgetPaypalConfigurationDialogId,
    configurationDialog as WidgetsPaypalConfigurationDialog,
} from "./Paypal/configurationDialog";
import {
    configurationDialogId as WidgetBookingsConfigurationDialogId,
    configurationDialog as WidgetsBookingsConfigurationDialog,
} from "./Bookings/configurationDialog";
import { WidgetsPremiumComponentDialogId } from './dialogs/premiumComponentId';
import { WidgetsPremiumComponentDialog } from './dialogs/premiumComponent';
import { TooManyWidgetsDialogId } from './dialogs/tooManyWidgetsId';
import { TooManyWidgetsDialog } from './dialogs/tooManyWidgets';

export const WidgetsDialogRegistry = [
    [WidgetPinterestConfigurationDialogId, WidgetsPinterestConfigurationDialog],
    [WidgetTwitterConfigurationDialogId, WidgetsTwitterConfigurationDialog],
    [WidgetInstagramConfigurationDialogId, WidgetsInstagramConfigurationDialog],
    [WidgetFacebookConfigurationDialogId, WidgetsFacebookConfigurationDialog],
    [WidgetSoundcloudConfigurationDialogId, WidgetsSoundcloudConfigurationDialog],
    [WidgetSpotifyConfigurationDialogId, WidgetsSpotifyConfigurationDialog],
    [WidgetTiktokConfigurationDialogId, WidgetsTiktokConfigurationDialog],
    [WidgetEventbriteConfigurationDialogId, WidgetsEventbriteConfigurationDialog],
    [WidgetBingMapsConfigurationDialogId, WidgetsBingMapsConfigurationDialog],
    [WidgetVimeoConfigurationDialogId, WidgetsVimeoConfigurationDialog],
    [WidgetGoogleMapsConfigurationDialogId, WidgetsGoogleMapsConfigurationDialog],
    [WidgetGoFundMeConfigurationDialogId, WidgetsGoFundMeConfigurationDialog],
    [WidgetMailchimpConfigurationDialogId, WidgetsMailchimpConfigurationDialog],
    [WidgetPaypalConfigurationDialogId, WidgetsPaypalConfigurationDialog],
    [WidgetBookingsConfigurationDialogId, WidgetsBookingsConfigurationDialog],
    [WidgetsPremiumComponentDialogId, WidgetsPremiumComponentDialog],
    [TooManyWidgetsDialogId, TooManyWidgetsDialog],
];
