import React from "react";
import ButtonKind from "../../oneweb/Button/kind";
import { ComponentNames } from "../../oneweb/constants";
import * as Icon from './Icons.css';
import { ButtonTemplateView } from "../../oneweb/Button/expandedPanel/expandedPanelContent";
import type { ExtendedPanelContentProps } from "../view/inserter";

export default {
    kind: ButtonKind,
    shortCut: {
        iconClassName: Icon.button,
        text: ComponentNames[ButtonKind]
    },
    extendedPanelData: {
        title: 'msg: extendedPanel.buttonTitle {Button}',
        content: (props: ExtendedPanelContentProps) => <ButtonTemplateView {...props} />
    }
};
