/* eslint-disable max-len */
import React from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import { injectIntl } from "../../../view/intl/index";
import * as styles from "../AutoColorLeftPanel.css";
import { ACCENT_THEME_COLOR, MAIN_THEME_COLOR, WHITE_THEME_COLOR, BLACK_THEME_COLOR } from "../../ThemeGlobalData/constants";
import ICONS from "../../../view/Icons/index";
import { QuestionTip } from "../../presentational/Tooltip/questionTip/QuestionTip";
import { TooltipPosition } from "../../presentational/Tooltip/constants";
import * as colorMapper from "../../../mappers/color";
import { showColorPickerAction } from "../../ColorPicker/actionCreators";
import { getPosition } from "../../../utils/mouse";
import { getSuggestedColorsForColorPicker } from "../../ThemesAccordion/constants";
import { toHsl } from "../../../../dal/pageMapAdapter/mappers/Base/color";
import { getMainColorsHexFromAccentColor } from "../../../utils/colorUtils";
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import type { Color } from "../../../mappers/flowTypes";
import { ThemeHistoryIcon } from "../ThemeHistory/ThemeHistoryView";
import ContrastAdjustAnimation from "../ContrastAdjustDialog/view/LottieAnimation/ContrastAdjustAnimation";
import { isNextGenPreviewSelector } from "../../TemplateSelector_DEPRECATED/epics/preview/selector";

type ColorContainerProps = {
    currentColor: Color;
    suggestedColorsLabel: string;
    suggestedColors: Array<Color | undefined>;
    colorText: string;
    colorDesc: string;
    themeColorName: string;
    customStyles?: Record<string, any>;
    className?: string;
};

const colorPickerClassname = "colorPickerContainer";
const suggestedLightColorsHex = ["#FFF5F5", "#FFFFF5", "#F6FFF5", "#F5FBFF", "#F8F5FF"],
    suggestedDarkColorsHex = ["#4A3535", "#4A4A35", "#354A35", "#35454A", "#41354A"],
    suggestedLightColorsHsl = suggestedLightColorsHex.map(hex => toHsl(hex)),
    suggestedDarkColorsHsl = suggestedDarkColorsHex.map(hex => toHsl(hex));
const mainColorText = "msg: autoColor.leftPanel.setColorTitle.mainColour {Main Colour}",
    secondaryColourText = "msg: autoColor.leftPanel.setColorTitle.secondaryColour {Secondary Colour}",
    lightColourText = "msg: autoColor.leftPanel.setColorTitle.lightColour {Light Colour}",
    darkColourText = "msg: autoColor.leftPanel.setColorTitle.darkColour {Dark Colour}",
    mainColorDesc =
        "msg: autoColor.leftPanel.setColorDescription.mainColour {This is the predominant colour used on your website, for items such as background and buttons}",
    secondaryColourDesc =
        "msg: autoColor.leftPanel.setColorDescription.secondaryColour {This colour is used as an accent colour on your website}",
    lightColourDesc = "msg: autoColor.leftPanel.setColorDescription.lightColour {This colour is mostly used for text on a dark background}",
    darkColourDesc = "msg: autoColor.leftPanel.setColorDescription.darkColour {This colour is mostly used for text on a light background}";

export const EditColors = injectIntl(props => {
    const isNextGenPreview = useSelector(isNextGenPreviewSelector);
    const { intl, dispatch, themeColors, animation, themeSingleColorChangeAction, disabled } = props;
    const { mainColor, accentColor, whiteColor, blackColor } = themeColors || {};
    // Need to add 2 more colors in suggestion
    const mainColorHexOptions = getMainColorsHexFromAccentColor(accentColor);
    const mainColorSuggestions = mainColorHexOptions.map(mainColorHex => toHsl(mainColorHex));
    const lightColorPickerStyles = {
            Saturation: {
                color: {
                    width: "4200px",
                    height: "4200px"
                }
            }
        },
        darkColorPickerStyles = {
            Saturation: {
                color: {
                    width: "850px",
                    height: "850px",
                    marginTop: "-600px"
                }
            }
        };

    const ColorContainer = ({
        currentColor,
        suggestedColorsLabel,
        suggestedColors,
        colorText,
        colorDesc,
        themeColorName,
        customStyles = {},
        className
    }: ColorContainerProps) => {
        const isColorFeedbackOn = themeColorName === MAIN_THEME_COLOR;
        return (
            <div
                className={cx(styles.setColorContainer, {
                    [styles.disabledPalette]: disabled
                })}
            >
                <div
                    className={styles.setBaseColorBox}
                    style={{
                        backgroundColor: colorMapper.toCss(currentColor)
                    }}
                    onClick={e => {
                        if (disabled) return;
                        dispatch(
                            showColorPickerAction({
                                position: getPosition(e),
                                color: currentColor,
                                onChangeDebounced: {
                                    action: themeSingleColorChangeAction,
                                    timeout: 100
                                },
                                onTheFlyChange: true,
                                additionalPayload: {
                                    themeColorName,
                                    isColorFeedbackOn: themeColorName === MAIN_THEME_COLOR,
                                    styles: customStyles,
                                    ...(isColorFeedbackOn
                                        ? {
                                            colorToCompare: accentColor
                                        }
                                        : {}),
                                    className
                                },
                                suggestedColorsLabel,
                                suggestedColors
                            })
                        );
                    }}
                >
                    {themeColorName === WHITE_THEME_COLOR ? <ICONS.EDIT_ICON_BLACK /> : <ICONS.EDIT_ICON_WHITE />}
                </div>
                <div className={styles.setColorTitleAndTip}>
                    <div className={styles.setColorTitle}> {intl.msgJoint(colorText)} </div>
                    <QuestionTip
                        position={TooltipPosition.RIGHT}
                        distance={7}
                        theme={{
                            tipInfo: styles.tipInfo,
                            container: styles.tipContainer
                        }}
                        className={styles.questionTip}
                    >
                        <div>
                            <div className={styles.tipTitle}>{intl.msgJoint(colorText)}</div>
                            <VerticalSpacer y={6} />
                            <div className={styles.tipDesc}>{intl.msgJoint(colorDesc)}</div>
                        </div>
                    </QuestionTip>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.editColorsContainer}>
            <VerticalSpacer y={39} />
            <div className={cx(styles.colourTheme)}> {intl.msgJoint("msg: autoColor.leftPanel.editTopTitle {Edit colour Theme}")} </div>
            <div className={cx(styles.colourThemeDescription)}>
                {" "}
                {intl.msgJoint(
                    "msg: autoColor.leftPanel.editTopDescription {Select the colours you would like to use on your website}"
                )}{" "}
            </div>
            <div className={styles.fullWidthSeparator} />
            {animation ? (
                <ContrastAdjustAnimation dispatch={dispatch} />
            ) : (
                <React.Fragment>
                    {isNextGenPreview ? (
                        <VerticalSpacer y={18} />
                    ) : (
                        <div className={styles.editScreenHistoryIconContainer}>
                            <ThemeHistoryIcon dispatch={dispatch} />
                        </div>
                    )}
                    <div className={styles.setColorsContainer}>
                        <ColorContainer
                            currentColor={accentColor}
                            themeColorName={ACCENT_THEME_COLOR}
                            suggestedColorsLabel="msg: colorTheme.suggestedMainColours {Suggested Main Colours}"
                            suggestedColors={getSuggestedColorsForColorPicker(accentColor)}
                            colorText={mainColorText}
                            colorDesc={mainColorDesc}
                        />
                        <VerticalSpacer y={24} />
                        <ColorContainer
                            currentColor={mainColor}
                            themeColorName={MAIN_THEME_COLOR}
                            suggestedColorsLabel="msg: colorTheme.suggestedSecondaryColors {Suggested Secondary Colours}"
                            suggestedColors={mainColorSuggestions}
                            colorText={secondaryColourText}
                            colorDesc={secondaryColourDesc}
                        />
                        <VerticalSpacer y={24} />
                        <ColorContainer
                            currentColor={whiteColor}
                            themeColorName={WHITE_THEME_COLOR}
                            suggestedColorsLabel="msg: colorTheme.suggestedLightColors {Suggested Light Colours}"
                            suggestedColors={suggestedLightColorsHsl}
                            colorText={lightColourText}
                            colorDesc={lightColourDesc}
                            customStyles={lightColorPickerStyles}
                            className={colorPickerClassname}
                        />
                        <VerticalSpacer y={24} />
                        <ColorContainer
                            currentColor={blackColor}
                            themeColorName={BLACK_THEME_COLOR}
                            suggestedColorsLabel="msg: colorTheme.suggestedDarkColors {Suggested Dark Colours}"
                            suggestedColors={suggestedDarkColorsHsl}
                            colorText={darkColourText}
                            colorDesc={darkColourDesc}
                            customStyles={darkColorPickerStyles}
                            className={colorPickerClassname}
                        />
                        <VerticalSpacer y={32} />
                    </div>
                </React.Fragment>
            )}
        </div>
    );
});
