
export enum LinkTypes {
    album = 'album',
    artist = 'artist',
    concert = 'concert',
    episode = 'episode',
    playlist = 'playlist',
    track = 'track',
    show = 'show'
}

export const
    DefaultTheme = 'default',
    AltTheme = 'alternate',
    Themes = {
        [`${DefaultTheme}`]: 'msg: widgets.spotify.cta.themes.default {Default}',
        [`${AltTheme}`]: 'msg: widgets.spotify.cta.themes.alternate {Alternate}'
    },
    DefaultAllowed = "autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture",
    defaultState = {
        link: "",
        theme: DefaultTheme,
        startTime: 0,
        mobileHide: false,
    },
    ComponentHeightsByType = {
        [LinkTypes.album]: 380,
        [LinkTypes.artist]: 380,
        [LinkTypes.concert]: 380,
        [LinkTypes.episode]: 352,
        [LinkTypes.playlist]: 380,
        [LinkTypes.track]: 352,
        [LinkTypes.show]: 352,
    },
    CtaButtonTexts = {
        [LinkTypes.album]: 'msg: widgets.spotify.cta.changeAlbum {Change album}',
        [LinkTypes.artist]: 'msg: widgets.spotify.cta.changeArtist {Change artist}',
        [LinkTypes.concert]: 'msg: widgets.spotify.cta.changeConcert {Change concert}',
        [LinkTypes.episode]: 'msg: widgets.spotify.cta.changeEpisode {Change episode}',
        [LinkTypes.playlist]: 'msg: widgets.spotify.cta.changePlaylist {Change playlist}',
        [LinkTypes.track]: 'msg: widgets.spotify.cta.changeTrack {Change track}',
        [LinkTypes.show]: 'msg: widgets.spotify.cta.changeShow {Change show}',
    },
    DEFAULT_WIDTH = 600,
    DEFAULT_HEIGHT = 380,
    DATA_TEST_ID = 'spotify-widget';
