/* eslint-disable max-len */

import React, { useRef } from 'react';
import cx from 'classnames';
import { connect } from "react-redux";
// Todo: WBTGEN-19410
import Lottie from "react-web-lottie";
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import { StripTypes, DialogBody } from "../../../../../view/common/dialogs/baseDialog/index";
import { closeDialog } from "../../../../App/actionCreators/index";
import { injectIntl, Intl, Msg } from "../../../../../view/intl/index";
import PrimaryButton from "../../../../../view/common/Button/PrimaryButton";
import styles from './UpgradeSuccessfulDialog.css';
import type { BrowserDimensions } from "../../../../../redux/modules/children/dimensions/index";
import Dialog from "../../../../../view/common/dialogs/baseDialog/Dialog";
import { LOTTIE_DEFAULT_OPTIONS } from "../../../../Onboarding/view/LottieAnimations/constant";
import animationData from "./successAnimation.json";
import subscriptionDataEpic from '../../../../App/epics/subscriptionData/index';
import { isEcommerceSubscription, isPremiumSubscription } from '../../../../App/epics/subscriptionData/utils';
import type { AppDispatch, SubscriptionData } from "../../../../../redux/modules/flowTypes";

type UpgradeSuccessfulDialogProps = {
    subscriptionData: SubscriptionData;
    intl: Intl,
    dispatch: AppDispatch
}
const
    UpgradeSuccessfulDialog = injectIntl((props: UpgradeSuccessfulDialogProps) => {
        const defaultOptions = {
            ...LOTTIE_DEFAULT_OPTIONS,
            loop: false,
            autoplay: true,
            animationData
        };
        const
            { intl, dispatch, subscriptionData: { subscriptionType } } = props;
        let upgradedTier = <Msg k="common.starter">Starter</Msg>;
        if (isEcommerceSubscription(subscriptionType)) {
            upgradedTier = <Msg k="common.business_ecommerce">Business + Ecommerce</Msg>;
        } else if (isPremiumSubscription(subscriptionType)) {
            upgradedTier = <Msg k="common.premium">Premium</Msg>;
        }

        const lottieRef = useRef(null);
        return (
            <Dialog
                stripType={StripTypes.NONE}
                className={styles.dialog}
                closeBtnClassName={styles.dialogCloseBtn}
            >
                <DialogBody className={cx(styles.commonElementCSS, styles.upgardeSuccessfuldialogBody)}>
                    <div className={styles.upgradeSuccessfulAnimation}>
                        <Lottie
                            lottiRef={lottieRef}
                            options={defaultOptions}
                            isClickToPauseDisabled
                        />
                    </div>
                    <div className={cx(styles.commonElementCSS, styles.upgradeSuccessfulHeader)}>
                        {intl.msgJoint('msg:upgradeSuccess.dialog.header.label {Upgrade successful!}')}
                    </div>
                    <div className={styles.upgradeSuccessfulMsg}>
                        <Msg k="upgradeSuccess.dialog.msg.label" params={{ upgradedTier }}>
                            {`Congratulations! Your plan has been upgraded to Website Builder {upgradedTier}.`}
                        </Msg>
                    </div>
                    <div className={styles.upgradeSuccessfulMsg}>
                        <Msg k="upgradeSuccess.dialog.mailsent.msg">
                            {`You will receive a confirmation email with more details about your new plan. `}
                        </Msg>
                    </div>
                    <PrimaryButton
                        className={cx(styles.commonElementCSS, styles.upgradeSuccessfulContinueToWsbBtn)}
                        onClick={() => {
                            dispatch(closeDialog());
                        }}
                    >
                        {intl.msgJoint('msg: seamlessUpgrade.freeUpgradeSuccessDialog.MCTABtn.label {Continue to Website Builder}')}
                    </PrimaryButton>
                </DialogBody>

            </Dialog>
        );
    });

export default {
    ...getCenteredDialogConfig({
        width: 710,
        height: 760,
        component: connect(null)(UpgradeSuccessfulDialog),
        dialogClassName: styles.dialogContainerClassName,
        dependsOn: {
            subscriptionData: subscriptionDataEpic.reducer,
        },
    }),
    updateConfFn: (config: Record<string, any>, dimensions: BrowserDimensions, props: Record<string, any>) =>
        ({ ...config, props: { ...config.props, ...props } })
};
