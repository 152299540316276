import React from "react";
import cx from "classnames";
import btnStyles from "../view/Button.css";
import type { StylesheetsIdToNameMap } from "../../../Workspace/epics/stylesheets/flowTypes";
import type { ThemeBackgroundType } from "../../../ThemeGlobalData/flowTypes";

const optionRenderer = (
    value: string,
    label: string,
    stylesheetsIdToNameMap: StylesheetsIdToNameMap,
    selectedParentTheme?: ThemeBackgroundType
) => (
    <div className={cx(selectedParentTheme, stylesheetsIdToNameMap[value], `${stylesheetsIdToNameMap[value]}-ddo`)}>
        <div className={btnStyles.textContainer}>{label}</div>
    </div>
);

export { optionRenderer };
