// @ts-nocheck
import { isStripKind } from "./util";

const OrderFactor = {
    STRETCH: 100,
    TEMPLATE: 500,
    PAGE: 1000
};

export default (component) => {
    let orderFactor;

    if (isStripKind(component.kind)) {
        orderFactor = OrderFactor.STRETCH;
    } else {
        orderFactor = component.inTemplate ? OrderFactor.TEMPLATE : OrderFactor.PAGE;
    }

    return ((component.orderIndex * 2) + orderFactor - 1);
};
