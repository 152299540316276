import React, { useState } from 'react';
import { InputBase, InputLabel, InputLabelProps, Grid, Autocomplete, Chip, TextField } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';

import { ErrorHint } from './ErrorHint';

import imageStyles from "../Images/imageStyle.css";
import { Msg } from "../../../../../wbtgen/src/view/intl";
import { extraThemeConfig } from '../../app/theme';

export const PlaceholderInput = withStyles(
    (theme) => ({
        root: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            wordBreak: "break-all",
            padding: "8px 20px",
            borderRadius: 100,
            width: "100%",
            minHeight: "40px",
            border: `1px solid ${theme.palette.custom.colorGray_9d}`,
            [theme.breakpoints.down('sm')]: {
                width: `calc(100vw - 90px)`,
                padding: "13px 20px",
                minHeight: "50px",
            },
            'label + &': {
                position: 'static',
                marginTop: '10px',
            },
        }
    }),
    {
        name: "PlaceHolderInput"
    }
)(Grid);

export const SettingsInput = withStyles(
    (theme) => ({
        root: {
            borderRadius: extraThemeConfig.borderRadius,
            'label + &': {
                position: 'static',
                marginTop: '10px',
            },
        },
        input: {
            position: 'relative',
            border: `1px solid ${theme.palette.custom.colorGray_bb}`,
            fontSize: 16,
            height: (props) => { return props?.height ? props?.height : 46; },
            lineHeight: "24px",
            width: "100%",
            padding: '0 12px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
        },
        focused: {
            '& > input': {
                border: `1px solid ${theme.palette.custom.colorScienceBlue}`,
            }
        },
        error: {
            '& > input': {
                border: '1px solid red',
            }
        }
    }),
    {
        name: 'SettingsInput'
    }
)(InputBase);

export const SettingsInputLabel = (props: InputLabelProps) => (
    <InputLabel
        shrink
        htmlFor="input"
        style={{
            fontSize: '14px',
            lineHeight: '18px',
            transform: 'none',
            position: 'static'
        }}
        {...props}
    />
);

const useStyles = makeStyles((theme) => ({
    autoCompleteInputRoot: {
        height: "auto",
        minHeight: "50px",
        gap: "6px",
        padding: "9px 20px !important",
        borderRadius: "30px",
        [theme.breakpoints.down('md')]: {
            minHeight: "44px",
            padding: "7px 20px !important",
        }
    },
    autoCompleteInput: {
        fontSize: "15px",
        lineHeight: "24px",
        padding: "0 3px !important",
    },
    autoCompleteEndAdornment: {
        display: "none",
    },
    chipRoot: {
        margin: "0 !important",
        height: "30px",
        borderRadius: "4px",
        padding: "0 15px",
        background: theme.palette.custom.colorGray_f7,
        border: `1px solid ${theme.palette.custom.colorGray_ed}`
    },
    chipLabel: {
        fontSize: "14px",
        lineHeight: "16px",
        padding: 0
    },
    chipDeleteIcon: {
        margin: "0 0 0 6px !important",
    }
}), { name: "MultiSelectTagInput" });

type MultiSelectTagInputProps = {
    defaultValue?: Array<string>,
    options: Array<{value: string, label: string}>,
    placeholder: string,
    handleChange: (value: string | Array<string>) => void;
    min: number,
    max: number,
    multiple?: boolean
}

export const MultiSelectTagInput = (props: MultiSelectTagInputProps) => {
    const { defaultValue = [], options, placeholder, handleChange, min = 0, max, multiple = true } = props;
    const classes = useStyles();

    const [items, setItems] = useState<string | string[]>(defaultValue);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const [isBeingEdited, setIsBeingEdited] = useState(false);

    const errorMessage = (
        <Msg
            k="common.selectUpToError"
            params={{
                maxSelectionPermitted: max
            }}
        >
            {`You can only select up to {maxSelectionPermitted, number} options`}
        </Msg>
    );

    return (
        <React.Fragment>
            <Autocomplete
                multiple={multiple}
                options={options.map(el => el.label)}
                disableClearable
                defaultValue={defaultValue}
                componentsProps={{
                    popper: {
                        placement: "top"
                    }
                }}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                            deleteIcon={
                                <div className={imageStyles.cancel16} />
                            }
                            classes={{
                                root: classes.chipRoot,
                                label: classes.chipLabel,
                                deleteIcon: classes.chipDeleteIcon
                            }}
                        />
                    ))}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={items.length ? undefined : placeholder}
                        error={!isBeingEdited && items.length < min}
                        onFocus={() => setIsBeingEdited(true)}
                        onBlur={() => setIsBeingEdited(false)}
                    />
                )}
                classes={{
                    inputRoot: classes.autoCompleteInputRoot,
                    input: classes.autoCompleteInput,
                    endAdornment: classes.autoCompleteEndAdornment
                }}
                onInputChange={() => {
                    if (showErrorMessage) {
                        setShowErrorMessage(false);
                    }
                }}
                onChange={(_e, value) => {
                    const isArrayValue = Array.isArray(value);
                    if (value.length <= max) {
                        setShowErrorMessage(false);
                    } else {
                        setShowErrorMessage(true);
                        if (isArrayValue) {
                            value.pop();
                        }
                    }
                    if (isArrayValue) {
                        setItems([...value]);
                        handleChange([...value]);
                    } else {
                        setItems(value as string);
                        handleChange(value as string);
                    }
                }}
            />
            <ErrorHint message={errorMessage} isValid={!showErrorMessage} />
        </React.Fragment>
    );
};
