import React from "react";
export const SvgSocialLinksDragHandlePatched = ({ hover, ...rest }) => (
    <svg viewBox="0 0 8 13" {...rest}>
        <g fill={hover ? "#b1c8e4" : "#dddddd"}>
            <ellipse cx={1.71} cy={1.77} rx={1.71} ry={1.77} />
            <ellipse cx={6.29} cy={1.77} rx={1.71} ry={1.77} />
            <ellipse cx={1.71} cy={6.5} rx={1.71} ry={1.77} />
            <ellipse cx={6.29} cy={6.5} rx={1.71} ry={1.77} />
            <ellipse cx={1.71} cy={11.23} rx={1.71} ry={1.77} />
            <ellipse cx={6.29} cy={11.23} rx={1.71} ry={1.77} />
        </g>
    </svg>
);
