import * as main from './pages/main';
import * as mobileView from '../../../PropertiesPanel/view/MobileView/page';
import * as language from '../../../PropertiesPanel/view/Language/page';
import makePages from '../../../PropertiesPanel/view/makePages';

const propertiesPanelConfig = {
    pages: makePages(main, language, mobileView),
    defaultPageId: main.id,
};

export default propertiesPanelConfig;
