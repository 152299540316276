import * as R from 'ramda';
import { getFontName } from '../../presentational/AddGoogleFont/utils';
import {
    getFirstStylesheetByType,
    textNormalGlobalstyle
} from '../../Workspace/epics/stylesheets/selectors';
import type { WebshopComponent, ComponentDependencies } from './flowTypes';
import { font } from "../../../mappers/path";
import { getGSNormalTextColor, getDefaultButtonBGColor } from './utils';
import styleButton from '../Button/globalStyle/kind';

/**
 * IMPORTANT: It is important to set the left to 0 here because webshop component when dropped should match
 * the width of the template. Adding the component by double click adds a padding 20px, which should not be
 * For reference look into logic of wbtgen/src/components/Workspace/epics/componentsOutsideTemplateWarning/index.js
 */

export default (
    defaultState: WebshopComponent,
    {
        templateWidth,
        globalStyles,
        defaultButtonStyleId
    }: ComponentDependencies
): WebshopComponent => {
    const WebShopData = R.evolve({
        left: R.always(0),
        width: R.always(templateWidth),
        buttonId: R.always(defaultButtonStyleId),
        font: R.always(R.pipe(textNormalGlobalstyle, R.path([font]), getFontName)(globalStyles)),
        fontColor: R.always(getGSNormalTextColor(globalStyles))
    })(defaultState);

    // Focuscolor is set using utils.js function. Same is used in "mergePageLayoutWithTemplate" to set template
    // default button bg color
    WebShopData.focusColor = getDefaultButtonBGColor(getFirstStylesheetByType(styleButton)(globalStyles)) ||
        defaultState.focusColor;
    return WebShopData;
};

