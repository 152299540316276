import type { AppStore } from "../../redux/modules/flowTypes";
import { getDAL } from "../../../dal/index";
import {
    DIRECTORY_CONTENTS_STATUS_FETCH_REQUEST
} from './epic/directoryContentFetchActions';
import { directoryContentsFetchSuccessAction } from "./actionCreators";
import type { MissingAssetsResourceList } from "./types";
import type { WebspaceDirectoryContents } from "../../../dal/types";

export const missingAssetsMiddleware = (store: AppStore) => (next: Dispatch) => (action: AnyAction) => {
    if (action.type === DIRECTORY_CONTENTS_STATUS_FETCH_REQUEST) {
        const { payload: directories } = action,
            resourcePromises: Array<Promise<any>> = [],
            dal = getDAL();

        directories.forEach(directory => {
            const resPromise = dal.getWebSpaceContent(directory, true)
                .then(response => ({ directory, ...response }));
            resourcePromises.push(resPromise);
        });

        Promise.all(resourcePromises).then(resourceValues => {
            const resources: MissingAssetsResourceList = [];

            resourceValues.forEach(response => resources.push(
                (response && response.body) ?
                    { directory: response.directory, entries: response.body.entries } :
                    { directory: response.directory, error: true }
            ));

            const payload: WebspaceDirectoryContents = resources.reduce(
                (acc, { directory, ...rest }) => ({ ...acc, [directory]: rest }),
                {}
            );
            store.dispatch(directoryContentsFetchSuccessAction(payload));
        });
    }
    return next(action);
};
