import makeEpic from "../../../../epics/makeEpic";
import { openDialog } from "../../../App/actionCreators";
import { NewPageDialogId } from "../newPageDialogIds";
import {
    NEW_PAGE_DIALOG_OPEN,
    NEW_PAGE_SWITCH_TAB,
    LOAD_NEW_PAGE_LAYOUT_TEMPLATES_SUCCESS_ACTION,
    // loadNewPageLayoutRepositorySiteAction,
    loadPageLayoutsSiteAction,
    loadNewPageLayoutTemplatesApiAction,
    newPageTemplateSelectedAction
} from "../actions";
import pageDatasetValueActionType from "../../../App/epics/pageDataset/valueActionType";
import { receiveOnly } from "../../../../epics/makeCondition";
import { loadSectionComponentsForPage } from "../../Tree/actionCreators";

const NEW_PAGE_DIALOG_EPIC_VAT = 'NEW_PAGE_DIALOG_EPIC_VAT';

export const newPageDialogEpic = makeEpic({
    defaultState: null,
    valueActionType: NEW_PAGE_DIALOG_EPIC_VAT,
    updaters: [
        {
            conditions: [NEW_PAGE_DIALOG_OPEN],
            reducer: ({ state, values: [{ activeTab }] }: any) => ({
                state,
                multipleActionsToDispatch: [
                    openDialog(NewPageDialogId),
                    // $FlowFixMe
                    { type: NEW_PAGE_SWITCH_TAB, payload: activeTab },
                    // loadNewPageLayoutRepositorySiteAction(),
                    loadPageLayoutsSiteAction(),
                    loadSectionComponentsForPage(),
                    loadNewPageLayoutTemplatesApiAction(),
                ]
            })
        },
        {
            conditions: [
                receiveOnly(pageDatasetValueActionType),
                LOAD_NEW_PAGE_LAYOUT_TEMPLATES_SUCCESS_ACTION
            ],
            reducer: ({ state, values: [pageDataSet] }) => ({
                state,
                actionToDispatch: newPageTemplateSelectedAction(pageDataSet.template.id)
            })
        }
    ]
});
