import { getLocalization } from "../getLocalization";
import type { GetPlaceholderTextType } from "../types";

export const getPlaceholderTextForEmail = (
    { component: { specific }, viewerDomain, messages, locale }: GetPlaceholderTextType<any>
): string => {
    if (viewerDomain && locale) {
        return getLocalization(messages, locale, 'email.placeholder');
    }
    return specific.placeholderText;
};
