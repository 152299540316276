import React from 'react';
import { connect } from 'react-redux';
import { makeEpicStateSelector } from '../../epics/makeEpic';
import { colorGlobalDataEpic } from "./epic/colorGlobalDataEpic";
import ColorPicker from "./view/index";
import reducer from "./reducers/index";
import type { AppState } from "../../redux/modules/flowTypes";
import { stylesheetsEpic } from "../Workspace/epics/stylesheets/index";
import { getThemeColorsFromStylesheet } from "../Workspace/epics/stylesheets/selectors";
import { colorThemeDataEpicStateFromAppStateSelector } from "../SiteSettings/ColorThemeData/selectorActionTypes";

const View = props => (
    <div>
        {props.state.opened && <ColorPicker {...props.state} dispatch={props.dispatch} />}
    </div>
);

const globalColorSelector = makeEpicStateSelector(colorGlobalDataEpic.valueActionType);
const themeColorsDataSelector = (state) => getThemeColorsFromStylesheet(makeEpicStateSelector(stylesheetsEpic.valueActionType)(state));
const view = connect((state: AppState) => ({ state: {
    ...state.colorPicker,
    ...globalColorSelector(state),
    themeSettingsData: colorThemeDataEpicStateFromAppStateSelector(state),
    themeColorsData: themeColorsDataSelector(state)
} }))(View);

export {
    view,
    reducer
};
