import React from 'react';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import * as Actions from '../../actionTypes';
import * as pagesIds from "./ids";
import pagesTitles from "./titles";
import type { Props } from '../flowTypes';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import injectIntl from '../../../../../view/intl/injectIntl';
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';
import stylePropertyContainer from '../../../../../view/common/PropertyContainer/PropertyContainer.css';
import * as styles from '../styles.css';
import Textarea from "../../../../../view/common/Input/Textarea";

const
    id = pagesIds.SUCCESSMESSAGE,
    title = pagesTitles[id],
    view = injectIntl(({ dispatch, selectedComponent }: Props) => {
        const { successMessage } = selectedComponent;
        return (
            <Page>
                <VerticalSpacer />
                <PropertyContainer
                    label="msg: component.contactForm.successMessage {Success message}"
                    className={stylePropertyContainer.pointer}
                >
                    <Textarea
                        className={styles.successMessage}
                        value={successMessage}
                        onChange={payload => dispatch({
                            type: Actions.CONTACT_FORM_SUCCESS_MESSAGE_CHANGED,
                            payload
                        })}
                        placeholder="msg: component.contactForm.enterSuccessMsg {Enter success message}"
                    />
                </PropertyContainer>
            </Page>
        );
    });

export { id, title, view };
