import { put, take } from "redux-saga/effects"; // eslint-disable-line node/file-extension-in-import
import makeResolveWhenFulfilledSaga from "../../../../../epics/makeResolveWhenFulfilledSaga";
import { FC_MY_IMAGES_EDIT_ACTION, FC_MY_IMAGES_EDIT_SAVE_ACTION, FC_RESOURCES_FETCH_SUCCESS } from "../actionTypes";
import Resource from "../Resource";
import getResourceMetadataSaga from './getResourceMetadataSaga';
import type { ResourceMetadata } from "../flowTypes";
import { editImageAC } from "../../../../../components/ImageEditor/actionCreators";
import reloadResourcesSaga from "./reloadResourcesSaga";
import { selectResourcesSaga } from "./upload/uploadFromComputer/uploadFromComputerSaga";

export const fcMyFilesEditSaga = makeResolveWhenFulfilledSaga(
    FC_MY_IMAGES_EDIT_ACTION,
    function* (resource: Resource) {
        const asset: ResourceMetadata | false = yield* getResourceMetadataSaga(resource.getWebspacePath());
        if (!asset) return; // TODO: error dialog ?

        asset.url = resource.getWebSpaceProtocolRelativePath();

        // @ts-ignore
        yield put(editImageAC(FC_MY_IMAGES_EDIT_SAVE_ACTION, asset, FC_MY_IMAGES_EDIT_ACTION));

        const { payload: { asset: newAsset } } = yield take(FC_MY_IMAGES_EDIT_SAVE_ACTION);

        // reload resources and selected edited (=copied) one

        yield* reloadResourcesSaga();
        yield take(FC_RESOURCES_FETCH_SUCCESS);

        yield* selectResourcesSaga([Resource.fromWebspaceUrl(newAsset.url).getName()]);
    },
);
