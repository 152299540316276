import React from "react";
import cx from "classnames";
import { Msg } from "../intl/index";
import styles from "./LearnMore.css";

type LearnMoreProps = {
    onClick: React.MouseEventHandler<HTMLAnchorElement>;
    className ?: string;
}

export const LearnMore = (props: LearnMoreProps) => (
    <a onClick={props.onClick} className={cx(props.className, styles.learnMore)}>
        <Msg k="common.learnMore">Learn more</Msg>
    </a>
);

export const FindMoreAboutUpgrade = (props: LearnMoreProps) => (
    <a onClick={props.onClick} className={cx(props.className, styles.learnMore, styles.upgradeOptions)}>
        <Msg k="common.findOutUpgradeOptions">Find more about your upgrade options.</Msg>
    </a>
);
