import kind from "./kind";

import calcRenderProps from "../Background/calcRenderProps";
import view from "../Background/view/index";
import propertiesPanel from "./propertiesPanel/index";
import componentMainActions from "./componentMainActions/index";
import reducer from "./reducer/index";
import dependsOn from "../Background/dependsOn";
import { adjustmentHookConfig } from "../Background/adjustmentHookConfig";
import previewConfig from "./previewConfig";
import { bgMobileCalcProps } from "../Background/bgMobileCalcProps";
import { ComponentNames } from "../constants";
import { MinDimensions } from './constants';

export default {
    kind,
    label: ComponentNames[kind],
    shortcutIconName: 'hoverbox',
    calcRenderProps,
    view,
    propertiesPanel,
    componentMainActions,
    reducer,
    dependsOn,
    adjustmentHookConfig,
    minDimensions: MinDimensions,
    mobileEditorConfig: {
        ...previewConfig,
        calcProps: bgMobileCalcProps
    }
};
