import { makeTreeFactory } from './tree/index';
import { getElements } from './elements/index';
import type { Stylesheet, AnyComponent, DataSite, HtmlWriterData } from '../flowTypes';
import { normalizeComponent } from './helpers/index';

export default (
    data: HtmlWriterData,
    defaultGlobalStyles: Array<Stylesheet>,
    site: null | undefined | DataSite,
    wrappedItems: Record<number, AnyComponent>,
    editMode: boolean = true
) => {
    const { text } = data;
    const normalizedComponent = normalizeComponent(data);
    const treeFactory = makeTreeFactory(editMode);
    const { elements, wrappedItems: updatedWrappedItems } =
        getElements(normalizedComponent, defaultGlobalStyles, site, wrappedItems, editMode);

    return {
        nodes: treeFactory(text, elements),
        wrappedItems: updatedWrappedItems
    };
};
