import { MobileMaxFontSize } from '../../../../components/Globalstyles/Text/constants';
import type { Stylesheet } from '../../flowTypes';
import Node from '../../parser/tree/Node';

export default (node: Node, defaultGlobalStyles: Array<Stylesheet>): string => {
    const {
        ref,
        styles
    } = node;

    const {
        size: fontSize
    } = styles;

    const defaultGlobalStyle = defaultGlobalStyles.find(style => style.ref === ref);

    // @ts-ignore
    const size = fontSize || (defaultGlobalStyle ? defaultGlobalStyle.size : null);

    if (!size) {
        return '';
    }

    const mobileMaxSize = MobileMaxFontSize[ref] || 20;

    // todo move to const
    if (size > mobileMaxSize) {
        return 'mobile-oversized';
    } else if (size < 12) {
        return 'mobile-undersized-lower';
    } else if (size < 16) {
        return 'mobile-undersized-upper';
    }

    return '';
};
