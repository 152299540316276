import {
    IMAGE_SLIDER_SET_IMAGES_ON_COMPONENT_DROP,
    IMAGE_SLIDER_PP_OPEN_SORTING_WINDOW_CLICK,
    IMAGE_SLIDER_PP_MANAGE_LINKS_CLICK
} from "../actionTypes";
import { label, message } from '../../Gallery/constants/index';
import { openSortedImageDialogAction } from "../../Gallery/actionCreators";
import { componentConfigurationCompleteAction } from "../../../../redux/modules/children/workspace/actionCreators";
import { getFormattedAssets } from "../../Gallery/utils";

export const imageSliderMiddleware = (store: Store) => (next: Dispatch) => (action: AnyAction) => {
    if (action.type === IMAGE_SLIDER_SET_IMAGES_ON_COMPONENT_DROP) {
        const { payload: { assets } } = action,
            images = getFormattedAssets(assets);

        store.dispatch(componentConfigurationCompleteAction({ images }));
    }

    if (
        action.type === IMAGE_SLIDER_PP_OPEN_SORTING_WINDOW_CLICK ||
        action.type === IMAGE_SLIDER_PP_MANAGE_LINKS_CLICK
    ) {
        store.dispatch(openSortedImageDialogAction(label, message));
    }

    return next(action);
};
