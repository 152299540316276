import makeEpic from '../../../../epics/makeEpic';
import {
    EXTENDED_PANEL_CLOSE,
    PANEL_ITEM_CLICK,
    PANEL_ITEM_LEFT_MOUSE_DOWN,
    PANEL_COMPONENT_SHORTCUT_CLICK,
    EXPANDED_PANEL_COMPONENT_CLICK,
    EXPANDED_PANEL_COMPONENT_MOUSE_DOWN,
    PANEL_COMPONENT_SHORTCUT_MOUSE_DOWN,
    OPEN_ITEM_PANEL
} from "../../actionTypes";
import valueActionType from './valueActionType';
import { APP_LEFT_MOUSE_DOWN, REPLACE_DIALOG } from "../../../../redux/modules/actionTypes";
import { APP_RIGHT_MOUSE_DOWN, LEFT_PANEL_ON_MOUSE_LEAVE } from "../../../App/actionTypes";
import { LOGOUT, TOP_BAR_OPEN_POPUP } from "../../../TopBar/actionTypes";
import IsMouseOverLeftPanelVAT from "../../../App/epics/isMouseOverLeftPanel/valueActionType";
import { receiveOnly, withSelector } from "../../../../epics/makeCondition";
import { COMPONENT_ADD_STARTED } from "../../../DndAddComponent/actionTypes";
import { onShortCutClick } from "../../actionCreators/index";
import { SHOW_MODERN_HEADER_LAYOUT_LEFT_PANEL } from "../../../ModernLayouts/actionTypes";

export const defaultState = {
    open: false,
    openedOnMouseDown: false,
    itemKind: null,
    componentConfig: {}
};

const closeExpandedPanelEvents = [
    APP_LEFT_MOUSE_DOWN,
    APP_RIGHT_MOUSE_DOWN,
    TOP_BAR_OPEN_POPUP,
    PANEL_COMPONENT_SHORTCUT_CLICK,
    LOGOUT,
    REPLACE_DIALOG,
    SHOW_MODERN_HEADER_LAYOUT_LEFT_PANEL
];

const panelClose = (state) => ({ ...state, open: false, itemKind: null });

const LeftPanelEpic = makeEpic({
    defaultState: { ...defaultState },
    valueActionType,
    updaters: [
        {
            conditions: [PANEL_ITEM_LEFT_MOUSE_DOWN],
            reducer: ({ values: [panelItem], state }) => {
                const { itemKind, open } = state,
                    { componentKind } = panelItem;

                if (open && itemKind === componentKind) {
                    return {
                        state: {
                            ...state,
                            openedOnMouseDown: false
                        }
                    };
                }
                return {
                    state: {
                        ...state,
                        itemKind: componentKind,
                        open: true,
                        openedOnMouseDown: true
                    }
                };
            }
        },
        {
            conditions: [PANEL_ITEM_CLICK],
            reducer: ({ values: [panelItem], state }) => {
                const { itemKind, open, openedOnMouseDown } = state,
                    { componentKind } = panelItem;

                if (open && itemKind === componentKind) {
                    if (openedOnMouseDown) {
                        return {
                            state: {
                                ...state,
                                openedOnMouseDown: false
                            }
                        };
                    } else {
                        return {
                            state: {
                                ...state,
                                itemKind: null,
                                open: false,
                                openedOnMouseDown: false
                            }
                        };
                    }
                }
                return { state };
            }
        },
        {
            conditions: [OPEN_ITEM_PANEL],
            reducer: ({ values: [{ componentKind }], state }) => {
                return {
                    state: {
                        ...state,
                        itemKind: componentKind,
                        open: true,
                        openedOnMouseDown: false
                    }
                };
            }
        },
        {
            conditions: [EXPANDED_PANEL_COMPONENT_CLICK],
            reducer: ({ values: [{ componentKind, ...componentConfig }], state }) => ({
                state: { ...state, componentConfig },
                actionToDispatch: onShortCutClick(componentKind)
            })
        },
        {
            conditions: [EXPANDED_PANEL_COMPONENT_MOUSE_DOWN],
            reducer: ({ values: [{ componentKind, ...componentConfig }], state }) => ({
                state: { ...state, componentConfig },
                actionToDispatch: {
                    type: PANEL_COMPONENT_SHORTCUT_MOUSE_DOWN,
                    payload: { componentKind }
                }
            })
        },
        ...[COMPONENT_ADD_STARTED, PANEL_COMPONENT_SHORTCUT_CLICK, EXTENDED_PANEL_CLOSE].map(action => ({
            conditions: [action],
            reducer: ({ state }) => ({
                state: panelClose(state),
                actionToDispatch: { type: LEFT_PANEL_ON_MOUSE_LEAVE }
            })
        })),
        ...closeExpandedPanelEvents.map((action) => ({
            conditions: [
                receiveOnly(IsMouseOverLeftPanelVAT),
                action,
            ],
            reducer: ({ values: [mouseIsOverPanel], state }) => ({
                state: !mouseIsOverPanel ? panelClose(state) : state
            })
        }))
    ]
});

const LeftPanelOpenStateSelector = withSelector(valueActionType,
    ({ open, itemKind }): {open: boolean, itemKind: string} => ({ open, itemKind }));

export {
    LeftPanelOpenStateSelector,
    closeExpandedPanelEvents,
    LeftPanelEpic as default
};
