/* eslint-disable */

import { ErrorCodes } from './CodeValidationErrors';

const HtmlValidator = (function () {
    function unwrap(str) {
        var arr = str.split(','), val, o = {};
        while ((val = arr.pop())) {
            o[val] = true;
        }
        return o;
    }
    //HTML 4 and 5 void tags
    var voidTags = unwrap('area,base,basefont,br,col,command,embed,frame,hr,img,input,keygen,link,meta,param,source,track,wbr'),
        singlelevel = unwrap('script,style'),
        regxstr = {
            tagname: "[\\-A-Za-z0-9_:]+",
            attrname: "[\\w\\-]+",
            attrvalue: (/(?:(?:"[^"]*")|(?:'[^']*')|[^>\s]+)/).toString().slice(1, -1) //quoted and unquoted strings
        },
        regx = {
            // Start tag regex: /[^<]*<([\-A-Za-z0-9_]+:)(?:\s+[\w\-]+(?:\s*=\s*(?:(?:"[^"]*")|(?:'[^']*')|[^>\s]+))?)*[^>]*>(.*)/,
            opentag: new RegExp('^[^<]*?<(' + regxstr.tagname + ')' +
                '(?:\\s+' + regxstr.attrname +
                '(?:\\s*=\\s*' + regxstr.attrvalue + ')?' +
                ')*' +
                '([^>]*?)>'),
            closetag: /^[^<]*?<([\-\[\]\/A-Za-z0-9_:]+)(\s*?)>/, //close tag
            doctype: /^[^<]*?<([!\w]+)([^>]*?)>/,   //NOSONAR
            comment: /^[^<]*?<(!--(?:.|\n)*?--)>/,
            unclosedComment: /^[^<]*?<!--(?!.*-->).*/s,
            cdata: /^[^<]*?<(!\[CDATA\[(?:.|\n)*?\]\])>/,
            unclosedCdata: /<!\[CDATA\[(?!.*\]\]>)([^]*?)$/m
        };
    return function (html) {
        var str = html.replace(/[\r]/g, '').trim(),
            tag, rawTag, isCloseTag,
            matches, stack: Record<string, any>[] = [], lineNumber: number, tagStartLineNumber = 1, tagEndLineNumber = 1, last,
            broken: any[] | string = '', level = 0,
            replaceSingleLevel = function (m) {
                tagEndLineNumber = tagStartLineNumber + (m.match(/\n/g) || []).length;
                //prepare for the next tag.
                tagStartLineNumber = tagEndLineNumber;
                return '';
            },
            pos;
        while (str) {
            tagStartLineNumber += (str.substring(0, str.indexOf('<')).match(/\n/g) || []).length;
            lineNumber = tagStartLineNumber;
            if(regx.unclosedComment.test(str)){
                broken = [ErrorCodes.HTML_COMMENT_NOT_CLOSED, lineNumber];
                break;
            }
            if(regx.unclosedCdata.test(str)){
                broken = [ErrorCodes.CDATA_NOT_CLOSED, lineNumber];
                break;
            }
            matches = (str.match(regx.opentag) || str.match(regx.closetag) || str.match(regx.comment) || str.match(regx.cdata) || str.match(regx.doctype));
            if (!matches) {
                pos = str.indexOf('<');
                if (pos >= 0) {
                    //add new lines.
                    tagStartLineNumber += (str.substr(0, pos).match(/\n/g) || []).length;
                    str = str.substr(pos + 1);
                    continue;
                }
                break;
            }
            rawTag = matches[1];
            tag = rawTag.toLowerCase(); //html is case insensitive
            
            tagEndLineNumber += (str.substring(0, matches[0].length).match(/\n/g) || []).length;

            str = str.substr(matches[0].length);

            //Identify close tag
            if (tag[0] === '/') {
                isCloseTag = true;
                tag = tag.substr(1);
            } else {
                isCloseTag = false;
            }

            //Do something per tag
            if (tag[0] === '!') {
                //Either doctype or comment, so ignore them
                if (tag.indexOf('![cdata[') === 0 ||tag.indexOf('!--') === 0 ) {
                    tagEndLineNumber = tagStartLineNumber + (matches[0].substr(matches[0].indexOf('<')).match(/\n/g) || []).length;
                    //prepare for the next tag.
                    tagStartLineNumber = tagEndLineNumber;
                }
                continue;
            } else if (voidTags[tag]) {
                continue;
            } else if (singlelevel[tag]) {
                //prepare for counting the \n between start of tag and end angle bracket of end tag
                tagStartLineNumber = tagEndLineNumber;
                //remove everything upto end tag
                var specialEndTagRegex = new RegExp("^((?:.|\\n)*?)</" + tag + "[^>]*>", 'i');
                if (!specialEndTagRegex.test(str.toLowerCase())) {
                    if (!isCloseTag) {
                        broken = [ErrorCodes.START_TAG_MISSING_END_TAG, lineNumber, '<' + tag + '>'];
                    } else {
                        broken = [ErrorCodes.EXTRA_END_TAG_FOUND, lineNumber, '<' + tag + '>'];
                    }
                    break;
                }
                str = str.replace(specialEndTagRegex, replaceSingleLevel);
                continue;
            }

            if (isCloseTag) {
                level -= 1;
            }
            if (level < 0) {
                broken = [ErrorCodes.EXTRA_END_TAG_FOUND, lineNumber, '<' + rawTag + '>'];
                break;
            }
            if (!isCloseTag) {
                level += 1;
            }

            if (!isCloseTag) {
                stack.push({
                    tag: tag,
                    line: lineNumber
                });
            } else {
                last = stack[stack.length - 1];
                if (last.tag !== tag) {
                    pos = -1;
                    stack.some(function (o, index) {
                        if (o.tag === tag) {
                            pos = index;
                            return true;
                        }
                    });
                    if (pos < 0) {
                        broken = [ErrorCodes.EXTRA_END_TAG_FOUND, lineNumber, '<' + rawTag + '>'];
                    } else {
                        broken = [ErrorCodes.START_TAG_SHOULD_BE_CLOSED_BEFORE, lineNumber,
                            '<' + last.tag + '>', last.line, '<' + rawTag + '>'];
                    }
                    break;
                }
                stack.pop();
            }
        }
        if (!broken && stack.length > 0) {
            last = stack[stack.length - 1];
            broken = [ErrorCodes.START_TAG_MISSING_END_TAG, last.line, '<' + last.tag + '>'];
        }
        return broken;
    };
}());

export default HtmlValidator;
