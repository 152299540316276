import { getCurrentPage, initiateNavigation } from "../utils/navigation";
import { adjustShouldNotOverlapSelectedComponentAndMCTA } from "../utils/position";
import type { Scope, Context, StateHolder } from "../../flowTypes";
import { getMaxHeight, getDimensions } from "../utils/dimensions";

export default (context: Context, scope: Scope): StateHolder => {
    const
        {
            selectedComponentWithId,
            selectedComponentWithId: { component, id },
            browserDimensions,
            componentDependencies,
            stylesheets,
            mobileEnablement
        } = context,
        { kind } = component,
        navigation = initiateNavigation(kind),
        currentPage = getCurrentPage(navigation, kind),
        maxHeight = getMaxHeight(browserDimensions.height),
        dimensions = getDimensions(maxHeight, currentPage.height),
        position = adjustShouldNotOverlapSelectedComponentAndMCTA({ context, dimensions, navigation });

    return {
        scope: {
            ...scope,
            selectedComponentId: id,
            maxHeight,
            actualPosition: position
        },
        state: {
            show: true,
            opacity: 0,
            navigation,
            currentPage,
            dimensions,
            position,
            animateMovement: false,
            selectedComponentWithId,
            componentDependencies,
            stylesheets,
            mobileEnablement
        }
    };
};
