import React from 'react';
import { connect } from "react-redux";
import cx from 'classnames';
import styles from '../FileChooserList.css';
import CheckBox from '../../../CheckBox/CheckBox';
import type { CheckBoxHeaderPropTypes } from "../../flowTypes";
import type { AppState } from "../../../../../redux/modules/flowTypes";
import { FC_TOGGLE_ALL_RESOURCES_SELECTION } from "../../../../../redux/modules/children/fileChooser/actionTypes";
import injectIntl from "../../../../intl/injectIntl";

const CheckBoxHeader = injectIntl(({ allIsSelected, toggleAll }: CheckBoxHeaderPropTypes) => {
    return (
        <div className={cx(styles.headerContainer, styles.checkbox)}>
            <CheckBox isChecked={allIsSelected} onClick={toggleAll} />
        </div>
    );
});

const mapStateToProps = ({ fileChooser }: AppState) => ({
    allIsSelected: fileChooser.allIsSelected
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    toggleAll: () => dispatch({ type: FC_TOGGLE_ALL_RESOURCES_SELECTION })
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckBoxHeader);
