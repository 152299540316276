import React from 'react';
import { connect } from 'react-redux';
import { errorDialogStandardHeight, errorDialogStandardWidth } from '../../../dialogs/constants';
import styles from '../FileChooserUpload.css';
import { Dialog, DialogTitleBox, DialogBody, DialogFooter } from '../../../dialogs/baseDialog/index';
import injectIntl from "../../../../intl/injectIntl";
import { StripTypes } from "../../../dialogs/baseDialog/Dialog";
import { FC_UPLOAD_CANCEL, FC_UPLOAD_RETRY } from "../../../../../redux/modules/children/fileChooser/actionTypes";
import getCenteredDialogConfig from "../../../../../components/DialogManager/getCenteredDialogConfig";
import ErrorDialogFileList from './ErrorDialogFileList';
import warningDialogStyle from '../../../dialogs/WarningDialog/WarningDialog.css';

const
    width = errorDialogStandardWidth,
    height = errorDialogStandardHeight,
    title = 'msg: fc.uploadFailedDialog.err.common.title {Upload to web space failed}',
    fileUploadText = 'msg: fc.uploadFailedDialog.err.common.failedUpload {The following file(s) did not upload.}',
    tryAgainText = 'msg: fc.uploadFailedDialog.err.common.tryAgain {Click \"{btn}\" to try again.}',
    mctaText = 'msg: common.ok {OK}',
    sctaText = 'msg: common.cancel {Cancel}';

const FailedErrorDialog = injectIntl((props: { fileNames: string[], retry, cancel, intl: Intl }) => {
    const { fileNames, retry, cancel, intl } = props;
    const tryAgainMsg = intl.msgJoint([
        tryAgainText,
        { btn: `<span class="${styles.bold}">${intl.msgJoint(mctaText)}</span>` }
    ]);

    return (
        <Dialog stripType={StripTypes.WARNING}>
            <DialogTitleBox
                title={title}
                className={warningDialogStyle.warningDialogTitle}
                iconClass={warningDialogStyle.iconWarning}
            />
            <DialogBody className={styles.labelSmall}>
                <p>{intl.msgJoint(fileUploadText)}</p>
                <ErrorDialogFileList fileNames={fileNames} />
                <p dangerouslySetInnerHTML={{ __html: tryAgainMsg }} />
            </DialogBody>
            <DialogFooter
                mctaText={mctaText}
                mctaHandler={retry}
                sctaText={sctaText}
                sctaHandler={cancel}
                noTopBorder
                className={warningDialogStyle.warningDialogFooter}
            />
        </Dialog>
    );
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    retry: () => dispatch({ type: FC_UPLOAD_RETRY }),
    cancel: () => dispatch({ type: FC_UPLOAD_CANCEL })
});

export default getCenteredDialogConfig(width, height, connect(null, mapDispatchToProps)(FailedErrorDialog));
