import { TEXT_CHARACTER_LIMIT_ON_STEP_FIELD } from "../../constants";
import { isValidEmail } from "../../../../../utils/validation";
import { telInputValidation } from "../../../../inputControls/input/commonValidations/telInputValidation";

const basicValidation = (value, limit) => {
    return !!value &&
        value.length >= limit.min &&
        value.length <= limit.max;
};

export const validateWebsitePurposeStep = (selectedOptions) => {
    return selectedOptions.length > 0;
};

export const validateWebsiteCategoryStep = (gmbKey) => {
    return basicValidation(gmbKey, TEXT_CHARACTER_LIMIT_ON_STEP_FIELD.gmbKey);
};

export const validateBusinessNameStep = (businessName) => {
    return basicValidation(businessName, TEXT_CHARACTER_LIMIT_ON_STEP_FIELD.businessName);
};

export const validateKeywordsStep = (keywords) => {
    return keywords && keywords.length;
};

export const validateEmailField = (email) => {
    return !email || isValidEmail(email);
};

export const validatePhoneField = (phone) => {
    return !phone || telInputValidation(phone).isValid;
};
