import React from 'react';
import { Flex } from "../../../view/reflexbox/index";
import IntlCombobox from '../../../view/common/Combobox/IntlCombobox';
import Label from '../../../view/common/Label';
import type { LabelledComboboxPropTypes } from './flowTypes';

export default (props: LabelledComboboxPropTypes) => {
    const
        {
            label,
            disabled,
            ...rest
        } = props;

    return (
        <Flex align="center" justify="space-between">
            <Label disabled={disabled}>{label}</Label>
            <IntlCombobox {...rest} disabled={disabled} />
        </Flex>
    );
};
