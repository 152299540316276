import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import TOOLTIP_VAT from '../tooltip/valueActionType';
import { CLOSE_TOOLTIP } from "../../actionTypes";
import { receiveOnly, optional } from "../../../../epics/makeCondition";
import { ModernLayoutInfoTooltip } from "../../ids";
import {
    MODERN_LAYOUT_ONBOARDING_PANEL_CLICKED,
    MODERN_LAYOUT_ONBOARDING_BKGD_CLICKED,
    GOTO_NEXT_STEP_ONBOARDING_PANEL,
    MODERN_LAYOUT_ONBOARDING_PANEL_IS_CLOSED,
} from '../../../ModernLayouts/actionTypes';

export const CHECK_STICKY_TOOLTIP_AFTER_QUICK_TOUR = 'CHECK_STICKY_TOOLTIP_AFTER_QUICK_TOUR';
const SHOW_MODERN_LAYOUT_INFO_TOOLTIP = 'SHOW_MODERN_LAYOUT_INFO_TOOLTIP';
const HIDE_MODERN_LAYOUT_INFO_TOOLTIP = 'HIDE_MODERN_LAYOUT_INFO_TOOLTIP';
export const showModernLayoutInfoTooltipAC = () => ({ type: SHOW_MODERN_LAYOUT_INFO_TOOLTIP });
export const hideModernLayoutInfoTooltipAC = () => ({ type: HIDE_MODERN_LAYOUT_INFO_TOOLTIP });

const defaultState = { show: false };

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [SHOW_MODERN_LAYOUT_INFO_TOOLTIP],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        show: true
                    }
                };
            }
        },
        {
            conditions: [HIDE_MODERN_LAYOUT_INFO_TOOLTIP],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        show: false
                    }
                };
            }
        },
        {
            // close info tooltip when click inserter shortcuts
            conditions: [
                receiveOnly(TOOLTIP_VAT),
                optional(MODERN_LAYOUT_ONBOARDING_PANEL_CLICKED),
                optional(MODERN_LAYOUT_ONBOARDING_BKGD_CLICKED),
                optional(GOTO_NEXT_STEP_ONBOARDING_PANEL),
                optional(MODERN_LAYOUT_ONBOARDING_PANEL_IS_CLOSED)
            ],
            reducer: ({ values: [{ id: tooltipId }], state }) => {
                if (tooltipId === ModernLayoutInfoTooltip) {
                    return {
                        state,
                        actionToDispatch: { type: CLOSE_TOOLTIP }
                    };
                }
                return { state };
            }
        }
    ]
});
