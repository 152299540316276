import * as React from 'react';
import cx from 'classnames';
import styles from './LazyImage.css';
import LoadingProxy from '../LoadingIndicator';
import { ua } from "../../../utils/user-agent/UserAgent";
import { BrowserName } from "../../../utils/user-agent/constants";
import { imgToDataUrl } from "../../../utils/imgToDataUrl";
import Url from "../../../../utils/Url";
import { getImageType } from "../../../utils/getImageType";
import { isTransparentImage } from "../../../utils/isTransparentImage";
import ImageComponent from "../../../components/oneweb/Image/view/Image";
import imgStyles from "../../../components/oneweb/Image/view/Image.css";

/**
 * TODO: refactor
 * - move to components/presentational
 * - refactor to getDerivedStateFromProps()
 * - clean up the mess
 * - export const
 * - implement LazyImageLoader and reuse it in HoverBox
 */

export type LazyImageProxy = Object;
export type LazyImageOnLoad = (img: HTMLImageElement) => void;

type Props = {
    src: string,
    proxy?: LazyImageProxy,
    className?: string,
    transparentClassName?: string,
    defaultProxyClassName?: string,
    style?: Record<string, any>,
    shouldLoad?: boolean,
    srcSet?: string,
    onLoad?: LazyImageOnLoad,
    showTransparency?: boolean,
    alt?: null | undefined | string,
    doNotUseDataUriForSafari?: boolean,
    width?: number,
    height?: number,
    onError?: Function
}

type State = {
    src: null | undefined | string;
    error: null | undefined | Error;
};

// TODO: refactor to use one object (WBTGEN-12858)
const
    ready = {},
    pending = {},
    transparent = {},
    dimensions = {};

const DefaultProxy = (props: Record<string, any>) => <LoadingProxy {...props} center />;

const shouldUseDataUrl = (src: string, doNotUseDataUriForSafari: null | undefined | boolean) => (doNotUseDataUriForSafari ?
    false : (ua().isBrowser(BrowserName.SAFARI) && getImageType(src) !== 'gif'));

export default class extends React.Component<Props, State> {
    static defaultProps = {
        shouldLoad: true
    };

    img: null | undefined | Record<string, any>;
    proxy: any;

    constructor({ proxy = DefaultProxy }: Props) {
        // @ts-ignore
        super();

        this.state = { src: null, error: null };
        if (proxy) {
            this.proxy = { display: proxy };
        }

        this._onLoad = this._onLoad.bind(this);
    }

    UNSAFE_componentWillMount() {
        if (this.props.shouldLoad) {
            // @ts-ignore
            this._load(this.props.src, this.props.srcSet);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.src !== this.props.src || nextProps.shouldLoad !== this.props.shouldLoad) {
            this._cancelLoadingImg();
            this.setState({ src: null });
        }
        if (nextProps.shouldLoad) {
            // @ts-ignore
            this._load(nextProps.src, nextProps.srcSet);
        }
    }

    componentWillUnmount() {
        this._cancelLoadingImg();
    }

    _load(src: string, srcSet: string) {
        const that = this;

        if (ready[src]) {
            this.setState({ src, error: null });
            return;
        }

        if (pending[src]) {
            pending[src].push(this._onLoad);
            return;
        }

        pending[src] = [this._onLoad];

        this.img = new window.Image();

        // handle CORS
        const
            currentDomain = Url(window.location.href).host,
            srcDomain = Url(src).host;

        if (srcDomain && srcDomain !== currentDomain) {
            this.img.crossOrigin = 'anonymous';
        }

        this.img.onload = () => {
            if (!pending[src] || !this.img) return;

            pending[src].forEach(callback => callback(src, this.img));
            delete pending[src];
            this.img.onload = null;
        };

        this.img.onerror = (error) => {
            delete pending[src];
            that.setState({ error });

            if (this.props.onError) {
                this.props.onError();
            }
        };

        // start loading (check for img.srcset because IE11 doesn't seem to support that)
        if (srcSet && this.img.srcset !== undefined) this.img.srcset = srcSet;
        else this.img.src = src;
    }

    _onLoad(src: string, img) {
        if (this.props.showTransparency && transparent[src] === undefined) {
            transparent[src] = isTransparentImage(img);
        }

        ready[src] = shouldUseDataUrl(src, this.props.doNotUseDataUriForSafari)
            ? imgToDataUrl(img)
            : true;

        dimensions[src] = {
            width: img.width,
            height: img.height,
        };

        if (src === this.props.src) {
            this.setState({ src });

            if (this.props.onLoad) this.props.onLoad(img);
        }
    }

    _cancelLoadingImg() {
        if (this.img && pending[this.props.src]) {
            delete pending[this.props.src];
            this.img.onerror = null;
            this.img.src = '';
            this.img = null;
        }
    }

    _isTransparent() {
        return transparent[this.props.src] === true;
    }

    renderImg() {
        const
            unsupportedObjectFit = new Image().style.objectFit === undefined,
            { src } = this.state,
            { srcSet, style = {}, className } = this.props,
            standInStyle = {
                ...style,
                backgroundImage: `url('${src}')`,
                backgroundSize: style.objectFit,
                ...((src && dimensions[src]) || {}),
            };

        if (style.objectFit && unsupportedObjectFit) {
            return (
                <div
                    style={standInStyle}
                    className={styles.standIn}
                />
            );
        }

        let srcProps;

        // @ts-ignore
        if (shouldUseDataUrl(src, this.props.doNotUseDataUriForSafari)) {
            // @ts-ignore
            const dataUrl = ready[src];
            srcProps = srcSet && new Image().srcset !== undefined ? { srcSet: dataUrl } : { src: dataUrl };
        } else {
            srcProps = srcSet && new Image().srcset !== undefined ? { srcSet } : { src };
        }

        if (this._isTransparent()) {
            const additionalStyles = { maxHeight: '100%', maxWidth: '100%' };
            return (
                <div
                    className={cx(
                        className,
                        styles.container,
                        styles.transparentContainer
                    )}
                    style={style}
                >
                    <img
                        {...srcProps}
                        className={this.props.transparentClassName}
                        style={{ objectFit: style.objectFit, ...additionalStyles }}
                        alt={this.props.alt}
                    />
                </div>
            );
        }

        return (
            <img
                {...srcProps}
                style={style}
                className={className}
                alt={this.props.alt}
            />
        );
    }

    render() {
        const { defaultProxyClassName } = this.props;

        if (!this.state.src && this.state.error) {
            return (
                <ImageComponent
                    isWorkspace
                    imageAvailable={false}
                    width={this.props.width as number}
                    height={this.props.height as number}
                    divClassName={cx(imgStyles.imageComponent, imgStyles.imgNotFound)}
                />
            );
        }

        return this.proxy && (!this.state.src || !ready[this.state.src])

            ? <this.proxy.display className={defaultProxyClassName} />
            : this.renderImg();
    }
}
