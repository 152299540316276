import React from 'react';
import { PremiumIcon } from '../../../../view/common/PremiumIcon/index';
import { DroppableCmpShortcut, ExtendedPanelShortCut } from "../../../Panel/view/DroppableCmpShortcut";
import styles from '../../../Panel/view/Inserter.css';

export const WidgetDroppableCmpShortCut = props => {
    const { widgetConfig, showPremiumIcon, dispatch } = props;
    return (
        <DroppableCmpShortcut
            kind={widgetConfig.kind}
            dispatch={dispatch}
        >
            {showPremiumIcon &&
                <PremiumIcon iconCustomClass={styles.premiumSocialMediaIconOnWidget} />}
            <ExtendedPanelShortCut
                iconClassName={widgetConfig.iconClassName}
                text={widgetConfig.title}
            />
        </DroppableCmpShortcut>
    );
};
