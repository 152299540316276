import React from 'react';
import * as pageIds from './ids';
import pagesTitles from './titles';
import NavigationGroup from '../../../../PropertiesPanel/view/IntlNavigationGroup';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import { id as mobileViewPageId } from '../../../../PropertiesPanel/view/MobileView/page';

// TODO: Props annotation
class Common extends React.Component<any> {
    render() {
        const
            { navigateToPage, isLogo } = this.props;
        return (
            <Page>
                <NavigationGroup
                    navigateToPage={navigateToPage}
                    pagesTitles={pagesTitles}
                    targetPagesIds={isLogo ?
                        [mobileViewPageId]
                        : [pageIds.SCALING, pageIds.BORDER, pageIds.ONCLICK, pageIds.SEO, mobileViewPageId]}
                />
            </Page>
        );
    }
}

const id = pageIds.MAIN;

export const makeCommonProperties = ({ componentTitle }: { componentTitle: string}) =>
    ({ id, view: Common, title: componentTitle });
