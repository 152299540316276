import globalStyles from '../../../view/global.css';
import type { ComponentsMap } from "../../../redux/modules/children/workspace/flowTypes";
import { getAutoMobileStyleComponentAndGlobalStyle } from "./getAutoMobileStyleComponentAndGlobalStyle";
import isTestEnv from "../../../debug/isTestEnv";
import type { Stylesheets } from '../../Workspace/epics/stylesheets/flowTypes';

const DEFAULT_FONT_FAMILY = isTestEnv() ? 'Open Sans' : globalStyles.oneFontFamilyDefaultFont.replace(/"/g, '');

export const getAutoMobileStyleFont = (
    componentsMap: ComponentsMap,
    stylesheet: Stylesheets,
    autoColorMode: boolean = false,
): null | undefined | string => {
    const componentAndGlobalStyle = getAutoMobileStyleComponentAndGlobalStyle(componentsMap, stylesheet, autoColorMode);

    if (!componentAndGlobalStyle) return DEFAULT_FONT_FAMILY;

    return componentAndGlobalStyle.globalStyle.item.inactive.text.font || DEFAULT_FONT_FAMILY;
};
