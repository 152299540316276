import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import {
    MCTA_ON_MOUSE_ENTER,
    MCTA_ON_MOUSE_LEAVE
} from "../../../../view/Workspace/Decorations/ComponentMainActions/actionTypes";
import { componentsMainActionsVAT } from "../componentMainActions/valueActionType";

const defaultState = false;

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [MCTA_ON_MOUSE_ENTER],
            reducer: () => ({ state: true })
        },
        {
            conditions: [MCTA_ON_MOUSE_LEAVE],
            reducer: () => ({ state: false })
        },
        {
            // If mouse is over MCTA during component deletion, MCTA_ON_MOUSE_LEAVE will not be fired
            conditions: [componentsMainActionsVAT],
            reducer: ({ values: [{ show }], state }) => ({ state: show ? state : false })
        }
    ]
});
