import valueActionType from './valueActionType';
import { makeEpic } from "../../../epics/makeEpic";
import { PIN_TO_SCREEN } from "../actionTypes";
import { componentPinToTopAC, openPinDialogAC } from "../actionCreators/updateComponentPin";

export default makeEpic({
    valueActionType,
    defaultState: null,
    updaters: [
        {
            conditions: [PIN_TO_SCREEN],
            reducer: ({ values: [componentId], state }) => {
                return {
                    state,
                    multipleActionsToDispatch: [
                        componentPinToTopAC(),
                        openPinDialogAC(componentId)
                    ]
                };
            }
        }
    ]
});
