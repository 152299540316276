export const
    AUTO_COLOR_MODE_CHANGE = 'AUTO_COLOR_MODE_CHANGE',
    AUTO_COLOR_MODE_DEACTIVATED = 'AUTO_COLOR_MODE_DEACTIVATED',
    AUTO_COLOR_MODE_ACTIVATED = 'AUTO_COLOR_MODE_ACTIVATED',
    SHOW_WAITING_SCREEN_AND_INIT_COMPUTE = 'SHOW_WAITING_SCREEN_AND_INIT_COMPUTE',
    SET_ONBOARDING_THEME_PALETTE = 'SET_ONBOARDING_THEME_PALETTE',
    START_ONBOARDING_OF_EXISTING_CUSTOMERS = 'START_ONBOARDING_OF_EXISTING_CUSTOMERS';

export const
    ENABLE_COLOR_THEME_SUCCESS = 'ENABLE_COLOR_THEME_SUCCESS',
    ENABLE_COLOR_THEME_REQUEST = 'ENABLE_COLOR_THEME_REQUEST',
    ENABLE_COLOR_THEME_FAILURE = 'ENABLE_COLOR_THEME_FAILURE',
    DYNAMIC_ONBOARDING_ENABLE_COLOR_THEME_SUCCESS = 'DYNAMIC_ONBOARDING_ENABLE_COLOR_THEME_SUCCESS',
    DYNAMIC_ONBOARDING_ENABLE_COLOR_THEME_REQUEST = 'DYNAMIC_ONBOARDING_ENABLE_COLOR_THEME_REQUEST',
    DYNAMIC_ONBOARDING_ENABLE_COLOR_THEME_FAILURE = 'DYNAMIC_ONBOARDING_ENABLE_COLOR_THEME_FAILURE',
    COMPUTE_COLOR_THEME_REQUEST = 'COMPUTE_COLOR_THEME_REQUEST',
    COMPUTE_COLOR_THEME_SUCCESS = 'COMPUTE_COLOR_THEME_SUCCESS',
    COMPUTE_COLOR_THEME_FAILURE = 'COMPUTE_COLOR_THEME_FAILURE',
    COMPUTE_COLOR_THEME_TEMPLATE_SELECTOR_REQUEST = 'COMPUTE_COLOR_THEME_TEMPLATE_SELECTOR_REQUEST',
    COMPUTE_COLOR_THEME_TEMPLATE_SELECTOR_SUCCESS = 'COMPUTE_COLOR_THEME_TEMPLATE_SELECTOR_SUCCESS',
    COMPUTE_COLOR_THEME_TEMPLATE_SELECTOR_FAILURE = 'COMPUTE_COLOR_THEME_TEMPLATE_SELECTOR_FAILURE';
