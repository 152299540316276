import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(
    (theme: Theme) => ({
        dashboardContainer: {
            "& > div:not(:first-child)": {
                marginTop: "24px",
            },
            [theme.breakpoints.up('sm')]: {
                "& > div:not(:first-child)": {
                    marginTop: "36px",
                },
            },
            [theme.breakpoints.up('md')]: {
                "& > div:not(:first-child)": {
                    marginTop: "52px",
                },
            },
        },
        title: {
            fontSize: "44px !important",
            lineHeight: "50px",
            marginBottom: "20px",
            [theme.breakpoints.down('md')]: {
                fontSize: "30px !important",
                lineHeight: "34px",
                marginBottom: "12px",
            }
        },
        description: {
            fontSize: "18px !important",
            lineHeight: "30px",
            [theme.breakpoints.down('md')]: {
                fontSize: "18px !important",
                lineHeight: "26px",
            }
        },
        analyticsAction: {
            padding: "11px 30px",
            fontSize: "16px",
            boxShadow: "none",
            "&:hover, &:focus, &:active": {
                boxShadow: "none"
            }
        },
        analyticsTerms: {
            color: theme.palette.custom.colorGray_8a,
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "12px",
            lineHeight: "22px",
            letterSpacing: "0.2px",
            [theme.breakpoints.down('md')]: {
                textAlign: 'center'
            }
        },
        iframeContainer: {
            height: '60vh',
            position: "relative",
            overflow: "hidden"
        },
        loaderContainer: {
            position: "absolute",
            zIndex: 1,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        }
    }),
    { name: "AnalyticsPage" },
);
