import { createSelector } from 'reselect';
import * as R from 'ramda';
import type { Components, Workspace, ComponentsMap, AnyComponent, ComponentsDependencies } from './flowTypes';
import registry from '../../../../view/oneweb/registry/index';
import getStretchComponentsMap from "./getStretchComponentsMap";
import type { AppStateSel } from '../../../../globalTypes';
import { pathSelector } from '../../../../utils/pathSelector';
import { Lit } from '../../../../lit';

type ComponentsMapSelectorProps = {
    components: Components
}

export const
    componentsMap = ({ components: { componentsMap: cmp } }: ComponentsMapSelectorProps) => cmp,
    pageModeSelector = ({ isPageMode }: Workspace): boolean => isPageMode,
    templateWidthSelector = ({ templateWidth }: Workspace): number => templateWidth,
    isEditingModePanelMinimizedSelector = ({ isEditingModePanelMinimized }: Workspace): boolean =>
        isEditingModePanelMinimized,
    browserDimensionsSelector = ({ browserDimensions }: Workspace) => browserDimensions,
    isPageModeSelector = ({ isPageMode }: Workspace): boolean => isPageMode,
    currentModeComponentsMapSelectorUtil = (componentsMapValue: ComponentsMap, isPageMode: boolean) =>
        R.pickBy(
            component => isPageMode === !component.inTemplate,
            componentsMapValue
        ),
    currentModeComponentsMapSelector = createSelector(
        componentsMap,
        isPageModeSelector,
        currentModeComponentsMapSelectorUtil
    ),
    selectedComponentsIds = ({ selectedComponentsIds }: Workspace): Array<string> => selectedComponentsIds,
    selectedComponentsCount = createSelector(
        selectedComponentsIds,
        (selectedCmpIds: Array<string>): number => selectedCmpIds.length
    ),
    selectedComponentId = createSelector(
        selectedComponentsIds,
        selectedComponentsCount,
        (selectedCmpIds: Array<string>, selectedCmpCount: number): null | undefined | string => {
            return selectedCmpCount === 1 ? selectedCmpIds[0] : null;
        }
    ),
    // @ts-ignore
    selectedComponent = createSelector(
        selectedComponentId,
        componentsMap,
        (selectedCmpId: string, cmpMap: ComponentsMap): AnyComponent => cmpMap[selectedCmpId]
    ),
    selectedComponents = createSelector(
        selectedComponentsIds,
        componentsMap,
        (selectedCmpsIds: Array<string>, cmpMap: ComponentsMap): Array<AnyComponent> =>
            selectedCmpsIds.map(selectedCmpId => cmpMap[selectedCmpId])
    ),
    selectedComponentKind = createSelector(
        selectedComponent,
        (selectedCmp: AnyComponent) => {
            return selectedCmp ? selectedCmp.kind : null;
        }
    ),
    selectedComponentRecord = createSelector(
        selectedComponentKind, (selectedCmpKind: string): any => registry[selectedCmpKind]
    ),
    selectedComponentRecordPropertiesPanelDefaultPageId = createSelector(
        selectedComponentRecord,
        (record: any): string => R.path(['propertiesPanel', 'defaultPageId'], record)
    ),
    componentsDependenciesSelector = (workspace: Workspace, kind: string): ComponentsDependencies =>
        R.path(['componentsDependencies', kind], workspace),
    stretchComponentsSelector = ({ components }: Workspace) => ({
        ...components,
        componentsMap: getStretchComponentsMap(components.componentsMap)
    }),
    workspaceFirstLoadedAppSel: AppStateSel<boolean> = pathSelector([Lit.selectedWorkspace, Lit.firstLoaded]);
