import React from "react";
import { useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import { SettingsSectionWrapper } from "../../../../SettingsControls";
import { GMBGetStartedInfo } from "./GMBGetStartedInfo";
import { GMBGetStartedActions } from "./GMBGetStartedActions";
import { GMBGetStartedUpgrade } from "./GMBGetStartedUpgrade";
import { isGMBLoading } from "../../redux/selectors";
import { GMBPreviewLoader } from "../GMBLoader/GMBPreviewLoader";
import getSubscriptionFeatureManager from "../../../../../../../../wbtgen/src/getSubscriptionFeatureManager";

const useStyles = makeStyles(
    {
        preview: {
            alignSelf: "stretch"
        }
    },
    { name: "GMBListingPreview" }
);

const renderDetails = ({ isLoading, requiresUpgrade }) => {
    if (isLoading) {
        return <GMBPreviewLoader />;
    }

    if (requiresUpgrade) {
        return <GMBGetStartedUpgrade />;
    }

    return <GMBGetStartedActions />;
};

export const GMBGetStarted = () => {
    const classes = useStyles();
    const isLoading = useSelector(isGMBLoading);
    const requiresUpgrade = !getSubscriptionFeatureManager().isGoogleMyBusinessAllowed();

    return (
        <SettingsSectionWrapper
            mt="52px"
            info={<GMBGetStartedInfo />}
            form={renderDetails({ isLoading, requiresUpgrade })}
            classes={{ form: classes.preview }}
        />
    );
};
