import { Lit } from '../../../lit';
import { pathSelector } from '../../../utils/pathSelector';
import type { AppState } from "../../../redux/modules/flowTypes";
import { AllCategoryId } from "./components/ImagesCategories";
import { SstockImageType, SstockImageOrientation } from "../../../../dal/constants";
import { normalizedCurrentLangaugeAppSel } from "../../TopBar/epics/languages/selectors";
import type {
    ShutterstockSelectedImages,
    ShutterstockImages,
    ShutterstockDownloadsState, ShutterstockKindT,
} from "./types";
import type { AppStateSel } from "../../../globalTypes";
import type { SstockImageDownload, GetImagesQuery } from "../../../../dal/flowTypes";

const baseSel = (kind, path: string[] = []) => pathSelector([Lit.fileChooser, kind, ...path]);

export const
    shutterstockInitializedAppSel = (kind: ShutterstockKindT): AppStateSel<boolean> =>
        baseSel(kind, [Lit.initialized]),
    shutterstockImagesAppSel = (kind: ShutterstockKindT): AppStateSel<ShutterstockImages> =>
        baseSel(kind, [Lit.images]),
    shutterstockImagesSearchIdAppSel = (kind: ShutterstockKindT): AppStateSel<string> =>
        baseSel(kind, [Lit.imagesSearchId]),
    shutterstockQueryAppSel = (kind: ShutterstockKindT) => (appState: AppState): GetImagesQuery => {
        const
            shutterstock = baseSel(kind)(appState),
            filters = shutterstock[Lit.filters],
            currentLanguage = normalizedCurrentLangaugeAppSel(appState),
            query = {
                ...filters,
                [Lit.language]: currentLanguage,
                [Lit.search]: filters.search.value,
            };

        if (shutterstock[Lit.isTypeImages] !== shutterstock[Lit.isTypeIllustrations]) {
            query[Lit.imageType] = shutterstock[Lit.isTypeImages] ?
                SstockImageType.PHOTO : SstockImageType.ILLUSTRATION;
        }

        if (query[Lit.category] === AllCategoryId) {
            delete query[Lit.category];
        }

        if (shutterstock[Lit.isPortraitMode] !== shutterstock[Lit.isLandscapeMode]) {
            query[Lit.orientation] = shutterstock[Lit.isPortraitMode] ?
                SstockImageOrientation.VERTICAL : SstockImageOrientation.HORIZONTAL;
        }

        return query;
    },
    shutterstockSearchPageAppSel = (kind: ShutterstockKindT): AppStateSel<number> =>
        baseSel(kind, [Lit.filters, Lit.page]),
    shutterstockImagesPageLoadingAppSel = (kind: ShutterstockKindT): AppStateSel<boolean> =>
        baseSel(kind, [Lit.imagesPageLoading]),
    shutterstockImagesLoadingAppSel = (kind: ShutterstockKindT): AppStateSel<boolean> =>
        baseSel(kind, [Lit.imagesLoading]),
    shutterstockDownloadedImagesLoadingAppSel = (kind: ShutterstockKindT): AppStateSel<boolean> =>
        baseSel(kind, [Lit.downloadsLoading]),
    shutterstockDownloadsAppSel = (kind: ShutterstockKindT): AppStateSel<ShutterstockDownloadsState> =>
        baseSel(kind, [Lit.downloads]),
    shutterstockSelectedImagesAppSel = (kind: ShutterstockKindT): AppStateSel<ShutterstockSelectedImages> =>
        baseSel(kind, [Lit.selectedImages]),
    shutterstockSelectedImagesIdsAppSel = (kind: ShutterstockKindT): AppStateSel<Array<string>> =>
        (appState: AppState) => {
            const selectedImages = shutterstockSelectedImagesAppSel(kind)(appState);
            return selectedImages.map(({ imageId }) => imageId);
        },
    shutterstockPartialDownloadsAppSel = (kind: ShutterstockKindT): AppStateSel<Array<SstockImageDownload>> =>
        baseSel(kind, [Lit.partialDownloads]),
    shutterstockFailedPartialDownloadsAppSel = (kind: ShutterstockKindT) =>
        (appState: AppState): Array<SstockImageDownload> =>
            shutterstockPartialDownloadsAppSel(kind)(appState).filter(d => !d.ok),
    shutterstockSuccessfulPartialDownloadsAppSel = (kind: ShutterstockKindT) =>
        (appState: AppState): Array<SstockImageDownload> =>
            shutterstockPartialDownloadsAppSel(kind)(appState).filter(d => d.ok),
    shutterstockSkipActionPostDownloadAppSel = (kind: ShutterstockKindT): AppStateSel<boolean> =>
        baseSel(kind, [Lit.skipComponentActionPostDownload]);
