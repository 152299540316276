import cx from 'classnames';
import getCenteredDialog from "../../../DialogManager/getCenteredDialogConfig";
import { configurationDialogFactory } from '../configurationDialogFactory';
import { DialogWidth, VimeoDialogHeight } from '../constants';
import { WIDGETS_VIMEO_SET_LINK } from "./actions";
import { validateLinkOrCode } from '../utils';
import { isVimeoLinkValid, parseVimeoCode } from './utils';
import Icons from '../view/Icons.css';

const validateVimeoData = validateLinkOrCode([isVimeoLinkValid], parseVimeoCode);

const
    configurationDialogId = "WIDGETS_VIMEO_CONFIG_DIALOG",
    configurationDialogView = configurationDialogFactory({
        saveAction: WIDGETS_VIMEO_SET_LINK,
        dialogIconClass: cx(Icons.vimeo, Icons.dialog),
        dialogTitle: "msg: widgets.vimeo.label {Vimeo}",
        info: "msg: widgets.vimeo.config.addVimeo {Add a Vimeo video widget to your page.}",
        subInfo: "msg: widgets.vimeo.config.info {In Vimeo, copy the URL from the address bar or click 'share' to copy the link and paste it below.}", // eslint-disable-line max-len
        learnMore: "msg: widgets.vimeo.config.learnMore {https://help.one.com/hc/en-us/articles/9337754918929}",
        inputPlaceHolder: "msg: widgets.vimeo.configDialog.placeHolder {Paste Vimeo link}",
        isLinkValid: validateVimeoData,
        termsOfService: "msg: widgets.vimeo.config.termsOfService {By using this widget, you agree to Vimeo\'s {termsOfServiceLink}.}",
        termsOfServiceLink: "msg: widgets.vimeo.config.termsOfServiceLink {https://vimeo.com/terms}",
    }),
    configurationDialog = getCenteredDialog({
        width: DialogWidth,
        height: VimeoDialogHeight,
        component: configurationDialogView,
    });

export { configurationDialogId, configurationDialog };

