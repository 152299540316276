import { parseDOM } from "../../../../../utils/parseDOM";
import { getFacebookPostType } from './parseFacebookCode';
import { type FacebookComponent } from '../types';

export const parseFacebookPostCode = (code: string): FacebookComponent => {
    let params = <FacebookComponent>{};
    const configs = [
        {
            nodeName: 'IFRAME',
            validator: node => node.src.startsWith("https://www.facebook.com/plugins/"),
            parser: node => {
                const
                    src = node.src,
                    link = src.split('href=')[1],
                    postType = getFacebookPostType(link);

                return { link, postType };
            }
        }
    ];

    params = parseDOM(configs, code);

    return params;
};
