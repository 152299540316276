import * as React from "react";
import { injectIntl, useIntl as useReactIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';
import type { Opts } from "react-intl/src/components/injectIntl";
import dayjs from "../../dayjs";
import { normalizeLocale } from "../../../../server/shared/utils/normalizeLocale";
import { memoMaxOne } from '../../../utils/memo';
import { isString } from '../../../utils/string';
import { parseMsgJoint } from "../../../../server/shared/utils/parseMsgJoint";
import { isSingleTrialSingupEnv } from "../../../utils/isSingleTrialSingupEnv";

export type MsgJointInput = string | [string, AnyMap];
type Params = Record<string, string>;

type DateTimeFormatOptions = {
    localeMatcher?: 'best fit' | 'lookup', // default: 'best fit'
    formatMatcher?: 'basic' | 'best fit', // default: 'best fit'

    timeZone?: string,
    hour12?: boolean,

    weekday?: 'narrow' | 'short' | 'long',
    era?: 'narrow' | 'short' | 'long',
    year?: 'numeric' | '2-digit',
    month?: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long',
    day?: 'numeric' | '2-digit',
    hour?: 'numeric' | '2-digit',
    minute?: 'numeric' | '2-digit',
    second?: 'numeric' | '2-digit',
    timeZoneName?: 'short' | 'long',
};

type DateTimeValue = number | Date;

type NumberFormatOptions = {
    localeMatcher: 'best fit' | 'lookup', // default: 'best fit'

    style: 'decimal' | 'currency' | 'percent', // default: 'decimal'

    currency: string,
    currencyDisplay: 'symbol' | 'code' | 'name', // default: 'symbol'

    useGrouping: boolean, // defaut: true,

    minimumIntegerDigits: number, // default: 1
    minimumFractionDigits: number,
    maximumFractionDigits: number,
    minimumSignificantDigits: number, // default: 1
    maximumSignificantDigits: number,
};

const DateDefaults = {
    weekday: 'short',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
} as const;

const TimeDefaults = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
} as const;

const isMsgJointInput = (input: AnyValue): input is MsgJointInput =>
    (isString(input) && input.indexOf('msg:') === 0)
    || (
        Array.isArray(input) && input.length === 2
        && isString(input[0]) && input[0].indexOf('msg:') === 0
    );

class OneIntl {
    locale!: string;

    get normalizedLocale() {
        return normalizeLocale(this.locale);
    }

    msg(k?: string, defaultMsg?: string, params?: Params): string {
        return (this as unknown as IntlShape).formatMessage({ id: k, defaultMessage: defaultMsg }, params);
    }
    // eslint-disable-next-line
    parseMsg(input: MsgJointInput): [string, string, AnyMap] { // eslint
        return parseMsgJoint(input);
    }

    /**
     * To be used when passing 'joint' msg from outer components.
     */
    msgJoint(input: MsgJointInput): string {
        const
            [k, defaultMsg, inParams] = parseMsgJoint(input),
            params = inParams
                ? Object
                    .keys(inParams)
                    .reduce((acc, k) => Object.assign(acc, {
                        [k]: isMsgJointInput(inParams[k]) ? this.msgJoint(inParams[k]) : inParams[k],
                    }), {})
                : undefined;

        return this.msg.apply(this, [k, defaultMsg, params]);
    }

    msgJointFormat(format: string, messages: Array<MsgJointInput>): string {
        // @ts-ignore
        return messages.reduce((acc, msg) => acc.replace('%m', this.msgJoint(msg)), format);
    }

    // Looks like this method is not used anywhere and does not exist on IntlShape, please verify and remove it
    msgHtml(k: string, defaultMsg: string, params: Params = {}) {
        return (this as any).formatHTMLMessage({ id: k, defaultMessage: defaultMsg }, params);
    }

    /**
     * See https://day.js.org/docs/en/display/format for format patterns.
     */
    date(value: DateTimeValue, format?: string) { // eslint-disable-line class-methods-use-this
        if (isSingleTrialSingupEnv()) {
            throw new Error('intl.date() not supported in single trial signup');
        } else {
            return dayjs(value).format(format);
        }
    }

    /**
     * @see: https://github.com/yahoo/react-intl/wiki/API#formattime
     */
    time(value: DateTimeValue, options: DateTimeFormatOptions = {}) {
        return (this as unknown as IntlShape).formatTime(value, { ...TimeDefaults, ...options });
    }

    dateTime(value: DateTimeValue, options: DateTimeFormatOptions = {}) {
        return (this as unknown as IntlShape).formatDate(value, { ...DateDefaults, ...TimeDefaults, ...options });
    }

    /**
     * @see: https://github.com/yahoo/react-intl/wiki/API#formatnumber
     */
    number(value: number, options?: Partial<NumberFormatOptions>) {
        return (this as unknown as IntlShape).formatNumber(value, options);
    }

    currency(number: number, currency: string) {
        return this.number(number, {
            style: 'currency',
            currency,
        });
    }
}

let intlInstance: null | OneIntl = null;

type ExtendIntlFunction = (intl: IntlShape) => OneIntl;

const extendIntl: ExtendIntlFunction = memoMaxOne((intl: IntlShape) => {
    Object.assign(OneIntl.prototype, intl);
    intlInstance = new OneIntl();
    return intlInstance;
});

const useOneIntl = () => {
    return extendIntl(useReactIntl());
};

/**
 * @deprecated
 * Intl-Instance is already intialized by the root component.
 * Root component is calling oneInjectIntl.
 * Use this method only outside React components.
 * UseIntl will be used widely in the application.
 */
const getIntl = () => {
    return intlInstance;
};

type IntlType = {
    intl: IntlShape;
};

function oneInjectIntl<Props = any>(
    Component: React.ComponentType<Props> | React.ComponentClass<Props, any>,
    options: Opts = { forwardRef: false }
) {
    const IntlWrapComponent = React.forwardRef((props: Props & IntlType, ref: React.Ref<any>) => (
        <Component
            {...props}
            intl={extendIntl(props.intl)}
            ref={ref}
        />
    ));

    return injectIntl(IntlWrapComponent, options);
}

const resolveMsgJoint = (msg: MsgJointInput): [string] | MsgJointInput => {
    if (typeof msg === 'string') {
        return [msg];
    }
    return msg;
};

export const getDefaultMsg = (input: MsgJointInput): string => parseMsgJoint(input)[1];

export {
    oneInjectIntl as default,
    useOneIntl as useIntl,
    getIntl,
    OneIntl as Intl,
    resolveMsgJoint,
    isMsgJointInput,
};
