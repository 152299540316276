import { startsWith, values } from 'ramda';
import type { LinkChooserLinkType } from "../flowTypes";
import { LcTabName, SUPPORTED_EXTERNAL_URL_SCHEMES } from "../constants";
import Resource from "../../../../../redux/modules/children/fileChooser/Resource";
import { parseMailTo } from "./parseMailTo";
import { DataSite } from "../../../../../../dal/model/index";
import { BROKEN_LINK } from "../../../../../components/oneweb/Text/constants";
import { JS_VOID_VALUE, JS_VOID_PATTERN, JS_PROTOCOL_PATTERN } from "../../../../../constants";
import { isTelUrl, parseTelUrlPhone } from '../../../../../utils/TelProtocol';

export const absoluteUrlToInputLinkOld = (url: string, site: DataSite): LinkChooserLinkType => {
    let type, value;

    if (startsWith('/api', url) && url.includes('/preview')) { // page case
        const pageId = url.split('/').slice(-1)[0].split('.')[0];
        const page = site.getOptionalPageRefByPageId(pageId);
        value = page ? page.id : null;
        type = LcTabName.PAGE;
    } else if (startsWith('/api', url) && url.includes('/webspace')) { // file case
        value = Resource.fromWebspaceUrl(url);
        type = LcTabName.FILE;
    } else if (startsWith('http', url)) { // url case
        value = url;
        type = LcTabName.URL;
    } else if (url === BROKEN_LINK || JS_VOID_PATTERN.test(url)) { // text broken link case
        value = JS_VOID_VALUE;
        type = LcTabName.URL;
    } else if (JS_PROTOCOL_PATTERN.test(url)) { // javascript: protocol
        value = url;
        type = LcTabName.URL;
    } else if (isTelUrl(url)) { // phone numbers
        value = parseTelUrlPhone(url);
        type = LcTabName.PHONE;
    } else if (startsWith('mailto:', url)) { // email
        const parsedEmail = parseMailTo(url);
        value = {
            selection: parsedEmail.email,
            subject: parsedEmail.subject,
        };
        type = LcTabName.EMAIL;
    } else if (startsWith('location:', url)) { // location
        const [selection, encodedName] = url.replace('location:', '').split('##');
        value = { selection, name: decodeURIComponent(encodedName) };
        type = LcTabName.LOCATION;
    } else if (startsWith('sectionLink:', url)) { // email
        const parsedvalue = url.split(':');
        value = {
            pageId: parsedvalue[1],
            sectionId: parsedvalue[2],
        };
        type = LcTabName.SECTION_LINK;
    } else { // eslint-disable-line
        throw new Error('Cannot parse url into link chooser input');
    }

    return { type, value };
};

// TODO: why do we need this second function ?
export const absoluteUrlToInputLink = (url: null | undefined | string): LinkChooserLinkType => {
    if (url === '#addressUrl') return { type: LcTabName.URL, value: '#addressUrl' }; // TODO replace '#addressUrl' with variable
    let [, linkType, linkUrl] = (url || '').match(/(.*?):(.*)/) || [];  //NOSONAR
    const brokenInputLink = { type: LcTabName.PAGE, value: BROKEN_LINK };

    if (linkType === LcTabName.FILE) {
        const [basePath, href] = linkUrl.split(',');
        if (href) {
            // @ts-ignore
            linkUrl = new Resource(basePath, { href });
        } else {
            return brokenInputLink;
        }
    }

    if (linkType === LcTabName.EMAIL) {
        const { email: selection, subject } = parseMailTo(`mailto:${linkUrl}`);

        return {
            type: LcTabName.EMAIL,
            value: {
                selection,
                subject,
            },
        };
    } else if (linkType === LcTabName.LOCATION) {
        const [selection, uriEncodedName] = linkUrl.split('##');
        return { type: LcTabName.LOCATION, value: { selection, name: decodeURIComponent(uriEncodedName) } };
    } else if (linkType === LcTabName.SECTION_LINK) {
        const parsedvalue = linkUrl.split(':');
        const value = {
            pageId: parsedvalue[0],
            sectionId: parsedvalue[1],
        };

        return {
            type: LcTabName.SECTION_LINK,
            value
        };
    } else if (values(LcTabName).includes(linkType)) {
        // @ts-ignore
        return { type: linkType, value: linkUrl };
    } else if (SUPPORTED_EXTERNAL_URL_SCHEMES.includes(linkType)) { // LcTabName = url; type = http|https|javascript;
        return { type: LcTabName.URL, value: url };
    } else {
        return brokenInputLink;
    }
};
