import { log } from '../../../utils/log';
import type { AnyReducer } from "./flowTypes";

export default function (...reducers: Array<AnyReducer>) {
    reducers.forEach(reducer => {
        if (typeof reducer !== 'function') {
            throw Error('reducer should be a function');
        }
    });

    return (previousState: AnyValue, action: Action) =>
        reducers.reduce(
            (previous, reducer) => {
                const next = reducer(previous, action);
                if (typeof next === 'function') {
                    log(
                        'Reducer', reducer,
                        '\nreturned function as state. prev state:', previous,
                        '\naction: ', action
                    );
                    throw new Error(`Reducer retured function as state for action: ${action.type}`);
                }
                return next;
            },
            previousState
        );
}
