import * as actionTypes from "./actionTypes";
import { replaceWithCreateListingDialog, replaceConnectHandlerDialogs } from "./actionCreators";
import type {
    BusinessListingServiceScopeType,
    BusinessListingServiceDefaultStateType,
} from "../flowTypes";

const
    openCreateYourListingDialogActionUpdater = ({ state, scope }: {
        state: BusinessListingServiceDefaultStateType, scope: BusinessListingServiceScopeType
    }) => {
        return {
            state: { ...state, isWaitingForApi: false, isConnected: false },
            scope,
            actionToDispatch: replaceWithCreateListingDialog()
        };
    },
    gmbGetLocationsFailureUpdater = ({ state, scope }: {
        state: BusinessListingServiceDefaultStateType, scope: BusinessListingServiceScopeType
    }) => {
        if (state.onGoingAction === actionTypes.CREATE_YOUR_LISTING) {
            return openCreateYourListingDialogActionUpdater({ state, scope });
        }
        return {
            state: { ...state, isWaitingForApi: false, isConnected: false },
            scope,
            actionToDispatch: replaceConnectHandlerDialogs({ actionType: actionTypes.CONNECT_FAILURE })
        };
    };

export {
    gmbGetLocationsFailureUpdater,
    openCreateYourListingDialogActionUpdater,
};
