import type { SideBarAccordianDef } from '../../SideBar/types';
import { OnlinePresenceSideBarItem } from '../OnlinePresence';
import { SocialShareSideBarItem } from '../SocialShare';
import { GoogleAdsSideBarItem } from '../GoogleAds';
import { BusinessListingsSideBarItem } from '../BusinessListing';
import { MarketgooSideBarItem } from '../Marketgoo';

import imageStyles from '../../Images/imageStyle.css';

export const MARKETING_AND_SEO_PAGE_SIDE_BAR_ITEM_ID = 'MARKETING_AND_SEO_PAGE_SIDE_BAR_ITEM_ID';

export const MarketingAndSEOSideBarItem: SideBarAccordianDef = {
    id: MARKETING_AND_SEO_PAGE_SIDE_BAR_ITEM_ID,
    icon: imageStyles.marketing,
    title: 'msg: marketingandseo {Marketing & SEO}',
    subItems: [
        OnlinePresenceSideBarItem,
        MarketgooSideBarItem,
        GoogleAdsSideBarItem,
        SocialShareSideBarItem,
        BusinessListingsSideBarItem,
    ],
};
