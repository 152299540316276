import { setComponentsMap } from "../../Workspace/epics/componentsEval/setters";
import { SUPPORT_DELETE_SELECTED_CONTAINER_ONLY } from "./actions";
import {
    ReceiveOnlySelectedComponentSelector
} from "../../Workspace/epics/componentsEval/selectorActionTypes";
import { isContainerKind } from "../../../convertToSections/util";
import { isSectionKind } from "../../oneweb/componentKinds";
import { updateComponentsRelIns } from "../../Workspace/epics/relations/updateComponentsRelIns";
import { ReceiveOnlyTemplateWidthActionType } from "../../oneweb/Template/epics/template/selectorActionTypes";
import * as workspaceActionTypes from "../../Workspace/actionTypes";

export const deleteContainerOnlyUpdater = {
    conditions: [
        ReceiveOnlySelectedComponentSelector,
        ReceiveOnlyTemplateWidthActionType,
        SUPPORT_DELETE_SELECTED_CONTAINER_ONLY
    ],
    reducer: ({ values: [selectedComponent, templateWidth], state: epicState }) => {
        if (selectedComponent) {
            const { kind, id: selectedComponentId } = selectedComponent,
                isBoxOrStrip = !isSectionKind(kind) && isContainerKind(kind);

            if (isBoxOrStrip) {
                let { state: { componentsMap } } = epicState,
                    newComponentsMap = { ...componentsMap };
                delete newComponentsMap[selectedComponentId];
                newComponentsMap = updateComponentsRelIns(newComponentsMap, templateWidth);
                const componentIdsToBeDeleted: Array<string> = [selectedComponentId];
                return {
                    state: setComponentsMap(newComponentsMap, epicState),
                    actionToDispatch: {
                        type: workspaceActionTypes.COMPONENTS_DELETED,
                        payload: componentIdsToBeDeleted
                    },
                    updateReason: SUPPORT_DELETE_SELECTED_CONTAINER_ONLY
                };
            }
        }
        return {
            state: epicState
        };
    }
};
