import { $PropertyType } from "utility-types";
import formats from "../formats";
import type { Color, ColorPickerColor, HSLColorCode } from "../../../mappers/flowTypes";

const rotation = 360;

const toColorPicker = (color: Color): HSLColorCode => {
    const [type] = color;

    if (type === formats.HSL) {
        const [, h, s, l, a] = color;
        return { h: h * rotation, s, l, a };
    }

    throw new Error('Unknown color type: ' + type);
};

const isHSLFormat = (color: any): boolean => Array.isArray(color) && color[0] === formats.HSL;

type HSLType = { hsl: $PropertyType<ColorPickerColor, 'hsl'> };
const fromColorPicker = (color: HSLType): Color => {
    if (color.hsl) {
        const { hsl: { h, s, l, a } } = color;
        return [formats.HSL, h / rotation, s, l, a];
    }

    throw new Error('Unable to convert from ColorPicker color ^^^');
};

export {
    toColorPicker,
    fromColorPicker,
    isHSLFormat
};
