import type { PropertiesPanelState } from "../flowTypes";
import type { InstancePropertiesPanelStateShape } from "./types";
import { setToPath } from "../../../utils/ramdaEx";
import { Lit } from "../../../lit";

export const setInstancePropertiesPanelState = (
    instanceState: InstancePropertiesPanelStateShape,
    state: PropertiesPanelState,
): PropertiesPanelState => setToPath(
    [Lit.instance, Lit.state],
    instanceState,
    state,
);
