import React, { CSSProperties } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import type { AppState } from '../../redux/modules/flowTypes';
import { getDialogFromRegistry } from './registry';
import {
    MOUSE_DOWN_ON_DRAGGABLE_AREA_OF_DIALOG
} from './actionTypes';
import { ZIndex, background, container, draggable, show as showClassName } from './DialogManager.css';
import type { DialogManagerRegistryRecord } from "./flowTypes";
import { mouseDownOnDialogAC } from "./actionCreators";
import { dialogManagerStateFromAppStateSelector } from "./epic/selectors";

type State = {
    showBackground: boolean,
};

class DialogsList extends React.Component<any, State> {
    state: State = {
        showBackground: false,
    };

    componentDidUpdate() {
        const dialogsAreShown = this.props.state.computed.renderProps.length;
        if (dialogsAreShown && !this.state.showBackground) {
            setTimeout(() => this.setState({ showBackground: true }));
        } else if (!dialogsAreShown && this.state.showBackground) {
            this.setState({ showBackground: false }); // eslint-disable-line react/no-did-update-set-state
        }
    }

    render() {
        const {
            onMouseDown,
            onMouseDownOfDraggableArea,
            state: { isDragging, computed: { renderProps, topMostModalIndex } },
            dispatch,
        } = this.props;

        const atLeastOneNonHeadlesDialogOpened = renderProps.filter(p => !p.headlessMode).length > 0;

        return (
            <div
                onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => onMouseDown(e)}
            >
                {renderProps.map((currentDialogRenderProps, index): AnyReactComponent => {
                    const
                        {
                            id,
                            position,
                            dimensions,
                            dragEnabled,
                            dialogClassName,
                            dialogBackgroundClassName,
                            forceModal,
                            onModalClickAction,
                            draggableClassName,
                            enableBackground,
                            ...rest
                        } = currentDialogRenderProps,
                        zIndex = (+ZIndex) + index,
                        width = dimensions.width + 'px',
                        height = dimensions.height + 'px',
                        minHeight = (dimensions.minHeight || dimensions.minHeight === 0) ?
                            dimensions.minHeight + 'px' : undefined,
                        { component: Display }: DialogManagerRegistryRecord = getDialogFromRegistry(id);

                    let styleDimensions: CSSProperties = { width };
                    styleDimensions = minHeight ?
                        { ...styleDimensions, minHeight } : { ...styleDimensions, height };
                    const backgroundProps = {
                        onClick: onModalClickAction ? () => dispatch(onModalClickAction) : null,
                        style: { zIndex },
                        className: cx(
                            background,
                            dialogBackgroundClassName,
                            {
                                [showClassName]: this.state.showBackground,
                            },
                        ),
                    };

                    // @ts-ignore
                    return (
                        <div key={index}>
                            {(topMostModalIndex === index || forceModal)
                                && atLeastOneNonHeadlesDialogOpened
                                && !enableBackground
                                // @ts-ignore
                                && <div {...backgroundProps} />}
                            <div
                                className={cx(container, dialogClassName)}
                                style={{
                                    zIndex,
                                    ...position,
                                    ...styleDimensions,
                                    display: currentDialogRenderProps.headlessMode ? 'none' : 'flex'
                                }}
                            >
                                {/* TODO Display should receive only finalProps, state is used to compute finalProps */}
                                {
                                    // @ts-ignore
                                    <Display
                                        dimensions={dimensions}
                                        isDragging={isDragging}
                                        {...rest}
                                        dispatch={dispatch}
                                    />
                                }
                                {dragEnabled && <div
                                    className={cx(draggableClassName, draggable)}
                                    onMouseDown={
                                        (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => onMouseDownOfDraggableArea(e, id)
                                    }
                                />}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

function mapStateToProps(appState: AppState) {
    return {
        state: dialogManagerStateFromAppStateSelector(appState)
    };
}

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        onMouseDownOfDraggableArea: (e: MouseEvent, id: string) => {
            e.stopPropagation();
            dispatch({
                type: MOUSE_DOWN_ON_DRAGGABLE_AREA_OF_DIALOG,
                payload: { id, x: e.clientX, y: e.clientY }
            });
        },
        onMouseDown: (e: MouseEvent) => {
            e.stopPropagation();
            dispatch(mouseDownOnDialogAC(e.clientX, e.clientY));
        },
        dispatch
    };
}

export const DialogManager = connect(mapStateToProps, mapDispatchToProps)(DialogsList);
