import type { AppStateSel } from '../../globalTypes';
import type { PreferencesState } from './types';
import { pathSelector } from '../../utils/pathSelector';
import { Lit } from '../../lit';
import { AppState } from '../../redux/modules/flowTypes';

export const
    preferencesAppSel: AppStateSel<PreferencesState> = pathSelector([Lit.preferences]),
    preferencesSelector = (state: AppState) => state.preferences,
    preferencesSettingAppSel = (settingName: string): AppStateSel<AnyValue> =>
        pathSelector([Lit.preferences, settingName]);
