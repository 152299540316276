import React from 'react';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import ComboBox from '../../../../../view/common/Combobox/IntlCombobox';
import * as pageIds from '../pageIds';
import * as actionTypes from '../../actionTypes';
import pageTitles from '../pageTitles';
import { fwdBackOptions, indicatorOptions } from '../constants';
import Page from "../../../../PropertiesPanel/view/PropertiesPage";
import PropertiesCheckBox from '../../../../../view/common/CheckBox/PropertiesCheckBox';
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';
import type { PageProps } from '../../../../PropertiesPanel/flowTypes';
import type { ImageSliderComponent } from '../../flowTypes';

const
    id = pageIds.CONTROLS,
    title = pageTitles[id],
    view = ({ selectedComponent: { autoNext, navigator, indicator }, dispatch }: PageProps<ImageSliderComponent>) => (
        <Page>
            <PropertiesCheckBox
                label="msg: component.imageSlider.pp.automaticImageShifts {Shift images automatically}"
                isChecked={autoNext}
                onClick={() => dispatch({ type: actionTypes.IMAGE_SLIDER_TRANSITION_TOGGLE_SHOW_NEXT_IMAGE_METHOD })}
            />
            <VerticalSpacer />
            <PropertyContainer label="msg: component.imageSlider.pp.controls.fwdBack {Navigation arrows}">
                <ComboBox
                    searchable={false}
                    value={navigator}
                    options={fwdBackOptions}
                    onChange={({ value }) => dispatch({
                        type: actionTypes.IMAGE_SLIDER_CONTROLS_SET_FWD_BK_METHOD_CHANGED,
                        payload: value
                    })}
                />
            </PropertyContainer>
            <VerticalSpacer />
            <PropertyContainer label="msg: component.imageSlider.pp.controls.indicator {Indicator:}">
                <ComboBox
                    searchable={false}
                    value={indicator}
                    options={indicatorOptions}
                    onChange={({ value }) => dispatch({
                        type: actionTypes.IMAGE_SLIDER_CONTROLS_SHOW_INDICATOR_METHOD_CHANGED,
                        payload: value
                    })}
                />
            </PropertyContainer>
        </Page>
    );

export { id, title, view };
