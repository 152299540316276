
import type {
    CreateYourListingBusinessInformationStep,
    CreateYourListingContactDetailsStep,
    CreateYourListingOpeningHoursStep,
    errorDataType
} from "../flowTypes";
import { customSendReport } from "../../../../customSendCrashReport";
import {
    PRIMARY_PHONE,
    ADMINISTRATIVE_AREA_REQUIRED,
    ADMINISTRATIVE_AREA_INVALID,
    POSTAL_CODE,
    LATLNG,
    ADDRESS,
    STREET_ADDRESS,
    PROVINCE,
    LOCALITY,
    LOCALITY_NOT_REQUIRED,
    LOCALITY_REQUIRED,
} from "./Mappers/gmbErrorFields";
import {
    PROVINCE_REQUIRED,
    INVALID_PROVINCE,
    INVALID_POSTAL_CODE,
    INVALID_LATLNG,
    OOPS_SOMETHING_WENT_WRONG,
    LOCALITY_NOT_REQUIRED_MSG,
    LOCALITY_REQUIRED_MSG,
} from "./Mappers/gmbErrorMessages";
import { TEL_INVALID_MSG } from "../../../inputControls/input/commonValidations/telInputValidation";
import { classifyAddressErrors } from "../../General/utils";

export const
    gmbDoNotShowPublishDialogLocalStorageKey = 'gmbDoNotShowPublishDialog',
    CREATE_YOUR_LISTING_BUSINESS_INFORMATION_STEP: CreateYourListingBusinessInformationStep =
        'CREATE_YOUR_LISTING_BUSINESS_INFORMATION_STEP',
    CREATE_YOUR_LISTING_CONTACT_DETAILS_STEP: CreateYourListingContactDetailsStep =
        'CREATE_YOUR_LISTING_CONTACT_DETAILS_STEP',
    CREATE_YOUR_LISTING_OPENING_HOURS_STEP: CreateYourListingOpeningHoursStep =
        'CREATE_YOUR_LISTING_OPENING_HOURS_STEP';

export const errorDataDefault: errorDataType = ['', null, ''];

export const parseGmbValidateError = (response): errorDataType => {
    let phoneError = '', addressError, generalError = '';
    try {
        const errDetailsList = response.createLocationOutput.error.details;
        errDetailsList.forEach((errDetail) => {
            const
                errField = errDetail.metadata.field_mask, // NEW-CODE
                // errField = errDetail.errorDetails[0].field, // OLD-CODE
                errMessage = `${errDetail.reason} ${errDetail.value ? errDetail.value : ''}`, // NEW-CODE
                // errMessage = `${errDetail.errorDetails[0].message} ${errDetail.errorDetails[0].value ? errDetail.errorDetails[0].value : ''}`, // OLD-CODE
                errCode = 0; // NEW-CODE
                // errCode = errDetail.errorDetails[0].code; // OLD-CODE
            switch (classifyAddressErrors(errField, errMessage, errCode)) {
                case PRIMARY_PHONE:
                    phoneError = TEL_INVALID_MSG;
                    break;
                // Assuming that at a time only one addressError will occur. Can be changed in future.
                case ADMINISTRATIVE_AREA_REQUIRED:
                    addressError = { field: PROVINCE, msg: PROVINCE_REQUIRED };
                    break;
                case ADMINISTRATIVE_AREA_INVALID:
                    addressError = { field: PROVINCE, msg: INVALID_PROVINCE };
                    break;
                case POSTAL_CODE:
                    addressError = { field: POSTAL_CODE, msg: INVALID_POSTAL_CODE };
                    break;
                case LATLNG:
                    addressError = { field: STREET_ADDRESS, msg: INVALID_LATLNG };
                    break;
                case STREET_ADDRESS:
                    addressError = { field: STREET_ADDRESS, msg: INVALID_LATLNG }; // Msg can be changed later if needed
                    break;
                case LOCALITY_NOT_REQUIRED:
                    addressError = { field: LOCALITY, msg: LOCALITY_NOT_REQUIRED_MSG };
                    break;
                case LOCALITY_REQUIRED:
                    addressError = { field: LOCALITY, msg: LOCALITY_REQUIRED_MSG };
                    break;
                case ADDRESS:
                    addressError = { field: PROVINCE, msg: OOPS_SOMETHING_WENT_WRONG };
                    customSendReport({
                        message: "GOOGLE_MY_BUSINESS_VALIDATION_ERROR_ADDRESS",
                        additionalInfo: { errField, errMessage, locationOutput: response.createLocationOutput }
                    });
                    break;
                default:
                    generalError = OOPS_SOMETHING_WENT_WRONG;
                    customSendReport({
                        message: "GOOGLE_MY_BUSINESS_VALIDATION_ERROR_GENERAL",
                        additionalInfo: { errField, errMessage, locationOutput: response.createLocationOutput }
                    });
            }
        });
    } catch (e) {
        customSendReport({
            message: "GOOGLE_MY_BUSINESS_VALIDATION_ERROR_UNEXPECTED",
            additionalInfo: { response }
        });
    }
    return [phoneError, addressError, generalError];
};
