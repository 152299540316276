import * as R from "ramda";
import makeEpic from "../../../epics/makeEpic";
import { when } from "../../../epics/makeCondition";
import valueActionType from "./valueActionType";
import { getAllDocsWithComponentWrappedInTable } from "./getAllDocsWithComponentWrappedInTable";
import { CHECK_COMPONENT_WRAP_TABLE_SUCCESS } from "./actionTypes";
import { openDialog } from "../../App/actionCreators/index";
import ComponentWrappedInTableDetectedWarningDialog from "../dialog/id";
import { STOP_QUICK_TOUR } from "../../QuickTour/actionTypes";
import { SHOW_WELCOME_MESSAGE, HIDE_WELCOME_MESSAGE } from "../../WelcomeMessage/actionTypes";
import { WORKSPACE_READY } from "../../Workspace/actionTypes";
import { addChatTagWSBTableEjectsComponent } from "../../TopBar/epics/chat/actionCreators";
import { chatTags } from "../../TopBar/epics/chat/constants";
import UserDataMigrationEpicVAT from "../../UserDataMigration/epic/valueActionType";
import { UserDataMigrationStatus } from "../../UserDataMigration/epic/constants";

/**
 * IMPORTANT: One of the other possiblity is that a template might have a table with wrapped componeent,
 *            but it is not being used by any page currently. And later the user can use the same template
 *            for any other page. For handling such case, switch template success action can be captured
 *            here and shown warning for the same.
 */

const
    getActionToDispatch = ({ userNotified, docs }) => {
        if (userNotified || docs.length === 0) {
            return null;
        }

        return openDialog(ComponentWrappedInTableDetectedWarningDialog, { docs });
    },
    showWarningDialogReducer = ({ state }) => ({
        state: R.evolve({
            userNotified: () => true
        })(state),
        actionToDispatch: getActionToDispatch(state)
    });

export default makeEpic({
    valueActionType,
    defaultState: {
        docs: [],
        userNotified: false,
        welcomeDialogShown: false
    },
    updaters: [
        {
            conditions: [
                when(
                    UserDataMigrationEpicVAT,
                    ({ payload: userDataMigrationState }) => (
                        userDataMigrationState === UserDataMigrationStatus.USER_DATA_MIGRATION_SUCCESSFUL ||
                        userDataMigrationState === UserDataMigrationStatus.NO_USER_DATA_MIGRATION_REQUIRED
                    )
                )
            ],
            reducer: ({ state }) => ({
                state,
                actionToDispatch: getAllDocsWithComponentWrappedInTable()
            })
        },
        {
            conditions: [
                CHECK_COMPONENT_WRAP_TABLE_SUCCESS,
                SHOW_WELCOME_MESSAGE
            ],
            reducer: ({ state, values: [response] }) => ({
                state: R.evolve({
                    docs: () => response.docs,
                    welcomeDialogShown: R.T
                }, state)
            })
        },
        {
            conditions: [STOP_QUICK_TOUR],
            reducer: showWarningDialogReducer
        },
        {
            conditions: [HIDE_WELCOME_MESSAGE],
            reducer: showWarningDialogReducer
        },
        {
            conditions: [
                CHECK_COMPONENT_WRAP_TABLE_SUCCESS,
                WORKSPACE_READY
            ],
            reducer: ({ state, values: [response] }) => {
                const updateState = R.evolve({
                    docs: () => response.docs
                }, state);

                if (!updateState.welcomeDialogShown) {
                    const updatedState = showWarningDialogReducer({ state: updateState });
                    let multipleActionsToDispatch: Action[] = [
                        // @ts-ignore
                        updatedState.actionToDispatch
                    ];
                    if (updateState.docs.length) {
                        multipleActionsToDispatch.push(
                            addChatTagWSBTableEjectsComponent(chatTags.WSB3_TABLE_EJECTS_COMPONENTS)
                        );
                    }
                    return {
                        state: updatedState.state,
                        multipleActionsToDispatch
                    };
                }

                return { state: updateState };
            }
        }
    ]
});
