import type { GetCurrentDataProps, SplitDataProps } from "../../flowTypes";

const getParentId = (data, cmpId) => Object.keys(data).filter(id => data[id].indexOf(cmpId) !== -1)[0];

export const getCurrentData = ({ data, mobileDownData, selectedCmpId }: GetCurrentDataProps) => {
    let
        parentId = getParentId(data, selectedCmpId),
        isMdData = false;
    if (!parentId) {
        isMdData = true;
        parentId = getParentId(mobileDownData, selectedCmpId);
    }
    return {
        isMdData,
        parentId,
        currentData: isMdData ? mobileDownData : data
    };
};

export default ({ data, wrappedCmpsMap, mdStartFromId, templateId }: SplitDataProps) => {
    let
        newData = data,
        mobileDownData = {};
    if (mdStartFromId) {
        newData = {
            ...data,
            [templateId]: data[templateId].slice()
        };
        mobileDownData[templateId] = newData[templateId].splice(data[templateId].indexOf(mdStartFromId));
        mobileDownData[templateId].forEach(id => {
            if (wrappedCmpsMap && wrappedCmpsMap[id]) {
                wrappedCmpsMap[id].forEach(wId => {
                    if (newData[wId]) {
                        mobileDownData[wId] = newData[wId].slice();
                        delete newData[wId];
                    }
                });
            }
            if (newData[id]) {
                mobileDownData[id] = newData[id].slice();
            }
            delete newData[id];
        });
    }
    return {
        data: newData,
        mobileDownData
    };
};
