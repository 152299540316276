import CALL_API from '../../../redux/middleware/api/CALL_API';
import * as Actions from '../actionTypes';
import type { ApiAction } from '../../../redux/middleware/api/flowTypes';

const checkPublishStatus = function (publicationId ?: number): ApiAction {
    return {
        [CALL_API]: {
            types: [
                Actions.CHECK_PUBLISH_STATUS_REQUEST,
                Actions.CHECK_PUBLISH_STATUS_SUCCESS,
                Actions.CHECK_PUBLISH_STATUS_FAILURE
            ],
            endpoint: 'checkPublishStatus',
            endpointParams: publicationId
        }
    };
};

export {
    checkPublishStatus as default,
    checkPublishStatus
};
