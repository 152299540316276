/* eslint-disable max-len */

// TODO: decouple image editor ? should be separate button

import React from 'react';
import { Flex } from '../../../view/reflexbox/index';
import HorizontalSpacer from "../../../view/common/HorizontalSpacer";
import Icons from "../../../view/Icons/index";
import { openImageChooserAction } from "./actions";
import { FileChooserButton } from "../../PropertiesPanel/view/components/FileChooserButton/FileChooserButton";
import allowImageEditor from "../../../view/common/dialogs/ImageEditorDialog/allowImageEditor";
import { editImageAC } from "../../ImageEditor/actionCreators";
import type { ImageAsset } from "../../App/flowTypes";
import type { FcContentTypePattern } from "../../../redux/modules/children/fileChooser/utils/testFcContentType";
import type { MsgJointInput } from "../../../view/intl/index";
import type { AppDispatch } from "../../../redux/modules/flowTypes";
import styles from "./ImageChooser.css";
import { VideoUtils } from '../../../utils/fileUtils';

const getLabelFromAsset = asset => asset.url.split('/').slice(-1)[0];

type Props = {
    onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>,
    asset: undefined | ImageAsset;
    changeAction: string,
    removeAction: string,
    headLabel?: MsgJointInput,
    chooseLabel: MsgJointInput,
    dispatch: AppDispatch,
    extraImageContentTypes?: FcContentTypePattern,
    headlessMode?: true,
    hideFreeOneComImagesTab?: boolean,
    hideShutterstockImagesTab?: true,
    hideMyVideosTab?: true,
    isImageEditorAllowed?: boolean,
    removeButtonTitle?: string,
    labelMaxWidth?: null | undefined | number,
    tabIndex?: number,
    className ?: string,
};

export const ImageChooserBtnPP = (props: Props) => {
    const
        {
            asset,
            changeAction,
            removeAction,
            removeButtonTitle,
            chooseLabel,
            headLabel,
            extraImageContentTypes,
            dispatch,
            headlessMode,
            hideFreeOneComImagesTab,
            hideShutterstockImagesTab,
            hideMyVideosTab,
            isImageEditorAllowed = allowImageEditor(),
            labelMaxWidth,
            tabIndex,
            onKeyDown,
            className
        } = props,
        fileChooserProps = {
            onKeyDown,
            filename: asset && getLabelFromAsset(asset),
            fileChangeAction: openImageChooserAction({
                onSaveAction: changeAction,
                isMultiSelect: false,
                forwardToComponent: true,
                extraImageContentTypes,
                headlessMode,
                hideFreeOneComImagesTab,
                hideShutterstockImagesTab,
                hideMyVideosTab,
            }),
            removeAction: asset ? { type: removeAction } : undefined,
            showAssetInfo: asset && !VideoUtils.isVideoFile(getLabelFromAsset(asset)),
            removeButtonTitle,
            chooseLabel,
            headLabel,
            dispatch,
            headlessMode,
            hideFreeOneComImagesTab,
            hideShutterstockImagesTab,
            hideMyVideosTab,
            labelMaxWidth,
            tabIndex,
            className
        };

    if (asset && !VideoUtils.isVideoFile(getLabelFromAsset(asset))) {
        return (
            <Flex align="flex-end" justify="flex-end">
                <div className={styles.fileChooser}><FileChooserButton {...fileChooserProps} /></div>
                {isImageEditorAllowed && <HorizontalSpacer />}
                {isImageEditorAllowed &&
                <Icons.EDIT
                    border
                    disabled={!asset}
                    className={styles.imageEdit}
                    style={{ fill: '#5181cf', width: '35px', height: '35px' }}
                    title="msg: component.image.edit {Edit image}"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        dispatch(editImageAC(changeAction, asset));
                    }}
                />}
            </Flex>
        );
    }

    return (<FileChooserButton {...fileChooserProps} />);
};
