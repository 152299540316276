export const
    CssObjectFit = {
        FILL: 'fill',
        CONTAIN: 'contain',
        COVER: 'cover',
        NONE: 'none',
        SCALE_DOWN: 'scale-down',
        UNSET: 'unset',
        INITIAL: 'initial'
    };
