import * as R from 'ramda';
import cx from 'classnames';
import * as layoutTypes from '../../constants/layoutType';
import styles from '../Menu.css';
import { layoutFactory } from './factory';
import * as selectors from '../../selectors';
import maybe from '../../../../../utils/maybe';
import * as actionTypes from '../../actionTypes';
import { DATA_PAGE_REF_TYPE } from '../../../../../../dal/model/utils/dataSiteItemTypes';
import type { CalcMenuItemStylingProps } from './flowTypes';
import type { LayoutRenderProps } from '../../flowTypes';
import FLUSH_INTERVAL from "../../../../../redux/middleware/adjustmentData/FLUSH_INTERVAL";
import { addComponentAdjustmentDataEntry } from "../../../../Workspace/epics/componentsEval/adjustmentDataDispatchCache";

type Predicate = (props: CalcMenuItemStylingProps) => boolean;
type ClassName = string;
type ClassNameOrNothing = ClassName | void;
type Rule = [Predicate, ClassName];
type RuleExecutor = (props: CalcMenuItemStylingProps) => ClassNameOrNothing;
type MakeRuleExecutors = (rules: Array<Rule>) => Array<RuleExecutor>;
type StylingRules = {
    a?: Array<Rule>;
    li?: Array<Rule>;
};

const emptyStyle = R.always({}),
    makeRuleExecutors: MakeRuleExecutors = R.map(([predicate, value]) => R.ifElse(predicate, R.always(value), R.always(null))),
    ruleExecutorsToClassNames = props => R.map((ruleExecutor: RuleExecutor) => ruleExecutor(props)),
    makeClassNameCalculator = props => R.pipe(maybe(makeRuleExecutors), maybe(ruleExecutorsToClassNames(props)), cx),
    getMenuItemStyling = ({ a, li }: StylingRules) => (props: CalcMenuItemStylingProps) => {
        const classNameCalculator = makeClassNameCalculator(props);
        return {
            a: {
                className: classNameCalculator(a)
            },
            li: {
                className: classNameCalculator(li)
            }
        };
    },
    hasChildPages = R.path(["page", "hasChildren"]),
    notFirstLevelPage = ({ level }) => level !== 0,
    expandableRule = [hasChildPages, "expandable"],
    isCurrentBranchPage = R.pipe(R.prop("page"), selectors.isCurrentPageBranch),
    notCurrentBranchPageOrChildOfIt = R.both(
        R.complement(isCurrentBranchPage),
        R.pipe(R.prop("parentPage"), maybe(R.complement(selectors.isCurrentPageBranch)))
    ),
    getDropDownMenuItemStyling = getMenuItemStyling({
        // @ts-ignore
        a: [expandableRule],
        li: [[notFirstLevelPage, "menuhidden"]]
    }),
    getTreeMenuItemStyling = getMenuItemStyling({
        // @ts-ignore
        a: [expandableRule, [R.both(isCurrentBranchPage, hasChildPages), "expanded"]],
        li: [[R.both(notFirstLevelPage, notCurrentBranchPageOrChildOfIt), styles.hidden]]
    }),
    onDimensionsChange = (dispatch, dimensions) => {
        if (dimensions.height === 0) {
            /* happens on first render */
            return;
        }

        dispatch({
            type: actionTypes.MENU_RENDER_DIMENSIONS_CHANGED,
            payload: dimensions
        });
    },
    makeOnMenuItemDimensionsChange = () => {
        /* Each menu item and divider will notify about it's own size change by calling this callback
         * We want to dispatch all items dimensions as single actions
         * So will wait BatchInterval ms after first callback triggered and than dispach all them at once */
        const recievedDimensionsByComponentId = {},
            BatchInterval = FLUSH_INTERVAL / 3;

        function flush(dispatch, componentId) {
            const cache = recievedDimensionsByComponentId[componentId];
            addComponentAdjustmentDataEntry(componentId, {
                itemsDimensions: [...cache.items]
            });
            cache.batchingInProgress = false;
        }

        return (dispatch, componentId, index, dimensions) => {
            let cache = recievedDimensionsByComponentId[componentId];

            if (!cache) {
                recievedDimensionsByComponentId[componentId] = {
                    batchingInProgress: false,
                    items: []
                };
                cache = recievedDimensionsByComponentId[componentId];
            }

            if (!cache.batchingInProgress) {
                cache.batchingInProgress = true;
                setTimeout(() => flush(dispatch, componentId), BatchInterval);
            }

            cache.items[index] = dimensions;
        };
    },
    makeSingleLevel = className => ({
        className,
        calcMenuItemStyling: emptyStyle,
        pagesSelector: selectors.singleLevel,
        onDimensionsChange
    }),
    makeDropDown = (className, onMenuItemDimensionsChange) => ({
        className: cx(className, "dropdown"),
        calcMenuItemStyling: getDropDownMenuItemStyling,
        pagesSelector: selectors.all,
        onDimensionsChange,
        onMenuItemDimensionsChange
    }),
    treeLayout = {
        className: cx("menuvertical", "tree"),
        calcMenuItemStyling: getTreeMenuItemStyling,
        pagesSelector: selectors.topLevelAndCurrentBranch,
        onDimensionsChange
    },
    _horizontalDropdown = layoutFactory(makeDropDown("menuhorizontal", makeOnMenuItemDimensionsChange())),
    hackPages = (props: LayoutRenderProps) => {
        const {
                componentExtension: { pagesToShow },
                pages
            } = props,
            finalPages = R.pipe(
                R.take(pagesToShow),
                R.append({
                    type: DATA_PAGE_REF_TYPE,
                    url: "",
                    isHome: false,
                    hidden: false,
                    public: true,
                    isCurrentPage: false,
                    hasChildren: true,
                    id: "more-id",
                    name: props.component.moreText,
                    pageId: "more-button-page-id",
                    items: R.drop(pagesToShow, pages)
                })
            )(pages);
        return { ...props, pages: finalPages };
    },
    horizontalDropdown = (props: LayoutRenderProps) => {
        const showMoreButton = R.path(["componentExtension", "showMoreButton"], props);
        return _horizontalDropdown(showMoreButton ? hackPages(props) : props);
    },
    layouts = {
        // @ts-ignore
        [layoutTypes.VERTICAL_SINGLE_LEVEL]: layoutFactory(makeSingleLevel("menuvertical")),
        // @ts-ignore
        [layoutTypes.VERTICAL_DROPDOWN]: layoutFactory(makeDropDown("menuvertical")),
        [layoutTypes.VERTICAL_TREE]: layoutFactory(treeLayout),
        // @ts-ignore
        [layoutTypes.HORIZONTAL_SINGLE_LEVEL]: layoutFactory(makeSingleLevel("menuhorizontal")),
        [layoutTypes.HORIZONTAL_DROPDOWN]: horizontalDropdown
    };

export default layouts;
