import React from "react";
import { connect } from 'react-redux';
import styles from './styles.css';
import toasterVAT from '../epic/valueActionType';
import type { AppState } from "../../../redux/modules/flowTypes";
import ToasterMessage from './toasterMessage';
import type { ToasterContainerProps } from "../flowTypes";

class ToasterContainer extends React.PureComponent<ToasterContainerProps> {
    render() {
        const { messages, dispatch } = this.props;
        return (
            <div className={styles.container}>
                {
                    Array.isArray(messages) &&
                    <ul>
                        {
                            messages.map((message, index) => (
                                <ToasterMessage
                                    key={`${message.timestamp || Date.now()}-${index}-${message.msgKey || ''}`}
                                    message={message}
                                    index={index}
                                    dispatch={dispatch}
                                />
                            ))
                        }
                    </ul>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ epics }: AppState): Omit<ToasterContainerProps, 'dispatch'> => ({
    messages: epics[toasterVAT].state.messages
});

export default connect(mapStateToProps)(ToasterContainer);
