import * as R from 'ramda';
import { withSelector } from '../../../epics/makeCondition';
import valueActionType from './valueActionType';

export const PropertiesPanelIsMoving = withSelector(valueActionType, ({ scope: { isMoving } }) => isMoving);
export const PropertiesPanelPosition = withSelector(valueActionType, ({ scope: { actualPosition } }) => actualPosition);

const navigationPath = ['state', 'navigation'];
const activePageIdSelector = R.converge(
    (sequence = [], activePageIndex) => sequence[activePageIndex],
    [
        R.path([...navigationPath, 'sequence']),
        R.path([...navigationPath, 'activePageIndex'])
    ]
);

export const PropertiesPanalActivePageIdSelector = withSelector(valueActionType, activePageIdSelector);
