import type { SideBarItemDef } from '../../SideBar/types';

import imageStyles from "../../Images/imageStyle.css";

export const SOCIAL_SHARE_PAGE_SIDE_BAR_ITEM_ID = 'SOCIAL_SHARE_PAGE_SIDE_BAR_ITEM_ID';

export const SocialShareSideBarItem: SideBarItemDef = {
    id: SOCIAL_SHARE_PAGE_SIDE_BAR_ITEM_ID,
    icon: imageStyles.socialShare,
    title: 'msg: common.socialShare {Social share}',
    header: {
        title: "msg: common.socialShare {Social share}",
        description: "msg: dashboard.socialShare.description {Choose which image is displayed when your site is shared on social media. The text that accompanies the image when shared can be changed in SEO settings.}"
    }
};
