import makeStyles from '@mui/styles/makeStyles';
import { extraThemeConfig } from '../../../../../../../app/theme';

export const useStyles = makeStyles(
    (theme) => ({
        root: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            [theme.breakpoints.only("xs")]: {
                alignItems: "stretch",
                "& > *:not(:first-child)": {
                    marginTop: "16px",
                },
            },
            [theme.breakpoints.up("sm")]: {
                flexDirection: "row",
                "& > *:not(:first-child)": {
                    marginLeft: "16px",
                },
            },
        },
        inputWrapper: {
            flex: 1,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            height: "40px",
            padding: "5px 19px",
            border: `1px solid ${theme.palette.custom.colorGray_8a}`,
            borderRadius: `${extraThemeConfig.borderRadius}px`,
            overflow: "hidden",
        },
        inputField: {
            flex: 1,
            fontSize: "14px",
            lineHeight: "22px",
            letterSpacing: "0.25px",
        },
        inputChip: {
            height: "30px",
            border: `1px solid ${theme.palette.custom.colorLinkWater}`,
            borderRadius: "4px",
            fontSize: "14px",
            lineHeight: "22px",
            letterSpacing: "0.25px",
            padding: "4px 14px",
            margin: "0px",
            overflow: "hidden",

            "&, &:focus": {
                backgroundColor: theme.palette.custom.colorLavenderWeb,
            },

            "& .MuiChip-label": {
                margin: "0px",
                padding: "0px",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                userSelect: "none",
            },
        },
        chipDeleteIcon: {
            margin: "0px 0px 0px 8px !important",
            width: "16px",
            height: "16px",
            minWidth: "16px",
        },
        submitButton: {
            fontSize: "14px",
            lineHeight: "22px",
            height: "40px",
            paddingLeft: "30px",
            paddingRight: "30px",
        },
    }),
    { name: "KeywordTaskForm" }
);
