/**
 * IMPORTANT: Sometimes when changing locale for ShareButtons component or for Facebook Component
 * when ShareButton component is also on the page, the user may get the following javacript error
 * in the console
 *
 * Uncaught TypeError: Cannot read property 'className' of null
 *  at Object.addClass (secureAnonymousFramework?v=1.0.108-1429&lang=da_DK:1404)
 *  at B.<anonymous> (secureAnonymousFramework?v=1.0.108-1429&lang=da_DK:2809)
 *  at B.setContent (secureAnonymousFramework?v=1.0.108-1429&lang=da_DK:306)
 *  at B.<anonymous> (secureAnonymousFramework?v=1.0.108-1429&lang=da_DK:1898)
 *  at B.setCountFormatted (secureAnonymousFramework?v=1.0.108-1429&lang=da_DK:306)
 *  at o (secureAnonymousFramework?v=1.0.108-1429&lang=da_DK:1935)
 *  at b (secureAnonymousFramework?v=1.0.108-1429&lang=da_DK:1937)
 *  at Function.handleCount (secureAnonymousFramework?v=1.0.108-1429&lang=da_DK:1906)
 *  at share?url=http%3A%2F%2Flocal.webeditor.one.com%3A3000%2Fbeta.html&lang=da_DK:1
 *
 *
 * http://codejaxy.com/q/79907/javascript-linkedin-linkedin-jsapi-linkedin-share-count-button-secureanonymousframework-error
 * https://stackoverflow.com/questions/45097973/linkedin-share-count-button-secureanonymousframework-error
 *
 * */
import * as React from 'react';
import kind from './kind';
import reducer from './reducer/index';
import workspace from './view/workspace';
import adjustmentHookConfig from './adjustmentHookConfig';
import calcRenderProps from './calcRenderProps';
import componentMainActions from './componentMainActions/index';
import propertiesPanel from './propertiesPanel/index';
import { localeReducer as locale } from '../Template/epics/template/index';
import ShareButtonsLocaleWrapper from "./view/ShareButtonsLocaleWrapper";
import previewConfig from "./previewConfig";
import type { CalcProps } from '../../Preview/flowTypes';
import type { ShareButtonsComponent } from './flowTypes';
import { ComponentNames } from "../constants";
import Icons from '../../../view/Icons/index';

type PreviewCalcProps = CalcProps<ShareButtonsComponent> & {
    renderedWidth?: number
};

export default {
    workspace,
    kind,
    label: ComponentNames[kind],
    shortcutIconName: 'shareButtons',
    calcRenderProps,
    // contextMenu,
    propertiesPanel,
    componentMainActions,
    reducer,
    dependsOn: {
        locale
    },
    adjustmentHookConfig,
    mobileEditorConfig: {
        kind,
        calcProps: (props: PreviewCalcProps) => {
            return {
                ...previewConfig.calcProps(props),
                width: props.renderedWidth
            };
        },
        view: ShareButtonsLocaleWrapper,
        getHiddenElementData: () => {
            const Icon = Icons.SHARE;
            return { icon: <Icon /> };
        }
    },
    hideFromInserter: true
};
