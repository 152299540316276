import { Dispatch } from "redux";
import { FacebookFeedGalleryKind } from "../oneweb/FacebookFeedGallery/kind";
import { InstagramGalleryKind } from "../oneweb/InstagramGallery/kind";
import type { AppStore } from "../../redux/modules/flowTypes";
import { ComponentsEvalValueActionType } from "../Workspace/epics/componentsEval/valueActionType";
import { getComponentsMap } from "../Workspace/epics/componentsEval/getters";
import { makeEpicStateSelector } from "../../epics/makeEpic";
import * as workspaceActions from "../Workspace/actionTypes";
import * as componentsEvalActions from "../Workspace/epics/componentsEval/actionTypes";
import { COMPONENT_CONFIGURATION_CANCELED } from "../../redux/modules/children/workspace/actionTypes";
import { REDO, UNDO } from "../../epics/undoManager/actionTypes";
import {
    GET_SOCIAL_MEDIA_COMPONENTS_USAGE_FAILURE_ACTION,
    GET_SOCIAL_MEDIA_COMPONENTS_USAGE_SUCCESS_ACTION
} from "./actions";
import { COMPONENTS_MAP_INITIALIZED } from "../Workspace/epics/componentsEval/actionTypes";
import {
    setTotalCurrentPageFacebookFeedGalleryComponentsAction,
    setTotalOtherPageFacebookFeedGalleryComponentsAction,
    setTotalFacebookFeedGalleryComponentsAction,
    disconnectFacebookFeedAction,
    getFacebookFeedAccessTokenAction
} from "./Facebook/FacebookFeed/actions";
import {
    setTotalCurrentPageInstagramGalleryComponentsAction,
    setTotalOtherPageInstagramGalleryComponentsAction,
    setTotalInstagramGalleryComponentsAction,
    disconnectInstagramAction,
    getInstagramAccessTokenAction
} from "./instagram/actions";
import {
    totalOtherPageFacebookFeedGalleryComponentsSelector,
    totalOtherPageInstagramGalleryComponentsSelector,
    totalFacebookFeedGalleryComponentsSelector,
    totalInstagramGalleryComponentsSelector
} from "./selectors";
import { PUBLISH_SITE_REQUEST } from "../TopBar/actionTypes";
import { socialAccountsFacebookFeedConnected } from "../oneweb/FacebookFeedGallery/selectors";
import { socialAccountsInstagramConnected } from "../oneweb/InstagramGallery/selectors";

const actionsToCheck = [
    workspaceActions.WORKSPACE_NEW_COMPONENT_ADDED,
    workspaceActions.DELETE_SELECTED_COMPONENTS,
    componentsEvalActions.COMPONENTS_PASTED,
    componentsEvalActions.COMPONENTS_DUPLICATED,
    COMPONENT_CONFIGURATION_CANCELED,
    UNDO,
    REDO
];

const getCurrentPageSocialAccountComponentsCount = (store) => {
    const
        componentsEvalEpicState = makeEpicStateSelector(ComponentsEvalValueActionType)(store.getState()),
        componentsMap = getComponentsMap(componentsEvalEpicState);

    let totalCurrentPageFacebookFeedGalleryComponents = 0,
        totalCurrentPageInstagramGalleryComponents = 0;

    Object.keys(componentsMap).forEach((componentID) => {
        const component = componentsMap[componentID];
        if (component.kind === FacebookFeedGalleryKind) {
            totalCurrentPageFacebookFeedGalleryComponents += 1;
        } else if (component.kind === InstagramGalleryKind) {
            totalCurrentPageInstagramGalleryComponents += 1;
        }
    });
    return {
        totalCurrentPageFacebookFeedGalleryComponents,
        totalCurrentPageInstagramGalleryComponents
    };
};

export const socialAccountsMiddleware = (store: AppStore) => (next: Dispatch) => (action: Action) => {
    if (action.type === GET_SOCIAL_MEDIA_COMPONENTS_USAGE_SUCCESS_ACTION || action.type === COMPONENTS_MAP_INITIALIZED) {
        let totalOverallFacebookFeedGalleryComponents;
        let totalOverallInstagramGalleryComponents;

        if (action.type === GET_SOCIAL_MEDIA_COMPONENTS_USAGE_SUCCESS_ACTION) {
            totalOverallFacebookFeedGalleryComponents = action.payload.totalFacebookFeedGalleryComponents;
            totalOverallInstagramGalleryComponents = action.payload.totalInstagramGalleryComponents;
        } else {
            totalOverallFacebookFeedGalleryComponents = totalFacebookFeedGalleryComponentsSelector(store.getState());
            totalOverallInstagramGalleryComponents = totalInstagramGalleryComponentsSelector(store.getState());
        }

        const {
            totalCurrentPageFacebookFeedGalleryComponents,
            totalCurrentPageInstagramGalleryComponents
        } = getCurrentPageSocialAccountComponentsCount(store);

        store.dispatch(
            setTotalCurrentPageFacebookFeedGalleryComponentsAction(totalCurrentPageFacebookFeedGalleryComponents)
        );
        store.dispatch(
            setTotalCurrentPageInstagramGalleryComponentsAction(totalCurrentPageInstagramGalleryComponents)
        );

        store.dispatch(setTotalOtherPageFacebookFeedGalleryComponentsAction(
            totalOverallFacebookFeedGalleryComponents - totalCurrentPageFacebookFeedGalleryComponents
        ));
        store.dispatch(setTotalOtherPageInstagramGalleryComponentsAction(
            totalOverallInstagramGalleryComponents - totalCurrentPageInstagramGalleryComponents
        ));

        if (action.type === GET_SOCIAL_MEDIA_COMPONENTS_USAGE_SUCCESS_ACTION) {
            store.dispatch(getInstagramAccessTokenAction());
            store.dispatch(getFacebookFeedAccessTokenAction());
        }
    }

    if (action.type === GET_SOCIAL_MEDIA_COMPONENTS_USAGE_FAILURE_ACTION) {
        store.dispatch(getInstagramAccessTokenAction());
        store.dispatch(getFacebookFeedAccessTokenAction());
    }

    if (actionsToCheck.indexOf(action.type) > -1) {
        const {
            totalCurrentPageFacebookFeedGalleryComponents,
            totalCurrentPageInstagramGalleryComponents
        } = getCurrentPageSocialAccountComponentsCount(store);

        store.dispatch(
            setTotalCurrentPageFacebookFeedGalleryComponentsAction(totalCurrentPageFacebookFeedGalleryComponents)
        );
        store.dispatch(
            setTotalCurrentPageInstagramGalleryComponentsAction(totalCurrentPageInstagramGalleryComponents)
        );

        const totalOtherPagesFacebookFeedGalleryComponents = totalOtherPageFacebookFeedGalleryComponentsSelector(store.getState());
        const totalOtherPagesInstagramGalleryComponents = totalOtherPageInstagramGalleryComponentsSelector(store.getState());

        store.dispatch(
            setTotalFacebookFeedGalleryComponentsAction(
                totalCurrentPageFacebookFeedGalleryComponents + totalOtherPagesFacebookFeedGalleryComponents
            )
        );
        store.dispatch(
            setTotalInstagramGalleryComponentsAction(
                totalCurrentPageInstagramGalleryComponents + totalOtherPagesInstagramGalleryComponents
            )
        );
    }

    if (action.type === PUBLISH_SITE_REQUEST) {
        const totalFacebookFeedGalleryComponents = totalFacebookFeedGalleryComponentsSelector(store.getState());
        const totalInstagramGalleryComponents = totalInstagramGalleryComponentsSelector(store.getState());

        const isFacebookFeedConnected = socialAccountsFacebookFeedConnected(store.getState());
        const isInstagramConnected = socialAccountsInstagramConnected(store.getState());

        if (isFacebookFeedConnected && totalFacebookFeedGalleryComponents === 0) {
            store.dispatch(disconnectFacebookFeedAction());
        }
        if (isInstagramConnected && totalInstagramGalleryComponents === 0) {
            store.dispatch(disconnectInstagramAction());
        }
    }
    return next(action);
};
