import kind from "./kind";
import view from './view/index';
import dependsOn from './dependsOn';
import reducer from './reducer/index';
import calcRenderProps from "./calcRenderProps";
import propertiesPanel from './propertiesPanel/index';
import componentMainActions from './componentMainActions/index';
import { adjustmentHookConfig } from "./adjustmentHookConfig";
import previewConfig from './previewConfig';
import { ComponentNames } from "../constants";
import { bgMobileCalcProps } from "./bgMobileCalcProps";
import { BackgroundCmpMinDimensions } from "./constants";

export default {
    kind,
    label: ComponentNames[kind],
    shortcutIconName: 'background',
    calcRenderProps,
    view,
    propertiesPanel,
    componentMainActions,
    reducer,
    dependsOn,
    adjustmentHookConfig,
    minDimensions: BackgroundCmpMinDimensions,
    mobileEditorConfig: {
        ...previewConfig,
        calcProps: bgMobileCalcProps
    }
};
