import React from 'react';
import Measure from "react-measure";
import { EXTENDED_PANEL_TEXT_CLEAR_DATA } from "../epics/ExtendedPanelScaleStateEpic/actionTypes";

type Props = {
    onResize: Function;
    className: string;
    dispatch: Dispatch;
    width?: number;
    children?: any;
}

interface OnResize {
    offset: { width: number, height: number }
}

export default class MeasuredText extends React.PureComponent<Props> {
    constructor() {
        // @ts-ignore
        super();
        this.onResize = this.onResize.bind(this);
    }

    onResize({ offset: { width, height } }: OnResize): void {
        this.props.onResize({ width, height });
    }

    componentWillUnmount() {
        this.props.dispatch({ type: EXTENDED_PANEL_TEXT_CLEAR_DATA });
    }

    render() {
        const { width, className, children } = this.props;
        return (
            <Measure offset onResize={this.onResize}>
                {({ measureRef }) => (
                    <div ref={measureRef} className={className} style={{ width }}>{children}</div>
                )}
            </Measure>
        );
    }
}

