import * as R from 'ramda';
import { getFontName } from '../../presentational/AddGoogleFont/utils';
import {
    textNormalGlobalstyle
} from '../../Workspace/epics/stylesheets/selectors';
import type { FeaturedProductsComponent, ComponentDependencies } from './flowTypes';
import { font } from "../../../mappers/path";
import { getGSNormalTextColor } from '../WebShop/utils';
import {
    getProductsCount,
    getWidth,
    getProductsPerRow
} from './utils';

export default (
    defaultState: FeaturedProductsComponent,
    {
        globalStyles,
    }: ComponentDependencies
): FeaturedProductsComponent => {
    const productsCount = getProductsCount(defaultState.products);
    const FeaturedProductsData = R.evolve({
        font: R.always(R.pipe(textNormalGlobalstyle, R.path([font]), getFontName)(globalStyles)),
        fontColor: R.always(getGSNormalTextColor(globalStyles)),
        width: () => getWidth(productsCount),
        productsPerRow: () => getProductsPerRow(productsCount)
    })(defaultState);

    return FeaturedProductsData;
};

