export const
    TEXT_MCTA_BOLD_BTN_CLICKED = 'TEXT_MCTA_BOLD_BTN_CLICKED',
    TEXT_MCTA_ITALIC_BTN_CLICKED = 'TEXT_MCTA_ITALIC_BTN_CLICKED',
    TEXT_MCTA_UNDERLINE_BTN_CLICKED = 'TEXT_MCTA_UNDERLINE_BTN_CLICKED',
    TEXT_MCTA_SUPERSCRIPT_BTN_CLICKED = 'TEXT_MCTA_SUPERSCRIPT_BTN_CLICKED',
    TEXT_MCTA_SUBSCRIPT_BTN_CLICKED = 'TEXT_MCTA_SUBSCRIPT_BTN_CLICKED',
    TEXT_MCTA_REMOVE_FORMAT_BTN_CLICKED = 'TEXT_MCTA_REMOVE_FORMAT_BTN_CLICKED',
    TEXT_MCTA_ALIGN_MOUSE_ENTER = 'TEXT_MCTA_ALIGN_MOUSE_ENTER',
    TEXT_MCTA_ALIGN_MOUSE_LEAVE = 'TEXT_MCTA_ALIGN_MOUSE_LEAVE',
    TEXT_MCTA_HORIZONTAL_ALIGN_BTN_CLICKED = 'TEXT_MCTA_HORIZONTAL_ALIGN_BTN_CLICKED',
    TEXT_MCTA_FONT_SIZE_CHANGED = 'TEXT_MCTA_FONT_SIZE_CHANGED',
    TEXT_CHANGED = 'TEXT_CHANGED',
    TEXT_UPDATE_TOOLBAR_STATE = 'TEXT_UPDATE_TOOLBAR_STATE',
    TEXT_ARROW_KEY_PRESSED = 'TEXT_ARROW_KEY_PRESSED',
    TEXT_CHARACTER_CLICK = 'TEXT_CHARACTER_CLICK',
    TEXT_MCTA_COLOR_CHANGED = 'TEXT_COLOR_CHANGED',
    TEXT_MCTA_COLOR_CHANGED_AUTO_COLOR = 'TEXT_MCTA_COLOR_CHANGED_AUTO_COLOR',
    TEXT_MCTA_UPDATE_CLIENT_POSITION = 'TEXT_MCTA_UPDATE_CLIENT_POSITION',
    TEXT_HIGHLIGHT_COLOR_CHANGED = 'TEXT_HIGHLIGHT_COLOR_CHANGED',
    TEXT_HIGHLIGHT_COLOR_REMOVED = 'TEXT_HIGHLIGHT_COLOR_REMOVED',
    TEXT_PARAGRAPH_SPACING_CHANGED = 'TEXT_PARAGRAPH_SPACING_CHANGED',
    TEXT_VERTICAL_ALIGNMENT_CHANGED = 'TEXT_VERTICAL_ALIGNMENT_CHANGED',
    TEXT_THEME_COLOR_OVERRIDE = 'TEXT_THEME_COLOR_OVERRIDE',
    TEXT_SPACING_APPLIED = 'TEXT_SPACING_APPLIED',
    TEXT_SET_LINK = 'TEXT_SET_LINK',
    TEXT_REMOVE_LINK = 'TEXT_REMOVE_LINK',
    TEXT_COMPONENT_CHARACTER_SPACING_CHANGED = 'TEXT_COMPONENT_CHARACTER_SPACING_CHANGED',
    TEXT_COMPONENT_LINE_SPACING_CHANGED = 'TEXT_COMPONENT_LINE_SPACING_CHANGED',
    TEXT_COMPONENT_GLOBAL_STYLE_SELECTED = 'TEXT_COMPONENT_GLOBAL_STYLE_SELECTED',
    TEXT_COMPONENT_LINK_GLOBAL_STYLE_SELECTED = 'TEXT_COMPONENT_LINK_GLOBAL_STYLE_SELECTED',
    TEXT_INDENT_BTN_CLICKED = 'TEXT_INDENT_BTN_CLICKED',
    TEXT_OUTDENT_BTN_CLICKED = 'TEXT_OUTDENT_BTN_CLICKED',
    TEXT_EDITOR_LINK_ADDED = 'TEXT_EDITOR_LINK_ADDED',
    TEXT_FONT_FAMILY_CHANGED = 'TEXT_FONT_FAMILY_CHANGED',
    TEXT_SHADOW_COLOR_CHANGED = 'TEXT_SHADOW_COLOR_CHANGED',
    TEXT_SHADOW_COLOR_REMOVED = 'TEXT_SHADOW_COLOR_REMOVED',
    TEXT_COMPONENT_BLUR_RADIUS_CHANGED = 'TEXT_COMPONENT_BLUR_RADIUS_CHANGED',
    TEXT_COMPONENT_SHADOWOFFSETX_CHANGED = 'TEXT_COMPONENT_SHADOWOFFSETX_CHANGED',
    TEXT_COMPONENT_SHADOWOFFSETY_CHANGED = 'TEXT_COMPONENT_SHADOWOFFSETY_CHANGED',
    TEXT_FOCUS_ON_LAST_NODE = 'TEXT_FOCUS_ON_LAST_NODE',
    TEXT_OPEN_LINK_EDITOR = 'TEXT_OPEN_LINK_EDITOR',
    TEXT_EDITOR_UPDATE_STATE = 'TEXT_EDITOR_UPDATE_STATE',
    TEXT_MCTA_UNORDERED_LIST_BTN_CLICKED = 'TEXT_MCTA_UNORDERED_LIST_BTN_CLICKED',
    TEXT_MCTA_ORDERED_LIST_BTN_CLICKED = 'TEXT_MCTA_ORDERED_LIST_BTN_CLICKED',
    TEXT_HANDLE_EDITOR_CHANGE = 'TEXT_HANDLE_EDITOR_CHANGE',
    TEXT_MOBILE_FONT_CHANGE = 'TEXT_MOBILE_FONT_CHANGE',
    TEXT_MOBILE_CLEAR_FORMATTING = 'TEXT_MOBILE_CLEAR_FORMATTING',
    TEXT_LINKED_VALUE_TOOLBAR_EDIT_CLIKED = 'TEXT_LINKED_VALUE_TOOLBAR_EDIT_CLIKED',
    TEXT_LINKED_VALUE_TOOLBAR_CONVERT_TO_TEXT_CLIKED = 'TEXT_LINKED_VALUE_TOOLBAR_CONVERT_TO_TEXT_CLIKED',
    TEXT_HIGHLIGHT_COLOR_CHANGED_AUTO_COLOR = 'TEXT_HIGHLIGHT_COLOR_CHANGED_AUTO_COLOR',
    TEXT_HIGHLIGHT_COLOR_REMOVED_AUTO_COLOR = 'TEXT_HIGHLIGHT_COLOR_REMOVED_AUTO_COLOR',
    TEXT_SHADOW_COLOR_CHANGED_AUTO_COLOR = 'TEXT_SHADOW_COLOR_CHANGED_AUTO_COLOR',
    TEXT_SHADOW_COLOR_REMOVED_AUTO_COLOR = 'TEXT_SHADOW_COLOR_REMOVED_AUTO_COLOR',
    TEXT_COMPONENT_BLUR_RADIUS_CHANGED_AUTO_COLOR = 'TEXT_COMPONENT_BLUR_RADIUS_CHANGED_AUTO_COLOR',
    TEXT_COMPONENT_SHADOWOFFSETX_CHANGED_AUTO_COLOR = 'TEXT_COMPONENT_SHADOWOFFSETX_CHANGED_AUTO_COLOR',
    TEXT_COMPONENT_SHADOWOFFSETY_CHANGED_AUTO_COLOR = 'TEXT_COMPONENT_SHADOWOFFSETY_CHANGED_AUTO_COLOR',
    SPELLING_FIX_APPLIED = 'SPELLING_FIX_APPLIED',
    SHOW_SPELLCHECK_TOOLTIP = 'SHOW_SPELLCHECK_TOOLTIP';
