import { makeEpic } from "../../../../../epics/makeEpic";
import {
    TRACKING_TAB_MOUNT,
} from "../../../../SiteSettings/trackingGlobalData/actionTypes";
import { loadSubscriptionDataForGoogleAdsCampaign } from "../../../actionCreators/loadSubscriptionData";

export const siteSettingsTrackingTabAnalyticsEpic = makeEpic({
    defaultState: { dataLoaded: false },
    valueActionType: 'SITE_SETTINGS_TRACKING_TAB_ANALYTICS_EPIC',
    updaters: [
        {
            conditions: [TRACKING_TAB_MOUNT],
            reducer: ({ state }) => {
                const multipleActionsToDispatch: Action[] = [];
                if (state.dataLoaded === false) {
                    multipleActionsToDispatch.push(loadSubscriptionDataForGoogleAdsCampaign());
                }

                return {
                    state: { ...state, dataLoaded: true },
                    multipleActionsToDispatch
                };
            }
        },
    ]
});
