/* eslint-disable max-len */

import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";

import { WizardFooter } from "../../WizardFooter";
import { StepDescription } from "../Common/StepDescription";
import { OptionsSet } from "../Common/OptionSet/OptionsSet";

import { PRONOUNS_IDS, PRONOUNS_MAP } from "./constants";
import { DynamicTemplateContext } from "../../Context";
import { DYNAMIC_TEMPLATE_UPDATE_ISGROUP } from "../../../Epic/actionTypes";

import stepStyles from "../steps.css";
import styles from "./Pronouns.css";

const getTitle = (type) => {
    let title;

    if (type === 'business') {
        title = "msg: onboarding.dynamic.step5.business.title {How do you want to present your business?}";
    } else {
        title = "msg: onboarding.dynamic.step5.project.title {Should the texts use “I” or “We”?}";
    }

    return title;
};

const getDescription = (type) => {
    let description;

    if (type === 'business') {
        description = "msg: onboarding.dynamic.step5.business.description {Let us know if the texts should use “I” or “We” when describing your business.}";
    } else {
        description = "msg: onboarding.dynamic.step5.project.description {Let us know if the writing tool should use “I” or “We” in your website texts.}";
    }

    return description;
};

export const PronounsStep = () => {
    const { concept: { type }, isGroup } = useContext(DynamicTemplateContext);
    const dispatch = useDispatch();
    let pronounsValue;

    if (isGroup !== undefined) {
        pronounsValue = isGroup ? PRONOUNS_IDS.group : PRONOUNS_IDS.single;
    }

    const [selected, setSelected] = useState(pronounsValue);

    const StepDescriptionProps = {
        title: getTitle(type),
        description: getDescription(type)
    };

    const options = PRONOUNS_MAP.map(el => {
        return {
            value: el.value,
            label: el.label,
            description: el.description[type] || el.description.default
        };
    });

    const onChange = (list) => {
        const value = list[0] === PRONOUNS_IDS.group;
        setSelected(list[0]);
        dispatch({ type: DYNAMIC_TEMPLATE_UPDATE_ISGROUP, payload: value });
    };

    return (
        <React.Fragment>
            <div className={stepStyles.stepContainer} data-testid="dynamic-onboarding-pronouns">
                <StepDescription {...StepDescriptionProps} />
                <OptionsSet
                    className={styles.optionSet}
                    optionClass={styles.optionClass}
                    options={options}
                    selectedOptions={[selected]}
                    onSelectionChange={onChange}
                />
            </div>
            <WizardFooter disableButton={!selected} />
        </React.Fragment>
    );
};
