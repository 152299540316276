import * as R from "ramda";
import { BlankDataPageSet } from "../../../../dal/model";
import { createSection, getHeaderSection } from "../../oneweb/Section/utils";
import { MENU } from "../../oneweb/componentKinds";
import { getTitleForSection } from "../../Workspace/epics/componentsEval/getSectionTitles";
import { mapBackFromComponent } from "../../../../dal/pageMapAdapter/mappers";
import { BLANK_SECTION_HEIGHT } from "../../Panel/constants";
import type { ComponentsMap } from "../../../redux/modules/children/workspace/flowTypes";

export const getBlankPageDataSet = (componentsMap: ComponentsMap, pageId: string, pageName: string) => {
    const layoutData = new BlankDataPageSet(pageName, pageId);
    const header = getHeaderSection(componentsMap);
    if (header) {
        const pageSectionTop = header.height;
        let newSection = createSection({
            top: pageSectionTop,
            bottom: pageSectionTop + BLANK_SECTION_HEIGHT,
            inTemplate: false,
            orderIndex: 0
        });
        const bottomMostStickyMenu = R.pipe(
            R.values,
            R.filter(({ kind, isStickyToHeader }) => (kind === MENU && isStickyToHeader)),
            R.sort((s1: Record<string, any>, s2: Record<string, any>) => ((s1.top + s1.height) - (s2.top + s2.height))),
            R.last
        )(componentsMap);

        if (bottomMostStickyMenu) {
            const bottomPadding = 15;
            const pageSectionBottom = bottomMostStickyMenu.top + bottomMostStickyMenu.height + bottomPadding;
            if ((pageSectionBottom - pageSectionTop) > bottomPadding) {
                newSection = createSection({ top: pageSectionTop, bottom: pageSectionBottom, inTemplate: false, orderIndex: 0 });
            }
        }
        newSection = {
            ...newSection,
            title: getTitleForSection(componentsMap, newSection)
        };
        layoutData.page.items.push(mapBackFromComponent(newSection, {}));
    }
    return layoutData;
};
