import cx from 'classnames';
import getCenteredDialog from "../../../DialogManager/getCenteredDialogConfig";
import { configurationDialogFactory } from '../configurationDialogFactory';
import { DialogWidth, MailchimpWidgetDialogHeight } from '../constants';
import { WIDGETS_MAILCHIMP_SET_LINK } from "./actionTypes";
import { validateMailChimpCode } from './utils';
import Icons from '../view/Icons.css';

const
    configurationDialogId = "WIDGETS_MAILCHIMP_CONFIG_DIALOG",
    configurationDialogView = configurationDialogFactory({
        saveAction: WIDGETS_MAILCHIMP_SET_LINK,
        dialogIconClass: cx(Icons.mailchimp, Icons.dialog),
        dialogTitle: "msg: widgets.mailchimp.label {Mailchimp}",
        info: "msg: widgets.mailchimp.config.addMailchimp {Add a Mailchimp signup form to your page to get more subscribers to your mailing list.}", // eslint-disable-line max-len
        subInfo: "msg: widgets.mailchimp.config.info {In your Mailchimp account, go to \'Audience\', then \'Signup forms\' and \'Embedded forms\'. Follow the instructions, copy the code and paste it below.}", // eslint-disable-line max-len
        learnMore: "msg: widgets.mailchimp.config.learnMore {https://help.one.com/hc/en-us/articles/11400254613009}", // TODO: Fix this
        inputPlaceHolder: "msg: widgets.mailchimp.configDialog.placeHolder {Paste Mailchimp embed code}",
        isLinkValid: validateMailChimpCode,
        termsOfService: "msg: widgets.mailchimp.config.termsOfService {By using this widget, you agree to Mailchimp\'s {termsOfServiceLink}.}", // eslint-disable-line max-len
        termsOfServiceLink: "msg: widgets.mailchimp.config.termsOfServiceLink {https://mailchimp.com/en-gb/legal/terms/ }",
    }),
    configurationDialog = getCenteredDialog({
        width: DialogWidth,
        height: MailchimpWidgetDialogHeight,
        component: configurationDialogView,
    });

export { configurationDialogId, configurationDialog };

