import React from 'react';
import cx from 'classnames';
import styles from './styles.css';
import { Intl } from '../../../../view/intl/index';
import { SECTION_PANEL_MOVE_SECTION_UP, SECTION_PANEL_MOVE_SECTION_DOWN } from '../../../Workspace/epics/componentsEval/actionTypes';
import { CMP_PANEL_DELETE_CLICKED } from "../../../Workspace/epics/componentPanelActions/actionTypes";
import type { AnyComponent } from '../../../../redux/modules/children/workspace/flowTypes';
import {
    MOVE_UP_ACTION,
    MOVE_DOWN_ACTION,
    DELETE_ACTION,
    DUPLICATE_ACTION
} from '../../../Workspace/epics/componentPanelActions/constants';
import { usePrevious } from '../../../../utils/customHooks';
import { DUPLICATE_COMPONENTS_BY_IDS } from '../../../Workspace/actionTypes';

type PropTypes = {
    intl: Intl;
    dispatch: Dispatch;
    selectedComponent: AnyComponent;
    hideActions: Record<string, any>;
    panelTop: number;
    disableActions: Record<string, any>;
    isSectionTourVisible: boolean;
}

const
    View = ({ intl, dispatch, selectedComponent, hideActions, panelTop, disableActions, isSectionTourVisible }: PropTypes) => {
        const moveDownArrowToolTip = disableActions[MOVE_DOWN_ACTION] ?
                'msg: tooltip.moveDownArrow.disable {There is no section below this one}' :
                'msg: tooltip.moveDownArrow {Move down}',
            moveUpArrowToolTip = disableActions[MOVE_UP_ACTION] ?
                'msg: tooltip.moveUpArrow.disable {There is no section above this one}' :
                'msg: tooltip.moveUpArrow {Move up}',
            deleteTip = 'msg: common.delete {Delete}',
            lastPageSectionTip = 'msg: common.lastPageSectionTip {You need at least one section on the page}',
            duplicateTip = 'msg: common.duplicate {Duplicate}';
        const [titleDelay, setTitleDelay] = React.useState(0);
        const prevPanelTop = usePrevious(panelTop);
        React.useEffect(() => {
            if (prevPanelTop && prevPanelTop !== panelTop) {
                setTitleDelay(300);
            }
        }, [panelTop]);
        const onClickDelete = disableActions[DELETE_ACTION] ? null
                : () => dispatch({ type: CMP_PANEL_DELETE_CLICKED, payload: selectedComponent.id }),
            onClickDuplicate = () => dispatch({ type: DUPLICATE_COMPONENTS_BY_IDS, payload: [selectedComponent.id] }),
            onClickMoveUp = disableActions[MOVE_UP_ACTION] ? null
                : () => dispatch({ type: SECTION_PANEL_MOVE_SECTION_UP }),
            onClickMoveDown = disableActions[MOVE_DOWN_ACTION] ? null
                : () => dispatch({ type: SECTION_PANEL_MOVE_SECTION_DOWN });
        return (
            <div
                data-title-position="right"
                className={cx(styles.panelActions)}
            >
                {!hideActions[DELETE_ACTION] && <div
                    data-title={intl.msgJoint(disableActions[DELETE_ACTION] ? lastPageSectionTip : deleteTip)}
                    data-title-delay={titleDelay}
                    className={cx(styles.deleteIcon, styles.panelIcon,
                        { [styles.disabledDelete]: !isSectionTourVisible && disableActions[DELETE_ACTION] })}
                    // @ts-ignore
                    onClick={onClickDelete}
                />}
                {!hideActions[DUPLICATE_ACTION] && <div
                    data-title={intl.msgJoint(duplicateTip)}
                    data-title-delay={titleDelay}
                    className={cx(styles.duplicateIcon, styles.panelIcon)}
                    onClick={onClickDuplicate}
                />}
                {!hideActions[MOVE_UP_ACTION] && <div
                    data-title={intl.msgJoint(moveUpArrowToolTip)}
                    data-title-delay={titleDelay}
                    className={cx(styles.upArrow, styles.panelIcon,
                        { [styles.disabledMoveUp]: !isSectionTourVisible && disableActions[MOVE_UP_ACTION] })}
                    // @ts-ignore
                    onClick={onClickMoveUp}
                />}
                {!hideActions[MOVE_DOWN_ACTION] && <div
                    data-title={intl.msgJoint(moveDownArrowToolTip)}
                    data-title-delay={titleDelay}
                    className={cx(styles.downArrow, styles.panelIcon,
                        { [styles.disabledMoveDown]: !isSectionTourVisible && disableActions[MOVE_DOWN_ACTION] })}
                    // @ts-ignore
                    onClick={onClickMoveDown}
                />}
            </div>
        );
    };

export default {
    view: View,
    show: true,
};
