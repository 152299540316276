import * as alignmentTypes from '../../../alignmentTypes';
import { getSelectedNodeProperty } from "../../utils/nodeUtils/getSelectedNodeProperty";
import { makeComputedParentStyleSelector } from "../../utils/selectors/makeComputedParentStyleSelector";
import type { TinyMceEditor } from "../../../flowTypes";

const DEFAULT_ALIGNMENT: string = 'left';

export const ALIGNMENT_OPTIONS: Record<string, any> = {
    right: alignmentTypes.TEXT_ALIGN_RIGHT,
    left: alignmentTypes.TEXT_ALIGN_LEFT,
    center: alignmentTypes.TEXT_ALIGN_CENTER,
    justify: alignmentTypes.TEXT_ALIGN_FULL
};

export const getAlignment = (editor: TinyMceEditor) => {
    const selector = makeComputedParentStyleSelector('text-align');
    const textAlign = getSelectedNodeProperty(editor, selector);
    return ALIGNMENT_OPTIONS[textAlign] || ALIGNMENT_OPTIONS[DEFAULT_ALIGNMENT];
};
