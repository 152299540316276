import React from 'react';
import cx from 'classnames';
import styles from './InstancePropertiesPanelController.css';
import type { InstancePropertiesPanelProps } from "../types";
import { InstancePropertiesPanelConfig } from "../InstancePropertiesPanelRegistry";
import { getCurrentPageId } from "../../epic/utils/navigation";
import { GenericPropertiesPanel } from "../../view/GenericPropertiesPanel";
import PropertiesPanelPage from '../../view/PropertiesPage';
import addGoogleFontView from "../../view/AddGoogleFont/index";

export class InstancePropertiesPanelController extends React.Component<InstancePropertiesPanelProps> {
    config: InstancePropertiesPanelConfig;

    constructor({ state }: InstancePropertiesPanelProps) {
        // @ts-ignore
        super();
        if (!state.instance) {
            throw new Error('Instance properties panel state is undefined');
        }
        this.config = new InstancePropertiesPanelConfig(state.instance.id);
    }

    renderPage() {
        const
            { state: { navigation, additionalPayload }, navigateToPage, dispatch } = this.props,
            pageId = getCurrentPageId(navigation),
            Page = this.config.getPage(pageId);

        if (pageId === addGoogleFontView.id) {
            return (
                // @ts-ignore
                <Page.view additionalPayload={additionalPayload} dispatch={dispatch} />
            );
        }

        return (
            <PropertiesPanelPage>
                <Page.View
                    state={this.props.state}
                    navigateToPage={navigateToPage}
                    dispatch={dispatch}
                />
            </PropertiesPanelPage>
        );
    }

    render() {
        const
            { state: { dimensions, navigation }, dispatch } = this.props,
            Page = this.config.getPage(getCurrentPageId(navigation)),
            { containerClassName, getPortalNode } = this.config.getPropertiesPanelParams();

        return (
            // @ts-ignore
            <GenericPropertiesPanel
                title={Page.title}
                dimensions={dimensions}
                navigation={navigation}
                containerClassName={cx(styles.container, containerClassName)}
                getPortalNode={getPortalNode}
                dispatch={dispatch}
            >
                {this.renderPage()}
            </GenericPropertiesPanel>
        );
    }
}
