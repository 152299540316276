import type { SocialLink, SocialLinkInCmp } from "./flowTypes";
import { SocialPlatformTypes } from "./socialLinksConfigs";
import { makeUuidNoReplaceOnDuplicate } from "../../../../utils/makeUuid";

export const removeUrlProtocolAndWWW = (url: string) => url.replace('http://', '').replace('https://', '').replace('mailto:', '').replace('www.', '');

const removeLastSlash = s => {
    if (s[s.length - 1] === '/') {
        return s.substring(0, s.length - 1);
    }
    return s;
};

export const removeUrlProtocolAndWWWAndLastSlash = (s: string) => removeLastSlash(removeUrlProtocolAndWWW(s));

const defaultPlatforms = [
    SocialPlatformTypes.TWITTER,
    SocialPlatformTypes.FACEBOOK,
    SocialPlatformTypes.INSTAGRAM,
    SocialPlatformTypes.YOUTUBE,
    SocialPlatformTypes.PINTEREST
];

export const
    getDefaultSocialLinks = (existingLinks: Array<SocialLink>): Array<SocialLink> =>
        defaultPlatforms.map(platform =>
            existingLinks.find(link => link.kind === platform) ||
            ({ id: makeUuidNoReplaceOnDuplicate(), kind: platform, url: '' })),
    getDefaultSocialLinksInCmp = (existingLinks: Array<SocialLink>): Array<SocialLinkInCmp> =>
        getDefaultSocialLinks(existingLinks).map((link): SocialLinkInCmp => {
            return { id: link.id, kind: link.kind, hidden: false };
        });
