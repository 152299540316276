import makeEpic from '../../../../epics/makeEpic';
import {
    ReceiveOnlyComponentsMap, SelectedComponentsSelector,
    UserInteractionModeSelector
} from "../../../Workspace/epics/componentsEval/selectorActionTypes";
import { receiveOnly } from "../../../../epics/makeCondition";
import {
    MOUSE_DOWN_ON_HANDLE
} from "../../../Workspace/epics/componentsEval/userInteractionMutations/interactionModes";
import { isHoverBoxKind } from "../../componentKinds";
import topMostHandleVAT from "../../../Workspace/epics/topMostHandle/valueActionType";
import { componentAttachmentsVAT } from "../../../Workspace/epics/componentAttachements/valueActionType";
import { addInfoMessage } from "../../../Toaster/actionCreators";
import { COMPONENTS_BOTTOM_SHIFTED, COMPONENTS_RESIZED } from "../../../Workspace/epics/componentsEval/actionTypes";
import * as HandleKinds from "../../../../utils/handle/kinds";
import { isShiftBarBottom } from "../../../../utils/handle/index";
import { getHiddenComponentsForHoverBox } from "../utils";

const defaultState = {};
const addRightBottomProps = cmp => ({ ...cmp, right: cmp.left + cmp.width, bottom: cmp.top + cmp.height });
export const hoverboxResizeEpic = makeEpic({
    defaultState,
    valueActionType: 'HOVERBOX_RESIZING_MESSAGE_VAT',
    updaters: [
        ...[COMPONENTS_RESIZED, COMPONENTS_BOTTOM_SHIFTED].map(condition => ({
            conditions: [
                condition,
                receiveOnly(componentAttachmentsVAT),
                ReceiveOnlyComponentsMap,
            ],
            reducer: ({ values: [{ componentsId }, { attachments }, componentsmap], state }) => {
                const hoverBoxes = componentsId.map(id => componentsmap[id]).filter(cmp => isHoverBoxKind(cmp.kind)) || [];
                if (hoverBoxes.length && state.handle) {
                    const cannotResizeHoverbox = hoverBoxes
                        .map(hoverBox => addRightBottomProps(hoverBox))
                        .some(hoverBox => {
                            const allHiddenChildren = getHiddenComponentsForHoverBox(hoverBox.id, attachments, componentsmap)
                                .map(id => addRightBottomProps(componentsmap[id]));
                            if (!allHiddenChildren.length) {
                                return false;
                            }
                            if (state.handle.kind === HandleKinds.ResizeE) {
                                return allHiddenChildren.some(cmp => cmp.right === hoverBox.right);
                            }
                            if (state.handle.kind === HandleKinds.ResizeN) {
                                return allHiddenChildren.some(cmp => cmp.top === hoverBox.top);
                            }
                            if (state.handle.kind === HandleKinds.ResizeW) {
                                return allHiddenChildren.some(cmp => cmp.left === hoverBox.left);
                            }
                            if (state.handle.kind === HandleKinds.ResizeS || isShiftBarBottom(state.handle.kind)) {
                                return allHiddenChildren.some(cmp => cmp.bottom === hoverBox.bottom);
                            }
                            if (state.handle.kind === HandleKinds.ResizeNE) {
                                return allHiddenChildren.some(cmp => cmp.top === hoverBox.top || cmp.right === hoverBox.right);
                            }
                            if (state.handle.kind === HandleKinds.ResizeNW) {
                                return allHiddenChildren.some(cmp => cmp.top === hoverBox.top || cmp.left === hoverBox.left);
                            }
                            if (state.handle.kind === HandleKinds.ResizeSE) {
                                return allHiddenChildren.some(cmp => cmp.bottom === hoverBox.bottom || cmp.right === hoverBox.right);
                            }
                            if (state.handle.kind === HandleKinds.ResizeSW) {
                                return allHiddenChildren.some(cmp => cmp.bottom === hoverBox.bottom || cmp.left === hoverBox.left);
                            }
                            return false;
                        });
                    if (cannotResizeHoverbox) {
                        return {
                            state: defaultState,
                            actionToDispatch: addInfoMessage(
                                "hoverbox.resizingMessage",
                                // eslint-disable-next-line max-len
                                "msg: hoverbox.resizingMessage {Reduce the size of the elements in the hoverbox before you can make it smaller.}"
                            )
                        };
                    }
                }
                return { state: defaultState };
            }
        })),
        {
            conditions: [
                UserInteractionModeSelector,
                receiveOnly(topMostHandleVAT),
                receiveOnly(SelectedComponentsSelector)
            ],
            reducer: ({ values: [interactionMode, handle, selectedComponents], state }) => {
                const hoverBoxes = selectedComponents.filter(cmp => isHoverBoxKind(cmp.kind));
                if (interactionMode === MOUSE_DOWN_ON_HANDLE && hoverBoxes.length) {
                    return {
                        state: {
                            handle,
                        }
                    };
                }

                return { state };
            }
        }
    ]
});

