import React from "react";
import cx from 'classnames';
import * as R from 'ramda';
import shiftStyles, { borderThickness } from './ShiftBarTopDecorations.css';
import resizeStyles from './ResizeDecorations.css';
import { Border } from '../../../components/presentational/Border/Border';
import type { ResizeDecorationsState } from '../../../components/Workspace/epics/resizeDecos/index';
import { isZeroBBox } from "../../../components/Workspace/epics/resizeDecos/utils";
import { makeBorderProps } from "./makeBorderProps";
import { red270 } from '../../../components/variables.css';
import { BBox } from "../../../components/App/flowTypes";

let oldDistanceBBox = {} as BBox;

type Props = {
    state: ResizeDecorationsState
}

export default ({ state }: Props) => {
    let distanceBBox = state.distanceBBox,
        isVisible = true,
        selectedComponentBBox = state.selectionBBox,
        sectionSplitLines = state.sectionSplitLines,
        distanceLines = state.distanceLines || {},
        counter = 0;

    if (!isZeroBBox(distanceBBox) || Object.keys(distanceLines).length) {
        oldDistanceBBox = distanceBBox;
    } else {
        isVisible = false;
        distanceBBox = oldDistanceBBox;
        selectedComponentBBox = state.oldSelectionBBox;
    }

    const borders = (<div>{
            state.componentsBBoxes.map(bBox => {
                const borderProps = makeBorderProps({ bBox, opacity: isVisible ? 0.5 : 0, color: red270 });
                counter++;
                return <Border {...borderProps} key={counter} />;
            })
        }</div>),
        selectionBBoxWidth = selectedComponentBBox.right - selectedComponentBBox.left,
        selectionBBoxHeight = selectedComponentBBox.bottom - selectedComponentBBox.top,
        spacingStyle: Record<string, any> = {},
        labelStyle: Record<string, any> = {},
        lineStyle: Record<string, any> = {},
        labelWidth = 50,
        labelHeight = 25;

    spacingStyle.top = distance => ({
        top: selectedComponentBBox.top - distance,
        left: selectedComponentBBox.left - 1,
        width: selectionBBoxWidth + 2,
        height: distance,
        opacity: isVisible ? 1 : 0
    });
    spacingStyle.bottom = distance => ({
        top: selectedComponentBBox.bottom,
        left: selectedComponentBBox.left - 1,
        width: selectionBBoxWidth + 2,
        height: distance,
        opacity: isVisible ? 1 : 0
    });
    spacingStyle.left = distance => ({
        top: selectedComponentBBox.top,
        left: selectedComponentBBox.left - distance,
        width: distance,
        height: selectionBBoxHeight,
        opacity: isVisible ? 1 : 0
    });
    spacingStyle.right = distance => ({
        top: selectedComponentBBox.top,
        left: selectedComponentBBox.right,
        width: distance,
        height: selectionBBoxHeight,
        opacity: isVisible ? 1 : 0
    });

    labelStyle.top = distance => ({
        top: (distance / 2) - (labelHeight / 2),
        left: (selectionBBoxWidth / 2) - (labelWidth / 2)
    });
    labelStyle.bottom = distance => ({
        top: (distance / 2) - (labelHeight / 2),
        left: (selectionBBoxWidth / 2) - (labelWidth / 2)
    });
    labelStyle.left = distance => ({
        top: (selectionBBoxHeight / 2) - (labelHeight / 2),
        left: (distance / 2) - (labelWidth / 2)
    });
    labelStyle.right = distance => ({
        top: (selectionBBoxHeight / 2) - (labelHeight / 2),
        left: (distance / 2) - (labelWidth / 2)
    });
    lineStyle.top = distance => ({
        top: 0,
        left: selectionBBoxWidth / 2,
        height: distance - borderThickness
    });
    lineStyle.bottom = distance => ({
        top: 0,
        left: selectionBBoxWidth / 2,
        height: distance - borderThickness
    });
    lineStyle.left = distance => ({
        top: selectionBBoxHeight / 2,
        left: 0,
        width: distance - borderThickness
    });
    lineStyle.right = distance => ({
        top: selectionBBoxHeight / 2,
        left: 0,
        width: distance - borderThickness
    });

    const boxes: React.JSX.Element[] = [];
    R.forEachObjIndexed((distance, direction) => {
        if (distance <= 0) {
            return;
        }
        const distanceFormat = Math.floor(Math.abs(distance));
        boxes.push(
            <div
                style={spacingStyle[direction](distanceFormat)}
                className={resizeStyles['spacing-' + direction]}
                key={direction}
            >
                <div className={shiftStyles.label} style={labelStyle[direction](distanceFormat)}>{distanceFormat}px
                </div>
                <div
                    className={resizeStyles['measurementLine-' + direction]}
                    style={lineStyle[direction](distanceFormat)}
                />
            </div>
        );
    }, distanceBBox);

    R.forEachObjIndexed((lines, direction) => {
        lines.distances.forEach((distance, index) => {
            if (lines.isHideDistanceLines[index]) {
                return;
            }
            const distanceFormat = distance;
            boxes.push(
                <div
                    style={lines.spacingStyles[index]}
                    className={resizeStyles['spacing-' + direction]}
                    key={direction + '-' + index}
                >
                    <div className={shiftStyles.label} style={lines.labelStyles[index]}>{distanceFormat}px
                    </div>
                    <div
                        className={cx(resizeStyles['measurementLine-' + direction],
                            { [resizeStyles.arrow]: lines.isEqualDistances[index] })}
                        style={lines.lineStyles[index]}
                    />
                </div>
            );
        });
    }, distanceLines);

    const easierWSLines: React.JSX.Element[] = [];

    R.forEachObjIndexed(({ top, left, height, width }, direction) => {
        easierWSLines.push(
            <div
                key={'easier-ws-line' + direction}
                className={cx(resizeStyles['measurementLine-' + direction], resizeStyles['split-line'])}
                style={{ top, left, height, width }}
            />
        );
    }, sectionSplitLines);

    return (
        <div>
            {easierWSLines}
            <div>{boxes}</div>
            {borders}
        </div>
    );
};

