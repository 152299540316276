import { ActionType } from '../../../../epics/makeCondition';
import valueActionType from './valueActionType';
import { keyNamesMap } from "./index";

const
    makeIsKeyPressedActionType = (keyCodes, receiveOnly, isOptional = false) => new ActionType({
        actionType: valueActionType,
        receiveOnly,
        isOptional,
        selector: ({ pressedKeysMap }) => keyCodes.some(keyCode => pressedKeysMap[keyCode])
    }),
    shiftKeyCodes = [keyNamesMap.shift],
    altKeyCodes = [keyNamesMap.alt],
    ctrlLikeKeyCodes = [keyNamesMap.ctrl, keyNamesMap.meta],
    arrowKeyCodes = [keyNamesMap.up, keyNamesMap.down, keyNamesMap.left, keyNamesMap.right],
    shiftOrCtrlLikeKeyCodes = [keyNamesMap.shift, keyNamesMap.ctrl, keyNamesMap.meta];

export const
    OptionalIsShiftPressedActionType = makeIsKeyPressedActionType(shiftKeyCodes, false, true),
    OptionalIsAltPressedActionType = makeIsKeyPressedActionType(altKeyCodes, false, true),
    OptionalIsShiftOrCtrlLikePressedActionType = makeIsKeyPressedActionType(shiftOrCtrlLikeKeyCodes, false, true),
    OptionalReceiveOnlyIsShiftOrCtrlLikePressedActionType =
        makeIsKeyPressedActionType(shiftOrCtrlLikeKeyCodes, true, true),
    OptionalIsArrowKeyPressedActionType = makeIsKeyPressedActionType(arrowKeyCodes, false, true),
    AltPressedActionType = makeIsKeyPressedActionType(altKeyCodes, false, false),

    ReceiveOnlyIsShiftPressedActionType = makeIsKeyPressedActionType(shiftKeyCodes, true),
    ReceiveOnlyIsCtrlLikePressedActionType = makeIsKeyPressedActionType(ctrlLikeKeyCodes, true),
    ReceiveOnlyIsAltPressedActionType = makeIsKeyPressedActionType(altKeyCodes, true);
