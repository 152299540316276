import view from "./view/index";
import kind from "./kind";
import textNormalClassName from '../Text/globalStyle/textNormalClassName';
import calcRenderProps from './calcRenderProps';
import type { CalcProps, CmpSpecificStyles } from '../../Preview/flowTypes';
import type { ImageSliderComponent } from './flowTypes';
import './view/Preview.css';

type PreviewCalcProps = CalcProps<ImageSliderComponent>

export default {
    kind,
    view,
    calcProps: ({
        componentId,
        component,
        hdImages,
        siteMap,
        globalVariables,
        previewBackupTime,
        template
    }: PreviewCalcProps) => calcRenderProps({
        componentId,
        component,
        componentDependencies: {
            hdImages,
            // @ts-ignore
            textNormalClassName,
            site: siteMap,
            globalVariables,
            missingAssetUrls: [],
            templateWidth: template.width,
            workspaceBBoxWidth: 100,
            workspaceBBoxLeft: 0,
        },
        componentExtension: { shouldComponentUpdate: false },
        isWorkspace: false,
        previewBackupTime,
        inEditMode: false
    }),
    getSpecificStyles: ({ component, template }: CmpSpecificStyles<ImageSliderComponent>): string => {
        if (component.stretch) {
            return `
            div[data-id='${component.id}'] div.swiper-container {
                min-width: ${template.width}px;
                width: ${100 - (component.fullWidthOption.margin * 2)}%;
                max-width: ${component.fullWidthOption.maxWidth}px;
            }
            `;
        }
        return '';
    },
    js: [
        // Require for swiper slider
        "/renderStatic/3rdparty/swiper/swiper.modified.js",
        "/renderStatic/3rdparty/swiper/app.js",

        // Required for shinybox
        '/renderStatic/lightbox/shinybox/jquery.shinybox.modified.js',
        '/renderStatic/lightbox/lightbox_wbtgen.js'
    ],
    css: [
        "/renderStatic/3rdparty/swiper/swiper.css",
        '/renderStatic/lightbox/shinybox/shinybox.css'
    ]
};
