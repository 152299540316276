import CALL_API from "../../../redux/middleware/api/CALL_API";
import { PAGE_DATA_LOAD_REQUEST, PAGE_DATA_LOAD_SUCCESS, PAGE_DATA_LOAD_FAILURE } from "../actionTypes";
import { PAGE_DATA_SET_REQUEST_PUBLIC_API_TAG } from "../../App/epics/pageDataset/constants";
import type { ApiAction } from "../../../redux/middleware/api/flowTypes";

export default function (pageId: string, usePublicApiTag: boolean = true): any {
    const action: ApiAction = {
        [CALL_API]: {
            types: [
                PAGE_DATA_LOAD_REQUEST,
                PAGE_DATA_LOAD_SUCCESS,
                PAGE_DATA_LOAD_FAILURE
            ],
            endpoint: 'getPageDataSet',
            endpointParams: pageId
        }
    };
    if (usePublicApiTag) action[CALL_API].tag = PAGE_DATA_SET_REQUEST_PUBLIC_API_TAG;

    return action;
}
