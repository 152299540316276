import * as R from 'ramda';
import { receiveOnly, withSelector } from "../../../../../epics/makeCondition";

import isKeyPressedValueActionType from "../../../../App/epics/isKeyPressed/valueActionType";
import { keyNamesMap } from "../../../../App/epics/isKeyPressed/index";
import { ANIMATION_FRAME } from "../../../../../redux/middleware/raf";

import { UserFocusValueActionType } from "../../../../App/epics/userFocus/valueActionType";
import * as userFocusKind from "../../../../App/epics/userFocus/kind";

import { getNumRowsAndCols } from "../../utils";
import { ReceiveOnlySelectedComponentSelector } from "../../../../Workspace/epics/componentsEval/selectorActionTypes";
import type { TableEditModeUpdater } from './flowTypes';
import { nothingInEditModeIndex } from "./models/state";

const
    delay = 500,
    requiredKeys = [keyNamesMap.left, keyNamesMap.right, keyNamesMap.up, keyNamesMap.down, keyNamesMap.tab];

const makeKeyPressedSelector = key => withSelector(
    isKeyPressedValueActionType,
    state => Boolean(state.pressedKeysMap[key])
);

const makeSelectionUpdater = (cells, directions, hasShift) => {
    const numCells = cells.length;
    const { numCols } = getNumRowsAndCols(cells);
    const moves = {
        [keyNamesMap.left]: n => {
            return n % numCols !== 0 ? n - 1 : n + numCols - 1;
        },
        [keyNamesMap.right]: n => {
            return (n + 1) % numCols !== 0 ? n + 1 : n - numCols + 1;
        },
        [keyNamesMap.up]: n => {
            return n - numCols < 0 ? numCells + n - numCols : n - numCols;
        },
        [keyNamesMap.down]: n => {
            return n + numCols >= numCells ? n + numCols - numCells : n + numCols;
        },
        [keyNamesMap.tab]: n => {
            if (hasShift) {
                return n === 0 ? numCells - 1 : n - 1;
            }

            return n === numCells - 1 ? 0 : n + 1;
        },
        [keyNamesMap.shift]: R.identity
    };

    const chainMoveUpdater = R.pipe(...directions.map(direction => moves[direction]));
    return R.evolve({
        selectedCellsIndexes: n => (n.length ? [chainMoveUpdater(n[0])] : [0])
    });
};

const makeResetResult = (state, scope) => ({
    state,
    scope: R.evolve({
        prevPressedKeysMap: () => ({ pressedKeys: 0 })
    })(scope)
});

const isValidScope = (userFocus, cellInEditModeIndex, key?: string) => (
    !userFocus.comboBoxOpen && !userFocus.inputInFocus && (
        (userFocus.kind === userFocusKind.EDIT_TABLE_COMPONENT && cellInEditModeIndex === -1) ||
        (key === keyNamesMap.tab && (
            userFocus.kind === userFocusKind.EDIT_TABLE_COMPONENT_CELL && cellInEditModeIndex > -1
        ))
    )
);

const keyPressUpdaters: Array<TableEditModeUpdater> = (
    requiredKeys.concat(keyNamesMap.shift).map(key => ({
        conditions: [
            ReceiveOnlySelectedComponentSelector,
            receiveOnly(UserFocusValueActionType),
            makeKeyPressedSelector(key)
        ],
        reducer: ({ values: [component, userFocus, keyDown], state, scope }) => {
            const { cellInEditModeIndex } = state;
            const { prevPressedKeysMap } = scope;

            if (!isValidScope(userFocus, cellInEditModeIndex, key)) {
                if (prevPressedKeysMap.pressedKeys > 0) {
                    return makeResetResult(state, scope);
                }

                return { state, scope };
            }

            let newState = state;
            let newScope = scope;
            let actionToDispatch;

            if (keyDown) {
                newScope = R.evolve({
                    prevPressedKeysMap: m => {
                        const newMap = R.evolve({
                            pressedKeys: R.inc
                        })(m);

                        newMap[key] = Date.now() + delay;
                        return newMap;
                    }
                })(scope);

                const isShift = key === keyNamesMap.shift;
                if (!isShift || prevPressedKeysMap[keyNamesMap.tab]) {
                    const
                        direction = !isShift ? key : keyNamesMap.tab,
                        hasShift = isShift || Boolean(prevPressedKeysMap[keyNamesMap.shift]);

                    newState = R.pipe(
                        makeSelectionUpdater(component.cells, [direction], hasShift),
                        R.assoc('cellInEditModeIndex', nothingInEditModeIndex)
                    )(state);
                }
            } else if (prevPressedKeysMap.pressedKeys) {
                newScope = R.evolve({
                    prevPressedKeysMap: m => {
                        const newMap = R.evolve({
                            pressedKeys: R.dec
                        })(m);

                        newMap[key] = false;
                        return newMap;
                    }
                })(scope);
            }

            return {
                state: newState,
                scope: newScope,
                actionToDispatch
            };
        }
    }))
);

const animationFrameUpdater: TableEditModeUpdater = {
    conditions: [
        ReceiveOnlySelectedComponentSelector,
        receiveOnly(UserFocusValueActionType),
        ANIMATION_FRAME
    ],
    reducer: ({ values: [component, userFocus], state, scope }) => {
        const { cellInEditModeIndex } = state;
        const { prevPressedKeysMap } = scope;

        if (!isValidScope(userFocus, cellInEditModeIndex)) {
            if (prevPressedKeysMap.pressedKeys > 0) {
                return makeResetResult(state, scope);
            }

            return { state, scope };
        }

        let newState = state;
        let moveDirections: string[] = [];
        const now = Date.now();

        moveDirections = requiredKeys.filter(key => {
            const keyState = prevPressedKeysMap[key];
            return Boolean(keyState) && now > keyState;
        });

        if (moveDirections.length) {
            const shiftValue = prevPressedKeysMap[keyNamesMap.shift];
            const hasShift = shiftValue && now > shiftValue;
            newState = makeSelectionUpdater(component.cells, moveDirections, hasShift)(state);
        }

        return {
            state: newState,
            scope
        };
    }
};

export const selectionChangeUpdaters = [
    ...keyPressUpdaters,
    animationFrameUpdater
];
