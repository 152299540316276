import * as R from 'ramda';
import { DataPage, DataPageSet } from "../../../../dal/model";
import DataPageTemplate from "../../../../dal/model/DataPageTemplate";
import { ItemsPlane } from "./ItemsPlane";
import isDevEnv from '../../../debug/isDevEnv';
import { updateItemsRelations } from "./updateItemsRelations";
import { OldComponentTypes } from "../../../../dal/pageMapAdapter/componentTypesMap";
import ComponentTypes from "../../../view/oneweb/registry/ComponentTypes";
import { mapBackFromComponent } from "../../../../dal/pageMapAdapter/mappers";
import { workspaceStylesheet } from "./workspaceStylesheet.td";
import { mapPageDataToComponentsMap } from "../../../../dal/pageMapAdapter";
import { getTitleForSection } from "../../Workspace/epics/componentsEval/getSectionTitles";
import { createSection } from "../../oneweb/Section/utils";
import { alignComponentsToCenterBasedOnTemplateWidthChange } from "./utils";

type Params = {
    from: {
        page: DataPage;
        template: DataPageTemplate;
    };
    toTemplate: DataPageTemplate;
    debug?: boolean;
};

type Result = {
    page: DataPage,
    template: DataPageTemplate,
    debug?: {
        tplane: typeof ItemsPlane,
        pplane: typeof ItemsPlane,
    },
};

export const
    sortTopFn = (s1: Record<string, any>, s2: Record<string, any>) => (s1.bbox.top - s2.bbox.top),
    sortBottomFn = (s1: Record<string, any>, s2: Record<string, any>) => (s1.bbox.bottom - s2.bbox.bottom),
    filterSectionsAndSortByTopFn = R.pipe(
        R.filter(({ type }) => (type === OldComponentTypes[ComponentTypes.SECTION])),
        R.sort(sortTopFn)
    ),
    updateItemBbox = (item: Record<string, any>, objProp: Record<string, any>) => ({
        ...item,
        bbox: {
            ...item.bbox,
            ...objProp
        }
    });
export const adjustPageItemsToTemplate = (
    { from: { page, template }, toTemplate, debug = false }: Params,
    headerPageCmps: Array<Record<string, any>> = [],
    footerPageCmps: Array<Record<string, any>> = []
): Result => {
    const templateSections = filterSectionsAndSortByTopFn(template.items),
        header = R.head(templateSections),
        footer = R.last(templateSections),
        { width: pageLayoutWidth } = template,
        { width: templateWidth } = toTemplate;

    if (!header && !footer) return { page, template: toTemplate };
    const
        pageDataSet = new DataPageSet({
            page: { items: page.items },
            template,
            stylesheet: workspaceStylesheet
        });

    let heightAdjustmentForHeaderPageComponents = 0,
        heightAdjustmentForFooterPageComponents = 0,
        adjustedPageItems = [...page.items],
        componentsMap = mapPageDataToComponentsMap(pageDataSet, undefined, false),
        syncRelations = false;

    const padding = 15;
    const topBottomPadding = padding * 2;
    // @ts-ignore
    const headerPageComponents = headerPageCmps.map((cmp): Record<string, any> => adjustedPageItems.find(({ id }) => id === cmp.id));
    // @ts-ignore
    const footerPageComponents = footerPageCmps.map((cmp): Record<string, any> => adjustedPageItems.find(({ id }) => id === cmp.id));
    if (headerPageComponents.length) {
        const firstHeaderPageComponent = R.head(headerPageComponents),
            lastHeaderPageComponent = R.pipe(
                R.sort(sortBottomFn),
                R.last
            )(headerPageComponents);

        heightAdjustmentForHeaderPageComponents = (lastHeaderPageComponent.bbox.bottom
            - firstHeaderPageComponent.bbox.top) + topBottomPadding;

        // adjust page items top and bottom because we are inserting new section just below the header
        const bboxAdjustedPageItems = R.pipe(
                R.filter(item =>
                    !R.find(R.propEq('id', item.id))(headerPageComponents)
                    && !R.find(R.propEq('id', item.id))(footerPageComponents)),
                R.map(item => updateItemBbox(item, {
                    top: item.bbox.top + heightAdjustmentForHeaderPageComponents,
                    bottom: item.bbox.bottom + heightAdjustmentForHeaderPageComponents,
                }))
            )(page.items),
            newSectionTop = header.bbox.bottom;

        let newSectionBelowHeader = createSection({
            top: newSectionTop,
            bottom: newSectionTop + heightAdjustmentForHeaderPageComponents,
            inTemplate: false,
            orderIndex: 0
        });
        newSectionBelowHeader = {
            ...newSectionBelowHeader,
            title: getTitleForSection(componentsMap, newSectionBelowHeader)
        };
        componentsMap = {
            ...componentsMap,
            [newSectionBelowHeader.id]: {
                ...newSectionBelowHeader
            }
        };

        const adjustedHeaderPageComponents = headerPageComponents.map(item => updateItemBbox(item, {
            top: item.bbox.top + (newSectionTop - firstHeaderPageComponent.bbox.top) + padding,
            bottom: item.bbox.bottom + (newSectionTop - firstHeaderPageComponent.bbox.top) + padding,
        }));

        adjustedPageItems = [mapBackFromComponent(newSectionBelowHeader, {}), ...adjustedHeaderPageComponents, ...bboxAdjustedPageItems];
        syncRelations = true;
    }
    if (footerPageComponents.length) {
        const firstPageComponentInFooter = R.head(footerPageComponents),
            lastPageComponentInFooter = R.pipe(
                R.sort(sortBottomFn),
                R.last
            )(footerPageComponents),
            lastPageSection = R.pipe(
                filterSectionsAndSortByTopFn,
                R.last
            )(adjustedPageItems),
            newSectionTop = lastPageSection.bbox.bottom;

        heightAdjustmentForFooterPageComponents = (lastPageComponentInFooter.bbox.bottom
            - firstPageComponentInFooter.bbox.top) + topBottomPadding;

        let newSectionAboveFooter = createSection({
            top: newSectionTop,
            bottom: newSectionTop + heightAdjustmentForFooterPageComponents,
            inTemplate: false,
            orderIndex: 0
        });
        newSectionAboveFooter = {
            ...newSectionAboveFooter,
            title: getTitleForSection(componentsMap, newSectionAboveFooter)
        };
        const adjustedFooterPageComponents = footerPageComponents.map(item => updateItemBbox(item, {
            top: newSectionTop + (item.bbox.top - firstPageComponentInFooter.bbox.top) + padding,
            bottom: (newSectionTop + (item.bbox.top - firstPageComponentInFooter.bbox.top))
                + (item.bbox.bottom - item.bbox.top) + padding,
        }));

        adjustedPageItems = [
            mapBackFromComponent(newSectionAboveFooter, {}),
            ...adjustedPageItems.filter(item => !R.find(R.propEq('id', item.id))(footerPageComponents)),
            ...adjustedFooterPageComponents
        ];
        syncRelations = true;
    }

    let newPageItems = [...page.items],
        newTemplateItems = [...toTemplate.items];

    const templateWidthDiff = templateWidth - pageLayoutWidth;
    newPageItems = alignComponentsToCenterBasedOnTemplateWidthChange(newPageItems, templateWidthDiff);

    if (syncRelations) {
        ({ page: { items: newPageItems }, template: { items: newTemplateItems } } = updateItemsRelations(
            new DataPage({ ...page, items: adjustedPageItems }),
            new DataPageTemplate({ ...toTemplate, items: toTemplate.items }),
        ));
    }

    const result: Result = {
        template: new DataPageTemplate({ ...toTemplate, items: newTemplateItems }),
        page: new DataPage({ ...page, items: newPageItems }),
    };

    if (isDevEnv() && debug) {
        // @ts-ignore
        result.debug = { tplane: toTemplate, pplane: page };
    }

    return result;
};
