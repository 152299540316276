import { DocumentComponent } from "./view/DocumentComponent";
import { documentComponentCalcRenderProps } from "./view/documentComponentCalcRenderProps";

const MOBILE_DOCUMENT_MAX_HEIGHT = 500;

export const documentComponentMobileEditorConfig = {
    view: DocumentComponent,
    calcProps: ({ component, componentDependencies, rootContainerRect, isReordered, shortcut }) =>
        documentComponentCalcRenderProps({
            component,
            componentDependencies,
            isSelected: false,
            isResizing: false,
            componentExtension: undefined,
            componentId: component.id,
            inEditMode: false,
            isWorkspace: true,
            componentHeight: rootContainerRect && rootContainerRect.height
                ? Math.min(rootContainerRect.height * 0.9, MOBILE_DOCUMENT_MAX_HEIGHT)
                : 0,
            isReordered,
            isMve: true,
            shortcut,
        }),
};
