import makePages from "../../../PropertiesPanel/view/makePages";
import * as pageIds from "./pageIds";
import mainPage from './pages/main';
import { imagePatternPage } from "../../Background/propertiesPanel/pages/index";
import borderPage from './pages/border';
import scrollEffectsPage from './pages/scrollEffects';
import stripColorPage from './pages/stripColorPage';
import titlePage from './pages/title';

export const makeStripPropertiesPanelConfig = ({
    mainPageConfig = {},
    imagePatternPageConfig = {},
    shopStripPageConfig = {}
}: { imagePatternPageConfig?: { view?: AnyReactComponent },
    shopStripPageConfig?: { view?: AnyReactComponent, id?: string, title?: string },
    mainPageConfig?: { title?: string } } = {}) => {
    return {
        pages: makePages(
            { ...mainPage, ...mainPageConfig },
            stripColorPage,
            { ...imagePatternPage, ...imagePatternPageConfig },
            borderPage,
            titlePage,
            scrollEffectsPage,
            shopStripPageConfig as any
        ),
        defaultPageId: pageIds.MAIN,
        reducer: () => {}
    };
};

const propertiesPanelConfig = makeStripPropertiesPanelConfig();

export default propertiesPanelConfig;
