import { adjustShouldNotOverlapSelectedComponentAndMCTA } from "../utils/position";
import shouldShow from "../utils/shouldShow";
import onComponentSelection from "./onComponentSelection";
import type { StateHolder, SelectedComponentWithId } from "../../flowTypes";
import type {
    Dimensions,
    ComponentsDependencies,
    ComponentsMap,
    BBox
} from "../../../../redux/modules/children/workspace/flowTypes";
import type { Stylesheets } from "../../../Workspace/epics/stylesheets/flowTypes";
import type { Position } from "../../../../redux/modules/flowTypes";
import calcMobileEnablement from "../utils/calcMobileEnablement";
import type { RelationsMap } from "../../../Workspace/epics/relations/flowTypes";

export default (
    userInteractionMode: string,
    selectedComponentWithId: SelectedComponentWithId,
    browserDimensions: Dimensions,
    leftPanelWidth: number,
    componentDependencies: ComponentsDependencies,
    stylesheets: Stylesheets,
    editModeComponentId: string | null,
    templateOffset: Position,
    stateHolder: StateHolder,
    componentsMap: ComponentsMap,
    relInChanges: RelationsMap,
    mctaBBox: BBox,
    codeComponentsRendererHeadHeight: number
): StateHolder => {
    if (!selectedComponentWithId) {
        return stateHolder;
    }

    const { scope, state, state: { dimensions, navigation } } = stateHolder;
    if (
        shouldShow(userInteractionMode) &&
        editModeComponentId &&
        navigation &&
        componentsMap[selectedComponentWithId.id]
    ) {
        const
            mobileEnablement = calcMobileEnablement(componentsMap, relInChanges, selectedComponentWithId.id),
            context = {
                selectedComponentWithId,
                browserDimensions,
                leftPanelWidth,
                componentDependencies,
                stylesheets,
                mode: userInteractionMode,
                templateOffset,
                mobileEnablement,
                mctaBBox,
                codeComponentsRendererHeadHeight
            };

        if (state.selectedComponentWithId && selectedComponentWithId.id === state.selectedComponentWithId.id) {
            const newPosition = adjustShouldNotOverlapSelectedComponentAndMCTA({
                context,
                dimensions,
                position: state.position,
                navigation
            });
            return {
                scope: { ...scope, actualPosition: newPosition, mode: userInteractionMode },
                state: { ...state, show: true, position: newPosition, mobileEnablement }
            };
        }
        return onComponentSelection(context, { ...scope, mode: userInteractionMode });
    } else if (state.show) {
        return {
            scope: { ...scope, mode: userInteractionMode },
            state: { ...state, show: false }
        };
    } else if (userInteractionMode !== scope.mode) {
        return {
            scope: { ...scope, mode: userInteractionMode },
            state
        };
    }
    return stateHolder;
};
