import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { useIntl } from "../../../../../../wbtgen/src/view/intl";

import imageStyles from "../../Images/imageStyle.css";

const useStyles = makeStyles(
    (theme) => ({
        helpBoxHeader: {
            fontSize: "18px",
            lineHeight: "26px",
            letterSpacing: "0.2px",
        },
        helpBoxBody: {
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0.2px",
            marginTop: "8px",
        },
        helpBoxLink: {
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "0.1px",
            color: theme.palette.custom.link || theme.palette.primary.main,
            marginTop: "8px",
        },
    }),
    { name: "SeoHelpBox" }
);

export const SeoHelpBox = () => {
    const
        intl = useIntl(),
        classes = useStyles();

    return (
        <Box
            width={{ xs: 'auto', md: '380px' }}
            display="flex"
            alignItems="flex-start"
            bgcolor="rgb(247, 247, 247)"
            padding="20px 28px"
            style={{ userSelect: 'none' }}
            data-testid="seo-help-box"
        >
            <Box width="48px" height="48px" className={imageStyles.seoImportant} />

            <Box flex={1} marginLeft="20px">
                <Typography className={classes.helpBoxHeader}>
                    {intl.msgJoint("msg: seoScan.whySeoImportant.title {Why SEO is so important}")}
                </Typography>
                <Typography className={classes.helpBoxBody}>
                    {intl.msgJoint("msg: seoScan.whySeoImportant.description {In short, SEO is crucial because it makes your website more visible.}")}
                </Typography>
                <Typography className={classes.helpBoxBody}>
                    <Link
                        href={intl.msgJoint("msg: seoScan.whySeoImportant.findOutMoreLink {https://help.one.com/hc/en-us/articles/115005594085}")}
                        target="_blank"
                        className={classes.helpBoxLink}
                    >
                        {intl.msgJoint("msg: seoScan.whySeoImportant.findOutMore {Find out more about SEO}")}
                    </Link>
                </Typography>
            </Box>
        </Box>
    );
};
