/* eslint-disable max-len */
import React from "react";
import cx from 'classnames';
import { connect } from "react-redux";
import { Msg } from "../../../../../../../view/intl/index";
import getCenteredDialogConfig from "../../../../../../DialogManager/getCenteredDialogConfig";
import upgradePlanStyles from '../../UpgradePlansDialog.css';
import styles from './UpgradeApproveDialog.css';
import { StripTypes } from "../../../../../../../view/common/dialogs/baseDialog/index";
import { DialogHeight, DialogWidth, DialogCTAS } from "./constants";
import { closeDialog } from "../../../../../../App/actionCreators/index";
import { StandardDialog } from "../../../../../../../view/common/dialogs/StandardDialog/index";
import { seamlessUpgradeAction } from "../../../actionCreator";
import {
    UpgradeDialogPlanTypeMap
} from "../../constants";
import VerticalSpacer from "../../../../../../../view/common/VerticalSpacer";
import { freeUpgradeApprovedAction, upgradeCanceledAction } from "../../../../../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions";
import { UpgradeCancelReason } from "../../../../../../EventTracking/constants";
import { useIntl } from "../../../../../../../view/intl/injectIntl";
import { getCapitalizedCaseName } from "../../../../../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/utils";
import { subscriptionTypeSelector } from "../../../../../../Onboarding/Dynamic/Epic/selectors";
import { SubscriptionTypeT } from "../../../../../../../redux/modules/flowTypes";

type Props = {
    dispatch: Dispatch,
    freeMonths: number,
    pricePerMonth: string,
    upgradeType: string,
    subscriptionType: SubscriptionTypeT
}

const TermsLink = () => (
    <a
        href="https://www.one.com/en/terms-and-conditions-eur"
        className={cx(
            upgradePlanStyles.upgradePlanTxtCommonStyle,
            styles.upgradeApproveTxtStyle,
            styles.upgradeApproveFooterLinkColor
        )}
        target="_blank"
    >
        <Msg k="upgrade.approve.terms.footer.link">Terms of use</Msg>
    </a>
);

const UpgradeApproveDialog = (props: Props) => {
    const
        {
            dispatch,
            freeMonths,
            pricePerMonth,
            upgradeType,
            subscriptionType: currentSubscriptionType
        } = props,
        intl = useIntl(),
        upgradeToType = getCapitalizedCaseName(intl.msgJoint(UpgradeDialogPlanTypeMap[upgradeType])),
        currentSubscriptionTypeLabel = getCapitalizedCaseName(
            intl.msgJoint(UpgradeDialogPlanTypeMap[currentSubscriptionType])
        ),
        title = <Msg
            className={cx(upgradePlanStyles.upgradePlanTxtCommonStyle, upgradePlanStyles.approvePlansDescTitle)}
            k="upgrade.approve.dialog.title"
            params={{ upgradeToType }}
        >
            {`Upgrade to {upgradeToType}`}
        </Msg>,
        message1 = <Msg
            className={cx(upgradePlanStyles.upgradePlanTxtCommonStyle, styles.upgradeApproveTxtStyle, styles.upgradeApproveTxtColor)}
            k="upgrade.approve.dialog.message1"
            params={{ freeMonths, pricePerMonth }}
        >
            {`You will get access to the service free of charge for the next {freeMonths} months. After that, it is just {pricePerMonth} /month added to your current hosting price.`}
        </Msg>,
        message2 = <Msg
            className={cx(upgradePlanStyles.upgradePlanTxtCommonStyle, styles.upgradeApproveTxtStyle, styles.upgradeApproveTxtColor)}
            k="upgrade.approve.dialog.message3"
            params={{ upgradeToType, currentSubscriptionTypeLabel }}
        >
            {`You can downgrade from Website Builder {upgradeToType} plan to {currentSubscriptionTypeLabel} anytime during the free period.`}
        </Msg>,
        termsLink = <TermsLink />;

    const onApprove = () => {
        dispatch(freeUpgradeApprovedAction());
        dispatch(seamlessUpgradeAction(upgradeType));
        dispatch(closeDialog());
    };

    const onCancelOrClose = () => {
        dispatch(closeDialog());
        dispatch(upgradeCanceledAction(UpgradeCancelReason.UserCancelled));
    };

    return (
        <div className={styles.upgradeApproveContainer}>
            <StandardDialog
                title={title}
                autoFocus={false}
                stripType={StripTypes.NONE}
                mctaText={DialogCTAS.APPROVE_LABEL}
                sctaText={DialogCTAS.CANCEL_LABEL}
                titleBoxClassName={styles.upgradeApproveTitleClass}
                sctaBtnClass={styles.cancelBtn}
                footerClassName={styles.footerClassName}
                primaryBtnClass={styles.primaryBtnClass}
                mctaHandler={onApprove}
                sctaHandler={onCancelOrClose}
                onClose={onCancelOrClose}
                noTopBorderForFooter
                disabled={false}
            >
                <div className={styles.upgradeApproveTxtContainer}>{message1}</div>
                <VerticalSpacer y={30} />
                <div className={styles.upgradeApproveTxtContainer}>{message2}</div>
            </StandardDialog>
            <div className={styles.upgradeApproveFooterTextContainer}>
                <Msg
                    className={
                        cx(
                            upgradePlanStyles.upgradePlanTxtCommonStyle,
                            styles.upgradeApproveTxtStyle,
                            styles.upgradeApproveFooterLinkColor,
                        )
                    }
                    k="upgrade.approve.terms.footer"
                    params={{ link: termsLink }}
                >{`By clicking approve you agree to our {link}`}</Msg>
            </div>
        </div>
    );
};

const mapStateToProps = appState => {
    const subscriptionType = subscriptionTypeSelector(appState);
    return { subscriptionType };
};
export default getCenteredDialogConfig({
    width: DialogWidth,
    height: DialogHeight,
    component: connect(mapStateToProps)(UpgradeApproveDialog),
    dragEnabled: false
});
