import makePages from '../../../PropertiesPanel/view/makePages';
import reducer from '../reducer';
import * as main from './pages/main';
import * as layout from './pages/layout';
import * as captions from './pages/captions';
import * as captionBoxStyle from './pages/captionBoxStyle';
import * as captionDescriptionStyle from './pages/captionDescriptionStyle';
import * as onClick from './pages/onClick';
import * as account from './pages/account';
import * as profileImage from './pages/profileImage';
import addGoogleFont from "../../../PropertiesPanel/view/AddGoogleFont/index";

const propertiesPanelConfig = {
    pages: makePages(
        main,
        layout,
        onClick,
        captions,
        captionDescriptionStyle,
        captionBoxStyle,
        addGoogleFont,
        account,
        profileImage
    ),
    defaultPageId: main.id,
    reducer
};

export default propertiesPanelConfig;
