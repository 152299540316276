import type { AppStore } from "../../../../redux/modules/flowTypes";
import {
    SELECT_SHUTTERSTOCK_CATEGORY_ACTION,
    SHUTTERSTOCK_GRID_SCROLLED_TO_BOTTOM_ACTION,
    SHUTTERSTOCK_SEARCH_CLICK_ACTION,
    SHUTTERSTOCK_SEARCH_ENTER_PRESS_ACTION,
    SHUTTERSTOCK_CLEAN_SEARCH_ACTION,
    HIDE_SHUTTERSTOCK_PREMIUM_DOWNLOADS_MSG_ACTION,
    SHUTTERSTOCK_PORTRAIT_ORIENTATION_CLICKED_ACTION,
    SHUTTERSTOCK_LANDSCAPE_ORIENTATION_CLICKED_ACTION,
    SHUTTERSTOCK_TYPE_IMAGES_CLICKED_ACTION,
    SHUTTERSTOCK_TYPE_ILLUSTRATIONS_CLICKED_ACTION,
    getShutterstockCategoriesAction,
    getShutterstockDownloadedImagesAction,
    initializeShutterstockAction,
    loadShutterstockImagesPageAction,
    searchShutterstockImagesAction
} from "../actions";
import {
    shutterstockImagesPageLoadingAppSel,
    shutterstockQueryAppSel,
    shutterstockInitializedAppSel,
} from "../selectors";
import { ShutterstockKind } from "../constants";
import {
    SWITCH_FM_TAB_ACTION
} from "../../dialog/actions";
import { FmTabId } from "../../dialog/constants";
import { Lit } from "../../../../lit";
import ls from '../../../../utils/localStorage';
import { fcDialogActiveTabIdAppSel } from "../../../../redux/modules/children/fileChooser/selectors";
import { getPaidShutterstockImagePriceAction } from "../../paidShutterstock/actions";
import { isDemoSubscriptionTypeAppSel } from "../../../../../demo/modules/selectors";

export const shutterstockCommonMiddleware = (store: AppStore) => (next: Dispatch) => (action: Action) => {
    if (action.type === SWITCH_FM_TAB_ACTION) {
        const { tabId } = action.payload;

        if ([FmTabId.MY_IMAGES, FmTabId.MY_VIDEOS, FmTabId.UNSPLASH, FmTabId.FREE_ONECOM_IMAGES].includes(tabId)) {
            return next(action);
        }

        const
            state = store.getState(),
            query = shutterstockQueryAppSel(tabId)(state),
            initialized = shutterstockInitializedAppSel(tabId)(state),
            isDemo = isDemoSubscriptionTypeAppSel(state);

        if (initialized) {
            return next(action);
        }

        store.dispatch(initializeShutterstockAction(tabId));
        store.dispatch(getShutterstockCategoriesAction(tabId));
        store.dispatch(searchShutterstockImagesAction(tabId, query));
        if (tabId === ShutterstockKind.FREE) {
            store.dispatch(getShutterstockDownloadedImagesAction(tabId));
        }

        if (tabId === ShutterstockKind.PAID && !isDemo) {
            store.dispatch(getPaidShutterstockImagePriceAction());
        }

        return next(action);
    }

    if (action.type === SHUTTERSTOCK_GRID_SCROLLED_TO_BOTTOM_ACTION) {
        const
            state = store.getState(),
            tabId = fcDialogActiveTabIdAppSel(state),
            imagesPageLoading = shutterstockImagesPageLoadingAppSel(tabId)(state);

        // load page after page
        if (imagesPageLoading) return next(action);

        const
            search = shutterstockQueryAppSel(tabId)(state),
            query = {
                ...search,
                [Lit.page]: (search.page || 0) + 1
            };

        store.dispatch(loadShutterstockImagesPageAction(tabId, query));

        return next(action);
    }

    if (
        action.type === SHUTTERSTOCK_SEARCH_CLICK_ACTION ||
        action.type === SHUTTERSTOCK_SEARCH_ENTER_PRESS_ACTION ||
        action.type === SHUTTERSTOCK_TYPE_IMAGES_CLICKED_ACTION ||
        action.type === SHUTTERSTOCK_TYPE_ILLUSTRATIONS_CLICKED_ACTION ||
        action.type === SHUTTERSTOCK_PORTRAIT_ORIENTATION_CLICKED_ACTION ||
        action.type === SHUTTERSTOCK_LANDSCAPE_ORIENTATION_CLICKED_ACTION ||
        action.type === SELECT_SHUTTERSTOCK_CATEGORY_ACTION ||
        action.type === SHUTTERSTOCK_CLEAN_SEARCH_ACTION
    ) {
        const
            state = store.getState(),
            activeTabId = fcDialogActiveTabIdAppSel(state),
            nextResult = next(action),
            search = shutterstockQueryAppSel(activeTabId)(store.getState()),
            query = {
                ...search,
                [Lit.page]: 1
            };

        store.dispatch(searchShutterstockImagesAction(activeTabId, query));

        return nextResult;
    }

    if (action.type === HIDE_SHUTTERSTOCK_PREMIUM_DOWNLOADS_MSG_ACTION) {
        ls.set(Lit.showPremiumDownloadsMsg, false);
    }

    return next(action);
};
