import { WIDGETS_EVENTBRITE_SHOW_UPDATE_LINK_DIALOG } from './actionTypes';
import { ctaOnClickFactory, linkExtractor } from '../utils';

const componentMainActions = {
    editButtonText: 'msg: common.settings {Settings}',
    ctaButtonText: 'msg: widgets.eventbrite.cta.changeCheckout {Change checkout}',
    ctaOnClick: ctaOnClickFactory(
        WIDGETS_EVENTBRITE_SHOW_UPDATE_LINK_DIALOG,
        component => ({ link: linkExtractor(component) })
    ),
};

export { componentMainActions };

