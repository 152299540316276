import { ImageEditorDialogProps } from "./flowTypes";
import { OPEN_IMAGE_EDITOR_DIALOG } from "../../../../components/ImageEditor/actionTypes";

type Action = DialogOpenAction<ImageEditorDialogProps>;

const openImageEditorDialog = (props: any): Action => ({
    type: OPEN_IMAGE_EDITOR_DIALOG,
    payload: props
});

export default openImageEditorDialog;
