export const GLOBAL_STYLE_PROPS = ['globalId', 'ref', 'globalName'];

export const ELEMENT_IGNORED_PROPS = ['stop', 'start', 'end', 'atype', 'type'];
export const STYLES_IGNORED_PROPS = [...ELEMENT_IGNORED_PROPS, 'full'];
export const PARAGRAPH_ELEMENT_IGNORED_PROPS = [...ELEMENT_IGNORED_PROPS, ...GLOBAL_STYLE_PROPS];

export const BREAKING_STYLE_TYPES = ['para', 'listItem'];
export const BREAKING_STYLES = ['underline'];
export const UNNESTABLE_STYLES = [...GLOBAL_STYLE_PROPS, 'size'];

export const PARAGRAPH_STYLE = ['align', 'height'];
export const HEADER_STYLE_EXCLUDED = ['highlight'];
