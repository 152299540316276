import React, { useEffect } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { Dialog, IconButton, Fade as FadeTransition } from '@mui/material';

import { CloseIcon } from '../../common/icons';
import { SlideUpTransition } from './SlideUpTransition';
import { useIsMobile } from '../../hooks/useIsMobileOrTablet';
import { useStyles } from './styles';

export const BasicDialog = (props) => {
    const {
        onClose,
        closeButtonStyleClass,
        showCloseIcon = true,
        isFullScreenInMobile = false,
        containerClass = '',
        children,
        maxWidth = 'sm',
        dialogId
    } = props;

    const classes = useStyles();

    const isMobile = useIsMobile();
    const isFullScreen = isMobile && isFullScreenInMobile;
    const TransitionComponent = isMobile || isFullScreen ? SlideUpTransition : FadeTransition;

    const history = useHistory();

    const onCloseHandler = () => {
        if (history.state && history.state.dialogId) {
            history.goBack();
        }
        onClose();
    };

    useEffect(() => {
        return history && history.listen(() => {
            if (history.action === 'POP') {
                onCloseHandler();
            }
        });
    }, []);

    return (
        <Dialog
            open
            fullScreen={isFullScreen}
            maxWidth={maxWidth}
            onClose={(_event, reason) => {
                if (reason !== "backdropClick") {
                    onCloseHandler();
                }
            }}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            classes={props.classes}
            className={classes.dialogContainer}
            disableEscapeKeyDown
            TransitionComponent={TransitionComponent}
            data-testid={dialogId}
        >
            {
                showCloseIcon && (
                    <IconButton
                        aria-label="close"
                        className={cx(classes.closeButton, closeButtonStyleClass)}
                        onClick={onCloseHandler}
                        data-testid="dialog-close-icon"
                        size="large"
                        disableRipple
                    >
                        <CloseIcon />
                    </IconButton>
                )
            }
            <div className={cx(classes.container, containerClass)}>
                {children}
            </div>
        </Dialog>
    );
};
