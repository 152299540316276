import type { AnyComponent, Stylesheet, Element, HtmlWriterData } from '../../flowTypes';
import {
    elementParser,
    fullStyleParser,
    headerStyleParser,
    textParser
} from '../parsers/index';
import { sortElements } from '../helpers/index';
import { makeNoEmptyParagraphElementFilter } from '../filters/index';
import { getStyleElements, getParagraphElements, getLinkElements, getListElements, getParagraphs } from './index';
import {
    removeZeroLengthElements,
    addParaStyleToHeader,
    addGlobalStyleToOverridenGlobalStyle
} from './utils/index';
import { DataSite } from "../../../../../dal/model/index";

export default (
    data: HtmlWriterData,
    defaultGlobalStyles: Array<Stylesheet>,
    site: null | undefined | DataSite,
    wrappedItems: Record<number, AnyComponent>,
    editMode: boolean = false
) => {
    let updatedWrappedItems;
    let { textParagraphs, listParagraphs } = getParagraphs(data);

    // split styles over paras and lists
    let styleElements: Array<Element> = getStyleElements(
        data, defaultGlobalStyles,
        textParagraphs, listParagraphs
    );

    // remove zero length elements from old wsb ()
    if (Object.keys(wrappedItems).length) {
        const filteredElements = removeZeroLengthElements(textParagraphs, styleElements, wrappedItems);
        textParagraphs = filteredElements.paragraphElements;
        styleElements = filteredElements.styleElements;
        updatedWrappedItems = filteredElements.wrappedItems;
    }

    // Append styles to paras and lists
    let listElements: Array<Element> = getListElements(data, styleElements, listParagraphs);
    let { paragraphElements, listParagraphElements } = getParagraphElements(
        data, styleElements,
        textParagraphs, defaultGlobalStyles
    );

    // get styles (some styles are overlaping multiple paragraphs so we need to divide them)
    styleElements = fullStyleParser(styleElements, textParagraphs);

    // if its edit mode we dont use header tag
    if (!editMode) {
        const headerStyles = headerStyleParser(paragraphElements, styleElements);
        styleElements = addParaStyleToHeader(paragraphElements, styleElements);

        // Header tags are created from the style entry, no need for paragraph entry for it
        const noEmptyParagraphElementFilter = makeNoEmptyParagraphElementFilter(styleElements);
        paragraphElements = paragraphElements.filter(noEmptyParagraphElementFilter);

        styleElements = sortElements(headerStyles, styleElements);
    }

    // sort & merge elements
    const sortedElements = sortElements(styleElements, listElements, paragraphElements, listParagraphElements);
    // parse elements (merge styles attributes etc)
    const elements = elementParser(sortedElements);
    // some global styles are overriden and needs to be reapplied
    let elementsWithOverridenGlobalStyles = addGlobalStyleToOverridenGlobalStyle(elements);

    // link elements
    let linkElements: Array<Element> = site ? getLinkElements(
        data,
        site,
        paragraphElements,
        listElements,
        elementsWithOverridenGlobalStyles,
    ) : []; // TODO merge page to template is not passing site

    /**
     * We dont want to merge links so we need to put them in right position
     */
    const elementsWithLinks = sortElements(elementsWithOverridenGlobalStyles, linkElements);
    const textElements = textParser(elementsWithLinks, data.text);
    return {
        wrappedItems: updatedWrappedItems || wrappedItems,
        elements: sortElements(textElements, elementsWithLinks)
    };
};
