import React from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import cx from "classnames";
import { Button } from "@mui/material";
import { useIntl } from "../../../../../../wbtgen/src/view/intl";

import { RenderWhen } from "../../Helpers/RenderWhen";
import { useStyles } from "./styles";
import { bannerCloseButtonClickedAction } from "../actions";
import { BannerProps } from "./types";

import imageStyles from "../../Images/imageStyle.css";

export const Banner = React.forwardRef((config: BannerProps, ref: React.Ref<any>) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const classes = useStyles();

    return (
        <div
            ref={ref}
            className={cx(classes.rootContainer, { [classes.stickyContainer]: config.sticky })}
            style={{ backgroundColor: config.backgroundColor }}
        >
            <Box className={classes.content}>
                <RenderWhen when={!config.persistent}>
                    <Box
                        className={imageStyles.closeIcon}
                        position="absolute"
                        top={0}
                        right={0}
                        width={{ xs: 14, sm: 18 }}
                        height={{ xs: 14, sm: 18 }}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            if (config.closeAction) {
                                config.closeAction(dispatch);
                            } else {
                                dispatch(bannerCloseButtonClickedAction(config.id));
                            }
                        }}
                    />
                </RenderWhen>

                <Box display="flex" flexDirection={{ xs: "column", sm: "row" }}>
                    <Box className={cx(classes.icon, config.icon)} />
                    <Box flex={1} marginTop={{ xs: "10px", sm: "0px" }} marginLeft={{ xs: "0px", sm: "20px" }}>
                        <Typography component="h3" className={classes.bannerTitle}>
                            {intl.msgJoint(config.title)}
                        </Typography>
                        <Typography component="p" className={classes.bannerDescription}>
                            {intl.msgJoint(config.description)}
                        </Typography>
                    </Box>
                    <RenderWhen when={config.ctaAction}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                if (config.ctaAction) {
                                    config.ctaAction(dispatch);
                                }
                            }}
                        >
                            {config.ctaText ? intl.msgJoint(config.ctaText) : ""}
                        </Button>
                    </RenderWhen>
                </Box>
            </Box>
        </div>
    );
});
