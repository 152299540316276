import { GOOGLE_REVIEWS } from "../../../components/oneweb/componentKinds";
import type { AnyFeatureKind } from "../../../redux/modules/children/workspace/flowTypes";

const FeatureTypes: { [k: string]: AnyFeatureKind } = {
    GOOGLE_ANALYTICS_TRACKING: 'GOOGLE_ANALYTICS',
    FACEBOOK_PIXEL_TRACKING: 'FACEBOOK_PIXEL',
    BACKUP_RESTORE: 'BACKUP_RESTORE',
    BUSINESS_LISTINGS: 'BUSINESS_LISTINGS',
    FACEBOOK_CHAT: 'FACEBOOK_CHAT',
    TRACKING_TAB: 'TRACKING_TAB',
    GOOGLE_REVIEWS_WIDGET: GOOGLE_REVIEWS,
};

export default FeatureTypes;
