// @flow

const {
    GROUP_2_COUNTRIES,
    GROUP_3_COUNTRIES,
    GROUP_4_COUNTRIES,
    GROUP_5_COUNTRIES
} = require('./constants.js');

/*::
    import type { CountryFormat, RenderAddressToStringProps } from './types'
*/
const getCountryFormat = (countryCode /*: ?string*/) => {
    if (GROUP_5_COUNTRIES.includes(countryCode))
        return 'CountryFormat5';
    if (GROUP_4_COUNTRIES.includes(countryCode))
        return 'CountryFormat4';
    if (GROUP_3_COUNTRIES.includes(countryCode))
        return 'CountryFormat3';
    if (GROUP_2_COUNTRIES.includes(countryCode))
        return 'CountryFormat2';
    return 'CountryFormat1';
};

module.exports = {
    getCountryFormat
};
