import * as R from "ramda";
import { StyleText, StyleCell, StyleMenu, StyleLink, StyleForm, StyleBlock, StyleButton } from './types';
import * as styleTextMapper from './StyleText';
import * as styleLinkMapper from './StyleLink';
import * as styleMenuMapper from "./StyleMenu";
import * as styleButtonMapper from "./StyleButton";
import * as styleBlockMapper from './StyleBlock';
import * as styleCellMapper from './StyleCell';
import * as styleFormMapper from './StyleForm';
import * as path from "../../../../src/mappers/path";
import { overPath } from "../../../../src/utils/ramdaEx";
import { getFontSize } from "../../../../src/components/Workspace/epics/stylesheets/selectors";
import makeUuid from '../../../../utils/makeUuid';
import isTestEnv from '../../../../src/debug/isTestEnv';
import ThemeStyleMenu from '../../../../src/components/oneweb/Menu/globalStyle/themeColorKind';
import * as colorMapper from "../Base/color";
import type {
    Stylesheet,
    LinkStylesheet,
    TextStylesheet
} from '../../../../src/components/Workspace/epics/stylesheets/flowTypes';

const
    rootKeys = [path.disabled, path.hover, path.inactive, path.press, path.visited],
    fixButtonStylesUsingRef = style => {
        const inactiveFontSize = getFontSize(path.inactive)(style);
        return R.pipe(
            overPath([
                [rootKeys, path.text, path.size]
            ])(R.always(inactiveFontSize)),

            overPath([
                [rootKeys, path.horizontalAlign]
            ])(R.always('center')),

            overPath([
                [rootKeys, path.verticalAlign]
            ])(R.always('middle'))
        )(style);
    },
    fixNameUsingRef = stylesheet => {
        const
            { ref, name } = stylesheet,
            nameToCompare = name.substr(1).slice(0, -1), // [str_here] -> str_here
            nameEvolve = ref !== nameToCompare ? R.always(`[${ref}]`) : R.replace(/\s+/g, '');

        return R.evolve({ name: nameEvolve }, stylesheet);
    },
    fixInvalidNameAndRef = style => {
        if (style.name.includes('link.link.') || style.ref.includes('link.link.')) {
            const newStyle = { ...style };
            newStyle.name = newStyle.name.replace('link.link.', 'link.');
            newStyle.ref = newStyle.ref.replace('link.link.', 'link.');
            return newStyle;
        } else {
            return style;
        }
    },
    addIndexPropFromRefProp = obj => R.set(
        R.lensProp('index'),
        R.pipe(R.prop('ref'), R.split('.'), R.last)(obj)
    )(obj),
    removeIndexPropFromRefProp = R.pickBy((val, key) => key !== 'index');

// TODO: Fix this
const indexKeeper = { 'web.data.styles.StyleMenu': 0, 'web.data.styles.StyleButton': 0, [ThemeStyleMenu]: 0 };

const dropRogueGlobalStyles = R.filter(style => {
    // Drop a global style if ref and name both are missing.
    if (!style.ref && !style.name) {
        return false;
    }
    return true;
});

const addButtonNormalGlobalStyle = styles => {
    const hasButtonGlobalstyles = styles.some(style => style.type === StyleButton);
    if (!hasButtonGlobalstyles) {
        const
            styleCellNormal = R.pipe(
                R.filter(style => style.type === StyleCell && style.ref === 'normal'),
                R.head
            )(styles),
            newButtonGlobalstyle = styleButtonMapper.createGlobalstyleWithDefaults(styleCellNormal);

        return R.append(newButtonGlobalstyle, styles);
    }
    return styles;
};

const addDefaultLinkGlobalStyle = (styles: Stylesheet[]): Stylesheet[] => {
    if (isTestEnv()) return styles;

    const hasLinkGlobalstyles: boolean = styles.some(R.propEq('type', StyleLink));
    if (!hasLinkGlobalstyles) {
        const newLinkGlobalStyle: LinkStylesheet = styleLinkMapper.createGlobalstyleWithDefaults();
        return R.append(newLinkGlobalStyle, styles);
    }
    return styles;
};

export const LOGO_FONT_SIZE_SHADOW_RENDER = 1000;

const fixLogoStyle = (styles: Stylesheet[]): Stylesheet[] => {
    if (isTestEnv()) return styles;

    const logoGlobalstyle = styles.find(R.both(R.propEq('type', StyleText), R.propEq('ref', 'logo')));
    if (!logoGlobalstyle) {
        const heading1Style = styles.find(R.both(R.propEq('type', StyleText), R.propEq('ref', 'heading.1')));
        // @ts-ignore
        const newLogoGlobalStyle: TextStylesheet = {
            ...heading1Style,
            size: LOGO_FONT_SIZE_SHADOW_RENDER,
            id: makeUuid(),
            ref: 'logo'
        };
        return R.append(newLogoGlobalStyle, styles);
    } else {
        return styles.map(s => {
            if (s === logoGlobalstyle) {
                return { ...s, size: LOGO_FONT_SIZE_SHADOW_RENDER };
            }
            return s;
        });
    }
};

function fixMissingMenuGlobalStyle(stylesheet) {
    let updatedStylesheet = stylesheet;

    const styleMenuNeedsFix = styleMenuMapper.rootKeys.some(rootKey => !stylesheet[rootKey]);

    if (styleMenuNeedsFix) {
        const itemToCopy = styleMenuMapper.rootKeys.reduce((acc, rootKey) => {
            return acc || stylesheet[rootKey];
        }, null);

        if (itemToCopy) {
            const allKeys = R.uniq(R.concat(styleMenuMapper.rootKeys, Object.keys(stylesheet)));
            updatedStylesheet = allKeys.reduce((acc, rootKey) => {
                const allKeys = R.uniq(R.concat(styleMenuMapper.subRootKeys, Object.keys(itemToCopy)));
                let tmpItemToCopy = allKeys.reduce((acc, subRootKey) => {
                    let output = { ...acc },
                        clone: any = itemToCopy[subRootKey];

                    if (styleMenuMapper.subRootKeys.indexOf(subRootKey) > -1) {
                        clone = R.clone(itemToCopy[subRootKey]);
                        clone.id = makeUuid();
                    }

                    return { ...output, [subRootKey]: clone };
                }, {});
                tmpItemToCopy.id = makeUuid();

                return {
                    ...acc,
                    [rootKey]: stylesheet[rootKey] ? R.clone(stylesheet[rootKey]) : tmpItemToCopy
                };
            }, {});
        }
    }

    return R.pipe(styleMenuMapper.to, addIndexPropFromRefProp, fixNameUsingRef)(updatedStylesheet);
}

function mapTo(stylesheet: Stylesheet): Stylesheet {
    switch (stylesheet.type) {
        case StyleText:
            return styleTextMapper.to(stylesheet);
        case StyleLink:
            return R.pipe(addIndexPropFromRefProp, fixNameUsingRef, styleLinkMapper.to, fixInvalidNameAndRef)(stylesheet);
        case StyleMenu:
            return fixMissingMenuGlobalStyle(stylesheet);
        // @ts-ignore
        case ThemeStyleMenu:
            return fixMissingMenuGlobalStyle(stylesheet);
        case StyleButton:
            return R.pipe(
                styleButtonMapper.to,
                addIndexPropFromRefProp,
                fixNameUsingRef,
                fixButtonStylesUsingRef
            )(stylesheet);
        // @ts-ignore
        case StyleBlock:
            return styleBlockMapper.to(stylesheet);
        case StyleCell:
            return styleCellMapper.to(stylesheet);
        // @ts-ignore
        case StyleForm:
            return styleFormMapper.to(stylesheet);
        default:
            return stylesheet;
    }
}

function mapBack(stylesheet: Stylesheet) {
    let index;
    switch (stylesheet.type) {
        case StyleText:
            return styleTextMapper.back(stylesheet);
        case StyleLink:
            return R.pipe(styleLinkMapper.back, removeIndexPropFromRefProp)(stylesheet);
        // @ts-ignore
        case ThemeStyleMenu:
        case StyleMenu:
            // TODO: Fix this
            index = indexKeeper['web.data.styles.StyleMenu'] + 1;
            indexKeeper['web.data.styles.StyleMenu'] = index;
            return R.pipe(
                removeIndexPropFromRefProp,
                styleMenuMapper.back
            )(stylesheet);
        case StyleButton:
            // TODO: Fix this
            index = indexKeeper['web.data.styles.StyleButton'] + 1;
            indexKeeper['web.data.styles.StyleButton'] = index;
            return R.pipe(
                removeIndexPropFromRefProp,
                styleButtonMapper.back
            )(stylesheet);
        // @ts-ignore
        case StyleBlock:
            return styleBlockMapper.back(stylesheet);
        case StyleCell:
            return styleCellMapper.back(stylesheet);
        // @ts-ignore
        case StyleForm:
            return styleFormMapper.back(stylesheet);
        default:
            return stylesheet;
    }
}

const themeColorsLens = R.lensPath(path.themeColors);
export const
    to =
        R.pipe(
            R.over(
                R.lensProp('styles'),
                R.pipe(
                    dropRogueGlobalStyles,
                    addButtonNormalGlobalStyle,
                    addDefaultLinkGlobalStyle,
                    fixLogoStyle,
                    R.map(mapTo)
                )
            ),
            // Set only if the path exist. Path will not exist for customers who have not migrated to theming.
            R.unless(R.o(R.isNil, R.view(themeColorsLens)), R.over(themeColorsLens, R.map(colorMapper.toHsl)))
        ),
    back = R.over(R.lensProp('styles'), R.map(mapBack));
