import siteDataEpic from "../../App/epics/siteData/index";
import defaultStyleIdEpic from '../commonEpics/buttonDefaultStyleId';
import stylesheetsEpic from '../../Workspace/epics/stylesheets/index';
import stylesheetsIdToNameMapEpic from '../../Workspace/epics/stylesheets/idToNameMap';
import workspace from './view/workspace';
import kind from './kind';
import reducer from './reducer/index';
import calcRenderProps from "./calcRenderProps";
import propertiesPanel from './propertiesPanel/index';
import reducerForExtension from './reducerForExtension/index';
import adjustmentHookConfig from './adjustmentHookConfig';
import adjustComponentOnAdd from './adjustComponentOnAdd';
import adjustComponentOnPaste from './adjustComponentOnPaste';
import componentMainActions from './componentMainActions/index';
import previewConfig from './previewConfig';
import type { ButtonComponent } from "./flowTypes";
import { ComponentNames } from "../constants";
import { MIN_DIMENSIONS } from "./constants";
import { BUTTON_MCTA_CHANGE_TEXT } from './actionTypes';
import { globalVariablesEpic } from "../../App/epics/globalVariablesEpic";
import type { ChangeOldComponentDataBeforeImportProps } from "../../../view/oneweb/flowTypes";
import { replaceTagsWithContent } from "../Text/view/replaceTagsWithContent";
import { sectionComponentsEpic } from '../../PagesTree/sectionComponents/epic/sectionComponentsEpic';
import { colorThemeSiteSettingsEpic } from "../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import colorPickerEpic from '../../ColorPicker/epic/index';
import colorPickerEpicValueActionType from '../../ColorPicker/epic/valueActionType';
import makeStateSelectorReducer from "../../../epics/makeStateSelectorReducer";
import { isModernLayoutActivatedEpic } from "../../Workspace/epics/isModernLayoutActivatedEpic/index";

type ButtonComponentData = {
    btnText: string,
    action?: {
        link: null | undefined | {
            url?: string
        }
    }
}

export default {
    workspace,
    kind,
    label: ComponentNames[kind],
    shortcutIconName: 'button',
    propertiesPanel,
    reducer,
    reducerForExtension,
    componentMainActions,
    adjustmentHookConfig,
    adjustComponentOnAdd,
    adjustComponentOnPaste,
    calcRenderProps,
    dependsOn: {
        isModernLayoutActivated: isModernLayoutActivatedEpic.reducer,
        site: siteDataEpic.reducer,
        globalstyles: stylesheetsEpic.reducer,
        stylesheetsIdToNameMap: stylesheetsIdToNameMapEpic.reducer,
        defaultStyleId: defaultStyleIdEpic.reducer,
        globalVariables: globalVariablesEpic.reducer,
        sectionComponents: sectionComponentsEpic.reducer,
        themeSettingsData: colorThemeSiteSettingsEpic.reducer,
    },
    controlsDependsOn: {
        colorPickerOpened: makeStateSelectorReducer(
            colorPickerEpic.reducer,
            colorPickerEpicValueActionType,
            state => state.opened
        ),
    },
    minDimensions: MIN_DIMENSIONS,
    mobileEditorConfig: {
        ...previewConfig,
        getHiddenElementData: (props: ButtonComponent) => ({ content: props.text })
    },
    // BUTTON_MCTA_CHANGE_TEXT (not undoable) is always followed by BUTTON_UPDATE_COMPONENT_ON_RESIZE (undoable)
    nonUndoableActionTypes: [BUTTON_MCTA_CHANGE_TEXT],
    changeOldComponentDataBeforeImport: ({
        component,
        globalVariables,
        site
    }: ChangeOldComponentDataBeforeImportProps<ButtonComponentData>) => {
        const result = ({
            ...component,
            btnText: replaceTagsWithContent(component.btnText, { globalVariables, site }),
        });

        if (component.action && component.action.link) {
            const { url } = component.action.link;
            if (url) {
                result.action = {
                    ...result.action,
                    link: {
                        ...result.action?.link,
                        url: replaceTagsWithContent(url, { globalVariables, site }, true)
                    }
                };
            }
        }

        return result;
    }
};
