import styles from './MveHeaderProperteisPanel.css';
import mveStyles from '../../view/MobileViewEditor.css';
import type { InstancePropertiesPanelRegistryConfigT } from '../../../PropertiesPanel/instance/types';
import { MveHeaderPropertiesPanelOnShowUpdater } from "./updaters/MveHeaderPropertiesPanelOnShowUpdater";
import { MainMveHeaderPpPage } from "./pages/main/MainMveHeaderPpPage";
import { TitleStyleMveHeaderPpPage } from "./pages/TitleStyleMveHeaderPpPage";
import { MveHeaderPropertiesPanelUpdaters } from "./updaters/MveHeaderPropertiesPanelUpdaters";
import { MenuStyleMveHeaderPpPage } from "./pages/MenuStyleMveHeaderPpPage";
import { BackgroundMveHeaderPpPage } from "./pages/BackgroundMveHeaderPpPage";
import AddGoogleFontPage from "../../../PropertiesPanel/view/AddGoogleFont/index";

export const MveHeaderPropertiesPanelConfig: InstancePropertiesPanelRegistryConfigT = {
    onShowUpdater: MveHeaderPropertiesPanelOnShowUpdater,
    updaters: [...MveHeaderPropertiesPanelUpdaters],
    Pages: [
        // @ts-ignore
        AddGoogleFontPage,
        MainMveHeaderPpPage,
        TitleStyleMveHeaderPpPage,
        MenuStyleMveHeaderPpPage,
        BackgroundMveHeaderPpPage,
    ],
    propertiesPanelParams: {
        containerClassName: styles.container,
        getPortalNode: () => document.getElementsByClassName(mveStyles.mobileContainer)[0] as HTMLElement,
    },
};
