
import React from 'react';
import cx from 'classnames';
import { Msg } from "../../../../../../view/intl/index";
import styles from './UpgradePlansDialogImages.css';
import { isShopInAllTiersEnabled } from "../../../../../ComponentTierManager/utils";

const RenderPlanSellOnlineDescription = () => {
    return isShopInAllTiersEnabled() ?
        (<Msg className={cx(styles.txtStyle, styles.imageBodyTxtStyle)} k="upgrade.plans.dialog.std.sellOnline.body">
            Expand your Online Shop and create as many products and variants as you need
        </Msg>) :
        (<Msg className={cx(styles.txtStyle, styles.imageBodyTxtStyle)} k="upgrade.plans.dialog.std.img3.body">
            Easily set up an online shop and sell products or services online
        </Msg>);
};

export {
    RenderPlanSellOnlineDescription
};
