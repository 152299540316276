import { AppState } from "../../../../redux/modules/flowTypes";
import valueActionType from "./valueActionType";

const IsMobilePreviewViewSelector = (appState: AppState) => !!appState.epics[valueActionType].state.mobileView?.show;
const PreviewShownSelector = (appState: AppState) => !!appState.epics[valueActionType].state.show;
const IsDesktopPreviewSelector = (appState: AppState) => !appState.epics[valueActionType].state.mobileView?.show;

export {
    IsMobilePreviewViewSelector,
    PreviewShownSelector,
    IsDesktopPreviewSelector,
};
