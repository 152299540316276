import MissingAssetsDialogId from './dialogId';
import { closeDialogByIdAction } from "../App/actionCreators/index";
import { DIRECTORY_CONTENTS_STATUS_FETCH_SUCCESS } from './epic/directoryContentFetchActions';
import type { WebspaceDirectoryContents } from '../../../dal/types';

export const
    closeMissingAssetsDialogAction = () => closeDialogByIdAction(MissingAssetsDialogId);

export const directoryContentsFetchSuccessAction =
    (payload: WebspaceDirectoryContents) => ({ type: DIRECTORY_CONTENTS_STATUS_FETCH_SUCCESS, payload });
