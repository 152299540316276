import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { Box } from "@mui/material";

import { Select } from "../../../../Select";

import { setAddressCountryCodeAction } from "./action";

import { selectAddressCountryCode, selectedSideBarItemIdSelector } from "../../../../../common/selectors";
import { BUSINESS_LISTINGS_PAGE_SIDE_BAR_ITEM_ID } from '../../../BusinessListing';

const { ALL_COUNTRIES } = require('../../../../../../../../server/shared/address/constants');

const countriesOptions = Object.keys(ALL_COUNTRIES).map(k => ({ value: k, label: ALL_COUNTRIES[k] }));

export const AddressSettingsCountryCodeForm = () => {
    const dispatch = useDispatch();
    const addressCountryCode = useSelector(selectAddressCountryCode);
    const selectedSideBarId = useSelector(selectedSideBarItemIdSelector);

    const [value, setValue] = useState(addressCountryCode);

    useEffect(() => {
        if (value !== addressCountryCode) {
            setValue(addressCountryCode);
        }
    }, [addressCountryCode]);

    const handleChange = (e) => {
        dispatch(setAddressCountryCodeAction(e.target.value));
    };

    const selectProps = {
        label: "msg: common.country {Country}",
        value,
        data: countriesOptions,
        handleChange,
        fullWidth: true,
        height: selectedSideBarId === BUSINESS_LISTINGS_PAGE_SIDE_BAR_ITEM_ID ? 44 : 46,
        labelStyles: {
            fontSize: '14px',
            lineHeight: '18px',
            transform: 'none',
            position: 'static'
        }
    };

    return (
        <Box data-testid="country">
            <Select {...selectProps} />
        </Box>
    );
};
