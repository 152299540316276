
import { $PropertyType } from 'utility-types';
import * as onClickActions from './constants/onClickActions';
import * as constants from './constants/index';
import { DataSite } from "../../../../dal/model/index";
import type {
    FacebookAttachmentMedia, FacebookPostAsset, FacebookPostAttachment, FacebookPostMediaAsset
} from "../../SocialAccounts/Facebook/FacebookFeed/types";
import {
    FACEBOOK_FEED_IMAGE,
    FACEBOOK_FEED_VIDEO,
    FACEBOOK_FEED_CAROUSEL_ALBUM,
    SHINYBOX_VIDEO_MEDIA_TYPE,
    SHINYBOX_ALBUM_MEDIA_TYPE,
    FB_VIDEO_MEDIA_TYPES,
    SHINYBOX_NO_FACEBOOK_MEDIA_TYPE,
    captionDefaults, CAPTION_CONTAINER_HEIGHT_MAP
} from "./constants/index";
import type { Stylesheets } from "../../Workspace/epics/stylesheets/flowTypes";
import type { ThemeBackgroundType, ThemeColorDataType, ThemeColorTypes } from "../../ThemeGlobalData/flowTypes";
import { CaptionStyles } from "../../presentational/CaptionStyle/constants";
import { getFontName } from "../../presentational/AddGoogleFont/utils";
import type { FacebookFeedGalleryComponent, CaptionBoxStyle } from "./flowTypes";
import type { Color } from "../../../mappers/flowTypes";
import { findSuitableTextColorName } from "../../ThemeGlobalData/utils/commonUtils";
import { getThemeRulesForBackground } from "../../ThemeGlobalData/themeRules";
import { textHeading3Globalstyle, textNormalGlobalstyle } from "../../Workspace/epics/stylesheets/selectors";
import { toHex } from "../../../mappers/color";
import type { CaptionStyleTypes } from '../../presentational/CaptionStyle/flowTypes';

type CalcAProps = {
    onClickAction: string,
    caption: string,
    componentId: string,
    index: number,
    image: FacebookPostMediaAsset,
    previewBackupTime?: number,
    site: DataSite,
    imageAvailable?: boolean,
    profileImageSrc?: string
}
type FontStyle = {
    color: Color,
    font: string|number,
    size: number,
    bold: boolean,
    underline: boolean,
    italic: boolean,
    lineHeight: number,
    opacity: number
};

export const
    /**
     * Finds a factor such that when the width and height are multiplied with it, the
     * new size will follow the constraints:
     * 1. Max size is not more then 16mp or whatever is global limit
     * 2. Either side is not more then sanity limit
     * 3. Either side is not more then original side (specified as maxW and maxH)
     * @public
     * @param {Number} w Width
     * @param {Number} h Height
     * @param {Number} maxW (optional) Max width
     * @param {Number} maxH (optional) Max height
     * @returns {Number} A value between 0 .. 1
     */
    getReduceFactor = (w: number, h: number, maxW: number, maxH: number) => {
        let
            maxPixels = constants.IMAGE_SCALE_MAX_PIXELS,
            maxSide = constants.IMAGE_MAX_DIMENSION - 1,
            newScale = 1;

        // Check for maxPixels limit
        if (w * h > maxPixels) {
            newScale = maxPixels / (w * h);
        }

        // Check for original size constraints
        if (maxW && w > maxW) {
            newScale = Math.min(newScale, maxW / w);
        }
        if (maxH && h > maxH) {
            newScale = Math.min(newScale, maxH / h);
        }

        // Check for either side limit constraint
        if (w > maxSide) {
            newScale = Math.min(newScale, maxSide / w);
        }
        if (h > maxSide) {
            newScale = Math.min(newScale, maxSide / h);
        }

        return newScale;
    },

    calcA = ({
        onClickAction,
        componentId,
        index,
        image,
        imageAvailable,
        profileImageSrc
    }: CalcAProps) => {
        if (onClickAction === onClickActions.OPEN_LIGHT_BOX) {
            let aProps = {
                href: image.media_type === FACEBOOK_FEED_VIDEO ? image.thumbnail_url : image.media_url,
                title: '',
                alt: '',
                caption: image.message || '',
                rel: `lightbox[oneweb] ${componentId}`,
                className: "shinybox",
                'data-dom-index': index,
            };

            if (image.media_type === FACEBOOK_FEED_VIDEO) {
                aProps['data-force-media-type'] = SHINYBOX_VIDEO_MEDIA_TYPE;
                aProps['data-force-media-url'] = image.media_url;
            } else if (image.media_type === FACEBOOK_FEED_CAROUSEL_ALBUM) {
                aProps['data-force-media-type'] = SHINYBOX_ALBUM_MEDIA_TYPE;
                // @ts-ignore
                aProps['data-media-album-items'] = JSON.stringify(image.children.data);
            }

            if (!imageAvailable && !profileImageSrc) {
                aProps['data-force-media-type'] = SHINYBOX_NO_FACEBOOK_MEDIA_TYPE;
            } else if (!imageAvailable && profileImageSrc) {
                aProps.href = profileImageSrc;
            }

            return aProps;
        } else if (onClickAction === onClickActions.OPEN_LINK) {
            return {
                href: image.permalink_url,
                target: '_blank',
                alt: image.message,
                className: 'open-facebook-feed-post-new-tab',
            };
        }
        return null;
    },
    getFontStyle = ({ color, size: fontSize, font, bold, italic, underline, lineHeight }: FontStyle) => {
        return {
            color: toHex(color),
            fontFamily: getFontName(font.toString()),
            fontSize,
            fontWeight: bold ? 'bold' : 'normal',
            textDecoration: underline ? 'underline' : 'none',
            fontStyle: italic ? 'italic' : 'normal',
            lineHeight,
            textShadow: 'none',
            overflow: 'visible',
        };
    },
    getTitleClassicStyle = () => ({
        ...constants.titleDefaultStyle,
        //color // inherit color from global
    }),
    getDescriptionClassicStyle = (autoColorMode: boolean, themeColorsData: ThemeColorDataType) => ({
        ...constants.descriptionDefaultStyle,
        ...(autoColorMode ? { color: themeColorsData[constants.titleDefaultStyle.themeColor] } : {}),
        //color // inherit color from global
    }),
    getTitleGlobalStyle = (globalStyles: Stylesheets, autoColorMode: boolean, themeColorsData, selectedParentTheme) => {
        let autoColorModeData = {};
        if (autoColorMode) {
            const themeColor = findSuitableTextColorName(
                getThemeRulesForBackground(selectedParentTheme, themeColorsData).background, themeColorsData
            );
            autoColorModeData = { themeColor, color: themeColorsData[themeColor] };
        }
        return {
            ...textHeading3Globalstyle(globalStyles),
            ...autoColorModeData,
            size: 12,
            bold: false,
        };
    },
    getDescriptionGlobalStyle = (globalStyles: Stylesheets, autoColorMode: boolean, themeColorsData, selectedParentTheme) => {
        let autoColorModeData = {};
        if (autoColorMode) {
            const themeColor = findSuitableTextColorName(
                getThemeRulesForBackground(selectedParentTheme, themeColorsData).background, themeColorsData
            );
            autoColorModeData = { themeColor, color: themeColorsData[themeColor] };
        }
        return {
            ...textNormalGlobalstyle(globalStyles),
            ...autoColorModeData,
            size: 13 // description template style is always 13px
        };
    },
    getDefaultCaptionStyle = (
        component: FacebookFeedGalleryComponent,
        globalStyle: FontStyle,
        classicStyle: FontStyle & {
            themeColor: ThemeColorTypes;
        },
    ) => {
        const
            isPrevCaptionStyleTemplate = component.previousCaptionStyle === CaptionStyles.TEMPLATE;

        return {
            ...(isPrevCaptionStyleTemplate ? globalStyle : classicStyle)
        };
    },
    makeCaptionStyle = (width: number) => ({
        width
    }),
    makeCaptionStylePadding = (style: { padding: { left: any; right: any; top: any; bottom: any; }; }) => ({
        paddingLeft: style.padding && style.padding.left ? style.padding.left : constants.Padding.DEFAULT_LEFT,
        paddingRight: style.padding && style.padding.right ? style.padding.right : constants.Padding.DEFAULT_RIGHT,
        paddingTop: style.padding && style.padding.top ? style.padding.top : constants.Padding.DEFAULT_TOP,
        paddingBottom: style.padding && style.padding.bottom ? style.padding.bottom : constants.Padding.DEFAULT_BOTTOM,
    }),

    getFinalDescriptionEllipsisProps = (
        { desiredHeight, captionDescriptionLineHeight, captionDescriptionFontSize }: Record<string, any>
    ) => {
        const webkitLineClamp = Math.round(
            desiredHeight / (captionDescriptionLineHeight * captionDescriptionFontSize)
        );

        const finalViewHeight = (
            (captionDescriptionFontSize * captionDescriptionLineHeight * webkitLineClamp) - 4
        );
        return {
            finalViewHeight,
            webkitLineClamp,
        };
    },

    getCaptionFontStyle = (
        component: FacebookFeedGalleryComponent,
        globalStyles: Stylesheets,
        themeColorsData: ThemeColorDataType,
        autoColorMode: boolean,
        selectedParentTheme: ThemeBackgroundType
    ) => {
        const
            templateTitleStyle = getTitleGlobalStyle(globalStyles, autoColorMode, themeColorsData, selectedParentTheme),
            templateDescriptionStyle = getDescriptionGlobalStyle(globalStyles, autoColorMode, themeColorsData, selectedParentTheme),
            classicTitleStyle = getTitleClassicStyle(), // classic title style
            classicDescriptionStyle = getDescriptionClassicStyle(autoColorMode, themeColorsData), // classic desc style
            customTitleDefaultStyle: Record<string, any> = getDefaultCaptionStyle(
                component,
                templateTitleStyle,
                classicTitleStyle
            ),
            customDescriptionDefaultStyle: Record<string, any> = getDefaultCaptionStyle(
                component,
                templateDescriptionStyle,
                classicDescriptionStyle
            );
        let
            captionTitleTextStyle: Record<string, any> = {},
            captionDescriptionTextStyle: Record<string, any> = {};

        switch (component.captionStyle) {
            case CaptionStyles.TEMPLATE:
                captionTitleTextStyle = getFontStyle(templateTitleStyle);
                captionDescriptionTextStyle = getFontStyle(templateDescriptionStyle);

                break;
            case CaptionStyles.CUSTOM:
                captionTitleTextStyle = getFontStyle({
                    ...customTitleDefaultStyle,
                    ...component.captionTitleTextStyle,
                    bold: false,
                    color: (
                        autoColorMode
                            ? themeColorsData[component.captionTitleTextStyle.themeColor || customTitleDefaultStyle.themeColor]
                            : (component.captionTitleTextStyle.color || customTitleDefaultStyle.color)
                    ),
                });
                captionDescriptionTextStyle = getFontStyle({
                    ...customDescriptionDefaultStyle,
                    ...component.captionDescriptionTextStyle,
                    color: (
                        autoColorMode
                            ? themeColorsData[component.captionDescriptionTextStyle.themeColor || customDescriptionDefaultStyle.themeColor]
                            : (component.captionDescriptionTextStyle.color || customDescriptionDefaultStyle.color)
                    ),
                });

                break;
            default:
                captionTitleTextStyle = getFontStyle(classicTitleStyle);
                captionDescriptionTextStyle = getFontStyle(classicDescriptionStyle);
        }

        captionTitleTextStyle.opacity = 0.5;
        captionTitleTextStyle.marginLeft = 5;

        const { finalViewHeight, webkitLineClamp } = getFinalDescriptionEllipsisProps({
            desiredHeight: CAPTION_CONTAINER_HEIGHT_MAP[component.columns] - 70,
            captionDescriptionLineHeight: captionDescriptionTextStyle.lineHeight,
            captionDescriptionFontSize: captionDescriptionTextStyle.fontSize
        });

        captionDescriptionTextStyle.height = finalViewHeight;
        captionDescriptionTextStyle.WebkitLineClamp = webkitLineClamp;

        return {
            captionTitleTextStyle,
            captionDescriptionTextStyle
        };
    },
    getCaptionFontStyleSettings = (style: FontStyle, defaultStyle: FontStyle) => ({
        ...defaultStyle,
        ...style,
        font: getFontName((style && style.font ? style.font : defaultStyle.font).toString()),
    }),
    getCaptionBoxBorderColorForTheme = (
        captionStyle: CaptionStyleTypes,
        previousCaptionStyle: CaptionStyleTypes,
        captionBorderObj: $PropertyType<CaptionBoxStyle, 'border'>,
        selectedParentTheme: ThemeBackgroundType,
        themeColorsData: ThemeColorDataType
    ) => {
        if (captionStyle === CaptionStyles.CUSTOM) {
            if (captionBorderObj && captionBorderObj.themeColor) {
                return captionBorderObj.themeColor;
            } else if (previousCaptionStyle === CaptionStyles.CLASSIC) {
                return findSuitableTextColorName(
                    // @ts-ignore
                    captionDefaults.box[CaptionStyles.CLASSIC].background.colorData.themeColor, themeColorsData
                );
            } else {
                return getThemeRulesForBackground(selectedParentTheme, themeColorsData).text;
            }
        } else if (captionStyle === CaptionStyles.TEMPLATE) {
            return getThemeRulesForBackground(selectedParentTheme, themeColorsData).text;
        } else { // CaptionStyles.CLASSIC
            // @ts-ignore
            return captionDefaults.box[CaptionStyles.CLASSIC].background.colorData.themeColor;
        }
    },
    makeImageAdjustmentDataKey = (imageIndex: number) => `caption-height-of-image-${imageIndex}`,
    getMediaAssetsToBeShown = (mediaAssets: Array<FacebookPostAsset>, columns: number, rows: number): Array<FacebookPostAsset> => {
        return mediaAssets.slice(0, (columns * rows));
    },
    extractMediaInformation = (fbMediaItem: FacebookAttachmentMedia, mediaType: string) => {
        let mediaUrl, thumbnailUrl;
        if (fbMediaItem) {
            if (mediaType === FACEBOOK_FEED_VIDEO) {
                if (fbMediaItem.image && fbMediaItem.image.src) {
                    thumbnailUrl = fbMediaItem.image.src;
                }
                if (fbMediaItem.source) {
                    mediaUrl = fbMediaItem.source;
                }
            } else if (mediaType === FACEBOOK_FEED_IMAGE) {
                if (fbMediaItem.image && fbMediaItem.image.src) {
                    mediaUrl = fbMediaItem.image.src;
                }
            }
        }

        return {
            mediaUrl,
            thumbnailUrl,
        };
    },
    convertFbPostAttachmentDataItemToMediaItem = (attachmentDataItem: FacebookPostAttachment) => {
        if (attachmentDataItem.media) {
            // @ts-ignore
            const mediaType = FB_VIDEO_MEDIA_TYPES.indexOf(attachmentDataItem.type) > -1 ? FACEBOOK_FEED_VIDEO : FACEBOOK_FEED_IMAGE;
            const { mediaUrl, thumbnailUrl } = extractMediaInformation(attachmentDataItem.media, mediaType);
            const mediaAsset = {
                media_type: mediaType,
                media_url: mediaUrl,
                thumbnail_url: '',
                description: ''
            };
            if (thumbnailUrl) {
                mediaAsset.thumbnail_url = thumbnailUrl;
            }
            if (attachmentDataItem.title || attachmentDataItem.description) {
                // @ts-ignore
                mediaAsset.description = attachmentDataItem.title || attachmentDataItem.description;
            }
            return mediaAsset;
        }
        return null;
    },
    extractEssentialDataFromFbPost = (fbPost: FacebookPostAsset): FacebookPostMediaAsset => {
        const finalImageData = {
            message: fbPost.message,
            created_time: fbPost.created_time,
            id: fbPost.id,
            permalink_url: fbPost.permalink_url,
            full_picture: fbPost.full_picture,
            media_type: FACEBOOK_FEED_IMAGE,
            media_url: '',
            thumbnail_url: '',
            children: {}
        };
        let finalMediaType = FACEBOOK_FEED_IMAGE;
        let finalDescription;
        if (fbPost.attachments && fbPost.attachments.data) {
            const mediaAssets: Array<Record<string, any>> = [];
            fbPost.attachments.data.forEach((attachmentDataItem) => {
                const mediaAsset = convertFbPostAttachmentDataItemToMediaItem(attachmentDataItem);
                if (mediaAsset) {
                    mediaAssets.push(mediaAsset);
                    if (mediaAsset.description && !finalDescription) {
                        finalDescription = mediaAsset.description;
                    }
                }
                if (attachmentDataItem.subattachments && attachmentDataItem.subattachments.data) {
                    attachmentDataItem.subattachments.data.forEach((subAttachmentDataItem) => {
                        const mediaAsset = convertFbPostAttachmentDataItemToMediaItem(subAttachmentDataItem);
                        if (mediaAsset) {
                            mediaAssets.push(mediaAsset);
                            if (mediaAsset.description && !finalDescription) {
                                finalDescription = mediaAsset.description;
                            }
                        }
                    });
                }
            });

            const uniqueAssets: Array<Record<string, any>> = [];
            const exisitingUrls: Array<string> = [];
            mediaAssets.forEach((asset) => {
                if (exisitingUrls.indexOf(asset.media_url) < 0) {
                    uniqueAssets.push(asset);
                    exisitingUrls.push(asset.media_url);
                }
            });

            if (uniqueAssets.length > 1) {
                finalMediaType = FACEBOOK_FEED_CAROUSEL_ALBUM;
            } else if (uniqueAssets.length === 1 && uniqueAssets[0].media_type === FACEBOOK_FEED_VIDEO) {
                finalMediaType = FACEBOOK_FEED_VIDEO;
            }

            if (uniqueAssets.length > 0) {
                const firstMediaAsset = uniqueAssets[0];
                if (finalMediaType === FACEBOOK_FEED_CAROUSEL_ALBUM && firstMediaAsset.media_type === FACEBOOK_FEED_VIDEO) {
                    finalImageData.media_url = firstMediaAsset.thumbnail_url;
                } else {
                    finalImageData.media_url = firstMediaAsset.media_url;
                }
                if (firstMediaAsset.thumbnail_url) {
                    finalImageData.thumbnail_url = firstMediaAsset.thumbnail_url;
                }
            }

            finalImageData.children = {
                data: uniqueAssets,
            };
        }

        if (!finalImageData.media_url && fbPost.full_picture) {
            finalImageData.media_url = fbPost.full_picture;
        }

        finalImageData.media_type = finalMediaType;
        finalImageData.message = fbPost.message || finalDescription;
        // @ts-ignore
        return finalImageData;
    };
