import { LcTabName } from "../constants";
import inputDefaultState from '../../../../../components/inputControls/input/reducerFactory/inputDefaultState';

export default {
    [LcTabName.PAGE]: {
        selection: undefined,
        expandedIds: []
    },
    [LcTabName.FILE]: {
        selection: undefined
    },
    [LcTabName.URL]: {
        selection: undefined,
        isValid: false
    },
    [LcTabName.PHONE]: {
        value: inputDefaultState.value,
        input: inputDefaultState,
    },
    [LcTabName.EMAIL]: {
        selection: null,
        emails: [],
        subject: inputDefaultState,
    },
    [LcTabName.LOCATION]: {
        selection: null,
        name: null,
    },
    [LcTabName.SECTION_LINK]: {
        selection: null,
        sectionId: null,
        isValid: false,
    },
    activeTabName: LcTabName.PAGE,
    currentResult: null,
    openInNewWindow: false
};
