import * as PagesIds from './ids';
import borderPage from "./Border";
import * as mobileView from '../../../../PropertiesPanel/view/MobileView/page';
import IMAGE from "../../kind";
import { ComponentNames } from "../../../constants";

export default {
    [PagesIds.MAIN]: ComponentNames[IMAGE],
    [PagesIds.ONCLICK]: "msg: common.onClick {On click}",
    [PagesIds.SCALING]: "msg: common.scaling {Scaling}",
    [PagesIds.SEO]: "msg: common.seo {SEO}",
    [PagesIds.BORDER]: borderPage.title,
    [mobileView.id]: mobileView.title
};
