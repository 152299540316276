export const WEBSHOP_MHF_DATA_REQUEST = 'WEBSHOP_MHF_DATA_REQUEST',
    WEBSHOP_MHF_DATA_SUCCESS = 'WEBSHOP_MHF_DATA_SUCCESS',
    WEBSHOP_MHF_DATA_FAILURE = 'WEBSHOP_MHF_DATA_FAILURE',
    POPULATE_WEBSHOP_FOOTER_CMPS_BACKUP_DATA = 'POPULATE_WEBSHOP_FOOTER_CMPS_BACKUP_DATA';

export const WebShopMHFDataActionTypes = [
    WEBSHOP_MHF_DATA_REQUEST,
    WEBSHOP_MHF_DATA_SUCCESS,
    WEBSHOP_MHF_DATA_FAILURE
];
