import type { DocumentConfigDialogTabId, DocumentConfigDialogSelection } from "./configurationDialog/flowTypes";
import Resource from "../../../redux/modules/children/fileChooser/Resource";
import { inputOnChange } from "../../inputControls/input/actionCreator/index";
import { openDialog } from "../../App/actionCreators/index";
import { DOCUMENT_CONFIG_DIALOG_ID } from "./configurationDialog/constants";

export const
    SWITCH_DOCUMENT_CONFIG_TAB_ACTION = 'SWITCH_DOCUMENT_CONFIG_TAB_ACTION',
    switchDocumentConfigTabAction = (tabId: DocumentConfigDialogTabId) => ({
        type: SWITCH_DOCUMENT_CONFIG_TAB_ACTION,
        payload: tabId
    }),

    SELECT_FILE_DOCUMENT_ACTION = 'SELECT_FILE_DOCUMENT_ACTION',
    selectFileDocumentAction = (resource: Resource) => ({
        type: SELECT_FILE_DOCUMENT_ACTION,
        payload: resource
    }),

    CHANGE_DOCUMENT_EXTERNAL_URL_ACTION = 'CHANGE_DOCUMENT_EXTERNAL_URL_ACTION',
    changeDocumentExternalUrlAction = (url: string, actionParams?: Object) =>
        inputOnChange(CHANGE_DOCUMENT_EXTERNAL_URL_ACTION, url, actionParams),

    SAVE_DOCUMENT_CONFIG_ACTION = 'SAVE_DOCUMENT_CONFIG_ACTION',
    saveDocumentConfigAction = (selection: DocumentConfigDialogSelection) => ({
        type: SAVE_DOCUMENT_CONFIG_ACTION,
        payload: selection
    }),

    UPDATE_DOCUMENT_CONFIG_ACTION = 'UPDATE_DOCUMENT_CONFIG_ACTION',
    updateDocumentConfigAction = (src: DocumentConfigDialogSelection) => ({
        type: UPDATE_DOCUMENT_CONFIG_ACTION,
        payload: src
    }),

    openDocumentConfigDialogAction = (src: string) => openDialog(DOCUMENT_CONFIG_DIALOG_ID, { src });
