export default (props) => {
    const { component, isWorkspace, componentDependencies, selectedParentTheme } = props;
    return {
        ...componentDependencies,
        isWorkspace,
        component,
        componentWidth: component.width,
        selectedParentTheme
    };
};
