import React from "react";
import VerticalSpacer from '../../../../view/common/VerticalSpacer';
import getCenteredDialog from "../../../DialogManager/getCenteredDialogConfig";
import type { FacebookFeedGalleryPremiumDialogPropTypes } from "../flowTypes";
import { injectIntl } from "../../../../view/intl/index";
import styles from './configurationDialog.css';
import { StripTypes } from "../../../../view/common/dialogs/baseDialog/index";
import StandardDialog from "../../../../view/common/dialogs/StandardDialog/index";
import { tryUpgradeDialogCompleted, tryUpgradeDialogCancelled } from "../../../ComponentTierManager/actionCreators";
import ComponentTypes from "../../../../view/oneweb/registry/ComponentTypes";
import subscriptionDataEpic from '../../../App/epics/subscriptionData/index';
import { getPremiumPackageNameFromMetadata } from '../../../ComponentTierManager/utils';

class FacebookFeedGalleryPremiumDialog extends React.Component<FacebookFeedGalleryPremiumDialogPropTypes> {
    render() {
        const { props: { dispatch, intl, subscriptionData: { metadata: subscriptionMetadata } } } = this,
            premiumPackageNameFromMetadata = getPremiumPackageNameFromMetadata(subscriptionMetadata);
        return <StandardDialog
            title={
                intl.msgJoint(
                    [
                        "msg: common.premiumFeature {{packageName} Feature}",
                        {
                            packageName: premiumPackageNameFromMetadata || intl.msgJoint('msg: common.premium {Premium}')
                        }
                    ]
                )
            }
            titleExtraClassName={styles.titleContainer}
            titleClassName={styles.title}
            className={styles.dialog}
            iconClass={styles.premiumFeatureIcon}
            mctaText="msg: common.tryIt {Try it!}"
            mctaHandler={() => dispatch(tryUpgradeDialogCompleted(ComponentTypes.FACEBOOK_FEED_GALLERY))}
            sctaHandler={null}
            onClose={() => dispatch(tryUpgradeDialogCancelled(ComponentTypes.FACEBOOK_FEED_GALLERY))}
            stripType={StripTypes.NONE}
            footerClassName={styles.footerClass}
            primaryBtnClass={styles.tryItButton}
        >
            <div className={styles.premiumComponentIllustration} />
            <VerticalSpacer y={10} />
            <div className={styles.message}>
                {/* eslint-disable-next-line max-len */}
                {intl.msgJoint('msg: component.facebookFeedGallery.premiumComponent.message {Facebook Gallery is great for adding social media presence to your site. Try it out before upgrading.}')}
            </div>
        </StandardDialog>;
    }
}

export default {
    ...getCenteredDialog({
        width: 364,
        height: 368,
        component: injectIntl(FacebookFeedGalleryPremiumDialog)
    }),
    dependsOn: {
        subscriptionData: subscriptionDataEpic.reducer,
    }
};
