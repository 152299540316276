import { InputBase, TextField } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { extraThemeConfig } from "../../../app/theme";

export const useStyles = makeStyles((theme) => ({
    root: {
        flexDirection: "column",
        marginTop: "40px",
        [theme.breakpoints.down('md')]: {
            marginTop: "50px",
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: "40px",
        },
    },
    divider: {
        borderBottom: `1px solid ${theme.palette.custom.colorGray_ed}`,
    },
    leftSideArea: {
        [theme.breakpoints.up('md')]: {
            maxWidth: "462px",
            width: "58.333333%"
        },
    },
    toggleSection: {
        marginTop: "34px"
    },
    selectPage: {
        fontSize: "15px",
        lineHeight: "24px",
        letterSpacing: "0.2px",
        marginBottom: "10px"
    },
    iconSection: {
        width: "50px"
    },
    icon: {
        marginRight: "19px"
    },
    searchSectionTitle: {
        fontSize: "20px",
        lineHeight: "24px",
        letterSpacing: "0.2px",
        wordBreak: "break-word"
    },
    searchSectionDescription: {
        fontSize: "14px",
        lineHeight: "20px",
        marginTop: "10px",
    },
    searchSectionPageTitle: {
        fontSize: "14px",
        lineHeight: "20px",
    },
    searchSectionPageDescription: {
        display: "flex",
        fontSize: "15px",
        lineHeight: "24px",
        letterSpacing: "0.1px",
        marginBottom: "5px"
    },
    searchSectionApply: {
        fontSize: "14px",
        lineHeight: "20px"
    },
    pageSelectorFormControl: {
        '& > div': {
            height: "52px",
            fontSize: "15px",
            lineHeight: "24px"
        }
    },
    includePageDescription: {
        paddingLeft: "52px",
        fontSize: "14px",
        lineHeight: "20px",
        color: theme.palette.custom.colorGray_8a
    },
    imageRequirement: {
        fontSize: "14px",
        lineHeight: "20px",
        color: theme.palette.custom.colorGray_bb,
        marginTop: "5px"
    },
    rememberToPublish: {
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "17px"
    },
    inputFormControl: {
        width: "100%"
    },
    pageUrlContainer: {
        fontSize: "15px",
        lineHeight: "24px"
    },
    previewArea: {
        marginTop: "116px",
        [theme.breakpoints.down('sm')]: {
            marginTop: "34px",
        },
    },
    tooltip: {
        margin: "0px",
        marginBottom: "6px",
        padding: "6px 10px",
        width: "214px",
        fontSize: "12px",
        backgroundColor: theme.palette.custom.colorGray_2b
    },
    arrow: {
        color: theme.palette.custom.colorGray_2b
    },
    titleInput: {
        '& > input': {
            border: `1px solid ${theme.palette.custom.colorScienceBlue}`
        }
    },
    topBorder: {
        margin: '37px 0px',
        borderColor: theme.palette.custom.colorGray_ed,
        [theme.breakpoints.down('sm')]: {
            margin: '32px 0px'
        },
    },
    disableMouse: {
        pointerEvents: 'none'
    }
}), { name: "OnlinePresence" });

const InputFiledStyles = withStyles((theme) => ({
    input: {
        position: 'relative',
        border: `1px solid ${theme.palette.custom.colorGray_bb}`,
        fontSize: 15,
        height: 48,
        width: "100%",
        padding: '0 20px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
    focused: {
        '& > input': {
            border: `1px solid ${theme.palette.custom.colorScienceBlue}`,
        }
    },
    disabled: {
        '& > input': {
            border: `1px solid ${theme.palette.custom.colorGray_bb}`,
        }
    },
    error: {
        '& > input': {
            border: '1px solid red',
        }
    }
}), { name: "OnlinePresenceInput" });

const TextAreaStyles = withStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.custom.colorScienceBlue,
                borderWidth: '1px',
                transition: theme.transitions.create(['border-color', 'box-shadow']),
            }
        },
        '& .MuiOutlinedInput-root:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.custom.colorGray_bb
            }
        },
        '& .MuiOutlinedInput-root': {
            height: "auto",
            fontSize: "15px",
            lineHeight: "24px",
            borderRadius: `${extraThemeConfig.borderRadius2}px`,
            padding: "15px 20px",
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.custom.colorGray_bb
            },
            "& > textarea": {
                borderRadius: `${extraThemeConfig.borderRadius2}px`
            }
        }
    }
}), { name: "OnlinePresenceTextArea" });

export const Input = InputFiledStyles(InputBase);
export const TextArea = TextAreaStyles(TextField);
