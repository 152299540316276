import React from 'react';
import * as R from 'ramda';
import maybe from '../../../utils/maybe';
import p from '../../../utils/pipePath';
import { Dialog, DialogBody } from "../../../view/common/dialogs/baseDialog/index";
import { getCenterPositionForDialog } from "../../DialogManager/utility";
import VerticalSpacer from '../../../view/common/VerticalSpacer';
import TextEffectView from './dialogView';
import stylesheetsEpic from '../../Workspace/epics/stylesheets/index';
import {
    getAllStylesheets, getStylesByType, getStyleByRef, getStyleById
} from '../../Workspace/epics/stylesheets/selectors';
import * as mp from '../../../mappers/path';
import { DEFAULT_SHADOW } from "../../oneweb/Text/constants";
import type { TextShadowDialogPropTypes } from './flowTypes';
import type { BrowserDimensions } from "../../../redux/modules/children/dimensions/index";

export default (kind: string, ref: Array<string>, configurationDialogId: string, addtionalPath: Path = []) => {
    const
        width = 312,
        height = 270,
        TextShadowDialog = ({
            globalStyles,
            currentStylesheetId,
            shadowColorChangeAction,
            shadowRemoveAction,
            blurRadiusChangeAction,
            shadowOffsetXChangeAction,
            shadowOffsetYChangeAction,
            dispatch
        }: TextShadowDialogPropTypes) => {
            const
                textEffectViewProps = {
                    shadowColor: null,
                    blurRadius: DEFAULT_SHADOW.blur,
                    shadowOffsetX: DEFAULT_SHADOW.left,
                    shadowOffsetY: DEFAULT_SHADOW.top,
                    shadowColorChangeAction,
                    shadowRemoveAction,
                    blurRadiusChangeAction,
                    shadowOffsetXChangeAction,
                    shadowOffsetYChangeAction,
                    dispatch
                },
                filters = R.ifElse(
                    R.isNil,
                    R.always([
                        getAllStylesheets,
                        getStylesByType(kind),
                        getStyleByRef(ref[0]),
                        maybe(R.path(p(addtionalPath, mp.shadow))),
                        shadow => ({ ...DEFAULT_SHADOW, color: null, ...shadow })
                    ]),
                    R.always([
                        getAllStylesheets,

                        getStyleById(currentStylesheetId),
                        maybe(R.path(p(ref, mp.shadow))),
                        shadow => ({ ...DEFAULT_SHADOW, color: null, ...shadow })
                    ])
                )(currentStylesheetId),
                shadow = R.pipe(...filters)(globalStyles);

            if (shadow) {
                const { color, blur, left, top } = shadow;
                textEffectViewProps.shadowColor = color;
                textEffectViewProps.blurRadius = blur;
                textEffectViewProps.shadowOffsetX = left;
                textEffectViewProps.shadowOffsetY = top;
            }

            return (
                <Dialog>
                    <DialogBody>
                        <VerticalSpacer y={40} />
                        <TextEffectView {...textEffectViewProps} />
                    </DialogBody>
                </Dialog>
            );
        };

    return {
        component: TextShadowDialog,
        confFactory: ({ browserWidth, browserHeight }: BrowserDimensions) => ({
            configurationDialogId,
            position: getCenterPositionForDialog(width, height, browserWidth, browserHeight),
            modal: false,
            dimensions: { width, height }
        }),
        dependsOn: {
            globalStyles: stylesheetsEpic.reducer
        }
    };
};
