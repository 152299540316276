/* @flow */

function getLoginRedirectUrl({ config, targetDomain, username, loginTarget, successUrl }/*: Object */)/*: string */ {
    const
        { requireTicketRedirectAuth, loginUrl } = config,
        hashParams = {};

    if (requireTicketRedirectAuth) {
        hashParams.loginTarget = loginTarget;
    }

    if (targetDomain) {
        hashParams.targetDomain = targetDomain;
    }

    if (username) {
        hashParams.username = username;
    }

    if (successUrl) {
        hashParams.successUrl = successUrl;
    }

    const urlParams = Object
        .keys(hashParams)
        .map((key/*: string*/) => key + '=' + encodeURIComponent(hashParams[key]))
        .join('&');

    return urlParams ? `${loginUrl}?${urlParams}` : loginUrl;
}

module.exports = { getLoginRedirectUrl };
