/* eslint-disable max-len */
export const
    ConfigDialogSettings = {
        width: 700,
        height: 440,
        mctaText: 'msg: common.save {Save}',
        title: 'msg: component.contactForm.config.title {Set form email address}',
        subTitle: 'msg: component.contactForm.config.subTitle {The form responses will be sent to this email address. You can change it in the contact form settings at any time.}' // eslint-disable-line max-len
    },

    AddFieldsSettings = {
        title: 'msg: component.contactForm.addFields {Add fields}',
        subtitle: 'msg: component.conatctForm.addFields.subTitle { Select fields to be added to your contact form. }',
        mctaText: 'msg: component.contactForm.addFields.insertFields {Insert fields}'
    },

    EditFieldsSettings = {
        width: 900,
        height: 600,
        title: 'msg: component.contactForm.editFields.title {Contact form fields}',
        subtitle: 'msg: component.conatctForm.editFields.subTitle { Select a field type and edit the settings. Add new fields by clicking the button on the right. }', // eslint-disable-line max-len
        mctaText: 'msg: common.save {Save}'
    },

    MissingEmailWarningSettings = {
        title: 'msg: component.contactForm.missingEmail {Missing email}',
        emailText: 'msg: common.email {Email}',
        missingEmailInfo: 'msg: component.contactForm.emailNotFound {could not be found on your web space account.}',
        description: 'msg: component.contactForm.missingEmailDescription {This email has most likely been deleted. Please try to add another email.}', // eslint-disable-line max-len
        mctaText: 'msg: component.contactForm.createEmailAddress {Create email address}'
    },

    FieldLabels = {
        name: "msg: component.contactForm.fields.name {Name}",
        phone: "msg: common.phone {Phone}",
        message: "msg: component.contactForm.fields.message {Long Text}",
        company: "msg: component.contactForm.fields.company {Company}",
        website: "msg: component.contactForm.fields.website {Website}",
        text: "msg: component.contactForm.fields.text {Short Text}",
        number: "msg: component.contactForm.fields.number {Number}",
        checkbox: "msg: component.contactForm.fields.checkbox {Checkboxes}",
        radio: "msg: component.contactForm.fields.radio {Radio buttons}",
        dropdown: "msg: component.contactForm.fields.dropdown {Dropdown}",
        email: "msg: component.contactForm.fields.email {Email}",
        subject: "msg: component.contactForm.fields.subject {Subject}",
        fileupload: "msg: component.contactForm.fields.fileUpload {File upload}"
    },

    UndefinedFields = ['text', 'message', 'number', 'checkbox', 'radio', 'dropdown', 'fileupload'],

    FieldDescriptions = {
        name: "msg: component.contactForm.fieldsDesc.name {This field allows text}",
        phone: "msg: component.contactForm.fieldsDesc.phone {This field only allows numbers}",
        message: "msg: component.contactForm.fieldsDesc.message {This field allows a long text}",
        company: "msg: component.contactForm.fieldsDesc.company {This field allows text}",
        website: "msg: component.contactForm.fieldsDesc.website {This field only allows a URL}",
        text: "msg: component.contactForm.fieldsDesc.text {This field allows a short text}",
        number: "msg: component.contactForm.fieldsDesc.number {This field only allows numbers}",
        checkbox: "msg: component.contactForm.fieldsDesc.checkbox {Allows multiple options to be selected}",
        radio: "msg: component.contactForm.fieldsDesc.radio {Allows only one option to be selected}",
        dropdown: "msg: component.contactForm.fieldsDesc.dropdown {Allows only one option to be selected}",
        email: "msg: component.contactForm.fieldsDesc.email {Requires a valid email address}",
        subject: "msg: component.contactForm.fieldsDesc.subject {This field allows text}",
        fileupload: "msg: component.contactForm.fieldDesc.fileupload {Allow file uploads}"
    },

    AtleastOneOptionError = 'msg: component.contactForm.fieldProps.oneOption {You cannot have less than 1 option.}',
    MultipleOptionsError = "msg: component.contactForm.fieldProps.manyOptions {You cannot have less than 2 options.}",

    MinWidth = 220,
    MinWidthWithCaptcha = 275,
    MinWidthWithCaptchaES = 325,

    MinOptions = {
        checkbox: 1,
        radio: 2,
        dropdown: 2
    },

    MaxWidthOfFieldText = 300,

    MinFontSize = 9,
    MaxFontSize = 150,
    ButtonTextCharLimit = 60,

    inputTypeMap = {
        "text": "msg: component.contactForm.textField {Text field}",
        "email": "msg: component.contactForm.emailField {Email field}",
        "textarea": "msg: component.contactForm.textField {Text field}",
        "checkbox": "msg: component.contactForm.multiSelectField {Multiple-select field}",
        "radio": "msg: component.contactForm.singleSelectField {Single-select field}",
        "dropdown": "msg: component.contactForm.singleSelectField {Single-select field}",
        "url": "msg: component.contactForm.textField {Text field}",
        "number": "msg: component.contactForm.numberField {Number field}",
        "tel": "msg: component.contactForm.telField {Phone number field}",
        "all": "msg: component.contactForm.allFilesField {File upload: all files}",
        "images": "msg: component.contactForm.imageFilesField {File upload: only images}",
        "documents": "msg: component.contactForm.documentFilesField {File upload: only documents}",
    },

    ContactFormAlreadyExist = 'ContactFormAlreadyExist',
    ContactFormNotAllowedInTemplateMode = 'ContactFormNotAllowedInTemplateMode',

    ContactFormAlreadyExistDialogContent = {
        title: "msg: component.contactForm.formAlreadyExistsError.title {Contact form already exists}",
        // text: "msg: component.contactForm.formAlreadyExistsError.text {You cannot have more than one contact form on a page.}" // eslint-disable-line max-len
        text: 'msg: tooltip.contactFormOnePerPage {You can only have one contact form on a page. Looks like you already have one.}' // eslint-disable-line max-len
    },

    DEFAULT_MARKETING_CONSENT_CHECKBOX_TEXT = "msg: component.contactForm.marketingConsentDefaultText {Yes, I want to receive news about promotions.}",

    ErrorMsgsOnAdd = {
        [ContactFormAlreadyExist]: {
            ...ContactFormAlreadyExistDialogContent,
            titleForDisabledShortCut: ContactFormAlreadyExistDialogContent.title
        }
    };
