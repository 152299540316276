import { PageTemplateSelectorInitialState } from "./pageTemplateSelectorReducer";
import type { PageTemplateSelectorDialogProps } from "../types";

export const makeDefaultState = (
    { selectedTemplateId, templates = [] }: PageTemplateSelectorDialogProps
) => ({
    ...PageTemplateSelectorInitialState,
    selectedId: selectedTemplateId || '',
    templates
});
