import React from "react";
import { injectIntl } from "../../../../../view/intl/index";
import { PremiumTexts, NonPremiumTexts } from "./constants";
import { isPremiumSubscription } from "../../../../App/epics/subscriptionData/utils";
import type { ProgressTextProps, ProgressTextState } from "../../../flowTypes";

class PublishingProgressText extends React.Component<ProgressTextProps, ProgressTextState> {
    timer: ReturnType<typeof setTimeout> | null = null;
    texts: Array<{ text: string, time: number }>;

    constructor(props) {
        super(props);
        this.state = { index: 0 };
        this.texts = isPremiumSubscription(this.props.subscriptionType) ? PremiumTexts : NonPremiumTexts;
    }

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({ index: 1 });
            this.processTexts();
        }, this.texts[0].time);
    }

    processTexts() {
        const { index } = this.state;
        this.timer = setTimeout(() => {
            if ((index + 1) === this.texts.length) {
                this.setState({ index: 0 });
                this.processTexts();
            } else {
                this.setState({ index: index + 1 });
                this.processTexts();
            }
        }, (this.texts[index].time));
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }

    render() {
        const msg = this.texts[this.state.index].text;
        return (
            <span>{ this.props.intl.msgJoint(msg) }</span>
        );
    }
}

export default injectIntl(PublishingProgressText);
