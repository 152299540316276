import { getSelectedNodeProperty } from "../../utils/nodeUtils/getSelectedNodeProperty";
import { makeComputedParentStyleSelector } from "../../utils/selectors/makeComputedParentStyleSelector";
import { makeExistingParentStyleSelector } from "../../utils/selectors/makeExistingParentStyleSelector";
import { toHsl } from "../../../../../../../../dal/pageMapAdapter/mappers/Base/color";
import type { TinyMceEditor } from "../../../flowTypes";

export const getColor = (editor: TinyMceEditor) => {
    let selector: any = makeExistingParentStyleSelector(editor, 'color');
    let color = getSelectedNodeProperty(editor, selector);

    if (!color) {
        selector = makeComputedParentStyleSelector('color');
        color = getSelectedNodeProperty(editor, selector);
    }

    return color ? toHsl(color) : null;
};
