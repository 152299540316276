
import {
    makeCombineReducer,
    makeDefaultStateReducers,
    makeComponentBaseReducers
} from "../../../../redux/makeReducer/index";
import * as actions from "./actionTypes";
import { WidgetsPaypalKind } from "./kind";
import { defaultState, DEFAULT_HEIGHT, DEFAULT_WIDTH } from "./constants";

const reducer = makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(WidgetsPaypalKind, DEFAULT_WIDTH, DEFAULT_HEIGHT),
        ...makeDefaultStateReducers(defaultState)
    },
    handleActions: {
        [actions.WIDGETS_PAYPAL_UPDATE_PROPS]: (component, { payload: params }) => ({
            ...component, ...params
        }),
        [actions.WIDGETS_PAYPAL_UPDATE_LINK]: (component, { payload: params }) => ({
            ...component, ...params
        }),
        [actions.WIDGETS_PAYPAL_SET_LABEL]: (component, { payload: label }) => ({
            ...component, label
        }),
        [actions.WIDGET_PAYPAL_COLOR_CHANGE]: (component, { payload: color }) => ({
            ...component, color
        }),
        [actions.WIDGET_PAYPAL_LAYOUT_CHANGE]: (component, { payload: layout }) => ({
            ...component, layout
        }),
        [actions.WIDGET_PAYPAL_SHAPE_CHANGE]: (component, { payload: shape }) => ({
            ...component, shape
        })
    }
});

export { reducer };

