import * as R from 'ramda';
import CodeComponentLocationTypes from "./locationTypes";
import CodeComponentKind from "./kind";
import { memoMax } from "../../../../utils/memo";
import type { AnyComponent } from "../flowTypes";

const
    componentIsGhost = (component: AnyComponent) => {
        return component.kind === CodeComponentKind && (
            // don't replace with component.location !== CodeComponentLocationTypes.Body it will brake DND add of code component
            component.location === CodeComponentLocationTypes.BeforeClosingBody
            || component.location === CodeComponentLocationTypes.BeforeClosingHead
        );
    },
    componentIsNotGhost = R.complement(componentIsGhost),
    filterOutGhostComponents = R.filter(componentIsNotGhost),
    getComponentsMapNoGhosts = memoMax(
        (componentsMap) => {
            return filterOutGhostComponents(componentsMap);
        },
        // TODO WBTGEN-6262 Remove this hack, make sure only single componentsMap reference is passed to this function
        // Case: move component out of workspace, mouse down - one dialog should pop up
        3
    );

export {
    getComponentsMapNoGhosts,
    componentIsGhost,
    componentIsNotGhost
};
