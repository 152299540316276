import * as R from 'ramda';
import type { Attachments } from "../componentAttachements/flowTypes";
import type { Range, ComponentRange } from "./flowTypes";
import type { AnyComponent, ComponentsMap } from "../../../../redux/modules/children/workspace/flowTypes";
import { getAttachedContainerCmpId } from "../componentAttachements/util";
import type { BBox } from "../../../App/flowTypes";
import { getSectionAtPosition } from "../isPageMode/utils";
import { getBBox } from "../../../../utils/bBox";
import { ResizeHandleKinds } from "../../../../utils/handle/kinds";
import { isSectionId } from "../../../oneweb/Section/utils";

export const getAllChildrenCmpsId = (containerId: string, attachments: Attachments) => {
        let attachedChildren: Array<string> = attachments[containerId] || [];
        attachedChildren = attachedChildren.reduce((acc, id) => {
            acc.push(...getAllChildrenCmpsId(id, attachments));
            return acc;
        }, attachedChildren);

        return attachedChildren;
    },
    isIntersecting = (range1: Range, range2: Range) => {
        return !(range1.end < range2.start || range2.end < range1.start);
    },

    getComponentYRange = ({ top, height }: AnyComponent): ComponentRange => {
        return ({ range: { start: top, end: top + height } });
    },
    getComponentXRange = ({ left, width }: AnyComponent): ComponentRange => {
        return ({ range: { start: left, end: left + width } });
    },
    getBBoxYRange = ({ top, bottom }: BBox): ComponentRange => {
        return ({ range: { start: top, end: bottom } });
    },
    getBBoxXRange = ({ left, right }: BBox): ComponentRange => {
        return ({ range: { start: left, end: right } });
    },
    isIntersectingCmps = (cmp1: AnyComponent, cmp2: AnyComponent) => {
        return isIntersecting(getComponentXRange(cmp1).range, getComponentXRange(cmp2).range)
            || isIntersecting(getComponentYRange(cmp1).range, getComponentYRange(cmp2).range);
    },
    isIntersectingBBox = (bbox1: BBox, bbox2: BBox) => {
        return isIntersecting(getBBoxXRange(bbox1).range, getBBoxXRange(bbox2).range)
            || isIntersecting(getBBoxYRange(bbox1).range, getBBoxYRange(bbox2).range);
    },
    getCenterPoint = ({ start, end }: Range) => {
        return start + (Math.abs(start - end) / 2);
    },
    getCenterSnappingBbox = ({ top, right, bottom, left }: BBox): Array<BBox> => {
        const centerPoint = getCenterPoint({ start: left, end: right });
        return [
            {
                top,
                right: centerPoint,
                bottom,
                left
            },
            {
                top,
                left: centerPoint,
                bottom,
                right
            }
        ];
    },
    getMiddleSnappingBbox = ({ top, right, bottom, left }: BBox): Array<BBox> => {
        const middlePoint = getCenterPoint({ start: top, end: bottom });
        return [
            {
                top,
                right,
                bottom: middlePoint,
                left
            },
            {
                bottom,
                right,
                top: middlePoint,
                left
            },
        ];
    },
    getSectionRelatedSnappingBBoxes = (sectionBBox: BBox, selectedBBox: BBox, itemsBBoxes: Array<BBox>): Array<BBox> => {
        let sectionSnappingBBoxes: Array<BBox> = [];
        const sectionCenterY = getCenterPoint({ start: sectionBBox.top, end: sectionBBox.bottom });
        const sectionMiddleX = getCenterPoint({ start: sectionBBox.left, end: sectionBBox.right });
        const isSelectedComponentBelowSectionCenterY = selectedBBox.top >= sectionCenterY;
        const isSelectedComponentAboveSectionCenterY = selectedBBox.bottom <= sectionCenterY;
        const isSelectedComponentLeftOfSectionMiddleX = selectedBBox.right <= sectionMiddleX;
        const isSelectedComponentRightOfSectionMiddleX = selectedBBox.left >= sectionMiddleX;
        const bboxes = itemsBBoxes
            .filter((bbox: BBox) => (
                isIntersectingBBox(bbox, selectedBBox) && (
                    (isSelectedComponentBelowSectionCenterY && bbox.bottom <= sectionCenterY) ||
                    (isSelectedComponentAboveSectionCenterY && bbox.top >= sectionCenterY) ||
                    (isSelectedComponentLeftOfSectionMiddleX && bbox.left >= sectionMiddleX) ||
                    (isSelectedComponentRightOfSectionMiddleX && bbox.right <= sectionMiddleX)
                )
            ))
            .map(bbox => {
                if (isSelectedComponentBelowSectionCenterY && bbox.bottom <= sectionCenterY) {
                    const top = sectionCenterY + (sectionCenterY - bbox.bottom);
                    return {
                        ...bbox,
                        top,
                        bottom: top + (bbox.bottom - bbox.top)
                    };
                }
                if (isSelectedComponentAboveSectionCenterY && bbox.top >= sectionCenterY) {
                    const bottom = sectionCenterY - (bbox.top - sectionCenterY);
                    return {
                        ...bbox,
                        bottom,
                        top: bottom - (bbox.bottom - bbox.top)
                    };
                }
                if (isSelectedComponentLeftOfSectionMiddleX && bbox.left >= sectionMiddleX) {
                    const right = sectionMiddleX - (bbox.left - sectionMiddleX);
                    return {
                        ...bbox,
                        right,
                        left: right - (bbox.right - bbox.left)
                    };
                }
                if (isSelectedComponentRightOfSectionMiddleX && bbox.right <= sectionMiddleX) {
                    const left = sectionMiddleX + (sectionMiddleX - bbox.right);
                    return {
                        ...bbox,
                        left,
                        right: left + (bbox.right - bbox.left)
                    };
                }
                return bbox;
            });
        sectionSnappingBBoxes.push(...bboxes);
        return sectionSnappingBBoxes;
    },
    getNearestComponentBBox = (itemsBBox: Array<BBox>, filterFn: Function, sortFn: Function) => {
        const nearestBBox = R.pipe(
            R.filter(filterFn),
            sortFn,
            R.head
        )(itemsBBox);
        return nearestBBox;
    },
    getSectionRelatedResizeBBox = (selectedBBox: BBox, sectionComponentsBBox: Array<BBox>, handleKind: string) => {
        const bboxes: any = [];
        const westRelatedResize = handleKind === ResizeHandleKinds.ResizeW || handleKind === ResizeHandleKinds.ResizeNW
            || handleKind === ResizeHandleKinds.ResizeSW;
        const eastRelatedResize = handleKind === ResizeHandleKinds.ResizeE || handleKind === ResizeHandleKinds.ResizeNE
            || handleKind === ResizeHandleKinds.ResizeSE;
        const northRelatedResize = handleKind === ResizeHandleKinds.ResizeN || handleKind === ResizeHandleKinds.ResizeNW
            || handleKind === ResizeHandleKinds.ResizeNE;
        const southRelatedResize = handleKind === ResizeHandleKinds.ResizeS || handleKind === ResizeHandleKinds.ResizeSW
            || handleKind === ResizeHandleKinds.ResizeSE;

        const intersectingBBoxesWithSelectedBBox =
            R.filter(isIntersectingBBox.bind(this, selectedBBox))(sectionComponentsBBox);

        if (westRelatedResize || eastRelatedResize) {
            const nextCmpBBoxLeftOfSelectedCmp = getNearestComponentBBox(
                intersectingBBoxesWithSelectedBBox,
                (bbox: BBox) => (bbox.right <= selectedBBox.left),
                R.pipe(R.sortBy(R.prop('right')), R.reverse),
            );
            const nextCmpBBoxRightOfSelectedCmp = getNearestComponentBBox(
                intersectingBBoxesWithSelectedBBox,
                (bbox: BBox) => (bbox.left >= selectedBBox.right),
                R.sortBy(R.prop('left')),
            );
            const intersectingLeftRightCmps = [nextCmpBBoxLeftOfSelectedCmp, nextCmpBBoxRightOfSelectedCmp]
                .filter(bbox => !!bbox)
                .map((bbox) => {
                    if (westRelatedResize) {
                        return {
                            ...bbox,
                            right: selectedBBox.right,
                            left: selectedBBox.right - (bbox.right - bbox.left)
                        };
                    }

                    return {
                        ...bbox,
                        left: selectedBBox.left,
                        right: selectedBBox.left + (bbox.right - bbox.left)
                    };
                });
            bboxes.push(...intersectingLeftRightCmps);
        }

        if (northRelatedResize || southRelatedResize) {
            const nextCmpBBoxTopOfSelectedCmp = getNearestComponentBBox(
                intersectingBBoxesWithSelectedBBox,
                (bbox: BBox) => (bbox.bottom <= selectedBBox.top),
                R.pipe(R.sortBy(R.prop('bottom')), R.reverse),
            );
            const nextCmpBBoxBottomOfSelectedCmp = getNearestComponentBBox(
                intersectingBBoxesWithSelectedBBox,
                (bbox: BBox) => (bbox.top >= selectedBBox.bottom),
                R.sortBy(R.prop('top')),
            );
            const intersectingTopBottomCmps = [nextCmpBBoxTopOfSelectedCmp, nextCmpBBoxBottomOfSelectedCmp]
                .filter(bbox => !!bbox)
                .map((bbox) => {
                    if (northRelatedResize) {
                        return {
                            ...bbox,
                            bottom: selectedBBox.bottom,
                            top: selectedBBox.bottom - (bbox.bottom - bbox.top)
                        };
                    }

                    return {
                        ...bbox,
                        top: selectedBBox.top,
                        bottom: selectedBBox.top + (bbox.bottom - bbox.top)
                    };
                });
            bboxes.push(...intersectingTopBottomCmps);
        }

        return bboxes;
    },
    getSectionSnappingBBoxes = (
        cmpId: string,
        parentContainerId: string|null,
        attachments: Attachments,
        componentsMap: ComponentsMap,
        templateWidth: number,
        handleKind: string = ''
    ) => {
        const selectedCmp = componentsMap[cmpId];
        const parentId = parentContainerId || getAttachedContainerCmpId(cmpId, attachments);
        if (!parentId || !isSectionId(parentId, componentsMap)) {
            return [];
        }
        const selectedCmpBbox = getBBox(selectedCmp);
        const noParentSectionDetected = parentId === cmpId;
        const section = noParentSectionDetected
            ? getSectionAtPosition({ y: selectedCmp.top, x: selectedCmp.left }, componentsMap) // for add new cmp case
            : componentsMap[parentId];
        // this check is for tests that have data that dont have sections
        if (!section) {
            return [];
        }
        const sectionChildrenBboxes: Array<BBox> = (attachments[section.id] || []).map(id => getBBox(componentsMap[id]));
        const sectionBBox = {
            top: section.top,
            left: 0,
            right: templateWidth,
            bottom: section.top + section.height
        };
        if (section && noParentSectionDetected) { // when dragging a new component
            sectionChildrenBboxes.push(selectedCmpBbox);
        }

        const sectionCenterSnappingBbox = getCenterSnappingBbox(sectionBBox);
        const sectionMiddleSnappingBbox = getMiddleSnappingBbox(sectionBBox);
        const result = [...sectionCenterSnappingBbox, ...sectionMiddleSnappingBbox];
        const sectionSnappingBBoxes = getSectionRelatedSnappingBBoxes(sectionBBox, selectedCmpBbox, sectionChildrenBboxes);

        const ignoreHorizontalSnap = sectionChildrenBboxes.filter(({ left, right, top, bottom }) => {
            const width = (right - left);
            if (width % 2 && Math.abs((left + width / 2) - templateWidth / 2) <= 1) {
                const cmpsHAligned = sectionChildrenBboxes.filter((box) => !(box.top > bottom || box.bottom < top));
                return (cmpsHAligned.length % 2) && cmpsHAligned.every(({ left, right }) => width === (right - left));
            }
            return false;
        });

        const ignoreVerticalSnap = sectionChildrenBboxes.filter(({ top, bottom, left, right }) => {
            const height = (bottom - top);
            if (height % 2 && Math.abs((left + height / 2) - templateWidth / 2) <= 1) {
                const cmpsVAligned = sectionChildrenBboxes.filter((box) => !(box.left > right || box.right < left));
                return (cmpsVAligned.length % 2) && cmpsVAligned.every(({ bottom, top }) => height === (bottom - top));
            }
            return false;
        });

        if (ignoreHorizontalSnap.length) {
            ignoreHorizontalSnap.forEach(({ top, bottom }) => {
                sectionSnappingBBoxes.forEach((box) => {
                    if (!(box.top > bottom || box.bottom < top)) {
                        box.left = sectionBBox.left; box.right = sectionBBox.right; // eslint-disable-line
                    }
                });
            });
        }

        if (ignoreVerticalSnap.length) {
            ignoreVerticalSnap.forEach(({ left, right }) => {
                sectionSnappingBBoxes.forEach((box) => {
                    if (!(box.left > right || box.right < left)) {
                        box.top = sectionBBox.top; box.bottom = sectionBBox.bottom; // eslint-disable-line
                    }
                });
            });
        }

        const isResize = handleKind;
        if (isResize) {
            const resizeSnappingBBoxes = getSectionRelatedResizeBBox(selectedCmpBbox, sectionChildrenBboxes, handleKind);
            if (resizeSnappingBBoxes.length) {
                result.push(...resizeSnappingBBoxes);
            }
        }
        result.push(...sectionSnappingBBoxes);

        return result;
    };
