import React from "react";
import { connect } from 'react-redux';
import type { AppState } from "../../../redux/modules/flowTypes";
import Styles from './styles.css';
import { Msg } from "../../intl/index";
import { makeEpicStateSelector } from '../../../epics/makeEpic';
import { textLimitWarningVAT } from '../../../components/App/epics/textLimitWarning/valueActionType';
import type { ComponentMainActionsEpicState } from '../../../components/Workspace/epics/componentMainActions/flowTypes';
import type { textLimitWarningEpicInitialStateType } from "../../../components/App/epics/textLimitWarning/types";

type TextLimitWarningType = {
    state: textLimitWarningEpicInitialStateType,
    componentMainActions: ComponentMainActionsEpicState;
};

const EXTRA_TOP = 10;

const TextLimitWarningComponent = ({ state, componentMainActions }: TextLimitWarningType) => {
    const {
        // @ts-ignore this need to be fixed
        mctaTop,
        // @ts-ignore
        selectedComponentNotGhost: {
            left,
            top,
            height
        }
    } = componentMainActions;

    let styleTop = mctaTop + 36;
    let styleLeft = left;

    if (top + height > styleTop) {
        styleTop = top + height;
    }

    styleTop += EXTRA_TOP;

    const style = {
        top: styleTop,
        left: styleLeft,
    };

    return (
        state && state.showWarning ?
            <div className={Styles.warningContainer} style={style}>
                <Msg
                    k="text.character.warning"
                    params={{ count: state.remainingCharacters }}
                >
                    {`{count} characters left`}
                </Msg>
            </div>
            : null
    );
};

const stateSelector = makeEpicStateSelector(textLimitWarningVAT);
const mapStateToProps = (appState: AppState) => ({
    state: stateSelector(appState),
});

const TextLimitWarning = connect(mapStateToProps)(TextLimitWarningComponent);

export {
    TextLimitWarning
};
