import { formatMessage } from '../../../view/intl/CurrentLocale';
import { GoogleReviewsComponent } from "./flowTypes";
import { getReviewTimeText } from "./utils";

const adjustComponentOnAdd = (
    component: GoogleReviewsComponent,
    componentDependencies: any
): GoogleReviewsComponent => {
    const { buttonDefaultStyleId, globalStyles: { styles }, templateWidth: width } = componentDependencies,
        { name } = styles.filter(({ id }) => id === buttonDefaultStyleId)[0];
    return {
        ...component,
        width,
        left: 0,
        seeMoreText: formatMessage({
            id: 'common.seeMore',
            defaultMessage: 'See more'
        }),
        seeLessText: formatMessage({
            id: 'common.seeLess',
            defaultMessage: 'See less'
        }),
        headerText: formatMessage({
            id: 'component.googleReviews.headerText',
            defaultMessage: 'What our customers are saying'
        }),
        reviewButtonText: formatMessage({
            id: 'component.googleReviews.leaveReview',
            defaultMessage: 'Leave a review'
        }),
        googleReviewsText: formatMessage({
            id: 'common.googleReviews',
            defaultMessage: 'Google reviews'
        }),
        reviewTimeText: getReviewTimeText(),
        styleButton: {
            ...(component.styleButton || {}),
            globalId: buttonDefaultStyleId,
            globalName: name
        }
    };
};

export {
    adjustComponentOnAdd
};
