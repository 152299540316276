import { componentIsGhost } from "../oneweb/Code/getComponentsMapNoGhosts";
import isStretchComponentKind from "../oneweb/isStretchComponentKind";
import type { ComponentsMap } from "../../redux/modules/children/workspace/flowTypes";
import type { TemplateComponent } from "../oneweb/Template/flowTypes";
import { updateComponentsRelIns } from "../Workspace/epics/relations/updateComponentsRelIns";
import type { RelationsMap } from "../Workspace/epics/relations/flowTypes";
import { getAllCmpIdsInModernHeaderFooter } from "../ModernLayouts/preview_utils";
import { getWebShopStripCmpIdsTrueMap } from "../ModernLayouts/layoutsData/webshopMHFDataUtils";

export const extractRelInsMap = (componentsMap: ComponentsMap): RelationsMap => {
    let relInsMap = {};
    Object.keys(componentsMap).forEach(id => {
        if (componentsMap[id].relIn) {
            relInsMap[id] = {
                relIn: componentsMap[id].relIn
            };
        }
    });
    return relInsMap;
};

export default (componentsMap: ComponentsMap, template: TemplateComponent, shouldExcludeMHFCmps: boolean = false) => {
    let oldTemplateWidth = template.width,
        expandRightSide = 0,
        expandLeftSide = 0,
        newComponentsMap: ComponentsMap = {},
        diff = 0;
    const mhfCmpIdsTrueMap = {
        ...getAllCmpIdsInModernHeaderFooter(componentsMap),
        ...getWebShopStripCmpIdsTrueMap(componentsMap)
    };
    Object.keys(componentsMap).forEach(id => {
        const { kind, left, width, stretch = false } = componentsMap[id],
            right = left + width;
        if (
            componentIsGhost(componentsMap[id]) ||
            isStretchComponentKind(kind, stretch) ||
            (shouldExcludeMHFCmps && mhfCmpIdsTrueMap[id])
        ) {
            return;
        }

        if (right > oldTemplateWidth) {
            expandRightSide = Math.max((right - oldTemplateWidth), expandRightSide);
        }

        if (left < 0) {
            expandLeftSide = Math.max(Math.abs(left), expandLeftSide);
        }
    });
    if (!expandRightSide && !expandLeftSide) {
        return { componentsMap, template, relInsMap: extractRelInsMap(componentsMap), noChange: true };
    }
    diff = Math.max(expandLeftSide, expandRightSide);
    const newTemplateWidth = oldTemplateWidth + (2 * diff);
    Object.keys(componentsMap).forEach(id => {
        const { kind, relIn, left, stretch = false } = componentsMap[id];

        newComponentsMap[id] = { ...componentsMap[id] };
        if (!isStretchComponentKind(kind, stretch)) {
            newComponentsMap[id].left = left + diff;
            if (relIn && componentsMap[relIn.id] && isStretchComponentKind(componentsMap[relIn.id].kind)) {
                newComponentsMap[id].relIn = {
                    ...newComponentsMap[id].relIn,

                    left: newComponentsMap[id].relIn.left + diff
                };
            }
        } else {
            newComponentsMap[id].left = 2 * -diff;
            newComponentsMap[id].width = newComponentsMap[id].width + (4 * diff);
        }
    });
    newComponentsMap = updateComponentsRelIns(newComponentsMap, newTemplateWidth);
    return {
        componentsMap: newComponentsMap,
        template: { ...template, width: newTemplateWidth },
        relInsMap: extractRelInsMap(newComponentsMap),
        noChange: false
    };
};
