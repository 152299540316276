import React from 'react';
import cx from 'classnames';
import styles from './LinkChooserDialog.css';
import { LcTabName, LC_CURRENT_LINK_MAX_WIDTH } from '../constants';
import { getLinkName } from "../utils/getLinkName";
import { injectIntl, Msg } from '../../../../intl/index';
import LongTextTip from '../../../LongTextTip/index';
import CheckBox from '../../../CheckBox/CheckBoxWithLabel';
import { LC_RESET_CURRENT_RESULT, LC_TOGGLE_OPEN_IN_NEW_WINDOW } from "../actionTypes";
import type { LinkChooserFooterPropsTypes } from "../flowTypes";
import { getIntlLinkName } from "../utils/getIntlLinkName";
import { isEmailValid } from '../utils/isEmailValid';
import { isLocationValid } from '../utils/isLocationValid';
import { isPhoneValid } from '../utils/isPhoneValid';

export const LinkChooserFooter = injectIntl((props: LinkChooserFooterPropsTypes) => {
    const
        { state: { currentResult, activeTabName, openInNewWindow }, state, site, intl, sectionComponents, globalVariables } = props,
        link = currentResult && currentResult.link,
        { name: linkName, isBrokenLink } = getIntlLinkName({
            link,
            site,
            getter: getLinkName,
            intl,
            sectionComponents,
            globalVariables,
        }),
        currentLinkLabelClassName = cx({ [styles.footerDisabled]: !link }),
        currentLinkValueClassName = cx(styles.currentLinkValue, { [styles.broken]: isBrokenLink });

    let hasSelection = false;
    if (state[activeTabName]) {
        if (activeTabName === LcTabName.URL) {
            hasSelection = state[activeTabName].selection && state[activeTabName].isValid;
        } else if (activeTabName === LcTabName.EMAIL) {
            hasSelection = state[activeTabName].selection && isEmailValid(state[activeTabName], globalVariables);
        } else if (activeTabName === LcTabName.LOCATION) {
            hasSelection = state[activeTabName].selection && isLocationValid(state[activeTabName], globalVariables);
        } else if (activeTabName === LcTabName.PHONE) {
            // @ts-ignore
            hasSelection = state[activeTabName].value && isPhoneValid(state[activeTabName], globalVariables);
        } else {
            hasSelection = !!state[activeTabName].selection;
        }
    }

    const openInNewWindowCheckboxIsDisabled = !hasSelection || isBrokenLink;

    return (
        <div>
            <div>
                <Msg k="lc.currentLink" className={currentLinkLabelClassName}>Current link:</Msg>
                {linkName && (
                    <span className={styles.currentLink}>
                        <LongTextTip
                            maxWidth={LC_CURRENT_LINK_MAX_WIDTH}
                            className={currentLinkValueClassName}
                        >{linkName}</LongTextTip>
                        <span
                            onClick={props.dispatch.bind(null, { type: LC_RESET_CURRENT_RESULT })}
                            className={styles.currentLinkCancel}
                        />
                    </span>
                )}
            </div>
            {activeTabName !== LcTabName.EMAIL && activeTabName !== LcTabName.PHONE && (
                <CheckBox
                    label="msg: lc.openInNewWindow {Open in new window/tab}"
                    onClick={props.dispatch.bind(null, { type: LC_TOGGLE_OPEN_IN_NEW_WINDOW })}
                    isChecked={openInNewWindow}
                    disabled={openInNewWindowCheckboxIsDisabled}
                    checkBoxRight
                    containerClassName={styles.openInNewWindowCheckboxContainer}
                    className={styles.openInNewWindowCheckbox}
                    disabledLabelClassName={styles.footerDisabled}
                />
            )}
        </div>
    );
});
