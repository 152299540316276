import { awsAuthAction } from "../../../../src/redux/middleware/aws/actions";
import { TrialUserCustomAttributes } from '../../../../../trial/lambda-functions/src/TrialUserCustomAttributes';
import { TrialUserAttributes } from '../../../../../trial/lambda-functions/src/TrialUserAttributes';
import { getDefultLocaleId } from "../../../../src/components/Locale/getDefaultLocale";

const DEFAULT_LOCALE = getDefultLocaleId();
export type UserSignUpRequest = {
    email: string,
    password: string,
    captchaResponse: string,
    locale: string,
    currentHostName: string,
    clientIPAddress: string,
    abtAttrs?: Record<string, any>,
    mailCampaignAgreed: string
}

export const
    DEMO_SIGNUP_REQUEST_ACTION = 'DEMO_SIGNUP_REQUEST_ACTION',
    DEMO_SIGNUP_SUCCESS_ACTION = 'DEMO_SIGNUP_SUCCESS_ACTION',
    DEMO_SIGNUP_FAILURE_ACTION = 'DEMO_SIGNUP_FAILURE_ACTION',
    demoSignUpAction = (userSignUpRequest: UserSignUpRequest) => awsAuthAction({
        actionTypes: [
            DEMO_SIGNUP_REQUEST_ACTION,
            DEMO_SIGNUP_SUCCESS_ACTION,
            DEMO_SIGNUP_FAILURE_ACTION,
        ],
        serviceMethod: 'signUp',
        serviceMethodParams: [{
            password: userSignUpRequest.password,
            username: userSignUpRequest.email,
            attributes: {
                [TrialUserAttributes.EMAIL]: userSignUpRequest.email,
                [TrialUserAttributes.LOCALE]: userSignUpRequest.locale || DEFAULT_LOCALE,
                [TrialUserCustomAttributes.SIGNUP_REQUEST_HOST_NAME]: userSignUpRequest.currentHostName,
                [TrialUserCustomAttributes.SIGNUP_IP_ADDRESS]: userSignUpRequest.clientIPAddress,
                [TrialUserCustomAttributes.MAIL_CAMPAIGN_AGREED]: userSignUpRequest.mailCampaignAgreed,
                ...userSignUpRequest.abtAttrs,
            },
            validationData: [{
                "Name": TrialUserCustomAttributes.RECAPTCHA_CODE,
                "Value": userSignUpRequest.captchaResponse
            }]
        }]
    });

export const
    DEMO_UPDATE_SIGNUP_EMAIL_VALUE_ACTION = 'DEMO_UPDATE_SIGNUP_EMAIL_VALUE_ACTION',
    updateEmailValue = (emailValue: String) => ({
        type: DEMO_UPDATE_SIGNUP_EMAIL_VALUE_ACTION,
        payload: { emailValue },
        amendToSelf: true
    });

export const
    DEMO_UPDATE_SIGNUP_PASSWORD_VALUE_ACTION = 'DEMO_UPDATE_SIGNUP_PASSWORD_VALUE_ACTION',
    updatePasswordValue = (passwordValue: String) => ({
        type: DEMO_UPDATE_SIGNUP_PASSWORD_VALUE_ACTION,
        payload: { passwordValue },
        amendToSelf: true
    });

export const
    DEMO_TOGGLE_TERMS_CONDITION_CHECKBOX_ACTION = 'DEMO_TOGGLE_TERMS_CONDITION_CHECKBOX_ACTION',
    enableTermsToggle = () => ({
        type: DEMO_TOGGLE_TERMS_CONDITION_CHECKBOX_ACTION,
    });

export const
    DEMO_MAIL_CAMPAIGN_AGREEMENT_CHECKBOX_ACTION = 'DEMO_MAIL_CAMPAIGN_AGREEMENT_CHECKBOX_ACTION',
    mailCampaignAgreementToggle = () => ({
        type: DEMO_MAIL_CAMPAIGN_AGREEMENT_CHECKBOX_ACTION,
    });

export const
    DEMO_TOGGLE_SIGNUP_SHOW_PASSWORD_VALUE_ACTION = 'DEMO_TOGGLE_SIGNUP_SHOW_PASSWORD_VALUE_ACTION',
    toggleShowPassword = () => ({
        type: DEMO_TOGGLE_SIGNUP_SHOW_PASSWORD_VALUE_ACTION
    });

export const
    DEMO_SHOW_SIGNIN_PAGE_ACTION = 'DEMO_SHOW_SIGNIN_PAGE_ACTION',
    showSignInPage = () => ({
        type: DEMO_SHOW_SIGNIN_PAGE_ACTION
    });

export const
    DEMO_SIGNUP_CLEAN_ALL_ERRORS_ACTION = 'DEMO_SIGNUP_CLEAN_ALL_ERRORS_ACTION',
    cleanUpAllErrors = () => ({
        type: DEMO_SIGNUP_CLEAN_ALL_ERRORS_ACTION
    });

export const
    DEMO_SIGNUP_SHOW_INVALID_EMAIL_ERROR_ACTION = 'DEMO_SIGNUP_SHOW_INVALID_EMAIL_ERROR_ACTION',
    showInvalidEmailError = () => ({
        type: DEMO_SIGNUP_SHOW_INVALID_EMAIL_ERROR_ACTION
    });

export const DEMO_SIGNUP_SHOW_INVALID_PASSWORD_ERROR_ACTION = 'DEMO_SIGNUP_SHOW_INVALID_PASSWORD_ERROR_ACTION',
    showInvalidPasswordError = () => ({
        type: DEMO_SIGNUP_SHOW_INVALID_PASSWORD_ERROR_ACTION
    });

export const
    DEMO_SIGNUP_TERMS_NOT_CHECKED_ERROR_ACTION = 'DEMO_SIGNUP_TERMS_NOT_CHECKED_ERROR_ACTION',
    showTermsNotCheckedError = () => ({
        type: DEMO_SIGNUP_TERMS_NOT_CHECKED_ERROR_ACTION
    });

export const
    DEMO_CLEAN_UP_SIGNUP_SCREEN_ACTIONS = 'DEMO_CLEAN_UP_SIGNUP_SCREEN_ACTIONS',
    cleanUpSignupScreen = () => ({
        type: DEMO_CLEAN_UP_SIGNUP_SCREEN_ACTIONS
    });

export const
    DEMO_SIGNUP_LOADING_ACTION = 'DEMO_SIGNUP_LOADING_ACTION',
    signUpLoading = () => ({
        type: DEMO_SIGNUP_LOADING_ACTION
    });

export const
    DEMO_GET_CLIENT_IP_REQUEST_ACTION = 'DEMO_GET_CLIENT_IP_REQUEST_ACTION',
    DEMO_GET_CLIENT_IP_SUCCESS_ACTION = 'DEMO_GET_CLIENT_IP_SUCCESS_ACTION',
    DEMO_GET_CLIENT_IP_FAILURE_ACTION = 'DEMO_GET_CLIENT_IP_FAILURE_ACTION',
    demoGetClientIpRequestAction = () => ({
        type: DEMO_GET_CLIENT_IP_REQUEST_ACTION
    }),
    demoGetClientIpSuccessAction = (ipAddress: string) => ({
        type: DEMO_GET_CLIENT_IP_SUCCESS_ACTION,
        payload: {
            ipAddress
        }
    }),
    demoGetClientIpFailureAction = () => ({
        type: DEMO_GET_CLIENT_IP_FAILURE_ACTION
    });

export const
    DEMO_OPEN_SIGNUP_MODAL_ACTION = 'DEMO_OPEN_SIGNUP_MODAL_ACTION',
    demoOpenSignupModalAction = () => ({
        type: DEMO_OPEN_SIGNUP_MODAL_ACTION
    });

export const
    DEMO_CLOSE_SIGNUP_MODAL_ACTION = 'DEMO_CLOSE_SIGNUP_MODAL_ACTION',
    demoCloseSignupModalAction = () => ({
        type: DEMO_CLOSE_SIGNUP_MODAL_ACTION
    });
