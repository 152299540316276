/* eslint-disable max-len */

import React from 'react';
import { connect } from "react-redux";
import cx from 'classnames';

import styles from '../UpgradePlansDialog.css';
import { Msg } from "../../../../../../view/intl";
import getCenteredDialogConfig from "../../../../../DialogManager/getCenteredDialogConfig";
import { isNormalSubscription } from "../../../../../App/epics/subscriptionData/utils";
import StandardDialog from '../../../../../../view/common/dialogs/StandardDialog';
import { StripTypes } from "../../../../../../view/common/dialogs/baseDialog";
import UpgradePlansDialogImages from "../UpgradePlansDialogImages";
import PrimaryButton from "../../../../../../view/common/Button/PrimaryButton";
import VerticalSpacer from "../../../../../../view/common/VerticalSpacer";
import Scrollbar from "../../../../../../view/common/Scrollbar";
import { makeEpicStateSelector } from "../../../../../../epics/makeEpic";
import subscriptionDataVat from "../../../../../App/epics/subscriptionData/valueActionType";
import type { UpgradePlansDialogProps } from "../../flowTypes";

import { getExternalPlansLink } from '../../../../../ComponentTierManager/utils';
import { navigateToBlankLocationAction } from "../../../../../../redux/middleware/window/actionCreators";
import { closeDialog } from '../../../../../App/actionCreators';
import { upgradeCanceledAction } from '../../../../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions';
import { UpgradeCancelReason } from '../../../../../EventTracking/constants';
import { getCenterPositionForDialog } from '../../../../../DialogManager/utility';
import { SubscriptionTypes } from "../../../../../../constants/app";

const
    titleMsg = "msg: upgrade.plans.dialog.title {Upgrade your Website Builder}",
    subTitle = "msg: upgrade.plans.dialog.subTitle {Select a plan that\'s right for you and upgrade to get the features you need.}";

let externalPlansLink = '';
try {
    externalPlansLink = getExternalPlansLink();
} catch {
    // do nothing
}

const
    subscriptionDataSelector = makeEpicStateSelector(subscriptionDataVat),
    mapStateToProps = appState => {
        const subscriptionData = subscriptionDataSelector(appState);
        return { subscriptionData };
    };
const
    partnerUpgradePlansDialogComponent = (props: UpgradePlansDialogProps) => {
        const {
                subscriptionData: {
                    subscriptionType,
                },
                dispatch
            } = props,
            isNormalUser = isNormalSubscription(subscriptionType);

        const standardDialogExtraProps: Record<string, any> = {
            showTitle: true,
            title: titleMsg,
            subTitle,
            titleBoxClassName: styles.upgradePlansDialogTitleBox,
            titleExtraClassName: styles.upgradePlansDialogTitleContainer,
            titleClassName: cx(styles.upgradePlanTxtCommonStyle, styles.upgradePlansDialogTitle),
            subTitleClass: cx(styles.upgradePlanTxtCommonStyle, styles.upgradePlansDialogSubTitle),
            stripType: StripTypes.NONE,
            closeBtnClassName: styles.closeBtnClassName
        };

        return (
            <Scrollbar height="100%">
                <StandardDialog
                    stripType={StripTypes.NONE}
                    closeBtnClassName={styles.closeBtnClassName}
                    onClose={() => {
                        dispatch(closeDialog());
                        dispatch(upgradeCanceledAction(UpgradeCancelReason.UserCancelled));
                    }}
                    {...standardDialogExtraProps}
                >
                    <div className={styles.rootContainer}>
                        <UpgradePlansDialogImages subscriptionType={subscriptionType} />
                        <VerticalSpacer y={56} />
                        <div
                            className={styles.upgradePlansDialogSeePlansBtnContainer}
                            onClick={() => {
                                dispatch(navigateToBlankLocationAction(externalPlansLink));
                            }}
                        >
                            { /* @ts-ignore */ }
                            <PrimaryButton className={styles.upgradePlansDialogSeePlansBtn} isLarge>
                                <Msg k="upgrade.plans.dialog.seeplans">
                                    See plans
                                </Msg>
                            </PrimaryButton>
                        </div>
                        <VerticalSpacer y={56} />
                        {
                            isNormalUser &&
                            <React.Fragment>
                                <Msg className={styles.plansDescTitle} k="upgrade.plans.dialog.planDescBody">
                                    Upgrading also includes
                                </Msg>
                                <VerticalSpacer y={8} />
                                <Msg className={styles.plansDescBody} k="upgrade.plans.dialog.planDescription">
                                    Backup & Restore feature for added security, HD image support, up to 40% faster site loading, Google Analytics tracking, FB pixel tracking and a custom code component.
                                </Msg>
                                <VerticalSpacer y={100} />
                            </React.Fragment>
                        }
                    </div>
                </StandardDialog>
            </Scrollbar>
        );
    },
    partnerUpgradePlansDialog = () => {
        const
            WIDTH = 1162,
            NORMAL_HEIGHT = 840,
            PREMIUM_HEIGHT = 645,
            getDialogDimensions = (maxHeight, browserHeight) => ({
                width: WIDTH,
                height: Math.min(Math.max(browserHeight - 100, 475), maxHeight)
            });
        return {
            ...getCenteredDialogConfig(WIDTH, NORMAL_HEIGHT),
            confFactory: ({ browserWidth, browserHeight }, { subscriptionData: { subscriptionType } }) => {
                const maxHeight = subscriptionType === SubscriptionTypes.PREMIUM ? PREMIUM_HEIGHT : NORMAL_HEIGHT;
                const { width, height } = getDialogDimensions(maxHeight, browserHeight);
                return {
                    position: getCenterPositionForDialog(width, height, browserWidth, browserHeight),
                    modal: true,
                    dimensions: { width, height }
                };
            },
            component: connect(mapStateToProps)(partnerUpgradePlansDialogComponent),
            dialogClassName: styles.dialogContainerClassName,
            moveToCenter: true,
            dragEnabled: false,
        };
    };

export {
    partnerUpgradePlansDialog
};
