import {
    DEFAULT_WIDTH,
    DEFAULT_HEIGHT
} from './constants';
import { parseIframeCode, regexpsValidator } from '../utils';
import { type IframeParserOutput, type LinkValidator } from '../types';

export const isGoogleMapsLinkValid: LinkValidator = regexpsValidator([
    /^https:\/\/www\.google\.com\/maps\/embed\?pb=/
]);

export const parseGoogleMapsCode = (code: string): Promise<any> => {
    const params = <any>{};
    const str = code.trim();

    if (isGoogleMapsLinkValid(str)) {
        const match = str.match(/!2s(.*)!5e0/);
        if (Array.isArray(match)) {
            params.addressText = decodeURIComponent(match[1]);
        }
    } else {
        const { src, width, height }: IframeParserOutput = parseIframeCode(str, { width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT });
        if (src && isGoogleMapsLinkValid(src)) {
            const match = src.match(/!2s(.*)!5e0/);
            if (Array.isArray(match)) {
                params.source = 'iframe';
                params.width = width;
                params.height = height;
                params.addressText = decodeURIComponent(match[1]);
            }
        }
    }

    return params;
};

export const getLink = (link: string, locale: string): string => {
    const r = (a, b) => a.replace(b, locale);
    return link
        .replace(/!3m2!1s(\w\w)/, r)
        .replace(/!5m2!1s(\w\w)/, r);
};

export const getIFrameLink = ({ addressText, addressLocation, addressUrl, locale, zoom }) => {
    if (addressUrl) {
        return `${addressUrl}&hl=${locale}&z=${zoom}&output=embed`;
    } else {
        const q = addressLocation && addressLocation.lat && addressLocation.lng
            ? `${addressLocation.lat},${addressLocation.lng}`
            : encodeURIComponent(addressText);
        return `https://maps.google.com/maps?q=${q}&hl=${locale}&z=${zoom}&output=embed`;
    }
};

export const getAddressDataFromDependencies = (componentDependencies) => {
    return {
        addressText: componentDependencies.generalData.addressText,
        addressLocation: componentDependencies.generalData.addressLocation,
        addressUrl: componentDependencies.generalData.addressUrl,
    };
};
