import { ApiActionTypes } from "../../redux/middleware/api/flowTypes";

export const
    APP_MOUNT = 'APP_MOUNT',

    SITE_DATA_LOAD_REQUEST = 'SITE_DATA_LOAD_REQUEST',
    SITE_DATA_LOAD_FAIL = 'SITE_DATA_LOAD_FAIL',

    // WBTGEN-10019 - Backup & Restore is excluded from this criteria
    // IMPORTANT: Never use SITE_DATA_LOAD_SUCCESS, always subscribe to SITE_DATA_LOAD_SUCCESS_CONTINUE_WITH_WSB
    SITE_DATA_LOAD_SUCCESS = 'SITE_DATA_LOAD_SUCCESS',
    SITE_DATA_LOAD_SUCCESS_NO_PAGES = 'SITE_DATA_LOAD_SUCCESS_NO_PAGES',
    SITE_DATA_UPDATE_SUCCESS_NO_PAGES = 'SITE_DATA_UPDATE_SUCCESS_NO_PAGES',
    SITE_DATA_LOAD_SUCCESS_PAGES_EXIST = 'SITE_DATA_LOAD_SUCCESS_PAGES_EXIST',

    SITE_PAGES_LOAD_REQUEST = 'SITE_PAGES_LOAD_REQUEST',
    SITE_PAGES_LOAD_SUCCESS = 'SITE_PAGES_LOAD_SUCCESS',
    SITE_PAGES_LOAD_FAILURE = 'SITE_PAGES_LOAD_FAILURE',
    sitePagesLoadActionTypes: ApiActionTypes = [SITE_PAGES_LOAD_REQUEST, SITE_PAGES_LOAD_SUCCESS, SITE_PAGES_LOAD_FAILURE],

    SITE_DATA_BACKUP_LOAD_REQUEST = 'SITE_DATA_BACKUP_LOAD_REQUEST',
    SITE_DATA_BACKUP_LOAD_SUCCESS = 'SITE_DATA_BACKUP_LOAD_SUCCESS',
    SITE_DATA_BACKUP_LOAD_FAIL = 'SITE_DATA_BACKUP_LOAD_FAIL',
    siteDataLoadActionTypes = [SITE_DATA_LOAD_REQUEST, SITE_DATA_LOAD_SUCCESS, SITE_DATA_LOAD_FAIL] as ApiActionTypes,
    siteDataBackupLoadActionTypes: ApiActionTypes = [SITE_DATA_BACKUP_LOAD_REQUEST, SITE_DATA_BACKUP_LOAD_SUCCESS,
        SITE_DATA_BACKUP_LOAD_FAIL],

    SITE_SUBSCRIPTION_DATA_LOAD_REQUEST = 'SITE_SUBSCRIPTION_DATA_LOAD_REQUEST',
    SITE_SUBSCRIPTION_DATA_LOAD_SUCCESS = 'SITE_SUBSCRIPTION_DATA_LOAD_SUCCESS',
    SITE_SUBSCRIPTION_DATA_LOAD_FAIL = 'SITE_SUBSCRIPTION_DATA_LOAD_FAIL',
    siteSubscriptionDataLoadActionTypes: ApiActionTypes = [
        SITE_SUBSCRIPTION_DATA_LOAD_REQUEST,
        SITE_SUBSCRIPTION_DATA_LOAD_SUCCESS,
        SITE_SUBSCRIPTION_DATA_LOAD_FAIL
    ],
    SITE_SUBSCRIPTION_METADATA_LOAD_REQUEST_ACTION = 'SITE_SUBSCRIPTION_METADATA_LOAD_REQUEST_ACTION',
    SITE_SUBSCRIPTION_METADATA_LOAD_SUCCESS_ACTION = 'SITE_SUBSCRIPTION_METADATA_LOAD_SUCCESS_ACTION',
    SITE_SUBSCRIPTION_METADATA_LOAD_FAIL_ACTION = 'SITE_SUBSCRIPTION_METADATA_LOAD_FAIL_ACTION',
    siteSubscriptionMetadataLoadActionTypes: ApiActionTypes = [
        SITE_SUBSCRIPTION_METADATA_LOAD_REQUEST_ACTION,
        SITE_SUBSCRIPTION_METADATA_LOAD_SUCCESS_ACTION,
        SITE_SUBSCRIPTION_METADATA_LOAD_FAIL_ACTION,
    ],

    SITE_SUBSCRIPTION_DATA_LOAD_REQUEST_FOR_GA_CAMP = 'SITE_SUBSCRIPTION_DATA_LOAD_REQUEST_FOR_GA_CAMP',
    SITE_SUBSCRIPTION_DATA_LOAD_SUCCESS_FOR_GA_CAMP = 'SITE_SUBSCRIPTION_DATA_LOAD_SUCCESS_FOR_GA_CAMP',
    SITE_SUBSCRIPTION_DATA_LOAD_FAIL_FOR_GA_CAMP = 'SITE_SUBSCRIPTION_DATA_LOAD_FAIL_FOR_GA_CAMP',
    siteSubscriptionDataLoadActionTypesForGoogleAdsCampaign: ApiActionTypes = [
        SITE_SUBSCRIPTION_DATA_LOAD_REQUEST_FOR_GA_CAMP,
        SITE_SUBSCRIPTION_DATA_LOAD_SUCCESS_FOR_GA_CAMP,
        SITE_SUBSCRIPTION_DATA_LOAD_FAIL_FOR_GA_CAMP
    ],

    SITE_SUBSCRIPTION_BACKUP_RESTORE_DATA_LOAD_REQUEST = 'SITE_SUBSCRIPTION_BACKUP_RESTORE_DATA_LOAD_REQUEST',
    SITE_SUBSCRIPTION_BACKUP_RESTORE_DATA_LOAD_SUCCESS = 'SITE_SUBSCRIPTION_BACKUP_RESTORE_DATA_LOAD_SUCCESS',
    SITE_SUBSCRIPTION_BACKUP_RESTORE_DATA_LOAD_FAIL = 'SITE_SUBSCRIPTION_BACKUP_RESTORE_DATA_LOAD_FAIL',

    siteSubscriptionStatusBackupRestoreDataLoadActionTypes: ApiActionTypes = [
        SITE_SUBSCRIPTION_BACKUP_RESTORE_DATA_LOAD_REQUEST,
        SITE_SUBSCRIPTION_BACKUP_RESTORE_DATA_LOAD_SUCCESS,
        SITE_SUBSCRIPTION_BACKUP_RESTORE_DATA_LOAD_FAIL
    ],

    SITE_DATA_PUT_REQUEST = 'SITE_DATA_PUT_REQUEST',
    SITE_DATA_PUT_SUCCESS = 'SITE_DATA_PUT_SUCCESS',
    SITE_DATA_PUT_FAILURE = 'SITE_DATA_PUT_FAILURE',

    SITE_DATA_UPDATE_REQUEST = 'SITE_DATA_UPDATE_REQUEST',
    SITE_DATA_UPDATE_SUCCESS = 'SITE_DATA_UPDATE_SUCCESS',
    SITE_DATA_UPDATE_FAILURE = 'SITE_DATA_UPDATE_FAILURE',
    SITE_DATA_UPDATE_ACTIONS: ApiActionTypes = [
        SITE_DATA_UPDATE_REQUEST,
        SITE_DATA_UPDATE_SUCCESS,
        SITE_DATA_UPDATE_FAILURE
    ],

    SITE_DOCUMENTS_UPDATE_REQUEST = 'SITE_DOCUMENTS_UPDATE_REQUEST',
    SITE_DOCUMENTS_UPDATE_SUCCESS = 'SITE_DOCUMENTS_UPDATE_SUCCESS',
    SITE_DOCUMENTS_UPDATE_FAILURE = 'SITE_DOCUMENTS_UPDATE_FAILURE',

    SITE_SETTINGS_DOCUMENTS_UPDATE_REQUEST = 'SITE_SETTINGS_DOCUMENTS_UPDATE_REQUEST',
    SITE_SETTINGS_DOCUMENTS_UPDATE_SUCCESS = 'SITE_SETTINGS_DOCUMENTS_UPDATE_SUCCESS',
    SITE_SETTINGS_DOCUMENTS_UPDATE_FAILURE = 'SITE_SETTINGS_DOCUMENTS_UPDATE_FAILURE',

    REPOSITORY_SITE_DATA_LOAD = 'REPOSITORY_SITE_DATA_LOAD',

    MOUSE_DOWN_ON_PREVIEW_BUTTON = 'MOUSE_DOWN_ON_PREVIEW_BUTTON',
    MOUSE_UP_ON_PREVIEW_BUTTON = 'MOUSE_UP_ON_PREVIEW_BUTTON',
    SAVE_CLICKED = 'SAVE_CLICKED',
    CTRL_S_PRESSED = 'CTRL_S_PRESSED',
    CTRL_0_PRESSED = 'CTRL_0_PRESSED',
    CTRL_J_PRESSED = 'CTRL_J_PRESSED',
    CTRL_P_PRESSED = 'CTRL_P_PRESSED',
    PUBLISH = 'PUBLISH',
    UPGRADE = 'UPGRADE',
    UPGRADE_COMPLETE = 'UPGRADE_COMPLETE',
    UPGRADE_SUCCESS = 'UPGRADE_SUCCESS',
    UPGRADE_FAILURE = 'UPGRADE_FAILURE',
    SEAMLESS_UPGRADE = 'SEAMLESS_UPGRADE',
    SAVE_AND_PUBLISH_BUTTON_CLICKED = 'SAVE_AND_PUBLISH_BUTTON_CLICKED',
    // SAVE_CLICKED and CTRL_S_PRESSED will trigger SAVE_REQUEST if save is allowed
    SAVE_REQUEST = 'SAVE_REQUEST',
    // save will be fired after sync relations
    SAVE = 'SAVE',
    BACK_TO_EDITOR = 'BACK_TO_EDITOR',
    MOBILE_PREVIEW = 'MOBILE_PREVIEW',
    DESKTOP_PREVIEW = 'DESKTOP_PREVIEW',
    PREVIEW_LOADED = 'PREVIEW_LOADED',
    MOBILE_PREVIEW_ORIENTATION_TOGGLE = 'MOBILE_PREVIEW_ORIENTATION_TOGGLE',

    WINDOW_MOUSE_UP = 'WINDOW_MOUSE_UP',
    WINDOW_MOUSE_MOVE = 'WINDOW_MOUSE_MOVE',

    WINDOW_BLUR = 'WINDOW_BLUR',

    APP_RIGHT_MOUSE_DOWN = 'APP_RIGHT_MOUSE_DOWN', // WBTGEN-6823 Rename to WINDOW_RIGHT_MOUSE_DOWN
    APP_LEFT_MOUSE_DOWN = 'APP_LEFT_MOUSE_DOWN', // WBTGEN-6823 Rename to WINDOW_LEFT_MOUSE_DOWN
    KEY_DOWN = 'KEY_DOWN',
    KEY_PRESS = 'KEY_PRESS',
    KEY_UP = 'KEY_UP',

    ESC_KEY_PRESSED = "ESC_KEY_PRESSED",
    ENTER_KEY_PRESSED = 'ENTER_KEY_PRESSED',
    CTRL_Z_PRESSED = 'CTRL_Z_PRESSED',
    CTRL_SHIFT_Z_PRESSED = 'CTRL_SHIFT_Z_PRESSED',
    CTRL_Y_PRESSED = 'CTRL_Y_PRESSED',
    WINDOW_RESIZED = 'WINDOW_RESIZED',
    WINDOW_RESIZED_DEBOUNCED = 'WINDOW_RESIZED_DEBOUNCED',

    OWNER_INFO_REQUEST = 'OWNER_INFO_REQUEST',
    OWNER_INFO_SUCCESS = 'OWNER_INFO_SUCCESS',
    OWNER_INFO_FAILURE = 'OWNER_INFO_FAILURE',
    ADMIN_ID_AVAILABLE = 'ADMIN_ID_AVAILABLE',
    ADMIN_ID_FETCH_FAILURE = 'ADMIN_ID_FETCH_FAILURE',
    OWNER_INFO_RETRY_REQUEST = 'OWNER_INFO_RETRY_REQUEST',

    REPOSITORY_PAGES_LOAD_REQUEST = 'REPOSITORY_PAGES_LOAD_REQUEST',
    REPOSITORY_PAGES_LOAD_SUCCESS = 'REPOSITORY_PAGES_LOAD_SUCCESS',
    REPOSITORY_PAGES_LOAD_FAILURE = 'REPOSITORY_PAGES_LOAD_FAILURE',

    repositoryPageDataActionTypes: ApiActionTypes = [
        REPOSITORY_PAGES_LOAD_REQUEST,
        REPOSITORY_PAGES_LOAD_SUCCESS,
        REPOSITORY_PAGES_LOAD_FAILURE
    ],

    REPOSITORY_TEMPLATE_DATA_LOAD_REQUEST = 'REPOSITORY_TEMPLATE_DATA_LOAD_REQUEST',
    REPOSITORY_TEMPLATE_DATA_LOAD_SUCCESS = 'REPOSITORY_TEMPLATE_DATA_LOAD_SUCCESS',
    REPOSITORY_TEMPLATE_DATA_LOAD_FAILURE = 'REPOSITORY_TEMPLATE_DATA_LOAD_FAILURE',
    repositoryTemplateDataActionTypes: ApiActionTypes = [
        REPOSITORY_TEMPLATE_DATA_LOAD_REQUEST,
        REPOSITORY_TEMPLATE_DATA_LOAD_SUCCESS,
        REPOSITORY_TEMPLATE_DATA_LOAD_FAILURE
    ],

    SEND_EVENT_AEC = 'SEND_EVENT_AEC',

    LEFT_PANEL_ON_MOUSE_ENTER = 'LEFT_PANEL_ON_MOUSE_ENTER',
    LEFT_PANEL_ON_MOUSE_LEAVE = 'LEFT_PANEL_ON_MOUSE_LEAVE',
    INPUT_FOCUSED = 'INPUT_FOCUSED',
    INPUT_BLUR = 'INPUT_BLUR';

export const
    saveAndPublishButtonClickedAction = () => ({ type: SAVE_AND_PUBLISH_BUTTON_CLICKED });
