import React from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import makeStyles from '@mui/styles/makeStyles';

import { useIntl } from '../../../../../../../../wbtgen/src/view/intl';

import { analyticsStatsWidgetSeeMoreAction } from "../actions";

const useStyles = makeStyles(
    (theme) => ({
        actionLink: {
            padding: "0px",
            fontSize: "14px",
            lineHeight: "22px",
            letterSpacing: "0.25px",
            fontWeight: 500,
            color: theme.palette.custom.colorScienceBlue,
            "&, &:hover": {
                textDecoration: "underline",
                background: "white"
            }
        }
    }),
    { name: "VisitStatsLink" }
);

export const VisitStatsLink = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const classes = useStyles();

    return (
        <Button
            disableElevation
            disableRipple
            variant="text"
            color="primary"
            className={classes.actionLink}
            onClick={() => dispatch(analyticsStatsWidgetSeeMoreAction())}
        >
            {intl.msgJoint("msg: common.seeMoreStatistics {See more statistics}")}
        </Button>
    );
};

