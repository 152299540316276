import React from "react";
import { connect } from 'react-redux';
import { Border } from "../presentational/Border/Border";

import type { Handles, Handle } from '../../redux/modules/children/workspace/flowTypes';
import type { AppState } from "../../redux/modules/flowTypes";

type Props = {
    handles: Handles,
    topMostHandle: Handle
}

const HandlesOverlay = ({ handles, topMostHandle }: Props) => {
    const
        handlesOverlays = handles.map((handle, index) => (
            <Border
                key={index}
                bBox={handle.bBox}
                color={handle === topMostHandle ? "red" : "green"}
                animate={false}
                width={1}
                opacity={1}
                makeInvisible={false}
            />
        ));

    return (
        <div>
            {handlesOverlays}
        </div>
    );
};

const mapStateToProps = ({ selectedWorkspace: { topMostHandle, handles } }: AppState) => ({ topMostHandle, handles });

export default connect(mapStateToProps)(HandlesOverlay);
