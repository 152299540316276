import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    paper: {
        width: 576,
        [theme.breakpoints.down('md')]: {
            position: "absolute",
            bottom: 0,
            width: "100%",
            maxWidth: "100%",
            margin: 0,
            padding: 0,
        },
    },
    dialogTitle: {
        fontSize: "30px",
        lineHeight: "34px",
    },
    dialogSubTitle: {
        fontSize: "18px",
        lineHeight: "26px",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        [theme.breakpoints.down('md')]: {
            justifyContent: "flex-start",
        }

    },
    button: {
        height: 50,
        padding: "0 30px",
        [theme.breakpoints.down('md')]: {
            width: "50%",
        }
    }
}), { name: "FacebookChatMultiplePagesDialog" });
