import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from './reducers/componentChange';
import type { ImageComponent } from './flowTypes';

const imageAdjustComponentOnAdd = (component: ImageComponent): ImageComponent => {
    const { asset } = component;
    let scale = 1.0,
        width = asset.width || DEFAULT_WIDTH, // TODO validate on metadata fetch
        height = asset.height || DEFAULT_HEIGHT; // TODO validate on metadata fetch

    // Reduce size of asset keeping aspect ratio
    if (width > DEFAULT_WIDTH || height > DEFAULT_HEIGHT) {
        if (width / DEFAULT_WIDTH > height / DEFAULT_HEIGHT) {
            // comply width
            scale = DEFAULT_WIDTH / width;
            width = DEFAULT_WIDTH;
            height = height * scale;
        } else {
            // comply height
            scale = DEFAULT_HEIGHT / height;
            width = width * scale;
            height = DEFAULT_HEIGHT;
        }
    }

    // Check if the dimensions are >= 1 and <= limit
    width = Math.min(Math.max(Math.round(width), 1), DEFAULT_WIDTH);
    height = Math.min(Math.max(Math.round(height), 1), DEFAULT_HEIGHT);

    return {
        ...component,
        scale,
        height,
        width,
        lightBoxEnabled: false,
        openLink: true
    };
};

export {
    imageAdjustComponentOnAdd as default,
    imageAdjustComponentOnAdd
};

