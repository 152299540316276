import * as React from "react";
import { Typography, Grid, Paper } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";

import { useIntl } from "../../../../../../../wbtgen/src/view/intl/injectIntl";
import VerticalSpacer from "../../../../../../../wbtgen/src/view/common/VerticalSpacer";
import { getEcommercePackageNameFromMetadata } from "../../../../../../../wbtgen/src/components/ComponentTierManager/utils";

import imageStyles from '../../../Images/imageStyle.css';
import { selectSubscriptionData } from "../../../../common/selectors";

export const useStyles = makeStyles(
    (theme: Theme) => ({
        container: {
            justifyContent: "center",
            marginTop: "12px",
            [theme.breakpoints.up('sm')]: {
                marginTop: "42px",
                justifyContent: "space-between",
            },
            [theme.breakpoints.up('md')]: {
                marginTop: "50px"
            },
        },
        title: {
            fontSize: "18px",
            lineHeight: "28px"
        },
        description: {
            fontSize: "14px",
            lineHeight: "22px"
        },
        paper: {
            width: "100%",
            border: `1px solid ${theme.palette.custom.colorGray_e2}`,
            padding: "30px 24px",
            borderRadius: "0px",
            [theme.breakpoints.up('sm')]: {
                padding: "30px 26px"
            },
        },
        item: {
            display: "flex",
            [theme.breakpoints.up('sm')]: {
                flex: 1,
                maxWidth: "35%",
                paddingTop: "0px !important",
            },
        }
    }),
    { name: "SeoScanTiles" }
);

export default function SpacingGrid() {
    const classes = useStyles();
    const intl = useIntl();
    const subscriptionData = useSelector(selectSubscriptionData);
    const subscriptionMetadata = subscriptionData?.metadata;
    const eCommercePackageNameFromMetadata = getEcommercePackageNameFromMetadata(subscriptionMetadata);
    const packageName = eCommercePackageNameFromMetadata || intl.msgJoint("msg: common.business_ecommerce {Business + Ecommerce}");

    const TILES_DATA: {
        iconClass: string,
        title: string,
        description: MsgJointInput
    }[] = [
        {
            iconClass: "siteVisitor",
            title: "msg: seoScan.tiles.getScore.title {Get your site’s SEO score}",
            description: "msg: seoScan.tiles.getScore.description {We scan your website and provide you with a SEO score that indicates how well it ranks in search engine results.}"
        },
        {
            iconClass: "increaseUsers",
            title: "msg: seoScan.tiles.monitorPerformance.title {Monitor your SEO performance}",
            description: "msg: seoScan.tiles.monitorPerformance.description {We rescan your site regularly to update your SEO score and show if it has improved, stayed the same or decreased since the last scan. You can run a rescan of your site any time.}"
        },
        {
            iconClass: "conversion",
            title: "msg: seoScan.tiles.improveScore.title {We help you improve your score}",
            description: ["msg: seoScan.tiles.improveScore.description_new {With our {packageName}, we provide you with a more detailed scan of your site and a list of specific actions that you can take on each page of your website to improve your SEO score.}", { packageName }]
        }
    ];

    return (
        <Grid container spacing={3} className={classes.container}>
            {TILES_DATA.map((tile, index) => (
                <Grid key={index} item className={classes.item}>
                    <Paper elevation={0} className={classes.paper}>
                        <div className={imageStyles[tile.iconClass]} />
                        <VerticalSpacer y={24} />
                        <Typography className={classes.title}>
                            {intl.msgJoint(tile.title)}
                        </Typography>
                        <VerticalSpacer y={8} />
                        <Typography className={classes.description}>
                            {intl.msgJoint(tile.description)}
                        </Typography>
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
}
