import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import styles from '../FileChooserList.css';
import injectIntl from "../../../../intl/injectIntl";
import { SortDirections } from "../../../../../redux/modules/children/fileChooser/sortFunctions";
import { FC_SORT } from "../../../../../redux/modules/children/fileChooser/actionTypes";
import type { AppState } from "../../../../../redux/modules/flowTypes";

const SortDirectionCls = {
    [SortDirections.ASC]: styles.sortAsc,
    [SortDirections.DESC]: styles.sortDesc
};

type Props = { label, column, sortDirection, sortColumn, applySort, intl: Intl }

const ColumnHeader = injectIntl(({ label, column, sortDirection, sortColumn, applySort, intl }: Props) => {
    return (
        <div onClick={() => applySort(column)} className={cx(styles.headerContainer, styles[column])}>
            <span className={styles.headerLabel}>
                {intl.msgJoint(label)}
            </span>
            <span
                className={
                    cx(
                        styles.sortIcn,
                        SortDirectionCls[sortDirection],
                        { [styles.sortIcnHidden]: sortColumn !== column }
                    )
                }
            />
        </div>
    );
});

const mapStateToProps = ({ fileChooser: { sort } }: AppState) => ({
    sortDirection: sort.columns[sort.currentColumn],
    sortColumn: sort.currentColumn
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    applySort: column => dispatch({ type: FC_SORT, payload: { column } })
});

export default connect(mapStateToProps, mapDispatchToProps)(ColumnHeader);
