import type { AddOnecomTemplateState } from "../types";
import { Lit } from "../../../../lit";
import { makePayloadPathReducer, makeValuePathReducer, makeValueReducer } from "../../../../redux/makeReducer";
import { AddOnecomTemplatePath as P } from "../paths";
import {
    ADD_ONECOM_TEMPLATES_FAILURE_ACTION,
    ADD_ONECOM_TEMPLATES_REQUEST_ACTION,
    ADD_ONECOM_TEMPLATES_SUCCESS_ACTION,
    SELECT_ONECOM_TEMPLATE_ACTION,
    SHOW_ONECOM_TEMPLATE_PREVIEW_ACTION,
    HIDE_ONECOM_TEMPLATE_PREVIEW_ACTION
} from "../actions";

export const AddOnecomTemplateInitialState = {
    [Lit.isLoading]: false,
    [Lit.onHold]: false,
    [Lit.templates]: [],
    [Lit.selectedId]: null,
    [Lit.showPreviewId]: null
};

export const addOnecomTemplateReducer = makeValueReducer({
    defaultState: AddOnecomTemplateInitialState,
    handleActions: {
        [ADD_ONECOM_TEMPLATES_REQUEST_ACTION]: makeValuePathReducer(true, P.isLoading),
        [ADD_ONECOM_TEMPLATES_SUCCESS_ACTION]: (state: AddOnecomTemplateState, action: Record<string, any>) => {
            const
                { payload: templates } = action,
                nextTemplates = templates.filter(t => t.categories && t.categories.length > 0);

            return { ...state, [Lit.isLoading]: false, [Lit.templates]: nextTemplates };
        },
        [ADD_ONECOM_TEMPLATES_FAILURE_ACTION]: makeValuePathReducer(false, P.isLoading),
        [SELECT_ONECOM_TEMPLATE_ACTION]: makePayloadPathReducer(P.selectedId),
        [SHOW_ONECOM_TEMPLATE_PREVIEW_ACTION]: makePayloadPathReducer(P.showPreviewId),
        [HIDE_ONECOM_TEMPLATE_PREVIEW_ACTION]: makeValuePathReducer(null, P.showPreviewId)
    }
});
