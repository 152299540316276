import { FacebookChatAlreadyExist } from './constants/index';
import type { validateComponentOnAddType } from '../../../redux/modules/children/workspace/flowTypes';

export default (dataToValidate: validateComponentOnAddType) => {
    const { siteSettings } = dataToValidate;
    if (siteSettings) {
        const currentSiteSettings = siteSettings.current;
        if (Object.keys(currentSiteSettings).some(key => currentSiteSettings[key].enableChatWidget)) {
            return FacebookChatAlreadyExist;
        }
    }
    return null;
};
