import { memoMax } from '../../../../../utils/memo';
import * as colorMapper from '../../../../mappers/color';
import { MaxComponentsOnPage } from '../../../../constants/app';

export const makeThemeHighlightAndShadowStyles = memoMax((
    themeColorsData,
    themeHighlightColor,
    blurRadius,
    themeShadowColor,
    offsetX,
    offsetY
) => {
    return {
        ...(themeHighlightColor ? { backgroundColor: colorMapper.toCss(themeColorsData[themeHighlightColor]) } : {}),
        ...(themeShadowColor ? {
            textShadow: `${colorMapper.toCss(themeColorsData[themeShadowColor])} ${offsetX || 3}px ${offsetY || 3}px ${blurRadius || 3}px`
        } : {})
    };
}, MaxComponentsOnPage);
