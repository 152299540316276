import React from 'react';
import { Flex } from '../../../../../view/reflexbox/index';
import type {
    InstancePropertiesPanelProps,
    InstancePropertiesPanelRegistryPage
} from "../../../../PropertiesPanel/instance/types";
import type { MobileHeaderState } from "../../../../Mobile/header/types";
import { MveHeaderPpPageId } from "./MveHeaderPpPageId";
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import ColorProperty from '../../../../presentational/ColorProperty/index';
import OpacitySlider from '../../../../presentational/OpacitySlider/index';
import * as actions from '../actions';
import { opacityToAlpha, getColorOpacity } from '../../../../oneweb/Background/utils/index';
import styles from "../MveHeaderProperteisPanel.css";
import Tabs, { Tab } from "../../../../presentational/tabs/Tabs";
import TextFormatButtonGroup from "../../../../presentational/IconButtonsGroup/TextFormat";
import { PropertiesPanelFontFamilySelector } from '../../../../PropertiesPanel/view/components/PropertiesPanelFontFamilySelector';
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';
import { PropertiesPanelTextSection } from '../../../../PropertiesPanel/view/components/Section/PropertiesPanelTextSection';
import Slider from '../../../../../view/common/Slider/PPSlider';
import { DIRECTIONBACKWARD } from '../../../../PropertiesPanel/epic/constants';
import { THEME_COLOR_OVERRIDE_COLOR_PICKER } from '../../../../ColorPicker/constants';

export const ClosedTab = 'Closed';
export const OpenTab = 'Open';
export const SelectedTab = 'SelectedTab';

const getBrightColor = (color) => {
    return color && [color[0], color[1], color[2], color[3], 1];
};

class MenuStyleMvePpPageClass extends React.Component<InstancePropertiesPanelProps> {
    onClosedOpacityChange = value =>
        this.props.dispatch(actions.changeMveMenuStyleClosedOpacityAction(opacityToAlpha(value)));

    onOpenOpacityChange = value =>
        this.props.dispatch(actions.changeMveMenuStyleOpenOpacityAction(opacityToAlpha(value)));

    onSelectedBgOpacityChange = value =>
        this.props.dispatch(actions.changeMveMenuStyleSelectedBgOpacityAction(opacityToAlpha(value)));

    onTabClick = (id) => this.props.dispatch(actions.mveHeaderPPMenuStylePageTabClickedAC(id));

    render() {
        const
            {
                menu: {
                    closed: {
                        iconThemeColor: themeClosedIconColor,
                        iconColor: closedColor
                    },
                    open: {
                        iconThemeColor: themeOpenIconColor,
                        iconColor: openColor,
                        fontFamily,
                        bold: openBold,
                        italic: openItalic,
                        underline: openUnderline,
                        fontSize: openFontSize,
                        lineHeight: openLineHeight,
                        themeColor,
                        color: openTextColor,
                        backgroundThemeColor,
                        backgroundColor: openBgColor
                    },
                    selected: {
                        bold: selectedBold,
                        italic: selectedItalic,
                        underline: selectedUnderline,
                        themeColor: selectedItemThemeColor,
                        color: selectedTextColor,
                        backgroundThemeColor: selectedItemBackgroundThemeColor,
                        backgroundColor: selectedBgColor
                    }
                }
            } =
                this.props.state.instance?.state as MobileHeaderState,
            { dispatch } = this.props,
            activeTab = this.props.state.navigation.direction === DIRECTIONBACKWARD ? OpenTab : ClosedTab,
            iconClosedColor = getBrightColor(closedColor),
            iconClosedOpacity = closedColor ? getColorOpacity(closedColor) : 100,
            iconOpenColor = getBrightColor(openColor),
            iconOpenOpacity = openColor ? getColorOpacity(openColor) : 100,
            selectedBgBrightColor = getBrightColor(selectedBgColor),
            selectedBgOpacity = selectedBgColor ? getColorOpacity(selectedBgColor) : 100;

        return <Tabs className={styles.tabs} onTabClick={this.onTabClick} activeTabKey={activeTab}>
            <Tab
                id={ClosedTab}
                label="msg: component.menu.mve.pp.closed {Closed}"
                className={styles.closedTab}
            >
                <PropertiesPanelTextSection>{
                    `msg: component.menu.mve.pp.closedDescription {Customize the look of the icon for the closed menu.}` // eslint-disable-line
                }</PropertiesPanelTextSection>
                {
                    this.props.state.instance?.state.autoColorMode
                        ? <ColorProperty
                            colorPickerKind={THEME_COLOR_OVERRIDE_COLOR_PICKER}
                            themeColorsData={this.props.state.instance.state.themeColorsData}
                            themeColor={themeClosedIconColor}
                            label="msg: common.iconColor {Icon colour}"
                            onChangeAction={actions.CHANGE_MVE_MENU_STYLE_CLOSED_ICON_COLOR_ACTION_AUTO_COLOR}
                            dispatch={dispatch}
                        />
                        : <ColorProperty
                            label="msg: common.iconColor {Icon colour}"
                            color={iconClosedColor}
                            onChangeAction={actions.CHANGE_MVE_MENU_STYLE_CLOSED_ICON_COLOR_ACTION}
                            dispatch={dispatch}
                        />
                }
                <VerticalSpacer y={13} />
                <OpacitySlider
                    label="msg: common.iconOpacity {Icon opacity}"
                    opacity={iconClosedOpacity}
                    onChange={this.onClosedOpacityChange}
                    onAfterChange={this.onClosedOpacityChange}
                />
            </Tab>
            <Tab
                id={OpenTab}
                label="msg: component.menu.mve.pp.open {Open}"
                className={styles.openTab}
            >
                <PropertiesPanelTextSection>{
                    `msg: component.menu.mve.pp.openDescription {Customize the look of the open menu box and icon.}` // eslint-disable-line
                }</PropertiesPanelTextSection>
                {
                    this.props.state.instance?.state.autoColorMode
                        ? <ColorProperty
                            colorPickerKind={THEME_COLOR_OVERRIDE_COLOR_PICKER}
                            themeColorsData={this.props.state.instance.state.themeColorsData}
                            themeColor={themeOpenIconColor}
                            label="msg: common.iconColor {Icon colour}"
                            onChangeAction={actions.CHANGE_MVE_MENU_STYLE_OPEN_ICON_COLOR_ACTION_AUTO_COLOR}
                            dispatch={dispatch}
                        />
                        : <ColorProperty
                            label="msg: common.iconColor {Icon colour}"
                            color={iconOpenColor}
                            onChangeAction={actions.CHANGE_MVE_MENU_STYLE_OPEN_ICON_COLOR_ACTION}
                            dispatch={dispatch}
                        />
                }
                <VerticalSpacer y={13} />
                <OpacitySlider
                    label="msg: common.iconOpacity {Icon opacity}"
                    opacity={iconOpenOpacity}
                    onChange={this.onOpenOpacityChange}
                    onAfterChange={this.onOpenOpacityChange}
                />
                <VerticalSpacer y={13} />
                <PropertyContainer label="msg: common.font {Font}">
                    <PropertiesPanelFontFamilySelector
                        dispatch={dispatch}
                        selected={fontFamily}
                        onChangeAction={actions.CHANGE_MVE_MENU_STYLE_OPEN_FONTFAMILY_ACTION}
                        isPropertiesPanel
                    />
                </PropertyContainer>
                <VerticalSpacer y={13} />
                <Flex align="center" justify="space-between">
                    <TextFormatButtonGroup
                        bold={{ isSelected: openBold,
                            onClickAction: actions.TOGGLE_MVE_MENU_STYLE_OPEN_BOLD_ACTION }}
                        italic={{ isSelected: openItalic, onClickAction: actions.TOGGLE_MVE_MENU_STYLE_OPEN_ITALIC_ACTION }}
                        underline={{ isSelected: openUnderline,
                            onClickAction: actions.TOGGLE_MVE_MENU_STYLE_OPEN_UNDERLINE_ACTION }}
                        dispatch={dispatch}
                    />
                </Flex>
                <VerticalSpacer y={13} />
                <Slider
                    label="msg: common.fontSize {Font size}"
                    valueLabel={`${openFontSize} px`}
                    value={openFontSize}
                    min={6}
                    max={100}
                    step={1}
                    onChange={size => dispatch({
                        type: actions.TOGGLE_MVE_MENU_STYLE_OPEN_FONTSIZE_ACTION,
                        payload: size,
                    })}
                />
                <VerticalSpacer y={13} />
                <Slider
                    label="msg: common.lineSpacing {Line spacing}"
                    valueLabel={`${openLineHeight}`}
                    value={openLineHeight}
                    min={1}
                    max={4}
                    step={0.1}
                    onChange={spacing => dispatch({
                        type: actions.TOGGLE_MVE_MENU_STYLE_OPEN_LINEHEIGHT_ACTION,
                        payload: spacing,
                    })}
                />
                <VerticalSpacer y={13} />
                {
                    this.props.state.instance?.state.autoColorMode
                        ? <ColorProperty
                            colorPickerKind={THEME_COLOR_OVERRIDE_COLOR_PICKER}
                            themeColorsData={this.props.state.instance.state.themeColorsData}
                            themeColor={themeColor}
                            label="msg: common.textColor {Text colour}"
                            onChangeAction={actions.CHANGE_MVE_MENU_STYLE_OPEN_TEXT_COLOR_ACTION_AUTO_COLOR}
                            dispatch={dispatch}
                        />
                        : <ColorProperty
                            label="msg: common.textColor {Text colour}"
                            color={openTextColor}
                            onChangeAction={actions.CHANGE_MVE_MENU_STYLE_OPEN_TEXT_COLOR_ACTION}
                            dispatch={dispatch}
                        />
                }
                <VerticalSpacer y={13} />
                {
                    this.props.state.instance?.state.autoColorMode
                        ? <ColorProperty
                            colorPickerKind={THEME_COLOR_OVERRIDE_COLOR_PICKER}
                            themeColorsData={this.props.state.instance.state.themeColorsData}
                            themeColor={backgroundThemeColor}
                            label="msg: common.backgroundColor {Background colour}"
                            onChangeAction={actions.CHANGE_MVE_MENU_STYLE_OPEN_BG_COLOR_ACTION_AUTO_COLOR}
                            dispatch={dispatch}
                        />
                        : <ColorProperty
                            label="msg: common.backgroundColor {Background colour}"
                            color={openBgColor}
                            onChangeAction={actions.CHANGE_MVE_MENU_STYLE_OPEN_BG_COLOR_ACTION}
                            dispatch={dispatch}
                        />
                }
            </Tab>
            <Tab
                id={SelectedTab}
                label="msg: component.menu.mve.pp.selected {Selected}"
                className={styles.selectedTab}
            >
                <PropertiesPanelTextSection>{
                    `msg: component.menu.mve.pp.selectedDescription {Customize the look of the selected page in the open menu.}` // eslint-disable-line
                }</PropertiesPanelTextSection>
                <Flex align="center" justify="flex-start">
                    <TextFormatButtonGroup
                        bold={{ isSelected: selectedBold,
                            onClickAction: actions.TOGGLE_MVE_MENU_STYLE_SELECTED_BOLD_ACTION }}
                        italic={{ isSelected: selectedItalic,
                            onClickAction: actions.TOGGLE_MVE_MENU_STYLE_SELECTED_ITALIC_ACTION }}
                        underline={{ isSelected: selectedUnderline,
                            onClickAction: actions.TOGGLE_MVE_MENU_STYLE_SELECTED_UNDERLINE_ACTION }}
                        dispatch={dispatch}
                    />
                </Flex>
                <VerticalSpacer y={13} />
                {
                    this.props.state.instance?.state.autoColorMode
                        ? <ColorProperty
                            colorPickerKind={THEME_COLOR_OVERRIDE_COLOR_PICKER}
                            themeColorsData={this.props.state.instance.state.themeColorsData}
                            themeColor={selectedItemThemeColor}
                            label="msg: common.textColor {Text colour}"
                            onChangeAction={actions.CHANGE_MVE_MENU_STYLE_SELECTED_TEXT_COLOR_ACTION_AUTO_COLOR}
                            dispatch={dispatch}
                        />
                        : <ColorProperty
                            label="msg: common.textColor {Text colour}"
                            color={selectedTextColor}
                            onChangeAction={actions.CHANGE_MVE_MENU_STYLE_SELECTED_TEXT_COLOR_ACTION}
                            dispatch={dispatch}
                        />
                }
                <VerticalSpacer y={13} />
                {
                    this.props.state.instance?.state.autoColorMode
                        ? <ColorProperty
                            colorPickerKind={THEME_COLOR_OVERRIDE_COLOR_PICKER}
                            themeColorsData={this.props.state.instance.state.themeColorsData}
                            themeColor={selectedItemBackgroundThemeColor}
                            label="msg: common.backgroundColor {Background colour}"
                            onChangeAction={actions.CHANGE_MVE_MENU_STYLE_SELECTED_BG_COLOR_ACTION_AUTO_COLOR}
                            dispatch={dispatch}
                        />
                        : <ColorProperty
                            label="msg: common.backgroundColor {Background colour}"
                            color={selectedBgBrightColor}
                            onChangeAction={actions.CHANGE_MVE_MENU_STYLE_SELECTED_BG_COLOR_ACTION}
                            dispatch={dispatch}
                        />
                }
                <VerticalSpacer y={13} />
                <OpacitySlider
                    label="msg: common.backgroundOpacity {Background opacity}"
                    opacity={selectedBgOpacity}
                    onChange={this.onSelectedBgOpacityChange}
                    onAfterChange={this.onSelectedBgOpacityChange}
                />
            </Tab>
        </Tabs>;
    }
}

export const MenuStyleMveHeaderPpPage: InstancePropertiesPanelRegistryPage = {
    id: MveHeaderPpPageId.MENU_STYLE,
    title: 'msg: common.menuStyle {Menu style}',
    View: MenuStyleMvePpPageClass,
};
