import { FM_DIALOG_MAX_HEIGHT, FM_DIALOG_MIN_HEIGHT, FM_DIALOG_WIDTH } from "./constants";
import {
    getCenterPositionForDialog,
    getDialogHeightWithRespectToBrowserHeight,
    makeUpdateDialogHeightOnBrowserDimensionsChanged,
} from "../../DialogManager/utility";
import type { BrowserDimensions } from "../../../redux/modules/children/dimensions/index";
import { FileManagerDialogCom } from "./components/FileManagerDialogCom";

const confFactory = ({ browserWidth, browserHeight }: BrowserDimensions) => {
    const height = getDialogHeightWithRespectToBrowserHeight({
        browserHeight,
        minHeight: FM_DIALOG_MIN_HEIGHT,
        maxHeight: FM_DIALOG_MAX_HEIGHT,
    });

    return {
        position: getCenterPositionForDialog(FM_DIALOG_WIDTH, height, browserWidth, browserHeight),
        modal: true,
        dimensions: { width: FM_DIALOG_WIDTH, height },
    };
};

export const fileManageDialog = {
    confFactory,
    updateOnBrowserDimensionsChanged: makeUpdateDialogHeightOnBrowserDimensionsChanged({
        minHeight: FM_DIALOG_MIN_HEIGHT,
        maxHeight: FM_DIALOG_MAX_HEIGHT
    }),
    component: FileManagerDialogCom,
};
