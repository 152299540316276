import React from "react";
import { useDispatch } from "react-redux";

import { ErrorModel, Props } from "./ErrorModel";

import { openDialog } from "../../../../App/actionCreators";
import { CONTINUE_TO_TEMPLATES_DIALOG } from "./ContinueToTemplatesDialog";
import { DYNAMIC_TEMPLATE_RESET_ERROR_TYPE } from "../../Epic/actionTypes";

import styles from "./ErrorModel.css";

export const LimitError = () => {
    const dispatch = useDispatch();

    let props: Props = {
        imageClass: styles.limitErrorIcon,
        // eslint-disable-next-line max-len
        description: "msg: onboarding.dynamic.dailyLimitError.description {You’ve reached your maximum usage limit for today. Please try again tomorrow or create your website with one of our templates instead.}",
        primaryButtonText: "msg: common.back {Back}",
        primaryButtonAction: () => {
            dispatch({ type: DYNAMIC_TEMPLATE_RESET_ERROR_TYPE });
        },
        secondaryButtonText: "msg: common.browseTemplates {Browse templates}",
        secondaryButtonAction: () => {
            dispatch(openDialog(CONTINUE_TO_TEMPLATES_DIALOG));
        }
    };

    return (
        <ErrorModel {...props} />
    );
};
