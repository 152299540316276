import { DataSite, DataPageRef } from "../../../../../dal/model";
import applyPatchToSitePage from "./applyPatchToSitePage";

export default (site: DataSite, parentPageRefId: string, patch: Record<string, any>) => {
    site.forEachPageRefInSubTree(parentPageRefId, (page: DataPageRef) => {
        let finalPatch = { ...patch };
        if (finalPatch.public !== undefined) delete finalPatch.public;
        if (!finalPatch.hidden && !page.public) delete finalPatch.hidden;

        applyPatchToSitePage(page, finalPatch);
    });
};
