import type { AnyComponent, BBox } from "../../redux/modules/children/workspace/flowTypes";
import _makeBBox from './makeBBoxMemoized';
import isStretchComponentKind from '../../components/oneweb/isStretchComponentKind';

export default (component: AnyComponent, workspaceBBox: BBox) => {
    if (isStretchComponentKind(component.kind, component.stretch)) {
        const
            { left, right } = workspaceBBox,
            { top, height } = component,
            width = right - left;

        return _makeBBox(left, top, width, height);
    } else {
        const { left, top, width, height } = component;
        return _makeBBox(left, top, width, height);
    }
};
