import * as R from 'ramda';
import type { TinyMceEpicScope } from "../flowTypes";

const
    tinyMceEpicDefaultScope: TinyMceEpicScope = {
        isTextColorPickerOpen: false,
        isTextShadowColorPickerOpen: false,
        isHighlightColorPickerOpen: false,
        bookmark: null,
        tinyMceExist: false,
        handleEditorChangeActionType: null,
        processingMultipleEditors: false,
        // @ts-ignore
        stylesheets: {},
        autoColorMode: false,
        site: null,
        lastEditModeComponentId: null,
        multiProcessingInProgress: false,
        multiProcessingContentsArr: [],
        multiProcessingUpdatedContentsArr: [],
        multiProcessingActionCmd: null,
        currentMultiProcessingContentIndex: -1
    },

    setMultiProcessingInProgress = R.assoc('multiProcessingInProgress'),
    setMultiProcessingContentsArr = R.assoc('multiProcessingContentsArr'),
    setMultiProcessingUpdatedContentsArr = R.assoc('multiProcessingUpdatedContentsArr'),
    setMultiProcessingActionCmd = R.assoc('multiProcessingActionCmd'),
    updateCurrentMultiProcessingContentIndex = R.curry((updater, scope) =>
        R.evolve({ currentMultiProcessingContentIndex: updater }, scope)),
    setCurrentMultiProcessingContentIndex = R.curry((value: number, scope: TinyMceEpicScope) =>
        updateCurrentMultiProcessingContentIndex(() => value, scope)),
    incCurrentMultiProcessingContentIndex = updateCurrentMultiProcessingContentIndex(R.inc),
    setMultiProcessingUpdatedContent = R.curry((content, scope) =>
        R.assocPath(['multiProcessingUpdatedContentsArr', scope.currentMultiProcessingContentIndex], content, scope));

export {
    tinyMceEpicDefaultScope,
    setMultiProcessingInProgress,
    setMultiProcessingContentsArr,
    setMultiProcessingUpdatedContentsArr,
    setMultiProcessingActionCmd,
    setCurrentMultiProcessingContentIndex,
    incCurrentMultiProcessingContentIndex,
    setMultiProcessingUpdatedContent
};
