// Truncate function that preserves HTML tags
export const getTruncatedContent = (str, length) => {
    const div = document.createElement('div');
    div.innerHTML = str;
    let truncated = '';

    const nodes = div.childNodes;
    let count = 0;

    const truncateNode = (node) => {
        if (node.nodeType === 3) { // Text node
            const text = node.nodeValue;
            for (let i = 0; i < text.length; i++) {
                if (count < length) {
                    truncated += text[i];
                    count += (text[i] === '\n') ? 2 : 1; // Account for new line characters
                } else {
                    break;
                }
            }
        } else if (node.nodeType === 1) { // Element node
            truncated += `<${node.nodeName.toLowerCase()}>`;

            const childNodes = node.childNodes;
            for (let j = 0; j < childNodes.length; j++) {
                truncateNode(childNodes[j]);
                if (count >= length) break;
            }
            truncated += `</${node.nodeName.toLowerCase()}>`;
        }
    };

    for (let i = 0; i < nodes.length; i++) {
        truncateNode(nodes[i]);
        if (count >= length) break;
    }

    return truncated;
};

export const formatNumberWithCommas = (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
