import type { InputState, InputValidationErrorCollection } from "../flowTypes";

// TODO: use inputDefaultState
const Defaults = {
    isValid: true,
    errors: [],
    isTouched: false
};

type Params = {
    value: string,
    isValid?: boolean,
    errors?: InputValidationErrorCollection,
    isTouched?: boolean
};

export const makeInputDefaultState = (params: Params): InputState => ({
    ...Defaults,
    ...params
});
