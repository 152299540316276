import type { PageInfoDialogState } from "../../flowTypes";
import { DataPageTemplateRef } from "../../../../../../../../dal/model";
import { PageInfoTab } from "../../../../constants";

export const saveTemplateSelectionReducer = (state: PageInfoDialogState, action: AnyAction) => {
    const
        { [PageInfoTab.TEMPLATE]: { templates }, currentTemplateId } = state,
        { payload: nextSelectedTemplateId } = action,
        nextTemplateRef: DataPageTemplateRef | null | undefined = templates.find(t => t.id === nextSelectedTemplateId);

    if (!nextTemplateRef) {
        throw new Error(`Cannot find nextTemplateRef by id: ${nextSelectedTemplateId}`);
    }

    return {
        ...state,
        [PageInfoTab.TEMPLATE]: {
            ...state[PageInfoTab.TEMPLATE],
            selectedTemplate: {
                id: nextTemplateRef.id,
                name: nextTemplateRef.name
            },
            templateIsChanged: currentTemplateId !== nextSelectedTemplateId
        }
    };
};
