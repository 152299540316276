import React from 'react';
import cx from 'classnames';
import { connect } from "react-redux";
import { Table, Column } from 'fixed-data-table-one.com';
import styles from './FileChooserList.css';

import 'fixed-data-table-one.com/dist/fixed-data-table.css';
import { ColumnHeader, CheckBoxCell, IconCell, DataCell, NameCell, DeleteCell } from './tableComponents/index';
import { getListTableWidth, getListColumnWidths, isResourceSelected } from '../utils';
import type { FileChooserListPropTypes } from '../flowTypes';
import { SortColumns } from "../../../../redux/modules/children/fileChooser/sortFunctions";
import CheckBoxHeader from './tableComponents/CheckBoxHeader';
import {
    resourcesSelector,
    lastSelectedResourceIndexSelector
} from "../../../../redux/modules/children/fileChooser/selectors";
import getStyleIntValue from "../../../../utils/getStyleIntValue";
import type { AppState } from "../../../../redux/modules/flowTypes";
import EmptyFolderMsg from '../FileChooserBody/EmptyFolderMsg';
import { ProgressBar } from '../ProgressBar';

const dataCellColumnGetterMap = {
    [SortColumns.NAME]: 'name',
    [SortColumns.TYPE]: 'renderContentType',
    [SortColumns.SIZE]: 'size',
    [SortColumns.ADDED]: 'created'
};

const styleIntValue = varName => getStyleIntValue(styles, varName);

type State = {
    scrollTo: null | undefined | number
};

class FileChooserList extends React.Component<FileChooserListPropTypes, State> {
    constructor() {
        // @ts-ignore
        super();
        this.state = { scrollTo: null };
    }

    _getCellProps(rowIndex) {
        const { resources, selection, onResourceDoubleClick } = this.props;
        return {
            resource: resources[rowIndex],
            isSelected: isResourceSelected(resources[rowIndex], selection),
            onDbClick: onResourceDoubleClick
        };
    }

    _getDataCellProps(rowIndex, column) {
        return { ...this._getCellProps(rowIndex), getter: dataCellColumnGetterMap[column], column };
    }

    _getListWidth() {
        return this.props.width || getListTableWidth(this.props.showPreview);
    }

    _setSrollTo(props) {
        this.setState({ scrollTo: props.lastSelectedResourceIndex });
    }

    componentDidMount() {
        this._setSrollTo(this.props);
    }

    renderTable() {
        const
            {
                tableHeight,
                isMultiSelect,
                resources,
                selection
            } = this.props,
            tableWidth = this._getListWidth();

        const columnWidths = getListColumnWidths(tableWidth);

        return (
            <Table
                width={tableWidth}
                height={tableHeight}
                rowHeight={styleIntValue('rowHeight')}
                headerHeight={styleIntValue('headerHeight')}
                rowsCount={resources.length}
                scrollToRow={this.state.scrollTo}
                rowLiner={({ rowIndex }) => (
                    <ProgressBar show={resources[rowIndex].isGhost && resources[rowIndex].isLoading} />
                )}
                showScrollbarX={false}
                rowClassNameGetter={
                    rowIndex => (isResourceSelected(resources[rowIndex], selection) ? 'selectedRow' : null)
                }
            >
                {isMultiSelect ? <Column
                    header={<CheckBoxHeader />}
                    cell={({ rowIndex }) => <CheckBoxCell {...this._getCellProps(rowIndex)} />}
                    width={columnWidths.checkbox}
                /> : null}
                <Column
                    header={null}
                    cell={({ rowIndex }) => <IconCell {...this._getCellProps(rowIndex)} />}
                    width={columnWidths.icon}
                    flexGrow={1}
                />
                <Column
                    header={<ColumnHeader label="msg: common.name {Name}" column={SortColumns.NAME} />}
                    cell={({ rowIndex }) => <NameCell {...this._getCellProps(rowIndex)} />}
                    width={columnWidths[SortColumns.NAME]}
                    flexGrow={5.3}
                />
                <Column
                    header={<ColumnHeader label="msg: common.type {Type}" column={SortColumns.TYPE} />}
                    cell={({ rowIndex }) => <DataCell {...this._getDataCellProps(rowIndex, SortColumns.TYPE)} />}
                    width={columnWidths[SortColumns.TYPE]}
                    flexGrow={2}
                />
                <Column
                    header={<ColumnHeader label="msg: common.size {Size}" column={SortColumns.SIZE} />}
                    cell={({ rowIndex }) => <DataCell {...this._getDataCellProps(rowIndex, SortColumns.SIZE)} />}
                    width={columnWidths[SortColumns.SIZE]}
                    flexGrow={1.3}
                />
                <Column
                    header={<ColumnHeader label="msg: common.added {Added}" column={SortColumns.ADDED} />}
                    cell={({ rowIndex }) => <DataCell {...this._getDataCellProps(rowIndex, SortColumns.ADDED)} />}
                    width={columnWidths[SortColumns.ADDED]}
                    flexGrow={2.3}
                />
                <Column
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    cell={({ rowIndex }) => <DeleteCell {...this._getCellProps(rowIndex)} />}
                    width={columnWidths.icon}
                />
            </Table>
        );
    }

    render() {
        const { emptyFolderArrowClassName } = this.props;

        return (
            <div className={cx(styles.table, { [styles.multiSelect]: this.props.isMultiSelect })}>
                {
                    this.props.resources.length
                        ? this.renderTable()
                        : <EmptyFolderMsg arrowClassName={cx(styles.emptyFolderMsg, emptyFolderArrowClassName)} />
                }
            </div>
        );
    }
}

const mapStateToProps = ({ fileChooser }: AppState) => ({
    isMultiSelect: fileChooser.isMultiSelect,
    resources: resourcesSelector(fileChooser),
    selection: fileChooser.selection,
    lastSelectedResourceIndex: lastSelectedResourceIndexSelector(fileChooser)
});

export default connect(mapStateToProps, null)(FileChooserList);
