import kind from './kind';
import view from './view/index';
import type { CalcProps } from '../../Preview/flowTypes';
import type { GoogleReviewsComponent } from './flowTypes';
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";

export default {
    kind,
    view,
    calcProps: ({
        component,
        stylesheetsIdToNameMap,
        globalStyles,
        globalVariables,
        siteSettings: { themeSettingsData },
        selectedParentTheme,
    }: CalcProps<GoogleReviewsComponent>) => {
        const
            { autoColorMode } = themeSettingsData,
            themeColorsData = getThemeColorsFromStylesheet(globalStyles);
        return {
            component,
            globalStyles,
            stylesheetsIdToNameMap,
            showMask: false,
            globalVariables,
            themeSettingsData,
            themeColorsData,
            selectedParentTheme: autoColorMode && selectedParentTheme
        };
    },
    js: [
        "/renderStatic/googleReviews/googleReviewsScript.js"
    ]
};
