import React from 'react';
import type { UrlInputProps } from "../flowTypes";
import { Input } from "./index";
import { inputOnChange } from "../actionCreator/index";
import { isValidSchemelessUrl } from "../../../../utils/validation";

export class UrlInput extends React.Component<UrlInputProps> {
    constructor() {
        // @ts-ignore
        super();
        this.onPaste = this.onPaste.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onEnterPress = this.onEnterPress.bind(this);
    }

    onPaste(e: any) {
        const value = e.clipboardData.getData('Text');
        if (isValidSchemelessUrl(value)) {
            e.preventDefault();
            this.autoPrefixUrl(value);
        }
    }

    onBlur(e: React.FocusEvent<any>) {
        this.autoPrefixUrl(e.target.value);
    }

    onEnterPress(e: React.KeyboardEvent) {
        //  @ts-ignore
        this.autoPrefixUrl(e.target.value);
        // @ts-ignore
        if (this.props.onEnterPress) setTimeout(() => this.props.onEnterPress(e));
    }

    autoPrefixUrl(url: string) {
        if (isValidSchemelessUrl(url)) {
            const
                correctedUrl = `http://${url}`,
                { onChangeAction, actionParams, dispatch } = this.props;

            dispatch(inputOnChange(onChangeAction, correctedUrl, actionParams));
        }
    }

    render() {
        return (
            <Input
                {...this.props}
                onPaste={this.onPaste}
                onBlur={this.onBlur}
                onEnterPress={this.onEnterPress}
                decodeValue={false}
                defaultValue="http://"
            />
        );
    }
}
