import React from "react";
import cx from "classnames";
import { Msg } from "../../../view/intl/index";
import Icons from "../../../view/Icons/index";
import { ONBOARDING_TEMPLATE_LIST_LEFT_ARROW_CLICKED, ONBOARDING_TEMPLATE_LIST_RIGHT_ARROW_CLICKED } from "../actionTypes";
import { PrimaryButton } from "../../../view/common/Button/index";
import LazyImage from "../../../view/common/LazyImage/index";
import type { DataDesignT } from "../../../../dal/model/DataDesign";
import { TemplateCarousel } from "./TemplateCarousel";
import TemplateSelectorRightSideView from "./LottieAnimations/templateSelectorRightSideView";
import styles from "../../TemplateSelector_DEPRECATED/view/TemplateSelector.css";
import rstStyles from "./RightSideTemplatesStyles.css";
import type { SubscriptionMetadata } from "../../../redux/modules/flowTypes";
import { getPremiumPackageNameFromMetadata } from "../../ComponentTierManager/utils";

type RightSideTemplatesViewPropsType = {
    gmbFilteredDesigns: Array<DataDesignT>;
    previewTemplateDesign: (selectedTemplateIndex: number) => void;
    selectedTemplateIndex: number;
    isLoading: boolean;
    setIsLoading: (...args: Array<any>) => any;
    subscriptionMetadata?: SubscriptionMetadata;
    dispatch: Dispatch;
};

const PREVIEW_IMAGE_WIDTH = 1080,
    PREVIEW_IMAGE_HEIGHT = 700,
    previewSize = `${PREVIEW_IMAGE_WIDTH}x${PREVIEW_IMAGE_HEIGHT}`,
    templatePreview = uuidOfHomePage => <LazyImage src={`/screenshots/${previewSize}/${uuidOfHomePage}.jpg?auto=false`} />;

export class RightSideTemplatesView extends React.Component<RightSideTemplatesViewPropsType> {
    render() {
        const {
                selectedTemplateIndex,
                previewTemplateDesign,
                gmbFilteredDesigns,
                isLoading,
                setIsLoading,
                subscriptionMetadata,
                dispatch
            } = this.props,
            templates = gmbFilteredDesigns,
            premiumPackageNameFromMetadata = getPremiumPackageNameFromMetadata(subscriptionMetadata);
        return (
            <div className={rstStyles.container}>
                {isLoading && (
                    <div className={rstStyles.loader}>
                        <TemplateSelectorRightSideView setIsLoading={setIsLoading} />
                    </div>
                )}
                <TemplateCarousel
                    className={isLoading ? rstStyles.hide : ""}
                    templateWidth={PREVIEW_IMAGE_WIDTH}
                    selectedTemplateIndex={selectedTemplateIndex}
                    onChange={i => {
                        if (i < selectedTemplateIndex) {
                            dispatch({
                                type: ONBOARDING_TEMPLATE_LIST_LEFT_ARROW_CLICKED
                            });
                        } else if (i > selectedTemplateIndex) {
                            dispatch({
                                type: ONBOARDING_TEMPLATE_LIST_RIGHT_ARROW_CLICKED,
                                payload: {
                                    templatesCount: templates.length
                                }
                            });
                        }
                    }}
                    items={templates.map((design, index) => {
                        if (index === selectedTemplateIndex) {
                            return (
                                <div className={cx(rstStyles.slide, rstStyles.selected)}>
                                    {templatePreview(design.uuidOfHomePage)}
                                    {design.isPremium && [
                                        <div key={0} className={cx(rstStyles.premium, styles.premium)} />,
                                        <div key={1} className={styles.premiumContent}>
                                            <Icons.PREMIUM_UPGRADE_LARGE />
                                            {premiumPackageNameFromMetadata || <Msg k="common.premium">Premium</Msg>}
                                        </div>
                                    ]}
                                    <div className={rstStyles.templateOverlay}>
                                        <div className={rstStyles.buttonActions}>
                                            <PrimaryButton
                                                onClick={e => {
                                                    e.preventDefault();
                                                    previewTemplateDesign(selectedTemplateIndex);
                                                }}
                                            >
                                                <Msg k="common.previewTemplate">Preview Template</Msg>
                                            </PrimaryButton>
                                        </div>
                                    </div>
                                </div>
                            );
                        } else {
                            return <div className={rstStyles.slide}>{templatePreview(design.uuidOfHomePage)}</div>;
                        }
                    })}
                />
            </div>
        );
    }
}
