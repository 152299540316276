import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import { SAVE_REQUEST } from "../../actionTypes";
import { sendEventToAec } from "../../actionCreators/sendEventToAecActionCreator";
import { COMPONENTS_ADD_COMPONENT } from "../../../Workspace/epics/componentsEval/actionTypes";

const epicConfig = {
    defaultState: null,
    valueActionType,
    updaters: [
        {
            conditions: [SAVE_REQUEST],
            reducer: ({ state }) => ({
                state,
                actionToDispatch: sendEventToAec({
                    category: 'Workspace',
                    action: 'save'
                })
            })
        },
        {
            conditions: [COMPONENTS_ADD_COMPONENT],
            reducer: ({ values: [{ kind }], state }) => ({
                state,
                actionToDispatch: sendEventToAec({
                    category: 'Components',
                    action: 'add',
                    opt_label: kind
                })
            })
        }
    ]
};

export default makeEpic(epicConfig);
