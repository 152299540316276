import React, { useState } from 'react';
import cx from 'classnames';

import ReviewCell from "../ReviewCard/ReviewCard";
import * as styles from "./ReviewSlider.css";
import { ReviewViewType } from "../../constants";

enum SliderArrow { LEFT, RIGHT }

const Arrow = ({ direction, onClick }) => (
    <div className={`${styles.arrowContainer} reviewArrows`}>
        <div
            className={
                `${direction === SliderArrow.LEFT ? styles.leftArrow : styles.rightArrow} googleReviewsSliderArrow-${direction}`
            }
            onClick={onClick}
        />
    </div>
);

const Dots = ({ total, active }) => {
    let dots: AnyReactElement[] = [];
    for (let i = 0; i < total; i++) {
        dots.push(<div key={`review_${i}`} className={cx('dot', { 'activeDot': active === i })} />);
    }
    return (
        <div className={cx(styles.dots, 'dots')}>
            { dots }
        </div>
    );
};

const generateSlides = ({ reviews, seeMoreText, seeLessText, reviewTimeText }) => {
    let Slides: AnyReactElement[] = [];
    reviews.forEach(review => {
        const { reviewId, displayName, profilePhotoUrl, starRating, comment, updateTime, reviewUrl } = review;
        Slides.push(
            <div key={reviewId} className={cx(styles.googleReviewSlide, 'googleReviewSlide')}>
                <ReviewCell
                    key={reviewId}
                    layoutType={ReviewViewType.SLIDER}
                    name={displayName}
                    profilePhoto={profilePhotoUrl}
                    rating={starRating}
                    comment={comment}
                    showReadMore
                    date={updateTime}
                    customClass={cx(styles.removeBorder)}
                    reviewUrl={reviewUrl}
                    seeMoreText={seeMoreText}
                    seeLessText={seeLessText}
                    reviewTimeText={reviewTimeText}
                />
            </div>
        );
    });
    return <div className={cx(styles.googleReviewSlides, 'googleReviewSlides')}>{ Slides }</div>;
};

const ReviewSlider = ({ reviews, isWorkspace, seeMoreText, seeLessText, reviewTimeText }) => {
    const totalReviews = reviews.length,
        [currentReviewIndex, setCurrentReviewIndex] = useState(0);
    const { reviewId, displayName, profilePhotoUrl, starRating, comment, updateTime, reviewUrl } = reviews[currentReviewIndex];
    const onArrowClick = (direction) => {
        if (direction === SliderArrow.LEFT) {
            setCurrentReviewIndex(currentReviewIndex => ((currentReviewIndex - 1 + totalReviews) % totalReviews));
        } else {
            setCurrentReviewIndex(currentReviewIndex => ((currentReviewIndex + 1) % totalReviews));
        }
    };
    return (
        <div className={`googleReviewsSlider ${styles.container}`}>
            <Arrow direction={SliderArrow.LEFT} onClick={() => onArrowClick(SliderArrow.LEFT)} />
            <div className={styles.reviewContainer}>
                {
                    isWorkspace
                        ?
                        (
                            <ReviewCell
                                key={reviewId}
                                layoutType={ReviewViewType.SLIDER}
                                name={displayName}
                                profilePhoto={profilePhotoUrl}
                                rating={starRating}
                                comment={comment}
                                showReadMore
                                date={updateTime}
                                customClass={cx(styles.removeBorder)}
                                reviewUrl={reviewUrl}
                                seeMoreText={seeMoreText}
                                seeLessText={seeLessText}
                                reviewTimeText={reviewTimeText}
                            />
                        )
                        :
                        generateSlides({ reviews, seeMoreText, seeLessText, reviewTimeText })
                }
                <Dots total={totalReviews} active={currentReviewIndex} />
            </div>
            <Arrow direction={SliderArrow.RIGHT} onClick={() => onArrowClick(SliderArrow.RIGHT)} />
        </div>
    );
};

export default ReviewSlider;
