import { parseDOM } from "../../../../../utils/parseDOM";
import { type BookingsComponent } from '../types';

export const parseTrafftPostCode = (code: string): BookingsComponent => {
    let params = <BookingsComponent>{};

    const configs = [
        {
            nodeName: 'DIV',
            parser: node => {
                const dataUrl = node.getAttribute('data-url'),
                    dataQuery = node.getAttribute('data-query'),
                    dataEmployee = node.getAttribute('data-employee'),
                    dataAutoResize = node.getAttribute('data-autoresize'),
                    dataShowServices = node.getAttribute('data-showservices'),
                    dataShowSidebar = node.getAttribute('data-showsidebar'),
                    dataLang = node.getAttribute('data-lang');
                let link = "";
                if (
                    // eslint-disable-next-line max-len
                    node.classList.contains("embedded-booking")
                    && dataUrl
                    && (
                        dataUrl.endsWith("trafft.com")
                        || dataUrl.endsWith("wlbookings.com")
                    )
                    && dataAutoResize && dataShowSidebar && dataLang
                ) {
                    let employeeUrl = '';

                    if (dataEmployee) {
                        employeeUrl = '/e/' + dataEmployee;
                    }

                    if (dataShowServices) {
                        link = (dataUrl + `/services-embed?autoResize=${dataAutoResize}&showSidebar=${dataShowSidebar}&lang=${dataLang}`);
                    } else {
                        link = (
                            dataUrl +
                            `/booking-embed${employeeUrl}?autoResize=${dataAutoResize}&showSidebar=${dataShowSidebar}&lang=${dataLang}` +
                            (dataQuery ? `${dataQuery}` : '')
                        );
                    }
                }
                return { link };
            }
        },
        {
            nodeName: 'SCRIPT',
            validator: node => node.src.endsWith("trafft.com/embed.js"),
        }
    ];

    params = parseDOM(configs, code);

    return { ...params, code };
};
