import makeEpic from "../../../../../epics/makeEpic";
import valueActionType from "./valueActionType";
import * as ActionTypes from "../../actionTypes";
import { CLOSE_DIALOG, OPEN_DIALOG } from "../../../../../redux/modules/actionTypes";
import buildNewVideoComponentState from "../../buildNewVideoComponentState";
import { COMPONENT_CONFIGURATION_COMPLETE } from "../../../../../redux/modules/children/workspace/actionTypes";
import { YoutubeConfigDialogId, PreviewDialogId } from "../../dialogIds";
import { youtubeUrl, previewUrl } from "../../videoUrlsUtility";
import { makeActionForwardToSelectedComponent } from "../../../../../redux/forwardTo";

const videoReplacedAction = videoId => makeActionForwardToSelectedComponent({
    type: ActionTypes.YOUTUBE_REPLACE_VIDEO_UPDATE,
    payload: {
        input: youtubeUrl(videoId)
    }
});

const newVideoAddedAction = videoId => ({
    type: COMPONENT_CONFIGURATION_COMPLETE,
    payload: buildNewVideoComponentState(videoId)
});

const getDefaultState = () => ({ replace: false, videoId: null });

export default makeEpic({
    dispatchOutside: true,
    defaultState: getDefaultState(),
    valueActionType,
    updaters: [
        {
            conditions: [ActionTypes.YOUTUBE_REPLACE_VIDEO],
            reducer: () => ({
                state: { replace: true, videoId: null },
                actionToDispatch: {
                    type: OPEN_DIALOG,
                    payload: { dialogId: YoutubeConfigDialogId }
                }
            })
        },
        {
            conditions: [ActionTypes.YOUTUBE_REPLACE_VIDEO_UPDATE],
            reducer: () => ({ state: getDefaultState(), actionToDispatch: { type: CLOSE_DIALOG } })
        },
        {
            conditions: [ActionTypes.VIDEO_SELECTED],
            reducer: ({ values: [videoId], state }) => ({
                state: getDefaultState(),
                                actionToDispatch: state.replace ? videoReplacedAction(videoId) : newVideoAddedAction(videoId)
            })
        },
        {
            conditions: [ActionTypes.YOUTUBE_VIDEO_PREVIEW],
            reducer: ({ values: [{ videoId }], state }) => ({
                state,
                actionToDispatch: {
                    type: OPEN_DIALOG,
                    payload: { dialogId: PreviewDialogId, props: { url: previewUrl(videoId) } }
                }
            })
        }
    ]
});

