export const
    LC_PAGE_SELECT = 'LC_PAGE_SELECT',
    LC_PAGE_EXPAND = 'LC_PAGE_EXPAND',
    LC_FILE_SELECT = 'LC_FILE_SELECT',
    LC_URL_SET = 'LC_URL_SET',
    LC_URL_CORRECT_SCHEMA = 'LC_URL_CORRECT_SCHEMA',
    LC_EMAIL_SET = 'LC_EMAIL_SET',
    LC_SWITCH_TAB = 'LC_SWITCH_TAB',
    LC_SAVE = 'LC_SAVE',
    LC_CANCEL = 'LC_CANCEL',
    LC_LOAD_EMAIL_REQUEST = 'LC_LOAD_EMAIL_REQUEST',
    LC_LOAD_EMAIL_SUCCESS = 'LC_LOAD_EMAIL_SUCCESS',
    LC_LOAD_EMAIL_FAILURE = 'LC_LOAD_EMAIL_FAILURE',
    LC_RESET_CURRENT_RESULT = 'LC_RESET_CURRENT_RESULT',
    LC_TOGGLE_OPEN_IN_NEW_WINDOW = 'LC_TOGGLE_OPEN_IN_NEW_WINDOW',
    _LC_RESET_CURRENT_RESULT_VALUE = '_LC_RESET_CURRENT_RESULT_VALUE',
    LC_SECTION_LINK_PAGE_SELECT = 'LC_SECTION_LINK_PAGE_SELECT',
    LC_SECTION_LINK_SECTION_SELECT = 'LC_SECTION_LINK_SECTION_SELECT';

export const
    LC_PHONE_OPTION_CHANGE_ACTION = 'LC_PHONE_OPTION_CHANGE_ACTION',
    LC_PHONE_INPUT_CHANGE_ACTION = 'LC_PHONE_INPUT_CHANGE_ACTION',
    LC_RESET_PHONE_INPUT = 'LC_RESET_PHONE_INPUT';

export const
    LC_EMAIL_SUBJECT_INPUT_CHANGE_ACTION = 'LC_EMAIL_SUBJECT_INPUT_CHANGE_ACTION';

export const
    LC_LOCATION_OPTION_CHANGE_ACTION = 'LC_LOCATION_OPTION_CHANGE_ACTION';
