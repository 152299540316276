import { SCHEDULE_ACTION } from '../../../../redux/middleware/schedule/actionTypes';
import type { ApiAction } from '../../../../redux/middleware/api/flowTypes';

export type ScheduleAction = Action<{
    timeout: number;
    actionToDispatch: AnyAction | PlainAction | ApiAction
}>

export const createScheduleAction = (
    actionToDispatch: AnyAction | PlainAction,
    timeout: number = 0
): ScheduleAction => ({
    type: SCHEDULE_ACTION,
    payload: {
        timeout,
        actionToDispatch
    }
});
