import CALL_API from '../../../redux/middleware/api/CALL_API';
import { sitePagesLoadActionTypes } from '../actionTypes';

export default function (stubs?: boolean): any {
    return {
        [CALL_API]: {
            types: sitePagesLoadActionTypes,
            endpoint: 'getSitePages',
            endpointParams: { stubs }
        }
    };
}
