import { awsDynamoDbAction } from "../../../redux/middleware/aws/actions";

export const
    SET_INSTAGRAM_ACCESS_TOKEN_TRIAL_REQUEST_ACTION = 'SET_INSTAGRAM_ACCESS_TOKEN_TRIAL_REQUEST_ACTION',
    SET_INSTAGRAM_ACCESS_TOKEN_TRIAL_SUCCESS_ACTION = 'SET_INSTAGRAM_ACCESS_TOKEN_TRIAL_SUCCESS_ACTION',
    SET_INSTAGRAM_ACCESS_TOKEN_TRIAL_FAILURE_ACTION = 'SET_INSTAGRAM_ACCESS_TOKEN_TRIAL_FAILURE_ACTION',
    setInstagramAccessCredentialsInDemoAction = (
        instagramAccessToken: string,
        instagramAccountName: string,
        expiresIn: number
    ) => awsDynamoDbAction({
        actionTypes: [
            SET_INSTAGRAM_ACCESS_TOKEN_TRIAL_REQUEST_ACTION,
            SET_INSTAGRAM_ACCESS_TOKEN_TRIAL_SUCCESS_ACTION,
            SET_INSTAGRAM_ACCESS_TOKEN_TRIAL_FAILURE_ACTION,
        ],
        serviceMethod: 'storeInstagramCredentials',
        serviceMethodParams: [instagramAccessToken, instagramAccountName, expiresIn]
    });
