/* eslint-disable max-len */

import React from "react";
import cx from "classnames";
import { ComponentMaskBackgroundStyle, DefaultComponentMaskBackground } from './constants';
import type { ComponentMaskPropTypes } from './flowTypes';
import styles from "./ComponentMask.css";
import { PremiumFeatureComponentMaskLabel } from "../../ComponentTierManager/view/PremiumFeatureComponentMaskLabel";

export default (
    {
        children,
        forceShowMask,
        // Prevents flickering of mask
        siblingContent = <div />,
        showPreviewOnlyLayer = true,
        previewOnlyLayerContent,
        style,
        maskStyle = DefaultComponentMaskBackground,
        containerClassName = '',
        componentTierData,
        componentWidth,
        inEditMode,
        inSelectedMode
    }: ComponentMaskPropTypes
) => {
    const isComponentUnselectedAndTierIncompatible = (!inEditMode && !inSelectedMode) && componentTierData && !componentTierData.isCompatible;
    return (
        <div className={forceShowMask ? styles.forceShowMask : styles.componentMask}>
            <div className={cx(styles.maskedContent, containerClassName)}>{children}</div>
            <div
                className={cx(styles.contentMask,
                    {
                        [styles.componentTierIncompatible]: isComponentUnselectedAndTierIncompatible
                    })}
                style={style}
            >
                {
                    isComponentUnselectedAndTierIncompatible &&
                    <PremiumFeatureComponentMaskLabel
                        // @ts-ignore
                        width={componentWidth}

                        componentTierType={componentTierData.componentTierType}
                    />
                }
            </div>
            {siblingContent}
            {
                showPreviewOnlyLayer &&
                <div className={cx(styles.hoverMask, styles[ComponentMaskBackgroundStyle[maskStyle]])}>
                    {previewOnlyLayerContent}
                </div>
            }
        </div>
    );
};
