import * as React from 'react';
import cx from 'classnames';
import styles from './LinkButton.css';
import { injectIntl, Intl, type MsgJointInput } from "../../../view/intl/index";

type Props = {
    children: React.ReactNode | MsgJointInput,
    onClick: React.MouseEventHandler,
    intl: Intl,
    translate?: boolean,
    className?: string,
};

const LinkButton = injectIntl(
    // @ts-ignore
    ({ translate = true, children, intl }: Props) => (
        (translate && intl.msgJoint(children as MsgJointInput)) || children
    )
);

export const LinkButtonCom = React.forwardRef(
    (props: Omit<Props, 'intl'>, ref: React.ForwardedRef<any>) => (
        <span className={cx(styles.link, props.className)} onClick={props.onClick} ref={ref}>
            <LinkButton {...props} />
        </span>
    )
);

