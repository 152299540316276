import type { ResizeWebspaceImgSrcParams } from "./resizeWebspaceImgSrc";
import { ImgResolutionSrcSet } from "./ImgResolutionSrcSet";
import { resizeWebspaceImgSrc } from "./resizeWebspaceImgSrc";
import type { ResizeScale } from "./flowTypes";

export type ScaleWebspaceImgResolutionParams = ResizeWebspaceImgSrcParams & {
    scale: ResizeScale,
};

export type ScaleWebspaceImgResolutionResult = {
    src: string,
    srcSet: ImgResolutionSrcSet,
};

export const scaleWebspaceImgResolution = (
    params: ScaleWebspaceImgResolutionParams
): ScaleWebspaceImgResolutionResult => {
    const
        { scale, width, height, ...restParams } = params,
        src = resizeWebspaceImgSrc({
            ...restParams,
            width: Math.round(width),
            height: Math.round(height)
        }),
        srcSet = new ImgResolutionSrcSet(
            scale.reduce(
                (acc, num) => ({
                    ...acc,
                    [num]: resizeWebspaceImgSrc({
                        ...restParams,
                        width: Math.round(width * num) || 0,
                        height: Math.round(height * num) || 0
                    })
                }),
                // src is included as 1x in case image is explicitly loaded (via js) as: img.srcset = setset
                // (see: LazyImage for more)

                { 1: src }
            )
        );

    return { src, srcSet };
};
