import React from "react";
import kind from '../kind';
import { getTestingWithScreenshotPreview } from '../../constants';

import type { ViewProps } from './flowTypes';
import VideoPlayer from "./VideoPlayer/VideoPlayer";

export default ({ url, height, width, isMobileHide, testingWithScreenshots, settings, isVideoFile,
    videoAvailable, isNewVideo, videoStatus }: ViewProps) => {
    if (testingWithScreenshots) {
        return getTestingWithScreenshotPreview(kind);
    }

    if (isVideoFile) {
        return (
            <VideoPlayer
                isNewVideo={isNewVideo}
                videoAvailable={videoAvailable}
                videoStatus={videoStatus}
                height={height}
                width={width}
                url={url}
                settings={settings}
            />
        );
    }

    return <iframe
        allowFullScreen
        allow="autoplay"
        // @ts-ignore
        type="text/html"
        height={height}
        width={width}
        data-kind={kind}
        src={url}
        frameBorder="0"
        data-mobile-hide={isMobileHide}
    />;
};
