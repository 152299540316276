import React from "react";
import { Box, BoxProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import cx from "classnames";

const useStyles = makeStyles(
    theme => ({
        root: {
            userSelect: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            [theme.breakpoints.up("md")]: {
                flexDirection: "row",
                alignItems: "flex-start",
                flexWrap: "nowrap",
                gap: "40px",
            }
        },
        info: {
            flex: "1 0 calc(42% - 48px)"
        },
        form: {
            flex: "1 0 58%",
            maxWidth: "100%",
            [theme.breakpoints.down("sm")]: {
                overflow: "hidden"
            }
        }
    }),
    { name: "SettingsSectionWrapper" }
);

type SettingsSectionWrapperProps = BoxProps & {
    info: JSX.Element;
    form: JSX.Element;
    settings?: Record<string, any>;
    classes?: {
        container?: string;
        info?: string;
        form?: string;
    };
};

export const SettingsSectionWrapper = (props: SettingsSectionWrapperProps) => {
    const classes = useStyles();

    const { info, form, settings = { isPaddedInMobile: true }, classes: classesProp, ...boxProps } = props;
    const { isPaddedInMobile } = settings;

    return (
        <Box className={cx(classes.root, classesProp?.container)} {...boxProps}>
            <Box className={cx(classes.info, classesProp?.info)}>{info}</Box>

            <Box
                className={cx(classes.form, classesProp?.form)}
                marginLeft={{ xs: isPaddedInMobile ? "42px" : "0px", md: "0px" }}
                marginTop={{ xs: "16px", md: "0px" }}
            >
                {form}
            </Box>
        </Box>
    );
};
