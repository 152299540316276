/* @flow */

const
    REPOSITORY_PREFIX = 'repository:',
    WEBSPACE_MAX_IMAGE_RESOLUTION = 64039360,
    ONE_MB_BYTES = 1024 * 1024,
    MAX_ALLOWED_SVG_SIZE_BYTES = 2 * ONE_MB_BYTES,
    WEBSPACE_PREFIX = 'webspace:';

module.exports = {
    REPOSITORY_PREFIX,
    WEBSPACE_MAX_IMAGE_RESOLUTION,
    MAX_ALLOWED_SVG_SIZE_BYTES,
    WEBSPACE_PREFIX,
    ONE_MB_BYTES,
};
