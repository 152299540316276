// @ts-nocheck
import type { AnyComponent } from '../../../flowTypes';

export default (component: AnyComponent): Record<string, any> => {
    const { relIn, width } = component;

    // Determine text component width
    const textComponentWidth = width + relIn.left - relIn.right;
    const textComponentLeftThird = Math.floor(textComponentWidth / 3);
    const textComponentRightThird = Math.floor(textComponentLeftThird * 2);
    const componentMiddle = relIn.left + Math.floor(width / 2);

    if (componentMiddle < textComponentLeftThird) {
        return {
            left: true
        };
    } else if (componentMiddle > textComponentRightThird) {
        return {
            right: true
        };
    }
    return {
        center: true
    };
};
