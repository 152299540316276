import { getDimensions } from "../utils/dimensions";
import { adjustShouldNotOverlapSelectedComponentAndMCTA, adjustShouldNotCollideWithViewPort } from "../utils/position";
import type { DimensionsContext, StateHolder, Navigation } from "../../flowTypes";

export default (context: DimensionsContext, epicState: StateHolder, pageHeight: number,
    navigation: Navigation): StateHolder => {
    const
        { state, scope } = epicState,
        { selectedComponentWithId } = state;

    if (!selectedComponentWithId && !state.instance) {
        return epicState;
    }

    const
        { opacity } = state,
        newDimensions = getDimensions(scope.maxHeight, pageHeight);

    if (newDimensions.height !== state.dimensions.height) {
        const
            newPosition = (opacity === 0)
                ? adjustShouldNotOverlapSelectedComponentAndMCTA({ context, dimensions: newDimensions, navigation })
                : adjustShouldNotCollideWithViewPort(context, newDimensions, state.position);

        return {
            scope: {
                ...scope,
                actualPosition: newPosition
            },
            state: {
                ...state,
                animateMovement: (opacity === 1),
                opacity: 1,
                dimensions: newDimensions,
                currentPage: {
                    ...state.currentPage,
                    height: pageHeight
                },
                position: newPosition
            }
        };
    }

    return epicState;
};
