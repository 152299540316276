import { UPDATE_SITE_DATA_SAGA_AFTER_SAVE } from "../actionTypes";
import { saveSiteDataAction } from "../../App/epics/siteData/actionCreators";
import type { UpdateSiteDataInput } from "../../../../dal/flowTypes";
import { SITE_DATA_UPDATE_EXTERNAL } from "../../App/epics/siteData/updateReasons";

export default (saveSiteDataInput: UpdateSiteDataInput, isExternalUpdate: boolean = false) => saveSiteDataAction({
    saveSiteDataInput,
    afterSaveActionType: UPDATE_SITE_DATA_SAGA_AFTER_SAVE,
    afterSaveUpdateReason: isExternalUpdate ? SITE_DATA_UPDATE_EXTERNAL : undefined
});
