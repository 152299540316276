/* eslint-disable max-len */

import React from "react";
import * as styles from './PublishingSuccess.css';
import Icons from '../../../../../view/Icons/index';
import Msg from '../../../../../view/intl/Msg';
import { CLOSE_PUBLISHING_SUCCESS_POPUP } from "../../../actionTypes";
import type { PublishingSuccessPopupProps } from "../../../flowTypes";
import NoMouseEventsPropagationContainer from '../../../../../view/Workspace/Decorations/ComponentMainActions/noMouseEventsPropagationContainer';

const closeAction = { type: CLOSE_PUBLISHING_SUCCESS_POPUP },
    closeAfterMs = 10000,
    setCloseAfterMs = dispatch => setTimeout(() => {
        dispatch(closeAction);
    }, closeAfterMs);

class PublishingSuccess extends React.Component<PublishingSuccessPopupProps> {
    timer: ReturnType<typeof setTimeout> | null | undefined;

    constructor(props: PublishingSuccessPopupProps) {
        super(props);
        this.timer = null;
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.onViewSite = this.onViewSite.bind(this);
    }
    componentDidMount() {
        this.timer = setCloseAfterMs(this.props.dispatch);
    }
    onMouseEnter() {
        if (this.timer) clearTimeout(this.timer);
    }
    onMouseLeave() {
        this.timer = setCloseAfterMs(this.props.dispatch);
    }
    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }
    onViewSite() {
        const win = window.open(
            this.props.publishSiteLink,
            '_blank',
            // Note: Without this "noopener" option, Chrome browser "might" (the may not be observed all the time or
            //       might get fixed in future) also use the <body> element's "background-color" for the newly opened
            //       tab intermittently for a few milliseconds. See: WBTGEN-23180
            'noopener'
        );

        if (win?.focus) win.focus();

        this.props.dispatch(closeAction);
    }
    render() {
        return (
            <NoMouseEventsPropagationContainer className={styles.container}>
                <div
                    className={styles.infoContainer}
                    onClick={e => e.stopPropagation()}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                >
                    <div className={styles.pointer} />
                    <div className={styles.infoBox}>
                        <Icons.DONE className={styles.done} />
                        <div className={styles.successTitle}>
                            <Msg k="publish.publishSuccess.title">Your site is published</Msg>
                        </div>
                        <div className={styles.viewSite} onClick={this.onViewSite}>
                            <a>
                                <Msg k="publish.publishSuccess.viewSite">View site</Msg>
                            </a>
                        </div>
                    </div>
                    <div
                        className={styles.close}
                        onClick={() => this.props.dispatch(closeAction)}
                    >
                        <Icons.CLOSE_LIGHT />
                    </div>
                </div>
            </NoMouseEventsPropagationContainer>
        );
    }
}

export default PublishingSuccess;
