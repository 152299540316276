import cx from "classnames";
import getCenteredDialog from "../../../DialogManager/getCenteredDialogConfig";
import { configurationDialogFactory } from '../configurationDialogFactory';
import { SoundcloudDialogHeight, DialogWidth } from '../constants';
import { WIDGETS_SOUNDCLOUD_SET_LINK } from "./actionTypes";
import { validateLinkOrCode } from '../utils';
import { parseSoundcloudCode } from './utils/parseSoundcloudCode';
import Icons from '../view/Icons.css';

const validateSoundcloudData = validateLinkOrCode([], parseSoundcloudCode);

const
    configurationDialogId = "WIDGETS_SOUNDCLOUD_CONFIG_DIALOG",
    configurationDialogView = configurationDialogFactory({
        saveAction: WIDGETS_SOUNDCLOUD_SET_LINK,
        dialogIconClass: cx(Icons.soundcloud, Icons.dialog),
        dialogTitle: "msg: widgets.soundcloud.label {SoundCloud}",
        info: "msg: widgets.soundcloud.config.info {Add a SoundCloud track or playlist to your page.}",
        subInfo: "msg: widgets.soundcloud.configDialog.copyPaste {Go to SoundCloud and select \'copy link\' below the track or playlist. Then paste it below. }", // eslint-disable-line max-len
        inputPlaceHolder: "msg: widgets.soundcloud.configDialog.placeHolder {Paste SoundCloud embed code}",
        learnMore: "msg: widgets.soundcloud.config.learnMore {https://help.one.com/hc/en-us/articles/10148437825169}",
        isLinkValid: validateSoundcloudData,
        termsOfService: "msg: widgets.soundcloud.config.termsOfService {By using this widget, you agree to SoundCloud\'s {termsOfServiceLink}.}", // eslint-disable-line max-len
        termsOfServiceLink: "msg: widgets.soundcloud.config.termsOfServiceLink {https://soundcloud.com/terms-of-use}",
    }),
    configurationDialog = getCenteredDialog({
        width: DialogWidth,
        height: SoundcloudDialogHeight,
        component: configurationDialogView
    });

export { configurationDialogId, configurationDialog };
