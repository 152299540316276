
import {
    DEFAULT_WIDTH, DEFAULT_HEIGHT,
    VIDEO_DEFAULT_WIDTH, VIDEO_DEFAULT_HEIGHT,
    PostType,
} from '../constants';

export const getWidgetDimensionsByPostType = (postType) => {
    let width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT;

    if (postType === PostType.VIDEO) {
        width = VIDEO_DEFAULT_WIDTH;
        height = VIDEO_DEFAULT_HEIGHT;
    }

    return { width, height };
};
