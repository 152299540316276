
import * as main from './pages/main';
import * as background from './pages/background';
import * as templateCategoriesPage from './pages/templateCategoriesPage';
import scrollEffectsPage from './pages/scrollEffects';
import makePages from '../../../PropertiesPanel/view/makePages';

const propertiesPanelConfig = {
    pages: makePages(main, background, scrollEffectsPage, templateCategoriesPage),
    defaultPageId: main.id
};

export default propertiesPanelConfig;
