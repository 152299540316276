export const
    parseIntDec = (val: string): number => parseInt(val, 10),
    round = (number: number, precision: number): number => {
        const factor = Math.pow(10, precision);
        return Math.round(number * factor) / factor;
    },
    trunc = (number: number, precision: number): number => {
        const factor = Math.pow(10, precision);
        return Math.trunc(number * factor) / factor;
    },
    trunc1 = (number: number): number => {
        return trunc(number, 1);
    },
    isNumeric = (value: any) => !isNaN(parseFloat(value)) && isFinite(value);

