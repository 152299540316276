import React from "react";
import { pure } from 'recompose';
import { MctaInput } from '../../../componentMainActions/index';
import * as actionTypes from "../actionTypes";
import Msg from '../../../../view/intl/Msg';
import * as styles from './ComponentMainActions.css';
import ContactForm from "../view/workspace";
import ContactFormKind from '../kind';
import AlignmentControls from '../../../componentMainActions/MctaAlignmentControls/alignmentControls';
import { MOBILE_MCTA_ALIGN_MOUSE_ENTER, MOBILE_MCTA_ALIGN_MOUSE_LEAVE,
    MOBILE_EDITOR_ALIGNMENT_CHANGE } from '../../../MobileViewEditor/actionTypes';
import type { MCTA_PROPS } from '../../../MobileViewEditor/flowTypes';
import type { ContactFormComponent, ContactFormDependsOn } from "../flowTypes";
import { ALIGNMENT_TYPES } from "../../../../constants";
import type { isCTAUrgentType } from '../../flowTypes';

const
    MCTAView = pure(({ selectedComponent, dispatch }) => {
        return (
            <div>
                <MctaInput
                    label="msg: component.button.buttonText {Button text}"
                    placeholder="msg: component.button.buttonText {Button text}"
                    value={selectedComponent.submitBtn}
                    inputStyle={{ width: 150 }}
                    maxLength={60}
                    onChange={text => dispatch({
                        type: actionTypes.CONTACT_FORM_CHANGE_BUTTON_TEXT, payload: text
                    })}
                />
            </div>
        );
    });

type PropTypes = {
    dispatch: Dispatch,
    mobileMCTA: MCTA_PROPS,
    selectedComponent: ContactFormComponent
};

const
    MCTAMobileView = (props: PropTypes) => {
        const {
            dispatch,
            mobileMCTA,
            selectedComponent,
            selectedComponent: { mobileSettings: { align = ALIGNMENT_TYPES.ALIGN_CENTER } }
        } = props;
        const { mctaHorizontalAlignControlExpanded, availableSpace } = mobileMCTA;
        return (
            <div>
                <AlignmentControls
                    availableSpace={availableSpace}
                    actionTypeToDispatch={MOBILE_EDITOR_ALIGNMENT_CHANGE}
                    selectedCmpId={selectedComponent.id}
                    onMouseEnterCollapsedViewActionType={MOBILE_MCTA_ALIGN_MOUSE_ENTER}
                    onMouseLeaveExpandedViewActionType={MOBILE_MCTA_ALIGN_MOUSE_LEAVE}
                    alignment={align}
                    expanded={mctaHorizontalAlignControlExpanded}
                    dispatch={dispatch}
                    kind={ContactFormKind}
                />
            </div>
        );
    },
    isCTAUrgent: isCTAUrgentType<ContactFormComponent, ContactFormDependsOn> = () => false;

export default {
    editView: MCTAView,
    mobileViewEditorV: MCTAMobileView,
    editButtonText: 'msg: component.contactForm.editContactForm {Edit contact form}',
    // WBTGEN-30493 (Reorganise contact from settings)
    CtaButtonTextView: ({ selectedComponent }: { selectedComponent: ContactForm }) => (
        //@ts-ignore
        selectedComponent.formElementsOrder.length ?
            <Msg k="component.contactForm.editFields">Edit fields</Msg> :
            <Msg k="component.contactForm.addFields" className={styles.noFields}>Add fields</Msg>
    ),
    onCTAClickActionType: actionTypes.CONTACT_FORM_EDIT_FIELDS,
    isCTAUrgent
};
