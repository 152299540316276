import { equals } from 'ramda';
import { SCHEDULE_ACTION, CANCEL_SCHEDULED_ACTION } from './actionTypes';
import type { ScheduleAction } from '../../../components/Workspace/epics/resizeDecos/actionCreators';
import { traceEnabled } from '../../../utils/isDebug';

let pendingActions: Array<{ timeoutId: ReturnType<typeof setTimeout>, actionToDispatch: Action | PlainAction }> = [];
function removePendingAction(action) {
    pendingActions = pendingActions.filter(({ actionToDispatch }) => actionToDispatch !== action);
}

const expectedActionsMap = { SCHEDULE_ACTION: true, CANCEL_SCHEDULED_ACTION: true };
export default (store: Store) => (next: Dispatch) => (action: Action | PlainAction | ScheduleAction) => {
    if (!expectedActionsMap[action.type]) {
        return next(action);
    }
    const scheduleAction = (action as any);
    const { actionToDispatch } = scheduleAction.payload;

    // TODO should return promise
    if (scheduleAction.type === SCHEDULE_ACTION) {
        const timeoutId = setTimeout(() => {
            removePendingAction(actionToDispatch);
            store.dispatch(actionToDispatch);
        }, scheduleAction.payload.timeout);
        pendingActions.push({ timeoutId, actionToDispatch });
        if (traceEnabled()) {
            console.log('SCHEDULE_ACTION', timeoutId, actionToDispatch);
        }
    } else if (scheduleAction.type === CANCEL_SCHEDULED_ACTION) {
        const pendingAction = pendingActions.find(pa => equals(pa.actionToDispatch, actionToDispatch));
        if (pendingAction) {
            if (traceEnabled()) {
                console.log('canceled SCHEDULE_ACTION', pendingAction.timeoutId);
            }
            clearTimeout(pendingAction.timeoutId);
            removePendingAction(pendingAction.actionToDispatch);
        }
    }

    return true;
};
