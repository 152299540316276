// @ts-nocheck

/* eslint-disable max-len */
import React from "react";
import cx from "classnames";
import { Box, Flex } from "../../../view/reflexbox/index";
import * as selectors from "../../Workspace/epics/stylesheets/selectors";
import * as path from "../../../mappers/path";
import Scrollbar from '../../../view/common/Scrollbar/index';
import optionMapper from "../../oneweb/Menu/globalStyle/options";
import MenuGlobalStyleKind from "../../oneweb/Menu/globalStyle/kind";
import { injectIntl, Msg } from '../../../view/intl/index';
import Combobox from '../../../view/common/Combobox/index';
import CheckBoxWithLabel from '../../../view/common/CheckBox/CheckBoxWithLabel';
import HorizontalSpacer from "../../../view/common/HorizontalSpacer";
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import GrayBgContainer from "../../presentational/GrayBgContainer";
import FontFamilySelector from "../../presentational/FontFamily/index";
import TextFormatButtonGroup from "../../presentational/IconButtonsGroup/TextFormat";
import FontSizeSelector from "../../presentational/FontSize/index";
import { TextHorizontalAlignmentButtonsGroup } from "../../presentational/IconButtonsGroup/TextAlignmentButtonGroup/index"; // eslint-disable-line max-len
import ChangeLetterCaseButtonGroup from "../../presentational/IconButtonsGroup/ChangeLetterCase";
import { button as BackgroundButton } from "../../presentational/Background/index";
import borderStyleConfigurationDialogId from "./borderStyleConfigurationDialogId";
import { button as BorderStyleButton } from "../../presentational/BorderStyle/index";
import { button as CellSpacingButton } from "../../presentational/CellSpacing/index";
import ColorProperty from "../../presentational/ColorProperty/index";
import { button as BorderCornerButton } from "../../presentational/BorderCorner/index";
import { button as TextShadowButton } from "../../presentational/TextShadow/index";
import { getGlobalstyleHoverClassName } from "../../RenderGlobalstyles/getGlobalstyleClassName";
import { makeVerticalMenuPreview, makeHorizontalMenuPreview } from './menuPreview';
import * as actionTypes from "./actionTypes";
import TopBar from "../TopBar";
import { BACKGROUND_THEME_WHITE } from '../../ThemeGlobalData/constants';

import {
    menuTabLabel,
    TextTransform,
    Types,
    Messages,
    DialogIds,
    TreeMenuTextIndentOptions
} from "./constants";
import { getDataForStyle, getActionForStyle } from "./utility";
import spacingConfigurationDialogId from './spacingConfigurationDialogId';
import type { MenuGlobalstyleDialogProps } from '../flowTypes';
import styles from "../GlobalstylesDialog.css";
import ThemeStyleMenuType from "../../oneweb/Menu/globalStyle/themeColorKind";

const
    renderBgButton = ({ dialogIds, actions, dispatch, autoColorMode }) => <BackgroundButton
        // @ts-ignore
        showColorSettings={autoColorMode !== true}
        dialogId={dialogIds.background}
        colorGradientSettingsActions={{
            colorChangeAction: actions.BG_COLOR_CHANGED,
            colorRemoveAction: actions.BG_COLOR_REMOVED,
            gradientColorChangeAction: actions.BG_GRADIENT_CHANGED,
            gradientColorRemoveAction: actions.BG_GRADIENT_CANCELLED,
            gradientDirectionChangeAction: actions.BG_GRADIENT_DIRECTION_CHANGED, // eslint-disable-line max-len
            gradientFadePointChangeAction: actions.BG_GRADIENT_FADEPOINT_CHANGED, // eslint-disable-line max-len
            opacityChangeAction: actions.BG_COLOR_OPACITY_CHANGED
        }}
        backgroundSettingsActions={{
            assetChangeAction: actions.BG_ASSET_CHANGED,
            assetRemoveAction: actions.BG_ASSET_REMOVED,
            assetPositionChangeAction: actions.BG_ASSET_POSITION_CHANGED,
            assetRepeatChangeAction: actions.BG_ASSET_REPEAT_CHANGED,
            assetSizeChangeAction: actions.BG_ASSET_SIZE_CHANGED
        }}
        dispatch={dispatch}
    />,
    renderSpacingOrCorners = ({ data, actions, dialogIds, dispatch, ...props }) => (
        <div>
            {data.corners && (
                <BorderCornerButton
                    corners={data.corners}
                    cornerChangeAction={actions.BORDER_RADUIS_CHANGED}
                    dispatch={dispatch}
                />
            )}
            {data.spacing && dialogIds.spacing && (
                <CellSpacingButton
                    dialogId={dialogIds.spacing}
                    spacing={data.spacing}
                    spacingChangeAction={actions.PADDING_CHANGED}
                    dispatch={dispatch}
                    // @ts-ignore
                    additionalDialogProps={props}
                />
            )}
        </div>
    );

export const
    Tab = injectIntl(({ intl }) => (<div id="menu-globalstyles-tab">{intl.msgJoint(menuTabLabel)}</div>)),
    DROPDOWN_SELECTOR_WIDTH = 120,
    verticalSpaceByType = {
        [Types.NORMAL]: 112,
        [Types.EXPANDABLE]: 116,
        [Types.EXPANDED]: 140,
        [Types.SELECTED]: 112,
        [Types.MOUSE_OVER]: 112
    },
    TabPanel = injectIntl(({
        scrollHeight,
        previewScrollHeight,
        stylesheets,
        stylesheetsIdToNameMap,
        currentStylesheet,
        siteFonts,
        intl,
        dispatch,
        themeSettingsData
    }: MenuGlobalstyleDialogProps) => {
        const
            { item } = currentStylesheet,
            fontFamily = selectors.getFontFamily(path.inactive)(item),
            bold = selectors.getFontBold(path.inactive)(item),
            italic = selectors.getFontItalic(path.inactive)(item),
            fontSize = selectors.getFontSize(path.inactive)(item),
            alignment = selectors.getTextAlignment(path.inactive)(item),
            textTransform = selectors.getTextTransform(path.inactive)(item),
            spacing = selectors.getBlockPadding(path.inactive)(item),
            treeMenuTextIndent = selectors.menuTreeTextIndent(currentStylesheet),
            { autoColorMode } = themeSettingsData,
            selectedParentTheme = autoColorMode ? BACKGROUND_THEME_WHITE : '',
            currentStylesheetId = selectors.getStyleId(currentStylesheet),
            globalstyleHoverClassName = getGlobalstyleHoverClassName(currentStylesheetId);

        const
            types1 = [Types.NORMAL, Types.EXPANDABLE, Types.EXPANDED, Types.SELECTED, Types.MOUSE_OVER],
            types2 = [Types.DIVIDER, Types.LEVEL_CONTAINER],
            expandableOrExpandedAndUseDropdownArrowEnabled = (type, data) =>
                (type === Types.EXPANDABLE || type === Types.EXPANDED)
                && data.showDropdownArrow === true,
            renderStyleSection = ({ index, dialogIds, messages, data, actions, type }) => (
                <Flex
                    className={cx(
                        styles.otherCommonStyles,
                        styles.defaultStyles,
                        styles.borderLeft,
                        styles.ml10,
                        type === Types.MOUSE_OVER ? styles.menuMouseOverBox : null
                    )}
                    key={index}
                >
                    <Box><HorizontalSpacer /></Box>
                    <Box>
                        <VerticalSpacer y={28} />
                        <div className={styles.title}>{intl.msgJoint(messages.title)}</div>
                        <VerticalSpacer y={10} />
                        <div className={styles.subtitle}>{intl.msgJoint(messages.subtitle)}</div>
                        <VerticalSpacer y={10} />
                        <GrayBgContainer className={styles.globalStylesMenuGrayBox}>
                            <Flex align="center" justify="flex-start">
                                {
                                    data.showDropdownArrow !== undefined && <div>
                                        <CheckBoxWithLabel
                                            isChecked={data.showDropdownArrow}
                                            onClick={() => dispatch({
                                                type: actionTypes.GS_MENU_EXPANDABLE_SHOW_DROPDOWN_CHECKBOX_PRESSED
                                            })}
                                            label="msg: gs.menu.showDropdownArrow {Use dropdown arrow}"
                                        />
                                        {
                                            !expandableOrExpandedAndUseDropdownArrowEnabled(type, data)
                                            && <VerticalSpacer y={10} />
                                        }
                                    </div>
                                }
                            </Flex>
                            <div>
                                {
                                    (type === Types.EXPANDABLE && data.showDropdownArrow !== true) ?
                                        <div className={styles.dropDownSubtitle}>
                                            {intl.msgJoint(Messages[Types.EXPANDABLE].dropDownSubtitle)}
                                        </div> : <div />
                                }
                                {
                                    data.showDropdownArrow === true ?
                                        <div /> :
                                        <div>
                                            {expandableOrExpandedAndUseDropdownArrowEnabled(type, data) &&
                                                <div className={styles.subLabel}>
                                                    {intl.msgJoint('msg: gs.menu.expandable.dropdown.sublabel {You are not using the default dropdown arrow. The image and colour defined in "Background" is used instead.}')}
                                                </div>}
                                            <Flex>
                                                {renderBgButton({ dialogIds, actions, dispatch, autoColorMode })}
                                                <HorizontalSpacer />
                                                {renderSpacingOrCorners({ data, actions, dialogIds, dispatch, showTopAndBottomElements: (type !== Types.EXPANDABLE) })}
                                            </Flex>
                                            <VerticalSpacer y={10} />
                                        </div>
                                }
                                {
                                    ((type === Types.EXPANDED && data.showDropdownArrow !== true) ||
                                        (type !== Types.EXPANDABLE && type !== Types.EXPANDED))
                                            && <Flex>
                                                {
                                                    !autoColorMode &&
                                                    <React.Fragment>
                                                        <ColorProperty
                                                            color={data.textColor}
                                                            style={{ width: 122 }}
                                                            label="msg: common.textColor {Text colour}"
                                                            onChangeAction={actions.TEXT_COLOR_CHANGED}
                                                            dispatch={dispatch}
                                                        />
                                                        <HorizontalSpacer x={11} />
                                                    </React.Fragment>
                                                }
                                                <TextFormatButtonGroup
                                                    underline={{
                                                        isSelected: data.underline,
                                                        onClickAction: actions.TOGGLE_UNDERLINE
                                                    }}
                                                    dispatch={dispatch}
                                                />
                                                {
                                                    !autoColorMode &&
                                                    <React.Fragment>
                                                        <HorizontalSpacer x={10} />
                                                        <TextShadowButton
                                                            dialogId={dialogIds.textShadow}
                                                            currentStylesheetId={currentStylesheetId}
                                                            shadowColorChangeAction={actions.TEXT_SHADOW_COLOR_CHANGED}
                                                            shadowRemoveAction={actions.TEXT_SHADOW_COLOR_REMOVED}
                                                            blurRadiusChangeAction={actions.TEXT_SHADOW_BLUR_RADIUS_CHANGED}
                                                            shadowOffsetXChangeAction={actions.TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED} // eslint-disable-line max-len
                                                            shadowOffsetYChangeAction={actions.TEXT_SHADOW_VERTICAL_OFFSET_CHANGED}
                                                            dispatch={dispatch}
                                                        />
                                                    </React.Fragment>
                                                }
                                            </Flex>
                                }
                            </div>
                        </GrayBgContainer>
                    </Box>
                    <Box><HorizontalSpacer x={30} /></Box>
                </Flex>
            ),
            renderBoxStyle = ({ index, dialogIds, messages, data, actions }) => (
                <Flex key={index}>
                    <Box>
                        <VerticalSpacer y={28} />
                        <div style={{ width: 404 }} className={styles.title}>{intl.msgJoint(messages.title)}</div>
                        <VerticalSpacer y={10} />
                        <div className={styles.subtitle}>{intl.msgJoint(messages.subtitle)}</div>
                        <VerticalSpacer y={10} />
                        <GrayBgContainer className={styles.globalStylesMenuGrayBox}>
                            <Flex align="center" justify="flex-start">
                                {
                                    !autoColorMode &&
                                    <React.Fragment>
                                        <BorderStyleButton
                                            border={data.borderStyle}
                                            dialogId={dialogIds.borderStyle}
                                            borderStyleChangeAction={actions.BORDER_STYLE_CHANGED}
                                            borderColorChangeAction={actions.BORDER_COLOR_CHANGED}
                                            borderColorRemoveAction={actions.BORDER_COLOR_REMOVED}
                                            borderColorOpacityChangeAction={actions.BORDER_COLOR_OPACITY_CHANGED}
                                            borderWidthChangeAction={actions.BORDER_WIDTH_CHANGED}
                                            dispatch={dispatch}
                                        />
                                        <HorizontalSpacer />
                                    </React.Fragment>
                                }
                                <CellSpacingButton
                                    dialogId={dialogIds.spacing}
                                    spacing={data.spacing}
                                    spacingChangeAction={actions.PADDING_CHANGED}
                                    dispatch={dispatch}
                                />
                            </Flex>
                            <VerticalSpacer y={10} />
                            <Flex align="center" justify="flex-start">
                                <BackgroundButton
                                    // @ts-ignore
                                    showColorSettings={autoColorMode !== true}
                                    dialogId={dialogIds.background}
                                    colorGradientSettingsActions={{
                                        colorChangeAction: actions.BG_COLOR_CHANGED,
                                        colorRemoveAction: actions.BG_COLOR_REMOVED,
                                        gradientColorChangeAction: actions.BG_GRADIENT_CHANGED,
                                        gradientColorRemoveAction: actions.BG_GRADIENT_CANCELLED,
                                        gradientDirectionChangeAction: actions.BG_GRADIENT_DIRECTION_CHANGED,
                                        gradientFadePointChangeAction: actions.BG_GRADIENT_FADEPOINT_CHANGED,
                                        opacityChangeAction: actions.BG_COLOR_OPACITY_CHANGED
                                    }}
                                    backgroundSettingsActions={{
                                        assetChangeAction: actions.BG_ASSET_CHANGED,
                                        assetRemoveAction: actions.BG_ASSET_REMOVED,
                                        assetPositionChangeAction: actions.BG_ASSET_POSITION_CHANGED,
                                        assetRepeatChangeAction: actions.BG_ASSET_REPEAT_CHANGED,
                                        assetSizeChangeAction: actions.BG_ASSET_SIZE_CHANGED
                                    }}
                                    dispatch={dispatch}
                                />
                                <HorizontalSpacer />
                                <BorderCornerButton
                                    corners={data.corners}
                                    cornerChangeAction={actions.BORDER_RADUIS_CHANGED}
                                    dispatch={dispatch}
                                />
                            </Flex>
                        </GrayBgContainer>
                    </Box>
                    <Box><HorizontalSpacer x={30} /></Box>
                </Flex>
            );

        return (
            <Box className={styles.tabPanelContents}>
                <TopBar
                    messages={{
                        select: 'msg: gs.selectToStyle.menu {Select a menu style to edit}',
                        duplicate: 'msg: gs.duplicateStyle.menu {New menu style (Duplicate)}',
                        delete: 'msg: gs.deleteStyle.menu {Delete this menu style}'
                    }}
                    globalStyleSelectorChangeAction={actionTypes.GS_MENU_GLOBAL_STYLE_SELECTED}
                    optionMapper={(styles, intl) => optionMapper(styles, intl, (autoColorMode && ThemeStyleMenuType) || '')}
                    stylesheets={stylesheets}
                    currentStylesheetId={currentStylesheetId}
                    autoColorMode={autoColorMode}
                    dispatch={dispatch}
                    intl={intl}
                />
                <Flex className={cx(styles.tabPanel, styles.styleSelectorsStylePreview)}>
                    <div className={styles.styleSelectors}>
                        <Scrollbar height={`${scrollHeight}px`}>
                            <GrayBgContainer className={cx(styles.borderBottom, styles.mainStyleSelectors)}>
                                <Flex align="center" justify="flex-start">
                                    <FontFamilySelector
                                        selected={fontFamily}
                                        siteFonts={siteFonts}
                                        onChangeAction={actionTypes.GS_MENU_FONT_FAMILY_CHANGED}
                                        additionalPayload={{ source: MenuGlobalStyleKind }}
                                    />
                                    <HorizontalSpacer />
                                    <TextFormatButtonGroup
                                        bold={{ isSelected: bold, onClickAction: actionTypes.GS_MENU_TOGGLE_BOLD }}
                                        italic={{
                                            isSelected: italic,
                                            onClickAction: actionTypes.GS_MENU_TOGGLE_ITALIC
                                        }}
                                        dispatch={dispatch}
                                    />
                                </Flex>
                                <VerticalSpacer y={10} />
                                <Flex align="center" justify="flex-start">
                                    <FontSizeSelector
                                        selected={fontSize}
                                        onChangeAction={actionTypes.GS_MENU_FONT_SIZE_CHANGED}
                                        dispatch={dispatch}
                                        searchable={false}
                                    />
                                    <HorizontalSpacer />
                                    <TextHorizontalAlignmentButtonsGroup
                                        value={alignment}
                                        onBtnClick={alignment => dispatch({
                                            type: actionTypes.GS_MENU_TEXT_ALIGN_CHANGED,
                                            payload: alignment
                                        })}
                                    />
                                    <HorizontalSpacer />
                                    <ChangeLetterCaseButtonGroup
                                        lowercase={{
                                            isSelected: (textTransform === TextTransform.lowercase),
                                            onClickAction: actionTypes.GS_MENU_TEXT_TRANSFORM_LOWERCASE
                                        }}
                                        uppercase={{
                                            isSelected: (textTransform === TextTransform.uppercase),
                                            onClickAction: actionTypes.GS_MENU_TEXT_TRANSFORM_UPPERCASE
                                        }}
                                        dispatch={dispatch}
                                    />
                                </Flex>
                                <VerticalSpacer y={10} />
                                <Flex align="center" justify="flex-start">
                                    {
                                        !autoColorMode &&
                                        <React.Fragment>
                                            <BorderStyleButton
                                                dialogId={borderStyleConfigurationDialogId}
                                                borderStyleChangeAction={actionTypes.GS_MENU_BORDER_STYLE_CHANGED}
                                                borderColorChangeAction={actionTypes.GS_MENU_BORDER_COLOR_CHANGED}
                                                borderColorRemoveAction={actionTypes.GS_MENU_BORDER_COLOR_REMOVED}
                                                borderColorOpacityChangeAction={
                                                    actionTypes.GS_MENU_BORDER_COLOR_OPACITY_CHANGED
                                                }
                                                borderWidthChangeAction={actionTypes.GS_MENU_BORDER_WIDTH_CHANGED}
                                                dispatch={dispatch}
                                            />
                                            <HorizontalSpacer />
                                        </React.Fragment>
                                    }
                                    <CellSpacingButton
                                        dialogId={spacingConfigurationDialogId}
                                        spacing={spacing}
                                        spacingChangeAction={actionTypes.GS_MENU_CELL_SPACING_CHANGED}
                                        dispatch={dispatch}
                                    />
                                </Flex>
                            </GrayBgContainer>
                            {
                                types1.map(
                                    (type, index) => {
                                        return renderStyleSection({
                                            index,
                                            dialogIds: DialogIds[type],
                                            messages: Messages[type],
                                            data: getDataForStyle(currentStylesheet, type),
                                            actions: getActionForStyle(type),
                                            type
                                        });
                                    }
                                )
                            }
                            {
                                types2.map(
                                    (type, index) => renderBoxStyle({
                                        index,
                                        dialogIds: DialogIds[type],
                                        messages: Messages[type],
                                        data: getDataForStyle(currentStylesheet, type),
                                        actions: getActionForStyle(type)
                                    })
                                )
                            }
                            <Flex>
                                <Box>
                                    <VerticalSpacer y={28} />
                                    <div className={styles.title}>
                                        {intl.msgJoint(Messages[Types.MULTIPLE_LEVELS].title)}
                                    </div>
                                    <VerticalSpacer y={10} />
                                    <div className={styles.subtitle}>
                                        {intl.msgJoint(Messages[Types.MULTIPLE_LEVELS].subtitle)}
                                    </div>
                                    <VerticalSpacer y={10} />
                                    <GrayBgContainer className={styles.globalStylesMenuGrayBox}>
                                        <Flex justify="space-between" style={{ alignItems: 'center' }}>
                                            <Msg k="gs.menu.treeTextIndent">Tree menu text indent</Msg>
                                            <div style={{ width: DROPDOWN_SELECTOR_WIDTH }}>
                                                <Combobox
                                                    value={treeMenuTextIndent}
                                                    searchable={false}
                                                    options={TreeMenuTextIndentOptions}
                                                    onChange={({ value }) => dispatch({
                                                        type: actionTypes.GS_MENU_TREE_MENU_TEXT_INDENT_CHANGED,
                                                        payload: value
                                                    })}
                                                    style={{ width: DROPDOWN_SELECTOR_WIDTH }}
                                                />
                                            </div>
                                        </Flex>
                                        <VerticalSpacer y={10} />
                                    </GrayBgContainer>
                                </Box>
                                <HorizontalSpacer x={40} />
                            </Flex>
                        </Scrollbar>
                    </div>
                    <Box className={cx(styles.stylePreview, styles.borderLeft)} onClick={e => e.stopPropagation()}>
                        <div className={styles.previewLabel}>{intl.msgJoint('msg: common.preview.noun {Preview}')}</div>
                        <Scrollbar height={`${previewScrollHeight}px`}>
                            <div className={styles.menuStyleLabel}>
                                <Msg k="common.verticalMenu">Vertical menu</Msg>
                            </div>
                            <VerticalSpacer />
                            <Flex align="center" justify="center">
                                {makeVerticalMenuPreview(
                                    currentStylesheetId,
                                    stylesheetsIdToNameMap,
                                    [currentStylesheetId, globalstyleHoverClassName],
                                    intl,
                                    selectedParentTheme,
                                    autoColorMode
                                )}
                            </Flex>
                            <VerticalSpacer />
                            <div className={styles.menuStyleLabel}>
                                <Msg k="common.horizontalMenu">Horizontal menu</Msg>
                            </div>
                            <VerticalSpacer />
                            <Flex align="center" justify="center">
                                {makeHorizontalMenuPreview(
                                    currentStylesheetId,
                                    stylesheetsIdToNameMap,
                                    [currentStylesheetId, globalstyleHoverClassName],
                                    intl,
                                    selectedParentTheme,
                                    autoColorMode
                                )}
                            </Flex>
                            <VerticalSpacer />
                        </Scrollbar>
                    </Box>
                </Flex>
            </Box>
        );
    });
