import React from 'react';
import View from './index';
import BaseComponent from '../../../../view/oneweb/BaseComponent';
import ComponentMask from "../../../presentational/ComponentMask/index";
import { ComponentMaskBackground } from "../../../presentational/ComponentMask/constants";

export default (props: any) => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <BaseComponent {...props}>
            <ComponentMask maskStyle={ComponentMaskBackground.LIGHT} {...props}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <View {...props} />
            </ComponentMask>
        </BaseComponent>
    );
};
