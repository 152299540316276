/* eslint-disable max-len */

import * as R from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import isStretchComponentKind from '../../../oneweb/isStretchComponentKind';
import { IDLE, MOVING_COMPONENTS_BY_ARROW_KEYS } from '../componentsEval/userInteractionMutations/interactionModes';
import {
    ReceiveOnlyComponentsMapNoGhostsSelector,
    ReceiveOnlySelectedComponentsIdsNoGhostsFromFullActionSelector,
    ReceiveOnlyComponentsMapNoGhostsFromFullActionSelector,
    userInteractionModeSelector
} from '../componentsEval/selectorActionTypes';
import {
    ReceiveOnlyTemplateWidthActionType,
    ReceiveOnlyTemplateWidthFromFullActionSelector
} from '../../../oneweb/Template/epics/template/selectorActionTypes';
import valueActionType from './valueActionType';
import { roundWithTolerance } from "../../../../utils/snapping/index";
import { CANCEL_SCHEDULED_ACTION } from "../../../../redux/middleware/schedule/actionTypes";
import { withSelector } from "../../../../epics/makeCondition";
import {
    MOVED_BY_KEYBOARD,
    MOVED_BY_MOUSE,
    RESIZED_BY_MOUSE,
    RESIZED_FROM_PROP_PANEL,
    SHIFTBAR,
    COMPONENT_ADDED, PASTE
} from "../componentsEval/updateReasons";
import { emptyArray } from "../../../../utils/handle/makeHandle";
import { createScheduledAction } from "../../../../redux/middleware/schedule/actionCreators";
import { ComponentsEvalValueActionType } from "../componentsEval/valueActionType";

import { addInfoMessage } from '../../../Toaster/actionCreators';
import { ADD_MESSAGE } from '../../../Toaster/actionTypes';

const
    defaultState = null,
    setUserInteractionComponentIds = R.assoc('userInteractionComponentIds'),
    SOURCE_COMPONENT_OUTSIDE_TEMPLATE_LINES = 'SOURCE_COMPONENT_OUTSIDE_TEMPLATE_LINES',
    updateReasonsForComponentOutsideWithoutTimer = [
        MOVED_BY_MOUSE,
        RESIZED_BY_MOUSE,
        SHIFTBAR,
        COMPONENT_ADDED,
        PASTE
    ];

function checkForComponentOutsideTemplateArea(userInteractionComponentIds, componentsMap, templateWidth, scope) {
    const
        hideWarning = (scope.shownCount >= 2),
        componentOutsideTemplate = userInteractionComponentIds.some(
            id => {
                if (!componentsMap[id]) {
                    return false;
                }
                const { kind, left, width, stretch = false } = componentsMap[id],
                    roundedWidth = roundWithTolerance(width);
                return isStretchComponentKind(kind, stretch)
                    ? false
                    : (left < 0 || left > templateWidth || (left + roundedWidth) > templateWidth);
            }
        );

    return {
        state: null,
        scope: setUserInteractionComponentIds(emptyArray, scope),
        actionToDispatch: (!hideWarning && componentOutsideTemplate)
            ? createScheduledAction({
                actionToDispatch: addInfoMessage(
                    "common.componentOutsideTemplate.toasterMessage",
                    "msg: common.componentOutsideTemplate.toasterMessage {You placed an element outside the gridlines. It might not show on smaller screens.}",
                    SOURCE_COMPONENT_OUTSIDE_TEMPLATE_LINES
                ),
                timeout: 0
            })
            : null
    };
}

function getCancelActionToDispatch(type) {
    return {
        type: CANCEL_SCHEDULED_ACTION,
        payload: {
            actionToDispatch: { type, payload: null }
        }
    };
}

export const CHECK_FOR_COMPONENT_OUTSIDE_ACTION_TYPE = 'CHECK_FOR_COMPONENT_OUTSIDE_ACTION_TYPE';

export default makeEpic({
    defaultState,
    defaultScope: {
        shownCount: 0,
        userInteractionComponentIds: emptyArray
    },
    valueActionType,
    updaters: [{
        keepFullActions: true,
        conditions: [
            ReceiveOnlySelectedComponentsIdsNoGhostsFromFullActionSelector,
            ReceiveOnlyComponentsMapNoGhostsFromFullActionSelector,
            ReceiveOnlyTemplateWidthFromFullActionSelector,
            withSelector(ComponentsEvalValueActionType, (action) => ({
                userInteractionMode: userInteractionModeSelector(action.payload),
                updateReason: action.epicUpdateReason
            })),
        ],
        reducer: (
            {
                values: [
                    selectedComponentsIds,
                    componentsMap,
                    templateWidth,
                    { userInteractionMode, updateReason },
                ],
                state,
                scope
            }
        ) => {
            // TODO: WBTGEN-6527: Use new user interaction for RESIZED_FROM_PROP_PANEL using arrow keys and trigger the popup.
            if (userInteractionMode === IDLE) {
                if (updateReasonsForComponentOutsideWithoutTimer.indexOf(updateReason) > -1) {
                    return checkForComponentOutsideTemplateArea(
                        selectedComponentsIds,
                        componentsMap,
                        templateWidth,
                        scope
                    );
                }

                if (updateReason === MOVED_BY_KEYBOARD || updateReason === RESIZED_FROM_PROP_PANEL) {
                    return {
                        state,
                        scope: setUserInteractionComponentIds(selectedComponentsIds, scope),
                        actionToDispatch: createScheduledAction({
                            actionToDispatch: { type: CHECK_FOR_COMPONENT_OUTSIDE_ACTION_TYPE },
                            timeout: 1000
                        })
                    };
                }
            }

            if (userInteractionMode === MOVING_COMPONENTS_BY_ARROW_KEYS) {
                return {
                    state,
                    scope,
                    actionToDispatch: getCancelActionToDispatch(CHECK_FOR_COMPONENT_OUTSIDE_ACTION_TYPE)
                };
            }
            return { state, scope };
        }
    }, {
        conditions: [
            ReceiveOnlyComponentsMapNoGhostsSelector,
            ReceiveOnlyTemplateWidthActionType,
            CHECK_FOR_COMPONENT_OUTSIDE_ACTION_TYPE
        ],
        reducer: ({ values: [componentsMap, templateWidth], scope }) => {
            return checkForComponentOutsideTemplateArea(
                scope.userInteractionComponentIds,
                componentsMap,
                templateWidth,
                scope
            );
        }
    }, {
        conditions: [ADD_MESSAGE],
        reducer: ({ values: [message], scope, state }) => {
            return {
                scope: R.evolve(
                    {
                        shownCount: (shownCount) => {
                            if (message.source === SOURCE_COMPONENT_OUTSIDE_TEMPLATE_LINES) {
                                return shownCount + 1;
                            }
                            return shownCount;
                        }
                    },
                    scope
                ),
                state
            };
        }
    }]
});
