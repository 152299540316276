import * as ActionTypes from './actionTypes';

export const
    openMobileViewEditorClicked = () => ({ type: ActionTypes.OPEN_MOBILE_VIEW_EDITOR_CLICKED }),
    mobileViewEditorPageDataLoadSuccess = () => ({ type: ActionTypes.MOBILE_VIEW_EDITOR_PAGE_DATA_LOAD_SUCCESS }),
    closeMobileViewEditorClicked = () => ({ type: ActionTypes.BACK_TO_DESKTOP_EDITOR_FROM_MOBILE_EDITOR }),
    mobileEditorActionsMouseHoverAC = () => ({ type: ActionTypes.MOBILE_EDITOR_ARROW_MOUSE_OVER }),
    mobileEditorActionsMouseOutAC = () => ({ type: ActionTypes.MOBILE_EDITOR_ARROW_MOUSE_OUT }),
    removeOutlineAndSelectionMobileViewEditorAC = () =>
        ({ type: ActionTypes.MOBILE_EDITOR_REMOVE_OUTLINE_AND_SELECTION }),
    unFocusMveWorkspaceAC = () => ({ type: ActionTypes.MOBILE_EDITOR_WORKSPACE_UNFOCUSED }),
    deleteKeyPressedAC = () => ({ type: ActionTypes.MOBILE_EDITOR_DELETE_KEY_PRESSED }),
    mobileEditorActionHideClickedAC = (selectedId: string) =>
        ({ type: ActionTypes.MOBILE_EDITOR_ACTION_HIDE_CLICKED, payload: selectedId }),
    mobileEditorHideComponentsAC =
        (componentId: string) => ({ type: ActionTypes.MOBILE_EDITOR_HIDE_COMPONENTS, payload: { componentId } }),
    betaHoverAC = () => ({ type: ActionTypes.MOBILE_EDITOR_BETA_HOVER });
