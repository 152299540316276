import CALL_API from "../../../redux/middleware/api/CALL_API";
import { BACKUP_RECORDS_FAIL, BACKUP_RECORDS_REQUEST, BACKUP_RECORDS_SUCCESS } from "../actionTypes";
import type { ApiAction } from "../../../redux/middleware/api/flowTypes";

export default function (): any {
    const action: ApiAction = {
        [CALL_API]: {
            types: [
                BACKUP_RECORDS_REQUEST,
                BACKUP_RECORDS_SUCCESS,
                BACKUP_RECORDS_FAIL
            ],
            endpoint: 'getBackupRecords'
        }
    };
    return action;
}
