import * as ActionTypes from "../actions";
import { DataPageLayout, DataPageTemplateRef } from "../../../../../dal/model";
import { NewPageTab } from "../constants";

const openNewPageDialog = (reload: boolean = true, activeTab: string = NewPageTab.LAYOUT_PAGE) => ({
    type: ActionTypes.NEW_PAGE_DIALOG_OPEN,
    payload: { reload, activeTab }
});

const selectPageLayoutAction = (layout: DataPageLayout) => ({
    type: ActionTypes.NEW_PAGE_LAYOUT_SELECTED,
    payload: layout
});

const addLayoutPage = (
    layout: DataPageLayout,
    template: DataPageTemplateRef | null | undefined,
    componentsMap: Record<string, any> | null = {}
) => ({
    type: ActionTypes.NEW_PAGE_LAYOUT_ADD,
    payload: { layout, template, componentsMap }
});

const newPageLayoutNameEnteredAction = (name: string) => ({
    type: ActionTypes.NEW_PAGE_LAYOUT_NAME_ENTERED,
    payload: name
});

const newLayoutPageCreated = (pageRefId: string) => ({
    type: ActionTypes.NEW_PAGE_LAYOUT_PAGE_CREATED,
    payload: pageRefId
});

export {
    openNewPageDialog,
    selectPageLayoutAction,
    addLayoutPage,
    newPageLayoutNameEnteredAction,
    newLayoutPageCreated,
};
