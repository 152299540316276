import * as React from 'react';
import cx from 'classnames';
import { injectIntl, MsgJointInput, Intl } from '../../../intl/index';
import styles from './Dialog.css';
import tabStyles from '../TabDialog/TabDialog.css';
import { isMsgJointInput } from "../../../intl/injectIntl";

export type PropTypes = {
    title?: MsgJointInput | React.ReactNode,
    intl: Intl,
    subTitle?: MsgJointInput,
    subTitleContent?: React.JSX.Element,
    iconClass?: string,
    iconPosition?: string,
    newStylesV2?: boolean,
    titleIconCmp?: React.JSX.Element,
    className?: string,
    children?: React.JSX.Element,
    titleContainerClass?: string,
    titleClass?: string,
    extraClass?: string,
    subTitleClass?: string
}

const IconPosition = { LEFT: 'left', RIGHT: 'right' };

export default injectIntl(({
    title: inTitle,
    subTitle,
    subTitleContent,
    newStylesV2,
    titleIconCmp,
    iconClass = '',
    iconPosition = IconPosition.LEFT,
    className: inClassName = '',
    children,
    titleContainerClass = '',
    titleClass = '',
    subTitleClass = '',
    extraClass = '',
    intl
}: PropTypes) => {
    const
        title = isMsgJointInput(inTitle) ? intl.msgJoint(inTitle) : inTitle,
        className = cx(
            styles.titleBox,
            inClassName,
            {
                [tabStyles.withSubTitle]: subTitle || subTitleContent,
                [styles.titleBoxNewStyles]: newStylesV2
            }
        );

    const parts = [<div key="title" className={titleClass}>{title}</div>];
    if (iconPosition === IconPosition.LEFT) {
        if (titleIconCmp) {
            parts.unshift(titleIconCmp);
        }
        if (iconClass) {
            parts.unshift(<div key="icon" className={cx(styles.titleIcon, iconClass)} />);
        }
    } else {
        if (titleIconCmp) {
            parts.push(titleIconCmp);
        }
        if (iconClass) {
            parts.push(<div key="icon" className={cx(styles.titleIcon, iconClass)} />);
        }
    }

    return (
        <div className={className}>
            <div className={titleContainerClass}>
                <div className={cx(
                    styles.title,
                    { [styles.titleNewStyles]: newStylesV2 },
                    extraClass
                )}
                >
                    {parts}
                </div>
                {subTitle && <div className={cx(
                    styles.subTitle,
                    { [styles.subTitleNewStyles]: newStylesV2 },
                    subTitleClass
                )}
                >{intl.msgJoint(subTitle)}</div>}
                {subTitleContent && <div className={cx(styles.subTitle, subTitleClass)}>{subTitleContent}</div>}
            </div>
            {children}
        </div>
    );
});

export { IconPosition };
