/* eslint-disable max-len */

export const
    GALLERY_SET_IMAGES_ON_COMPONENT_DROPED = 'GALLERY_SET_IMAGES_ON_COMPONENT_DROPED',
    GALLERY_PP_COLUMNS_CHANGED = 'GALLERY_PP_COLUMNS_CHANGED',
    GALLERY_PP_SPACING_CHANGED = 'GALLERY_PP_SPACING_CHANGED',
    GALLERY_IS_CROP_CHECKBOX_CLICKED = 'GALLERY_IS_CROP_CHECKBOX_CLICKED',
    GALLERY_CAPTIONS_ENABLED_CHECKBOX_CLICKED = 'GALLERY_CAPTIONS_ENABLED_CHECKBOX_CLICKED',
    GALLERY_COMPACT_VIEW = 'GALLERY_COMPACT_VIEW',
    GALLERY_FULL_WIDTH = 'GALLERY_FULL_WIDTH',
    GALLERY_PP_OPEN_SORTING_WINDOW_CLICKED = 'GALLERY_PP_OPEN_SORTING_WINDOW_CLICKED',
    GALLERY_MCTA_OPEN_SORTING_WINDOW_CLICKED = 'GALLERY_MCTA_OPEN_SORTING_WINDOW_CLICKED',
    GALLERY_ON_CLICK_ACTION_CHANGED = 'GALLERY_ON_CLICK_ACTION_CHANGED',
    GALLERY_CAPTIONS_ALIGN_CHANGED = 'GALLERY_CAPTIONS_ALIGN_CHANGED',
    GALLERY_PP_CAPTIONS_STYLE = 'GALLERY_PP_CAPTIONS_STYLE',
    GALLERY_PP_IMAGE_RATIO = 'GALLERY_PP_IMAGE_RATIO',
    GALLERY_PP_CAPTIONS_TITLE_STYLE_FONT_FAMILY_CHANGE = 'GALLERY_PP_CAPTIONS_TITLE_STYLE_FONT_FAMILY_CHANGE',
    GALLERY_PP_CAPTIONS_TITLE_STYLE_FONT_SIZE_CHANGE = 'GALLERY_PP_CAPTIONS_TITLE_STYLE_FONT_SIZE_CHANGE',
    GALLERY_PP_CAPTIONS_TITLE_STYLE_FONT_COLOR_CHANGE = 'GALLERY_PP_CAPTIONS_TITLE_STYLE_FONT_COLOR_CHANGE',
    GALLERY_PP_CAPTIONS_TITLE_STYLE_FONT_COLOR_CHANGE_AUTO_COLOR = 'GALLERY_PP_CAPTIONS_TITLE_STYLE_FONT_COLOR_CHANGE_AUTO_COLOR',
    GALLERY_PP_CAPTIONS_TITLE_STYLE_TOGGLE_BOLD = 'GALLERY_PP_CAPTIONS_TITLE_STYLE_TOGGLE_BOLD',
    GALLERY_PP_CAPTIONS_TITLE_STYLE_TOGGLE_ITALIC = 'GALLERY_PP_CAPTIONS_TITLE_STYLE_TOGGLE_ITALIC',
    GALLERY_PP_CAPTIONS_TITLE_STYLE_TOGGLE_UNDERLINE = 'GALLERY_PP_CAPTIONS_TITLE_STYLE_TOGGLE_UNDERLINE',
    GALLERY_PP_CAPTIONS_TITLE_STYLE_LINE_HEIGHT_CHANGE = 'GALLERY_PP_CAPTIONS_TITLE_STYLE_LINE_HEIGHT_CHANGE',
    GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_FONT_FAMILY_CHANGE = 'GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_FONT_FAMILY_CHANGE',
    GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_FONT_SIZE_CHANGE = 'GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_FONT_SIZE_CHANGE',
    GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_FONT_COLOR_CHANGE = 'GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_FONT_COLOR_CHANGE',
    GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_FONT_COLOR_CHANGE_AUTO_COLOR = 'GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_FONT_COLOR_CHANGE_AUTO_COLOR',
    GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_TOGGLE_BOLD = 'GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_TOGGLE_BOLD',
    GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_TOGGLE_ITALIC = 'GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_TOGGLE_ITALIC',
    GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_TOGGLE_UNDERLINE = 'GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_TOGGLE_UNDERLINE',
    GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_LINE_HEIGHT_CHANGE = 'GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_LINE_HEIGHT_CHANGE',
    GALLERY_PP_IMAGE_BORDER_WIDTH_CHANGE = 'GALLERY_PP_IMAGE_BORDER_WIDTH_CHANGE',
    GALLERY_PP_IMAGE_BORDER_CORNER_CHANGE = 'GALLERY_PP_IMAGE_BORDER_CORNER_CHANGE',
    GALLERY_PP_IMAGE_BORDER_OPACITY_CHANGE = 'GALLERY_PP_IMAGE_BORDER_OPACITY_CHANGE',
    GALLERY_PP_IMAGE_BORDER_TYPE_CHANGE = 'GALLERY_PP_IMAGE_BORDER_TYPE_CHANGE',
    GALLERY_PP_IMAGE_BORDER_COLOR_CHANGE = 'GALLERY_PP_IMAGE_BORDER_COLOR_CHANGE',
    GALLERY_PP_IMAGE_BORDER_COLOR_CHANGE_AUTO_COLOR = 'GALLERY_PP_IMAGE_BORDER_COLOR_CHANGE_AUTO_COLOR',
    GALLERY_PP_CAPTION_BOX_BORDER_STYLE = 'GALLERY_PP_CAPTION_BOX_BORDER_STYLE',
    GALLERY_PP_CAPTION_BOX_BORDER_COLOR = 'GALLERY_PP_CAPTION_BOX_BORDER_COLOR',
    GALLERY_PP_CAPTION_BOX_BORDER_COLOR_AUTO_COLOR = 'GALLERY_PP_CAPTION_BOX_BORDER_COLOR_AUTO_COLOR',
    GALLERY_PP_CAPTION_BOX_BORDER_OPACITY = 'GALLERY_PP_CAPTION_BOX_BORDER_OPACITY',
    GALLERY_PP_CAPTION_BOX_BORDER_WIDTH = 'GALLERY_PP_CAPTION_BOX_BORDER_WIDTH',
    GALLERY_PP_CAPTION_BOX_BORDER_CORNER = 'GALLERY_PP_CAPTION_BOX_BORDER_CORNER',
    GALLERY_PP_CAPTION_BOX_BACKGROUND_COLOR_SET = 'GALLERY_PP_CAPTION_BOX_BACKGROUND_COLOR_SET',
    GALLERY_PP_CAPTION_BOX_BACKGROUND_COLOR_SET_AUTO_COLOR = 'GALLERY_PP_CAPTION_BOX_BACKGROUND_COLOR_SET_AUTO_COLOR',
    GALLERY_PP_CAPTION_BOX_BACKGROUND_OPACITY_SET_IF_UNSET = 'GALLERY_PP_CAPTION_BOX_BACKGROUND_OPACITY_SET_IF_UNSET',
    GALLERY_PP_CAPTION_BOX_BACKGROUND_COLOR_UNSET = 'GALLERY_PP_CAPTION_BOX_BACKGROUND_COLOR_UNSET',
    GALLERY_PP_CAPTION_BOX_BACKGROUND_COLOR_UNSET_AUTO_COLOR = 'GALLERY_PP_CAPTION_BOX_BACKGROUND_COLOR_UNSET_AUTO_COLOR',
    GALLERY_PP_CAPTION_BOX_BACKGROUND_OPACITY_CHANGE = 'GALLERY_PP_CAPTION_BOX_BACKGROUND_OPACITY_CHANGE',
    GALLERY_PP_CAPTION_BOX_PADDING_TOP_CHANGE = 'GALLERY_PP_CAPTION_BOX_PADDING_TOP_CHANGE',
    GALLERY_PP_CAPTION_BOX_PADDING_BOTTOM_CHANGE = 'GALLERY_PP_CAPTION_BOX_PADDING_BOTTOM_CHANGE',
    GALLERY_PP_CAPTION_BOX_PADDING_LEFT_CHANGE = 'GALLERY_PP_CAPTION_BOX_PADDING_LEFT_CHANGE',
    GALLERY_PP_CAPTION_BOX_PADDING_RIGHT_CHANGE = 'GALLERY_PP_CAPTION_BOX_PADDING_RIGHT_CHANGE',
    GALLERY_PP_FULL_WIDTH_MARGIN = 'GALLERY_PP_FULL_WIDTH_MARGIN',
    GALLERY_PP_FULL_WIDTH_MAX_WIDTH = 'GALLERY_PP_FULL_WIDTH_MAX_WIDTH',
    GALLERY_MOBILE_COLUMN_CHANGE = 'GALLERY_MOBILE_COLUMN_CHANGE',
    GALLERY_MOBILE_SPACING_CHANGE = 'GALLERY_MOBILE_SPACING_CHANGE';
