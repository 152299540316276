import * as globalStylesMapper from "../../../../../dal/pageMapAdapter/mappers/Globalstyles/index";
import { mapPageDataToComponentsMap } from "../../../../../dal/pageMapAdapter/index";
import { initializeSectionTitles } from "./getSectionTitles";
import { updateComponentsRelIns } from "../relations/updateComponentsRelIns";
import fixBBoxes from './fixBBoxes';
import { getInitializedTemplate } from '../../../oneweb/Template/epics/template/index';
import { DataSite, DataPageSet } from "../../../../../dal/model/index";
import type { Template as TemplateType } from "../../../oneweb/Template/flowTypes";
import { limitAllSizes } from "./limitSize";
import { removeLargeWhiteSpaces } from "./removeWhiteSpaces";
import { extractOldHeaderOrFooterCmps } from "../../../ModernLayouts/preview_utils";
import type { AllCmpsMapData } from "../../../ModernLayouts/flowTypes";

export default (
    site: DataSite,
    pageDataSet: DataPageSet,
    template?: TemplateType,
): AllCmpsMapData => {
    const
        mappedStylesheet = globalStylesMapper.to(pageDataSet.stylesheet),
        modifiedTemplate = template ||
            getInitializedTemplate(pageDataSet.template, pageDataSet.stylesheet),
        modifiedPageDataset = new DataPageSet({ ...pageDataSet, stylesheet: mappedStylesheet }),
        mappedComponentsMap = limitAllSizes(mapPageDataToComponentsMap(modifiedPageDataset, site)); // eslint-disable-line max-len
    let { componentsMap, ...rest } = extractOldHeaderOrFooterCmps(mappedComponentsMap);
    componentsMap = fixBBoxes(componentsMap, modifiedTemplate);
    componentsMap = removeLargeWhiteSpaces(
        initializeSectionTitles(updateComponentsRelIns(componentsMap, modifiedTemplate.width)),
        modifiedTemplate.width
    );
    return { componentsMap, ...rest };
};

export const getInitializedTemplateFn = getInitializedTemplate;
