import { DebouncedActions } from '../../view/oneweb/registry/index';

export const debounceActionWithRafMiddleware = () => {
    let animationFrameRequestId: number | null = null;
    let delayedActions: Action[] = [];

    return (next: Dispatch) => (action: Action): any => { // eslint-disable-line consistent-return
        if (DebouncedActions[action.type]) {
            delayedActions = delayedActions.filter(({ type }) => type !== action.type);
            delayedActions.push(action);
            if (!animationFrameRequestId) {
                animationFrameRequestId = window.requestAnimationFrame(() => {
                    delayedActions.forEach(a => next(a));
                    delayedActions.length = 0;
                    animationFrameRequestId = null;
                });
            }
        } else {
            return next(action);
        }
    };
};
