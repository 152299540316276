/* eslint-disable max-len */

import React from 'react';
import cx from 'classnames';
import Scrollbar from '../../../../../view/common/Scrollbar/index';
import { Msg } from '../../../../../view/intl/index';
import closeDialog from "../../../../App/actionCreators/closeDialog";
import { PUBLISH } from "../../../../App/actionTypes";
import { publishCancelledAfterErrorDialog } from "../../../actionCreators/index";
import { APP_STANDARD_ERROR_DIALOG_HEIGHT } from "../../../../App/dialogs/constants";
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import WarningDialog from '../../../../../view/common/dialogs/WarningDialog/index';
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import * as styles from './PublishErrorDialogs.css';

const ContactFormEmailValidationFailed = ({
    contactFormMissingEmails,
    dispatch
}) => {
    return (
        <WarningDialog
            title="msg: publish.emailValidationFailed.title {Missing email in contact form}"
            mctaText="msg: common.Continue {Continue}"
            mctaHandler={() => dispatch({ type: PUBLISH, payload: { force: true, forceAllowInvalidEmails: true } })}
            sctaHandler={() => { dispatch(publishCancelledAfterErrorDialog()); dispatch(closeDialog()); }}
        >
            <div>
                <Msg k="publish.emailValidationFailed.msg">
                    One or more contact forms on your website don’t have an email address attached to them. This means that you’re not able to receive any messages from these contact forms.
                </Msg>
            </div>
            <VerticalSpacer y={20} />
            <div>
                <Msg k="publish.emailValidationFailed.subMsg">
                    The following email addresses were deleted:
                </Msg>
            </div>
            <VerticalSpacer y={10} />
            <Scrollbar height="70px">
                <div className={cx(styles.containerFull, styles.desc)}>
                    <ul className={styles.list}>
                        {contactFormMissingEmails.map((email) => <li key={email}>{email}</li>)}
                    </ul>
                </div>
            </Scrollbar>
            <VerticalSpacer y={10} />
            <div>
                <Msg k="publish.emailValidationFailed.recommend">
                    You can publish your website now, but we recommend adding email addresses to your contact forms first.
                </Msg>
            </div>
        </WarningDialog>
    );
};

const dialog = getCenteredDialogConfig({
    width: styles.DialogWidth,
    height: (APP_STANDARD_ERROR_DIALOG_HEIGHT + 125), // To support - de
    component: ContactFormEmailValidationFailed
});

export default {
    ...dialog,
    updateConfFn: (config, dimensions, props) => {
        return { ...config, props: { ...config.props, ...props } };
    }
};
