export const
    GENERATE_AI_TEXT_REQUEST = "GENERATE_AI_TEXT_REQUEST",
    GENERATE_AI_TEXT_SUCCESS = "GENERATE_AI_TEXT_SUCCESS",
    GENERATE_AI_TEXT_FAILED = "GENERATE_AI_TEXT_FAILED",
    GENERATE_AI_TEXT_COMPLETE_EVENT = "GENERATE_AI_TEXT_COMPLETE_EVENT",
    GENERATE_AI_TEXT_FAILED_EVENT = "GENERATE_AI_TEXT_FAILED_EVENT",
    AI_TEXT_CLOSE_DIALOG = 'AI_TEXT_CLOSE_DIALOG',
    AI_TEXT_CONTENT_TYPE_CHANGED = 'AI_TEXT_CONTENT_TYPE_CHANGED',
    AI_TEXT_TOPIC_TYPE_CHANGED = 'AI_TEXT_TOPIC_TYPE_CHANGED',
    AI_TEXT_TOPIC_CHANGED = 'AI_TEXT_TOPIC_CHANGED',
    AI_TEXT_EXTRAS_CHANGED = 'AI_TEXT_EXTRAS_CHANGED',
    AI_TEXT_GENERATE_CLICKED = 'AI_TEXT_GENERATE_CLICKED',
    AI_TEXT_COPY_BTN_CLICKED = 'AI_TEXT_COPY_BTN_CLICKED',
    AI_TEXT_REPLACE_BTN_CLICKED = 'AI_TEXT_REPLACE_BTN_CLICKED',
    AI_TEXT_REPLACE_TEXT_CONTENT = 'AI_TEXT_REPLACE_TEXT_CONTENT',
    AI_TEXT_AGREE_TERMS_CHECKED = 'AI_TEXT_AGREE_TERMS_CHECKED',
    AI_TEXT_INPUT_CHANGED = 'AI_TEXT_INPUT_CHANGED',
    AI_TEXT_RESULTS_PANEL_SCROLLED = 'AI_TEXT_RESULTS_PANEL_SCROLLED',
    AI_TEXT_RESULTS_PANEL_SCROLLED_TO_BOTTOM = 'AI_TEXT_RESULTS_PANEL_SCROLLED_TO_BOTTOM',
    GENERAL_AI_TEXT_GLOBAL_DATA_UPDATE = 'GENERAL_AI_TEXT_GLOBAL_DATA_UPDATE',
    AI_TOOLTIP_SEEN = 'AI_TOOLTIP_SEEN';
