import { workspaceFirstLoadedAppSel } from "../../redux/modules/children/workspace/selectors";
import { AppState } from "../../redux/modules/flowTypes";
import { WorkspaceEventIds } from "../EventTracking/constants";
import { WIDGET_ADDED } from "../oneweb/Widgets/epics";
import { WORKSPACE_NEW_COMPONENT_ADDED, WORKSPACE_READY } from "./actionTypes";
import { COMPONENTS_DUPLICATED, COMPONENTS_PASTED } from "./epics/componentsEval/actionTypes";

const componentAddedEvenConfig = {
    eventName: WorkspaceEventIds.COMPONENT_ADDED
};

const WorkspaceTrackingEvents = {
    [WORKSPACE_READY]: {
        eventName: WorkspaceEventIds.ENTERED_WSB,
        isDisabled: (appState: AppState) => workspaceFirstLoadedAppSel(appState)
    },
    [WIDGET_ADDED]: componentAddedEvenConfig,
    [WORKSPACE_NEW_COMPONENT_ADDED]: componentAddedEvenConfig,
    [COMPONENTS_PASTED]: componentAddedEvenConfig,
    [COMPONENTS_DUPLICATED]: componentAddedEvenConfig
};

export {
    WorkspaceTrackingEvents
};
