import * as React from "react";
import cx from 'classnames';
import styles from "./TopBarDropdownMenuItem.css";
import type { TopBarDropdownMenuItemProps } from "../../flowTypes";

const
    TopBarDropdownMenuItem = React.forwardRef(
        (
            {
                icon,
                href,
                target,
                onClick,
                onMouseEnter,
                onMouseLeave,
                isActive,
                children,
                extraChildren,
                className,
                title,
                dataTitlePosition,
                itemTextClassName,
            }: TopBarDropdownMenuItemProps,
            ref: React.ForwardedRef<any>,
        ) => (
            <a
                className={cx(styles.item, { [styles.active]: isActive }, className)}
                href={href}
                target={target}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                data-title={title}
                data-title-position={dataTitlePosition}
                ref={ref}
            >
                <span className={cx(styles.menuItemIcon, icon)} />
                <span className={cx(styles.itemText, itemTextClassName)}>{children}</span>
                {extraChildren && <div>{extraChildren}</div>}
            </a>
        )
    ),
    TopBarDropdownMenuDivider = () => (<div className={styles.dropdownDivider}>&nbsp;</div>);

export {
    TopBarDropdownMenuItem as default,
    TopBarDropdownMenuDivider
};
