export const
    ShutterstockNormalLimitMessages = {
        title: 'msg: fm.quotaLimit.normal.title {Not enough free downloads left}',
        messages: [
            'msg: fm.quotaLimit.normal.msg1 {The number of images selected is higher than the free downloads left.}',
            'msg: fm.quotaLimit.normal.msg2 {Upgrade to {premiumPackageName} and get full access to all the free images.}'
        ]
    },
    ShutterstockDailyLimitMessages = {
        title: 'msg: fm.quotaLimit.perDay.title {You have reached your daily limit}',
        messages: [
            'msg: fm.quotaLimit.perDay.msg1 {You have reached your daily download limit of free images.}',
            'msg: common.pleaseTryAgainLater {Please try again later.}'
        ]
    },
    ShutterstockRequestLimitMessages = {
        title: 'msg: fm.quotaLimit.perRequest.title {Only {limit, number} images at a time}',
        messages: [
            'msg: fm.quotaLimit.perRequest.msg1 {You can add up to {limit, number} images in one go.}',
            'msg: fm.quotaLimit.perRequest.msg2 {If you want to add more, you can always do this later.}'
        ]
    };
