import { useState, useEffect } from "react";

export enum IframeLoadingState {
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR'
}

type IframeLoadingStateType = {
    status: IframeLoadingState;
    error: any;
    accountId: string | null | undefined;
    appSize: number | undefined;
    setError: React.Dispatch<React.SetStateAction<null>>;
};

type UseIframeLoadingStateType = {
    iframeRef: React.RefObject<HTMLIFrameElement>;
    onAccountIdReceived?: (accountId: string) => void;
};

export const useIframeLoadingState = ({ iframeRef, onAccountIdReceived }: UseIframeLoadingStateType): IframeLoadingStateType => {
    const
        [error, setError] = useState(null),
        [accountId, setAccountId] = useState(),
        [appSize, setAppSize] = useState(undefined),
        [lastChanged, setLastChanged] = useState(Date.now()),
        [fullyLoaded, setFullyLoaded] = useState(false);

    useEffect(() => {
        const messageListener = (event: MessageEvent<any>) => {
            const iframe: any = iframeRef.current;

            if (iframe && iframe.src) {
                const isIframeMessage = iframe.src.startsWith(event.origin);

                if (isIframeMessage && event.data) {
                    if (event.data.iframeError) {
                        setError(event.data.iframeError);
                    } else if (event.data.appAcctID) {
                        setAccountId(event.data.appAcctID);
                        if (onAccountIdReceived) {
                            onAccountIdReceived(event.data.appAcctID);
                        }
                    } else if (event.data.appSize) {
                        setAppSize(event.data.appSize);
                    }

                    setLastChanged(Date.now());
                }
            }
        };

        window.addEventListener('message', messageListener);

        return () => {
            window.removeEventListener('message', messageListener);
        };
    }, []);

    useEffect(() => {
        const timeoutHandle = setTimeout(() => {
            if (accountId && appSize && !error) {
                setFullyLoaded(true);
            }
        }, 1000);

        return () => {
            clearTimeout(timeoutHandle);
        };
    }, [lastChanged]);

    let status = IframeLoadingState.LOADING;

    if (error) {
        status = IframeLoadingState.ERROR;
    } else if (accountId && appSize && fullyLoaded) {
        status = IframeLoadingState.SUCCESS;
    }

    return { status, error, accountId, appSize, setError };
};
