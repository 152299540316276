import { pure } from 'recompose';
import React from 'react';
import TopBarItemMenu from "../index";
import TopBarHelpPopup from '../../Popup/TopBarHelpPopup/index';
import Msg from "../../../../../view/intl/Msg";
import type { TopBarHelpMenuProps } from "../../../flowTypes";
import styles from "../TopBarItemMenu.css";
import { HELP_BTN_CLIENT_RECT, TOP_BAR_HELP_PRESSED } from "../../../actionTypes";

export default pure(class HelpBtn extends React.Component<TopBarHelpMenuProps, void> {
    btnRef: null|Element;
    constructor(props: TopBarHelpMenuProps) {
        super(props);
        this.btnRef = null;
    }
    componentDidUpdate(prevProps: TopBarHelpMenuProps) {
        if (!prevProps.getClientRect && this.props.getClientRect && this.btnRef) {
            this.props.dispatch({ type: HELP_BTN_CLIENT_RECT, payload: this.btnRef.getBoundingClientRect() });
        }
    }
    render() {
        const {
            isShown,
            chatLoaded,
            popupContainerClassName,
            tipClassName,
            className,
            showQuickTour,
            showTutorial,
            dispatch
        } = this.props;
        return (
            <div className={styles.containerWrapper} ref={(btnRef) => { this.btnRef = btnRef; }}>
                <TopBarItemMenu
                    onClick={() => dispatch({ type: TOP_BAR_HELP_PRESSED })}
                    selected={isShown}
                    className={className}
                >
                    <Msg k="contextmenu.item.help.title">Help</Msg>
                </TopBarItemMenu>
                <TopBarHelpPopup
                    isShown={isShown}
                    chatLoaded={chatLoaded}
                    popupContainerClassName={popupContainerClassName}
                    tipClassName={tipClassName}
                    showQuickTour={showQuickTour}
                    showTutorial={showTutorial}
                    dispatch={dispatch}
                />
            </div>
        );
    }
});
