import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import { getCenterPositionForDialog } from "../../../../DialogManager/utility";
import { GmbPublishDialogView } from './index';

const
    WIDTH = 750,
    HEIGHT = 610,
    gmbPublishDialog = {
        ...getCenteredDialogConfig({
            WIDTH,
            HEIGHT,
            component: GmbPublishDialogView,
        }),
        confFactory: ({ browserWidth, browserHeight }) => {
            return {
                position: getCenterPositionForDialog(WIDTH, HEIGHT, browserWidth, browserHeight),
                modal: true,
                dimensions: { width: WIDTH, height: HEIGHT }
            };
        },
        moveToCenter: true,
    };

const GmbPublishDialogId = 'GmbPublishDialogId';

export {
    gmbPublishDialog,
    GmbPublishDialogId
};
