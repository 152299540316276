import React from 'react';
import { Box, Link } from "@mui/material";

type SettingsSectionInfoProps = {
    iconClassname?: string;
    label: string;
    description: React.ReactNode;
    link?: {
        label: string;
        url?: string;
        clickHandler?: () => void;
    };
    extra?: React.JSX.Element;
};

export const SettingsSectionInfo = ({ iconClassname, label, description, link, extra, ...otherProps }: SettingsSectionInfoProps) => {
    return (
        <Box display="flex" alignItems="flex-start" {...otherProps}>
            {
                iconClassname &&
                <Box className={iconClassname} />
            }
            <Box flex={1} marginLeft={iconClassname ? "18px" : "0px"}>
                <Box
                    component="h2"
                    fontSize={{ xs: '18px', sm: '20px' }}
                    lineHeight={{ xs: '24px', sm: '26px' }}
                    fontWeight="400"
                >
                    {label}
                </Box>
                <Box
                    component="p"
                    fontSize={{ xs: '15px', sm: '14px' }}
                    lineHeight={{ xs: '24px', sm: '22px' }}
                    fontWeight="400"
                    marginTop={{ xs: '4px', md: '10px' }}
                >
                    {description}
                </Box>
                {
                    link &&
                    <Box
                        fontSize={{ xs: '15px', sm: '14px' }}
                        lineHeight={{ xs: '24px', sm: '17px' }}
                        marginTop={{ xs: '4px', md: '18px' }}
                    >
                        <Link
                            href={link.url}
                            target="_blank"
                            onClick={link.clickHandler}
                        >
                            {link.label}
                        </Link>
                    </Box>
                }
                {extra}
            </Box>
        </Box>
    );
};
