import React from 'react';
import { connect } from 'react-redux';
import { ContextMenuView } from './view/index';
import type { AppState } from "../../redux/modules/flowTypes";

const View = ({ state, dispatch }) => (
    <div>
        {state.opened && <ContextMenuView state={state} dispatch={dispatch} />}
    </div>
);

export const view = connect(({ contextMenu: state }: AppState) => ({ state }))(View);
