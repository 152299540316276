import * as R from 'ramda';
import valueActionType from './valueActionType';
import * as ActionTypes from '../../actionTypes';
import makeEpic from "../../../../epics/makeEpic";
import { optional, receiveOnly } from "../../../../epics/makeCondition";
import {
    ComponentsMapSelector,
    ReceiveOnlyComponentsMap
} from "../../../Workspace/epics/componentsEval/selectorActionTypes";
import calculateDefaultComponentsSequence from '../../calculateDefaultComponentsSequence';
import templateValueActionType from "../../../oneweb/Template/epics/template/valueActionType";
import calcFinalMobileSequence from "../../calcFinalMobileSequence";
import pageDatasetLoadedActionType from "../../../App/epics/pageDataset/pageDatasetLoadedActionType";
import { boxTop, cmpTypes } from "../../constants";
import calcStyles from "../../calcStyles";
import * as updateReasons from '../updateReasons';
import { REDO, UNDO, UNDO_INITIAL_STATE } from "../../../../epics/undoManager/updateReasons";
import {
    MAKE_MOBILE_VIEW_EDITOR_INVISIBLE_ACTION, MAKE_MOBILE_VIEW_EDITOR_VISIBLE_ACTION
} from "./actions";
import { arrayToTrueMap } from "../../../../utils/arrayToTrueMap";
import getPageAndTemplateMobileData from "../../getPageAndTemplateMobileData";
import reorderCmps from "./reorderCmps";
import tagPageOrTemplateToRelations from "./tagPageOrTemplateToRelations";
import hoveringAndSelectionVAT from "../hoveringAndSelection/valueActionType";
import getMdStartFromId from "../getMdStartFromId";
import { mobileViewEditorPageDataLoadSuccess } from "../../actionCreators";
import * as userFocusKind from "../../../App/epics/userFocus/kind";
import { KEY_DOWN } from "../../../App/actionTypes";
import * as keyCodes from "../../../App/epics/isKeyPressed/keyCodes";
import { UserFocusValueActionType } from "../../../App/epics/userFocus/valueActionType";
import updateNewGroupsBasedOnOldGroups from "../../updateNewGroupsBasedOnOldGroups";
import getCmpTypeById from "../../getCmpTypeById";
import calcMobileSettings from "../../calcSettings";
import expandTemplateWidthAndAdjustComponents from "../../../Preview/expandTemplateWidthAndAdjustComponents";
import type { AnyComponent } from "../../../oneweb/flowTypes";
import { COPY_REPOSITORY_TEMPLATE_SUCCESS } from "../../../TemplateSelector_DEPRECATED/actionTypes";
import { BACKGROUND, MENU, STRIP, SECTION, HOVERBOX } from "../../../oneweb/componentKinds";
import { getHeaderAndFooterSection } from "../../../oneweb/Section/utils";
import { removeHeaderFooterRelations } from "./removeHeaderFooterRelations";
import { AttachmentsSelector } from "../../../Workspace/epics/componentAttachements/selectors";
import { isBoxWithNoAssetOrSmallSize } from '../../../oneweb/Background/utils';

const defaultState = {
    show: false,
    data: {},
    settings: {},
    mobileEltExtension: {},
    hiddenComponents: [],
    relations: [],
    relationsForView: [],
    styles: {},
    cmpWidths: {},
    cmpHeights: {},
    reload: false,
    isReordered: false,
    mdStartFromId: null,
    root: null,
    currentScrollTop: 0,
    groups: {},
    groupsForView: {}
};

const keyCodesToPreventInMve = [
    keyCodes.ESCAPE,
    keyCodes.DELETE,
    keyCodes.BACKSPACE,
    keyCodes.TAB,
    keyCodes.ENTER,
    keyCodes.F2,
    keyCodes.LEFT_ARROW,
    keyCodes.UP_ARROW,
    keyCodes.RIGHT_ARROW,
    keyCodes.DOWN_ARROW
];

const getChildToParentMap = (sequenceMap) => {
    let allCmpIds = {};
    Object.keys(sequenceMap).forEach(parentId => {
        sequenceMap[parentId].forEach(childId => {
            allCmpIds[childId] = parentId;
        });
    });
    return allCmpIds;
};

const isNeighboursDeleted = (neighbours, cmpIdsMap, groups) => {
    return !neighbours.some(cmpId => (cmpId === boxTop || cmpIdsMap[cmpId] || groups[cmpId]));
};

const deleteRelationsForDeletedCmps = (defaultSequence, oldRelations, groups) => {
    const childToParentMap = getChildToParentMap(defaultSequence);
    let latestRelations = [...oldRelations];

    oldRelations.forEach(({ id, top, bottom }) => {
        const neighbours = [...([top] || []), ...([bottom] || [])];

        if (!childToParentMap[id] || isNeighboursDeleted(neighbours, childToParentMap, groups)) {
            const index = latestRelations.findIndex(rel => rel.id === id);
            if (index > -1) {
                latestRelations.splice(index, 1);
            }
        }
    });
    return latestRelations;
};

const exitRelations = (relations, data, root, componentsMap, groups) => {
    let latestRelations = relations.filter(({ id }) => !(getCmpTypeById(id) === cmpTypes.group && !groups[id]));
    const processSeq = (seq) => {
        if (seq) {
            seq.forEach((cmpId, cmpIndex) => {
                const relationIndex = latestRelations.findIndex(({ id }) => id === cmpId);
                if (relationIndex !== -1) {
                    let relationObj = { ...latestRelations[relationIndex] };
                    const
                        topCmpId = relationObj.top,
                        topCmpIndex = seq.indexOf(topCmpId),
                        bottomCmpId = relationObj.bottom,
                        bottomCmpIndex = seq.indexOf(bottomCmpId);

                    if (topCmpId !== boxTop && topCmpIndex === -1) {
                        delete relationObj.top;
                    }
                    if ((bottomCmpIndex === -1) || ((bottomCmpIndex - cmpIndex) !== 1)) {
                        delete relationObj.bottom;
                    }

                    if (relationObj.top || relationObj.bottom) {
                        latestRelations[relationIndex] = relationObj;
                    } else {
                        latestRelations.splice(relationIndex, 1);
                    }
                }
                if (data[cmpId]) {
                    processSeq(data[cmpId]);
                }
            });
        }
    };

    processSeq(data[root]);
    return tagPageOrTemplateToRelations(latestRelations, componentsMap, groups);
};

const getHiddenCmpChildren = (allCmpData, hiddenComponents, cmpMap, root, allGroups) => {
    let allHiddenComponents = [...hiddenComponents];
    const defaultHiddenMobileCmp = [STRIP, MENU, SECTION];
    const hiddenMobileCmpOnEmpty = [BACKGROUND, HOVERBOX];

    Object.keys(allGroups).forEach(groupdId => {
        if (allGroups[groupdId].every(cmpId => {
            return (hiddenComponents.indexOf(cmpId) !== -1);
        })) {
            allHiddenComponents.push(groupdId);
        }
    });
    const cmpChildrenMap = new Map(),
        canShowContainer = (cmpId) => {
            const cmpChildren = allCmpData[cmpId] || [];
            return cmpChildren.some((id) => {
                if (getCmpTypeById(id) === cmpTypes.group) {
                    return true;
                }
                const cmp: AnyComponent = cmpMap[id];
                if (defaultHiddenMobileCmp.includes(cmp.kind)) {
                    return canShowContainer(id);
                }
                if (hiddenMobileCmpOnEmpty.includes(cmp.kind) && isBoxWithNoAssetOrSmallSize(cmp, true)) {
                    return canShowContainer(id);
                }
                return cmp.kind !== MENU;
            });
        },
        processChildren = (cmpIds: Array<string> = []) => {
            const hiddenChildren: any[] = [];
            cmpIds.forEach((cmpId) => {
                const children = getCmpTypeById(cmpId) === cmpTypes.group ? allGroups[cmpId] : allCmpData[cmpId];
                const kind = cmpMap[cmpId] && cmpMap[cmpId].kind;
                const cmp = cmpMap[cmpId];
                if (cmp && allHiddenComponents.includes(cmpId)) {
                    const cmpChildren = processChildren(children);
                    if (defaultHiddenMobileCmp.includes(kind) && !cmpChildren.length && !canShowContainer(cmpId)) {
                        return;
                    }
                    if (hiddenMobileCmpOnEmpty.includes(kind)) {
                        if (isBoxWithNoAssetOrSmallSize(cmp, true)
                            && !cmpChildren.length && !canShowContainer(cmpId)) {
                            return;
                        }
                    }
                    cmpChildrenMap.set(cmpId, cmpChildren);
                    hiddenChildren.push(cmpId);
                    return;
                }
                processChildren(children);
            });
            return hiddenChildren;
        };
    processChildren(allCmpData[root]);
    return cmpChildrenMap;
};

const compute = ({
        componentsMap: oldComponentsMap,
        template: oldTemplate,
        relations,
        groups,
        settings,
        allOldGroups,
        attachments
    }) => {
        const
            { componentsMap, template } = expandTemplateWidthAndAdjustComponents(oldComponentsMap, oldTemplate),
            { data: allCmpSequence, groups: allNewGroups } =
                calculateDefaultComponentsSequence(componentsMap, template, attachments, {}, true),
            result = calculateDefaultComponentsSequence(componentsMap, template, attachments, {}),
            defaultSequence = result.data,
            { wrappedCmpsMap, hiddenComponents } = result,
            root = template.id,
            { newGroups, newSequence } = updateNewGroupsBasedOnOldGroups(
                { ...allOldGroups, ...groups },
                result.groups,
                componentsMap,
                defaultSequence
            ),
            { newGroups: allGroups, newSequence: allNewCmpSequence } = updateNewGroupsBasedOnOldGroups(
                { ...allOldGroups, ...newGroups },
                allNewGroups,
                componentsMap,
                allCmpSequence
            ),
            {
                header: { id: headerId },
                footer: { id: footerId }
            } = getHeaderAndFooterSection(componentsMap),
            latestRelations = removeHeaderFooterRelations(
                deleteRelationsForDeletedCmps(newSequence, relations, newGroups),
                headerId,
                footerId,
                componentsMap
            ),
            data = calcFinalMobileSequence(newSequence, latestRelations, componentsMap, template.id, newGroups),
            finalRelations = exitRelations(latestRelations, data, root, componentsMap, newGroups),
            styles = calcStyles(root, data, componentsMap, newGroups),
            finalSettings = calcMobileSettings({ ...newGroups, ...groups }, settings, componentsMap),
            allCmpData = calcFinalMobileSequence(allNewCmpSequence, relations, componentsMap, template.id, groups);
        return {
            styles,
            relationsForView: finalRelations,
            data,
            hiddenComponents: getHiddenCmpChildren(allCmpData, hiddenComponents, componentsMap, template.id, allGroups),
            groupsForView: newGroups,
            groups: { ...newGroups, ...groups },
            allGroups: { ...newGroups, ...allGroups },
            wrappedCmpsMap,
            mdStartFromId: getMdStartFromId({ topLevelChildren: data[root], componentsMap, groups: newGroups }),
            root,
            settings: finalSettings
        };
    },
    makeScopeUpdater = ({ condition, prop }) => ({
        conditions: [condition],
        reducer: ({ values: [value], state, scope }) => {
            return {
                state,
                scope: { ...scope, [prop]: value },
                updateReason: prop === 'componentsMap' ? updateReasons.COMPONENTS_UPDATED : updateReasons.STATE_UPDATION
            };
        }
    }),
    computeReasonsMap = arrayToTrueMap([UNDO_INITIAL_STATE, UNDO, REDO, updateReasons.ENTERED_MOBILE_VIEW_EDITOR,
        updateReasons.COMPONENTS_UPDATED]),

    openMobileViewEditorClicked = (state, scope, actionToDispatch: Action | null = null) => {
        return {
            state: {
                ...state,
                show: true
            },
            scope,
            updateReason: updateReasons.ENTERED_MOBILE_VIEW_EDITOR,
            actionToDispatch
        };
    };

export default makeEpic({
    defaultState,
    defaultScope: {
        componentsMap: null,
        template: null,
        attachments: null
    },
    undo: {
        isUndoableChange: ({ updateReason }) => [
            updateReasons.MOBILE_EDITOR_COMPONENT_MOVED,
            updateReasons.MOBILE_SETTINGS_CHANGED,
            ActionTypes.MOBILE_EDITOR_TEXT_FONT_CHANGE_IN_GROUP,
            ActionTypes.MOBILE_EDITOR_GROUP_CLEAR_FORMATTING,
            ActionTypes.MOBILE_EDITOR_GROUP_IMAGE_SCALE_CHANGE
        ].includes(updateReason),
        undoablePaths: [
            ['data'],
            ['styles'],
            ['relations'],
            ['settings']
        ]
    },
    afterUpdate: ({ nextState, nextScope, updateReason }) => {
        if (nextState.show && computeReasonsMap[updateReason]) {
            return {
                state: {
                    ...nextState,
                    ...compute({
                        componentsMap: nextScope.componentsMap,
                        template: nextScope.template,
                        relations: nextState.relations,
                        groups: nextState.groups,
                        allOldGroups: nextState.allGroups,
                        settings: nextState.settings,
                        attachments: nextScope.attachments
                    }),
                    isReordered: false,
                },
                scope: nextScope
            };
        }

        if (updateReason === updateReasons.MOBILE_EDITOR_COMPONENT_MOVED) {
            return {
                state: {
                    ...nextState,
                    isReordered: true
                },
                scope: nextScope,
            };
        }

        if (nextState.isReordered) {
            return {
                state: {
                    ...nextState,
                    isReordered: false,
                },
                scope: nextScope
            };
        }

        return { state: nextState, scope: nextScope };
    },
    valueActionType,
    updaters: [
        {
            conditions: [
                pageDatasetLoadedActionType
            ],
            reducer: ({ values: [{ page, template }], scope, state: { show } }) => {
                return {
                    state: {
                        ...defaultState,
                        show,
                        reload: show,
                        ...getPageAndTemplateMobileData(page.mobileData, template.mobileData)
                    },
                    actionToDispatch: show ? mobileViewEditorPageDataLoadSuccess() : null,
                    scope,
                    updateReason: UNDO_INITIAL_STATE
                };
            }
        },
        makeScopeUpdater({ prop: 'componentsMap', condition: ComponentsMapSelector }),
        makeScopeUpdater({ prop: 'template', condition: templateValueActionType }),
        makeScopeUpdater({ prop: 'attachments', condition: AttachmentsSelector }),
        {
            conditions: [
                ActionTypes.OPEN_MOBILE_VIEW_EDITOR_CLICKED
            ],
            reducer: ({ state, scope }) => {
                const actionToDispatch = {
                    type: ActionTypes.OPEN_MOBILE_VIEW_EDITOR_WELCOME_MODAL
                };
                return openMobileViewEditorClicked(state, scope, actionToDispatch);
            }
        },
        {
            conditions: [
                ActionTypes.MOBILE_VIEW_EDITOR_PAGE_DATA_LOAD_SUCCESS
            ],
            reducer: ({ state, scope }) => {
                return openMobileViewEditorClicked(state, scope);
            }
        },
        {
            conditions: [
                COPY_REPOSITORY_TEMPLATE_SUCCESS
            ],
            reducer: ({ state, scope }) => {
                if (state.show) {
                    return {
                        state: {
                            ...state,
                            show: false
                        },
                        scope,
                        updateReason: updateReasons.LEAVING_MOBILE_VIEW_EDITOR
                    };
                }
                return { state, scope };
            }
        },
        {
            conditions: [
                MAKE_MOBILE_VIEW_EDITOR_VISIBLE_ACTION
            ],
            reducer: ({ state, scope }) => {
                return {
                    state: {
                        ...state,
                        show: true
                    },
                    scope,
                    updateReason: updateReasons.STATE_UPDATION
                };
            }
        },
        {
            conditions: [
                optional(ActionTypes.BACK_TO_DESKTOP_EDITOR_FROM_MOBILE_EDITOR),
                optional(MAKE_MOBILE_VIEW_EDITOR_INVISIBLE_ACTION)
            ],
            reducer: ({ state, scope }) => {
                return {
                    state: {
                        ...state,
                        show: false
                    },
                    scope,
                    updateReason: updateReasons.LEAVING_MOBILE_VIEW_EDITOR
                };
            }
        },
        {
            conditions: [ActionTypes.MOBILE_EDITOR_COMPONENT_WIDTHS_ON_LOAD],
            reducer: ({ values: [cmpWidths], scope, state }) => ({
                state: {
                    ...state,
                    reload: false,
                    cmpWidths
                },
                scope,
                updateReason: updateReasons.STATE_UPDATION
            })
        },
        {
            conditions: [ActionTypes.MOBILE_EDITOR_COMPONENTS_SPECIFIC_STYLES_ON_LOAD],
            reducer: ({ values: [componentsSpecificStyles], scope, state }) => ({
                state: {
                    ...state,
                    componentsSpecificStyles
                },
                scope,
                updateReason: updateReasons.STATE_UPDATION
            })
        },
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                receiveOnly(hoveringAndSelectionVAT),
                ActionTypes.MOBILE_EDITOR_COMPONENT_MOVED
            ],
            reducer: ({ values: [componentsMap, { selectedCmpId }, { children }], state, scope }) =>
                reorderCmps({
                    componentsMap,
                    selectedCmpId,
                    state,
                    scope,
                    children,
                    currentScrollTop: -1
                })
        },
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                receiveOnly(hoveringAndSelectionVAT),
                ActionTypes.MOBILE_EDITOR_MOVE_DOWN_ARROW_CLICKED
            ],
            reducer: ({
                values: [componentsMap, { selectedCmpId }, { currentScrollTop, cmpHeights }],
                state,
                scope
            }) =>
                reorderCmps({
                    componentsMap,
                    selectedCmpId,
                    currentScrollTop,
                    cmpHeights,
                    state,
                    scope,
                    down: true
                })
        },
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                receiveOnly(hoveringAndSelectionVAT),
                ActionTypes.MOBILE_EDITOR_MOVE_UP_ARROW_CLICKED
            ],
            reducer: ({
                values: [componentsMap, { selectedCmpId }, { currentScrollTop, cmpHeights }],
                state,
                scope
            }) =>
                reorderCmps({
                    componentsMap,
                    selectedCmpId,
                    currentScrollTop,
                    cmpHeights,
                    state,
                    scope,
                    up: true
                })
        },
        {
            conditions: [
                receiveOnly(UserFocusValueActionType),
                receiveOnly(hoveringAndSelectionVAT),
                KEY_DOWN
            ],
            reducer: ({ values: [userFocus, { isHeaderSelected }, { keyCode, preventDefault }], state, scope }) => {
                const mveInFocus = userFocus.kind === userFocusKind.MOBILE_EDITOR;
                if (!isHeaderSelected && mveInFocus && keyCodesToPreventInMve.indexOf(keyCode) > -1) {
                    preventDefault();
                }
                return { state, scope };
            }
        },
        {
            conditions: [
                ActionTypes.MOBILE_EDITOR_ALIGNMENT_CHANGE
            ],
            reducer: ({ values: [{ id, value }], state, scope }) => {
                let newState = state;
                if (id && state.groups[id]) {
                    newState = R.assocPath(['settings', id, 'align'], value, newState);
                }
                return {
                    state: newState,
                    scope,
                    updateReason: updateReasons.MOBILE_SETTINGS_CHANGED
                };
            }
        },
        {
            conditions: [
                ActionTypes.MOBILE_EDITOR_GROUP_IMAGE_SCALE_CHANGE
            ],
            reducer: ({ values: [{ id, value }], state, scope }) => {
                let newState = state;
                if (id && state.groups[id]) {
                    newState = R.assocPath(['settings', id, 'scale'], value, newState);
                }
                return {
                    state: newState,
                    scope,
                    updateReason: ActionTypes.MOBILE_EDITOR_GROUP_IMAGE_SCALE_CHANGE
                };
            }
        },
        {
            conditions: [
                ActionTypes.MOBILE_EDITOR_UPDATE_VIEW_DATA_TEXT_SIZE_IN_IMG_AND_TEXT_GROUP
            ],
            reducer: ({ values: [{ groupId, minSize, maxSize, oldFont }], state, scope }) => ({
                state: R.assocPath(['mobileEltExtension', groupId], { minSize, maxSize, oldFont }, state),
                scope,
                updateReason: updateReasons.STATE_UPDATION
            })
        },
        {
            conditions: [
                ActionTypes.MOBILE_EDITOR_UPDATE_VIEW_DATA_TEXT_SIZE_IN_TEXT_AND_TEXT_GROUP
            ],
            reducer: ({ values: [{ groupId, minSize, maxSize, oldFont }], state, scope }) => {
                const viewData = state.mobileEltExtension[groupId] || { minSize: 500, maxSize: -1 };
                return {
                    state: R.assocPath(
                        ['mobileEltExtension', groupId],
                        {
                            minSize: Math.min(viewData.minSize, minSize),
                            maxSize: Math.max(viewData.maxSize, maxSize),
                            oldFont
                        },
                        state
                    ),
                    scope,
                    updateReason: updateReasons.STATE_UPDATION
                };
            }
        },
        {
            conditions: [ActionTypes.MOBILE_EDITOR_REMOVE_VIEW_DATA],
            reducer: ({ values: [groupId], state, scope }) => ({
                state: R.dissocPath(['mobileEltExtension', groupId], state),
                scope,
                updateReason: updateReasons.STATE_UPDATION
            })
        },
        {
            conditions: [
                ActionTypes.MOBILE_EDITOR_TEXT_FONT_CHANGE_IN_GROUP
            ],
            reducer: ({ values: [{ id, factor }], state, scope }) => {
                const settings = { font: 0, ...(state.settings[id] || {}) };
                settings.font = settings.font + factor;
                return {
                    state: R.assocPath(['settings', id], settings, state),
                    scope,
                    updateReason: ActionTypes.MOBILE_EDITOR_TEXT_FONT_CHANGE_IN_GROUP
                };
            }
        },
        {
            conditions: [ActionTypes.MOBILE_EDITOR_GROUP_CLEAR_FORMATTING],
            reducer: ({ values: [groupId], state, scope }) => {
                return {
                    state: R.dissocPath(['settings', groupId], state),
                    scope,
                    updateReason: ActionTypes.MOBILE_EDITOR_GROUP_CLEAR_FORMATTING
                };
            }
        }
    ]
});
