import { isEmptyTextNode } from './isEmptyTextNode';
import Node from './Node';

export default (node: Node): boolean => {
    if (!/^h[123]$/.test(node.tag)) {
        return false;
    }

    if (node.childNodes.length !== 1) {
        return false;
    }

    return isEmptyTextNode(node.childNodes[0]);
};
