import * as R from "ramda";
import oneColor from "onecolor";
import { lensPath } from "../../../../src/utils/ramdaEx";
import formats from "../../../../src/components/ColorPicker/formats";
import type { Color } from "../../../../src/mappers/flowTypes";

function parseColor(color) {
    if (R.is(Array)(color)) {
        const
            [f, v1, v2, v3, a] = color,
            finalV1 = ([formats.HSL, formats.HSV].indexOf(f) > -1 && v1 > 1 ? (v1 / 360) : v1);
        return [f, finalV1, v2, v3, a];
    } else if (color === 'transparent') {
        // 'transparent' is not parsed properly by onecolor
        return [0, 0, 0, 0];
    }
    return color;
}

export const toHsl = <T extends string | Color | undefined>(color: T): T extends Color | string ? Color : null => {
    // IMPORTANT: All dirty work for handling garbage color should be done only and here only.
    if (color) {
        const
            toConvert = parseColor(color),
            colorObj = oneColor(toConvert);

        if (colorObj) {
            const
                { _hue, _saturation, _lightness, _alpha } = colorObj.hsl(),
                out: Color = ["HSL", _hue, _saturation, _lightness, _alpha];
            // @ts-ignore
            return out;
        }
    }
    // @ts-ignore
    return null;
};

export const toRgb = (color: Color): Color => oneColor(color).rgb();

export function to(path: Array<string|number>): Function {
    return (o: Record<string, any>): Record<string, any> => R.set(lensPath(path), toHsl(R.view(lensPath(path), o)), o);
}

export function back(): any {
    return undefined;
}

