import makeStyles from "@mui/styles/makeStyles";

import { MAX_CONTENT_WIDTH } from "../../../../app/theme";
import { getHeroBgKey } from "../../../../app/theme/utils";

export const useStyles = makeStyles(
    theme => ({
        root: {
            padding: "24px",
            userSelect: "none",
            backgroundColor: (props: { subscriptionType: string }) =>
                theme.palette.custom[getHeroBgKey(props.subscriptionType)],
            [theme.breakpoints.up("sm")]: {
                padding: "32px 48px"
            },
            [theme.breakpoints.up("md")]: {
                padding: "32px 48px"
            }
        },
        contentWrapper: {
            maxWidth: `${MAX_CONTENT_WIDTH}px`,
            margin: "auto",
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "flex-start",
            alignItems: "stretch",
            gap: "24px",
            overflow: "hidden",
            [theme.breakpoints.up("sm")]: {
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "20px"
            }
        },
        infoWrapper: {
            flex: 1,
            overflow: "hidden",
            padding: "4px"
        },
        previewWrapper: {
            height: "243px",
            overflow: "hidden",
            [theme.breakpoints.up("sm")]: {
                flex: "0 0 60%",
                height: "400px"
            }
        }
    }),
    { name: "Hero" }
);
