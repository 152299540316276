
import React from 'react';
import { injectIntl, Intl } from "../../../../../view/intl/index";
import { INTL_DAYS_OF_THE_WEEK, getMidnightToday } from '../../../../oneweb/OpeningHours/constants';
import {
    getDaysOfTheWeek,
    openingHoursToOpeningHoursMap
} from '../../../../oneweb/OpeningHours/utils';
import {
    getStartDayOfTheWeek,
} from '../../../../oneweb/OpeningHours/previewUtils';
import styles from './styles.css';
import type { OpeningHour } from '../../../../oneweb/OpeningHours/flowTypes';

type Props = {
    intl: Intl,
    openingHours: OpeningHour[],
};

export const OpeningHours = injectIntl(({ intl, openingHours }: Props) => {
    const daysOfTheWeek = getDaysOfTheWeek(getStartDayOfTheWeek());
    const openingHoursMap = openingHoursToOpeningHoursMap(openingHours);
    const timeToIntlTime = (time: number) => intl.time(new Date(getMidnightToday() + time), { second: undefined });
    const intlOpeningHourLabel = (openingHoursForTheDay) => {
        if (!openingHoursForTheDay) {
            return intl.msgJoint('msg: common.closed {Closed}');
        } else if (openingHoursForTheDay.from === openingHoursForTheDay.to && openingHoursForTheDay.from === 0) {
            return intl.msgJoint('msg: component.openingHours.open24Hours {Open 24 hours}');
        } else {
            return `${timeToIntlTime(openingHoursForTheDay.from)} - ${timeToIntlTime(openingHoursForTheDay.to)}`;
        }
    };

    return (
        <table className={styles.openHoursTable}>
            <tbody>
                {daysOfTheWeek.map((day) => {
                    return (
                        <tr key={day}>
                            <td>{intl.msgJoint(INTL_DAYS_OF_THE_WEEK[day])}</td>
                            <td className={styles.openHoursLabel}>{intlOpeningHourLabel(openingHoursMap[day])}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
});
