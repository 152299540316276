import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import browserDimensionsValueActionType from '../../../App/epics/browserDimensions/valueActionType';
import leftPanelWidthValueActionType from '../../../Panel/epics/width/valueActionType';
import { TemplateWidthActionType } from '../../../oneweb/Template/epics/template/selectorActionTypes';
import makeBBoxMemoized from '../../../../utils/componentsMap/makeBBoxMemoized';
import { ContentBBoxSelector } from "../componentsEval/selectorActionTypes";
import { getBBoxDimensions } from "../../../../utils/bBox";
import { isOdd } from "../../../../utils/ramdaEx";
import type { BBox } from '../../../../redux/modules/children/workspace/flowTypes';
import { TopBarHeight } from "../../../TopBar/constants";

type MakeWorkspaceBBoxParams = {
    browserWidth: number;
    browserHeight: number;
    leftPanelWidth: number;
    templateWidth: number;
    contentBBox: BBox;
};

const makeWorkspaceBBox = ({
    browserWidth,
    browserHeight,
    leftPanelWidth,
    templateWidth,
    contentBBox
}: MakeWorkspaceBBoxParams): BBox => {
    const
        workspaceWidth = browserWidth - leftPanelWidth,
        templateOffsetWithRefToWorkspace = Math.round((workspaceWidth - templateWidth) / 2),
        // fixing 0.5px rounding caused by center position of template area when viewport width is odd
        workspaceRightAdjust = isOdd(workspaceWidth) ? -1 : 0,
        workspaceRight = templateWidth + templateOffsetWithRefToWorkspace + workspaceRightAdjust,
        { height: contentHeight } = getBBoxDimensions(contentBBox),
        finalLeft = Math.min(contentBBox.left, -templateOffsetWithRefToWorkspace),
        finalRight = Math.max(contentBBox.right, workspaceRight),
        finalHeight = Math.max(browserHeight - TopBarHeight, contentHeight),
        result = makeBBoxMemoized(
            finalLeft,
            0,
            finalRight - finalLeft,
            finalHeight
        );
    return result;
};

const epic = makeEpic({
    valueActionType,
    updaters: [
        {
            conditions: [
                browserDimensionsValueActionType,
                leftPanelWidthValueActionType,
                TemplateWidthActionType,
                ContentBBoxSelector
            ],
            reducer: ({
                values: [
                    {
                        width: browserWidth,
                        height: browserHeight
                    },
                    leftPanelWidth,
                    templateWidth,
                    contentBBox
                ]
            }) => ({
                state: makeWorkspaceBBox({ browserWidth, browserHeight, leftPanelWidth, templateWidth, contentBBox })
            })
        }
    ]
});

export { epic as default, makeWorkspaceBBox };
