import React from 'react';
import cx from 'classnames';
import styles from './Button.css';
import { injectIntl } from "../../intl/index";
import type { Intl } from "../../intl/index";
import Button from './Button';

type PropTypes = {
    className ?: string,
    text: string,
    disabledText?: string,
    iconClassName?: string,
    onClick?: Function,
    disabled?: boolean,
    intl: Intl,
}

export default injectIntl(({
    className, text, disabledText, iconClassName, onClick, intl, disabled = false
}: PropTypes) => (
    <Button
        className={cx(styles.propertiesButton, className, disabled && styles.disabled)}
        onClick={() => {
            if (onClick) {
                onClick();
            }
        }}
        disabled={disabled}
    >
        <span className={styles.text}>{intl.msgJoint((disabled && disabledText) ? disabledText : text)}</span>
        {iconClassName && <div className={iconClassName} />}
    </Button>
));
