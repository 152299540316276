
import { makeValueReducer, makePayloadToSetterReducer } from "../../../../redux/makeReducer/index";
import { TEMPLATE_PAGES_SELECT_PAGE } from "../actionTypes";

export default makeValueReducer({
    defaultState: {
        selectedId: null
    },
    handleActions: {
        [TEMPLATE_PAGES_SELECT_PAGE]: makePayloadToSetterReducer('selectedId')
    }
});
