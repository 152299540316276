import React from 'react';

import makeAppStandardErrorDialog from "../../../../components/App/dialogs/makeAppStandardErrorDialog";
import { WarningConfirmationDialog } from '../../dialogs/WarningConfirmationDialog/index';
import { Msg } from '../../../intl/index';
import {
    FC_DELETE_CONFIRM,
    FC_DELETE_CANCEL,
} from "../../../../redux/modules/children/fileChooser/actionTypes";

const
    Messages = {
        directory: {
            title: 'msg: fc.folderDeleteDialog.title {Are you sure you want to delete the folder?}',
            message: () => (
                <Msg k="fc.folderDeleteDialog.description">
                    Deleting the folder will also permanently remove all of its content from your web space.
                </Msg>
            )
        },
        file: {
            title: 'msg: fc.fileDeleteDialog.title {Are you sure you want to delete this file?}',
            message: () => (
                <Msg k="fc.fileDeleteDialog.description">
                    Deleting the file will permanently remove it from your web space.
                </Msg>
            )
        },
    };

export const fileChooserDeleteConfirmationDialog = makeAppStandardErrorDialog(({ dispatch, resource }) => {
    const
        { title, message: Message } = resource.isDirectory() ? Messages.directory : Messages.file,
        onClose = () => dispatch({ type: FC_DELETE_CANCEL });
    return (
        <WarningConfirmationDialog
            title={title}
            confirmLabel="msg: delete {Delete}"
            onConfirm={() => dispatch({ type: FC_DELETE_CONFIRM })}
            onCancel={onClose}
            onClose={onClose}
            dispatch={dispatch}
        >
            <Message />
        </WarningConfirmationDialog>
    );
});
