// @ts-nocheck
import isProdEnv from '../../../../debug/isProdEnv';
import isDevEnv from '../../../../debug/isDevEnv';
import isTestEnv from '../../../../debug/isTestEnv';
import type {
    Stylesheet,
    Node
} from '../../flowTypes';
import {
    getClass,
    getMobileClass
} from '../utils/index';
import {
    DEFAULT_GLOBAL_CLASS
} from '../../../../components/App/epics/tinyMceEpic/editorUtils/utils/nodeUtils/utils';

export default (defaultGlobalStyles: Array<Stylesheet>, editMode: boolean) =>
    (node: Node, shouldHaveDefaultClass: boolean): string => {
        const classNames = [];
        const className = getClass(node, defaultGlobalStyles);
        if (className) {
            classNames.push(className);
        }

        if (shouldHaveDefaultClass && (!className || node.tag === 'a')) {
            classNames.push(DEFAULT_GLOBAL_CLASS);
        }

        if (!isTestEnv() && (isDevEnv() || isProdEnv())) {
            if (node.styles.indent) {
                classNames.push('mb-indent');
            }
            if (!editMode) {
                const mobileClassName = getMobileClass(node, defaultGlobalStyles);
                if (mobileClassName) {
                    classNames.push(mobileClassName);
                }
            }
        }

        return classNames.join(' ');
    };
