import * as React from "react";
import * as styles from '../FacebookChat.css';
import type { Props } from '../flowTypes';

class FacebookChat extends React.PureComponent<Props> {
    render() {
        return (
            <div className={styles.facebookChat} />
        );
    }
}

export default FacebookChat;
