import React from 'react';
import cx from 'classnames';
import globalStyles from '../global.css';
import UrlField from './Input/UrlField';
import injectIntl, { Intl } from "../intl/injectIntl";
import type { InputBlurEventHandler, InputFocusEventHandler, InputOnChangeEventHandler } from "./Input/flowTypes";

type Props = {
    url: null | undefined | string,
    onChange: InputOnChangeEventHandler,
    onEnter?: (...arg: any) => void,
    onKeyDown?: (...arg: any) => void,
    onFocus?: InputFocusEventHandler,
    onBlur?: InputBlurEventHandler,
    className?: string,
    inputClassName?: string,
    labelClassName?: string,
    allowJsProtocol?: boolean,
    validatePathPresence?: boolean,
    title?: string,
    intl: Intl,
};

const MSG = 'msg: common.externalUrlLink {Link to an external URL:}';

const ExternalUrl = injectIntl(({
    url: inUrl,
    onChange,
    onEnter,
    onBlur,
    onKeyDown,
    onFocus,
    className = '',
    inputClassName = '',
    labelClassName = '',
    allowJsProtocol = true,
    validatePathPresence = false,
    title,
    intl
}: Props) => {
    const url = inUrl || 'http://';
    return (
        <div className={className}>
            <div className={cx(globalStyles.labelSmall, labelClassName)}>{intl.msgJoint(title || MSG)}</div>
            <div>
                <UrlField
                    value={url}
                    onChange={onChange}
                    onEnterPress={onEnter}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    className={inputClassName}
                    allowJsProtocol={allowJsProtocol}
                    validatePathPresence={validatePathPresence}
                    autoFocus
                />
            </div>
        </div>
    );
});

export { ExternalUrl as default, MSG };
