import React from 'react';
import ReactDOMServer from "react-dom/server"; // eslint-disable-line node/file-extension-in-import
import type { AppStore } from "../../../../redux/modules/flowTypes";
import { BUY_PAID_SHUTTERSTOCK_IMAGE_ACTION } from "../actions";
import { ShutterstockKind } from "../../shutterstock/constants";
import {
    disableShutterstockMctaAction,
    enableShutterstockMctaAction,
    skipAddImageToComponentPostDownloadAction,
    resetSkipAddImageToComponentPostDownloadAction
} from "../../shutterstock/actions";
import { ShutterstockPurchaseForm, formId } from "../components/ShutterstockPurchaseForm";
import { getSelectedImageDetails, getImageById, getPurchaseImageBody } from "../selectors";
import {
    shutterstockImagesSearchIdAppSel,
    shutterstockSkipActionPostDownloadAppSel
} from "../../shutterstock/selectors";
import { openCommunicationWindow } from "../../../../utils/openCommunicationWindow";
import type { PaidShutterstockCommunicationMessage } from "../types";
import { currentLanguageAppSel } from "../../../TopBar/epics/languages/selectors";
import { makeActionForwardToSelectedComponent } from "../../../../redux/forwardTo";
import { ASSET_IMAGE_DAL_TYPE, getDAL } from "../../../../../dal/index";
import { makeUuid } from "../../../../../utils/makeUuid";
import { closeDialog } from "../../../App/actionCreators/index";
import { fcResetAction } from "../../../../redux/modules/children/fileChooser/actionCreators/index";
import { CLOSE_FM_DIALOG_ACTION } from "../../dialog/actions";
import { PaidShutterstockPurchaseWindowDimensions } from "../constants";
import type { Dimensions } from '../../../../globalTypes';

type ImagesResponse = {
    imageId: string,
    imageDimensions: Dimensions,
    webspaceUrl: string,
    contentType: string,
};

const completeDownload = ({ selectedImages, downloadImages, storeDispatch, onSaveAction, isMultiSelect }) => {
    const assets = downloadImages.map((d: ImagesResponse) => {
        const anImage = selectedImages.find(img => img.id === d.imageId);
        if (!anImage) {
            throw new Error(`Could not find downloaded image ${d.imageId} in state`);
        }

        return {
            id: makeUuid(),
            type: ASSET_IMAGE_DAL_TYPE,
            etag: "",
            url: d.webspaceUrl,
            contentType: d.contentType,
            filesize: anImage.fileSize,
            alpha: null,
            bpp: null,
            width: d.imageDimensions.width,
            height: d.imageDimensions.height,
            animated: false,
            recommendedFormat: null,
        };
    });

    const
        saveAction = makeActionForwardToSelectedComponent({
            type: onSaveAction,
            payload: isMultiSelect ? { assets } : { asset: assets[0] }
        });

    storeDispatch(closeDialog());
    storeDispatch(fcResetAction());
    return storeDispatch(saveAction);
};

export const paidShutterstockMiddleware = (store: AppStore) => (next: Dispatch) => (action: Action) => {
    if (action.type === BUY_PAID_SHUTTERSTOCK_IMAGE_ACTION) {
        const
            appState = store.getState(),
            images = action.payload.id
                ? getImageById(action.payload.id, appState)
                : getSelectedImageDetails(appState),
            imagesSearchId = shutterstockImagesSearchIdAppSel(ShutterstockKind.PAID)(appState),
            locale = currentLanguageAppSel(appState);

        const props = {
            images: getPurchaseImageBody(images),
            imagesSearchId,
            locale,
            action: getDAL().sstockImagesFullPurchageEndpoint(),
        };

        const dialogHtml = ReactDOMServer.renderToStaticMarkup(<ShutterstockPurchaseForm {...props} />);

        const cw = openCommunicationWindow({
            openOptions: {
                windowFeatures: {
                    scrollbars: 1,
                    width: PaidShutterstockPurchaseWindowDimensions.WIDTH,
                    height: PaidShutterstockPurchaseWindowDimensions.HEIGHT,
                },
                center: true,
            },
            onOpen: (popup) => {
                popup.document.write(dialogHtml);
                // @ts-ignore
                popup.document.getElementById(formId).submit();
            },
            onClose: () => {
                store.dispatch(enableShutterstockMctaAction(ShutterstockKind.PAID));
            },
        });

        cw.subscribe((event) => {
                        const data: PaidShutterstockCommunicationMessage = JSON.parse(event.data);
            const componentActionAllowed =
                !shutterstockSkipActionPostDownloadAppSel(ShutterstockKind.PAID)(store.getState());
            if (data.ok === true && componentActionAllowed) {
                completeDownload({
                    selectedImages: images,
                    downloadImages: data.message,
                    storeDispatch: store.dispatch,
                    onSaveAction: action.payload.onSaveAction,
                    isMultiSelect: action.payload.isMultiSelect
                });
            }

            // @ts-ignore
            if (event.source) event.source.close();
            store.dispatch(resetSkipAddImageToComponentPostDownloadAction(ShutterstockKind.PAID));
            store.dispatch(enableShutterstockMctaAction(ShutterstockKind.PAID));
        });
        store.dispatch(resetSkipAddImageToComponentPostDownloadAction(ShutterstockKind.PAID));
        store.dispatch(disableShutterstockMctaAction(ShutterstockKind.PAID));
    }

    if (action.type === CLOSE_FM_DIALOG_ACTION) {
        store.dispatch(skipAddImageToComponentPostDownloadAction(ShutterstockKind.PAID));
    }

    return next(action);
};
