import React, { useRef, useEffect } from "react";
import Lottie from "react-web-lottie";
import animationData from "./animation.json";
import {
    getStepMarkers,
    updateTextData,
    ONBOARDING_STEPS,
    ELEMENT_INDEXES,
    LOTTIE_DEFAULT_OPTIONS,
    updateStaticTextData
} from "./constant";

type TitleStepRightSideViewProps = {
    title: string;
    isForwardDirection: boolean;
};
const markers = animationData.markers;
const stepMarkers = getStepMarkers(markers, ONBOARDING_STEPS.WEBSITE_TITLE);
let initialSegment;
export default function TitleStepRightSideView({ title, isForwardDirection }: TitleStepRightSideViewProps) {
    const animationRef = useRef(null);

    if (!animationRef.current) {
        animationData.assets[ELEMENT_INDEXES.logoImage].p = "";
        updateStaticTextData({
            animationData,
            layerIndex: ELEMENT_INDEXES.title,
            text: title
        });
        updateStaticTextData({
            animationData,
            layerIndex: ELEMENT_INDEXES.email
        });
        updateStaticTextData({
            animationData,
            layerIndex: ELEMENT_INDEXES.phone
        });
        updateStaticTextData({
            animationData,
            layerIndex: ELEMENT_INDEXES.address
        });
    }

    // Moving backward
    if (!isForwardDirection && !animationRef.current) {
        initialSegment = [stepMarkers.title_stop_point, stepMarkers.title_stop_point + 1];
    } else {
        initialSegment = [stepMarkers.title_start_of_step, stepMarkers.title_stop_point + 1];
    }

    useEffect(() => {
        // @ts-ignore
        animationRef.current.playSegments([[stepMarkers.title_stop_point, stepMarkers.title_stop_point + 1]], true);
        updateTextData({
            animationRef,
            elementIndex: ELEMENT_INDEXES.title,
            textData: {
                t: title
            }
        });
    }, [title]);
    useEffect(() => {
        if (isForwardDirection) {
            // @ts-ignore
            animationRef.current.playSegments([[stepMarkers.title_start_of_step, stepMarkers.title_stop_point]], true);
        }
    }, []);
    const defaultOptions = { ...LOTTIE_DEFAULT_OPTIONS, initialSegment, animationData };
    return <Lottie lottiRef={animationRef} options={defaultOptions} isClickToPauseDisabled speed={0.5} />;
}
