/* eslint-disable max-len */
import { PAGE_TASKS_KEYS, SITE_TASKS_KEYS } from "../../../../src/dashboard/src/components/Pages/Marketgoo/Report/constants";
import { pageURL } from "../../../../src/dashboard/src/components/Pages/OnlinePresence/constants";
import { GO_TO_EDITOR_TO_PUBLISH_KEY, MARKETGOO_PAGE_SIDE_BAR_ITEM_ID } from "../../../../src/dashboard/src/components/Pages/Marketgoo/constants";
import { setDashboardSessionDefaultPage } from "../../../../src/dashboard/src/utils/constants";
import { DASHBOARD_ROUTE } from '../../../../server/shared/routes';

import * as Actions from "./actionTypes";
import makeEpic from "../../epics/makeEpic";
import { when, receiveOnly } from "../../epics/makeCondition";
import { openSiteSettingsDialog } from "../SiteSettings/SiteSettingsDialog/actionCreators";
import { SiteSettingsTabName } from "../SiteSettings/SiteSettingsDialog/constants";
import { PANEL_ITEM_LEFT_MOUSE_DOWN } from "../Panel/actionTypes";
import { ContactFormKind } from "../oneweb/ContactForm/kind";
import { MARKETGOO_VAT } from "./marketgooVat";
import { handleMarketgooTaskAction, marketgooTaskDoneAction, openMarketgooTaskBannerAction } from "./actionCreators";
import { navigateToLocationAction } from '../../redux/middleware/window/actionCreators';
import { openMobileViewEditorClicked } from '../MobileViewEditor/actionCreators';
import { selectPageAction, openPageInfoDialogAction } from "../PagesTree/Tree/actionCreators";
import openTopBarPopup from "../TopBar/actionCreators/openTopBarPopup";
import { Popups } from "../TopBar/constants";
import { RedirectReceiverVAT } from "../App/epics/redirectReceiverEpic";
import { SAVE_MARKETGOO_IN_PREFERENCES_ACTION } from "../../../../src/dashboard/src/components/Pages/Marketgoo/actions";
import { PREFERENCES_EPIC_VALUE_VAT } from "../Preferences/preferencesVat";
import { PUT_PREFERENCES_REQUEST_ACTION, savePreferencesAction } from "../Preferences/actions";
import { currentPageIdValueActionType } from "../App/epics/currentPageId/valueActionType";
import { checkUnsavedChangesAction } from "../UnsavedChanges/actionCreators";
import { CANCEL_UNSAVED_CHANGES, UNSAVED_CHANGES_CHECKED } from "../UnsavedChanges/actionTypes";
import unsavedChangesVAT from "../UnsavedChanges/epic/valueActionType";
import { siteDataValueActionType } from "../App/epics/siteData/valueActionType";

export type MarketgooEpicState = {
    isTaskBannerOpen: boolean;
    taskBannerId?: string;
};

const defaultState: MarketgooEpicState = {
    isTaskBannerOpen: false
};

const openInserter = (kind: string) => ({
    type: PANEL_ITEM_LEFT_MOUSE_DOWN,
    payload: { componentKind: kind }
});

const whenMarketgooTaskAction = (taskId: string) => {
    return when(
        Actions.HANDLE_MARKETGOO_TASK_ACTION,
        ({ payload }) => payload.key === taskId
    );
};

export const marketgooEpic = makeEpic({
    defaultState,
    defaultScope: {
        savingMarketgooInPreferences: false,
        checkUnsavedChanges: false,
        markTaskAsDone: false,
    },
    valueActionType: MARKETGOO_VAT,
    updaters: [
        {
            conditions: [RedirectReceiverVAT],
            reducer: ({ values: [sessionData], scope, state }): any => {
                const taskId = sessionData?.key;

                if (sessionData) {
                    if (typeof taskId === "string" && (taskId in SITE_TASKS_KEYS || taskId in PAGE_TASKS_KEYS)) {
                        return {
                            state,
                            scope,
                            multipleActionsToDispatch: [
                                openMarketgooTaskBannerAction({ taskId, pageId: sessionData.payload }),
                                handleMarketgooTaskAction(sessionData)
                            ]
                        };
                    } else if (taskId === pageURL) {
                        return {
                            state,
                            scope,
                            actionToDispatch: openPageInfoDialogAction({ pageRefId: sessionData.payload })
                        };
                    } else if (taskId === GO_TO_EDITOR_TO_PUBLISH_KEY) {
                        return {
                            state,
                            scope,
                            actionToDispatch: openMarketgooTaskBannerAction({ taskId })
                        };
                    }
                }

                return { state, scope };
            },
        },
        {
            conditions: [
                whenMarketgooTaskAction(SITE_TASKS_KEYS.responsivesite),
            ],
            reducer: ({ state, scope }) => {
                return {
                    state,
                    scope,
                    actionToDispatch: openMobileViewEditorClicked(),
                };
            },
        },
        {
            conditions: [
                whenMarketgooTaskAction(SITE_TASKS_KEYS.dupetitle),
            ],
            reducer: ({ state, scope, values: [{ payload }] }) => {
                if (payload) {
                    return {
                        state,
                        scope,
                        actionToDispatch: openPageInfoDialogAction({
                            pageRefId: payload
                        }),
                    };
                }

                return {
                    state,
                    scope,
                    actionToDispatch: openTopBarPopup({ id: Popups.PAGES }),
                };
            },
        },
        {
            conditions: [
                receiveOnly(siteDataValueActionType),
                whenMarketgooTaskAction(SITE_TASKS_KEYS.longurl),
            ],
            reducer: ({ state, scope, values: [site, { payload }] }) => {
                if (payload) {
                    const page = site.getOptionalPageRefByPageId(payload);

                    if (page) {
                        return {
                            state,
                            scope,
                            actionToDispatch: openPageInfoDialogAction({
                                pageRefId: page.id
                            }),
                        };
                    }
                }

                return {
                    state,
                    scope,
                    actionToDispatch: openTopBarPopup({ id: Popups.PAGES }),
                };
            },
        },
        {
            conditions: [
                whenMarketgooTaskAction(SITE_TASKS_KEYS.no404),
                receiveOnly(currentPageIdValueActionType)
            ],
            reducer: ({ state, scope, values: [{ payload }, currentPageId] }) => {
                if (payload && payload !== currentPageId) {
                    return {
                        state,
                        scope,
                        actionToDispatch: selectPageAction(payload),
                    };
                }

                return {
                    state,
                    scope,
                };
            },
        },
        {
            conditions: [
                receiveOnly(siteDataValueActionType),
                whenMarketgooTaskAction(PAGE_TASKS_KEYS.keywordinurl),
            ],
            reducer: ({ state, scope, values: [site, { payload }] }) => {
                if (payload) {
                    const page = site.getOptionalPageRefByPageId(payload);

                    if (page) {
                        return {
                            state,
                            scope,
                            actionToDispatch: openPageInfoDialogAction({
                                pageRefId: page.id
                            }),
                        };
                    }
                }

                return {
                    state,
                    scope,
                    actionToDispatch: openTopBarPopup({ id: Popups.PAGES }),
                };
            },
        },
        {
            conditions: [
                whenMarketgooTaskAction(PAGE_TASKS_KEYS.lowcontentsingle),
                receiveOnly(currentPageIdValueActionType)
            ],
            reducer: ({ state, scope, values: [{ payload }, currentPageId] }) => {
                if (payload && payload !== currentPageId) {
                    return {
                        state,
                        scope,
                        actionToDispatch: selectPageAction(payload),
                    };
                }

                return {
                    state,
                    scope,
                };
            },
        },
        {
            conditions: [
                whenMarketgooTaskAction(PAGE_TASKS_KEYS.imgaltsingle),
                receiveOnly(currentPageIdValueActionType)
            ],
            reducer: ({ state, scope, values: [{ payload }, currentPageId] }) => {
                if (payload && payload !== currentPageId) {
                    return {
                        state,
                        scope,
                        actionToDispatch: selectPageAction(payload),
                    };
                }

                return {
                    state,
                    scope,
                };
            },
        },
        {
            conditions: [
                whenMarketgooTaskAction(SITE_TASKS_KEYS.sslexists),
            ],
            reducer: ({ state, scope }) => {
                return {
                    state,
                    scope,
                    actionToDispatch: openSiteSettingsDialog({
                        activeTabKey: SiteSettingsTabName.SECURITY
                    }),
                };
            },
        },
        {
            conditions: [
                whenMarketgooTaskAction(SITE_TASKS_KEYS.googleplace),
            ],
            reducer: ({ state, scope }) => {
                return {
                    state,
                    scope,
                    actionToDispatch: openSiteSettingsDialog({
                        activeTabKey: SiteSettingsTabName.BUSINESS_LISTINGS
                    }),
                };
            },
        },
        {
            conditions: [
                whenMarketgooTaskAction(SITE_TASKS_KEYS.clicktocall),
            ],
            reducer: ({ state, scope }) => {
                return {
                    state,
                    scope,
                    actionToDispatch: openInserter(ContactFormKind),
                };
            },
        },
        {
            conditions: [
                whenMarketgooTaskAction(PAGE_TASKS_KEYS.keyonh1),
                receiveOnly(currentPageIdValueActionType)
            ],
            reducer: ({ state, scope, values: [{ payload }, currentPageId] }) => {
                if (payload && payload !== currentPageId) {
                    return {
                        state,
                        scope,
                        actionToDispatch: selectPageAction(payload),
                    };
                }

                return {
                    state,
                    scope,
                };
            },
        },
        {
            conditions: [
                whenMarketgooTaskAction(PAGE_TASKS_KEYS.keyonhxorpara),
                receiveOnly(currentPageIdValueActionType)
            ],
            reducer: ({ state, scope, values: [{ payload }, currentPageId] }) => {
                if (payload && payload !== currentPageId) {
                    return {
                        state,
                        scope,
                        actionToDispatch: selectPageAction(payload),
                    };
                }

                return {
                    state,
                    scope,
                };
            },
        },
        {
            conditions: [
                Actions.OPEN_MARKETGOO_TASK_BANNER_ACTION,
            ],
            reducer: ({ state, scope, values: [{ taskId, pageId }] }) => {
                return {
                    state: {
                        ...state,
                        isTaskBannerOpen: true,
                        taskBannerId: taskId,
                        pageId
                    },
                    scope
                };
            },
        },
        {
            conditions: [
                Actions.CLOSE_MARKETGOO_TASK_BANNER_ACTION,
            ],
            reducer: ({ state, scope }) => {
                return {
                    state: {
                        ...state,
                        isTaskBannerOpen: false,
                        taskBannerId: null,
                    },
                    scope
                };
            },
        },
        {
            conditions: [
                Actions.DISMISS_MARKETGOO_TASK_BANNER_ACTION,
            ],
            reducer: ({ state, scope }) => {
                return {
                    state,
                    scope: {
                        ...scope,
                        checkUnsavedChanges: true,
                        markTaskAsDone: false,
                    },
                    actionToDispatch: checkUnsavedChangesAction()
                };
            },
        },
        {
            conditions: [
                Actions.MARKETGOO_TASK_DONE_ACTION,
            ],
            reducer: ({ state, scope }) => {
                return {
                    state,
                    scope: {
                        ...scope,
                        checkUnsavedChanges: true,
                        markTaskAsDone: true,
                    },
                    actionToDispatch: checkUnsavedChangesAction()
                };
            },
        },
        {
            conditions: [CANCEL_UNSAVED_CHANGES],
            reducer: ({ state, scope }) => {
                return {
                    state,
                    scope: {
                        ...scope,
                        checkUnsavedChanges: false,
                        markTaskAsDone: false,
                    },
                };
            }
        },
        {
            conditions: [
                receiveOnly(unsavedChangesVAT),
                UNSAVED_CHANGES_CHECKED
            ],
            reducer: ({ state, scope, values: [hasChanges] }) => {
                if (!scope.checkUnsavedChanges) {
                    return { state, scope };
                }

                // could be either:
                //   - no pending changes
                //   - user clicked on "don't save" button
                //   - after saving, if user clicked on "save" button (which means no changes)
                // TODO: `hasChanges` seems to be always `false`, refactor if that's the case
                if (!hasChanges) {
                    // necessary if user clicked on "don't save" button
                    window.skipUnsavedChangesPrompt = true;

                    if (scope.markTaskAsDone) {
                        // passing the task id means the task is considered as done,
                        setDashboardSessionDefaultPage(MARKETGOO_PAGE_SIDE_BAR_ITEM_ID, {
                            taskId: state.taskBannerId, comingFromBackToSeoBtn: true
                        });
                    } else {
                        setDashboardSessionDefaultPage(MARKETGOO_PAGE_SIDE_BAR_ITEM_ID, { comingFromBackToSeoBtn: true });
                    }

                    return ({
                        state,
                        scope: {
                            ...scope,
                            checkUnsavedChanges: false
                        },
                        actionToDispatch: navigateToLocationAction(DASHBOARD_ROUTE)
                    });
                }

                // TODO: this part seems to be a dead code since `hasChanges` is always `false`
                return {
                    state,
                    scope: {
                        ...scope,
                        checkUnsavedChanges: false
                    }
                };
            }
        },
        {
            conditions: [
                SAVE_MARKETGOO_IN_PREFERENCES_ACTION,
                receiveOnly(PREFERENCES_EPIC_VALUE_VAT)
            ],
            reducer: ({ state, values: [task, { marketgoo = {} }] }) => {
                return {
                    state,
                    scope: { savingMarketgooInPreferences: true },
                    // @ts-ignore
                    actionToDispatch: savePreferencesAction({ marketgoo: { ...marketgoo, ...task } })
                };
            },
        },
        {
            conditions: [
                PUT_PREFERENCES_REQUEST_ACTION,
            ],
            reducer: ({ state, scope }) => {
                if (scope.savingMarketgooInPreferences) {
                    return {
                        state,
                        scope: { savingMarketgooInPreferences: false },
                        actionToDispatch: marketgooTaskDoneAction()
                    };
                }

                return { state, scope };
            },
        },
    ]
});
