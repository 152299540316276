import cx from "classnames";
import getCenteredDialog from "../../../DialogManager/getCenteredDialogConfig";
import { configurationDialogFactory } from '../configurationDialogFactory';
import { PaypalDialogHeight, DialogWidth } from '../constants';
import { WIDGETS_PAYPAL_SET_LINK } from "./actionTypes";
import { validateLinkOrCode } from '../utils';
import { parsePaypalCode } from './utils/parsePaypalCode';
import Icons from '../view/Icons.css';

const validatePaypalData = validateLinkOrCode([], parsePaypalCode);

const
    configurationDialogId = "WIDGETS_PAYPAL_CONFIG_DIALOG",
    configurationDialogView = configurationDialogFactory({
        saveAction: WIDGETS_PAYPAL_SET_LINK,
        dialogIconClass: cx(Icons.paypal, Icons.dialog),
        dialogTitle: "msg: widgets.paypal.configDialog.title {PayPal}",
        info: "msg: widgets.paypal.config.info {Add a PayPal button to your page. Note that only one Paypal account can be connected to your website. }", // eslint-disable-line max-len
        subInfo: "msg: widgets.paypal.configDialog.copyPaste {Go to paypal.com/buttons and create a button. Copy the code provided by PayPal and paste it below. }", // eslint-disable-line max-len
        inputPlaceHolder: "msg: widgets.paypal.configDialog.placeHolder {Paste PayPal embed code.}",
        learnMore: "msg: widgets.paypal.config.learnMore {https://help.one.com/hc/en-us/articles/11406108098833}",
        isLinkValid: validatePaypalData,
        termsOfService: "msg: widgets.paypal.config.termsOfService {By using this widget, you agree to PayPal\'s {termsOfServiceLink}.}",
        termsOfServiceLink: "msg: widgets.paypal.config.termsOfServiceLink {https://www.paypal.com/uk/webapps/mpp/ua/useragreement-full}",
    }),
    configurationDialog = getCenteredDialog({
        width: DialogWidth,
        height: PaypalDialogHeight,
        component: configurationDialogView
    });

export { configurationDialogId, configurationDialog };
