import React from "react";
export const SvgRedditSquareFillMulticolor = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <path fill="#ff4500" d="M0 0h48v48H0z" />
            <path
                fill="#ffffff"
                d="M17.952 24.903a1.488 1.488 0 112.976 0 1.488 1.488 0 01-2.976 0zm8.927 0a1.488 1.488 0 112.976 0 1.488 1.488 0 01-2.976 0zm.073 3.18a.883.883 0 111.093 1.388c-1.067.84-2.693 1.384-4.142 1.384-1.449 0-3.074-.543-4.141-1.384a.883.883 0 011.093-1.388c.614.484 1.83 1.005 3.048 1.005s2.434-.52 3.049-1.005zm8.855-6.155a2.976 2.976 0 00-5.577-1.446c-1.53-.837-3.393-1.377-5.423-1.502l1.776-3.988 3.399.981a2.232 2.232 0 10.14-1.799l-3.788-1.093a.883.883 0 00-1.052.49l-2.413 5.417c-1.98.14-3.795.675-5.292 1.494A2.975 2.975 0 0012 21.928c0 1.216.73 2.26 1.776 2.722a5.45 5.45 0 00-.288 1.741c0 4.109 4.663 7.44 10.415 7.44 5.753 0 10.416-3.331 10.416-7.44 0-.6-.1-1.182-.288-1.74a2.975 2.975 0 001.776-2.723zm-3.72-7.533a.837.837 0 110 1.674.837.837 0 010-1.674zm-18.6 7.533a1.49 1.49 0 012.833-.636c-.777.59-1.421 1.27-1.898 2.016a1.49 1.49 0 01-.934-1.38zm10.416 10.136c-4.776 0-8.648-2.54-8.648-5.673s3.872-5.672 8.648-5.672c4.777 0 8.649 2.54 8.649 5.672 0 3.133-3.872 5.673-8.649 5.673zm9.482-8.756c-.477-.746-1.121-1.427-1.899-2.016a1.49 1.49 0 011.345-.852c.82 0 1.488.667 1.488 1.488a1.49 1.49 0 01-.934 1.38z"
            />
        </g>
    </svg>
);
