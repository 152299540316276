import * as actionMapper from '../Common/action';
import defaultImageData from '../../../../src/components/SortImagesDialog/defaultImageData';
import type { GalleryIn } from './flowTypes';
import type { GalleryComponent } from "../../../../src/components/oneweb/Gallery/flowTypes";

export const
    to = ({ images }: GalleryIn) => ({

        images: images.map(image => ({
            ...defaultImageData,
            ...image,
            title: image.title || image.altText || "",
            action: image.action && actionMapper.to(image.action)
        }))
    }),
    back = ({ images }: GalleryComponent) => ({
        images: images.map(image => ({
            ...image,
            action: image.action && actionMapper.back(image.action)
        }))
    });
