import React from "react";
import cx from "classnames";
import { pure } from 'recompose';
import { isResizingHandle, resizeHandleKinds } from "../../../utils/handle/index";
import styles from './ResizingDots.css';
import * as HandleKinds from '../../../utils/handle/kinds';
import {
    MOUSE_DOWN_ON_HANDLE, RESIZE_HANDLE_MOVING,
    IDLE
} from "../../../components/Workspace/epics/componentsEval/userInteractionMutations/interactionModes";

import type {
    ComponentsSelectionDecorationState
} from "../../../components/Workspace/epics/componentsSelectionDecoration/flowTypes";
import type { Handles } from "../../../redux/modules/children/workspace/flowTypes";
import { getBBoxesBoudaries } from "../../../utils/bBox";
import type { AnyComponent } from "../../../components/oneweb/flowTypes";

type Props = {
    handles: Handles,
    componentsSelectionDecoration: ComponentsSelectionDecorationState,
    userInteractionHandleKind: string,
    interactionMode: string,
    selectedComponent: AnyComponent | null,
}

const kindsForStrip: Record<string, any> = {
    [HandleKinds.ResizeN]: true,
    [HandleKinds.ResizeS]: true
};

const hideOthersInteractionModes = [RESIZE_HANDLE_MOVING, MOUSE_DOWN_ON_HANDLE];

function isResizeHandle(handleKind,
    interactionMode,
    userInteractionHandleKind,
    componentsSelectionDecoration: ComponentsSelectionDecorationState): boolean {
    if (hideOthersInteractionModes.indexOf(interactionMode) > -1) {
        return userInteractionHandleKind === handleKind;
    }
    if (interactionMode !== IDLE) {
        return false;
    }

    if (componentsSelectionDecoration.containsStrip) {
        return kindsForStrip[handleKind];
    }

    return true;
}

const getResizeHandles = (
    interactionMode: string,
    userInteractionHandleKind: string,
    componentsSelectionDecoration: ComponentsSelectionDecorationState
): Array<string> => {
    return resizeHandleKinds
        .filter(
            (handleKind: string) => isResizeHandle(
                handleKind,
                interactionMode,
                userInteractionHandleKind,
                componentsSelectionDecoration
            )
        );
};

const
    getHandlesOfKindBBox = (handles: Handles) => getBBoxesBoudaries(handles.map(({ bBox }) => bBox)),
    ResizingDots = pure(({
        handles,
        componentsSelectionDecoration,
        interactionMode,
        userInteractionHandleKind,
    }: Props) => {
        const
            handlesMap = handles.reduce((acc, handle) => {
                if (isResizingHandle(handle.kind)) {
                    acc[handle.kind] = handle; // eslint-disable-line
                }
                return acc;
            }, {});

        if (Object.keys(handlesMap).length === 0) {
            return null;
        }

        const
            {
                isMouseDown,
                topMostHandleKind
            } = componentsSelectionDecoration,
            resizingHandles = getResizeHandles(interactionMode, userInteractionHandleKind, componentsSelectionDecoration)
                .map(handleKind => {
                    const
                        handlesOfKind = handles.filter(({ kind }) => kind === handleKind),
                        handlesOfKindBBox = getHandlesOfKindBBox(handlesOfKind),
                        isHovered = topMostHandleKind === handleKind,
                        isActive = isHovered && isMouseDown,
                        handleBoxStyle = {
                            left: handlesOfKindBBox.left,
                            top: handlesOfKindBBox.top,
                            width: handlesOfKindBBox.right - handlesOfKindBBox.left,
                            height: handlesOfKindBBox.bottom - handlesOfKindBBox.top
                        };

                    return (
                        <div key={handleKind}>
                            <div
                                data-handle-kind={handleKind}
                                className={styles.resizePointContainer}
                                style={handleBoxStyle}
                            >
                                <div
                                    className={cx(
                                        styles.resizePoint,
                                        { [styles.hover]: isHovered, [styles.active]: isActive }
                                    )}
                                />
                            </div>
                        </div>
                    );
                });

        return (
            <div>
                {resizingHandles}
            </div>
        );
    });

export { ResizingDots, getResizeHandles, getHandlesOfKindBBox };
