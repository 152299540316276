import { Lit } from "../../../../lit";
import type { ShutterstockState } from "../types";
import type { SstockImage } from "../../../../../dal/flowTypes";

export const selectShutterstockImageReducer = (state: ShutterstockState, action: Record<string, any>): ShutterstockState => {
    const
        { payload: { imageId, isMultiSelect } } = action,
        images = state[Lit.images],
        selectedImage: null | undefined | SstockImage = images.find(img => img.id === imageId);

    if (!selectedImage) {
        throw new Error(`Selected image '${imageId}' not found`);
    }

    const selectedImageStub = {
        imageId: selectedImage.id,
        description: selectedImage.description,
        fileSize: selectedImage.assets.orig.fileSize,
        width: selectedImage.assets.orig.width,
        height: selectedImage.assets.orig.height,
        format: selectedImage.assets.orig.format
    };

    let nextSelectedImages;

    if (isMultiSelect) {
        nextSelectedImages = [...state[Lit.selectedImages]];
        const idx = nextSelectedImages.findIndex(img => img.imageId === selectedImage.id);
        if (idx > -1) {
            nextSelectedImages.splice(idx, 1);
        } else {
            nextSelectedImages.push(selectedImageStub);
        }
    } else {
        nextSelectedImages = [selectedImageStub];
    }

    return {
        ...state,
        [Lit.selectedImages]: nextSelectedImages
    };
};
