import * as R from "ramda";
import { getNumRowsAndCols, getRowAndColumnForAddOrRemoveAction, fixRowAndColumnIndices } from "../utils";
import type { TableComponent } from "../flowTypes";

export const removeRowReducer = (component: TableComponent, action: AnyAction): TableComponent => {
    const
        { cells } = component,
        { payload: { fisrtSelectedCellIndex } } = action,
        { numRows, numCols } = getNumRowsAndCols(cells),
        { rowIndex: selectedCellRowIndex } = getRowAndColumnForAddOrRemoveAction(
            { numRows, numCols },
            fisrtSelectedCellIndex
        ),
        removeStartIndex = numCols * selectedCellRowIndex;

    if (numRows > 1) {
        return R.evolve({
            cells: R.pipe(
                cells => R.flatten([
                    R.slice(0, removeStartIndex, cells),
                    R.slice(removeStartIndex + numCols, Infinity, cells)
                ]),
                fixRowAndColumnIndices({ numRows: numRows - 1, numCols })
            )
        }, component);
    }

    return component;
};
