import React, { useEffect } from 'react';
import { ComponentMaskBackground } from '../../../presentational/ComponentMask/constants';
import ComponentMask from '../../../presentational/ComponentMask/index';
import type { FeaturedProductsViewProps } from '../flowTypes';
import { FrameComponent } from './FrameComponent';
import { FEATURED_PRODUCTS_COMPONENT_DID_MOUNT } from '../actionTypes';

const FeaturedProductsView = (
    viewProps: FeaturedProductsViewProps
) => {
    useEffect(() => {
        viewProps.dispatch({ type: FEATURED_PRODUCTS_COMPONENT_DID_MOUNT, payload: { componentId: viewProps.component.id } });
    });

    const {
        component,
        componentChanging,
        inSelectedMode,
        inEditMode,
        componentTierData
    } = viewProps;

    return (
        <ComponentMask
            forceShowMask={componentChanging}
            style={{ height: component.height }}
            maskStyle={ComponentMaskBackground.TRANSPARENT}
            inEditMode={inEditMode}
            inSelectedMode={inSelectedMode}
            componentTierData={componentTierData}
            componentWidth={component.width}
        >
            <FrameComponent {...viewProps} shopComponentExists />
        </ComponentMask>);
};

export default FeaturedProductsView;
