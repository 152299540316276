import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(
    {
        actionButton: {
            position: "relative",
            overflow: "hidden",
            height: "46px",
            padding: "0 30px"
        },
    },
    { name: "SaveButton" }
);
