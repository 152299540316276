import React from 'react';
import * as pageIds from '../pageIds';
import pagesTitles from '../pageTitles';
import Page from "../../../../../PropertiesPanel/view/PropertiesPage";
import NavigationGroup from "../../../../../PropertiesPanel/view/IntlNavigationGroup";

const
    id = pageIds.MAIN,
    title = pagesTitles[id],
    view = ({ navigateToPage }) => (
        <Page>
            <NavigationGroup
                navigateToPage={navigateToPage}
                pagesTitles={pagesTitles}
                targetPagesIds={[pageIds.SHAPE, pageIds.COLOR, pageIds.LAYOUT]}
            />
        </Page>
    );

export { id, title, view };
