import {
    makeCombineReducer,
    makeDefaultStateReducers,
    makeComponentBaseReducers
} from "../../../../redux/makeReducer/index";
import kind from '../kind';
import * as ActionTypes from '../actionTypes';
import {
    default as settingsReducer,
    muteVideoByDefault
} from './settings';
import { updateVideoFileReducer, updateYoutubeLinkReducer } from './updateVideo';
import mobileDownReducer from '../../../PropertiesPanel/view/MobileView/mobileDownReducer';
import {
    TOGGLE_MOBILE_DOWN_ACTION
} from "../../../PropertiesPanel/view/MobileView/actions";
import { VideoSettings } from '../constants';

export default makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(kind),
        ...makeDefaultStateReducers({
            wrap: null,
            stretch: null,
            width: 600,
            height: 400,
            mobileHide: false,
            mobileDown: false,
            state: null,
            asset: null
        })
    },
    handleActions: {
        [ActionTypes.VIDEO_PROP_PANEL_SHOW_INFO]: settingsReducer,
        [ActionTypes.VIDEO_PROP_PANEL_SHOW_CONTROLS]: settingsReducer,
        [ActionTypes.VIDEO_PROP_PANEL_PLAY_AUTOMATICALLY]: settingsReducer,
        [ActionTypes.VIDEO_PROP_PANEL_PLAY_AGAIN]: settingsReducer,
        [ActionTypes.VIDEO_PROP_PANEL_PLAY_MUTED]: settingsReducer,
        [ActionTypes.VIDEO_PROP_PANEL_AUTO_RESIZE]: settingsReducer,
        [ActionTypes.YOUTUBE_REPLACE_VIDEO_UPDATE]: updateYoutubeLinkReducer,
        [TOGGLE_MOBILE_DOWN_ACTION]: mobileDownReducer,
        [ActionTypes.VIDEO_FILE_UPDATE]: updateVideoFileReducer,
        [ActionTypes.VIDEO_PROP_PANEL_AUTO_PLAY_MUTED]: (component, action) => {
            const updatedComponent = settingsReducer(component, action);
            if (muteVideoByDefault(updatedComponent, VideoSettings.autoPlay, VideoSettings.muted)) {
                return settingsReducer(updatedComponent, { payload: { setting: VideoSettings.muted } });
            }
            return updatedComponent;
        },
    }
});

