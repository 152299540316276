import React from 'react';
import { getAppConfig } from "../../../../App/epics/appConfig/appConfig";
import AppConfig from "../../../../../utils/AppConfig";
import { PartnerName } from '../../../../../../../server/shared/partners/PartnerName';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import * as pagesIds from './ids';
import pagesTitles from './titles';
import type { Props } from '../flowTypes';
import NavigationGroup from "../../../../PropertiesPanel/view/IntlNavigationGroup";
import { shouldUseOneConnectAPI } from '../../utility';

const
    id = pagesIds.MAIN,
    title = pagesTitles[id],
    appConfig = AppConfig(getAppConfig()),
    partnerName = appConfig.getOptional(`partnerName`, PartnerName.ONECOM),
    view = ({ navigateToPage }: Props) => {
        const pageIds = [
            pagesIds.STYLE,
            pagesIds.BUTTONS,
            pagesIds.EMAILSETTINGS,
            pagesIds.SUCCESSMESSAGE
        ];

        if (partnerName === PartnerName.ONECOM) {
            pageIds.push(pagesIds.CAPTCHA);
        }

        if (shouldUseOneConnectAPI()) {
            pageIds.unshift(pagesIds.MARKETING_CONSENT);
        }

        return (
            <Page>
                <NavigationGroup
                    navigateToPage={navigateToPage}
                    pagesTitles={pagesTitles}
                    targetPagesIds={pageIds}
                />
            </Page>
        );
    };

export { id, title, view };
