
import React from "react";
import cx from 'classnames';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import getCenteredDialog from "../../../../DialogManager/getCenteredDialogConfig";
import type { GoogleReviewsPremiumDialogPropTypes } from "../../flowTypes";
import { injectIntl } from "../../../../../view/intl/index";
import styles from './configurationDialog.css';
import { StripTypes } from "../../../../../view/common/dialogs/baseDialog/index";
import StandardDialog from "../../../../../view/common/dialogs/StandardDialog/index";
import { tryUpgradeDialogCancelled } from "../../../../ComponentTierManager/actionCreators";
import ComponentTypes from "../../../../../view/oneweb/registry/ComponentTypes";
import subscriptionDataEpic from '../../../../App/epics/subscriptionData/index';
import { getEcommercePackageNameFromMetadata } from '../../../../ComponentTierManager/utils';
import { replaceDialogWithUpgradePlanDialog } from "../../../../TopBar/view/Upgrade/UpgradePlansDialog/OpenUpgradePlanDialog";
import { isTrialSubscription, isUnknownSubscription } from "../../../../App/epics/subscriptionData/utils";
import { closeDialog } from "../../../../App/actionCreators";
import { upgradeDialogOpenedAction } from "../../../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions";
import { GoogleReviewsKind } from "../../kind";
import { UpgradePlansDialogId } from "../../../../TopBar/view/dialogIds";

class GoogleReviewsPremiumDialog extends React.Component<GoogleReviewsPremiumDialogPropTypes> {
    render() {
        const { props: { dispatch, intl, subscriptionData } } = this,
            { metadata: subscriptionMetadata, subscriptionType } = subscriptionData,
            eCommercePackageNameFromMetadata = getEcommercePackageNameFromMetadata(subscriptionMetadata),
            isDemoUserOrUnknown = isTrialSubscription(subscriptionType) ||
                    isUnknownSubscription(subscriptionType);

        return <StandardDialog
            title={
                intl.msgJoint([
                    "msg: componentTier.ecommerceTier.businessEcommerceFeature {{packageName} feature}",
                    { packageName: eCommercePackageNameFromMetadata ||
                        intl.msgJoint('msg: common.business_ecommerce {Business + Ecommerce}') }
                ])
            }
            titleBoxClassName={styles.titleBox}
            titleClassName={styles.title}
            className={styles.dialog}
            iconClass={styles.premiumFeatureIcon}
            mctaText="msg: common.upgrade {Upgrade}"
            sctaText="msg: common.close {Close}"
            mctaHandler={() => {
                dispatch(replaceDialogWithUpgradePlanDialog(subscriptionData, 'SiteSettings:BusinessListing'));
                dispatch(upgradeDialogOpenedAction({
                    feature: GoogleReviewsKind,
                    dialogId: UpgradePlansDialogId
                }));
            }}
            sctaHandler={() => { dispatch(closeDialog()); }}
            onClose={() => dispatch(tryUpgradeDialogCancelled(ComponentTypes.GOOGLE_REVIEWS))}
            stripType={StripTypes.NONE}
            footerClassName={styles.footerClass}
            primaryBtnClass={cx(styles.upgradeButton, { [styles.hide]: isDemoUserOrUnknown })}
            sctaBtnClass={cx(styles.closeButton, { [styles.hide]: !isDemoUserOrUnknown })}
        >
            <div className={styles.premiumComponentIllustration} />
            <VerticalSpacer y={10} />
            <div className={styles.message}>
                {/* eslint-disable-next-line max-len */}
                {intl.msgJoint('msg: component.googleReviews.premiumComponent.message {The Google reviews widget is great if you easily want to show reviews on your site. Try it out before upgrading.}')}
            </div>
        </StandardDialog>;
    }
}

export default {
    ...getCenteredDialog({
        width: 364,
        height: 340,
        component: injectIntl(GoogleReviewsPremiumDialog)
    }),
    dependsOn: {
        subscriptionData: subscriptionDataEpic.reducer,
    }
};
