import kind from "./kind";
import StripConfig from '../Strip/index';
import reducer from './reducer/index';
import { ComponentNames } from "../constants";
import componentMainActions from "./componentMainActions/index";
import componentPanelActions from "./componentPanelActions/index";
import splitDecorationEpic from "./epics/splitSectionDecoration/index";
import { getTitleForSection } from "../../Workspace/epics/componentsEval/getSectionTitles";
import type { ComponentsMap } from "../../../redux/modules/children/workspace/flowTypes";
import type { SectionComponent } from "./flowTypes";
import { workspace } from "./view/workspace";
import { selectedComponentIsHeaderEpic } from "../../Workspace/epics/selectedComponentIsHeaderEpic/index";
import { selectedComponentIsFooterEpic } from "../../Workspace/epics/selectedComponentIsFooterEpic/index";
import { sectionPropertiesPanel } from './propertiesPanel/sectionPropertiesPanel';
import { shareHeaderAndFirstSectionBgImgEpic } from './epics/shareHeaderAndFirstSectionBgImgEpic';
import { topMostPageSectionIdEpic } from './epics/topMostPageSectionIdEpic';
import { headerSectionIdEpic } from './epics/headerSectionIdEpic';
import { topMostPageSectionBackgroundImageIsSetEpic } from './epics/topMostPageSectionBackgroundImageIsSetEpic';
import defaultHeaderFooterEpic from '../../Workspace/epics/defaultHeaderFooterHeights/index';
import { isModernLayoutActivatedEpic } from "../../Workspace/epics/isModernLayoutActivatedEpic/index";
import { templateEpic } from '../Template/epics/template/index';
import webshopMHFEpic from '../WebShopMHF/epic/index';
import { isPremiumUserReducer, isECommerceUserReducer } from "../../App/epics/subscriptionData/index";

export default {
    ...StripConfig,
    dependsOn: {
        ...StripConfig.dependsOn,
        splitData: splitDecorationEpic.reducer,
        shareHeaderAndFirstSectionBgImg: shareHeaderAndFirstSectionBgImgEpic.reducer,
        headerSectionId: headerSectionIdEpic.reducer,
        topMostPageSectionId: topMostPageSectionIdEpic.reducer,
        defaultHeaderFooter: defaultHeaderFooterEpic.reducer,
        isModernLayoutActivated: isModernLayoutActivatedEpic.reducer,
        templateData: templateEpic.reducer,
        webshopMHFData: webshopMHFEpic.reducer
    },
    controlsDependsOn: {
        selectedComponentIsHeader: selectedComponentIsHeaderEpic.reducer,
        selectedComponentIsFooter: selectedComponentIsFooterEpic.reducer,
        topMostPageSectionBackgroundImageIsSet: topMostPageSectionBackgroundImageIsSetEpic.reducer,
        isPremiumUser: isPremiumUserReducer,
        isEcommerceUser: isECommerceUserReducer,
    },
    workspace,
    kind,
    reducer,
    componentMainActions,
    propertiesPanel: sectionPropertiesPanel,
    componentPanelActions,
    label: ComponentNames[kind],
    shortcutIconName: 'section',
    onBeforeComponentAdded: (cmp: SectionComponent, cmpsMap: ComponentsMap): SectionComponent => {
        return { ...cmp, title: getTitleForSection(cmpsMap, cmp) };
    },
};
