export const
    PAGE_LOADED = 'PAGE_LOADED',
    ENTERED_MOBILE_VIEW_EDITOR = 'ENTERED_MOBILE_VIEW_EDITOR',
    LEAVING_MOBILE_VIEW_EDITOR = 'LEAVING_MOBILE_VIEW_EDITOR',
    STATE_UPDATION = 'STATE_UPDATION',
    SCOPE_UPDATION = 'SCOPE_UPDATION',
    MOBILE_EDITOR_COMPONENT_MOVED = 'MOBILE_EDITOR_COMPONENT_MOVED',
    MOBILE_SETTINGS_CHANGED = 'MOBILE_SETTINGS_CHANGED',
    COMPONENTS_DELETED = 'COMPONENTS_DELETED',
    COMPONENTS_UPDATED = 'COMPONENTS_UPDATED';
