import React from 'react';
import * as styles from './DisableCaptchaWarning.css';
import * as Actions from '../../../actionTypes';
import { Msg } from "../../../../../../view/intl";
import { closeDialog } from "../../../../../App/actionCreators/index";
import PrimaryButton from "../../../../../../view/common/Button/PrimaryButton";
import SecondaryButton from "../../../../../../view/common/Button/SecondaryButton";
import { Dialog, DialogBody } from '../../../../../../view/common/dialogs/baseDialog/index';
import getCenteredDialogConfig from "../../../../../DialogManager/getCenteredDialogConfig";
import { StripTypes } from "../../../../../../view/common/dialogs/baseDialog/Dialog";

const DialogWidth = parseInt(styles.DialogWidth, 10),
    DialogHeight = parseInt(styles.DialogHeight, 10);

const DisableCaptchaWarning = ({ dispatch }) => {
    const closeDialogAC = () => { dispatch(closeDialog()); };
    return (
        <Dialog onClose={closeDialogAC} stripType={StripTypes.NONE} closeBtnClassName="closeBtn">
            <DialogBody className={styles.dialogBody}>
                <div className={styles.warningIcon} />
                <h3 className={styles.disableCaptchaTitle}>
                    <Msg k="component.contactForm.captcha.disableCaptchaTitle">
                        {`Disable Captcha`}
                    </Msg>
                </h3>
                <p className={styles.disableCaptchaMessage}>
                    <Msg k="component.contactForm.captcha.disableCaptchaWarning">
                        {`If you turn off the Captcha, your contact form will no 
                        longer be protected from bots and you might receive spam messages.`}
                    </Msg>
                </p>
                <div className={styles.footerButtons}>
                    <SecondaryButton
                        onClick={() => dispatch({
                            type: Actions.CONTACT_FORM_CANCEL_DISABLE_CAPTCHA
                        })}
                        className={`${styles.button}`}
                    >
                        <Msg k="common.cancel">
                            Cancel
                        </Msg>
                    </SecondaryButton>
                    <PrimaryButton
                        onClick={() => dispatch({
                            type: Actions.CONTACT_FORM_DISABLE_CAPTCHA
                        })}
                        className={`${styles.button}`}
                    >
                        <Msg k="common.disable">
                            Disable
                        </Msg>
                    </PrimaryButton>
                </div>
            </DialogBody>
        </Dialog>);
};

export default getCenteredDialogConfig(DialogWidth, DialogHeight, DisableCaptchaWarning);
