import React from 'react';
import View from './index';
import BaseComponent from '../../../../../view/oneweb/BaseComponent';

export default (props: any) => {
    return (
        <BaseComponent {...props}>
            <View {...props} />
        </BaseComponent>
    );
};
