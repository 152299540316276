/* eslint-disable no-param-reassign, no-use-before-define */

import type {
    AnyComponent,
    ComponentsMap
} from "../../../../redux/modules/children/workspace/flowTypes";
import type { RelationsMap, Tree } from "./flowTypes";

import Code from "../../../oneweb/Code/kind";
import CodeLocationTypes from "../../../oneweb/Code/locationTypes";
import { orderIndexSorter, isChildCompletelyInsideParent } from "../componentAttachements/util";
import { isStripKind, isSectionKind, isHoverBoxKind } from "../../../oneweb/componentKinds";
import { isStretchComponentKind, fullWidthCmpMap } from "../../../oneweb/isStretchComponentKind";
import { maxLeftOrRightForStretchedKind } from "./constants";
import { ComponentsNotAllowedInHoverBox } from "../../../oneweb/HoverBox/constants";

const
    getBBoxFromTreeValue = ({ minX, minY, maxX, maxY }) => ({
        left: minX,
        top: minY,
        right: maxX,
        bottom: maxY
    }),
    isRelInCmpInMap = (map, cmpId, changes, componentsMap) => {
        if (changes[cmpId]) {
            if (map[changes[cmpId].relIn.id]) {
                return true;
            }
        } else if (componentsMap[cmpId].relIn &&
            map[componentsMap[cmpId].relIn.id]) {
            return true;
        }
        return false;
    },
    getComponentsToUpdateRelIns = (selectedComponents, tree, componentsMap, changes) => {
        // 1. Components with selectedComponent as relIns
        // 2. Components which are intersecting selected components
        // 3. Components which are children of intersecting components found in step 2
        // 4. Selected components itself if they are not deleted
        let
            result: Record<string, any> = {}, // Use object to avoid duplicates
            intersectingComponents: Array<Record<string, any>> = [];

        // 1. Components with selectedComponent as relIn
        const
            selectedComponentIdsMap = selectedComponents.reduce((acc, cmp) => {
                // 2. Components which are intersecting selected components
                const { id, kind, left, top, width, height, inTemplate } = cmp,
                    isStrip = isStripKind(kind);
                intersectingComponents = intersectingComponents.concat(tree.search({
                    minX: left,
                    minY: top,
                    maxX: left + width,
                    maxY: top + height
                })
                    // If selected cmp is template cmp, we can avoid page cmps
                    .filter(item => {
                        if (item.id === id || (isStrip && isStripKind(item.kind))) {
                            return false;
                        }
                        if (inTemplate && !isStrip) {
                            return item.inTemplate;
                        }
                        return true;
                    }));

                // 4. Selected components or its children in case selected cmp is deleted
                result[id] = true;
                acc[id] = true;
                return acc;
            }, {}),
            cmpIds = Object.keys(componentsMap);
        cmpIds.forEach(cmpId => {
            if (isRelInCmpInMap(selectedComponentIdsMap, cmpId, changes, componentsMap)) {
                result[cmpId] = true;
            }
        });

        // 3. Components which are children of intersecting components found in step 2
        // This is mainly to update relins of siblings
        const intersectingComponentsIdsMap = intersectingComponents.reduce((acc, iCmp) => {
            // collect cmps found in step 2
            result[iCmp.id] = true;
            acc[iCmp.id] = true;
            return acc;
        }, {});
        cmpIds.forEach(cmpId => {
            if (isRelInCmpInMap(intersectingComponentsIdsMap, cmpId, changes, componentsMap)) {
                result[cmpId] = true;
            }
        });

        return Object.keys(result);
    },
    removeHoverBoxCmpsIfNotFullyOverlapping = (intersections, cmp) => {
        const hoverBoxCmpIndex = intersections.findIndex(c => isHoverBoxKind(c.kind));
        if (hoverBoxCmpIndex === -1 || isChildCompletelyInsideParent(cmp, intersections[hoverBoxCmpIndex])) {
            return intersections;
        }
        for (let i = 0; i <= hoverBoxCmpIndex; i++) {
            if (!isChildCompletelyInsideParent(intersections[i], intersections[hoverBoxCmpIndex]) ||
                isChildCompletelyInsideParent(cmp, intersections[i])) {
                return intersections.slice(i, intersections.length);
            }
        }
        return intersections.slice(hoverBoxCmpIndex + 1, intersections.length);
    },
    updateRelIn = (cmp, tree, changes, templateWidth, componentsMap) => {
        // get cmps intersecting with mid point of current cmp
        const
            { id: cmpId, kind, left: cmpLeft, top: cmpTop, height, width, inTemplate, stretch = false } = cmp;
        const
            midX = isStretchComponentKind(kind, stretch) ? (templateWidth / 2) : Math.round(cmpLeft + (width / 2)),
            midY = Math.round(cmpTop + (height / 2)),
            intersections = tree.search({
                minX: midX,
                minY: midY,
                maxX: midX,
                maxY: midY
            })
                .filter(item => {
                    const intersectingCmpIsFullWidthCmp =
                        (fullWidthCmpMap.hasOwnProperty(item.kind)
                            && isStretchComponentKind(item.kind, componentsMap[item.id].stretch || false))
                        && item.id !== cmpId;
                    if (intersectingCmpIsFullWidthCmp) return false;

                    const currentCmpIsFullWidthIntersectingWithOtherFullWidthCmp =
                        (fullWidthCmpMap.hasOwnProperty(kind) && isStretchComponentKind(kind, componentsMap[cmpId].stretch || false))
                        && (fullWidthCmpMap.hasOwnProperty(item.kind)
                            && isStretchComponentKind(item.kind, componentsMap[item.id].stretch || false))
                        && item.id !== cmpId;
                    if (currentCmpIsFullWidthIntersectingWithOtherFullWidthCmp) return false;

                    // const currentFullWidthCmpIntersectingWithStrip =
                    //     (fullWidthCmpMap.hasOwnProperty(kind) && isStretchComponentKind(kind, componentsMap[cmpId].stretch || false))
                    //     && isStripKind(item.kind) && item.id !== cmpId;
                    //if (currentFullWidthCmpIntersectingWithStrip) return false;

                    if (isStripKind(kind) && isStripKind(item.kind) && item.id !== cmpId) return false;
                    if (inTemplate && !cmp.isStickyToHeader) {
                        return item.inTemplate;
                    }
                    if (item.id !== cmpId) {
                        if (ComponentsNotAllowedInHoverBox.includes(cmp.kind)) {
                            return !(item.onHover || isHoverBoxKind(item.kind));
                        }
                        if (cmp.onHover) {
                            return isHoverBoxKind(item.kind) ||
                                (item.onHover && cmp.onHover && item.onHover.show === cmp.onHover.show);
                        }
                    }
                    return true;
                });
        intersections.sort(orderIndexSorter);

        delete changes[cmpId];

        if (kind === Code &&
            (cmp.location === CodeLocationTypes.BeforeClosingHead ||
                cmp.location === CodeLocationTypes.BeforeClosingBody)) {
            return;
        }

        // next cmp in order would be the parent for current cmp
        const index = intersections.findIndex(item => item.id === cmpId);

        if (index < intersections.length - 1) {
            let updatedIntersections = intersections.slice(index + 1, intersections.length);
            updatedIntersections = removeHoverBoxCmpsIfNotFullyOverlapping(updatedIntersections, intersections[index]);
            if (updatedIntersections.length) {
                const
                    parentId = updatedIntersections[0].id,
                    parentBbox = getBBoxFromTreeValue(updatedIntersections[0]),
                    isParentStretchedCmp = isStretchComponentKind(componentsMap[parentId].kind, componentsMap[parentId].stretch),
                    parent = {
                        id: parentId,
                        ...parentBbox,
                        left: isParentStretchedCmp ? 0 : parentBbox.left,
                        right: isParentStretchedCmp ? templateWidth + maxLeftOrRightForStretchedKind : parentBbox.right
                    };

                changes[cmpId] = {
                    relIn: {
                        id: parent.id,
                        top: cmpTop - parent.top,
                        left: stretch && fullWidthCmpMap.hasOwnProperty(kind) ? 0 : cmpLeft - parent.left,
                        right: stretch && fullWidthCmpMap.hasOwnProperty(kind) ? templateWidth + maxLeftOrRightForStretchedKind
                            : (cmpLeft + width) - parent.right,
                        bottom: (cmpTop + height) - parent.bottom
                    }
                };
            }
        }
    };

const isRelInChanged = (oldChange, newChange) => {
    if (!oldChange && !newChange) {
        return false;
    }
    return (
        (oldChange && !newChange) ||
        (!oldChange && newChange) ||
        (oldChange.relIn.id !== newChange.relIn.id) ||
        (oldChange.relIn.left !== newChange.relIn.left) ||
        (oldChange.relIn.right !== newChange.relIn.right) ||
        (oldChange.relIn.top !== newChange.relIn.top) ||
        (oldChange.relIn.bottom !== newChange.relIn.bottom)
    );
};

export const findRelIn = (
    tree: Tree,
    selectedComponents: Array<AnyComponent>,
    { changes: prevChanges }: RelationsMap,
    componentsMap: ComponentsMap,
    allComponents?: boolean,
    // @ts-ignore
    templateWidth: number,
) => {
    let changes = { ...prevChanges }, relInChanged = false;
    const componentsToUpdate = allComponents ? Object.keys(componentsMap) :
        getComponentsToUpdateRelIns(selectedComponents, tree, componentsMap, changes);
    componentsToUpdate.forEach(id => {
        const cmp = componentsMap[id];
        if (!isSectionKind(cmp.kind)) {
            updateRelIn(cmp, tree, changes, templateWidth, componentsMap);
            if (!relInChanged && isRelInChanged(prevChanges[id], changes[id])) {
                relInChanged = true;
            }
        }
    });
    return { changes, relInChanged };
};
/* eslint-enable no-param-reassign, no-use-before-define */
