export const TrialLoginGTMAction = {
    USER_SCROLLED_NEW_SIGNUP_PAGE_WITH_MODAL: 'USER_SCROLLED_NEW_SIGNUP_PAGE_WITH_MODAL',
    USER_SCROLLED_NEW_SIGNUP_PAGE_WITHOUT_MODAL: 'USER_SCROLLED_NEW_SIGNUP_PAGE_WITHOUT_MODAL',
    CLICKED_GET_STARTED_FAST_AND_EASY_WITH_MODAL_SCROLLED: 'CLICKED_GET_STARTED_FAST_AND_EASY_WITH_MODAL_SCROLLED',
    CLICKED_GET_STARTED_FAST_AND_EASY_WITHOUT_MODAL_SCROLLED: 'CLICKED_GET_STARTED_FAST_AND_EASY_WITHOUT_MODAL_SCROLLED',
    CLICKED_GET_STARTED_FAST_AND_EASY_WITH_MODAL_UNSCROLLED: 'CLICKED_GET_STARTED_FAST_AND_EASY_WITH_MODAL_UNSCROLLED',
    CLICKED_GET_STARTED_FAST_AND_EASY_WITHOUT_MODAL_UNSCROLLED: 'CLICKED_GET_STARTED_FAST_AND_EASY_WITHOUT_MODAL_UNSCROLLED',
    CLICKED_GET_STARTED_UNIQUE_TEMPLATES_WITH_MODAL_SCROLLED: 'CLICKED_GET_STARTED_UNIQUE_TEMPLATES_WITH_MODAL_SCROLLED',
    CLICKED_GET_STARTED_UNIQUE_TEMPLATES_WITHOUT_MODAL_SCROLLED: 'CLICKED_GET_STARTED_UNIQUE_TEMPLATES_WITHOUT_MODAL_SCROLLED',
    CLICKED_GET_STARTED_UNIQUE_TEMPLATES_WITH_MODAL_UNSCROLLED: 'CLICKED_GET_STARTED_UNIQUE_TEMPLATES_WITH_MODAL_UNSCROLLED',
    CLICKED_GET_STARTED_UNIQUE_TEMPLATES_WITHOUT_MODAL_UNSCROLLED: 'CLICKED_GET_STARTED_UNIQUE_TEMPLATES_WITHOUT_MODAL_UNSCROLLED',
    CLICKED_GET_STARTED_CUSTOMER_SUPPORT_WITH_MODAL_SCROLLED: 'CLICKED_GET_STARTED_CUSTOMER_SUPPORT_WITH_MODAL_SCROLLED',
    CLICKED_GET_STARTED_CUSTOMER_SUPPORT_WITHOUT_MODAL_SCROLLED: 'CLICKED_GET_STARTED_CUSTOMER_SUPPORT_WITHOUT_MODAL_SCROLLED',
    CLICKED_GET_STARTED_CUSTOMER_SUPPORT_WITH_MODAL_UNSCROLLED: 'CLICKED_GET_STARTED_CUSTOMER_SUPPORT_WITH_MODAL_UNSCROLLED',
    CLICKED_GET_STARTED_CUSTOMER_SUPPORT_WITHOUT_MODAL_UNSCROLLED: 'CLICKED_GET_STARTED_CUSTOMER_SUPPORT_WITHOUT_MODAL_UNSCROLLED',
    CLICKED_START_FREE_TRIAL_NEW_SIGNUP_WITH_MODAL: 'CLICKED_START_FREE_TRIAL_NEW_SIGNUP_WITH_MODAL',
    CLICKED_START_FREE_TRIAL_NEW_SIGNUP_WITHOUT_MODAL: 'CLICKED_START_FREE_TRIAL_NEW_SIGNUP_WITHOUT_MODAL',
    DEMO_SIGNUP_SUCCESSFUL_NEW_SIGNUP_PAGE_WITH_MODAL: 'DEMO_SIGNUP_SUCCESSFUL_NEW_SIGNUP_PAGE_WITH_MODAL',
    DEMO_SIGNUP_SUCCESSFUL_NEW_SIGNUP_PAGE_WITHOUT_MODAL: 'DEMO_SIGNUP_SUCCESSFUL_NEW_SIGNUP_PAGE_WITHOUT_MODAL',
    DEVICE_BLOCKED: 'DEVICE_BLOCKED',
    CLICKED_START_FREE_TRIAL: 'CLICKED_START_FREE_TRIAL',
    VIEWED_SIGNUP_PAGE: 'VIEWED_SIGNUP_PAGE',
    DEMO_SIGNUP_SUCCESSFUL: 'DEMO_SIGNUP_SUCCESSFUL'
};

export const TrialLoginGTMCategory = {
    VIEW: 'View',
    CLICK: 'Click'
};
