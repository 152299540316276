import { AITextErrorMap } from "../../AIText/Dialogs/CreateAIText/constants";
import { rephraseBtn } from "../../AIText/Dialogs/EditAIText/EditingAssistant/constants";
import { AITextEventNames } from "../AITextEpic/events";
import { AI_EDIT_COPY_BUTTON_CLICKED, AI_EDIT_INSERT_BUTTON_CLICKED,
    REGENERATE_EDIT_TEXT_FAILED_EVENT, REGENERATE_TEXT_BUTTON_CLICKED } from "./actionTypes";

const TextModifiedWithSuggestion = 'WSB AI text modified with suggestions',
    TextModifiedWithSInstruction = 'WSB AI text modified with instruction',
    TextUsed = 'WSB modified AI text used';

const PropertyNames = {
    modificationType: 'AI modification type',
    textInserted: 'Text inserted',
    errorType: 'Error type',
    aiTextType: 'WSB AI text type'
};

const modificationType = {
    rephrase: 'rephrase',
    makeShorter: 'make shorter',
    makeLonger: 'make longer',
    instruction: 'instruction'
};

const onCopyOrInsertProps = (isInsert = false) => {
    return {
        eventName: TextUsed,
        propsHandler: (_, payload) => {
            const { mode, instructions } = payload;
            return {
                [PropertyNames.textInserted]: isInsert,
                [PropertyNames.modificationType]: instructions ? modificationType.instruction : modificationType[mode]
            };
        }
    };
};

const EditTextMixpanelEvents = {
    [REGENERATE_TEXT_BUTTON_CLICKED]: {
        getEventName: (_, payload) => {
            const { mode, instructions } = payload || {};
            return mode === rephraseBtn.id && instructions ? TextModifiedWithSInstruction : TextModifiedWithSuggestion;
        },
        propsHandler: (_, payload) => {
            const { mode, instructions } = payload;
            return instructions ? {} : { [PropertyNames.modificationType]: modificationType[mode] };
        }
    },
    [AI_EDIT_INSERT_BUTTON_CLICKED]: onCopyOrInsertProps(true),
    [AI_EDIT_COPY_BUTTON_CLICKED]: onCopyOrInsertProps(false),
    [REGENERATE_EDIT_TEXT_FAILED_EVENT]: {
        eventName: AITextEventNames.apiError,
        propsHandler: (_, { errorState, currentVersion }) => {
            const { mode, instructions } = currentVersion || {};
            return {
                [PropertyNames.errorType]: AITextErrorMap[errorState],
                [PropertyNames.modificationType]: instructions ? modificationType.instruction : modificationType[mode],
                [PropertyNames.aiTextType]: 'edit'
            };
        }
    },

};

export {
    EditTextMixpanelEvents
};
