export const
    UPDATE_APP_STATE = 'UPDATE_APP_STATE',
    SET_APP_STATE = 'SET_APP_STATE',
    SHOW_HELP = 'SHOW_HELP',
    WINDOW_MOUSE_UP = 'WINDOW_MOUSE_UP',
    APP_LEFT_MOUSE_DOWN = 'APP_LEFT_MOUSE_DOWN', // WBTGEN-6823 TODO rename to WINDOW_LEFT_MOUSE_DOWN
    WINDOW_RESIZED = 'WINDOW_RESIZED',
    WINDOW_MOUSE_MOVE = 'WINDOW_MOUSE_MOVE',
    OPEN_DIALOG = 'OPEN_DIALOG',
    CLOSE_DIALOG = 'CLOSE_DIALOG',
    CLOSE_DIALOG_BY_ID = 'CLOSE_DIALOG_BY_ID',
    CLOSE_DIALOG_BY_ID_ONLY_IF_TOP_MOST = 'CLOSE_DIALOG_BY_ID_ONLY_IF_TOP_MOST',
    UPDATE_DIALOG = 'UPDATE_DIALOG',
    REPLACE_DIALOG = 'REPLACE_DIALOG',
    CLOSE_PERSISTENT_DIALOG = 'CLOSE_PERSISTENT_DIALOG',
    DUPLICATE_PAGE = 'DUPLICATE_PAGE',
    PAGE_INFO = 'PAGE_INFO',
    DELETE_PAGE = 'DELETE_PAGE',
    _PAGE_TEMPLATES_SET = '_PAGE_TEMPLATES_SET';

