/* @flow */

const TrialAuthPage = {
    NEW_SIGNUP: 'new-signup',
    VERIFICATION: 'email-verification-successful',
    SIGNUP: 'signup',
    SIGNIN: 'login',
    FORGOT_PASSWORD: 'forgot-password',
    RESET_PASSWORD: 'reset-password',
    TRIAL_ENDED: 'trial-ended',
    EMAIL_ALREADY_VERIFIED: 'email-already-verified',
    USER_NOT_FOUND: 'user-not-found',
    VERIFICATION_EMAIL_REQUIRED: 'verification-email-required',
    MOBILE_DEVICES_BLOCKED: 'mobile-devices-blocked'
};

module.exports = {
    TrialAuthPage
};
