import * as React from 'react';
import styles from '../Mobile/Mobile.css';
import WarningDialog from "../../../../view/common/dialogs/WarningDialog/index";
import getCenteredDialogConfig from "../../../DialogManager/getCenteredDialogConfig";
import { Msg } from "../../../../view/intl/index";
import { disableMobileVersionAC } from "../actionCreators";
import { CLOSE_DIALOG } from "../../../../redux/modules/actionTypes";

const
    WIDTH = 500,
    HEIGHT = 350,
    title = 'msg: preview.mobileOption.turnOffMobile {Turn off mobile view?}';

type PropsType = {
    dispatch: Dispatch;
}
const DisableMobileOptionDialogCom = ({ dispatch }: PropsType) => (
    <WarningDialog
        style={{ zIndex: 200000 }}
        title={title}
        mctaText="msg: preview.mobileOption.turnOff {Turn off}"
        mctaHandler={() => dispatch(disableMobileVersionAC())}
        sctaHandler={() => dispatch({ type: CLOSE_DIALOG })}
    >
        <p className={styles.warningDialogTurnOffMobileViewText}>
            <Msg k="preview.mobileOption.warningMsg1">
                Your visitors will not see this mobile friendly view.
                Instead they will see the desktop version of your site.
                Please keep in mind that the desktop version does not always fit the screen of a mobile device.
            </Msg>
        </p>
        <br />
        <p className={styles.warningDialogTurnOffMobileViewText}>
            <Msg k="preview.mobileOption.warningMsg2">
                We recommend keeping the mobile view activated,  to get the best mobile experience.
            </Msg>
        </p>
    </WarningDialog>
);

export const disableMobileOptionWarningDialog = getCenteredDialogConfig(
    WIDTH,
    HEIGHT,
    DisableMobileOptionDialogCom
);
