import { receiveOnly, withSelector } from "../../../epics/makeCondition";
import { dialogManagerVAT } from "./valueActionType";
import { openedDialogIdsSelector } from "./selectors";

const
    OpenedDialogsIdsSelector = withSelector(dialogManagerVAT, openedDialogIdsSelector),
    ROOpenedDialogsIdsSelector = receiveOnly(dialogManagerVAT, openedDialogIdsSelector);

export {
    OpenedDialogsIdsSelector,
    ROOpenedDialogsIdsSelector
};
