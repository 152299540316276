/* eslint-disable max-len */

import React from 'react';
import { injectIntl } from '../../view/intl/index';
import { Dialog, DialogBody } from '../../view/common/dialogs/baseDialog/index';
import getCenteredDialogConfig from '../DialogManager/getCenteredDialogConfig';
import { HIDE_DEPRECATED_BROWSER_DIALOG, UPGRADE_BROWSER } from './actionTypes';
import DialogTitleBox from "../../view/common/dialogs/baseDialog/DialogTitleBox";
import { StripTypes } from "../../view/common/dialogs/baseDialog/Dialog";
import warningStyles from '../../view/common/dialogs/WarningDialog/WarningDialog.css';
import styles from './styles.css';
import DialogFooter from "../../view/common/dialogs/baseDialog/DialogFooter";

const
    width = 480,
    height = 340,
    unsupportedBrowserTitle = 'msg: unsupported.browser.title {Unsupported browser}',
    getUnsupportedBrowserMessage = intl => intl.msgJoint('msg: unsupported.browser.message {Your browser is not supported or may be out of date. For the best experience using Website Builder, please upgrade your browser.}'),
    upgradeBrowserDialogComponent = injectIntl(({ intl, dispatch }) => {
        const sctaHandler = () => dispatch({ type: HIDE_DEPRECATED_BROWSER_DIALOG });
        return (
            <Dialog stripType={StripTypes.WARNING} onClose={sctaHandler}>
                <DialogTitleBox
                    title={unsupportedBrowserTitle}
                    iconClass={warningStyles.iconWarning}
                    titleClass={styles.unsupportedBrowserTitle}
                    className={warningStyles.warningDialogTitle}
                />
                <DialogBody>
                    <p>{getUnsupportedBrowserMessage(intl)}</p>
                </DialogBody>
                <DialogFooter
                    noTopBorder
                    className={warningStyles.warningDialogFooter}
                    mctaText="msg: common.upgrade {Upgrade}"
                    mctaHandler={() => dispatch({ type: UPGRADE_BROWSER })}
                    sctaText="msg: common.continue {Continue}"
                    sctaHandler={sctaHandler}
                />
            </Dialog>
        );
    }),
    upgradeBrowserDialog = getCenteredDialogConfig(width, height, upgradeBrowserDialogComponent);

export const dialog = upgradeBrowserDialog;
