import { connect } from 'react-redux';
import React from "react";
import cx from 'classnames';
import { injectIntl, Intl } from "../../../view/intl/index";
import * as styles from "./SocialShareTab.css";
import { makeEpicStateSelector } from '../../../epics/makeEpic';
import { socialShareGlobalDataEpic } from './socialShareGlobalDataEpic';
import type { SocialShareGlobalDataStateType } from './socialShareGlobalDataEpic';
import HorizontalSpacer from "../../../view/common/HorizontalSpacer";
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import { ImageChooserBtnPP } from "../../FileManager/imageChooser/ImageChooserBtnPP";
import { SOCIAL_SHARE_IMAGE_CHOSEN, SOCIAL_SHARE_IMAGE_REMOVE_PRESSED } from "./actionTypes";
import { SocialShareImagePreview } from "../../SocialShare/view/SocialShareImagePreview";
import { generalGlobalDataEpic } from "../General/generalGlobalDataEpic/generalGlobalDataEpic";
import type { GeneralGlobalData } from "../General/generalGlobalDataEpic/generalGlobalDataEpic";
import { getDAL } from "../../../../dal/index";
import { validateSocialShareAsset } from "../../SocialShare/utils";
import Scrollbar from "../../../view/common/Scrollbar/index";

type SocialShareTabPropsWithIntl = {
    intl: Intl,
    dispatch: Dispatch,
    socialShareGlobalData: SocialShareGlobalDataStateType,
    generalGlobalData: GeneralGlobalData,
}

class SocialShareTab extends React.Component<SocialShareTabPropsWithIntl> {
    render() {
        const
            {
                dispatch,
                intl,
                socialShareGlobalData: {
                    socialShareAsset,
                },
                generalGlobalData: {
                    websiteTitle
                }
            } = this.props,
            domainName = getDAL().getDomain(),
            socialAShareAssetWarning = validateSocialShareAsset(socialShareAsset),
            socialShareImageHint = socialAShareAssetWarning ?
                <div className={cx(styles.imageHint, styles.warning)}>
                    {intl.msgJoint(socialAShareAssetWarning)}
                </div> :
                <div className={styles.imageHint}>
                    {/* eslint-disable-next-line max-len */}
                    {intl.msgJoint('msg: socialShare.asset.hint {This image will be used, if there are no other images added to the page you want to share. Each page can also have it’s own social share image. }')}
                </div>;

        return (
            <Scrollbar
                height="100%"
                className={styles.scrollbar}
            >
                <div className={styles.bodyContainer}>
                    <div className={styles.bodyLeftHalf}>
                        <VerticalSpacer y={12} />
                        <ImageChooserBtnPP
                            // @ts-ignore
                            asset={socialShareAsset}
                            headLabel="msg: socialShare.image {Social share image}"
                            chooseLabel="msg: addImage {Add image}"
                            changeAction={SOCIAL_SHARE_IMAGE_CHOSEN}
                            removeAction={SOCIAL_SHARE_IMAGE_REMOVE_PRESSED}
                            removeButtonTitle={intl.msgJoint("msg: socialShare.deleteImage {Delete social share image}")}
                            dispatch={dispatch}
                            eventSrc=""
                            labelMaxWidth={110}
                        />
                        <VerticalSpacer y={8} />
                        {socialShareImageHint}
                    </div>

                    <div className={styles.verticalBar}>&nbsp;</div>
                    <HorizontalSpacer x={35} />

                    <div className={styles.bodyRightHalf}>
                        <SocialShareImagePreview
                            socialShareImageAsset={socialShareAsset}
                            removeSocialShareActionType={SOCIAL_SHARE_IMAGE_REMOVE_PRESSED}
                            socialShareImageChoosenActionType={SOCIAL_SHARE_IMAGE_CHOSEN}
                            dispatch={dispatch}
                            width={470}
                            height={227}
                            headLabel="msg: socialShare.preview {Preview}"
                            className={styles.socialShareImagePreviewContainer}
                            domainUrl={domainName || intl.msgJoint("msg: socialShare.domainUrl {siteurl.com}")}
                            websiteTitle={websiteTitle || intl.msgJoint("msg: socialShare.websiteTitle {Website title}")}
                            /* eslint-disable-next-line max-len */
                            socialShareDescription={intl.msgJoint("msg: socialShare.description {Description here is taken from the page description under the SEO tab in page info, and if there is none, it will automatically be generated based on content. }")}
                        />
                    </div>
                </div>
            </Scrollbar>
        );
    }
}
const socialShareGlobalDataSelector = makeEpicStateSelector(socialShareGlobalDataEpic.valueActionType);
const generalGlobalDataSelector = makeEpicStateSelector(generalGlobalDataEpic.valueActionType);

const mapStateToProps = (appState) => ({
    socialShareGlobalData: socialShareGlobalDataSelector(appState),
    generalGlobalData: generalGlobalDataSelector(appState),
});
export default injectIntl(connect(mapStateToProps)(SocialShareTab));
