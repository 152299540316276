import type { ComponentsMap } from "../../../redux/modules/children/workspace/flowTypes";
import { getTopMostMenuComponent } from '../header/menu/getTopMostMenuComponent';
import type { Stylesheets } from '../../Workspace/epics/stylesheets/flowTypes';

type Result = {
    component: Record<string, any>,
    globalStyle: Record<string, any>,
};

export const getAutoMobileStyleComponentAndGlobalStyle = (
    componentsMap: ComponentsMap,
    stylesheet: Stylesheets,
    autoColorMode: boolean = false,
): null | undefined | Result => {
    const component = getTopMostMenuComponent(componentsMap);

    if (!component) return null;

    const globalId = (autoColorMode && component.themeStyles ? component.themeStyles.mainMenu.id : component.style.globalId);
    const globalStyle = stylesheet.styles.find(s => s.id === globalId);

    // @ts-ignore
    if (!globalStyle || !globalStyle.item) return null;

    return { component, globalStyle };
};
