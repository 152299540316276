import * as R from "ramda";
import oneColor from "onecolor";
import type { ColorGlobalData } from '../flowTypes';
import {
    UPDATE_RECENT_COLOR,
    HIDE_COLOR_PICKER
} from '../actionTypes';
import { makeSiteSettingsChildEpic } from '../../App/epics/siteSettings/makeSiteSettingsChildEpic';
import * as colorMapper from '../../../mappers/color';
import type { Stylesheets } from '../../Workspace/epics/stylesheets/flowTypes';
import {
    FILL_SPECIFIC_SITE_SETTINGS_DURING_PAGE_OR_TEMPLATE_IMPORT,
    SITE_SETTINGS_MIGRATE_HOOK
} from "../../App/epics/siteSettings/actionTypes";
import { fullEpicUndoablePath } from '../../../epics/makeEpic';

import {
    getParagraphTextColor,
    getAllLinkStyles,
    getAllButtonStyles,
    getInactiveBackgroundColor,
    getInactiveLinkFontColor,
    getInactiveButtonFontColor
} from '../../Workspace/epics/stylesheets/selectors';
import { isHSLFormat } from '../utils/parseColor';
import { toHsl } from "../../../../dal/pageMapAdapter/mappers/Base/color";
import { UNDO_INITIAL_STATE } from "../../../epics/undoManager/updateReasons";

const RECENT_COLOR_INITIALIZATION = 'RECENT_COLOR_INITIALIZATION';

const getIndex = (name) => {
    const indexArray = (/\d+/).exec(name);
    if (indexArray) return Number(indexArray[0]);
    return -1;
};

const getColor = (styles, colorExtractionFn: Function, multiple = false) => {
    const allStyles = styles
        .map(a => [a, getIndex(a.name)])
        .filter(([, aIndex]) => aIndex > -1 && aIndex < 4)
        .sort(([, aIndex], [, bIndex]) => aIndex - bIndex)
        .slice(0, 3);

    if (!multiple) {
        return allStyles.map(a => colorExtractionFn(a[0]));
    }
    return allStyles.reduce((colors, [currentStyle]) => {
        return [...colors, ...colorExtractionFn(currentStyle)];
    }, []);
};

export const getIntialColors = (stylesheets: Stylesheets) => {
    const linkStyles = getAllLinkStyles(stylesheets);
    const buttonStyles = getAllButtonStyles(stylesheets);

    const linkTextColors = getColor(linkStyles, getInactiveLinkFontColor);
    const buttonColors = getColor(buttonStyles, (style) => {
        const textColor = getInactiveButtonFontColor(style);
        const backgroundColor = getInactiveBackgroundColor(style);

        return [textColor, backgroundColor];
    }, true);

    const allColorsHexHSLMap = [getParagraphTextColor(stylesheets), ...linkTextColors, ...buttonColors]
        .filter(a => !!a)
        .reduce((acc: Record<string, any>, color) => {
            return { ...acc, [colorMapper.toHex(color)]: color };
        }, {});
    const uniqueColors: Array<string> = Array.from(new Set(Object.keys(allColorsHexHSLMap)));

    return uniqueColors.map(hexCode => allColorsHexHSLMap[hexCode]);
};

export const colorGlobalDataInitialState: ColorGlobalData = {
    recentColors: [undefined, undefined, undefined, undefined, undefined]
};

export const colorGlobalDataEpic = makeSiteSettingsChildEpic/* ::<ColorGlobalData, void> */({
    defaultState: colorGlobalDataInitialState,
    undo: {
        isUndoableChange: ({ sourceAction }) => {
            if (sourceAction && sourceAction.type === HIDE_COLOR_PICKER) {
                return false;
            }

            return true;
        },
        undoablePaths: fullEpicUndoablePath
    },
    key: 'colors',
    updaters: [
        {
            conditions: [SITE_SETTINGS_MIGRATE_HOOK],
            reducer: ({ state }: { state: ColorGlobalData }) => {
                if (
                    state.recentColors && state.recentColors.filter(a => !!a).length !== 0
                ) {
                    const allRecentColors = state.recentColors;
                    const updatedRecentColorsWithHSLFormat =
                        R.map(color => (color && !isHSLFormat(color) ? toHsl(color) : color), allRecentColors);

                    if (!R.equals(updatedRecentColorsWithHSLFormat, allRecentColors)) {
                        return {
                            state: R.assocPath(
                                ['recentColors'],
                                updatedRecentColorsWithHSLFormat,
                                state
                            ),
                            updateReason: UNDO_INITIAL_STATE
                        };
                    }
                }

                return { state };
            }
        },
        {
            conditions: [FILL_SPECIFIC_SITE_SETTINGS_DURING_PAGE_OR_TEMPLATE_IMPORT],
            reducer: ({ values: [{ designSiteSettings }], state }) => {
                const recentColors = R.path(['colors', 'recentColors'], designSiteSettings) || [];
                if (state.recentColors && state.recentColors.filter(Boolean).length === 0) {
                    return {
                        state: R.assocPath(["recentColors"], recentColors)(state),
                        updateReason: RECENT_COLOR_INITIALIZATION
                    };
                }

                return { state };
            }
        }
    ],
    handleActions: {
        [UPDATE_RECENT_COLOR]: (state, { payload: { color } }) => {
            if (!oneColor(color).hex) {
                return state;
            }

            const recentColorsFilter = state.recentColors.filter(
                recentColor =>
                    !recentColor ||
                    colorMapper.toHex(recentColor) !== colorMapper.toHex(color)
            );

            return {
                ...state,
                recentColors: [color, ...recentColorsFilter].slice(0, 5)
            };
        }
    }
});
