/* eslint-disable react/no-unused-prop-types */

import * as React from 'react';
import cx from 'classnames';
import styles from './TabDialog.css';
import { DialogTitleBox } from '../baseDialog/index';
import injectIntl, { isMsgJointInput } from "../../../intl/injectIntl";
import { TabPanelPositions } from "./constants";
import type { TabProps, TabsComponentProps, TabsComponentState } from './flowTypes';

// Something is weired about usage of this component, may be this component was heavily modified
const Tab = ({ children, className }: TabProps) => (
    <div className={className}>{children}</div>
);

class Tabs extends React.Component<TabsComponentProps, TabsComponentState> {
    static defaultProps = {
        mctaText: 'msg: common.save {Save}',
        sctaText: 'msg: common.cancel {Cancel}'
    };

    constructor({ children: tabs, activeTabKey }: TabsComponentProps) {
        // @ts-ignore
        super();
        this.state = { activeTabKey: activeTabKey || tabs[0].props.id };
    }

    shouldComponentUpdate(nextProps: TabsComponentProps): boolean {
        if (this.props.switchWithActiveTabKey && nextProps.activeTabKey !== this.state.activeTabKey) {
            // render() is binded with state in order to display the active tab state.
            // This is done in order to modify the active tab via the props being passed to the component.
            // @ts-ignore
            this.setState({ activeTabKey: nextProps.activeTabKey });
        }
        return true;
    }

    getTabs() {
        const tabs = this.props.children.filter(t => !!t);
        if (!tabs || !tabs.length || tabs.length < 1) throw new Error('Missing tabs for TabDialog');

        return tabs.filter(t => t.props.isHidden !== true);
    }

    getActiveTab() {
        const tab = this.getTabs().find(t => t.props.id === this.state.activeTabKey);
        if (!tab) throw new Error('Cannot find active tab by key: ' + this.state.activeTabKey);
        return tab;
    }

    switchTab(tab: React.Component<TabProps>) {
        this.setState({ activeTabKey: tab.props.id });

        if (this.props.onTabSwitch) this.props.onTabSwitch(tab);
    }

    renderPanel() {
        const
            {
                intl,
                panelStyle,
                panelHeaderText,
                panelItemStyle,
                panelItemActiveStyle
            } = this.props,
            activeTab = this.getActiveTab();

        return (
            <div className={cx(styles.panel, panelStyle)}>

                {panelHeaderText && <div className={styles.panelHeadingContainer}>
                    <p className={styles.panelHeading}>
                        {isMsgJointInput(panelHeaderText) ? intl.msgJoint(panelHeaderText) : panelHeaderText}
                    </p>
                </div>}

                {this.getTabs().map((t, i) => (
                    <div
                        className={cx(
                            styles.panelItem,
                            panelItemStyle,
                            { [cx(panelItemActiveStyle, styles.active)]: t.props.label === activeTab.props.label }
                        )}
                        // @ts-ignore
                        onClick={this.switchTab.bind(this, t)}
                        key={i}
                    >
                        {isMsgJointInput(t.props.label) ? intl.msgJoint(t.props.label) : t.props.label}
                        {
                            t.props.tabIcon && t.props.label === activeTab.props.label ? (
                                <span className={t.props.tabIcon} />
                            ) : null
                        }
                    </div>
                ))}
            </div>
        );
    }

    renderTabBody() {
        const
            activeTab = this.getActiveTab(),
            activeTabProps = activeTab.props,
            {
                tabBodyCustomStyle = '',
                tabHeadingCustomStyle = ''
            } = this.props;

        let body;
        if (activeTabProps.title) {
            body = [
                <DialogTitleBox
                    title={activeTabProps.title}
                    subTitleContent={activeTabProps.subTitleContent}
                    subTitleClass={activeTabProps.subTitleClass}
                    key="tabDialog.tabBody.title"
                    className={cx(
                        styles.titleBox,
                        { [styles.titleBoxBorder]: activeTabProps.border },
                        tabHeadingCustomStyle
                    )}
                    iconClass={activeTabProps.iconClass}
                    iconPosition={activeTabProps.iconPosition}
                >
                    {activeTabProps.titleContents}
                </DialogTitleBox>,
                activeTab
            ];
        } else {
            body = activeTab;
        }

        return <div className={cx(styles.tabBody, tabBodyCustomStyle, activeTabProps.tabBodyCustomStyle)}>{body}</div>;
    }

    render() {
        const { panelPosition = TabPanelPositions.LEFT } = this.props;
        return (
            <div className={cx(styles.tabsContainer, panelPosition === TabPanelPositions.TOP ? styles.panelTop : '')}>
                {this.renderPanel()}
                {this.renderTabBody()}
            </div>
        );
    }
}
const IntlTabs = injectIntl(Tabs);

export { IntlTabs as default, Tab, Tabs };
