import makeStyles from "@mui/styles/makeStyles";

import { DRAWER_WIDTH } from "../../utils/constants";

export const useStyles = makeStyles(
    theme => ({
        loader: {
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 999999
        },
        loaderContent: {
            marginTop: "16px",
        },
        container: {
            width: "100%",
            height: "100%",
            backgroundColor: theme.palette.custom.colorWhite,
            padding: 0,
            margin: 0,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch"
        },
        content: {
            flex: 1,
            display: "flex",
            alignItems: "stretch",
            overflow: "hidden",
            "& > div:last-child": {
                flex: 1
            }
        },
        formRow: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        formRowInput: {
            flex: 1
        },
        formRowButton: {
            marginLeft: "10px"
        },
        feedbackWidgetErrorRoot: {
            position: "fixed",
            right: "0",
            top: "225px",
            [theme.breakpoints.down("lg")]: {
                top: "325px"
            },
            [theme.breakpoints.down("md")]: {
                top: "200px"
            }
        },
        desktopSidebarErrorRoot: {
            width: DRAWER_WIDTH,
            padding: "8px"
        },
        mobileSidebarErrorRoot: {
            width: "100%",
            padding: "24px"
        }
    }),
    { name: "Main" }
);
