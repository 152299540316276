import WIDGETS_KIND from './kind';
import {
    WidgetsPinterestConfig,
    WidgetsTwitterConfig,
    WidgetsVimeoConfig,
    WidgetsSpotifyConfig,
    WidgetsGoogleMapsConfig,
    WidgetsInstagramConfig,
    WidgetsFacebookConfig,
    WidgetsSoundcloudConfig,
    WidgetsTiktokConfig,
    WidgetsEventbriteConfig,
    WidgetsBingMapsConfig,
    WidgetsGoFundMeConfig,
    WidgetsMailchimpConfig,
    WidgetsPaypalConfig,
    WidgetsBookingsConfig,
} from './constants';

export const WidgetsComponentNames = {
    [WIDGETS_KIND]: 'msg: component.widgets.label {Widgets}',
    [WidgetsPinterestConfig.kind]: WidgetsPinterestConfig.title,
    [WidgetsTwitterConfig.kind]: WidgetsTwitterConfig.title,
    [WidgetsVimeoConfig.kind]: WidgetsVimeoConfig.title,
    [WidgetsSpotifyConfig.kind]: WidgetsSpotifyConfig.title,
    [WidgetsGoogleMapsConfig.kind]: WidgetsGoogleMapsConfig.title,
    [WidgetsInstagramConfig.kind]: WidgetsInstagramConfig.title,
    [WidgetsFacebookConfig.kind]: WidgetsFacebookConfig.title,
    [WidgetsSoundcloudConfig.kind]: WidgetsSoundcloudConfig.title,
    [WidgetsTiktokConfig.kind]: WidgetsTiktokConfig.title,
    [WidgetsEventbriteConfig.kind]: WidgetsEventbriteConfig.title,
    [WidgetsBingMapsConfig.kind]: WidgetsBingMapsConfig.title,
    [WidgetsGoFundMeConfig.kind]: WidgetsGoFundMeConfig.title,
    [WidgetsMailchimpConfig.kind]: WidgetsMailchimpConfig.title,
    [WidgetsPaypalConfig.kind]: WidgetsPaypalConfig.title,
    [WidgetsBookingsConfig.kind]: WidgetsBookingsConfig.title

};
