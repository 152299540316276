import React from 'react';
import TextKind from "../../oneweb/Text/kind";
import * as Icon from './Icons.css';
import { sectionTitleHeight } from '../view/Inserter.css';
import { ComponentNames } from "../../oneweb/constants";
import { Content, HeaderView } from "../../oneweb/Text/expandedPanel/index";
import type { ExtendedPanelContentProps } from "../view/inserter";

export default {
    kind: TextKind,
    shortCut: {
        iconClassName: Icon.text,
        text: ComponentNames[TextKind]
    },
    extendedPanelData: {
        headerHeight: sectionTitleHeight,
        title: <HeaderView />,
        // @ts-ignore
        content: (props: ExtendedPanelContentProps) => <Content {...props} />
    }
};
