import { $Diff } from "utility-types";
import React from 'react';
import { connect } from "react-redux";
import { Intl } from "../../../intl/index";
import { Dialog, DialogTitleBox, DialogBody, DialogFooter } from '../baseDialog/index';
import type { CombinedProps as DialogV2Props } from '../StandardDialog/index';
import { closeDialog } from "../../../../components/App/actionCreators/index";

type Props = $Diff<DialogV2Props, {
    intl: Intl,
}>

const DialogV2Component = ({
    className,
    bodyClassName,
    style,
    onClose,
    closeBtnClassName,
    title,
    titleIconCmp,
    titleExtraClassName = '',
    subTitle,
    titleContents,
    titleClassName,
    titleBoxClassName,
    subTitleClass = '',
    footerContents,
    footerContentClassName,
    footerButtonContainerClassName,
    iconClass,
    mctaHandler,
    defaultSctaHandler,
    sctaHandler = defaultSctaHandler,
    mctaText,
    autoFocus = true,
    sctaText = 'msg: common.cancel {Cancel}',
    disabled,
    noTopBorderForFooter = false,
    primaryBtnClass,
    sctaBtnClass,
    showCloseBtn,
    children,
    footerClassName,
    showTitle = true,
    containerClassName = '',
}: Props) => {
    return (
        // @ts-ignore
        <Dialog
            stripType={null}
            className={className}
            style={style}
            onClose={onClose}
            showCloseBtn={showCloseBtn}
            closeBtnClassName={closeBtnClassName}
            containerClassName={containerClassName}
            newStylesV2
        >
            { showTitle && <DialogTitleBox
                title={title}
                titleIconCmp={titleIconCmp}
                subTitle={subTitle}
                iconClass={iconClass}
                className={titleBoxClassName}
                titleClass={titleClassName}
                extraClass={titleExtraClassName}
                subTitleClass={subTitleClass}
                newStylesV2
            >
                {titleContents}
            </DialogTitleBox>}
            <DialogBody className={bodyClassName}>
                {children}
            </DialogBody>
            {
                mctaText &&
                <DialogFooter
                    primaryBtnClass={primaryBtnClass}
                    sctaBtnClass={sctaBtnClass}
                    mctaHandler={mctaHandler}
                    autoFocus={autoFocus}
                    sctaHandler={sctaHandler}
                    mctaText={mctaText}
                    sctaText={sctaText}
                    disabled={disabled}
                    contentClassName={footerContentClassName}
                    footerButtonContainerClassName={footerButtonContainerClassName}
                    noTopBorder={noTopBorderForFooter}
                    className={footerClassName}
                    footerV2
                >
                    {footerContents}
                </DialogFooter>
            }
        </Dialog>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    defaultSctaHandler: () => dispatch(closeDialog())
});

const DialogV2 = connect(null, mapDispatchToProps)(DialogV2Component);

export {
    DialogV2 as default,
    DialogV2
};
