import { makeCombineReducer, makeDefaultStateReducers } from "../../redux/makeReducer/index";
import * as Actions from './actions';

const defaultState = {
    show: false,
};

export const autosaveReducer = makeCombineReducer({
    combineReducers: makeDefaultStateReducers(defaultState),
    handleActions: {
        [Actions.AUTOSAVE_SHOW_POPUP]: (state) => ({ ...state, show: true, }),
        [Actions.AUTOSAVE_HIDE_POPUP]: (state) => ({ ...state, show: false, }),
    },
});
