import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
    (theme) => ({
        paper: {
            textAlign: "center",
            width: "100%",
            maxWidth: "100%",
            maxHeight: "100%",
            bottom: 0,
            margin: 0,
            padding: 0,
            [theme.breakpoints.down('md')]: {
                position: "absolute"
            },
        },
        containerClass: {
            width: "1440px",
            maxWidth: "100%",
            padding: "48px",
            alignSelf: "center",
            [theme.breakpoints.down('md')]: {
                padding: "16px 24px"
            },
        },
        dialogContent: {
            overflow: "hidden",
            textAlign: "center"
        },
        dialogTitle: {
            fontSize: "32px",
            lineHeight: "40px",
            [theme.breakpoints.down('sm')]: {
                fontSize: "22px",
                lineHeight: "30px",
                fontWeight: 600
            },
        },
        dialogSubTitle: {
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0.5px",
            margin: "0 auto",
            [theme.breakpoints.down('md')]: {
                fontSize: "14px",
                lineHeight: "22px",
            },
        },
        seePlansButton: {
            height: 40,
            padding: "0 30px",
        },
        paymentPlanContainer: {
            paddingTop: "0px",
        },
        planDescTitle: {
            fontSize: "24px",
            lineHeight: "30px",
        },
        planDescBody: {
            fontSize: "15px",
            lineHeight: "24px",
        },
        freeMonthsLabel: {
            fontSize: "14px",
            lineHeight: "22px",
        },
        backBtn: {
            display: "flex",
            gap: "8px",
            alignItems: "center",
            fontWeight: 400
        },
        backBtnText: {
            color: theme.palette.custom.colorGray_8a,
            fontSize: "16px",
            lineHeight: "32px",
            [theme.breakpoints.down('md')]: {
                fontSize: "14px",
                lineHeight: "22px",
            },
        }
    }),
    { name: "UpgradePlansDialog" }
);
