import React from 'react';
import * as R from 'ramda';
import * as path from '../../../../../mappers/path';
import { Flex } from "../../../../../view/reflexbox/index";
import Page from "../../../../PropertiesPanel/view/PropertiesPage";
import AppConfig from "../../../../../utils/AppConfig";
import { getAppConfig } from "../../../../App/epics/appConfig/appConfig";
import { partnerIsOnecom } from '../../../../../../../server/shared/partners/PartnerName';
import injectIntl from '../../../../../view/intl/injectIntl';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';
import Slider from '../../../../../view/common/Slider/PPSlider';
import * as Actions from '../../actionTypes';
import * as selectors from '../../../../Workspace/epics/stylesheets/selectors';
import * as pagesIds from './ids';
import pagesTitles from './titles';
import type { Props } from '../flowTypes';
import { MinFontSize, MaxFontSize } from "../../constants";
import { getFontSettings } from "../../fontsUtility";
import { LINE_HEIGHT_PERCENTAGE } from "../../view/constants";
import optionMapper from '../../../Button/globalStyle/options';
import buttonKind from '../../../Button/globalStyle/kind';
import * as styles from '../styles.css';
import { MCTA_THEME_OPTIONS_BUTTON } from "../../../../componentMainActions/constants";
import { themeColoroptionRenderer } from "../../../Button/globalStyle/themeColoroptionRenderer";
import PropertyGlobalStyleSelector from '../../../../../view/common/PropertyGlobalstyleSelector/index';
import { optionRenderer } from "../../../Button/globalStyle/optionRenderer";

const HorizontalBar = () => {
    return <div className={styles.horizontalBar} />;
};

const id: any = pagesIds.BUTTONS,
    title = pagesTitles[pagesIds.BUTTONS],
    view = injectIntl((props: Props) => {
        const {
            dispatch,
            selectedComponent,
            intl,
            globalStyles,
            themeSettingsData: {
                autoColorMode
            },
            stylesheetsIdToNameMap,
            selectedComponentProps: { selectedParentTheme }
        } = props;
        const appConfig = AppConfig(getAppConfig());
        const partnerName = appConfig.get('partnerName');
        const isPartnerOnecom = partnerIsOnecom(partnerName);
        const buttonGlobalStyleId = R.path(path.styleButtonGlobalId)(selectedComponent),
            fuButtonGlobalStyleId = R.path(path.fileUploadButtonStyleGlobalId)(selectedComponent),
            fontSettings = getFontSettings({
                component: selectedComponent,
                globalStyles,
                lineHeightPercentage: LINE_HEIGHT_PERCENTAGE,
                autoColorMode
            }),
            { buttonFontSize: sendButtonFontSize, fuButtonFontSize } = fontSettings,
            stylesheets = selectors.getAllStylesheets(globalStyles);

        let
            optRenderer,
            options,
            colorThemeOnChangeAction,
            fileUploadColorThemeOnChangeAction,
            selected,
            fileUploadSelectedButton,
            onChangeAction,
            currentStylesheetId;

        if (autoColorMode) {
            currentStylesheetId = selectors.getFirstButtonStyleId(globalStyles);
            options = MCTA_THEME_OPTIONS_BUTTON;

            const btnName = stylesheetsIdToNameMap[currentStylesheetId];
            optRenderer = (value, label) => themeColoroptionRenderer(value, label, selectedParentTheme, btnName);

            onChangeAction = Actions.CONTACT_FORM_CHANGE_BUTTON_STYLE_FOR_AUTO_COLOR;
            // TODO: WBTGEN-17574
            colorThemeOnChangeAction = (value) => {
                dispatch({ type: onChangeAction, payload: value });
            };
            fileUploadColorThemeOnChangeAction = (value) => {
                dispatch({ type: Actions.CONTACT_FORM_CHANGE_FILE_UPLOAD_BUTTON_STYLE_FOR_AUTO_COLOR, payload: value });
            };
            selected = selectedComponent.buttonThemeSelected;
            fileUploadSelectedButton = selectedComponent.fuButtonThemeSelected;
        } else {
            options = optionMapper(stylesheets, intl);
            onChangeAction = Actions.CONTACT_FORM_CHANGE_BUTTON_STYLE;
            optRenderer = (value, label) => optionRenderer(value, label, stylesheetsIdToNameMap);
        }

        return (
            <Page>
                <VerticalSpacer />
                <Flex align="center" justify="space-between">
                    <PropertyGlobalStyleSelector
                        label="msg: common.sendButtonStyle {Send button style}"
                        globalStyleId={selected || buttonGlobalStyleId}
                        onChangeAction={onChangeAction}
                        options={options}
                        optionRenderer={optRenderer}
                        kind={buttonKind}
                        dispatch={dispatch}
                        showEditLink
                        colorThemeOnChangeAction={colorThemeOnChangeAction}
                        autoColorMode={autoColorMode}
                        currentStylesheetId={currentStylesheetId}
                    />
                </Flex>
                <VerticalSpacer />
                <Flex column>
                    <PropertyContainer
                        label="msg: component.contactForm.appearance.sendButtonFontSize {Send button font size}"
                        valueLabel={`${sendButtonFontSize} px`}
                    >
                        <Slider
                            defaultValue={sendButtonFontSize}
                            value={sendButtonFontSize}
                            min={MinFontSize}
                            max={MaxFontSize}
                            onChange={newSize => dispatch({
                                type: Actions.CONTACT_FORM_SEND_BUTTON_FONT_SIZE_CHANGED,
                                payload: newSize,
                                amendToSelf: true
                            })}
                        />
                    </PropertyContainer>
                </Flex>
                <VerticalSpacer />
                {isPartnerOnecom && <React.Fragment>
                    <HorizontalBar />
                    <VerticalSpacer />
                    <React.Fragment>
                        <Flex align="center" justify="space-between">
                            <PropertyGlobalStyleSelector
                                label="msg: common.fileUploadButtonStyle {File upload button style}"
                                globalStyleId={fileUploadSelectedButton || fuButtonGlobalStyleId}
                                onChangeAction={Actions.CONTACT_FORM_CHANGE_FILE_UPLOAD_BUTTON_STYLE}
                                options={options}
                                optionRenderer={optRenderer}
                                kind={buttonKind}
                                dispatch={dispatch}
                                showEditLink
                                colorThemeOnChangeAction={fileUploadColorThemeOnChangeAction}
                                autoColorMode={autoColorMode}
                                currentStylesheetId={currentStylesheetId}
                            />
                        </Flex>
                        <VerticalSpacer />
                        <Flex column>
                            <PropertyContainer
                                label="msg: component.contactForm.appearance.fileUploadButtonFontSize {File upload button font size}"
                                valueLabel={`${fuButtonFontSize} px`}
                            >
                                <Slider
                                    defaultValue={fuButtonFontSize}
                                    value={fuButtonFontSize}
                                    min={MinFontSize}
                                    max={MaxFontSize}
                                    onChange={newSize => dispatch({
                                        type: Actions.CONTACT_FORM_FILE_UPLOAD_BUTTON_FONT_SIZE_CHANGED,
                                        payload: newSize,
                                        amendToSelf: true
                                    })}
                                />
                            </PropertyContainer>
                        </Flex>
                        <VerticalSpacer />
                    </React.Fragment>
                </React.Fragment>}
            </Page>
        );
    });

export { id, title, view };
