/* eslint-disable max-len */

import React, { useState } from 'react';
import { Slider } from './slider';
import { Box } from "../../../../../../view/reflexbox/index";
import styles, { sliderWidth, sliderGap } from './styles.css';
import PrimaryButton from "../../../../../../view/common/Button/PrimaryButton";
import { injectIntl, Intl } from "../../../../../../view/intl/index";
import { GoogleMapView } from "../../GoogleMap/view/index";
import type { GmbDataType } from "../../../flowTypes";

type MessageArray = [string, any];

type Props = {
    intl: Intl,
    gmbDataList: Array<GmbDataType>,
    titleMessage: string | MessageArray,
    description: string | MessageArray,
    primaryBtnText: string,
    primaryBtnAction: (arg?: SelectedGMBDataUseState) => void,
    secondaryBtnText?: string,
    secondaryBtnAction?: React.MouseEventHandler<HTMLDivElement>,
    accountName: string,
};

type SelectedGMBDataUseState = {
    accountPath: string,
    locationPath: string
};

const getSliders = (gmbDataList) => {
    return (
        gmbDataList.map(element => {
            const { addressFields, openingHours, phoneNumber } = element;
            return (
                <GoogleMapView addressFields={addressFields} openingHours={openingHours} phoneNumber={phoneNumber} />
            );
        })
    );
};

export const GMBListingView = injectIntl((props: Props) => {
    const {
        intl,
        gmbDataList,
        titleMessage,
        description,
        primaryBtnText,
        primaryBtnAction,
        secondaryBtnText,
        secondaryBtnAction,
        accountName,
    } = props;

    const [selectedGMBData, setSelectedGMBData] = useState<SelectedGMBDataUseState>();

    const onSelect = (i) => {
        setSelectedGMBData({ accountPath: accountName, locationPath: gmbDataList[i].name });
    };

    return (
        <React.Fragment>
            <Box className={styles.titleMessage}>
                {intl.msgJoint(titleMessage)}
            </Box>
            <Box className={styles.description}>
                {intl.msgJoint(description)}
            </Box>

            <div className={styles.sliderWrapper}>
                <Slider sliders={getSliders(gmbDataList)} sliderWidth={sliderWidth} sliderGap={sliderGap} onSelect={onSelect} />
            </div>

            <PrimaryButton
                className={styles.primaryButton}
                onClick={() => primaryBtnAction(selectedGMBData)}
            >
                {intl.msgJoint(primaryBtnText)}
            </PrimaryButton>

            {
                secondaryBtnText ? (
                    <Box
                        className={styles.secondaryButton}
                        onClick={secondaryBtnAction}
                    >
                        {intl.msgJoint(secondaryBtnText)}
                    </Box>
                ) : null
            }

            <div className={styles.vericalBarLeft} />
            <div className={styles.vericalBarRight} />
        </React.Fragment>
    );
});
