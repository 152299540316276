import {
    calculateImageDimensionsByContainerWidth,
    calculateImageDimensionsByContainerHeight,
    roundOutput
} from './calculateImageDimensionsUtils';
import type { Dimensions } from '../../../../redux/modules/children/workspace/flowTypes';

export default (
    containerWidth: number, containerHeight: number, assetWidth: number, assetHeight: number
): Dimensions => {
    const
        containerRatio = (containerWidth / containerHeight),
        containerLandscape = containerRatio >= 1,
        containerPortrait = containerRatio < 1,

        assetRatio = (assetWidth / assetHeight),
        assetLandscape = assetRatio >= 1,
        assetPortrait = assetRatio < 1;

    let imageDimensions = { width: containerWidth, height: containerHeight };

    if ((containerLandscape && assetLandscape) || (containerPortrait && assetPortrait)) {
        if (containerRatio > assetRatio) {
            imageDimensions = calculateImageDimensionsByContainerHeight(assetWidth, assetHeight, containerHeight);
        } else if (containerRatio < assetRatio) {
            imageDimensions = calculateImageDimensionsByContainerWidth(assetWidth, assetHeight, containerWidth);
        }
    } else if (containerLandscape && assetPortrait) {
        imageDimensions = calculateImageDimensionsByContainerHeight(assetWidth, assetHeight, containerHeight);
    } else if (containerPortrait && assetLandscape) {
        imageDimensions = calculateImageDimensionsByContainerWidth(assetWidth, assetHeight, containerWidth);
    }

    return roundOutput(imageDimensions);
};
