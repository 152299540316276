import formats from '../../ColorPicker/formats';
import { formatMessage } from '../../../view/intl/CurrentLocale';

export const
    DEFAULT_SHADOW_COLOR = [formats.HSL, 0, 0, 0.5, 1],
    DEFAULT_BLUR_RADIUS = 3,
    DEFAULT_SHADOW_OFFSET_X = 3,
    DEFAULT_SHADOW_OFFSET_Y = 3,
    DEFAULT_SHADOW = {
        color: DEFAULT_SHADOW_COLOR,
        blur: DEFAULT_BLUR_RADIUS,
        left: DEFAULT_SHADOW_OFFSET_X,
        top: DEFAULT_SHADOW_OFFSET_Y
    },
    DEFAULT_TEXT_TOOLBAR_COLOR_BOX_STYLE = {
        height: '26px',
        width: '26px',
        borderRadius: "13px"
    },
    MAX_FONT_SIZE = 150,
    MIN_FONT_SIZE = 9;

export const BROKEN_LINK = 'wsb://broken-link';
export const BROKEN_LINK_NAME = 'Broken Link';

export const makeDefaultContentOnDrop =
    (content: string = '<br>') => `<p>${content}</p>`;
export const DefaultContentOnDrop = makeDefaultContentOnDrop();
export const makeDefaultContentOnDropForText = () => makeDefaultContentOnDrop(formatMessage({
    id: 'component.text.initialText', defaultMessage: 'Double-click here to add your own text.'
}));

export const ROOT_BLOCK_TAG = 'p';
export const ROOT_BLOCK_ATTRS = {};

export const VALID_ROOT_BLOCK_TAGS = ['P', 'UL', 'OL'];
export const VALID_CONTENT_BLOCK_TAGS = ['P', 'LI'];

export const MOBILE_MAX_HORIZONTAL_SPACING = 20;

export const MOBILE_MAX_FONT_SIZE = {
    textnormal: 20,
    textheading1: 40,
    textheading2: 28,
    textheading3: 24
};

export const JS_TO_CSS_STYLE_MAPPING = {
    'textDecoration': 'text-decoration'
};

export const TEXT_GLOBAL_STYLE_FORMATS: Array<string> = [
    'fontFamily', 'bold', 'italic', 'underline', 'characterSpacing', 'textshadow', 'fontSize', 'color'
];
export const TEXT_GLOBAL_STYLE_RULES = [
    'font-family', 'font-weight', 'font-style', 'text-decoration', 'letter-spacing', 'text-shadow', 'font-size', 'color'
];

export const LINK_GLOBAL_STYLE_FORMATS: Array<string> = [
    'textshadow', 'underline', 'color', 'hilitecolor'
];
export const LINK_GLOBAL_STYLE_RULES = [
    'text-shadow', 'text-decoration', 'color', 'background-color'
];

export const STYLES_APPLICABLE_ON_LIST_ITEMS: Array<string> = [
    'color', 'font-family', 'font-size', 'font-style', 'font-weight', 'letter-spacing', 'line-height'
];

export const linkedValueTag = 'linked-value';
export const linkedValueAttr = 'value';

export const containsWrappedItems = (content) => (content || '').includes("data-wrap-id=");
