import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from "classnames";

import Scrollbar from '../../../../../../../view/common/Scrollbar';
import { ResultsPanelContent } from "./ResultsPanelContent";

import styles from '../styles.css';
import { AI_TEXT_RESULTS_PANEL_SCROLLED, AI_TEXT_RESULTS_PANEL_SCROLLED_TO_BOTTOM } from '../../../../epics/AITextEpic/actionTypes';
import { aiTextSelector } from '../../../../epics/AITextEpic/selectors';

export const ResultsPanel = () => {
    const dispatch = useDispatch();
    const aiTextState = useSelector(aiTextSelector);

    const {
        isQueryInProgress,
        isScrolledWhileStreaming,
        errorState,
        isAnyFieldChanged
    } = aiTextState;
    const resultsScrollRef = React.useRef<any>(null);

    const scrollToBottom = () => {
        if (resultsScrollRef.current) {
            const scrollHeight = resultsScrollRef.current.getScrollHeight();
            const clientHeight = resultsScrollRef.current.getClientHeight();
            resultsScrollRef.current.scrollTop(scrollHeight - clientHeight);
        }
    };

    const onResultsScroll = () => {
        if (!isQueryInProgress) return;
        const scrollHeight = resultsScrollRef.current.getScrollHeight();
        const clientHeight = resultsScrollRef.current.getClientHeight();
        const scrollTop = resultsScrollRef.current.getScrollTop();
        const atBottom = (scrollHeight - scrollTop) - clientHeight;
        if (atBottom > 30 || atBottom < -30) {
            dispatch({ type: AI_TEXT_RESULTS_PANEL_SCROLLED });
        }
        if (atBottom === 0) {
            dispatch({ type: AI_TEXT_RESULTS_PANEL_SCROLLED_TO_BOTTOM });
        }
    };

    React.useEffect(() => {
        // Should scroll to bottom while result is being streamed
        // Or should scroll to bottom when their is error while generating result
        const scrollTimeOutId = setTimeout(() => {
            if (
                !isScrolledWhileStreaming &&
                (isQueryInProgress || (!isQueryInProgress && errorState && !isAnyFieldChanged))
            ) {
                scrollToBottom();
            }
        });

        return () => {
            clearTimeout(scrollTimeOutId);
        };
    });

    return (
        <Scrollbar
            className={cx(styles.resultsScrollBar, {
                [styles.hideScrollBar]: isQueryInProgress && !isScrolledWhileStreaming,
            })}
            scrollRef={resultsScrollRef}
            onScroll={onResultsScroll}
        >
            <div className={styles.resultsPanelContainer}>
                <ResultsPanelContent />
            </div>
        </Scrollbar>
    );
};
