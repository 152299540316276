import { map } from "ramda";
import { OPENING_HOURS_KIND } from "./kind";
import { openingHoursWorkspaceView } from "./view/index";
import { ComponentNames } from "../constants";
import { openingHoursPPConfig } from "./propertiesPanel/index";
import { openingHoursReducer } from "./reducer";
import { openingHoursComponentMainActions } from "./componentMainActions";
import { openingHoursCalcRenderProps } from "./calcRenderProps";
import previewConfig from "./previewConfig";
import { adjustComponentOnAdd } from "./adjustComponentOnAdd";
import { adjustmentHookConfig } from "./adjustmentHookConfig";
import siteFontsReducer from "../../Fonts/siteFontsReducer";
import siteDataEpic from "../../App/epics/siteData/index";
import { globalVariablesEpic } from "../../App/epics/globalVariablesEpic";
import stylesheetsEpic from "../../Workspace/epics/stylesheets/index";
import makeStateSelectorReducer from "../../../epics/makeStateSelectorReducer";
import { colorThemeSiteSettingsEpic } from "../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";
import { formatMessage } from "../../../view/intl/CurrentLocale";
import { parseMsgJoint } from "../../../../../server/shared/utils/parseMsgJoint";
import { INTL_DAYS_OF_THE_WEEK } from "./constants";
import { getStartDayOfTheWeek } from "./previewUtils";
import type { OpeningHoursComponent } from "./flowTypes";
import type { ChangeOldComponentDataBeforeImportProps } from "../../../view/oneweb/flowTypes";

export default {
    kind: OPENING_HOURS_KIND,
    view: openingHoursWorkspaceView,
    label: ComponentNames[OPENING_HOURS_KIND],
    shortcutIconName: "openingHours",
    propertiesPanel: openingHoursPPConfig,
    reducer: openingHoursReducer,
    dependsOn: {
        site: siteDataEpic.reducer,
        globalVariables: globalVariablesEpic.reducer,
        stylesheets: stylesheetsEpic.reducer,
        themeColorsData: makeStateSelectorReducer(stylesheetsEpic.reducer, stylesheetsEpic.valueActionType, getThemeColorsFromStylesheet),
        themeSettingsData: colorThemeSiteSettingsEpic.reducer
    },
    controlsDependsOn: {
        siteFonts: siteFontsReducer
    },
    mobileEditorConfig: { ...previewConfig },
    calcRenderProps: openingHoursCalcRenderProps,
    componentMainActions: openingHoursComponentMainActions,
    adjustComponentOnAdd,
    adjustmentHookConfig,
    changeOldComponentDataBeforeImport: ({
        component
    }: ChangeOldComponentDataBeforeImportProps<OpeningHoursComponent>): OpeningHoursComponent => {
        return {
            ...component,
            closedLabel: formatMessage({
                id: "component.openingHours.closed",
                defaultMessage: "Closed"
            }),
            dayLabels: map(msgJointInput => {
                const [id, defaultMessage] = parseMsgJoint(msgJointInput);
                return formatMessage({
                    id,
                    defaultMessage
                });
            }, INTL_DAYS_OF_THE_WEEK),
            hourFormat: formatMessage({
                id: "component.openingHours.hourFormat",
                defaultMessage: "HH"
            }),
            open24HoursLabel: formatMessage({
                id: "component.openingHours.open24Hours",
                defaultMessage: "Open 24 hours"
            }),
            startDayOfTheWeek: getStartDayOfTheWeek(),
            timeComponentSeparator: formatMessage({
                id: "component.openingHours.timeComponentSeparator",
                defaultMessage: ":"
            })
        };
    }
};
