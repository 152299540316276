import { useMediaQuery, Theme } from '@mui/material';

export const
    useIsMobile = () => {
        return useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
    },
    useIsTablet = () => {
        return useMediaQuery<Theme>((theme) => theme.breakpoints.only('sm'));
    },
    useIsMobileOrTablet = () => {
        return useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
    };
