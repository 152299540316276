import React from 'react';
import injectIntl from "../../intl/injectIntl";
import Combobox from './index';

export default injectIntl(({ options, placeholder, useOptionsAsIs, disabled, intl, ...props }) => {
    const
        intlOptions = useOptionsAsIs ? options : options.map(o => ({ ...o, label: intl.msgJoint(o.label) })),
        intlPlaceholder = placeholder && intl.msgJoint(placeholder);

    return (
        <Combobox options={intlOptions} placeholder={intlPlaceholder} disabled={disabled} {...props} />
    );
});
