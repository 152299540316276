import type { PreviewDocumentComponentProps } from "./flowTypes";
import kind from '../kind';
import { documentComponentStateToViewerUrl } from "../utils/documentComponentStateToViewerUrl";
import { getDefultLocaleId } from "../../../Locale/getDefaultLocale";
import type { CalcProps } from "../../../Preview/flowTypes";
import type { DocumentComponent } from "../flowTypes";

const DEFAULT_LOCALE = getDefultLocaleId();

export const previewDocumentComponentCalcProps = (params: CalcProps<DocumentComponent>): PreviewDocumentComponentProps => {
    const
        { component, componentsDependencies, isServerPreview } = params,

        //TODO: WBTGEN-6950 Added default language to en to fix 6950.
        //Earlier code was expecting that componentDependencies have data and hence languages were accessed
        //diretly. Since rendering of preview is now changed, so loading of componentDependencies
        //should be fixed and then below code don't need to load english by default
        locale = isServerPreview
            ? undefined : (((componentsDependencies?.[kind] || {}).languages || {}).current || DEFAULT_LOCALE);
    return {
        url: documentComponentStateToViewerUrl(component, locale)
    };
};
