import { makeEpic } from "../../../../epics/makeEpic";
import { COMPONENTS_MAP_INITIALIZED } from "../../../Workspace/epics/componentsEval/actionTypes";
import { getHeaderSectionId } from "../utils";

export const headerSectionIdVAT = "HEADER_SECTION_ID";

export const headerSectionIdEpic = makeEpic({
    defaultState: null,
    valueActionType: headerSectionIdVAT,
    updaters: [
        {
            conditions: [COMPONENTS_MAP_INITIALIZED],
            reducer: ({ values: [{ componentsMap }] }) => {
                return {
                    state: getHeaderSectionId(componentsMap),
                };
            },
        },
    ],
});
