import * as R from 'ramda';

import { calcGalleryHeight } from "./calcGalleryHeight";
import { MinGalleryDimensions } from "./constants";

import type {
    AdjustmentHook,
    AdjustmentHookConfig,
    ShouldCallAdjustmentHook
} from "../../Workspace/epics/componentsEval/flowTypes";
import type { GalleryComponent, GalleryComponentDependsOn, GalleryComponentExtension } from './flowTypes';
import { calcGalleryMaxThumbnailDimensions, calcWorkspaceWidthWithMargin } from "./calcRenderProps";

type AdjustmentData = Record<string, any> // { 'caption-height-of-image-0': 10, 'caption-height-of-image-1': 20 }
type Hook = AdjustmentHook<GalleryComponent, GalleryComponentDependsOn, GalleryComponentExtension, AdjustmentData>
type HookConfig =
    AdjustmentHookConfig<GalleryComponent, GalleryComponentDependsOn, GalleryComponentExtension, AdjustmentData>
type GalleryShouldCallAdjustmentHook =
    ShouldCallAdjustmentHook<GalleryComponent, GalleryComponentDependsOn, GalleryComponentExtension, AdjustmentData>

const
    setHighestCaptionPxIfChanged = (highestCaptionPx, extension) => {
        if (extension.highestCaptionPx === highestCaptionPx) {
            return extension;
        }

        return { ...extension, highestCaptionPx };
    },
    setHeightAndCaptionMinHeightIfChanged = (component: GalleryComponent, height: number, captionMinHeight: number) => {
        if (component.height === height && component.captionMinHeight === captionMinHeight) {
            return component;
        }

        return { ...component, height, captionMinHeight };
    },
    setHeightDiff = (heightDiff: number, extensions: Record<string, any>) => {
        //check for negative value because we need to pull up
        return { ...extensions, heightDiff: heightDiff <= 0 ? (extensions.heightDiff || 0) + heightDiff : 0 };
    },
    hook: Hook = ({
        originalComponent,
        component,
        componentExtension = { highestCaptionPx: 0 },
        adjustmentData = {},
        componentDependencies
    },
    { component: prevComponent }) => {
        if (!component.images.length) {
            let finalComponent = component;
            if (originalComponent) {
                if (component.width !== originalComponent.width) {
                    finalComponent = { ...component, width: originalComponent.width };
                }
                if (component.height !== originalComponent.height) {
                    finalComponent = { ...finalComponent, height: originalComponent.height };
                }
            }
            return [finalComponent, componentExtension];
        }
        let updatedNextComponent = { ...component };
        const
            allCaptionsAreEmpty = !component.captionsEnabled || updatedNextComponent.images.every(i => !i.caption && !i.title),
            { workspaceBBoxWidth, templateWidth } = componentDependencies || {},
            { fullWidthOption, width } = updatedNextComponent,
            highestCaptionPx = allCaptionsAreEmpty ? 0 : R.pipe(
                R.values,
                R.sort(R.subtract),
                R.last,
                R.defaultTo(0)
            )(adjustmentData) || 0,
            updatedWidth = updatedNextComponent.stretch && workspaceBBoxWidth
                // @ts-ignore
                ? calcWorkspaceWidthWithMargin(fullWidthOption.margin, fullWidthOption.maxWidth, workspaceBBoxWidth, templateWidth)
                : width,
            { maxThumbnailWidth } = calcGalleryMaxThumbnailDimensions({
                ...updatedNextComponent,
                width: updatedWidth
            }),
            { columns, spacingPx } = updatedNextComponent;

        let componentWithAdjustedWidth = {
            ...updatedNextComponent,
            width: updatedNextComponent.stretch && workspaceBBoxWidth ?
                Math.round(updatedWidth) : Math.round((maxThumbnailWidth * columns) + (spacingPx * (columns - 1)))
        };
        const calculatedHeight = updatedNextComponent.images.length === 0 ?
            MinGalleryDimensions.height :
            calcGalleryHeight({ component: componentWithAdjustedWidth, highestCaptionPx, captionHeightsMap: adjustmentData });

        let heightDiff = 0;
        if (prevComponent) {
            heightDiff = calculatedHeight - prevComponent.height;
        }
        return [
            setHeightAndCaptionMinHeightIfChanged(componentWithAdjustedWidth, calculatedHeight, highestCaptionPx),
            R.pipe(
                setHighestCaptionPxIfChanged.bind(null, highestCaptionPx),
                setHeightDiff.bind(null, heightDiff)
            )(componentExtension)
        ];
    },
    shouldCallHook: GalleryShouldCallAdjustmentHook = (prevProps, { adjustmentData, component }) =>
        !!adjustmentData ||
        !component.captionsEnabled ||
        (
            prevProps.component
            && prevProps.component !== component
            && !component.captionsEnabled
        ),
    hookConfig: HookConfig = {
        hook,
        shouldCallHook
    };

export {
    hookConfig as default
};
