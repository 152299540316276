import React from 'react';
import openDialog from '../../App/actionCreators/openDialog';
import Icons from '../../../view/Icons/index';
import { injectIntl } from '../../../view/intl/index';
import TextIconButton from './index';
import type { FactoryPropTypes } from './flowTypes';

export default injectIntl(
    ({
        text,
        large = true,
        Icon = Icons.BOTTOM,
        dialogId,
        dialogProps,
        intl,
        dispatch,
        className
    }: FactoryPropTypes) => (
        <TextIconButton
            text={intl.msgJoint(text)}
            large={large}
            Icon={Icon}
            onClick={() => dispatch(openDialog(dialogId, dialogProps))}
            className={className}
        />
    )
);
