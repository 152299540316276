/* eslint max-len: ["error", { "code": 200 }] */

export const
    ErrorCodes = {
        CDATA_NOT_CLOSED: 'component.code.validate.cdata_not_closed',
        HTML_COMMENT_NOT_CLOSED: 'component.code.validate.html_comment_not_closed',
        START_TAG_MISSING_END_TAG: 'component.code.validate.start_tag_missing_end_tag',
        EXTRA_END_TAG_FOUND: 'component.code.validate.extra_end_tag_found',
        START_TAG_SHOULD_BE_CLOSED_BEFORE: 'component.code.validate.start_tag_should_be_closed_before'
    },
    ErrorMessages = {
        [ErrorCodes.CDATA_NOT_CLOSED]: 'msg: component.code.validate.cdata_not_closed {Line {0}: CDATA section not closed properly.}',
        [ErrorCodes.HTML_COMMENT_NOT_CLOSED]: 'msg: component.code.validate.html_comment_not_closed {Line {0}: HTML comment not closed properly.}',
        [ErrorCodes.START_TAG_MISSING_END_TAG]: 'msg: component.code.validate.start_tag_missing_end_tag {Line {0}: {1} start tag missing corresponding end tag.}',
        [ErrorCodes.EXTRA_END_TAG_FOUND]: 'msg: component.code.validate.extra_end_tag_found {Line {0}: Extra end tag found: {1}}',
        [ErrorCodes.START_TAG_SHOULD_BE_CLOSED_BEFORE]: 'msg: component.code.validate.start_tag_should_be_closed_before {Line {0}: {1} start tag from line {2} should be closed before {3}.}'
    };
