import React from 'react';
import cx from 'classnames';
import Msg from "../../../../view/intl/Msg";
import { EPSeparator } from "../../../Panel/view/EPSeparator";
import VerticalSpacer from "../../../../view/common/VerticalSpacer";
import * as InserterStyles from '../../../Panel/view/Inserter.css';
import HelpTip from "../../../Panel/view/HelpTip";
import { Flex } from "../../../../view/reflexbox/index";
import { DroppableCmpShortcut } from "../../../Panel/view/DroppableCmpShortcut";
import FeaturedProductsKind from '../../FeaturedProducts/kind';

import styles from './styles.css';

type Props = {
    dispatch: Dispatch;
};

const SampleProductImages = () => {
    const productImages = [styles.productImage_2, styles.productImage_3, styles.productImage_4];

    return (
        <Flex justify="space-between" align="center" className={styles.productsContainer}>
            {productImages.map((productImage, i) => {
                return (
                    <Flex column key={'product-' + i}>
                        <div className={cx(styles.productImage, styles.featuredProductImage, productImage)} />
                        <Msg
                            k="webshopInserter.singleProduct.ProductTitle"
                            className={cx(styles.productTitle, styles.featuredProductsTitle)}
                        >I'm a product</Msg>
                        <Msg
                            k="webshopInserter.singleProduct.ProductPrice"
                            className={cx(styles.productPrice, styles.featuredProductsPrice)}
                        >EUR 35</Msg>
                    </Flex>
                );
            })}
        </Flex>
    );
};

export const FeaturedProducts = ({ dispatch }: Props) => {
    return (
        <React.Fragment>
            <VerticalSpacer y={20} />
            <Flex column>
                <Flex justify="space-between">
                    <Msg k="common.featuredProducts" className={InserterStyles.subTitle}>Featured products</Msg>
                    <Flex className={styles.helpTipContainer}>
                        <HelpTip
                            msg="msg: webshopInserter.featuredProducts.HelpText
                                {Highlight a selection of products from your Online Shop on any given page.}"
                            helpTipClassName="inserterInfoIconTip"
                            dispatch={dispatch}
                        />
                    </Flex>
                </Flex>
                <DroppableCmpShortcut
                    kind={FeaturedProductsKind}
                    dispatch={dispatch}
                    payload={{
                        isSingleProduct: false
                    }}
                >
                    <SampleProductImages />
                </DroppableCmpShortcut>
            </Flex>
            <VerticalSpacer y={25} />
            <EPSeparator />
        </React.Fragment>
    );
};
