import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { InputField } from "../Common/InputField";
import { validateEmailField } from "../validations";
import { DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA } from "../../../Epic/actionTypes";

export const EmailField = (props) => {
    const dispatch = useDispatch();
    const { email, setEmail } = props;

    useEffect(() => {
        dispatch({
            type: DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA,
            payload: { path: "contactInfo.email", value: email.value }
        });
    }, [email]);

    return (
        <InputField
            label="msg: common.email.optional {Email address (optional)}"
            error={email.showError}
            errorMsg="msg: onboarding.dynamic.error.email {Enter an email address in the format email@example.one.}"
            value={email.value}
            onChange={(e) => {
                const v = e.target.value;
                setEmail({ value: v, showError: false });
            }}
            onFocus={() => {
                dispatch({
                    type: DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA,
                    payload: { path: "contactInfo.focusedField", value: 'email' }
                });
            }}
            onBlur={() => {
                setEmail({
                    ...email,
                    showError: !validateEmailField(email.value)
                });
                dispatch({
                    type: DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA,
                    payload: { path: "contactInfo.focusedField", value: null }
                });
            }}
            data-testid="dynamic-onboarding-email-field"
        />
    );
};
