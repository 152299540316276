import React from 'react';
import '../../view/Preview.css';
import { DATA_TEST_ID, TiktokEmbedUrl } from '../constants';
import { getMediaId } from '../utils/parseTiktokCode';

export const TiktokView = props => {
    const mediaId = getMediaId(props.link);
    const url = `${TiktokEmbedUrl}${mediaId}`;
    return (
        <div data-testid={DATA_TEST_ID} className="widget" style={{ width: props.width }}>
            <iframe src={url} width="100%" height={props.height} style={{ border: 'none', overflow: 'hidden' }} />
        </div>
    );
};
