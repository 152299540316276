import * as React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import styles from './FileChooserHeader.css';
import injectIntl from "../../../intl/injectIntl";
import { ExternalUrlUploadDialog } from '../dialogIds';
import { OPEN_DIALOG } from "../../../../redux/modules/actionTypes";
import type { FileChooserUploadFileMenuProps } from "../flowTypes";
import { uploadFromComputerAction } from "../../../../redux/modules/children/fileChooser/actionCreators/index";
import { FmUploadMenuOptions, FM_UPLOAD_MENU_FROM_LINK } from '../constants';
import { FmComboboxCom } from '../../../../components/FileManager/combobox/FmComboboxCom';
import type { FmComboboxOption } from '../../../../components/FileManager/combobox/FmComboboxCom';
import type { ReactElementRef } from '../../../../globalTypes';
import type { AppState } from '../../../../redux/modules/flowTypes';
import { isDemoSubscriptionTypeAppSel } from '../../../../../demo/modules/selectors';
import { FileManagerMode } from '../../../../components/FileManager/constants';

type State = {
    isOpen: boolean,
    dropDownWidth: number,
};

class UploadFileMenu extends React.Component<FileChooserUploadFileMenuProps, State> {
    options: Array<FmComboboxOption>;
    fileRef: ReactElementRef<HTMLInputElement>;
    fileKey: number = new Date().getTime();

    constructor(props) {
        super(props);
        let { mode } = this.props;
        this.options = FmUploadMenuOptions
            .filter(({ value }) => !(mode === FileManagerMode.VIDEO_CHOOSER && value === FM_UPLOAD_MENU_FROM_LINK))
            .map(({ label, value }) => ({
                label,
                value,

                onClick: this[value],
            }));
        this.fileRef = React.createRef();
    }

    onFileClick = () => {
        if (this.fileRef.current) {
            this.fileRef.current.click();
        }
    };

    onFileChange = (e) => {
        this.fileKey = new Date().getTime();
        this.props.uploadFilesFromComputer(e.target.files);

        if (this.fileRef.current) this.fileRef.current.value = '';
    };

    onUrlClick = () => {
        this.props.openUrlUploadDialog();
    };

    render() {
        const { className, isDemo, acceptTypes, isFreeOneComVideoFileChooser = false } = this.props,
            accept = Array.isArray(acceptTypes) ? acceptTypes.join(', ') : acceptTypes;

        return (
            <React.Fragment>
                <FmComboboxCom
                    isFreeOneComVideoFileChooser={isFreeOneComVideoFileChooser}
                    options={this.options}
                    className={cx(styles.uploadMenu, className)}
                    placeholder="msg: fc.uploadFile {Upload}"
                    isDemo={isDemo}
                    isVideoChooser={FileManagerMode.VIDEO_CHOOSER === this.props.mode}
                />
                <input
                    ref={this.fileRef}
                    key={this.fileKey} // reset file input value (by unmounting from DOM)
                    type="file"
                    name="fileUpload"
                    multiple={FileManagerMode.VIDEO_CHOOSER !== this.props.mode} // TODO should not be multiple for logo component onboarding
                    style={{ display: 'none' }}
                    accept={accept}
                    onChange={this.onFileChange}
                />
            </React.Fragment>
        );
    }

    componentDidMount() {
        if (this.props.headlessMode) {
            this.onFileClick();
        }
    }
}

const mapStateToProps = (appState: AppState) => ({
    isDemo: isDemoSubscriptionTypeAppSel(appState),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    openUrlUploadDialog: () => dispatch({ type: OPEN_DIALOG, payload: { dialogId: ExternalUrlUploadDialog } }),
    uploadFilesFromComputer: (files: FileList) => dispatch(uploadFromComputerAction(files)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UploadFileMenu));
