import React from "react";
import { useDispatch } from "react-redux";
import cx from "classnames";

import { ColorTheme } from "./Components/ColorTheme";
import { Intl, Msg, useIntl } from "../../../view/intl/index";
import * as styles from "../AutoColorLeftPanel.css";
import { HIDE_AUTO_COLOR_LEFT_PANEL } from "../actionTypes";

export const AutoColorModeOff = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <div
                data-testid="auto-color-mode-off"
                style={{
                    marginLeft: "25px"
                }}
            >
                <ColorTheme currentAutoColorMode={false} intl={intl} dispatch={dispatch} />
            </div>
            <div className={cx(styles.footer, styles.single)}>
                <button
                    type="button"
                    className={styles.doneBtn}
                    data-testid="auto-color-mode-close-button"
                    onClick={() => {
                        dispatch({
                            type: HIDE_AUTO_COLOR_LEFT_PANEL
                        });
                    }}
                >
                    <Msg k="common.close">Close</Msg>
                </button>
            </div>
        </React.Fragment>
    );
};
