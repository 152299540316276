import React from 'react';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import * as pagesIds from "./ids";
import pagesTitles from "./titles";

// NOTE: Without <Page> the real property panel wont render
const
    id = pagesIds.MAIN,
    title = pagesTitles[id],
    view = () => (
        <Page>
            <div />
        </Page>
    );

export { id, title, view };
