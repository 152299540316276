import * as R from "ramda";
import { overPath } from "../../../../src/utils/ramdaEx";
import * as path from '../../../../src/mappers/path';
import * as colorMapper from '../Base/color';
import * as fontFamilyMapper from "../Base/fontFamily";
import { DEFAULT_LETTER_SPACING, DEFAULT_LINE_HEIGHT } from "../../../../src/components/App/epics/tinyMceEpic/editorUtils/constants";
import { DEFAULT_TEXT_COLOR } from "../../../../src/components/Globalstyles/Text/constants";

const
    colorMap = {
        to: overPath([[path.color], [...path.shadowColor]])(colorMapper.toHsl),
        back: overPath([[path.color], [...path.shadowColor]])(R.identity)
    },
    fontFamilyMap = {
        to: R.over(R.lensProp(path.font), fontFamilyMapper.to),
        back: R.over(R.lensProp(path.font), fontFamilyMapper.back)
    },
    addTextStyleDefaults = style => {
        return {
            ...style,
            color: style.color || DEFAULT_TEXT_COLOR,
            letterspacing: style.letterspacing || DEFAULT_LETTER_SPACING,
            shadow: style.shadow || 'none'
        };
    },
    addLineHeight = style => ({ ...style, lineHeight: (style.lineHeight || DEFAULT_LINE_HEIGHT) }),
    removeDefaultShadow = style => {
        const styleWithoutDefaultShadow = { ...style };

        if (style.shadow === 'none') {
            delete styleWithoutDefaultShadow.shadow;
        }

        return styleWithoutDefaultShadow;
    };

export const
    to = R.pipe(colorMap.to, fontFamilyMap.to, addTextStyleDefaults, addLineHeight),
    back = R.pipe(colorMap.back, fontFamilyMap.back, removeDefaultShadow);
