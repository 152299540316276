import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from '../../../../../view/intl/index';
import { Dialog, DialogBody } from '../../../../../view/common/dialogs/baseDialog/index';
import getCenteredDialogConfig from '../../../../DialogManager/getCenteredDialogConfig';
import styles from './styles.css';
import { StripTypes } from "../../../../../view/common/dialogs/baseDialog/Dialog";
import { closeDialog } from "../../../../App/actionCreators/index";
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import Button from "../../../../../view/common/Button/Button";
import type { AppState } from '../../../../../redux/modules/flowTypes';
import { makeEpicStateSelector } from '../../../../../epics/makeEpic';
import { textLimitWarningVAT } from '../../../../App/epics/textLimitWarning/valueActionType';

const
    width = 576,
    height = 523,
    HEADER = 'msg: text.character.exceededDialog.title {Maximum characters reached}',
    // eslint-disable-next-line max-len
    MSG_ON_TYPE = 'msg: text.character.exceededDialog.msg {You’ve reached the character limit for a single text box. Add another one to your page to continue writing more text.}',
    // eslint-disable-next-line max-len
    MSG_ON_PASTE = 'msg: text.character.exceededDialog.onPaste.msg {You’ve added more characters to the text box than we support. Add another text component to your page to continue writing.}',

    TextCharacterLimitExceededDialogComponent = injectIntl(({ warningType, intl, dispatch }) => {
        const
            onClose = () => dispatch(closeDialog());

        return (
            <Dialog
                onClose={onClose}
                stripType={StripTypes.NONE}
                className={styles.textCharacterLimitExceededDialog}
                closeBtnClassName={styles.textCharacterLimitExceededDialogCloseButton}
            >
                <DialogBody className={styles.textCharacterLimitExceededDialogContainer}>
                    <div className={styles.textCharacterLimitExceededDialogIcon} />
                    <VerticalSpacer y={30} />
                    <div className={styles.textCharacterLimitExceededDialogHeader}>
                        {intl.msgJoint(HEADER)}
                    </div>
                    <VerticalSpacer y={30} />
                    <div className={styles.textCharacterLimitExceededDialogMsg}>
                        {
                            warningType === 'onType' ? intl.msgJoint(MSG_ON_TYPE) : intl.msgJoint(MSG_ON_PASTE)
                        }
                    </div>
                    <VerticalSpacer y={46} />
                    <div className={styles.textCharacterLimitExceededDialogBtnContainer}>
                        <Button
                            className={styles.textCharacterLimitExceededDialogOkBtn}
                            onClick={() => {
                                dispatch(closeDialog());
                            }}
                        >
                            {intl.msgJoint("msg: common.Ok {Ok}")}
                        </Button>
                    </div>

                </DialogBody>
            </Dialog>
        );
    }),
    stateSelector = makeEpicStateSelector(textLimitWarningVAT),
    mapStateToProps = (appState: AppState) => ({
        warningType: stateSelector(appState).warningType,
    }),
    TextCharacterLimitExceededDialog = connect(mapStateToProps)(TextCharacterLimitExceededDialogComponent),
    config = getCenteredDialogConfig({
        width,
        height,
        component: TextCharacterLimitExceededDialog,
        dialogBackgroundClassName: styles.backgroundClassName
    });

export default config;
