import makeEpic from "../../../../epics/makeEpic";
import valueActionType from './valueActionType';
import { TOP_BAR_PAGE_TREE_ON_EXPAND, TOP_BAR_PAGE_TREE_SCROLL_TOP } from "../../actionTypes";
import { PAGE_TREE_PAGE_OPEN_CONTEXT_MENU, PAGE_TREE_PAGE_SELECTED } from "../../../PagesTree/Tree/actionTypes";
import { HIDE_CONTEXT_MENU } from "../../../ContextMenu/actionTypes";
import siteDataValueActionType from '../../../App/epics/siteData/valueActionType';
import { NEW_PAGE_LAYOUT_PAGE_CREATED } from "../../../PagesTree/NewPageDialog/actions";
import { SET_PAGE_TREE_LOADING_ACTION, UNSET_PAGE_TREE_LOADING_ACTION } from "./actions";
import { closeTopBarPagesTreeAction } from "../../actionCreators/index";

const defaultState = {
    expandedPageIds: [],
    scrollTop: undefined,
    contextMenuItemId: null,
    isLoading: false
};

const makeIsLoadingUpdater = (action: string, isLoading: boolean) => ({
    conditions: [action],
    reducer: ({ state }) => ({
        state: { ...state, isLoading }
    })
});

export default makeEpic({
    valueActionType,
    defaultState,
    updaters: [
        {
            conditions: [TOP_BAR_PAGE_TREE_ON_EXPAND],
            reducer: ({ state, values: [expandedPageIds] }) => ({ state: { ...state, expandedPageIds } })
        },
        {
            conditions: [siteDataValueActionType, NEW_PAGE_LAYOUT_PAGE_CREATED],
            reducer: ({ state, values: [siteData, newPageRefId] }) => {
                const parent = siteData.getPageRefParent(newPageRefId);
                if (parent && state.expandedPageIds.indexOf(parent.id) === -1) {
                    return { state: { ...state, expandedPageIds: [...state.expandedPageIds, parent.id] } };
                }

                return { state };
            }
        },
        {
            conditions: [TOP_BAR_PAGE_TREE_SCROLL_TOP],
            reducer: ({ state, values: [scrollTop] }) => ({ state: { ...state, scrollTop } })
        },
        {
            conditions: [PAGE_TREE_PAGE_OPEN_CONTEXT_MENU],
            reducer: ({ state, values: [{ pageTreeItem }] }) => ({
                state: {
                    ...state,
                    activeHoverItemId: pageTreeItem.id
                }
            })
        },
        {
            conditions: [PAGE_TREE_PAGE_SELECTED],
            reducer: ({ state }) => ({
                state,
                actionToDispatch: closeTopBarPagesTreeAction(),
            }),
        },
        {
            conditions: [HIDE_CONTEXT_MENU],
            reducer: ({ state }) => ({ state: { ...state, activeHoverItemId: null } })
        },
        makeIsLoadingUpdater(SET_PAGE_TREE_LOADING_ACTION, true),
        makeIsLoadingUpdater(UNSET_PAGE_TREE_LOADING_ACTION, false)
    ]
});
