import React from 'react';
import platform from 'platform';
import styles from './styles.css';
import StandardDialog from '../../../../view/common/dialogs/StandardDialog/index';
import Msg from "../../../../view/intl/Msg";
import getCenteredDialogConfig from "../../../DialogManager/getCenteredDialogConfig";
import { Flex } from "../../../../view/reflexbox/index";

const
    title = "msg: common.keyboardShortcuts {Keyboard shortcuts}",
    width = 775,
    height = 500,
    Dialog = () => {
        const
            MacSymbol = `⌘`,
            isMac = platform.os.family === 'OS X',
            prefixTextStyle = isMac ? MacSymbol : 'Ctrl',
            boldShortcut = `${prefixTextStyle} + B`,
            italicShortcut = `${prefixTextStyle} + I`,
            underlineShortcut = `${prefixTextStyle} + U`,
            copyShortcut = `${prefixTextStyle} + C`,
            cutShortcut = `${prefixTextStyle} + X`,
            pasteShortcut = `${prefixTextStyle} + V`,
            undoShortcut = `${prefixTextStyle} + Z`,
            duplicateShortcut = `${prefixTextStyle} + D`,
            saveShortcut = `${prefixTextStyle} + S`,
            previewShortcut = `${prefixTextStyle} + P`,
            switchViewShortcut = `${prefixTextStyle} + J`;

        let redoShortcut = `${prefixTextStyle} + Y`;
        if (isMac) {
            redoShortcut = `${prefixTextStyle} + Shift + Z`;
        }

        return (
            <StandardDialog title={title}>
                <Flex justify="space-between" className={styles.container}>
                    <table className={styles.keyboardGroup}>
                        <thead>
                            <tr>
                                <th colSpan={2} className={styles.keyboardGroupHeader}>
                                    <Msg k="common.commonActions">Common actions</Msg>
                                </th>
                                <th colSpan={1}>&nbsp;</th>
                                <th colSpan={2} className={styles.keyboardGroupHeader}>
                                    <Msg k="common.textFormatting">Text formatting</Msg>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={2} className={styles.keyboardGroupHeaderContainer}>&nbsp;</td>
                                <td colSpan={1}>&nbsp;</td>
                                <td colSpan={2} className={styles.keyboardGroupHeaderContainer}>&nbsp;</td>
                            </tr>
                            <tr>
                                <td className={styles.shortcutName}>
                                    <Msg k="common.save">Save</Msg>
                                </td>
                                <td className={styles.shortcutValue} colSpan={2}>
                                    {saveShortcut}
                                </td>
                                <td className={styles.shortcutName}>
                                    <Msg k="common.bold">Bold</Msg>
                                </td>
                                <td className={styles.shortcutValue}>
                                    {boldShortcut}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.shortcutName}>
                                    <Msg k="common.undo">Undo</Msg>
                                </td>
                                <td className={styles.shortcutValue} colSpan={2}>
                                    {undoShortcut}
                                </td>
                                <td className={styles.shortcutName}>
                                    <Msg k="common.italic">Italic</Msg>
                                </td>
                                <td className={styles.shortcutValue}>
                                    {italicShortcut}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.shortcutName}>
                                    <Msg k="common.redo">Redo</Msg>
                                </td>
                                <td className={styles.shortcutValue} colSpan={2}>
                                    {redoShortcut}
                                </td>
                                <td className={styles.shortcutName}>
                                    <Msg k="common.underline">Underline</Msg>
                                </td>
                                <td className={styles.shortcutValue}>
                                    {underlineShortcut}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.shortcutName}>
                                    <Msg k="common.copy">Copy</Msg>
                                </td>
                                <td className={styles.shortcutValue} colSpan={4}>
                                    {copyShortcut}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.shortcutName}>
                                    <Msg k="common.cut">Cut</Msg>
                                </td>
                                <td className={styles.shortcutValue} colSpan={4}>
                                    {cutShortcut}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.shortcutName}>
                                    <Msg k="common.paste">Paste</Msg>
                                </td>
                                <td className={styles.shortcutValue} colSpan={4}>
                                    {pasteShortcut}
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.shortcutName}>
                                    <Msg k="common.duplicate">Duplicate</Msg>
                                </td>
                                <td className={styles.shortcutValue} colSpan={2}>
                                    {duplicateShortcut}
                                </td>
                                <th colSpan={2} className={styles.keyboardGroupHeader}>
                                    <Msg k="common.editingPage">Editing page</Msg>
                                </th>
                            </tr>
                            <tr>
                                <td className={styles.shortcutName}>
                                    <Msg k="common.delete">Delete</Msg>
                                </td>
                                <td className={styles.shortcutValue} colSpan={2}>
                                    Del
                                </td>
                                <td colSpan={2} className={styles.keyboardGroupHeaderContainer}>&nbsp;</td>
                            </tr>
                            <tr>
                                <td className={styles.shortcutName}>
                                    <Msg k="common.preview">Preview</Msg>
                                </td>
                                <td className={styles.shortcutValue} colSpan={2}>
                                    {previewShortcut}
                                </td>
                                <td className={styles.shortcutName}>
                                    <Msg k="common.moveSinglePixel">Move single pixel</Msg>
                                </td>
                                <td className={styles.shortcutValue} colSpan={1}>
                                    ←, ↑, →, ↓
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.shortcutName}>
                                    <Msg k="common.switchView">Switch view between mobile/desktop</Msg>
                                </td>
                                <td className={styles.shortcutValue} colSpan={2}>
                                    {switchViewShortcut}
                                </td>
                                <td className={styles.shortcutName}>
                                    <Msg k="common.moveTenPixel">Move 10 pixels</Msg>
                                </td>
                                <td className={styles.shortcutValue} colSpan={1}>
                                    Shift + ←, ↑, →, ↓
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Flex>
            </StandardDialog>
        );
    };

export default getCenteredDialogConfig(width, height, Dialog);
