import { disableRaf } from "../../utils/isDebug";
import isTestEnv from "../../debug/isTestEnv";

export const
    ANIMATION_FRAME = 'ANIMATION_FRAME',
    animationFrameAC = (ts: number) => ({ type: ANIMATION_FRAME, payload: { ts } });

let dispatch;

const loop = () => {
    window.requestAnimationFrame(() => {
        dispatch(animationFrameAC(Date.now()));
        loop();
    });
};

export default (store: Store) => (next: Dispatch) => (action: Action): any => {
    if (!dispatch) {
        dispatch = store.dispatch;

        dispatch(animationFrameAC(Date.now()));

        if (!disableRaf() && !isTestEnv()) {
            loop();
        }
    }

    // TODO remove in WBTGEN-4990
    if (action.type === 'MIDDLEWARE_CRASH') {
        throw new Error("WBTGEN middleware crash");
    }

    return next(action);
};
