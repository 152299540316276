import React from 'react';
import * as R from 'ramda';
import { mapIndexed } from '../../utils/ramdaEx';

type ReplaceLineBreaksWithBr = (text: string) => Array<AnyReactElement>

export const
    replaceLineBreaksWithBr: ReplaceLineBreaksWithBr = R.pipe(
        R.split('\n'),
        R.intersperse({ br: true }),
        mapIndexed((part, index) => (part.br ? <br key={index} /> : <span key={index}>{part}</span>)),
    );
