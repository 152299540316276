/* eslint-disable max-len */

import * as React from 'react';
import cx from 'classnames';
import Label from '../Label';
import HorizontalSpacer from '../HorizontalSpacer';
import VerticalSpacer from '../VerticalSpacer';
import { injectIntl, Intl } from '../../intl/index';
import styles from './PropertyContainer.css';

type PropTypes = {
    intl: Intl,
    style?: any,
    label?: string,
    htmlFor?: string,
    disabled?: boolean,
    className?: string,
    valueLabel?: string,
    valueClassName?: string,
    valueLabelClassName?: string,
    children?: React.ReactNode,
    labelIcon?: {
        icon: string,
        horizontalSpacer?: number,
        className?: string,
        popup?: {
            message: string,
            className?: string,
            bodyClassname?: string,
            tipClassname?: string
        }

    },
    containerRef?: any,
    vsHeight?: number,
    hsHeight?: number,
    childContainerStyle?: string,
    isGroupLabelRequired?: boolean,
    isHorizontalLabel?: string,
    dataTestId?: string
};

const
    labelStyle = { marginTop: '-3px' },
    valueLabelStyle = { marginTop: '-5px' };

export default injectIntl(
    ({
        intl,
        label,
        valueLabel,
        valueClassName,
        valueLabelClassName,
        style,
        className,
        disabled,
        labelIcon,
        children,
        containerRef,
        vsHeight = 10,
        hsHeight = 40,
        htmlFor,
        childContainerStyle,
        isGroupLabelRequired = true,
        isHorizontalLabel,
        dataTestId
    }: PropTypes) => {
        const
            labelIconClass = labelIcon && labelIcon.icon ? labelIcon.icon : null,
            labelIconClassname = labelIcon && labelIcon.className ? labelIcon.className : null,
            popupMessage = labelIcon && labelIcon.popup ? labelIcon.popup.message : null,
            hs = labelIcon && labelIcon.horizontalSpacer ? labelIcon.horizontalSpacer : 3;

        const getLabelContainerContent = () => {
            return (
                <div className={cx({ [styles.labelParentContainer]: isHorizontalLabel })}>
                    <div className={styles.labelContainer}>
                        {
                            label &&
                            <div className={styles.labelAndIcon}>
                                <Label disabled={disabled} style={labelStyle} className={valueClassName}>{label}</Label>
                                <span className={styles.iconAndPopupCont}>
                                    {labelIconClass && <HorizontalSpacer x={hs} />}
                                    {
                                        labelIconClass &&
                                        <div
                                            className={cx(labelIconClass, styles.labelIcon, labelIconClassname)}
                                            data-title={popupMessage && intl.msgJoint(popupMessage)}
                                            data-title-warning
                                            data-title-position="top"
                                        />
                                    }
                                </span>
                            </div>
                        }
                        {valueLabel && <Label disabled={disabled} className={valueLabelClassName} isValue style={valueLabelStyle}>{valueLabel}</Label>}
                    </div>
                    {label && <React.Fragment>
                        {!isHorizontalLabel && <VerticalSpacer y={vsHeight} />}
                        {isHorizontalLabel && <HorizontalSpacer x={hsHeight} />}
                    </React.Fragment>}
                    <div className={childContainerStyle}>
                        {children}
                    </div>
                </div>
            );
        };

        return (
            <div style={style} data-test-id={dataTestId} className={className} ref={containerRef}>
                {
                    isGroupLabelRequired ?
                        <label htmlFor={htmlFor}>
                            {getLabelContainerContent()}
                        </label> :
                        getLabelContainerContent()
                }
            </div>
        );
    }
);
