import {
    makeCombineReducer,
    makeComponentBaseReducers,
    makeDefaultStateReducers,
    makePathPayloadToSetterReducer,
    makePayloadToSetterReducer
} from "../../../../redux/makeReducer/index";
import kind from '../kind';
import actionTypes from './actionTypes';
import opacityToAlpha from '../../Background/utils/opacityToAlpha';
import p from '../../../../utils/pipePath';
import { setToPath, toggleValue } from '../../../../utils/ramdaEx';
import { WEBSHOP_MIN_WIDTH } from '../constants';
import stylesheetsValueActionType from "../../../Workspace/epics/stylesheets/valueActionType";
import { getButtonStyleId } from "../utils";
import { reducerDefaultData } from './reducerDefaultData';

const makeColorReducer = prop => makePathPayloadToSetterReducer([prop], ['color']);
const makeThemeColorReducer
    = (prop: string) => (component, { payload: { themeColor } }) => ({ ...component, [prop]: themeColor });

export default makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(kind, WEBSHOP_MIN_WIDTH, 550),
        ...makeDefaultStateReducers(reducerDefaultData)
    },
    handleActions: {
        [actionTypes.WEBSHOP_FONT_FAMILY_CHANGED]: makePayloadToSetterReducer('font'),
        [actionTypes.WEBSHOP_TEXT_COLOR_CHANGED]: makeColorReducer('fontColor'),
        [actionTypes.WEBSHOP_TEXT_COLOR_CHANGED_AUTO_COLOR]: makeThemeColorReducer('fontColorTheme'),
        [actionTypes.WEBSHOP_LABEL_TEXT_COLOR_CHANGED]: makeColorReducer('labelTextColor'),
        [actionTypes.WEBSHOP_LABEL_TEXT_COLOR_CHANGED_AUTO_COLOR]: makeThemeColorReducer('labelTextColorTheme'),
        [actionTypes.WEBSHOP_LABEL_BG_COLOR_CHANGED]: makeColorReducer('labelBgColor'),
        [actionTypes.WEBSHOP_LABEL_BG_COLOR_CHANGED_AUTO_COLOR]: makeThemeColorReducer('labelBgColorTheme'),
        [actionTypes.WEBSHOP_FOCUS_COLOR_CHANGED]: makeColorReducer('focusColor'),
        [actionTypes.WEBSHOP_FOCUS_COLOR_CHANGED_AUTO_COLOR]: makeThemeColorReducer('focusColorTheme'),
        [actionTypes.WEBSHOP_HOVER_COLOR_CHANGED]: makeColorReducer('hoverColor'),
        [actionTypes.WEBSHOP_HOVER_COLOR_CHANGED_AUTO_COLOR]: makeThemeColorReducer('hoverColorTheme'),
        [actionTypes.WEBSHOP_HOVER_OPACITY_CHANGED]: (component: any, { payload }: any) => {
            return {
                ...component,
                temporaryHoverOpacity: 0,
                hoverColor: setToPath(p(4), opacityToAlpha(payload), [...component.hoverColor])
            };
        },
        [actionTypes.WEBSHOP_IMAGE_RATIO_CHANGED]: makePayloadToSetterReducer('imageRatio'),
        [actionTypes.WEBSHOP_LABEL_POSITION_CHANGED]: makePayloadToSetterReducer('labelPosition'),
        [actionTypes.WEBSHOP_PRODUCTS_PER_PAGE_CHANGED]: makePayloadToSetterReducer('productsPerPage'),
        [actionTypes.WEBSHOP_PRODUCTS_PER_ROW_CHANGED]: makePayloadToSetterReducer('productsPerRow'),
        [actionTypes.WEBSHOP_PRODUCTS_PER_ROW_IN_MOBILE_CHANGED]: makePayloadToSetterReducer('productsPerRowInMobile'),
        [actionTypes.WEBSHOP_SPACING_ITEMS_IN_MOBILE_CHANGED]: makePayloadToSetterReducer('spacingItemsInMobile'),
        [actionTypes.WEBSHOP_BUY_BUTTON_TEXT_CHANGED]: makePayloadToSetterReducer('buyButtonText'),
        [actionTypes.WEBSHOP_BUY_NOW_BUTTON_TEXT_CHANGED]: makePayloadToSetterReducer('buyNowButtonText'),
        [actionTypes.WEBSHOP_BUTTON_STYLE_CHANGED]: makePathPayloadToSetterReducer(['buttonId'], ['stylesheetId']),
        [actionTypes.WEBSHOP_BUTTON_STYLE_CHANGED_AUTO_COLOR]: makePayloadToSetterReducer('buttonThemeSelected'),

        [actionTypes.WEBSHOP_BUY_NOW_BUTTON_STYLE_CHANGED]: makePathPayloadToSetterReducer(['buyNowButtonId'], ['stylesheetId']),
        [actionTypes.WEBSHOP_BUY_NOW_BUTTON_STYLE_CHANGED_AUTO_COLOR]: makePayloadToSetterReducer('buyNowButtonThemeSelected'),

        [actionTypes.WEBSHOP_SHOW_CATEGORY_SELECTOR_CHANGED]: toggleValue(['showCategory']),
        [actionTypes.WEBSHOP_CAN_SORT_PRODUCTS_CHANGED]: toggleValue(['userCanSortProducts']),
        [actionTypes.WEBSHOP_SHOW_SEARCH_BAR_CHANGED]: toggleValue(['showSearchBar']),
        [actionTypes.WEBSHOP_CATEGORY_POSITION_CHANGED]: makePayloadToSetterReducer('categoriesPosition'),
        [stylesheetsValueActionType]: (component, { payload: globalStyles }) => {
            const buttonId = getButtonStyleId(component.buttonId, globalStyles);
            if (buttonId === component.buttonId) {
                return component;
            }
            return {
                ...component,
                buttonId,
            };
        },
        [actionTypes.WEBSHOP_CROP_IMAGES_CHANGED]: toggleValue(['cropImages']),
        [actionTypes.WEBSHOP_PRODUCT_APPEARANCE_CHANGED]: makePayloadToSetterReducer('productListStyle'),
        'WEBSHOP_WELCOME_OK_BUTTON': state => ({
            ...state,
            showWelcomeMessage: false
        }),
        [actionTypes.WEBSHOP_ENABLE_BUY_NOW_CHANGED]: toggleValue(['enableBuyNowButton']),

        [actionTypes.WEBSHOP_PROMOTION_RIBBON_LABEL_BG_COLOR_CHANGED]: makeColorReducer('promoRibbonBgColor'),
        [actionTypes.WEBSHOP_PROMOTION_RIBBON_LABEL_BG_COLOR_CHANGED_AUTO_COLOR]: makeThemeColorReducer('promoRibbonBgColorTheme'),
        [actionTypes.WEBSHOP_PROMOTION_RIBBON_LABEL_TEXT_COLOR_CHANGED]: makeColorReducer('promoRibbonTextColor'),
        [actionTypes.WEBSHOP_PROMOTION_RIBBON_LABEL_TEXT_COLOR_CHANGED_AUTO_COLOR]: makeThemeColorReducer('promoRibbonTextColorTheme'),
    }
});
