import { HOVERBOX, MENU, SECTION, STRIP, WEB_SHOP } from "../componentKinds";

export const
    ComponentsNotAllowedInHoverBox = [WEB_SHOP, STRIP, SECTION, MENU, HOVERBOX],
    HoverEffect = {
        instant: 'instant',
        fade: 'fade',
        dynamic: 'dynamic',
    },
    MinDimensions = { width: 40, height: 40 };
