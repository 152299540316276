/* eslint-disable max-len */

import React from 'react';
import cx from 'classnames';
import * as R from 'ramda';
import TdIconButton from '../TdIconButton/index';
import alignmentConfigs from './alignmentConfigs';
import { AlignmentTableTitleMsg } from './messages';
import * as HorizontalAlign from './HorizontalAlign';
import * as VerticalAlign from './VerticalAlign';
import * as actionTypes from './actionTypes';
import Icons from '../../../view/Icons/index';
import { isAligmentButtonActive } from './helpers';
import { injectIntl } from '../../../view/intl/index';
import { ComponentMaskBackground } from '../ComponentMask/constants';
import ComponentMask from '../ComponentMask/index';
import type { AlignmentTablePropTypes } from './flowTypes';
import styles from './AlignmentTable.css';

export default injectIntl(({
    alignment,
    alignmentChangeAction,
    showFit = true,
    disabled = false,
    isPropertiesPanel,
    intl,
    dispatch,
    disabledCells = [],
    disabledTooltip,
}: AlignmentTablePropTypes) => {
    const
        horizontalAlignment = R.ifElse(R.is(Array), R.prop(0), R.always(null))(alignment),
        verticalAlignment = R.ifElse(R.is(Array), R.prop(1), R.always(null))(alignment),

        renderCell = (alignmentConfig, index) => {
            const
                [Icon, verticalAlign, horizontalAlign] = alignmentConfig,
                isSelected = isAligmentButtonActive(
                    horizontalAlign,
                    verticalAlign,
                    { horizontalAlign: horizontalAlignment, verticalAlign: verticalAlignment }
                ),
                titleMsgKey = `${verticalAlign}${horizontalAlign}`,
                disabledCell = disabled || disabledCells.includes(alignmentConfig);
            return <TdIconButton
                key={index}
                title={intl.msgJoint(disabledCell ? disabledTooltip : AlignmentTableTitleMsg[titleMsgKey])}
                data-title-position={verticalAlign === VerticalAlign.TOP ? 'top' : 'bottom'}
                onClick={() => { if (!disabledCell) { dispatch({ type: alignmentChangeAction, payload: [horizontalAlign, verticalAlign] }); } }}
                isSelected={isSelected}
                iconClassName={cx(styles.iconClassName, { [styles.disabled]: isPropertiesPanel && disabledCell })}
                Icon={Icon}
                disabled={disabledCell}
                className={cx(styles.iconButton, {
                    [styles.iconButtonSelectedPropertiesPanel]: isPropertiesPanel && isSelected,
                    [styles.disabled]: disabledCell
                })}
            />;
        },

        renderRow = (configsRow, index) => (
            <tr key={index}>
                {configsRow.map(renderCell)}
            </tr>
        ),

        view = (
            <table className={cx(styles.table, styles.alignmentTable)}>
                <tbody>
                    {alignmentConfigs.map(renderRow)}
                    {showFit && <tr>
                        <TdIconButton
                            title="fit"
                            onClick={() => dispatch({ type: actionTypes.HORIZONTAL_ALIGN_FIT })}
                            isSelected={horizontalAlignment === HorizontalAlign.FIT}
                            Icon={Icons.FULL_WIDTH}
                            colSpan={3}
                            disabled={disabled}
                        />
                    </tr>}
                </tbody>
            </table>
        );

    return disabled
        ? (<ComponentMask showPreviewOnlyLayer={false} maskStyle={ComponentMaskBackground.TRANSPARENT}>{view}</ComponentMask>)
        : view;
});
