import React, { useRef } from "react";

import "tinymce/tinymce";
// A theme is also required
import "tinymce/themes/modern/theme";

// Any plugins you want to use has to be imported
import "tinymce/plugins/paste";
import "tinymce/plugins/lists";
import "tinymce/plugins/link";

import { Editor } from "@tinymce/tinymce-react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../../common/selectors";
import { PRIVACY_POLICY_EDITOR_CONTENT_CHANGED_ACTION } from "../actions";
import { MAX_ALLOWED_CHARACTERS } from "../constants";
import { getTruncatedContent } from "./utils";
import './styles.css';

const toolbar = "undo redo bold italic underline link numlist bullist formatselect";

export const TextEditor = ({ content, setCharCount }) => {
    const editorRef = useRef<any>(null);
    const language = useSelector(selectCurrentLanguage);
    const dispatch = useDispatch();

    const getContentCount = () => {
        const editorContent = editorRef.current.getContent({ format: 'text' });
        return editorContent === "\n" ? 0 : editorContent.length;
    };

    return (
        <Editor
            // eslint-disable-next-line no-return-assign
            onInit={(evt, editor) => {
                editorRef.current = editor;

                const contentLength = getContentCount();
                setCharCount(contentLength);
            }}
            onEditorChange={(val => {
                const contentLength = getContentCount();
                setCharCount(contentLength);

                let limitedContent;

                if (contentLength <= MAX_ALLOWED_CHARACTERS) {
                    limitedContent = val;
                } else {
                    const editorContent = editorRef.current.getContent();
                    limitedContent = getTruncatedContent(editorContent, MAX_ALLOWED_CHARACTERS);
                }

                dispatch({ type: PRIVACY_POLICY_EDITOR_CONTENT_CHANGED_ACTION, payload: { limitedContent, content: val } });
            })}
            value={content}
            init={{
                branding: false,
                min_height: 360,
                menubar: false,
                plugins: ["paste", "lists", "link"],
                toolbar,
                statusbar: false,
                language,
                language_url: `/dashboard-assets/tinymce/langs/${language}.js`,  // Path to your language file
                skin_url: '/dashboard-assets/tinymce/skins/lightgray',           // Path to your skin directory
            }}
        />

    );
};
