import * as R from "ramda";
import { overPathWhen } from "../../../utils/ramdaEx";
import { UNDO } from "../../../epics/undoManager/updateReasons";
import type { Dimensions } from "../../../redux/modules/children/workspace/flowTypes";
import type { AdjustmentHook, ShouldCallAdjustmentHook, AdjustmentHookConfig,
    MHFCmpsDataItem } from "../../Workspace/epics/componentsEval/flowTypes";
import type { ComponentDependencies, MenuComponent } from "./flowTypes";
import { isMoreButtonModeSelector, isMoreButtonModeSettableSelector, selectPagesFromLevel } from "./selectors";
import { makeSetCurrentPageIdAndHasChildren } from "./utils";
import { isSafari } from "../../../../utils/browser";
import { getTopMostParentId } from "../../Workspace/epics/componentAttachements/util";
import { isModernHeaderOrFooter } from "../../ModernLayouts/utils";

type ComponentExtension = {
    showMoreButton?: boolean;
    pagesToShow?: number;
    layoutTypeChanged?: boolean;
    onePageAndMoreButtonWidth?: number;
};
type AddjustmentData = {
    minDimensionsForHook: Dimensions;
    itemsDimensions: Array<{
        id: string;
        width: number;
    }>;
};
type MenuShouldCallAdjustmentHook = ShouldCallAdjustmentHook<MenuComponent, ComponentDependencies, ComponentExtension, AddjustmentData>;
type Hook = AdjustmentHook<MenuComponent, ComponentDependencies, ComponentExtension, AddjustmentData>;

function updateIf(propValue, comparator, propName) {
    const pathToUpdate = [propName],
        predicate = comparator(propValue),
        putPropValue = R.always(propValue);
    return overPathWhen([pathToUpdate], predicate)(putPropValue);
}

const
    isCartAllowedInMenu = (componentDependencies) => {
        return !!(componentDependencies?.menuState.isMenuCartAllowed);
    },
    selectWidthOnly = R.map(R.prop("width")),
    getWidth = R.prop("width"),
    getMenuMoreBtnWidth = R.pipe(R.filter(item => !item.isCart), R.last, getWidth),
    getMenuCartBtnWidth = R.pipe(R.filter(item => item.isCart), R.last, getWidth),
    /* todo test getFittingItemsCount */
    _getFittingItemsCount = (widthAvailableForItems: number, itemsWidths: Array<number>): number => {
        const [, fittingItemsCount] = R.reduceWhile(
            ([widthAcc], width) => widthAcc + width <= widthAvailableForItems,
            ([widthAcc, count], width) => [widthAcc + width, count + 1],
            [isSafari() ? 1 : 0, 0],
            itemsWidths
        );
        return fittingItemsCount;
    },
    getFittingItemsCount = (component, adjustmentData, itemsCount, isCmpInsideModernLayout, maxWidth, componentDependencies) => {
        let availableWidth = component.width;

        if (!adjustmentData) {
            throw new Error("getFittingItemsCount !adjustmentData Should never happen. make flow happy");
        }

        if (isCmpInsideModernLayout) {
            availableWidth = maxWidth;
        }

        const
            isCartAllowed = isCartAllowedInMenu(componentDependencies),
            moreButtonWidth = getMenuMoreBtnWidth(adjustmentData?.itemsDimensions ?? []),
            cartButtonWidth = isCartAllowed ? getMenuCartBtnWidth(adjustmentData?.itemsDimensions ?? []) : 0,
            itemsWidths = isCartAllowed ?
                selectWidthOnly([...R.take(itemsCount, adjustmentData.itemsDimensions), R.last(adjustmentData.itemsDimensions)]) :
                selectWidthOnly(R.take(itemsCount, adjustmentData.itemsDimensions)),
            fitCountWithoutMoreBtn = _getFittingItemsCount(availableWidth, itemsWidths),
            shouldAddMoreButton = fitCountWithoutMoreBtn < (itemsCount + (isCartAllowed ? 1 : 0));
        if (shouldAddMoreButton) {
            return _getFittingItemsCount(availableWidth - moreButtonWidth - cartButtonWidth, itemsWidths);
        } else {
            return fitCountWithoutMoreBtn;
        }
    },
    isMenuInModernLayout = (component, attachments, componentsMap) => {
        return isModernHeaderOrFooter(componentsMap[getTopMostParentId(component.id, attachments)]);
    },
    getMenuItemsWidth = (adjustmentData, itemsCount) => {
        const menuItemsWidth = (adjustmentData.itemsDimensions
            && selectWidthOnly(R.take(itemsCount + 1, adjustmentData.itemsDimensions))) || [];
        return menuItemsWidth;
    },
    getMaxMenuWidth = (adjustmentData, itemsCount) => {
        return Math.ceil(getMenuItemsWidth(adjustmentData, itemsCount).reduce((itemWidth, a) => itemWidth + a, 0));
    },
    getMenuWidthWithOnePageAndMoreButtonInModernLayout = (adjustmentData, itemsCount) => {
        const menuItemsWidth = getMenuItemsWidth(adjustmentData, itemsCount);
        if (menuItemsWidth.length > 1) {
            return Math.ceil(menuItemsWidth[0] + menuItemsWidth[menuItemsWidth.length - 1]);
        } else if (menuItemsWidth.length === 1) {
            return Math.ceil(menuItemsWidth[0]);
        } else {
            return 0;
        }
    },
    getWidthOfFittingMenuItems = (menuItemsWidth, maxAvailableWidthForMenu, moreButtonWidth) => {
        let remainingWidth = maxAvailableWidthForMenu;
        remainingWidth -= moreButtonWidth;
        for (let i = 0; i < menuItemsWidth.length; i++) {
            const itemWidth = menuItemsWidth[i];
            if (remainingWidth < itemWidth) {
                break;
            }
            remainingWidth -= itemWidth;
        }
        return maxAvailableWidthForMenu - Math.floor(remainingWidth);
    };

export const getMenuRenderWidthInModernLayout = (
    itemsCount: number = 0,
    adjustmentData: any,
    maxAvailableWidthForMenu: number = 0,
    onePageAndMoreButtonWidth: number = 0,
    showMoreButtonSettings: any
) => {
    const menuItemsWidth = (adjustmentData.itemsDimensions && selectWidthOnly(R.take(itemsCount, adjustmentData.itemsDimensions))) || [],
        { width: moreButtonWidth } =
        (adjustmentData.itemsDimensions && adjustmentData.itemsDimensions[adjustmentData.itemsDimensions.length - 1]) || { width: 0 },
        totalMenuItemsWidth = Math.ceil(menuItemsWidth.reduce((a, b) => a + b, 0)),
        menuItemsWidthFittingInModernLayout = (totalMenuItemsWidth > maxAvailableWidthForMenu) && showMoreButtonSettings.showMoreButton
            ? getWidthOfFittingMenuItems(menuItemsWidth, maxAvailableWidthForMenu, moreButtonWidth) : totalMenuItemsWidth;
    return onePageAndMoreButtonWidth && itemsCount > 2
        ? Math.max(
            Math.min(menuItemsWidthFittingInModernLayout, maxAvailableWidthForMenu),
            onePageAndMoreButtonWidth
        )
        : Math.min(totalMenuItemsWidth, maxAvailableWidthForMenu);
};

const getPagesCount = (component, componentDependencies) => {
        const preparePages = makeSetCurrentPageIdAndHasChildren(componentDependencies.currentPageId);
        return selectPagesFromLevel({
            pages: preparePages(componentDependencies.siteData.folder.items),
            level: component.startLevel
        }).filter(page => !page.hidden).length;
    },
    isNotMoreButtonMode = R.complement(isMoreButtonModeSelector),
    calcShowMoreButtonSettings = ({ componentDependencies, component, adjustmentData, attachments, componentsMap, maxWidth }) => {
        const isMenuInsideModernLayout = attachments && componentsMap ? isMenuInModernLayout(component, attachments, componentsMap) : false,
            pagesCount = getPagesCount(component, componentDependencies),
            fittingItemsCount =
                getFittingItemsCount(component, adjustmentData, pagesCount, isMenuInsideModernLayout, maxWidth, componentDependencies);
        return {
            showMoreButton: fittingItemsCount < pagesCount,
            pagesToShow: fittingItemsCount
        };
    },
    computeMinWidth = ({ shouldCalculateComponentExtension, isNotMoreButtonMode, minRenderWidth, adjustmentData, isCartAllowed }) => {
        if (shouldCalculateComponentExtension) {
            const moreButtonWidth = getMenuMoreBtnWidth(adjustmentData?.itemsDimensions ?? []),
                cartButtonWidth = isCartAllowed ? getMenuCartBtnWidth(adjustmentData?.itemsDimensions ?? []) : 0;
            return moreButtonWidth + cartButtonWidth;
        } else if (isNotMoreButtonMode) {
            return minRenderWidth;
        } else {
            return 1;
        }
    },
    getMinRenderWidth = (componentDependencies, minDimensions, adjustmentData, isMoreButtonMode) => {
        const moreBtnWidth = getMenuMoreBtnWidth(adjustmentData.itemsDimensions ?? []),
            cartBtnWidth = getMenuCartBtnWidth(adjustmentData.itemsDimensions ?? []);
        if (isCartAllowedInMenu(componentDependencies)) {
            return moreBtnWidth + cartBtnWidth;
        }
        if (isMoreButtonMode) {
            return moreBtnWidth;
        }
        return minDimensions.width;
    },
    hook: Hook = (nextState, prevState, updateReason) => {
        const { component, adjustmentData, componentDependencies, componentExtension, attachments, componentsMap, mhfCmpsData } = nextState,
            { component: prevComponentState, adjustmentData: prevAdjustmentData } = prevState;
        let menuData: MHFCmpsDataItem = {} as MHFCmpsDataItem,
            updatedComponentExtension = componentExtension,
            onePageAndMoreButtonWidth;
        const isMenuInsideModernLayout = (attachments && componentsMap) ?
                isMenuInModernLayout(component, attachments, componentsMap) : false,
            pagesCount = (componentDependencies && getPagesCount(component, componentDependencies)) || 0;

        if (!adjustmentData || !componentDependencies) {
            throw new Error("adjustmentData, componentDependencies should be defined. flowtype:)");
        }

        if (updateReason === UNDO) {
            return [component, updatedComponentExtension];
        }

        if (isMenuInsideModernLayout) {
            onePageAndMoreButtonWidth = getMenuWidthWithOnePageAndMoreButtonInModernLayout(adjustmentData, pagesCount);
            updatedComponentExtension = {
                ...(updatedComponentExtension || {
                    layoutTypeChanged: false
                }),
                onePageAndMoreButtonWidth
            };
        }

        const minDimensions = adjustmentData.minDimensionsForHook;

        menuData = {
            id: component.id,
            maxWidth: minDimensions.width,
            minLeft: component.left,
        };

        if (isMenuInsideModernLayout) {
            menuData = (mhfCmpsData || {})[component.id] || menuData;
        }

        const
            prevMinDimensions = prevAdjustmentData ?
                prevAdjustmentData.minDimensionsForHook : null,
            prevMinRenderHeight = prevMinDimensions ? prevMinDimensions.height : null,
            prevMinRenderWidth = prevMinDimensions ? prevMinDimensions.width : null,
            isMoreButtonMode = isMoreButtonModeSelector(component),
            shouldCalculateComponentExtension = isMoreButtonMode && adjustmentData.itemsDimensions,
            showMoreButtonSettings = shouldCalculateComponentExtension
                ? calcShowMoreButtonSettings({
                    componentDependencies,
                    component,
                    adjustmentData,
                    attachments,
                    componentsMap,
                    maxWidth: menuData.maxWidth
                })
                : {
                    showMoreButton: false
                },
            minRenderHeight = minDimensions.height,
            minRenderWidth = isMenuInsideModernLayout
                ? getMenuRenderWidthInModernLayout(
                    pagesCount,
                    adjustmentData,
                    menuData.maxWidth,
                    onePageAndMoreButtonWidth,
                    showMoreButtonSettings
                )
                : getMinRenderWidth(
                    componentDependencies,
                    minDimensions,
                    adjustmentData,
                    isMoreButtonMode
                ),
            minDimensionsChangedAndLayoutTypeChanged =
                prevMinRenderHeight !== null &&
                prevMinRenderWidth !== null &&
                (prevMinRenderHeight !== minRenderHeight || prevMinRenderWidth !== minRenderWidth) &&
                updatedComponentExtension &&
                !!updatedComponentExtension.layoutTypeChanged,
            minWidth = isMenuInsideModernLayout
                ? minRenderWidth
                : computeMinWidth({
                    shouldCalculateComponentExtension,
                    isNotMoreButtonMode,
                    minRenderWidth,
                    adjustmentData,
                    isCartAllowed: isCartAllowedInMenu(componentDependencies)
                }),
            maxWidth = getMaxMenuWidth(adjustmentData, pagesCount),

            minHeight = minRenderHeight,
            heightShouldBeAtLeastMinHeight =
                isMenuInsideModernLayout && minHeight < component.height
                    ? updateIf(minHeight, R.lt, "height")
                    : updateIf(minHeight, R.gt, "height"),
            widthShouldBeAtLeastMinWidth = updateIf(minWidth, R.gt, "width"),
            finalComponent = R.pipe(
                heightShouldBeAtLeastMinHeight,
                widthShouldBeAtLeastMinWidth,
            )(component),
            setLayoutTypeChangedTrueIfChanged = R.when(
                () => prevComponentState && prevComponentState.layoutType !== component.layoutType,
                R.assoc("layoutTypeChanged", true)
            ),
            setLayoutTypeChangedToFalseWhenChangesAppliedToComponent = R.when(
                () => minDimensionsChangedAndLayoutTypeChanged,
                R.assoc("layoutTypeChanged", false)
            ),
            moreButtonEditDisabled = !isMoreButtonModeSettableSelector(component),
            setMoreButtonEditDisabled = R.assoc("moreButtonEditDisabled", moreButtonEditDisabled),
            moreButtonTextEditDisabled = moreButtonEditDisabled || !component.moreButtonEnabled,
            setMoreButtonTextEditDisabled = R.assoc("moreButtonTextEditDisabled", moreButtonTextEditDisabled),
            updateOldComponentExtension = newComponentExtension => ({ ...updatedComponentExtension, ...newComponentExtension }),
            setMinimumDimensions = R.assoc("minDimensions", {
                width: Math.min(onePageAndMoreButtonWidth ?? Infinity, minRenderWidth),
                height: minHeight
            }),
            setMaximumDimensions = R.assoc('maxDimensions', { width: maxWidth }),
            returnNewComponentsExtensionIfChanged = nextComponentExtension =>
                (R.equals(updatedComponentExtension, nextComponentExtension) ? componentExtension : nextComponentExtension),
            finalComponentExtension = R.pipe(
                setLayoutTypeChangedTrueIfChanged,
                setLayoutTypeChangedToFalseWhenChangesAppliedToComponent,
                setMoreButtonEditDisabled,
                setMoreButtonTextEditDisabled,
                updateOldComponentExtension,
                setMinimumDimensions,
                setMaximumDimensions,
                returnNewComponentsExtensionIfChanged
            )(showMoreButtonSettings);

        return [finalComponent, finalComponentExtension];
    },
    propsNotFulfilled = ({ adjustmentData, componentDependencies }) =>
        !adjustmentData || adjustmentData.minDimensionsForHook === undefined || componentDependencies === undefined,
    shouldCallHook: MenuShouldCallAdjustmentHook = (prevProps, nextProps) => {
        // @ts-ignore
        if (propsNotFulfilled(nextProps)) {
            return false;
        }

        return (
            prevProps.component !== nextProps.component ||
            !prevProps.componentDependencies ||
            !nextProps.componentDependencies ||
            (prevProps.componentDependencies !== nextProps.componentDependencies &&
                prevProps.componentDependencies.siteData !== nextProps.componentDependencies.siteData) ||
            prevProps.adjustmentData !== nextProps.adjustmentData ||
            prevProps.mhfCmpsData !== nextProps.mhfCmpsData
        );
    },
    hookConfig: AdjustmentHookConfig<MenuComponent, ComponentDependencies, ComponentExtension, AddjustmentData> = {
        hook,
        shouldCallHook
    };

export default hookConfig;
