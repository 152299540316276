import type { TinyMceEditor } from "../../../flowTypes";
import { MCT_CONTENT_BODY_REGEX } from "../../constants";
import { getAttributeAsString } from '../../../../../../../utils/dom';

export const getSelectedNode = (editor: TinyMceEditor): HTMLElement | null | undefined => {
    if (!editor || editor.removed) {
        return null;
    }

    let selectedNode = editor.selection.getStart();
    if (selectedNode.nodeName === 'BODY' || selectedNode.nodeName === 'HEAD') {
        // $FlowFixMe: WBTGEN-9962: firstChild can be a node, text|comment, put checks
        return editor.getBody().firstChild as HTMLElement;
    }

    // WORKAROUND_START if selection is outside of TinyMCE return null. This is workaround in tinyMCE bug with selection when multiple editors exist without iFrame
    // TODO Probably can be removed when IFrame mode is implemented. Check that https://jira.one.com/browse/WBTGEN-8412 increase_cell.mp4 is working
    let node = selectedNode;
    do {
        if (!node.parentNode) {
            return null;
        }
        node = node.parentNode as any;
        // $FlowFixMe: WBTGEN-9962: parentNode can be null, put checks
    } while (!MCT_CONTENT_BODY_REGEX.test(getAttributeAsString(node, 'class')));
    // WORKAROUND_END

    return selectedNode;
};
