/* eslint-disable max-len */
import { assocPath, pipe, pathOr, path } from 'ramda';
import makeEpic from "../../../../epics/makeEpic";
import { DYNAMIC_PREVIEW_EPIC as valueActionType, DYNAMIC_TEMPLATE_EPIC } from "./valueActionType";
import { receiveOnly } from "../../../../epics/makeCondition";
import * as Actions from "./actionTypes";
import localStorage from '../../../../utils/localStorage';
import { DT_GMBKEY, DYNAMIC_PREVIEW_TOASTER_TYPES } from "../constants";
import { toHsl } from '../../../../../dal/pageMapAdapter/mappers/Base/color';
import { blackColorValueHSL, whiteColorValueHSL } from "../../../ThemeGlobalData/constants";
import generateSectionContent from "./actionCreators/generateSectionContent";
import { DYNAMIC_TEMPLATE_AFTER_PAGE_DATA_UPDATE,
    DYNAMIC_TEMPLATE_IMPORT_TEMPLATE_CLICK,
    DYNAMIC_TEMPLATE_UPDATE_PAGE_DATA,
    RETURN_TO_ONBOARDING } from "../../../TemplateSelector_DEPRECATED/actionTypes";
import { extractTextFromTextComponents } from "./utils";
import templateSelectorPreviewVAT from "../../../TemplateSelector_DEPRECATED/epics/preview/valueActionType";
import { DynamicPreviewPaths } from './constants';
import { closeDynamicPreviewToaster, dynamicPreviewToaster } from '../dynamicpreviewToaster';
import { ERROR_TYPES } from '../view/ErrorModels/constants';
import { ROmessages } from '../../../Toaster/epic/selectorActionTypes';
import { WORKSPACE_READY } from '../../../Workspace/actionTypes';
import { LOAD_TEMPLATE_PAGES_SUCCESS_ACTION } from '../../../oneweb/Template/actionTypes';
import { GET_TRIAL_GPT_DEMO_DATA_SUCCESS_ACTION } from '../../../../../demo/modules/gptDemo/actions';

const getSectionIdToTitleMap = (state) => state.sectionIdToTitleMap || {};

type sectionStateProps = {
    currentSection?: number;
    sectionDataLoading?: boolean;
    limitReached?: boolean;
}

type DynamicPreviewEpicState = {
    editPreview: Boolean;
    sectionDataLoading: Boolean;
    sectionIdToTitleMap: Object;
    staticTextComponents: Array<string>;
    editPreviewEtag: string;
    sectionsState: sectionStateProps;
}

export const defaultState: DynamicPreviewEpicState = {
    editPreview: false,
    sectionDataLoading: false,
    sectionIdToTitleMap: {},
    staticTextComponents: [],
    editPreviewEtag: '',
    sectionsState: {}
};

export const dynamicPreviewEpic = makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                receiveOnly(templateSelectorPreviewVAT),
                Actions.DYNAMIC_TEMPLATE_REGENERATE_TEXT_BUTTON_CLICKED
            ],
            reducer: ({ state, values: [templatePreviewAppState, { sectionId, cmpsHTML }] }) => {
                const {
                    getCmpsHtmlPath,
                    getCmpsDataPath,
                    getSectionCmpIdsPath,
                    getCurrentVersionsPath,
                    currentSectionPath,
                    sectionDataLoadingPath,
                } = DynamicPreviewPaths;

                const { staticTextComponents } = state;
                const items = templatePreviewAppState.themePreviewDataForOnboarding?.computedDataPageSetForNewOnBoarding.pages[0].items;

                const currentVersionPath = getCurrentVersionsPath(sectionId);

                const sectionTitle = getSectionIdToTitleMap(state)[sectionId],
                    textIds = Object.keys(cmpsHTML).filter(id => !staticTextComponents.includes(id)),
                    textComponents = items.filter(item => textIds.includes(item.id)),
                    oldTexts = extractTextFromTextComponents(textComponents),
                    currentVersion = pathOr(0, currentVersionPath, state);

                let newState = pipe(
                    assocPath(sectionDataLoadingPath, true),
                    assocPath(currentSectionPath, sectionId),
                    assocPath(getSectionCmpIdsPath(sectionId), textIds),
                    assocPath(getCmpsHtmlPath(sectionId, currentVersion), cmpsHTML),
                    assocPath(getCmpsDataPath(sectionId, currentVersion), textComponents),
                )(state);

                return {
                    state: newState,
                    multipleActionsToDispatch: [{
                        type: Actions.DYNAMIC_TEMPLATE_GET_SECTION_CONTENT,
                        payload: { filterSections: [sectionTitle], oldTexts }
                    }]
                };
            }
        },
        {
            conditions: [receiveOnly(templateSelectorPreviewVAT), Actions.DYNAMIC_TEMPLATE_REGENERATE_TEXT_TRY_AGAIN_CLICKED],
            reducer: ({ state, values: [templatePreviewAppState] }) => {
                const {
                    currentSectionPath,
                    getCurrentVersionsPath,
                    sectionDataLoadingPath,
                    getCmpsHtmlPath,
                } = DynamicPreviewPaths;

                const { staticTextComponents } = state;
                const items = templatePreviewAppState.themePreviewDataForOnboarding?.computedDataPageSetForNewOnBoarding.pages[0].items;
                const sectionId = path(currentSectionPath, state);
                const currentVersionPath = getCurrentVersionsPath(sectionId),
                    currentVersion = pathOr(0, currentVersionPath, state),
                    cmpsHTML = path(getCmpsHtmlPath(sectionId, currentVersion), state);
                const sectionTitle = getSectionIdToTitleMap(state)[sectionId],
                    textIds = Object.keys(cmpsHTML).filter(id => !staticTextComponents.includes(id)),
                    textComponents = items.filter(item => textIds.includes(item.id)),
                    oldTexts = extractTextFromTextComponents(textComponents);

                let newState = pipe(
                    assocPath(sectionDataLoadingPath, true)
                )(state);

                return {
                    state: newState,
                    multipleActionsToDispatch: [{
                        type: Actions.DYNAMIC_TEMPLATE_GET_SECTION_CONTENT,
                        payload: { filterSections: [sectionTitle], oldTexts }
                    }]
                };
            }
        },
        {
            conditions: [
                receiveOnly(templateSelectorPreviewVAT),
                Actions.DYNAMIC_PREVIEW_SHOW_PREVIOUS_TEXT
            ],
            reducer: ({ state, values: [templatePreviewAppState, { sectionId, cmpsHTML }] }) => {
                const {
                    getCmpsHtmlPath,
                    getCmpsDataPath,
                    getSectionCmpIdsPath,
                    getCurrentVersionsPath,
                    sectionDataLoadingPath,
                    getIsLastVersionPath,
                    getAllVersionsPath,
                    currentSectionPath,
                    getIsFirstVersionPath
                } = DynamicPreviewPaths;

                let newState = state;
                const items = templatePreviewAppState.themePreviewDataForOnboarding?.computedDataPageSetForNewOnBoarding.pages[0].items;
                const currentVersionPath = getCurrentVersionsPath(sectionId);

                const
                    textIds = path(getSectionCmpIdsPath(sectionId), state),
                    textComponents = items.filter(item => textIds.includes(item.id)),
                    currentVersion = pathOr(0, currentVersionPath, state),
                    allVersions = path(getAllVersionsPath(sectionId), state);

                if (currentVersion <= 0) {
                    return { state: assocPath(getIsFirstVersionPath(sectionId), true, state), };
                }

                // In case the current version is not in the state, we need to add it
                if (!allVersions[currentVersion]) {
                    newState = pipe(
                        assocPath(sectionDataLoadingPath, false),
                        assocPath(getCmpsHtmlPath(sectionId, currentVersion), cmpsHTML),
                        assocPath(getCmpsDataPath(sectionId, currentVersion), textComponents),
                    )(state);
                }

                const updatedVersion = currentVersion - 1;
                const components = path(getCmpsDataPath(sectionId, updatedVersion), state);

                newState = pipe(
                    assocPath(currentSectionPath, sectionId),
                    // Should always be the last in the pipe
                    assocPath(getIsFirstVersionPath(sectionId), updatedVersion === 0),
                    assocPath(getIsLastVersionPath(sectionId), false),
                    assocPath(currentVersionPath, updatedVersion),
                )(newState);

                return {
                    state: newState,
                    multipleActionsToDispatch: [{ type: DYNAMIC_TEMPLATE_UPDATE_PAGE_DATA, payload: components }]
                };
            }
        },
        {
            conditions: [
                Actions.DYNAMIC_PREVIEW_SHOW_NEXT_TEXT
            ],
            reducer: ({ state, values: [{ sectionId }] }) => {
                const {
                    getCmpsDataPath,
                    getCurrentVersionsPath,
                    getIsLastVersionPath,
                    getAllVersionsPath,
                    currentSectionPath,
                    getIsFirstVersionPath,
                } = DynamicPreviewPaths;

                let newState = state;

                const currentVersionPath = getCurrentVersionsPath(sectionId);

                const currentVersion = pathOr(0, currentVersionPath, state),
                    allVersions = path(getAllVersionsPath(sectionId), state);

                if (currentVersion >= allVersions.length - 1) {
                    return { state: assocPath(getIsLastVersionPath(sectionId), true, state) };
                }

                const updatedVersion = currentVersion + 1;
                const components = path(getCmpsDataPath(sectionId, updatedVersion), state);

                newState = pipe(
                    assocPath(currentSectionPath, sectionId),
                    // Should always be the last in the pipe
                    assocPath(getIsFirstVersionPath(sectionId), updatedVersion === 0),
                    assocPath(getIsLastVersionPath(sectionId), updatedVersion === allVersions.length - 1),
                    assocPath(currentVersionPath, updatedVersion),
                )(newState);

                return {
                    state: newState,
                    multipleActionsToDispatch: [{ type: DYNAMIC_TEMPLATE_UPDATE_PAGE_DATA, payload: components }]
                };
            }
        },
        {
            conditions: [
                receiveOnly(DYNAMIC_TEMPLATE_EPIC),
                Actions.DYNAMIC_TEMPLATE_GET_SECTION_CONTENT,
            ],
            reducer: ({ state, values: [onboardingEpicState, { filterSections, oldTexts }] }) => {
                let defaultColor: any = {};
                const { contactInfo, shopDisabled, gmbKey, isGroup,
                    businessName, purpose: _purpose, keywords, showShopWelcome,
                    language, concept: { name, optionSet } } = onboardingEpicState;
                const defaultPalette = onboardingEpicState.concept?.colorThemes[0];
                const { addressLocation, address, addressUrl } = (contactInfo?.completeAddress || {});
                const addressData = {
                    addressLocation,
                    addressText: address,
                    addressUrl
                };
                if (defaultPalette?.length === 2) {
                    defaultColor = {
                        accentColor: toHsl(defaultPalette[0]),
                        mainColor: toHsl(defaultPalette[1]),
                        whiteColor: whiteColorValueHSL,
                        blackColor: blackColorValueHSL
                    };
                }

                let purpose = shopDisabled && optionSet?.key === 'shop' ? [optionSet.set[0]] : [..._purpose];

                const multipleActionsToDispatch: any[] = [generateSectionContent({
                    websiteTitle: businessName,
                    country: contactInfo?.completeAddress?.addressCountryCode,
                    city: contactInfo?.completeAddress?.addressCity,
                    gmbKey,
                    group: isGroup,
                    concept: name,
                    aboutKeywords: keywords,
                    optionSet: purpose,
                    defaultColor,
                    addressData: addressUrl ? addressData : {},
                    language,
                    additionalData: {
                        localGmbKey: localStorage.get(DT_GMBKEY)
                    },
                    addShopSection: showShopWelcome && !['onlineShopCatchall', 'shops'].includes(name),
                    filterSections,
                    oldTexts
                })];

                return {
                    state,
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [
                Actions.GENERATE_SECTION_CONTENT_SUCCESS
            ],
            reducer: ({ state, values: [{ components, sectionIdToTitleMap, filterSections }] }) => {
                const {
                    sectionDataLoadingPath,
                    getIsLastVersionPath,
                    getIsFirstVersionPath,
                    getCurrentVersionsPath,
                    getAllVersionsPath,
                } = DynamicPreviewPaths;
                const [sectionTitle] = filterSections;
                const sectionId = Object.keys(sectionIdToTitleMap).find(k => sectionIdToTitleMap[k] === sectionTitle);
                const currentVersionPath = getCurrentVersionsPath(sectionId);
                const updatedVersion = path(getAllVersionsPath(sectionId), state);
                let newState = pipe(                    // Should always be the last in the pipe
                    assocPath(sectionDataLoadingPath, false),
                    assocPath(getIsLastVersionPath(sectionId), true),
                    assocPath(getIsFirstVersionPath(sectionId), false),
                    assocPath(currentVersionPath, updatedVersion.length),
                )(state);
                if (!sectionId || !state.editPreview) {
                    newState = assocPath(sectionDataLoadingPath, false, state);
                    return { state: newState };
                }
                return {
                    state: newState,
                    multipleActionsToDispatch: [{ type: DYNAMIC_TEMPLATE_UPDATE_PAGE_DATA, payload: components },
                        ...closeDynamicPreviewToaster(),
                        dynamicPreviewToaster(DYNAMIC_PREVIEW_TOASTER_TYPES.SUCCESS)]
                };
            }
        },
        {
            conditions: [ROmessages, Actions.GENERATE_SECTION_CONTENT_FAILED],
            reducer: ({ state, values: [messages, data] }) => {
                const {
                    currentSectionPath,
                    sectionDataLoadingPath,
                    sectionGenerationLimitReachedPath
                } = DynamicPreviewPaths;
                let newState = state;
                const sectionId = path(currentSectionPath, state);
                if (!sectionId || !state.editPreview) {
                    newState = assocPath(sectionDataLoadingPath, false, state);
                    return { state: newState };
                }

                let errorType: string | null = null;
                if (data.status === 451) {
                    errorType = ERROR_TYPES.DYNAMIC_TEMPLATES_INAPPROPRIATE_LANGUAGE_ERROR;
                } else if (data.status === 429) {
                    errorType = ERROR_TYPES.DYNAMIC_TEMPLATES_LIMIT_ERROR;
                } else if (data.status === 503) {
                    errorType = ERROR_TYPES.DYNAMIC_TEMPLATES_HIGH_TRAFFIC_ERROR;
                } else {
                    errorType = ERROR_TYPES.DYNAMIC_TEMPLATES_CONNECTION_ERROR;
                }
                const errorMsg = messages.filter(x => x.msgKey === DYNAMIC_PREVIEW_TOASTER_TYPES.ERROR_TOASTER_KEY);
                if (errorMsg.length) errorType = DYNAMIC_PREVIEW_TOASTER_TYPES.REPEAT_ERROR;
                newState = pipe(
                    assocPath(sectionDataLoadingPath, false),
                    assocPath(sectionGenerationLimitReachedPath, data.status === 429)
                )(state);
                return {
                    state: newState,
                    multipleActionsToDispatch: [
                        ...closeDynamicPreviewToaster(),
                        dynamicPreviewToaster(errorType)]
                };
            }
        },
        ...[Actions.DYNAMIC_PREVIEW_CLOSE_TOASTER, RETURN_TO_ONBOARDING, DYNAMIC_TEMPLATE_IMPORT_TEMPLATE_CLICK].map(actionType => ({ conditions: [actionType],
            reducer: ({ state }) => {
                return {
                    state,
                    multipleActionsToDispatch: closeDynamicPreviewToaster()
                };
            } }))
    ],
    handleActions: {
        [RETURN_TO_ONBOARDING]: () => defaultState,
        [WORKSPACE_READY]: () => defaultState,
        [Actions.DYNAMIC_PREVIEW_EDIT_OPTION_CHANGED]: (state, { payload }) => ({
            ...state,
            editPreview: payload
        }),
        [Actions.GET_GENERATED_AI_CONTENT_SUCCESS]: (state, { payload: { sectionIdToTitleMap, staticTextComponents } }) => {
            return {
                ...state,
                sectionIdToTitleMap,
                staticTextComponents
            };
        },
        [GET_TRIAL_GPT_DEMO_DATA_SUCCESS_ACTION]: (state, { payload: { data: { output } } }) => {
            const { sectionIdToTitleMap, staticTextComponents } = output;
            return {
                ...state,
                sectionIdToTitleMap,
                staticTextComponents
            };
        },
        [DYNAMIC_TEMPLATE_AFTER_PAGE_DATA_UPDATE]: (state) => {
            return {
                ...state,
                editPreviewEtag: `${Date.now()}`,
            };
        },
        [LOAD_TEMPLATE_PAGES_SUCCESS_ACTION]: (state) => {
            return {
                ...state,
                editPreview: false
            };
        }
    }
});
