import * as R from 'ramda';
import type { Element } from '../../../flowTypes';

const getRightElement = R.pipe(R.sortWith([R.descend(R.prop('end')), R.descend(R.prop('start'))]), R.head);

const getFirstChildElements = (elements: Array<Element>) => elements
    .filter((el, index, array) =>
        // @ts-ignore
        index === array.indexOf(array.find(e => e.start === el.start && e.end === el.end)))
    .reduce((topElements, element, index, elements) => {
        if (elements.some(e => e !== element && e.start <= element.start && e.end >= element.end)) {
            return topElements;
        }
        return [...topElements, element];
    }, [] as Element[]);

export default (element: Element) => {
    const splitElement = (parent: Element, elements: Array<Element>) => {
        const newElements: Element[] = [];

        const childElements = elements
            .filter(e => parent !== e && e.start >= parent.start && e.end <= parent.end);

        const firstChildElements = getFirstChildElements(childElements);
        if (!firstChildElements.length) {
            return [{
                ...element,
                start: parent.start,
                end: parent.end
            }];
        }

        if (parent.start !== firstChildElements[0].start) {
            newElements.push({
                ...element,
                start: element.start,
                end: firstChildElements[0].start
            });
        }

        for (let i = 0; i < firstChildElements.length - 1; i++) {
            if (firstChildElements[i].end !== firstChildElements[i + 1].start) {
                newElements.push({
                    ...element,
                    start: firstChildElements[i].end,
                    end: firstChildElements[i + 1].start
                });
            }
        }
        for (let i = 0; i < firstChildElements.length; i++) {
            newElements.push(...splitElement(firstChildElements[i], childElements));
        }

        const elementToRight = getRightElement(firstChildElements);
        if (parent.end !== elementToRight.end) {
            newElements.push({
                ...element,
                start: elementToRight.end
            });
        }

        return newElements;
    };

    return splitElement;
};
