
const { SubscriptionType } = require("./constants.js");
const { SUBSCRIPTIONERR } = require("./constants");

const SubscriptionErrors = {
    [SubscriptionType.NORMAL]: 'needs-standard-version-to-publish',
    [SubscriptionType.PREMIUM]: 'needs-premium-version-to-publish',
    [SubscriptionType.ECOMMERCE]: 'needs-ecommerce-version-to-publish'
};

const
    throwStandardRequiredToPublish = () => ({ [SUBSCRIPTIONERR]: SubscriptionErrors[SubscriptionType.NORMAL] }),
    throwPremiumRequiredToPublish = () => ({ [SUBSCRIPTIONERR]: SubscriptionErrors[SubscriptionType.PREMIUM] }),
    throwEcommerceRequiredToPublish = () => ({ [SUBSCRIPTIONERR]: SubscriptionErrors[SubscriptionType.ECOMMERCE] }),

    needsStandardToPublish = errObj => {
        return errObj && errObj.error && errObj.error === SubscriptionErrors[SubscriptionType.NORMAL];
    },

    needsPremiumToPublish = errObj => {
        return errObj && errObj.error && errObj.error === SubscriptionErrors[SubscriptionType.PREMIUM];
    },
    needsEcommerceToPublish = errObj => {
        return errObj && errObj.error && errObj.error === SubscriptionErrors[SubscriptionType.ECOMMERCE];
    };

module.exports = {
    throwStandardRequiredToPublish,
    throwPremiumRequiredToPublish,
    throwEcommerceRequiredToPublish,
    needsStandardToPublish,
    needsPremiumToPublish,
    needsEcommerceToPublish
}
