export const
    REGENERATE_TEXT_REQUEST = "REGENERATE_TEXT_REQUEST",
    REGENERATE_TEXT_SUCCESS = "REGENERATE_TEXT_SUCCESS",
    REGENERATE_TEXT_FAILED = "REGENERATE_TEXT_FAILED",
    REGENERATE_TEXT_BUTTON_CLICKED = "REGENERATE_TEXT_BUTTON_CLICKED",
    REGENERATED_TEXT_SELECTED = "REGENERATED_TEXT_SELECTED",
    AI_EDIT_TEXT_PANEL_SCROLLED = "AI_EDIT_TEXT_PANEL_SCROLLED",
    REGENERATE_TEXT_COMPLETE_EVENT = "REGENERATE_TEXT_COMPLETE_EVENT",
    REGENERATE_EDIT_TEXT_FAILED_EVENT = "REGENERATE_EDIT_TEXT_FAILED_EVENT",
    UPDATE_PROMPT_TEXT = "UPDATE_PROMPT_TEXT",
    AI_EDIT_TEXT_PANEL_SCROLLED_TO_BOTTOM = "AI_EDIT_TEXT_PANEL_SCROLLED_TO_BOTTOM",
    AI_EDIT_INSERT_BUTTON_CLICKED = 'AI_EDIT_INSERT_BUTTON_CLICKED',
    AI_EDIT_COPY_BUTTON_CLICKED = 'AI_EDIT_COPY_BUTTON_CLICKED',
    AI_TEXT_EDIT_INTRO_CLICKED = 'AI_TEXT_EDIT_INTRO_CLICKED',
    AI_TEXT_EDIT_INTRO_CLOSED = 'AI_TEXT_EDIT_INTRO_CLOSED',
    AI_TEXT_EDIT_AGREE_TERMS_CHECKED = 'AI_TEXT_EDIT_AGREE_TERMS_CHECKED';
