// TODO: move to gallery component and exnted FileChooser api to support isMaxMultiSelectValidation

import React from 'react';
import WarningDialog from '../dialogs/WarningDialog/index';
import { injectIntl } from "../../intl/index";
import getCenteredDialogConfig from "../../../components/DialogManager/getCenteredDialogConfig";

const
    width = 480,
    height = 350,
    mctaText = 'msg: common.ok {OK}';

const MaxMultiSelectWarningDialog = injectIntl(({ title, label, message, limit, intl }) => (
    <WarningDialog title={title} mctaText={mctaText}>
        {intl.msgJoint([message, { label, limit }])}
    </WarningDialog>
));

export default getCenteredDialogConfig(width, height, MaxMultiSelectWarningDialog);
