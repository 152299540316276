import React from 'react';
import { connect } from 'react-redux';
import RCSlider from '@one.com/rc-slider';

import '@one.com/rc-slider/assets/index.css';
import './Slider.css';

import { SLIDER_ON_AFTER_CHANGE, SLIDER_ON_BEFORE_CHANGE } from "./actionTypes";
import type { SliderProps } from "./flowTypes";

export default connect(() => ({}))(
    class Slider extends React.Component<SliderProps> {
        active: boolean = false;
        render() {
            const { dispatch, ...rest } = this.props;
            return (
                <RCSlider
                    {...rest}
                    onBeforeChange={() => {
                        this.active = true;
                        dispatch({ type: SLIDER_ON_BEFORE_CHANGE });
                    }}
                    onAfterChange={v => {
                        this.active = false;

                        dispatch({ type: SLIDER_ON_AFTER_CHANGE });
                        if (this.props.onAfterChange) {
                            this.props.onAfterChange(v);
                        }
                    }}
                    onChange={(val) => {
                        if (this.active && this.props.onChange) {
                            this.props.onChange(val);
                        }
                    }}
                />
            );
        }
    }
);
