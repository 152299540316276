import * as ActionTypes from '../../actionTypes';
import * as updateReasons from './updateReasons';
import { componentsMapSelector, componentsSelector } from "./selectorActionTypes";
import type { ComponentsEvalEpicUpdater } from './flowTypes';
import workspaceBBoxValueActionType from "../workspaceBBox/valueActionType";
import { receiveOnly } from "../../../../epics/makeCondition";
import applyResizingComponentsMutations from "./userInteractionMutations/applyResizingComponentsMutations";
import { ResizeE, ResizeS } from "../../../../utils/handle/kinds";
import { setComponentsMap } from "./setters";
import { COMPONENT_DIMENSIONS_CHANGED_FROM_PP, COMPONENTS_RESIZED } from './actionTypes';

const
    mouseDirections = {
        width: 'x',
        height: 'y'
    },
    handleKindOnProp = {
        width: ResizeE,
        height: ResizeS
    },
    makeUpdater = (updateActionType, propToUpdate): ComponentsEvalEpicUpdater => ({
        conditions: [
            receiveOnly(workspaceBBoxValueActionType),
            updateActionType
        ],
        reducer: ({ values: [workspaceBBox, { value, selectedComponentId }], state: epicState }) => {
            const componentsMap = componentsMapSelector(epicState);
            if (componentsMap[selectedComponentId]) {
                const currentValue = componentsMap[selectedComponentId][propToUpdate],
                    // @ts-ignore this need to be fixed
                    newEpicState = setComponentsMap(applyResizingComponentsMutations(
                        componentsSelector(epicState),
                        [selectedComponentId],
                        { x: 0, y: 0 },
                        // only diff is needed
                        {
                            x: 0,
                            y: 0,
                            [mouseDirections[propToUpdate]]: value - currentValue
                        },
                        handleKindOnProp[propToUpdate],
                        workspaceBBox,
                        epicState.scope.componentsAdjustmentData,
                        false
                    ), epicState);

                return {
                    state: newEpicState,
                    updateReason: updateReasons.RESIZED_FROM_PROP_PANEL,
                    multipleActionsToDispatch: [{
                        type: COMPONENT_DIMENSIONS_CHANGED_FROM_PP,
                        payload: {
                            selectedComponentId,
                            prop: propToUpdate,
                            diffPx: value - currentValue,
                            ppValue: value
                        }
                    }, {
                        type: COMPONENTS_RESIZED,
                        payload: { componentsId: [selectedComponentId] }
                    }]
                };
            } else {
                return {
                    state: epicState
                };
            }
        }
    });

export const
    widthUpdater = makeUpdater(ActionTypes.PROP_PANEL_CHANGE_COMPONENT_WIDTH, 'width'),
    heightUpdater = makeUpdater(ActionTypes.PROP_PANEL_CHANGE_COMPONENT_HEIGHT, 'height');
