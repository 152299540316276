import * as R from 'ramda';
import { getGlobalstyleClassNameForRadium } from "../../../RenderGlobalstyles/getGlobalstyleClassName";
import {
    cssConfig,
    fontCssConfig,
    highlightConfig,
    pseudoCssConfig
} from '../../../../mappers/block/index';
import * as shadowMapper from '../../../../mappers/shadow';
import * as mp from "../../../../mappers/path";
import makeSpec from "../../../../mappers/makeSpec/index";
import type { ButtonStylesheet } from '../../../Workspace/epics/stylesheets/flowTypes';
import { SpecConfig } from '../../../../mappers/makeSpec/flowTypes';

const
    MIN_BORDER_WIDTH = 0,
    MAX_BORDER_WIDTH = 10;

const
    buttonBlockCssConfig: SpecConfig = {
        ...cssConfig,
        textTransform: [mp.textTransform, R.identity],
        textAlign: [mp.horizontalAlign, R.identity],
        textDecoration: [mp.textUnderline, underline => (underline ? 'underline' : 'none')],
        textShadow: [mp.textShadow, shadowMapper.toCss]
    },
    buttonDDCssConfig: SpecConfig = {
        ...highlightConfig,
        fontSize: [mp.textSize, fontSize => `${R.clamp(9, 20)(fontSize)}px`],
        borderWidth: [mp.blockBorderWidth, (width) => width.map(
            w => `${R.clamp(MIN_BORDER_WIDTH, MAX_BORDER_WIDTH)(w)}px`
        ).join(' ')],
        textAlign: [mp.horizontalAlign, () => 'center']
    },
    makeButtonBlockSpec = makeSpec(buttonBlockCssConfig),
    makeButtonBlockPseudoSpec = makeSpec(pseudoCssConfig),

    makeTextSpecForDropdownOption = makeSpec({ ...buttonDDCssConfig, ...fontCssConfig }),
    makeTextSpecForDropdownHoverOption = makeSpec(buttonDDCssConfig),

    buttonBlockSpec = (cssPrefix: string = '') => ({
        [cssPrefix]: makeButtonBlockSpec(mp.inactive),
        [`${cssPrefix}:hover`]: makeButtonBlockPseudoSpec(mp.hover),
        [`${cssPrefix}:active`]: makeButtonBlockPseudoSpec(mp.press),
        [`${cssPrefix}.disabled`]: makeButtonBlockPseudoSpec(mp.disabled), // TODO: WBTGEN-17706
        [`${cssPrefix}${cssPrefix}-ddo`]: makeTextSpecForDropdownOption(mp.inactive),
        [`${cssPrefix}${cssPrefix}-ddo:hover`]: makeTextSpecForDropdownHoverOption(mp.hover)
    });

export default (stylesheet: ButtonStylesheet): Record<string, any> => R.applySpec(
    buttonBlockSpec(getGlobalstyleClassNameForRadium(stylesheet))
)(stylesheet);

export {
    buttonBlockCssConfig,
    makeButtonBlockSpec
};
