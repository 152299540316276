import type { StateHolder } from "../../flowTypes";

export default (stateHolder: StateHolder): StateHolder => {
    const { scope, state } = stateHolder;
    if (scope.isMoving) {
        return {
            scope: { ...scope, isMoving: false, actualPosition: state.position },
            state
        };
    }
    return stateHolder;
};
