import type { ComponentsMap } from "../../../../redux/modules/children/workspace/flowTypes";
import menuKind from "../../../oneweb/Menu/kind";

export const getTopMostMenuComponent = (
    componentsMap: ComponentsMap,
): undefined | Record<string, any> => {
    const topmostMenuComponentId = Object.keys(componentsMap)
        .filter(k => componentsMap[k].kind === menuKind && componentsMap[k].top >= 0)
        .sort((k1, k2) => componentsMap[k1].top - componentsMap[k2].top)[0];

    return componentsMap[topmostMenuComponentId];
};
