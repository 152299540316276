import { makeCombineReducer, makeDefaultStateReducers } from '../../../redux/makeReducer/index';
import * as ActionTypes from "../actionTypes";
import defaultState from '../defaultState';
import showColorPickerReducer from "./showColorPicker";
import updateColorReducer from "./updateColor";
import hideColorPickerReducer from "./hideColorPicker";
import browserDimensionsEpic from "../../App/epics/browserDimensions/index";
import { flowFriendlySpread } from '../../../utils/flowFriendlySpread';
import type { ColorPicker } from "../flowTypes";
import { THEME_COLOR_PICKER, THEME_BG_OVERRIDE_COLOR_PICKER, THEME_COLOR_OVERRIDE_COLOR_PICKER } from '../constants';

export default makeCombineReducer({
    combineReducers: {
        ...makeDefaultStateReducers({
            ...defaultState,
            selectedParentTheme: null,
            backgroundTheme: null,
            backgroundColor: null,
            textColor: null,
            themeColor: null,
            defaultThemeColor: null,
            tooltipTop: null,
        }),
        browserDimensions: browserDimensionsEpic.reducer
    },
    handleActions: {
        [ActionTypes.SHOW_COLOR_PICKER]: showColorPickerReducer,
        [ActionTypes.COLOR_CHANGE_COMPLETE]: updateColorReducer,
        [ActionTypes.HIDE_COLOR_PICKER]: hideColorPickerReducer,
        [ActionTypes.THEME_COLOR_OVERRIDE_CHANGE]: (state: ColorPicker, { payload }: any): ColorPicker => {
            const { themeOverrideColor } = payload;
            if (state.colorPickerKind === THEME_COLOR_PICKER) {
                return flowFriendlySpread((stateClone) => { stateClone.themeColor = themeOverrideColor.themeColor; }, state); // eslint-disable-line no-param-reassign
            } else if (state.colorPickerKind === THEME_BG_OVERRIDE_COLOR_PICKER) {
                return flowFriendlySpread((stateClone) => { stateClone.backgroundTheme = themeOverrideColor.backgroundTheme; }, state); // eslint-disable-line no-param-reassign
            } else if (state.colorPickerKind === THEME_COLOR_OVERRIDE_COLOR_PICKER) {
                return flowFriendlySpread((stateClone) => { stateClone.themeColor = themeOverrideColor.themeColor; }, state); // eslint-disable-line no-param-reassign
            }
            return state;
        },
    }
});
