export const
    SHAREBUTTONS_STYLE_CHANGED = 'SHAREBUTTONS_STYLE_CHANGED',
    SHAREBUTTONS_ALIGNMENT_CHANGED = 'SHAREBUTTONS_ALIGNMENT_CHANGED',
    SHAREBUTTONS_TOGGLE_FACEBOOK = 'SHAREBUTTONS_TOGGLE_FACEBOOK',
    SHAREBUTTONS_VERB_TO_DISPLAY_CHANGED = 'SHAREBUTTONS_VERB_TO_DISPLAY_CHANGED',
    SHAREBUTTONS_TOGGLE_FACEBOOK_SHOW_FRIENDS = 'SHAREBUTTONS_TOGGLE_FACEBOOK_SHOW_FRIENDS',
    SHAREBUTTONS_TOGGLE_FACEBOOK_SHARE = 'SHAREBUTTONS_TOGGLE_FACEBOOK_SHARE',
    SHAREBUTTONS_TOGGLE_GOOGLE_PLUS = 'SHAREBUTTONS_TOGGLE_GOOGLE_PLUS',
    SHAREBUTTONS_TOGGLE_LINKEDIN = 'SHAREBUTTONS_TOGGLE_LINKEDIN',
    SHAREBUTTONS_TOGGLE_TWITTER = 'SHAREBUTTONS_TOGGLE_TWITTER';
