/* eslint-disable max-len */
import React from 'react';

import VerticalSpacer from "../../../../../../../view/common/VerticalSpacer";

import { useIntl } from "../../../../../../../view/intl";

import styles from '../styles.css';

export const ReturnUserView = ({ onClickHandler }) => {
    const intl = useIntl();

    return (
        <div className={styles.returnUserViewContainer}>
            <div className={styles.returnUserIcon} />
            <VerticalSpacer y={16} />
            <div className={styles.returnUserContent}>
                {intl.msgJoint("msg: component.text.createAIText.dialog.returnUser.content {Tell us what you want to write about and get a customised text or }")}
            </div>
            <VerticalSpacer y={8} />
            <div
                className={styles.returnUserSubContent}
                onClick={onClickHandler}
            >
                {intl.msgJoint("msg: component.text.createAIText.dialog.returnUser.subContent {see previously created texts.}")}
            </div>
        </div>
    );
};
