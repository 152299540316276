import React from 'react';
import cx from 'classnames';
import styles from './index.css';
import factory from "../../presentational/Button/factory";
import injectIntl, { Intl } from "../../../view/intl/injectIntl";

type Props = {
    count: number,
    onClick: Function,
    selected?: boolean,
    intl: Intl
}

const ICON_MULTI_COLUMN_MARGIN = 2,
    ICON_SINGLE_COLUMN_PADDING = 4;

export default injectIntl(({ count, onClick, selected, intl, ...rest }: Props) => {
    let html: React.JSX.Element[] = [];
    for (let i = 0; i < count; i++) {
        const style = {
            marginRight: i + 1 < count ? ICON_MULTI_COLUMN_MARGIN : 0,
            paddingRight: count === 1 ? ICON_SINGLE_COLUMN_PADDING : 0,
            paddingLeft: count === 1 ? ICON_SINGLE_COLUMN_PADDING : 0
        };

        html.push(
            <div key={`grid${i}`} className={styles.column} style={style}>
                <div style={{ marginBottom: 2 }} />
                <div />
            </div>
        );
    }

    const buttonClassname = cx(styles.mctaButton, {
            [styles.selected]: selected
        }),
        MctaButton = factory({
            // @ts-ignore
            styles,
            containerType: 'div',
            containerExtraPropNames: [],
            additionalClassName: buttonClassname
        }),
        toolTipMsg: MsgJointInput = [
            'msg: common.columnsCount {{columnCount, plural, one {# column} other {# columns}}}',
            { columnCount: count }
        ];

    return (
        <MctaButton
            className={styles.columnGridContainer}
            onClick={onClick.bind(this, count)}
            title={intl.msgJoint(toolTipMsg)}
            {...rest}
        >
            {html}
        </MctaButton>
    );
});
