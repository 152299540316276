import React from 'react';
import { connect } from 'react-redux';

import { Box, FormControl, Typography } from "@mui/material";

import { Switch } from '../../../../Switch';

import { publishConfigDataSelector } from "../../../../../common/selectors";

import type { URLSettingsSectionProps } from "../types";
import type { AppState } from '../../../../../common/types';

export const URLSettingsSectionComponent = (props: URLSettingsSectionProps) => {
    return (
        <Box display="flex" alignItems="flex-start">
            <Box className={props.iconClassname} />
            <Box flex={1} marginLeft="18px">
                <Box
                    component="h2"
                    fontSize={{ xs: '18px', sm: '20px' }}
                    lineHeight={{ xs: '24px', sm: '26px' }}
                    fontWeight="400"
                >
                    {props.label}
                </Box>
                <Box
                    component="p"
                    fontSize={{ xs: '15px', sm: '14px' }}
                    lineHeight={{ xs: '24px', sm: '22px' }}
                    fontWeight="400"
                    marginTop={{ xs: '4px', md: '10px' }}
                >
                    {props.description}
                </Box>
                <Box
                    lineHeight={{ xs: '24px', sm: '17px' }}
                    fontSize={{ xs: '15px', sm: '14px' }}
                    marginTop={{ xs: '14px', md: '20px' }}
                >
                    <FormControl>
                        <Switch
                            height={24}
                            width={42}
                            checked={props.value}
                            handleChange={props.clickHandler}
                            labelPadding={10}
                            labelComponent={(
                                <Box style={{ display: "flex" }}>
                                    <Typography>
                                        {props.form.label}
                                    </Typography>
                                </Box>
                            )}
                        />
                    </FormControl>
                </Box>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state: AppState) => ({
    publishConfig: publishConfigDataSelector(state),
});

export const URLSettingsSection = connect(mapStateToProps)(URLSettingsSectionComponent);
