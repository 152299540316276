import React, { useRef, useEffect, useContext, useState } from "react";
import { useSelector } from "react-redux";
import Lottie from "react-web-lottie";

import animationData from "./animation.json";
import { DynamicTemplateContext } from "../Context";
import { getStepMarkers, LOTTIE_DEFAULT_OPTIONS } from "../../../view/LottieAnimations/constant";

import { WIZARD_STEP_ID } from "../../constants";
import { STEP_MARKERS } from "./constants";

import { animationDataSelector, gmbKeySelector } from "../../Epic/selectors";

const markers = animationData.markers;
const stepMarkers = getStepMarkers(markers, WIZARD_STEP_ID.GMB_SELECTION, STEP_MARKERS[WIZARD_STEP_ID.GMB_SELECTION]);

export const WebsiteCategoryAnimation = () => {
    const gmbKey = useSelector(gmbKeySelector);
    const { gmb: { key: animationGmbKey, isTyping }, isForward } = useSelector(animationDataSelector);
    const { isLoading } = useContext(DynamicTemplateContext);
    const animationRef = useRef(null);

    let initialStartPoint = stepMarkers.gmb_start_point;
    let initialStopPoint = stepMarkers.gmb_start_point + 1;
    if (!isForward) {
        initialStartPoint = stepMarkers.gmb_key_selected;
        initialStopPoint = stepMarkers.gmb_key_selected - 1;
    }

    const [startPoint, setStartPoint] = useState(initialStartPoint);
    const [stopPoint, setStopPoint] = useState(initialStopPoint);

    const initialSegment = [startPoint, stopPoint];

    const defaultOptions = {
        ...LOTTIE_DEFAULT_OPTIONS,
        loop: isLoading,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid meet"
        },
        initialSegment,
        animationData
    };

    useEffect(() => {
        if (isLoading) {
            if (stopPoint !== stepMarkers.concept_loading) {
                setStartPoint(stopPoint);
                setStopPoint(stepMarkers.concept_loading);
            }
        } else if (animationGmbKey && isTyping) {
            if (stopPoint !== stepMarkers.gmb_key_typing) {
                setStartPoint(stopPoint);
                setStopPoint(stepMarkers.gmb_key_typing);
            } else {
                setStartPoint(stepMarkers.gmb_key_typing - 1);
                setStopPoint(stepMarkers.gmb_key_typing);
            }
        } else if (animationGmbKey) {
            if (stopPoint !== stepMarkers.gmb_key_selected) {
                setStartPoint(stopPoint);
                setStopPoint(stepMarkers.gmb_key_selected);
            }
        } else if (!animationGmbKey) {
            if (stopPoint !== stepMarkers.gmb_start_point) {
                setStartPoint(stopPoint);
                setStopPoint(stepMarkers.gmb_start_point);
            }
        }
    }, [isLoading, gmbKey, animationGmbKey, isTyping, isForward]);

    useEffect(() => {
        // @ts-ignore
        animationRef.current.playSegments([[startPoint, stopPoint]], true);
    }, [startPoint, stopPoint]);

    return (
        <Lottie lottiRef={animationRef} options={defaultOptions} isClickToPauseDisabled />
    );
};
