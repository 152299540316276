import makeStyles from "@mui/styles/makeStyles";
import { MAX_CONTENT_WIDTH } from "../../../app/theme";

export const useStyles = makeStyles(
    (theme) => ({
        contentRoot: {
            backgroundColor: theme.palette.custom.colorGray_f7,
            [theme.breakpoints.up("sm")]: {
                padding: "48px",
                backgroundColor: theme.palette.custom.colorWhite
            }
        },
        content: {
            maxWidth: `${MAX_CONTENT_WIDTH}px`,
            margin: "auto"
        },
        section: {
            padding: "24px",
            backgroundColor: theme.palette.custom.colorWhite,
            [theme.breakpoints.up("sm")]: {
                padding: "0px"
            }
        },
        sectionTitle: {
            fontSize: "22px",
            lineHeight: "30px",
            fontWeight: 500,
            letterSpacing: "0.25px",
            userSelect: "none",
            [theme.breakpoints.up("sm")]: {
                fontSize: "24px",
                lineHeight: "30px",
                fontWeight: 400,
                letterSpacing: "0.15px"
            }
        },
        widgetsRow: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "stretch",
            gap: "8px",
            [theme.breakpoints.up("sm")]: {
                flexDirection: "row",
                gap: "24px",
                flexWrap: "wrap"
            }
        },
        widgetWrapper: {
            [theme.breakpoints.up("sm")]: {
                flex: 1,
            },

            "& > div:first-child:last-child": {
                height: "100%"
            }
        },
        widgetErrorRoot: {
            minHeight: "350px",
            border: `1px solid ${theme.palette.custom.colorGray_ce}`
        },
        borderlessWidgetErrorRoot: {
            minHeight: "350px",
        },
    }),
    { name: "Home" }
);
