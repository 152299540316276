import type { ImageComponent } from '../flowTypes';

const
    rotate = (component: ImageComponent, rotateBy: number): ImageComponent => {
        return ({
            ...component,
            rotation: (component.rotation + rotateBy) % 360,
            width: component.height
        });
    };

export const
    rotateRight = (component: ImageComponent): ImageComponent => rotate(component, 90),
    rotateLeft = (component: ImageComponent): ImageComponent => rotate(component, 270);
