import { makeForwardActionUpdater } from "../../../../epics/makeEpic";
import * as tinyMceActionTypes from "./actionTypes";
import type { EpicUpdater } from "../../../../epics/flowTypes";

type Prop = string | Array<string>;
type Props = {
    setColor: Prop;
    setShadowColor: Prop;
    setShadowBlurRadius: Prop;
    setShadowOffsetX: Prop;
    setShadowOffsetY: Prop;
    removeShadow: Prop;
    setLinkInContentBlock: Prop;
    setLinkAcrossContentBlocks: Prop;
    removeLink: Prop;
    toggleBold: Prop;
    toggleItalic: Prop;
    toggleUnderline: Prop;
    setHighlightColor: Prop;
    removeHighlightColor: Prop;
    setHorizontalAlignment: Prop;
    setFontFamily: Prop;
    setOrderedList: Prop;
    setUnorderedList: Prop;
    indent: Prop;
    outdent: Prop;
    setSubscript: Prop;
    setSuperscript: Prop;
    removeGlobalStylesAndFormats: Prop;
    setGlobalStyle: Prop;
    setLinkGlobalStyle: Prop;
    setCharacterSpacing: Prop;
    setLineSpacing: Prop;
    setFontSize: Prop;
    removeFormat: Prop;
    setParagraphSpacing: Prop;
};

const
    makeTinyMceForwardUpdatersFactory =
        (makeForwardActionUpdater: (arg0: Array<string> | string, arg1: string) => EpicUpdater<any, any, any, any>) => ({
            setColor,
            setShadowColor,
            setShadowBlurRadius,
            setShadowOffsetX,
            setShadowOffsetY,
            removeShadow,
            setLinkInContentBlock,
            setLinkAcrossContentBlocks,
            removeLink,
            toggleBold,
            toggleItalic,
            toggleUnderline,
            setHighlightColor,
            removeHighlightColor,
            setHorizontalAlignment,
            setFontFamily,
            setOrderedList,
            setUnorderedList,
            indent,
            outdent,
            setSubscript,
            setSuperscript,
            removeGlobalStylesAndFormats,
            setGlobalStyle,
            setLinkGlobalStyle,
            setCharacterSpacing,
            setLineSpacing,
            setFontSize,
            removeFormat,
            setParagraphSpacing
        }: Props) => [
            makeForwardActionUpdater(setColor, tinyMceActionTypes.TINY_MCE_SET_TEXT_COLOR),
            makeForwardActionUpdater(setShadowColor, tinyMceActionTypes.TINY_MCE_SET_SHADOW_COLOR),
            makeForwardActionUpdater(setShadowBlurRadius, tinyMceActionTypes.TINY_MCE_SET_SHADOW_BLUR_RADIUS),
            makeForwardActionUpdater(setShadowOffsetX, tinyMceActionTypes.TINY_MCE_SET_SHADOW_OFFSET_X),
            makeForwardActionUpdater(setShadowOffsetY, tinyMceActionTypes.TINY_MCE_SET_SHADOW_OFFSET_Y),
            makeForwardActionUpdater(removeShadow, tinyMceActionTypes.TINY_MCE_REMOVE_SHADOW),
            makeForwardActionUpdater(setLinkInContentBlock, tinyMceActionTypes.TINY_MCE_SET_LINK_IN_CONTENT_BLOCK),
            makeForwardActionUpdater(
                setLinkAcrossContentBlocks, tinyMceActionTypes.TINY_MCE_SET_LINK_ACROSS_CONTENT_BLOCKS
            ),
            makeForwardActionUpdater(removeLink, tinyMceActionTypes.TINY_MCE_REMOVE_LINK),
            makeForwardActionUpdater(toggleBold, tinyMceActionTypes.TINY_MCE_TOGGLE_BOLD),
            makeForwardActionUpdater(toggleItalic, tinyMceActionTypes.TINY_MCE_TOGGLE_ITALIC),
            makeForwardActionUpdater(toggleUnderline, tinyMceActionTypes.TINY_MCE_TOGGLE_UNDERLINE),
            makeForwardActionUpdater(setHighlightColor, tinyMceActionTypes.TINY_MCE_SET_HIGHLIGHT_COLOR),
            makeForwardActionUpdater(removeHighlightColor, tinyMceActionTypes.TINY_MCE_REMOVE_HIGHLIGHT_COLOR),
            makeForwardActionUpdater(setHorizontalAlignment, tinyMceActionTypes.TINY_MCE_SET_HORIZONTAL_ALIGNMENT),
            makeForwardActionUpdater(setFontFamily, tinyMceActionTypes.TINY_MCE_SET_FONT_FAMILY),
            makeForwardActionUpdater(setOrderedList, tinyMceActionTypes.TINY_MCE_TOGGLE_ORDERED_LIST),
            makeForwardActionUpdater(setUnorderedList, tinyMceActionTypes.TINY_MCE_TOGGLE_UNORDERED_LIST),
            makeForwardActionUpdater(indent, tinyMceActionTypes.TINY_MCE_INDENT),
            makeForwardActionUpdater(outdent, tinyMceActionTypes.TINY_MCE_OUTDENT),
            makeForwardActionUpdater(setSubscript, tinyMceActionTypes.TINY_MCE_SET_SUBSCRIPT),
            makeForwardActionUpdater(setSuperscript, tinyMceActionTypes.TINY_MCE_SET_SUPERSCRIPT),
            makeForwardActionUpdater(
                removeGlobalStylesAndFormats, tinyMceActionTypes.TINY_MCE_REMOVE_GLOBAL_STYLES_AND_FORMATS
            ),
            makeForwardActionUpdater(setGlobalStyle, tinyMceActionTypes.TINY_MCE_SET_GLOBAL_STYLE),
            makeForwardActionUpdater(setLinkGlobalStyle, tinyMceActionTypes.TINY_MCE_SET_LINK_GLOBAL_STYLE),
            makeForwardActionUpdater(setCharacterSpacing, tinyMceActionTypes.TINY_MCE_SET_CHARACTER_SPACING),
            makeForwardActionUpdater(setLineSpacing, tinyMceActionTypes.TINY_MCE_SET_LINE_SPACING),
            makeForwardActionUpdater(setFontSize, tinyMceActionTypes.TINY_MCE_SET_FONT_SIZE),
            makeForwardActionUpdater(removeFormat, tinyMceActionTypes.TINY_MCE_REMOVE_FORMAT),
            makeForwardActionUpdater(setParagraphSpacing, tinyMceActionTypes.TINY_MCE_SET_PARAGRAPH_SPACING)
        ],
    makeTinyMceForwardUpdaters = makeTinyMceForwardUpdatersFactory(makeForwardActionUpdater);

export {
    makeTinyMceForwardUpdaters,
    makeTinyMceForwardUpdatersFactory
};
