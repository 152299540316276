import React from "react";
import { connect } from "react-redux";
import getCenteredDialog from "../../../../DialogManager/getCenteredDialogConfig";
import { injectIntl, Intl } from "../../../../../view/intl/index";
import styles from './FacebookChatMultiplePagesDialog.css';
import { StripTypes } from "../../../../../view/common/dialogs/baseDialog/index";
import { DialogHeight, DialogWidth, DialogMessages } from "./constants";
import { closeDialog } from "../../../../App/actionCreators/index";
import { StandardDialog } from "../../../../../view/common/dialogs/StandardDialog/index";
import type { AppState } from "../../../../../redux/modules/flowTypes";
import { socialAccountsFacebookChatManagedPages, socialAccountsFacebookChatSelectedPageValue } from "../selectors";
import ComboBox from "../../../../../view/common/Combobox/index";
import PropertyContainer from "../../../../../view/common/PropertyContainer/index";
import {
    unSetFacebookChatAccessTokenAction,
    exchangeForFBChatLongAccessTokenAction,
    setFbChatPageValue
} from "../actions";
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import type { FacebookChatPage } from "../types";
import { openChatWidgetInSiteSettings } from "../actionSelector";
import { appNameSelector } from "../../../../../../../src/dashboard/src/common/selectors";

type Props = {
    dispatch: Dispatch,
    intl: Intl,
    managedFbPages: Array<FacebookChatPage>,
    selectedPageValue: string,
    appName: string,
}

// eslint-disable-next-line max-len
class FacebookChatMultiplePagesDialog extends React.Component<Props> {
    render() {
        const { props: { dispatch, intl, managedFbPages, selectedPageValue, appName } } = this;

        const pageOptions = managedFbPages.map((pageData) => {
            return {
                value: pageData.access_token,
                label: pageData.name
            };
        });

        return (
            <StandardDialog
                title={DialogMessages.TITLE}
                autoFocus={false}
                stripType={StripTypes.NONE}
                mctaText={DialogMessages.RECONNECT_LABEL}
                sctaText={DialogMessages.CANCEL_LABEL}
                titleClassName={styles.multiPagesTitleClassname}
                bodyClassName={styles.bodyClassName}
                sctaBtnClass={styles.cancelBtn}
                primaryBtnClass={styles.primaryBtnClass}
                mctaHandler={
                    () => {
                        dispatch(exchangeForFBChatLongAccessTokenAction(selectedPageValue));
                        dispatch(closeDialog());
                        openChatWidgetInSiteSettings(dispatch, true, !appName);
                    }
                }
                noTopBorderForFooter
                disabled={false}
                sctaHandler={
                    () => {
                        dispatch(unSetFacebookChatAccessTokenAction());
                        dispatch(closeDialog());
                    }
                }
            >
                <VerticalSpacer y={30} />
                <div className={styles.message}>{intl.msgJoint(DialogMessages.MESSAGE)}</div>
                <VerticalSpacer y={30} />
                <PropertyContainer className={styles.pagesListContainer}>
                    <ComboBox
                        searchable={false}
                        value={selectedPageValue}
                        options={pageOptions}
                        onChange={
                            ({ value }) => {
                                dispatch(setFbChatPageValue(value));
                            }
                        }
                    />
                </PropertyContainer>
            </StandardDialog>
        );
    }
}

const mapStateToProps = (appState: AppState) => ({
    managedFbPages: socialAccountsFacebookChatManagedPages(appState),
    selectedPageValue: socialAccountsFacebookChatSelectedPageValue(appState),
    // @ts-ignore This selector should not be there in dashboard as AppState are different for dashboard and editor
    appName: appNameSelector(appState),
});

export default getCenteredDialog({
    width: DialogWidth,
    height: DialogHeight,
    component: connect(mapStateToProps)(injectIntl(FacebookChatMultiplePagesDialog)),
});
