import u from 'updeep';
import {
    makeCombineReducer,
    makeDefaultStateReducers,
} from "../../../../redux/makeReducer";
import type { SectionLinkFormReducerActionTypes, SectionLinkFormReducer } from "../flowTypes";
import defaultState from "./sectionLinkDefaultState";

export default (actions: SectionLinkFormReducerActionTypes): SectionLinkFormReducer => makeCombineReducer({
    combineReducers: {
        ...makeDefaultStateReducers(defaultState),
    },
    handleActions: {
        [actions.NAME_CHANGE]: (state, action) => {
            const
                { payload } = action,
                name = payload,
                isInvalidName = !name;
            return u({ name, isInvalidName }, state);
        },
        [actions.SECTION_CHANGE]: (state, action) => {
            const
                { payload } = action,
                sectionId = payload;
            return u({ sectionId }, state);
        },
        [actions.PAGE_CHANGE]: (state, action) => {
            const
                { payload } = action,
                pageId = payload,
                sectionId = state.pageId === pageId ? state.sectionId : null;
            return u({ pageId, sectionId }, state);
        },
        [actions.HIDDEN_TOGGLE]: (state) => {
            const
                hidden = !state.hidden;
            return u({ hidden }, state);
        },
        [actions.RESET]: (state) => {
            return u({ name: '', isInvalidName: true, pageId: null, sectionId: null }, state);
        },
    }
});
