import React from "react";
import styles from './PropertiesPanelSection.css';
import type { MsgJointInput, Intl } from "../../../../../view/intl/index";
import { injectIntl } from "../../../../../view/intl/index";
import { PropertiesPanelSection } from "./PropertiesPanelSection";

type Props = {
    title?: MsgJointInput,
    containerClassName?: string,
    children: MsgJointInput,
    intl: Intl,
};

export const PropertiesPanelTextSection = injectIntl(
    ({ title, containerClassName, children, intl }: Props) => (
        <PropertiesPanelSection
            title={title}
            contentClassName={styles.textContent}
            containerClassName={containerClassName}
        >
            {intl.msgJoint(children)}
        </PropertiesPanelSection>
    ),
);
