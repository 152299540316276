import { TINY_MCE_PUT_CURSOR_AT_THE_END_OF_EDITOR } from "../actionTypes";
import { putCursorAtTheEndOfEditor } from "../editorUtils/methods/helpers/selection";
import { queryEditor } from "./helpers/queryEditor";

import type { TinyMceEpicUpdater } from "../flowTypes";

const onPutCursorAtTheEndOfEditorUpdater: TinyMceEpicUpdater = {
    conditions: [TINY_MCE_PUT_CURSOR_AT_THE_END_OF_EDITOR],
    reducer: ({ state, scope }) => {
        queryEditor(editor => putCursorAtTheEndOfEditor(editor));

        return {
            state,
            scope,
        };
    }
};

export {
    onPutCursorAtTheEndOfEditorUpdater
};
