import React from "react";
import { useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import MuiTooltip from "@mui/material/Tooltip";
import { useIntl } from "../../../../../../wbtgen/src/view/intl";
import { BookingsTrialTooltip } from "./BookingsTrialTooltip";
import { preferencesSelector } from "../../../common/selectors";

const useStyles = makeStyles(
    theme => ({
        arrow: {
            color: "#E6F2FA",
        },
        tooltip: {
            backgroundColor: "#E6F2FA",
            color: "#3C3C3C",
            maxWidth: "382px",
            fontSize: "24px", // this is arrow size
            [theme.breakpoints.down('sm')]: {
                display: "none"
            },
        },
        itemIcon: {
            backgroundColor: "#D9EBF7",
            borderRadius: "4px",
            padding: "0 8px",
            fontSize: "12px",
            fontWeight: "400",
            lineHeight: "18px",
            letterSpacing: "0.4px",
            position: "relative"
        },
    }),
    { name: "RightSideIcon" }
);

export const RightSideIcon = () => {
    const classes = useStyles();
    const intl = useIntl();
    const { hideBookingsTrialTooltip } = useSelector(preferencesSelector);

    return (
        <MuiTooltip
            classes={classes}
            title={<BookingsTrialTooltip />}
            placement="right"
            arrow
            open={!hideBookingsTrialTooltip}
        >
            <div className={classes.itemIcon}>
                {intl.msgJoint("msg: common.beta {Beta}")}
            </div>
        </MuiTooltip>
    );
};
