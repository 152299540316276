import makeEpic from '../../../../epics/makeEpic';
import { LOCALE_CHANGED } from '../../../presentational/LocaleList/actionTypes';
import valueActionType from './valueActionType';
import { LinkedInAPI, TwitterAPI } from '../constants';
import * as windowActions from '../../../../redux/middleware/window/actionTypes';

export default makeEpic({
    defaultState: null,
    valueActionType,
    updaters: [{
        conditions: [LOCALE_CHANGED],
        reducer: ({ state }) => {
            const
                removeScript: AnyAction = {
                    type: windowActions.WINDOW_REMOVE_SCRIPT_TAGS,
                    payload: [LinkedInAPI, TwitterAPI]
                },
                removeGlobalVar: AnyAction = {
                    type: windowActions.WINDOW_DELETE_GLOBAL_OBJECTS,
                    payload: ['IN']
                };

            return { state, multipleActionsToDispatch: [removeScript, removeGlobalVar] };
        }
    }]
});
