import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { ErrorModel, Props } from "./ErrorModel";

import { openDialog } from "../../../../App/actionCreators";
import { failedAttemptSelector } from "../../Epic/selectors";
import { CONTINUE_TO_TEMPLATES_DIALOG } from "./ContinueToTemplatesDialog";
import { DYNAMIC_TEMPLATE_RESET_ERROR_TYPE } from "../../Epic/actionTypes";

import styles from "./ErrorModel.css";

export const ConnectionError = () => {
    const dispatch = useDispatch();
    const failedAttempt = useSelector(failedAttemptSelector);
    const showBrowseTemplates = failedAttempt.count > 2;

    let props: Props = {
        imageClass: styles.connectionErrorIcon,
        title: "msg: component.text.createAIText.dialog.connectionError.content {Connection error}",
        description: "msg: onboarding.dynamic.connectionError.subTitle {Unable to connect to OpenAI at the moment.}",
        primaryButtonText: "msg: common.tryAgain {Try again}",
        primaryButtonAction: () => {
            dispatch({ type: DYNAMIC_TEMPLATE_RESET_ERROR_TYPE });
            if (failedAttempt.action) {
                dispatch(failedAttempt.action);
            }
        },
        secondaryButtonText: "msg: common.back {Back}",
        secondaryButtonAction: () => {
            dispatch({ type: DYNAMIC_TEMPLATE_RESET_ERROR_TYPE });
        }
    };

    if (showBrowseTemplates) {
        props = {
            ...props,
            // eslint-disable-next-line max-len
            subDescription: "msg: onboarding.dynamic.error.description {Please leave this page open to keep your progress and try again later. You can also create your website with one of our templates instead.}",
            secondaryButtonText: "msg: common.browseTemplates {Browse templates}",
            secondaryButtonAction: () => {
                dispatch(openDialog(CONTINUE_TO_TEMPLATES_DIALOG));
            }
        };
    }

    return (
        <ErrorModel {...props} />
    );
};
