import * as R from 'ramda';
import React from "react";
import { pure } from 'recompose';
import openLinkChooserDialog from '../../../../view/common/dialogs/LinkChooserDialog/actionCreators/openLinkChooserDialog'; // eslint-disable-line max-len
import * as path from '../../../../mappers/path';
import FontSizeSelector from '../../../presentational/FontSize/index';
import GlobalstyleSelector from '../../../presentational/GlobalstyleSelector/index';
import { getAllStylesheets, getFirstButtonStyleId } from '../../../Workspace/epics/stylesheets/selectors';
import { MctaButton, MctaInput } from '../../../componentMainActions/index';
import * as selectors from '../selectors';
import { getButtonLocalFontSize } from "../utils";
import * as actionTypes from "../actionTypes";
import optionMapper from '../globalStyle/options';
import ButtonGlobalstyleKind from '../globalStyle/kind';
import ButtonKind from '../kind';
import mctaStyles from '../../../componentMainActions/MctaButton/styles.css';
import customStyles from './styles.css';
import HorizontalSpacer from '../../../../view/common/HorizontalSpacer';
import MctaPropertyContainer from '../../../componentMainActions/MctaPropertyContainer/index';
import { DEFAULT_MCTA_COMBOBOX_MENUSTYLE, MCTA_THEME_OPTIONS_BUTTON } from '../../../componentMainActions/constants';
import { optionRenderer } from "../globalStyle/optionRenderer";
import { themeColoroptionRenderer } from "../globalStyle/themeColoroptionRenderer";
import {
    default as AlignmentControls,
    StatefulAlignmentControls
} from '../../../componentMainActions/MctaAlignmentControls/alignmentControls';
import { MOBILE_MCTA_ALIGN_MOUSE_ENTER, MOBILE_MCTA_ALIGN_MOUSE_LEAVE,
    MOBILE_EDITOR_ALIGNMENT_CHANGE } from '../../../MobileViewEditor/actionTypes';
import type { MCTA_PROPS } from '../../../MobileViewEditor/flowTypes';
import type { ButtonComponent } from "../flowTypes";

const
    MCTAView = pure((props) => {
        const {
                selectedComponent,
                globalstyles,
                stylesheetsIdToNameMap,
                intl,
                dispatch,
                themeSettingsData,
                children,
                isModernLayoutActivated,
                isInsideHeaderOrFooter
            } = props,
            { buttonThemeSelected } = selectedComponent,
            { autoColorMode } = themeSettingsData,
            styles = getAllStylesheets(globalstyles),
            globalStyleId = R.path(path.styleGlobalId, selectedComponent),
            buttonText = selectors.getButtonText(selectedComponent),
            fontSize = getButtonLocalFontSize(selectedComponent, globalstyles),
            linkAction = selectedComponent.linkAction,
            linkTitle = linkAction ? "msg: common.changeLink {Change link}" : "msg: common.addLink {Add link}",
            selectedParentTheme = props.selectedComponentProps.selectedParentTheme;

        let
            optRenderer,
            onChangeAction,
            options,
            colorThemeOnChangeAction,
            selected,
            selectedKind = ButtonGlobalstyleKind,
            currentStylesheetId;

        if (autoColorMode) {
            currentStylesheetId = getFirstButtonStyleId(globalstyles);

            const btnName = stylesheetsIdToNameMap[currentStylesheetId];
            optRenderer = (value, label) => themeColoroptionRenderer(value, label, selectedParentTheme, btnName);

            options = MCTA_THEME_OPTIONS_BUTTON;
            onChangeAction = actionTypes.BUTTON_THEME_SELECTED;
            // TODO: WBTGEN-17574
            colorThemeOnChangeAction = (value) => {
                dispatch({ type: onChangeAction, payload: value });
            };

            selected = buttonThemeSelected;
        } else {
            optRenderer = (value, label) => optionRenderer(value, label, stylesheetsIdToNameMap);
            onChangeAction = actionTypes.BUTTON_GLOBAL_STYLE_UPDATED;
            options = optionMapper(styles, intl);
            selected = globalStyleId;
        }

        return (
            <div>
                <HorizontalSpacer x={5} />
                <MctaInput
                    label="msg: component.button.tb.text {Text}"
                    placeholder="msg: component.button.buttonText {Button text}"
                    value={buttonText}
                    inputStyle={{ width: 150 }}
                    onChange={text => dispatch({ type: actionTypes.BUTTON_MCTA_CHANGE_TEXT, payload: text })}
                    focusOnLoad
                />
                <HorizontalSpacer x={5} />
                <MctaButton
                    className={selectedComponent.linkAction ? mctaStyles.mctaLinkButtonWithLinkSet : null}
                    title={linkTitle}
                    icon="LINK"
                    onClick={() => dispatch(
                        openLinkChooserDialog({ setLinkAction: actionTypes.BUTTON_SET_LINK, input: linkAction })
                    )}
                />
                <HorizontalSpacer x={5} />
                <GlobalstyleSelector
                    dropDownClassName={customStyles.dropdownOption}
                    selected={selected}
                    selectedKind={selectedKind}
                    onChangeAction={onChangeAction}
                    forwardToSelectedComponent
                    options={options}
                    optionRenderer={optRenderer}
                    dispatch={dispatch}
                    style={{ width: 185 }}
                    menuStyle={DEFAULT_MCTA_COMBOBOX_MENUSTYLE}
                    mcta
                    showEditLink
                    colorThemeOnChangeAction={colorThemeOnChangeAction}
                    currentStylesheetId={currentStylesheetId}
                />
                <HorizontalSpacer x={13} />
                {isModernLayoutActivated && isInsideHeaderOrFooter ?
                    <StatefulAlignmentControls
                        label="msg: common.sizeColon {Size:}"
                        actionTypeToDispatch={actionTypes.BUTTON_MCTA_CHANGE_SIZE}
                        size={selectedComponent.modernLayoutOptions && selectedComponent.modernLayoutOptions.size}
                        dispatch={dispatch}
                        kind={ButtonKind}
                    />
                    : <React.Fragment>
                        <MctaPropertyContainer label="msg: component.button.mcta.label {Font size:}">
                            <FontSizeSelector
                                onChangeAction={actionTypes.BUTTON_MCTA_CHANGE_FONT_SIZE}
                                selected={fontSize}
                                dispatch={dispatch}
                                searchable={false}
                                menuStyle={DEFAULT_MCTA_COMBOBOX_MENUSTYLE}
                                mcta
                            />
                        </MctaPropertyContainer>
                        {children && <HorizontalSpacer />}
                        {children}
                    </React.Fragment>}
            </div>
        );
    });

type PropTypes = {
    dispatch: Dispatch,
    mobileMCTA: MCTA_PROPS,
    selectedComponent: ButtonComponent
};

const
    MCTAMobileView = (props: PropTypes) => {
        const { dispatch, mobileMCTA, selectedComponent: { id, mobileSettings: { align } } } = props;
        const { mctaHorizontalAlignControlExpanded, availableSpace } = mobileMCTA;
        return (
            <div>
                <AlignmentControls
                    actionTypeToDispatch={MOBILE_EDITOR_ALIGNMENT_CHANGE}
                    selectedCmpId={id}
                    availableSpace={availableSpace}
                    onMouseEnterCollapsedViewActionType={MOBILE_MCTA_ALIGN_MOUSE_ENTER}
                    onMouseLeaveExpandedViewActionType={MOBILE_MCTA_ALIGN_MOUSE_LEAVE}
                    alignment={align}
                    expanded={mctaHorizontalAlignControlExpanded}
                    dispatch={dispatch}
                    kind={ButtonKind}
                />
            </div>
        );
    };

export default {
    editView: MCTAView,
    mobileViewEditorV: MCTAMobileView,
    editButtonText: 'msg: component.button.editButton {Edit button}'
};
