import React from "react";
import { pure } from 'recompose';
import { Flex } from "../../../view/reflexbox/index";
import * as styles from './TopBar.css';

export const Separator = pure(() => (
    <Flex align="center" className={styles.separator}>
        <div className={styles.line} />
    </Flex>
));
