import * as R from 'ramda';
import React from 'react';
import cx from 'classnames';
import { getRenderContent } from '../utils/getRenderContent';
import { THEME_TEXT_CLASS } from '../../../ThemeGlobalData/constants';
import * as styles from './styles.css';
import { DataSite } from '../../../../../dal/model/index';
import type { TextComponent } from '../flowTypes';
import { replaceTagsWithContentDOM } from "./replaceTagsWithContentDOM";
import type {
    ThemeSiteSettingsDataType,
    ThemeColorDataType,
    ThemeColorTypes,
    ThemeBackgroundType
} from "../../../ThemeGlobalData/flowTypes";
import { makeThemeHighlightAndShadowStyles } from './makeThemeHighlightAndShadowStyles';

type Props = {
    component: TextComponent,
    style: Object,
    globalStyles: { styles: Object },
    siteMap: DataSite,
    previewBackupTime?: number,
    renderedWrappedComponentContentsMap?: {
        [componentId: string]: string
    },
    globalVariables: Object,
    mobileStyles: Object,
    selectedParentTheme: ThemeBackgroundType,
    themeOverrideColor: ThemeColorTypes,
    themeSettingsData: ThemeSiteSettingsDataType,
    themeColorsData: ThemeColorDataType,
    themeHighlightColor: ThemeColorTypes,
    themeShadowBlurRadius: number,
    themeShadowColor: ThemeColorTypes,
    themeShadowOffsetX: number,
    themeShadowOffsetY: number,
};

const
    renderWrappedComponentsIntoTextComponent = (textComponentContent, renderedWrappedComponentContentsMap) =>
        R.keys(renderedWrappedComponentContentsMap).reduce((textComponentContent, id) => {
            const split = textComponentContent.split(id);
            // This regex handles the br tag that gets inserted if selection contains wrapped component,
            // but it wont work if anything else gets inserted
            split[1] = split[1].replace(/>.*?<\//, `>${renderedWrappedComponentContentsMap[id]}</`);    //NOSONAR

            return split.join(id);
        }, textComponentContent);

export default ({
    component,
    style,
    renderedWrappedComponentContentsMap,
    siteMap,
    previewBackupTime,
    globalStyles: { styles: globalStyles },
    globalVariables,
    selectedParentTheme,
    themeSettingsData,
    themeColorsData,
    themeOverrideColor,
    themeHighlightColor,
    themeShadowBlurRadius,
    themeShadowColor,
    themeShadowOffsetX,
    themeShadowOffsetY,
}: Props) => {
    const { autoColorMode } = themeSettingsData;
    const renderContent = getRenderContent(
        component.content,
        // @ts-ignore
        globalStyles,
        siteMap,
        previewBackupTime,
    );
    const mobileFont = component.mobileSettings && component.mobileSettings.font;
    let html = renderedWrappedComponentContentsMap ?
        renderWrappedComponentsIntoTextComponent(
            renderContent,
            renderedWrappedComponentContentsMap
        ) : renderContent;
    html = replaceTagsWithContentDOM(html, { globalVariables, site: siteMap }, false);

    const contentDiv = (extraStyle = {}) => <div
        data-mve-font-change={mobileFont}
        style={{
            ...extraStyle,
            ...style,
            ...(autoColorMode ? makeThemeHighlightAndShadowStyles(
                themeColorsData, themeHighlightColor, themeShadowBlurRadius, themeShadowColor, themeShadowOffsetX, themeShadowOffsetY
            ) : {}),
        }}
        className={cx([
            styles.contentContainer,
            'textnormal',
            styles.text,
            autoColorMode && styles.invalidateHighlightAndShadows,
            autoColorMode && selectedParentTheme,
            autoColorMode && THEME_TEXT_CLASS,
            autoColorMode && themeOverrideColor,
        ])}
        dangerouslySetInnerHTML={{ __html: html }}
    />;

    switch (component.verticalAlignment) {
        case 'middle':
        case 'bottom':
            return <div
                className={styles.verticalAlignmentWrapper}
                style={{
                    alignItems: component.verticalAlignment === 'middle' ? 'center' : 'flex-end',
                }}
            >
                {contentDiv({ width: '100%' })}
            </div>;

        default:
            return contentDiv();
    }
};
