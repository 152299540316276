import kind from '../kind';
import {
    makeComponentBaseReducers,
    makeDefaultStateReducers,
} from "../../../../redux/makeReducer/index";
import { reducerDefaultData } from './reducerDefaultData';

export const combineReducers = {
    ...makeComponentBaseReducers(kind),
    ...makeDefaultStateReducers({
        mobileHide: false,
        mobileDown: false,
        ...reducerDefaultData,
        products: ''
    })
};
