import { openDialog, replaceDialog } from "../../../../App/actionCreators/index";
import * as dialogIds from "../../dialogIds";
import { SubscriptionData } from "../../../../../redux/modules/flowTypes";

const OpenUpgradePlanDialog = (subscriptionData: SubscriptionData, source?: string) => {
    return openDialog(dialogIds.UpgradePlansDialogId, {
        subscriptionData,
        source
    });
};

export const replaceDialogWithUpgradePlanDialog = (subscriptionData: SubscriptionData, source: string) => (
    replaceDialog(dialogIds.UpgradePlansDialogId, {
        subscriptionData,
        source
    })
);

export default OpenUpgradePlanDialog;
