
import React from 'react';
import styles from '../TemplateProperitesPanel.css';
import stylesPropertyContainer from '../../../../../view/common/PropertyContainer/PropertyContainer.css';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import * as pagesIds from "./ids";
import pagesTitles from "./titles";
import type { TemplatePageViewProps } from '../flowTypes';
import NavigationGroup from "../../../../PropertiesPanel/view/IntlNavigationGroup";
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';
import * as Actions from "../../actionTypes";
import InputField from '../../../../../view/common/Input/PropertiesInputField';
import { SCROLL } from "../../constants";
import Msg from '../../../../../view/intl/Msg';
import CheckBox from '../../../../../view/common/CheckBox/PropertiesCheckBox';
import ComboBox from '../../../../../view/common/Combobox/index';
import {
    openTemplatePagesDialogAction,
    templateTitleChangeAction,
    templateVerticalScrollToggleAction
} from "../../actionCreators";
import { LinkButtonCom } from "../../../../presentational/LinkButton/LinkButton";
import {
    openTextGlobalStylesDialogAction,
} from "../../../../presentational/GlobalstyleDialogInvoker/index";
import { openPageTemplateSwitchAction } from "../../../../PagesTree/PageTemplateSwitch/actions";
import { TooltipPosition } from '../../../../presentational/Tooltip/constants';
import { DemoTip } from '../../../../../../demo/modules/tip/DemoTip';
import { LanguageViewTitle } from '../../../../PropertiesPanel/view/Language/constants';
import { LOCALE_CHANGED } from '../../../../presentational/LocaleList/actionTypes';
import { getLocaleList } from '../../../../presentational/LocaleList/utils';
import { SCROLL_EFFECTS } from "../../../commonView/ScrollEffects/constants";
import ScrollTitleInPPNavigation from "../../../commonView/ScrollEffects/ScrollTitleInPPNavigation";

const
    id = pagesIds.MAIN,
    title = pagesTitles[id],
    view = ({ selectedComponent, navigateToPage, dispatch, isRepositoryDomain }: TemplatePageViewProps) => {
        const
            { name, verticalScroll, pages, locale } = selectedComponent,
            navigationTitles = Object
                .keys(pagesTitles)
                .reduce((acc, t) => (
                    t === pagesTitles.CATEGORIES
                        ? acc
                        : { ...acc, [t]: pagesTitles[t] }
                ), {}),
            navigationPageIds = [pagesIds.BACKGROUND, SCROLL_EFFECTS];

        return (
            <Page>
                { isRepositoryDomain && <div>
                    <VerticalSpacer />
                    <PropertyContainer label={LanguageViewTitle}>
                        <ComboBox
                            searchable={false}
                            value={locale}
                            options={getLocaleList()}
                            onChange={({ value: locale }) => dispatch({ type: LOCALE_CHANGED, payload: locale })}
                        />
                    </PropertyContainer>
                    <VerticalSpacer />
                    <LinkButtonCom onClick={() => dispatch({ type: Actions.TEMPLATE_PP_EDIT_LOCALIZATION_PRESSED })}>
                        {'msg: template.editI18n {Edit i18n}'}
                    </LinkButtonCom>
                </div> }
                <VerticalSpacer />
                <PropertyContainer
                    label="msg: template.title {Template title:}"
                    className={stylesPropertyContainer.pointer}
                >
                    <InputField
                        value={name}
                        onChange={text => dispatch(templateTitleChangeAction(text))}
                    />
                </PropertyContainer>
                <CheckBox
                    label="msg: template.verticalScrollbar {Always show vertical scrollbar}"
                    isChecked={verticalScroll === SCROLL}
                    onClick={() => { dispatch(templateVerticalScrollToggleAction()); }}
                />
                <VerticalSpacer />
                <div>
                    <Msg k="template.templateUsedBy">Template used by:</Msg>
                    &nbsp;
                    <Msg
                        className={styles.templatePagesLink}
                        onClick={() => dispatch(openTemplatePagesDialogAction())}
                        k="template.pages"
                        params={{ numPages: pages.length }}
                    >
                        {`{numPages, number} pages`}
                    </Msg>
                </div>
                <VerticalSpacer />
                {/* @ts-ignore */}
                <DemoTip position={TooltipPosition.BOTTOM}>
                    <LinkButtonCom
                        onClick={() => dispatch(openPageTemplateSwitchAction({
                            selectedTemplateId: selectedComponent.id
                        }))}
                    >
                        {`msg: template.pp.changeTemplateForThisPage {Change template for this page}`}
                    </LinkButtonCom>
                </DemoTip>
                <VerticalSpacer />
                <LinkButtonCom onClick={() => dispatch(openTextGlobalStylesDialogAction())}>
                    {`msg: template.pp.editTemplateStyles {Edit template styles}`}
                </LinkButtonCom>
                <VerticalSpacer />
                <NavigationGroup
                    navigateToPage={navigateToPage}
                    pagesTitles={navigationTitles}
                    pageTitleEltsInNavigation={ScrollTitleInPPNavigation}
                    targetPagesIds={navigationPageIds}
                />
            </Page>
        );
    };

export { id, title, view };
