import * as R from "ramda";
import p from "../../../../utils/pipePath";
import { setToPath } from "../../../../utils/ramdaEx";
import * as mp from "../../../../mappers/path";
import { setCells, setCommonCellsData } from "./setAllCells";
import { cellSpacingValidator } from "../validators";
import type { TableComponent, TableComponentDependsOn } from "../flowTypes";

const spacingPath = p(mp.style, mp.block, mp.padding);

export const setCellSpacingReducer =
    (component: TableComponent, { payload: spacing }: any, depedencies: TableComponentDependsOn) => {
        if (cellSpacingValidator(spacing)) {
            const
                { tableEditModeState: { selectedCellsIndexes } } = depedencies,
                setCellSpacing = (cell, commonCellsData) => {
                    if (cell.style) {
                        return setToPath(spacingPath, spacing, cell);
                    } else if (commonCellsData) {
                        return setToPath(spacingPath, spacing, { ...cell, ...commonCellsData });
                    } else {
                        return cell;
                    }
                };

            return R.pipe(
                (component) => setCells({ cellUpdater: setCellSpacing, selectedCellsIndexes }, component),
                (component) => setCommonCellsData({ cellUpdater: setCellSpacing, selectedCellsIndexes }, component)
            )(component);
        }

        return component;
    };
