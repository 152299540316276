import type { MsgJointInput } from "../../../../view/intl";
import { openPageTemplateSelectorErrorDialog } from "../dialogs/errorDialog/actions";
import { sendShownErrorEventToAecAC } from "../../../App/actionCreators/sendEventToAecActionCreator";

export const makePageTemplateSelectorErrorDialogUpdater = (action: string, msg: MsgJointInput, errorMsg: string) =>
    ({
        conditions: [action],
        reducer: ({ state }: Record<string, any>) => ({
            state,
            multipleActionsToDispatch: [
                openPageTemplateSelectorErrorDialog({ msg }),
                sendShownErrorEventToAecAC(errorMsg)
            ]
        })
    });
