import type { SiteSettingsEpicState } from "../../App/epics/siteSettings/flowTypes";
import type { MobileHeaderState } from "../../Mobile/header/types";
import { siteSettingsMobileSel, siteSettingsGeneralDataSel } from "../../App/epics/siteSettings/selectorActionTypes";
import { Lit } from "../../../lit";
import { MveHeaderTitleType } from "./constants";
import {
    backgroundMobileStyleFromSiteSettingsSel,
    titleMobileStyleFromSiteSettingsSel,
    menuMobileStyleFromSiteSettingsSel,
} from "../../Mobile/style/selectors";
import type { ComponentsMap } from "../../../redux/modules/children/workspace/flowTypes";
import type { Stylesheets } from '../../Workspace/epics/stylesheets/flowTypes';

type Params = {
    siteSettings: SiteSettingsEpicState,
    componentsMap: ComponentsMap,
    stylesheet: Stylesheets,
};

export const getMveHeaderStateFromSiteSettings = (
    { siteSettings, componentsMap, stylesheet }: Params,
): MobileHeaderState => {
    if (siteSettings.current.themeSettingsData.autoColorMode) {
        return {
            autoColorMode: true,
            // @ts-ignore: stylesheet.theme.colors won't be undefined when autoColorMode is on
            themeColorsData: stylesheet.theme?.colors,
            [Lit.logo]: {
                [Lit.show]: siteSettingsMobileSel([Lit.logo, Lit.show], false)(siteSettings),
                [Lit.asset]: siteSettingsGeneralDataSel([Lit.logoAsset])(siteSettings),
            },
            [Lit.title]: {
                [Lit.show]: siteSettingsMobileSel([Lit.title, Lit.show], true)(siteSettings),
                [Lit.type]: siteSettingsMobileSel([Lit.title, Lit.type], MveHeaderTitleType.PAGE)(siteSettings),
                [Lit.value]: siteSettingsGeneralDataSel([Lit.websiteTitle])(siteSettings),
                [Lit.style]: titleMobileStyleFromSiteSettingsSel({ siteSettings, componentsMap, stylesheet }),
            },
            [Lit.useCustomStyle]: siteSettingsMobileSel([Lit.useCustomStyle], false)(siteSettings),
            [Lit.pin]: siteSettingsMobileSel([Lit.pin], 0)(siteSettings),

            // @ts-ignore
            [Lit.menu]: menuMobileStyleFromSiteSettingsSel({ siteSettings, componentsMap, stylesheet }),

            // @ts-ignore
            [Lit.background]: {
                [Lit.useColor]: siteSettingsMobileSel([Lit.background, Lit.useColor], true)(siteSettings),
                ...backgroundMobileStyleFromSiteSettingsSel(siteSettings),
            },
        };
    } else {
        return {

            autoColorMode: false,
            [Lit.logo]: {
                [Lit.show]: siteSettingsMobileSel([Lit.logo, Lit.show], false)(siteSettings),
                [Lit.asset]: siteSettingsGeneralDataSel([Lit.logoAsset])(siteSettings),
            },
            [Lit.title]: {
                [Lit.show]: siteSettingsMobileSel([Lit.title, Lit.show], true)(siteSettings),
                [Lit.type]: siteSettingsMobileSel([Lit.title, Lit.type], MveHeaderTitleType.PAGE)(siteSettings),
                [Lit.value]: siteSettingsGeneralDataSel([Lit.websiteTitle])(siteSettings),
                [Lit.style]: titleMobileStyleFromSiteSettingsSel({ siteSettings, componentsMap, stylesheet }),
            },
            [Lit.useCustomStyle]: siteSettingsMobileSel([Lit.useCustomStyle], false)(siteSettings),
            [Lit.pin]: siteSettingsMobileSel([Lit.pin], 0)(siteSettings),

            // @ts-ignore
            [Lit.menu]: menuMobileStyleFromSiteSettingsSel({ siteSettings, componentsMap, stylesheet }),

            // @ts-ignore
            [Lit.background]: {
                [Lit.useColor]: siteSettingsMobileSel([Lit.background, Lit.useColor], true)(siteSettings),
                ...backgroundMobileStyleFromSiteSettingsSel(siteSettings),
            },
        };
    }
};
