import React from "react";
import cx from "classnames";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
    (theme) => ({
        icon: {
            width: "72px",
            height: "72px",
            backgroundSize: "72px",

            [theme.breakpoints.up("sm")]: {
                width: "48px",
                height: "48px",
                backgroundSize: "48px",
            },
        }
    }),
    { name: "WidgetIllustration" }
);

export const WidgetIllustration = ({ iconClass }: { iconClass: string }) => {
    const classes = useStyles();

    return <div className={cx(iconClass, classes.icon)} />;
};

