import { SocialColorSourceType } from './constants';
import type {
    SocialGlobalData,
    SocialComponent,
    SocialCalcRenderProps,
    SocialCalcRenderPropsResult,
    SocialCalcRenderPropsLinkResult
} from './flowTypes';
import type { ThemeColorDataType, ThemeSiteSettingsDataType, ThemeBackgroundType } from '../../ThemeGlobalData/flowTypes';
import { getThemeRulesForBackground } from '../../ThemeGlobalData/themeRules';
import { BACKGROUND_THEME_WHITE, WHITE_THEME_COLOR } from '../../ThemeGlobalData/constants';

type ComputeSizeAndLinksCommonProps = { component: SocialComponent; socialData: SocialGlobalData; };
type ComputeSizeAndLinksPropsWithAutoColor = ComputeSizeAndLinksCommonProps & {
    themeSettingsData: ThemeSiteSettingsDataType;
    themeColorsData: ThemeColorDataType;
    selectedParentTheme: ThemeBackgroundType;
};
type ComputeSizeAndLinksProps = ComputeSizeAndLinksCommonProps | ComputeSizeAndLinksPropsWithAutoColor;

const computeSizeAndLinks = (props: ComputeSizeAndLinksProps) => {
    const { component, socialData } = props;
    const { styleType, themeColorType, links: componentLinks, size } = component;
    const { links: globalSocialLinks } = socialData;

    let colorType = component.colorType;
    // @ts-ignore
    if (props.themeSettingsData && props.themeSettingsData.autoColorMode) {
        if (themeColorType === SocialColorSourceType.MULTI_COLOR) {
            colorType = themeColorType;
        } else {
            colorType = (
                // @ts-ignore
                WHITE_THEME_COLOR === getThemeRulesForBackground(props.selectedParentTheme, props.themeColorsData).text
                    ? SocialColorSourceType.LIGHT
                    : SocialColorSourceType.DARK
            );
        }
    }

    return {
        size,
        links: componentLinks
            .filter(({ id, hidden }) => !hidden && globalSocialLinks.find(globalLink => globalLink.id === id))
            .map/* ::<SocialCalcRenderPropsLinkResult> */(({ id, kind }) => ({
                kind,
                url: (globalSocialLinks.find(({ id: globalLinkId }) => globalLinkId === id) || {}).url,
                styleType,
                colorType
            } as SocialCalcRenderPropsLinkResult))
    };
};

const calProps = ({
    socialData, component, isWorkspace, themeColorsData, themeSettingsData, selectedParentTheme,
}: {
    socialData: SocialGlobalData,
    component: SocialComponent,
    isWorkspace?: boolean,
    themeColorsData: ThemeColorDataType,
    themeSettingsData: ThemeSiteSettingsDataType,
    selectedParentTheme: ThemeBackgroundType,
}) => {
    if (themeSettingsData.autoColorMode) {
        return ({
            ...computeSizeAndLinks({
                component,
                socialData,
                themeColorsData,
                themeSettingsData,
                selectedParentTheme,
            }),
            isWorkspace,
            themeColorsData,
            themeSettingsData,
        } as SocialCalcRenderPropsResult);
    }
    return ({
        ...computeSizeAndLinks({
            component,
            socialData
        }),
        isWorkspace,
        themeColorsData,
        themeSettingsData,
    } as SocialCalcRenderPropsResult);
};

const socialCalcRenderProps: SocialCalcRenderProps = props => {
    const {
        componentDependencies: { socialData, themeColorsData, themeSettingsData },
        component,
        isWorkspace,
        selectedParentTheme,
    } = props;

    return calProps({
        socialData,
        component,
        isWorkspace,
        themeColorsData,
        themeSettingsData,
        selectedParentTheme: selectedParentTheme || BACKGROUND_THEME_WHITE,
    });
};

export {
    calProps,
    computeSizeAndLinks,
    socialCalcRenderProps
};
