// @ts-nocheck
/* globals $R */

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const GMBConnectionStatus = function () {
    const domain = $R.store.getState().preferences.owner;
    const url = `/api/v1/${domain}/gmb/getConnectionStatus`;

    const [data, setData] = useState({
        status: 'not-initiated'
    });

    useEffect(() => {
        async function fetchData() {
            try {
                let response = await axios.get(url);
                setData({
                    status: 'loaded',
                    connected: response.data.connected
                });
            } catch (e: any) {
                setData({
                    status: 'error'
                });
            }
        }

        fetchData();
    }, [url]);

    return (
        <div>
            {
                data.status === 'not-initialized' &&
                <div>Loading ...</div>
            }
            {
                data.status === 'loaded' &&
                <div>
                    {data.connected ? 'Connected' : 'Not connected'}
                </div>
            }
            {
                data.status === 'error' &&
                <div>Error</div>
            }
        </div>
    );
};

export {
    GMBConnectionStatus
};
