import { makeApplyEditorChangesReducer } from "./helpers/helpers";
import { makeRemoveFormatter } from "../editorUtils/methods/helpers/makeRemoveFormatter";
import { TINY_MCE_REMOVE_HIGHLIGHT_COLOR } from "../actionTypes";

const onRemoveHightlightColorUpdater = {
    conditions: [TINY_MCE_REMOVE_HIGHLIGHT_COLOR],
    reducer: makeApplyEditorChangesReducer(
        ({ state, scope, editor }) => {
            // TODO: FixGlobalStyles might needs to be called here, this remove is not followed by apply
            makeRemoveFormatter(editor)('hilitecolor');

            return {
                state: {
                    ...state,
                    highLightColor: null
                },
                scope
            };
        }
    )
};

export {
    onRemoveHightlightColorUpdater
};
