import { GOOGLE_TAG_MANAGER_EVENT } from "../../../redux/middleware/window/actionTypes";

type GoogleTagManagerEvent = {
    event: string;
    eventCategory: string;
    eventAction?: string;
    eventLabel?: string;
    eventValue?: any;
    trialId?: string;
};

export const sendGTMEvent = (payload: GoogleTagManagerEvent) => ({
    type: GOOGLE_TAG_MANAGER_EVENT,
    payload
});
