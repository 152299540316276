import CreateListingDialog from './view/index';
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import { getCenterPositionForDialog } from "../../../../DialogManager/utility";

const HEIGHT = 802,
    WIDTH = 1218,
    createListingDialog = {
        ...getCenteredDialogConfig(WIDTH, HEIGHT, CreateListingDialog),
        confFactory: ({ browserWidth, browserHeight }) => {
            return {
                position: getCenterPositionForDialog(WIDTH, HEIGHT, browserWidth, browserHeight),
                modal: true,
                dimensions: { width: WIDTH, height: HEIGHT }
            };
        },
        moveToCenter: true,
    };

export {
    createListingDialog
};
