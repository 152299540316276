import React from 'react';
import cx from 'classnames';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import * as pagesIds from '../pagesIds';
import pagesTitles from '../pagesTitles';
import type { Props } from '../flowTypes';
import injectIntl from '../../../../../view/intl/injectIntl';
import styles from "./styles.css";
import { openImageChooserAction } from '../../../../FileManager/imageChooser/actions';
// import openDialogAC from '../../../../App/actionCreators/openDialog';
// import connectFacebookFeedDialogId from '../../configurationDialog/ciCookieBannerMigrationDialogId';
// import Msg from "../../../../../view/intl/Msg";
import * as actionTypes from "../../actionTypes";
import { getImageSrc } from "../../../Image/utils";
import LazyImage from '../../../../../view/common/LazyImage/index';
import { PROFILE_IMAGE_HOLDER_SIZE } from '../../constants/index';

const dispatchImageChooseAction = (dispatch) => {
    dispatch(
        openImageChooserAction({
            onSaveAction: actionTypes.FACEBOOK_FEED_GALLERY_PP_PROFILE_IMAGE_CHANGE,
            isMultiSelect: false,
            forwardToComponent: true,
        })
    );
};

const
    id = pagesIds.PROFILE_IMAGE,
    title = pagesTitles[id],
    view = injectIntl(({
        selectedComponent,
        intl,
        dispatch
    }: Props) => {
        const
            { profileImageAsset } = selectedComponent,
            profileImageMsg = "msg: component.facebookFeed.pp.profileImage.info {This is shown when posts without images are displayed on your site}", // eslint-disable-line max-len
            { src } = getImageSrc(profileImageAsset, PROFILE_IMAGE_HOLDER_SIZE, PROFILE_IMAGE_HOLDER_SIZE),
            classNames = [styles.profileImgAddRemoveIcon, profileImageAsset ? styles.profileImgMinus : styles.profileImgPlus];
        return (
            <Page>
                <VerticalSpacer />
                <div
                    className={styles.profileImgIcon}
                    onClick={() => dispatchImageChooseAction(dispatch)}
                >
                    {profileImageAsset && src && <LazyImage
                        src={src}
                        shouldLoad
                        showTransparency
                        className={cx(styles.profileImgIcon, styles.profileImgAsset)}
                        doNotUseDataUriForSafari
                    />}
                    <div
                        className={cx(...classNames)}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (!profileImageAsset) {
                                dispatchImageChooseAction(dispatch);
                            }
                            dispatch({ type: actionTypes.FACEBOOK_FEED_GALLERY_PP_PROFILE_IMAGE_CLEAR });
                        }}
                    />
                </div>
                <VerticalSpacer />
                <div className={styles.profileImgDesc}>
                    {intl.msgJoint(profileImageMsg)}
                </div>
                <VerticalSpacer />
            </Page>
        );
    });

export { id, view, title };
