/* eslint-disable react/jsx-fragments */
/* eslint-disable max-len */

import { textLikeComponentFactory } from '../factories/textLikeComponentFactory';
import { PHONE_KIND } from './kind';
import { phoneComponentView } from "./phoneComponentView";
import { computeComponentDependenciesForPreviewForPhone } from "./computeComponentDependenciesForPreviewForPhone";
import { getPlaceholderTextForPhone } from "./getPlaceholderTextForPhone";

const {
    mappers: { to: phoneComponentMapperTo, back: phoneComponentMapperBack },
    epic: phoneComponentEpic,
    previewConfig: phonePreviewConfig
} = textLikeComponentFactory({
    View: phoneComponentView,
    kind: PHONE_KIND,
    computeComponentDependenciesForPreview: computeComponentDependenciesForPreviewForPhone,
    getPlaceholderText: getPlaceholderTextForPhone
});

export {
    phoneComponentMapperTo,
    phoneComponentMapperBack,
    phoneComponentEpic,
    phonePreviewConfig,
};

