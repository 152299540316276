import makeEpic from '../../../../epics/makeEpic';
import { UNDO, REDO } from "../../../../epics/undoManager/actionTypes";
import * as componentsEvalActions from "../../../Workspace/epics/componentsEval/actionTypes";
import * as workspaceActions from "../../../Workspace/actionTypes";
import registry from '../../../../view/oneweb/registry/index';
import { COMPONENT_CONFIGURATION_CANCELED } from '../../../../redux/modules/children/workspace/actionTypes';
import { DndAddComponentVisible, DndAddComponentComponentKind } from '../../../DndAddComponent/epic/selectorActionTypes'; // eslint-disable-line max-len
import valueActionType from './valueActionType';
import { ReceiveOnlyComponentsMap } from "../../../Workspace/epics/componentsEval/selectorActionTypes";
import subscriptionDataVAT from '../../../App/epics/subscriptionData/valueActionType';
import { shopPageIdsListVat } from "../../../oneweb/WebShop/epics/shopPageIdsList/valueActionType";
import { siteSettingsValueActionType } from "../../../App/epics/siteSettings/valueActionType";

const
    disabledComponentKinds = { },
    actionsToCheck = [
        workspaceActions.WORKSPACE_NEW_COMPONENT_ADDED,
        workspaceActions.DELETE_SELECTED_COMPONENTS,
        workspaceActions.DELETE_SELECTED_SECTION,
        componentsEvalActions.COMPONENTS_PASTED,
        componentsEvalActions.COMPONENTS_DUPLICATED,
        COMPONENT_CONFIGURATION_CANCELED,
        UNDO,
        REDO
    ],
    getComponentFromRegistry = componentKind => registry[componentKind],
    getComponentErrorOnAdd = (componentRegistry, componentsMap, subscriptionData, shopPageIds, siteSettings) => {
        const { validateComponentAdd } = componentRegistry;
        return validateComponentAdd({ componentsMap, subscriptionData, shopPageIds, siteSettings }); // eslint-disable-line max-len
    },
    getDisabledComponentShortcutTitle = ({ errorMsgsOnAdd }, error) => errorMsgsOnAdd[error].titleForDisabledShortCut,
    getComponentKindsToDisable = (componentsMap, subscriptionData, siteSettings, shopPageIds) => {
        const componentsKindsToDisableMap = {};

        Object.keys(registry).forEach(componentKind => {
            const
                componentRegistry = getComponentFromRegistry(componentKind),
                error = getComponentErrorOnAdd(componentRegistry, componentsMap, subscriptionData, shopPageIds, siteSettings);

            if (error) {
                componentsKindsToDisableMap[componentKind] = getDisabledComponentShortcutTitle(componentRegistry, error); // eslint-disable-line max-len
            }
        });

        return { ...disabledComponentKinds, ...componentsKindsToDisableMap };
    };

export default makeEpic({
    defaultState: {},
    valueActionType,
    updaters: [
        {
            conditions: [
                subscriptionDataVAT,
                componentsEvalActions.COMPONENTS_MAP_INITIALIZED,
                siteSettingsValueActionType,
                shopPageIdsListVat
            ],
            reducer: ({ values: [subscriptionData, { componentsMap }, siteSettings, shopPageIds] }) =>
                ({ state: getComponentKindsToDisable(componentsMap, subscriptionData, siteSettings, shopPageIds) })
        },
        {
            conditions: [
                subscriptionDataVAT,
                DndAddComponentVisible,
                DndAddComponentComponentKind,
                ReceiveOnlyComponentsMap,
                siteSettingsValueActionType,
                shopPageIdsListVat
            ],
            reducer: ({ values: [subscriptionData, isVisible, componentKind, componentsMap, siteSettings, shopPageIds] }) => {
                const
                    componentsKindsToDisableMap = getComponentKindsToDisable(
                        componentsMap,
                        subscriptionData,
                        siteSettings,
                        shopPageIds
                    ),
                    componentRegistry = getComponentFromRegistry(componentKind);

                if (isVisible && componentRegistry) {
                    componentsKindsToDisableMap[componentKind] = componentKind;
                }

                return { state: componentsKindsToDisableMap };
            }
        }, /**/
        ...actionsToCheck.map(action => ({
            conditions: [
                action,
                subscriptionDataVAT,
                ReceiveOnlyComponentsMap,
                siteSettingsValueActionType,
                shopPageIdsListVat
            ],
            reducer: ({ values: [, subscriptionData, componentsMap, siteSettings, shopPageIds] }) => {
                const componentsKindsToDisableMap = getComponentKindsToDisable(
                    componentsMap,
                    subscriptionData,
                    siteSettings,
                    shopPageIds
                );
                return { state: componentsKindsToDisableMap };
            }
        }))
    ]
});
