import * as R from 'ramda';
import p from '../../../../utils/pipePath';
import maybe from '../../../../utils/maybe';
import * as mp from "../../../../mappers/path";
import { alphaToOpacity } from "../../../oneweb/Background/utils/index";
import * as backgroundMapper from "../../../../mappers/background/index";
import TextGlobalStyleType from '../../../oneweb/Text/globalStyle/kind';
import TableGlobalStyleType from '../../../oneweb/Table/globalStyle/kind';
import ButtonGlobalStyleType from '../../../oneweb/Button/globalStyle/kind';
import { types as textTypes, globalStyleTextClass } from '../../../Globalstyles/Text/constants';
import { types as tableTypes } from '../../../Globalstyles/Table/constants';
import getGlobalstyleClassName from '../../../RenderGlobalstyles/getGlobalstyleClassName';
import type { Stylesheet, Stylesheets } from "./flowTypes";
import stylesheetVAT from './valueActionType';
import { epicStateAppSel } from "../../../../epics/selectors";
import linkGlobalStyleKind from '../../../oneweb/Link/globalStyle/kind';
import { isMediumLight } from "../../../../mappers/color";
import { withSelector, receiveOnly } from '../../../../epics/makeCondition';
import type { ThemeColorDataType } from "../../../ThemeGlobalData/flowTypes";
import { memoMaxOne } from "../../../../../utils/memo";

export const
    getStyleId = R.path(['id']),
    getStyleType = R.prop('type'),
    getAllStylesheets = R.path(['styles']),
    getStylesByType = (type: string) => R.filter(style => style.type === type),
    getButtonStyles = R.filter(style => style.type === ButtonGlobalStyleType),
    getStyleByRef = (ref: string) => R.pipe(R.filter(style => style.ref === ref), R.head),
    getFirstStylesheetByType = (type: string) => R.pipe(
        getAllStylesheets,
        getStylesByType(type),
        R.head
    ),
    getStyleById = (id: string): ((s: Stylesheet[]) => Stylesheet) => R.pipe(R.filter(style => style.id === id), R.head),
    getFirstStylesheetIdByType = (type: string) => R.pipe(getFirstStylesheetByType(type), getStyleId),
    getNextStyleIndex = (type: string) => R.pipe(
        getAllStylesheets,
        getStylesByType(type),
        styles => {
            let nextIndex = 1;
            const indexes = styles.reduce(
                (indexArray, style) => ([...indexArray, R.pipe(R.prop('index'), parseInt)(style)]), []
            );

            while (indexes.indexOf(nextIndex) > -1) {
                nextIndex = nextIndex + 1;
            }

            return nextIndex;
        }
    ),
    getFontFamily = (rootKey?: string) => R.path(p(rootKey, mp.textFont)),
    getFontBold = (rootKey?: string) => R.path(p(rootKey, mp.textBold)),
    getFontItalic = (rootKey?: string) => R.path(p(rootKey, mp.textItalic)),
    getFontUnderline = (rootKey?: string) => R.path(p(rootKey, mp.textUnderline)),
    getFontSize = (rootKey?: string) => R.path(p(rootKey, mp.textSize)),
    getFontShadowColor = (rootKey?: string) => R.path(p(rootKey, mp.text, mp.shadowColor)),
    getFontShadowBlurRadius = (rootKey?: string) => R.path(p(rootKey, mp.text, mp.shadowBlur)),
    getFontShadowHorizontalOffset = (rootKey?: string) => R.path(p(rootKey, mp.text, mp.shadowHorizontalOffset)),
    getFontShadowVerticalOffset = (rootKey?: string) => R.path(p(rootKey, mp.text, mp.shadowVerticalOffset)),
    getBorderWidth = (rootKey?: string) => R.path(p(rootKey, mp.blockBorderWidth)),
    getBorderRadius = (rootKey: string) => R.path(p(rootKey, mp.blockBorderRadius)),
    getBorderStyle = (rootKey?: string) => R.path(p(rootKey, mp.blockBorderStyle)),
    getBorderColor = (rootKey?: string) => R.path(p(rootKey, mp.blockBorderColor)),
    getBorderColorOpacity = (rootKey?: string) => (data: any) => {
        const color = getBorderColor(rootKey)(data);
        return color ? color[4] : undefined;
    },
    getFontColor = (rootKey?: string) => R.path(p(rootKey, mp.textColor)),
    getFontHightlightColor = (rootKey?: string) => R.path(p(rootKey, mp.text, mp.highlight)),

    getTextAlignment = (rootKey: string) => R.path(p(rootKey, mp.horizontalAlign)),
    getTextTransform = (rootKey: string) => R.path(p(rootKey, mp.textTransform)),
    getBlockPadding = (rootKey: string) => R.path(p(rootKey, mp.blockPadding)),

    getBackgroundColor = (rootKey: Array<string>) => R.path(p(rootKey, mp.blockBackgroundColor)),
    getBackgroundColorOpacity = (rootKey: Array<string>) => (data: AnyValue): number => {
        const color = R.path(p(rootKey, mp.blockBackgroundColor))(data),
            gradient = R.path(p(rootKey, mp.blockBackgroundGradient))(data);

        if (color) return color[4];
        else if (gradient && gradient.color) return gradient.color[4];
        else return 0;
    },
    getBackgroundColorAlpha = (rootKey: Array<string>) =>
        R.pipe(R.path(p(rootKey, mp.blockBackgroundColor, 4)), alphaToOpacity),
    getBackgroundGradient = (rootKey: Array<string>) => R.path(p(rootKey, mp.blockBackgroundGradient)),
    getBackgroundGradientColor = (rootKey: Array<string>) => R.path(p(rootKey, mp.blockBackgroundGradient, mp.color)),
    getBackgroundGradientDirection = (rootKey: Array<string>) =>
        R.path(p(rootKey, mp.blockBackgroundGradient, mp.degree)),
    getBackgroundGradientFadePoint = (rootKey: Array<string>) =>
        R.path(p(rootKey, mp.blockBackgroundGradient, mp.fadePoint)),
    getBackgroundOpacity = (rootKey: Array<string>, stylesheet: Stylesheet) =>
        // @ts-ignore
        alphaToOpacity(backgroundMapper.toAlpha(R.path(p(rootKey, mp.blockBackground), stylesheet)) || 0),
    getBackgroundAsset = (rootKey: Array<string>) => R.path(p(rootKey, mp.blockBackgroundAsset)),
    getBackgroundPosition = (rootKey: Array<string>) => R.path(p(rootKey, mp.blockBackgroundPosition)),
    getBackgroundRepeat = (rootKey: Array<string>) => R.path(p(rootKey, mp.blockBackgroundRepeat)),
    getBackgroundOverlay = (rootKey: Array<string>) => R.path(p(rootKey, mp.blockBackgroundOverlay)),
    getBackgroundSize = (rootKey: Array<string>) => R.path(p(rootKey, mp.blockBackgroundSize)),

    indexByIdSelector = (id: string) => R.pipe(getAllStylesheets, maybe(R.findIndex(R.propEq('id', id)))),
    stylesheetByIdSelector = (id: string | null | undefined) => R.pipe(
        getAllStylesheets,
        R.filter((stylesheet: Stylesheet) => id === R.prop('id')(stylesheet)),
        R.head
    ),

    // Additional selectors for menu divider style
    menuTreeTextIndent = R.path([mp.accordionTextIndent]),
    menuDropdownWidth = R.path([mp.cascadeWidth]),

    menuDividerBackgroundColor = R.path(p(mp.divider, mp.backgroundColor)),
    menuDividerBackgroundGradient = R.path(p(mp.divider, mp.backgroundGradient)),
    menuDividerBackgroundOpacity = (stylesheet: Stylesheet) => {
        const
            color = R.path(p(mp.divider, mp.backgroundColor), stylesheet),
            gradient = R.path(p(mp.divider, mp.backgroundGradient), stylesheet);

        if (gradient) {
            return alphaToOpacity(gradient.color[4]);
        } else if (color) {
            return alphaToOpacity(color[4]);
        }

        return undefined;
    },

    menuDividerBackgroundAsset = R.path(p(mp.divider, mp.backgroundAsset)),
    menuDividerBackgroundRepeat = R.path(p(mp.divider, mp.backgroundRepeat)),
    menuDividerBackgroundPosition = R.path(p(mp.divider, mp.backgroundPosition)),
    menuDividerBackgroundSize = R.path(p(mp.divider, mp.backgroundSize)),

    menuDividerBorderColor = R.path(p(mp.divider, mp.borderColor)),
    menuDividerBorderStyle = R.path(p(mp.divider, mp.borderStyle)),
    menuDividerBorderWidth = R.path(p(mp.divider, mp.borderWidth)),
    textNormalGlobalstyle = R.pipe(
        getAllStylesheets,
        R.defaultTo([]),
        getStylesByType(TextGlobalStyleType),
        getStyleByRef(textTypes.NORMAL)
    ),
    textNormalGlobalstyleId = R.pipe(textNormalGlobalstyle, getStyleId),
    textNormalClassName = R.pipe(textNormalGlobalstyleId, getGlobalstyleClassName),
    textNormalFontSize = R.pipe(textNormalGlobalstyle, R.path(['size']), R.defaultTo(1)),
    textNormalColor = R.pipe(textNormalGlobalstyle, R.path(['color'])),
    textNormalBold = R.pipe(textNormalGlobalstyle, R.path(['bold']), R.defaultTo(false)),
    textNormalItalic = R.pipe(textNormalGlobalstyle, R.path(['italic']), R.defaultTo(false)),
    textNormalFont = R.pipe(textNormalGlobalstyle, R.path(['font'])),

    textHeadingXGlobalstyle = (x: string) => R.pipe(
        getAllStylesheets,
        R.defaultTo([]),
        getStylesByType(TextGlobalStyleType),
        getStyleByRef(x),
        R.defaultTo(null)
    ),
    linkXGlobalstyle = (x: string) => R.pipe(
        getAllStylesheets,
        R.defaultTo([]),
        getStylesByType(linkGlobalStyleKind),
        getStyleByRef(x),
        R.defaultTo(null)
    ),
    textHeading1Globalstyle = textHeadingXGlobalstyle(textTypes.HEADING1),
    textHeading1FontSize = R.pipe(textHeading1Globalstyle, R.path(['size']), R.defaultTo(1)),
    textHeading2Globalstyle = textHeadingXGlobalstyle(textTypes.HEADING2),
    textLogoGlobalstyle = textHeadingXGlobalstyle(textTypes.LOGO),
    textHeading2GlobalstyleGlobalstyleId = R.pipe(textHeading2Globalstyle, getStyleId),
    textHeading2GlobalstyleClassName = R.pipe(textHeading2GlobalstyleGlobalstyleId, getGlobalstyleClassName),

    textHeading3Globalstyle = textHeadingXGlobalstyle(textTypes.HEADING3),
    textHeading3GlobalstyleGlobalstyleId = R.pipe(textHeading3Globalstyle, getStyleId),
    link1GlobalStyle = linkXGlobalstyle('link.1'),
    link1Id = R.pipe(link1GlobalStyle, getStyleId, R.defaultTo(null)),
    textHeading3GlobalstyleClassName = R.pipe(textHeading3GlobalstyleGlobalstyleId, getGlobalstyleClassName),

    tableNormalGlobalstyle = R.pipe(
        getAllStylesheets,
        getStylesByType(TableGlobalStyleType),
        getStyleByRef(tableTypes.NORMAL)
    ),
    tableNormalGlobalstyleId = R.pipe(tableNormalGlobalstyle, R.prop(mp.id)),
    getTextColor = (x: string) => R.pipe(
        getAllStylesheets,
        getStylesByType(TextGlobalStyleType),
        getStyleByRef(x),
        R.path([mp.color])
    ),
    getParagraphTextColor = (stylesheet: Stylesheets) => getTextColor(textTypes.NORMAL)(stylesheet),
    getTextColors = (stylesheet: Stylesheet, textStyles: Array<string>): Array<string> => {
        return textStyles.map((textStyle: string) => {
            let textType;
            if (textStyle === globalStyleTextClass[textTypes.NORMAL]) {
                textType = textTypes.NORMAL;
            } else if (textStyle === globalStyleTextClass[textTypes.HEADING1]) {
                textType = textTypes.HEADING1;
            } else if (textStyle === globalStyleTextClass[textTypes.HEADING2]) {
                textType = textTypes.HEADING2;
            } else {
                textType = textTypes.HEADING3;
            }
            return getTextColor(textType)(stylesheet);
        });
    },
    getTextColorOnPriority = (stylesheet: Stylesheet, textStyles: Array<string>) => {
        let textType;
        if (textStyles.includes(globalStyleTextClass[textTypes.NORMAL])) {
            textType = textTypes.NORMAL;
        } else if (textStyles.includes(globalStyleTextClass[textTypes.HEADING1])) {
            textType = textTypes.HEADING1;
        } else if (textStyles.includes(globalStyleTextClass[textTypes.HEADING2])) {
            textType = textTypes.HEADING2;
        } else {
            textType = textTypes.HEADING3;
        }
        return getTextColor(textType)(stylesheet);
    },
    getTextContrast = (color: any) => {
        return isMediumLight(color) ? 'light' : 'dark';
    },
    getAllLinkStyles = R.pipe(
        getAllStylesheets,
        getStylesByType(linkGlobalStyleKind)
    ),
    getFirstLinkStyle = R.pipe(
        getAllStylesheets,
        getStylesByType(linkGlobalStyleKind),
        R.head,
        R.defaultTo(null)
    ),
    getAllButtonStyles = R.pipe(
        getAllStylesheets,
        getStylesByType(ButtonGlobalStyleType)
    ),
    getFirstButtonStyle = R.pipe(
        getFirstStylesheetByType(ButtonGlobalStyleType)
    ),
    getFirstButtonStyleId = R.pipe(
        getFirstStylesheetIdByType(ButtonGlobalStyleType)
    ),
    getInactiveLinkFontColor = R.path(mp.inactiveLinkTextFontColor),
    getInactiveButtonFontColor = R.path(mp.inactiveButtonTextFontColor),
    getInactiveBackgroundColor = R.path(p(mp.inactive, mp.blockBackgroundColor)),
    getButtonStyleById = (id: string) => R.pipe(
        getAllStylesheets,
        getStylesByType(ButtonGlobalStyleType),
        getStyleById(id),
    ),
    getAllStylesheetsByType = (type: string) => R.pipe(
        getAllStylesheets,
        getStylesByType(type),
    );

export const
    stylesheetAppSel = epicStateAppSel(stylesheetVAT);

const emptyObj = {}; // Note: Need to use the same object reference (emptyObj), otherwise it may cause React re-render

const
    _getThemeColorsFromStylesheet =
        (stylesheets: Stylesheets): ThemeColorDataType => R.path(mp.themeColors)(stylesheets) || emptyObj,
    _memoMaxThemeColors = memoMaxOne(themeColors => themeColors);

export const
    getThemeColorsFromStylesheet = (stylesheets: Stylesheets): ThemeColorDataType => {
        return _memoMaxThemeColors(_getThemeColorsFromStylesheet(stylesheets));
    },
    ThemeColorSelector = withSelector(stylesheetVAT, getThemeColorsFromStylesheet),
    ROThemeColorSelector = receiveOnly(stylesheetVAT, getThemeColorsFromStylesheet);
