import view from "./view/index";
import kind from "./kind";
import { getComputedStylesForMenu } from "./utils";
import { getSpecificStyles } from "./getSpecificStyles";

export default {
    kind,
    view,
    getSpecificStyles,
    calcProps: ({
        siteMap,
        componentId,
        stylesheetsIdToNameMap,
        selectedParentTheme,
        siteSettings,
        parentSection,
        component,
        globalStyles,
        template,
        isMenuCartAllowed
    }) => {
        const themeSettingsData = siteSettings.themeSettingsData;
        return {
            componentId,
            siteData: siteMap,
            stylesheetsIdToNameMap,
            selectedParentTheme,
            themeSettingsData,
            menuState: { isMenuCartAllowed: isMenuCartAllowed ?? false },
            computedStyles: getComputedStylesForMenu(
                parentSection,
                themeSettingsData.autoColorMode,
                component,
                globalStyles,
                template && template.style
            )
        };
    },
    js: ["/renderStatic/dropDownMenu/dropDownMenu_wbtgen.js"]
};
