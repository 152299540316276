
import { receiveOnly } from "../../../../epics/makeCondition";
import { GoogleReviewsEpicState } from "../flowTypes";
import valueActionType from "./valueActionType";

const ReviewsConfigInProgressSelector = (state: GoogleReviewsEpicState) => {
    return state.reviewsConfigInProgress;
};

const ReceiveOnlyReviewsConfigInProgress =
    receiveOnly(valueActionType, ReviewsConfigInProgressSelector);

export {
    ReceiveOnlyReviewsConfigInProgress,
};
