import { pipe } from 'ramda';
import type { AppStateSel } from '../../globalTypes';
import type { WhatsNewState, WhatsNewCombinedListT } from './types';
import { pathSelector } from '../../utils/pathSelector';
import { Lit } from '../../lit';

export const
    whatsNewAppSel: AppStateSel<WhatsNewState> = pathSelector([Lit.whatsNew]),
    whatsNewListAppSel: AppStateSel<WhatsNewCombinedListT> = pipe(whatsNewAppSel, pathSelector([Lit.list])),
    whatsNewLatestTimestampAppSel: AppStateSel<number> = pathSelector([Lit.latestTimestamp]),
    whatsNewCountAppSel: AppStateSel<number> = pipe(whatsNewAppSel, pathSelector([Lit.count])),
    whatsNewShowTipAppSel: AppStateSel<boolean> = pipe(whatsNewAppSel, pathSelector([Lit.showTip]));
