import { siteSettingsValueActionType } from "../../../../App/epics/siteSettings/valueActionType";
import { setMveHeaderPpStateFromSiteSettings } from "./setMveHeaderPpStateFromSiteSettings";
import { receiveOnly } from "../../../../../epics/makeCondition";
import { ComponentsMapSelector } from "../../../../Workspace/epics/componentsEval/selectorActionTypes";
import stylesheetsValueActionType from "../../../../Workspace/epics/stylesheets/valueActionType";
import type { InstancePropertiesPanelRegistryConfigUpdater } from "../../../../PropertiesPanel/instance/types";

export const MveHeaderPropertiesPanelOnShowUpdater: InstancePropertiesPanelRegistryConfigUpdater = {
    conditions: [
        receiveOnly(siteSettingsValueActionType),
        receiveOnly(ComponentsMapSelector),
        receiveOnly(stylesheetsValueActionType),
    ],

    reducer: ({ state: stateHolder, values: [siteSettings, componentsMap, stylesheet] }) => ({
        state: {
            state: setMveHeaderPpStateFromSiteSettings({
                state: stateHolder.state,
                siteSettings,
                componentsMap,
                stylesheet,
            }),
        }
    }),
};
