import { isValidSchemelessUrl, isValidUrl } from "../../../../utils/validation";
import type { InputValidationFunction } from "../flowTypes";
import { makeInputValidationResult } from "./utils/index";

const
    REASON = 'INVALID_URL',
    MESSAGE = 'msg: validation.url {Sorry, looks like {_name} is not a valid URL.}';

type Options = {
    omitSchema?: boolean,
};
export type UrlInputValidation = InputValidationFunction<Options>;

const urlInputValidation: UrlInputValidation = (value: string, options: Options = {}) => {
    const encodedValue = value === decodeURI(value) ? encodeURI(value) : value;
    const isValid = isValidUrl(encodedValue) || (options.omitSchema === true && isValidSchemelessUrl(encodedValue));

    return makeInputValidationResult(
        isValid,
        REASON,
        MESSAGE
    );
};

export {
    urlInputValidation as default,
    REASON as URL_INPUT_VALIDATION_REASON
};
