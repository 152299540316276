export const
    LOGO_MCTA_SET_LOGO_PRESSED = 'LOGO_MCTA_SET_LOGO_PRESSED',
    LOGO_MCTA_LOGO_ASSET_CHOOSEN = 'LOGO_MCTA_LOGO_ASSET_CHOOSEN',
    LOGO_PP_WEBSITE_TITLE_CHANGED = 'LOGO_PP_WEBSITE_TITLE_CHANGED',
    LOGO_PP_LOGO_ASSET_CHOOSEN = 'LOGO_PP_LOGO_ASSET_CHOOSEN',
    LOGO_PP_REMOVE_LOGO_ASSET_PRESSED = 'LOGO_PP_REMOVE_LOGO_ASSET_PRESSED',
    LOGO_MCTA_HORIZONTAL_ALIGNMENT_MOUSE_ENTER = 'LOGO_MCTA_HORIZONTAL_ALIGNMENT_MOUSE_ENTER',
    LOGO_MCTA_HORIZONTAL_ALIGNMENT_MOUSE_LEAVE = 'LOGO_MCTA_HORIZONTAL_ALIGNMENT_MOUSE_LEAVE',

    LOGO_PP_TEXT_STYLE_FONT_FAMILY_CHANGED = 'LOGO_PP_TEXT_STYLE_FONT_FAMILY_CHANGED',
    LOGO_PP_TEXT_STYLE_BOLD_TOGGLE = 'LOGO_PP_TEXT_STYLE_BOLD_TOGGLE',
    LOGO_PP_TEXT_STYLE_ITALIC_TOGGLE = 'LOGO_PP_TEXT_STYLE_ITALIC_TOGGLE',
    LOGO_PP_TEXT_STYLE_UNDERLINE_TOGGLE = 'LOGO_PP_TEXT_STYLE_UNDERLINE_TOGGLE',
    LOGO_PP_TEXT_STYLE_CHARACTER_SPACING_CHANGED = 'LOGO_PP_TEXT_STYLE_CHARACTER_SPACING_CHANGED',
    LOGO_PP_TEXT_STYLE_COLOR_CHANGED = 'LOGO_PP_TEXT_STYLE_COLOR_CHANGED',
    LOGO_PP_TEXT_STYLE_COLOR_CHANGED_AUTO_COLOR = 'LOGO_PP_TEXT_STYLE_COLOR_CHANGED_AUTO_COLOR',
    LOGO_ASSET_UPDATE_ON_REPLACE = 'LOGO_ASSET_UPDATE_ON_REPLACE';
