import { applyMappers, makePlainMappers } from "../../utils";
import type { AnyComponentData } from "../../../../src/components/App/flowTypes";
import type { OpeningHoursComponent } from "../../../../src/components/oneweb/OpeningHours/flowTypes";

const plainPropsMapper = makePlainMappers({
    closedLabel: 'closedLabel',
    dayLabels: 'dayLabels',
    mobileDown: 'mobileDown',
    mobileHide: 'mobileHide',
    textStyle: 'textStyle',
    groupDays: 'groupDays',
    horizontalAlignment: 'horizontalAlignment',
    mobileHorizontalAlignment: 'mobileHorizontalAlignment',
    hourFormat: 'hourFormat',
    open24HoursLabel: 'open24HoursLabel',
    startDayOfTheWeek: 'startDayOfTheWeek',
    timeComponentSeparator: 'timeComponentSeparator',
});

export const to = (componentData: AnyComponentData): OpeningHoursComponent => applyMappers(
    componentData,
    plainPropsMapper.to,
);

export const back = (component: OpeningHoursComponent): AnyComponentData => applyMappers(
    component,
    plainPropsMapper.back,
);
