import * as R from 'ramda';
import { mapIndexed } from "../../../../utils/ramdaEx";

import type { TableComponent } from "../flowTypes";

type Props = {
    cellUpdater: any,
    selectedCellsIndexes: Array<number>
}

const
    setAllCells = ({ cellUpdater }: Props, component: TableComponent) => R.evolve({
        cells: R.map(cellUpdater)
    }, component),
    setSelectedCells = ({ cellUpdater, selectedCellsIndexes }: Props, component: TableComponent) => R.evolve({
        cells: mapIndexed((cell, index) => {
            return selectedCellsIndexes.indexOf(index) > -1 ? cellUpdater(cell, component.commonCellsData) : cell;
        })
    }, component),
    setCells = ({ cellUpdater, selectedCellsIndexes }: Props, component: TableComponent) => {
        const runner = selectedCellsIndexes.length === 0 ? setAllCells : setSelectedCells;

        return runner({ cellUpdater, selectedCellsIndexes }, component);
    },
    setCommonCellsData = ({ cellUpdater, selectedCellsIndexes }: Props, component: TableComponent) => R.evolve({
        commonCellsData: (commonCellsData) => (selectedCellsIndexes.length ? commonCellsData : cellUpdater(commonCellsData))
    }, component);

export {
    setCells,
    setCommonCellsData
};
