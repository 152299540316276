import type { AppStore } from "../../../../../redux/modules/flowTypes";
import { CLOSE_DIALOG_BY_ID } from "../../../../../redux/modules/actionTypes";
import { DOCUMENT_CONFIG_DIALOG_ID } from "../constants";
import { fcResetAction } from "../../../../../redux/modules/children/fileChooser/actionCreators/index";

export const documentConfigDialogMiddleware = (store: AppStore) => (next: Dispatch) => (action: Action) => {
    if (action.type === CLOSE_DIALOG_BY_ID && action.payload === DOCUMENT_CONFIG_DIALOG_ID) {
        store.dispatch(fcResetAction());
    }

    return next(action);
};
