import makeEpic from "../../../../../epics/makeEpic";
import valueActionType from "./valueActionType";
import { ReceiveOnlySelectedComponentSelector } from "../../../../Workspace/epics/componentsEval/selectorActionTypes";
import * as Actions from "../../actionTypes";
import openDialog from "../../../../App/actionCreators/openDialog";
import { DisableCaptchaWarningDialogId } from "../../dialogIds";
import { closeDialogAC } from "../../../../App/actionCreators/closeDialog";

const getDefaultEpicState = (component) => ({
    selectedComponentId: component.id,
    isCaptchaEnabled: true,
    captchaLang: "en_gb"
});

export default makeEpic({
    valueActionType,
    updaters: [
        {
            conditions: [
                ReceiveOnlySelectedComponentSelector,
                Actions.CONTACT_FORM_TOGGLE_CAPTCHA
            ],
            reducer: ({ state, values: [component] }) => {
                if (component.isCaptchaEnabled) {
                    return ({
                        state: {
                            ...(state || getDefaultEpicState(component)),
                        },
                        actionToDispatch: openDialog(DisableCaptchaWarningDialogId)
                    });
                }
                return ({
                    state: {
                        ...(state || getDefaultEpicState(component)),
                    },
                    actionToDispatch: {
                        type: Actions.CONTACT_FORM_ENABLE_CAPTCHA
                    }
                });
            }
        },
        {
            conditions: [
                ReceiveOnlySelectedComponentSelector,
                Actions.CONTACT_FORM_ENABLE_CAPTCHA
            ],
            reducer: ({ state }) => {
                return ({
                    state: {
                        ...state,
                        isCaptchaEnabled: true
                    }
                });
            }
        },
        {
            conditions: [
                ReceiveOnlySelectedComponentSelector,
                Actions.CONTACT_FORM_CANCEL_DISABLE_CAPTCHA
            ],
            reducer: ({ values: [component] }) => {
                return ({
                    state: {
                        ...getDefaultEpicState(component),
                        isCaptchaEnabled: true
                    },
                    actionToDispatch: closeDialogAC()
                });
            }
        },
        {
            conditions: [
                ReceiveOnlySelectedComponentSelector,
                Actions.CONTACT_FORM_DISABLE_CAPTCHA
            ],
            reducer: ({ state }) => {
                return ({
                    state: {
                        ...state,
                        isCaptchaEnabled: false
                    },
                    actionToDispatch: closeDialogAC()
                });
            }
        },
        {
            conditions: [
                ReceiveOnlySelectedComponentSelector,
                Actions.CAPTCHA_LOCALE_CHANGED
            ],
            reducer: ({ state, values: [component] }) => {
                return ({
                    state: {
                        ...state,
                        captchaLang: component.captchaLang
                    }
                });
            }
        }
    ]
});
