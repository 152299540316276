/* eslint-disable no-empty */

declare const IN: Record<string, any>;
declare const FB: Record<string, any>;
declare const twttr: Record<string, any>;
declare const gapi: Record<string, any>;

export default () => {
    /**
     * window.setTimeout is to ensure that the facebook plugin rendering happens correctly.
     * - Vishal Raj
     */

    window.setTimeout(function () {
        try { gapi.plus.go(); } catch (e) { }
        try { twttr.widgets.load(); } catch (e) { }
        try { IN.parse(); } catch (e) { }
        try { FB.XFBML.parse(); } catch (e) { }
    }, 500);
};
