/* eslint-disable max-len */
import React, { useState } from "react";
import cx from "classnames";
import {
    ONBOARDING_CONTINUE_PRESSED,
    ONBOARDING_INITIALIZE_TEMPLATE_PREVIEW,
    SEE_ALL_TEMPLATES_CLICKED,
    ONBOARDING_STEP_BACK_BUTTON_PRESSED
} from "../actionTypes";
import { Msg, Intl } from "../../../view/intl/index";
import { Buttons } from "./Buttons";
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import styles from "./styles.css";
import stepStyles from "./TemplateSelectorStep.css";
import onboardingTopBarStyles from "../../TopBar/view/OnboardingTopBar.css";
import { RightSideTemplatesView } from "./RightSideTemplatesView";
import type { DataDesignT } from "../../../../dal/model/DataDesign";
import type { SubscriptionMetadata } from "../../../redux/modules/flowTypes";

export type TemplateSelectorStepProps = {
    gmbFilteredDesigns: Array<DataDesignT>;
    selectedTemplateIndex: number;
    isForwardDirection: boolean;
    subscriptionMetadata?: SubscriptionMetadata;
    dispatch: Dispatch;
    intl: Intl;
};
export const TemplateSelectorStep = (props: TemplateSelectorStepProps) => {
    const { gmbFilteredDesigns, selectedTemplateIndex, isForwardDirection, subscriptionMetadata, dispatch, intl } = props;

    const previewTemplateDesign = selectedTemplateIndex => {
        dispatch({
            type: ONBOARDING_CONTINUE_PRESSED
        });
        dispatch({
            type: ONBOARDING_INITIALIZE_TEMPLATE_PREVIEW,
            payload: gmbFilteredDesigns[selectedTemplateIndex]
        });
    };

    const [isLoading, setIsLoading] = useState(isForwardDirection);
    return (
        <div className={styles.contentContainer}>
            <div className={cx(styles.leftContentContainer, stepStyles.leftContentContainer)}>
                <div className={onboardingTopBarStyles.topBarContainer}>
                    <div className={onboardingTopBarStyles.topBar}>
                        <div className={onboardingTopBarStyles.oneComLogoContainer}>
                            <div className={onboardingTopBarStyles.oneComLogo} />
                        </div>
                    </div>
                </div>
                <div className={styles.flex1} />
                <div className={cx(styles.leftContent)}>
                    {isLoading ? (
                        <div className={cx(styles.question)}>
                            <Msg k="onboarding.step6.loadingMessage">Sit tight, we\'re finding the perfect templates!</Msg>
                        </div>
                    ) : (
                        <React.Fragment>
                            <div className={styles.textProgress}>
                                <Msg
                                    k="onboarding.step"
                                    params={{
                                        step: 5
                                    }}
                                >{`Step {step} of 5`}</Msg>
                            </div>
                            <div className={cx(styles.question)}>
                                <Msg k="onboarding.step6.websiteTitle">Choose a template</Msg>
                            </div>
                            <div className={styles.tip}>
                                <Msg k="onboarding.step6.websiteTitleTip">
                                    Select a template before choosing what colors it should have. You can always change this in the future.
                                </Msg>
                                <VerticalSpacer y={25} />
                                <Msg k="onboarding.step6.doNotFind">Don’t see anything you like here?</Msg>
                                <br />
                                <a
                                    // eslint-disable-next-line no-script-url, react/jsx-first-prop-new-line
                                    href="javascript:void(0);"
                                    className={stepStyles.seeAllTemplates}
                                    onClick={() =>
                                        dispatch({
                                            type: SEE_ALL_TEMPLATES_CLICKED
                                        })}
                                >
                                    <Msg k="onboarding.templates.viewAll">See all templates</Msg>
                                </a>
                            </div>
                        </React.Fragment>
                    )}
                </div>
                <div className={styles.flex1} />
                {!isLoading && (
                    <Buttons
                        mctaDisabled={false}
                        mctaText="msg: common.select {Select}"
                        mctaHandler={() => previewTemplateDesign(selectedTemplateIndex)}
                        sctaHandler={() =>
                            dispatch({
                                type: ONBOARDING_STEP_BACK_BUTTON_PRESSED
                            })}
                        intl={intl}
                    />
                )}
            </div>
            <div className={cx(styles.rightContentContainer, stepStyles.rightContentContainer)}>
                <RightSideTemplatesView
                    gmbFilteredDesigns={gmbFilteredDesigns}
                    selectedTemplateIndex={selectedTemplateIndex}
                    previewTemplateDesign={previewTemplateDesign}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    subscriptionMetadata={subscriptionMetadata}
                    dispatch={dispatch}
                />
            </div>
        </div>
    );
};
