import * as R from 'ramda';

import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly } from '../../../../epics/makeCondition';
import valueActionType from './valueActionType';
import {
    ReceiveOnlySelectedComponentsIdsSelector,
    ComponentsMapNoGhostBasesSelector,
    ReceiveOnlyComponentsMap,
} from "../componentsEval/selectorActionTypes";
import templateOffsetValueActionType from "../../../oneweb/Template/epics/templateOffset/valueActionType";
import { isParentFor, getComponentsIntersection, getComponentsBBox } from "../../../../utils/componentsMap/index";
import memo, { memoMaxOne } from '../../../../../utils/memo';
import { isContainer, getAboveComponent } from '../../../../utils/component';
import workspaceBBoxValueActionType from '../workspaceBBox/valueActionType';
import { ReceiveOnlyTemplateWidthActionType } from '../../../oneweb/Template/epics/template/selectorActionTypes';
import isStretchComponentKind from '../../../oneweb/isStretchComponentKind';
import tree from './rbushInstance';
import DndAddComponentValueActionType from '../../../DndAddComponent/epic/valueActionType';

import type {
    ComponentsMap, BBox, AnyComponent
} from '../../../../redux/modules/children/workspace/flowTypes';
import type { ComponentBase } from '../../../oneweb/flowTypes';
import type {
    ComponentsMapAndArray,
    ComponentTreeMap,
    ComponentTree,
    ComponentsMatches,
    RedDecorationState
} from './flowTypes';
import { AligningEdgesStep } from "../../../../constants/app";
import { getComponentZIndex } from "../../zIndex";
import { ROCodeComponentsRendererHeadHeightSelector } from "../../CodeComponentsRenderer/epic/selectorActionTypes";
import { isCmpsInsideModernHeaderOrFooter } from "../../../ModernLayouts/utils";
import { getAllCmpIdsInModernHeaderFooter } from "../../../ModernLayouts/preview_utils";
import { getParentChildrenMap } from '../componentsEval/userInteractionMutations/getParentChildrenMap';
import { getWebShopStripCmpIds } from '../../../ModernLayouts/layoutsData/webshopMHFDataUtils';

const
    defaultState: RedDecorationState = {
        bBoxes: [],
        componentBBoxes: []
    };
export const getComponentsMapAndArray = memoMaxOne(
    (componentsMap: ComponentsMap): ComponentsMapAndArray => {
        const rmap: ComponentTreeMap = {};
        R.forEachObjIndexed((component: ComponentBase, id: string) => {
            rmap[id] = {
                ...component,
                minX: component.left,
                maxX: component.left + component.width,
                minY: component.top,
                maxY: component.top + component.height,
                id
            };
        }, componentsMap);
        return {
            componentsForTreeMap: rmap,
            componentsArray: R.values(rmap),
        };
    }
);
export const fillTree = memo((components: ComponentTree[]) => {
    tree.clear();
    tree.load(components);
});

function checkExistingIntersections(componentToCheck, intersections, currentComponentId) {
    return componentToCheck.id !== currentComponentId &&
        (!intersections[componentToCheck.id] || intersections[componentToCheck.id].indexOf(currentComponentId) === -1);
}

const overlapOrConditions = [
    (aboveComponent, underneathComponent, sameZIndex) =>
        !isContainer(underneathComponent.kind) && !aboveComponent.wrap && !sameZIndex,
    (aboveComponent, underneathComponent, sameZIndex) =>
        !isContainer(underneathComponent.kind) && !isContainer(aboveComponent.kind)
        && !aboveComponent.wrap && sameZIndex,
    (aboveComponent, underneathComponent, sameZIndex) => isParentFor(underneathComponent, aboveComponent, true) && !sameZIndex // eslint-disable-line max-len
];

function checkOverlap(underneathComponent: AnyComponent, aboveComponent: AnyComponent): boolean {
    const sameZIndex = getComponentZIndex(aboveComponent) === getComponentZIndex(underneathComponent);
    return overlapOrConditions.some(condition => condition(aboveComponent, underneathComponent, sameZIndex));
}

const unreachableOrderIndex = 10000;

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                receiveOnly(workspaceBBoxValueActionType),
                ReceiveOnlySelectedComponentsIdsSelector,
                ReceiveOnlyTemplateWidthActionType,
                receiveOnly(templateOffsetValueActionType),
                ComponentsMapNoGhostBasesSelector,
                ReceiveOnlyComponentsMap,
                ROCodeComponentsRendererHeadHeightSelector,
                DndAddComponentValueActionType
            ],
            reducer: ({
                values: [
                    workspaceBBox,
                    selectedComponentsIds,
                    templateWidth,
                    templateOffset,
                    componentsMapNoGhostBases,
                    componentsMap,
                    codeComponentHeadHeight,
                    dnd
                ]
            }) => {
                let inputComponentsMap = componentsMapNoGhostBases;
                const mhfCmpIds = [...Object.keys(getAllCmpIdsInModernHeaderFooter(componentsMap)),
                    ...getWebShopStripCmpIds(componentsMap)];

                if (mhfCmpIds.length) {
                    inputComponentsMap = R.omit(mhfCmpIds, inputComponentsMap);
                }
                if (dnd.isDroppable) {
                    inputComponentsMap = R.assoc('dnd-component-id', {
                        kind: dnd.componentKind,
                        width: dnd.dimensions.width,
                        height: dnd.dimensions.height,
                        left: dnd.position.x - templateOffset.x,
                        top: dnd.position.y - templateOffset.y - codeComponentHeadHeight,
                        orderIndex: isStretchComponentKind(dnd.componentKind) ? 0 : unreachableOrderIndex,
                        stretch: dnd.stretch
                    }, inputComponentsMap);
                }

                const
                    { componentsForTreeMap, componentsArray } = getComponentsMapAndArray(inputComponentsMap),
                    intersections: ComponentsMatches = {},
                    intersectionBboxes: BBox[] = [],
                    intersectionComponents: any = [],
                    dndAddIntersections: any = [];

                fillTree(componentsArray);
                R.forEachObjIndexed((component: AnyComponent, id: string) => {
                    const
                        isDndAddComponent = id === 'dnd-component-id',
                        results = tree.search({
                            minX: component.left + AligningEdgesStep,
                            maxX: component.left + component.width - AligningEdgesStep,
                            minY: component.top + AligningEdgesStep,
                            maxY: component.top + component.height - AligningEdgesStep
                        }),
                        oComponent: AnyComponent = inputComponentsMap[id],
                        _isCmpInsideModernHeaderOrFooter = !isDndAddComponent &&
                            isCmpsInsideModernHeaderOrFooter([component], componentsMap);

                    intersections[id] = [];

                    const parentChildrenMap = getParentChildrenMap(componentsMap);

                    const isChildOfComponent = (parentId, childId, parentChildrenMap) => {
                        const children = parentChildrenMap[parentId] || [];
                        return children.some(id => {
                            if (id === childId) {
                                return true;
                            }
                            return isChildOfComponent(id, childId, parentChildrenMap);
                        });
                    };

                    results.forEach(treeResult => {
                        if (checkExistingIntersections(treeResult, intersections, id)) {
                            const uComponent: AnyComponent = inputComponentsMap[treeResult.id],
                                aboveComponent: AnyComponent = getAboveComponent(oComponent, uComponent),
                                underneathComponent: AnyComponent =
                                    aboveComponent === oComponent ? uComponent : oComponent;

                            const overlap: boolean = checkOverlap(underneathComponent, aboveComponent);
                            const isChild = overlap && isChildOfComponent(underneathComponent.id, aboveComponent.id, parentChildrenMap);
                            if (!overlap || isChild) return;

                            const intersectionBbox: BBox = getComponentsIntersection(
                                uComponent,
                                oComponent,
                                workspaceBBox
                            );
                            if (intersectionBbox) {
                                intersectionBboxes.push(intersectionBbox);
                                if (selectedComponentsIds.indexOf(oComponent.id) > -1) {
                                    intersectionComponents.push(uComponent);
                                }
                                if (selectedComponentsIds.indexOf(uComponent.id) > -1) {
                                    intersectionComponents.push(oComponent);
                                }

                                if (isDndAddComponent) {
                                    dndAddIntersections.push(intersectionBbox);
                                }
                            }
                        }
                    });
                    // Check for out of template area position
                    const componentRight = Math.ceil(component.left + component.width),
                        componentLeft = Math.floor(component.left);
                    if (
                        !_isCmpInsideModernHeaderOrFooter &&
                        !isStretchComponentKind(component.kind, component.stretch)
                        && (componentRight > templateWidth || componentLeft < 0)
                    ) {
                        if (componentRight > templateWidth && componentLeft < 0) {
                            const
                                intersection1 = {
                                    top: component.top,
                                    left: componentLeft,
                                    bottom: component.top + component.height,
                                    right: 0
                                },
                                intersection2 = {
                                    top: component.top,
                                    left: templateWidth,
                                    bottom: component.top + component.height,
                                    right: componentRight
                                };
                            intersectionBboxes.push(intersection1);
                            intersectionBboxes.push(intersection2);
                            if (isDndAddComponent) {
                                dndAddIntersections.push(intersection1);
                                dndAddIntersections.push(intersection2);
                            }
                        } else {
                            const intersection = {
                                top: component.top,
                                left: componentLeft < 0 ? componentLeft : Math.max(componentLeft, templateWidth + 1),
                                bottom: component.top + component.height,
                                right: componentRight > templateWidth ? componentRight : Math.min(componentRight, -1)
                            };

                            intersectionBboxes.push(intersection);
                            if (isDndAddComponent) {
                                dndAddIntersections.push(intersection);
                            }
                        }
                    }
                    // if (intersectionComponentsLength === intersectionComponents.length) {
                    //     intersectionComponents.pop();
                    // }
                }, componentsForTreeMap);

                return {
                    state: {
                        bBoxes: intersectionBboxes,
                        componentBBoxes: R.pipe(
                            R.uniqBy(R.prop('id')),
                            R.map(v => [v]),
                            R.map(R.curry(getComponentsBBox)(R.__, workspaceBBox)),
                            R.concat(intersectionBboxes.filter(
                                intersection => dndAddIntersections.indexOf(intersection) !== -1
                            ))
                        )(intersectionComponents)
                    }
                };
            }
        }
    ]
});
