import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import { LEFT_PANEL_ON_MOUSE_ENTER, LEFT_PANEL_ON_MOUSE_LEAVE } from '../../actionTypes';

const defaultState = false;

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [LEFT_PANEL_ON_MOUSE_ENTER],
            reducer: () => ({ state: true })
        },
        {
            conditions: [LEFT_PANEL_ON_MOUSE_LEAVE],
            reducer: () => ({ state: false })
        }
    ]
});
