import React from "react";
import cx from "classnames";
import styles from "../Globalstyles/GlobalstylesDialog.css";

type GrayBgContainerProps = {
    className?: string,
    children?: any
}

export default ({ className, children }: GrayBgContainerProps) => (
    <div className={cx(styles.grayBackground, className)}>
        {children}
    </div>
);
