/* global $ */

import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { MobileHeader } from "../../../Mobile/header/MobileHeader";
import type { MobileHeaderProps } from "../../../Mobile/header/types";
import { selectMveHeaderAction } from "../../epics/hoveringAndSelection/actions";
import type { AppState } from "../../../../redux/modules/flowTypes";
import { mveHeaderStateAppSel } from "../selectors";
import { mobileStyleAppSel } from "../../../Mobile/style/selectors";
import * as styles from './MveHeader.css';

type Props = MobileHeaderProps & {
    dispatch: Dispatch,
};

export class MveHeaderClass extends React.Component<Props> {
    headerRef: React.RefObject<HTMLDivElement>;
    constructor(props: Props) {
        super(props);
        this.headerRef = React.createRef();
    }

    onClick = () => this.props.dispatch(selectMveHeaderAction());
    componentDidUpdate(prevProps: Props) {
        if (this.headerRef && this.headerRef.current && (
            (prevProps.state.pin && !this.props.state.pin) ||
            (this.props.isMenuOpen && !prevProps.isMenuOpen)
        )) {
            // @ts-ignore
            $(this.headerRef.current.parentNode).animate({ scrollTop: 0 }, 500);
        }
    }
    render() {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { dispatch, ...mobileHeaderProps } = this.props;
        const header = <MobileHeader {...mobileHeaderProps} />,
            { isMVE, isMenuOpen, state: { pin } } = this.props,
            isFixedHeader = isMVE ? (pin && !isMenuOpen) : pin;
        return (
            <div ref={this.headerRef} className={cx({ [styles.container]: isFixedHeader })} onClick={this.onClick}>
                {isFixedHeader ? <div className={styles.fixedContainer}>{header}</div> : header}
            </div>
        );
    }
}

const mapStateToProps = (appState: AppState) => ({
    state: mveHeaderStateAppSel(appState),
    mobileStyle: mobileStyleAppSel(appState),
});

export const MveHeader = connect(mapStateToProps)(MveHeaderClass);
