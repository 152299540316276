import type { FmTabIdT } from './types';

export type SwitchFmTabReducerAction = {
    type: 'SWITCH_FM_TAB_ACTION',
    payload: any,
};

export const
    SWITCH_FM_TAB_ACTION = 'SWITCH_FM_TAB_ACTION',
    switchFmTabAction = (id: FmTabIdT, isVideoFileType: boolean = false) => ({
        type: SWITCH_FM_TAB_ACTION,
        payload: {
            tabId: id,
            isVideoFileType
        }
    });

export const
    CLOSE_FM_DIALOG_ACTION = 'CLOSE_FM_DIALOG_ACTION',
    closeFmDialogAction = () => ({
        type: CLOSE_FM_DIALOG_ACTION
    });
