import makeEpic from '../../../epics/makeEpic';
import { COMPONENTS_ADD_NOT_ALLOWED } from "../../Workspace/epics/componentsEval/actionTypes";
import ComponentsAddNotAllowedDialogId from "../dialogId";
import openDialog from "../../App/actionCreators/openDialog";
import { AddNotAllowed } from '../constants';
import registry from "../../../view/oneweb/registry/index";
import valueActionType from "./valueActionType";
import { addMessage } from "../../Toaster/actionCreators";
import MessageTypes from "../../Toaster/MessageTypes";

export default makeEpic({
    valueActionType,
    updaters: [
        {
            conditions: [COMPONENTS_ADD_NOT_ALLOWED],
            reducer: ({ values: [allErrors] }) => {
                const toasterErrors = allErrors.filter(err => err.showToaster),
                    errors = allErrors.filter(err => !err.showToaster);

                const multipleActionsToDispatch: Action[] = [];
                if (errors.length) {
                    const title = (errors.length > 1)
                            ? AddNotAllowed.multipleErrorsTitle
                            : registry[errors[0].componentKind].errorMsgsOnAdd[errors[0].error].title,
                        texts = errors.map(errorObj =>
                            registry[errorObj.componentKind].errorMsgsOnAdd[errorObj.error].text);
                    multipleActionsToDispatch.push(openDialog(ComponentsAddNotAllowedDialogId, { title, texts }));
                }
                if (toasterErrors.length) {
                    toasterErrors.forEach(({ message }) => multipleActionsToDispatch.push(
                        addMessage({
                            type: MessageTypes.INFO,
                            text: message
                        })
                    ));
                }

                return {
                    state: null,
                    multipleActionsToDispatch
                };
            }
        }
    ]
});
