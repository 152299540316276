
export const
    TEMPLATE_INITIALIZING = 'TEMPLATE_SETTINGS_INITIALIZING',
    LOCALE_CHANGED = 'LOCALE_CHANGED',
    TEMPLATE_WIDTH_CHANGED = 'TEMPLATE_WIDTH_CHANGED',
    TEMPLATE_WIDTH_CHANGE_FINISHED = 'TEMPLATE_WIDTH_CHANGE_FINISHED',
    TEMPLATE_TITLE_CHANGE = 'TEMPLATE_TITLE_CHANGE',
    TEMPLATE_VERTICAL_SCROLL_TOGGLE = 'TEMPLATE_VERTICAL_SCROLL_TOGGLE',
    TEMPLATE_AFTER_SITE_DATA_CHANGE = 'TEMPLATE_AFTER_SITE_DATA_CHANGE',
    THEME_COLORS_DATA_CHANGE = 'THEME_COLORS_DATA_CHANGE',
    TEMPLATE_DISPLAY_SHOP_STRIP_IN_FOOTER = 'TEMPLATE_DISPLAY_SHOP_STRIP_IN_FOOTER';
