import makeEpic from '../../../../epics/makeEpic';
import { isModernLayoutActivatedVAT } from './valueActionType';
import {
    ReceiveOnlyComponentsMap,
    SelectedComponentsIdsSelector
} from '../componentsEval/selectorActionTypes';
import { optional, receiveOnly } from "../../../../epics/makeCondition";
import { COMPONENTS_MAP_INITIALIZED } from "../componentsEval/actionTypes";
import { ACTIVATE_MODERN_HEADER_FOOTER, DEACTIVATE_MODERN_HEADER_FOOTER, ACTIVATE_MODERN_HEADER,
    ACTIVATE_MODERN_FOOTER, HIDE_MODERN_LAYOUT_ONBOARDING_PANEL } from '../../../ModernLayouts/actionTypes';
import { REDO, UNDO } from "../../../../epics/undoManager/actionTypes";
import { isSectionComponent } from "../../../oneweb/isSectionComponent";
import { scrollWorkspaceToPositionAction } from '../scroll/actions';
import { MODERN_LAYOUT_ONBOARDING_PANEL_EPIC_VALUE } from "../../../ModernLayouts/epics/onBoardingPanel/valueActionType";
import { getHeaderAndFooterSection } from '../../../oneweb/Section/utils';
import type { AnyComponent } from "../../../oneweb/flowTypes";
import type { ComponentsMap } from "../../../../redux/modules/children/workspace/flowTypes";

export type IsModernLayoutActivatedEpic = {
    isModernLayoutActivated: boolean
}

const isModernLayoutSection = (component: AnyComponent) => {
    return !!component && !!(component.modernLayout || {}).active;
};

const getIsModernLayoutActivated = (componentsMap: ComponentsMap): boolean => {
    const { footer, header } = getHeaderAndFooterSection(componentsMap);
    return (header && isModernLayoutSection(header) &&
        footer && isModernLayoutSection(footer)) || false;
};

const defaultState = false;
const isModernLayoutActivatedEpic = makeEpic({
    defaultState,
    valueActionType: isModernLayoutActivatedVAT,
    updaters: [
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                SelectedComponentsIdsSelector,
                receiveOnly(MODERN_LAYOUT_ONBOARDING_PANEL_EPIC_VALUE),
                optional(COMPONENTS_MAP_INITIALIZED),
                optional(ACTIVATE_MODERN_HEADER_FOOTER),
                optional(DEACTIVATE_MODERN_HEADER_FOOTER),
                optional(ACTIVATE_MODERN_HEADER),
                optional(ACTIVATE_MODERN_FOOTER),
                optional(HIDE_MODERN_LAYOUT_ONBOARDING_PANEL),
                optional(UNDO),
                optional(REDO),
            ],
            reducer: ({ values: [componentsMap, selectedCmps, { show: isOnBoardingPanelOpen }], state }) => {
                const isModernLayoutActivated = getIsModernLayoutActivated(componentsMap);
                if (state === isModernLayoutActivated || isOnBoardingPanelOpen) {
                    return { state };
                }
                const sectionId = selectedCmps.find(id => isSectionComponent(componentsMap[id]));
                let actionToDispatch;
                if (sectionId) {
                    const { top: y } = componentsMap[sectionId];
                    actionToDispatch = scrollWorkspaceToPositionAction(0, y);
                }
                return {
                    state: isModernLayoutActivated,
                    actionToDispatch
                };
            }
        }
    ]
});

export {
    isModernLayoutActivatedEpic,
    isModernLayoutActivatedEpic as default
};
