import {
    makeCombineReducer,
    makeDefaultStateReducers,
    makeComponentBaseReducers
} from "../../../../redux/makeReducer/index";
import kind from '../kind';
import * as ActionTypes from "../actionTypes";
import type { CodeComponent, CodeComponentDependsOn } from "../flowTypes";
import {
    TOGGLE_MOBILE_DOWN_ACTION
} from "../../../PropertiesPanel/view/MobileView/actions";
import mobileDownReducer from "../../../PropertiesPanel/view/MobileView/mobileDownReducer";
import CodeComponentLocationTypes from "../locationTypes";
import { Lit } from '../../../../lit';

function setProperty(props: Array<string>) {
    return (component: CodeComponent, { payload }: Action<any>): CodeComponent => {
        return props.reduce((acc, prop) => {
            return { ...acc, [prop]: payload[prop] };
        }, component);
    };
}

function setLocation() {
    return (component: CodeComponent, { payload }: Action<any>,
        { headerFooterSectionsPosition, isModernLayoutActivated }: CodeComponentDependsOn): CodeComponent => {
        if (payload) {
            return { ...component, location: payload };
        }
        let top, inTemplate = isModernLayoutActivated ? false : component.inTemplate;
        if (component.location === CodeComponentLocationTypes.BeforeClosingHead) {
            const headerPosition = headerFooterSectionsPosition[Lit.headerSection];
            top = component.inTemplate && !isModernLayoutActivated ? 0 : headerPosition.height;
        } else if (component.location === CodeComponentLocationTypes.BeforeClosingBody) {
            const footerPosition = headerFooterSectionsPosition[Lit.footerSection];
            top = component.inTemplate && !isModernLayoutActivated ?
                (footerPosition.top + footerPosition.height - component.height) :
                (footerPosition.top - component.height);
        }
        return ({ ...component, location: payload, top, left: 0, inTemplate });
    };
}

const reducer = makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(kind),
        ...makeDefaultStateReducers({
            width: 200,
            height: 80,
            location: '',
            name: '',
            code: '',
            mobileHide: false,
            mobileDown: false
        })
    },
    handleActions: {
        [ActionTypes.CODE_PROP_PANEL_CHANGE_TITLE]: setProperty(['name']),
        [ActionTypes.CODE_UPDATE_CODE_CONTENT]: setProperty(['code', 'location']),
        [TOGGLE_MOBILE_DOWN_ACTION]: mobileDownReducer
    }
});

const handleActions = {
    [ActionTypes.CODE_UPDATE_PLACEMENT]: setLocation()
};

export default (state: CodeComponent, action: AnyAction, dependencies?: CodeComponentDependsOn) => {
    return handleActions[action.type]
        ? handleActions[action.type](state, action, dependencies)
        : reducer(state, action);
};
