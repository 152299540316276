import * as R from 'ramda';
import { shopPageIdsListVat } from './valueActionType';
import makeEpic from "../../../../../epics/makeEpic";
import { COMPONENT_ADDED, DELETED, PASTE } from "../../../../Workspace/epics/componentsEval/updateReasons";
import { REDO, UNDO, UNDO_INITIAL_STATE } from "../../../../../epics/undoManager/updateReasons";
import currentPageIdValueActionType from "../../../../App/epics/currentPageId/valueActionType";
import { receiveOnly, whenWithSelector, whenReason } from "../../../../../epics/makeCondition";
import { ComponentsEvalValueActionType } from "../../../../Workspace/epics/componentsEval/valueActionType";
import { siteDataValueActionType } from '../../../../App/epics/siteData/valueActionType';
import { getValidPageIds, isWebshopComponentFound } from "../../utils";
import { getWebshopPageIds } from "../../../../../../../server/shared/utils/getWebshopPageIds";
import {
    FILL_SPECIFIC_SITE_SETTINGS_DURING_PAGE_OR_TEMPLATE_IMPORT
} from '../../../../App/epics/siteSettings/actionTypes';
import fetchFeaturedProductsPageIds from '../fetchFeaturedProductsPageIds';
import actionTypes from '../../reducer/actionTypes';

export const
    cartPageIdsDefaultState: Array<string> = [];

const
    updateReasonsToCheck = [
        COMPONENT_ADDED,
        DELETED,
        PASTE,
        UNDO,
        REDO,
        UNDO_INITIAL_STATE
    ],
    ComponentsMapSelector = whenWithSelector(
        ComponentsEvalValueActionType,
        a => a.payload.state.componentsMap,
        a => updateReasonsToCheck.indexOf(a.epicUpdateReason) !== -1
    ),
    appendPageIdToList = (isPageIdExist, currentPageIds, currentPageId) => {
        if (!isPageIdExist) {
            return [...currentPageIds, currentPageId];
        }
        return null;
    },
    removePageIdFromList = (isPageIdExist, currentPageIds, currentPageId) => {
        if (isPageIdExist) {
            return R.remove(currentPageIds.indexOf(currentPageId), 1, currentPageIds);
        }
        return null;
    },
    findPageIdUpdater = (isWebshopComponentFound) => {
        if (isWebshopComponentFound) {
            return appendPageIdToList;
        } else {
            return removePageIdFromList;
        }
    };

export default makeEpic({
    defaultState: cartPageIdsDefaultState,
    valueActionType: shopPageIdsListVat,
    saveIntoSiteSettings: { key: 'webshopPageIds' },
    updaters: [
        {
            keepFullActions: true,
            conditions: [
                receiveOnly(currentPageIdValueActionType),
                ComponentsMapSelector
            ],
            reducer: ({ values: [{ payload: currentPageId }, componentsMap], state }) => {
                const
                    currentPageIds = state,
                    isPageIdExist = currentPageIds.indexOf(currentPageId) > -1;

                const webshopComponentFound = isWebshopComponentFound(componentsMap);
                const pageIdUpdater = findPageIdUpdater(webshopComponentFound);
                const webshopPageIds = pageIdUpdater(isPageIdExist, currentPageIds, currentPageId);
                const webshopComponentDeleted = isPageIdExist && !webshopComponentFound;

                if (webshopPageIds) {
                    if (webshopComponentDeleted) {
                        return {
                            state: webshopPageIds,
                            actionToDispatch: fetchFeaturedProductsPageIds()
                        };
                    }
                    // webshop component added
                    return {
                        state: webshopPageIds,
                        actionToDispatch: { type: actionTypes.WEBSHOP_WELCOME_OK_BUTTON }
                    };
                }
                return { state };
            }
        },
        {
            conditions: [
                siteDataValueActionType
            ],
            reducer: ({ values: [siteData], state }) => {
                /* Validate the list of pageIds here. */
                const webshopPageIds = getValidPageIds(state, siteData.getPageIds());
                if (state !== webshopPageIds) {
                    return {
                        state: webshopPageIds,
                    };
                }
                return { state };
            }
        },
        {
            conditions: [FILL_SPECIFIC_SITE_SETTINGS_DURING_PAGE_OR_TEMPLATE_IMPORT],
            reducer: ({ values: [{ pages }], state }) => {
                const { webshopPageIds } = getWebshopPageIds({ pages });

                if (webshopPageIds.length) {
                    return {
                        state: R.concat(state, webshopPageIds),
                    };
                }

                return { state };
            }
        },
        {
            conditions: [
                whenReason(shopPageIdsListVat, UNDO_INITIAL_STATE),
                receiveOnly(siteDataValueActionType)
            ],
            reducer: ({ values: [pages, siteData] }) => {
                const webshopPageIds = getValidPageIds(pages, siteData.getPageIds());
                return { state: webshopPageIds };
            }
        }
    ]
});
