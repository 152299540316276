export const
    TopBarHeight = 46, /* Keep this consistent with 'topBarHeight' in 'wbtgen/src/components/TopBar/view/TopBar.css' */
    SubHeaderHeight = 75,
    Popups = {
        USER_PROFILE: 'USER_PROFILE',
        PAGES: 'PAGES',
        HELP: 'HELP',
        FEEDBACK: 'FEEDBACK',
        TUTORIAL_VIDEO_INTRO: 'TUTORIAL_VIDEO_INTRO'
    },
    PUBLISH_STATUS = {
        ACCEPTED: 'accepted',
        DEFAULT: 'default',
        PUBLISHED: 'published',
        PUBLISHING: 'publishing',
        UNPUBLISHED: 'unpublished'
    },
    PublishStatusCheckDuration = 5000,
    UpdatePublishProgressAnimationDuration = 1000,
    maxFakePublishProgress = 20,
    APP_EVENT_COLLECTOR = {
        CATEGORY: 'Workspace',
        ACTION: 'publish'
    },
    GA_PUBLISH_EVENTS = {
        CATEGORY: 'Publishing',
        ACTION: 'publish',
        LABEL: 'pages',
    },
    FEEDBACK_DIALOG_CONSTANTS = {
        MINIMUM_LENGTH: 30,
        ONE_COM_CHAT_URL: "https://www.one.com/chat"
    };
