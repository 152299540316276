import React from 'react';
import * as pageIds from './ids';
import pageTitles from './titles';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import PropertyContainer from "../../../../../view/common/PropertyContainer/index";
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import injectIntl from "../../../../../view/intl/injectIntl";
import InputField from '../../../../../view/common/Input/PropertiesInputField';
import * as actionTypes from "../../actionTypes";
import { PropertiesButton } from '../../../../../view/common/Button/index';

class Main extends React.Component<any> {
    render() {
        const { selectedComponent, dispatch } = this.props;
        return (
            <Page>
                <VerticalSpacer />
                <PropertyContainer
                    label="msg: common.title {Title}"
                >
                    <InputField
                        value={selectedComponent.name}
                        onChange={name => dispatch({
                            type: actionTypes.CODE_PROP_PANEL_CHANGE_TITLE,
                            payload: { name },
                            amendToSelf: true
                        })}
                        placeholder="msg: common.enterTitle {Enter title}"
                    />
                </PropertyContainer>
                <VerticalSpacer />
                <PropertyContainer>
                    <PropertiesButton
                        text="msg: component.code.editCode {Edit code}"
                        onClick={() => dispatch({
                            type: actionTypes.CODE_EDIT_CODE_BUTTON_CLICKED,
                            payload: selectedComponent
                        })}
                    />
                </PropertyContainer>
            </Page>
        );
    }
}

const
    id = pageIds.HTML_CODE,
    title = pageTitles[id];

export default { id, view: injectIntl(Main), title };
