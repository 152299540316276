/* global $ */

import React from 'react';
import cx from 'classnames';
import * as styles from './MobileViewEditor.css';
import type { MobileEditorActionProps, CmpHeights } from '../flowTypes';
import { injectIntl } from "../../../view/intl/index";
import {
    mobileEditorActionHideClickedAC,
    mobileEditorActionsMouseHoverAC,
    mobileEditorActionsMouseOutAC,
    removeOutlineAndSelectionMobileViewEditorAC
} from "../actionCreators";
import {
    MOBILE_EDITOR_MOVE_DOWN_ARROW_CLICKED,
    MOBILE_EDITOR_MOVE_UP_ARROW_CLICKED,
    MOBILE_EDITOR_UNLOCK_COMPONENTS
} from "../actionTypes";

export const getCmpHeights = (): CmpHeights => {
    const cmps = $('.' + styles.content + ' li.mobileV > div');
    let cmpHeights = {};
    cmps.each(function (_, elt) {
        const container = $(elt),
            id = $(elt).attr('data-id')!;
        cmpHeights[id] = Math.round(container.outerHeight()!);
    });
    return cmpHeights as CmpHeights;
};

// @ts-ignore
export default injectIntl(class MobileEditorActions extends React.Component<MobileEditorActionProps, void> {
    render() {
        const
            {
                hoveringAndSelection: {
                    reorderArrowsShow,
                    mobileEditorActionsPosition,
                    disableUpArrow,
                    disableDownArrow,
                    selectedCmpId,
                    hideIconShow,
                    unlockIconShow
                },
                intl,
                onMouseLeave,
                dispatch
            } = this.props;
        if (!selectedCmpId || !mobileEditorActionsPosition) {
            return null;
        }

        const { top, left } = mobileEditorActionsPosition,
            moveDownArrowToolTip = 'msg: tooltip.moveDownArrow {Move down}',
            moveUpArrowToolTip = 'msg: tooltip.moveUpArrow {Move up}',
            hideToolTip = 'msg: tooltip.mobileHide {Hide}',
            unlockToolTip = 'msg: tooltip.unlockFromBotom {Unlock from bottom}',
            currentScrollTop = $('.' + styles.content)[0].scrollTop,
            onClickDownArrow = !disableDownArrow ?
                () => dispatch({
                    type: MOBILE_EDITOR_MOVE_DOWN_ARROW_CLICKED,
                    payload: { currentScrollTop, cmpHeights: getCmpHeights() }
                }) : undefined,
            onClickUpArrow = !disableUpArrow ?
                () => dispatch({
                    type: MOBILE_EDITOR_MOVE_UP_ARROW_CLICKED,
                    payload: { currentScrollTop, cmpHeights: getCmpHeights() }
                }) : undefined,
            onHideIconClick = () => dispatch(mobileEditorActionHideClickedAC(selectedCmpId)),
            onBottomUnlockClick = () => {
                dispatch({
                    type: MOBILE_EDITOR_UNLOCK_COMPONENTS,
                    payload: { componentId: selectedCmpId }
                });
                dispatch(removeOutlineAndSelectionMobileViewEditorAC());
            },
            hideReorderArrows = !reorderArrowsShow || (disableUpArrow && disableDownArrow);
        return (
            <div
                data-title-position="top"
                className={cx(styles.mobileActions,
                    { [styles.hideIconHidden]: !hideIconShow,
                        [styles.unlockIconHidden]: !unlockIconShow,
                        [styles.reorderArrowsHidden]: hideReorderArrows })}
                style={{ top, left }}
                onMouseLeave={() => onMouseLeave(selectedCmpId)}
            >
                {hideReorderArrows ? null : <div
                    data-title={intl.msgJoint(moveDownArrowToolTip)}
                    className={cx(styles.downArrow, styles.upDownArrows, { [styles.disabled]: disableDownArrow })}
                    onMouseOver={() => dispatch(mobileEditorActionsMouseHoverAC())}
                    onMouseOut={() => dispatch(mobileEditorActionsMouseOutAC())}
                    onClick={onClickDownArrow}
                />}
                {hideReorderArrows ? null : <div
                    data-title={intl.msgJoint(moveUpArrowToolTip)}
                    className={cx(styles.upArrow, styles.upDownArrows, { [styles.disabled]: disableUpArrow })}
                    onMouseOver={() => dispatch(mobileEditorActionsMouseHoverAC())}
                    onMouseOut={() => dispatch(mobileEditorActionsMouseOutAC())}
                    onClick={onClickUpArrow}
                />}
                {hideIconShow ? <div
                    data-title={intl.msgJoint(hideToolTip)}
                    className={styles.hideIcon}
                    onMouseOver={() => dispatch(mobileEditorActionsMouseHoverAC())}
                    onMouseOut={() => dispatch(mobileEditorActionsMouseOutAC())}
                    onClick={onHideIconClick}
                /> : null}
                {unlockIconShow ? <div
                    data-title={intl.msgJoint(unlockToolTip)}
                    className={styles.unlockIcon}
                    onMouseOver={() => dispatch(mobileEditorActionsMouseHoverAC())}
                    onMouseOut={() => dispatch(mobileEditorActionsMouseOutAC())}
                    onClick={onBottomUnlockClick}
                /> : null}
            </div>
        );
    }
});
