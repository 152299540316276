import { makeApplyEditorChangesReducer } from "./helpers/helpers";
import type { Command } from "../flowTypes";

type Props = {
    triggerActionType: string;
    cmd: Command;
};

const makeExecCommandUpdater = ({ triggerActionType, cmd }: Props) => ({
    conditions: [triggerActionType],
    reducer: makeApplyEditorChangesReducer(({ state, scope, editor }) => {
        editor.execCommand(cmd);

        return {
            state,
            scope
        };
    })
});

export {
    makeExecCommandUpdater
};
