import React from 'react';
import * as Icon from './Icons.css';
import { ContainersKind, Containers } from "./constants";
import { HeaderView } from "../view/containers/HeaderView";
import { ContainerExtendedPanelContentView } from "../view/containers/ContentView";

export default {
    kind: ContainersKind,
    shortCut: {
        iconClassName: Icon.containers,
        text: Containers
    },
    extendedPanelData: {
        title: <HeaderView />,
        content: ContainerExtendedPanelContentView
    }
};
