import * as R from 'ramda';
import platform from 'platform';
import throttle from "lodash/throttle"; // eslint-disable-line node/file-extension-in-import
import debounce from "lodash/debounce"; // eslint-disable-line node/file-extension-in-import
import * as appActionTypes from './components/App/actionTypes';
import * as appActionCreators from './components/App/actionCreators/index';
import * as OldAppActionTypes from './redux/modules/actionTypes';
import { PASTE_FROM_SYSTEM_CLIPBOARD } from './components/Workspace/actionTypes';
import * as mouseUtils from "./utils/mouse";
import { throttleMouse } from "./utils/isDebug";
import { BLOCK_NATIVE_CONTEXT_MENU_CLASS, SHOW_NATIVE_CONTEXT_MENU_CLASS } from "./constants";

export default (dispatch: Dispatch) => {
    let mouseMouseAfRequestAction;
    const
        onMouseMove = ({ clientX: x, clientY: y }): void => {
            if (!mouseMouseAfRequestAction) {
                window.requestAnimationFrame(() => {
                    dispatch(mouseMouseAfRequestAction);
                    mouseMouseAfRequestAction = null;
                });
            }
            mouseMouseAfRequestAction = {
                type: OldAppActionTypes.WINDOW_MOUSE_MOVE,
                payload: { x, y }
            };
        },
        onKeyDownOrUpFactory = type => e => dispatch({
            type,
            payload: {
                keyCode: e.keyCode || e.charCode,
                ctrlKey: e.ctrlKey,
                shiftKey: e.shiftKey,
                targetClassName: e.target && e.target.className,
                preventDefault: () => e.preventDefault()
            }
        }),
        mousedownHandler = type => (position, { shiftKey, metaKey, ctrlKey, altKey }) => dispatch({
            type,
            payload: position,
            shiftKey,
            metaKey,
            ctrlKey,
            altKey
        }),
        checkForNativeContextMenuBlockedConditions = (el) => {
            return el.className && el.className.indexOf(BLOCK_NATIVE_CONTEXT_MENU_CLASS) > -1;
        },
        checkForNativeContextMenuAllowedConditions = (el) => {
            return el.className.indexOf(SHOW_NATIVE_CONTEXT_MENU_CLASS) > -1 ||
                el.tagName === "A" ||
                el.tagName === "BODY" ||
                el.contentEditable === 'true' ||
                el.id === 'root' ||
                !el.parentNode;
        },
        isNativeContextMenuAllowed = el => {
            if (checkForNativeContextMenuBlockedConditions(el)) {
                return false;
            } else if (checkForNativeContextMenuAllowedConditions(el)) {
                return true;
            } else {
                return isNativeContextMenuAllowed(el.parentNode);
            }
        },
        isInputRelatedArea = (e) => {
            return /^(?:INPUT|TEXTAREA)$/.test(e.target.tagName);
        },
        onResize = debounce((): void => {
            dispatch({
                type: appActionTypes.WINDOW_RESIZED_DEBOUNCED
            });
        }, 250),
        subscriptions = {
            resize: () => {
                dispatch({
                    type: OldAppActionTypes.WINDOW_RESIZED,
                    payload: {
                        width: window.innerWidth,
                        height: window.innerHeight
                    }
                });
                onResize();
            },
            mousemove: throttleMouse() ? throttle(onMouseMove, 500) : onMouseMove,
            mouseup: ({ clientX: x, clientY: y, shiftKey, metaKey, ctrlKey, altKey }): void => {
                dispatch(appActionCreators.mouseUpAC({ x, y }, shiftKey, metaKey, ctrlKey, altKey));
            },
            mousedown: e => mouseUtils.onMouseDown(
                e,
                mousedownHandler(OldAppActionTypes.APP_LEFT_MOUSE_DOWN),
                mousedownHandler(appActionTypes.APP_RIGHT_MOUSE_DOWN)
            ),
            contextmenu: e => {
                if (isInputRelatedArea(e) || isNativeContextMenuAllowed(e.target)) {
                    return true;
                }
                return e.preventDefault();
            },
            keydown: onKeyDownOrUpFactory(appActionTypes.KEY_DOWN),
            keyup: onKeyDownOrUpFactory(appActionTypes.KEY_UP),
            keypress: (e) => {
                const { key, ctrlKey, charCode } = e;
                // charCode will be zero or undefined for non printable characters
                // Safari gives charCode for Enter and Backpace, EDGE gives charcode for Enter
                if (charCode && charCode !== 8 && charCode !== 13) {
                    dispatch({
                        type: appActionTypes.KEY_PRESS,
                        payload: {
                            key,
                            ctrlKey,
                            targetClassName: e.target && e.target.className,
                            preventDefault: () => e.preventDefault(),
                        }
                    });
                }
            },
            blur: () => {
                dispatch({
                    type: appActionTypes.WINDOW_BLUR,
                    payload: null
                });
            }
        };

    R.forEachObjIndexed((callback, eventName) => {
        if (window.addEventListener) {
            // TODO: We may need to use capture mode of event handling for some of the TinyMCE iframe related changes
            // const useCapture = true;
            const useCapture = false;
            window.addEventListener(eventName, callback, useCapture);
        } else {
            window['on' + eventName] = callback;
        }
    }, subscriptions);

    /* populate with initial value */
    subscriptions.resize();

    /* handle copy paste in workspace */
    function handlePaste(e) {
        // WBTGEN-2543 - Firefox - cannot paste using key commands (cmd+v)
        // In Firefox, [event].target doesn't seem to be behaving as expected, hence using [event].explicitOriginalTarget (which is a Firefox only property)
        const
            eventTarget = e.explicitOriginalTarget || e.target,
            nodeName = eventTarget && eventTarget.nodeName ? String(eventTarget.nodeName) : null;

        if (nodeName && ['HTML', 'BODY', 'DIV'].indexOf(nodeName.toUpperCase()) > -1) {
            const clipboardData: any = e.clipboardData || window.clipboardData;
            dispatch({ type: PASTE_FROM_SYSTEM_CLIPBOARD, payload: clipboardData });
        }
    }

    if (platform.os.family === 'Windows' &&
        platform.name === 'IE' &&
        (platform.version && platform.version.indexOf('11.0') === 0)
    ) {
        /**
         * IMPORTANT:   Internet Explorer 11 fires "keypress" and not "onpaste" when user pastes using keyboard.
         *              Therefore separate key bindings have been added to handle cut copy paste.
         */
        const handler = (evt: { ctrlKey?: any; keyCode: number; }) => {
            if (evt.ctrlKey && evt.keyCode === 86 && window.clipboardData) {
                handlePaste(evt);
            }
        };

        document.addEventListener('keydown', handler, false);
    } else {
        const body: null | undefined | Record<string, any> = document.body;

        if (!body) {
            throw new Error('wrong env?');
        }

        // @ts-ignore
        if (window.addEventListener) {
            body.addEventListener('paste', handlePaste);
        } else {
            body.onpaste = handlePaste;
        }
    }
};
