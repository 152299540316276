import { applyMappers, makePlainMappers } from '../../utils';
import type { ShareButtonsComponent } from '../../../../src/components/oneweb/ShareButtons/flowTypes';

const
    plainPropsMapper = makePlainMappers({
        styleInfo: 'styleInfo',
        verbToDisplay: 'verbToDisplay',
        showFriends: 'showFriends',
        showFacebook: 'showFacebook',
        showFacebookShare: 'showFacebookShare',
        showTwitter: 'showTwitter',
        showLinkedIn: 'showLinkedIn',
        align: 'align',
        mobileHide: 'mobileHide',
        mobileDown: 'mobileDown'
    }),
    to = (componentData: any): ShareButtonsComponent => applyMappers(
        componentData,
        plainPropsMapper.to
    ),
    back = (componentData: ShareButtonsComponent): any => applyMappers(
        componentData,
        plainPropsMapper.back
    );

export { to, back };
