// @ts-nocheck
import { MobileMaxFontSize } from '../../../../../../components/Globalstyles/Text/constants';

const getStyleRef = (node) => {
    if (node.ref) {
        return node.ref;
    }
    return node.parent ? getStyleRef(node.parent) : '';
};

export default (defaultGlobalStyles: Array<Stylesheet>) => node => {
    const {
        styles
    } = node;
    const {
        size: fontSize
    } = styles;
    const ref = getStyleRef(node);
    const defaultGlobalStyle = defaultGlobalStyles.find(style => style.ref === ref);
    const size = fontSize || (defaultGlobalStyle ? defaultGlobalStyle.size : null);

    if (!size) {
        return false;
    }
    const mobileMaxSize = MobileMaxFontSize[ref] || 20;

    // mobile-oversized
    if (size <= mobileMaxSize && size >= 16) {
        return true;
    }

    return false;
};
