import { Theme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
    (theme: Theme) => ({
        titleContainer: {
            marginTop: "24px",
            [theme.breakpoints.up("sm")]: {
                marginTop: "44px",
            },
            [theme.breakpoints.up("md")]: {
                marginTop: "0px",
            },
            userSelect: "none",
        },
        title: {
            fontSize: "30px",
            lineHeight: "34px",
        },
        description: {
            fontSize: "18px",
            lineHeight: "32px",
            marginTop: "10px",
            [theme.breakpoints.down('md')]: {
                fontSize: "18px",
                lineHeight: "26px",
                marginTop: "12px",
            },
        },
    }),
    { name: "PagesHeader" }
);
