import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly } from "../../../../epics/makeCondition";
import { LINK_REDIRECT_VALUE_ACTION_TYPE } from "./valueActionType";
import { GO_TO_DASHBOARD_CLICKED_ACTION } from "../../actionTypes";
import unsavedChangesVAT from "../../../UnsavedChanges/epic/valueActionType";
import { CANCEL_UNSAVED_CHANGES, UNSAVED_CHANGES_CHECKED } from "../../../UnsavedChanges/actionTypes";
import { checkUnsavedChangesAction } from "../../../UnsavedChanges/actionCreators";
import { DASHBOARD_ROUTE } from '../../../../../../server/shared/routes';
import { navigateToLocationAction } from '../../../../redux/middleware/window/actionCreators';

export default makeEpic({
    defaultState: {
        checkUnsavedChanges: false
    },
    valueActionType: LINK_REDIRECT_VALUE_ACTION_TYPE,
    updaters: [
        {
            conditions: [GO_TO_DASHBOARD_CLICKED_ACTION],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        checkUnsavedChanges: true
                    },
                    multipleActionsToDispatch: [
                        checkUnsavedChangesAction()
                    ]
                };
            }
        },
        {
            conditions: [CANCEL_UNSAVED_CHANGES],
            reducer: ({ state }) => {
                if (state.checkUnsavedChanges) {
                    return {
                        state: {
                            ...state,
                            checkUnsavedChanges: false
                        }
                    };
                } else {
                    return { state };
                }
            }
        },
        {
            conditions: [
                receiveOnly(unsavedChangesVAT),
                UNSAVED_CHANGES_CHECKED
            ],
            reducer: ({ values: [hasChanges], state }) => {
                if (!state.checkUnsavedChanges) {
                    return { state };
                }

                // TODO: `hasChanges` seems to be always `false`, refactor if that's the case
                if (!hasChanges) {
                    window.skipUnsavedChangesPrompt = true;

                    return ({
                        state: {
                            ...state,
                            checkUnsavedChanges: false
                        },
                        actionToDispatch: navigateToLocationAction(DASHBOARD_ROUTE)
                    });
                }

                // TODO: this part seems to be a dead code since `hasChanges` is always `false`
                return {
                    state: {
                        ...state,
                        checkUnsavedChanges: false
                    }
                };
            }
        }
    ]
});
