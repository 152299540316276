import { WidgetsBingMapsKind } from './kind';
import { BingMapsView } from './view/index';
import { calcRenderProps } from './calcRenderProps';

// See wbtgen/src/view/oneweb/registry/preview.js
const WidgetsBingMapsPreviewConfig = {
    kind: WidgetsBingMapsKind,
    view: BingMapsView,
    calcProps: ({ component }) => calcRenderProps({ component, componentExtension: { isTransient: false } }),
};

export { WidgetsBingMapsPreviewConfig };
