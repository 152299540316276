import * as R from "ramda";
import type { AdjustmentHook } from '../../Workspace/epics/componentsEval/flowTypes';
import type { ButtonComponent, ButtonComponentExtension } from "./flowTypes";
import { MCTA_RESIZE_OPTIONS } from "./constants";

const
    hook: AdjustmentHook<ButtonComponent, Record<string, any>, ButtonComponentExtension> = (nextState) => {
        const
            {
                component,
                componentExtension,
                mhfCmpsData = {}
            } = nextState;
        let updatedComponentExtension = componentExtension, minButtonWidth, maxButtonWidth;
        if (componentExtension && mhfCmpsData[component.id]) {
            minButtonWidth =
                (component && component.modernLayoutOptions && MCTA_RESIZE_OPTIONS[component.modernLayoutOptions.size].width) || 0;
            maxButtonWidth = Math.max(minButtonWidth, componentExtension.offScreenRenderTextWidth);

            const
                setMinimumDimensions = R.assoc('minDimensions', { width: minButtonWidth, height: component.height }),
                setMaximumDimensions = R.assoc('maxDimensions', { width: maxButtonWidth, height: component.height }),
                finalComponentExtension = R.pipe(
                    setMinimumDimensions,
                    setMaximumDimensions
                )(updatedComponentExtension);

            return [component, finalComponentExtension];
        }
        return [component, componentExtension];
    },
    hookConfig = {
        hook,
        shouldCallHook: () => true
    };

export default hookConfig;
