import {
    FC_DELETE_ACTION_TYPES
} from '../actionTypes';
import type { ApiAction } from '../../../../middleware/api/flowTypes';
import { apiAction } from "../../../../middleware/api/actions";
import type { DeleteWebspaceResourceOptions } from '../flowTypes';

export const deleteWebspaceResourceApiAction = function (deleteOptions: DeleteWebspaceResourceOptions): ApiAction {
    return apiAction({
        types: FC_DELETE_ACTION_TYPES,
        endpoint: 'deleteWebspaceResource',
        endpointParams: [deleteOptions],
    });
};
