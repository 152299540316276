import * as R from 'ramda';
import valueActionType from "./valueActionType";
import makeEpic from "../../../../epics/makeEpic";
import * as ActionTypes from "../../actionTypes";
import * as Constants from "../../constants";
import { optional, receiveOnly } from "../../../../epics/makeCondition";
import browserDimensionsVAT from "../../../App/epics/browserDimensions/valueActionType";
import { getCurrentData } from "../splitData/utils";
import { MAKE_MOBILE_VIEW_EDITOR_INVISIBLE_ACTION } from "../reorder/actions";
import pageDatasetLoadedActionType from "../../../App/epics/pageDataset/pageDatasetLoadedActionType";
import type { ArrowsDisabledState, Sequence } from "../../flowTypes";
import { addInfoMessage } from "../../../Toaster/actionCreators";
import {
    MOBILE_EDITOR_REORDER_TIP_CLOSED,
    MOBILE_EDITOR_REORDER_TIP_CLOSE,
    MOBILE_EDITOR_REORDER_TIP_DO_NOT_SHOW,
    MOBILE_EDITOR_GROUP_TIP_DO_NOT_SHOW,
    MOBILE_EDITOR_TOGGLE_TIP_CHKBOX,
    SHOW_TOOLTIP_FOR_MVE_GROUP
} from "../../../Tooltip/actionTypes";
import localStorage from "../../../../utils/localStorage";
import mobileViewEditorVAT from "../reorder/valueActionType";
import { ReceiveOnlyComponentsMap } from "../../../Workspace/epics/componentsEval/selectorActionTypes";
import { getSelectedCmpTopWRTContent } from '../reorder/scrollTopWhenUsingArrows';
import { getCmpHeights } from '../../view/MobileEditorActions';
import { removeOutlineAndSelectionMobileViewEditorAC } from '../../actionCreators';
import { PAGE_TREE_LOAD_PAGE } from "../../../PagesTree/actionTypes";
import { isUnlockFromBottomEnabled } from '../../calcLockToBottomEnablement';
import { SELECT_MVE_HEADER_ACTION, SET_MVE_OPEN_MENU_ACTION } from "./actions";
import { Lit } from "../../../../lit";
import { PROPERTIES_PANEL_NAVIGATE_BACK } from '../../../PropertiesPanel/epic/actionTypes';
import { MveHeaderPpPageId } from '../../header/propertiesPanel/pages/MveHeaderPpPageId';
import propertiesPanelValueActionType from '../../../PropertiesPanel/epic/valueActionType';
import { getCurrentPageId } from '../../../PropertiesPanel/epic/utils/navigation';
import getCmpTypeById from "../../getCmpTypeById";
import { MobileEditorGroupTip } from "../../../Tooltip/ids";
import { isFooterSection, isHeaderOrFooterSection, isHeaderSection } from "../../../oneweb/Section/utils";
import { REDO_BUTTON_PRESSED, UNDO_BUTTON_PRESSED } from '../../../TopBar/actionTypes';

export const calcDisableArrows = (children: Array<string>, cmpId: string, componentsMap: Record<string, any> = {}, currentData: Sequence): ArrowsDisabledState => { // eslint-disable-line max-len
        const firstChildCmp = componentsMap[children[0]];
        const isFirstCmpHeader = firstChildCmp && isHeaderSection(firstChildCmp);
        const isHeaderVisible = isFirstCmpHeader && currentData[firstChildCmp.id];
        const lastChildCmp = componentsMap[children[children.length - 1]];
        const isLastChildCmpFooter = lastChildCmp && isFooterSection(lastChildCmp);
        const isFooterVisible = isLastChildCmpFooter && currentData[lastChildCmp.id];
        // visible children
        const realChildren = (isFirstCmpHeader || isLastChildCmpFooter)
            ? children.filter(child => currentData[child])
            : children;
        if (isFirstCmpHeader && isLastChildCmpFooter) {
            const lastPageSectionIndex = realChildren.length - (isFooterVisible ? 2 : 1);
            const firstPageSectionIndex = isHeaderVisible ? 1 : 0;
            return {
                disableDownArrow: realChildren[lastPageSectionIndex] === cmpId,
                disableUpArrow: realChildren[firstPageSectionIndex] === cmpId
            };
        }
        if (isFirstCmpHeader && !isLastChildCmpFooter) {
            const firstPageSectionIndex = isHeaderVisible ? 1 : 0;
            return {
                disableDownArrow: realChildren[realChildren.length - 1] === cmpId,
                disableUpArrow: realChildren[firstPageSectionIndex] === cmpId
            };
        }
        if (!isFirstCmpHeader && isLastChildCmpFooter) {
            const lastPageSectionIndex = realChildren.length - (isFooterVisible ? 2 : 1);
            return {
                disableDownArrow: realChildren[lastPageSectionIndex] === cmpId,
                disableUpArrow: realChildren[0] === cmpId
            };
        }
        return {
            disableDownArrow: children[children.length - 1] === cmpId,
            disableUpArrow: children[0] === cmpId
        };
    },
    calculateBgPositionAfterMouseMove = ([x, y]: Array<string>, position: Record<string, any>, selectedCmpPosition: Record<string, any>,
        selectedComponentRect: Record<string, any>) => {
        const diffX = selectedCmpPosition.x - position.x;
        const diffY = selectedCmpPosition.y - position.y;
        let newX = diffX === 0 ? parseFloat(x) : (parseFloat(x) + (diffX / selectedComponentRect.width) * 100);
        let newY = diffY === 0 ? parseFloat(y) : (parseFloat(y) + (diffY / selectedComponentRect.height) * 100);
        newX = Math.round(Math.max(0, Math.min(100, newX)) * 100) / 100;
        newY = Math.round(Math.max(0, Math.min(100, newY)) * 100) / 100;
        return [`${newX}%`, `${newY}%`];
    };

const
    TOP_MARGIN = 40,
    BOTTOM_MARGIN = 40,
    ARROW_HEIGHT = 36,  // refer MobileViewEditor.css
    ARROWS_LEFT_MARGIN = 25,
    REORDER_TIP_LEFT_MARGIN = 308,
    localStorageReorderTipKey = 'mveShowReorderTip',
    localStorageGroupTipKey = 'mveDoNotShowGroupTipKey',
    computeReorderArrowsTop = ({ compTop, compHeight }, { top, bottom }) => {
        let arrowsComputedTop = compTop;
        if (compHeight > TOP_MARGIN + BOTTOM_MARGIN + ARROW_HEIGHT) {
            arrowsComputedTop += TOP_MARGIN;
        } else {
            arrowsComputedTop += compHeight < ARROW_HEIGHT ?
                ((-1) * ((ARROW_HEIGHT - compHeight) / 2)) : ((compHeight - ARROW_HEIGHT) / 2);
        }
        // to maintain arrows within the limit of MV Container
        if (arrowsComputedTop < top) {
            arrowsComputedTop = top;
        } else if (arrowsComputedTop > bottom) {
            arrowsComputedTop = bottom;
        }
        return arrowsComputedTop;
    },
    computeReorderArrowsLeft = (browserWidth, rootContainerWidth) =>
        (((browserWidth + rootContainerWidth) / 2) + ARROWS_LEFT_MARGIN),
    computeReorderTipLeft = (browserWidth, rootContainerWidth) => {
        const left = ((browserWidth - rootContainerWidth) / 2) - REORDER_TIP_LEFT_MARGIN;
        return left > 0 ? left : 0;
    },
    getComponentKind = (componentsMap, cmpId) => {
        return R.path([cmpId, 'kind'])(componentsMap);
    },
    calcArrowsData = (selectedCmpRect, browserWidth, rootContainerRect, kind, mobileDown, isHeaderOrFooter) => {
        return {
            reorderArrowsShow: !mobileDown && !isHeaderOrFooter,
            hideIconShow: !Constants.CmpsKindsHideIconNA[kind],
            unlockIconShow: mobileDown && !Constants.CmpsKindUnlockIconNA[kind],
            mobileEditorActionsPosition: {
                top: computeReorderArrowsTop(selectedCmpRect, rootContainerRect),
                left: computeReorderArrowsLeft(browserWidth, rootContainerRect.width)
            }
        };
    },

    arrowPositionUpdater = (selectedCmpRect, browserWidth, state, isScrolling, kind, mobileDown, isHeaderOrFooter) => {
        if (isScrolling && state.arrowHover) {
            return { state };
        }
        if (state.selectedCmpId) {
            return {
                state: {
                    ...state,
                    ...calcArrowsData(selectedCmpRect, browserWidth, state.rootContainerRect, kind, mobileDown, isHeaderOrFooter)
                }
            };
        }
        return { state };
    },
    defaultEpicState = {
        headerHeight: 50,
        mouseOverCmpId: null,
        selectedCmpId: null,
        previousSelectedCmpId: null,
        arrowHover: false,
        disableUpArrow: false,
        disableDownArrow: false,
        rootContainerRect: {},
        reorderArrowsShow: false,
        mobileEditorActionsPosition: null,
        hideIconShow: false,
        unlockIconShow: false,
        isDragging: false,
        reorderTipPosLeft: 183,
        contentScrollTop: 0,
        updateScrollTop: false,
        newScrollTopValue: 0,
        showMobileDownMask: false,
        isMobileDownMaskSelected: false,
        selectedComponentRect: null,
        maskBorderStyle: {
            showCustomTop: false,
            hideCustomBottom: false
        },
        showCmpReorderTip: false,
        isReorderTipClosed: true,
        [Lit.isHeaderSelected]: false,
        [Lit.isMenuOpen]: false,
        isMVEEditMode: false,
        isMouseDown: false,
        selectedCmpBgPosition: [],
    },
    getMobileDownTreeTop = function (styles, mdStartFromId) {
        const mobileDownTreeStyle = mdStartFromId ? styles[mdStartFromId] : {};
        return (mobileDownTreeStyle && mobileDownTreeStyle.marginTop) || 0;
    },
    calculateMaskBorderStyle = function (state, elemTop, elemBottom, mobileDownTreeTop) {
        const
            {
                rootContainerRect: { top: containerTop, bottom: containerBottom }
            } = state,
            showCustomTop = elemTop + mobileDownTreeTop < containerTop,
            /* elemTop >= containerBottom: Custom Bottom is hidden if entire mobile down is below view port */
            /* elemBottom < containerBottom: WBTGEN-8498: Custom Bottom is hidden if mobile down is in the view port and much above the bottom */
            hideCustomBottom = elemTop >= containerBottom || elemBottom < containerBottom;
        return {
            showCustomTop,
            hideCustomBottom
        };
    },
    deselectComponentReducer = state => {
        if (state.isDragging) return state;

        return {
            ...state,
            mouseOverCmpId: null,
            selectedCmpId: null,
            previousSelectedCmpId: null,
            showMobileDownMask: false,
            isMobileDownMaskSelected: false,
            reorderArrowsShow: false,
            hideIconShow: false,
            unlockIconShow: false,
            selectedComponentRect: null,
        };
    };

export default makeEpic({
    defaultState: {
        ...defaultEpicState
    },
    valueActionType,
    updaters: [
        {
            conditions: [
                receiveOnly(browserDimensionsVAT),
                pageDatasetLoadedActionType
            ],
            reducer: ({ values: [{ width }], state }) => {
                const reorderTipPosLeft = state.rootContainerRect ?
                    computeReorderTipLeft(
                        width, state.rootContainerRect.width
                    ) : defaultEpicState.reorderTipPosLeft;

                return {
                    state: {
                        ...defaultEpicState,
                        reorderTipPosLeft
                    }
                };
            }
        },
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                ActionTypes.MOBILE_EDITOR_COMPONENT_MOUSE_MOVE
            ],
            reducer: ({ values: [componentsMap, { cmpId, position }], state }) => {
                if (state.mouseOverCmpId === cmpId) {
                    if (state.isMVEEditMode && state.isMouseDown) {
                        const currentBgPosition = state.selectedCmpBgPosition ||
                            R.path([cmpId, 'mobileSettings', 'position'], componentsMap) || ['50%', '50%'];
                        const newBgPosition = calculateBgPositionAfterMouseMove(currentBgPosition, position,
                            state.selectedCmpPosition, state.selectedComponentRect);
                        return {
                            state: {
                                ...state,
                                selectedCmpPosition: position,
                                selectedCmpBgPosition: newBgPosition
                            }
                        };
                    }
                    return { state };
                }

                return {
                    state: {
                        ...state,
                        selectedCmpPosition: position,
                        mouseOverCmpId: cmpId
                    }
                };
            }
        },
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                ActionTypes.MOBILE_EDITOR_COMPONENT_MOUSE_DOWN
            ],
            reducer: ({ values: [componentsMap, { cmpId, componentRect, position }], state }) => {
                if (cmpId === Constants.mobileDownMask) {
                    return {
                        state: {
                            ...state,
                            mouseOverCmpId: null,
                            selectedCmpId: null,
                            previousSelectedCmpId: null,
                            isMobileDownMaskSelected: true,
                            selectedComponentRect: null
                        }
                    };
                }
                return {
                    state: {
                        ...state,
                        mouseOverCmpId: cmpId,
                        selectedCmpId: cmpId,
                        selectedCmpPosition: position,
                        previousSelectedCmpId: cmpId && state.previousSelectedCmpId,
                        showMobileDownMask: false,
                        isMobileDownMaskSelected: false,
                        selectedComponentRect: componentRect || null,
                        isMouseDown: true,
                        selectedCmpBgPosition: R.path([cmpId, 'mobileSettings', 'position'], componentsMap) || ['50%', '50%']
                    }
                };
            }
        },
        ...[
            ActionTypes.MOBILE_EDITOR_COMPONENT_MOUSE_UP,
            ActionTypes.MOBILE_EDITOR_COMPONENT_MOUSE_UP_OUTSIDE
        ].map(actionType => ({
            conditions: [actionType],
            reducer: ({ state }) => {
                let actionToDispatch: Action | null = null;
                if (state.isMVEEditMode && state.selectedCmpId && state.selectedCmpBgPosition) {
                    actionToDispatch = {
                        type: ActionTypes.MOBILE_EDITOR_CHANGE_BG_POSITION,
                        payload: {
                            cmpId: state.selectedCmpId,
                            selectedCmpBgPosition: state.selectedCmpBgPosition
                        }
                    };
                }
                return {
                    state: {
                        ...state,
                        isMouseDown: false
                    },
                    actionToDispatch
                };
            },
        })),
        {
            conditions: [ActionTypes.MOBILE_EDITOR_REMOVE_OUTLINE],
            reducer: ({ state }) => {
                return (state.mouseOverCmpId === null ? ({ state }) : ({
                    state: {
                        ...state,
                        mouseOverCmpId: null
                    }
                }));
            }
        },
        {
            conditions: [
                receiveOnly(browserDimensionsVAT),
                ActionTypes.MOBILE_EDITOR_ROOT_CONTAINER_RECT
            ],
            reducer: ({ values: [{ width }, rootContainerRect], state }) => ({
                state: {
                    ...state,
                    rootContainerRect,
                    reorderTipPosLeft: computeReorderTipLeft(width, rootContainerRect.width)

                }
            })
        },
        {
            conditions: [ActionTypes.MOBILE_EDITOR_REMOVE_OUTLINE_AND_SELECTION],
            reducer: ({ state }) => ({
                state: deselectComponentReducer(state),
            }),
        },
        {
            conditions: [ActionTypes.MOBILE_EDITOR_WORKSPACE_UNFOCUSED],
            reducer: ({ state }) => {
                return {
                    state,
                    actionToDispatch: removeOutlineAndSelectionMobileViewEditorAC()
                };
            }
        },
        {
            conditions: [ReceiveOnlyComponentsMap, ActionTypes.MOBILE_EDITOR_COMPONENT_DRAGGING_STARTED],
            reducer: ({ values: [componentsMap, { contentScrollTop, data }], state }) => {
                const { currentData, parentId } = getCurrentData({ data,
                    mobileDownData: data,
                    selectedCmpId: state.mouseOverCmpId });
                const { disableDownArrow, disableUpArrow } = calcDisableArrows(currentData[parentId],
                    state.mouseOverCmpId, componentsMap, currentData);
                let showReorderTip = localStorage.get(localStorageReorderTipKey);
                if (showReorderTip !== false) {
                    showReorderTip = disableUpArrow && disableDownArrow && state.isReorderTipClosed;
                }
                return ({
                    state: {
                        ...state,
                        selectedCmpId: state.mouseOverCmpId,
                        reorderArrowsShow: false,
                        hideIconShow: false,
                        unlockIconShow: false,
                        isDragging: true,
                        contentScrollTop,
                        disableDownArrow,
                        disableUpArrow,
                        showCmpReorderTip: showReorderTip,
                        selectedComponentRect: null
                    }
                });
            }

        },
        {
            conditions: [ActionTypes.MOBILE_EDITOR_CMP_REORDER_TIP_SHOWN],
            reducer: ({ state }) => {
                return ({
                    state: {
                        ...state,
                        showCmpReorderTip: false,
                        isReorderTipClosed: false
                    }
                });
            }
        },
        {
            conditions: [ActionTypes.MOBILE_EDITOR_ENABLE_EDIT_MODE],
            reducer: ({ state }) => {
                return ({
                    state: {
                        ...state,
                        isMVEEditMode: true
                    }
                });
            }
        },
        ...[
            ActionTypes.MOBILE_EDITOR_MOVE_DOWN_ARROW_CLICKED,
            ActionTypes.MOBILE_EDITOR_MOVE_UP_ARROW_CLICKED,
            ActionTypes.MOBILE_EDITOR_ACTION_HIDE_CLICKED,
            ActionTypes.MOBILE_EDITOR_WORKSPACE_UNFOCUSED,
            ActionTypes.MOBILE_EDITOR_EXPAND_COLLAPSE_HIDDEN_PANEL,
            ActionTypes.MOBILE_EDITOR_MOUSE_ACTION_HIDDEN_PANEL,
            ActionTypes.MOBILE_EDITOR_COMPONENT_DRAGGING_STARTED,
            SELECT_MVE_HEADER_ACTION,
            REDO_BUTTON_PRESSED,
            UNDO_BUTTON_PRESSED,
        ].map(actionType => ({
            conditions: [actionType],
            reducer: ({ state }) => {
                let actionToDispatch: Action | null = null;
                if (state.isMVEEditMode && state.selectedCmpId && state.selectedCmpBgPosition) {
                    actionToDispatch = {
                        type: ActionTypes.MOBILE_EDITOR_CHANGE_BG_POSITION,
                        payload: {
                            cmpId: state.selectedCmpId,
                            selectedCmpBgPosition: state.selectedCmpBgPosition
                        }
                    };
                }
                return {
                    state: {
                        ...state,
                        isMVEEditMode: false
                    },
                    actionToDispatch
                };
            },
        })),
        {
            conditions: [MOBILE_EDITOR_REORDER_TIP_CLOSED],
            reducer: ({ state }) => {
                return ({
                    state: {
                        ...state,
                        isReorderTipClosed: true,
                    }
                });
            }
        },
        {
            conditions: [MOBILE_EDITOR_REORDER_TIP_DO_NOT_SHOW],
            reducer: ({ values: [chkBoxEnabled], state }) => {
                const doNotShowReorderTip = !chkBoxEnabled;
                localStorage.set(localStorageReorderTipKey, doNotShowReorderTip);
                const payload: boolean = chkBoxEnabled;
                return ({
                    state: {
                        ...state,
                        showCmpReorderTip: doNotShowReorderTip
                    },
                    actionToDispatch: {
                        type: MOBILE_EDITOR_TOGGLE_TIP_CHKBOX,
                        payload
                    }
                });
            }
        },
        {
            conditions: [MOBILE_EDITOR_GROUP_TIP_DO_NOT_SHOW],
            reducer: ({ values: [chkBoxEnabled], state }) => {
                localStorage.set(localStorageGroupTipKey, chkBoxEnabled);

                const payload: boolean = chkBoxEnabled;
                return ({
                    state,
                    actionToDispatch: {
                        type: MOBILE_EDITOR_TOGGLE_TIP_CHKBOX,
                        payload
                    }
                });
            }
        },
        {
            conditions: [ActionTypes.MOBILE_EDITOR_COMPONENT_DRAGGING_ENDED],
            reducer: ({ state }) => ({
                state: {
                    ...state,
                    isDragging: false
                }
            })
        },
        {
            conditions: [ActionTypes.MOBILE_EDITOR_ARROW_MOUSE_OVER],
            reducer: ({ state }) => ({
                state: {
                    ...state,
                    arrowHover: true
                }
            })
        },
        {
            conditions: [ActionTypes.MOBILE_EDITOR_ARROW_MOUSE_OUT],
            reducer: ({ state }) => ({
                state: {
                    ...state,
                    arrowHover: false
                }
            })
        },
        {
            conditions: [
                optional(ActionTypes.BACK_TO_DESKTOP_EDITOR_FROM_MOBILE_EDITOR),
                optional(MAKE_MOBILE_VIEW_EDITOR_INVISIBLE_ACTION),
                optional(PAGE_TREE_LOAD_PAGE)
            ],
            reducer: () => ({ state: { ...defaultEpicState, show: false } })
        },
        {
            conditions: [
                receiveOnly(browserDimensionsVAT),
                ActionTypes.MOBILE_EDITOR_COMPONENT_SELECT,
                ReceiveOnlyComponentsMap,
                receiveOnly(mobileViewEditorVAT)
            ],
            reducer: ({
                values: [{ width }, { mobileData, selectedCmpRect, cmpId, componentRect },
                    componentsMap, { groupsForView, data }],
                state
            }) => {
                const { currentData, parentId } = getCurrentData({ ...mobileData, selectedCmpId: cmpId }),
                    component = componentsMap[cmpId],
                    showGroupTip = (!localStorage.get(localStorageGroupTipKey) &&
                        getCmpTypeById(cmpId) === Constants.cmpTypes.group) && state.previousSelectedCmpId !== cmpId;
                let actionToDispatch: Action | null = null;
                if (showGroupTip) {
                    const { top, left, offsetLeft, height } = componentRect;
                    actionToDispatch = {
                        type: SHOW_TOOLTIP_FOR_MVE_GROUP,
                        payload: {
                            id: MobileEditorGroupTip,
                            position: {
                                x: left - offsetLeft,
                                y: top + (height / 2)
                            }
                        }
                    };
                }

                return {
                    state: {
                        ...state,
                        selectedCmpId: cmpId,
                        previousSelectedCmpId: cmpId,
                        ...calcArrowsData(selectedCmpRect, width, state.rootContainerRect,
                            getComponentKind(componentsMap, cmpId),
                            isUnlockFromBottomEnabled(cmpId, componentsMap, groupsForView, data),
                            component && isHeaderOrFooterSection(component)),
                        ...calcDisableArrows(currentData[parentId], cmpId, componentsMap, currentData),
                        selectedComponentRect: componentRect,
                        isMVEEditMode: cmpId === state.previousSelectedCmpId ? state.isMVEEditMode : false,
                    },
                    actionToDispatch
                };
            }
        },
        {
            conditions: [
                ActionTypes.MOBILE_EDITOR_COMPONENT_UPDATE_ARROWS_AFTER_MOVE
            ],
            reducer: ({ values: [arrowsUpdate], state }) => ({
                state: {
                    ...state,
                    ...arrowsUpdate
                }
            })
        },
        {
            conditions: [
                ActionTypes.MOBILE_EDITOR_UPDATED_SCROLL_TOP
            ],
            reducer: ({ values: [{ newScrollTopValue, updateScrollTop }], state }) => {
                return ({
                    state: {
                        ...state,
                        updateScrollTop,
                        newScrollTopValue
                    }
                });
            }
        },
        {
            conditions: [
                ActionTypes.MOBILE_EDITOR_UPDATE_SCROLL_FOR_CMP,
                receiveOnly(mobileViewEditorVAT)
            ],
            reducer: ({ values: [{ cmpId }, { root, data, styles }], state }) => {
                const newScrollTopValue = getSelectedCmpTopWRTContent(root, data, getCmpHeights(), cmpId, styles);
                return ({
                    state: {
                        ...state,
                        updateScrollTop: true,
                        newScrollTopValue
                    }
                });
            }
        },
        {
            conditions: [
                receiveOnly(browserDimensionsVAT),
                ActionTypes.MOBILE_EDITOR_UPDATE_ARROW_FOR_SCROLL,
                ReceiveOnlyComponentsMap,
                receiveOnly(mobileViewEditorVAT)
            ],
            reducer: ({ values: [{ width }, selectedCmpRect, componentsMap, { groupsForView, data }], state }) => {
                const component = componentsMap[state.selectedCmpId];
                return ({
                    state: {
                        ...arrowPositionUpdater(selectedCmpRect, width, state, true,
                            getComponentKind(componentsMap, state.selectedCmpId),
                            isUnlockFromBottomEnabled(state.selectedCmpId, componentsMap, groupsForView, data),
                            component && isHeaderOrFooterSection(component)).state,
                        selectedComponentRect: selectedCmpRect.elemRect
                    },
                    actionToDispatch: !state.isReorderTipClosed ? {
                        type: MOBILE_EDITOR_REORDER_TIP_CLOSE
                    } : null
                });
            }
        },
        {
            conditions: [
                receiveOnly(mobileViewEditorVAT),
                ActionTypes.MOBILE_DOWN_ON_MOUSE_OVER,
            ],
            reducer: ({ values: [{ styles, mdStartFromId },
                { boundingClientRect: { top: elemTop, bottom: elemBottom } }], state }) => {
                const maskBorderStyle = calculateMaskBorderStyle(state, elemTop, elemBottom,
                    getMobileDownTreeTop(styles, mdStartFromId));
                return {
                    state: {
                        ...state,
                        showMobileDownMask: true,
                        maskBorderStyle
                    }
                };
            }
        },
        {
            conditions: [ActionTypes.MOBILE_DOWN_ON_MOUSE_OUT],
            reducer: ({ state }) => {
                if (state.isMobileDownMaskSelected) {
                    return { state };
                }
                return {
                    state: {
                        ...state,
                        showMobileDownMask: false
                    }
                };
            }
        },
        {
            conditions: [
                receiveOnly(mobileViewEditorVAT),
                ActionTypes.MOBILE_EDITOR_VIEW_SCROLLED
            ],
            reducer: ({ values: [{ styles, mdStartFromId },
                { boundingClientRect: { top: elemTop, bottom: elemBottom } }], state }) => {
                if (state.showMobileDownMask) {
                    const maskBorderStyle = calculateMaskBorderStyle(state, elemTop, elemBottom,
                        getMobileDownTreeTop(styles, mdStartFromId));
                    return {
                        state: {
                            ...state,
                            maskBorderStyle
                        }
                    };
                }
                return { state };
            }
        },
        {
            conditions: [
                receiveOnly(ActionTypes.MOBILE_EDITOR_COMPONENT_SELECT),
                browserDimensionsVAT,
                receiveOnly(mobileViewEditorVAT)
            ],
            reducer: ({ values: [{ selectedCmpRect, componentsMap }, { width }, { groupsForView, data }], state }) =>
                arrowPositionUpdater(selectedCmpRect, width, { ...state },
                    false, getComponentKind(componentsMap, state.selectedCmpId),
                    isUnlockFromBottomEnabled(state.selectedCmpId, componentsMap, groupsForView, data),
                    componentsMap && state.selectedCmpId && componentsMap[state.selectedCmpId]
                    && isHeaderOrFooterSection(componentsMap[state.selectedCmpId]))
        },
        {
            conditions: [
                browserDimensionsVAT,
                receiveOnly(ActionTypes.MOBILE_EDITOR_ROOT_CONTAINER_RECT)
            ],
            reducer: ({ values: [{ width }], state }) => ({
                state: {
                    ...state,
                    reorderTipPosLeft: computeReorderTipLeft(width, state.rootContainerRect.width)
                }
            })
        },
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                ActionTypes.MOBILE_EDITOR_DELETE_KEY_PRESSED,
            ],
            reducer: ({ values: [componentsMap], state }) => {
                const selectedCmpId = state.selectedCmpId;
                if (!selectedCmpId) {
                    return { state };
                }
                const multipleActionsToDispatch: Action[] = [],
                    selectedCmp = componentsMap[selectedCmpId];
                if (selectedCmp && Constants.CmpsKindsHideIconNA[selectedCmp.kind]) {
                    const cmpMsg = Constants.CmpsKindsHideDisabledMsgs[selectedCmp.kind];
                    multipleActionsToDispatch.push(addInfoMessage(
                        cmpMsg.msgKey,
                        cmpMsg.defaultMsg
                    ));
                } else {
                    multipleActionsToDispatch.push({
                        type: ActionTypes.MOBILE_EDITOR_HIDE_COMPONENTS,
                        payload: {
                            componentId: selectedCmpId
                        }
                    }, removeOutlineAndSelectionMobileViewEditorAC());
                }
                return { state, multipleActionsToDispatch };
            }
        },
        {
            conditions: [
                ActionTypes.UPDATE_SCROLL_TOP_VALUE
            ],
            reducer: ({ values: [{ contentScrollTop }], state }) => ({
                state: {
                    ...state,
                    newScrollTopValue: contentScrollTop,
                    contentScrollTop
                }
            })
        },

        // mve header selection
        {
            conditions: [SELECT_MVE_HEADER_ACTION],
            reducer: ({ state }) => ({
                state: {
                    ...deselectComponentReducer(state),
                    [Lit.isHeaderSelected]: true,
                },
            }),
        },
        ...[
            ActionTypes.MOBILE_EDITOR_COMPONENT_SELECT,
            ActionTypes.MOBILE_EDITOR_REMOVE_OUTLINE_AND_SELECTION,
        ].map(actionType => ({
            conditions: [actionType],
            reducer: ({ state }) => ({
                state: {
                    ...state,
                    [Lit.isHeaderSelected]: false,
                    [Lit.isMenuOpen]: false,
                },
            }),
        })),
        {
            conditions: [SET_MVE_OPEN_MENU_ACTION],
            reducer: ({ values: [isOpen], state }) => ({
                state: {
                    ...state,
                    [Lit.isMenuOpen]: isOpen,
                },
            }),
        },
        {
            conditions: [receiveOnly(propertiesPanelValueActionType), PROPERTIES_PANEL_NAVIGATE_BACK],
            reducer: ({ values: [{ state: { navigation } }], state }) => {
                const currentPageId = getCurrentPageId(navigation);
                if (currentPageId === MveHeaderPpPageId.MAIN) {
                    return {
                        state: {
                            ...state,
                            [Lit.isMenuOpen]: false,
                        },
                    };
                }
                return {
                    state
                };
            },
        }
    ]
});
