import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { ONLINE_SHOP_DASHBOARD_DIALOG_Z_INDEX } from "../OnlineShop/OnlineShopDashboard/constants";

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        zIndex: ONLINE_SHOP_DASHBOARD_DIALOG_Z_INDEX + 1
    },
    paper: {
        [theme.breakpoints.down('sm')]: {
            position: "absolute",
            bottom: 0,
            maxWidth: "100%",
            margin: 0,
            backgroundColor: "#E6F2FA",
        },
    },
    container: {
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            padding: "64px 24px 24px"
        }
    },
    closeButton: {
        position: "absolute",
        right: "12px",
        top: "12px",
        cursor: "pointer",
    },
    dialogContent: {
        display: "flex",
        flexDirection: "row",
        margin: "0px",
        alignItems: "center",
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
        },
    },
    dialogContentInfo: {
        display: "flex",
        flexDirection: "column",
        width: "500px",
        margin: "0px",
        gap: "16px",
        padding: "48px",
        [theme.breakpoints.down('md')]: {
            width: "500px",
            gap: "16px"
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: "0",
            flexDirection: "column",
            gap: "16px",
        },
    },
    title: {
        fontSize: "24px",
        lineHeight: "30px",
        [theme.breakpoints.down('md')]: {
            fontSize: "24px",
            lineHeight: "30px"
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: "18px",
            lineHeight: "24px",
            marginTop: "16px",
            fontWeight: "600",
        },
    },
    description: {
        fontSize: "14px",
        lineHeight: "22px",
        marginBottom: "0px",
        letterSpacing: "0.25px",
        [theme.breakpoints.down('sm')]: {
            lineHeight: "22px",
        },
    },
    dialogActions: {
        display: "flex",
        flexDirection: "row",
        gap: "16px",
        [theme.breakpoints.down('sm')]: {
            flexFlow: "column-reverse",
            gap: "10px"
        }
    },
    closeBtn: {
        color: '#3C3C3C',
        backgroundColor: 'white',
        borderColor: '#BBBBBB',
        padding: "0px 30px",
        fontSize: "14px",
        lineHeight: "40px",
        "&:hover, &:focus, &:active": {
            color: '#3C3C3C',
            backgroundColor: '#eeeeee',
            borderColor: '#eeeeee',
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: "0px",
        },
    },
    learnMoreBtn: {
        fontSize: "14px",
        lineHeight: "46px",
        fontWeight: 600,
        padding: "0px 30px",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: "0px",
        },
    },
    bookingsIllustrationImage: {
        [theme.breakpoints.down('sm')]: {
            alignSelf: "flex-start"
        },
    }
}), { name: "BookingsTrialDialog" });
