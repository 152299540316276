import React from 'react';
import * as R from 'ramda';
import { Dialog, DialogBody } from '../../../view/common/dialogs/baseDialog/index';
import VerticalSpacer from '../../../view/common/VerticalSpacer';
import { getCenterPositionForDialog } from '../../DialogManager/utility';
import stylesheetsEpic from '../../Workspace/epics/stylesheets/index';
import * as selectors from '../../Workspace/epics/stylesheets/selectors';
import * as path from '../../../mappers/path';
import CharacterSpacingView from './dialogView';
import type { CharacterSpacingDialogPropTypes } from './flowTypes';
import type { BrowserDimensions } from "../../../redux/modules/children/dimensions/index";

export default (kind: string, type: string, configurationDialogId: string) => {
    const
        DEFAULT_CHARACTER_SPACING = 0,
        width = 312,
        height = 175,
        CharacterSpacingDialog = ({
            globalStyles,
            spacingChangeAction,
            lineHeightChangeAction,
            dispatch
        }: CharacterSpacingDialogPropTypes) => {
            const
                stylesheet = R.pipe(
                    selectors.getAllStylesheets,
                    selectors.getStylesByType(kind),
                    R.filter(style => style.ref === type),
                    R.head
                )(globalStyles),
                spacing = R.propOr(DEFAULT_CHARACTER_SPACING, path.letterSpacing)(stylesheet),
                lineHeight = R.propOr(DEFAULT_CHARACTER_SPACING, path.lineHeight)(stylesheet),
                spacingProps = {
                    spacing,
                    spacingChangeAction,
                    lineHeight,
                    lineHeightChangeAction,
                    dispatch
                };

            return (
                <Dialog>
                    <DialogBody>
                        <VerticalSpacer y={40} />
                        <CharacterSpacingView {...spacingProps} />
                    </DialogBody>
                </Dialog>
            );
        };

    return {
        component: CharacterSpacingDialog,
        confFactory: ({ browserWidth, browserHeight }: BrowserDimensions) => ({
            configurationDialogId,
            position: getCenterPositionForDialog(width, height, browserWidth, browserHeight),
            modal: false,
            dimensions: { width, height }
        }),
        dependsOn: {
            globalStyles: stylesheetsEpic.reducer
        }
    };
};
