import * as actionMapper from '../Common/action';
import defaultImageData from '../../../../src/components/SortImagesDialog/defaultImageData';
import type { ImageSliderIn } from './flowTypes';
import type { ImageSliderComponent } from "../../../../src/components/oneweb/ImageSlider/flowTypes";

export const
    to = ({ assets, slides }: ImageSliderIn) => {
        let images: any = [];

        if (assets) {
            images = assets.map(({ action, caption, ...rest }) => ({
                asset: rest,
                caption,
                title: rest.title || rest.altText || "",
                action: action ? actionMapper.to(action) : null
            }));
        } else if (slides) {
            images = slides.map(slide => ({
                ...defaultImageData,
                ...slide,
                title: slide.title || slide.altText || "",
                action: slide.action && actionMapper.to(slide.action)
            }));
        }

        return { images };
    },
    back = ({ images }: ImageSliderComponent) => ({
        slides: images.map(image => ({
            ...image,
            action: image.action && actionMapper.back(image.action)
        }))
    });
