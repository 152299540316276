import type { ContactFormComponent } from "../flowTypes";

export default (component: ContactFormComponent, { payload: { email, subject } }: any): ContactFormComponent => {
    // For WSB Trial, recipientEmail in the ContactForm component defaults to empty string in Template data,
    // but is converted to NULL when being stored in AWS DynamoDB. Hence the email edit dialog for the contact
    // form components fails. Thus the recipientEmail must always be of type string only.
    const recipientEmail = (email || "");

    return { ...component, subject, recipientEmail };
};
