import React from "react";
import Page from "../../../../PropertiesPanel/view/PropertiesPage";
import injectIntl from "../../../../../view/intl/injectIntl";
import * as pagesIds from "./ids";
import NavigationGroup from "../../../../PropertiesPanel/view/IntlNavigationGroup";
import pagesTitles from "./titles";

const id: any = pagesIds.MAIN,
    title = pagesTitles[pagesIds.MAIN],
    view = injectIntl(({ navigateToPage }) => {
        return (
            <Page>
                <NavigationGroup
                    navigateToPage={navigateToPage}
                    pagesTitles={pagesTitles}
                    targetPagesIds={[
                        pagesIds.STYLE,
                        pagesIds.BUTTON,
                        pagesIds.PROMO,
                    ]}
                />
            </Page>
        );
    });

export { id, title, view };
