
import { getScaledGalleryImageStyles, getScaledGalleryCaptionStyles } from "../../../utils/getScaledStyles";
import type { CmpSpecificStyles } from "../../Preview/flowTypes";
import type { GalleryComponent } from "./flowTypes";

export const getSpecificStyles = ({ component }: CmpSpecificStyles<GalleryComponent>): string => {
    let scaledStyles = getScaledGalleryImageStyles(component);
    scaledStyles += getScaledGalleryCaptionStyles(component);

    return scaledStyles;
};
