import React from 'react';
import cx from 'classnames';
import styles from './FileChooser.css';
import FakeProgressTimer from "../../../utils/FakeProgressTimer";
import type { FileChooserProgressBarProps } from "./flowTypes";

type State = {
    progress: string;
};

export class ProgressBar extends React.Component<FileChooserProgressBarProps, State> {
    timer = new FakeProgressTimer(2000, 50, this._updateProgress.bind(this));

    constructor() {
        // @ts-ignore
        super();
        this.state = { progress: '0' };
    }

    _updateProgress(progress: number) {
        this.setState({ progress: `${progress}%` });
    }

    UNSAFE_componentWillMount() {
        if (this.props.show) {
            this.timer.go();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: FileChooserProgressBarProps) {
        if (!this.props.show && nextProps.show) {
            this.timer.go();
        } else if (this.props.show && !nextProps.show) {
            this.timer.finish();
        }
    }

    componentWillUnmount() {
        this.timer.finish();
    }

    render() {
        return (
            <span
                className={cx(styles.progressBar, this.props.className)}
                style={{ width: this.state.progress }}
            />
        );
    }
}
