import type { CmpSpecificStyles } from "../../Preview/flowTypes";
import type { SocialComponent } from "./flowTypes";
import { ALIGNMENT_TYPES } from "../../../constants";
import styles from './view/Social.css';

const alignmentToJustifyContentMap = {
    [ALIGNMENT_TYPES.ALIGN_LEFT]: 'flex-start',
    [ALIGNMENT_TYPES.ALIGN_CENTER]: 'center',
    [ALIGNMENT_TYPES.ALIGN_RIGHT]: 'flex-end',
};

const getMarginPart = (component) => {
    const horizontalSpacing = component.mobileSize / 2;

    switch (component.mobileHorizontalAlignment) {
        case ALIGNMENT_TYPES.ALIGN_LEFT:
            return `margin-right: ${horizontalSpacing}px;`;
        case ALIGNMENT_TYPES.ALIGN_RIGHT:
            return `margin-left: ${horizontalSpacing}px;`;
        default: {
            const leftMargin = Math.round(horizontalSpacing / 2);
            const rightMargin = horizontalSpacing - leftMargin;
            return `
                margin-left: ${leftMargin}px;
                margin-right: ${rightMargin}px;
            `;
        }
    }
};
export const socialGetSpecificStyles = ({ component }: CmpSpecificStyles<SocialComponent>): string => {
    const componentMobileSelector = `.mobileV div[data-id='${component.id}']`;
    const verticalSpacing = component.mobileSize / 4;

    return `
${componentMobileSelector} {
    overflow: hidden;
}
${componentMobileSelector} .${styles.container} {
    justify-content: ${alignmentToJustifyContentMap[component.mobileHorizontalAlignment]};
    margin-top: -${verticalSpacing}px;
    margin-bottom: -${verticalSpacing}px;
}
${componentMobileSelector} .${styles.container} a {
    ${getMarginPart(component)}
    margin-top: ${verticalSpacing}px;
    margin-bottom: ${verticalSpacing}px;
    height: ${component.mobileSize}px !important;
    width: ${component.mobileSize}px !important;
}
${componentMobileSelector} .${styles.container} a svg {
    width: ${component.mobileSize}px !important;
    height: ${component.mobileSize}px !important;
    position: absolute;
}`;
};
