import { makeEpicStateSelector } from "../../../epics/makeEpic";
import { COLOR_THEME_SITE_SETTINGS_EPIC_VALUE } from "./colorThemeSiteSettingsVAT";
import { colorThemeServiceSiteSettingsEpic } from "./colorThemeServiceSiteSettingsEpic";

const
    colorThemeDataEpicStateFromAppStateSelector = makeEpicStateSelector(COLOR_THEME_SITE_SETTINGS_EPIC_VALUE),
    colorThemeServiceSiteSettingsDataSelector = makeEpicStateSelector(colorThemeServiceSiteSettingsEpic.valueActionType);

export {
    colorThemeDataEpicStateFromAppStateSelector,
    colorThemeServiceSiteSettingsDataSelector
};
