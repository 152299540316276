import type { PublishConfig } from "../../../App/epics/siteSettings/flowTypes";

export const
    isHtAccessInModifiedURLs = (urls: Array<string>, publishConfig: PublishConfig): boolean => {
        const { publishWithSSL, dropHtmlExtension } = publishConfig;

        if (publishWithSSL || dropHtmlExtension) {
            return urls.some(
                url => {
                    return (
                        /^\/\.htaccess$/.test(url) ||                       // Production
                        /^\/webdav\/[\w\d.-]+\/\.htaccess$/.test(url)       // Development
                    );
                }
            );
        }

        return false;
    };
