import React from 'react';
import { connect } from 'react-redux';
import styles from './EditingModePanel.css';
import { showTemplateSettingsAction } from "../../redux/modules/children/workspace/actionCreators";
import { injectIntl, Msg } from '../../view/intl/index';
import { SHOW_AUTO_COLOR_LEFT_PANEL } from '../AutoColorLeftPanel/actionTypes';
import { makeEpicStateSelector } from "../../epics/makeEpic";
import AUTO_COLOR_LEFT_PANEL_EPIC_VALUE from '../AutoColorLeftPanel/epic/valueActionType';

const EditingModePanel = ({ dispatch, intl, leftPanel }) => {
    if (leftPanel.show) {
        return null;
    }

    const onClickTemplateSettings = (): void => {
        dispatch(showTemplateSettingsAction());
    };

    return (
        <div className={styles.mainContainer}>
            <div
                onClick={() => dispatch({ type: SHOW_AUTO_COLOR_LEFT_PANEL })}
                className={styles.editAutoColors}
                data-title={intl.msgJoint('msg: common.editColourTheme {Edit colour theme}')}
                data-title-position="left"
            >
                <div className={styles.editAutoColorsIcon} />
            </div>
            <button
                type="button"
                className={styles.editSettingsBtn}
                onClick={onClickTemplateSettings}
            >
                <Msg k="component.editingModePanel.templateSettings.title">Template settings</Msg>
            </button>
        </div>
    );
};

const mapStateToProps = state => {
    return { leftPanel: makeEpicStateSelector(AUTO_COLOR_LEFT_PANEL_EPIC_VALUE)(state) };
};

export default connect(mapStateToProps)(injectIntl(EditingModePanel));
