/* eslint-disable max-len */
import React from "react";
import { StepDescription } from "../Common/StepDescription";

import stepStyles from "../steps.css";
import { LanguageField } from "./LanguageField";
import { WizardFooter } from "../../WizardFooter";

export const LanguageStep = () => {
    const StepDescriptionProps = {
        title: "msg: onboarding.dynamic.step5.language.title {Which language should your website be in?}",
        description: "msg: onboarding.dynamic.step5.language.description {This language will be used for all the texts we create for your website.}",
    };

    return (
        <React.Fragment>
            <div className={stepStyles.stepContainer} data-testid="dynamic-onboarding-language">
                <StepDescription {...StepDescriptionProps} />
                <LanguageField />
            </div>
            <WizardFooter />
        </React.Fragment>
    );
};
