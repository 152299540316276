export default [
    "Arial",
    "Arial Black",
    "Comic Sans MS",
    "Courier",
    "Courier New",
    "Georgia",
    "Helvetica",
    "Impact",
    "Lucida Console",
    "Lucida Sans Console",
    "Palatino Linotype",
    "Tahoma",
    "Times New Roman",
    "Trebuchet MS",
    "Verdana",
    "MS Sans Serif",
    "MS Serif"
];

export const FontFamiliesFallback = {
    "Arial": 'Arial, Helvetica, sans-serif',
    "Arial Black": '"Arial Black", Gadget, sans-serif',
    "Comic Sans MS": '"Comic Sans MS", cursive',
    "Courier": 'Courier, monospace',
    "Courier New": '"Courier New", Courier, monospace',
    "Georgia": 'Georgia, serif',
    "Helvetica": 'Helvetica, sans-serif',
    "Impact": 'Impact, Charcoal, sans-serif',
    "Lucida Console": '"Lucida Console", Monaco, monospace',
    "Lucida Sans Unicode": '"Lucida Sans Unicode", "Lucida Grande", sans-serif',
    "Palatino Linotype": '"Palatino Linotype", "Book Antiqua", Palatino, serif',
    "Tahoma": 'Tahoma, Geneva, sans-serif',
    "Times New Roman": '"Times New Roman", Times, serif',
    "Trebuchet MS": '"Trebuchet MS", Helvetica, sans-serif',
    "Verdana": 'Verdana, Geneva, sans-serif',
    "MS Sans Serif": '"MS Sans Serif", Geneva, sans-serif',
    "MS Serif": '"MS Serif", "New York", serif'
};

export const commonFontsMetrics = [
    {
        fontFamily: 'Arial',
        variations: [
            {
                identifier: 'arial',
                textMetrics: {
                    unitsPerEm: 1000,
                    ascender: 728,
                    descender: -210
                }
            },
            /*{
                identifier: 'arial_800',
                fontWeight: 800,
                textMetrics: {
                    unitsPerEm: 1000,
                    ascender: 728,
                    descender: -210
                }
            },*/
            {
                identifier: 'arial_700',
                fontWeight: 700,
                textMetrics: {
                    unitsPerEm: 1000,
                    ascender: 728,
                    descender: -210
                }
            },
            {
                identifier: 'arial_700italic',
                fontWeight: 700,
                fontStyle: 'italic',
                textMetrics: {
                    unitsPerEm: 1000,
                    ascender: 728,
                    descender: -210
                }
            },
            {
                identifier: 'arial_italic',
                fontStyle: 'italic',
                textMetrics: {
                    unitsPerEm: 1000,
                    ascender: 728,
                    descender: -210
                }
            }
        ]
    },
    {
        fontFamily: 'Arial Black',
        variations: [
            {
                identifier: 'arial_black',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 2254,
                    descender: -634
                }
            },
            {
                identifier: 'arial_black_italic',
                fontStyle: 'italic',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 2254,
                    descender: -634
                }
            }
        ]
    },
    {
        fontFamily: 'Comic Sans MS',
        variations: [
            {
                identifier: 'comic_sans_ms',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 2257,
                    descender: -597
                }
            },
            {
                identifier: 'comic_sans_ms_700',
                fontWeight: 700,
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 2257,
                    descender: -597
                }
            },
            {
                identifier: 'comic_sans_ms_700italic',
                fontWeight: 700,
                fontStyle: 'italic',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 2257,
                    descender: -597
                }
            },
            {
                identifier: 'comic_sans_ms_italic',
                fontStyle: 'italic',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 2257,
                    descender: -597
                }
            },
        ]
    },
    {
        fontFamily: 'Courier',
        variations: [
            {
                identifier: 'courier',
                textMetrics: {
                    unitsPerEm: 1000,
                    ascender: 661,
                    descender: -171
                }
            },
            {
                identifier: 'courier_700',
                fontWeight: 700,
                textMetrics: {
                    unitsPerEm: 1000,
                    ascender: 661,
                    descender: -171
                }
            },
            {
                identifier: 'courier_700italic',
                fontWeight: 700,
                fontStyle: 'italic',
                textMetrics: {
                    unitsPerEm: 1000,
                    ascender: 661,
                    descender: -171
                }
            },
            {
                identifier: 'courier_italic',
                fontStyle: 'italic',
                textMetrics: {
                    unitsPerEm: 1000,
                    ascender: 661,
                    descender: -171
                }
            }
        ]
    },
    {
        fontFamily: 'Courier New',
        variations: [
            {
                identifier: 'courier_new',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1705,
                    descender: -615
                }
            },
            {
                identifier: 'courier_new_700',
                fontWeight: 700,
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1705,
                    descender: -615
                }
            },
            {
                identifier: 'courier_new_700italic',
                fontWeight: 700,
                fontStyle: 'italic',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1705,
                    descender: -615
                }
            },
            {
                identifier: 'courier_new_italic',
                fontStyle: 'italic',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1705,
                    descender: -615
                }
            }
        ]
    },
    {
        fontFamily: 'Georgia',
        variations: [
            {
                identifier: 'georgia',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1878,
                    descender: -449
                }
            },
            {
                identifier: 'georgia_700',
                fontWeight: 700,
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1878,
                    descender: -449
                }
            },
            {
                identifier: 'georgia_700italic',
                fontWeight: 700,
                fontStyle: 'italic',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1878,
                    descender: -449
                }
            },
            {
                identifier: 'georgia_italic',
                fontStyle: 'italic',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1878,
                    descender: -449
                }
            }
        ]
    },
    {
        fontFamily: 'Helvetica',
        variations: [
            {
                identifier: 'helvetica',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1577,
                    descender: -471
                }
            },
            {
                identifier: 'helvetica_700',
                fontWeight: 700,
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1577,
                    descender: -471
                }
            },
            {
                identifier: 'helvetica_700italic',
                fontWeight: 700,
                fontStyle: 'italic',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1577,
                    descender: -471
                }
            },
            {
                identifier: 'helvetica_italic',
                fontStyle: 'italic',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1577,
                    descender: -471
                }
            }
        ]
    },
    {
        fontFamily: 'Impact',
        variations: [
            {
                identifier: 'impact',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 2066,
                    descender: -432
                }
            }
        ]
    },
    {
        fontFamily: 'Lucida Console',
        variations: [
            {
                identifier: 'lucida_console',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1616,
                    descender: -432
                }
            }
        ]
    },
    {
        fontFamily: 'Lucida Sans',
        variations: [
            {
                identifier: 'lucida_sans',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1980,
                    descender: -432
                }
            },
            /*{
                identifier: 'lucida_sans_600italic',
                fontWeight: 600,
                fontStyle: 'italic',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1980,
                    descender: -432
                }
            },*/
            {
                identifier: 'lucida_sans_italic',
                fontStyle: 'italic',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1980,
                    descender: -432
                }
            }
        ]
    },
    {
        fontFamily: 'Palatino Linotype',
        variations: [
            {
                identifier: 'palatino_linotype',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1499,
                    descender: -582
                }
            },
            {
                identifier: 'palatino_linotype_700',
                fontWeight: 700,
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1499,
                    descender: -582
                }
            },
            {
                identifier: 'palatino_linotype_700italic',
                fontWeight: 700,
                fontStyle: 'italic',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1499,
                    descender: -582
                }
            },
            {
                identifier: 'palatino_linotype_italic',
                fontStyle: 'italic',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1499,
                    descender: -582
                }
            }
        ]
    },
    {
        fontFamily: 'Tahoma',
        variations: [
            {
                identifier: 'tahoma',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 2049,
                    descender: -423
                }
            },
            {
                identifier: 'tahoma_700',
                fontWeight: 700,
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 2049,
                    descender: -423
                }
            }
        ]
    },
    {
        fontFamily: 'Times New Roman',
        variations: [
            {
                identifier: 'times_new_roman',
                textMetrics: {
                    unitsPerEm: 1000,
                    ascender: 677,
                    descender: -215
                }
            },
            {
                identifier: 'times_new_roman_700',
                fontWeight: 700,
                textMetrics: {
                    unitsPerEm: 1000,
                    ascender: 677,
                    descender: -215
                }
            },
            {
                identifier: 'times_new_roman_700italic',
                fontWeight: 700,
                fontStyle: 'italic',
                textMetrics: {
                    unitsPerEm: 1000,
                    ascender: 677,
                    descender: -215
                }
            },
            {
                identifier: 'times_new_roman_italic',
                fontStyle: 'italic',
                textMetrics: {
                    unitsPerEm: 1000,
                    ascender: 677,
                    descender: -215
                }
            },
        ]
    },
    {
        fontFamily: 'Trebuchet MS',
        variations: [
            {
                identifier: 'trebuchet_ms',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1923,
                    descender: -455
                }
            },
            {
                identifier: 'trebuchet_ms_700',
                fontWeight: 700,
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1923,
                    descender: -455
                }
            },
            {
                identifier: 'trebuchet_ms_700italic',
                fontWeight: 700,
                fontStyle: 'italic',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1923,
                    descender: -455
                }
            },
            {
                identifier: 'trebuchet_ms_italic',
                fontStyle: 'italic',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1923,
                    descender: -455
                }
            },
        ]
    },
    {
        fontFamily: 'Verdana',
        variations: [
            {
                identifier: 'verdana',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 2059,
                    descender: -430
                }
            },
            {
                identifier: 'verdana_700',
                fontWeight: 700,
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 2059,
                    descender: -430
                }
            },
            {
                identifier: 'verdana_700italic',
                fontWeight: 700,
                fontStyle: 'italic',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 2059,
                    descender: -430
                }
            },
            {
                identifier: 'verdana_italic',
                fontStyle: 'italic',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 2059,
                    descender: -430
                }
            }
        ]
    },
    {
        fontFamily: 'MS Sans Serif',
        variations: [
            {
                identifier: 'ms_sans_serif',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1888,
                    descender: -430
                }
            }
        ]
    },
    {
        fontFamily: 'MS Serif',
        variations: [
            {
                identifier: 'ms_serif',
                textMetrics: {
                    unitsPerEm: 2048,
                    ascender: 1878,
                    descender: -449
                }
            }
        ]
    },
];
