import { DataSite } from "../../../../../dal/model";
import { PAGE_DATA_COMPONENT } from "../../../../../dal";
import type { UpdateSiteDataDeletedPages } from "../../../../../dal/flowTypes"; // eslint-disable-line
import { isPageRef, isSectionLink } from "../../../../../dal/model/utils/dataSiteItemUtils";
import type { DataSiteItem } from "../../../../../dal/model/utils/dataSiteItemUtils"; // eslint-disable-line

type Result = [DataSite, UpdateSiteDataDeletedPages | null | undefined];

const getPageIds = (page: DataSiteItem): Array<string> => {
    const ids: any[] = [];
    const find = (page: DataSiteItem) => {
        if (isPageRef(page)) {
            ids.push(page.pageId);
            page.items.map(find);
        }
    };
    find(page);
    return ids;
};

export default (site: DataSite, targetPage: DataSiteItem): Result => {
    const newSite = new DataSite(site); // clone site

    // remove page from site
    let
        parent,
        pageIndex;
    const matchParent = (pages: Array<DataSiteItem>, ctxParent: Record<string, any>) => {
        pages.forEach((p, i) => {
            if (parent) return;
            if (p.id === targetPage.id) {
                parent = ctxParent;
                pageIndex = i;
            } else if (isPageRef(p)) {
                matchParent(p.items, p);
            }
        });
    };
    matchParent(newSite.getItems(), newSite.folder);
    if (!parent) throw new Error(`Cannot find parent for site page: ${targetPage.id}`);
    parent.items.splice(pageIndex, 1);

    // Remove all section links on deleting the last remaining page
    if (isPageRef(targetPage) && site.isLastRootPageRef(targetPage.id)) {
        parent.items = parent.items.filter(item => !isSectionLink(item));
    }

    // build deletedPages
    let deletePages;
    if (isPageRef(targetPage)) {
        deletePages = getPageIds(targetPage).map(id => ({
            id,
            type: PAGE_DATA_COMPONENT
        }));
    }

    // clear homePageId if home page is deleted
    if (isPageRef(targetPage) && site.pageRefContainsPageId(targetPage.id, newSite.homePageId)) {
        newSite.homePageId = '';
    }

    return [newSite, deletePages];
};
