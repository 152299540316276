import * as R from "ramda";
import type { TableEditModeState } from "../flowTypes";

const
    nothingInEditModeIndex = -1,
    tableEditModeEpicDefaultState: TableEditModeState = {
        selectedCellsIndexes: [],
        cellInEditModeIndex: nothingInEditModeIndex,
        mctaHorizontalAlignControlExpanded: false,
        processingOfMultipleTinyMCEInstancesInProgress: false
    },
    setProcessingOfMultipleTinyMCEInstancesInProgress = R.assoc('processingOfMultipleTinyMCEInstancesInProgress'),
    enableProcessingOfMultipleTinyMCEInstancesInProgress = setProcessingOfMultipleTinyMCEInstancesInProgress(true),
    disableProcessingOfMultipleTinyMCEInstancesInProgress = setProcessingOfMultipleTinyMCEInstancesInProgress(false);

export {
    nothingInEditModeIndex,
    tableEditModeEpicDefaultState,
    enableProcessingOfMultipleTinyMCEInstancesInProgress,
    disableProcessingOfMultipleTinyMCEInstancesInProgress
};
