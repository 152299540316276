import * as React from 'react';
import { connect } from 'react-redux';
import platform from 'platform';
import styles from './BrowserZoomResetTipContent.css';
import PrimaryButton from "../Button/PrimaryButton";
import { injectIntl, Intl } from "../../intl/index";
import { setHideBrowserWarning } from './utils';
import { CLOSE_STICKY_TOOLTIP } from "../../../components/Tooltip/stickyTooltip/actionTypes";

const isMac = platform.os.family === 'OS X';

type BrowserZoomResetProps = {
    dispatch: Dispatch,
    intl: Intl
}

class BrowserZoomReset extends React.Component<BrowserZoomResetProps> {
    render() {
        const { intl } = this.props;
        return (
            <div>
                <div className={styles.infoWrapper}>
                    <div>
                        {intl.msgJoint(`msg: tooltip.BrowserZoom.text
                        {Looks like your browser zoom is activated and your workspace might not behave correctly.}`)}
                    </div>
                    <div>
                        {intl.msgJoint(`msg: tooltip.BrowserZoom.text1
                            {Reset the zoom level by pressing these keys on your keyboard:}`)}
                    </div>
                </div>
                <div className={styles.ctrlsWrapper}>
                    <div className={styles.ctrlKey}>
                        <div>{isMac ? 'cmd' : 'ctrl'}</div>
                        {isMac && <div>⌘</div>}
                    </div>
                    <div className={styles.plus}>+</div>
                    <div className={styles.zeroKey}>
                        <div className={styles.zeroNum}>0</div>
                        <div className={styles.zeroText}>{intl.msgJoint('msg: tooltip.BrowserZoom.zero {zero}')}</div>
                    </div>
                </div>
                <PrimaryButton
                    className={styles.clsBtn}
                    onClick={() => { setHideBrowserWarning(); this.props.dispatch({ type: CLOSE_STICKY_TOOLTIP }); }}
                >
                    {intl.msgJoint('msg: common.gotIt.exclamation {Got it!}')}
                </PrimaryButton>
            </div>
        );
    }
}

export default injectIntl(connect()(BrowserZoomReset));
