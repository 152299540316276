import CALL_API from "../../../redux/middleware/api/CALL_API";
import {
    PUT_NEW_STYLESHEET_REQUEST,
    PUT_NEW_STYLESHEET_SUCCESS,
    PUT_NEW_STYLESHEET_FAILURE
} from "../actionTypes";
import type { ApiAction } from "../../../redux/middleware/api/flowTypes";

export default (styleSheet: any): ApiAction => ({
    [CALL_API]: {
        types: [
            PUT_NEW_STYLESHEET_REQUEST,
            PUT_NEW_STYLESHEET_SUCCESS,
            PUT_NEW_STYLESHEET_FAILURE
        ],
        endpoint: 'putNewStylesheet',
        endpointParams: styleSheet
    }
});
