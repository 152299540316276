import { receiveOnly, receiveOnlyWhenReason, whenReason } from "../../../../epics/makeCondition";
import { SITE_DATA_UPDATE_EXTERNAL } from "./updateReasons";

const
    valueActionType = 'SITE_DATA_EPIC_VALUE',
    siteDataValueActionType = valueActionType,
    siteDataReceiveOnlyValueActionType = receiveOnly(valueActionType),
    siteDataExternalValueActionType = whenReason(valueActionType, SITE_DATA_UPDATE_EXTERNAL),
    siteDataReceiveOnlyExternalValueActionType = receiveOnlyWhenReason(valueActionType, SITE_DATA_UPDATE_EXTERNAL);

export {
    siteDataExternalValueActionType as default,
    valueActionType,
    siteDataReceiveOnlyValueActionType,
    siteDataReceiveOnlyExternalValueActionType,
    siteDataValueActionType
};
