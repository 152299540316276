import * as R from 'ramda';
import makeEpic from '../../../epics/makeEpic';
import makeStateSelectorReducer from '../../../epics/makeStateSelectorReducer';
import { receiveOnly, optionalResetReceiveOnly, optional } from '../../../epics/makeCondition';
import { getDefaultReducerState } from '../../../redux/makeReducer/index';
import * as appActionTypes from '../../App/actionTypes';
import browserDimensionsValueActionType from '../../App/epics/browserDimensions/valueActionType';
import * as panelActionTypes from '../../Panel/actionTypes';
import leftPanelWidthValueActionType from '../../Panel/epics/width/valueActionType';
import WebShopComponentKind from '../../oneweb/WebShop/kind';
import GalleryComponentKind from '../../oneweb/Gallery/kind';
import ImageSliderComponentKind from '../../oneweb/ImageSlider/kind';
import componentsRegistry from "../../../view/oneweb/registry/index";
import isStretchComponentKind from '../../oneweb/isStretchComponentKind';
import templateOffsetValueActionType from '../../oneweb/Template/epics/templateOffset/valueActionType';
import { ReceiveOnlyTemplateWidthActionType } from '../../oneweb/Template/epics/template/selectorActionTypes';
import { snap } from "../../../utils/snapping/index";
import { getComponentsBBox } from "../../../utils/componentsMap/index";
import topMostHandleVAT from '../../Workspace/epics/topMostHandle/valueActionType';
import * as HandleKinds from '../../../utils/handle/kinds';
import scrollValueActionType from '../../Workspace/epics/scroll/valueActionType';
import { snappingDecosDefaultState } from "../../Workspace/epics/snappingDecos/index";
import workspaceBBoxValueActionType from '../../Workspace/epics/workspaceBBox/valueActionType';
import { ReceiveOnlyComponentsMap } from "../../Workspace/epics/componentsEval/selectorActionTypes";
import { ReceiveOnlyViewPortWidthActionType } from "../../Workspace/epics/viewportDimensions/selectorActionTypes";
import mousePositionWithRespectToTemplateAreaValueActionType from '../../Workspace/epics/mousePositionWithRespectToTemplateArea/valueActionType'; // eslint-disable-line max-len
import * as actionsTypes from "../actionTypes";
import valueActionType from './valueActionType';
import type { AnyComponent } from "../../oneweb/flowTypes";
import type { DndAddComponentState } from '../flowTypes';
import {
    ROCodeComponentsRendererHeadHeightSelector
} from "../../Workspace/CodeComponentsRenderer/epic/selectorActionTypes";
import { SOCIAL_KIND } from '../../oneweb/Social/kind';
import { socialComponentComputeWidthOnDrop, socialComponentInitialState } from '../../oneweb/Social/index';
import { socialGlobalDataEpic } from '../../oneweb/Social/epics/socialGlobalDataEpic/socialGlobalDataEpic';
import { getCmpPositionOnDrop } from "./util";
import { LOGO_KIND } from '../../oneweb/Logo/kind';
import { globalVariablesEpic } from '../../App/epics/globalVariablesEpic';
import { getLogoInitialDimensions } from '../../oneweb/Logo/getLogoInitialDimensions';
import ExtendedPanelVAT from "../../Panel/epics/extendedPanel/valueActionType";
import { LeftPanel } from "../../../utils/handle/kinds";
import { GOOGLE_REVIEWS, isSectionKind } from "../../oneweb/componentKinds";
import { getComponentsMapForHoverBox as _getComponentsMapForHoverBox } from "../../oneweb/HoverBox/utils";
import { memoMaxOne } from "../../../../utils/memo";
import { ReceiveOnlyAttachments } from "../../Workspace/epics/componentAttachements/selectorActionTypes";
import {
    getIsBoxBelowModernFooter,
    isBoxInModernHeaderOrFooter
} from "../../ModernLayouts/utils";
import { getSectionSnappingBBoxes } from "../../Workspace/epics/SnapEquisistantFromEdgesOfSection/util";
import { workspaceComponentAttachDecorationsValueActionType } from "../../Workspace/epics/componentAttachDecorations/valueActionType";
import { getCmpRect, isBoxInsideParentBox } from '../../Workspace/epics/componentsEval/utils';
import { getHeaderSection } from '../../oneweb/Section/utils';
import { CANNOT_ADD_COMPONENT_TO_FOOTER, CANNOT_ADD_COMPONENT_TO_HEADER } from '../../Workspace/epics/componentsEval/messages';
import { getAddComponentsNotAllowedAction } from '../../Workspace/epics/componentsEval/actionCreators/componentsAddNotAllowed';
import { fullWidthCmpConfigMap } from "../../oneweb/fullWidthUtils";
import { getAllComponentsIdsInModernHeaderAndFooter } from '../../ModernLayouts/preview_utils';
import { getIsBoxInWebShopFooterStrip, getIsBoxBelowWebShopFooterStrip } from '../../ModernLayouts/layoutsData/webshopMHFDataUtils';

const
    STRETCH_COMPONENT_HEIGHT = 80,
    defaultState: DndAddComponentState = {
        isVisible: false,
        isDroppable: false,
        position: { x: 0, y: 0 },
        inWorkspacePosition: { x: 0, y: 0 },
        inWorkspaceIconX: 0,
        inWorkspaceDimensions: { width: 0, height: 0 },
        componentKind: 'inValid kind',
        sectionOrBlockId: '',
        dimensions: { width: 0, height: 0 },
        snappingState: snappingDecosDefaultState
    },
    getComponentsMapForHoverBox = memoMaxOne(_getComponentsMapForHoverBox),
    getDefaultComponentDimensions = kind => {
        const { reducer } = componentsRegistry[kind];
        return getDefaultReducerState(reducer);
    },
    epic = makeEpic({
        defaultState,
        valueActionType,
        updaters: [
            {
                conditions: [
                    receiveOnly(appActionTypes.WINDOW_MOUSE_MOVE),
                    receiveOnly(mousePositionWithRespectToTemplateAreaValueActionType),
                    receiveOnly(topMostHandleVAT),
                    ReceiveOnlyComponentsMap,
                    appActionTypes.WINDOW_MOUSE_UP
                ],
                reducer: ({
                    state,
                    values: [mousePosition, mousePositionWithRespectToTemplateArea, topMostHandle, componentsMap]
                }) => {
                    if (state.isDroppable) {
                        const position = getCmpPositionOnDrop({
                            positionWithRespectToBrowser: state.position,
                            mousePosition,
                            mousePositionWithRespectToTemplateArea
                        });
                        return {
                            state: defaultState,
                            actionToDispatch: {
                                type: actionsTypes.NEW_COMPONENT_DROPED_ON_WORKSPACE,
                                payload: {
                                    kind: state.componentKind,
                                    sectionOrBlockId: state.sectionOrBlockId,
                                    position,
                                    topMostHandleKind: topMostHandle.kind
                                }
                            }
                        };
                    } else {
                        const { inWorkspacePosition: { x, y }, dimensions: { width, height } } = state,
                            cmpBox = (width && height && { top: y, left: x, right: x + width, bottom: y + height }) || null,
                            isInModernHF = !!(cmpBox && isBoxInModernHeaderOrFooter(cmpBox, componentsMap)),
                            isComponentBelowModernFooter = !!(cmpBox && getIsBoxBelowModernFooter(cmpBox, componentsMap)),
                            isBoxBelowWebShopFooter = !!(cmpBox && getIsBoxBelowWebShopFooterStrip(cmpBox, componentsMap)),
                            isBoxInWebShopFooterStrip = !!(cmpBox && getIsBoxInWebShopFooterStrip(cmpBox, componentsMap)),
                            header = getHeaderSection(componentsMap),
                            multipleActionsToDispatch: Action[] = [];
                        let isInHeader = header && isBoxInsideParentBox(
                            getCmpRect({ top: y, left: x, width, height }),
                            getCmpRect(header), true
                        );

                        if (state.isVisible) {
                            multipleActionsToDispatch.push({ type: actionsTypes.COMPONENT_ADD_CANCELLED });
                        }

                        if (isInModernHF || isComponentBelowModernFooter ||
                                isBoxBelowWebShopFooter || isBoxInWebShopFooterStrip) {
                            multipleActionsToDispatch.push(getAddComponentsNotAllowedAction([{
                                componentKind: state.componentKind,
                                message: isInHeader ? CANNOT_ADD_COMPONENT_TO_HEADER : CANNOT_ADD_COMPONENT_TO_FOOTER,
                                showToaster: true
                            }]));
                        }
                        return {
                            state: defaultState,
                            multipleActionsToDispatch
                        };
                    }
                }
            },
            {
                conditions: [
                    receiveOnly(workspaceComponentAttachDecorationsValueActionType),
                    receiveOnly(globalVariablesEpic.valueActionType),
                    receiveOnly(socialGlobalDataEpic.valueActionType),
                    receiveOnly(topMostHandleVAT),
                    ReceiveOnlyViewPortWidthActionType,
                    receiveOnly(leftPanelWidthValueActionType),
                    ReceiveOnlyTemplateWidthActionType,
                    receiveOnly(templateOffsetValueActionType),
                    receiveOnly(browserDimensionsValueActionType),
                    ReceiveOnlyComponentsMap,
                    ReceiveOnlyAttachments,
                    receiveOnly(workspaceBBoxValueActionType),
                    scrollValueActionType,
                    ROCodeComponentsRendererHeadHeightSelector,
                    optionalResetReceiveOnly(appActionTypes.WINDOW_MOUSE_UP),
                    receiveOnly(panelActionTypes.PANEL_COMPONENT_SHORTCUT_MOUSE_DOWN),
                    receiveOnly(ExtendedPanelVAT),
                    receiveOnly(mousePositionWithRespectToTemplateAreaValueActionType),
                    appActionTypes.WINDOW_MOUSE_MOVE,
                    optional(appActionTypes.LEFT_PANEL_ON_MOUSE_LEAVE),
                ],
                reducer: ({
                    state,
                    values: [
                        { cmpId: parentContainerId },
                        globalVariables,
                        socialData,
                        topMostHandle,
                        viewportWidth,
                        leftPanelWidth,
                        templateWidth,
                        templateOffset,
                        browserDimensions,
                        componentsMap,
                        attachments,
                        workspaceBBox,
                        scroll,
                        codeComponentHeadHeight,,
                        { componentKind },
                        { componentConfig: { sectionOrBlockId, width: cmpWidth, height: cmpHeight, type } },
                        mousePositionWithRespectToTemplateArea,
                        mousePosition,
                    ]
                }) => {
                    let
                        { width, height } = getDefaultComponentDimensions(componentKind),
                        position = { ...mousePosition },
                        dimensions,
                        finalPosition;

                    const stretchHeight = sectionOrBlockId ? cmpHeight : STRETCH_COMPONENT_HEIGHT,
                        isStretch = isStretchComponentKind(componentKind) ||
                            (fullWidthCmpConfigMap[componentKind] && fullWidthCmpConfigMap[componentKind][type].stretch);

                    width = cmpWidth || width;
                    height = cmpHeight || height;

                    if (componentKind === WebShopComponentKind
                        || componentKind === GalleryComponentKind
                        || componentKind === ImageSliderComponentKind
                        || componentKind === GOOGLE_REVIEWS
                    ) {
                        width = templateWidth;
                    }

                    if (componentKind === SOCIAL_KIND) {
                        width = socialComponentComputeWidthOnDrop({
                            component: socialComponentInitialState,
                            socialData
                        });
                    }

                    if (componentKind === LOGO_KIND) {
                        const initialDimensions = getLogoInitialDimensions({ globalVariables });
                        width = initialDimensions.width;
                        height = initialDimensions.height;
                    }

                    if (componentKind === ImageSliderComponentKind) {
                        height = templateWidth * (1 / 2);
                    }

                    if (isStretch) {
                        finalPosition = {
                            x: leftPanelWidth,
                            y: position.y - (stretchHeight / 2)
                        };
                        dimensions = {
                            width: viewportWidth,
                            height
                        };
                    } else {
                        dimensions = { width, height };
                        finalPosition = {
                            x: position.x - (width / 2),
                            y: position.y - (height / 2)
                        };
                    }

                    let
                        inWorkspaceWidth = dimensions.width,
                        inWorkspaceX = Math.min(finalPosition.x - templateOffset.x, templateWidth),
                        inWorkspaceY = finalPosition.y - templateOffset.y - codeComponentHeadHeight,
                        cmpBox = {
                            top: inWorkspaceY,
                            left: inWorkspaceX,
                            right: inWorkspaceX + dimensions.width,
                            bottom: inWorkspaceY + dimensions.height
                        };

                    const
                        componentHeight = isStretch ? stretchHeight : height,
                        isDroppable = topMostHandle && (
                            ((topMostHandle.kind === HandleKinds.Workspace || topMostHandle.kind === HandleKinds.TemplateLines) &&
                                (mousePositionWithRespectToTemplateArea.y - (componentHeight / 2)) >= 0) ||
                            (isSectionKind(componentKind) && topMostHandle.kind !== LeftPanel)
                        ) && !isBoxInModernHeaderOrFooter(cmpBox, componentsMap)
                        && !getIsBoxBelowModernFooter(cmpBox, componentsMap)
                        && !getIsBoxBelowWebShopFooterStrip(cmpBox, componentsMap)
                        && !getIsBoxInWebShopFooterStrip(cmpBox, componentsMap);

                    const
                        addingComponentStubId = 'addingComponentStub',
                        addingComponentStub: AnyComponent = {
                            kind: componentKind,
                            top: inWorkspaceY,
                            left: inWorkspaceX,
                            width: dimensions.width,
                            height: dimensions.height
                        };

                    const visibleComponentsMap = getComponentsMapForHoverBox(componentsMap, attachments),
                        newCmpsMap = { ...visibleComponentsMap, [addingComponentStubId]: addingComponentStub },
                        sectionSnappingBboxes = getSectionSnappingBBoxes(
                            addingComponentStubId, parentContainerId, attachments, newCmpsMap, templateWidth
                        ) || [],
                        excludeComponentIds = getAllComponentsIdsInModernHeaderAndFooter(componentsMap),
                        snapResult = snap({
                            componentsMap: newCmpsMap,
                            componentsIds: [addingComponentStubId],
                            templateWidth,
                            browserDimensions,
                            scrollTop: scroll.y,
                            mousePosition: finalPosition,
                            workspaceBBox,
                            handleKind: HandleKinds.Component,
                            sectionSnappingBboxes,
                            excludeComponentIds
                        });

                    let snappingState = snappingDecosDefaultState;

                    if (snapResult.snapped === true) {
                        const
                            { snappedPoints, newMousePosition, mousePositionDeviation } = snapResult,
                            snappedComponentsBBox = getComponentsBBox([addingComponentStub], workspaceBBox);

                        inWorkspaceX = inWorkspaceX + mousePositionDeviation.x;
                        inWorkspaceY = inWorkspaceY + mousePositionDeviation.y;
                        finalPosition = newMousePosition;
                        snappingState = { snappedPoints, snappedComponentsBBox, workspaceBBox };
                    }

                    let inWorkspaceIconX = 0;
                    if (!isStretch) {
                        const
                            templateX = templateOffset.x,
                            componentX = finalPosition.x;

                        if (templateX > componentX) {
                            const lefterTemplateByPx = templateX - componentX;
                            inWorkspaceX = inWorkspaceX + lefterTemplateByPx;
                            inWorkspaceWidth = Math.max(0, width - lefterTemplateByPx);
                            inWorkspaceIconX = -((lefterTemplateByPx * 2) - width + inWorkspaceWidth);
                        }

                        const
                            componentRight = componentX + dimensions.width,
                            templateRight = templateX + templateWidth;

                        if (templateRight < componentRight) {
                            const righterTemplateByPx = componentRight - templateRight;
                            inWorkspaceWidth = Math.max(0, width - righterTemplateByPx);
                            const halfWidth = width / 2;

                            if (inWorkspaceWidth === 0) {
                                inWorkspaceIconX = componentX - templateRight + halfWidth;
                            } else if (inWorkspaceWidth < halfWidth) {
                                inWorkspaceIconX = halfWidth;
                            } else {
                                inWorkspaceIconX = width - inWorkspaceWidth;
                            }
                        }
                    }
                    const
                        newState = {
                            isVisible: true,
                            isDroppable,
                            componentKind,
                            sectionOrBlockId,
                            position: finalPosition,
                            inWorkspaceIconX,
                            inWorkspacePosition: {
                                x: inWorkspaceX,
                                y: inWorkspaceY
                            },
                            dimensions,
                            inWorkspaceDimensions: {
                                width: Math.max(0, inWorkspaceWidth),
                                height: dimensions.height
                            },
                            snappingState,
                            stretch: isStretch
                        },
                        actionToDispatch = !state.isVisible ? { type: actionsTypes.COMPONENT_ADD_STARTED } : null;
                    return { state: newState, actionToDispatch };
                }
            }
        ]
    }),
    reducerFactory = ({ prop, defaultValue }) => makeStateSelectorReducer(
        epic.reducer,
        valueActionType,
        R.pipe(R.path([prop]), R.defaultTo(defaultValue))
    ),
    dndAddComponentIsVisibleReducer = reducerFactory({ prop: 'isVisible', defaultValue: false });

export {
    epic as default,
    dndAddComponentIsVisibleReducer
};
