import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(theme => ({
    table: {
        width: "auto",
        [theme.breakpoints.down("sm")]: {
            width: "100%"
        }
    },
    tableRow: {
        '&:last-of-type > .MuiTableCell-root': {
            paddingBottom: "0",
        },
        [theme.breakpoints.down("sm")]: {
            '&:last-of-type > .MuiTableCell-root': {
                paddingBottom: "0",
            },
        }
    },
    tableCell: {
        borderBottom: "none",
        padding: "0 40px 16px 0",
        width: "auto",
        fontSize: "14px",
        lineHeight: "22px",
        [theme.breakpoints.down("sm")]: {
            padding: "0 16px 24px 0",
            lineHeight: "20px",
        }
    },
    toTableCell: {
        paddingLeft: "20px",
        paddingRight: "20px"
    },
    label: {
        margin: "0px"
    },
    labelText: {
        fontSize: "14px"
    },
    checkbox: {
        padding: "0px",
        marginRight: "8px"
    },
    select: {
        fontSize: "14px",
        padding: "0px 30px 0px 0px !important",
    },
    dropdownIcon: {
        width: "20px",
        height: "20px",
        right: "0px",
        pointerEvents: "none"
    },
    wrapper: {
        paddingBottom: "16px",
        [theme.breakpoints.down("sm")]: {
            paddingBottom: "24px",
        }
    }
}));
