import * as R from 'ramda';
import { styleGlobalIdSelector, getLocalFontSize } from "./selectors";
import StyleButtonType from "./globalStyle/kind";
import * as path from "../../../mappers/path";
import { getStylesByType } from "../../Workspace/epics/stylesheets/selectors";
import type { Stylesheets } from "../../Workspace/epics/stylesheets/flowTypes";
import type {
    ButtonComponent,
    ThemeStyleForButton,
    GetThemeButtonProps,
    GetThemeButtonPropsWithKnownTheme,
    GetMigratedButtonThemeProps,
    MigratedTheme
} from "./flowTypes";
import { getThemeRulesForButton } from "../../ThemeGlobalData/themeRules";
import * as colorMapper from "../../../mappers/color";
import {
    BUTTON_THEME_ALTERNATE,
    BUTTON_THEME_PRIMARY,
    BUTTON_THEME_SECONDARY,
} from "../../ThemeGlobalData/constants";

export const
    getButtonGlobalFontSize = (component: ButtonComponent, stylesheets: Stylesheets): number => {
        const
            buttonGlobalStyles = getStylesByType(StyleButtonType)(stylesheets.styles),
            selectedButtonStyle = R.find(R.propEq('id', styleGlobalIdSelector(component)))(buttonGlobalStyles);
        return R.path(path.inactiveTextSize, selectedButtonStyle);
    },
    getButtonLocalFontSize = (component: ButtonComponent, stylesheets: Stylesheets): number => {
        return getLocalFontSize(component) || getButtonGlobalFontSize(component, stylesheets);
    },
    getThemeStyleForButtonWithKnownTheme = ({
        selectedParentTheme,
        buttonThemeSelected,
        themeColorsData
    }: GetThemeButtonPropsWithKnownTheme) => {
        const
            themeRuleForButton = getThemeRulesForButton(selectedParentTheme, themeColorsData)[buttonThemeSelected],
            backgroundColor = themeRuleForButton.background !== "transparent" ?
                colorMapper.toCss(themeColorsData[themeRuleForButton.background]) : "transparent",
            textColor = colorMapper.toCss(themeColorsData[themeRuleForButton.text]),
            // @ts-ignore WBTGEN-16368
            borderColor = colorMapper.toCss(themeColorsData[themeRuleForButton.border]),
            border = borderColor ? `1px solid ${borderColor}` : "none";
        return {
            backgroundColor,
            color: textColor,
            border,
            backgroundImage: 'none'
        };
    },
    getThemeStyleForButton = ({
        selectedParentTheme,
        component,
        themeSettingsData,
        themeColorsData,
    }: GetThemeButtonProps): ThemeStyleForButton | Object => {
        const
            { buttonThemeSelected } = component,
            { autoColorMode } = themeSettingsData;

        if (autoColorMode) {
            if (autoColorMode && buttonThemeSelected && selectedParentTheme) {
                return getThemeStyleForButtonWithKnownTheme({ selectedParentTheme, buttonThemeSelected, themeColorsData });
            }
        }
        return {};
    },
    getMigratedButtonThemeForAutoColorChange = ({
        autoColorMode,
        buttonThemeSelected,
        globalName = ""
    }: GetMigratedButtonThemeProps): MigratedTheme => {
        if (autoColorMode && !buttonThemeSelected) {
            const
                // eslint-disable-next-line radix
                buttonStyle = parseInt(globalName.split(".")[1]);
            let newThemeSelected;
            switch (buttonStyle) {
                case 1:
                    newThemeSelected = BUTTON_THEME_PRIMARY;
                    break;
                case 2:
                    newThemeSelected = BUTTON_THEME_SECONDARY;
                    break;
                case 3:
                    newThemeSelected = BUTTON_THEME_ALTERNATE;
                    break;
                default:
                    newThemeSelected = BUTTON_THEME_PRIMARY;
                    break;
            }
            return { buttonThemeSelected: newThemeSelected };
        }
        return { buttonThemeSelected };
    };
