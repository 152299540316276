import React from 'react';
import cx from 'classnames';
import InputField from './InputField';
import styles from './InputField.css';

import type { InputFieldPropTypes } from './flowTypes';
import { isMsgJointInput } from "../../intl/index";
import type { MsgJointInput } from "../../intl/index";

export type ValidatedInputProps = InputFieldPropTypes & {
    intl: Intl;
    invalidInputClass: string;
    validationMsg?: MsgJointInput;
};

export const ValidatedInput = ({ intl, invalidInputClass, isInvalid, validationMsg, ...rest }: ValidatedInputProps) => (
    <React.Fragment>
        <InputField
            isInvalid={isInvalid}
            intl={intl}
            {...rest}
        />
        {
            isInvalid ? (
                <div className={cx(invalidInputClass, { [styles.hidden]: !isInvalid })}>
                    { /* @ts-ignore */ }
                    {validationMsg && isMsgJointInput(validationMsg) ? intl.msgJoint(validationMsg) : validationMsg}
                </div>
            ) : (null)
        }
    </React.Fragment>
);
