import * as R from "ramda";
import makeEpic from "../../../epics/makeEpic";
import BANNER_EPIC_VAT from "./valueActionType";
import { OPEN_BANNER, CLOSE_BANNER } from "./actionTypes";
import makeStateSelectorReducer from "../../../epics/makeStateSelectorReducer";
import { BannerDefaultState } from '../utils';
import { type BannerProps } from '../types';

export type BannerEpicState = BannerProps & {
    visible: boolean;
};

const defaultState: BannerEpicState = {
    ...BannerDefaultState,
    visible: false,
};

export const bannerEpic = makeEpic({
    defaultState,
    valueActionType: BANNER_EPIC_VAT,
    updaters: [
        {
            conditions: [OPEN_BANNER],
            reducer: ({ values: [bannerProps] }) => {
                return {
                    state: {
                        visible: true,
                        ...bannerProps,
                    }
                };
            }
        },
        {
            conditions: [CLOSE_BANNER],
            reducer: () => {
                return {
                    state: defaultState,
                };
            }
        }
    ]
});

export const bannerVisibilityStatus = makeStateSelectorReducer(
    bannerEpic.reducer,
    BANNER_EPIC_VAT,
    R.pipe(R.path(['visible']), R.defaultTo(false))
);
