import React from "react";
import Measure from 'react-measure';
import { injectIntl } from "../../../../view/intl/index";
import * as Actions from "../../actionTypes";
import * as constants from "../../constants";
import * as styles from './TipContent.css';
import noMouseEventPropagation from "../../../../view/common/NoMouseEventsPropagation";
import { TUTORIAL_VIDEO_CLICKED } from "../../../TopBar/actionTypes";

export default injectIntl(({
    tipNumber, showPreviousTipBtn, showNextTipBtn, showStartBuilding, showWatchVideo, showLetsGo,
    showSkipTour, tipStyle, pointerStyle, dispatch, intl, tourType,
}) => {
    let tipSequence;
    switch (tourType) {
        case constants.FULL_TOUR_WITH_TUTORIAL:
            tipSequence = constants.TipsSequence[constants.FULL_TOUR];
            break;
        case constants.FULL_TOUR:
            tipSequence = constants.TipsSequence[constants.FULL_TOUR].filter(type => type !== "VideoTutorial");
            break;
        default:
            tipSequence = constants.TipsSequence[tourType];
            break;
    }
    const tipData = constants.TipsData[tipSequence[tipNumber]],
        dispatchAction = (action) => (e) => {
            e.stopPropagation();
            if (action) {
                dispatch({ type: action });
            }
        },
        dispatchMultipleAction = (actions) => (e) => {
            e.stopPropagation();
            if (actions) {
                actions.forEach((action) => dispatch({ type: action }));
            }
        };

    return (
        <Measure
            offset
            onResize={(contentRect) => {
                dispatch({ type: Actions.TIP_HEIGHT_CHANGED, payload: contentRect.offset });
            }}
        >
            {({ measureRef }) => (
                <div
                    className={`${styles.container} ${tipData.classNames.container}`}
                    {...noMouseEventPropagation}
                    onClick={dispatchAction}
                    style={tipStyle}
                    ref={measureRef}
                >
                    <div>
                        <div className={`${styles.pointer} ${tipData.classNames.pointer}`} style={pointerStyle} />
                        <div className={styles.tipNumberContainer}>
                            <div className={styles.tipNumber}>
                                {intl.msgJoint(constants.Tip)} {tipNumber + 1}/{tipSequence.length}
                            </div>
                            <div
                                className={styles.closeIcon}
                                onClick={dispatchAction(Actions.STOP_QUICK_TOUR)}
                            />
                        </div>
                        <div className={styles.content}>
                            <div className={styles.header}>
                                <div className={styles.hintIcon} />
                                <div className={styles.titleContainer}>
                                    <div className={styles.title}>{intl.msgJoint(tipData.title)}</div>
                                    {tipData.subTitle && <div className={styles.subTitle}>{intl.msgJoint(tipData.subTitle)}</div>}
                                </div>
                            </div>
                            <div className={styles.text}>{intl.msgJoint(tipData.text)}</div>
                            {
                                showNextTipBtn &&
                                <div className={styles.nextTipBtn} onClick={dispatchAction(Actions.SHOW_NEXT_TIP)}>
                                    {intl.msgJoint(constants.NextTip)}
                                </div>
                            }
                            {
                                (showStartBuilding || showLetsGo) &&
                                <div className={styles.startBuildingBtn} onClick={dispatchAction(Actions.STOP_QUICK_TOUR)}>
                                    {showStartBuilding ? intl.msgJoint(constants.StartBuilding) : intl.msgJoint(constants.letsGo)}
                                </div>
                            }
                            {
                                showWatchVideo &&
                                // eslint-disable-next-line max-len
                                <div className={styles.startBuildingBtn} onClick={dispatchMultipleAction([TUTORIAL_VIDEO_CLICKED, Actions.STOP_QUICK_TOUR])}>
                                    {intl.msgJoint(constants.WatchVideo)}
                                </div>
                            }
                            {
                                showPreviousTipBtn &&
                                <div className={styles.previousTipBtn} onClick={dispatchAction(Actions.SHOW_PREVIOUS_TIP)}>
                                    {intl.msgJoint(constants.Previous)}
                                </div>
                            }
                            {
                                showSkipTour &&
                                <div className={styles.skipTour} onClick={dispatchAction(Actions.STOP_QUICK_TOUR)}>
                                    {intl.msgJoint(constants.SkipTour)}
                                </div>
                            }
                        </div>
                    </div>
                    {tipData.classNames.image && <div className={styles.imageContainer}>
                        <div className={tipData.classNames.image} />
                    </div>}
                </div>
            )}
        </Measure>
    );
});
