import React from 'react';
import { getHeaderAndFooterSection } from '../../../../oneweb/Section/utils';
import * as styles from '../ModernLayoutLeftPanel.css';
import { getHTMLAndDimension } from "../../../../Workspace/epics/componentsEval/adjustAfterUpdate";
import {
    createComponentsFromLayoutAndRearrange, getCmpsMapForLayout,
    getPropsForSidebarDesktopPreview,
    getModernLayoutYourItemHeight,
    arrangeComponentsAndUpdateSectionHeight
} from "../../../utils";
import type { SectionComponent } from "../../../../oneweb/Section/flowTypes";
import type { Stylesheets } from "../../../../Workspace/epics/stylesheets/flowTypes";
import { DesktopPreview } from "../../../../Preview/View/Desktop/index";
import type { LayoutData, LeftPanelEpicState as ModernLayoutLeftPanel, NewLayoutDataPerSection } from "../../../flowTypes";
import LoadingIndicator from "../../../../../view/common/LoadingIndicator/index";
import type { TemplateComponent } from "../../../../oneweb/Template/flowTypes";
import { maxLayoutWidth } from "../../../layoutsData/constants";
import type { WebShopMHFEpicState } from "../../../../oneweb/WebShopMHF/flowTypes";

type ModernLayoutItemProps = {
    leftPanel: ModernLayoutLeftPanel,
    layoutItem: LayoutData,
    section: SectionComponent,
    componentsEval: Record<string, any>,
    styleSheets: Stylesheets,
    globalVariables: Record<string, any>,
    template: TemplateComponent,
    MHFStoredLayoutsData: NewLayoutDataPerSection,
    dispatch: Dispatch,
    isYourLayoutItem?: boolean,
    isActivated?: boolean,
    isForceUpdateRequired?: boolean,
    webshopMHFData: WebShopMHFEpicState,
};

const _parseInt = val => parseInt(val, 10);
const availableLayoutWidth = _parseInt(styles.modernLayoutLeftPanelWidth)
    - (2 * _parseInt(styles.layoutItemMargin));
const getLayoutId = (layoutItem, isYourLayoutItem) => (isYourLayoutItem ? 'Your-' + layoutItem.id : layoutItem.id);

const ModernLayoutItem = ({
    layoutItem,
    leftPanel,
    section,
    template,
    componentsEval,
    styleSheets,
    globalVariables,
    MHFStoredLayoutsData,
    dispatch,
    isYourLayoutItem,
    isActivated,
    webshopMHFData,
    isForceUpdateRequired,
}: ModernLayoutItemProps) => {
    const [state, setState] = React.useState<Record<string, any> | null>(null);
    const layoutMinWidth = React.useRef<number>();

    React.useEffect(() => {
        const {
            scope: { currentPageId, siteMap, siteSettings, componentsDependencies },
            state: { componentsMap }
        } = componentsEval;
        let layoutId = layoutItem.id,
            newComponentsMap = getCmpsMapForLayout(
                section,
                componentsMap,
                MHFStoredLayoutsData,
                layoutId
            ),
            heightsMap = leftPanel.layoutItemHeights[getLayoutId(
                layoutItem,
                isYourLayoutItem
            )],
            renderTmplWidth = maxLayoutWidth;
        if (!newComponentsMap) {
            newComponentsMap = createComponentsFromLayoutAndRearrange(
                layoutItem,
                section,
                componentsEval.state,
                componentsDependencies,
                renderTmplWidth,
                true
            ).newComponentsMap;
        }
        const { header, footer } = getHeaderAndFooterSection(newComponentsMap);
        [header, footer].forEach(cmp => {
            if (!cmp) {
                return;
            }
            const { id, modernLayout = {} } = cmp;
            newComponentsMap = {
                ...newComponentsMap,
                [id]: { ...cmp, modernLayout: { ...modernLayout, active: true } }
            };
            newComponentsMap = arrangeComponentsAndUpdateSectionHeight(newComponentsMap, cmp.id, true);
        });
        const response = getHTMLAndDimension(
            newComponentsMap,
            { ...template, width: renderTmplWidth },
            currentPageId,
            styleSheets,
            siteMap,
            siteSettings,
            componentsDependencies,
            globalVariables,
            true,
            true,
            webshopMHFData,
            true
        );

        layoutMinWidth.current = state && state.props.data.minPageWidth;
        const layoutWidth = response.props.data ? response.props.data.minPageWidth : renderTmplWidth;
        const scale = availableLayoutWidth / layoutWidth;

        setState({
            scale,
            props: response.props,
            height: heightsMap && heightsMap.height,
            width: layoutWidth
        });
    }, [section.modernLayout]);

    const prevLayoutItemIdRef = React.useRef<string>();
    React.useEffect(() => {
        prevLayoutItemIdRef.current = layoutItem.id;
    }, [layoutItem.id]);
    const prevLayoutItemId = prevLayoutItemIdRef.current;

    const prevOptionsRef = React.useRef();
    React.useEffect(() => {
        prevOptionsRef.current = section.modernLayout && section.modernLayout.options;
    }, [section.modernLayout]);
    const prevOptions = prevOptionsRef.current;

    if (!state) {
        return null;
    }

    const layoutItemId = getLayoutId(layoutItem, isYourLayoutItem);

    const props = {
            ...getPropsForSidebarDesktopPreview(state.props, { ...layoutItem, id: layoutItemId }),
            dispatch
        },
        showLoadingIndicator = (!(leftPanel.layoutItemHeights[layoutItemId]) || !(leftPanel.layoutItemHeights[layoutItemId].show));

    const isLayoutIdChanged = layoutItem.id !== prevLayoutItemId,
        isOptionsChanged = (section.modernLayout && section.modernLayout.options
         && prevOptions && section.modernLayout.options.some((option, i) => prevOptions[i] !== option)),
        // @ts-ignore
        isTemplateWidthChanged = !isNaN(layoutMinWidth.current) && (layoutMinWidth.current !== (!!state && state.props.data.minPageWidth));

    if (isTemplateWidthChanged) {
        layoutMinWidth.current = state.props.data.minPageWidth;
    }

    props.forceUpdate =
    (isForceUpdateRequired || isYourLayoutItem) ?
        (isLayoutIdChanged || isOptionsChanged || isTemplateWidthChanged) : false;

    return <div
        className={styles.layoutItemContainer}
        style={{
            height: getModernLayoutYourItemHeight(leftPanel, layoutItemId, state.scale)
        }}
    >
        {showLoadingIndicator &&
        <LoadingIndicator />}
        {isActivated && !showLoadingIndicator && <React.Fragment>
            <div className={styles.selectedLayoutMask} />
            <div className={styles.selectedLayoutBorder} />
            <div className={styles.selectedTick1} />
            <div className={styles.selectedTick2} />
        </React.Fragment>}
        <DesktopPreview
            style={{
                transform: `scale(${state.scale || 1})`,
                transformOrigin: 'left top',
                height: leftPanel.layoutItemHeights[layoutItemId] && leftPanel.layoutItemHeights[layoutItemId].height,
                width: state.width
            }}
            className={styles.sidebarDesktopPreview}
            {...props}
        />
    </div>;
};

export default ModernLayoutItem;
