import { makeApplyEditorChangesReducer } from "./helpers/helpers";
import { TINY_MCE_SET_CONTENT } from "../actionTypes";
import { putCursorAtTheEndOfEditor } from "../editorUtils/methods/helpers/selection";

const onSetContentUpdater = {
    conditions: [TINY_MCE_SET_CONTENT],
    reducer: makeApplyEditorChangesReducer(({ state, scope, values: [content], editor }) => {
        putCursorAtTheEndOfEditor(editor);
        editor.insertContent(content);

        return {
            state,
            scope
        };
    })
};

export {
    onSetContentUpdater
};
