/* @flow */

const { SubscriptionType } = require("./constants.js");

const ValidSubscriptionTypes = [
    SubscriptionType.BASIC,
    SubscriptionType.NORMAL,
    SubscriptionType.PREMIUM,
    SubscriptionType.ECOMMERCE,
    SubscriptionType.ECOMMERCE_DIFM
];

const
    isUnknownSubscription = (subscriptionType /*: string */) /*: boolean */ =>
        !subscriptionType || (subscriptionType === SubscriptionType.UNKNOWN),
    isTrialSubscription = (subscriptionType /*: string */) /*: boolean */ => (
        (subscriptionType === SubscriptionType.DEMO) || (subscriptionType === SubscriptionType.TRIAL)
    ),
    isBasicSubscription = (subscriptionType /*: string */) /*: boolean */ => (subscriptionType === SubscriptionType.BASIC),
    isTrialOrBasicSubscription = (subscriptionType /*: string */) /*: boolean */ => (
        (subscriptionType === SubscriptionType.BASIC) ||
        (subscriptionType === SubscriptionType.DEMO) ||
        (subscriptionType === SubscriptionType.TRIAL)),
    isNormalSubscription = (subscriptionType /*: string */) /*: boolean */ => (subscriptionType === SubscriptionType.NORMAL),
    isPremiumSubscription = (subscriptionType /*: string */) /*: boolean */ => (subscriptionType === SubscriptionType.PREMIUM),
    isEcommerceSubscription = (subscriptionType /*: string */) /*: boolean */ => {
        return (subscriptionType === SubscriptionType.ECOMMERCE || subscriptionType === SubscriptionType.ECOMMERCE_DIFM)
    },
    isInvalidSubscription = (subscriptionType /*: string */) /*: boolean */ =>
        !ValidSubscriptionTypes.includes(subscriptionType),
    isValidSubscription = (subscriptionType /*: string */) /*: boolean */ =>
        ValidSubscriptionTypes.includes(subscriptionType) || isTrialSubscription(subscriptionType);

module.exports = {
    isUnknownSubscription,
    isTrialSubscription,
    isBasicSubscription,
    isTrialOrBasicSubscription,
    isNormalSubscription,
    isPremiumSubscription,
    isEcommerceSubscription,
    isInvalidSubscription,
    isValidSubscription,
};
