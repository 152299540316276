import * as tooltipActionTypes from "../../Tooltip/actionTypes";
import * as mouseUtils from "../../../utils/mouse";

type Props = {
    dispatch: Dispatch,
    id: string,
    tipWidth?: number
}

export default ({ dispatch, id, tipWidth = 248 }: Props) => ({
    onMouseEnter: (e: React.MouseEvent<any, MouseEvent>) => {
        dispatch({
            type: tooltipActionTypes.SHOW_FORCE_DISABLED_INSERTER_TOOLTIP,
            payload: {
                id,
                boundingClientRect: mouseUtils.getBoundingClientRect(e),
                tipWidth
            }
        });
    },
    onMouseLeave: () => dispatch({ type: tooltipActionTypes.CLOSE_COMPONENT_LIST_TOOLTIP })
});
