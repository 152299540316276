import React from 'react';
import cx from 'classnames';
import styles from './NotReady.css';
import selectorStyles from './ProductSelector.css';
import Msg from "../../../../../view/intl/Msg";
import { SHOW_WEBSHOP_ADMIN_DIALOG } from "../../../WebShop/WebshopAdminDialog/actionTypes";
import { HIDE_FEATURED_PRODUCTS_SELECTOR_DIALOG } from '../../SelectorDialog/actionTypes';

type Props = {
    appDispatch: Dispatch;
};

const NotReady = ({ appDispatch }: Props) => {
    return (
        <div className={selectorStyles.notReady}>
            <div className={cx(styles.main, styles.alreadyAdded)}>
                <h3 className={styles.infoHeader}>
                    <Msg k="component.webshop.featuredProducts.shopNotReady">
                        Your shop is not yet ready!
                    </Msg>
                </h3>

                <div className={styles.info}>
                    <div className={cx(styles.addShop, selectorStyles.blocksMargin)}>
                        <button
                            onClick={() => {
                                appDispatch({ type: HIDE_FEATURED_PRODUCTS_SELECTOR_DIALOG });
                                appDispatch({ type: SHOW_WEBSHOP_ADMIN_DIALOG });
                            }}
                        >
                            <Msg k="webshopInserter.manageShop">
                                Manage shop
                            </Msg>
                        </button>
                    </div>
                </div>
                <div className={cx(styles.footer, selectorStyles.blocksMargin)}>
                    <div className={styles.subInfoHeader}>
                        <Msg k="webshopInserter.didYouKnowThat">
                            Did you know that you can:
                        </Msg>
                    </div>
                    <ul>
                        <li>
                            <Msg k="webshopInserter.showRelatedProduct">
                                Show related products
                            </Msg>
                        </li>
                        <li>
                            <Msg k="webshopInserter.createOffers">
                                Create discount codes & special offers
                            </Msg>
                        </li>
                        <li>
                            <Msg k="webshopInserter.customizeProducts">
                                Customize the order of products
                            </Msg>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default NotReady;
