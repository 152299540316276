import * as pageIds from "./pageIds";
import { ComponentNames } from "../../constants";
import BACKGROUND from "../kind";

export default {
    [pageIds.MAIN]: ComponentNames[BACKGROUND],
    [pageIds.GRADIENT_SETTINGS]: 'msg: component.background.page.gradient {Colour}',
    [pageIds.IMAGE_PATTERN]: 'msg: common.background {Background}',
    [pageIds.BORDER]: 'msg: common.border {Border}',
};
