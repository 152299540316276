import * as pageIds from "./pageIds";
import { MOBILE_VIEW, MobileViewTitle } from "../../../PropertiesPanel/view/MobileView/constants";

export default {
    [pageIds.MAIN]: "msg: component.table.title {Table}",
    [pageIds.TableStyle]: "msg: component.table.pp.tableStyle.title {Table style}",
    [pageIds.SizeAndSpacing]: "msg: component.table.pp.tableStyle.sizeAndSpacing.title {Size & spacing}",
    [pageIds.Border]: "msg: common.border {Border}",
    [pageIds.Background]: "msg: common.background {Background}",
    [pageIds.TextStyle]: "msg: component.table.pp.textStyle.title {Text style}",
    [MOBILE_VIEW]: MobileViewTitle
};
