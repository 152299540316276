export const imageRatioOptions = [
        {
            label: "msg: webshop.Square {Square}",
            value: "square"
        },
        {
            label: "msg: webshop.Landscape {Landscape}",
            value: "landscape"
        },
        {
            label: "msg: webshop.Portrait {Portrait}",
            value: "portrait"
        },
        {
            label: "msg: webshop.Actual {Actual ratio}",
            value: "actual"
        }
    ],
    productPerPageOptions = [
        {
            label: "25",
            value: "25"
        },
        {
            label: "50",
            value: "50"
        },
        {
            label: "75",
            value: "75"
        }
    ],
    labelPositionOptions = [
        {
            label: "msg: webshop.topLeft {Top left}",
            value: "topLeft"
        },
        {
            label: "msg: webshop.topRight {Top right}",
            value: "topRight"
        },
        {
            label: "msg: webshop.bottomLeft {Bottom left}",
            value: "bottomLeft"
        },
        {
            label: "msg: webshop.bottomRight {Bottom right}",
            value: "bottomRight"
        }
    ],
    sortByOptions = [
        {
            label: "msg: webshop.Alphabetic {Alphabetical}",
            value: "name-ascending"
        },
        {
            label: "msg: webshop.Newest {Newest}",
            value: "age-ascending"
        },
        {
            label: "msg: PriceLowest {Price: Low to high}",
            value: "price-ascending"
        },
        {
            label: "msg: webshop.PriceHighest {Price: High to low}",
            value: "price-descending"
        }
    ],
    categoryPositionOptions = [
        {
            label: "msg: webshop.categoryPosition.above {Above shop}",
            value: "default"
        },
        {
            label: "msg: webshop.categoryPosition.left {Left of shop}",
            value: "left"
        }
    ];
