export const
    CHANGE_SITE_SETTINGS_PUBLISH_WITH_SSL_ACTION = "CHANGE_SITE_SETTINGS_PUBLISH_WITH_SSL_ACTION",
    CHANGE_SITE_SETTINGS_DROP_HTML_EXTENSION_ACTION = "CHANGE_SITE_SETTINGS_DROP_HTML_EXTENSION_ACTION";

export const
    changePublishWithSSLAction = (value: boolean): Action => ({
        type: CHANGE_SITE_SETTINGS_PUBLISH_WITH_SSL_ACTION,
        payload: value
    }),
    changeDropHtmlExtensionAction = (value: boolean): Action => ({
        type: CHANGE_SITE_SETTINGS_DROP_HTML_EXTENSION_ACTION,
        payload: value
    });
