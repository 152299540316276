/* eslint-disable max-len */
import React from 'react';
import { injectIntl, Msg, makeLinkIntlTag } from '../../view/intl/index';
import { Dialog, DialogBody } from '../../view/common/dialogs/baseDialog/index';
import getCenteredDialogConfig from '../DialogManager/getCenteredDialogConfig';
import DialogTitleBox from "../../view/common/dialogs/baseDialog/DialogTitleBox";
import HorizontalSpacer from "../../view/common/HorizontalSpacer";
import { StripTypes } from "../../view/common/dialogs/baseDialog/Dialog";
import warningDialogStyles from '../../view/common/dialogs/WarningDialog/WarningDialog.css';
import styles from './styles.css';
import { isIE9OrBelow } from './constants';

const
    BrowserUrl = {
        CHROME: 'https://google.com/intl/{lang}/chrome/',
        FF: 'https://www.mozilla.org/{lang}/firefox/',
        SAFARI: 'https://support.apple.com/{locale}/HT204416',
        OPERA: 'https://www.opera.com/{lang}',
        EDGE: 'https://www.microsoft.com/{locale}/windows/microsoft-edge',
    },
    makeBrowserLinks = urls => Object
        .keys(urls)
        .reduce(
            (acc, k) => Object.assign(acc, { [k]: makeLinkIntlTag({ href: urls[k] }) }),
            {},
        );

const
    width = 680,
    height = 470,
    title = 'msg: installNewBrowser.title {Sorry, it looks like you are using a browser that is not supported by Website Builder}',
    getMsg1 = intl => intl.msgJoint('msg: installNewBrowser.msg1 {It looks like you\'re using an old version of Internet Explorer that can\'t be upgraded to the latest version. For the best experience on the web and to use Website Builder, we suggest you try Google Chrome or Mozilla Firefox.}'),
    MessageDialog = injectIntl(({ intl }) => {
        const
            chromeLink = intl.msgJoint("msg: browser.links.chrome {https://www.google.com/chrome/}"),
            mozillaLink = intl.msgJoint("msg: browser.links.firefox {https://www.mozilla.org/en-US/firefox/");

        return (
            <Dialog className={isIE9OrBelow ? styles.ieStyles : ""} stripType={StripTypes.WARNING} showCloseBtn={false}>
                <DialogTitleBox
                    title={title}
                    iconClass={warningDialogStyles.iconWarning}
                    titleClass={styles.useAnotherBrowserTitle}
                    extraClass={isIE9OrBelow ? styles.title : ""}
                    className={warningDialogStyles.warningDialogTitle}
                />
                <DialogBody>
                    <HorizontalSpacer />
                    <p>{getMsg1(intl)}</p>
                    <HorizontalSpacer />
                    <div className={styles.browserIconsContainer}>
                        <div className={styles.browserIcon}>
                            <a href={chromeLink} target="_blank">
                                <span className={styles.chromeIcon} />
                            </a>
                            <a href={chromeLink} target="_blank">Google Chrome</a>
                        </div>
                        <div className={styles.browserIcon}>
                            <a href={mozillaLink} target="_blank">
                                <span className={styles.firefoxIcon} />
                            </a>
                            <a href={mozillaLink} target="_blank">Firefox</a>
                        </div>
                    </div>
                    <HorizontalSpacer />
                    <Msg
                        k="browser.upgrade"
                        params={makeBrowserLinks({
                            chromeLink: BrowserUrl.CHROME,
                            ffLink: BrowserUrl.FF,
                            safariLink: BrowserUrl.SAFARI,
                            operaLink: BrowserUrl.OPERA,
                            edgeLink: BrowserUrl.EDGE,
                        })}
                    >
                        {`We recommend using the latest version of <chromeLink>Google Chrome</chromeLink>,
                        <ffLink>Firefox</ffLink>, <safariLink>Safari</safariLink>, <operaLink>Opera</operaLink>
                        or <edgeLink>Edge</edgeLink>.`}
                    </Msg>
                </DialogBody>
            </Dialog>
        );
    });

export const
    dialog = getCenteredDialogConfig(width, height, MessageDialog);
