import StripKind from './Strip/kind';
import SectionKind from './Section/kind';
import GalleryKind from "./Gallery/kind";
import ImageSliderKind from "./ImageSlider/kind";
import { WidgetsGoogleMapsKind } from './Widgets/GoogleMaps/kind';

const map = {
    [StripKind]: true,
    [SectionKind]: true,
};

const
    isStretchComponentKind = (kind: string, stretch: boolean = false) => !!map[kind] || stretch,
    isStretchComponentKindOrServerStretch = ({ kind, stretch }: {
        kind: string,
        stretch?: boolean
    }) => map[kind] || stretch || false,
    isStripComponentKindOrServerType = ({ kind, stretch }: {
        kind: string,
        stretch?: boolean
    }) => kind === StripKind || (stretch && kind === "web.data.components.Block") || false,
    isFullWidthComponent = ({ stretch = false }: Record<string, any>) => stretch,
    isSectionComponentKind =
        ({ kind }: { kind: string }) => kind === SectionKind; // Kind and type is both 'SECTION'

export const fullWidthCmpMap = {
    [GalleryKind]: true,
    [ImageSliderKind]: true,
    [WidgetsGoogleMapsKind]: true,
};
export {
    isStretchComponentKind as default,
    isStretchComponentKind,
    isStretchComponentKindOrServerStretch,
    isStripComponentKindOrServerType,
    isFullWidthComponent,
    isSectionComponentKind,
};
