export const
    pathToTemplateSelectedTheme = ['scope', 'templateSelectedTheme'],
    pathToEditModeComponentId = ['scope', 'editModeComponentId'],
    pathToPushDownHappened = ['scope', 'pushDownHappened'],
    componentsMapPath = ['state', 'componentsMap'],
    componentsMapFromTransientMutationHandlerPath = ['scope', 'componentsMapFromTransientMutationHandler'],
    componentsMapExtensionPath = ['state', 'componentsMapExtension'],
    stateBeforeTransientChangesPath = ['scope', 'stateBeforeTransientChanges'],
    componentsDependenciesPath = ['scope', 'componentsDependencies'],
    userInteractionPath = ['scope', 'userInteraction'],
    sectionInsertionPath = ['scope', 'sectionInsertion'],
    sectionInsertionPlaceholderPath = ['scope', 'sectionInsertion', 'placeholderPosition'],
    sectionInsertionProgressPath = ['scope', 'sectionInsertion', 'inProgress'],
    selectedComponentsIdsPath = ['scope', 'selectedComponentsIds'],
    attachmentsPath = ['scope', 'attachments'],
    mhfCmpDataPath = ['scope', 'mhfCmpsData'],
    userInteractionModePath = [...userInteractionPath, 'mode'],
    userInteractionPayloadPath = [...userInteractionPath, 'payload'],
    userInteractionComponentDuplicatedPath = [...userInteractionPayloadPath, 'isDuplicated'],
    userInteractionPayloadNewMousePosition = [...userInteractionPayloadPath, 'newMousePosition'],
    userInteractionComponentsIdsPath = [...userInteractionPayloadPath, 'componentsIds'],
    movingComponentsPath = ['scope', 'movingComponents'];
