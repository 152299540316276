import React from "react";
export const SvgEmailRoundedFillMulticolor = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <rect width={48} height={48} fill="#999999" rx={6} />
            <g fill="#ffffff">
                <path d="M16.656 15.837c-1.254 0-2.272.99-2.272 2.213v10.622c0 1.222 1.018 2.213 2.272 2.213h15.45c1.254 0 2.271-.991 2.271-2.213V18.05c0-1.222-1.017-2.213-2.272-2.213H16.656zm0-1.328h15.45c2.007 0 3.634 1.585 3.634 3.54v10.623c0 1.955-1.627 3.54-3.635 3.54H16.656c-2.007 0-3.635-1.585-3.635-3.54V18.05c0-1.956 1.628-3.54 3.635-3.54z" />
                <path d="M26.378 24.475l-1.5 1.554a.694.694 0 01-.993 0l-1.502-1.554-4.79 4.666a.694.694 0 01-.964 0 .652.652 0 010-.939l4.82-4.694-4.835-5.003a.652.652 0 01.03-.939.694.694 0 01.963.029l6.774 7.01 6.774-7.01a.694.694 0 01.963-.029.652.652 0 01.03.939l-4.835 5.003 4.82 4.694c.266.26.266.68 0 .94a.694.694 0 01-.964 0l-4.79-4.667z" />
            </g>
        </g>
    </svg>
);
