import * as React from 'react';
import styles from './SectionLinkForm.css';
import { injectIntl } from "../../../../view/intl";

const SectionLinkOutdatedView = ({ intl }) => {
    const description = `msg: tooltip.sectionLinkOutdated.text {This section link leads to a strip. New section links can only be linked to sections. If you change this section link you will not be able to link to the strip again.}`; //eslint-disable-line
    const heading = `msg: tooltip.sectionLinkOutdated.heading.text {Outdated section link}`;
    return (
        <div>
            <div className={styles.headingContainer}>
                <span className={styles.warningIcon} />
                <span className={styles.tooltipHeading}>{intl.msgJoint(heading)}</span>
            </div>
            <div className={styles.tooltipWarningText}>
                {intl.msgJoint(description)}
            </div>
        </div>
    );
};

export const SectionLinkOutdated = injectIntl(SectionLinkOutdatedView);
