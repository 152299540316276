import { getBBoxDimensions } from '../../../../src/utils/bBox';
import { CROP_RATIO } from '../../../../src/components/oneweb/Gallery/constants/index';
import type { GalleryComponent } from "../../../../src/components/oneweb/Gallery/flowTypes";
import type { GalleryIn } from './flowTypes';

const
    getLayoutConfig = (componentWidth, galleryIn) => {
        /* from old web editor */
        let
            imagesPerRow,
            maxThumbnailWidth,
            maxThumbnailHeight,
            rows;

        const
            { crop, scale, images } = galleryIn;

        maxThumbnailWidth = scale * componentWidth;

        if (crop) {
            maxThumbnailHeight = maxThumbnailWidth * CROP_RATIO;
        } else {
            maxThumbnailHeight = Math.min.apply(Math, images.map(image => {
                const asset = image.asset;
                return asset.height * (maxThumbnailWidth / asset.width);
            }));
        }

        imagesPerRow = Math.floor(componentWidth / maxThumbnailWidth);
        rows = Math.ceil(images.length / imagesPerRow);

        return {
            maxThumbnailWidth,
            maxThumbnailHeight,
            imagesPerRow,
            rows
        };
    },
    /* roundScaleToNearestAvailableInOldWebEditor = (scale) => {
     const possibleScales = [
     0.1, 0.109, 0.118, 0.127, 0.136, 0.145, 0.154, 0.163, 0.172, 0.181, 0.19, 0.199, 0.208, 0.217, 0.226,
     0.235, 0.244, 0.253, 0.262, 0.271, 0.28, 0.289, 0.298, 0.307, 0.316, 0.325, 0.334, 0.343, 0.352, 0.361,
     0.37, 0.379, 0.388, 0.397, 0.406, 0.415, 0.424, 0.433, 0.442, 0.451, 0.46, 0.469, 0.478, 0.487, 0.496,
     0.505, 0.514, 0.523, 0.532, 0.541, 0.55, 0.559, 0.568, 0.577, 0.586, 0.595, 0.604, 0.613, 0.622, 0.631,
     0.64, 0.649, 0.658, 0.667, 0.676, 0.685, 0.694, 0.703, 0.712, 0.721, 0.73, 0.739, 0.748, 0.757, 0.766,
     0.775, 0.784, 0.793, 0.802, 0.811, 0.82, 0.829, 0.838, 0.847, 0.856, 0.865, 0.874, 0.883, 0.892, 0.901,
     0.91, 0.919, 0.928, 0.937, 0.946, 0.955, 0.964, 0.973, 0.982, 0.991, 0.999
     ];

     let i = 0;
     while (possibleScales[i] < scale && possibleScales.length > i - 1) i++;

     const
     nextScale = possibleScales[i] || R.last(possibleScales),
     prevScale = possibleScales[i - 1] || possibleScales[0],
     nextDiff = nextScale - scale,
     prevDiff = scale - prevScale;

     return nextDiff < prevDiff ? nextScale : prevScale;
     }, */
    // scale can become negative if width < spacings width
    adjustScale = scale => {
        const
            minPossibleScale = 0.1,
            maxPosibleScale = 0.999;

        if (scale < minPossibleScale) {
            return minPossibleScale;
        }
        if (scale > maxPosibleScale) {
            return maxPosibleScale;
        }

        return scale;
    },
    to = (galleryIn: GalleryIn) => {
        if (galleryIn.columns) {
            const
                { bbox, spacingPx, relIn, relTo } = galleryIn,
                { left, top, right, bottom } = bbox,
                // @ts-ignore this can cause a bug
                spaceBetweenRows = spacingPx / 2,
                newLeft = left + spaceBetweenRows,
                newTop = top + spaceBetweenRows,
                newRight = right - spaceBetweenRows,
                newBottom = bottom - spaceBetweenRows,
                newRelIn = relIn && {
                    id: relIn.id,
                    left: relIn.left + spaceBetweenRows,
                    top: relIn.top + spaceBetweenRows,
                    right: relIn.right - spaceBetweenRows,
                    bottom: relIn.bottom - spaceBetweenRows
                },
                newRelTo = relTo && {
                    id: relTo.id,
                    below: relTo.below + spaceBetweenRows
                };

            return {
                left: newLeft,
                top: newTop,
                width: newRight - newLeft,
                height: newBottom - newTop,
                columns: galleryIn.columns,
                relIn: newRelIn,
                relTo: newRelTo,
                spacingPx
            };
        } else {
            const
                { bbox, relIn, relTo } = galleryIn,
                { width } = getBBoxDimensions(bbox),
                { imagesPerRow, maxThumbnailWidth } = getLayoutConfig(width, galleryIn),
                spaceBetweenRows = Math.floor(((width / imagesPerRow) - maxThumbnailWidth) / 2),
                { left, top, right, bottom } = bbox,
                newLeft = left + spaceBetweenRows,
                newTop = top + spaceBetweenRows,
                newRight = right - spaceBetweenRows,
                newBottom = bottom - spaceBetweenRows,
                spacingPx = 2 * spaceBetweenRows,
                newRelIn = relIn && {
                    id: relIn.id,
                    left: relIn.left + spaceBetweenRows,
                    top: relIn.top + spaceBetweenRows,
                    right: relIn.right - spaceBetweenRows,
                    bottom: relIn.bottom - spaceBetweenRows
                },
                newRelTo = relTo && {
                    id: relTo.id,
                    below: relTo.below + spaceBetweenRows
                };

            return {
                left: newLeft,
                top: newTop,
                width: newRight - newLeft,
                height: newBottom - newTop,
                columns: imagesPerRow,
                relIn: newRelIn,
                relTo: newRelTo,
                spacingPx
            };
        }
    },
    back = (gallery: GalleryComponent) => {
        const
            { spacingPx, columns, relIn, relTo } = gallery,
            columnsScale = 1 / gallery.columns,
            spacingScale = gallery.spacingPx / (gallery.width + gallery.spacingPx),
            scale = columnsScale - spacingScale,
            halfSpacingPx = (gallery.spacingPx / 2),
            left = Math.floor(gallery.left - halfSpacingPx),
            top = Math.floor(gallery.top - halfSpacingPx),
            right = left + gallery.width + gallery.spacingPx,
            bottom = top + gallery.height + gallery.spacingPx,
            newRelIn = relIn && {
                id: relIn.id,
                left: relIn.left - halfSpacingPx,
                top: relIn.top - halfSpacingPx,
                right: relIn.right + halfSpacingPx,
                bottom: relIn.bottom + halfSpacingPx
            },
            newRelTo = relTo && {
                id: relTo.id,
                below: relTo.below - halfSpacingPx
            };

        return {
            bbox: { left, top, right, bottom },
            /*
             * If we round scale to available in old webeditor we will lose percision (spacingPx)
             * it is not possible to make 1px spacing in old webeditor
             * so we will save scale with maximum possible percision to old webeditor
             * */
            // scale: roundScaleToNearestAvailableInOldWebEditor(scale),
            scale: adjustScale(scale),
            spacingPx,
            relIn: newRelIn,
            relTo: newRelTo,
            columns
        };
    };

export { to, back };
