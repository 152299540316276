import * as R from 'ramda';
import * as mp from '../path';
import * as padding from './padding';
import fontMapperConfig from './font';
import { makeBorderToCssConfig } from "../border/index";
import * as backgroundMapper from "../background/index";
import configMappers from "../configMappers";
import * as textShadowMapper from "../shadow";
import p from '../../utils/pipePath';
import { SpecConfig } from '../makeSpec/flowTypes';

const
    font = configMappers(fontMapperConfig),
    backgroundCssConfig = (prefix: string): SpecConfig => ({
        background: [p(prefix, mp.background), R.pipe(backgroundMapper.toCss, R.prop('background'))],
        backgroundImage: [p(prefix, mp.background), R.pipe(backgroundMapper.toCss, R.prop('backgroundImage'))],
        backgroundRepeat: [p(prefix, mp.background), R.pipe(backgroundMapper.toCss, R.prop('backgroundRepeat'))],
        backgroundPosition: [p(prefix, mp.background), R.pipe(backgroundMapper.toCss, R.prop('backgroundPosition'))],
        backgroundSize: [p(prefix, mp.background), R.pipe(backgroundMapper.toCss, R.prop('backgroundSize'))],
        backgroundColor: [p(prefix, mp.background), R.pipe(backgroundMapper.toCss, R.prop('backgroundColor'))],
        backgroundAttachment: [p(prefix, mp.background), R.pipe(backgroundMapper.toCss, R.prop('backgroundAttachment'))]
    }),

    borderToCssConfig = (prefix: string): SpecConfig => makeBorderToCssConfig(p(prefix, mp.border)),

    highlightConfig: SpecConfig = {
        backgroundColor: [mp.textHighlight, font.color.toCss]
    },
    fontCssConfig: SpecConfig = {
        color: [mp.textColor, font.color.toCss],
        fontSize: [mp.textSize, font.size.toCss],
        fontWeight: [mp.textBold, font.weight.toCss],
        fontStyle: [mp.textItalic, font.italic.toCss],
        textDecoration: [mp.textUnderline, font.underline.toCss],
        fontFamily: [mp.textFont, font.family.toCss],
        textShadow: [mp.textShadow, textShadowMapper.toCss]
    },

    baseCssConfig = (prefix: string): SpecConfig => ({
        padding: [p(prefix, mp.padding), padding.toCss],
        ...borderToCssConfig(prefix),
        ...backgroundCssConfig(prefix)
    }),
    cssConfig: SpecConfig = {
        ...baseCssConfig(mp.block),
        ...fontCssConfig
    },
    pseudoCssConfig: SpecConfig = {
        ...baseCssConfig(mp.block),
        color: [mp.textColor, font.color.toCss],
    };

export {
    baseCssConfig,
    fontCssConfig,
    cssConfig,
    pseudoCssConfig,
    highlightConfig
};
