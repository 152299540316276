type Params = {
    onClick: React.MouseEventHandler,
    onDbClick: React.MouseEventHandler,
    delay?: number,
};

export const makeClickHandler = (params: Params): React.MouseEventHandler => {
    const { onClick, onDbClick, delay = 300 } = params;
    let timer: undefined | ReturnType<typeof setTimeout>;
    return (e: React.MouseEvent<any>) => {
        if (!timer) {
            onClick(e);
            timer = setTimeout(() => {
                clearTimeout(timer);
                timer = undefined;
            }, delay);
        } else onDbClick(e);
    };
};
