import React, { useState } from "react";

// FIXME: ASAP: TODO: Move the axios call to standard "dal" (based on "fetch")
// import axios from 'axios';

import * as styles from './generalTab.css';

import { getAppConfig } from "../../../components/App/epics/appConfig/appConfig";

import localStorage from '../../../utils/localStorage';

import { GMBConnectionStatus } from './GMBConnectionStatus';
import { GMBDisconnect } from './GMBDisconnect';
import { GMBConnectedData } from './GMBConnectedData';
import { GMBUpdate } from './GMBUpdate';
import { AllowMessengerChatWidgetKey } from "../../../components/oneweb/FacebookChat/constants/index";
import { EnableDashboardAnalyticsKey } from "../../../../../src/dashboard/src/utils/configUtils";

/*
const loginDone = function (event) {
    const getOrigin = function (win) {
        const location = win.location;
        // IE and some other browsers don't have window.location.origin
        // See: http://tosbourn.com/a-fix-for-window-location-origin-in-internet-explorer/
        return location.origin || (location.protocol + "//" + location.hostname + (location.port ? ':' + location.port : ''));
    };

    if (event.origin !== getOrigin(window)) {
        return;
    }

    const data = event.data;
    if (data.purpose !== 'gmb-login') {
        return;
    }

    if (data.status === 'success') {
        console.log(data);
        // setTimeout(async () => {
        //     // FIXME: TODO: ASAP: This is temporary code and needs to be moved to "dal"
        //     // FIXME: TODO: ASAP: Fetch the domain appropriately
        //     // FIXME: TODO: ASAP: Only save the "refresh_token", "scope" and "token_type". Other data shouldn't be saved.
        //     // FIXME: TODO: ASAP: If "refresh_token" is not returned, then show an appropriate error. We may want to enable "prompt=consent" for Google login URL.
        //     const domain = $R.store.getState().preferences.owner; // eslint-disable-line no-undef
        //     const gmbToken = (
        //         data.json &&
        //         data.json.gmbToken
        //     ) || {};
        //     await axios.post(
        //         `/api/v1/${domain}/doc/gmbOAuth2Token`,
        //         {
        //             doc: {
        //                 gmbToken
        //             }
        //         }
        //     );
        // });
    } else {
        // FIXME: TODO: ASAP: Handle error properly
        console.error('Encountered an unexpected error while logging in for Google My Business');
    }
};
if (!window.gmbOAuth2EventListenerAdded) {
    window.addEventListener('message', loginDone, false);
    window.gmbOAuth2EventListenerAdded = true;
}
/* */

export const GeneralTab = () => {
    const appConfig = getAppConfig();

    let businessListingActivatedFromLocalStorage = localStorage.get('allow-business-listing') === 'yes';
    const [allowBusinessListing, setAllowBusinessListing] = useState(businessListingActivatedFromLocalStorage);

    let chatWidgetFromLocalStorage = localStorage.get(AllowMessengerChatWidgetKey) === 'yes';
    const [allowMessengerChatWidget, setAllowMessengerChatWidget] = useState(chatWidgetFromLocalStorage);

    const dashboardAnalyticsFromLocalStorage = (
        window.localStorage &&
        window.localStorage.getItem(EnableDashboardAnalyticsKey) === 'yes'
    );
    const [enableDashboardAnalytics, setEnableDashboardAnalytics] = useState(dashboardAnalyticsFromLocalStorage);

    const host = window.location.host;

    const gmbAuthUrl = `${appConfig.oneWeb.gmb.gmbOAuth2PopupUrl}&redirect_uri=https://${host}/gmb/oAuth2RedirectUrl`;
    return (
        <div className={styles.bodyContainer}>
            <div className={styles.dataContainer}>
                <div style={{ marginTop: 20, display: 'flex' }}>
                    <div style={{ width: 250 }}>
                        JavaScript Performance Monitor
                    </div>
                    <div>
                        <button
                            type="button"
                            onClick={() => {
                                if (!window.flagJsPerformanceMonitor) {
                                    const script = document.createElement('script');
                                    script.onload = function () {
                                        // @ts-ignore
                                        const stats = new Stats(); // eslint-disable-line no-undef

                                        const el = document.body.appendChild(stats.dom);
                                        window.elJsPerformanceMonitor = el;
                                        el.style.zIndex = '10000000';
                                        el.style.top = '50px';
                                        el.style.left = '';
                                        el.style.right = '20px';
                                        requestAnimationFrame(function loop() {
                                            stats.update();
                                            requestAnimationFrame(loop);
                                        });
                                    };
                                    script.src = 'https://mrdoob.github.io/stats.js/build/stats.min.js';

                                    document.head.appendChild(script);

                                    window.flagJsPerformanceMonitor = true;
                                }

                                if (window.elJsPerformanceMonitor) { // eslint-disable-line no-lonely-if
                                    const oldDisplayValue = window.elJsPerformanceMonitor.style.display;
                                    if (oldDisplayValue === 'none') {
                                        window.elJsPerformanceMonitor.style.display = '';
                                    } else {
                                        window.elJsPerformanceMonitor.style.display = 'none';
                                    }
                                }
                            }}
                        >
                            Show/Hide
                        </button>
                    </div>
                </div>

                <div style={{ marginTop: 20, display: 'flex' }}>
                    <div style={{ width: 250 }}>
                        Business Listing Tab in Site Settings
                    </div>
                    <div>
                        {
                            !allowBusinessListing &&
                            <button
                                type="button"
                                onClick={() => {
                                    setAllowBusinessListing(true);
                                    localStorage.set('allow-business-listing', 'yes');
                                }}
                            >
                                Show
                            </button>
                        }
                        {
                            allowBusinessListing &&
                            <button
                                type="button"
                                onClick={() => {
                                    setAllowBusinessListing(false);
                                    localStorage.remove('allow-business-listing');
                                }}
                            >
                                Hide
                            </button>
                        }
                    </div>
                </div>

                <div style={{ marginTop: 20, display: 'flex' }}>
                    <div style={{ width: 250 }}>
                        Google My Business
                    </div>
                    <div>
                        <button
                            type="button"
                            onClick={() => {
                                // Skipping auth
                                // const popupUrl = "/gmb/oAuth2RedirectUrl";

                                // With auth
                                // const popupUrl = "https://accounts.google.com/o/oauth2/v2/auth?prompt=consent&access_type=offline&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fbusiness.manage&response_type=code&client_id=490924528966-jt1g26dlbnstjoofi00t81qkkg720rqb.apps.googleusercontent.com&redirect_uri=http%3A%2F%2Flocal.one.com%3A3000%2Fgmb%2FoAuth2RedirectUrl";

                                const popupUrl = gmbAuthUrl;
                                // @ts-ignore
                                window
                                    .open(
                                        popupUrl,
                                        "Google My Business",
                                        "height=675,width=950,scrollbars=yes,resizable=yes" // scrollbars=yes is required for some browsers (like FF & IE)
                                    )
                                    .focus();
                            }}
                        >
                            Login
                        </button>

                        <button
                            type="button"
                            onClick={() => {
                                const popupUrl = `${gmbAuthUrl}%3FconnectAlso%3Dyes`;
                                // @ts-ignore
                                window
                                    .open(
                                        popupUrl,
                                        "Google My Business",
                                        "height=675,width=950,scrollbars=yes,resizable=yes" // scrollbars=yes is required for some browsers (like FF & IE)
                                    )
                                    .focus();
                            }}
                        >
                            Login with connect
                        </button>
                    </div>
                </div>

                <div style={{ marginTop: 20, display: 'flex' }}>
                    <div style={{ width: 250 }}>
                        Google My Business connection status
                    </div>
                    <div>
                        <GMBConnectionStatus />
                    </div>
                </div>

                <div style={{ marginTop: 20, display: 'flex' }}>
                    <div style={{ width: 250 }}>
                        Google My Business disconnect
                    </div>
                    <div>
                        <GMBDisconnect />
                    </div>
                </div>

                <div style={{ marginTop: 20, display: 'flex' }}>
                    <div style={{ width: 250 }}>
                        Google My Business connected data
                    </div>
                </div>
                <div style={{ marginTop: 5 }}>
                    <div>
                        <GMBConnectedData />
                    </div>
                </div>

                <div style={{ marginTop: 20, display: 'flex' }}>
                    <div style={{ width: 250 }}>
                        Google My Business update data
                    </div>
                    <div>
                        <GMBUpdate />
                    </div>
                </div>
                <div style={{ marginTop: 20, display: 'flex' }}>
                    <div style={{ width: 250 }}>
                        Chat Widget
                    </div>
                    <div>
                        {
                            !allowMessengerChatWidget &&
                            <button
                                type="button"
                                onClick={() => {
                                    setAllowMessengerChatWidget(true);
                                    localStorage.set(AllowMessengerChatWidgetKey, 'yes');
                                }}
                            >
                                Show
                            </button>
                        }
                        {
                            allowMessengerChatWidget &&
                            <button
                                type="button"
                                onClick={() => {
                                    setAllowMessengerChatWidget(false);
                                    localStorage.remove(AllowMessengerChatWidgetKey);
                                }}
                            >
                                Hide
                            </button>
                        }
                    </div>
                </div>
                <div style={{ marginTop: 20, display: 'flex' }}>
                    <div style={{ width: 250 }}>
                        Dashboard Analytics
                    </div>
                    <div>
                        {
                            !enableDashboardAnalytics &&
                            <button
                                type="button"
                                onClick={() => {
                                    setEnableDashboardAnalytics(true);
                                    // eslint-disable-next-line no-unused-expressions
                                    window.localStorage && window.localStorage.setItem(
                                        EnableDashboardAnalyticsKey, 'yes'
                                    );
                                }}
                            >
                                Enable
                            </button>
                        }
                        {
                            enableDashboardAnalytics &&
                            <button
                                type="button"
                                onClick={() => {
                                    setEnableDashboardAnalytics(false);
                                    // eslint-disable-next-line no-unused-expressions
                                    window.localStorage && window.localStorage.removeItem(
                                        EnableDashboardAnalyticsKey
                                    );
                                }}
                            >
                                Disable
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};
