import React from "react";
import { Breakpoint, Theme, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";

import type { SubscriptionTypeT } from "../../../../../wbtgen/src/components/App/epics/subscriptionData/flowTypes";
import { subscriptionTypeSelector } from "../../common/selectors";
import { isFlagMatch } from "../../utils/configUtils";

export const RenderWhen = ({ when, children }: { when: any; children: any }) => (Boolean(when) && children) || null;

type RenderWithLoaderProps = { children: JSX.Element; loader: JSX.Element; isLoading: boolean };

export const RenderWithLoader = ({ children, loader, isLoading }: RenderWithLoaderProps) => {
    if (isLoading) return loader;
    return children;
};

type RenderForSubscriptionsProps = { children: JSX.Element; subscriptions: Array<SubscriptionTypeT> };

export const RenderForSubscriptions = ({ children, subscriptions }: RenderForSubscriptionsProps) => {
    const subscriptionType = useSelector(subscriptionTypeSelector);

    if (subscriptions.length === 0) {
        throw new Error("Received an empty `subscriptions` list prop.");
    }

    return <RenderWhen when={subscriptions.includes(subscriptionType)}>{children}</RenderWhen>;
};

type RenderForBreakpointProps = {
    children: React.ReactNode;
    up: Breakpoint;
};

export const RenderForBreakpoint = ({ children, up }: RenderForBreakpointProps) => {
    const matches = useMediaQuery<Theme>((theme) => theme.breakpoints.up(up));
    return <RenderWhen when={matches}>{children}</RenderWhen>;
};

type RenderForFlagProps = {
    children: React.ReactNode | ((args: { match: boolean }) => any);
    flag: string;
    value?: any;
    strict?: boolean;
};

export const RenderForFlag = ({ children, flag, value = true, strict = false }: RenderForFlagProps) => {
    const match = isFlagMatch(flag, value, strict);

    if (typeof children === "function") {
        return children({ match });
    }

    return <RenderWhen when={match}>{children}</RenderWhen>;
};

type RenderSwitchCaseProps = {
    children?: any;
    when?: boolean;
    default?: boolean;
};

const RenderSwitchCase = ({ children }: RenderSwitchCaseProps) => {
    return children;
};

type RenderSwitchProps = {
    children: React.ReactNode[];
};

export const RenderSwitch = ({ children }: RenderSwitchProps) => {
    const childrenArray = React.Children.toArray(children);
    let defaultChild: JSX.Element | null = null;
    let renderChild: JSX.Element | null = null;

    childrenArray.forEach((child) => {
        if (!(React.isValidElement(child) && child.type === RenderSwitchCase)) {
            throw new Error("`RenderSwitch` can only accept children of type `RenderSwitchCase`.");
        }

        // only return the fist element which satisfies its condition
        if (child.props.when && renderChild === null) {
            renderChild = child;
        }

        if (child.props.default) {
            if (defaultChild !== null) {
                throw new Error("`RenderSwitch` can only accept one default `RenderSwitchCase` child.");
            }

            defaultChild = child;
        }
    });

    return renderChild || defaultChild;
};

RenderSwitch.Case = RenderSwitchCase;
