/* @flow */

/*::
    type Component = {
        type: string
    };

    type Page = {
        id: string,
        templateId: string,
        items: Array<Component>
    };

    type Params = {
        pages: Array<Page>,
        returnTemplatePageIdMap?: boolean
    };

    type WebshopPageIdsData = {
        webshopPageIds: Array<string>,
        templatePageIdMap: Object
    };

    type FeaturedProductsParams = {
        pages: Array<Page>
    };

    type FeaturedProductsPageIdsData = {
        featuredProductsPageIds: Array<string>
    };
 */

const
    containsWebshopComponent = (items /*: Array<Component> */) /*: boolean */ => {
        return Array.isArray(items) && items.some(function(item) {
            return item.type === "web.data.components.WebShop"
        });
    },
    getWebshopPageIds = (params /*: Params */) /*: WebshopPageIdsData */ => {
        let
            pages = params.pages,
            returnTemplatePageIdMap = params.returnTemplatePageIdMap,
            webshopPageIds = [],
            templatePageIdMap = {};

        pages.forEach(function (page) {
            let pageId = page.id;
            if (containsWebshopComponent(page.items)) {
                webshopPageIds.push(pageId);
            }

            if (returnTemplatePageIdMap) {
                let templateId = page.templateId;
                if (!templatePageIdMap[templateId]) {
                    templatePageIdMap[templateId] = [];
                }
                templatePageIdMap[templateId].push(pageId);
            }
        });

        return {
            webshopPageIds: webshopPageIds,
            templatePageIdMap: templatePageIdMap
        };
    },
    containsFeaturedProductComponent = (items /*: Array<Component> */) /*: boolean */ => {
        return Array.isArray(items) && items.some(function(item) {
            return item.type === "PRODUCT_WIDGET" || item.type === "web.data.components.FeaturedProducts"
        });
    },
    getFeaturedProductsPageIds = (params /*: FeaturedProductsParams */) /*: FeaturedProductsPageIdsData */ => {
        let
            pages = params.pages,
            featuredProductsPageIds = [];

        pages.forEach(function (page) {
            let pageId = page.id;
            if (containsFeaturedProductComponent(page.items)) {
                featuredProductsPageIds.push(pageId);
            }
        });

        return { featuredProductsPageIds: featuredProductsPageIds };
    };

module.exports = {
    getWebshopPageIds,
    containsWebshopComponent,
    getFeaturedProductsPageIds
};
