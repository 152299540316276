import * as R from 'ramda';
import positionCalculator, { Positions, CollisionAlignment } from "../../../utils/Position";
import { getOverlappingComponents } from "../../../utils/componentsMap/index";
import registry from "../../../view/oneweb/registry/index";
import {
    componentCommonActionItems,
    componentCommonActionItemsOrder,
    componentDeleteActionItem,
    componentLayerActionItem,
    componentLayeringActionItems,
    overlappingComponentsHeaderItem,
    overlappingComponentsFooterItem,
    workspaceContextMenuGroup,
    mobileViewCommonActionItems,
    mobileViewCommonActionItemsOrder,
    mobileViewSecondaryActionItems,
    mobileViewSecondaryActionItemsOrder,
    pageRefContextMenuPrimaryGroup,
    pageRefContextMenuSecondaryGroup,
    linkPageContextMenuPrimaryGroup,
    linkPageContextMenuSecondaryGroup,
    sectionLinkContextMenuPrimaryGroup,
    sectionLinkContextMenuSecondaryGroup,
    leftPanelComponentsTabContextMenuGroup,
    OVERLAPPING_COMPONENTS_MAIN_MENU_COUNT as overlappingComponentsMainMenuCount,
    ContextMenuDimensions,
    ContextSubMenuDirection,
    ContextMenuStyles,
    componentWrapToCenter,
    componentWrapTopLeft,
    componentWrapTopRight,
    componentWrapAbove,
    componentWrapBelow,
    componentUnwrap, pinToScreen, pinSettings,
    headerFooterSectionCommonActionItems,
    headerFooterSectionCommonActionItemsOrder,
    sectionCommonActionItems,
    sectionCommonActionItemsOrder,
    splitSection, splitHeaderFooterSection, unPin, pinToTop, pinToBottom,
} from "./constants";
import { CONTEXT_MENU_SELECT_OVERLAPPING_COMPONENT } from "../actionTypes";
import getStretchComponentsMap from "../../../redux/modules/children/workspace/getStretchComponentsMap";
import { isStretchComponentKind, isFullWidthComponent } from "../../oneweb/isStretchComponentKind";
import type { Position } from "../../../redux/modules/flowTypes";
import type { BrowserDimensions } from "../../../redux/modules/children/dimensions/index";
import type {
    ComponentsMap,
    ComponentsIds,
    AnyComponent,
    BBox,
    ComponentWrapOptions
} from "../../../redux/modules/children/workspace/flowTypes";
import type {
    ContextMenuItemPartialPropType,
    ContextMenuGroupPartialPropType,
    ComponentMenuExpandableItemPartialPropType
} from "./flowTypes";
import type { ContextMenuItemType } from '../flowTypes';
import * as object from "../../../../utils/object";
import type { DataSiteItem } from "../../../../dal/model/utils/dataSiteItemUtils";
import { isPageRef, isLinkPage } from "../../../../dal/model/utils/dataSiteItemUtils"; // eslint-disable-line no-duplicate-imports
import styles from './ContextMenu.css';
import getCmpTypeById from '../../MobileViewEditor/getCmpTypeById';
import { cmpTypes } from '../../MobileViewEditor/constants';
import getId from '../../MobileViewEditor/getId';
import {
    getSiblingAttachedComponentsMap,
    getAllAttachmentsForCmpIds,
    simpleTopSorter
} from "../../Workspace/epics/componentAttachements/util";
import type { Attachments } from "../../Workspace/epics/componentAttachements/flowTypes";
import {
    getComponentsAbove,
    getComponentsBelow
} from "../../../redux/modules/children/workspace/reducers/utils/moveComponents";
import { isSectionComponent } from "../../oneweb/isSectionComponent";
import { isSectionKind } from "../../oneweb/componentKinds";
import { isFooterSection, isHeaderSection, isHeaderOrFooterSection, isCmpInsideHeader } from "../../oneweb/Section/utils";
import { allComponentIdsInTemplate } from "../../Workspace/epics/isPageMode/utils";
import { Intl } from "../../../view/intl/injectIntl";

type ComponentDefaultItemType = MapT<ContextMenuItemType>

const
    setDisabled = (obj, disabled) => R.assoc('disabled', disabled, obj),
    canHeaderBeSplit = (selectedComponentsIds, componentsMap, attachments) => {
        if (window.location.href.indexOf('setSupportOn') === -1 ||
            window.location.href.indexOf('splitHF') === -1) {
            return false;
        }
        if (selectedComponentsIds.length > 2) {
            return false;
        }
        if (selectedComponentsIds.every(cmpId => componentsMap[cmpId].inTemplate &&
                    isCmpInsideHeader(cmpId, componentsMap, attachments))) {
            if (selectedComponentsIds.length === 1) {
                return true;
            }
            const selectedCmps = selectedComponentsIds.map(id => componentsMap[id])
                .sort(simpleTopSorter);
            if (selectedCmps[1].top > (selectedCmps[0].top + selectedCmps[0].height + 50)) {
                return true;
            }
        }
        return false;
    };

export const
    getComponentCommonActionItems = (
        items: ComponentDefaultItemType,
        itemsStatus: null | undefined | MapT<ComponentDefaultItemType>,
        defaultItemsOverride: null | undefined | ComponentDefaultItemType,
        actionsOrder: Array<string> = componentCommonActionItemsOrder
    ): Array<ContextMenuItemType> => {
        const getCurrentItem = key => (itemsStatus && itemsStatus[key] ?
            R.merge(items[key], itemsStatus[key]) : items[key]);
        return actionsOrder
            .filter(key => {
                const updatedItem = getCurrentItem(key);
                return !updatedItem.hide;
            })
            .map(
                key => {
                    const updatedItem = getCurrentItem(key);
                    return defaultItemsOverride && defaultItemsOverride[key]
                        ? R.merge(updatedItem, defaultItemsOverride[key])
                        : updatedItem;
                }
            );
    },
    getOverlappingComponentsFooterItem = (
        overlappingComponentsSubMenuItems: Array<any>
    ): ComponentMenuExpandableItemPartialPropType => ({
        title: overlappingComponentsFooterItem.title(overlappingComponentsSubMenuItems.length),
        groupDirection: ContextSubMenuDirection.bottomToTop,
        contextMenuGroups: [{
            group: overlappingComponentsSubMenuItems,
            groupClassName: ContextMenuStyles.contextMenuOverlappingComponentsGroup
        }]
    }),

    sortComponentsIdsByOrderIndex = (componentsIds: Array<string>, componentsMap: ComponentsMap) => R.sort(
        (componentId1, componentId2) =>
            (componentsMap[componentId1].orderIndex - componentsMap[componentId2].orderIndex),
        componentsIds
    ),

    isSoleStripComponent = (kind: string, componentsMap: ComponentsMap): boolean =>
        (isStretchComponentKind(kind) && (getStretchComponentsMap(componentsMap).length === 1)),

    // TODO: test after test data is refactored
    filterLayeringComponents = (
        componentsMap: ComponentsMap,
        selectedComponentsIds: ComponentsIds,
        isPageMode: boolean,
        extraFilter?: any
    ): ComponentsMap => object.filter(componentsMap, (component: AnyComponent, componentId: string) => {
        // stretch components arranged on their own
        const
            isStretchSelected = selectedComponentsIds.length === 1
                && isStretchComponentKind(componentsMap[selectedComponentsIds[0]].kind, componentsMap[selectedComponentsIds[0]].stretch),
            isComponentStretch = isStretchComponentKind(component.kind, component.stretch);

        if (!isStretchSelected) {
            // filter out page / template components
            const inTemplate = !isPageMode;
            if (component.inTemplate !== inTemplate) return false;
        }

        if ((isStretchSelected && !isComponentStretch) || (!isStretchSelected && isComponentStretch)) {
            return false;
        }

        // custom filter
        if (extraFilter && !extraFilter(component, componentId)) return false;

        return true;
    }),

    getLayeringOrderIndexFactory = (selector: Function) => (
        componentsMap: ComponentsMap,
        selectedComponentIds: ComponentsIds,
        isPageMode: boolean
    ): number => {
        const orderIndices = object.mapToArray(
            filterLayeringComponents(componentsMap, selectedComponentIds, isPageMode),
            (component: AnyComponent) => component.orderIndex
        );

        return (orderIndices.length && selector(...orderIndices)) || 0;
    },

    // TODO: test after test data is refactored
    getLayeringMinOrderIndex = getLayeringOrderIndexFactory(Math.min),
    getLayeringMaxOrderIndex = getLayeringOrderIndexFactory(Math.max),

    isComponentAtTopOrBottom = (isTop: boolean, componentId: string, componentsMap: ComponentsMap,
        editPageMode: boolean, workspaceBBox: BBox, attachments: Attachments) => {
        const { orderIndex, kind } = componentsMap[componentId];
        if (isSectionKind(kind) || isSoleStripComponent(kind, componentsMap)
            || componentsMap[componentId].isStickyToHeader || isFullWidthComponent(componentsMap[componentId])) {
            return true;
        }
        const siblingCmps = getSiblingAttachedComponentsMap(componentId, attachments, componentsMap);
        if (!siblingCmps) {
            const attachedCmpIds = getAllAttachmentsForCmpIds(attachments, [componentId]),
                nonAttachedCmpsMap = R.filter(c => !attachedCmpIds.includes(c.id), componentsMap);
            return isTop ? getLayeringMaxOrderIndex(nonAttachedCmpsMap, [componentId], editPageMode) === orderIndex
                : getLayeringMinOrderIndex(nonAttachedCmpsMap, [componentId], editPageMode) === orderIndex;
        }
        if (Object.keys(siblingCmps).length === 1) {
            return true;
        }
        const cmpsAboveOrBelow = isTop ?
            getComponentsAbove(siblingCmps, [componentId], false, workspaceBBox, editPageMode)
            : getComponentsBelow(siblingCmps, [componentId], false, workspaceBBox, editPageMode);
        return cmpsAboveOrBelow.length === 0;
    },

    bringToFront = 0,
    sendForward = 1,
    sendBackwards = 2,
    sendToBack = 3,

    getComponentsLayerContextMenuItem = (
        componentsIds: Array<string>,
        componentsMap: ComponentsMap,
        layeringItemsOverride: null | undefined | ComponentDefaultItemType,
        workspaceBBox: BBox,
        attachments: Attachments,
    ): ComponentMenuExpandableItemPartialPropType => {
        let
            componentOnTop = false,
            componentAtBottom = false;

        if (componentsIds.length === 1) {
            const componentId = componentsIds[0],
                inPage = !allComponentIdsInTemplate(componentsIds, componentsMap);

            componentOnTop = isComponentAtTopOrBottom(true, componentId, componentsMap,
                inPage, workspaceBBox, attachments);
            componentAtBottom = isComponentAtTopOrBottom(false, componentId, componentsMap,
                inPage, workspaceBBox, attachments);
        } else if (componentsIds.length > 1) {
            componentOnTop = true;
            componentAtBottom = true;
        }

        return {
            contextMenuGroups: [{
                group: [
                    setDisabled(componentLayeringActionItems[bringToFront], componentOnTop),
                    setDisabled(componentLayeringActionItems[sendForward], componentOnTop),
                    setDisabled(componentLayeringActionItems[sendBackwards], componentAtBottom),
                    setDisabled(componentLayeringActionItems[sendToBack], componentAtBottom)
                ]
            }],
            groupDirection: ContextSubMenuDirection.topToBottom,
            ...componentLayerActionItem
        };
    },

    convertComponentsToContextMenuItem = (
        componentsMap: ComponentsMap,
        componentsIds: Array<string>
    ): Array<ContextMenuItemPartialPropType> => R.map((componentId: string): ContextMenuItemPartialPropType => {
        const
            component = componentsMap[componentId],
            { kind } = component;
        let { label, shortcut } = registry[kind];

        if (isSectionKind(kind)) {
            if (isHeaderSection(component)) {
                label = "msg: common.section.type.header {Header}";
            } else if (isFooterSection(component)) {
                label = "msg: common.section.type.footer {Footer}";
            }
        }

        return {
            icon: shortcut,
            title: label,
            actionInput: CONTEXT_MENU_SELECT_OVERLAPPING_COMPONENT,
            payload: { componentId }
        };
    }, componentsIds),

    getContextMenuGroupsForComponent = (
        componentsMap: ComponentsMap,
        componentId: string,
        itemsStatus: Record<string, any>,
        workspaceBBox: BBox,
        wrapOptions: ComponentWrapOptions,
        attachments: Attachments,
        canBeSplit: boolean,
        mousePosition: Record<string, any>,
    ): Array<ContextMenuGroupPartialPropType> => {
        const
            component = componentsMap[componentId],
            { kind } = component,
            { contextMenu: { items: componentContextMenuItems, defaultItemsOverride, layeringItemsOverride } } = registry[kind], // eslint-disable-line max-len
            componentLayerMenuItem = getComponentsLayerContextMenuItem([componentId], componentsMap, layeringItemsOverride, workspaceBBox, attachments), // eslint-disable-line max-len
            overlappingComponents = getOverlappingComponents(
                componentsMap,
                componentId,
                workspaceBBox,
                mousePosition,
                attachments,
            ),
            overlappingComponentsSubMenuItems =
                convertComponentsToContextMenuItem(componentsMap, overlappingComponents),
            overlappingComponentsMenuItems =
                overlappingComponentsSubMenuItems.slice(0, overlappingComponentsMainMenuCount),
            componentActions = [...componentContextMenuItems, componentLayerMenuItem],
            componentCommonActions = getComponentCommonActionItems(
                componentCommonActionItems, itemsStatus, defaultItemsOverride
            ),
            headerFooterSectionsCommonActions = getComponentCommonActionItems(
                headerFooterSectionCommonActionItems, itemsStatus, defaultItemsOverride, headerFooterSectionCommonActionItemsOrder
            ),
            sectionCommonActions = getComponentCommonActionItems(
                sectionCommonActionItems, itemsStatus, defaultItemsOverride, sectionCommonActionItemsOrder
            ),
            componentDeleteAction = [componentDeleteActionItem],
            overlappingComponentActions = overlappingComponentsMenuItems.length > 0
                ? [overlappingComponentsHeaderItem, ...overlappingComponentsMenuItems] : [];

        if (overlappingComponentsSubMenuItems.length > overlappingComponentsMainMenuCount) {
            // @ts-ignore
            overlappingComponentActions.push(getOverlappingComponentsFooterItem(overlappingComponentsSubMenuItems));
        }

        let contextMenuGroups: { group: any, groupClassName?: string }[] = [
            { group: componentActions },
            { group: componentCommonActions },
            { group: componentDeleteAction }
        ];

        if (isHeaderOrFooterSection(component)) {
            contextMenuGroups = [
                { group: componentActions },
                { group: headerFooterSectionsCommonActions }
            ];
        } else if (isSectionComponent(component)) {
            contextMenuGroups = [
                { group: componentActions },
                { group: sectionCommonActions },
                { group: [(() => {
                    const intl = new Intl(),
                        disableTitle = intl.msgJoint('msg: contextmenu.item.splitSection.Title.disabled {No suitable splits found}');

                    return splitSection(componentId, {
                        disabled: !canBeSplit,
                        toolTip: !canBeSplit ? disableTitle : undefined
                    });
                })()] }
            ];
        }

        if (wrapOptions.left || wrapOptions.right || wrapOptions.center || wrapOptions.unwrap) {
            const wrapGroup: {
                title: React.JSX.Element;
                actionInput: string;
            }[] = [];

            if (wrapOptions.left) {
                wrapGroup.push(componentWrapTopLeft);
            }
            if (wrapOptions.center) {
                wrapGroup.push(componentWrapToCenter);
            }
            if (wrapOptions.right) {
                wrapGroup.push(componentWrapTopRight);
            }
            if (wrapOptions.above) {
                wrapGroup.push(componentWrapAbove);
            }
            if (wrapOptions.below) {
                wrapGroup.push(componentWrapBelow);
            }
            if (wrapOptions.unwrap) {
                wrapGroup.push(componentUnwrap);
            }

            contextMenuGroups.push({
                group: wrapGroup,
                groupClassName: ContextMenuStyles.contextMenuGroup
            });
        }

        if (!isHeaderOrFooterSection(component) && isSectionKind(component.kind)) {
            contextMenuGroups.push({
                group: [component.pin ? pinSettings(componentId) : pinToScreen(componentId)],
                groupClassName: ContextMenuStyles.contextMenuGroup
            });
        }

        if (isHeaderOrFooterSection(component)) {
            const headerFooterPinMenuOption = isHeaderSection(component) ? pinToTop() : pinToBottom();
            contextMenuGroups.push({
                group: [component.pin ? unPin() : headerFooterPinMenuOption],
                groupClassName: ContextMenuStyles.contextMenuGroup
            });
        }

        if (canHeaderBeSplit([component.id], componentsMap, attachments)) {
            contextMenuGroups.push(
                { group: [(() => {
                    return splitHeaderFooterSection([componentId], {});
                })()] }
            );
        }

        if (overlappingComponentActions.length > 0) {
            contextMenuGroups.push({
                group: overlappingComponentActions,
                groupClassName: ContextMenuStyles.contextMenuOverlappingComponentsGroup
            });
        }

        return contextMenuGroups;
    },
    getContextMenuGroupsForWorkspace = (
        componentsMap: ComponentsMap,
        selectedComponentsIds: Array<string>,
        workspaceBBox: BBox,
        clickedInsideSelectedComponentsBBox: boolean,
        undoDisabled: boolean,
        redoDisabled: boolean,
        pasteDisabled: boolean,
        attachments: Attachments,
    ): Array<ContextMenuGroupPartialPropType> => {
        const
            copyComponents = 1,
            cutComponents = 2,
            duplicateComponents = 3,
            pasteComponents = 4;

        if (clickedInsideSelectedComponentsBBox) {
            const componentLayerMenuItem = getComponentsLayerContextMenuItem(selectedComponentsIds, componentsMap, null, workspaceBBox, attachments); // eslint-disable-line max-len
            const contextMenuGroup = [
                componentLayerMenuItem,
                workspaceContextMenuGroup[copyComponents],
                workspaceContextMenuGroup[cutComponents],
                workspaceContextMenuGroup[duplicateComponents],
                componentDeleteActionItem,
                setDisabled(workspaceContextMenuGroup[pasteComponents], pasteDisabled)
            ];

            if (canHeaderBeSplit(selectedComponentsIds, componentsMap, attachments)) {
                contextMenuGroup.push((() => {
                    return splitHeaderFooterSection(selectedComponentsIds, {});
                })());
            }

            return [{
                group: contextMenuGroup
            }];
        }
        return [];
    },

    getContextMenuGroupsForPagesTreeItem = (
        item: DataSiteItem
    ): Array<ContextMenuGroupPartialPropType> => {
        let primaryItems, secondaryItems;
        if (isPageRef(item)) {
            primaryItems = pageRefContextMenuPrimaryGroup;
            secondaryItems = pageRefContextMenuSecondaryGroup.filter(contextItem =>
                (contextItem.hidden === undefined || contextItem.hidden === item.hidden));
        } else if (isLinkPage(item)) {
            primaryItems = linkPageContextMenuPrimaryGroup;
            secondaryItems = linkPageContextMenuSecondaryGroup;
        } else {
            primaryItems = sectionLinkContextMenuPrimaryGroup;
            secondaryItems = sectionLinkContextMenuSecondaryGroup.filter(contextItem =>
                (contextItem.hidden === undefined || contextItem.hidden === item.hidden));
        }

        return [
            { group: primaryItems.map(group => ({ ...group, payload: item })) },
            { group: secondaryItems.map(group => ({ ...group, payload: item })) }
        ];
    },

    addcomponentIdToPayload = (
        items: ComponentDefaultItemType,
        componentId: string
    ) => {
        const newItems = {};
        Object.keys(items).forEach(key => {
            newItems[key] = { ...items[key], payload: { componentId } };
        });
        return newItems;
    },

    getContextMenuGroupsForMobileView = (
        componentsMap: ComponentsMap,
        componentId: string,
        itemsStatus: MapT<ComponentDefaultItemType>
    ): Array<ContextMenuGroupPartialPropType> => {
        let kind, mobileView;
        if (getCmpTypeById(componentId) !== cmpTypes.group) {
            kind = componentsMap[getId(componentId)].kind;
            mobileView = registry[kind].contextMenu.mobileView;
        }
        return [{
            group: getComponentCommonActionItems(addcomponentIdToPayload(mobileViewCommonActionItems, componentId),
                itemsStatus, mobileView && mobileView.defaultItemsOverride, mobileViewCommonActionItemsOrder)
        },
        {
            group: getComponentCommonActionItems(addcomponentIdToPayload(mobileViewSecondaryActionItems, componentId),
                itemsStatus, mobileView && mobileView.defaultItemsOverride, mobileViewSecondaryActionItemsOrder)
        }
        ];
    },

    getContextMenuGroupsForLeftPanelComponentsTab = (): Array<ContextMenuGroupPartialPropType> =>
        [{ group: leftPanelComponentsTabContextMenuGroup }],

    getMenuPosition = (
        { x, y }: Position,
        { browserWidth, browserHeight }: BrowserDimensions,
        contextMenuGroups: Array<ContextMenuGroupPartialPropType>
    ): Position => {
        const
            borders = 4, // 2 px for the outer most, 1 px for the three inner groups, -1 for last group
            { containerWidth: width } = ContextMenuDimensions,
            itemsCount = contextMenuGroups.reduce(
                (count, contextMenuGroup): number => (count + contextMenuGroup.group.length), 0
            ),
            itemsHeight = itemsCount * parseInt(styles.itemHeight, 10),
            groupsPadding = contextMenuGroups.length * (2 * parseInt(styles.containerPadding, 10)),
            delPadding = 2 * parseInt(styles.deleteOptionExtraPadding, 10),
            arrangePadding = 2 * parseInt(styles.arrangeOptionExtraPadding, 10),
            height = itemsHeight + groupsPadding + delPadding + arrangePadding + borders;

        return positionCalculator(
            { position: Positions.TopLeft, dimension: { width, height } },
            { position: Positions.TopLeft, bbox: { top: y, right: x + 1, bottom: y + 1, left: x } },
            { top: 0, right: browserWidth, bottom: browserHeight, left: 0 },
            CollisionAlignment.Intelligent
        );
    },

    getSubMenuPosition = (
        { x, y }: Position,
        { browserWidth: bw }: BrowserDimensions,
        itemPosition: number,
        groupsCount: number,
        groupsItemsCount: number,
        groupDirection: number
    ): Position => {
        const
            { containerWidth, subMenuOverlap } = ContextMenuDimensions,
            subMenuRightEndPoint = x + (2 * containerWidth) - subMenuOverlap,
            subMenuNormalLeftPoint = x + containerWidth,
            subMenuFlippedLeftPoint = x - containerWidth;

        if (groupDirection === ContextSubMenuDirection.topToBottom) {
            return {
                x: subMenuRightEndPoint < bw ? subMenuNormalLeftPoint : subMenuFlippedLeftPoint,
                y: y + (itemPosition * parseInt(styles.itemHeight, 10))
            };
        } else { /* if (groupDirection === ContextSubMenuDirection.bottomToTop) {/* */
            const
                groupItemsHeight = groupsItemsCount * parseInt(styles.itemHeight, 10),
                groupsPadding = 2 * groupsCount * parseInt(styles.containerPadding, 10),
                groupBottomPosition = y + ((itemPosition + 1) * parseInt(styles.itemHeight, 10)) + parseInt(styles.containerPadding, 10); // eslint-disable-line max-len

            return {
                x: subMenuRightEndPoint < bw ? subMenuNormalLeftPoint : subMenuFlippedLeftPoint,
                y: groupBottomPosition - (groupItemsHeight + groupsPadding)
            };
        }
    },

    getSubMenuRelativePosition = (
        lastGroupPosition: Position,
        lastGroupItemsCount: number
    ): Position => ({
        x: lastGroupPosition.x,
        y: (lastGroupPosition.y
            + (lastGroupItemsCount * parseInt(styles.itemHeight, 10))
            + (lastGroupItemsCount > 0 ? (2 * parseInt(styles.containerPadding, 10)) : 0))
    });

