import type { DataPageCopy } from './copyPage';
import { DataPageTemplate } from '../index';
import { Relation } from '../../../src/components/MobileViewEditor/flowTypes';

export const addPageToTemplate = (pageCopy: DataPageCopy, template: DataPageTemplate): DataPageTemplate | false => {
    const { itemIds } = pageCopy;

    let relationsUpdated = false;
    const nextRelations = template.mobileData.relations.reduce((acc, relation) => {
        const prop = Object.keys(relation).find(prop => itemIds[relation[prop]]);
        if (prop) {
            relationsUpdated = relationsUpdated || true;
            return acc.concat(relation, {
                ...relation,

                [prop]: itemIds[relation[prop]],
            });
        }
        return acc.concat(relation);
    }, [] as Relation[]);

    if (relationsUpdated) {
        const nextTemplateData = {
            ...template,
            mobileData: relationsUpdated
                ? { ...template.mobileData, relations: nextRelations }
                : template.mobileData,
            items: template.items,
        };
        return new DataPageTemplate(nextTemplateData);
    }

    return false;
};
