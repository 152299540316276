import * as R from 'ramda';

// This function works for only pure data objects(no functions should be there)
// If the value of a property in specificPropertiesMap is an object or an Array, then it will not be considered
// currently there is no such case that needs to be handled
export default (
    obj ?: Record<string, any>,
    specificPropertiesMap?: { [property: string]: boolean }
): any => {
    if (!obj) return obj;
    const
        round = (property, value) => {
            if (typeof value === 'number' && (!specificPropertiesMap || specificPropertiesMap[property])) {
                return Math.round(value);
            }
            return value;
        },
        copy = (obj: any) => {
            let output: any = Array.isArray(obj) ? [] : {};
            const objKeys = Object.keys(obj);
            objKeys.forEach(key => {
                const val = obj[key];
                if (R.is(Object, val) || Array.isArray(val)) {
                    output[key] = copy(val);
                } else {
                    output[key] = round(key, val);
                }
            });
            return output;
        };
    return copy(obj);
};
