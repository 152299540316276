import { Intl } from "../../../../../view/intl";
import { isNormalSubscription, isPremiumSubscription, isEcommerceSubscription } from "../../../../App/epics/subscriptionData/utils";

export const getSubscriptionTierTitle = (intl: Intl, subscriptionType: string) => {
    if (isNormalSubscription(subscriptionType)) {
        return intl.msgJoint('msg: common.starter {Starter}');
    }
    if (isPremiumSubscription(subscriptionType)) {
        return intl.msgJoint('msg: common.premium {Premium}');
    }
    if (isEcommerceSubscription(subscriptionType)) {
        return intl.msgJoint('msg: common.business_ecommerce {Business + Ecommerce}');
    }
    return "";
};
