import Measure from 'react-measure';
import * as React from "react";
import cx from 'classnames';
import Image from '../../Image/view/Image';
import { replaceLineBreaksWithBr } from '../../../presentational/utils';
import { LIMIT } from "../constants";
import type { GalleryImageRenderProps, Props } from '../flowTypes';
import * as styles from '../Gallery.css';
import * as imageStyles from '../../Image/view/Image.css';
import { Msg } from "../../../../view/intl/index";
import { makeImageAdjustmentDataKey } from '../utils';
import { addComponentAdjustmentDataEntry } from '../../../Workspace/epics/componentsEval/adjustmentDataDispatchCache';
import textNormalClassName from '../../Text/globalStyle/textNormalClassName';
import VerticalSpacer from '../../../../view/common/VerticalSpacer';
import { replaceTagsWithContent } from '../../Text/view/replaceTagsWithContent';

// used to apply script in preview and publish
const
    staticGalleryClassName = 'gallery',
    staticGalleryCellClassName = 'gallery-cell',
    staticGalleryImageContainerClassName = 'img-container',
    staticGalleryCaptionContainerClassName = 'gallery-caption',
    staticGalleryTitleDescriptionWrapperClassName = 'gallery-title-description-wrapper',
    addEllipsisIfRequiredCaption = (text) => ((text.length > LIMIT.caption.hard)
        ? `${text.substr(0, LIMIT.caption.hard)}...` : text);

class Gallery extends React.PureComponent<Props> {
    getImageEl({ divStyle = {
        width: 0,
        height: 0
    }, imageAvailable, img }: GalleryImageRenderProps) {
        const
            { isWorkspace } = this.props;
        return (
            <div
                className={cx(
                    staticGalleryImageContainerClassName
                )}
                style={{ height: divStyle.height, width: divStyle.width, display: 'flex' }}
            >
                <Image
                    imageAvailable={imageAvailable}
                    divStyle={{ ...divStyle, height: img.style.height, width: img.style.width }}
                    divClassName={cx(
                        imageStyles.imageComponent,
                        {
                            [`${imageStyles.imgNotFound}`]: !imageAvailable
                        }
                    )}
                    imageAttributes={{ ...img, role: "presentation" }}
                    width={img.style.width}
                    height={img.style.height}
                    isWorkspace={isWorkspace}
                />
            </div>
        );
    }

    getCaptionEl({ title, caption, captionWrapperStyle }: GalleryImageRenderProps, measureRef?: any) {
        const
            { text = '', props = {} } = caption || {},
            { text: titleText = '', props: titleProps = {} } = title || {};
        return (
            <div ref={measureRef}>
                <div
                    style={{
                        ...captionWrapperStyle,
                        ...this.props.captionBoxStyles
                    }}
                    className={
                        cx(
                            styles.galleryCaption,
                            staticGalleryTitleDescriptionWrapperClassName,
                            {
                                [styles.emptyCaption]: !titleText && !text
                            }
                        )
                    }
                >
                    {titleText && <p
                        {...titleProps}
                        className={styles.galleryCaption}
                    >
                        {replaceLineBreaksWithBr(titleText.substr(0, LIMIT.title.hard))}
                    </p>}
                    {
                        titleText && text &&
                            <VerticalSpacer y={5} />
                    }
                    {text &&
                    <p {...props} className={cx(styles.galleryCaption)}>
                        {replaceLineBreaksWithBr(addEllipsisIfRequiredCaption(text))}
                    </p>}
                </div>
            </div>
        );
    }

    getImageCells() {
        const
            {
                images,
                captionsEnabled,
                isWorkspace,
                globalVariables,
                captionsContainerHeight,
                captionMinHeight,
                componentId
            } = this.props,
            imageCells: React.CElement<any, any>[] = images.map((image: GalleryImageRenderProps, index) => {
                const anchorProps = image.a;
                if (anchorProps && anchorProps.href) {
                    anchorProps.href = replaceTagsWithContent(anchorProps.href, { globalVariables }, this.props.isWorkspace);
                }
                return (
                    <div
                        key={index}
                        data-index={index}
                        className={cx(styles.galleryCell, staticGalleryCellClassName)}
                        {...image.container}
                    >
                        <a {...anchorProps}>
                            {this.getImageEl(image)}
                            {
                                captionsEnabled && (
                                    <div
                                        style={{ height: captionsContainerHeight, minHeight: captionMinHeight, position: "relative" }}
                                        className={cx(staticGalleryCaptionContainerClassName)}
                                    >
                                        <div className={textNormalClassName}>
                                            <div className={styles.galleryCaptionContainer}>
                                                {this.getCaptionEl(image)}
                                            </div>
                                            {
                                                isWorkspace && (
                                                    <div style={{ visibility: 'hidden', position: 'absolute', top: 0, left: 0 }}>
                                                        <Measure
                                                            offset
                                                            onResize={({ offset: { height } }) => {
                                                                addComponentAdjustmentDataEntry(
                                                                    componentId,
                                                                    { [makeImageAdjustmentDataKey(index)]:
                                                                    Math.round(height) }
                                                                );
                                                            }}
                                                            key={index}
                                                        >
                                                            {({ measureRef }) => this.getCaptionEl(image, measureRef)}
                                                        </Measure>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </a>
                    </div>
                );
            });

        return imageCells;
    }

    getMobileStyles() {
        const { mobileSettings, componentId: id } = this.props;
        if (mobileSettings) {
            let
                { columns, spacingPx } = mobileSettings;
            if (columns === 1) {
                return `
                .mobileV div[data-id='${id}'] .gallery .gallery-cell {
                    padding: 0 0 ${spacingPx}px 0 !important;
                    width: 100% !important;
                }
                .mobileV div[data-id='${id}'] .gallery .gallery-cell:last-child {
                    padding-bottom: 0 !important;
                }`;
            }
            spacingPx = spacingPx / columns; //spacing should be shared between columns
            return `
                .mobileV div[data-id='${id}'] .gallery .gallery-cell:nth-child(2n+1) {
                    padding: ${spacingPx}px ${spacingPx}px ${spacingPx}px 0 !important;
                }
                .mobileV div[data-id='${id}'] .gallery .gallery-cell:nth-child(2n+2) {
                    padding: ${spacingPx}px 0 ${spacingPx}px ${spacingPx}px !important;
                }
                .mobileV div[data-id='${id}'] .gallery .gallery-cell:first-child {
                    padding: 0 ${spacingPx}px ${spacingPx}px 0 !important;
                }
                .mobileV div[data-id='${id}'] .gallery .gallery-cell:nth-child(2) {
                    padding: 0 0 ${spacingPx}px ${spacingPx}px !important;
                }
                .mobileV div[data-id='${id}'] .gallery {
                    margin-bottom: -${spacingPx}px !important;
                }`;
        }
        return '';
    }

    render() {
        const
            {
                captionsEnabled,
                isWorkspace,
                measureRef,
                crop,
                mobileSettings,
                spacingPx,
                columns,
                imageRatio,
                width,
                stretch,
                fullWidthOption,
                templateWidth,
                compactView
            } = this.props,
            imagesCells = this.getImageCells(),
            zeroImages = imagesCells.length === 0,
            cn = cx(
                staticGalleryClassName,
                styles.gallery,
                textNormalClassName,
                {
                    [styles.zeroImages]: zeroImages,
                    [styles.notCrop]: !crop
                },
            ),
            style = isWorkspace && stretch ?
                {
                    width: `${100 - (fullWidthOption.margin * 2)}%`,
                    margin: 'auto',
                    maxWidth: fullWidthOption.maxWidth,
                    minWidth: templateWidth
                }
                : undefined;

        if (zeroImages && !isWorkspace) {
            return <div />;
        }

        return <React.Fragment>
            <style type="text/css" dangerouslySetInnerHTML={{ __html: this.getMobileStyles() }} />
            <div
                className={cn}
                ref={measureRef}
                data-captionenabled={captionsEnabled}
                data-mobile-column={mobileSettings && mobileSettings.columns}
                data-columns={columns}
                data-spacing={spacingPx}
                data-image-ratio={imageRatio}
                data-original-width={width}
                data-compact-view={compactView}
                style={style}
            >
                {
                    zeroImages ?
                        <Msg k="component.gallery.noImagesSelected">No images selected.</Msg>
                        : imagesCells
                }
            </div>
        </React.Fragment>;
    }
}

export default Gallery;
