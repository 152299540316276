import { WidgetsSoundcloudKind } from './kind';
import { calcRenderProps } from './calcRenderProps';
import { SoundcloudView } from './view/index';

const WidgetsSoundcloudPreviewConfig = {
    kind: WidgetsSoundcloudKind,
    view: SoundcloudView,
    calcProps: ({ component }) => calcRenderProps({
        component,
        componentExtension: { isTransient: false },
    }),
};

export { WidgetsSoundcloudPreviewConfig };
