import React from 'react';
import { WarningConfirmationDialog } from '../../../../../view/common/dialogs/WarningConfirmationDialog';
import { Msg } from "../../../../../view/intl";
import { confirmDeletePageTemplateAction } from "../../actions";

type Props = {
    dispatch: Dispatch,
};

export const PageTemplateSelectorDeleteConfirmDialogCom = ({ dispatch }: Props) => (
    <WarningConfirmationDialog
        title="msg: deleteTemplate {Delete template}"
        onConfirm={() => dispatch(confirmDeletePageTemplateAction())}
        dispatch={dispatch}
    >
        <Msg k="confirmDeleteTemplate">Are you sure you want to delete this template?</Msg>
    </WarningConfirmationDialog>
);
