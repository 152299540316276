import * as selectors from '../../../Workspace/epics/stylesheets/selectors';
import { toHex } from "../../../../mappers/color";

export const getTextStyles = (stylesheets) => {
    const {
        bold,
        color,
        font: fontFamily,
        italic,
        letterspacing: letterSpacing,
        lineHeight,
        size: fontSize,
        underline
    } = selectors.textNormalGlobalstyle(stylesheets);

    let textStyle = {
        fontFamily,
        fontSize,
        color: toHex(color),
        letterSpacing,
        lineHeight,
        fontStyle: 'inherit',
        fontWeight: 400,
        textDecoration: 'inherit',
    };

    if (italic) {
        textStyle.fontStyle = "italic";
    }
    if (bold) {
        textStyle.fontWeight = 700;
    }
    if (underline) {
        textStyle.textDecoration = "underline";
    }
    return textStyle;
};
