import { WIZARD_STEP_ID } from "../../constants";
import { BusinessNameStep } from "./BusinessName/BusinessName";
import CatchAllStep from "./CatchAll/CatchAll";
import { ContactInfoStep } from "./ContactInfo/ContactInfo";
import { KeywordsStep } from "./Keywords/Keywords";
import { PronounsStep } from "./Pronouns/Pronouns";
import { WebsiteCategoryStep } from "./WebsiteCategory/WebsiteCategory";
import WebsitePurposeStep from "./WebsitePurpose/WebsitePurpose";
import { LanguageStep } from "./Language/Language";
import { BusinessNameAnimation } from "../Animations/BusinessNameAnimation";
import { ContactInfoAnimation } from "../Animations/ContactInfoAnimation";
import { KeywordsAnimation } from "../Animations/KeywordsAnimation";
import { WebsiteCategoryAnimation } from "../Animations/WebsiteCategoryAnimation";
import { PronounsAnimation } from "../Animations/PronounsAnimation";
import { WebsitePurposeAnimation } from "../Animations/WebsitePurposeAnimation";
import { LanguageAnimation } from "../Animations/LanguageAnimation";

const WizardSteps = [{
    id: WIZARD_STEP_ID.GMB_SELECTION,
    view: WebsiteCategoryStep,
    animation: WebsiteCategoryAnimation
}, {
    id: WIZARD_STEP_ID.CATCH_ALL,
    view: CatchAllStep,
    animation: WebsitePurposeAnimation
}, {
    id: WIZARD_STEP_ID.WEBSITE_PURPOSE,
    view: WebsitePurposeStep,
    animation: WebsitePurposeAnimation
}, {
    id: WIZARD_STEP_ID.NAME,
    view: BusinessNameStep,
    animation: BusinessNameAnimation
}, {
    id: WIZARD_STEP_ID.DESCRIPTION,
    view: KeywordsStep,
    animation: KeywordsAnimation,
    skippable: true
}, {
    id: WIZARD_STEP_ID.CONTACT_INFO,
    view: ContactInfoStep,
    animation: ContactInfoAnimation,
    skippable: true
}, {
    id: WIZARD_STEP_ID.PRONOUNS,
    view: PronounsStep,
    animation: PronounsAnimation
}, {
    id: WIZARD_STEP_ID.LANGUAGE,
    view: LanguageStep,
    animation: LanguageAnimation
}];

export {
    WizardSteps,
};
