import { openDialogGen, replaceDialogGen, takePayloadGen, closeDialogGen } from "./index";

export default function* (
    dialogId: string,
    getActionType: string,
    replaceDialog: boolean = false
): Generator<any, any, any> {
    if (replaceDialog) yield* replaceDialogGen(dialogId);
    else yield* openDialogGen(dialogId);

    const payload = yield* takePayloadGen(getActionType);

    yield* closeDialogGen();

    return payload;
}
