import CALL_API from "../../../redux/middleware/api/CALL_API";
import {
    GET_DESIGN_PAGE_REQUEST,
    GET_DESIGN_PAGE_SUCCESS,
    GET_DESIGN_PAGE_FAILURE
} from "../actionTypes";
import type { ApiAction } from "../../../redux/middleware/api/flowTypes";

export default (pageId: string, designAccountName: string): ApiAction => ({
    [CALL_API]: {
        types: [
            GET_DESIGN_PAGE_REQUEST,
            GET_DESIGN_PAGE_SUCCESS,
            GET_DESIGN_PAGE_FAILURE
        ],
        endpoint: 'getDesignPage',
        endpointParams: [pageId, designAccountName]
    }
});
