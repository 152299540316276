import React from "react";
export const SvgRedditRegularMulticolor = props => (
    <svg viewBox="0 0 43 43" {...props}>
        <defs>
            <path
                id="reddit_regular_multicolor_svg__a"
                d="M.015.019h42.449v42.464H.015z"
            />
        </defs>
        <g fill="none" fillRule="evenodd">
            <mask id="reddit_regular_multicolor_svg__b" fill="#ffffff">
                <use xlinkHref="#reddit_regular_multicolor_svg__a" />
            </mask>
            <path
                fill="#ff4500"
                d="M42.464 21.25c0 11.727-9.506 21.233-21.232 21.233C9.506 42.483 0 32.977 0 21.25 0 9.525 9.506.019 21.232.019c11.726 0 21.232 9.506 21.232 21.232"
                mask="url(#reddit_regular_multicolor_svg__b)"
            />
            <path
                fill="#ffffff"
                d="M26.098 25.674a2.214 2.214 0 01-2.212-2.211c0-1.22.992-2.212 2.212-2.212 1.22 0 2.212.992 2.212 2.212 0 1.22-.993 2.211-2.212 2.211zm.39 3.633c-1.51 1.507-4.403 1.625-5.254 1.625-.85 0-3.743-.118-5.25-1.626a.573.573 0 11.81-.81c.951.951 2.987 1.289 4.44 1.289 1.454 0 3.49-.338 4.443-1.29a.573.573 0 01.81.812zm-12.333-5.844c0-1.22.992-2.212 2.212-2.212a2.211 2.211 0 010 4.422 2.214 2.214 0 01-2.212-2.21zm21.229-2.212a3.097 3.097 0 00-5.245-2.228c-2.116-1.527-5.032-2.514-8.28-2.627l1.41-6.636 4.609.98a2.207 2.207 0 004.412-.105 2.205 2.205 0 00-4.186-.977l-5.146-1.094a.552.552 0 00-.656.426l-1.575 7.404c-3.295.092-6.258 1.08-8.401 2.623a3.096 3.096 0 10-3.408 5.056 6.11 6.11 0 00-.075.938c0 4.763 5.545 8.625 12.386 8.625 6.84 0 12.385-3.862 12.385-8.625 0-.315-.026-.625-.073-.93a3.095 3.095 0 001.843-2.83z"
            />
        </g>
    </svg>
);
