import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { colorPeachyPinky } from './SectionHoverDecotations.css';
import { Border } from "../../../components/presentational/Border/Border";
import { AddIconView } from "./addDecorations";
import { addSectionAction } from "../../../components/Workspace/epics/componentsEval/actionCreators/section";
import * as HandleKinds from "../../../utils/handle/kinds";
import { makeBorderProps } from "./makeBorderProps";
import { TitleTag } from "./TitleTag";

const getAddDecorations = (dispatch, { bBox: { left, top }, kind }, sectionId, interactionMode) => {
    const onClick = (e) => {
            e.stopPropagation();
            dispatch(addSectionAction(sectionId, kind === HandleKinds.AddIconN));
        },
        style = { top, left };
    return <AddIconView
        interactionMode={interactionMode}
        onClick={onClick}
        style={style}
        key={sectionId + kind}
    />;
};

export const SectionHoverDecorations = connect()(({ dispatch, sectionDecorations, scroll, interactionMode }: any) => {
    const decorations = R.uniqBy(decoration => decoration && decoration.section.id, sectionDecorations);
    return decorations.reduce((acc, decoration, index) => {
        if (!decoration) return acc;
        const { addIconHandles, section, bBox } = decoration,
            { id: sectionId, title } = section,
            isSelectionDecoration = decorations.length > 1 && index !== 0;
        const borderProps = makeBorderProps({
            bBox,
            color: colorPeachyPinky,
            thickBorder: isSelectionDecoration
        });
        const result = [
            ...acc,
            <Border {...borderProps} key={`${sectionId}_border`} />,
            ...addIconHandles.map(handler => getAddDecorations(dispatch, handler, sectionId, interactionMode))
        ];
        if (isSelectionDecoration) {
            result.push(<TitleTag component={section} bBox={bBox} scroll={scroll} key={`${title}`} />);
        }
        return result;
    }, []);
});
