import getCenteredDialogConfig from "../../DialogManager/getCenteredDialogConfig";
import NewPageDialog from "./view";
import siteDataEpic from '../../App/epics/siteData';
import subscriptionDataEpic from '../../App/epics/subscriptionData';
import { sectionComponentsEpic } from '../sectionComponents/epic/sectionComponentsEpic';
import reducer from './reducer';
import calcRenderProps from "./view/calcRenderProps";
import currentPageIdEpic from '../../App/epics/currentPageId';
import { colorThemeSiteSettingsEpic } from "../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import { componentsMapReducer } from "../../Workspace/epics/componentsEval";
import { generalGlobalDataEpic } from "../../SiteSettings/General/generalGlobalDataEpic/generalGlobalDataEpic";

export default getCenteredDialogConfig({
    width: 1070,
    height: 680,
    component: NewPageDialog,
    persistentState: true,
    dependsOn: {
        themeSettingsData: colorThemeSiteSettingsEpic.reducer,
        site: siteDataEpic.reducer,
        generalData: generalGlobalDataEpic.reducer,
        subscriptionData: subscriptionDataEpic.reducer,
        sectionComponents: sectionComponentsEpic.reducer,
        currentPageId: currentPageIdEpic.reducer,
        componentsMap: componentsMapReducer
    },
    reducer,
    calcRenderProps
});
