import React from 'react';
import { connect } from 'react-redux';

import styles from './Template.css';
import Line from './Line';

type PropTypes = {
    distanceBetweenLines: number,
    labelTop: number,
    dottedLineTop: number,
    isActive: boolean,
    dispatch: Function,
    top: number,
    height: number,
};

const TemplateLines = (props: PropTypes) => {
    const elements = [
        <Line key="leftLine" isLeft {...props} />,
        <Line key="rightLine" {...props} />
    ];

    if (props.distanceBetweenLines > 0) {
        elements.push((
            <div key="widthLabel" className={styles.widthLabel} style={{ top: `${props.labelTop}px` }}>
                {props.distanceBetweenLines}px
            </div>
        ));
        elements.push((
            <div key="dottedLine" className={styles.dottedLine} style={{ top: `${props.dottedLineTop}px` }} />
        ));
    }
    return elements;
};

const mapStateToProps = ({ selectedWorkspace: { templateLines: { state } } }) => ({ ...state });

// @ts-ignore
export default connect(mapStateToProps)(TemplateLines);
