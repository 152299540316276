import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from "classnames";

import Scrollbar from '../../../../../../../view/common/Scrollbar';
import { ErrorMessage } from './ErrorMessage';

import styles from './styles.css';
import { editAiTextSelector } from '../../../../epics/EditAITextEpic/selectors';
import { AI_EDIT_TEXT_PANEL_SCROLLED, AI_EDIT_TEXT_PANEL_SCROLLED_TO_BOTTOM,
    REGENERATE_TEXT_COMPLETE_EVENT, REGENERATE_TEXT_FAILED } from '../../../../epics/EditAITextEpic/actionTypes';
import { EditTextResultCard } from './resultCard';
import useReduxEffect from '../../../../../../../redux/effects/useReduxEffect';

import { ERROR_MESSAGES, errorMessageIds, MAX_INPUT_TEXT_LIMIT } from './constants';

import { GenerationReminderBanner } from './GenerationReminderBanner';

export const EditingAssistant = () => {
    const {
        queryVersions,
        isQueryInProgress,
        isScrolledWhileStreaming,
        errorState,
        generationReminder
    } = useSelector(editAiTextSelector);
    const dispatch = useDispatch();
    const resultsScrollRef = React.useRef<any>(null);

    const scrollToBottom = () => {
        return setTimeout(() => {
            if (resultsScrollRef.current) {
                const scrollHeight = resultsScrollRef.current.getScrollHeight();
                const clientHeight = resultsScrollRef.current.getClientHeight();
                resultsScrollRef.current.scrollTop(scrollHeight - clientHeight);
            }
        }, 10);
    };

    let errorMessage = errorState;
    if (!errorMessage) {
        const lastVersion = queryVersions[queryVersions.length - 1];
        if (lastVersion.isSelected && lastVersion.text.length > MAX_INPUT_TEXT_LIMIT) {
            errorMessage = errorMessageIds.LONG_TEXT;
            scrollToBottom();
        }
    }

    const onResultsScroll = () => {
        if (!isQueryInProgress) return;
        const scrollHeight = resultsScrollRef.current.getScrollHeight();
        const clientHeight = resultsScrollRef.current.getClientHeight();
        const scrollTop = resultsScrollRef.current.getScrollTop();
        const atBottom = (scrollHeight - scrollTop) - clientHeight;
        if ((atBottom > 30 || atBottom < -30) && !isScrolledWhileStreaming) {
            dispatch({ type: AI_EDIT_TEXT_PANEL_SCROLLED });
        }
        if (atBottom <= 30 && isScrolledWhileStreaming) {
            dispatch({ type: AI_EDIT_TEXT_PANEL_SCROLLED_TO_BOTTOM });
        }
    };

    useReduxEffect(() => {
        scrollToBottom();
        dispatch({ type: AI_EDIT_TEXT_PANEL_SCROLLED_TO_BOTTOM });
    }, [REGENERATE_TEXT_COMPLETE_EVENT, REGENERATE_TEXT_FAILED], [isScrolledWhileStreaming]);

    React.useEffect(() => {
        // Should scroll to bottom while result is being streamed
        let scrollTimeOutId;
        if (!isScrolledWhileStreaming && isQueryInProgress) {
            scrollTimeOutId = scrollToBottom();
        }

        return () => {
            clearTimeout(scrollTimeOutId);
        };
    }, [isScrolledWhileStreaming, isQueryInProgress, queryVersions]);

    return (
        <Scrollbar
            height="100%"
            scrollRef={resultsScrollRef}
            onScroll={onResultsScroll}
            className={cx(styles.resultsScrollBar, {
                [styles.hideScrollBar]: isQueryInProgress && !isScrolledWhileStreaming
            })}
        >
            <div className={styles.container} data-testid="ai-edit-text-dialog-content">
                {
                    queryVersions.map((version, index) => <EditTextResultCard key={index} version={version} index={index} />)
                }
                { /* eslint-disable-next-line no-nested-ternary */ }
                {isQueryInProgress ? null : errorMessage ? (
                    <ErrorMessage {...ERROR_MESSAGES[errorMessage]} />
                ) : generationReminder.showBanner ? (
                    <GenerationReminderBanner mode={generationReminder.prevActionType} />
                ) : null}
            </div>
        </Scrollbar>
    );
};
