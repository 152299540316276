/* eslint-disable max-len */
import * as R from "ramda";
import * as colorMapper from "../color";
import * as path from "../path";
import makeSpec from '../makeSpec/index';
import type { Path } from '../path'; // eslint-disable-line
import type { AnyComponent } from "../../redux/modules/children/workspace/flowTypes";
import type { Border } from "./flowTypes";
import type { Color } from "../flowTypes";

// TODO: test

const
    defaultBorder = R.repeat(1, 4),
    appendPx = v => `${v}px`,
    getBorderWidthCss = widths => (widths || defaultBorder).map(appendPx).join(' '),
    getPropIfColorExists = (propPath: Path, border: Border, propValMapperFn?: Function): any => {
        const
            color = R.prop(path.color, border),
            propVal = R.path(propPath, border);

        if (color && propVal) {
            return propValMapperFn ? propValMapperFn(propVal) : propVal;
        }
        return undefined;
    },
    getBorderCorner = (border: Border, index: number): null | undefined | string =>
        (border && border.corners ? appendPx(border.corners[index]) : undefined),
    makeCssConfig = (borderColorValueMapperFunction?: ((c: Color) => string)) => ({
        borderColor: (border: Border): null | undefined | string =>
            getPropIfColorExists([path.color], border, borderColorValueMapperFunction),
        borderStyle: (border: Border): null | undefined | string =>
            getPropIfColorExists([path.style], border),
        borderWidth: (border: Border): null | undefined | string => getBorderWidthCss(R.path([path.width], border)),
        borderTopLeftRadius: (border: Border): null | undefined | string => getBorderCorner(border, 0),
        borderTopRightRadius: (border: Border): null | undefined | string => getBorderCorner(border, 1),
        borderBottomRightRadius: (border: Border): null | undefined | string => getBorderCorner(border, 2),
        borderBottomLeftRadius: (border: Border): null | undefined | string => getBorderCorner(border, 3)
    }),
    cssConfig = makeCssConfig(colorMapper.toCss),
    makeBorderToCssConfig = (prefixPath: Path, borderColorValueMapperFunction?: ((c: Color) => string)) => {
        const config = borderColorValueMapperFunction ? makeCssConfig(borderColorValueMapperFunction) : cssConfig;
        return Object.keys(config).reduce((acc: Record<string, any>, borderProp: string): Record<string, any> => ({
            ...acc,
            [borderProp]: [prefixPath, config[borderProp]]
        }), {});
    },
    makeBorderStyle = (borderPath: Path, data: Record<string, any>, borderColorOverrideWithoutOpacity?: null | undefined | Color): Record<string, any> => {
        const spec = makeSpec(makeBorderToCssConfig(borderPath, (color) => (
            borderColorOverrideWithoutOpacity
                ? colorMapper.toCss([
                    borderColorOverrideWithoutOpacity[0],
                    borderColorOverrideWithoutOpacity[1],
                    borderColorOverrideWithoutOpacity[2],
                    borderColorOverrideWithoutOpacity[3],
                    color[4],
                ])
                : colorMapper.toCss(color)
        )))([]);
        return R.applySpec(spec)(data);
    },
    makeComponentBorderStyle = (component: AnyComponent, borderColorOverrideWithoutOpacity?: null | undefined | Color): Record<string, any> =>
        makeBorderStyle(path.styleBorder, component, borderColorOverrideWithoutOpacity);

export {
    cssConfig as default,
    makeBorderToCssConfig,
    makeBorderStyle,
    makeComponentBorderStyle
};
