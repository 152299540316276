import { getSelectedNodesProperties } from "./getSelectedNodesProperties";

import type { TinyMceEditor } from "../../../flowTypes";

export const getSelectedNodeProperty = (editor: TinyMceEditor, selector: (n: any) => any) => {
    if (!editor || editor.removed) {
        return '';
    }

    const properties = getSelectedNodesProperties(editor, selector);

    return properties ? properties[0] : '';
};
