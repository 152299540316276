import * as R from 'ramda';
import type { ComponentsMap } from "../../../../redux/modules/children/workspace/flowTypes";
import type { SectionComponent } from "../../../oneweb/Section/flowTypes";
import { getSectionsOrderdByTop, isHeaderSection, isFooterSection } from "../../../oneweb/Section/utils";
import type { AnyComponent } from "../../../oneweb/flowTypes";

const getDefaultTitle = (cmp, count) => {
    if (isHeaderSection(cmp)) {
        return 'Header';
    }
    if (isFooterSection(cmp)) {
        return 'Footer';
    }
    return `Section${count}`;
};

export const initializeSectionTitles = (
    componentsMap: ComponentsMap
): ComponentsMap => {
    const sectionComponents: Array<AnyComponent> = getSectionsOrderdByTop(componentsMap),
        isInitialized = !!sectionComponents.find((cmp) => cmp.title);

    if (isInitialized) {
        return componentsMap;
    }

    let count = 0;

    const sectionComponentsMap = R.mergeAll(sectionComponents.map((cmp) => {
        if (cmp.title) {
            return {
                [cmp.id]: cmp
            };
        }
        count++;
        return {
            [cmp.id]: { ...cmp, title: getDefaultTitle(cmp, count) }
        };
    }));
    return {
        ...componentsMap,
        ...sectionComponentsMap
    };
};

export const getTitleForSection = (componentsMap: ComponentsMap, cmp: SectionComponent) => {
    const sectionComponents: Array<AnyComponent> = getSectionsOrderdByTop(componentsMap),
        sectionTitles = sectionComponents.map((cmp) => cmp.title).filter(title => title),
        { title = '' } = cmp;

    let count = 0, sectionTitle = title;

    const tailingDigitsRegex = /\d+$/,  //NOSONAR
        defaultCount = parseInt(R.match(tailingDigitsRegex, sectionTitle)[0] || 0, 10),
        defaultTitle = title.replace(tailingDigitsRegex, '');

    while (!sectionTitle || sectionTitles.includes(sectionTitle)) {
        count++;
        sectionTitle = title ? `${defaultTitle}${count + defaultCount}` : getDefaultTitle(cmp, count);
    }
    return sectionTitle;
};
