import { UPGRADE } from "../../App/actionTypes";

export const
    FacebookFeedNotAllowedInNormalWSB = 'FacebookFeedNotAllowedInNormalWSB';

export const
    ErrorMsgsOnAdd = {
        [FacebookFeedNotAllowedInNormalWSB]: {
            header: 'msg: component.facebookFeedGallery.premiumFeature {Premium feature}',
            // eslint-disable-next-line max-len
            text: 'msg: component.facebookFeedGallery.notAllowedInNormalWSB {Your current Website Builder does not include Facebook feed galleries. Upgrade to Premium to get this and other great features.}',
            linkText: 'msg: common.learnMore {Learn more}',
            linkCustomActionDispatch: {
                actionType: UPGRADE,
                actionPayload: "Workspace:FacebookFeedPremiumComponent"
            }
        }
    };
