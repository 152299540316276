import CALL_API from '../../../redux/middleware/api/CALL_API';
import {
    CHECK_STYLESHEET_DELETABLE_REQUEST,
    CHECK_STYLESHEET_DELETABLE_SUCCESS,
    CHECK_STYLESHEET_DELETABLE_FAILURE
} from '../epics/stylesheets/actionTypes';

export default (stylesheetId: string, stylesheetNameInComponent: string): any => ({
    [CALL_API]: {
        types: [
            CHECK_STYLESHEET_DELETABLE_REQUEST,
            CHECK_STYLESHEET_DELETABLE_SUCCESS,
            CHECK_STYLESHEET_DELETABLE_FAILURE
        ],
        endpoint: 'checkStylesheetDeletable',
        endpointParams: [stylesheetId, stylesheetNameInComponent]
    }
});
