import { injectIntl } from '../../../../view/intl/index';
import { WIDGETS_FACEBOOK_SHOW_UPDATE_LINK_DIALOG } from './actionTypes';
import { ctaOnClickFactory, linkExtractor } from '../utils';
import { getFacebookPostType } from './utils/parseFacebookCode';
import { PostType } from './constants';

const CtaButtonTextView = injectIntl((props) => {
    const { selectedComponent: { link }, intl } = props;
    const postType = getFacebookPostType(link);

    if (postType === PostType.VIDEO) {
        return intl.msgJoint("msg: widgets.facebook.cta.changeVideo {Change video}");
    } else if (postType === PostType.POST) {
        return intl.msgJoint("msg: widgets.facebook.cta.changePost {Change post}");
    }

    return intl.msgJoint("msg: widgets.cta.changeWidget {Change widget}");
});

const componentMainActions = {
    editButtonText: 'msg: common.settings {Settings}',
    CtaButtonTextView,
    ctaOnClick: ctaOnClickFactory(
        WIDGETS_FACEBOOK_SHOW_UPDATE_LINK_DIALOG,
        component => ({ link: linkExtractor(component) })
    ),
};

export { componentMainActions };
