export const
    CONTACT_FORM_EDIT_FIELDS = 'CONTACT_FORM_EDIT_FIELDS',
    CONTACT_FORM_TOGGLE_CAPTCHA = 'CONTACT_FORM_TOGGLE_CAPTCHA',
    CAPTCHA_LOCALE_CHANGED = 'CAPTCHA_LOCALE_CHANGED',
    CONTACT_FORM_ENABLE_CAPTCHA = 'CONTACT_FORM_ENABLE_CAPTCHA',
    CONTACT_FORM_DISABLE_CAPTCHA = 'CONTACT_FORM_DISABLE_CAPTCHA',
    CONTACT_FORM_CANCEL_DISABLE_CAPTCHA = 'CONTACT_FORM_CANCEL_DISABLE_CAPTCHA',
    CONTACT_FORM_SELECT_FIELD = 'CONTACT_FORM_SELECT_FIELD',
    CONTACT_FORM_EDIT_FIELDS_SAVE = 'CONTACT_FORM_EDIT_FIELDS_SAVE',
    CONTACT_FORM_UPDATE_EDITED_FIELDS = 'CONTACT_FORM_UPDATE_EDITED_FIELDS',
    CONTACT_FORM_EDIT_FIELDS_CANCELED = 'CONTACT_FORM_EDIT_FIELDS_CANCELED',
    CONTACT_FORM_ADD_FIELDS = 'CONTACT_FORM_ADD_FIELDS',
    CONTACT_FORM_FIELD_TOGGLED = 'CONTACT_FORM_FIELD_TOGGLED',
    CONTACT_FORM_ADD_FIELDS_SAVE = 'CONTACT_FORM_ADD_FIELDS_SAVE',
    CONTACT_FORM_ADD_FIELDS_DND_SAVE = 'CONTACT_FORM_ADD_FIELDS_DND_SAVE',
    CONTACT_FORM_ADD_FIELDS_CANCELED = 'CONTACT_FORM_ADD_FIELDS_CANCELED',
    CONTACT_FORM_DELETE_FIELD = 'CONTACT_FORM_DELETE_FIELD',
    CONTACT_FORM_FIELD_TITLE_CHANGED = 'CONTACT_FORM_FIELD_TITLE_CHANGED',
    CONTACT_FORM_FIELD_FILE_TYPE_CHANGED = 'CONTACT_FORM_FIELD_FILE_TYPE_CHANGED',
    CONTACT_FORM_FIELD_REQUIRED_TOGGLED = 'CONTACT_FORM_FIELD_REQUIRED_TOGGLED',
    CONTACT_FORM_FIELD_ERR_MSG_TOGGLED = 'CONTACT_FORM_FIELD_ERR_MSG_TOGGLED',
    CONTACT_FORM_FIELD_ERROR_MESSAGE_CHANGED = 'CONTACT_FORM_FIELD_ERROR_MESSAGE_CHANGED',
    CONTACT_FORM_FIELD_OPTION_CHANGED = 'CONTACT_FORM_FIELD_OPTION_CHANGED',
    CONTACT_FORM_FIELD_OPTION_DELETED = 'CONTACT_FORM_FIELD_OPTION_DELETED',
    CONTACT_FORM_FIELD_OPTION_ADDED = 'CONTACT_FORM_FIELD_OPTION_ADDED',
    CONTACT_FORM_CHANGED_FIELDS_ORDER = 'CONTACT_FORM_CHANGED_FIELDS_ORDER',
    CONTACT_FORM_EMAIL_CHANGED = 'CONTACT_FORM_EMAIL_CHANGED',
    CONTACT_FORM_SUBJECT_CHANGED = 'CONTACT_FORM_SUBJECT_CHANGED',
    CONTACT_FORM_SUCCESS_MESSAGE_CHANGED = 'CONTACT_FORM_SUCCESS_MESSAGE_CHANGED',
    CONTACT_FORM_FONT_COLOR_CHANGED = 'CONTACT_FORM_FONT_COLOR_CHANGED',
    CONTACT_FORM_FONT_COLOR_CHANGED_AUTO_COLOR = 'CONTACT_FORM_FONT_COLOR_CHANGED_AUTO_COLOR',
    CONTACT_FORM_FONT_COLOR_REMOVED_AUTO_COLOR = 'CONTACT_FORM_FONT_COLOR_REMOVED_AUTO_COLOR',
    CONTACT_FORM_FONT_CHANGED = 'CONTACT_FORM_FONT_CHANGED',
    CONTACT_FORM_FONT_SIZE_CHANGED = 'CONTACT_FORM_FONT_SIZE_CHANGED',
    CONTACT_FORM_SEND_BUTTON_FONT_SIZE_CHANGED = 'CONTACT_FORM_SEND_BUTTON_FONT_SIZE_CHANGED',
    CONTACT_FORM_FILE_UPLOAD_BUTTON_FONT_SIZE_CHANGED = 'CONTACT_FORM_FILE_UPLOAD_BUTTON_FONT_SIZE_CHANGED',
    CONTACT_FORM_CHANGE_BUTTON_TEXT = 'CONTACT_FORM_CHANGE_BUTTON_TEXT',
    CONTACT_FORM_CHANGE_BUTTON_STYLE = 'CONTACT_FORM_CHANGE_BUTTON_STYLE',
    CONTACT_FORM_CHANGE_BUTTON_STYLE_FOR_AUTO_COLOR = 'CONTACT_FORM_CHANGE_BUTTON_STYLE_FOR_AUTO_COLOR',
    CONTACT_FORM_CHANGE_FILE_UPLOAD_BUTTON_STYLE = 'CONTACT_FORM_CHANGE_FILE_UPLOAD_BUTTON_STYLE',
    CONTACT_FORM_CHANGE_FILE_UPLOAD_BUTTON_STYLE_FOR_AUTO_COLOR = 'CONTACT_FORM_CHANGE_FILE_UPLOAD_BUTTON_STYLE_FOR_AUTO_COLOR',
    CONTACT_FORM_HANDLE_MISSING_EMAIL = 'CONTACT_FORM_HANDLE_MISSING_EMAIL',
    CONTACT_FORM_SET_EMAIL_CLICKED = 'CONTACT_FORM_SET_EMAIL_CLICKED',
    CONTACT_FORM_SET_EMAIL = 'CONTACT_FORM_SET_EMAIL',
    CONTACT_FORM_SET_EMAIL_PP = 'CONTACT_FORM_SET_EMAIL_PP',
    CONTACT_FORM_SET_SUBJECT_PP = 'CONTACT_FORM_SET_SUBJECT_PP',
    CONTACT_FORM_SET_MAIL_CANCELED = 'CONTACT_FORM_SET_MAIL_CANCELED',
    CONTACT_FORM_CONFIGURATION_DIALOG_MOUNTED = 'CONTACT_FORM_CONFIGURATION_DIALOG_MOUNTED',
    ENABLE_MARKETING_CONSENT = 'ENABLE_MARKETING_CONSENT',
    DISABLE_MARKETING_CONSENT = 'DISABLE_MARKETING_CONSENT',
    MARKETING_CONSENT_GO_TO_DASHBOARD_FOR_PP = "MARKETING_CONSENT_GO_TO_DASHBOARD_FOR_PP",
    MARKETING_CONSENT_CHECKBOX_TEXT_CHANGED = 'MARKETING_CONSENT_CHECKBOX_TEXT_CHANGED',
    MARKETING_CONSENT_BACK_NAVIGATION = 'MARKETING_CONSENT_BACK_NAVIGATION';
