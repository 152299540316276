import React from "react";
export const SvgYoutubeRoundedFillMulticolor = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <rect width={48} height={48} fill="#f44336" rx={6} />
            <path
                fill="#ffffff"
                d="M36.15 18.782s-.243-1.718-.992-2.473c-.949-.993-2.01-.998-2.496-1.056C29.18 15 23.952 15 23.952 15h-.01s-5.229 0-8.711.253c-.487.058-1.547.063-2.496 1.056-.749.755-.987 2.473-.987 2.473s-.248 2.015-.248 4.034v1.889c0 2.015.248 4.035.248 4.035s.243 1.718.987 2.472c.949.993 2.194.959 2.748 1.066 1.995.19 8.468.248 8.468.248s5.234-.01 8.716-.258c.487-.058 1.547-.063 2.495-1.056.75-.754.993-2.472.993-2.472s.248-2.015.248-4.035v-1.889a38.75 38.75 0 00-.253-4.034zm-14.776 8.215v-7.003l6.726 3.514-6.726 3.49z"
            />
        </g>
    </svg>
);
