import { applyMappers, makePlainMappers } from "../../utils";
import type { AnyComponentData } from "../../../../src/components/App/flowTypes";
import type { ComponentBaseWithKind } from "../../../../src/components/oneweb/flowTypes";

const plainPropsMapper = makePlainMappers({
    colorType: 'colorType',
    themeColorType: 'themeColorType',
    size: 'size',
    spacing: 'spacing',
    mobileSize: 'mobileSize',
    mobileHorizontalAlignment: 'mobileHorizontalAlignment',
    darkToLightValue: 'darkToLightValue',
    styleType: 'styleType',
    height: 'height',
    links: 'links',
    mobileHide: 'mobileHide',
    mobileDown: 'mobileDown',
    modernLayoutOptions: 'modernLayoutOptions',
});

export function to(componentData: AnyComponentData): ComponentBaseWithKind {
    return applyMappers(
        componentData,
        plainPropsMapper.to
    );
}

export function back(component: ComponentBaseWithKind): AnyComponentData {
    return applyMappers(
        component,
        plainPropsMapper.back
    );
}
