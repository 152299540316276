import type { Position } from "../../../redux/modules/flowTypes";

export const
    PROPERTIES_PANEL_SHOW_CONTEXT_MENU = 'PROPERTIES_PANEL_SHOW_CONTEXT_MENU',
    PROPERTIES_PANEL_NAVIGATE_TO_PAGE = 'PROPERTIES_PANEL_NAVIGATE_TO_PAGE',
    PROPERTIES_PANEL_NAVIGATE_BACK = 'PROPERTIES_PANEL_NAVIGATE_BACK',
    MOUSE_DOWN_ON_PROPERTIES_PANEL_DRAGGABLE_AREA = 'MOUSE_DOWN_ON_PROPERTIES_PANEL_DRAGGABLE_AREA',
    MOUSE_UP_ON_PROPERTIES_PANEL_DRAGGABLE_AREA = 'MOUSE_UP_ON_PROPERTIES_PANEL_DRAGGABLE_AREA',
    PROPERTIES_PANEL_PAGE_HEIGHT = 'PROPERTIES_PANEL_PAGE_HEIGHT';

export const
    mouseDownOnPropertiesPanelDraggableAreaAction = (position: Position) => ({
        type: MOUSE_DOWN_ON_PROPERTIES_PANEL_DRAGGABLE_AREA,
        payload: position,
    }),
    mouseUpOnPropertiesPanelDraggableAreaAction = (position: Position) => ({
        type: MOUSE_UP_ON_PROPERTIES_PANEL_DRAGGABLE_AREA,
        payload: position,
    }),
    navigatePropertiesPanelBackAction = () => ({
        type: PROPERTIES_PANEL_NAVIGATE_BACK,
    });
