import { pickBy } from 'ramda';
import { intlCreateOptions } from "../../../../../../view/common/Combobox/utils";
import { gmbKeysMap } from "../../../gmbKeyList";

export const normalizeLabelCaseSensitive = (label) => {
    return label.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const normalizeLabel = (label) => {
    return label.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

const invalidGmbKeysForTrial = { 'online_shop': true, 'web_shop': true };
const filterGmbKeysForTrial = (keysMap) => {
    return pickBy((_, k) => !invalidGmbKeysForTrial[k.toLowerCase()], keysMap);
};

export const createGMBOption = (value) => {
    return {
        value: (value || '').toLowerCase().replace(/\s/g, '_'),
        label: (value || '').replace(/_/g, ' ')
    };
};

export const getDefaultGMBOption = (options, value) => {
    let option = options.filter(el => el.value === value);

    if (option.length) {
        return option[0];
    } else {
        return {
            value: (value || '').toLowerCase().replace(/\s/g, '_'),
            label: (value || '').replace(/_/g, ' ')
        };
    }
};

export const getGMBEntry = (text, shopDisabled, intl) => {
    const gmbText = text.toLowerCase().trim();

    if (shopDisabled && invalidGmbKeysForTrial[gmbText]) { return null; }

    return Object.entries(gmbKeysMap)
        .find(el => intl?.msgJoint(el[1]).toLowerCase() === gmbText);
};

export const getGMBCategoryOptions = (intl: Intl, shopDisabled: boolean) => {
    let finalGmbKeysMap = gmbKeysMap;
    if (shopDisabled) {
        finalGmbKeysMap = filterGmbKeysForTrial(gmbKeysMap);
    }
    const gmbCategoryOptions = intlCreateOptions(finalGmbKeysMap, intl);
    gmbCategoryOptions.sort((a, b) => a.label.length - b.label.length);

    return gmbCategoryOptions;
};

export const createRegexFromValue = (value) => {
    const updatedValue = normalizeLabel(value.replace(/[.*+]/g, '').trim());
    let valueList = [updatedValue, ...new Set(updatedValue.split(/\s+/))];
    const regexValue = valueList.join('|');

    return regexValue;
};

export const convertLabelAsPerLanguage = (result, intl) => {
    return result.map(({ value }) => {
        return {
            value,
            label: intl.msgJoint(gmbKeysMap[value])
        };
    });
};

export const createValueFromLabel = (label) => {
    return label.toLowerCase().split(/\s+/).join('_');
};
