import { $ } from 'tinymce';
import { head, last } from 'ramda';
import { memoMaxOneCheckOneArg } from '../../../../../../../../utils/memo';
import { isValidContentBlock, isValidFirstChild, isValidLastChild } from './utils';
import { isTextNode } from '../../../../../../../utils/dom';
import type { TinyMceEditor } from "../../../flowTypes";

/**
 * Function goes recursively to the last children of node and returns it in correct order
 * @param {DOM element} node
 */
const extractLeaves = (node: Node, startOffset: number = 0, endOffset?: number): Node[] => {
    return !node.hasChildNodes() ? [node] :
        Array.from(node.childNodes).slice(startOffset, endOffset)
            .reduce((leaves: Node[], child) => leaves.concat(extractLeaves(child)), []);
};

const collapseLeaves = memoMaxOneCheckOneArg((leaves: HTMLElement[], root: HTMLElement): HTMLElement[] => {
    let
        prevNodes,
        nodesChangedInIteration,

        nextNodes = leaves,
        currentNode: Node | null = null,
        lastNode: Node | null = null,
        currentNodeLeaves: HTMLElement[] = [];

    const resetCurrentNode = () => {
        currentNode = null;
        currentNodeLeaves = [];
    };

    do {
        resetCurrentNode();
        nodesChangedInIteration = 0;
        prevNodes = nextNodes;
        nextNodes = [];

        // eslint-disable-next-line
        prevNodes.forEach(node => {
            if (currentNode || (
                !isValidContentBlock(node) &&
                node.parentNode !== root &&
                isValidFirstChild(node)
            )) {
                lastNode = null;

                if (!currentNode) {
                    currentNode = node.parentNode;
                }

                if (currentNode !== node.parentNode) {
                    nextNodes = nextNodes.concat(currentNodeLeaves);
                    resetCurrentNode();
                    currentNode = node.parentNode;
                }

                if (isValidLastChild(node)) {
                    if (!$(currentNode).hasClass('mceNonEditable')) {
                        nodesChangedInIteration++;
                        // $FlowFixMe: WBTGEN-9962: parentNode can be null, put checks
                        nextNodes.push(currentNode as HTMLElement);
                        lastNode = currentNode;
                    }

                    resetCurrentNode();
                } else {
                    currentNodeLeaves.push(node);
                }
            } else if (!lastNode || node.parentNode !== lastNode) {
                lastNode = null;
                nextNodes.push(node);
            }
        });

        nextNodes = nextNodes.concat(currentNodeLeaves);
    } while (nodesChangedInIteration);

    return nextNodes;
});

const getLeavesFromSelection = (
    selectedNode: Element,
    startContainer: Node, startOffset: number,
    endContainer: Node, endOffset: number,
    shouldIgnorePartiallySelected: boolean
) => {
    if (!startContainer || !endContainer) return [];

    const isSelectionInSameContainer = () => startContainer === endContainer;

    // Selection is with in a single text node
    if (isSelectionInSameContainer() && isTextNode(startContainer)) {
        // $FlowFixMe: WBTGEN-9962: length property doesn't exist in Node
        // @ts-ignore
        const isEntirelySelected = (startOffset === 0 && endOffset === startContainer.length);
        return (isEntirelySelected || !shouldIgnorePartiallySelected) ? [startContainer] : [];
    }

    // If end of the selection is beginning of a node, set previous node as endContainer (unless its just cursor)
    if (
        !isSelectionInSameContainer() && !isTextNode(endContainer) &&
        endOffset === 0 && endContainer.previousSibling
    ) {
        endContainer = endContainer.previousSibling; // eslint-disable-line
        // $FlowFixMe: WBTGEN-9962: length property doesn't exist in Node
        // @ts-ignore
        endOffset = isTextNode(endContainer) ? endContainer.length : endContainer.childNodes.length; // eslint-disable-line
    }

    // If start of the selection is end of a node, set next node as startContainer (unless its just cursor)
    if (
        !isSelectionInSameContainer() && !isTextNode(startContainer) &&
        startOffset === startContainer.childNodes.length && startContainer.nextSibling
    ) {
        startContainer = startContainer.nextSibling; // eslint-disable-line
        startOffset = 0; // eslint-disable-line
    }

    // extract leaves from selectedNode
    // selectedNode can not be 'text' element so it's usually parent element
    let leaves: Node[] = extractLeaves(selectedNode);

    // if br is the only selected node return
    // NOTE: This is needed only for getSelectedNodesProperties, add a new flag for this if necessary
    // $FlowFixMe: WBTGEN-9962: use nodeName instead of tagName, which returns tagName for HTMLElements
    // @ts-ignore
    if (leaves.length === 1 && leaves[0].tagName === 'BR') {
        return leaves;
    }

    // start leaf is the first element of selected text we need to get all leaves because in some cases start
    // leaf is <span> or <p> so it could contain multiple text nodes
    let firstSelectedLeafIndex;
    if (isTextNode(startContainer)) {
        firstSelectedLeafIndex = leaves.indexOf(startContainer);
        if (shouldIgnorePartiallySelected && startOffset !== 0) {
            firstSelectedLeafIndex++;
        }
    } else {
        const didSelectionBeginAtEndOfContainer = (startOffset === startContainer.childNodes.length);
        firstSelectedLeafIndex = leaves.indexOf(
            head(extractLeaves(startContainer, didSelectionBeginAtEndOfContainer ? -1 : startOffset))
        );
        if (
            (didSelectionBeginAtEndOfContainer && shouldIgnorePartiallySelected) ||
            (didSelectionBeginAtEndOfContainer && !shouldIgnorePartiallySelected && !isSelectionInSameContainer())
        ) {
            firstSelectedLeafIndex++;
        }
    }

    // end leaf is the last element of selected text
    let lastSelectedLeafIndex;
    if (isTextNode(endContainer)) {
        lastSelectedLeafIndex = leaves.indexOf(endContainer);
        // $FlowFixMe: WBTGEN-9962: length property doesn't exist in Node
        // @ts-ignore
        if (shouldIgnorePartiallySelected && endOffset !== endContainer.length) {
            lastSelectedLeafIndex--;
        }
    } else {
        const didSelectionEndAtStartOfContainer = (endOffset === 0);
        lastSelectedLeafIndex = leaves.indexOf(
            last(extractLeaves(endContainer, 0, didSelectionEndAtStartOfContainer ? 1 : endOffset))
        );
        if (
            (didSelectionEndAtStartOfContainer && shouldIgnorePartiallySelected) ||
            (didSelectionEndAtStartOfContainer && !shouldIgnorePartiallySelected && !isSelectionInSameContainer())
        ) {
            lastSelectedLeafIndex--;
        }
    }

    return leaves.slice(firstSelectedLeafIndex, lastSelectedLeafIndex + 1);
};

export const getSelectedTextNodes = (
    editor: TinyMceEditor, shouldIgnorePartiallySelected: boolean = false
): Node[] => {
    if (!editor || editor.removed) {
        return [];
    }

    const selection = editor.selection;
    const selectedNode = selection.getNode();

    // https://developer.mozilla.org/en-US/docs/Web/API/Range
    // Reference for following properties
    const { startContainer, startOffset, endContainer, endOffset } = selection.getRng();

    return getLeavesFromSelection(
        selectedNode,
        startContainer, startOffset,
        endContainer, endOffset,
        shouldIgnorePartiallySelected
    );
};

export const getSelectedNodes = (
    editor: TinyMceEditor, shouldIgnorePartiallySelected: boolean = false
): HTMLElement[] => {
    const leaves = getSelectedTextNodes(editor, shouldIgnorePartiallySelected);
    return collapseLeaves(leaves, editor.getBody());
};
