import React, { useRef, useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { Grid } from "@mui/material";

import { LogoSettingsAltTextForm } from "./LogoSettingsAltTextForm";
import { LogoSettingsImagePreview } from "./LogoSettingsImagePreview";
import { LogoSettingsImageChooser } from "./LogoSettingsImageChooser";

import { uploadFromComputerAction, fetchImageContents, getResourceMetaData } from "../../../../ImageChooser/actions";
import { imageChooserAssetKeyMap } from "../../../../ImageChooser/config";
import { selectImageChooserData, selectLogoAsset } from "../../../../../common/selectors";
import { setLogoAssetAction } from "./actions";

import { useStyles } from './styles';

import type { AppState } from "../../../../../common/types";
import type { ImageAsset } from "../../../../../../../../wbtgen/src/components/App/flowTypes";

type LogoSettingsFormProps = {
    logoAsset: ImageAsset | null | undefined,
    isResourcesLoaded: Boolean,
    isAssetMetaDataLoaded: Boolean
}

export const LogoSettingsFormComponent = (props: LogoSettingsFormProps) => {
    const { logoAsset, isResourcesLoaded, isAssetMetaDataLoaded } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [file, setFile] = useState(null);
    const onFileChange = (e) => {
        const files = e.target.files;
        if (files.length) {
            setFile(files[0]);
        }
        dispatch(uploadFromComputerAction(files, imageChooserAssetKeyMap.logo));
    };

    const onChange = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const discardFile = () => {
        setFile(null);

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const onRemove = (e) => {
        e.preventDefault();
        e.stopPropagation();
        discardFile();

        // @ts-ignore this can be a bug
        dispatch(setLogoAssetAction(null));
    };

    useEffect(() => {
        if (!isResourcesLoaded) {
            dispatch(fetchImageContents());
        }
    }, []);

    useEffect(() => {
        if (file && isResourcesLoaded && !isAssetMetaDataLoaded) {
            dispatch(getResourceMetaData(file));
        }
    }, [isResourcesLoaded]);

    useEffect(discardFile, [logoAsset]);

    return (
        <Grid container spacing={3} data-testid="logo-settings">
            <Grid item xs={12}>
                <Grid className={classes.logoSettingsFormContainer} container alignItems="center">
                    <Grid item xs={12} md={6}>
                        <LogoSettingsImagePreview
                            asset={logoAsset}
                            onChange={onChange}
                            onRemove={onRemove}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LogoSettingsImageChooser
                            asset={logoAsset}
                            hintWarning=""
                            onChange={onChange}
                            onRemove={onRemove}
                        />
                    </Grid>
                </Grid>
                <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={onFileChange}
                />
            </Grid>
            <Grid item xs={12}>
                <LogoSettingsAltTextForm />
            </Grid>
        </Grid>
    );
};

const mapStateToProps = (state: AppState) => ({
    logoAsset: selectLogoAsset(state),
    isResourcesLoaded: selectImageChooserData(state).isResourcesLoaded,
    isAssetMetaDataLoaded: selectImageChooserData(state).isAssetMetaDataLoaded,
});

export const LogoSettingsForm = connect(mapStateToProps)(LogoSettingsFormComponent);
