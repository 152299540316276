import { parseBingMap } from './parseBingMap';
import { DEFAULT_MAP_PARAMS } from './constants';
import { type BingMapsParams, type BingMapsQueryParams } from "./types";

export const getMapParams = (mapLink:string) => {
    let qp = {};

    if (mapLink.includes("?")) {
        const parts = mapLink.split("?"),
            query = parts[1] || "";

        qp = query.split('&').reduce((acc, param) => {
            const [key, value] = param.split("=");
            return { ...acc, [key]: value };
        }, {});
    }

    return qp;
};

export const isBingMapsLinkValid = (link: string) => {
    if (/^(?:https:\/\/)?(?:www\.)?bing\.com\/maps(?:\/embed-a-map)?\?/.test(link)) {
        const qp = getMapParams(link) as BingMapsQueryParams;
        if (qp.cp) {
            const cpValid = /^(\-)?\d+(\.\d+)?\~(\-)?\d+(\.\d+)?$/.test(decodeURIComponent(qp.cp));
            return qp.lvl ? (cpValid && /^\d+(\.\d+)?$/.test(qp.lvl)) : cpValid;
        }
    }

    return false;
};

export const parseBingMapsCode = (code: string): BingMapsParams => {
    const params = <BingMapsParams>{};
    const str = code.trim();

    if (isBingMapsLinkValid(str)) {
        const qparams = getMapParams(str) as BingMapsQueryParams;
        const { cp, lvl, w, h } = { ...DEFAULT_MAP_PARAMS, ...qparams };

        params.link = 'https://www.bing.com/maps/embed?' +
            [`h=${h}`, `w=${w}`, `cp=${cp}`, `lvl=${lvl}`].join('&');
    } else {
        const { width, height, link, largeMapLink, dirMapLink } = parseBingMap(str);
        if (link) {
            params.link = link;
            params.width = width;
            params.height = height;

            if (largeMapLink) params.largeMapLink = largeMapLink;
            if (dirMapLink) params.dirMapLink = dirMapLink;
        }
    }

    return params;
};

export const getIframeSrc = ({ link, width, height }) => link
    .replace(/w=(\d+)/, `w=${width}`)
    .replace(/h=(\d+)/, `h=${height}`);
