// @ts-nocheck
// WBTGEN-2173 - This disables dropping files to browser window
const disableDropToWindow = function () {
    window.addEventListener('dragover', function (e) {
        e.preventDefault();
    }, false);
    window.addEventListener('drop', function (e) {
        e.preventDefault();
    }, false);
};
disableDropToWindow();

export {};
