import React from "react";
export const SvgTripadvisorRegularMulticolor = props => (
    <svg viewBox="0 0 43 43" {...props}>
        <g fill="none">
            <circle cx={21.5} cy={21.5} r={21.5} fill="#34e0a1" />
            <g fill="#000000" transform="translate(6.5 12)">
                <path d="M7.5 13.803a2.658 2.658 0 100-5.315 2.658 2.658 0 000 5.315z" />
                <circle cx={22.48} cy={11.15} r={2.66} />
                <path d="M.008 11.146A7.491 7.491 0 0012.59 16.64l2.401 2.614 2.402-2.612a7.494 7.494 0 0012.585-5.496 7.474 7.474 0 00-2.446-5.537l2.452-2.667H24.55a16.976 16.976 0 00-19.1 0H0l2.451 2.667a7.473 7.473 0 00-2.443 5.537zm27.545 0a5.07 5.07 0 11-10.14 0 5.07 5.07 0 0110.14 0zM14.99 2.45c1.932-.002 3.846.38 5.629 1.126C17.42 4.8 14.994 7.664 14.994 11c0-3.337-2.427-6.2-5.627-7.424a14.546 14.546 0 015.624-1.125zM7.5 6.076a5.07 5.07 0 110 10.14 5.07 5.07 0 010-10.14z" />
            </g>
        </g>
    </svg>
);
