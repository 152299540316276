import React, { useRef } from 'react';
import cx from 'classnames';
import { injectIntl } from "../../intl/index";
import styles from './Textarea.css';
import focusHandler from "../../../utils/inputFocusSelect";
import type { InputCounterProps } from "./counter/types";
import { InputCounter } from "./counter/InputCounter";
import * as keyCodes from "../../../components/App/epics/isKeyPressed/keyCodes";
import { isSafari } from "../../../../utils/browser";

type TextareaProps = {
    disabled?: boolean,
    placeholder?: string,
    value?: string,
    className?: string,
    onChange?: Function,
    onAfterPaste?: Function,
    onBlur?: Function,
    onFocus?: Function,
    autoFocus?: boolean,
    maxLength?: number,
    intl: any,
    style?: { [k: string]: string | number },
    counter?: InputCounterProps,
    countBeyondMax?: boolean,
    isMandatory?: boolean
}

const
    createCallback = (e, cb) => {
        if (cb && e.target && e.target.value !== undefined) {
            cb(e.target.value);
        }
    },
    createOnChangeCallbackForSafari = (e, cb, maxLength) => {
        if (cb && e.target && e.target.value !== undefined &&
            (!e.target.value || !maxLength || e.target.value.length <= maxLength)) {
            cb(e.target.value);
        }
    },
    createOnPasteCallbackForSafari = (e, onAfterPasteCb, onChangeCb, maxLength) => {
        if ((onAfterPasteCb || onChangeCb) && e.target && e.target.value !== undefined) {
            // WBTGEN-11010: Safari Issue - Implementing custom maxLength property.
            // NOTE: This is needed to handle paste.
            // NOTE: Target will have old value in onpaste event whereas it will have new value in onChange.
            const
                copiedValue = e.clipboardData.getData('Text'),
                target = e.target;
            if (
                maxLength && target.value && copiedValue &&
                target.value.length + copiedValue.length > maxLength &&
                (target.selectionStart || target.selectionStart === '0')
            ) {
                const
                    startPos = target.selectionStart,
                    endPos = target.selectionEnd,
                    charactersAllowedToPaste = maxLength - target.value.length,
                    copiedValueAllowedToPaste = copiedValue.substr(0, charactersAllowedToPaste),
                    result = target.value.substring(0, startPos) +
                        copiedValueAllowedToPaste +
                        target.value.substring(endPos, target.value.length);

                if (onAfterPasteCb) { onAfterPasteCb(result); }
                // We need to call onChange with here since we are preventing onchange in createOnChangeCallbackForSafari.
                if (onChangeCb) { onChangeCb(result); }
            } else if (onAfterPasteCb) {
                onAfterPasteCb(e.target.value);
            }
        }
    },
    keyCodesToBypass = [
        keyCodes.ESCAPE,
        keyCodes.DELETE,
        keyCodes.BACKSPACE,
        keyCodes.TAB,
        keyCodes.F2,
        keyCodes.LEFT_ARROW,
        keyCodes.UP_ARROW,
        keyCodes.RIGHT_ARROW,
        keyCodes.DOWN_ARROW
    ],
    isMaxLengthForSafari = (e, maxLength) => {
        // WBTGEN-11010: Safari Issue - Implementing custom maxLength property.
        // Deprecated: But using as it is used in initSubscriptions --> onKeyDownOrUpFactory and e.key is not implemented in all browsers.
        if (!(keyCodesToBypass.indexOf(e.keyCode || e.charCode) > -1) && maxLength && e.target) {
            const value = e.target.value;
            if (value && value.length >= maxLength) {
                e.preventDefault();
                return true;
            }
        }
        return false;
    };

export default injectIntl(
    (
        {
            disabled,
            placeholder,
            value,
            className,
            onChange,
            onAfterPaste,
            autoFocus,
            maxLength,
            onFocus,
            onBlur,
            intl,
            style = {},
            counter,
            countBeyondMax = false,
            isMandatory
        }: TextareaProps // eslint-disable-line max-len
    ) => {
        const ref = useRef(null);

        const
            normalProps = {
                style,
                placeholder: placeholder && intl.msgJoint(placeholder),
                className: cx(styles.defaultStyle, className, { [styles.redBorder]: isMandatory && !value }),
                value,
                onChange: e => createCallback(e, onChange),
                onPaste: e => createCallback(e, onAfterPaste),
                onFocus: e => { if (onFocus) { onFocus(e); } focusHandler(e); },
                onBlur: e => { if (onBlur) { onBlur(e); } },
                disabled,
                autoFocus,
                maxLength,
                ref
            },
            safariProps = {
                onChange: e => createOnChangeCallbackForSafari(e, onChange, maxLength),
                // NOTE: React OnPaste will only have old value. (https://stackoverflow.com/questions/45079800/react-js-onpaste-not-working-as-expected)
                onPaste: e => createOnPasteCallbackForSafari(e, onAfterPaste, onChange, maxLength),
                onKeyDown: e => { return !isMaxLengthForSafari(e, maxLength); },
                maxLength: undefined
            },
            props = !isSafari() ? normalProps : { ...normalProps, ...safariProps };

        return (
            <React.Fragment>
                <textarea {...props} />
                {counter && <InputCounter {...counter} inputRef={ref} countBeyondMax={countBeyondMax} />}
            </React.Fragment>
        );
    }
);
