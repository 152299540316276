import React from "react";
import { InputBase } from "@mui/material";
import cx from "classnames";
import { useIntl } from "../../../../../../view/intl";
import styles from "./InputField.css";
import stepStyles from "../steps.css";
import { FieldError } from "./FieldError";

export const InputField = (props) => {
    const { label, placeholder, error, labelClass, errorMsg, msgCounter, rootclass, inputclass, ...rest } = props;
    const intl = useIntl();
    return (
        <div className={cx(styles.inputFieldContainer, { [styles.margin]: error })}>
            {label &&
            <div className={cx(styles.stepFieldLabel, labelClass, { [stepStyles.errorLabel]: error })}>
                {intl.msgJoint(label)}
            </div>}
            <InputBase
                classes={{
                    root: cx(rootclass, styles.root),
                    input: cx(inputclass, styles.input),
                    focused: styles.focused,
                    error: styles.error
                }}
                placeholder={placeholder && intl.msgJoint(placeholder)}
                error={error}
                {...rest}
            />
            {error && errorMsg && <FieldError
                message={errorMsg}
                counter={msgCounter}
            />}
        </div>
    );
};
