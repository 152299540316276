
export const
    GENERAL_INFO_CONTACT_EMAIL_INPUT_CHANGED = 'GENERAL_INFO_CONTACT_EMAIL_INPUT_CHANGED',
    GENERAL_INFO_PHONE_NUMBER_INPUT_CHANGED = 'GENERAL_INFO_PHONE_NUMBER_INPUT_CHANGED',
    GENERAL_INFO_WEBSITE_TITLE_INPUT_CHANGED = 'GENERAL_INFO_WEBSITE_TITLE_INPUT_CHANGED',
    GENERAL_INFO_WEBSITE_TITLE_INPUT_CHANGED_FROM_GMB_CREATE_STEP =
        'GENERAL_INFO_WEBSITE_TITLE_INPUT_CHANGED_FROM_GMB_CREATE_STEP',
    GENERAL_INFO_WEBSITE_DESCRIPTION_CHANGED = 'GENERAL_INFO_WEBSITE_DESCRIPTION_CHANGED',
    GENERAL_INFO_DELETE_MISSING_ASSETS = 'GENERAL_INFO_DELETE_MISSING_ASSETS',
    GENERAL_INFO_REPLACE_MISSING_ASSETS = 'GENERAL_INFO_REPLACE_MISSING_ASSETS',
    GENERAL_INFO_LOGO_CHOOSEN = 'GENERAL_INFO_LOGO_CHOOSEN',
    GENERAL_INFO_LOGO_ALT_TEXT_INPUT_CHANGED = 'GENERAL_INFO_LOGO_ALT_TEXT_INPUT_CHANGED',
    GENERAL_REMOVE_LOGO_PRESSED = 'GENERAL_REMOVE_LOGO_PRESSED',
    GENERAL_INFO_SITE_SETTINGS_TAB_FOCUS_INPUT = 'GENERAL_INFO_SITE_SETTINGS_TAB_FOCUS_INPUT',
    GENERAL_INFO_ADDRESS_CHANGED_ON_BLUR = 'GENERAL_INFO_ADDRESS_CHANGED_ON_BLUR',
    GENERAL_INFO_ADDRESS_CHANGED = 'GENERAL_INFO_ADDRESS_CHANGED',
    GENERAL_INFO_ADDRESS_GOOGLE_PLACE_RESPONSE = 'GENERAL_INFO_ADDRESS_GOOGLE_PLACE_RESPONSE',
    GENERAL_INFO_CONTACT_EMAIL_CHANGED_ON_BLUR = 'GENERAL_INFO_CONTACT_EMAIL_CHANGED_ON_BLUR',
    GENERAL_INFO_PHONE_NUMBER_CHANGED_ON_BLUR = 'GENERAL_INFO_PHONE_NUMBER_CHANGED_ON_BLUR',
    GENERAL_INFO_WEBSITE_TITLE_CHANGED_ON_BLUR = 'GENERAL_INFO_WEBSITE_TITLE_CHANGED_ON_BLUR',
    GENERAL_INFO_LOGO_ALT_TEXT_CHANGED_ON_BLUR = 'GENERAL_INFO_LOGO_ALT_TEXT_CHANGED_ON_BLUR',
    GENERAL_INFO_TAB_MOUNT = 'GENERAL_INFO_TAB_MOUNT',
    GENERAL_INFO_FAVICON_CHOOSEN = 'GENERAL_INFO_FAVICON_CHOOSEN',
    GENERAL_REMOVE_FAVICON_PRESSED = 'GENERAL_REMOVE_FAVICON_PRESSED',
    GENERAL_INFO_ADDRESS_NAME_INPUT_CHANGED = 'GENERAL_INFO_ADDRESS_NAME_INPUT_CHANGED',
    GENERAL_INFO_ADDRESS_NAME_INPUT_CHANGED_ON_BLUR = 'GENERAL_INFO_ADDRESS_NAME_INPUT_CHANGED_ON_BLUR',
    GENERAL_INFO_ADDRESS_STREET_ADDRESS_INPUT_CHANGED = 'GENERAL_INFO_ADDRESS_STREET_ADDRESS_INPUT_CHANGED',
    GENERAL_INFO_ADDRESS_STREET_ADDRESS_INPUT_CHANGED_ON_BLUR = 'GENERAL_INFO_ADDRESS_STREET_ADDRESS_INPUT_CHANGED_ON_BLUR',
    GENERAL_INFO_ADDRESS_ZIP_INPUT_CHANGED = 'GENERAL_INFO_ADDRESS_ZIP_INPUT_CHANGED',
    GENERAL_INFO_ADDRESS_ZIP_INPUT_CHANGED_ON_BLUR = 'GENERAL_INFO_ADDRESS_ZIP_INPUT_CHANGED_ON_BLUR',
    GENERAL_INFO_ADDRESS_CITY_INPUT_CHANGED = 'GENERAL_INFO_ADDRESS_CITY_INPUT_CHANGED',
    GENERAL_INFO_ADDRESS_CITY_INPUT_CHANGED_ON_BLUR = 'GENERAL_INFO_ADDRESS_CITY_INPUT_CHANGED_ON_BLUR',
    GENERAL_INFO_ADDRESS_AREA_INPUT_CHANGED = 'GENERAL_INFO_ADDRESS_AREA_INPUT_CHANGED',
    GENERAL_INFO_ADDRESS_AREA_INPUT_CHANGED_ON_BLUR = 'GENERAL_INFO_ADDRESS_AREA_INPUT_CHANGED_ON_BLUR',
    GENERAL_INFO_ADDRESS_COUNTRY_CODE_CHANGED = 'GENERAL_INFO_ADDRESS_COUNTRY_CODE_CHANGED';
