import type { CalcRenderer } from "../../../Workspace/epics/componentsProps/flowTypes";
import type { DocumentComponentProps } from "./flowTypes";
import type { TopBarLanguagesState } from "../../../TopBar/epics/languages/index";
import type { DocumentComponent } from "../flowTypes";
import { documentComponentStateToViewerUrl } from "../utils/documentComponentStateToViewerUrl";

type Renderer = CalcRenderer<
    DocumentComponent,
    void,
    { languages: TopBarLanguagesState },
    DocumentComponentProps
>;

export const documentComponentCalcRenderProps: Renderer = (
    { component, componentDependencies, isSelected, isResizing, componentHeight, isReordered, isMve, shortcut }
): DocumentComponentProps => {
    const { languages: { current: locale } } = componentDependencies;

    return {
        url: documentComponentStateToViewerUrl(component, locale),
        isSelected,
        isResizing,
        height: componentHeight,
        reload: isReordered,
        isMve,
        shortcut,
    };
};
