import cx from "classnames";
import getCenteredDialog from "../../../DialogManager/getCenteredDialogConfig";
import { configurationDialogFactory } from '../configurationDialogFactory';
import { GoFundMeDialogHeight, DialogWidth } from '../constants';
import { WIDGETS_GOFUNDME_SET_LINK } from "./actionTypes";
import { validateLinkOrCode } from '../utils';
import { parseGoFundMe } from './utils/parseGoFundMeCode';
import Icons from '../view/Icons.css';

const validateGoFundMeData = validateLinkOrCode([], parseGoFundMe);

const
    configurationDialogId = "WIDGETS_GOFUNDME_CONFIG_DIALOG",
    configurationDialogView = configurationDialogFactory({
        saveAction: WIDGETS_GOFUNDME_SET_LINK,
        dialogIconClass: cx(Icons.goFundMe),
        dialogTitle: "msg: widgets.gofundme.label {GoFundMe}",
        info: "msg: widgets.gofundme.config.info {Add a GoFundMe donation button to your page to collect donations for your cause.}",
        subInfo: "msg: widgets.gofundme.configDialog.copyPaste {Go to the fundraiser page on GoFundMe, copy the URL and paste it below. }", // eslint-disable-line max-len
        learnMore: "msg: widgets.gofundme.config.learnMore {https://help.one.com/hc/en-us/articles/11374775721233}",
        inputPlaceHolder: "msg: widgets.gofundme.configDialog.placeHolder {Paste GoFundMe URL}",
        isLinkValid: validateGoFundMeData,
        // eslint-disable-next-line max-len
        termsOfService: "msg: widgets.gofundme.config.termsOfService {By using this widget, you agree to GoFundMe\'s {termsOfServiceLink}.}",
        termsOfServiceLink: "msg: widgets.gofundme.config.termsOfServiceLink {https://www.gofundme.com/en-gb/c/terms-2 }",
    }),
    configurationDialog = getCenteredDialog({
        width: DialogWidth,
        height: GoFundMeDialogHeight,
        component: configurationDialogView
    });

export { configurationDialogId, configurationDialog };
