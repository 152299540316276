import React from 'react';
import cx from 'classnames';
import { uniq } from 'ramda';
import { dispatchForwardToSelectedComponent } from "../../../redux/forwardTo";
import { getPositionForDialogWithRespectToMouseClick } from '../../DialogManager/utility';
import { Dialog, StripTypes } from '../../../view/common/dialogs/baseDialog/index';
import Scrollbar from '../../../view/common/Scrollbar/index';
import siteFontsReducer from '../../Fonts/siteFontsReducer';
import { GOOGLE_PREFIX } from '../AddGoogleFont/constants';
import configurationDialogId from './fontSelectionConfigurationDialogId';
import fontFamilies, { FontFamiliesFallback } from "./fontFamilies";
import { getFontName } from '../AddGoogleFont/utils';
import * as actionTypes from './actionTypes';
import type { BrowserDimensions } from '../../../redux/modules/children/dimensions/index';
import styles from './FontFamily.css';
import { WINDOW_SCROLL_INTO_VIEW } from "../../../redux/middleware/window/actionTypes";

type PageFontDialogProps = {
    siteFonts: Array<string>,
    fontFamily: string,
    onChangeAction: string,
    dispatch: Dispatch,
    preserveGooglePrefix: boolean,
    forwardToSelectedComponent?: boolean
}

const
    width = 294,
    height = 364,
    getGoogleFontName = (fonts, preserveGooglePrefix) => {
        return preserveGooglePrefix ? fonts : fonts.map(font => font.replace(GOOGLE_PREFIX, ''));
    };

class PageFontsDialog extends React.Component<PageFontDialogProps> {
    componentDidMount() {
        this.props.dispatch({
            type: WINDOW_SCROLL_INTO_VIEW,
            payload: '#fontSelected'
        });
    }
    render() {
        const { forwardToSelectedComponent, fontFamily, siteFonts, onChangeAction, preserveGooglePrefix } = this.props,
            dispatch = forwardToSelectedComponent
                ? dispatchForwardToSelectedComponent(this.props.dispatch)
                : this.props.dispatch,
            selectedFontFamily = getFontName(fontFamily),
            fontList = uniq(fontFamilies.concat(getGoogleFontName(siteFonts, preserveGooglePrefix))).sort();

        return (
            <Dialog stripType={StripTypes.NONE} showCloseBtn={false}>
                <Scrollbar height="364px" fitHeight>
                    {
                        fontList.map((fontFamily, index) => {
                            const selected = fontFamily === selectedFontFamily;
                            return (
                                <div
                                    id={selected ? 'fontSelected' : undefined}
                                    className={cx(styles.fontPreview, { [styles.selected]: selected })}
                                    key={index}
                                    style={{ fontFamily: FontFamiliesFallback[fontFamily] || fontFamily }}
                                    onClick={() => dispatch({
                                        type: actionTypes.FONT_SELECTED,
                                        payload: { onChangeAction, fontFamily }
                                    })}
                                >
                                    {fontFamily}
                                </div>
                            );
                        })
                    }
                </Scrollbar>
            </Dialog>
        );
    }
}

export default {
    component: PageFontsDialog,
    confFactory: ({ browserWidth, browserHeight }: BrowserDimensions, { position }: any) => ({
        configurationDialogId,
        position: getPositionForDialogWithRespectToMouseClick(width, height, browserWidth, browserHeight, position),
        modal: false,
        dimensions: { width, height },
    }),
    dragEnabled: false,
    dependsOn: {
        siteFonts: siteFontsReducer
    }
};
