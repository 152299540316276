import React from 'react';
import { getBBoxDimensions } from '../../../utils/bBox';
import styles from './SelectionFrame.css';
import type { SelectionFrameDecorationState } from '../../../components/Workspace/epics/selectionFrameDecorationEval/flowTypes'; // eslint-disable-line max-len

type Props = {
    state: SelectionFrameDecorationState
}

const SelectionBox = ({ state: { bBox, isVisible } }: Props) => {
    if (!isVisible) {
        return <div />;
    }

    const { width, height } = getBBoxDimensions(bBox),

        style = {
            top: bBox.top,
            left: bBox.left,
            width,
            height
        };

    return <div style={style} className={styles.box} />;
};

export default SelectionBox;

