import React from 'react';
import styles from '../LinkChooserDialog.css';
import PreviewTree from '../../../../../../components/PagesTree/PreviewTree/index';
import getStyleIntValue from "../../../../../../utils/getStyleIntValue";
import type { PageTabPropTypes } from "../../flowTypes";
import { LC_PAGE_EXPAND, LC_PAGE_SELECT } from "../../actionTypes";

export default ({
    globalVariables,
    site,
    selection,
    currentResult,
    expandedIds,
    subscriptionData,
    dispatch,
}: PageTabPropTypes) => {
    const
        pageRefId = selection || (currentResult && currentResult.link.value),
        selectedPageRef = pageRefId && site.getOptionalPageRefById(pageRefId),
        selectedId = selectedPageRef && selectedPageRef.id;

    return (
        <div className={styles.pageTab}>
            <PreviewTree
                globalVariables={globalVariables}
                containerHeight={getStyleIntValue(styles, 'pageTabHeight')}
                site={site}
                subscriptionData={subscriptionData}
                selectedId={selectedId}
                onSelectAction={LC_PAGE_SELECT}
                expandedIds={expandedIds}
                expandAll
                onExpandAction={LC_PAGE_EXPAND}
                dispatch={dispatch}
            />
        </div>
    );
};
