import type { SubscriptionTypeT } from "../../../../../../wbtgen/src/components/App/epics/subscriptionData/flowTypes";
import { UpgradeCancelReason } from "../../../../../../wbtgen/src/components/EventTracking/constants";
import { getTrackingEventSource } from "../../../../../../wbtgen/src/components/TopBar/view/Upgrade/tracking/contants";

type UpgradeButtonClickedActionArgs = {
    feature: string;
    upgradeType: SubscriptionTypeT;
    freeUpgrade: boolean;
}

export const
    UPGRADE_DIALOG_OPENED_ACTION = "UPGRADE_DIALOG_OPENED_ACTION",
    UPGRADE_BUTTON_CLICKED_ACTION = "UPGRADE_BUTTON_CLICKED_ACTION",
    UPGRADE_CANCELED_ACTION = "UPGRADE_CANCELED_ACTION",
    FREE_UPGRADE_APPROVED_ACTION = "FREE_UPGRADE_APPROVED_ACTION",
    UPGRADE_COMPLETED_ACTION = "UPGRADE_COMPLETED_ACTION",
    PLANS_OVERVIEW_DIALOG_OPENED_ACTION = "PLANS_OVERVIEW_DIALOG_OPENED_ACTION";

export const
    upgradeDialogOpenedAction = ({ feature, dialogId }: { feature: string; dialogId: string; }) => ({
        type: UPGRADE_DIALOG_OPENED_ACTION,
        payload: { source: getTrackingEventSource(feature), dialogId }
    }),
    upgradeButtonClickedAction = ({ feature, upgradeType, freeUpgrade }: UpgradeButtonClickedActionArgs) => ({
        type: UPGRADE_BUTTON_CLICKED_ACTION,
        payload: { source: getTrackingEventSource(feature), upgradeType, freeUpgrade }
    }),
    upgradeCanceledAction = (cancelReason: UpgradeCancelReason) => ({
        type: UPGRADE_CANCELED_ACTION,
        payload: { cancelReason }
    }),
    freeUpgradeApprovedAction = () => ({ type: FREE_UPGRADE_APPROVED_ACTION }),
    upgradeCompletedAction = (
        params: {
            fromSubscriptionType: SubscriptionTypeT;
            toSubscriptionType: SubscriptionTypeT;
        }
    ) => ({
        type: UPGRADE_COMPLETED_ACTION,
        payload: params
    }),
    plansOverviewDialogOpenedAction = (source: string) => ({
        type: PLANS_OVERVIEW_DIALOG_OPENED_ACTION,
        payload: { source }
    });
