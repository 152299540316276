import { SECTION_PANEL_MOVE_SECTION_DOWN, SECTION_PANEL_MOVE_SECTION_UP } from '../actionTypes';
import type { ComponentsEvalEpicUpdater } from '../flowTypes';
import { componentAttachmentsVAT } from '../../componentAttachements/valueActionType';
import { receiveOnly } from "../../../../../epics/makeCondition";
import { getCmps, getAllAttachmentsForCmpIds } from "../../componentAttachements/util";
import { getPrevOrNextSectionInfo } from "../../../../oneweb/Section/utils";
import { WORKSPACE_SCROLL_AFTER_SECTION_MOVEMENT } from "../../../actionTypes";
import { componentsPanelActionsVAT } from "../../componentPanelActions/valueActionType";

const moveSectionUp = (belowSection, aboveSection, attachments, componentsMap) => {
    let changes = {};
    getAllAttachmentsForCmpIds(attachments, [belowSection.id]).concat(belowSection.id).forEach(id => {
        const cmp = componentsMap[id];
        changes[cmp.id] = cmp.top - aboveSection.height;
    });
    getAllAttachmentsForCmpIds(attachments, [aboveSection.id]).concat(aboveSection.id).forEach(id => {
        const cmp = componentsMap[id];
        changes[cmp.id] = cmp.top + belowSection.height;
    });
    return changes;
};

const getChanges = (componentsMap, selectedCmpId, up, attachments) => {
    let belowSectionInfo, belowSection, aboveSectionInfo, aboveSection;
    if (up) {
        belowSection = componentsMap[selectedCmpId];
        aboveSectionInfo = getPrevOrNextSectionInfo(selectedCmpId, componentsMap, false);
        aboveSection = aboveSectionInfo && aboveSectionInfo.section;
    } else {
        aboveSection = componentsMap[selectedCmpId];
        belowSectionInfo = getPrevOrNextSectionInfo(selectedCmpId, componentsMap, true);
        belowSection = belowSectionInfo && belowSectionInfo.section;
    }
    if (aboveSection && belowSection) {
        return moveSectionUp(belowSection, aboveSection, attachments, componentsMap);
    }
    return {};
};

const updateState = (epicState, selectedComponent, up, action, attachments) => {
    const
        { scope, state, state: { componentsMap } } = epicState,
        selectedCmpId = selectedComponent.id,
        changesInTops = getChanges(componentsMap, selectedCmpId, up, attachments),
        newCmpsMap = getCmps(componentsMap).reduce((acc, cmp) => {
            acc[cmp.id] = cmp;
            if (changesInTops.hasOwnProperty(cmp.id)) {
                acc[cmp.id] = { ...cmp, top: changesInTops[cmp.id] };
            }
            return acc;
        }, {}),
        movedSectionCmp = newCmpsMap[selectedCmpId];

    return {
        state: {
            state: {
                ...state,
                componentsMap: newCmpsMap
            },
            scope
        },
        actionToDispatch: {
            type: WORKSPACE_SCROLL_AFTER_SECTION_MOVEMENT,
            payload: {
                top: movedSectionCmp.top,
                height: movedSectionCmp.height
            }
        },
        updateReason: action
    };
};

const moveSectionUpUpdater: ComponentsEvalEpicUpdater = {
    conditions: [
        receiveOnly(componentAttachmentsVAT),
        receiveOnly(componentsPanelActionsVAT),
        SECTION_PANEL_MOVE_SECTION_UP
    ],
    reducer: ({ values: [{ attachments }, { component }], state: epicState }) => {
        return updateState(epicState, component, true, SECTION_PANEL_MOVE_SECTION_UP, attachments);
    }
};
const moveSectionDownUpdater = {
    conditions: [
        receiveOnly(componentAttachmentsVAT),
        receiveOnly(componentsPanelActionsVAT),
        SECTION_PANEL_MOVE_SECTION_DOWN
    ],
    // $FlowFixMe
    reducer: ({ values: [{ attachments }, { component }], state: epicState }) => {
        return updateState(epicState, component, false, SECTION_PANEL_MOVE_SECTION_DOWN, attachments);
    }
};

export const
    onSectionMovedUpdaters = [
        moveSectionUpUpdater,
        moveSectionDownUpdater
    ];
