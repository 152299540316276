import { GET_COOKIE, SET_COOKIE } from "./actionTypes";

type Payload = {
    callerId: string,
    cookieKey: string
};

const getCookieAction = (callerId: string, cookieKey: string): Action<Payload> => ({
    type: GET_COOKIE,
    payload: { callerId, cookieKey }
});

const setCookieAction =
    (key: string, value: string, options ?: Record<string, any>) =>
        ({ type: SET_COOKIE, payload: { key, value, options } });

export {
    getCookieAction,
    setCookieAction
};
