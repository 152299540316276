import {
    GENERAL_INFO_LOGO_ALT_TEXT_INPUT_CHANGED,
    GENERAL_INFO_LOGO_CHOOSEN
} from "../../../../SiteSettings/actions";

import type { ResourceMetadata } from "../../../../../../../../wbtgen/src/redux/modules/children/fileChooser/flowTypes";

export const
    setLogoAltTextAction = (value: string): Action => ({
        type: GENERAL_INFO_LOGO_ALT_TEXT_INPUT_CHANGED,
        payload: value
    }),
    setLogoAssetAction = (value: ResourceMetadata): Action => ({
        type: GENERAL_INFO_LOGO_CHOOSEN,
        payload: value
    });
