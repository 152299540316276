import makeSpec from '../makeSpec/index';
import * as path from '../path';
import * as link from './cssConfig';
import { getGlobalstyleHoverClassName } from '../../components/RenderGlobalstyles/getGlobalstyleClassName';

const
    makeLinkSpec = makeSpec(link.cssConfig),
    linkSpec = (additionalPath: Path = [], cssPrefix: string = '') => ({
        [cssPrefix]: makeLinkSpec(additionalPath, path.inactive),
        [getGlobalstyleHoverClassName(cssPrefix)]: makeLinkSpec(additionalPath, path.hover),
        [`${cssPrefix}:hover`]: makeLinkSpec(additionalPath, path.hover),
        [`${cssPrefix}-hover`]: makeLinkSpec(additionalPath, path.hover)
    });

export {
    linkSpec
};
