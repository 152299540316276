import { getAppConfig } from "../../../../wbtgen/src/components/App/epics/appConfig/appConfig";
import AppConfig from "../../../../wbtgen/src/utils/AppConfig";
import { AppState } from "../common/types";
import { getDAL } from "../../../../wbtgen/dal/index";

export const EnableDashboardAnalyticsKey = "enable-dashboard-analytics";

function isStatsEnabledFromLocalStorage() {
    // custom `localStorage` instance is not used since this function
    // is called before DAL initialization.
    return (
        window.localStorage &&
        window.localStorage.getItem(EnableDashboardAnalyticsKey) === 'yes'
    );
}

export function isAnalyticsFeatureEnabled() {
    return (
        isStatsEnabledFromLocalStorage() || isFlagMatch('oneWeb.kliken.enabled', true)
    );
}

export function isFlagMatch(flag: string, value?: any, strict?: boolean) {
    const appConfig = AppConfig(getAppConfig());
    const flagConfig = appConfig.getOptional(flag);

    if (strict || typeof value !== "boolean") {
        return flagConfig === value;
    }

    return Boolean(flagConfig) === Boolean(value);
}

export const isBookingsEnabledFromConfig = (appState?: AppState) => {
    const appConfig = AppConfig(getAppConfig());
    const bookingsConfig = appConfig.getOptional('oneWeb.bookings');
    const isBookingsEnabled = bookingsConfig && bookingsConfig.getOptional('enabled');
    const bookingsUrl = bookingsConfig.getOptional('url');
    const bookingsSubscriptionTypes = bookingsConfig.getOptional('allowedsubscriptionTypes', []);
    const currentSubscriptionType = appState?.subscriptionData?.subscriptionType || '';

    let result = (
        isBookingsEnabled &&
        bookingsUrl &&
        bookingsSubscriptionTypes.includes(currentSubscriptionType)
    );

    // check locale ids
    // if undefined or empty then allow all
    const supportedLocaleIds = bookingsConfig.getOptional('supportedLocaleIds', []);
    if (supportedLocaleIds.length > 0) {
        const currentLocale = appState?.topBar?.languages?.current || '';
        result = result && supportedLocaleIds.includes(currentLocale);
    }

    // check if domain whitelisting is enabled and if the current domain is whitelisted
    const whiteListedDomains = bookingsConfig.getOptional('whitelistedDomains', []);
    if (whiteListedDomains.length > 0) {
        const domain = getDAL().getDomain();
        result = result && whiteListedDomains.includes(domain);
    }

    return result;
};
