import React from 'react';
import { WidgetWorkspaceView } from '../../view/workspace';
import { SpotifyView } from './index';

const SpotifyWorkspaceView = props => (
    <WidgetWorkspaceView {...props}>
        <SpotifyView {...props} />
    </WidgetWorkspaceView>
);

export { SpotifyWorkspaceView };

