import { TwitterTimelineSDK } from "./constants";
import { WidgetsTwitterKind } from './kind';
import { calcRenderProps } from './calcRenderProps';
import { TwitterView } from './view/index';

// See wbtgen/src/view/oneweb/registry/preview.js
const WidgetsTwitterPreviewConfig = {
    kind: WidgetsTwitterKind,
    view: TwitterView,
    calcProps: ({ component }) => calcRenderProps({
        component,
        componentExtension: { isTransient: false },
    }),
    js: ['/renderStatic/widgets/twitterTimeline.js'],
    generateScripts: () => [{
        src: TwitterTimelineSDK,
        async: true,
        defer: true,
        charset: 'utf-8'
    }]
};

export { WidgetsTwitterPreviewConfig };
