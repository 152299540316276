import React from "react";
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';
import cx from "classnames";

const useStyles = makeStyles(
    (theme) => ({
        container: {
            background: theme.palette.custom.colorWhite,
            padding: "30px"
        },
        section: {
            border: `1px solid ${theme.palette.custom.colorGray_ed}`,
            padding: '34px'
        },
        loader: {
            borderRadius: '4px'
        },
    }),
    { name: "IframeLoader" }
);

type PropsType = {
    containerClasses?: string;
};

const StatsItemLoader = () => {
    const classes = useStyles();

    return (
        <Box>
            <Skeleton variant="rectangular" width={110} height={50} className={classes.loader} />
            <Box mt={2}>
                <Skeleton variant="text" height={20} className={classes.loader} />
                <Skeleton variant="text" height={20} className={classes.loader} />
            </Box>
        </Box>
    );
};

const StatsSectionLoader = () => {
    const classes = useStyles();

    return (
        <Box>
            <Skeleton variant="rectangular" width={200} height={40} className={classes.loader} />
            <Box mt={2} display="flex" justifyContent="space-between" className={classes.section}>
                <StatsItemLoader />
                <StatsItemLoader />
                <StatsItemLoader />
                <StatsItemLoader />
            </Box>
        </Box>
    );
};

export const IframeLoader = (props: PropsType) => {
    const classes = useStyles();

    return (
        <Box className={cx(classes.container, props.containerClasses)}>
            <StatsSectionLoader />
            <Box height={40} />
            <StatsSectionLoader />
            <Box height={40} />
            <StatsSectionLoader />
            <Box height={40} />
            <StatsSectionLoader />
        </Box>
    );
};
