import React from "react";
import * as styles from './preview.css';
import { TooltipCom } from "../../../presentational/Tooltip/TooltipCom";
import { TooltipPosition } from "../../../presentational/Tooltip/constants";
import VerticalSpacer from "../../../../view/common/VerticalSpacer";
import { Intl } from "../../../../view/intl/index";
import { MessengerChatWidgetSeenOnPreviewKey } from "../constants/index";

type ChatPreviewViewProps = {
    dispatch?: Dispatch,
    intl: Intl,
    chatWidgetColor: string,
    isMobilePreview?: boolean
}

type ChatPreviewViewState = {
    show: boolean
}

class ChatWidgetPreviewView extends React.Component<ChatPreviewViewProps, ChatPreviewViewState> {
    ref: React.RefObject<HTMLDivElement>;
    targetRef: React.RefObject<HTMLDivElement>;

    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.targetRef = React.createRef();
        this.state = {
            show: false
        };
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
        if (window.sessionStorage.getItem(MessengerChatWidgetSeenOnPreviewKey) !== 'true') {
            window.sessionStorage.setItem(MessengerChatWidgetSeenOnPreviewKey, 'true');
            this.setState({ show: true });
        }
    }

    handleClickOutside = (event: MouseEvent) => {
        // @ts-ignore
        if (this.targetRef && this.targetRef.current && !this.targetRef.current.contains(event.target)) {
            this.toolTipClose(event as any as React.MouseEvent<HTMLElement>);
        }
    };

    toolTipOpen = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        this.setState({ show: true });
    };

    toolTipClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        this.setState({ show: false });
    };

    render() {
        let extraStyles = {};
        const
            { show } = this.state,
            { intl, chatWidgetColor, isMobilePreview } = this.props,
            tooltipProps = {
                theme: {
                    // eslint-disable-next-line max-len
                    container: styles.chatWidgetPreviewTipContainer,
                    pointer: styles.chatWidgetPreviewTipPointer,
                    tipInfo: styles.chatWidgetPreviewTipInfo
                },
                isSmallTipInfo: false,
                show,
                targetRef: this.ref,
                distance: 5,
                position: TooltipPosition.RIGHT,
            },
            toolTipPreviewHeader = "msg: common.chatWidget.label {Messenger chat}",
            // eslint-disable-next-line max-len
            toolTipPreviewMsg = "msg: chatWidget.chat.preview.toolTip.msg {To see Messenger chat in action check it out on your published site.}";
        if (isMobilePreview) {
            extraStyles = { right: 35, bottom: 100 };
        }

        return (
            <React.Fragment>
                <div
                    className={styles.chatWidgetPreviewContainer}
                    onClick={this.toolTipOpen}
                    ref={this.ref}
                    style={{
                        backgroundColor: chatWidgetColor,
                        ...extraStyles
                    }}
                />
                <TooltipCom {...tooltipProps} onClick={(event) => { event.stopPropagation(); }}>
                    <div className={styles.chatWidgetPreviewCrossIcon} onClick={this.toolTipClose} />
                    <div className={styles.chatWidgetPreviewHeader}>
                        {intl.msgJoint(toolTipPreviewHeader)}
                    </div>
                    <VerticalSpacer y={6} />
                    <div className={styles.chatWidgetPreviewMsg}>
                        {intl.msgJoint(toolTipPreviewMsg)}
                    </div>

                </TooltipCom>
            </React.Fragment>
        );
    }
}

export const ChatWidgetPreview = ChatWidgetPreviewView;
