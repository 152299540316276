import {
    makeCombineReducer,
    makeDefaultStateReducers,
    makeComponentBaseReducers
} from "../../../../../redux/makeReducer/index";
import { WEBSHOP_FOOTER_CMP_COLOR_CHANGED, WEBSHOP_FOOTER_CMP_COLOR_CHANGED_AUTO_COLOR } from "../../actionTypes";
import kind from '../kind';
import webShopFooterCmpColorReducer from "./webShopFooterCmpColorReducer";
import webShopFooterCmpThemeColorReducer from "./webShopFooterCmpThemeColorReducer";

export default makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(kind),
        ...makeDefaultStateReducers({
            width: 300,
            height: 40,
            mobileHide: false,
            mobileDown: false,
        })
    },
    handleActions: {
        [WEBSHOP_FOOTER_CMP_COLOR_CHANGED_AUTO_COLOR]: webShopFooterCmpThemeColorReducer,
        [WEBSHOP_FOOTER_CMP_COLOR_CHANGED]: webShopFooterCmpColorReducer
    }
});
