/* eslint-disable max-len */
import React from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import { Intl } from "../../../../view/intl/index";
import { DEFAULT } from "../../../../constants/app";
import type { selectedColorPickerPaletteType } from "../../flowTypes";
import type { Color } from "../../../../mappers/flowTypes";
import styles from "./OnboardingTheming.css";
import { CurrentThemeColors } from "../../../AutoColorLeftPanel/screens/Components/CurrentThemeColors";
import { isNextGenPreviewSelector } from "../../../TemplateSelector_DEPRECATED/epics/preview/selector";

type OnboardingThemingHeaderProps = {
    selectedColorPickerTheme: string;
    changeColorPickerThemeMethodActionType: string;
    selectedColorPickerPalette: selectedColorPickerPaletteType;
    intl: Intl;
};
export const OnboardingThemingHeader = (props: OnboardingThemingHeaderProps) => {
    const isNextGenPreview = useSelector(isNextGenPreviewSelector);
    const
        {
            selectedColorPickerTheme,
            selectedColorPickerPalette,
            changeColorPickerThemeMethodActionType,
            intl,
        } = props,
        isDefaultTheme = selectedColorPickerTheme === DEFAULT,
        onboardingDefaultThemeColorPickerLabel = "msg: onboarding.theming.colorpicker.default.header.label {Pick your colors}",
        onboardingDefaultThemeColorPickerMsg =
            "msg: onboarding.theming.colorpicker.default.header.msg {Edit the colour theme for this template or select from a number of predefined themes.}",
        onboardingCustomThemeColorPickerLabel = "msg: onboarding.theming.colorpicker.custom.header.label {Edit colour theme}",
        onboardingCustomThemeColorPickerMsg =
            "msg: onboarding.theming.colorpicker.custom.header.msg {Set a base colour to use for elements such as links and buttons, then select a colour theme.}",
        dynamicOnboardingThemeColorPickerMsg = "msg: onboarding.theming.colorpicker.dynamic.header.msg {Colour recommendations, based on your category and design.}";
    const onboardingThemingHeaderLabel = isDefaultTheme
            ? onboardingDefaultThemeColorPickerLabel
            : onboardingCustomThemeColorPickerLabel,
        themeColors: Array<Color> = selectedColorPickerPalette.palette as any;

    let onboardingThemingHeaderMsg;
    if (isNextGenPreview) {
        onboardingThemingHeaderMsg = dynamicOnboardingThemeColorPickerMsg;
    } else if (isDefaultTheme) {
        onboardingThemingHeaderMsg = onboardingDefaultThemeColorPickerMsg;
    } else {
        onboardingThemingHeaderMsg = onboardingCustomThemeColorPickerMsg;
    }

    return (
        <div>
            <div className={cx(styles.onboardingThemingHeaderLabel, isNextGenPreview && styles.onboardingThemingHeaderLabelLarge)}>
                {intl.msgJoint(onboardingThemingHeaderLabel)}
            </div>
            <div className={cx(styles.onboardingThemingHeaderMsg, isNextGenPreview && styles.onboardingThemingHeaderMsgLarge)}>
                {intl.msgJoint(onboardingThemingHeaderMsg)}
            </div>
            {
                isDefaultTheme && selectedColorPickerPalette && selectedColorPickerPalette.palette && !isNextGenPreview ?
                    (
                        <CurrentThemeColors
                            themeColors={themeColors}
                            turnOnEditModeActionType={changeColorPickerThemeMethodActionType}
                        />
                    ) :
                    null
            }
        </div>
    );
};
