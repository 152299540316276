import * as R from 'ramda';
import makeEpic from '../../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import WORKSPACE_COMPONENT_KIND from "../../../../Workspace/epics/componentsEval/WORKSPACE_COMPONENT_KIND";
import { PAGE_DATA_LOAD_REQUEST } from "../../../../PagesTree/actionTypes";
import {
    TEXT_CHANGED,
    TEXT_COMPONENT_BLUR_RADIUS_CHANGED, TEXT_COMPONENT_CHARACTER_SPACING_CHANGED, TEXT_COMPONENT_GLOBAL_STYLE_SELECTED,
    TEXT_COMPONENT_LINE_SPACING_CHANGED, TEXT_COMPONENT_LINK_GLOBAL_STYLE_SELECTED,
    TEXT_COMPONENT_SHADOWOFFSETX_CHANGED,
    TEXT_COMPONENT_SHADOWOFFSETY_CHANGED,
    TEXT_FONT_FAMILY_CHANGED,
    TEXT_HANDLE_EDITOR_CHANGE,
    TEXT_HIGHLIGHT_COLOR_CHANGED,
    TEXT_HIGHLIGHT_COLOR_REMOVED,
    TEXT_INDENT_BTN_CLICKED,
    TEXT_MCTA_ALIGN_MOUSE_ENTER,
    TEXT_MCTA_ALIGN_MOUSE_LEAVE,
    TEXT_MCTA_BOLD_BTN_CLICKED,
    TEXT_MCTA_COLOR_CHANGED, TEXT_MCTA_FONT_SIZE_CHANGED,
    TEXT_MCTA_HORIZONTAL_ALIGN_BTN_CLICKED,
    TEXT_MCTA_ITALIC_BTN_CLICKED,
    TEXT_MCTA_ORDERED_LIST_BTN_CLICKED, TEXT_MCTA_REMOVE_FORMAT_BTN_CLICKED,
    TEXT_MCTA_SUBSCRIPT_BTN_CLICKED,
    TEXT_MCTA_SUPERSCRIPT_BTN_CLICKED,
    TEXT_MCTA_UNDERLINE_BTN_CLICKED,
    TEXT_MCTA_UNORDERED_LIST_BTN_CLICKED,
    TEXT_OUTDENT_BTN_CLICKED, TEXT_PARAGRAPH_SPACING_CHANGED,
    TEXT_REMOVE_LINK,
    TEXT_SET_LINK,
    TEXT_SHADOW_COLOR_CHANGED,
    TEXT_SHADOW_COLOR_REMOVED
} from "../../actionTypes";
import {
    ReceiveOnlySelectedComponentIdSelector, ReceiveOnlyComponentsMap,
} from "../../../../Workspace/epics/componentsEval/selectorActionTypes";
import { makeTinyMceForwardUpdaters } from "../../../../App/epics/tinyMceEpic/makeTinyMceForwardUpdaters";
import { areHtmlStringsEquivalent } from '../../../../../utils/string';
import { receiveOnly } from '../../../../../epics/makeCondition';
import { tinyMcePickLinkedFieldEpic } from '../../../../App/epics/tinyMceEpic/tinyMcePickLinkedFieldEpic';

const
    defaultState = { expanded: false },
    UNUSED = '@@UNUSED',
    setExpanded = R.assoc('expanded');

export const selectedTextStateEpic = makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [PAGE_DATA_LOAD_REQUEST],
            reducer: () => ({ state: defaultState })
        },
        {
            conditions: [
                TEXT_HANDLE_EDITOR_CHANGE,
                ReceiveOnlySelectedComponentIdSelector,
                ReceiveOnlyComponentsMap,
                receiveOnly(tinyMcePickLinkedFieldEpic.valueActionType, state => !!state.pickLinkedFieldCtxMenu)
            ],
            reducer: ({
                values: [payload, selectedComponentId, componentsMap, pickLinkedFieldCtxMenuOpened],
                state
            }) => {
                if (pickLinkedFieldCtxMenuOpened) return { state };
                let {
                    componentId
                } = payload;

                if (!componentId) {
                    // This case will happen when tinyMce epic notify about content change after actions like set bold
                    componentId = selectedComponentId;
                }

                if (!componentId) {
                    throw new Error('componentId should be defined to apply text changes');
                }

                if (
                    // at times, this action is received after the component is deleted
                    // or the componentsMap is updated, due to page change
                    !componentsMap[componentId]
                    || areHtmlStringsEquivalent(componentsMap[componentId].content, payload.content)
                ) {
                    return { state };
                }

                const textChangedAction: AnyAction = {
                    amendToSelf: true,
                    type: TEXT_CHANGED,
                    payload: {
                        content: payload.content
                    },
                    forwardTo: {
                        kind: WORKSPACE_COMPONENT_KIND,
                        id: componentId
                    }
                };

                return {
                    state,
                    actionToDispatch: textChangedAction
                };
            }
        },
        {
            conditions: [TEXT_MCTA_ALIGN_MOUSE_ENTER],
            reducer: ({ state }) => ({
                state: setExpanded(true, state)
            })
        },
        {
            conditions: [TEXT_MCTA_ALIGN_MOUSE_LEAVE],
            reducer: ({ state }) => ({
                state: setExpanded(false, state)
            })
        },
        ...makeTinyMceForwardUpdaters({
            setColor: TEXT_MCTA_COLOR_CHANGED,
            setShadowColor: TEXT_SHADOW_COLOR_CHANGED,
            setShadowBlurRadius: TEXT_COMPONENT_BLUR_RADIUS_CHANGED,
            setShadowOffsetX: TEXT_COMPONENT_SHADOWOFFSETX_CHANGED,
            setShadowOffsetY: TEXT_COMPONENT_SHADOWOFFSETY_CHANGED,
            removeShadow: TEXT_SHADOW_COLOR_REMOVED,
            setLinkInContentBlock: TEXT_SET_LINK,
            setLinkAcrossContentBlocks: UNUSED,
            removeLink: TEXT_REMOVE_LINK,
            toggleBold: TEXT_MCTA_BOLD_BTN_CLICKED,
            toggleItalic: TEXT_MCTA_ITALIC_BTN_CLICKED,
            toggleUnderline: TEXT_MCTA_UNDERLINE_BTN_CLICKED,
            setHighlightColor: TEXT_HIGHLIGHT_COLOR_CHANGED,
            removeHighlightColor: TEXT_HIGHLIGHT_COLOR_REMOVED,
            setHorizontalAlignment: TEXT_MCTA_HORIZONTAL_ALIGN_BTN_CLICKED,
            setFontFamily: TEXT_FONT_FAMILY_CHANGED,
            setOrderedList: TEXT_MCTA_ORDERED_LIST_BTN_CLICKED,
            setUnorderedList: TEXT_MCTA_UNORDERED_LIST_BTN_CLICKED,
            indent: TEXT_INDENT_BTN_CLICKED,
            outdent: TEXT_OUTDENT_BTN_CLICKED,
            removeGlobalStylesAndFormats: UNUSED,
            setSubscript: TEXT_MCTA_SUBSCRIPT_BTN_CLICKED,
            setSuperscript: TEXT_MCTA_SUPERSCRIPT_BTN_CLICKED,
            setGlobalStyle: TEXT_COMPONENT_GLOBAL_STYLE_SELECTED,
            setLinkGlobalStyle: TEXT_COMPONENT_LINK_GLOBAL_STYLE_SELECTED,
            setCharacterSpacing: TEXT_COMPONENT_CHARACTER_SPACING_CHANGED,
            setLineSpacing: TEXT_COMPONENT_LINE_SPACING_CHANGED,
            setFontSize: TEXT_MCTA_FONT_SIZE_CHANGED,
            removeFormat: TEXT_MCTA_REMOVE_FORMAT_BTN_CLICKED,
            setParagraphSpacing: TEXT_PARAGRAPH_SPACING_CHANGED
        })
    ]
});
