export const
    DEFAULT_WIDTH = 500,
    DEFAULT_HEIGHT = 715,
    VIDEO_DEFAULT_WIDTH = 600,
    VIDEO_DEFAULT_HEIGHT = 400,
    EmbedTypes = ['facebook', 'profile'],
    defaultState = {
        link: "",
        postType: "",
        mobileHide: false,
    },
    DATA_TEST_ID = 'facebook-widget';

export enum PostType {
    VIDEO = 'video',
    POST = 'post',
}

export enum Keyword {
    VIDEOS = 'videos',
    WATCH = 'watch',
    POSTS = 'posts',
    PHOTOS = 'photos',
}
