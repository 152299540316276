import type {
    ThemeColorPickerKindType,
    ThemeBackgroundOverrideColorPickerKindType,
    ThemeColorOverrideColorPickerKindType,
    DefaultColorPickerKindType,
    InvalidThemeColorType,
} from './flowTypes';

export const INVALID_THEME_COLOR: InvalidThemeColorType = 'INVALID_THEME_COLOR';

export const THEME_COLOR_PICKER: ThemeColorPickerKindType = 'THEME_COLOR_PICKER';
export const THEME_BG_OVERRIDE_COLOR_PICKER: ThemeBackgroundOverrideColorPickerKindType = 'THEME_BG_OVERRIDE_COLOR_PICKER';
export const THEME_COLOR_OVERRIDE_COLOR_PICKER: ThemeColorOverrideColorPickerKindType = 'THEME_COLOR_OVERRIDE_COLOR_PICKER';
export const DEFAULT_COLOR_PICKER: DefaultColorPickerKindType = 'DEFAULT_COLOR_PICKER';
