/* eslint-disable max-len */

import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { makeEpicStateSelector } from '../../../epics/makeEpic';
import { WRAP_POSITION_CONTROLS_VAT } from './epic/valueActionType';
import ButtonFactory from '../../presentational/Button/factory';
import styles from './wrapPositionControls.css';
import * as workspaceActionTypes from '../actionTypes';
import { injectIntl } from '../../../view/intl/index';
import NoMouseEventsPropagationContainer from '../../../view/Workspace/Decorations/ComponentMainActions/noMouseEventsPropagationContainer';

import type { WpcButtonType, WrapPositionControlsProps } from './flowTypes';
import type { FactoryProps } from '../../presentational/Button/flowTypes';

const wpcEpicStateSelector = makeEpicStateSelector(WRAP_POSITION_CONTROLS_VAT),
    mapStateToProps = (appState) => ({ state: wpcEpicStateSelector(appState) });

export const WrapPositionControls =
    connect(mapStateToProps)(injectIntl(({ state, dispatch, intl }: WrapPositionControlsProps) => {
        const btnStyles = {
                button: styles.wrapPositionButton, disabled: styles.wrapPositionButtonDisabled, error: '', selected: '',
            },
            buttonFactoryConfig: FactoryProps = { btnStyles, containerType: 'span', containerExtraPropNames: [] },
            WpcButton = ButtonFactory({ ...buttonFactoryConfig, additionalClassName: styles.wrapPositionButton }),
            getConfigForHorizontalWrapButtons = (wrapDirection) => {
                const oppositeWrapDirection = wrapDirection === 'left' ? 'right' : 'left',
                    isComponentWrappedOnOppositeDirection = !state.wrapOptions[oppositeWrapDirection]; // edge of wrapDirection is two hops away
                if (isComponentWrappedOnOppositeDirection) {
                    return {
                        ActionType: workspaceActionTypes.SELECTED_COMPONENT_WRAP_TO_CENTER,
                        contentClass: wrapDirection === 'left' ? styles.arrowLeft : styles.arrowRight,
                        title: intl.msgJoint('msg: contextmenu.item.wrapToCenter.Title {Wrap center}'),
                    };
                } else if (wrapDirection === 'left') {
                    return {
                        ActionType: workspaceActionTypes.SELECTED_COMPONENT_WRAP_TOP_LEFT,
                        contentClass: wrapDirection === 'left' ? styles.arrowLeft : styles.arrowRight,
                        title: intl.msgJoint('msg: contextmenu.item.wrapTopLeft.Title {Wrap left}'),
                    };
                } else {
                    return {
                        ActionType: workspaceActionTypes.SELECTED_COMPONENT_WRAP_TOP_RIGHT,
                        contentClass: wrapDirection === 'left' ? styles.arrowLeft : styles.arrowRight,
                        title: intl.msgJoint('msg: contextmenu.item.wrapTopRight.Title {Wrap right}'),
                    };
                }
            },
            wrapDirectionToConfigMap: Record<WpcButtonType, any> = {
                above: {
                    ActionType: workspaceActionTypes.SELECTED_COMPONENT_WRAP_ABOVE,
                    contentClass: styles.arrowUp,
                    title: intl.msgJoint('msg: contextMenu.item.wrapUp.Title {Wrap above}'),
                },
                below: {
                    ActionType: workspaceActionTypes.SELECTED_COMPONENT_WRAP_BELOW,
                    contentClass: styles.arrowDown,
                    title: intl.msgJoint('msg: contextMenu.item.wrapDown.Title {Wrap below}'),
                },
                left: getConfigForHorizontalWrapButtons('left'),
                right: getConfigForHorizontalWrapButtons('right'),
            },
            buttonDirections: WpcButtonType[] = ['above', 'below', 'left', 'right'],
            makeOnClick = (wrapDirection: WpcButtonType) => () => {
                if (state.wrapOptions[wrapDirection]) {
                    dispatch({ type: wrapDirectionToConfigMap[wrapDirection].ActionType });
                }
            },
            getButtonWrapperStyleFromPosition = (p) => ({ position: 'absolute', top: `${p.y}px`, left: `${p.x}px` }),
            makeButton = (wrapDirection: WpcButtonType) => (
                <div
                    key={wrapDirection}
                    // @ts-ignore
                    style={getButtonWrapperStyleFromPosition(state.wpcButtonPositions[wrapDirection])}
                >
                    <WpcButton
                        disabled={!state.wrapOptions[wrapDirection]}
                        onClick={makeOnClick(wrapDirection)}
                        title={wrapDirectionToConfigMap[wrapDirection].title}
                    >
                        <div className={cx(styles.arrow, wrapDirectionToConfigMap[wrapDirection].contentClass)} />
                    </WpcButton>
                </div>
            );

        return !state.isVisible ? <div /> : (
            <NoMouseEventsPropagationContainer>
                <div className={styles.wpcWrapper}>
                    {buttonDirections.map(makeButton)}
                </div>
            </NoMouseEventsPropagationContainer>
        );
    }));
