import { combineReducers } from 'redux';
import { demoLoginReducer, demoLoginDefaultState } from '../login/demoLoginReducer';
import { makeInitialStateReducer } from '../../../src/redux/makeReducer/makeInitialStateReducer';
import { Lit } from '../../../src/lit';
import type { DemoBarState, DemoState } from '../types';
import {
    COLLAPSE_DEMO_BAR_ACTION,
    EXPAND_DEMO_BAR_ACTION,
    HIDE_DEMO_BAR_ACTION,
    SET_TRIAL_REMAINING_DAYS_ACTION,
    SET_TRIAL_USER_HASHED_TOKEN_ACTION,
    SET_TRIAL_USER_SUB_ID_ACTION,
    SET_TRIAL_CONTROL_PANEL_USER_ACTION,
    TOGGLE_DEMO_BAR_ACTION,
    SET_TRIAL_USER_EMAIL_ACTION,
    SET_TRIAL_USER_EMAIL_VERIFIED_STATUS_ACTION,
    SET_TRIAL_DOMAIN_AVAILABLE_ACTION,
    CLEAR_AVAILABLE_TRIAL_DOMAIN_ACTION,
    SET_TRIAL_DOMAIN_NAME_ACTION,
} from '../actions';
import { DemoBarStatus } from '../bottomBar/constants';
import { START_QUICK_TOUR, STOP_QUICK_TOUR } from '../../../src/components/QuickTour/actionTypes';
import AppConfig from "../../../src/utils/AppConfig";
import { getAppConfig } from "../../../src/components/App/epics/appConfig/appConfig";
import {
    TrialImportAvailabilityDefaultState,
    trialImportAvailabilityReducer
} from "../import/reducer/trialImportAvailabilityReducer";

const
    appConfig = AppConfig(getAppConfig()),
    trialValidDuration = appConfig.getOptional('oneWeb.trial.awsConfig.serverless.trialValidDuration');

export const DemoLoginInitialState = {
    [Lit.login]: demoLoginDefaultState
};

export const TrialImportAvailabilityInitialState = {
    [Lit.trialImportAvailabilityData]: TrialImportAvailabilityDefaultState
};

export const DemoInitialState: DemoState = {
    [Lit.bottomBar]: {
        [Lit.status]: DemoBarStatus.EXPANDED,
        [Lit.beforeTourStatus]: null,
        [Lit.trialDaysRemaining]: parseInt(trialValidDuration, 10),
        [Lit.trialDomainNameForPurchase]: null,
    },
    ...DemoLoginInitialState,
    [Lit.trialImportAvailability]: {
        ...TrialImportAvailabilityInitialState
    }
};

export const demoReducer = combineReducers({
    [Lit.bottomBar]: makeInitialStateReducer(
        DemoInitialState[Lit.bottomBar],
        // @ts-ignore
        (state: DemoBarState, action: Action) => {
            switch (action.type) {
                case TOGGLE_DEMO_BAR_ACTION:
                    return {
                        ...state,
                        status: state.status === DemoBarStatus.EXPANDED
                            ? DemoBarStatus.COLLAPSED
                            : DemoBarStatus.EXPANDED,
                    };

                case EXPAND_DEMO_BAR_ACTION:
                    return { ...state, status: DemoBarStatus.EXPANDED };

                case COLLAPSE_DEMO_BAR_ACTION:
                    return { ...state, status: DemoBarStatus.COLLAPSED };

                case START_QUICK_TOUR:
                    return {
                        ...state,
                        status: DemoBarStatus.COLLAPSED,
                        [Lit.beforeTourStatus]: state[Lit.status],
                    };

                case STOP_QUICK_TOUR:
                    return {
                        ...state,
                        status: state[Lit.beforeTourStatus],
                        [Lit.beforeTourStatus]: null,
                    };

                case HIDE_DEMO_BAR_ACTION:
                    return { ...state, status: DemoBarStatus.HIDDEN };

                case SET_TRIAL_REMAINING_DAYS_ACTION:
                    return { ...state, trialDaysRemaining: action.payload.trialDaysRemaining };

                case SET_TRIAL_USER_SUB_ID_ACTION:
                    return { ...state, trialUserSubId: action.payload.userSubId };

                case SET_TRIAL_USER_EMAIL_ACTION:
                    return { ...state, email: action.payload.trialUserEmail };

                case SET_TRIAL_CONTROL_PANEL_USER_ACTION:
                    return { ...state, controlPanelUser: action.payload.controlPanelUser };

                case SET_TRIAL_DOMAIN_NAME_ACTION:
                    return { ...state, domain: action.payload.trialDomainName };

                case SET_TRIAL_USER_HASHED_TOKEN_ACTION:
                    return { ...state, trialUserToken: action.payload.userHashedToken };

                case SET_TRIAL_USER_EMAIL_VERIFIED_STATUS_ACTION:
                    return { ...state, trialUserEmailVerified: action.payload.trialUserEmailVerified };

                case SET_TRIAL_DOMAIN_AVAILABLE_ACTION:
                    return {
                        ...state,
                        [Lit.trialDomainNameForPurchase]: action.payload.trialDomainNameForPurchase
                    };

                case CLEAR_AVAILABLE_TRIAL_DOMAIN_ACTION:
                    return {
                        ...state,
                        [Lit.trialDomainNameForPurchase]: null,
                    };

                default:
                    return state;
            }
        },
    ),
    [Lit.login]: makeInitialStateReducer(demoLoginDefaultState, demoLoginReducer),
    [Lit.trialImportAvailability]: makeInitialStateReducer(
        {
            [Lit.trialImportAvailabilityData]: TrialImportAvailabilityDefaultState,
        },
        trialImportAvailabilityReducer
    )
});
