import makePages from '../../../PropertiesPanel/view/makePages';
import * as main from './pages/main';
import * as style from './pages/style';
import * as promo from './pages/promotion';
import * as navigation from './pages/navigation';
import * as buttons from './pages/buttons';
import * as Pages from './pages/ids';
import addGoogleFont from "../../../PropertiesPanel/view/AddGoogleFont/index";

export default {
    pages: makePages(main, style, buttons, navigation, promo, addGoogleFont),
    defaultPageId: Pages.MAIN
};
