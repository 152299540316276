/* eslint-disable max-len */
import React from 'react';
import cx from "classnames";

import { ErrorMessage } from './ErrorMessage';

import styles from "./styles.css";

const DailyLimitError = () => {
    return (
        <ErrorMessage
            iconClass={cx(styles.dailyLimitErrorIcon, styles.errorIcon)}
            content="msg: component.text.createAIText.dialog.error.unableToCreate {Unable to create text}"
            subContent="msg: component.text.createAIText.dialog.error.dailyLimitError.subContent {You\'ve reached your daily limit. Please try again tomorrow.}"
        />
    );
};

export default DailyLimitError;
