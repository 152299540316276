import { getOnlyLinkStyleFromSelection } from "./getLinkFromEditorSelection";
import type { TinyMceEditor } from "../../../flowTypes";
import { THEME_LINK_CLASS_LIST, themeaccent } from '../../constants';
import { linkGlobalStyleKind } from '../../../../../../oneweb/Link/globalStyle/kind';
import { getGlobalstyleClassNameFromStyle } from '../../../../../../RenderGlobalstyles/getGlobalstyleClassName';

// eslint-disable-next-line max-len
export const getSelectedLinkGlobalStyle = (editor: TinyMceEditor, stylesheets: Record<string, any>, autoColorMode: boolean): string|null => {
    const linkClassName: string | null | undefined = getOnlyLinkStyleFromSelection(editor, autoColorMode);

    if (linkClassName) {
        const linkGlobalStyles = stylesheets.styles.filter(s => s.type === linkGlobalStyleKind);
        const linkGlobalStyleClasses = linkGlobalStyles.map(getGlobalstyleClassNameFromStyle);
        if (autoColorMode) {
            // @ts-ignore
            if (THEME_LINK_CLASS_LIST.includes(linkClassName)) {
                return linkClassName;
            } else {
                return THEME_LINK_CLASS_LIST[linkGlobalStyleClasses.indexOf(linkClassName)] || themeaccent;
            }
        } else {
            return (
                (
                    (
                        linkGlobalStyles[linkGlobalStyleClasses.indexOf(linkClassName)] || {}
                    ).id
                ) ||
                null
            );
        }
    }

    return null;
};
