import React from 'react';
import Page from '../PropertiesPage';
import AddGoogleFontsView from '../../../presentational/AddGoogleFont/index';

const AddGoogleFont = props => (
    <Page>
        <AddGoogleFontsView.view {...props} />
    </Page>
);

export default {
    ...AddGoogleFontsView,
    view: AddGoogleFont
};
