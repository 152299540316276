import React from 'react';
import type { ColProps } from "../flowTypes";

type ColsProps = {
    colsProps: Array<ColProps>
}

export default ({ colsProps }: ColsProps) => (
    <colgroup>
        { colsProps.map((colProps, index) => (<col key={index} {...colProps} />)) }
    </colgroup>
);

