import CALL_API from '../../../../../redux/middleware/api/CALL_API';
import * as Actions from "../actionTypes";

export default function (data) {
    return {
        [CALL_API]: {
            types: [Actions.GENERATE_SECTION_CONTENT_REQUEST,
                Actions.GENERATE_SECTION_CONTENT_SUCCESS,
                Actions.GENERATE_SECTION_CONTENT_FAILED],
            endpoint: "generateSectionContent",
            endpointParams: data,
        }
    };
}
