import type { ShutterstockKindT } from "../shutterstock/types";
import { apiAction } from "../../../redux/middleware/api/actions";
import { shutterstockAction } from "../shutterstock/actions";
import type { GetImagesQuery } from "../../../../dal/flowTypes";
import { ThirdPartyImageKind } from "./components/constants";
import type { SelectShutterstockImageActionParams } from "../shutterstock/actions";
import { selectUnsplashCategoryActionProps, UnsplashSaveActionParams } from "./types";

export const
    INITIALIZE_UNSPLASH_ACTION = 'INITIALIZE_UNSPLASH_ACTION',
    initializeUnsplashAction = () => ({
        type: INITIALIZE_UNSPLASH_ACTION
    });

export const
    UNSPLASH_SAVE_ACTION = 'UNSPLASH_SAVE_ACTION',
    unsplashSaveAction = (params: UnsplashSaveActionParams) => ({
        type: UNSPLASH_SAVE_ACTION,
        payload: params
    });

export const
    UNSPLASH_SEARCH_INPUT_CHANGE_ACTION = 'UNSPLASH_SEARCH_INPUT_CHANGE_ACTION';

export const
    SEARCH_UNSPLASH_IMAGES_REQUEST_ACTION = 'SEARCH_UNSPLASH_IMAGES_REQUEST_ACTION',
    SEARCH_UNSPLASH_IMAGES_SUCCESS_ACTION = 'SEARCH_UNSPLASH_IMAGES_SUCCESS_ACTION',
    SEARCH_UNSPLASH_IMAGES_FAILURE_ACTION = 'SEARCH_UNSPLASH_IMAGES_FAILURE_ACTION',
    searchUnsplashImagesAction = (query: GetImagesQuery = {}) =>
        apiAction({
            types: [
                SEARCH_UNSPLASH_IMAGES_REQUEST_ACTION,
                SEARCH_UNSPLASH_IMAGES_SUCCESS_ACTION,
                SEARCH_UNSPLASH_IMAGES_FAILURE_ACTION,
            ],
            endpoint: 'searchUnsplashImages',
            endpointParams: [ThirdPartyImageKind.UNSPLASH, query]
        });

export const
    GET_FREE_ONE_COM_VIDEOS_ACTION = 'GET_FREE_ONE_COM_VIDEOS_ACTION',
    GET_FREE_ONE_COM_VIDEOS_ACTION_SUCCESS = 'GET_FREE_ONE_COM_VIDEOS_ACTION_SUCCESS',
    GET_FREE_ONE_COM_VIDEOS_ACTION_FAILURE = 'GET_FREE_ONE_COM_VIDEOS_ACTION_FAILURE',
    getFreeOneComVideosAction = () =>
        apiAction({
            types: [
                GET_FREE_ONE_COM_VIDEOS_ACTION,
                GET_FREE_ONE_COM_VIDEOS_ACTION_SUCCESS,
                GET_FREE_ONE_COM_VIDEOS_ACTION_FAILURE,
            ],
            endpoint: 'getFreeOneComVideos',
        });

export const
    DOWNLOAD_FREE_ONE_COM_VIDEOS_ACTION = 'DOWNLOAD_FREE_ONE_COM_VIDEOS_ACTION',
    DOWNLOAD_FREE_ONE_COM_VIDEOS_SUCCESS_ACTION = 'DOWNLOAD_FREE_ONE_COM_VIDEOS_SUCCESS_ACTION',
    DOWNLOAD_FREE_ONE_COM_VIDEOS_FAILURE_ACTION = 'DOWNLOAD_FREE_ONE_COM_VIDEOS_FAILURE_ACTION';

export const
    DOWNLOAD_UNSPLASH_IMAGES_REQUEST_ACTION = 'DOWNLOAD_UNSPLASH_IMAGES_REQUEST_ACTION',
    DOWNLOAD_UNSPLASH_IMAGES_SUCCESS_ACTION = 'DOWNLOAD_UNSPLASH_IMAGES_SUCCESS_ACTION',
    DOWNLOAD_UNSPLASH_IMAGES_FAILURE_ACTION = 'DOWNLOAD_UNSPLASH_IMAGES_FAILURE_ACTION',
    downloadUnsplashImagesApiActionAction = (request) =>
        apiAction({
            types: [
                DOWNLOAD_UNSPLASH_IMAGES_REQUEST_ACTION,
                DOWNLOAD_UNSPLASH_IMAGES_SUCCESS_ACTION,
                DOWNLOAD_UNSPLASH_IMAGES_FAILURE_ACTION,
            ],
            endpoint: 'downloadFreeUnsplashImages',
            endpointParams: [request]
        });

export const
    GET_UNSPLASH_CATEGORIES_ACTION = 'GET_UNSPLASH_CATEGORIES_ACTION',
    GET_UNSPLASH_CATEGORIES_SUCCESS_ACTION = 'GET_UNSPLASH_CATEGORIES_SUCCESS_ACTION',
    GET_UNSPLASH_CATEGORIES_FAILURE_ACTION = 'GET_UNSPLASH_CATEGORIES_FAILURE_ACTION',
    getUnsplashCategoriesAction = () =>
        apiAction({
            types: [
                GET_UNSPLASH_CATEGORIES_ACTION,
                GET_UNSPLASH_CATEGORIES_SUCCESS_ACTION,
                GET_UNSPLASH_CATEGORIES_FAILURE_ACTION,
            ],
            endpoint: 'getUnsplashCategories',
            endpointParams: [ThirdPartyImageKind.UNSPLASH]
        });
export const
    HIDE_UNSPLASH_PREMIUM_DOWNLOADS_MSG_ACTION = "HIDE_UNSPLASH_UPGRADE_MSG_ACTION",
    hideUnsplashPremiumDownloadsMsgAction = () => ({
        type: HIDE_UNSPLASH_PREMIUM_DOWNLOADS_MSG_ACTION
    });

export const
    SELECT_UNSPLASH_CATEGORY_ACTION = 'SELECT_UNSPLASH_CATEGORY_ACTION',
    selectUnsplashCategoryAction = (
        { categoryId, categoryName }: selectUnsplashCategoryActionProps,
    ) => ({
        type: SELECT_UNSPLASH_CATEGORY_ACTION,
        payload: { categoryId, categoryName }
    });

export const
    UNSPLASH_GRID_SCROLLED_TO_BOTTOM_ACTION = 'UNSPLASH_GRID_SCROLLED_TO_BOTTOM_ACTION',
    unsplashGridScrolledToBottomAction = () => ({
        type: UNSPLASH_GRID_SCROLLED_TO_BOTTOM_ACTION
    });

export const
    LOAD_UNSPLASH_IMAGES_PAGE_REQUEST_ACTION = 'LOAD_UNSPLASH_IMAGES_PAGE_REQUEST_ACTION',
    LOAD_UNSPLASH_IMAGES_PAGE_SUCCESS_ACTION = 'LOAD_UNSPLASH_IMAGES_PAGE_SUCCESS_ACTION',
    LOAD_UNSPLASH_IMAGES_PAGE_FAILURE_ACTION = 'LOAD_UNSPLASH_IMAGES_PAGE_FAILURE_ACTION',
    loadUnsplashImagesPageAction = (query?: GetImagesQuery) =>
        apiAction({
            types: [
                LOAD_UNSPLASH_IMAGES_PAGE_REQUEST_ACTION,
                LOAD_UNSPLASH_IMAGES_PAGE_SUCCESS_ACTION,
                LOAD_UNSPLASH_IMAGES_PAGE_FAILURE_ACTION,
            ],
            endpoint: 'searchUnsplashImages',
            endpointParams: [ThirdPartyImageKind.UNSPLASH, query]
        });

export const
    UNSPLASH_SEARCH_CLICK_ACTION = 'UNSPLASH_SEARCH_CLICK_ACTION',
    unsplashSearchClickAction = (kind: ShutterstockKindT) => shutterstockAction(kind, {
        type: UNSPLASH_SEARCH_CLICK_ACTION,
    });

export const
    UNSPLASH_SEARCH_ENTER_PRESS_ACTION = 'UNSPLASH_SEARCH_ENTER_PRESS_ACTION ',
    unsplashSearchEnterPressAction = () => ({
        type: UNSPLASH_SEARCH_ENTER_PRESS_ACTION
    });

export const
    UNSPLASH_GRID_SCROLL_TOP_ACTION = 'UNSPLAH_GRID_SCROLL_TOP_ACTION',
    unsplashGridScrollTopAction = (scrollTop: number) => ({
        type: UNSPLASH_GRID_SCROLL_TOP_ACTION,
        payload: scrollTop
    });

export const
    UNSPLASH_CLEAN_SEARCH_ACTION = 'UNSPLASH_CLEAN_SEARCH_ACTION',
    unsplashCleanSearchAction = () => ({
        type: UNSPLASH_CLEAN_SEARCH_ACTION,
    });

export const
    DESELECT_ALL_UNSPLASH_IMAGES_ACTION = 'DESELECT_ALL_UNSPLASH_IMAGES_ACTION',
    deselectAllUnsplashImagesAction = () => ({
        type: DESELECT_ALL_UNSPLASH_IMAGES_ACTION,
    });

export const
    UNSPLASH_PORTRAIT_ORIENTATION_CLICKED_ACTION = 'UNSPLASH_PORTRAIT_ORIENTATION_CLICKED_ACTION',
    unsplashPortraitOrientationClickedAction = () => ({
        type: UNSPLASH_PORTRAIT_ORIENTATION_CLICKED_ACTION,
    });

export const
    UNSPLASH_LANDSCAPE_ORIENTATION_CLICKED_ACTION = 'UNSPLASH_LANDSCAPE_ORIENTATION_CLICKED_ACTION',
    unsplashLandscapeOrientationClickedAction = () => ({
        type: UNSPLASH_LANDSCAPE_ORIENTATION_CLICKED_ACTION,
    });

export const
    SELECT_UNSPLASH_IMAGE_ACTION = 'SELECT_UNSPLASH_IMAGE_ACTION',
    selectUnsplashImageAction = ({ imageId, isMultiSelect }: SelectShutterstockImageActionParams) =>
        ({
            type: SELECT_UNSPLASH_IMAGE_ACTION,
            payload: { imageId, isMultiSelect },
        });
