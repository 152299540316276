import * as R from 'ramda';
import knn from 'rbush-knn';
import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly } from '../../../../epics/makeCondition';
import valueActionType from './valueActionType';
import {
    ForDecosSelector,
    ReceiveOnlyUserInteractionModeSelector,
    ReceiveOnlyComponentsMapNoGhostsSelector,
    ReceiveOnlySelectedComponentsIdsSelector,
} from "../componentsEval/selectorActionTypes";
import type {
    BBox, AnyComponent, Handle
} from '../../../../redux/modules/children/workspace/flowTypes';
import { isResizingHandle } from '../../../../utils/handle/index';
import { getComponentsBBox, zeroBBox } from '../../../../utils/componentsMap/index';
import makeStateSelectorReducer from '../../../../epics/makeStateSelectorReducer';
import workspaceBBoxValueActionType from '../workspaceBBox/valueActionType';
import handlesValueActionType from '../handles/valueActionType';
import * as interactionModes from '../componentsEval/userInteractionMutations/interactionModes';
import makeBBox from '../../../../utils/componentsMap/makeBBoxMemoized';
import { filteringBBoxValues, handleKindsForNEWS, DIRECTIONS, oppositeDirections } from './constants';
import type { ComponentTree } from '../redDecorations/flowTypes';
import treeInstance from '../redDecorations/rbushInstance';
import { getComponentZIndex } from '../../zIndex';
import { createScheduleAction } from './actionCreators';
import { areBBoxesIntersectedByY } from "../../../../../utils/bbox";
import { ReceiveOnlyTemplateWidthActionType } from "../../../oneweb/Template/epics/template/selectorActionTypes";
import { isZeroBBox } from "./utils";
import { CANCEL_SCHEDULED_ACTION } from "../../../../redux/middleware/schedule/actionTypes";
import { ReceiveOnlyAttachments } from "../componentAttachements/selectorActionTypes";
import { getAttachedContainerCmpId } from "../componentAttachements/util";
import { makeDistancesLines, getCmpsToSnapToWithDirections, shouldEasierWSLinesBeShown, shouldSameSizeLinesBeShown,
    getCmpsToSnapToWithDirectionsForSameSize, makeDistancesLinesForSameSize } from "./easierWSGuidelines";
import type { BBoxContainer, DistanceLine } from './flowTypes';
import { workspaceComponentAttachDecorationsValueActionType } from "../componentAttachDecorations/valueActionType";
import { SHOW_EASIER_WS_GUIDELINES_ON_ADD_CMP, HIDE_EASIER_WS_GUIDELINES_ON_ADD_CMP } from "./actionTypes";
import { COMPONENT_ADD_CANCELLED, NEW_COMPONENT_DROPED_ON_WORKSPACE } from "../../../DndAddComponent/actionTypes";
import { NEW_COMPONENT_DROPPED_IS_ADDED_TO_ATTACHMENTS } from "../componentAttachements/actionTypes";
import { makeEquidistantLines } from "./easierWSEquidistantGuidelines";
import { ReceiveOnlyDnDSnappedState } from "../../../DndAddComponent/epic/selectorActionTypes";
import {
    AltPressedActionType,
    OptionalIsArrowKeyPressedActionType
} from "../../../App/epics/isKeyPressed/selectorActionTypes";
import { isLeftMouseDownVAT } from "../isLeftMouseDown/valueActionType";

function isLessEq(a: number, b: number) {
    return a <= b;
}

function isBiggerEq(a: number, b: number) {
    return a >= b;
}

function sortLess(a: number, b: number) {
    return a - b;
}

function sortBigger(a: number, b: number) {
    return b - a;
}

function decrement(a: number, b: number) {
    return a - b;
}

function increment(a: number, b: number) {
    return a + b;
}

export const RESET_RESIZE_DECOS = 'RESET_RESIZE_DECOS';
const
    directionIntersectionFunction = {
        top: areBBoxesIntersectedByX,
        bottom: areBBoxesIntersectedByX,
        left: areBBoxesIntersectedByY,
        right: areBBoxesIntersectedByY
    };
export const directionComparingFunction = {
    top: isLessEq,
    bottom: isBiggerEq,
    left: isLessEq,
    right: isBiggerEq
};

const sortComparingFunction = {
    top: sortLess,
    bottom: sortBigger,
    left: sortLess,
    right: sortBigger
};

const incrementFunction = {
    top: decrement,
    bottom: increment,
    left: decrement,
    right: increment
};
type Coords = {
    x: number,
    y: number
}

export function areBBoxesIntersectedByX(bboxA: BBox, bboxB: BBox): boolean {
    return bboxA.right >= bboxB.left && bboxB.right >= bboxA.left;
}

function createSortByDistance(selectionBBox: BBox, direction: string, workspaceBBox) {
    return (cmpA, cmpB) => sortByDistance(
        selectionBBox,
        direction,
        getComponentsBBox([cmpA], workspaceBBox),
        getComponentsBBox([cmpB], workspaceBBox)
    );
}

function sortByDistance(selectionBBox: BBox, direction, bboxA: BBox, bboxB: BBox): number {
    return sortLess(getDistanceFromBBoxToBBox(selectionBBox, bboxA, direction),
        getDistanceFromBBoxToBBox(selectionBBox, bboxB, direction));
}

function getDistanceFromBBoxToBBox(selectionBBox: BBox, bbox: BBox,
    direction: string, noAbs: boolean = false): number {
    const
        distanceSameDirection = selectionBBox[direction] - bbox[direction],
        distanceOppositeDirection = selectionBBox[direction] - bbox[oppositeDirections[direction]],
        fN = noAbs ? R.identity : Math.abs;

    return fN(directionComparingFunction[direction](0, distanceOppositeDirection) ?
        distanceOppositeDirection : distanceSameDirection);
}

function getDirectionBBoxPoints(bbox: BBox): BBoxContainer<Coords> {
    return {
        top: {
            x: (bbox.left / 2) + (bbox.right / 2),
            y: bbox.top
        },
        left: {
            x: bbox.left,
            y: (bbox.top / 2) + (bbox.bottom / 2)
        },
        right: {
            x: bbox.right,
            y: (bbox.top / 2) + (bbox.bottom / 2)
        },
        bottom: {
            x: (bbox.left / 2) + (bbox.right / 2),
            y: bbox.bottom
        }
    };
}

function limitingEntityFilter(entity, limitedBBox, direction, selectedComponentsIds: Array<string> = []) {
    if (entity.id && selectedComponentsIds.indexOf(entity.id) > -1) {
        return false;
    }
    const cmpBBox = makeBBox(entity.left, entity.top, entity.width, entity.height);

    return directionIntersectionFunction[direction](
        cmpBBox,
        limitedBBox
    )
        && directionComparingFunction[direction](cmpBBox[direction], limitedBBox[direction]);
}

function findClosestComponent(tree: any,
    selectionBBox: BBox,
    direction: string,
    minZIndex: number,
    workspaceBBox: BBox,
    templateWidth: number,
    selectedComponentsIds: string[]): ComponentTree {
    const
        directionCoords: Coords = getDirectionBBoxPoints(selectionBBox)[direction],
        templateEntity = {
            top: 0,
            left: 0,
            width: templateWidth,
            height: workspaceBBox.bottom
        },
        cmps = knn(tree, directionCoords.x, directionCoords.y, 5,
            a => limitingEntityFilter(a, selectionBBox, direction, selectedComponentsIds))
            .concat(limitingEntityFilter(templateEntity, selectionBBox, direction) ? [templateEntity] : []);
    return cmps
        .sort(
            createSortByDistance(selectionBBox, direction, workspaceBBox)
        )
        .filter(component => !component.id || getComponentZIndex(component) >= minZIndex)[0];
}

function getMergedDistanceBbox(distanceLines, distanceBBox) {
    let newDistanceBBox = zeroBBox;
    const easierWSDirections = Object.keys(distanceLines);
    if (easierWSDirections.length !== 1) {
        return newDistanceBBox;
    }
    Object.keys(distanceBBox).forEach(direction => {
        if (!distanceBBox[direction]) {
            return;
        }
        if ([direction, oppositeDirections[direction]].includes(easierWSDirections[0])) {
            return;
        }
        newDistanceBBox = {
            ...newDistanceBBox,
            [direction]: distanceBBox[direction]
        };
    });
    return newDistanceBBox;
}

function getMergedDistanceLines(distanceLines1 = {}, distanceLines2 = {}) {
    let directions = [...Object.keys(distanceLines1), ...Object.keys(distanceLines2)];
    directions = Array.from(new Set(directions));
    const distanceLines = {};
    directions.forEach(direction => {
        const lines1 = distanceLines1[direction], lines2 = distanceLines2[direction];
        if (!lines1 && !lines2) { return; }
        if (!lines1) { distanceLines[direction] = lines2; return; }
        if (!lines2) { distanceLines[direction] = lines1; return; }
        distanceLines[direction] = {};
        Object.keys(lines2).forEach(prop => {
            distanceLines[direction][prop] = [...distanceLines1[direction][prop], ...distanceLines2[direction][prop]];
        });
    });
    return distanceLines;
}

export function makeBBoxDistances(limitingEntities: BBoxContainer<string>,
    selectedComponentsBBox: BBox, workspaceBBox?: BBox) {
    return R.mapObjIndexed((entity, direction) => {
        return Math.abs(getDistanceFromBBoxToBBox(
            selectedComponentsBBox,
            getComponentsBBox([entity], workspaceBBox),
            direction,
            true
        ));
    }, limitingEntities);
}

function makeLimitingBBox(selectionBBox: BBox, distancesBBox: BBox): BBox {
    return R.mapObjIndexed((distance: number, direction: string): number => {
        return incrementFunction[direction](selectionBBox[direction], distancesBBox[direction]);
    }, distancesBBox);
}

function makeDifferenceFromBBoxes(fromBBox: BBox, toBBox: BBox): BBox {
    return R.mapObjIndexed((fromValue: number, direction: string) => {
        return sortComparingFunction[direction](fromBBox[direction], toBBox[direction]);
    }, toBBox);
}

export function findLimitingEntities(tree: any,
    selectionBBox: BBox,
    handleKind: string,
    workspaceBBox: BBox,
    templateWidth: number,
    selectedComponentsIds: string[],
    minZIndex: number = 0): BBoxContainer<string> {
    return filteringBBoxValues[handleKind].reduce((limitingEntities, direction) => {
        const closestComponent: ComponentTree = findClosestComponent(
            tree,
            selectionBBox,
            direction,
            minZIndex,
            workspaceBBox,
            templateWidth,
            selectedComponentsIds
        );
        if (closestComponent) {
            limitingEntities[direction] = closestComponent; // eslint-disable-line
        }
        return limitingEntities;
    }, {});
}

export type ResizeDecorationsState = {
    distanceBBox: BBox,
    componentsBBoxes: BBox[],
    selectionBBox: BBox,
    oldSelectionBBox: BBox,
    userInteractionMode: string,
    sectionSplitLines: BBoxContainer<number>,
    distanceLines: DistanceLine
}

export type ResizeDecorationsEpicState = {
    scope: {
        limitingBBox: BBox,
        scheduleActionInProgress: boolean,
        altActionInProgress: boolean,
    },
    state: ResizeDecorationsState
}

const
    defaultScope = {
        limitingBBox: zeroBBox,
        scheduleActionInProgress: false,
        altActionInProgress: false,
    },
    defaultState: ResizeDecorationsEpicState = {
        scope: defaultScope,
        state: {
            distanceBBox: zeroBBox,
            componentsBBoxes: [],
            selectionBBox: zeroBBox,
            oldSelectionBBox: zeroBBox,
            userInteractionMode: '',
            isVisible: false,
            sectionSplitLines: {},
            // @ts-ignore
            distanceLines: {},
        }
    },
    makeZeroState = R.curry((userInteractionMode, epicState) => {
        return {
            scope: defaultScope,
            state: {
                distanceBBox: zeroBBox,
                componentsBBoxes: epicState.state.componentsBBoxes,
                distanceLines: {},
                selectionBBox: epicState.state.selectionBBox,
                oldSelectionBBox: epicState.state.selectionBBox,
                userInteractionMode,
                isVisible: false
            }
        };
    }),
    scheduleActionInProgressPath = ['scope', 'scheduleActionInProgress'],
    altActionInProgressPath = ['scope', 'altActionInProgress'],
    setScheduleActionInProgress = R.assocPath(scheduleActionInProgressPath),
    setAltActionInProgress = R.assocPath(altActionInProgressPath),
    altActionInProgressSelector = R.path(altActionInProgressPath),
    scheduleActionInProgressSelector = R.path(scheduleActionInProgressPath),
    makeTransitionStateUpdater = (componentsMap, userInteractionComponentsIds, workspaceBBox, userInteractionMode) =>
        (epicState) => {
            const selectedComponents: Array<AnyComponent> = userInteractionComponentsIds.map(
                (id: string): AnyComponent => componentsMap[id]
            ),
                selectionBBox: BBox = getComponentsBBox(selectedComponents, workspaceBBox),
                limitingBBox = epicState.scope.limitingBBox,
                distanceBBox = isZeroBBox(limitingBBox) ?
                    zeroBBox : makeDifferenceFromBBoxes(selectionBBox, limitingBBox);

            return {
                scope: {
                    ...epicState.scope,
                    limitingBBox
                },
                state: {
                    distanceBBox,
                    componentsBBoxes: epicState.state.componentsBBoxes,
                    selectionBBox,
                    oldSelectionBBox: selectionBBox,
                    userInteractionMode,
                    isVisible: true
                }
            };
        },
    makeTransitionStateUpdaterForEasierWSSameSize = (componentsMap, selectedCmp, workspaceBBox, userInteractionMode,
        templateWidth, attachments, parentContainerId, handleKind?: any) =>
        (epicState) => {
            const parentId = parentContainerId || getAttachedContainerCmpId(selectedCmp.id, attachments),
                parentContainer = componentsMap[parentId];

            const limitingEntities = getCmpsToSnapToWithDirectionsForSameSize(selectedCmp, componentsMap, parentContainer,
                attachments, parentId, templateWidth, handleKind);

            if (!Object.keys(limitingEntities).length) {
                return epicState;
            }

            const distanceLines = makeDistancesLinesForSameSize(limitingEntities, selectedCmp);

            return {
                scope: {
                    ...epicState.scope,
                },
                state: {
                    ...epicState.state,
                    distanceLines,
                }
            };
        },
    makeTransitionStateUpdaterForEasierWS = (componentsMap, selectedCmp, workspaceBBox, userInteractionMode,
        templateWidth, attachments, parentContainerId, handleKind?: any) =>
        (epicState) => {
            const selectionBBox: BBox = getComponentsBBox([selectedCmp], workspaceBBox);
            if (selectionBBox.left < 0 || selectionBBox.right > templateWidth) {
                return epicState;
            }
            const parentId = parentContainerId || getAttachedContainerCmpId(selectedCmp.id, attachments),
                parentContainer = componentsMap[parentId],
                parentCenterY = parentContainer.top + (parentContainer.height / 2),
                componentsBBoxes: Array<any> = [];

            const limitingEntities = getCmpsToSnapToWithDirections(selectedCmp, componentsMap, parentContainer,
                attachments, parentId, templateWidth, handleKind);

            if (!Object.keys(limitingEntities).length) {
                return epicState;
            }

            let sectionSplitLines = {};
            let distanceBBox = zeroBBox,
                distanceLines = makeDistancesLines(limitingEntities, selectionBBox, workspaceBBox, userInteractionMode,
                    templateWidth, parentContainer, handleKind);

            R.forEachObjIndexed((entity, direction) => {
                if (entity.id) {
                    componentsBBoxes.push(getComponentsBBox([entity], workspaceBBox));
                }
                if ((direction === DIRECTIONS.LEFT || direction === DIRECTIONS.RIGHT)
                    && distanceLines[direction].isHideDistanceLines.some(d => !d)) {
                    sectionSplitLines[DIRECTIONS.TOP] = {
                        left: templateWidth / 2,
                        height: componentsMap[parentId].height,
                        top: componentsMap[parentId].top,
                    };
                }
                if ((direction === DIRECTIONS.TOP || direction === DIRECTIONS.BOTTOM)
                    && distanceLines[direction].isHideDistanceLines.some(d => !d)) {
                    sectionSplitLines[DIRECTIONS.LEFT] = {
                        left: 0,
                        width: templateWidth,
                        top: parentCenterY,
                    };
                }
            }, limitingEntities);

            return {
                scope: {
                    ...epicState.scope,
                },
                state: {
                    distanceBBox,
                    distanceLines,
                    componentsBBoxes,
                    selectionBBox,
                    oldSelectionBBox: selectionBBox,
                    userInteractionMode,
                    isVisible: true,
                    sectionSplitLines,
                }
            };
        },
    makeTransitionStateUpdaterForEquidistant = (snappedPoints, selectedCmp, workspaceBBox, userInteractionMode) =>
        (epicState) => {
            const selectionBBox: BBox = getComponentsBBox(selectedCmp, workspaceBBox),
                componentsBBoxes = [],
                distanceBBox = zeroBBox,
                distanceLines =
                    snappedPoints.reduce((acc, [direction, { boxes }]) => {
                        return {
                            ...acc,
                            [direction]: makeEquidistantLines(selectionBBox, boxes, direction)
                        };
                    }, {});
            return {
                scope: {
                    ...epicState.scope,
                },
                state: {
                    distanceBBox,
                    distanceLines: getMergedDistanceLines(distanceLines, epicState.state.distanceLines),
                    componentsBBoxes,
                    selectionBBox,
                    oldSelectionBBox: selectionBBox,
                    userInteractionMode,
                    isVisible: true,
                    sectionSplitLines: {},
                }
            };
        },
    filterHandlesByKind = (handles: Handle[], handleKinds: string[]): Handle[] => handles.filter(
        handle => handleKinds.indexOf(handle.kind) > -1
    ),
    // isHandleOutsideTemplateArea = (handle, templateWidth) => handle.bBox.right < 0 || handle.bBox.left > templateWidth,
    makeInitialState = (componentsMap, userInteractionComponentsIds, tree, userInteractionHandleKinds, workspaceBBox,
        resizeHandles, userInteractionMode, templateWidth) => {
        const selectedComponents: Array<AnyComponent> = userInteractionComponentsIds.map(
            (id: string): AnyComponent => componentsMap[id]
        ),
            selectionBBox: BBox = getComponentsBBox(selectedComponents, workspaceBBox),
            limitingEntities = resizeHandles.map((handle, i) => {
                const
                    uiHandleKind = userInteractionHandleKinds[i],
                    topcomponent = R.sortBy(R.prop(filteringBBoxValues[uiHandleKind][0]))(selectedComponents)[0],
                    selectionZIndex = getComponentZIndex(topcomponent),
                    componentsBelow = tree
                        .search(handle)
                        .filter(component => getComponentZIndex(component) < selectionZIndex)
                        .sort((a, b) => (getComponentZIndex(b) - getComponentZIndex(a))),
                    componentBelowZIndex = componentsBelow.length > 0 ? getComponentZIndex(componentsBelow[0]) : 0;
                return findLimitingEntities(
                    tree,
                    selectionBBox,
                    uiHandleKind,
                    workspaceBBox,
                    templateWidth,
                    userInteractionComponentsIds,
                    componentBelowZIndex
                );
            }).reduce((acc, limitingEntitiesObj) => ({
                ...acc,
                ...limitingEntitiesObj
            }), {}),
            componentsBBoxes: Array<any> = [];

        R.forEachObjIndexed(entity => {
            if (entity.id) {
                componentsBBoxes.push(getComponentsBBox([entity], workspaceBBox));
            }
        }, limitingEntities);
        const distanceBBox = makeBBoxDistances(limitingEntities, selectionBBox, workspaceBBox),
            limitingBBox = makeLimitingBBox(selectionBBox, distanceBBox);

        return {
            scope: {
                ...defaultScope,
                limitingBBox
            },
            state: {
                distanceBBox,
                componentsBBoxes,
                selectionBBox,
                oldSelectionBBox: selectionBBox,
                userInteractionMode,
                isVisible: true
            }
        };
    },
    makeMoveByArrowStateUpdate = ({
        userInteractionMode,
        componentsMapNoGhosts,
        userInteractionComponentsIds,
        workspaceBBox,
        handles,
        templateWidth,
        state
    }) => {
        if (userInteractionMode === interactionModes.MOUSE_DOWN_ON_HANDLE) {
            return [makeZeroState(userInteractionMode), null];
        }
        const scheduleActionInProgress = scheduleActionInProgressSelector(state);
        if (userInteractionMode === interactionModes.MOVING_COMPONENTS_BY_ARROW_KEYS) {
            const returnNewState = () => makeInitialState(
                componentsMapNoGhosts,
                userInteractionComponentsIds,
                treeInstance,
                handleKindsForNEWS,
                workspaceBBox,
                filterHandlesByKind(handles, handleKindsForNEWS),
                userInteractionMode,
                templateWidth
            );

            if (scheduleActionInProgress) {
                const actionToDispatch = {
                    type: CANCEL_SCHEDULED_ACTION,
                    payload: {
                        actionToDispatch: { type: RESET_RESIZE_DECOS, payload: null }
                    }
                };

                return [returnNewState, actionToDispatch];
            } else {
                return [returnNewState, null];
            }
        }

        const previousUserInteractionMode = state.state.userInteractionMode;
        if (
            !scheduleActionInProgress
            && previousUserInteractionMode === interactionModes.MOVING_COMPONENTS_BY_ARROW_KEYS
        ) {
            const actionToDispatch = createScheduleAction({
                type: RESET_RESIZE_DECOS,
                payload: null
            }, 1000);

            return [setScheduleActionInProgress(true), actionToDispatch];
        }
        return [makeZeroState(userInteractionMode), null];
    },
    makeOnResizeByMouseUpdater = ({
        userInteractionHandleKind,
        userInteractionMode,
        componentsMapNoGhosts,
        userInteractionComponentsIds,
        workspaceBBox,
        handles,
        templateWidth,
        state,
    }) => {
        const currentUserInteractionHandleIsResize = isResizingHandle(userInteractionHandleKind);
        if (userInteractionMode === interactionModes.MOUSE_DOWN_ON_HANDLE) {
            if (currentUserInteractionHandleIsResize) {
                const toInitialStateUpdater = () => makeInitialState(
                    componentsMapNoGhosts,
                    userInteractionComponentsIds,
                    treeInstance,
                    [userInteractionHandleKind],
                    workspaceBBox,
                    filterHandlesByKind(handles, [userInteractionHandleKind]),
                    userInteractionMode,
                    templateWidth
                );
                return [toInitialStateUpdater, null];
            }
        }

        if (userInteractionMode === interactionModes.RESIZE_HANDLE_MOVING) {
            const transitionStateUpdater = makeTransitionStateUpdater(
                componentsMapNoGhosts,
                userInteractionComponentsIds,
                workspaceBBox,
                userInteractionMode,
            );
            return [transitionStateUpdater, null];
        }

        const previousUserInteractionMode = state.state.userInteractionMode;

        if (
            !currentUserInteractionHandleIsResize
            && (
                previousUserInteractionMode === interactionModes.MOVING_COMPONENTS ||
                previousUserInteractionMode === interactionModes.RESIZE_HANDLE_MOVING
                || previousUserInteractionMode === interactionModes.MOUSE_DOWN_ON_HANDLE
            )
        ) {
            return [makeZeroState(userInteractionMode)];
        }
        return [R.identity, null];

        /*
        if (isHandleOutsideTemplateArea(
            filterHandlesByKind(handles, [userInteractionHandleKind])[0],
            templateWidth
        )) {
            return { state: defaultState };
        }*/
    },
    { reducer: epicReducer, updaters } = makeEpic({
        defaultState,
        valueActionType,
        updaters: [
            {
                conditions: [
                    AltPressedActionType,
                    OptionalIsArrowKeyPressedActionType,
                    isLeftMouseDownVAT,
                    ReceiveOnlyComponentsMapNoGhostsSelector,
                    receiveOnly(workspaceBBoxValueActionType),
                    receiveOnly(handlesValueActionType),
                    ReceiveOnlyTemplateWidthActionType,
                    ReceiveOnlyUserInteractionModeSelector,
                    ReceiveOnlySelectedComponentsIdsSelector,
                ],
                reducer: ({
                    values: [
                        isAlt,
                        isArrowPressed,
                        isLeftMouseDown,
                        componentsMapNoGhosts,
                        workspaceBBox,
                        handles,
                        templateWidth,
                        userInteractionMode,
                        selectedComponentIds,
                    ],
                    state
                }) => {
                    const altInProgress = altActionInProgressSelector(state),
                        canShowLines = isAlt && !isArrowPressed && !isLeftMouseDown;
                    if (canShowLines) {
                        const newState = makeInitialState(
                            componentsMapNoGhosts,
                            selectedComponentIds,
                            treeInstance,
                            handleKindsForNEWS,
                            workspaceBBox,
                            filterHandlesByKind(handles, handleKindsForNEWS),
                            userInteractionMode,
                            templateWidth
                        );
                        return { state: setAltActionInProgress(true, newState) };
                    }
                    if (altInProgress && !isAlt) {
                        return {
                            state: R.pipe(makeZeroState(userInteractionMode), setAltActionInProgress(false))(state)
                        };
                    }
                    return { state };
                }
            },
            {
                conditions: [
                    ForDecosSelector,
                    receiveOnly(workspaceBBoxValueActionType),
                    receiveOnly(handlesValueActionType),
                    ReceiveOnlyTemplateWidthActionType,
                    ReceiveOnlyAttachments,
                    receiveOnly(workspaceComponentAttachDecorationsValueActionType),
                ],
                reducer: ({
                    values: [
                        {
                            componentsMapNoGhosts,
                            userInteractionMode,
                            userInteractionComponentsIds,
                            userInteractionHandleKind,
                            snappingState: { snappedPoints },
                        },
                        workspaceBBox,
                        handles,
                        templateWidth,
                        attachments,
                        { cmpId: parentContainerId },
                    ],
                    state
                }) => {
                    const
                        [moveByArrowsStateUpdater, moveByArrowsActionToDispatch] = makeMoveByArrowStateUpdate({
                            userInteractionMode,
                            componentsMapNoGhosts,
                            userInteractionComponentsIds,
                            workspaceBBox,
                            handles,
                            templateWidth,
                            state
                        }), [onResizeByMouseStateUpdater, onResizeByMouseActionToDispatch] = makeOnResizeByMouseUpdater({
                            userInteractionHandleKind,
                            userInteractionMode,
                            componentsMapNoGhosts,
                            userInteractionComponentsIds,
                            workspaceBBox,
                            handles,
                            templateWidth,
                            state
                        });

                    let newState = R.pipe(moveByArrowsStateUpdater, onResizeByMouseStateUpdater)(state);
                    const actionToDispatch = moveByArrowsActionToDispatch || onResizeByMouseActionToDispatch;

                    if (shouldSameSizeLinesBeShown(userInteractionMode, userInteractionComponentsIds, componentsMapNoGhosts,
                        attachments, parentContainerId, workspaceBBox, templateWidth)) {
                        newState = makeTransitionStateUpdaterForEasierWSSameSize(
                            componentsMapNoGhosts,
                            componentsMapNoGhosts[userInteractionComponentsIds[0]],
                            workspaceBBox,
                            userInteractionMode,
                            templateWidth,
                            attachments,
                            parentContainerId,
                            userInteractionHandleKind,
                        )(newState);
                        if (newState.state.distanceLines) {
                            return {
                                state: newState,
                                actionToDispatch
                            };
                        }
                    }

                    const equidistantPoints = Object.keys(snappedPoints).reduce((acc: Array<any>, k) => {
                        if (snappedPoints[k].isEquidistant) {
                            return [...acc, [k, snappedPoints[k]]];
                        }
                        return acc;
                    }, []);
                    if (equidistantPoints.length) {
                        return {
                            state: makeTransitionStateUpdaterForEquidistant(
                                equidistantPoints,
                                userInteractionComponentsIds.map(id => componentsMapNoGhosts[id]),
                                workspaceBBox,
                                userInteractionMode
                            )(newState),
                            actionToDispatch
                        };
                    }

                    if (!shouldEasierWSLinesBeShown(userInteractionMode, userInteractionComponentsIds, componentsMapNoGhosts,
                        attachments, parentContainerId, workspaceBBox, templateWidth, userInteractionHandleKind)) {
                        return {
                            state: newState,
                            actionToDispatch,
                        };
                    }
                    const easierWSstate = makeTransitionStateUpdaterForEasierWS(
                        componentsMapNoGhosts,
                        componentsMapNoGhosts[userInteractionComponentsIds[0]],
                        workspaceBBox,
                        userInteractionMode,
                        templateWidth,
                        attachments,
                        parentContainerId,
                        userInteractionHandleKind,
                    )(newState);

                    if (userInteractionMode === interactionModes.MOVING_COMPONENTS_BY_ARROW_KEYS
                        || userInteractionMode === interactionModes.RESIZE_HANDLE_MOVING) {
                        easierWSstate.state = {
                            ...easierWSstate.state,
                            distanceBBox: getMergedDistanceBbox(easierWSstate.state.distanceLines,
                                newState.state.distanceBBox)
                        };
                    }

                    return {
                        state: easierWSstate,
                        actionToDispatch
                    };
                }
            },
            {
                conditions: [
                    ReceiveOnlyUserInteractionModeSelector,
                    RESET_RESIZE_DECOS
                ],
                reducer: ({
                    values: [
                        userInteractionMode
                    ],
                    state
                }) => {
                    return { state: makeZeroState(userInteractionMode, state) };
                }
            },
            {
                conditions: [
                    SHOW_EASIER_WS_GUIDELINES_ON_ADD_CMP,
                    ReceiveOnlyDnDSnappedState,
                    ReceiveOnlyComponentsMapNoGhostsSelector,
                    receiveOnly(workspaceBBoxValueActionType),
                    ReceiveOnlyTemplateWidthActionType,
                    ReceiveOnlyAttachments,
                ],
                reducer: ({
                    values: [
                        {
                            parentContainerId,
                            cmpToAdd,
                        },
                        { snappedPoints },
                        componentsMap,
                        workspaceBBox,
                        templateWidth,
                        attachments,
                    ],
                    state
                }) => {
                    let newState = makeZeroState(interactionModes.ADDING_COMPONENT, state);
                    const equidistantPoints = Object.keys(snappedPoints).reduce((acc: Array<any>, k) => {
                        if (snappedPoints[k].isEquidistant) { return [...acc, [k, snappedPoints[k]]]; }
                        return acc;
                    }, []);
                    if (equidistantPoints.length) {
                        return {
                            state: makeTransitionStateUpdaterForEquidistant(
                                equidistantPoints,
                                [cmpToAdd],
                                workspaceBBox,
                                interactionModes.ADDING_COMPONENT
                            )(newState)
                        };
                    }
                    return {
                        state: makeTransitionStateUpdaterForEasierWS(
                            componentsMap,
                            cmpToAdd,
                            workspaceBBox,
                            interactionModes.ADDING_COMPONENT,
                            templateWidth,
                            attachments,
                            parentContainerId,
                        )(newState)
                    };
                }
            },
            ...[
                HIDE_EASIER_WS_GUIDELINES_ON_ADD_CMP,
                NEW_COMPONENT_DROPED_ON_WORKSPACE,
                NEW_COMPONENT_DROPPED_IS_ADDED_TO_ATTACHMENTS,
                COMPONENT_ADD_CANCELLED,
            ].map(action => ({
                conditions: [action],
                reducer: ({ state }) => ({ state: makeZeroState(interactionModes.ADDING_COMPONENT, state) })
            })),
        ]
    });

export default {
    reducer: makeStateSelectorReducer(epicReducer, valueActionType),
    defaultState,
    valueActionType,
    updaters
};
