import kind from './kind';
import view from './view/index';
import type { CalcProps } from '../../../Preview/flowTypes';
import type { WebShopPoliciesComponent } from '../flowTypes';
import { CmpSpecificScriptProps } from "../../../Preview/flowTypes";

export default {
    kind,
    view,
    calcProps: ({
        component,
        webshopMHFData,
        globalStyles: stylesheets,
        siteSettings,
        selectedParentTheme,
    }: CalcProps<WebShopPoliciesComponent>) => ({
        ...component,
        specialFooterData: {
            ...webshopMHFData,
            privacyPolicyContent: siteSettings?.privacyPolicy?.content
        },
        stylesheets,
        selectedParentTheme: siteSettings?.themeSettingsData?.autoColorMode && selectedParentTheme
    }),
    getSpecificScript: ({
        siteSettings
    }: CmpSpecificScriptProps<WebShopPoliciesComponent>) => {
        const privacyPolicy = JSON.stringify({ content: siteSettings?.privacyPolicy?.content });
        return `
            (function (){
                function policiesDialogOnClose() {
                    oneJQuery(".policiesDialogVisibilityContainer").hide();
                    oneJQuery(".policiesDialogVisibilityContainer .policiesDialogBody").children().remove();
                }
                oneJQuery(window).on("load", function() {
                    if (oneJQuery(".openPrivacyPolicyDialog").length) {
                        oneJQuery(".openPrivacyPolicyDialog").on("click", function () {
                            oneJQuery(".policiesDialogVisibilityContainer .policiesDialogBody").children().remove();
                            oneJQuery(".policiesDialogVisibilityContainer").show();
                            oneJQuery(".policiesDialogVisibilityContainer .policiesDialogBody").html(${privacyPolicy}.content);

                        });
                        oneJQuery(".policiesDialogVisibilityContainer .policiesDialogClose").on("click", policiesDialogOnClose);
                        oneJQuery(".policiesDialogVisibilityContainer .policiesDialogFooterBtn").on("click", policiesDialogOnClose);
                    }
                });
            })();`;
    }
};
