/* eslint-disable max-len */

import { isLeftMouseDownEpic } from "../components/Workspace/epics/isLeftMouseDown";
import { dialogsManagerEpic } from "../components/DialogManager/epic/dialogManagerEpic";
import { templateEdgeDecosEpic } from "../components/Workspace/TemplateEdgeDecorations/templateEdgeDecos";
import {
    tableEditModeEpic
} from "../components/oneweb/Table/epics/tableEditMode";
import { tinyMceStateEpic } from "../components/App/epics/tinyMceEpic/tinyMceStateEpic";
import { selectedTextStateEpic } from "../components/oneweb/Text/epics/selectedTextStateEpic";
import {
    controlsDependenciesForSelectedComponentEpic
} from "../components/Workspace/epics/controlsDependenciesForSelectedComponent";
import wrapEpic from '../components/Workspace/epics/wrap';
import { mobileViewEditorWelcomeModalEpic } from "../components/MobileViewEditor/welcomeModalDialog/epics";
import isTestEnv from "../debug/isTestEnv";
import {
    chatWidgetGlobalDataEpic
} from "../components/SiteSettings/chatWidgetTabData/chatWidgetGlobalDataEpic";
import { trackingGlobalDataEpic } from "../components/SiteSettings/trackingGlobalData/trackingGlobalDataEpic";
import { trackingServiceEpic } from "../components/SiteSettings/trackingGlobalData/trackingServiceEpic";
import { seoGlobalDataEpic } from "../components/SiteSettings/seoGlobalData/seoGlobalDataEpic";
import { socialShareGlobalDataEpic } from "../components/SiteSettings/socialShareGlobalData/socialShareGlobalDataEpic";
import { publishConfigEpic } from "../components/SiteSettings/publishConfig/publishConfigGlobalDataEpic";
import { socialGlobalDataEpic } from "../components/oneweb/Social/epics/socialGlobalDataEpic/socialGlobalDataEpic";
import { socialServiceEpic } from "../components/oneweb/Social/epics/socialServiceEpic/socialServiceEpic";
import { makeSiteSettingsEpic } from "../components/App/epics/siteSettings";
import { mobileGlobalDataEpic } from "../components/MobileViewEditor/header/mobileGlobalDataEpic";
import { environmentEpic } from '../components/App/epics/environmentEpic';
import { generalInfoServiceEpic } from "../components/SiteSettings/General/generalInfoServiceEpic";
import { globalVariablesEpic } from "../components/App/epics/globalVariablesEpic";
import { generalGlobalDataEpic } from '../components/SiteSettings/General/generalGlobalDataEpic/generalGlobalDataEpic';
import { generalInfoTabEpic } from '../components/SiteSettings/General/GeneralInfoTabEpic';
import { logoServiceEpic } from '../components/oneweb/Logo/logoServiceEpic';
import { onboardingEpic } from "../components/Onboarding/onboardingEpic";
import { dynamicOnboardingEpic } from "../components/Onboarding/Dynamic/Epic";
import { dynamicPreviewEpic } from "../components/Onboarding/Dynamic/Epic/dynamicPreviewEpic";
import { marketgooEpic } from "../components/Marketgoo";
import { onboardingTemplateSelectorEpic } from "../components/Onboarding/templateSelectorEpic";
import {
    templateSelectorTemplateLocaleEpic
} from "../components/TemplateSelector_DEPRECATED/epics/templateSelectorTemplateLocaleEpic";
import { tinyMcePickLinkedFieldEpic } from '../components/App/epics/tinyMceEpic/tinyMcePickLinkedFieldEpic';
import { allInOneEpic } from "../debug/webDevAllInOne/allInOneDialog/allInOneEpic";
import { provideJsonToDbEpic } from "../debug/webDevAllInOne/provideJsonToDb/provideJsonToDbEpic";
import { designLocalizationEpic } from '../components/App/epics/designLocalization/designLocalizationEpic';
import { emailComponentEpic } from "../components/oneweb/TextLike/Email/emailComponentConfig";
import { phoneComponentEpic } from "../components/oneweb/TextLike/Phone/phoneComponentConfig";
import { addressComponentEpic } from "../components/oneweb/TextLike/Address/addressComponentConfig";
import { colorGlobalDataEpic } from "../components/ColorPicker/epic/colorGlobalDataEpic";
import { extendedPanelTextStyleEpic } from "../components/oneweb/Text/epics/ExtendedPanelScaleStateEpic";
import { aITextEpic } from "../components/oneweb/Text/epics/AITextEpic/index";
import { generalAITextGlobalDataEpic } from '../components/oneweb/Text/epics/AITextEpic/generalAIGlobalDataEpic';
import { editAITextEpic } from "../components/oneweb/Text/epics/EditAITextEpic/index";
import { extendedPanelWidgetsEpic } from "../components/Panel/epics/widgets";
import { colorThemeSiteSettingsEpic } from "../components/SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import {
    colorThemeServiceSiteSettingsEpic
} from "../components/SiteSettings/ColorThemeData/colorThemeServiceSiteSettingsEpic";
import sectionTooltipEpic from "../components/Tooltip/epics/sectionInfoTooltip";
import modernLayoutEpic from "../components/Tooltip/epics/modernLayoutInfoTooltip";
import webshopSubscriptionEpic from "../components/oneweb/WebShop/epics/subscription";
import sectionSplitDecorationEpic from "../components/oneweb/Section/epics/splitSectionDecoration";
import { svgEpic } from '../components/oneweb/Svg/epics';
import { galleryEpic } from '../components/oneweb/Gallery/epics';
import webshopAdminDialogEpic from "../components/oneweb/WebShop/WebshopAdminDialog/epic";
import { preferencesEpic } from '../components/Preferences/preferencesEpic';
import FeaturedProductsSelectorDialogEpic from '../components/oneweb/FeaturedProducts/SelectorDialog/epic';
import { backgroundEpic } from '../components/oneweb/Background/epic';
import { getParentThemeMapEpic } from "../components/ThemeGlobalData/epics/getParentThemeMapEpic";
import { sectionIntroEpic } from "../components/oneweb/Section/epics/sectionIntroDialog";
import { GoogleReviewsDialogEpic } from "../components/oneweb/GoogleReviews/epic/index";
import { selectedComponentIsHeaderEpic } from "../components/Workspace/epics/selectedComponentIsHeaderEpic";
import { selectedComponentIsFooterEpic } from "../components/Workspace/epics/selectedComponentIsFooterEpic";
import { selectedComponentIsInsideHeaderOrFooterEpic } from "../components/Workspace/epics/selectedComponentIsInsideHeaderOrFooterEpic";
import { isModernLayoutActivatedEpic } from "../components/Workspace/epics/isModernLayoutActivatedEpic";
import {
    shareHeaderAndFirstSectionBgImgEpic
} from '../components/oneweb/Section/epics/shareHeaderAndFirstSectionBgImgEpic';
import {
    topMostPageSectionIdEpic
} from '../components/oneweb/Section/epics/topMostPageSectionIdEpic';
import {
    topMostPageSectionBackgroundImageIsSetEpic
} from '../components/oneweb/Section/epics/topMostPageSectionBackgroundImageIsSetEpic';
import {
    headerSectionIdEpic
} from '../components/oneweb/Section/epics/headerSectionIdEpic';
import {
    footerSectionIdEpic
} from '../components/oneweb/Section/epics/footerSectionIdEpic';
import {
    headerFooterSectionsPositionEpic
} from '../components/oneweb/Section/epics/headerFooterSectionsPositionEpic';
import { blankHeaderFooterEpic } from "../components/oneweb/Section/epics/blankHeaderFooter/blankHeaderFooterSelectedEpic";
import { containersExtendedPanelFilterEpic } from "../components/Panel/epics/containers";
import { hoverboxResizeEpic } from "../components/oneweb/HoverBox/epics/resizingEpic";
import { componentTierManagerEpic } from "../components/ComponentTierManager/epics";
import { businessListingSiteSettingsEpic } from "../components/SiteSettings/BusinessListing/GoogleMyBusiness/businessListingSiteSettingsEpic";
import { businessListingSiteSettingsServiceEpic } from "../components/SiteSettings/BusinessListing/GoogleMyBusiness/businessListingSiteSettingsServiceEpic";
import {
    hoveredComponentIsInsideTemplateSectionEpic
} from "../components/Workspace/epics/hoveredComponentInsideTemplateSection";
import {
    componentsInHeaderFooterSectionsEpic
} from "../components/oneweb/Section/epics/componentsInHeaderFooterSectionsEpic";
import { googleTagMangerEpic } from "../components/GoogleTagManager/epics";
import { checkTranscodeStatusEpic } from "../components/oneweb/Video/epics/checkTranscodeStatus";
import { textLimitWarningEpic } from "../components/App/epics/textLimitWarning";
import { WidgetsEpics } from '../components/oneweb/Widgets/epicsRegistry';
import { redirectReceiverEpic } from "../components/App/epics/redirectReceiverEpic";
import { WebshopOnboardStatusEpic } from "../components/oneweb/WebShop/epics/onboardStatus";
import { termsAndConditionsEpic } from "../components/SiteSettings/termsAndConditions/termsAndConditionsEpic";

// TODO WBTGEN-6261 epics registry - replace require with imports, no default exports
const epics = [
    /* APP */
    require('../components/App/epics/currentPageId').default,
    require('../components/App/epics/currentPageName').default,
    require('../components/App/epics/siteData').default,
    require('../components/App/epics/pageDataset').default,
    require('../components/App/epics/componentsClipboard').default,
    require('../components/App/epics/browserDimensions').default,
    require('../components/App/epics/appConfig').default,
    require('../components/App/epics/isKeyPressed').default,
    require('../components/App/epics/userFocus').default,
    require('../components/App/epics/auth').default,
    require('../components/App/epics/status').default,
    require('../components/App/epics/cursor').default,
    require('../components/App/epics/subscriptionData').default,
    require('../components/App/epics/ownerData').default,
    require('../components/UnsavedChanges/epic').default,
    require('../components/TopBar/epics/undoRedoButtons').default,
    require('../components/TopBar/epics/checkPublishStatus').default,
    require('../components/TopBar/epics/languages').default,
    require('../components/TopBar/epics/topBar').default,
    require('../components/WelcomeMessage/epic').default,
    require('../components/App/epics/isMouseOverLeftPanel').default,
    require('../components/App/epics/appEventCollector').default,
    require('../components/App/epics/highPageCount').default,

    /* WORKSPACE */
    controlsDependenciesForSelectedComponentEpic,
    require('../components/Workspace/epics/saveStatus').default,
    require('../components/Workspace/epics/workspaceClick').default,
    require('../components/Workspace/epics/workspaceDoubleClick').default,
    require('../components/oneweb/Template/epics/template').default,
    require('../components/oneweb/Template/epics/templateLines').default,
    require('../components/Workspace/epics/heightsToSave').default,
    require('../components/Workspace/epics/isPageMode').default,
    require('../components/Workspace/epics/componentsEval').default,
    require('../components/Workspace/epics/componentAttachements').default,
    require('../components/Workspace/epics/handles').default,
    require('../components/Workspace/epics/componentMainActions').default,
    require('../components/Workspace/epics/componentPanelActions').default,
    require('../components/Workspace/epics/scroll').default,
    require('../components/Workspace/epics/currentOpenedStylesheetId').default,
    require('../components/Workspace/epics/currentOpenedStylesheetArrayIndex').default,
    require('../components/Workspace/epics/currentOpenedStylesheet').default,
    require('../components/Workspace/epics/stylesheets').default,
    require('../components/Workspace/epics/stylesheets/idToNameMap').default,
    require('../components/Workspace/epics/status').default,
    require('../components/Workspace/epics/mousePositionWithRespectToTemplateArea').default,
    require('../components/Workspace/epics/topMostHandle').default,
    require('../components/Workspace/epics/handlesUnderMouse').default,
    require('../components/Workspace/epics/componentsSelectionDecoration').default,
    require('../components/Workspace/epics/redDecorations').default,
    require('../components/Workspace/epics/relations').default,
    require('../components/Workspace/epics/resizeDecos').default,
    require('../components/Workspace/WrapPositionControls/epic').default,
    require('../components/Workspace/epics/shiftBarDecos').default,
    require('../components/Workspace/epics/componentHoverDecorations').default,
    require('../components/Workspace/epics/componentAttachDecorations').default,
    require('../components/Workspace/epics/defaultHeaderFooterHeights').default,
    require('../components/Workspace/epics/selectionFrameDecorationEval').default,
    require('../components/Workspace/epics/selectionFrameDecoration').default,
    require('../components/Workspace/epics/rightMouseDownPositionWithRespectToTemplateArea').default,
    require('../components/Workspace/epics/workspaceBBox').default,
    require('../components/Workspace/epics/isMouseOverWorkspace').default,
    require('../components/Workspace/epics/isMouseOverMCTA').default,
    require('../components/Workspace/epics/isSliderDragging').default,
    require('../components/Workspace/epics/viewportDimensions').default,
    require('../components/Workspace/epics/snappingDecos').default,
    templateEdgeDecosEpic,
    require('../components/Workspace/epics/componentsOutsideTemplateWarning').default,
    require('../components/Workspace/epics/topmostHandlesQueue').default,
    require('../components/Workspace/CodeComponentsRenderer/epic').default,
    ...(isTestEnv() ? [] : [wrapEpic]),
    isLeftMouseDownEpic,
    dialogsManagerEpic,
    tableEditModeEpic,
    tinyMceStateEpic,
    require('../components/MissingAssets/epic').default,

    require('../components/PropertiesPanel/epic').default,

    require('../components/ContextMenu/epic').default,

    require('../components/DndAddComponent/epic').default,

    require('../components/DndFilesIntoWorkspace/epic').default,

    require('../components/SortImagesDialog/epic').default,

    require('../components/Preview/epics/preview').default,

    require('../components/ComponentsAddNotAllowedDialog/epic').default,

    require('../components/ShadowRenderer/epics/state').default,

    require('../components/StylesShadowRenderer/epics').default,

    require('../components/ColorPicker/epic').default,

    colorGlobalDataEpic,

    require('../components/OwnerEmails/epic').default,

    require('../components/TopBar/epics/popup').default,
    require('../components/TopBar/epics/logout').default,
    require('../components/TopBar/epics/treeState').default,
    require('../components/TopBar/epics/checkFreeUpgrade').default,
    require('../components/TopBar/epics/upgrade').default,
    require('../components/TopBar/epics/tutorialVideo').default,
    require('../components/TopBar/epics/chat').default,
    require('../components/TopBar/epics/keyboardShortcuts').default,
    require('../components/TopBar/epics/linkRedirect').default,

    require('../components/Banner/epic').bannerEpic,

    require('../components/Panel/epics/expanded').default,
    require('../components/Panel/epics/extendedPanel').default,
    require('../components/Panel/epics/sectionsBlocks').default,
    require('../components/Panel/epics/width').default,
    require('../components/Panel/epics/componentsKindsToDisableMap').default,
    require('../components/Panel/epics/componentsDisabledReasonMap').default,

    require('../components/PinComponentDialog/epic').default,

    require('../components/oneweb/Template/epics/templateOffset').default,
    require('../components/oneweb/Template/epics/templateRenderOffset').default,

    selectedTextStateEpic,
    require('../components/oneweb/Text/epics/onMouseLeaveMCTA').default,
    require('../components/oneweb/Text/epics/defaultStyleId').default,
    require('../components/oneweb/Text/epics/selectedTextComponentControlsDependencies').textAutoColorControlsDependenciesEpic,

    require('../components/oneweb/Menu/epics/defaultStyleId').default,
    require('../components/oneweb/Menu/epics/service').default,

    require('../components/oneweb/Button/epics/buttonMainAction').default,

    require('../components/oneweb/commonEpics/buttonDefaultStyleId').default,

    require('../components/oneweb/Video/epics/videoSearch').default,
    require('../components/oneweb/Video/epics/videoSelected').default,
    require('../components/oneweb/Video/epics/videoComponent').videoComponentEpic,

    require('../components/oneweb/ContactForm/epics/editFields').default,
    require('../components/oneweb/ContactForm/epics/captcha').default,
    require('../components/oneweb/ContactForm/epics/saveFields').default,
    require('../components/oneweb/ContactForm/epics/missingEmail').default,
    require('../components/oneweb/ContactForm/epics/setEmail').default,

    require('../components/oneweb/Code/epic').default,

    require('../components/oneweb/WebShopMHF/epic').default,

    require('../components/oneweb/Facebook/epic').default,

    require('../components/oneweb/ShareButtons/epic').default,

    require('../components/oneweb/Document/epic').documentComponentEpic,

    require('../components/Tooltip/epics/shiftbarTooltip').default,
    require('../components/Tooltip/epics/tooltip').default,
    require('../components/Tooltip/stickyTooltip/epics').default,

    require('../components/QuickTour/epics/quickTour').default,

    require('../components/TemplateSelector_DEPRECATED/epics/templatesList').default,
    require('../components/TemplateSelector_DEPRECATED/epics/preview').default,
    require('../components/TemplateSelector_DEPRECATED/epics/importTemplate').default,

    require('../components/Toaster/epic').default,
    require('../components/UnsupportedMessagesDialogs/epic').default,
    require('../view/common/Combobox/epic').default,
    require('../components/FileUploader/epic').default,

    /* PagesTree */
    require(
        '../components/PagesTree/Tree/dialogs/PageInfoDialog/epic/pageInfoDialogEpic'
    ).pageInfoDialogEpic,
    require(
        '../components/PagesTree/PageTemplateSelector/epic/pageTemplateSelectorEpic'
    ).pageTemplateSelectorEpic,
    require(
        '../components/PagesTree/AddOnecomTemplate/epic/addOnecomTemplateEpic'
    ).addOnecomTemplateEpic,
    require(
        '../components/PagesTree/sectionComponents/epic/sectionComponentsEpic'
    ).sectionComponentsEpic,
    require(
        '../components/PagesTree/NewPageDialog/epic/newPageDialogEpic'
    ).newPageDialogEpic,
    require(
        '../components/PagesTree/PageTemplateSwitch/pageTemplateSwitchEpic'
    ).pageTemplateSwitchEpic,
    require('../components/oneweb/WebShop/epics/shopPageIdsList').default,
    require('../components/oneweb/WebShop/epics/cartGlobalData').default,
    WebshopOnboardStatusEpic,
    trackingGlobalDataEpic,
    trackingServiceEpic,
    seoGlobalDataEpic,
    socialShareGlobalDataEpic,
    publishConfigEpic,
    colorThemeSiteSettingsEpic,
    colorThemeServiceSiteSettingsEpic,
    getParentThemeMapEpic,
    businessListingSiteSettingsEpic,
    businessListingSiteSettingsServiceEpic,
    chatWidgetGlobalDataEpic,

    require('../components/ImageEditor/epics').default,
    require('../components/MobileViewEditor/epics/hoveringAndSelection').default,
    require('../components/MobileViewEditor/epics/reorder').default,
    require('../components/MobileViewEditor/epics/hiddenComponents').default,
    require('../components/MobileViewEditor/epics/mobileLock').default,
    require('../components/MobileViewEditor/epics/componentExtension').default,
    require('../components/MobileViewEditor/epics/mobileEditorTips').default,
    require('../components/MobileViewEditor/epics/splitData').default,
    require('../components/MobileViewEditor/epics/componentMainActions').default,
    require('../components/App/epics/googleAnalytics/websiteSettings/siteSettingsTrackingTabAnalyticsEpic').siteSettingsTrackingTabAnalyticsEpic,
    mobileViewEditorWelcomeModalEpic,
    require('../components/DetectTableWrap/epic').default,
    require('../components/UserDataMigration/epic').userDataMigrationEpic,
    require('../components/AutoColorLeftPanel/epic/autoColorLeftPanelEpic').autoColorLeftPanelEpic,
    require('../components/ModernLayouts/epics/leftPanel').default,
    require('../components/ModernLayouts/epics/onBoardingPanel').default,
    require('../components/ModernLayouts/epics/oldData').default,
    socialServiceEpic,
    socialGlobalDataEpic,
    mobileGlobalDataEpic,
    generalGlobalDataEpic,
    generalInfoTabEpic,
    environmentEpic,
    generalInfoServiceEpic,
    globalVariablesEpic,
    logoServiceEpic,
    onboardingEpic,
    dynamicOnboardingEpic,
    dynamicPreviewEpic,
    marketgooEpic,
    redirectReceiverEpic,
    onboardingTemplateSelectorEpic,
    templateSelectorTemplateLocaleEpic,
    tinyMcePickLinkedFieldEpic,
    extendedPanelTextStyleEpic,
    aITextEpic,
    generalAITextGlobalDataEpic,
    editAITextEpic,
    termsAndConditionsEpic,
    extendedPanelWidgetsEpic,
    textLimitWarningEpic,

    emailComponentEpic,
    phoneComponentEpic,
    addressComponentEpic,

    webshopSubscriptionEpic,
    webshopAdminDialogEpic,
    FeaturedProductsSelectorDialogEpic,
    sectionTooltipEpic,
    modernLayoutEpic,
    sectionSplitDecorationEpic,
    svgEpic,
    galleryEpic,
    backgroundEpic,
    preferencesEpic,
    require('../components/oneweb/FeaturedProducts/epics/addFeaturedProducts').default,
    require('../components/oneweb/FeaturedProducts/epics/featuredProductsPageIdsList').default,
    require('../components/oneweb/FeaturedProducts/epics/subscription').default,
    require('../components/oneweb/FeaturedProducts/epics/featuredProductExists').default,
    require('../components/oneweb/FeaturedProducts/epics/updateHeightOnImageRatio').default,
    require('../components/oneweb/ProductWidget/epics/productSetup').default,
    require('../components/oneweb/ProductWidget/epics/replaceProduct').default,
    require('../components/oneweb/ProductWidget/epics/setupProductTooltip').default,
    require('../components/oneweb/WebShop/epics/featuredProducts').default,
    sectionIntroEpic,
    selectedComponentIsHeaderEpic,
    selectedComponentIsFooterEpic,
    selectedComponentIsInsideHeaderOrFooterEpic,
    isModernLayoutActivatedEpic,

    shareHeaderAndFirstSectionBgImgEpic,
    topMostPageSectionIdEpic,
    topMostPageSectionBackgroundImageIsSetEpic,
    headerSectionIdEpic,
    componentsInHeaderFooterSectionsEpic,
    footerSectionIdEpic,
    headerFooterSectionsPositionEpic,
    blankHeaderFooterEpic,
    containersExtendedPanelFilterEpic,
    hoverboxResizeEpic,
    checkTranscodeStatusEpic,
    componentTierManagerEpic,
    hoveredComponentIsInsideTemplateSectionEpic,
    ...WidgetsEpics,
    /* Debug */
    allInOneEpic,
    provideJsonToDbEpic,
    googleTagMangerEpic,
    GoogleReviewsDialogEpic
];

if (!isTestEnv()) {
    epics.push(require('../components/App/epics/analyticsDependencies').default);
    epics.push(require('../components/App/epics/analyticsGoalsTracker').default);
    epics.push(designLocalizationEpic);
}

const
    undoManagerEpicFactory = require('./undoManager/factory').default,
    siteSettingsEpic = makeSiteSettingsEpic(epics);

epics.push(siteSettingsEpic);

const undoManagerEpic = undoManagerEpicFactory({ epics });

epics.push(undoManagerEpic);

epics.reduce((acc, { valueActionType }) => {
    if (acc[valueActionType]) {
        throw new Error('Duplicate epic registration: ' + valueActionType);
    }
    return { ...acc, [valueActionType]: true };
}, {});

export {
    epics as default,
    siteSettingsEpic,
};
