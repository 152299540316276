import * as React from "react";
import cx from 'classnames';
import Measure from "react-measure";

import Image from '../../Image/view/Image';
import type { FacebookFeedGalleryImageRenderProps, Props } from '../flowTypes';
import * as styles from '../FacebookFeedGallery.css';
import * as imageStyles from '../../Image/view/Image.css';
import { Msg } from "../../../../view/intl";
import textNormalClassName from '../../Text/globalStyle/textNormalClassName';
import { replaceTagsWithContent } from '../../Text/view/replaceTagsWithContent';
import { LoadingIndicator } from "../../../../view/common/LoadingIndicator";
import { buttonViewFactory } from "../../Button/view";
import buttonStyles from '../../Button/view/Button.css';
import {
    FACEBOOK_FEED_CAROUSEL_ALBUM,
    FACEBOOK_FEED_VIDEO, LIMIT,
    CAPTION_CONTAINER_HEIGHT_MAP,
    PROFILE_IMAGE_HOLDER_SIZE
} from "../constants";
import { replaceLineBreaksWithBr } from "../../../presentational/utils";
import VerticalSpacer from "../../../../view/common/VerticalSpacer";
import { addComponentAdjustmentDataEntry } from "../../../Workspace/epics/componentsEval/adjustmentDataDispatchCache";
import { makeImageAdjustmentDataKey, getFinalDescriptionEllipsisProps } from "../utils";
import { getAssetUrl } from '../../../../utils/assetUtils';
import { FACEBOOK_FEED_GALLERY_PP_SHOW_MORE_BTN_ENABLED } from "../actionTypes";
import { SAVE } from "../../../App/actionTypes";

// used to apply script in preview and publish
const
    staticFacebookFeedGalleryClassName = 'facebook-feed-gallery',
    staticFacebookFeedGalleryCellToPopulate = 'facebook-feed-gallery-cell-to-populate',
    staticFacebookFeedGalleryPublished = 'facebook-feed-gallery-container',
    staticFacebookFeedGalleryCellClassName = 'facebook-feed-gallery-cell',
    staticFacebookFeedGalleryImageContainerClassName = 'img-container',
    staticFacebookFeedCellToShowOnShowMore = 'facebook-feed-gallery-cell-on-show-more',
    staticFacebookFeedGalleryShowMoreBtn = 'facebook-feed-gallery-show-more-btn',
    staticFacebookFeedGalleryVideoIconClassName = 'video-type-post',
    staticFacebookFeedGalleryCarouselIconClassName = 'carousel-type-post',
    staticFacebookFeedGalleryTitleDescriptionWrapperClassName = 'facebook-feed-title-description-wrapper',
    staticFacebookFeedGalleryCaptionContainerClassName = 'facebook-feed-gallery-caption',
    staticFacebookFeedGalleryCaptionTextClassName = 'facebook-feed-gallery-caption-text',
    staticFacebookFeedGalleryImageNotAvailableClassName = 'facebook-feed-image-not-available',
    staticFacebookFeedGalleryProfileImageClassName = 'facebook-feed-profileImage',
    staticFacebookFeedGalleryMainImageContainerClassName = 'facebook-feed-mainImage-container',
    staticFacebookFeedGalleryPostDateClassName = 'facebook-feed-post-date-container',
    addEllipsisIfRequiredCaption = (text) => ((text.length > LIMIT.caption.hard)
        ? `${text.substr(0, LIMIT.caption.hard)}...` : text);

class FacebookFeedGallery extends React.PureComponent<Props> {
    // @ts-ignore TODO: fix TS
    getImageEl({ divStyle = {}, imageAvailable, img, mediaType }: FacebookFeedGalleryImageRenderProps) {
        const
            { isWorkspace, isServerPreview, profileImageAsset } = this.props;

        let profileImageSrc = '';
        if (!imageAvailable && profileImageAsset) {
            profileImageSrc = getAssetUrl(
                profileImageAsset, { resize: `${PROFILE_IMAGE_HOLDER_SIZE},${PROFILE_IMAGE_HOLDER_SIZE}`, ignoreAspectRatio: true }
            );
        }
        const postIconHeightWidth = Math.min(divStyle.height * 0.1, divStyle.width * 0.1);
        return (
            <div
                className={cx(
                    staticFacebookFeedGalleryImageContainerClassName,
                )}
                style={{ height: divStyle.height, width: divStyle.width, position: "relative" }}
            >

                {
                    (imageAvailable || isServerPreview) &&
                    <Image
                        imageAvailable={imageAvailable}
                        divStyle={divStyle}
                        divClassName={cx(
                            imageStyles.imageComponent,
                            styles.image,
                            staticFacebookFeedGalleryMainImageContainerClassName
                        )}
                        imageAttributes={{
                            ...img,
                            role: "presentation"
                        }}
                        width={img.style.width}
                        height={img.style.height}
                        isWorkspace={isWorkspace}
                    />
                }
                {
                    (!imageAvailable || isServerPreview) &&
                        <div
                            className={cx(
                                styles.facebookImageNotAvailableContainer,
                                staticFacebookFeedGalleryImageNotAvailableClassName
                            )}
                            // $FlowFixMe
                            style={{
                                width: img.style.width,
                                height: img.style.height,
                                ...(isServerPreview && { display: 'none' }),
                            }}
                        >
                            {
                                profileImageSrc && <Image
                                    imageAvailable
                                    divStyle={{
                                        width: PROFILE_IMAGE_HOLDER_SIZE,
                                        height: PROFILE_IMAGE_HOLDER_SIZE
                                    }}
                                    divClassName={cx(styles.profileImage, staticFacebookFeedGalleryProfileImageClassName)}
                                    imageAttributes={{
                                        src: profileImageSrc,
                                        style: { borderRadius: '50%', width: PROFILE_IMAGE_HOLDER_SIZE, height: PROFILE_IMAGE_HOLDER_SIZE },
                                        srcSet: '',
                                        role: "presentation"
                                    }}
                                    width={PROFILE_IMAGE_HOLDER_SIZE}
                                    height={PROFILE_IMAGE_HOLDER_SIZE}
                                    isWorkspace={isWorkspace}
                                />
                            }
                            {
                                !profileImageSrc && <div className={styles.facebookImageNotAvailable} />
                            }
                        </div>
                }
                <div
                    className={cx(
                        styles.facebookFeedPostIconContainer,
                    )}
                >
                    {(mediaType === FACEBOOK_FEED_VIDEO || isServerPreview) &&
                    <div
                        className={cx(
                            styles.facebookFeedVideoIcon,
                            staticFacebookFeedGalleryVideoIconClassName,
                        )}
                        // $FlowFixMe
                        style={{
                            height: postIconHeightWidth,
                            width: postIconHeightWidth,
                            ...(isServerPreview && { display: 'none' }),
                        }}
                    />}
                    {(mediaType === FACEBOOK_FEED_CAROUSEL_ALBUM || isServerPreview) &&
                    <div
                        className={cx(
                            styles.facebookFeedCarouselIcon,
                            staticFacebookFeedGalleryCarouselIconClassName,
                        )}
                        // $FlowFixMe
                        style={{
                            height: postIconHeightWidth,
                            width: postIconHeightWidth,
                            ...(isServerPreview && { display: 'none' }),
                        }}
                    />}
                </div>
            </div>
        );
    }

    getCaptionEl({ title, caption }: FacebookFeedGalleryImageRenderProps, measureRef?: any) {
        const
            { text = '', props = { style: {} } } = caption || {},
            { text: titleText = '', props: titleProps = {} } = title || {};
        const
            { isServerPreview } = this.props;
        return (
            <div ref={measureRef}>
                <div
                    className={
                        cx(
                            styles.galleryCaption,
                            staticFacebookFeedGalleryTitleDescriptionWrapperClassName,
                            {
                                [styles.emptyCaption]: !titleText && !text
                            }
                        )
                    }
                >
                    {
                        titleText &&
                        <div className={styles.facebookCaptionTitleContainer}>
                            <div
                                className={styles.facebookCaptionTitleLogoContainer}
                            />
                            <p
                                {...titleProps}
                                className={
                                    cx(
                                        styles.galleryCaption,
                                        staticFacebookFeedGalleryPostDateClassName
                                    )
                                }
                            >
                                {replaceLineBreaksWithBr(titleText.substr(0, LIMIT.title.hard))}
                            </p>
                        </div>
                    }
                    {
                        titleText && text &&
                        <VerticalSpacer y={5} />
                    }
                    {
                        (text || isServerPreview) &&
                        <p
                            {...props}
                            className={cx(styles.galleryCaption, staticFacebookFeedGalleryCaptionTextClassName)}
                            style={{
                                ...props.style,
                                width: '100%',
                            }}
                        >
                            {replaceLineBreaksWithBr(addEllipsisIfRequiredCaption(text))}
                        </p>
                    }
                </div>
            </div>
        );
    }

    getImageCells() {
        const
            {
                images,
                captionsEnabled,
                globalVariables,
                isServerPreview,
                isWorkspace,
                columns,
                rows,
                captionsContainerHeight,
                componentId
            } = this.props,
            maxVisibleImagesBeforeShowMore = columns * rows,
            imageCells: React.ReactElement<any>[] = images.map((image: FacebookFeedGalleryImageRenderProps, index) => {
                const isCellToBeShownAfterShowMoreBtnClick = (index + 1) > maxVisibleImagesBeforeShowMore;
                if (isWorkspace && isCellToBeShownAfterShowMoreBtnClick) {
                    return '';
                }
                const anchorProps = image.a;
                if (anchorProps && anchorProps.href) {
                    anchorProps.href = replaceTagsWithContent(anchorProps.href, { globalVariables }, this.props.isWorkspace);
                }
                return (
                    <div
                        key={index}
                        data-index={index}
                        className={cx(
                            styles.facebookFeedGalleryCell,
                            staticFacebookFeedGalleryCellClassName,
                            {
                                [staticFacebookFeedGalleryCellToPopulate]: isServerPreview,
                                [staticFacebookFeedCellToShowOnShowMore]: isCellToBeShownAfterShowMoreBtnClick,
                            }
                        )}
                        {...image.container}
                        style={{
                            ...image.container.style,
                            ...(isServerPreview && { display: 'none' }),
                            ...(isCellToBeShownAfterShowMoreBtnClick && { display: 'none' }),
                        }}
                    >
                        <a {...anchorProps}>
                            {this.getImageEl(image)}
                            {
                                captionsEnabled && (
                                    <div
                                        className={cx(staticFacebookFeedGalleryCaptionContainerClassName)}
                                        style={{
                                            height: captionsContainerHeight,
                                            minHeight: CAPTION_CONTAINER_HEIGHT_MAP[columns],
                                            ...image.captionWrapperStyle,
                                            ...this.props.captionBoxStyles
                                        }}
                                    >
                                        <div className="textnormal">
                                            <div className={styles.galleryCaptionContainer}>
                                                {this.getCaptionEl(image)}
                                            </div>
                                            {
                                                isWorkspace && (
                                                    <div style={{ visibility: 'hidden' }}>
                                                        <Measure
                                                            offset
                                                            onResize={({ offset: { height } }) => {
                                                                addComponentAdjustmentDataEntry(
                                                                    componentId,
                                                                    { [makeImageAdjustmentDataKey(index)]:
                                                                            Math.round(height) }
                                                                );
                                                            }}
                                                            key={index}
                                                        >
                                                            {({ measureRef }) => this.getCaptionEl(image, measureRef)}
                                                        </Measure>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </a>
                    </div>
                );
            });

        return imageCells;
    }

    getMobileStyles() {
        const { mobileSettings, componentId: id, captionDescriptionLineHeight, captionDescriptionFontSize } = this.props;
        if (mobileSettings) {
            let
                { columns, spacingPx } = mobileSettings;
            if (columns === 1) {
                const { finalViewHeight, webkitLineClamp } = getFinalDescriptionEllipsisProps({
                    desiredHeight: 120,
                    captionDescriptionLineHeight,
                    captionDescriptionFontSize
                });
                return `
                .mobileV div[data-id='${id}'] .facebook-feed-gallery .facebook-feed-gallery-caption {
                   width: 100% !important;
                   height: 198px !important;
                }
                .mobileV div[data-id='${id}'] .facebook-feed-gallery .facebook-feed-gallery-caption-text {
                   height: ${finalViewHeight}x !important;
                   display: -webkit-box !important;
                   -webkit-line-clamp: ${webkitLineClamp} !important;
                   -webkit-box-orient: vertical !important;
                   overflow: hidden !important;
                }
                .mobileV div[data-id='${id}'] .facebook-feed-gallery .facebook-feed-gallery-cell {
                    padding: 0 0 ${spacingPx}px 0 !important;
                    width: 100% !important;
                    height: 500px !important;
                    margin-bottom: 10px !important;
                }
                .mobileV div[data-id='${id}'] .facebook-feed-gallery img {
                    height: 303px !important;
                }
                .mobileV div[data-id='${id}'] .facebook-feed-gallery .facebook-feed-image-not-available {
                    height: 303px !important;
                    width: 303px !important;
                }
                .mobileV div[data-id='${id}'] .facebook-feed-gallery .facebook-feed-image-not-available .facebook-feed-profileImage img {
                    height: 65px !important;
                    width: 65px !important;
                    margin-left: auto;
                    margin-right: auto;
                }
                .mobileV div[data-id='${id}'] .facebook-feed-gallery .facebook-feed-gallery-cell:last-child{
                    padding: 0 !important;
                }`;
            }
            const { finalViewHeight, webkitLineClamp } = getFinalDescriptionEllipsisProps({
                desiredHeight: 103,
                captionDescriptionLineHeight,
                captionDescriptionFontSize
            });

            spacingPx = spacingPx / columns; //spacing should be shared between columns
            return `
                .mobileV div[data-id='${id}'] .facebook-feed-gallery .facebook-feed-gallery-caption {
                   width: 100% !important;
                   height: 180px !important;
                }
                .mobileV div[data-id='${id}'] .facebook-feed-gallery .facebook-feed-gallery-caption-text {
                   height: ${finalViewHeight}px !important;
                   display: -webkit-box !important;
                   -webkit-line-clamp: ${webkitLineClamp} !important;
                   -webkit-box-orient: vertical !important;
                   overflow: hidden !important;
                }
                .mobileV div[data-id='${id}'] .facebook-feed-gallery .facebook-feed-gallery-cell {
                    width: calc(50% - ${spacingPx + columns}px) !important;
                }
                .mobileV div[data-id='${id}'] .facebook-feed-gallery .facebook-feed-gallery-cell:nth-child(2n+1) {
                    margin: ${spacingPx}px ${spacingPx}px ${spacingPx}px 0 !important;
                }
                .mobileV div[data-id='${id}'] .facebook-feed-gallery .facebook-feed-gallery-cell:nth-child(2n+2) {
                    margin: ${spacingPx}px 0 ${spacingPx}px ${spacingPx}px !important;
                }
                .mobileV div[data-id='${id}'] .facebook-feed-gallery .facebook-feed-gallery-cell:first-child {
                    margin: 0 ${spacingPx}px ${spacingPx}px 0 !important;
                }
                .mobileV div[data-id='${id}'] .facebook-feed-gallery .facebook-feed-gallery-cell:nth-child(2) {
                    margin: 0 0 ${spacingPx}px ${spacingPx}px !important;
                }
                .mobileV div[data-id='${id}'] .facebook-feed-gallery img {
                    height: 145.6px !important;
                }
                .mobileV div[data-id='${id}'] .facebook-feed-gallery .facebook-feed-image-not-available {
                    height: 145.6px !important;
                    width: 145.6px !important;
                }
                .mobileV div[data-id='${id}'] .facebook-feed-gallery .facebook-feed-image-not-available .facebook-feed-profileImage img {
                    height: 65px !important;
                    width: 65px !important;
                    margin-left: auto;
                    margin-right: auto;
                }
                .mobileV div[data-id='${id}'] .facebook-feed-gallery {
                    margin-bottom: 0px !important;
                }`;
        }
        return '';
    }

    render() {
        const
            {
                captionsEnabled,
                isWorkspace,
                measureRef,
                isSocialAccountsFacebookFeedAssetsLoading,
                isServerPreview,
                showMoreBtn,
                globalStyleClass,
                fontSizePx,
                images,
                showMoreTxt,
                dispatch
            } = this.props,
            imagesCells = this.getImageCells(),
            zeroImages = imagesCells.length === 0,
            cn = cx(
                staticFacebookFeedGalleryClassName,
                styles.facebookFeedGallery,
                textNormalClassName,
                {
                    [styles.zeroImages]: zeroImages,
                    [staticFacebookFeedGalleryPublished]: isServerPreview,
                }
            );

        // Fix show more button txt state which do not have any value set.
        if (dispatch && !showMoreTxt && showMoreBtn) {
            dispatch({
                type: FACEBOOK_FEED_GALLERY_PP_SHOW_MORE_BTN_ENABLED,
                payload: {
                    isEnabled: showMoreBtn,
                }
            });
            dispatch({
                type: SAVE
            });
        }

        if (zeroImages && !isWorkspace) {
            return <div />;
        }

        if (isSocialAccountsFacebookFeedAssetsLoading) {
            return <div className={styles.loadingIcon}><LoadingIndicator /></div>;
        }

        const ButtonView = buttonViewFactory({ style: { fontSize: fontSizePx, minWidth: 130, minHeight: 50 } });
        const autoColorModeProps = (
            this.props.themeSettingsData.autoColorMode
                ? {
                    autoColorMode: true,
                    selectedParentTheme: this.props.selectedParentTheme,
                    buttonThemeSelected: this.props.buttonThemeSelected,
                }
                : {}
        );

        return ([
            <style key={0} type="text/css" dangerouslySetInnerHTML={{ __html: this.getMobileStyles() }} />,
            <div key={1} className={cn} ref={measureRef} data-captionenabled={!!captionsEnabled}>
                {
                    zeroImages ?
                        <Msg k="component.facebookFeedGallery.noImagesAvailable">No images available</Msg> : imagesCells
                }
                {
                    showMoreBtn && images.length > 0 &&
                        <div
                            className={cx(
                                styles.buttonPreview,
                                staticFacebookFeedGalleryShowMoreBtn,
                            )}
                            // $FlowFixMe
                            style={{
                                ...(isServerPreview && { display: 'none' })
                            }}
                        >
                            <ButtonView
                                text={showMoreTxt || 'Show more'}
                                styles={buttonStyles}
                                globalStyleClass={globalStyleClass}
                                {...autoColorModeProps}
                            />
                        </div>
                }
            </div>
        ]);
    }
}

export default FacebookFeedGallery;
