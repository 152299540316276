import React from "react";
import TextIconButton from "../TextIconButton/factory";
import type { BackgroundButtonPropTypes } from "./flowTypes";

export default ({ dialogId, dispatch, ...rest }: BackgroundButtonPropTypes) => (
    <TextIconButton
        text="msg: common.background {Background}"
        dialogId={dialogId}
        dialogProps={{ ...rest, dispatch }}
        dispatch={dispatch}
    />
);
