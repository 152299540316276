import makePages from '../../../PropertiesPanel/view/makePages';
import * as mobileView from '../../../PropertiesPanel/view/MobileView/page';
import reducer from '../reducer';
import * as main from './pages/main';
import * as onClick from './pages/onClick';
import * as captions from './pages/captions';
import * as fullWidth from './pages/fullWidth';
import * as captionBoxStyle from './pages/captionBoxStyle';
import * as imageBorder from './pages/imageStyles';
import * as captionTitleStyle from './pages/captionTitleStyle';
import * as captionDescriptionStyle from './pages/captionDescriptionStyle';
import addGoogleFont from "../../../PropertiesPanel/view/AddGoogleFont/index";

const propertiesPanelConfig = {
    pages: makePages(
        main,
        onClick,
        captions,
        imageBorder,
        fullWidth,
        captionBoxStyle,
        captionTitleStyle,
        captionDescriptionStyle,
        addGoogleFont,
        mobileView
    ),
    defaultPageId: main.id,
    reducer
};

export default propertiesPanelConfig;
