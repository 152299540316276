import { HEAD_CODE_BEGIN_TEXT, HEAD_CODE_END_TEXT } from "../../oneweb/Code/view/preview";

const processHTMLForHeadCodeComponent = (generatedHTMLText: string) => {
    /**
     * Reference Regex:
     * let processedText =
     * generatedHTMLText.replace(/<div([^>]*)>(<!--#begin_code_headCodeComponentItems-->([\s\S]*?)<!--#end_code_headCodeComponentItems-->)<\/div>/g, function ($0, $1, $2, codeComponent) {
     **/

    let
        pattern = `<div([^>]*)>(` + HEAD_CODE_BEGIN_TEXT + `([\\s\\S]*?)` + HEAD_CODE_END_TEXT + `)<\\/div>`,
        regexExp = new RegExp(pattern, "g"),
        processedText = generatedHTMLText.replace(regexExp, function ($0, $1, $2, codeComponent) {
            return codeComponent;
        });

    return processedText;
};

export {
    processHTMLForHeadCodeComponent
};
