import * as R from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import { WRAP_POSITION_CONTROLS_VAT } from './valueActionType';
import { ComponentsEvalValueActionType } from '../../epics/componentsEval/valueActionType';
import {
    selectedComponentIdSelector, componentsMapSelector, userInteractionModeSelector,
} from '../../epics/componentsEval/selectorActionTypes';
import { editModeComponentIdSelector } from '../../epics/componentsEval/selectors';
import { IDLE } from '../../epics/componentsEval/userInteractionMutations/interactionModes';
import { getWrapOptions } from '../../epics/wrap/wrapOptions';
import { workspaceHandlesValueActionType } from '../../epics/handles/valueActionType';
import { ResizeSW } from '../../../../utils/handle/kinds';
import { receiveOnly } from '../../../../epics/makeCondition';
import { HandleHeightPx, HandleWidthPx } from '../../../../utils/handle/resize';

import type { WrapPositionControlsState } from '../flowTypes';

export const WPC_BUTTON_EDGE_LENGTH = 26;

const
    originPos = { x: 0, y: 0 },
    defaultState: WrapPositionControlsState = {
        isVisible: false,
        wrapOptions: { above: false, below: false, left: false, right: false },
        wpcButtonPositions: { above: originPos, below: originPos, left: originPos, right: originPos },
    },
    GUTTER_FROM_COMPONENT = 11,
    GUTTER_BETWEEN_BUTTONS = 3,
    ADDITIONAL_GUTTER_AFTER_ROW0 = 7,
    getPivot = (handleBBox) => (
        { x: handleBBox.left + (HandleWidthPx / 2) - GUTTER_FROM_COMPONENT, y: handleBBox.top + (HandleHeightPx / 2) }
    ),
    getButtonStyle = (pivot, row, col, additionalVerticalGutter = 0) => {
        //                 row2,col0
        //                 row1,col0
        // row0,col1       row0,col0       pivot
        return {
            x: pivot.x - WPC_BUTTON_EDGE_LENGTH - (col * (GUTTER_BETWEEN_BUTTONS + WPC_BUTTON_EDGE_LENGTH)),
            y: pivot.y - WPC_BUTTON_EDGE_LENGTH - additionalVerticalGutter - (row * (GUTTER_BETWEEN_BUTTONS + WPC_BUTTON_EDGE_LENGTH)), // eslint-disable-line
        };
    };

export default makeEpic({
    defaultState,
    valueActionType: WRAP_POSITION_CONTROLS_VAT,
    updaters: [{
        conditions: [
            ComponentsEvalValueActionType,
            receiveOnly(workspaceHandlesValueActionType),
        ],
        reducer: ({ state, values: [componentsEval, workspaceHandles] }) => {
            const selectedComponentId = selectedComponentIdSelector(componentsEval),
                componentsMap = componentsMapSelector(componentsEval),
                selectedComponent = componentsMap[selectedComponentId],
                editModeComponentId = editModeComponentIdSelector(componentsEval),
                resizeSwHandle = workspaceHandles.find(R.propEq('kind', ResizeSW)),
                mode = userInteractionModeSelector(componentsEval);

            if (!selectedComponent || selectedComponentId === editModeComponentId || !resizeSwHandle || mode !== IDLE) {
                return { state: defaultState };
            }

            const isVisible = R.propOr(false, 'wrap', selectedComponent),
                wrapOptions = getWrapOptions(componentsMap, [selectedComponent.id]),
                pivot = getPivot(resizeSwHandle.bBox),
                wpcButtonPositions = {
                    above: getButtonStyle(pivot, 2, 0, ADDITIONAL_GUTTER_AFTER_ROW0),
                    below: getButtonStyle(pivot, 1, 0, ADDITIONAL_GUTTER_AFTER_ROW0),
                    left: getButtonStyle(pivot, 0, 1),
                    right: getButtonStyle(pivot, 0, 0),
                };

            return { state: { ...state, isVisible, wrapOptions, wpcButtonPositions } };
        },
    }],
});
