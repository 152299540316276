import { $Diff } from "utility-types";
import * as React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { composeStylesTheme } from "@sepo27/react-redux-lib";
import styles from './DemoTip.css';
import type { TooltipProps } from '../../../src/components/presentational/Tooltip/TooltipCom';
import { TooltipCom } from '../../../src/components/presentational/Tooltip/TooltipCom';
import type { ReactElementRef } from '../../../src/globalTypes';
import { Intl, injectIntl, isMsgJointInput } from '../../../src/view/intl/index';
import type { AppState } from '../../../src/redux/modules/flowTypes';
import { isDemoSubscriptionType } from '../../../src/components/App/epics/subscriptionData/isDemoSubscriptionType';
import { DemoTipHandler } from './DemoTipHandler';
import { TooltipPosition } from "../../../src/components/presentational/Tooltip/constants";
import type { MsgJointInput } from "../../../src/view/intl/index";

const DEFAULT_TIP_MSG = 'msg: demo.restrictedFeature {This feature is not available in the trial version.}';

const TipChildren = injectIntl(({ children = DEFAULT_TIP_MSG, intl }) => (
    isMsgJointInput(children) ? <span>{intl.msgJoint(children)}</span> : children
));

type DemoTipTheme = {
    container?: string,
    pointer?: string,
    tipInfo?: string,
    closeBtn?: string
}
type Props = $Diff<TooltipProps, {
    show: boolean,
    targetRef: ReactElementRef<any>,
    intl: Intl,
}> & {
    isDemo: boolean,
    isDisabled?: boolean,
    autoHide?: boolean,
    tipChildren?: React.ReactNode | MsgJointInput,
    tipTheme?: DemoTipTheme,
    hideOnTargetLeave?: boolean,
    children: any
};

export class DemoTipClass extends React.Component<Props> {
    handlerRef: React.RefObject<DemoTipHandler>;
    targetRef: ReactElementRef<any>;
    timer: null | undefined | ReturnType<typeof setTimeout>;

    constructor() {
        // @ts-ignore
        super();
        this.handlerRef = React.createRef();
        this.targetRef = React.createRef();
    }

    onTargetClick = (e: React.MouseEvent<any>) => {
        e.stopPropagation();

        const handler = this.getHandler();
        if (handler) {
            handler.show();
        }
    }

    hide = () => {
        const handler = this.getHandler();
        if (handler) {
            handler.hide();
        }
    }

    getHandler(): null | DemoTipHandler {
        return this.handlerRef.current;
    }

    render() {
        if (this.props.isDisabled || !this.props.isDemo) {
            return this.props.children;
        }

        const
            {
                children: propChildren,
                position = TooltipPosition.BOTTOM,
                tipChildren,
                tipTheme: propsTheme = {},
                autoHide = false,
                hideOnTargetLeave,
            } = this.props,
            children = React.Children.map(propChildren, (child) => {
                const props = {
                    ref: this.targetRef,
                    onClick: this.onTargetClick,
                    onMouseLeave: hideOnTargetLeave && this.hide,
                    // make sure anchors are not triggered
                    // @ts-ignore
                    ...(child.props.href ? { href: null } : {}),
                };
                // @ts-ignore
                return React.cloneElement(child, props);
            });

        return (
            <React.Fragment>
                {children}
                <DemoTipHandler
                    autoHide={autoHide}
                    render={
                        ({ show, ...renderProps }) => (
                            <TooltipCom
                                show={show}
                                targetRef={this.targetRef}
                                position={position}
                                hideOnTargetLeave
                                theme={composeStylesTheme(styles, {
                                    container: propsTheme.container,
                                    pointer: propsTheme.pointer,
                                    tipInfo: propsTheme.tipInfo
                                })}
                                {...renderProps}
                            >
                                <React.Fragment>
                                    <span className={cx(styles.closeBtn, propsTheme.closeBtn)} onClick={this.hide} />
                                    <TipChildren>{tipChildren}</TipChildren>
                                </React.Fragment>
                            </TooltipCom>
                        )
                    }
                    ref={this.handlerRef}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (appState: AppState) => ({
    isDemo: isDemoSubscriptionType(appState.subscriptionData),
});

export const DemoTip = connect(mapStateToProps)(DemoTipClass);
