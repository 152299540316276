import React from 'react';
import { connect } from "react-redux";
import styles from "./PaidShutterstock.css";
import { buyPaidShutterstockImageAction } from "../actions";
import { isDemoSubscriptionTypeAppSel } from "../../../../../demo/modules/selectors";
import type { AppState } from "../../../../redux/modules/flowTypes";

type Props = {
    dispatch: Dispatch,
    id?: string,
    hide: boolean,
    price: string | null,
    onSaveAction?: string,
    isMultiSelect?: boolean,
    isDemo: boolean,
};

export class ShutterstockBuyButtonCom extends React.Component<Props> {
    onClick = () => this.props.dispatch(buyPaidShutterstockImageAction({
        id: this.props.id,
        onSaveAction: this.props.onSaveAction,
        isMultiSelect: this.props.isMultiSelect
    }));

    render() {
        if (this.props.isDemo) {
            return null;
        }

        return (
            !this.props.hide && <span className={styles.buyButton} onClick={this.onClick}>{this.props.price}</span>
        );
    }
}

const mapStateToProps = (appState: AppState) => ({
    isDemo: isDemoSubscriptionTypeAppSel(appState),
});

export const ShutterstockBuyButton = connect(mapStateToProps)(ShutterstockBuyButtonCom);
