import { $Values } from "utility-types";
import { identity } from 'ramda';
import { BROKEN_LINK } from "../../../../../components/oneweb/Text/constants";
import { LcTabName } from "../constants";
import type { LinkChooserLinkType } from "../flowTypes";
import { getDAL } from "../../../../../../dal/index";
import { DataSite } from "../../../../../../dal/model/index";
import { buildMailTo } from './buildMailTo';
import Resource from "../../../../../redux/modules/children/fileChooser/Resource";
import { makeTelUrl } from '../../../../../utils/TelProtocol';
import findPageRecursively from '../../../../../components/oneweb/Link/utils/findPageRecursively';

type linkTypeToAbsoluteUrlGetterMapType = {
    [k in $Values<typeof LcTabName>]: (value: any, site: DataSite, previewBackupTime?: number) => string | null | undefined;
};

const linkTypeToAbsoluteUrlGetterMap: linkTypeToAbsoluteUrlGetterMapType = {
    [LcTabName.PAGE]: (value, site, previewBackupTime) => {
        if (value !== BROKEN_LINK) {
            try {
                const page = findPageRecursively(site.folder.items, value);
                if (page && page.public) {
                    const query = previewBackupTime ? { previewbackup: true, time: previewBackupTime } : {};
                    return getDAL().makePagePreviewUrl(page.pageId, 'html', query);
                }
            } catch (e: any) {} //eslint-disable-line
        }
        return null;
    },
    [LcTabName.FILE]: value => {
        const { basePath, getWebSpaceProtocolRelativePath, href } = value;
        return getWebSpaceProtocolRelativePath ?
            value.getFullImagePath() :
            (new Resource(basePath, { href })).getFullImagePath();
    },
    [LcTabName.URL]: identity,
    [LcTabName.PHONE]: makeTelUrl,
    [LcTabName.EMAIL]: ({ selection: email, subject }) => buildMailTo({ email, subject }),
    [LcTabName.LOCATION]: ({ selection }) => selection,
    [LcTabName.SECTION_LINK]: ({ pageId, sectionId }, site, previewBackupTime) => {
        if (pageId && sectionId) {
            try {
                const page = findPageRecursively(site.folder.items, pageId, 'pageId');
                if (page && page.public) {
                    const query = previewBackupTime ? { previewbackup: true, time: previewBackupTime } : {};
                    const pageHref = getDAL().makePagePreviewUrl(page.pageId, 'html', query);
                    return `${pageHref}${sectionId ? `#${sectionId}` : ''}`;
                }
            } catch (e: any) {} //eslint-disable-line
        }
        return null;
    },
};

export default (link: LinkChooserLinkType, site: DataSite, previewBackupTime?: number): null | undefined | string => {
    const { type, value } = link,
        absoluteUrlGetter = linkTypeToAbsoluteUrlGetterMap[type];

    return absoluteUrlGetter ? absoluteUrlGetter(value, site, previewBackupTime) : null;
};
