/* eslint-disable max-len */

import React from "react";

import pagesTitles from "./titles";
import * as pagesIds from "./ids";
import * as actionTypes from "../../actionTypes";
import { MAX_COLOR_SUPPORTED } from '../../constants';
import styles from '../../styles.css';

import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import OpacitySlider from '../../../../presentational/OpacitySlider/index';
import { toHsl } from '../../../../../../dal/pageMapAdapter/mappers/Base/color';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import { injectIntl } from "../../../../../view/intl/index";
import { ColoredSwatch } from '../../../../ColorPicker/view/ColoredSwatch';
import { showColorPickerAction } from '../../../../ColorPicker/actionCreators';
import { getPosition } from '../../../../../utils/mouse';
import Separator from '../../../../presentational/PropertiesPanel/Separator';
import { getThemeRulesForSvg } from '../../../../ThemeGlobalData/themeRules';
import { THEME_COLOR_PICKER, THEME_COLOR_OVERRIDE_COLOR_PICKER, INVALID_THEME_COLOR } from '../../../../ColorPicker/constants';
import { BLACK_THEME_COLOR } from '../../../../ThemeGlobalData/constants';

import type { PPPageViewPropTypes } from '../../flowTypes';
import { Color } from "../../../../../mappers/flowTypes";

const
    id = pagesIds.COLOR,
    title = pagesTitles[pagesIds.COLOR],
    view = injectIntl((props: PPPageViewPropTypes) => {
        const {
            intl,
            dispatch,
            themeColorsData,
            themeSettingsData: { autoColorMode },
            selectedComponentProps: { selectedParentTheme },
            selectedComponent: { colors, opacity, useOriginalColors },
        } = props;

        return (
            <Page>
                {(colors.length > 0 && colors.length <= MAX_COLOR_SUPPORTED) && (
                    <React.Fragment>
                        <VerticalSpacer />
                        <div style={{ display: 'flex', flexWrap: 'wrap', marginLeft: -8, width: 220 }}>
                            {(
                                autoColorMode ? getThemeRulesForSvg(selectedParentTheme, colors, themeColorsData) : colors
                            ).map(({ fromColor, toColor, toThemeColor }) => {
                                const toColorHSL = toHsl(toColor) as Color;
                                const coloredSwatchProps = { key: fromColor, color: toColorHSL, selected: false };
                                const makeCommonShowColorPickerActionProps =
                                    (e) => ({ position: getPosition(e), additionalPayload: { fromColor } });

                                let ColoredSwatchView;
                                if (autoColorMode && colors.length === 1) {
                                    ColoredSwatchView = <ColoredSwatch
                                        {...coloredSwatchProps}
                                        {...(useOriginalColors ? { color: toHsl(fromColor) as Color } : {})}
                                        onClick={(_, e) => dispatch(
                                            showColorPickerAction({
                                                ...makeCommonShowColorPickerActionProps(e),
                                                colorPickerKind: THEME_COLOR_PICKER,
                                                themeColor: useOriginalColors ? INVALID_THEME_COLOR : colors[0].toThemeColor,
                                                themeColorsData,
                                                defaultThemeColor: getThemeRulesForSvg(
                                                    selectedParentTheme,
                                                    colors.map(({ fromColor, toColor }) => ({ fromColor, toColor })),
                                                    themeColorsData,
                                                )[0].toThemeColor || BLACK_THEME_COLOR,
                                                onChangeAction: actionTypes.SVG_COLOR_SWATCH_ITEM_CHANGED_AUTO_COLOR,
                                            })
                                        )}
                                    />;
                                } else if (autoColorMode) {
                                    ColoredSwatchView = <ColoredSwatch
                                        {...coloredSwatchProps}
                                        onClick={(_, e) => dispatch(
                                            showColorPickerAction({
                                                ...makeCommonShowColorPickerActionProps(e),
                                                colorPickerKind: THEME_COLOR_OVERRIDE_COLOR_PICKER,
                                                themeColor: toThemeColor,
                                                themeColorsData,
                                                onChangeAction: actionTypes.SVG_COLOR_SWATCH_ITEM_CHANGED_AUTO_COLOR,
                                            })
                                        )}
                                    />;
                                } else { // autoColorMode === false
                                    ColoredSwatchView = <ColoredSwatch
                                        {...coloredSwatchProps}
                                        onClick={(_, e) => dispatch(
                                            showColorPickerAction({
                                                ...makeCommonShowColorPickerActionProps(e),
                                                color: toColorHSL,
                                                onChangeDebounced: { action: actionTypes.SVG_COLOR_SWATCH_ITEM_CHANGED, timeout: 100 },
                                                onTheFlyChange: true,
                                            })
                                        )}
                                    />;
                                }

                                return (
                                    <div key={fromColor} style={{ padding: 8 }}>
                                        {ColoredSwatchView}
                                    </div>
                                );
                            })}
                        </div>
                    </React.Fragment>
                )}
                {(colors.length > MAX_COLOR_SUPPORTED) && (
                    <React.Fragment>
                        <VerticalSpacer />
                        {intl.msgJoint('msg: component.svg.pp.tooManyColors {The colour editor is not available as the SVG has too many colours.}')}
                    </React.Fragment>
                )}
                <VerticalSpacer />
                <OpacitySlider
                    opacity={opacity}
                    onChange={opacity => dispatch({ type: actionTypes.SVG_SET_OPACITY, payload: opacity })}
                />
                {(colors.length > 0 && colors.length <= MAX_COLOR_SUPPORTED) && (
                    <React.Fragment>
                        <Separator />
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <a
                                className={styles.ppLink}
                                onClick={() => dispatch({
                                    type: autoColorMode ? actionTypes.SVG_RESET_COLORS_AUTO_COLOR : actionTypes.SVG_RESET_COLORS
                                })}
                            >
                                {intl.msgJoint('msg: component.svg.pp.resetColors {Reset to original colours}')}
                            </a>
                        </div>
                    </React.Fragment>
                )}
            </Page>
        );
    });

export { id, view, title };
