import { makeEpic } from "../../../epics/makeEpic";
import * as fileChooserTypes from "../../../redux/modules/children/fileChooser/actionTypes";
import { fileUploadValueActionType } from "./valueActionType";
import * as actionTypes from "../actions";
import {
    fcInit,
    uploadFromComputerAction
} from "../../../redux/modules/children/fileChooser/actionCreators/index";
import { fileUploadCompleteAction, uploadResourceStatusAction } from "../actionCreators";

const defaultState = {
        resources: [],
        uploadInProgress: false,
    },
    defaultScope = {
        files: [],
        isMultiSelect: false,
        contentTypes: '',
        uploadInProgress: false,
    };

export default makeEpic({
    defaultState,
    defaultScope,
    valueActionType: fileUploadValueActionType,
    updaters: [
        {
            conditions: [actionTypes.FU_UPLOAD_FILES],
            reducer: ({ values: [{ files, isMultiSelect, contentTypes, ...rest }], state }) => ({
                state: { ...defaultState, ghostResources: state.ghostResources },
                scope: { ...defaultScope, files, isMultiSelect, contentTypes, uploadInProgress: true },
                actionToDispatch: fcInit({
                    isMultiSelect,
                    contentTypes,
                    preSelection: [],
                    ...rest,
                })
            })
        },
        {
            conditions: [fileChooserTypes.FC_INITIALIZED],
            reducer: ({ state, scope }) => {
                if (scope.uploadInProgress) {
                    return { state, scope, actionToDispatch: uploadFromComputerAction(scope.files) };
                }
                return { state, scope };
            }
        },
        {
            conditions: [fileChooserTypes.FC_RESOURCES_SELECTED],
            reducer: ({ values: [{ resources }], state, scope }) => {
                if (scope.uploadInProgress) {
                    return {
                        state: { ...state, resources },
                        scope,
                        actionToDispatch: uploadResourceStatusAction(actionTypes.FU_SELECTED_FILES, resources)
                    };
                }
                return { state, scope };
            }
        },
        {
            conditions: [fileChooserTypes.FC_COMPUTER_UPLOAD_STARTED],
            reducer: ({ state, scope }) => {
                return { state: { ...state, uploadInProgress: true }, scope };
            }
        },
        {
            conditions: [fileChooserTypes.FC_COMPUTER_UPLOAD_FINISHED],
            reducer: ({ state, scope }) => {
                return { state: { ...state, uploadInProgress: false }, scope };
            }
        },
        ...[
            fileChooserTypes.FC_RESOURCE_UPLOADING,
            fileChooserTypes.FC_RESOURCE_UPLOADING_SUCCESS
        ].map((type) => {
            return {
                conditions: [type],
                reducer: ({ values: [{ resource }], state, scope }) => {
                    if (scope.uploadInProgress) {
                        const
                            action = type === fileChooserTypes.FC_RESOURCE_UPLOADING ?
                                actionTypes.FU_UPLOADING_FILE :
                                actionTypes.FU_UPLOADING_FILE_SUCCESS,

                            resources = state.resources.map((res) => {
                                if (resource.id === res.id) {
                                    return resource;
                                }
                                return res;
                            });
                        return {
                            scope,
                            state: { ...state, resources },
                            actionToDispatch:
                                uploadResourceStatusAction(action, resources)
                        };
                    }
                    return { state, scope };
                }
            };
        }),
        {
            conditions: [fileChooserTypes.FC_RESOURCE_UPLOADING_FAILED],
            reducer: ({ values: [{ resource }], state, scope }) => {
                if (scope.uploadInProgress) {
                    const resources = state.resources.map((res) => {
                        if (resource.id === res.id) {
                            return { ...resource, isLoading: false };
                        }
                        return res;
                    });
                    return {
                        state: {
                            ...state,
                            resources
                        },
                        scope,
                        actionToDispatch: uploadResourceStatusAction(actionTypes.FU_UPLOADING_FILE_FAILED, resources)
                    };
                }
                return { state, scope };
            }
        },
        {
            conditions: [fileChooserTypes._FC_SET_SELECTION],
            reducer: ({ values: [{ selection }], state, scope }) => {
                if (scope.uploadInProgress) {
                    return {
                        state,
                        scope: defaultScope,
                        actionToDispatch: fileUploadCompleteAction(selection)
                    };
                }
                return { state, scope };
            }
        },
        {
            conditions: [fileChooserTypes.FC_RESOURCE_UPLOAD_CANCELED],
            reducer: ({ state, scope }) => {
                if (scope.uploadInProgress) {
                    return {
                        state: { ...state, uploadInProgress: false },
                        scope: defaultScope,
                        actionToDispatch: { type: actionTypes.FU_UPLOAD_CANCELED },
                    };
                }
                return { state: { ...state, uploadInProgress: false }, scope };
            }
        },
    ]
});
