import React from "react";
export const SvgYelpSquareFillMulticolor = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <path fill="#d52018" d="M0 0h48v48H0z" />
            <path
                fill="#ffffff"
                d="M26.767 26.703c-.415.42-.064 1.181-.064 1.181l3.12 5.22s.512.688.956.688c.446 0 .887-.367.887-.367l2.466-3.532s.249-.446.255-.837c.008-.555-.827-.707-.827-.707l-5.84-1.879s-.572-.152-.953.233zm-.296-2.63c.299.507 1.123.36 1.123.36l5.827-1.707s.794-.324.907-.755c.112-.432-.13-.952-.13-.952l-2.785-3.287s-.242-.415-.742-.457c-.552-.047-.892.622-.892.622l-3.293 5.191s-.29.517-.015.984zm-2.753-2.024c.686-.17.795-1.168.795-1.168l-.047-8.312s-.103-1.025-.563-1.303c-.721-.439-.935-.21-1.142-.18l-4.837 1.802s-.474.157-.721.553c-.352.561.358 1.383.358 1.383l5.028 6.867s.497.514 1.129.358zm-1.195 3.363c.017-.64-.768-1.026-.768-1.026l-5.2-2.632s-.77-.318-1.145-.096c-.286.17-.54.476-.565.748l-.339 4.177s-.05.723.137 1.053c.265.467 1.137.142 1.137.142l6.07-1.345c.237-.159.65-.173.673-1.02zm1.51 2.254c-.521-.268-1.145.288-1.145.288l-4.065 4.483s-.507.686-.378 1.107c.122.394.323.59.607.728l4.082 1.291s.495.103.87-.005c.532-.155.434-.99.434-.99l.092-6.07s-.02-.584-.497-.832z"
            />
        </g>
    </svg>
);
