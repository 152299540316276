import React from 'react';
import { Dispatch } from "redux";
import cx from "classnames";
import styles from "./HoverBoxModes.css";
import { NoMouseDownAndUp } from "../../../../common/NoMouseEventsPropagation";
import { Msg } from "../../../../intl/index";
import type { HoverBoxComponent } from "../../../../../components/oneweb/HoverBox/flowTypes";

type Props = {
    dispatch: Dispatch,
    component: HoverBoxComponent,
    onHoverModeClicked: Function,
    onDefaultModeClicked: Function
}

const validateClickAndExec = onValidClick => ((e) => !e.ctrlKey && !e.shiftKey && !e.altKey && !e.metaKey && onValidClick());

const HoverBoxOptions = ({
    component,
    onHoverModeClicked,
    onDefaultModeClicked
}: Props) => {
    const { top, left, hoverMode } = component,
        style = {
            top: top - styles.optionsHeight,
            left
        };

    return (
        <div style={style} className={styles.container} {...NoMouseDownAndUp}>
            <div
                className={cx(styles.option, { [styles.selected]: !hoverMode })}
                onClick={validateClickAndExec(onDefaultModeClicked)}
            >
                <Msg k="common.default">Default</Msg>
            </div>
            <div
                className={cx(styles.option, { [styles.selected]: hoverMode })}
                onClick={validateClickAndExec(onHoverModeClicked)}
            >
                <Msg k="common.hover">Hover</Msg>
            </div>
        </div>
    );
};

export {
    HoverBoxOptions
};
