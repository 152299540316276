import { WidgetsVimeoKind } from './kind';
import { componentMainActions } from './componentMainActions';
import { configurationDialogId } from './configurationDialog';
import { propertiesPanel } from './propertiesPanel/index';
import { reducer } from './reducer';
import { VimeoVideoView } from './view/index';
import { VimeoWorkspaceView } from './view/workspace';
import { calcRenderProps } from './calcRenderProps';
import { WidgetsVimeoConfig } from '../constants';
import { getMVEHiddenElementData } from '../mobileEditorConfig';

const WidgetsVimeo = {
    kind: WidgetsVimeoKind,
    label: WidgetsVimeoConfig.title,
    shortcutIconName: 'widgetsVimeo',
    view: VimeoVideoView,
    workspace: VimeoWorkspaceView,
    requireConfigurationOnDrop: () => true,
    configurationDialog: configurationDialogId,
    calcRenderProps,
    componentMainActions,
    propertiesPanel,
    reducer,
    mobileEditorConfig: {
        kind: WidgetsVimeoKind,
        calcProps: (props: any): any => {
            const { component: { width, height }, renderedWidth } = props,
                finalProps = calcRenderProps(props);

            return renderedWidth
                ? { ...finalProps, height: (renderedWidth * (height / width)) }
                : finalProps;
        },
        view: VimeoVideoView,
        getHiddenElementData: getMVEHiddenElementData(WidgetsVimeoConfig),
    },
};

export { WidgetsVimeo };
