import React from 'react';
import cx from 'classnames';

import { useIntl } from '../../../../../../../view/intl';

import { errorTypes } from './constants';

import styles from './styles.css';

export const ErrorMessage = (props) => {
    const intl = useIntl();
    const {
        iconClass,
        title,
        description,
        type
    } = props;

    return (
        <div
            className={cx(
                styles.errorMessageContainer, {
                    [styles.error]: type === errorTypes.error,
                    [styles.warning]: type === errorTypes.warning,
                }
            )}
            data-testid="ai-edit-text-error"
        >
            <div className={iconClass} />
            <div className={styles.errorMessageTitle}>{intl.msgJoint(title)}</div>
            <div className={styles.errorMessageDesc}>{intl.msgJoint(description)}</div>
        </div>
    );
};
