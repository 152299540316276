import * as R from "ramda";
import { createSelector } from "reselect";
import * as path from "../../../mappers/path";
import * as HORIZONTAL_ALIGN from "./constants/horizontalAlign";
import * as LAYOUT_TYPE from "./constants/layoutType";
import type { PagesWithCurrentPageFlag, PageWithCurrentPageFlag } from "./flowTypes";

type SelectPagesFromLevelProps = {
    pages: PagesWithCurrentPageFlag;
    level: number;
    inspectingPage?: PageWithCurrentPageFlag;
};

const styleGlobalId = R.path(path.styleGlobalId),
    submenuStyleGlobalId = R.path(path.subMenuStyleGlobalId),
    styleThemeGlobalId = R.path(["themeStyles", "mainMenu", "id"]),
    submenuThemeStyleGlobalId = R.path(["themeStyles", "submenu", "id"]),
    layoutIsHorizontalDropdown = R.propEq("layoutType", LAYOUT_TYPE.HORIZONTAL_DROPDOWN),
    horizontalAlignIsNotFit = R.complement(R.propEq("horizontalAlign", HORIZONTAL_ALIGN.FIT)),
    moreButtonEnabled = R.propEq("moreButtonEnabled", true),
    isMoreButtonModeSettableSelector = layoutIsHorizontalDropdown,
    isMoreButtonModeSelector = R.both(layoutIsHorizontalDropdown, moreButtonEnabled),
    /* PAGES selectors */
    all = R.identity,
    isCurrentPageBranch = R.either(R.prop("isCurrentPage"), page => R.any(isCurrentPageBranch)(page.items || [])),
    singleLevel = createSelector(
        all,
        R.map(
            R.evolve({
                items: R.always([])
            })
        )
    ),
    topLevelAndCurrentBranch = (pages: PagesWithCurrentPageFlag) => {
        // $FlowFixMe
        return pages.map(page => {
            return R.evolve(
                {
                    items: isCurrentPageBranch(page) ? topLevelAndCurrentBranch : () => []
                },
                page
            );
        });
    };

function makePageToPages(level: number) {
    return (page: PageWithCurrentPageFlag) => {
        if (!page.items) {
            return [];
        }

        return selectPagesFromLevel({
            pages: page.items,
            level,
            inspectingPage: page
        });
    };
}

function selectPagesFromLevel({ pages, level, inspectingPage }: SelectPagesFromLevelProps) {
    const isInitialCall = inspectingPage === undefined;

    if (level === 1) {
        if (isInitialCall) {
            return pages;
        } else if (isCurrentPageBranch(inspectingPage)) {
            return pages;
        } else {
            return [];
        }
    }

    return pages.map(makePageToPages(level - 1)).filter(array => array.length > 0)[0] || [];
}

export {
    styleGlobalId,
    submenuStyleGlobalId,
    styleThemeGlobalId,
    submenuThemeStyleGlobalId,
    layoutIsHorizontalDropdown,
    horizontalAlignIsNotFit,
    moreButtonEnabled,
    isMoreButtonModeSettableSelector,
    isMoreButtonModeSelector,
    all,
    isCurrentPageBranch,
    singleLevel,
    topLevelAndCurrentBranch,
    makePageToPages,
    selectPagesFromLevel
};
