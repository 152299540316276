import React from 'react';

export type MakeReactContextRes<V> = {
    Context: React.Context<V>,
    useContext: () => V,
};

export const makeReactContext = <V extends any>(initValue: V): MakeReactContextRes<V> => {
    const
        Context = React.createContext<V>(initValue),
        useContext = () => React.useContext<V>(Context);

    return { Context, useContext };
};
