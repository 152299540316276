import React from "react";
import PropertyContainer from "../../../view/common/PropertyContainer/index";
import { CaptionStyles } from "./constants";
import type { CaptionStylePropTypes } from "./flowTypes";
import { RadioButtonGroup } from "../../../view/common/RadioButton/index";
import styles from "./styles.css";

const
    captionStyleOptions = [
        {
            label: "msg: common.captionStyle.option.classic {Classic}",
            value: CaptionStyles.CLASSIC
        },
        {
            label: "msg: common.captionStyle.option.template {Template}",
            value: CaptionStyles.TEMPLATE
        },
        {
            label: "msg: common.captionStyle.option.custom {Custom}",
            value: CaptionStyles.CUSTOM
        }
    ],
    captionStyleHelpText = "msg: component.gallery.pp.captionStyle.helpText {This option allows you to set the title and description style.}"; // eslint-disable-line max-len
export default ({ value, onChangeAction, dispatch }: CaptionStylePropTypes) => (
    <PropertyContainer
        label="msg: common.captionStyle {Caption style}"
        labelIcon={{
            icon: styles.tooltip, //sortImageStyles.captionIcon,
            popup: {
                message: captionStyleHelpText,
                className: styles.LabelIconPopopClassname,
                tipClassname: styles.LabelIconPopopTipClassname
            }
        }}
        isGroupLabelRequired={false}
    >
        <RadioButtonGroup
            optionsConfig={captionStyleOptions}
            onChange={(value) => dispatch({ type: onChangeAction, payload: value })}
            value={value}
        />
    </PropertyContainer>
);
