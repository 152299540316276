const fromSuperscriptFontSize = (fontSize: number): number => {
    return Math.floor((fontSize / 0.6) - 0.5);
};

const toSuperscriptFontSize = (fontSize: number): number => {
    return parseFloat(((fontSize * 0.6) + 0.5).toFixed(1));
};

export {
    fromSuperscriptFontSize,
    toSuperscriptFontSize
};
