import React from 'react';
import cx from "classnames";
import styles from '../view/Inserter.css';
import { Intl } from "../../../view/intl/injectIntl";
import BlocksItemWrapper from "./BlocksItem";
import {
    EXTENDED_PANEL_AVAILABLE_WIDTH,
    EXTENDED_PANEL_AVAILABLE_WIDTH_BY_45_PER
} from "../constants";
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import EPSeparator, { EPHalfSeparator } from "../view/EPSeparator";
import { Stylesheet } from '../../Workspace/epics/stylesheets/flowTypes';

type Props = {
    category: Record<string, any>,
    blocks: Array<Record<string, any>>,
    componentsEval: Record<string, any>,
    styleSheets: Stylesheet,
    globalVariables: any,
    intl: Intl,
    dispatch: Dispatch,
    showSeperator?: boolean,
    showSeperatorBetweenBlocks?: boolean,
    noMultiColumn?: boolean,
    noHeader?: boolean,
    canTransparentBackground?: boolean,
    autoColorMode?: boolean,
    isNoOverlay?: boolean,
};

const MAX_WIDTH_FOR_MULTI_COLUMN = 460;
const MIN_SCALE = 0.6;

const getHeightScale = ({ rect, parentId, block, fullWidth, isMultiCol }: {
    rect: Record<string, any>,
    parentId: string,
    block: Record<string, any>,
    fullWidth?: boolean,
    isMultiCol?: boolean
}) => {
    const originalHeight = block.data.componentsMap[parentId].height;
    const originalWidth = block.data.componentsMap[parentId].width;

    const showMultiCol = !fullWidth && (isMultiCol ||
            originalWidth <= originalHeight || originalWidth < MAX_WIDTH_FOR_MULTI_COLUMN);

    let scale = showMultiCol ?
        (EXTENDED_PANEL_AVAILABLE_WIDTH_BY_45_PER) / rect.width
        :
        EXTENDED_PANEL_AVAILABLE_WIDTH / rect.width;
    let marginLeft = 0;

    if (!showMultiCol && scale > MIN_SCALE) {
        scale = MIN_SCALE;
        marginLeft = (EXTENDED_PANEL_AVAILABLE_WIDTH - (originalWidth * MIN_SCALE)) / 2;
    }

    return {
        scale,
        height: scale * rect.height,
        isMultiCol: showMultiCol,
        marginLeft
    };
};

const Blocks = ({
    category,
    blocks,
    componentsEval,
    styleSheets,
    globalVariables,
    intl,
    dispatch,
    showSeperator,
    showSeperatorBetweenBlocks,
    noMultiColumn = false,
    noHeader,
    canTransparentBackground,
    autoColorMode,
    isNoOverlay,
}: Props) => {
    return <React.Fragment key={category.title}>
        {
            !noHeader && <React.Fragment>
                {showSeperator && <VerticalSpacer y={22} />}
                {showSeperator && <EPSeparator />}
                <VerticalSpacer y={23} />
                <h3 className={styles.categoryHeader}>
                    {intl.msgJoint(category.title)}
                </h3>
                <VerticalSpacer y={23} />
            </React.Fragment>
        }
        <div className={styles.blockListContainer} key={category.title}>
            {blocks.map((block, index) => {
                return <React.Fragment key={`fragement-${index}`}>
                    {showSeperatorBetweenBlocks && index !== 0 && <VerticalSpacer y={10} />}
                    {showSeperatorBetweenBlocks && index !== 0 && <EPHalfSeparator />}
                    {showSeperatorBetweenBlocks && index !== 0 && <VerticalSpacer y={10} />}
                    <BlocksItemWrapper
                        key={block.id}
                        canTransparentBackground={canTransparentBackground}
                        noMultiColumn={noMultiColumn}
                        block={block}
                        componentsEval={componentsEval}
                        styleSheets={styleSheets}
                        globalVariables={globalVariables}
                        intl={intl}
                        dispatch={dispatch}
                        getHeightAndScale={({ rect, parentId }) => getHeightScale({ rect, parentId, block, fullWidth: noMultiColumn })}
                        autoColorMode={autoColorMode}
                        isNoOverlay={isNoOverlay}
                    />
                </React.Fragment>;
            })}
        </div>
    </React.Fragment>;
};

type ColumnBlocksProps = {
    blocks: Array<Record<string, any>>,
    componentsEval: Record<string, any>,
    styleSheets: Stylesheet,
    globalVariables: any,
    intl: Intl,
    dispatch: Dispatch,
    getHeightScaleFn?: Function,
    verticallyCenter?: boolean,
    canTransparentBackground?: boolean
};

export const ColumnBlocks = ({
    blocks,
    componentsEval,
    styleSheets,
    globalVariables,
    intl,
    dispatch,
    getHeightScaleFn,
    verticallyCenter,
    canTransparentBackground
}: ColumnBlocksProps) => {
    return <div className={cx(styles.columnBlocks, { [styles.verticallyCenter]: !!verticallyCenter })}>
        {blocks.map(block => {
            return <BlocksItemWrapper
                key={block.id}
                block={block}
                canTransparentBackground={canTransparentBackground}
                componentsEval={componentsEval}
                styleSheets={styleSheets}
                globalVariables={globalVariables}
                intl={intl}
                dispatch={dispatch}
                getHeightAndScale={({ rect, parentId }) => {
                    return getHeightScaleFn ?
                        getHeightScaleFn({ rect, parentId, block })
                        :
                        getHeightScale({
                            rect, parentId, block, isMultiCol: true
                        });
                }}
            />;
        })}
    </div>;
};

type BlocksByColumnProps = {
    leftBlocks: Array<Record<string, any>>,
    rightBlocks: Array<Record<string, any>>,
    category: Record<string, any>,
    componentsEval: Record<string, any>,
    styleSheets: Stylesheet,
    globalVariables: any,
    intl: Intl,
    dispatch: Dispatch,
    showSeperator?: boolean,
    noHeader?: boolean,
    classNames?: string,
    getHeightScale?: Function,
    verticallyCenter?: boolean,
    canTransparentBackground?: boolean
};

export const BlocksByColumn = ({
    leftBlocks,
    rightBlocks,
    category,
    componentsEval,
    styleSheets,
    globalVariables,
    intl,
    dispatch,
    showSeperator,
    noHeader,
    getHeightScale,
    classNames = "",
    verticallyCenter,
    canTransparentBackground
}: BlocksByColumnProps) => {
    return <React.Fragment key={category.title}>
        {
            !noHeader && <React.Fragment>
                {showSeperator && <VerticalSpacer y={22} />}
                {showSeperator && <EPSeparator />}
                <VerticalSpacer y={23} />
                <h3 className={styles.categoryHeader}>
                    {intl.msgJoint(category.title)}
                </h3>
                <VerticalSpacer y={23} />
            </React.Fragment>
        }
        <div className={cx(styles.parentContainerList, classNames)}>
            <ColumnBlocks
                blocks={leftBlocks.filter(a => !!a)}
                componentsEval={componentsEval}
                styleSheets={styleSheets}
                globalVariables={globalVariables}
                intl={intl}
                dispatch={dispatch}
                getHeightScaleFn={getHeightScale}
                verticallyCenter={verticallyCenter}
                canTransparentBackground={canTransparentBackground}
            />
            <ColumnBlocks
                blocks={rightBlocks.filter(a => !!a)}
                componentsEval={componentsEval}
                styleSheets={styleSheets}
                globalVariables={globalVariables}
                intl={intl}
                dispatch={dispatch}
                getHeightScaleFn={getHeightScale}
                verticallyCenter={verticallyCenter}
                canTransparentBackground={canTransparentBackground}
            />
        </div>
    </React.Fragment>;
};

export default Blocks;
