/*global $ */

import * as R from 'ramda';
import cx from 'classnames';
import queryString from 'querystring';
import React from "react";
import { connect } from "react-redux";
import styles from "./Desktop.css";
import getHtmlOnPreviewLoad from '../getHtmlOnPreviewLoad';
import injectIntl from "../../../../view/intl/injectIntl";
import fixLinks from "../fixLinks";
import { isWsbDemo } from "../../../../debug/isWsbDemo";
import { ContactFormKind } from "../../../oneweb/ContactForm/kind";
import { DESKTOP_PREVIEW_LOADED_IN_SIDEBAR } from "../../../ModernLayouts/actionTypes";
import type { AppState } from "../../../../redux/modules/flowTypes";
import { makeEpicStateSelector } from "../../../../epics/makeEpic";
import { chatWidgetGlobalDataEpic } from "../../../SiteSettings/chatWidgetTabData/chatWidgetGlobalDataEpic";
import { ChatWidgetPreview } from "../../../oneweb/FacebookChat/view/preview";

type DesktopPreviewState = {
    iFrameKey: number
}

const
    isExternalLinkOrLocalFile = (elt): boolean => {
        if (elt.hostname === window.location.hostname) {
            if (isWsbDemo()) {
                return elt.pathname && elt.pathname.search('/trial/') > -1;
            }
            return elt.pathname && elt.pathname.search('/webspace/') > -1;
        }

        return elt.href.search(/^(?:mailto:.*|javascript:.*|sms:.*|tel:.*)|(api\/).*/) === -1;
    },
    isPageLink = (href) => /\/preview-wbtgen\/[A-F0-9-]+\.html/i.test(href),
    getPageId = (href) => {
        return R.pathOr(null, [1], href.match(/\/([\w-]+)(?=\.html)/));
    },
    getLinkElt = (el) => {
        let elt = el;
        while (elt && !elt.href) {
            elt = elt.parentNode;
        }
        return elt;
    },
    isContactFormElementsEventAllowed = (ele) => {
        let contactFormElem = false;
        if (['checkbox', 'radio'].includes(ele.type)) {
            let parentElement = ele.parentNode;

            while (
                !contactFormElem
                && parentElement
                && typeof parentElement.hasAttribute === 'function'
                && typeof parentElement.getAttribute === 'function'
            ) {
                if (parentElement.hasAttribute('data-kind')
                    && parentElement.hasAttribute('data-kind') === 'Component'
                    && parentElement.hasAttribute('data-specific-kind')
                    && parentElement.hasAttribute('data-specific-kind') === ContactFormKind) {
                    contactFormElem = true;
                    break;
                }
                parentElement = parentElement.parentNode;
            }
        }

        return contactFormElem;
    },
    preventExternalLink = (event: any) => {
        // Handle checkbox / radio from contact from
        if (isContactFormElementsEventAllowed(event.target)) {
            return true;
        }

        const linkElt = getLinkElt(event.target);
        // block external url, or local file or mailto on preview
        if (!linkElt || isExternalLinkOrLocalFile(linkElt) || /^mailto:/i.test(linkElt.href)) {
            event.preventDefault();
            return false;
        }

        return true;
    },
    processInternalLink = (
        event: any,
        cb: Function,
        frame: any,
        currentPageId: string
    ) => {
        const linkElt = getLinkElt(event.target),
            href = linkElt ? linkElt.href : null;
        if (href && isPageLink(href)) {
            const pageId = getPageId(href),
                hash = linkElt.hash.substr(1);
            event.preventDefault();
            const section = event.currentTarget.querySelector(`[data-id="${hash}"]`);
            if (pageId && !section) {
                cb(pageId);
                if (pageId !== currentPageId) {
                    frame.contentWindow.localStorage.setItem('previewHash', hash);
                }
            }
        }
    },
    disablePreviewWbtgenForTrial = el => {
        if (isWsbDemo()) {
            const linkEl = getLinkElt(el);
            if (linkEl && linkEl.href && linkEl.href.indexOf('preview-wbtgen') !== -1) {
                return true;
            }
        }

        return false;
    };

const DOMReady = function (callback, doc) {
    doc = doc || document; // eslint-disable-line no-param-reassign
    if (doc.readyState === 'interactive' || doc.readyState === 'complete') {
        callback();
    } else {
        doc.addEventListener('DOMContentLoaded', callback);
    }
};

export class DesktopPreview extends React.Component<any, DesktopPreviewState> {
    setIframeRef: any;
    previewIframeRef: any;
    timeoutKey: undefined | ReturnType<typeof setTimeout>;

    constructor(props: any) {
        super(props);
        this.setIframeRef = previewIframeRef => { this.previewIframeRef = previewIframeRef; };
        this.state = { iFrameKey: Math.random() };  //NOSONAR
        this.onIframeMessage = this.onIframeMessage.bind(this);
        this.timeoutKey = undefined;
    }

    onIframeMessage({ data }: any) {
        if (data && data.hotKey === 'ctrlP') {
            this.props.togglePreview();
        }
    }

    load() {
        const
            { src, setPreviewPageToLoad, viewType, orientation } = this.props,
            frame = this.previewIframeRef,
            iframeDoc = frame.contentWindow.document;

        if (src) {
            frame.src = src;

            let timerForDomReady;

            const main = () => {
                if (
                    frame.contentWindow &&               // Note: This may be "truthy" in almost all of the cases, but under some intermittent network situations, it may be "falsy" (null)
                    frame.contentWindow.document &&      // Note: Added for some robustness (may not be necessary)
                    frame.contentWindow.document.body &&
                    frame.contentWindow.location &&
                    frame.contentWindow.location.href !== 'about:blank'
                ) {
                    clearInterval(timerForDomReady);
                    DOMReady(() => {
                        fixLinks(frame.contentWindow.document.body, src, viewType, orientation);

                        frame.contentWindow.document.body.addEventListener(
                            'click',
                            e => {
                                preventExternalLink(e);
                                const target = getLinkElt(e.target);
                                if (target) {
                                    const getQueryPart = s => (s.includes('?') ? s.substring(s.indexOf('?') + 1) : '');
                                    const frameQueryParams = queryString.parse(getQueryPart(frame.src));

                                    const queryStringPart = target.href.split('?')[1] || '';

                                    const hrefQueryParams = queryString.parse(queryStringPart);
                                    const resultQueryParams = { ...frameQueryParams, ...hrefQueryParams };

                                    let targetHref = target.href.replace('?' + getQueryPart(target.href), '');
                                    if (queryString.stringify(resultQueryParams)) {
                                        targetHref += '?' + queryString.stringify(resultQueryParams);
                                    }
                                    target.href = targetHref;
                                }
                            },
                            true
                        );

                        frame.contentWindow.addEventListener('unload', () => {
                            setTimeout(() => {
                                clearInterval(timerForDomReady);
                                timerForDomReady = setInterval(main, 50);
                                setTimeout(() => {
                                    clearInterval(timerForDomReady);
                                }, 60000);
                            }, 0);
                        });
                    }, frame.contentWindow.document);
                }
            };

            timerForDomReady = setInterval(main, 50);
            setTimeout(() => {
                clearInterval(timerForDomReady);
            }, 60000);
        } else {
            try {
                iframeDoc.open();
                iframeDoc.write(getHtmlOnPreviewLoad(this.props));
            } finally {
                const { currentPageId } = this.props.preview;
                iframeDoc.close();
                frame.contentWindow.document.addEventListener('click', (event) => {
                    preventExternalLink(event);
                    processInternalLink(event, setPreviewPageToLoad, frame, currentPageId);
                }, true);
                frame.contentWindow.document.addEventListener('contextmenu', (event) => {
                    if (disablePreviewWbtgenForTrial(event.target)) {
                        event.preventDefault();
                    }
                }, true);
            }
        }
        frame.onload = () => {
            const { intl } = this.props;
            if (intl) {
                frame.contentWindow.postMessage({
                    'externalLinksDisallowMessage': intl.msgJoint('msg: common.externalLinksNotAllowed {External links do not work when previewing. Please publish your site to test this link.}') // eslint-disable-line
                }, window.location.origin);
            }
            if (
                this.props.isInSidebar &&
                frame.contentWindow &&
                frame.contentWindow.document &&
                frame.contentWindow.document.body
            ) {
                frame.contentWindow.document.body.style.overflow = 'hidden';
                const sectionRect = $(frame.contentWindow.document.body).find('[data-kind="SECTION"]')[0].getBoundingClientRect();
                this.props.dispatch({
                    type: DESKTOP_PREVIEW_LOADED_IN_SIDEBAR,
                    payload: { sectionRect, layoutItem: this.props.layoutItem }
                });
            }
        };
    }

    componentDidMount() {
        this.load();
        window.addEventListener('message', this.onIframeMessage, false);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.onIframeMessage);
        clearTimeout(this.timeoutKey);
    }

    componentDidUpdate(prevProps: any) {
        const { src, viewType, preview, forceUpdate, orientation } = this.props;
        if (prevProps.viewType !== viewType || prevProps.orientation !== orientation) {
            const frame = this.previewIframeRef;
            fixLinks(frame.contentWindow.document.body, src, viewType, orientation);
        }
        if ((forceUpdate && !prevProps.forceUpdate) || (!src && prevProps.preview.currentPageId !== preview.currentPageId)) {
            clearTimeout(this.timeoutKey);
            this.setState({ iFrameKey: Math.random() });    //NOSONAR
            this.timeoutKey = setTimeout(() => {
                this.load();
            });
        }
    }

    render() {
        // Class "webshop-preview-in-website-builder" is added for webshop to be able to detect its parent iframe as
        // WSB preview and run some JavaScript code as required for WBTGEN-5856 and other such tasks where Webshop
        // needs to adjust the preview UI.
        // Class "x-panel-body-noheader" is added to support WBTGEN-5856
        // Once webshop changes are done for WEBSHOP-6664, code here should remove x-panel-body-noheader
        const {
            intl,
            chatWidgetState
        }
         = this.props;

        return (
            <div className={cx(styles.container, this.props.className)} style={this.props.style || {}}>
                <iframe
                    key={this.state.iFrameKey}
                    className="x-panel-body-noheader webshop-preview-in-website-builder"
                    style={{
                        backgroundColor: '#fff',
                        display: 'block'
                    }}
                    ref={this.setIframeRef}
                    allowFullScreen
                    // @ts-ignore
                    type="text/html"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                />
                {
                    chatWidgetState && chatWidgetState.enableChatWidget &&
                    <ChatWidgetPreview
                        intl={intl}
                        chatWidgetColor={chatWidgetState.chatWidgetColor}
                    />
                }
            </div>
        );
    }
}

const chatWidgetEpicStateSelector = makeEpicStateSelector(chatWidgetGlobalDataEpic.valueActionType);
const mapStateToProps = (appState: AppState) => ({
    chatWidgetState: chatWidgetEpicStateSelector(appState)
});

export default connect(mapStateToProps)(injectIntl(DesktopPreview));

export {
    preventExternalLink,
    processInternalLink
};
