import * as ActionTypes from "../../../actionTypes";
import makeLinkPageReducer from "../../../../linkPageForm/reducer/makeLinkPageReducer";

const Actions = {
    SET_LINK_TO_FILE_OR_PAGE: ActionTypes.LINK_PAGE_INFO_SET_LINK_TO_FILE_OR_PAGE,
    UNSET_LINK_TO_FILE_OR_PAGE: ActionTypes.LINK_PAGE_INFO_UNSET_LINK_TO_FILE_OR_PAGE,
    OPEN_NEW_WINDOW_TOGGLE: ActionTypes.LINK_PAGE_INFO_OPEN_NEW_WINDOW_TOGGLE,
    URL_CHANGE: ActionTypes.LINK_PAGE_INFO_URL_CHANGE,
    NAME_CHANGE: ActionTypes.LINK_PAGE_INFO_NAME_CHANGE,
    IS_HIDDEN_TOGGLE: ActionTypes.LINK_PAGE_INFO_IS_HIDDEN_TOGGLE
};

export default makeLinkPageReducer(Actions);
