import React from 'react';
import { pure } from 'recompose';
import { MctaCombobox, MctaVerticalBar, MctaCheckbox } from '../../../componentMainActions/index';
import { StyleOptions } from '../constants';
import * as actionTypes from '../actionTypes';
import styles from '../view/ShareButtons.css';
import MctaPropertyContainer from '../../../componentMainActions/MctaPropertyContainer/index';
import HorizontalSpacer from '../../../../view/common/HorizontalSpacer';

const
    EditView = pure(({
        selectedComponent: {
            styleInfo,
            showFacebookShare,
            showLinkedIn,
            showTwitter
        },
        children,
        dispatch
    }) => (
        <div>
            <HorizontalSpacer x={5} />
            <MctaPropertyContainer label="msg: component.shareButtons.mcta.label.style {Style:}">
                <MctaCombobox
                    searchable={false}
                    value={styleInfo}
                    options={StyleOptions}
                    className={styles.styleStyle}
                    onChange={({ value }) => dispatch({ type: actionTypes.SHAREBUTTONS_STYLE_CHANGED, payload: value })}
                />
            </MctaPropertyContainer>
            <HorizontalSpacer x={13} />
            <MctaCheckbox
                label="msg: component.shareButtons.tb.facebook {Facebook}"
                isChecked={showFacebookShare}
                onClick={() => dispatch({ type: actionTypes.SHAREBUTTONS_TOGGLE_FACEBOOK_SHARE })}
            />
            <HorizontalSpacer x={12} />
            <MctaVerticalBar />
            <HorizontalSpacer x={12} />
            <MctaCheckbox
                label="msg: component.shareButtons.tb.linkedIn {LinkedIn}"
                isChecked={showLinkedIn}
                onClick={() => dispatch({ type: actionTypes.SHAREBUTTONS_TOGGLE_LINKEDIN })}
            />
            <HorizontalSpacer x={12} />
            <MctaVerticalBar />
            <HorizontalSpacer x={12} />
            <MctaCheckbox
                label="msg: component.shareButtons.tb.twitter {X}"
                isChecked={showTwitter}
                onClick={() => dispatch({ type: actionTypes.SHAREBUTTONS_TOGGLE_TWITTER })}
            />
            {children ? <HorizontalSpacer /> : <HorizontalSpacer x={5} />}
            {children}
        </div>
    ));

export default {
    editView: EditView,
    editButtonText: 'msg: component.shareButtons.tb.settings {Edit share buttons}'
};
