import { CodeComponentKind } from "../../../../oneweb/Code/kind";
import { FacebookFeedGalleryKind } from "../../../../oneweb/FacebookFeedGallery/kind";
import { GoogleReviewsKind } from "../../../../oneweb/GoogleReviews/kind";
import { InstagramGalleryKind } from "../../../../oneweb/InstagramGallery/kind";
import { ProductWidgetComponentKind } from "../../../../oneweb/ProductWidget/kind";
import { FeaturedProductsComponentKind } from "../../../../oneweb/FeaturedProducts/kind";
import { VideoFileKind } from "../../../../oneweb/Video/VideoFile/kind";
import { WebshopKind } from "../../../../oneweb/WebShop/kind";

export const PremiumWidgetKind = "PREMIUM_WIDGET";

const ComponentKindTrackingEventSource = {
    [WebshopKind]: "Online shop",
    [ProductWidgetComponentKind]: "Product widget",
    [FeaturedProductsComponentKind]: "Featured products",
    [CodeComponentKind]: "Code component",
    [VideoFileKind]: "Video upload",
    [InstagramGalleryKind]: "Instagram gallery",
    [FacebookFeedGalleryKind]: "Facebook feed gallery",
    [GoogleReviewsKind]: "Google reviews",
    [PremiumWidgetKind]: "Premium widget"
};

export const
    FEATURE_ONLINE_SHOP = "FEATURE_ONLINE_SHOP",
    FEATURE_SEO_SCAN = "FEATURE_SEO_SCAN",
    FEATURE_BUSINESS_LISTING = "FEATURE_BUSINESS_LISTING",
    FEATURE_GOOGLE_ANALYTICS = "FEATURE_GOOGLE_ANALYTICS",
    FEATURE_FACEBOOK_PIXEL = "FEATURE_FACEBOOK_PIXEL",
    FEATURE_PUBLISH = "FEATURE_PUBLISH",
    FEATURE_BACKUP_RESTORE = "FEATURE_BACKUP_RESTORE",
    FEATURE_BURGER_MENU = "FEATURE_BURGER_MENU",
    FEATURE_PAGES_MENU = "FEATURE_PAGES_MENU",
    FEATURE_TOP_BAR = "FEATURE_TOP_BAR",
    FEATURE_ADD_NEW_PAGE = "FEATURE_ADD_NEW_PAGE",
    FEATURE_ADD_SECTION_LINK = "FEATURE_ADD_SECTION_LINK",
    FEATURE_PLANS_OVERVIEW = "FEATURE_PLANS_OVERVIEW",
    FEATURE_SHUTTERSTOCK_IMAGES = "FEATURE_SHUTTERSTOCK_IMAGES";

const FeatureTrackingEventSource = {
    [FEATURE_ONLINE_SHOP]: "Move your business online",
    [FEATURE_SEO_SCAN]: "SEO scan",
    [FEATURE_BUSINESS_LISTING]: "Business listing",
    [FEATURE_GOOGLE_ANALYTICS]: "Google analytics",
    [FEATURE_FACEBOOK_PIXEL]: "FB pixel",
    [FEATURE_PUBLISH]: "Publish",
    [FEATURE_BACKUP_RESTORE]: "Backup & Restore",
    [FEATURE_BURGER_MENU]: "Burger menu",
    [FEATURE_PAGES_MENU]: "Pages menu",
    [FEATURE_TOP_BAR]: "Top-bar",
    [FEATURE_ADD_NEW_PAGE]: "Add new page",
    [FEATURE_ADD_SECTION_LINK]: "Add section link",
    [FEATURE_PLANS_OVERVIEW]: "Plans overview",
    [FEATURE_SHUTTERSTOCK_IMAGES]: "Shutterstock images",
};

const DefaultTrackingEventSource = ComponentKindTrackingEventSource[PremiumWidgetKind];

export const getTrackingEventSource = (source: string): string => {
    return (
        ComponentKindTrackingEventSource[source] ||
        FeatureTrackingEventSource[source] ||
        DefaultTrackingEventSource
    );
};
