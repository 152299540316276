import openDialog from "../../App/actionCreators/openDialog";
import { ADD_ONECOM_TEMPLATE_DIALOG_ID } from "./dialogId";
import { getRepositoryTemplateRefListApiAction } from '../../../redux/middleware/api/commonApiActions';

export const
    OPEN_ONECOME_TEMPLATE_ACTION = 'OPEN_ONECOME_TEMPLATE_ACTION',
    openAddOnecomTemplateAction = () => ({
        type: OPEN_ONECOME_TEMPLATE_ACTION
    });

export const
    openAddOnecomTemplateDialogAction = () => openDialog(ADD_ONECOM_TEMPLATE_DIALOG_ID);

export const
    ADD_ONECOM_TEMPLATES_REQUEST_ACTION = 'ADD_ONECOM_TEMPLATES_REQUEST_ACTION',
    ADD_ONECOM_TEMPLATES_SUCCESS_ACTION = 'ADD_ONECOM_TEMPLATES_SUCCESS_ACTION',
    ADD_ONECOM_TEMPLATES_FAILURE_ACTION = 'ADD_ONECOM_TEMPLATES_FAILURE_ACTION',
    addOneComTemplatesApiAction = () => getRepositoryTemplateRefListApiAction({
        types: [
            ADD_ONECOM_TEMPLATES_REQUEST_ACTION,
            ADD_ONECOM_TEMPLATES_SUCCESS_ACTION,
            ADD_ONECOM_TEMPLATES_FAILURE_ACTION
        ]
    });

export const
    SELECT_ONECOM_TEMPLATE_ACTION = 'SELECT_ONECOM_TEMPLATE_ACTION',
    selectOnecomTemplateAction = (id: string) => ({
        type: SELECT_ONECOM_TEMPLATE_ACTION,
        payload: id
    });

export const
    SHOW_ONECOM_TEMPLATE_PREVIEW_ACTION = 'SHOW_ONECOM_TEMPLATE_PREVIEW_ACTION',
    showOnecomTemplatePreviewAction = (id: string) => ({
        type: SHOW_ONECOM_TEMPLATE_PREVIEW_ACTION,
        payload: id
    });

export const
    HIDE_ONECOM_TEMPLATE_PREVIEW_ACTION = 'HIDE_ONECOM_TEMPLATE_PREVIEW_ACTION',
    hideOnecomeTemplatePreviewAction = () => ({
        type: HIDE_ONECOM_TEMPLATE_PREVIEW_ACTION
    });
