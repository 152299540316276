import type {
    Element
} from '../../../flowTypes';

export default (
    paragraphs: Array <Element>,
    styles: Array <Element>,
    wrappedItems: Record<string, any>,
): Record<string, any> => {
    // if any zero-length styles match zero-length paragraphs, remove them both
    const updatedWrappedItems = {};
    const zeroLengthParagraphIndexes = {};
    const wrapIndexes = Object.keys(wrappedItems).map(Number);

    paragraphs.forEach(({
        start,
        end
    }, index) => {
        if (wrappedItems[index] && start === end) {
            zeroLengthParagraphIndexes[start] = index;
        }
    });

    const styleElements = styles.filter(({
        start,
        end
    }) => {
        if (start === end && zeroLengthParagraphIndexes.hasOwnProperty(start)) {
            return false;
        }

        return true;
    });

    const updateWrapIndexes = (() => {
        let removedParagraphs = 0;
        return (wrapIndex = Infinity) => {
            while (wrapIndexes.length && wrapIndexes[0] <= wrapIndex) {
                const oldIndex = wrapIndexes.shift() as number;
                const newIndex = oldIndex - removedParagraphs;
                if (!updatedWrappedItems[newIndex]) {
                    updatedWrappedItems[newIndex] = wrappedItems[oldIndex];
                } else {
                    const existingHeight = updatedWrappedItems[newIndex].reduce((a, component) => {
                        return a + component.relPara.offset + component.height;
                    }, 0);

                    wrappedItems[oldIndex].forEach(component => {
                        const offset = component.relPara.offset + existingHeight;
                        updatedWrappedItems[newIndex].push({ ...component, relPara: { ...component.relPara, offset } });
                    });
                }
            }

            removedParagraphs++;
        };
    })();

    const paragraphElements = paragraphs.filter(({
        start,
        end
    }) => {
        if (start === end && zeroLengthParagraphIndexes.hasOwnProperty(start)) {
            updateWrapIndexes(zeroLengthParagraphIndexes[start]);
            return false;
        }

        return true;
    });

    updateWrapIndexes();
    return {
        paragraphElements,
        styleElements,
        wrappedItems: updatedWrappedItems
    };
};
