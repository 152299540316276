import React from 'react';
import styles from './HoverBoxControls.css';
import { HoverBoxBtnCom } from "./HoverBoxBtnCom";

type Props = {
    onClick: React.MouseEventHandler,
};

export const HoverBoxDuplicateBtnCom = ({ onClick }: Props) => (
    <HoverBoxBtnCom
        title="msg: duplicate {Duplicate}"
        icoClassName={styles.duplicate}
        onClick={onClick}
    />
);

