import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { mapIndexed } from '../../utils/ramdaEx';
import { OnClickActions } from '../oneweb/ImageSlider/constants/index';
import { calculateImageAttributes } from '../oneweb/ImageSlider/utils/index';
import { getNodeCss } from './utils';
import SortableItem from './sortableItem';
import SortableGhostItem from './sortableGhostItem';
import * as styles from './SortImageDialog.css';
import Resource from "../../redux/modules/children/fileChooser/Resource";

export default SortableContainer<any>( // eslint-disable-line new-cap
    ({
        images,
        ghostImages,
        activeNodeIndex,
        hdImages,
        site,
        componentId,
        isImageEditorAllowed,
        missingAssetUrls,
        onEditIconClick,
        onDeleteIconClick
    }) => {
        const renderImage = (image, index) => {
            let imageAvailable = false,
                imageAttributes = {},
                resource,
                containerHeight = parseInt(styles.THUMB_HEIGHT, 10),
                containerWidth = parseInt(styles.THUMB_WIDTH, 10);
            if (!image.isUploading) {
                const {
                    // Exclude these from the calculated result as they are not used by client
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    a, caption, srcSet,     // eslint-disable-line no-unused-vars
                    imageAvailable: isImgAvailable, ...imageAttr
                } = calculateImageAttributes({
                    image,
                    width: containerWidth,
                    height: containerHeight,
                    crop: !(image.asset.width < containerWidth || image.asset.height < containerHeight),
                    onClickAction: OnClickActions.NOTHING,
                    componentId,
                    hdImages,
                    site,
                    missingAssetUrls,
                    index
                });
                imageAvailable = isImgAvailable;
                imageAttributes = imageAttr;
            } else {
                resource = new Resource(image.basePath, image);
            }

            return (
                !image.isUploading ?
                    <SortableItem
                        key={index}
                        index={index}
                        image={image}
                        imageIdx={index}
                        imageAvailable={imageAvailable}
                        imageAttributes={{ ...imageAttributes, role: "presentation" }}
                        containerCss={getNodeCss(index, activeNodeIndex)}
                        onEditIconClick={e => { e.stopPropagation(); onEditIconClick(image.asset, index); }}
                        onDeleteIconClick={e => { e.stopPropagation(); onDeleteIconClick(index); }}
                        isImageEditorAllowed={isImageEditorAllowed}
                    /> :
                    <SortableGhostItem
                        disabled
                        containerCss={getNodeCss(index, activeNodeIndex)}
                        key={index}
                        index={index}
                        image={resource}
                        imageIdx={index}
                        height={containerHeight}
                        width={containerWidth}
                    />
            );
        };

        return (<ul>{mapIndexed(renderImage, [
            ...images,
            ...ghostImages.map((res) => ({ ...res, isUploading: true }))
        ])}</ul>);
    }
);
