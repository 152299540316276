import { getSelectedNodeProperty } from "../../utils/nodeUtils/getSelectedNodeProperty";
import { makeExistingParentStyleSelector } from "../../utils/selectors/makeExistingParentStyleSelector";
import type { TinyMceEditor } from "../../../flowTypes";

export const isUnderline = (editor: TinyMceEditor) => {
    // This logic should be changed to, closest parent with text-decoration in computed style,
    // when globalstyle starts supporting underline
    const
        selector = makeExistingParentStyleSelector(editor, 'text-decoration'),
        textDecoration = getSelectedNodeProperty(editor, selector);

    return textDecoration === 'underline';
};
