import * as pageIds from "./pageIds";
import { ComponentNames } from "../../constants";
import HOVERBOX from "../kind";
import PageTitles from "../../Background/propertiesPanel/pageTitles";

export default {
    ...PageTitles,
    [pageIds.MAIN]: ComponentNames[HOVERBOX],
    [pageIds.HOVER]: 'msg: common.hover {Hover}',
    [pageIds.HOVER_EFFECT]: 'msg: common.hoverEffect {Hover effect}',
};
