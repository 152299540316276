import React from 'react';
import CircleSlider from "../../../view/common/CircleSlider/CircleSlider";
import NumberField from "../../../view/common/Input/NumberField";
import Label from "../../../view/common/Label";
import styles from "./index.css";

type Props = {
    label: string,
    value: number,
    onChange: (number) => void
};

export default (
    { label, value, onChange }: Props,
) => {
    const [typing, setTyping] = React.useState(false);

    return <div className={styles.container}>
        <div className={styles.commonLabel}>
            <Label>{label}</Label>
        </div>
        <div className={styles.sliderContainer}>
            <CircleSlider
                onChange={onChange}
                value={value}
            />
        </div>
        <div className={styles.inputContainer}>
            <NumberField
                value={typing ? value : `${value}${String.fromCharCode(176)}`}
                onFocus={() => setTyping(true)}
                onBlur={() => {
                    setTyping(false);
                    onChange(value % 360);
                }}
                onChange={onChange}
                className={styles.input}
                allowDecimals={false}
                usePropsValue
                max={360}
                min={0}
                maxLength={3}
            />
        </div>
    </div>;
};
