import { DEFAULT_WIDTH, DEFAULT_HEIGHT } from '../constants';
import { type PaypalComponent } from '../types';
import { parsePaypalPostCode } from './parsePaypalPostCode';

export const parsePaypalCode = (code: string): PaypalComponent => {
    let params = <PaypalComponent>{};
    if (code.length) {
        params = parsePaypalPostCode(code);
        if (params.scriptLink) {
            params.link = code;
        }
    }
    return params.link ? { width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT, ...params, } : {};
};
