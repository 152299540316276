import React from 'react';
import { WHITE_COLOR } from '../../../Menu/constants/colors';
import '../../view/Preview.css';
import { DATA_TEST_ID } from '../constants';

export const FacebookView = props => (
    <div data-testid={DATA_TEST_ID} className="widget" style={{ width: props.width, backgroundColor: WHITE_COLOR }}>
        <iframe src={`https://www.facebook.com/plugins/${props.postType}.php?href=${props.link}`} width="100%" height={props.height} style={{ border: 'none', overflow: 'hidden' }} />
    </div>
);
