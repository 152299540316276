// @ts-nocheck
import React from 'react';
import * as colorMapper from "../../../mappers/color";
import type { ColorPropertyPropTypes } from "./flowTypes";
import BaseColorProperty from './BaseColorProperty';
import { DEFAULT_COLOR } from "./constants";
import { setColorOpacity } from "../../oneweb/Background/utils/setColorOpacity";
import type { Color } from '../../../mappers/flowTypes';
import {
    THEME_COLOR_PICKER, THEME_BG_OVERRIDE_COLOR_PICKER, THEME_COLOR_OVERRIDE_COLOR_PICKER, DEFAULT_COLOR_PICKER
} from '../../ColorPicker/constants';
import { getColorsFromBackgroundTheme } from '../../ThemeGlobalData/themeRules';

export default (props: ColorPropertyPropTypes) => {
    const
        defaultColor = props.defaultColor || DEFAULT_COLOR,
        color = props.color || defaultColor,
        {
            label,
            onChangeAction,
            style,
            beforeOpen,
            dispatch,
            disabled,
            colorBoxStyle,
            title,
            colorPickerOpened,
            showCaret,
            containerClassName,
        } = props,
        isDefaultValue = (() => {
            if (props.colorPickerKind === THEME_BG_OVERRIDE_COLOR_PICKER) {
                return false;
            } else if (props.colorPickerKind === THEME_COLOR_OVERRIDE_COLOR_PICKER) {
                return !props.themeColor;
            } else if (props.colorPickerKind === THEME_COLOR_PICKER) {
                return !props.themeColor && !props.defaultThemeColor;
            } else {
                return color === defaultColor;
            }
        })(),
        colorBoxColor = (() => {
            if (THEME_COLOR_PICKER === props.colorPickerKind) {
                return !isDefaultValue && props.themeColorsData[props.themeColor || props.defaultThemeColor];
            } else if (THEME_BG_OVERRIDE_COLOR_PICKER === props.colorPickerKind) {
                const { backgroundColor } = getColorsFromBackgroundTheme(props.backgroundTheme, props.themeColorsData);
                return !isDefaultValue && props.themeColorsData[backgroundColor];
            } else if (THEME_COLOR_OVERRIDE_COLOR_PICKER === props.colorPickerKind) {
                return !isDefaultValue && props.themeColor && props.themeColorsData[props.themeColor];
            } else {
                const color: Color = props.color || defaultColor;
                return !isDefaultValue && (props.preserveColorBoxOpacity ? setColorOpacity(color, 100) : color);
            }
        })();

    const
        _colorBoxStyle = { ...colorBoxStyle, backgroundColor: colorBoxColor && colorMapper.toCss(colorBoxColor) },
        changeActionPayload = (() => {
            if (!props.colorPickerKind || props.colorPickerKind === DEFAULT_COLOR_PICKER) {
                return {
                    color, onChangeCompleteAction: onChangeAction, onChangeCompleteDebounced: { action: props.onChangeDebouncedAction }
                };
            }
            return { color, onChangeCompleteAction: onChangeAction };
        })();

    const commonColorPickerProps = {
        label,
        isDefaultValue,
        changeActionPayload,
        style,
        colorBoxStyle: _colorBoxStyle,
        beforeOpen,
        dispatch,
        disabled,
        title,
        colorPickerOpened,
        showCaret,
        containerClassName,
    };

    switch (props.colorPickerKind) {
        case THEME_COLOR_PICKER: {
            return (
                <BaseColorProperty
                    {...commonColorPickerProps}
                    themeColor={props.themeColor}
                    defaultThemeColor={props.defaultThemeColor}
                    colorPickerKind={THEME_COLOR_PICKER}
                    themeColorsData={props.themeColorsData}
                />
            );
        }
        case THEME_BG_OVERRIDE_COLOR_PICKER: {
            return (
                <BaseColorProperty
                    {...commonColorPickerProps}
                    backgroundTheme={props.backgroundTheme}
                    colorPickerKind={THEME_BG_OVERRIDE_COLOR_PICKER}
                    themeColorsData={props.themeColorsData}
                    tooltipTop={props.tooltipTop}
                />
            );
        }
        case THEME_COLOR_OVERRIDE_COLOR_PICKER: {
            return (
                <BaseColorProperty
                    {...commonColorPickerProps}
                    themeColor={props.themeColor}
                    colorPickerKind={THEME_COLOR_OVERRIDE_COLOR_PICKER}
                    themeColorsData={props.themeColorsData}
                    onRemoveAction={props.onRemoveAction}
                />
            );
        }
        default: {
            return (
                <BaseColorProperty
                    {...commonColorPickerProps}
                    color={props.color || defaultColor}
                    colorPickerKind={DEFAULT_COLOR_PICKER}
                    onRemoveAction={props.onRemoveAction}
                    onTheFlyChange={props.onTheFlyChange}
                />
            );
        }
    }
};
