import DataPage from "../../../../../../dal/model/DataPage";
import DataSite from "../../../../../../dal/model/DataSite";
import { isPageRef } from "../../../../../../dal/model/utils/dataSiteItemUtils";
import type { DataSiteItem } from "../../../../../../dal/model/utils/dataSiteItemUtils";

export default (site: DataSite, pages: Array<DataPage>) => {
    const pageToTemplateId: { [key: string]: string } = {};
    pages.forEach(page => {
        pageToTemplateId[page.id] = page.templateId;
    });

    const mapUpdatedItems = (items: Array<DataSiteItem>) => {
        if (!items || !items.length) return items;

        return items.map(item => {
            if (isPageRef(item)) {
                return {
                    ...item,
                    // $FlowFixMe
                    templateId: pageToTemplateId[item.pageId],
                    items: mapUpdatedItems(item.items),
                };
            }

            return item;
        });
    };

    return new DataSite({
        ...site,
        folder: {
            ...site.folder,
            items: mapUpdatedItems(site.getItems()),
        }
    });
};
