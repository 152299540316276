import React from 'react';
import styles from './Image.css';
import BrokenImage from './BrokenImage';
import BrokenImageText from './BrokenImageText';
import type { ImageView } from '../flowTypes';
import LazyImage from '../../../../view/common/LazyImage/index';
import { replaceTagsWithContent } from '../../Text/view/replaceTagsWithContent';

const
    wrapInA = ({ a, imageAttributes, isWorkspace, isSortableItem, globalVariables }) => {
        const dataAttrs = {};

        if (imageAttributes && imageAttributes.style) {
            dataAttrs['data-original-width'] = imageAttributes.style.width;
            dataAttrs['data-original-height'] = imageAttributes.style.height;
        }

        const
            imgProps = {
                ...dataAttrs,
                ...imageAttributes,
                // once the api is stable, convert "data-loading" to "loading"
                'data-loading': "lazy",
                role: "presentation",
                draggable: "false",
                onMouseDown: e => e.preventDefault && e.preventDefault(), // Image should not have native browser drag
            },
            img = isSortableItem && imgProps.src
                ? <LazyImage {...imgProps} showTransparency transparentClassName={styles.transparentImage} />
                : <img {...imgProps} loading="lazy" />; /* loading attr is now stable september 21, 2022*/

        if (a && !isWorkspace) {
            const { href, ...restHyperLinkProps } = a,
                hrefWithContentReplaced =
                    href && globalVariables ? replaceTagsWithContent(href, { globalVariables }, isWorkspace) : href;
            return <a href={hrefWithContentReplaced} {...restHyperLinkProps}>{img}</a>;
        } else {
            return img;
        }
    };

export default ({
    imageAvailable,
    divClassName = '',
    divStyle,
    a,
    imageAttributes,
    width,
    height,
    isWorkspace,
    isSortableItem,
    globalVariables,
}: ImageView) => {
    const keyProps = imageAttributes && imageAttributes.src ? { key: imageAttributes.src } : {};

    if (isWorkspace) {
        return (
            <div className={`${divClassName} img-wrapper`} style={divStyle} {...keyProps}>
                {imageAvailable && wrapInA({ a, imageAttributes, isWorkspace, isSortableItem, globalVariables })}
                {!imageAvailable && <BrokenImage width={width} height={height} />}
                {!imageAvailable && <BrokenImageText width={width} height={height} />}
            </div>
        );
    }

    return (
        <div className={`${divClassName} img-wrapper`} style={divStyle} {...keyProps}>
            {wrapInA({ a, imageAttributes, isWorkspace: false, isSortableItem, globalVariables })}
        </div>
    );
};
