import * as actions from '../actionTypes';
import { WIDGETS_TWITTER_EPIC_VAT } from "./valueActionType";
import { configurationDialogId } from '../configurationDialog';
import { parseTwitterCode } from '../utils/parseTwitterCode';
import { epicFactory } from '../../epics/factory';
import { linkExtractor } from '../../utils';

const WidgetsTwitterEpic = epicFactory({
    defaultState: null,
    valueActionType: WIDGETS_TWITTER_EPIC_VAT,
    configurationDialogId,
    codeParser: parseTwitterCode,
    payloadExtractor: linkExtractor,
    setLinkAction: actions.WIDGETS_TWITTER_TIMELINE_SET_LINK,
    updatePropsAction: actions.WIDGETS_TWITTER_TIMELINE_UPDATE_PROPS,
    showUpdateLinkDialogAction: actions.WIDGETS_TWITTER_TIMELINE_SHOW_UPDATE_LINK_DIALOG,
    closeDialogActions: [actions.WIDGETS_TWITTER_TIMELINE_UPDATE_LINK, actions.WIDGETS_TWITTER_TIMELINE_UPDATE_PROPS],
});

export { WidgetsTwitterEpic };

