import * as R from "ramda";
import * as path from "../../../mappers/path";
import { formatMessage } from "../../../view/intl/CurrentLocale";
import type { MenuComponent } from "./flowTypes";
import menuThemeStyleKind from "./globalStyle/themeColorKind";

export default (defaultState: MenuComponent, componentDependencies: Record<string, any>): MenuComponent => {
    const autoColorMode = componentDependencies.themeSettingsData && componentDependencies.themeSettingsData.autoColorMode;
    let mainMenuIdPath,
        mainMenuNamePath,
        submenuIdPath,
        submenuNamePath,
        mainMenuId,
        mainMenuName,
        submenuId,
        submenuName,
        subMenuId,
        subMenuName,
        updatedState;
    const {
            defaultStyleId,
            globalstyles: { styles }
        } = componentDependencies,
        style = styles.filter(({ id }) => id === defaultStyleId)[0],
        { subStyle } = defaultState;

    if (subStyle) {
        subMenuId = subStyle.globalId;
        subMenuName = subStyle.globalName;
    }

    mainMenuIdPath = path.styleGlobalId;
    mainMenuNamePath = path.styleGlobalName;
    submenuIdPath = path.subMenuStyleGlobalId;
    submenuNamePath = path.subMenuStyleGlobalName;
    mainMenuId = defaultStyleId;
    mainMenuName = style.name;
    submenuId = subMenuId || defaultStyleId;
    submenuName = subMenuName || style.name;
    updatedState = R.pipe(
        R.assocPath(mainMenuIdPath, mainMenuId),
        R.assocPath(mainMenuNamePath, mainMenuName),
        R.assocPath(submenuIdPath, submenuId),
        R.assocPath(submenuNamePath, submenuName),
        R.assoc(
            "moreText",
            formatMessage({
                id: "common.More",
                defaultMessage: "More"
            })
        )
    )(defaultState);

    if (autoColorMode) {
        mainMenuIdPath = path.themeMainMenuIdPath;
        mainMenuNamePath = path.themeMainMenuNamePath;
        submenuIdPath = path.themeSubmenuIdPath;
        submenuNamePath = path.themeSubmenuNamePath;
        const menuThemeStyles = componentDependencies.globalstyles.styles.filter(style => style.type === menuThemeStyleKind),
            stylesheetsIdToNameMap = componentDependencies.stylesheetsIdToNameMap;
        mainMenuId = menuThemeStyles[0].id;
        mainMenuName = stylesheetsIdToNameMap[mainMenuId];
        submenuId = mainMenuId;
        submenuName = mainMenuName;
        updatedState = R.pipe(
            R.assocPath(mainMenuIdPath, mainMenuId),
            R.assocPath(mainMenuNamePath, mainMenuName),
            R.assocPath(submenuIdPath, submenuId),
            R.assocPath(submenuNamePath, submenuName)
        )(updatedState);
    }

    return updatedState;
};
