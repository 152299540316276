import React from "react";
import { Dialog, DialogBody } from '../../../../../view/common/dialogs/baseDialog/index';
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import { CLOSE_DIALOG } from "../../../../../redux/modules/actionTypes";
import * as styles from './PreviewDialog.css';

const
    PreviewDialogWidth = parseInt(styles.PreviewDialogWidth, 10),
    PreviewDialogHeight = parseInt(styles.PreviewDialogHeight, 10),
    PreviewVideoWidth = parseInt(styles.PreviewVideoWidth, 10),
    PreviewVideoHeight = parseInt(styles.PreviewVideoHeight, 10);

const PreviewDialog = ({ dispatch, url }) => {
    return (
        <Dialog onClose={() => { dispatch({ type: CLOSE_DIALOG }); }}>
            <DialogBody className={styles.previewVideo}>
                <iframe src={url} width={PreviewVideoWidth} height={PreviewVideoHeight} />
            </DialogBody>
        </Dialog>
    );
};

export default getCenteredDialogConfig(PreviewDialogWidth, PreviewDialogHeight, PreviewDialog);
