import { replaceDialog, openDialog } from "../../../App/actionCreators";
import { ConnectToGoogleDialogId } from "../dialog/dialogIds";
import { REVIEW_WIDGET_CONFIG_SUCCESS } from "./actionTypes";

const reviewConfigSuccessAction = () => ({ type: REVIEW_WIDGET_CONFIG_SUCCESS });
const replaceWithConnectToReviewDialog = (): Action => replaceDialog(ConnectToGoogleDialogId);
const openConnectToReviewDialog = (props) => openDialog(ConnectToGoogleDialogId, props);

export {
    replaceWithConnectToReviewDialog,
    openConnectToReviewDialog,
    reviewConfigSuccessAction
};
