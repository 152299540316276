import type { TopRightPosition } from '../flowTypes';
import type { Dimensions } from "../../../redux/modules/children/workspace/flowTypes";
import { TopBarHeight } from "../../TopBar/constants";

export const
    DefaultPadding = 20,
    DefaultTopRightPosition: TopRightPosition = { // relative to browser
        top: DefaultPadding + TopBarHeight,
        right: DefaultPadding
    },
    DefaultDimensions: Dimensions = {
        height: 300,
        width: 290
    },
    SettingsContainerHeight: number = 49,
    MaxPropertiesPanelOffset: number = 40,
    DIRECTIONFORWARD = 'FORWARD',
    DIRECTIONBACKWARD = 'BACKWARD',
    PROPERTIES_PANEL_WIDTH = DefaultDimensions.width;
