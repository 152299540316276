import type { Style } from '../../flowTypes';

export const isOrderedList = ({ bullet }: Style): boolean => {
    return /^(?:decimal|lower-alpha|upper-alpha|lower-roman|upper-roman)$/.test(bullet || '');
};

export const isUnorderedList = ({ bullet }: Style): boolean => {
    return /^(?:disc|circle|square)$/.test(bullet || '');
};

export default (style: Style): boolean => {
    return isOrderedList(style) || isUnorderedList(style);
};
