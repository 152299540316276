import type { TinyMceEditor } from "../../../flowTypes";
import { makeExistingParentTagSelector } from "../../utils/selectors/makeExistingParentTagSelector";
import { getSelectedText } from "./getSelectedText";
import { VALID_CONTENT_BLOCK_TAGS } from '../../../../../../oneweb/Text/constants';

export const canSetLink = (editor: TinyMceEditor) => {
    if (getSelectedText(editor) === '') {
        return false;
    }

    const startNode = editor.selection.getStart();
    const endNode = editor.selection.getEnd();

    if (startNode === endNode) {
        return true;
    }

    const parentBlockSelector = makeExistingParentTagSelector(editor.bodyElement, VALID_CONTENT_BLOCK_TAGS);

    const startBlock = parentBlockSelector(startNode);
    const endBlock = parentBlockSelector(endNode);

    return startBlock === endBlock;
};
