/* eslint-disable max-len */
import { makeEpic } from "../../../epics/makeEpic";
import {
    LOGO_MCTA_SET_LOGO_PRESSED,
    LOGO_MCTA_LOGO_ASSET_CHOOSEN,
    LOGO_MCTA_HORIZONTAL_ALIGNMENT_MOUSE_LEAVE,
    LOGO_MCTA_HORIZONTAL_ALIGNMENT_MOUSE_ENTER,
    LOGO_PP_TEXT_STYLE_FONT_FAMILY_CHANGED,
    LOGO_PP_TEXT_STYLE_BOLD_TOGGLE,
    LOGO_PP_TEXT_STYLE_ITALIC_TOGGLE,
    LOGO_PP_TEXT_STYLE_UNDERLINE_TOGGLE,
    LOGO_PP_TEXT_STYLE_CHARACTER_SPACING_CHANGED,
    LOGO_PP_TEXT_STYLE_COLOR_CHANGED,
    LOGO_PP_TEXT_STYLE_COLOR_CHANGED_AUTO_COLOR,
} from './actionTypes';
import { openImageChooserAction } from "../../FileManager/imageChooser/actions";
import {
    GS_TEXT_LOGO_FONT_FAMILY_CHANGED,
    GS_TEXT_LOGO_FONT_TOGGLE_BOLD,
    GS_TEXT_LOGO_FONT_TOGGLE_UNDERLINE,
    GS_TEXT_LOGO_TEXT_CHARACTER_SPACING_CHANGED,
    GS_TEXT_LOGO_FONT_COLOR_CHANGED,
    GS_TEXT_LOGO_FONT_COLOR_CHANGED_AUTO_COLOR,
    GS_TEXT_LOGO_FONT_TOGGLE_ITALIC
} from "../../Globalstyles/Text/actionTypes";
import { FcContentTypes } from "../../../redux/modules/children/fileChooser/FcContentTypes";

const forwardToGlobalStylesEpic = (inputActionType, outputActionType) => ({
    conditions: [inputActionType],
    reducer: ({ values: [payload], state }) => ({
        state,
        actionToDispatch: ({
            type: outputActionType,
            payload
        } as any),
    }),
});

export const logoServiceEpic = makeEpic({
    valueActionType: "LOGO_EPIC_VALUE",
    defaultState: {
        horizontalAlignmentMctaExpanded: false
    },
    updaters: [
        {
            conditions: [LOGO_MCTA_SET_LOGO_PRESSED],
            reducer: ({ state }) => ({
                state,
                actionToDispatch: openImageChooserAction({
                    onSaveAction: LOGO_MCTA_LOGO_ASSET_CHOOSEN,
                    isMultiSelect: false,
                    extraImageContentTypes: FcContentTypes.SVG
                })
            })
        },
        forwardToGlobalStylesEpic(LOGO_PP_TEXT_STYLE_FONT_FAMILY_CHANGED, GS_TEXT_LOGO_FONT_FAMILY_CHANGED),
        forwardToGlobalStylesEpic(LOGO_PP_TEXT_STYLE_BOLD_TOGGLE, GS_TEXT_LOGO_FONT_TOGGLE_BOLD),
        forwardToGlobalStylesEpic(LOGO_PP_TEXT_STYLE_ITALIC_TOGGLE, GS_TEXT_LOGO_FONT_TOGGLE_ITALIC),
        forwardToGlobalStylesEpic(LOGO_PP_TEXT_STYLE_UNDERLINE_TOGGLE, GS_TEXT_LOGO_FONT_TOGGLE_UNDERLINE),
        forwardToGlobalStylesEpic(LOGO_PP_TEXT_STYLE_CHARACTER_SPACING_CHANGED, GS_TEXT_LOGO_TEXT_CHARACTER_SPACING_CHANGED),
        forwardToGlobalStylesEpic(LOGO_PP_TEXT_STYLE_COLOR_CHANGED, GS_TEXT_LOGO_FONT_COLOR_CHANGED),
        forwardToGlobalStylesEpic(LOGO_PP_TEXT_STYLE_COLOR_CHANGED_AUTO_COLOR, GS_TEXT_LOGO_FONT_COLOR_CHANGED_AUTO_COLOR),
    ],
    handleActions: {
        [LOGO_MCTA_HORIZONTAL_ALIGNMENT_MOUSE_ENTER]: state => ({
            ...state,
            horizontalAlignmentMctaExpanded: true
        }),
        [LOGO_MCTA_HORIZONTAL_ALIGNMENT_MOUSE_LEAVE]: state => ({
            ...state,
            horizontalAlignmentMctaExpanded: false
        })
    }
});

