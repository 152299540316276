import { types } from "./constants";

import TableNormalBackgroundDialogId from "./dialogs/TableNormalBackgroundDialogId";
import TableHeading1BackgroundDialogId from "./dialogs/TableHeading1BackgroundDialogId";
import TableHeading2BackgroundDialogId from "./dialogs/TableHeading2BackgroundDialogId";
import TableAlternateBackgroundDialogId from "./dialogs/TableAlternateBackgroundDialogId";

import TableNormalBorderDialogId from "./dialogs/TableNormalBorderDialogId";
import TableHeading1BorderDialogId from "./dialogs/TableHeading1BorderDialogId";
import TableHeading2BorderDialogId from "./dialogs/TableHeading2BorderDialogId";
import TableAlternateBorderDialogId from "./dialogs/TableAlternateBorderDialogId";

import TableNormalTextShadowDialogId from "./dialogs/TableNormalTextShadowDialogId";
import TableHeading1TextShadowDialogId from "./dialogs/TableHeading1TextShadowDialogId";
import TableHeading2TextShadowDialogId from "./dialogs/TableHeading2TextShadowDialogId";
import TableAlternateTextShadowDialogId from "./dialogs/TableAlternateTextShadowDialogId";

import TableNormalCellSpacingDialogId from "./dialogs/TableNormalCellSpacingDialogId";
import TableHeading1CellSpacingDialogId from "./dialogs/TableHeading1CellSpacingDialogId";
import TableHeading2CellSpacingDialogId from "./dialogs/TableHeading2CellSpacingDialogId";
import TableAlternateCellSpacingDialogId from "./dialogs/TableAlternateCellSpacingDialogId";

export const
    BackgroundDialogIds = {
        [types.NORMAL]: TableNormalBackgroundDialogId,
        [types.HEADING1]: TableHeading1BackgroundDialogId,
        [types.HEADING2]: TableHeading2BackgroundDialogId,
        [types.ALTERNATE]: TableAlternateBackgroundDialogId
    },
    BorderDialogIds = {
        [types.NORMAL]: TableNormalBorderDialogId,
        [types.HEADING1]: TableHeading1BorderDialogId,
        [types.HEADING2]: TableHeading2BorderDialogId,
        [types.ALTERNATE]: TableAlternateBorderDialogId
    },
    TextShadowDialogIds = {
        [types.NORMAL]: TableNormalTextShadowDialogId,
        [types.HEADING1]: TableHeading1TextShadowDialogId,
        [types.HEADING2]: TableHeading2TextShadowDialogId,
        [types.ALTERNATE]: TableAlternateTextShadowDialogId
    },
    CellSpacingDialogIds = {
        [types.NORMAL]: TableNormalCellSpacingDialogId,
        [types.HEADING1]: TableHeading1CellSpacingDialogId,
        [types.HEADING2]: TableHeading2CellSpacingDialogId,
        [types.ALTERNATE]: TableAlternateCellSpacingDialogId
    };
