import React, { useEffect } from 'react';
import cx from 'classnames';
import { connect } from "react-redux";
import { Intl, Msg } from '../../../../../view/intl/index';
import * as styles from '../onBoardingPanel.css';
import {
    GOTO_NEXT_STEP_ONBOARDING_PANEL,
} from '../../../actionTypes';
import { SHOW_TOOLTIP_FOR_MODERN_LAYOUT } from "../../../../Tooltip/actionTypes";
import { ModernLayoutInfoTooltip } from "../../../../Tooltip/ids";
import { makeEpicStateSelector } from "../../../../../epics/makeEpic";
import modernLayoutInfoTooltipVAT from "../../../../Tooltip/epics/modernLayoutInfoTooltip/valueActionType";
import { welcomeSubTitle } from '../../../constants';

type Props = {
    showModernLayoutTooltip: boolean,
    intl: Intl,
    dispatch: Dispatch,
};

const OnBoardingWelcome = ({
    intl,
    dispatch,
    showModernLayoutTooltip
}: Props) => {
    useEffect(() => {
        if (showModernLayoutTooltip) {
            const customHTML = <div className={styles.tooltipContainer}>
                <div className={styles.tooltipHeader}>
                    <div className={styles.tooltipHeaderText}>
                        {intl.msgJoint('msg: tooltipInfo.onBoardingPanel.header {New!}')}
                    </div>
                </div>
                <div className={styles.tooltipBody}>
                    {intl.msgJoint('msg: tooltipInfo.onBoardingPanel.body {Check out the new header and footer layouts!}')} {/*eslint-disable-line*/}
                </div>
            </div>;
            dispatch({
                type: SHOW_TOOLTIP_FOR_MODERN_LAYOUT,
                payload: {
                    position: {
                        x: 275,
                        y: 350
                    },
                    elementDimensions: { top: 350, width: 275, height: 0, left: 275 },
                    id: ModernLayoutInfoTooltip,
                    customClass: styles.onBoardingToolTip,
                    customHTML
                }
            });
        }
    }, []);

    return <div>
        <div className={styles.welcomeImage} />
        <div className={styles.welcomeSubTitleWrapper}>
            <div className={cx(styles.welcomeSubTitle)}>
                {intl.msgJoint(welcomeSubTitle)}
            </div>
        </div>
        <div className={styles.tryItBtnWrapper}>
            <button
                type="button"
                className={styles.tryItBtn}
                onClick={() => {
                    dispatch({ type: GOTO_NEXT_STEP_ONBOARDING_PANEL });
                }}
            >
                <Msg k="common.tryItOut">
                    Try it out
                </Msg>
            </button>
        </div>
        {/* <div className={styles.learnMoreWrapper}>
            <a
                className={styles.learnMoreLink}
                target="_blank"
            >
                {intl.msgJoint("msg: common.learnMore {Learn more}")}
            </a>
        </div> */}
    </div>;
};

const mapStateToProps = (appState) => ({
    showModernLayoutTooltip: makeEpicStateSelector(modernLayoutInfoTooltipVAT)(appState).show
});

export default connect(mapStateToProps)(OnBoardingWelcome);
