import view from "./view/index";
import { SOCIAL_KIND } from "./kind";
import { socialCalcRenderProps } from "./calcRenderProps";
import { socialGetSpecificStyles } from "./socialGetSpecificStyle";
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";

export default {
    kind: SOCIAL_KIND,
    view,
    // @ts-ignore
    calcProps: ({ component, siteSettings, globalStyles, selectedParentTheme }: any) => socialCalcRenderProps({
        componentDependencies: {
            socialData: siteSettings.socialData,
            themeSettingsData: siteSettings.themeSettingsData,
            themeColorsData: getThemeColorsFromStylesheet(globalStyles),
        },
        selectedParentTheme,
        component
    }),
    getSpecificStyles: socialGetSpecificStyles
};
