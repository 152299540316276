import { PUBLISH, SAVE_AND_PUBLISH_BUTTON_CLICKED, UPGRADE_FAILURE, UPGRADE_SUCCESS } from "../../../App/actionTypes";
import { isPagesOrSectionLinksLimitExceededGivenSiteDataAndSubscriptionType } from
    "../../../App/epics/siteData/utils/pages";
import closeDialog from "../../../App/actionCreators/closeDialog";
import { calculateUpgradeTierBeforeSeamlessUpgrade } from "../../../ComponentTierManager/actionCreators";
import publishSiteActionCreator from '../../actionCreators/publishSite';
import { sendEventToAec, sendShownErrorEventToAecAC } from "../../../App/actionCreators/sendEventToAecActionCreator";
import { APP_EVENT_COLLECTOR } from "../../constants";
import * as Actions from '../../actionTypes';
import { SaveStatus } from "../../../Workspace/epics/saveStatus/SaveStatus";
import { openDialog, updateDialog } from "../../../App/actionCreators";
import * as dialogIds from '../../view/dialogIds';
import { PAGE_DATASET_SAVE_SUCCESS } from "../../../App/epics/pageDataset/actions";
import { LOAD_PAID_FEATURES_DOC_SUCCESS } from "../../../Workspace/epics/saveStatus/actionTypes";
import {
    PUBLISH_DISPATCH_AFTER_PREMIUM_REQUEST_COMPLETE,
    SET_PREMIUM_FEATURE_REQUEST_IN_PROGRESS,
    IS_PREMIUM_COMPONENT_REQUEST_IN_PROGRESS_CHANGED_ACTION
} from "../../../ComponentTierManager/actionTypes";
import { getEpicStateFromAppStateSelector } from '../../../../redux/modules/selectors';
import { valueActionType as SiteDataValueActionType } from '../../../App/epics/siteData/valueActionType';
import SubscriptionDataValueActionType from '../../../App/epics/subscriptionData/valueActionType';
import WorkspaceSaveStatusValueActionType from '../../../Workspace/epics/saveStatus/valueActionType';
import ComponentTierManagerEpicValueActionType from '../../../ComponentTierManager/epics/valueActionType';
import { PUBLISH_CHECK_PREMIUM_FEATURES_ACTION, PUBLISH_CONFIRMED, PUBLISH_CONFIRM_BUTTON_CLICKED, PUBLISH_REQUESTED } from "./actionTypes";
import { hasNoPublicHomePage } from "./utils";
import { currentPageIdEpicStateFromAppStateSelector } from "../../../App/epics/currentPageId";

import type { ExecuteActionConfig } from '../../../../../../src/dashboard/src/common/reduxUtils';
import { executeAction } from '../../../../../../src/dashboard/src/common/reduxUtils';
import { upgradeCanceledAction } from "../../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions";
import { FEATURE_PUBLISH } from "../../view/Upgrade/tracking/contants";
import { UpgradeCancelReason } from "../../../EventTracking/constants";

export const publishSiteMiddleware = (store: Store) => {
    const scope = {
        publishAfterSave: false,
        publishAfterCheckPremiumFeatures: false,
    };

    const isIncompatibleComponentPresent = (appState) => {
        const
            componentTierManagerState = getEpicStateFromAppStateSelector(appState, ComponentTierManagerEpicValueActionType),
            { isIncompatibleComponentPresent } = componentTierManagerState;

        return isIncompatibleComponentPresent;
    };

    const isUpgradeRequired = (appState) => {
        const
            siteData = getEpicStateFromAppStateSelector(appState, SiteDataValueActionType),
            subscriptionData = getEpicStateFromAppStateSelector(appState, SubscriptionDataValueActionType);

        return isIncompatibleComponentPresent(appState)
            || isPagesOrSectionLinksLimitExceededGivenSiteDataAndSubscriptionType(siteData, subscriptionData.subscriptionType);
    };

    const shouldShowUpgradeDialog = (appState) => {
        return isUpgradeRequired(appState);
    };

    return (next: Dispatch) => (action: AnyAction) => {
        const
            appState = store.getState(),
            siteData = getEpicStateFromAppStateSelector(appState, SiteDataValueActionType),
            saveStatus = getEpicStateFromAppStateSelector(appState, WorkspaceSaveStatusValueActionType),
            canSave = saveStatus === SaveStatus.CAN_SAVE,
            componentTierManagerState = getEpicStateFromAppStateSelector(appState, ComponentTierManagerEpicValueActionType),
            { isPremiumComponentRequestInProgress } = componentTierManagerState;

        const executeActionConfig: ExecuteActionConfig = { dispatch: store.dispatch, action, next };

        switch (action.type) {
            case Actions.PUBLISH_BUTTON_CLICKED:
                executeActionConfig.actionsToDispatch = [
                    { type: PUBLISH_REQUESTED }
                ];
                break;

            case SAVE_AND_PUBLISH_BUTTON_CLICKED:
                scope.publishAfterSave = true;
                executeActionConfig.actionsToDispatch = [
                    closeDialog(),
                    { type: SET_PREMIUM_FEATURE_REQUEST_IN_PROGRESS }
                ];
                break;

            case PAGE_DATASET_SAVE_SUCCESS:
                if (scope.publishAfterSave) {
                    scope.publishAfterSave = false;

                    if (isPremiumComponentRequestInProgress) {
                        scope.publishAfterCheckPremiumFeatures = true;
                    } else {
                        executeActionConfig.actionsToDispatch = [
                            { type: PUBLISH_CONFIRMED }
                        ];
                    }
                }
                break;

            case LOAD_PAID_FEATURES_DOC_SUCCESS:
                if (scope.publishAfterCheckPremiumFeatures) {
                    scope.publishAfterCheckPremiumFeatures = false;
                    executeActionConfig.actionsToDispatch = [
                        { type: PUBLISH_CONFIRMED }
                    ];
                }
                break;

            case PUBLISH_REQUESTED:
                if (hasNoPublicHomePage(siteData)) {
                    executeActionConfig.actionsToDispatch = [
                        openDialog(dialogIds.PublishWarningNoStartPageDialogId, { saveAndPublish: canSave }),
                        sendShownErrorEventToAecAC("no start page is set")
                    ];
                } else if (canSave) {
                    executeActionConfig.actionsToDispatch = [
                        openDialog(dialogIds.SaveAndPublishConfirmationDialogId)
                    ];
                } else {
                    executeActionConfig.actionsToDispatch = [
                        openDialog(dialogIds.PublishConfirmationDialogId)
                    ];
                }
                break;

            case PUBLISH_CONFIRMED:
            case PUBLISH_CONFIRM_BUTTON_CLICKED:
                {
                    const showUpgradeDialog = shouldShowUpgradeDialog(appState);

                    if (showUpgradeDialog) {
                        executeActionConfig.actionsToDispatch = [
                            closeDialog(),
                            calculateUpgradeTierBeforeSeamlessUpgrade("publish:publishCheck", FEATURE_PUBLISH)
                        ];
                    } else {
                        executeActionConfig.actionsToDispatch = [
                            { type: PUBLISH_CHECK_PREMIUM_FEATURES_ACTION }
                        ];
                    }
                }
                break;

            case PUBLISH_CHECK_PREMIUM_FEATURES_ACTION:
                scope.publishAfterCheckPremiumFeatures = true;
                if (!isPremiumComponentRequestInProgress) {
                    executeActionConfig.actionsToDispatch = [
                        { type: PUBLISH_DISPATCH_AFTER_PREMIUM_REQUEST_COMPLETE }
                    ];
                }
                break;

            case IS_PREMIUM_COMPONENT_REQUEST_IN_PROGRESS_CHANGED_ACTION:
            case PUBLISH_DISPATCH_AFTER_PREMIUM_REQUEST_COMPLETE:
                if (scope.publishAfterCheckPremiumFeatures && !isPremiumComponentRequestInProgress) {
                    scope.publishAfterCheckPremiumFeatures = false;
                    const showUpgradeDialog = shouldShowUpgradeDialog(appState);

                    if (showUpgradeDialog) {
                        executeActionConfig.actionsToDispatch = [
                            closeDialog(),
                            calculateUpgradeTierBeforeSeamlessUpgrade("publish:publishCheck", FEATURE_PUBLISH)
                        ];
                    } else {
                        executeActionConfig.actionsToDispatch = [
                            { type: PUBLISH }
                        ];
                    }
                }
                break;

            case PUBLISH:
                {
                    const currentPageId = currentPageIdEpicStateFromAppStateSelector(appState);
                    let publishData = action.payload;
                    if (!publishData) {
                        publishData = { force: false };
                    }
                    publishData.currentPageId = currentPageId;

                    executeActionConfig.actionsToDispatch = [
                        // @ts-ignore
                        publishSiteActionCreator(publishData),
                        sendEventToAec({
                            category: APP_EVENT_COLLECTOR.CATEGORY,
                            action: APP_EVENT_COLLECTOR.ACTION
                        }),
                    ];
                }
                break;

            case Actions.SEAMLESS_UPGRADE_REQUEST:
                executeActionConfig.actionsToDispatch = [
                    updateDialog(dialogIds.SeamlessUpgradeDialogId, { isUpgrading: true })
                ];
                break;

            case Actions.SEAMLESS_UPGRADE_SUCCESS:
                executeActionConfig.actionsToDispatch = [
                    closeDialog(),
                    openDialog(dialogIds.UpgradeSuccessfulDialogId)
                ];
                break;

            case Actions.SEAMLESS_UPGRADE_FAILURE:
                executeActionConfig.actionsToDispatch = [
                    closeDialog(),
                    openDialog(dialogIds.UpgradeFailureDialogId),
                    upgradeCanceledAction(UpgradeCancelReason.Error)
                ];
                break;

            case UPGRADE_SUCCESS:
                executeActionConfig.actionsToDispatch = [
                    closeDialog(),
                    { type: Actions.PUBLISH_BUTTON_CLICKED }
                ];
                break;

            case UPGRADE_FAILURE:
                executeActionConfig.actionsToDispatch = [
                    closeDialog(),
                    openDialog(dialogIds.UpgradeFailureDialogId),
                    upgradeCanceledAction(UpgradeCancelReason.Error)
                ];
                break;

            default:
                break;
        }

        return executeAction(executeActionConfig);
    };
};
