import * as React from 'react';
import cx from 'classnames';
import Button from './Button';
import styles from "./Button.css";

type PropTypes = {
    className ?: string,
    style ?: Record<string, any>,
    disabled ?: boolean,
    onClick: (e: React.MouseEvent<any>) => void,
    onKeyPress?: (e: KeyboardEvent) => void,
    autoFocus ?: boolean,
    children ?: any,
    isIntl?: boolean,
    isLarge?: boolean,
    dataTid?: string,
}

export default React.forwardRef(

    ({ isLarge, ...restProps }: PropTypes, ref: React.ForwardedRef<any>) => {
        return (<Button
            {...restProps}
            className={cx(
                styles.button,
                { [styles.buttonPrimary]: !isLarge },
                { [styles.buttonPrimaryLg]: isLarge },
                { [styles.disabled]: restProps.disabled },
                restProps.className
            )}
            ref={ref}
        >
            {restProps.children}
        </Button>
        );
    }
);

export const PrimaryButtonType = 'PrimaryButton';
