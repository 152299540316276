import { GroupTypes } from "../../../constants";
import Images from "./Images";
import ImageAndText from "./ImageAndText";
import TextAndText from "./TextAndText";

export default {
    [GroupTypes.images]: Images,
    [GroupTypes.imageAndText]: ImageAndText,
    [GroupTypes.textAndText]: TextAndText
};
