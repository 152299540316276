import { applyMappers, makePlainMappers } from "../../utils";

const getPlainPropsMapperProps = state => Object
    .keys(state)
    .reduce(
        (acc, key) => ({ ...acc, [`${key}`]: key }), {}
    );

export const makeWidgetMapper = widgetDefaultState => {
    const plainPropsMapper = makePlainMappers(getPlainPropsMapperProps(widgetDefaultState));

    return {
        to: component => applyMappers(component, plainPropsMapper.to),
        back: component => applyMappers(component, plainPropsMapper.back),
    };
};
