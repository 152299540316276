import * as R from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import returnOldStateIfNotChanged from '../../../../epics/returnOldStateIfNotChanged';
import valueActionType from './valueActionType';
import selectionFrameDecorationValueActionType from "../selectionFrameDecoration/valueActionType";
import workspaceBBoxValueActionType from '../workspaceBBox/valueActionType';
import * as interactionModes from '../componentsEval/userInteractionMutations/interactionModes';
import {
    getComponentsIdsInBBox, getComponentsBBox, zeroBBox, getComponentBBox
} from "../../../../utils/componentsMap/index";
import {
    ForDecosSelector,
    UserInteractionModeSelector,
    ReceiveOnlyComponentsMap,
    ComponentsMapSelector,
    SelectedComponentsIdsSelector
} from '../componentsEval/selectorActionTypes';
import {
    getHoverBoxDecorations as _getHoverBoxDecorations,
    sanitizeSelectedComponentsForHoverBox
} from "../../../oneweb/HoverBox/utils";
import type { ComponentsMap, BBox } from '../../../../redux/modules/children/workspace/flowTypes';
import { isShiftBarHandle, isShiftBarTop } from '../../../../utils/handle/index';
import isStretchComponentKind from '../../../oneweb/isStretchComponentKind';
import type { ComponentsSelectionDecorationState } from "./flowTypes";
import { TopmostHandleKindSelector } from "../topMostHandle/selectorActionTypes";
import { isLeftMouseDownVAT } from "../isLeftMouseDown/valueActionType";
import { isSectionComponent, commonSectionForComponents } from "../../../oneweb/Section/utils";
import { ReceiveOnlyAttachments } from "../componentAttachements/selectorActionTypes";
import { constructSectionHoverDecorations } from "../componentHoverDecorations/index";
import { memoMaxOne } from "../../../../../utils/memo";
import { transientInteractionModesByMouse } from "../componentsEval/userInteractionMutations/interactionModes";
import { isHoverBoxKind } from "../../../oneweb/componentKinds";

const
    defaultState: ComponentsSelectionDecorationState = {
        bBox: zeroBBox,
        componentsBBoxes: [],
        isBorderVisible: false,
        handlesIsVisible: false,
        topLineIsVisible: false,
        bottomLineIsVisible: false,
        workspaceBBox: zeroBBox,
        selectionShiftBarVisible: false,
        containsStrip: false,
        containsSection: false,
        isMouseDown: false,
        topMostHandleKind: null,
        sectionDecorations: null,
        hoverBoxDecorations: null
    },
    visibleUserInteractionModes = {
        [interactionModes.IDLE]: true,
        [interactionModes.MOUSE_DOWN_ON_HANDLE]: true,
        ...interactionModes.transientInteractionModesByMouse
    },
    shiftBarVisibleUserInteractionModes = {
        [interactionModes.IDLE]: true,
        [interactionModes.SHIFTBAR_MOVING]: true,
        [interactionModes.MOUSE_DOWN_ON_HANDLE]: true
    };

function getBBox(componentsMap: ComponentsMap, componentsIds: Array<string>, workspaceBBox: BBox): BBox {
    return getComponentsBBox(componentsIds.map(id => componentsMap[id]), workspaceBBox);
}

const
    setHandlesVisibility = R.assoc('handlesIsVisible'),
    getComponentsBBoxes = (componentsMap, workspaceBBox, componentsIds) => componentsIds.map(
        id => getComponentBBox(componentsMap[id], workspaceBBox)
    ),
    getHoverBoxDecorations = memoMaxOne(_getHoverBoxDecorations);

/* todo userInteraction.payload.isShifted, userInteraction.payload.prevSelectedComponentsIds */
export default makeEpic({
    defaultState,
    valueActionType,
    afterUpdate: ({ prevState, nextState }) => {
        if (prevState !== nextState && R.equals(prevState, nextState)) {
            return { state: prevState };
        }

        return { state: nextState };
    },
    updaters: [
        {
            conditions: [
                ForDecosSelector,
                workspaceBBoxValueActionType,
                selectionFrameDecorationValueActionType,
                ReceiveOnlyAttachments,
                ReceiveOnlyComponentsMap,
            ],
            reducer: returnOldStateIfNotChanged(({
                values: [
                    {
                        componentsMapNoGhosts,
                        selectedComponentsIdsNoGhosts,
                        userInteractionMode,
                        userInteractionComponentsIds,
                        userInteractionHandleKind,
                    },
                    workspaceBBox,
                    selectionFrameDecoration,
                    attachments,
                    componentsMap,
                ],
                state
            }) => {
                const
                    hoverBoxDecorations = state.hoverBoxDecorations,
                    linesAreVisible = userInteractionMode === interactionModes.SHIFTBAR_MOVING
                        || (isShiftBarHandle(userInteractionHandleKind) &&
                            userInteractionMode === interactionModes.MOUSE_DOWN_ON_HANDLE),
                    topLineIsVisible = linesAreVisible && isShiftBarTop(userInteractionHandleKind),
                    bottomLineIsVisible = linesAreVisible && !isShiftBarTop(userInteractionHandleKind);

                const containsSection = selectedComponentsIdsNoGhosts.some(id => isSectionComponent(componentsMapNoGhosts[id])),
                    containsStrip = containsSection || selectedComponentsIdsNoGhosts.some(
                        id => isStretchComponentKind(componentsMapNoGhosts[id].kind, componentsMapNoGhosts[id].stretch)
                    ),
                    sectionForSelectedComponents =
                        commonSectionForComponents(selectedComponentsIdsNoGhosts, attachments, componentsMap),
                    sectionDecorations = !containsSection ? constructSectionHoverDecorations(
                        sectionForSelectedComponents,
                        selectedComponentsIdsNoGhosts,
                        componentsMap,
                        workspaceBBox,
                        state.sectionDecorations
                    ) : null;

                // Don't show shift bar if wrapped component is selected
                const containsWrappedComponent = selectedComponentsIdsNoGhosts.some(
                    id => componentsMapNoGhosts[id].wrap
                );

                if (selectionFrameDecoration.isVisible) {
                    let
                        componentsIds = getComponentsIdsInBBox(
                            componentsMapNoGhosts,
                            workspaceBBox,
                            selectionFrameDecoration.bBox,
                        );

                    if (
                        selectionFrameDecoration.ctrlLikePressedOnMouseDown
                        || selectionFrameDecoration.shiftPressedOnMouseDown
                    ) {
                        componentsIds = [...componentsIds, ...selectedComponentsIdsNoGhosts];
                    }
                    componentsIds = sanitizeSelectedComponentsForHoverBox(componentsIds, attachments, componentsMap);

                    if (componentsIds.length === 0) {
                        return { state: defaultState };
                    } else {
                        const newState: ComponentsSelectionDecorationState = {
                            bBox: getBBox(
                                componentsMapNoGhosts,
                                componentsIds,
                                workspaceBBox
                            ),
                            isBorderVisible: false,
                            componentsBBoxes: getComponentsBBoxes(
                                componentsMapNoGhosts,
                                workspaceBBox,
                                componentsIds
                            ),
                            workspaceBBox,
                            topLineIsVisible,
                            bottomLineIsVisible,
                            selectionShiftBarVisible: shiftBarVisibleUserInteractionModes[userInteractionMode]
                            && !containsWrappedComponent,
                            containsStrip,
                            containsSection,
                            sectionDecorations,
                            hoverBoxDecorations,
                            isMouseDown: state.isMouseDown,
                            topMostHandleKind: state.topMostHandleKind,
                            handlesIsVisible: state.handlesIsVisible
                        };

                        return {
                            state: newState
                        };
                    }
                } else {
                    const
                        componentsIds = selectedComponentsIdsNoGhosts.length === 0 ?
                            userInteractionComponentsIds : selectedComponentsIdsNoGhosts,
                        newState: ComponentsSelectionDecorationState = {
                            bBox: getBBox(componentsMapNoGhosts, componentsIds, workspaceBBox),
                            componentsBBoxes: getComponentsBBoxes(
                                componentsMapNoGhosts,
                                workspaceBBox,
                                componentsIds
                            ),
                            isBorderVisible: (componentsIds.length > 0)
                            && visibleUserInteractionModes[userInteractionMode],
                            handlesIsVisible: userInteractionMode === interactionModes.IDLE,
                            workspaceBBox,
                            topLineIsVisible,
                            bottomLineIsVisible,
                            selectionShiftBarVisible: (selectedComponentsIdsNoGhosts.length > 0)
                            && shiftBarVisibleUserInteractionModes[userInteractionMode]
                            && !containsWrappedComponent,
                            containsStrip,
                            containsSection,
                            sectionDecorations,
                            hoverBoxDecorations,
                            isMouseDown: state.isMouseDown,
                            topMostHandleKind: state.topMostHandleKind
                        };

                    return {
                        state: newState
                    };
                }
            })
        },
        {
            conditions: [
                SelectedComponentsIdsSelector,
                ComponentsMapSelector,
                ReceiveOnlyAttachments,
                workspaceBBoxValueActionType,
                UserInteractionModeSelector
            ],
            reducer: ({
                values: [
                    selectedComponentIds,
                    componentsMap,
                    attachments,
                    workspaceBBox,
                    userInteractionMode,
                ],
                state
            }) => {
                const invalidCmpsMap = selectedComponentIds.some(id => !componentsMap[id]),
                    hoverBoxIsSelected = !invalidCmpsMap && selectedComponentIds.some(id => isHoverBoxKind(componentsMap[id].kind)),
                    cmp = R.path(['hoverBoxDecorations', 'component'], state);
                let hoverBoxDecorations = null;
                if (
                    invalidCmpsMap ||
                    (transientInteractionModesByMouse[userInteractionMode] && hoverBoxIsSelected) ||
                    !selectedComponentIds.length
                ) {
                    return { state: { ...state, hoverBoxDecorations: null } };
                }
                if (cmp &&
                    componentsMap[cmp.id] === cmp &&
                    selectedComponentIds.includes(cmp.id)
                ) {
                    hoverBoxDecorations = state.hoverBoxDecorations;
                } else {
                    hoverBoxDecorations = getHoverBoxDecorations(
                        selectedComponentIds,
                        workspaceBBox,
                        attachments,
                        componentsMap
                    );
                }
                return {
                    state: { ...state, hoverBoxDecorations }
                };
            }
        },
        {
            conditions: [UserInteractionModeSelector],
            reducer: ({ values: [mode], state }) => {
                if (mode === interactionModes.IDLE) {
                    if (!state.handlesIsVisible) {
                        return { state: setHandlesVisibility(true, state) };
                    }
                } else if (state.handlesIsVisible) {
                    return { state: setHandlesVisibility(false, state) };
                }

                return { state };
            }
        },
        {
            conditions: [
                TopmostHandleKindSelector,
                isLeftMouseDownVAT
            ],
            reducer: ({ values: [topMostHandleKind, isLeftMouseDown], state }) => {
                if (
                    state.isMouseDown !== isLeftMouseDown
                    || (state.topMostHandleKind !== topMostHandleKind && !isLeftMouseDown)
                ) {
                    return {
                        state: R.evolve({
                            isMouseDown: () => isLeftMouseDown,
                            topMostHandleKind: () => topMostHandleKind
                        }, state)
                    };
                }

                return { state };
            }
        }
    ]
});
