/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */

import React, { useState } from 'react';
import { Msg } from '../../../../view/intl/index';
import { StripTypes } from "../../../../view/common/dialogs/baseDialog/index";
import DialogCheckBox from '../../../../view/common/dialogs/baseDialog/DialogCheckBox';
import StandardDialog from "../../../../view/common/dialogs/StandardDialog/index";
import VerticalSpacer from '../../../../view/common/VerticalSpacer';
import getCenteredDialog from "../../../DialogManager/getCenteredDialogConfig";
import { CLOSE_TOO_MANY_WIDGETS_WARNING_DIALOG } from '../epics/index';
import * as styles from './dialogs.css';

type TooManyWidgetsDialogProps = {
    widgetsCount: number;
    dispatch: Dispatch;
};

const TooManyWidgets = (props: TooManyWidgetsDialogProps) => {
    const { widgetsCount, dispatch } = props;

    const [dontShowAgain, setDontShowAgain] = useState(false);

    return (
        <StandardDialog
            sctaHandler={null}
            stripType={StripTypes.NONE}
            footerClassName={styles.footerClass}
            bodyClassName={styles.dialogBodyWidePadding}
            titleBoxClassName={styles.header50px}
            primaryBtnClass={styles.tryNow}
            footerV2
            mctaText="msg: common.ok {OK}"
            mctaHandler={() => dispatch({ type: CLOSE_TOO_MANY_WIDGETS_WARNING_DIALOG, payload: dontShowAgain })}
        >
            <div className={styles.body}>
                <div className={styles.warningIcon} />
                <VerticalSpacer y={16} />
                <div className={styles.title}>
                    <Msg k="widgets.warnings.tooMany.title">Too many widgets</Msg>
                </div>
                <VerticalSpacer y={16} />
                <div className={styles.message}>
                    <Msg
                        k="widgets.warnings.tooMany.message"
                        params={{ count: widgetsCount }}
                    >
                        {`You already have {count} widgets on this page. Adding more may make this page load slowly.`}
                    </Msg>
                </div>
                <VerticalSpacer y={16} />
                <div className={styles.message}>
                    <DialogCheckBox
                        label="msg: common.dontShowAgain {Don't show this again}"
                        isChecked={dontShowAgain}
                        onClick={() => setDontShowAgain(!dontShowAgain)}
                    />
                </div>
            </div>
        </StandardDialog>
    );
};

export const TooManyWidgetsDialog = getCenteredDialog({
    width: 600,
    height: 409,
    component: TooManyWidgets
});
