const MIN_SECTION_HEIGHT = 50;
const HEADER_SECTION = 'HEADER_SECTION',
    FOOTER_SECTION = 'FOOTER_SECTION',
    SectionTypes = {
        HeaderSection: HEADER_SECTION,
        FooterSection: FOOTER_SECTION
    };

const SectionTypeNames = {
    [HEADER_SECTION]: 'msg: common.section.type.header {Header}',
    [FOOTER_SECTION]: 'msg: common.section.type.footer {Footer}',
};

export {
    SectionTypes,
    SectionTypeNames,
    MIN_SECTION_HEIGHT
};
