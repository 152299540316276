import type { GalleryComponent } from "./flowTypes";

export const calcGalleryColumn =
    ({ images }: GalleryComponent): number => {
        switch (images.length) {
            case 1:
            case 2:
                return 2;
            case 3:
            case 6:
                return 3;
            default:
                return 4;
        }
    };
