import React from 'react';
import { FormattedMessage } from 'react-intl';

export type MsgPropTypes = {
    k: string;
    // children validation not supported when passed in as tag contents :(
    // https://github.com/facebook/flow/issues/1820
    children?: string;
    params?: { [key: string]: boolean|string|number|React.ReactElement<any>|Function };
    className?: string;
    style?: Record<string, any>;
    onClick?: SimpleFunction;
    dataTid?: string,
};

const Msg = ({ k, children: defaultMsg, params, className, style, onClick, dataTid }: MsgPropTypes) => (
    <span className={className} style={style} onClick={onClick} data-tid={dataTid}>
        <FormattedMessage id={k} defaultMessage={defaultMsg} values={params} />
    </span>
);

export default Msg;
