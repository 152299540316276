import type { ComponentsMap } from "../../../../../redux/modules/children/workspace/flowTypes";
import type { ParentChildrenMap } from "./flowTypes";

export const getParentChildrenMap = (cmpsMap: ComponentsMap): ParentChildrenMap => {
    let parentChildrenMap = {};

    const cmps = Object.keys(cmpsMap).map(id => cmpsMap[id]);
    cmps.forEach(c => {
        if (c.relIn) {
            if (!parentChildrenMap[c.relIn.id]) {
                parentChildrenMap[c.relIn.id] = [];
            }
            parentChildrenMap[c.relIn.id].push(c.id);
        }
    });
    return parentChildrenMap;
};
