import { $Keys } from "utility-types";

export const
    Component = 'Component',
    DisableMoveStub = 'DisableMoveStub',
    Workspace = 'Workspace',
    TemplateLines = 'TemplateLines',
    LeftPanel = 'LeftPanel',
    GhostComponentsHeadSection = 'GhostComponentsHeadSection',
    GhostComponentsBodyEndSection = 'GhostComponentsBodyEndSection',
    ShiftBarTopSelection = 'ShiftBarTopSelection',
    ShiftBarBottomSelection = 'ShiftBarBottomSelection',
    ShiftBarTopHover = 'ShiftBarTopHover',
    ShiftBarBottomHover = 'ShiftBarBottomHover',
    AddIconN = 'AddIconN',
    AddIconS = 'AddIconS',
    ResizeN = 'ResizeN',
    ResizeNE = 'ResizeNE',
    ResizeE = 'ResizeE',
    ResizeSE = 'ResizeSE',
    ResizeS = 'ResizeS',
    ResizeSW = 'ResizeSW',
    ResizeW = 'ResizeW',
    ResizeNW = 'ResizeNW',
    ResizeHandleKinds = {
        ResizeN,
        ResizeNE,
        ResizeE,
        ResizeSE,
        ResizeS,
        ResizeSW,
        ResizeW,
        ResizeNW
    },
    WpcAbove = 'WpcAbove',
    WpcBelow = 'WpcBelow',
    WpcLeft = 'WpcLeft',
    WpcRight = 'WpcRight',
    ComponentHandleKinds = {
        ...ResizeHandleKinds,
        Component,
        ShiftBarTopSelection,
        ShiftBarBottomSelection,
        ShiftBarTopHover,
        ShiftBarBottomHover,
        WpcAbove,
        WpcBelow,
        WpcLeft,
        WpcRight,
    };

export type ComponentHandleKind = $Keys<typeof ComponentHandleKinds>
