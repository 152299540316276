import kind from './kind';
import {
    WebshopExistsOnCurrentPage,
    WebshopIsDisabledInDemo
} from './errorMessagesOnAdd';
import { isDemoSubscriptionType } from '../../App/epics/subscriptionData/isDemoSubscriptionType';
import type { validateComponentOnAddType } from '../../../redux/modules/children/workspace/flowTypes';

export default (dataToValidate: validateComponentOnAddType) => {
    const { componentsMap, subscriptionData } = dataToValidate;
    if (isDemoSubscriptionType(subscriptionData)) {
        return WebshopIsDisabledInDemo;
    } else if (Object.keys(componentsMap).some(key => componentsMap[key].kind === kind)) {
        return WebshopExistsOnCurrentPage;
    }
    return null;
};
