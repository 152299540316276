import React from "react";

import { Header } from "../../Header";

export const AnalyticsDashboardHeader = () => {
    return (
        <Header
            header={{
                title: 'msg: common.analytics {Analytics}',
                description: 'msg: analyticsTab.header.label {Access useful statistics to fine-tune your marketing efforts and identify areas of improvement for your site.}'
            }}
        />
    );
};
