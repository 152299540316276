import CALL_API from '../../../redux/middleware/api/CALL_API';
import * as Actions from '../actionTypes';

import type { ApiAction } from '../../../redux/middleware/api/flowTypes';

export default function (): ApiAction {
    return {
        [CALL_API]: {
            types: [
                Actions.OWNER_EMAIL_IDS_FETCH_REQUEST,
                Actions.OWNER_EMAIL_IDS_FETCH_SUCCESS,
                Actions.OWNER_EMAIL_IDS_FETCH_FAILURE
            ],
            endpoint: 'getOwnerEmailIds'
        }
    };
}
