export const
    DEFAULT_WIDTH = 500,
    DEFAULT_HEIGHT = 400,
    DEFAULT_ZOOM = 14, // Range 2.2 - 22.0,
    DEFAULT_MAP_PARAMS = {
        lvl: DEFAULT_ZOOM,
        w: DEFAULT_WIDTH,
        h: DEFAULT_HEIGHT,
    },
    defaultState = {
        link: "",
        dirMapLink: "",
        largeMapLink: "",
        mobileHide: false,
    },
    DATA_TEST_ID = 'bing-maps-widget';
