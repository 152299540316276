import { DataPage, DataPageStylesheet, DataPageTemplate } from "../../../../../dal/model/index";
import { apiAction } from "../../../../redux/middleware/api/actions";

export const
    PAGE_DATASET_SAVE_SUCCESS = 'PAGE_DATASET_SAVE_SUCCESS',
    PAGE_DATASET_SAVE_FAILURE = 'PAGE_DATASET_SAVE_FAILURE',
    UPDATE_PAGE_SAVE_STATUS_ON_NOCHANGE = 'UPDATE_PAGE_SAVE_STATUS_ON_NOCHANGE';

export const
    UPDATE_PAGE_TEMPLATE_REQUEST_ACTION = 'UPDATE_PAGE_TEMPLATE_REQUEST_ACTION',
    UPDATE_PAGE_TEMPLATE_SUCCESS_ACTION = 'UPDATE_PAGE_TEMPLATE_SUCCESS_ACTION',
    UPDATE_PAGE_TEMPLATE_FAILURE_ACTION = 'UPDATE_PAGE_TEMPLATE_FAILURE_ACTION',
    updatePageTemplateActionApiAction = (
        docs: {
            page: DataPage,
            template: DataPageTemplate,
            stylesheet: DataPageStylesheet
        }
    ) => apiAction({
        types: [
            UPDATE_PAGE_TEMPLATE_REQUEST_ACTION,
            UPDATE_PAGE_TEMPLATE_SUCCESS_ACTION,
            UPDATE_PAGE_TEMPLATE_FAILURE_ACTION
        ],
        endpoint: 'updateSiteDocuments',
        endpointParams: docs,
    });
