import type { Element } from '../../../flowTypes';
import { include } from '../../helpers/index';
import { TEXT_GLOBAL_CLASS_STYLES } from '../../constants';

const getSupportedStyles = include(TEXT_GLOBAL_CLASS_STYLES);

const mergeStyles = (elements: Array<Element>) => {
    return elements.reduce((style, element) => {
        return {
            ...style,
            ...getSupportedStyles(element)
        };
    }, {});
};

const isContentBlock = element => {
    return element.atype === 'para' || element.atype === 'listItem';
};

/**
 * Returns elemnets with global styles and override styles
 */
export default (elements: Array<Element>): Array<Element> => {
    return elements.map(element => {
        if (!element.globalName || isContentBlock(element)) {
            return element;
        }

        const parentElements = elements.filter(e => (
            element !== e && !isContentBlock(e) &&
            e.start === element.start && e.end === element.end
        ));

        return {
            ...element,
            ...mergeStyles(parentElements)
        };
    });
};
