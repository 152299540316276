import { MobileViewOrientations } from "../../Preview/View/Mobile/constants";

const
    defaultState = {
        dimensions: {},
        pageIdToLoad: null,
        timestamp: null,
        timeline: [],
        siteMap: null,
        hideIntro: false,
        loading: true,
        opened: false,
        panelExpanded: true,
        isMobileView: false,
        mobileView: { orientation: MobileViewOrientations.PORTRAIT },
        isBackupAndRestoreAllowed: false
    };

export default defaultState;
