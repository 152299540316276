import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly } from "../../../../epics/makeCondition";
import valueActionType from './valueActionType';
import { SHOW_TOOLTIP } from "../../actionTypes";
import topMostHandleAV from '../../../Workspace/epics/topMostHandle/valueActionType';
import { WORKSPACE_CLICK } from "../../../Workspace/actionTypes";
import { ShiftbarClick } from "../../ids";
import { isShiftBarHandle, isShiftBarTop } from "../../../../utils/handle/index";
import templateOffsetValueActionType from '../../../oneweb/Template/epics/templateOffset/valueActionType';
import getComponentsBBox from "../../../../utils/componentsMap/getComponentsBBox";
import workspaceBBoxValueActionType from '../../../Workspace/epics/workspaceBBox/valueActionType';
import { SelectionShiftBarHandleWidth } from '../../../../view/App.css';
import { getShiftBarTopSelectionLeft } from "../../../../utils/handle/shiftBar";
import { ReceiveOnlySelectedComponentsSelector } from "../../../Workspace/epics/componentsEval/selectorActionTypes";

export default makeEpic({
    defaultState: null,
    valueActionType,
    updaters: [
        {
            conditions: [
                WORKSPACE_CLICK,
                receiveOnly(topMostHandleAV),
                ReceiveOnlySelectedComponentsSelector,
                receiveOnly(templateOffsetValueActionType),
                receiveOnly(workspaceBBoxValueActionType)
            ],
            reducer: ({ values: [position, handle, selectedCmps, templateOffset, workspaceBBox] }) => {
                if (!handle || (handle && !isShiftBarHandle(handle.kind))) {
                    return {
                        state: null
                    };
                }
                const selectionbBox = getComponentsBBox(selectedCmps, workspaceBBox),
                    left = getShiftBarTopSelectionLeft(selectionbBox),
                    top = isShiftBarTop(handle.kind) ? selectionbBox.top : selectionbBox.bottom,
                    xWrtTemplateOffset = position.x - templateOffset.x,
                    shitbarButtonWidth = parseInt(SelectionShiftBarHandleWidth, 10);
                if (xWrtTemplateOffset >= left && xWrtTemplateOffset <= (left + shitbarButtonWidth)) {
                    return {
                        state: null,
                        actionToDispatch: {
                            type: SHOW_TOOLTIP,
                            payload: {
                                position: {
                                    x: left + templateOffset.x,
                                    y: top + templateOffset.y
                                },
                                id: ShiftbarClick
                            }
                        }
                    };
                }
                return {
                    state: null
                };
            }
        }
    ]
});
