
export const
    CHECK_NETWORK_CONNECTION = 'CHECK_NETWORK_CONNECTION',
    checkNetworkConnection = () => ({ type: CHECK_NETWORK_CONNECTION }),
    SAVE_VIA_AUTOSAVE = 'SAVE_VIA_AUTOSAVE',
    saveViaAutosave = () => ({ type: SAVE_VIA_AUTOSAVE }),
    AUTOSAVE_SHOW_POPUP = 'AUTOSAVE_SHOW_POPUP',
    showAutosavePopup = () => ({ type: AUTOSAVE_SHOW_POPUP }),
    AUTOSAVE_HIDE_POPUP = 'AUTOSAVE_HIDE_POPUP',
    hideAutosavePopup = () => ({ type: AUTOSAVE_HIDE_POPUP });
