const
    StyleCell = 'web.data.styles.StyleCell',
    StyleForm = 'web.data.styles.StyleForm',
    StyleBlock = 'web.data.styles.StyleBlock';

export { default as StyleText } from '../../../../src/components/oneweb/Text/globalStyle/kind';
export { default as StyleLink } from '../../../../src/components/oneweb/Link/globalStyle/kind';
export { default as StyleButton } from '../../../../src/components/oneweb/Button/globalStyle/kind';
export { default as StyleMenu } from '../../../../src/components/oneweb/Menu/globalStyle/kind';

export { StyleCell, StyleForm, StyleBlock };
