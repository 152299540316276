
import React from 'react';
import cx from 'classnames';
import { Flex } from '../../../../../view/reflexbox/index';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import * as actionTypes from '../../actionTypes';
import * as pagesIds from '../pagesIds';
import pagesTitles from '../pagesTitles';
import type { Props } from '../flowTypes';
import injectIntl from '../../../../../view/intl/injectIntl';
import { PropertiesButton } from '../../../../../view/common/Button/index';
import styles from "./styles.css";
import openDialogAC from '../../../../App/actionCreators/openDialog';
import connectInstagramDialogId from '../../configurationDialog/configurationDialogId';
import Msg from "../../../../../view/intl/Msg";
import isInstagramComponentDisabled from '../../../../../utils/disableInstagramComponent';
import { InstagramDisabledModalId } from '../../../../SocialAccounts/instagram/InstagramDisabledModal/InstagramDisabledModal';

const
    id = pagesIds.ACCOUNT,
    title = pagesTitles[id],
    view = injectIntl(({
        intl,
        socialAccounts,
        dispatch
    }: Props) => {
        const { instagram: { instagramConnected, instagramAccountName } } = socialAccounts;

        const renderOnInstagramNotConnected = () => {
            const
                addInstagramMessage = "msg: component.instagram.pp.account.addInstagram {Add your Instagram feed to your site and share it with your visitors.}", // eslint-disable-line max-len
                connectAccount = "msg: component.instagram.pp.account.connect {Connect account}",
                noAccountMessage = "msg: component.instagram.pp.account.noAccount {Don\'t have an account?}",
                createAccountLink = 'https://www.instagram.com/accounts/emailsignup/?hl=en';
            return (
                <div>
                    <div className={cx(styles.positionItems, styles.instagramIcon)} />
                    <VerticalSpacer />
                    <div className={cx(styles.positionItems, styles.accountPage, styles.addInstagramMsg)}>
                        {intl.msgJoint(addInstagramMessage)}
                    </div>
                    <VerticalSpacer />
                    <PropertiesButton
                        className={cx(styles.positionItems, styles.accountBtn, styles.connectAccountBtn)}
                        text={connectAccount}
                        onClick={() => dispatch(
                            isInstagramComponentDisabled() ? openDialogAC(InstagramDisabledModalId) :
                                openDialogAC(connectInstagramDialogId)
                        )}
                    />
                    <VerticalSpacer y={14} />
                    <div className={cx(styles.positionItems, styles.accountPage, styles.noAccountMsg)}>
                        {intl.msgJoint(noAccountMessage)}
                    </div>
                    <div className={cx(styles.positionItems, styles.accountPage, styles.createAccount)}>
                        <a target="_blank" href={createAccountLink}>
                            <Msg k="component.instagram.pp.account.createAccount">
                                Create one here
                            </Msg>
                        </a>
                    </div>
                </div>
            );
        };

        const renderOnInstagramConnected = () => {
            const
                disconnect = "msg: component.instagram.pp.account.disconnect {Disconnect}",
                instagramRootUrl = "https://www.instagram.com/",
                connectedAs = "msg: component.instagram.pp.account.connectedAs {Connected as:}",
                openInstagramAccountLink = `${instagramRootUrl}${instagramAccountName}/`;
            const disconnectInfo = "msg: component.instagram.pp.account.disconnectInfo {Disconnect to log out or to sign in to another account.}";   // eslint-disable-line max-len

            return (
                <div>
                    <div className={cx(styles.positionItems, styles.accountPage, styles.connectedAs)}>{intl.msgJoint(connectedAs)}</div>
                    <VerticalSpacer y={7} />
                    <Flex align="center">
                        <div className={
                            cx(styles.positionItems, styles.accountPage, styles.connectedAccountName)
                        }
                        >
                            {instagramAccountName}
                        </div>
                        <a
                            className={cx(styles.positionItems, styles.connectedAccountLink)}
                            target="_blank"
                            href={openInstagramAccountLink}
                        >
                            {}
                        </a>
                    </Flex>
                    <VerticalSpacer />
                    <PropertiesButton
                        className={cx(styles.positionItems, styles.accountBtn, styles.disconnectBtn)}
                        text={disconnect}
                        onClick={() => dispatch({ type: actionTypes.INSTAGRAM_GALLERY_PP_DISCONNECT_BTN_PRESSED })}
                    />
                    <VerticalSpacer y={14} />
                    <div className={cx(styles.positionItems, styles.accountPage, styles.noAccountMsg)}>{intl.msgJoint(disconnectInfo)}</div>
                    <VerticalSpacer />
                </div>
            );
        };

        return (
            <Page>
                <VerticalSpacer />
                {
                    instagramConnected &&
                    renderOnInstagramConnected()
                }
                {
                    !instagramConnected &&
                    renderOnInstagramNotConnected()
                }
            </Page>
        );
    });

export { id, view, title };
