import * as main from './pages/main';
import * as onClick from './pages/onClick';
import * as seo from './pages/seo';
import * as color from './pages/color';
import * as mobileView from '../../../PropertiesPanel/view/MobileView/page';
import makePages from '../../../PropertiesPanel/view/makePages';

const svgPropertiesPanelConfig = {
    pages: makePages(main, color, onClick, seo, mobileView),
    defaultPageId: main.id,
};

export {
    svgPropertiesPanelConfig as default,
    svgPropertiesPanelConfig,
};
