import CALL_API from '../../../../../../redux/middleware/api/CALL_API';
import * as Actions from "../actionTypes";

export default function (data) {
    return {
        [CALL_API]: {
            types: [Actions.REGENERATE_TEXT_REQUEST,
                Actions.REGENERATE_TEXT_SUCCESS,
                Actions.REGENERATE_TEXT_FAILED],
            endpoint: "regenerateTextWithAI",
            endpointParams: data,
            isStream: true
        }
    };
}
