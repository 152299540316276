import { MCTA_ON_MOUSE_LEAVE } from '../../../../../view/Workspace/Decorations/ComponentMainActions/actionTypes';
import makeEpic from '../../../../../epics/makeEpic';
import TextComponentKind from '../../kind';
import { TEXT_MCTA_ALIGN_MOUSE_LEAVE } from '../../actionTypes';
import valueActionType from './valueActionType';

const
    defaultState = null,
    epic = makeEpic({
        defaultState,
        valueActionType,
        updaters: [
            {
                conditions: [MCTA_ON_MOUSE_LEAVE],
                reducer: ({ values: [componentKind], state }) => ({
                    state,
                    actionToDispatch: componentKind === TextComponentKind ? { type: TEXT_MCTA_ALIGN_MOUSE_LEAVE } : null
                })
            }
        ]
    });

export default epic;
