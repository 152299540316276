import * as R from "ramda";
import buildLocalStyle from "./buildLocalStyle";
import type { CalcRendererProps } from '../../Workspace/epics/componentsProps/flowTypes';
import type { ButtonPropsUI, ButtonComponent, ButtonComponentExtension } from './flowTypes';
import { replaceTagsWithContent } from '../Text/view/replaceTagsWithContent';
import { getFirstButtonStyle } from "../../Workspace/epics/stylesheets/selectors";
import { getGlobalstyleClassNameFromStyle } from "../../RenderGlobalstyles/getGlobalstyleClassName";
import { isModernLayoutSection } from "../../ModernLayouts/preview_utils";

export default ({
    component,
    componentDependencies,
    // @ts-ignore
    componentExtension = { offScreenRendererText: null, fontSize: null, offScreenRenderTextWidth: 0, isBtnInModernLayout: false },
    inEditMode,
    selectedParentTheme,
    parentSection
}: CalcRendererProps<ButtonComponent, ButtonComponentExtension, Record<string, any>>): ButtonPropsUI => {
    let
        isBtnInModernLayout = isModernLayoutSection(parentSection),
        style = buildLocalStyle(component, isBtnInModernLayout),
        fontSize = null,
        text = component.text,
        offScreenRendererText = component.text,
        offScreenRendererFontSize = style && style.fontSize,
        globalStyleId = R.path(['style', 'globalId'], component),
        { site, globalVariables, themeSettingsData, globalstyles } = componentDependencies,
        { autoColorMode } = themeSettingsData,
        globalStyleClass = componentDependencies.stylesheetsIdToNameMap[globalStyleId];
    /**
     * TODO: WBTGEN-16379
     * Currently the first button style is used to save data for button styles related to theming.
     * It has a drawback that, the button style 1 will be affected when the user goes back to non-theme mode.
     * We may have to create a new button global style per template just to save the theme button global style data.
     */
    if (autoColorMode) {
        const firstButtonStyle = getFirstButtonStyle(globalstyles);
        globalStyleClass = getGlobalstyleClassNameFromStyle(firstButtonStyle);
    }

    if (componentExtension) {
        if (componentExtension.fontSize != null) {
            offScreenRendererFontSize = componentExtension.fontSize;
        }
        if (componentExtension.offScreenRendererText != null) {
            offScreenRendererText = componentExtension.offScreenRendererText;
        }
    }

    return {
        // @ts-ignore
        fontSize,
        text: replaceTagsWithContent(text, { globalVariables, site }),
        localizedOffScreenRendererText: replaceTagsWithContent(offScreenRendererText, { globalVariables, site }),
        offScreenRendererText,
        offScreenRendererFontSize,
        // @ts-ignore
        style,
        inEditMode,
        globalStyleClass,
        // @ts-ignore
        selectedParentTheme,
        autoColorMode,
        buttonThemeSelected: component.buttonThemeSelected,
        isBtnInModernLayout,
    };
};
