/* eslint-disable max-len */

import React from 'react';
import { timestampToDateTime } from '../../../utils/date';
import { Msg, injectIntl } from '../../../view/intl/index';
import VerticalSpacer from '../../../view/common/VerticalSpacer';
import { Dialog, DialogTitleBox, DialogBody, DialogFooter } from '../../../view/common/dialogs/baseDialog/index';
import getCenteredDialogConfig from '../../DialogManager/getCenteredDialogConfig';
import { closeDialog } from '../../App/actionCreators/index';
import { RESTORE_SAVEPOINT } from '../actionTypes';
import styles from '../view/Backup.css';

const
    dialogId = 'BackupsRestoreDialog',
    width = 490,
    height = 350,
    BackupRestoreDialog = injectIntl(({ timestamp, intl, dispatch }) => (
        <Dialog>
            <DialogTitleBox
                title="msg: component.backup.restore.dialog.title {Do you want to restore your website?}"
            />
            <DialogBody>
                <Msg k="component.backup.restore.dialog.message">Your website will be restored back to how it looked at:</Msg>
                <VerticalSpacer y={10} />
                <div className={styles.restoreTimestamp}>{timestampToDateTime(timestamp, intl)}</div>
            </DialogBody>
            <DialogFooter
                noTopBorder
                mctaText="msg: component.backup.dialog.restore.restore {Restore}"
                mctaHandler={() => dispatch({ type: RESTORE_SAVEPOINT, payload: timestamp })}
                sctaHandler={() => dispatch(closeDialog())}
            />
        </Dialog>
    )),
    dialog = getCenteredDialogConfig(width, height, BackupRestoreDialog);

export { dialogId, dialog };
