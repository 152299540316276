import * as R from 'ramda';
import makeEpic from '../../../epics/makeEpic';
import { optional, receiveOnly } from '../../../epics/makeCondition';
import valueActionType from './valueActionType';
import stylesheetsValueActionType from '../../Workspace/epics/stylesheets/valueActionType';
import leftPanelWidthValueActionType from '../../Panel/epics/width/valueActionType';
import browserDimensionsValueActionType from '../../App/epics/browserDimensions/valueActionType';
import componentMainActionsValueActionType from '../../Workspace/epics/componentMainActions/valueActionType';
import relationsValueActionType from '../../Workspace/epics/relations/valueActionType';
import onComponentSelection from './actionHandlers/onComponentSelection';
import shouldShow from './utils/shouldShow';
import onWindowMouseMove from "./actionHandlers/onWindowMouseMove";
import onMouseDownOfDraggableArea from "./actionHandlers/onMouseDownOfDraggableArea";
import onLeftPanelWidthChange from "./actionHandlers/onLeftPanelWidthChange";
import * as ActionTypes from "./actionTypes";
import onNavigateToPage from "./actionHandlers/onNavigateToPage";
import onNavigateBack from "./actionHandlers/onNavigateBack";
import onUserInteractionModeChange from "./actionHandlers/onUserInteractionModeChange";
import onWindowResize from "./actionHandlers/onWindowResize";
import onWindowMouseUp from "./actionHandlers/onWindowMouseUp";
import { WINDOW_MOUSE_MOVE, WINDOW_MOUSE_UP } from "../../../redux/modules/actionTypes";
import defaultState from './defaultState';
import {
    CHANGE_TEMPLATE_BACKGROUND,
    COMPONENT_CONFIGURATION_CANCELED,
    TEMPLATE_SETTINGS
} from "../../../redux/modules/children/workspace/actionTypes";
import { COMPONENT_ADD_STARTED, COMPONENT_ADD_CANCELLED } from "../../DndAddComponent/actionTypes";
import {
    ComponentsDependenciesSelector,
    ReceiveOnlyComponentsDependenciesSelector,
    ReceiveOnlyComponentsMap,
    ReceiveOnlyUserInteractionModeSelector,
    ROEditModeComponentIdSelector,
    SelectedComponentAndEditModeComponentIdSelector,
    SelectedComponentIdSelector,
    UserInteractionModePlusSelectedComponentSelector
} from "../../Workspace/epics/componentsEval/selectorActionTypes";
import onTemplateSettings from "./actionHandlers/onTemplateSettings";
import pageDatasetLoadedActionType from "../../App/epics/pageDataset/pageDatasetLoadedActionType";
import TemplateKind from "../../oneweb/Template/kind";
import templateValueActionType from '../../oneweb/Template/epics/template/valueActionType';
import templateOffsetValueActionType from '../../oneweb/Template/epics/templateOffset/valueActionType';
import generalActionsToClosePopups from "../../App/generalActionsToClosePopups";
import calcMobileEnablement from "./utils/calcMobileEnablement";
import onHeightChangeOfPage from "./actionHandlers/onHeightChangeOfPage";
import { createScheduleAction } from "../../Workspace/epics/resizeDecos/actionCreators";
import {
    ROCodeComponentsRendererHeadHeightSelector
} from "../../Workspace/CodeComponentsRenderer/epic/selectorActionTypes";
import { CANCEL_SCHEDULED_ACTION } from "../../../redux/middleware/schedule/actionTypes";
import { InstancePropertiesPanelUpdaters } from '../instance/InstancePropertiesPanelUpdaters';
import { WhenRepositoryDomainSelector } from '../../../redux/middleware/cookie/selectorActionTypes';
import { COLOR_THEME_SITE_SETTINGS_EPIC_VALUE } from "../../SiteSettings/ColorThemeData/colorThemeSiteSettingsVAT";
import { getThemeColorsFromStylesheet, ThemeColorSelector } from "../../Workspace/epics/stylesheets/selectors";
import * as workspaceActionTypes from "../../Workspace/actionTypes";
import { getWebShopStripCmpIds } from "../../ModernLayouts/layoutsData/webshopMHFDataUtils";

const
    setShowStateOnComponentAddCancellation = ({ values: [editModeComponentId], state: stateHolder }) => ({
        state: {
            ...stateHolder,
            state: {
                ...stateHolder.state,
                show: (!!stateHolder.scope.selectedComponentId && editModeComponentId)
                || R.path(['state', 'selectedComponentWithId', 'component', 'kind'], stateHolder) === TemplateKind
            }
        }
    }),
    closeUpdaters = [
        pageDatasetLoadedActionType,
        ...generalActionsToClosePopups
    ].map(action => ({
        conditions: [action],
        reducer: ({ state: stateHolder }) => {
            if (stateHolder && stateHolder.state) {
                const { show, selectedComponentWithId } = stateHolder.state;
                if (show && selectedComponentWithId && selectedComponentWithId.component.kind === TemplateKind) {
                    return { state: { ...stateHolder, state: { ...stateHolder.state, show: false } } };
                }
                return { state: stateHolder };
            }
            return { state: stateHolder };
        }
    })),
    getTemplateContext = (template, browserDimensions, stylesheets) => ({
        selectedComponentWithId: {
            id: template.id,
            component: template,
            componentExtension: {}
        },
        browserDimensions,
        stylesheets
    }),
    hideAndResetSelectedComponentId = R.pipe(
        R.assocPath(['state', 'show'], false),
        R.assocPath(['scope', 'selectedComponentId'], null)
    ),
    SHOW_PROP_PANEL_BY_MEASURE_TIMEOUT = 'SHOW_PROP_PANEL_BY_MEASURE_TIMEOUT';

const epic = makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                receiveOnly(optional(WhenRepositoryDomainSelector)),
                receiveOnly(templateValueActionType),
                receiveOnly(browserDimensionsValueActionType),
                receiveOnly(stylesheetsValueActionType),
                receiveOnly(COLOR_THEME_SITE_SETTINGS_EPIC_VALUE),
                TEMPLATE_SETTINGS
            ],
            reducer: ({
                values: [
                    isRepositoryDomain,
                    template,
                    browserDimensions,
                    stylesheets,
                    themeSettingsData,
                    { preserveNav = false } = {}
                ],
                state
            }) => ({
                state: onTemplateSettings({
                    isRepositoryDomain,
                    context: getTemplateContext(template, browserDimensions, stylesheets),
                    epicState: state,
                    preserveCurrentNavigation: preserveNav,
                    themeSettingsData,
                    themeColorsData: getThemeColorsFromStylesheet(stylesheets)
                })
            })
        },
        {
            conditions: [COLOR_THEME_SITE_SETTINGS_EPIC_VALUE],
            reducer: ({
                values: [themeSettingsData],
                state
            }) => ({ state: R.assocPath(['state', 'themeSettingsData'], themeSettingsData, state) })
        },
        {
            conditions: [ThemeColorSelector],
            reducer: ({
                values: [themeColorsData],
                state
            }) => ({ state: R.assocPath(['state', 'themeColorsData'], themeColorsData, state) })
        },
        {
            conditions: [
                receiveOnly(optional(WhenRepositoryDomainSelector)),
                receiveOnly(templateValueActionType),
                receiveOnly(browserDimensionsValueActionType),
                receiveOnly(stylesheetsValueActionType),
                receiveOnly(COLOR_THEME_SITE_SETTINGS_EPIC_VALUE),
                CHANGE_TEMPLATE_BACKGROUND
            ],
            reducer: ({ values: [
                isRepositoryDomain,
                template,
                browserDimensions,
                stylesheets,
                themeSettingsData
            ], state }) => ({
                state: onTemplateSettings({
                    isRepositoryDomain,
                    context: getTemplateContext(template, browserDimensions, stylesheets),
                    epicState: state,
                    templateBackground: true,
                    themeSettingsData,
                    themeColorsData: getThemeColorsFromStylesheet(stylesheets)
                })
            })
        },
        {
            conditions: [templateValueActionType],
            reducer: ({ values: [template], state: stateHolder }) => {
                const { state } = stateHolder;
                if (
                    state
                    && state.show
                    && state.selectedComponentWithId
                    && state.selectedComponentWithId.id === template.id
                ) {
                    return {
                        state: {
                            ...stateHolder,
                            state: {
                                ...state,
                                selectedComponentWithId: {
                                    id: template.id,
                                    component: template,
                                    componentExtension: {}
                                }
                            }
                        }
                    };
                }
                return { state: stateHolder };
            }
        },
        {
            conditions: [stylesheetsValueActionType],
            reducer: ({ values: [stylesheets], state: stateHolder }) => ({
                state: stateHolder.state.show
                    ? R.assocPath(['state', 'stylesheets'], stylesheets, stateHolder)
                    : stateHolder
            })
        },
        {
            conditions: [
                SelectedComponentAndEditModeComponentIdSelector,
                ReceiveOnlyComponentsDependenciesSelector,
                receiveOnly(stylesheetsValueActionType),
                receiveOnly(templateOffsetValueActionType),
                receiveOnly(browserDimensionsValueActionType),
                receiveOnly(leftPanelWidthValueActionType),
                ReceiveOnlyUserInteractionModeSelector,
                ReceiveOnlyComponentsMap,
                receiveOnly(relationsValueActionType),
                receiveOnly(componentMainActionsValueActionType),
                ROCodeComponentsRendererHeadHeightSelector
            ],
            reducer: ({
                values: [
                    { selectedComponent, editModeComponentId },
                    componentsDependencies,
                    stylesheets,
                    templateOffset,
                    browserDimensions,
                    leftPanelWidth,
                    mode,
                    componentsMap,
                    { changes },
                    componentMainActions,
                    codeComponentsRendererHeadHeight
                ],
                state: stateHolder
            }) => {
                if (
                    selectedComponent &&
                    editModeComponentId &&
                    componentsMap[selectedComponent.id] &&
                    (selectedComponent.id === editModeComponentId)
                ) {
                    const
                        { mctaTop, mctaRight, mctaBottom, mctaLeft } = componentMainActions,
                        { id } = selectedComponent,
                        componentDependencies = componentsDependencies[selectedComponent.kind],
                        selectedComponentWithId = { component: selectedComponent, id },
                        webShopFooterStripCmpIds = getWebShopStripCmpIds(componentsMap),
                        { scope } = stateHolder;

                    if (shouldShow(mode) && editModeComponentId
                        && !webShopFooterStripCmpIds.includes(selectedComponentWithId.id)) {
                        if ((scope.selectedComponentId !== id)) {
                            const
                                mctaBBox = { top: mctaTop, right: mctaRight, bottom: mctaBottom, left: mctaLeft },
                                context = {
                                    selectedComponentWithId,
                                    componentDependencies,
                                    stylesheets,
                                    templateOffset,
                                    browserDimensions,
                                    leftPanelWidth,
                                    mode,
                                    mobileEnablement: calcMobileEnablement(componentsMap, changes, id),
                                    mctaBBox,
                                    codeComponentsRendererHeadHeight
                                };

                            return {
                                state: onComponentSelection(context, scope),
                                actionToDispatch: createScheduleAction(
                                    { type: SHOW_PROP_PANEL_BY_MEASURE_TIMEOUT },
                                    200
                                )
                            };
                        }
                    }
                    return {
                        state: R.assocPath(['state', 'selectedComponentWithId'], selectedComponentWithId, stateHolder)
                    };
                }
                if (stateHolder.state.show) {
                    return {
                        state: hideAndResetSelectedComponentId(stateHolder)
                    };
                }
                return { state: stateHolder };
            }
        },
        {
            conditions: [
                relationsValueActionType,
                ReceiveOnlyComponentsMap,
                SelectedComponentIdSelector,
                optional(workspaceActionTypes.MOVE_SELECTED_COMPONENTS_TO_PAGE),
                optional(workspaceActionTypes.MOVE_SELECTED_COMPONENTS_TO_TEMPLATE),
            ],
            reducer: ({ values: [{ changes }, componentsMap, selectedComponentId], state: stateHolder }) => {
                if (selectedComponentId && componentsMap[selectedComponentId] && stateHolder.state.show) {
                    return {
                        state: {
                            ...stateHolder,
                            state: {
                                ...stateHolder.state,
                                mobileEnablement: calcMobileEnablement(componentsMap, changes, selectedComponentId)
                            }
                        }
                    };
                }
                return { state: stateHolder };
            }
        },
        {
            conditions: [
                UserInteractionModePlusSelectedComponentSelector,
                receiveOnly(templateOffsetValueActionType),
                receiveOnly(browserDimensionsValueActionType),
                receiveOnly(leftPanelWidthValueActionType),
                ReceiveOnlyComponentsDependenciesSelector,
                receiveOnly(stylesheetsValueActionType),
                ROEditModeComponentIdSelector,
                ReceiveOnlyComponentsMap,
                receiveOnly(relationsValueActionType),
                receiveOnly(componentMainActionsValueActionType),
                ROCodeComponentsRendererHeadHeightSelector
            ],
            reducer: ({
                values: [
                    userInteractionModeWithSelectedComponent,
                    templateOffset,
                    browserDimensions,
                    leftPanelWidth,
                    componentsDependencies,
                    stylesheets,
                    editModeComponentId,
                    componentsMap,
                    relIns,
                    componentMainActions,
                    codeComponentsRendererHeadHeight
                ],
                state: stateHolder
            }) => {
                const { userInteractionMode, selectedComponent } = userInteractionModeWithSelectedComponent;
                if (selectedComponent && selectedComponent.id === stateHolder.scope.selectedComponentId) {
                    const
                        { mctaTop, mctaRight, mctaBottom, mctaLeft } = componentMainActions,
                        mctaBBox = { top: mctaTop, right: mctaRight, bottom: mctaBottom, left: mctaLeft };

                    return ({
                        state: onUserInteractionModeChange(
                            userInteractionMode,
                            { id: selectedComponent.id, component: selectedComponent },
                            browserDimensions,
                            leftPanelWidth,
                            componentsDependencies[selectedComponent.kind],
                            stylesheets,
                            editModeComponentId,
                            templateOffset,
                            stateHolder,
                            componentsMap,
                            relIns.changes,
                            mctaBBox,
                            codeComponentsRendererHeadHeight
                        )
                    });
                }
                return { state: stateHolder };
            }
        },
        {
            conditions: [
                ComponentsDependenciesSelector
            ],
            reducer: ({
                values: [
                    componentsDependencies
                ], state: epicState
            }) => {
                if (epicState.state.show && epicState.state.selectedComponentWithId) {
                    const { kind } = epicState.state.selectedComponentWithId.component;
                    return {
                        state: R.assocPath(
                            ['state', 'componentDependencies'],
                            componentsDependencies[kind],
                            epicState
                        )
                    };
                }
                return { state: epicState };
            }
        },
        {
            conditions: [ActionTypes.PROPERTIES_PANEL_NAVIGATE_TO_PAGE],
            reducer: ({ values: [{ pageId, additionalPayload }], state: stateHolder }) => ({
                state: onNavigateToPage({ pageId, additionalPayload }, stateHolder)
            })
        },
        {
            conditions: [ActionTypes.PROPERTIES_PANEL_NAVIGATE_BACK],
            reducer: ({ state: stateHolder }) => ({
                state: onNavigateBack(stateHolder)
            })
        },
        {
            conditions: [
                leftPanelWidthValueActionType,
                receiveOnly(browserDimensionsValueActionType)
            ],
            reducer: ({ values: [leftPanelWidth, browserDimensions], state: stateHolder }) => ({
                state: onLeftPanelWidthChange(leftPanelWidth, browserDimensions, stateHolder)
            })
        },
        {
            conditions: [
                browserDimensionsValueActionType,
                receiveOnly(leftPanelWidthValueActionType)
            ],
            reducer: ({ values: [browserDimensions, leftPanelWidth], state: stateHolder }) => ({
                state: onWindowResize(leftPanelWidth, browserDimensions, stateHolder)
            })
        },
        {
            conditions: [ActionTypes.MOUSE_DOWN_ON_PROPERTIES_PANEL_DRAGGABLE_AREA],
            reducer: ({ values: [mousePosition], state: stateHolder }) => ({
                state: onMouseDownOfDraggableArea(mousePosition, stateHolder)
            })
        },
        {
            conditions: [
                WINDOW_MOUSE_MOVE,
                receiveOnly(browserDimensionsValueActionType),
                receiveOnly(leftPanelWidthValueActionType)
            ],
            reducer: ({ values: [mousePosition, browserDimensions, leftPanelWidth], state: stateHolder }) => ({
                state: onWindowMouseMove(mousePosition, browserDimensions, leftPanelWidth, stateHolder)
            })
        },
        {
            conditions: [ActionTypes.MOUSE_UP_ON_PROPERTIES_PANEL_DRAGGABLE_AREA],
            reducer: ({ state: stateHolder }) => ({ state: onWindowMouseUp(stateHolder) })
        },
        {
            conditions: [WINDOW_MOUSE_UP],
            reducer: ({ state: stateHolder }) => ({ state: onWindowMouseUp(stateHolder) })
        },
        {
            conditions: [COMPONENT_ADD_STARTED],
            reducer: ({ state: stateHolder }) => ({
                state: { ...stateHolder, state: { ...stateHolder.state, show: false } }
            })
        },
        {
            conditions: [
                ROEditModeComponentIdSelector,
                COMPONENT_CONFIGURATION_CANCELED
            ],
            reducer: setShowStateOnComponentAddCancellation
        },
        {
            conditions: [
                ROEditModeComponentIdSelector,
                COMPONENT_ADD_CANCELLED
            ],
            reducer: setShowStateOnComponentAddCancellation
        },
        {
            conditions: [
                ActionTypes.PROPERTIES_PANEL_PAGE_HEIGHT,
                ROCodeComponentsRendererHeadHeightSelector,
                ReceiveOnlyComponentsDependenciesSelector,
                receiveOnly(templateOffsetValueActionType),
                receiveOnly(browserDimensionsValueActionType),
                receiveOnly(leftPanelWidthValueActionType),
                ReceiveOnlyUserInteractionModeSelector,
                receiveOnly(componentMainActionsValueActionType),
                optional(SHOW_PROP_PANEL_BY_MEASURE_TIMEOUT)
            ],
            reducer: ({
                values: [
                    pageHeight,
                    codeComponentsRendererHeadHeight,
                    componentsDependencies,
                    templateOffset,
                    browserDimensions,
                    leftPanelWidth,
                    mode,
                    componentMainActions
                ],
                state: epicState
            }) => {
                const
                    { selectedComponentWithId, navigation } = epicState.state;
                if (!epicState.state.show) {
                    return { state: epicState };
                }
                const { mctaTop, mctaRight, mctaBottom, mctaLeft } = componentMainActions,
                    mctaBBox = { top: mctaTop, right: mctaRight, bottom: mctaBottom, left: mctaLeft },
                    context = {
                        selectedComponentWithId,
                        browserDimensions,
                        leftPanelWidth,
                        componentDependencies: epicState.state.instance
                            ? {}
                            : componentsDependencies[selectedComponentWithId.component.kind],
                        mode,
                        templateOffset,
                        mctaBBox,
                        codeComponentsRendererHeadHeight
                    };

                return {
                    state: onHeightChangeOfPage(context, epicState, pageHeight, navigation),
                    actionToDispatch: {
                        type: CANCEL_SCHEDULED_ACTION,
                        payload: {
                            actionToDispatch: SHOW_PROP_PANEL_BY_MEASURE_TIMEOUT
                        }
                    }
                };
            }
        },
        ...closeUpdaters,
        ...InstancePropertiesPanelUpdaters,
    ]
});

export {
    defaultState,
    epic as default
};
