import React from 'react';
import { getBBoxDimensions } from '../../../utils/bBox';
import styles from '../../App.css';
import injectIntl from '../../intl/injectIntl';
import type { BBox } from '../../../redux/modules/children/workspace/flowTypes';
import {
    ABOVE_MCTA_POS, BELOW_MCTA_POS,
    createGetMCTAPosition, mctaTopGetters,
    TOP_FROM_COMPONENT
} from "../../../components/Workspace/epics/componentMainActions/index";
import type { ScrollEpicState } from "../../../components/Workspace/epics/scroll/flowTypes";

const CornerDecorationHeight = parseInt(styles.CornerDecorationHeight, 10),
    CornerDecorationMargin = 5;

type Props = {
    bBox: BBox, isVisible: boolean, isPosition: boolean, isDimensions: boolean, intl: any, scroll: ScrollEpicState
}

const decorationTopHandlers = {
        [ABOVE_MCTA_POS]: (arg: any, componentBBox: BBox) => {
            return componentBBox.top - CornerDecorationHeight - CornerDecorationMargin;
        },
        [BELOW_MCTA_POS]: mctaTopGetters[TOP_FROM_COMPONENT][BELOW_MCTA_POS]
    },
    getMCTAPosition = createGetMCTAPosition(0);

const CornerDecoration = ({ bBox, isVisible, isPosition, isDimensions, scroll, intl }: Props) => {
    if (!isVisible) {
        return <div />;
    }
    let output = {};
    if (isPosition) {
        output = {
            [intl.msgJoint('msg: decorations.X {X}')]: bBox.left,
            [intl.msgJoint('msg: decorations.Y {Y}')]: bBox.top
        };
    }
    if (isDimensions) {
        const { width, height } = getBBoxDimensions(bBox);
        output = {
            [intl.msgJoint('msg: decorations.W {W}')]: width,
            [intl.msgJoint('msg: decorations.H {H}')]: height
        };
    }

    const MCTATopAboveComponent = decorationTopHandlers[ABOVE_MCTA_POS](null, bBox),
        MCTAPosition = getMCTAPosition(MCTATopAboveComponent, scroll),
        mctaTop = decorationTopHandlers[MCTAPosition]([], bBox);

    const style = {
        top: mctaTop,
        left: bBox.left
    };

    return (<div style={style} className={styles.positionBox}>{
        Object.keys(output).map(key => key + ': ' + Math.round(output[key])).join(', ')
    }</div>);
};

export default injectIntl(CornerDecoration);

