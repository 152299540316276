import makeEpic, { makeEpicStateSelector } from '../../../../epics/makeEpic';
import valueActionType, { isPageModeValueActionType } from "./valueActionType";
import { receiveOnly } from "../../../../epics/makeCondition";
import { ComponentsMapSelector } from "../componentsEval/selectorActionTypes";
import { MOBILE_EDITOR_COMPONENT_SELECT } from '../../../MobileViewEditor/actionTypes';

const isPageModeFromAppStateSelector = makeEpicStateSelector(isPageModeValueActionType);

export default makeEpic({
    defaultState: true,
    valueActionType,
    updaters: [
        {
            conditions: [
                receiveOnly(ComponentsMapSelector),
                MOBILE_EDITOR_COMPONENT_SELECT
            ],
            reducer: ({ values: [componentsMap, { cmpId }], state: isPageMode }) => {
                if (!componentsMap[cmpId]) {
                    return { state: isPageMode };
                }
                const componentInTemplate = componentsMap[cmpId].inTemplate;
                if (componentInTemplate !== !isPageMode) {
                    return { state: !componentInTemplate };
                }
                return { state: isPageMode };
            }
        }
    ]
});

export {
    isPageModeFromAppStateSelector
};
