import React from "react";
import cx from "classnames";
import styles from './Inserter.css';

const EPSeparator = () => <div className={styles.separator} />;
const EPHalfSeparator = () => <div className={cx(styles.separator, styles.halfSeparator)} />;

export {
    EPSeparator,
    EPHalfSeparator,
    EPSeparator as default
};
