import { cloneDeep, set } from 'lodash';

import makeEpic from '../../../epics/makeEpic';
import valueActionType from './valueActionType';
import * as Actions from "../actionTypes";
import type { ToasterMessage } from "../flowTypes";
import { SCHEDULE_ACTION } from "../../../redux/middleware/schedule/actionTypes";
import { dialogManagerVAT } from '../../DialogManager/epic/valueActionType';
import { receiveOnly } from "../../../epics/makeCondition";
import { openedDialogIdsSelector } from '../../DialogManager/epic/selectors';
import { TutorialVideoDialogId } from '../../TopBar/view/dialogIds';

const messages: Array<ToasterMessage> = [];
const defaultState = {
    messages
};
const AUTO_DELETE_TIME = 6000;
const AUTO_CLEANUP_TIME = 500;

const updateHtml = (htmlObj: any, modifiedDataList: any) => {
    let modifiedHtml = cloneDeep(htmlObj);
    modifiedDataList.forEach(obj => {
        set(modifiedHtml, obj.key, obj.value);
    });
    return modifiedHtml;
};

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [receiveOnly(dialogManagerVAT), Actions.ADD_MESSAGE],
            reducer: ({ values: [dialogState, message], state }) => {
                if (state.messages.find((msg) => msg.msgKey === message.msgKey && !msg.delete)) {
                    return { state };
                }
                //if tutorial video is open dont show toaster
                if (dialogState && openedDialogIdsSelector(dialogState).includes(TutorialVideoDialogId)) {
                    return { state };
                }
                const _message = {
                    ...message,
                    timestamp: Date.now()
                };
                const multipleActionsToDispatch: Action[] = [];
                multipleActionsToDispatch.push({
                    type: SCHEDULE_ACTION,
                    payload: {
                        timeout: 100,
                        actionToDispatch: {
                            type: Actions.UPDATE_MESSAGE
                        }
                    }
                });
                if (!message.permanent) {
                    multipleActionsToDispatch.push({
                        type: SCHEDULE_ACTION,
                        payload: {
                            timeout: AUTO_DELETE_TIME,
                            actionToDispatch: {
                                type: Actions.AUTO_DELETE_MESSAGE
                            }
                        }
                    });
                }
                return {
                    state: {
                        ...state,
                        messages: [_message, ...state.messages]
                    },
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [Actions.UPDATE_MESSAGE],
            reducer: ({ state }) => {
                const messages = state.messages.map(msg => {
                    if (!msg.show) {
                        return {
                            ...msg,
                            show: true
                        };
                    }
                    return msg;
                });
                return {
                    state: {
                        ...state,
                        messages
                    }
                };
            }
        },
        {
            conditions: [Actions.MODIFY_HTML_MESSAGE_CONTENT],
            reducer: ({ state, values: [message] }) => {
                const messages = state.messages.map(msg => {
                    if (msg.msgKey === message.msgKey) {
                        let newObj = {
                            ...msg,
                            msgHtml: updateHtml(
                                msg.msgHtml,
                                message.modifiedDataList
                            )
                        };
                        return newObj;
                    }
                    return msg;
                });
                return {
                    state: {
                        ...state,
                        messages
                    }
                };
            }
        },
        {
            conditions: [Actions.AUTO_DELETE_MESSAGE],
            reducer: ({ values: [timeout], state }) => {
                if (!state.messages.length) return { state };
                let hasDeletedMsg = false;
                let autoDeleteTime = timeout || AUTO_DELETE_TIME;
                const messages = state.messages.map((message) => {
                    if ((Date.now() - message.timestamp) >= autoDeleteTime && !message.permanent) {
                        hasDeletedMsg = true;
                        return {
                            ...message,
                            delete: true
                        };
                    }
                    return message;
                });
                return {
                    state: {
                        ...state,
                        messages
                    },
                    actionToDispatch: {
                        type: SCHEDULE_ACTION,
                        payload: {
                            timeout: AUTO_CLEANUP_TIME,
                            actionToDispatch: {
                                type: hasDeletedMsg ? Actions.AUTO_CLEANUP_MESSAGE : Actions.AUTO_DELETE_MESSAGE
                            }
                        }
                    }
                };
            }
        },
        {
            conditions: [Actions.DELETE_MESSAGE],
            reducer: ({ values: [index], state }) => {
                const messages = state.messages.map((message, indx) => {
                    if (!message.delete && index === indx) {
                        return {
                            ...message,
                            delete: true
                        };
                    }
                    return message;
                });
                return {
                    state: {
                        ...state,
                        messages
                    },
                    actionToDispatch: {
                        type: SCHEDULE_ACTION,
                        payload: {
                            timeout: AUTO_CLEANUP_TIME,
                            actionToDispatch: {
                                type: Actions.AUTO_CLEANUP_MESSAGE
                            }
                        }
                    }
                };
            }
        },
        {
            conditions: [Actions.DELETE_MESSAGE_WITH_KEY],
            reducer: ({ values: [msgKey], state }) => {
                const messages = state.messages.map(message => {
                    if (!message.delete && msgKey === message.msgKey) {
                        return {
                            ...message,
                            delete: true
                        };
                    }
                    return message;
                });
                return {
                    state: {
                        ...state,
                        messages
                    },
                    actionToDispatch: {
                        type: SCHEDULE_ACTION,
                        payload: {
                            timeout: AUTO_CLEANUP_TIME,
                            actionToDispatch: {
                                type: Actions.AUTO_CLEANUP_MESSAGE
                            }
                        }
                    }
                };
            }
        },
        {
            conditions: [Actions.AUTO_CLEANUP_MESSAGE],
            reducer: ({ state }) => {
                const messages = state.messages.filter((message) => !message.delete);
                return {
                    state: {
                        ...state,
                        messages
                    }
                };
            }
        }
    ]
});
