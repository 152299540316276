import makeEpic from '../../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import { FEATURED_PRODUCTS_SHOW_PRODUCT_PREVIEW } from '../actionTypes';
import {
    LOAD_SITE_SETTINGS_SUCCESS
} from '../../../../App/epics/siteSettings/actionTypes';

import { SET_PREVIEW_PAGE_TO_LOAD } from '../../../../Preview/actionTypes';
import actionTypes from '../../reducer/actionTypes';

import {
    MOUSE_UP_ON_PREVIEW_BUTTON,
    BACK_TO_EDITOR
} from "../../../../App/actionTypes";

const getPreviewPageLoadAction = (webshopPageId): AnyAction => ({ type: SET_PREVIEW_PAGE_TO_LOAD, payload: [webshopPageId] });

export default makeEpic({
    defaultState: {},
    valueActionType,
    updaters: [
        {
            conditions: [
                FEATURED_PRODUCTS_SHOW_PRODUCT_PREVIEW,
                LOAD_SITE_SETTINGS_SUCCESS
            ],
            reducer: ({ state, values: [{ product }, { webshopPageIds }] }) => {
                if (Array.isArray(webshopPageIds) && webshopPageIds.length > 0) {
                    const webshopPageId = webshopPageIds[0];
                    const featuredProduct = (!product) ? {} : product;
                    return {
                        state,
                        multipleActionsToDispatch: [
                            { type: actionTypes.WEBSHOP_SET_FEATURED_PRODUCT_URL,
                                payload: featuredProduct },
                            getPreviewPageLoadAction(webshopPageId)
                        ]
                    };
                }
                return { state };
            }
        },
        {
            conditions: [
                MOUSE_UP_ON_PREVIEW_BUTTON
            ],
            reducer: ({ state }) => {
                return {
                    state,
                    actionToDispatch: {
                        type: actionTypes.WEBSHOP_SET_FEATURED_PRODUCT_URL,
                        payload: {}
                    }
                };
            }
        },
        {
            conditions: [
                BACK_TO_EDITOR
            ],
            reducer: ({ state }) => {
                return {
                    state,
                    actionToDispatch: {
                        type: actionTypes.WEBSHOP_SET_FEATURED_PRODUCT_URL,
                        payload: {}
                    }
                };
            }
        }
    ]
});
