import * as React from 'react';
import cx from 'classnames';
import styles from './styles.css';

type PropTypes = {
    href: string,
    target: string,
    title?: string,
    rel?: string,
    className?: string,
    style?: any,
    'data-dom-index'?: number,
    children: React.ReactElement<any>
}

/*
 * This is used when we need to attach onClick handlers to our published sites
 * This will add metadata needed by the onload script on published pages to attach the callbacks
 *
 * We use a dummy anchor tag so that AssetGraph can transform urls in preview and the attachOnClickHandlers
 * script uses the transformed anchor's href which has been placed as the first child of this component's outer div
 */

export default (props: PropTypes): React.ReactElement<any> => {
    const { children, style = {}, href, className, ...rest } = props;
    return (
        <div data-link="we-link" className={cx(styles.defaultStyle, className)} style={style} {...rest}>
            <a href={href} className={styles.dummyAnchorStyle}>&nbsp;</a>
            {children}
        </div>
    );
};
