import React from 'react';
import HelpTip from './HelpTip';
import TextKind from "../../oneweb/Text/kind";
import ButtonKind from "../../oneweb/Button/kind";

type Props = {
    dispatch: Dispatch,
    kind?: string,
};

const tipMsgs = {
    [TextKind]: "msg: extendedPanel.constrastBgHelpTip {This box is here to make your text visible. It will not appear when you add it to your website.}", // eslint-disable-line max-len
    [ButtonKind]: "msg: extendedPanel.constrastBgHelpTipButton {This box is here to make your button visible. It will not appear when you add it to your website.}", // eslint-disable-line max-len
};

export default ({ dispatch, kind = TextKind }: Props) => {
    return (<HelpTip
        msg={tipMsgs[kind]}
        dispatch={dispatch}
        helpTipClassName="inserterInfoTip"
        calcPosition={({ top, width, height, left }: ClientRect) => {
            // position relative to the help tip
            return {
                x: left + width - 4,
                y: (top + (height / 2)) - 4
            };
        }}
    />);
};
