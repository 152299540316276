import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import { DOUBLE_CLICK_THRESHOLD } from '../../../../constants/app';
import { WORKSPACE_CLICK, WORKSPACE_DOUBLE_CLICK } from "../../actionTypes";
import { calcPositionDiff, FuzzyClickDistance } from "../workspaceClick/index";

const defaultState = {
    lastClickTimestamp: null,
    lastPosition: {
        x: null,
        y: null
    }
};

function makeCurrentState(position) {
    return { lastClickTimestamp: Date.now(), lastPosition: position };
}

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [WORKSPACE_CLICK],
            reducer: ({ values: [position], state: prevState }) => {
                if (!prevState.lastClickTimestamp) {
                    return { state: makeCurrentState(position) };
                }
                const
                    sinceLastClickMs = Date.now() - prevState.lastClickTimestamp,
                    positionDiff = calcPositionDiff(position, prevState.lastPosition);

                if (sinceLastClickMs > DOUBLE_CLICK_THRESHOLD || positionDiff > FuzzyClickDistance) {
                    return { state: makeCurrentState(position) };
                } else {
                    return { state: [defaultState], actionToDispatch: { type: WORKSPACE_DOUBLE_CLICK } };
                }
            }
        }
    ]
});
