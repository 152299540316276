import * as actionTypes from "./actionTypes";
import closeDialog from "../../components/App/actionCreators/closeDialog";

export const
    width = 1000,
    height = 600,
    AllInOneTabName = {
        GENERAL: 'GENERAL',
        PROVIDE_JSON_TO_DB: 'PROVIDE_JSON_TO_DB'
    },
    DoneBtnActionForAllInOneTabs = {
        [AllInOneTabName.GENERAL]: [closeDialog()],
        [AllInOneTabName.PROVIDE_JSON_TO_DB]: [{ type: actionTypes.SAVE_JSON_TO_DB }]
    };
