import {
    LOAD_TEMPLATE_SELECTOR,
    LOAD_TEMPLATE_SELECTOR_FOR_ONBOARDING
} from "../actionTypes";

const loadTemplateSelectorForOnboarding = (payload: { isFirstVisit: boolean, source: string }) => ({
    type: LOAD_TEMPLATE_SELECTOR_FOR_ONBOARDING,
    payload
});

const loadTemplateSelector = (payload: { isFirstVisit?: boolean, source: string, filter ?: string }) => ({
    type: LOAD_TEMPLATE_SELECTOR,
    payload
});

export {
    loadTemplateSelector as default,
    loadTemplateSelector,
    loadTemplateSelectorForOnboarding
};
