import React from 'react';
import {
    IMAGE_PROP_PANEL_SCALE_STRATEGY
} from '../../actionTypes';
import * as pageIds from './ids';
import pagesTitles from './titles';
import { ScaleStrategiesIntl } from '../../scaleStrategy';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import PropertyContainer from "../../../../../view/common/PropertyContainer/index";
import Combobox from '../../../../../view/common/Combobox/index';
import { injectIntl } from "../../../../../view/intl/index";
import { ComboboxOptions } from '../../../../../view/common/Combobox/flowTypes';

// TODO: Props annotation
class Common extends React.Component<any> {
    render() {
        const
            { selectedComponent, dispatch, intl } = this.props,
            { scaleStrategy } = selectedComponent,
            scaleStrategies: ComboboxOptions = [];

        Object.keys(ScaleStrategiesIntl).forEach(strategy => {
            scaleStrategies.push({
                value: strategy,
                label: intl.msgJoint(ScaleStrategiesIntl[strategy])
            });
        });

        return (
            <Page>
                <VerticalSpacer />
                <PropertyContainer label="msg: component.image.scalingSettings {Image scaling:}">
                    <Combobox
                        searchable={false}
                        options={scaleStrategies}
                        value={scaleStrategy}
                        onChange={({ value: newScale }) =>
                            dispatch({ type: IMAGE_PROP_PANEL_SCALE_STRATEGY, payload: newScale })}
                    />
                </PropertyContainer>
            </Page>
        );
    }
}

const
    id: string = pageIds.SCALING,
    title = pagesTitles[id];

export default { id, view: injectIntl(Common), title };
