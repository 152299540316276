import * as R from "ramda";
import { toHsl } from '../../../../dal/pageMapAdapter/mappers/Base/color';
import type { ComponentsMap } from "../../../redux/modules/children/workspace/flowTypes";
import type { MobileStyle } from "./types";
import { toCss } from "../../../mappers/color";
import isStretchComponentKind from "../../oneweb/isStretchComponentKind";
import {
    findThemeColorTypeFromColorObject,
    getBrighterColor,
    getFallbackContrastColor,
    isGoodEnoughContrast,
    isOpaque,
    mergeColors,
    getHexFromRgba,
} from "../../../utils/colorUtils";
import { getTemplateBackgroundStyleFromStylesheetStyles } from "../../oneweb/Template/epics/template/styleMapper";
import {
    MenuStyleDefaults, HEADER_TITLE_COLOR, THEME_HEADER_BG_COLOR, THEME_HEADER_TITLE_COLOR, HEADER_BG_COLOR
} from "../../MobileViewEditor/header/constants";
import { getAutoMobileStyleComponentAndGlobalStyle } from "./getAutoMobileStyleComponentAndGlobalStyle";
import type { Stylesheets } from '../../Workspace/epics/stylesheets/flowTypes';
import { getTopMostMenuComponent } from '../header/menu/getTopMostMenuComponent';
import { getParentThemeMap } from '../../ThemeGlobalData/utils/getParentThemeMap';
import { getMenuThemeRulesFromBackgroundTheme, getThemeRulesForBackground } from '../../ThemeGlobalData/themeRules';
import type { ThemeBackgroundType, ThemeColorDataType, ThemeColorTypes } from '../../ThemeGlobalData/flowTypes';
import { isBoxKind } from "../../oneweb/componentKinds";
import { getIsModernLayoutActivated } from "../../ModernLayouts/preview_utils";
import { getHeaderSection } from "../../oneweb/Section/utils";
import { getDefaultAccentColor, getContrastMenuColor } from "../../oneweb/Menu/utils";

const getBgColor = (style) => {
    let bgColor = R.path(['background', 'colorData', 'color'], style);

    const gradient = R.path(['background', 'colorData', 'gradient'], style);
    if (gradient && gradient.fadePoint <= 50) {
        bgColor = gradient.color;
    }

    if (!bgColor) {
        return 'transparent';
    }

    return toCss(bgColor);
};

const getComponentsLayers = ({ component, componentsMap }) => {
    const layers: any[] = [];
    let currentComponent = component;
    while (currentComponent) {
        if (currentComponent.relIn) {
            const parentComponent = componentsMap[currentComponent.relIn.id];
            currentComponent = parentComponent;
            if (isBoxKind(parentComponent.kind) || isStretchComponentKind(parentComponent.kind)) {
                layers.push(getBgColor(parentComponent.style));
            }
        } else {
            currentComponent = null;
        }
    }
    return layers;
};

type DefineStyleReturnValue = {
    color: string;
    fontFamily: any;
    bold: boolean;
    italic: boolean;
    backgroundColor: string;
    menuBackgroundColor: string;
    menuColor: string;
    themeBackgroundColor: ThemeColorTypes | null;
    themeColor: ThemeColorTypes | null;
    themeMenuColor: ThemeColorTypes | undefined;
    themeMenuBackgroundColor: ThemeColorTypes | undefined;
    menuAccentColor: ThemeColorTypes | undefined;
    themeBackgroundColorOpacity: number | undefined
};

const defineStyle = (
    componentsMap: ComponentsMap,
    stylesheet: Stylesheets,
    autoColorMode?: boolean,
    inTemplateStyle?: Record<string, any>,
): DefineStyleReturnValue | null => {
    const componentAndGlobalStyle = getAutoMobileStyleComponentAndGlobalStyle(componentsMap, stylesheet, autoColorMode);

    if (!componentAndGlobalStyle) return null;

    const
        { component, globalStyle: gs } = componentAndGlobalStyle,
        {
            text: { color: rawColor, font, bold, italic },
            block,
        } = gs.item.inactive;

    if (!rawColor) return null;

    const textColor = toCss(rawColor);
    const isBadContrast = (_color) => !isGoodEnoughContrast(_color, textColor);

    const rgbaWhite = 'rgba(255,255,255,1)';
    const templateStyle = inTemplateStyle || getTemplateBackgroundStyleFromStylesheetStyles(stylesheet);
    const rbgaLayers = [
        getBgColor(block), // menu item bg color
        getBgColor(gs.block), // menu container bg color
        ...getComponentsLayers({ component, componentsMap }), // bg or strip backgrounds
        getBgColor(templateStyle), // template bg color
        rgbaWhite // white background of browser
    ];

    const layersSplittedByOpaqueLayer = rbgaLayers.reduce((result, rgbaLayer, index) => {
        result[result.length - 1].push(rgbaLayer);

        if (index !== rbgaLayers.length - 1 && isOpaque(rgbaLayer)) {
            result.push([]);
        }
        return result;
    }, [[]]);

    const backgroundColor = layersSplittedByOpaqueLayer.reduce((backgroundColor, rgbaLayersToMerge) => {
        if (backgroundColor !== null) return backgroundColor; // found backgroundColor, skip subsequent layers

        const mergedColor = mergeColors(rgbaLayersToMerge);
        if (isBadContrast(mergedColor)) return null;

        return mergedColor;
    }, null) || getFallbackContrastColor(textColor);

    if (!font || italic === undefined || bold === undefined) return null;

    const menuBackgroundColor = getBrighterColor(textColor, backgroundColor);
    const menuColor = textColor === menuBackgroundColor ? backgroundColor : textColor;

    return {
        color: textColor,
        fontFamily: font,
        bold,
        italic,
        backgroundColor,
        menuBackgroundColor,
        menuColor,
        themeBackgroundColor: null,
        themeColor: null,
        themeMenuColor: undefined,
        themeMenuBackgroundColor: undefined,
        menuAccentColor: undefined,
        themeBackgroundColorOpacity: undefined
    };
};

export const getAutoMobileStyle = (
    componentsMap: ComponentsMap,
    stylesheet: Stylesheets,
    inTemplateStyle?: Record<string, any>,
    autoColorMode?: boolean,
    templateSelectedTheme?: ThemeBackgroundType,
    themeColorsData?: ThemeColorDataType,
): MobileStyle => {
    let style = defineStyle(componentsMap, stylesheet, autoColorMode, inTemplateStyle) || {
        themeColor: null,
        color: null,
        fontFamily: null,
        bold: false,
        italic: false,
        themeBackgroundColor: null,
        themeBackgroundColorOpacity: 1,
        backgroundColor: null,
        menuBackgroundColor: null,
        menuColor: null,
        themeMenuColor: undefined,
        themeMenuBackgroundColor: undefined,
        menuAccentColor: undefined,
    };
    const isModernLayoutActivated = getIsModernLayoutActivated(componentsMap);
    const topMostMenuComponent = getTopMostMenuComponent(componentsMap);
    const templateBgColor = inTemplateStyle && inTemplateStyle.background &&
        inTemplateStyle.background.colorData && inTemplateStyle.background.colorData.color;

    if (topMostMenuComponent) {
        if (autoColorMode && themeColorsData) {
            const parentThemeMap = getParentThemeMap(componentsMap, templateSelectedTheme, 0);
            const parentThemeOfMenuComponent = parentThemeMap[topMostMenuComponent.id];
            const { menu: { normalTextColor: textColor } } =
                getMenuThemeRulesFromBackgroundTheme(parentThemeOfMenuComponent, themeColorsData);
            const rgbaTextColor = toCss(textColor);
            const backgroundColor = themeColorsData[getThemeRulesForBackground(parentThemeOfMenuComponent, themeColorsData).background];
            const rgbaBackgroundColor = toCss(backgroundColor);
            const menuBackgroundColor =
                getBrighterColor(rgbaTextColor, rgbaBackgroundColor) === rgbaTextColor ? backgroundColor : textColor;
            const topMostMenuComponentParentsBg = getComponentsLayers({ component: topMostMenuComponent, componentsMap });
            style = {
                ...style,
                // @ts-ignore
                themeColor: findThemeColorTypeFromColorObject(textColor, themeColorsData),
                // @ts-ignore
                themeBackgroundColor: findThemeColorTypeFromColorObject(backgroundColor, themeColorsData),
                // @ts-ignore
                themeBackgroundColorOpacity: topMostMenuComponentParentsBg.length ? toHsl(topMostMenuComponentParentsBg[0])[4] : 1,
                themeMenuBackgroundColor: findThemeColorTypeFromColorObject(menuBackgroundColor, themeColorsData),
                themeMenuColor:
                    findThemeColorTypeFromColorObject(textColor === menuBackgroundColor ? backgroundColor : textColor, themeColorsData),
            };
        } else if (isModernLayoutActivated) {
            const headerSection = getHeaderSection(componentsMap);
            const path = ["style", "background", "colorData", "color"];
            const headerColor = R.path(path, headerSection) || toHsl(HEADER_BG_COLOR);
            style = {
                ...style,
                backgroundColor: getHexFromRgba(toCss(headerColor)),
                menuBackgroundColor: getHexFromRgba(toCss(headerColor)),
                // @ts-ignore
                color: toCss(getContrastMenuColor(headerColor, templateBgColor)),
                // @ts-ignore
                menuColor: toCss(getContrastMenuColor(headerColor, templateBgColor)),
                // @ts-ignore
                menuAccentColor: topMostMenuComponent.style.accentColor || getDefaultAccentColor(stylesheet)
            };
        }
    }

    return {
        defaultLogo: {
            themeColor: style.themeBackgroundColor || THEME_HEADER_BG_COLOR,
            color: style.backgroundColor,
            themeBackgroundColor: style.themeColor || THEME_HEADER_TITLE_COLOR,
            backgroundColor: style.color,
        },
        title: {
            fontFamily: style.fontFamily,
            fontWeight: style.bold ? 'bold' : 'normal',
            fontStyle: style.italic ? 'italic' : 'normal',
            underline: false,
            themeColor: style.themeColor || THEME_HEADER_TITLE_COLOR,
            color: style.color || HEADER_TITLE_COLOR,
        },
        menu: {
            closed: {
                iconThemeColor: style.themeColor || MenuStyleDefaults.THEME_CLOSED_ICON_COLOR,
                // @ts-ignore
                iconColor: style.color ? toHsl(style.color) : toHsl(MenuStyleDefaults.CLOSED_ICON_COLOR),
            },
            open: {
                iconThemeColor: style.themeMenuColor || MenuStyleDefaults.THEME_OPEN_ICON_COLOR,
                // @ts-ignore
                iconColor: style.menuColor ? toHsl(style.menuColor) : toHsl(MenuStyleDefaults.OPEN_ICON_COLOR),
                fontFamily: style.fontFamily,
                bold: false,
                italic: false,
                underline: false,
                fontSize: MenuStyleDefaults.FONT_SIZE,
                lineHeight: MenuStyleDefaults.LINE_HEIGHT,
                themeColor: style.themeMenuColor || MenuStyleDefaults.THEME_OPEN_TEXT_COLOR,
                color: style.menuColor ? toHsl(style.menuColor) : null,
                backgroundThemeColor: style.themeMenuBackgroundColor || MenuStyleDefaults.THEME_OPEN_BG_COLOR,
                backgroundColor: style.menuBackgroundColor ? toHsl(style.menuBackgroundColor) : null,
            },
            selected: {
                bold: true,
                italic: false,
                underline: false,
                themeColor: style.themeMenuColor || MenuStyleDefaults.THEME_SELECTED_TEXT_COLOR,
                // @ts-ignore
                color: style.menuAccentColor || (style.menuColor ? toHsl(style.menuColor) : null),
                backgroundThemeColor: style.themeMenuBackgroundColor || MenuStyleDefaults.THEME_SELECTED_BG_COLOR,
                backgroundColor: style.menuBackgroundColor ? toHsl(style.menuBackgroundColor) : null,
            }
        },
        background: {
            themeColor: style.themeBackgroundColor || THEME_HEADER_BG_COLOR,
            themeColorOpacity: style.themeBackgroundColorOpacity || 1,
            color: style.backgroundColor ? toHsl(style.backgroundColor) : null,
            showBottomShadow: false,
            showBottomBorder: false,
        },
    };
};
