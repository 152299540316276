import {
    DASHBOARD_REDIRECT_TRIGGER_KEY,
    SessionData
} from "../../../../src/dashboard/src/components/Window/windowStorageUtils";

export const
    getAndClearRedirectSessionData = (): SessionData | null => {
        const sessionDataString = window.sessionStorage.getItem(
            DASHBOARD_REDIRECT_TRIGGER_KEY
        );

        if (sessionDataString) {
            try {
                return JSON.parse(sessionDataString);
            } finally {
                window.sessionStorage.removeItem(
                    DASHBOARD_REDIRECT_TRIGGER_KEY
                );
            }
        }
        return null;
    };
