import React from "react";
import factory from '../../Background/componentMainActions/factory';
import { TooltipTextForStripColorPicker } from "../../Strip/constants";
import { Msg } from "../../../../view/intl/index";
import type { CTAViewParams } from "../../../../view/Workspace/Decorations/ComponentMainActions/flowTypes";
import type { SectionComponent } from "../flowTypes";
import {
    SHOW_MODERN_HEADER_LAYOUT_LEFT_PANEL,
    SHOW_MODERN_FOOTER_LAYOUT_LEFT_PANEL
} from "../../../ModernLayouts/actionTypes";
import { isModernLayoutSection } from "../../../ModernLayouts/preview_utils";

export default factory({
    getEditButtonText: ({ selectedComponentIsHeader, selectedComponentIsFooter }) => {
        if (selectedComponentIsHeader) {
            return 'msg: component.section.editSection.header {Edit header}';
        }
        if (selectedComponentIsFooter) {
            return 'msg: component.section.editSection.footer {Edit footer}';
        }
        return 'msg: component.section.editSection {Edit section}';
    },
    CtaButtonTextView: ({ selectedComponentIsHeader }) => {
        return selectedComponentIsHeader ?
            <Msg k="component.section.headerLayouts">Header layouts</Msg> :
            <Msg k="component.section.footerLayouts">Footer layouts</Msg>;
    },
    isCtaVisible: ({ selectedComponentIsHeader, selectedComponentIsFooter, selectedComponent }) => {
        return (selectedComponentIsHeader || selectedComponentIsFooter) &&
            isModernLayoutSection(selectedComponent);
    },
    ctaOnClick: ({ dispatch, selectedComponentIsHeader, selectedComponent }: CTAViewParams<SectionComponent>) => {
        let actionType = selectedComponentIsHeader ? SHOW_MODERN_HEADER_LAYOUT_LEFT_PANEL : SHOW_MODERN_FOOTER_LAYOUT_LEFT_PANEL;
        dispatch({ type: actionType, payload: selectedComponent });
    },
    colorLabelForTheme: 'msg: component.section.sectionStyle {Section style}',
    tooltipTextForTheme: TooltipTextForStripColorPicker,
});
