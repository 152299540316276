import * as R from "ramda";
import {
    getAllAttachmentsForCmpIds,
    getTopMostParentId,
    removeDuplicatesFromAnArrayOfStrings
} from "../componentAttachements/util";
import { getComponentsMap as getCmpMap } from "./getters";
import { isStickyToHeader } from "./isStickyToHeader";
import { getNearestParentHoverBoxCmp, getOnHoverShow } from "../../../oneweb/HoverBox/utils";
import { isHoverBoxKind } from "../../../oneweb/componentKinds";
import { COMPONENT_CONVERTED_TO_TEMPLATE } from "./actionTypes";
import * as updateReasons from "./updateReasons";

export const processCmpsMapAfterAttachmentUpdateReducer = (
    {
        state,
        values: [{
            componentsId,
            oldAttachments,
            newAttachments,
            skipInTemplateUpdate
        }]
    }
) => {
    let newState = { ...state },
        actionToDispatch: Action | null = null;
    const cmpsConvertedToTemplate: Array<string> = [],
        cmpIdsWithAttachments = removeDuplicatesFromAnArrayOfStrings(componentsId.concat(
            getAllAttachmentsForCmpIds(newAttachments, componentsId)
        ));
    cmpIdsWithAttachments.forEach((cmpId) => {
        const
            componentsMap = getCmpMap(newState),
            component = componentsMap[cmpId],
            oldParentId = getTopMostParentId(cmpId, oldAttachments),
            newParentId = getTopMostParentId(cmpId, newAttachments),
            pathToComponent = ['state', 'componentsMap', cmpId],
            transientPathToComponent = ['scope', 'stateBeforeTransientChanges', 'componentsMap', cmpId],
            hasBeforeTransientChanges = R.view(R.lensPath(['scope', 'stateBeforeTransientChanges', 'componentsMap']))(newState); // eslint-disable-line
        if (!skipInTemplateUpdate && oldParentId !== newParentId) {
            const inTemplate =
                    state.state.componentsMap[newParentId].inTemplate,
                isStickCmp = isStickyToHeader(component);
            if (hasBeforeTransientChanges) {
                newState = R.assocPath([...transientPathToComponent, 'inTemplate'], inTemplate, newState);
                if (isStickCmp) {
                    newState = R.assocPath([...transientPathToComponent, 'isStickyToHeader'], false, newState);
                }
            }
            newState = R.assocPath([...pathToComponent, 'inTemplate'], inTemplate, newState);
            if (isStickCmp) {
                newState = R.assocPath([...pathToComponent, 'isStickyToHeader'], false, newState);
            }
            if (inTemplate) {
                cmpsConvertedToTemplate.push(cmpId);
            }
        }
        const oldParentHoverId = getNearestParentHoverBoxCmp(cmpId, oldAttachments, componentsMap),
            newParentHoverId = getNearestParentHoverBoxCmp(cmpId, newAttachments, componentsMap);
        if (oldParentHoverId !== newParentHoverId && !componentsId.includes(newParentHoverId)) {
            const onHoverShow = getOnHoverShow(cmpId, newAttachments, componentsMap);
            let component = newState.state.componentsMap[cmpId];
            let updatedProps;
            if (onHoverShow === null) {
                updatedProps = {
                    onHover: null
                };
            } else {
                updatedProps = {
                    mobileDown: onHoverShow ? false : component.mobileDown,
                    onHover: {
                        show: onHoverShow || false
                    }
                };
            }
            newState = R.assocPath([...pathToComponent], { ...component, ...updatedProps }, newState);
            if (hasBeforeTransientChanges) {
                newState = R.assocPath([...transientPathToComponent],
                    {
                        ...newState.scope.stateBeforeTransientChanges.componentsMap[cmpId],
                        ...updatedProps
                    },
                    newState);
            }
        }
        if (isHoverBoxKind(component.kind)) {
            const hoverboxChildComponents = (newAttachments[cmpId] || []).map(id => componentsMap[id]);
            hoverboxChildComponents
                .filter(cmp => !cmp.onHover) // filter attached to hoverbox without onHover property
                .map(cmp => {
                    // we set onHover here because it is attached to hoverbox
                    const onHoverShow = getOnHoverShow(cmp.id, newAttachments, componentsMap);
                    return {
                        ...cmp,
                        mobileDown: onHoverShow ? false : cmp.mobileDown,
                        onHover: {
                            show: onHoverShow || false
                        }
                    };
                })
                .forEach(cmp => {
                    newState = R.assocPath(['state', 'componentsMap', cmp.id], cmp, newState);
                    if (hasBeforeTransientChanges) {
                        newState = R.assocPath(
                            ['scope', 'stateBeforeTransientChanges', 'componentsMap', cmp.id],
                            {
                                ...newState.scope.stateBeforeTransientChanges.componentsMap[cmp.id],
                                mobileDown: cmp.mobileDown,
                                onHover: cmp.onHover
                            },
                            newState
                        );
                    }
                });
        }
    });
    if (cmpsConvertedToTemplate.length) {
        actionToDispatch = {
            type: COMPONENT_CONVERTED_TO_TEMPLATE,
            payload: { newComponentsIds: cmpsConvertedToTemplate },
        };
    }
    return {
        state: newState,
        actionToDispatch,
        updateReason: updateReasons.PROPERTY_CHANGE
    };
};
