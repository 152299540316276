export const PARAGRAPH_TAGS: Array<string> = ['ol', 'ul', 'p'];
export const TYPE_SORT_PRIORITY: Array<string> = [
    'wrapper', 'para', 'listItem',
    'link',
    'style',
    'text', 'break'
];

export const STYLE_SORT_PRIORITY: Array<string> = [
    'size',
    'superscript',
    'subscript'
];

export const GLOBAL_NAME_SORT_PRIORITY_REGEX: Array<RegExp> = [
    /\[paragraph\]/,
    /\[heading.1\]/,
    /\[heading.3\]/,
    /\[heading.2\]/,
    /link.[\d]+/
];
export const NEW_LINE_CHAR: string = '\n';
export const EMPTY_SPACE: string = '&#65279;';

export const TEXT_GLOBAL_CLASS_STYLES: Array<string> = [
    'font',
    'bold', 'italic', 'underline',

    'letterspacing',
    'shadow',

    'size',
    'color'
];

const CONTENT_BLOCK_STYLES = [
    'align',
    'height'
];

export const PARA_OVERRIDE_STYLES = [
    'size'
];

export const PARA_STYLES: Array<string> = [
    ...CONTENT_BLOCK_STYLES,
    'padding',
    'indent'
];

export const LIST_OVERRIDE_STYLES = [
    'size',
    'ref',
    'globalName',
    'globalId',
    'font',
    'bold',
    'italic',
    'letterspacing',
    'color'
];

export const LIST_STYLES: Array<string> = [
    ...CONTENT_BLOCK_STYLES,
    ...LIST_OVERRIDE_STYLES
];
