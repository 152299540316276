import CALL_API from '../../../../../redux/middleware/api/CALL_API';
import * as Actions from "../actionTypes";

export default function (data) {
    return {
        [CALL_API]: {
            types: [Actions.GET_SYNONYMS_FOR_LANGUAGE_REQUEST,
                Actions.GET_SYNONYMS_FOR_LANGUAGE_SUCCESS,
                Actions.GET_SYNONYMS_FOR_LANGUAGE_FAILED],
            endpoint: "synonyms",
            endpointParams: data,
        }
    };
}
