export enum EventTrackingIds {
    INITIATE_WSB_ONBOARDING = 'WSB onboarding initiated',
    COMPLETE_WSB_ONBOARDING = 'WSB onboarding completed',
    PUBLISH_WSB = 'WSB website published',
    LAUNCH_WEBSITE = 'WSB website launched',
    // WSB Dashboard Events
    DASHBOARD_OPEN_ONLINE_SHOP = "WSB Dashboard online shop enter",
    DASHBOARD_CREATE_FIRST_PRODUCT = "WSB Dashboard create first product",
    DASHBOARD_OPEN_SHOP_OPEN_ORDERS = "WSB Dashboard go to open orders",
    DASHBOARD_OPEN_SHOP_ALL_PRODUCTS = "WSB Dashboard go to product list",
    DASHBOARD_OPEN_SHOP_OUT_OF_STOCK = "WSB Dashboard go to out of stock",
    DASHBOARD_CLOSE_ONLINE_SHOP = "WSB Dashboard online shop exit",
    DASHBOARD_BOOKINGS_TRIAL_LEARN_MORE_CLICK = "WSB booking trial learn more click",
    // upgrade dialog
    WSB_UPGRADE_PLANS_DIALOG_OPENED = 'WSB upgrade dialog opened',
    WSB_UPGRADE_PLANS_DIALOG_VIEWED = 'WSB plans overview viewed',
    WSB_UPGRADE_BUTTON_CLICKED = 'WSB upgrade button clicked',
    WSB_UPGRADE_CANCELED = 'WSB upgrade cancelled',
    WSB_FREE_UPGRADE_APPROVED = 'WSB free upgrade approved',
    WSB_UPGRADE_SUCCESSFUL = 'WSB upgrade successful',
}

export const MixpanelSlackErrorType = 'mixpanel';

export enum WorkspaceEventIds {
    COMPONENT_ADDED = 'WSB Component added',
    ENTERED_WSB = 'WSB Entered Websitebuilder',
}

export enum PreviewEventIds {
    PREVIEW = 'WSB Preview',
}

export enum ModuleSource {
    WSB_DASHBOARD = "WSB dashboard",
    WSB_EDITOR = "WSB editor",
}

export enum UpgradeOffer {
    Free = "Free upgrade",
    Paid = "Paid upgrade"
}

export enum UpgradeCancelReason {
    Error = "Error",
    UserCancelled = "Cancelled by user"
}

export const ApplicationSource = "Website builder";

/* eslint-disable no-multi-spaces */

// IMPORTANT: naming convention
//      single word:        Only first letter is in upper case (ex: Language)
//      multiple words:     Only first letter of last word is in upper case (ex: upgradedsubscriptionType)
export enum EventTrackingProps {
    // common properties
    /** partner name */                 brand = 'Brand',
    /** @see ApplicationSource */       application = 'Application',
    /** @see ModuleSource */            module = 'Module',
    /** domain hash */                  domain = 'Domain',
    /** active subscription type */     subscriptionType = 'subscriptionType',
    /** wsb selected language */        editorLanguage = 'Editor language',
    /** only for editor (trial) */      trialId = 'trialId',
    /** only for editor (trial) */      trialEmail = 'trialEmail',
    /** only for editor (trial) */      trialDomain = 'trialDomain',
                                        email = 'email',
                                        adminId = 'adminId',
    /** env (prod, staging, ...) */     server = 'Server',

    // other properties
                                        selectedTemplate = 'selectedTemplate',
                                        oldOnboarding = 'oldOnboarding',
                                        version = 'Version',
                                        source = 'Source',
    /** @see UpgradeOffer */            upgradeOffer = 'upgradeOffer',
    /** @see UpgradeCancelReason */     upgradecancelReason = 'upgradecancelReason',
                                        upgradedsubscriptionType = 'upgradedsubscriptionType',
}

/* eslint-enable */
