import { max } from 'ramda';
import { DEFAULT_WIDTH, DEFAULT_HEIGHT } from './constants';

const hook = ({
    component,
    componentExtension
}) => {
    const minWidth = DEFAULT_WIDTH;
    const minHeight = DEFAULT_HEIGHT;

    const updatedComponent = {
        ...component,
        width: max(minWidth, component.width),
        height: max(minHeight, component.height),
    };

    return [updatedComponent, componentExtension];
};

const adjustmentHookConfig = {
    hook,
    shouldCallHook: () => true,
};

export { adjustmentHookConfig };
