import React from "react";
import { useDispatch } from "react-redux";

import PrimaryButton from '../../../view/common/Button/PrimaryButton';
import SecondaryButton from '../../../view/common/Button/SecondaryButton';
import { Msg } from "../../../view/intl/index";

import { isNormalSubscription } from "../../App/epics/subscriptionData/utils";

import styles from './TemplateSelector.css';
import { TRACK_TEMPLATE_PREVIEW_EVENT } from "../actionTypes";
import { isPremiumTemplateBasedOnSubscription } from "./utils";

export const TemplateOverlay = (props) => {
    const {
        design,
        onePageDesign,
        themeSettings,
        previewTemplate,
        subscriptionType,
        timeStamp
    } = props;
    const dispatch = useDispatch();

    const trackEvent = () => dispatch({
        type: TRACK_TEMPLATE_PREVIEW_EVENT,
        payload: { time: Math.round((Date.now() - timeStamp) / 1000) }
    });

    const isPremium = isPremiumTemplateBasedOnSubscription(design, subscriptionType);

    return (
        <div className={styles.templateOverlay}>
            <div className={styles.templateName}>
                <span>{design.name}</span>
            </div>

            <div className={styles.buttonActions}>
                {
                    onePageDesign ?
                        <React.Fragment>
                            <SecondaryButton
                                onClick={() => {
                                    trackEvent();
                                    previewTemplate(
                                        design,
                                        isNormalSubscription(subscriptionType),
                                        themeSettings.autoColorMode
                                    );
                                }}
                                className={isPremium ? styles.premiumViewBtn : styles.viewBtn}
                            >
                                { isPremium && <div className={styles.premiumIcon} /> }
                                <Msg k="common.multiplePages">Multiple Pages</Msg>
                            </SecondaryButton>
                            <PrimaryButton
                                onClick={() => {
                                    trackEvent();
                                    previewTemplate(
                                        onePageDesign,
                                        isNormalSubscription(subscriptionType),
                                        themeSettings.autoColorMode
                                    );
                                }}
                                className={styles.selectBtn}
                            >
                                <Msg k="common.singlePage">One Page</Msg>
                            </PrimaryButton>
                        </React.Fragment> :
                        <PrimaryButton
                            onClick={() => {
                                trackEvent();
                                previewTemplate(
                                    design,
                                    isNormalSubscription(subscriptionType),
                                    themeSettings.autoColorMode
                                );
                            }}
                            className={isPremium ? styles.premiumViewBtn : styles.selectBtn}
                        >
                            { isPremium && <div className={styles.premiumIcon} /> }
                            <Msg k="common.preview">Preview</Msg>
                        </PrimaryButton>
                }
            </div>
        </div>
    );
};
