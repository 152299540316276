import React from "react";
import { connect } from "react-redux";
import { makeEpicStateSelector } from "../../../../epics/makeEpic";
import sectionsBlocksVAT from "../../../Panel/epics/sectionsBlocks/valueActionType";
import HeaderFilterView from "../../../Panel/configs/Header";
import { FILTERS } from "../epics/ExtendedPanelScaleStateEpic/constants";
import { SET_FILTER_VALUE } from "../epics/ExtendedPanelScaleStateEpic/actionTypes";
import { EXTENDED_PANEL_TEXT_STYLE_STATE_EPIC_VALUE } from "../epics/ExtendedPanelScaleStateEpic/valueActionType";
import { TextTemplateView } from "./expandedPanelContent";
import { injectIntl } from "../../../../view/intl/index";
import styles from "./ExpandedPanelText.css";
// const tipMsg = 'msg: extendedPanel.TextLayoutTip {Add chunks of text styles to your website quick and easy. Your template styles will be added once you drop a block on the workspace.}'; // eslint-disable-line

const Header = injectIntl(({ intl, sectionsBlocksState: { listLoaded, hasError }, filterKey, dispatch }) => {
    if (!listLoaded) return null;

    const props = {
        data: FILTERS,
        title: intl.msgJoint('msg: extendedPanel.textTitle {Text}'),
        selected: filterKey,
        actionName: SET_FILTER_VALUE,
        dispatch,
        className: styles.textFilters,
        hasError
    };

    return <HeaderFilterView {...props} />;
});

const mapStateToProps = (appState) => ({
    sectionsBlocksState: makeEpicStateSelector(sectionsBlocksVAT)(appState),
    filterKey: (makeEpicStateSelector(EXTENDED_PANEL_TEXT_STYLE_STATE_EPIC_VALUE)(appState)).filterKey
});

export const HeaderView = connect(mapStateToProps)(Header);

export const Content = TextTemplateView;
