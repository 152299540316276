import calculateDefaultComponentsSequence from "../MobileViewEditor/calculateDefaultComponentsSequence";
import calcMobileStyles from "../MobileViewEditor/calcStyles";
import calcMobileSettings from "../MobileViewEditor/calcSettings";
import calcFinalMobileSequence from "../MobileViewEditor/calcFinalMobileSequence";
import type { ComponentsMap } from "../../redux/modules/children/workspace/flowTypes";
import flattening from "./flattening/Flattening";
import type { TemplateComponent } from "../oneweb/Template/flowTypes";
import updateNewGroupsBasedOnOldGroups from "../MobileViewEditor/updateNewGroupsBasedOnOldGroups";
import type { MobileData } from "../MobileViewEditor/flowTypes";
import expandTemplateWidthAndAdjustComponents from "./expandTemplateWidthAndAdjustComponents";
import fixBBoxes from "../Workspace/epics/componentsEval/fixBBoxes";
import { updateComponentsRelIns } from "../Workspace/epics/relations/updateComponentsRelIns";
import { updateRelationWhenComponentCutsOff } from "../Workspace/epics/relations/updateRelationWhenComponentCutsOff";
import updateStripDimensions from './updateStripDimensions';
import { resetDefaultHeightForHeaderFooter } from "../Workspace/epics/defaultHeaderFooterHeights/utils";
import { createAttachments, getChildToParentMap } from "../Workspace/epics/componentAttachements/util";
import { getAttachmentsForDynamicHeightCmp, getChangedHeightsCmpInfoMap, } from "./flattening/util";
import {
    getHeaderAndFooterSection,
    shrinkHeaderFooterHeightIfInvalid,
    shrinkTheOnlyPageSectionHeightIfEmpty
} from "../oneweb/Section/utils";
import { removeLargeWhiteSpaces } from "../Workspace/epics/componentsEval/removeWhiteSpaces";
import { removeHeaderFooterRelations } from "../MobileViewEditor/epics/reorder/removeHeaderFooterRelations";
import { updateWebshopHeight } from "./updateWebshopHeight";
import { extractOldHeaderOrFooterCmps } from "../ModernLayouts/preview_utils";
import { WebShopMHFData } from "../oneweb/WebShopMHF/flowTypes";

type DefaultHeights = {
    isDefaultHeaderHeightSet: boolean,
    isDefaultFooterHeightSet: boolean
};

type PreviewInput = {
    componentsMap: ComponentsMap,
    template: TemplateComponent,
    isForWorkspace?: boolean,
    mobileData: MobileData,
    defaultHeights?: DefaultHeights,
    webshopMHFData?: WebShopMHFData,
    dynamicTemplateSelected?: boolean
};

export default ({
    componentsMap: oldComponentsMapInp,
    template: oldTemplate,
    isForWorkspace = false,
    mobileData,
    defaultHeights = { isDefaultHeaderHeightSet: false, isDefaultFooterHeightSet: false },
    webshopMHFData = { data: { policies: [], paymentMethods: [] } },
    dynamicTemplateSelected = false
}: PreviewInput) => {
    const { isDefaultHeaderHeightSet, isDefaultFooterHeightSet } = defaultHeights;
    let oldComponentsMap = extractOldHeaderOrFooterCmps(oldComponentsMapInp).componentsMap,
        newComponentsMap = oldComponentsMap;
    if (!isForWorkspace) {
        newComponentsMap = fixBBoxes(oldComponentsMap, oldTemplate);
    } else {
        newComponentsMap = resetDefaultHeightForHeaderFooter(newComponentsMap, { isDefaultHeaderHeightSet, isDefaultFooterHeightSet });
    }
    newComponentsMap = shrinkHeaderFooterHeightIfInvalid(newComponentsMap);
    newComponentsMap = shrinkTheOnlyPageSectionHeightIfEmpty(newComponentsMap);
    newComponentsMap = updateWebshopHeight(newComponentsMap);
    newComponentsMap = updateStripDimensions(newComponentsMap, oldTemplate.width);
    newComponentsMap = updateComponentsRelIns(newComponentsMap, oldTemplate.width);
    if (!isForWorkspace) {
        newComponentsMap = removeLargeWhiteSpaces(newComponentsMap, oldTemplate.width);
    }

    const newData = expandTemplateWidthAndAdjustComponents(newComponentsMap, oldTemplate, true);
    newComponentsMap = newData.componentsMap;
    const template = newData.template;

    const
        data = flattening({
            componentsMap: updateRelationWhenComponentCutsOff(newComponentsMap),
            template,
            isForWorkspace,
            addExtraPaddingOnSides: dynamicTemplateSelected
        }),
        mobileRelatedData = flattening({
            componentsMap: newComponentsMap,
            template,
            isForWorkspace,
            addExtraPaddingOnSides: dynamicTemplateSelected
        }),
        attachments = createAttachments({ componentsMap: newComponentsMap }),
        childToParentMap = getChildToParentMap(attachments),
        result = calculateDefaultComponentsSequence(newComponentsMap, template, attachments,
            (mobileRelatedData ? mobileRelatedData.structure : {})),
        { newGroups, newSequence } = updateNewGroupsBasedOnOldGroups(
            mobileData.groups,
            result.groups,
            newComponentsMap,
            result.data
        ),
        {
            header: { id: headerId },
            footer: { id: footerId }
        } = getHeaderAndFooterSection(newComponentsMap),
        mobileSequenceData = calcFinalMobileSequence(
            newSequence,
            removeHeaderFooterRelations(mobileData.relations, headerId, footerId, newComponentsMap),
            newComponentsMap,
            template.id,
            newGroups,
        ),
        dynamicHeightsCmpInfo = getChangedHeightsCmpInfoMap(
            data && data.floatingComponents ? data.floatingComponents.map(({ id }) => id) : [],
            newComponentsMap,
            childToParentMap
        ),
        dynamicHeightComponentAttachments = getAttachmentsForDynamicHeightCmp(attachments, dynamicHeightsCmpInfo);

    return {
        data,
        mobileData: {
            data: mobileSequenceData,
            wrappedCmpsMap: result.wrappedCmpsMap,
            root: template.id,
            styles: calcMobileStyles(template.id, mobileSequenceData, newComponentsMap, newGroups),
            groups: newGroups,
            settings: calcMobileSettings(newGroups, mobileData.settings, newComponentsMap)
        },
        componentsMap: newComponentsMap,
        template,
        childToParentMap,
        dynamicHeightsCmpInfo,
        dynamicHeightComponentAttachments,
        webshopMHFData,
    };
};
