export const SHOW_WEBSHOP_ADMIN_DIALOG = 'SHOW_WEBSHOP_ADMIN_DIALOG';
export const SHOW_WEBSHOP_UPGRADE = 'SHOW_WEBSHOP_UPGRADE';
export const HIDE_WEBSHOP_UPGRADE = 'HIDE_WEBSHOP_UPGRADE';
export const HIDE_WEBSHOP_ADMIN_DIALOG = 'HIDE_WEBSHOP_ADMIN_DIALOG';
export const LOGIN_TO_WEBSHOP = 'LOGIN_TO_WEBSHOP';
export const SHOW_WEBSHOP_ONBOARDING_DIALOG = 'SHOW_WEBSHOP_ONBOARDING_DIALOG';
export const HIDE_WEBSHOP_ONBOARDING_DIALOG = 'HIDE_WEBSHOP_ONBOARDING_DIALOG';
export const CLOSE_WEBSHOP_ONBOARDING_DIALOG = 'CLOSE_WEBSHOP_ONBOARDING_DIALOG';
export const HIDE_WEBSHOP_IFRAME_DIALOGS = 'HIDE_WEBSHOP_IFRAME_DIALOGS';
export const SHOW_WEBSHOP_UPGRADE_SUCCESS = 'SHOW_WEBSHOP_UPGRADE_SUCCESS';
export const HIDE_WEBSHOP_UPGRADE_SUCCESS = 'HIDE_WEBSHOP_UPGRADE_SUCCESS';
