export const
    USERNAME_EXISTS_EXCEPTION = 'UsernameExistsException',
    USERNAME_NOT_EXISTS_EXCEPTION = 'UserNotFoundException',
    ATTEMPT_LIMIT_EXCEEDED_EXCEPTION = 'LimitExceededException',
    NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException',
    EXPIRED_CODE_EXCEPTION = 'ExpiredCodeException',
    CODE_MISMATCH_EXCEPTION = 'CodeMismatchException',
    USER_DISABLED_EXCEPTION = 'UserDisabledException',
    USER_EMAIL_VERIFICATION_REQUIRED_EXCEPTION = 'TrialEmailVerificationRequiredException',
    USER_LAMBDA_VALIDATION_EXCEPTION = 'UserLambdaValidationException',
    KEEP_LOGGED_IN_SESSION = 'keepLoggedInSession';
