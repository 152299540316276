/* eslint-disable max-len */
import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import * as pagesIds from "./ids";
import pagesTitles from "./titles";
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import injectIntl from '../../../../../view/intl/injectIntl';
import type { Props } from '../flowTypes';
import { Switch } from '../../../../../../../src/dashboard/src/components/Switch';
import { makeEpicStateSelector } from '../../../../../epics/makeEpicStateSelector';
import { siteSettingsValueActionType } from '../../../../App/epics/siteSettings/valueActionType';
import { marketingConsentStyles } from './marketingConsentStyles';
import { DISABLE_MARKETING_CONSENT,
    ENABLE_MARKETING_CONSENT,
    MARKETING_CONSENT_CHECKBOX_TEXT_CHANGED,
    MARKETING_CONSENT_GO_TO_DASHBOARD_FOR_PP } from '../../actionTypes';
import { DEFAULT_MARKETING_CONSENT_CHECKBOX_TEXT } from '../../constants';

const
    id = pagesIds.MARKETING_CONSENT,
    title = pagesTitles[id],
    view = injectIntl((Props: Props) => {
        const { intl, dispatch, selectedComponent } = Props;
        const siteSettings = useSelector(makeEpicStateSelector(siteSettingsValueActionType))?.current;
        const { isMarketingConsentEnabled, marketingConsentCheckBoxText } = selectedComponent;
        const [checked, setChecked] = useState<boolean>(isMarketingConsentEnabled);
        const defaultCheckBoxText = intl.msgJoint(DEFAULT_MARKETING_CONSENT_CHECKBOX_TEXT);
        const [checkBoxText, setCheckBoxText] = useState<string>(marketingConsentCheckBoxText || defaultCheckBoxText);
        const noTextAlert = checked && !checkBoxText;
        const privacyPolicyContent = siteSettings?.privacyPolicy?.content;
        const privacyPolicyBtnText = privacyPolicyContent ?
            "msg: component.contactForm.editPrivacyPolicy {Edit privacy policy}" :
            "msg: component.contactForm.setPrivacyPolicy {Set privacy policy}";

        const handleSwitchChange = (enabled: boolean) => {
            setChecked(enabled);

            if (enabled) {
                dispatch({ type: ENABLE_MARKETING_CONSENT });

                if (checkBoxText === defaultCheckBoxText) {
                    dispatch({ type: MARKETING_CONSENT_CHECKBOX_TEXT_CHANGED, payload: checkBoxText });
                }
            } else {
                dispatch({ type: DISABLE_MARKETING_CONSENT });
            }
        };

        const classes = marketingConsentStyles({ noTextAlert });
        const handleTextChange = (text: string) => {
            setCheckBoxText(text);

            dispatch({ type: MARKETING_CONSENT_CHECKBOX_TEXT_CHANGED, payload: text });

            if (!text && checked) {
                dispatch({ type: DISABLE_MARKETING_CONSENT });
            } else if (!checkBoxText && text && checked) {
                dispatch({ type: ENABLE_MARKETING_CONSENT });
            }
        };

        const onSetPPBtnClick = () => {
            dispatch({ type: MARKETING_CONSENT_GO_TO_DASHBOARD_FOR_PP });
        };

        return (
            <Page>
                <VerticalSpacer y={16} />
                <div className={classes.description}>
                    {intl.msgJoint("msg: component.contactForm.marketingConsentDescription {Ask visitors to subscribe to your marketing communication.}")}
                </div>
                <VerticalSpacer y={16} />
                <Button
                    className={cx(classes.setPPBtn, { [classes.EditPPBtn]: privacyPolicyContent })}
                    variant="outlined"
                    onClick={onSetPPBtnClick}
                    data-testid="privacy-policy-button"
                >
                    {intl.msgJoint(privacyPolicyBtnText)}
                </Button>
                <VerticalSpacer y={24} />
                <Switch
                    width={42}
                    height={24}
                    labelFontSize={14}
                    checked={checked}
                    labelPadding={8}
                    handleChange={handleSwitchChange}
                    label="msg: common.showCheckbox{Show checkbox}"
                />
                <VerticalSpacer y={24} />
                <div className={classes.description}>
                    {intl.msgJoint("msg: common.checkBoxText {Checkbox text}")}
                </div>
                <VerticalSpacer y={4} />
                <TextField
                    variant="outlined"
                    className={classes.root}
                    multiline
                    maxRows={6}
                    minRows={3}
                    value={checkBoxText}
                    onChange={(e) => handleTextChange(e.target.value)}
                />
                {
                    noTextAlert && (
                        <div className={cx(classes.description, classes.noTextAlert)}>
                            {intl.msgJoint("msg: component.contactForm.marketingConsentTextAlert {Enter your checkbox text.}")}
                        </div>
                    )
                }
            </Page>
        );
    });

export { id, title, view };
