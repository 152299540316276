import { getOnlyLinkFromSelection } from "./getLinkFromEditorSelection";
import { absoluteUrlToInputLink } from "../../../../../../../view/common/dialogs/LinkChooserDialog/utils/absoluteUrlToInputLink";

import type { TinyMceEditor } from "../../../flowTypes";

export const getLinkAction = (editor: TinyMceEditor, limitToOneContentBlock: boolean) => {
    const link = getOnlyLinkFromSelection(editor, limitToOneContentBlock);
    if (link) {
        return {
            link: absoluteUrlToInputLink(link.href),
            openInNewWindow: link.openInNewWindow
        };
    }
    return null;
};
