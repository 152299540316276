import React from "react";
import StandardDialog from '../../../../../view/common/dialogs/StandardDialog/index';
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import * as styles from '../PublishConfirmation/PublishConfirmation.css';
import Msg from '../../../../../view/intl/Msg';
import { SAVE_AND_PUBLISH_BUTTON_CLICKED } from "../../../../App/actionTypes";

const
    PublishDialogWidth = parseInt(styles.PublishDialogWidth, 10),
    PublishDialogHeight = parseInt(styles.PublishDialogHeight, 10);

const PublishConfirmationDialog = ({ dispatch }) => {
    return (
        <StandardDialog
            title="msg: publish.saveAndPublishDialogTitle {Save and publish}"
            mctaText="msg: publish.saveAndPublishText {Save & Publish}"
            mctaHandler={() => dispatch({ type: SAVE_AND_PUBLISH_BUTTON_CLICKED })}
            noTopBorderForFooter
        >
            <div className={styles.contentText}>
                <Msg k="publish.saveAndPublishDialogDescription">
                    You have changes which are not saved. These changes need to be saved first.
                </Msg>
            </div>
        </StandardDialog>
    );
};

export default getCenteredDialogConfig(PublishDialogWidth, PublishDialogHeight, PublishConfirmationDialog);
