/* eslint-disable max-len */

import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import cx from "classnames";
import { createPortal } from 'react-dom';
import { injectIntl } from "../../../view/intl/index";
import { DataSite } from "../../../../dal/model/index";
import mobileStyles from "../../Preview/View/Mobile/Mobile.css";
import { ViewTypes } from "../../TemplateSelector_DEPRECATED/constants";
import AppConfigEpicVAT from '../../App/epics/appConfig/valueActionType';
import { makeEpicStateSelector } from '../../../epics/makeEpic';
import type { Stylesheets } from "../../Workspace/epics/stylesheets/flowTypes";
import { themePagePreviewGenerateHtml, themePagePreviewGenerateHtmlProps } from "../utils/themePagePreviewGenerateHtml";
import type { ComponentsMap } from "../../../redux/modules/children/workspace/flowTypes";
import type { SiteSettings } from "../../App/epics/siteSettings/flowTypes";
import type { MobileData } from "../../MobileViewEditor/flowTypes";
import type { TemplateComponent } from "../../oneweb/Template/flowTypes";
import type { AppState } from "../../../redux/modules/flowTypes";
import { preventExternalLink, processInternalLink } from "../../Preview/View/Desktop/index";
import { InlineLoadingIndicatorCom } from '../../../view/common/LoadingIndicator/InlineLoadingIndicatorCom';
import styles from "./ThemePagePreview.css";
import { PreviewEditor } from './PreviewEditor';
import { editPreviewEtagSelector, editPreviewSelector } from '../../Onboarding/Dynamic/Epic/selectors';
import { DYNAMIC_PREVIEW_CLOSE_TOASTER } from '../../Onboarding/Dynamic/Epic/actionTypes';
import ErrorBoundary from '../../../redux/recoverAfterException/ErrorBoundary/ErrorBoundary';
import { isNextGenPreviewSelector } from '../../TemplateSelector_DEPRECATED/epics/preview/selector';
import { shouldShowEditPreview } from '../../Onboarding/domainHashUtils';
import { getPartnerName } from '../../ComponentTierManager/utils';

type Props = {
    stylesheets: Stylesheets,
    currentPageId: string,
    currentPageName: string,
    template: TemplateComponent,
    componentsMap: ComponentsMap,
    siteSettings: SiteSettings,
    siteMap: DataSite,
    mobileData: MobileData,
    expectedWidth?: string,
    isTemplateSelector?: boolean,
    localizationsByTemplateId?: Record<string, any>,
    changeThemePreviewPage?: Function,
    themingTemplatePreviewMode?: string,
    orientationClassName?: string,
    contentProps?: Record<string, any>,
    uiLocale?: string,
    config: Record<string, any>,
    dynamicTemplateSelected?: boolean,
    editPreviewProps: {
        edit: boolean
        etag: string,
        isNextGenPreview: boolean
    },
    dispatch: Dispatch
};

type State = {
    iFrameKey: number;
    isLoading?: boolean;
    html?: string
};

const CustomIframe = ({
    children,
    previewIframeRef,
    setIframeRef,
    ...props
}) => {
    const mountNode = previewIframeRef?.contentWindow?.document?.body;

    return (
        <iframe {...props} ref={setIframeRef}>
            {mountNode && createPortal(children, mountNode)}
        </iframe>
    );
};

class ThemePagePreview extends React.Component<Props, State> {
    setIframeRef: React.LegacyRef<HTMLIFrameElement> | undefined;
    previewIframeRef: any;

    constructor(props: any) {
        super(props);
        this.setIframeRef = previewIframeRef => { this.previewIframeRef = previewIframeRef; };
        this.state = { iFrameKey: Math.random(), isLoading: false };    //NOSONAR
    }

    getPreviewHtml = () => {
        const {
            stylesheets,
            currentPageId,
            currentPageName,
            siteSettings,
            template,
            componentsMap,
            mobileData,
            siteMap,
            expectedWidth = "1400px",
            isTemplateSelector,
            localizationsByTemplateId,
            uiLocale,
            config = {},
            dynamicTemplateSelected
        } = this.props;
        const
            themePagePreviewParams: themePagePreviewGenerateHtmlProps = {
                componentsMap,
                template,
                currentPageId,
                currentPageName,
                stylesheets,
                siteMap,
                siteSettings,
                mobileData,
                localizationsByTemplateId,
                uiLocale,
                config,
                dynamicTemplateSelected
            };
        if (!isTemplateSelector) {
            const
                downSizeScale = 300 / parseInt(expectedWidth, 10); // Or 300 / response.rect.width. But rect.width is always more than template width

            themePagePreviewParams.downSizeScale = downSizeScale;
        }
        const
            response = themePagePreviewGenerateHtml(themePagePreviewParams),
            { html } = response;
        return html;
    }

    addPartnerNameInIframeClass = () => {
        const frame = this.previewIframeRef;

        if (frame && frame.contentDocument) {
            const partnerName = getPartnerName();
            frame.contentDocument.documentElement.classList.add(partnerName);
        }
    }

    load() {
        const { changeThemePreviewPage, isTemplateSelector, currentPageId, dispatch } = this.props,
            frame = this.previewIframeRef,
            iframeDoc = frame.contentWindow.document,
            html = this.getPreviewHtml();
        try {
            iframeDoc.open();
            iframeDoc.write(html);
        } finally {
            if (this.props.editPreviewProps.edit) {
                this.setState({ html });
            }
            iframeDoc.addEventListener("DOMContentLoaded", () => {
                this.setState({ isLoading: false });
                this.addPartnerNameInIframeClass();
            });
            iframeDoc.close();
            if (isTemplateSelector) {
                frame.contentWindow.document.addEventListener('click', (event) => {
                    dispatch({ type: DYNAMIC_PREVIEW_CLOSE_TOASTER });
                    preventExternalLink(event);
                    // @ts-ignore
                    processInternalLink(event, changeThemePreviewPage, frame, currentPageId);
                }, true);
            }
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        this.load();
    }

    componentDidUpdate(prevProps: Props) {
        const {
            stylesheets: { theme },
            currentPageId,
            themingTemplatePreviewMode
        } = this.props;
        if (
            (prevProps.stylesheets.theme && theme && !R.equals(prevProps.stylesheets.theme.colors, theme.colors)) ||
            prevProps.currentPageId !== currentPageId ||
            prevProps.themingTemplatePreviewMode !== themingTemplatePreviewMode
        ) {
            /** Ref: React 18 new batching of events for state change.*/
            this.setState({ isLoading: true });
            this.setState({ iFrameKey: Math.random() });    //NOSONAR
            setTimeout(() => {
                this.load();
            }, 0);
        }
        if (prevProps.editPreviewProps.etag !== this.props.editPreviewProps.etag) {
            this.setState({ html: this.getPreviewHtml() });
        }
    }

    render() {
        const {
            expectedWidth = "1400px",
            themingTemplatePreviewMode = ViewTypes.desktop,
            orientationClassName,
            contentProps,
            editPreviewProps: {
                edit: editPreview,
                isNextGenPreview,
            }
        } = this.props;

        let mobileContainerClassName = "", desktopDefaultStyle = {};

        if (themingTemplatePreviewMode === ViewTypes.desktop) {
            desktopDefaultStyle = { height: '100%', width: expectedWidth };
        } else {
            mobileContainerClassName = `${mobileStyles.mobileContainer} ${orientationClassName}`;
        }

        const showEditPreview = isNextGenPreview && shouldShowEditPreview() && editPreview &&
            this.previewIframeRef?.contentWindow && themingTemplatePreviewMode === ViewTypes.desktop;

        return (
            <React.Fragment>
                {this.state.isLoading &&
                    <div className={styles.loaderContainer}>
                        <div>
                            <InlineLoadingIndicatorCom />
                        </div>
                    </div>}
                <div
                    className={mobileContainerClassName}
                    style={{ ...desktopDefaultStyle }}
                >
                    <div
                        className={styles.previewContainer}
                    >
                        <CustomIframe
                            key={this.state.iFrameKey}
                            className={cx("x-panel-body-noheader webshop-preview-in-website-builder")}
                            style={{ backgroundColor: '#fff' }}
                            setIframeRef={this.setIframeRef}
                            previewIframeRef={this.previewIframeRef}
                            allowFullScreen
                            // @ts-ignore
                            type="text/html"
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            {...contentProps}
                        >
                            { showEditPreview && <ErrorBoundary invisible> <PreviewEditor iframe={this.previewIframeRef} html={this.state.html} /> </ErrorBoundary>}
                        </CustomIframe>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (appState: AppState) => {
    const editPreviewProps = {
        edit: editPreviewSelector(appState),
        etag: editPreviewEtagSelector(appState),
        isNextGenPreview: isNextGenPreviewSelector(appState)
    };

    return {
        config: makeEpicStateSelector(AppConfigEpicVAT)(appState),
        editPreviewProps
    };
};

export default connect(mapStateToProps)(injectIntl(ThemePagePreview));
