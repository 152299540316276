import React from "react";
export const SvgVimeoRoundedFillLight = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <rect width={48} height={48} fill="#ffffff" rx={6} />
            <path
                fill="#3c3c3c"
                d="M35.58 18.814c-.109 2.343-1.746 5.557-4.913 9.632-3.276 4.254-6.047 6.386-8.316 6.386-1.402 0-2.592-1.294-3.562-3.887-.65-2.376-1.294-4.753-1.943-7.13-.72-2.592-1.492-3.886-2.32-3.886-.179 0-.81.381-1.892 1.134L11.5 19.604c1.19-1.044 2.362-2.09 3.515-3.134 1.586-1.37 2.777-2.09 3.572-2.164 1.873-.18 3.03 1.1 3.463 3.844.466 2.96.79 4.8.974 5.52.542 2.456 1.134 3.685 1.784 3.685.503 0 1.261-.796 2.273-2.39 1.007-1.591 1.548-2.806 1.623-3.638.146-1.375-.395-2.066-1.623-2.066-.579 0-1.172.132-1.784.395 1.186-3.878 3.445-5.76 6.786-5.652 2.47.066 3.638 1.671 3.496 4.81z"
            />
        </g>
    </svg>
);
