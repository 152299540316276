import * as actions from '../actionTypes';
import { WIDGETS_INSTAGRAM_EPIC_VAT } from "./valueActionType";
import { configurationDialogId } from '../configurationDialog';
import { parseInstagramCode } from '../utils/parseInstagramCode';
import { epicFactory } from '../../epics/factory';
import { linkExtractor } from '../../utils';

const WidgetsInstagramEpic = epicFactory({
    defaultState: null,
    valueActionType: WIDGETS_INSTAGRAM_EPIC_VAT,
    configurationDialogId,
    codeParser: parseInstagramCode,
    payloadExtractor: linkExtractor,
    setLinkAction: actions.WIDGETS_INSTAGRAM_SET_LINK,
    updatePropsAction: actions.WIDGETS_INSTAGRAM_UPDATE_PROPS,
    showUpdateLinkDialogAction: actions.WIDGETS_INSTAGRAM_SHOW_UPDATE_LINK_DIALOG,
    closeDialogActions: [actions.WIDGETS_INSTAGRAM_UPDATE_LINK, actions.WIDGETS_INSTAGRAM_UPDATE_PROPS],
});

export { WidgetsInstagramEpic };

