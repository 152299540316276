import { makeEpic } from "../../../../../epics/makeEpic";
import { isHeaderSection, isFooterSection, getHeaderAndFooterSection } from "../../utils";
import {
    ReceiveOnlySelectedComponentSelector,
    SelectedComponentSelector
} from "../../../../Workspace/epics/componentsEval/selectorActionTypes";
import { ReceiveOnlyAttachments } from "../../../../Workspace/epics/componentAttachements/selectorActionTypes";
import { SHOW_TOOLTIP_FOR_HEADER_FOOTER_SECTION } from "../../../../Tooltip/actionTypes";
import { SHOW_BLANK_HEADER_FOOTER_TOOLTIP } from "./actionTypes";
import { COMPONENTS_MAP_INITIALIZED } from "../../../../Workspace/epics/componentsEval/actionTypes";

export const valueActionType = "BLANK_HEADER_FOOTER_SELECTED_EPIC";

export const blankHeaderFooterEpic = makeEpic({
    defaultState: {
        isHeaderBlank: false,
        isFooterBlank: false
    },
    defaultScope: {
        shownHeaderTooltip: false,
        shownFooterTooltip: false
    },
    valueActionType,
    updaters: [
        {
            conditions: [ReceiveOnlyAttachments, COMPONENTS_MAP_INITIALIZED],
            reducer: ({ values: [attachments, { componentsMap }], state, scope }) => {
                const { header, footer } = getHeaderAndFooterSection(componentsMap);
                if (!header || !footer) return { state, scope };
                return {
                    state,
                    scope: {
                        ...scope,
                        shownHeaderTooltip: attachments[header.id],
                        shownFooterTooltip: attachments[footer.id]
                    }
                };
            },
        },
        {
            conditions: [
                ReceiveOnlyAttachments,
                SelectedComponentSelector
            ],
            reducer: ({ values: [attachments, selectedComponent], state, scope }) => {
                if (!selectedComponent) return { state, scope };
                const
                    attachedComponentIds = attachments[selectedComponent.id],
                    isSelectedComponentHasNoAttachment = !attachedComponentIds || attachedComponentIds.length === 0;

                let newState = { ...state };
                if (isHeaderSection(selectedComponent) && isSelectedComponentHasNoAttachment) {
                    newState = {
                        ...state,
                        isHeaderBlank: true
                    };
                }

                if (isFooterSection(selectedComponent) && isSelectedComponentHasNoAttachment) {
                    newState = {
                        ...state,
                        isFooterBlank: true
                    };
                }

                return {
                    state: newState,
                    scope
                };
            }
        },
        {
            conditions: [
                ReceiveOnlySelectedComponentSelector,
                SHOW_BLANK_HEADER_FOOTER_TOOLTIP
            ],
            reducer: ({ values: [selectedComponent, clientRect], state, scope }) => {
                if (!selectedComponent) return { state, scope };
                const
                    { top, width, height, left } = clientRect,
                    isSelectedCmpHeaderSection = isHeaderSection(selectedComponent),
                    actionToDispatch = {
                        type: SHOW_TOOLTIP_FOR_HEADER_FOOTER_SECTION,
                        payload: {
                            position: { x: left + (width / 2) - 1, y: top + height },
                            elementDimensions: { top, width, height, left },
                            isHeaderSection: isSelectedCmpHeaderSection,
                        }
                    };
                if (isSelectedCmpHeaderSection && !scope.shownHeaderTooltip) {
                    return {
                        state,
                        actionToDispatch,
                        scope: {
                            ...scope,
                            shownHeaderTooltip: true
                        }
                    };
                }
                if (!isSelectedCmpHeaderSection && !scope.shownFooterTooltip) {
                    return {
                        state,
                        actionToDispatch,
                        scope: {
                            ...scope,
                            shownFooterTooltip: true
                        }
                    };
                }
                return { state, scope };
            }
        }
    ],
});
