export const TEXT_LIKE_PREFIX_BOLD_CHANGED = 'TEXT_LIKE_PREFIX_BOLD_CHANGED';
export const TEXT_LIKE_PREFIX_ITALIC_CHANGED = 'TEXT_LIKE_PREFIX_ITALIC_CHANGED';
export const TEXT_LIKE_PREFIX_UNDERLINE_CHANGED = 'TEXT_LIKE_PREFIX_UNDERLINE_CHANGED';
export const TEXT_LIKE_VALUE_BOLD_CHANGED = 'TEXT_LIKE_VALUE_BOLD_CHANGED';
export const TEXT_LIKE_VALUE_ITALIC_CHANGED = 'TEXT_LIKE_VALUE_ITALIC_CHANGED';
export const TEXT_LIKE_VALUE_UNDERLINE_CHANGED = 'TEXT_LIKE_VALUE_UNDERLINE_CHANGED';
export const TEXT_LIKE_FONT_SIZE_CHANGED = 'TEXT_LIKE_FONT_SIZE_CHANGED';
export const TEXT_LIKE_COLOR_CHANGED = 'TEXT_LIKE_COLOR_CHANGED';
export const TEXT_LIKE_COLOR_CHANGED_AUTO_COLOR = 'TEXT_LIKE_COLOR_CHANGED_AUTO_COLOR';
export const TEXT_LIKE_COLOR_REMOVED_AUTO_COLOR = 'TEXT_LIKE_COLOR_REMOVED_AUTO_COLOR';
export const TEXT_LIKE_HORIZONTAL_ALIGNMENT_CHANGED = 'TEXT_LIKE_HORIZONTAL_ALIGNMENT_CHANGED';
export const TEXT_LIKE_CLEAR_FORMATTING = 'TEXT_LIKE_CLEAR_FORMATTING';
export const TEXT_LIKE_SHOW_CUSTOM_TITLE_FIRST_TOGGLE = 'TEXT_LIKE_SHOW_CUSTOM_TITLE_FIRST_TOGGLE';
export const TEXT_LIKE_CUSTOM_PREFIX_TEXT_CHANGED = 'TEXT_LIKE_CUSTOM_PREFIX_TEXT_CHANGED';
export const TEXT_LIKE_SHOW_ON_ONE_LINE_TOGGLE = 'TEXT_LIKE_SHOW_ON_ONE_LINE_TOGGLE';
export const TEXT_LIKE_FONT_FAMILY_CHANGED = 'TEXT_LIKE_FONT_FAMILY_CHANGED';
export const TEXT_LIKE_CHARACTER_SPACING_CHANGED = 'TEXT_LIKE_CHARACTER_SPACING_CHANGED';
export const TEXT_LIKE_LINE_SPACING_CHANGED = 'TEXT_LIKE_LINE_SPACING_CHANGED';
export const TEXT_LIKE_PP_VALUE_ALL_UPPERCASE_BTN_CLICKED = 'TEXT_LIKE_PP_VALUE_ALL_UPPERCASE_BTN_CLICKED';
export const TEXT_LIKE_PP_VALUE_LOWERCASE_BTN_CLICKED = 'TEXT_LIKE_PP_VALUE_LOWERCASE_BTN_CLICKED';
export const TEXT_LIKE_PP_PREFIX_ALL_UPPERCASE_BTN_CLICKED = 'TEXT_LIKE_PP_PREFIX_ALL_UPPERCASE_BTN_CLICKED';
export const TEXT_LIKE_PP_PREFIX_LOWERCASE_BTN_CLICKED = 'TEXT_LIKE_PP_PREFIX_LOWERCASE_BTN_CLICKED';
export const TEXT_LIKE_MOBILE_INC_FONT_BTN_PRESSED = 'TEXT_LIKE_MOBILE_INC_FONT_BTN_PRESSED';
export const TEXT_LIKE_MOBILE_DEC_FONT_BTN_PRESSED = 'TEXT_LIKE_MOBILE_DEC_FONT_BTN_PRESSED';
export const TEXT_LIKE_MOBILE_INC_SVG_SIZE_BTN_PRESSED = 'TEXT_LIKE_MOBILE_INC_SVG_SIZE_BTN_PRESSED';
export const TEXT_LIKE_MOBILE_DEC_SVG_SIZE_BTN_PRESSED = 'TEXT_LIKE_MOBILE_DEC_SVG_SIZE_BTN_PRESSED';
export const TEXT_LIKE_MOBILE_TEXT_ALIGNMENT_CHANGED = 'TEXT_LIKE_MOBILE_TEXT_ALIGNMENT_CHANGED';
export const TEXT_LIKE_MOBILE_CLEAR_FORMATTING = 'TEXT_LIKE_MOBILE_CLEAR_FORMATTING';

export const TEXT_LIKE_SHOW_ICON_TOGGLE = 'TEXT_LIKE_SHOW_ICON_TOGGLE';
export const TEXT_LIKE_ICON_SIZE_CHANGED = 'TEXT_LIKE_ICON_SIZE_CHANGED';
export const TEXT_LIKE_ICON_TEXT_SPACING_CHANGED = 'TEXT_LIKE_ICON_TEXT_SPACING_CHANGED';
export const TEXT_LIKE_MCTA_CHANGE_SIZE = 'TEXT_LIKE_MCTA_CHANGE_SIZE';
export const TEXT_LIKE_MOBILE_MCTA_CHANGE_SIZE = 'TEXT_LIKE_MOBILE_MCTA_CHANGE_SIZE';
