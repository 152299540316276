export const
    BOLD = 'Bold',
    ITALIC = 'Italic',
    UNDERLINE = 'Underline',
    SUPERSCRIPT = 'Superscript',
    SUBSCRIPT = 'Subscript',
    FORECOLOR = 'ForeColor',
    HIGHLIGHT_COLOR = 'HiliteColor',
    BACKCOLOR = 'backColor',
    REMOVE_FORMAT = 'RemoveFormat',
    ALIGN_RIGHT = 'JustifyRight',
    ALIGN_LEFT = 'JustifyLeft',
    ALIGN_CENTER = 'JustifyCenter',
    ALIGN_FULL = 'JustifyFull',
    INSERT_LINK = 'mceInsertLink',
    UNLINK = 'Unlink',
    FONT_SIZE = 'FontSize',
    UNORDERED_LIST = 'InsertUnorderedList',
    ORDERED_LIST = 'InsertOrderedList',
    INDENT = 'Indent',
    OUTDENT = 'Outdent',
    FONT_NAME = 'fontName',
    APPLY_TEXT_COLOR = 'mceApplyTextcolor',
    REMOVE_TEXT_COLOR = 'mceRemoveTextcolor';

