/* eslint-disable max-len */
import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from "classnames";

import CheckBox from "../../../../../../../view/common/CheckBox/PropertiesCheckBox";
import Msg from '../../../../../../../view/intl/Msg';

import { useIntl } from '../../../../../../../view/intl';

import {
    AI_TEXT_AGREE_TERMS_CHECKED
} from "../../../../epics/AITextEpic/actionTypes";
import { REQUIRED_FIELDS } from '../constants';

import styles from "../styles.css";
import { aiTextSelector } from '../../../../epics/AITextEpic/selectors';
import { OPEN_AI_PRIVACY_LINK, OPEN_AI_TERMS_LINK } from "../../../../../../../constants/app";

export const TermsCheckbox = ({ showTerms = false }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const aiTextState = useSelector(aiTextSelector);

    const ref = useRef<HTMLDivElement>(null);
    const {
            isFirstTimeUser,
            agreeTerms,
            errorFields,
            queryResults,
            errorState,
            showTermsCheckbox
        } = aiTextState,
        agreeTermsError = errorFields.includes(REQUIRED_FIELDS.agreeTerms),
        agreeTermsDisabled = agreeTerms && (queryResults.length > 0 || errorState),
        checkboxProps: any = {};

    useEffect(() => {
        const scrollIntoView = () => {
            const isFirstErrorField = errorFields && errorFields.indexOf(REQUIRED_FIELDS.agreeTerms) === 0;
            return isFirstErrorField && ref?.current?.scrollIntoView({ behavior: 'smooth' });
        };

        scrollIntoView();
    }, [errorFields]);

    if (agreeTermsDisabled) {
        checkboxProps["is-disabled"] = "true";
    }

    const getTermsAndConditionsLabel = () => {
        const terms = (
            <a className={cx(styles.termsLink, agreeTermsError && styles.errorLink)} href={OPEN_AI_TERMS_LINK} target="_blank">
                {intl.msgJoint("msg: common.termsAndConditions {terms and conditions}")}
            </a>
        );
        const privacy = (
            <a className={cx(styles.termsLink, agreeTermsError && styles.errorLink)} href={OPEN_AI_PRIVACY_LINK} target="_blank">
                {intl.msgJoint("msg: common.privacyPolicy {privacy policy}")}
            </a>
        );
        return (
            <Msg k="component.text.createAIText.dialog.textBuilder.terms" params={{ terms, privacy }}>
                {`I understand and agree to the {terms} and {privacy} of OpenAI.`}
            </Msg>
        );
    };
    const getAcceptedTermsAndConditionsLabel = () => {
        const terms = (
            <a className={cx(styles.termsLink, agreeTermsError && styles.errorLink)} href={OPEN_AI_TERMS_LINK} target="_blank">
                {intl.msgJoint("msg: common.termsAndConditions {terms and conditions}")}
            </a>
        );
        const privacy = (
            <a className={cx(styles.termsLink, agreeTermsError && styles.errorLink)} href={OPEN_AI_PRIVACY_LINK} target="_blank">
                {intl.msgJoint("msg: common.privacyPolicy {privacy policy}")}
            </a>
        );
        return (
            <Msg k="component.text.editAIText.dialog.textBuilder.accpetedTerms" params={{ terms, privacy }}>
                {`For details on your data and privacy, check OpenAI’s {terms} and {privacy}.`}
            </Msg>
        );
    };
    const label = getTermsAndConditionsLabel();
    const acceptedTermsLabel = getAcceptedTermsAndConditionsLabel();
    return (
        // eslint-disable-next-line no-nested-ternary
        (isFirstTimeUser && showTermsCheckbox) ?
            <div className={styles.termsContainer} ref={ref} data-testid="ai-terms-checkbox">
                <CheckBox
                    label={label}
                    isHtmlLabel
                    isChecked={agreeTerms}
                    onClick={() => {
                        dispatch({ type: AI_TEXT_AGREE_TERMS_CHECKED });
                    }}
                    className={cx(styles.checkBoxLabel, agreeTermsError && styles.errorLabel)}
                    containerClassName={styles.checkBoxContainer}
                    error={agreeTermsError}
                    disabled={agreeTermsDisabled}
                    checkboxProps={checkboxProps}
                />
            </div> :
            showTerms ?
                <div>{acceptedTermsLabel}</div> :
                null
    );
};
