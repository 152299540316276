import * as React from 'react';
import cx from 'classnames';
import styles from './Dialog.css';

type Props = {
    className?: string,
    children: React.ReactNode;
};

export default ({ className, children }: Props) => (
    <div className={cx(styles.footerBare, className)}>{children}</div>
);
