import React from 'react';
import cx from 'classnames';
import * as styles from './styles.css';
import injectIntl from '../../../../../view/intl/injectIntl';
import PrimaryButton from "../../../../../view/common/Button/PrimaryButton";
import SecondaryButton from "../../../../../view/common/Button/SecondaryButton";
import { LinkToListings } from '../../constants';

type Props = {
    intl: Intl,
    titleMessage: string,
    description: string,
    primaryBtnText: string,
    primaryBtnAction?: (e: React.MouseEvent<any, MouseEvent>) => void,
    secondaryBtnText?: string,
    secondaryBtnAction?: () => void
};

export const ExceptionGoogleReviewsCommonView = injectIntl((props: Props) => {
    const {
        intl,
        titleMessage,
        description,
        primaryBtnText,
        secondaryBtnText,
        primaryBtnAction,
        secondaryBtnAction
    } = props;
    const getLinkToGmbListing = () => LinkToListings + decodeURIComponent(intl.msgJoint("msg: common.GoogleLocaleCode {en}"));

    return (
        <React.Fragment>
            <div className={styles.googleIcon} />
            <h3 className={styles.titleMessage}>
                {intl.msgJoint(titleMessage)}
            </h3>
            <p className={styles.descriptionMessage}>
                {intl.msgJoint(description)}
            </p>
            <div className={cx(styles.footerButtons, { [styles.buttonsContainer]: !!secondaryBtnText })}>
                {primaryBtnAction ?
                    <PrimaryButton onClick={primaryBtnAction} className={styles.button}>
                        {intl.msgJoint(primaryBtnText)}
                    </PrimaryButton> :
                    <a href={getLinkToGmbListing()} className={cx(styles.button, styles.primaryBtn)} target="_blank">
                        <span>{intl.msgJoint(primaryBtnText)}</span>
                    </a>}
                { /* @ts-ignore */ }
                {secondaryBtnText && <SecondaryButton onClick={secondaryBtnAction} className={cx(styles.button, styles.secondaryBtn)}>
                    {intl.msgJoint(secondaryBtnText)}
                </SecondaryButton>}
            </div>
        </React.Fragment>
    );
});
