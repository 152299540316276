import React from 'react';
import cx from 'classnames';
import styles from './Template.css';
import { MOUSE_DOWN_ON_TEMPLATE_LINE } from '../../../../components/oneweb/Template/epics/templateLines/actionTypes';
import {
    TEMPLATE_LEFT_LINE,
    TEMPLATE_RIGHT_LINE
} from '../../../../components/oneweb/Template/epics/templateLines/kinds';

type PropTypes = {
    dispatch: Function,
    isActive: boolean,
    isLeft?: boolean,
    top: number,
    height?: number,
}

const
    onMouseDown = (dispatch, left) => e => {
        e.stopPropagation();
        dispatch({
            type: MOUSE_DOWN_ON_TEMPLATE_LINE,
            payload: {
                x: e.clientX,
                y: e.clientY,
                kind: left ? TEMPLATE_LEFT_LINE : TEMPLATE_RIGHT_LINE
            }
        });
    };

export default ({ dispatch, isLeft = false, isActive, top, height }: PropTypes) => {
    return (
        <div
            className={cx(styles.templateLine, { [styles.active]: isActive })}
            onMouseDown={isActive ? onMouseDown(dispatch, isLeft) : () => {}}
            style={{ [isLeft ? 'left' : 'right']: !isLeft && !isActive ? '10px' : '-11px', top, height }}
        >
            <div className={`${styles.templateLineInner} ${isActive ? '' : styles.dashed}`} />
        </div>
    );
};
