import React from 'react';
import { WidgetWorkspaceView } from '../../view/workspace';
import { PinterestView } from './index';

const PinterestWorkspaceView = props => (
    <WidgetWorkspaceView {...props}>
        <PinterestView {...props} />
    </WidgetWorkspaceView>
);

export { PinterestWorkspaceView };

