import { all, takeLatest, fork, put } from 'redux-saga/effects';
import { uniq } from 'ramda';
import pageDatasetLoadedActionType from "../App/epics/pageDataset/pageDatasetLoadedActionType";
import loadFonts, { loadFontForPreview } from './fontLoader';
import { LOAD_GOOGLE_FONT_FOR_PREVIEW, SAVE_GOOGLE_FONT } from "./actionTypes";
import { valueActionType as siteDataValueActionType } from '../App/epics/siteData/valueActionType';
import pageDataSetVAT from '../App/epics/pageDataset/valueActionType';
import makeResolveWhenFulfilledSaga from "../../epics/makeResolveWhenFulfilledSaga";
import { cloneModel } from "../../../dal/model/utils/index";
import updateSiteDataSaga from "../PagesTree/saga/updateSiteDataSaga";
import { closeDialog } from "../App/actionCreators/index";
import DataSite from "../../../dal/model/DataSite";
import { receiveOnly } from '../../epics/makeCondition';

import TextComponentKind from '../oneweb/Text/kind';
import { TEXT_FONT_FAMILY_CHANGED } from '../oneweb/Text/actionTypes';

import ContactFormKind from '../oneweb/ContactForm/kind';
import { CONTACT_FORM_FONT_CHANGED } from '../oneweb/ContactForm/actionTypes';
import WebShopComponentKind from "../oneweb/WebShop/kind";
import webshopActionTypes from "../oneweb/WebShop/reducer/actionTypes";

import TableComponentKind from "../oneweb/Table/kind";
import { TABLE_TEXT_FONT_FAMILY_CHANGED } from "../oneweb/Table/actionTypes";

import { GOOGLE_PREFIX } from "../presentational/AddGoogleFont/constants";

import GalleryComponentKind from "../oneweb/Gallery/kind";
import { GALLERY_PP_CAPTIONS_TITLE_STYLE_FONT_FAMILY_CHANGE } from "../oneweb/Gallery/actionTypes";
import getPageGoogleFonts from "./getPageGoogleFonts";

import { LOGO_KIND } from "../oneweb/Logo/kind";
import { OPENING_HOURS_KIND } from "../oneweb/OpeningHours/kind";
import { LOGO_PP_TEXT_STYLE_FONT_FAMILY_CHANGED } from "../oneweb/Logo/actionTypes";
import { OPENING_HOURS_FONT_FAMILY_CHANGED } from "../oneweb/OpeningHours/actionTypes";

import { makeActionForwardToSelectedComponent } from '../../redux/forwardTo';
import { TEXT_LIKE_FONT_FAMILY_CHANGED } from "../oneweb/TextLike/actionTypes";
import { TextLike } from "../oneweb/TextLike/constants";

type GoogleFont = { googleFont: string, additionalPayload: any }

const onPageDataLoadSuccess = makeResolveWhenFulfilledSaga(
    receiveOnly(siteDataValueActionType),
    pageDatasetLoadedActionType,
    function* (siteData: DataSite, page) {
        yield* loadFonts({ page, siteData });
    }
);

function* onLoadFontForPreview() {
    // @ts-ignore
    yield takeLatest(LOAD_GOOGLE_FONT_FOR_PREVIEW, function* ({ payload: font }): Generator<any, void, any> {
        yield* loadFontForPreview({ font });
    });
}

const componentKindToActionMap = {
    [TextComponentKind]: TEXT_FONT_FAMILY_CHANGED,
    [TableComponentKind]: TABLE_TEXT_FONT_FAMILY_CHANGED,
    [WebShopComponentKind]: webshopActionTypes.WEBSHOP_FONT_FAMILY_CHANGED,
    [ContactFormKind]: CONTACT_FORM_FONT_CHANGED,
    [GalleryComponentKind]: GALLERY_PP_CAPTIONS_TITLE_STYLE_FONT_FAMILY_CHANGE,
    [LOGO_KIND]: LOGO_PP_TEXT_STYLE_FONT_FAMILY_CHANGED,
    [TextLike]: TEXT_LIKE_FONT_FAMILY_CHANGED,
    [OPENING_HOURS_KIND]: OPENING_HOURS_FONT_FAMILY_CHANGED
};

const onSaveGoogleFont = makeResolveWhenFulfilledSaga(
    receiveOnly(siteDataValueActionType),
    receiveOnly(pageDataSetVAT),
    SAVE_GOOGLE_FONT,
    function* (siteData: DataSite, pageDataSet: Object, { googleFont, additionalPayload }: GoogleFont) {
        if (siteData.fonts.indexOf(googleFont) === -1) {
            const
                currentFonts = getPageGoogleFonts(pageDataSet),
                fonts = uniq([...siteData.fonts, ...currentFonts, googleFont]),
                newSiteData = cloneModel(siteData, false /* createNewId */, { fonts });
                // @ts-ignore
            yield* updateSiteDataSaga({ site: newSiteData });
        }

        if (additionalPayload &&
            additionalPayload.source &&
            (
                Object.keys(componentKindToActionMap).indexOf(additionalPayload.source) > -1
                || additionalPayload.source.indexOf('_ACTION') > -1
            )
        ) {
            yield put(makeActionForwardToSelectedComponent(({
                type: additionalPayload.actionToDispatch
                    || componentKindToActionMap[additionalPayload.source]
                    || additionalPayload.source,
                payload: googleFont.replace(GOOGLE_PREFIX, '')
            } as AnyAction)));
        }

        yield put(closeDialog());
    }
);

export default function* root(): any {
    yield all([
        fork(onPageDataLoadSuccess),
        fork(onLoadFontForPreview),
        fork(onSaveGoogleFont)
    ]);
}
