import type { ApiAction } from "../../../../middleware/api/flowTypes";
import CALL_API from "../../../../middleware/api/CALL_API";
import {
    FC_CREATE_WEBSPACE_FOLDER_REQUEST,
    FC_CREATE_WEBSPACE_FOLDER_SUCCESS,
    FC_CREATE_WEBSPACE_FOLDER_FAILURE
} from "../actionTypes";

export default function (folderPath: string): ApiAction {
    return {
        [CALL_API]: {
            types: [
                FC_CREATE_WEBSPACE_FOLDER_REQUEST,
                FC_CREATE_WEBSPACE_FOLDER_SUCCESS,
                FC_CREATE_WEBSPACE_FOLDER_FAILURE
            ],
            endpoint: 'createWebSpaceFolder',
            endpointParams: folderPath
        }
    };
}
