import ScaleStrategy from '../scaleStrategy';
import type { ImageComponent } from '../flowTypes';
import type { AdjustAfterPushdownHook } from "../../../Workspace/epics/componentsEval/flowTypes";
import { calculateImageScale } from "../utils";

const
    hook: AdjustAfterPushdownHook<ImageComponent> = ({ component }) => {
        if (component.scaleStrategy === ScaleStrategy.CROP) {
            const newScale = calculateImageScale(component);
            if (newScale !== component.scale) {
                return {
                    ...component,
                    scale: newScale
                };
            }
        }
        return component;
    };

export default {
    hook
};
