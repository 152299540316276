import type { SavePreferencesPartial } from '../types';
import * as object from '../../../../utils/object';

export const SavePreferencesBlacklist = ['id', 'type', 'owner', '_rev'];

export const sanitizeSavePreferences = (preferences: Object): SavePreferencesPartial =>
    object.filter(
        preferences,
        (_, k) => SavePreferencesBlacklist.indexOf(k) === -1,
    );
