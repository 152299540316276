import React from 'react';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import * as pagesIds from "./ids";
import pagesTitles from "./titles";
import ColorProperty from '../../../../presentational/ColorProperty/index';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';
import Slider from '../../../../../view/common/Slider/PPSlider';
import * as Actions from '../../actionTypes';
import { MinFontSize, MaxFontSize } from "../../constants";
import { getFontSettings } from "../../fontsUtility";
import addGoogleFontView from '../../../../PropertiesPanel/view/AddGoogleFont/index';
import FontFamilySelector from "../../../../presentational/FontFamily/index";
import type { Props } from '../flowTypes';
import injectIntl from '../../../../../view/intl/injectIntl';
import { LINE_HEIGHT_PERCENTAGE } from "../../view/constants";
import ContactFormKind from '../../kind';
import { getThemeRulesForBackground } from '../../../../ThemeGlobalData/themeRules';
import { findSuitableTextColorName } from '../../../../ThemeGlobalData/utils/commonUtils';
import { DEFAULT_COLOR_PICKER, THEME_COLOR_PICKER } from '../../../../ColorPicker/constants';
import { getThemeColorsFromStylesheet } from "../../../../Workspace/epics/stylesheets/selectors";

const
    id = pagesIds.STYLE,
    title = pagesTitles[id],
    view = injectIntl((props: Props) => {
        const
            {
                dispatch,
                selectedComponent,
                globalStyles,
                siteFonts,
                navigateToPage,
                themeSettingsData
            } = props,
            { autoColorMode } = themeSettingsData,
            themeColorsData = getThemeColorsFromStylesheet(globalStyles),
            fontSettings = getFontSettings({
                component: selectedComponent,
                globalStyles,
                lineHeightPercentage: LINE_HEIGHT_PERCENTAGE,
                autoColorMode
            }),
            { font, fontSize, fontColor } = fontSettings;

        return (
            <Page>
                <VerticalSpacer />
                <PropertyContainer label="msg: common.font {Font}">
                    <FontFamilySelector
                        selected={font}
                        onChangeAction={Actions.CONTACT_FORM_FONT_CHANGED}
                        siteFonts={siteFonts}
                        onAddFontClicked={() => navigateToPage(addGoogleFontView.id, { source: ContactFormKind })}
                        isPropertiesPanel
                    />
                </PropertyContainer>
                <VerticalSpacer />
                <PropertyContainer label="msg: common.fontSize {Font size}" valueLabel={`${fontSize} px`}>
                    <Slider
                        defaultValue={fontSize}
                        value={fontSize}
                        min={MinFontSize}
                        max={MaxFontSize}
                        onChange={newSize => dispatch({
                            type: Actions.CONTACT_FORM_FONT_SIZE_CHANGED,
                            payload: newSize,
                            amendToSelf: true
                        })}
                    />
                </PropertyContainer>
                <VerticalSpacer />
                {
                    autoColorMode
                        ? <ColorProperty
                            colorPickerKind={THEME_COLOR_PICKER}
                            themeColor={props.selectedComponent.styleForm.themeOverrideColor}
                            defaultThemeColor={findSuitableTextColorName(
                                getThemeRulesForBackground(
                                    props.selectedComponentProps.selectedParentTheme,
                                    themeColorsData
                                ).background, themeColorsData
                            )}
                            themeColorsData={themeColorsData}
                            onChangeAction={Actions.CONTACT_FORM_FONT_COLOR_CHANGED_AUTO_COLOR}
                            label="msg: component.contactForm.appearance.fontColor {Text colour}"
                            dispatch={dispatch}
                        />
                        : <ColorProperty
                            colorPickerKind={DEFAULT_COLOR_PICKER}
                            label="msg: component.contactForm.appearance.fontColor {Text colour}"
                            dispatch={dispatch}
                            onChangeAction={Actions.CONTACT_FORM_FONT_COLOR_CHANGED}
                            color={fontColor}
                        />
                }
            </Page>
        );
    });

export { id, title, view };

