import React from 'react';
import cx from 'classnames';
import { Intl, injectIntl } from '../../view/intl/index';
import { Flex } from '../../view/reflexbox/index';
import { closeBanner } from './utils';
import { type BannerProps } from './types';
import styles from './Banner.css';

type BannerUiProps = BannerProps & {
    intl: Intl
    dispatch: Dispatch;
}

const Banner = injectIntl((props: BannerUiProps) => {
    const { intl, dispatch, texts, iconCss, warning = false } = props;
    return (
        <Flex justify="space-between" className={cx(styles.banner, { [styles.warning]: warning })}>
            <Flex align="center" className={styles.textContainer}>
                <Flex align="center" className={styles.iconContainer}>
                    <div className={iconCss} />
                </Flex>
                <Flex column>
                    {texts.map(text => <Flex align="center" className={styles.text}>{intl.msgJoint(text)}</Flex>)}
                </Flex>
            </Flex>
            <Flex align="center" justify="center" className={styles.close}>
                <div className={styles.closeButton} onClick={() => dispatch(closeBanner())} />
            </Flex>
        </Flex>
    );
});

export default Banner;
