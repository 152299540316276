import React from 'react';
import { ComponentMaskBackground } from "../../../presentational/ComponentMask/constants";
import ComponentMask from "../../../presentational/ComponentMask/index";
import BaseComponent from "../../../../view/oneweb/BaseComponent";
import ShareButtonsLocaleWrapper from './ShareButtonsLocaleWrapper';
import type { ShareButtonsCalcRenderPropsResult } from '../flowTypes';

export default (props: ShareButtonsCalcRenderPropsResult) => (
    <BaseComponent {...props}>
        <ComponentMask forceShowMask={false} maskStyle={ComponentMaskBackground.LIGHT}>
            <ShareButtonsLocaleWrapper {...props} />
        </ComponentMask>
    </BaseComponent>
);
