import * as R from "ramda";
import * as path from '../path';
import configMappers from '../configMappers';
import fontConfigMappers from '../block/font';
import * as colorMapper from '../color';
import * as shadowMapper from '../shadow';
import * as letterSpacing from '../letterSpacing';
import { SpecConfig } from "../makeSpec/flowTypes";

const
    font = configMappers(fontConfigMappers),
    baseCssConfig: SpecConfig = {
        fontFamily: [path.font, font.family.toCss],
        fontStyle: [path.italic, font.italic.toCss],
        fontSize: [path.size, font.size.toCss],
        fontWeight: [path.bold, font.weight.toCss],
        textDecoration: [path.underline, font.underline.toCss],
        color: [path.color, colorMapper.toCss],
        letterSpacing: [path.letterSpacing, letterSpacing.toCss],
        lineHeight: [path.lineHeight, R.identity],
    };

export const cssConfig: SpecConfig = {
    ...baseCssConfig,
    textShadow: [path.shadow, shadowMapper.toCss],
};

export const cssConfigInTheme = {
    ...baseCssConfig
};
