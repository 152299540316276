import { makeItemMsgT } from "../types";

export const isWhatsNewItem = (itemReleaseDate: string, latestTimestamp: number | null | undefined): boolean => !!(
    latestTimestamp && new Date(itemReleaseDate).getTime() > latestTimestamp
);

const whatsNewMsgKey = (id, prop) => `whatsNew.${id}.${prop}`;

// TODO: WGTGEN-26035: Implement re-direct to editor / dashboard based on case by case need.
export const makeItemMsg = ({
    item,
    prop,
    dispatch,
    linkClassName,
    intl,
    subscriptionType,
    disabledLinkClassName
}: makeItemMsgT): string => {
    const
        msg = `msg: ${whatsNewMsgKey(item.id, prop)} {${item[prop]}}`,
        params = item.makeDescriptionParams ? item.makeDescriptionParams({
            dispatch,
            linkClassName,
            subscriptionType,
            disabledLinkClassName
        }) : {};

    return intl.msgJoint([msg, params]);
};
