import makePages from "../../../PropertiesPanel/view/makePages";
import * as main from "./pages/main";
import * as style from "./pages/style";
import * as promo from "./pages/promotion";
import * as Pages from "./pages/ids";
import * as button from "./pages/button";
import addGoogleFont from "../../../PropertiesPanel/view/AddGoogleFont/index";

export default {
    pages: makePages(main, style, button, promo, addGoogleFont),
    defaultPageId: Pages.MAIN
};
