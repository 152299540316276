import React from 'react';

import StandardDialog from '../../../../../../view/common/dialogs/StandardDialog';
import { SectionLinkForm } from '../../../../sectionLinkForm/view';
import type { SectionLinkInfoDialogProps } from "../flowTypes";
import { SECTION_LINK_INFO_SAVE, PAGE_TREE_PAGE_DELETE } from "../../../actionTypes";
import InfoTip from "../../../../../../view/common/InfoTip";
import { sectionLinkDialogInfoTip } from "../../../constants";
import { StripTypes } from '../../../../../../view/common/dialogs/baseDialog';
import styles from './SectionLinkInfoDialog.css';
import Msg from "../../../../../../view/intl/Msg";
import { QuestionTip } from "../../../../../presentational/Tooltip/questionTip/QuestionTip";
import { TooltipPosition } from "../../../../../presentational/Tooltip/constants";
import LoadingIndicator from '../../../../../../view/common/LoadingIndicator';

export default (props: SectionLinkInfoDialogProps) => {
    const { form, result, isFormValid, dispatch, sectionLinkId, sectionLink } = props,
        footerContent = (
            <div className={styles.footerWrapper}>
                <div className={styles.deleteLink}>
                    <a onClick={() => dispatch({ type: PAGE_TREE_PAGE_DELETE, payload: sectionLink })}>
                        <Msg k="sectionLinkInfoDialog.deleteLink" className={styles.deleteLink}>
                            Delete section link
                        </Msg>
                    </a>
                    <QuestionTip
                        position={TooltipPosition.TOP}
                        distance={3}
                        theme={{ container: styles.helpTipContainer }}
                    >
                        <Msg k="sectionLinkInfoDialog.deleteLink.tip">
                            This will delete the section link in your site menu. The section itself will not be deleted.
                        </Msg>
                    </QuestionTip>
                </div>
            </div>
        );

    return (
        <StandardDialog
            title="msg: sectionLinkInfoDialog.updateLink {Update section link in site menu}"
            mctaText="msg: common.save {Save}"
            mctaHandler={() => dispatch({ type: SECTION_LINK_INFO_SAVE, payload: { sectionLinkId, data: result } })}
            disabled={!isFormValid}
            stripType={StripTypes.NONE}
            titleClassName={styles.heading}
            bodyClassName={styles.dialogBody}
            titleBoxClassName={styles.titleBox}
            footerContents={footerContent}
            footerClassName={styles.footer}
            closeBtnClassName={styles.dialogCloseBtn}
        >
            <SectionLinkForm {...form} dispatch={dispatch} isSettings />
            <InfoTip
                isTipLabelBlock
                className={styles.tipContainer}
                textKey={sectionLinkDialogInfoTip}
            />
            {form.isLoading && <LoadingIndicator absoluteCenter />}
        </StandardDialog>
    );
};
