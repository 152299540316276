/* eslint-disable max-len */

import * as R from 'ramda';
import { SOCIAL_KIND } from './kind';
import view from './view/index';
import workspace from './view/workspace';
import propertiesPanel from './propertiesPanel/index';
import componentMainActions from './componentMainActions/index';
import {
    getComponentInitialStateCommonPart,
} from "../../../redux/makeReducer/index";
import type { SocialGlobalData, SocialComponent } from './flowTypes';
import {
    SOCIAL_COLOR_TYPE_CHANGED,
    SOCIAL_COLOR_TYPE_CHANGED_AUTO_COLOR,
    SOCIAL_STYLE_TYPE_CHANGED,
    SOCIAL_TOGGLE_PLATFORM_VISIBILITY,
    SOCIAL_SET_COMPONENT_HORIZONTAL_SPACING,
    SOCIAL_MCTA_CHANGE_SIZE,
    SOCIAL_MCTA_SIZE_SLIDER_VALUE_CHANGED,
    SOCIAL_MCTA_HORIZONTAL_SPACING_SLIDER_VALUE_CHANGED,
    SOCIAL_MOBILE_MCTA_CHANGE_SIZE,
    SOCIAL_MOBILE_MCTA_HORIZONTAL_ALIGNMENT_CHANGED,
    SOCIAL_SET_VISIBLE_PLATFORMS_FOR_COMPONENT,
    SOCIAL_MOBILE_MCTA_SIZE_SLIDER_VALUE_CHANGED,
    SOCIAL_GLOBAL_LINKS_SORTED,
    SOCIAL_OUTDATED_LINKS_LOCAL_STATE_FIX,
    SOCIAL_FIX_COMPONENT_LINKS_ON_PASTE,
} from './actionTypes';
import { MHFMinSpaceBetweenSocialLinkIcons, SocialColorSourceType, SocialStyleType, THEME_DEFAULT, MCTA_RESIZE_OPTIONS } from './constants';
import { computeSizeAndLinks, socialCalcRenderProps } from './calcRenderProps';
import previewConfig from './previewConfig';
import { socialGlobalDataEpic } from './epics/socialGlobalDataEpic/socialGlobalDataEpic';
import { socialServiceEpic } from './epics/socialServiceEpic/socialServiceEpic';
import { ALIGNMENT_TYPES } from '../../../constants';
import { ComponentNames } from '../constants';
import makeStateSelectorReducer from "../../../epics/makeStateSelectorReducer";
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";
import stylesheetsEpic from "../../Workspace/epics/stylesheets/index";
import { colorThemeSiteSettingsEpic } from "../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import { isModernLayoutActivatedEpic } from "../../Workspace/epics/isModernLayoutActivatedEpic/index";
import type { MHFCmpsData } from '../../Workspace/epics/componentsEval/flowTypes';

const componentInitialStateCommonPart = getComponentInitialStateCommonPart(SOCIAL_KIND, 300, 60);

export const socialComponentInitialState: SocialComponent = {
    ...componentInitialStateCommonPart,
    colorType: SocialColorSourceType.MULTI_COLOR,
    themeColorType: THEME_DEFAULT,
    size: 32,
    spacing: 20,
    mobileSize: 50,
    mobileHorizontalAlignment: ALIGNMENT_TYPES.ALIGN_CENTER,
    styleType: SocialStyleType.REGULAR,
    height: 40,
    links: [],
};

type SocialComponentDependsOn = { socialData: SocialGlobalData }

export const socialComponentComputeWidthOnDrop = ({
    component,
    socialData
}: { component: SocialComponent, socialData: SocialGlobalData }) => {
    const { size } = computeSizeAndLinks({
        component: { ...component, width: 10000 },
        socialData
    });
    return ((socialData.links.length || 5) * (size + component.spacing)) - component.spacing;
};

const EmptyComponentMinWidth = 180;
const calcMinHeight = component => component.size;
const makePositionAndDimensionsAdjuster = (
    prevState,
    componentIsOnTheRightSideOfTemplate = false,
    adjustLeft = false
) => state => {
    const visibleLinksCount = state.links.filter(({ hidden }) => !hidden).length;
    const width = visibleLinksCount === 0 ?
        EmptyComponentMinWidth :
        ((state.size + state.spacing) * visibleLinksCount) - state.spacing;
    const diff = state.width - width;

    let left = state.left;
    if (adjustLeft && componentIsOnTheRightSideOfTemplate) {
        left += diff;
    }

    const possiblyNextHeight = calcMinHeight(state);
    const prevHeightIsMinumumHeightOrNextHeightIsMoreThanPrevHeight =
        prevState.height === calcMinHeight(prevState)
        || possiblyNextHeight > prevState.height;
    return {
        ...state,
        left,
        width,
        height: prevHeightIsMinumumHeightOrNextHeightIsMoreThanPrevHeight ?
            possiblyNextHeight
            : state.height
    };
};

export const socialComponentConfig = {
    view,
    workspace,
    kind: SOCIAL_KIND,
    label: ComponentNames[SOCIAL_KIND],
    shortcutIconName: 'social',
    propertiesPanel,
    reducer: (state: SocialComponent = socialComponentInitialState, action: AnyAction) => {
        switch (action.type) {
            case SOCIAL_MCTA_SIZE_SLIDER_VALUE_CHANGED: {
                if (state.links.length === 0) return state;
                const adjuster = makePositionAndDimensionsAdjuster(state);

                return adjuster({ ...state, size: action.payload });
            }
            case SOCIAL_MCTA_HORIZONTAL_SPACING_SLIDER_VALUE_CHANGED: {
                if (state.links.length === 0) return state;
                const adjuster = makePositionAndDimensionsAdjuster(state);

                return adjuster({ ...state, spacing: action.payload });
            }
            case SOCIAL_SET_COMPONENT_HORIZONTAL_SPACING: return { ...state, spacing: action.payload };
            case SOCIAL_COLOR_TYPE_CHANGED: return { ...state, colorType: action.payload };
            case SOCIAL_COLOR_TYPE_CHANGED_AUTO_COLOR: return { ...state, themeColorType: action.payload };
            case SOCIAL_STYLE_TYPE_CHANGED: return { ...state, styleType: action.payload };
            case SOCIAL_TOGGLE_PLATFORM_VISIBILITY: {
                const { linkId, linkKind, componentIsOnTheRightSideOfTemplate } = action.payload;

                const adjuster = makePositionAndDimensionsAdjuster(state, componentIsOnTheRightSideOfTemplate, true);

                return adjuster({
                    ...state,
                    links: state.links.find(({ id }) => id === linkId) ?
                        // $FlowFixMe
                        state.links.map(link => (link.id === linkId ? { ...link, hidden: !link.hidden } : link)) :
                        [...state.links, { id: linkId, kind: linkKind, hidden: false }],
                });
            }
            case SOCIAL_MOBILE_MCTA_CHANGE_SIZE: {
                const size = action.payload;
                return {
                    ...state,
                    mobileSize: MCTA_RESIZE_OPTIONS[size].mobileSize,
                    modernLayoutOptions: { ...state.modernLayoutOptions, mobileSize: size }
                };
            }
            case SOCIAL_MOBILE_MCTA_SIZE_SLIDER_VALUE_CHANGED: return { ...state, mobileSize: action.payload };
            case SOCIAL_MOBILE_MCTA_HORIZONTAL_ALIGNMENT_CHANGED: return { ...state, mobileHorizontalAlignment: action.payload };
            case SOCIAL_SET_VISIBLE_PLATFORMS_FOR_COMPONENT: return { ...state, ...action.payload };
            case SOCIAL_GLOBAL_LINKS_SORTED: {
                const sortedGlobalLinks = action.payload;
                return {
                    ...state,
                    links: sortedGlobalLinks.map(globalLink => {
                        const exisitingLocalLink = state.links.find(link => link.id === globalLink.id);
                        if (exisitingLocalLink) {
                            return exisitingLocalLink;
                        }
                        const { kind, id } = globalLink;
                        return { kind, id, hidden: true };
                    }),
                };
            }
            case SOCIAL_OUTDATED_LINKS_LOCAL_STATE_FIX: {
                let links = state.links.filter(link => !action.payload.linkIdsToCleanup.includes(link.id));

                links = [...links, ...action.payload.missingLinks.map(({ id, kind }) => ({ id, kind, hidden: true }))];
                return {
                    ...state,
                    links
                };
            }
            case SOCIAL_FIX_COMPONENT_LINKS_ON_PASTE: {
                return { ...state, links: action.payload };
            }
            case SOCIAL_MCTA_CHANGE_SIZE: {
                const size = action.payload;
                if (state.links.length === 0) return state;
                const adjuster = makePositionAndDimensionsAdjuster(state);
                return adjuster({
                    ...state,
                    size: MCTA_RESIZE_OPTIONS[size].defaultSize,
                    modernLayoutOptions: { ...state.modernLayoutOptions, size }
                });
            }
            default: return state;
        }
    },
    calcRenderProps: socialCalcRenderProps,
    dependsOn: {
        socialData: socialGlobalDataEpic.reducer,
        themeColorsData: makeStateSelectorReducer(
            stylesheetsEpic.reducer,
            stylesheetsEpic.valueActionType,
            getThemeColorsFromStylesheet
        ),
        themeSettingsData: colorThemeSiteSettingsEpic.reducer,
        isModernLayoutActivated: isModernLayoutActivatedEpic.reducer,
    },
    controlsDependsOn: {
        service: socialServiceEpic.reducer,
    },
    componentMainActions,
    adjustComponentOnAdd: (
        component: SocialComponent,
        { socialData }: SocialComponentDependsOn
    ): SocialComponent => {
        return ({
            ...component,
            width: socialComponentComputeWidthOnDrop({ component, socialData }),
            links: socialData.links.map(({ id, kind }) => ({ id, kind, hidden: false })),
            height: calcMinHeight(component) + 10
        });
    },
    mobileEditorConfig: previewConfig,
    adjustmentHookConfig: {
        hook: ({
            component,
            componentExtension = {},
            mhfCmpsData
        }: {
            component: SocialComponent,
            componentExtension: { minDimensions?: number },
            mhfCmpsData: MHFCmpsData
        }) => {
            let isMHFComponent = mhfCmpsData[component.id],
                updatedComponent = component;

            const icons = component.links.filter(({ hidden }) => !hidden),
                minSpaceBetweenIcons = icons.length && isMHFComponent ? MHFMinSpaceBetweenSocialLinkIcons : updatedComponent.spacing,
                totalIconsSize = component.size * icons.length,
                totalSpaceBetweenIcons = icons.length ? ((icons.length - 1) * minSpaceBetweenIcons) : 0,
                minWidth = (totalIconsSize + totalSpaceBetweenIcons) || EmptyComponentMinWidth,
                minDimensions = {
                    width: minWidth,
                    height: calcMinHeight(component)
                };
            if (updatedComponent.width !== minWidth || updatedComponent.spacing !== minSpaceBetweenIcons) {
                updatedComponent = { ...updatedComponent, width: minWidth, spacing: minSpaceBetweenIcons };
            }
            return [
                updatedComponent,
                R.equals(minDimensions, componentExtension.minDimensions) ? componentExtension : { ...componentExtension, minDimensions }
            ];
        },
        shouldCallHook: () => true
    }
};
