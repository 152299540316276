import type { ImageComponent } from './flowTypes';

const ScaleStrategies = {
    LOGO: 'logo',
    FIT: 'fit',
    STRETCH: 'stretch',
    CROP: 'crop'
};

export default ScaleStrategies;
export const isFitMode = ({ scaleStrategy }: ImageComponent): boolean => scaleStrategy === ScaleStrategies.FIT;
export const ScaleStrategiesIntl = {
    fit: 'msg: image.scale.fit {Fit}',
    stretch: 'msg: image.scale.stretch {Stretch}',
    crop: 'msg: image.scale.crop {Crop}'
};

