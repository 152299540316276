import React, { useState } from "react";
import { useSelector } from "react-redux";

import { useIntl } from "../../../../view/intl";

import { subscriptionDataSelector } from "../../../App/epics/subscriptionData/selectors";

import { PrimaryButton } from "../../../../view/common/Button";
import { SUBSCRIPTION_LINK } from "../../../../constants/app";

import styles from "./Preview.css";

import type { SubscriptionData } from "../../../../redux/modules/flowTypes";
import { isPremiumTemplateBasedOnSubscription } from "../utils";

type Props = {
    template: any
}

export const PremiumBanner = ({ template }: Props) => {
    const intl = useIntl();

    const subscriptionData: SubscriptionData = useSelector(subscriptionDataSelector);
    const subscriptionType = subscriptionData.subscriptionType;

    const isPremium = isPremiumTemplateBasedOnSubscription(template, subscriptionType);

    const [showBanner, setShowBanner] = useState(isPremium);

    if (showBanner) {
        return (
            <div className={styles.premiumBannerContainer} data-testid="preview-premium-banner">
                <div className={styles.premiumBannerContent}>
                    <div className={styles.iconContainer} data-testid="preview-premium-banner-icon">
                        <div className={styles.premiumIcon} />
                    </div>
                    <div className={styles.content}>
                        {
                            // eslint-disable-next-line max-len
                            intl.msgJoint("msg: templateSelector.upgrade.premiumTemplate {Your current plan allows a single-page website. You can begin with a multiple-page template now and upgrade to a larger plan when you are ready to publish.}")
                        }
                    </div>
                </div>
                <div className={styles.premiumBannerActions}>
                    <PrimaryButton
                        onClick={() => {
                            window.open(SUBSCRIPTION_LINK, "_blank");
                        }}
                        className={styles.premiumViewBtn}
                    >
                        {intl.msgJoint('msg: common.upgradeNow {Upgrade now}')}
                    </PrimaryButton>
                    <div className={styles.iconContainer} data-testid="preview-premium-banner-close-icon">
                        <div className={styles.closeIcon} onClick={() => setShowBanner(false)} />
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};
