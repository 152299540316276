import React from 'react';
import { WidgetWorkspaceView } from '../../view/workspace';
import { BingMapsView } from './index';

const BingMapsWorkspaceView = props => (
    <WidgetWorkspaceView {...props}>
        <BingMapsView {...props} />
    </WidgetWorkspaceView>
);

export { BingMapsWorkspaceView };

