/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import * as styles from './styles.css';
import { Msg } from "../../../../../view/intl";
import PrimaryButton from "../../../../../view/common/Button/PrimaryButton";
import SecondaryButton from "../../../../../view/common/Button/SecondaryButton";
import { Dialog, DialogBody } from '../../../../../view/common/dialogs/baseDialog/index';
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import { StripTypes } from "../../../../../view/common/dialogs/baseDialog/Dialog";
import { ConnectToGoogleDialogId } from '../dialogIds';
import { makeEpicStateSelector } from '../../../../../epics/makeEpic';
import GOOGLE_REVIEWS_EPIC from '../../epic/valueActionType';
import { LoadingIndicator } from '../../../../../view/common/LoadingIndicator';
import { ReviewsApiStatus } from '../../constants';
import {
    CONNECT_YOUR_LISTING,
    CREATE_YOUR_LISTING_BTN_CLICKED_ACTION
} from '../../../../SiteSettings/BusinessListing/GoogleMyBusiness/actionTypes';
import {
    BUSINESS_LISTING_SITE_SETTINGS_SERVICE_EPIC_VALUE
} from '../../../../SiteSettings/BusinessListing/GoogleMyBusiness/businessListingSiteSettingsServiceVAT';
import { CANCEL_REVIEW_WIDGET_CONFIG } from '../../epic/actionTypes';

const DialogWidth = parseInt(styles.DialogWidth, 10),
    DialogHeight = parseInt(styles.DialogHeight, 10);

const LoadingView = () => {
    return (<div className={styles.loadingContainer}>
        <LoadingIndicator />
        <Msg k="component.googleReviews.loadingListing">
            Checking for Google business listing
        </Msg>
    </div>);
};

const ConnectToGoogleDialog = ({ dispatch, googleReviewEpicState, isWaitingForApi }) => {
    const { reviewsApiStatus } = googleReviewEpicState;
    const closeDialog = () => { dispatch({ type: CANCEL_REVIEW_WIDGET_CONFIG }); };
    const isApiLoading = reviewsApiStatus === ReviewsApiStatus.InProgress || isWaitingForApi;
    return (
        <Dialog
            showCloseBtn={!isApiLoading}
            closeBtnClassName={styles.closeButtonStyles}
            onClose={closeDialog}
            stripType={StripTypes.NONE}
        >
            <DialogBody className={styles.dialogBody}>
                {isApiLoading ?
                    <LoadingView /> :
                    <div className={styles.container}>
                        <div className={styles.googleLogo}>
                            <div className={styles.googleLogo} />
                        </div>
                        <div className={styles.title}>
                            <Msg k="component.googleReviews.connectToGoogleTitle">
                                Connect or create a listing
                            </Msg>
                        </div>
                        <div className={styles.subTitle}>
                            <Msg k="component.googleReviews.connectToGoogleSubTitle">
                                Connect your Google listing to fetch your reviews.
                            </Msg>
                        </div>
                        <div className={styles.description}>
                            <Msg k="component.googleReviews.connectToGoogleDescription">
                                Don’t have a listing yet? Create one now and verify it with Google. Once you’ve received your first reviews, you can come back to add them to your website.
                            </Msg>
                        </div>
                        <div className={styles.footerButtons}>
                            <SecondaryButton
                                onClick={() => { dispatch({ type: CREATE_YOUR_LISTING_BTN_CLICKED_ACTION }); }}
                            >
                                <Msg k="gmb.createAListing">
                                    Create a listing
                                </Msg>
                            </SecondaryButton>
                            <PrimaryButton
                                onClick={() => { dispatch({ type: CONNECT_YOUR_LISTING }); }}
                            >
                                <Msg k="gmb.connectAListing">
                                    Connect a listing
                                </Msg>
                            </PrimaryButton>
                        </div>
                    </div>}
            </DialogBody>
        </Dialog>
    );
};

const openConnectToGoogleDialogAC = {
    type: "OPEN_DIALOG",
    payload: { dialogId: ConnectToGoogleDialogId }
};

const mapStateToProps = (appState) => {
    const googleReviewEpicState = makeEpicStateSelector(GOOGLE_REVIEWS_EPIC)(appState);
    const { isWaitingForApi } = makeEpicStateSelector(BUSINESS_LISTING_SITE_SETTINGS_SERVICE_EPIC_VALUE)(appState);
    return {
        googleReviewEpicState,
        isWaitingForApi
    };
};

export default getCenteredDialogConfig(DialogWidth, DialogHeight,
    connect(mapStateToProps)(ConnectToGoogleDialog));

export {
    openConnectToGoogleDialogAC
};
