// import { UPGRADE } from "../../App/actionTypes";
import { FacebookChatAlreadyExist, FacebookChatAlreadyExistContent } from "./constants/index";

export const
    FacebookChatNotAllowedInNormalWSB = 'FacebookChatNotAllowedInNormalWSB';

export const
    ErrorMsgsOnAdd = {
        [FacebookChatAlreadyExist]: {
            ...FacebookChatAlreadyExistContent,
            titleForDisabledShortCut: FacebookChatAlreadyExistContent.title
        }
    };
