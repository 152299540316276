export const stripsConfig = {
        height: 63,
        style: {
            border: { style: null, corners: [0, 0, 0, 0] },
            background: {
                colorData: {
                    color: null,
                    gradient: null
                },
                assetData: null
            }
        }
    },

    WHITE_STRIP = 'whiteStrip',
    DARK_STRIP = 'darkStrip',
    GRADIENT_STRIP = 'gradientStrip',
    IMAGE_STRIP = 'imageStrip',

    stripsStylesMap = {
        [WHITE_STRIP]: { style: { background: { colorData: { color: ["HSL", 0.5645, 0, 1, 1] } } } },
        [DARK_STRIP]: { style: { background: { colorData: { color: ["HSL", 0.5645, 0, 0.2, 1] } } } },
        [GRADIENT_STRIP]: {
            style: {
                background: {
                    colorData: {
                        color: ["HSL", 0.4965, 0.8563, 0.3275, 1],
                        gradient: {
                            color: ["HSL", 0.5327, 0.6986, 0.1431, 1],
                            fadePoint: 50,
                            degree: 135
                        }
                    }
                }
            }
        },
        [IMAGE_STRIP]: {
            style: {
                background: {
                    assetData: {
                        asset: {
                            contentType: 'image/jpeg',
                            width: 3500,
                            height: 2336,
                            animated: false,
                            recommendedFormat: null,
                            url: 'repository:/stripBgImage.png'
                        },
                        repeat: [false, false],
                        position: ["50%", "50%"],
                        size: 'cover',
                        fixed: false,
                        opacity: 1
                    }
                }
            }
        }
    },
    stripsLabelMap = {
        [WHITE_STRIP]: 'msg: leftPanel.containers.strip.whiteStrip {White strip}',
        [DARK_STRIP]: 'msg: leftPanel.containers.strip.darkStrip {Dark strip}',
        [GRADIENT_STRIP]: 'msg: leftPanel.containers.strip.gradientStrip {Gradient strip}',
        [IMAGE_STRIP]: 'msg: leftPanel.containers.strip.imageStrip {Strip with image}',
    },
    stripTemplates = [WHITE_STRIP, DARK_STRIP, GRADIENT_STRIP, IMAGE_STRIP];
