import type { Navigation, PageConfig } from "../../flowTypes";
import registry from "../../registryWithTemplate";
import { DIRECTIONFORWARD, DIRECTIONBACKWARD } from "../constants";
import TemplateKind from '../../../oneweb/Template/kind';
import { InstancePropertiesPanelConfig } from "../../instance/InstancePropertiesPanelRegistry";
import { modernLayoutComponentKindsExceptionPP } from "../../../ModernLayouts/constants";

export const
    shouldShowSettings = ({ activePageIndex }: Navigation, kind: string): boolean =>
        (activePageIndex === 0 && kind !== TemplateKind),
    shouldShowBackButton = (
        { activePageIndex }: Navigation,
        isModernLayoutActive: boolean,
        selectedCmpPosition: Record<string, any>,
        { kind }: Record<string, any>
    ): boolean => {
        const isInsideMHF = isModernLayoutActive && selectedCmpPosition.isInsideHeaderOrFooter;
        return (isInsideMHF && modernLayoutComponentKindsExceptionPP[kind]) ? false : activePageIndex !== 0;
    },
    getCurrentPageId = ({ sequence, activePageIndex }: Navigation): string => sequence[activePageIndex],
    getCurrentPage = (navigation: Navigation, kind: string): PageConfig => {
        const currentPageId = getCurrentPageId(navigation),
            // @ts-ignore
            { title } = registry[kind].propertiesPanel.pages[currentPageId];
        return { id: currentPageId, title, height: 0 };
    },
    getInstanceCurrentPage = (instanceId: string, navigation: Navigation): PageConfig => {
        const
            config = new InstancePropertiesPanelConfig(instanceId),
            currentPageId = getCurrentPageId(navigation),
            { title } = config.getPage(currentPageId);

        return { id: currentPageId, title, height: 0 };
    },
    initiateNavigation = (kind: string) => ({
        sequence: [registry[kind].propertiesPanel.defaultPageId],
        activePageIndex: 0,
        direction: null
    }),
    updateSequenceOrder = (oldNavigation: Navigation, newPageId: string, pageIndex: number) => {
        const activeIndex = oldNavigation.activePageIndex;
        const sequence = [
            ...oldNavigation.sequence.slice(0, pageIndex),
            ...oldNavigation.sequence.slice(pageIndex + 1, activeIndex + 1),
            newPageId
        ];

        return {
            sequence,
            activePageIndex: activeIndex,
            direction: DIRECTIONFORWARD
        };
    },
    navigateForward = (oldNavigation: Navigation, newPageId: string): Navigation => {
        const nextActiveIndex = oldNavigation.activePageIndex + 1;
        return {
            sequence: (oldNavigation.sequence[nextActiveIndex] === newPageId) ? oldNavigation.sequence :
                [...oldNavigation.sequence.slice(0, nextActiveIndex), newPageId],
            activePageIndex: nextActiveIndex,
            direction: DIRECTIONFORWARD
        };
    },
    navigateBackword = (oldNavigation: Navigation): Navigation => ({
        sequence: oldNavigation.sequence,
        activePageIndex: oldNavigation.activePageIndex - 1,
        direction: DIRECTIONBACKWARD
    });

