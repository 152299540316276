import * as R from 'ramda';
import kind from './kind';
import type { Stylesheet } from '../../../Workspace/epics/stylesheets/flowTypes';
import type { ComboboxOptions } from '../../../../view/common/Combobox/flowTypes';
import type { Intl } from '../../../../view/intl/injectIntl';

export default (styles: Array<Stylesheet>, intl: Intl): ComboboxOptions =>
    R.pipe(
        R.filter(style => style.type === kind),
        R.map(style => ({
            value: style.id,
            label: intl.msgJoint('msg: common.buttonStyle {Button style}') + ' ' + style.index,
            name: style.name
        }))
    )(styles);
