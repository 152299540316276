import React from 'react';
import Icons from '../../../../view/Icons/index';
import FourFields from '../index';
import makeFourFieldIcons from "../makeFourFieldIcons";
import type { FourFieldSpacingProps } from "../flowTypes";

const LockIconDefaultTitle = "msg: common.cell.paragraphSpacing {Link together paragraph spacing}";

export default (props: FourFieldSpacingProps) => (
    <FourFields
        label="msg: common.cellSpacing {Cell spacing (px)}"
        title={props.lockIconTitle || LockIconDefaultTitle}
        icons={makeFourFieldIcons(Icons.SPACING, [90, 180, -90, 0])}
        restrictDispatchTillAnimationFrame
        {...props}
    />
);
