import { FM_DIALOG_ID } from "./dialog/dialogId";
import { FcContentTypes } from "../../redux/modules/children/fileChooser/FcContentTypes";
import { FileManagerMode } from "./constants";

type Params = {
    onSaveAction: string;
    forwardToComponent?: boolean;
};

export const svgChooserDialogDefaultProps = {
    title: 'msg: common.chooseSvg {Choose SVG}',
    contentTypes: FcContentTypes.SVG,
    mode: FileManagerMode.IMAGE_CHOOSER,
    hideFreeOneComImagesTab: true,
    hideMyVideosTab: true,
    hideShutterstockImagesTab: true,
};

export const makeSvgChooserConfigDialog = (params: Params) => ({
    dialogId: FM_DIALOG_ID,
    dialogProps: {
        ...svgChooserDialogDefaultProps,
        ...params,
    },
});
