import type { InstancePropertiesPanelRegistryConfigUpdater } from "./types";
import PropertiesPanelInitialState from "../epic/defaultState";

export const makeInstancePropertiesPanelInitialStateUpdater = (
    actionType: string,
): InstancePropertiesPanelRegistryConfigUpdater => ({
    conditions: [actionType],
    reducer: () => ({
        state: PropertiesPanelInitialState,
    }),
});
