import { $Keys } from "utility-types";
import {
    _FC_SET_SELECTION,
    FC_COMPUTER_UPLOAD,
    FC_RESET,
    FC_RESOURCES_SELECTED,
    FC_SAVE,
    FC_SORT,
    FC_UPLOAD_RENAME,
    FREE_ONE_COM_SAVE
} from "../actionTypes";
import Resource from "../Resource";
import { encodeWebspaceUri } from "../../../../../../dal/utils/webspaceUriTransformers";
import type { FcSelection } from "../flowTypes";
import { SortColumns, SortDirections } from '../sortFunctions';
import { VIDEO_SET } from "../../../../../components/oneweb/Video/actionTypes";

export { default as fcInit } from './fcInit';
export { default as fcToggleResourceSelection } from './fcToggleResourceSelection';
export { default as fcCreateWebSpaceFolder } from './fcCreateWebSpaceFolder';
export { default as reloadResources } from './reloadResources';
export { default as loadResources } from './loadResources';

export type UploadFromComputerAction = {type: 'FC_COMPUTER_UPLOAD', payload: FileList};
export const uploadFromComputerAction = (files: FileList) => ({ type: FC_COMPUTER_UPLOAD, payload: files });

export const fcSetSelectionAction = (selection: Array<Resource>) => ({
    type: _FC_SET_SELECTION,
    payload: { selection }
});

export const fcSelectedResourcesAction = (resources: Array<Resource>) => ({
    type: FC_RESOURCES_SELECTED,
    payload: { resources }
});

export const fcUploadRenameAction = (fileName: string) => ({
    type: FC_UPLOAD_RENAME,
    payload: { fileName: encodeWebspaceUri(fileName) }
});

export const fcResetAction = () => ({
    type: FC_RESET
});

type SaveParams = {
    onSaveAction?: string,
    selection: FcSelection,
    isMultiSelect: boolean,
    forwardToComponent?: boolean,
    closeModalOnSave?: boolean,
    softSave?: boolean,
    headlessMode?: boolean
};

type FreeOneComSaveActionParams = {
    isMultiSelect?: boolean,
    onSaveAction?: string,
    isVideoFileDialog: boolean,
    selection?: any,
    forwardToComponent?: boolean
};

export const fcSaveAction = (params: SaveParams) => ({
    type: FC_SAVE,
    payload: params,
});

export const fcSetVideoAction = (params: SaveParams) => ({
    type: VIDEO_SET,
    payload: params,
});

export const freeOneComSaveAction = (params: FreeOneComSaveActionParams) => ({
    type: FREE_ONE_COM_SAVE,
    payload: params,
});

type SortParams = {
    column: $Keys<typeof SortColumns>,
    direction?: $Keys<typeof SortDirections>,
};

export const fcSortAction = (payload: SortParams) => ({
    type: FC_SORT,
    payload,
});

export const
    FC_DESELECT_ALL_MY_FILES_ACTION = 'FC_DESELECT_ALL_ACTION',
    fcDeselectAllMyFilesAction = () => ({ type: FC_DESELECT_ALL_MY_FILES_ACTION });
