import CALL_API from '../../../../../redux/middleware/api/CALL_API';
import * as Actions from '../actionTypes';
import type { ApiAction } from '../../../../../redux/middleware/api/flowTypes';

export default function (): ApiAction {
    return {
        [CALL_API]: {
            types: [
                Actions.CHECK_RESERVED_CONTENT_REQUEST,
                Actions.CHECK_RESERVED_CONTENT_SUCCESS,
                Actions.CHECK_RESERVED_CONTENT_FAILURE
            ],
            endpoint: 'checkReservedContent'
        }
    };
}
