import { AUTH_USERNAME_KEY_IN_HASH } from "../constants";
import Url from "../../../../../../utils/Url";

export default (url: typeof Url, originallyAuthenticatedEmailAddress: string | null | undefined): string | null | undefined => {
    // @ts-ignore
    const mailIdInHash = url.hash.get(AUTH_USERNAME_KEY_IN_HASH);
    if (mailIdInHash) {
        // Setting it to Mail Id is useful for filling up login in production logins
        // Only Mail IDs are supported for this parameter (username) by the One.com Login page
        try {
            return decodeURIComponent(mailIdInHash);
        } catch (e) {
            return null;
        }
    }

    return originallyAuthenticatedEmailAddress;
};
