import React from 'react';
import Page from '../PropertiesPage';
import VerticalSpacer from '../../../../view/common/VerticalSpacer';
import PropertyContainer from '../../../../view/common/PropertyContainer/index';
import ComboBox from '../../../../view/common/Combobox/index';
import { LOCALE_CHANGED } from '../../../presentational/LocaleList/actionTypes';
import { getSupportedRenderLocales } from '../../../../renderLocales';
import { LANGUAGE, LanguageViewTitle } from './constants';
import type { Props } from './flowTypes';

const
    id = LANGUAGE,
    title = LanguageViewTitle,
    supportedLocales = getSupportedRenderLocales(),
    localeList = Object.keys(supportedLocales).map(key => ({
        value: key, label: supportedLocales[key],
    })),
    view = ({ dispatch, locale }: Props) => (
        <Page>
            <VerticalSpacer />
            <PropertyContainer label={LanguageViewTitle}>
                <ComboBox
                    searchable={false}
                    value={locale}
                    options={localeList}
                    onChange={({ value: locale }) => dispatch({ type: LOCALE_CHANGED, payload: locale })}
                />
            </PropertyContainer>
        </Page>
    );

export { id, title, view };
