import { $Diff } from "utility-types";
import React from 'react';
import {
    scaleWebspaceImgResolution,
    type ScaleWebspaceImgResolutionParams
} from "../../../../dal/utils/webspaceImage/scaleWebspaceImgResolution";
import LazyImage from "../../../view/common/LazyImage";
import type { LazyImageProxy } from "../../../view/common/LazyImage";
import type { ResizeCropT } from "../../../../dal/utils/webspaceImage/flowTypes";
import { ResizeCrop } from "../../../../dal/utils/webspaceImage/constants";
import type { CssObjectFitT } from "../../../mappers/flowTypes";
import { CssObjectFit } from "../../../mappers/constatns";

type Props = $Diff<ScaleWebspaceImgResolutionParams, { crop?: ResizeCropT }> & {
    cover?: boolean,
    lazyProxy?: LazyImageProxy,
    className?: string,
    shouldLoad?: boolean,
    showTransparency?: boolean,
    transparentImageClassName?: string,
    doNotUseDataUriForSafari?: boolean,
    scale?: number[]
};

type State = {
    init: boolean,
    path: string,
    src: string,
    srcSet: string,
    objectFit: null | undefined | CssObjectFitT,
};

export class ScaledWebspaceImage extends React.Component<Props, State> {
    static defaultProps = {
        scale: [2, 3]
    };

    static loadedObjectFits = {};

    static getResolutionState(params: Omit<ScaleWebspaceImgResolutionParams, 'crop'> & { cover?: boolean }) {
        const
            { src, srcSet } = scaleWebspaceImgResolution({
                ...params,
                crop: params.cover ? ResizeCrop.CENTER : undefined
            }),
            objectFit = ScaledWebspaceImage.loadedObjectFits[src]
                ? ScaledWebspaceImage.loadedObjectFits[src]
                : null;

        return { src, srcSet: srcSet.toString(), objectFit };
    }

    static getDerivedStateFromProps(nextProps: Props, prevState: State) {
        const
            { init, path: prevPath } = prevState,
            { path, width, height, scale, cover, etag } = nextProps;

        if (!init) {
            return {
                ...ScaledWebspaceImage.getResolutionState({
                    path,
                    width,
                    height,
                    scale,
                    cover,
                    etag
                }),
                init: true,
                path
            };
        }

        if (path !== prevPath) {
            return {
                ...ScaledWebspaceImage.getResolutionState({
                    path,
                    width,
                    height,
                    scale,
                    cover,
                    etag
                }),
                path
            };
        }

        return null;
    }

    state: State = {
        init: false,
        path: '',
        src: '',
        srcSet: '',
        objectFit: null
    };

    constructor() {
        // @ts-ignore
        super();
        this.onLoad = this.onLoad.bind(this);
    }

    onLoad(img: HTMLImageElement) {
        const { width, height, cover } = this.props;

        if (img.naturalWidth !== width || img.naturalHeight !== height) {
            let state;
            if (img.width < width && img.height < height) {
                // full physically less than container
                state = { objectFit: CssObjectFit.NONE };
            } else if (cover && img.width >= width && img.height >= height) {
                state = { objectFit: CssObjectFit.COVER };
            } else {
                state = { objectFit: CssObjectFit.CONTAIN };
            }

            this.setState(state);
            ScaledWebspaceImage.loadedObjectFits[this.state.src] = state.objectFit;
        }
    }

    render() {
        const
            {
                width,
                height,
                className,
                lazyProxy,
                shouldLoad,
                showTransparency,
                transparentImageClassName,
                doNotUseDataUriForSafari,
            } = this.props,
            { src, srcSet, objectFit } = this.state,
            style = { width, height, objectFit };

        return (
            <LazyImage
                src={src}
                srcSet={srcSet}
                className={className}
                style={style}
                proxy={lazyProxy}
                onLoad={this.onLoad}
                shouldLoad={shouldLoad}
                showTransparency={showTransparency}
                transparentClassName={transparentImageClassName}
                doNotUseDataUriForSafari={doNotUseDataUriForSafari}
            />
        );
    }
}
