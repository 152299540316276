import * as main from './pages/main';
import * as controls from './pages/controls';
import * as onClick from './pages/onClick';
import * as captions from './pages/captions';
import * as fullWidth from './pages/fullWidth';
import * as mobileView from '../../../PropertiesPanel/view/MobileView/page';
import makePages from '../../../PropertiesPanel/view/makePages';

const propertiesPanelConfig = {
    pages: makePages(main, controls, onClick, captions, fullWidth, mobileView),
    defaultPageId: main.id,
    reducer: () => {}
};

export default propertiesPanelConfig;
