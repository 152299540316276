import React from 'react';
import type { ComponentType } from 'react';

type ToggleSelectViewItemProp = {
    id: any,
    data: Record<string, any>,
}

type ToggleSelectViewProps = {
    selectedId: any,
    toggleItemTemplate: ComponentType<any>,
    configs: Array<ToggleSelectViewItemProp>,
    onToggle: Function,
    className?: string,
    children?: React.ReactNode,
}

class ToggleSelectView extends React.Component<ToggleSelectViewProps> {
    render() {
        const {
            configs,
            className,
            onToggle,
            selectedId,
            toggleItemTemplate: ToggleItemView,
            children
        } = this.props;

        return (
            <div>
                <div className={className}>
                    {
                        configs.map(config => {
                            return (
                                <div onClick={() => onToggle(config)} key={config.id}>
                                    <ToggleItemView {...config.data} selected={config.id === selectedId} />
                                </div>
                            );
                        })
                    }
                </div>
                <div>
                    {children}
                </div>
            </div>
        );
    }
}

export default ToggleSelectView;
