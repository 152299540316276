import { parseDOM } from "../../../../../utils/parseDOM";
import { type PaypalComponent } from '../types';

export const parsePaypalPostCode = (code: string): PaypalComponent => {
    let params = <PaypalComponent>{};

    const configs = [
        {},
        {},
        {
            nodeName: 'SCRIPT',
            validator: node => node.src.startsWith("https://www.paypal.com/sdk/js"),
            attributes: {
                src: 'scriptLink'
            }
        },
        {},
        {
            nodeName: 'SCRIPT',
            parser: node => {
                const nodeText = node.text;
                let params = <PaypalComponent>{};

                if (nodeText.includes('shape:')) params.shape = nodeText.match(/shape:\s?(['""'])([\w\d]+)\1/)[2];
                if (nodeText.includes('label:')) params.label = nodeText.match(/label:\s?(['""'])([\w\d]+)\1/)[2];
                if (nodeText.includes('color:')) params.color = nodeText.match(/color:\s?(['""'])([\w\d]+)\1/)[2];
                if (nodeText.includes('layout:')) params.layout = nodeText.match(/layout:\s?(['""'])([\w\d]+)\1/)[2];
                if (nodeText.includes('purchase_units:')) {
                    const purchaseUnits = JSON.parse(nodeText.match(/purchase_units:\s?(\[[^\]]+\])/)[1].replace(/'/g, '"'));
                    if (Array.isArray(purchaseUnits) && purchaseUnits[0].amount && purchaseUnits[0].amount.currency_code) {
                        params.purchaseUnit = JSON.stringify(purchaseUnits);
                    }
                }

                return params;
            }
        }
    ];

    params = parseDOM(configs, code);

    return params;
};
