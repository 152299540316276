import React from 'react';
import { Msg } from "../../../view/intl/index";
import WarningDialog from "../../../view/common/dialogs/WarningDialog/index";
import getCenteredDialogConfig from "../../DialogManager/getCenteredDialogConfig";

const UploadFilesLimitExceedDialog = () => (
    <WarningDialog
        title="msg: common.uploadFailed {Try with fewer files}"
        mctaText="msg: common.gotIt {Got it}"
    >
        <Msg k="workspace.uploadFiles.maxLimit.message">
            You can only upload 10 files at once. Please try again with fewer files.
        </Msg>
    </WarningDialog>
);

export default getCenteredDialogConfig({
    width: 500,
    height: 350,
    component: UploadFilesLimitExceedDialog,
    forceModal: true,
});
