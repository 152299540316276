import React from "react";

import type { Position } from "../../redux/modules/flowTypes";

type Props = {
    position: Position,
    color?: string,
    invertCoordsPosition?: boolean,
}

const
    crossSize = 80,
    halfCrossSize = crossSize / 2,
    MousePositionCross = ({ color = 'black', invertCoordsPosition = false, position: { x, y } }: Props) => {
        const coordsPositionStylePart = invertCoordsPosition ? { left: -halfCrossSize, top: -15 } : { top: 0, left: 5 };
        return (
            <div
                style={{
                    pointerEvents: 'none',
                    left: x,
                    top: y,
                    width: halfCrossSize,
                    height: halfCrossSize,
                    position: 'absolute',
                    zIndex: 100000
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        fontSize: 10,
                        ...coordsPositionStylePart
                    }}
                >{`${x},${y}`}</div>
                <div
                    style={{
                        left: -halfCrossSize,
                        width: crossSize,
                        height: 1,
                        backgroundColor: color,
                        position: 'absolute'
                    }}
                />
                <div
                    style={{
                        top: -halfCrossSize,
                        width: 1,
                        height: crossSize,
                        backgroundColor: color,
                        position: 'absolute'
                    }}
                />
            </div>
        );
    };

export default MousePositionCross;
