import { DOCUMENT_CONFIG_DIALOG_ID } from "./configurationDialog/constants";
import kind from './kind';
import { documentComponentReducer } from "./reducer/documentComponentReducer";
import { documentComponentCalcRenderProps } from "./view/documentComponentCalcRenderProps";
import { DocumentComponent } from "./view/DocumentComponent";
import languagesEpic from "../../TopBar/epics/languages/index";
import propertiesPanel from './propertiesPanel/index';
import componentMainActions from './componentMainActions/index';
import { documentComponentMobileEditorConfig } from "./mobileEditorConfig";
import { ComponentNames } from "../constants";
import { MIN_DIMENSIONS } from "./constants";

export default {
    kind,

    label: ComponentNames[kind],
    shortcutIconName: 'document',

    requireConfigurationOnDrop: () => true,
    configurationDialog: DOCUMENT_CONFIG_DIALOG_ID,

    reducer: documentComponentReducer,
    dependsOn: {
        languages: languagesEpic.reducer
    },

    calcRenderProps: documentComponentCalcRenderProps,
    view: DocumentComponent,

    propertiesPanel,
    componentMainActions,

    mobileEditorConfig: documentComponentMobileEditorConfig,
    minDimensions: MIN_DIMENSIONS
};
