import React from "react";
import { injectIntl } from '../../../view/intl/index';
import VerticalSpacer from '../../../view/common/VerticalSpacer';
import PrefixedInputField from "../../../view/common/Input/PrefixedInputField";
import getCenteredDialog from "../../DialogManager/getCenteredDialogConfig";
import ComponentConfigurationDialog from '../../../view/common/dialogs/ComponentConfigurationDialog/index';
import { fixPageURL } from "./utils";

import { FACEBOOKPAGE_SET_PAGE_URL } from './actionTypes';
import { PAGE_PREFIX, DEFAULT_PAGE, ConfigDialogSettings, DialogWidth, DialogHeight } from "./constants";
import styles from './view/FacebookPage.css';
import type { SetFacebookPageDialogPropTypes, SetFacebookPageDialogStateTypes } from "./flowTypes";

const pageURLValidator = pageURL => fixPageURL(pageURL).length > 0;
const denyFBGroupUrl = pageURL => /^groups\//i.test(fixPageURL(pageURL)) === false;

class SetFacebookPageDialog extends React.Component<SetFacebookPageDialogPropTypes, SetFacebookPageDialogStateTypes> {
    constructor(props: SetFacebookPageDialogPropTypes) {
        super(props);
        this.state = {
            pageURL: props.pageURL ? fixPageURL(props.pageURL) : DEFAULT_PAGE,
            isValid: true
        };
        this.updateState = this.updateState.bind(this);
    }

    shouldComponentUpdate(nextProps: SetFacebookPageDialogPropTypes, nextState: SetFacebookPageDialogStateTypes) {
        return nextState.pageURL !== this.state.pageURL;
    }

    updateState(pageURL, isValid) {
        this.setState({ pageURL: fixPageURL(pageURL), isValid });
    }

    render() {
        // @ts-ignore
        const { props: { replace = false, dispatch, intl }, state: { pageURL, isValid } } = this;

        return (
            <ComponentConfigurationDialog
                title={ConfigDialogSettings.TITLE}
                autoFocus={false}
                onSaveText={this.props.onSaveText || ConfigDialogSettings.ADD_SAVE_LABEL}
                onSave={isValid ? () => dispatch({ type: FACEBOOKPAGE_SET_PAGE_URL, payload: { pageURL, replace } }) : null} // eslint-disable-line max-len
                hideCancel
            >
                <div className={styles.message}>{intl.msgJoint(ConfigDialogSettings.MESSAGE)}</div>
                <VerticalSpacer y={15} />
                <div className={styles.subMessage}>{intl.msgJoint(ConfigDialogSettings.SUBMESSAGE)}</div>
                <VerticalSpacer y={10} />
                <PrefixedInputField
                    prefixText={PAGE_PREFIX}
                    value={pageURL}
                    isValid={isValid}
                    // @ts-ignore
                    onBlur={this.updateState}
                    onAfterPaste={this.updateState}
                    onChange={this.updateState}
                    validators={[
                        { validator: pageURLValidator },
                        { validator: denyFBGroupUrl },
                    ]}
                />
            </ComponentConfigurationDialog>
        );
    }
}

export const configurationDialogId = "FacebookPageComponentConfigDialogId";

export default getCenteredDialog({
    width: DialogWidth,
    height: DialogHeight,
    component: injectIntl(SetFacebookPageDialog)
});
