import { MOVE_SELECTED_COMPONENTS_TO_TEMPLATE } from '../../Workspace/actionTypes';
import { CTX_MENU_DUPLICATE } from "../../ContextMenu/view/constants";

const contextMenu = {
    defaultItemsOverride: {
        [CTX_MENU_DUPLICATE]: {
            disabled: true
        }
    },
    layeringItemsOverride: {
        [MOVE_SELECTED_COMPONENTS_TO_TEMPLATE]: {
            disabled: true
        }
    },
};

export default contextMenu;
