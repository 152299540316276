import type { StateHolder } from "../../flowTypes";
import type { Dimensions } from "../../../../redux/modules/children/workspace/flowTypes";
import { moveInsideViewPort } from "../utils/position";

export default (leftPanelWidth: number, browserDimensions: Dimensions, stateHolder: StateHolder): StateHolder => {
    const { scope, state, state: { show } } = stateHolder;
    if (show) {
        return {
            scope,
            state: {
                ...state,
                animateMovement: true,
                position: moveInsideViewPort(browserDimensions, leftPanelWidth, scope.actualPosition)
            }
        };
    }
    return stateHolder;
};
