import { TemplateCategory } from '../oneweb/Template/constants';
import { TemplateCategoryName } from '../oneweb/Template/messages';
import { gmbCategories } from '../oneweb/Template/gmbCategories';

export const
    CATEGORY = {
        standard: 'standard',
        modified: 'modified',
        blank: 'blank'
    },
    TEMPLATE_FILTER = {
        all: 'all',
        ...(Object
            .keys(TemplateCategory)
            .reduce((acc, k) => Object.assign(acc, { [k.toLowerCase()]: TemplateCategory[k] }), {})
        ),
    },
    TEMPLATE_FILTER_TEXT = {
        "all": 'msg: web.ui.TemplateCategories.List.all {All}',
        ...(Object
            .keys(TemplateCategoryName)
            .reduce((acc, k) => Object.assign(acc, { [k.toLowerCase()]: TemplateCategoryName[k] }), {})),
    },
    GMB_CATEGORY_LIST = gmbCategories,
    DESIGN_DT_PREFIX = 'design-dt-',
    ViewTypes = {
        desktop: 'desktop',
        mobileVertical: 'mobile-vertical',
        mobileHorizontal: 'mobile-horizontal'
    };
