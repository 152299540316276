import inputDefaultState from "../../../inputControls/input/reducerFactory/inputDefaultState";

export default {
    linkTo: {
        url: { ...inputDefaultState, value: 'http://' },
        resource: null
    },
    origPageRefUrl: null,
    openInNewWindow: false,
    name: inputDefaultState,
    isHidden: false
};
