import type { BBox, Handle } from '../../redux/modules/children/workspace/flowTypes';
import { memoMax } from '../../../utils/memo';
import { MaxComponentsOnPage } from "../../constants/app";

export const
    MaxPossibleHandles = (MaxComponentsOnPage * 3) + 1000, // 1000 - safty buffer
    emptyArray: Array<any> = [],
    getComponentsIdsMemo = memoMax(
        componentId => { return componentId ? [componentId] : emptyArray; },
        MaxComponentsOnPage
    ),
    makeHandle = memoMax((componentsIds: Array<string>, kind: string, bBox: BBox, zIndex: number): Handle => ({
        componentsIds,
        kind,
        bBox,
        zIndex,
        minX: bBox.left,
        maxX: bBox.right,
        minY: bBox.top,
        maxY: bBox.bottom
    }), MaxPossibleHandles);

export default (componentsIds: Array<string>, kind: string, bBox: BBox, zIndex: number): Handle => {
    if (componentsIds.length <= 1) {
        return makeHandle(getComponentsIdsMemo(componentsIds[0]), kind, bBox, zIndex);
    } else {
        return makeHandle(componentsIds, kind, bBox, zIndex);
    }
};
