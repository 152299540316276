import mime from "mime";
import type { FileManagerState } from "../flowTypes";

export default (state: FileManagerState, action: any) => {
    const
        { payload: { entries } } = action,
        // sometimes server responds with no contentType set ...
        resources = entries.map(data => {
            if (data.contentType === 'image/*') {
                // @ts-ignore
                return ({ ...data, contentType: mime.getType(data.href) || data.contentType });
            }
            return ({ contentType: 'X', ...data });
        });

    return { ...state, resources, resourcesLoadProgress: false };
};
