import Url from "../../../../../../utils/Url";
import { AUTH_TARGET_DOMAIN_KEY_IN_HASH } from "../constants";

export default (url: typeof Url, domainName: string | null | undefined): string | null | undefined => {
    // @ts-ignore
    const targetDomainInHash = url.hash.get(AUTH_TARGET_DOMAIN_KEY_IN_HASH);
    if (targetDomainInHash) return targetDomainInHash;
    else if (domainName) return domainName;
    return null;
};
