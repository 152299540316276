import React from 'react';
import { connect } from "react-redux";
import styles from '../FileChooserList.css';
import Cell from './Cell';
import { directoryCellMapDispatchToProps } from "../../utils";

// TODO: flowtype bug: (https://github.com/facebook/flow/issues/1511)

const DirectoryCell = ({ resource, navigateSubDirectory, ...props }) => (
    <Cell onClick={() => navigateSubDirectory(resource)} {...props} className={styles.directoryIcon} />
);

export default connect(null, directoryCellMapDispatchToProps)(DirectoryCell);
