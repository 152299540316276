// @flow

const filter = require('ramda/src/filter');
const join = require('ramda/src/join');
const pipe = require('ramda/src/pipe');
const {
    ALL_COUNTRIES,
    singleLineAddressSeparator,
    multiLineAddressSeparator,
} = require('./constants.js');
const { getCountryFormat } = require("./getCountryFormat.js");

const filterFalsyValues = filter(x => !!x);
const joinWithSeparator = join;
const joinWithComma = joinWithSeparator(', ');
const joinWithSpace = joinWithSeparator(' ');

/*::
    import type { CountryFormat, RenderAddressToStringProps } from './types'
*/

const renderAddressToString = ({
    addressName,
    addressStreetAddress,
    addressCity,
    addressZip,
    addressArea,
    addressCountry,
    countryFormat,
    separator,
}/*: {|
    addressName: string,
    addressStreetAddress: string,
    addressCity: string,
    addressZip: string,
    addressArea: string | null,
    addressCountry: string | null,
    countryFormat: CountryFormat,
    separator: string,
|}*/) => { // eslint-disable-line function-paren-newline
    let parts = [addressName];
    if (addressName !== addressStreetAddress) {
        parts = [...parts, addressStreetAddress]
    }
    if (countryFormat === 'CountryFormat1') {
        parts = [...parts, pipe(filterFalsyValues, joinWithSpace)([addressZip, addressCity])];
    } else if (countryFormat === 'CountryFormat2') {
        parts = [...parts, addressCity, addressZip];
    } else if (countryFormat === 'CountryFormat3') {
        parts = [
            ...parts,
            pipe(filterFalsyValues, joinWithComma)([
                addressCity, pipe(filterFalsyValues, joinWithSpace)([addressArea, addressZip])
            ])
        ];
    } else {
        parts = [...parts, pipe(filterFalsyValues, joinWithSpace)([addressZip, addressCity]), addressArea];
    }
    parts = [...parts, addressCountry];
    return pipe(filterFalsyValues, joinWithSeparator(separator))(parts);
};

const makeRenderAddressToString = (separator/*: string*/) =>
    ({
        addressName,
        addressStreetAddress,
        addressCity,
        addressZip,
        addressArea,
        addressCountryCode,
        showCountry = true
    }/*: RenderAddressToStringProps*/) => {
        const countryFormat/*: CountryFormat*/ = getCountryFormat(addressCountryCode);
        const addressCountry = showCountry ? ALL_COUNTRIES[addressCountryCode] : null;
        return renderAddressToString({
            addressName,
            addressStreetAddress,
            addressCity,
            addressZip,
            addressArea,
            addressCountry,
            countryFormat,
            separator,
        });
    };

const renderAddressToStringMultiline = makeRenderAddressToString(multiLineAddressSeparator);
const renderAddressToStringSingleline = makeRenderAddressToString(singleLineAddressSeparator);

module.exports = {
    renderAddressToString,
    renderAddressToStringMultiline,
    renderAddressToStringSingleline,
};
