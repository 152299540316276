// @ts-nocheck
import { getAppConfig } from "../../components/App/epics/appConfig/appConfig";

const appConfig = getAppConfig();

const defaultFacebookPage = (
    appConfig.oneWeb &&
    appConfig.oneWeb.facebookPage &&
    appConfig.oneWeb.facebookPage.defaultPage
) || 'facebook';

export { defaultFacebookPage };
