import makeEpic from '../../../../epics/makeEpic';
import { BACKGROUND_VAT } from "./valueActionType";
import {
    BG_GRADIENT_COLOR_SET_AUTO_COLOR,
    BG_GRADIENT_COLOR_SET_IF_UNSET,
    BG_BACKGROUND_COLOR_SET_AUTO_COLOR,
    BG_OPACITY_SET_IF_ZERO,
} from "../actionTypes";
import { ROThemeColorSelector } from '../../../Workspace/epics/stylesheets/selectors';
import { SELECTED_COMPONENT } from '../../../../redux/forwardTo';

export const backgroundEpic = makeEpic({
    valueActionType: BACKGROUND_VAT,
    defaultState: null,
    updaters: [{
        conditions: [
            BG_GRADIENT_COLOR_SET_AUTO_COLOR,
            ROThemeColorSelector,
        ],
        reducer: ({ state, values: [{ themeColor }, themeColorsData] }) => {
            if (themeColor) {
                return {
                    state,
                    actionToDispatch: {
                        type: BG_GRADIENT_COLOR_SET_IF_UNSET,
                        payload: { color: [...themeColorsData[themeColor]] },
                        forwardTo: { kind: SELECTED_COMPONENT },
                    }
                };
            } else {
                return { state };
            }
        },
    }, {
        conditions: [
            BG_BACKGROUND_COLOR_SET_AUTO_COLOR,
            ROThemeColorSelector,
        ],
        reducer: ({ state, values: [{ backgroundTheme }, themeColorsData] }) => {
            return {
                state,
                actionToDispatch: {
                    type: BG_OPACITY_SET_IF_ZERO,
                    payload: { autoColorMode: true, selectedTheme: backgroundTheme, themeColorsData },
                    forwardTo: { kind: SELECTED_COMPONENT },
                }
            };
        },
    }]
});
