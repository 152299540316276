import * as R from "ramda";
import ellipsis from "text-ellipsis";
import Resource from "../../redux/modules/children/fileChooser/Resource";
import { activeNodeClassName, inactiveNodeClassName } from './constants';
import { decodeWebspaceUri } from "../../../dal/utils/webspaceUriTransformers";

const addEllipses = (imageName: string) => {
    let start = imageName.substr(0, 34),
        end = ellipsis(imageName, 10, { side: 'start' });
    return start + end;
};

export const
    getNodeCss = (imageIdx: number, activeNodeIndex: number) =>     // eslint-disable-line
        imageIdx === activeNodeIndex ? activeNodeClassName : inactiveNodeClassName,
    shouldCancelStart = (e: Event) => {
        const { target } = e;
        if (target) {
            // @ts-ignore
            const { nodeName, className } = target;
            return (nodeName &&
                nodeName.toLowerCase() === 'div' &&
                ['deleteImageIcon', 'editImage'].some(c => className.indexOf(c) > -1)
            );
        }
        return false;
    },
    getImageNameFromUrl = (url: string, shorten: boolean = true, shortenLength: number = 58) => {
        const
            parts = url.split('?'),
            imageName = decodeWebspaceUri(Resource.getBaseName(parts[0]));

        return shorten ? ellipsis(imageName, shortenLength) : imageName;
    },

    getImageNameWithoutExtension = (url: string = "") => {
        return R.pipe(
            R.split("."),
            R.dropLast(1),
            R.join(".")
        )(getImageNameFromUrl(url, false));
    },
    validateLongImageName = (imageName: string) => {
        return imageName.length > 42 ? addEllipses(imageName) : imageName;
    };
