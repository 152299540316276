import { DataSite } from "../../../../dal/model";
import { isPageRef } from "../../../../dal/model/utils/dataSiteItemUtils";

type Params = {
    siteData: DataSite;
    itemId: string;
    toItemId: string;
    gap: -1 | 1 | 0;
};

export const disallowedToMoveHomePage = ({ siteData, itemId, toItemId, gap }: Params): boolean => {
    const item = siteData.getItemById(itemId),
        toItem = siteData.getItemById(toItemId);
    // $FlowFixMe: isPageRef() takes case of the check
    if (isPageRef(item) && isPageRef(toItem) && siteData.isHomePageId(item.pageId)) {
        if (gap === 0) return true;

        const toItemPath = siteData.getPageRefUrlPath(toItemId);
        if (toItemPath.length >= 2) {
            return true;
        }
    }

    return false;
};
