export const
    textTabLabel = 'msg: common.gs.tab.text.label {Text}',
    types = {
        LOGO: 'logo',
        NORMAL: 'normal',
        HEADING1: 'heading.1',
        HEADING2: 'heading.2',
        HEADING3: 'heading.3'
    },
    globalStyleLabels = {
        [types.LOGO]: 'msg: common.logo {Logo}',
        [types.NORMAL]: 'msg: common.gs.textStyle.normal {Paragraph}',
        [types.HEADING1]: 'msg: common.gs.textStyle.heading1 {Heading 1}',
        [types.HEADING2]: 'msg: common.gs.textStyle.heading2 {Heading 2}',
        [types.HEADING3]: 'msg: common.gs.textStyle.heading3 {Heading 3}'
    },
    globalStyleTextClass = {
        [types.NORMAL]: 'textnormal',
        [types.HEADING1]: 'textheading1',
        [types.HEADING2]: 'textheading2',
        [types.HEADING3]: 'textheading3'
    },
    MobileMaxFontSize = {
        [types.LOGO]: 40,
        [types.NORMAL]: 20,
        [types.HEADING1]: 40,
        [types.HEADING2]: 28,
        [types.HEADING3]: 24
    },
    DEFAULT_TEXT_COLOR = "#000000",
    DefaultComponentDimensions = {
        width: {
            [types.NORMAL]: 300,
            [types.HEADING1]: 550,
            [types.HEADING2]: 435,
            [types.HEADING3]: 370
        },
        height: {
            [types.NORMAL]: 80,
            [types.HEADING1]: 80,
            [types.HEADING2]: 80,
            [types.HEADING3]: 80
        }
    };
