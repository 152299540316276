import PromoPageDialog from './index';
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import { getCenterPositionForDialog } from "../../../../DialogManager/utility";

const HEIGHT = 720,
    WIDTH = 1218,
    promoPageDialog = {
        ...getCenteredDialogConfig(WIDTH, HEIGHT, PromoPageDialog),
        confFactory: ({ browserWidth, browserHeight }) => {
            return {
                position: getCenterPositionForDialog(WIDTH, HEIGHT, browserWidth, browserHeight),
                modal: true,
                dimensions: { width: WIDTH, height: HEIGHT }
            };
        },
        moveToCenter: true,
    };

export {
    promoPageDialog
};
