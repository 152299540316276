import type { AdjustAfterUpdate, EpicUpdater, UndoConfig } from "../../../../epics/flowTypes";
import makeEpic from "../../../../epics/makeEpic";

type Props<State, Scope> = {
    valueActionType?: string,
    key: string,
    undo: true | UndoConfig<State, Scope, string>,
    defaultState: Record<string, any>,
    defaultScope?: Record<string, any>,
    dispatchOutside?: true,
    afterUpdate?: AdjustAfterUpdate<State, Scope, string>,
    updaters?: Array<EpicUpdater<State, Scope, string>>,
    handleActions?: Record<string, (State, action: Action & { payload: any }) => State>
}
const makeSiteSettingsChildEpic = <State, Scope>({
    valueActionType,
    key,
    undo,
    defaultState,
    defaultScope,
    dispatchOutside,
    afterUpdate,
    updaters,
    handleActions,
}: Props<State, Scope>) => {
    return makeEpic({
        valueActionType: valueActionType || `SITE_SETTINGS_CHILD_${key.toUpperCase()}_EPIC_VALUE`,
        undo,
        defaultState,
        defaultScope,
        dispatchOutside,
        afterUpdate,
        updaters,
        saveIntoSiteSettings: { key },
        // @ts-ignore
        handleActions
    });
};

export {
    makeSiteSettingsChildEpic,
};
