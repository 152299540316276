import * as R from 'ramda';
import { $ } from 'tinymce';
import bowser from 'bowser';
import React from 'react';
import View from './index';
import {
    MOBILE_EDITOR_REMOVE_COMPONENT_EXTENSION_PROPS,
    MOBILE_EDITOR_UPDATE_COMPONENT_EXTENSION_PROPS
} from "../../../MobileViewEditor/actionTypes";
import { ALIGNMENT_TYPES } from "../../../../constants";
import styles from './styles.css';
import type { TextInMVE } from "../flowTypes";

const NODE_TYPE_TEXT = 3;

const { msie } = bowser;

const removeImpFontAttr = (node) => {
    let style: string = node.attr('style') || '';
    const matches = style.match(/font-size:[\s\w\.%]*!important;?/);
    if (matches) {
        matches.forEach((match) => { style = style.replace(match, ''); });
        node.attr('style', style);
    }
};

export const updateTextView = (componentEle: any, textSize: number) => {
    const nodes = componentEle.find('*').toArray().reverse();
    let minSize, maxSize;
    nodes.forEach((domNode) => {
        const node = $(domNode);
        let fontSize: number = parseFloat(node.css('fontSize'));
        const removeCls = Array.from(domNode.classList)
            // @ts-ignore
            .filter(cls => cls.match(/mobile-((oversized)|(undersized))[\w-]*/g))
            .join(' ');
        if (removeCls) {
            node.removeClass(removeCls);
        }
        fontSize = fontSize + textSize;
        if (msie) {
            removeImpFontAttr(node);
        }
        node.css('font-size', `${fontSize > 9 ? fontSize : 9}px`);
        const hasTextNode = Array.from(node.contents()).some((ele: any) => {
            return ele.nodeType === NODE_TYPE_TEXT && ele.nodeValue.trim();
        });
        if (hasTextNode) {
            maxSize = R.max(fontSize, maxSize);
            minSize = R.min(fontSize, minSize);
        }
    });
    return {
        minSize,
        maxSize
    };
};

class Text extends React.Component<TextInMVE> {
    id: string;

    constructor(props: TextInMVE) {
        super(props);
        this.id = props.component.id;
    }

    shouldComponentUpdate(nextProps: TextInMVE) {
        const prevTextSize = R.pathOr(0, ['componentExtensions', this.id, 'currentTextSize'], nextProps),
            textSize = nextProps.component.mobileSettings.font || 0;
        const { align } = this.props.component.mobileSettings,
            { align: nextAlign } = nextProps.component.mobileSettings;

        return textSize !== prevTextSize || align !== nextAlign ||
            (this.props.selectedCmpId !== nextProps.selectedCmpId && nextProps.selectedCmpId === this.id);
    }

    componentDidUpdate() {
        const prevTextSize = R.pathOr(0, ['componentExtensions', this.id, 'currentTextSize'], this.props),
            textSize = this.props.component.mobileSettings.font || 0,
            componentEle = $(`[data-id='${this.id}'][data-specific-kind='TEXT'] .${styles.contentContainer}`),
            { minSize, maxSize } = updateTextView(componentEle, textSize - prevTextSize),
            textAlign = componentEle.children().css('textAlign');
        this.props.dispatch({
            type: MOBILE_EDITOR_UPDATE_COMPONENT_EXTENSION_PROPS,
            payload: {
                id: this.id,
                currentTextSize: textSize,
                textAlign: textAlign || ALIGNMENT_TYPES.ALIGN_LEFT,
                minSize,
                maxSize
            }
        });
    }

    componentWillUnmount() {
        this.props.dispatch({
            type: MOBILE_EDITOR_REMOVE_COMPONENT_EXTENSION_PROPS,
            payload: {
                id: this.id
            }
        });
    }

    render() {
        // @ts-ignore
        return <View {...this.props} />;
    }
}

export default Text;
