import makeEpic from "../../../epics/makeEpic";
import valueActionType from "./valueActionType";
import AppStatusVAT from "../../App/epics/status/valueActionType";
import { MIGRATING_USER_DATA } from "../../App/epics/status/appStatusTypes";
import { fetchUserDataMigrationInfo, requestUserDataMigration } from "../migration/index";
import {
    FETCH_USER_DATA_MIGRATION_INFO_SUCCESS,
    FETCH_USER_DATA_MIGRATION_INFO_FAILURE,
    USER_DATA_MIGRATION_REQUEST_SUCCESS,
    USER_DATA_MIGRATION_REQUEST_FAILURE
} from "../migration/userDataMigrationActionTypes";
import { openDialog, closeDialogByIdAction } from "../../App/actionCreators/index";
import { sendShownErrorEventToAecAC } from '../../App/actionCreators/sendEventToAecActionCreator';
import UserDataMigrationFailedDialogId from "../dialog/migrationFailedId";
import { UserDataMigrationStatus } from "./constants";
import { createScheduledAction, cancelScheduledAction } from "../../../redux/middleware/schedule/actionCreators";
import { UserDataMigrationProlongedDialogId } from "../dialog/migrationProlonged";

const updateState = (props) => {
    const
        { state, showErrorDialog } = props,
        additionalDispatch = props.additionalDispatch || [],
        multipleActionsToDispatch: Action[] = [];

    if (showErrorDialog) {
        multipleActionsToDispatch.push(openDialog(UserDataMigrationFailedDialogId));
    }

    if (Array.isArray(additionalDispatch) && additionalDispatch.length) {
        multipleActionsToDispatch.push(...additionalDispatch);
    }

    return {
        state,
        multipleActionsToDispatch
    };
};
const WBTGEN_MIGRATION_PROLONGED_DIALOGUE_TIMER = 10000, //10 seconds
    prolongedDialogActionToDispatch: any = openDialog(UserDataMigrationProlongedDialogId),
    makeDelayedMigrationProlongedDialogAction = () => createScheduledAction({
        actionToDispatch: prolongedDialogActionToDispatch,
        timeout: WBTGEN_MIGRATION_PROLONGED_DIALOGUE_TIMER
    }),
    cancelDelayedMigrationProlongedDialogAction = () => cancelScheduledAction(prolongedDialogActionToDispatch),
    closeMigrationProlongedDialog = () => closeDialogByIdAction(UserDataMigrationProlongedDialogId);

const userDataMigrationEpic = makeEpic({
    defaultState: null,
    valueActionType,
    updaters: [
        {
            conditions: [AppStatusVAT],
            reducer: ({ values: [status], state }) => {
                if (state === null && status === MIGRATING_USER_DATA) {
                    return {
                        state: UserDataMigrationStatus.FETCHING_USER_DATA_MIGRATION_INFO,
                        actionToDispatch: fetchUserDataMigrationInfo
                    };
                }

                return { state };
            }
        },
        {
            conditions: [FETCH_USER_DATA_MIGRATION_INFO_SUCCESS],
            reducer: ({ values: [migrationInfo] }) => {
                const { currentDataVersionNumber, latestDataVersionNumber } = migrationInfo;

                if (currentDataVersionNumber < latestDataVersionNumber) {
                    return {
                        state: UserDataMigrationStatus.REQUESTING_USER_DATA_MIGRATION,
                        multipleActionsToDispatch: [requestUserDataMigration, makeDelayedMigrationProlongedDialogAction()]
                    };
                }

                return { state: UserDataMigrationStatus.NO_USER_DATA_MIGRATION_REQUIRED };
            }
        },
        {
            conditions: [FETCH_USER_DATA_MIGRATION_INFO_FAILURE],
            reducer: () => updateState({
                state: UserDataMigrationStatus.FETCH_USER_DATA_MIGRATION_INFO_FAILED,
                showErrorDialog: true,
                additionalDispatch: [sendShownErrorEventToAecAC('fetch user data migration status failed')]
            })
        },
        {
            conditions: [USER_DATA_MIGRATION_REQUEST_SUCCESS],
            reducer: ({ values: [migrationStatus] }) => {
                if (migrationStatus.success === false) {
                    return updateState({
                        state: UserDataMigrationStatus.USER_DATA_MIGRATION_FAILURE,
                        showErrorDialog: true,
                        additionalDispatch: [sendShownErrorEventToAecAC('user data migration failed'),
                            cancelDelayedMigrationProlongedDialogAction(),
                            closeMigrationProlongedDialog()]
                    });
                }

                return updateState({
                    state: UserDataMigrationStatus.USER_DATA_MIGRATION_SUCCESSFUL,
                    showErrorDialog: false,
                    additionalDispatch: [sendShownErrorEventToAecAC('user data migration failed'),
                        cancelDelayedMigrationProlongedDialogAction(),
                        closeMigrationProlongedDialog()]
                });
            }
        },
        {
            conditions: [USER_DATA_MIGRATION_REQUEST_FAILURE],
            reducer: () => updateState({
                state: UserDataMigrationStatus.USER_DATA_MIGRATION_FAILURE,
                showErrorDialog: true,
                additionalDispatch: [sendShownErrorEventToAecAC('user data migration request failed'),
                    cancelDelayedMigrationProlongedDialogAction(),
                    closeMigrationProlongedDialog()]
            })
        }
    ]
});

export {
    userDataMigrationEpic
};
