export const
    DialogWidth = 470,
    DialogHeight = 291,
    PopupWindowWidth = 973,
    PopupWindowHeight = 798,
    DialogMessages = {
        TITLE: 'msg: component.facebookFeed.multiplePages {Choose Facebook page}',
        MESSAGE: 'msg: component.facebookFeed.multiplePages.message {You have selected multiple Facebook pages when connecting. Website Builder only allows one connected page at a time. Select a page to display on your site.}', // eslint-disable-line max-len
        RECONNECT_LABEL: 'msg: component.facebookFeed.multiplePages.reconnectLabel {Connect Facebook}',
        CANCEL_LABEL: 'msg: component.facebookFeed.multiplePages.cancel {Cancel}'
    };
