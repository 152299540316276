import { sendGTMEvent } from "../../../src/components/App/actionCreators/googleTagManager";
import { DEMO_LOGIN_GTM_CATEGORY, DEMO_LOGIN_GTM_EVENT } from "./events";

export const sendDemoLoginGTMEventAction = (eventAction: string) => sendGTMEvent({
    event: DEMO_LOGIN_GTM_EVENT,
    eventCategory: DEMO_LOGIN_GTM_CATEGORY,
    eventAction,
});

export const sendDemoLoginGTMEvent = (event: Record<string, any>) => sendGTMEvent({
    event: DEMO_LOGIN_GTM_EVENT,
    eventCategory: DEMO_LOGIN_GTM_CATEGORY,
    ...event,
});
