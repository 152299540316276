import makeUuid from "../../../utils/makeUuid";

// TODO: WBTGEN-2155
export default (model: Record<string, any>, createNewId: boolean = true, patch: Record<string, any> = {}): Record<string, any> => {
    const
        proto = Object.getPrototypeOf(model),
        newModel = { ...model, ...patch };
    if (createNewId) newModel.id = makeUuid();
    Object.setPrototypeOf(newModel, proto);

    return newModel;
};
