import DataSite, { DataSiteErrorMessage } from "../../../../../../dal/model/DataSite";

export default (site?: DataSite): boolean => {
    try {
        // @ts-ignore
        site.getHomePageRef();
        return false;
    } catch (e: any) {
        if (e.message.indexOf(DataSiteErrorMessage.MISSING_HOME_PAGE) !== -1) {
            return true;
        }
        throw e;
    }
};
