import * as R from 'ramda';
import { zeroBBox, getComponentsBBox } from '../../../../utils/componentsMap/index';
import isUndoableChange from './isUndoableChange';
import makeBBoxMemoized from "../../../../utils/componentsMap/makeBBoxMemoized";
import * as updateReasons from './updateReasons';
import { getComponentsMapNoGhosts } from '../../../oneweb/Code/getComponentsMapNoGhosts';

import type { ComponentEvalAdjustAfterUpdate } from './flowTypes';
import type { BBox, ComponentsMap } from '../../../../redux/modules/children/workspace/flowTypes';
import { getHeaderAndFooterSection } from "../../../oneweb/Section/utils";
import {
    getAllComponentsIdsInModernFooter,
    getAllComponentsIdsInModernHeader,
    isModernLayoutSection
} from "../../../ModernLayouts/preview_utils";
import { isCmpsMapHasWebshopFooterCmps, getWebShopStripCmpIds } from '../../../ModernLayouts/layoutsData/webshopMHFDataUtils';

type MakeContentBboxParams = {
    templateWidth: number;
    componentsMap: ComponentsMap;
    horizontalMargin?: number;
    freeSpaceBelowComponents?: number;
};

const removeMHFCmps = (cmpsMap, cmpIdsToRemove) => {
    let newCmpsMap = { ...cmpsMap };
    cmpIdsToRemove.forEach(id => {
        delete newCmpsMap[id];
    });
    return newCmpsMap;
};

const makeContentBBox = ({
    templateWidth,
    componentsMap,
    horizontalMargin = 0,
    freeSpaceBelowComponents = 0
}: MakeContentBboxParams): BBox => {
    let minMHFLeft = 0,
        maxMHFRight = templateWidth,
        minWidth = 0,
        newCmpsMap = componentsMap,
        { footer, header } = getHeaderAndFooterSection(componentsMap);
    if (header && isModernLayoutSection(header)) {
        minWidth = (header.modernLayout && (header.modernLayout.minDimensions || {}).width) || 0;
        newCmpsMap = removeMHFCmps(componentsMap, getAllComponentsIdsInModernHeader(componentsMap));
    }
    if (footer && isModernLayoutSection(footer)) {
        minWidth = Math.max((footer.modernLayout && (footer.modernLayout.minDimensions || {}).width) || 0, minWidth);
        newCmpsMap = removeMHFCmps(newCmpsMap, getAllComponentsIdsInModernFooter(newCmpsMap));
    }
    if (isCmpsMapHasWebshopFooterCmps(newCmpsMap)) {
        newCmpsMap = removeMHFCmps(newCmpsMap, getWebShopStripCmpIds(newCmpsMap));
    }
    if (minWidth > templateWidth) {
        let diffOnEachSide = (minWidth - templateWidth) / 2;
        minMHFLeft = minMHFLeft - diffOnEachSide;
        maxMHFRight = maxMHFRight + diffOnEachSide;
    }
    const
        componentsMapBBox = getComponentsBBox(R.values(newCmpsMap), zeroBBox), // passing zeroBBox to avoid strip calculations
        height = componentsMapBBox.bottom;
    if (minWidth) {
        componentsMapBBox.left = Math.min(componentsMapBBox.left, minMHFLeft);
        componentsMapBBox.right = Math.max(componentsMapBBox.right, maxMHFRight);
    }

    let
        finalLeft,
        finalRight;

    if (componentsMapBBox.right <= templateWidth && componentsMapBBox.left >= 0) {
        // all components within template
        finalLeft = 0;
        finalRight = templateWidth;
    } else {
        const
            deviationRight = componentsMapBBox.right - templateWidth,
            deviationLeft = componentsMapBBox.left,
            maxTemplateDeviation = deviationLeft < 0 ?
                Math.max(Math.abs(deviationLeft), deviationRight) : deviationRight;

        finalLeft = -maxTemplateDeviation;
        finalRight = templateWidth + maxTemplateDeviation;
    }

    finalLeft -= horizontalMargin;
    finalRight += horizontalMargin;
    return makeBBoxMemoized(
        finalLeft,
        0,
        finalRight - finalLeft,
        height + freeSpaceBelowComponents
    );
};

const
    HorizontalMargin = 50,
    adjustContentDimensionsAfterUpdate: ComponentEvalAdjustAfterUpdate = props => {
        const { prevState, nextState, updateReason } = props;

        if (
            isUndoableChange(props)
            || prevState.scope.templateWidth !== nextState.scope.templateWidth
            || updateReason === updateReasons.ADJUSTMENT_DATA_APPLIED
            || updateReason === updateReasons.SHIFTDOWN_DURING_TYPING
            || updateReason === updateReasons.COMPONENT_HEIGHT_CHANGE_DURING_TYPING
            || updateReason === updateReasons.ADJUSTMENT_DATA_APPLIED_AFTER_COMPONENT_ADD
        ) {
            const { scope: { templateWidth }, state: { componentsMap } } = nextState;
            return {
                state: R.evolve({
                    scope: {
                        contentBBox: () => makeContentBBox({
                            templateWidth,
                            componentsMap: getComponentsMapNoGhosts(componentsMap),
                            horizontalMargin: HorizontalMargin
                        })
                    }
                }, nextState)
            };
        }

        return { state: nextState };
    };

export { adjustContentDimensionsAfterUpdate as default, makeContentBBox };
