import { parseIframeCode, regexpsValidator } from '../Widgets/utils';
import buildNewVideoComponentState from './buildNewVideoComponentState';
import { MIN_DIMENSIONS } from './constants';
import { type IframeParserOutput, type LinkValidator } from '../Widgets/types';
import { type ParseYoutubeCodeOutput } from './flowTypes';

const
    getYoutubeURLRegex = (ytUrlPattern: string): RegExp => {
        return new RegExp(/^(?:https?:\/\/)?(?:www\.)?/.source + ytUrlPattern + /([-\w\d]{11})/.source);
    },
    ytUrlRegexps = [
        'youtu\\.be/',
        'youtube\\.com\\/watch\\?v=',
        'youtube\\.com\\/embed\\/',
        'youtube-nocookie\\.com\\/embed\\/',
        'youtube\\.com\\/shorts\\/',
    ].map(getYoutubeURLRegex),
    extractVideoId = (url: string): string => {
        let videoId = '';
        ytUrlRegexps.forEach(regexp => {
            if (videoId.length === 0 && regexp.test(url)) {
                const m = url.match(regexp);
                if (Array.isArray(m) && m.length) {
                    videoId = m[1];
                }
            }
        });

        return videoId;
    };

export const isYoutubeLinkValid: LinkValidator = regexpsValidator(ytUrlRegexps);

export const parseYoutubeCode = (code: string): ParseYoutubeCodeOutput => {
    let params = <ParseYoutubeCodeOutput>{};
    const str = code.trim();

    if (isYoutubeLinkValid(str)) {
        // @ts-ignore
        params = buildNewVideoComponentState(extractVideoId(str));
    } else {
        const { width, height, src }: IframeParserOutput = parseIframeCode(str, MIN_DIMENSIONS);

        if (src && isYoutubeLinkValid(src)) {
            // @ts-ignore
            params = {
                ...buildNewVideoComponentState(extractVideoId(src)),
                width,
                height
            };
        }
    }

    return params;
};
