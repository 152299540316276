import { inactive, hover } from '../../../mappers/path';
import InctiveBackgroundConfigurationDialogId from './inactiveBackgroundConfigurationDialogId';
import HoverBackgroundConfigurationDialogId from './hoverBackgroundConfigurationDialogId';
import InactiveBorderStyleConfigurationDialogId from './inactiveBorderStyleConfigurationDialogId';
import HoverBorderStyleConfigurationDialogId from './hoverBorderStyleConfigurationDialogId';

export const
    buttonTabLabel = 'msg: common.gs.button.tab.label {Button}',
    backgroundDialogConfigurationDialogId = {
        [inactive]: InctiveBackgroundConfigurationDialogId,
        [hover]: HoverBackgroundConfigurationDialogId
    },
    borderStyleDialogConfigurationDialogId = {
        [inactive]: InactiveBorderStyleConfigurationDialogId,
        [hover]: HoverBorderStyleConfigurationDialogId
    };
