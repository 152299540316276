import removeDuplicateElements from './removeDuplicateElements';
import type { HtmlWriterData, Element } from '../../flowTypes';
import { except } from './setOperations';
import { executeOnExactMatch } from '../elements/utils/executeOnMatch';

const removeGlobalProperties = except(['globalName', 'globalId']);
const removeDefaultProperties = except(['atype', 'start', 'end']);

const removeFullTextNormalStyles = (component: HtmlWriterData) => {
    const { paras, styles } = component;
    const outputStyles: Element[] = [];

    executeOnExactMatch(
        // @ts-ignore
        styles, paras,
        // @ts-ignore
        (style, para, isFirst) => { if (isFirst) outputStyles.push(removeGlobalProperties(style)); },
        style => { outputStyles.push(style); }
    );

    return outputStyles.filter(style => Object.keys(removeDefaultProperties(style)).length);
};

export default (data: HtmlWriterData): HtmlWriterData => {
    let styles;
    styles = removeFullTextNormalStyles(data);
    styles = removeDuplicateElements(data.styles);

    return {
        ...data,
        styles
    };
};
