import * as React from "react";
import {
    MctaButton,
    MctaCombobox,
    MctaPropertyContainer,
    MctaVerticalBar
} from "../../../componentMainActions/index";
import { MctaColor } from "../../../componentMainActions/MctaColor/index";
import { MctaOpacitySlider } from '../../../componentMainActions/MctaSlider/MctaOpacitySlider';
import {
    getBackgroundImageOpacity,
    getComponentColorGradientSettings
} from "../../../../view/oneweb/commonComponentSelectors";
import {
    BG_BACKGROUND_COLOR_SET,
    BG_OPACITY_CHANGE,
    BG_BACKGROUND_COLOR_SET_AUTO_COLOR,
    BG_MOBILE_IMAGE_SIZE_CHANGE_ACTION,
    BG_OPACITY_CHANGE_AUTO_COLOR
} from "../actionTypes";
import type { BackgroundComponent } from '../flowTypes';
import type { ComponentMainActionProps } from "../../flowTypes";
import HorizontalSpacer from "../../../../view/common/HorizontalSpacer";
import { ImageOpacityLabel } from "../constants";
import { isNumeric } from "../../../../../utils/number";
import { bgSetBackgroundImageOpacityAC } from "../actionCreators";
import { BACKGROUND_THEME_WHITE } from "../../../ThemeGlobalData/constants";
import { THEME_BG_OVERRIDE_COLOR_PICKER, DEFAULT_COLOR_PICKER } from '../../../ColorPicker/constants';
import type { ThemeSiteSettingsDataType, ThemeColorDataType, ThemeBackgroundType } from "../../../ThemeGlobalData/flowTypes";
import mctaPropertyContainerStyles from '../../../componentMainActions/MctaPropertyContainer/MctaPropertyContainer.css';
import Label from "../../../../view/common/Label";
import { FcContentTypes } from "../../../../redux/modules/children/fileChooser/FcContentTypes";
import { MobileBackgroundImageSizeOptions, BackgroundSize } from "../../../presentational/BackgroundImageSettings/options";
import { MobileBackgroundVideoSizeOptions } from "../../../presentational/BackgroundVideoSettings/options";
import { getAsset } from "../../../../getters/backgroundGetters";
import * as mp from "../../../../mappers/path";
import style from './style.css';
import { MOBILE_EDITOR_ENABLE_EDIT_MODE } from "../../../MobileViewEditor/actionTypes";

type MobileMCTAProps = {
    dispatch: Dispatch;
    selectedComponent: BackgroundComponent
};

type Props = ComponentMainActionProps<BackgroundComponent, {
    missingAssetUrls: Array<string>;
    workspaceBBoxWidth: number;
    colorPickerOpened: boolean;
    themeSettingsData: ThemeSiteSettingsDataType,
    themeColorsData: ThemeColorDataType,
    children?: any,
}>;

const imagesRegex = new RegExp(FcContentTypes.IMAGE, 'i');

class MobileViewEditorV extends React.PureComponent<MobileMCTAProps> {
    render() {
        const
            { dispatch, selectedComponent: { mobileSettings }, selectedComponent } = this.props,
            assetData = getAsset(mp.styleBackground, selectedComponent),
            isImage = assetData && assetData.asset &&
                imagesRegex.test(assetData.asset.contentType),
            titleReposition = isImage ? "msg: mcta.section.mve.repositionImage {Reposition background image}"
                : "msg: mcta.section.mve.repositionVideo {Reposition background video}",
            sizeOptions = isImage ? MobileBackgroundImageSizeOptions : MobileBackgroundVideoSizeOptions;
        if (mobileSettings && mobileSettings.size && assetData) {
            return (
                <div>
                    <HorizontalSpacer x={5} />
                    <MctaPropertyContainer label="msg: common.fitting {Fitting}">
                        <MctaCombobox
                            className={style.mveMctaFillStyle}
                            searchable={false}
                            value={mobileSettings.size}
                            options={sizeOptions}
                            onChange={({ value }) =>
                                dispatch({ type: BG_MOBILE_IMAGE_SIZE_CHANGE_ACTION, payload: value })}
                        />
                    </MctaPropertyContainer>
                    <HorizontalSpacer x={5} />
                    {mobileSettings.size === BackgroundSize.FILL && <MctaButton
                        title={titleReposition}
                        icon="REPOSITION"
                        onMouseDown={e => e.stopPropagation()}
                        onClick={() => dispatch({
                            type: MOBILE_EDITOR_ENABLE_EDIT_MODE
                        })}
                    />}
                </div>
            );
        }
        return null;
    }
}

const
    makeBackgroundMctaEditView = ({ colorLabelForTheme, tooltipTextForTheme }) => ({
        selectedComponent,
        colorPickerOpened,
        themeSettingsData,
        themeColorsData,
        children,
        dispatch
    }: Props) => {
        const
            { selectedTheme } = selectedComponent,
            themeSelectedCorrected: ThemeBackgroundType = selectedTheme || BACKGROUND_THEME_WHITE,
            { autoColorMode } = themeSettingsData,
            { color, opacity } = getComponentColorGradientSettings(selectedComponent, autoColorMode),
            bgImageOpacity = getBackgroundImageOpacity(selectedComponent),
            mctaColorLabelForTheme = (<div className={mctaPropertyContainerStyles.labelContainer}>
                <Label className={mctaPropertyContainerStyles.label} style={{ fontWeight: 600 }}>{(colorLabelForTheme)}</Label>
            </div>),
            opacityChange = (opacity) => dispatch(bgSetBackgroundImageOpacityAC(opacity));

        return (
            <div>
                {
                    autoColorMode ?
                        <MctaColor
                            colorPickerKind={THEME_BG_OVERRIDE_COLOR_PICKER}
                            themeColorsData={themeColorsData}
                            backgroundTheme={themeSelectedCorrected}
                            opacity={opacity}
                            onColorChangeAction={BG_BACKGROUND_COLOR_SET_AUTO_COLOR}
                            colorPickerOpened={colorPickerOpened}
                            onOpacityChangeAction={BG_OPACITY_CHANGE_AUTO_COLOR}
                            dispatch={dispatch}
                            showOpacity={!isNumeric(bgImageOpacity)}
                            labelCmp={mctaColorLabelForTheme}
                            tooltipTop={tooltipTextForTheme}
                        /> :
                        <MctaColor
                            colorPickerKind={DEFAULT_COLOR_PICKER}
                            color={color}
                            opacity={opacity}
                            onColorChangeAction={BG_BACKGROUND_COLOR_SET}
                            colorPickerOpened={colorPickerOpened}
                            showOpacity={!isNumeric(bgImageOpacity)}
                            onOpacityChangeAction={BG_OPACITY_CHANGE}
                            onTheFlyChange
                            dispatch={dispatch}
                        />
                }
                {
                    isNumeric(bgImageOpacity) &&
                [
                    <HorizontalSpacer x={13} key={1} />,
                    <MctaVerticalBar key={2} />,
                    <HorizontalSpacer x={13} key={3} />,
                    <MctaOpacitySlider
                        opacity={bgImageOpacity}
                        onChange={opacityChange}
                        onAfterChange={opacityChange}
                        key={4}
                    />
                ]
                }
                { children && <HorizontalSpacer x={5} />}
                {children}
            </div>
        );
    };

type FactoryProps = {
    getEditButtonText: Function,
    colorLabelForTheme: string,
    tooltipTextForTheme: string,
    CtaButtonTextView?: Function,
    isCtaVisible?: Function,
    ctaOnClick?: Function
}

export default ({
    getEditButtonText,
    colorLabelForTheme,
    tooltipTextForTheme,
    CtaButtonTextView = () => null,
    isCtaVisible = () => false,
    ctaOnClick = () => null
}: FactoryProps) => ({
    editView: makeBackgroundMctaEditView({ colorLabelForTheme, tooltipTextForTheme }),
    mobileViewEditorV: (props: MobileMCTAProps) => <MobileViewEditorV {...props} />,
    getEditButtonText,
    ImageOpacityLabel,
    CtaButtonTextView,
    ctaOnClick,
    isCtaVisible

});
