import * as R from 'ramda';
import { getAllStylesheets } from '../../Workspace/epics/stylesheets/selectors';
import type { ButtonComponent } from './flowTypes';
import type { Stylesheets } from '../../Workspace/epics/stylesheets/flowTypes';

type DependsOn = {
    defaultStyleId: string,
    globalstyles: Stylesheets;
}

export default (component: ButtonComponent, { defaultStyleId, globalstyles }: DependsOn): ButtonComponent => {
    const
        globalId = R.path(['style', 'globalId'], component),
        hasMatchingGlobalStyle = R.pipe(getAllStylesheets, R.any(({ id }) => id === globalId))(globalstyles);

    return hasMatchingGlobalStyle
        ? component
        : R.evolve({ style: { globalId: R.always(defaultStyleId), text: { size: R.always(null) } } }, component);
};
