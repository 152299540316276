import * as OnClickActionOptions from '../../Gallery/constants/onClickActions';
import * as CaptionsAlignmentOptions from '../../Gallery/constants/captionAligments';
import * as CaptionsPlacementOptions from './captionsPlacement';
import * as ShowNextImageOptions from './ShowNextImage';
import * as TransitionOptions from './Transition';
import * as ShowControlsOptions from './IndicatorNavigator';
import { ComponentNames } from "../../constants";
import IMAGE_SLIDER from "../kind";

export const
    MIN_WIDTH = 128,
    MIN_HEIGHT = 64,
    DEFAULT_WIDTH = 300,
    DEFAULT_HEIGHT = 200,

    ImagePixelRatios = [1, 2, 3],

    label = ComponentNames[IMAGE_SLIDER],

    message = 'msg: fc.imageSliderMultiSelectValidationMessage {The Image Slider component only supports 100 images. Deselect other images before selecting new images.}', // eslint-disable-line

    Transition = TransitionOptions,

    ShowNavigator = ShowControlsOptions,

    ShowIndicator = ShowControlsOptions,

    ShowNextImage = ShowNextImageOptions,

    OnClickActions = OnClickActionOptions,

    CaptionsAlignment = CaptionsAlignmentOptions,

    CaptionsPlacement = CaptionsPlacementOptions,
    minSliderDimensions = {
        width: 50,
        height: 50
    },
    defaultState = {
        captionsEnabled: false,
        captionsAlignment: CaptionsAlignment.CENTER,
        captionsPlacement: CaptionsPlacement.BOTTOM,
        crop: true,
        delay: 5,
        autoNext: true,
        indicator: ShowNavigator.MOUSEOVER,
        navigator: ShowNavigator.MOUSEOVER,
        transition: Transition.SLIDE,
        images: [],
        onClickAction: OnClickActions.OPEN_LIGHT_BOX,
        fullWidthOption: {
            margin: 0,
            maxWidth: 3000,
            originalWidth: 0,
            originalLeft: null
        },
        stretch: false,
        mobileHide: false,
        mobileDown: false,
        originalSize: true
    };
