import ImageResource from "../../../redux/modules/children/fileChooser/ImageResource";
import * as onClickActions from './constants/onClickActions';
import * as constants from './constants/index';
import { getAssetUrl } from '../../../utils/assetUtils';
import getLinkAbsoluteUrl from '../../../view/common/dialogs/LinkChooserDialog/utils/getLinkAbsoluteUrl';
import type { A, GalleryComponent, GalleryImage, CaptionBoxStyle } from './flowTypes';
import { DataSite } from "../../../../dal/model/index";
import { CaptionStyles } from "../../presentational/CaptionStyle/constants";
import type { Stylesheets } from "../../Workspace/epics/stylesheets/flowTypes";
import { getFontName } from "../../presentational/AddGoogleFont/utils";
import { toHex } from "../../../mappers/color";
import { textHeading3Globalstyle, textNormalGlobalstyle } from "../../Workspace/epics/stylesheets/selectors";
import type { Color } from "../../../mappers/flowTypes";
import type { ThemeColorDataType, ThemeBackgroundType, ThemeColorTypes } from '../../ThemeGlobalData/flowTypes';
import { findSuitableTextColorName } from "../../ThemeGlobalData/utils/commonUtils";
import { getThemeRulesForBackground } from '../../ThemeGlobalData/themeRules';
import { captionDefaults } from './constants';
import type { CaptionStyleTypes } from '../../presentational/CaptionStyle/flowTypes';
import { replaceTagsWithContent } from "../Text/view/replaceTagsWithContent";
import { getQualityAssetUrlParam } from "../Image/utils";
import { getImageNameWithoutExtension } from "../../SortImagesDialog/utils";

type CalcAProps = {
    onClickAction: string,
    caption: string,
    componentId: string,
    index: number,
    image: GalleryImage,
    previewBackupTime?: number,
    globalVariables?: Record<string, any>,
    site: DataSite
}
type FontStyle = {
    color: Color,
    font: string|number,
    size: number,
    bold: boolean,
    underline: boolean,
    italic: boolean,
    lineHeight: number
};

export const

    /**
     * Finds a factor such that when the width and height are multiplied with it, the
     * new size will follow the constraints:
     * 1. Max size is not more then 16mp or whatever is global limit
     * 2. Either side is not more then sanity limit
     * 3. Either side is not more then original side (specified as maxW and maxH)
     * @public
     * @param {Number} w Width
     * @param {Number} h Height
     * @param {Number} maxW (optional) Max width
     * @param {Number} maxH (optional) Max height
     * @returns {Number} A value between 0 .. 1
     */
    getReduceFactor = (w: number, h: number, maxW: number, maxH: number) => {
        let
            maxPixels = constants.IMAGE_SCALE_MAX_PIXELS,
            maxSide = constants.IMAGE_MAX_DIMENSION - 1,
            newScale = 1;

        // Check for maxPixels limit
        if (w * h > maxPixels) {
            newScale = maxPixels / (w * h);
        }

        // Check for original size constraints
        if (maxW && w > maxW) {
            newScale = Math.min(newScale, maxW / w);
        }
        if (maxH && h > maxH) {
            newScale = Math.min(newScale, maxH / h);
        }

        // Check for either side limit constraint
        if (w > maxSide) {
            newScale = Math.min(newScale, maxSide / w);
        }
        if (h > maxSide) {
            newScale = Math.min(newScale, maxSide / h);
        }

        return newScale;
    },
    resizeForRetinaFn = function (width: number, height: number) {   // ONEWEB-8817, ONEWEB-8516, ONEWEB-6265
        let
            w = width,
            h = height;

        if (w >= h && w < constants.MINSIDE) {
            h = h * (constants.MINSIDE / w);
            w = constants.MINSIDE;
        } else if (h > w && h < constants.MINSIDE) {
            w = w * (constants.MINSIDE / h);
            h = constants.MINSIDE;
        }
        return { width: w, height: h };
    },
    getStepedDimensions = (
        width: number,
        height: number,
        step: number = constants.URL_REQUEST_WIDTH_STEP,
        ignoreAspectRatio: boolean = false
    ) => {
        const
            ratio = parseFloat((width / height).toFixed(1)),
            steppedWidth = width - (width % step) + step,
            steppedHeight = ignoreAspectRatio ? height - (height % step) + step : Math.round(steppedWidth / ratio);

        return { width: steppedWidth, height: steppedHeight };
    },
    makeCaptionStyle = (width: number) => ({
        width
    }),
    makeCaptionStylePadding = (style: Record<string, any> = {}) => ({
        paddingLeft: style.padding && style.padding.left ? style.padding.left : constants.Padding.DEFAULT_LEFT,
        paddingRight: style.padding && style.padding.right ? style.padding.right : constants.Padding.DEFAULT_RIGHT,
        paddingTop: style.padding && style.padding.top ? style.padding.top : constants.Padding.DEFAULT_TOP,
        paddingBottom: style.padding && style.padding.bottom ? style.padding.bottom : constants.Padding.DEFAULT_BOTTOM,
    }),

    calcNewImageDimensionForLightBoxSrcSet = (width: number, imageRatio: number): { width: number, resize: string } => {
        const
            newHeight = Math.round(width * imageRatio);
        return {
            resize: `${width},${newHeight}`,
            width
        };
    },

    // TODO: test ?
    calcA = ({
        onClickAction,
        componentId,
        index,
        image,
        previewBackupTime,
        globalVariables,
        site
    }: CalcAProps): A | null | undefined => {
        if (onClickAction === onClickActions.OPEN_LIGHT_BOX) {
            let
                imageQueryParam: Record<string, any> = {},
                srcSet,
                newImageDimensions: Array<{ width: number, resize: string }> = [],
                title = image.title || '';

            if (image.rotation) {
                imageQueryParam.rotate = image.rotation;
                imageQueryParam.ignoreAspectRatio = false;
            }

            let aProps = {
                href: getAssetUrl(image.asset, { ...imageQueryParam, ...getQualityAssetUrlParam(image.asset) }),
                title: replaceTagsWithContent(title, { globalVariables, site }),
                alt: replaceTagsWithContent(image.altText || title, { globalVariables, site }),
                caption: replaceTagsWithContent(image.caption, { globalVariables, site }) || '',
                rel: `lightbox[oneweb] ${componentId}`,
                className: "shinybox",
                'data-dom-index': index
            };

            const
                xLargeImageWidth = 2500,
                largeImageWidth = 2000,
                mediumImageWidth = 1500,
                smallImageWidth = 1000,
                //xSmallImageWidth = 750,
                imageRatio = image.asset.height / image.asset.width;
            if (image.asset.width > xLargeImageWidth) {
                newImageDimensions = [
                    calcNewImageDimensionForLightBoxSrcSet(xLargeImageWidth, imageRatio),
                    calcNewImageDimensionForLightBoxSrcSet(largeImageWidth, imageRatio),
                    calcNewImageDimensionForLightBoxSrcSet(mediumImageWidth, imageRatio),
                    calcNewImageDimensionForLightBoxSrcSet(smallImageWidth, imageRatio),
                    //calcNewImageDimensionForLightBoxSrcSet(xSmallImageWidth, imageRatio),
                ];
            } else if (image.asset.width > largeImageWidth) {
                newImageDimensions = [
                    calcNewImageDimensionForLightBoxSrcSet(largeImageWidth, imageRatio),
                    calcNewImageDimensionForLightBoxSrcSet(mediumImageWidth, imageRatio),
                    calcNewImageDimensionForLightBoxSrcSet(smallImageWidth, imageRatio),
                    //calcNewImageDimensionForLightBoxSrcSet(xSmallImageWidth, imageRatio),
                ];
            } else if (image.asset.width > mediumImageWidth) {
                newImageDimensions = [
                    calcNewImageDimensionForLightBoxSrcSet(mediumImageWidth, imageRatio),
                    calcNewImageDimensionForLightBoxSrcSet(smallImageWidth, imageRatio),
                    //calcNewImageDimensionForLightBoxSrcSet(xSmallImageWidth, imageRatio),
                ];
            } else if (image.asset.width > smallImageWidth) {
                newImageDimensions = [
                    calcNewImageDimensionForLightBoxSrcSet(smallImageWidth, imageRatio),
                    //calcNewImageDimensionForLightBoxSrcSet(xSmallImageWidth, imageRatio),
                ];
            }

            srcSet = newImageDimensions.map((newImageDimension) => {
                return getAssetUrl(
                    image.asset,
                    {
                        ...imageQueryParam,
                        ignoreAspectRatio: !image.rotation,
                        resize: newImageDimension.resize,
                        ...getQualityAssetUrlParam(image.asset)
                    }
                ) + " " + newImageDimension.width + "w";
            });

            if (srcSet.length) {
                return {
                    ...aProps,
                    href: srcSet[0].split(' ')[0],
                    'data-srcset': srcSet.join(', ')
                };
            }

            return aProps;
        } else if (onClickAction === onClickActions.OPEN_LINK && image.action && image.action.link) {
            return {
                href: getLinkAbsoluteUrl(image.action.link, site, previewBackupTime) as string,
                target: image.action && image.action.openInNewWindow ? '_blank' : '_self',
                title: image.title || '',
                alt: image.altText || '',
                sectionid: image.action.link.value && image.action.link.value.sectionId,
            };
        }
        return null;
    },
    getFontStyle = ({ color, size: fontSize, font, bold, italic, underline, lineHeight }: FontStyle) => {
        return {
            color: toHex(color),
            fontFamily: getFontName(font.toString()),
            fontSize,
            fontWeight: bold ? 'bold' : 'normal',
            textDecoration: underline ? 'underline' : 'none',
            fontStyle: italic ? 'italic' : 'normal',
            lineHeight,
            textShadow: 'none'
        };
    },
    getTitleClassicStyle = (autoColorMode: boolean, themeColorsData: ThemeColorDataType) => ({
        ...constants.titleDefaultStyle,
        ...(autoColorMode ? { color: (themeColorsData[constants.titleDefaultStyle.themeColor] as Color) } : {}),
        //color // inherit color from global
    }),
    getDescriptionClassicStyle = (autoColorMode: boolean, themeColorsData: ThemeColorDataType) => ({
        ...constants.descriptionDefaultStyle,
        ...(autoColorMode ? { color: (themeColorsData[constants.titleDefaultStyle.themeColor] as Color) } : {}),
        //color // inherit color from global
    }),
    getTitleGlobalStyle = (globalStyles: Stylesheets, autoColorMode: boolean, themeColorsData, selectedParentTheme) => {
        let autoColorModeData = {};
        if (autoColorMode) {
            const themeColor = findSuitableTextColorName(
                getThemeRulesForBackground(selectedParentTheme, themeColorsData).background, themeColorsData
            );
            autoColorModeData = { themeColor, color: themeColorsData[themeColor] as Color };
        }
        return {
            ...textHeading3Globalstyle(globalStyles),
            ...autoColorModeData,
            bold: true,
            size: 15 // title template style is always be bold and size 15px
        };
    },
    getDescriptionGlobalStyle = (globalStyles: Stylesheets, autoColorMode: boolean, themeColorsData, selectedParentTheme) => {
        let autoColorModeData = {};
        if (autoColorMode) {
            const themeColor = findSuitableTextColorName(
                getThemeRulesForBackground(selectedParentTheme, themeColorsData).background, themeColorsData
            );
            autoColorModeData = { themeColor, color: themeColorsData[themeColor] };
        }
        return {
            ...textNormalGlobalstyle(globalStyles),
            ...autoColorModeData,
            size: 13 // description template style is always 13px
        };
    },
    getDefaultCaptionStyle = (
        component: GalleryComponent,
        globalStyle: FontStyle,
        classicStyle: FontStyle & { themeColor: ThemeColorTypes }
    ): Record<string, any> => {
        const
            isPrevCaptionStyleTemplate = component.previousCaptionStyle === CaptionStyles.TEMPLATE;

        return {
            ...(isPrevCaptionStyleTemplate ? globalStyle : classicStyle)
        };
    },
    getCaptionFontStyle = (
        component: GalleryComponent,
        globalStyles: Stylesheets,
        themeColorsData: ThemeColorDataType,
        autoColorMode: boolean,
        selectedParentTheme: ThemeBackgroundType
    ) => {
        const
            templateTitleStyle = getTitleGlobalStyle(globalStyles, autoColorMode, themeColorsData, selectedParentTheme),
            templateDescriptionStyle = getDescriptionGlobalStyle(globalStyles, autoColorMode, themeColorsData, selectedParentTheme),
            classicTitleStyle = getTitleClassicStyle(autoColorMode, themeColorsData), // classic title style
            classicDescriptionStyle = getDescriptionClassicStyle(autoColorMode, themeColorsData), // classic desc style
            customTitleDefaultStyle = getDefaultCaptionStyle(
                component,
                templateTitleStyle,
                classicTitleStyle
            ),
            customDescriptionDefaultStyle = getDefaultCaptionStyle(
                component,
                templateDescriptionStyle,
                classicDescriptionStyle
            );
        let
            captionTitleTextStyle: {},
            captionDescriptionTextStyle: {};

        switch (component.captionStyle) {
            case CaptionStyles.TEMPLATE:
                captionTitleTextStyle = getFontStyle(templateTitleStyle);
                captionDescriptionTextStyle = getFontStyle(templateDescriptionStyle);

                break;
            case CaptionStyles.CUSTOM:
                captionTitleTextStyle = getFontStyle({
                    ...customTitleDefaultStyle,
                    ...component.captionTitleTextStyle,
                    color: (
                        autoColorMode
                            ? themeColorsData[component.captionTitleTextStyle.themeColor || customTitleDefaultStyle.themeColor]
                            : (component.captionTitleTextStyle.color || customTitleDefaultStyle.color)
                    ),
                });
                captionDescriptionTextStyle = getFontStyle({
                    ...customDescriptionDefaultStyle,
                    ...component.captionDescriptionTextStyle,
                    color: (
                        autoColorMode
                            ? themeColorsData[component.captionDescriptionTextStyle.themeColor || customDescriptionDefaultStyle.themeColor]
                            : (component.captionDescriptionTextStyle.color || customDescriptionDefaultStyle.color)
                    ),
                });

                break;
            default:
                captionTitleTextStyle = getFontStyle(classicTitleStyle);
                captionDescriptionTextStyle = getFontStyle(classicDescriptionStyle);
        }

        return {
            captionTitleTextStyle,
            captionDescriptionTextStyle
        };
    },
    getCaptionFontStyleSettings = (style: FontStyle, defaultStyle: FontStyle) => ({
        ...defaultStyle,
        ...style,
        font: getFontName((style && style.font ? style.font : defaultStyle.font).toString()),
    }),
    getCaptionBoxBorderColorForTheme = (
        captionStyle: CaptionStyleTypes,
        previousCaptionStyle: CaptionStyleTypes,
        captionBorderObj: CaptionBoxStyle['border'],
        selectedParentTheme: ThemeBackgroundType,
        themeColorsData: ThemeColorDataType
    ) => {
        if (captionStyle === CaptionStyles.CUSTOM) {
            if (captionBorderObj && captionBorderObj.themeColor) {
                return captionBorderObj.themeColor;
            } else if (previousCaptionStyle === CaptionStyles.CLASSIC) {
                return findSuitableTextColorName(
                    // @ts-ignore
                    captionDefaults.box[CaptionStyles.CLASSIC].background?.colorData.themeColor, themeColorsData
                );
            } else {
                return getThemeRulesForBackground(selectedParentTheme, themeColorsData).text;
            }
        } else if (captionStyle === CaptionStyles.TEMPLATE) {
            return getThemeRulesForBackground(selectedParentTheme, themeColorsData).text;
        } else { // CaptionStyles.CLASSIC
            return captionDefaults.box[CaptionStyles.CLASSIC].background?.colorData.themeColor;
        }
    },
    makeImageAdjustmentDataKey = (imageIndex: number) => `caption-height-of-image-${imageIndex}`,
    getFormattedAssets = assets => assets.map((asset: ImageResource) => ({
        // @ts-ignore
        title: (getImageNameWithoutExtension(asset.url) || '').substr(0, constants.LIMIT.title.hard),
        altText: '',
        caption: '',
        action: null,
        asset
    }));
