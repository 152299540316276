import React from 'react';
import Slider from '../../../view/common/Slider/PPSlider';
import {
    DEFAULT_SPACING,
    MIN_CHARACTER_SPACING,
    MAX_CHARACTER_SPACING,
    MIN_LINE_HEIGHT,
    MAX_LINE_HEIGHT,
    LINE_HEIGHT_STEP
} from './constants';
import VerticalSpacer from "../../../view/common/VerticalSpacer";

import type { CharacterSpacingPropTypes } from './flowTypes';

const CharacterSpacingView = ({
    spacing = DEFAULT_SPACING,
    spacingChangeAction,
    minCharSpacing = MIN_CHARACTER_SPACING,
    maxCharSpacing = MAX_CHARACTER_SPACING,
    lineHeight = 1,
    lineHeightChangeAction,
    minLineHeight = MIN_LINE_HEIGHT,
    maxLineHeight = MAX_LINE_HEIGHT,
    dispatch
}: CharacterSpacingPropTypes) => (
    <div>
        <Slider
            label="msg: common.characterSpacingWithColon {Character spacing:}"
            min={minCharSpacing}
            max={maxCharSpacing}
            value={spacing || DEFAULT_SPACING}
            valueLabel={`${spacing || DEFAULT_SPACING}`}
            onChange={spacing => dispatch({ type: spacingChangeAction, payload: spacing, amendToSelf: true })}
        />
        <VerticalSpacer y={30} />
        <Slider
            label="msg: text.pp.lineSpacing {Line spacing:}"
            valueLabel={`${lineHeight}`}
            value={lineHeight}
            min={minLineHeight}
            max={maxLineHeight}
            step={LINE_HEIGHT_STEP}
            onChange={spacing => dispatch({ type: lineHeightChangeAction, payload: spacing, amendToSelf: true })}
        />
    </div>
);

export {
    CharacterSpacingView as default,
    CharacterSpacingView,
};
