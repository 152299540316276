import { assocPath } from "ramda";
import { getThemeRulesForBackground } from "../../ThemeGlobalData/themeRules";
import * as colorMapper from "../../../mappers/color";
import type { OpeningHpoursCalcRenderProps } from "./flowTypes";

export const openingHoursCalcRenderProps: OpeningHpoursCalcRenderProps = ({
    component,
    componentDependencies,
    isWorkspace,
    previewBackupTime,
    selectedParentTheme
}) => {
    const { site, stylesheets, themeColorsData, themeSettingsData, globalVariables } = componentDependencies;
    const {
        textStyle: { themeColor, color }
    } = component;
    let transformedComponent = component;

    if (themeSettingsData.autoColorMode) {
        transformedComponent = assocPath(
            ["textStyle", "color"],
            colorMapper.toCss(
                themeColor
                    ? themeColorsData[themeColor] // @ts-ignore WBTGEN-16368: selectedParentTheme won't be null when autoColorMode is true
                    : themeColorsData[getThemeRulesForBackground(selectedParentTheme, themeColorsData).text]
            ),
            transformedComponent
        );
    } else if (color) {
        transformedComponent = assocPath(["textStyle", "color"], colorMapper.toCss(color), transformedComponent);
    }

    return {
        component: transformedComponent,
        isWorkspace,
        previewBackupTime,
        site,
        stylesheets,
        selectedParentTheme,
        themeColorsData,
        themeSettingsData,
        globalVariables
    };
};
