/* eslint-disable max-len */

import * as R from 'ramda';
import isStretchComponentKind from '../../../oneweb/isStretchComponentKind';
import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly, optional } from '../../../../epics/makeCondition';
import { SelectionFrameBorderWidth, SelectionFrameMargin } from '../../../../constants/app';
import browserDimensionsValueActionType from '../../../App/epics/browserDimensions/valueActionType';
import scrollValueActionType from '../scroll/valueActionType';
import workspaceBBoxValueActionType from '../workspaceBBox/valueActionType';
import { IDLE, COMPONENT_CHANGING, MOUSE_DOWN_ON_HANDLE } from '../componentsEval/userInteractionMutations/interactionModes';
import {
    SelectedComponentSelector,
    UserInteractionModeSelectedComponentNoGhostsSelector,
    SelectedComponentDepsSelector,
    ReceiveOnlyComponentsMap
} from '../componentsEval/selectorActionTypes';
import valueActionType from './valueActionType';
import { keepMinBBox, minWidth } from "../../../../utils/handle/factory";
import { isBottomResizingHandle, isResizingHandle, isTopResizingHandle } from "../../../../utils/handle/index";
import { getComponentBBox, zeroBBox } from "../../../../utils/componentsMap/index";
import { workspaceHandlesValueActionType } from "../handles/valueActionType";
import styles from '../../../../view/Workspace/Decorations/ComponentMainActions/ComponentMainActions.css';
import topBarStyles from '../../../TopBar/view/TopBar.css';
import {
    controlsDependenciesForSelectedComponentValueActionType
} from "../controlsDependenciesForSelectedComponent/valueActionType";
import registry from '../../../../view/oneweb/registry/index';
import { MCTA_RESIZED } from '../../../../view/Workspace/Decorations/ComponentMainActions/actionTypes';
import CodeComponentsValueActionType from "../../CodeComponentsRenderer/epic/valueActionType";
import { PropertiesPanalActivePageIdSelector } from '../../../PropertiesPanel/epic/selectorActionTypes';

import type { ComponentMainActionsEpicState } from './flowTypes';
import type { BBox } from "../../../App/flowTypes";
import type { Handles } from "../../../../redux/modules/children/workspace/flowTypes";
import type { ScrollEpicState } from "../scroll/flowTypes";
import { isSectionComponent } from "../../../oneweb/Section/utils";
import { selectedComponentIsInsideHeaderOrFooterVAT } from "../selectedComponentIsInsideHeaderOrFooterEpic/valueActionType";
import { valueActionType as blankHeaderFooterSelectedVAT } from "../../../oneweb/Section/epics/blankHeaderFooter/blankHeaderFooterSelectedEpic";
import { isHoverBoxKind } from "../../../oneweb/componentKinds";
import { ReceiveOnlyAttachments } from "../componentAttachements/selectorActionTypes";
import { getNearestParentHoverBoxCmp } from "../../../oneweb/HoverBox/utils";
import { isModernLayoutActivatedVAT } from "../isModernLayoutActivatedEpic/valueActionType";
import { iconForAlignmentTypeMap, MCTA_ALIGNMENT_ICON_HEIGHT } from "../../../componentMainActions/constants";

const windowScrollWidth = 20;
export const
    MCTA_GAP_HOVERBOX = 32,
    SECONDARY_MCTA_HEIGHT = parseInt(styles.SECONDARY_MCTA_HEIGHT, 10),
    TOP_BAR_HEIGHT = parseInt(topBarStyles.topBarHeight, 10),
    MCTA_BOTTOM_GAP = 8,
    SelectionFrameWidth = SelectionFrameBorderWidth + SelectionFrameMargin,
    MIN_TOP_DISTANCE = 0,
    mctaGapFromTop = MCTA_BOTTOM_GAP + SelectionFrameWidth,
    mctaGapFromTopHandles = SelectionFrameWidth,
    ABOVE_MCTA_POS = 'ABOVE_MCTA_POS',
    BELOW_MCTA_POS = 'BELOW_MCTA_POS',
    TOP_FROM_COMPONENT = 'TOP_FROM_COMPONENT',
    TOP_FROM_HANDLE = 'TOP_FROM_HANDLE',
    mctaTopGetters = {
        [TOP_FROM_HANDLE]: {
            [ABOVE_MCTA_POS]: (handles: Handles, componentBBox: BBox, extraSpacing: number = 0) => { // eslint-disable-line
                return adjustMCTATopAboveHandles(handles.filter(handle => isTopResizingHandle(handle.kind))[0].minY);
            },
            [BELOW_MCTA_POS]: (handles: Handles, componentBBox: BBox, extraSpacing: number = 0) => {// eslint-disable-line
                return adjustMCTATopBelowHandles(handles.filter(handle => isBottomResizingHandle(handle.kind))[0].maxY);
            }
        },
        [TOP_FROM_COMPONENT]: {
            [ABOVE_MCTA_POS]: (handles: Handles, componentBBox: BBox, extraSpacing: number = 0) => {
                return adjustMCTATopAboveComponent(componentBBox.top, extraSpacing);
            },
            [BELOW_MCTA_POS]: (handles: Handles, componentBBox: BBox, extraSpacing: number = 0) => {// eslint-disable-line
                return adjustMCTATopBelowComponent(componentBBox.bottom);
            }
        }
    };

function adjustMCTATopAboveHandles(originTop) {
    return originTop - mctaGapFromTopHandles - SECONDARY_MCTA_HEIGHT;
}

function adjustMCTATopBelowHandles(originTop) {
    return originTop + mctaGapFromTopHandles;
}

function adjustMCTATopAboveComponent(originTop, extraSpacing) {
    return originTop - mctaGapFromTop - SECONDARY_MCTA_HEIGHT - extraSpacing;
}

function adjustMCTATopBelowComponent(originTop) {
    return originTop + mctaGapFromTop;
}

export function createGetMCTAPosition(constant: number) {
    return (originTop: number, scroll: ScrollEpicState) => {
        if (originTop <= constant || scroll.y > originTop) {
            return BELOW_MCTA_POS;
        }
        return ABOVE_MCTA_POS;
    };
}

const getMCTAPosition = createGetMCTAPosition(MIN_TOP_DISTANCE);

const resizeHandleOriginAndConditions = [
    (resizingHandles) => resizingHandles.length > 0,
    (handles, component) => component.height < minWidth
];

const hasEnoughSpaceToRenderMctaBelow = (browserDimensions, scroll, componentBBox) => {
    return componentBBox.bottom < scroll.y + browserDimensions.height - 2 * TOP_BAR_HEIGHT;
};

const shouldSendMctaBelowForHoverboxChild = (component, componentsMap, attachments, MCTATopAboveComponent) => {
    if (component.hasOwnProperty('onHover')) {
        const hoverBoxId = getNearestParentHoverBoxCmp(component.id, attachments, componentsMap),
            parentHoverBox = hoverBoxId ? componentsMap[hoverBoxId] : null;
        return parentHoverBox && (parentHoverBox.top > MCTATopAboveComponent);
    }
    return false;
};

function getOrigin(resizingHandles, component) {
    const conditionsMet = R.all(condition => condition(resizingHandles, component))(resizeHandleOriginAndConditions);
    return conditionsMet ? TOP_FROM_HANDLE : TOP_FROM_COMPONENT;
}

export function placeMctaOverComponent(mctaTop: number, componentBBox: BBox, scroll: ScrollEpicState, browserDimensions: any) {
    if ((componentBBox.top - TOP_BAR_HEIGHT) < scroll.y && (componentBBox.bottom + (2 * TOP_BAR_HEIGHT)) > (scroll.y + browserDimensions.height)) {
        return scroll.y + mctaGapFromTop;
    }

    return mctaTop;
}

const
    defaultState: ComponentMainActionsEpicState = {
        show: false,
        // @ts-ignore
        selectedComponentId: null,
        selectedComponent: null,
        dependencies: null,
        controlsDependenciesForSelectedComponent: null,
        wasHidden: false,
        propertiesPanelPageId: null,
        selectedComponentIsInsideHeaderOrFooter: false,
        isModernLayoutActive: false
    },
    getMctaBBox = (
        component,
        scroll,
        handles,
        mctaWidth,
        workspaceBBox,
        browserDimensions,
        totalHeightOfCodeComponentsPlacedBeforeHead,
        componentsMap,
        attachments
    ) => {
        const
            extraGapOnTop = isHoverBoxKind(component.kind) ? MCTA_GAP_HOVERBOX : 0,
            resizingHandles = R.filter(R.pipe(R.prop('kind'), isResizingHandle))(handles),
            origin = getOrigin(resizingHandles, component),
            componentBBox = getComponentBBox(component, workspaceBBox),
            alignMentOptions = (iconForAlignmentTypeMap[component.kind] && Object.keys(iconForAlignmentTypeMap[component.kind]).length) || 2,
            MCTATopAboveComponent = mctaTopGetters[origin][ABOVE_MCTA_POS](resizingHandles, componentBBox, extraGapOnTop) + totalHeightOfCodeComponentsPlacedBeforeHead,
            sendBelowBasedOnSpecificChecks = shouldSendMctaBelowForHoverboxChild(component, componentsMap, attachments, MCTATopAboveComponent) &&
                                            hasEnoughSpaceToRenderMctaBelow(browserDimensions, scroll, componentBBox),
            MCTAPosition = sendBelowBasedOnSpecificChecks ? BELOW_MCTA_POS : getMCTAPosition(MCTATopAboveComponent, scroll),
            mctaTop = mctaTopGetters[origin][MCTAPosition](resizingHandles, componentBBox, extraGapOnTop),
            calcMctaTop = placeMctaOverComponent(mctaTop, componentBBox, scroll, browserDimensions),
            // Showing MCTA at top/bottom depends on alignment options for that particular kind of component
            finalMctaTop = calcMctaTop < (MCTA_ALIGNMENT_ICON_HEIGHT * (alignMentOptions / 2)) ? (componentBBox.bottom + 10) : calcMctaTop;

        const mctaLeft = isStretchComponentKind(component.kind, component.stretch) ? 0 : keepMinBBox(getComponentBBox(component, zeroBBox)).left,
            //Find out delta of the component left with repect to scroll of the workspace.
            // If negative that means component is visible and if not then it is either hidden completly or partially
            mctaLeftDelta = scroll.x - (mctaLeft + Math.abs(-workspaceBBox.left)),
            //If greater then we have to show mcta at the visible part of the component and if its completly hidden then show at the end of the component.
            mctaLeftAfterDeltaAdjustment = mctaLeftDelta > 0 ? mctaLeft + Math.min(mctaLeftDelta, component.width) : mctaLeft,
            finalLeft = mctaLeftAfterDeltaAdjustment + mctaWidth + windowScrollWidth > workspaceBBox.right - scroll.x
                ? component.left + component.width - mctaWidth
                : mctaLeftAfterDeltaAdjustment,
            finalRight = finalLeft + mctaWidth;

        return {
            top: finalMctaTop,
            right: finalRight,
            bottom: (mctaTop + SECONDARY_MCTA_HEIGHT),
            left: isStretchComponentKind(component.kind, component.stretch) && scroll.x === 0 ? 0 : finalLeft,
            topAboveComponent: MCTATopAboveComponent,
        };
    },
    getSelectedComponentIfMCTADependsOnIt = (component) => {
        if (!component) {
            return null;
        }

        const record = registry[component.kind];

        if (record.componentMainActionsIsNotDependentOnSelectedComponent) {
            return null;
        }
        return component;
    },
    epic = makeEpic({
        defaultState,
        valueActionType,
        updaters: [
            {
                conditions: [SelectedComponentDepsSelector],
                reducer: ({ values: [dependencies], state, scope }) => {
                    return { state: dependencies ? { ...state, dependencies } : defaultState, scope };
                }
            },
            {
                conditions: [SelectedComponentSelector],
                reducer: ({ state, scope, values: [selectedComponent] }) => {
                    if (state.show) {
                        return {
                            state: {
                                ...state,
                                selectedComponent
                            },
                            scope
                        };
                    }

                    return { state, scope };
                }
            },
            {
                conditions: [
                    receiveOnly(workspaceBBoxValueActionType),
                    receiveOnly(workspaceHandlesValueActionType),
                    receiveOnly(browserDimensionsValueActionType),
                    scrollValueActionType,
                    UserInteractionModeSelectedComponentNoGhostsSelector,
                    receiveOnly(CodeComponentsValueActionType),
                    ReceiveOnlyComponentsMap,
                    ReceiveOnlyAttachments,
                    optional(MCTA_RESIZED),
                ],
                reducer: ({
                    values: [
                        workspaceBBox,
                        handles,
                        browserDimensions,
                        scroll,
                        { selectedComponentNotGhost, mode },
                        { heights: codeComponentsHeights },
                        componentsMap,
                        attachments,
                        mctaDimensions = { width: 0, height: 0 }
                    ],
                    state: prevState,
                    scope
                }) => {
                    if (!selectedComponentNotGhost) {
                        return { state: defaultState };
                    }

                    const
                        isSectionCmp = isSectionComponent(selectedComponentNotGhost),
                        totalHeightOfCodeComponentsPlacedBeforeHead = codeComponentsHeights.headTemplate + codeComponentsHeights.headPage,
                        // Selected component will not be presented for components that is not dependent on them
                        // For ex Text component is not depends on it, and MCTA should not be rerendered during typing.
                        selectedComponent = getSelectedComponentIfMCTADependsOnIt(selectedComponentNotGhost);
                    const updatePosition = (state) => {
                        const { top, right, bottom, left, topAboveComponent } =
                            getMctaBBox(
                                selectedComponentNotGhost,
                                scroll,
                                handles,
                                mctaDimensions.width,
                                workspaceBBox,
                                browserDimensions,
                                totalHeightOfCodeComponentsPlacedBeforeHead,
                                componentsMap,
                                attachments
                            );

                        return {
                            ...state,
                            mctaTop: top,
                            mctaRight: right,
                            mctaBottom: bottom,
                            mctaLeft: left,
                            topAboveComponent,
                        };
                    };

                    let state = {
                        ...prevState,
                        show: mode === IDLE || mode === COMPONENT_CHANGING || (isSectionCmp && mode === MOUSE_DOWN_ON_HANDLE),
                        selectedComponentIdNotGhost: selectedComponentNotGhost.id,
                        selectedComponentNotGhost,
                        selectedComponent,
                        selectedComponentKind: selectedComponentNotGhost.kind
                    };

                    if (mode === IDLE) {
                        state = updatePosition(state);
                    }

                    return {
                        state,
                        scope
                    };
                }
            },
            {
                conditions: [
                    controlsDependenciesForSelectedComponentValueActionType
                ],
                reducer: ({ values: [controlsDependenciesForSelectedComponent], state }) => {
                    return {
                        state: { ...state, controlsDependenciesForSelectedComponent }
                    };
                }
            },
            {
                conditions: [
                    selectedComponentIsInsideHeaderOrFooterVAT,
                ],
                reducer: ({
                    values: [{ isInsideHeaderOrFooter: selectedComponentIsInsideHeaderOrFooter }], state
                }) => {
                    return {
                        state: { ...state, selectedComponentIsInsideHeaderOrFooter }
                    };
                }
            },
            {
                conditions: [
                    isModernLayoutActivatedVAT,
                ],
                reducer: ({
                    values: [isModernLayoutActivated], state
                }) => {
                    return {
                        state: { ...state, isModernLayoutActive: isModernLayoutActivated }
                    };
                }
            },
            {
                conditions: [
                    blankHeaderFooterSelectedVAT
                ],
                reducer: ({ values: [{ isHeaderBlank, isFooterBlank }], state }) => {
                    return {
                        state: {
                            ...state,
                            isHeaderBlank,
                            isFooterBlank
                        }
                    };
                }
            },
            {
                conditions: [PropertiesPanalActivePageIdSelector],
                reducer: ({ values: [propertiesPanelPageId], state }) => {
                    if (state.propertiesPanelPageId === propertiesPanelPageId) return { state };

                    return { state: { ...state, propertiesPanelPageId } };
                }
            }
        ]
    });

export {
    epic as default
};
