import mapOldLinksToNew from "./mapOldLinksToNew";
import fixFallBacksForOldFonts from "./fixFallBacksForOldFonts";
import fixWrappedItemFontSize from './fixWrappedItemFontSize';
import removeStylesFromSubSup from './removeStylesFromSubSup';
import { DataSite } from "../../../../utils/htmlWriter/flowTypes";
import { linkedValueTag, linkedValueAttr } from "../constants";
import { tinyMceDomParser, tinyMceHtmlSerializer } from "../editorSetup";
import { customSendReport } from '../../../../customSendCrashReport';
import { transformToTinyMceCompatibleHtml } from './transformToTinyMceCompatibleHtml';
import { insertBrIntoEmptyParagraphs } from '../insertBrIntoEmptyParagraphs';

const areEltSameAttributeComparisonSkipList = ['id'];
const areEltSame = (dbElt: HTMLElement, tmceElt: HTMLElement, maxComparisons: number): [boolean, number] => {
    const
        dbEltChildren = dbElt.children,
        dbEltChildLen = dbEltChildren.length,
        tmceEltChildren = tmceElt.children,
        tmceEltChildLen = tmceEltChildren.length,
        attributes = dbElt.attributes,
        attributesLen = attributes.length;

    if (maxComparisons <= 0) {
        return [true, maxComparisons]; // skip, as the child tree is too deep
    }
    if (dbElt.tagName !== tmceElt.tagName) {
        return [false, maxComparisons];
    }
    if (dbEltChildLen !== tmceEltChildLen) {
        return [false, maxComparisons];
    }
    // tmceElt will not have additional attributes of relevance, so just one way comparison should do
    for (let i = 0; i < attributesLen; i++) {
        const { name, value } = attributes[i];
        if (!areEltSameAttributeComparisonSkipList.includes(name) && value !== tmceElt.getAttribute(name)) {
            return [false, maxComparisons];
        }
    }

    for (let i = 0; i < dbEltChildLen; i++) {
        // @ts-ignore
        const [areSame, remainingComparisons] = areEltSame(dbEltChildren[i], tmceEltChildren[i], maxComparisons);
        maxComparisons = remainingComparisons; // eslint-disable-line no-param-reassign
        if (!areSame) {
            return [false, maxComparisons];
        }
    }
    return [true, maxComparisons];
};

export default (content: string, site: DataSite): string => {
    const pseudoDom = document.createElement('div');
    pseudoDom.innerHTML = content;
    mapOldLinksToNew(pseudoDom, site);
    fixFallBacksForOldFonts(pseudoDom);
    fixWrappedItemFontSize(pseudoDom);
    removeStylesFromSubSup(pseudoDom);
    transformToTinyMceCompatibleHtml(pseudoDom);

    // BEGIN: Text/TinyMce invalid data error reporting
    const tmceSanitizedDom = document.createElement('div');
    tmceSanitizedDom.innerHTML = tinyMceHtmlSerializer.serialize(tinyMceDomParser.parse(pseudoDom.innerHTML));
    const [areSame] = areEltSame(pseudoDom, tmceSanitizedDom, 1000);
    if (!areSame) {
        customSendReport({
            message: `Invalid Text Component Data Found`,
            additionalInfo: { html: pseudoDom.innerHTML.slice(1000) },
        });
    }
    // END: Text/TinyMce invalid data error reporting

    pseudoDom.innerHTML = insertBrIntoEmptyParagraphs(pseudoDom.innerHTML);

    ((pseudoDom) => {
        Array.from(pseudoDom.querySelectorAll('span[data-linked-value]')).forEach((oldElt) => {
            const linkedValue = oldElt.getAttribute('data-linked-value');
            if (linkedValue) {
                const newElt = document.createElement(linkedValueTag);
                newElt.setAttribute(linkedValueAttr, linkedValue);
                oldElt.removeAttribute('data-linked-value');
                // @ts-ignore
                newElt.innerText = oldElt.innerText;

                if (oldElt.attributes.length) {
                    oldElt.innerHTML = ''; // eslint-disable-line
                    oldElt.append(newElt);
                } else if (oldElt.parentElement) {
                    oldElt.parentElement.insertBefore(newElt, oldElt);
                    oldElt.remove();
                }
            } else {
                oldElt.remove();
            }
        });
    })(pseudoDom);

    return pseudoDom.innerHTML;
};
