import React from "react";
import { pure } from 'recompose';
import { Border } from "../../../components/presentational/Border/Border";
import type {
    ComponentsSelectionDecorationState
} from '../../../components/Workspace/epics/componentsSelectionDecoration/flowTypes';
import TopShiftBar from './ShiftBar/Top';
import BottomShiftBar from './ShiftBar/Bottom';
import type { Handles, Handle } from '../../../redux/modules/children/workspace/flowTypes';
import {
    MOUSE_DOWN_ON_HANDLE,
    RESIZE_HANDLE_MOVING, SHIFTBAR_MOVING
} from "../../../components/Workspace/epics/componentsEval/userInteractionMutations/interactionModes";
import { isShiftBarBottom, isShiftBarTop } from "../../../utils/handle/index";
import { makeBorderProps } from "./makeBorderProps";
import { red260, red270 } from '../../../components/variables.css';

type Props = {
    state: ComponentsSelectionDecorationState,
    handles: Handles,
    topMostHandle: Handle,
    interactionMode: string,
    userInteractionHandleKind: string,
    dontShowHandles: boolean
}

const hideShiftBarInteractionModes = [RESIZE_HANDLE_MOVING],
    hideOthersInteractionModes = [MOUSE_DOWN_ON_HANDLE, SHIFTBAR_MOVING];

export default pure(({
    state, state: { bBox, componentsBBoxes, isBorderVisible,
        selectionShiftBarVisible, containsSection }, handles, userInteractionHandleKind, dontShowHandles, interactionMode
}: Props) => {
    const hideOthers = containsSection ? interactionMode === SHIFTBAR_MOVING : hideOthersInteractionModes.includes(interactionMode),
        hideTop = hideOthers && !isShiftBarTop(userInteractionHandleKind),
        hideBottom = hideOthers && !isShiftBarBottom(userInteractionHandleKind),
        topVisible = !hideTop && selectionShiftBarVisible && !dontShowHandles,
        bottomVisible = !hideBottom && selectionShiftBarVisible && !dontShowHandles,
        borderProps = makeBorderProps({
            bBox,
            opacity: isBorderVisible ? 1 : 0,
            color: red260,
            thickBorder: containsSection
        });

    return (<div>
        <Border {...borderProps} />
        {
            componentsBBoxes.map((bBox, i) =>
                <Border key={i} {...makeBorderProps({ bBox, color: red270 })} />)
        }
        {!hideShiftBarInteractionModes.includes(interactionMode) && <div>
            {topVisible && <TopShiftBar state={state} handles={handles} />}
            {bottomVisible && <BottomShiftBar state={state} handles={handles} />}
        </div>}
    </div>);
});
