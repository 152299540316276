import React, { useEffect, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { PlanSelectionView } from "./PlanSelectionView";
import type { SubscriptionTypesProps } from "../types";
import { filterAvailableUpgrades } from './utils';
import common from "../../../../../../../wbtgen/src/view/Theme/common.css";
import { Slider } from '../../../Slider/Slider';
import { Stepper } from './stepper';
import { FullTable } from './FullTable';
import { useIsMobile, useIsTablet } from '../../../../hooks/useIsMobileOrTablet';
import { SubscriptionType } from "../../../../../../../server/shared/subscriptionStatus/constants";
import {
    isEcommerceSubscription
} from "../../../../../../../wbtgen/src/components/App/epics/subscriptionData/utils";
import VerticalSpacer from "../../../../../../../wbtgen/src/view/common/VerticalSpacer";
import { useIntl } from "../../../../../../../wbtgen/src/view/intl";

const SUBSCRIPTION_PLANS = [
    SubscriptionType.ECOMMERCE,
    SubscriptionType.PREMIUM,
    SubscriptionType.NORMAL,
    SubscriptionType.BASIC,
];

const useStyles = makeStyles(
    (theme) => ({
        plan: {
            borderLeft: "none",
        },
        recommendedPlan: {
            border: `2px solid ${common.colorZundaGreen}`
        },
        stepByStepGuide: {
            fontSize: "14px",
            lineHeight: "22px"
        },
        link: {
            color: theme.palette.primary.main,
            cursor: 'pointer',
        },
        compareAll: {
            marginTop: "72px",
            fontSize: "24px",
            lineHeight: "30px",
            letterSpacing: "0.15px",
            [theme.breakpoints.down('md')]: {
                marginTop: "24px"
            },
        },
        seeAll: {
            marginTop: "8px",
            fontSize: "16px",
            lineHeight: "24px",
            letterSpacing: "0.5px"
        }
    }),
    { name: "SubscriptionTypes" }
);

const getPlansList = (props: SubscriptionTypesProps) => {
    const {
        subscriptionData: {
            subscriptionType,
            chargesForCurrentSubscription,
            isCurrentSubscriptionFree,
            availableUpgrades
        },
        allowedUpgradeTypes
    } = props;

    const availableUpgradesArr = filterAvailableUpgrades(availableUpgrades, allowedUpgradeTypes);
    const sortedAvailableUpgrades = [...availableUpgradesArr]
        .reverse()
        .filter(plan => subscriptionType !== plan.addonProductToUpgrade);
    const currentPlan = {
        addonProductToUpgrade: subscriptionType,
        chargesForUpgrade: chargesForCurrentSubscription,
        freeMonths: undefined,
        freeUpgradeAvailable: isCurrentSubscriptionFree
    };

    return [...sortedAvailableUpgrades, currentPlan];
};

const getAllPlans = (props: SubscriptionTypesProps) => {
    const plans = [...getPlansList({ ...props, allowedUpgradeTypes: [] })];

    for (let i = plans.length; i < SUBSCRIPTION_PLANS.length; i++) {
        plans.push({
            addonProductToUpgrade: SUBSCRIPTION_PLANS[i],
            chargesForUpgrade: {
                currencyCode: "",
                currencyFactor: 1,
                includeVat: false,
                price: 0
            },
            freeMonths: undefined,
            freeUpgradeAvailable: false,
            // @ts-ignore
            isDowngradeAvailable: true
        });
    }

    return plans;
};

export const SubscriptionTypes = (props: SubscriptionTypesProps) => {
    const [selected, setSelected] = useState(0);
    const [selectedFullTable, setSelectedFullTable] = useState(0);
    const classes = useStyles();
    const isMobile = useIsMobile();
    const isTablet = useIsTablet();
    const divRefs = useRef<HTMLDivElement[]>([]);
    const titleRefs = useRef<HTMLDivElement[]>([]);
    const descriptionRefs = useRef<HTMLDivElement[]>([]);
    const intl = useIntl();
    const currentsubscriptionType = props.subscriptionData.subscriptionType;
    const isBPlusE = isEcommerceSubscription(currentsubscriptionType);
    const hideRecommended = isBPlusE;

    const sliderWidth = (isBPlusE && !isMobile) ? "50%" : 288;
    const fitAvailable = (isBPlusE && !isMobile) ? false : true;

    useEffect(() => {
        setTimeout(() => {
            [titleRefs, descriptionRefs, divRefs].forEach(elementRef => {
                // Get an array of heights for each sibling div
                const heights = elementRef.current.map(ref => ref.clientHeight);

                // Find the maximum height
                const maxHeight = Math.max(...heights);

                // Set the maximum height to all siblings
                elementRef.current.forEach(ref => {
                    ref.style.height = `${maxHeight}px`;
                });
            });
        }, 0);
    }, []);

    const
        plansList = getPlansList(props),
        handleNext = () => {
            setSelected((selected) => Math.min(plansList.length - 1, selected + 1));
        },
        handleBack = () => {
            setSelected((selected) => Math.max(0, selected - 1));
        };

    const
        allPlansList = getAllPlans(props),
        handleNextFT = () => {
            setSelectedFullTable((selectedFullTable) => Math.min(allPlansList.length - 1, selectedFullTable + 1));
        },
        handleBackFT = () => {
            setSelectedFullTable((selectedFullTable) => Math.max(0, selectedFullTable - 1));
        };

    const plansListForTablet = [[allPlansList[0], allPlansList[1]], [allPlansList[2], allPlansList[3]]];
    const plansListFullTable = isTablet ? plansListForTablet : allPlansList;

    return (
        <React.Fragment>
            <Slider
                data={plansList}
                selected={selected}
                onChange={setSelected}
                gap={0}
                width={sliderWidth}
                slideIndicators={(
                    <Stepper
                        totalSteps={plansList.length}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        activeStepIndex={selected}
                    />
                )}
                fitAvailable={fitAvailable}
            >
                {(plan, index) => (
                    <PlanSelectionView
                        upgradeType={plan.addonProductToUpgrade}
                        chargesForUpgrade={plan.chargesForUpgrade}
                        freeMonths={plan.freeMonths}
                        isFreeUpgradeAvailable={plan.freeUpgradeAvailable}
                        isCurrentPlan={plan.addonProductToUpgrade === currentsubscriptionType}
                        // eslint-disable-next-line no-nested-ternary
                        classes={{ root: index > 0 ? classes.plan : (hideRecommended ? "" : classes.recommendedPlan) }}
                        divRefs={divRefs}
                        titleRefs={titleRefs}
                        descriptionRefs={descriptionRefs}
                        index={index}
                        hideRecommended={hideRecommended}
                    />
                )}
            </Slider>
            <div className={classes.compareAll}>{intl.msgJoint("msg: plans.title {Compare all plans and features}")}</div>
            <div className={classes.seeAll}>{intl.msgJoint("msg: plans.description {See all the features here and choose what fits you best}")}</div>
            <VerticalSpacer y={isBPlusE ? 0 : 24} />
            {
                (isMobile || isTablet) ?
                    <Slider
                        data={plansListFullTable}
                        selected={selectedFullTable}
                        onChange={setSelectedFullTable}
                        gap={0}
                        width="100%"
                        fitAvailable
                        slideIndicators={false}
                        disableClickOnSlide
                    >
                        {(plan) => {
                            return <FullTable
                                handleNext={handleNextFT}
                                handleBack={handleBackFT}
                                selected={selectedFullTable}
                                totalSteps={plansListFullTable.length}
                                plansList={isMobile ? [plan] : plan}
                                currentsubscriptionType={currentsubscriptionType}
                                hideRecommended={hideRecommended}
                            />;
                        }}
                    </Slider>
                    // @ts-ignore
                    : <FullTable plansList={allPlansList} currentsubscriptionType={currentsubscriptionType} hideRecommended={hideRecommended} />
            }
        </React.Fragment>
    );
};
