/**
 *
 * @param keys
 * @param key
 * @param comparator
 * @param returnClosestIndex returns index of the @keys to the most closest to searched element with an offset specified in this parameter.
 * @returns {int} array index
 */
export default function search(keys: Array<any>, key: any, comparator: Function, returnClosestIndex: boolean = false) {
    if (!keys.length || key === null || !comparator) {
        return -1;
    }

    let
        low = 0,
        high = keys.length - 1,
        mid,
        midVal,
        compare,
        offsetToClosest;

    while (low <= high) {
        mid = low + ((high - low) >> 1); // eslint-disable-line no-bitwise
        midVal = keys[mid];

        compare = comparator(key, midVal);

        if (compare < 0) {
            high = mid - 1;
            // This set of offset 0 and 1 is for the case when keys are sorted in ascending order. Descending should be 1 and 0
            offsetToClosest = 0;
        } else if (compare > 0) {
            low = mid + 1;
            offsetToClosest = 1;
        } else {
            return mid;
        }
    }

    if (returnClosestIndex) {
        return mid + offsetToClosest;
    }
    return -1;
}
