import { $Diff } from "utility-types";

import type { PageTemplateSelectorDialogProps } from "../../../../../../PageTemplateSelector/types";
import { openPageTemplateSelectorAction } from "../../../../../../PageTemplateSelector/actions";
import { DataPageTemplateRef } from "../../../../../../../../../dal/model";
import { getTemplatesWithPagesApiAction } from '../../../../../../../../redux/middleware/api/commonApiActions';

export const
    LOAD_TEMPLATE_TAB_TEMPLATES_REQUEST_ACTION = 'LOAD_TEMPLATE_TAB_TEMPLATES_REQUEST_ACTION',
    LOAD_TEMPLATE_TAB_TEMPLATES_SUCCESS_ACTION = 'LOAD_TEMPLATE_TAB_TEMPLATES_SUCCESS_ACTION',
    LOAD_TEMPLATE_TAB_TEMPLATES_FAILURE_ACTION = 'LOAD_TEMPLATE_TAB_TEMPLATES_FAILURE_ACTION',
    loadTemplateTabTemplatesAction = () => getTemplatesWithPagesApiAction({
        types: [
            LOAD_TEMPLATE_TAB_TEMPLATES_REQUEST_ACTION,
            LOAD_TEMPLATE_TAB_TEMPLATES_SUCCESS_ACTION,
            LOAD_TEMPLATE_TAB_TEMPLATES_FAILURE_ACTION
        ]
    });

export const
    SET_TEMPLATE_TAB_TEMPLATES_ACTION = 'SET_TEMPLATE_TAB_TEMPLATES_ACTION',
    setTemplateTabTemplatesAction = (templates: Array<DataPageTemplateRef>) => ({
        type: SET_TEMPLATE_TAB_TEMPLATES_ACTION,
        payload: templates
    });

export const
    SAVE_TEMPLATE_SELECTION_ACTION = 'SAVE_TEMPLATE_SELECTION_ACTION',
    saveTemplateSelectionAction = (templateId: string) => ({
        type: SAVE_TEMPLATE_SELECTION_ACTION,
        payload: templateId
    });

export const openTemplateTabPageTemplateSelectorAction = (
    params: $Diff<PageTemplateSelectorDialogProps, { onSaveAction: string; }> = {},
) => openPageTemplateSelectorAction({
    ...params,
    onSaveAction: SAVE_TEMPLATE_SELECTION_ACTION,
});

export const
    SWITCH_TO_TEMPLATE_SETTINGS_ACTION = 'SWITCH_TO_TEMPLATE_SETTINGS_ACTION',
    switchToTemplateSettingsAction = (pageRefId: string) => ({
        type: SWITCH_TO_TEMPLATE_SETTINGS_ACTION,
        payload: pageRefId
    });

export const
    SWITCH_TO_TEMPLATE_STYLES_ACTION = 'SWITCH_TO_TEMPLATE_STYLES_ACTION',
    switchToTemplateStylesAction = (pageRefId: string) => ({
        type: SWITCH_TO_TEMPLATE_STYLES_ACTION,
        payload: pageRefId
    });

export const
    SWITCH_TO_TEMPLATE_CONTENT_ACTION = 'SWITCH_TO_TEMPLATE_CONTENT_ACTION',
    switchToTemplateContentAction = (pageRefId: string) => ({
        type: SWITCH_TO_TEMPLATE_CONTENT_ACTION,
        payload: pageRefId
    });

export const
    SWITCH_PAGE_FROM_TEMPLATE_TAB_ACTION = 'SWITCH_PAGE_FROM_TEMPLATE_TAB_ACTION',
    switchPageFromTemplateAction = (pageRefId: string) => ({
        type: SWITCH_PAGE_FROM_TEMPLATE_TAB_ACTION,
        payload: pageRefId
    });
