import * as actionTypes from "../epic/actionTypes";
import { propPanelNavigateToPageAC } from "../actionCreators";

export default (dispatch: Dispatch) => {
    return {
        navigateToPage: (pageId: string, additionalPayload: any) =>
            dispatch(propPanelNavigateToPageAC(pageId, additionalPayload)),
        navigateBack: () => dispatch({ type: actionTypes.PROPERTIES_PANEL_NAVIGATE_BACK }),

        onMouseDown: (e: MouseEvent): void => {
            e.stopPropagation();
            dispatch({
                type: actionTypes.MOUSE_DOWN_ON_PROPERTIES_PANEL_DRAGGABLE_AREA,
                payload: { x: e.clientX, y: e.clientY }
            });
        },
        onMouseUp: (e: MouseEvent): void => {
            e.stopPropagation();
            dispatch({
                type: actionTypes.MOUSE_UP_ON_PROPERTIES_PANEL_DRAGGABLE_AREA,
                payload: { x: e.clientX, y: e.clientY }
            });
        }
    };
};
