import makeStyles from '@mui/styles/makeStyles';
import common from "../../../../../../../wbtgen/src/view/Theme/common.css";

const SPACE_AROUND = 24;

export const styles = makeStyles(
    (theme) => ({
        container: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
        },
        root: {
            flex: 1,
            padding: `${SPACE_AROUND}px ${SPACE_AROUND}px 0px !important`,
            border: `1px solid ${theme.palette.custom.colorGray_ce}`,
            textAlign: "left",
            marginBottom: "1px"
        },
        recommended: {
            color: common.colorWhite,
            fontSize: "12px",
            lineHeight: "18px",
            padding: "4px 0px",
            background: common.colorZundaGreen,
            border: `2px solid ${common.colorZundaGreen}`,
            borderBottom: "0px",
            textAlign: "center",
            fontWeight: 600
        },
        upgradePlanHeader: {
            fontSize: "24px",
            lineHeight: "30px",
            letterSpacing: "0.15px",
            fontWeight: 600,
            [theme.breakpoints.down('md')]: {
                fontSize: "16px",
                lineHeight: "22px",
            },
        },
        upgradePlanDescription: {
            fontSize: "14px",
            lineHeight: "22px",
            letterSpacing: "0.25px",
            [theme.breakpoints.down('md')]: {
                fontSize: "14px",
                lineHeight: "22px",
            },
        },
        priceInfo: {
            fontSize: "32px",
            lineHeight: "40px",
            fontWeight: 600,
            [theme.breakpoints.down('md')]: {
                fontSize: "16px",
                lineHeight: "22px",
            },
        },
        perMonth: {
            fontSize: "14px",
            lineHeight: "22px",
            fontWeight: 400,
            [theme.breakpoints.down('md')]: {
                fontSize: "14px",
                lineHeight: "22px",
            },
        },
        freeInfo: {
            fontSize: "12px",
            lineHeight: "18px",
            letterSpacing: "0.4px",
            background: theme.palette.custom.colorLinkWater,
            padding: "4px 8px",
            borderRadius: "4px"
        },
        monthInfo: {
            fontSize: "18px",
            lineHeight: "20px",
        },
        freeMonths: {
            color: theme.palette.custom.colorZundaGreen,
            lineHeight: "20px",
        },
        renewInfo: {
            fontSize: "12px",
            lineHeight: "18px",
            color: common.colorDarkSilver,
            letterSpacing: "0.4px"
        },
        selectPlanButton: {
            width: "100%",
            height: "40px",
        },
        horizontalLine: {
            borderTop: `1px solid ${theme.palette.custom.colorGray_ce}`,
            height: "1px",
            width: "calc(100% + 52px)",
            marginLeft: "-26px",
        },
        planOffersListContainer: {
            display: "flex",
            alignItems: "flex-start",
            margin: `0px -${SPACE_AROUND}px`
        },
        topSection: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
        },
        feature: {
            fontSize: "14px",
            lineHeight: "22px",
            letterSpacing: "0.25px",
            padding: `8px ${SPACE_AROUND}px`,
            width: "100%"
        },
        keyFeatures: {
            fontWeight: "600",
            padding: "8px 0px",
        },
        featureWithBg: {
            background: theme.palette.custom.colorGray_f7
        }
    }),
    { name: "PlanSelectionView" }
);
