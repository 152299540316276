import { put } from 'redux-saga/effects'; // eslint-disable-line node/file-extension-in-import
import { DataPage, DataSite } from "../../../../dal/model";
import { preparePersistentModel } from "../../../../dal/model/utils";
import makeNewPageRef from "../utils/makeNewPageRef";
import insertPageToSiteData from "../utils/insertPageToSiteData";
import makeUuid from "../../../../utils/makeUuid";
import updateSiteDataSaga from "./updateSiteDataSaga";
import DataPageTemplate from "../../../../dal/model/DataPageTemplate";
import { PAGE_OR_TEMPLATE_IMPORT_BEFORE_SAVE } from '../../TemplateSelector_DEPRECATED/actionTypes';
import { fixSocialComponentsLinks } from '../../oneweb/Social/fixSocialComponentsLinks';
import { type SiteSettings } from '../../App/epics/siteSettings/flowTypes';

type Params = {
    site: DataSite;
    siteSettings: SiteSettings;
    srcPage: DataPage;
    layoutCategory: string;
    parentPageId: string | null | undefined;
    updatedTemplate?: DataPageTemplate;
    generateNewId?: boolean;
};

export default function* (
    { site, siteSettings, srcPage, layoutCategory, parentPageId, updatedTemplate, generateNewId = true }: Params
): Generator<any, string, any> {
    const
        newPagePatch = generateNewId ? { id: makeUuid() } : undefined,
        newPage = preparePersistentModel(
            // @ts-ignore
            new DataPage(fixSocialComponentsLinks(siteSettings, srcPage)),
            newPagePatch
        ),
        addedPages = [newPage],
        newUpdatedTemplate = updatedTemplate && preparePersistentModel(updatedTemplate),
        newPageRef = makeNewPageRef(
            newPage,
            site,
            parentPageId,
            undefined,
            { name: newPage.name, title: newPage.name, layoutCategory, hidden: false },
            {}
        ),
        newSite = insertPageToSiteData(newPageRef, site, parentPageId);

    yield* updateSiteDataSaga({
        site: newSite,
        addedPages,
        updatedTemplate: newUpdatedTemplate,
        nextCurrentPageId: newPage.id,
        isExternalUpdate: true
    });

    yield put({
        type: PAGE_OR_TEMPLATE_IMPORT_BEFORE_SAVE,
        payload: { pages: addedPages }
    });

    return newPageRef.id;
}
