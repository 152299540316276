
/* eslint-disable no-useless-escape */

import * as pageIds from '../pagesIds';
import pageTitles from '../pagesTitles';
import {
    GALLERY_FULL_WIDTH,
    GALLERY_PP_FULL_WIDTH_MARGIN,
    GALLERY_PP_FULL_WIDTH_MAX_WIDTH
} from "../../actionTypes";
import { factoryFullWidthPP } from "../../../fullWidthUtils";

const
    id = pageIds.FULL_WIDTH,
    title = pageTitles[id],
    view = factoryFullWidthPP(GALLERY_FULL_WIDTH, GALLERY_PP_FULL_WIDTH_MARGIN, GALLERY_PP_FULL_WIDTH_MAX_WIDTH, true);

export { id, title, view };
