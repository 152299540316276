import * as React from 'react';
import cx from 'classnames';
import styles from './InfoTip.css';
import { injectIntl, Intl } from "../../intl/index";

type PropTypes = {
    intl: Intl,
    className: string,
    textKey: string,
    isTipLabelBlock?: boolean,
};

export default injectIntl(({ textKey, className = '', intl, isTipLabelBlock = false }: PropTypes) => {
    const whiteSpace = isTipLabelBlock ? '' : <span>&nbsp;</span>;
    return (
        <div className={cx(styles.tipContainer, className)}>
            <div className={styles.hintIcon} />
            <div>
                <span className={cx(styles.tipLabel, { [styles.blockLabel]: isTipLabelBlock })}>
                    {intl.msgJoint('msg:tip {Tip:}')}
                </span>
                {whiteSpace}
                <span>{intl.msgJoint(textKey)}</span>
            </div>
        </div>
    );
});
