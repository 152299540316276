// @ts-nocheck
import React from "react";
import cx from 'classnames';
import styles from './WSModernSection.css';
import { convertLayoutStyleToStyleObject } from "../../../../components/ModernLayouts/preview_utils";
import { RowView, RowGroupView } from "./RowRenderer";

const LayoutView = ({ layout: { vResponsive, rows, style: { padding = {} } = {} }, layoutRef, isRoot = false }) => {
    let style = {};
    if (!isRoot) {
        style = { ...convertLayoutStyleToStyleObject({ padding }) };
    }

    const { updatedRows } = rows.reduce((res, row) => {
        const { vResponsive } = row;
        if (vResponsive && res.isPrevRowResponsive) {
            const { updatedRows } = res;
            let lastUpdatedRow = updatedRows[updatedRows.length - 1];
            if (lastUpdatedRow.row) {
                lastUpdatedRow = {
                    rows: [lastUpdatedRow.row, row],
                    isPrevRowResponsive: true
                };
            } else {
                lastUpdatedRow = {
                    rows: [...lastUpdatedRow.rows, row],
                    isPrevRowResponsive: true
                };
            }
            return {
                updatedRows: [...res.updatedRows.splice(0, -1), lastUpdatedRow],
                isPrevRowResponsive: true,
            };
        }
        return {
            updatedRows: [...res.updatedRows, { row }],
            isPrevRowResponsive: vResponsive,
        };
    }, { updatedRows: [], isPrevRowResponsive: false });

    return (<div
        ref={layoutRef}
        className={cx(
            styles.layout,
            { [styles.rootLayout]: isRoot, [styles.subLayout]: !isRoot, [styles.vResponsive]: vResponsive }
        )}
        style={style}
    >
        {updatedRows.map(({ row, rows }, i) => (row ? <RowView key={i} row={row} /> : <RowGroupView rows={rows} key={i} />))}
    </div>);
};

const ModernSectionLayoutView = ({ section, layout }) => {
    const { id: rootId } = section;
    return <div className={cx(styles.modernLayoutWrapper)} style={{ height: section.height }}>
        <LayoutView layout={layout[rootId]} rootId={rootId} isRoot />
    </div>;
};

export {
    ModernSectionLayoutView,
    LayoutView
};
