/* eslint-disable import/order */

import { ROOT_CONTAINER_ID } from "./constants";

if (!window.___nodeTests) {
    window.$ = require('jquery'); // eslint-disable-line
}

import React from 'react';
import * as reproduceException from './debug/reproduceException';
import { createRoot } from 'react-dom/client';
import registerHotKeys from './registerHotkeys';
import initSubscriptions from './initSubscriptions';
import { UPDATE_APP_STATE } from './redux/modules/actionTypes';
import { appMountAC } from "./components/App/actionCreators/index";
import { error, info } from '../utils/log';
import './debug/core';
import './utils/disableDropToWindow';
import { createStore } from "./redux/createStore";
import type { LocaleData } from "../dal/flowTypes";
import { initReportError } from "./reportError";
import { getAppConfig } from './components/App/epics/appConfig/appConfig';
import 'console-panel/src/console-panel';

import 'console-panel/src/console-panel.css';
import { webDevComponent } from "./debug/webDevAllInOne/index";
import { initLocaleData } from "./view/intl/init/initLocaleData";
import { CustomTooltip } from "./customTooltip/initCustomTooltips";

const appConfig = getAppConfig();

if (!window.___nodeTests) {
    CustomTooltip(window.$, window).registerTooltip(document);
    if (appConfig.oneWeb.interceptFrontEndErrors &&
        typeof window.consolePanel !== 'undefined'
    ) {
        window.consolePanel.enable({
            functionsToIntercept: ['window.onerror', 'console.error'],
            showOnlyForTheseRelevantMessages: 'all'
        });
    }
}

export const bootstrap = (localeData: LocaleData) => {
    const
        store: Store = createStore(),
        { locale, messages } = initLocaleData(localeData),
        appProps = { locale, messages, store },
        reloadErrorHtml = `<h1>Failed to re-render app on hot reloading. Stacktrace in console.</h1>`;

    webDevComponent(store.dispatch);

    reproduceException.init(store);
    initReportError(store);

    function renderApp() {
        const AppRoot = require('./view/Root').default;

        const root = createRoot(document.getElementById(ROOT_CONTAINER_ID) as HTMLElement);
        root.render(<AppRoot {...appProps} />);
    }

    function reloadStyles() {
        const
            styleTag = document.getElementById('style-tag') as HTMLAnchorElement,
            oldHref = styleTag.href;

        styleTag.href = '';

        styleTag.href = oldHref;
    }

    if ("hot" in module && module.hot) {
        // @ts-ignore
        module.hot.accept('./view/Root', () => {
            info('HOT: replacing view');

            try {
                renderApp();
                reloadStyles();
            } catch (e: any) {
                error('Failed to re-render app on hot reloading', e);
                (document.getElementById(ROOT_CONTAINER_ID) as HTMLElement).innerHTML = reloadErrorHtml;
            }
        });

        // @ts-ignore
        module.hot.accept('./debug/reproduceException', () => {
            info('HOT: replacing reproduceException not required, reproduce exeption should be used after page reload');
        });
    }

    const { htmlTheme } = appConfig.oneWeb.frontEnd.partnerConfig;

    document.documentElement.classList.add(htmlTheme);
    const elTheme = document.querySelector(`link[data-theme=${htmlTheme}]`);
    if (elTheme) {
        elTheme.removeAttribute('disabled');
    }

    if (window.__testCaseMode) {
        renderApp();
    } else {
        registerHotKeys(store);
        initSubscriptions(store.dispatch);
        renderApp();
        store.dispatch(appMountAC());
    }

    window.updateState = (update: any) => store.dispatch({ type: UPDATE_APP_STATE, payload: update });
};
