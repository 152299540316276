import * as R from 'ramda';
import { HeaderWidth, HeaderHeight } from '../../view/PropertiesPanel.css';
import { DefaultTopRightPosition, DefaultPadding, SettingsContainerHeight } from '../constants';
import { TopBarHeight } from "../../../TopBar/constants";
import { makeBBox, doBBoxIntersect } from '../../../../utils/componentsMap/index';
import type { Position } from "../../../../redux/modules/flowTypes";
import type { Dimensions, BBox } from "../../../../redux/modules/children/workspace/flowTypes";
import type { DimensionsContext, TopRightPosition, PPAdjustInputs } from "../../flowTypes";
import isStretchComponentKind from "../../../oneweb/isStretchComponentKind";
import { shouldShowSettings } from './navigation';
import { isWsbDemo } from '../../../../debug/isWsbDemo';

export type BBoxCalcInput = {
    left: number,
    top: number,
    width: number,
    height: number
}

const HalfHeaderWidth = HeaderWidth / 2;

export const
    _getInitialPosition = (
        dimensions: Dimensions,
        browserDimensions: Dimensions,
        defaultTopRightPosition: TopRightPosition
    ): Position => ({
        y: defaultTopRightPosition.top,
        x: browserDimensions.width - (dimensions.width + defaultTopRightPosition.right)
    }),

    getInitialPosition = (dimensions: Dimensions, browserDimensions: Dimensions): Position =>
        _getInitialPosition(dimensions, browserDimensions, DefaultTopRightPosition),

    _moveInsideViewPort = (
        halfHeaderWidth: number,
        headerHeight: number,
        browserDimensions: Dimensions,
        panelWidth: number,
        position: Position
    ): Position => {
        const newPosition = {
            x: position.x,
            y: position.y
        };

        if (position.x > (browserDimensions.width - halfHeaderWidth)) {
            newPosition.x = browserDimensions.width - halfHeaderWidth;
        } else if (position.x < (panelWidth - halfHeaderWidth)) {
            newPosition.x = panelWidth - halfHeaderWidth;
        }

        if (position.y > (browserDimensions.height - headerHeight)) {
            newPosition.y = browserDimensions.height - headerHeight;
        } else if (position.y < TopBarHeight) {
            newPosition.y = TopBarHeight;
        }

        return (newPosition.x !== position.x) || (newPosition.y !== position.y) ? newPosition : position;
    },

    _makePositionFromBBox = ({ left: x, top: y }: BBox): Position => ({ x, y }),

    moveInsideViewPort = (browserDimensions: Dimensions, panelWidth: number, position: Position): Position =>
        _moveInsideViewPort(HalfHeaderWidth, HeaderHeight, browserDimensions, panelWidth, position),

    _getBBox = ({ left, top, width, height }: BBoxCalcInput, templateOffset: Position): BBox =>
        makeBBox((left + templateOffset.x), (top + templateOffset.y), width, height),

    //WBTGEN-11090 if bottom left or bottom right top is less than 65 then make them atleast 65
    _adjustTop = (propertyPanelBBox: BBox): BBox =>
        ({ ...propertyPanelBBox, top: Math.max(propertyPanelBBox.top, 65) }),

    _adjustTopInTrial = (propertyPanelBBox: BBox): BBox => {
        return _adjustTop(({ ...propertyPanelBBox, top: propertyPanelBBox.top - 110 }));
    },

    _propertyPanelCollidesWithComponentOrMCTA = (propertyPanelBBox: BBox, componentBBox: BBox, mctaBBox: BBox) =>
        doBBoxIntersect(componentBBox, propertyPanelBBox) || doBBoxIntersect(mctaBBox, propertyPanelBBox),

    _adjustShouldNotOverlapSelectedComponentAndMCTA = ({
        context,
        dimensions: { width, height },
        position,
        navigation
    }: PPAdjustInputs): Position => {
        const {
                templateOffset,
                leftPanelWidth,
                browserDimensions: { width: bw, height: bh },
                selectedComponentWithId: { component },
                selectedComponentWithId: { component: { kind } },
                mctaBBox: mcta,
                codeComponentsRendererHeadHeight
            } = context,
            adjustedTemplateOffset = R.evolve({ y: R.add(codeComponentsRendererHeadHeight) }, templateOffset),
            l1 = bw - width - DefaultPadding - adjustedTemplateOffset.x,
            l2 = leftPanelWidth + DefaultPadding - adjustedTemplateOffset.x,

            t1 = DefaultPadding + TopBarHeight - adjustedTemplateOffset.y,
            t2 = bh - height - DefaultPadding - adjustedTemplateOffset.y -
                (shouldShowSettings(navigation, kind) ? SettingsContainerHeight : 0),

            propertyPanelBBoxTopRight = _getBBox({ left: l1, top: t1, width, height }, adjustedTemplateOffset),

            propertyPanelBBoxTopLeft = _getBBox({ left: l2, top: t1, width, height }, adjustedTemplateOffset),

            componentBBox = _getBBox(
                { left: component.left, top: component.top, width: component.width, height: component.height },
                adjustedTemplateOffset
            ),

            mctaBBox = _getBBox(
                { left: mcta.left, top: mcta.top, width: (mcta.right - mcta.left), height: (mcta.bottom - mcta.top) },
                adjustedTemplateOffset
            );

        let propertyPanelBBoxBottomRight: BBox | null = null,
            propertyPanelBBoxBottomLeft: BBox | null = null;

        if (isWsbDemo()) {
            propertyPanelBBoxBottomRight = _adjustTopInTrial(_getBBox({ left: l1, top: t2, width, height },
                adjustedTemplateOffset));

            propertyPanelBBoxBottomLeft = _adjustTopInTrial(_getBBox({ left: l2, top: t2, width, height },
                adjustedTemplateOffset));
        } else {
            propertyPanelBBoxBottomRight = _adjustTop(_getBBox({ left: l1, top: t2, width, height },
                adjustedTemplateOffset));

            propertyPanelBBoxBottomLeft = _adjustTop(_getBBox({ left: l2, top: t2, width, height },
                adjustedTemplateOffset));
        }

        if (
            position &&
            (isStretchComponentKind(component.kind, component.stretch) || !_propertyPanelCollidesWithComponentOrMCTA(
                makeBBox(position.x, position.y, width, height), componentBBox, mctaBBox
            ))
        ) {
            return position;
        }

        if (_propertyPanelCollidesWithComponentOrMCTA(propertyPanelBBoxTopRight, componentBBox, mctaBBox)) {
            if (_propertyPanelCollidesWithComponentOrMCTA(propertyPanelBBoxBottomRight, componentBBox, mctaBBox)) {
                if (_propertyPanelCollidesWithComponentOrMCTA(propertyPanelBBoxBottomLeft, componentBBox, mctaBBox)) {
                    if (_propertyPanelCollidesWithComponentOrMCTA(propertyPanelBBoxTopLeft, componentBBox, mctaBBox)) {
                        return _makePositionFromBBox(propertyPanelBBoxTopRight);
                    }
                    return _makePositionFromBBox(propertyPanelBBoxTopLeft);
                }
                return _makePositionFromBBox(propertyPanelBBoxBottomLeft);
            }
            return _makePositionFromBBox(propertyPanelBBoxBottomRight);
        }
        return _makePositionFromBBox(propertyPanelBBoxTopRight);
    },

    adjustShouldNotOverlapSelectedComponentAndMCTA = _adjustShouldNotOverlapSelectedComponentAndMCTA,

    _adjustShouldNotCollideWithViewPort = (
        { browserDimensions }: DimensionsContext,
        dimension: Dimensions,
        position: Position
    ): Position => {
        if ((position.y + dimension.height) > browserDimensions.height) {
            return {
                x: position.x,
                y: (browserDimensions.height - dimension.height - DefaultPadding)
            };
        }

        return position;
    },

    adjustShouldNotCollideWithViewPort = _adjustShouldNotCollideWithViewPort;
