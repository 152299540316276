import * as R from "ramda";
import makeEpic from "../../../epics/makeEpic";
import * as actionTypes from "./actions";
import makeStateSelectorReducer from '../../../epics/makeStateSelectorReducer';

const updateReasons = {
    HTTPS_TOGGLED: 'HTTPS_TOGGLED',
    DROP_HTML_EXT_TOGGLED: 'DROP_HTML_EXT_TOGGLED'
};

const publishConfigDefaultState = {
    publishWithSSL: true,
    dropHtmlExtension: true
};

const valueActionType = 'PUBLISH_CONFIG_EPIC_VALUE';

const publishConfigEpicConfig = {
    valueActionType,
    saveIntoSiteSettings: { key: 'publishConfig' },
    undo: true,
    defaultState: publishConfigDefaultState,
    updaters: [
        {
            conditions: [actionTypes.ENABLE_HTTPS_TOGGLE],
            reducer: ({ state }) => {
                return {
                    state: R.evolve({
                        publishWithSSL: R.not
                    }, state),
                    updateReason: updateReasons.HTTPS_TOGGLED
                };
            }
        },
        {
            conditions: [actionTypes.DROP_HTML_EXT_TOGGLE],
            reducer: ({ state }) => {
                return {
                    state: R.evolve({
                        dropHtmlExtension: R.not
                    }, state),
                    updateReason: updateReasons.DROP_HTML_EXT_TOGGLED
                };
            }
        }
    ]
};

const publishConfigEpic = makeEpic(publishConfigEpicConfig);

const publishConfigReducer = makeStateSelectorReducer(
    publishConfigEpic.reducer,
    valueActionType,
    state => state
);

export {
    valueActionType,
    publishConfigDefaultState,
    publishConfigEpic,
    publishConfigReducer
};
