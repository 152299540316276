import * as mainPage from './pages/main';
import * as advancedFormatting from './pages/advancedFormatting';
import * as linkPage from './pages/link';
import * as textEffects from './pages/textEffects';
import makePages from '../../../PropertiesPanel/view/makePages';
import addGoogleFont from '../../../PropertiesPanel/view/AddGoogleFont/index';
import * as mobileView from '../../../PropertiesPanel/view/MobileView/page';

const propertiesPanelConfig = {
    pages: makePages(mainPage, linkPage, textEffects, advancedFormatting, addGoogleFont, mobileView),
    defaultPageId: mainPage.id
};

export default propertiesPanelConfig;
