/* eslint-disable max-len */

import React from 'react';
import { injectIntl } from '../../view/intl/index';
import { Dialog, DialogBody } from '../../view/common/dialogs/baseDialog/index';
import getCenteredDialogConfig from '../DialogManager/getCenteredDialogConfig';
import { HIDE_UNSUPPORTED_DIMENSION_MESSAGE } from './actionTypes';
import DialogTitleBox from "../../view/common/dialogs/baseDialog/DialogTitleBox";
import HorizontalSpacer from "../../view/common/HorizontalSpacer";
import DialogFooter from "../../view/common/dialogs/baseDialog/DialogFooter";
import { StripTypes } from "../../view/common/dialogs/baseDialog/Dialog";
import styles from '../../view/common/dialogs/WarningDialog/WarningDialog.css';

const
    width = 480,
    height = 320,
    title = 'msg: unsupportedDimension.title {Browser window too small}',
    getMsg1 = intl => intl.msgJoint('msg: unsupportedDimension.msg1 {Your browser window is too small for all of Website Builder\'s features.}'),
    getMsg2 = intl => intl.msgJoint('msg: unsupportedDimension.msg2 {Make sure that the zoom level on your browser is set to 100%, make the browser window bigger and consider removing any toolbars in the browser.}'),
    MessageDialog = injectIntl(({ intl, dispatch }) => {
        const mctaHandler = () => dispatch({ type: HIDE_UNSUPPORTED_DIMENSION_MESSAGE });
        return (
            <Dialog stripType={StripTypes.WARNING} onClose={mctaHandler}>
                <DialogTitleBox title={title} iconClass={styles.iconWarning} className={styles.warningDialogTitle} />
                <DialogBody>
                    <p>{getMsg1(intl)}</p>
                    <HorizontalSpacer />
                    <p>{getMsg2(intl)}</p>
                </DialogBody>
                <DialogFooter
                    mctaText="msg: common.continue {Continue}"
                    mctaHandler={mctaHandler}
                    noTopBorder
                    className={styles.warningDialogFooter}
                />
            </Dialog>
        );
    });

export const
    dialog = getCenteredDialogConfig(width, height, MessageDialog);

