import React from "react";
export const SvgSnapchatRegularDark = props => (
    <svg viewBox="0 0 46 43" {...props}>
        <path
            fill="#3c3c3c"
            fillRule="evenodd"
            d="M23.006 0c1.867 0 8.191.52 11.17 7.207 1.002 2.25.762 6.073.568 9.144l-.008.13-.059.968c.14.077.385.17.759.188.563-.025 1.218-.21 1.945-.552a2.08 2.08 0 01.864-.175c.327 0 .66.063.942.18h.002c.836.3 1.361.903 1.374 1.579.017.86-.75 1.603-2.28 2.208-.188.074-.415.146-.656.223-.864.274-2.169.69-2.523 1.525-.183.433-.11.99.217 1.655a.75.75 0 01.021.045c.115.268 2.875 6.574 9.012 7.587a.936.936 0 01.783.976 1.248 1.248 0 01-.102.424c-.459 1.073-2.4 1.861-5.938 2.411-.118.16-.24.722-.315 1.066-.075.345-.152.687-.258 1.049-.148.505-.518.783-1.044.783h-.052c-.25 0-.593-.043-1.02-.126a11.974 11.974 0 00-2.405-.256c-.56 0-1.14.049-1.724.146-1.141.19-2.11.876-3.235 1.671-1.607 1.138-3.427 2.427-6.196 2.427-.124 0-.24-.004-.33-.008a3.098 3.098 0 01-.228.008c-2.768 0-4.589-1.289-6.196-2.427-1.123-.795-2.093-1.481-3.234-1.671a10.57 10.57 0 00-1.725-.146c-1.01 0-1.816.158-2.405.273-.393.077-.732.144-1.02.144-.711 0-.988-.435-1.095-.799a14.87 14.87 0 01-.259-1.06c-.075-.344-.198-.91-.316-1.07-3.536-.55-5.478-1.34-5.939-2.417a1.263 1.263 0 01-.1-.42.938.938 0 01.783-.978c6.134-1.011 8.897-7.319 9.012-7.586l.021-.046c.328-.665.4-1.222.217-1.655-.354-.836-1.66-1.25-2.523-1.525-.24-.076-.466-.148-.655-.223-2.09-.827-2.382-1.777-2.258-2.428.171-.904 1.273-1.51 2.194-1.51.266 0 .507.048.715.146.787.369 1.485.556 2.076.556.443 0 .724-.106.878-.192-.02-.363-.045-.738-.067-1.1-.193-3.07-.433-6.889.569-9.137C13.94.527 20.25.008 22.113.008l.78-.007.11-.001h.003z"
        />
    </svg>
);
