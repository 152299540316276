import React from 'react';
import cx from "classnames";
import styles from '../LinkChooserDialog.css';
import type { ReactElementRef } from "../../../../../../globalTypes";
import {
    LC_RESET_CURRENT_RESULT,
    LC_SECTION_LINK_PAGE_SELECT,
    LC_SECTION_LINK_SECTION_SELECT
} from "../../actionTypes";
import type { SectionLinkTabPropTypes } from "../../flowTypes";
import { InputLabel } from '../../../../../../components/inputControls/common/view/index';
import Combobox from '../../../../Combobox/index';
import { injectIntl } from '../../../../../intl/index';
import { loadSectionComponentsForPageId } from '../../../../../../components/PagesTree/Tree/actionCreators/index';
import InfoTip from "../../../../InfoTip/index";
import { CLOSE_STICKY_TOOLTIP } from "../../../../../../components/Tooltip/stickyTooltip/actionTypes";
import {
    showOutDatedLinkToolTipAC, showPageDeletedToolTipAC,
} from "../../../../../../components/PagesTree/sectionLinkForm/actions";
import { sectionLinkDialogInfoTip } from "../../../../../../components/PagesTree/Tree/constants";
import { isSectionKind, isStripKind } from "../../../../../../components/oneweb/componentKinds";
import { BrokenLink } from "../../../../../../components/PagesTree/sectionLinkForm/view/BrokenLink";

class SectionLinktab extends React.PureComponent<SectionLinkTabPropTypes> {
    sectionComboBoxRef: ReactElementRef<HTMLDivElement> = React.createRef();
    tooltipUpdatedAtPage?: string;
    oldSelectedSectionId?: string;
    stripWithLinks: Record<string, any>[] = [];

    componentDidUpdate() {
        const { selection: pageId, dispatch, pageSections, isLoading, sectionId, isValidPageId } = this.props,
            sectionEle: HTMLDivElement | null = this.sectionComboBoxRef.current,
            // @ts-ignore
            sectionOptions = pageSections[pageId] || [],
            showOutdatedToolTip = !isLoading && !!sectionOptions.find(({ value, kind }) => sectionId === value && isStripKind(kind));

        if (!sectionEle || !pageId) {
            return;
        }

        const formEle = sectionEle.closest(`.${styles.sectionTab}`),
            pageComboBox = formEle && formEle.querySelector('.pageComboBox');

        const focus = () => {
            const focusEle = sectionEle.querySelector(`.${styles.focusEle}`);
            if (focusEle) {
                // @ts-ignore
                focusEle.focus();

                focusEle.addEventListener('blur', () => dispatch({ type: CLOSE_STICKY_TOOLTIP }));
            }
        };

        if (pageComboBox && !isValidPageId && this.tooltipUpdatedAtPage !== pageId) {
            const { top, right, height } = pageComboBox.getBoundingClientRect();
            this.tooltipUpdatedAtPage = pageId;
            dispatch(showPageDeletedToolTipAC({ top: (top + (height / 2)), left: right, customClass: styles.toolTipCustomCls }));
            focus();
            return;
        }

        if (sectionId && this.oldSelectedSectionId !== sectionId && showOutdatedToolTip) {
            const { top, right, height } = sectionEle.getBoundingClientRect(),
                action = showOutDatedLinkToolTipAC({ top: (top + (height / 2)), left: right, customClass: styles.toolTipCustomCls });
            this.oldSelectedSectionId = sectionId;
            dispatch(action);
            focus();
        }
    }

    componentDidMount() {
        if (!this.props.selection) {
            this.props.dispatch({ type: LC_SECTION_LINK_PAGE_SELECT, payload: this.props.currentPageId });
        }
        this.props.dispatch(loadSectionComponentsForPageId(this.props.selection));
    }

    componentWillUnmount() {
        this.props.dispatch({ type: CLOSE_STICKY_TOOLTIP });
    }

    render() {
        const { selection, sectionId, dispatch, pageOptions, intl, pageSections, isValidPageId, isLoading } = this.props,
            // @ts-ignore
            sectionOptions = pageSections[selection] || [],
            isInvalidSectionId = !isLoading && sectionId && !sectionOptions.find(({ value }) => sectionId === value),
            stripSelectionDisabled = isValidPageId && !sectionOptions.length,
            stripSelectionPlaceholder = intl.msgJoint('msg: newPage.sectionLink.sectionplaceholder {Choose from your sections}'),
            noStripsOnPage = intl.msgJoint('msg: newPage.sectionLink.noSectionsOnPage {No sections on the page selected}');

        let finalSectionOptions = sectionOptions.filter(({ kind }) => isSectionKind(kind)),
            linkedStrip = sectionOptions.find(({ value, kind }) => value === sectionId && isStripKind(kind));
        if (!this.stripWithLinks.length && linkedStrip) {
            this.stripWithLinks = [linkedStrip];
        }
        finalSectionOptions = [...finalSectionOptions, ...this.stripWithLinks];
        return (
            <div
                className={styles.sectionTab}
            >
                <div className={styles.formLinkRow}>
                    <InputLabel>{'msg: newPage.sectionLink.page {On what page is the section located?}'}</InputLabel>
                    <div className={`${styles.sectionComboBoxWrapper} pageComboBox`}>
                        <Combobox
                            options={pageOptions}
                            searchable
                            placeholder={intl.msgJoint('msg: newPage.sectionLink.pageplaceholder {Choose from your pages}')}
                            value={selection}
                            onChange={({ value }) => {
                                dispatch({ type: LC_SECTION_LINK_PAGE_SELECT, payload: value });
                                dispatch(loadSectionComponentsForPageId(value));
                            }}
                            className={cx(styles.pageComboBox, { [styles.invalidComboBox]: !isValidPageId })}
                        />
                    </div>
                </div>
                <div className={styles.formLinkRow}>
                    <InputLabel>
                        {'msg: newPage.sectionLink.section {Which section should the link lead to?}'}
                    </InputLabel>
                    <div ref={this.sectionComboBoxRef} className={styles.sectionComboBoxWrapper}>
                        <Combobox
                            options={finalSectionOptions}
                            searchable
                            disabled={stripSelectionDisabled}
                            placeholder={stripSelectionDisabled ? noStripsOnPage : stripSelectionPlaceholder}
                            value={sectionId}
                            onChange={({ value }) => dispatch({ type: LC_SECTION_LINK_SECTION_SELECT, payload: value })}
                            className={styles.sectionComboBox}
                        />
                        <input className={styles.focusEle} />
                    </div>
                    {
                        isValidPageId &&
                        isInvalidSectionId &&
                        <BrokenLink onClose={() => dispatch({ type: LC_RESET_CURRENT_RESULT })} />
                    }
                </div>
                <InfoTip
                    className={styles.tipContainer}
                    textKey={sectionLinkDialogInfoTip}
                />
            </div>
        );
    }
}

export default injectIntl(SectionLinktab);
