import React from 'react';
import configurationDialogId from './addGoogleFontConfigurationDialogId';
import { getCenterPositionForDialog } from '../../DialogManager/utility';
import type { BrowserDimensions } from '../../../redux/modules/children/dimensions/index';
import AddGoogleFont from '../AddGoogleFont/index';
import fontStatusReducer from "../../Fonts/fontStatusReducer";
import { Dialog, DialogBody } from '../../../view/common/dialogs/baseDialog/index';

type AddGoogleFontProps = {
    fontStatus: string,
    dispatch: Dispatch
}

const
    width = 314,
    height = 292,
    comboBoxStyle = { width: '176px' },
    AddGoogleFontDialog = (props: AddGoogleFontProps) => (
        <Dialog>
            <DialogBody>
                <AddGoogleFont.view {...props} comboBoxStyle={comboBoxStyle} />
            </DialogBody>
        </Dialog>
    ),
    config = {
        component: AddGoogleFontDialog,
        confFactory: ({ browserHeight, browserWidth }: BrowserDimensions) => ({
            configurationDialogId,
            position: getCenterPositionForDialog(width, height, browserWidth, browserHeight),
            modal: false,
            dimensions: { width, height }
        }),
        dependsOn: {
            fontStatus: fontStatusReducer
        }
    };

export default config;
