import type { AnalyticsEvent } from '../flowTypes';
import * as ActionTypes from '../actionTypes';

const
    sendEventToAec = (event: AnalyticsEvent) => ({
        type: ActionTypes.SEND_EVENT_AEC,
        payload: event
    }),
    // eslint-disable-next-line camelcase
    sendShownErrorEventToAecAC = (opt_label?: string, opt_value?: number) => sendEventToAec({
        category: 'errors shown to users',
        action: 'showError',
        opt_label,
        opt_value
    });

export {
    sendEventToAec,
    sendShownErrorEventToAecAC
};
