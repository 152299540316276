import { EXPANDED_PANEL_COMPONENT_CLICK, EXPANDED_PANEL_COMPONENT_MOUSE_DOWN } from "../actionTypes";

export const
    onShortCutClick = (componentKind: string, restProps: Record<string, any>) => ({
        type: EXPANDED_PANEL_COMPONENT_CLICK,
        payload: { componentKind, ...restProps }
    }),
    onShortCutMouseDown = (componentKind: string, restProps: Record<string, any>) => ({
        type: EXPANDED_PANEL_COMPONENT_MOUSE_DOWN,
        payload: { componentKind, ...restProps }
    });
