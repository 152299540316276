import React from 'react';
import { errorDialogStandardHeight, errorDialogStandardWidth } from '../../../dialogs/constants';
import styles from '../FileChooserUpload.css';
import WarningDialog from '../../../dialogs/WarningDialog/index';
import injectIntl from "../../../../intl/injectIntl";
import getCenteredDialogConfig from "../../../../../components/DialogManager/getCenteredDialogConfig";
import ErrorDialogFileList from './ErrorDialogFileList';

const
    width = errorDialogStandardWidth,
    height = errorDialogStandardHeight,
    title = 'msg: fc.uploadFailedDialog.err.unsup.title {Unsupported file.}',
    singleError = 'msg: fc.uploadFailedDialog.err.unsup.fileTypeSingle {\"{fileName}\" is not a supported file type.}', // eslint-disable-line no-useless-escape
    multiError = 'msg: fc.uploadFailedDialog.err.unsup.fileTypeMultiple {These files have an unsupported type:}',
    chooseAnotherText = 'msg: fc.uploadFailedDialog.err.unsup.chooseAnother {Please choose another file type.}',
    mctaText = 'msg: common.ok {OK}';

const renderBody = (fileNames, intl) => {
    if (Array.isArray(fileNames)) {
        return (
            <div className={styles.labelSmall}>
                <span className={styles.bold}>{intl.msgJoint(multiError)}</span>
                <ErrorDialogFileList fileNames={fileNames} />
                <span className={styles.chooseAnotherFileType}>{intl.msgJoint(chooseAnotherText)}</span>
            </div>
        );
    } else {
        const fileName = fileNames;
        return (
            <div className={styles.labelSmall}>
                <span className={styles.bold}>{intl.msgJoint([singleError, { fileName }])}</span>
                &nbsp;
                <span className={styles.chooseAnotherFileType}>{intl.msgJoint(chooseAnotherText)}</span>
            </div>
        );
    }
};

type Props = { fileNames, intl: Intl };

const UnsupportedErrorDialog = injectIntl((props: Props) => {
    const { fileNames, intl } = props;
    return (
        <WarningDialog title={title} mctaText={mctaText}>
            {renderBody(fileNames, intl)}
        </WarningDialog>
    );
});

export default getCenteredDialogConfig(width, height, UnsupportedErrorDialog);
