import React from 'react';
import { connect } from "react-redux";
import type { AppState } from "../../../../redux/modules/flowTypes";
import { injectIntl } from "../../../../view/intl/index";
import { makeEpicStateSelector } from "../../../../epics/makeEpic";
import { stylesheetAppSel } from "../../../Workspace/epics/stylesheets/selectors";
import sectionsBlocksVAT from "../../../Panel/epics/sectionsBlocks/valueActionType";
import componentsEvalValueActionType from "../../../Workspace/epics/componentsEval/valueActionType";
import { globalVariablesEpic } from '../../../App/epics/globalVariablesEpic';
import Blocks from "../../../Panel/configs/Blocks";
import HelpTip from "../../../Panel/view/HelpTip";
import * as InserterStyles from "../../../Panel/view/Inserter.css";
import { PreDefinedHoverBoxes } from "../../Background/extendedPanel/boxes/boxes";
import { NewTag } from "../../../../view/common/Tag/index";

type BlockMap = { [a: string]: Object };

const HOVERBOX_NORMAL_VIEW = ["hoverbox-3", "hoverbox-4", "hoverbox-5"];
const HOVERBOX_BLOCK = {
    id: 'hoverbox',
    title: "msg: common.hoverBox {Hoverbox}",
    // eslint-disable-next-line max-len
    helpTip: 'msg: extendedPanel.hoverBox.helpTip {A hoverbox allows you to create interactive elements on your website. Use it to make an important message stand out.}'
};

const HoverBoxComponents = injectIntl(({
    styleSheets,
    componentsEval,
    globalVariables,
    blocks,
    intl,
    dispatch
}) => {
    const
        filteredCategory = blocks.find(categoryObj => categoryObj.key === HOVERBOX_BLOCK.id),
        blocksMap: BlockMap = (filteredCategory ? filteredCategory.items : []).reduce((acc, block) => ({
            ...acc,
            [block.key]: block
        }), {});
    return <React.Fragment>
        <div className={InserterStyles.sectionsExtendedPanelTitleWrapper}>
            <h3 className={InserterStyles.subTitle}>{intl.msgJoint(HOVERBOX_BLOCK.title)}
                <NewTag containerClassName={InserterStyles.newLabelContainer} />
            </h3>
            <HelpTip
                msg={HOVERBOX_BLOCK.helpTip}
                dispatch={dispatch}
            />
        </div>
        <PreDefinedHoverBoxes intl={intl} dispatch={dispatch} />
        <Blocks
            noHeader
            category={HOVERBOX_BLOCK}
            key="HOVERBOX_NORMAL_VIEW"
            blocks={HOVERBOX_NORMAL_VIEW.map(key => blocksMap[key])}
            componentsEval={componentsEval}
            styleSheets={styleSheets}
            globalVariables={globalVariables}
            intl={intl}
            dispatch={dispatch}
            isNoOverlay
        />
    </React.Fragment>;
});

const mapStateToProps = (appState: AppState) => {
    const { sectionsBlocks } = makeEpicStateSelector(sectionsBlocksVAT)(appState);

    return {
        styleSheets: stylesheetAppSel(appState),
        componentsEval: makeEpicStateSelector(componentsEvalValueActionType)(appState),
        globalVariables: makeEpicStateSelector(globalVariablesEpic.valueActionType)(appState),
        blocks: sectionsBlocks.blocks
    };
};

export const HoverBoxes = connect(mapStateToProps)(HoverBoxComponents);
