export const
    COMPONENT_ENTERED_EDIT_MODE_USING_DOUBLE_CLICK = 'COMPONENT_ENTERED_EDIT_MODE_USING_DOUBLE_CLICK',
    COMPONENT_CHANGING = "COMPONENT_CHANGING",
    COMPONENTS_ADD_COMPONENT = "COMPONENTS_ADD_COMPONENT",
    COMPONENTS_ADD_NOT_ALLOWED = "COMPONENTS_ADD_NOT_ALLOWED",
    COMPONENTS_PASTED = "COMPONENTS_PASTED",
    COMPONENTS_DUPLICATED = "COMPONENTS_DUPLICATED",
    SET_SELECTED_COMPONENTS = "SET_SELECTED_COMPONENTS",
    ADD_SECTION_BUTTON_CLICKED = "ADD_SECTION_BUTTON_CLICKED",
    SILENT_COMPONENTS_DUPLICATED = "SILENT_COMPONENTS_DUPLICATED",
    COMPONENTS_MOVED_BY_MOUSE = 'COMPONENTS_MOVED_BY_MOUSE',
    COMPONENTS_MOVED_BY_KEYBOARD = 'COMPONENTS_MOVED_BY_KEYBOARD',
    COMPONENTS_ORDER_CHANGED = 'COMPONENTS_ORDER_CHANGED',
    COMPONENTS_RESIZED = 'COMPONENTS_RESIZED',
    COMPONENTS_BOTTOM_SHIFTED = 'COMPONENTS_BOTTOM_SHIFTED',
    COMPONENTS_WRAPPED = 'COMPONENTS_WRAPPED',
    REMOVE_SECTION_DROP_ZONE = 'REMOVE_SECTION_DROP_ZONE',
    MOVED_TO_TEMPLATE_OR_PAGE = 'MOVED_TO_TEMPLATE_OR_PAGE',
    COMPONENT_REQUIRES_ADDITIONAL_CONFIGURATION = "COMPONENT_REQUIRES_ADDITIONAL_CONFIGURATION",
    USER_INTERACTION_DONE_ON_COMPONENTS = "USER_INTERACTION_DONE_ON_COMPONENTS",
    COMPONENTS_MAP_INITIALIZED = 'COMPONENTS_MAP_INITIALIZED',
    COMPONENTS_MAP_REPLACE_MISSING_ASSETS = 'COMPONENTS_MAP_REPLACE_MISSING_ASSETS',
    FLUSH_ADJUSTMENT_CACHE = 'FLUSH_ADJUSTMENT_CACHE',
    COMPONENTS_MAP_DELETE_MISSING_ASSETS = 'COMPONENTS_MAP_DELETE_MISSING_ASSETS',
    DESELECTED_COMPONENTS_ON_WORKSPACE_MOUSE_DOWN = 'DESELECTED_COMPONENTS_ON_WORKSPACE_MOUSE_DOWN',
    PUSHDOWN_COMPLETE = 'PUSHDOWN_COMPLETE',
    END_COMPONENT_CHANGING_USER_INTERACTION = 'END_COMPONENT_CHANGING_USER_INTERACTION',
    TEMPLATE_TEAM_PAGE_TRANSLATION_COMPLETE = 'TEMPLATE_TEAM_PAGE_TRANSLATION_COMPLETE',
    COMPONENT_DIMENSIONS_CHANGED_FROM_PP = 'COMPONENT_DIMENSIONS_CHANGED_FROM_PP',
    PROCESS_CMPS_MAP_AFTER_ATTACHMENTS_UPDATE = 'PROCESS_CMPS_MAP_AFTER_ATTACHMENTS_UPDATE',
    COMPONENT_CONVERTED_TO_TEMPLATE = 'COMPONENT_CONVERTED_TO_TEMPLATE',
    SECTION_PANEL_MOVE_SECTION_UP = 'SECTION_PANEL_MOVE_SECTION_UP',
    SECTION_PANEL_MOVE_SECTION_DOWN = 'SECTION_PANEL_MOVE_SECTION_DOWN',
    UPDATE_COMPONENT_EXTENSION_DATA = 'UPDATE_COMPONENT_EXTENSION_DATA',
    ADJUSTMENT_DATA_APPLIED = 'ADJUSTMENT_DATA_APPLIED';
