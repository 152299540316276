import * as main from './pages/main';
// import * as mobileView from '../../../../PropertiesPanel/view/MobileView/page';
import makePages from '../../../PropertiesPanel/view/makePages';

const propertiesPanelConfig = {
    pages: makePages(main/*, mobileView*/),
    defaultPageId: main.id,
    reducer: () => {}
};

export default propertiesPanelConfig;
