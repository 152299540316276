import React from 'react';
import styles from './Separator.css';
import VerticalSpacer from '../../../view/common/VerticalSpacer';

export default () => (
    <div>
        <VerticalSpacer />
        <div className={styles.separator} />
        <VerticalSpacer />
    </div>
);
