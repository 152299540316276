/* eslint-disable max-len */
import React from 'react';
import * as styles from './SocialEditLinkDialog.css';
import StandardDialog from "../../../../view/common/dialogs/StandardDialog/index";
import getCenteredDialogConfig from "../../../DialogManager/getCenteredDialogConfig";
import {
    SOCIAL_EDIT_DIALOG_OK_PRESSED,
    SOCIAL_EDIT_LINK_DIALOG_INPUT_CHANGED,
    SOCIAL_EDIT_DIALOG_CLOSE_PRESSED
} from "../actionTypes";
import { injectIntl } from "../../../../view/intl/index";
import { socialLinksConfigsMap, socialEditUrlDialogDescription } from "../socialLinksConfigs";
import { socialServiceEpic } from "../epics/socialServiceEpic/socialServiceEpic";
import { removeUrlProtocolAndWWWAndLastSlash } from "../utils";
import { SocialColorSourceType, SocialStyleType } from '../constants';
import { ValidatedInput } from '../../../../view/common/Input/ValidatedInputField';

const _dialog = injectIntl(({ dispatch, intl, socialService }) => {
    const { editUrlInputIsInvalid, editLinkDialogInputValue, editDialogPlatformKind } = socialService;
    const {
        editInputLabel,
        editUrlDialogTitle,
        urlPlaceholder,
        editValidationErrMsg,
    } = socialLinksConfigsMap[editDialogPlatformKind];

    const getErrMessage = (): MsgJointInput => {
        if (removeUrlProtocolAndWWWAndLastSlash(urlPlaceholder) === removeUrlProtocolAndWWWAndLastSlash(editLinkDialogInputValue.toLowerCase())) {
            return "msg: social.editUrl.validationErr.lacksPlatformId {Add your profile id to the URL and try again.}";
        } else {
            return [
                editValidationErrMsg,
                { platform: intl.msgJoint(editInputLabel) }
            ];
        }
    };

    const IconCmp = socialLinksConfigsMap[editDialogPlatformKind].svgCmpsByColorByType[SocialColorSourceType.DARK][SocialStyleType.REGULAR];

    return (
        <StandardDialog
            title={intl.msgJoint(editUrlDialogTitle)}
            titleIconCmp={<IconCmp style={{ width: 18, height: 18, marginRight: 8 }} />}
            mctaText="msg: common.save {Save}"
            mctaHandler={() => dispatch({ type: SOCIAL_EDIT_DIALOG_OK_PRESSED })}
            ctaHandler={() => dispatch({ type: SOCIAL_EDIT_DIALOG_CLOSE_PRESSED })}
            noTopBorderForFooter
            titleClassName={styles.titleClassName}
            disabled={editUrlInputIsInvalid}
        >
            <div className={styles.description}>
                {intl.msgJoint(socialEditUrlDialogDescription)}
            </div>
            <div className={styles.inputLabel}>
                {intl.msgJoint(editInputLabel)}
            </div>
            <ValidatedInput
                value={editLinkDialogInputValue}
                onChange={payload => dispatch({ type: SOCIAL_EDIT_LINK_DIALOG_INPUT_CHANGED, payload })}
                isInvalid={editUrlInputIsInvalid}
                invalidInputClass={styles.inputInvalidMsg}
                validationMsg={getErrMessage()}
                intl={intl}
            />
        </StandardDialog>
    );
});

const SocialEditLinkDialog = getCenteredDialogConfig({
    width: 482,
    height: 327,
    component: _dialog,
    dependsOn: {
        socialService: socialServiceEpic.reducer
    }
});

export {
    SocialEditLinkDialog
};

