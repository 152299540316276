export const
    START_QUICK_TOUR = 'START_QUICK_TOUR',
    START_SECTION_TOUR = 'START_SECTION_TOUR',
    STOP_QUICK_TOUR = 'STOP_QUICK_TOUR',
    SHOW_NEXT_TIP = 'SHOW_NEXT_TIP',
    SHOW_PREVIOUS_TIP = 'SHOW_PREVIOUS_TIP',
    TIP_HEIGHT_CHANGED = 'TIP_HEIGHT_CHANGED';

export const startQuickTourAction = () => ({
    type: START_QUICK_TOUR,
});

export const stopQuickTourAction = () => ({
    type: STOP_QUICK_TOUR,
});
