/* eslint-disable max-len */
import type { ComponentsMap, ComponentsIds, BBox } from "../../../../redux/modules/children/workspace/flowTypes";
import { memoMax, memoMaxOne } from "../../../../../utils/memo";
import { getSectionsOrderdByTop, isHeaderOrFooterSection } from "../../../oneweb/Section/utils";
import type { Attachments } from "../componentAttachements/flowTypes";
import { getTopMostParentId } from "../componentAttachements/util";
import type { AnyComponent } from "../../../oneweb/flowTypes";
import type { SectionComponent } from "../../../oneweb/Section/flowTypes";

type Position = {
    x: number,
    y: number
}

type SectionsRangeProp = {
    sections: Array<AnyComponent>,
    range: Array<{ top: number, bottom: number, section: AnyComponent }>
}

const getSectionRange = memoMax((componentsMap): SectionsRangeProp => {
    const sections = getSectionsOrderdByTop(componentsMap);
    return {
        sections,
        range: sections.map(section => ({
            top: section.top,
            bottom: (section.top + section.height),
            section
        }))
    };
}, 2);

/**
 * Returns the section whose area is at a position.
 * conditions:
 *  1. if yPosition is less than 0 then return first section.
 *  2. if yPosition is greater than the page height then return last section.
 *  3. if yPosition is exactly between two sections then return below section.
 */
const getSectionAtPosition = ({ y: yPosition }: Position, componentsMap: ComponentsMap, strict: boolean = false): null | undefined | SectionComponent => {
    const { sections, range } = getSectionRange(componentsMap),
        lastSectionIndex = sections.length - 1;

    if (yPosition < 0 && !strict) { return sections[0]; }

    const { section } = (range.find(({ top, bottom }, index) => {
        if (index === lastSectionIndex && bottom <= yPosition) {
            return true;
        }
        const prevSection = sections[index - 1],
            prevTop = prevSection ? prevSection.top : top;

        if (yPosition >= prevTop && yPosition < bottom) {
            return true;
        }
        return false;
    })) || {};
    if (!section || (strict && (section.top >= yPosition && (section.height + section.top) < yPosition))) {
        return null;
    }
    return section;
};

const getParentSectionForBBox = memoMaxOne(({ top, bottom }: BBox, componentsMap: ComponentsMap, strict: boolean = true) => {
    const y = top + ((bottom - top) / 2);
    return getSectionAtPosition({ y, x: 0 }, componentsMap, strict);
});

const getSectionWhoseEdgeIsInBoxArea = (bBox: BBox, componentsMap: ComponentsMap): AnyComponent => {
    const { top, bottom } = bBox,
        { range: sectionRange } = getSectionRange(componentsMap);
    const result = sectionRange.find(({ bottom: sectionBottom }) => (
        top < sectionBottom &&
        bottom > sectionBottom
    ));
    return result && result.section;
};

// return true if a position on the workspace belongs to header or footer zones
const isPositionInsideTemplateZone = (position: Position, componentsMap: ComponentsMap) => {
        const section = getSectionAtPosition(position, componentsMap);
        return !!section && isHeaderOrFooterSection(section);
    },
    allComponentIdsInTemplate =
    (componentIds: ComponentsIds, componentsMap: ComponentsMap) => componentIds.every(id => componentsMap[id].inTemplate),
    /*
     * return true if some of the components are inTemplate and some inPage.
     */
    someComponentIdsInPageAndTemplate = (componentIds: ComponentsIds, componentsMap: ComponentsMap) => {
        const inTemplate = allComponentIdsInTemplate(componentIds, componentsMap);
        if (componentIds.length < 1) { return false; }
        return inTemplate ? false : componentIds.some(id => componentsMap[id].inTemplate);
    },
    /*
     * returns true if some of the components are templates.
     */
    someComponentsInsideTemplate = (componentIds: Array<string> = [], attachments: Attachments, componentsMap: ComponentsMap): boolean => {
        return !!componentIds.length && componentIds.some(cmpId => {
            const topParentId = getTopMostParentId(cmpId, attachments);
            return isHeaderOrFooterSection(componentsMap[topParentId]);
        });
    },
    validateSectionsArrangement = (componentsMap: ComponentsMap) => {
        const { range } = getSectionRange(componentsMap),
            errorSections: Array<Record<'id' | 'title', any>> = [];
        let prevSectionBottom = 0;
        range.forEach(({ top, bottom, section: { id, title } }) => {
            if (prevSectionBottom !== top) {
                errorSections.push({ id, title });
            }
            prevSectionBottom = bottom;
        });
        return errorSections.length ? errorSections : null;
    };

export {
    isPositionInsideTemplateZone,
    someComponentsInsideTemplate,
    someComponentIdsInPageAndTemplate,
    allComponentIdsInTemplate,
    getSectionAtPosition,
    getParentSectionForBBox,
    getSectionWhoseEdgeIsInBoxArea,
    validateSectionsArrangement,
};
