import React from "react";
export const SvgLinkedinRoundedFillMulticolor = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <rect width={48} height={48} fill="#2867b2" rx={6} />
            <path
                fill="#ffffff"
                d="M21.524 19.512h4.164v2.133h.06c.579-1.038 1.997-2.133 4.11-2.133 4.396 0 5.209 2.734 5.209 6.288v7.24h-4.34v-6.418c0-1.53-.032-3.5-2.258-3.5-2.261 0-2.606 1.667-2.606 3.389v6.53h-4.34v-13.53zm-7.524 0h4.514V33.04H14zm4.514-3.757a2.256 2.256 0 01-2.257 2.254 2.256 2.256 0 110-4.509 2.256 2.256 0 012.257 2.255z"
            />
        </g>
    </svg>
);
