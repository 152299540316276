import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import { SnappingStateSelector } from "../componentsEval/selectorActionTypes";
import dndAddComponentValueActionType from "../../../DndAddComponent/epic/valueActionType";
import { zeroBBox } from "../../../../utils/componentsMap/index";

import type { SnappedPoints } from "../../../../utils/snapping/index";
import type { BBox } from "../../../App/flowTypes";

export type SnappingDecorations = {
    snappedPoints: SnappedPoints,
    componentsBBox?: BBox,
    workspaceBBox: BBox
}

export const snappingDecosDefaultState: SnappingDecorations & { snappedComponentsBBox?: any } = {
    snappedPoints: {},
    componentsBBox: zeroBBox,
    workspaceBBox: zeroBBox
};

export default makeEpic({
    defaultState: snappingDecosDefaultState,
    valueActionType,
    updaters: [
        {
            conditions: [SnappingStateSelector],
            reducer: ({ values: [snappingState] }) => {
                return {
                    state: snappingState
                };
            }
        },
        {
            conditions: [dndAddComponentValueActionType],
            reducer: ({ values: [{ snappingState }] }) => {
                return {
                    state: snappingState
                };
            }
        }
    ]
});
