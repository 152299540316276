import CALL_API from "../../../redux/middleware/api/CALL_API";
import type { ApiAction } from "../../../redux/middleware/api/flowTypes";

export default function (fileName: string, webSpacePath: string, types: [string, string, string]): ApiAction {
    return {
        [CALL_API]: {
            types,
            endpoint: 'uploadToWebspaceFromOneStore',
            endpointParams: [fileName, webSpacePath]
        }
    };
}
