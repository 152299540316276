/* eslint-disable max-len */
import { connect } from "react-redux";
import React from "react";
import * as R from "ramda";
import cx from "classnames";
import styles from "./ThemeOnboardingDialog.css";
import { Dialog, DialogBody, DialogFooter, StripTypes } from "../../../../view/common/dialogs/baseDialog/index";
import { injectIntl, Intl, Msg } from "../../../../view/intl/index";
import { THEME_ONBOARDING_SCREEN_FIRST, THEME_ONBOARDING_SCREEN_SECOND, THEME_ONBOARDING_SCREEN_THIRD } from "../constants";
import { showWaitingScreenAndInitializeComputeThemeColors } from "../../../ThemeGlobalData/actionCreators";
import { closeDialog } from "../../../App/actionCreators/index";
import ThemePagePreviewWithColors from "../../../ThemeGlobalData/view/ThemePagePreviewWithColors";
import Icons from "../../../../view/Icons/index";
import { colorThemeServiceSiteSettingsDataSelector } from "../../../SiteSettings/ColorThemeData/selectorActionTypes";
import type { ColorTabServiceDefaultStateType } from "../../../SiteSettings/ColorThemeData/colorThemeServiceSiteSettingsEpic";
import { THEME_ACTIVATED_WORKSPACE_STATE_LEFT_PANEL } from "../../../ThemeGlobalData/constants";

type Props = {
    dispatch: Dispatch;
    dimensions: {
        height: number;
    };
    themeService: ColorTabServiceDefaultStateType;
    intl: Intl;
};
type State = {
    screenId: string;
};
const screenContentMap = {
    [THEME_ONBOARDING_SCREEN_FIRST]: () => {
        const src = "/wbtgen/videos/autoColorOnboardingAnimation1.mp4";
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <video
                    key={src}
                    autoPlay
                    loop
                    muted
                    style={{
                        width: 712,
                        height: 345,
                        display: "block"
                    }}
                >
                    <source src={src} type="video/mp4" />
                </video>
            </div>
        );
    },
    [THEME_ONBOARDING_SCREEN_SECOND]: () => {
        const src = "/wbtgen/videos/autoColorOnboardingAnimation2.mp4";
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <video
                    key={src}
                    autoPlay
                    loop
                    muted
                    style={{
                        width: 712,
                        height: 345,
                        display: "block"
                    }}
                >
                    <source src={src} type="video/mp4" />
                </video>
            </div>
        );
    },
    [THEME_ONBOARDING_SCREEN_THIRD]: () => (
        <ThemePagePreviewWithColors />
    )
};

const
    makeStepTitle = (currentStep, totalSteps) => (
        <React.Fragment>
            <Msg
                k="common.stepOfTotal"
                params={{
                    currentStep,
                    totalSteps
                }}
                className={styles.step}
            >
                {`{currentStep, number} / {totalSteps, number}`}
            </Msg>
            &nbsp;-&nbsp;
            <Msg k="theme.onboarding.screen.title">get started with colour theming</Msg>
        </React.Fragment>
    ),
    themeOnboardingNextStateMap = {
        [THEME_ONBOARDING_SCREEN_FIRST]: THEME_ONBOARDING_SCREEN_SECOND,
        [THEME_ONBOARDING_SCREEN_SECOND]: THEME_ONBOARDING_SCREEN_THIRD,
        [THEME_ONBOARDING_SCREEN_THIRD]: THEME_ONBOARDING_SCREEN_FIRST
    },
    themeOnboardingPreviousStateMap = R.invertObj(themeOnboardingNextStateMap);

const footerContentMap = {
    [THEME_ONBOARDING_SCREEN_FIRST]: () => (
        <div>
            <div className={styles.firstLabel}>{makeStepTitle(1, 3)}</div>
            <div className={styles.secondLabel}>
                <Msg k="theme.onboarding.screen.one.welcome">Setting your colour theme</Msg>
            </div>
            <div className={styles.thirdLabel}>
                <Msg
                    params={{
                        icon: <Icons.COLOR_THEME_ICON_BLUE className={styles.colorThemeIcon} />
                    }}
                    k="theme.onboarding.screen.one.welcome.description"
                >{`
                    Find your colour theme by clicking the {icon}-button in the bottom-right corner of your workspace.
                    Here you can choose from a selection of predefined palettes or edit your current colour theme.
                `}</Msg>
            </div>
        </div>
    ),
    [THEME_ONBOARDING_SCREEN_SECOND]: () => (
        <div>
            <div className={styles.firstLabel}>{makeStepTitle(2, 3)}</div>
            <div className={styles.secondLabel}>
                <Msg k="theme.onboarding.screen.two.welcome">Using your colour theme</Msg>
            </div>
            <div className={styles.thirdLabel}>
                <Msg k="theme.onboarding.screen.two.welcome.description">
                    Use your colour theme to style groups of components quick and easy. Set a background colour for a strip or box and see
                    how the components inside automatically update.
                </Msg>
            </div>
        </div>
    ),
    [THEME_ONBOARDING_SCREEN_THIRD]: ({ intl, themeActivatedWorkspaceState }) => (
        <div>
            <div className={styles.firstLabel}>{makeStepTitle(3, 3)}</div>
            <div className={styles.secondLabel}>{intl.msgJoint("msg: trackingTab.google.ads.youAreSet {You’re all set!}")}</div>
            <div className={styles.thirdLabel}>
                {themeActivatedWorkspaceState === THEME_ACTIVATED_WORKSPACE_STATE_LEFT_PANEL ? (
                    <Msg k="theme.onboarding.screen.three.welcome.description.changedText">
                        We created the colour theme above to get you started. If you prefer a different one, you can always change it in the sidebar on the left.
                    </Msg>
                ) : (
                    <Msg
                        params={{
                            icon: <Icons.COLOR_THEME_ICON_BLUE className={styles.colorThemeIcon} />
                        }}
                        k="theme.onboarding.screen.three.welcome.description.changedText2"
                    >{`
                            We created the colour theme above to get you started. If you prefer a different one, you can always change it by clicking the {icon}-button in the bottom right corner of your workspace.
                        `}</Msg>
                )}
            </div>
        </div>
    )
};

class ThemeOnboardingDialog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            screenId: THEME_ONBOARDING_SCREEN_FIRST
        };
    }

    render() {
        const { screenId } = this.state,
            {
                dispatch,
                dimensions: { height },
                themeService: { themeActivatedWorkspaceState },
                intl
            } = this.props,
            pagePreviewScrollHeight = Math.min(height - parseInt(styles.FooterHeight, 10), 500),
            onNext = () => {
                if (screenId === THEME_ONBOARDING_SCREEN_THIRD) {
                    dispatch(showWaitingScreenAndInitializeComputeThemeColors());
                    dispatch(closeDialog());
                }

                this.setState({
                    screenId: themeOnboardingNextStateMap[screenId]
                });
            },
            tctaProps =
                screenId === THEME_ONBOARDING_SCREEN_FIRST
                    ? {}
                    : {
                        tctaText: "msg: common.goBack {Go back}",
                        tctaHandler: () =>
                            this.setState({
                                screenId: themeOnboardingPreviousStateMap[screenId]
                            }),
                        tctaButtonClassName: styles.goBack
                    };

        return (
            <Dialog stripType={StripTypes.NONE} className={styles.dialogMain}>
                <DialogBody className={styles.dialogBody}>
                    {screenContentMap[screenId]({
                        pagePreviewScrollHeight
                    })}
                </DialogBody>
                <DialogFooter
                    mctaText={
                        screenId === THEME_ONBOARDING_SCREEN_THIRD
                            ? "msg: common.applyColourTheme {Apply colour theme}"
                            : "msg: common.next {Next}"
                    }
                    mctaHandler={onNext}
                    className={cx(styles.footer, styles.container)}
                    footerButtonContainerClassName={styles.footerButton}
                    primaryBtnClass={styles.footerPrimaryBtn}
                    sctaBtnClass={styles.footerSctaBtn}
                    contentClassName={styles.footerContentClassName}
                    sctaHandler={() => dispatch(closeDialog())}
                    {...tctaProps}
                    autoFocus
                >
                    {footerContentMap[screenId]({
                        intl,
                        themeActivatedWorkspaceState
                    })}
                </DialogFooter>
            </Dialog>
        );
    }
}

const mapStateToProps = appState => ({
    themeService: colorThemeServiceSiteSettingsDataSelector(appState)
});

export default injectIntl(connect(mapStateToProps)(ThemeOnboardingDialog));
