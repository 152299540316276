/* eslint-disable max-len */

import cx from "classnames";
import React from "react";
import { TrialAuthPage } from "../../../../../../server/shared/trial/TrialAuthPage";
import {
    EMAIL_ALREADY_VERIFIED_PAGE,
    EMAIL_VERIFICATION_SUCCESSFUL_PAGE,
    FORGOT_PASSWORD_PAGE, MOBILE_BLOCKING_PAGE,
    RESET_PASSWORD_PAGE,
    SIGNIN_PAGE,
    SIGNUP_PAGE, TRIAL_ENDED_PAGE, USER_NOT_FOUND_PAGE, VERIFICATION_EMAIL_REQUIRED_PAGE
} from "../../constants";
import { showSignInPage } from "../../signUp/actions";
import { cleanUpSigninScreen, showForgotPasswordPage, showSignUpPage } from "../../signIn/actions";
import { showResetPasswordPage } from "../../resetPassword/actions";
import { showEmailVerificationPage } from "../../emailVerification/actions";
import { showTrialEndedPage } from "../../trialEnded/actions";
import { showEmailAlreadyVerifiedPage } from "../../emailAlreadyVerified/actions";
import { showUserNotFoundPage } from "../../userNotFound/actions";
import { showVerificationEmailRequiredPageAction } from "../../verificationEmailRequired/actions";
import { showMobileBlockingPage } from "../../mobileBlocking/actions";
import { DemoLoginMessages } from "../messages";
import styles from "./DemoLoginPage.css";
import { isCompactScreen, isMobileDevice } from "../../validators";
import { Signin } from "../../signIn/components/Signin";
import { Signup } from "../../signUp/components/Signup";
import { ForgotPassword } from "../../forgotPassword/components/ForgotPassword";
import { ResetPassword } from "../../resetPassword/components/ResetPassword";
import { EmailVerification } from "../../emailVerification/components/EmailVerificationPage";
import { TrialEnded } from "../../trialEnded/components/TrialEndedPage";
import { EmailAlreadyVerifiedPage } from "../../emailAlreadyVerified/components/EmailAlreadyVerifiedPage";
import { UserNotFoundPage } from "../../userNotFound/components/UserNotFoundPage";
import { VerificationEmailRequiredPage } from "../../verificationEmailRequired/components/VerificationEmailRequiredPage";
import { MobileBlockingPage } from "../../mobileBlocking/components/MobileBlockingPage";
import Msg from "../../../../../src/view/intl/Msg";
import TrialLanguageSelector from "../TrialLanguageSelector";
import type { DemoLoginPageRouterPropTypes } from "../../types";
import { getAppConfig } from "../../../../../src/components/App/epics/appConfig/appConfig";
import TermsnConditionsUrlLink from '../../../dataPrivacyLinks/TermsnConditionsUrlLink';
import PrivacyPolicyUrlLink from '../../../dataPrivacyLinks/PrivacyPolicyUrlLink';
import CookiePolicyUrlLink from '../../../dataPrivacyLinks/CookiePolicyUrlLink';

const DemoLoginPageRouter = ({
    intl,
    dispatch,
    languages,
    signin,
    signup,
    forgotPassword,
    resetPassword,
    currentPage,
    verificationEmailRequired,
    queryParams
}: DemoLoginPageRouterPropTypes) => {
    const appConfig = getAppConfig();
    const trialConfig = appConfig.oneWeb.trial;
    const loginProps = { intl, dispatch, appConfig, ...queryParams };
    const signInProps = { ...loginProps, ...signin };
    const signUpProps = { ...loginProps, ...signup, currentLanguage: languages.current };
    const forgotPasswordProps = { ...loginProps, ...forgotPassword };
    const resetPasswordProps = { ...resetPassword, ...loginProps, };
    const trialEndedProps = { ...loginProps, userTrialEndedDetails: signin.userTrialEndedDetails };
    const verificationEmailRequiredProps = { verificationEmailRequired, ...loginProps, email: signin.email };
    const fragmentIdentifier = window.location.hash.replace(/^#/, "");
    const showAppropriatePage = () => {
            if (fragmentIdentifier === TrialAuthPage.SIGNIN && currentPage !== SIGNIN_PAGE) {
                dispatch(showSignInPage());
            } else if (fragmentIdentifier === TrialAuthPage.SIGNUP && currentPage !== SIGNUP_PAGE) {
                dispatch(showSignUpPage());
            } else if (fragmentIdentifier === TrialAuthPage.FORGOT_PASSWORD && currentPage !== FORGOT_PASSWORD_PAGE) {
                dispatch(showForgotPasswordPage());
            } else if (fragmentIdentifier === TrialAuthPage.RESET_PASSWORD && currentPage !== RESET_PASSWORD_PAGE) {
                dispatch(showResetPasswordPage());
            } else if (fragmentIdentifier === TrialAuthPage.VERIFICATION &&
                currentPage !== EMAIL_VERIFICATION_SUCCESSFUL_PAGE) {
                dispatch(showEmailVerificationPage());
            } else if (fragmentIdentifier === TrialAuthPage.TRIAL_ENDED && currentPage !== TRIAL_ENDED_PAGE) {
                dispatch(showTrialEndedPage());
            } else if (fragmentIdentifier === TrialAuthPage.EMAIL_ALREADY_VERIFIED && currentPage !== EMAIL_ALREADY_VERIFIED_PAGE) {
                dispatch(showEmailAlreadyVerifiedPage());
            } else if (fragmentIdentifier === TrialAuthPage.USER_NOT_FOUND && currentPage !== USER_NOT_FOUND_PAGE) {
                dispatch(showUserNotFoundPage());
                // eslint-disable-next-line max-len
            } else if (fragmentIdentifier === TrialAuthPage.VERIFICATION_EMAIL_REQUIRED && currentPage !== VERIFICATION_EMAIL_REQUIRED_PAGE) {
                dispatch(showVerificationEmailRequiredPageAction());
            } else if (fragmentIdentifier === TrialAuthPage.MOBILE_DEVICES_BLOCKED && currentPage !== MOBILE_BLOCKING_PAGE) {
                dispatch(showMobileBlockingPage());
            }

            return null;
        },
        {
            websiteBuilderPageLink
        } = DemoLoginMessages,
        endDateYear = new Date().getFullYear(),
        startYear = trialConfig.startYear,
        partnerName = trialConfig.label,
        termsOfUse = (
            <TermsnConditionsUrlLink
                className={styles.footerMsgLink}
            />),
        privacyPolicy = (
            <PrivacyPolicyUrlLink
                className={styles.footerMsgLink}
            />
        ),
        cookiePolicy = (
            <CookiePolicyUrlLink
                className={styles.footerMsgLink}
            />
        );

    return (
        <div className={styles.loginPageContainer}>
            <div className={styles.logoContainer}>
                <span className={styles.oneComIcon} />
                {currentPage !== TRIAL_ENDED_PAGE &&
                <span
                    className={styles.crossIcon}
                    onClick={() => {
                        window.location.href = intl.msgJoint(websiteBuilderPageLink);
                    }}
                />}
                {currentPage === TRIAL_ENDED_PAGE &&
                <span
                    className={styles.logout}
                    onClick={() => {
                        dispatch(cleanUpSigninScreen());
                        dispatch(showSignInPage());
                    }}
                >
                    {intl.msgJoint('msg: common.logout {Log out}')}
                </span>}
            </div>
            <div className={cx(styles.loginContainer, {
                [styles.emptyLoginContainer]: (currentPage === MOBILE_BLOCKING_PAGE),
                [styles.emptyLoginContainerForMobile]: (isMobileDevice() && isCompactScreen()),
                [styles.loginContainerSignup]: (currentPage === SIGNUP_PAGE)
            })}
            >
                {showAppropriatePage()}
                { currentPage === SIGNIN_PAGE && <Signin {...signInProps} /> }
                { currentPage === SIGNUP_PAGE && <Signup {...signUpProps} /> }
                { currentPage === FORGOT_PASSWORD_PAGE && <ForgotPassword {...forgotPasswordProps} /> }
                { currentPage === RESET_PASSWORD_PAGE && <ResetPassword {...resetPasswordProps} /> }
                {
                    currentPage === EMAIL_VERIFICATION_SUCCESSFUL_PAGE &&
                    // @ts-ignore
                    <EmailVerification {...loginProps} />
                }
                { currentPage === TRIAL_ENDED_PAGE && <TrialEnded {...trialEndedProps} />}
                { currentPage === EMAIL_ALREADY_VERIFIED_PAGE && <EmailAlreadyVerifiedPage {...trialEndedProps} />}
                { currentPage === USER_NOT_FOUND_PAGE && <UserNotFoundPage {...trialEndedProps} />}
                {/* eslint-disable-next-line max-len */}
                { currentPage === VERIFICATION_EMAIL_REQUIRED_PAGE && <VerificationEmailRequiredPage {...verificationEmailRequiredProps} />}
                {currentPage === MOBILE_BLOCKING_PAGE && <MobileBlockingPage {...loginProps} />}
            </div>
            {/* eslint-disable-next-line max-len */}
            <div className={cx(styles.loginFooter, { [styles.loginFooterLanguageSelectorStyles]: (!isCompactScreen() || !isMobileDevice()) })}>
                <div className={cx(styles.invisibleItem, { [styles.removeInvisibleDiv]: (isCompactScreen() && isMobileDevice()) })} />
                <Msg
                    k="demo.footer.msg"
                    params={{ endYear: endDateYear,
                        startYear,
                        partnerName,
                        termsOfUse,
                        privacyPolicy,
                        cookiePolicy }}
                >
                    {`Copyright © {startYear} - {endYear} {partnerName}. All rights reserved. {cookiePolicy}, {privacyPolicy} and {termsOfUse}.`}
                </Msg>
                {(!isCompactScreen() || !isMobileDevice()) &&
                <div className={styles.languageSelector}>
                    <TrialLanguageSelector
                        // @ts-ignore
                        languages={languages}
                    />
                </div>}
            </div>
        </div>
    );
};

export {
    DemoLoginPageRouter
};
