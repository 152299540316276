import { parseDOM } from "../../../../../utils/parseDOM";
import { type SoundcloudComponent } from '../types';

export const parseSoundcloudHTMLCode = (code: string): SoundcloudComponent => {
    let params = <SoundcloudComponent>{};

    const configs = [
        {
            nodeName: 'IFRAME',
            validator: node => node.src.startsWith("https://w.soundcloud.com/player/?url="),
            parser: node => {
                const src = node.src;
                return {
                    link: src.split('url=')[1].split('&')[0],
                    color: src.split('&color=%23')[1].split('&')[0],
                    autoplay: src.split('&auto_play=')[1].split('&')[0] === 'true',
                };
            }
        }
    ];

    params = parseDOM(configs, code);

    return params;
};
