import React from "react";
import cx from 'classnames';
import { injectIntl } from "../../../view/intl/index";
import loadingStyles from '../../../view/common/LoadingIndicator/LoadingIndicator.css';
import styles from './Backup.css';

export default injectIntl(({ intl, containerClassName, message }) => (
    <div className={cx(styles['restoring-fullscreen'], styles[containerClassName])}>
        <div>
            <div className={cx(loadingStyles.animatedLoadingDots, loadingStyles.greenDots)}>
                <div />
                <div />
                <div />
            </div>
            <div className={loadingStyles.subtext}>
                { message ? intl.msgJoint(message) : intl.msgJoint('msg: web.Restoring {Restoring...}') }
            </div>
        </div>
    </div>
));
