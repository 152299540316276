import * as R from 'ramda';
import type { Path } from "../mappers/path";
import { getByPath, setToPath } from "../utils/ramdaEx";
import p from "../utils/pipePath";
import * as mp from "../mappers/path"; // eslint-disable-line
import { opacityToAlpha, setColorAlpha } from "../components/oneweb/Background/utils/index";
import {
    DefaultAssetData,
    ReverseBackgroundRepeat
} from "../components/presentational/BackgroundImageSettings/options";
import { unsetGradientColor } from "./gradientSetter";
import type { Color } from '../mappers/flowTypes';
import type { AssetPosition, AssetSize, AssetData } from "../mappers/background/flowTypes";
import type { ImageAsset } from "../components/App/flowTypes";
import { DefaultGradient } from "../components/presentational/ColorProperty/constants";
import { getBackgroundAlpha } from "../getters/backgroundGetters";
import type { ThemeColorTypes } from '../components/ThemeGlobalData/flowTypes';

const
    unsetBackgroundColor = R.curry((backgroundPath: Path, data: any) =>
        setToPath(p(backgroundPath, mp.colorData, mp.color), null, data)),
    unsetBackgroundThemeColor = R.curry((backgroundPath: Path, data: any) =>
        setToPath(p(backgroundPath, mp.colorData, 'themeColor'), null, data)),
    setBackgroundSolidColor = R.curry((backgroundPath: Path, inColor: null | undefined | Color, data: any) => {
        if (!inColor) {
            return unsetBackgroundColor(backgroundPath, data);
        }

        const alpha = getBackgroundAlpha(backgroundPath, data),
            color = setColorAlpha(inColor, alpha);

        let newData = setToPath(p(backgroundPath, mp.colorData, mp.color), color, data);

        // WBTGEN-4164 Correcting opacity of GradientColor
        const prevGradientColor = getByPath(p(backgroundPath, mp.colorData, mp.gradient, mp.color), data);
        if (prevGradientColor) {
            newData = setToPath(p(backgroundPath, mp.colorData, mp.gradient, mp.color),
                setColorAlpha(prevGradientColor, alpha), newData);
        }

        return newData;
    }),
    setBackgroundSolidThemeColor = R.curry((backgroundPath: Path, inColor: ThemeColorTypes, data: any) => {
        if (!inColor) {
            return unsetBackgroundThemeColor(backgroundPath, data);
        }

        return setToPath(p(backgroundPath, mp.colorData, 'themeColor'), inColor, data);
    }),
    setBackgroundSolidColorIfNotSet = R.curry((backgroundPath: Path, inColor: null | undefined | Color, data: any) => {
        const
            prevSolidColor = getByPath(p(backgroundPath, mp.colorData, mp.color), data);

        if (!prevSolidColor) {
            return setBackgroundSolidColor(backgroundPath, inColor, data);
        }
        return data;
    }),
    setBackgroundGradientColor = R.curry((backgroundPath: Path, inColor: null | undefined | Color, data: any) => {
        if (!inColor) {
            return unsetGradientColor(p(backgroundPath, mp.colorData, mp.gradient), data);
        }

        const alpha = getBackgroundAlpha(backgroundPath, data),
            color = setColorAlpha(inColor, alpha);
        let newData = null;
        const prevGradient = getByPath(p(backgroundPath, mp.colorData, mp.gradient), data);
        if (prevGradient) {
            newData = setToPath(p(backgroundPath, mp.colorData, mp.gradient, mp.color), color, data);
        } else {
            newData = setToPath(p(backgroundPath, mp.colorData, mp.gradient), { ...DefaultGradient, color }, data);
        }

        // WBTGEN-4164 Correcting opacity of solidColor
        const prevSolidColor = getByPath(p(backgroundPath, mp.colorData, mp.color), data);
        if (prevSolidColor) {
            newData = setToPath(p(backgroundPath, mp.colorData, mp.color),
                setColorAlpha(prevSolidColor, alpha), newData);
        }
        return newData;
    }),
    setBackgroundGradientThemeColor = R.curry((backgroundPath: Path, inColor: ThemeColorTypes, data: any) => {
        if (!inColor) {
            return unsetGradientColor(p(backgroundPath, mp.colorData, mp.gradient), data);
        }

        let newData = null;
        const prevGradient = getByPath(p(backgroundPath, mp.colorData, mp.gradient), data);
        if (prevGradient) {
            newData = setToPath(p(backgroundPath, mp.colorData, mp.gradient, 'themeColor'), inColor, data);
        } else {
            newData = setToPath(p(backgroundPath, mp.colorData, mp.gradient), { ...DefaultGradient, themeColor: inColor }, data);
        }
        return newData;
    }),
    setBackgroundOpacity = R.curry((backgroundPath: Path, opacity: number, data: any) => {
        const alpha = opacityToAlpha(opacity);
        let newData = data;

        // solid color
        const solidColorPath = p(backgroundPath, mp.colorData, mp.colorAlpha);
        if (typeof getByPath(solidColorPath, newData) === 'number') {
            newData = setToPath(solidColorPath, alpha, newData);
        }

        // gradient color
        const gradientColorPath = p(backgroundPath, mp.colorData, mp.gradient, mp.colorAlpha);
        if (typeof getByPath(gradientColorPath, newData) === 'number') {
            newData = setToPath(gradientColorPath, alpha, newData);
        }

        return newData;
    }),
    setBackgroundImageOpacity = R.curry((backgroundPath: Path, opacity: number, data: any) =>
        setToPath(backgroundPath, opacityToAlpha(opacity), data)),
    unsetAsset = (backgroundPath: Path, data: any) =>
        setToPath(p(backgroundPath, mp.assetData), null, data),
    setAsset = (
        backgroundPath: Path,
        asset: ImageAsset,
        data: any,
        defaultAssetData: null | undefined | AssetData = DefaultAssetData
    ) => {
        const
            path = p(backgroundPath, mp.assetData),
            assetData = getByPath(path, data) || defaultAssetData;

        if (R.isNil(data)) {
            return unsetAsset(backgroundPath, data);
        }

        return setToPath(path, { ...assetData, asset }, data);
    },
    setAssetRepeat = (backgroundPath: Path, repeat: string, data: any) =>
        setToPath(p(backgroundPath, mp.assetData, mp.repeat), ReverseBackgroundRepeat[repeat], data),
    setAssetOverlay = (backgroundPath: Path, overlay: string, data: any) =>
        setToPath(p(backgroundPath, mp.assetData, mp.overlay), overlay, data),
    setAssetPosition = (backgroundPath: Path, position: AssetPosition, data: any) =>
        setToPath(p(backgroundPath, mp.assetData, mp.position), position, data),
    setAssetSize = (backgroundPath: Path, size: AssetSize, data: any) =>
        setToPath(p(backgroundPath, mp.assetData, mp.size), size, data),
    setMobileAssetSize = (path: Path, size: AssetSize, data: any) =>
        setToPath(p(path), size, data),
    setAssetScrollEffect = (backgroundPath: Path, scrollEffect?: string|null, data?: any) => {
        const path = p(backgroundPath, mp.assetData, mp.scrollEffect);
        return setToPath(path, scrollEffect, data);
    };

export {
    setBackgroundSolidColor,
    setBackgroundSolidThemeColor,
    setBackgroundSolidColorIfNotSet,
    setBackgroundGradientColor,
    setBackgroundGradientThemeColor,
    unsetBackgroundColor,
    setBackgroundOpacity,
    setBackgroundImageOpacity,
    setAsset,
    unsetAsset,
    setAssetRepeat,
    setAssetOverlay,
    setAssetPosition,
    setAssetSize,
    setMobileAssetSize,
    setAssetScrollEffect
};
