import { withSelector } from '../../../../epics/makeCondition';
import valueActionType from './valueActionType';
import type { ScrollEpicState } from "./flowTypes";

const
    SYNC_SCROLL_TO_DOM = 'SYNC_SCROLL_TO_DOM',
    ySelector = ({ y }: ScrollEpicState) => y,
    VerticalScrollSelector = withSelector(valueActionType, ySelector),
    xSelector = ({ x }: ScrollEpicState) => x,
    horizontalScrollSelector = withSelector(valueActionType, xSelector);

export {
    VerticalScrollSelector,
    horizontalScrollSelector,
    SYNC_SCROLL_TO_DOM
};
