import React from 'react';
import cx from 'classnames';
import styles from './LinkChooserOption.css';

export type LinkChooserOptionProps = {
    contentRenderer: Function;
    isActive: boolean;
    onClick: () => void;
    title: any;
};

export const LinkChooserOption = (props: LinkChooserOptionProps) => {
    return <div
        className={cx(styles.linkChooserOptionWrapper, { [styles.active]: props.isActive })}
        onClick={() => !props.isActive && props.onClick()}
    >
        <div className={styles.linkChooserOptionHandle} />
        <div
            className={styles.linkChooserOptionTitle}
            onClick={props.onClick}
        >{props.title}</div>
        <div
            className={styles.linkChooserOptionBody}
            style={{ display: props.isActive ? 'block' : 'none' }}
        >{props.contentRenderer()}</div>
    </div>;
};
