import type { StateHolder } from "../../flowTypes";
import type { Position } from "../../../../redux/modules/flowTypes";

export default (mousePosition: Position, { scope, state }: StateHolder): StateHolder => {
    const { position } = state;
    return {
        scope: {
            ...scope,
            actualPosition: position,
            startMovePosition: position,
            startMousePosition: mousePosition,
            isMoving: true
        },
        state: {
            ...state,
            animateMovement: false
        }
    };
};
