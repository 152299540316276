import * as R from 'ramda';
import { Style } from '@one.com/radium-one.com';
import webshopActionTypes from '../../oneweb/WebShop/reducer/actionTypes';

export function UNSAFE_componentWillMount(this: any) {
    const styleProp = R.path(['state', 'styleProp'], this),
        newStyleProp = R.path(['props', 'state', 'styleProp'], this);

    if (
        newStyleProp === styleProp
        || !R.is(Object, newStyleProp)
    ) {
        return false;
    }

    this.setState({
        styleProp: newStyleProp
    });
    this.props.dispatch({
        type: webshopActionTypes.WEBSHOP_BUTTON_STYLES_GENERATED,
        payload: this._buildStyles(this.props.state.styleProp),
        amendTo: webshopActionTypes.WEBSHOP_BUTTON_STYLE_CHANGED
    });
    return true;
}

export default class StyleRenderer extends Style {
    componentDidUpdate() {
        return UNSAFE_componentWillMount.call(this);
    }

    UNSAFE_componentWillMount() {
        return UNSAFE_componentWillMount.call(this);
    }
}
