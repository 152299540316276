export const
    DialogWidth = 570,
    DialogHeight = 220,
    PopupWindowWidth = 973,
    PopupWindowHeight = 798,
    DialogMessages = {
        TITLE: 'msg: component.facebookFeedGalery.accountDisconnected {Facebook account disconnected}',
        MESSAGE: 'msg: component.facebookFeedGalery.accountDisconnected.message {Your account has been disconnected by Facebook, who require users to reauthorise every 60 days. Reconnect again to continue showing your feed on your site.}', // eslint-disable-line max-len
        RECONNECT_LABEL: 'msg: component.facebookFeedGalery.accountDisconnected.reconnectLabel {Reconnect Facebook}',
        CANCEL_LABEL: 'msg: component.facebookFeedGalery.accountDisconnected.cancel {Cancel}'
    };
