// @ts-nocheck
/* globals $R */

import React from 'react';
import axios from 'axios';

const GMBDisconnect = function () {
    const domain = $R.store.getState().preferences.owner;
    const url = `/api/v1/${domain}/gmb/disconnect`;

    const disconnect = async function () {
        try {
            await axios.get(url);
            alert('Disconnected');
        } catch (e: any) {
            alert('Unable to disconnect');
        }
    };

    return (
        <button
            type="button"
            onClick={async function () {
                await disconnect();
            }}
        >
            Disconnect
        </button>
    );
};

export {
    GMBDisconnect
};
