export const
    DEMO_SHOW_EMAIL_VERIFICATION_PAGE_ACTION = 'DEMO_SHOW_EMAIL_VERIFICATION_PAGE_ACTION',
    showEmailVerificationPage = () => ({
        type: DEMO_SHOW_EMAIL_VERIFICATION_PAGE_ACTION
    });

export const
    TRACK_EMAIL_VERIFICATION_GOAL = 'TRACK_EMAIL_VERIFICATION_GOAL',
    trackEmailVerificationGoal = () => ({
        type: TRACK_EMAIL_VERIFICATION_GOAL
    });
