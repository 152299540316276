import React from 'react';
import cx from 'classnames';
import { isTrialSubscription, isUnknownSubscription } from "../../../App/epics/subscriptionData/utils";
import { GOOGLE_REVIEWS } from '../../../oneweb/componentKinds';
import * as styles from '../../view/Inserter.css';
import { PremiumIcon } from "../../../../view/common/PremiumIcon/index";
import { checkSubscriptionCompatibilityFromKind,
    isComponentOrFeatureTierDisabledByConfiguration } from '../../../ComponentTierManager/utils';
import type { ExtendedPanelContentProps } from "../../view/inserter";
import handleDisableTip from "../../ToolTip/handleDisableIconTip";
import { DroppableCmpShortcut } from "../../view/DroppableCmpShortcut";
import VerticalSpacer from "../../../../view/common/VerticalSpacer";
import { EPSeparator } from "../../view/EPSeparator";
import Msg from "../../../../view/intl/Msg";
import getFeatureManager from '../../../../getFeatureManager';

const GoogleReviewsInserter = (props: ExtendedPanelContentProps) => {
    const { subscriptionType } = props;
    const isGoogleReviewsEnabled = getFeatureManager().isGoogleReviewsComponentEnabled();
    const isUserPremiumCompatible = checkSubscriptionCompatibilityFromKind(GOOGLE_REVIEWS);
    const mouseHoverHandler = handleDisableTip({
        dispatch: props.dispatch,
        id: isGoogleReviewsEnabled ?
            `${GOOGLE_REVIEWS}ForceDisabled` :
            `GenericFeatureDisabled`
        });
    const isDemoUserOrUnknown = isTrialSubscription(subscriptionType) || isUnknownSubscription(subscriptionType);
    const showPremiumIcon = !isUserPremiumCompatible || isDemoUserOrUnknown;

    const getGoogleReviewsInserterDiv = () => {
        return <React.Fragment>
            <div className={styles.socialMediaFeedLogoContainer}>
                { showPremiumIcon && <PremiumIcon iconCustomClass={styles.premiumSocialMediaIcon} />}
                <div className={cx(styles.googleReviewsLogo)} />
            </div>
            <div className={styles.socialMediaFeedDescriptionContainer}>
                <Msg
                    k="common.googleReviews"
                    className={styles.socialMediaFeedTitle}
                >Google reviews</Msg>
                <br />
                <Msg
                    k="googleReviewsInserter.subtitle"
                    className={styles.socialMediaFeedSubtitle}
                >Display Google reviews</Msg>
            </div>
        </React.Fragment>;
    };
    if (isDemoUserOrUnknown || !isGoogleReviewsEnabled) {
        return (
            <div className={cx(styles.socialMediaFeedInserter, styles.disableFeature)} {...mouseHoverHandler}>
                {getGoogleReviewsInserterDiv()}
            </div>
        );
    }
    return (
        <React.Fragment>
            <DroppableCmpShortcut
                {...props}
                kind={GOOGLE_REVIEWS}
                isOverlayExtended
            >
                <div className={cx(styles.socialMediaFeedInserter)}>
                    {getGoogleReviewsInserterDiv()}
                </div>
            </DroppableCmpShortcut>
        </React.Fragment>
    );
};

const ReviewsInserter = (props: ExtendedPanelContentProps) => {
    const DROPPABLE_KINDS = [GOOGLE_REVIEWS];
    const isComponentEligible = (kind) => !isComponentOrFeatureTierDisabledByConfiguration(kind);
    const hasAtleastOneEligibleCmp = DROPPABLE_KINDS.some(isComponentEligible);
    const Title = () => (
        <React.Fragment>
            <EPSeparator />
            <VerticalSpacer y={20} />
            <div className={styles.subTitle}>{props.intl.msgJoint('msg: googleReviewsInserter.header {Reviews}')}</div>
            <VerticalSpacer y={20} />
        </React.Fragment>
    );
    return (
        <React.Fragment>
            {hasAtleastOneEligibleCmp && <Title />}
            <GoogleReviewsInserter {...props} />
        </React.Fragment>
    );
};

export default ReviewsInserter;
