import React from "react";
import * as R from 'ramda';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import BorderSettings from "../../../../presentational/BorderSettings/index";
import { alphaToOpacity } from "../../../Background/utils/index";
import * as mp from "../../../../../mappers/path";
import * as pageIds from "../pageIds";
import pagesTitles from "../pagesTitles";
import { getCellToReadValues, getGlobalstyleById } from "../../utils";
import * as actionTypes from "../../actionTypes";
import * as selectors from "../../../../Workspace/epics/stylesheets/selectors";
import type { PropertyPanelViewProps } from "../../flowTypes";
import { getThemeRulesForBackground } from '../../../../ThemeGlobalData/themeRules';

const
    id = pageIds.Border,
    title = pagesTitles[id],
    view = (props: PropertyPanelViewProps) => {
        const
            { stylesheets, selectedComponent, tableEditModeState, dispatch } = props,
            cell = getCellToReadValues(selectedComponent, tableEditModeState),
            cellGlobalstyle = getGlobalstyleById(stylesheets)(cell.style.globalId);

        let
            color = selectors.getBorderColor(mp.style)(cell),
            style = color && selectors.getBorderStyle(mp.style)(cell),
            widths = color && selectors.getBorderWidth(mp.style)(cell),
            opacity = color && parseFloat(selectors.getBorderColorOpacity(mp.style)(cell)),
            colorRemoveAction: string | null = (
                props.themeSettingsData.autoColorMode
                    ? actionTypes.TABLE_CELL_BORDER_COLOR_REMOVED_AUTO_COLOR
                    : actionTypes.TABLE_CELL_BORDER_COLOR_REMOVED
            );

        const getBorderPropertiesFromCellGlobalstyle = () => {
            style = selectors.getBorderStyle()(cellGlobalstyle);
            widths = selectors.getBorderWidth()(cellGlobalstyle);
            // Clone color. Pass by reference is changing the global style value.
            color = R.clone(selectors.getBorderColor()(cellGlobalstyle));
        };
        if (!color) {
            getBorderPropertiesFromCellGlobalstyle();
            opacity = selectors.getBorderColorOpacity()(cellGlobalstyle);
        // Pass border-color information to borderSettingsView to render border opacity in Properties Panel.
        } else if ((color[0] === undefined || color[0] === null) && opacity !== null) {
            getBorderPropertiesFromCellGlobalstyle();
            color[4] = opacity;
        }

        const themeColor = R.path(['style', 'block', 'border', 'themeColor'], cell),
            defaultThemeColor = getThemeRulesForBackground(props.selectedComponentProps.selectedParentTheme, props.themeColorsData).text;

        if (
            (props.themeSettingsData.autoColorMode && !themeColor)
            || (!props.themeSettingsData.autoColorMode && !color)
        ) {
            colorRemoveAction = null;
        }

        return (
            <Page>
                {
                    props.themeSettingsData.autoColorMode
                        ? <BorderSettings.view
                            autoColorMode={props.themeSettingsData.autoColorMode}
                            themeColor={themeColor || defaultThemeColor}
                            themeColorsData={props.themeColorsData}
                            selectedComponent={selectedComponent}
                            style={style}
                            styleChangeAction={actionTypes.TABLE_CELL_BORDER_STYLE_CHANGED}
                            colorChangeAction={actionTypes.TABLE_CELL_BORDER_COLOR_CHANGED_AUTO_COLOR}
                            colorRemoveAction={colorRemoveAction}
                            opacity={opacity ? alphaToOpacity(parseFloat(opacity)) : opacity}
                            opacityChangeAction={actionTypes.TABLE_CELL_BORDER_OPACITY_CHANGED}
                            widths={widths}
                            widthChangeAction={actionTypes.TABLE_CELL_BORDER_WIDTH_CHANGED}
                            showCorners={false}
                            dispatch={dispatch}
                        />
                        : <BorderSettings.view
                            selectedComponent={selectedComponent}
                            style={style}
                            styleChangeAction={actionTypes.TABLE_CELL_BORDER_STYLE_CHANGED}
                            color={color}
                            colorChangeAction={actionTypes.TABLE_CELL_BORDER_COLOR_CHANGED}
                            colorRemoveAction={colorRemoveAction}
                            opacity={opacity ? alphaToOpacity(parseFloat(opacity)) : opacity}
                            opacityChangeAction={actionTypes.TABLE_CELL_BORDER_OPACITY_CHANGED}
                            widths={widths}
                            widthChangeAction={actionTypes.TABLE_CELL_BORDER_WIDTH_CHANGED}
                            showCorners={false}
                            dispatch={dispatch}
                        />
                }
            </Page>
        );
    };

export { id, title, view };
