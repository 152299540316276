import { DATA_PAGE_REF_TYPE } from "../../../../dal/model/utils/dataSiteItemUtils";

export default {
    type: DATA_PAGE_REF_TYPE,
    url: "",
    isHome: false,
    hidden: false,
    public: false,
    pageId: "page-id"
};
