
import type { DayOfTheWeek, SiteSettingsOpeningHoursMapValue } from '../../../oneweb/OpeningHours/flowTypes';

export const TOGGLE_OPENING_HOURS_FOR_THE_DAY_ACTION = 'TOGGLE_OPENING_HOURS_FOR_THE_DAY_ACTION';
export const UPDATE_FROM_OPENING_HOURS_ACTION = 'UPDATE_FROM_OPENING_HOURS_ACTION';
export const UPDATE_TO_OPENING_HOURS_ACTION = 'UPDATE_TO_OPENING_HOURS_ACTION';

export const toggleOpeningHoursForTheDayAction = (payload: {
    day: DayOfTheWeek,
    prevDay: DayOfTheWeek,
}) => ({
    type: TOGGLE_OPENING_HOURS_FOR_THE_DAY_ACTION,
    payload,
});

export const updateFromOpeningHoursAction = (payload: {
    openingHoursForTheDay: SiteSettingsOpeningHoursMapValue,
    day: DayOfTheWeek,
    time: number,
}) => ({
    type: UPDATE_FROM_OPENING_HOURS_ACTION,
    payload,
});

export const updateToOpeningHoursAction = (payload: {
    openingHoursForTheDay: SiteSettingsOpeningHoursMapValue,
    day: DayOfTheWeek,
    time: number,
}) => ({
    type: UPDATE_TO_OPENING_HOURS_ACTION,
    payload,
});
