import { diff } from 'objectdiff';
import isTestEnv from "../../../../debug/isTestEnv";
import type { ComponentEvalAdjustAfterUpdate } from './flowTypes';
import componentUpdater from "./componentUpdater";
import { componentsMapTraceEnabled } from "../../../../utils/isDebug";
import { info, warn } from '../../../../../utils/log';

const
    shouldVerify = isTestEnv() || process.env.NODE_ENV !== 'production',
    verifyComponentsAfterUpdate: ComponentEvalAdjustAfterUpdate = props => {
        if (!shouldVerify) {
            return { state: props.nextState };
        }

        if (componentsMapTraceEnabled()) {
            if (props.prevState.state.componentsMap !== props.nextState.state.componentsMap) {
                const
                    componentsMapDiff = diff(props.prevState.state.componentsMap, props.nextState.state.componentsMap);

                info(`[1] componentsMap changed updater index ${props.updaterIndex} on ${props.triggerSubscriptionActionType} condition initiated by ${props.sourceAction.type} source action`, 'from', props.prevState.state.componentsMap, 'to', props.nextState.state.componentsMap, 'diff', componentsMapDiff); // eslint-disable-line max-len

                if (componentsMapDiff.changed === 'equal') {
                    warn('new reference to componentsMap was created, but actually nothing changed');
                }
            }

            if (props.prevState.state.componentsMapExtension !== props.nextState.state.componentsMapExtension) {
                const
                    componentsMapExtensionDiff = diff(
                        props.prevState.state.componentsMapExtension,
                        props.nextState.state.componentsMapExtension
                    );

                info(`[1] componentsMapExtension changed updater index ${props.updaterIndex} on ${props.triggerSubscriptionActionType} condition initiated by ${props.sourceAction.type} source action`, 'from', props.prevState.state.componentsMapExtension, 'to', props.nextState.state.componentsMapExtension, 'diff', componentsMapExtensionDiff); // eslint-disable-line max-len

                if (componentsMapExtensionDiff.changed === 'equal') {
                    warn('new reference to componentsMapExtension was created, but actually nothing changed');
                }
            }
        }

        const
            updatedComponents = componentUpdater({
                state: props.nextState.state,
                action: ({ type: 'DUMMY_ACTION' }), // we just want reducers to sanitaze state that was just set, so any action is suitable
                selectedComponentId: props.nextState.scope.selectedComponentsIds[0],
                componentsDependencies: props.nextState.scope.componentsDependencies
            });

        if (updatedComponents !== props.nextState.state) {
            warn('diff: ', diff(props.nextState.state, updatedComponents));
            throw new Error(`COMPONENTS_EVAL epic updater ${props.updaterIndex} on ${props.triggerSubscriptionActionType} from ${props.sourceAction.type} source action, set some invalid components data. This data will be sanitized by component reducer and lost. diff ^`); // eslint-disable-line max-len
        }

        return { state: props.nextState };
    },
    verifyComponentsOnceAfterUpdateHooksComplete: ComponentEvalAdjustAfterUpdate = props => {
        if (!shouldVerify) {
            return { state: props.nextState };
        }

        if (componentsMapTraceEnabled()) {
            if (props.prevState.state.componentsMap !== props.nextState.state.componentsMap) {
                const
                    componentsMapDiff = diff(props.prevState.state.componentsMap, props.nextState.state.componentsMap);

                info(`[2] If prev line has [1] ignore this. Some Of COMPONENTS_EVAL afterUpdate hooks changed componentsMap updater index ${props.updaterIndex} on ${props.triggerSubscriptionActionType} condition initiated by ${props.sourceAction.type} source action`, 'from', props.prevState.state.componentsMap, 'to', props.nextState.state.componentsMap, 'diff', componentsMapDiff); // eslint-disable-line max-len

                if (componentsMapDiff.changed === 'equal') {
                    warn('new reference to componentsMap was created, but actually nothing changed');
                }
            }

            if (props.prevState.state.componentsMapExtension !== props.nextState.state.componentsMapExtension) {
                const
                    componentsMapExtensionDiff = diff(
                        props.prevState.state.componentsMapExtension,
                        props.nextState.state.componentsMapExtension
                    );

                info(`[2] If prev line has [1] ignore this. Some Of COMPONENTS_EVAL afterUpdate hooks changed componentsMap updater index ${props.updaterIndex} on ${props.triggerSubscriptionActionType} condition initiated by ${props.sourceAction.type} source action`, 'from', props.prevState.state.componentsMapExtension, 'to', props.nextState.state.componentsMapExtension, 'diff', componentsMapExtensionDiff); // eslint-disable-line max-len

                if (componentsMapExtensionDiff.changed === 'equal') {
                    warn('new reference to componentsMapExtension was created, but actually nothing changed');
                }
            }
        }

        const
            updatedComponents = componentUpdater({
                state: props.nextState.state,
                action: ({ type: 'DUMMY_ACTION' }), // we just want reducers to sanitaze state that was just set, so any action is suitable
                selectedComponentId: props.nextState.scope.selectedComponentsIds[0],
                componentsDependencies: props.nextState.scope.componentsDependencies
            });

        if (updatedComponents !== props.nextState.state) {
            warn('diff: ', diff(props.nextState.state, updatedComponents));
            throw new Error(`Some Of COMPONENTS_EVAL afterUpdate hooks epic updater ${props.updaterIndex} on ${props.triggerSubscriptionActionType} from ${props.sourceAction.type} source action, set some invalid components data. This data will be sanitized by component reducer and lost. diff ^ `); // eslint-disable-line max-len
        }

        return { state: props.nextState };
    };

export { verifyComponentsAfterUpdate, verifyComponentsOnceAfterUpdateHooksComplete };
