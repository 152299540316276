// @ts-nocheck
import React, { useEffect, useRef, useState } from "react";
import cx from 'classnames';
import styles from './WSModernSection.css';
import { convertLayoutStyleToStyleObject } from "../../../../components/ModernLayouts/preview_utils";
import { H_ALIGN } from "../../../../components/ModernLayouts/constants";
import { RendererContext, SectionContext } from "./ModernSectionsRenderer";
import { BUTTON } from "../../../../components/oneweb/componentKinds";
import {
    evaluateElementRectValue,
    getEleDimensions,
} from './utils';
import { LayoutView } from "./LayoutRenderer";
import { FlexComponentView } from "./MHFComponentRenderer";
import { MCTA_RESIZE_OPTIONS } from "../../../../components/oneweb/Button/constants";

const ColumnView = ({ col, colsStyle }) => {
    const
        layoutRef = useRef(null),
        { mhfLayout: layout } = React.useContext(SectionContext),
        { modernCmpsMap, workspaceBBox, componentsMapExtension } = React.useContext(RendererContext),
        { cmps, hAlign, hPositioned, isExpandable } = col,
        [cmpStyles, setCmpStyles] = useState({}),
        colRef = useRef(null),
        colClasses = cx(
            styles.flexColumn,
            {
                [styles.expandable]: isExpandable,
                [styles.verticalColumn]: !hPositioned,
                [styles.leftAlign]: hAlign === H_ALIGN.left,
                [styles.rightAlign]: hAlign === H_ALIGN.right,
                [styles.centerAlign]: hAlign === H_ALIGN.center,
            }
        ),
        subLayout = cmps.length === 1 && layout[cmps[0].id],
        // eslint-disable-next-line no-use-before-define
        subLayoutView = subLayout ? <LayoutView layout={subLayout} layoutRef={layoutRef} /> : null,
        colComponents = cmps.map(({ id }) => modernCmpsMap[id]),
        addedCmpDimensions = colComponents.reduce(([w, h], { width, height }) => [w + width, h + height], [0, 0]),
        updateTriggeringValues = [
            ...Object.values(evaluateElementRectValue(colRef.current, workspaceBBox)),
            ...addedCmpDimensions
        ];

    useEffect(() => {
        const colEle = colRef.current;
        const isButtonCol = colComponents.every(({ kind }) => kind === BUTTON);
        const colWidth = getEleDimensions(colEle).width,
            totalMarginWith = cmps.reduce((sum, { margin: { left = 0, right = 0 } = {} }) => (sum + left + right), 0);

        if (isButtonCol) {
            let componentStyles = {};
            const cmpProps = colComponents.map(({ id, width, modernLayoutOptions }) => {
                const {
                        maxDimensions: { width: maxWidth } = {},
                    } = componentsMapExtension[id] || {},
                    minWidth = MCTA_RESIZE_OPTIONS[modernLayoutOptions.size].width;
                return {
                    maxWidth: maxWidth || Math.max(minWidth, width),
                    minWidth,
                    id,
                };
            });
            const maxBtnWidth = (colWidth - totalMarginWith) / cmps.length;

            if (cmps.length === 2) {
                let cmp1 = cmpProps[0],
                    cmp2 = cmpProps[1];

                // set buttonMinWith all the time
                componentStyles = {
                    [cmp1.id]: {
                        minWidth: cmp1.minWidth,
                    },
                    [cmp2.id]: {
                        minWidth: cmp2.minWidth,
                    }
                };

                if (cmp1.maxWidth > maxBtnWidth && cmp2.maxWidth > maxBtnWidth) {
                    componentStyles[cmp1.id] = {
                        maxWidth: maxBtnWidth,
                        minWidth: maxBtnWidth,
                    };
                    componentStyles[cmp2.id] = {
                        maxWidth: maxBtnWidth,
                        minWidth: maxBtnWidth,
                    };
                } else if (cmp1.maxWidth < maxBtnWidth) {
                    componentStyles[cmp1.id] = {
                        minWidth: cmp1.maxWidth,
                    };
                } else if (cmp2.maxWidth < maxBtnWidth) {
                    componentStyles[cmp2.id] = {
                        minWidth: cmp2.maxWidth
                    };
                }
            } else {
                const { minWidth, id } = cmpProps[0].id;
                componentStyles[id] = {
                    minWidth
                };
            }
            setCmpStyles(componentStyles);
        }
    }, updateTriggeringValues);

    return <div className={colClasses} style={colsStyle} ref={colRef}>
        {cmps.map(({ id, style: { margin = {} } = {} }) => {
            let style = {
                ...convertLayoutStyleToStyleObject({ margin }),
                ...(cmpStyles[id] || {})
            };
            return <FlexComponentView key={id} hasSubLayout={!!subLayout} componentId={id} style={style}>
                {subLayoutView}
            </FlexComponentView>;
        })}
    </div>;
};

export {
    ColumnView
};
