import platform from 'platform';
import browser from 'bowser';
import isTestEnv from "../../debug/isTestEnv";

// TODO: probably use: wbtgen/src/utils/user-agent/UserAgent.js
// TODO: Move this to some common place at root level : WBTGEN: 8437

const version = parseFloat(browser.version as string),
    { chrome, chromium, firefox, msie, msedge, safari, samsungBrowser, opera, mobile, tablet } = browser;

export const
    isTouchDevice =
        ('ontouchstart' in window)
        || mobile
        || tablet
        || (navigator.maxTouchPoints > 0)
                // @ts-ignore
                || (navigator.msMaxTouchPoints > 0),
    isBrowserUnsupported = isTestEnv() ? false : (function () {
        const
            supported = (
                msedge ||
                (firefox && version >= 45) ||
                ((chrome || chromium) && version >= 41) ||
                (safari && version >= 10) ||
                (samsungBrowser && version >= 5) ||
                (opera && version >= 28)
            );
        return !supported;
    }()),
    isBrowserDeprecated = isTestEnv() ? false : (
        (msedge && version < 18) ||
        (firefox && version < 60) ||
        ((chrome || chromium) && version < 71) ||
        (safari && version < 12) ||
        (samsungBrowser && version < 8) ||
        (opera && version < 55)
    ),
    isIE9OrBelow = msie && version <= 9,
    isMacOS = () => platform.os.family === 'OS X';
