/* eslint-disable max-len */

import React from 'react';
import { Box, Flex } from "../../../view/reflexbox/index";
import { injectIntl, Msg } from '../../../view/intl/index';
import HorizontalSpacer from '../../../view/common/HorizontalSpacer';
import VerticalSpacer from '../../../view/common/VerticalSpacer'; import getCenteredDialogConfig from '../../DialogManager/getCenteredDialogConfig';
import { Dialog, DialogBody, DialogFooter } from '../../../view/common/dialogs/baseDialog/index';
import { CheckBoxWithLabel } from '../../../view/common/CheckBox/index';
import { BACKUP_INTRO_CLOSE_DIALOG, BACKUP_INTRO_TOGGLE_SHOW } from '../actionTypes';
import subscriptionDataEpic from '../../App/epics/subscriptionData/index';
import { getPremiumPackageNameFromMetadata } from '../../ComponentTierManager/utils';

import styles from '../view/Backup.css';

const
    dialogId = 'BackupsIndtroductionDialog',
    width = 490,
    height = 435,
    BackupIntroDialog = injectIntl(({
        showHideIntro = true,
        hideIntro,
        subscriptionData: { metadata: subscriptionMetadata },
        intl,
        dispatch
    }) => {
        const premiumPackageNameFromMetadata = getPremiumPackageNameFromMetadata(subscriptionMetadata);
        return (
            <Dialog>
                <DialogBody>
                    <VerticalSpacer y={38} />
                    <Box>
                        <Flex align="center" justify="center">
                            <Msg className={styles.dialogTitle} k="component.backup.intro.dialog.title">Backups</Msg>
                        </Flex>
                        <VerticalSpacer y={32} />
                        <Flex align="center" justify="center">
                            <div className={styles.backupIcon} />
                        </Flex>
                        <VerticalSpacer y={31} />
                        <Flex>
                            <HorizontalSpacer x={45} />
                            <Box>
                                <Msg
                                    className={styles.bold}
                                    k="component.backup.intro.dialog.message"
                                >
                                    Restore your website project to a previous version:
                                </Msg>
                                <VerticalSpacer y={17} />
                                <Flex>
                                    <div className={styles.listItem} />
                                    <HorizontalSpacer x={9} />
                                    <Msg k="component.backup.intro.dialog.benefits.backup">Backups when you save</Msg>
                                </Flex>
                                <VerticalSpacer y={7} />
                                <Flex>
                                    <div className={styles.listItem} />
                                    <HorizontalSpacer x={9} />
                                    <Msg k="component.backup.intro.dialog.benefits.preview">Preview backups</Msg>
                                </Flex>
                                <VerticalSpacer y={7} />
                                <Flex>
                                    <div className={styles.listItem} />
                                    <HorizontalSpacer x={9} />
                                    <Msg k="component.backup.intro.dialog.benefits.restore">Restore your website from up to 200 saved versions</Msg>
                                </Flex>
                                <VerticalSpacer y={12} />
                                <Msg
                                    className={styles.displayBlock}
                                    k="component.backup.intro.dialog.benefits.backupAndRestore"
                                    params={{ packageName: premiumPackageNameFromMetadata || intl.msgJoint('msg: common.premium {Premium}') }}
                                >{`
                                    Backup & Restore is included in Website Builder {packageName}
                                `}</Msg>
                                <a
                                    target="_blank"
                                    className={styles.learnMore}
                                    href={intl.msgJoint("msg: help.links.upgradeLearnMoreLink {https://help.one.com/hc/en-us/articles/115005592665}")}
                                >
                                    <Msg k="common.learnMore">Learn more</Msg>
                                </a>
                            </Box>
                            <HorizontalSpacer x={45} />
                        </Flex>
                    </Box>
                </DialogBody>
                <DialogFooter
                    noTopBorder
                    mctaText="msg: common.gotIt {Got it}"
                    mctaHandler={() => dispatch({ type: BACKUP_INTRO_CLOSE_DIALOG, payload: { hideIntro } })}
                >
                    {
                        showHideIntro &&
                        <CheckBoxWithLabel
                            isChecked={hideIntro}
                            containerClassName={styles.dontShowAgain}
                            label="msg: component.backup.introDialog.dontShowAgain {Don\'t show this again}"
                            onClick={() => dispatch({ type: BACKUP_INTRO_TOGGLE_SHOW })}
                        />
                    }
                </DialogFooter>
            </Dialog>
        );
    }),
    dialog = {
        ...getCenteredDialogConfig(width, height, BackupIntroDialog),
        updateConfFn: (config, dimensions, props) => ({ ...config, props }),
        dependsOn: {
            subscriptionData: subscriptionDataEpic.reducer,
        }
    };

export { dialogId, dialog };
