import * as R from 'ramda';
import p from "../../../utils/pipePath";
import * as path from "../../../mappers/path";
import * as gradientSetters from '../../../setters/gradientSetter';
import * as bgSetters from '../../../setters/backgroundSetter';
import * as borderSetters from '../../../setters/borderSetter';
import * as textSetters from '../../../setters/textSetter';
import ButtonGlobalStyleKind from "../../oneweb/Button/globalStyle/kind";
import type { ButtonStylesheetEpicUpdater } from '../flowTypes';

type EpicUpdaterFactory = (actionType: string, basePath: string|Array<string>) => ButtonStylesheetEpicUpdater;

const blockBorder = p(path.block, path.border);

export const
    googleFontEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [{ googleFont, additionalPayload }], state: stylesheet }) => ({
            state: additionalPayload && additionalPayload.source === ButtonGlobalStyleKind
                ? textSetters.setFontFamily(p(basePath, path.text), googleFont, stylesheet)
                : stylesheet
        })
    }),
    fontFamilyEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [fontFamily], state: stylesheet }) => ({
            state: textSetters.setFontFamily(p(basePath, path.text), fontFamily, stylesheet)
        })
    }),
    toggleBoldEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: textSetters.toggleBold(p(basePath, path.text), stylesheet)
        })
    }),
    toggleItalicEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: textSetters.toggleItalic(p(basePath, path.text), stylesheet)
        })
    }),
    toggleUnderlineEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: textSetters.toggleUnderline(p(basePath, path.text), stylesheet)
        })
    }),
    fontSizeEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [fontSize], state: stylesheet }) => ({
            state: textSetters.setFontSize(p(basePath, path.text), fontSize, stylesheet)
        })
    }),
    borderWidthEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [borderWidth], state: stylesheet }) => ({
            state: R.all(R.is(Number), borderWidth)
                ? R.reduce((s, bp) => borderSetters.setBorderWidths(p(bp, blockBorder), borderWidth, s), stylesheet)(basePath) // eslint-disable-line max-len
                : stylesheet
        })
    }),
    borderRadiusEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [borderRadius], state: stylesheet }) => ({
            state: R.reduce(
                (s, bp) => borderSetters.setBorderCorners(p(bp, blockBorder), borderRadius, s), stylesheet
            )(basePath)
        })
    }),
    textColorEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [{ color }], state: stylesheet }) => ({
            state: textSetters.setTextColor(p(basePath, path.text), color, stylesheet)
        })
    }),
    textColorRemoveEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: textSetters.unsetTextColor(p(basePath, path.text), stylesheet)
        })
    }),
    borderStyleEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [borderStyle], state: stylesheet }) => ({
            state: borderSetters.setBorderStyle(p(basePath, blockBorder), borderStyle, stylesheet)
        })
    }),
    borderColorEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [{ color }], state: stylesheet }) => ({
            state: borderSetters.setBorderColor(p(basePath, blockBorder), color, stylesheet)
        })
    }),
    borderColorRemoveEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: borderSetters.unsetBorderColor(p(basePath, blockBorder), stylesheet)
        })
    }),
    borderColorOpacityEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [opacity], state: stylesheet }) => ({
            state: borderSetters.setBorderOpacity(p(basePath, blockBorder), opacity, stylesheet)
        })
    }),
    assetRemoveEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: bgSetters.unsetAsset(p(basePath, path.blockBackground), stylesheet)
        })
    }),
    assetChangeEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [{ asset }], state: stylesheet }) => ({
            state: bgSetters.setAsset(p(basePath, path.blockBackground), asset, stylesheet)
        })
    }),
    assetRepeatEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [repeat], state: stylesheet }) => ({
            state: bgSetters.setAssetRepeat(p(basePath, path.blockBackground), repeat, stylesheet)
        })
    }),
    assetPositionEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [position], state: stylesheet }) => ({
            state: bgSetters.setAssetPosition(p(basePath, path.blockBackground), position, stylesheet)
        })
    }),
    assetSizeEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [size], state: stylesheet }) => ({
            state: bgSetters.setAssetSize(p(basePath, path.blockBackground), size, stylesheet)
        })
    }),
    bgColorEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [{ color }], state: stylesheet }) => ({
            state: bgSetters.setBackgroundSolidColor(p(basePath, path.blockBackground), color, stylesheet)
        })
    }),
    bgColorRemoveEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: bgSetters.unsetBackgroundColor(p(basePath, path.blockBackground), stylesheet)
        })
    }),
    bgColorOpacityEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [opacity], state: stylesheet }) => ({
            state: bgSetters.setBackgroundOpacity(p(basePath, path.blockBackground), opacity, stylesheet)
        })
    }),
    gradientEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [{ color }], state: stylesheet }) => ({
            state: bgSetters.setBackgroundGradientColor(p(basePath, path.blockBackground), color, stylesheet)
        })
    }),
    gradientRemoveEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: gradientSetters.unsetGradientColor(p(basePath, path.blockBackgroundGradient), stylesheet)
        })
    }),
    gradientOpacityEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [opacity], state: stylesheet }) => ({
            state: bgSetters.setBackgroundOpacity(
                p(basePath, path.blockBackgroundGradient),
                opacity,
                stylesheet
            )
        })
    }),
    gradientDirectionEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [direction], state: stylesheet }) => ({
            state: gradientSetters.setGradientDirection(
                p(basePath, path.blockBackgroundGradient),
                direction,
                stylesheet
            )
        })
    }),
    gradientFadePointEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [fadePoint], state: stylesheet }) => ({
            state: gradientSetters.setGradientFadePoint(
                p(basePath, path.blockBackgroundGradient),
                fadePoint,
                stylesheet
            )
        })
    });
