import React from "react";
import cx from 'classnames';
import styles from './styles.css';
import { deleteMessage } from '../actionCreators';
import type { ToasterMessageProps } from "../flowTypes";
import { injectIntl } from '../../../view/intl/index';

class ToasterMessage extends React.PureComponent<ToasterMessageProps> {
    render() {
        const { message, index, intl, dispatch } = this.props,
            { params, msgKey } = message;
        let msg = message.text;

        if (!message.msgHtml) {
            if (message.useIntl) {
                msg = intl.msg(msgKey, msg, params);
            } else if (params) {
                const [key, text, otherParams = {}] = intl.parseMsg(msg);
                msg = intl.msg(key, text, { ...otherParams, ...params });
            } else {
                msg = intl.msgJoint(msg);
            }
        }

        return (
            <li
                className={cx(
                    message.msgStyles,
                    styles.message,
                    styles[message.type],
                    {
                        [styles.show]: message.show,
                        [styles.delete]: message.delete
                    }
                )}
            >
                {!message.msgHtml && <p className={styles.text}>{msg}</p>}
                {!!message.msgHtml && message.msgHtml}
                <span
                    className={cx(message.closeStyles, styles.close)}
                    onClick={() => { dispatch(deleteMessage(index)); }}
                />
            </li>
        );
    }
}

export default injectIntl(ToasterMessage);
