import makeStyles from '@mui/styles/makeStyles';
import { extraThemeConfig } from '../../../../../app/theme';

export const useStyles = makeStyles(
    (theme) => ({
        rootContainer: {
            marginBottom: "40px",
            [theme.breakpoints.only('sm')]: {
                marginRight: "-24px",
                marginLeft: "-24px",
            }
        },
        contentWrapper: {
            [theme.breakpoints.up("sm")]: {
                border: `1px solid ${theme.palette.custom.colorGray_ed}`,
                borderTop: "none",
            },
        },
        title: {
            fontSize: "18px",
            lineHeight: "26px",
            userSelect: "none",
        },
        tabHeader: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            userSelect: "none",
        },
        tabHeaderItem: {
            fontSize: "18px",
            lineHeight: "26px",
            padding: "11px 25px",
            opacity: 0.7,
            [theme.breakpoints.down("sm")]: {
                fontSize: "16px",
                lineHeight: "24px",
            },
        },
        tabHeaderActiveItem: {
            borderBottom: `3px solid ${theme.palette.custom.colorScienceBlue}`,
            opacity: 1,
            position: "relative",
            [theme.breakpoints.down("sm")]: {
                width: "100%",
                border: `1px solid ${theme.palette.custom.colorGray_8a}`,
                borderRadius: `${extraThemeConfig.borderRadius}px`,
                margin: "12px 0px",
            },
        },
        dropdownIcon: {
            position: "absolute",
            width: "24px",
            height: "24px",
            top: "12px",
            right: "20px",
            [theme.breakpoints.up("sm")]: {
                display: "none",
            },
        },
        listContainer: {
            display: "flex",
            padding: "24px",
            borderTop: `1px solid ${theme.palette.custom.colorGray_ed}`,
            alignItems: "center",
            justifyContent: "space-between",
        },
        text: {
            marginLeft: "16px",
        },
        seeMore: {
            paddingLeft: "16px",
        },
        introLoader: {
            marginTop: "2px",
            display: "block",
            borderTop: `1px solid ${theme.palette.custom.colorGray_ed}`,
            [theme.breakpoints.down("sm")]: {
                borderTop: "none",
            },
        },
    }),
    { name: "TasksLoader" }
);
