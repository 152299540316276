/* eslint-disable max-len */

import { text } from "../../../../mappers/path";
import kind from "../../../oneweb/Table/globalStyle/kind";
import { configurationDialogFactory } from "../../../presentational/TextShadow/index";
import { types } from "../constants";
import configurationDialogId from "./TableAlternateTextShadowDialogId";

const TableAlternateTextShadowDialog = configurationDialogFactory(kind, [types.ALTERNATE], configurationDialogId, [text]);

export default TableAlternateTextShadowDialog;
