import React from 'react';
import { WidgetWorkspaceView } from '../../view/workspace';
import { VimeoVideoView } from './index';

// IMPORTANT: When rendering the widget in workspace, the autoplay flag is forcefully set to false

const VimeoWorkspaceView = props => (
    <WidgetWorkspaceView {...props}>
        <VimeoVideoView {...props} autoplay={false} />
    </WidgetWorkspaceView>
);

export { VimeoWorkspaceView };

