import React from 'react';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import NavigationGroup from '../../../../PropertiesPanel/view/IntlNavigationGroup';
import { MOBILE_VIEW } from "../../../../PropertiesPanel/view/MobileView/constants";
import * as pagesIds from '../pagesIds';
import pagesTitles from '../pagesTitles';
import type { Props } from '../flowTypes';

const
    id = pagesIds.MAIN,
    title = pagesTitles[pagesIds.MAIN],
    view = ({ navigateToPage }: Props) => (
        <Page>
            <NavigationGroup
                navigateToPage={navigateToPage}
                pagesTitles={pagesTitles}
                targetPagesIds={[pagesIds.ONCLICK, pagesIds.CAPTIONS, pagesIds.IMAGE_STYLES, pagesIds.FULL_WIDTH, MOBILE_VIEW]}
            />
        </Page>
    );

export { id, view, title };
