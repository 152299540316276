import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, DialogContent, Hidden } from "@mui/material";

import { Select } from "../../../Select";

import VerticalSpacer from "../../../../../../../wbtgen/src/view/common/VerticalSpacer";
import HorizontalSpacer from "../../../../../../../wbtgen/src/view/common/HorizontalSpacer";

import { BasicDialog } from "../../../Dialog/BasicDialog";
import { closeDialogAction } from "../../../Dialog/actions";

import {
    unSetFacebookChatAccessTokenAction,
    exchangeForFBChatLongAccessTokenAction,
    setFbChatPageValue
} from "../../../../../../../wbtgen/src/components/SocialAccounts/Facebook/FacebookChat/actions";

import {
    openChatWidgetInSiteSettings
} from "../../../../../../../wbtgen/src/components/SocialAccounts/Facebook/FacebookChat/actionSelector";
import {
    socialAccountsFacebookChatManagedPages, socialAccountsFacebookChatSelectedPageValue
} from "../../../../../../../wbtgen/src/components/SocialAccounts/Facebook/FacebookChat/selectors";
import { appNameSelector } from "../../../../common/selectors";

import type {
    FacebookChatPage
} from "../../../../../../../wbtgen/src/components/SocialAccounts/Facebook/FacebookChat/types";

import { useStyles } from "./styles";

type Props = {
    intl: Intl,
    config: any,
};

export const FacebookChatMultiplePagesDialog = (props: Props) => {
    const
        { intl, config } = props,
        dispatch = useDispatch(),
        managedFbPages: Array<FacebookChatPage> = useSelector(socialAccountsFacebookChatManagedPages),
        selectedPageValue: string = useSelector(socialAccountsFacebookChatSelectedPageValue),
        appName: string = useSelector(appNameSelector),
        pageOptions = managedFbPages.map((pageData) => {
            return {
                value: pageData.access_token,
                label: pageData.name
            };
        }),
        classes = useStyles(),
        handleClose = () => {
            dispatch(closeDialogAction());
        },
        selectProps = {
            value: selectedPageValue,
            data: pageOptions,
            handleChange: (e) => {
                dispatch(setFbChatPageValue(e.target.value));
            },
            fullWidth: true,
        };

    return (
        <BasicDialog
            onClose={handleClose}
            classes={{ paper: classes.paper }}
            {...config}
        >
            <DialogContent>
                <div className={classes.dialogTitle}>
                    {intl.msgJoint("msg: component.facebookChat.multipleBusinessPages {Choose business page}")}
                </div>
                <Hidden mdDown>
                    <VerticalSpacer y={30} />
                </Hidden>
                {/* @ts-ignore */}
                <Hidden mdUp>
                    <VerticalSpacer y={16} />
                </Hidden>
                <div className={classes.dialogSubTitle}>
                    {intl.msgJoint("msg: component.facebookChat.multipleBusinessPages.message {You have selected multiple Facebook Business pages when connecting. Messenger chat only allows one connected page at a time. Select a page to connect to your site.}")}
                </div>
                <Hidden mdDown>
                    <VerticalSpacer y={30} />
                </Hidden>
                {/* @ts-ignore */}
                <Hidden mdUp>
                    <VerticalSpacer y={16} />
                </Hidden>
                <Select {...selectProps} />
                <Hidden mdDown>
                    <VerticalSpacer y={30} />
                </Hidden>
                {/* @ts-ignore */}
                <Hidden mdUp>
                    <VerticalSpacer y={38} />
                </Hidden>
                <div className={classes.buttonContainer}>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                            dispatch(unSetFacebookChatAccessTokenAction());
                            dispatch(closeDialogAction());
                        }}
                    >
                        {intl.msgJoint("msg: common.cancel {Cancel}")}
                    </Button>
                    <Hidden mdDown>
                        <HorizontalSpacer x={20} inline />
                    </Hidden>
                    {/* @ts-ignore */}
                    <Hidden mdUp>
                        <HorizontalSpacer x={10} inline />
                    </Hidden>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                            dispatch(exchangeForFBChatLongAccessTokenAction(selectedPageValue));
                            openChatWidgetInSiteSettings(dispatch, true, !appName);
                            dispatch(closeDialogAction());
                        }}
                    >
                        {intl.msgJoint("msg: common.continue {Continue}")}
                    </Button>
                </div>
            </DialogContent>

        </BasicDialog>
    );
};

export const FACEBOOK_CHAT_MULTIPLE_PAGES_DIALOG_ID = 'FACEBOOK_CHAT_MULTIPLE_PAGES_DIALOG_ID';
