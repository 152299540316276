
export const
    WIDGETS_PAYPAL_SET_LINK = "WIDGETS_PAYPAL_SET_LINK",
    WIDGETS_PAYPAL_UPDATE_LINK = "WIDGETS_PAYPAL_UPDATE_LINK",
    WIDGETS_PAYPAL_UPDATE_PROPS = "WIDGETS_PAYPAL_UPDATE_PROPS",
    WIDGETS_PAYPAL_SHOW_UPDATE_LINK_DIALOG = "WIDGETS_PAYPAL_SHOW_UPDATE_LINK_DIALOG",
    WIDGETS_PAYPAL_SET_LABEL = "WIDGETS_PAYPAL_SET_LABEL",
    WIDGET_PAYPAL_COLOR_CHANGE = 'WIDGET_PAYPAL_COLOR_CHANGE',
    WIDGET_PAYPAL_SHAPE_CHANGE = 'WIDGET_PAYPAL_SHAPE_CHANGE',
    WIDGET_PAYPAL_LAYOUT_CHANGE = 'WIDGET_PAYPAL_LAYOUT_CHANGE';
