import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
    (theme) => ({
        statsItem: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "8px",
        },
        statsTitle: {
            fontSize: "14px",
            lineHeight: "22px",
            letterSpacing: "0.25px",
            color: theme.palette.custom.colorGray_8a
        },
        statsValue: {
            fontSize: "24px",
            lineHeight: "30px",
            letterSpacing: "0.15px",
            color: theme.palette.custom.colorGray_3c
        }
    }),
    { name: "VisitStatsViewItem" },
);

type VisitStatsViewItemProps = {
    label: string;
    value: number | string;
};

export const VisitStatsViewItem = ({ label, value }: VisitStatsViewItemProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.statsItem}>
            <Typography className={classes.statsTitle}>{label}</Typography>
            <Typography className={classes.statsValue}>{value}</Typography>
        </Box>
    );
};

