export default {
    WEBSHOP_FONT_FAMILY_CHANGED: 'WEBSHOP_FONT_FAMILY_CHANGED',
    WEBSHOP_TEXT_COLOR_CHANGED: 'WEBSHOP_TEXT_COLOR_CHANGED',
    WEBSHOP_TEXT_COLOR_CHANGED_AUTO_COLOR: 'WEBSHOP_TEXT_COLOR_CHANGED_AUTO_COLOR',
    WEBSHOP_FOCUS_COLOR_CHANGED: 'WEBSHOP_FOCUS_COLOR_CHANGED',
    WEBSHOP_FOCUS_COLOR_CHANGED_AUTO_COLOR: 'WEBSHOP_FOCUS_COLOR_CHANGED_AUTO_COLOR',
    WEBSHOP_HOVER_COLOR_CHANGED: 'WEBSHOP_HOVER_COLOR_CHANGED',
    WEBSHOP_HOVER_COLOR_CHANGED_AUTO_COLOR: 'WEBSHOP_HOVER_COLOR_CHANGED_AUTO_COLOR',
    WEBSHOP_HOVER_OPACITY_CHANGING: 'WEBSHOP_HOVER_OPACITY_CHANGING',
    WEBSHOP_HOVER_OPACITY_CHANGED: 'WEBSHOP_HOVER_OPACITY_CHANGED',
    WEBSHOP_SHOW_CATEGORY_SELECTOR_CHANGED: 'WEBSHOP_SHOW_CATEGORY_SELECTOR_CHANGED',
    WEBSHOP_CAN_SORT_PRODUCTS_CHANGED: 'WEBSHOP_CAN_SORT_PRODUCTS_CHANGED',
    WEBSHOP_SHOW_SEARCH_BAR_CHANGED: 'WEBSHOP_SHOW_SEARCH_BAR_CHANGED',
    WEBSHOP_BUTTON_STYLE_CHANGED: 'WEBSHOP_BUTTON_STYLE_CHANGED',
    WEBSHOP_BUTTON_STYLE_CHANGED_AUTO_COLOR: 'WEBSHOP_BUTTON_STYLE_CHANGED_AUTO_COLOR',

    WEBSHOP_BUY_NOW_BUTTON_STYLE_CHANGED: 'WEBSHOP_BUY_NOW_BUTTON_STYLE_CHANGED',
    WEBSHOP_BUY_NOW_BUTTON_STYLE_CHANGED_AUTO_COLOR: 'WEBSHOP_BUY_NOW_BUTTON_STYLE_CHANGED_AUTO_COLOR',
    WEBSHOP_ENABLE_BUY_NOW_CHANGED: 'WEBSHOP_ENABLE_BUY_NOW_CHANGED',

    WEBSHOP_BUTTON_STYLES_GENERATED: 'WEBSHOP_BUTTON_STYLES_GENERATED',
    WEBSHOP_BUY_NOW_BUTTON_STYLES_GENERATED: 'WEBSHOP_BUY_NOW_BUTTON_STYLES_GENERATED',
    WEBSHOP_IMAGE_RATIO_CHANGED: 'WEBSHOP_IMAGE_RATIO_CHANGED',
    WEBSHOP_SORT_BY_CHANGED: 'WEBSHOP_SORT_BY_CHANGED',
    WEBSHOP_PRODUCTS_PER_PAGE_CHANGED: 'WEBSHOP_PRODUCTS_PER_PAGE_CHANGED',
    WEBSHOP_PRODUCTS_PER_ROW_CHANGED: 'WEBSHOP_PRODUCTS_PER_ROW_CHANGED',
    WEBSHOP_PRODUCTS_PER_ROW_IN_MOBILE_CHANGED: 'WEBSHOP_PRODUCTS_PER_ROW_IN_MOBILE_CHANGED',
    WEBSHOP_BUY_BUTTON_TEXT_CHANGED: 'WEBSHOP_BUY_BUTTON_TEXT_CHANGED',
    WEBSHOP_BUY_NOW_BUTTON_TEXT_CHANGED: 'WEBSHOP_BUY_NOW_BUTTON_TEXT_CHANGED',
    WEBSHOP_WELCOME_OK_BUTTON: 'WEBSHOP_WELCOME_OK_BUTTON',
    WEBSHOP_CLOSE_NOTE: 'WEBSHOP_CLOSE_NOTE',
    WEBSHOP_LABEL_BG_COLOR_CHANGED: 'WEBSHOP_LABEL_BG_COLOR_CHANGED',
    WEBSHOP_LABEL_BG_COLOR_CHANGED_AUTO_COLOR: 'WEBSHOP_LABEL_BG_COLOR_CHANGED_AUTO_COLOR',
    WEBSHOP_LABEL_TEXT_COLOR_CHANGED: 'WEBSHOP_LABEL_TEXT_COLOR_CHANGED',
    WEBSHOP_LABEL_TEXT_COLOR_CHANGED_AUTO_COLOR: 'WEBSHOP_LABEL_TEXT_COLOR_CHANGED_AUTO_COLOR',
    WEBSHOP_LABEL_POSITION_CHANGED: 'WEBSHOP_LABEL_POSITION_CHANGED',
    WEBSHOP_CROP_IMAGES_CHANGED: 'WEBSHOP_CROP_IMAGES_CHANGED',
    WEBSHOP_PRODUCT_APPEARANCE_CHANGED: 'WEBSHOP_PRODUCT_APPEARANCE_CHANGED',
    WEBSHOP_CART_POSITION_CHANGED: 'WEBSHOP_CART_POSITION_CHANGED',
    WEBSHOP_CART_VERTICAL_POSITION: 'WEBSHOP_CART_VERTICAL_POSITION',
    WEBSHOP_CART_SHOW_MOBILE: 'WEBSHOP_CART_SHOW_MOBILE',
    WEBSHOP_CART_MOBILE_BACKGROUND_COLOUR: 'WEBSHOP_CART_MOBILE_BACKGROUND_COLOUR',
    WEBSHOP_CART_MOBILE_BACKGROUND_COLOUR_AUTO_COLOR: 'WEBSHOP_CART_MOBILE_BACKGROUND_COLOUR_AUTO_COLOR',
    WEBSHOP_SPACING_ITEMS_IN_MOBILE_CHANGED: 'WEBSHOP_SPACING_ITEMS_IN_MOBILE_CHANGED',
    WEBSHOP_CATEGORY_POSITION_CHANGED: 'WEBSHOP_CATEGORY_POSITION_CHANGED',
    WEBSHOP_SET_FEATURED_PRODUCT_URL: 'WEBSHOP_SET_FEATURED_PRODUCT_URL',
    WEBSHOP_UPDATE_TIMESTAMP_IN_URL: 'WEBSHOP_UPDATE_TIMESTAMP_IN_URL',

    WEBSHOP_PROMOTION_RIBBON_LABEL_BG_COLOR_CHANGED: 'WEBSHOP_PROMOTION_RIBBON_LABEL_BG_COLOR_CHANGED',
    WEBSHOP_PROMOTION_RIBBON_LABEL_BG_COLOR_CHANGED_AUTO_COLOR: 'WEBSHOP_PROMOTION_RIBBON_LABEL_BG_COLOR_CHANGED_AUTO_COLOR',
    WEBSHOP_PROMOTION_RIBBON_LABEL_TEXT_COLOR_CHANGED: 'WEBSHOP_PROMOTION_RIBBON_LABEL_TEXT_COLOR_CHANGED',
    WEBSHOP_PROMOTION_RIBBON_LABEL_TEXT_COLOR_CHANGED_AUTO_COLOR: 'WEBSHOP_PROMOTION_RIBBON_LABEL_TEXT_COLOR_CHANGED_AUTO_COLOR',
};
