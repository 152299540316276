import React from "react";
export const SvgYoutubeRoundedFillDark = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <rect width={48} height={48} fill="#3c3c3c" rx={6} />
            <path
                fill="#ffffff"
                d="M36.151 18.782s-.243-1.718-.992-2.473c-.949-.993-2.009-.997-2.495-1.056C29.18 15 23.952 15 23.952 15h-.01s-5.229 0-8.711.253c-.487.059-1.547.063-2.496 1.056-.749.755-.987 2.473-.987 2.473s-.248 2.015-.248 4.035v1.888c0 2.016.248 4.036.248 4.036s.243 1.718.987 2.472c.949.993 2.194.959 2.749 1.066 1.994.19 8.468.248 8.468.248s5.234-.01 8.716-.258c.487-.058 1.547-.063 2.496-1.056.749-.754.992-2.472.992-2.472s.248-2.016.248-4.036v-1.888a38.722 38.722 0 00-.253-4.035zm-14.777 8.216v-7.004l6.727 3.514-6.727 3.49z"
            />
        </g>
    </svg>
);
