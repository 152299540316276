/* eslint-disable max-len */
import type { TemplateComponent } from "../../flowTypes";
import DataPageStylesheet from "../../../../../../dal/model/DataPageStylesheet";
import * as backgroundMapper from "../../../../../../dal/pageMapAdapter/mappers/Common/background";

export const BODY_STYLE_REF = 'body';

export const getTemplateBackgroundStyleFromStylesheetStyles = <T extends Record<string, any>>(stylesheet: { styles: T[]; id: string; }): T => {
    const style = stylesheet.styles.filter(style => (style.ref === BODY_STYLE_REF))[0];
    if (!style) {
        throw new Error(`Missing body style in stylesheet: ${stylesheet.id}`);
    }
    return style;
};

const getTemplateBackgroundStyleFromStylesheet = (stylesheet: DataPageStylesheet): Object => {
    const rawStyle = getTemplateBackgroundStyleFromStylesheetStyles(stylesheet);

    return {
        ...rawStyle,
        background: rawStyle && backgroundMapper.to(rawStyle.background),
    };
};

export const
    back = (template: TemplateComponent, stylesheet: DataPageStylesheet): DataPageStylesheet => {
        if (stylesheet.id !== template.stylesheetId) {
            return stylesheet;
        }
        // @ts-ignore
        return {
            ...stylesheet,
            styles: stylesheet.styles.map(style => (
                style.ref === BODY_STYLE_REF
                    ? { ...style, background: backgroundMapper.back(template.style.background) }
                    : style
            ))
        };
    },
    to = (template: TemplateComponent, stylesheet: DataPageStylesheet): TemplateComponent => {
        if (stylesheet.id !== template.stylesheetId) {
            return template;
        }

        const style = getTemplateBackgroundStyleFromStylesheet(stylesheet);

        return {
            ...template,
            style
        };
    };
