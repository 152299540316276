import React from 'react';
import ReactDOM from 'react-dom';
import { ROOT_VIEWS_CONTAINER_ID } from "../../constants";

const root = document.getElementById(ROOT_VIEWS_CONTAINER_ID);

class RootView extends React.Component<any> {
    el: HTMLDivElement;
    constructor(props: any) {
        super(props);
        this.el = document.createElement('div');
    }

    componentDidMount() {
        if (root) {
            root.appendChild(this.el);
        }
    }

    componentWillUnmount() {
        if (root) {
            root.removeChild(this.el);
        }
    }

    render() {
        return ReactDOM.createPortal(
            this.props.children,
            this.el,
        );
    }
}

export { RootView };

