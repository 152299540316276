import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from '../../../../view/intl/index';
import { Dialog, DialogBody, DialogTitleBox, DialogFooter } from '../../../../view/common/dialogs/baseDialog/index';
import { StripTypes } from '../../../../view/common/dialogs/baseDialog/Dialog';
import getCenteredDialogConfig from '../../../DialogManager/getCenteredDialogConfig';
import { getCenterPositionForDialog } from '../../../DialogManager/utility';
import {
    HIDE_PRODUCT_WIDGET_PRODUCT_SELECTOR_DIALOG,
    PRODUCT_WIDGET_SET_ITEM,
} from '../actionTypes';
import { SearchBox } from './SearchBox';
import ProductWidgetKind from '../kind';
import styles from './styles.css';
import { ProductsList } from './ProductsList';
import { FEATURED_PRODUCTS_UPDATE_SHOP_SETUP_STATUS } from '../../FeaturedProducts/actionTypes';
import { makeEpicStateSelector } from '../../../../epics/makeEpic';
import { WEBSHOP_ONBOARD_STATUS } from '../../WebShop/epics/onboardStatus/valueActionType';

const ProductWidgetProductSelectionDialog = injectIntl(({
    dispatch,
    hasOnlyDigitalShop,
    products = [],
    isWebshopSetupDone = true,
    componentId
}) => {
    const onClose = () => dispatch({ type: HIDE_PRODUCT_WIDGET_PRODUCT_SELECTOR_DIALOG });
    const isUpdate = (Array.isArray(products) && products.length > 0) || !isWebshopSetupDone;

    const [selectedProductId, setSelectedProductId] = React.useState('');
    const [searchText, setSearchText] = React.useState('');

    React.useEffect(() => {
        if (products.length > 0) {
            setSelectedProductId(products[0]);
        }
    }, [products]);

    return (
        <Dialog
            className={styles.dialogClassName}
            onClose={onClose}
            stripType={StripTypes.NONE}
            closeBtnClassName={styles.closeBtnClassName}
        >
            <DialogTitleBox
                className={styles.dialogTitleBox}
                extraClass={styles.dialogText}
                title="msg: component.ProductWidget.dialog.title {Select a product to highlight}"
            >
                <SearchBox onSearch={(searchTxt) => { setSearchText(searchTxt); }} />
            </DialogTitleBox>
            <DialogBody className={styles.dialogBody}>
                <ProductsList
                    dispatch={dispatch}
                    searchText={searchText}
                    selectedProductId={selectedProductId}
                    setSelectedProductId={setSelectedProductId}
                    hasOnlyDigitalShop={hasOnlyDigitalShop}
                />
            </DialogBody>
            <DialogFooter
                noTopBorder
                className={styles.dialogFooter}
                contentClassName={styles.dialogFooterContent}
                disabled={!selectedProductId}
                mctaText="msg: common.done {Done}"
                mctaHandler={() => {
                    if (selectedProductId) {
                        if (!isWebshopSetupDone) {
                            dispatch({
                                type: FEATURED_PRODUCTS_UPDATE_SHOP_SETUP_STATUS,
                                payload: { componentId, isWebshopSetupDone: true }
                            });
                        }
                        dispatch({
                            type: PRODUCT_WIDGET_SET_ITEM,
                            payload: {
                                componentKind: ProductWidgetKind,
                                isUpdate,
                                products: [selectedProductId].map(id => `product: '${id}'`).join()
                            }
                        });
                    }
                }}
                sctaHandler={onClose}
            />
        </Dialog>
    );
});

const
    width = 600,
    height = 823;

const mapStateToProps = appState => {
    const { hasOnlyDigitalShop } = makeEpicStateSelector(WEBSHOP_ONBOARD_STATUS)(appState);
    return { hasOnlyDigitalShop };
};

const config = getCenteredDialogConfig({
    width,
    height,
    component: connect(mapStateToProps)(ProductWidgetProductSelectionDialog),
    draggableClassName: '',
    dialogBackgroundClassName: styles.dialogBackground,
    dialogClassName: styles.dialogManagerContainer
});

const getDialogDimensions = (browserHeight) => Math.min(Math.max(browserHeight - 100, 475), height);

export default {
    ...config,
    confFactory: ({ browserWidth, browserHeight }) => {
        const height = getDialogDimensions(browserHeight);
        return {
            position: getCenterPositionForDialog(width, height, browserWidth, browserHeight),
            modal: true,
            dimensions: { width, height }
        };
    },
    moveToCenter: true,
};
