/* eslint-disable max-len */

import React from 'react';
import { injectIntl } from '../../../../view/intl/index';
import { Dialog, DialogBody } from '../../../../view/common/dialogs/baseDialog/index';
import getCenteredDialogConfig from "../../../DialogManager/getCenteredDialogConfig";
import DialogTitleBox from "../../../../view/common/dialogs/baseDialog/DialogTitleBox";
import styles from './TemplateWidthDialog.css';
import DialogFooter from "../../../../view/common/dialogs/baseDialog/DialogFooter";
import { CLOSE_DIALOG } from "../../../../redux/modules/actionTypes";
import { UNDO_BUTTON_PRESSED } from "../../../TopBar/actionTypes";

const
    width = 591,
    height = 422,
    siteWideTitle = 'msg: template.siteWiderTitle {Site wider than recommended}',
    siteWideMsg = 'msg: template.siteWiderMsg {You have made your site wider than 1200 px. As most screens are smaller than this, we recommend you remain under 1200 px to avoid a horizontal scrollbar on your site.}',
    getDialogFooterProps = (dispatch) => {
        return {
            tctaText: "msg: common.close {Close}",
            tctaHandler: () => dispatch({ type: CLOSE_DIALOG }),
            tctaButtonClassName: styles.closeButton,
            mctaText: "msg: common.undo {Undo}",
            mctaHandler: () => {
                dispatch({ type: UNDO_BUTTON_PRESSED });
                dispatch({ type: CLOSE_DIALOG });
            }
        };
    },
    templateWidthDialogComponent = injectIntl(({ intl, dispatch }) => {
        return (
            <Dialog stripType="" showCloseBtn>
                <DialogTitleBox
                    title={siteWideTitle}
                    iconClass={styles.iconWarning}
                    titleClass={styles.siteWideTitle}
                    className={styles.siteWideTitleContainer}
                />
                <DialogBody>
                    <div className={styles.siteWideBodyContainer}>
                        {intl.msgJoint(siteWideMsg)}
                        <div className={styles.siteWiderIcon} />
                    </div>
                </DialogBody>
                <DialogFooter
                    {...getDialogFooterProps(dispatch)}
                    noTopBorder
                    primaryBtnClass={styles.undoButton}
                />
            </Dialog>
        );
    }),
    templateWidthDialog = {
        ...getCenteredDialogConfig(width, height, templateWidthDialogComponent)
    };

export default templateWidthDialog;

