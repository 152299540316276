import { textLimitWarningEnum, textLimitWarningEpicInitialStateType } from "./types";

const textLimitWarningEpicInitialState: textLimitWarningEpicInitialStateType = {
    showWarning: false,
    remainingCharacters: 0,
    warningType: textLimitWarningEnum.ON_TYPE,
};

export {
    textLimitWarningEpicInitialState
};
