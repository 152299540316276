import React from 'react';
import View from "../../Strip/view/index";
import styles from "./workspace.css";
import { StretchBase as StretchBaseComponent } from "../../../../view/oneweb/BaseComponent";
import { SPLIT_SECTION_COMPONENT } from "../epics/splitSectionDecoration/actionTypes";
import Msg from "../../../../view/intl/Msg";
import { NoMouseUpDownAndMove } from "../../../../view/common/NoMouseEventsPropagation";

const Divider = ({ top, onClick }) => (
    <div style={{ top }} className={styles.dividerLine}>
        <div onClick={onClick} {...NoMouseUpDownAndMove} className={styles.dividerText}>
            <Msg k="common.section.split">Split here</Msg>
        </div>
    </div>
);
export const workspace = (props: any) => {
    const { splitData, dispatch } = props;
    return (
        <div data-section-title={props.title}>
            <StretchBaseComponent {...props}>
                <View {...props} />
            </StretchBaseComponent>
            {
                splitData.splitPoints &&
                splitData.splitPoints.map((splitPoint, index) => {
                    const { sectionId, topOfGap, gapHeight } = splitPoint;
                    if (props.base.id !== sectionId) return null;
                    const top = topOfGap + (gapHeight / 2);
                    return (
                        <Divider
                            key={index}
                            top={top}
                            onClick={() => (
                                dispatch({
                                    type: SPLIT_SECTION_COMPONENT,
                                    payload: splitPoint
                                })
                            )}
                        />
                    );
                })
            }
        </div>
    );
};
