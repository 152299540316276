import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import * as ActionTypes from '../../actionTypes';
import type { LeftPanelEpicState } from '../../flowTypes';
import type { EpicUpdaterConfig } from '../../../../epics/flowTypes';
import { receiveOnly } from "../../../../epics/makeCondition";
import { undoManagerValueActionType } from '../../../../epics/undoManager/valueActionType';
import { ReceiveOnlyComponentsMap } from "../../../Workspace/epics/componentsEval/selectorActionTypes";
import { isModernLayoutActivatedVAT } from '../../../Workspace/epics/isModernLayoutActivatedEpic/valueActionType';
import {
    ACTIVATE_MODERN_HEADER_FOOTER,
    DEACTIVATE_MODERN_HEADER_FOOTER,
    DEACTIVATE_MODERN_HEADER,
    DEACTIVATE_MODERN_FOOTER,
    MOBILE_EDITOR_MOUSE_ENTER,
    PREVIEW_MOUSE_ENTER,
    BACKUP_RESTORE_MOUSE_ENTER,
    PREVIEW_MOUSE_LEAVE, MOBILE_EDITOR_MOUSE_LEAVE, BACKUP_RESTORE_MOUSE_LEAVE,
} from "../../actionTypes";
import {
    activateModernFooterAction,
    activateModernHeaderAction,
} from "../../actions";
import { defaultHeaderId } from "../../layoutsData/headerLayouts";
import { defaultFooterId } from "../../layoutsData/footerLayouts";
import { APP_RIGHT_MOUSE_DOWN, BACK_TO_EDITOR } from "../../../App/actionTypes";
import { APP_LEFT_MOUSE_DOWN } from "../../../../redux/modules/actionTypes";
import { TOP_BAR_OPEN_POPUP } from "../../../TopBar/actionTypes";
import { UserFocusValueActionType } from "../../../App/epics/userFocus/valueActionType";
import * as kinds from "../../../App/epics/userFocus/kind";
import { getIsModernHeaderFooterNeverActivated, getTemplateSectionByName,
    isAddWebshopMHFCmps } from '../../utils';
import { sectionNames } from "../../constants";
import webShopMHFValueActionType from "../../../oneweb/WebShopMHF/epic/valueActionType";
import mhfDataVAT from '../oldData/valueActionType';

const defaultState = {
    show: false,
    isFooter: false,
    undoStackIndex: -1,
    preserveShow: false,
    showRestoreLayouts: false,
    layoutItemHeights: {}
};

const isFooterUpgradeRequired = (isModernLayoutActive, layoutVersion, webshopMHFData, componentsMap,
    layoutId, mhfData) => {
    if (!isModernLayoutActive) {
        return false;
    }
    if (!layoutVersion) {
        return true;
    }
    return isAddWebshopMHFCmps(webshopMHFData, componentsMap, layoutId, mhfData);
};

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: ([
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                ACTIVATE_MODERN_HEADER_FOOTER
            ],
            reducer: ({ state, values: [componentsMap] }) => {
                const headerSection = getTemplateSectionByName(sectionNames.header, componentsMap),
                    footerSection = getTemplateSectionByName(sectionNames.footer, componentsMap),
                    headerLayoutId = (headerSection.modernLayout && headerSection.modernLayout.layoutId)
                        || defaultHeaderId,
                    footerLayoutId = (footerSection.modernLayout && footerSection.modernLayout.layoutId)
                        || defaultFooterId;
                // order here matter for the section tooltip to show in the correct section
                let multipleActionsToDispatch: Action[] = [
                    activateModernHeaderAction(headerLayoutId),
                    activateModernFooterAction(footerLayoutId)
                ];
                if (state.show && !state.isFooter) {
                    multipleActionsToDispatch = [
                        activateModernFooterAction(footerLayoutId),
                        activateModernHeaderAction(headerLayoutId)
                    ];
                }
                return {
                    state: {
                        ...state,
                        showRestoreLayouts: false,
                    },
                    multipleActionsToDispatch,
                };
            }
        },
        {
            conditions: [DEACTIVATE_MODERN_HEADER_FOOTER],
            reducer: ({ state }) => {
                // order here matter for the section tooltip to show in the correct section
                let multipleActionsToDispatch: Action[] = [
                    { type: DEACTIVATE_MODERN_HEADER },
                    { type: DEACTIVATE_MODERN_FOOTER }
                ];
                if (state.show && !state.isFooter) {
                    multipleActionsToDispatch = [
                        { type: DEACTIVATE_MODERN_FOOTER },
                        { type: DEACTIVATE_MODERN_HEADER }
                    ];
                }
                return {
                    state,
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                receiveOnly(isModernLayoutActivatedVAT),
                receiveOnly(undoManagerValueActionType),
                ActionTypes.SHOW_MODERN_HEADER_LAYOUT_LEFT_PANEL,
            ],
            reducer: ({ state, values: [componentsMap, isModernLayoutActive, { undoState }, selectedComponent] }) => {
                const modernLayoutPreviouslyActivated = !getIsModernHeaderFooterNeverActivated(componentsMap);
                let actionToDispatch: Action | null = null;
                if (!modernLayoutPreviouslyActivated) {
                    return {
                        state: defaultState,
                        actionToDispatch: { type: ActionTypes.SHOW_MODERN_LAYOUT_ONBOARDING_PANEL }
                    };
                } else {
                    const layoutVersion = selectedComponent.modernLayout && selectedComponent.modernLayout.layoutVersion;
                    if (isModernLayoutActive && !layoutVersion) {
                        actionToDispatch = {
                            type: ActionTypes.UPGRADE_MODERN_HEADER_FOOTER
                        };
                    }
                }
                return {
                    state: {
                        ...state,
                        show: true,
                        isFooter: false,
                        showRestoreLayouts: modernLayoutPreviouslyActivated && !isModernLayoutActive,
                        undoStackIndex: undoState.commandsStackIndex,
                        layoutItemHeights: {},
                    },
                    actionToDispatch
                };
            },
        },
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                receiveOnly(isModernLayoutActivatedVAT),
                receiveOnly(undoManagerValueActionType),
                receiveOnly(webShopMHFValueActionType),
                receiveOnly(mhfDataVAT),
                ActionTypes.SHOW_MODERN_FOOTER_LAYOUT_LEFT_PANEL,
            ],
            reducer: ({ state, values: [componentsMap, isModernLayoutActive, { undoState },
                webshopMHFData, mhfData, selectedComponent] }) => {
                const modernLayoutPreviouslyActivated = !getIsModernHeaderFooterNeverActivated(componentsMap);
                let actionToDispatch: Action | null = null;
                if (!modernLayoutPreviouslyActivated) {
                    return {
                        state: defaultState,
                        actionToDispatch: { type: ActionTypes.SHOW_MODERN_LAYOUT_ONBOARDING_PANEL }
                    };
                } else {
                    const layoutVersion = selectedComponent.modernLayout && selectedComponent.modernLayout.layoutVersion,
                        layoutId = selectedComponent.modernLayout && selectedComponent.modernLayout.layoutId;
                    if (isFooterUpgradeRequired(isModernLayoutActive, layoutVersion, webshopMHFData, componentsMap,
                        layoutId, mhfData)) {
                        actionToDispatch = {
                            type: ActionTypes.UPGRADE_MODERN_HEADER_FOOTER,
                        };
                    }
                }
                return {
                    state: {
                        ...state,
                        show: true,
                        isFooter: true,
                        showRestoreLayouts: modernLayoutPreviouslyActivated && !isModernLayoutActive,
                        undoStackIndex: undoState.commandsStackIndex,
                        layoutItemHeights: {},
                    },
                    actionToDispatch
                };
            },
        },
        {
            conditions: [
                ActionTypes.DESKTOP_PREVIEW_LOADED_IN_SIDEBAR
            ],
            reducer: ({ values: [{ sectionRect, layoutItem }], state }) => {
                const { id } = layoutItem;
                return {
                    state: {
                        ...state,
                        layoutItemHeights: {
                            ...state.layoutItemHeights,
                            [id]: {
                                ...state.layoutItemHeights[id],
                                height: sectionRect.height,
                                show: true
                            }
                        }
                    },
                };
            },
        },
        ...[MOBILE_EDITOR_MOUSE_ENTER, PREVIEW_MOUSE_ENTER, BACKUP_RESTORE_MOUSE_ENTER].map(action => ({
            conditions: [action],
            reducer: ({ state }) => {
                if (state.show) {
                    return {
                        state: {
                            ...state,
                            preserveShow: true
                        }
                    };
                }
                return { state };
            }
        })),
        ...[MOBILE_EDITOR_MOUSE_LEAVE, PREVIEW_MOUSE_LEAVE, BACKUP_RESTORE_MOUSE_LEAVE, BACK_TO_EDITOR].map(action => ({
            conditions: [receiveOnly(UserFocusValueActionType), action],
            reducer: ({ state, values: [{ kind }] }) => {
                if (state.show && kind === kinds.WORKSPACE) {
                    return {
                        state: {
                            ...state,
                            preserveShow: false
                        }
                    };
                }
                return { state };
            }
        })),
        ...[
            TOP_BAR_OPEN_POPUP,
            APP_LEFT_MOUSE_DOWN,
            APP_RIGHT_MOUSE_DOWN,
            ActionTypes.HIDE_MODERN_LAYOUT_LEFT_PANEL,
            ActionTypes.DEACTIVATE_MODERN_HEADER_FOOTER,
        ]
            .map(action => ({
                conditions: [action],
                reducer: ({ state }) => {
                    if (state.preserveShow) {
                        return { state };
                    }
                    return ({ state: { ...defaultState, layoutItemHeights: state.layoutItemHeights } });
                }
            }))
    ] as Array<EpicUpdaterConfig<LeftPanelEpicState, any, any>>)
});
