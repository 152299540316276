import Background from "../../oneweb/Background/kind";
import HoverBox from "../../oneweb/HoverBox/kind";
import Strip from "../../oneweb/Strip/kind";
import Image from "../../oneweb/Image/kind";
import * as styles from './Preview.css';
import type { AnyComponent } from "../../../redux/modules/children/workspace/flowTypes";
import type { KindsMap } from "../flowTypes";
import getMobileViewAlignmentClass from "./getMobileViewAlignmentClass";
import Section from "../../oneweb/Section/kind";

export const
    BackgroundKinds: KindsMap = {
        [Background]: true,
        [HoverBox]: true,
    },
    MobileBlocks: KindsMap = {
        ...BackgroundKinds,
        [Strip]: true,
        [Section]: true,
        [Image]: true
    },
    dataKinds = {
        block: 'Block',
        component: 'Component'
    };

const isWrappedAtAnyLevel = (component, structure, templateId) => {
    let isWrapped = false;
    const processComponent = ({ wrap, relIn }) => {
        if (wrap) {
            isWrapped = true;
            return;
        }
        if (relIn && relIn.id && relIn.id !== templateId && structure[relIn.id] && structure[relIn.id].block) {
            processComponent(structure[relIn.id].block);
        }
    };
    processComponent(component);
    return isWrapped;
};

export default (
    component: AnyComponent,
    dataKind: string,
    isMobileHide: boolean,
    structure: any,
    templateId: string,
): string => {
    const { inTemplate, kind, mobileDown } = component;
    let className = '';
    if (isMobileHide) {
        className += styles.mobileHide;
    }
    className += ` ${(dataKinds.block === dataKind ? styles.block : styles.component)}`;
    if (
        inTemplate &&
        mobileDown &&
        (
            kind !== Image ||
            (
                dataKind === dataKinds.component &&
                !isWrappedAtAnyLevel(component, structure, templateId)
            )
        )
    ) {
        className += ` mobileDown`;
    }
    className += ` ${getMobileViewAlignmentClass(component)}`;
    return className;
};
