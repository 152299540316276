import * as React from 'react';
import cx from 'classnames';
import styles from './FmImagePreview.css';
import type { RenderHoverBoxPreviewParams } from "../../presentational/HoverBox/HoverBoxPreviewCom";
import type { FmImagePreviewDimensionsT } from "./types";
import type { Dimensions } from "../../../globalTypes";
import LazyImage from '../../../view/common/LazyImage/index';
import LongTextTip from '../../../view/common/LongTextTip/index';
import { FmImagePreviewDimensions } from "./constants";
import { decodeWebspaceUri } from "../../../../dal/utils/webspaceUriTransformers";
import { CssObjectFit } from "../../../mappers/constatns";
import { Msg } from '../../../view/intl/index';

const DimensionsCache = {};

type FactoryParams = {
    title: string,
    dimensions: FmImagePreviewDimensionsT,
    previewDimensions?: Dimensions,
    imgContainerClassName?: string,
    photographerName?: string | null,
    photographerProfileLink?: string,
};

type Props = FactoryParams & RenderHoverBoxPreviewParams;

type State = {
    dimensionsLoaded: boolean,
    origWidth: number,
    origHeight: number,
};

class FmImageRenderPreview extends React.Component<Props, State> {
    state: State = {
        dimensionsLoaded: false,
        origWidth: 0,
        origHeight: 0,
    };

    constructor(props: Props) {
        super(props);

        if (DimensionsCache[props.src]) {
            this.state = {
                dimensionsLoaded: true,
                ...DimensionsCache[props.src],
            };
        }
    }

    getPreviewDimensions(): Dimensions {
        if (this.props.previewDimensions) return this.props.previewDimensions;

        const
            { origWidth, origHeight } = this.state,
            isHorizontal = origWidth > origHeight,
            width = isHorizontal ? FmImagePreviewDimensions.WIDTH : FmImagePreviewDimensions.HEIGHT,
            height = isHorizontal ? FmImagePreviewDimensions.HEIGHT : FmImagePreviewDimensions.WIDTH;

        return { width, height };
    }

    componentDidMount(): void {
        if (DimensionsCache[this.props.src]) return;

        this.props.dimensions().then(({ width, height }: Dimensions) => {
            DimensionsCache[this.props.src] = {
                origWidth: width,
                origHeight: height,
            };

            this.setState({
                dimensionsLoaded: true,
                ...DimensionsCache[this.props.src],
            });
        });
    }

    shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
        return nextProps.shouldLoad !== this.props.shouldLoad
            || nextState.dimensionsLoaded !== this.state.dimensionsLoaded;
    }

    render() {
        const
            { src, title, imgContainerClassName, photographerName, photographerProfileLink } = this.props,
            { origWidth, origHeight } = this.state,
            shouldLoad = this.state.dimensionsLoaded && this.props.shouldLoad,
            { width: previewWidth, height: previewHeight } = this.getPreviewDimensions();

        return (
            <React.Fragment>
                <div
                    className={cx(styles.imgContainer, imgContainerClassName)}
                    style={{ width: previewWidth, height: previewHeight }}
                >
                    <LazyImage
                        className={styles.image}
                        style={{
                            objectFit: CssObjectFit.NONE,
                        }}
                        src={src}
                        shouldLoad={shouldLoad}
                        showTransparency
                    />
                </div>
                <div className={styles.description}>
                    <LongTextTip maxWidth={previewWidth * 0.8}>
                        {decodeWebspaceUri(title)}
                    </LongTextTip>
                    <span>{`${origWidth}x${origHeight}px`}</span>
                </div>
                {
                    photographerName &&
                    <div className={styles.descriptionData}>
                        <Msg
                            className={cx(styles.userDescriptionCommon, styles.descriptionTitle)}
                            k="common.Photographer"
                        >Photographer:</Msg>
                        &nbsp;
                        <span>
                            <a
                                className={cx(styles.userDescriptionCommon, styles.userProfileLink)}
                                href={photographerProfileLink}
                                target="_blank"
                            >{photographerName}</a>
                        </span>
                    </div>
                }
            </React.Fragment>
        );
    }
}

export const makeFmImageRenderPreview = (params: FactoryParams) =>
    (props: RenderHoverBoxPreviewParams) => (
        <FmImageRenderPreview
            {...params}
            {...props}
        />
    );
