import React, { Component } from "react";
import cx from "classnames";
import InputField from "../../../../../view/common/Input/InputField";
import PrimaryButton from "../../../../../view/common/Button/PrimaryButton";
import type { PasteYoutubeLinkContainerProps, PasteYoutubeLinkContainerState } from "../flowTypes";
import Msg from '../../../../../view/intl/Msg';
import * as styles from './YoutubeVideoDialog.css';
import { adjustUrl } from "../../videoUrlsUtility";
import { VideoUrlPrefix } from "../../constants";
import { VIDEO_SELECTED } from "../../actionTypes";
import getVideoIdFromUrl from "../../getVideoIdFromUrl";

class PasteYoutubeLink extends Component<PasteYoutubeLinkContainerProps, PasteYoutubeLinkContainerState> {
    constructor(props: PasteYoutubeLinkContainerProps) {
        super(props);
        this.state = {
            value: VideoUrlPrefix,
            error: false
        };
    }

    onChange(newValue: string) {
        const { value, error } = this.state;
        if (error && (value !== newValue)) {
            this.setState({ value: newValue, error: false });
        }
        this.setState({ value: newValue });
    }

    onAddVideo() {
        const { dispatch } = this.props,
            url = adjustUrl(this.state.value),
            videoId = getVideoIdFromUrl(url);
        if (videoId) {
            dispatch({ type: VIDEO_SELECTED, payload: videoId });
        } else {
            this.setState({ error: true });
        }
    }

    render() {
        const { value, error } = this.state;
        return (
            <div>
                <div className={styles.youtubeIcon} />
                <div className={styles.enterUrl}>
                    <Msg k="component.video.enterYoutubeUrl">Enter the URL of a YouTube video</Msg>
                </div>
                <div className={styles.pasteLinkContainer}>
                    <InputField
                        value={value}
                        className={cx(styles.youtubeUrlInput, { [styles.urlError]: error })}
                        onChange={this.onChange.bind(this)}
                        onEnterPress={this.onAddVideo.bind(this)}
                    />
                    <PrimaryButton onClick={this.onAddVideo.bind(this)} style={{ 'height': 'inherit' }}>
                        <Msg k="component.video.addVideo">Add video</Msg>
                    </PrimaryButton>
                </div>
            </div>
        );
    }
}

export default PasteYoutubeLink;
