import type { SstockImage, SstockFullImageAsset, SstockShallowImageAsset } from "../flowTypes";
import type { Dimensions } from "../../src/globalTypes";

export class SstockImageModel {
    /*::#*/ data: SstockImage;

    constructor(data: SstockImage) {
        this./*::#*/data = data;
    }

    getOrig(): SstockFullImageAsset {
        return this./*::#*/data.assets.orig;
    }

    getOrigDimensions(): Dimensions {
        const { width, height } = this.getOrig();
        return { width, height };
    }

    getThumb(): SstockShallowImageAsset {
        return this./*::#*/data.assets.thumb;
    }

    getPreview(): SstockShallowImageAsset {
        return this./*::#*/data.assets.preview;
    }

    getPreviewUrl(): string {
        return this.getPreview().url;
    }

    getPreviewDimensions(): Dimensions {
        const { width, height } = this.getPreview();
        return { width, height };
    }

    getDescription(): string {
        return this./*::#*/data.description;
    }

    getFilename(): string {
        return `${this./*::#*/data.description}.${this./*::#*/data.assets.orig.format}`;
    }

    getUserName(): null | undefined | string {
        return this./*::#*/data.user ? this./*::#*/data.user.name : null;
    }

    getUserProfileLink(): undefined | string {
        return this./*::#*/data.user ? this./*::#*/data.user.profile_link_html : undefined;
    }

    getUserProfileImageUrl(): null | undefined | string {
        return this./*::#*/data.user ? this./*::#*/data.user.profile_image : null;
    }
}
