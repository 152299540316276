import React from 'react';
import type { PageTemplateSelectorErrorDialogProps } from "./types";
import WarningDialog from '../../../../../view/common/dialogs/WarningDialog';
import { injectIntl } from "../../../../../view/intl";

export const PageTemplateSelectorErrorDialogCom = injectIntl(
    ({ msg, intl }: PageTemplateSelectorErrorDialogProps) => (
        <WarningDialog
            title="msg: common.errorOccurred {An error occurred}"
            mctaText="msg: common.ok {OK}"
        >
            <div>{intl.msgJoint(msg)}</div>
            <div>
                {intl.msg(
                    'common.tryAgainOrContactSupport',
                    'Please try again later or contact our support for further assistance.'
                )}
            </div>
        </WarningDialog>
    )
);

