import React from 'react';
import Msg from "../../../../../view/intl/Msg";

import styles from './Products.css';

type Props = {
    category: string,
    searchTerm: string
};

export default ({ searchTerm, category }: Props) => {
    if (category) {
        const params = { searchTerm, category };
        return (
            <div className={styles.noResultsFound}>
                <Msg k="shutterstock.noSearchResult" params={params}>
                    {`No results found for \"{searchTerm}\" in \"{category}\".`}
                </Msg>
            </div>
        );
    }

    const params = { searchTerm };
    return (
        <div className={styles.noResultsFound}>
            <Msg k="shutterstock.noSearchResultAllCategory" params={params}>
                {`No results found for \"{searchTerm}\".`}
            </Msg>
        </div>
    );
};
