export const
    MessageTypes = {
        INFO: 'info',
        ERROR: 'error'
    },
    MessageCodes = {
        LOADING: "common.loading",
        PROCESSING: "common.processing",
        SAVING_FILE_AS: "common.savingAs",
        GENERAL_ERROR: "imageEditor.generalError"
    },
    Messages = {
        [MessageCodes.LOADING]: {
            type: MessageTypes.INFO,
            text: "msg: common.Loading {Loading...}"
        },
        [MessageCodes.PROCESSING]: {
            type: MessageTypes.INFO,
            text: "msg: common.processing {Processing...}"
        },
        [MessageCodes.SAVING_FILE_AS]: {
            type: MessageTypes.INFO,
            text: "msg: common.savingAs {Saving as}"
        },
        [MessageCodes.GENERAL_ERROR]: {
            type: MessageTypes.ERROR,
            text: "msg: imageEditor.generalError {An unexpected error occurred. Please reload Website Builder or contact our support for further assistance.}" // eslint-disable-line
        }
    };
