import React, { useRef, useEffect } from "react";
import Lottie from "react-web-lottie";
import animationData from "./animation.json";
import { getStepMarkers, ONBOARDING_STEPS, LOTTIE_DEFAULT_OPTIONS } from "./constant";

type GMBCategoriesStepRightSideViewProps = {
    selectedCategoriesCount: number;
    isForwardDirection: boolean;
};
const markers = animationData.markers;
const stepMarkers = getStepMarkers(markers, ONBOARDING_STEPS.GMB_CATEGORIES);
let startPoint, stopPoint, initialSegment;
export default function GMBCategoriesStepRightSideView({
    selectedCategoriesCount,
    isForwardDirection
}: GMBCategoriesStepRightSideViewProps) {
    const animationRef = useRef<HTMLDivElement>(null),
        categoriesCount = useRef(selectedCategoriesCount),
        isLeftdirection = selectedCategoriesCount < categoriesCount.current;
    categoriesCount.current = selectedCategoriesCount;

    switch (selectedCategoriesCount) {
        case 1:
            if (isLeftdirection) {
                startPoint = stepMarkers.gmb_category2_selected;
                stopPoint = stepMarkers.gmb_category1_selected;
            } else {
                startPoint = stepMarkers.gmb_stop_point;
                stopPoint = stepMarkers.gmb_category1_selected;
            }

            break;

        case 2:
            if (isLeftdirection) {
                startPoint = stepMarkers.gmb_category3_selected;
                stopPoint = stepMarkers.gmb_category2_selected;
            } else {
                startPoint = stepMarkers.gmb_category1_selected;
                stopPoint = stepMarkers.gmb_category2_selected;
            }

            break;

        case 3:
            startPoint = stepMarkers.gmb_category2_selected;
            stopPoint = stepMarkers.gmb_category3_selected;
            break;

        default:
            if (isLeftdirection) {
                startPoint = stepMarkers.gmb_category1_selected;
                stopPoint = stepMarkers.gmb_stop_point;
            } else {
                startPoint = stepMarkers.gmb_start_of_step;
                stopPoint = stepMarkers.gmb_stop_point;
            }

            break;
    }

    // Moving backward
    if (!isForwardDirection && !animationRef.current) {
        startPoint = stopPoint;
        stopPoint = stopPoint + 1;
        initialSegment = [startPoint, stopPoint];
    } else {
        initialSegment = [stepMarkers.gmb_start_of_step, stepMarkers.gmb_start_of_step + 1];
    }

    useEffect(() => {
        // @ts-ignore
        animationRef.current.playSegments([[startPoint, stopPoint]], true);
    }, [selectedCategoriesCount]);
    useEffect(() => {
        if (isForwardDirection) {
            // @ts-ignore
            animationRef.current.playSegments([[stepMarkers.gmb_start_of_step, stopPoint]], true);
        }
    }, []);
    const defaultOptions = { ...LOTTIE_DEFAULT_OPTIONS, initialSegment, animationData };
    return <Lottie lottiRef={animationRef} options={defaultOptions} isClickToPauseDisabled speed={0.7} />;
}
