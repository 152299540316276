import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import { SLIDER_ON_BEFORE_CHANGE, SLIDER_ON_AFTER_CHANGE } from "../../../../view/common/Slider/actionTypes";
import { END_COMPONENT_CHANGING_USER_INTERACTION } from '../componentsEval/actionTypes';

const defaultState = false;

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [SLIDER_ON_BEFORE_CHANGE],
            reducer: () => ({ state: true })
        },
        {
            conditions: [SLIDER_ON_AFTER_CHANGE],
            reducer: () => ({ state: false, actionToDispatch: { type: END_COMPONENT_CHANGING_USER_INTERACTION } })
        }
    ]
});
