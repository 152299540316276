import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button, DialogContent, Link } from "@mui/material";

import { useIntl, Msg } from "../../../../../../../wbtgen/src/view/intl";
import { UpgradeDialogPlanTypeMap } from "../../../../../../../wbtgen/src/components/TopBar/view/Upgrade/UpgradePlansDialog/constants";
import VerticalSpacer from "../../../../../../../wbtgen/src/view/common/VerticalSpacer";
import { UpgradeCancelReason } from "../../../../../../../wbtgen/src/components/EventTracking/constants";

import { BasicDialog } from "../../../Dialog/BasicDialog";
import { closeDialogAction } from "../../../Dialog/actions";
import { seamlessUpgradeAction } from "../../../Subscription/actions";
import {
    upgradeCanceledAction,
    freeUpgradeApprovedAction
} from "../actions";
import type { UpgradeApproveDialogProps } from "../types";
import { getCapitalizedCaseName } from "../utils";
import { useStyles } from "./styles";
import { subscriptionTypeSelector } from "../../../../common/selectors";

const TermsLink = ({ intl }) => {
    const classes = useStyles();
    return (
        <Link
            href="https://www.one.com/en/terms-and-conditions-eur"
            target="_blank"
            className={classes.linkText}
        >
            {intl.msgJoint('msg: upgrade.approve.terms.footer.link {Terms of use}')}
        </Link>
    );
};

export const UpgradeApproveDialog = ({ dialogProps, config }: UpgradeApproveDialogProps) => {
    const { freeMonths, pricePerMonth, upgradeType } = dialogProps;

    const intl = useIntl();
    const dispatch = useDispatch();
    const classes = useStyles();

    const upgradeToType = getCapitalizedCaseName(intl.msgJoint(UpgradeDialogPlanTypeMap[upgradeType]));
    const termsLink = <TermsLink intl={intl} />;

    const currentSubscriptionType = useSelector(subscriptionTypeSelector);
    const currentSubscriptionTypeLabel = getCapitalizedCaseName(
        intl.msgJoint(UpgradeDialogPlanTypeMap[currentSubscriptionType])
    );

    const handleCancelOrClose = () => {
        dispatch(upgradeCanceledAction(UpgradeCancelReason.UserCancelled));
        dispatch(closeDialogAction());
    };

    const handleApprove = () => {
        dispatch(freeUpgradeApprovedAction());
        dispatch(seamlessUpgradeAction(upgradeType));
    };

    return (
        <BasicDialog
            onClose={handleCancelOrClose}
            classes={{ paper: classes.paper }}
            {...config}
        >
            <DialogContent>
                <div className={classes.dialogTitle}>
                    {intl.msgJoint([`msg: upgrade.approve.dialog.title {Upgrade to {upgradeToType}}`, { upgradeToType }])}
                </div>
                <VerticalSpacer y={30} />
                <div className={classes.dialogDesc}>
                    <Msg
                        k="upgrade.approve.dialog.message1"
                        params={{ freeMonths, pricePerMonth }}
                    >
                        {"You will get access to the service free of charge for the next {freeMonths} months. After that, it is just {pricePerMonth} /month added to your current hosting price."}
                    </Msg>
                </div>
                <VerticalSpacer y={30} />
                <div className={classes.dialogDesc}>
                    {intl.msgJoint([
                        `msg: upgrade.approve.dialog.message3 {You can downgrade from Website Builder {upgradeToType} plan to {currentSubscriptionTypeLabel} anytime during the free period.}`,
                        { upgradeToType, currentSubscriptionTypeLabel }
                    ])}
                </div>

                <Grid container className={classes.buttonContainer}>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.actionButton}
                        onClick={handleCancelOrClose}
                    >
                        {intl.msgJoint("msg: common.cancel {Cancel}")}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.actionButton}
                        onClick={handleApprove}
                    >
                        {intl.msgJoint("msg: common.approve {Approve}")}
                    </Button>
                </Grid>
                <div className={classes.dialogDesc}>
                    <Msg
                        k="upgrade.approve.terms.footer"
                        className={classes.linkText}
                        params={{ link: termsLink }}
                    >
                        {`By clicking approve you agree to our {link}`}
                    </Msg>
                </div>
            </DialogContent>
        </BasicDialog>
    );
};

export const UPGRADE_APPROVE_DIALOG_ID = 'UPGRADE_APPROVE_DIALOG_ID';
