import type { ImageAsset } from "../App/flowTypes";
import { isSVG, isGif } from "../oneweb/Image/utils";

const MAX_ALLOWED_DIMENSION = 4096;
export const validateSocialShareAsset = (
    socialShareAsset: ImageAsset | null | undefined,
) => {
    if (socialShareAsset) {
        if (isGif(socialShareAsset.contentType)) {
            // eslint-disable-next-line max-len
            return "msg: socialShare.asset.hint.gif {Looks like you've selected a GIF animation. Please be advised that most social networks do not support GIF animations.}";
        } else if (isSVG(socialShareAsset.contentType)) {
            // eslint-disable-next-line max-len
            return "msg: socialShare.asset.hint.svg {Looks like you've selected a SVG file. Please be advised that most social networks do not support SVG files for preview.}";
        } else if (socialShareAsset.width / socialShareAsset.height < 0.9) {
            // eslint-disable-next-line max-len
            return "msg: socialShare.asset.hint.aspectRatio {Looks like you're not using the recommended aspect ratio for social share images. Try a landscape image instead.}";
        } else if (
            socialShareAsset.width > MAX_ALLOWED_DIMENSION ||
            socialShareAsset.height > MAX_ALLOWED_DIMENSION
        ) {
            // eslint-disable-next-line max-len
            return "msg: socialShare.asset.hint.large {Looks like you're using an image that exceeds the size limit on most social networks. Please try an image smaller than 4096x4096 px.}";
        }
    }

    return "";
};
