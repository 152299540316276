import { YoutubeUrlPattern } from "./constants";

export default (url: string): string => {
    const oldPatternMatch = url.match(new RegExp(YoutubeUrlPattern));
    if (oldPatternMatch) {
        return oldPatternMatch[1];
    }

    const newPatternMatch = url.match(/(https?):\/\/youtu.be\/([^$]+)/);
    if (newPatternMatch) {
        return newPatternMatch[2];
    }

    const shortsPatternMatch = url.match(/^(?:https:\/\/)?(?:www\.)?youtube\.com\/shorts\/([\w\d-]+)/);
    if (shortsPatternMatch) {
        return shortsPatternMatch[1];
    }

    return '';
};
