import React from 'react';
import registry from "../registryWithTemplate";
import Scrollbar from '../../../view/common/Scrollbar/index';
import type { PageProps } from '../flowTypes';
import type { AnyComponent } from '../../../redux/modules/children/workspace/flowTypes';

class Content extends React.Component<PageProps<AnyComponent>> {
    shouldComponentUpdate(nextProps: PageProps<AnyComponent>) {
        return !(
            nextProps.selectedComponent === this.props.selectedComponent &&
            nextProps.componentDependencies === this.props.componentDependencies &&
            nextProps.controlsDependenciesForSelectedComponent ===
            this.props.controlsDependenciesForSelectedComponent &&
            nextProps.currentPage.id === this.props.currentPage.id
        );
    }

    render() {
        const {
            selectedComponent,
            selectedComponentExtension,
            currentPage,
            navigateToPage,
            dispatch,
            stylesheets,
            componentDependencies,
            controlsDependenciesForSelectedComponent,
            selectedComponentProps,
            mobileEnablement,
            additionalPayload,
            isRepositoryDomain,
            themeSettingsData: themeSettingsDataFromEpic,
            themeColorsData: themeColorsDataFromEpic
        } = this.props;

        const
            // @ts-ignore
            PageView = registry[selectedComponent.kind].propertiesPanel.pages[currentPage.id].content,
            // TODO WBTGEN-16602: Remove themeSettingsDataFromEpic and themeColorsDataFromEpic it should always come from component dependencies.
            themeSettingsData = (componentDependencies && componentDependencies.themeSettingsData) || themeSettingsDataFromEpic,
            themeColorsData = (componentDependencies && componentDependencies.themeColorsData) || themeColorsDataFromEpic,
            pageViewProps = {
                navigateToPage,
                dispatch,
                selectedComponent,
                selectedComponentExtension,
                ...componentDependencies,
                ...controlsDependenciesForSelectedComponent,
                selectedComponentProps,
                stylesheets,
                mobileEnablement,
                additionalPayload,
                isRepositoryDomain,
                themeSettingsData,
                themeColorsData
            };

        return (
            <Scrollbar height="100%">
                <PageView {...pageViewProps} />
            </Scrollbar>
        );
    }
}

export {
    Content as default
};
