import React, { useState } from 'react';
import cx from "classnames";
import { useDispatch, useSelector } from 'react-redux';

import { useIntl } from "../../../../../../../view/intl";
import { editAiTextSelector } from '../../../../epics/EditAITextEpic/selectors';
import {
    regenerateTextButtonClickedAction,
    selectGeneratedTextAction,
    updatePromptAction
} from '../../../../epics/EditAITextEpic/actionCreators/actions';
import { AI_EDIT_INSERT_BUTTON_CLICKED, AI_EDIT_COPY_BUTTON_CLICKED } from '../../../../epics/EditAITextEpic/actionTypes';

import { ResultLoader } from '../../CreateAIText/ResultsPanel/ResultLoader';
import { ButtonGroups } from './ButtonGroups';
import { TextPromptField } from './TextPromptField';
import { ActionButtons } from './ActionButtons';

import {
    MAX_INPUT_TEXT_LIMIT,
    MAX_TEXT_LENGTH,
    MIN_WORD_LENGTH,
    makeLongerBtn,
    makeShorterBtn,
    rephraseBtn
} from './constants';

import styles from './styles.css';

const EditTextResultCard = ({ version, index }) => {
    const {
        queryVersions,
        isQueryInProgress,
        promptText,
        hasWrappedElements,
        insertedVersion,
        copiedVersion
    } = useSelector(editAiTextSelector);
    const [isHovered, setIsHovered] = useState(false);
    const [inserted, setInserted] = useState(insertedVersion === index);
    const [copied, setCopied] = useState(copiedVersion === index);
    const dispatch = useDispatch();
    const intl = useIntl();
    const isLatestQueryInStreaming = queryVersions.length === (index + 1) && isQueryInProgress;
    const { mode, instructions, text: versionText } = version;
    const isTextLimitExceeded = versionText.length > MAX_INPUT_TEXT_LIMIT;

    const isLongTextSelected = isTextLimitExceeded && !isLatestQueryInStreaming && version.isSelected;
    const shouldShowSelectedState = !isLatestQueryInStreaming && version.isSelected && !isTextLimitExceeded;
    const shouldShowHoveredState = !version.isOriginal && (isLongTextSelected || (isHovered && !version.isSelected));

    const textInserted = () => {
        setInserted(true);
        dispatch({ type: AI_EDIT_INSERT_BUTTON_CLICKED, payload: { content: versionText, versionNumber: index, mode, instructions } });
    };

    const textCopied = () => {
        if (!navigator.clipboard) {
            return;
        }
        let textToBeCopied = versionText;
        if (versionText && (typeof versionText === 'string')) {
            const tempElement = document.createElement('div');
            tempElement.innerHTML = versionText;
            textToBeCopied = tempElement.innerText;
        }
        navigator.clipboard.writeText(textToBeCopied);
        setCopied(true);
        dispatch({ type: AI_EDIT_COPY_BUTTON_CLICKED,
            payload: { versionNumber: index, mode, instructions } });
    };

    const onButtonClick = (btnName) => {
        dispatch(regenerateTextButtonClickedAction(versionText, btnName));
    };

    const onSend = (instructions: string) => {
        dispatch(regenerateTextButtonClickedAction(versionText, rephraseBtn.id, instructions.trim()));
    };

    const handleMouseEnter = () => {
        setCopied(copiedVersion === index);
        setInserted(insertedVersion === index);
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const getWordCount = (htmlStr) => htmlStr.replace(/<\/?[^>]+(>|$)/g, " ").split(/\s+/).filter(str => !!str).length;

    const btnGroups = [
        rephraseBtn,
        {
            ...makeShorterBtn,
            disable: getWordCount(versionText) <= MIN_WORD_LENGTH
        },
        {
            ...makeLongerBtn,
            disable: versionText.length > MAX_TEXT_LENGTH
        }
    ];
    return (
        <div
            key={version.id}
            onClick={() => dispatch(selectGeneratedTextAction(version.id))}
            className={cx(styles.versionContainer, { [styles.selected]: version.isSelected })}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            data-testid="ai-edit-text-result"
        >
            <div className={styles.versionTextContainer}>
                <div className={styles.versionNumber}>
                    {
                        version.isOriginal ?
                            intl.msgJoint('msg: common.originalText {Original text}') :
                            intl.msgJoint(["msg: common.version.text {Version {version}}", { version: index }])
                    }
                </div>
                <div className={styles.versionText}>
                    <div dangerouslySetInnerHTML={{ __html: versionText }} />
                    {isLatestQueryInStreaming && <ResultLoader />}
                </div>
            </div>
            {
                shouldShowHoveredState ? (
                    <div className={styles.versionActionContainer}>
                        <div className={styles.actionContainerHovered}>
                            <ActionButtons
                                hasWrappedElements={hasWrappedElements}
                                isInserted={inserted}
                                onInsertAction={textInserted}
                                isCopied={copied}
                                onCopyAction={textCopied}
                            />
                        </div>
                    </div>
                ) : null
            }
            {
                shouldShowSelectedState ? (
                    <div className={styles.versionActionContainer}>
                        <div className={styles.actionContainer}>
                            <ButtonGroups btnGroups={btnGroups} onClick={(id) => onButtonClick(id)} />
                            {
                                !version.isOriginal && <ActionButtons
                                    hasWrappedElements={hasWrappedElements}
                                    isInserted={inserted}
                                    onInsertAction={textInserted}
                                    isCopied={copied}
                                    onCopyAction={textCopied}
                                />
                            }
                        </div>
                        <TextPromptField
                            prompt={promptText}
                            onPromptChange={(prompt) => {
                                dispatch(updatePromptAction(prompt));
                            }}
                            onSend={() => onSend(promptText)}
                        />
                    </div>
                ) : null
            }
        </div>
    );
};

export {
    EditTextResultCard
};
