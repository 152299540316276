import { logoPpMainPageConfig } from './pages/Main';
import * as mobileView from '../../../PropertiesPanel/view/MobileView/page';
import makePages from "../../../PropertiesPanel/view/makePages";
import { logoTitleStylePpPageConfig } from './pages/TitleStyle';
import addGoogleFontView from '../../../PropertiesPanel/view/AddGoogleFont/index';

export const logoPpConfig = {
    pages: makePages(
        logoPpMainPageConfig,
        logoTitleStylePpPageConfig,
        mobileView,
        addGoogleFontView
    ),
    defaultPageId: logoPpMainPageConfig.id
};
