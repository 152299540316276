import React from 'react';
import cx from 'classnames';
import type { Props } from '../flowTypes';
import * as styles from './preview.css';
import CodeComponentLocationTypes from "../locationTypes";

const
    HEAD_CODE_BEGIN_TEXT = `<!--#begin_code_headCodeComponentItems-->`,
    HEAD_CODE_END_TEXT = `<!--#end_code_headCodeComponentItems-->`,
    createCodeComponentInnerHTML = (id, code) => {
        return `<!--#begin code ${id}-->${code}<!--#end code ${id}-->`;
    },
    createHeadCodeComponentInnerHTML = (id, code) => {
        return HEAD_CODE_BEGIN_TEXT + createCodeComponentInnerHTML(id, code) + HEAD_CODE_END_TEXT;
    },
    codePreview = ({ component: { id, code, location, width }, renderOnlyCodeContainer }: Props) => {
        const props = {
            'data-width': width,
            'data-location': location
        };
        const htmlOption: any = {};
        if (location === CodeComponentLocationTypes.BeforeClosingHead) {
            if (!renderOnlyCodeContainer) {
                htmlOption.dangerouslySetInnerHTML = { __html: createHeadCodeComponentInnerHTML(id, code) };
            }
            return (
                <div
                    {...props}
                    className="code-component-container"
                    {...htmlOption}
                />
            );
        }

        if (!renderOnlyCodeContainer) {
            htmlOption.dangerouslySetInnerHTML = { __html: createCodeComponentInnerHTML(id, code) };
        }

        return (
            <div
                {...props}
                id={`code-component-${id}`}
                className={cx(styles.code, "code-component-container")}
                {...htmlOption}
            />
        );
    };

export {
    codePreview,
    codePreview as default,
    HEAD_CODE_BEGIN_TEXT,
    HEAD_CODE_END_TEXT
};
