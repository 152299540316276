import * as main from './pages/main';
import * as language from './pages/languages';
import makePages from "../../../../PropertiesPanel/view/makePages";

const propertiesPanel = {
    pages: makePages(main, language),
    defaultPageId: main.id,
};

export { propertiesPanel };
