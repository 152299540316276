import makeEpic from "../../../../epics/makeEpic";
import {
    OPEN_PAGE_TEMPLATE_SELECTOR_ACTION,
    LOAD_PAGE_TEMPLATES_SUCCESS_ACTION,
    DUPLICATE_PAGE_TEMPLATE_ACTION,
    DUPLICATE_PAGE_TEMPLATE_FAILURE_ACTION,
    DELETE_PAGE_TEMPLATE_ACTION,
    DELETE_PAGE_TEMPLATE_FAILURE_ACTION,
    CONFIRM_DELETE_PAGE_TEMPLATE_ACTION,
    loadPageTemplatesApiAction,
    openPageTemplateSelectorDialogAction,
    setPageTemplatesAction,
    duplicatePageTemplateApiAction,
    deletePageTemplateApiAction,
    openPageTemplateSelectorDeleteConfirmDialog
} from "../actions";
import { makePageTemplateSelectorErrorDialogUpdater } from "./updaters";
import { closeDialog } from "../../../App/actionCreators";

export const PAGE_TEMPLATE_SELECTOR_VAT = 'PAGE_TEMPLATE_SELECTOR_VAT';

export const pageTemplateSelectorEpic = makeEpic({
    defaultState: {
        deleteTemplateId: null
    },
    valueActionType: PAGE_TEMPLATE_SELECTOR_VAT,
    updaters: [
        {
            conditions: [OPEN_PAGE_TEMPLATE_SELECTOR_ACTION],
            reducer: ({ state, values: [props] }) => {
                const actions = [
                    openPageTemplateSelectorDialogAction(props)
                ];
                if (!props.templates || !props.templates.length) {
                    // @ts-ignore
                    actions.unshift(loadPageTemplatesApiAction());
                }

                return { state, multipleActionsToDispatch: actions };
            }
        },
        {
            conditions: [LOAD_PAGE_TEMPLATES_SUCCESS_ACTION],
            reducer: ({ state, values: [templates] }) => ({
                state,
                actionToDispatch: setPageTemplatesAction(templates)
            })
        },
        {
            conditions: [DUPLICATE_PAGE_TEMPLATE_ACTION],
            reducer: ({ state, values: [templateId] }) => ({
                state,
                actionToDispatch: duplicatePageTemplateApiAction(templateId)
            })
        },
        makePageTemplateSelectorErrorDialogUpdater(
            DUPLICATE_PAGE_TEMPLATE_FAILURE_ACTION,
            'msg: common.unableToDuplicateTemplate {Unable to duplicate template.}',
            'Template duplication failed'
        ),
        {
            conditions: [DELETE_PAGE_TEMPLATE_ACTION],
            reducer: ({ state, values: [templateId] }) => ({
                state: {
                    ...state,
                    deleteTemplateId: templateId
                },
                actionToDispatch: openPageTemplateSelectorDeleteConfirmDialog()
            })
        },
        {
            conditions: [CONFIRM_DELETE_PAGE_TEMPLATE_ACTION],
            reducer: ({ state: { deleteTemplateId }, state }) => ({
                state: {
                    ...state,
                    deleteTemplateId: null
                },
                multipleActionsToDispatch: [
                    deletePageTemplateApiAction(deleteTemplateId),
                    closeDialog()
                ]
            })
        },
        makePageTemplateSelectorErrorDialogUpdater(
            DELETE_PAGE_TEMPLATE_FAILURE_ACTION,
            'msg: common.unableToDeleteTemplate {Unable to delete template.}',
            'Template deletion failed'
        )
    ]
});
