import { getTrialAuth } from '../../../trial/services/trialAuth';
import { AwsAmplifyCognitoUser } from "../../../server/shared/awsServices/cognito/AwsAmplifyCognitoUser";
import { TrialUserCustomAttributes } from "../../../trial/lambda-functions/src/TrialUserCustomAttributes";

export const isWbtgenTrialOneComCpUser = ()/*: Promise<boolean> */ =>
    getTrialAuth().getUserInfo()
        .then(
            // $FlowFixMe: TODO
            user => !!AwsAmplifyCognitoUser({ attributes: {}, storage: {}, ...user })
                .attributes
                .getOptional(TrialUserCustomAttributes.IS_ONE_COM_CP_USER),
        );
