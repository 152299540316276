import * as R from 'ramda';
import { Style } from '@one.com/radium-one.com';
import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import templateValueActionType from '../../../oneweb/Template/epics/template/valueActionType';
import { receiveOnly, reset } from '../../../../epics/makeCondition';
import * as Actions from '../../../App/actionTypes';
import { valueActionType as siteDataValueActionType } from '../../../App/epics/siteData/valueActionType';
import currentPageIdValueActionType from '../../../App/epics/currentPageId/valueActionType';
import currentPageNameValueActionType from '../../../App/epics/currentPageName/valueActionType';
import styleSheetsValueActionType from '../../../Workspace/epics/stylesheets/valueActionType';
import { getDAL } from "../../../../../dal/index";
import { MobileViewOrientations } from "../../View/Mobile/constants";
import syncRelation from '../../../Workspace/epics/relations/syncRelationsWithComponentsMap';
import relationsValueActionType from '../../../Workspace/epics/relations/valueActionType';
import { ReceiveOnlyComponentsMap } from "../../../Workspace/epics/componentsEval/selectorActionTypes";
import { mapPageDataToComponentsMap } from "../../../../../dal/pageMapAdapter/index";
import * as styleMapper from '../../../oneweb/Template/epics/template/styleMapper';
import { to } from "../../../../../dal/pageMapAdapter/mappers/Template/index";
import TemplateKind from "../../../oneweb/Template/kind";
import * as globalStylesMapper from "../../../../../dal/pageMapAdapter/mappers/Globalstyles/index";
import * as PreviewActions from "../../actionTypes";
import * as ActionCreators from "../../actionCreators/index";
import fixBBoxes from "../../../Workspace/epics/componentsEval/fixBBoxes";
import { generateStylesheetsIdToNameMap } from "../../../Workspace/epics/stylesheets/idToNameMap";
import componentsEvalValueActionType from "../../../Workspace/epics/componentsEval/valueActionType";
import { DataPageSet } from "../../../../../dal/model/index";
import type { Preview } from "../../../../redux/modules/flowTypes";
import type { AnyComponent } from '../../../oneweb/flowTypes';
import mobileViewEditorValueActionType from '../../../MobileViewEditor/epics/reorder/valueActionType';
import getPreviewData from "../../getPreviewData";
import { toggleMobileOptionAC } from "../../View/actionCreators";
import { openDialog } from "../../../App/actionCreators/index";
import { DisableMobileOptionDialogId } from "../../View/dialog/dialogIds";
import getPageAndTemplateMobileData from "../../../MobileViewEditor/getPageAndTemplateMobileData";
import { stylesShadowRendererReducer, webshopButtonStyleIdSelector } from "../../../StylesShadowRenderer/epics/index";
import * as stylesheetSelectors from "../../../Workspace/epics/stylesheets/selectors";
import buttonGlobalStyleKind from "../../../oneweb/Button/globalStyle/kind";
import { getAllFonts } from "../../getAllFonts";
import { SiteSettingsSelector } from "../../../App/epics/siteSettings/selectorActionTypes";
import defaultHeaderFooterHeightsVAT from "../../../Workspace/epics/defaultHeaderFooterHeights/valueActionType";
import { componentAttachmentsVAT } from "../../../Workspace/epics/componentAttachements/valueActionType";
import { removeEmptySpacesBetweenSections } from "../../../oneweb/Section/utils";
import {
    shareHeaderAndFirstSectionBgImgEpic
} from '../../../oneweb/Section/epics/shareHeaderAndFirstSectionBgImgEpic';
import { ReceiveOnlyHFCmpsBeforeMHF } from '../../../ModernLayouts/epics/oldData/selectorActionTypes';
import webShopMHFValueActionType from "../../../oneweb/WebShopMHF/epic/valueActionType";

const MobileViewDefaultState = {
    show: false,
    orientation: MobileViewOrientations.PORTRAIT
};

const getAllTemplateCmpsBeforeMHF = (oldCmpsData: Record<string, any> = {}) => {
    const { header = {}, footer = {} } = oldCmpsData;
    return [header, footer].reduce((result, { cmpsMap = {} }) => {
        return {
            ...result,
            ...Object.values(cmpsMap).reduce((acc: Record<string, any>, cmp: AnyComponent) => {
                if (!cmp.inTemplate) { return acc; }
                return { ...acc, [cmp.id]: cmp };
            }, {})
        };
    }, {});
};

export const defaultPreviewState: Preview = {
    hiddenRender: false,
    show: false,
    mobileView: MobileViewDefaultState,
    previewHTML: '',
    data: null,
    siteMap: null,
    siteSettings: null,
    mobileData: null,
    currentPageId: null,
    template: null,
    fonts: [],
    globalStyles: {},
    stylesheetsIdToNameMap: {},
    componentsDependencies: {},
    componentsMap: {},
};

export default makeEpic({
    defaultState: defaultPreviewState,
    valueActionType,
    updaters: [
        {
            conditions: [templateValueActionType],
            reducer: ({ values: [template], state }) => ({ state: { ...state, template } })
        },
        {
            conditions: [
                receiveOnly(componentsEvalValueActionType),
                receiveOnly(currentPageIdValueActionType),
                receiveOnly(currentPageNameValueActionType),
                receiveOnly(siteDataValueActionType),
                receiveOnly(SiteSettingsSelector),
                ReceiveOnlyComponentsMap,
                receiveOnly(defaultHeaderFooterHeightsVAT),
                receiveOnly(templateValueActionType),
                receiveOnly(styleSheetsValueActionType),
                receiveOnly(relationsValueActionType),
                receiveOnly(mobileViewEditorValueActionType),
                receiveOnly(componentAttachmentsVAT),
                receiveOnly(shareHeaderAndFirstSectionBgImgEpic.valueActionType),
                receiveOnly(webShopMHFValueActionType),
                Actions.MOUSE_DOWN_ON_PREVIEW_BUTTON
            ],
            reducer: ({
                values: [
                    { scope: { componentsDependencies } },
                    currentPageId,
                    currentPageName,
                    siteMap,
                    siteSettings,
                    componentsMap,
                    { isHeader: isDefaultHeaderHeightSet, isFooter: isDefaultFooterHeightSet },
                    template,
                    styleSheet,
                    { changes },
                    { relations, groups, styles, show, settings },
                    { attachments },
                    shareHeaderAndFirstSectionBgImg,
                    webshopMHFData,
                ],
                state
            }) => {
                let newComponentsMap = R.pipe(
                    removeEmptySpacesBetweenSections(attachments),
                    cm => syncRelation(changes, cm, template, currentPageId)
                )(componentsMap);
                const previewData = getPreviewData({
                    componentsMap: newComponentsMap,
                    template,
                    isForWorkspace: true,
                    mobileData: { relations, groups, styles, settings },
                    defaultHeights: { isDefaultHeaderHeightSet, isDefaultFooterHeightSet },
                    webshopMHFData
                });
                return {
                    state: {
                        hiddenRender: true,
                        show: false,
                        mobileView: {
                            ...state.mobileView,
                            show
                        },
                        ...previewData,
                        shareHeaderAndFirstSectionBgImg,
                        previewHTML: '',
                        siteMap,
                        siteSettings,
                        currentPageId,
                        currentPageName,
                        domain: getDAL().getDomain(),
                        globalStyles: { stylesheets: styleSheet },
                        stylesheetsIdToNameMap: generateStylesheetsIdToNameMap(styleSheet),
                        componentsDependencies,
                        fonts: getAllFonts(previewData.componentsMap, styleSheet, siteSettings)
                    }
                };
            }
        },
        {
            conditions: [
                PreviewActions.SET_PREVIEW_PAGE_TO_LOAD
            ],
            reducer: ({ values: [pageId], state }) => ({
                state: {
                    ...state
                },
                actionToDispatch: ActionCreators.getPreviewPageDataSet(pageId)
            })
        },
        {
            conditions: [
                receiveOnly(componentsEvalValueActionType),
                receiveOnly(currentPageIdValueActionType),
                receiveOnly(templateValueActionType),
                receiveOnly(styleSheetsValueActionType),
                receiveOnly(siteDataValueActionType),
                ReceiveOnlyComponentsMap,
                ReceiveOnlyHFCmpsBeforeMHF,
                receiveOnly(SiteSettingsSelector),
                receiveOnly(shareHeaderAndFirstSectionBgImgEpic.valueActionType),
                receiveOnly(webShopMHFValueActionType),
                reset(PreviewActions.PREVIEW_PAGE_DATA_LOAD_SUCCESS)
            ],
            reducer: ({ values: [
                { scope: { componentsDependencies } },
                currentPageId,
                currentTemplate,
                currentStyleSheet,
                site,
                currentComponentsMap,
                oldMHFCmpsData,
                siteSettings,
                workspacePageShareHeaderAndFirstSectionBgImg,
                webshopMHFData,
                pageDataSet,
            ], state }) => {
                const
                    pageId = pageDataSet.page.id,
                    pageName = pageDataSet.page.name,
                    sameTemplate = currentTemplate.id === pageDataSet.template.id,
                    allOldTemplateCmpsBeforeMHF = getAllTemplateCmpsBeforeMHF(oldMHFCmpsData);

                let shareHeaderAndFirstSectionBgImg = {
                    enabledBeforeModernLayout: pageDataSet.page.shareHeaderAndFirstSectionBgImg,
                    enabledInModernLayout: pageDataSet.page.shareModernHeaderAndFirstSectionBgImg,
                    offsetTop: pageDataSet.page.shareBgImgOffsetTop
                };

                let styleSheet, template, finalComponentsMap;

                if (sameTemplate) {
                    template = currentTemplate;
                    styleSheet = currentStyleSheet;

                    if (pageId === currentPageId) {
                        finalComponentsMap = currentComponentsMap;
                        shareHeaderAndFirstSectionBgImg = workspacePageShareHeaderAndFirstSectionBgImg;
                    } else {
                        let
                            tmpPageDataSet = new DataPageSet({
                                template: {
                                    items: []
                                },
                                page: pageDataSet.page,
                                stylesheet: pageDataSet.stylesheet
                            }),
                            componentsMap = mapPageDataToComponentsMap(tmpPageDataSet, site, false, false);

                        Object.values({ ...allOldTemplateCmpsBeforeMHF, ...currentComponentsMap })
                            .forEach((component: AnyComponent) => {
                                const { id: cmpId } = component;
                                if (component.inTemplate) {
                                    componentsMap[cmpId] = component;
                                }
                            });

                        finalComponentsMap = fixBBoxes(componentsMap, template);
                    }
                } else {
                    styleSheet = globalStylesMapper.to(pageDataSet.stylesheet);
                    template = styleMapper.to({
                        kind: TemplateKind,
                        ...to(pageDataSet.template)
                    }, pageDataSet.stylesheet);
                    finalComponentsMap =
                        fixBBoxes(mapPageDataToComponentsMap(pageDataSet, site, false, false), template);
                }
                const
                    isWorkspace = pageId === currentPageId,
                    mobileData = getPageAndTemplateMobileData(
                        pageDataSet.page.mobileData, pageDataSet.template.mobileData
                    );
                const webshopButtonStyleId = webshopButtonStyleIdSelector(finalComponentsMap) ||
                    stylesheetSelectors.getStyleId(
                        stylesheetSelectors.getFirstStylesheetByType(buttonGlobalStyleKind)(styleSheet)
                    );
                let buttonStyle = '';
                if (webshopButtonStyleId) {
                    const webshopStyles = stylesShadowRendererReducer({
                        values: [styleSheet, webshopButtonStyleId],
                        state: { styleProp: {}, styleId: '' }
                    });
                    const StyleInstance = new Style({ props: { ...webshopStyles } });
                    buttonStyle = StyleInstance._buildStyles(webshopStyles.state.styleProp);
                }

                const stylesheetsIdToNameMap = generateStylesheetsIdToNameMap(styleSheet),
                    previewData = getPreviewData({
                        componentsMap: finalComponentsMap,
                        template,
                        isForWorkspace: isWorkspace,
                        mobileData,
                        webshopMHFData
                    });
                return {
                    state: {
                        ...state,
                        ...previewData,
                        previewHTML: '',
                        currentPageId: pageId,
                        currentPageName: pageName,
                        shareHeaderAndFirstSectionBgImg,
                        globalStyles: { stylesheets: styleSheet },
                        stylesheetsIdToNameMap,
                        componentsDependencies: {
                            ...componentsDependencies,
                            WEBSHOP: {
                                ...(componentsDependencies.WEBSHOP || {}),
                                buttonStyle,
                                stylesheetsIdToNameMap
                            }
                        },
                        fonts: getAllFonts(previewData.componentsMap, styleSheet, siteSettings)
                    }
                };
            }
        },
        {
            conditions: [Actions.MOUSE_UP_ON_PREVIEW_BUTTON],
            reducer: ({ state }) => {
                if (!state.hiddenRender) {
                    return { state }; // hidden render should have started as we start computations on mouse down
                }

                return {
                    state: { ...state, show: true }
                };
            }
        },
        {
            conditions: [Actions.BACK_TO_EDITOR],
            reducer: ({ state }) => ({
                state: {
                    ...state,
                    hiddenRender: false,
                    show: false,
                    data: null
                }
            })
        },
        {
            conditions: [Actions.MOBILE_PREVIEW],
            reducer: ({ state }) => ({
                state: {
                    ...state,
                    mobileView: { ...state.mobileView, show: true }
                }
            })
        },
        {
            conditions: [Actions.DESKTOP_PREVIEW],
            reducer: ({ state }) => ({
                state: {
                    ...state,
                    mobileView: { ...state.mobileView, show: false }
                }
            })
        },
        {
            conditions: [Actions.PREVIEW_LOADED],
            reducer: ({ values: [HTML], state }) => ({
                state: {
                    ...state,
                    previewHTML: HTML
                }
            })
        },
        {
            conditions: [Actions.MOBILE_PREVIEW_ORIENTATION_TOGGLE],
            reducer: ({ state }) => ({
                state: {
                    ...state,
                    mobileView: {
                        ...state.mobileView,
                        orientation: state.mobileView.orientation === MobileViewOrientations.PORTRAIT ?
                            MobileViewOrientations.LANDSCAPE : MobileViewOrientations.PORTRAIT
                    }
                }
            })
        },
        {
            conditions: [
                receiveOnly(siteDataValueActionType),
                PreviewActions.PREVIEW_TOGGLE_MOBILE_VERSION_CLICKED,
            ],
            reducer: ({ values: [{ activateMobileView }], state }) => {
                const actionType = activateMobileView ?
                    PreviewActions.TURN_OFF_MOBILE_WARNING_SHOWN :
                    PreviewActions.ENABLE_MOBILE_VERSION;
                return {
                    state,
                    actionToDispatch: { type: actionType }
                };
            }
        },
        {
            conditions: [PreviewActions.DISABLE_MOBILE_VERSION],
            reducer: ({ state }) => ({
                state,
                actionToDispatch: toggleMobileOptionAC()
            })
        },
        {
            conditions: [PreviewActions.ENABLE_MOBILE_VERSION],
            reducer: ({ state }) => ({
                state,
                actionToDispatch: toggleMobileOptionAC()
            })
        },
        {
            conditions: [PreviewActions.TURN_OFF_MOBILE_WARNING_SHOWN],
            reducer: ({ state }) => ({
                state,
                actionToDispatch: openDialog(DisableMobileOptionDialogId)
            })
        }
    ]
});
