import type { AppStore } from "../../redux/modules/flowTypes";
import { dialogId } from "../../view/common/dialogs/ImageEditorDialog/index";
import openDialogAC from "../App/actionCreators/openDialog";
import { currentLanguageAppSel } from "../TopBar/epics/languages/selectors";
import {
    IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_ACTIONS,
    IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_FAILURE,
    IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_SUCCESS,
    IMAGE_EDITOR_SERIALIZED_SAVE_CLOSE_EDITOR,
    IMAGE_EDITOR_SHOW_REPLACED_IMAGE_MSG,
    OPEN_IMAGE_EDITOR_DIALOG,
    SAVE_EDITED_IMAGE
} from "./actionTypes";
import { AssetFileInfo, getAssetFileInfo } from "../../utils/assetUtils";
import { MessageCodes } from "./Messages";
import { updateImageEditorMessage } from "./actionCreators";
import loadResources from "../App/actionCreators/loadResources";
import { fcCreateWebSpaceFolder } from "../../redux/modules/children/fileChooser/actionCreators";
import { FC_ONEWEBMEDIA_PATH } from "../../redux/modules/children/fileChooser/constants";
import {
    FC_COMPUTER_UPLOAD_FAILURE,
    FC_COMPUTER_UPLOAD_SUCCESS,
    FC_CREATE_WEBSPACE_FOLDER_FAILURE,
    FC_CREATE_WEBSPACE_FOLDER_SUCCESS,
    FC_RESOURCE_METADATA_FAILURE,
    FC_RESOURCE_METADATA_SUCCESS
} from "../../redux/modules/children/fileChooser/actionTypes";
import { editedImageNameSuffix } from "./constants";
import { decodeWebspaceUri } from "../../../dal/utils/webspaceUriTransformers";
import getResourceMetadata from "../../redux/modules/children/fileChooser/actionCreators/getResourceMetadata";
import uploadFromComputerApiAction from "../../redux/modules/children/fileChooser/actionCreators/uploadFromComputerApiAction";
import { SELECTED_COMPONENT } from "../../redux/forwardTo";
import { blobToFile, getDisplayFileName, getNewFileName, getResourceMetadataFormatted } from "./utils";
import { getConvertedLanguageKey } from "../../../../server/shared/locale/utils";

let imageEditorData: any = {};
let retryCount = 0;

export const imageEditorMiddleware = (store: AppStore) => (next: Dispatch) => (action: AnyAction) => {
    if (action.type === OPEN_IMAGE_EDITOR_DIALOG) {
        const appState = store.getState();
        const currLang = currentLanguageAppSel(appState);
        const language = getConvertedLanguageKey(currLang);

        store.dispatch(openDialogAC(dialogId, { ...action.payload, language }));
    }

    if (action.type === SAVE_EDITED_IMAGE) {
        const {
            asset,
            blob,
            replaceWithEditedImageAction,
            extraPayload,
            fileNameSuffix = editedImageNameSuffix,
            forwardToSelectedComponent = true
        } = action.payload;

        retryCount = 0; // Reset retry count

        if (!replaceWithEditedImageAction) {
            throw new Error("No action for dispatching on edited image");
        }

        const fileInfo: AssetFileInfo = getAssetFileInfo(asset);

        imageEditorData = {
            ...imageEditorData,
            fileInfo,
            asset,
            blob,
            extraPayload,
            forwardToSelectedComponent,
            fileNameSuffix,
            replaceWithEditedImageAction
        };

        store.dispatch(loadResources(fileInfo.dir, true, IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_ACTIONS));
    }

    if (imageEditorData.fileInfo && action.type === IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_SUCCESS) {
        if (action.payload.entries) {
            const { entries } = action.payload;
            const { blob, fileInfo, fileNameSuffix, forwardToSelectedComponent } = imageEditorData;

            const newImageFilePath = getNewFileName(entries, fileInfo, fileNameSuffix);
            const newFileName = newImageFilePath.substring(newImageFilePath.lastIndexOf("/") + 1);

            imageEditorData.newImageFilePath = newImageFilePath;
            imageEditorData.displayFileName = getDisplayFileName(decodeWebspaceUri(newFileName), fileInfo.extn, fileNameSuffix);

            if (!forwardToSelectedComponent) {
                store.dispatch(updateImageEditorMessage(MessageCodes.SAVING_FILE_AS, newFileName));
            }

            const file = blobToFile(blob, newFileName);
            // try to upload new file into webspace
            // @ts-ignore
            store.dispatch(uploadFromComputerApiAction(file, newImageFilePath));
        } else {
            store.dispatch(updateImageEditorMessage(MessageCodes.GENERAL_ERROR));
        }
    }

    if (imageEditorData.fileInfo && action.type === IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_FAILURE) {
        if (retryCount < 1) {
            // Dispatch the folder creation action after failure
            store.dispatch(fcCreateWebSpaceFolder(FC_ONEWEBMEDIA_PATH));
            retryCount++; // Update retry count in the middleware state
        } else {
            store.dispatch(updateImageEditorMessage(MessageCodes.GENERAL_ERROR));
        }
    }

    if (imageEditorData.fileInfo && action.type === FC_CREATE_WEBSPACE_FOLDER_SUCCESS) {
        const success = action.payload;
        if (success) {
            store.dispatch(loadResources(imageEditorData.fileInfo.dir, true, IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_ACTIONS));
        }
    }

    if (imageEditorData.fileInfo && action.type === FC_CREATE_WEBSPACE_FOLDER_FAILURE) {
        throw new Error(FC_CREATE_WEBSPACE_FOLDER_FAILURE);
    }

    if (imageEditorData.fileInfo && action.type === FC_COMPUTER_UPLOAD_SUCCESS) {
        const imageUploadSuccess = action.payload;
        const { newImageFilePath, displayFileName, forwardToSelectedComponent } = imageEditorData;
        if (imageUploadSuccess && !forwardToSelectedComponent) {
            store.dispatch({ type: IMAGE_EDITOR_SHOW_REPLACED_IMAGE_MSG, payload: displayFileName });
            return next(action);
        }
        if (imageUploadSuccess) {
            store.dispatch(getResourceMetadata(newImageFilePath));
        } else {
            store.dispatch(updateImageEditorMessage(MessageCodes.GENERAL_ERROR));
        }
    }

    if (imageEditorData.fileInfo && action.type === FC_COMPUTER_UPLOAD_FAILURE) {
        store.dispatch(updateImageEditorMessage(MessageCodes.GENERAL_ERROR));
    }

    if (
        action.endpointParams === imageEditorData.newImageFilePath &&
        action.type === FC_RESOURCE_METADATA_SUCCESS
    ) {
        const response = action.payload;
        if (!response) store.dispatch(updateImageEditorMessage(MessageCodes.GENERAL_ERROR));

        const metadata = getResourceMetadataFormatted(response);
        if (metadata) {
            const { asset, newImageFilePath, extraPayload, replaceWithEditedImageAction } = imageEditorData;

            store.dispatch({
                type: replaceWithEditedImageAction,
                payload: {
                    asset: {
                        ...metadata,
                        url: "webspace:" + newImageFilePath,
                        data: asset.data
                    },
                    ...(extraPayload || {})
                },
                forwardTo: {
                    kind: SELECTED_COMPONENT
                },
                fromMVE: false
            });
            store.dispatch({ type: IMAGE_EDITOR_SERIALIZED_SAVE_CLOSE_EDITOR });
        } else {
            store.dispatch(updateImageEditorMessage(MessageCodes.GENERAL_ERROR)); // todo SAVE ERROR
        }
    }

    if (
        action.endpointParams === imageEditorData.newImageFilePath &&
        action.type === FC_RESOURCE_METADATA_FAILURE
    ) {
        store.dispatch(updateImageEditorMessage(MessageCodes.GENERAL_ERROR));
    }

    return next(action);
};
