import { IntlShape } from "react-intl";
import type { Intl } from "../view/intl/injectIntl";

export const
    TODAY = 'TODAY',
    YESTERDAY = 'YESTERDAY';

const
    daysLabels = {
        [TODAY]: 'msg: web.Today {Today}',
        [YESTERDAY]: 'msg: web.Yesterday {Yesterday}'
    },
    ydate = new Date(),
    tdate = new Date();
ydate.setDate(ydate.getDate() - 1);
export const yesterdayTS = ydate.setHours(0, 0, 0, 0),
    todayTS = tdate.setHours(0, 0, 0, 0);

export const
    dateToRelativeDays = (string: string, intl: Intl) => {
        if (daysLabels[string]) {
            return intl.msgJoint(daysLabels[string]);
        }
        return string;
    },
    timestampToDate = (timestamp: number, intl: Intl & IntlShape): string => {
        if (timestamp >= todayTS) {
            return TODAY;
        }
        if (timestamp >= yesterdayTS) {
            return YESTERDAY;
        }

        return intl.formatDate(timestamp, {
            month: 'short',
            day: '2-digit',
            year: 'numeric'
        });
    },
    timestampToDateTime = (timestamp: number, intl: Intl & IntlShape): string => {
        const
            date = timestampToDate(timestamp, intl),
            dateDays = dateToRelativeDays(date, intl);
        if (daysLabels[date]) {
            return dateDays + ' ' + intl.time(timestamp);
        }

        const string = intl.formatDate(timestamp, {
            month: 'short',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
        let count = 0;
        return string.replace(/,/g, (match) => {
            count++;
            if (count === 1) {
                return match;
            }
            return ' -';
        });
    };

const MICROSECONDS_PER_DAY = 1000 * 60 * 60 * 24;

export const dateDiffInDays = (firstDate: Date, secondDate: Date) => {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate());
    const utc2 = Date.UTC(secondDate.getFullYear(), secondDate.getMonth(), secondDate.getDate());

    return Math.floor((utc2 - utc1) / MICROSECONDS_PER_DAY);
};
