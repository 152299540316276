import CALL_API from "../../../redux/middleware/api/CALL_API";
import * as Actions from "../actionTypes";
import type { ApiAction } from "../../../redux/middleware/api/flowTypes";

export const
    getPreviewPageDataSet = (pageId: string): ApiAction => ({
        [CALL_API]: {
            types: [
                Actions.PREVIEW_PAGE_DATA_LOAD_REQUEST,
                Actions.PREVIEW_PAGE_DATA_LOAD_SUCCESS,
                Actions.PREVIEW_PAGE_DATA_LOAD_FAILURE
            ],
            endpoint: 'getPageDataSet',
            endpointParams: pageId
        }
    });
