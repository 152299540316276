import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import popupVAT from "../popup/valueActionType";
import undoRedoButtonsVAT from "../undoRedoButtons/valueActionType";
import { valueActionType as siteDataVAT } from '../../../App/epics/siteData/valueActionType';
import subscriptionDataVAT from '../../../App/epics/subscriptionData/valueActionType';
import currentPageIdVAT from '../../../App/epics/currentPageId/valueActionType';
import currentPageNameVAT from '../../../App/epics/currentPageName/valueActionType';
import treeStateVAT from "../treeState/valueActionType";
import { workspaceSaveStatusValueActionType } from '../../../Workspace/epics/saveStatus/valueActionType';
import checkPublishStatusVAT from "../checkPublishStatus/valueActionType";
import upgradeStatusDataVAT from "../upgrade/valueActionType";
import checkPreviewStatusDataVAT from '../../../Preview/epics/preview/valueActionType';
import chatLoadedVAT from "../chat/valueActionType";
import topBarLanguagesVAT from '../languages/valueActionType';
import quickTourVAT from '../../../QuickTour/epics/quickTour/valueActionType';
import mobileEditorVAT from '../../../MobileViewEditor/epics/reorder/valueActionType';
import {
    closeMobileViewEditorClicked,
    openMobileViewEditorClicked
} from '../../../MobileViewEditor/actionCreators';
import { optional, receiveOnly } from "../../../../epics/makeCondition";
import { START_QUICK_TOUR } from "../../../QuickTour/actionTypes";
import {
    CTRL_J_PRESSED,
    CTRL_P_PRESSED,
    MOUSE_DOWN_ON_PREVIEW_BUTTON,
    MOUSE_UP_ON_PREVIEW_BUTTON,
    BACK_TO_EDITOR
} from "../../../App/actionTypes";
import { isBackupIntroDialog, isAnyDialogOnFocus } from "../../../App/epics/userFocus/index";
import { UserFocusValueActionType } from "../../../App/epics/userFocus/valueActionType";
import { mobilePreviewAction, desktopPreviewAction } from '../../../Preview/View/actionCreators';
import { openTopBarPopup } from '../../actionCreators/index';
import { Popups } from '../../constants';
import { TOP_BAR_HELP_PRESSED, TOPBAR_ON_MENU_CLICKED } from '../../actionTypes';
import { globalVariablesEpic } from '../../../App/epics/globalVariablesEpic';
import TEMPLATE_LISTING_VALUE_ACTION_TYPE from "../../../TemplateSelector_DEPRECATED/epics/templatesList/valueActionType";
import TEMPLATE_PREVIEW_ACTION_TYPE from "../../../TemplateSelector_DEPRECATED/epics/preview/valueActionType";
import supportToolTopBarUpdaters from '../../Support/supportToolTopBarUpdaters';
import componentTierManagerVAT from "../../../ComponentTierManager/epics/valueActionType";
import { HIDE_WELCOME_MESSAGE } from '../../../WelcomeMessage/actionTypes';
import { ROLastPublished } from '../checkPublishStatus/selectorActionTypes';
import { shouldShowTutorial } from '../../view/TopBarItemMenu/TopBarTutorial';

export default makeEpic({
    defaultState: null,
    valueActionType,
    updaters: [
        {
            conditions: [
                globalVariablesEpic.valueActionType,
                popupVAT,
                undoRedoButtonsVAT,
                siteDataVAT,
                optional(currentPageIdVAT),
                optional(currentPageNameVAT),
                workspaceSaveStatusValueActionType,
                treeStateVAT,
                checkPublishStatusVAT,
                subscriptionDataVAT,
                upgradeStatusDataVAT,
                checkPreviewStatusDataVAT,
                chatLoadedVAT,
                topBarLanguagesVAT,
                quickTourVAT,
                mobileEditorVAT,
                componentTierManagerVAT
            ],
            reducer: ({
                values: [
                    globalVariables,
                    popup,
                    undoRedoButtons,
                    site,
                    currentPageId,
                    currentPageName,
                    saveStatus,
                    treeState,
                    publishStatus,
                    subscriptionData,
                    upgradeStatus,
                    previewStatus,
                    chatStatus,
                    languages,
                    {
                        helpBtnGetClientRect,
                        pagesTreeContainerGetClientRect,
                        saveBtnGetClientRect,
                        tutorialBtnGetClientRect,
                    },
                    { show },
                    componentTierManagerState
                ],
                state
            }) => ({
                state: {
                    ...state,
                    popup,
                    undoRedoButtons,
                    pagesTree: {
                        globalVariables,
                        site,
                        currentPageId,
                        currentPageName,
                        saveStatus,
                        treeState
                    },
                    publishStatus,
                    subscriptionData,
                    upgradeStatus,
                    previewStatus,
                    chatStatus,
                    languages,
                    helpBtnGetClientRect,
                    pagesTreeContainerGetClientRect,
                    saveBtnGetClientRect,
                    tutorialBtnGetClientRect,
                    showMobileEditor: show,
                    componentTierManagerState
                }
            })
        },
        {
            conditions: [
                receiveOnly(UserFocusValueActionType),
                receiveOnly(TEMPLATE_LISTING_VALUE_ACTION_TYPE),
                receiveOnly(TEMPLATE_PREVIEW_ACTION_TYPE),
                CTRL_J_PRESSED
            ],
            reducer: ({
                values: [
                    userFocus,
                    { show: templateListing },
                    { show: templatePreview }
                ],
                state
            }) => {
                const notAllowed = isAnyDialogOnFocus(userFocus) || isBackupIntroDialog(userFocus)
                    || templateListing || templatePreview;

                if (notAllowed) {
                    return { state };
                } else {
                    const { previewStatus } = state;
                    let actionToDispatch: Action | null = null;

                    if (previewStatus.show) {
                        actionToDispatch = previewStatus.mobileView.show ? desktopPreviewAction() :
                            mobilePreviewAction();
                    } else {
                        actionToDispatch = state.showMobileEditor ?
                            closeMobileViewEditorClicked() : openMobileViewEditorClicked();
                    }
                    return ({
                        state,
                        actionToDispatch
                    });
                }
            }
        },
        {
            conditions: [
                receiveOnly(UserFocusValueActionType),
                receiveOnly(TEMPLATE_LISTING_VALUE_ACTION_TYPE),
                receiveOnly(TEMPLATE_PREVIEW_ACTION_TYPE),
                CTRL_P_PRESSED
            ],
            reducer: ({ values: [
                userFocus,
                { show: templateListing },
                { show: templatePreview }
            ], state }) => {
                const notAllowed = isAnyDialogOnFocus(userFocus) || isBackupIntroDialog(userFocus)
                    || templateListing || templatePreview;

                let actionsToDispatch: Array<Action> = [];
                if (notAllowed) {
                    return { state };
                } else {
                    const { previewStatus } = state;

                    if (previewStatus.show) {
                        actionsToDispatch.push({ type: BACK_TO_EDITOR });
                        actionsToDispatch.push(previewStatus.mobileView.show ? openMobileViewEditorClicked() :
                            closeMobileViewEditorClicked());
                    } else {
                        actionsToDispatch.push({ type: MOUSE_DOWN_ON_PREVIEW_BUTTON });
                        actionsToDispatch.push({ type: MOUSE_UP_ON_PREVIEW_BUTTON });
                        if (state.showMobileEditor) {
                            actionsToDispatch.push(mobilePreviewAction());
                        } else {
                            actionsToDispatch.push(desktopPreviewAction());
                        }
                    }
                }
                return ({
                    state,
                    multipleActionsToDispatch: actionsToDispatch
                });
            }
        },
        {
            conditions: [START_QUICK_TOUR],
            reducer: ({ state }) => ({
                state,
                actionToDispatch: closeMobileViewEditorClicked()
            })
        },
        {
            conditions: [TOP_BAR_HELP_PRESSED],
            reducer: ({ state }) => ({
                state,
                actionToDispatch: openTopBarPopup({ id: Popups.HELP })
            })
        },
        {
            conditions: [TOPBAR_ON_MENU_CLICKED],
            reducer: ({ state }) => ({
                state,
                actionToDispatch: openTopBarPopup({ id: Popups.USER_PROFILE })
            })
        },
        {
            conditions: [ROLastPublished, HIDE_WELCOME_MESSAGE],
            reducer: ({ state, values: [lastPublished] }) => {
                let actionToDispatch;
                if (!lastPublished && shouldShowTutorial(state.languages.current)) {
                    actionToDispatch = openTopBarPopup({ id: Popups.TUTORIAL_VIDEO_INTRO });
                }
                return ({
                    state,
                    actionToDispatch
                });
            }
        },
        ...supportToolTopBarUpdaters
    ]
});
