import { connect } from "react-redux";
import SiteSettingsDialog from './view/index';
import getCenteredDialogConfig from "../../DialogManager/getCenteredDialogConfig";
import { getCenterPositionForDialog } from "../../DialogManager/utility";
import { makeEpicStateSelector } from "../../../epics/makeEpic";
import subscriptionDataEpicVat from "../../App/epics/subscriptionData/valueActionType";

const mapStateToProps = appState => {
    const { subscriptionType } = makeEpicStateSelector(subscriptionDataEpicVat)(appState),
        props = {
            subscriptionType,
        };
    return props;
};

const
    getDialogDimensions = (browserHeight) => ({
        // TODO: Find out a good UI design when the width and height is more
        width: 1218,
        height: Math.min(Math.max(browserHeight - 100, 475), 950)
    }),
    siteSettingsDialog = {
        ...getCenteredDialogConfig(900, 600),
        confFactory: ({ browserWidth, browserHeight }) => {
            const { width, height } = getDialogDimensions(browserHeight);
            return {
                position: getCenterPositionForDialog(width, height, browserWidth, browserHeight),
                modal: true,
                dimensions: { width, height }
            };
        },
        moveToCenter: true,
        component: connect(mapStateToProps)(SiteSettingsDialog)
    };

export {
    siteSettingsDialog
};

