// @ts-nocheck
import { getComponentsBBox } from './getComponentsBBox';
import getBBoxesIntersection from './getBBoxesIntersection';
import type { AnyComponent, BBox } from "../../redux/modules/children/workspace/flowTypes";

export default function getComponentsIntersection(
    component1: AnyComponent,
    component2: AnyComponent,
    workspaceBBox
): BBox {
    const
        bbox1: BBox = getComponentsBBox([component1], workspaceBBox),
        bbox2: BBox = getComponentsBBox([component2], workspaceBBox);

    return getBBoxesIntersection(bbox1, bbox2);
}
