
import React from 'react';
import cx from 'classnames';
import { Msg } from "../../../../../../view/intl/index";
import styles from './UpgradePlansDialogImages.css';
import { isEcommerceSubscriptionTypeEnabled } from "../../../../../ComponentTierManager/utils";
import { RenderPlanSellOnlineDescription } from "./RenderPlanSellOnlineDescription";

const RenderStandardPaymentPlanImages = () => {
    const isEcommerceSubscriptionEnabled = isEcommerceSubscriptionTypeEnabled();
    return (
        <div className={styles.subContainerStandard}>
            <div className={cx(styles.stdPaymentPlanPublish, styles.imageCommonStyles)} />
            <div className={cx(styles.stdPaymentPlanSocialMedia, styles.imageCommonStyles)} />
            {
                isEcommerceSubscriptionEnabled ?
                    <div className={cx(styles.paymentPlanShop, styles.imageCommonStyles)} />
                    :
                    <div className={cx(styles.freeStockImages, styles.imageCommonStyles)} />
            }
            <Msg className={cx(styles.txtStyle, styles.imageHeaderTxtStyle)} k="upgrade.plans.dialog.std.img1.header">
                More pages
            </Msg>
            <Msg className={cx(styles.txtStyle, styles.imageHeaderTxtStyle)} k="upgrade.plans.dialog.std.widgets.header">
                Widgets
            </Msg>
            {
                isEcommerceSubscriptionEnabled ?
                    <Msg className={cx(styles.txtStyle, styles.imageHeaderTxtStyle)} k="upgrade.plans.dialog.std.img3.header">
                        Sell online
                    </Msg> :
                    <Msg className={cx(styles.txtStyle, styles.imageHeaderTxtStyle)} k="upgrade.plans.dialog.std.stockimages.header">
                        Free stock images
                    </Msg>
            }
            <Msg className={cx(styles.txtStyle, styles.imageBodyTxtStyle)} k="upgrade.plans.dialog.std.morePages.body">
                Add more content to your site with unlimited published pages
            </Msg>
            <Msg className={cx(styles.txtStyle, styles.imageBodyTxtStyle)} k="upgrade.plans.dialog.std.widgets.body">
                Embed third-party media to engage with your visitors
            </Msg>
            {
                isEcommerceSubscriptionEnabled ?
                    <RenderPlanSellOnlineDescription /> :
                    <Msg className={cx(styles.txtStyle, styles.imageBodyTxtStyle)} k="upgrade.plans.dialog.std.stockimages.body">
                        Find the perfect look from thousands of images
                    </Msg>
            }
        </div>
    );
};

export default RenderStandardPaymentPlanImages;
