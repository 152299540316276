import { getDAL } from '../../../dal';

const onePageRegex = /-onepage$/;
const isOnePageDesign = (name) => onePageRegex.test(name);

const getHashCode = function (str = '') {
    let hash = 0;
    let char;
    if (str.length === 0) {
        return hash;
    }
    for (let i = 0; i < str.length; i++) {
        char = str.charCodeAt(i);
        // eslint-disable-next-line no-bitwise
        hash = ((hash << 5) - hash) + char;
        // eslint-disable-next-line no-bitwise
        hash = hash & hash; // Convert to 32bit integer
    }
    return Math.abs(hash);
};

const getDomainHashScore = () => {
    const domainName = getDAL().getDomain();
    return (getHashCode(domainName) % 1000);
};

const shouldShowEditPreview = () => true;
const showOnlyOnePageTemplates = () => {
    return getDomainHashScore() < 500;
};

export {
    onePageRegex,
    isOnePageDesign,
    getHashCode,
    shouldShowEditPreview,
    showOnlyOnePageTemplates
};
