import { Lit } from "../../../../lit";

export const PageTemplateSelectorPath = {
    templates: [Lit.templates],
    isLoading: [Lit.isLoading],
    onHold: [Lit.onHold],
    itemsOnHold: [Lit.itemsOnHold],
    selectedId: [Lit.selectedId],
    previewId: [Lit.previewId],
    isEqToInitialState: [Lit.isEqToInitialState]
};
