import React from 'react';
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";

import { AddressSettingsNameForm } from "./AddressSettingsNameForm";
import { AddressSettingsStreetAddressForm } from "./AddressSettingsStreetAddressForm";
import { AddressSettingsZipCodeForm } from "./AddressSettingsZipCodeForm";
import { AddressSettingsCityForm } from "./AddessSettingsCityForm";
import { AddressSettingsAreaForm } from "./AddressSettingsAreaForm";
import { AddressSettingsCountryCodeForm } from "./AddressSettingsCountryCodeForm";

import { selectAddressCountryCode } from "../../../../../common/selectors";

const { getCountryFormat } = require('../../../../../../../../server/shared/address/getCountryFormat.js');

const renderSpecificArea = (countryFormat) => {
    switch (countryFormat) {
        case 'CountryFormat1':
            return (
                <React.Fragment>
                    <Grid item xs={12} sm={6}>
                        <AddressSettingsZipCodeForm />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AddressSettingsCityForm />
                    </Grid>
                </React.Fragment>

            );

        case 'CountryFormat2':
            return (
                <React.Fragment>
                    <Grid item xs={12}>
                        <AddressSettingsCityForm />
                    </Grid>
                    <Grid item xs={12}>
                        <AddressSettingsZipCodeForm />
                    </Grid>
                </React.Fragment>
            );

        case 'CountryFormat3':
            return (
                <React.Fragment>
                    <Grid item xs={12} sm={4}>
                        <AddressSettingsCityForm />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <AddressSettingsAreaForm />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <AddressSettingsZipCodeForm />
                    </Grid>
                </React.Fragment>
            );

        case 'CountryFormat5':
            return (
                <React.Fragment>
                    <Grid item xs={12} sm={4}>
                        <AddressSettingsZipCodeForm />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <AddressSettingsCityForm />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <AddressSettingsAreaForm />
                    </Grid>
                </React.Fragment>
            );

        default:
            return (
                <React.Fragment>
                    <Grid item xs={12} sm={6}>
                        <AddressSettingsZipCodeForm />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <AddressSettingsCityForm />
                    </Grid>
                    <Grid item xs={12}>
                        <AddressSettingsAreaForm />
                    </Grid>
                </React.Fragment>
            );
    }
};

type Props = {
    omitAddressName?: boolean,
}

export const AddressSettingsForm = (props: Props) => {
    const { omitAddressName = false } = props;
    const countryFormat = getCountryFormat(useSelector(selectAddressCountryCode));

    return (
        <Grid container spacing={2} data-testid="address-settings">
            {
                omitAddressName ?
                    null :
                    <Grid item xs={12}>
                        <AddressSettingsNameForm />
                    </Grid>
            }

            <Grid item xs={12}>
                <AddressSettingsStreetAddressForm />
            </Grid>
            { renderSpecificArea(countryFormat) }
            <Grid item xs={12}>
                <AddressSettingsCountryCodeForm />
            </Grid>
        </Grid>
    );
};
