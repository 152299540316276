import type { ColorPicker } from "../flowTypes";
import defaultState from "../defaultState";

export default (state: ColorPicker): ColorPicker => ({
    ...state,
    ...defaultState,
    // @ts-ignore
    selectedParentTheme: null,
    backgroundTheme: null,
    backgroundColor: null,
    textColor: null,
    themeColor: null,
});
