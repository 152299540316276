import { handleUnderlineButtonClick } from "./helpers/handleToggledFormats";
import { makeToggleUpdater } from "./makeToggleUpdater";
import { TINY_MCE_TOGGLE_UNDERLINE } from "../actionTypes";

const onToggleUnderlineUpdater =
    makeToggleUpdater({
        triggerActionType: TINY_MCE_TOGGLE_UNDERLINE,
        propToToggle: 'underline',
        doWithEditor: handleUnderlineButtonClick
    });

export {
    onToggleUnderlineUpdater
};
