import React from "react";
import * as R from "ramda";
import IconButtonsGroup from './index';
import Icons from '../../../view/Icons/index';
import type { TextFormatButtonGroupProps } from "./flowTypes";

const
    textFormatButtons = {
        bold: {
            title: "msg: common.bold {Bold}",
            icon: Icons.BOLD
        },
        italic: {
            title: "msg: common.italic {Italic}",
            icon: Icons.ITALIC
        },
        underline: {
            title: "msg: common.underline {Underline}",
            icon: Icons.UNDERLINE
        }
    },
    getButtonProp = (key, prop) => ({
        ...textFormatButtons[key],
        ...prop
    });

const TextFormatButtonGroup = (props: TextFormatButtonGroupProps) => {
    const
        { dispatch } = props,
        buttonGroup = R.pipe(
            R.keys,
            R.map(key => (props[key] ? getButtonProp(key, props[key]) : undefined)),
            R.filter(obj => obj !== undefined)
        )(textFormatButtons);

    return (
        <IconButtonsGroup buttonGroup={buttonGroup} dispatch={dispatch} />
    );
};

export {
    TextFormatButtonGroup as default,
    TextFormatButtonGroup,
};
