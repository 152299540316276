import React from 'react';
import cx from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from "@mui/material";
import { StandardDialog } from "../../../Dialog/StandardDialog";
import {
    setSelectedDevice
} from "./actions";

import {
    replaceDialogAction,
    closeDialogAction
} from "../../../Dialog/actions";
import { QRCodeDialogId } from "./QRCodeDialog";

import imageStyles from "../../../Images/imageStyle.css";

const Styles = makeStyles(() => ({
    qrTextHeader: {
        fontSize: "30px",
        lineHeight: "34px",
        paddingBottom: "20px",
        textAlign: "center",
    },
    iconWrapper: {
        paddingBottom: "20px",
    },
    image: {
        height: "193px",
        width: "193px",
        margin: "auto",
    },
    qrTextDescription: {
        fontSize: "18px",
        textAlign: "center",
    },
    qrCodeContainer: {
        marginLeft: "60px",
    },
}), { name: "DeviceSelectionDialog" });

const getButtons = (intl, dispatch) => {
    return [
        {
            type: "outlined",
            text: intl.msgJoint("msg: common.android {Android}"),
            onClick: () => {
                dispatch(setSelectedDevice('android'));
                dispatch(replaceDialogAction(QRCodeDialogId));
            },
        },
        {
            type: "outlined",
            text: intl.msgJoint("msg: common.iphone {iPhone}"),
            onClick: () => {
                dispatch(setSelectedDevice('iphone'));
                dispatch(replaceDialogAction(QRCodeDialogId));
            },
        },
    ];
};

type DeviceSelectionDialogProps = {
    intl: Intl,
    dispatch: Dispatch,
}

export const deviceSelectionDialogId = 'deviceSelectionDialogId';

export const DeviceSelectionDialog = ({ intl, dispatch }: DeviceSelectionDialogProps) => {
    const
        classes = Styles(),
        onClose = () => {
            // Needs a small delay
            setTimeout(() => {
                dispatch(closeDialogAction());
            });
        };

    return (
        <StandardDialog
            onClose={onClose}
            title={intl.msgJoint("msg: dashboard.overview.companion.title {Download the one.com Companion}")}
            buttons={getButtons(intl, dispatch)}
            dialogId={deviceSelectionDialogId}
        >
            <Grid container justifyContent="center">
                <Grid item>
                    <div className={classes.iconWrapper}>
                        <div className={cx(classes.image, imageStyles.image, imageStyles.companionIcon)} />
                    </div>
                    <div className={classes.qrTextDescription}>
                        {intl.msgJoint("msg: dashboard.overview.companion.dialog.description {Please select the type of phone that you’ll be using the app on.}")}
                    </div>
                </Grid>
            </Grid>
        </StandardDialog>
    );
};
