/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect } from 'react';
import { useDroppable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import cx from "classnames";
import { useSortable, SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import Field from './Field';
import * as styles from './Fields.css';
import type { FieldListProps, SortableListProps } from "../flowTypes";

const SortableItem = ({ field, dragOverFieldVal, index, fieldObj, selected, dispatch }) => {//eslint-disable-line
    let firstLastFieldHeight = 10;
    if (field === "lastField") {
        const calcLastFieldHt = 338 - (66 * (index - 1)) - (dragOverFieldVal ? 20 : 0);
        firstLastFieldHeight = calcLastFieldHt > 0 ? calcLastFieldHt : 10;
    }
    const {
        attributes,
        listeners,
        setNodeRef,
        transform
    } = useSortable({ id: field });
    return (<li
        style={{ zIndex: 1000000, listStyle: 'none', transform: CSS.Transform.toString(transform) }}
        ref={setNodeRef}
        {...attributes}
        {...listeners}
    >
        <div className={cx({ [styles.paddingCtr]: dragOverFieldVal === field })} />
        { field === "lastField" || field === "firstField" ? <div style={{ height: firstLastFieldHeight + "px" }} /> : <Field
            field={field}
            index={index}
            fieldObj={fieldObj}
            selected={selected}
            dispatch={dispatch}
        />}
    </li>);
};

const SortableList = ({ dragOverFieldVal, fields, formElements, selectedField, dispatch }: SortableListProps) => { //eslint-disable-line
    const { setNodeRef } = useDroppable({ id: fields[0] });

    return (
        <SortableContext id="sortable" items={[...fields]} strategy={rectSortingStrategy}>
            <div>
                <ul ref={setNodeRef} className={styles.fieldsContainer}>
                    {fields.map((field, index) => (
                        <SortableItem
                            dragOverFieldVal={dragOverFieldVal}
                            key={`item-${index}`}
                            field={field}
                            index={index}
                            fieldObj={formElements[field]}
                            selected={field === selectedField}
                            dispatch={dispatch}
                        />
                    ))}
                </ul>
            </div>
        </SortableContext>
    );
};

export const FieldsList = (props: FieldListProps) => {
    const [state, setState] = React.useState({ fields: props.formElementsOrder }),
        { fields } = state,
        { formElements, selectedField, dispatch, dragOverFieldVal } = props;
    // Top and bottom extra fields are added for achieving animation.
    const firstFieldFormElObj = { firstField: {
            name: "firstField",
            displayName: "firstField",
            isRequired: false,
            inputType: "text",
            errorMessage: "",
            hasCustomErrMsg: false,
            displayErrorMessage: "",
            values: []
        } },
        lastFieldFormElObj = { lastField: {
            name: "lastField",
            displayName: "lastField",
            isRequired: false,
            inputType: "text",
            errorMessage: "",
            hasCustomErrMsg: false,
            displayErrorMessage: "",
            values: []
        } };

    useEffect(() => {
        setState({
            fields: props.formElementsOrder
        });
    }, [props]);

    return (
        <SortableList
            dragOverFieldVal={dragOverFieldVal}
            fields={["firstField", ...fields, "lastField"]}
            formElements={
                {
                    ...formElements,
                    ...firstFieldFormElObj,
                    ...lastFieldFormElObj
                }
            }
            selectedField={selectedField}
            dispatch={dispatch}
        />
    );
};
