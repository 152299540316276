import React from "react";
export const SvgLinkedinCircleFillMulticolor = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <circle cx={24} cy={24} r={24} fill="#2867b2" />
            <path
                fill="#ffffff"
                d="M21.524 19.51h4.164v2.131h.06c.579-1.037 1.997-2.131 4.11-2.131 4.396 0 5.209 2.732 5.209 6.286v7.237h-4.34v-6.416c0-1.53-.032-3.499-2.258-3.499-2.261 0-2.606 1.667-2.606 3.388v6.527h-4.34V19.51zm-7.524 0h4.514v13.523H14zm4.514-3.756a2.255 2.255 0 01-2.257 2.254A2.255 2.255 0 0114 15.754a2.255 2.255 0 012.257-2.254 2.255 2.255 0 012.257 2.254z"
            />
        </g>
    </svg>
);
