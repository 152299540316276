export const
    WEBSHOP_MIN_WIDTH = 680,
    WEBSHOP_MIN_HEIGHT = 450,
    ProductListStyles = {
        Default: null,
        Card: 'card'
    },
    CommonMapperKeys = {
        showCategory: "showCategory",
        showSearchBar: "showSearchBar",
        showWelcomeMessage: "showWelcomeMessage",
        imageRatio: "imageRatio",
        cropImages: "cropImages",
        productsPerRow: "productsPerRow",
        productsPerRowInMobile: "productsPerRowInMobile",
        productsPerPage: "productsPerPage",
        userCanSortProducts: "userCanSortProducts",
        labelPosition: "labelPosition",
        buyButtonText: "buyButtonText",
        buyNowButtonText: "buyNowButtonText",
        isPaginationEnabled: "isPaginationEnabled",
        productListStyle: "productListStyle",
        spacingItemsInMobile: "spacingItemsInMobile",
        categoriesPosition: "categoriesPosition",
        timestamp: "timestamp",
        siteFonts: "siteFonts",
        enableBuyNowButton: "enableBuyNowButton",
        dtLanguage: "dtLanguage",
        promoRibbonBgColor: "promoRibbonBgColor",
        promoRibbonTextColor: "promoRibbonTextColor"
    },
    WebshopThemeMapperKeys = {
        fontColorTheme: "fontColorTheme",
        focusColorTheme: "focusColorTheme",
        hoverColorTheme: "hoverColorTheme",
        labelBgColorTheme: "labelBgColorTheme",
        labelTextColorTheme: "labelTextColorTheme",
        buttonThemeSelected: "buttonThemeSelected",
        buyNowButtonThemeSelected: "buyNowButtonThemeSelected",
        promoRibbonBgColorTheme: "promoRibbonBgColorTheme",
        promoRibbonTextColorTheme: "promoRibbonTextColorTheme"
    },
    SubscriptionStatus = {
        NONE: "none",
        TRIAL: "trial",
        PAID: "paid",
        EXPIRED: 'expired'
    },
    DialogTypes = {
        UPGRADE_SHOP: 'upgrade-shop',
        UPGRADE_SHOP_SUCCESS: 'upgrade-shop-success',
        MANAGE_SHOP: 'manage-shop',
        ONBOARDING: 'onboarding'
    };
