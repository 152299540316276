import buildNewVideoComponentState from "./buildNewVideoComponentState";
import { getDefaultVideoFileAsset } from "./constants";
import type { VideoComponent } from './flowTypes';
import { Youtube } from "../../Panel/configs/constants";

const videoAdjustComponentOnAdd = (component: VideoComponent): VideoComponent => {
    if (component.dialogProps &&
        component.dialogProps.type &&
        component.dialogProps.type.toLowerCase() === Youtube) {
        return component;
    }
    return {
        ...component,
        asset: getDefaultVideoFileAsset(),
        state: buildNewVideoComponentState(null).state
    };
};

export {
    videoAdjustComponentOnAdd as default,
    videoAdjustComponentOnAdd
};
