import { all, fork } from 'redux-saga/effects'; // eslint-disable-line node/file-extension-in-import
import makeReloadResourcesSaga from "./reducers/makeReloadResourcesSaga";
import uploadFromUrlSaga from "./reducers/upload/uploadFromUrlSaga";
import {
    uploadFromComputerSaga
} from "./reducers/upload/uploadFromComputer/uploadFromComputerSaga";
import { deleteResourceSaga } from "./reducers/deleteResourceSaga";
import { FC_NAVIGATE_DIRECTORY, FC_NAVIGATE_SUB_DIRECTORY } from "./actionTypes";
import saveSaga from "./reducers/saveSaga";
import initializeSaga from "./reducers/initializeSaga";
import { fcMyFilesEditSaga } from "./reducers/fcMyFilesEditSaga";

export default function* (): any {
    yield all([
        fork(makeReloadResourcesSaga(FC_NAVIGATE_DIRECTORY)),
        fork(makeReloadResourcesSaga(FC_NAVIGATE_SUB_DIRECTORY)),
        fork(uploadFromUrlSaga),
        fork(uploadFromComputerSaga),
        fork(saveSaga),
        fork(fcMyFilesEditSaga),
        fork(initializeSaga),
        fork(deleteResourceSaga),
    ]);
}
