import type { SectionLinkFormState, SectionLinkFormCombinedProps } from "../flowTypes";
import { DataSite } from "../../../../../dal/model";
import type { SectionComponents } from "../../sectionComponents/epic/sectionComponentsEpic";

type Input = {
    dependencies: {
        site: DataSite;
        currentPageId: string;
        sectionComponents: SectionComponents;
    };
    state: SectionLinkFormState;
    actions: {
        nameOnChangeAction: string;
        pageOnChangeAction: string;
        sectionOnChangeAction: string;
        hiddenOnChangeAction?: string,
        resetAction?: string,
    };
};

export default ({ dependencies, state, actions }: Input): SectionLinkFormCombinedProps => {
    const
        { sectionComponents, currentPageId } = dependencies,
        {
            nameOnChangeAction,
            pageOnChangeAction,
            sectionOnChangeAction,
            hiddenOnChangeAction,
            resetAction,
        } = actions,
        pageOptions = sectionComponents.pageOptions;

    let { pageId, sectionId, name, hidden, isInvalidName } = state,
        isValidPageId,
        sectionOptions;
    if (pageId === '') {
        pageId = currentPageId;
    }
    isValidPageId = pageOptions.some(({ value }) => value === pageId);
    sectionOptions = isValidPageId ? sectionComponents.pageSections[pageId] : [];
    const isLoading = sectionComponents.isLoading;
    const
        form = {
            pageId,
            sectionId,
            name,
            hidden,
            nameOnChangeAction,
            pageOnChangeAction,
            sectionOnChangeAction,
            hiddenOnChangeAction,
            resetAction,
            pageOptions,
            sectionOptions,
            isInvalidName,
            isLoading,
            isValidPageId,
        },
        result = {
            pageId,
            sectionId,
            name,
            hidden
        };
    let isFormValid = !!name && !!pageId && !!sectionId && !isLoading;

    return {
        form,
        result,
        isFormValid,
        sectionComponents,
    };
};
