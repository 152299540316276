import { FontFamiliesFallback } from "../../../presentational/FontFamily/fontFamilies";

export default (node: HTMLElement) => {
    // @ts-ignore
    Array.from(node.querySelectorAll('[style*=font-family]')).forEach((el: HTMLElement) => {
        let fontFamily = el.style.fontFamily;
        if (fontFamily) {
            fontFamily = fontFamily.replace(/"/g, "");
            if (FontFamiliesFallback[fontFamily]) {
                el.style.fontFamily = FontFamiliesFallback[fontFamily]; // eslint-disable-line no-param-reassign
            }
        }
    });
};
