import React from 'react';
import { Flex } from '../../../view/reflexbox/index';
import PropertyContainer from '../../../view/common/PropertyContainer/index';
import injectIntl from "../../../view/intl/injectIntl";
import { GlobalstyleDialogInvoker } from '../../presentational/GlobalstyleDialogInvoker/index';
import { Combobox } from '../../../view/common/Combobox/index';
import { BUTTON_PROP_PANEL_BUTTON_GLOBAL_STYLE_BTN_PRESSED } from "../../oneweb/Button/actionTypes";

import type { ThemeButtonTypes } from '../../ThemeGlobalData/flowTypes';
import type { Intl } from "../../../view/intl/index";

type ThemeOption = {
    label: string;
    value: ThemeButtonTypes;
};

type Props = {
    intl: Intl;
    dispatch: Dispatch;
    label?: string;
    onChange: (ThemeOption) => void;
    selectedTheme: ThemeButtonTypes;
    themeOptions: Array<ThemeOption>;
    containerClassName?: string;
    optionRenderer?: Function;
    className?: string;
};

export const ThemeSelector = injectIntl(
    (props: Props) =>
        <PropertyContainer className={props.containerClassName} label={props.label}>
            <Flex align="center" justify="space-between">
                <Combobox
                    value={props.selectedTheme}
                    searchable={false}
                    style={{ width: 200 }}
                    options={props.themeOptions}
                    onChange={props.onChange}
                    placeholder={props.intl.msgJoint('msg: common.select.placeholder {Select...}')}
                    // @ts-ignore
                    intl={props.intl}
                    optionRenderer={props.optionRenderer}
                    className={props.className}
                />
                <GlobalstyleDialogInvoker
                    onClick={() => props.dispatch({ type: BUTTON_PROP_PANEL_BUTTON_GLOBAL_STYLE_BTN_PRESSED })}
                />
            </Flex>
        </PropertyContainer>
);
