import { ThemeOptions, createTheme } from "@mui/material/styles";

import { PartnerName } from "../../../../../server/shared/partners/PartnerName";

import { theme as onecomTheme, extraConfig as onecomExtraConfig } from "./onecom";
import { theme as hostnetTheme, extraConfig as hostnetExtraConfig } from "./hostnet";
import { theme as checkdomainTheme, extraConfig as checkdomainExtraConfig } from "./checkdomain";
import { theme as heroldTheme, extraConfig as heroldExtraConfig } from "./herold";
import { theme as zonerTheme, extraConfig as zonerExtraConfig } from "./zoner";
import { htmlTheme } from "../../common/config";
import { ExtraThemeConfig } from "./types";

// @ts-ignore
const EXTRA_THEMES_CONFIG: Record<string, ExtraThemeConfig> = {
    [PartnerName.ONECOM]: onecomExtraConfig,
    [PartnerName.HOSTNET]: hostnetExtraConfig,
    [PartnerName.CHECKDOMAIN]: checkdomainExtraConfig,
    [PartnerName.HEROLD]: heroldExtraConfig,
    [PartnerName.ZONER]: zonerExtraConfig,
};

export const extraThemeConfig = EXTRA_THEMES_CONFIG[htmlTheme];

export const MAX_CONTENT_WIDTH = 1440;

const APP_THEMES: Record<string, ThemeOptions> = {
    [PartnerName.ONECOM]: onecomTheme,
    [PartnerName.HOSTNET]: hostnetTheme,
    [PartnerName.CHECKDOMAIN]: checkdomainTheme,
    [PartnerName.HEROLD]: heroldTheme,
    [PartnerName.ZONER]: zonerTheme,
};

export const createAppTheme = (partner: string) => {
    if (!(partner in APP_THEMES)) {
        throw new Error("Invalid partner key provided for theme!");
    }

    return createTheme(APP_THEMES[partner]);
};
