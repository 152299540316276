// @ts-nocheck
import { DEFAULT_LOCALE } from "../../../../server/shared/constants";

import { calendar as calendarEn } from "./en";
import { calendar as calendarDa } from "./da";
import { calendar as calendarDe } from "./de";
import { calendar as calendarEs } from "./es";
import { calendar as calendarFr } from "./fr";
import { calendar as calendarIt } from "./it";
import { calendar as calendarNl } from "./nl";
import { calendar as calendarNb } from "./nb";
import { calendar as calendarPt } from "./pt";
import { calendar as calendarFi } from "./fi";
import { calendar as calendarSv } from "./sv";

export const CALENDAR_LOCALE_FORMATS = {
    en: calendarEn,
    da: calendarDa,
    de: calendarDe,
    es: calendarEs,
    fr: calendarFr,
    it: calendarIt,
    nl: calendarNl,
    nb: calendarNb,
    pt: calendarPt,
    fi: calendarFi,
    sv: calendarSv,
};

export const CALENDAR_DEFAULT_LOCALE_FORMAT = CALENDAR_LOCALE_FORMATS[DEFAULT_LOCALE.split('_')[0]];
