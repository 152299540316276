import React from 'react';
import type { CTAViewParams } from "../../../../view/Workspace/Decorations/ComponentMainActions/flowTypes";
import * as actionTypes from "../actionTypes";
import HorizontalSpacer from "../../../../view/common/HorizontalSpacer";
import { MctaInput } from "../../../componentMainActions/index";
import type { CodeComponent } from '../flowTypes';

type PropTypes = {
    selectedComponent: CodeComponent,
    children?: any,
    dispatch: Function
}

export default {
    editView: ({ selectedComponent: { name }, children, dispatch }: PropTypes) => (
        <div>
            <HorizontalSpacer x={5} />
            <MctaInput
                label="msg: common.title {Title}"
                placeholder="msg: common.enterTitle {Enter title}"
                value={name}
                inputStyle={{ width: 150 }}
                onChange={name => dispatch({
                    type: actionTypes.CODE_PROP_PANEL_CHANGE_TITLE,
                    payload: { name },
                    amendToSelf: true
                })}
            />
            {children ? <HorizontalSpacer /> : <HorizontalSpacer x={6} />}
            {children}
        </div>
    ),
    editButtonText: "msg: component.common.Settings {Settings}",
    ctaButtonText: "msg: component.code.editCode {Edit code}",
    ctaOnClick: ({ dispatch, selectedComponent }: CTAViewParams<CodeComponent>) => {
        dispatch({ type: actionTypes.CODE_EDIT_CODE_BUTTON_CLICKED, payload: selectedComponent });
    }
};
