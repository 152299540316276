// @ts-nocheck
export default (text: string, isFirstChild): string => {
    return text
        .replace('\n', '')
        // todo use regex to match it if its not empty_space
        // .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/(^\s$)/, isFirstChild ? '&nbsp;' : ' ')
        .replace(/ {2}/g, '&nbsp; ')
        .replace(/ {2}/g, ' &nbsp;');
};
