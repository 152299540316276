import { fileManagerDialogMiddleware } from "./dialog/middleware/fileManagerDialogMiddleware";
import { shutterstockMiddlewares } from "./shutterstock/middleware/index";
import { freeOneComSaveMiddleware } from "./freeOnecom/middleware/freeOnecomSaveMiddleware";
import { freeOnecomMiddleware } from "./freeOnecom/middleware/freeOnecomMiddleware";
import { paidShutterstockMiddleware } from "./paidShutterstock/middleware/paidShutterstockMiddleware";
import { unsplashMiddleware } from "./unsplash/middleware/unsplashMiddleware";

export const fileManagerMiddlewares = [
    fileManagerDialogMiddleware,
    ...shutterstockMiddlewares,
    freeOneComSaveMiddleware,
    freeOnecomMiddleware,
    paidShutterstockMiddleware,
    unsplashMiddleware
];
