import React from 'react';
import dialogStyles from '../../../../../view/common/dialogs/baseDialog/Dialog.css';
import injectIntl, { Intl } from "../../../../../view/intl/injectIntl";

type Props = {
    intl: Intl,
};

// eslint-disable-next-line max-len
const
    NOTICE_MSG = 'msg: document.footerNotice {By inserting a document, you accept {0}.}',
    TERMS_MSG = 'msg: document.googleTermsMsg {Google Docs Viewer Terms of Service}',
    TERMS_URL = 'msg: googleDriveTermsOfServicesUrl {https://support.google.com/drive/answer/2450387?visit_id=0-636613745763011945-177324582&hl=en&rd=1}';

export const DocumentConfigDialogFooterComponent = injectIntl(({ intl }: Props) => {
    const link = `
        <a
            href="${intl.msgJoint(TERMS_URL)}"
            class="${dialogStyles.dialogGreenLink}"
            target="_blank"
        >${intl.msgJoint(TERMS_MSG)}</a>`;

    return (
        <div
            dangerouslySetInnerHTML={{ __html: intl.msgJoint([NOTICE_MSG, { '0': link }]) }}
        />
    );
});
