import React from 'react';
import cx from 'classnames';
import styles from './TopBarUserProfile.css';
import type { TopBarLanguagesState } from '../../epics/languages/index';
import { topBarSelectLanguageAction } from '../../actionCreators/index';
import Scrollbar from "../../../../view/common/Scrollbar/index";

type Props = {
    state: TopBarLanguagesState,
    dispatch?: Dispatch;
    fromTemplateSelector?: boolean,
    className?: string,
    containerRef?: any,
    onLanguageClick?: (locale: string, e: MouseEvent) => void;
}

export const TopBarLanguages = (props: Props) => {
    const {
        state: { map, current, height },
        fromTemplateSelector,
        containerRef,
        dispatch,
        className,
        onLanguageClick,
    } = props;

    return (
        <div className={cx(styles.languagesContainer, className)} ref={containerRef}>
            <Scrollbar height={height} fitHeight>
                <ul>
                    {Object.keys(map).map(locale => {
                        const
                            name = map[locale],
                            isCurrent = current === locale,
                            className = cx(styles.languagesItem, { [styles.active]: isCurrent });

                        const onClick = e => {
                            if (isCurrent) return;
                            else if (onLanguageClick) {
                                onLanguageClick(locale, e);
                            } else if (dispatch) {
                                dispatch(topBarSelectLanguageAction({
                                    locale, fromTemplateSelector
                                }));
                            }
                        };

                        return (
                            <li className={className} onClick={onClick} key={locale}>{name}</li>
                        );
                    })}
                </ul>
            </Scrollbar>
        </div>
    );
};
