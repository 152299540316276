import Button from '../../oneweb/Button/kind';
import ContactForm from '../../oneweb/ContactForm/kind';
import type { AnyComponent } from "../../oneweb/flowTypes";
import Text from "../../oneweb/Text/kind";

export default (
    cmp: AnyComponent
): string => {
    let className = '';
    if (cmp && cmp.mobileSettings) {
        const alignment = cmp.mobileSettings.align;
        switch (cmp.kind) {
            case Button:
            case ContactForm:
                className = `align-${alignment}`;
                break;
            case Text:
                className = `text-align-${alignment}`;
                break;
            default:
                className = '';
        }
    }
    return className;
};
