import React from 'react';
import cx from 'classnames';
import styles from './Common.css';

type Props = {
    y?: number,
    className?: string,
    children?: React.ReactElement<any> | Array<React.ReactElement<any>>
}

const DEFAULT_HEIGHT = 20;

const VerticalSpacer = ({ y = DEFAULT_HEIGHT, className, children }: Props) => (
    <div className={cx(styles.verticalSpacer, className)} style={{ height: y }}>
        {children}
    </div>
);

export {
    VerticalSpacer,
    VerticalSpacer as default
};
