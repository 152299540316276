import { LOAD_SITE_SETTINGS_SUCCESS } from "../../../App/epics/siteSettings/actionTypes";

export const marketingConsent = (state = {}, action) => {
    switch (action.type) {
        case LOAD_SITE_SETTINGS_SUCCESS: {
            if (action.payload?.privacyPolicy) {
                return { ...state, ...action.payload.privacyPolicy };
            }

            return state;
        }

        default:
            return state;
    }
};
