import type { Color } from "../../../mappers/flowTypes";
import {
    BG_BACKGROUND_COLOR_SET,
    BG_GRADIENT_COLOR_SET,
    BG_GRADIENT_DIRECTION_CHANGE,
    BG_GRADIENT_FADE_POINT_CHANGE, BG_IMAGE_OPACITY_CHANGE
} from "./actionTypes";
import { GradientDirection } from "../../presentational/ColorGradientSettings/constants";

type GradientDirectionValues = typeof GradientDirection[keyof typeof GradientDirection];

export const
    bgSetBackgroundImageOpacityAC = (opacity: number) => ({
        type: BG_IMAGE_OPACITY_CHANGE,
        payload: opacity
    }),
    bgSetBackgroundColorAC = (color: Color) => ({
        type: BG_BACKGROUND_COLOR_SET,
        payload: { color }
    }),
    bgSetBackgroundGradientColorAC = (color: Color) => ({
        type: BG_GRADIENT_COLOR_SET,
        payload: { color }
    }),
    bgChangeBackgroundGradientDirectionAC = (direction: GradientDirectionValues) => ({
        type: BG_GRADIENT_DIRECTION_CHANGE,
        payload: direction
    }),
    bgChangeBackgroundGradientFadePointAC = (fadePoint: number) => ({
        type: BG_GRADIENT_FADE_POINT_CHANGE,
        payload: fadePoint
    });

