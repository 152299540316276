import type { SideBarItemDef } from '../../SideBar/types';
import { isAnalyticsFeatureEnabled } from '../../../utils/configUtils';

import imageStyles from "../../Images/imageStyle.css";

export const ANALYTICS_PAGE_SIDE_BAR_ITEM_ID = 'ANALYTICS_PAGE_SIDE_BAR_ITEM_ID';

export const AnalyticsSideBarItem: SideBarItemDef = {
    id: ANALYTICS_PAGE_SIDE_BAR_ITEM_ID,
    icon: imageStyles.analyticsMenu,
    title: 'msg: common.analytics {Analytics}',
    header: {
        title: "msg: common.analytics {Analytics}",
        description: "msg: analyticsTab.header.label {Access useful statistics to fine-tune your marketing efforts and identify areas of improvement for your site.}"
    },
    disabled: () => !isAnalyticsFeatureEnabled(),
};

export const IS_ANALYTICS_RECENTLY_ENABLED_DAYS = 7;
