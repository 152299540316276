import type { AnyComponent } from "../../../flowTypes";

type Group = {
    top: number,
    bottom: number,
    cmps: Array<AnyComponent>
};
export const divideInToGroups = (cmps: Array<AnyComponent>): Group[] => {
    let groups: Group[] = [], group = {} as Group;
    cmps.slice().sort((a, b) => a.top - b.top).forEach(cmp => {
        const { top, height } = cmp,
            bottom = top + height;
        if (!group.bottom) {
            group = { top, bottom, cmps: [cmp] };
        } else if (top >= group.bottom) {
            groups.push(group);
            group = { top, bottom, cmps: [cmp] };
        } else {
            group.bottom = Math.max(group.bottom, bottom);
            group.cmps.push(cmp);
        }
    });
    if (group.bottom) {
        groups.push(group);
    }
    return groups;
};
