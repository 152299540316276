/* eslint-disable max-classes-per-file */
import * as React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import styles from './DemoBar.css';
import type { DemoBarStatusT } from '../types';
import type { AppState } from '../../../src/redux/modules/flowTypes';
import {
    demoBarStatusAppSel,
    isDemoSubscriptionTypeAppSel,
    trialDaysRemainingAppSel,
    trialDomainNameForPurchaseSel
} from '../selectors';
import { DemoBarStatus } from './constants';
import { loginFromDemoAction, toggleDemoBarAction } from '../actions';
import { DemoBuyBtn } from '../buyButton/DemoBuyBtn';
import { injectIntl, Intl, Msg } from "../../../src/view/intl/index";
import { isWbtgenTrialOneComCpUser } from "../../utils/isWbtgenTrialOneComCpUser";
import { currentLanguageAppSel } from "../../../src/components/TopBar/epics/languages/selectors";
import { mapLocaleCountry } from '../../../../server/shared/locale/mapLocaleCountry';
import { TRIAL_PRICE_PLAN } from '../../../../server/shared/trial/constants';
import { TrialUrl } from '../../../../server/shared/trial/TrialUrl';
import { fetchService } from "../../../src/services/fetch/fetchService";
import { getAppConfig } from "../../../src/components/App/epics/appConfig/appConfig";

const
    TextMessage = {
        BASE: 'msg: demo.bottomBarText.base {Website tools, hosting and personalized email all in one plan.}',
        PRICE: 'msg: demo.bottomBarText.price {From {price} /month.}',
        DOMAIN_AVAILABLE: 'msg: demo.bottomBarText.domainAvailable {<b>{trialDomainNameForPurchase}</b> is available - make it yours today!}', // eslint-disable-line max-len
    },
    appConfig = getAppConfig();

type CommonBaseProps = {
    dispatch: Dispatch,
    children?: React.ReactNode
};

type BaseProps = CommonBaseProps & { expanded?: boolean, collapsed?: boolean };

class DemoBarBase extends React.Component<BaseProps> {
    onToggleClick = () => {
        this.props.dispatch(toggleDemoBarAction());
    }

    render() {
        const statusClasses = {

            [styles.expanded]: !!this.props.expanded,

            [styles.collapsed]: !!this.props.collapsed,
        };

        return (
            <div className={cx(styles.container, statusClasses)}>
                {this.props.children}
                <span className={styles.toggleIcn} onClick={this.onToggleClick} />
            </div>
        );
    }
}

const DemoBarBuyBtn = () => <DemoBuyBtn className={styles.buyButton} />;

type TrialInfoProps = {
    trialDaysRemaining: number
};

class TrialVersion extends React.Component <TrialInfoProps> {
    render() {
        return (
            <Msg k="demo.trialDaysRemaining" params={{ daysLeft: this.props.trialDaysRemaining }}>
                {`{daysLeft, plural, zero { no days left } one { # day left } other { # days left }}`}
            </Msg>
        );
    }
}

type Props = {
    isDemo: boolean,
    status: DemoBarStatusT,
    trialDaysRemaining: number,
    trialDomainNameForPurchase: null | undefined | string,
    lang: string,
    dispatch: Dispatch,
    intl: Intl,
};

type State = {
    show: boolean,
    isCpUser: boolean,
    priceText: string,
};

class DemoBarClass extends React.Component<Props, State> { // eslint-disable-line react/no-multi-comp
    state = {
        show: false,
        isCpUser: false,
        priceText: '',
    };

    componentDidMount() {
        if (this.props.isDemo) {
            isWbtgenTrialOneComCpUser().then(isCpUser => this.setState({ show: true, isCpUser }));

            const country = mapLocaleCountry(this.props.lang).toUpperCase();
            const trialBuyPriceConfig = appConfig.oneWeb.trial.price && appConfig.oneWeb.trial.price.static ?
                appConfig.oneWeb.trial.price.static : '';

            if (!trialBuyPriceConfig) {
                fetchService(TrialUrl.price(TRIAL_PRICE_PLAN), {
                    query: { country },
                }).then((res: Record<string, any>) => {
                    const { price, currency } = res.bodyData;
                    this.setState({
                        priceText: this.props.intl.currency(price, currency),
                    });
                });
            } else {
                this.setState({
                    priceText: trialBuyPriceConfig
                });
            }
        }
    }

    onLoginClick = () => {
        return this.props.dispatch(loginFromDemoAction());
    }

    renderText() {
        const
            { intl } = this.props,
            { isCpUser, priceText } = this.state,
            nodes = [
                intl.msgJoint(TextMessage.BASE),
            ],
            addNode = msg => nodes.push(' ', intl.msgJoint(msg));

        if (!isCpUser && priceText) {
            addNode([TextMessage.PRICE, { price: priceText }]);
        }

        return nodes;
    }

    renderExpanded() {
        const { dispatch, trialDaysRemaining } = this.props;

        return (
            <DemoBarBase expanded dispatch={dispatch}>
                <div className={styles.innerContainer}>
                    <span className={styles.brushIcn} />
                    <div className={styles.textContainer}>
                        <p>
                            {/* eslint-disable-next-line max-len */}
                            <b><Msg k="demo.trialDaysRemainingTitle">Trial version</Msg></b> - <TrialVersion trialDaysRemaining={trialDaysRemaining} />
                        </p>
                        <p>
                            {this.renderText()}
                        </p>
                    </div>
                    <div className={styles.buyContainer}>
                        <DemoBarBuyBtn />
                    </div>
                </div>
            </DemoBarBase>
        );
    }

    renderCollapsed() {
        const { dispatch } = this.props;

        return (
            <DemoBarBase collapsed dispatch={dispatch}>
                <Msg k="demo.trialVersionOfWsb">This is a trial version of Website Builder.</Msg>
                <DemoBarBuyBtn />
            </DemoBarBase>
        );
    }

    render() {
        if (!this.props.isDemo || !this.state.show) return null;

        const
            { status } = this.props,
            expanded = status === DemoBarStatus.EXPANDED,
            collapsed = status === DemoBarStatus.COLLAPSED;

        if (expanded) return this.renderExpanded();
        else if (collapsed) return this.renderCollapsed();
        return null;
    }
}

const mapStateToProps = (appState: AppState) => ({
    isDemo: isDemoSubscriptionTypeAppSel(appState),
    status: demoBarStatusAppSel(appState),
    trialDaysRemaining: trialDaysRemainingAppSel(appState),
    trialDomainNameForPurchase: trialDomainNameForPurchaseSel(appState),
    lang: currentLanguageAppSel(appState),
});

export const DemoBar = injectIntl(connect(mapStateToProps)(DemoBarClass));
