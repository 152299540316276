import isDuplicatePageUrlPath from "./isDuplicatePageUrlPath";
import { parseIntDec } from '../../../../utils/number';
import { encodeWebspaceUri, decodeWebspaceUri } from "../../../../dal/utils/webspaceUriTransformers";
import { urlCleanerRegex } from "../Tree/dialogs/PageInfoDialog/reducer/containsInvalidCharactersInURL";

const SEPARATOR = '-';

const DefaultPageURL = 'page';
export const DefaultLayoutCategory = "BLANK_PAGE";

const RegExes = {
    allSplCharsToSeparator: new RegExp(`[${SEPARATOR}]+`, 'g'),
    noSeparatorAtBeginning: new RegExp(`^${SEPARATOR}+`, 'g'),
    noSeparatorAtEnd: new RegExp(`${SEPARATOR}+$`, 'g'),
    noSplCharsAtBeginOrEnd: /^[^a-zA-Z0-9]+|[^a-zA-Z0-9]+$/g    //NOSONAR
};

const getCounter = (path: Array<string>): number => {
    const url = path.slice(-1)[0];
    if (url.indexOf(SEPARATOR) === -1) return 0;

    const suffix = url.split(SEPARATOR).slice(-1)[0];
    return parseIntDec(suffix) || 0;
};

const getUrlForNewPage = (pageName: string, layoutCategory: string): string => {
    let url = pageName
        .replace(urlCleanerRegex, SEPARATOR)
        .replace(RegExes.allSplCharsToSeparator, SEPARATOR)
        .replace(RegExes.noSeparatorAtBeginning, '')
        .replace(RegExes.noSeparatorAtEnd, '')
        .replace(RegExes.noSplCharsAtBeginOrEnd, '');

    url = url.length ? url : (layoutCategory || DefaultLayoutCategory);

    return url.toLowerCase();
};

const getPathPrefix = (path: Array<string>): string => {
    return getCounter(path)
        ? path
            .join(SEPARATOR)
            .split(SEPARATOR)
            .slice(0, -1)
            .join(SEPARATOR)
        : path
            .join(SEPARATOR);
};

/**
 * Produces url as page name.
 * In case of url is already in use - advances counter.
 * Default counter = 1.
 *
 * @param pageName
 * @param parentPageUrlPath
 * @param allPagesUrlPaths
 *
 * @returns {string}
 */
export default (
    pageName: string,
    parentPageUrlPath: Array<string>,
    allPagesUrlPaths: Array<Array<string>>,
    layoutCategory: string
): string => {
    let url = encodeWebspaceUri(getUrlForNewPage(decodeWebspaceUri(pageName), layoutCategory));

    const urlPath = [...parentPageUrlPath, url];
    if (isDuplicatePageUrlPath(urlPath, allPagesUrlPaths)) {
        // suffix with counter

        const
            maxCounter = allPagesUrlPaths.reduce((acc: number, path: Array<string>) => (
                getPathPrefix(path) === getPathPrefix(urlPath)
                    ? Math.max(acc, getCounter(path))
                    : acc
            ), 0),
            newUrlCounter = maxCounter + 1;

        const urlCounter = getCounter(urlPath);
        if (urlCounter) {
            url = url.replace(`${SEPARATOR}${urlCounter}`, `${SEPARATOR}${newUrlCounter}`);
        } else {
            url += `${SEPARATOR}${newUrlCounter}`;
        }

        if (isDuplicatePageUrlPath([...parentPageUrlPath, url], allPagesUrlPaths)) {
            throw new Error(`Failed to correct new page url. New url: ${url}; all urls: ${allPagesUrlPaths.join(',')}`);
        }
    }

    return url;
};

export { SEPARATOR, DefaultPageURL };
