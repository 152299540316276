export const
    LOAD_SITE_SETTINGS_REQUEST = 'LOAD_SITE_SETTINGS_REQUEST',
    LOAD_SITE_SETTINGS_SUCCESS = 'LOAD_SITE_SETTINGS_SUCCESS',
    LOAD_SITE_SETTINGS_FAILURE = 'LOAD_SITE_SETTINGS_FAILURE',

    CREATE_DEFAULT_SITE_SETTINGS_REQUEST = 'CREATE_DEFAULT_SITE_SETTINGS_REQUEST',
    CREATE_DEFAULT_SITE_SETTINGS_SUCCESS = 'CREATE_DEFAULT_SITE_SETTINGS_SUCCESS',
    CREATE_DEFAULT_SITE_SETTINGS_FAILURE = 'CREATE_DEFAULT_SITE_SETTINGS_FAILURE',

    FILL_SPECIFIC_SITE_SETTINGS_DURING_PAGE_OR_TEMPLATE_IMPORT =
        "FILL_SPECIFIC_SITE_SETTINGS_DURING_PAGE_OR_TEMPLATE_IMPORT",
    SAVE_SITE_SETTINGS_AFTER_PAGE_OR_TEMPLATE_IMPORT = "SAVE_SITE_SETTINGS_AFTER_PAGE_OR_TEMPLATE_IMPORT",
    SITE_SETTINGS_LOADED = 'SITE_SETTINGS_LOADED',
    AFTER_SITE_SETTINGS_LOADED_PROCESSING_COMPLETE = 'AFTER_SITE_SETTINGS_LOADED_PROCESSING_COMPLETE',
    MERGE_TEMPLATE_SITE_SETTINGS = 'MERGE_TEMPLATE_SITE_SETTINGS',
    SITE_SETTING_LOADED = 'SITE_SETTING_LOADED',
    SAVE_SITE_SETTINGS = 'SAVE_SITE_SETTINGS',
    SITE_SETTINGS_MIGRATE_HOOK = 'SITE_SETTINGS_MIGRATE_HOOK',
    AFTER_MIGRATE_SITE_SETTINGS = 'AFTER_MIGRATE_SITE_SETTINGS',
    SITE_SETTINGS_DB_LAYER_DATA_UPDATED = 'SITE_SETTINGS_DB_LAYER_DATA_UPDATED';
