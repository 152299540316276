import React from "react";
import { useDispatch } from "react-redux";
import { Button, DialogContentText, DialogContent, DialogActions } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import { useIntl } from "../../../../../../wbtgen/src/view/intl";

import { closeDialogAction } from "../../Dialog/actions";
import { BasicDialog } from "../../Dialog/BasicDialog";
import { GENERATE_PRIVACY_POLICY_ACTION } from "./actions";

const
    title = "msg: privacyPolicy.generate.title {Generate privacy policy}",
    description = "msg: privacyPolicy.generate.description {The generated privacy policy will overwrite your current draft. Are you sure?}";

const useStyles = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.down('sm')]: {
            position: "absolute",
            bottom: 0,
            maxWidth: "100%",
            margin: 0,
        },
    },
    dialogContainer: {
        padding: "48px 48px 32px"
    },
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        margin: "0px",
        textAlign: "left",
        alignItems: "flex-start"
    },
    title: {
        fontSize: "24px",
        lineHeight: "36px"
    },
    description: {
        fontSize: "14px",
        lineHeight: "22px",
        marginBottom: "0px",
        margin: "16px 0px 0px",
    },
    dialogActions: {
        display: "flex",
        justifyContent: "center",
        marginTop: "24px",
        [theme.breakpoints.up('sm')]: {
            justifyContent: "flex-start",
        },
    },
    closeButtonStyleClass: {
        padding: "20px",
        "& svg": {
            width: "24px",
            height: "24px"
        },
        [theme.breakpoints.up('sm')]: {
            padding: "18px",
            "& svg": {
                width: "22px",
                height: "22px"
            },
        },
    },
    actionBtn: {
        fontSize: "14px",
        lineHeight: "40px",
        fontWeight: 600,
        padding: "0px 30px",
        [theme.breakpoints.down('sm')]: {
            width: "50%",
            padding: "0px"
        },
    },
}), { name: "generatePrivacyPolicyDialog" });

type GeneratePrivacyPolicyDialogProps = {
    config: any;
};

export const GeneratePrivacyPolicyDialog = ({ config }: GeneratePrivacyPolicyDialogProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const classes = useStyles();

    return (
        <BasicDialog
            onClose={() => dispatch(closeDialogAction())}
            classes={{ paper: classes.paper }}
            closeButtonStyleClass={classes.closeButtonStyleClass}
            containerClass={classes.dialogContainer}
            maxWidth="lg"
            {...config}
        >
            <DialogContent className={classes.dialogContent}>
                <DialogContentText className={classes.title}>
                    {intl.msgJoint(title)}
                </DialogContentText>

                <DialogContentText className={classes.description} component="div">
                    {intl.msgJoint(description)}
                </DialogContentText>
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.actionBtn}
                    onClick={() => dispatch(closeDialogAction())}
                >
                    {intl.msgJoint("msg: common.cancel {Cancel}")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.actionBtn}
                    onClick={() => {
                        dispatch({ type: GENERATE_PRIVACY_POLICY_ACTION });
                        dispatch(closeDialogAction());
                    }}
                    style={{ marginLeft: "16px" }}
                >
                    {intl.msgJoint("msg: common.confirm {Confirm}")}
                </Button>
            </DialogActions>
        </BasicDialog>
    );
};

export const GENERATE_PRIVACY_POLICY_DIALOG_ID = 'GENERATE_PRIVACY_POLICY_DIALOG_ID';
