import kind from './kind';
import view from './view/index';
import type { CalcProps } from '../../../Preview/flowTypes';
import type { WebShopPaymentMethodsComponent } from '../flowTypes';

export default {
    kind,
    view,
    calcProps: ({
        component,
        webshopMHFData,
        globalStyles: stylesheets,
        siteSettings: { themeSettingsData: { autoColorMode } },
        selectedParentTheme,
    }: CalcProps<WebShopPaymentMethodsComponent>) => ({ ...component,
        webshopMHFData,
        stylesheets,
        selectedParentTheme: autoColorMode && selectedParentTheme })
};
