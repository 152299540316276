import * as React from 'react';
import cx from 'classnames';
import { $Shape } from 'utility-types';
import styles from './InputCounter.css';
import type { ReactElementRef } from "../../../../globalTypes";
import type { InputCounterProps } from "./types";
import { INPUT_COUNTER_THRESHOLD, INPUT_COUNTER_TOP_SPACING } from "./constants";

type Props = InputCounterProps & {
    inputRef: React.RefObject<any>,
    countBeyondMax: boolean
};

type State = {
    isInputFocused: boolean,
    height: number,
    length: number,
    HTML?: AnyReactElement
};

export class InputCounter extends React.Component<Props, State> {
    // eslint-disable-next-line react/static-property-placement
    static defaultProps: $Shape<Props> = {
        // eslint-disable-next-line react/default-props-match-prop-types
        threshold: INPUT_COUNTER_THRESHOLD,
        // eslint-disable-next-line react/default-props-match-prop-types
        topSpacing: INPUT_COUNTER_TOP_SPACING,
    };

    counterRef: ReactElementRef<HTMLDivElement>;

    state: State = {
        isInputFocused: false,
        height: 0,
        length: 0,
    };

    constructor() {
        // @ts-ignore
        super();
        this.counterRef = React.createRef();
    }

    isLimitExceeded = () => {
        const
            { maxLength, threshold } = this.props,
            { length } = this.state;

        // @ts-ignore
        return length && (maxLength - length) <= threshold;
    }

    onInputChange = (e: Record<string, any>) => {
        const
            { maxLength, countBeyondMax } = this.props,
            target = e.target,
            length = target.value.length;

        this.setState({ length: (length > maxLength && !countBeyondMax) ? maxLength : length }, () => {
            if (this.isLimitExceeded()) {
                target.style.border = styles.limitExceededInputBorder;
            } else {
                target.style.border = '';
            }
        });
    }

    componentDidMount() {
        const { inputRef } = this.props;

        if (inputRef.current && this.counterRef.current) {
            const
                inputTarget = inputRef.current,
                parent = inputTarget.parentNode,
                parentPosition = window.getComputedStyle(parent).getPropertyValue('position');

            if (parentPosition !== 'relative') {
                parent.style.position = 'relative';
            }

            inputTarget.addEventListener('input', this.onInputChange);

            inputTarget.addEventListener('focus', e => {
                this.onInputChange(e);
                this.setState({ isInputFocused: true });
            });

            inputTarget.addEventListener('blur', () => {
                inputTarget.style.border = '';
                this.setState({ isInputFocused: false });
            });

            this.setState({

                height: this.counterRef.current.getBoundingClientRect().height,
            });
        }
    }

    render() {
        const
            { maxLength, topSpacing, HTML, countBeyondMax } = this.props,
            { isInputFocused, length, height } = this.state,
            show = isInputFocused && height && this.isLimitExceeded(),
            className = cx(styles.container, { [styles.show]: show }),
            style: any = {
                // @ts-ignore
                top: -(height + topSpacing),
            };

        if (countBeyondMax) {
            delete style.top;
            style.paddingTop = 4;
        }

        return (
            <div
                className={className}
                style={style}
                ref={this.counterRef}
                data-testid="input-counter"
            >
                {HTML}
                <span>{length} / {maxLength}</span>
            </div>
        );
    }
}
