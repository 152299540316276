import { inactive, hover } from '../../../mappers/path';
import * as actionTypes from './actionTypes';
import inactiveTextShadowConfigurationDialogId from './inactiveTextShadowConfigurationDialogId';
import hoverTextShadowConfigurationDialogId from './hoverTextShadowConfigurationDialogId';
import { THEME_LINK_LIST } from "../../ThemeGlobalData/themeRules";

export const
    linkTabLabel = 'msg: common.gs.tab.link.label {Link}',
    Messages = {
        [inactive]: {
            label: 'msg: gs.link.inactive.label {Link State: Normal}',
            subLabel: 'msg: gs.link.inactive.sublabel {Use these additional styling options to further style the normal links.}' // eslint-disable-line
        },
        [hover]: {
            label: 'msg: gs.link.hover.label {Link State: Mouse over}',
            subLabel: 'msg: gs.link.hover.sublabel {These additional styling options are activated when you move your mouse over a link.}' // eslint-disable-line
        }
    },
    Labels = {
        [inactive]: 'msg: gs.link.inactive.preview {A normal link}',
        [hover]: 'msg: gs.link.hover.preview {Mouse Over}'
    },
    ThemeLabelObj = {
        [inactive]: THEME_LINK_LIST,
        [hover]: THEME_LINK_LIST
    },
    Actions = {
        toggleUnderlineAction: {
            [inactive]: actionTypes.GS_LINK_INACTIVE_TOGGLE_UNDERLINE,
            [hover]: actionTypes.GS_LINK_HOVER_TOGGLE_UNDERLINE
        },
        textColorChangeAction: {
            [inactive]: actionTypes.GS_LINK_INACTIVE_TEXT_COLOR_CHANGED,
            [hover]: actionTypes.GS_LINK_HOVER_TEXT_COLOR_CHANGED
        },
        textColorRemoveAction: {
            [inactive]: actionTypes.GS_LINK_INACTIVE_TEXT_COLOR_REMOVED,
            [hover]: actionTypes.GS_LINK_HOVER_TEXT_COLOR_REMOVED
        },
        highlightColorChangeAction: {
            [inactive]: actionTypes.GS_LINK_INACTIVE_HIGHLIGHT_COLOR_CHANGED,
            [hover]: actionTypes.GS_LINK_HOVER_HIGHLIGHT_COLOR_CHANGED
        },
        highlightColorRemoveAction: {
            [inactive]: actionTypes.GS_LINK_INACTIVE_HIGHLIGHT_COLOR_REMOVED,
            [hover]: actionTypes.GS_LINK_HOVER_HIGHLIGHT_COLOR_REMOVED
        },
        shadowRemoveAction: {
            [inactive]: actionTypes.GS_LINK_INACTIVE_SHADOW_REMOVED,
            [hover]: actionTypes.GS_LINK_HOVER_SHADOW_REMOVED
        },
        shadowColorChangeAction: {
            [inactive]: actionTypes.GS_LINK_INACTIVE_SHADOW_COLOR_CHANGED,
            [hover]: actionTypes.GS_LINK_HOVER_SHADOW_COLOR_CHANGED
        },
        blurRadiusChangeAction: {
            [inactive]: actionTypes.GS_LINK_INACTIVE_SHADOW_BLUR_RADIUS_CHANGED,
            [hover]: actionTypes.GS_LINK_HOVER_SHADOW_BLUR_RADIUS_CHANGED
        },
        shadowOffsetXChangeAction: {
            [inactive]: actionTypes.GS_LINK_INACTIVE_SHADOW_HORIZONTAL_OFFSET_CHANGED,
            [hover]: actionTypes.GS_LINK_HOVER_SHADOW_HORIZONTAL_OFFSET_CHANGED
        },
        shadowOffsetYChangeAction: {
            [inactive]: actionTypes.GS_LINK_INACTIVE_SHADOW_VERTICAL_OFFSET_CHANGED,
            [hover]: actionTypes.GS_LINK_HOVER_SHADOW_VERTICAL_OFFSET_CHANGED
        },
        textShadowConfigurationDialogId: {
            [inactive]: inactiveTextShadowConfigurationDialogId,
            [hover]: hoverTextShadowConfigurationDialogId
        }
    };
