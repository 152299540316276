export const KEYBOARD_KEY_CODES = {
    ESCAPE: 27,
    DELETE: 46,
    BACKSPACE: 8,
    TAB: 9,

    ENTER: 13,
    F2: 113,

    LEFT_ARROW: 37,
    UP_ARROW: 38,
    RIGHT_ARROW: 39,
    DOWN_ARROW: 40
};

export const
    ESCAPE = KEYBOARD_KEY_CODES.ESCAPE,
    DELETE = KEYBOARD_KEY_CODES.DELETE,
    BACKSPACE = KEYBOARD_KEY_CODES.BACKSPACE,
    TAB = KEYBOARD_KEY_CODES.TAB,

    ENTER = KEYBOARD_KEY_CODES.ENTER,
    F2 = KEYBOARD_KEY_CODES.F2,

    LEFT_ARROW = KEYBOARD_KEY_CODES.LEFT_ARROW,
    UP_ARROW = KEYBOARD_KEY_CODES.UP_ARROW,
    RIGHT_ARROW = KEYBOARD_KEY_CODES.RIGHT_ARROW,
    DOWN_ARROW = KEYBOARD_KEY_CODES.DOWN_ARROW;
