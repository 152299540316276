import React from "react";
import { useDispatch } from "react-redux";
import { Button, DialogContentText, DialogContent, Hidden, IconButton } from "@mui/material";
import cx from "classnames";

import { useIntl } from "../../../../../../wbtgen/src/view/intl";

import { closeDialogAction } from "../../Dialog/actions";
import { BasicDialog } from "../../Dialog/BasicDialog";
import type { BookingsTrialDialogProps } from "./types";

import imageStyles from '../../Images/imageStyle.css';
import { bookingsTrialLearnMoreClickAction, openBookingsWebsiteAction } from "./actions";
import { savePreferencesAction } from "../../Preferences/actions";
import { useStyles } from "./BookingsTrialDialogStyles";
import { CloseIcon } from "../../../common/icons";

export const BOOKINGS_TRIAL_DIALOG_ID = 'BOOKINGS_TRIAL_DIALOG_ID';
export const HIDE_BOOKINGS_TRIAL_DIALOG = 'hideBookingsTrialDialog';

export const BookingsTrialDialog = ({ config }: BookingsTrialDialogProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const classes = useStyles();

    const hideDialog = () => dispatch(savePreferencesAction({ [HIDE_BOOKINGS_TRIAL_DIALOG]: true }));

    const handleClose = () => {
        dispatch(closeDialogAction());
        hideDialog();
    };

    const handleLearnMore = () => {
        dispatch(openBookingsWebsiteAction());
        dispatch(bookingsTrialLearnMoreClickAction(`Booking trial popup`));
        handleClose();
    };

    return (
        <BasicDialog
            classes={{ paper: classes.paper, root: classes.root }}
            containerClass={classes.container}
            maxWidth="lg"
            dialogId={BOOKINGS_TRIAL_DIALOG_ID}
            showCloseIcon={false}
            {...config}
        >
            <Hidden mdUp>
                <IconButton
                    aria-label="close"
                    className={cx(classes.closeButton)}
                    onClick={handleClose}
                    data-testid="tooltip-close-icon"
                    size="large"
                    disableRipple
                >
                    <CloseIcon />
                </IconButton>
            </Hidden>
            <DialogContent className={classes.dialogContent}>
                <Hidden smUp>
                    <div className={cx(imageStyles.bookingsCalendar, imageStyles.regularImageStyles, classes.bookingsIllustrationImage)} />
                </Hidden>
                <Hidden mdUp smDown>
                    <div className={cx(imageStyles.bookingsIllustration, imageStyles.regularImageStyles, classes.bookingsIllustrationImage)} />
                </Hidden>
                <div className={classes.dialogContentInfo}>
                    <DialogContentText className={classes.title}>
                        {intl.msgJoint("msg: bookings.dialogHeader {Exclusive free bookings trial!}")}
                    </DialogContentText>

                    <DialogContentText className={classes.description} component="div">
                        {intl.msgJoint("msg: bookings.dialogContent {Do you offer services and want your customers to book on your website 24/7? We've partnered with Trafft to offer you a 3-month, free trial of their booking tool.}")}
                    </DialogContentText>

                    <div className={classes.dialogActions}>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.closeBtn}
                            onClick={handleClose}
                        >
                            {intl.msgJoint("msg: common.close {Close}")}
                        </Button>

                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.learnMoreBtn}
                            onClick={handleLearnMore}
                        >
                            {intl.msgJoint("msg: common.learnMore {Learn more}")}
                        </Button>
                    </div>
                </div>
                <Hidden mdDown>
                    <div className={cx(imageStyles.bookingsIllustration, imageStyles.regularImageStyles, classes.bookingsIllustrationImage)} />
                </Hidden>
            </DialogContent>
        </BasicDialog>
    );
};
