import React from 'react';

import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import { PropertiesButton } from '../../../../../view/common/Button/index';
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';

import Page from '../../../../PropertiesPanel/view/PropertiesPage';

import * as actionTypes from '../../actionTypes';
import * as OnClickActions from '../../constants/onClickActions';
import PpOnClickActionComboBox from '../../PpOnClickActionComboBox';

import * as pagesIds from '../pagesIds';
import pagesTitles from '../pagesTitles';

import type { Props } from '../flowTypes';

const
    id = pagesIds.ONCLICK,
    title = pagesTitles[id],
    manageLinksLabel = "msg: component.gallery.pp.onClick.manageLinks.title {Click \"Manage links\", then select each image and add a link.}", // eslint-disable-line max-len
    view = ({ selectedComponent: { onClickAction }, dispatch }: Props) => (
        <Page>
            <VerticalSpacer />
            <PpOnClickActionComboBox
                actionType={actionTypes.GALLERY_ON_CLICK_ACTION_CHANGED}
                value={onClickAction}
                dispatch={dispatch}
            />
            {
                onClickAction === OnClickActions.OPEN_LINK &&
                <div>
                    <VerticalSpacer />
                    <PropertyContainer label={manageLinksLabel}>
                        <PropertiesButton
                            text="msg: component.gallery.pp.onClick.manageLinks {Manage links}"
                            onClick={() => dispatch({ type: actionTypes.GALLERY_PP_OPEN_SORTING_WINDOW_CLICKED })}
                        />
                    </PropertyContainer>
                </div>
            }
        </Page>
    );

export { id, view, title };
