import { getDefaultReducerState } from '../../../redux/makeReducer/index';

import { reducer as VimeoReducer } from './Vimeo/reducer';
import { parseVimeoCode } from './Vimeo/utils';

import { reducer as GoogleMapsReducer } from './GoogleMaps/reducer';
import { parseGoogleMapsCode } from './GoogleMaps/utils';

import { reducer as SpotifyReducer } from './Spotify/reducer';
import { reducer as InstagramReducer } from './Instagram/reducer';
import { reducer as FacebookReducer } from './Facebook/reducer';
import { reducer as SoundcloudReducer } from './Soundcloud/reducer';
import { reducer as PaypalReducer } from './Paypal/reducer';
import { parseSpotifyCode } from './Spotify/utils';

import { reducer as TiktokReducer } from './Tiktok/reducer';
import { parseTiktokCode } from './Tiktok/utils/parseTiktokCode';

import { reducer as EventbriteReducer } from './Eventbrite/reducer';
import { parseEventbriteCode } from './Eventbrite/utils/parseEventbriteCode';

import { reducer as GoFundMeReducer } from './GoFundMe/reducer';
import { parseGoFundMe } from './GoFundMe/utils/parseGoFundMeCode';

import { reducer as TwitterReducer } from './Twitter/reducer';
import { parseTwitterCode } from './Twitter/utils/parseTwitterCode';

import { reducer as PinterestReducer } from './Pinterest/reducer';
import { parsePinterestCode } from './Pinterest/utils';

import { reducer as BingMapsReducer } from './BingMaps/reducer';
import { parseBingMapsCode } from './BingMaps/utils';

import { reducer as MailchimpReducer } from './Mailchimp/reducer';
import { parseMailchimpCode } from './Mailchimp/utils';

import { parseYoutubeCode } from '../Video/utils';
import YoutubeReducer from '../Video/reducer/index';

import type { Position } from '../../../redux/modules/flowTypes';
import { parseInstagramCode } from './Instagram/utils/parseInstagramCode';
import { parseFacebookCode } from './Facebook/utils/parseFacebookCode';
import { parseSoundcloudCode } from './Soundcloud/utils/parseSoundcloudCode';
import { parsePaypalCode } from './Paypal/utils/parsePaypalCode';

const makeComponent = (reducer, params, top) => {
    return {
        plaintext: false,
        selectedComponents: [{
            ...getDefaultReducerState(reducer),
            ...params,
            top,
        }]
    };
};

export const makeWidgetsFromClipboard = (plainText: string, scroll: Position) => {
    let widgetParsed = false;

    const top = scroll.y;

    if (!widgetParsed) {
        const vimeoParams = parseVimeoCode(plainText);
        if (Object.keys(vimeoParams).length) {
            widgetParsed = true;
            return makeComponent(VimeoReducer, vimeoParams, top);
        }
    }

    if (!widgetParsed) {
        const googleMapsParams = parseGoogleMapsCode(plainText);
        if (Object.keys(googleMapsParams).length) {
            widgetParsed = true;
            return makeComponent(GoogleMapsReducer, googleMapsParams, top);
        }
    }

    if (!widgetParsed) {
        const spotifyParams = parseSpotifyCode(plainText);
        if (Object.keys(spotifyParams).length) {
            widgetParsed = true;
            return makeComponent(SpotifyReducer, spotifyParams, top);
        }
    }

    if (!widgetParsed) {
        const soundcloudParams = parseSoundcloudCode(plainText);
        if (Object.keys(soundcloudParams).length) {
            widgetParsed = true;
            return makeComponent(SoundcloudReducer, soundcloudParams, top);
        }
    }

    if (!widgetParsed) {
        const instagramParams = parseInstagramCode(plainText);
        if (Object.keys(instagramParams).length) {
            widgetParsed = true;
            return makeComponent(InstagramReducer, instagramParams, top);
        }
    }

    if (!widgetParsed) {
        const facebookParams = parseFacebookCode(plainText);
        if (Object.keys(facebookParams).length) {
            widgetParsed = true;
            return makeComponent(FacebookReducer, facebookParams, top);
        }
    }

    if (!widgetParsed) {
        const tiktokParams = parseTiktokCode(plainText);
        if (Object.keys(tiktokParams).length) {
            widgetParsed = true;
            return makeComponent(TiktokReducer, tiktokParams, top);
        }
    }

    if (!widgetParsed) {
        const goFundMeParams = parseGoFundMe(plainText);
        if (Object.keys(goFundMeParams).length) {
            widgetParsed = true;
            return makeComponent(GoFundMeReducer, goFundMeParams, top);
        }
    }

    if (!widgetParsed) {
        const eventbriteParams = parseEventbriteCode(plainText);
        if (Object.keys(eventbriteParams).length) {
            widgetParsed = true;
            return makeComponent(EventbriteReducer, eventbriteParams, top);
        }
    }

    if (!widgetParsed) {
        const soundcloudParams = parseSoundcloudCode(plainText);
        if (Object.keys(soundcloudParams).length) {
            widgetParsed = true;
            return {
                plaintext: false,
                selectedComponents: [{
                    ...getDefaultReducerState(SoundcloudReducer),
                    ...soundcloudParams,
                    top: scroll.y
                }]
            };
        }
    }

    if (!widgetParsed) {
        const instagramParams = parseInstagramCode(plainText);
        if (Object.keys(instagramParams).length) {
            widgetParsed = true;
            return {
                plaintext: false,
                selectedComponents: [{
                    ...getDefaultReducerState(InstagramReducer),
                    ...instagramParams,
                    top: scroll.y
                }]
            };
        }
    }

    if (!widgetParsed) {
        const facebookParams = parseFacebookCode(plainText);
        if (Object.keys(facebookParams).length) {
            widgetParsed = true;
            return {
                plaintext: false,
                selectedComponents: [{
                    ...getDefaultReducerState(FacebookReducer),
                    ...facebookParams,
                    top: scroll.y
                }]
            };
        }
    }

    if (!widgetParsed) {
        const twitterParams = parseTwitterCode(plainText);
        if (Object.keys(twitterParams).length) {
            widgetParsed = true;
            return makeComponent(TwitterReducer, twitterParams, top);
        }
    }

    if (!widgetParsed) {
        const pinterestParams = parsePinterestCode(plainText);
        if (Object.keys(pinterestParams).length) {
            widgetParsed = true;
            return makeComponent(PinterestReducer, pinterestParams, top);
        }
    }

    if (!widgetParsed) {
        const paypalParams = parsePaypalCode(plainText);
        if (Object.keys(paypalParams).length) {
            widgetParsed = true;
            return {
                plaintext: false,
                selectedComponents: [{
                    ...getDefaultReducerState(PaypalReducer),
                    ...paypalParams,
                    top: scroll.y
                }]
            };
        }
    }

    if (!widgetParsed) {
        const youtubeParams = parseYoutubeCode(plainText);
        if (Object.keys(youtubeParams).length) {
            widgetParsed = true;
            return makeComponent(YoutubeReducer, youtubeParams, top);
        }
    }

    if (!widgetParsed) {
        const bingMapsParams = parseBingMapsCode(plainText);
        if (Object.keys(bingMapsParams).length) {
            widgetParsed = true;
            return makeComponent(BingMapsReducer, bingMapsParams, top);
        }
    }

    if (!widgetParsed) {
        const mailchimpParams = parseMailchimpCode(plainText);
        if (Object.keys(mailchimpParams).length) {
            widgetParsed = true;
            return makeComponent(MailchimpReducer, mailchimpParams, top);
        }
    }

    // Explicitly not adding Booking Widget (Trafft) here as it is only for B+E customers and we don't want other tiers customer to abuse this feature.

    return null;
};
