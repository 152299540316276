import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly, optional, when } from "../../../../epics/makeCondition";
import { selectedComponentIsInsideHeaderOrFooterVAT } from './valueActionType';
import {
    UserInteractionModeSelectedComponentNoGhostsSelector,
    ReceiveOnlyComponentsMap,
} from '../componentsEval/selectorActionTypes';
import { isSectionComponent, getHeaderSectionId, getFooterSectionId } from "../../../oneweb/Section/utils";
import { componentAttachmentsVAT } from "../componentAttachements/valueActionType";
import { getTopMostParentId } from "../componentAttachements/util";
import { COMPONENT_EDIT } from '../../../../redux/modules/children/workspace/actionTypes';
import { ATTACHMENTS_UPDATED_AFTER_UNDO } from "../componentAttachements/actionTypes";
import { CODE_UPDATE_PLACEMENT } from "../../../oneweb/Code/actionTypes";
import { getWebShopStripId } from '../../../ModernLayouts/layoutsData/webshopMHFDataUtils';

export type SelectedComponentIsInsideHeaderOrFooterEpic = {
    isInsideHeaderOrFooter: boolean
}

const defaultState = { isInsideHeaderOrFooter: false };
export const selectedComponentIsInsideHeaderOrFooterEpic = makeEpic({
    defaultState,
    valueActionType: selectedComponentIsInsideHeaderOrFooterVAT,
    updaters: [
        {
            conditions: [
                optional(UserInteractionModeSelectedComponentNoGhostsSelector),
                ReceiveOnlyComponentsMap,
                receiveOnly(componentAttachmentsVAT),
                optional(COMPONENT_EDIT),
                optional(ATTACHMENTS_UPDATED_AFTER_UNDO),
                optional(when(CODE_UPDATE_PLACEMENT, ({ payload }) => !payload)),
            ],
            reducer: ({ values: [{ selectedComponentNotGhost }, componentsMap, { attachments }] }) => {
                if (!selectedComponentNotGhost) {
                    return { state: defaultState };
                }
                if (isSectionComponent(selectedComponentNotGhost)) {
                    return { state: { isInsideHeaderOrFooter: false } };
                }
                const topParentId = getTopMostParentId(selectedComponentNotGhost.id, attachments),
                    isInsideHeader = getHeaderSectionId(componentsMap) === topParentId,
                    isInsideFooter = getFooterSectionId(componentsMap) === topParentId,
                    webShopFooterStripId = getWebShopStripId(componentsMap);
                return {
                    state: {
                        isInsideHeaderOrFooter: isInsideHeader || isInsideFooter,
                        webShopFooterStripId,
                        topParentId,
                        isInsideHeader,
                        isInsideFooter
                    }
                };
            }
        }
    ]
});
