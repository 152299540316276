import * as R from "ramda";
import { setToPath } from "../utils/ramdaEx";
import p from "../utils/pipePath";
import * as mp from "../mappers/path";
import { opacityToAlpha } from "../components/oneweb/Background/utils/index";
import type { Path } from "../mappers/path"; // eslint-disable-line
import type { Color } from '../mappers/flowTypes';
import type { BorderStyle } from "../components/presentational/BorderStyle/flowTypes";
import type { BorderWidth, BorderCorners } from "../mappers/border/flowTypes";
import type { ThemeColorTypes } from '../components/ThemeGlobalData/flowTypes';

export const
    setBorderStyle = R.curry((borderPath: Path, style: BorderStyle, data: any) =>
        setToPath(p(borderPath, mp.style), style, data)),
    setBorderColor = R.curry((borderPath: Path, color: Color, data: any) =>
        setToPath(p(borderPath, mp.color), color, data)),
    setBorderThemeColor = R.curry((borderPath: Path, color: ThemeColorTypes | null, data: any) =>
        setToPath(p(borderPath, 'themeColor'), color, data)),
    unsetBorderColor = R.curry((borderPath: Path, data: any) =>
        setToPath(p(borderPath, mp.color), null, data)),
    setBorderOpacity = R.curry((borderPath: Path, opacity: number, data: any) =>
        setToPath(p(borderPath, mp.colorAlpha), opacityToAlpha(opacity), data)),
    setBorderWidths = R.curry((borderPath: Path, widths: BorderWidth, data: any) =>
        setToPath(p(borderPath, mp.width), widths, data)),
    setBorderCorners = R.curry((borderPath: Path, corners: BorderCorners, data: any) =>
        setToPath(p(borderPath, mp.corners), corners, data));

