import React, { useLayoutEffect, useRef, useState } from "react";
import cx from "classnames";

import { renderAddressToStringSingleline } from "../../../../../../../../server/shared/address/utils";

import { Flex } from "../../../../../../view/reflexbox";
import { StaticMaps } from "../../../../../googleMaps/StaticMaps";
import { MapsGmbDataPreview } from "../../../../../googleMaps/MapsGmbDataPreview";
import { openingHoursWithGmbTimeToMsTime } from "../../../../../oneweb/OpeningHours/utils";
import type { Address } from "../../../../../googleMaps/googleMaps";
import type { GmbOpeningHour } from "../../../../../oneweb/OpeningHours/flowTypes";
import type { ContainerSizeType } from "../../../../../googleMaps/flowTypes";
import isTestEnv from "../../../../../../debug/isTestEnv";

import styles from "./styles.css";

type Props = {
    addressFields: Address;
    openingHours: {
        periods: GmbOpeningHour[];
    };
    phoneNumber: string;
    classes?: {
        root?: string;
        dataPreview?: string;
        mapContainer?: string;
    };
    mapSize?: ContainerSizeType;
};

export const GoogleMapView = (props: Props) => {
    const { addressFields, openingHours, phoneNumber, mapSize: mapSizeProp, classes: classesProp, ...otherProps } = props;
    const defaultMapSize = mapSizeProp || { width: 300, height: 300 };

    const [mapSize, setMapSize] = useState<ContainerSizeType>(defaultMapSize);
    const mapContainerRef = useRef<HTMLDivElement | null>(null);

    useLayoutEffect(() => {
        if (!isTestEnv() && mapContainerRef.current && mapContainerRef.current.clientWidth !== mapSize.width) {
            setMapSize({ width: mapContainerRef.current.clientWidth, height: mapSize.height });
        }
    }, [mapSize.width, mapSize.height]);

    return (
        <Flex className={cx(styles.container, classesProp?.root)} {...otherProps}>
            <StaticMaps
                ref={mapContainerRef}
                containerSize={mapSize}
                staticMapInputs={{
                    latLng: addressFields.addressLocation ? [addressFields.addressLocation.lat, addressFields.addressLocation.lng] : [0, 0],
                    width: mapSize.width,
                    height: mapSize.height,
                    zoom: 16,
                    address: renderAddressToStringSingleline({
                        addressName: addressFields.addressName || "",
                        addressStreetAddress: addressFields.addressStreetAddress || "",
                        addressCity: addressFields.addressCity || "",
                        addressZip: addressFields.addressZip || "",
                        addressArea: addressFields.addressArea || "",
                        addressCountryCode: addressFields.addressCountryCode || ""
                    })
                }}
                className={classesProp?.mapContainer}
            />

            <MapsGmbDataPreview
                addressFields={addressFields}
                openingHours={openingHoursWithGmbTimeToMsTime(openingHours.periods)}
                phoneNumber={phoneNumber}
                className={classesProp?.dataPreview}
            />
        </Flex>
    );
};
