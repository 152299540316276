import { makeReactContext } from "../../../utils/react/context/makeReactContext";

type ThemeHistoryDataType = {
    show: boolean;
};
const ThemeHistoryInitState: ThemeHistoryDataType = {
    show: false
};
export const { Context: ThemeHistoryContext, useContext: useThemeHistoryContext } = makeReactContext(
    /*::<ThemeHistoryDataType>*/
    ThemeHistoryInitState
);
