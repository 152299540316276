import { memoMaxOne } from "../../../../../utils/memo";
import isGhost from "../../../oneweb/Code/isGhost";
import type { ComponentsMap, AnyComponent } from "../../../../redux/modules/children/workspace/flowTypes";
import type { Attachments, OrderIndexObj, CreateAttachmentsType } from "./flowTypes";
import { getCmpsTreeByCmpsMap, prepareCmpsForTree, prepareItem } from "../relations/rBushTree";
import type { RBushTreeCmp } from "../relations/rBushTree";
import { ContainerKinds } from "../../../../utils/containerKinds";
import { isHoverBoxKind, isSectionKind } from "../../../oneweb/componentKinds";
import TEXT from "../../../oneweb/Text/kind";
import { getWrappedComponentIds } from "../../../../utils/htmlWriter/html/render/wrapper/wrapperNodeUtils";
import {
    isComponentKindAllowedInHoverBox,
    getHiddenComponentsForHoverBox,
    getNearestParentHoverBoxCmp
} from "../../../oneweb/HoverBox/utils";
import { getComponentsMapNoGhosts, componentIsNotGhost } from "../../../oneweb/Code/getComponentsMapNoGhosts";

export const
    MinLeft = -20000,
    MaxRight = 20000,
    simpleTopSorter = (a: AnyComponent, b: AnyComponent) => (a.top - b.top),
    orderIndexSorter = (a: OrderIndexObj, b: OrderIndexObj) => (b.orderIndex - a.orderIndex),
    orderIndexSorterAsc = (a: OrderIndexObj, b: OrderIndexObj) => (a.orderIndex - b.orderIndex),
    isChildCompletelyInsideParent = (child: RBushTreeCmp, parent: RBushTreeCmp) => (
        child.minX >= parent.minX &&
        child.maxX <= parent.maxX &&
        child.minY >= parent.minY &&
        child.maxY <= parent.maxY
    ),
    isChildHalfInsideParent = (child: RBushTreeCmp, parent: RBushTreeCmp) => {
        const childMidX = (child.minX + child.maxX) / 2,
            childMidY = (child.minY + child.maxY) / 2;
        return (
            (childMidY >= parent.minY && childMidY < parent.maxY) &&
            (childMidX >= parent.minX && childMidX < parent.maxX)
        );
    },
    isChildInsideParent = (cmpData: RBushTreeCmp, parentCmp: RBushTreeCmp) => {
        const parentKind = parentCmp.kind,
            childkind = cmpData.kind;
        if (isSectionKind(parentKind)) {
            return isChildHalfInsideParent(cmpData, parentCmp);
        }
        if (isHoverBoxKind(parentKind) && !isComponentKindAllowedInHoverBox(childkind)) {
            return false;
        }
        return isChildCompletelyInsideParent(cmpData, parentCmp);
    },
    removeDuplicatesFromAnArrayOfStrings = (arr: Array<string>) => {
        let Obj = {};
        return arr.reduce((acc: Array<string>, item) => {
            if (!Obj[item]) {
                acc.push(item);
                Obj[item] = true;
            }
            return acc;
        }, []);
    },
    isAnyOfParentTemplate = (cmpData: RBushTreeCmp, topParents: Array<RBushTreeCmp>) => {
        if (!topParents.length) {
            return false;
        }
        return topParents.some(parentCmp => isChildInsideParent(cmpData, parentCmp));
    },
    getAttachedContainerCmpId = (cmpId: string, attachments: Attachments) => {
        const containerIds = Object.keys(attachments);
        for (let i = 0; i < containerIds.length; i++) {
            const childAttachments = attachments[containerIds[i]];
            if (childAttachments.includes(cmpId)) {
                return containerIds[i];
            }
        }
        return null;
    },
    findParentHoverBoxFromIntersections = (cmp: AnyComponent, topParents: Array<RBushTreeCmp>) => {
        if (!topParents.length) {
            return false;
        }
        return topParents.find(parentCmp => isHoverBoxKind(parentCmp.kind));
    },
    findParentHoverBoxFromAttachments = (cmp: AnyComponent, componentsMap: ComponentsMap, attachments: Attachments = {}) => {
        const parentContainerId = getAttachedContainerCmpId(cmp.id, attachments);
        if (!parentContainerId) {
            return null;
        }
        const parentContainer = componentsMap[parentContainerId];
        if (isHoverBoxKind(parentContainer.kind)) {
            return parentContainer;
        }
        return findParentHoverBoxFromAttachments(parentContainer, componentsMap, attachments);
    },
    getIfAttachedBasedOnHoverMode = (component: AnyComponent, parent: RBushTreeCmp,
        topParents: Array<RBushTreeCmp>, componentsMap: ComponentsMap, attachments: Attachments = {}) => {
        if (isHoverBoxKind(parent.kind)) {
            return true;
        }
        const topParentHoverBox = Object.keys(attachments).length === 0 ?
            findParentHoverBoxFromIntersections(parent, topParents) :
            findParentHoverBoxFromAttachments(parent, componentsMap, attachments);
        if (!topParentHoverBox) {
            return true;
        }
        const cmpOnHoverShow = component && component.onHover && component.onHover.show;
        const parentOnHoverShow = componentsMap[parent.id].onHover && componentsMap[parent.id].onHover.show;
        if (cmpOnHoverShow === true) {
            return !!parentOnHoverShow;
        }
        if (cmpOnHoverShow === false) {
            return !parentOnHoverShow;
        }
        // $FlowFixMe
        const hoverMode = componentsMap[topParentHoverBox.id].hoverMode;
        return !((hoverMode && !parentOnHoverShow) || (!hoverMode && parentOnHoverShow));
    },
    processAttachments = (
        tree: any,
        cmpData: RBushTreeCmp,
        componentsMap: ComponentsMap,
        attachmentsOld?: Attachments,
    ) => {
        const
            attachments = attachmentsOld ? { ...attachmentsOld } : {},
            { id, kind, orderIndex, inTemplate, isStickyToHeader } = cmpData,
            component = componentsMap[id],
            isWrapped = component && component.wrap;
        if (isWrapped || isSectionKind(kind)) {
            return attachments;
        }
        const intersections = tree.search(cmpData)
            .filter(item => id !== item.id)
            .sort(orderIndexSorter);
        for (let i = 0; i < intersections.length; i++) {
            const parent = intersections[i],
                topParents = intersections.slice(i + 1, intersections.length);
            if (
                parent.orderIndex < orderIndex &&
                ContainerKinds[parent.kind]
            ) {
                const containerId = parent.id;
                let isAttached = isChildInsideParent(cmpData, parent);
                if (isAttached) {
                    if (isStickyToHeader) {
                        isAttached = false;
                    } else if (inTemplate && !parent.inTemplate && isAnyOfParentTemplate(cmpData, topParents)) {
                        isAttached = false;
                    } else if (!isComponentKindAllowedInHoverBox(cmpData.kind) && topParents.some(parent => isHoverBoxKind(parent.kind))) {
                        isAttached = false;
                    } else {
                        isAttached = getIfAttachedBasedOnHoverMode(component, parent, topParents, componentsMap, attachments);
                    }
                }
                if (isAttached) {
                    attachments[containerId] = (!attachments[containerId]) ? [] : [...attachments[containerId]];
                    attachments[containerId].push(id);
                    if (kind === TEXT && component) {
                        // $FlowFixMe
                        attachments[containerId].push(...getWrappedComponentIds(component.content));
                    }
                    attachments[containerId] = removeDuplicatesFromAnArrayOfStrings(attachments[containerId]);
                    break;
                }
            }
        }
        return attachments;
    },
    getCmpsWithWrapParent = (cmps: Array<AnyComponent>, componentsMap: ComponentsMap): Array<AnyComponent> => {
        const affectedCmps = [...cmps].filter(componentIsNotGhost);
        cmps.forEach(cmp => {
            const { wrap, relIn } = cmp;
            if (wrap && relIn && componentsMap[relIn.id]) {
                affectedCmps.push(componentsMap[relIn.id]);
            }
        });
        return affectedCmps;
    },
    createAttachments = ({
        componentsMap,
        newCmps,
        attachmentsOld,
    }: CreateAttachmentsType) => {
        let componentsMapNoGhosts = getComponentsMapNoGhosts(componentsMap);
        const { tree, cmpsData } = getCmpsTreeByCmpsMap(componentsMapNoGhosts, MinLeft, MaxRight),
            cmpsDataToProcess = newCmps ? prepareCmpsForTree(getCmpsWithWrapParent(newCmps, componentsMapNoGhosts),
                MinLeft, MaxRight) : cmpsData;
        let attachments: Attachments = attachmentsOld ? { ...attachmentsOld } : {};
        cmpsDataToProcess
            .sort(orderIndexSorterAsc)
            .forEach((cmpData) => {
                attachments = processAttachments(tree, cmpData, componentsMapNoGhosts, attachments);
            });
        return attachments;
    },
    getCmps = (componentsMap: ComponentsMap): Array<AnyComponent> =>
        Object.keys(componentsMap).map(id => componentsMap[id]),
    getCmpsMap = (cmps: Array<AnyComponent>): ComponentsMap => {
        return cmps.reduce((acc, cmp) => {
            acc[cmp.id] = cmp;
            return acc;
        }, {});
    },
    getNonGhostCmps = (componentsMap: ComponentsMap): Array<AnyComponent> =>
        getCmps(componentsMap).filter(cmp => !isGhost(cmp)),
    getAllAttachmentsForCmpIds = (attachments: Attachments, cmpIds: Array<string> = [],
        selectedComponents: Array<string> = []) => {
        let alreadyProcessed = {}, ids = cmpIds.slice(), parentsLength = cmpIds.length, i, result: Array<string> = [];
        for (i = 0; i < ids.length; i++) {
            if (!alreadyProcessed[ids[i]]) {
                if (i >= parentsLength && selectedComponents.indexOf(ids[i]) === -1) {
                    result.push(ids[i]);
                }
                alreadyProcessed[ids[i]] = true;
                ids.push(...(attachments[ids[i]] || []));
            }
        }
        return result;
    },
    getAllAttachmentsCmpsMapForCmpIds = (componentsMap: ComponentsMap, attachments: Attachments,
        cmpIds: Array<string> = [], selectedComponents: Array<string> = []) => {
        const attachedIds = getAllAttachmentsForCmpIds(attachments, cmpIds, selectedComponents);
        if (attachedIds.length) {
            return attachedIds.reduce((acc, id) => {
                acc[id] = componentsMap[id];
                return acc;
            }, {});
        }
        return null;
    },
    getCmpIdsWithWrappedCmps = (cmpIds: Array<string>, componentsMap: ComponentsMap) => {
        let allCmpIds = cmpIds.slice();
        cmpIds.forEach(id => {
            const cmp = componentsMap[id];
            if (!cmp) {
                return;
            }
            const { kind, wrap, relIn } = cmp;
            if (wrap && relIn) {
                allCmpIds.push(relIn.id, ...getCmpIdsWithWrappedCmps([relIn.id], componentsMap));
            }
            if (kind === TEXT) {
                // $FlowFixMe
                allCmpIds.push(...getWrappedComponentIds(cmp.content));
            }
        });
        return removeDuplicatesFromAnArrayOfStrings(allCmpIds);
    },
    deleteCmpIdsFromAttachments = (attachmentsOld: Attachments, cmpIdsToDel: Array<string>,
        componentsMap: ComponentsMap, deleteChild: boolean = true) => {
        let attachments = { ...attachmentsOld }, changed = false;
        const cmpIds = getCmpIdsWithWrappedCmps(cmpIdsToDel, componentsMap);
        Object.keys(attachments).forEach(id => {
            if (deleteChild && cmpIds.includes(id)) {
                changed = true;
                delete attachments[id];
            }
            if (attachments[id]) {
                const attachedIds: Array<string> = [];
                attachments[id].forEach(cId => {
                    if (!cmpIds.includes(cId)) {
                        attachedIds.push(cId);
                    }
                });
                if (attachedIds.length !== attachments[id] as unknown as number) {
                    changed = true;
                }
                if (attachedIds.length) {
                    attachments[id] = attachedIds;
                } else {
                    delete attachments[id];
                }
            }
        });
        return {
            attachments: changed ? attachments : attachmentsOld,
            changed
        };
    }, isAttachedToComponent = (cmpId: string, attachToCmpID: string, attachments: Attachments) =>
        ((attachments[attachToCmpID] || []).includes(cmpId)),
    getSiblingAttachedComponentsIds = (cmpId: string, attachments: Attachments) => {
        const attachedContainerId = getAttachedContainerCmpId(cmpId, attachments);
        return attachedContainerId ? attachments[attachedContainerId] : [];
    },
    getSiblingAttachedComponentsMap = (cmpId: string, attachments: Attachments, componentsMap: ComponentsMap) => {
        const closestHoverBoxId = getNearestParentHoverBoxCmp(cmpId, attachments, componentsMap);
        let siblingCmpIds = getSiblingAttachedComponentsIds(cmpId, attachments);
        if (closestHoverBoxId) {
            const hiddenComponentIds = getHiddenComponentsForHoverBox(closestHoverBoxId, attachments, componentsMap);
            siblingCmpIds = siblingCmpIds.filter(id => !hiddenComponentIds.includes(id));
        }
        if (siblingCmpIds.length) {
            const cmp = componentsMap[cmpId];
            if (cmp.kind === TEXT) {
                // $FlowFixMe
                const wrappedIds = getWrappedComponentIds(cmp.content);
                siblingCmpIds = siblingCmpIds.filter(id => !wrappedIds.includes(id));
            }
            return siblingCmpIds.reduce((acc, id) => {
                acc[id] = componentsMap[id];
                return acc;
            }, {});
        }
        return null;
    },
    areAttachmentsEqual = (oldAttachments: Attachments, newAttachments: Attachments) => {
        const oldAttachmentKeys = Object.keys(oldAttachments),
            newAttachmentKeys = Object.keys(newAttachments);
        if (oldAttachmentKeys.length !== newAttachmentKeys.length) {
            return false;
        }
        for (let i = 0; i < oldAttachmentKeys.length; i++) {
            const key = oldAttachmentKeys[i];
            if (
                !newAttachments[key] ||
                newAttachments[key].length !== oldAttachments[key].length ||
                newAttachments[key].some(newId => !oldAttachments[key].find(oldId => oldId === newId))
            ) {
                return false;
            }
        }
        return true;
    },
    updateAttachments = (attachments: Attachments, componentsMap: ComponentsMap) => {
        let newAttachments = { ...attachments };
        Object.keys(attachments).forEach(parentId => {
            newAttachments[parentId] = attachments[parentId].slice(0);
            attachments[parentId].forEach(childId => {
                const childCmpData = prepareItem(componentsMap[childId], MinLeft, MaxRight),
                    parentCmp = componentsMap[parentId],
                    parentCmpData = prepareItem(parentCmp, MinLeft, MaxRight),
                    isAttached = isChildInsideParent(childCmpData, parentCmpData);
                if (!isAttached) {
                    const index = newAttachments[parentId].findIndex(cId => cId === childId);
                    newAttachments[parentId].splice(index, 1);
                }
            });
            if (!newAttachments[parentId].length) {
                delete newAttachments[parentId];
            }
        });
        return newAttachments;
    },
    getChildToParentMap = memoMaxOne((attachments: Attachments): Object => {
        let map = {};
        Object.keys(attachments).forEach(parentId => {
            attachments[parentId].forEach(childId => {
                map[childId] = parentId;
            });
        });
        return map;
    }),
    getTopMostParentId = (cmpId: string, attachments: Attachments) => {
        const childToParentMap = getChildToParentMap(attachments),
            getTopParent = (cmpId: string): string => {
                const parent = childToParentMap[cmpId];
                if (!parent) {
                    return cmpId;
                }
                return getTopParent(parent);
            };
        return getTopParent(cmpId);
    };

