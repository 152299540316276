import React from 'react';
import cx from 'classnames';
import styles from './CheckBox.css';
import type { CheckBoxPropTypes } from "./flowTypes";
import { injectIntl } from "../../intl/index";

export default injectIntl((
    { checkboxClass, isChecked, onClick, isGreen, disabled, error, showWhiteCheckMark, checkboxProps }: CheckBoxPropTypes
) => (
    <span
        className={cx(
            {
                [styles.bgGreen]: isGreen,
                [styles.bgBlue]: !isGreen,
                [styles.checked]: isChecked,
                [styles.disabled]: disabled,
                [styles.error]: error,
                [styles.whiteCheckMark]: showWhiteCheckMark
            },
            checkboxClass,
        )}
        onClick={onClick}
        {...(checkboxProps || {})}
    />
));
