
import * as componentReducers from "../componentReducers";
import { factoryGalleryStylesReducer } from "./factoryGalleryStylesReducer";

const
    makeGalleryBorderReducer = factoryGalleryStylesReducer('imageStyle');
export const
    typeReducer = makeGalleryBorderReducer(componentReducers.makeComponentStyleBorderStyleReducer()),
    widthReducer = makeGalleryBorderReducer(componentReducers.makeComponentStyleBorderWidthReducer()),
    colorReducer = makeGalleryBorderReducer(componentReducers.makeComponentStyleBorderColorReducer()),
    opacityReducer = makeGalleryBorderReducer(componentReducers.makeComponentStyleBorderOpacityReducer()),
    cornerReducer = makeGalleryBorderReducer(componentReducers.makeComponentStyleBorderCornersReducer());
