import React from "react";
export const SvgTumblrCircleFillMulticolor = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <circle cx={24} cy={24} r={24} fill="#35465c" />
            <path
                fill="#ffffff"
                d="M25.308 22.614v4.763c0 1.21-.015 1.906.113 2.248.127.341.446.695.793.9.461.276.988.414 1.58.414 1.056 0 1.679-.14 2.722-.825v3.132a11.91 11.91 0 01-2.387.833c-.722.167-1.503.252-2.34.252-.954 0-1.516-.12-2.247-.36a5.465 5.465 0 01-1.872-1.027c-.518-.446-.875-.92-1.075-1.421-.2-.501-.3-1.229-.3-2.182v-7.305H17.5v-2.95c.818-.265 1.732-.646 2.315-1.142a5.704 5.704 0 001.405-1.789c.354-.695.597-1.58.73-2.655h3.358v5.208h5.208v3.906h-5.208z"
            />
        </g>
    </svg>
);
