import * as PagesIds from "./ids";
import { OPENING_HOURS_KIND } from "../../kind";
import { ComponentNames } from "../../../constants";
import { MOBILE_VIEW, MobileViewTitle } from "../../../../PropertiesPanel/view/MobileView/constants";

export default {
    [PagesIds.MAIN]: ComponentNames[OPENING_HOURS_KIND],
    [PagesIds.FORMAT]: "msg: common.format {Format}",
    [PagesIds.STYLE]: "msg: common.style {Style}",
    [MOBILE_VIEW]: MobileViewTitle
};
