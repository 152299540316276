import React from 'react';
import WidgetsKind from '../../oneweb/Widgets/kind';
import { sectionTitleHeight } from '../view/Inserter.css';
import { ComponentNames } from '../../oneweb/constants';
import { Content } from '../view/widgets/Content';
import { Header } from '../view/widgets/Header';
import type { ExtendedPanelContentProps } from '../view/inserter';
import * as Icons from './Icons.css';

export default {
    kind: WidgetsKind,
    shortCut: {
        iconClassName: Icons.widgets,
        text: ComponentNames[WidgetsKind]
    },
    extendedPanelData: {
        headerHeight: sectionTitleHeight,
        title: <Header />,
        content: (props: ExtendedPanelContentProps) => <Content {...props} />
    }
};
