// @ts-nocheck
import * as R from 'ramda';
import { makeGetHtmlAttrs } from '../utils/index';
import { textMapper } from '../mappers/index';
import { PARA_OVERRIDE_STYLES, LIST_OVERRIDE_STYLES } from '../../parser/constants';

import type { Stylesheet, Node } from '../../flowTypes';

const getOverrideStyles = node => {
    const overrideStyles = node.tag === 'p' ? PARA_OVERRIDE_STYLES : LIST_OVERRIDE_STYLES;
    return R.intersection(Object.keys(node.styles), overrideStyles);
};

const hasChildrenWithGlobalStyle = node => {
    const childNodes = node.childNodes || [];
    return childNodes.some(childNode => {
        return !!childNode.ref || hasChildrenWithGlobalStyle(childNode);
    });
};

const hasStyles = (node, overrideStyles = getOverrideStyles(node)) => (
    overrideStyles.some(key => {
        const style = node.styles[key];
        return style !== null && typeof style !== 'undefined';
    })
);

const hasChildrenWithStyle = (node, overrideStyles = getOverrideStyles(node)) => (
    (node.childNodes || []).some(childNode => (
        hasStyles(childNode, overrideStyles) || hasChildrenWithStyle(childNode, overrideStyles)
    ))
);

const shouldOverwriteParentStyles = node => {
    const parentNode = node.parent;

    return (
        // Should be direct child of a content block
        parentNode && (parentNode.tag === 'p' || parentNode.tag === 'li') && !node.ref &&
        (
            // Parent has global style and node doesn't and parent has a childNode with globalStyle
            (parentNode.ref && hasChildrenWithGlobalStyle(parentNode)) ||
            (hasStyles(parentNode) && hasChildrenWithStyle(parentNode))
        )
    );
};

export default (defaultGlobalStyles: Array<Stylesheet>, editMode: boolean, shouldKeepSpan: boolean = false) => {
    const getHtmlAttrs = makeGetHtmlAttrs(defaultGlobalStyles, editMode);

    return (node: Node, content: string): string => {
        const shouldHaveDefaultClass = shouldOverwriteParentStyles(node);

        const {
            tag,
            single,
            text,
            isFirstChild
        } = node;

        const {
            classAttr,
            styleAttr,
            linkAttr,
            targetAttr
        } = getHtmlAttrs(node, shouldHaveDefaultClass);

        if (tag) {
            if (single) {
                return `<${tag}>`;
            } else {
                return `<${tag}${linkAttr}${targetAttr}${classAttr}${styleAttr}>${content}</${tag}>`;
            }
        }

        const textContent = textMapper(text, isFirstChild);
        if (node.parent && ['span', 'sup', 'sub'].indexOf(node.parent.tag) > -1) {
            return textContent;
        }

        if (shouldHaveDefaultClass && textContent) {
            return `<span${classAttr}>${textContent}</span>`;
        }

        // TODO WBTGEN-7554 Remove keep-span style from text/table content fields (Migration)
        if (shouldKeepSpan) {
            return `<span>${textContent}</span>`;
        }

        return textContent;
    };
};
