import { DEFAULT_WIDTH, DEFAULT_HEIGHT, PostType, DEFAULT_PROFILE_WIDTH, DEFAULT_PROFILE_HEIGHT } from '../constants';
import { isTiktokLinkValid } from './linkValidator';
import { parseTiktokPostCode } from "./parseTiktokPostCode";
import { type TiktokComponent } from '../types';

export const isVideo = (code: string): boolean => {
    return /https:\/\/www.tiktok.com\/@(\w.*)\/video\/(\w+)/.test(code);
};

export const getMediaId = (link) => {
    return isVideo(link) ? `v2/${link.split('/')[5].split('?')[0]}` : `${link.split('/')[3]}`;
};

export const parseTiktokCode = (code: string): TiktokComponent => {
    let params = <TiktokComponent>{};
    let type = '';
    if (code.length) {
        if (isTiktokLinkValid(code)) {
            type = isVideo(code) ? PostType.VIDEO : PostType.PROFILE;
            params.link = code;
        } else {
            params = parseTiktokPostCode(code);
            type = isVideo(params.link || '') ? PostType.VIDEO : PostType.PROFILE;
        }
        params.height = type === PostType.VIDEO ? DEFAULT_HEIGHT : DEFAULT_PROFILE_HEIGHT;
        params.width = type === PostType.VIDEO ? DEFAULT_WIDTH : DEFAULT_PROFILE_WIDTH;
    }
    return params.link ? { ...params, } : {};
};
