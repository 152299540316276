import { chargesForUpgradeType } from "./flowTypes";

const getPlanPricePerMonth = (chargesForUpgrade: chargesForUpgradeType) => {
    const { currencyCode, price, currencyFactor } = chargesForUpgrade,
        pricePerMonth = `${currencyCode} ${(price / currencyFactor).toFixed(2)}`; // Note that the space character here is a no-breaking space character
    return pricePerMonth;
};

const getPlanPricePerMonthWithoutCurrency = (chargesForUpgrade: chargesForUpgradeType) => {
    const { price, currencyFactor } = chargesForUpgrade,
        pricePerMonth = `${(price / currencyFactor).toFixed(2)}`;
    return pricePerMonth;
};

export {
    getPlanPricePerMonth,
    getPlanPricePerMonthWithoutCurrency
};
