import { makeCssMapper, makeClassNameMapper } from '../mappers/index';
import type { Stylesheet } from '../../flowTypes';
import Node from '../../parser/tree/Node';

const isNotEmptyValueObject = styles => Object.keys(styles)
    .filter(key => typeof styles[key] !== 'undefined' && styles[key] !== null).length > 0;

export default (defaultGlobalStyles: Stylesheet[], editMode: boolean): Function => {
    const classMapper = makeClassNameMapper(defaultGlobalStyles, editMode);
    const cssMapper = makeCssMapper(defaultGlobalStyles);
    return (node: Node, shouldHaveDefaultClass: boolean): any => {
        const {
            styles,
            href,
            target
        } = node;

        const classValue = classMapper(node, shouldHaveDefaultClass);
        const classAttr = classValue ? ` class="${classValue}"` : '';
        const styleAttr = isNotEmptyValueObject(styles) ? ` style="${cssMapper(node)}"` : '';
        const linkAttr = href ? ` href="${href}"` : '';
        const targetAttr = target ? ` target="${target}"` : '';

        return {
            classAttr,
            styleAttr,
            linkAttr,
            targetAttr
        };
    };
};
