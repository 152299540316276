import * as actionTypes from '../actions';
import { makeValueReducer } from "../../../../../src/redux/makeReducer/index";
import type { SignupPagePropTypes } from "../../types";
import {
    USERNAME_EXISTS_EXCEPTION,
    USER_DISABLED_EXCEPTION,
    USER_LAMBDA_VALIDATION_EXCEPTION
} from "../../../../constants";

export const defaultSignUpState = {
    isSignupModalOpen: false,
    loading: false,
    error: null,
    email: '',
    password: '',
    userExistsError: false,
    invalidCaptchaError: false,
    invalidPasswordErrorMessage: false,
    invalidEmailErrorMessage: false,
    termsNotCheckedError: false,
    termsAgreement: false,
    showPasswordEnabled: false,
    clientIPAddress: '',
    mailCampaignAgreement: false
};

export const signupReducer = makeValueReducer({
    defaultState: defaultSignUpState,
    handleActions: {
        [actionTypes.DEMO_UPDATE_SIGNUP_EMAIL_VALUE_ACTION]: (state: SignupPagePropTypes, action: Record<string, any>) => {
            return {
                ...state,
                email: action.payload.emailValue
            };
        },
        [actionTypes.DEMO_UPDATE_SIGNUP_PASSWORD_VALUE_ACTION]: (state: SignupPagePropTypes, action: Record<string, any>) => {
            return {
                ...state,
                password: action.payload.passwordValue
            };
        },
        [actionTypes.DEMO_TOGGLE_TERMS_CONDITION_CHECKBOX_ACTION]: (state: SignupPagePropTypes) => {
            return {
                ...state,
                termsAgreement: !state.termsAgreement
            };
        },
        [actionTypes.DEMO_MAIL_CAMPAIGN_AGREEMENT_CHECKBOX_ACTION]: (state: SignupPagePropTypes) => {
            return {
                ...state,
                mailCampaignAgreement: !state.mailCampaignAgreement
            };
        },
        [actionTypes.DEMO_TOGGLE_SIGNUP_SHOW_PASSWORD_VALUE_ACTION]: (state: SignupPagePropTypes) => {
            return {
                ...state,
                showPasswordEnabled: !state.showPasswordEnabled
            };
        },
        [actionTypes.DEMO_SIGNUP_CLEAN_ALL_ERRORS_ACTION]: (state: SignupPagePropTypes) => {
            return {
                ...state,
                error: null,
                invalidPasswordErrorMessage: false,
                invalidEmailErrorMessage: false,
                termsNotCheckedError: false,
                invalidCaptchaError: false,
                userExistsError: false
            };
        },
        [actionTypes.DEMO_SIGNUP_SHOW_INVALID_EMAIL_ERROR_ACTION]: (state: SignupPagePropTypes) => {
            return {
                ...state,
                invalidEmailErrorMessage: true
            };
        },
        [actionTypes.DEMO_SIGNUP_SHOW_INVALID_PASSWORD_ERROR_ACTION]: (state: SignupPagePropTypes) => {
            return {
                ...state,
                invalidPasswordErrorMessage: true
            };
        },
        [actionTypes.DEMO_SIGNUP_TERMS_NOT_CHECKED_ERROR_ACTION]: (state: SignupPagePropTypes) => {
            return {
                ...state,
                termsNotCheckedError: true
            };
        },
        [actionTypes.DEMO_SIGNUP_FAILURE_ACTION]: (state: SignupPagePropTypes, action: Record<string, any>) => {
            const newState = state;
            if (action.payload.code === USERNAME_EXISTS_EXCEPTION) {
                newState.userExistsError = true;
            } else if (action.payload.code === USER_DISABLED_EXCEPTION) {
                newState.error = action.payload.message;
            } else if (action.payload.code === USER_LAMBDA_VALIDATION_EXCEPTION
                && action.payload.message.indexOf('Cannot authenticate user because of invalid captcha') > -1) {
                newState.invalidCaptchaError = true;
            } else {
                newState.error = action.payload.message;
            }
            return {
                ...newState,
                loading: false
            };
        },
        [actionTypes.DEMO_SIGNUP_LOADING_ACTION]: (state: SignupPagePropTypes) => {
            return {
                ...state,
                loading: true
            };
        },
        [actionTypes.DEMO_GET_CLIENT_IP_SUCCESS_ACTION]: (state: SignupPagePropTypes, action: Record<string, any>) => {
            return {
                ...state,
                clientIPAddress: action.payload.ipAddress,
            };
        },
        [actionTypes.DEMO_GET_CLIENT_IP_FAILURE_ACTION]: (state: SignupPagePropTypes) => {
            return {
                ...state,
                clientIPAddress: '',
            };
        },
        [actionTypes.DEMO_CLEAN_UP_SIGNUP_SCREEN_ACTIONS]: () => {
            return defaultSignUpState;
        },
        [actionTypes.DEMO_OPEN_SIGNUP_MODAL_ACTION]: (state: SignupPagePropTypes) => {
            return {
                ...state,
                isSignupModalOpen: true
            };
        },
        [actionTypes.DEMO_CLOSE_SIGNUP_MODAL_ACTION]: (state: SignupPagePropTypes) => {
            return {
                ...state,
                isSignupModalOpen: false
            };
        }
    }
});
