import { DataPageTemplate } from "../../../../../dal/model/index";
import type { TemplateComponent } from "../../../oneweb/Template/flowTypes";
import SectionKind from "../../../oneweb/Section/kind";
import { to as toTemplateDataMapper } from "../../../../../dal/pageMapAdapter/mappers/Template/index";
import { objectsAreDiff, objectCollectionsAreDiff } from "../../../../utils/objectsDiffChecks";
import { LINK_ACTION_TYPE } from "../../../../../dal/constants";

const TemplateDataExcludeFields = [
    'bbox',
    'items',
    '_preViewUrls',
    'kind',
    'left',
    'top',
    'pages',
    'style',
    'height', // because height might be changed with fns like sync relations or so

    // these gets stripped in templateData
    'mobileDown',
    'orderIndex',
    'stretch',
    'wrap',
];

const hydrateTemplateData = (data: Record<string, any>): Record<string, any> => Object.keys(data).reduce((acc, k) => (
    TemplateDataExcludeFields.indexOf(k) === -1
        ? { [k]: data[k], ...acc }
        : acc
), {});

type Params = {
    template: DataPageTemplate;
    templateData: TemplateComponent;
    nextTemplateItems: Array<Record<string, any>>;
};

export const objPreProcessor = (obj: Record<string, any>) => {
    if (obj.type) {
        if (obj.type === LINK_ACTION_TYPE) {
            let result = { ...obj };
            if (result.link) {
                result.link = { ...result.link };
                delete result.link.id;
            }
            delete result.id;
            return result;
        }
        if (obj.inTemplate && obj.bbox) {
            let result: Record<string, any> = {
                ...obj,
                bbox: { ...obj.bbox }
            };
            if (result.relIn && result.relIn.right === -Infinity) {
                result.relIn = { ...result.relIn, right: null };
            }
            if (obj.type === SectionKind) {
                if (result.bbox.top !== 0 && result.relTo) {
                    result.relTo = { ...result.relTo };
                    delete result.relTo.below;
                }
                delete result.orderIndex;
            }
            delete result.bbox.top;
            delete result.bbox.bottom;
            return result;
        }
    }
    return obj;
};

export const templateDataIsChanged = ({ template, templateData, nextTemplateItems }: Params): boolean => {
    // check template data fields
    const
        currentTemplateData = hydrateTemplateData(toTemplateDataMapper(template)),
        nextTemplateData = hydrateTemplateData(templateData);

    if (objectsAreDiff(currentTemplateData, nextTemplateData)) return true;

    // check if template items removed
    if (template.items.length > nextTemplateItems.length) return true;

    // check template items updated
    return (objectCollectionsAreDiff(template.items, nextTemplateItems, objPreProcessor));
};
