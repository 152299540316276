import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
    (theme) => ({
        accordionSummaryRoot: {
            padding: "10px 24px",
            background: theme.palette.custom.colorGray_f7,
            borderTop: `1px solid ${theme.palette.custom.colorGray_ed}`,
            borderRight: `1px solid ${theme.palette.custom.colorGray_ed}`,
            borderLeft: `1px solid ${theme.palette.custom.colorGray_ed}`,
        },
        accordionSummaryContent: {
            overflow: "hidden",
        },
        accordionSummaryText: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        accordionDetailsRoot: {
            flexDirection: "column",
            padding: 0,
        },
        expandIconWrapper: {
            padding: "12px",
        },
    }),
    { name: 'PageTasks' }
);
