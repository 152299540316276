/* eslint-disable max-len */

import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly } from '../../../../epics/makeCondition';
import { BUSINESS_LISTING_SITE_SETTINGS_SERVICE_EPIC_VALUE } from "./businessListingSiteSettingsServiceVAT";
import type {
    BusinessListingServiceDefaultStateType,
    GmbDataType,
    BusinessListingServiceScopeType,
} from "../flowTypes";
import { SubscriptionTypeSelector } from "../../../App/epics/subscriptionData/selectorActionTypes";
import * as actionTypes from "./actionTypes";
import {
    replaceConnectHandlerDialogs,
    copyGmbDataToGeneralData,
    replaceWithBusinessListTabDialog,
} from "./actionCreators";
import {
    UNSAVED_CHANGES_CHECKED,
    CANCEL_UNSAVED_CHANGES
} from "../../../UnsavedChanges/actionTypes";
import { checkUnsavedChangesAction } from "../../../UnsavedChanges/actionCreators";
import { OpenGoogleAuthPage } from "./GoogleAuth/index";
import { fetchConnectedBusinessData } from './fetchConnectedBusinessData';
import { getAccountsWithLocationsData } from './getAccountsWithLocationsData';
import { GmbToWsb, WsbToGmb } from "./Mappers/index";
import { getGmbConnectionStatus } from "./getGmbConnectionStatus";
import { connectToThisListing } from "./connectToThisListing";
import {
    createConnectedBusinessData,
    createConnectedBusinessDataValidate,
} from "./createConnectedBusinessData";
import type { EpicUpdater } from '../../../../epics/flowTypes';
import localStorage from "../../../../utils/localStorage";
import { closeDialog } from "../../../App/actionCreators/index";
import { siteSettingsValueActionType } from '../../../App/epics/siteSettings/valueActionType';
import {
    gmbDoNotShowPublishDialogLocalStorageKey,
    CREATE_YOUR_LISTING_BUSINESS_INFORMATION_STEP,
    CREATE_YOUR_LISTING_CONTACT_DETAILS_STEP,
    CREATE_YOUR_LISTING_OPENING_HOURS_STEP,
    errorDataDefault,
    parseGmbValidateError
} from './constants';
import { GENERAL_INFO_ADDRESS_GOOGLE_PLACE_RESPONSE } from "../../General/actionTypes";
import {
    gmbGetLocationsFailureUpdater,
    openCreateYourListingDialogActionUpdater,
} from "./serivceEpicUpdaters";
import { PUBLISH } from "../../../App/actionTypes";
import { ReceiveOnlyReviewsConfigInProgress } from '../../../oneweb/GoogleReviews/epic/selectors';
import { replaceWithConnectToReviewDialog } from '../../../oneweb/GoogleReviews/epic/actionCreators';
import { GOOGLE_REVIEWS_EPIC } from '../../../oneweb/GoogleReviews/epic/valueActionType';
import getSubscriptionFeatureManager from '../../../../getSubscriptionFeatureManager';

const BusinessListingServiceDefaultState: BusinessListingServiceDefaultStateType = {
    onGoingAction: null,
    isWaitingForApi: false,
    isGmbAccessAllowed: false,
    isLoggedIn: false,
    isConnected: false,
    gmbData: null,
    doNotShowPublishDialog: false,
    errorData: errorDataDefault,
    createYourListingStep: CREATE_YOUR_LISTING_BUSINESS_INFORMATION_STEP
};

const businessListingSiteSettingsServiceEpic = makeEpic({
    defaultState: BusinessListingServiceDefaultState,
    defaultScope: ({
        isUnsavedCheckingInProgress: false
    } as BusinessListingServiceScopeType),
    valueActionType: BUSINESS_LISTING_SITE_SETTINGS_SERVICE_EPIC_VALUE,
    updaters: ([
        {
            conditions: [SubscriptionTypeSelector],
            reducer: ({ state, scope }) => {
                const isGmbAccessAllowed = getSubscriptionFeatureManager().isGoogleMyBusinessAllowed();
                if (isGmbAccessAllowed) {
                    return {
                        state: { ...state, isGmbAccessAllowed, isWaitingForApi: true },
                        scope,
                        // TODO: WBTGEN-20426: CAll this api only when site settings is loaded.
                        actionToDispatch: getGmbConnectionStatus()
                    };
                }
                return { state: { ...state, isGmbAccessAllowed }, scope };
            }
        },
        {
            conditions: [actionTypes.GOOGLE_MY_BUSINESS_CONNECTION_STATUS_SUCCESS],
            reducer: ({ state, scope, values: [response] }) => {
                if (response) {
                    const { isLoggedIn, isConnected } = response;
                    return {
                        state: { ...state, isLoggedIn, isConnected, isWaitingForApi: false },
                        scope,
                    };
                }
                return {
                    state: { ...state, isLoggedIn: false, isConnected: false, isWaitingForApi: false },
                    scope
                };
            }
        },
        {
            conditions: [actionTypes.GOOGLE_MY_BUSINESS_CONNECTION_STATUS_FAILURE],
            reducer: ({ state, scope }) => {
                return {
                    state: { ...state, isLoggedIn: false, isConnected: false, isWaitingForApi: false },
                    scope
                };
            }
        },
        {
            conditions: [actionTypes.GOOGLE_MY_BUSINESS_DISCONNECT_LISTING_REQUEST],
            reducer: ({ state, scope }) => {
                return {
                    state: { ...state, isWaitingForApi: true },
                    scope,
                };
            }
        },
        {
            conditions: [actionTypes.GOOGLE_MY_BUSINESS_DISCONNECT_LISTING_SUCCESS],
            reducer: ({ state, scope }) => {
                return {
                    state: { ...state, isLoggedIn: false, isConnected: false, isWaitingForApi: false },
                    scope,
                    actionToDispatch: replaceWithBusinessListTabDialog()
                };
            }
        },
        {
            conditions: [actionTypes.GOOGLE_MY_BUSINESS_DISCONNECT_LISTING_FAILURE],
            reducer: ({ state, scope }) => {
                return {
                    state: { ...state, isWaitingForApi: false },
                    scope,
                    actionToDispatch: replaceWithBusinessListTabDialog()
                };
            }
        },
        {
            conditions: [actionTypes.GOOGLE_MY_BUSINESS_FETCH_REQUEST],
            reducer: ({ state, scope }) => {
                return {
                    state: { ...state, isWaitingForApi: true },
                    scope,
                };
            }
        },
        {
            conditions: [actionTypes.GMB_GET_LOCATIONS_REQUEST],
            reducer: ({ state, scope }) => {
                return {
                    state: { ...state, isWaitingForApi: true },
                    scope,
                };
            }
        },
        {
            conditions: [actionTypes.CONNECT_TO_THIS_LISTING_REQUEST],
            reducer: ({ state, scope }) => {
                return {
                    state: { ...state, isWaitingForApi: true },
                    scope,
                };
            }
        },
        {
            conditions: [actionTypes.GOOGLE_AUTH_SUCCESS],
            reducer: ({ state, scope }) => {
                const newState = { ...state, isLoggedIn: true };

                if (state.onGoingAction === actionTypes.CREATE_YOUR_LISTING) {
                    newState.createYourListingStep = CREATE_YOUR_LISTING_BUSINESS_INFORMATION_STEP;
                }
                return {
                    state: newState,
                    scope,
                    actionToDispatch: getAccountsWithLocationsData()
                };
            }
        },
        {
            conditions: [
                ReceiveOnlyReviewsConfigInProgress,
                actionTypes.GOOGLE_AUTH_FAILURE
            ],
            reducer: ({ state, scope, values: [reviewsConfigInProgress, { errorCode }] }) => {
                if (errorCode === 'oauth-access-denied') {
                    return {
                        state,
                        scope,
                        actionToDispatch: reviewsConfigInProgress ?
                            replaceWithConnectToReviewDialog() : replaceWithBusinessListTabDialog()
                    };
                } else {
                    const dialogToShowActionType = state.onGoingAction === actionTypes.CREATE_YOUR_LISTING ? actionTypes.CREATE_FAILURE : actionTypes.CONNECT_FAILURE;
                    return {
                        state,
                        scope,
                        actionToDispatch: replaceConnectHandlerDialogs({ actionType: dialogToShowActionType })
                    };
                }
            }
        },
        {
            conditions: [actionTypes.CONNECT_YOUR_LISTING],
            reducer: ({ state, scope, dispatchAsync }) => {
                // TODO: Already loggedin case
                OpenGoogleAuthPage({ dispatch: dispatchAsync });

                return {
                    state: { ...state, onGoingAction: actionTypes.CONNECT_YOUR_LISTING },
                    scope
                };
            }
        },
        {
            conditions: [actionTypes.GOOGLE_MY_BUSINESS_FETCH_SUCCESS],
            reducer: ({ state, values: [response], scope }) => {
                if (response && response.data) {
                    const wsbFields = GmbToWsb(response.data);

                    return {
                        state: { ...state, gmbData: wsbFields, isWaitingForApi: false },
                        scope,
                        actionToDispatch: replaceConnectHandlerDialogs({ actionType: actionTypes.CONNECT_SUCCESS })
                    };
                }

                return {
                    state: { ...state, isWaitingForApi: false },
                    scope,
                    actionToDispatch: replaceConnectHandlerDialogs({ actionType: actionTypes.IMPORT_FAILURE })
                };
            }
        },
        {
            conditions: [actionTypes.GOOGLE_MY_BUSINESS_FETCH_FAILURE],
            reducer: ({ state, scope }) => {
                return {
                    state: { ...state, isWaitingForApi: false },
                    scope,
                    actionToDispatch: replaceConnectHandlerDialogs({ actionType: actionTypes.IMPORT_FAILURE })
                };
            }
        },
        {
            conditions: [actionTypes.GMB_GET_LOCATIONS_SUCCESS],
            reducer: ({ state, values: [response], scope }) => {
                const
                    isWaitingForApi = false;

                const account = response && response.accounts[0];

                if (account) {
                    const locations = account.locations;
                    const accountName = account.name;

                    if (state.onGoingAction === actionTypes.CREATE_YOUR_LISTING) {
                        if (locations.length === 0) {
                            return openCreateYourListingDialogActionUpdater({ state, scope });
                        } else {
                            let wsbFieldsList = locations.map(location => {
                                return GmbToWsb(location);
                            });

                            return {
                                state: { ...state, isWaitingForApi },
                                scope,
                                actionToDispatch: replaceConnectHandlerDialogs({
                                    actionType: actionTypes.CONNECT_LISTINGS_ON_CREATE,
                                    gmbDataList: wsbFieldsList,
                                    accountName
                                })
                            };
                        }
                    } else {
                        if (locations.length > 1) {
                            let wsbFieldsList = locations.map(location => {
                                return GmbToWsb(location);
                            });

                            return {
                                state: { ...state, isWaitingForApi },
                                scope,
                                actionToDispatch: replaceConnectHandlerDialogs({
                                    actionType: actionTypes.CONNECT_LISTINGS,
                                    gmbDataList: wsbFieldsList,
                                    accountName
                                })
                            };
                        } else if (locations.length === 1) {
                            const payload = {
                                accountPath: accountName,
                                locationPath: locations[0].name
                            };

                            return {
                                state,
                                scope,
                                actionToDispatch: connectToThisListing(payload)
                            };
                        }
                        return {
                            state: { ...state, isWaitingForApi },
                            scope,
                            actionToDispatch: replaceConnectHandlerDialogs({ actionType: actionTypes.NO_GMB_FAILURE })
                        };
                    }
                }

                return gmbGetLocationsFailureUpdater({ state, scope });
            }
        },
        {
            conditions: [actionTypes.GMB_GET_LOCATIONS_FAILURE],
            reducer: ({ state, scope }) => {
                return gmbGetLocationsFailureUpdater({ state, scope });
            }
        },
        {
            conditions: [actionTypes.CONNECT_TO_THIS_LISTING],
            reducer: ({ state, scope, values: [payload] }) => {
                return {
                    state: { ...state, isWaitingForApi: true },
                    scope,
                    actionToDispatch: connectToThisListing(payload)
                };
            }
        },
        {
            conditions: [actionTypes.CONNECT_TO_THIS_LISTING_SUCCESS],
            reducer: ({ state, values: [response], scope }) => {
                if (response && response.status === 'success') {
                    return {
                        state: { ...state, isConnected: true },
                        scope,
                        actionToDispatch: fetchConnectedBusinessData()
                    };
                }

                return {
                    state: { ...state, isWaitingForApi: false, isConnected: false },
                    scope,
                    actionToDispatch: replaceConnectHandlerDialogs({ actionType: actionTypes.CONNECT_FAILURE })
                };
            }
        },
        {
            conditions: [actionTypes.CONNECT_TO_THIS_LISTING_FAILURE],
            reducer: ({ state, scope }) => {
                return {
                    state: { ...state, isWaitingForApi: false, isConnected: false },
                    scope,
                    actionToDispatch: replaceConnectHandlerDialogs({ actionType: actionTypes.CONNECT_FAILURE })
                };
            }
        },
        {
            conditions: [actionTypes.IMPORT_YOUR_LISTING],
            reducer: ({ state, scope }) => {
                const { gmbData } = state;
                return {
                    state: { ...state, onGoingAction: null },
                    scope,
                    multipleActionsToDispatch: [
                        copyGmbDataToGeneralData(gmbData as GmbDataType),
                        replaceConnectHandlerDialogs({ actionType: actionTypes.IMPORT_SUCCESS }),
                    ]
                };
            }
        },
        {
            conditions: [actionTypes.IMPORT_FAILURE],
            reducer: ({ state, scope }) => {
                return {
                    state: { ...state, isWaitingForApi: false },
                    scope,
                    actionToDispatch: replaceConnectHandlerDialogs({ actionType: actionTypes.IMPORT_FAILURE })
                };
            }
        },
        {
            conditions: [actionTypes.CREATE_YOUR_LISTING_BTN_CLICKED_ACTION],
            reducer: ({ state, scope }) => {
                return {
                    state,
                    scope: {
                        ...scope,
                        isUnsavedCheckingInProgress: true
                    },
                    actionToDispatch: checkUnsavedChangesAction()
                };
            }
        },
        {
            conditions: [
                UNSAVED_CHANGES_CHECKED,
            ],
            reducer: ({ state, scope, dispatchAsync }) => {
                if (scope.isUnsavedCheckingInProgress) {
                    let actionToDispatch;
                    if (state.isLoggedIn) {
                        actionToDispatch = getAccountsWithLocationsData();
                    } else {
                        OpenGoogleAuthPage({ dispatch: dispatchAsync });
                    }

                    return {
                        state: { ...state, onGoingAction: actionTypes.CREATE_YOUR_LISTING },
                        scope: { isUnsavedCheckingInProgress: false },
                        actionToDispatch
                    };
                }

                return {
                    state,
                    scope
                };
            }
        },
        {
            conditions: [
                CANCEL_UNSAVED_CHANGES,
            ],
            reducer: ({ state, scope }) => {
                if (scope.isUnsavedCheckingInProgress) {
                    return {
                        state,
                        scope: {
                            isUnsavedCheckingInProgress: false
                        },
                    };
                }

                return {
                    state,
                    scope
                };
            }
        },
        {
            conditions: [
                receiveOnly(GOOGLE_REVIEWS_EPIC),
                actionTypes.CREATE_YOUR_LISTING_BACK_ACTION
            ],
            reducer: ({ state, values: [{ reviewsConfigInProgress }], scope }) => {
                const { createYourListingStep } = state;
                if (createYourListingStep === CREATE_YOUR_LISTING_BUSINESS_INFORMATION_STEP) {
                    return {
                        state: { ...state },
                        scope,
                        actionToDispatch: reviewsConfigInProgress ? replaceWithConnectToReviewDialog() : replaceWithBusinessListTabDialog()
                    };
                }
                if (createYourListingStep === CREATE_YOUR_LISTING_CONTACT_DETAILS_STEP) {
                    return {
                        state: { ...state, createYourListingStep: CREATE_YOUR_LISTING_BUSINESS_INFORMATION_STEP },
                        scope
                    };
                }
                return {
                    state: { ...state, createYourListingStep: CREATE_YOUR_LISTING_CONTACT_DETAILS_STEP },
                    scope
                };
            }
        },
        {
            conditions: [
                receiveOnly(siteSettingsValueActionType),
                actionTypes.CREATE_YOUR_LISTING_VALIDATE_ACTION
            ],
            reducer: ({ state, values: [{ current: siteSettings }], scope }) => {
                if (state.isLoggedIn) {
                    const { createYourListingStep } = state;
                    if (createYourListingStep === CREATE_YOUR_LISTING_BUSINESS_INFORMATION_STEP) {
                        return {
                            state: { ...state, createYourListingStep: CREATE_YOUR_LISTING_CONTACT_DETAILS_STEP },
                            scope
                        };
                    }
                    if (
                        createYourListingStep === CREATE_YOUR_LISTING_CONTACT_DETAILS_STEP ||
                        createYourListingStep === CREATE_YOUR_LISTING_OPENING_HOURS_STEP
                    ) {
                        const { generalData, publishConfig } = siteSettings;
                        const gmbData = WsbToGmb({
                            wsbData: { generalData, publishConfig },
                            validateOnly: true,
                            removeRegularHours: createYourListingStep === CREATE_YOUR_LISTING_CONTACT_DETAILS_STEP
                        });
                        return {
                            state: { ...state, isWaitingForApi: true, onGoingAction: actionTypes.CREATE_YOUR_LISTING },
                            scope,
                            actionToDispatch: createConnectedBusinessDataValidate(gmbData)
                        };
                    }
                }
                return { state, scope };
            }
        },
        {
            conditions: [
                receiveOnly(siteSettingsValueActionType),
                actionTypes.GOOGLE_MY_BUSINESS_VALIDATE_TO_GMB_SUCCESS
            ],
            reducer: ({ state, scope, values: [{ current: siteSettings }] }) => {
                const { createYourListingStep } = state;
                const errorData = errorDataDefault;
                if (createYourListingStep === CREATE_YOUR_LISTING_CONTACT_DETAILS_STEP) {
                    return {
                        state: {
                            ...state,
                            createYourListingStep: CREATE_YOUR_LISTING_OPENING_HOURS_STEP,
                            isWaitingForApi: false,
                            errorData
                        },
                        scope
                    };
                } else if (createYourListingStep === CREATE_YOUR_LISTING_OPENING_HOURS_STEP) {
                    const { generalData, publishConfig } = siteSettings;
                    if (state.isLoggedIn) {
                        // Push data to GMB
                        const gmbData = WsbToGmb({ wsbData: { generalData, publishConfig } });
                        return {
                            state: {
                                ...state,
                                isWaitingForApi: true,
                                onGoingAction: actionTypes.CREATE_YOUR_LISTING,
                                errorData
                            },
                            actionToDispatch: createConnectedBusinessData(gmbData),
                            scope
                        };
                    }
                }
                return {
                    state: { ...state, isWaitingForApi: false, errorData },
                    scope
                };
            }
        },
        {
            conditions: [actionTypes.GOOGLE_MY_BUSINESS_VALIDATE_TO_GMB_FAILURE],
            reducer: ({ state, values: [response], scope }) => {
                const parsedResultList = parseGmbValidateError(response);
                return {
                    state: { ...state, isWaitingForApi: false, errorData: parsedResultList },
                    scope
                };
            }
        },
        /* This is to reset address errorData when a new place is searched. */
        {
            conditions: [GENERAL_INFO_ADDRESS_GOOGLE_PLACE_RESPONSE],
            reducer: ({ state, scope }) => {
                return {
                    state: { ...state, errorData: errorDataDefault },
                    scope
                };
            }
        },
        {
            conditions: [actionTypes.GOOGLE_MY_BUSINESS_SAVE_TO_GMB_SUCCESS],
            reducer: ({ state, scope }) => {
                return {
                    state: { ...state, isWaitingForApi: false, isConnected: true },
                    scope,
                    actionToDispatch: replaceConnectHandlerDialogs({ actionType: actionTypes.CREATE_SUCCESS })
                };
            }
        },
        {
            conditions: [actionTypes.GOOGLE_MY_BUSINESS_SAVE_TO_GMB_FAILURE],
            reducer: ({ state, scope }) => {
                return {
                    state: { ...state, isWaitingForApi: false },
                    scope,
                    actionToDispatch: replaceConnectHandlerDialogs({ actionType: actionTypes.CREATE_FAILURE })
                };
            }
        },
        {
            conditions: [actionTypes.TOGGLE_SHOWING_GMB_PUBLISH_DIALOG_ACTION],
            reducer: ({ state, scope }) => {
                const doNotShowPublishDialog = !state.doNotShowPublishDialog;
                localStorage.set(gmbDoNotShowPublishDialogLocalStorageKey, { doNotShowPublishDialog });
                return { state: { ...state, doNotShowPublishDialog }, scope };
            }
        },
        {
            conditions: [actionTypes.GMB_PUBLISH_UPDATE_USER_CONSENT_ACTION],
            reducer: ({ state, scope }) => {
                return {
                    state,
                    scope,
                    multipleActionsToDispatch: [
                        closeDialog(),
                        { type: PUBLISH, payload: { force: false, forceAndGmbForce: true } }
                    ]
                };
            }
        },
    ] as Array<EpicUpdater<BusinessListingServiceDefaultStateType, BusinessListingServiceScopeType, string>>)
});

export {
    businessListingSiteSettingsServiceEpic,
};
