import React from 'react';
import styles from '../FileChooserUpload.css';
import WarningDialog from '../../../dialogs/WarningDialog/index';
import injectIntl from "../../../../intl/injectIntl";
import getCenteredDialogConfig from "../../../../../components/DialogManager/getCenteredDialogConfig";
import ErrorDialogFileList from './ErrorDialogFileList';
import { errorDialogStandardHeight, errorDialogStandardWidth } from '../../../dialogs/constants';

const
    width = errorDialogStandardWidth,
    height = errorDialogStandardHeight,
    title = 'msg: fc.uploadFailedDialog.err.invalidFile.title {Invalid File}',
    error = 'msg: fc.uploadFailedDialog.err.invalidFile.error {The file could not be read correctly.}',
    mctaText = 'msg: common.ok {OK}';

const InvalidErrorDialog = injectIntl(({ fileNames, intl }) => {
    return (
        <WarningDialog title={title} mctaText={mctaText}>
            <div className={styles.labelSmall}>
                <span className={styles.bold}>{intl.msgJoint(error)}</span>
                <br /><br />
                <ErrorDialogFileList fileNames={fileNames} />
            </div>
        </WarningDialog>
    );
});

export default getCenteredDialogConfig(width, height, InvalidErrorDialog);
