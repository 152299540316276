import { applyMappers, makePlainMappers } from "../../utils";
import * as scale from "./scale";
import * as onClickAction from "./onClickAction";
import * as imagesMapper from "./imagesMapper";
import type { FacebookFeedGalleryComponent } from "../../../../src/components/oneweb/FacebookFeedGallery/flowTypes";
import type { FacebookFeedGalleryIn } from './flowTypes';

const plainPropsMapper = makePlainMappers({
    crop: "crop",
    captionsEnabled: "captionsEnabled",
    captionsAlignment: "captionsAlignment",
    captionBoxStyle: "captionBoxStyle",
    mobileHide: "mobileHide",
    mobileDown: "mobileDown",
    imageStyle: 'imageStyle',
    previousCaptionStyle: 'previousCaptionStyle',
    captionStyle: 'captionStyle',
    mobileSettings: 'mobileSettings',
    captionTitleTextStyle: 'captionTitleTextStyle',
    captionDescriptionTextStyle: 'captionDescriptionTextStyle',
    imageRatio: 'imageRatio',
    profileImageAsset: 'profileImageAsset',
    buttonThemeSelected: 'buttonThemeSelected'
});

export function to(componentData: FacebookFeedGalleryIn): FacebookFeedGalleryComponent {
    return applyMappers(
        componentData,
        scale.to,
        onClickAction.to,
        plainPropsMapper.to,
        imagesMapper.to
    );
}

export function back(component: FacebookFeedGalleryComponent): FacebookFeedGalleryIn {
    return applyMappers(
        component,
        scale.back,
        onClickAction.back,
        plainPropsMapper.back,
        imagesMapper.back
    );
}
