import React from 'react';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import {
    getBackgroundAsset,
    getBackgroundRepeat,
    getBackgroundPosition,
    getBackgroundSize,
    getBackgroundImageOpacity,
    getBackgroundImageScrollEffect
} from "../../../../../view/oneweb/commonComponentSelectors";
import type { BackgroundPageProps } from "../flowTypes";
import * as ActionTypes from "../../actionTypes";
import BackgroundImageSettings from '../../../../presentational/BackgroundImageSettings/index';
import OpacitySlider from "../../../../presentational/OpacitySlider/index";
import { ImageOpacityLabel } from "../../constants";
import { bgSetBackgroundImageOpacityAC } from "../../actionCreators";

const view = ({ selectedComponent, dispatch }: BackgroundPageProps) => {
    const
        asset = getBackgroundAsset(selectedComponent),
        repeat = getBackgroundRepeat(selectedComponent),
        position = getBackgroundPosition(selectedComponent),
        size = getBackgroundSize(selectedComponent),
        scrollEffect = getBackgroundImageScrollEffect(selectedComponent),
        bgImageOpacity = getBackgroundImageOpacity(selectedComponent),
        opacityChange = (opacity) => dispatch(bgSetBackgroundImageOpacityAC(opacity));
    return (
        <Page>
            <VerticalSpacer />
            <BackgroundImageSettings
                asset={asset}
                assetChangeAction={ActionTypes.BG_CHANGE_ACTION}
                assetRemoveAction={ActionTypes.BG_REMOVE_ACTION}
                repeat={repeat}
                assetRepeatChangeAction={ActionTypes.BG_REPEAT_CHANGE_ACTION}
                position={position}
                assetPositionChangeAction={ActionTypes.BG_POSITION_CHANGE_ACTION}
                size={size}
                assetSizeChangeAction={ActionTypes.BG_SIZE_CHANGE_ACTION}
                dispatch={dispatch}
                scrollEffect={scrollEffect}
            />
            {
                asset &&
                <div>
                    <VerticalSpacer />
                    <OpacitySlider
                        label={ImageOpacityLabel}
                        opacity={bgImageOpacity}
                        onChange={opacityChange}
                        onAfterChange={opacityChange}
                    />
                </div>
            }
        </Page>
    );
};

export default (id: string, title: string) => ({ id, title, view });
