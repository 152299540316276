import React from 'react';
import '../../view/Preview.css';
import { DATA_TEST_ID } from '../constants';

export const BookingsView = props => {
    return (
        <div data-testid={DATA_TEST_ID} className="widget" style={{ width: props.width }}>
            {props.isServerPreview ? (
                <div id="bookings-widget" dangerouslySetInnerHTML={{ __html: props.code }} />
            ) : (
                <iframe
                    src={props.link}
                    width="100%"
                    height={props.height}
                    loading="lazy"
                    style={{ border: 'none' }}
                />
            )}
        </div>
    );
};
