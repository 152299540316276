import React from 'react';
import FourFields from '../index';
import type { FourFieldBordersProps } from "../flowTypes";
import type { AnyComponent } from "../../../../redux/modules/children/workspace/flowTypes";
import Icons from '../../../../view/Icons/index';
import makeFourFieldIcons from "../makeFourFieldIcons";

const MAX_BORDER_VALUE: number = 50;

export const getMaxBorderWidth = (component: AnyComponent): number =>
    Math.floor(Math.min(component.width / 2, component.height / 2, MAX_BORDER_VALUE));

export default ({ label, selectedComponent, disabled, ...rest }: FourFieldBordersProps) => {
    let validators: any = null;
    if (selectedComponent) {
        const maxBorderWidth = getMaxBorderWidth(selectedComponent);
        validators = {
            validator: value => value <= maxBorderWidth,
            message: [
                'msg: common.maxBorderSizeError {The maximum value is {maxValue}}',
                { maxValue: maxBorderWidth }
            ]
        };
    }

    return (
        <FourFields
            label={label || "msg: common.borderSize {Border size (px)}"}
            title="msg: common.border.sizeLock {Link together border sizes}"
            icons={makeFourFieldIcons((disabled ? Icons.BORDER_DISABLED : Icons.BORDER), [90, 180, -90, 0])}
            validators={validators}
            disabled={disabled}
            restrictDispatchTillAnimationFrame
            {...rest}
        />
    );
};
