import React from "react";
import styles from "./ContrastAdjustDialog.css";
import leftPanelStyles from "../../AutoColorLeftPanel.css";
import { Dialog, DialogBody, DialogFooter, StripTypes } from "../../../../view/common/dialogs/baseDialog/index";
import { Msg, Intl } from "../../../../view/intl/index";
import { closeDialog } from "../../../App/actionCreators/index";
import { ADJUST_CONTRAST_COLOR, KEEP_CONTRAST_COLOR } from "../../actionTypes";
import injectIntl from "../../../../view/intl/injectIntl";

type Props = {
    dispatch: Dispatch;
    intl: Intl;
};
const ContrastAdjustDialog = injectIntl((props: Props) => {
    const { dispatch, intl } = props,
        adjustContrastColor = () => {
            dispatch({
                type: ADJUST_CONTRAST_COLOR
            });
            dispatch(closeDialog());
        },
        keepContrastColor = () => {
            dispatch({
                type: KEEP_CONTRAST_COLOR
            });
            dispatch(closeDialog());
        };

    return (
        <Dialog stripType={StripTypes.NONE} className={styles.dialogMain} closeBtnClassName={styles.closeBtn}>
            <DialogBody className={styles.dialogBody}>
                <div className={styles.title}>
                    <Msg k="adjustColorDialog.title">Have you thought about contrast?</Msg>
                </div>
                <div className={styles.description}>
                    <Msg k="adjustColorDialog.description">
                        The colour combination you selected will make some of the elements on your site hard to see.
                    </Msg>
                    <br />
                    <a
                        className={leftPanelStyles.learnMoreLink}
                        target="_blank"
                        href={intl.msgJoint(
                            "msg: autoColor.leftPanel.themeOff.learnMore {https://help.one.com/hc/en-us/articles/360015355958}"
                        )}
                    >
                        <Msg k="common.learnMore">Learn more</Msg>
                    </a>
                </div>
            </DialogBody>
            <DialogFooter
                className={styles.footerContainer}
                footerButtonContainerClassName={styles.footerButtonContainer}
                mctaText="msg: adjustColorDialog.primaryButtonText {Adjust colours}"
                sctaText="msg: adjustColorDialog.secondaryButtonText {Keep as it is}"
                mctaHandler={adjustContrastColor}
                noTopBorder
                primaryBtnClass={styles.footerPrimaryBtn}
                sctaBtnClass={styles.footerSctaBtn}
                sctaHandler={keepContrastColor}
                autoFocus
            />
        </Dialog>
    );
});
export default ContrastAdjustDialog;
