import React from 'react';
import cx from 'classnames';
import { injectIntl } from "../../../../view/intl/index";
import styles from "./PreviewSwitcher.css";

const switchToMobile = "msg: preview.toolbar.switchToMobile.title {Switch to mobile preview}",
    switchToDesktop = "msg: preview.toolbar.switchToDesktop.title {Switch to desktop preview}";

export default injectIntl(({ mobileView, intl, onMobilePreview, disableMobilePreview = false, onDesktopPreview, style = {} }) => (
    <div className={styles.PreviewSwitcher} style={style}>
        <button
            data-title={intl.msgJoint(switchToMobile)}
            className={cx(styles.buttonSmall, styles.buttonMobile, { [styles.selected]: mobileView })}
            onClick={onMobilePreview}
            disabled={disableMobilePreview}
        />
        <button
            data-title={intl.msgJoint(switchToDesktop)}
            className={cx(styles.buttonSmall, styles.buttonDesktop, { [styles.selected]: !mobileView })}
            onClick={onDesktopPreview}
        />
    </div>
));
