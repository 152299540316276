import type { Element } from '../../flowTypes';
import { isHeader } from '../helpers/index';
import { isOrderedList, isUnorderedList } from '../filters/listStyleFilter';

// $FlowFixMe: TODO: exec() returns an array
const getHeaderTag = (ref?: string) => `h${/\d+/g.exec(ref || '')}`;

/**
 * Tag name depends on few properties like class, type or style property
 */
export default (element: Element, editMode?: null | undefined | boolean): string => {
    const {
        atype,
        ref,
        full,
        spacing,
        listItemElement
    } = element;
    if (atype === 'text') {
        return '';
    } else if (atype === 'link') {
        return 'a';
    } else if (atype === 'listItem') {
        return 'li';
    } else if (atype === 'break') {
        return 'br';
    } else if (atype === 'wrapper') {
        return 'div';
    } else if (isOrderedList(element)) {
        return 'ol';
    } else if (isUnorderedList(element)) {
        return 'ul';
    } else if (isHeader(ref) && !editMode && full &&
        !listItemElement && !spacing) {
        return getHeaderTag(ref);
    } else if (atype === 'nested') {
        return 'span';
    } else if (atype === 'style') {
        if (element.superscript) {
            return 'sup';
        }
        if (element.subscript) {
            return 'sub';
        }
        return 'span';
    }
    return 'p';
};
