import { setComponentsMap } from "../../Workspace/epics/componentsEval/setters";
import { REMOVE_EMPTY_STRIPS_OVERLAPPING_WITH_HEADER } from "./actions";
import { getCmps } from "../../Workspace/epics/componentAttachements/util";
import { getHeaderSection } from "../../oneweb/Section/utils";
import { deselectAllComponents } from "../../Workspace/epics/componentsEval/selectedComponentsIds";
import { isStripKind } from "../../oneweb/componentKinds";
import { ReceiveOnlyTemplateWidthActionType } from "../../oneweb/Template/epics/template/selectorActionTypes";
import { updateComponentsRelIns } from "../../Workspace/epics/relations/updateComponentsRelIns";

export default {
    conditions: [
        ReceiveOnlyTemplateWidthActionType,
        REMOVE_EMPTY_STRIPS_OVERLAPPING_WITH_HEADER
    ],
    reducer: ({ values: [templateWidth], state: epicState }) => {
        let { state: { componentsMap } } = epicState,
            newComponentsMap = { ...componentsMap },
            header = getHeaderSection(newComponentsMap),
            cmps = getCmps(newComponentsMap),
            pageStripsOverlappingWithHeader = cmps.filter(cmp => cmp.height > 1 && !cmp.inTemplate &&
                isStripKind(cmp.kind) && cmp.top < header.height && (cmp.top + cmp.height) > header.height);
        pageStripsOverlappingWithHeader.forEach(s => {
            delete newComponentsMap[s.id];
        });
        newComponentsMap = updateComponentsRelIns(newComponentsMap, templateWidth);
        return {
            state: deselectAllComponents(setComponentsMap(newComponentsMap, epicState)),
            updateReason: REMOVE_EMPTY_STRIPS_OVERLAPPING_WITH_HEADER
        };
    }
};
