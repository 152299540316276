import React from "react";
export const SvgTripadvisorSquareFillLight = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <path fill="#ffffff" d="M0 0h48v48H0z" />
            <g fill="#3c3c3c" transform="translate(9 14)">
                <path d="M7.5 13.803a2.658 2.658 0 100-5.316 2.658 2.658 0 000 5.316z" />
                <circle cx={22.48} cy={11.15} r={2.66} />
                <path d="M.008 11.145A7.491 7.491 0 0012.59 16.64l2.401 2.613 2.402-2.611a7.494 7.494 0 0012.585-5.496 7.474 7.474 0 00-2.446-5.538l2.452-2.667H24.55a16.976 16.976 0 00-19.1 0H0l2.451 2.667a7.473 7.473 0 00-2.443 5.537zm27.545 0a5.07 5.07 0 11-10.14 0 5.07 5.07 0 0110.14 0zM14.99 2.451c1.932-.002 3.846.38 5.629 1.125-3.2 1.225-5.626 4.087-5.626 7.423 0-3.336-2.427-6.199-5.627-7.423a14.546 14.546 0 015.624-1.125zM7.5 6.075a5.07 5.07 0 110 10.14 5.07 5.07 0 010-10.14z" />
            </g>
        </g>
    </svg>
);
