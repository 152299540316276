import * as R from 'ramda';
import valueActionType from "./valueActionType";
import makeEpic from "../../../../epics/makeEpic";
import mobileViewEditorVAT from "../hoveringAndSelection/valueActionType";
import { anyOf, receiveOnly } from "../../../../epics/makeCondition";
import * as ActionTypes from "../../actionTypes";
import { ALIGNMENT_TYPES } from '../../../../constants';
import { PAGE_TREE_LOAD_PAGE } from "../../../PagesTree/actionTypes";

export const MCTA_HEIGHT = 36;
export const SPACE_BETWEEEN_MCTA_ELEMENT = 9;
export const OFFSET = MCTA_HEIGHT + SPACE_BETWEEEN_MCTA_ELEMENT;

export const calculateTopForMCTA = (
    topValue: number,
    height: number,
    parentHeight: number,
    parentTop: number,
    scrollTop: number,
    headerHeight: number
) => {
    const relativeTop = topValue - parentTop;

    let top = relativeTop - OFFSET - Math.max(headerHeight - scrollTop, 0);

    if (top <= 0) { //if this satisfies it means no space on top and check for bottom
        top = relativeTop + height + SPACE_BETWEEEN_MCTA_ELEMENT;

        top = (top + MCTA_HEIGHT) >= parentHeight ? //if this satisfies it means no space on bottom and put mcta at the start of component
            relativeTop + SPACE_BETWEEEN_MCTA_ELEMENT : top;
    } else {
        top += Math.max(headerHeight - scrollTop, 0);
    }
    return top;
};

const defaultState = {
    visible: false,
    position: {
        top: 0,
        left: 0
    },
    mctaHorizontalAlignControlExpanded: false,
    selected: {
        align: ALIGNMENT_TYPES.ALIGN_CENTER
    },
    availableSpace: {
        top: 0,
        bottom: 0
    }
};

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [PAGE_TREE_LOAD_PAGE],
            reducer: () => ({ state: { ...defaultState } })
        },
        {
            conditions: [
                mobileViewEditorVAT
            ],
            reducer: ({
                values: [{
                    selectedCmpId,
                    isDragging,
                    selectedComponentRect,
                    contentScrollTop,
                    rootContainerRect,
                    headerHeight
                }],
                state
            }) => {
                if (selectedComponentRect) {
                    const top = calculateTopForMCTA(
                        selectedComponentRect.top,
                        selectedComponentRect.height,
                        rootContainerRect.height,
                        rootContainerRect.top,
                        contentScrollTop,
                        headerHeight
                    );

                    return {
                        state: {
                            ...state,
                            availableSpace: {
                                top,
                                bottom: rootContainerRect.height - top - MCTA_HEIGHT
                            },
                            position: {
                                top: top + contentScrollTop,
                                left: selectedComponentRect.offsetLeft
                            },
                            visible: !isDragging && state.visible && selectedCmpId
                        }
                    };
                }
                return {
                    state: {
                        ...state,
                        visible: !isDragging && state.visible && selectedCmpId
                    }
                };
            }
        },
        {
            conditions: [
                anyOf(
                    ActionTypes.MOBILE_EDITOR_MOVE_DOWN_ARROW_CLICKED,
                    ActionTypes.MOBILE_EDITOR_MOVE_UP_ARROW_CLICKED
                )
            ],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        visible: false,
                        mctaHorizontalAlignControlExpanded: false
                    }
                };
            }
        },
        {
            conditions: [
                ActionTypes.MOBILE_EDITOR_COMPONENT_SELECT
            ],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        visible: true
                    }
                };
            }
        },
        {
            conditions: [
                ActionTypes.MOBILE_EDITOR_ALIGNMENT_UPDATE,
                receiveOnly(ActionTypes.MOBILE_EDITOR_COMPONENT_SELECT)
            ],
            reducer: ({ values: [{ settings }, { cmpId }], state }) => {
                if (!state.visible || !settings[cmpId]) {
                    return { state };
                }
                let selected = settings[cmpId];
                return {
                    state: {
                        ...state,
                        selected
                    }
                };
            }
        },
        {
            conditions: [ActionTypes.MOBILE_MCTA_ALIGN_MOUSE_ENTER],
            reducer: ({ state }) => {
                return {
                    state: R.evolve({
                        mctaHorizontalAlignControlExpanded: () => true
                    }, state)
                };
            }
        },
        {
            conditions: [ActionTypes.MOBILE_MCTA_ALIGN_MOUSE_LEAVE],
            reducer: ({ state }) => ({
                state: R.evolve({
                    mctaHorizontalAlignControlExpanded: () => false
                }, state)
            })
        },
    ]
});
