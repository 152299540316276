import React from 'react';
import cx from 'classnames';
import TdButton from '../TdButton/index';
import styles from '../../buttons.css';
import type { Props } from './flowTypes';

export default (props: Props) => {
    const
        { Icon, ...rest } = props,
        iconClassName = cx(
            props.iconClassName,
            styles.buttonIcon,
            {
                [styles.selected]: props.isSelected
            }
        );

    return (
        <TdButton {...rest}>
            <Icon className={iconClassName} />
        </TdButton>
    );
};
