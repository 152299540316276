import React from 'react';
import cx from "classnames";

import styles from '../PageInfoDialog.css';
import type { PageInfoSocialShareTabProps } from "../../flowTypes";
import { Msg } from '../../../../../../../view/intl';
import injectIntl from "../../../../../../../view/intl/injectIntl";
import VerticalSpacer from "../../../../../../../view/common/VerticalSpacer";
import { ImageChooserBtnPP } from "../../../../../../FileManager/imageChooser/ImageChooserBtnPP";
import { SocialShareImagePreview } from "../../../../../../SocialShare/view/SocialShareImagePreview";
import {
    PAGE_INFO_SOCIAL_SHARE_ASSET_ADDED,
    PAGE_INFO_SOCIAL_SHARE_ASSET_REMOVED,
    switchPageInfoTabAction,
} from "../../actions";
import { LinkButtonCom } from "../../../../../../presentational/LinkButton/LinkButton";
import { PageInfoTab } from "../../../../constants";
import { SeoPageTitleInTabOptions } from "../constants";
import { getDefaultNonHomePageNameInBrowserTab } from "../../utils";
import { validateSocialShareAsset } from "../../../../../../SocialShare/utils";

export default injectIntl((props: PageInfoSocialShareTabProps) => {
    const
        {
            state: {
                socialShareAsset,
            },
            namesState: {
                name,
            },
            seoState: {
                description,
                customTitle,
                defaultHomePageNameInBrowserTab,
                pageNameOptionInBrowserTab,
            },
            urlParams,
            isHomePage,
            intl,
            dispatch,
            websiteTitle,
            domainName,
        } = props;

    const defaultNonHomePageNameInBrowserTab = getDefaultNonHomePageNameInBrowserTab(name.value, domainName, websiteTitle);
    const defaultDisplayTitle = isHomePage ? defaultHomePageNameInBrowserTab : defaultNonHomePageNameInBrowserTab;
    const displayTitle = pageNameOptionInBrowserTab === SeoPageTitleInTabOptions.CUSTOM ? customTitle.value : defaultDisplayTitle;

    const socialAShareAssetWarning = validateSocialShareAsset(socialShareAsset),
        socialShareImageHint = socialAShareAssetWarning ?
            <div className={cx(styles.imageHint, styles.warning)}>
                {intl.msgJoint(socialAShareAssetWarning)}
            </div> :
            <div className={styles.imageHint}>
                {/* eslint-disable-next-line max-len */}
                {intl.msgJoint('msg: pageInfo.socialShare.asset.hint {Get the best results by using an image that is minimum 200x200 px.}')}
            </div>;

    return (
        <div className={styles.tab}>
            <div>
                <div style={{ paddingTop: '10px' }}>
                    <Msg k="pageInfo.socialShare.header1">
                        Add the image you want to display when this page is shared on social networks.
                    </Msg>
                </div>

                <div className={styles.socialShareContainer}>
                    <div className={styles.bodyLeftHalf}>
                        <VerticalSpacer y={12} />
                        <ImageChooserBtnPP
                            asset={socialShareAsset}
                            headLabel="msg: socialShare.image {Social share image}"
                            chooseLabel="msg: addImage {Add image}"
                            changeAction={PAGE_INFO_SOCIAL_SHARE_ASSET_ADDED}
                            removeAction={PAGE_INFO_SOCIAL_SHARE_ASSET_REMOVED}
                            removeButtonTitle={intl.msgJoint("msg: socialShare.deleteImage {Delete social share image}")}
                            dispatch={dispatch}
                            labelMaxWidth={110}
                        />
                        <VerticalSpacer y={8} />
                        {socialShareImageHint}

                        <VerticalSpacer y={245} />
                        <LinkButtonCom
                            className={styles.editTitle}
                            onClick={() => {
                                dispatch(switchPageInfoTabAction(PageInfoTab.SEO));
                            }}
                        >
                            {'msg: pageInfo.socialShare.editTitle {Edit title and description}'}
                        </LinkButtonCom>
                    </div>

                    <div className={styles.bodyRightHalf}>
                        <SocialShareImagePreview
                            socialShareImageAsset={socialShareAsset}
                            removeSocialShareActionType={PAGE_INFO_SOCIAL_SHARE_ASSET_REMOVED}
                            socialShareImageChoosenActionType={PAGE_INFO_SOCIAL_SHARE_ASSET_ADDED}
                            dispatch={dispatch}
                            width={394}
                            height={204}
                            headLabel="msg: socialShare.preview {Preview}"
                            className={styles.socialShareImagePreviewContainer}
                            domainUrl={urlParams.cleanedDisplayUrl}
                            websiteTitle={displayTitle}
                            /* eslint-disable-next-line max-len */
                            socialShareDescription={description || intl.msgJoint("msg: socialShare.description {Description here is taken from the page description under the SEO tab in page info, and if there is none, it will automatically be generated based on content. }")}
                        />
                    </div>
                </div>

            </div>
        </div>
    );
});
