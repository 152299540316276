import type { RelativeDateFormat } from "../../flowTypes";

const dateFromNow = (date: number) => {
    const seconds = Math.floor((+(new Date()) - +date) / 1000);
    const years = Math.floor(seconds / 31536000);
    const months = Math.floor(seconds / 2592000);
    const days = Math.floor(seconds / 86400);

    if (days >= 320) {
        return {
            value: days > 548 ? years : 1,
            type: 'y'
        };
    }

    if (days >= 26 && days <= 319) {
        return {
            value: days > 45 ? months : 1,
            type: 'm'
        };
    }

    let hours = Math.floor(seconds / 3600);

    return {
        value: hours > 36 ? days : 1,
        type: 'd'
    };
};

const getRelativeTimeFromDate = (date): RelativeDateFormat => {
    const inputDate = (new Date(date)).getTime();
    if (!inputDate.valueOf()) {
        return {};
    }
    const { value, type } = dateFromNow(inputDate);
    const plural = value > 1 ? 's' : '';
    const typeUnitMap = { 'y': 'year', 'm': 'month', default: 'day' };
    const unit = (typeUnitMap[type] || typeUnitMap.default) + plural;
    return { key: unit, count: value };
};

export {
    getRelativeTimeFromDate
};
