import { OldComponentTypes } from "../../../../../dal/pageMapAdapter/componentTypesMap";

const extractTextFromTextComponents = (items) => {
    return items
        .reduce((acc, { type, text }) => {
            if (type === OldComponentTypes.TEXT) {
                acc.push(text);
            }
            return acc;
        }, []);
};

const clearGptDataInUrl = () => {
    const currURL = new URL(window.location.href);
    currURL.searchParams.delete('gptid');
    window.history.replaceState({}, '', currURL.toString());
};

export {
    extractTextFromTextComponents,
    clearGptDataInUrl
};
