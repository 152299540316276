import * as ActionTypes from './actionTypes';
import type { LinkTarget } from "../../../components/App/flowTypes";

type OpenWindowActionPayload = {
    url: string;
    name?: LinkTarget | string;
    specs?: string;
    replace?: boolean;
} | string;

export const
    openWindowAction = (payload: OpenWindowActionPayload) => ({ type: ActionTypes.WINDOW_OPEN, payload }),
    navigateToSelfAction = () => ({ type: ActionTypes.WINDOW_NAVIGATE_TO_SELF }),
    navigateToLocationAction = (url: string) => ({
        type: ActionTypes.WINDOW_NAVIGATE_TO_LOCATION,
        payload: url,
    }),
    replaceLocationAction = (url: string) => ({
        type: ActionTypes.WINDOW_REPLACE_LOCATION,
        payload: url,
    }),
    navigateToBlankLocationAction = (url: string) => ({
        type: ActionTypes.WINDOW_NAVIGATE_TO_BLANK_LOCATION_ACTION,
        payload: url,
    }),
    setWindowTitle = (windowTitle: string) => ({
        type: ActionTypes.SET_WINDOW_TITLE,
        payload: { windowTitle }
    }),
    navigateToPopupLocationAction = (url: string, windowName: string, windowSettings?: string) => ({
        type: ActionTypes.WINDOW_NAVIGATE_TO_POPUP_LOCATION_ACTION,
        payload: {
            targetUrl: url,
            windowName,
            windowSettings,
        },
    });
