import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import templateOffsetValueActionType from '../../../oneweb/Template/epics/templateOffset/valueActionType';
import { WINDOW_MOUSE_MOVE } from "../../../../redux/modules/actionTypes";
import { CodeComponentsRendererHeadHeightSelector } from "../../CodeComponentsRenderer/epic/selectorActionTypes";
import { memoMaxOne } from '../../../../../utils/memo';

const memoPosition = memoMaxOne((x, y) => ({ x, y }));

export default makeEpic({
    defaultState: { x: 0, y: 0 },
    valueActionType,
    updaters: [
        {
            conditions: [
                WINDOW_MOUSE_MOVE,
                templateOffsetValueActionType,
                CodeComponentsRendererHeadHeightSelector
            ],
            reducer: ({
                values: [
                    { x, y },
                    templateOffset,
                    componentsRendererHeadHeight
                ]
            }) => {
                if (typeof window !== 'undefined' && window.$R) {
                    window.$R.currentMousePosition = { x, y };
                }
                return {
                    state: memoPosition(x - templateOffset.x, y - templateOffset.y - componentsRendererHeadHeight)
                };
            }
        }
    ]
});
