import type { CmpSpecificStyles } from "../../Preview/flowTypes";
import "../../Preview/flowTypes";
import type { OpeningHoursComponent } from "./flowTypes";
import * as selectors from "../../Workspace/epics/stylesheets/selectors";
import { MAX_FONT_SIZE, MIN_FONT_SIZE } from "../Text/constants";
import { GROUPING_OPTIONS } from "./constants";

const capTextFontSize = size => {
    return Math.min(Math.max(MIN_FONT_SIZE, size), MAX_FONT_SIZE);
};

export const getSpecificStyles = ({ component, globalStyles: stylesheets }: CmpSpecificStyles<OpeningHoursComponent>): string => {
    const {
            id,
            horizontalAlignment,
            mobileHorizontalAlignment,
            groupDays,
            textStyle: { fontSize, mobileFontSize }
        } = component,
        componentMobileSelector = `.mobileV div[data-id='${id}'] `,
        textNormalStyle = selectors.textNormalGlobalstyle(stylesheets),
        textSize = mobileFontSize || capTextFontSize(fontSize || textNormalStyle.size),
        alignment = mobileHorizontalAlignment !== null ? mobileHorizontalAlignment : horizontalAlignment;
    let dtAlignment, ddAlignment;

    if (groupDays === GROUPING_OPTIONS.DONT_GROUP) {
        dtAlignment = alignment === "center" ? "right" : "left";
        ddAlignment = alignment === "right" ? "right" : "left";
    } else {
        dtAlignment = alignment;
        ddAlignment = alignment;
    }

    return `
        ${componentMobileSelector} dl {
            font-size: ${textSize}px !important;
        }
        ${componentMobileSelector} dt {
            text-align: ${dtAlignment} !important;
            white-space: normal !important;
            word-break: break-word;
        }
        ${componentMobileSelector} dl div div {
            width: auto !important;
        }
        ${componentMobileSelector} dd {
            text-align: ${ddAlignment} !important;
            white-space: normal !important;
            word-break: break-word;
        }
    `;
};
