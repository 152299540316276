import React from 'react';
import Page from "../../../../PropertiesPanel/view/PropertiesPage";
import actionTypes from '../../reducer/actionTypes';
import injectIntl from '../../../../../view/intl/injectIntl';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import CheckBox from '../../../../../view/common/CheckBox/CheckBoxWithLabel';
import * as pagesIds from './ids';
import pagesTitles from './titles';
import type { WebshopComponent } from '../../flowTypes';
import PropertyContainer from "../../../../../view/common/PropertyContainer/index";
import Combobox from "../../../../../view/common/Combobox/IntlCombobox";
import { categoryPositionOptions } from "../../options";

type NavigationViewProps = {
    dispatch: Function,
    selectedComponent: WebshopComponent
}

const id: string = pagesIds.NAVIGATION,
    title: string = pagesTitles[pagesIds.NAVIGATION],
    view = injectIntl(({ dispatch, selectedComponent }: NavigationViewProps) => {
        const {
            showCategory: isShowCategoryChecked,
            showSearchBar: isShowSearchBarChecked,
            userCanSortProducts: isSortByChecked,
            categoriesPosition
        } = selectedComponent;

        return (
            <Page>
                <VerticalSpacer />
                <CheckBox
                    label="msg: component.webshop.showCategorySelector {Show category selector}"
                    isChecked={isShowCategoryChecked}
                    onClick={() => dispatch({
                        type: actionTypes.WEBSHOP_SHOW_CATEGORY_SELECTOR_CHANGED
                    })}
                />
                <VerticalSpacer y={5} />
                <CheckBox
                    label='msg: component.webshop.showSortBySelector {Show \"Sort by\" selector}'
                    isChecked={isSortByChecked}
                    onClick={() => dispatch({
                        type: actionTypes.WEBSHOP_CAN_SORT_PRODUCTS_CHANGED
                    })}
                />
                <VerticalSpacer y={5} />
                <CheckBox
                    label="msg: component.webshop.showSearchBar {Show search bar}"
                    isChecked={isShowSearchBarChecked}
                    onClick={() => dispatch({
                        type: actionTypes.WEBSHOP_SHOW_SEARCH_BAR_CHANGED
                    })}
                />
                <VerticalSpacer y={20} />
                <PropertyContainer label="msg: webshop.categoryPosition.label {Category selector position}">
                    <Combobox
                        editable={false}
                        value={categoriesPosition}
                        options={categoryPositionOptions}
                        onChange={({ value }) => dispatch({ type: actionTypes.WEBSHOP_CATEGORY_POSITION_CHANGED, payload: value })}
                        searchable={false}
                        disabled={!isShowCategoryChecked}
                    />
                </PropertyContainer>
            </Page>
        );
    });

export { id, title, view };
