import { makeEpic } from "../../../epics/makeEpic";
import * as actionTypes from "../actionTypes";
import { changeToUpdateSiteDocumentsFormat, componentToTypeMap, SITE } from "../utils";
import { closeDialog, updateSiteData, updateSiteDocuments } from "../../../components/App/actionCreators/index";
import { SITE_DATA_UPDATE_SUCCESS, SITE_DOCUMENTS_UPDATE_SUCCESS } from "../../../components/App/actionTypes";
import { anyOf } from "../../../epics/makeCondition";

const
    defaultState = {
        jsonToDbData: "",
        isInvalidInput: false
    },
    defaultScope = {
        savingToDbInProgress: false
    };

const provideJsonToDbEpic = makeEpic({
    defaultState,
    defaultScope,
    valueActionType: "PROVIDE_JSON_TO_DB_VALUE_ACTION_TYPE",
    updaters: [
        {
            conditions: [actionTypes.JSON_TO_DB_CHANGE],
            reducer: ({ values: [{ jsonToDbData }], state, scope }) => {
                return {
                    state: { ...state, jsonToDbData },
                    scope
                };
            }
        },
        {
            conditions: [actionTypes.SAVE_JSON_TO_DB],
            reducer: ({ state, scope }) => {
                const { jsonToDbData } = state;
                const documentsToUpdate = changeToUpdateSiteDocumentsFormat(jsonToDbData);
                if (documentsToUpdate) {
                    let actionToDispatch: Action = updateSiteDocuments(documentsToUpdate);
                    if (documentsToUpdate[componentToTypeMap[SITE]] &&
                        documentsToUpdate[componentToTypeMap[SITE]].type === SITE
                    ) {
                        // @ts-ignore
                        actionToDispatch = updateSiteData(documentsToUpdate);
                    }

                    return {
                        state: { ...state, isInvalidInput: false },
                        scope: { ...scope, savingToDbInProgress: true },
                        actionToDispatch
                    };
                }
                return {
                    state: { ...state, isInvalidInput: true },
                    scope
                };
            }
        },
        {
            conditions: [anyOf(SITE_DOCUMENTS_UPDATE_SUCCESS, SITE_DATA_UPDATE_SUCCESS)],
            reducer: ({ state, scope }) => {
                const { savingToDbInProgress } = scope;
                if (savingToDbInProgress) {
                    return {
                        state,
                        scope: { ...scope, savingToDbInProgress: false },
                        actionToDispatch: closeDialog()
                    };
                }
                return { state, scope };
            }
        }
    ]
});

export {
    provideJsonToDbEpic
};
