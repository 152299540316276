export default (
    state: any, action: any
) => {
    return {
        ...state,
        generic: {
            ...state.generic,
            themeOverrideColor: action.payload.themeColor
        }
    };
};
