import { isDemoSubscriptionType } from '../../../App/epics/subscriptionData/isDemoSubscriptionType';
import type { validateComponentOnAddType } from '../../../../redux/modules/children/workspace/flowTypes';
import { VideoIsDisabledInTrial } from './errorMessagesOnAdd';

export default (dataToValidate: validateComponentOnAddType) => {
    const { subscriptionData } = dataToValidate;
    if (isDemoSubscriptionType(subscriptionData)) {
        return VideoIsDisabledInTrial;
    }
    return null;
};
