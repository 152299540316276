import React from 'react';
import styles from '../FileChooserUpload.css';
import Scrollbar from '../../../Scrollbar/index';
import LongTextTip from '../../../LongTextTip/index';

type PropTypes = {
    fileNames: Array<string>
};

export default ({ fileNames }: PropTypes) => {
    return (
        <Scrollbar className={styles.fileList} height={styles.fileListHeight} autoHeight>
            <ul>{fileNames.map((f, i) => <li key={i}>
                <LongTextTip className={styles.errorDialogListText}>
                    {f}
                </LongTextTip>
            </li>)}</ul>
        </Scrollbar>
    );
};
