import React from "react";
import { injectIntl, Intl } from '../../../src/view/intl/index';
import Msg from "../../../src/view/intl/Msg";
import { getAppConfig } from "../../../src/components/App/epics/appConfig/appConfig";
import { PartnerName } from '../../../../server/shared/partners/PartnerName';

type Props = {
    intl: Intl,
    className: '',
    isPrivacyPolicy?: boolean
};

const
    PrivacyPolicyUrlLink = injectIntl((props: Props) => {
        const appConfig = getAppConfig(),
            trialConfig = appConfig.oneWeb.trial;
        const { intl, className, isPrivacyPolicy } = props;
        return (
            <a
                href={trialConfig.partnerName === PartnerName.ONECOM ?
                    intl.msgJoint(trialConfig.privacyPolicyUrl) : trialConfig.privacyPolicyUrl}
                target="_blank"
                className={className}
            >{isPrivacyPolicy ? <Msg k="demo.signup.termsAndCondition.privacyPolicy">Privacy policy</Msg> : <Msg k="common.privacy">
                Privacy</Msg>}</a>
        );
    });

export default PrivacyPolicyUrlLink;
