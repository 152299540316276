import React from "react";
import { Flex } from '../../../../../view/reflexbox/index';
import pagesTitles from "./titles";
import * as pagesIds from "./ids";
import { SVG_SET_SEO } from "../../actionTypes";
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';
import InputField from '../../../../../view/common/Input/PropertiesInputField';
import lengthInputValidation from "../../../../inputControls/input/commonValidations/lengthInputValidation";
import { IMAGE_ALT_MAX_LENGTH } from '../../../Image/constants';
import stylePropertyContainer from '../../../../../view/common/PropertyContainer/PropertyContainer.css';
import styles from '../../../Image/propertiesPanel/propertiesPanel.css';

import type { PPPageViewPropTypes } from '../../flowTypes';

const
    id = pagesIds.SEO,
    title = pagesTitles[pagesIds.SEO],
    view = ({ selectedComponent, dispatch }: PPPageViewPropTypes) => (
        <Page>
            <VerticalSpacer />
            <PropertyContainer
                label="msg: component.svg.svgTitleAlt {SVG title (alt)}"
                className={stylePropertyContainer.pointer}
                labelIcon={{
                    icon: styles.captionIcon,
                    popup: {
                        message: 'msg: common.svg.title.helpText {This title will also be used as “alt” text for the SVG. Add \"alt\" texts to all your SVGs to push pages higher up on search results and boost your SEO.}', // eslint-disable-line
                    }
                }}
            >
                <Flex>
                    <InputField
                        value={selectedComponent.seo || ''}
                        onChange={payload => dispatch({ type: SVG_SET_SEO, payload })}
                        placeholder="msg: common.enterTitleHere {Enter title here}"
                        inputValidations={[
                            {
                                validation: lengthInputValidation,
                                options: { max: IMAGE_ALT_MAX_LENGTH },
                            }
                        ]}
                        rejectInvalidValue
                        trimValueOnPaste
                        counter={{
                            maxLength: IMAGE_ALT_MAX_LENGTH,
                        }}
                    />
                </Flex>
            </PropertyContainer>
        </Page>
    );

export { id, view, title };
