/* eslint-disable indent */
module.exports = [
    'html { word-wrap: break-word; }',
    '*, ::before, ::after { word-wrap: inherit; }',

    'html { box-sizing: border-box; }',
    '*, ::before, ::after { box-sizing: inherit; }',

    'div.code { box-sizing: content-box; }',    // For code component, we should use the default box-sizing
    'body, ul, ol, li, h1, h2, h3, h4, h5, h6, form, input, p, td, textarea { margin: 0; padding: 0; }',
    'h1, h2, h3, h4, h5, h6 { font-size: 100%; font-weight: normal; }',
    'ol, ul { list-style: none; }',
    'table { border-collapse: collapse; border-spacing: 0; }',
    'img { border: 0; }',

    // https://www.zachleat.com/web/font-smooth/
    // This style is also being used by Twitter
    'body { -moz-osx-font-smoothing: grayscale; -webkit-font-smoothing: antialiased; }'
].join('\n');
