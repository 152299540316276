import React from 'react';
import { Accordion } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { TaskSummary } from './TaskSummary';
import { TaskItemKeyT } from "../../types";
import { getTasks } from "../utils";
import { TaskDetails } from './TaskDetails';

const useStyles = makeStyles(
    (theme) => ({
        root: {
            borderTop: `1px solid ${theme.palette.custom.colorGray_ed}`,
            [theme.breakpoints.up("sm")]: {
                borderRight: `1px solid ${theme.palette.custom.colorGray_ed}`,
                borderLeft: `1px solid ${theme.palette.custom.colorGray_ed}`,
            },
        },
    }),
    { name: "Task" },
);

type TaskProps = {
    type: string;
    taskItemKey: TaskItemKeyT;
    isPassed: boolean;
    expanded: boolean | TaskItemKeyT;
    isPending: boolean;
    data: any;
    handleChange: (taskItemKey: TaskItemKeyT) => (e: React.ChangeEvent<{}>, isExpanded: boolean) => void;
    expandedTaskRef?: React.RefObject<HTMLDivElement> | null;
};

export const Task = ({ type, taskItemKey, isPassed, isPending, expanded, data, handleChange, expandedTaskRef }: TaskProps) => {
    const classes = useStyles();
    const TASKS = getTasks(type);

    return (
        <Accordion
            expanded={expanded === taskItemKey}
            onChange={handleChange(taskItemKey)}
            data-testid={`${type}-report-accordion`}
            classes={{ root: classes.root }}
            ref={expandedTaskRef}
        >
            <TaskSummary
                taskItemKey={taskItemKey}
                task={TASKS[taskItemKey]}
                type={type}
                isPassed={isPassed}
                isPending={isPending}
                expanded={expanded}
                data={data}
            />

            <TaskDetails
                taskItemKey={taskItemKey}
                task={TASKS[taskItemKey]}
                type={type}
                isPassed={isPassed}
                data={data}
                handleChange={handleChange}
            />
        </Accordion>
    );
};
