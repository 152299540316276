import { WidgetsEventbriteKind } from './kind';
import { calcRenderProps } from './calcRenderProps';
import { EventbriteView } from './view/index';

const WidgetsEventbritePreviewConfig = {
    kind: WidgetsEventbriteKind,
    view: EventbriteView,
    calcProps: ({ component }) => calcRenderProps({
        component,
        componentExtension: { isTransient: false },
    }),
};

export { WidgetsEventbritePreviewConfig };
