/*eslint max-len: ["error", { "ignoreStrings": true }]*/

export const
    CANNOT_ADD_TO_TEMPLATE = 'msg: nonTemplateComponent.cannotAddToTemplate.info {This component cannot be added to header or footer. Drag it to a section to add it on your website}',
    CANNOT_MOVE_TO_TEMPLATE = 'msg: nonTemplateComponent.cannotMoveToTemplate.info {This component cannot be added to a header or footer}',
    CANNOT_ADD_COMPONENT_TO_HEADER = 'msg: modernLayout.header.cannotAddComponent.info {This component cannot be added to the header. Drag it to a section to add it to your website.}',
    CANNOT_ADD_COMPONENT_TO_FOOTER = 'msg: modernLayout.footer.cannotAddComponent.info {This component cannot be added to the footer. Drag it to a section to add it to your website.}',
    CANNOT_MOVE_MODERN_HEADER_SECTION_COMPONENTS = "msg: modernLayout.cannotMoveComponent.header.info {It's not possible to move components when using header layouts}",
    CANNOT_MOVE_MODERN_FOOTER_SECTION_COMPONENTS = "msg: modernLayout.cannotMoveComponent.footer.info {It's not possible to move components when using footer layouts}";

