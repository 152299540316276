import {
    SITE_DOCUMENTS_UPDATE_REQUEST,
    SITE_DOCUMENTS_UPDATE_SUCCESS,
    SITE_DOCUMENTS_UPDATE_FAILURE
} from '../actionTypes';
import updateSiteDocumentFactory from "./updateSiteDocumentFactory";
import type { ApiActionTypes } from "../../../redux/middleware/api/flowTypes";

const
    updateSiteDocument = updateSiteDocumentFactory([
        SITE_DOCUMENTS_UPDATE_REQUEST,
        SITE_DOCUMENTS_UPDATE_SUCCESS,
        SITE_DOCUMENTS_UPDATE_FAILURE
    ]),
    updateSiteDocumentWithCustomActions = (types: ApiActionTypes) => updateSiteDocumentFactory(types);

export {
    updateSiteDocument as default,
    updateSiteDocument,
    updateSiteDocumentWithCustomActions
};
