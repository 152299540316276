/* eslint-disable no-useless-escape */

import React from "react";
import styles from './note.css';
import injectIntl from "../../../../view/intl/injectIntl";
import CloseBtn from '../../../../view/common/dialogs/baseDialog/DialogCloseBtn';
import actionTypes from '../reducer/actionTypes';
import { WEBSHOP_CTA_BUTTON_TEXT } from "../translations";

export default injectIntl(({ intl, dispatch }) => (<div className={styles.note}>
    {
        intl.msgJoint(['msg: component.webshop.note {You can add and edit products and prices by clicking \"{button}\" in the toolbar.}', { button: intl.msgJoint(WEBSHOP_CTA_BUTTON_TEXT) }]) // eslint-disable-line max-len
    }
    <CloseBtn
        className={styles.closeBtn}
        onClick={() => {
            dispatch({ type: actionTypes.WEBSHOP_CLOSE_NOTE });
        }}
    />
</div>
));
