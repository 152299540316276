import type { FileManagerState } from "../flowTypes";
import Resource from "../Resource";

export default (state: FileManagerState, { payload: { resource } }: any) => {
    const
        { isMultiSelect, maxMultiSelectValidation } = state,
        selection = isMultiSelect ? [...state.selection] : [];
    let isMaxMultiSelect = false;

    const i = selection.map((r: Resource) => r.getId()).indexOf(resource.getId());
    if (isMultiSelect && i !== -1) {
        // remove from selection
        selection.splice(i, 1);
    } else if (
        isMultiSelect &&
        maxMultiSelectValidation &&
        selection.length === maxMultiSelectValidation.remaniningLimit
    ) {
        isMaxMultiSelect = true;
    } else {
        selection.push(resource);
    }

    return { ...state, selection, isMaxMultiSelect, allIsSelected: false };
};
