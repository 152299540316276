/* eslint-disable max-len */

import {
    calculateImageDimensionsByContainerWidth,
    calculateImageDimensionsByContainerHeight,
    roundOutput
} from './calculateImageDimensionsUtils';
import type { Dimensions } from '../../../../redux/modules/children/workspace/flowTypes';

export default (containerWidth: number, containerHeight: number, assetWidth: number, assetHeight: number): Dimensions => {
    const
        containerRatio = (containerWidth / containerHeight),
        assetRatio = (assetWidth / assetHeight);

    let imageDimensions = { width: containerWidth, height: containerHeight };

    if (containerRatio < assetRatio) {
        imageDimensions = calculateImageDimensionsByContainerHeight(assetWidth, assetHeight, containerHeight);
    } else if (containerRatio > assetRatio) {
        imageDimensions = calculateImageDimensionsByContainerWidth(assetWidth, assetHeight, containerWidth);
    }

    return roundOutput(imageDimensions);
};
