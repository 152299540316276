import React from "react";
import FourFieldBordersConfigurationDialogId from "../FourFields/FourFieldBorders/configurationDialogId";
import TextIconButton from '../TextIconButton/factory';
import type { BorderButtonPropTypes } from "./flowTypes";

export default ({ borderWidth, borderWidthChangeAction, dispatch }: BorderButtonPropTypes) => (
    <TextIconButton
        text="msg: common.borderSize.noPixel {Border size}"
        dialogId={FourFieldBordersConfigurationDialogId}
        dialogProps={{ values: borderWidth, onChangeAction: borderWidthChangeAction }}
        dispatch={dispatch}
    />
);
