import { SelectionFrameMargin, SelectionFrameBorderWidth, SelectionFrameThickBorderWidth } from '../../../constants/app';
import type { BBox } from '../../../redux/modules/children/workspace/flowTypes';
import type { BorderProps } from '../../../components/presentational/Border/flowTypes';

type Props = {
    bBox: BBox,
    opacity?: number,
    animate?: boolean,
    makeInvisible?: boolean,
    color?: string,
    thickBorder?: boolean
}

const makeBorderProps = ({
    bBox: { top, left, right, bottom },
    opacity = 1,
    animate = false,
    makeInvisible = false,
    color,
    thickBorder = false,
}: Props): BorderProps => {
    const borderWidth = thickBorder ? SelectionFrameThickBorderWidth : SelectionFrameBorderWidth,
        halfBorderWidth = borderWidth > SelectionFrameBorderWidth ? borderWidth / 2 : borderWidth;
    return {
        bBox: {
            top: top - SelectionFrameMargin - halfBorderWidth,
            left: left - SelectionFrameMargin - borderWidth,
            right: right + SelectionFrameMargin + borderWidth,
            bottom: bottom + SelectionFrameMargin + halfBorderWidth
        },
        width: borderWidth,
        opacity,
        animate,
        makeInvisible,
        color
    };
};

export {
    makeBorderProps
};
