/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useIntl } from '../../../../../../../view/intl/index';

import { ValidateInput } from '../Components/ValidateInput';
import { FIELD_LABELS, VALIDATION_FIELDS } from '../constants';

import { selectErrorFields } from '../../../../epics/AITextEpic/selectors';

type Props = {
    targetAudience: string,
    onChangeAction: Function,
}

export const TargetAudienceField = ({ onChangeAction, targetAudience = '' }: Props) => {
    const intl = useIntl();
    const errorFields = useSelector(selectErrorFields);

    const fieldKey = FIELD_LABELS.targetAudience;

    const { max: maxLength } = VALIDATION_FIELDS[fieldKey];
    const [isLong, setIsLong] = useState(targetAudience?.trim().length > maxLength);

    const onChange = (key, value, errorflag) => {
        setIsLong(errorflag);
        onChangeAction(key, value);
    };

    return (
        <ValidateInput
            fieldKey={fieldKey}
            fieldLabel={intl.msgJoint("msg: component.text.createAIText.dialog.generalPreferences.targetAudience.label {Who is your target audience? (optional)}")}
            placeholder="msg: component.text.createAIText.dialog.generalPreferences.targetAudience.placeholder {e.g. families with children}"
            fieldValue={targetAudience}
            errorFields={errorFields}
            onChange={onChange}
            isInvalid={isLong}
            validationMsg={intl.msgJoint("msg: common.error.longInput {The entered text is too long.}")}
            dataTestId="ai-text-target-audience-field"
        />
    );
};
