import { applyMappers } from "../utils";
import { to as mapperRegistryTo, back as mapperRegistryBack, afterMappingTo } from "../mapperRegistry";
import * as baseMapper from "./Base/index";
import type { ComponentWithId } from "../../../src/redux/modules/children/workspace/flowTypes";
import type { AnyComponentData } from "../../../src/components/App/flowTypes";
import type { Stylesheets } from "../../../src/components/Workspace/epics/stylesheets/flowTypes";
import { DataSite } from "../../model/index";

export type ToDeps = {
    stylesheets: Stylesheets,
    componentsData: Array<AnyComponentData>,
    site: null | undefined | DataSite,
    templateId: string,
    globalVariables: Record<string, any>,
    isPreview: boolean
}

export type BackDeps = {
    stylesheets?: Stylesheets,
    site?: DataSite
}

export function mapToComponent(
    componentData: any,
    deps?: ToDeps,
    calledFromUpdateItemsRelations: boolean = false
): ComponentWithId {
    const typeMapper = mapperRegistryTo[componentData.type];

    if (!typeMapper) {
        throw new Error(`mapper for ${componentData.type} not found`);
    }

    const
        component = applyMappers(
            componentData,
            baseMapper.to,
            // @ts-ignore check and remove last 2 params
            data => typeMapper(data, deps, calledFromUpdateItemsRelations)
        ),
        afterMappingToHook = afterMappingTo[componentData.type];

    return {
        id: componentData.id,
        // @ts-ignore check and remove last param
        component: afterMappingToHook ? afterMappingToHook(component, deps, calledFromUpdateItemsRelations) : component
    };
}

export function mapBackFromComponent(component: any, deps: BackDeps, calledFromUpdateItemsRelations?: true): any {
    const typeMapper = mapperRegistryBack[component.kind];

    if (!typeMapper) {
        return undefined;
    }

    return applyMappers(
        component,
        baseMapper.back,
        data => typeMapper(data, deps, calledFromUpdateItemsRelations)
    );
}
