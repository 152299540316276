import * as R from 'ramda';
import type { CodeComponent } from "./flowTypes";
import { GhostComponentsBodyEndSection, GhostComponentsHeadSection } from "../../../utils/handle/kinds";
import CodeComponentLocationTypes from "./locationTypes";

export const adjustCodeComponentOnAdd =
    (component: CodeComponent, _: any, topmostHandleKind: string): CodeComponent =>
        R.evolve({
            location: (location) => {
                if (topmostHandleKind === GhostComponentsHeadSection) {
                    return CodeComponentLocationTypes.BeforeClosingHead;
                } else if (topmostHandleKind === GhostComponentsBodyEndSection) {
                    return CodeComponentLocationTypes.BeforeClosingBody;
                }

                return location;
            }
        })(component);
