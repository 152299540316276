
import { formatMessage } from '../../../view/intl/CurrentLocale';

export const getEmailPlaceholder = () => (formatMessage({
    id: 'email.placeholder',
    defaultMessage: 'your@email.com'
}));

export const getPhonePlaceholder = () => (formatMessage({
    id: 'phone.placeholder',
    defaultMessage: '+44 1234 567890'
}));

export const getAddressPlaceholder = () => ({
    addressName: formatMessage({ id: 'common.businessTitle', defaultMessage: 'Business title' }),
    addressStreetAddress: formatMessage({ id: 'common.streetAddress', defaultMessage: 'Street address' }),
    addressCity: formatMessage({ id: 'common.city', defaultMessage: 'City' }),
    addressZip: formatMessage({ id: 'common.zip', defaultMessage: 'Zip code' }),
    addressAreaState: formatMessage({ id: 'common.state', defaultMessage: 'State' }),
    addressAreaProvince: formatMessage({ id: 'common.province', defaultMessage: 'Province' }),
    addressCountry: formatMessage({ id: 'common.country', defaultMessage: 'Country' }),
});
