import React from "react";
export const SvgTumblrRoundedFillLight = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <rect width={48} height={48} fill="#ffffff" rx={6} />
            <path
                fill="#3c3c3c"
                d="M25.309 22.614v4.764c0 1.21-.016 1.906.112 2.248.127.341.446.695.794.9.461.276.987.414 1.58.414 1.055 0 1.678-.14 2.721-.825v3.132c-.889.418-1.665.663-2.387.833a10.37 10.37 0 01-2.34.252c-.953 0-1.515-.12-2.247-.36a5.465 5.465 0 01-1.872-1.027c-.517-.446-.875-.92-1.075-1.42-.2-.502-.3-1.23-.3-2.183v-7.305H17.5v-2.95c.818-.266 1.733-.647 2.315-1.143a5.704 5.704 0 001.406-1.789c.353-.694.597-1.58.73-2.655h3.358v5.208h5.207v3.906H25.31z"
            />
        </g>
    </svg>
);
