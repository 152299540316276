import {
    makeCombineReducer,
    makeComponentBaseReducers,
    makeDefaultStateReducers,
    makePayloadToSetterReducers,
    makeNegationPathPayloadToSetterReducers, makePayloadPathReducer
} from "../../../../redux/makeReducer/index";
import * as actionTypes from '../actionTypes';
import {
    TOGGLE_MOBILE_DOWN_ACTION
} from "../../../PropertiesPanel/view/MobileView/actions";
import mobileDownReducer from '../../../PropertiesPanel/view/MobileView/mobileDownReducer';
import * as sortingWindowActionTypes from '../../../SortImagesDialog/actionTypes';
import kind from '../kind';
import { DEFAULT_WIDTH, DEFAULT_HEIGHT, defaultState, OnClickActions } from '../constants';

export default makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(kind, DEFAULT_WIDTH, DEFAULT_HEIGHT),
        ...makeDefaultStateReducers(defaultState)
    },
    handleActions: {
        [TOGGLE_MOBILE_DOWN_ACTION]: mobileDownReducer,
        [sortingWindowActionTypes.SORT_IMAGES_DIALOG_SAVE_RESULT]: (component, { payload: { images, captionsEnabled } }) => { // eslint-disable-line max-len
            const onClickAction = images.some(image => image.action && image.action.link) ? OnClickActions.OPEN_LINK : component.onClickAction;  // eslint-disable-line max-len
            return { ...component, images, onClickAction, captionsEnabled };
        },
        ...makePayloadToSetterReducers({
            [actionTypes.IMAGE_SLIDER_CAPTIONS_PLACEMENT_CHANGED]: 'captionsPlacement',
            [actionTypes.IMAGE_SLIDER_ON_CLICK_ACTION_CHANGED]: 'onClickAction',
            [actionTypes.IMAGE_SLIDER_TRANSITION_METHOD_CHANGED]: 'transition',
            [actionTypes.IMAGE_SLIDER_CONTROLS_SET_FWD_BK_METHOD_CHANGED]: 'navigator',
            [actionTypes.IMAGE_SLIDER_CONTROLS_SHOW_INDICATOR_METHOD_CHANGED]: 'indicator',
            [actionTypes.IMAGE_SLIDER_TRANSITION_SHOW_NEXT_IMAGE_DELAY_CHANGED]: 'delay',
            [actionTypes.IMAGE_SLIDER_CAPTIONS_ALIGNMENT_CHANGED]: 'captionsAlignment'
        }),

        [actionTypes.IMAGE_SLIDER_PP_FULL_WIDTH_MARGIN]: makePayloadPathReducer(['fullWidthOption', 'margin']),
        [actionTypes.IMAGE_SLIDER_PP_FULL_WIDTH_MAX_WIDTH]: makePayloadPathReducer(['fullWidthOption', 'maxWidth']),

        ...makeNegationPathPayloadToSetterReducers({
            [actionTypes.IMAGE_SLIDER_TOGGLE_CAPTIONS]: ['captionsEnabled'],
            [actionTypes.IMAGE_SLIDER_TOGGLE_AUTO_CROP_TO_FIT]: ['crop'],
            [actionTypes.IMAGE_SLIDER_TRANSITION_TOGGLE_SHOW_NEXT_IMAGE_METHOD]: ['autoNext'],
            [actionTypes.IMAGE_SLIDER_PP_FULL_WIDTH]: ['stretch']
        })
    }
});
