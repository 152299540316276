import Image from '../oneweb/Image/kind';
import Text from '../oneweb/Text/kind';
import { ImageSpaceLimit, isSmallImage } from "./constants";
import type { AnyComponent, ComponentsMap } from "../../redux/modules/children/workspace/flowTypes";
import type { Group } from "./flowTypes";
import { canBeBigText, isParentSame } from "./util";
import { isStretchComponentKind } from "../oneweb/isStretchComponentKind";
import { getComponentZIndex } from "../Workspace/zIndex";
import type { Rect } from "../PagesTree/pagesLogic/EmptyRects";
import { isIntersecting } from "../Workspace/epics/componentsEval/utils";

type Groups = Array<Group>;

const ImageTextKinds = {
        [Image]: true,
        [Text]: true
    },
    leftTopHeightSorter = (c1, c2) => {
        if (c1.left === c2.left) {
            if (c1.top === c2.top) {
                return c1.height - c2.height;
            }
            return c1.top - c2.top;
        }
        return c1.left - c2.left;
    },
    tolerance = 3,
    ImageSpaceMaxLimit = ImageSpaceLimit + 1,
    topSorter = (a, b) => a.top - b.top,
    leftSorter = (a, b) => a.left - b.left,
    divideInToGroupsFromTop = (cmps) => {
        let groups: any[] = [], group: Record<string, any> = {};
        cmps.slice().sort(topSorter).forEach(cmp => {
            const { top, height } = cmp,
                bottom = top + height;
            if (!group.bottom) {
                group = { top, bottom, cmps: [cmp] };
            } else if ((top + tolerance) >= group.bottom) {
                groups.push(group);
                group = { top, bottom, cmps: [cmp] };
            } else {
                group.bottom = Math.max(group.bottom, bottom);
                group.cmps.push(cmp);
            }
        });
        if (group.bottom) {
            groups.push(group);
        }
        return groups.filter(g => g.cmps.length > 1);
    },
    divideInToGroupsFromLeft = (cmps, limit) => {
        let groups: any[] = [], group: Record<string, any> = {};
        cmps.slice().sort(leftSorter).forEach(cmp => {
            const { left, width } = cmp,
                right = left + width;
            if (!group.right) {
                group = { left, right, cmps: [cmp] };
            } else if (left > group.right + limit) {
                groups.push(group);
                group = { left, right, cmps: [cmp] };
            } else {
                group.right = Math.max(group.right, right);
                group.cmps.push(cmp);
            }
        });
        if (group.right) {
            groups.push(group);
        }
        return groups.filter(g => g.cmps.length > 1);
    },
    groupRect = (group, cmp): Rect => ({
        left: group.left + tolerance,
        right: Math.max(group.right, cmp.left + cmp.width) - tolerance,
        top: Math.min(group.top, cmp.top) + tolerance,
        bottom: Math.max(group.bottom, cmp.top + cmp.height) - tolerance
    }),
    shouldDivide = (cmp, prevCmp, allCmps, group, cmpsMap) => (
        !isParentSame(cmp, prevCmp) ||
        allCmps.some(c =>
            !ImageTextKinds.hasOwnProperty(c.kind) &&
            !isStretchComponentKind(c.kind, c.stretch) &&
            (
                !cmp.relIn ||
                (cmp.relIn.id !== c.id && getComponentZIndex(cmpsMap[cmp.relIn.id]) < getComponentZIndex(c))
            ) &&
            isIntersecting(
                group,
                { left: c.left, right: c.left + c.width, top: c.top, bottom: c.top + c.height }
            ))),
    divideInToFurtherGroups = (groupInp, allCmps, cmpsMap) => {
        let groups: any[] = [], group: Record<string, any> = {}, cmps = groupInp.cmps.slice().sort(leftTopHeightSorter);
        cmps.forEach((cmp, i) => {
            const { left, width, top, height } = cmp,
                right = left + width,
                bottom = top + height;
            if (!group.right) {
                group = { left, right, top, bottom, cmps: [cmp] };
            } else if (shouldDivide(cmp, cmps[i - 1], allCmps, groupRect(group, cmp), cmpsMap)) {
                groups.push(group);
                group = { left, right, top, bottom, cmps: [cmp] };
            } else {
                group.right = Math.max(group.right, right);
                group.top = Math.min(group.top, top);
                group.bottom = Math.max(group.bottom, bottom);
                group.cmps.push(cmp);
            }
        });
        if (group.right) {
            groups.push(group);
        }
        return groups.filter(g => g.cmps.length > 1);
    };

export default (cmpsMap: ComponentsMap): Groups => {
    const cmpIds = Object.keys(cmpsMap);
    const isChild = (cmp: AnyComponent) => cmp.relIn && cmp.relIn.id && cmpsMap[cmp.relIn.id];
    let selectedCmpsMap = {}, childCmps: Array<AnyComponent> = [], parentChildMap = {}, allCmps: Array<AnyComponent> = [];
    // Select only text and image cmps with matching dimensions
    for (let i = 0; i < cmpIds.length; i++) {
        const cmp = cmpsMap[cmpIds[i]];
        if ((cmp.kind === Image && isSmallImage(cmp)) || (cmp.kind === Text && canBeBigText(cmp))) {
            selectedCmpsMap[cmp.id] = true;
            if (isChild(cmp)) {
                if (!parentChildMap[cmp.relIn.id]) {
                    parentChildMap[cmp.relIn.id] = [];
                }
                parentChildMap[cmp.relIn.id].push(cmp);
            }
        } else if (isChild(cmp)) {
            childCmps.push(cmp.id);
        }
        allCmps.push(cmp);
    }
    // Remove all text cmps which has other cmps wrapped in it
    childCmps.forEach(id => {
        if (selectedCmpsMap[cmpsMap[id].relIn.id]) {
            delete selectedCmpsMap[cmpsMap[id].relIn.id];
        }
    });
    let result: Array<any> = [];
    Object.keys(parentChildMap).forEach(id => {
        const cmps = parentChildMap[id].filter(cmp => !!selectedCmpsMap[cmp.id]);
        let groupsByTop = divideInToGroupsFromTop(cmps), groupsByLeft: any[] = [], groups: any[] = [];
        groupsByTop.forEach(group => {
            groupsByLeft = groupsByLeft.concat(divideInToGroupsFromLeft(group.cmps, ImageSpaceMaxLimit));
        });
        groupsByTop = [];
        groupsByLeft.forEach((group: any) => {
            groupsByTop = groupsByTop.concat(divideInToGroupsFromTop(group.cmps));
        });
        groupsByTop.forEach(group => {
            groups = groups.concat(divideInToFurtherGroups(group, allCmps, cmpsMap));
        });
        result.push(...groups);
    });
    return result.map(group => group.cmps.sort(leftSorter));
};
