import React from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./ErrorModel.css";
import { errorTypeSelector } from "../../Epic/selectors";
import { ERROR_TYPES } from "./constants";
import { ConnectionError } from "./ConnectionError";
import { HighTrafficError } from "./HighTrafficError";
import { InappropriateLanguageError } from "./InappropriateLanguageError";
import { LimitError } from "./LimitError";
import { DYNAMIC_TEMPLATE_RESET_ERROR_TYPE } from "../../Epic/actionTypes";

const Error = () => {
    const errorType = useSelector(errorTypeSelector);

    switch (errorType) {
        case ERROR_TYPES.DYNAMIC_TEMPLATES_CONNECTION_ERROR:
            return <ConnectionError />;

        case ERROR_TYPES.DYNAMIC_TEMPLATES_HIGH_TRAFFIC_ERROR:
            return <HighTrafficError />;

        case ERROR_TYPES.DYNAMIC_TEMPLATES_INAPPROPRIATE_LANGUAGE_ERROR:
            return <InappropriateLanguageError />;

        case ERROR_TYPES.DYNAMIC_TEMPLATES_LIMIT_ERROR:
            return <LimitError />;

        default:
            return null;
    }
};

export const ErrorModel = () => {
    const dispatch = useDispatch();
    return (
        <div className={styles.errorModelContainer}>
            <div
                className={styles.topBarContainer}
                onClick={() => dispatch({ type: DYNAMIC_TEMPLATE_RESET_ERROR_TYPE })}
            >
                <div className={styles.logo} />
            </div>
            <Error />
        </div>
    );
};
