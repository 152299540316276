/* eslint-disable max-len */

import React from 'react';
import { SortableElement } from 'react-sortable-hoc';
import { ComponentMaskBackground } from '../presentational/ComponentMask/constants';
import ComponentMask from '../presentational/ComponentMask/index';
import styles from './SortImageDialog.css';
import { injectIntl } from "../../view/intl/index";
import { ProgressBar } from "../../view/common/FileChooser/ProgressBar";
import { ScaledWebspaceImage } from "../presentational/ScaledWebspaceImage/ScaledWebspaceImage";
import {
    LazyImageProxy
} from "../../view/common/FileChooser/FileChooserGrid/Cell/ResourceCellImage";

export default injectIntl(SortableElement<any>( // eslint-disable-line new-cap
    ({ image, imageIdx, containerCss, height, width }) => (
        <li className={containerCss}>
            <ComponentMask
                forceShowMask={false}
                maskStyle={ComponentMaskBackground.DARK}
            >
                <ScaledWebspaceImage
                    path={image._getFullPath()}
                    etag={image.etag}
                    width={height}
                    height={width}
                    lazyProxy={LazyImageProxy}
                    className={styles.cellImage}
                    cover
                    shouldLoad={!image.isGhost}
                />
                <ProgressBar show={image.isGhost && image.isLoading} className={styles.progressBar} />
                <div className={styles.imageIndex}>{imageIdx + 1}</div>
            </ComponentMask>
        </li>
    )
));
