import { SortColumns, SortDirections } from "./sortFunctions";
import type { FileManagerState } from "./flowTypes";
import appConfigEpic from '../../../../components/App/epics/appConfig/index';
import { getDefaultReducerState } from "../../../makeReducer/index";
import { FC_ONEWEBMEDIA_PATH } from './constants';
import { FmTabId } from '../../../../components/FileManager/dialog/constants';
import {
    ShutterstockInitialState
} from '../../../../components/FileManager/shutterstock/reducer/ShutterstockInitialState';
import { FileManagerMode } from "../../../../components/FileManager/constants";
import { ShutterstockKind } from "../../../../components/FileManager/shutterstock/constants";
import { ThirdPartyImageKind } from "../../../../components/FileManager/unsplash/components/constants";
import { UnsplashInitialState } from "../../../../components/FileManager/unsplash/reducer/UnsplashInitialState";

const defaultSort = {
    column: SortColumns.ADDED,
    direction: SortDirections.DESC,
};

// @ts-ignore
const defaultState: FileManagerState = {
    isInitialized: false,
    resources: [],
    ghostResources: [],
    currentPath: FC_ONEWEBMEDIA_PATH,
    sort: {
        currentColumn: defaultSort.column,
        currentDirectoryColumn: defaultSort.column,
        columns: (() => {
            const cols = {};
            Object.keys(SortColumns).forEach(k => {
                cols[SortColumns[k]] = SortDirections.DESC;
            });
            cols[defaultSort.column] = defaultSort.direction;
            return cols;
        })()
    },
    selection: [],
    allIsSelected: false,
    isMultiSelect: false,
    maxMultiSelectValidation: null,
    isMaxMultiSelect: false,
    contentTypes: '',
    // @ts-ignore
    activeViewMode: null,
    fileListUploadProgress: null,
    resourcesLoadProgress: false,
    appConfig: getDefaultReducerState(appConfigEpic.reducer),
    activeTabId: FmTabId.MY_IMAGES,
    [ShutterstockKind.FREE]: ShutterstockInitialState,
    [ShutterstockKind.PAID]: ShutterstockInitialState,
    freeOneComVideos: [],
    [ThirdPartyImageKind.UNSPLASH]: UnsplashInitialState,
    mode: FileManagerMode.FILE_MANAGER,
    headlessMode: false,
    forwardToComponent: false,
    onSaveAction: '',
    closeModalOnSave: true,
    computerUpload: false,
};

export default defaultState;
