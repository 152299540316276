class Node {
    type: string;
    start: number;
    end: number;
    href: string;
    target: string;
    single: boolean;
    globalId: string;
    ref: string;
    tag: string;
    text: string;
    styles: any;
    isFirstChild: boolean;
    childNodes: Array<Node>;
    parent: Node;

    constructor(element: any) {
        const {
            type,
            start,
            end,
            text,
            href,
            target,
            single,
            globalId,
            ref,
            childNodes = [],
            tag,
            styles,
            isFirstChild,
            parent
        } = element;
        this.parent = parent;
        this.type = type;
        this.start = start;
        this.end = end;
        this.text = text || '';
        this.href = href;
        this.target = target;
        this.globalId = globalId;
        this.ref = ref;
        this.tag = tag;
        this.styles = styles;
        this.childNodes = childNodes;
        this.single = single;
        this.isFirstChild = isFirstChild;
    }
}

export default Node;
