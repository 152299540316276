import { both, complement } from 'ramda';
import { isEmptyNode, isTextNode } from '../../../../utils/dom';

export const
    isNonEmptyNode = complement(isEmptyNode),
    toArray = function (nodeList: any) {
        let arr = [];
        let len = nodeList.length;
        for (let i = 0; i < len; i++) {
            // @ts-ignore
            arr.push(nodeList[i]);
        }
        return arr;
    },
    hasNonEmptyTextChildNodes = function (e: Element) {
        return toArray(e.childNodes).some(both(isTextNode, isNonEmptyNode));
    },
    hasNonEmptyTextChildNodesAtAnyLevel = function (e: Element) {
        if (hasNonEmptyTextChildNodes(e)) {
            return true;
        }

        const nodesArr = toArray(e.childNodes);
        const nodesArrLength = nodesArr.length;
        if (nodesArrLength) {
            for (let i = 0; i < nodesArrLength; i++) {
                if (hasNonEmptyTextChildNodesAtAnyLevel(nodesArr[i])) {
                    return true;
                }
            }
        }
        return false;
    },
    isFirstChildNodeNonEmptyTextNode = (e: Element): boolean => {
        const arr = toArray(e.childNodes);
        if (arr.length) {
            return both(isTextNode, isNonEmptyNode)(arr[0]);
        }
        return false;
    },
    getNonEmptyChildren = function (e: Element) {
        return toArray(e.children).filter(isNonEmptyNode);
    };
