import React from 'react';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import NavigationGroup from '../../../../PropertiesPanel/view/IntlNavigationGroup';
import * as pagesIds from '../pagesIds';
import pagesTitles from '../pagesTitles';

type Props = {
    navigateToPage: () => void
}

const
    id = pagesIds.MAIN,
    title = pagesTitles[pagesIds.MAIN],
    view = ({ navigateToPage }: Props) => (
        <Page>
            <NavigationGroup
                navigateToPage={navigateToPage}
                pagesTitles={pagesTitles}
                targetPagesIds={[pagesIds.STYLE, pagesIds.LINKS]}
            />
        </Page>
    );

export { id, view, title };
