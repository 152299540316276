import common from "../../../../../wbtgen/src/view/Theme/common.css";

export const
    HEIGHT = 16,
    WIDTH = 28,
    LINE_HEIGHT = 24,
    LINE_HEIGHT_XS = 26,
    SWITCH_BASE_PADDING = 3,
    BACKGROUND_COLOR_DISABLED = common.colorGray_bb,
    BACKGROUND_COLOR_ACTIVE = common.colorZundaGreen;
