import { getTemplatePagesApiAction } from '../../../redux/middleware/api/commonApiActions';
import type { TemplateCategoryT } from './flowTypes';

export const
    TEMPLATE_TITLE_CHANGE = 'TEMPLATE_TITLE_CHANGE',
    TEMPLATE_VERTICAL_SCROLL_TOGGLE = 'TEMPLATE_VERTICAL_SCROLL_TOGGLE',
    TEMPLATE_PAGES_SELECT_PAGE = 'TEMPLATE_PAGES_SELECT_PAGE',
    TEMPLATE_ASSET_CHANGE = 'TEMPLATE_ASSET_CHANGE',
    TEMPLATE_ASSET_UNSET = 'TEMPLATE_ASSET_UNSET',
    TEMPLATE_ASSET_CHANGE_REPEAT = 'TEMPLATE_ASSET_CHANGE_REPEAT',
    TEMPLATE_ASSET_CHANGE_POSITION = 'TEMPLATE_ASSET_CHANGE_POSITION',
    TEMPLATE_BG_IMAGE_SCROLL_EFFECT_CHANGE = 'TEMPLATE_BG_IMAGE_SCROLL_EFFECT_CHANGE',
    TEMPLATE_DISPLAY_SHOP_STRIP_IN_FOOTER = 'TEMPLATE_DISPLAY_SHOP_STRIP_IN_FOOTER',
    TEMPLATE_DISPLAY_SHOP_STRIP_IN_FOOTER_SET_TRUE = 'TEMPLATE_DISPLAY_SHOP_STRIP_IN_FOOTER_SET_TRUE',
    TEMPLATE_ASSET_CHANGE_SIZE = 'TEMPLATE_ASSET_CHANGE_SIZE',
    TEMPLATE_COLOR_CHANGE = 'TEMPLATE_COLOR_CHANGE',
    TEMPLATE_COLOR_REMOVE = 'TEMPLATE_COLOR_REMOVE',
    TEMPLATE_THEME_CHANGE = 'TEMPLATE_THEME_CHANGE',
    TEMPLATE_GRADIENT_COLOR_CHANGE = 'TEMPLATE_GRADIENT_COLOR_CHANGE',
    TEMPLATE_GRADIENT_COLOR_REMOVE = 'TEMPLATE_GRADIENT_COLOR_REMOVE',
    TEMPLATE_GRADIENT_COLOR_SET_AUTO_COLOR = 'TEMPLATE_COLOR_SET_AUTO_COLOR',
    TEMPLATE_GRADIENT_COLOR_UNSET_AUTO_COLOR = 'TEMPLATE_COLOR_UNSET_AUTO_COLOR',
    TEMPLATE_GRADIENT_DIRECTION_CHANGE = 'TEMPLATE_GRADIENT_DIRECTION_CHANGE',
    TEMPLATE_GRADIENT_FADE_POINT_CHANGE = 'TEMPLATE_GRADIENT_FADE_POINT_CHANGE',
    TEMPLATE_OPACITY_CHANGE = 'TEMPLATE_OPACITY_CHANGE',
    TEMPLATE_OPACITY_CHANGE_AUTO_COLOR = 'TEMPLATE_OPACITY_CHANGE_AUTO_COLOR',
    TEMPLATE_LOCALE_CHANGE = 'TEMPLATE_LOCALE_CHANGE',
    TEMPLATE_DELETE_MISSING_ASSET = 'TEMPLATE_DELETE_MISSING_ASSET',
    TEMPLATE_REPLACE_MISSING_ASSET = 'TEMPLATE_REPLACE_MISSING_ASSET',
    TEMPLATE_PP_EDIT_LOCALIZATION_PRESSED = 'TEMPLATE_PP_EDIT_LOCALIZATION_PRESSED';

export const
    LOAD_TEMPLATE_PAGES_REQUEST_ACTION = 'LOAD_TEMPLATE_PAGES_REQUEST_ACTION',
    LOAD_TEMPLATE_PAGES_SUCCESS_ACTION = 'LOAD_TEMPLATE_PAGES_SUCCESS_ACTION',
    LOAD_TEMPLATE_PAGES_FAILURE_ACTION = 'LOAD_TEMPLATE_PAGES_FAILURE_ACTION',
    loadTemplateComponentPagesApiAction = (templateId: string) => getTemplatePagesApiAction({
        types: [
            LOAD_TEMPLATE_PAGES_REQUEST_ACTION,
            LOAD_TEMPLATE_PAGES_SUCCESS_ACTION,
            LOAD_TEMPLATE_PAGES_FAILURE_ACTION
        ],
        templateId
    });

export const
    TOGGLE_TEMPLATE_CATEGORY_ACTION = 'TOGGLE_TEMPLATE_CATEGORY_ACTION',
    toggleTemplateCategoryAction = (category: TemplateCategoryT) => ({
        type: TOGGLE_TEMPLATE_CATEGORY_ACTION,
        payload: category,
    });
