import type { Element, HtmlWriterData, Stylesheet } from '../../flowTypes';
import { listParagraphParser } from '../parsers/index';
import { listStyleFilter } from '../filters/index';
import { listMapper } from '../mappers/index';
import { PARA_STYLES } from '../constants';
import { addStyleToParagraph } from './utils/index';

export default (
    data: HtmlWriterData,
    styles: Array<Element>,
    textParagraphs: Array<Element>,
    defaultGlobalStyles: Array<Stylesheet> = []
) => {
    const { paras: paraStyles } = data;
    // @ts-ignore
    const listStyles = paraStyles.filter(listStyleFilter);
    // @ts-ignore
    const listParagraphs = listParagraphParser(listStyles).map(listMapper);

    return {
        paragraphElements: addStyleToParagraph(
            defaultGlobalStyles,
            [...styles, ...paraStyles],
            textParagraphs,
            PARA_STYLES, true
        ),
        listParagraphElements: addStyleToParagraph(
            defaultGlobalStyles,
            paraStyles,
            listParagraphs,
            ['padding']
        )
    };
};
