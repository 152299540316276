import React from 'react';
import { WIDGETS_PAYPAL_SHOW_UPDATE_LINK_DIALOG, WIDGETS_PAYPAL_SET_LABEL } from './actionTypes';
import { ctaOnClickFactory, linkExtractor } from '../utils';
import { MctaPropertyContainer, MctaCombobox } from '../../../componentMainActions/index';
import { intlCreateOptions } from '../../../../view/common/Combobox/utils';
import { injectIntl } from '../../../../view/intl/index';
import styles from '../view/Widgets.css';
import { Labels } from './constants';

const EditView = injectIntl(props => {
    const { selectedComponent: { label }, intl, dispatch } = props;
    return (
        <MctaPropertyContainer label="msg: widgets.paypal.cma.label {Label}">
            <MctaCombobox
                searchable={false}
                options={intlCreateOptions(Labels, intl)}
                useOptionsAsIs
                value={label || Labels.paypal}
                className={styles.ctaTheme}
                onChange={({ value: label }) => {
                    dispatch({ type: WIDGETS_PAYPAL_SET_LABEL, payload: label });
                }}
            />
        </MctaPropertyContainer>
    );
});

const componentMainActions = {
    editView: EditView,
    editButtonText: 'msg: common.settings {Settings}',
    ctaButtonText: 'msg: widgets.paypal.cta.changePaypal {Change button}',
    ctaOnClick: ctaOnClickFactory(
        WIDGETS_PAYPAL_SHOW_UPDATE_LINK_DIALOG,
        component => ({ link: linkExtractor(component) })
    ),
};

export { componentMainActions };

