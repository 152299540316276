export const
    ToolTipDefaults = {
        width: 289,
        gap: 15,
        pointerEdge: 21,
        pointerAdjustement: 4
    },
    MveHeaderToolTipDefaults = {
        width: 290
    },
    PointerDirections = {
        Top: 'Top',
        Bottom: 'Bottom',
        Left: 'Left',
        Right: 'Right'
    },
    gapToScreenEnd = 6;
