// TODO: DRY with components/Locale

export default {
    cs: 'Čeština',
    da: 'Dansk',
    de: 'Deutsch',
    en_gb: 'English (UK)',
    en_us: 'English (US)',
    es: 'Español',
    fr: 'Français',
    it: 'Italiano',
    nl: 'Nederlands',
    nb: 'Norsk bokmål',
    pl: 'Polski',
    pt: 'Português',
    pt_br: 'Português (Brazilian)',
    fi: 'Suomi',
    sv: 'Svenska'
};
