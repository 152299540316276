import oneColor from "onecolor";
import type { SiteSettings } from "../../App/epics/siteSettings/flowTypes";
import type { ComponentsMap } from "../../../redux/modules/children/workspace/flowTypes";
import type { MobileBackgroundStyle, MobileStyle, MenuStyle } from "./types";
import { getByPath } from "../../../utils/ramdaEx";
import { Lit } from "../../../lit";
import { getAutoMobileStyle } from "./getAutoMobileStyle";
import type { AppState } from "../../../redux/modules/flowTypes";
import { stylesheetAppSel } from "../../Workspace/epics/stylesheets/selectors";
import { currentSiteSettingsAppSel, siteSettingsMobileSel } from "../../App/epics/siteSettings/selectorActionTypes";
import * as colorMapper from "../../../../dal/pageMapAdapter/mappers/Base/color";
import { templateDataAppSel } from "../../oneweb/Template/epics/template/selectors";
import {
    MenuStyleDefaults, HEADER_BG_COLOR, THEME_HEADER_BG_COLOR, HEADER_TITLE_COLOR, THEME_HEADER_TITLE_COLOR,
} from "../../MobileViewEditor/header/constants";
import { getAutoMobileStyleFont } from "./getAutoMobileStyleFont";
import { memoMaxOne } from "../../../../utils/memo";
import type { Stylesheets } from '../../Workspace/epics/stylesheets/flowTypes';
import type { ThemeBackgroundType } from '../../ThemeGlobalData/flowTypes';
import { getIsModernLayoutActivated } from "../../ModernLayouts/preview_utils";

type MobileStyleSelParams = {
    siteSettings: SiteSettings,
    componentsMap: ComponentsMap,
    stylesheet: Stylesheets,
    templateStyle?: Record<string, any>,
    templateSelectedTheme: ThemeBackgroundType,
};

type TitleMobileStyleParams = {
    siteSettings: { current: SiteSettings },
    componentsMap: ComponentsMap,
    stylesheet: Stylesheets,
};

type MenuMobileStyleParams = {
    siteSettings: { current: SiteSettings },
    componentsMap: ComponentsMap,
    stylesheet: Stylesheets,
};

export const
    titleMobileStyleFromSiteSettingsSel = ({ siteSettings, componentsMap, stylesheet }: TitleMobileStyleParams) => ({
        [Lit.fontFamily]: siteSettingsMobileSel(
            [Lit.title, Lit.style, Lit.fontFamily],
            getAutoMobileStyleFont(componentsMap, stylesheet, siteSettings.current.themeSettingsData.autoColorMode),
        )(siteSettings),
        [Lit.fontWeight]: siteSettingsMobileSel(
            [Lit.title, Lit.style, Lit.fontWeight],
            'normal',
        )(siteSettings),
        [Lit.fontStyle]: siteSettingsMobileSel(
            [Lit.title, Lit.style, Lit.fontStyle],
            'normal',
        )(siteSettings),
        [Lit.underline]: siteSettingsMobileSel(
            [Lit.title, Lit.style, Lit.underline],
            false,
        )(siteSettings),
        [Lit.color]: siteSettingsMobileSel(
            [Lit.title, Lit.style, Lit.color],
            HEADER_TITLE_COLOR,
        )(siteSettings),
        themeColor: siteSettingsMobileSel([Lit.title, Lit.style, 'themeColor'], THEME_HEADER_TITLE_COLOR)(siteSettings),
    }),
    backgroundMobileStyleFromSiteSettingsSel = (siteSettings: { current: SiteSettings }): MobileBackgroundStyle => {
        const useBackgroundColor = siteSettingsMobileSel(
            [Lit.background, Lit.useColor],
            true,
        )(siteSettings);
        const color = (
            useBackgroundColor
                ? siteSettingsMobileSel([Lit.background, Lit.color], colorMapper.toHsl(HEADER_BG_COLOR))(siteSettings)
                : ['HSL', 0, 0, 0, 0]
        );

        return {
            themeColor: siteSettingsMobileSel([Lit.background, 'themeColor'], THEME_HEADER_BG_COLOR)(siteSettings),
            themeColorOpacity: color[4],
            [Lit.color]: color,
            [Lit.showBottomShadow]: siteSettingsMobileSel(
                [Lit.background, Lit.showBottomShadow],
                true,
            )(siteSettings),
            [Lit.showBottomBorder]: siteSettingsMobileSel(
                [Lit.background, Lit.showBottomBorder],
                false,
            )(siteSettings),
        };
    },
    menuMobileStyleFromSiteSettingsSel =
        ({ siteSettings, componentsMap, stylesheet }: MenuMobileStyleParams): MenuStyle => {
            return {
                [Lit.closed]: {
                    iconThemeColor: siteSettingsMobileSel(
                        [Lit.menu, Lit.closed, 'iconThemeColor'],
                        MenuStyleDefaults.THEME_CLOSED_ICON_COLOR,
                    )(siteSettings),
                    [Lit.iconColor]: siteSettingsMobileSel(
                        [Lit.menu, Lit.closed, Lit.iconColor], colorMapper.toHsl(MenuStyleDefaults.CLOSED_ICON_COLOR)
                    )(siteSettings)
                },
                [Lit.open]: {
                    iconThemeColor: siteSettingsMobileSel(
                        [Lit.menu, Lit.open, 'iconThemeColor'],
                        MenuStyleDefaults.THEME_OPEN_ICON_COLOR,
                    )(siteSettings),
                    [Lit.iconColor]: siteSettingsMobileSel(
                        [Lit.menu, Lit.open, Lit.iconColor], colorMapper.toHsl(MenuStyleDefaults.OPEN_ICON_COLOR)
                    )(siteSettings),
                    [Lit.fontFamily]:
                        siteSettingsMobileSel(
                            [Lit.menu, Lit.open, Lit.fontFamily],
                            getAutoMobileStyleFont(componentsMap, stylesheet, siteSettings.current.themeSettingsData.autoColorMode),
                        )(siteSettings),
                    [Lit.bold]: siteSettingsMobileSel([Lit.menu, Lit.open, Lit.bold], false)(siteSettings),
                    [Lit.italic]: siteSettingsMobileSel([Lit.menu, Lit.open, Lit.italic], false)(siteSettings),
                    [Lit.underline]: siteSettingsMobileSel([Lit.menu, Lit.open, Lit.underline], false)(siteSettings),
                    [Lit.fontSize]:
                        siteSettingsMobileSel(
                            [Lit.menu, Lit.open, Lit.fontSize], MenuStyleDefaults.FONT_SIZE
                        )(siteSettings),
                    [Lit.lineHeight]:
                        siteSettingsMobileSel(
                            [Lit.menu, Lit.open, Lit.lineHeight], MenuStyleDefaults.LINE_HEIGHT
                        )(siteSettings),
                    themeColor: siteSettingsMobileSel(
                        [Lit.menu, Lit.open, 'themeColor'],
                        MenuStyleDefaults.THEME_OPEN_TEXT_COLOR,
                    )(siteSettings),
                    [Lit.color]: siteSettingsMobileSel(
                        [Lit.menu, Lit.open, Lit.color], colorMapper.toHsl(MenuStyleDefaults.OPEN_TEXT_COLOR)
                    )(siteSettings),
                    backgroundThemeColor: siteSettingsMobileSel(
                        [Lit.menu, Lit.open, 'backgroundThemeColor'],
                        MenuStyleDefaults.THEME_OPEN_BG_COLOR,
                    )(siteSettings),
                    [Lit.backgroundColor]: siteSettingsMobileSel(
                        [Lit.menu, Lit.open, Lit.backgroundColor], colorMapper.toHsl(MenuStyleDefaults.OPEN_BG_COLOR)
                    )(siteSettings),
                },
                [Lit.selected]: {
                    [Lit.bold]: siteSettingsMobileSel([Lit.menu, Lit.selected, Lit.bold], true)(siteSettings),
                    [Lit.italic]: siteSettingsMobileSel([Lit.menu, Lit.selected, Lit.italic], false)(siteSettings),
                    [Lit.underline]:
                        siteSettingsMobileSel([Lit.menu, Lit.selected, Lit.underline], false)(siteSettings),
                    themeColor: siteSettingsMobileSel(
                        [Lit.menu, Lit.selected, 'themeColor'],
                        MenuStyleDefaults.THEME_SELECTED_TEXT_COLOR,
                    )(siteSettings),
                    [Lit.color]: siteSettingsMobileSel(
                        [Lit.menu, Lit.selected, Lit.color], colorMapper.toHsl(MenuStyleDefaults.SELECTED_TEXT_COLOR)
                    )(siteSettings),
                    backgroundThemeColor: siteSettingsMobileSel(
                        [Lit.menu, Lit.selected, 'backgroundThemeColor'],
                        MenuStyleDefaults.THEME_SELECTED_BG_COLOR,
                    )(siteSettings),
                    [Lit.backgroundColor]: siteSettingsMobileSel(
                        [Lit.menu, Lit.selected, Lit.backgroundColor],
                        colorMapper.toHsl(MenuStyleDefaults.SELECTED_BG_COLOR)
                    )(siteSettings),
                },
            };
        };

const mobileStyleSelMemo = memoMaxOne((
    siteSettings,
    stylesheet,
    componentsMap,
    templateStyle,
    templateSelectedTheme,
): MobileStyle => {
    const useAutoStyle = !getByPath([Lit.mobile, Lit.useCustomStyle], siteSettings, false);
    const isModernLayoutActivated = getIsModernLayoutActivated(componentsMap);

    if (useAutoStyle || isModernLayoutActivated) {
        return getAutoMobileStyle(
            componentsMap,
            stylesheet,
            templateStyle,
            siteSettings.themeSettingsData.autoColorMode,
            templateSelectedTheme,
            stylesheet && stylesheet.theme && stylesheet.theme.colors,
        );
    }

    const
        title = titleMobileStyleFromSiteSettingsSel({
            siteSettings: { current: siteSettings },
            componentsMap,
            stylesheet,
        }),
        background = backgroundMobileStyleFromSiteSettingsSel({ current: siteSettings }),
        defaultLogo = {
            themeColor: background.themeColor,
            color: oneColor(background.color).hex(),
            themeBackgroundColor: title.themeColor,

            backgroundColor: title.color,
        },
        menu = menuMobileStyleFromSiteSettingsSel({
            siteSettings: { current: siteSettings },
            componentsMap,
            stylesheet,
        });

    return {
        defaultLogo,

        title,
        menu,
        background,
    };
});

export const
    mobileStyleSel = (
        { siteSettings, stylesheet, componentsMap, templateStyle, templateSelectedTheme }: MobileStyleSelParams,
    ): MobileStyle =>
        mobileStyleSelMemo(siteSettings, stylesheet, componentsMap, templateStyle, templateSelectedTheme),
    mobileStyleAppSel = (appState: AppState): MobileStyle => {
        const
            siteSettings = currentSiteSettingsAppSel()(appState),
            { selectedWorkspace: { components: { componentsMap } } } = appState,
            stylesheet = stylesheetAppSel(appState),
            templateData = templateDataAppSel(appState),
            /**
             * We can query template style from stylesheet object itself.
             * But it won't be up to date because template style is written to oneweb/Template/epics instead.
             * That is why we need to get up to date template style from template data epic.
             *
             * (Normally, template style should've just be read / written from / to stylsheet object).
             * (And for server side preview, we will get template style from stylesheet and it will be up to date anyway).
             */
            templateStyle = templateData && templateData.style;

        return mobileStyleSel({
            siteSettings, componentsMap, stylesheet, templateStyle, templateSelectedTheme: templateData && templateData.selectedTheme
        });
    };
