import openDialog from "../../App/actionCreators/openDialog";
import { SiteSettingsDialogId, SITE_SETTINGS_ACTIVE_TAB_ID } from "./constants";
import { replaceDialog, closeDialogByIdAction } from "../../App/actionCreators/index";

export type SiteSettingsDialogConfigWithInput = {
    activeTabKey?: string,
    activateInputWithId?: string | number,
};

export type SiteSettingsDialogConfigWithDiv = {
    activeTabKey?: string,
    activateDivWithId?: string | number,
};

type SiteSettingsDialogConfigProps = SiteSettingsDialogConfigWithInput | SiteSettingsDialogConfigWithDiv;

const
    openSiteSettingsDialog = (props?: SiteSettingsDialogConfigProps) => openDialog(SiteSettingsDialogId, props),
    closeSiteSettingsDialog = () => closeDialogByIdAction(SiteSettingsDialogId),
    replaceWithSiteSettingsDialog = (
        props?: SiteSettingsDialogConfigProps
    ) => replaceDialog(SiteSettingsDialogId, props),
    getSiteSettingsActiveTabId = () => {
        return window.sessionStorage.getItem(SITE_SETTINGS_ACTIVE_TAB_ID);
    },
    clearSiteSettingsActiveTabId = () => {
        window.sessionStorage.removeItem(SITE_SETTINGS_ACTIVE_TAB_ID);
    };

export {
    openSiteSettingsDialog,
    replaceWithSiteSettingsDialog,
    closeSiteSettingsDialog,
    getSiteSettingsActiveTabId,
    clearSiteSettingsActiveTabId
};
