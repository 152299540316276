import { isEmpty } from 'ramda';
import { parseIntDec } from '../../utils/number';

export const getElementFullWidth = (el: HTMLElement): number => {
    // @ts-ignore TODO: remove as it is only supported in IE6 as mentioned here: https://developer.mozilla.org/en-US/docs/Web/API/Element/currentStyle
    const style = window.getComputedStyle ? getComputedStyle(el) : (el.currentStyle || {});
    return el.offsetWidth
        + (parseIntDec(style.getPropertyValue('margin-left')) || 0)
        + (parseIntDec(style.getPropertyValue('margin-right')) || 0);
};

export const htmlCollectionToArray = (c: HTMLCollection): Array<HTMLElement> => [].slice.call(c);

export const getAttributeAsString = (elt: HTMLElement, attributeName: string): string => {
    if (elt.getAttribute) {
        return elt.getAttribute(attributeName) || '';
    } else {
        return '';
    }
};
export const windowGetComputedStyleSafe = (el: HTMLElement): CSSStyleDeclaration | null => {
    let result;
    try {
        result = window.getComputedStyle(el);
    } catch (e: any) {
        result = null;
    }
    return result;
};

export const getMatchingClassNames = (elt: HTMLElement, matcher: RegExp): string[] => {
    const isMatching = (v: string): boolean => matcher.test(v);
    return Array.from(elt.classList).filter(isMatching);
};

export const removeMatchingClassNames = (elt: HTMLElement, matcher: RegExp): void => {
    Array.from(elt.classList).forEach((className: string) => {
        if (matcher.test(className)) elt.classList.remove(className);
    });
};

export const getElementComputedStyleNumValue = (
    el: HTMLElement,
    property: string,
    defaultValue: number = 0,
): number => {
    const computedStyle = windowGetComputedStyleSafe(el);

    if (computedStyle) {
        const val = computedStyle.getPropertyValue(property);
        if (val) {
            return parseIntDec(val);
        }
    }

    return defaultValue;
};

export const getElementPureWidth = (el: HTMLElement): number => {
    const
        leftPadding = getElementComputedStyleNumValue(el, 'padding-left'),
        rightPadding = getElementComputedStyleNumValue(el, 'padding-right');

    return el.offsetWidth - leftPadding - rightPadding;
};

export const isEmptyNode = (node: Node, shouldIgnoreWhitespace: boolean = false): boolean => (
    shouldIgnoreWhitespace ? isEmpty(node.textContent?.trim()) : isEmpty(node.textContent)
);

export const isTextNode = (node: Node | null): boolean => {
    // window.Node instead of Node, as this method is called on server as well
    return node?.nodeType === window.Node.TEXT_NODE;
};

export const closest = (selector: string) => (node: Node): null | undefined | HTMLElement => {
    if (node instanceof HTMLElement && node.matches(selector)) {
        return node;
    } else if (node.parentElement) {
        return closest(selector)(node.parentElement);
    } else {
        return null;
    }
};
