/* eslint-disable max-len */

import React from "react";
import * as R from 'ramda';
import * as mp from "../../../mappers/path";
import { getCenterPositionForDialog } from "../../DialogManager/utility";
import currentOpenedStylesheetEpic from '../../Workspace/epics/currentOpenedStylesheet/index';
import stylesheetsEpic from "../../Workspace/epics/stylesheets/index";
import { Dialog, DialogBody } from "../../../view/common/dialogs/baseDialog/index";
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import * as selectors from "../../Workspace/epics/stylesheets/selectors";
import { getColorOpacity } from '../../oneweb/Background/utils/index';
import {
    borderStyleDialogView,
    borderStyleDialogFullView
} from './index';
import type { BorderStyleDialogPropTypes } from "./flowTypes";
import type { BrowserDimensions } from "../../../redux/modules/children/dimensions/index";
import type { Stylesheets } from "../../Workspace/epics/stylesheets/flowTypes";

type TableBorderDialogProps = {
    borderStyleChangeAction: string,
    borderColorChangeAction: string,
    borderColorRemoveAction: string,
    borderColorOpacityChangeAction: string,
    stylesheets: Stylesheets,
    dispatch: Dispatch
}

const
    DEFAULT_OPACITY = 100,
    getBorderData = (type, style) => ({
        borderColor: selectors.getBorderColor(type)(style),
        borderStyle: selectors.getBorderStyle(type)(style)
    }),
    getFullBorderData = (type, style) => ({
        borderColor: selectors.getBorderColor(type)(style),
        borderStyle: selectors.getBorderStyle(type)(style),
        borderWidth: selectors.getBorderWidth(type)(style)
    }),
    getMenuDividerBorderData = style => ({
        borderColor: selectors.menuDividerBorderColor(style),
        borderStyle: selectors.menuDividerBorderStyle(style),
        borderWidth: selectors.menuDividerBorderWidth(style)
    }),
    getBorderDataViewProps = (
        {
            borderStyleChangeAction,
            borderColorChangeAction,
            borderColorRemoveAction,
            borderColorOpacityChangeAction,
            dispatch
        },
        style,
        type
    ) => {
        const { borderStyle, borderColor } = getBorderData(type, style);

        return {
            borderStyle,
            borderStyleChangeAction,
            borderColor,
            borderColorChangeAction,
            borderColorRemoveAction,
            borderColorOpacity: (borderColor ? getColorOpacity(borderColor) : DEFAULT_OPACITY),
            borderColorOpacityChangeAction,
            dispatch
        };
    },
    getFullBorderDataViewProps = (
        {
            borderStyleChangeAction,
            borderColorChangeAction,
            borderColorRemoveAction,
            borderColorOpacityChangeAction,
            borderWidthChangeAction,
            dispatch
        },
        style,
        type
    ) => {
        const { borderStyle, borderColor, borderWidth } = getFullBorderData(type, style);

        return {
            borderStyle,
            borderStyleChangeAction,
            borderColor,
            borderColorChangeAction,
            borderColorRemoveAction,
            borderColorOpacity: (borderColor ? getColorOpacity(borderColor) : DEFAULT_OPACITY),
            borderColorOpacityChangeAction,
            borderWidth,
            borderWidthChangeAction,
            dispatch
        };
    },
    getMenuDividerBorderDataViewProps = (
        {
            borderStyleChangeAction,
            borderColorChangeAction,
            borderColorRemoveAction,
            borderColorOpacityChangeAction,
            borderWidthChangeAction,
            dispatch
        },
        style
    ) => {
        const { borderStyle, borderColor, borderWidth } = getMenuDividerBorderData(style);

        return {
            borderStyle,
            borderStyleChangeAction,
            borderColor,
            borderColorChangeAction,
            borderColorRemoveAction,
            borderColorOpacity: (borderColor ? getColorOpacity(borderColor) : DEFAULT_OPACITY),
            borderColorOpacityChangeAction,
            borderWidth,
            borderWidthChangeAction,
            dispatch
        };
    },
    configurationDialogFactory = (
        type: string | undefined,
        configurationDialogId: string,
        propsCreatorFn: Function,
        DialogView,
        width,
        height,
        additionalPath: Array<string> = []
    ): Record<string, any> => ({
        component: (props: BorderStyleDialogPropTypes) => {
            const
                { stylesheet } = props,
                style = (additionalPath.length ? R.path(additionalPath, stylesheet) : stylesheet),
                borderStyleDialogViewProps = propsCreatorFn(props, style, type);

            return (
                <Dialog>
                    <DialogBody>
                        <VerticalSpacer y={40} />
                        <DialogView {...borderStyleDialogViewProps} />
                    </DialogBody>
                </Dialog>
            );
        },
        confFactory: ({ browserWidth, browserHeight }: BrowserDimensions) => ({
            configurationDialogId,
            position: getCenterPositionForDialog(width, height, browserWidth, browserHeight),
            modal: false,
            dimensions: { width, height }
        }),
        dependsOn: {
            stylesheet: currentOpenedStylesheetEpic.reducer
        }
    }),
    width = 312,
    height = 244,
    fullHeight = height + 100,
    borderDialogViewFactory = (
        configurationDialogId: string,
        type?: string,
        additionalPath: Array<string> = []
    ) => configurationDialogFactory(
        type,
        configurationDialogId,
        getBorderDataViewProps,
        borderStyleDialogView,
        width,
        height,
        additionalPath
    ),
    borderDialogFullViewFactory = (
        configurationDialogId: string,
        type?: string,
        additionalPath: Array<string> = []
    ) => configurationDialogFactory(
        type,
        configurationDialogId,
        getFullBorderDataViewProps,
        borderStyleDialogFullView,
        width,
        fullHeight,
        additionalPath
    ),
    menuDividerBorderDialogViewFactory = (
        configurationDialogId: string,
        type?: string,
        additionalPath: Array<string> = []
    ) => configurationDialogFactory(
        type,
        configurationDialogId,
        getMenuDividerBorderDataViewProps,
        borderStyleDialogFullView,
        width,
        fullHeight,
        additionalPath
    ),

    tableBorderDialogViewFactory = (
        configurationDialogId: string,
        type: string,
        ref: string
    ) => ({
        component: (props: TableBorderDialogProps) => {
            const
                {
                    stylesheets,
                    borderStyleChangeAction,
                    borderColorChangeAction,
                    borderColorRemoveAction,
                    borderColorOpacityChangeAction,
                    dispatch
                } = props,
                stylesheet = R.pipe(
                    selectors.getAllStylesheets, selectors.getStylesByType(type), selectors.getStyleByRef(ref)
                )(stylesheets),
                borderStyle = R.path(mp.blockBorderStyle, stylesheet),
                borderColor = R.path(mp.blockBorderColor, stylesheet),
                borderStyleDialogViewProps = {
                    stylesheet,
                    borderStyle,
                    borderColor,
                    borderColorOpacity: getColorOpacity(borderColor),
                    borderStyleChangeAction,
                    borderColorChangeAction,
                    borderColorRemoveAction,
                    borderColorOpacityChangeAction,
                    dispatch
                },
                DialogView = borderStyleDialogView;

            return (
                <Dialog>
                    <DialogBody>
                        <VerticalSpacer y={40} />
                        <DialogView {...borderStyleDialogViewProps} />
                    </DialogBody>
                </Dialog>
            );
        },
        confFactory: ({ browserWidth, browserHeight }: BrowserDimensions) => ({
            configurationDialogId,
            position: getCenterPositionForDialog(width, height, browserWidth, browserHeight),
            modal: false,
            dimensions: { width, height }
        }),
        dependsOn: {
            stylesheets: stylesheetsEpic.reducer
        }
    });

export { borderDialogViewFactory, borderDialogFullViewFactory, menuDividerBorderDialogViewFactory, tableBorderDialogViewFactory };
