import CALL_API from '../../../../redux/middleware/api/CALL_API';
import * as Actions from '../actionTypes';
import type { ApiAction } from '../../../../redux/middleware/api/flowTypes';

const checkTranscodeStatus = function (videoName?: string): ApiAction {
    return {
        [CALL_API]: {
            types: [
                Actions.CHECK_TRANSCODE_STATUS_REQUEST,
                Actions.CHECK_TRANSCODE_STATUS_SUCCESS,
                Actions.CHECK_TRANSCODE_STATUS_FAILURE
            ],
            endpoint: 'checkTranscodeStatus',
            endpointParams: videoName
        }
    };
};

export {
    checkTranscodeStatus as default,
    checkTranscodeStatus
};
