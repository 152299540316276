import * as actionTypes from "./actionTypes";
import { inactive, hover } from "../../../mappers/path";
import * as euh from './epicUpdaterHelpers';
import { SAVE_GOOGLE_FONT } from '../../Fonts/actionTypes';

export default [
    euh.googleFontEpicUpdater(SAVE_GOOGLE_FONT, inactive),

    euh.fontFamilyEpicUpdater(actionTypes.GS_BTN_FONT_FAMILY_CHANGED, inactive),
    euh.toggleBoldEpicUpdater(actionTypes.GS_BTN_TOGGLE_BOLD, inactive),
    euh.toggleItalicEpicUpdater(actionTypes.GS_BTN_TOGGLE_ITALIC, inactive),
    euh.fontSizeEpicUpdater(actionTypes.GS_BTN_FONT_SIZE_CHANGED, inactive),
    euh.borderWidthEpicUpdater(actionTypes.GS_BTN_BORDER_WIDTH_CHANGED, [inactive, hover]),
    euh.borderRadiusEpicUpdater(actionTypes.GS_BTN_BORDER_RADIUS_CHANGED, [inactive, hover]),

    euh.textColorEpicUpdater(actionTypes.GS_BTN_DEFAULT_TEXT_COLOR_CHANGED, inactive),
    euh.textColorRemoveEpicUpdater(actionTypes.GS_BTN_DEFAULT_TEXT_COLOR_REMOVED, inactive),
    euh.borderStyleEpicUpdater(actionTypes.GS_BTN_INACTIVE_BORDER_STYLE_CHANGED, inactive),
    euh.borderColorEpicUpdater(actionTypes.GS_BTN_INACTIVE_BORDER_COLOR_CHANGED, inactive),
    euh.borderColorRemoveEpicUpdater(actionTypes.GS_BTN_INACTIVE_BORDER_COLOR_REMOVED, inactive),
    euh.borderColorOpacityEpicUpdater(actionTypes.GS_BTN_INACTIVE_BORDER_COLOR_OPACITY_CHANGED, inactive),

    euh.textColorEpicUpdater(actionTypes.GS_BTN_HOVER_TEXT_COLOR_CHANGED, hover),
    euh.textColorRemoveEpicUpdater(actionTypes.GS_BTN_HOVER_TEXT_COLOR_REMOVED, hover),
    euh.borderStyleEpicUpdater(actionTypes.GS_BTN_HOVER_BORDER_STYLE_CHANGED, hover),
    euh.borderColorEpicUpdater(actionTypes.GS_BTN_HOVER_BORDER_COLOR_CHANGED, hover),
    euh.borderColorRemoveEpicUpdater(actionTypes.GS_BTN_HOVER_BORDER_COLOR_REMOVED, hover),
    euh.borderColorOpacityEpicUpdater(actionTypes.GS_BTN_HOVER_BORDER_COLOR_OPACITY_CHANGED, hover),

    euh.assetRemoveEpicUpdater(actionTypes.GS_BTN_INACTIVE_ASSET_REMOVED, inactive),
    euh.assetChangeEpicUpdater(actionTypes.GS_BTN_INACTIVE_ASSET_CHANGED, inactive),
    euh.assetRepeatEpicUpdater(actionTypes.GS_BTN_INACTIVE_ASSET_REPEAT_CHANGED, inactive),
    euh.assetPositionEpicUpdater(actionTypes.GS_BTN_INACTIVE_ASSET_POSITION_CHANGED, inactive),
    euh.assetSizeEpicUpdater(actionTypes.GS_BTN_INACTIVE_ASSET_SIZE_CHANGED, inactive),

    euh.bgColorEpicUpdater(actionTypes.GS_BTN_INACTIVE_BG_COLOR_CHANGED, inactive),
    euh.bgColorRemoveEpicUpdater(actionTypes.GS_BTN_INACTIVE_BG_COLOR_REMOVED, inactive),
    euh.bgColorOpacityEpicUpdater(actionTypes.GS_BTN_INACTIVE_BG_COLOR_OPACITY_CHANGED, inactive),

    euh.gradientEpicUpdater(actionTypes.GS_BTN_INACTIVE_GRADIENT_CHANGED, inactive),
    euh.gradientRemoveEpicUpdater(actionTypes.GS_BTN_INACTIVE_GRADIENT_CANCELLED, inactive),
    euh.gradientOpacityEpicUpdater(actionTypes.GS_BTN_INACTIVE_GRADIENT_OPACITY_CHANGED, inactive),
    euh.gradientDirectionEpicUpdater(actionTypes.GS_BTN_INACTIVE_GRADIENT_DIRECTION_CHANGED, inactive),
    euh.gradientFadePointEpicUpdater(actionTypes.GS_BTN_INACTIVE_GRADIENT_FADEPOINT_CHANGED, inactive),

    euh.assetRemoveEpicUpdater(actionTypes.GS_BTN_HOVER_ASSET_REMOVED, hover),
    euh.assetChangeEpicUpdater(actionTypes.GS_BTN_HOVER_ASSET_CHANGED, hover),
    euh.assetRepeatEpicUpdater(actionTypes.GS_BTN_HOVER_ASSET_REPEAT_CHANGED, hover),
    euh.assetPositionEpicUpdater(actionTypes.GS_BTN_HOVER_ASSET_POSITION_CHANGED, hover),
    euh.assetSizeEpicUpdater(actionTypes.GS_BTN_HOVER_ASSET_SIZE_CHANGED, hover),

    euh.bgColorEpicUpdater(actionTypes.GS_BTN_HOVER_BG_COLOR_CHANGED, hover),
    euh.bgColorRemoveEpicUpdater(actionTypes.GS_BTN_HOVER_BG_COLOR_REMOVED, hover),
    euh.bgColorOpacityEpicUpdater(actionTypes.GS_BTN_HOVER_BG_COLOR_OPACITY_CHANGED, hover),

    euh.gradientEpicUpdater(actionTypes.GS_BTN_HOVER_GRADIENT_CHANGED, hover),
    euh.gradientRemoveEpicUpdater(actionTypes.GS_BTN_HOVER_GRADIENT_CANCELLED, hover),
    euh.gradientOpacityEpicUpdater(actionTypes.GS_BTN_HOVER_GRADIENT_OPACITY_CHANGED, hover),
    euh.gradientDirectionEpicUpdater(actionTypes.GS_BTN_HOVER_GRADIENT_DIRECTION_CHANGED, hover),
    euh.gradientFadePointEpicUpdater(actionTypes.GS_BTN_HOVER_GRADIENT_FADEPOINT_CHANGED, hover)
];
