import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import {
    SEAMLESS_UPGRADE,
    UPGRADE,
    UPGRADE_COMPLETE,
    UPGRADE_FAILURE,
    UPGRADE_SUCCESS
} from "../../../App/actionTypes";
import seamlessUpgrade from "../../actionCreators/upgrade/seamlessUpgrade";
import { receiveOnly } from "../../../../epics/makeCondition";
import {
    SEAMLESS_UPGRADE_CLICKED,
    SEAMLESS_UPGRADE_FAILURE,
    SEAMLESS_UPGRADE_REQUEST,
    SEAMLESS_UPGRADE_SUCCESS
} from "../../actionTypes";
import callSeamlessUpgrade from "../../actionCreators/upgrade/callSeamlessUpgrade";
import { getDAL } from "../../../../../dal/index";
import { WINDOW_LISTEN_FOR_UPGRADE_COMPLETE } from "../../../../redux/middleware/window/actionTypes";
import { getWindowOrigin } from "../../../../utils/windowHelper";
import { openDialog } from "../../../App/actionCreators/index";
import { UpgradeFailureDialogId, UpgradePlansDialogId } from '../../view/dialogIds';
import checkFreeUpgradeValueActionType from '../checkFreeUpgrade/valueActionType';
import { sendShownErrorEventToAecAC } from "../../../App/actionCreators/sendEventToAecActionCreator";
import { openWindowAction, navigateToBlankLocationAction } from "../../../../redux/middleware/window/actionCreators";
import { getExternalPlansLink } from '../../../ComponentTierManager/utils';
import {
    plansOverviewDialogOpenedAction,
    UPGRADE_DIALOG_OPENED_ACTION
} from '../../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions';

export default makeEpic({
    defaultState: {
        isUpgrading: false,
        selectedUpgradeType: null
    },
    valueActionType,
    updaters: [
        {
            conditions: [SEAMLESS_UPGRADE_CLICKED],
            reducer: ({ values: [{ upgradeType }], state }) => {
                return { state, actionToDispatch: callSeamlessUpgrade({ upgradeType }) };
            }
        },
        {
            conditions: [receiveOnly(checkFreeUpgradeValueActionType), UPGRADE],
            reducer: ({ values: [, { upgradeType }], state }) => {
                const
                    getInternalUpgradeWindowOpenAction = () => {
                        const origin = getWindowOrigin(window),
                            domain = getDAL().getDomain(),
                            basePath = origin + '/api/v1/' + domain + '/upgradeToPremium/',
                            url = basePath +
                        'initiate' +
                        '?successUrl=' + encodeURIComponent(basePath + 'complete') +
                        '&upgradeType=' + encodeURIComponent(upgradeType),
                            name = `Initiate upgrade to ${upgradeType}`,
                            specs = 'height=935,width=1045,scrollbars=yes,resizable=yes',
                            replace = true;
                        return openWindowAction({
                            url: url + '&' + (new Date()).getTime(),
                            name,
                            specs,
                            replace
                        });
                    },
                    externalPlansLink: string | null = getExternalPlansLink(),
                    getExternalUpgradeWindowOpenAction = (externalLink) => {
                        return navigateToBlankLocationAction(externalLink);
                    };
                return {
                    state: {
                        ...state,
                        selectedUpgradeType: upgradeType
                    },
                    multipleActionsToDispatch: [
                        externalPlansLink ? getExternalUpgradeWindowOpenAction(externalPlansLink) : getInternalUpgradeWindowOpenAction(),
                        {
                            type: WINDOW_LISTEN_FOR_UPGRADE_COMPLETE
                        }
                    ]
                };
            }
        },
        {
            conditions: [SEAMLESS_UPGRADE],
            reducer: ({ values: [{ upgradeType }], state }) => {
                return ({ state: {
                    ...state,
                    selectedUpgradeType: upgradeType,

                },
                actionToDispatch: seamlessUpgrade(upgradeType) });
            }
        },
        {
            conditions: [SEAMLESS_UPGRADE_REQUEST],
            reducer: ({ state }) => ({ state: { ...state, isUpgrading: true } })
        },
        {
            conditions: [SEAMLESS_UPGRADE_FAILURE],
            reducer: ({ state }) => ({ state: { ...state, isUpgrading: false } })
        },
        {
            conditions: [SEAMLESS_UPGRADE_SUCCESS],
            reducer: ({ state }) => ({
                state: { ...state, isUpgrading: false }
            })
        },
        {
            conditions: [receiveOnly(checkFreeUpgradeValueActionType), UPGRADE_COMPLETE],
            reducer: ({ values: [, event], state }) => {
                const data = event.data;

                if (data.status === 200) {
                    return {
                        state,
                        multipleActionsToDispatch: [
                            {
                                type: UPGRADE_SUCCESS
                            }
                        ]
                    };
                } else {
                    // Upgrade could not be completed
                    return {
                        state,
                        multipleActionsToDispatch: [
                            {
                                type: UPGRADE_FAILURE
                            }
                        ]
                    };
                }
            }
        },
        {
            conditions: [UPGRADE_FAILURE],
            reducer: ({ state }) => ({
                state,
                multipleActionsToDispatch: [
                    openDialog(UpgradeFailureDialogId),
                    sendShownErrorEventToAecAC('upgrade failed')
                ]
            })
        },
        {
            conditions: [UPGRADE_DIALOG_OPENED_ACTION],
            reducer: ({ state, values: [payload] }) => {
                if (payload.dialogId === UpgradePlansDialogId) {
                    return {
                        state,
                        actionToDispatch: plansOverviewDialogOpenedAction(payload.source)
                    };
                }
                return { state };
            }
        }
    ]
});
