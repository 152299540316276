
/* eslint-disable max-len */

import factory from '../../Background/componentMainActions/factory';
import { TooltipTextForStripColorPicker } from "../constants";
import { MctaCheckbox } from "../../../componentMainActions/index";
import { TEMPLATE_DISPLAY_SHOP_STRIP_IN_FOOTER } from '../../Template/actionTypes';

const showShopFooterMcta = (selectedComponent, selectedComponentIsInsideHeaderOrFooter) => {
    if (!selectedComponentIsInsideHeaderOrFooter || !selectedComponent) {
        return false;
    }
    if (selectedComponent.id !== selectedComponentIsInsideHeaderOrFooter.webShopFooterStripId) {
        return false;
    }
    return true;
};

export default factory({
    getEditButtonText: () => 'msg: component.strip.editStrip {Edit strip}',
    colorLabelForTheme: 'msg: component.strip.stripStyle {Strip style}',
    tooltipTextForTheme: TooltipTextForStripColorPicker,
    CtaButtonTextView: ({ selectedComponent, selectedComponentIsInsideHeaderOrFooter }) => {
        if (showShopFooterMcta(selectedComponent, selectedComponentIsInsideHeaderOrFooter)) {
            return MctaCheckbox({
                isChecked: true,
                label: 'msg: component.section.propPanel.showShopFooter {Show shop footer}',
            });
        }
        return null;
    },
    ctaOnClick: ({ dispatch, selectedComponent, selectedComponentIsInsideHeaderOrFooter }) => {
        if (showShopFooterMcta(selectedComponent, selectedComponentIsInsideHeaderOrFooter)) {
            dispatch({ type: TEMPLATE_DISPLAY_SHOP_STRIP_IN_FOOTER });
        }
    },
    isCtaVisible: ({ selectedComponent, selectedComponentIsInsideHeaderOrFooter }) =>
        showShopFooterMcta(selectedComponent, selectedComponentIsInsideHeaderOrFooter)
});
