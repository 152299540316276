export const imgToDataUrl = (img: HTMLImageElement): string => {
    const
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    canvas.height = img.naturalHeight;
    canvas.width = img.naturalWidth;
    ctx.drawImage(img, 0, 0);

    return canvas.toDataURL();
};
