// @ts-nocheck
import styles from './WSModernSection.css';
import appStyles from '../../../App.css';

const FlexComponentSelectorInColScope = `:scope > .${styles.flexComponent}`;

const evaluateElementRectValue = (ele, workspaceBBox) => {
    const wsElement = ele && ele.closest('[class*="App_workspace"]'),
        rect = ele ? ele.getBoundingClientRect() : {},
        { scrollLeft = 0, scrollTop = 0 } = wsElement || {},
        { top, left, width, height } = rect;
    const result = {
        top: Math.round(scrollTop + top - 46),
        left: Math.round(scrollLeft + left - 170 + workspaceBBox.left),
        width: Math.ceil(width),
        height: Math.ceil(height)
    };
    return result;
};

const evaluateElementRectBasedOnSectionEle = (ele, sectionEle, workspaceBBox, topOffset = 0) => {
    const wsElement = ele && ele.closest(`.${appStyles.workspace}`),
        eleRect = ele.getBoundingClientRect(),
        { scrollLeft = 0, scrollTop = 0 } = wsElement || {},
        sectionRect = sectionEle.getBoundingClientRect(),
        sectionTop = sectionRect.top,
        { top, left, width, height } = eleRect,
        sectionCmpTop = scrollTop + sectionTop - 46;
    return {
        top: Math.round(sectionCmpTop + (top - sectionTop) - topOffset),
        left: Math.round(scrollLeft + left - 170 + workspaceBBox.left),
        width: Math.ceil(width),
        height: Math.ceil(height)
    };
};

const getEleDimensions = (ele) => {
    const { width, height, top, left } = ele ? ele.getBoundingClientRect() : {};
    return {
        width: Math.ceil(width),
        height: Math.ceil(height),
        top: Math.round(top),
        left: Math.round(left)
    };
};

const getWidthsOfColElements = (flexColElements) => {
    return [...flexColElements].map((flexCol) => {
        const colChildren = [...flexCol.querySelectorAll(FlexComponentSelectorInColScope)];
        const { left, right } = colChildren.reduce(({ left, right }, flexCmp) => {
            return {
                left: Math.min(left, flexCmp.getBoundingClientRect().left),
                right: Math.max(right, flexCmp.getBoundingClientRect().right)
            };
        }, colChildren[0].getBoundingClientRect());
        return right - left;
    });
};

const getDimensionsOfColElements = (flexColElements) => {
    return [...flexColElements].map((flexCol) => {
        const colChildren = [...flexCol.querySelectorAll(FlexComponentSelectorInColScope)];
        const { left, right, top, bottom } = colChildren.reduce(({ left, right, top, bottom }, flexCmp) => {
            return {
                left: Math.min(left, flexCmp.getBoundingClientRect().left),
                right: Math.max(right, flexCmp.getBoundingClientRect().right),
                top: Math.min(top, flexCmp.getBoundingClientRect().top),
                bottom: Math.max(bottom, flexCmp.getBoundingClientRect().bottom),
            };
        }, colChildren[0].getBoundingClientRect());
        return {
            width: right - left,
            height: bottom - top,
            left,
            right,
            top,
            bottom
        };
    });
};

const getMinWidthOfCol = (col, componentsMapExtension) => {
    return col.cmps.map(({ id, margin = {} }) => {
        const { minDimensions: { width = 0 } = {} } = componentsMapExtension[id] || {};
        return width + (margin.left || 0) + (margin.right || 0);
    }).reduce((sum, num) => sum + (isNaN(num) ? 0 : num), 0);
};

export {
    evaluateElementRectValue,
    evaluateElementRectBasedOnSectionEle,
    getEleDimensions,
    getWidthsOfColElements,
    getDimensionsOfColElements,
    getMinWidthOfCol,
};
