import {
    makePayloadToStateReducer,
    makeValueReducer,
} from '../../../redux/makeReducer/index';
import {
    GET_PREFERENCES_SUCCESS_ACTION,
    SET_DEFAULT_PREFERENCES_ACTION,
    PUT_PREFERENCES_SUCCESS_ACTION,
    MERGE_PREFERENCES_ACTION,
} from '../actions';
import { makeDefaultPreferences } from './makeDefaultPreferences';
import type { PreferencesState } from '../types';
import { sanitizeSavePreferences } from '../functions/sanitizeSavePreferences';
import isTestEnv from '../../../debug/isTestEnv';

export const PreferencesInitialState: PreferencesState | null = null;

export const preferencesReducer = makeValueReducer({
    defaultState: PreferencesInitialState,
    handleActions: {
        [SET_DEFAULT_PREFERENCES_ACTION]: (_, action: AnyAction) => makeDefaultPreferences(action.payload),
        [GET_PREFERENCES_SUCCESS_ACTION]: makePayloadToStateReducer(),
        [PUT_PREFERENCES_SUCCESS_ACTION]: (state: PreferencesState, action: Action) => {
            // TODO: in test preferences are null for some reason ...
            if (!isTestEnv() && state === null) throw new Error('Preferences should not be null');

            return {
                ...state,
                ...sanitizeSavePreferences(action.endpointParams),
                _rev: action.payload._rev,
            };
        },
        [MERGE_PREFERENCES_ACTION]: (state: PreferencesState, action: AnyAction) => ({
            ...state,
            ...sanitizeSavePreferences(action.payload),
        }),
    },
});
