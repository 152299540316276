/* eslint-disable max-len*/

import React from "react";
import * as R from "ramda";
import cx from "classnames";
import { Box, Flex } from "../../../view/reflexbox/index";
import { injectIntl, Msg } from "../../../view/intl/index";
import * as selectors from "../../Workspace/epics/stylesheets/selectors";
import Scrollbar from '../../../view/common/Scrollbar/index';
import { tableTabLabel, globalStyleLabels } from './constants';
import {
    getPreviewProps, getAllActionsForStyle,
    getBorderDialogId, getCellSpacingDialogId, getTextShadowDialogId, getBackgroundDialogId
} from "./utils";
import HorizontalSpacer from "../../../view/common/HorizontalSpacer";
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import FontSizeSelector from "../../presentational/FontSize/index";
import {
    TextFullHorizontalAlignmentButtonsGroup,
    TextVerticalAlignmentButtonsGroup
} from "../../presentational/IconButtonsGroup/TextAlignmentButtonGroup/index";
import GrayBgContainer from "../../presentational/GrayBgContainer";
import FontFamilySelector from "../../presentational/FontFamily/index";
import TextFormatButtonGroup from "../../presentational/IconButtonsGroup/TextFormat";
import { button as TextShadowButton } from "../../presentational/TextShadow/index";
import { button as BackgroundButton } from "../../presentational/Background/index";
import { button as BorderStyleButton } from "../../presentational/BorderStyle/index";
import { button as CellSpacingButton } from "../../presentational/CellSpacing/index";
import ColorProperty from "../../presentational/ColorProperty/index";
import TableView from "../../oneweb/Table/view/index";
import TableGlobalStyleKind from "../../oneweb/Table/globalStyle/kind";
import styles from "../GlobalstylesDialog.css";
import type { TableCellStylesheet } from '../../Workspace/epics/stylesheets/flowTypes';
import type { TableGlobalstyleDialogProps } from '../flowTypes';

export const
    Tab = injectIntl(({ intl }) => (<div id="table-globalstyles-tab">{intl.msgJoint(tableTabLabel)}</div>)),
    TabPanel = injectIntl(({
        scrollHeight,
        previewScrollHeight,
        stylesheets,
        stylesheetsIdToNameMap,
        site,
        siteFonts,
        intl,
        dispatch,
        themeSettingsData,
        themeColorsData
    }: TableGlobalstyleDialogProps) => {
        const
            tableStyles = R.pipe(
                selectors.getAllStylesheets,
                selectors.getStylesByType(TableGlobalStyleKind)
            )(stylesheets),
            previewProps = getPreviewProps({
                stylesheets,
                stylesheetsIdToNameMap,
                site,
                intl,
                themeSettingsData,
                themeColorsData
            }),
            renderStyleControls = ({ style, actions, index, dispatch }) => {
                const
                    {
                        ref,
                        block: {
                            padding
                        },
                        text: {
                            bold,
                            italic,
                            underline,
                            size,
                            font,
                            color,
                            highlight
                        },
                        horizontalAlign,
                        verticalAlign
                    } = style,
                    {
                        fontFamilyChangeAction,
                        fontSizeChangeAction,
                        toggleBoldAction,
                        toggleItalicAction,
                        toggleUnderlineAction,
                        highlightColorChangeAction,
                        highlightColorRemoveAction,
                        fontColorChangeAction,
                        fontShadowColorChangeAction,
                        fontShadowColorRemoveAction,
                        fontShadowBlurRadiusChangeAction,
                        fontShadowHorizontalOffsetChangeAction,
                        fontShadowVerticalOffsetChangeAction,
                        horizontalAlignmentChangeAction,
                        verticalAlignmentChangeAction,
                        backgroundColorChangeAction,
                        backgroundColorRemoveAction,
                        backgroundGradientColorChangeAction,
                        backgroundGradientColorRemoveAction,
                        backgroundGradientDirectionChangeAction,
                        backgroundGradientFadePointChangeAction,
                        backgroundOpacityChangeAction,
                        borderStyleChangeAction,
                        borderColorChangeAction,
                        borderColorOpacityChangeAction,
                        backgroundAssetChangeAction,
                        backgroundAssetRemoveAction,
                        backgroundAssetPositionChangeAction,
                        backgroundAssetRepeatChangeAction,
                        backgroundAssetSizeChangeAction,
                        borderWidthChangeAction,
                        cellSpacingChangeAction
                    } = actions;

                return (
                    <Flex key={index}>
                        <Box>
                            <VerticalSpacer y={28} />
                            <div className={styles.title}>{intl.msgJoint(globalStyleLabels[ref])}</div>
                            <VerticalSpacer y={10} />
                            <GrayBgContainer className={cx(styles.mainStyleSelectors, styles.globalStylesTableGrayBox)}>
                                <Flex align="center">
                                    <FontFamilySelector
                                        selected={font}
                                        siteFonts={siteFonts}
                                        onChangeAction={fontFamilyChangeAction}
                                        additionalPayload={{ ref, source: TableGlobalStyleKind }}
                                    />
                                    <HorizontalSpacer x={22} />
                                    <FontSizeSelector
                                        selected={size}
                                        onChangeAction={fontSizeChangeAction}
                                        dispatch={dispatch}
                                        searchable={false}
                                    />
                                </Flex>
                                <VerticalSpacer y={10} />
                                <Flex align="center">
                                    <TextFormatButtonGroup
                                        bold={{ isSelected: bold, onClickAction: toggleBoldAction }}
                                        italic={{ isSelected: italic, onClickAction: toggleItalicAction }}
                                        underline={{ isSelected: underline, onClickAction: toggleUnderlineAction }}
                                        dispatch={dispatch}
                                    />
                                    <HorizontalSpacer />
                                    <TextFullHorizontalAlignmentButtonsGroup
                                        value={horizontalAlign}
                                        onBtnClick={ha => dispatch({ type: horizontalAlignmentChangeAction, payload: ha })}
                                    />
                                    <HorizontalSpacer />
                                    <TextVerticalAlignmentButtonsGroup
                                        value={verticalAlign}
                                        onBtnClick={va => dispatch({ type: verticalAlignmentChangeAction, payload: va })}
                                    />
                                    <HorizontalSpacer />
                                </Flex>
                                <VerticalSpacer y={10} />
                                <Flex align="center" justify="flex-start" wrap>
                                    {
                                        !themeSettingsData.autoColorMode && <React.Fragment>
                                            { /* @ts-ignore */ }
                                            <BorderStyleButton
                                                dialogId={getBorderDialogId(ref)}
                                                stylesheet={style}
                                                borderStyleChangeAction={borderStyleChangeAction}
                                                borderColorChangeAction={borderColorChangeAction}
                                                borderColorOpacityChangeAction={borderColorOpacityChangeAction}
                                                borderWidthChangeAction={borderWidthChangeAction}
                                                dispatch={dispatch}
                                            />
                                            <HorizontalSpacer />
                                        </React.Fragment>
                                    }
                                    <CellSpacingButton
                                        dialogId={getCellSpacingDialogId(ref)}
                                        spacing={padding}
                                        spacingChangeAction={cellSpacingChangeAction}
                                        dispatch={dispatch}
                                    />
                                    {
                                        !themeSettingsData.autoColorMode && <React.Fragment>
                                            <VerticalSpacer y={10} />
                                            <TextShadowButton
                                                dialogId={getTextShadowDialogId(ref)}
                                                shadowColorChangeAction={fontShadowColorChangeAction}
                                                shadowRemoveAction={fontShadowColorRemoveAction}
                                                blurRadiusChangeAction={fontShadowBlurRadiusChangeAction}
                                                shadowOffsetXChangeAction={fontShadowHorizontalOffsetChangeAction}
                                                shadowOffsetYChangeAction={fontShadowVerticalOffsetChangeAction}
                                                dispatch={dispatch}
                                            />
                                        </React.Fragment>
                                    }
                                    <HorizontalSpacer />
                                    <BackgroundButton
                                        // @ts-ignore
                                        autoColorMode={themeSettingsData.autoColorMode}
                                        dialogId={getBackgroundDialogId(ref)}
                                        colorGradientSettingsActions={{
                                            colorChangeAction: backgroundColorChangeAction,
                                            colorRemoveAction: backgroundColorRemoveAction,
                                            gradientColorChangeAction: backgroundGradientColorChangeAction,
                                            gradientColorRemoveAction: backgroundGradientColorRemoveAction,
                                            gradientDirectionChangeAction: backgroundGradientDirectionChangeAction,
                                            gradientFadePointChangeAction: backgroundGradientFadePointChangeAction,
                                            opacityChangeAction: backgroundOpacityChangeAction
                                        }}
                                        backgroundSettingsActions={{
                                            assetChangeAction: backgroundAssetChangeAction,
                                            assetRemoveAction: backgroundAssetRemoveAction,
                                            assetPositionChangeAction: backgroundAssetPositionChangeAction,
                                            assetRepeatChangeAction: backgroundAssetRepeatChangeAction,
                                            assetSizeChangeAction: backgroundAssetSizeChangeAction
                                        }}
                                        dispatch={dispatch}
                                    />
                                </Flex>
                                {
                                    !themeSettingsData.autoColorMode && <React.Fragment>
                                        <VerticalSpacer y={10} />
                                        <Flex align="center" justify="flex-start">
                                            <ColorProperty
                                                style={{ width: 167 }}
                                                label="msg: common.textColor {Text colour}"
                                                color={color}
                                                onChangeAction={fontColorChangeAction}
                                                dispatch={dispatch}
                                            />
                                            <HorizontalSpacer x={13} />
                                            <ColorProperty
                                                style={{ width: 167 }}
                                                label="msg: common.highlightColor {Highlight colour}"
                                                color={highlight}
                                                onChangeAction={highlightColorChangeAction}
                                                onRemoveAction={highlightColorRemoveAction}
                                                dispatch={dispatch}
                                            />
                                        </Flex>
                                    </React.Fragment>
                                }
                            </GrayBgContainer>
                        </Box>
                    </Flex>
                );
            };

        return (
            <Box className={styles.tabPanelContents}>
                <Flex className={cx(styles.borderBottom, styles.infoBar)} align="center" justify="flex-start">
                    <Msg k="gs.text.styleTableTitle">
                        Create predefined styles for tables.
                    </Msg>
                </Flex>
                <Flex className={cx(styles.tabPanel, styles.styleSelectorsStylePreview)}>
                    <Box className={styles.styleSelectors}>
                        <Scrollbar height={`${scrollHeight}px`}>
                            {
                                tableStyles.map((style: TableCellStylesheet, index: number) => renderStyleControls({
                                    style,
                                    actions: getAllActionsForStyle(style.ref),
                                    index,
                                    dispatch
                                }))
                            }
                        </Scrollbar>
                    </Box>
                    <Box className={cx(styles.borderLeft, styles.stylePreview)}>
                        <div className={styles.previewLabel}>{intl.msgJoint('msg: common.preview.noun {Preview}')}</div>
                        <Scrollbar height={`${previewScrollHeight}px`}>
                            <Box className={cx(styles.preview, styles.tablePreview)} align="center" justify="flex-start">
                                <TableView {...previewProps} dispatch={dispatch} />
                            </Box>
                        </Scrollbar>
                    </Box>
                </Flex>
            </Box>
        );
    });
