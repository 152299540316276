import makeEpic from '../../../../epics/makeEpic';
import valueActionType from "./valueActionType";
import {
    TOP_BAR_OPEN_POPUP,
    TOP_BAR_CLOSE_POPUP,
    LOGOUT,
    CLICKED_EXTERNAL_LINK
} from "../../actionTypes";
import { APP_LEFT_MOUSE_DOWN, OPEN_DIALOG } from "../../../../redux/modules/actionTypes";
import { PANEL_COMPONENT_SHORTCUT_MOUSE_DOWN, PANEL_ITEM_LEFT_MOUSE_DOWN } from "../../../Panel/actionTypes";
import { HIDE_TEMPLATE_LIST } from "../../../TemplateSelector_DEPRECATED/actionTypes";
import { WINDOW_SHOW_WIDGET } from "../../../../redux/middleware/window/actionTypes";
import { SHOW_MODERN_HEADER_LAYOUT_LEFT_PANEL, SHOW_MODERN_FOOTER_LAYOUT_LEFT_PANEL } from "../../../ModernLayouts/actionTypes";

const
    closeUpdaters = [
        PANEL_COMPONENT_SHORTCUT_MOUSE_DOWN,
        APP_LEFT_MOUSE_DOWN,
        TOP_BAR_CLOSE_POPUP,
        OPEN_DIALOG,
        WINDOW_SHOW_WIDGET,
        LOGOUT,
        CLICKED_EXTERNAL_LINK,
        HIDE_TEMPLATE_LIST,
        PANEL_ITEM_LEFT_MOUSE_DOWN,
        SHOW_MODERN_HEADER_LAYOUT_LEFT_PANEL,
        SHOW_MODERN_FOOTER_LAYOUT_LEFT_PANEL,
    ].map(action => ({ conditions: [action], reducer: () => ({ state: null }) }));

export default makeEpic({
    defaultState: null,
    valueActionType,
    updaters: [
        {
            conditions: [TOP_BAR_OPEN_POPUP],
            reducer: ({ values: [payload], state }) => {
                if (state && payload.id === state.id) {
                    return { state: null };
                }

                return { state: payload };
            }
        },
        ...closeUpdaters
    ]
});
