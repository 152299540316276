import React, { useRef, useEffect } from "react";
import Lottie from "react-web-lottie";
import { ONBOARDING_CONTINUE_PRESSED } from "../../actionTypes";
import animationData from "./animation.json";
import { getStepMarkers, ONBOARDING_STEPS, LOTTIE_DEFAULT_OPTIONS, updateTextData, ELEMENT_INDEXES } from "./constant";

type WelcomeStepRightSideViewProps = {
    isLetsDoButtonPressed: boolean;
    dispatch: Dispatch;
};
const markers = animationData.markers;
const stepMarkers = getStepMarkers(markers, ONBOARDING_STEPS.WELCOME);
export default function WelcomeStepRightSideView({ isLetsDoButtonPressed, dispatch }: WelcomeStepRightSideViewProps) {
    const animationRef = useRef(null);
    useEffect(() => {
        if (isLetsDoButtonPressed) {
            // @ts-ignore
            animationRef.current.playSegments([[stepMarkers.intro_start_of_step, stepMarkers.intro_stop_point]], true);

            // @ts-ignore
            animationRef.current.onComplete = function () {
                dispatch({
                    type: ONBOARDING_CONTINUE_PRESSED
                });
            };
        }
    }, [isLetsDoButtonPressed]);
    useEffect(() => {
        // @ts-ignore
        animationRef.current.playSegments([[stepMarkers.intro_start_of_step, stepMarkers.intro_start_of_step + 1]], true);
        updateTextData({
            animationRef,
            elementIndex: ELEMENT_INDEXES.title
        });
    }, []);
    const defaultOptions = {
        ...LOTTIE_DEFAULT_OPTIONS,
        rendererSettings: {
            preserveAspectRatio: "xMaxYMid meet"
        },
        animationData
    };
    return <Lottie lottiRef={animationRef} options={defaultOptions} isClickToPauseDisabled speed={0.5} />;
}
