import CALL_API from "../../../redux/middleware/api/CALL_API";
import {
    GET_DESIGN_SITE_DATA_REQUEST,
    GET_DESIGN_SITE_DATA_SUCCESS,
    GET_DESIGN_SITE_DATA_FAILURE
} from "../actionTypes";
import type { ApiAction } from "../../../redux/middleware/api/flowTypes";

export default (designAccountName: string): ApiAction => ({
    [CALL_API]: {
        types: [
            GET_DESIGN_SITE_DATA_REQUEST,
            GET_DESIGN_SITE_DATA_SUCCESS,
            GET_DESIGN_SITE_DATA_FAILURE
        ],
        endpoint: 'getDesignSiteData',
        endpointParams: designAccountName
    }
});
