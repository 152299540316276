import oneColor from "onecolor";
import type { Color } from "./flowTypes";

const toCss = (color: Color): string => {
    if (!color) return color;
    if (color && color[4] === 0) {
        return 'transparent';
    } else {
        const asOneColor = oneColor(color);
        if (asOneColor.cssa) {
            return asOneColor.cssa();
        } else {
            return oneColor('#000000').cssa();
        }
    }
};

const getHSLFromHex = (color: string) => oneColor(color).hsl();
const toHex = (color: Color): string => oneColor(color).hex();
const colorToCss = toCss;

const
    LightThreshold = 100,
    MediumLightThreshold = 180,
    VeryLightThreshold = 238;

const getBrightness = (c: Color) => {
    const { _red, _green, _blue } = oneColor(c).rgb(); // converting to RGB

    // Porting decimal RGB to RGB 255 color format and adding repective coefficient factor to get brightness (https://www.itu.int/rec/R-REC-BT.709-6-201506-I/en)
    const brightness = (Math.round(255 * _red) * 0.2126) +
        (Math.round(255 * _green) * 0.7152) +
        (Math.round(255 * _blue) * 0.0722);

    return brightness;
};

const isLight = (c: Color): boolean => getBrightness(c) > LightThreshold;
const isDark = (c: Color): boolean => !isLight(c);
const isMediumLight = (c: Color): boolean => getBrightness(c) > MediumLightThreshold;
const isVeryLight = (c: Color): boolean => getBrightness(c) > VeryLightThreshold;
const getColorType = (c: Color): string => { return isDark(c) ? "dark" : "light"; };
const areSameColors = (c1: Color, c2: Color): boolean => c1.every((e, i) => e === c2[i]);

export {
    toCss,
    toHex,
    isVeryLight,
    isLight,
    isDark,
    isMediumLight,
    colorToCss,
    getColorType,
    getHSLFromHex,
    areSameColors
};
