import React from 'react';
import * as languages from '../../../../../PropertiesPanel/view/Language/page';

const
    id = languages.id,
    title = languages.title,
    view = ({ dispatch, selectedComponent: { locale } }) => {
        const LanguageView = languages.view;

        return (
            // @ts-ignore
            <LanguageView dispatch={dispatch} locale={locale} />
        );
    };

export { id, title, view };
