import React from 'react';
import { LogoView } from './index';
import { ComponentMaskBackground } from '../../../presentational/ComponentMask/constants';
import ComponentMask from '../../../presentational/ComponentMask/index';
import BaseComponent from '../../../../view/oneweb/BaseComponent';

export const LogoWorkspaceView = (props: { base: { height: number } }) => {
    return (
        <BaseComponent {...props}>
            <ComponentMask
                showPreviewOnlyLayer={false}
                style={{ height: props.base.height }}
                maskStyle={ComponentMaskBackground.TRANSPARENT}
            >
                {
                    // @ts-ignore
                    React.createElement(LogoView, { ...props, isWorkspace: true })
                }
            </ComponentMask>
        </BaseComponent>
    );
};
