import { assocPath } from 'ramda';
import kind from './kind';
import view from './view/index';
import calcRenderProps from './calcRenderProps';
import { imagePpConfig } from './propertiesPanel/index';
import { imageComponentsMainActions } from './componentMainActions/index';
import { makeImageChooserConfigDialog } from "../../FileManager/imageChooser/makeImageChooserConfigDialog";
import reducer from './reducers/componentChange';
import adjustmentHookConfig from './reducers/adjustmentHookConfig';
import adjustAfterPushdownHookConfig from './reducers/adjustAfterPushdownHookConfig';
import onMouseMoveAfterMouseDownInEditMode from './reducers/onMouseMoveOrUp';
import adjustComponentOnAdd from './adjustComponentOnAdd';
import siteDataEpic from '../../App/epics/siteData/index';
import { missingAssetUrls } from '../../MissingAssets/epic/index';
import styles from './view/Image.css';
import ScaleStrategy, { isFitMode } from './scaleStrategy';
import type { ImageComponent } from './flowTypes';
import mobileEditorConfig from "./mobileEditorConfig";
import appConfigEpic from '../../App/epics/appConfig/index';
import { IMAGE_SET_IMAGE } from "./actionTypes";
import { ComponentNames } from "../constants";
import { globalVariablesEpic } from '../../App/epics/globalVariablesEpic';
import type { ChangeOldComponentDataBeforeImportProps } from "../../../view/oneweb/flowTypes";
import { replaceTagsWithContent } from '../Text/view/replaceTagsWithContent';
import { BROKEN_LINK } from '../../../constants';
import { colorThemeSiteSettingsEpic } from "../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import stylesheetsEpic from '../../Workspace/epics/stylesheets/index';
import makeStateSelectorReducer from "../../../epics/makeStateSelectorReducer";
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";
import { isModernLayoutActivatedEpic } from "../../Workspace/epics/isModernLayoutActivatedEpic/index";

const imageComponentConfig = {
    view,
    kind,
    label: ComponentNames[kind],
    shortcutIconName: 'image',
    calcRenderProps: (props: any): any => calcRenderProps(props, styles), // TODO fix proptypes
    requireConfigurationOnDrop: () => true,
    configurationDialog: makeImageChooserConfigDialog({
        onSaveAction: IMAGE_SET_IMAGE,
    }),
    componentMainActions: imageComponentsMainActions,
    propertiesPanel: imagePpConfig,
    reducer,
    adjustmentHookConfig,
    adjustAfterPushdownHookConfig,
    shouldKeepAspectRatio: (component: ImageComponent): boolean => isFitMode(component),
    adjustComponentOnAdd,
    willHandleMouseMoveAfterMouseDownInEditMode: (component: ImageComponent): boolean =>
        (component.scaleStrategy === ScaleStrategy.CROP),
    onMouseMoveAfterMouseDownInEditMode,
    dependsOn: {
        site: siteDataEpic.reducer,
        missingAssetUrls,
        appConfig: appConfigEpic.reducer,
        globalVariables: globalVariablesEpic.reducer,
        isModernLayoutActivated: isModernLayoutActivatedEpic.reducer,
        themeSettingsData: colorThemeSiteSettingsEpic.reducer,
        themeColorsData: makeStateSelectorReducer(
            stylesheetsEpic.reducer,
            stylesheetsEpic.valueActionType,
            getThemeColorsFromStylesheet
        ),
    },
    mobileEditorConfig,
    changeOldComponentDataBeforeImport: ({
        component,
        globalVariables,
        site
    }: ChangeOldComponentDataBeforeImportProps<{ action?: { link?: { url?: string }}}>) => {
        let resultComponent = component;

        if (resultComponent.action && resultComponent.action.link && resultComponent.action.link.url) {
            const { url } = resultComponent.action.link;

            let replacedUrl = replaceTagsWithContent(url, { globalVariables, site }, true);

            if (replacedUrl !== url) {
                if (
                    replacedUrl &&
                    replacedUrl !== 'mailto:' &&
                    replacedUrl !== 'tel:'
                ) {
                    resultComponent = assocPath(['action', 'link', 'url'], replacedUrl, resultComponent);
                } else {
                    resultComponent = assocPath(['action', 'link'], BROKEN_LINK, resultComponent);
                }
            }
        }

        return resultComponent;
    },
};

export {
    imageComponentConfig as default,
    imageComponentConfig
};

