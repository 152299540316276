import * as tooltipActionTypes from "../../../Tooltip/actionTypes";
import * as mouseUtils from "../../../../utils/mouse";
import styles from "./facebookChatTooltip.css";
import FacebookChatKind from "../kind";

type Props = {
    dispatch: Dispatch,
    forceDisabled?: boolean
}

export default ({ dispatch, forceDisabled }: Props) => ({
    onMouseEnter: (e: React.MouseEvent<any, MouseEvent>) => {
        if (forceDisabled) {
            dispatch({
                type: tooltipActionTypes.SHOW_FORCE_DISABLED_FB_CHAT_TOOLTIP,
                payload: {
                    id: `${FacebookChatKind}ForceDisabled`,
                    boundingClientRect: mouseUtils.getBoundingClientRect(e),
                }
            });
        } else {
            dispatch({
                type: tooltipActionTypes.SHOW_DISABLED_FB_CHAT_TOOLTIP,
                payload: {
                    id: `${FacebookChatKind}Disabled`,
                    boundingClientRect: mouseUtils.getBoundingClientRect(e),
                    customClass: styles.disabledTipBackground,
                    customTextClass: styles.customTextStyle,
                    linkTextCustomClass: styles.linkTextCustomClass
                }
            });
        }
    },
    onMouseLeave: () => dispatch({ type: tooltipActionTypes.CLOSE_FB_CHAT_TOOLTIP })
});
