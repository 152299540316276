import { getAppConfig } from "../../App/epics/appConfig/appConfig";
import { instagramStockImagesList } from "./instagramStockImagesList";
import { INSTAGRAM_IMAGE } from "../../oneweb/InstagramGallery/constants/index";

const
    appConfig = getAppConfig(),
    stockImagesCdnUrl = appConfig.oneWeb && appConfig.oneWeb.instagram ? appConfig.oneWeb.instagram.stockImagesCdnUrl : '';

export const getInstagramStockImagesData = () => {
    const instagramStockImagesData = instagramStockImagesList.map(imageName => {
        return {
            media_url: stockImagesCdnUrl ? `${stockImagesCdnUrl}/${imageName}` : '',
            media_type: INSTAGRAM_IMAGE
        };
    });

    return instagramStockImagesData;
};
