import makeEpic from '../../../../epics/makeEpic';
import currentOpenedStylesheetIdValueActionType from '../currentOpenedStylesheetId/valueActionType';
import stylesheetsValueActionType from '../stylesheets/valueActionType';
import valueActionType from "./valueActionType";
import * as stylesheetsSelectors from '../stylesheets/selectors';

const defaultState = -1;

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                currentOpenedStylesheetIdValueActionType,
                stylesheetsValueActionType
            ],
            reducer: ({ values: [id, stylesheets] }) => ({
                state: id ? stylesheetsSelectors.indexByIdSelector(id)(stylesheets) || defaultState : defaultState
            })
        }
    ]
});
