import { awsAuthAction, awsS3Action } from "../../../../src/redux/middleware/aws/actions";

export type UserSignInRequest = {
    username: string,
    password?: string,
};

export const
    DEMO_LOGIN_ACTION = 'DEMO_LOGIN_ACTION',
    demoLoginAction = () => ({
        type: DEMO_LOGIN_ACTION,
    });

export const
    DEMO_LOGIN_COMPLETE_ACTION = 'DEMO_LOGIN_COMPLETE_ACTION',
    demoLoginCompleteAction = (trialUserId: string) => ({
        type: DEMO_LOGIN_COMPLETE_ACTION,
        payload: trialUserId,
    });

export const
    DEMO_CURRENT_AUTHENTICATED_USER_REQUEST_ACTION = 'DEMO_CURRENT_AUTHENTICATED_USER_REQUEST_ACTION',
    DEMO_CURRENT_AUTHENTICATED_USER_SUCCESS_ACTION = 'DEMO_CURRENT_AUTHENTICATED_USER_SUCCESS_ACTION',
    DEMO_CURRENT_AUTHENTICATED_USER_FAILURE_ACTION = 'DEMO_CURRENT_AUTHENTICATED_USER_FAILURE_ACTION',
    demoCurrentAuthenticatedUserAction = () => awsAuthAction({
        actionTypes: [
            DEMO_CURRENT_AUTHENTICATED_USER_REQUEST_ACTION,
            DEMO_CURRENT_AUTHENTICATED_USER_SUCCESS_ACTION,
            DEMO_CURRENT_AUTHENTICATED_USER_FAILURE_ACTION,
        ],
        serviceMethod: 'currentAuthenticatedUser',
        serviceMethodParams: [{
            bypassCache: true
        }]
    });

export const
    DEMO_LOGIN_REQUEST_ACTION = 'DEMO_LOGIN_REQUEST_ACTION',
    DEMO_LOGIN_SUCCESS_ACTION = 'DEMO_LOGIN_SUCCESS_ACTION',
    DEMO_LOGIN_FAILURE_ACTION = 'DEMO_LOGIN_FAILURE_ACTION',
    demoLogInAction = (userSignInRequest: UserSignInRequest) => awsAuthAction({
        actionTypes: [
            DEMO_LOGIN_REQUEST_ACTION,
            DEMO_LOGIN_SUCCESS_ACTION,
            DEMO_LOGIN_FAILURE_ACTION,
        ],
        serviceMethod: 'signIn',
        serviceMethodParams: [{
            username: userSignInRequest.username,
            password: userSignInRequest.password,
        }]
    });

export const
    DEMO_CURRENT_USER_INFO_REQUEST_ACTION = 'DEMO_CURRENT_USER_INFO_REQUEST_ACTION',
    DEMO_CURRENT_USER_INFO_SUCCESS_ACTION = 'DEMO_CURRENT_USER_INFO_SUCCESS_ACTION',
    DEMO_CURRENT_USER_INFO_FAILURE_ACTION = 'DEMO_CURRENT_USER_INFO_FAILURE_ACTION',
    demoCurrentUserInfoAction = () => awsAuthAction({
        actionTypes: [
            DEMO_CURRENT_USER_INFO_REQUEST_ACTION,
            DEMO_CURRENT_USER_INFO_SUCCESS_ACTION,
            DEMO_CURRENT_USER_INFO_FAILURE_ACTION,
        ],
        serviceMethod: 'currentUserInfo',
    });

export const
    DEMO_INITIALIZE_USER_MEDIA_PATH_REQUEST_ACTION = 'DEMO_INITIALIZE_USER_MEDIA_PATH_REQUEST_ACTION',
    DEMO_INITIALIZE_USER_MEDIA_PATH_SUCCESS_ACTION = 'DEMO_INITIALIZE_USER_MEDIA_PATH_SUCCESS_ACTION',
    DEMO_INITIALIZE_USER_MEDIA_PATH_FAILURE_ACTION = 'DEMO_INITIALIZE_USER_MEDIA_PATH_FAILURE_ACTION',
    demoInitializeUserMediaPath = (userCognitoIdentityId: string) => awsS3Action({
        actionTypes: [
            DEMO_INITIALIZE_USER_MEDIA_PATH_REQUEST_ACTION,
            DEMO_INITIALIZE_USER_MEDIA_PATH_SUCCESS_ACTION,
            DEMO_INITIALIZE_USER_MEDIA_PATH_FAILURE_ACTION,
        ],
        serviceMethod: 'createUserMediaFolder',
        serviceMethodParams: [userCognitoIdentityId]
    });
export const
    DEMO_UPDATE_SIGNIN_EMAIL_VALUE_ACTION = 'DEMO_UPDATE_SIGNIN_EMAIL_VALUE_ACTION',
    updateSignInEmailValue = (emailValue: string) => ({
        type: DEMO_UPDATE_SIGNIN_EMAIL_VALUE_ACTION,
        payload: { emailValue },
        amendToSelf: true
    });

export const
    DEMO_UPDATE_SIGNIN_PASSWORD_VALUE_ACTION = 'DEMO_UPDATE_SIGNIN_PASSWORD_VALUE_ACTION',
    updatePasswordValue = (passwordValue: String) => ({
        type: DEMO_UPDATE_SIGNIN_PASSWORD_VALUE_ACTION,
        payload: { passwordValue },
        amendToSelf: true
    });

export const
    DEMO_TOGGLE_SHOW_PASSWORD_VALUE_ACTION = 'DEMO_TOGGLE_SHOW_PASSWORD_VALUE_ACTION',
    toggleShowPassword = () => ({
        type: DEMO_TOGGLE_SHOW_PASSWORD_VALUE_ACTION
    });

export const
    DEMO_SHOW_SIGNUP_PAGE_ACTION = 'DEMO_SHOW_SIGNUP_PAGE_ACTION',
    showSignUpPage = () => ({
        type: DEMO_SHOW_SIGNUP_PAGE_ACTION
    });

export const
    DEMO_SHOW_FORGOT_PASSWORD_PAGE_ACTION = 'DEMO_SHOW_FORGOT_PASSWORD_PAGE_ACTION',
    showForgotPasswordPage = () => ({
        type: DEMO_SHOW_FORGOT_PASSWORD_PAGE_ACTION
    });

export const
    DEMO_TOGGLE_LOGGED_IN_CHECKBOX_ACTION = 'DEMO_TOGGLE_LOGGED_IN_CHECKBOX_ACTION',
    keepLoggedInToggle = () => ({
        type: DEMO_TOGGLE_LOGGED_IN_CHECKBOX_ACTION
    });

export const
    DEMO_SIGNIN_SHOW_INVALID_EMAIL_ERROR_ACTION = 'DEMO_SIGNIN_SHOW_INVALID_EMAIL_ERROR_ACTION',
    showInvalidEmailError = () => ({
        type: DEMO_SIGNIN_SHOW_INVALID_EMAIL_ERROR_ACTION
    });

export const DEMO_SIGNIN_CLEAN_ALL_ERRORS_ACTION = 'DEMO_SIGNIN_CLEAN_ALL_ERRORS_ACTION',
    cleanUpAllErrors = () => ({
        type: DEMO_SIGNIN_CLEAN_ALL_ERRORS_ACTION
    });

export const DEMO_SIGNIN_SHOW_INVALID_PASSWORD_ERROR_ACTION = 'DEMO_SIGNIN_SHOW_INVALID_PASSWORD_ERROR_ACTION',
    showInvalidPasswordError = () => ({
        type: DEMO_SIGNIN_SHOW_INVALID_PASSWORD_ERROR_ACTION
    });
export const
    DEMO_CLEAN_UP_SIGNIN_SCREEN_ACTIONS = 'DEMO_CLEAN_UP_SIGNIN_SCREEN_ACTIONS',
    cleanUpSigninScreen = () => ({
        type: DEMO_CLEAN_UP_SIGNIN_SCREEN_ACTIONS
    });
export const
    DEMO_SIGNIN_LOADING_ACTION = 'DEMO_SIGNIN_LOADING_ACTION',
    signInLoading = () => ({
        type: DEMO_SIGNIN_LOADING_ACTION
    });

export const
    DEMO_SIGNIN_SET_TRIAL_ENDED_DATA = 'DEMO_SIGNIN_SET_TRIAL_ENDED_DATA',
    signInSetTrialEndedData = (trialEndedData: Record<string, any>) => ({
        type: DEMO_SIGNIN_SET_TRIAL_ENDED_DATA,
        payload: trialEndedData,
    });

export const
    DEMO_CURRENT_RESEND_CONFIRMATION_CODE_REQUEST_ACTION = 'DEMO_CURRENT_RESEND_CONFIRMATION_CODE_REQUEST_ACTION',
    DEMO_CURRENT_RESEND_CONFIRMATION_CODE_SUCCESS_ACTION = 'DEMO_CURRENT_RESEND_CONFIRMATION_CODE_SUCCESS_ACTION',
    DEMO_CURRENT_RESEND_CONFIRMATION_CODE_FAILURE_ACTION = 'DEMO_CURRENT_RESEND_CONFIRMATION_CODE_FAILURE_ACTION',
    demoResendConfirmationCodeAction = (email: string) => awsAuthAction({
        actionTypes: [
            DEMO_CURRENT_RESEND_CONFIRMATION_CODE_REQUEST_ACTION,
            DEMO_CURRENT_RESEND_CONFIRMATION_CODE_SUCCESS_ACTION,
            DEMO_CURRENT_RESEND_CONFIRMATION_CODE_FAILURE_ACTION,
        ],
        serviceMethod: 'resendSignUp',
        serviceMethodParams: [email]
    });
