import valueActionType from "./valueActionType";
import makeEpic from "../../../../epics/makeEpic";
import { SHOW_TOOLTIP_FOR_MVE_MENU,
    SHOW_TOOLTIP_FOR_MVE_CMP_REORDER } from "../../../Tooltip/actionTypes";
import { PointerDirections } from "../../../Tooltip/constants";
import { MOBILE_EDITOR_HEADER_CLICKED,
    MOBILE_EDITOR_CMP_REORDER_TIP, MOBILE_EDITOR_CMP_REORDER_TIP_SHOWN } from "../../actionTypes";

const TIP_BOTTOM_PADDING = 13;

export default makeEpic({
    defaultState: null,
    valueActionType,
    updaters: [
        {
            conditions: [MOBILE_EDITOR_HEADER_CLICKED],
            reducer: ({ values: [tooltipData], state }) => {
                if (tooltipData) {
                    const
                        {
                            id,
                            boundingClientRect,
                            contentContainerBoundingClientRect,
                            floorTooltipBbox,
                            pointerDirection
                        } = tooltipData,
                        isTooltipOnLeft = pointerDirection === PointerDirections.Right,
                        tipInfoStyle = isTooltipOnLeft ?
                            { padding: '10px 10px 10px 15px' } : { padding: '10px 8px 10px 14px' },
                        crossIconStyle = isTooltipOnLeft ?
                            { marginRight: 3, marginTop: -5 } : { marginRight: 6, marginTop: -5 },
                        x = isTooltipOnLeft ?
                            contentContainerBoundingClientRect.left : contentContainerBoundingClientRect.right,
                        y = boundingClientRect.top + (boundingClientRect.height / 2);

                    return {
                        state,
                        actionToDispatch: {
                            type: SHOW_TOOLTIP_FOR_MVE_MENU,
                            payload: {
                                id,
                                position: {
                                    x,
                                    y
                                },
                                floorTooltipBbox,
                                crossIconStyle,
                                tipInfoStyle
                            }
                        }
                    };
                }
                return { state };
            }
        },
        {
            conditions: [MOBILE_EDITOR_CMP_REORDER_TIP],
            reducer: ({ values: [{ id, verticalPosition, boundingClientRect }], state }) => {
                const
                    tipInfoStyle = { paddingBottom: TIP_BOTTOM_PADDING },
                    x = boundingClientRect.right,
                    y = verticalPosition;
                let actionsToDispatch = [{
                    type: SHOW_TOOLTIP_FOR_MVE_CMP_REORDER,
                    payload: {
                        id,
                        position: {
                            x,
                            y
                        },
                        tipInfoStyle
                    }
                },
                {
                    type: MOBILE_EDITOR_CMP_REORDER_TIP_SHOWN
                }];
                return {
                    state,
                    multipleActionsToDispatch: actionsToDispatch,
                };
            }
        }
    ]
});
