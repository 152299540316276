/* eslint-disable react/no-unused-prop-types */

import * as React from 'react';
import cx from 'classnames';
import styles from './styles.css';
import type { TabProps, TabsComponentProps, TabsComponentState } from './flowTypes';
import { injectIntl } from "../../../view/intl/index";

const Tab = ({ children, className }: TabProps) => <div className={className}>{children}</div>;

class Tabs extends React.Component<TabsComponentProps, TabsComponentState> {
    constructor({ children: tabs, activeTabKey }: TabsComponentProps) {
        // @ts-ignore
        super();
        this.state = { activeTabKey: activeTabKey || tabs[0].props.id };
    }

    getTabs(): Array<React.ReactElement<any>> {
        const tabs = this.props.children.filter(t => !!t);
        if (!tabs || !tabs.length) throw new Error('Missing tabs');
        return tabs;
    }

    getActiveTab() {
        const tab = this.getTabs().find(t => t.props.id === this.state.activeTabKey);
        if (!tab) throw new Error('Cannot find active tab by key: ' + this.state.activeTabKey);
        return tab;
    }

    switchTab(tab: React.JSX.Element) {
        this.setState({ activeTabKey: tab.props.id });
    }

    renderPanel() {
        const { intl, onTabClick } = this.props;
        return (
            <div className={styles.tabHeader}>
                {this.getTabs().map((t, i) => (
                    <span
                        className={cx('', { [styles.activeTab]: t.props.id === this.state.activeTabKey })}
                        onClick={() => { this.switchTab(t); onTabClick(t.props.id); }}
                        key={i}
                    >
                        {intl.msgJoint(t.props.label)}
                    </span>
                ))}
            </div>
        );
    }

    renderTabBody() {
        return <div className={styles.tabBody}>{this.getActiveTab()}</div>;
    }

    render() {
        return (
            <div className={cx(this.props.className)}>
                {this.renderPanel()}
                {this.renderTabBody()}
            </div>
        );
    }
}
const IntlTabs = injectIntl(Tabs);

export { IntlTabs as default, Tab };
