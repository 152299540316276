import React from 'react';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import * as Actions from '../../actionTypes';
import * as pagesIds from "./ids";
import pagesTitles from "./titles";
import type { Props } from '../flowTypes';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import injectIntl from '../../../../../view/intl/injectIntl';
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';
import Combobox from "../../../../../view/common/Combobox/index";
import { getLocaleList } from '../../../../presentational/LocaleList/utils';
import stylePropertyContainer from '../../../../../view/common/PropertyContainer/PropertyContainer.css';
import * as styles from '../styles.css';
import CheckBoxSlider from '../../../../../view/common/CheckBox/CheckBoxSlider';
import Msg from '../../../../../view/intl/Msg';
import { Flex } from '../../../../../view/reflexbox/index';

const
    id = pagesIds.CAPTCHA,
    readMoreFCLink = "https://friendlycaptcha.com/",
    title = pagesTitles[id],
    supportFCLocalae = (locale) => {
        return locale.filter((locale) => (locale.value !== "pt_br" && locale.value !== "nl"));
    },
    view = injectIntl(({ intl, dispatch, selectedComponent }: Props) => {
        const { isCaptchaEnabled, captchaLang } = selectedComponent;
        return (
            <Page>
                <VerticalSpacer />
                <PropertyContainer
                    label="msg: component.contactForm.captchaDescription
                    {Friendly Captcha’s fully GDPR-compliant captcha protects your contact form from bots and spam.}"
                    className={stylePropertyContainer.pointer}
                >
                    <a target="_blank" className={styles.readMore} href={readMoreFCLink}>
                        {intl.msgJoint("msg: common.component.contactForm.captcha.readMore {Read about Friendly Captcha}")}
                    </a>
                    <Flex style={{ "paddingTop": "10px", "alignItems": "center" }}>
                        <CheckBoxSlider
                            isChecked={isCaptchaEnabled}
                            className={styles.captchaToggle}
                            isGreen={false}
                            onClick={() => dispatch({
                                type: Actions.CONTACT_FORM_TOGGLE_CAPTCHA
                            })}
                        />
                        <Msg className={styles.captchaToggleTitle} k="component.contactForm.fieldProps.showCaptcha">Show Captcha</Msg>
                    </Flex>
                    <VerticalSpacer y={18} />
                    <div>
                        <Msg className={styles.captchaToggleTitle} k="component.contactForm.fieldProps.language">Language</Msg>
                        <Combobox
                            placeholder={
                                intl.msgJoint('msg: component.contactForm.fieldProps.select.language.placeholder {Select language}')
                            }
                            // @ts-ignore mostly we can remove this intl
                            options={supportFCLocalae(getLocaleList())}
                            value={captchaLang}
                            disabled={!isCaptchaEnabled}
                            className={styles.captchaLanguageSelect}
                            onChange={({ value: locale }) => {
                                return dispatch({ type: Actions.CAPTCHA_LOCALE_CHANGED, payload: { captchaLanguage: locale } });
                            }}
                        />
                    </div>
                </PropertyContainer>
            </Page>
        );
    });

export { id, title, view };
