import React from 'react';
import registry from '../../view/oneweb/registry/preview';
import Background from '../oneweb/Background/kind';
import HoverBox from '../oneweb/HoverBox/kind';
import Menu from "../oneweb/Menu/kind";
import Text from "../oneweb/Text/kind";
import ContactForm from "../oneweb/ContactForm/kind";
import Strip from "../oneweb/Strip/kind";
import Table from '../oneweb/Table/kind';
import Gallery from "../oneweb/Gallery/kind";
import Button from "../oneweb/Button/kind";
import { SOCIAL_KIND } from "../oneweb/Social/kind";
import * as styles from './pushDownPreviewMode.css';
import { EMAIL_KIND } from '../oneweb/TextLike/Email/kind';
import { PHONE_KIND } from '../oneweb/TextLike/Phone/kind';
import { ADDRESS_KIND } from '../oneweb/TextLike/Address/kind';
import Section from "../oneweb/Section/kind";
import SVG_KIND from "../oneweb/Svg/kind";
import { LOGO_KIND } from '../oneweb/Logo/kind';

const ComponentKindsToRenderForPushDown = {
    [Background]: true,
    [HoverBox]: true,
    [Strip]: true,
    [Section]: true,
    [ContactForm]: true,
    [Table]: true,
    [Menu]: true,
    [Text]: true,
    [Gallery]: true,
    [Button]: true,
    [SOCIAL_KIND]: true,
    [EMAIL_KIND]: true,
    [PHONE_KIND]: true,
    [ADDRESS_KIND]: true,
    [SVG_KIND]: true,
    [LOGO_KIND]: true,
};

export default (pushDown?: boolean, componentsKind: Record<string, any> = {}) => {
    if (!pushDown) {
        return registry;
    }
    return Object.keys(registry).reduce((acc, kind) => {
        let combinedMap = { ...ComponentKindsToRenderForPushDown, ...componentsKind };
        if (!combinedMap[kind]) {
            acc[kind] = {
                kind,
                view: ({ component: { id, kind, width, height }, children, paddingBottom }) => {
                    return (
                        <div
                            className={styles.dummyContainer}
                            data-id={id}
                            data-kind={kind}
                            style={{ width, minHeight: height, paddingBottom }}
                        >
                            {children}
                        </div>
                    );
                }
            };
        } else {
            acc[kind] = registry[kind];
        }
        return acc;
    }, {});
};
