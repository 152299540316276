
import { getReduceFactor } from "../Gallery/utils";
import { ImagePixelRatios, JpegCompressionQuality } from './constants';
import { getAssetUrl } from "../../../utils/assetUtils";

/**
 * Apply a transform to an image URL.
 * @protected
 * @override
 * @param {Object}  config
 * @param {String}  config.url
 * @param {Object}  config.params Parameter/value pairs to be included in the URL.
 * @param {boolean} config.hdImages
 * @param {Number}  config.w
 * @param {Number}  config.h
 * @param {Number}  config.left
 * @param {Number}  config.top
 *
 * @return {Object} assetUrlObj e.g. { src="...", srcSet=[...], cssUrls=[...] }
 */
const applyTransforms = (image, config, domain) => {
    let url1x,
        srcSet,
        cssUrls = {},
        { hdImages, left, top, innerWidth, innerHeight } = config,
        pixelRatios = hdImages ? ImagePixelRatios : [1],
        reduceFactor,
        reducedPixelRatio,
        w,
        h,
        el,
        et,
        ew,
        eh,
        asset = image.asset,
        processParams;

    // container width height spacing
    const
        icw = innerWidth,
        ich = innerHeight;

    pixelRatios.forEach(pixelRatio => {
        processParams = {};
        w = config.w;
        h = config.h;

        if (image.rotation !== 0) {
            processParams.rotate = image.rotation;
        }

        let flagResize = false,
            flagExtract = false;

        if (image.scale !== 1) {
            flagResize = true;
        }
        if (w > icw || h > ich) {
            flagExtract = true;

            if (left + w < icw) {
                left -= w + left - icw;
            }
            if (top + h < ich) {
                top -= h + top - ich;
            }
        }

        if (flagExtract || flagResize) {
            // Whenever "extract" parameter is required, "resize" parameter should also be used (See: ONEWEB-10506)
            // Math.min(maxDimension - 1, <width/height>) is being used to fix ONEWEB-10472 - Publishing stuck at 99%
            // The "sharp" library which we are using for image operations, supports width/height upto 16k by default,
            // as can be seen at https://github.com/lovell/sharp/blob/v0.14.0/index.js#L31
            // Similarly, the width/height limit is there in express-processimage (https://github.com/papandreou/express-processimage/blob/v5.5.0/lib/getFilterInfosAndTargetContentTypeFromQueryString.js#L75)

            if (hdImages) {
                w = w * pixelRatio;
                h = h * pixelRatio;
            }
            reduceFactor = getReduceFactor(w, h, asset.width, asset.height);

            w = Math.max(1, Math.round(w * reduceFactor));
            h = Math.max(1, Math.round(h * reduceFactor));
            processParams.ignoreAspectRatio = null; // so that we just put query parameter with no value
            processParams.resize = w + '+' + h;

            if (asset.contentType === "image/gif" && !asset.animated) {
                // ONEWEB-10176
                // Graphicsmagick resizes the gif images and makes them pixelated. In order to achive the anti-aliasing we can convert this to png on the fly.
                processParams.png = null;
            }
        }
        if (flagExtract) {
            reducedPixelRatio = pixelRatio * reduceFactor;
            el = Math.floor(Math.max(0, -left * reducedPixelRatio));
            et = Math.floor(Math.max(0, -top * reducedPixelRatio));
            ew = Math.floor(Math.max(1, icw * reducedPixelRatio));
            eh = Math.floor(Math.max(1, ich * reducedPixelRatio));

            if (h && eh > h) {
                eh = h;
            }
            processParams.extract = el + '+' + et + '+' + ew + '+' + eh;
        }

        if (Object.keys(processParams).length > 0) {
            if (asset.contentType === 'image/jpeg') {
                processParams.quality = JpegCompressionQuality;
            }
        }

        let url = getAssetUrl(image.asset, processParams, domain);

        if (hdImages) {
            srcSet = srcSet || [];
            srcSet.push(url + ' ' + pixelRatio + 'x');
        }
        if (hdImages || pixelRatio === 1) {
            cssUrls[pixelRatio] = url;
        }
        url1x = url1x || url;
    });

    return {
        src: url1x,
        srcSet: srcSet && srcSet.join(', '),
        cssUrls
    };
};

export default applyTransforms;
