import { arrayMove } from "react-sortable-hoc";
import type { SocialGlobalData } from '../../flowTypes';
import {
    SOCIAL_GLOBAL_DATA_ADD_PLATFORM,
    SOCIAL_GLOBAL_DATA_SET_PLATFORM_URL,
    SOCIAL_GLOBAL_DATA_DELETE_PLATFORM,
    SOCIAL_GLOBAL_DATA_SET_LINKS,
    SOCIAL_GLOBAL_DATA_UPDATE_PLATFORM_URL
} from './actionTypes';
import { SOCIAL_LINKS_SORT_END, SOCIAL_GLOBAL_LINKS_SORTED } from "../../actionTypes";
import { SOCIAL_KIND } from '../../kind';
import {
    FILL_SPECIFIC_SITE_SETTINGS_DURING_PAGE_OR_TEMPLATE_IMPORT
} from '../../../../App/epics/siteSettings/actionTypes';
import { makeActionForwardToSelectedComponent } from '../../../../../redux/forwardTo';
import { receiveOnly } from '../../../../../epics/makeCondition';
import { makeSiteSettingsChildEpic } from '../../../../App/epics/siteSettings/makeSiteSettingsChildEpic';
import { socialServiceVat } from '../socialServiceEpic/socialServiceVat';

export const socialGlobalDataInitialState: SocialGlobalData = {
    links: []
};

export const socialGlobalDataEpic = makeSiteSettingsChildEpic/* ::<SocialGlobalData, void> */({
    defaultState: socialGlobalDataInitialState,
    undo: true,
    key: 'socialData',
    handleActions: {
        [SOCIAL_GLOBAL_DATA_SET_LINKS]: (state, { payload: links }) => ({ ...state, links }),
        [SOCIAL_GLOBAL_DATA_ADD_PLATFORM]: (state, { payload: { id, linkKind, url } }) => ({
            ...state,
            links: [...state.links, { id, kind: linkKind, url }]
        }),
        [SOCIAL_GLOBAL_DATA_UPDATE_PLATFORM_URL]: (state, { payload: { id, url } }) => ({
            ...state,
            links: state.links.map(link => {
                if (link.id === id) {
                    return { ...link, url };
                }
                return link;
            })
        }),
        [SOCIAL_GLOBAL_DATA_SET_PLATFORM_URL]: (state, { payload: { url, id } }) => ({
            ...state,
            links: state.links.map(link => (link.id === id ? ({ ...link, url }) : link))
        }),
        [SOCIAL_GLOBAL_DATA_DELETE_PLATFORM]: (state, { payload: { linkId } }) => ({
            ...state,
            links: state.links.filter((link) => link.id !== linkId)
        }),
        [FILL_SPECIFIC_SITE_SETTINGS_DURING_PAGE_OR_TEMPLATE_IMPORT]: (state, { payload: { pages } }) => {
            const importedPagesPlatformKindsAndIds = pages.reduce((linksKindsAndIds, page) => {
                page.items.forEach(item => {
                    if (item.type === SOCIAL_KIND) {
                        item.links.forEach(({ kind: visibleLinkKind }, index) => {
                            if (!linksKindsAndIds.find(({ kind }) => kind === visibleLinkKind)) {
                                linksKindsAndIds.push(item.links[index]);
                            }
                        });
                    }
                });
                return linksKindsAndIds;
            }, []);

            const missingLinkKindsAndIds = importedPagesPlatformKindsAndIds
                .filter(({ kind }) => !state.links.find(l => l.kind === kind));

            if (missingLinkKindsAndIds.length) {
                return {
                    ...state,
                    links: [
                        ...state.links,
                        ...missingLinkKindsAndIds.map(({ kind, id }) => ({ id, kind, url: '' }))
                    ]
                };
            }
            return state;
        }
    },
    updaters: [
        {
            conditions: [
                receiveOnly(
                    socialServiceVat,
                    ({ linksForComponentInEditMode }) => linksForComponentInEditMode
                ),
                SOCIAL_LINKS_SORT_END
            ],
            reducer: ({ state, values: [linksForComponentInEditMode, { oldIndex, newIndex }] }) => {
                if (oldIndex === newIndex) return { state };

                const linksForComponentInEditModeSorted = arrayMove(linksForComponentInEditMode, oldIndex, newIndex);
                const links = linksForComponentInEditModeSorted
                    // @ts-ignore
                    .map(({ id }) => state.links.find(link => link.id === id));

                return ({
                    state: { ...state, links },
                    updateReason: SOCIAL_LINKS_SORT_END,
                    actionToDispatch: makeActionForwardToSelectedComponent(({
                        type: SOCIAL_GLOBAL_LINKS_SORTED,
                        payload: links
                    } as any))
                });
            }
        }
    ]
});
