/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { ReviewViewType } from '../../constants';
import RatingStars from '../RatingStars/RatingStars';
import * as styles from './ReviewCard.css';
import { getRelativeTimeFromDate } from './utils';

const ReviewerImg = ({ profilePhoto }) => (
    <img
        src={profilePhoto}
        className={`${styles.reviewImg} reviewImg`}
    />
);
const ReviewText = ({ comment, commentRef, setCommentIsEllipse }) => {
    const commentWidth: number = commentRef?.current?.clientWidth || 0;
    useEffect(() => {
        const targetEle = commentRef?.current;
        setCommentIsEllipse(!!targetEle && targetEle.clientHeight < targetEle.scrollHeight);
    }, [commentWidth]);
    const commentList = comment.split('\n');
    const lastCommentIndex = commentList.length - 1;

    return (
        <div ref={commentRef} className={cx(styles.reviewText, 'comment')}>
            <p>
                { commentList.map((line: string, index: number) => (
                    <React.Fragment key={index}>
                        {line}
                        {index !== lastCommentIndex && <br />}
                    </React.Fragment>
                )) }
            </p>
        </div>

    );
};

const ReviewerName = ({ name }) => (
    <span className={`${styles.reviewerName} title`}>{name}</span>
);

const ReviewDate = ({ date, reviewTimeText }) => {
    const { key, count } = getRelativeTimeFromDate(date);
    let dateLabel = '';
    if (key && count && reviewTimeText[key]) {
        dateLabel = reviewTimeText[key].replace('{count}', count);
    }
    // eslint-disable-next-line react/no-unknown-property
    return <div className={`${styles.reviewDate} date`} review-date={date}>{dateLabel}</div>;
};

const ReadMore = ({ seeMoreText, seeLessText, hide = false }) => (
    seeMoreText ?
        <div className={cx(`${styles.readMore} readMore`, { [styles.hideReadMore]: hide })}>
            <span data-see-more={seeMoreText} data-see-less={seeLessText}>{seeMoreText}</span>
        </div> : null
);

const ReviewCardCell = ({
    customClass, name, profilePhoto, rating, showReadMore,
    comment, date, cardWidth, onClick = () => {},
    seeMoreText = '',
    seeLessText = '', reviewTimeText
}) => {
    const [isCommentEllipse, setCommentIsEllipse] = useState(false);
    const commentRef = useRef<HTMLParagraphElement>(null);
    return (
        <div
            className={`${styles.reviewItem} ${styles.cardLayout} card reviewItem ${customClass}`}
            style={{
                width: cardWidth
            }}
            onClick={onClick}
        >
            <ReviewerImg profilePhoto={profilePhoto} />
            <ReviewerName name={name} />
            <RatingStars rating={rating} />
            { comment && <ReviewText comment={comment} commentRef={commentRef} setCommentIsEllipse={setCommentIsEllipse} /> }
            { showReadMore && <ReadMore seeMoreText={seeMoreText} seeLessText={seeLessText} hide={!isCommentEllipse} /> }
            <ReviewDate date={date} reviewTimeText={reviewTimeText} />
        </div>
    );
};

const ReviewListCell = ({ name, profilePhoto, rating, showReadMore, comment, date, seeMoreText, seeLessText, reviewTimeText }) => {
    const [isCommentEllipse, setCommentIsEllipse] = useState(false);
    const commentRef = useRef<HTMLParagraphElement>(null);
    return (
        <div className={`${styles.reviewItem} ${styles.listLayout} list reviewItem`}>
            <div className={styles.profileContainer}>
                <div className={styles.profileWrapper}>
                    <ReviewerImg profilePhoto={profilePhoto} />
                    <div className={styles.listDetailsWrapper}>
                        <ReviewerName name={name} />
                        <div className={styles.desktopReviewWrapper}>
                            <RatingStars rating={rating} />
                        </div>
                        <ReviewDate date={date} reviewTimeText={reviewTimeText} />
                    </div>
                </div>
                <div className={styles.mobileReviewWrapper}>
                    <RatingStars rating={rating} />
                </div>
            </div>
            <div>
                { comment && <ReviewText comment={comment} commentRef={commentRef} setCommentIsEllipse={setCommentIsEllipse} /> }
                { showReadMore && <ReadMore seeMoreText={seeMoreText} seeLessText={seeLessText} hide={!isCommentEllipse} /> }
            </div>
        </div>
    );
};

const ReviewCell = (props) => {
    switch (props.layoutType) {
        case ReviewViewType.LIST:
            return <ReviewListCell {...props} />;

        case ReviewViewType.CARD:
        case ReviewViewType.SLIDER:
        default:
            return <ReviewCardCell {...props} />;
    }
};

export default ReviewCell;
