// @ts-nocheck
import { SITE_DATA_MOBILE_VIEW_ACTIVATION_TOGGLE } from "../../App/epics/siteData/actionTypes";
import * as ActionTypes from "../actionTypes";
import { DESKTOP_PREVIEW, MOBILE_PREVIEW } from '../../App/actionTypes';

export const
    toggleMobileOptionAC = () => ({ type: SITE_DATA_MOBILE_VIEW_ACTIVATION_TOGGLE }),
    clickedToggleMobileOptionAC = () => ({ type: ActionTypes.PREVIEW_TOGGLE_MOBILE_VERSION_CLICKED }),
    disableMobileVersionAC = () => ({ type: ActionTypes.DISABLE_MOBILE_VERSION }),
    mobilePreviewAction = () => ({ type: MOBILE_PREVIEW }),
    desktopPreviewAction = () => ({ type: DESKTOP_PREVIEW });
