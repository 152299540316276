import { makeDefaultContentOnDropForText } from "./constants";
import type { TextComponent } from './flowTypes';

type DependsOn = {
    defaultStyleId: string
}

export default (defaultState: TextComponent, { defaultStyleId }: DependsOn): TextComponent =>
    ({
        ...defaultState,
        globalStyleId: defaultState.globalStyleId && defaultStyleId,
        content: defaultState.content || makeDefaultContentOnDropForText()
    });
