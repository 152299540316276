import { WidgetsPaypalKind } from './kind';
import { componentMainActions } from './componentMainAction';
import { propertiesPanel } from './propertiesPanel/index';
import { reducer } from './reducer';
import { configurationDialogId } from './configurationDialog';
import { calcRenderProps } from './calcRenderProps';
import { PaypalWorkspace } from './view/workspace';
import { PaypalView } from './view/index';
import { WidgetsPaypalConfig } from '../constants';
import { getMVEHiddenElementData } from '../mobileEditorConfig';

const WidgetsPaypal = {
    kind: WidgetsPaypalKind,
    label: WidgetsPaypalConfig.title,
    shortcutIconName: 'widgetsPaypal',
    workspace: PaypalWorkspace,
    view: PaypalView,
    requireConfigurationOnDrop: () => true,
    configurationDialog: configurationDialogId,
    calcRenderProps,
    componentMainActions,
    propertiesPanel,
    reducer,
    mobileEditorConfig: {
        kind: WidgetsPaypalKind,
        calcProps: calcRenderProps,
        view: PaypalView,
        getHiddenElementData: getMVEHiddenElementData(WidgetsPaypalConfig),
    },
};

export { WidgetsPaypal };

