import { makeEpic } from "../../../../epics/makeEpic";
import { ComponentsMapSelector } from '../../../Workspace/epics/componentsEval/selectorActionTypes';
import { getTopMostPageSectionId } from '../utils';

export const topMostPageSectionIdEpic = makeEpic({
    defaultState: null,
    valueActionType: "TOPMOST_PAGE_SECTION_ID",
    updaters: [
        {
            conditions: [ComponentsMapSelector],
            reducer: ({ values: [componentsMap] }) => {
                return {
                    state: getTopMostPageSectionId(componentsMap)
                };
            }
        },
    ]
});
