import {
    ShowNavigator,
    ShowIndicator,
    Transition,
    ShowNextImage,
    CaptionsPlacement
} from '../constants';

export const
    fwdBackOptions = [{
        label: 'msg: component.imageSlider.pp.controls.fwdBack.showAlways {Show always}',
        value: ShowNavigator.ALWAYS
    }, {
        label: 'msg: component.imageSlider.pp.controls.fwdBack.showOnMouseOver {Show on mouse over}',
        value: ShowNavigator.MOUSEOVER
    }, {
        label: 'msg: component.imageSlider.pp.controls.fwdBack.dontShow {Don\'t show}',
        value: ShowNavigator.NEVER
    }],
    indicatorOptions = [{
        label: 'msg: component.imageSlider.pp.controls.indicator.showAlways {Show always}',
        value: ShowIndicator.ALWAYS
    }, {
        label: 'msg: component.imageSlider.pp.controls.indicator.showOnMouseOver {Show on mouse over}',
        value: ShowIndicator.MOUSEOVER
    }, {
        label: 'msg: component.imageSlider.pp.controls.indicator.dontShow {Don\'t show}',
        value: ShowIndicator.NEVER
    }],
    transitionOptions = [{
        label: 'msg: component.imageSlider.pp.transition.transition.slideIn {Slide-in}',
        value: Transition.SLIDE
    }, {
        label: 'msg: component.imageSlider.pp.transition.transition.fadeIn {Fade-in}',
        value: Transition.FADE
    }],
    showNextImgOptions = [{
        label: 'msg: component.imageSlider.pp.transition.showNextImg.auto {Auto}',
        value: ShowNextImage.AUTO
    }, {
        label: 'msg: component.imageSlider.pp.transition.showNextImg.manual {Manual}',
        value: ShowNextImage.MANUAL
    }],
    captionPlacementOptions = [{
        label: 'msg: components.imageSlider.pp.captions.top {Top}',
        value: CaptionsPlacement.TOP
    },
    /*, {
        label: 'msg: components.imageSlider.pp.captions.middle {Middle}',
        value: CaptionsPlacement.MIDDLE
    }*/{
        label: 'msg: components.imageSlider.pp.captions.bottom {Bottom}',
        value: CaptionsPlacement.BOTTOM
    }];
