import { makeCombineReducer } from "../../../redux/makeReducer/index";
import StylesheetsEpic from '../../Workspace/epics/stylesheets/index';
import StylesheetsIdToNameMapEpic from '../../Workspace/epics/stylesheets/idToNameMap';

export default makeCombineReducer({
    combineReducers: {
        stylesheets: StylesheetsEpic.reducer,
        stylesheetsIdToNameMap: StylesheetsIdToNameMapEpic.reducer
    }
});

