import { when } from "../../../../../epics/makeCondition";
import { sendGTMEvent } from "../../../../App/actionCreators/googleTagManager";
import { REMEMBER_GOAL_ACHIEVED } from "../../../../App/epics/analyticsDependencies/actions";
import { WSB_GTM_EVENT } from "../../../events";
import { events } from "./events";

const whenGoalEvent = (goal: string) => when(
    REMEMBER_GOAL_ACHIEVED,
    ({ payload }) => payload.goalId === goal
);

export const GoalsGTMUpdaters = events.map(eventTracker => {
    return {
        conditions: [whenGoalEvent(eventTracker.LABEL)],
        reducer: ({ state, values: [payload] }) => ({
            state,
            actionToDispatch: sendGTMEvent({
                event: WSB_GTM_EVENT,
                eventCategory: eventTracker.CATEGORY,
                eventAction: eventTracker.ACTION,
                eventLabel: eventTracker.LABEL,
                eventValue: payload.eventValue
            })
        })
    };
});
