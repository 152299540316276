import React from "react";

import { connect } from "react-redux";
import { makeEpicStateSelector } from "../../../../epics/makeEpic";
import { Dialog, DialogBody } from "../../../../view/common/dialogs/baseDialog/index";
import getCenteredDialogConfig from "../../../DialogManager/getCenteredDialogConfig";
import {
    HIDE_WEBSHOP_IFRAME_DIALOGS,
    CLOSE_WEBSHOP_ONBOARDING_DIALOG,
    SHOW_WEBSHOP_UPGRADE_SUCCESS,
    SHOW_WEBSHOP_ADMIN_DIALOG,
    HIDE_WEBSHOP_UPGRADE
} from "./actionTypes";
import { StripTypes } from "../../../../view/common/dialogs/baseDialog/Dialog";
import styles from "./view/style.css";
import { getCenterPositionForDialog } from "../../../DialogManager/utility";
import type { Dimensions } from "../../../../redux/modules/children/workspace/flowTypes";
import { DialogTypes } from "../constants";
import Msg from "../../../../view/intl/Msg";
import PrimaryButton from "../../../../view/common/Button/PrimaryButton";
import subscriptionDataVat from "../../../App/epics/subscriptionData/valueActionType";
import { SubscriptionData } from "../../../../redux/modules/flowTypes";
import { getEcommercePackageNameFromMetadata } from "../../../ComponentTierManager/utils";

const getDialogDimensions = (dialogType: string, browserWidth: number, browserHeight: number): Dimensions => {
    let dimensions: Dimensions;
    switch (dialogType) {
        case DialogTypes.MANAGE_SHOP:
            dimensions = { width: browserWidth * 0.808, height: browserHeight * 0.839 };
            break;
        case DialogTypes.UPGRADE_SHOP:
            dimensions = { width: 700, height: 743 };
            break;
        case DialogTypes.UPGRADE_SHOP_SUCCESS:
            dimensions = { width: 700, height: 364 };
            break;
        default:
            dimensions = { width: 1160, height: 636 };
    }
    return dimensions;
};
const UpgradeSuccessful = ({ dispatch, subscriptionData }) => {
    const subscriptionMetadata = subscriptionData && subscriptionData.metadata;
    const eCommercePackageNameFromMetadata = getEcommercePackageNameFromMetadata(subscriptionMetadata);
    const gotToWebshop = () => {
        dispatch({ type: SHOW_WEBSHOP_ADMIN_DIALOG });
    };
    const packageNameBandE = <Msg k="common.business_ecommerce">Business + Ecommerce</Msg>;
    return (
        <div className={styles.upgradeSuccessful}>
            <h4 className={styles.successTitle}>
                <Msg k="webshopInserter.upgrade.successTitle">Upgrade successful!</Msg>
            </h4>
            <p className={styles.successDescription}>
                <Msg
                    k="webshopInserter.upgrade.successDescription"
                    params={{
                        packageName:
                            eCommercePackageNameFromMetadata || packageNameBandE
                    }}
                >{`
                    Your plan was upgraded to Website Builder {packageName}. You now have full access to the Online Shop and
                    additional features designed to support your online success. You will receive a confirmation email with more details
                    about your new plan.
                `}</Msg>
            </p>
            <div>
                <PrimaryButton className={styles.continueToShop} onClick={gotToWebshop}>
                    <Msg k="webshopInserter.upgrade.continueToOnlineShop">Continue To Online Shop</Msg>
                </PrimaryButton>
            </div>
        </div>
    );
};

type Props = {
    dispatch: Dispatch,
    webshopAdminUrl: string,
    dialogType: string,
    isDragging: boolean,
    subscriptionData: SubscriptionData,
}

const WebshopAdminDialog = ({
    dispatch,
    webshopAdminUrl,
    dialogType,
    isDragging,
    subscriptionData
}: Props) => {
    const onClose = () => dispatch({ type: HIDE_WEBSHOP_IFRAME_DIALOGS });

    const closeSetupProductDialog = (data: any) => {
        if (data) {
            const productId = data.productId ? data.productId : "";
            const isWebshopSetupDone = data.isWebshopSetupDone;
            dispatch({
                type: CLOSE_WEBSHOP_ONBOARDING_DIALOG,
                payload: {
                    productId,
                    isWebshopSetupDone
                }
            });
        }
    };

    const handleDialogMessages = (event: any) => {
        if (event && event.data) {
            const { type, data } = event.data;
            switch (type) {
                case "CLOSE_SETUP_PRODUCT_DIALOG":
                    closeSetupProductDialog(data);
                    break;
                case SHOW_WEBSHOP_UPGRADE_SUCCESS:
                    dispatch({ type: SHOW_WEBSHOP_UPGRADE_SUCCESS });
                    break;
                case "CLOSE_UPGRADE_DIALOG":
                    dispatch({ type: HIDE_WEBSHOP_UPGRADE });
                    break;
                case "WEBSHOP_OPEN_WSB_EDITOR":
                    dispatch({ type: HIDE_WEBSHOP_IFRAME_DIALOGS });
                    break;
                default: //nothing here
            }
        }
    };

    React.useEffect(() => {
        window.addEventListener("message", handleDialogMessages, false);
        return () => {
            window.removeEventListener("message", handleDialogMessages, false);
        };
    }, [webshopAdminUrl]);

    return (
        <React.Fragment>
            {dialogType === DialogTypes.UPGRADE_SHOP ? (
                <Dialog showCloseBtn={false} stripType={StripTypes.NONE} className={`${styles.upgradeFullScreen}`}>
                    <DialogBody className={styles.bodyContainer}>
                        <iframe src={webshopAdminUrl} className={styles.webshopAdminIframe} frameBorder="0" />
                    </DialogBody>
                </Dialog>
            ) : (
                <Dialog
                    onClose={onClose}
                    stripType={StripTypes.NONE}
                    className={`${styles.webshopAdminDialog} ${isDragging ? styles.webshopAdminDialogWhenDragging : ""}`}
                    closeBtnClassName={styles.webshopAdminDialogCloseButton}
                >
                    <DialogBody className={styles.bodyContainer}>
                        {dialogType === DialogTypes.UPGRADE_SHOP_SUCCESS ? (
                            <UpgradeSuccessful dispatch={dispatch} subscriptionData={subscriptionData} />
                        ) : (
                            <iframe src={webshopAdminUrl} className={styles.webshopAdminIframe} frameBorder="0" />
                        )}
                    </DialogBody>
                </Dialog>
            )}
        </React.Fragment>
    );
};
const
    subscriptionDataSelector = makeEpicStateSelector(subscriptionDataVat),
    mapStateToProps = appState => {
        const subscriptionData = subscriptionDataSelector(appState);
        return { subscriptionData };
    };

const centeredDialogConfig = getCenteredDialogConfig({
    component: connect(mapStateToProps)(WebshopAdminDialog),
    draggableClassName: styles.dragHandle,
    dialogClassName: styles.dialogContainer
});

const config = {
    ...centeredDialogConfig,
    confFactory: ({ browserWidth, browserHeight }, payload: Record<string, any>) => {
        const { width, height } = getDialogDimensions(payload.dialogType, browserWidth, browserHeight);
        return {
            position: getCenterPositionForDialog(width, height, browserWidth, browserHeight),
            modal: true,
            dimensions: { width, height }
        };
    },
    updateOnBrowserDimensionsChanged: (dialogConfig, browserDimensions) => {
        const dialogType = dialogConfig.props?.dialogType || DialogTypes.MANAGE_SHOP;
        const dimensions = getDialogDimensions(dialogType, browserDimensions.width, browserDimensions.height);
        return {
            ...dialogConfig,
            dimensions
        };
    }
};

export default config;
