import type { LinkChooserLinkType } from "../../../../view/common/dialogs/LinkChooserDialog/flowTypes";
import { DataSite } from "../../../../../dal/model";
import { LcTabName } from "../../../../view/common/dialogs/LinkChooserDialog/constants";
import urlBuild from "../../../../../utils/urlBuild";
import { getDAL } from "../../../../../dal";
import Resource from "../../../../redux/modules/children/fileChooser/Resource";
import { autofixUrlScheme } from "../../../../utils/autofixUrlScheme";

type Result = {
    url: string;
    linkId: string | null | undefined; // pageRef.id
};

export default (link: LinkChooserLinkType, site: DataSite): Result => {
    const { type, value } = link;
    let result = {
        url: '',
        // It should be empty string in place of null to fix below ts-ignore
        linkId: null
    };

    if (type === LcTabName.PAGE) {
        const pageRef = site.getOptionalPageRefById(value);
        if (pageRef) {
            let path;
            if (site.isHomePageId(pageRef.pageId)) {
                path = '';
            } else {
                path = site.getPageRefUrlPath(value).join('/');
                if (!pageRef.items.length) path += '.html';
            }

            result = {
                url: urlBuild(path, { scheme: 'http', host: getDAL().getDomain() }),
                // @ts-ignore
                linkId: pageRef.id
            };
        }
    } else if (type === LcTabName.FILE) {
        const file: Resource = value;
        result.url = file.getFullUrl();
    } else if (type === LcTabName.URL) {
        result.url = autofixUrlScheme(value).url;
    } else {
        throw new Error(`Unsupported type '${type}' to make link page url`);
    }

    return result;
};
