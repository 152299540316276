import React from "react";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";
import { connect } from "react-redux";

import { AnalyticsGetStartedIllustration } from "./AnalyticsGetStartedIllustration";
import { AnalyticsGetStartedAction } from "./AnalyticsGetStartedAction";
import { AnalyticsDashboardHeader } from "../AnalyticsDashboard/AnalyticsDashboardHeader";
import { selectCurrentLanguage } from "../../../../common/selectors";
import { AppState } from "../../../../common/types";

type AnalyticsGetStartedProps = {
    currentLanguage: string;
    dispatch: Dispatch;
};

const AnalyticsGetStartedComponent = ({ currentLanguage, dispatch }: AnalyticsGetStartedProps) => {
    return (
        <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }}
            alignItems="center"
            style={{ userSelect: 'none' }}
        >
            <Box>
                <AnalyticsDashboardHeader />

                {/* @ts-ignore */}
                <Hidden mdUp>
                    <Box marginTop="42px">
                        <AnalyticsGetStartedIllustration />
                    </Box>
                </Hidden>

                <Box marginTop="42px">
                    <AnalyticsGetStartedAction
                        language={currentLanguage}
                        dispatch={dispatch}
                    />
                </Box>
            </Box>

            <Hidden mdDown>
                <AnalyticsGetStartedIllustration />
            </Hidden>
        </Box>
    );
};

const mapStateToProps = (state: AppState) => ({
    currentLanguage: selectCurrentLanguage(state),
});

export const AnalyticsGetStarted = connect(mapStateToProps)(AnalyticsGetStartedComponent);
