import React from 'react';

import styles from '../NewPageNameDialog.css';
import getCenteredDialogConfig from "../../../DialogManager/getCenteredDialogConfig";
import StandardDialog from '../../../../view/common/dialogs/StandardDialog';
import { newPageLayoutNameEnteredAction } from "../actionCreators";
import { Msg } from "../../../../view/intl";
import { makeInputReducer } from "../../../inputControls/input/reducerFactory";
import { NEW_SECTION_LINK_NAME_ON_CHANGE } from "./actionTypes";
import type { InputState } from "../../../inputControls/input/flowTypes";
import { Input } from "../../../inputControls/input/view";
import { closeNewPageNameDialogAction } from "../actions";
import { closeDialog } from "../../../App/actionCreators";
import { StripTypes } from '../../../../view/common/dialogs/baseDialog';

type Props = {
    state: InputState,
    dispatch: Dispatch;
};

const NewSectionLinkNameDialog = ({ state, dispatch }: Props) => {
    const
        disabled = !state.isValid,
        mctaHandler = () => {
            if (disabled) return;
            dispatch(newPageLayoutNameEnteredAction(state.value));
        },
        sctaHandler = () => {
            dispatch(closeNewPageNameDialogAction());
            dispatch(closeDialog());
        };

    return (
        <StandardDialog
            title="msg: common.newSectionLink {New section link}"
            subTitle="msg: common.newSectionLink.subtitle {The new section link will be added to your site menu and lead to the same section as the one you duplicated it from.}" // eslint-disable-line max-len
            mctaText="msg: common.ok {OK}"
            mctaHandler={mctaHandler}
            sctaHandler={sctaHandler}
            onClose={sctaHandler}
            disabled={disabled}
            stripType={StripTypes.NONE}
            titleBoxClassName={styles.newPageHeading}
            bodyClassName={styles.bodyClassName}
            noTopBorderForFooter
        >
            <div className={styles.labelContainer}>
                <Msg k="pt.newSectionLinkTitle">Menu title of new section link:</Msg>
            </div>
            <Input
                state={state}
                onChangeAction={NEW_SECTION_LINK_NAME_ON_CHANGE}
                dispatch={dispatch}
                autoFocus
                onEnterPress={mctaHandler}
            />
        </StandardDialog>
    );
};

export default getCenteredDialogConfig({
    width: 483,
    height: 327,
    component: NewSectionLinkNameDialog,
    reducer: makeInputReducer({
        actionType: NEW_SECTION_LINK_NAME_ON_CHANGE,
    })
});
