import * as R from "ramda";
import { id } from "../../../mappers/path";
import * as selectors from "../../Workspace/epics/stylesheets/selectors";
import type { TableComponent, TableComponentDependsOn } from "./flowTypes";

export default (component: TableComponent, { stylesheets }: TableComponentDependsOn): TableComponent => {
    const getNewGlobalstyleId = (type, globalName) => {
        const globalId = R.pipe(
            selectors.getAllStylesheets,
            selectors.getStylesByType(type),
            R.find(style => style.name === globalName),
            R.prop(id)
        )(stylesheets);

        return globalId || selectors.tableNormalGlobalstyleId(stylesheets);
    };

    return R.evolve({
        cells: R.map(
            R.evolve({
                style: (style) => ({ ...style, globalId: getNewGlobalstyleId(style.type, style.globalName) })
            })
        )
    }, component);
};
