import React from 'react';

import { GoogleAdsSettingsTextWithSwitchView } from './GoogleAdsSettingsTextWithSwitchView';
import { BgColorSet, BodyTextSet, GoToDashboardActionButton, HeaderTextSet, IconClassesSet } from './constants';

type GoogleAdsSettingsAdLiveProps = {
    isCampaignEnabled: boolean;
    handleCampaignStatusChange: (enabled: boolean) => void;
};

export const GoogleAdsSettingsAdLive = (props: GoogleAdsSettingsAdLiveProps) => (
    <GoogleAdsSettingsTextWithSwitchView
        bgColor={BgColorSet.Active}
        header={{ iconClass: IconClassesSet.adLive, text: HeaderTextSet.adLive }}
        text={BodyTextSet.adLive}
        isCampaignEnabled={props.isCampaignEnabled}
        handleCampaignStatusChange={props.handleCampaignStatusChange}
        actionButton={GoToDashboardActionButton}
    />
);
