import { DEFAULT_WIDTH, DEFAULT_HEIGHT, eventbriteLink } from '../constants';
import { isEventbriteLinkValid } from './linkValidator';
import { parseEventbritePostCode } from "./parseEventbritePostCode";
import { type EventbriteComponent } from '../types';

export const parseEventbriteCode = (code: string): EventbriteComponent => {
    let params = <EventbriteComponent>{};
    if (code.length) {
        if (isEventbriteLinkValid(code)) {
            params.link = `${eventbriteLink}${code.split('/')[4]}`;
        } else {
            params = parseEventbritePostCode(code);
        }
    }
    return params.link ? { width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT, ...params, } : {};
};
