import { makeValueReducer } from '../../../../../makeReducer/index';
import { TOGGLE_EDIT_MODE_PANEL_MINIMIZATION } from './actionTypes';
import toggleEditModePanelMinimization from './reducers/toggleEditModePanelMinimization';

export default makeValueReducer({
    defaultState: false,
    handleActions: {
        [TOGGLE_EDIT_MODE_PANEL_MINIMIZATION]: toggleEditModePanelMinimization
    }
});
