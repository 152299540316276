import React, { useRef, useEffect } from "react";
import Lottie from "react-web-lottie";
import animationData from "./animation.json";
import { getStepMarkers, updateTextData, ONBOARDING_STEPS, ELEMENT_INDEXES, LOTTIE_DEFAULT_OPTIONS } from "./constant";

type ContactStepRightSideViewProps = {
    email: string;
    phone: string;
    address: string;
    isForwardDirection: boolean;
};
const markers = animationData.markers;
const stepMarkers = getStepMarkers(markers, ONBOARDING_STEPS.CONTACT);
let initialSegment;
export default function ContactStepRightSideView({ email, phone, address, isForwardDirection }: ContactStepRightSideViewProps) {
    const animationRef = useRef(null);

    // Moving backward
    if (!animationRef.current) {
        if (!isForwardDirection) {
            initialSegment = [stepMarkers.contact_stop_point, stepMarkers.contact_stop_point + 1];
        } else {
            initialSegment = [stepMarkers.contact_start_of_step, stepMarkers.contact_start_of_step + 1];
        }
    }

    useEffect(() => {
        // @ts-ignore
        animationRef.current.playSegments([[stepMarkers.contact_stop_point, stepMarkers.contact_stop_point + 1]], true);
        updateTextData({
            animationRef,
            elementIndex: ELEMENT_INDEXES.email,
            textData: {
                t: email
            }
        });
        updateTextData({
            animationRef,
            elementIndex: ELEMENT_INDEXES.phone,
            textData: {
                t: phone
            }
        });
        updateTextData({
            animationRef,
            elementIndex: ELEMENT_INDEXES.address,
            textData: {
                t: address
            }
        });
    }, [email, phone, address]);
    useEffect(() => {
        if (isForwardDirection) {
            // @ts-ignore
            animationRef.current.playSegments([[stepMarkers.contact_start_of_step, stepMarkers.contact_stop_point]], true);
        }
    }, []);
    const defaultOptions = {
        ...LOTTIE_DEFAULT_OPTIONS,
        rendererSettings: {
            preserveAspectRatio: "xMaxYMin meet"
        },
        initialSegment,
        animationData
    };
    return <Lottie lottiRef={animationRef} options={defaultOptions} isClickToPauseDisabled />;
}
