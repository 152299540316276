import React from "react";
import { useDispatch } from "react-redux";
import { Button, DialogContentText, DialogContent, DialogActions } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import { useIntl } from "../../../../../../wbtgen/src/view/intl";

import { closeDialogAction } from "../../Dialog/actions";
import { BasicDialog } from "../../Dialog/BasicDialog";
import VerticalSpacer from "../../../../../../wbtgen/src/view/common/VerticalSpacer";

const
    title = "msg: privacyPolicy.disclaimer.title {Disclaimer for generated terms}",
    descriptionPart1 = "msg: privacyPolicy.disclaimer.description1 {The materials below are for informational purposes only and do not constitute advertising, a solicitation or legal advice. You should also consult independent legal advice before publishing these agreements. You should read the generated information with care and modify, delete or add all and any areas as necessary.}",
    descriptionPart2 = "msg: privacyPolicy.disclaimer.description2 {Use of, access to or transmission of such materials and information or any of the links contained herein is not intended to create, and receipt thereof does not constitute formation of, an attorney-client relationship between One.com and the user or browser.}",
    descriptionPart3 = "msg: privacyPolicy.disclaimer.description3 {You should not rely upon this information for any purpose without seeking legal advice from a licensed attorney in your state or province. The information contained is provided only as general information and may or may not reflect the most current legal developments; accordingly, information is not promised or guaranteed to be correct or complete.}",
    descriptionPart4 = "msg: privacyPolicy.disclaimer.description4 {One.com expressly disclaims all liability in respect to any actions taken or not taken based on any or all of the contents of this website. Further, One.com does not necessarily endorse and is not responsible for any third-party content that may be accessed through this information.}";

const useStyles = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.down('sm')]: {
            position: "absolute",
            bottom: 0,
            maxWidth: "100%",
            margin: 0,
        },
    },
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        margin: "0px",
        textAlign: "left",
        alignItems: "flex-start"
    },
    title: {
        fontSize: "24px",
        lineHeight: "36px",
        margin: "16px 0px 0px",
    },
    description: {
        fontSize: "14px",
        lineHeight: "22px",
        marginBottom: "0px",
        margin: "16px 0px 0px",
    },
    dialogActions: {
        display: "flex",
        justifyContent: "center",
        marginTop: "32px",
        [theme.breakpoints.up('sm')]: {
            justifyContent: "flex-start",
        },
    },
    closeButtonStyleClass: {
        padding: "20px",
        "& svg": {
            width: "24px",
            height: "24px"
        },
        [theme.breakpoints.up('sm')]: {
            padding: "18px",
            "& svg": {
                width: "22px",
                height: "22px"
            },
        },
    },
    actionBtn: {
        fontSize: "14px",
        lineHeight: "40px",
        fontWeight: 600,
        padding: "0px 30px",
        [theme.breakpoints.down('sm')]: {
            width: "50%",
            padding: "0px"
        },
    },
}), { name: "disclaimerDialog" });

type disclaimerDialogProps = {
    config: any;
};

export const DisclaimerDialog = ({ config }: disclaimerDialogProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const classes = useStyles();

    return (
        <BasicDialog
            onClose={() => dispatch(closeDialogAction())}
            classes={{ paper: classes.paper }}
            closeButtonStyleClass={classes.closeButtonStyleClass}
            {...config}
        >
            <DialogContent className={classes.dialogContent}>
                <DialogContentText className={classes.title}>
                    {intl.msgJoint(title)}
                </DialogContentText>

                <DialogContentText className={classes.description} component="div">
                    {intl.msgJoint(descriptionPart1)}
                    <VerticalSpacer y={16} />
                    {intl.msgJoint(descriptionPart2)}
                    <VerticalSpacer y={16} />
                    {intl.msgJoint(descriptionPart3)}
                    <VerticalSpacer y={16} />
                    {intl.msgJoint(descriptionPart4)}
                </DialogContentText>
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.actionBtn}
                    onClick={() => dispatch(closeDialogAction())}
                >
                    {intl.msgJoint("msg: common.OK {OK}")}
                </Button>
            </DialogActions>
        </BasicDialog>
    );
};

export const DISCLAIMER_DIALOG_ID = 'DISCLAIMER_DIALOG_ID';
