import * as actionTypes from "./actionTypes";
import CALL_API from '../../../../redux/middleware/api/CALL_API';
import type { ApiAction } from '../../../../redux/middleware/api/flowTypes';
import type { GmbCreateDataType } from "../flowTypes";

const
    createConnectedBusinessData = (params: GmbCreateDataType): ApiAction => ({
        [CALL_API]: {
            types: [
                actionTypes.GOOGLE_MY_BUSINESS_SAVE_TO_GMB_REQUEST,
                actionTypes.GOOGLE_MY_BUSINESS_SAVE_TO_GMB_SUCCESS,
                actionTypes.GOOGLE_MY_BUSINESS_SAVE_TO_GMB_FAILURE
            ],
            endpoint: 'createConnectedBusinessData',
            endpointParams: params
        }
    }),
    createConnectedBusinessDataValidate = (params: GmbCreateDataType): ApiAction => ({
        [CALL_API]: {
            types: [
                actionTypes.GOOGLE_MY_BUSINESS_VALIDATE_TO_GMB_REQUEST,
                actionTypes.GOOGLE_MY_BUSINESS_VALIDATE_TO_GMB_SUCCESS,
                actionTypes.GOOGLE_MY_BUSINESS_VALIDATE_TO_GMB_FAILURE
            ],
            endpoint: 'createConnectedBusinessData',
            endpointParams: params
        }
    });

export {
    createConnectedBusinessData,
    createConnectedBusinessDataValidate,
};
