import Text from "../oneweb/Text/kind";
import Image from "../oneweb/Image/kind";
import { backgroundKindsMap, cmpCopy } from "./constants";
import type { Sequence, WrappedCmpsMap } from "./flowTypes";
import type { ComponentsMap } from "../../redux/modules/children/workspace/flowTypes";

export const hasChildren = (componentsMap: ComponentsMap, id?: string) =>
    Object.keys(componentsMap).some(cId => componentsMap[cId].relIn && componentsMap[cId].relIn.id === id);

const canCmpBeDeleted = (cmpId, data, templateId, componentsMap) => {
    if (cmpId === templateId) {
        return false;
    }
    let canBeDeleted = true;
    const checkParent = (parentId) => {
        if (data[parentId] && data[parentId].length) {
            data[parentId].forEach(childId => {
                if (!backgroundKindsMap[componentsMap[childId].kind]) {
                    canBeDeleted = false;
                    return;
                }
                checkParent(childId);
            });
        }
    };

    checkParent(cmpId);
    return canBeDeleted;
};

const getNewParentId = (oldParentId, data, templateId, componentsMap, childToParentIdsMap): string => {
    if (oldParentId === templateId) {
        return oldParentId;
    }
    let parentId = oldParentId, newParentId = oldParentId;

    if (componentsMap[parentId].kind === Image) {
        while (parentId) {
            let nextParentId = childToParentIdsMap[parentId];
            if (!nextParentId) {
                newParentId = templateId;
                break;
            }
            if (
                nextParentId === templateId ||
                    backgroundKindsMap.hasOwnProperty(componentsMap[nextParentId].kind)
            ) {
                newParentId = nextParentId;
                break;
            }
            parentId = nextParentId;
        }
    }
    return newParentId;
};

export default (
    data: Sequence,
    templateId: string,
    componentsMap: ComponentsMap,
    wrappedCmpsMap: WrappedCmpsMap
) => {
    let
        newData = { ...data },
        newWrappedCmpsMap = { ...wrappedCmpsMap },
        mdCmps: any[] = [],
        newParentId: any = null,
        alreadyAddedIdsMap = {},
        childToParentIdsMap = {};
    Object.keys(data).forEach(pId => {
        (data[pId] || []).forEach(cId => {
            childToParentIdsMap[cId] = pId;
        });
    });

    const processWrappedCmps = (textId, parentId) => {
        // if it is image and it is wrapped then mobile down is not applicable
        newWrappedCmpsMap[textId] = (wrappedCmpsMap[textId] || []).slice();
        (wrappedCmpsMap[textId] || []).forEach(wId => {
            const { id, kind, inTemplate, mobileDown } = componentsMap[wId];
            if (kind !== Image && inTemplate && mobileDown) {
                mdCmps.push({ id, parentId });
                newWrappedCmpsMap[textId].splice(newWrappedCmpsMap[textId].findIndex(cId => cId === id), 1);
            }
            if (id) {
                processParent(id, null, true); // eslint-disable-line
            }
        });
    };
    const processParent = (parentId: string, gParentId, wrapped?) => {
        newData[parentId] = (data[parentId] || []).slice();
        (data[parentId] || []).forEach((childId: string) => {
            newParentId = getNewParentId(parentId, data, templateId, componentsMap, childToParentIdsMap);
            const cmp = componentsMap[childId];
            // if it is image and any of its parents is wrapped, then mobile down is not applicable
            // if it is image and if it has children then mobile down is not applicable
            if (
                cmp.inTemplate &&
                cmp.mobileDown &&
                !backgroundKindsMap.hasOwnProperty(cmp.kind) &&
                ((cmp.kind !== Image) || (!wrapped && (!hasChildren(componentsMap, cmp.id))))
            ) {
                const index = newData[parentId].findIndex(cId => cId === childId);
                newData[parentId].splice(index, 1);
                if (canCmpBeDeleted(parentId, newData, templateId, componentsMap)) {
                    if (
                        gParentId &&
                        newData[gParentId] &&
                        componentsMap[parentId] &&
                        backgroundKindsMap.hasOwnProperty(componentsMap[parentId].kind)
                    ) {
                        newData[gParentId] = newData[gParentId].filter(p => p !== parentId);
                    }
                    delete newData[parentId];
                }
                mdCmps.push({ id: childId, parentId: newParentId });
            }
            if (data[childId]) {
                processParent(childId, parentId, wrapped);
            }
            if (componentsMap[childId].kind === Text) {
                processWrappedCmps(childId, parentId);
            }
        });
    };
    processParent(templateId, null);
    let counter = 0,
        lastIdAddedToRoot = null,
        mdStartFromId = null;
    const updateMdStartFrom = (id) => {
        if (!mdStartFromId) {
            mdStartFromId = id;
        }
    };
    mdCmps.forEach(mdCmp => {
        if (mdCmp.parentId) {
            if (mdCmp.parentId === templateId) {
                newData[templateId].push(mdCmp.id);
                updateMdStartFrom(mdCmp.id);
                lastIdAddedToRoot = mdCmp.id;
            } else {
                if (lastIdAddedToRoot !== mdCmp.parentId) {
                    counter++;
                }
                const newId = mdCmp.parentId + cmpCopy + '_' + counter;
                if (!newData[newId]) {
                    newData[newId] = [];
                }
                newData[newId].push(mdCmp.id);
                updateMdStartFrom(mdCmp.id);
                if (!alreadyAddedIdsMap[newId]) {
                    newData[templateId].push(newId);
                    updateMdStartFrom(newId);
                    lastIdAddedToRoot = mdCmp.parentId;
                    alreadyAddedIdsMap[newId] = true;
                }
            }
        }
    });
    return {
        data: newData,
        wrappedCmpsMap: newWrappedCmpsMap,
        mdStartFromId
    };
};
