import React from 'react';
import cx from 'classnames';
import ModernLayoutItem from './ModernLayoutItem';
import { getHeaderLayoutsMap } from '../../../layoutsData/headerLayouts';
import { getFooterLayoutsMap } from '../../../layoutsData/footerLayouts';
import * as styles from '../ModernLayoutLeftPanel.css';
import { Intl } from '../../../../../view/intl/index';
import type { Stylesheets } from "../../../../Workspace/epics/stylesheets/flowTypes";
import type { SectionComponent } from "../../../../oneweb/Section/flowTypes";
import type { LeftPanelEpicState } from "../../../flowTypes";
import type { TemplateComponent } from "../../../../oneweb/Template/flowTypes";
import { ACTIVATE_MODERN_HEADER, ACTIVATE_MODERN_FOOTER,
    SWITCH_MODERN_FOOTER, SWITCH_MODERN_HEADER } from "../../../actionTypes";
import type { WebShopMHFEpicState } from "../../../../oneweb/WebShopMHF/flowTypes";

type Props = {
    intl: Intl,
    dispatch: Dispatch,
    leftPanel: LeftPanelEpicState,
    template: TemplateComponent,
    componentsEval: Record<string, any>,
    globalVariables: Record<string, any>,
    styleSheets: Stylesheets,
    section: SectionComponent,
    activeLayoutId: string,
    isModernLayoutActivated: boolean,
    webshopMHFData: WebShopMHFEpicState,
};

const ModernLayoutsList = ({
    intl,
    leftPanel,
    template,
    componentsEval,
    globalVariables,
    styleSheets,
    dispatch,
    section,
    activeLayoutId,
    isModernLayoutActivated,
    webshopMHFData,
}: Props) => {
    const layoutsMap = leftPanel.isFooter ? getFooterLayoutsMap(webshopMHFData) : getHeaderLayoutsMap(),
        activateAction = leftPanel.isFooter ? ACTIVATE_MODERN_FOOTER : ACTIVATE_MODERN_HEADER,
        switchAction = leftPanel.isFooter ? SWITCH_MODERN_FOOTER : SWITCH_MODERN_HEADER;
    return <React.Fragment>
        <h6 className={styles.layoutThemesTitle}>{intl.msgJoint('msg: modernLayout.leftPanel.layoutsThemetitle {Layout themes}')}</h6>
        {Object.keys(layoutsMap).map((layoutId) => {
            const layoutItem = layoutsMap[layoutId];
            return (
                <React.Fragment key={layoutItem.id}>
                    <div className={styles.layoutTitle}>
                        {intl.msgJoint(layoutItem.title) + ':'}
                    </div>
                    <div
                        className={cx(styles.layoutItemWrapper, { [styles.inactive]: !isModernLayoutActivated })}
                        onClick={() => {
                            if (isModernLayoutActivated && activeLayoutId !== layoutId) {
                                if (activeLayoutId) {
                                    dispatch({ type: switchAction, payload: layoutId });
                                } else {
                                    dispatch({ type: activateAction, payload: layoutId });
                                }
                            }
                        }}
                    >
                        <ModernLayoutItem
                            isActivated={isModernLayoutActivated && activeLayoutId === layoutId}
                            layoutItem={layoutItem}
                            leftPanel={leftPanel}
                            template={template}
                            componentsEval={componentsEval}
                            globalVariables={globalVariables}
                            styleSheets={styleSheets}
                            dispatch={dispatch}
                            section={{ ...section, modernLayout: undefined }}
                            MHFStoredLayoutsData={{}}
                            webshopMHFData={webshopMHFData}
                        />
                    </div>
                </React.Fragment>);
        })}
    </React.Fragment>;
};

export default ModernLayoutsList;
