import * as R from "ramda";

// todo should be like this WBTGEN-2026
// export default function (...paths: Array<Path>): Array<StringOrNumber> {
export default function (...paths: Array<any>): Array<StringOrNumber> {
    const
        typeIsString = R.pipe(R.type, R.equals(String)),
        arraysOfPath = R.map(R.when(typeIsString, path => [path]))(paths);

    return R.pipe(R.flatten, R.filter(path => R.not(R.isNil(path))))(arraysOfPath);
}
