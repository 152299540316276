import {
    getPaidShutterstockImagePriceApiAction
} from '../../../redux/middleware/api/commonApiActions';

import {
    shutterstockApiAciton
} from "../shutterstock/actions";

import {
    ShutterstockKind
} from "../shutterstock/constants";

export type PaidShutterstockBuyActionParams = {
    isMultiSelect?: boolean,
    onSaveAction?: string,
    id?: string
};

export const
    BUY_PAID_SHUTTERSTOCK_IMAGE_ACTION = 'BUY_PAID_SHUTTERSTOCK_IMAGE_ACTION',
    buyPaidShutterstockImageAction = ({ isMultiSelect, id, onSaveAction }: PaidShutterstockBuyActionParams) => ({
        type: BUY_PAID_SHUTTERSTOCK_IMAGE_ACTION,
        payload: {
            isMultiSelect,
            id,
            onSaveAction
        }
    }),
    BUY_PAID_SHUTTERSTOCK_IMAGE_ACTION_COMPLETED = 'BUY_PAID_SHUTTERSTOCK_IMAGE_ACTION_COMPLETED',
    buyPaidShutterstockImageActionCompleted = () => ({
        type: BUY_PAID_SHUTTERSTOCK_IMAGE_ACTION_COMPLETED,
    }),
    PAID_SHUTTERSTOCK_IMAGE_PRICE_REQUEST_ACTION = 'PAID_SHUTTERSTOCK_IMAGE_PRICE_REQUEST_ACTION',
    PAID_SHUTTERSTOCK_IMAGE_PRICE_SUCCESS_ACTION = 'PAID_SHUTTERSTOCK_IMAGE_PRICE_SUCCESS_ACTION',
    PAID_SHUTTERSTOCK_IMAGE_PRICE_FAILURE_ACTION = 'PAID_SHUTTERSTOCK_IMAGE_PRICE_FAILURE_ACTION',
    getPaidShutterstockImagePriceAction = () => shutterstockApiAciton(
        ShutterstockKind.PAID,
        getPaidShutterstockImagePriceApiAction({
            types: [
                PAID_SHUTTERSTOCK_IMAGE_PRICE_REQUEST_ACTION,
                PAID_SHUTTERSTOCK_IMAGE_PRICE_SUCCESS_ACTION,
                PAID_SHUTTERSTOCK_IMAGE_PRICE_FAILURE_ACTION
            ]
        })
    );
