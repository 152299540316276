import { awsAuthAction } from "../../../../src/redux/middleware/aws/actions";

export type UserForgotPasswordRequest = {
    email: string
}
export const
    DEMO_FORGOT_PASSWORD_EMAIL_REQUEST_ACTION = 'DEMO_FORGOT_PASSWORD_EMAIL_REQUEST_ACTION',
    DEMO_FORGOT_PASSWORD_EMAIL_SUCCESS_ACTION = 'DEMO_FORGOT_PASSWORD_EMAIL_SUCCESS_ACTION',
    DEMO_FORGOT_PASSWORD_EMAIL_FAILURE_ACTION = 'DEMO_FORGOT_PASSWORD_EMAIL_FAILURE_ACTION',
    demoForgotPasswordEmailAction = (username: string, clientMetaData?: Record<string, any>) => awsAuthAction({
        actionTypes: [
            DEMO_FORGOT_PASSWORD_EMAIL_REQUEST_ACTION,
            DEMO_FORGOT_PASSWORD_EMAIL_SUCCESS_ACTION,
            DEMO_FORGOT_PASSWORD_EMAIL_FAILURE_ACTION,
        ],
        serviceMethod: 'forgotPassword',
        serviceMethodParams: [username, clientMetaData],
    });

export const
    DEMO_UPDATE_FORGOT_PASSWORD_EMAIL_VALUE_ACTION = 'DEMO_UPDATE_FORGOT_PASSWORD_EMAIL_VALUE_ACTION',
    updateEmailValue = (emailValue: String) => ({
        type: DEMO_UPDATE_FORGOT_PASSWORD_EMAIL_VALUE_ACTION,
        payload: { emailValue },
        amendToSelf: true
    });

export const
    DEMO_FORGOT_PASSWORD_SHOW_INVALID_EMAIL_ERROR_ACTION = 'DEMO_FORGOT_PASSWORD_SHOW_INVALID_EMAIL_ERROR_ACTION',
    showInvalidEmailError = () => ({
        type: DEMO_FORGOT_PASSWORD_SHOW_INVALID_EMAIL_ERROR_ACTION
    });

export const DEMO_FORGOT_PASSWORD_CLEAN_ALL_ERRORS_ACTION = 'DEMO_FORGOT_PASSWORD_CLEAN_ALL_ERRORS_ACTION',
    cleanUpAllErrors = () => ({
        type: DEMO_FORGOT_PASSWORD_CLEAN_ALL_ERRORS_ACTION
    });
export const
    DEMO_CLEAN_UP_FORGOT_PASSWORD_SCREEN_ACTIONS = 'DEMO_CLEAN_UP_FORGOT_PASSWORD_SCREEN_ACTIONS',
    cleanUpForgotPasswordScreen = () => ({
        type: DEMO_CLEAN_UP_FORGOT_PASSWORD_SCREEN_ACTIONS
    });
export const
    DEMO_FORGOT_PASSWORD_LOADING_ACTION = 'DEMO_FORGOT_PASSWORD_LOADING_ACTION',
    forgotPasswordLoading = () => ({
        type: DEMO_FORGOT_PASSWORD_LOADING_ACTION
    });

export const
    DEMO_SHOW_EXPIRED_PASSWORD_ERROR = 'DEMO_SHOW_EXPIRED_PASSWORD_ERROR',
    showExpiredPasswordError = () => ({
        type: DEMO_SHOW_EXPIRED_PASSWORD_ERROR
    });

export const
    DEMO_SHOW_INVALID_VERIFICATION_CODE_PAGE_ACTION = 'DEMO_SHOW_INVALID_VERIFICATION_CODE_PAGE_ACTION',
    showInvalidVerificationCodePage = () => ({
        type: DEMO_SHOW_INVALID_VERIFICATION_CODE_PAGE_ACTION
    });

export const
    DEMO_RESEND_VERIFICATION_EMAIL_SENT_ACTION = 'DEMO_RESEND_VERIFICATION_EMAIL_SENT_ACTION',
    showResendVerificationEmailSent = () => ({
        type: DEMO_RESEND_VERIFICATION_EMAIL_SENT_ACTION
    });
