import React from 'react';
import cx from 'classnames';
import Msg from "../../../../view/intl/Msg";
import { Intl } from "../../../../view/intl/injectIntl";
import { EPSeparator } from "../../../Panel/view/EPSeparator";
import VerticalSpacer from "../../../../view/common/VerticalSpacer";
import * as InserterStyles from '../../../Panel/view/Inserter.css';
import HelpTip from "../../../Panel/view/HelpTip";
import { Flex } from "../../../../view/reflexbox/index";
import { DroppableCmpShortcut } from "../../../Panel/view/DroppableCmpShortcut";
import ProductWidgetKind from '../../ProductWidget/kind';
import { SingleProductComponentTip } from "../../../Tooltip/ids";
import {
    PRODUCT_WIDGET_SHOW_PRODUCT_WIDGET_INSERTER_TOOLTIP
} from "../../ProductWidget/actionTypes";
import { AfterSetupTooltips } from './AfterSetupTooltips';
import styles from './styles.css';
import { WEBSHOP_PRODUCT_WIDGET_HELP_TIP, WEBSHOP_WIDGET_DISABLED_TIP } from '../translations';

type Props = {
    dispatch: Function;
    intl: Intl;
    isWebshopSetupDone: boolean,
    hasOnlyDigitalShop: boolean,
    disabled: boolean
};

const SampleProductImage = () => {
    return (
        <Flex justify="flex-start" align="center" className={styles.productsContainer}>
            <div className={cx(styles.productImage, styles.singleProductImage, styles.productImage_3)} />
            <Flex column justify="space-between" className={styles.singleProductText}>
                <Flex column>
                    <Msg
                        k="webshopInserter.singleProduct.ProductTitle"
                        className={cx(styles.productTitle, styles.singleProductTitle)}
                    >I'm a product</Msg>
                    <Msg
                        k="webshopInserter.singleProduct.ProductPrice"
                        className={cx(styles.productPrice, styles.singleProductPrice)}
                    >EUR 35</Msg>
                </Flex>
                <Flex justify="center" align="center" className={styles.singleProductViewDetails}>
                    <Msg k="webshopInserter.singleProduct.ViewDetails">View details</Msg>
                </Flex>
            </Flex>
        </Flex>
    );
};

const calcToRightAndVCenter = ({ top, width, height, left }) => {
    // position relative to the help tip
    return { x: left + width - 10, y: (top + (height / 2)) - 4 };
};

export const SingleProduct = ({ dispatch, isWebshopSetupDone, hasOnlyDigitalShop, disabled }: Props) => {
    const droppableCmpDispatch = (!disabled) ? dispatch : () => {};
    const ref = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const clientRect = ref.current && ref.current.getBoundingClientRect();
        const { top, width, height, left } = clientRect || {};

        dispatch({
            type: PRODUCT_WIDGET_SHOW_PRODUCT_WIDGET_INSERTER_TOOLTIP,
            payload: {
                position: calcToRightAndVCenter({ top, width, height, left }),
                id: SingleProductComponentTip,
                elementDimensions: { top, width, height, left },
                customClass: styles.afterSetupTooltipContainer,
                msg: { text: '' },
                timeout: 0,
                showIcon: false,
                showTipText: false,
                textStyle: { display: 'none' },
                customHTML: <AfterSetupTooltips
                    dispatch={dispatch}
                    productWidgetTooltip
                />,
            }
        });
    }, [ref]);

    return (
        <React.Fragment>
            <VerticalSpacer y={20} />
            <div className={styles.singleProductContainer} ref={ref}>
                <Flex justify="space-between">
                    <Msg k="component.webshop.ProductWidget" className={InserterStyles.subTitle}>Product widget</Msg>
                    <Flex className={styles.helpTipContainer}>
                        <HelpTip
                            msg={(!disabled) ? WEBSHOP_PRODUCT_WIDGET_HELP_TIP : WEBSHOP_WIDGET_DISABLED_TIP}
                            helpTipClassName="inserterInfoIconTip"
                            dispatch={dispatch}
                        />
                    </Flex>
                </Flex>
                <DroppableCmpShortcut
                    kind={ProductWidgetKind}
                    payload={{
                        isSingleProduct: true,
                        isWebshopSetupDone,
                        hasOnlyDigitalShop
                    }}
                    // @ts-ignore
                    dispatch={droppableCmpDispatch}
                    isNoOverlay={disabled}
                >
                    <SampleProductImage />
                </DroppableCmpShortcut>
            </div>
            <VerticalSpacer y={25} />
            <EPSeparator />
        </React.Fragment>
    );
};
