import validation from 'one-validation';

import { email } from '@hapi/address';

export const
    isValidEmail = function (value: string = ''): boolean {
        const flagValid = email.isValid(value);
        return flagValid;
    },
    isValidUrl = (value: string): boolean => validation.httpUrl.test(value),
    isValidSchemelessUrl = (value: string): boolean => {
        const [, scheme] = value.match(/^(\w+):\/\//) || [];
        if (!scheme) {
            const schemeValue = `http://${value}`;
            return isValidUrl(schemeValue);
        }

        return false;
    };
