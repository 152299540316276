/* @flow */

const { timeParse } = require("./timeParse");

/*::
    import type { TimeParseInput } from './timeParse'
 */

/**
 * See timeParse() docs for delay parameter details.
 */
const setHandyInterval = (
    callback/*: Function | string */,
    delay/*:? TimeParseInput */,
    ...args/*: any[] */
// $FlowFixMe
)/*: TimeoutID */ => setInterval(callback, timeParse(delay), ...args);

module.exports = {
    setHandyInterval,
};
