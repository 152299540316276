import React from 'react';
import cx from "classnames";
import ShiftBarTemplate from "./template";
import { isShiftBarTop } from '../../../../utils/handle/index';
import styles from '../../../App.css';
import type { BBox } from '../../../../redux/modules/children/workspace/flowTypes';
import type { FactoryPropsHover, SpaceAdjusterHoverWrapperProps, SpaceAdjusterHoverProps } from './flowTypes';
import { SelectionShiftBarHandleHeightPx } from '../../../../utils/handle/shiftBar';
import { SelectionFrameBorderWidth } from '../../../../constants/app';

export function fullWidthBar(kind: string, workspaceBBox: BBox, handleBBox: BBox) {
    const style = {
        top: handleBBox.top
        + (SelectionShiftBarHandleHeightPx / 2) + (isShiftBarTop(kind) ? -SelectionFrameBorderWidth : 0),
        left: workspaceBBox.left,
        width: workspaceBBox.right - workspaceBBox.left - 1 // -1 because otherwise it creates horizontal workspace scroll
    };

    return <div className={cx(styles.guideline, styles['guideline-hover'])} style={style} />;
}

// State is required to render component with opacity:0 and than trigger it to opacity:1, so transition can start
// Otherwise if component will be rendered initially with opacity:0 it will lack of transition
type State = {
    isVisible: boolean
}

export class SpaceAdjusterHover extends React.Component<SpaceAdjusterHoverProps, State> {
    timeoutKey?: ReturnType<typeof setTimeout>;
    constructor(props: SpaceAdjusterHoverProps) {
        super(props);
        this.state = { isVisible: false };
    }

    syncState(isVisible: boolean) {
        if (isVisible !== this.state.isVisible) {
            this.setState({ isVisible });
        }
    }

    UNSAFE_componentWillReceiveProps(props: SpaceAdjusterHoverProps) {
        this.syncState(props.isVisible);
    }

    componentDidMount() {
        const { isVisible } = this.props;
        clearTimeout(this.timeoutKey);
        this.timeoutKey = setTimeout(() => this.syncState(isVisible));
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutKey);
    }

    render() {
        const
            { bBox, getLeft, kind, workspaceBBox } = this.props,
            { isVisible } = this.state,
            handleBbox = {
                top: bBox.top,
                left: bBox.left,
                width: bBox.right - bBox.left,
                height: SelectionShiftBarHandleHeightPx
            },
            top = bBox.top
                + (SelectionShiftBarHandleHeightPx / 2) + (isShiftBarTop(kind) ? -SelectionFrameBorderWidth : 0);

        return (
            <div
                className={styles.shiftBarContainerHover}
                style={{ opacity: isVisible ? 1 : 0 }}
            >
                <div className={styles.shiftBarHandle} style={handleBbox} />
                <div className={styles.shiftPointContainerHover}>
                    <div
                        className={styles.hoverShiftPointBar}
                        style={{
                            top,
                            left: bBox.left,
                            width: bBox.right - bBox.left
                        }}
                    />
                    {fullWidthBar(kind, workspaceBBox, bBox)}
                    <ShiftBarTemplate
                        top={bBox.top}
                        left={getLeft(bBox)}
                        kind={kind}
                    />
                </div>
            </div>
        );
    }
}

export default function ({ kind, getLeft }: FactoryPropsHover) {
    return ({ bBox, isVisible, workspaceBBox }: SpaceAdjusterHoverWrapperProps) => {
        return (
            <SpaceAdjusterHover
                kind={kind}
                getLeft={getLeft}
                bBox={bBox}
                isVisible={isVisible}
                workspaceBBox={workspaceBBox}
            />
        );
    };
}
