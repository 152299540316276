import React from 'react';
import cx from 'classnames';
import HorizontalSpacer from '../HorizontalSpacer';
import type { LinkChooserInterchangeType } from "../dialogs/LinkChooserDialog/flowTypes";
import openLinkChooserDialog from "../dialogs/LinkChooserDialog/actionCreators/openLinkChooserDialog";
import getShortLinkName from "../dialogs/LinkChooserDialog/utils/getShortLinkName";
import isBrokenLink from "../dialogs/LinkChooserDialog/utils/isBrokenLink";
import LongTextTip from '../LongTextTip/index';
import { DataSite } from "../../../../dal/model/index";
import injectIntl, { Intl } from "../../intl/injectIntl";
import styles from './Button.css';
import { getIntlLinkName } from "../dialogs/LinkChooserDialog/utils/getIntlLinkName";
import { loadSectionComponentsForPageId } from '../../../components/PagesTree/Tree/actionCreators/index';
import type { SectionComponents } from '../../../components/PagesTree/sectionComponents/epic/sectionComponentsEpic';
import LoadingIndicator from "../LoadingIndicator/index";
import { LcTabName } from "../dialogs/LinkChooserDialog/constants";

const
    MAX_LINK_NAME_WIDTH = 175,
    DefaultSetLinkText = "msg: common.clickToSetLink {Add link}";

type Props = {
    setLinkAction: string,
    site: DataSite,
    globalVariables?: Record<string, any>,
    intl: Intl,
    dispatch: Function,
    linkAction: LinkChooserInterchangeType | null,
    onClick?: Function,
    clearLinkAction?: string,
    enabled?: boolean,
    title?: string,
    maxLinkNameWidth?: number,
    setLinkText?: string,
    sectionComponents: SectionComponents
};

export default injectIntl(class LinkButton extends React.Component<Props> {
    componentDidMount() {
        const link = this.props.linkAction && this.props.linkAction.link;
        if (link && link.type === LcTabName.SECTION_LINK && link.value) {
            this.props.dispatch(loadSectionComponentsForPageId(link.value.pageId));
        }
    }

    componentDidUpdate(prevProps: Props) {
        const link = this.props.linkAction && this.props.linkAction.link;
        if (link && link.value && link.type === LcTabName.SECTION_LINK) {
            const prevLink = prevProps.linkAction && prevProps.linkAction.link;
            if (!prevLink || !prevLink.value || prevLink.type !== LcTabName.SECTION_LINK ||
                prevLink.value.pageId !== link.value.pageId) {
                this.props.dispatch(loadSectionComponentsForPageId(link.value.pageId));
            }
        }
    }

    render() {
        const
            {
                linkAction,
                dispatch,
                setLinkAction,
                clearLinkAction,
                site,
                globalVariables = {},
                enabled = true,
                maxLinkNameWidth = MAX_LINK_NAME_WIDTH,
                setLinkText = DefaultSetLinkText,
                intl,
                sectionComponents,
                title = ''
            } = this.props,
            link = linkAction && linkAction.link,
            linkType = link && link.type,
            isLoading = linkType === LcTabName.SECTION_LINK && sectionComponents.isLoading,
            { name: linkName } = getIntlLinkName({
                link,
                site,
                globalVariables,
                getter: getShortLinkName,
                intl,
                sectionComponents
            }),
            clearLink = enabled ? (e) => {
                dispatch({ type: clearLinkAction });
                e.stopPropagation();
            } : undefined,
            onClickOpenLinkChooser = enabled
                ? {
                    onClick: () => {
                        if (this.props.onClick) {
                            this.props.onClick();
                        }
                        dispatch(openLinkChooserDialog({ setLinkAction, input: linkAction }));
                    }
                }
                : {};

        return (
            <div
                className={cx(styles.linkButton,
                    {
                        [`${styles.disabled}`]: !enabled,
                        [styles.blankLink]: !linkAction
                    })}
                {...onClickOpenLinkChooser}
                data-title={title}
            >
                {
                    isLoading && <LoadingIndicator className={cx(styles.loadingIndicator)} />
                }
                {
                    !isLoading &&
                    (linkAction
                        ? <div className={cx(styles.linkButtonContainer, {
                            broken: isBrokenLink(linkAction.link)
                        })}
                        >
                            {linkName && <LongTextTip maxWidth={maxLinkNameWidth}>{linkName}</LongTextTip>}
                            <HorizontalSpacer x={9} />
                            <span className={styles.linkButtonDeleteIco} onClick={clearLink} />
                        </div>
                        : <div className={styles.linkButtonContainer}>
                            <span className={styles.linkButtonText}>
                                {intl.msgJoint(setLinkText)}
                            </span>
                        </div>)
                }
                <span
                    className={cx(styles.linkButtonLinkIco, {
                        [styles.linkSet]: linkAction
                    })}
                />
            </div>
        );
    }
});
