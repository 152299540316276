import { makeInputReducer } from "../../../../../components/inputControls/input/reducerFactory/index";
import { CHANGE_RENAME_UPLOAD_FILENAME_ACTION } from "./actions";
import { uploadRenameDialogInputValidation } from "./uploadRenameDialogInputValidation";

export const uploadRenameDialogReducer = makeInputReducer({
    actionType: CHANGE_RENAME_UPLOAD_FILENAME_ACTION,
    validations: [
        { validation: uploadRenameDialogInputValidation }
    ]
});
