import React from "react";
import cx from "classnames";
import {
    ONBOARDING_WEBSITE_TITLE_CHANGED,
    ONBOARDING_WEBSITE_TITLE_CHANGED_ON_BLUR,
    ONBOARDING_STEP_BACK_BUTTON_PRESSED,
    ONBOARDING_FILL_LATER_PRESSED,
    ONBOARDING_CONTINUE_PRESSED
} from "../actionTypes";
import InputField from "../../../view/common/Input/InputField";
import { MVE_WEBSITE_TITLE_MAX_LENGTH } from "../../MobileViewEditor/header/propertiesPanel/pages/main/constants";
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import styles from "./styles.css";
import { Msg, Intl } from "../../../view/intl/index";
import { Buttons } from "./Buttons";
import { OnboardingTopBar } from "../../TopBar/view/OnboardingTopBar";
import TitleStepRightSideView from "./LottieAnimations/TitleStepRightSideView";
import stepStyles from "./TitleStep.css";

export type TitleStepProps = {
    websiteTitle: string;
    isForwardDirection: boolean;
    dispatch: Dispatch;
    intl: Intl;
};
export const TitleStep = (props: TitleStepProps) => {
    const { websiteTitle, isForwardDirection, dispatch, intl } = props,
        mctaDisabled = websiteTitle.length === 0;

    const mctaRef = React.useRef<HTMLDivElement>(null),
        onEnterPress = () => {
            if (!mctaDisabled && mctaRef && mctaRef.current) {
                mctaRef.current.focus();
            }
        };

    return (
        <div className={styles.contentContainer}>
            <div className={cx(styles.leftContentContainer)}>
                <OnboardingTopBar />
                <div className={styles.flex1} />
                <div className={styles.leftContent}>
                    <div className={styles.textProgress}>
                        <Msg
                            k="onboarding.step"
                            params={{
                                step: 1
                            }}
                        >{`Step {step} of 5`}</Msg>
                    </div>
                    <div className={cx(styles.question)}>
                        <Msg k="onboarding.websiteTitle">What\'s the name of your business or project?</Msg>
                    </div>
                    <div className={styles.tip}>
                        <Msg k="onboarding.websiteTitleTip">
                            This is shown at the top of your page and is used by your visitors and search engines to find you.
                        </Msg>
                    </div>
                    <VerticalSpacer y={45} />
                    <div
                        style={{
                            position: "relative"
                        }}
                    >
                        <InputField
                            className={styles.inputBox}
                            placeholder={intl.msgJoint('msg: onboarding.websiteTitle.placeHolder {Eg: "Gilbert\'s Bakery"}')}
                            value={websiteTitle}
                            onChange={payload =>
                                dispatch({
                                    type: ONBOARDING_WEBSITE_TITLE_CHANGED,
                                    payload
                                })}
                            usePropsValue
                            maxLength={MVE_WEBSITE_TITLE_MAX_LENGTH}
                            changedByUserOnBlur={payload =>
                                dispatch({
                                    type: ONBOARDING_WEBSITE_TITLE_CHANGED_ON_BLUR,
                                    payload
                                })}
                            onEnterPress={onEnterPress}
                        />
                    </div>
                </div>
                <div className={styles.flex1} />
                <Buttons
                    mctaRef={mctaRef}
                    mctaDisabled={mctaDisabled}
                    mctaHandler={() =>
                        dispatch({
                            type: ONBOARDING_CONTINUE_PRESSED
                        })}
                    sctaHandler={() =>
                        dispatch({
                            type: ONBOARDING_STEP_BACK_BUTTON_PRESSED
                        })}
                    tctaHandler={() =>
                        dispatch({
                            type: ONBOARDING_FILL_LATER_PRESSED
                        })}
                    intl={intl}
                />
            </div>
            <div className={cx(styles.rightContentContainer, stepStyles.rightContentContainer)}>
                <TitleStepRightSideView title={websiteTitle} isForwardDirection={isForwardDirection} />
            </div>
        </div>
    );
};
