// @ts-nocheck
import React from 'react';
import * as R from 'ramda';
import { Flex } from "../../../view/reflexbox/index";
import VerticalSpacer from '../../../view/common/VerticalSpacer';
import ColorProperty from '../ColorProperty/index';
import Slider from '../../../view/common/Slider/PPSlider';
import {
    MIN_BLUR_RADIUS,
    MAX_BLUR_RADIUS,

    MIN_SHADOW_HORIZONTAL_OFFSET,
    MAX_SHADOW_HORIZONTAL_OFFSET,

    MIN_SHADOW_VERTICAL_OFFSET,
    MAX_SHADOW_VERTICAL_OFFSET
} from './constants';
import { THEME_COLOR_OVERRIDE_COLOR_PICKER, DEFAULT_COLOR_PICKER } from '../../ColorPicker/constants';

import type { TextShadowPropTypes } from './flowTypes';

export default (props: TextShadowPropTypes) => {
    const {
        shadowColorChangeAction,
        shadowRemoveAction,
        blurRadius,
        blurRadiusChangeAction,
        shadowOffsetX,
        shadowOffsetXChangeAction,
        shadowOffsetY,
        shadowOffsetYChangeAction,
        dispatch,
        beforeOpen
    } = props;
    const getShadowColor = () => {
        if (props.colorPickerKind === THEME_COLOR_OVERRIDE_COLOR_PICKER) {
            return props.themeColor ? props.themeColorsData[props.themeColor] : null;
        } else {
            return props.shadowColor;
        }
    };
    const isShadowColorApply = (() => {
        const shadowColor = getShadowColor();
        return Boolean(shadowColor && shadowColor[4]);
    })();
    return (
        <Flex column>
            {
                (props.colorPickerKind === THEME_COLOR_OVERRIDE_COLOR_PICKER)
                    ? <ColorProperty
                        colorPickerKind={THEME_COLOR_OVERRIDE_COLOR_PICKER}
                        themeColorsData={props.themeColorsData}
                        themeColor={props.themeColor || null}
                        label="msg: common.shadowColor {Shadow colour}"
                        onChangeAction={shadowColorChangeAction}
                        onRemoveAction={shadowRemoveAction}
                        dispatch={dispatch}
                        beforeOpen={beforeOpen}
                    />
                    : <ColorProperty
                        colorPickerKind={DEFAULT_COLOR_PICKER}
                        label="msg: common.shadowColor {Shadow colour}"
                        color={isShadowColorApply ? props.shadowColor : null}
                        onChangeAction={shadowColorChangeAction}
                        onRemoveAction={shadowRemoveAction}
                        dispatch={dispatch}
                        beforeOpen={beforeOpen}
                    />
            }
            {
                isShadowColorApply &&
                <div>
                    <VerticalSpacer />
                    <Slider
                        label="msg: text.pp.blurRadius {Blur radius}"
                        valueLabel={`${blurRadius} px`}
                        value={blurRadius}
                        min={MIN_BLUR_RADIUS}
                        max={MAX_BLUR_RADIUS}
                        disabled={R.isNil(getShadowColor())}
                        onChange={blurRadius => dispatch({ type: blurRadiusChangeAction, payload: blurRadius })}
                    />
                    <VerticalSpacer />
                    <Slider
                        label="msg: text.pp.horizontalOffset {Horizontal offset}"
                        valueLabel={`${shadowOffsetX} px`}
                        value={shadowOffsetX}
                        min={MIN_SHADOW_HORIZONTAL_OFFSET}
                        max={MAX_SHADOW_HORIZONTAL_OFFSET}
                        disabled={R.isNil(getShadowColor())}
                        onChange={hOffset => dispatch({ type: shadowOffsetXChangeAction, payload: hOffset })}
                    />
                    <VerticalSpacer />
                    <Slider
                        label="msg: text.pp.verticalOffset {Vertical offset}"
                        valueLabel={`${shadowOffsetY} px`}
                        value={shadowOffsetY}
                        min={MIN_SHADOW_VERTICAL_OFFSET}
                        max={MAX_SHADOW_VERTICAL_OFFSET}
                        disabled={R.isNil(getShadowColor())}
                        onChange={vOffset => dispatch({ type: shadowOffsetYChangeAction, payload: vOffset })}
                    />
                </div>
            }
        </Flex>
    );
};
