import * as React from 'react';
import cx from 'classnames';
import styles from '../view/Inserter.css';
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import EPSeparator from "../view/EPSeparator";
import Filter from "./Filters";
import HelpTip from "../view/HelpTip";

type Props = {
    title: string,
    helpTip?: React.ReactNode,
    filters: React.ReactNode,
    className: string | Object
};

const Header = ({ title, helpTip, filters, className }: Props) => {
    return (
        <div className={cx(styles.extendedPanelTitleContainer, styles.panelWithFilter, className)}>
            <VerticalSpacer y={20} />
            <div className={styles.sectionsExtendedPanelTitleWrapper}>
                <div className={styles.extendedPanelTitle}>
                    {title}
                </div>
                {helpTip}
            </div>
            <VerticalSpacer y={10} />
            {filters}
            <VerticalSpacer y={12} />
            <EPSeparator />
        </div>
    );
};

type HeaderViewProps = {
    title: string,
    data: Array<{
        id: string,
        title: string
    }>,
    selected: string,
    actionName: string,
    tipMessage?: string,
    className?: string | Object,
    dispatch: any,
    hasError: boolean
};

const HeaderView = (props: HeaderViewProps) => {
    const { data, title, selected, actionName, tipMessage, dispatch, className = "", hasError } = props;

    return (
        <Header
            className={className}
            title={title}
            helpTip={tipMessage && <HelpTip
                msg={tipMessage}
                dispatch={dispatch}
            />}
            filters={hasError ? <span /> : <Filter
                data={data}
                selected={selected}
                setFilter={a => dispatch({ type: actionName, payload: a })}
            />}
        />
    );
};

export default HeaderView;
