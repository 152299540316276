import { makeValueReducer } from "../../redux/makeReducer/index";
import * as status from "./constants/status";

type RequestActionTypes = [string, string, string];

export default function makeRequestReducer(actionTypes: RequestActionTypes) {
    const [request, success, fail] = actionTypes;
    return makeValueReducer({
        defaultState: {
            status: status.NOT_INITIALIZED
        },
        handleActions: {
            [request]: state => ({ ...state, status: status.IN_PROGRESS }),
            [success]: state => ({ ...state, status: status.SUCCESS }),
            [fail]: (state, { payload }) => ({ ...state, status: status.FAIL, payload })
        }
    });
}
