export const OPENING_HOURS_FONT_SIZE_CHANGED = 'OPENING_HOURS_FONT_SIZE_CHANGED';
export const OPENING_HOURS_HORIZONTAL_ALIGNMENT_CHANGED = 'OPENING_HOURS_HORIZONTAL_ALIGNMENT_CHANGED';
export const OPENING_HOURS_COLOR_CHANGED_AUTO_COLOR = 'OPENING_HOURS_COLOR_CHANGED_AUTO_COLOR';
export const OPENING_HOURS_COLOR_CHANGED = 'OPENING_HOURS_COLOR_CHANGED';
export const OPENING_HOURS_CLEAR_FORMATTING = 'OPENING_HOURS_CLEAR_FORMATTING';
export const OPENING_HOURS_FONT_FAMILY_CHANGED = 'OPENING_HOURS_FONT_FAMILY_CHANGED';
export const OPENING_HOURS_CHARACTER_SPACING_CHANGED = 'OPENING_HOURS_CHARACTER_SPACING_CHANGED';
export const OPENING_HOURS_LINE_SPACING_CHANGED = 'OPENING_HOURS_LINE_SPACING_CHANGED';
export const OPENING_HOURS_DAYS_BOLD_CHANGED = 'OPENING_HOURS_DAYS_BOLD_CHANGED';
export const OPENING_HOURS_DAYS_ITALIC_CHANGED = 'OPENING_HOURS_DAYS_ITALIC_CHANGED';
export const OPENING_HOURS_DAYS_UNDERLINE_CHANGED = 'OPENING_HOURS_DAYS_UNDERLINE_CHANGED';
export const OPENING_HOURS_DAYS_LOWERCASE_BTN_CLICKED = 'OPENING_HOURS_DAYS_LOWERCASE_BTN_CLICKED';
export const OPENING_HOURS_DAYS_UPPERCASE_BTN_CLICKED = 'OPENING_HOURS_DAYS_UPPERCASE_BTN_CLICKED';
export const OPENING_HOURS_HOURS_BOLD_CHANGED = 'OPENING_HOURS_HOURS_BOLD_CHANGED';
export const OPENING_HOURS_HOURS_ITALIC_CHANGED = 'OPENING_HOURS_HOURS_ITALIC_CHANGED';
export const OPENING_HOURS_HOURS_UNDERLINE_CHANGED = 'OPENING_HOURS_HOURS_UNDERLINE_CHANGED';
export const OPENING_HOURS_HOURS_LOWERCASE_BTN_CLICKED = 'OPENING_HOURS_HOURS_LOWERCASE_BTN_CLICKED';
export const OPENING_HOURS_HOURS_UPPERCASE_BTN_CLICKED = 'OPENING_HOURS_HOURS_UPPERCASE_BTN_CLICKED';
export const OPENING_HOURS_WEEK_STARTS_FROM_CHANGED = 'OPENING_HOURS_WEEK_STARTS_FROM_CHANGED';
export const OPENING_HOURS_HOUR_FORMAT_CHANGED = 'OPENING_HOURS_HOUR_FORMAT_CHANGED';
export const OPENING_HOURS_GROUPING_CHANGED = 'OPENING_HOURS_GROUPING_CHANGED';
export const OPENING_HOURS_MOBILE_HORIZONTAL_ALIGNMENT_CHANGED = 'OPENING_HOURS_MOBILE_HORIZONTAL_ALIGNMENT_CHANGED';
export const OPENING_HOURS_MOBILE_DEC_FONT_BTN_PRESSED = 'OPENING_HOURS_MOBILE_DEC_FONT_BTN_PRESSED';
export const OPENING_HOURS_MOBILE_INC_FONT_BTN_PRESSED = 'OPENING_HOURS_MOBILE_INC_FONT_BTN_PRESSED';
export const OPENING_HOURS_MOBILE_CLEAR_FORMATTING = 'OPENING_HOURS_MOBILE_CLEAR_FORMATTING';
