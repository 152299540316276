
import type { AppStateSel } from "../../../globalTypes";
import { Lit } from "../../../lit";
import { pathSelector } from "../../../utils/pathSelector";
import type { InstagramMediaAsset } from "../../SocialAccounts/instagram/types";

export const
    socialAccountsInstagramConnected: AppStateSel<boolean> = pathSelector(
        [Lit.socialAccounts, Lit.instagram, Lit.instagramConnected]
    ),
    socialAccountsInstagramAssets: AppStateSel<Array<InstagramMediaAsset>> = pathSelector(
        [Lit.socialAccounts, Lit.instagram, Lit.instagramAssetUrls]
    );
