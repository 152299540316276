/* eslint-disable max-len */

import * as React from 'react';
import styles from './GoogleAnalyticsSupportedFormatsTip.css';
import { TooltipCom } from "../../presentational/Tooltip/TooltipCom";
import type { ReactElementRef } from "../../../globalTypes";
import { TooltipPosition } from "../../presentational/Tooltip/constants";
import { GOOGLE_TAG_LABELS, SUPPORTED_FORMATS_HEADER_TEXT } from '../../../../../src/dashboard/src/components/Pages/Connections/GoogleAnalyticsSettings/constants';

type GoogleAnalyticsSupportedFormatsTipProps = {
    intl: Intl,
}

type GoogleAnalyticsSupportedFormatsTipState = {
    show: boolean,
};

const SupportedFormats = ({
    intl,
    onTipClose,
    targetRef
}) => {
    return (
        <div ref={targetRef}>
            <div className={styles.supportedFormatsTipHeaderContainer}>
                <div className={styles.supportedFormatsTipHeading}>
                    {intl.msgJoint(SUPPORTED_FORMATS_HEADER_TEXT)}
                </div>
                <div className={styles.crossIcon} onClick={onTipClose} />
            </div>
            <div className={styles.supportedFormatsTipLabelsContainer}>
                {GOOGLE_TAG_LABELS.map((tagLabel, index) => <span key={index}>{intl.msgJoint(tagLabel)}</span>)}
            </div>
        </div>
    );
};

export class GoogleAnalyticsSupportedFormatsTip extends React.Component<GoogleAnalyticsSupportedFormatsTipProps, GoogleAnalyticsSupportedFormatsTipState> {
    ref:ReactElementRef<HTMLSpanElement>;
    targetRef: ReactElementRef<HTMLDivElement>;

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }

    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.targetRef = React.createRef();
        this.state = { show: false };
    }

    handleClickOutside = (event: Event) => {
        // @ts-ignore
        if (this.targetRef && this.targetRef.current && !this.targetRef.current.contains(event.target)) {
            this.toolTipClose(event);
        }
    };

    toolTipOpen = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        this.setState({ show: true });
    };

    toolTipClose = (event: Event) => {
        event.stopPropagation();
        this.setState({ show: false });
    };

    render() {
        const
            { show } = this.state,
            {
                intl,
                ...restProps
            } = this.props,
            tooltipProps = {
                theme: {
                    // eslint-disable-next-line max-len
                    container: styles.googleAnalyticsSupportedTipContainer,
                    pointer: styles.googleAnalyticsSupportedTipPointer,
                    tipInfo: styles.googleAnalyticsSupportedTipInfo
                },
                isSmallTipInfo: false,
                ...restProps,
                show,
                targetRef: this.ref,
                distance: 2,
                position: TooltipPosition.RIGHT,
            };

        return (
            <React.Fragment>
                <span
                    onClick={this.toolTipOpen}
                    ref={this.ref}
                    className={styles.supportedFormatsIcon}
                />

                <TooltipCom {...tooltipProps} onClick={(event) => { event.stopPropagation(); }}>
                    <SupportedFormats
                        intl={intl}
                        onTipClose={this.toolTipClose}
                        targetRef={this.targetRef}
                    />
                </TooltipCom>
            </React.Fragment>
        );
    }
}
