// @ts-nocheck
import cx from 'classnames';
import React from "react";
import styles from './SiteSettingsDialog.css';
import { Msg } from "../../../../view/intl/index";

export default () => (
    <div className={cx(styles.footer)}>
        <div className={styles.footerHintIcon} />
        <div className={styles.tipTextContainer}>
            <Msg k="tip" className={styles.cookieBannerFooterTip}>
                Tip:
            </Msg>
            <Msg k="cookie.banner.footer.tipText" className={styles.cookieBannerFooterTipText}>
                Remember to publish your site to make these changes live.
            </Msg>
        </div>
    </div>
);
