import type { ApiAction } from "../../../../redux/middleware/api/flowTypes";
import { apiAction } from "../../../../redux/middleware/api/actions";
import type { ManagedFacebookChatPageType } from "./types";

export const
    INITIATE_FB_CHAT_EXCHANGE_TOKEN_ACTION = 'INITIATE_FB_CHAT_EXCHANGE_TOKEN_ACTION',
    initiateFBChatExchangeTokenAction = () => ({
        type: INITIATE_FB_CHAT_EXCHANGE_TOKEN_ACTION,
    });

export const
    SET_FACEBOOK_CHAT_ACCESS_CREDENTIALS_ACTION = 'SET_FACEBOOK_CHAT_ACCESS_CREDENTIALS_ACTION',
    setFacebookChatAccessCredentialsAction = (
        facebookChatAccessToken: string,
        facebookChatAccountName: string,
        facebookChatAccountId: string,
    ) => ({
        type: SET_FACEBOOK_CHAT_ACCESS_CREDENTIALS_ACTION,
        payload: {
            facebookChatAccessToken,
            facebookChatAccountName,
            facebookChatAccountId,
        }
    });

export const
    UNSET_FACEBOOK_CHAT_ACCESS_TOKEN_ACTION = 'UNSET_FACEBOOK_CHAT_ACCESS_TOKEN_ACTION',
    unSetFacebookChatAccessTokenAction = () => ({
        type: UNSET_FACEBOOK_CHAT_ACCESS_TOKEN_ACTION,
    });

export const
    EXCHANGE_FOR_FB_CHAT_LONG_ACCESS_TOKEN_REQUEST_ACTION = 'EXCHANGE_FOR_FB_CHAT_LONG_ACCESS_TOKEN_REQUEST_ACTION',
    EXCHANGE_FOR_FB_CHAT_LONG_ACCESS_TOKEN_SUCCESS_ACTION = 'EXCHANGE_FOR_FB_CHAT_LONG_ACCESS_TOKEN_SUCCESS_ACTION',
    EXCHANGE_FOR_FB_CHAT_LONG_ACCESS_TOKEN_FAILURE_ACTION = 'EXCHANGE_FOR_FB_CHAT_LONG_ACCESS_TOKEN_FAILURE_ACTION',
    exchangeForFBChatLongAccessTokenAction = (shortLivedAccessToken: string): ApiAction => {
        return apiAction({
            types: [
                EXCHANGE_FOR_FB_CHAT_LONG_ACCESS_TOKEN_REQUEST_ACTION,
                EXCHANGE_FOR_FB_CHAT_LONG_ACCESS_TOKEN_SUCCESS_ACTION,
                EXCHANGE_FOR_FB_CHAT_LONG_ACCESS_TOKEN_FAILURE_ACTION,
            ],
            endpoint: 'exchangeForFBChatLongAccessToken',
            endpointParams: [shortLivedAccessToken]
        });
    };

export const
    DISCONNECT_FACEBOOK_CHAT_REQUEST_ACTION = 'DISCONNECT_FACEBOOK_CHAT_REQUEST_ACTION',
    DISCONNECT_FACEBOOK_CHAT_SUCCESS_ACTION = 'DISCONNECT_FACEBOOK_CHAT_SUCCESS_ACTION',
    DISCONNECT_FACEBOOK_CHAT_FAILURE_ACTION = 'DISCONNECT_FACEBOOK_CHAT_FAILURE_ACTION',
    disconnectFacebookChatAction = (): ApiAction => {
        return apiAction({
            types: [
                DISCONNECT_FACEBOOK_CHAT_REQUEST_ACTION,
                DISCONNECT_FACEBOOK_CHAT_SUCCESS_ACTION,
                DISCONNECT_FACEBOOK_CHAT_FAILURE_ACTION,
            ],
            endpoint: 'disconnectFacebookChat',
        });
    };

export const
    GET_FB_CHAT_OWN_MANAGED_ACCOUNTS_SUCCESS_ACTION = 'GET_FB_CHAT_OWN_MANAGED_ACCOUNTS_SUCCESS_ACTION',
    GET_FB_CHAT_OWN_MANAGED_ACCOUNTS_FAILURE_ACTION = 'GET_FB_CHAT_OWN_MANAGED_ACCOUNTS_FAILURE_ACTION';

export const
    SET_MANAGED_FB_CHAT_PAGES_ACTION = 'SET_MANAGED_FB_CHAT_PAGES_ACTION',
    setManagedFbChatPagesAction = (fbPages: Array<ManagedFacebookChatPageType>) => ({
        type: SET_MANAGED_FB_CHAT_PAGES_ACTION,
        payload: fbPages
    });

export const
    UNSET_MANAGED_FB_CHAT_PAGES_ACTION = 'UNSET_MANAGED_FB_CHAT_PAGES_ACTION',
    unsetManagedFbChatPagesAction = () => ({
        type: UNSET_MANAGED_FB_CHAT_PAGES_ACTION,
    });

export const
    SET_SELECTED_FB_CHAT_PAGE_VALUE = 'SET_SELECTED_FB_CHAT_PAGE_VALUE',
    setFbChatPageValue = (fbPageAccessValue: string) => ({
        type: SET_SELECTED_FB_CHAT_PAGE_VALUE,
        payload: fbPageAccessValue
    });

export const
    GET_FACEBOOK_CHAT_ACCESS_TOKEN_REQUEST_ACTION = 'GET_FACEBOOK_CHAT_ACCESS_TOKEN_REQUEST_ACTION',
    GET_FACEBOOK_CHAT_ACCESS_TOKEN_SUCCESS_ACTION = 'GET_FACEBOOK_CHAT_ACCESS_TOKEN_SUCCESS_ACTION',
    GET_FACEBOOK_CHAT_ACCESS_TOKEN_FAILURE_ACTION = 'GET_FACEBOOK_CHAT_ACCESS_TOKEN_FAILURE_ACTION',
    getFacebookChatAccessTokenAction = (): ApiAction => {
        return apiAction({
            types: [
                GET_FACEBOOK_CHAT_ACCESS_TOKEN_REQUEST_ACTION,
                GET_FACEBOOK_CHAT_ACCESS_TOKEN_SUCCESS_ACTION,
                GET_FACEBOOK_CHAT_ACCESS_TOKEN_FAILURE_ACTION,
            ],
            endpoint: 'getFacebookChatAccessToken',
        });
    };
