import cx from 'classnames';
import getCenteredDialog from "../../../DialogManager/getCenteredDialogConfig";
import { configurationDialogFactory } from '../configurationDialogFactory';
import { GoogleMapsWidgetDialogHeight, DialogWidth } from '../constants';
import { WIDGETS_GOOGLEMAPS_SET_LINK } from "./actionTypes";
import { WidgetsGoogleMapsKind } from "./kind";
import Icons from '../view/Icons.css';

const
    configurationDialogId = "WIDGETS_GOOGLEMAPS_CONFIG_DIALOG",
    configurationDialogView = configurationDialogFactory({
        saveAction: WIDGETS_GOOGLEMAPS_SET_LINK,
        dialogIconClass: cx(Icons.googleMaps, Icons.dialog),
        dialogTitle: "msg: widgets.googlemaps.label {Google Maps}",
        info: "msg: widgets.googlemaps.config.info {Search your address to generate a map.}",
        learnMore: "msg: widgets.googlemaps.config.learnMore {https://help.one.com/hc/en-us/articles/360000110018}",
        inputPlaceHolder: "Add an address",
        widgetType: WidgetsGoogleMapsKind,
        isLinkValid: (addressLocation) => !!addressLocation,
        // eslint-disable-next-line max-len
        termsOfService: "msg: widgets.googlemaps.config.termsOfService {By using this widget, you agree to Google\'s {termsOfServiceLink}.}",
        termsOfServiceLink: "msg: widgets.googlemaps.config.termsOfServiceLink {https://cloud.google.com/maps-platform/terms }",
    }),
    configurationDialog = getCenteredDialog({
        width: DialogWidth,
        height: GoogleMapsWidgetDialogHeight,
        component: configurationDialogView
    });

export { configurationDialogId, configurationDialog };

