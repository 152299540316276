import type { LinkChooserLinkType } from "../flowTypes";
import { Intl } from "../../../../intl/index";
import { DataSite } from "../../../../../../dal/model/index";
import { BROKEN_LINK_MSG } from "../constants";
import type { SectionComponents } from '../../../../../components/PagesTree/sectionComponents/epic/sectionComponentsEpic';

type Params = {
    link: null | undefined | LinkChooserLinkType,
    site: DataSite,
    getter: (LinkChooserLinkType, DataSite, SectionComponents, Object) => string,
    intl: Intl,
    sectionComponents: SectionComponents,
    globalVariables?: Record<string, any>,
};

type Result = {
    name: null | undefined | string,
    isBrokenLink: boolean,
};

export const getIntlLinkName = ({ link, site, getter, intl, sectionComponents, globalVariables }: Params): Result => {
    if (!link) return { name: null, isBrokenLink: false };

    const
        name = getter(link, site, sectionComponents, globalVariables),
        isBrokenLink = name === BROKEN_LINK_MSG;

    return {
        name: isBrokenLink ? intl.msgJoint(name) : name,
        isBrokenLink
    };
};
