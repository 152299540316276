import makeEpic from '../../../../epics/makeEpic';
import { PANEL_ITEM_LEFT_MOUSE_DOWN, PANEL_RESIZE_HANDLE_CLICKED } from "../../actionTypes";
import valueActionType from './valueActionType';
import { RedirectReceiverVAT } from "../../../App/epics/redirectReceiverEpic";

export default makeEpic({
    defaultState: true,
    valueActionType,
    updaters: [
        {
            conditions: [PANEL_RESIZE_HANDLE_CLICKED],
            reducer: ({ state }) => ({ state: !state })
        },
        {
            conditions: [RedirectReceiverVAT],
            reducer: ({ values: [sessionData], state }) => {
                const actionType = sessionData && sessionData.key;
                const payload = sessionData && sessionData.payload;
                let actionToDispatch: Action | null = null;
                if (actionType && payload && actionType === PANEL_ITEM_LEFT_MOUSE_DOWN) {
                    actionToDispatch = {
                        type: actionType,
                                                payload
                    };
                }
                return {
                    state,
                    actionToDispatch
                };
            }
        }
    ]
});
