import React from 'react';
import * as pageIds from './ids';
import pageTitles from './titles';
import NavigationGroup from '../../../../PropertiesPanel/view/IntlNavigationGroup';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import { id as mobileViewPageId } from '../../../../PropertiesPanel/view/MobileView/page';
import injectIntl from "../../../../../view/intl/injectIntl";

class Main extends React.Component<any> {
    render() {
        const { navigateToPage } = this.props;
        return (
            <Page>
                <NavigationGroup
                    navigateToPage={navigateToPage}
                    pagesTitles={pageTitles}
                    targetPagesIds={[pageIds.HTML_CODE, pageIds.PLACEMENT, mobileViewPageId]}
                />
            </Page>
        );
    }
}

const
    id = pageIds.MAIN,
    title = pageTitles[id];

export default { id, view: injectIntl(Main), title };
