import React from "react";
export const SvgPinterestRegularMulticolor = props => (
    <svg viewBox="0 0 43 43" {...props}>
        <g fill="none">
            <circle cx={20.5} cy={23.85} r={18.5} fill="#ffffff" />
            <path
                fill="#e60019"
                d="M21.212 0C9.497 0 0 9.498 0 21.213c0 8.992 5.587 16.674 13.478 19.764-.192-1.676-.35-4.26.07-6.093a6786.66 6786.66 0 012.479-10.546s-.629-1.274-.629-3.142c0-2.951 1.711-5.15 3.841-5.15 1.816 0 2.689 1.36 2.689 2.985 0 1.815-1.152 4.54-1.764 7.07-.506 2.113 1.065 3.842 3.143 3.842 3.771 0 6.67-3.981 6.67-9.708 0-5.08-3.65-8.625-8.87-8.625-6.04 0-9.584 4.522-9.584 9.202 0 1.815.698 3.77 1.57 4.836.175.21.193.401.14.61-.157.664-.523 2.113-.593 2.41-.087.384-.314.472-.716.28-2.654-1.24-4.312-5.099-4.312-8.224 0-6.687 4.853-12.832 14.019-12.832 7.35 0 13.076 5.237 13.076 12.256 0 7.316-4.609 13.2-10.998 13.2-2.148 0-4.173-1.118-4.854-2.445l-1.327 5.046c-.471 1.85-1.763 4.155-2.636 5.57 1.99.61 4.085.942 6.285.942 11.715 0 21.212-9.498 21.212-21.213C42.424 9.498 32.927 0 21.212 0z"
            />
        </g>
    </svg>
);
