import { SET_COMPONENT_ADJUSTMENT_DATA_BATCHED } from "../../actionTypes";
import { UPDATE_COMPONENT_EXTENSION_DATA } from "./actionTypes";

let _dispath = (a) => a;
export function initAdjustmentDataDispatchCache(dispath: Dispatch) {
    _dispath = dispath;
}

const dispatchActionAfterAnimationFrameFacotry = (action) => {
    let cache = {};
    let anythingPending = false;

    return (componentId: string, entry: Object, dispatchImmediately: boolean = false) => {
        if (dispatchImmediately) {
            _dispath({ type: action, payload: { [componentId]: entry } });
        }
        cache[componentId] = { ...(cache[componentId] || {}), ...entry };
        if (!anythingPending) {
            anythingPending = true;
            window.requestAnimationFrame(() => {
                _dispath({ type: action, payload: cache });
                anythingPending = false;
                cache = {};
            });
        }
    };
};

export const addComponentAdjustmentDataEntry =
    dispatchActionAfterAnimationFrameFacotry(SET_COMPONENT_ADJUSTMENT_DATA_BATCHED);

export const updateComponentsMapExtension =
    dispatchActionAfterAnimationFrameFacotry(UPDATE_COMPONENT_EXTENSION_DATA);
