import React from 'react';
import { deepMerge } from "../../../utils/deepMerge";

// TODO: proper flow type for state

export const useMergeState = <T>(initialState: T): [T, (nextState: Record<string, any>) => void] => {
    const
        [state, setState] = React.useState(initialState),
        mergeState = (nextState: Record<string, any>) => {
            setState(deepMerge(state, nextState));
        };

    return [state, mergeState];
};
