import React from "react";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";

import imageStyles from "../../../Images/imageStyle.css";

export const AnalyticsGetStartedIllustration = () => (
    <Box width="100%" height="100%">
        {/* @ts-ignore */}
        <Hidden smUp>
            <Box marginX="auto" className={imageStyles.analyticsIllustrationMobile} />
        </Hidden>
        {/* @ts-ignore */}
        <Hidden smDown>
            <Box marginX="auto" className={imageStyles.analyticsIllustration} />
        </Hidden>
    </Box>
);
