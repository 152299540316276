import { take } from 'redux-saga/effects'; // eslint-disable-line node/file-extension-in-import
import Resource from "../../Resource";
import { fileChooserResourcesSelector } from "../../../../selectors";
import openDialogGen from "../../../../../../utils/saga/openDialogGen";
import { FileNameConflictDialog } from "../../../../../../view/common/FileChooser/dialogIds";
import { FC_UPLOAD_OVERWRITE, FC_UPLOAD_RENAME } from "../../actionTypes";
import { CLOSE_DIALOG } from "../../../../actionTypes";
import { raceGen, selectGen, closeDialogGen } from "../../../../../../utils/saga/index";

type ConflictType = {
    overwrite: any,
    rename: string,
    close: any
};

export default function* (fileName: string): Generator<any, ConflictType | null | undefined, void> {
    const resources: Array<Resource> = yield* selectGen(fileChooserResourcesSelector);

    // check if there is a conflict
    if (!resources.find((r: Resource) => r.getName() === fileName)) return null;

    // open file name conflict dialog
    yield* openDialogGen(FileNameConflictDialog, { fileName });
    const conflict = yield* raceGen({
        overwrite: take(FC_UPLOAD_OVERWRITE),
        rename: take(FC_UPLOAD_RENAME),
        close: take(CLOSE_DIALOG)
    });
    if (conflict.rename) conflict.rename = conflict.rename.payload.fileName;
    if (!conflict.close) yield* closeDialogGen();

    // @ts-ignore
    return conflict;
}
