import kind from "./kind";
import calcRenderProps from "./calcRenderProps";
import { TableForPreviewAndPublish } from "./view/Table";
import type { CalcProps } from '../../Preview/flowTypes';
import type { TableComponent } from "./flowTypes";
import './view/Preview.css';
import { tableEditModeEpicDefaultState } from "./epics/tableEditMode/models/state";
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";

export default {
    kind,
    view: TableForPreviewAndPublish,
    calcProps: ({
        componentId,
        component,
        globalStyles,
        globalVariables,
        stylesheetsIdToNameMap,
        siteMap,
        siteSettings: { themeSettingsData },
        selectedParentTheme
    }: CalcProps<TableComponent>) => calcRenderProps({
        componentId,
        component,
        componentDependencies: {
            stylesheets: globalStyles,
            globalVariables,
            stylesheetsIdToNameMap,
            tableEditModeState: tableEditModeEpicDefaultState,
            site: siteMap,
            selectedParentTheme,
            themeSettingsData,
            themeColorsData: getThemeColorsFromStylesheet(globalStyles)
        },
        componentExtension: undefined,
        inEditMode: false,
        isWorkspace: false,
        selectedParentTheme
    })
};
