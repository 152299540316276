/* eslint-disable max-len */
import React from "react";
import cx from 'classnames';
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import * as styles from "./TutorialVideoIntro.css";
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import Popup from "../../Popup";
import { Msg } from "../../../../../view/intl";
import { TOP_BAR_CLOSE_POPUP, TUTORIAL_VIDEO_CLICKED } from "../../../actionTypes";

const TutorialVideoOption = () => {
    const dispatch = useDispatch();

    return (
        <div className={styles.background}>
            <div className={styles.bgSpotlight}>
                <Popup
                    containerClassName={styles.container}
                    tipClassName={styles.tip}
                    bodyClassName={styles.popupBody}
                >
                    <div className={styles.leftContainer}>
                        <div className={styles.wrapper}>
                            <div className={styles.closeBtn} onClick={() => dispatch({ type: TOP_BAR_CLOSE_POPUP })} />
                            <VerticalSpacer y={45} />
                            <span className={styles.welcomeTitle}>
                                <Msg k="common.videoTutorial">Video Tutorial</Msg>
                            </span>
                            <VerticalSpacer y={1} />
                            <span className={styles.welcomeMessage}>
                                <Msg k="tutorialVideoIntroPopup.msg">
                                    Watch our step-by-step video tutorial on how to use Website Builder and edit your website at the same time.
                                </Msg>
                            </span>
                            <VerticalSpacer y={30} />
                            <div className={styles.mainBtns}>
                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    className={styles.skipBtn}
                                    onClick={() => dispatch({ type: TOP_BAR_CLOSE_POPUP })}
                                >
                                    <Msg k="common.skip">Skip</Msg>
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={styles.btn}
                                    onClick={() => dispatch({ type: TUTORIAL_VIDEO_CLICKED })}
                                >
                                    <Msg k="common.watchVideo">Watch Video</Msg>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={cx(styles.rightContainer, styles.vt)} />
                </Popup>
            </div>
        </div>
    );
};

export default TutorialVideoOption;
