import * as R from 'ramda';

/*
 a helper function that returns null if any of argument undefined or null, otherwise execute inner function
 usefull for epic that may have value, but also may not
 */

type Config = {
    howMuchArgsToCheck?: number,
    defaultValue?: AnyValue
}

export default (fun: Function, config?: null | Config) => {
    const howMuchArgsToCheck = config ? config.howMuchArgsToCheck : 1;
    if (howMuchArgsToCheck && howMuchArgsToCheck < 1) {
        throw Error('howMuchArgsToCheck should be 1 or more');
    } else if (typeof fun === 'undefined') {
        throw Error('Expected Function, got undefined');
    }

    return (...args: Array<any>) => {
        const argsToCheck = (howMuchArgsToCheck && howMuchArgsToCheck > 0 && howMuchArgsToCheck < args.length) ?
            R.take(howMuchArgsToCheck, args) : args;

        if (R.any(arg => arg === undefined || arg === null, argsToCheck)) {
            return (!config || config.defaultValue === undefined) ? null : config.defaultValue;
        } else {
            return fun(...args);
        }
    };
};
