/* eslint-disable max-len */
import React from 'react';
import cx from 'classnames';
import Measure from "react-measure";
import styles from './style.css';
import { LOGO_FONT_SIZE_SHADOW_RENDER } from '../../../../../dal/pageMapAdapter/mappers/Globalstyles/index';
import type { ThemeBackgroundType } from '../../../ThemeGlobalData/flowTypes';

export type Props = {
    websiteTitle: string,
    horizontalAlignment: 'left' | 'center' | 'right',
    scale: number,
    isWorkspace: boolean,
    href: string | null,
    base: { width: number, height: number },
    selectedParentTheme?: ThemeBackgroundType,
}

const toJustifyContent = (horizontalAlignment) => {
    switch (horizontalAlignment) {
        case 'left':
            return 'flex-start';
        case 'center':
            return 'center';
        case 'right':
            return 'flex-end';
        default:
            return 'center';
    }
};

type State = {
    renderDimensions: { width: number, height: number },
}

const wrapInA = ({ href, isWorkspace, cmp }) => {
    return href && !isWorkspace ? (<a style={{ textDecoration: 'none', display: 'block' }} href={href}>{cmp}</a>) : cmp;
};

const getWidthScale = scale => (50 + (scale * 50)) / 100;

const getScale = (scale, componentWidth, componentHeight, renderWidth, renderHeight) => {
    const availableComponentWidth = componentWidth * getWidthScale(scale);
    const availableComponentHeight = componentHeight * scale;
    const scaleWidth = availableComponentWidth / renderWidth;
    const scaleHeight = availableComponentHeight / renderHeight;

    return Math.min(scaleWidth, scaleHeight);
};

const TEXT_MAX_LINE_HEIGHT = 48; // (40 * 1.2) 40 is max font size of text we allow. default line height from browsers is approx 1.2

const MveLogoDimensions = { width: 300, height: TEXT_MAX_LINE_HEIGHT };
export class LogoTextModeView extends React.Component<Props, State> {
    textNode: Node | null | undefined;
    constructor(props: Props) {
        super(props);
        this.state = { renderDimensions: { width: 0, height: 0 } };
    }

    render() {
        const { props: { isWorkspace, horizontalAlignment, href, websiteTitle, scale, base } } = this;
        const { width, height } = base || MveLogoDimensions;

        const fontSizeScale = this.state.renderDimensions.width ? getScale(scale, width, height, this.state.renderDimensions.width, this.state.renderDimensions.height) : 0;
        return (
            wrapInA({
                href,
                isWorkspace,
                cmp: <div>
                    <div
                        style={{
                            whiteSpace: 'nowrap', // do not remove, required for text being in a single line during measurements
                            visibility: 'hidden',
                            pointerEvents: 'none',
                            position: 'absolute', // do not change it to fixed, it is breaking overflow: visible on mac
                            width: 1,
                            height: 1,
                            overflow: 'hidden', // do not remove it, ot will break iPad view with logo component. WBTGEN-12357
                            left: -20000, // required in ios deivces because of double render
                            top: -20000, // required in ios deivces because of double render
                        }}
                        data-shadow-render-container
                    >
                        <Measure
                            offset
                            onResize={({ offset }) => {
                                const width = offset.width;
                                const height = offset.height;

                                if (this.state.renderDimensions.width !== width || this.state.renderDimensions.height !== height) {
                                    this.setState({ renderDimensions: { width, height } });
                                }
                            }}
                        >
                            {
                                ({ measureRef }) => {
                                    return (
                                        <div className="tinyMceContent textnormal">
                                            <h1
                                                style={{ display: 'inline-block' }}
                                                ref={node => {
                                                    if (!this.textNode) {
                                                        this.textNode = node;
                                                        if (node) {
                                                            this.setState({
                                                                renderDimensions: {
                                                                    width: node.offsetWidth,
                                                                    height: node.offsetHeight,
                                                                }
                                                            });
                                                        }
                                                    }
                                                    return measureRef(node);
                                                }}
                                                className={cx("textlogo", this.props.selectedParentTheme)}
                                                data-shadow-render
                                            >
                                                {websiteTitle}
                                            </h1>
                                        </div>
                                    );
                                }
                            }
                        </Measure>
                    </div>
                    <div
                        className={styles.logoTextContainer}
                        style={{ justifyContent: toJustifyContent(horizontalAlignment) }}
                        data-horizontal-alignment={horizontalAlignment}
                        data-logo-container
                    >
                        <div
                            className={styles.logoTextInnerContainer}
                            style={{
                                height: `${scale * 100}%`,
                                width: `${getWidthScale(scale) * 100}%`,
                                justifyContent: toJustifyContent(horizontalAlignment)
                            }}
                        >
                            <div className="tinyMceContent textnormal" style={{ whiteSpace: 'nowrap' }}>
                                <h1
                                    className={cx("textlogo", this.props.selectedParentTheme)}
                                    style={{
                                        // textDecorationSkipInk: 'none', // use it if we want consistent underline cross-browser
                                        fontSize: LOGO_FONT_SIZE_SHADOW_RENDER * fontSizeScale,
                                    }}
                                >
                                    {websiteTitle}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            })
        );
    }
}
