import * as R from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';

import { unwrapUpdaters } from './updaters/unwrapUpdaters';
import { wrapDirectionUpdaters } from './updaters/wrapDirectionUpdaters';

import type { WrapScope } from './flowTypes';
import { emptyArray } from "../../../../utils/handle/makeHandle";
import { SelectedComponentsIdsSelector } from "../componentsEval/selectorActionTypes";
import { onWrapperContentChangedUpdaters } from "./updaters/onWrapperContentChangedUpdaters";
import { onResizeUpdaters } from "./updaters/onResizeUpdaters";
import { Lit } from '../../../../lit';

const defaultScope: WrapScope = {
    lastSelectedComponents: emptyArray
};

const syncLastSelectedComponentsIdUpdater = {
    conditions: [SelectedComponentsIdsSelector],
    reducer: ({ values: [selectedComponentIds], state, scope }) => {
        return {
            state,
            scope: R.evolve({
                lastSelectedComponents: selectedComponentIds.length === 0 ? R.identity : () => selectedComponentIds
            }, scope)
        };
    }
};

export default makeEpic({
    defaultState: { [Lit.unwrapedTipVisible]: false },
    defaultScope,
    valueActionType,
    updaters: [
        syncLastSelectedComponentsIdUpdater,
        ...wrapDirectionUpdaters,
        ...unwrapUpdaters,
        ...onWrapperContentChangedUpdaters,
        ...onResizeUpdaters
    ]
});
