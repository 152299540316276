import type { SideBarItemDef } from '../../../SideBar/types';

export const GENERAL_PAGE_SIDE_BAR_ITEM_ID = 'GENERAL_PAGE_SIDE_BAR_ITEM_ID';

export const GeneralSideBarItem: SideBarItemDef = {
    id: GENERAL_PAGE_SIDE_BAR_ITEM_ID,
    title: 'msg: common.general {General}',
    header: {
        title: 'msg: common.general {General}',
        description: 'msg: general.header.label {Edit key information about your website, such as the logo, phone number, opening hours and more.}'
    }
};
