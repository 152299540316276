import { makeActionSetterReducer, makeCombineReducer, makeDefaultStateReducers, makeValuePathReducer } from "../../../makeReducer";
import { makeInitialStateReducer } from "../../../makeReducer/makeInitialStateReducer";
import * as ActionTypes from "./actionTypes";
import * as fmActionTypes from "../../../../components/FileManager/unsplash/actions";
import defaultState from './defaultState';
import sort from "./reducers/sort";
import toggleResourceSelection from "./reducers/toggleResourceSelection";
import toggleAllResourcesSelection from "./reducers/toggleAllResourcesSelection";
import navigateSubDirectory from "./reducers/navigateSubDirectory";
import navigateDirectory from "./reducers/navigateDirectory";
import reset from "./reducers/reset";
import handleGhostResource from "./reducers/handleGhostResource";
import resetSelection from "./reducers/resetSelection";
import fetchResourcesRequest from "./reducers/fetchResourcesRequest";
import fetchResourcesSuccess from "./reducers/fetchResourcesSuccess";
import fetchOneComVideosSuccess from "./reducers/fetchOneComVideosSuccess";
import fetchResourcesFailure from "./reducers/fetchResourcesFailure";
import { COMPONENT_CONFIGURATION_COMPLETE, COMPONENT_CONFIGURATION_CANCELED } from "../workspace/actionTypes";
import toggleViewMode from "./reducers/toggleViewMode";
import _setDefaultState from "./reducers/_setDefaultState";
import appConfigEpic from '../../../../components/App/epics/appConfig/index';
import { SWITCH_FM_TAB_ACTION } from '../../../../components/FileManager/dialog/actions';
import { switchFmTabReducer } from '../../../../components/FileManager/dialog/reducer/switchFmTabReducer';
import {
    makeShutterstockKindReducer,
} from "../../../../components/FileManager/shutterstock/reducer/makeShutterstockKindReducer";
import { IMAGE_UPDATE_ON_REPLACE } from "../../../../components/oneweb/Image/actionTypes";
import { Lit } from '../../../../lit';
import { FC_DESELECT_ALL_MY_FILES_ACTION } from './actionCreators/index';
import { ShutterstockKind } from "../../../../components/FileManager/shutterstock/constants";
import {
    paidShutterstockCombinereducerConfig
} from "../../../../components/FileManager/paidShutterstock/reducer/paidShutterstockReducerConfig";
import { ThirdPartyImageKind } from "../../../../components/FileManager/unsplash/components/constants";
import { UnsplashInitialState } from "../../../../components/FileManager/unsplash/reducer/UnsplashInitialState";
import { unsplashReducer } from "../../../../components/FileManager/unsplash/reducer/index";

const reducer = makeCombineReducer({
    combineReducers: {
        ...makeDefaultStateReducers(defaultState),
        appConfig: appConfigEpic.reducer,
        [ShutterstockKind.FREE]: makeInitialStateReducer(UnsplashInitialState, unsplashReducer),
        [ShutterstockKind.PAID]: makeShutterstockKindReducer(
            ShutterstockKind.PAID,
            paidShutterstockCombinereducerConfig
        ),
        [ThirdPartyImageKind.UNSPLASH]: makeInitialStateReducer(UnsplashInitialState, unsplashReducer),
    },
    handleActions: {
        [ActionTypes.FC_RESOURCES_REQUEST]: fetchResourcesRequest,
        [ActionTypes.FC_RESOURCES_FETCH_SUCCESS]: fetchResourcesSuccess,
        [fmActionTypes.GET_FREE_ONE_COM_VIDEOS_ACTION_SUCCESS]: fetchOneComVideosSuccess,
        [ActionTypes.FC_RESOURCES_FETCH_FAILURE]: fetchResourcesFailure,
        [ActionTypes.FC_SORT]: sort,
        [ActionTypes.FC_TOGGLE_RESOURCE_SELECTION]: toggleResourceSelection,
        [ActionTypes.FC_TOGGLE_ALL_RESOURCES_SELECTION]: toggleAllResourcesSelection,
        [ActionTypes.FC_NAVIGATE_SUB_DIRECTORY]: navigateSubDirectory,
        [ActionTypes.FC_NAVIGATE_DIRECTORY]: navigateDirectory,
        [COMPONENT_CONFIGURATION_COMPLETE]: reset,
        [COMPONENT_CONFIGURATION_CANCELED]: reset,
        [IMAGE_UPDATE_ON_REPLACE]: reset,
        [ActionTypes.FC_RESET]: reset,
        [ActionTypes.FC_ADD_GHOST_RESOURCE]: handleGhostResource,
        [ActionTypes.FC_REMOVE_GHOST_RESOURCE]: handleGhostResource,
        [ActionTypes.FC_REMOVE_GHOST_RESOURCE_BY_NAME]: handleGhostResource,
        [ActionTypes.FC_RESET_SELECTION]: resetSelection,
        [ActionTypes.FC_TOGGLE_VIEW_MODE]: toggleViewMode,
        [ActionTypes.FC_SET_CONTENT_TYPES]: makeActionSetterReducer('contentTypes'),
        [ActionTypes._FC_SET_RESOURCES]: makeActionSetterReducer('resources'),
        [ActionTypes._FC_SET_FILE_LIST_UPLOAD_PROGRESS]: makeActionSetterReducer('fileListUploadProgress'),
        [ActionTypes._FC_SET_SELECTION]: makeActionSetterReducer('selection'),
        [ActionTypes.FC_SET_MULTI_SELECT]: state => ({ ...state, isMultiSelect: true }),
        [ActionTypes._FC_SET_DEFAULT_STATE]: _setDefaultState,
        [SWITCH_FM_TAB_ACTION]: switchFmTabReducer,
        [FC_DESELECT_ALL_MY_FILES_ACTION]: makeValuePathReducer([], [Lit.selection]),
        [ActionTypes.FC_COMPUTER_UPLOAD]: state => ({ ...state, computerUpload: true }),
    }
});

export { reducer as default, defaultState };
