import { useState } from 'react';

// Selected products state passed from parent modal
export const useStateProductSelected = (featuredProducts: any[] = []) => {
    const [selectedProducts, setSelectedProducts] = useState(new Set(featuredProducts));
    const toggleSet = (set: Set<string>, id: string) => {
        if (set.has(id)) {
            set.delete(id);
        } else {
            set.add(id);
        }
        return set;
    };
    const resetSelectedProducts = () => setSelectedProducts(new Set());
    const updateSelectedProducts = (id: string) => {
        setSelectedProducts(new Set([...toggleSet(selectedProducts, id)]));
    };

    return {
        selectedProducts,
        updateSelectedProducts,
        resetSelectedProducts
    };
};
