import React from 'react';
import { Flex } from "../../../../../view/reflexbox/index";
import Page from "../../../../PropertiesPanel/view/PropertiesPage";
import actionTypes from '../../reducer/actionTypes';
import injectIntl from '../../../../../view/intl/injectIntl';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import buttonOptionsMapper from '../../../Button/globalStyle/options';
import * as selectors from '../../../../Workspace/epics/stylesheets/selectors';
import * as pagesIds from './ids';
import pagesTitles from './titles';
import type { PropertiesPanelStyleViewProps } from '../../flowTypes';
import InputField from '../../../../../view/common/Input/InputField';
import Label from '../../../../../view/common/Label';
import styles from "../webshopPropertiesPanel.css";
import { MCTA_THEME_OPTIONS_BUTTON } from "../../../../componentMainActions/constants";
import { themeColoroptionRenderer } from "../../../Button/globalStyle/themeColoroptionRenderer";
import PropertyGlobalStyleSelector from '../../../../../view/common/PropertyGlobalstyleSelector/index';
import buttonKind from '../../../Button/globalStyle/kind';
import { optionRenderer } from "../../../Button/globalStyle/optionRenderer";
import CheckBox from '../../../../../view/common/CheckBox/CheckBoxWithLabel';
import ICONS from "../../../../../view/Icons/index";

const HorizontalBar = () => {
    return <div className={styles.horizontalBar} />;
};

const id: any = pagesIds.BUTTONS,
    title = pagesTitles[pagesIds.BUTTONS],
    view = injectIntl((props: PropertiesPanelStyleViewProps) => {
        const {
            dispatch,
            selectedComponent,
            intl,
            globalStyles,
            themeSettingsData: {
                autoColorMode
            },
            stylesheetsIdToNameMap,
            selectedComponentProps: { selectedParentTheme }
        } = props;
        const
            stylesheets = selectors.getAllStylesheets(globalStyles),
            buttonStyleId = selectedComponent.buttonId;

        let
            optRenderer,
            options,
            colorThemeOnChangeAction,
            buyNowColorThemeOnChangeAction,
            selected,
            buyNowSelected,
            onChangeAction,
            buyNowOnChangeAction,
            currentStylesheetId;

        if (autoColorMode) {
            currentStylesheetId = selectors.getFirstButtonStyleId(globalStyles);
            options = MCTA_THEME_OPTIONS_BUTTON;

            const btnName = stylesheetsIdToNameMap[currentStylesheetId];
            optRenderer = (value, label) => themeColoroptionRenderer(value, label, selectedParentTheme, btnName);

            onChangeAction = actionTypes.WEBSHOP_BUTTON_STYLE_CHANGED_AUTO_COLOR;
            buyNowOnChangeAction = actionTypes.WEBSHOP_BUY_NOW_BUTTON_STYLE_CHANGED_AUTO_COLOR;
            // TODO: WBTGEN-17574
            colorThemeOnChangeAction = (value) => {
                dispatch({ type: onChangeAction, payload: value });
            };
            buyNowColorThemeOnChangeAction = (value) => {
                dispatch({ type: buyNowOnChangeAction, payload: value });
            };
            selected = selectedComponent.buttonThemeSelected;
            buyNowSelected = selectedComponent.buyNowButtonThemeSelected;
        } else {
            options = buttonOptionsMapper(stylesheets, intl);
            onChangeAction = actionTypes.WEBSHOP_BUTTON_STYLE_CHANGED;
            buyNowOnChangeAction = actionTypes.WEBSHOP_BUY_NOW_BUTTON_STYLE_CHANGED;
            optRenderer = (value, label) => optionRenderer(value, label, stylesheetsIdToNameMap);
        }

        return (
            <Page>
                <VerticalSpacer />
                <Flex align="center" justify="space-between">
                    <PropertyGlobalStyleSelector
                        label="msg: common.addToCardButtonStyle {Add to cart button style}"
                        globalStyleId={selected || buttonStyleId}
                        onChangeAction={onChangeAction}
                        options={options}
                        optionRenderer={optRenderer}
                        kind={buttonKind}
                        dispatch={dispatch}
                        showEditLink
                        colorThemeOnChangeAction={colorThemeOnChangeAction}
                        autoColorMode={autoColorMode}
                        currentStylesheetId={currentStylesheetId}
                    />
                </Flex>
                <VerticalSpacer />
                <Flex column>
                    <label className={styles.buyBtnTxtInputLabel} htmlFor="buyBtnTxt">
                        <Label>
                            {'msg: webshop.addToCartButtonText {Add to cart button text}'}
                        </Label>
                    </label>
                    <InputField
                        value={selectedComponent.buyButtonText}
                        onChange={
                            text =>
                                dispatch({
                                    type: actionTypes.WEBSHOP_BUY_BUTTON_TEXT_CHANGED,
                                    payload: text,
                                    amendToSelf: true
                                })
                        }
                        placeholder="msg: webshop.addToCart {Add to cart}"
                        className={styles.buyBtnTxtInputField}
                        id="buyBtnTxt"
                    />
                </Flex>
                <VerticalSpacer />
                <HorizontalBar />
                <VerticalSpacer />
                { /* @ts-ignore */ }
                <Flex row justify="space-between">
                    <CheckBox
                        label="msg: webshop.enableBuyNowButton {Enable Buy now button}"
                        onClick={() => dispatch({ type: actionTypes.WEBSHOP_ENABLE_BUY_NOW_CHANGED })}
                        isChecked={selectedComponent.enableBuyNowButton}
                        isSliderStyle
                    />
                    <ICONS.HELPICON
                        title="msg: component.buyNowbutton.HelpText
                        {Takes the customer directly to your checkout, where they can purchase the product.}"
                    />
                </Flex>
                <VerticalSpacer />
                {selectedComponent.enableBuyNowButton && <React.Fragment>
                    <Flex align="center" justify="space-between">
                        <PropertyGlobalStyleSelector
                            label="msg: common.buyNowButtonStyle {Buy now button style}"
                            globalStyleId={buyNowSelected || selectedComponent.buyNowButtonId}
                            onChangeAction={buyNowOnChangeAction}
                            options={options}
                            optionRenderer={optRenderer}
                            kind={buttonKind}
                            dispatch={dispatch}
                            showEditLink
                            colorThemeOnChangeAction={buyNowColorThemeOnChangeAction}
                            autoColorMode={autoColorMode}
                            currentStylesheetId={currentStylesheetId}
                        />
                    </Flex>
                    <VerticalSpacer />
                    <Flex column>
                        <label className={styles.buyBtnTxtInputLabel} htmlFor="buyNowBtnTxt">
                            <Label>
                                {'msg: webshop.buyNowButtonText {Buy now button text}'}
                            </Label>
                        </label>
                        <InputField
                            value={selectedComponent.buyNowButtonText}
                            onChange={
                                text =>
                                    dispatch({
                                        type: actionTypes.WEBSHOP_BUY_NOW_BUTTON_TEXT_CHANGED,
                                        payload: text,
                                        amendToSelf: true
                                    })
                            }
                            placeholder="msg: webshop.buyNow {Buy now}"
                            className={styles.buyBtnTxtInputField}
                            id="buyNowBtnTxt"
                        />
                    </Flex>
                    <VerticalSpacer />
                </React.Fragment>}
            </Page>
        );
    });

export { id, title, view };
