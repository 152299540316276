import React from 'react';
import * as R from 'ramda';
import cx from 'classnames';
import { Box } from '../../../view/reflexbox/index';
import LabelledCombobox from '../LabelledCombobox/index';
import { ImageChooserBtnPP } from '../../FileManager/imageChooser/ImageChooserBtnPP';
import Slider from '../../../view/common/Slider/PPSlider';
import PropertyContainer from '../../../view/common/PropertyContainer/index';
import {
    BackgroundImagePositionMsgMap,
    BackgroundRepeatOptions,
    BackgroundSize,
    BackgroundSizeOptions
} from './options';
import backgroundMapper from '../../../mappers/block/background';
import VerticalSpacer from '../../../view/common/VerticalSpacer';
import AlignmentTable from '../AlignmentTable/index';
import type { BackgroundSettingPropTypes } from './flowTypes';
import styles from './BackgroundSetting.css';
import injectIntl from "../../../view/intl/injectIntl";
import { alignments } from '../AlignmentTable/alignmentConfigs';
import { scrollEffects } from "../../oneweb/commonView/ScrollEffects/constants";
import { VideoUtils } from '../../../utils/fileUtils';

const
    radix = 10,
    MIN_ZOOM = 1,
    DEFAULT_ZOOM = 100;

const getLabelFromAsset = asset => asset.url.split('/').slice(-1)[0];

export default injectIntl(({
    asset,
    assetChangeAction,
    assetRemoveAction,
    repeat,
    assetRepeatChangeAction,
    position: alignment,
    assetPositionChangeAction: alignmentChangeAction,
    size,
    assetSizeChangeAction,
    className,
    dispatch,
    intl,
    scrollEffect,
    hideHeadLabel,
    disabled
}: BackgroundSettingPropTypes) => {
    const
        repeatValue = backgroundMapper.repeat(repeat),
        zoomNumber = parseInt(size as string, radix),
        zoomValue = isNaN(zoomNumber) ? DEFAULT_ZOOM : zoomNumber,
        zoomHidden = !(asset) || isNaN(parseInt(size as string, radix)),
        sizeValue = ((R.isNil(size) || parseInt(size as string, radix) > 0) ? BackgroundSize.ZOOM : size),
        alignmentLabel = alignment
            && intl.msgJoint(BackgroundImagePositionMsgMap[alignment.join(',').replace(/%/g, '')]),
        onZoomChange = value => dispatch({ type: assetSizeChangeAction, payload: value, amendToSelf: true }),
        disabledCells = (scrollEffect === scrollEffects.reveal || scrollEffect === scrollEffects.parallax) ?
            [alignments.LEFT_TOP, alignments.TOP, alignments.RIGHT_TOP,
                alignments.LEFT_BOTTOM, alignments.BOTTOM, alignments.RIGHT_BOTTOM] : [],
        disabledTooltip = 'msg: common.scrollEffects.disabledImagePosTooltip {Disable scroll effects to use this position}'; // eslint-disable-line max-len

    return (
        <Box className={cx(className, { [styles.disabled]: disabled })}>
            <ImageChooserBtnPP
                asset={asset}
                headLabel={hideHeadLabel ? undefined : "msg: image {Image}"}
                chooseLabel="msg: addImage {Add image}"
                changeAction={assetChangeAction}
                removeAction={assetRemoveAction}
                dispatch={dispatch}
            />
            {
                !disabled && asset && !VideoUtils.isVideoFile(getLabelFromAsset(asset)) &&
                <div>
                    <VerticalSpacer y={10} />
                    <LabelledCombobox
                        label="msg: common.Repeat {Repeat}"
                        className={styles.assetComboboxProperty}
                        value={repeatValue}
                        searchable={false}
                        options={BackgroundRepeatOptions}
                        onChange={({ value }) => dispatch({ type: assetRepeatChangeAction, payload: value })}
                    />
                    <VerticalSpacer y={10} />
                    <PropertyContainer
                        label="msg: common.Position {Position}"
                        valueLabel={alignmentLabel}
                        valueClassName={styles.alignmentValueLabel}
                    >
                        <AlignmentTable
                            alignment={alignment}
                            alignmentChangeAction={alignmentChangeAction}
                            showFit={false}
                            dispatch={dispatch}
                            disabledCells={disabledCells}
                            disabledTooltip={disabledTooltip}
                            isPropertiesPanel
                        />
                    </PropertyContainer>
                    <VerticalSpacer y={10} />
                    <LabelledCombobox
                        label="msg: common.fitting {Fitting}"
                        className={styles.assetComboboxProperty}
                        value={sizeValue}
                        options={BackgroundSizeOptions}
                        searchable={false}
                        onChange={({ value }) => dispatch({ type: assetSizeChangeAction, payload: value })}
                    />
                    {
                        !zoomHidden &&
                        <div className={styles.scaleDown}>
                            <VerticalSpacer />
                            <Slider
                                label="msg: common.scaleDown {Scale down}"
                                valueLabel={`${String(zoomValue)}%`}
                                min={MIN_ZOOM}
                                value={zoomValue}
                                onChange={onZoomChange}
                                onAfterChange={onZoomChange}
                            />
                        </div>
                    }
                </div>
            }
            {disabled && <div className={styles.disabledOverlay} />}
        </Box>
    );
});
