import React from 'react';
import cx from 'classnames';
import styles from './Input.css';
import injectIntl, { isMsgJointInput, resolveMsgJoint } from "../../../../view/intl/injectIntl";
import type { InputProps, InputValidationError } from "../flowTypes";
import { inputDefaultErrorOpts } from "../constants";
import { inputOnChange } from "../actionCreator/index";
import focusHandler from "../../../../utils/inputFocusSelect";
import { decodeWebspaceUri } from "../../../../../dal/utils/webspaceUriTransformers";
import { ENTER } from "../../../App/epics/isKeyPressed/keyboardKeys";

class Input extends React.PureComponent<InputProps> {
    onKeyPress: undefined | React.MouseEventHandler<HTMLInputElement>;
    static defaultProps = {
        decodeValue: true
    };

    static correctFocus(e) {
        const val = e.target.value;
        e.target.value = '';
        e.target.value = val;
        focusHandler(e);
    }

    constructor(props: InputProps) {
        // @ts-ignore
        super();
        this._onChange = this._onChange.bind(this);

        if (props.onEnterPress) {
            this.onKeyPress = function (e) {
                if (e.key === ENTER) {
                    e.preventDefault();
                    // @ts-ignore onEnterPress is checked above
                    this.props.onEnterPress(e);
                }
            }.bind(this);
        }
    }

    _onChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.stopPropagation();

        const { onChangeAction, actionParams, dispatch } = this.props;
        // @ts-ignore
        dispatch(inputOnChange(onChangeAction, e.target.value, actionParams));
    }

    onFocus = (e) => {
        Input.correctFocus(e);
        const { onFocus: fn } = this.props;
        if (fn) { fn(e); }
    };

    getValue(): string {
        const {
            state: { value: stateValue, isTouched },
            defaultValue,
            decodeValue,
        } = this.props;

        let value = (defaultValue && !isTouched && !stateValue) ? defaultValue : stateValue;

        if (decodeValue) value = decodeWebspaceUri(value);

        return value;
    }

    renderErrors() {
        const {
            state: { errors, isTouched },
            showErrors = true,
            errorOpts: { _name } = inputDefaultErrorOpts,
            errorsClassName,
            intl
        } = this.props;

        if (!errors.length || !showErrors || !isTouched) return null;

        return (
            <ul className={cx(styles.errors, errorsClassName)}>
                {errors.map((e: InputValidationError, i) => {
                    const [message, params = {}] = resolveMsgJoint(e.msg);
                    return (
                        // @ts-ignore
                        <li key={i}>{intl.msgJoint([message, { ...params, _name }])}</li>
                    );
                })}
            </ul>
        );
    }

    render() {
        const
            {
                state: { isValid, isTouched },
                containerClassName,
                inputClassName,
                disabled = false,
                autoFocus,
                onPaste,
                onBlur,
                placeholder: propPlacehodler,
                intl,
                refHandler
            } = this.props,
            showInvalid = isTouched && !isValid,
            className = cx(styles.input, { [styles.invalid]: showInvalid }, inputClassName),
            placeholder = propPlacehodler && isMsgJointInput(propPlacehodler)
                ? intl.msgJoint(propPlacehodler)
                : propPlacehodler;

        return (
            <div className={cx(containerClassName, styles.inputContainer)}>
                <div>
                    <input
                        value={this.getValue()}
                        onChange={this._onChange}
                        className={className}
                        disabled={disabled}
                        type="text"
                        autoFocus={autoFocus}
                        onFocus={this.onFocus}
                        // @ts-ignore
                        onKeyPress={this.onKeyPress}
                        onPaste={onPaste}
                        onBlur={onBlur}
                        placeholder={placeholder}
                        ref={(input) => {
                            if (refHandler) {
                                refHandler(input);
                            }
                        }}
                    />
                </div>
                {this.renderErrors()}
            </div>
        );
    }
}

export default injectIntl(Input);
