import { combineReducers } from "redux";
import { makeInitialStateReducer } from "../../../../src/redux/makeReducer/makeInitialStateReducer";
import { Lit } from "../../../../src/lit";
import type { TrialImportAvailabilityStateT } from "../flowTypes";
import {
    SET_TRIAL_IMPORT_AVAILABILITY_DATA_ACTION,
    TRIAL_IMPORT_DATA_REQUEST_ACTION,
    TRIAL_IMPORT_DATA_SUCCESS_ACTION,
    TRIAL_IMPORT_DATA_FAILURE_ACTION,
} from "../actions";

export const TrialImportAvailabilityDefaultState: TrialImportAvailabilityStateT = {
    [Lit.trialDataImporting]: false,
    [Lit.trialImported]: false,
    [Lit.trialImportAvailable]: false,
    [Lit.trialImportEmail]: '',
    [Lit.trialImportSubID]: '',
    [Lit.trialImportToken]: '',
    [Lit.trialImportAvailabilityScreenShot]: '',
};

export const trialImportAvailabilityReducer = combineReducers({
    [Lit.trialImportAvailabilityData]: makeInitialStateReducer(
        TrialImportAvailabilityDefaultState,
        // @ts-ignore
        (state: TrialImportAvailabilityStateT, action: Action) => {
            switch (action.type) {
                case SET_TRIAL_IMPORT_AVAILABILITY_DATA_ACTION:
                    return {
                        ...state,
                        [Lit.trialImportAvailable]: true,
                        [Lit.trialImportEmail]: action.payload.userEmail,
                        [Lit.trialImportSubID]: action.payload.trialId,
                        [Lit.trialImportToken]: action.payload.token,
                        [Lit.trialImportAvailabilityScreenShot]: decodeURIComponent(action.payload.screenshotSignedUrl),
                    };
                case TRIAL_IMPORT_DATA_REQUEST_ACTION:
                    return {
                        ...state,
                        [Lit.trialDataImporting]: true,
                    };
                case TRIAL_IMPORT_DATA_SUCCESS_ACTION:
                    return {
                        ...state,
                        [Lit.trialImported]: true,
                    };
                case TRIAL_IMPORT_DATA_FAILURE_ACTION:
                    return {
                        ...state,
                        [Lit.trialImported]: false,
                        [Lit.trialDataImporting]: false,
                    };
                default:
                    return state;
            }
        },
    ),
});
