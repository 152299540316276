import makeStyles from "@mui/styles/makeStyles";

import { extraThemeConfig } from "../../../../../app/theme";
import { getSubscriptionBgKey, getSubscriptionColorKey } from "../../../../../app/theme/utils";

export const useStyles = makeStyles(
    theme =>
        ({
            root: {
                fontSize: "14px",
                lineHeight: "22px",
                letterSpacing: "0.25px",
                textTransform: "lowercase",
                "&::first-letter": {
                    textTransform: "uppercase"
                },
                display: "inline-block",
                padding: "4px 15px",
                backgroundColor: (props: { subscriptionType: string }) =>
                    theme.palette.custom[getSubscriptionBgKey(props.subscriptionType)],
                borderRadius: "4px",
                "& > span": {
                    fontWeight: extraThemeConfig.fontWeight
                },
                color: theme.palette.custom[getSubscriptionColorKey()]
            }
        }),
    { name: "SiteSubscription" }
);
