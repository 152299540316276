/* eslint-disable max-len */

import React from 'react';

import styles from './VideoStatusToaster.css';
import { addHtmlToasterMessageWithKey, deleteMessageWithKey, modifyHtmlToasterMessage } from '../../../Toaster/actionCreators';
import MessageTypes from '../../../Toaster/MessageTypes';
import { UPLOADING_FILE, OPTIMIZING_VIDEO, UPLOAD_COMPLETE, UPLOAD_FAILED, MUTED_ON_AUTOPLAY } from './videoStatus';
import { Msg } from '../../../../view/intl';

const msgKeyGen = (status, videoName) => `${status}-${videoName}`;

const videoStatusToasterHtml = ({ status, progress = 0 }) => {
    let title: React.ReactElement | null = null, description: React.ReactElement | null = null;
    // eslint-disable-next-line default-case
    switch (status) {
        case UPLOADING_FILE:
            title = (<Msg k="component.video.toaster.uploadingVideo.title">Uploading file</Msg>);
            description = (<Msg k="component.video.toaster.uploadingVideo.desc">This may take a few minutes. We'll let you know once done.</Msg>);
            break;
        case OPTIMIZING_VIDEO:
            title = (<Msg k="component.video.toaster.optimizingVideo.title">Optimising video</Msg>);
            description = (<Msg k="component.video.toaster.optimizingVideo.desc">You can close this window. We'll let you know when your video is ready.</Msg>);
            break;
        case UPLOAD_COMPLETE:
            title = (<Msg k="component.video.toaster.uploadComplete.title">Upload complete</Msg>);
            description = (<Msg k="component.video.toaster.uploadComplete.desc">All files are uploaded and can be added to your site.</Msg>);
            break;
        case UPLOAD_FAILED:
            title = (<Msg k="component.video.failedStatusPlaceholder">Upload failed</Msg>);
            description = (<Msg k="component.video.toaster.uploadFailed.desc">Video failed to upload.</Msg>);
            break;
        case MUTED_ON_AUTOPLAY:
            title = (<Msg k="component.video.tb.autoplay">Autoplay</Msg>);
            description = (<Msg k="component.video.toaster.autoplay.desc">Please be aware that videos with autoplay enabled, will be muted by default.</Msg>);
            break;
    }
    return (
        <div className={status === UPLOAD_FAILED ? styles.videoStatusErrorToasterContainer : styles.videoStatusToasterContainer}>
            <div className={styles.videoStatusHeader}>
                {title}
            </div>
            <div className={styles.videoStatusDescription}>
                {description}
            </div>
            {
                status === OPTIMIZING_VIDEO
                    ?
                    (
                        <div key="progressBarContainer" className={styles.progressBarContainer}>
                            <div className={styles.progressBarBackground}>
                                <div className={styles.progressBarForeground} style={{ width: progress + '%' }} />
                            </div>
                            <div key="progress" className={styles.progressPercent}>{`${progress}%`}</div>
                        </div>
                    )
                    :
                    null
            }
        </div>
    );
};

const updateVideoStatusProgress = (status, videoName, progress) => {
    return modifyHtmlToasterMessage(
        msgKeyGen(status, videoName),
        [
            {
                key: 'props.children[2].props.children[0].props.children.props.style.width',
                value: `${progress}%`
            },
            {
                key: 'props.children[2].props.children[1].props.children',
                value: `${progress}%`
            }
        ]
    );
};

const closeVideoStatusToaster = (status, videoName) => {
    return deleteMessageWithKey(msgKeyGen(status, videoName));
};

const videoStatusToaster = ({ status, permanent = true, videoName }) => {
    return addHtmlToasterMessageWithKey(
        videoStatusToasterHtml({ status }),
        '',
        status === UPLOAD_FAILED ? styles.toasterErrorMessage : styles.toasterMessage,
        styles.toasterClose,
        permanent,
        MessageTypes.SUCCESS,
        msgKeyGen(status, videoName)
    );
};

export {
    videoStatusToaster,
    updateVideoStatusProgress,
    closeVideoStatusToaster
};
