import type { AppStateSel } from "../../../../globalTypes";
import { pathSelector } from "../../../../utils/pathSelector";
import { Lit } from "../../../../lit";
import { FacebookChatPage } from "./types";

export const
    socialAccountsFacebookChatManagedPages: AppStateSel<Array<FacebookChatPage>> = pathSelector(
        [Lit.socialAccounts, Lit.facebookChat, Lit.managedFbPages]
    ),
    socialAccountsFacebookChatSelectedPageValue: AppStateSel<string> = pathSelector(
        [Lit.socialAccounts, Lit.facebookChat, Lit.fbSelectedPageValue]
    );
