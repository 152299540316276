import React from 'react';
import * as styles from '../ReviewCard/ReviewCard.css';

const
    FILLED_STAR = 'FILLED_STAR',
    UNFILLED_STAR = 'UNFILLED_STAR';

const getStyle = (rating, starType) => {
    const multiplier = (starType === FILLED_STAR) ? rating : (5 - rating);
    return {
        backgroundSize: `${100 / multiplier}%`,
        width: `${16 * multiplier}px`
    };
};

const RatingStars = ({ rating }) => (
    <div className={`${styles.ratingStars} ratingStars`}>
        <div style={getStyle(rating, FILLED_STAR)} className={styles.filled} />
        <div style={getStyle(rating, UNFILLED_STAR)} className={styles.unfilled} />
    </div>
);

export default RatingStars;
