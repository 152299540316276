import * as actions from '../actionTypes';
import { WIDGETS_SPOTIFY_EPIC_VAT } from "./valueActionType";
import { parseSpotifyCode } from '../utils';
import { configurationDialogId } from '../configurationDialog';
import { epicFactory } from '../../epics/factory';
import { linkExtractor } from '../../utils';

const WidgetsSpotifyEpic = epicFactory({
    defaultState: null,
    valueActionType: WIDGETS_SPOTIFY_EPIC_VAT,
    configurationDialogId,
    codeParser: parseSpotifyCode,
    payloadExtractor: linkExtractor,
    setLinkAction: actions.WIDGETS_SPOTIFY_SET_LINK,
    updatePropsAction: actions.WIDGETS_SPOTIFY_UPDATE_PROPS,
    showUpdateLinkDialogAction: actions.WIDGETS_SPOTIFY_SHOW_UPDATE_LINK_DIALOG,
    closeDialogActions: [actions.WIDGETS_SPOTIFY_UPDATE_LINK, actions.WIDGETS_SPOTIFY_UPDATE_PROPS],
});

export { WidgetsSpotifyEpic };

