import * as colorMapper from "../../../dal/pageMapAdapter/mappers/Base/color";
import { DEFAULT_COLOR_PICKER } from './constants';
import type { ColorPicker } from './flowTypes';

const defaultState: ColorPicker = {
    opened: false,
    onChangeCompleteAction: null,
    onChangeCompleteDebounced: null,
    additionalPayload: {},
    color: colorMapper.toHsl('#000'),
    position: { x: -9999, y: -9999 },
    onTheFlyChange: false,
    recentColors: [undefined, undefined, undefined, undefined, undefined],
    hasColorChanged: false,
    colorPickerKind: DEFAULT_COLOR_PICKER,
    suggestedColorsLabel: "",
    suggestedColors: []
};

export default defaultState;
