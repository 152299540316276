import { handleBoldButtonClick } from "./helpers/handleToggledFormats";
import { makeToggleUpdater } from "./makeToggleUpdater";
import { TINY_MCE_TOGGLE_BOLD } from "../actionTypes";

const onToggleBoldUpdater =
    makeToggleUpdater({
        triggerActionType: TINY_MCE_TOGGLE_BOLD,
        propToToggle: 'bold',
        doWithEditor: handleBoldButtonClick
    });

export {
    onToggleBoldUpdater
};
