export const
    GS_TEXT_NORMAL_FONT_FAMILY_CHANGED = 'GS_TEXT_NORMAL_FONT_FAMILY_CHANGED',
    GS_TEXT_HEADING1_FONT_FAMILY_CHANGED = 'GS_TEXT_HEADING1_FONT_FAMILY_CHANGED',
    GS_TEXT_HEADING2_FONT_FAMILY_CHANGED = 'GS_TEXT_HEADING2_FONT_FAMILY_CHANGED',
    GS_TEXT_HEADING3_FONT_FAMILY_CHANGED = 'GS_TEXT_HEADING3_FONT_FAMILY_CHANGED',
    GS_TEXT_LOGO_FONT_FAMILY_CHANGED = 'GS_TEXT_LOGO_FONT_FAMILY_CHANGED',

    GS_TEXT_NORMAL_FONT_TOGGLE_BOLD = 'GS_TEXT_NORMAL_FONT_TOGGLE_BOLD',
    GS_TEXT_HEADING1_FONT_TOGGLE_BOLD = 'GS_TEXT_HEADING1_FONT_TOGGLE_BOLD',
    GS_TEXT_HEADING2_FONT_TOGGLE_BOLD = 'GS_TEXT_HEADING2_FONT_TOGGLE_BOLD',
    GS_TEXT_HEADING3_FONT_TOGGLE_BOLD = 'GS_TEXT_HEADING3_FONT_TOGGLE_BOLD',
    GS_TEXT_LOGO_FONT_TOGGLE_BOLD = 'GS_TEXT_LOGO_FONT_TOGGLE_BOLD',

    GS_TEXT_NORMAL_FONT_TOGGLE_ITALIC = 'GS_TEXT_NORMAL_FONT_TOGGLE_ITALIC',
    GS_TEXT_HEADING1_FONT_TOGGLE_ITALIC = 'GS_TEXT_HEADING1_FONT_TOGGLE_ITALIC',
    GS_TEXT_HEADING2_FONT_TOGGLE_ITALIC = 'GS_TEXT_HEADING2_FONT_TOGGLE_ITALIC',
    GS_TEXT_HEADING3_FONT_TOGGLE_ITALIC = 'GS_TEXT_HEADING3_FONT_TOGGLE_ITALIC',
    GS_TEXT_LOGO_FONT_TOGGLE_ITALIC = 'GS_TEXT_LOGO_FONT_TOGGLE_ITALIC',

    GS_TEXT_NORMAL_FONT_TOGGLE_UNDERLINE = 'GS_TEXT_NORMAL_FONT_TOGGLE_UNDERLINE',
    GS_TEXT_HEADING1_FONT_TOGGLE_UNDERLINE = 'GS_TEXT_HEADING1_FONT_TOGGLE_UNDERLINE',
    GS_TEXT_HEADING2_FONT_TOGGLE_UNDERLINE = 'GS_TEXT_HEADING2_FONT_TOGGLE_UNDERLINE',
    GS_TEXT_HEADING3_FONT_TOGGLE_UNDERLINE = 'GS_TEXT_HEADING3_FONT_TOGGLE_UNDERLINE',
    GS_TEXT_LOGO_FONT_TOGGLE_UNDERLINE = 'GS_TEXT_LOGO_FONT_TOGGLE_UNDERLINE',

    GS_TEXT_NORMAL_TEXT_CHARACTER_SPACING_CHANGED = 'GS_TEXT_NORMAL_TEXT_CHARACTER_SPACING_CHANGED',
    GS_TEXT_HEADING1_TEXT_CHARACTER_SPACING_CHANGED = 'GS_TEXT_HEADING1_TEXT_CHARACTER_SPACING_CHANGED',
    GS_TEXT_HEADING2_TEXT_CHARACTER_SPACING_CHANGED = 'GS_TEXT_HEADING2_TEXT_CHARACTER_SPACING_CHANGED',
    GS_TEXT_HEADING3_TEXT_CHARACTER_SPACING_CHANGED = 'GS_TEXT_HEADING3_TEXT_CHARACTER_SPACING_CHANGED',
    GS_TEXT_LOGO_TEXT_CHARACTER_SPACING_CHANGED = 'GS_TEXT_LOGO_TEXT_CHARACTER_SPACING_CHANGED',

    GS_TEXT_NORMAL_LINE_HEIGHT_CHANGED = 'GS_TEXT_NORMAL_LINE_HEIGHT_CHANGED',
    GS_TEXT_HEADING1_LINE_HEIGHT_CHANGED = 'GS_TEXT_HEADING1_LINE_HEIGHT_CHANGED',
    GS_TEXT_HEADING2_LINE_HEIGHT_CHANGED = 'GS_TEXT_HEADING2_LINE_HEIGHT_CHANGED',
    GS_TEXT_HEADING3_LINE_HEIGHT_CHANGED = 'GS_TEXT_HEADING3_LINE_HEIGHT_CHANGED',
    GS_TEXT_LOGO_LINE_HEIGHT_CHANGED = 'GS_TEXT_LOGO_LINE_HEIGHT_CHANGED',

    GS_TEXT_NORMAL_TEXT_SHADOW_COLOR_CHANGED = 'GS_TEXT_NORMAL_TEXT_SHADOW_COLOR_CHANGED',
    GS_TEXT_HEADING1_TEXT_SHADOW_COLOR_CHANGED = 'GS_TEXT_HEADING1_TEXT_SHADOW_COLOR_CHANGED',
    GS_TEXT_HEADING2_TEXT_SHADOW_COLOR_CHANGED = 'GS_TEXT_HEADING2_TEXT_SHADOW_COLOR_CHANGED',
    GS_TEXT_HEADING3_TEXT_SHADOW_COLOR_CHANGED = 'GS_TEXT_HEADING3_TEXT_SHADOW_COLOR_CHANGED',

    GS_TEXT_NORMAL_TEXT_SHADOW_REMOVED = 'GS_TEXT_NORMAL_TEXT_SHADOW_REMOVED',
    GS_TEXT_HEADING1_TEXT_SHADOW_REMOVED = 'GS_TEXT_HEADING1_TEXT_SHADOW_REMOVED',
    GS_TEXT_HEADING2_TEXT_SHADOW_REMOVED = 'GS_TEXT_HEADING2_TEXT_SHADOW_REMOVED',
    GS_TEXT_HEADING3_TEXT_SHADOW_REMOVED = 'GS_TEXT_HEADING3_TEXT_SHADOW_REMOVED',

    GS_TEXT_NORMAL_TEXT_SHADOW_BLUR_RADIUS_CHANGED = 'GS_TEXT_NORMAL_TEXT_SHADOW_BLUR_RADIUS_CHANGED',
    GS_TEXT_HEADING1_TEXT_SHADOW_BLUR_RADIUS_CHANGED = 'GS_TEXT_HEADING1_TEXT_SHADOW_BLUR_RADIUS_CHANGED',
    GS_TEXT_HEADING2_TEXT_SHADOW_BLUR_RADIUS_CHANGED = 'GS_TEXT_HEADING2_TEXT_SHADOW_BLUR_RADIUS_CHANGED',
    GS_TEXT_HEADING3_TEXT_SHADOW_BLUR_RADIUS_CHANGED = 'GS_TEXT_HEADING3_TEXT_SHADOW_BLUR_RADIUS_CHANGED',

    GS_TEXT_NORMAL_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED = 'GS_TEXT_NORMAL_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED',
    GS_TEXT_HEADING1_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED = 'GS_TEXT_HEADING1_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED',
    GS_TEXT_HEADING2_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED = 'GS_TEXT_HEADING2_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED',
    GS_TEXT_HEADING3_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED = 'GS_TEXT_HEADING3_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED',

    GS_TEXT_NORMAL_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED = 'GS_TEXT_NORMAL_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED',
    GS_TEXT_HEADING1_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED = 'GS_TEXT_HEADING1_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED',
    GS_TEXT_HEADING2_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED = 'GS_TEXT_HEADING2_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED',
    GS_TEXT_HEADING3_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED = 'GS_TEXT_HEADING3_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED',

    GS_TEXT_NORMAL_FONT_SIZE_CHANGED = 'GS_TEXT_NORMAL_FONT_SIZE_CHANGED',
    GS_TEXT_HEADING1_FONT_SIZE_CHANGED = 'GS_TEXT_HEADING1_FONT_SIZE_CHANGED',
    GS_TEXT_HEADING2_FONT_SIZE_CHANGED = 'GS_TEXT_HEADING2_FONT_SIZE_CHANGED',
    GS_TEXT_HEADING3_FONT_SIZE_CHANGED = 'GS_TEXT_HEADING3_FONT_SIZE_CHANGED',
    GS_TEXT_LOGO_FONT_SIZE_CHANGED = 'GS_TEXT_LOGO_FONT_SIZE_CHANGED',

    GS_TEXT_NORMAL_FONT_COLOR_CHANGED = 'GS_TEXT_NORMAL_FONT_COLOR_CHANGED',
    GS_TEXT_HEADING1_FONT_COLOR_CHANGED = 'GS_TEXT_HEADING1_FONT_COLOR_CHANGED',
    GS_TEXT_HEADING2_FONT_COLOR_CHANGED = 'GS_TEXT_HEADING2_FONT_COLOR_CHANGED',
    GS_TEXT_HEADING3_FONT_COLOR_CHANGED = 'GS_TEXT_HEADING3_FONT_COLOR_CHANGED',
    GS_TEXT_LOGO_FONT_COLOR_CHANGED = 'GS_TEXT_LOGO_FONT_COLOR_CHANGED',
    GS_TEXT_LOGO_FONT_COLOR_CHANGED_AUTO_COLOR = 'GS_TEXT_LOGO_FONT_COLOR_CHANGED_AUTO_COLOR',

    GS_TEXT_NORMAL_FONT_COLOR_REMOVED = 'GS_TEXT_NORMAL_FONT_COLOR_REMOVED',
    GS_TEXT_HEADING1_FONT_COLOR_REMOVED = 'GS_TEXT_HEADING1_FONT_COLOR_REMOVED',
    GS_TEXT_HEADING2_FONT_COLOR_REMOVED = 'GS_TEXT_HEADING2_FONT_COLOR_REMOVED',
    GS_TEXT_HEADING3_FONT_COLOR_REMOVED = 'GS_TEXT_HEADING3_FONT_COLOR_REMOVED',
    GS_TEXT_LOGO_FONT_COLOR_REMOVED = 'GS_TEXT_LOGO_FONT_COLOR_REMOVED';
