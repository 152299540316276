import React from 'react';
import BaseComponent, { StretchBase as StretchBaseComponent } from '../../../../view/oneweb/BaseComponent';
import ComponentMask from "../../../presentational/ComponentMask/index";
import { ComponentMaskBackground } from "../../../presentational/ComponentMask/constants";

const WidgetWorkspaceView = props => {
    const Wrapper = props.stretch ? StretchBaseComponent : BaseComponent;
    return (
        <Wrapper {...props}>
            <ComponentMask showPreviewOnlyLayer maskStyle={ComponentMaskBackground.TRANSPARENT} style={{ zIndex: 201 }}>
                {props.children}
            </ComponentMask>
        </Wrapper>
    );
};

export { WidgetWorkspaceView };
