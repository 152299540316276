export function ellipsis(str: string, length: number, filter: string = '....'): string {
    const strlen = str.length;

    if (strlen > length) {
        const
            part1 = str.substr(0, Math.ceil(strlen / 2)),
            part2 = str.substr(Math.floor(strlen / 2));

        return part1.substr(0, Math.ceil(length / 2)) + filter + part2.substr(-1 * Math.floor(length / 2));
    }

    return str;
}

// do removeNewLines before replaceAsciiSpaceWithSpace, as \s matches \n as well
const removeNewLines = (str: string): string => str.replace(/[\n\r]/gm, '');
const replaceAsciiSpaceWithSpace = (str: string): string => str.replace(/(\s|&nbsp;)/gm, ' ');
const removeTrailingSlashFromSelfClosingTags = (str: string): string => str.replace(/\s*\/?>/gm, '>');  //NOSONAR

export function areHtmlStringsEquivalent(str1: string, str2: string): boolean {
    return (
        removeTrailingSlashFromSelfClosingTags(replaceAsciiSpaceWithSpace(removeNewLines(str1)))
        ===
        removeTrailingSlashFromSelfClosingTags(replaceAsciiSpaceWithSpace(removeNewLines(str2)))
    );
}
