import { WidgetsMailchimpKind } from './kind';
import { componentMainActions } from './componentMainActions';
import { configurationDialogId } from './configurationDialog';
import { propertiesPanel } from './propertiesPanel/index';
import { reducer } from './reducer';
import { MailchimpView } from './view/index';
import { MailchimpWorkspaceView } from './view/workspace';
import { calcRenderProps } from './calcRenderProps';
import { WidgetsMailchimpConfig } from '../constants';
import { getMVEHiddenElementData } from '../mobileEditorConfig';

const WidgetsMailchimp = {
    kind: WidgetsMailchimpKind,
    label: WidgetsMailchimpConfig.title,
    shortcutIconName: 'widgetsMailchimp',
    view: MailchimpView,
    workspace: MailchimpWorkspaceView,
    requireConfigurationOnDrop: () => true,
    configurationDialog: configurationDialogId,
    calcRenderProps,
    componentMainActions,
    propertiesPanel,
    reducer,
    mobileEditorConfig: {
        kind: WidgetsMailchimpKind,
        calcProps: calcRenderProps,
        view: MailchimpView,
        getHiddenElementData: getMVEHiddenElementData(WidgetsMailchimpConfig),
    },
};

export { WidgetsMailchimp };
