
import {
    TEMPLATE_COLOR_CHANGE,
    TEMPLATE_TITLE_CHANGE,
    TEMPLATE_VERTICAL_SCROLL_TOGGLE
} from "./actionTypes";
import { TemplatePagesDialogId } from "./templatePagesDialog/templatePagesDialogId";
import { openDialog } from "../../App/actionCreators/index";
import type { Color } from "../../../mappers/flowTypes";

export const
    openTemplatePagesDialogAction = () => openDialog(TemplatePagesDialogId),
    templateTitleChangeAction = (value: string) => ({
        type: TEMPLATE_TITLE_CHANGE,
        payload: value
    }),
    templateVerticalScrollToggleAction = () => ({
        type: TEMPLATE_VERTICAL_SCROLL_TOGGLE
    }),
    templateBackgroundColorChangeAction = (color: Color) => ({
        type: TEMPLATE_COLOR_CHANGE,
        payload: { color }
    });

