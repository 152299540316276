/* eslint-disable no-console */

// TODO WBTGEN-6223 rename exports
if (typeof window !== 'undefined' && window.___nodeTests) {
    module.exports = {
        log: (...args) => console.log(...args),
        trace: (...args) => console.log(...args),
        error: (...args) => console.log(...args),
        info: (...args) => console.log(...args),
        warn: (...args) => console.log(...args)
    };
} else {
    module.exports = {
        log: (...args) => console.log(...args),
        trace: (...args) => console.trace(...args),
        error: (...args) => console.error(...args),
        info: (...args) => console.info(...args),
        warn: (...args) => console.warn(...args)
    };
}
