/* eslint-disable max-len */
import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import {
    COOKIE_LOCALE_EN_GB,
    COOKIE_LOCALE_EN_US,
    COOKIE_LOCALE_EN,
    ONE_LANG_COOKIE_KEY,
    TOP_BAR_LANGUAGES_COOKIE_CALLER_ID
} from '../../../../constants/app';
import {
    TOP_BAR_LANGUAGES_DEFAULT_HEIGHT,
    TOP_BAR_LANGUAGES_MIN_HEIGHT
} from "../../view/TopBarUserProfile/constants";
import { getCookieAction, setCookieAction } from "../../../../redux/middleware/cookie/actionCreators";
import { whenCookieKey, receiveOnly } from '../../../../epics/makeCondition';
import { getSupportedRenderLocales } from '../../../../renderLocales';
import { TOP_BAR_HIDE_LANGUAGES, TOP_BAR_SELECT_LANGUAGE, TOP_BAR_SHOW_LANGUAGES } from "../../actionTypes";
import { checkUnsavedChangesAction } from "../../../UnsavedChanges/actionCreators";
import { UNSAVED_CHANGES_CHECKED } from "../../../UnsavedChanges/actionTypes";
import { topBarClosePopupAction, topBarLanguageHasSelectedAction } from "../../actionCreators/index";
import { navigateToSelfAction } from "../../../../redux/middleware/window/actionCreators";
import viewportDiminsionsValueActionType from "../../../Workspace/epics/viewportDimensions/valueActionType";
import { APP_MOUNT } from '../../../App/actionTypes';
import { getDefultLocaleId, getDefultLocaleIdNormalized } from "../../../Locale/getDefaultLocale";

const
    DEFAULT_LOCALE = getDefultLocaleId(),
    DEFAULT_LOCALE_NORMALIZED = getDefultLocaleIdNormalized();
export type TopBarLanguagesState = {
    map: MapT<string>,
    current: string,
    uiLocale: string,
    height: number,
};

const
    CORRECT_HEIGHT_BUFFER = 50;

const defaultState: TopBarLanguagesState = {
    map: {},
    current: DEFAULT_LOCALE,
    uiLocale: DEFAULT_LOCALE_NORMALIZED,
    height: TOP_BAR_LANGUAGES_DEFAULT_HEIGHT
};

const supportedRenderLocals = getSupportedRenderLocales();

const correctHeight = (height: number, viewportHeight: number, languagesTopPosition: number): number => {
    let newHeight = height;
    if (
        viewportHeight < (height + languagesTopPosition + CORRECT_HEIGHT_BUFFER)
        && viewportHeight > (TOP_BAR_LANGUAGES_MIN_HEIGHT + languagesTopPosition + CORRECT_HEIGHT_BUFFER)
    ) {
        newHeight = viewportHeight - languagesTopPosition - CORRECT_HEIGHT_BUFFER;
    } else if (
        viewportHeight > (height + languagesTopPosition + CORRECT_HEIGHT_BUFFER)
        && height < TOP_BAR_LANGUAGES_DEFAULT_HEIGHT
    ) {
        newHeight = viewportHeight - languagesTopPosition - CORRECT_HEIGHT_BUFFER;
        if (newHeight > TOP_BAR_LANGUAGES_DEFAULT_HEIGHT) {
            newHeight = TOP_BAR_LANGUAGES_DEFAULT_HEIGHT;
        }
    }
    return newHeight;
};

export default makeEpic({
    defaultState,
    defaultScope: {
        selectingLanguage: null
    },
    valueActionType,
    updaters: [
        {
            conditions: [APP_MOUNT],
            reducer: ({ state, scope }) => {
                return ({
                    state,
                    scope,
                    actionToDispatch: getCookieAction(TOP_BAR_LANGUAGES_COOKIE_CALLER_ID, ONE_LANG_COOKIE_KEY)
                });
            }
        },
        {
            conditions: [whenCookieKey(TOP_BAR_LANGUAGES_COOKIE_CALLER_ID, ONE_LANG_COOKIE_KEY)],
            reducer: ({ values: [{ value: current }], state, scope }) => (
                {
                    state: {
                        ...state,
                        map: supportedRenderLocals,
                        current: current || state.current,
                        uiLocale: (function (cookieLocale) {
                            if (cookieLocale === COOKIE_LOCALE_EN_GB || cookieLocale === COOKIE_LOCALE_EN_US) {
                                return COOKIE_LOCALE_EN;
                            } else {
                                return cookieLocale;
                            }
                        }(current || state.current))
                    },
                    scope
                }
            )
        },
        {
            conditions: [TOP_BAR_SELECT_LANGUAGE],
            reducer: ({ state, values: [{ locale, fromTemplateSelector }] }) => {
                let actionToDispatch = checkUnsavedChangesAction();

                if (fromTemplateSelector) {
                    actionToDispatch = { type: UNSAVED_CHANGES_CHECKED };
                }

                return {
                    state,
                    scope: { selectingLanguage: locale },
                    actionToDispatch
                };
            }
        },
        {
            conditions: [UNSAVED_CHANGES_CHECKED],
            reducer: ({ state, scope, scope: { selectingLanguage } }) => {
                if (selectingLanguage) {
                    return {
                        state: { ...state, changed: true },
                        scope: { selectingLanguage: null },
                        multipleActionsToDispatch: [
                            topBarLanguageHasSelectedAction(),
                            topBarClosePopupAction(),
                            // If making changes here please make changes to src/dashboard/src/components/TopBar/topBarMiddleware.ts
                            setCookieAction(ONE_LANG_COOKIE_KEY, selectingLanguage, {
                                expires: new Date(Date.now() + (86400 * 180 * 1000))
                            }),
                            navigateToSelfAction()
                        ]
                    };
                }

                return { state, scope };
            }
        },
        {
            conditions: [receiveOnly(viewportDiminsionsValueActionType), TOP_BAR_SHOW_LANGUAGES],
            reducer: ({ state, scope, values: [{ height: viewportHeight }, top] }) => {
                return ({
                    state: { ...state, height: correctHeight(state.height, viewportHeight, top) },
                    scope
                });
            }
        },
        {
            conditions: [TOP_BAR_HIDE_LANGUAGES],
            reducer: ({ state, scope }) => ({ state: { ...state, height: TOP_BAR_LANGUAGES_DEFAULT_HEIGHT }, scope })
        }
    ]
});
