/* eslint-disable max-len */

import { generateStylesheetsIdToNameMap } from "../../Workspace/epics/stylesheets/idToNameMap";
import getPreviewData from "../../Preview/getPreviewData";
import { mobileMenuSel } from "../../Mobile/header/menu/selectors";
import { getAllFonts } from "../../Preview/getAllFonts";
import { mobileStyleSel } from "../../Mobile/style/selectors";
import { getDAL } from "../../../../dal/index";
import generateHtml from "../../Preview/View/generateHtml";
import type { Stylesheets } from "../../Workspace/epics/stylesheets/flowTypes";
import { DataSite } from "../../../../dal/model/index";
import type { ComponentsMap } from "../../../redux/modules/children/workspace/flowTypes";
import type { SiteSettings } from "../../App/epics/siteSettings/flowTypes";
import type { MobileData } from "../../MobileViewEditor/flowTypes";
import type { TemplateComponent } from "../../oneweb/Template/flowTypes";

export type themePagePreviewGenerateHtmlProps = {
    componentsMap: ComponentsMap,
    template: TemplateComponent,
    currentPageId: string,
    currentPageName: string,
    stylesheets: Stylesheets,
    siteMap: DataSite,
    siteSettings: SiteSettings,
    downSizeScale?: number,
    mobileData: MobileData,
    localizationsByTemplateId?: Record<string, any>,
    uiLocale?: string,
    config: Record<string, any>
    dynamicTemplateSelected?: boolean
};

const
    themePagePreviewGenerateHtml = ({
        componentsMap,
        template,
        currentPageId,
        currentPageName,
        stylesheets,
        siteMap,
        siteSettings,
        downSizeScale,
        mobileData,
        localizationsByTemplateId,
        uiLocale,
        dynamicTemplateSelected,
        config
    }: themePagePreviewGenerateHtmlProps) => {
        const
            stylesheetsIdToNameMap = generateStylesheetsIdToNameMap(stylesheets),
            previewData = getPreviewData({
                componentsMap,
                template,
                mobileData,
                dynamicTemplateSelected
            });

        const
            previewProps = {
                ...previewData,
                componentsDependencies: {},
                globalStyles: {
                    stylesheets
                },
                stylesheetsIdToNameMap,
                menuState: mobileMenuSel({ siteData: siteMap, currentPageId, componentsMap }),
                siteSettings,
                // @ts-ignore WBTGEN-16368
                fonts: getAllFonts(previewData.componentsMap, stylesheets, siteSettings),
                mobileStyle: mobileStyleSel({
                    siteSettings,
                    componentsMap: previewData.componentsMap,
                    stylesheet: stylesheets,
                    templateStyle: template.style,
                    templateSelectedTheme: template.selectedTheme,
                }),
                isServerPreview: false,
                domain: getDAL().getDomain(),
                page: {
                    id: currentPageId,
                    name: currentPageName,
                    shareHeaderAndFirstSectionBgImg: false,
                    shareModernHeaderAndFirstSectionBgImg: false,
                    shareBgImgOffsetTop: 0
                },
                siteMap,
                hdImages: false,
                downSizeScale,
                renderOnlyCodeContainer: true,
                previewLocale: uiLocale,
                templateI18n: null,

                // WBTGEN-16354 - This data is only for preview of template so any value will not make difference, but still passing false
                userHasEcommerceSubscription: false,
                userHasPremiumSubscription: false,
                flagPublish: false,
                gaAllowed: false,
                fbPixelAllowed: false,
                templateSelectorPreviewMode: "desktop",
                config,
                dynamicTemplateSelected

                /*intl,
                messages,
                onClickOrientation,
                onToggleMobileVersion,
                preview,
                previewLoaded,
                setPreviewPageToLoad,
                subscriptionData,
                togglePreview,*/
            };
        if (localizationsByTemplateId) {
            previewProps.templateI18n = localizationsByTemplateId[template.id];
        }
        // @ts-ignore
        const html = generateHtml(previewProps);
        return { html };
    };

export {
    themePagePreviewGenerateHtml
};
