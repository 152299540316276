import React from 'react';
import cx from 'classnames';
import { connect } from "react-redux";
import Resource from "../../../../../redux/modules/children/fileChooser/Resource";
import styles from '../FileChooserGrid.css';
import { directoryCellMapDispatchToProps, getFcTitleAndLabel } from "../../utils";
import type { GridDirectoryCellPropTypes } from "../../flowTypes";
import { GRID_SHORT_NAME_LENGTH } from "../../constants";
import { FmDeleteHoverBoxCom } from "../FmDeleteHoverBoxCom";

const DirectoryCell = (props: GridDirectoryCellPropTypes) => {
    const
        resource: Resource = props.resource,
        { label, title } = getFcTitleAndLabel(resource.getName(), GRID_SHORT_NAME_LENGTH);

    return (
        <div className={cx(styles.cell, styles.directoryCell)}>
            <div className={styles.directoryCellBody} />
            <div className={styles.directoryCellFooter}>{label}</div>
            <div
                className={styles.cellOverlay}
                onClick={() => props.navigateSubDirectory(resource)}
            >
                <span className={styles.cellOverlayLabel} data-title={title}>{label}</span>
                <span className={styles.cellOverlayActions}>
                    <FmDeleteHoverBoxCom resource={resource} />
                </span>
            </div>
        </div>
    );
};

export default connect(null, directoryCellMapDispatchToProps)(DirectoryCell);
