import * as actions from '../actionTypes';
import { WIDGETS_FACEBOOK_EPIC_VAT } from "./valueActionType";
import { configurationDialogId } from '../configurationDialog';
import { parseFacebookCode } from '../utils/parseFacebookCode';
import { epicFactory } from '../../epics/factory';
import { linkExtractor } from '../../utils';

const WidgetsFacebookEpic = epicFactory({
    defaultState: null,
    valueActionType: WIDGETS_FACEBOOK_EPIC_VAT,
    configurationDialogId,
    codeParser: parseFacebookCode,
    payloadExtractor: linkExtractor,
    setLinkAction: actions.WIDGETS_FACEBOOK_SET_LINK,
    updatePropsAction: actions.WIDGETS_FACEBOOK_UPDATE_PROPS,
    showUpdateLinkDialogAction: actions.WIDGETS_FACEBOOK_SHOW_UPDATE_LINK_DIALOG,
    closeDialogActions: [actions.WIDGETS_FACEBOOK_UPDATE_LINK, actions.WIDGETS_FACEBOOK_UPDATE_PROPS],
});

export { WidgetsFacebookEpic };

