import * as PagesIds from './ids';
import { SVG_KIND } from "../../kind";
import { ComponentNames } from "../../../constants";
import { MOBILE_VIEW, MobileViewTitle } from "../../../../PropertiesPanel/view/MobileView/constants";

export default {
    [PagesIds.MAIN]: ComponentNames[SVG_KIND],
    [PagesIds.COLOR]: "msg: common.color {Colour}",
    [PagesIds.ON_CLICK]: "msg: common.onClick {On click}",
    [PagesIds.SEO]: "msg: common.seo {SEO}",
    [MOBILE_VIEW]: MobileViewTitle,
};
