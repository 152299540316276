import { InstancePropertiesPanelConfig, InstancePropertiesPanelRegistry } from './InstancePropertiesPanelRegistry';
import { SHOW_PROPERTIES_PANEL_BY_INSTANCE_ID_ACTION } from './actions';
import { Lit } from '../../../lit';
import { deepMerge } from "../../../../utils/deepMerge";
import { getDimensions, getMaxHeight } from "../epic/utils/dimensions";
import { receiveOnly } from "../../../epics/makeCondition";
import browserDimensionsValueActionType from "../../App/epics/browserDimensions/valueActionType";
import PropertiesPanelInitialState from '../epic/defaultState';
import { BACK_TO_DESKTOP_EDITOR_FROM_MOBILE_EDITOR } from "../../MobileViewEditor/actionTypes";
import { makeInstancePropertiesPanelInitialStateUpdater } from "./makePropertiesPanelInitialStateUpdater";
import { TEMPLATE_SELECTOR_IMPORT_TEMPLATE_CLICK } from "../../TemplateSelector_DEPRECATED/actionTypes";
import {
    isModernLayoutActivatedVAT
} from "../../Workspace/epics/isModernLayoutActivatedEpic/valueActionType";

export const InstancePropertiesPanelUpdaters = Object.keys(InstancePropertiesPanelRegistry).reduce(
    (acc, instanceId) => {
        const
            config = new InstancePropertiesPanelConfig(instanceId),
            onShowUpdater = config.getOnShowUpdater(),
            defaultPage = config.getDefaultPage();

        let res: any = [];

        res.push({
            conditions: [
                receiveOnly(isModernLayoutActivatedVAT),
                receiveOnly(browserDimensionsValueActionType),
                ...onShowUpdater.conditions,
                SHOW_PROPERTIES_PANEL_BY_INSTANCE_ID_ACTION,
            ],
            reducer: ({
                state: stateHolder,
                values: [isModernLayoutActivated,
                    browserDimensions,
                    ...resetValues
                ]
            }: Record<string, any>) => {
                if (stateHolder.state.instance) {
                    return { state: stateHolder };
                }

                const
                    maxHeight = getMaxHeight(browserDimensions.height),
                    dimensions = getDimensions(maxHeight, 0 /* default page height before measure is 0 */),
                    {
                        state: onShowUpdaterNextState,
                        ...restOnShowUpdaterRes
                    } = onShowUpdater.reducer({ state: stateHolder, values: resetValues });

                return {
                    state: {
                        state: deepMerge(
                            PropertiesPanelInitialState.state,
                            onShowUpdaterNextState.state,
                            { // always overwrite show and instance id
                                [Lit.show]: true,
                                [Lit.instance]: {
                                    id: instanceId,
                                },
                                [Lit.dimensions]: dimensions,
                                [Lit.position]: {
                                    x: 0,
                                    y: 0,
                                },
                                [Lit.navigation]: {
                                    sequence: [defaultPage.id],
                                    activePageIndex: 0,
                                    direction: null,
                                },
                                isModernLayoutActivated,
                            },
                        ),
                        scope: {
                            ...PropertiesPanelInitialState.scope,
                            ...onShowUpdaterNextState.scope,
                            maxHeight,
                        },
                    },
                    ...restOnShowUpdaterRes,
                };
            },
        });

        res.push(makeInstancePropertiesPanelInitialStateUpdater(BACK_TO_DESKTOP_EDITOR_FROM_MOBILE_EDITOR));
        res.push(makeInstancePropertiesPanelInitialStateUpdater(TEMPLATE_SELECTOR_IMPORT_TEMPLATE_CLICK));

        res = res.concat(config.getUpdaters());

        return acc.concat(res);
    },
    [],
);
