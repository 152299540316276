import * as R from "ramda";
import {
    BackgroundRepeat
} from '../../components/presentational/BackgroundImageSettings/options';
import type { ImageAsset } from "../../components/App/flowTypes";
import type { AssetSize, AssetRepeat, AssetOverlay } from "../background/flowTypes";

export default {
    size: (asset: ImageAsset, size: AssetSize): null | undefined | string|number => {
        // @ts-ignore
        const s = parseInt(size, 10);
        if (s < 100) {
            const
                { height, width } = asset,
                factor = s / 100;
            return Math.floor(width * factor) + 'px ' + Math.floor(height * factor) + 'px';
        } else if (s === 100) {
            return 'auto'; // WBTGEN-7718
        }
        return size;
    },
    position: R.join(" "),
    repeat: (repeat: null | undefined | AssetRepeat): string => {
        if (repeat) {
            const [x, y] = repeat;
            if (x && y) {
                return BackgroundRepeat.BOTH;
            } else if (x && !y) {
                return BackgroundRepeat.HORIZONTAL;
            } else if (!x && y) {
                return BackgroundRepeat.VERTICAL;
            }
        }
        return BackgroundRepeat.NONE;
    },
    overlay: (overlay: null | undefined | AssetOverlay): string => {
        if (overlay) {
            return overlay;
        }
        return "none";
    }
};
