import { OPENING_HOURS_KIND } from "./kind";
import { defaultState } from "./defaultState";
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from "./constants";
import { makeCombineReducer, makeDefaultStateReducers, makeComponentBaseReducers } from "../../../redux/makeReducer/index";
import { TOGGLE_MOBILE_DOWN_ACTION } from "../../PropertiesPanel/view/MobileView/actions";
import mobileDownReducer from "../../PropertiesPanel/view/MobileView/mobileDownReducer";
import type { OpeningHoursComponent } from "./flowTypes";
import "./flowTypes";
import * as actionTypes from "./actionTypes";

const makePayloadToPropReducer = prop => (component, { payload }) => ({ ...component, [prop]: payload });

const makeTextStylePayloadToPropReducer = prop => (component, { payload }) => ({
    ...component,
    textStyle: { ...component.textStyle, [prop]: payload }
});

export const openingHoursReducer = makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(OPENING_HOURS_KIND, DEFAULT_WIDTH, DEFAULT_HEIGHT),
        ...makeDefaultStateReducers(defaultState)
    },
    handleActions: {
        [actionTypes.OPENING_HOURS_FONT_SIZE_CHANGED]: makeTextStylePayloadToPropReducer("fontSize"),
        [actionTypes.OPENING_HOURS_MOBILE_DEC_FONT_BTN_PRESSED]: makeTextStylePayloadToPropReducer("mobileFontSize"),
        [actionTypes.OPENING_HOURS_MOBILE_INC_FONT_BTN_PRESSED]: makeTextStylePayloadToPropReducer("mobileFontSize"),
        [actionTypes.OPENING_HOURS_COLOR_CHANGED_AUTO_COLOR]: (component, { payload }) => {
            return { ...component, textStyle: { ...component.textStyle, themeColor: payload.themeColor } };
        },
        [actionTypes.OPENING_HOURS_COLOR_CHANGED]: (component, { payload }) => {
            return { ...component, textStyle: { ...component.textStyle, color: payload.color } };
        },
        [actionTypes.OPENING_HOURS_HORIZONTAL_ALIGNMENT_CHANGED]: makePayloadToPropReducer("horizontalAlignment"),
        [actionTypes.OPENING_HOURS_MOBILE_HORIZONTAL_ALIGNMENT_CHANGED]: makePayloadToPropReducer("mobileHorizontalAlignment"),
        [actionTypes.OPENING_HOURS_CLEAR_FORMATTING]: component => {
            return {
                ...component,
                horizontalAlignment: defaultState.horizontalAlignment,
                textStyle: {
                    ...component.textStyle,
                    fontSize: defaultState.textStyle.fontSize,
                    color: defaultState.textStyle.color,
                    themeColor: defaultState.textStyle.themeColor,
                    fontFamily: defaultState.textStyle.fontFamily,
                    daysBold: defaultState.textStyle.daysBold,
                    daysItalic: defaultState.textStyle.daysItalic,
                    daysUnderline: defaultState.textStyle.daysUnderline,
                    daysCase: defaultState.textStyle.daysCase,
                    hoursBold: defaultState.textStyle.hoursBold,
                    hoursItalic: defaultState.textStyle.hoursItalic,
                    hoursUnderline: defaultState.textStyle.hoursUnderline,
                    hoursCase: defaultState.textStyle.hoursCase,
                    lineHeight: defaultState.textStyle.lineHeight,
                    letterSpacing: defaultState.textStyle.letterSpacing
                }
            };
        },
        [actionTypes.OPENING_HOURS_MOBILE_CLEAR_FORMATTING]: component => {
            return {
                ...component,
                mobileHorizontalAlignment: defaultState.mobileHorizontalAlignment,
                textStyle: { ...component.textStyle, mobileFontSize: defaultState.textStyle.mobileFontSize }
            };
        },
        [actionTypes.OPENING_HOURS_FONT_FAMILY_CHANGED]: makeTextStylePayloadToPropReducer("fontFamily"),
        [actionTypes.OPENING_HOURS_CHARACTER_SPACING_CHANGED]: makeTextStylePayloadToPropReducer("letterSpacing"),
        [actionTypes.OPENING_HOURS_LINE_SPACING_CHANGED]: makeTextStylePayloadToPropReducer("lineHeight"),
        [actionTypes.OPENING_HOURS_DAYS_BOLD_CHANGED]: makeTextStylePayloadToPropReducer("daysBold"),
        [actionTypes.OPENING_HOURS_DAYS_ITALIC_CHANGED]: makeTextStylePayloadToPropReducer("daysItalic"),
        [actionTypes.OPENING_HOURS_DAYS_UNDERLINE_CHANGED]: makeTextStylePayloadToPropReducer("daysUnderline"),
        [actionTypes.OPENING_HOURS_DAYS_LOWERCASE_BTN_CLICKED]: component => {
            return {
                ...component,
                textStyle: { ...component.textStyle, daysCase: component.textStyle.daysCase === "lowercase" ? null : "lowercase" }
            };
        },
        [actionTypes.OPENING_HOURS_DAYS_UPPERCASE_BTN_CLICKED]: component => {
            return {
                ...component,
                textStyle: { ...component.textStyle, daysCase: component.textStyle.daysCase === "uppercase" ? null : "uppercase" }
            };
        },
        [actionTypes.OPENING_HOURS_HOURS_BOLD_CHANGED]: makeTextStylePayloadToPropReducer("hoursBold"),
        [actionTypes.OPENING_HOURS_HOURS_ITALIC_CHANGED]: makeTextStylePayloadToPropReducer("hoursItalic"),
        [actionTypes.OPENING_HOURS_HOURS_UNDERLINE_CHANGED]: makeTextStylePayloadToPropReducer("hoursUnderline"),
        [actionTypes.OPENING_HOURS_HOURS_LOWERCASE_BTN_CLICKED]: component => {
            return {
                ...component,
                textStyle: { ...component.textStyle, hoursCase: component.textStyle.hoursCase === "lowercase" ? null : "lowercase" }
            };
        },
        [actionTypes.OPENING_HOURS_HOURS_UPPERCASE_BTN_CLICKED]: component => {
            return {
                ...component,
                textStyle: { ...component.textStyle, hoursCase: component.textStyle.hoursCase === "uppercase" ? null : "uppercase" }
            };
        },
        [actionTypes.OPENING_HOURS_WEEK_STARTS_FROM_CHANGED]: makePayloadToPropReducer("startDayOfTheWeek"),
        [actionTypes.OPENING_HOURS_HOUR_FORMAT_CHANGED]: makePayloadToPropReducer("hourFormat"),
        [actionTypes.OPENING_HOURS_GROUPING_CHANGED]: makePayloadToPropReducer("groupDays"),
        [TOGGLE_MOBILE_DOWN_ACTION]: mobileDownReducer
    } as Record<string, (c: OpeningHoursComponent, a: any) => OpeningHoursComponent>
});
