import type { MaxMultiSelectValidation } from "../../../view/common/FileChooser/flowTypes";
import type { FcContentTypePattern } from "../../../redux/modules/children/fileChooser/utils/testFcContentType";
import { openDialog } from "../../App/actionCreators/index";
import { FM_DIALOG_ID } from "../dialog/dialogId";
import { imageChooserDialogDefaultProps } from "./constants";

export type ImageChooserDialogProps = {
    onSaveAction: string,
    preSelection?: null | undefined | string,
    extraImageContentTypes?: FcContentTypePattern,
    isMultiSelect?: boolean,
    maxMultiSelectValidation?: MaxMultiSelectValidation,
    forwardToComponent?: boolean,
    headlessMode?: true,
    hideFreeOneComImagesTab?: boolean,
    hideShutterstockImagesTab?: true,
    hideMyVideosTab?: boolean
};

export const openImageChooserAction = (props: ImageChooserDialogProps) => openDialog(FM_DIALOG_ID, {
    ...imageChooserDialogDefaultProps,
    ...props,
    hideMyVideosTab: true
});
