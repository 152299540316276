
import { withSelector, receiveOnly } from '../../../../../epics/makeCondition';
import valueActionType from './valueActionType';
import type { TemplateComponent } from "../../flowTypes";
import { withPayload } from "../../../../../../utils/action";
import { getDefultLocaleId } from "../../../../Locale/getDefaultLocale";

const DEFAULT_LOCALE = getDefultLocaleId();

const
    templateWidthSelector = (state: TemplateComponent) => state.width,
    templateSelectedThemeSelector = (state: TemplateComponent) => state.selectedTheme,
    templateIdSelector = (state: TemplateComponent) => state.id,
    templateShowShopStripInFooterSelector = (state: TemplateComponent) => state.showShopStripInFooter,
    templateLocale2Selector = ({ locale }: TemplateComponent) => (locale || DEFAULT_LOCALE).substring(0, 2),
    TemplateWidthActionType = withSelector(valueActionType, templateWidthSelector),
    TemplateSelectedThemeActionType = withSelector(valueActionType, templateSelectedThemeSelector),
    ReceiveOnlyTemplateSelectedThemeActionType = receiveOnly(valueActionType, templateSelectedThemeSelector),
    Locale2Selector = withSelector(valueActionType, templateLocale2Selector),
    TemplateIdSelector = withSelector(valueActionType, templateIdSelector),
    ReceiveOnlyTemplateWidthActionType = receiveOnly(valueActionType, templateWidthSelector),
    ReceiveOnlyTemplateShowShopStripInFooterActionType = receiveOnly(valueActionType, templateShowShopStripInFooterSelector),
    ReceiveOnlyTemplateWidthFromFullActionSelector = receiveOnly(valueActionType, withPayload(templateWidthSelector));

export {
    TemplateWidthActionType,
    TemplateSelectedThemeActionType,
    ReceiveOnlyTemplateSelectedThemeActionType,
    ReceiveOnlyTemplateWidthActionType,
    ReceiveOnlyTemplateWidthFromFullActionSelector,
    ReceiveOnlyTemplateShowShopStripInFooterActionType,
    TemplateIdSelector,
    Locale2Selector,
    templateLocale2Selector,
};
