import React from 'react';
import { ReviewViewType } from '../../constants';
import ReviewCard from '../ReviewCard/ReviewCard';
import * as styles from '../Review/Review.css';

/*
1 : 100
2 : 50 50
3 : 100/3 100/3 100/3
4 : 50 50 50 50
5 : 100/3 100/3 100/3 50 50
6 : 100/3 100/3 100/3 100/3 100/3 100/3
*/

const getCardWidth = ({ type, cardIndex, totalCards }) => {
    if ([ReviewViewType.LIST, ReviewViewType.SLIDER].includes(type)) return '100%';
    const CARDS_PER_ROW = 3, MAX_WIDTH = 100;
    if (totalCards === 4) {
        return `${MAX_WIDTH / 2}%`;
    }
    if (totalCards <= CARDS_PER_ROW) {
        return `${MAX_WIDTH / totalCards}%`;
    }
    if (totalCards > CARDS_PER_ROW) {
        if ([1, 2, 3].includes(cardIndex) || totalCards === 6) {
            return `${MAX_WIDTH / CARDS_PER_ROW}%`;
        }
        return `${MAX_WIDTH / 2}%`;
    }
    return `100%`;
};

const generateReviewCards = ({ type, reviews, seeMoreText, seeLessText, reviewTimeText }) => {
    const totalReviews = reviews.length;
    return reviews.map(({ reviewId, displayName, profilePhotoUrl, starRating, comment, updateTime, reviewUrl }, index) =>
        (
            <ReviewCard
                key={reviewId}
                layoutType={type}
                name={displayName}
                profilePhoto={profilePhotoUrl}
                rating={starRating}
                comment={comment}
                date={updateTime}
                reviewUrl={reviewUrl}
                showReadMore
                cardWidth={getCardWidth({ type, cardIndex: index + 1, totalCards: totalReviews })}
                seeMoreText={seeMoreText}
                seeLessText={seeLessText}
                reviewTimeText={reviewTimeText}
            />
        ));
};

const ReviewGrid = ({ type, reviews, seeMoreText, seeLessText, reviewTimeText }) => (
    <div className={styles.gridLayout}>
        {generateReviewCards({ type, reviews, seeMoreText, seeLessText, reviewTimeText })}
    </div>
);

export default ReviewGrid;
