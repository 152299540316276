import * as actionTypes from "./actionTypes";

export const
    tableTabLabel = 'msg: common.gs.tab.table.label {Table}',
    types = {
        NORMAL: 'normal',
        HEADING1: 'heading.1',
        HEADING2: 'heading.2',
        ALTERNATE: 'alternate'
    },
    globalStyleLabels = {
        [types.NORMAL]: 'msg: common.gs.tableStyle.normal {Normal}',
        [types.HEADING1]: 'msg: common.gs.tableStyle.heading1 {Heading 1}',
        [types.HEADING2]: 'msg: common.gs.tableStyle.heading2 {Heading 2}',
        [types.ALTERNATE]: 'msg: common.gs.tableStyle.alternate {Alternate}'
    },
    MobileMaxFontSize = {
        [types.NORMAL]: 20,
        [types.HEADING1]: 40,
        [types.HEADING2]: 28,
        [types.ALTERNATE]: 24
    },
    FontFamilyChangeActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_FONT_FAMILY_CHANGED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_FONT_FAMILY_CHANGED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_FONT_FAMILY_CHANGED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_FONT_FAMILY_CHANGED
    },
    FontSizeChangeActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_FONT_SIZE_CHANGED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_FONT_SIZE_CHANGED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_FONT_SIZE_CHANGED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_FONT_SIZE_CHANGED
    },
    ToggleBoldActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_TOGGLE_BOLD,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_TOGGLE_BOLD,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_TOGGLE_BOLD,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_TOGGLE_BOLD
    },
    ToggleItalicActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_TOGGLE_ITALIC,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_TOGGLE_ITALIC,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_TOGGLE_ITALIC,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_TOGGLE_ITALIC
    },
    ToggleUnderlineActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_TOGGLE_UNDERLINE,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_TOGGLE_UNDERLINE,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_TOGGLE_UNDERLINE,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_TOGGLE_UNDERLINE
    },
    HighlightColorChangeActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_HIGHLIGHT_COLOR_CHANGED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_HIGHLIGHT_COLOR_CHANGED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_HIGHLIGHT_COLOR_CHANGED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_HIGHLIGHT_COLOR_CHANGED
    },
    HighlightColorRemoveActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_HIGHLIGHT_COLOR_REMOVED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_HIGHLIGHT_COLOR_REMOVED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_HIGHLIGHT_COLOR_REMOVED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_HIGHLIGHT_COLOR_REMOVED
    },
    FontColorChangeActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_FONT_COLOR_CHANGED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_FONT_COLOR_CHANGED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_FONT_COLOR_CHANGED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_FONT_COLOR_CHANGED
    },
    FontShadowColorChangeActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_FONT_SHADOW_COLOR_CHANGED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_FONT_SHADOW_COLOR_CHANGED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_FONT_SHADOW_COLOR_CHANGED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_FONT_SHADOW_COLOR_CHANGED
    },
    FontShadowColorRemoveActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_FONT_SHADOW_COLOR_REMOVED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_FONT_SHADOW_COLOR_REMOVED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_FONT_SHADOW_COLOR_REMOVED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_FONT_SHADOW_COLOR_REMOVED
    },
    FontShadowBlurRadiusChangeActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_FONT_SHADOW_BLUR_RADIUS_CHANGED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_FONT_SHADOW_BLUR_RADIUS_CHANGED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_FONT_SHADOW_BLUR_RADIUS_CHANGED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_FONT_SHADOW_BLUR_RADIUS_CHANGED
    },
    FontShadowHorizontalOffsetChangeActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_FONT_SHADOW_HORIZONTAL_OFFSET_CHANGED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_FONT_SHADOW_HORIZONTAL_OFFSET_CHANGED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_FONT_SHADOW_HORIZONTAL_OFFSET_CHANGED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_FONT_SHADOW_HORIZONTAL_OFFSET_CHANGED
    },
    FontShadowVerticalOffsetChangeActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_FONT_SHADOW_VERTICAL_OFFSET_CHANGED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_FONT_SHADOW_VERTICAL_OFFSET_CHANGED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_FONT_SHADOW_VERTICAL_OFFSET_CHANGED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_FONT_SHADOW_VERTICAL_OFFSET_CHANGED
    },
    HorizontalAlignmentChangeActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_HORIZONTAL_ALIGNMENT_CHANGED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_HORIZONTAL_ALIGNMENT_CHANGED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_HORIZONTAL_ALIGNMENT_CHANGED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_HORIZONTAL_ALIGNMENT_CHANGED
    },
    VerticalAlignmentChangeActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_VERTICAL_ALIGNMENT_CHANGED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_VERTICAL_ALIGNMENT_CHANGED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_VERTICAL_ALIGNMENT_CHANGED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_VERTICAL_ALIGNMENT_CHANGED
    },
    BackgroundColorChangeActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_BACKGROUND_COLOR_CHANGED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_BACKGROUND_COLOR_CHANGED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_BACKGROUND_COLOR_CHANGED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_BACKGROUND_COLOR_CHANGED
    },
    BackgroundColorRemoveActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_BACKGROUND_COLOR_REMOVED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_BACKGROUND_COLOR_REMOVED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_BACKGROUND_COLOR_REMOVED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_BACKGROUND_COLOR_REMOVED
    },
    BackgroundGradientColorChangeActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_BACKGROUND_GRADIENT_COLOR_CHANGED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_BACKGROUND_GRADIENT_COLOR_CHANGED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_BACKGROUND_GRADIENT_COLOR_CHANGED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_BACKGROUND_GRADIENT_COLOR_CHANGED
    },
    BackgroundGradientColorRemoveActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_BACKGROUND_GRADIENT_COLOR_REMOVED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_BACKGROUND_GRADIENT_COLOR_REMOVED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_BACKGROUND_GRADIENT_COLOR_REMOVED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_BACKGROUND_GRADIENT_COLOR_REMOVED
    },
    BackgroundGradientDirectionChangeActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_BACKGROUND_GRADIENT_DIRECTION_CHANGED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_BACKGROUND_GRADIENT_DIRECTION_CHANGED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_BACKGROUND_GRADIENT_DIRECTION_CHANGED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_BACKGROUND_GRADIENT_DIRECTION_CHANGED
    },
    BackgroundGradientFadePointChangeActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_BACKGROUND_GRADIENT_FADEPOINT_CHANGED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_BACKGROUND_GRADIENT_FADEPOINT_CHANGED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_BACKGROUND_GRADIENT_FADEPOINT_CHANGED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_BACKGROUND_GRADIENT_FADEPOINT_CHANGED
    },
    BackgroundOpacityChangeAction = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_BACKGROUND_OPACITY_CHANGED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_BACKGROUND_OPACITY_CHANGED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_BACKGROUND_OPACITY_CHANGED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_BACKGROUND_OPACITY_CHANGED
    },
    BorderStyleChangeActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_BORDER_STYLE_CHANGED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_BORDER_STYLE_CHANGED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_BORDER_STYLE_CHANGED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_BORDER_STYLE_CHANGED
    },
    BorderColorChangeActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_BORDER_COLOR_CHANGED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_BORDER_COLOR_CHANGED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_BORDER_COLOR_CHANGED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_BORDER_COLOR_CHANGED
    },
    BorderColorOpacityChangeActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_BORDER_COLOR_OPACITY_CHANGED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_BORDER_COLOR_OPACITY_CHANGED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_BORDER_COLOR_OPACITY_CHANGED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_BORDER_COLOR_OPACITY_CHANGED
    },
    BackgroundAssetChangeAction = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_BACKGROUND_ASSET_CHANGED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_BACKGROUND_ASSET_CHANGED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_BACKGROUND_ASSET_CHANGED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_BACKGROUND_ASSET_CHANGED
    },
    BackgroundAssetRemoveAction = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_BACKGROUND_ASSET_REMOVED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_BACKGROUND_ASSET_REMOVED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_BACKGROUND_ASSET_REMOVED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_BACKGROUND_ASSET_REMOVED
    },
    BackgroundAssetRepeatChangeAction = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_BACKGROUND_ASSET_REPEAT_CHANGED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_BACKGROUND_ASSET_REPEAT_CHANGED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_BACKGROUND_ASSET_REPEAT_CHANGED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_BACKGROUND_ASSET_REPEAT_CHANGED
    },
    BackgroundAssetPositionChangeAction = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_BACKGROUND_ASSET_POSITION_CHANGED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_BACKGROUND_ASSET_POSITION_CHANGED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_BACKGROUND_ASSET_POSITION_CHANGED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_BACKGROUND_ASSET_POSITION_CHANGED
    },
    BackgroundAssetSizeChangeAction = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_BACKGROUND_ASSET_SIZE_CHANGED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_BACKGROUND_ASSET_SIZE_CHANGED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_BACKGROUND_ASSET_SIZE_CHANGED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_BACKGROUND_ASSET_SIZE_CHANGED
    },
    BorderWidthChangeActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_BORDER_WIDTH_CHANGED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_BORDER_WIDTH_CHANGED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_BORDER_WIDTH_CHANGED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_BORDER_WIDTH_CHANGED
    },
    CellSpacingChageActions = {
        [types.NORMAL]: actionTypes.GS_TABLE_NORMAL_CELL_SPACING_CHANGED,
        [types.HEADING1]: actionTypes.GS_TABLE_HEADING1_CELL_SPACING_CHANGED,
        [types.HEADING2]: actionTypes.GS_TABLE_HEADING2_CELL_SPACING_CHANGED,
        [types.ALTERNATE]: actionTypes.GS_TABLE_ALTERNATE_CELL_SPACING_CHANGED
    };
