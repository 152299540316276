import type { AppStore } from "../../../redux/modules/flowTypes";
import { globalStyleSelectedAction } from "./actions";
import { GLOBAL_STYLE_SELECTED } from "./actionTypes";

export const globalStyleSelectorMiddleware = (store: AppStore) => (next: Dispatch) => (action: AnyAction) => {
    if (action.type === GLOBAL_STYLE_SELECTED) {
        const { payload: { onChangeAction, stylesheetId, stylesheetName }, forwardTo } = action;
        store.dispatch(globalStyleSelectedAction(onChangeAction, { stylesheetId, stylesheetName }, forwardTo));
    }
    return next(action);
};
