/* eslint-disable max-len */

import { ERROR_STATES } from "../../CreateAIText/constants";

import styles from "./styles.css";

export const rephraseBtn = {
    id: 'rephrase',
    label: 'msg: common.rewrite {Rewrite}',
    disableTooltip: 'msg: component.text.editAIText.dialog.textPrompt.textLimitExceededMsg {The instruction you entered is too long. Shorten it to continue.}'
};

export const makeShorterBtn = {
    id: 'makeShorter',
    label: 'msg: common.makeShorter {Make shorter}',
    disableTooltip: 'msg: component.text.editAIText.dialog.makeShorter.textLimitExceededMsg {Minimum length reached. Cannot make text shorter.}'
};

export const makeLongerBtn = {
    id: 'makeLonger',
    label: 'msg: common.makeLonger {Make longer}',
    disableTooltip: 'msg: component.text.editAIText.dialog.makeLonger.textLimitExceededMsg {Maximum length reached. Cannot make text longer.}'
};

export const errorMessageIds = {
    LONG_TEXT: "LONG_TEXT",
    TEXT_LENGTH_LIMIT: "TEXT_LENGTH_LIMIT",
    ...ERROR_STATES
};
export const REQUIRED_FIELDS = {
    agreeTerms: 'agreeTerms'
};
export const errorTypes = {
    error: 'error',
    warning: 'warning'
};

export const ERROR_MESSAGES = {
    [errorMessageIds.LONG_TEXT]: {
        iconClass: styles.longTextIcon,
        title: 'msg: common.cannotMakeChanges {Cannot make changes}',
        description: 'msg: component.text.editingAssistant.warning.longText {This text is too long to make further changes. Shorten it in the editor and try again.}',
        type: errorTypes.warning
    },
    [errorMessageIds.TEXT_LENGTH_LIMIT]: {
        iconClass: styles.longTextIcon,
        title: 'msg: common.cannotMakeChanges {Cannot make changes}',
        description: 'msg: component.text.editingAssistant.warning.textLengthLimit {With this change, the text would exceed our maximum length limit. Please adjust your instructions.}',
        type: errorTypes.warning
    },
    [errorMessageIds.CONNECTION_ERROR]: {
        iconClass: styles.connectionErrorIcon,
        title: 'msg: component.text.createAIText.dialog.connectionError.content {Connection error}',
        description: 'msg: component.text.createAIText.dialog.connectionError.subContent {Unable to connect to OpenAI at the moment. Please try again later.}',
        type: errorTypes.error
    },
    [errorMessageIds.INNAPPROPRIATE_LANGUAGE_ERROR]: {
        iconClass: styles.profaneLanguageIcon,
        title: 'msg: component.text.createAIText.dialog.error.unableToCreate {Unable to create text}',
        description: 'msg: component.text.editAIText.dialog.warning.inappropriateLanguageError.subContent {Your instruction doesn’t follow OpenAI’s content guidelines. Check if it might contain any inappropriate or harmful language and rewrite it to try again.}',
        type: errorTypes.warning
    },
    [errorMessageIds.DAILY_LIMIT_ERROR]: {
        iconClass: styles.limitError,
        title: 'msg: component.text.createAIText.dialog.error.unableToCreate {Unable to create text}',
        description: 'msg: component.text.editAIText.dialog.warning.dailyLimit.subContent {You’ve reached your maximum usage limit for today. Please try again tomorrow.}',
        type: errorTypes.warning
    },
    [errorMessageIds.HIGH_TRAFFIC_ERROR]: {
        iconClass: styles.highTrafficErrorIcon,
        title: 'msg: component.text.createAIText.dialog.error.unableToCreate {Unable to create text}',
        description: 'msg: component.text.createAIText.dialog.error.highTrafficError.subContent {Due to high traffic at OpenAI, we can\'t process your request. Try again in a few minutes.}',
        type: errorTypes.warning
    }
};

export const MAX_PROMPT_LENGTH = 150;
export const MAX_TEXT_LENGTH = 3200;
export const MAX_INPUT_TEXT_LIMIT = 3500;
export const MIN_WORD_LENGTH = 3;
