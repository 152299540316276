import * as actionTypes from "./actions";
import Resource from "../../redux/modules/children/fileChooser/Resource";

export type FileUploadInit = Record<string, any> & {
    isMultiSelect: boolean,
    contentTypes: string | Array<string>,
    files: FileList
}

export const
    fileUploadCompleteAction = (files: Array<Resource>) => ({
        type: actionTypes.FU_UPLOAD_COMPLETED,
        payload: { files }
    }),
    uploadFilesAction = (config: FileUploadInit) => ({
        type: actionTypes.FU_UPLOAD_FILES,
        payload: config
    }),
    uploadResourceStatusAction = (type: string, resources: Array<Resource>) => ({
        type,
        payload: { resources }
    });

