// @ts-nocheck
import backgroundViewFactory from "./view/backgroundViewFactory";
import kind from "./kind";
import calcRenderProps from "./calcRenderProps";
import * as styles from './view/BackgroundPreview.css';
import { makeGetBackgroundSpecificStyles } from './makeGetBackgroundSpecificStyles';
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";
import type { BackgroundComponent } from './flowTypes';
import type { CalcProps } from "../../Preview/flowTypes";

export const componentDependencies = {
    missingAssetUrls: [],
    workspaceBBoxWidth: 100,
    colorPickerOpened: false
};

export default {
    kind,
    view: backgroundViewFactory(styles),
    calcProps: ({
        component, siteSettings: { themeSettingsData }, globalStyles, paddingBottom
    }: CalcProps<BackgroundComponent>) => {
        return calcRenderProps({
            component,
            paddingBottom,
            componentDependencies: {
                ...componentDependencies, themeSettingsData, themeColorsData: getThemeColorsFromStylesheet(globalStyles)
            }
        });
    },
    getSpecificStyles: makeGetBackgroundSpecificStyles()
};
