import type { Element } from '../../flowTypes';

const extractLeaves = (elements: Element[]) => {
    const getParentNodeIndex = i => {
        const current = elements[i];
        while (elements[--i] && elements[i].end <= current.end); //eslint-disable-line
        return i;
    };

    const leaves: number[][] = [];
    const closeParentNodes = (currentIndex: number, nextElement: Element) => {
        let parentIndex = getParentNodeIndex(currentIndex);
        while (elements[parentIndex]) {
            if (elements[parentIndex].end <= nextElement.start) {
                // G and H are extracted here
                leaves.push([elements[currentIndex].end, elements[parentIndex].end]);
            } else {
                // E is extracted here
                leaves.push([elements[currentIndex].end, nextElement.start]);
                break;
            }

            currentIndex = parentIndex; // eslint-disable-line
            parentIndex = getParentNodeIndex(parentIndex);
        }
    };

    elements.forEach((current, i) => {
        // <p><span><br/></span><p>
        if (current.start === current.end) {
            const lastLeaf = leaves[leaves.length - 1];
            // Should create an entry when p is encountered and ignore for span
            if (!lastLeaf || lastLeaf[1] !== current.start) {
                leaves.push([current.start, current.end]);
            }
            return;
        }

        const next = elements[i + 1] || { start: Infinity };
        if (current.end === next.start) {
            // C and K are extracted here
            leaves.push([current.start, current.end]);
        } else if (current.end < next.start) {
            // D, F, IJ and L are extracted here
            leaves.push([current.start, current.end]);
            closeParentNodes(i, next);
        } else if (current.start < next.start) {
            // A and B are extracted here
            leaves.push([current.start, next.start]);
        }
    });

    return leaves;
};

/* Consider following html, every extracted leaf contains a comment informing which part is extracted
    <p>
        A
        <span>
            B
            <span>C</span>
            <span>D</span>
            E
            <span>F</span>
            G
        </span>
        H
    </p>
    <p>
        <span><br/></span>
    </p>
    <p>IJ</p>
    <p>
        <span>K</span>
        <span>L</span>
    </p>
*/
export default (elements: Array<Element>, content: string) => {
    const atype = 'text';
    const leaves = extractLeaves(elements);

    return leaves.map(([start, end]) => {
        const text = content.slice(start, end);
        return { atype, start, end, text };
    });
};
