export const
    BUTTON_SET_LINK = 'BUTTON_SET_LINK',
    BUTTON_REMOVE_LINK = 'BUTTON_REMOVE_LINK',
    BUTTON_GLOBAL_STYLE_UPDATED = 'BUTTON_GLOBAL_STYLE_UPDATED',
    BUTTON_PROP_PANEL_BUTTON_GLOBAL_STYLE_BTN_PRESSED = 'BUTTON_PROP_PANEL_BUTTON_GLOBAL_STYLE_BTN_PRESSED',
    BUTTON_MCTA_CHANGE_TEXT = 'BUTTON_MCTA_CHANGE_TEXT',
    BUTTON_MCTA_CHANGE_FONT_SIZE = 'BUTTON_MCTA_CHANGE_FONT_SIZE',
    BUTTON_MCTA_CHANGE_SIZE = 'BUTTON_MCTA_CHANGE_SIZE',
    BUTTON_UPDATE_COMPONENT = 'BUTTON_UPDATE_COMPONENT',
    BUTTON_UPDATE_COMPONENT_ON_RESIZE = 'BUTTON_UPDATE_COMPONENT_ON_RESIZE',
    BUTTON_CHANGE_LINK = 'BUTTON_CHANGE_LINK',
    BUTTON_THEME_SELECTED = 'BUTTON_THEME_SELECTED';
