import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Lottie from "react-web-lottie";

import animationData from "./animation.json";
import { getStepMarkers, LOTTIE_DEFAULT_OPTIONS } from "../../../view/LottieAnimations/constant";

import { WIZARD_STEP_ID } from "../../constants";
import { STEP_MARKERS } from "./constants";

import { animationDataSelector } from "../../Epic/selectors";

const markers = animationData.markers;
const stepMarkers = getStepMarkers(markers, WIZARD_STEP_ID.DESCRIPTION, STEP_MARKERS[WIZARD_STEP_ID.DESCRIPTION]);

export const KeywordsAnimation = () => {
    const { keywords } = useSelector(animationDataSelector);
    const animationRef = useRef(null);

    const [startPoint, setStartPoint] = useState(stepMarkers.business_name_appears);
    const [stopPoint, setStopPoint] = useState(stepMarkers.description_start_point);

    const initialSegment = [startPoint, stopPoint];

    const defaultOptions = {
        ...LOTTIE_DEFAULT_OPTIONS,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid meet"
        },
        initialSegment,
        animationData
    };

    useEffect(() => {
        // @ts-ignore
        animationRef.current.playSegments([[startPoint, stopPoint]], true);

        const keywordsCount = keywords ? keywords.split(',').filter(e => e.replace(/\s/g, '')).length : 0;

        if (keywordsCount === 0) {
            if (stopPoint !== stepMarkers.description_start_point) {
                setStartPoint(stopPoint);
                setStopPoint(stepMarkers.description_start_point);
            }
        } else if (keywordsCount === 1) {
            if (stopPoint !== stepMarkers.description_one_word_entered) {
                setStartPoint(stopPoint);
                setStopPoint(stepMarkers.description_one_word_entered);
            }
            setStartPoint(stepMarkers.description_one_word_entered);
            setStopPoint(stepMarkers.description_one_word_entered + 1);
        } else if (keywordsCount === 2) {
            if (stopPoint !== stepMarkers.description_two_words_entered) {
                setStartPoint(stopPoint);
                setStopPoint(stepMarkers.description_two_words_entered);
            }
            setStartPoint(stepMarkers.description_two_words_entered);
            setStopPoint(stepMarkers.description_two_words_entered + 1);
        } else if (keywordsCount > 2) {
            if (stopPoint !== stepMarkers.description_end_point) {
                setStartPoint(stopPoint);
                setStopPoint(stepMarkers.description_end_point);
            }
            setStartPoint(stepMarkers.description_end_point);
            setStopPoint(stepMarkers.description_end_point + 1);
        }
    }, [keywords]);

    useEffect(() => {
        // @ts-ignore
        animationRef.current.playSegments([[startPoint, stopPoint]], true);
    }, [startPoint, stopPoint]);

    return (
        <Lottie lottiRef={animationRef} options={defaultOptions} isClickToPauseDisabled />
    );
};
