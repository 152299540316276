import { injectIntl } from '../../../../view/intl/index';
import { WIDGETS_TIKTOK_SHOW_UPDATE_LINK_DIALOG } from './actionTypes';
import { ctaOnClickFactory, linkExtractor } from '../utils';
import { isVideo } from './utils/parseTiktokCode';

const CtaButtonTextView = injectIntl((props) => {
    const { selectedComponent: { link }, intl } = props;
    if (isVideo(link)) {
        return intl.msgJoint("msg: widgets.tiktok.cta.changeVideo {Change video}");
    } else {
        return intl.msgJoint("msg: widgets.tiktok.cta.changeProfile {Change profile}");
    }
});

const componentMainActions = {
    editButtonText: 'msg: common.settings {Settings}',
    CtaButtonTextView,
    ctaOnClick: ctaOnClickFactory(
        WIDGETS_TIKTOK_SHOW_UPDATE_LINK_DIALOG,
        component => ({ link: linkExtractor(component) })
    ),
};

export { componentMainActions };

