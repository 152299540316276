import React from 'react';
import styles from './ProgressDialog.css';
import { Dialog, DialogTitleBox, DialogBody } from '../../../../view/common/dialogs/baseDialog';
import getCenteredDialogConfig from "../../../DialogManager/getCenteredDialogConfig";
import type { MsgJointInput } from "../../../../view/intl/injectIntl";
import LoadingIndicator from '../../../../view/common/LoadingIndicator';

const
    width = 490,
    height = 265;

type Props = {
    title: MsgJointInput,
    msg: MsgJointInput,
};

const ProgressDialog = (props: Props) => {
    const { title, msg } = props;
    return (
        <Dialog>
            <DialogTitleBox
                title={title}
                subTitle={msg}
            />
            <DialogBody>
                <LoadingIndicator className={styles.loadingIndicator} center={false} />
            </DialogBody>
        </Dialog>
    );
};

export default getCenteredDialogConfig(width, height, ProgressDialog);
