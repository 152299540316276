import React from 'react';
import { Dispatch } from "redux";
import styles from "./AddDecorations.css";
import * as HandleKinds from "../../../utils/handle/kinds";
import { getHandlesOfKindBBox } from "./ResizingDots";
import { NoMouseDownAndUp } from "../../common/NoMouseEventsPropagation";
import {
    IDLE,
    MOUSE_DOWN_ON_HANDLE
} from "../../../components/Workspace/epics/componentsEval/userInteractionMutations/interactionModes";
import type { Handles } from "../../../redux/modules/children/workspace/flowTypes";
import { addSectionAction } from "../../../components/Workspace/epics/componentsEval/actionCreators/section";
import Msg from "../../intl/Msg";

type Props = {
    dispatch: Dispatch,
    handles: Handles,
    interactionMode: string
}

type ViewProps = {
    onClick: React.MouseEventHandler<HTMLDivElement>,
    style: Record<string, any>,
    interactionMode: string,
};
type State = {
    width: number
}
const ShowAddIconsForModes = [IDLE, MOUSE_DOWN_ON_HANDLE];

class View extends React.Component<ViewProps, State> {
    containerRef = React.createRef<HTMLDivElement>();
    labelWidth = 0;
    containerWidth = 24;
    state = { width: 24 };
    componentDidMount() {
        const $container = this.containerRef.current;
        if ($container) {
            const $label = $container.querySelector(`.${styles.label}`);
            // @ts-ignore
            this.labelWidth = $label ? $label.offsetWidth : 0;
        }
    }

    mouseLeave = () => this.setState({
        width: this.containerWidth
    });

    mouseEnter = () => this.setState({
        width: this.containerWidth + this.labelWidth
    })

    render() {
        const { style, onClick, interactionMode } = this.props,
            { width } = this.state;
        if (interactionMode && !ShowAddIconsForModes.includes(interactionMode)) {
            return null;
        }
        return <div
            className={styles.addIconWrapper}
            style={style}
        >
            <div
                className={styles.controller}
                style={{ width }}
                onMouseEnter={this.mouseEnter}
                onMouseLeave={this.mouseLeave}
                {...NoMouseDownAndUp}
            >
                <div
                    ref={this.containerRef}
                    onClick={onClick}
                    className={styles.addIconContainer}
                >
                    <span className={styles.addIcon} />
                    <span className={styles.label}>
                        <Msg k="common.section.addSection.label">Add section</Msg>
                    </span>
                </div>
            </div>
        </div>;
    }
}

const AddIcon = ({
    dispatch,
    handles,
    interactionMode
}: Props) => {
    if (!ShowAddIconsForModes.includes(interactionMode)) {
        return null;
    }
    const
        addHandles = [HandleKinds.AddIconN, HandleKinds.AddIconS]
            .map(handleKind => {
                const
                    handlesOfKind = handles.filter(({ kind }) => kind === handleKind);

                if (!handlesOfKind.length) {
                    return null;
                }

                const handlesOfKindBBox = getHandlesOfKindBBox(handlesOfKind),
                    [sectionId] = handlesOfKind[0].componentsIds,
                    left = handlesOfKindBBox.left,
                    top = handlesOfKindBBox.top,
                    handleBoxStyle = { left, top: top > 0 ? top : 0 };

                const onClick = () => {
                    dispatch(addSectionAction(sectionId, handleKind === HandleKinds.AddIconN));
                };

                return <View
                    onClick={onClick}
                    style={handleBoxStyle}
                    key={handleKind}
                    interactionMode={interactionMode}
                />;
            });

    return (
        <div>
            {addHandles}
        </div>
    );
};

export {
    AddIcon,
    View as AddIconView
};
