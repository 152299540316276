import * as R from 'ramda';

export const
    makeLens = R.lensProp,
    scopeL = makeLens('scope'),
    addComponentLens = R.compose(
        scopeL,
        makeLens('addingComponent')
    ),
    lastClickedTimeL = R.compose(
        addComponentLens,
        makeLens('lastClickedTime')
    ),
    startMovingAfterTimeStampL = R.compose(
        scopeL,
        makeLens('startMovingAfterTimeStamp')
    );
