import React from "react";
import { useDispatch } from "react-redux";
import { Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useIntl } from "../../../../../../../../wbtgen/src/view/intl";
import { gmbConnectListingButtonAction, gmbCreateListingButtonAction } from "../../redux/actions";

const useStyles = makeStyles(
    theme => ({
        root: {
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            gap: "20px",
            [theme.breakpoints.up("sm")]: {
                height: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                alignContent: "center",
                gap: "24px",
                flexWrap: "wrap",
            },
            [theme.breakpoints.up("md")]: {
                justifyContent: "center"
            }
        },
        // actionButton: {
        //     fontSize: "16px",
        //     lineHeight: "20px",
        //     padding: "14px 30px",
        //     [theme.breakpoints.up("sm")]: {
        //         fontSize: "14px",
        //         lineHeight: "22px",
        //         padding: "8px 30px"
        //     }
        // }
    }),
    { name: "GMBSettingsGetStarted" }
);

export const GMBGetStartedActions = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleConnect = () => {
        dispatch(gmbConnectListingButtonAction());
    };

    const handleCreate = () => {
        dispatch(gmbCreateListingButtonAction());
    };

    return (
        <Box className={classes.root} data-testid="gmb-settings-details">
            <Button
                variant="outlined"
                color="primary"
                // className={classes.actionButton}
                onClick={handleConnect}
            >
                {intl.msgJoint("msg: gmb.connectAListing {Connect a listing}")}
            </Button>

            <Button
                variant="contained"
                color="primary"
                // className={classes.actionButton}
                onClick={handleCreate}
            >
                {intl.msgJoint("msg: gmb.createAListing {Create a listing}")}
            </Button>
        </Box>
    );
};
