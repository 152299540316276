import * as R from "ramda";
import type { AppState } from "../../../redux/modules/flowTypes";
import { ShutterstockKind } from "../shutterstock/constants";
import type { PurchaseImageBody, ImageDetails } from "./types";
import { shutterstockImagesAppSel, shutterstockSelectedImagesAppSel } from "../shutterstock/selectors";

export const
    getImageById = (id: string, appState: AppState): Array<ImageDetails> => {
        const images = shutterstockImagesAppSel(ShutterstockKind.PAID)(appState);
        const buyImage = images.find(image => image.id === id);

        if (buyImage) {
            return [{
                id: buyImage.id,
                name: buyImage.description,
                format: buyImage.assets.orig.format,
                size: "huge",
                width: buyImage.assets.orig.width,
                height: buyImage.assets.orig.height,
                fileSize: buyImage.assets.orig.fileSize
            }];
        }
        return [];
    },
    getSelectedImageDetails = (appState: AppState): Array<ImageDetails> => {
        const selectedImages = shutterstockSelectedImagesAppSel(ShutterstockKind.PAID)(appState);

        return R.map(image => ({
            id: image.imageId,
            name: image.description,
            format: image.format,
            size: "huge",
            width: image.width,
            height: image.height,
            fileSize: image.fileSize
        }))(selectedImages);
    },
    getPurchaseImageBody = (images: Array<ImageDetails>): Array<PurchaseImageBody> => images.map(image => ({
        id: image.id,
        name: image.name,
        format: image.format,
        size: image.size,
    }));
