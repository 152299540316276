import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(
    theme => ({
        root: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "stretch",
            flexDirection: "column",
            flexWrap: "wrap",
            gap: "16px",
            marginTop: "24px",
            [theme.breakpoints.up("sm")]: {
                display: "inline-flex",
                flexDirection: "row",
            },
        }
    }),
    { name: "SiteActions" }
);
