import { WidgetsGoFundMeKind } from './kind';
import { calcRenderProps } from './calcRenderProps';
import { GoFundMeView } from './view/index';
import { GoFundMeSDK } from './constants';

const WidgetsGoFundMePreviewConfig = {
    kind: WidgetsGoFundMeKind,
    view: GoFundMeView,
    calcProps: ({ component }) => calcRenderProps({
        component,
        componentExtension: { isTransient: false },
    }),
    js: [GoFundMeSDK],
};

export { WidgetsGoFundMePreviewConfig };
