import makeEpic from '../../../../../epics/makeEpic';
import { receiveOnly } from '../../../../../epics/makeCondition';
import valueActionType from "./valueActionType";
import { COMPONENTS_MAP_INITIALIZED } from "../../../../Workspace/epics/componentsEval/actionTypes";
import getOwnerEmails from '../../../../OwnerEmails/actionCreators/getOwnerEmailIds';
import { OWNER_EMAIL_IDS_FETCH_SUCCESS } from "../../../../OwnerEmails/actionTypes";
import kind from '../../kind';
import openDialog from "../../../../App/actionCreators/openDialog";
import closeDialog from "../../../../App/actionCreators/closeDialog";
import { MissingEmailWarningDialogId } from "../../dialogIds";
import { CONTACT_FORM_HANDLE_MISSING_EMAIL } from "../../actionTypes";
import { openWindowAction } from "../../../../../redux/middleware/window/actionCreators";
import { AppConfigSelector } from "../../../../App/epics/appConfig/selectors";
import { partnerIsOnecom } from "../../../../../../../server/shared/partners/PartnerName";
import { readWsbCustomerProfileCookiePayload } from '../../../../../utils/wsbCustomerProfile/wsbCustomerProfileUtils';
import { filterDefaultEmails } from "../../filterDefaultEmails";

const defaultState = { requestEmails: false, userEmail: null };

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                COMPONENTS_MAP_INITIALIZED,
                receiveOnly(AppConfigSelector('server.fetchOwnerInfo')),
            ],
            reducer: ({ values: [{ componentsMap, fetchOwnerInfo }] }) => {
                const contactFormId = Object.keys(componentsMap).filter(id => componentsMap[id].kind === kind)[0],
                    userEmail = contactFormId ? componentsMap[contactFormId].recipientEmail : "",
                    actionToDispatch = (fetchOwnerInfo && userEmail) ? getOwnerEmails() : null;

                return {
                    state: { requestEmails: !!userEmail, userEmail },
                    actionToDispatch
                };
            }
        },
        {
            conditions: [OWNER_EMAIL_IDS_FETCH_SUCCESS],
            reducer: ({ values: [response], state }) => {
                const { requestEmails, userEmail } = state;

                if (
                    requestEmails &&
                    filterDefaultEmails([userEmail]).length > 0 &&
                    (response.email.includes(userEmail) === false)
                ) {
                    return {
                        state: defaultState,
                        actionToDispatch: openDialog(MissingEmailWarningDialogId, { userEmail }),
                    };
                }

                return { state };
            }
        },
        {
            conditions: [
                AppConfigSelector("partnerName"),
                AppConfigSelector('oneWeb.contactForm.createEmailUrl'),
                CONTACT_FORM_HANDLE_MISSING_EMAIL
            ],
            reducer: ({ values: [partnerName, createEmailUrl], state }) => {
                let createEmailLink = createEmailUrl;

                if (partnerIsOnecom(partnerName) === false) {
                    const customerProfileData = readWsbCustomerProfileCookiePayload(),
                        { profileId } = customerProfileData || {};

                    createEmailLink = createEmailLink.replace("{profileId}", profileId);
                }

                return {
                    state,
                    multipleActionsToDispatch: [
                        openWindowAction(createEmailLink),
                        closeDialog()
                    ]
                };
            }
        }
    ]
});
