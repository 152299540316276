import kind from './kind';
import reducer from './reducer/index';
import view from './view/index';
import { ComponentNames } from "../constants";
import componentMainActions from './componentMainActions/index';
import previewConfig from './previewConfig';
import propertiesPanel from "./propertiesPanel/index";
import siteFontsReducer from '../../Fonts/siteFontsReducer';
import stylesheetsEpic from '../../Workspace/epics/stylesheets/index';
import * as actionTypes from './actionTypes';
import handleActions from '../../../redux/makeReducer/handleActions/index';
import fontStatusReducer from "../../Fonts/fontStatusReducer";
import { widthReducer as templateWidth } from "../Template/epics/template/index";
import stylesheetsIdToNameMapEpic from "../../Workspace/epics/stylesheets/idToNameMap";
import adjustComponentOnAdd from './adjustComponentOnAdd';
import adjustmentHookConfig from './adjustmentHookConfig';
import configurationDialog from './SelectorDialog/dialogId';
import calcRenderProps from "./calcRenderProps";
import { colorThemeSiteSettingsEpic } from "../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import cartDataEpic from "../WebShop/epics/cartGlobalData/index";
import { componentTierManagerEpic } from "../../ComponentTierManager/epics/index";
import selectedLanguageEpic from "../../TopBar/epics/languages/index";

export default {
    kind,
    label: ComponentNames[kind],
    dependsOn: {
        fontStatus: fontStatusReducer,
        localeDetails: selectedLanguageEpic.reducer,
        templateWidth,
        siteFonts: siteFontsReducer,
        globalStyles: stylesheetsEpic.reducer,
        themeSettingsData: colorThemeSiteSettingsEpic.reducer,
        temporaryHoverOpacity: handleActions({
            [actionTypes.FEATURED_PRODUCTS_HOVER_OPACITY_CHANGING]: (prevstate, { payload }) => payload,
            [actionTypes.FEATURED_PRODUCTS_HOVER_OPACITY_CHANGED]: () => null
        }, ''),
        stylesheetsIdToNameMap: stylesheetsIdToNameMapEpic.reducer,
        mobilePreview: handleActions({
            [actionTypes.FEATURED_PRODUCTS_UPDATE_MOBILE_PREVIEW]: (prevstate, { payload }) => {
                return (payload) ? payload.mobilePreview : false;
            }
        }, false),
        timestamp: handleActions({
            [actionTypes.FEATURED_PRODUCTS_UPDATE_TIMESTAMP]: (prevstate, { payload: { timestamp } }) => timestamp
        }, 0),
        shopComponentExists: handleActions({
            [actionTypes.FEATURED_PRODUCTS_UPDATE_SHOP_COMPONENT_EXISTS]: (prevstate, { payload }) => {
                return (payload) ? payload.shopComponentExists : false;
            }
        }, false),
        componentTierData: componentTierManagerEpic.reducer,
        isSubscriptionTypeCompatible: handleActions({
            [actionTypes.FEATURED_PRODUCTS_UPDATE_SUBSCRIPTION_COMPATIBILITY_STATUS]:
                (prevstate, { payload: { isSubscriptionTypeCompatible } }) => isSubscriptionTypeCompatible
        }, false),
    },
    view,
    calcRenderProps,
    controlsDependsOn: {
        cartData: cartDataEpic.reducer
    },
    adjustComponentOnAdd,
    reducer,
    shortcutIconName: 'webshop',
    requireConfigurationOnDrop: () => true,
    configurationDialog,
    mobileEditorConfig: previewConfig,
    propertiesPanel,
    componentMainActions,
    hideFromInserter: true,
    adjustmentHookConfig,
};
