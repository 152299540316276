import React from 'react';
import { connect } from 'react-redux';
import styles from './ErrorBoundary.css';
import type { AppState } from "../../modules/flowTypes";
import { RENDER_EXCEPTION } from "../actionTypes";
import { Msg } from '../../../view/intl/index';

type State = {
    hasError: boolean
}

type Props = {
    dispatch: Dispatch,
    children: React.ReactNode,
    rerenderBrokenUI: boolean, // eslint-disable-line react/no-unused-prop-types
    invisible?: true
}

class ErrorBoundary extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        // TODO WBTGEN-2970 - wbtgen/src/redux/recoverAfterException/ErrorBoundary/ErrorBoundary.js should not have state
        this.state = { hasError: false };
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.rerenderBrokenUI && this.state.hasError) {
            this.setState({ hasError: false });
        }
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ hasError: true });

        this.props.dispatch({ type: RENDER_EXCEPTION, payload: { error, info } });
    }

    render() {
        if (this.state.hasError) {
            if (this.props.invisible) {
                return (<div style={{ display: 'none' }} />);
            }
            return (
                <div className={styles.errorBoundary}>
                    <div>
                        <p>
                            <Msg k="common.somethingWentWrong">
                                Sorry, something went wrong. Please try to refresh your browser window.
                            </Msg>
                        </p>
                        {/* <button onClick={() => this.setState({ hasError: false })}>Try to recover</button> */}
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}

const mapStateToProps = ({ recoveryData: { rerenderBrokenUI } }: AppState) => ({ rerenderBrokenUI });
export default connect(mapStateToProps)(ErrorBoundary);
