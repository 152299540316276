/* eslint-disable no-useless-escape */
/* eslint-disable max-len */

import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from "../../../view/intl/index";
import { cancelUnsavedChangesAction, dontSaveUnsavedChangesAction, saveUnsavedChangesAction } from "../actionCreators";
import { UnsavedChangesDialogCom } from "../../presentational/UnsavedChangesDialog/UnsavedChangesDialogCom";
import { makeEpicStateSelector } from "../../../epics/makeEpic";
import AUTO_COLOR_LEFT_PANEL_EPIC_VALUE from '../../AutoColorLeftPanel/epic/valueActionType';
import { colorThemeDataEpicStateFromAppStateSelector } from "../../SiteSettings/ColorThemeData/selectorActionTypes";
import type { EpicState } from '../../AutoColorLeftPanel/flowTypes';
import type { ThemeSiteSettingsDataType } from '../../ThemeGlobalData/flowTypes';

const
    COMM_MSG = 'msg: unsavedChanges.text {Please save the changes to \"{pageName}\" before continuing, otherwise these changes will be lost.}',
    LEFT_PANEL_MESSAGE = 'msg: unsavedChanges.leftPanelText {Please save the changes to \"{pageName}\" before continuing, otherwise these will be lost. This will not save your colour changes.}';

type Props = {
    pageName: string,
    dispatch: Dispatch,
    leftPanel: EpicState,
    themeSettings: ThemeSiteSettingsDataType
};

const UnsavedChangesDialog = ({ pageName, dispatch, leftPanel, themeSettings }: Props) => (
    <UnsavedChangesDialogCom
        msg={[leftPanel.show && themeSettings.autoColorMode ? LEFT_PANEL_MESSAGE : COMM_MSG, { pageName }]}
        onSaveClick={() => dispatch(saveUnsavedChangesAction())}
        onDontSaveClick={() => dispatch(dontSaveUnsavedChangesAction())}
        onCancelClick={() => dispatch(cancelUnsavedChangesAction())}
    />
);

const leftPanelDataSelector = makeEpicStateSelector(AUTO_COLOR_LEFT_PANEL_EPIC_VALUE);

const mapStateToProps = (appState) => ({
    leftPanel: leftPanelDataSelector(appState),
    themeSettings: colorThemeDataEpicStateFromAppStateSelector(appState),
});

export default injectIntl(connect(mapStateToProps)(UnsavedChangesDialog));
