import React from "react";

// TODO WBTGEN-11802 find proper solution for rendering <defs> with same id
let i = 0;

export class SvgInstagramRegularMulticolor extends React.Component {
    render() {
        const { props } = this;
        const id = ++i;
        const idA = `a${id}`;
        const idB = `b${id}`;
        const idC = `c${id}`;

        return (
            <svg viewBox="0 0 42 42" {...props}>
                <defs>
                    <linearGradient
                        id={idA}
                        x1="18.55%"
                        x2="76.26%"
                        y1="95.39%"
                        y2="3.24%"
                    >
                        <stop offset="0%" stopColor="#e09b3d" />
                        <stop offset="18.95%" stopColor="#df983d" />
                        <stop offset="51.42%" stopColor="#c74c4d" />
                        <stop offset="67.53%" stopColor="#c21975" />
                        <stop offset="100%" stopColor="#7024c4" />
                    </linearGradient>
                    <linearGradient
                        id={idB}
                        x1="20.07%"
                        x2="82.07%"
                        y1="100%"
                        y2="-15.97%"
                    >
                        <stop offset="0%" stopColor="#e09b3d" />
                        <stop offset="26.5%" stopColor="#c74c4d" />
                        <stop offset="42.82%" stopColor="#c21975" />
                        <stop offset="100%" stopColor="#9d39a9" />
                    </linearGradient>
                    <linearGradient
                        id={idC}
                        x1="50%"
                        x2="50%"
                        y1="658.14%"
                        y2="-140.03%"
                    >
                        <stop offset="0%" stopColor="#e09b3d" />
                        <stop offset="30%" stopColor="#c74c4d" />
                        <stop offset="60%" stopColor="#c21975" />
                        <stop offset="100%" stopColor="#7024c4" />
                    </linearGradient>
                </defs>
                <g fill="none">
                    <path
                        fill={`url(#${idA})`}
                        d="M30 0c6.627 0 12 5.373 12 12v18c0 6.627-5.373 12-12 12H12C5.373 42 0 36.627 0 30V12C0 5.373 5.373 0 12 0h18zm-1 4H13a9 9 0 00-9 9v16a9 9 0 009 9h16a9 9 0 009-9V13a9 9 0 00-9-9z"
                    />
                    <path
                        fill={`url(#${idB})`}
                        d="M11 .5C16.799.5 21.5 5.201 21.5 11S16.799 21.5 11 21.5.5 16.799.5 11 5.201.5 11 .5zM11 4a7 7 0 100 14 7 7 0 000-14z"
                        transform="translate(10 10)"
                    />
                    <circle
                        cx={31.5}
                        cy={9.5}
                        r={2.5}
                        fill={`url(#${idC})`}
                    />
                </g>
            </svg>
        );
    }
}
