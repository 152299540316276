import { calcRelIn } from "./calcRelIn";
import type { ComponentsMap } from "../../../../redux/modules/children/workspace/flowTypes";

// The positions of all the components should be corrected before calling this function
export const updateComponentsRelIns = (
    componentsMap: ComponentsMap,
    templateWidth: number,
    onlyPageComponents: boolean = false,
): ComponentsMap => {
    let newComponentsMap: Object = componentsMap;
    // Remove all relIns
    Object.keys(componentsMap).forEach(id => {
        const cmp = componentsMap[id];
        if (!onlyPageComponents || (cmp.relIn && !componentsMap[cmp.relIn.id])) {
            if (newComponentsMap === componentsMap) {
                newComponentsMap = { ...newComponentsMap };
            }
            newComponentsMap[id] = { ...cmp, relIn: null };
        }
    });
    const oldState = { changes: {} },
        newState = calcRelIn(
            [],
            newComponentsMap,
            oldState,
            templateWidth,
            true,
            onlyPageComponents,
        );

    if (newState !== oldState) {
        return Object.keys(newComponentsMap).reduce((acc, id) => {
            const
                change = newState.changes[id],
                fallbackRelIn =
                    (onlyPageComponents && newComponentsMap[id].inTemplate) ? newComponentsMap[id].relIn : null;

            acc[id] = {
                ...newComponentsMap[id],
                relIn: change ? change.relIn : fallbackRelIn,
            };
            return acc;
        }, {});
    }
    return newComponentsMap;
};
