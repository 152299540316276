import React from 'react';
import cx from 'classnames';
import * as styles from '../ModernLayoutLeftPanel.css';
import CheckBox from '../../../../../view/common/CheckBox/CheckBoxSlider';
import type { ModernLayoutToggleOptions } from "../../../flowTypes";

type ModernLayoutToggleOptionProps = {
    option: ModernLayoutToggleOptions,
    onClick: Function
};

const ModernLayoutToggleOption = ({
    option,
    onClick,
}: ModernLayoutToggleOptionProps) => {
    const [expandedFlag, setExpandedFlag] = React.useState(false);
    return <React.Fragment>
        {!option.hideParentInPanel && <div key={option.id} className={styles.yourLayoutToggleContainer}>
            <div
                className={cx(styles.toggleCmpTitle, {
                    [styles.expandable]: !!option.children,
                    [styles.expanded]: expandedFlag
                })}
                onClick={() => {
                    if (option.children) {
                        setExpandedFlag(!expandedFlag);
                    }
                }}
            >{option.title}</div>
            <div className={styles.toggleCmpContainer}>
                <CheckBox
                    className={cx(styles.toggleCmp,
                        { [styles.toggleCmpChecked]: option.show })}
                    isChecked={option.show}
                    onClick={() => onClick(option)}
                />
            </div>
        </div>}
        { option.children &&
            <div
                className={cx({ [styles.childToggleOptions]: !option.hideParentInPanel },
                    { [styles.show]: expandedFlag || option.hideParentInPanel })}
            >
                { option.children.map(childComponent => {
                    return <div
                        key={childComponent.id}
                        className={cx({
                            [styles.childToggleContainer]: !option.hideParentInPanel,
                            [styles.yourLayoutToggleContainer]: option.hideParentInPanel
                        })}
                    >
                        <div className={styles.toggleCmpTitle}>{childComponent.title}</div>
                        <div className={styles.toggleCmpContainer}>
                            <CheckBox
                                className={cx(styles.toggleCmp,
                                    { [styles.toggleCmpChecked]: childComponent.show })}
                                isChecked={childComponent.show}
                                onClick={() => onClick(childComponent, option.id)}
                            />
                        </div>
                    </div>;
                }) }
            </div> }
    </React.Fragment>;
};

export default ModernLayoutToggleOption;
