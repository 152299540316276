import type { FacebookFeedGalleryComponent } from "./flowTypes";

type ColumnChangePayloadType = {
    payload: {
        id: string,
        count: number
    }
};
type SpacingChangePayloadType = {
    payload: {
        id: string,
        spacing: number
    }
};
const
    columnReducer = (
        component: FacebookFeedGalleryComponent,
        { payload: { count: columns } }: ColumnChangePayloadType
    ): FacebookFeedGalleryComponent => {
        return {
            ...component,
            mobileSettings: {
                ...component.mobileSettings,
                columns
            }
        };
    },
    spacingReducer = (
        component: FacebookFeedGalleryComponent,
        { payload: { spacing: spacingPx } }: SpacingChangePayloadType
    ): FacebookFeedGalleryComponent => {
        return {
            ...component,
            mobileSettings: {
                ...component.mobileSettings,
                spacingPx
            }
        };
    };

export {
    columnReducer,
    spacingReducer
};
