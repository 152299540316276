import { isEcommerceFeatureActiveInSiteSetting } from "../../../../../../server/shared/premiumFeatures/helper";
import { isPremiumSubscription, isNormalSubscription, isBasicSubscription } from "../../../App/epics/subscriptionData/utils";
import DataSite from "../../../../../dal/model/DataSite";
import type { SubscriptionData } from '../../../../redux/modules/flowTypes';
import { SiteSettings } from "../../../App/epics/siteSettings/flowTypes";

export const hasNoPublicHomePage = (siteData: DataSite) => {
    const site = siteData instanceof DataSite ? siteData : new DataSite(siteData);
    const homePageRef = site.getOptionalPageRefByPageId(site.homePageId);
    return !homePageRef || !homePageRef.isPublic();
};

export const isBnEFeatureInSiteSettingsRequireUpgrade = (subscriptionData: SubscriptionData, siteSettings: SiteSettings) => {
    const
        { subscriptionType } = subscriptionData,
        isBnEFeatureInSiteSettings = isEcommerceFeatureActiveInSiteSetting(siteSettings);

    const
        basicUser = isBasicSubscription(subscriptionType),
        normalUser = isNormalSubscription(subscriptionType),
        premiumUser = isPremiumSubscription(subscriptionType);

    if (basicUser || normalUser || premiumUser) {
        return isBnEFeatureInSiteSettings;
    }

    return false;
};
