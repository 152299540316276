import React from 'react';
import { injectIntl } from '../../../view/intl/index';
import { Dialog, DialogBody } from '../../../view/common/dialogs/baseDialog/index';
import getCenteredDialogConfig from '../../DialogManager/getCenteredDialogConfig';
import styles from './ChatWidgetTab.css';
import { StripTypes } from "../../../view/common/dialogs/baseDialog/Dialog";
import { closeDialog } from "../../App/actionCreators/index";
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import Button from "../../../view/common/Button/Button";
import * as actionTypes from "./actionTypes";

const
    width = 576,
    height = 492,
    HEADER = 'msg: chatWidget.chat.setDialog.header { You’re all set! }',
    MSG = 'msg: chatWidget.chat.setDialog.msg {Your chat is ready to go. You just need to publish to make it visible on your website.}',

    ChatWidgetSetDialog = injectIntl(({ intl, dispatch }) => {
        const
            onClose = () => {
                dispatch({
                    type: actionTypes.SEEN_CHAT_WIDGET_SET_DIALOG
                });
                dispatch(closeDialog());
            };

        return (
            <Dialog
                onClose={onClose}
                stripType={StripTypes.NONE}
                className={styles.chatWidgetSetDialog}
                closeBtnClassName={styles.chatWidgetSetDialogCloseButton}
            >
                <DialogBody className={styles.chatWidgetSetDialogContainer}>
                    <VerticalSpacer y={22} />
                    <div className={styles.chatWidgetSetDialogHeader}>
                        {intl.msgJoint(HEADER)}
                    </div>
                    <VerticalSpacer y={30} />
                    <div className={styles.chatWidgetSetDialogIcon} />
                    <VerticalSpacer y={30} />
                    <div className={styles.chatWidgetSetDialogMsg}>
                        {intl.msgJoint(MSG)}
                    </div>
                    <VerticalSpacer y={30} />
                    <Button
                        onClick={onClose}
                        className={styles.chatWidgetSetDialogOkBtn}
                    >
                        {intl.msgJoint("msg: common.Ok {Ok}")}
                    </Button>

                </DialogBody>
            </Dialog>
        );
    }),
    config = getCenteredDialogConfig({
        width,
        height,
        component: ChatWidgetSetDialog,
        dialogBackgroundClassName: styles.backgroundClassName
    });

export default config;
