import { getAssetUrl } from '../../../../utils/assetUtils';
import { ImagePixelRatios } from '../constants';
import calculateImageDimensionsNonCropped from './calculateImageDimensionsNonCropped';
import calculateImageDimensionsCropped from './calculateImageDimensionsCropped';
import { calcA, getReduceFactor } from '../../Gallery/utils';
import type { ImageSliderComponent, ImageSliderImageRenderProps } from '../flowTypes';
import type { GalleryImage } from '../../Gallery/flowTypes';
import { DataSite } from "../../../../../dal/model/index";
import strUtils from "../../../../../utils/string";
import { getResizeAssetQuery } from "../../Image/utils";

type calculateImagesAttributesProps = {
    component: ImageSliderComponent,
    componentId: string,
    hdImages: boolean,
    site: DataSite,
    previewBackupTime?: number,
    missingAssetUrls: Array<string>,
    isWorkspace?: boolean
}

type calculateImageAttributesProps = {
    image: GalleryImage,
    width: number,
    height: number,
    crop: boolean,
    onClickAction: string,
    componentId: string,
    hdImages: boolean,
    site: DataSite,
    missingAssetUrls: Array<string>,
    previewBackupTime?: number,
    index: number,
    isWorkspace?: boolean,
    stretch?: boolean,
    originalSize?: boolean
}

function _pi(value: number): number {
    return parseInt('' + value, 10);
}

function _pf(value: number): number {
    return parseFloat('' + value);
}

const getImageExtractParams = (imageWidth, imageHeight, containerWidth, containerHeight) => {
    let extractTop = (imageHeight - containerHeight) / 2,
        extractLeft = (imageWidth - containerWidth) / 2;

    extractLeft = extractLeft < 0 ? 0 : extractLeft;
    extractTop = extractTop < 0 ? 0 : extractTop;

    return [
        extractLeft,
        extractTop,
        Math.min(containerWidth, imageWidth),
        Math.min(containerHeight, imageHeight)
    ].map(Math.floor).join(',');
};

const
    calcCroppedAssetData = (asset, output, query, containerWidth, containerHeight, isWorkspace) => {
        const
            {
                width: imageWidth,
                height: imageHeight
            } = calculateImageDimensionsCropped(containerWidth, containerHeight, asset.width, asset.height);

        delete query.resize; // eslint-disable-line no-param-reassign

        if (imageWidth >= containerWidth || imageHeight >= containerHeight) {
            query.ignoreAspectRatio = true; // eslint-disable-line no-param-reassign
            query.resize = `${Math.floor(imageWidth)},${Math.floor(imageHeight)}`; // eslint-disable-line no-param-reassign

            query.extract = getImageExtractParams(imageWidth, imageHeight, containerWidth, containerHeight); // eslint-disable-line no-param-reassign
        }

        const { srcSet } = output,
            updatedSrcSet = isWorkspace ? [] : srcSet.split(', ').map(src => {
                if (/\ \d+x$/.test(src)) {
                    const url = src.replace(/\ \d+x$/, ''),
                        res = strUtils.trim(src.replace(url, ''));

                    const dims = url.match(/resize=(\d+),(\d+)/);
                    const imageRatio = _pf(imageWidth / imageHeight);

                    let newRatio = 1;

                    if (imageRatio >= 1) {
                        newRatio = _pf(_pi(dims[1]) / imageWidth);
                    } else if (imageRatio < 1) {
                        newRatio = _pf(_pi(dims[2]) / imageHeight);
                    }

                    const nw = _pi(newRatio * containerWidth);
                    const nh = _pi(newRatio * containerHeight);

                    const extract = getImageExtractParams(_pi(dims[1]), _pi(dims[2]), nw, nh);

                    return `${url}&extract=${extract} ${res}`;
                }

                return src;
            });

        return {
            ...output,
            src: isWorkspace ? output.src : getAssetUrl(asset, query),
            srcSet: updatedSrcSet.join(', '),
            style: { width: containerWidth, height: containerHeight }
        };
    },
    calcNonCroppedAssetData = (asset, output, query, containerWidth, containerHeight, isWorkspace) => {
        const
            { width, height } = calculateImageDimensionsNonCropped(
                containerWidth, containerHeight, asset.width, asset.height
            );
        if (query.resize && !isWorkspace) {
            query.ignoreAspectRatio = true; // eslint-disable-line no-param-reassign
            query.resize = `${width},${height}`; // eslint-disable-line no-param-reassign
            output.src = getAssetUrl(asset, query); // eslint-disable-line no-param-reassign
        }

        return { ...output, style: { width, height } };
    },
    calculateImageAttributes = ({
        image,
        width: containerWidth,
        height: containerHeight,
        crop,
        onClickAction,
        componentId,
        hdImages,
        site,
        missingAssetUrls,
        previewBackupTime,
        index,
        isWorkspace,
        stretch
    }: calculateImageAttributesProps): ImageSliderImageRenderProps => {
        const
            query: Record<string, any> = {},
            { asset, caption, title } = image,
            a = calcA({ onClickAction, caption, index, image, site, componentId, previewBackupTime }),
            workspaceUrls = {
                url1x: getAssetUrl(asset, getResizeAssetQuery(asset)),
                srcSet: []
            },
            { url1x, srcSet } = isWorkspace || stretch ? workspaceUrls :
                ImagePixelRatios.reduce((result: { srcSet: string[], url1x: string }, imagePixelRatio) => {
                if (asset.width > containerWidth) {
                    const
                        multiplier = hdImages ? imagePixelRatio : 1,
                        slidew = multiplier * containerWidth,
                        slideh = multiplier * asset.height * (containerWidth / asset.width),
                        reduceFactor = getReduceFactor(slidew, slideh, asset.width, asset.height);

                    query.ignoreAspectRatio = true;
                    query.resize = `${Math.round(slidew * reduceFactor)},${Math.round(slideh * reduceFactor)}`;
                }

                const assetUrl = getAssetUrl(asset, query);

                if (imagePixelRatio === 1) {
                    result.url1x = assetUrl; // eslint-disable-line no-param-reassign
                } else if (asset.width > containerWidth && hdImages && imagePixelRatio > 1) {
                    result.srcSet.push(`${assetUrl} ${imagePixelRatio}x`);
                }

                return result;
            }, { url1x: '', srcSet: [] });

        let output = {
            a,
            src: url1x,
            srcSet: srcSet.join(', '),
            caption,
            stretch,
            title,
            imageAvailable: (missingAssetUrls.indexOf(asset.url) === -1),
            alt: title,
            isAssetDimensionLessThanContainer: asset.height < containerHeight && asset.width < containerWidth
        };

        if (stretch) {
            return {
                ...output,
                style: { width: containerWidth, height: containerHeight }
            };
        }
        return crop
            ? calcCroppedAssetData(asset, output, query, containerWidth, containerHeight, isWorkspace)
            : calcNonCroppedAssetData(asset, output, query, containerWidth, containerHeight, isWorkspace);
    },
    calculateImagesAttributes = (
        {
            component: { images, width, height, crop, onClickAction, stretch, originalSize },
            componentId, hdImages, site, missingAssetUrls, previewBackupTime, isWorkspace = false
        }: calculateImagesAttributesProps
    ): Array<ImageSliderImageRenderProps> => images.map((image, index) => calculateImageAttributes({
        image,
        width,
        height,
        crop,
        onClickAction,
        componentId,
        hdImages,
        site,
        missingAssetUrls,
        index,
        previewBackupTime,
        isWorkspace,
        stretch,
        originalSize
    }));

export {
    calculateImageAttributes,
    calculateImagesAttributes
};
