import React from 'react';
import type { ShareButtonsCalcRenderPropsResult, ShareButtonsState } from '../flowTypes';

const
    propsToCompare = [
        'locale',
        'align',
        'showFacebook',
        'showFacebookShare',
        'showFriends',
        'showLinkedIn',
        'showTwitter',
        'styleInfo',
        'verbToDisplay'
    ];

export default class ShareButtonsBase extends React.Component<ShareButtonsCalcRenderPropsResult, ShareButtonsState> {
    shouldComponentUpdate(nextProps: ShareButtonsCalcRenderPropsResult) {
        const { props } = this;
        return propsToCompare.some(prop => props[prop] !== nextProps[prop]);
    }
}
