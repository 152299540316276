import { WidgetsTwitterPreviewConfig } from "./Twitter/previewConfig";
import { WidgetsInstagramPreviewConfig } from "./Instagram/previewConfig";
import { WidgetsFacebookPreviewConfig } from "./Facebook/previewConfig";
import { WidgetsSpotifyPreviewConfig } from "./Spotify/previewConfig";
import { WidgetsSoundcloudPreviewConfig } from "./Soundcloud/previewConfig";
import { WidgetsPinterestPreviewConfig } from "./Pinterest/previewConfig";
import { WidgetsVimeoPreviewConfig } from "./Vimeo/previewConfig";
import { WidgetsGoogleMapsPreviewConfig } from "./GoogleMaps/previewConfig";
import { WidgetsTiktokPreviewConfig } from "./Tiktok/previewConfig";
import { WidgetsEventbritePreviewConfig } from "./Eventbrite/previewConfig";
import { WidgetsBingMapsPreviewConfig } from "./BingMaps/previewConfig";
import { WidgetsGoFundMePreviewConfig } from "./GoFundMe/previewConfig";
import { WidgetsMailchimpPreviewConfig } from "./Mailchimp/previewConfig";
import { WidgetsPaypalPreviewConfig } from "./Paypal/previewConfig";
import { WidgetsBookingsPreviewConfig } from "./Bookings/previewConfig";

export const WidgetsPreviewConfigs = [
    WidgetsTwitterPreviewConfig,
    WidgetsInstagramPreviewConfig,
    WidgetsFacebookPreviewConfig,
    WidgetsSpotifyPreviewConfig,
    WidgetsSoundcloudPreviewConfig,
    WidgetsPinterestPreviewConfig,
    WidgetsVimeoPreviewConfig,
    WidgetsGoogleMapsPreviewConfig,
    WidgetsTiktokPreviewConfig,
    WidgetsEventbritePreviewConfig,
    WidgetsBingMapsPreviewConfig,
    WidgetsGoFundMePreviewConfig,
    WidgetsMailchimpPreviewConfig,
    WidgetsPaypalPreviewConfig,
    WidgetsBookingsPreviewConfig,
];
