import * as HORIZONTAL_ALIGN from "../../constants/horizontalAlign";
import type { MenuComponent } from "../../flowTypes";

function isAligmentButtonActive(verticalAlign: string, horizontalAlign: string, component: MenuComponent): boolean {
    return (
        component.verticalAlign === verticalAlign &&
        (component.horizontalAlign === HORIZONTAL_ALIGN.FIT || component.horizontalAlign === horizontalAlign)
    );
}

export { isAligmentButtonActive };
