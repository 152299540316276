/* eslint-disable max-len */

import { connect } from "react-redux";
import React from 'react';
import styles from './GmbPublishView.css';
import { Dialog, DialogBody, DialogFooter, StripTypes } from "../../../../../view/common/dialogs/baseDialog/index";
import { makeEpicStateSelector } from "../../../../../epics/makeEpic";
import { BUSINESS_LISTING_SITE_SETTINGS_SERVICE_EPIC_VALUE } from "../businessListingSiteSettingsServiceVAT";
import { injectIntl, Intl, Msg } from "../../../../../view/intl/index";
import { Box } from "../../../../../view/reflexbox/index";
import DialogCheckBox from "../../../../../view/common/dialogs/baseDialog/DialogCheckBox";
import PrimaryButton from "../../../../../view/common/Button/PrimaryButton";
import { replaceWithDisconnectListingDialog } from "../actionCreators";
import { siteSettingsValueActionType } from "../../../../App/epics/siteSettings/valueActionType";
import { openingHoursToOpeningHoursMap } from '../../../../oneweb/OpeningHours/utils';
import { OpeningHours } from '../OpeningHours/index';
import { gmbCategories as gmbCategoriesIntl } from '../../../../oneweb/Template/gmbCategories';
import { getDAL } from "../../../../../../dal/index";
import * as actionTypes from "../actionTypes";

import type { BusinessListingServiceDefaultStateType } from "../../flowTypes";
import type { SiteSettings } from '../../../../App/epics/siteSettings/flowTypes';

const { renderAddressToStringSingleline } = require('../../../../../../../server/shared/address/utils.js');

type Props = {
    intl: Intl,
    dispatch: Dispatch,
    serviceData: BusinessListingServiceDefaultStateType,
    siteSettings: SiteSettings,
    diff: Record<string, any>
};
const View = injectIntl((props: Props) => {
    const
        { intl, dispatch, serviceData, siteSettings, diff } = props,
        { doNotShowPublishDialog } = serviceData;

    const {
        locationName: diffLocationName,
        websiteUri: diffWebsiteUri,
        gmbCategories: diffGmbCategories,
        address: diffAddress,
        openingHours: diffOpeningHours,
        phoneNumber: diffPhoneNumber
    } = diff;

    const { generalData } = siteSettings;

    const addressStr = renderAddressToStringSingleline({
        addressName: '',
        addressStreetAddress: generalData.addressStreetAddress || '',
        addressCity: generalData.addressCity || '',
        addressZip: generalData.addressZip || '',
        addressArea: generalData.addressArea || '',
        addressCountryCode: generalData.addressCountryCode || '',
    });

    const openingHoursMap = openingHoursToOpeningHoursMap(generalData.openingHours || []);

    return (
        <Dialog
            stripType={StripTypes.NONE}
        >
            <DialogBody className={styles.dialogBody}>
                <Box className={styles.title}>
                    <Msg k="gmb.publishing.updateGmbListing">Update your Google My Business listing?</Msg>
                </Box>
                <Box className={styles.description}>
                    <Msg k="gmb.publishing.recentlyUpdated_new">You recently changed your contact information in Website Builder. We'll update your Google My Business listing automatically while publishing your site.</Msg>
                </Box>
                <Box className={styles.updateContainer}>
                    <Box className={styles.updateTitle}>
                        <Msg k="gmb.publishing.updateContact">Updated contact information</Msg>
                    </Box>
                    <Box className={styles.updateRest}>
                        <table className={styles.updateRestTable}>
                            <tbody>
                                {
                                    typeof diffLocationName === 'undefined' ?
                                        null :
                                        (
                                            <tr className={styles.updateContentSingle}>
                                                <td className={styles.updateLabel}>
                                                    <Msg k="BusinessListing.createListing.companyName">Company Name</Msg>:
                                                </td>
                                                <td className={styles.updateDescription}>
                                                    {generalData.addressName}
                                                </td>
                                            </tr>
                                        )
                                }
                                {
                                    typeof diffWebsiteUri === 'undefined' ?
                                        null :
                                        (
                                            <tr className={styles.updateContentSingle}>
                                                <td className={styles.updateLabel}>
                                                    <Msg k="BusinessListing.createListing.websiteUrl">Website URL</Msg>:
                                                </td>
                                                <td className={styles.updateDescription}>
                                                    {getDAL().getDomain()}
                                                </td>
                                            </tr>
                                        )
                                }
                                {
                                    typeof diffGmbCategories === 'undefined' ?
                                        null :
                                        (
                                            <tr className={styles.updateContentSingle}>
                                                <td className={styles.updateLabel}>
                                                    <Msg k="BusinessListing.createListing.businessCategory">Business Category</Msg>:
                                                </td>
                                                <td className={styles.updateDescription}>
                                                    {
                                                        (generalData.gmbCategories || '').split(',').map((cat, index) => {
                                                            return (
                                                                <React.Fragment>
                                                                    {
                                                                        index !== 0 &&
                                                                        <span>, </span>
                                                                    }
                                                                    {
                                                                        gmbCategoriesIntl[cat] && intl.msgJoint(gmbCategoriesIntl[cat])
                                                                    }
                                                                </React.Fragment>
                                                            );
                                                        })
                                                    }
                                                </td>
                                            </tr>
                                        )
                                }
                                {
                                    typeof diffAddress === 'undefined' ?
                                        null :
                                        (
                                            <tr className={styles.updateContentSingle}>
                                                <td className={styles.updateLabel}>
                                                    <Msg k="common.address">Address</Msg>:
                                                </td>
                                                <td className={styles.updateDescription}>{addressStr}</td>
                                            </tr>
                                        )
                                }
                                {
                                    typeof diffPhoneNumber === 'undefined' ?
                                        null :
                                        (
                                            <tr className={styles.updateContentSingle}>
                                                <td className={styles.updateLabel}><Msg k="common.phone">Phone</Msg>:</td>
                                                <td className={styles.updateDescription}>{generalData.phoneNumber}</td>
                                            </tr>
                                        )
                                }
                                {
                                    typeof diffOpeningHours === 'undefined' ?
                                        null :
                                        (
                                            <tr className={styles.updateContentSingle}>
                                                <td className={styles.updateLabel}><Msg k="common.openingHours">Opening hours</Msg>:</td>
                                                <td className={styles.updateDescription}>
                                                    {(Object.keys(openingHoursMap).length > 0) && (
                                                        <div className={styles.openHours}>
                                                            { /* @ts-ignore */ }
                                                            <OpeningHours openingHours={generalData.openingHours} />
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                }
                            </tbody>
                        </table>
                    </Box>
                </Box>
                <DialogFooter className={styles.footer}>
                    <div className={styles.footerInternal}>
                        <DialogCheckBox
                            className={styles.checkboxLabel}
                            containerClassName={styles.dontShowContainer}
                            isChecked={doNotShowPublishDialog}
                            label="msg: gmb.publishing.dontShowThisMessage {Don\'t show this message again}"
                            onClick={() => dispatch({ type: actionTypes.TOGGLE_SHOWING_GMB_PUBLISH_DIALOG_ACTION })}
                        />
                        <PrimaryButton
                            className={styles.okButton}
                            onClick={() => dispatch({ type: actionTypes.GMB_PUBLISH_UPDATE_USER_CONSENT_ACTION })}
                        >
                            <Msg k="common.ok">
                                OK
                            </Msg>
                        </PrimaryButton>
                        <Box className={styles.disconnectButton}>
                            <Msg
                                onClick={() => dispatch(replaceWithDisconnectListingDialog())}
                                k="gmb.publishing.disconnectFromListing"
                            >
                                Disconnect from my listing
                            </Msg>
                        </Box>
                    </div>
                </DialogFooter>
            </DialogBody>
        </Dialog>
    );
});

const mapStateToProps = (appState) => ({
    serviceData: makeEpicStateSelector(BUSINESS_LISTING_SITE_SETTINGS_SERVICE_EPIC_VALUE)(appState),
    siteSettings: makeEpicStateSelector(siteSettingsValueActionType)(appState).current,
});

export const GmbPublishDialogView = connect(mapStateToProps)(View);
