/* eslint-disable max-len */
import React from "react";
import cx from "classnames";
import * as styles from "../../AutoColorLeftPanel.css";
import CheckBox from "../../../../view/common/CheckBox/CheckBoxSlider";
import { ColorThemeDescription } from "./ColorThemeDescription";
import { autoColorChangeAction } from "../../../ThemeGlobalData/actionCreators";
import { QuestionTip } from "../../../presentational/Tooltip/questionTip/QuestionTip";
import { TooltipPosition } from "../../../presentational/Tooltip/constants";
import VerticalSpacer from "../../../../view/common/VerticalSpacer";
import HorizontalSpacer from "../../../../view/common/HorizontalSpacer";
import { Intl } from "../../../../view/intl/index";

type ColorThemeProps = {
    currentAutoColorMode: boolean;
    intl: Intl;
    dispatch: Dispatch;
};
export const ColorTheme = (props: ColorThemeProps) => {
    const { currentAutoColorMode, intl, dispatch } = props;
    return (
        <React.Fragment>
            <VerticalSpacer y={39} />
            <div
                style={{
                    display: "flex"
                }}
            >
                <div
                    className={cx(styles.colourTheme)}
                    style={{
                        width: "108px"
                    }}
                >
                    {intl.msgJoint("msg: autoColor.leftPanel.topTitle {Colour Theme}")}
                </div>
                <HorizontalSpacer x={9} />
                <CheckBox
                    isChecked={currentAutoColorMode}
                    onClick={() => {
                        dispatch(
                            autoColorChangeAction({
                                currentAutoColorMode
                            })
                        );
                    }}
                    className={styles.checkboxLabel}
                />
                <HorizontalSpacer x={39} />
                <QuestionTip
                    position={TooltipPosition.RIGHT}
                    distance={7}
                    theme={{
                        tipInfo: styles.tipInfo,
                        container: styles.tipContainer
                    }}
                    className={styles.questionTip}
                >
                    <div>
                        <div className={styles.tipTitle}>{intl.msgJoint("msg: autoColor.leftPanel.topTitle {Colour Theme}")}</div>
                        <VerticalSpacer y={6} />
                        <div className={styles.toggleTipDesc}>
                            {intl.msgJoint(
                                "msg: autoColor.leftPanel.toggle.toolTipDesc {Makes your website follow a colour theme of your choice and automatically updates if you make theme changes along the way. A simple yet flexible way to manage colours.}"
                            )}
                        </div>
                    </div>
                </QuestionTip>
            </div>
            <div
                style={{
                    display: "flex"
                }}
            >
                <ColorThemeDescription currentAutoColorMode={currentAutoColorMode} intl={intl} />
            </div>
        </React.Fragment>
    );
};
