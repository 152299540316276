import { pathOr } from 'ramda';
import React from 'react';
import {
    ComponentBorderSettings
} from "../../../../PropertiesPanel/view/components/BorderSettings/index";
import type { PageProps } from "../../../../PropertiesPanel/flowTypes";
import type { ImageComponent } from '../../flowTypes';
import * as actionTypes from '../../actionTypes';
import { getThemeRulesForBackground } from '../../../../ThemeGlobalData/themeRules';

const BorderActions = {
    cornerChangeAction: actionTypes.IMAGE_PROP_PANEL_BORDER_CORNER,
    styleChangeAction: actionTypes.IMAGE_PROP_PANEL_BORDER_TYPE,
    opacityChangeAction: actionTypes.IMAGE_PROP_PANEL_BORDER_OPACITY,
    widthChangeAction: actionTypes.IMAGE_PROP_PANEL_BORDER_WIDTH,
};

export default {
    ...ComponentBorderSettings,
    view: (props: PageProps<ImageComponent>) => {
        return (
            props.themeSettingsData.autoColorMode
                ? <ComponentBorderSettings.view
                    autoColorMode={props.themeSettingsData.autoColorMode}
                    themeColor={pathOr(
                        getThemeRulesForBackground(props.selectedComponentProps.selectedParentTheme, props.themeColorsData).text,
                        ['selectedComponent', 'style', 'border', 'themeColor'],
                        props
                    )}
                    themeColorsData={props.themeColorsData}
                    dispatch={props.dispatch}
                    selectedComponent={props.selectedComponent}
                    colorChangeAction={actionTypes.IMAGE_PROP_PANEL_BORDER_COLOR_AUTO_COLOR}
                    {...BorderActions}
                />
                : <ComponentBorderSettings.view
                    dispatch={props.dispatch}
                    selectedComponent={props.selectedComponent}
                    colorChangeAction={actionTypes.IMAGE_PROP_PANEL_BORDER_COLOR}
                    colorOnTheFlyChange
                    {...BorderActions}
                />
        );
    }
};
