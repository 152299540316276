export const
    DEFAULT_WIDTH = 500,
    SMALL_BUTTON_WIDTH = 240,
    DEFAULT_HEIGHT = 500,
    LARGE_BUTTON_HEIGHT = 510,
    MEDIUM_BUTTON_HEIGHT = 190,
    SMALL_BUTTON_HEIGHT = 50,
    defaultState = {
        link: "",
        mobileHide: false,
    },
    GoFundMeSDK = 'https://www.gofundme.com/static/js/embed.js',
    DATA_TEST_ID = 'gofundme-widget';
