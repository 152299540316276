import { omit } from "ramda";
import siteDataEpic from '../../App/epics/siteData/index';
import { missingAssetUrls } from '../../MissingAssets/epic/index';
import kind from './kind';
import reducer from './reducer';
import view from './view/index';
import workspace from './view/workspace';
import propertiesPanel from './propertiesPanel/index';
import calcRenderProps from "./calcRenderProps";
import adjustmentHookConfig from './adjustmentHookConfig';
import componentMainActions from './componentMainActions/index';
import { isPremiumUserReducer } from "../../App/epics/subscriptionData/index";
import { label, message, MinGalleryDimensions } from "./constants";
import previewConfig from './previewConfig';
import type { GalleryComponent, GalleryComponentDependsOn } from "./flowTypes";
import { makeImageChooserConfigDialog } from "../../FileManager/imageChooser/makeImageChooserConfigDialog";
import { GALLERY_SET_IMAGES_ON_COMPONENT_DROPED } from "./actionTypes";
import {
    DEFAULT_MULTI_SELECT_LIMIT,
    DEFAULT_MULTI_SELECT_VALIDATION_TITLE,
} from "../../../view/common/FileChooser/constants";
import { getSpecificStyles } from './mobileViewGetSpecificStyle';
import { calcGalleryColumn } from "./calcGalleryColumn";
import stylesheetsEpic from "../../Workspace/epics/stylesheets/index";
import siteFontsReducer from "../../Fonts/siteFontsReducer";
import fontStatusReducer from "../../Fonts/fontStatusReducer";
import { widthReducer as templateWidth } from "../Template/epics/template/index";
import { ComponentNames } from "../constants";
import { globalVariablesEpic } from "../../App/epics/globalVariablesEpic";
import { replaceTagsWithContent } from "../Text/view/replaceTagsWithContent";
import PropsTypeMap from "./galleryTypes";
import { colorThemeSiteSettingsEpic } from "../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import type { ChangeOldComponentDataBeforeImportProps } from "../../../view/oneweb/flowTypes";
import makeStateSelectorReducer from "../../../epics/makeStateSelectorReducer";
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";
import { workspaceLeftReducer, workspaceWidthReducer } from "../../Workspace/epics/workspaceBBox/selectors";

export default {
    view,
    workspace,
    kind,
    label: ComponentNames[kind],
    shortcutIconName: 'gallery',
    requireConfigurationOnDrop: () => true,
    propertiesPanel,
    configurationDialog: makeImageChooserConfigDialog({
        onSaveAction: GALLERY_SET_IMAGES_ON_COMPONENT_DROPED,
        isMultiSelect: true,
        hideMyVideosTab: true,
        maxMultiSelectValidation: {
            title: DEFAULT_MULTI_SELECT_VALIDATION_TITLE,
            label,
            limit: DEFAULT_MULTI_SELECT_LIMIT,
            message,
            remaniningLimit: DEFAULT_MULTI_SELECT_LIMIT
        },
    }),
    reducer,
    calcRenderProps,
    adjustmentHookConfig,
    shouldKeepAspectRatio: () => true,
    dependsOn: {
        hdImages: isPremiumUserReducer,
        site: siteDataEpic.reducer,
        missingAssetUrls,
        fontStatus: fontStatusReducer,
        globalStyles: stylesheetsEpic.reducer,
        globalVariables: globalVariablesEpic.reducer,
        siteFonts: siteFontsReducer,
        templateWidth,
        themeColorsData: makeStateSelectorReducer(
            stylesheetsEpic.reducer,
            stylesheetsEpic.valueActionType,
            getThemeColorsFromStylesheet
        ),
        themeSettingsData: colorThemeSiteSettingsEpic.reducer,
        workspaceBBoxWidth: workspaceWidthReducer,
        workspaceBBoxLeft: workspaceLeftReducer,
    },
    minDimensions: MinGalleryDimensions,
    componentMainActions,
    adjustComponentOnAdd: (
        defaultState: GalleryComponent & { type: string },
        { templateWidth: width }: GalleryComponentDependsOn
    ): GalleryComponent => {
        const type = defaultState.type;
        const specificPropsForType = PropsTypeMap[type] || {};

        return {
            ...omit(['type'], defaultState),
            ...defaultState,
            width,
            left: 0,
            columns: calcGalleryColumn(defaultState),
            ...specificPropsForType,
            fullWidthOption: {
                ...defaultState.fullWidthOption,
                originalWidth: width,
                originalLeft: 0
            }
        };
    },
    mobileEditorConfig: {
        ...previewConfig,
        getSpecificStyles
    },
    changeOldComponentDataBeforeImport: ({
        component, globalVariables, site,
    }: ChangeOldComponentDataBeforeImportProps<any>) => {
        return {
            ...component,
            images: component.images.map(image => ({
                ...image,
                title: replaceTagsWithContent(image.title, { globalVariables, site }),
                caption: replaceTagsWithContent(image.caption, { globalVariables, site }),
                altText: replaceTagsWithContent(image.altText, { globalVariables, site }),
            }))
        };
    }
};
