import { warn } from "../../utils/log";
import type { EpicUpdaterEvalExData } from "../debug/flowTypes";

let consequenceEpicErrorsMap = {};

const
    bannedEpicsVats = {},
    banEpicIfContiniouslyThrowingErrors = (exData) => {
        const
            consequenenceErrorsCountToGetBanned = 3,
            { valueActionType } = exData;
        if (!consequenceEpicErrorsMap[valueActionType]) {
            consequenceEpicErrorsMap[valueActionType] = [];
        }
        consequenceEpicErrorsMap[valueActionType].push(exData);
        if (consequenceEpicErrorsMap[valueActionType].length > consequenenceErrorsCountToGetBanned - 1) {
            bannedEpicsVats[valueActionType] = true;
            warn(`${valueActionType} epic was banned because was throwing errors continiously for last ${consequenenceErrorsCountToGetBanned} actions`); // eslint-disable-line max-len
        }
    },
    onSuccessActionProcessedByEpicMiddleware = () => {
        // we will reset errors map if there was an action that did not cause any epics erros
        // for now we just want to ban epic that throwing error for every action
        consequenceEpicErrorsMap = {};
    },
    onErrorDuringProcessingActionByEpicMiddleware = (exData: EpicUpdaterEvalExData) => {
        banEpicIfContiniouslyThrowingErrors(exData);
    };

export {
    onSuccessActionProcessedByEpicMiddleware,
    onErrorDuringProcessingActionByEpicMiddleware,
    bannedEpicsVats
};
