import React from "react";
import cx from "classnames";
import LazyImage from "../../../view/common/LazyImage/index";
import { openImageChooserAction } from "../../FileManager/imageChooser/actions";
import { injectIntl, Intl, makeLinkIntlTag } from "../../../view/intl/index";
import ButtonFactory from "../../presentational/Button/factory";
import Icons from "../../../view/Icons/index";
import { getImageSrc } from "../../oneweb/Image/utils";
import { FileUploader } from "../../FileUploader/index";
import { FcContentTypes } from "../../../redux/modules/children/fileChooser/FcContentTypes";
import { FileManagerMode } from "../../FileManager/constants";
import { uploadFilesAction } from "../../FileUploader/actionCreators";
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import Popup from "../../TopBar/view/Popup/index";
import styles from "./styles.css";
import logoImagePreviewStyles from "./LogoImagePreview.css";
import type { ImageAsset } from "../../App/flowTypes";
import "../../App/flowTypes";
import type { FactoryProps as ButtonFactoryProps } from "../../presentational/Button/flowTypes";
import type { FcContentTypePattern } from "../../../redux/modules/children/fileChooser/utils/testFcContentType";

type Props = {
    logoAsset: ImageAsset | null | undefined;
    alt?: string | null | undefined;
    height: number;
    width: number;
    dispatch: Dispatch;
    removeLogoActionType: string;
    logoChoosenActionType: string;
    headlessMode?: true;
    extraImageContentTypes?: FcContentTypePattern;
    intl: Intl;
    onError?: (...args: Array<any>) => any;
};
type State = {
    isHoveringOnLogoImage: boolean;
    isFileTypeTipShown: boolean;
};
type LazyLogoImagePreviewProps = {
    className: string;
    logoAsset: ImageAsset | null | undefined;
    alt?: string | null | undefined;
    height: number;
    width: number;
    onError?: (...args: Array<any>) => any;
};
const borderWidth = 1;
const RemoveIcon = injectIntl(({ onClick, intl }) => {
    const btnStyles = {
            button: logoImagePreviewStyles.removeLogoImageIcon,
            disabled: "",
            error: "",
            selected: ""
        },
        buttonFactoryConfig: ButtonFactoryProps = {
            btnStyles,
            containerType: "span",
            containerExtraPropNames: []
        },
        IconButton = ButtonFactory({ ...buttonFactoryConfig });
    return (
        <div className={logoImagePreviewStyles.removeLogoImageIconWrapper}>
            <div className={logoImagePreviewStyles.removeLogoImageIconClickWrapper} onClick={onClick} />
            <IconButton title={intl.msgJoint("msg: logo.pp.deleteLogo {Delete logo}")}>
                <Icons.CLOSE_LIGHT />
            </IconButton>
        </div>
    );
});
export const LazyLogoImagePreview = (props: LazyLogoImagePreviewProps) => {
    const { className, logoAsset, alt, width, height, onError } = props,
        { src, srcSet } = getImageSrc(logoAsset, height - borderWidth * 2, width - borderWidth * 2);

    if (src) {
        return (
            <LazyImage
                width={width}
                height={height}
                alt={alt}
                src={src}
                srcSet={srcSet}
                className={className}
                shouldLoad
                showTransparency
                doNotUseDataUriForSafari
                onError={onError}
            />
        );
    }

    return null;
};

export const LogoImagePreview = injectIntl(
    class _LogoImagePreview extends React.Component<Props, State> {
        constructor(props: Props) {
            super(props);
            this.state = {
                isHoveringOnLogoImage: false,
                isFileTypeTipShown: false
            };
            this.removeLogoImage = this.removeLogoImage.bind(this);
        }

        removeLogoImage(e: Event) {
            e.stopPropagation();
            this.props.dispatch({
                type: this.props.removeLogoActionType
            });
        }

        render() {
            const { logoAsset, alt, height, width, dispatch, headlessMode, extraImageContentTypes, onError, intl } = this.props,
                openImageChooserActionArg = {
                    onSaveAction: this.props.logoChoosenActionType,
                    isMultiSelect: false,
                    forwardToComponent: true,
                    headlessMode,
                    extraImageContentTypes
                },
                { isHoveringOnLogoImage } = this.state;
            return (
                <FileUploader
                    noOverlay
                    onDrop={(e, asset) => {
                        dispatch(
                            uploadFilesAction({
                                closeModalOnSave: false,
                                isMultiSelect: false,
                                forwardToComponent: true,
                                headlessMode: true,
                                contentTypes: FcContentTypes.SUPPORTED_IMAGE_WITH_SVG,
                                mode: FileManagerMode.IMAGE_CHOOSER,
                                onSaveAction: this.props.logoChoosenActionType,
                                files: [asset[0]] as any
                            })
                        );
                    }}
                >
                    <div
                        className={logoImagePreviewStyles.relative}
                        onClick={() => !!logoAsset && dispatch(openImageChooserAction(openImageChooserActionArg))}
                        onMouseEnter={() =>
                            this.setState({
                                isHoveringOnLogoImage: true
                            })}
                        onMouseLeave={() =>
                            this.setState({
                                isHoveringOnLogoImage: false
                            })}
                    >
                        <div
                            className={cx({
                                [logoImagePreviewStyles.logoPreviewContainer]: true,
                                [logoImagePreviewStyles.notEmpty]: !!logoAsset
                            })}
                            style={{
                                borderWidth,
                                height,
                                width,
                                overflow: "hidden",
                                position: "relative"
                            }}
                        >
                            {!logoAsset && (
                                <div className={logoImagePreviewStyles.logoUpload}>
                                    <div
                                        className={logoImagePreviewStyles.clickableArea}
                                        onClick={() => dispatch(openImageChooserAction(openImageChooserActionArg))}
                                    >
                                        <VerticalSpacer y={5} />
                                        <Icons.UPLOAD />
                                        <VerticalSpacer y={9} />
                                        <div className={logoImagePreviewStyles.logoUploadMsg}>
                                            {intl.msgJoint([
                                                "msg: onboarding.dragDropOrUpload {Drag and drop or <link>select a file</link>}",
                                                {
                                                    link: makeLinkIntlTag({
                                                        onClick: e => {
                                                            e.preventDefault();
                                                        }
                                                    })
                                                }
                                            ])}
                                        </div>
                                        <VerticalSpacer y={10} />
                                    </div>
                                    <div className={logoImagePreviewStyles.nonClickableArea}>
                                        <VerticalSpacer y={9} />
                                        <a
                                            onMouseEnter={() =>
                                                this.setState({
                                                    isFileTypeTipShown: true
                                                })}
                                            onMouseLeave={() =>
                                                this.setState({
                                                    isFileTypeTipShown: false
                                                })}
                                            className={logoImagePreviewStyles.logoFileTypeLink}
                                        >
                                            {intl.msgJoint("msg: common.seeRecommendedFileTypes {See recommended file types}")}
                                        </a>
                                    </div>
                                </div>
                            )}
                            {isHoveringOnLogoImage && logoAsset && <RemoveIcon onClick={this.removeLogoImage} />}
                            <LazyLogoImagePreview
                                logoAsset={logoAsset}
                                alt={alt}
                                height={height}
                                width={width}
                                className={styles.logoImage}
                                onError={onError}
                            />
                        </div>
                        {this.state.isFileTypeTipShown && (
                            <Popup containerClassName={logoImagePreviewStyles.popupContainer}>
                                {/* eslint-disable-next-line max-len */}
                                {intl.msgJoint("msg: settings.logo.fileTypeRecommendation {For best results we recommend using a transparent .png file which is at least 200kb.}")}
                                <VerticalSpacer y={2} />
                                {/* eslint-disable-next-line max-len */}
                                {intl.msgJoint("msg: settings.logo.fileTypeOptions {You can use .jpg, .gif and .svg files.}")}
                            </Popup>
                        )}
                    </div>
                </FileUploader>
            );
        }
    }
);
