import getCenteredDialogConfig from "../../DialogManager/getCenteredDialogConfig";
import { addOnecomTemplateReducer } from "./reducers/addOnecomTemplateReducer";
import { AddOnecomTemplateCom } from "./components/AddOnecomTemplateCom";

const
    WIDTH = 900,
    HEIGHT = 600;

export default getCenteredDialogConfig({
    width: WIDTH,
    height: HEIGHT,
    reducer: addOnecomTemplateReducer,
    component: AddOnecomTemplateCom
});
