import { CalcRenderPropsResult, ExtractReturnType, GetPlaceholderTextType, TextLikeCalcRenderProps } from "../types";
import { replaceTagsWithContent } from "../../Text/view/replaceTagsWithContent";

export const textLikeCalcRenderProps = (getPlaceholderText: (arg0: GetPlaceholderTextType<any>) => string) => (
    props: TextLikeCalcRenderProps<any>
): CalcRenderPropsResult<any, Record<any, ExtractReturnType>> => {
    const
        { component, componentDependencies, messages, viewerDomain, locale, isWorkspace, selectedParentTheme, mhfCmpData } = props,
        { themeSettingsData } = componentDependencies,
        // @ts-ignore
        { autoColorMode } = themeSettingsData;
    return {
        deps: viewerDomain ? componentDependencies : props.componentDependencies,
        specific: props.component.specific,
        generic: {
            ...props.component.generic,
            customPrefixText: replaceTagsWithContent(
                props.component.generic.customPrefixText,
                { globalVariables: props.componentDependencies.globalVariables }
            )
        },
        // @ts-ignore
        placeholderText: getPlaceholderText({ component, componentDependencies, messages, viewerDomain, locale }),
        componentId: props.component.id,
        isWorkspace,
        selectedParentTheme: autoColorMode && selectedParentTheme,
        mhfCmpData
    };
};
