import { withSelector } from "../../epics/makeCondition";
import { ONBOARDING_VAT } from "./onboardingVat";
import type { OnboardingEpicState } from "./onboardingEpic";
import { makeEpicStateSelector } from '../../epics/makeEpic';
import type { AppState } from "../../redux/modules/flowTypes";

export const OnboardingStepSelector = withSelector(ONBOARDING_VAT, (state: OnboardingEpicState) => state.step);
export const isShopOnboardingSelector = withSelector(ONBOARDING_VAT, (state: OnboardingEpicState) => state.isShopOnboarding);

const onboardingEpicStateSelector = makeEpicStateSelector(ONBOARDING_VAT);

export const isShopOnboardingStateSelector = (state: AppState) => onboardingEpicStateSelector(state).isShopOnboarding;
