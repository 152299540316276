import * as ActionTypes from "../../../actionTypes";
import type { LinkPageFormState, LinkPageFormCombinedProps } from "../../../../linkPageForm/flowTypes";
import linkPageFormCalcRenderProps from "../../../../linkPageForm/view/linkPageFormCalcRenderProps";
import { DataSite, DataLinkPage } from "../../../../../../../dal/model";

const actions = {
    setLinkAction: ActionTypes.LINK_PAGE_INFO_SET_LINK_TO_FILE_OR_PAGE,
    unsetLinkAction: ActionTypes.LINK_PAGE_INFO_UNSET_LINK_TO_FILE_OR_PAGE,
    urlOnChangeAction: ActionTypes.LINK_PAGE_INFO_URL_CHANGE,
    nameOnChangeAction: ActionTypes.LINK_PAGE_INFO_NAME_CHANGE,
    openInNewWindowToggleAction: ActionTypes.LINK_PAGE_INFO_OPEN_NEW_WINDOW_TOGGLE,
    isHiddenToggleAction: ActionTypes.LINK_PAGE_INFO_IS_HIDDEN_TOGGLE
};

type Input = {
    props: {
        page: DataLinkPage;
    },
    dependencies: {
        site: DataSite;
    };
    state: LinkPageFormState;
};

export default ({ props: { page }, dependencies, state }: Input): LinkPageFormCombinedProps & { linkPageId: string } => ({
    ...linkPageFormCalcRenderProps({
        dependencies,
        state,
        actions
    }),
    linkPageId: page.id
});
