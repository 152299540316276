import * as R from "ramda";

import makeEpic from "../../../../../epics/makeEpic";
import valueActionType from "./valueActionType";
import { TRANSCODE_STATUS } from "../../constants";
import * as Actions from "../../actionTypes";
import { checkTranscodeStatus } from "../../actionCreators/checkTranscodeStatus";
import { updateTranscodeStatus } from "../../actionCreators/index";
import { createScheduledAction } from "../../../../../redux/middleware/schedule/actionCreators";
import makeStateSelectorReducer from "../../../../../epics/makeStateSelectorReducer";
import { WORKSPACE_READY } from "../../../../Workspace/actionTypes";

export const TranscodeStatusCheckDuration = 15000; // in milliseconds
const transcodeProgressDefaultState = {
    status: TRANSCODE_STATUS.DEFAULT,
    progress: 0,
    progressAnimation: {
        current: 0,
        target: 0,
        remaining: 0
    },
    transcodeStatus: {}
};
const defaultState = {
    status: TRANSCODE_STATUS.DEFAULT,
    showtranscodeProgress: false,
    transcodeProgress: transcodeProgressDefaultState,
    transcodeStatus: {}
};
const defaultScope = {
    transcodingStackIndex: -1,
    transcodedStackIndex: -1,
    publicRootUrl: null,
    serverTranscodeStatus: null,
    transcodeRetries: 0,
    transcodeProgress: {
        // @ts-ignore it is a bug
        status: TRANSCODE_STATUS.CONVERTING,
        progress: 0
    },
    lastTimeApiWasCalled: 0,
    isStatusPollingRequired: true
};

const isTranscodingPending = (transcodeStatusResponse) => {
    return Object
        .keys(transcodeStatusResponse)
        // Ignoring videos with 'error' and 'completed' status as polling has no impact on them
        .filter(videoFileName =>
            ![TRANSCODE_STATUS.COMPLETED, TRANSCODE_STATUS.ERROR].includes(transcodeStatusResponse[videoFileName].status.toLowerCase()))
        .length > 0;
};

export const checkTranscodeInProgress = (transcodeStatusFromServerObj: Record<string, any>) => {
    return Object.values(transcodeStatusFromServerObj).some((transcodeState) => (
        transcodeState.status && (
            transcodeState.status.toLowerCase() === TRANSCODE_STATUS.UPLOADED ||
            transcodeState.status.toLowerCase() === TRANSCODE_STATUS.PROGRESSING
        )
    ));
};

export const checkTranscodeStatusEpic = makeEpic({
    defaultState,
    defaultScope,
    valueActionType,
    updaters: [
        {
            conditions: [
                WORKSPACE_READY
            ],
            reducer: ({ state, scope }) => {
                return {
                    state,
                    scope,
                    multipleActionsToDispatch: scope.isStatusPollingRequired ? [
                        { type: Actions.CALL_CHECK_TRANSCODE_STATUS_REQUEST },
                        { type: Actions.FETCH_TRANSCODE_STATUS_UNTIL_VIDEO_UPLOADS }
                    ] : []
                };
            }
        },
        {
            conditions: [
                Actions.CHECK_TRANSCODE_STATUS_REQUEST
            ],
            reducer: ({ state, scope }) => {
                return ({
                    state,
                    scope: {
                        ...scope,
                        lastTimeApiWasCalled: Date.now()
                    }
                });
            }
        },
        {
            conditions: [
                Actions.CHECK_TRANSCODE_STATUS_SUCCESS
            ],
            reducer: ({ values: [transcodeStatusFromReqObj], state, scope }) => {
                const
                    transcodeStatusUpdaterAction = updateTranscodeStatus({
                        statusResponse: transcodeStatusFromReqObj
                    }),
                    multipleActionsToDispatch = [transcodeStatusUpdaterAction];
                return ({
                    state: {
                        ...state,
                        transcodeStatus: transcodeStatusFromReqObj
                    },
                    scope: {
                        ...scope,
                        isStatusPollingRequired: isTranscodingPending(transcodeStatusFromReqObj)
                    },
                    multipleActionsToDispatch
                });
            }
        },
        {
            conditions: [
                Actions.CALL_CHECK_TRANSCODE_STATUS_REQUEST
            ],
            reducer: ({ state, scope }) => {
                const timeDiff = Date.now() - scope.lastTimeApiWasCalled;
                const actionToDispatch = checkTranscodeStatus();
                if (timeDiff >= TranscodeStatusCheckDuration) {
                    return { state, scope, actionToDispatch };
                }
                return { state, scope };
            }
        },
        {
            conditions: [
                Actions.TRANSCODE_STATUS_UPDATER
            ],
            reducer: ({
                values: [
                    payload
                ],
                state,
                scope
            }) => {
                let transcodeStatusFromServerObj = payload.statusResponse;
                let actionToDispatch;

                if (checkTranscodeInProgress(transcodeStatusFromServerObj)) {
                    actionToDispatch = createScheduledAction({
                        actionToDispatch: {
                            type: Actions.CALL_CHECK_TRANSCODE_STATUS_REQUEST
                        },
                        timeout: TranscodeStatusCheckDuration
                    });
                } else {
                    // Based on UX handle what happens when transcoding is completed
                }
                return { state: {
                    ...state,
                    transcodeStatus: transcodeStatusFromServerObj
                },
                scope,
                actionToDispatch };
            }
        },
    ]
});

export const transcodeStatus = makeStateSelectorReducer(
    checkTranscodeStatusEpic.reducer,
    valueActionType,
    R.pipe(R.path(['transcodeStatus']), R.defaultTo({}))
);
