import makeEpic from '../../../epics/makeEpic';
import { GET_PARENT_THEME_MAP_VAT } from './getParentThemeMapVAT';
import {
    ComponentsMapSelector,
} from '../../Workspace/epics/componentsEval/selectorActionTypes';
import { getParentThemeMap } from "../utils/getParentThemeMap";
import { TemplateSelectedThemeActionType } from "../../oneweb/Template/epics/template/selectorActionTypes";
import type { GetParentThemeMapEpicStateType } from "../flowTypes";

const getParentThemeMapEpicDefaultState: GetParentThemeMapEpicStateType = {
    parentThemeMap: {}
};

const getParentThemeMapEpic = makeEpic({
    defaultState: getParentThemeMapEpicDefaultState,
    valueActionType: GET_PARENT_THEME_MAP_VAT,
    updaters: [
        {
            conditions: [
                ComponentsMapSelector,
                TemplateSelectedThemeActionType,
            ],
            reducer: ({ values: [componentsMap, templateSelectedTheme], state }) => {
                const parentThemeMap = getParentThemeMap(componentsMap, templateSelectedTheme);
                if (parentThemeMap !== state.parentThemeMap) {
                    return { state: { ...state, parentThemeMap } };
                }
                return { state };
            }
        },
    ]
});

export {
    getParentThemeMapEpic,
};
