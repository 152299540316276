import React from 'react';
import * as pageIds from './ids';
import pageTitles from './titles';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import Locations from "../../locationTypes";
import PropertyContainer from "../../../../../view/common/PropertyContainer/index";
import CheckBox from '../../../../../view/common/CheckBox/PropertiesCheckBox';
import ComboBox from "../../../../../view/common/Combobox/index";
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import { Intl, injectIntl } from "../../../../../view/intl/index";
import * as actionTypes from "../../actionTypes";
import * as workspaceActionTypes from "../../../../Workspace/actionTypes";

const applyToAllPagesTxt = 'msg: code.pp.applyToAllPages {Apply to all pages}';
export const
    getOptions = (intl: Intl) => {
        return [
            {
                value: Locations.Body,
                label: intl.msgJoint("msg: common.normal {Normal}")
            },
            {
                value: Locations.BeforeClosingHead,
                label: intl.msgJoint("msg: common.beforeClosingHead {Before closing </head>}")
            },
            {
                value: Locations.BeforeClosingBody,
                label: intl.msgJoint("msg: common.beforeClosingBody {Before closing </body>}")
            }
        ];
    };

class Main extends React.Component<any> {
    constructor() {
        // @ts-ignore
        super();
        this.onClickApplyToAllPages = this.onClickApplyToAllPages.bind(this);
    }

    onClickApplyToAllPages() {
        const { selectedComponent, dispatch } = this.props;
        const type = selectedComponent.inTemplate
            ? workspaceActionTypes.MOVE_SELECTED_COMPONENTS_TO_PAGE
            : workspaceActionTypes.MOVE_SELECTED_COMPONENTS_TO_TEMPLATE;
        dispatch({ type });
    }
    render() {
        const { selectedComponent, dispatch, intl } = this.props;
        return (
            <Page>
                <VerticalSpacer />
                <PropertyContainer label="msg: component.code.placement {Placement}">
                    <ComboBox
                        searchable={false}
                        options={getOptions(intl)}
                        value={selectedComponent.location || Locations.Body}
                        onChange={({ value }) => dispatch({ type: actionTypes.CODE_UPDATE_PLACEMENT, payload: value })}
                    />
                    {
                        (selectedComponent.location === Locations.BeforeClosingBody
                        || selectedComponent.location === Locations.BeforeClosingHead) && (
                            <CheckBox
                                label={applyToAllPagesTxt}
                                onClick={this.onClickApplyToAllPages}
                                isChecked={selectedComponent.inTemplate}
                            />
                        )
                    }
                </PropertyContainer>
            </Page>
        );
    }
}

const
    id = pageIds.PLACEMENT,
    title = pageTitles[id];

export default { id, view: injectIntl(Main), title };
