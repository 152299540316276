import removeEmptyGaps from './removeEmptyGaps';
import removeStripsOverlappingWithHeader from './removeStripsOverlappingWithHeader';
import separateFooterTmpCmpsUpdater from './separateFooterTmpCmpsUpdater';
import supportDeleteSelectedSection from './supportDeleteSelectedSection';
import fixTops from "./fixTops";
import { supportDeleteStripAndAddBackgroundToSectionUpdater } from "./deleteStripAndAddBackgroundToSection";
import { deleteContainerOnlyUpdater } from "./deleteContainerOnly";
import groupTextComponents from './groupTextComponents';
import addBoxesToPageSection from './addBoxesToPageSection';
import wrapImgAndTextInBox from './wrapImgAndTextInBox';

export default [
    ...removeEmptyGaps,
    removeStripsOverlappingWithHeader,
    separateFooterTmpCmpsUpdater,
    supportDeleteSelectedSection,
    supportDeleteStripAndAddBackgroundToSectionUpdater,
    deleteContainerOnlyUpdater,
    groupTextComponents,
    addBoxesToPageSection,
    wrapImgAndTextInBox,
    fixTops
];
