/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import VideocamOffRounded from '@mui/icons-material/VideocamOffRounded';
import PlayCircleOutline from '@mui/icons-material/PlayCircleOutlined';

import { Msg } from "../../../../../view/intl/index";

import * as styles from "./VideoPlayer.css";

const videoFitStyle = { objectFit: 'cover' };

const MissingVideoPlaceholder = ({ height, width }) => (
    // @ts-ignore It can be bug
    <div className={[styles.flexContainer]} style={{ backgroundColor: '#2D2D2D', height, width }}>
        <VideocamOffRounded style={{ color: 'red' }} />
        <p style={{ color: '#FFF', paddingLeft: 5 }}>Missing Video</p>
    </div>
);

const EmptyVideoPlaceholder = ({ height, width }) => (
    <div className={styles.videoStatusContainer} style={{ height, width }}>
        <PlayCircleOutline className={styles.emptyVideoPlaceholderIcon} />
    </div>
);

const StatusPlaceholder = ({ videoStatus, height, width }) => {
    const videoStatusMsg = (videoStatus === 'Uploading' && videoStatus !== 'Failed') ?
        <Msg k="component.video.status.uploading">Uploading</Msg> : <Msg k="component.video.status.processing">Processing</Msg>;
    return (
        <div className={styles.videoStatusContainer} style={{ height, width }}>
            {
                videoStatus === 'Failed'
                    ?
                    (
                        <React.Fragment>
                            <Msg k="component.video.failedStatusPlaceholder">Upload failed</Msg>
                            <Msg k="component.video.failedStatusPlaceholderFooter">Please try again, or upload another file</Msg>
                        </React.Fragment>
                    )
                    :
                    (
                        <React.Fragment>
                            <Msg k="component.video.statusPlaceholder" params={{ videoStatusMsg }}>
                                {`{videoStatusMsg} video...`}
                            </Msg>
                            <Msg k="component.video.statusPlaceholderFooter">It will show here when it's ready.</Msg>
                        </React.Fragment>
                    )
            }
        </div>
    );
};

const useVideoIntersectionObserver = () => {
    const ref = React.useRef<HTMLVideoElement>();

    React.useEffect(() => {
        const options: IntersectionObserverInit = {
            threshold: 0.15
        };
        const observerCallback: IntersectionObserverCallback = entries => {
            entries.forEach(function (entry) {
                const el = entry.target;
                if (el instanceof HTMLVideoElement) {
                    if (entry.isIntersecting) {
                        el.preload = "metadata";
                    }
                }
            });
        };
        const observer = new IntersectionObserver(observerCallback, options);
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            observer.disconnect();
        };
    }, []);

    return ref;
};

const VideoPlayer = ({ height, width, url, settings, videoAvailable, isNewVideo, videoStatus }) => {
    const ref = useVideoIntersectionObserver();
    if (videoAvailable) {
        if (!videoStatus) {
            const { fit, ...restOfSettings } = settings;
            return (
                <video // eslint-disable-line
                    {...restOfSettings}
                    playsInline
                    src={`${url}#t=0.001`}
                    width={width}
                    height={height}
                    controlsList="nodownload"
                    style={fit ? videoFitStyle : {}}
                    preload="none"
                    ref={ref}
                />
            );
        }
        return <StatusPlaceholder videoStatus={videoStatus} height={height} width={width} />;
    } else if (isNewVideo) {
        return <EmptyVideoPlaceholder height={height} width={width} />;
    } else if (videoStatus === 'Failed') {
        return <StatusPlaceholder videoStatus={videoStatus} height={height} width={width} />;
    } else {
        return <MissingVideoPlaceholder height={height} width={width} />;
    }
};

export default VideoPlayer;
