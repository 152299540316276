export const PRONOUNS_IDS = {
    single: 'single',
    group: 'group'
};

export const PRONOUNS_MAP = [
    {
        id: PRONOUNS_IDS.single,
        value: PRONOUNS_IDS.single,
        label: "msg: onboarding.dynamic.step5.single.label {Use “I”}",
        description: {
            business: "msg: onboarding.dynamic.step5.business.singleSubLabel {e.g. I provide marketing services.}",
            default: "msg: onboarding.dynamic.step5.project.singleSubLabel {e.g. See all photos I’ve taken on my trip.}"
        }
    },
    {
        id: PRONOUNS_IDS.group,
        value: PRONOUNS_IDS.group,
        label: "msg: onboarding.dynamic.step5.group.label {Use “We”}",
        description: {
            business: "msg: onboarding.dynamic.step5.business.groupSubLabel {e.g. We provide marketing services.}",
            default: "msg: onboarding.dynamic.step5.project.groupSubLabel {e.g. See all photos we’ve taken on our trip.}"
        }
    }
];
