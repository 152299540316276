import React from "react";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';

import { useIntl } from '../../../../../../../wbtgen/src/view/intl';

const useStyles = makeStyles(
    {
        subtitle: {
            fontSize: "18px",
            lineHeight: "28px",
            letterSpacing: "0.15px",
        }
    },
    { name: "WidgetSubTitle" }
);

type WidgetSubTitleProps = {
    subtitle: string;
};

export const WidgetSubTitle = ({ subtitle }: WidgetSubTitleProps) => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Typography className={classes.subtitle}>
            {intl.msgJoint(subtitle)}
        </Typography>
    );
};
