import React from 'react';
import type { PurchaseShutterstockRequestBody } from "../types";
import { getSstockImagesPurchaseMockQuery } from "../../shutterstock/getSstockImagesPurchaseMockQuery";
import urlBuild from "../../../../../utils/urlBuild";

type Props = PurchaseShutterstockRequestBody & {
    action: string,
    locale: string,
};

export const formId = "paidShutterstockForm";

export class ShutterstockPurchaseForm extends React.Component<Props> {
    render() {
        const action = urlBuild(this.props.action, {
            query: getSstockImagesPurchaseMockQuery(),
        });

        return (
            <form id={formId} method="post" action={action}>
                <input type="hidden" name="images" value={JSON.stringify(this.props.images)} />
                <input type="hidden" name="imagesSearchId" value={this.props.imagesSearchId} />
                <input type="hidden" name="locale" value={this.props.locale} />
            </form>
        );
    }
}
