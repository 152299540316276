import React from 'react';

import { Box, Link, FormControl, Typography } from "@mui/material";

import { Switch } from '../../../../Switch';

import type { SSLSettingsSectionProps } from "../types";

export const SSLSettingsSection = (props: SSLSettingsSectionProps) => {
    return (
        <Box display="flex" alignItems="flex-start">
            <Box className={props.iconClassname} />
            <Box flex={1} marginLeft="18px">
                <Box
                    component="h2"
                    fontSize={{ xs: '18px', sm: '20px' }}
                    lineHeight={{ xs: '24px', sm: '26px' }}
                    fontWeight="400"
                >
                    {props.label}
                </Box>
                <Box
                    component="p"
                    fontSize={{ xs: '15px', sm: '14px' }}
                    lineHeight={{ xs: '24px', sm: '22px' }}
                    fontWeight="400"
                    marginTop={{ xs: '4px', md: '10px' }}
                >
                    {props.description}
                </Box>
                <Box
                    fontSize={{ xs: '15px', sm: '14px' }}
                    lineHeight={{ xs: '24px', sm: '17px' }}
                    marginTop={{ xs: '4px', md: '18px' }}
                >
                    <Link
                        href={props.link.url}
                        target="_blank"
                        underline="none"
                    >
                        {props.link.label}
                    </Link>
                </Box>
                <Box
                    lineHeight={{ xs: '24px', sm: '17px' }}
                    fontSize={{ xs: '15px', sm: '14px' }}
                    marginTop={{ xs: '14px', md: '20px' }}
                >
                    <FormControl>
                        <Switch
                            height={24}
                            width={42}
                            checked={props.value}
                            handleChange={props.clickHandler}
                            labelPadding={10}
                            labelComponent={(
                                <Box style={{ display: "flex" }}>
                                    <Typography>
                                        {props.form.label}
                                    </Typography>
                                </Box>
                            )}
                        />
                    </FormControl>
                </Box>
            </Box>
        </Box>
    );
};

