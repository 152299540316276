import type { NumberOrString } from './flowTypes';
import { Intl } from '../../intl/injectIntl';
import type { MsgJointInput } from '../../intl/injectIntl'; // eslint-disable-line no-duplicate-imports

export const
    createOption = (value: NumberOrString) => ({ value, label: value }),

    createOptions = (values: Array<NumberOrString>) => values.map(createOption),
    intlCreateOption = (value: MsgJointInput, intl: Intl) => ({
        value, label: intl.msgJoint(value)
    }),
    intlCreateOptions = (options: AnyMap, intl: any) =>

        Object.keys(options).map(k => ({ value: k, label: intl.msgJoint(options[k]) }));

