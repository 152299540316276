import * as actionTypes from '../actions';
import { makeValueReducer } from "../../../../../src/redux/makeReducer/index";

export const defaultVerificationEmailRequiredState = {
    verificationEmailRequired: true,
    verificationEmailRequiredSuccess: false
};

export const verificationEmailRequiredReducer = makeValueReducer({
    defaultState: defaultVerificationEmailRequiredState,
    handleActions: {
        [actionTypes.DEMO_SHOW_VERIFICATION_EMAIL_REQUIRED_SUCCESS_PAGE_ACTION]: (state: Record<string, any>) => {
            return {
                ...state,
                verificationEmailRequired: false,
                verificationEmailRequiredSuccess: true,
            };
        },
        [actionTypes.DEMO_CLEAN_UP_VERIFICATION_EMAIL_REQUIRED_SCREEN_ACTION]: (state: Record<string, any>) => {
            return {
                ...state,
                verificationEmailRequired: true,
                verificationEmailRequiredSuccess: false,
            };
        },
    }
});
