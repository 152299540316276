import cx from 'classnames';
import { PremiumTierComponents, EcommerceTierComponents } from '../../../view/oneweb/registry/ComponentTierTypes';
import { getDefultLocaleIdNormalized } from "../../Locale/getDefaultLocale";
import CodeComponentKind from '../Code/kind';
import { WidgetsTwitterKind } from "./Twitter/kind";
import { WidgetsSpotifyKind } from './Spotify/kind';
import { WidgetsPinterestKind } from './Pinterest/kind';
import { WidgetsGoogleMapsKind } from './GoogleMaps/kind';
import { WidgetsVimeoKind } from './Vimeo/kind';
import WidgetsYouTubeKind from "../Video/kind";
import { Youtube } from '../../Panel/configs/constants';
import { WidgetsInstagramKind } from './Instagram/kind';
import { WidgetsTiktokKind } from './Tiktok/kind';
import { WidgetsFacebookKind } from './Facebook/kind';
import { WidgetsSoundcloudKind } from './Soundcloud/kind';
import { WidgetsEventbriteKind } from './Eventbrite/kind';
import { WidgetsBingMapsKind } from './BingMaps/kind';
import { WidgetsGoFundMeKind } from './GoFundMe/kind';
import { WidgetsPaypalKind } from './Paypal/kind';
import ComponentTypes from '../../../view/oneweb/registry/ComponentTypes';
import * as Icons from './view/Icons.css';
import { WidgetsMailchimpKind } from './Mailchimp/kind';
import { WidgetsBookingsKind } from './Bookings/kind';

const isWidgetPremium = kind => !!PremiumTierComponents[kind];
const isWidgetECommerce = kind => !!EcommerceTierComponents[kind];

export const CountToShowTooManyWidgetsWarning = 5;

export const WIDGETS_CATEGORY_KEY = 'widgets';

export const
    WIDGETS_ALL = "all",
    WIDGETS_SOCIAL = 'social',
    WIDGETS_IMAGES = 'images',
    WIDGETS_VIDEO = 'video',
    WIDGETS_BUSINESS = 'business',
    WIDGETS_FORMS = 'forms',
    WIDGETS_MUSIC = 'mucic',
    WIDGETS_CONTACT = 'contact';

export const WidgetsPinterestConfig = {
    id: 'pinterest',
    title: "msg: widgets.pinterest.label {Pinterest}",
    iconClassName: cx(Icons.pinterest, Icons.inserter),
    kind: WidgetsPinterestKind,
    isPremium: isWidgetPremium(WidgetsPinterestKind),
};

export const WidgetsVimeoConfig = {
    id: 'vimeo',
    title: "msg: widgets.vimeo.label {Vimeo}",
    iconClassName: cx(Icons.vimeo, Icons.inserter),
    kind: WidgetsVimeoKind,
    isPremium: isWidgetPremium(WidgetsVimeoKind),
};

export const WidgetCodeConfig = {
    id: CodeComponentKind,
    title: "msg: component.code.label {Code}",
    iconClassName: cx(Icons.code, Icons.inserter),
    kind: CodeComponentKind,
    isPremium: isWidgetPremium(CodeComponentKind)
};

export const WidgetsTwitterConfig = {
    id: 'twitter-timeline',
    title: "msg: widgets.twitter.label {X}",
    iconClassName: cx(Icons.twitter, Icons.inserter),
    kind: WidgetsTwitterKind,
    isPremium: isWidgetPremium(WidgetsTwitterKind),
};

export const WidgetsSpotifyConfig = {
    id: 'spotify',
    title: "msg: widgets.spotify.label {Spotify}",
    iconClassName: cx(Icons.spotify, Icons.inserter),
    kind: WidgetsSpotifyKind,
    isPremium: isWidgetPremium(WidgetsSpotifyKind)
};

export const WidgetsGoogleMapsConfig = {
    id: 'googlemaps',
    title: "msg: widgets.googlemaps.label {Google Maps}",
    iconClassName: cx(Icons.googleMaps, Icons.inserter),
    kind: WidgetsGoogleMapsKind,
    stretch: false,
};

export const WidgetsInstagramConfig = {
    id: 'instagram',
    title: "msg: widgets.instagram.label {Instagram}",
    iconClassName: cx(Icons.instagram, Icons.inserter),
    kind: WidgetsInstagramKind,
    isPremium: isWidgetPremium(WidgetsInstagramKind),
};

export const WidgetsTiktokConfig = {
    id: 'tiktok',
    title: "msg: widgets.tiktok.label {TikTok}",
    iconClassName: cx(Icons.tiktok, Icons.inserter),
    kind: WidgetsTiktokKind,
    isPremium: isWidgetPremium(WidgetsTiktokKind),
};

export const WidgetsFacebookConfig = {
    id: 'facebook',
    title: "msg: widgets.facebook.label {Facebook}",
    iconClassName: cx(Icons.facebook, Icons.inserter),
    kind: WidgetsFacebookKind,
    isPremium: isWidgetPremium(WidgetsFacebookKind),
};

export const WidgetsSoundcloudConfig = {
    id: 'soundcloud',
    title: "msg: widgets.soundcloud.label {SoundCloud}",
    iconClassName: cx(Icons.soundcloud, Icons.inserter),
    kind: WidgetsSoundcloudKind,
    isPremium: isWidgetPremium(WidgetsSoundcloudKind),
};

export const WidgetsEventbriteConfig = {
    id: 'eventbrite',
    title: "msg: widgets.eventbrite.label {Eventbrite}",
    iconClassName: cx(Icons.eventbrite, Icons.inserter),
    kind: WidgetsEventbriteKind,
    isPremium: isWidgetPremium(WidgetsEventbriteKind),
};

export const WidgetsBingMapsConfig = {
    id: 'bingmaps',
    title: "msg: widgets.bingmaps.label {Bing Maps}",
    iconClassName: cx(Icons.bingMaps, Icons.inserter),
    kind: WidgetsBingMapsKind,
    isPremium: isWidgetPremium(WidgetsBingMapsKind),
};

export const WidgetsGoFundMeConfig = {
    id: 'gofundme',
    title: "msg: widgets.gofundme.label {GoFundMe}",
    iconClassName: cx(Icons.goFundMe, Icons.inserter),
    kind: WidgetsGoFundMeKind,
    isPremium: isWidgetPremium(WidgetsGoFundMeKind),
};

export const WidgetsMailchimpConfig = {
    id: 'mailchimp',
    title: "msg: widgets.mailchimp.label {Mailchimp}",
    iconClassName: cx(Icons.mailchimp, Icons.inserter),
    kind: WidgetsMailchimpKind,
    isPremium: isWidgetPremium(WidgetsMailchimpKind),
};

export const WidgetsPaypalConfig = {
    id: 'paypal',
    title: "msg: widgets.paypal.label {PayPal}",
    iconClassName: cx(Icons.paypal, Icons.inserter),
    kind: WidgetsPaypalKind,
    isPremium: isWidgetPremium(WidgetsPaypalKind),
};

export const WidgetsBookingsConfig = {
    id: 'booking',
    title: "msg: widgets.booking.label {Bookings}",
    iconClassName: cx(Icons.bookings, Icons.inserter),
    kind: WidgetsBookingsKind,
    isPremium: isWidgetPremium(WidgetsBookingsKind),
    isEcommerce: isWidgetECommerce(WidgetsBookingsKind),
};

export const WidgetsRegistry = [
    {
        id: WIDGETS_SOCIAL,
        title: "msg: common.social {Social}",
        subCategories: [WidgetsTwitterConfig, WidgetsInstagramConfig, WidgetsFacebookConfig, WidgetsTiktokConfig],
    },
    {
        id: WIDGETS_IMAGES,
        title: "msg: common.images {Images}",
        subCategories: [WidgetsPinterestConfig]
    },
    {
        id: WIDGETS_VIDEO,
        title: "msg: common.video {Video}",
        subCategories: [WidgetsVimeoConfig],
    },
    {
        id: WIDGETS_BUSINESS,
        title: "msg: common.business {Business}",
        subCategories: [WidgetsPaypalConfig, WidgetsEventbriteConfig, WidgetsMailchimpConfig, WidgetsGoFundMeConfig],
    },
    {
        id: WIDGETS_MUSIC,
        title: "msg: widgets.music.panel.label {Music}",
        subCategories: [WidgetsSpotifyConfig, WidgetsSoundcloudConfig]
    },
    {
        id: WIDGETS_CONTACT,
        title: "msg: common.contact {Contact}",
        subCategories: [WidgetsGoogleMapsConfig, WidgetsBingMapsConfig],
    },
];

const mergeAndReorder = (a1, a2, order) => {
    const result = a2.reduce((acc, item) => {
        let itemHandled = false;

        const r = acc.map(accItem => {
            if (accItem.id === item.id && Array.isArray(item.subCategories) && item.subCategories.length) {
                itemHandled = true;
                return { ...accItem, subCategories: [...(accItem.subCategories || []), ...item.subCategories] };
            }

            return accItem;
        });

        if (!itemHandled) {
            r.push(item);
        }

        return r;
    }, a1);

    return order.map(o => result.find(r => r.id === o));
};

// Defines the UI order of Widgets
const WidgetsOrder = [
    WIDGETS_ALL,
    WIDGETS_SOCIAL,
    WIDGETS_IMAGES,
    WIDGETS_VIDEO,
    WIDGETS_BUSINESS,
    WIDGETS_MUSIC,
    WIDGETS_CONTACT,
    CodeComponentKind,
];

export const WIDGETS_FILTERS = mergeAndReorder(
    [
        {
            id: WIDGETS_ALL,
            title: "msg: common.blocks.all {All}",
        },
        {
            id: WIDGETS_VIDEO,
            title: "msg: common.video {Video}",
            subCategories: [
                {
                    id: 'youtube',
                    title: "msg: common.youtube {YouTube}",
                    iconClassName: cx(Icons.youtube, Icons.inserter),
                    kind: WidgetsYouTubeKind,
                    payload: {
                        dialogProps: {
                            type: Youtube,
                        }
                    }
                },
            ]
        },
        {
            id: CodeComponentKind,
            title: "msg: component.code.label {Code}",
            subCategories: [
                WidgetCodeConfig,
            ],
        },
    ],
    WidgetsRegistry,
    WidgetsOrder
);

export const WidgetsCategories = WIDGETS_FILTERS
    .map(cat => cat.id)
    .filter(catId => catId !== WIDGETS_ALL);

export const
    DefaultLocale = getDefultLocaleIdNormalized(),
    DialogWidth = 600,
    SpotifyDialogHeight = 515,
    PinterestDialogHeight = 521,
    VimeoDialogHeight = 515,
    TwitterDialogHeight = 537,
    InstagramDialogHeight = 521,
    TiktokDialogHeight = 511,
    FacebookDialogHeight = 521,
    SoundcloudDialogHeight = 521,
    EventbriteDialogHeight = 555,
    GoogleMapsWidgetDialogHeight = 706,
    BingMapsWidgetDialogHeight = 511,
    MailchimpWidgetDialogHeight = 555,
    GoFundMeDialogHeight = 519,
    PaypalDialogHeight = 543,
    BookingsDialogHeight = 543;

// Confirm this with Sathish
export const MVEMaxWidth = 290;

export const WidgetsAllowedForAllSubscriptions = [
    // Weird typescript behaviour, ts check fails when using only youtube kind and works with combination
    ComponentTypes.WIDGETS_GOOGLEMAPS,
    ComponentTypes.WIDGETS_BINGMAPS,
    WidgetsYouTubeKind
];
