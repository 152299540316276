import { alpha } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(
    (theme) => ({
        root: {
            width: "100%",
            height: "100%",
            overflow: "hidden",
            border: `1px solid ${theme.palette.custom.colorGray_ce}`,
            position: "relative"
        },
        frameTopBar: {
            height: "20px",
            backgroundColor: theme.palette.custom.colorWhite,
            borderBottom: `1px solid ${theme.palette.custom.colorGray_ce}`,
            display: "flex",
            alignItems: "center",
            padding: "0 10px",
        },
        frameButtonsWrapper: {
            display: "flex",
            gap: "5px"
        },
        frameButton: {
            display: "inline-block",
            width: "5px",
            height: "5px",
            borderRadius: "50%",
            backgroundColor: theme.palette.custom.colorGray_ce
        },
        previewContainer: {
            width: "100%",
            background: alpha("#000", 0.06),
            height: "calc(100% - 20px)",
            "& img": {
                width: "50%",
                height: "50%"
            },
            "& > img": {
                width: "100%",
                height: "auto",
                minHeight: "100%"
            }
        },
        image: {
            width: "100%",
            height: "100%",
            backgroundPosition: "center",
            backgroundSize: "contain"
        },
        previewOverlay: {
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "transparent"
        },
        previewErrorRoot: {
            height: "calc(100% - 20px)",
        }
    }),
    { name: "SitePreview" }
);
