/* @flow */

const
    ROOT_ROUTE = '/',
    EDITOR_ROUTE = '/editor',
    DASHBOARD_ROUTE = '/dashboard',
    DASHBOARD_SHOP_ROUTE = '/dashboard/webshop';

const PROTECTED_ROUTES = [
    ROOT_ROUTE,
    EDITOR_ROUTE,
    DASHBOARD_ROUTE,
    DASHBOARD_SHOP_ROUTE
];

module.exports = {
    ROOT_ROUTE,
    EDITOR_ROUTE,
    DASHBOARD_ROUTE,
    DASHBOARD_SHOP_ROUTE,
    PROTECTED_ROUTES
};
