// @ts-nocheck
import React from "react";
import BaseComponent from "../../BaseComponent";

export default config => {
    return props => (
        <BaseComponent {...props}>
            <config.view {...props} />
        </BaseComponent>
    );
};
