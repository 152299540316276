import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import cx from "classnames";

import { useIntl, Msg } from "../../../../view/intl";
import CheckBox from "../../../../view/common/CheckBox/PropertiesCheckBox";
import styles from "./WizardFooter.css";
import { PrimaryButton, SecondaryButton } from "../../../../view/common/Button/index";
import { SEE_ALL_TEMPLATES_CLICKED } from "../../actionTypes";
import { DynamicTemplateContext } from "./Context";
import {
    DYNAMIC_TEMPLATE_AGREE_TERMS,
    DYNAMIC_TEMPLATE_GET_AI_CONTENT,
    DYNAMIC_TEMPLATE_SHOW_WELCOME_PAGE,
    DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA
} from "../Epic/actionTypes";
import { OPEN_AI_PRIVACY_LINK, OPEN_AI_TERMS_LINK } from "../../../../constants/app";

const getTermsAndConditionsLabel = (intl, agreeTermsError, dispatch) => {
    //const termsLink = "https://openai.com/policies/terms-of-use";
    //const privacyLink = "https://openai.com/policies/privacy-policy";
    // eslint-disable-next-line max-len
    const tooltipMsg = intl.msgJoint("msg: component.text.createAIText.terms.tooltip {We use OpenAI\'s writing tool to create the texts for your website.}");

    const terms = (
        <a className={cx(styles.termsLink, agreeTermsError && styles.errorLink)} href={OPEN_AI_TERMS_LINK} target="_blank">
            {intl.msgJoint("msg: common.termsAndConditions {terms and conditions}")}
        </a>
    );
    const privacy = (
        <a className={cx(styles.termsLink, agreeTermsError && styles.errorLink)} href={OPEN_AI_PRIVACY_LINK} target="_blank">
            {intl.msgJoint("msg: common.privacyPolicy {privacy policy}")}
        </a>
    );
    const templates = (
        <span className={cx(styles.termsLink)} onClick={(e) => { e.stopPropagation(); dispatch({ type: SEE_ALL_TEMPLATES_CLICKED }); }}>
            {intl.msgJoint("msg: onboarding.termsAndConditions.userTemplate {use templates instead}")}
        </span>
    );
    const tooltip = (<span
        className={styles.info}
        data-title={tooltipMsg}
        data-title-position="top"
        data-title-class={styles.tooltipStyles}
    >.</span>);
    return (
        <Msg k="onboarding.termsAndConditions" params={{ terms, privacy, tooltip, templates }}>
            {`I understand and agree to the {terms} and {privacy} of OpenAI. {tooltip} Or {templates}`}
        </Msg>
    );
};

const WizardFooter = (props) => {
    const {
        wizardState,
        wizardState: {
            isFirstStep,
            isLastStep,
            goBack,
            skippable
        },
        agreeToTerms,
    } = useContext(DynamicTemplateContext);
    const intl = useIntl();
    const { disableButton = false, useSkipText, showContinueAnyway = false } = props;
    const dispatch = useDispatch();
    const [agreeTerms, setAgreeTerms] = useState(agreeToTerms);
    const [agreeTermsError, setAgreeTermsError] = useState(false);
    const canGoToNextStep = agreeTerms || !isFirstStep;
    const checkboxProps: any = {};

    const goNext = () => {
        if (!canGoToNextStep) {
            setAgreeTermsError(true);
            return;
        }
        (props.goNext || wizardState.goNext)();
        if (isLastStep) {
            dispatch({ type: DYNAMIC_TEMPLATE_GET_AI_CONTENT });
        } else {
            dispatch({
                type: DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA,
                payload: { path: "isForward", value: true }
            });
        }
    };
    const skipStep = () => {
        if (!canGoToNextStep) {
            setAgreeTermsError(true);
            return;
        }
        (props.skipStep || wizardState.goNext)();
        if (isLastStep) {
            dispatch({ type: DYNAMIC_TEMPLATE_GET_AI_CONTENT });
        } else {
            dispatch({
                type: DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA,
                payload: { path: "isForward", value: true }
            });
        }
    };

    return (
        <div className={styles.wizardFooter}>
            {
                isFirstStep &&
                <div data-testid="dynamic-onboarding-terms-and-conditions">
                    <CheckBox
                        label={getTermsAndConditionsLabel(intl, false, dispatch)}
                        isHtmlLabel
                        isChecked={agreeTerms}
                        onClick={() => {
                            if (!agreeTerms) {
                                setAgreeTermsError(false);
                            }
                            setAgreeTerms((v) => {
                                const agreeTerms = !v;
                                dispatch({ type: DYNAMIC_TEMPLATE_AGREE_TERMS, payload: agreeTerms });
                                return agreeTerms;
                            });
                        }}
                        className={cx(styles.checkBoxLabel, agreeTermsError && styles.errorLabel)}
                        containerClassName={styles.checkBoxContainer}
                        error={agreeTermsError}
                        checkboxProps={checkboxProps}
                    />
                </div>
            }
            <div className={styles.footerButtons}>
                <SecondaryButton
                    className={styles.button}
                    onClick={() => {
                        if (isFirstStep) {
                            dispatch({ type: DYNAMIC_TEMPLATE_SHOW_WELCOME_PAGE });
                        } else {
                            dispatch({
                                type: DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA,
                                payload: { path: "isForward", value: false }
                            });
                        }
                        goBack();
                    }}
                >
                    <Msg className={styles.buttonText} k="common.back">Back</Msg>
                </SecondaryButton>
                <PrimaryButton
                    className={styles.button}
                    onClick={goNext}
                    disabled={disableButton || !canGoToNextStep}
                >
                    {showContinueAnyway ?
                        <Msg className={styles.buttonText} k="common.continueAnyway">Continue anyway</Msg> :
                        <Msg className={styles.buttonText} k="common.continue">Continue</Msg>}
                </PrimaryButton>
                {
                    skippable && (
                        <a
                            className={styles.laterButton}
                            onClick={skipStep}
                        >
                            {
                                useSkipText ?
                                    <Msg k="common.skip">Skip</Msg> :
                                    <Msg k="onboarding.doThisLater">I\'ll add this later</Msg>
                            }
                        </a>
                    )
                }
            </div>
            <div className={styles.footerText}>
                <Msg k="common.poweredByOpenAI">Powered by OpenAI</Msg>
            </div>
        </div>
    );
};

export {
    WizardFooter
};
