/* eslint-disable max-len */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import {
    CONTENT_KEYS,
    GO_TO_EDITOR_TO_PUBLISH_CONFIG,
    PAGE_TASKS, SITE_TASKS
} from '../../../../src/dashboard/src/components/Pages/Marketgoo/Report/constants';

import { useIntl } from '../../view/intl/index';
import { makeEpicStateSelector } from "../../epics/makeEpic";
import { MARKETGOO_VAT } from './marketgooVat';
import { closeMarketgooTaskBannerAction, dismissMarketgooTaskBannerAction, marketgooTaskDoneAction } from './actionCreators';

import classes from './MarketgooDialog.css';
import { saveMarketgooInPreferences } from '../../../../src/dashboard/src/components/Pages/Marketgoo/actions';
import { GO_TO_EDITOR_TO_PUBLISH_KEY, MARKETGOO_TASK_STATUS } from '../../../../src/dashboard/src/components/Pages/Marketgoo/constants';
import { getPreferencesKeyForPageTask } from '../../../../src/dashboard/src/components/Pages/Marketgoo/Report/utils';
import { AppState } from '../../redux/modules/flowTypes';

export const MarketgooDialog = () => {
    const
        intl = useIntl(),
        dispatch = useDispatch();

    const
        marketgooState = useSelector((state: AppState) => makeEpicStateSelector(MARKETGOO_VAT)(state)),
        { isTaskBannerOpen: isOpen, taskBannerId, pageId } = marketgooState;

    if (!isOpen || !taskBannerId) {
        return null;
    }

    let taskConfig = SITE_TASKS[taskBannerId] || PAGE_TASKS[taskBannerId];

    const isGoToEditorToPublishId = taskBannerId === GO_TO_EDITOR_TO_PUBLISH_KEY;

    if (!taskConfig && isGoToEditorToPublishId) {
        taskConfig = GO_TO_EDITOR_TO_PUBLISH_CONFIG;
    }

    if (!taskConfig) {
        return null;
    }

    const
        titleText = intl.msgJoint(taskConfig[CONTENT_KEYS.title]),
        description = taskConfig[CONTENT_KEYS.bannerText],
        descriptionText = typeof description === 'function' ? description(intl, classes) : intl.msgJoint(description),
        backToSeoBtnText = isGoToEditorToPublishId ? "msg: common.backToSeoScanPage {Back to SEO scan}" : "msg: common.backToSeoScan {Done, back to SEO scan}";

    const
        closeHandler = () => dispatch(closeMarketgooTaskBannerAction()),
        dismissHandler = () => dispatch(dismissMarketgooTaskBannerAction()),
        doneHandler = () => {
            if (isGoToEditorToPublishId) {
                dispatch(marketgooTaskDoneAction());
                return;
            }

            if (pageId) {
                dispatch(saveMarketgooInPreferences({
                    [getPreferencesKeyForPageTask(pageId, taskBannerId)]: MARKETGOO_TASK_STATUS.pending
                }));
            } else {
                dispatch(saveMarketgooInPreferences({ [taskBannerId]: MARKETGOO_TASK_STATUS.pending }));
            }
        };

    return (
        <div className={classes.dialogContainer}>
            <div className={classes.closeButton} onClick={closeHandler} />

            <div className={classes.dialogContentWrapper}>
                <div className={classes.dialogContentHeader}>
                    {titleText}
                </div>
                <div className={classes.dialogContentBody}>
                    {descriptionText}
                </div>

                <div className={classes.dialogActions}>
                    {
                        isGoToEditorToPublishId ?
                            null
                            : <React.Fragment>
                                <button
                                    type="button"
                                    className={cx(classes.actionButton, classes.secondaryAction)}
                                    onClick={dismissHandler}
                                >
                                    {intl.msgJoint("msg: common.maybeLater {Maybe later}")}
                                </button><div style={{ width: "22px", height: "22px" }} />
                            </React.Fragment>
                    }

                    <button
                        type="button"
                        className={cx(classes.actionButton, classes.primaryAction)}
                        onClick={doneHandler}
                    >
                        {intl.msgJoint(backToSeoBtnText)}
                    </button>
                </div>
            </div>
        </div>
    );
};
