import { LogoPreviewView } from "./view/preview";
import { LOGO_KIND } from "./kind";
import imagePreviewConfig, { imagePreviewCalcProps } from "../Image/previewConfig";
import { ImageComponent } from "../Image/flowTypes";
import { CalcProps } from "../../Preview/flowTypes";

const fixLogoLinkToHomePage = (component, site) => {
    const homePageRef = site.getHomePageRef();

    if (!homePageRef) {
        return component;
    }

    return {
        ...component,
        openLink: true,
        linkAction: homePageRef ? {
            link: { type: "page", value: homePageRef.id },
            openInNewWindow: false
        } : null
    };
};

export default {
    ...imagePreviewConfig,
    view: LogoPreviewView,
    kind: LOGO_KIND,
    calcProps: (props: CalcProps<ImageComponent>) => imagePreviewCalcProps({
        ...props,
        isLogo: true,
        component: fixLogoLinkToHomePage(props.component, props.siteMap),
        hdImages: true
    }),
    js: ['/renderStatic/logo/logo.js'],
    css: []
};
