import React from "react";
import cx from "classnames";
/* import type { BaseComponentProps } from "./flowTypes"; */
import { getComponentZIndex } from '../../components/Workspace/zIndex';
import ErrorBoundary from '../../redux/recoverAfterException/ErrorBoundary/ErrorBoundary';
import styles from './BaseComponent.css';

const
    factory = getStyle =>
        ({ component, base, children, componentId, extraClass, measureRef, isHiddenComponent, mhfCmpStyle }: any) => { // todo fix BaseComponentProps WBTGEN-1382
            let style = getStyle(component || base);
            if (mhfCmpStyle) {
                style = {
                    ...style,
                    ...mhfCmpStyle
                };
            }
            const
                className = cx(
                    styles.baseComponent,
                    extraClass || '',
                    {
                        [styles.hiddenComponents]: isHiddenComponent
                    }
                );

            return (
                <div
                    ref={measureRef}
                    data-kind={(base && base.kind) || (component && component.kind)}
                    id={componentId}
                    className={className}
                    style={style}
                >
                    <ErrorBoundary>
                        {children}
                    </ErrorBoundary>
                </div>
            );
        },
    Base = factory(component => ({
        top: `${component.top}px`,
        left: `${component.left}px`,
        width: `${component.width}px`,
        height: `${component.height}px`,
        zIndex: getComponentZIndex(component)
    })),
    StretchBase = factory(component => ({
        top: `${component.top}px`,
        left: `0px`,
        width: `100%`,
        height: `${component.height}px`,
        zIndex: getComponentZIndex(component)
    }));

export {
    StretchBase,
    Base as default
};

