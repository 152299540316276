import { ThemeOptions } from "@mui/material/styles";

export const breakpoints: ThemeOptions["breakpoints"] = {
    values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920
    }
};
