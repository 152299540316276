import React from 'react';
import Combobox from '../../../view/common/Combobox/IntlCombobox';
import type { ComboboxPropTypes } from '../../../view/common/Combobox/flowTypes';
import styles from './MctaCombobox.css';
import { DEFAULT_MCTA_COMBOBOX_MENUSTYLE } from '../constants';

export default (props: ComboboxPropTypes) => (
    <div className={styles.mctaCombobox}>
        <Combobox {...props} className={props.className} menuStyle={DEFAULT_MCTA_COMBOBOX_MENUSTYLE} />
    </div>
);
