import { handleItalicButtonClick } from "./helpers/handleToggledFormats";
import { makeToggleUpdater } from "./makeToggleUpdater";
import { TINY_MCE_TOGGLE_ITALIC } from "../actionTypes";

const onToggleItalicUpdater =
    makeToggleUpdater({
        triggerActionType: TINY_MCE_TOGGLE_ITALIC,
        propToToggle: 'italic',
        doWithEditor: handleItalicButtonClick
    });

export {
    onToggleItalicUpdater
};
