import type { AppState } from "../../../../flowTypes";
import { UnsupportedErrorDialogId } from "../../../../../../view/common/FileChooser/dialogIds";
import isFileChooserImageInstance from "../../utils/isFileChooserImageInstance";
import { openDialogGen, selectGen } from "../../../../../../utils/saga/index";
import getResourceMetadataSaga from "../getResourceMetadataSaga";

/**
 * Actually, this validation should be done as a part of server PUT request (ONEWEB-11379).
 *
 * TODO: Until it's implemented, make DELETE request.
 */
export default function* (resourceName: string, resourcePath: string): Generator<any, boolean, any> {
    // only validate if file chooser is configured to work with images
    const fcContentTypes = yield* selectGen((appState: AppState) => appState.fileChooser.contentTypes);
    if (!isFileChooserImageInstance(fcContentTypes)) return true;

    const metadata = yield* getResourceMetadataSaga(resourcePath);

    if (!metadata) {
        // TODO: DELETE request
        yield* openDialogGen(UnsupportedErrorDialogId, { fileNames: resourceName });
        return false;
    }

    return true;
}
