import React from 'react';
import ModernLayoutItem from '../../LeftPanel/screens/ModernLayoutItem';
import { getHeaderLayoutsMap } from '../../../layoutsData/headerLayouts';
import * as styles from '../onBoardingPanel.css';
import { Intl } from '../../../../../view/intl/index';
import type { Stylesheets } from "../../../../Workspace/epics/stylesheets/flowTypes";
import type { SectionComponent } from "../../../../oneweb/Section/flowTypes";
import type { LeftPanelEpicState, OnBoardingPanelEpicState } from "../../../flowTypes";
import type { TemplateComponent } from "../../../../oneweb/Template/flowTypes";
import { ACTIVATE_MODERN_HEADER, SWITCH_MODERN_HEADER } from "../../../actionTypes";
import type { WebShopMHFEpicState } from "../../../../oneweb/WebShopMHF/flowTypes";

type Props = {
    intl: Intl,
    dispatch: Dispatch,
    leftPanel: LeftPanelEpicState,
    onBoardingPanel: OnBoardingPanelEpicState,
    template: TemplateComponent,
    componentsEval: Record<string, any>,
    globalVariables: Record<string, any>,
    styleSheets: Stylesheets,
    headerSection: SectionComponent,
    webshopMHFData: WebShopMHFEpicState,
};

const OnBoardingSelectHeader = ({
    intl,
    leftPanel,
    onBoardingPanel,
    template,
    componentsEval,
    globalVariables,
    styleSheets,
    dispatch,
    headerSection,
    webshopMHFData,
}: Props) => {
    const headerLayouts = getHeaderLayoutsMap(),
        updatedHeaderSection = {
            ...headerSection,
            modernLayout: undefined
        };

    return Object.keys(headerLayouts).map((headerId: string) => {
        const layoutItem = headerLayouts[headerId],
            isActivated = onBoardingPanel.isModernHeaderActivated,
            activatedHeaderId = onBoardingPanel.activatedHeaderId;
        return (
            <React.Fragment key={layoutItem.id}>
                <div className={styles.layoutTitle}>
                    {'"' + intl.msgJoint(layoutItem.title) + '"'}
                </div>
                <div
                    className={styles.layoutItemWrapper}
                    onClick={() => {
                        if (!isActivated) {
                            dispatch({ type: ACTIVATE_MODERN_HEADER, payload: headerId });
                        } else if (activatedHeaderId !== headerId) {
                            dispatch({ type: SWITCH_MODERN_HEADER, payload: headerId, amendTo: ACTIVATE_MODERN_HEADER });
                        }
                    }}
                >
                    <ModernLayoutItem
                        isActivated={isActivated && activatedHeaderId === headerId}
                        layoutItem={layoutItem}
                        leftPanel={leftPanel}
                        template={template}
                        componentsEval={componentsEval}
                        globalVariables={globalVariables}
                        styleSheets={styleSheets}
                        dispatch={dispatch}
                        section={updatedHeaderSection}
                        MHFStoredLayoutsData={{}}
                        webshopMHFData={webshopMHFData}
                    />
                </div>
            </React.Fragment>);
    });
};

export default OnBoardingSelectHeader;
