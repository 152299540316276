import * as R from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly, anyOf, withSelector } from '../../../../epics/makeCondition';
import valueActionType from './valueActionType';
import { WORKSPACE_SCROLL,
    PASTE_FROM_SYSTEM_CLIPBOARD,
    WORKSPACE_NEW_COMPONENT_ADDED,
    WORKSPACE_SCROLL_AFTER_SECTION_MOVEMENT,
    WORKSPACE_SCROLL_TO_SELECTED_COMPONENT,
    WORKSPACE_SCROLL_TO_COMPONENT,
    WORKSPACE_SCROLL_AND_SYNC } from '../../actionTypes';
import {
    ContentDimensionsSelectorFullAction,
    ReceiveOnlyUserInteractionModeSelector,
    ReceiveOnlyContentDimensionsSelector,
    ReceiveOnlySelectedComponentsSelector, ROEditModeComponentIdSelector
} from "../componentsEval/selectorActionTypes";
import type { EpicUpdaterConfig, MakeEpicConfig } from "../../../../epics/flowTypes";
import type { Position } from "../../../../redux/modules/flowTypes";
import {
    ReceiveOnlyViewPortHeightActionType,
    ReceiveOnlyViewPortWidthActionTypeFromFullAction, ViewPortWidthActionType
} from "../viewportDimensions/selectorActionTypes";
import { isOdd } from "../../../../utils/ramdaEx";
import { CHANGE_SCOPE } from "../componentsEval/updateReasons";
import { UNDO_INITIAL_STATE } from "../../../../epics/undoManager/updateReasons";
import { WINDOW_MOUSE_MOVE } from "../../../App/actionTypes";
import { TopBarHeight } from "../../../TopBar/constants";
import { isTransientByMouse } from "../componentsEval/userInteractionMutations/interactionModes";
import { ANIMATION_FRAME } from "../../../../redux/middleware/raf";
import { SelectionFrameDecorationIsVisible } from '../selectionFrameDecoration/selectorActionTypes';
import viewportDimensionsValueActionType from "../viewportDimensions/valueActionType";
import {
    ReceiveOnlyDndAddComponentVisible
} from "../../../DndAddComponent/epic/selectorActionTypes";
import { isOverWorkspaceValueActionType } from "../isMouseOverWorkspace/valueActionType";
import { TextComponentKind } from '../../../oneweb/Text/kind';
import type { AnyComponent } from "../../../oneweb/flowTypes";
import type { ScrollEpicState } from "./flowTypes";
import { SYNC_SCROLL_TO_DOM } from "./selectorActionTypes";
import { WhenIE11OrEdge } from '../../../App/epics/environmentEpic';
import { ComponentsEvalValueActionType } from "../componentsEval/valueActionType";
import isStretchComponentKind from '../../../oneweb/isStretchComponentKind';
import { isSectionKind } from '../../../oneweb/componentKinds';
import {
    ROCodeComponentsRendererHeadHeightSelector
} from "../../CodeComponentsRenderer/epic/selectorActionTypes";
import { getCmpsRect } from "../componentsEval/utils";
import { BLOCK_NATIVE_CONTEXT_MENU_CLASS } from "../../../../constants";

type Scope = {
    lastScrollableAreaWidth: number | null,
    shouldScroll: boolean,
    prevHasHorizontalScroll?: boolean
}

const getVerticallyCenterPositionForCmpsInWS =
    (components: Array<AnyComponent>, workspaceHeight: number, scroll: Position): number => {
        const { y } = scroll,
            cmpsBox = getCmpsRect(components),
            cmpsBoxHeight = cmpsBox.bottom - cmpsBox.top,
            cmpsBoxCenter = cmpsBox.top + cmpsBoxHeight / 2,
            isCmpsBoxInVisibleSpace = cmpsBox.top >= y && cmpsBox.bottom <= (y + workspaceHeight);

        if (isCmpsBoxInVisibleSpace) { return y; }
        if (cmpsBoxHeight >= workspaceHeight) { return cmpsBox.top; }
        let newYPosition = Math.round(cmpsBoxCenter - workspaceHeight / 2);
        return newYPosition >= 0 ? newYPosition : 0;
    };

const pasteUpdater: EpicUpdaterConfig<
    ScrollEpicState, Scope, string, [Array<AnyComponent>, String, Position, { width: number, height: number }]
> = {
    conditions: [
        ReceiveOnlySelectedComponentsSelector,
        ROEditModeComponentIdSelector,
        receiveOnly(WORKSPACE_SCROLL),
        receiveOnly(viewportDimensionsValueActionType),
        PASTE_FROM_SYSTEM_CLIPBOARD
    ],
    reducer: ({ state, values: [selectedComponents, editModeComponentId, scroll, { height: workspaceHeight }], scope }) => {
        if (selectedComponents.length === 0) {
            return { state, scope };
        }

        if (selectedComponents.some(({ id, kind }) => kind === TextComponentKind && id === editModeComponentId)
            || selectedComponents.some(({ kind }) => isSectionKind(kind))) {
            return { state, scope };
        }

        return {
            state: R.evolve({
                y: () => getVerticallyCenterPositionForCmpsInWS(selectedComponents, workspaceHeight, scroll),
                shouldScrollToVertically: R.T,
                shouldScrollToYAnimated: R.F,
            }, state),
            scope
        };
    }
};

const getWorkspaceElement = (): HTMLElement | null => document.querySelector('.' + BLOCK_NATIVE_CONTEXT_MENU_CLASS);

const getScrollTopForComponent = (top, height, workspaceViewport, codeComponentsRendererHeadHeight) => {
    const workspaceElt = getWorkspaceElement(),
        maxY = (workspaceElt ? workspaceElt.scrollHeight : 0) - workspaceViewport.height;
    return Math.min((height >= workspaceViewport.height) ? top :
        (top + Math.round(height / 2) - Math.round(workspaceViewport.height / 2)) +
        codeComponentsRendererHeadHeight, maxY);
};

const resetShouldScrollToVertically = (state) => {
    if (state.shouldScrollToVertically) {
        return {
            ...state,
            shouldScrollToVertically: false
        };
    }

    return state;
};

export const workspaceScrollDefaultState = {
    x: 0,
    y: 0,
    shouldScrollToVertically: false,
    hasHorizontalScroll: false,
    shouldScrollToYAnimated: false,
};

export const
    getScrollSpeed = (howMuchPixelsMouseInsideScrollArea: number) =>
        Math.max(1, Math.round(howMuchPixelsMouseInsideScrollArea / 3));

const
    ScrollAreaLengthPx = 100,
    makeScrollVertically = y => R.evolve({ y: () => y, shouldScrollToVertically: () => true, shouldScrollToYAnimated: () => false }),
    epicConfig: MakeEpicConfig<ScrollEpicState, Scope, string> = {
        defaultScope: {
            lastScrollableAreaWidth: null,
            shouldScroll: false
        },
        defaultState: workspaceScrollDefaultState,
        valueActionType,
        updaters: [
            {
                conditions: [
                    SYNC_SCROLL_TO_DOM
                ],
                reducer: ({ state, scope }) => {
                    return {
                        state: R.evolve({
                            shouldScrollToVertically: R.T,
                            shouldScrollToYAnimated: R.F,
                        }, state),
                        scope
                    };
                }
            },
            {
                conditions: [
                    WORKSPACE_SCROLL
                ],
                reducer: ({ values: [scroll], scope, state }) => {
                    if (state.x === scroll.x && state.y === scroll.y) {
                        return { state, scope };
                    }

                    return { state: { ...state, ...scroll, shouldScrollToVertically: false, shouldScrollToYAnimated: false }, scope };
                }
            },
            {
                conditions: [
                    WORKSPACE_SCROLL_AND_SYNC
                ],
                reducer: ({ values: [scroll], scope, state }) => {
                    if (state.x === scroll.x && state.y === scroll.y) {
                        return { state, scope };
                    }

                    return { state: { ...state, ...scroll, shouldScrollToVertically: true }, scope };
                }
            },
            {
                conditions: [
                    receiveOnly(SelectionFrameDecorationIsVisible),
                    ReceiveOnlyDndAddComponentVisible,
                    receiveOnly(WINDOW_MOUSE_MOVE),
                    ReceiveOnlyViewPortHeightActionType,
                    ReceiveOnlyUserInteractionModeSelector,
                    ReceiveOnlyContentDimensionsSelector,
                    receiveOnly(isOverWorkspaceValueActionType),
                    anyOf(
                        ReceiveOnlyUserInteractionModeSelector,
                        SelectionFrameDecorationIsVisible,
                        ReceiveOnlyDndAddComponentVisible,
                        isOverWorkspaceValueActionType,
                        ANIMATION_FRAME
                    )
                ],
                reducer: ({
                    values: [
                        selectionFrameDecorationIsVisible,
                        dndAddComponentIsVisible,
                        position,
                        viewportHeight,
                        userInteractionMode,
                        { height: contentHeight },
                        isOverWorkspace
                    ],
                    scope,
                    state
                }) => {
                    const shouldScroll = !!(
                        isTransientByMouse(userInteractionMode)
                        || selectionFrameDecorationIsVisible
                        || (dndAddComponentIsVisible && isOverWorkspace)
                    );

                    let newScope = scope;
                    let newState = state;

                    if (scope.shouldScroll !== shouldScroll) {
                        newScope = R.evolve({
                            shouldScroll: () => shouldScroll
                        })(scope);
                    }

                    if (shouldScroll) {
                        const
                            topScrollAreaStartPoint = TopBarHeight + ScrollAreaLengthPx,
                            bottomScrollAreaStartPoint = TopBarHeight + viewportHeight - ScrollAreaLengthPx,
                            maxScrollY = contentHeight - viewportHeight;

                        if (position.y < topScrollAreaStartPoint && state.y > 0) {
                            // scroll up
                            const
                                howMuchPixelsMouseInsideScrollArea = topScrollAreaStartPoint - position.y,
                                nextY = state.y - getScrollSpeed(howMuchPixelsMouseInsideScrollArea);

                            newState = makeScrollVertically(Math.max(0, nextY))(state);
                        } else if (position.y > bottomScrollAreaStartPoint && state.y < maxScrollY) {
                            // scroll down
                            const
                                howMuchPixelsMouseInsideScrollArea = position.y - bottomScrollAreaStartPoint,
                                nextY = state.y + getScrollSpeed(howMuchPixelsMouseInsideScrollArea);

                            newState = makeScrollVertically(Math.min(maxScrollY, nextY))(state);
                        } else {
                            newState = resetShouldScrollToVertically(newState);
                        }
                    } else {
                        newState = resetShouldScrollToVertically(newState);
                    }

                    return {
                        state: newState,
                        scope: newScope
                    };
                }
            },
            {
                conditions: [
                    ViewPortWidthActionType,
                    ReceiveOnlyContentDimensionsSelector
                ],
                reducer: ({ values: [viewportWidth, contentDimensions], state, scope }) => {
                    const hasHorizontalScroll = viewportWidth < contentDimensions.width;

                    if (hasHorizontalScroll !== state.hasHorizontalScroll) {
                        return {
                            state: {
                                ...state,
                                hasHorizontalScroll
                            },
                            scope
                        };
                    }

                    return {
                        state,
                        scope
                    };
                }
            },
            {
                conditions: [
                    ContentDimensionsSelectorFullAction,
                    ReceiveOnlyViewPortWidthActionTypeFromFullAction
                ],
                reducer: ({
                    values: [{ contentDimensions: { width: contentWidth }, updateReason }, viewportWidth],
                    state,
                    scope
                }) => {
                    const hasHorizontalScroll = viewportWidth < contentWidth;

                    if (updateReason === CHANGE_SCOPE) {
                        if (hasHorizontalScroll !== state.hasHorizontalScroll) {
                            return { state: R.assoc('hasHorizontalScroll', hasHorizontalScroll, state), scope };
                        }
                        return { state, scope };
                    }

                    if (viewportWidth > contentWidth) {
                        return {
                            state: state.x === 0 ? state : R.evolve({ x: () => 0, hasHorizontalScroll: R.F }, state),
                            scope: R.evolve({
                                lastScrollableAreaWidth: () => viewportWidth
                            })(scope)
                        };
                    }

                    if (updateReason === UNDO_INITIAL_STATE) {
                        return {
                            state: {
                                y: 0,
                                x: (contentWidth - viewportWidth) / 2,
                                shouldScrollToVertically: false,
                                shouldScrollToYAnimated: false,
                                hasHorizontalScroll
                            },
                            scope: R.evolve({
                                lastScrollableAreaWidth: () => contentWidth
                            })(scope)
                        };
                    }

                    let possiblyNewState = state;

                    const { lastScrollableAreaWidth } = scope;
                    if (lastScrollableAreaWidth !== null) {
                        const scrolledMostLeft = state.x === 0 && viewportWidth !== lastScrollableAreaWidth;

                        if (!scrolledMostLeft && lastScrollableAreaWidth !== contentWidth) {
                            let newX = state.x + (Math.round((contentWidth - lastScrollableAreaWidth) / 2));

                            if (isOdd(lastScrollableAreaWidth)) {
                                // fixing 0.5px rounding caused by center position of template area when viewport width is odd
                                newX -= 1;
                            }

                            if (newX !== state.x) {
                                possiblyNewState = {
                                    y: state.y,
                                    x: newX,
                                    shouldScrollToVertically: false,
                                    shouldScrollToYAnimated: false,
                                    hasHorizontalScroll
                                };
                            }
                        }
                    }
                    return {
                        state: possiblyNewState,
                        scope: R.evolve({
                            lastScrollableAreaWidth: () => contentWidth
                        })(scope)
                    };
                },
                keepFullActions: true
            },
            pasteUpdater,
            {
                conditions: [
                    withSelector(valueActionType, ({ hasHorizontalScroll }) => hasHorizontalScroll),
                    WhenIE11OrEdge
                ],
                reducer: ({ state, scope, values: [hasHorizontalScroll] }) => {
                    const scrollGone = scope.prevHasHorizontalScroll && !hasHorizontalScroll;

                    if (scrollGone) {
                        return {
                            state: {
                                ...state,
                                hasHorizontalScroll: true,
                            },
                            scope
                        };
                    }
                    return {
                        state,
                        scope: {
                            ...scope,
                            prevHasHorizontalScroll: hasHorizontalScroll
                        }
                    };
                }
            },
            {
                conditions: [
                    receiveOnly(viewportDimensionsValueActionType),
                    receiveOnly(ComponentsEvalValueActionType),
                    ROCodeComponentsRendererHeadHeightSelector,
                    WORKSPACE_NEW_COMPONENT_ADDED
                ],
                reducer: ({ state, scope, values: [workspaceViewport, { state: { componentsMap } },
                    codeComponentsRendererHeadHeight, { id: newComponentId }] }) => {
                    const newComponent = componentsMap[newComponentId];
                    if (
                        isStretchComponentKind(newComponent.kind) &&
                        workspaceViewport.height + state.y <= (newComponent.top + newComponent.height)
                    ) {
                        const y = getScrollTopForComponent(newComponent.top, newComponent.height, workspaceViewport,
                            codeComponentsRendererHeadHeight);
                        if (state.y === y) {
                            return { state, scope };
                        }
                        return {
                            state: {
                                ...state,
                                y,
                                shouldScrollToVertically: true,
                                shouldScrollToYAnimated: false,
                            },
                            scope
                        };
                    }
                    return {
                        state,
                        scope
                    };
                }
            },
            {
                conditions: [
                    receiveOnly(viewportDimensionsValueActionType),
                    ROCodeComponentsRendererHeadHeightSelector,
                    WORKSPACE_SCROLL_AFTER_SECTION_MOVEMENT
                ],
                reducer: ({ state, scope, values: [workspaceViewport, codeComponentsRendererHeadHeight, { top, height }] }) => {
                    const y = getScrollTopForComponent(top, height, workspaceViewport, codeComponentsRendererHeadHeight);
                    if (state.y === y) {
                        return { state, scope };
                    }
                    return {
                        state: {
                            ...state,
                            y,
                            shouldScrollToVertically: true,
                            shouldScrollToYAnimated: true,
                        },
                        scope
                    };
                }
            },
            {
                conditions: [
                    receiveOnly(viewportDimensionsValueActionType),
                    ReceiveOnlySelectedComponentsSelector,
                    ROCodeComponentsRendererHeadHeightSelector,
                    WORKSPACE_SCROLL_TO_SELECTED_COMPONENT
                ],
                reducer: ({
                    state,
                    scope,
                    values: [
                        workspaceViewport,
                        selectedComponents,
                        codeComponentsRendererHeadHeight,
                        scrollConfig
                    ]
                }) => {
                    const selectedCmp = selectedComponents && selectedComponents[0];
                    if (!selectedCmp) {
                        return { state, scope };
                    }
                    const { top, height } = selectedCmp;
                    let y = getScrollTopForComponent(top, height, workspaceViewport, codeComponentsRendererHeadHeight);
                    y = y < 50 ? 0 : (y - 50);
                    if (state.y === y) {
                        return { state, scope };
                    }
                    return {
                        state: {
                            ...state,
                            y,
                            shouldScrollToVertically: true,
                            shouldScrollToYAnimated: scrollConfig && scrollConfig.animated,
                        },
                        scope
                    };
                }
            },
            {
                conditions: [
                    receiveOnly(viewportDimensionsValueActionType),
                    ROCodeComponentsRendererHeadHeightSelector,
                    receiveOnly(ComponentsEvalValueActionType),
                    WORKSPACE_SCROLL_TO_COMPONENT
                ],
                reducer: ({ state, scope, values: [workspaceViewport, codeComponentsRendererHeadHeight,
                    { state: { componentsMap } }, cmpId] }) => {
                    const selectedCmp = componentsMap[cmpId];
                    if (!selectedCmp) {
                        return { state, scope };
                    }
                    const { top, height } = selectedCmp;
                    let y = getScrollTopForComponent(top, height, workspaceViewport, codeComponentsRendererHeadHeight);
                    y = y < 50 ? 0 : (y - 50);
                    if (state.y === y) {
                        return { state, scope };
                    }
                    return {
                        state: {
                            ...state,
                            y,
                            shouldScrollToVertically: true,
                            shouldScrollToYAnimated: true,
                        },
                        scope
                    };
                }
            }
        ]
    };

export default makeEpic(epicConfig);
