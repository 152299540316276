/* eslint-disable max-len */
import React from 'react';

import { formatMessage } from '../../../../view/intl/CurrentLocale';
import { EMAIL_KIND } from './kind';
import { ComponentNames } from '../../constants';
import PropertiesCheckBox from '../../../../view/common/CheckBox/PropertiesCheckBox';
import VerticalSpacer from '../../../../view/common/VerticalSpacer';
import InputField from '../../../../view/common/Input/InputField';
import styles from '../textLike.css';
import { contactEmailReducer } from '../../../App/epics/globalVariablesEpic';
import { getEmailPlaceholder } from "../placeholders";
import { textLikeWorkspaceConfigFactory } from "../factories/textLikeWorkspaceConfigFactory";
import { emailComponentView } from "./emailComponentView";
import { computeComponentDependenciesForPreviewForEmail } from "./computeComponentDependenciesForPreviewForEmail";
import { getPlaceholderTextForEmail } from "./getPlaceholderTextForEmail";

const { config: emailComponentConfig } = textLikeWorkspaceConfigFactory({
    View: emailComponentView,
    label: ComponentNames[EMAIL_KIND],
    kind: EMAIL_KIND,
    dependsOn: {
        contactEmail: contactEmailReducer
    },
    isValueSet: deps => !!deps.contactEmail,
    reducer: (state = {}) => {
        return state;
    },
    pp: {
        showLineSpacingOptionForSingleLineMode: false,
        mainPageIntroText: 'msg: email.pp.mainPageIntroText {This component needs an email address stored in website settings.}',
        mainPageFooterText: 'msg: email.pp.mainPageFooterText {You will get more styling options as soon as you have added an email.}',
        addValueBtnLabel: 'msg: email.pp.addValueBtn {Add email address}',
        updateValueBtnLabel: 'msg: email.pp.updateValueBtn {Update email address}',
        valueStyleLabel: 'msg: common.email {Email}',
        mainPageRender: props => <React.Fragment>
            <PropertiesCheckBox
                containerClassName={styles.checkboxOverride}
                isChecked={props.component.generic.showCustomTitleFirst}
                label="msg: textlike.showCustomTitleFirst {Show custom title first}"
                onClick={() => props.dispatch({ type: props.TEXT_LIKE_SHOW_CUSTOM_TITLE_FIRST_TOGGLE })}
            />
            {
                props.component.generic.showCustomTitleFirst &&
                <React.Fragment>
                    <VerticalSpacer y={5} />
                    <InputField
                        value={props.component.generic.customPrefixText}
                        placeholder="msg: email.pp.emailPrefixPlaceholder {e.g. Email}"
                        onChange={payload => props.dispatch({ type: props.TEXT_LIKE_CUSTOM_PREFIX_TEXT_CHANGED, payload })}
                        maxLength={100}
                    />
                    <VerticalSpacer y={2} />
                    <PropertiesCheckBox
                        containerClassName={styles.checkboxOverride}
                        isChecked={!props.component.generic.showOnOneLine}
                        label="msg: email.pp.showOnMultipleLines {Show on multiple lines}"
                        onClick={() => props.dispatch({ type: props.TEXT_LIKE_SHOW_ON_ONE_LINE_TOGGLE })}
                    />
                </React.Fragment>
            }
        </React.Fragment>
    },
    mcta: {
        changeValueLabel: 'msg: common.changeEmail {Change email}',
        addValueLabel: 'msg: common.addEmail {Add email}',
    },
    getPrefixInitialValue: () => formatMessage({
        id: 'common.email',
        defaultMessage: 'Email'
    }),
    computeComponentDependenciesForPreview: computeComponentDependenciesForPreviewForEmail,
    defaultLineSpacing: 0.5,
    sequenceNumberUnderMore: 200,
    getStateAdjustmentsOnComponentAdd: () => {
        return {
            placeholderText: getEmailPlaceholder()
        };
    },
    getPlaceholderText: getPlaceholderTextForEmail,
});

export {
    emailComponentConfig,
};

