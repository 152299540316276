export const
    NEW_SIGNUP_PAGE = 'newSignupPage',
    SIGNUP_PAGE = 'signupPage',
    SIGNIN_PAGE = 'signinPage',
    RESET_PASSWORD_PAGE = 'resetPasswordPage',
    FORGOT_PASSWORD_PAGE = 'forgotPasswordPage',
    EMAIL_ALREADY_VERIFIED_PAGE = 'emailAlreadyVerifiedPage',
    USER_NOT_FOUND_PAGE = 'userNotFoundPage',
    EMAIL_VERIFICATION_SUCCESSFUL_PAGE = 'emailVerificationSuccessfulPage',
    TRIAL_IMPRO_COOKIES_TIMEOUT = '55m',
    TRIAL_ENDED_PAGE = 'trialEndedPage',
    VERIFICATION_EMAIL_REQUIRED_PAGE = 'verificationEmailRequiredPage',
    MOBILE_BLOCKING_PAGE = 'mobileBlockingPage',
    GOOGLE_ANALYTICS_EMAIL_VERIFIED_LOCAL_STORAGE_KEY = 'goalsGoogleAnalyticsEvent_emailSuccessfullyVerified',
    LOCAL_STORAGE_LANDING_PAGE_KEY = 'newSignupPageLandingPage',
    LOCAL_STORAGE_VALUE_LANDING_PAGE_WITH_MODAL = 'newSignupPageWithModal',
    LOCAL_STORAGE_VALUE_LANDING_PAGE_WITHOUT_MODAL = 'newSignupPageWithoutModal',
    LOCAL_STORAGE_LANDING_PAGE_SOCIAL_SIGNUP = 'landingPageSocialSignup',
    LOCAL_STORAGE_SIGNUP_SUCCESSFUL_GTM_EVENT_KEY = 'landingPageSignupSuccessfulGTMEventKey';
