import { $Shape } from "utility-types";

import type {
    ApiAction,
    ApiActionParams,
    RequestApiAction,
    ResultApiAction,
    ResultApiActionParams
} from "./flowTypes";
import CALL_API from "./CALL_API";
import { ApiResponseType } from "./index";

export const
    REDIRECT_TO_LOGIN_ACTION = 'REDIRECT_TO_LOGIN_ACTION',
    SERVER_INVALID_OR_MISSING_COOKIE_ACTION = 'SERVER_INVALID_OR_MISSING_COOKIE_ACTION';

export const apiAction = (
    {
        types,
        endpoint,
        endpointParams,
        tag,
        errorHandler,
        extraPayload,
    }: ApiActionParams,
): ApiAction => ({
    [CALL_API]: {
        types,
        endpoint,
        endpointParams,
        tag,
        errorHandler,
        extraPayload,
    }
});

export const extendedApiAction = (params: $Shape<ApiActionParams>, action: ApiAction): ApiAction => apiAction({
    ...action[CALL_API],
    ...params,
});

export const requestApiAction = (action: RequestApiAction): RequestApiAction => action;

export const successApiAction = (params: ResultApiActionParams): ResultApiAction => ({
    responseType: ApiResponseType.SUCCESS,
    ...params,
});

export const failureApiAction = (params: ResultApiActionParams): ResultApiAction => ({
    responseType: ApiResponseType.FAILURE,
    ...params,
});
