import {
    FC_TOGGLE_ALL_RESOURCES_SELECTION,
    FC_TOGGLE_RESOURCE_SELECTION,
    _FC_SET_SELECTION,
    FC_CONFLICT_DIALOG_CLOSE,
    FC_CONFLICT_RENAME_DIALOG_CLOSE,
    FC_RESOURCE_UPLOAD_CANCELED,
} from "../../../redux/modules/children/fileChooser/actionTypes";
import { openDialog } from "../../../components/App/actionCreators/index";
import { MaxMultiSelectWarningDialog } from "./dialogIds";
import { fcSaveAction, fcResetAction } from "../../../redux/modules/children/fileChooser/actionCreators/index";
import { APP_LEFT_MOUSE_DOWN, APP_RIGHT_MOUSE_DOWN, KEY_DOWN } from "../../../components/App/actionTypes";
import closeDialogAC from "../../../components/App/actionCreators/closeDialog";
import { ONBOARDING_CONTINUE_PRESSED, ONBOARDING_FILL_LATER_PRESSED } from "../../../components/Onboarding/actionTypes";

export const fileChooserMiddleware = (store: Store) => (next: Dispatch) => (action: AnyAction) => {
    const result = next(action);
    if (action.type === FC_TOGGLE_ALL_RESOURCES_SELECTION || action.type === FC_TOGGLE_RESOURCE_SELECTION) {
        const { fileChooser: { isMaxMultiSelect, maxMultiSelectValidation } } = store.getState();
        if (isMaxMultiSelect) {
            store.dispatch(openDialog(MaxMultiSelectWarningDialog, maxMultiSelectValidation));
        }
    }

    // HEADLESS MODE CASES START
    if (action.type === _FC_SET_SELECTION) {
        const {
            fileChooser: {
                headlessMode,
                isMultiSelect,
                selection,
                forwardToComponent,
                onSaveAction,
                closeModalOnSave,
            }
        } = store.getState();

        if (headlessMode) {
            store.dispatch(
                fcSaveAction({
                    onSaveAction,
                    selection,
                    isMultiSelect,
                    forwardToComponent,
                    closeModalOnSave,
                    headlessMode,
                })
            );
            store.dispatch(fcResetAction());
        }
    }

    if (
        action.type === APP_LEFT_MOUSE_DOWN
        || action.type === APP_RIGHT_MOUSE_DOWN
        || action.type === KEY_DOWN
        || action.type === ONBOARDING_CONTINUE_PRESSED
        || action.type === ONBOARDING_FILL_LATER_PRESSED
    ) {
        const {
            fileChooser: {
                headlessMode,
                computerUpload,
            }
        } = store.getState();

        if (headlessMode && !computerUpload) {
            store.dispatch(closeDialogAC());
            store.dispatch(fcResetAction());
        }
    }

    if (action.type === FC_CONFLICT_DIALOG_CLOSE || action.type === FC_CONFLICT_RENAME_DIALOG_CLOSE) {
        const {
            fileChooser: {
                headlessMode,
            }
        } = store.getState();

        store.dispatch(closeDialogAC()); // close conflict or rename dialog
        if (headlessMode) {
            store.dispatch(closeDialogAC()); // close fileChooser
            store.dispatch(fcResetAction());
        }
    }

    if (action.type === FC_RESOURCE_UPLOAD_CANCELED) {
        const { fileChooser: { headlessMode } } = store.getState();
        if (headlessMode) {
            store.dispatch(closeDialogAC());
            store.dispatch(fcResetAction());
        }
    }
    // HEADLESS MODE CASES END

    return result;
};
