import React from 'react';
import cx from 'classnames';
import styles from './ImagesCategories.css';
import LoadingIndicator from '../../../../view/common/LoadingIndicator/index';
import { ShutterstockCategoryMessages } from '../messages';
import Scrollbar from '../../../../view/common/Scrollbar/index';
import { selectShutterstockCategoryAction } from '../actions';
import type { SstockImageCategory } from "../../../../../dal/flowTypes";
import type { Intl } from "../../../../view/intl/injectIntl";
import injectIntl, { getDefaultMsg } from "../../../../view/intl/injectIntl";
import type { ShutterstockKindT } from "../types";
import { ThirdPartyImageKind } from "../../unsplash/components/constants";
import { UnsplashCategoryMessages } from "../../unsplash/messages";
import { selectUnsplashCategoryAction } from "../../unsplash/actions";
import { ShutterstockKind } from '../constants';
import { selectUnsplashCategoryActionProps } from '../../unsplash/types';

type Props = {
    kind: ShutterstockKindT,
    categories: Array<SstockImageCategory>,
    selectedCategoryId: string,
    intl: Intl,
    dispatch: Dispatch,
    categoriesLoading: boolean
};

export const AllCategoryId = '-1';

class ImagesCategoriesComClass extends React.Component<Props> {
    onClick = ({
        categoryId,
    }) => {
        const
            kind = this.props.kind;

        let rawCategory = '';
        let selectorCategoryAction: ((args: selectUnsplashCategoryActionProps) => Action) | null = null;
        if (kind === ThirdPartyImageKind.UNSPLASH || kind === ShutterstockKind.FREE) {
            rawCategory = UnsplashCategoryMessages[categoryId];
            selectorCategoryAction = selectUnsplashCategoryAction;
        } else {
            rawCategory = ShutterstockCategoryMessages[categoryId];
            selectorCategoryAction = selectShutterstockCategoryAction;
        }

        const
            categoryName = getDefaultMsg(rawCategory);

        this.props.dispatch(selectorCategoryAction({ kind: this.props.kind, categoryId, categoryName }));
    };

    getCategoryListMessage = (kind, id, intl) => {
        let categoryItem = '';
        if ((kind === ThirdPartyImageKind.UNSPLASH || kind === ShutterstockKind.FREE) && UnsplashCategoryMessages[id]) {
            categoryItem = intl.msgJoint(UnsplashCategoryMessages[id]);
        } else if (kind === ShutterstockKind.PAID && ShutterstockCategoryMessages[id]) {
            categoryItem = intl.msgJoint(ShutterstockCategoryMessages[id]);
        }
        return categoryItem;
    };

    getCategoriesList = () => {
        const { intl, categories, selectedCategoryId, kind, categoriesLoading } = this.props;
        if (!categories.length && kind === ThirdPartyImageKind.UNSPLASH && !categoriesLoading) {
            return (
                <div className={styles.categoryListFailed}>
                    {intl.msgJoint(
                        'msg: fm.unsplash.categories.failure {Categories loading failed. Please try again later or contact support.}'
                    )}
                </div>
            );
        } else if (categories.length) {
            return (
                [{ id: AllCategoryId }, ...categories].map(({ id }) => {
                    const isSelected = selectedCategoryId === id;
                    let topic = this.getCategoryListMessage(kind, id, intl);
                    return (
                        topic && <div
                            key={id}
                            className={cx(
                                styles.category,
                                { [styles.selectedCategory]: isSelected }
                            )}
                            onClick={() => !isSelected &&
                                this.onClick({
                                    categoryId: id
                                })}
                        >
                            {topic}
                        </div>
                    );
                })
            );
        }
        return (
            <LoadingIndicator />
        );
    }

    render() {
        return (
            // @ts-ignore
            <Scrollbar
                className={styles.container}
                height={styles.categoriesHeight}
            >
                { /* @ts-ignore */ }
                {this.getCategoriesList()}
            </Scrollbar>
        );
    }
}

export const ImagesCategories = injectIntl(ImagesCategoriesComClass);
