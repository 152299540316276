import React from "react";
import { useSelector } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";

import { SettingsSectionWrapper } from "../../../../SettingsControls";
import { isGMBLoading } from "../../redux/selectors";
import { GMBPreviewLoader } from "../GMBLoader/GMBPreviewLoader";
import { GMBListingPreviewInfo } from "./GMBListingPreviewInfo";
import { GMBListingPreviewMap } from "./GMBListingPreviewMap";

const useStyles = makeStyles(
    theme => ({
        container: {
            [theme.breakpoints.up("md")]: {
                flexWrap: "wrap"
            }
        }
    }),
    { name: "GMBListingPreview" }
);

export const GMBListingPreview = () => {
    const classes = useStyles();
    const isLoading = useSelector(isGMBLoading);

    return (
        <SettingsSectionWrapper
            mt="52px"
            info={<GMBListingPreviewInfo />}
            form={isLoading ? <GMBPreviewLoader /> : <GMBListingPreviewMap />}
            classes={{ container: classes.container }}
        />
    );
};
