import { getSelectedTextNodes } from "./getSelectedNodes";
import type { TinyMceEditor } from "../../../flowTypes";

const isItemAtIndex = (item, index, list) => list.indexOf(item) === index;
const removeDuplicatesPredicate = isItemAtIndex;
const removeFalsyPredicate = x => !!x;

/* TODO name better, this fuction can return array of anything, technically*/
export const getSelectedNodesProperties = (
    editor: TinyMceEditor,
    selector: (n: any) => AnyValue,
    excludedTags: Array<string> = ['BR']
): Array<AnyValue> => {
    if (!editor || editor.removed) {
        return [];
    }

    let leaves = getSelectedTextNodes(editor).filter((leaf: Node) => !excludedTags.includes(leaf.nodeName));

    // if there are no leaves it could mean that selected is empty tag or with br then we should return selected node
    if (!leaves.length) {
        leaves = [editor.getBody()];
    }

    return leaves
        .filter((leaf: Node) => !excludedTags.includes(leaf.nodeName))
        .map(selector)
        .filter(removeFalsyPredicate)
        .filter(removeDuplicatesPredicate);
};
