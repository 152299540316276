import { makeEpic } from "../../../../epics/makeEpic";
import { ComponentsMapSelector } from '../../../Workspace/epics/componentsEval/selectorActionTypes';
import { getTopMostPageSectionId } from '../utils';

export const topMostPageSectionBackgroundImageIsSetEpic = makeEpic({
    defaultState: null,
    valueActionType: "TOPMOST_PAGE_SECTION_BACKGROUND_IMAGE_IS_SET",
    updaters: [
        {
            conditions: [ComponentsMapSelector],
            reducer: ({ values: [componentsMap] }) => {
                // @ts-ignore this may be a bug
                const topMostPageSection = componentsMap[getTopMostPageSectionId(componentsMap)];
                return {
                    state: !!(topMostPageSection
                        && topMostPageSection.style
                        && topMostPageSection.style.background
                        && topMostPageSection.style.background.assetData)
                };
            }
        },
    ]
});
