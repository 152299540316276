export default ({
    component,
    componentDependencies: { privacyPolicy, webshopMHFData, stylesheets, themeSettingsData: { autoColorMode } },
    selectedParentTheme,
}) => {
    return {
        ...component,
        specialFooterData: {
            ...webshopMHFData,
            privacyPolicyContent: privacyPolicy?.content,
        },
        stylesheets,
        selectedParentTheme: autoColorMode && selectedParentTheme
    };
};
