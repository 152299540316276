import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import LinkPageInfoDialog from "./view/LinkPageInfoDialog";
import reducer from './reducer';
import siteDataEpic from '../../../../App/epics/siteData';
import makeDefaultState from "../../../linkPageForm/reducer/makeLinkPageDefaultState";
import calcRenderProps from "./view/calcRenderProps";

export default getCenteredDialogConfig({
    width: 900,
    height: 570,
    component: LinkPageInfoDialog,
    dependsOn: {
        site: siteDataEpic.reducer
    },
    makeDefaultState,
    reducer,
    calcRenderProps
});
