import React from 'react';
import cx from 'classnames';
import * as styles from '../ModernLayoutLeftPanel.css';
import type { SectionComponent } from "../../../../oneweb/Section/flowTypes";
import ModernLayoutItem from './ModernLayoutItem';
import ModernLayoutComponentsToggle from './ModernLayoutComponentsToggle';
import type { Stylesheets } from "../../../../Workspace/epics/stylesheets/flowTypes";
import { Intl } from '../../../../../view/intl/index';
import type { LayoutData, LeftPanelEpicState as ModernLayoutLeftPanel, NewLayoutDataPerSection } from "../../../flowTypes";
import type { TemplateComponent } from "../../../../oneweb/Template/flowTypes";
import type { WebShopMHFEpicState } from "../../../../oneweb/WebShopMHF/flowTypes";

type ModernLayoutYourItemProps = {
    intl: Intl,
    leftPanel: ModernLayoutLeftPanel,
    layoutItem: LayoutData,
    section: SectionComponent,
    template: TemplateComponent,
    componentsEval: Record<string, any>,
    styleSheets: Stylesheets,
    globalVariables: Record<string, any>,
    dispatch: Dispatch,
    isModernLayoutActivated: boolean,
    MHFStoredLayoutsData: NewLayoutDataPerSection,
    webshopMHFData: WebShopMHFEpicState,
};

const headerMessage = 'msg: modernLayout.leftPanel.headerToggleDisable {You have to have at least one element in your header}',
    footerMessage = 'msg: modernLayout.leftPanel.footerToggleDisable {You have to have at least one element in your footer}';
const ModernLayoutYourItem = ({
    intl,
    leftPanel,
    layoutItem,
    section,
    MHFStoredLayoutsData,
    template,
    componentsEval,
    styleSheets,
    globalVariables,
    dispatch,
    isModernLayoutActivated,
    webshopMHFData,
}: ModernLayoutYourItemProps) => {
    const disableToggleMsg = leftPanel.isFooter ? footerMessage : headerMessage;
    return <div className={cx(styles.yourItemLayoutWrapper, { [styles.activated]: isModernLayoutActivated })}>
        <div className={styles.yourLayoutTitleContainer}>
            <h2 className={styles.yourLayoutTitle}>{intl.msgJoint('msg: modernLayout.leftPanel.editLayout {Edit layout}')}</h2>
        </div>
        <ModernLayoutItem
            layoutItem={layoutItem}
            leftPanel={leftPanel}
            template={template}
            componentsEval={componentsEval}
            globalVariables={globalVariables}
            styleSheets={styleSheets}
            dispatch={dispatch}
            section={section}
            isYourLayoutItem
            MHFStoredLayoutsData={MHFStoredLayoutsData}
            webshopMHFData={webshopMHFData}
        />
        <ModernLayoutComponentsToggle
            intl={intl}
            dispatch={dispatch}
            section={section}
            disableToggleMsg={disableToggleMsg}
        />
    </div>;
};

export default ModernLayoutYourItem;
