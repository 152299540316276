import type { MergeDataProps } from "../../flowTypes";

export default ({ data, mobileDownData, templateId }: MergeDataProps) => {
    let newData = { ...data };
    Object.keys(mobileDownData).forEach(id => {
        if (id === templateId) {
            newData[templateId].push(...mobileDownData[id]);
        } else {
            newData[id] = mobileDownData[id];
        }
    });
    return newData;
};
