import React from 'react';
import styles from '../FileChooserGrid.css';
import Resource from "../../../../../redux/modules/children/fileChooser/Resource";
import {
    testFcImageContentType, testFcVideoContentType
} from "../../../../../redux/modules/children/fileChooser/utils/testFcContentType";
import { ScaledWebspaceImage } from "../../../../../components/presentational/ScaledWebspaceImage/ScaledWebspaceImage";
import LazyImage from "../../../LazyImage/index";
import { getDefaultResourceImagePath } from "../../utils";
import getStyleIntValue from "../../../../../utils/getStyleIntValue";
import { CssObjectFit } from "../../../../../mappers/constatns";

export const
    CELL_WIDTH = getStyleIntValue(styles, 'cellWidth'),
    CELL_HEIGHT = getStyleIntValue(styles, 'cellHeight');

//const firstThumbnailPlaceholder = '-00001';
const thumbnailExt = '.png';

export const LazyImageProxy = () => {
    return <span className={styles.resourceCellImageProxy} />;
};

type Props = {
    resource: Resource,
    shouldLoad: boolean,
    isFreeOneComVideo?: boolean,
};

export const ResourceCellImage = ({ resource, shouldLoad, isFreeOneComVideo }: Props) => {
    if (testFcImageContentType(resource.getContentType()) && !resource.isGhost) {
        return (
            <ScaledWebspaceImage
                path={resource._getFullPath()}
                etag={resource.etag}
                width={CELL_WIDTH}
                height={CELL_HEIGHT}
                lazyProxy={LazyImageProxy}
                className={styles.cellImage}
                cover
                shouldLoad={shouldLoad}
                showTransparency
                transparentImageClassName={styles.resourceCellTransparentImage}
                doNotUseDataUriForSafari
            />
        );
    } else if (testFcVideoContentType(resource.getContentType()) && !resource.isGhost) {
        if (isFreeOneComVideo) {
            return (<LazyImage
                src={resource.thumbnailUrl || getDefaultResourceImagePath(resource)}
                className={styles.cellImage}
                style={{ width: CELL_WIDTH, height: CELL_HEIGHT, objectFit: CssObjectFit.CONTAIN }}
                proxy={LazyImageProxy}
                shouldLoad={shouldLoad}
                showTransparency
                transparentClassName={styles.resourceCellTransparentImage}
                doNotUseDataUriForSafari
            />);
        } else {
            return (
                <ScaledWebspaceImage
                    path={resource._getFullPath().substring(0, (resource._getFullPath()).length - 4) + thumbnailExt}
                    etag={resource.etag}
                    width={CELL_WIDTH}
                    height={CELL_HEIGHT}
                    lazyProxy={LazyImageProxy}
                    className={styles.cellImage}
                    cover
                    shouldLoad={shouldLoad}
                    showTransparency
                    transparentImageClassName={styles.resourceCellTransparentImage}
                    doNotUseDataUriForSafari
                />);
        }
    } else {
        return (<LazyImage
            src={getDefaultResourceImagePath(resource)}
            className={styles.cellDefaultImage}
        />);
    }
};
