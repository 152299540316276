/* global one */

import isTestEnv from "./debug/isTestEnv";
import { isDALInitialized, getDAL } from "../dal/index";
import { makeUuidNoDash } from "../utils/makeUuid";

export type CrashReport = {
    crashReportId?: string,
    message: string,
    additionalInfo?: Record<string, any>,
    fileName?: string,
    lineNumber?: number
}

export const customSendReport = (crashReport: CrashReport) => {
    // @ts-ignore
    if (!isTestEnv() && typeof one !== 'undefined' && one.crashReporter) { // one && one.customCrashReporter to not be called from server
        let data: Record<string, any> = {
            ...crashReport,
            crashReportId: crashReport.crashReportId || makeUuidNoDash(),
            message: (crashReport.message || '') + '_wsberror'
        };

        if (isDALInitialized()) {
            const domainName = getDAL().getDomain();
            data.domainName = domainName;
            data.additionalInfo = {
                domainName,
                ...data.additionalInfo,
            };
        }

        /*
        // TODO: Bring this back once the Elastic Search indexing issues are resolved by maintenance team
        if (isDALInitialized()) {
            data.additionalInfo = {
                ...data.additionalInfo,
                domainName: getDAL().getDomain(),
            };
        }

        // WBTGEN-18609 - Handle Elastic Search indexing issue
        // Converting data.additionalInfo into an array so that Elastic Search doesn't individually index the member objects
        data.additionalInfo = [data.additionalInfo];
        /* */
        data.customMessage = JSON.stringify(data.additionalInfo);

        // TODO: Drop this block of code once the Elastic Search indexing issues are resolved by maintenance team
        delete data.additionalInfo;
        // @ts-ignore
        one.crashReporter.sendReport(data, { eventtype: 'wsberrors' });
    }
};
