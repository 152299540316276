const CANVAS_ID = 'imageTransparencyCheckCanvas';

const getCanvas = () => {
    let canvas = document.getElementById(CANVAS_ID);

    if (!canvas) {
        canvas = document.createElement('canvas');
        canvas.setAttribute('style', 'display:none');
        canvas.setAttribute('id', CANVAS_ID);

        document.body.appendChild(canvas);
    }

    return canvas;
};

const
    cache = {
        _data: {},

        key(img: HTMLImageElement): string {
            return `${img.src}${img.srcset}`;
        },

        has(img: HTMLImageElement): boolean {
            return this._data[this.key(img)] !== undefined;
        },

        get(img: HTMLImageElement): boolean {
            if (!this.has(img)) {
                console.log(img);
                throw new Error('Transparency is not defined for image ^^^');
            }
            return this._data[this.key(img)];
        },

        set(img: HTMLImageElement, transparency: boolean) {
            this._data[this.key(img)] = transparency;
        }
    };

export const isTransparentImage = (img: HTMLImageElement): boolean => {
    if (cache.has(img)) return cache.get(img);

    let { width, height } = img;
    if (!width) {
        width = 100;
    }
    if (!height) {
        height = 100;
    }

    const canvas = getCanvas();

    // @ts-ignore
    canvas.setAttribute('width', width);

    // @ts-ignore
    canvas.setAttribute('height', height);

    // @ts-ignore
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, width, height);

    let imageData = [];
    let result = false;

    imageData = ctx.getImageData(0, 0, width, height).data;

    for (let i = 3; i < imageData.length; i += 4) {
        if (imageData[i] < 255) {
            result = true;
        }
    }

    cache.set(img, result);

    return result;
};
