import makeEpic from '../../../../epics/makeEpic';
import { tinyMceVAT } from './valueActionType';
import { PAGE_DATA_LOAD_REQUEST } from "../../../PagesTree/actionTypes";
import { undoRedoUpdater } from "./updaters/undoRedoUpdaters";
import {
    onRemoveShadowUpdater,
    onSetBlurRadiusUpdater,
    onSetColorUpdater, onSetHighlightColorUpdater,
    onSetShadowColorUpdater,
    onSetShadowOffsetXUpdater,
    onSetShadowOffsetYUpdater
} from "./updaters/changeColorUpdaters";
import {
    removeLinkUpdater, setLinkInContentBlockUpdater, setLinkAcrossContentBlocksUpdater
} from "./updaters/setLinkUpdaters";
import {
    onTextSelectionChangedUpdater,
    onTinyMceDestroyedUpdater,
    onTinyMceInitializedUpdater
} from "./updaters/queryEditorStateUpdater";
import { onToggleBoldUpdater } from "./updaters/onToggleBoldUpdater";
import { onToggleItalicUpdater } from "./updaters/onToggleItalicUpdater";
import { onToggleUnderlineUpdater } from "./updaters/onToggleUnderlineUpdater";
import { onRemoveHightlightColorUpdater } from "./updaters/onRemoveHightlightColorUpdater";
import { setHorizontalAlignmentUpdater } from "./updaters/setHorizontalAlignmentUpdater";
import { onSetTextFontFamilyUpdater } from "./updaters/onSetTextFontFamilyUpdater";
import { onToggleOrderedListUpdater } from "./updaters/onToggleOrderedListUpdater";
import { onToggleUnorderedListUpdater } from "./updaters/onToggleUnorderedListUpdater";
import { onIndentUpdater } from "./updaters/onIndentUpdater";
import { onInsertContentUpdater } from "./updaters/onInsertContentUpdater";
import { onOutdentUpdater } from "./updaters/onOutdentUpdater";
import { subscriptUpdater, superscriptUpdater } from "./updaters/subscriptSuperscriptUpdaters";
import { onSelectGlobalStyleUpdater } from "./updaters/onSelectGlobalStyleUpdater";
import { removeGlobalStylesAndFormatsUpdater } from "./updaters/removeGlobalStylesAndFormatsUpdater";
import { onLinkGlobalStyleSelectedUpdater } from "./updaters/onLinkGlobalStyleSelectedUpdater";
import { onSetCharacterSpacingUpdater } from "./updaters/onSetCharacterSpacingUpdater";
import { onSetLineSpacingUpdater } from "./updaters/onSetLineSpacingUpdater";
import { onSetFontSizeUpdater } from "./updaters/onSetFontSizeUpdater";
import { onRemoveFormatUpdater } from "./updaters/onRemoveFormatUpdater";
import { onSetParagraphSpacingUpdater } from "./updaters/onSetParagraphSpacingUpdater";
import { onFocusLastNodeUpdater } from "./updaters/onFocusLastNodeUpdater";
import { COLOR_THEME_SITE_SETTINGS_EPIC_VALUE } from "../../../SiteSettings/ColorThemeData/colorThemeSiteSettingsVAT";
import type { TinyMceEpicScope, TinyMceEpicState } from "./flowTypes";
import {
    onMouseDownOnPropPanelDraggableAreaUpdater,
    onMouseUpOnPropPanelDraggableAreaUpdater
} from "./updaters/onPropPanelDndUpdater";
import { siteDataValueActionType } from "../siteData/valueActionType";
import { STYLESHEETS_EPIC_VALUE } from '../../../Workspace/epics/stylesheets/valueActionType';
import type { MakeEpicConfig } from "../../../../epics/flowTypes";
import { onSetContentUpdater } from "./updaters/onSetContentUpdater";
import { onPutCursorAtTheEndOfEditorUpdater } from "./updaters/onPutCursorAtTheEndOfEditorUpdater";
import { EditModeComponentSelector } from "../../../Workspace/epics/componentsEval/selectorActionTypes";
import { tinyMceEpicDefaultScope } from "./models/scope";
import { tinyMceEpicDefaultState } from "./models/state";
import {
    cancelMultiProcessingOnComponentIsNotInEditModeAnyMoreUpdater,
    multiProcessingStepUpdater,
    onTinyMceApplyChangeForMultipleInstancesUpdater
} from "./updaters/multiProcessingUpdater";
import {
    TINY_MCE_QUERY_STATE_FOR_GIVEN_CONTENT,
    TINY_MCE_INITIALIZED,
    TINY_MCE_DESTROYED,
    TINY_MCE_SYNC_CONTENT,
} from "./actionTypes";
import { getOffScreenEditor, resetBrowserFocus } from "./updaters/getOffScreenEditor";
import { getEditorState } from "./editorUtils/getEditorState";
import { optional, receiveOnly } from "../../../../epics/makeCondition";
import { tinyMceSelectAllContent } from "./editorUtils/methods/helpers/selectAllContent";
import { TableComponentKind } from '../../../oneweb/Table/kind';
import { TextComponentKind } from '../../../oneweb/Text/kind';
import { TABLE_ACTIVE_CELL_TEXT_CHANGED } from '../../../oneweb/Table/actionTypes';
import { makeApplyEditorChangesReducer } from './updaters/helpers/helpers';
import {
    TEXT_LINKED_VALUE_TOOLBAR_CONVERT_TO_TEXT_CLIKED,
    TEXT_LINKED_VALUE_TOOLBAR_EDIT_CLIKED,
    TEXT_HANDLE_EDITOR_CHANGE,
} from '../../../oneweb/Text/actionTypes';
import { SiteSettingsTabName } from '../../../SiteSettings/SiteSettingsDialog/constants';
import { createScheduledAction } from '../../../../redux/middleware/schedule/actionCreators';
import { openSiteSettingsDialog } from '../../../SiteSettings/SiteSettingsDialog/actionCreators';
import { GENERAL_INFO_SITE_SETTINGS_TAB_FOCUS_INPUT } from '../../../SiteSettings/General/actionTypes';
import { KEY_DOWN } from '../../actionTypes';
import { ESCAPE } from '../isKeyPressed/keyCodes';
import { linkedValueTag } from '../../../oneweb/Text/constants';
import {
    GS_TEXT_NORMAL_FONT_SIZE_CHANGED,
    GS_TEXT_HEADING1_FONT_SIZE_CHANGED,
    GS_TEXT_HEADING2_FONT_SIZE_CHANGED,
    GS_TEXT_HEADING3_FONT_SIZE_CHANGED,
} from '../../../Globalstyles/Text/actionTypes';

export const
    // updaters that used for multiple editors processing
    processUpdaters = [
        setLinkInContentBlockUpdater,
        setLinkAcrossContentBlocksUpdater,
        removeLinkUpdater,
        onToggleBoldUpdater,
        onToggleItalicUpdater,
        onToggleUnderlineUpdater,
        onRemoveHightlightColorUpdater,
        setHorizontalAlignmentUpdater,
        onSetTextFontFamilyUpdater,
        onToggleOrderedListUpdater,
        onToggleUnorderedListUpdater,
        onIndentUpdater,
        onInsertContentUpdater,
        onOutdentUpdater,
        subscriptUpdater,
        superscriptUpdater,
        onSelectGlobalStyleUpdater,
        onLinkGlobalStyleSelectedUpdater,
        onSetCharacterSpacingUpdater,
        onSetLineSpacingUpdater,
        onSetFontSizeUpdater,
        onRemoveFormatUpdater,
        onSetParagraphSpacingUpdater,
        onSetColorUpdater,
        onSetBlurRadiusUpdater,
        onSetShadowColorUpdater,
        onSetShadowOffsetXUpdater,
        onSetShadowOffsetYUpdater,
        onRemoveShadowUpdater,
        onSetHighlightColorUpdater,
        removeGlobalStylesAndFormatsUpdater,
    ],
    epicUpdaterReducersByConditionActionType = processUpdaters.reduce((acc, { conditions, reducer }, index) => {
        if (conditions.length !== 1) {
            throw new Error(`TinyMCE process updater[${index}] should have only single condition. You should read extra dependencies into scope and read it from scope.`); // eslint-disable-line max-len
        }

        acc[conditions[0].toString()] = reducer;

        return acc;
    }, {}),
    epicConfig: MakeEpicConfig<TinyMceEpicState, TinyMceEpicScope, string> = {
        defaultState: tinyMceEpicDefaultState,
        defaultScope: tinyMceEpicDefaultScope,
        valueActionType: tinyMceVAT,
        updaters: [
            {
                conditions: [EditModeComponentSelector],
                reducer: ({ state, scope, values: [component] }) => {
                    const { id, kind } = component || {};
                    let handleEditorChangeActionType: string | null = null;
                    if (kind === TableComponentKind) {
                        handleEditorChangeActionType = TABLE_ACTIVE_CELL_TEXT_CHANGED;
                    } else if (kind === TextComponentKind) {
                        handleEditorChangeActionType = TEXT_HANDLE_EDITOR_CHANGE;
                    }
                    return {
                        state,
                        scope: {
                            ...scope,
                            lastEditModeComponentId: (id || scope.lastEditModeComponentId),
                            handleEditorChangeActionType,
                        },
                    };
                }
            },
            {
                conditions: [TINY_MCE_INITIALIZED],
                reducer: ({ state, scope }) => ({ state, scope, actionToDispatch: { type: 'ENTER_SCOPED_UNDO_MODE' } })
            },
            {
                conditions: [TINY_MCE_DESTROYED],
                reducer: ({ state, scope }) => ({ state, scope, actionToDispatch: { type: 'EXIT_SCOPED_UNDO_MODE' } })
            },
            {
                conditions: [
                    receiveOnly(COLOR_THEME_SITE_SETTINGS_EPIC_VALUE),
                    PAGE_DATA_LOAD_REQUEST,
                ],
                reducer: ({ values: [{ autoColorMode }] }) => (
                    { state: tinyMceEpicDefaultState, scope: { ...tinyMceEpicDefaultScope, autoColorMode } }
                ),
            },
            {
                conditions: [
                    STYLESHEETS_EPIC_VALUE,
                    receiveOnly(siteDataValueActionType)
                ],
                reducer: ({ state, scope, values: [stylesheets, site] }) => ({
                    state,
                    scope: { ...scope, stylesheets, site }
                })
            },
            {
                conditions: [COLOR_THEME_SITE_SETTINGS_EPIC_VALUE],
                reducer: ({ state, scope, values: [{ autoColorMode }] }) => ({ state, scope: { ...scope, autoColorMode } }),
            },
            {
                conditions: [
                    optional(GS_TEXT_NORMAL_FONT_SIZE_CHANGED),
                    optional(GS_TEXT_HEADING1_FONT_SIZE_CHANGED),
                    optional(GS_TEXT_HEADING2_FONT_SIZE_CHANGED),
                    optional(GS_TEXT_HEADING3_FONT_SIZE_CHANGED),
                ],
                reducer: ({ state, scope, values: [site] }) => {
                    try {
                        const editor = getOffScreenEditor();
                        const { autoColorMode, stylesheets } = scope;
                        const editorState = getEditorState({ editor, site, stylesheets, editorWidth: 0, autoColorMode }, false);
                        resetBrowserFocus();
                        return { state: editorState, scope };
                    } catch (e) {
                        return { state, scope };
                    }
                },
            },
            {
                conditions: [
                    TINY_MCE_QUERY_STATE_FOR_GIVEN_CONTENT,
                    receiveOnly(siteDataValueActionType),
                ],
                reducer: ({ scope, values: [content, site] }) => {
                    const editor = getOffScreenEditor();

                    editor.setContent(content);
                    tinyMceSelectAllContent(editor);

                    const { autoColorMode, stylesheets } = scope;
                    const editorState = getEditorState({ editor, site, stylesheets, editorWidth: 0, autoColorMode }, false);
                    resetBrowserFocus();

                    return { state: editorState, scope };
                }
            },
            onTinyMceInitializedUpdater,
            onTinyMceDestroyedUpdater,
            undoRedoUpdater,
            onTextSelectionChangedUpdater,
            onFocusLastNodeUpdater,
            onMouseDownOnPropPanelDraggableAreaUpdater,
            onMouseUpOnPropPanelDraggableAreaUpdater,
            onPutCursorAtTheEndOfEditorUpdater,
            onSetContentUpdater,
            onTinyMceApplyChangeForMultipleInstancesUpdater,
            multiProcessingStepUpdater,
            cancelMultiProcessingOnComponentIsNotInEditModeAnyMoreUpdater,
            {
                conditions: [TINY_MCE_SYNC_CONTENT],
                reducer: makeApplyEditorChangesReducer(({ state, scope }) => {
                    return { state, scope };
                })
            },
            {
                conditions: [TEXT_LINKED_VALUE_TOOLBAR_CONVERT_TO_TEXT_CLIKED],
                reducer: makeApplyEditorChangesReducer(({ state, scope, editor }) => {
                    const selectedNode = editor.selection.getNode();
                    if (selectedNode.nodeName.toLowerCase() === linkedValueTag) {
                        const parentElt = selectedNode.parentElement;
                        Array.from(selectedNode.childNodes).forEach((childNode: Node) => {
                            if (parentElt) { parentElt.insertBefore(childNode, selectedNode); }
                        });
                        selectedNode.remove();
                        if (parentElt) { parentElt.normalize(); }
                    }
                    return { state: { ...state, selectedLinkedNode: null }, scope };
                })
            },
            {
                conditions: [KEY_DOWN],
                reducer: ({ state, scope, values: [{ keyCode, preventDefault }] }) => {
                    if (state.selectedLinkedNode && keyCode === ESCAPE) {
                        preventDefault();
                        return {
                            state: { ...state, selectedLinkedNode: tinyMceEpicDefaultState.selectedLinkedNode },
                            scope
                        };
                    }
                    return { state, scope };
                }
            },
            {
                conditions: [TEXT_LINKED_VALUE_TOOLBAR_EDIT_CLIKED],
                reducer: ({ state, scope }) => {
                    if (!state.selectedLinkedNode) return { state, scope };
                    const { fieldName } = state.selectedLinkedNode;
                    return {
                        state,
                        scope,
                        multipleActionsToDispatch: [
                            openSiteSettingsDialog({ activeTabKey: SiteSettingsTabName.GENERAL }),
                            createScheduledAction({
                                actionToDispatch: ({
                                    type: GENERAL_INFO_SITE_SETTINGS_TAB_FOCUS_INPUT,
                                    payload: fieldName
                                } as any),
                                timeout: 0
                            })
                        ]
                    };
                }
            },
            ...processUpdaters,
        ]
    },
    tinyMceStateEpic = makeEpic(epicConfig);
