import { parseDOM } from "../../../../../utils/parseDOM";
import { eventbriteLink } from '../constants';
import { type EventbriteComponent } from '../types';

export const parseEventbritePostCode = (code: string): EventbriteComponent => {
    let params = <EventbriteComponent>{};

    const configs1 = [
        {
            nodeName: 'A',
            validator: node => node.href.startsWith("https://www.eventbrite.com/e/"),
            attributes: {
                href: 'link'
            }
        }
    ];
    params = parseDOM(configs1, code);

    if (!params.link) {
        const configs2 = [
            {
                nodeName: 'DIV',
                validator: node => /^eventbrite\-widget\-container\-\d+/.test(node.id),
                parser: node => {
                    const matches = node.id.match(/^eventbrite\-widget\-container\-(\d+)/);
                    return Array.isArray(matches) && matches[1] ? { link: `${eventbriteLink}${matches[1]}` } : {};
                }
            }
        ];

        params = parseDOM(configs2, code);
    }

    return params;
};
