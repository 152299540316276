export const
    GradientDirection = {
        VERTICAL: 'vertical',
        HORIZONTAL: 'horizontal'
    },
    GradientDirectionOptions = [
        {
            label: 'msg: common.vertical {Vertical}',
            value: GradientDirection.VERTICAL
        },
        {
            label: 'msg: common.horizontal {Horizontal}',
            value: GradientDirection.HORIZONTAL
        }
    ],
    Degree = {
        MIN: 0,
        MAX: 360
    },
    FadePoint = {
        MIN: 0,
        MAX: 100
    };
