import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import { openDialog } from "../../../App/actionCreators/index";
import { KEYBOARD_SHORTCUTS_CLICKED } from "../../actionTypes";
import { KeyboardShortcutsId } from "../../view/dialogIds";

export default makeEpic({
    defaultState: null,
    valueActionType,
    updaters: [
        {
            conditions: [KEYBOARD_SHORTCUTS_CLICKED],
            reducer: ({ state }) => ({
                state,
                actionToDispatch: openDialog(KeyboardShortcutsId)
            })
        }
    ]
});
