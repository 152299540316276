import React from "react";
import { useIntl } from "../../../../view/intl";
import { PAGES_TREE_DISABLED_PAGE } from "../constants";

import styles from './PagesTree.css';

export const DisabledNodeOverlay = () => {
    const intl = useIntl();
    return (
        <span
            data-title={intl.msgJoint(PAGES_TREE_DISABLED_PAGE)}
            data-title-position="right"
            className={styles.disabledNodeOverlay}
        />
    );
};
