/* globals one */

import { stringify } from 'flatted';
import LZString from 'lz-string';
import * as R from 'ramda';
import { makeUuidNoDash } from "../utils/makeUuid";
import { getDAL, getNetworkLog } from "../dal/index";
import {
    getCrashTraceNoActionStringified,
    getCrashTraceStringified
} from "./redux/middleware/crashTraceCollector";
import { customSendReport } from "./customSendCrashReport";
import { recoveryDataDefaultState } from "./redux/recoverAfterException/index";
import registerDebugModule from "./debug/registerDebugModule";
import { printHelpAfterExeption } from "./debug/qaHelpers";
import { error as logError } from "../utils/log";

import type { ExData } from "./debug/flowTypes";
import type { AppState } from "./redux/modules/flowTypes";
import localStorage from "./utils/localStorage";
import getRecordId from "./debug/getRecordId";
import { currentPageIdEpicStateFromAppStateSelector } from "./components/App/epics/currentPageId/index";
import { sendEventToAec } from './components/App/actionCreators/sendEventToAecActionCreator';
import { flowFriendlySpread } from './utils/flowFriendlySpread';

registerDebugModule('errorReportsById', {});

export type ErrorReport = {
    type: string,
    crashReportId: string,
    url: string,
    message: string,
    domain: string,
    codeVersion: string,
    time: string,
    useragent: string,
    locationInCode: string,
    lastActionBeforeException: string,
    latestActionsTypesChain: string,
    errorLocationInEpics: string,
    stackTrace: string,
    exData: ExData,
    devicePixelRatio: number,
    pageId: string,
    networkLog: Array<string>
}

type StackFrame = {
    column: number,
    context: Array<string>,
    func: string,
    line: number,
    url: string
}

type TraceKitError = {
    error: Record<string, any>,
    mode: 'stack' | 'stacktrace' | 'multiline' | 'callers' | 'onerror' | 'failed',
    name: string,
    message: string,
    url: string,
    stack: Array<StackFrame>,
    useragent: string,
}

let
    exeptionIdCounter = 0,
    lastExtendedReportSentTimestamp = 0,
    _store;

/* https://stackoverflow.com/questions/5063489/how-can-you-get-the-css-pixel-device-pixel-ratio */
function getDevicePixelRatio() {
    let ratio = 1;
    // To account for zoom, change to use deviceXDPI instead of systemXDPI
    if (
        // @ts-ignore
        window.screen.systemXDPI !== undefined
        // @ts-ignore
        && window.screen.logicalXDPI !== undefined
        // @ts-ignore
        && window.screen.systemXDPI > window.screen.logicalXDPI
    ) {
        // Only allow for values > 1
        // @ts-ignore
        ratio = window.screen.systemXDPI / window.screen.logicalXDPI;
    } else if (window.devicePixelRatio !== undefined) {
        ratio = window.devicePixelRatio;
    }
    return ratio;
}

const
    initReportError = (store: Store) => {
        _store = store;
    },
    maximumTrackedExceptionsCount = 3,
    maximumErrorsPerMinute = 20,
    errorTimestampsByMessage = {},
    // TODO WBTGEN-5085 reproduce exceptions pushed to Kibana
    shouldSendExDataReport = false,

    makeTruncateIfLonger = (maxLen: number) => (str: string) => {
        if (str.length > maxLen) {
            return str.substr(0, maxLen);
        } else {
            return str;
        }
    },
    KB = 1024,
    MB = KB * KB,
    truncateIfLonger4KB = makeTruncateIfLonger(4 * KB),
    truncateIfLonger256KB = makeTruncateIfLonger(256 * KB),

    executeSafely = (fn) => {
        try {
            fn();
        } catch (e) {
            // to avoid recursive error reporting in unknown case
        }
    },

    minute = 60 * 1000,
    getTime = () => {
        const
            now = Date.now(),
            minuteAgo = now - minute,
            fiveMinutesAgo = now - (5 * minute);

        return { now, minuteAgo, fiveMinutesAgo };
    },
    sendExDataReport = ({
        crashReportId,
        exData
    }) => {
        const
            { now, fiveMinutesAgo } = getTime(),
            canSendExtendedReport = fiveMinutesAgo > lastExtendedReportSentTimestamp;

        if (exData && canSendExtendedReport) {
            const
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                { exception, ...exDataWithoutException } = exData,
                exDataStringified = stringify(exDataWithoutException),
                // compressing is time expensive, so don't try to send extended report more frequent that every 5 minutes
                compressedExData = LZString.compress(exDataStringified);

            customSendReport({
                crashReportId,
                message: `WBTGEN_EXDATA_REPORT ${crashReportId}`,
                additionalInfo: {
                    exDataStr: compressedExData.length < MB ? compressedExData : 'was longer that 1MB, so skipped.'
                }
            });
            lastExtendedReportSentTimestamp = now;
        }
    },
    executeSafelyWithTimeout = (fn, delay) => {
        setTimeout(() => executeSafely(fn), delay);
    };

export const
    sanitizeAppState = (appState: AppState): AppState => {
        // we have to drop recoveryData, due it is huge and not required for 99.9% of errors
        return flowFriendlySpread((appStateClone) => { appStateClone.recoveryData = recoveryDataDefaultState; }, appState); // eslint-disable-line no-param-reassign
    },
    getErrorLocationInEpics = (exData: ExData) => {
        switch (exData.type) {
            case 'EPIC_UPDATER_EVAL': {
                const { actionsTypesChain, subVat, reducerIndex, valueActionType } = exData;
                return `${actionsTypesChain.join(' -> ')} => ${valueActionType}[${reducerIndex}][${subVat}]`; // eslint-disable-line max-len
            }
            default:
                return '';
        }
    },
    getActionTypeTriggeredError = (exData?: ExData) => {
        if (!exData) {
            return '';
        }
        switch (exData.type) {
            case 'EPIC_UPDATER_EVAL':
                return exData.actionsTypesChain[0];
            case 'REDUCER_EXCEPTION':
            case 'MIDDLEWARE_EXCEPTION':
                return exData.action.type;
            default:
                return '';
        }
    },
    getLastActionBeforeException = (action: AnyAction | PlainAction | null) => {
        if (action) {
            const { type, ...rest } = action;
            return `${type}: ${truncateIfLonger256KB(JSON.stringify(rest))}`;
        }
        return 'unknown';
    },
    reportError = (traceKitError: TraceKitError) => {
        executeSafely(() => {
            if (exeptionIdCounter === maximumTrackedExceptionsCount) {
                exeptionIdCounter = maximumTrackedExceptionsCount - 1;
            }

            const inBrowserId = `${exeptionIdCounter++}`;

            const
                error = traceKitError.error,
                {
                    exData = {
                        type: 'UNHANDLED_EXCEPTION',
                        exception: error,
                        stateAfterException: _store ? _store.getState() : { error: '_store is not initialized' }
                    }
                } = error;

            // exData is passed via error due to it's only way to get stack trace from traceKit
            // once we receive it here, we need to remove it from error obj, it will be send to server as separate request
            delete error.exData;
            if (window.___nodeTests) {
                return;
            }

            let domain = '_unknown';

            try {
                domain = getDAL().getDomain();
            } catch (e) {
                // nothing
            }

            const
                networkLog = getNetworkLog(),
                pageId = currentPageIdEpicStateFromAppStateSelector(_store.getState()),
                codeVersion = window.appVersion,
                crashReportId = makeUuidNoDash(),
                actionsTraceStrMin = truncateIfLonger4KB(getCrashTraceNoActionStringified()),
                devicePixelRatio = getDevicePixelRatio(),
                additionalInfo: Record<string, any> = {
                    // TODO WBTGEN-4983 Send full app state in case of exception as second request
                    // required as a reference, to find full app state sent by second request
                    domain,
                    codeVersion,
                    actionsTraceStrMin,
                    devicePixelRatio,
                    pageId,
                    networkLog
                },
                errorLocationInEpics = getErrorLocationInEpics(exData),
                actionTypeTriggeredException = getActionTypeTriggeredError(exData);

            if (errorLocationInEpics) {
                additionalInfo.errorLocationInEpics = errorLocationInEpics;
            }

            if (actionTypeTriggeredException) {
                additionalInfo.actionTypeTriggeredException = actionTypeTriggeredException;
            }

            const
                { now, minuteAgo } = getTime(),
                errorWithSameMessageHappenedLessThanMinuteAgo = errorTimestampsByMessage[error.message] > minuteAgo;

            errorTimestampsByMessage[error.message] = now;

            error.crashReportId = crashReportId; // eslint-disable-line no-param-reassign
            error.additionalInfo = additionalInfo; // eslint-disable-line no-param-reassign

            const sendReportIsAllowed =
                !errorWithSameMessageHappenedLessThanMinuteAgo &&
                R.values(errorTimestampsByMessage).filter(ts => ts > minuteAgo).length < maximumErrorsPerMinute;

            if (sendReportIsAllowed) {
                // @ts-ignore
                one.crashReporter.sendReportFromTraceKit(traceKitError);
                const
                    actionsTraceStr = truncateIfLonger256KB(getCrashTraceStringified()), // This line should not be moved inside timeout callback, as it should be in sync with actionsTraceStrMin
                    firstFrame = Array.isArray(traceKitError.stack) && traceKitError.stack.length > 0 ?
                        traceKitError.stack[0] : null,
                    fileName = firstFrame ? firstFrame.url : undefined,
                    lineNumber = firstFrame ? firstFrame.line : undefined;

                executeSafelyWithTimeout(() => {
                    customSendReport({
                        crashReportId,
                        message: error.message,
                        additionalInfo: { ...additionalInfo, actionsTraceStr },
                        fileName,
                        lineNumber
                    });
                }, 1000);
                if (shouldSendExDataReport && exData) {
                    const props = { crashReportId, exData };
                    executeSafelyWithTimeout(() => sendExDataReport(props), 2000);
                }
            }

            if (process.env.NODE_ENV !== 'production') {
                logError(`
${exData.type} exception registered.
To reproduce it, open call window.debug.reproduceException('${inBrowserId}');
exData:
`, exData);
            }

            _store.dispatch(sendEventToAec({
                category: 'error',
                action: 'error',
                opt_label: error.fileName
            }));

            const
                report: ErrorReport = {
                    crashReportId,
                    url: error.pageName,
                    message: error.message,
                    domain,
                    codeVersion,
                    time: new Date(now).toString(),
                    useragent: traceKitError.useragent,
                    locationInCode: error.fileName,
                    latestActionsTypesChain: actionsTraceStrMin,
                    type: exData.type,
                    errorLocationInEpics,
                    stackTrace: error.stack,
                    lastActionBeforeException: getLastActionBeforeException(exData.action ? exData.action : null),
                    exData,
                    devicePixelRatio,
                    pageId,
                    networkLog
                };

            // @ts-ignore
            window.debug.errorReportsById[inBrowserId] = report;
            printHelpAfterExeption(inBrowserId);

            if (process.env.NODE_ENV !== 'production') {
                localStorage.set(getRecordId(inBrowserId), stringify(exData), true);
            }
        });
    };

export {
    initReportError
};
