import type { Element } from '../../flowTypes';
import { include } from '../helpers/setOperations';
import { LIST_STYLES } from '../constants';

const includeListStyles = include(LIST_STYLES);
export default (element: Element, index?: number): Element => {
    const { start, end } = element;

    return {
        ...includeListStyles(element),
        start,
        end,
        atype: 'listItem',
        index
    };
};
