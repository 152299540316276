import makeEpic from '../../../../epics/makeEpic';
import { WINDOW_RESIZED } from "../../../../redux/modules/actionTypes";
import valueActionType from './valueActionType';

export default makeEpic({
    valueActionType,
    updaters: [{
        conditions: [WINDOW_RESIZED],
        reducer: ({ values: [payload] }) => ({ state: payload })
    }]
});
