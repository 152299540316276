import * as R from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import { isComponentHandle } from '../../../../utils/handle';
import topMostHandleValueActionType from '../../../Workspace/epics/topMostHandle/valueActionType';
import { UserInteractionModeSelector, ReceiveOnlyComponentsMap } from '../../../Workspace/epics/componentsEval/selectorActionTypes';
import * as interactionModes from "../../../Workspace/epics/componentsEval/userInteractionMutations/interactionModes";
import valueActionType from './valueActionType';
import Cursors from "./Cursors";
import * as HandleKinds from '../../../../utils/handle/kinds';
import isSliderDraggingValueActionType from '../../../Workspace/epics/isSliderDragging/valueActionType';
import { DndAddComponentVisible } from "../../../DndAddComponent/epic/selectorActionTypes";
import { PropertiesPanelIsMoving } from "../../../PropertiesPanel/epic/selectorActionTypes";
import {
    SelectionFrameDecorationIsVisible
} from "../../../Workspace/epics/selectionFrameDecoration/selectorActionTypes";

import type { Handle, ComponentsMap } from "../../../../redux/modules/children/workspace/flowTypes";
import type { EpicUpdaterConfig } from "../../../../epics/flowTypes";
import { hideCursor } from "../../../../utils/isDebug";
import { receiveOnly } from "../../../../epics/makeCondition";
import { OptionalIsShiftOrCtrlLikePressedActionType } from "../isKeyPressed/selectorActionTypes";
import { SocialIconsSortingInProgressSelector } from '../../../oneweb/Social/epics/socialServiceEpic/socialServiceEpic';
import { isSectionComponent } from "../../../oneweb/Section/utils";
import { isModernLayoutActivatedVAT } from "../../../Workspace/epics/isModernLayoutActivatedEpic/valueActionType";
import { hoveredComponentIsInsideTemplateSectionVAT } from "../../../Workspace/epics/hoveredComponentInsideTemplateSection";

type State = string;

type Scope = {
    locked: boolean;
};

const
    handleToCursorMap = {
        [HandleKinds.ShiftBarBottomHover]: Cursors.rowResize,
        [HandleKinds.ShiftBarBottomSelection]: Cursors.rowResize,
        [HandleKinds.ShiftBarTopHover]: Cursors.rowResize,
        [HandleKinds.ShiftBarTopSelection]: Cursors.rowResize,
        [HandleKinds.TemplateLines]: Cursors.ewResize,
        [HandleKinds.ResizeE]: Cursors.ewResize,
        [HandleKinds.ResizeN]: Cursors.nsResize,
        [HandleKinds.ResizeW]: Cursors.ewResize,
        [HandleKinds.ResizeS]: Cursors.nsResize,
        [HandleKinds.ResizeNE]: Cursors.neswResize,
        [HandleKinds.ResizeSW]: Cursors.neswResize,
        [HandleKinds.ResizeNW]: Cursors.nwseResize,
        [HandleKinds.ResizeSE]: Cursors.nwseResize,
        [HandleKinds.AddIconN]: Cursors.pointer,
        [HandleKinds.AddIconS]: Cursors.pointer,
        [HandleKinds.Workspace]: Cursors.no,
        [HandleKinds.LeftPanel]: Cursors.no,
        [HandleKinds.GhostComponentsHeadSection]: Cursors.no,
        [HandleKinds.GhostComponentsBodyEndSection]: Cursors.no,
        [HandleKinds.DisableMoveStub]: Cursors.no,
        [HandleKinds.Component]: Cursors.move,
        [HandleKinds.WpcAbove]: Cursors.no,
        [HandleKinds.WpcBelow]: Cursors.no,
        [HandleKinds.WpcLeft]: Cursors.no,
        [HandleKinds.WpcRight]: Cursors.no,
    },
    setLocked = R.assocPath(['locked']),
    lock = setLocked(true),
    unlock = setLocked(false),
    // eslint-disable-next-line max-len
    topmostHandleUpdater: EpicUpdaterConfig<State, Scope, string, [Handle, boolean, ComponentsMap, Record<string, any>, Record<string, any>]> = {
        conditions: [
            topMostHandleValueActionType,
            OptionalIsShiftOrCtrlLikePressedActionType,
            ReceiveOnlyComponentsMap,
            receiveOnly(isModernLayoutActivatedVAT),
            hoveredComponentIsInsideTemplateSectionVAT,
        ],
        reducer: ({ values: [
            topMostHandle,
            shiftOrCtrlLikePressed,
            componentsMap,
            isModernLayoutActivated,
            { isInsideHeaderOrFooter: isHoveredComponentInsideHeaderOrFooter },
        ], state, scope }) => {
            const isHoveredComponentBelongsToModernHeaderFooter =
                isModernLayoutActivated && isHoveredComponentInsideHeaderOrFooter;
            if (isHoveredComponentBelongsToModernHeaderFooter) {
                return {
                    state: Cursors.pointer,
                    scope
                };
            }

            if (scope && scope.locked) {
                return { state, scope };
            }

            let newState;
            if (topMostHandle) {
                if (isComponentHandle(topMostHandle.kind)) {
                    const [cmpId] = topMostHandle.componentsIds,
                        component = componentsMap[cmpId];
                    if (shiftOrCtrlLikePressed) {
                        newState = Cursors.pointer;
                    } else if (component && isSectionComponent(component)) {
                        newState = Cursors.default;
                    }
                }
                newState = newState || handleToCursorMap[topMostHandle.kind];
            } else {
                newState = Cursors.no;
            }

            return {
                state: newState,
                scope
            };
        }
    },
    actorUpdaterFactory = ({ actorActionType, actingCursor }) => ({
        conditions: [actorActionType],
        reducer: ({ values: [isActing], scope }) => {
            if (isActing) {
                return { state: actingCursor, scope: lock(scope) };
            } else {
                return { state: Cursors.no, scope: unlock(scope) };
            }
        }
    });

const
    forceHideCursor = hideCursor(),
    defaultState = forceHideCursor ? Cursors.none : Cursors.no,
    updaters = forceHideCursor ? [] : [
        topmostHandleUpdater,
        {
            conditions: [
                UserInteractionModeSelector,
                receiveOnly(topMostHandleValueActionType)
            ],
            reducer: ({ values: [mode, topMostHandle], state, scope }) => {
                switch (mode) {
                    case interactionModes.MOVING_COMPONENTS:
                        return { state: Cursors.move, scope: lock(scope) };
                    case interactionModes.RESIZE_HANDLE_MOVING:
                    case interactionModes.SHIFTBAR_MOVING:
                        return { state, scope: lock(scope) };
                    case interactionModes.IDLE:
                        if (scope.locked) {
                            return {
                                state: topMostHandle ? handleToCursorMap[topMostHandle.kind] : Cursors.no,
                                scope: unlock(scope)
                            };
                        }
                        break;
                    default:
                        break;
                }
                return { state, scope };
            }
        },
        actorUpdaterFactory({ actorActionType: DndAddComponentVisible, actingCursor: Cursors.grabbing }),
        actorUpdaterFactory({ actorActionType: PropertiesPanelIsMoving, actingCursor: Cursors.move }),
        actorUpdaterFactory({ actorActionType: SelectionFrameDecorationIsVisible, actingCursor: Cursors.default }),
        actorUpdaterFactory({ actorActionType: isSliderDraggingValueActionType, actingCursor: Cursors.grabbing }),
        actorUpdaterFactory({ actorActionType: SocialIconsSortingInProgressSelector, actingCursor: Cursors.grabbing }),
    ];

export default makeEpic({
    defaultState,
    defaultScope: { locked: false },
    valueActionType,
    updaters
});

export { topmostHandleUpdater };
