import * as React from 'react';
import cx from 'classnames';
import styles from './PropertiesPanelSection.css';
import type { MsgJointInput, Intl } from "../../../../../view/intl/index";
import { injectIntl } from "../../../../../view/intl/index";

type Props = {
    title?: null | MsgJointInput,
    children: React.ReactNode,
    intl: Intl,
    contentClassName?: string,
    containerClassName?: string,
};

export const PropertiesPanelSection = injectIntl(
    ({ title, children, contentClassName, containerClassName, intl }: Props) => (
        <div className={cx(containerClassName, styles.container)}>
            { title && <div className={styles.title}>{intl.msgJoint(title)}</div> }
            <div className={contentClassName}>{children}</div>
        </div>
    ),
);
