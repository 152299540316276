import { apiAction } from "../../../src/redux/middleware/api/actions";

export const
    GET_TRIAL_GPT_DEMO_DATA_REQUEST_ACTION = 'GET_TRIAL_GPT_DEMO_DATA_REQUEST_ACTION',
    GET_TRIAL_GPT_DEMO_DATA_SUCCESS_ACTION = 'GET_TRIAL_GPT_DEMO_DATA_SUCCESS_ACTION',
    GET_TRIAL_GPT_DEMO_DATA_FAILURE_ACTION = 'GET_TRIAL_GPT_DEMO_DATA_FAILURE_ACTION',
    getTrialGptDemoDataAction = (id: string) => apiAction({
        types: [
            GET_TRIAL_GPT_DEMO_DATA_REQUEST_ACTION,
            GET_TRIAL_GPT_DEMO_DATA_SUCCESS_ACTION,
            GET_TRIAL_GPT_DEMO_DATA_FAILURE_ACTION,
        ],
        endpoint: 'getTrialGptDemoData',
        endpointParams: [id],
    });
