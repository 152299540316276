import React from 'react';
import factory from '../factory';
import { getShareButtonsApiUrls } from '../utils';
import ShareButtonsBase from './ShareButtonsBase';
import ShareButtons from './ShareButtons';
import type { ShareButtonsCalcRenderPropsResult } from '../flowTypes';

export default class ShareButtonsLocaleWrapper extends ShareButtonsBase {
    /**
     * https://flow.org/en/docs/react/types/
     * Update the type for RenderComponent correctly
     */
    RenderComponent: any;

    constructor(props: ShareButtonsCalcRenderPropsResult) {
        super(props);
        this.state = { time: Date.now() };
        this.RenderComponent = factory(getShareButtonsApiUrls(this.props, this.state.time))(ShareButtons);
    }

    UNSAFE_componentWillReceiveProps(nextProps: ShareButtonsCalcRenderPropsResult) {
        if (this.props.locale !== nextProps.locale) {
            const time = Date.now();
            this.setState({ time });
            this.RenderComponent = factory(getShareButtonsApiUrls(nextProps, time))(ShareButtons);
        }
    }

    render() {
        const { RenderComponent } = this;
        return (<RenderComponent {...this.props} />);
    }
}
