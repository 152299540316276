const
    BackgroundRepeat = {
        NONE: 'no-repeat',
        HORIZONTAL: 'repeat-x',
        VERTICAL: 'repeat-y',
        BOTH: 'repeat'
    },
    ReverseBackgroundRepeat = {
        [BackgroundRepeat.NONE]: [false, false],
        [BackgroundRepeat.HORIZONTAL]: [true, false],
        [BackgroundRepeat.VERTICAL]: [false, true],
        [BackgroundRepeat.BOTH]: [true, true]
    },
    BackgroundRepeatOptions = [{
        label: 'msg: common.none {None}',
        value: BackgroundRepeat.NONE
    }, {
        label: 'msg: common.horizontal {Horizontal}',
        value: BackgroundRepeat.HORIZONTAL
    }, {
        label: 'msg: common.vertical {Vertical}',
        value: BackgroundRepeat.VERTICAL
    }, {
        label: 'msg: common.repeat.both {Both}',
        value: BackgroundRepeat.BOTH
    }],
    BackgroundSize = {
        FIT: 'contain',
        FILL: 'cover',
        ZOOM: 100
    },
    BackgroundSizeOptions = [{
        label: 'msg: common.fitContentToFrame {Fit content to frame}',
        value: BackgroundSize.FIT
    }, {
        label: 'msg: common.background.size.fillFrameProportionally {Fill frame proportionally}',
        value: BackgroundSize.FILL
    }, {
        label: 'msg: common.originalSize {Original size}',
        value: BackgroundSize.ZOOM
    }],
    MobileBackgroundImageSizeOptions = [{
        label: 'msg: common.fitContentToFrame {Fit content to frame}',
        value: BackgroundSize.FIT
    }, {
        label: 'msg: common.background.size.fillFrameProportionally {Fill frame proportionally}',
        value: BackgroundSize.FILL
    }],
    DefaultAssetData = {
        opacity: 1,
        asset: null,
        repeat: [true, true] as [boolean, boolean],
        overlay: 'none',
        position: ['0%', '0%'] as [string, string],
        size: 100,
        scrollEffect: null
    },
    BackgroundImagePositionMsgMap = {
        '0,0': 'msg: bg.imagePosition.topLeft {Top, Left}',
        '50,0': 'msg: bg.imagePosition.topCenter {Top, Center}',
        '100,0': 'msg: bg.imagePosition.topRight {Top, Right}',
        '0,50': 'msg: bg.imagePosition.middleLeft {Middle, Left}',
        '50,50': 'msg: bg.imagePosition.middleCenter {Middle, Center}',
        '100,50': 'msg: bg.imagePosition.middleRight {Middle, Right}',
        '0,100': 'msg: bg.imagePosition.bottomLeft {Bottom, Left}',
        '50,100': 'msg: bg.imagePosition.bottomCenter {Bottom, Center}',
        '100,100': 'msg: bg.imagePosition.bottomRight {Bottom, Right}'
    };
export {
    BackgroundRepeat,
    ReverseBackgroundRepeat,
    BackgroundRepeatOptions,
    BackgroundSize,
    BackgroundSizeOptions,
    DefaultAssetData,
    MobileBackgroundImageSizeOptions,
    BackgroundImagePositionMsgMap
};
