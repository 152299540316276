import { $Diff } from "utility-types";
import * as React from 'react';
import type { HoverBoxModalProps } from "../HoverBox/HoverBoxModalCom";
import { Intl } from "../../../view/intl/index";
import { HoverBoxViewBtnCom } from "./HoverBoxViewBtnCom";
import { HoverBoxModalCom } from "../HoverBox/index";

// @ts-ignore
type Props = $Diff<HoverBoxModalProps & { intl: Intl }> & { onClick: React.MouseEventHandler };

export const HoverBoxModalBtnCom = ({ onClick, ...props }: Props) => (
    <React.Fragment>
        <HoverBoxViewBtnCom onClick={onClick} />
        <HoverBoxModalCom {...props} />
    </React.Fragment>
);

