import TableGlobalStyleKind from "./globalStyle/kind";
import {
    DefaultContentOnDrop,
    DEFAULT_BLUR_RADIUS,
    DEFAULT_SHADOW_OFFSET_X,
    DEFAULT_SHADOW_OFFSET_Y,
} from "../Text/constants";

export const DefaultCellContentOnDrop = DefaultContentOnDrop;

const
    generateDefaultCells = (padding, ha, va, height, numRows, numCols) => {
        let i = 0, j = 0;

        const
            flexWidth = parseFloat((1 / numCols).toFixed(3)),
            // @ts-ignore
            minHeight = parseInt((height / numRows), 10),
            cells: any = [];

        for (i = 0; i < numRows; i++) {
            for (j = 0; j < numCols; j++) {
                cells.push({
                    content: DefaultCellContentOnDrop,
                    cellInfo: { rowIndex: i, colIndex: j, flexWidth, minHeight },
                    style: {
                        type: TableGlobalStyleKind,
                        globalId: "",
                        globalName: "[normal]",
                        block: {
                            border: {
                                color: null,
                                corners: [0, 0, 0, 0],
                                style: 'solid',
                                width: [1, 1, 1, 1]
                            },
                            background: {
                                colorData: {
                                    color: null,
                                    gradient: null
                                },
                                assetData: null
                            },
                            padding
                        },
                        text: {
                            color: null,
                            font: null,
                            size: null,
                            themeColor: null,
                            themeHighlightColor: null,
                            themeShadowBlurRadius: DEFAULT_BLUR_RADIUS,
                            themeShadowColor: null,
                            themeShadowOffsetX: DEFAULT_SHADOW_OFFSET_X,
                            themeShadowOffsetY: DEFAULT_SHADOW_OFFSET_Y,
                        },
                        horizontalAlign: ha,
                        verticalAlign: va
                    }
                });
            }
        }

        return cells;
    };

// these are the styles, available for customization, for the table global styles
export const TABLE_GLOBAL_STYLE_FORMATS: string[] = [
    'fontFamily', 'fontSize', 'bold', 'italic', 'underline', 'characterSpacing', 'textshadow', 'color',
    'hilitecolor', 'alignleft', 'aligncenter', 'alignright', 'alignjustify'
];

export const
    DEFAULT_PADDING = null,
    DEFAULT_WIDTH = 300,
    DEFAULT_HEIGHT = 200,
    DEFAULT_NUM_ROWS = 10,
    DEFAULT_NUM_COLS = 3,
    DEFAULT_HORIZONTAL_ALIGN = 'left',
    DEFAULT_VERTICAL_ALIGN = 'middle',
    defaultState = {
        cells: generateDefaultCells(
            DEFAULT_PADDING,
            null,
            null,
            DEFAULT_HEIGHT,
            DEFAULT_NUM_ROWS,
            DEFAULT_NUM_COLS
        ),
        mobileDown: false,
        mobileHide: false,
        commonCellsData: {}
    },
    tableFirstCellReductionWidth = 35,
    MIN_CELL_HEIGHT = 1,
    MIN_CELL_WIDTH = 1,
    MIN_TABLE_WIDTH = 20;
