import * as R from 'ramda';
import * as path from '../../../mappers/path';
import * as selectors from '../../Workspace/epics/stylesheets/selectors';
import { DEFAULT_SHADOW } from '../../oneweb/Text/constants';
import TextGlobalStyleKind from '../../oneweb/Text/globalStyle/kind';
import { setToPath } from '../../../utils/ramdaEx';
import * as updateReasons from '../../Workspace/epics/stylesheets/updateReasons';
import { WINDOW_FORCE_REPAINT_REFLOW } from '../../../redux/middleware/window/actionTypes';
import type { TextStylesheetEpicUpdater } from '../flowTypes';
import type { Stylesheets } from '../../Workspace/epics/stylesheets/flowTypes';

type TextStylesheetAction = {
    action: string,
    ref: string
}

type EpicUpdaterFactory = (actionTypes: Array<TextStylesheetAction>) => Array<TextStylesheetEpicUpdater>

const
    getStyleToUpdate = ref => R.pipe(
        selectors.getAllStylesheets,
        R.filter(style => style.type === TextGlobalStyleKind && style.ref === ref),
        R.head
    ),
    getStyleIndex = style => R.pipe(R.prop('styles'), R.indexOf(style)),
    updateStyle = (oldStyle, newStyle, styles) =>
        setToPath(['styles', getStyleIndex(oldStyle)(styles)], newStyle, styles),
    toggleFontStyle = (ref, stylesheets, styleProp) => {
        const
            styleToUpdate = getStyleToUpdate(ref)(stylesheets),
            updatedStyle = { ...styleToUpdate, [styleProp]: !styleToUpdate[styleProp] };

        return updateStyle(styleToUpdate, updatedStyle, stylesheets);
    },
    updateGlobalstylesShadow = (ref, stylesheets, shadowProp) => {
        const
            styleToUpdate = getStyleToUpdate(ref)(stylesheets),
            updatedStyle = {
                ...styleToUpdate,
                shadow: { ...DEFAULT_SHADOW, ...styleToUpdate.shadow, ...shadowProp }
            };

        return updateStyle(styleToUpdate, updatedStyle, stylesheets);
    },
    removeGlobalStyleShadow = (ref, stylesheets) => {
        const
            styleToUpdate = getStyleToUpdate(ref)(stylesheets),
            updatedStyle = R.dissoc(path.shadow, styleToUpdate);

        return updateStyle(styleToUpdate, updatedStyle, stylesheets);
    };

export const
    updateGlobalstyles = (ref: string, stylesheets: Stylesheets, data: Record<string, any>) => {
        const
            styleToUpdate = getStyleToUpdate(ref)(stylesheets),
            updatedStyle = { ...styleToUpdate, ...data };

        return updateStyle(styleToUpdate, updatedStyle, stylesheets);
    },
    googleFontEpicUpdater: EpicUpdaterFactory = actions => actions.map(({ action }) => ({
        conditions: [action],
        reducer: ({ values: [{ googleFont, additionalPayload }], state: stylesheets }) => {
            if (additionalPayload && additionalPayload.source === TextGlobalStyleKind) {
                return {
                    state: updateGlobalstyles(additionalPayload.ref, stylesheets, { font: googleFont }),
                    updateReason: updateReasons.TEXT_FONT_CHANGED_CHANGED
                };
            }

            return { state: stylesheets };
        }
    })),
    getFontFamilyEpicUpdaters: EpicUpdaterFactory = actions => actions.map(({ action, ref }) => ({
        conditions: [action],
        reducer: ({ values: [font], state: stylesheets }) => ({
            state: updateGlobalstyles(ref, stylesheets, { font }),
            updateReason: updateReasons.TEXT_FONT_FAMILY_CHANGED
        })
    })),
    getFontBoldToggleEpicUpdaters: EpicUpdaterFactory = actions => actions.map(({ action, ref }) => ({
        conditions: [action],
        reducer: ({ state: stylesheets }) => ({
            state: toggleFontStyle(ref, stylesheets, path.bold),
            updateReason: updateReasons.TEXT_BOLD_CHANGED
        })
    })),
    getFontItalicToggleEpicUpdaters: EpicUpdaterFactory = actions => actions.map(({ action, ref }) => ({
        conditions: [action],
        reducer: ({ state: stylesheets }) => ({
            state: toggleFontStyle(ref, stylesheets, path.italic),
            updateReason: updateReasons.TEXT_STYLE_CHANGED
        })
    })),
    getFontUnderlineToggleEpicUpdaters: EpicUpdaterFactory = actions => actions.map(({ action, ref }) => ({
        conditions: [action],
        reducer: ({ state: stylesheets }) => ({
            state: toggleFontStyle(ref, stylesheets, path.underline),
            updateReason: updateReasons.TEXT_STYLE_CHANGED
        })
    })),
    getFontSizeEpicUpdaters: EpicUpdaterFactory = actions => actions.map(({ action, ref }) => ({
        conditions: [action],
        reducer: ({ values: [size], state: stylesheets }) => ({
            state: updateGlobalstyles(ref, stylesheets, { size }),
            updateReason: updateReasons.TEXT_FONT_SIZE_CHANGED
        })
    })),
    getCharacterSpacingEpicUpdaters: EpicUpdaterFactory = actions => actions.map(({ action, ref }) => ({
        conditions: [action],
        reducer: ({ values: [letterspacing], state: stylesheets }) => ({
            state: updateGlobalstyles(ref, stylesheets, { letterspacing }),
            updateReason: updateReasons.TEXT_CHARACTER_SPACING_CHANGED,
            actionToDispatch: {
                type: WINDOW_FORCE_REPAINT_REFLOW
            }
        })
    })),
    getLineHeightUpdaters: EpicUpdaterFactory = actions => actions.map(({ action, ref }) => ({
        conditions: [action],
        reducer: ({ values: [lineHeight], state: stylesheets }) => ({
            state: updateGlobalstyles(ref, stylesheets, { lineHeight }),
            updateReason: updateReasons.TEXT_LINE_HEIGHT_CHANGED,
            actionToDispatch: { type: WINDOW_FORCE_REPAINT_REFLOW },
        })
    })),
    getShadowEpicUpdaters: EpicUpdaterFactory = actions => actions.map(({ action, ref }) => ({
        conditions: [action],
        reducer: ({ state: stylesheets }) => ({
            state: removeGlobalStyleShadow(ref, stylesheets),
            updateReason: updateReasons.TEXT_STYLE_CHANGED
        })
    })),
    getShadowColorEpicUpdaters: EpicUpdaterFactory = actions => actions.map(({ action, ref }) => ({
        conditions: [action],
        reducer: ({ values: [color], state: stylesheets }) => ({
            state: updateGlobalstylesShadow(ref, stylesheets, color),
            updateReason: updateReasons.TEXT_STYLE_CHANGED
        })
    })),
    getShadowBlurRadiusEpicUpdaters: EpicUpdaterFactory = actions => actions.map(({ action, ref }) => ({
        conditions: [action],
        reducer: ({ values: [blur], state: stylesheets }) => ({
            state: updateGlobalstylesShadow(ref, stylesheets, { blur }),
            updateReason: updateReasons.TEXT_STYLE_CHANGED
        })
    })),
    getShadowHorizontalOffsetEpicUpdaters: EpicUpdaterFactory = actions => actions.map(({ action, ref }) => ({
        conditions: [action],
        reducer: ({ values: [left], state: stylesheets }) => ({
            state: updateGlobalstylesShadow(ref, stylesheets, { left }),
            updateReason: updateReasons.TEXT_STYLE_CHANGED
        })
    })),
    getShadowVerticalOffsetEpicUpdaters: EpicUpdaterFactory = actions => actions.map(({ action, ref }) => ({
        conditions: [action],
        reducer: ({ values: [top], state: stylesheets }) => ({
            state: updateGlobalstylesShadow(ref, stylesheets, { top }),
            updateReason: updateReasons.TEXT_STYLE_CHANGED
        })
    })),
    getFontColorEpicUpdates: EpicUpdaterFactory = actions => actions.map(({ action, ref }) => ({
        conditions: [action],
        reducer: ({ values: [color], state: stylesheets }) => ({
            state: updateGlobalstyles(ref, stylesheets, color),
            updateReason: updateReasons.TEXT_STYLE_CHANGED
        })
    })),
    getFontColorRemoveEpicUpdates: EpicUpdaterFactory = actions => actions.map(({ action, ref }) => ({
        conditions: [action],
        reducer: ({ state: stylesheets }) => ({
            state: updateGlobalstyles(ref, stylesheets, { color: null }),
            updateReason: updateReasons.TEXT_STYLE_CHANGED
        })
    }));
