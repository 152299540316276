import makeEpic from '../../../../epics/makeEpic';
import { COMPONENT_CONFIGURATION_COMPLETE } from "../../../../redux/modules/children/workspace/actionTypes";
import { makeActionForwardToSelectedComponent } from '../../../../redux/forwardTo';
import { openDialog, closeDialog } from '../../../App/actionCreators/index';
import valueActionType from './valueActionType';
import * as actionTypes from '../actionTypes';
import configurationDialogId from "../configurationDialogId";

const
    defaultState = { replace: false },
    addComponentAction = params => ({ type: COMPONENT_CONFIGURATION_COMPLETE, payload: params }),
    updateComponentAction = params => ({ type: actionTypes.CODE_UPDATE_CODE_CONTENT, payload: params });

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [actionTypes.CODE_SET_CODE_CONTENT],
            reducer: ({ values: [{ code, location, replace }], state }) => {
                const
                    params = { code, location },
                    tmpAction: AnyAction = replace ? updateComponentAction(params) : addComponentAction(params),
                    actionToDispatch: AnyAction = replace ? makeActionForwardToSelectedComponent(tmpAction) : tmpAction;

                return { state, actionToDispatch };
            }
        },
        {
            conditions: [actionTypes.CODE_EDIT_CODE_BUTTON_CLICKED],
            reducer: ({ values: [component], state }) => {
                const { code, location } = component;
                return {
                    state,
                    actionToDispatch: openDialog(configurationDialogId, { code, location, replace: true })
                };
            }
        },
        {
            conditions: [actionTypes.CODE_UPDATE_CODE_CONTENT],
            reducer: ({ state }) => ({ state, actionToDispatch: closeDialog() })
        }
    ]
});
