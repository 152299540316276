import React from 'react';

import WarningDialog from '../../../view/common/dialogs/WarningDialog';
import { Msg } from '../../../view/intl';
import makeAppStandardErrorDialog from "../../App/dialogs/makeAppStandardErrorDialog";

const PageTemplateSaveFailureDialog = () => {
    return (
        <WarningDialog
            title="msg: common.couldNotSaveTemplate {Could not save template}"
            mctaText="msg: common.ok {OK}"
        >
            <div>
                <Msg k="common.tryAgainOrContactSupport">
                    Please try again later or contact our support for further assistance.
                </Msg>.
            </div>
        </WarningDialog>
    );
};

export default makeAppStandardErrorDialog(PageTemplateSaveFailureDialog);
