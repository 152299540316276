/* eslint-disable max-len */
import React from 'react';
import cx from "classnames";

import { ErrorMessage } from './ErrorMessage';

import styles from "./styles.css";

const HighTrafficError = () => {
    return (
        <ErrorMessage
            iconClass={cx(styles.highTrafficErrorIcon, styles.errorIcon)}
            content="msg: component.text.createAIText.dialog.error.unableToCreate {Unable to create text}"
            subContent="msg: component.text.createAIText.dialog.error.highTrafficError.subContent {Due to high traffic at OpenAI, we can\'t process your request. Try again in a few minutes.}"
        />
    );
};

export default HighTrafficError;
