import type { AppStateSel } from "../../../globalTypes";
import { Lit } from "../../../lit";
import { pathSelector } from "../../../utils/pathSelector";
import type { FacebookPostAsset } from "../../SocialAccounts/Facebook/FacebookFeed/types";

export const
    socialAccountsFacebookFeedConnected: AppStateSel<boolean> = pathSelector(
        [Lit.socialAccounts, Lit.facebookFeed, Lit.facebookFeedConnected]
    ),
    socialAccountsFacebookFeedAssets: AppStateSel<Array<FacebookPostAsset>> = pathSelector(
        [Lit.socialAccounts, Lit.facebookFeed, Lit.facebookFeedAssetUrls]
    );
