import React, { useState, useEffect } from 'react';
import cx from "classnames";
import { Flex } from "../../../../../../view/reflexbox/index";
import styles from './slider.css';

type Props = {
    sliders: Array<any>,
    sliderWidth: number,
    sliderGap: number,
    onSelect: (i: number) => void,
}

export const Slider = (props: Props) => {
    const { sliders, sliderWidth, sliderGap, onSelect } = props;

    const
        [selectedTemplateIndex, setSelectedTemplateIndex] = useState(0),
        [left, setLeft] = useState(''),
        totalSlides = sliders.length;

    useEffect(() => {
        let leftPart = `(((${totalSlides} - 1) * (${sliderWidth} + ${sliderGap})) / 2)`;
        let rightPart = `calc(${selectedTemplateIndex} * (${sliderWidth} + ${sliderGap}))`;

        setLeft(`calc(${leftPart} - ${rightPart})`);
        onSelect(selectedTemplateIndex);
    }, [selectedTemplateIndex]);

    return (
        <React.Fragment>
            <Flex
                className={styles.wrapper}
                style={{ left, gap: sliderGap }}
            >
                {
                    sliders && sliders.map((slider, i) => {
                        return (
                            <Flex
                                key={i}
                                className={cx(styles.sliderContainer, { [styles.active]: i === selectedTemplateIndex })}
                                style={{ width: sliderWidth }}
                                onClick={() => setSelectedTemplateIndex(i)}
                            >
                                {slider}
                            </Flex>
                        );
                    })
                }
            </Flex>
            <Flex className={styles.sliderBottonsWrapper}>
                {
                    sliders && sliders.length > 1 && sliders.map((slider, i) => {
                        return (
                            <div
                                key={i}
                                className={cx(styles.sliderButtons, { [styles.active]: i === selectedTemplateIndex })}
                                onClick={() => setSelectedTemplateIndex(i)}
                            />
                        );
                    })
                }
            </Flex>
        </React.Fragment>
    );
};
