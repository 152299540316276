import { moveInsideViewPort } from "../utils/position";
import type { StateHolder } from "../../flowTypes";
import type { Dimensions } from "../../../../redux/modules/children/workspace/flowTypes";
import type { Position } from "../../../../redux/modules/flowTypes";

export default (
    mousePosition: Position,
    browserDimensions: Dimensions,
    leftPanelWidth: number,
    stateHolder: StateHolder
): StateHolder => {
    const
        { scope, state } = stateHolder,
        { startMovePosition, startMousePosition, isMoving } = scope;
    if (isMoving) {
        let newPosition = {
            x: startMovePosition.x - (startMousePosition.x - mousePosition.x),
            y: startMovePosition.y - (startMousePosition.y - mousePosition.y)
        };
        newPosition = moveInsideViewPort(browserDimensions, leftPanelWidth, newPosition);
        return {
            scope: { ...scope, actualPosition: newPosition },
            state: { ...state, position: newPosition }
        };
    }
    return stateHolder;
};
