import { $Keys } from "utility-types";
import { MOBILE_EDITOR, PREVIEW } from "../../../App/epics/userFocus/kind";
import * as updateReasons from "./updateReasons";

const
    UpdateReasonForResetComponentModeId = {
        [MOBILE_EDITOR]: updateReasons.SWITCHED_TO_MOBILE_EDITOR,
        [PREVIEW]: updateReasons.SWITCHED_TO_PREVIEW
    };

export const
    shouldResetEditModeComponentId = (userFocusKind: string): boolean => {
        return (
            userFocusKind === MOBILE_EDITOR
            || userFocusKind === PREVIEW
        );
    },
    getUpdateReasonForResetComponentModeId = (userFocusKind: $Keys<typeof UpdateReasonForResetComponentModeId>) =>
        UpdateReasonForResetComponentModeId[userFocusKind];
