import React from 'react';
import { PageIds, PageTitles } from "../constants";
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import type { DocumentComponentPageProps } from "../flowTypes";
import { DocumentFileChooserButton } from "./DocumentFileChooserButton";
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import NavigationGroup from '../../../../PropertiesPanel/view/IntlNavigationGroup';

const
    id = PageIds.MAIN,
    title = PageTitles[id],
    view = (props: DocumentComponentPageProps) => {
        const { selectedComponent: { settings: { src } }, navigateToPage, dispatch } = props;

        return (
            <Page>
                <VerticalSpacer />
                <DocumentFileChooserButton src={src} dispatch={dispatch} />
                <VerticalSpacer />
                <NavigationGroup
                    navigateToPage={navigateToPage}
                    pagesTitles={PageTitles}
                    targetPagesIds={[PageIds.MOBILE_VIEW]}
                />
            </Page>
        );
    };

export default { id, title, view };
