import type { Handle } from "../../../../redux/modules/children/workspace/flowTypes";

const removeTopmostHandleIfAlreadyExist = (handle: Handle | null) => (handles: Array<Handle | null>) => {
    const existingHandle = handles.filter(
        (h) => {
            if (h === handle) {
                return true;
            }
            return handle && h && handle.kind === h.kind && handle.componentsIds[0] === h.componentsIds[0];
        }
    )[0];

    if (existingHandle !== undefined) { /* null is valid handle */
        // $FlowFixMe
        return handles.filter(h => h !== existingHandle);
    }

    return handles;
};

export {
    removeTopmostHandleIfAlreadyExist
};
