import makeEpic from '../../../epics/makeEpic';
import valueActionType from './valueActionType';
import { receiveOnly } from "../../../epics/makeCondition";
import { WINDOW_MOUSE_MOVE } from "../../App/actionTypes";
import {
    mousePositionWithRespectToTemplateAreaValueActionType
} from "../../Workspace/epics/mousePositionWithRespectToTemplateArea/valueActionType";
import DocumentKind from "../../oneweb/Document/kind";
import ImageComponentKind from "../../oneweb/Image/kind";
import { openDialogAC } from "../../App/actionCreators/openDialog";
import { FilesUploadingDialogId, UploadFilesLimitExceedDialogId } from "../dialogs/dialogIds";
import { uploadFilesAction } from "../../FileUploader/actionCreators";
import { FcContentTypes } from "../../../redux/modules/children/fileChooser/FcContentTypes";
import { FU_UPLOAD_CANCELED, FU_UPLOAD_COMPLETED } from "../../FileUploader/actions";
import { fcSaveAction } from "../../../redux/modules/children/fileChooser/actionCreators/index";
import { closeDialog } from "../../App/actionCreators/index";
import { componentConfigurationCompleteAction } from "../../../redux/modules/children/workspace/actionCreators";
import { getDocFiles, getSvgFiles, getImageFiles, getUnsupportedFiles, hasDocFile } from "./utils";
import { getCmpPositionOnDrop } from "../../DndAddComponent/epic/util";
import { NEW_COMPONENT_DISPLACEMENT } from "../../Workspace/epics/componentsEval/constants";
import { UnsupportedErrorDialogId } from "../../../view/common/FileChooser/dialogIds";
import {
    ADD_SVG_COMPONENT,
    ADD_MULTIPLE_DOCUMENT_COMPONENTS,
    ADD_MULTIPLE_IMAGE_COMPONENTS,
    ADD_COMPONENT_FILE_DND_INTO_WORKSPACE
} from "./actions";
import { isWsbDemo } from "../../../debug/isWsbDemo";
import { DND_ADD_SVG } from '../../oneweb/Svg/actionTypes';
import { VideoUtils } from '../../../utils/fileUtils';

const DND_FILES_LIMIT = 10;

const defaultState = {
    configurationData: { left: 0, right: 0 },
    componentCounter: 0,
    uploadInProgress: false
};

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                receiveOnly(WINDOW_MOUSE_MOVE),
                receiveOnly(mousePositionWithRespectToTemplateAreaValueActionType),
                ADD_COMPONENT_FILE_DND_INTO_WORKSPACE
            ],
            reducer: ({
                values: [
                    mousePosition,
                    mousePositionWithRespectToTemplateArea,
                    { files, left: x, top: y }
                ],
                state
            }) => {
                // Temporarily blocking video files drag and drop on workspace
                // @ts-ignore
                if (Array.from(files).some(file => VideoUtils.isVideoFile(file.name))) {
                    return { state };
                }

                if (isWsbDemo() && files.length && hasDocFile(files)) {
                    return { state };
                }

                if (files && files.length && !state.uploadInProgress) {
                    let invalidAction;
                    if (files.length > DND_FILES_LIMIT) {
                        invalidAction = openDialogAC(UploadFilesLimitExceedDialogId);
                    }

                    const invalidFiles = getUnsupportedFiles(files);
                    if (invalidFiles.length) {
                        invalidAction = openDialogAC(UnsupportedErrorDialogId, { fileNames: invalidFiles });
                    }

                    if (invalidAction) {
                        return { state: defaultState, actionToDispatch: invalidAction };
                    }

                    const { x: left, y: top } = getCmpPositionOnDrop({
                            positionWithRespectToBrowser: { x, y },
                            mousePosition,
                            mousePositionWithRespectToTemplateArea
                        }),
                        configurationData = { left, top };

                    return {
                        state: {
                            ...defaultState,
                            uploadInProgress: true,
                            configurationData
                        },
                        multipleActionsToDispatch: [
                            openDialogAC(FilesUploadingDialogId),
                            uploadFilesAction({
                                isMultiSelect: true,
                                contentTypes: FcContentTypes.ANY,
                                files
                            })
                        ]
                    };
                }
                return { state };
            }
        },
        {
            conditions: [FU_UPLOAD_COMPLETED],
            reducer: ({ values: [{ files }], state }) => {
                if (state.uploadInProgress) {
                    const actions: Action[] = [],
                        svgs = getSvgFiles(files),
                        images = getImageFiles(files),
                        docs = getDocFiles(files);

                    if (svgs.length) {
                        actions.push(fcSaveAction({
                            onSaveAction: ADD_SVG_COMPONENT,
                            isMultiSelect: false,
                            selection: svgs,
                        }));
                    }

                    if (images.length) {
                        actions.push(fcSaveAction({
                            onSaveAction: ADD_MULTIPLE_IMAGE_COMPONENTS,
                            isMultiSelect: true,
                            selection: images,
                        }));
                    }

                    if (docs.length) {
                        actions.push({
                            type: ADD_MULTIPLE_DOCUMENT_COMPONENTS,
                            payload: docs
                        });
                    }
                    return {
                        state: { ...state, uploadInProgress: false },
                        multipleActionsToDispatch: [closeDialog(), ...actions]
                    };
                }
                return { state };
            }
        },
        {
            conditions: [FU_UPLOAD_CANCELED],
            reducer: ({ state }) => {
                if (state.uploadInProgress) {
                    return {
                        state: { ...state, uploadInProgress: false },
                        actionToDispatch: closeDialog()
                    };
                }
                return { state };
            }
        },
        {
            conditions: [ADD_MULTIPLE_DOCUMENT_COMPONENTS],
            reducer: ({ values: [fileUrls], state }) => {
                const { configurationData: { left, top }, componentCounter } = state;
                return {
                    state: { ...state, componentCounter: componentCounter + fileUrls.length },
                    multipleActionsToDispatch: fileUrls.map((src, index) => {
                        const positionChange = NEW_COMPONENT_DISPLACEMENT * (componentCounter + index);
                        return componentConfigurationCompleteAction({
                            settings: { src },
                            kind: DocumentKind,
                            left: left + positionChange,
                            top: top + positionChange,
                        });
                    })
                };
            }
        },
        {
            conditions: [ADD_SVG_COMPONENT],
            reducer: ({ values: [{ asset }], state }) => {
                const { configurationData: { left, top }, componentCounter } = state;
                return {
                    state: { ...state, componentCounter: componentCounter + 1 },
                    actionToDispatch: {
                        type: DND_ADD_SVG,
                        payload: { asset, left, top },
                    }
                };
            }
        },
        {
            conditions: [ADD_MULTIPLE_IMAGE_COMPONENTS],
            reducer: ({ values: [{ assets }], state }) => {
                const { configurationData: { left, top }, componentCounter } = state;
                return {
                    state: { ...state, componentCounter: componentCounter + assets.length },
                    multipleActionsToDispatch: assets.map((asset, index) => {
                        const positionChange = NEW_COMPONENT_DISPLACEMENT * (componentCounter + index);
                        return componentConfigurationCompleteAction({
                            asset,
                            kind: ImageComponentKind,
                            left: left + positionChange,
                            top: top + positionChange,
                        });
                    })
                };
            }
        }
    ]
});
