import { toArray } from "./nodeUtils";

const setStyleTransformer = (name, value) => (elt) => elt.style.setProperty(name, value);
const selectorTransformerMap = {
    b: setStyleTransformer('font-weight', 'bold'),
    i: setStyleTransformer('font-style', 'italic'),
    u: setStyleTransformer('text-decoration', 'underline'),
};

export const transformToTinyMceCompatibleHtml = (pseudoDom: HTMLElement) => {
    Object.keys(selectorTransformerMap).forEach((selector) => {
        toArray(pseudoDom.querySelectorAll(selector)).forEach((oldElt: Element) => {
            if (oldElt.parentElement) {
                const newElt = oldElt.parentElement.insertBefore(document.createElement('span'), oldElt);
                toArray(oldElt.attributes).forEach(({ name }) => newElt.setAttribute(name, oldElt.getAttribute(name) || ''));
                selectorTransformerMap[selector](newElt);
                newElt.append(...Array.from(oldElt.childNodes));
                oldElt.remove();
            }
        });
    });
};
