import { makeUuid } from "../../../../../../utils/makeUuid";
import type { TinyMceEditor } from "../flowTypes";
import isTestEnv from "../../../../../debug/isTestEnv";
import { getEditorConfig } from "../../../../oneweb/Text/editorSetup";

declare let tinymce: any;

const editorId = makeUuid();

let editor: TinyMceEditor | null | undefined = null;

const
    getOffScreenEditor = () => {
        if (!editor) {
            throw new Error('getEditorForMultiProcessing editor is not initialized');
        }
        return editor;
    },
    resetBrowserFocus = () => {
        const el = document.createElement('input');
        el.type = 'text';
        /* $FlowFixMe */
        document.body.appendChild(el);
        el.focus();
        /* $FlowFixMe */
        document.body.removeChild(el);
    };

if (!isTestEnv() && typeof tinymce !== 'undefined') {
    const el = document.createElement('div');

    el.style.position = 'absolute';
    el.style.opacity = '0';
    el.style.top = '-100000px';
    el.id = editorId;

    /* $FlowFixMe */
    document.body.appendChild(el);

    const editorConfig = { ...getEditorConfig(), selector: `#${editorId}` };

    tinymce.init(editorConfig).then(([ed]) => {
        editor = ed;
        resetBrowserFocus();
    });
}

export {
    getOffScreenEditor,
    resetBrowserFocus
};
