import * as R from 'ramda';
import { overPathWhen } from '../../../utils/ramdaEx';
import { getMinWrapperComponentWidth } from '../../../utils/htmlWriter/html/render/wrapper/wrapperNodeUtils';

import type { TextComponent } from './flowTypes';
import type { AdjustmentHook } from '../../Workspace/epics/componentsEval/flowTypes';

export function updateIf(propValue: AnyValue, makePredicate: (AnyValue) => (AnyValue) => boolean, propName: string) {
    const
        pathToUpdate = [propName],
        predicate = makePredicate(propValue),
        putPropValue = R.always(propValue);

    return overPathWhen([pathToUpdate], predicate)(putPropValue);
}

const
    getMinWidthFromExtension = R.path(['minDimensions', 'width']),
    getMinHeightFromExtension = R.path(['minDimensions', 'height']);

const
    hook: AdjustmentHook<TextComponent, Object, Object, Object> =
        // @ts-ignore
        ({ component, componentExtension, adjustmentData }: Object) => {
            const
                { height } = adjustmentData || { height: 0 },
                calculatedMinWidth = getMinWrapperComponentWidth(component.content),
                minHeightInAdjustmentData = adjustmentData.minDimensions ? adjustmentData.minDimensions.height : 1;

            const
                minWidthInExtension = getMinWidthFromExtension(componentExtension),
                minHeightinExtension = getMinHeightFromExtension(componentExtension);

            let updatedComponentExtension = componentExtension;

            // update componentExtension if calculated minWidth is different
            if (minWidthInExtension !== calculatedMinWidth || minHeightinExtension !== minHeightInAdjustmentData) {
                updatedComponentExtension = {
                    ...componentExtension,
                    minDimensions: {
                        width: calculatedMinWidth,
                        height: R.max(1, minHeightInAdjustmentData)
                    }
                };
            }

            return [
                updateIf(height, R.gt, 'height')(component),
                updatedComponentExtension
            ];
        },
    hookConfig = {
        hook,
        // @ts-ignore
        shouldCallHook: (prev: any, { adjustmentData }: Object) => !!adjustmentData
    };

export default hookConfig;
