import React from "react";
import { Flex } from '../../../../../view/reflexbox/index';
import pagesTitles from "./titles";
import * as pagesIds from "./ids";
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';
import InputField from '../../../../../view/common/Input/PropertiesInputField';
import lengthInputValidation from "../../../../inputControls/input/commonValidations/lengthInputValidation";
import stylePropertyContainer from '../../../../../view/common/PropertyContainer/PropertyContainer.css';
import { MAX_HEADER_TEXT_LENGTH, MAX_LINK_TEXT_LENGTH } from "../../constants";
import { CHANGE_REVIEWS_HEADER_TEXT, CHANGE_REVIEWS_LINK_TEXT } from "../../actionTypes";

const
    id = pagesIds.TEXT,
    title = pagesTitles[pagesIds.TEXT],
    view = ({ selectedComponent, dispatch }: any) => (
        <Page>
            <VerticalSpacer />
            <PropertyContainer
                label="msg: common.headerText {Header text}"
                className={stylePropertyContainer.pointer}
            >
                <Flex>
                    <InputField
                        value={selectedComponent.headerText || ''}
                        onChange={payload => dispatch({ type: CHANGE_REVIEWS_HEADER_TEXT, payload })}
                        placeholder="msg: common.headerText.placeholder {Enter header title here}"
                        stopOnKeyDownEventPropagation
                        inputValidations={[
                            {
                                validation: lengthInputValidation,
                                options: { max: MAX_HEADER_TEXT_LENGTH },
                            }
                        ]}
                        rejectInvalidValue
                        trimValueOnPaste
                        counter={{
                            maxLength: MAX_HEADER_TEXT_LENGTH,
                        }}
                    />
                </Flex>
            </PropertyContainer>
            <VerticalSpacer />
            <PropertyContainer
                label="msg: common.linkText {Link text}"
                className={stylePropertyContainer.pointer}
            >
                <Flex>
                    <InputField
                        value={selectedComponent.seeMoreText || ''}
                        onChange={payload => dispatch({ type: CHANGE_REVIEWS_LINK_TEXT, payload })}
                        placeholder="msg: common.linkText.placeholder {Enter link text here}"
                        stopOnKeyDownEventPropagation
                        inputValidations={[
                            {
                                validation: lengthInputValidation,
                                options: { max: MAX_LINK_TEXT_LENGTH },

                            }
                        ]}
                        rejectInvalidValue
                        trimValueOnPaste
                        counter={{
                            maxLength: MAX_HEADER_TEXT_LENGTH,
                        }}
                    />
                </Flex>
            </PropertyContainer>
        </Page>
    );

export { id, view, title };
