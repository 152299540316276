import { closeDialogByIdAction, openDialog } from "../App/actionCreators/index";
import { UnsavedChangesDialogId } from "./dialog/index";
import {
    CANCEL_UNSAVED_CHANGES,
    CHECK_UNSAVED_CHANGES,
    DONT_SAVE_UNSAVED_CHANGES,
    SAVE_UNSAVED_CHANGES
} from "./actionTypes";

export const
    checkUnsavedChangesAction = () => ({ type: CHECK_UNSAVED_CHANGES }),
    cancelUnsavedChangesAction = () => ({ type: CANCEL_UNSAVED_CHANGES }),
    dontSaveUnsavedChangesAction = () => ({ type: DONT_SAVE_UNSAVED_CHANGES }),
    saveUnsavedChangesAction = () => ({ type: SAVE_UNSAVED_CHANGES }),
    openUnsavedChangesDialog = (pageName: string) => openDialog(UnsavedChangesDialogId, { pageName }),
    closeUnsavedChangesDialog = () => closeDialogByIdAction(UnsavedChangesDialogId);

