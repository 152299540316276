/* eslint-disable max-len */

import React from 'react';
import OpeningHours from "../../../../General/OpeningHours/index";
import type { GeneralGlobalData } from '../../../../General/generalGlobalDataEpic/generalGlobalDataEpic';
import { Intl } from "../../../../../../view/intl/index";
import styles from './styles.css';

type OpeningHoursStepProps = {
    generalGlobalData: GeneralGlobalData;
    intl: Intl;
    dispatch: Dispatch;
    attemptedInvalidSubmit: boolean;
}

const OpeningHoursStep = (props: OpeningHoursStepProps) => {
    const { intl, dispatch, generalGlobalData } = props;

    return (
        <div className={styles.container}>
            <div className={styles.field}>
                <div className={styles.name}>
                    {intl.msgJoint("msg: BusinessListing.createListing.openingHours {Opening hours (optional)}")}
                    <span>{intl.msgJoint("msg: BusinessListing.createListing.openingHoursDescription {These are the hours during which your business is open}")}</span>
                </div>
                <OpeningHours intl={intl} dispatch={dispatch} generalGlobalData={generalGlobalData} />
            </div>
        </div>
    );
};

export { OpeningHoursStep };
