// TODO: use tree-shakeable imports (import isURL from 'validator/es/lib/isURL';)
import isURL from 'validator/lib/isURL'; // eslint-disable-line node/file-extension-in-import
import InputField from "./InputField";
import type { InputFieldPropTypes, UrlInputFieldProps } from "./flowTypes";
import Url from "../../../../utils/Url";
import path from "../../../../utils/path";
import { JS_VOID_PATTERN, JS_PROTOCOL_PATTERN } from '../../../constants';

export default class UrlField extends InputField<UrlInputFieldProps> {
    validationRules = {
        protocols: ['http', 'https'],
        require_protocol: false,
        allow_underscores: true
    };

    static defaultProps = {
        ...InputField.defaultProps,
    };

    constructor(props: UrlInputFieldProps) {
        super(props);

        const { allowJsProtocol } = props,
            url = String(this.state.value),
            shouldHideUrl = JS_VOID_PATTERN.test(url) || (!allowJsProtocol && JS_PROTOCOL_PATTERN.test(url));

        this.state = {
            ...this.state,
            value: shouldHideUrl ? 'http://' : url,
            isValid: true
        };
    }

    validateValue(value: any): boolean {
        const props: UrlInputFieldProps = this.props;
        if (value === '#addressUrl') return true; // TODO put variable instead of #addressUrl
        if (props.allowJsProtocol && JS_PROTOCOL_PATTERN.test(value)) return true;

        let valid = !!(value && isURL(value, this.validationRules));

        if (valid && props.validatePathPresence) {
            try {
                const parts = Url(value);
                valid = !!path.trim(parts.pathname);
            } catch (e: any) {
                valid = false;
            }
        }

        return valid;
    }

    UNSAFE_componentWillReceiveProps(nextProps: InputFieldPropTypes) {
        super.UNSAFE_componentWillReceiveProps((nextProps as any));
        this.setState({ isValid: this.validateValue(nextProps.value) });
    }
}
