import type { ApiAction } from "../../../../redux/middleware/api/flowTypes";
import { apiAction } from "../../../../redux/middleware/api/actions";
import type { ManagedFacebookPageType } from "./types";

export const
    INITIATE_FB_EXCHANGE_TOKEN_ACTION = 'INITIATE_FB_EXCHANGE_TOKEN_ACTION',
    initiateExchangeTokenAction = () => ({
        type: INITIATE_FB_EXCHANGE_TOKEN_ACTION,
    });

export const
    SET_FACEBOOK_FEED_ACCESS_CREDENTIALS_ACTION = 'SET_FACEBOOK_FEED_ACCESS_CREDENTIALS_ACTION',
    setFacebookFeedAccessCredentials = (
        facebookFeedAccessToken: string,
        facebookFeedAccountName: string,
        isPageAccount: boolean,
        facebookFeedAccountId: string,
    ) => ({
        type: SET_FACEBOOK_FEED_ACCESS_CREDENTIALS_ACTION,
        payload: {
            facebookFeedAccessToken,
            facebookFeedAccountName,
            isPageAccount,
            facebookFeedAccountId,
        }
    });

export const
    UNSET_FACEBOOK_FEED_ACCESS_TOKEN_ACTION = 'UNSET_FACEBOOK_FEED_ACCESS_TOKEN_ACTION',
    unSetFacebookFeedAccessToken = () => ({
        type: UNSET_FACEBOOK_FEED_ACCESS_TOKEN_ACTION,
    });

export const
    EXCHANGE_FOR_FACEBOOK_LONG_ACCESS_TOKEN_REQUEST_ACTION = 'EXCHANGE_FOR_FACEBOOK_LONG_ACCESS_TOKEN_REQUEST_ACTION',
    EXCHANGE_FOR_FACEBOOK_LONG_ACCESS_TOKEN_SUCCESS_ACTION = 'EXCHANGE_FOR_FACEBOOK_LONG_ACCESS_TOKEN_SUCCESS_ACTION',
    EXCHANGE_FOR_FACEBOOK_LONG_ACCESS_TOKEN_FAILURE_ACTION = 'EXCHANGE_FOR_FACEBOOK_LONG_ACCESS_TOKEN_FAILURE_ACTION',
    exchangeForFacebookLongAccessTokenAction = (shortLivedAccessToken: string, isPageMode: boolean = false): ApiAction => {
        return apiAction({
            types: [
                EXCHANGE_FOR_FACEBOOK_LONG_ACCESS_TOKEN_REQUEST_ACTION,
                EXCHANGE_FOR_FACEBOOK_LONG_ACCESS_TOKEN_SUCCESS_ACTION,
                EXCHANGE_FOR_FACEBOOK_LONG_ACCESS_TOKEN_FAILURE_ACTION,
            ],
            endpoint: 'exchangeForFacebookLongAccessToken',
            endpointParams: [shortLivedAccessToken, isPageMode]
        });
    };

export const
    DISCONNECT_FACEBOOK_FEED_REQUEST_ACTION = 'DISCONNECT_FACEBOOK_FEED_REQUEST_ACTION',
    DISCONNECT_FACEBOOK_FEED_SUCCESS_ACTION = 'DISCONNECT_FACEBOOK_FEED_SUCCESS_ACTION',
    DISCONNECT_FACEBOOK_FEED_FAILURE_ACTION = 'DISCONNECT_FACEBOOK_FEED_FAILURE_ACTION',
    disconnectFacebookFeedAction = (): ApiAction => {
        return apiAction({
            types: [
                DISCONNECT_FACEBOOK_FEED_REQUEST_ACTION,
                DISCONNECT_FACEBOOK_FEED_SUCCESS_ACTION,
                DISCONNECT_FACEBOOK_FEED_FAILURE_ACTION,
            ],
            endpoint: 'disconnectFacebookFeed',
        });
    };

export const
    SET_IS_FB_PAGE_MODE_ACTION = 'SET_IS_FB_PAGE_MODE_ACTION',
    setIsFbPageModeAction = (isFbPageMode: boolean) => ({
        type: SET_IS_FB_PAGE_MODE_ACTION,
        payload: isFbPageMode
    });

export const
    GET_FB_OWN_MANAGED_ACCOUNTS_SUCCESS_ACTION = 'GET_FB_OWN_MANAGED_ACCOUNTS_SUCCESS_ACTION',
    GET_FB_OWN_MANAGED_ACCOUNTS_FAILURE_ACTION = 'GET_FB_OWN_MANAGED_ACCOUNTS_FAILURE_ACTION';

export const
    SET_MANAGED_FB_PAGES_ACTION = 'SET_MANAGED_FB_PAGES_ACTION',
    setManagedFbPagesAction = (fbPages: Array<ManagedFacebookPageType>) => ({
        type: SET_MANAGED_FB_PAGES_ACTION,
        payload: fbPages
    });

export const
    UNSET_MANAGED_FB_PAGES_ACTION = 'UNSET_MANAGED_FB_PAGES_ACTION',
    unsetManagedFbPagesAction = () => ({
        type: UNSET_MANAGED_FB_PAGES_ACTION,
    });

export const
    SET_SELECTED_FB_PAGE_VALUE = 'SET_SELECTED_FB_PAGE_VALUE',
    setFbPagesValue = (fbPageAccessValue: string) => ({
        type: SET_SELECTED_FB_PAGE_VALUE,
        payload: fbPageAccessValue
    });

export const
    GET_FACEBOOK_FEED_ACCESS_TOKEN_REQUEST_ACTION = 'GET_FACEBOOK_FEED_ACCESS_TOKEN_REQUEST_ACTION',
    GET_FACEBOOK_FEED_ACCESS_TOKEN_SUCCESS_ACTION = 'GET_FACEBOOK_FEED_ACCESS_TOKEN_SUCCESS_ACTION',
    GET_FACEBOOK_FEED_ACCESS_TOKEN_FAILURE_ACTION = 'GET_FACEBOOK_FEED_ACCESS_TOKEN_FAILURE_ACTION',
    getFacebookFeedAccessTokenAction = (): ApiAction => {
        return apiAction({
            types: [
                GET_FACEBOOK_FEED_ACCESS_TOKEN_REQUEST_ACTION,
                GET_FACEBOOK_FEED_ACCESS_TOKEN_SUCCESS_ACTION,
                GET_FACEBOOK_FEED_ACCESS_TOKEN_FAILURE_ACTION,
            ],
            endpoint: 'getFacebookFeedAccessToken',
        });
    };

export const
    GET_FACEBOOK_FEED_USER_MEDIA_REQUEST_ACTION = 'GET_FACEBOOK_FEED_USER_MEDIA_REQUEST_ACTION',
    GET_FACEBOOK_FEED_USER_MEDIA_SUCCESS_ACTION = 'GET_FACEBOOK_FEED_USER_MEDIA_SUCCESS_ACTION',
    GET_FACEBOOK_FEED_USER_MEDIA_FAILURE_ACTION = 'GET_FACEBOOK_FEED_USER_MEDIA_FAILURE_ACTION',
    getFacebookFeedUserMediaAction = (accessTokens: Array<string>): ApiAction => {
        return apiAction({
            types: [
                GET_FACEBOOK_FEED_USER_MEDIA_REQUEST_ACTION,
                GET_FACEBOOK_FEED_USER_MEDIA_SUCCESS_ACTION,
                GET_FACEBOOK_FEED_USER_MEDIA_FAILURE_ACTION,
            ],
            endpoint: 'getFacebookFeedUserMedia',
            endpointParams: [accessTokens],
        });
    };

export const
    SET_TOTAL_FACEBOOK_FEED_GALLERY_COMPONENTS = 'SET_TOTAL_FACEBOOK_FEED_GALLERY_COMPONENTS',
    setTotalFacebookFeedGalleryComponentsAction = (totalComponents: number) => ({
        type: SET_TOTAL_FACEBOOK_FEED_GALLERY_COMPONENTS,
        payload: {
            totalComponents,
        }
    });

export const
    SET_TOTAL_CURRENT_PAGE_FACEBOOK_FEED_GALLERY_COMPONENTS = 'SET_TOTAL_CURRENT_PAGE_FACEBOOK_FEED_GALLERY_COMPONENTS',
    setTotalCurrentPageFacebookFeedGalleryComponentsAction = (totalComponents: number) => ({
        type: SET_TOTAL_CURRENT_PAGE_FACEBOOK_FEED_GALLERY_COMPONENTS,
        payload: {
            totalComponents,
        }
    });

export const
    SET_TOTAL_OTHER_PAGE_FACEBOOK_FEED_GALLERY_COMPONENTS = 'SET_TOTAL_OTHER_PAGE_FACEBOOK_FEED_GALLERY_COMPONENTS',
    setTotalOtherPageFacebookFeedGalleryComponentsAction = (totalComponents: number) => ({
        type: SET_TOTAL_OTHER_PAGE_FACEBOOK_FEED_GALLERY_COMPONENTS,
        payload: {
            totalComponents,
        }
    });

export const
    TOGGLE_FACEBOOK_FEED_PAGE_VISIBILITY_REQUEST_ACTION = 'TOGGLE_FACEBOOK_FEED_PAGE_VISIBILITY_REQUEST_ACTION',
    TOGGLE_FACEBOOK_FEED_PAGE_VISIBILITY_SUCCESS_ACTION = 'TOGGLE_FACEBOOK_FEED_PAGE_VISIBILITY_SUCCESS_ACTION',
    TOGGLE_FACEBOOK_FEED_PAGE_VISIBILITY_FAILURE_ACTION = 'TOGGLE_FACEBOOK_FEED_PAGE_VISIBILITY_FAILURE_ACTION',
    toggleFacebookFeedPageVisibilityAction = (fbAccountId: string, isVisible: boolean): ApiAction => {
        return apiAction({
            types: [
                TOGGLE_FACEBOOK_FEED_PAGE_VISIBILITY_REQUEST_ACTION,
                TOGGLE_FACEBOOK_FEED_PAGE_VISIBILITY_SUCCESS_ACTION,
                TOGGLE_FACEBOOK_FEED_PAGE_VISIBILITY_FAILURE_ACTION,
            ],
            endpoint: 'toggleFacebookFeedPageVisibility',
            endpointParams: [fbAccountId, isVisible],
        });
    };
