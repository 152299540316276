/* eslint-disable max-len */

import * as R from "ramda";
import * as path from "../../../../src/mappers/path";
import * as actionMapper from "../Common/action";
import { applyMappers, makePlainMappers } from "../../utils";
import * as styleMapper from "../Common/style";
import { evolvePath } from "../../../../src/utils/ramdaEx";
import type { ImageComponent } from "../../../../src/components/oneweb/Image/flowTypes";
import type { ImageComponentData } from "../../../../src/components/App/flowTypes";
import ScaleStrategies, { isFitMode } from '../../../../src/components/oneweb/Image/scaleStrategy';
import { fixHeightForFitMode } from '../../../../src/components/oneweb/Image/propertyChangeHandler/fixHeightForFitMode';
import { getMinZoom } from "../../../../src/components/oneweb/Image/componentSelectors";

export const imagePlainPropsMapper = makePlainMappers({
    title: 'title',
    scale: 'scale',
    logoTitleScale: 'logoTitleScale',
    rotation: 'rotation',
    mobileHide: 'mobileHide',
    mobileDown: 'mobileDown',
    scaleStrategy: 'scaleStrategy',
    cropTop: 'cropTop',
    cropLeft: 'cropLeft',
    asset: 'asset',
    style: 'style',
    action: 'linkAction',
    lightBoxEnabled: 'lightBoxEnabled',
    openLink: 'openLink',
    logoHorizontalAlignment: 'logoHorizontalAlignment',
    logoSize: 'logoSize'
});

export function to(componentData: ImageComponentData): ImageComponent {
    return applyMappers(
        componentData,
        R.pipe(
            imagePlainPropsMapper.to,
            evolvePath([path.style])(styleMapper.to),
            evolvePath(['linkAction'])(actionMapper.to)
        )
    );
}

export function afterMappingTo(
    component: ImageComponent,
): ImageComponent {
    const { scaleStrategy, lightBoxEnabled, linkAction, openLink, scale } = component;
    let changes: Record<string, any> = {};

    // no need to fix fit mode height since for publish in wbtgen without a save in old we have same height as in old
    // but if saved once from wbtgen then we set a flag 'savedInWbtgen' because of which we compute height of fit
    // images correctly in old WE

    if (!scaleStrategy) {
        changes.scaleStrategy = ScaleStrategies.CROP;
    }

    if (scaleStrategy === ScaleStrategies.CROP) {
        changes.scale = Math.max(scale, getMinZoom(component)); // old WE can have bad scale, they update on load
    }

    if (openLink === undefined) { // handling first time
        changes.openLink = lightBoxEnabled ? false : !!linkAction;
    }

    return {
        ...component,
        ...changes
    };
}

export function back(component: ImageComponent): ImageComponentData {
    return applyMappers(
        component,
        R.pipe(
            imagePlainPropsMapper.back,
            cmp => {
                if (isFitMode(component)) {
                    const cmpWithCorrectHeight = fixHeightForFitMode(component);
                    if (Math.abs(cmpWithCorrectHeight.height - component.height) < 0.001) {     // Fixing float comparison
                        return {
                            ...cmp,
                            savedInWbtgen: true
                        };
                    }
                }
                return cmp;
            },
            evolvePath([path.style])(styleMapper.back),
            evolvePath(['action'])(actionMapper.back)
        )
    );
}
