import React, { useContext } from "react";
import cx from "classnames";

import { useIntl } from "../../../../../../view/intl";
import Msg from "../../../../../../view/intl/Msg";

import stepStyles from "../steps.css";
import { DynamicTemplateContext } from "../../Context";

type Props = {
    title: string,
    description?: string,
    className?: string,
    showStepLabel?: boolean,
}

export const StepDescription = (props: Props) => {
    const intl = useIntl();
    const { title, description, showStepLabel = true } = props;
    const { wizardState: { currentStep, totalSteps } } = useContext(DynamicTemplateContext);

    return (
        <div className={cx(stepStyles.stepDescriptionContainer, props.className)}>
            {
                showStepLabel && (
                    <div className={stepStyles.stepLabel}>
                        {
                            currentStep !== 0 ? (
                                <Msg k="onboarding.dynamic.steps.label" params={{ currentStep, totalSteps }}>
                                    {`Step {currentStep} of {totalSteps}`}
                                </Msg>
                            ) : (" ")
                        }
                    </div>
                )
            }
            <div className={cx(stepStyles.stepTitle, { [stepStyles.additionalTitle]: !showStepLabel })}>
                {intl.msgJoint(title)}
            </div>
            {description &&
                <div className={cx(stepStyles.stepDescription, { [stepStyles.additionalDesc]: !showStepLabel })}>
                    {intl.msgJoint(description)}
                </div>}
        </div>
    );
};
