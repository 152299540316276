import u from 'updeep';

import type { SectionLinkInfoDialogInProps } from "../../Tree/dialogs/SectionLinkInfoDialog/flowTypes";
import sectionLinkFormDefaultState from "./sectionLinkDefaultState";

export default (props: SectionLinkInfoDialogInProps) => {
    const
        { page } = props;

    return u({
        pageId: page.pageId,
        sectionId: page.sectionId,
        name: page.name,
        hidden: page.hidden
    }, sectionLinkFormDefaultState);
};
