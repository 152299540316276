export const
    BACKUP_CLICKED = 'BACKUP_CLICKED',
    BACKUP_RECORDS_REQUEST = 'BACKUP_RECORDS_REQUEST',
    BACKUP_RECORDS_SUCCESS = 'BACKUP_RECORDS_SUCCESS',
    BACKUP_RECORDS_FAIL = 'BACKUP_RECORDS_FAIL',
    BACKUP_RESTORE_REQUEST = 'BACKUP_RESTORE_REQUEST',
    BACKUP_RESTORE_SUCCESS = 'BACKUP_RESTORE_SUCCESS',
    BACKUP_RESTORE_FAIL = 'BACKUP_RESTORE_FAIL',
    BACKUP_INTRO_CLOSE_DIALOG = 'BACKUP_INTRO_CLOSE_DIALOG',
    BACKUP_INTRO_TOGGLE_SHOW = 'BACKUP_INTRO_TOGGLE_SHOW',

    BACKUP_TOGGLE_LEFT_PANEL = 'BACKUP_TOGGLE_LEFT_PANEL',

    BACKUP_SET_PREVIEW_INTERFACE = 'BACKUP_SET_PREVIEW_INTERFACE',
    BACKUP_SET_PREVIEW_RECORD = 'BACKUP_SET_PREVIEW_RECORD',
    BACKUP_PREVIEW_RECORD_CHANGED = 'BACKUP_PREVIEW_RECORD_CHANGED',
    BACKUP_DESKTOP_PREVIEW = 'BACKUP_DESKTOP_PREVIEW',
    BACKUP_MOBILE_PREVIEW = 'BACKUP_MOBILE_PREVIEW',
    BACKUP_MOBILE_PREVIEW_ORIENTATION_TOGGLE = 'BACKUP_MOBILE_PREVIEW_ORIENTATION_TOGGLE',

    RESTORE_SAVEPOINT = 'RESTORE_SAVEPOINT';
