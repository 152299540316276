import { makeApplyEditorChangesReducer } from "./helpers/helpers";
import { makeRemoveFormatter } from "../editorUtils/methods/helpers/makeRemoveFormatter";
import { mergeNodes } from "../editorUtils/methods/helpers/mergeNodes";
import { selectAllText } from "../editorUtils/methods/helpers/selection";
import { GLOBAL_STYLE_CLASSES } from "../editorUtils/utils/nodeUtils/utils";
import { TINY_MCE_REMOVE_GLOBAL_STYLES_AND_FORMATS } from '../actionTypes';
import type { TinyMceEditor } from '../flowTypes';

const removeGlobalStylesAndFormats = (formats: string[], editor: TinyMceEditor) => {
        const removeFormatter = makeRemoveFormatter(editor);
        GLOBAL_STYLE_CLASSES.forEach((format: string) => removeFormatter(format));
        formats.forEach((format: string) => removeFormatter(format));
    },
    removeGlobalStylesAndFormatsUpdater = {
        conditions: [TINY_MCE_REMOVE_GLOBAL_STYLES_AND_FORMATS],
        reducer: makeApplyEditorChangesReducer(({ state, scope, values: [payload], editor }) => {
            selectAllText(editor);
            removeGlobalStylesAndFormats(payload.formats, editor);
            mergeNodes(editor);
            return { state, scope };
        }),
    };

export {
    removeGlobalStylesAndFormats,
    removeGlobalStylesAndFormatsUpdater,
};
