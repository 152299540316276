import React from 'react';
import { loadGoogleMapsScript, geocode, placeToAddress, type Address } from '../../../googleMaps/googleMaps';
import { ValidatedInput, type ValidatedInputProps } from '../../../../view/common/Input/ValidatedInputField';

export type GoogleMapsAutoCompleteInputProps = ValidatedInputProps & { onPlaceChanged: (a: Address) => any, refHandler?: Function };

export class GoogleMapsAutoCompleteInput extends React.Component<GoogleMapsAutoCompleteInputProps> {
    userInput: string | null | undefined;
    constructor(props: GoogleMapsAutoCompleteInputProps) {
        super(props);
        this.fetchAddressFromInput = this.fetchAddressFromInput.bind(this);
        this.userInput = null;
    }
    ref = React.createRef<HTMLInputElement>();

    fetchAddressFromInput() {
        this.userInput = this.ref.current?.value;

        if (this.props.value) {
            geocode((this.props.value as string))
                .then(place => this.props.onPlaceChanged({ ...place, address: this.userInput }))
                .catch(() => { /* do nothing */ });
        }
    }

    componentDidMount() {
        const ref = this.ref;
        if (ref && ref.current) {
            const { current } = ref;

            if (this.props.refHandler && typeof this.props.refHandler === 'function') {
                this.props.refHandler(current);
            }

            current.addEventListener("keydown", event => {
                if (event.key === "Enter" && document.querySelectorAll('.pac-item-selected').length === 0) {
                    this.fetchAddressFromInput();
                }
            });
            loadGoogleMapsScript().then(() => {
                const autocomplete = new window.google.maps.places.Autocomplete(current);
                autocomplete.addListener('place_changed', () => {
                    const place = autocomplete.getPlace();
                    if (place.formatted_address) {
                        this.userInput = current.value;
                        this.props.onPlaceChanged({ ...placeToAddress(place), address: this.userInput });
                    }
                });
            });
        }
    }

    render() {
        return (
            <ValidatedInput
                {...this.props}
                inputRef={this.ref}
            />
        );
    }
}
