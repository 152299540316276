import { DataPageRef } from "../../../../../dal/model";
import applyPatchToSitePage from "./applyPatchToSitePage";
import { PAGE_ROBOTS_NOINDEX, PAGE_ROBOTS_ALL } from "../../../../../dal/model/DataPageRef";
import { encodeWebspaceUri } from "../../../../../dal/utils/webspaceUriTransformers";
import type { PageInfoDialogStateForSave } from "../dialogs/PageInfoDialog/flowTypes";
import { SeoPageTitleInTabOptions } from "../dialogs/PageInfoDialog/view/constants";

export default (page: DataPageRef, values: PageInfoDialogStateForSave) => {
    const {
        names: {
            name: { value: name },
            url: { value: url }
        },
        seo: {
            description,
            excludeFromIndexing,
            title: { value: title },
            customTitle: { value: customTitle },
            pageNameOptionInBrowserTab,
        },
        socialShare: {
            socialShareAsset,
        },
        visibility: {
            isHidden: hidden,
            isPublished
        },
    } = values;

    let finalCustomTitleValue = customTitle;
    if (title === customTitle || pageNameOptionInBrowserTab === SeoPageTitleInTabOptions.DEFAULT) {
        finalCustomTitleValue = '';
    }
    const patch = {
        name,
        customTitle: finalCustomTitleValue,
        url: encodeWebspaceUri(url),
        description,
        hidden,
        public: isPublished,
        robots: excludeFromIndexing ? PAGE_ROBOTS_NOINDEX : PAGE_ROBOTS_ALL,
        socialShareAsset,
    };

    applyPatchToSitePage(page, patch);
};
