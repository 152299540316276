import { OPENING_HOURS_KIND } from "./kind";
import { openingHoursCalcRenderProps } from "./calcRenderProps";
import { openingHoursWorkspaceView } from "./view/index";
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";
import { getSpecificStyles } from "./getSpecificStyles";
import type { OpeningHoursComponent } from "./flowTypes";
import type { CalcProps } from "../../Preview/flowTypes";

export default {
    kind: OPENING_HOURS_KIND,
    view: openingHoursWorkspaceView,
    getSpecificStyles,
    calcProps: ({
        component,
        componentId,
        componentsDependencies,
        previewBackupTime,
        siteMap,
        globalVariables,
        siteSettings,
        globalStyles,
        selectedParentTheme
    }: CalcProps<OpeningHoursComponent>) =>
        openingHoursCalcRenderProps({
            component,
            componentId,
            componentExtension: null,
            componentDependencies: {
                // @ts-ignore
                ...componentsDependencies[OPENING_HOURS_KIND],
                site: siteMap,
                globalVariables,
                stylesheets: globalStyles,
                themeColorsData: getThemeColorsFromStylesheet(globalStyles),
                themeSettingsData: siteSettings.themeSettingsData
            },
            isWorkspace: false,
            inEditMode: false,
            previewBackupTime,
            selectedParentTheme
        })
};
