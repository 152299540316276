/* eslint-disable max-len */

import React from 'react';
import Measure from "react-measure";

import type { TableViewProps } from '../flowTypes';

import TableView from './Table';
import { LoadingIndicator } from "../../../../view/common/LoadingIndicator/index";
import * as TableStyles from './Table.css';
import { addComponentAdjustmentDataEntry } from '../../../Workspace/epics/componentsEval/adjustmentDataDispatchCache';
import { MIN_TABLE_WIDTH } from '../constants';

export default (props: TableViewProps) => {
    const
        { tableEditModeState, inEditMode, componentId } = props,
        shouldShowLoader = inEditMode && tableEditModeState.processingOfMultipleTinyMCEInstancesInProgress;

    return (
        <div>
            {shouldShowLoader && <div className={TableStyles.loader}><LoadingIndicator /></div>}
            <Measure
                bounds
                onResize={({ bounds: { height } }) => addComponentAdjustmentDataEntry(componentId, { minDimensions: { width: MIN_TABLE_WIDTH, height: Math.round(height) } })}
            >
                {({ measureRef }) => (
                    <div ref={measureRef}>
                        <TableView {...props} />
                    </div>
                )}
            </Measure>
        </div>
    );
};
