import React from 'react';
import { Intl, injectIntl } from "../../../view/intl/index";
import Combobox from "../../../view/common/Combobox/index";
import mctaComboboxStyles from '../MctaCombobox/MctaCombobox.css';
import type { ThemeButtonTypes } from '../../ThemeGlobalData/flowTypes';
import { ComboboxOptions } from '../../../view/common/Combobox/flowTypes';

export type MctaThemeSelectorProps = {
    dispatch: Dispatch,
    intl: Intl,
    onThemeChangeAction: string,
    selectedTheme: ThemeButtonTypes,
    themeOptions: ComboboxOptions,
    width?: number,
    label?: string,
    className: string,
    optionRenderer: Function
};

class MctaThemeSelector extends React.Component<MctaThemeSelectorProps> {
    render() {
        const {
                intl,
                dispatch,
                onThemeChangeAction,
                selectedTheme,
                themeOptions,
                width = 150,
                className,
                optionRenderer
            } = this.props,
            onInputChange = (option) => {
                dispatch({ type: onThemeChangeAction, payload: option.value });
            };
        return (
            <div className={mctaComboboxStyles.mctaCombobox}>
                <Combobox
                    value={selectedTheme}
                    style={{ width }}
                    options={themeOptions}
                    onChange={onInputChange}
                    className={className}
                    // @ts-ignore
                    intl={intl}
                    optionRenderer={optionRenderer}
                />
            </div>
        );
    }
}

const MctaThemeSelectorIntl = injectIntl(MctaThemeSelector);

export {
    MctaThemeSelectorIntl as default,
    MctaThemeSelectorIntl
};
