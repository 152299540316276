import ThemeOnboardingDialog from "./view/index";
import getCenteredDialogConfig from "../../DialogManager/getCenteredDialogConfig";
import { getCenterPositionForDialog } from "../../DialogManager/utility";

const HEIGHT = 640,
    WIDTH = 712,
    themeOnboardingDialog = {
        ...getCenteredDialogConfig(WIDTH, HEIGHT, ThemeOnboardingDialog),
        confFactory: ({ browserWidth, browserHeight }) => {
            return {
                position: getCenterPositionForDialog(WIDTH, HEIGHT, browserWidth, browserHeight),
                modal: true,
                dimensions: {
                    width: WIDTH,
                    height: HEIGHT
                }
            };
        },
        moveToCenter: true
    };
export { themeOnboardingDialog };
