import { ShutterstockInitialState } from "./ShutterstockInitialState";
import type { ShutterstockState } from '../types';
import { Lit } from '../../../../lit';
import {
    makeCombineReducer,
    makeDefaultStateReducers,
    makePayloadPathReducer,
    makeValuePathReducer,
} from '../../../../redux/makeReducer/index';
import * as Actions from '../actions';
import { selectShutterstockImageReducer } from "./selectShutterstockImageReducer";
import makeInputReducer from "../../../inputControls/input/reducerFactory/makeInputReducer";
import { shutterstockToggleReducer } from './shutterstockToggleReducer';
import { lengthInputValidation } from "../../../inputControls/input/commonValidations/index";
import type { MakeCombineReducerConfig } from "../../../../redux/makeReducer/flowTypes";

export const shutterstockReducer = (config: MakeCombineReducerConfig = { combineReducers: {} }) => makeCombineReducer({
    combineReducers: {
        ...makeDefaultStateReducers(ShutterstockInitialState),
        [Lit.filters]: makeCombineReducer({
            combineReducers: {
                ...makeDefaultStateReducers(ShutterstockInitialState.filters),
                [Lit.search]: makeInputReducer({
                    actionType: Actions.SHUTTERSTOCK_SEARCH_INPUT_CHANGE_ACTION,
                    validations: [
                        {
                            validation: lengthInputValidation,
                            options: { min: 0, max: 200 },
                        }
                    ],
                    rejectInvalidValue: true,
                }),
            },
        }),
        ...config.combineReducers
    },
    handleActions: {
        [Actions.INITIALIZE_SHUTTERSTOCK_ACTION]: makeValuePathReducer(true, [Lit.initialized]),
        [Actions.SHUTTERSTOCK_TYPE_IMAGES_CLICKED_ACTION]: shutterstockToggleReducer(
            Lit.isTypeImages, Lit.isTypeIllustrations
        ),
        [Actions.SHUTTERSTOCK_TYPE_ILLUSTRATIONS_CLICKED_ACTION]: shutterstockToggleReducer(
            Lit.isTypeIllustrations, Lit.isTypeImages
        ),
        [Actions.SHUTTERSTOCK_PORTRAIT_ORIENTATION_CLICKED_ACTION]: shutterstockToggleReducer(
            Lit.isPortraitMode, Lit.isLandscapeMode
        ),
        [Actions.SHUTTERSTOCK_LANDSCAPE_ORIENTATION_CLICKED_ACTION]: shutterstockToggleReducer(
            Lit.isLandscapeMode, Lit.isPortraitMode
        ),
        [Actions.SELECT_SHUTTERSTOCK_CATEGORY_ACTION]: (state: ShutterstockState, action: Record<string, any>) => ({
            ...state,
            [Lit.filters]: {
                ...state[Lit.filters],
                [Lit.category]: action.payload.categoryId
            }
        }),
        [Actions.DESELECT_ALL_SHUTTERSTOCK_IMAGES_ACTION]: makeValuePathReducer([], [Lit.selectedImages]),
        [Actions.GET_SHUTTERSTOCK_CATEGORIES_SUCCESS_ACTION]: (state: ShutterstockState, action: Record<string, any>) => ({
            ...state,
            [Lit.categoriesLoading]: false,
            [Lit.categories]: action.payload.categories
        }),
        [Actions.SEARCH_SHUTTERSTOCK_IMAGES_REQUEST_ACTION]: makeValuePathReducer(true, [Lit.imagesLoading]),
        [Actions.SEARCH_SHUTTERSTOCK_IMAGES_SUCCESS_ACTION]: (state: ShutterstockState, action: Record<string, any>) => ({
            ...state,
            [Lit.imagesLoading]: false,
            [Lit.images]: action.payload.images,
            [Lit.imagesSearchId]: action.payload.imagesSearchId,
            [Lit.filters]: {
                ...state[Lit.filters],
                [Lit.page]: 1,
            },
            [Lit.emptySearchResults]: false,
        }),
        [Actions.SEARCH_SHUTTERSTOCK_IMAGES_FAILURE_ACTION]: makeValuePathReducer(false, [Lit.imagesLoading]),
        [Actions.SELECT_SHUTTERSTOCK_IMAGE_ACTION]: selectShutterstockImageReducer,
        [Actions.GET_SHUTTERSTOCK_DOWNLOADED_IMAGES_REQUEST_ACTION]: makeValuePathReducer(true, [Lit.downloadsLoading]),
        [Actions.GET_SHUTTERSTOCK_DOWNLOADED_IMAGES_SUCCESS_ACTION]: (state: ShutterstockState, action: Record<string, any>) => ({
            ...state,
            [Lit.downloadsLoading]: false,
            [Lit.downloads]: action.payload.downloads
        }),
        [Actions.GET_SHUTTERSTOCK_DOWNLOADED_IMAGES_FAILURE_ACTION]:
            makeValuePathReducer(false, [Lit.downloadsLoading]),
        [Actions.HIDE_SHUTTERSTOCK_PREMIUM_DOWNLOADS_MSG_ACTION]: (state: ShutterstockState) => ({
            ...state,
            [Lit.showPremiumDownloadsMsg]: false
        }),
        [Actions.LOAD_SHUTTERSTOCK_IMAGS_PAGE_REQUEST_ACTION]: makeValuePathReducer(true, [Lit.imagesPageLoading]),
        [Actions.LOAD_SHUTTERSTOCK_IMAGS_PAGE_SUCCESS_ACTION]: (state: ShutterstockState, action: Record<string, any>) => {
            const
                { payload: { images: pageImages }, endpointParams: [, { page: nextPage }] } = action,
                nextImages = [...state.images, ...pageImages];

            return {
                ...state,
                [Lit.filters]: {
                    ...state[Lit.filters],
                    [Lit.page]: nextPage,
                },
                [Lit.images]: nextImages,
                [Lit.imagesPageLoading]: false,
                [Lit.emptySearchResults]: !pageImages.length
            };
        },
        [Actions.LOAD_SHUTTERSTOCK_IMAGS_PAGE_FAILURE_ACTION]: makeValuePathReducer(false, [Lit.imagesPageLoading]),
        [Actions.SHUTTERSTOCK_GRID_SCROLL_TOP_ACTION]: makePayloadPathReducer([Lit.gridScrollTop]),
        [Actions.DOWNLOAD_SHUTTERSTOCK_IMAGES_REQUEST_ACTION]: makeValuePathReducer(true, [Lit.isUploading]),
        [Actions.DOWNLOAD_SHUTTERSTOCK_IMAGES_SUCCESS_ACTION]: makeValuePathReducer(false, [Lit.isUploading]),
        [Actions.DOWNLOAD_SHUTTERSTOCK_IMAGES_FAILURE_ACTION]: makeValuePathReducer(false, [Lit.isUploading]),
        [Actions.SHUTTERSTOCK_CLEAN_SEARCH_ACTION]: (state: ShutterstockState) => ({
            ...state,
            [Lit.filters]: {
                ...state[Lit.filters],
                [Lit.search]: {
                    ...state[Lit.filters][Lit.search],
                    value: '',
                },
            },
        }),
        [Actions.STORE_SUCCESSFUL_SHUTTERSTOCK_PARTIAL_DOWNLOADS_ACTION]:
            makePayloadPathReducer([Lit.partialDownloads]),
        [Actions.SKIP_ADD_IMAGE_COMPONENT_POST_DOWNLOAD_ACTION]:
            makeValuePathReducer(true, [Lit.skipComponentActionPostDownload]),
        [Actions.RESET_SKIP_ADD_IMAGE_COMPONENT_POST_DOWNLOAD_ACTION]:
            makeValuePathReducer(false, [Lit.skipComponentActionPostDownload]),
        [Actions.DISABLE_SHUTTERSTOCK_MCTA_ACTION]:
            makeValuePathReducer(true, [Lit.mctaDisabled]),
        [Actions.ENABLE_SHUTTERSTOCK_MCTA_ACTION]:
            makeValuePathReducer(false, [Lit.mctaDisabled]),
        ...(config.handleActions || {})
    },
});
