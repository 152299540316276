import { withSelector } from '../../../../epics/makeCondition';
import valueActionType from './valueActionType';
import * as selectors from './selectors';
import { stylesheetUpdateReasonsThatMayAffectTextHeightMap } from "./updateReasons";

export const
    TextNormalStyleId = withSelector(valueActionType, selectors.textNormalGlobalstyleId),
    TextHeading1FontSizeSelector = withSelector(valueActionType, selectors.textHeading1FontSize),
    StylesheetUpdateThatMayCauseTextHeightChangeHappenedSelector = withSelector(valueActionType,
        ({ epicUpdateReason }) => {
            if (stylesheetUpdateReasonsThatMayAffectTextHeightMap[epicUpdateReason]) {
                return { value: true };
            }
            return { value: false };
        });

