export const
    WIDGETS_VIMEO_SET_LINK = "WIDGETS_VIMEO_SET_LINK",
    WIDGETS_VIMEO_UPDATE_PROPS = "WIDGETS_VIMEO_UPDATE_PROPS",
    WIDGETS_VIMEO_UPDATE_LINK = "WIDGETS_VIMEO_UPDATE_LINK",
    WIDGETS_VIMEO_SHOW_UPDATE_LINK_DIALOG = "WIDGETS_VIMEO_SHOW_UPDATE_LINK_DIALOG",
    WIDGETS_VIMEO_TOGGLE_AUTOPLAY = "WIDGETS_VIMEO_TOGGLE_AUTOPLAY",
    WIDGETS_VIMEO_TOGGLE_LOOP = "WIDGETS_VIMEO_TOGGLE_LOOP",
    WIDGETS_VIMEO_TOGGLE_SHOW_TEXT_LINK = "WIDGETS_VIMEO_TOGGLE_SHOW_TEXT_LINK",
    WIDGETS_VIMEO_TOGGLE_PORTRAIT = "WIDGETS_VIMEO_TOGGLE_PORTRAIT",
    WIDGETS_VIMEO_TOGGLE_TITLE = "WIDGETS_VIMEO_TOGGLE_TITLE",
    WIDGETS_VIMEO_TOGGLE_BYLINE = "WIDGETS_VIMEO_TOGGLE_BYLINE";

