import cx from 'classnames';
import * as React from 'react';
import styles from './LoadingIndicator.css';

type Props = {
    height?: number | string;
    center?: boolean;
    className?: string;
    isGreen?: true;
    absoluteCenter?: boolean;
};

const LoadingIndicator = ({ height, center = true, absoluteCenter = false, className, isGreen, ...props }: Props) => (
    <div
        className={cx({ [styles.center]: center, [styles.absoluteCenter]: absoluteCenter }, className)}
        style={{ height }}
        {...props}
    >
        <div className={styles['animatedLoadingDots-container']}>
            <div className={cx(styles.animatedLoadingDots, { [styles.greenDots]: isGreen })}>
                <div />
                <div />
                <div />
            </div>
        </div>
    </div>
);

export {
    LoadingIndicator as default,
    LoadingIndicator
};
