import type { ComputeComponentDependenciesForPreviewType, ExtractReturnType } from "../types";

export const computeComponentDependenciesForPreviewForAddress = ({
    siteSettings,
    isTemplatePreview,
    globalVariables: {
        addressUrl,
        addressName,
        addressStreetAddress,
        addressZip,
        addressCity,
        addressArea,
        addressCountryCode,
    }
}: ComputeComponentDependenciesForPreviewType): Record<any, ExtractReturnType> => ({
    addressUrl: isTemplatePreview ? addressUrl : siteSettings.generalData.addressUrl,
    addressName: isTemplatePreview ? addressName : siteSettings.generalData.addressName,
    addressStreetAddress: isTemplatePreview ? addressStreetAddress : siteSettings.generalData.addressStreetAddress,
    addressZip: isTemplatePreview ? addressZip : siteSettings.generalData.addressZip,
    addressCity: isTemplatePreview ? addressCity : siteSettings.generalData.addressCity,
    addressArea: isTemplatePreview ? addressArea : siteSettings.generalData.addressArea,
    addressCountryCode: isTemplatePreview ? addressCountryCode : siteSettings.generalData.addressCountryCode,
});
