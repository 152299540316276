import * as React from 'react';
import cx from 'classnames';
import styles from './LongTextTip.css';

type PropTypes = {
    children?: React.ReactNode,
    maxWidth?: number,
    className?: string,
    style?: Record<string, any>,
    onClick?: () => void,
};

const onMouseOver = (e: any) => {
    const el = e.target;
    if (el.offsetWidth < el.scrollWidth) el.setAttribute('data-title', el.textContent);
};

export default ({ children, maxWidth, className = '', style: propStyle, onClick }: PropTypes) => {
    const style = propStyle || {};

    if (maxWidth) style.maxWidth = maxWidth;

    return (
        <span
            className={cx(styles.tip, className)}
            style={style}
            onMouseOver={onMouseOver}
            onClick={onClick}
        >
            {children}
        </span>
    );
};
