import React from 'react';
import { connect } from 'react-redux';
import styles from './WhatsNewTip.css';
import type { ReactElementRef } from '../../../globalTypes';
import type { AppState } from '../../../redux/modules/flowTypes';
import { whatsNewShowTipAppSel } from '../selectors';
import { TooltipPosition } from '../../presentational/Tooltip/constants';
import { TooltipCom } from '../../presentational/Tooltip/TooltipCom';
import { Msg } from '../../../view/intl/index';
import { hideWhatsNewTipAction } from '../actions';

type Props = {
    userProfileButtonRef: ReactElementRef<any>,
    show: boolean,
    dispatch: Dispatch,
};

class WhatsNewTipClass extends React.Component<Props> {
    closeBtnClicked = () => {
        this.props.dispatch(hideWhatsNewTipAction());
    }

    render() {
        const { userProfileButtonRef, show } = this.props;

        return (
            <TooltipCom
                show={show}
                targetRef={userProfileButtonRef}
                position={TooltipPosition.BOTTOM}
                theme={{
                    container: styles.container,
                    pointer: styles.pointer,
                    tipInfo: styles.tipInfo,
                }}
            >
                <span className={styles.hintIcn} />
                <div className={styles.messageContainer}>
                    <p className={styles.messageHeader}><Msg k="tip">Tip:</Msg></p>
                    <p className={styles.message}>
                        <Msg k="whatsNew.tip.message">
                            Check out the \"What\'s new\" section! This blue dot will show when you have new messages.
                        </Msg>
                    </p>
                </div>
                <span className={styles.closeBtn} onClick={this.closeBtnClicked} />
            </TooltipCom>
        );
    }
}

const mapStateToProps = (appState: AppState) => ({
    show: whatsNewShowTipAppSel(appState),
});

export const WhatsNewTip = connect(mapStateToProps)(WhatsNewTipClass);
