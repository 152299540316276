import React from 'react';
import { Box } from '@mui/material';

import { useIntl } from "../../../../../../wbtgen/src/view/intl/injectIntl";

export type GoogleAdsSettingsViewBodyTextProps = {
    text: string;
};

export const GoogleAdsSettingsViewBodyText = (props: GoogleAdsSettingsViewBodyTextProps) => {
    const intl = useIntl();

    return (
        <Box component="p" fontSize="15px" lineHeight="24px" marginTop="10px">
            {intl.msgJoint(props.text)}
        </Box>
    );
};
