import React, { useContext } from 'react';
import cx from 'classnames';
import { Msg } from "../../../../../../view/intl";
import * as styles from './Reviews.css';
import { ReviewsDataContext } from '../dialogContexts';
import { ReviewViewType } from '../../../constants';
import ReviewCell from '../../../components/ReviewCard/ReviewCard';
import Scrollbar from '../../../../../../view/common/Scrollbar/index';
import { IdMap } from '../../../flowTypes';
import { getReviewTimeText } from '../../../utils';

const SelectReviewsView = ({ onClick }) => {
    const { reviewsApiData, selectedReviewsIds } = useContext(ReviewsDataContext);

    const { reviews } = reviewsApiData.reviewsData;

    const reviewTimeText = getReviewTimeText();

    const onReviewClick = ({ reviewId, isSelected }) => {
        let selectedIds: IdMap = [];
        if (!isSelected) {
            selectedIds = Array.from(new Set([...selectedReviewsIds, reviewId]));
        } else {
            selectedIds = selectedReviewsIds.filter(id => id !== reviewId);
        }
        if (selectedIds.length > 6) {
            return;
        }
        onClick(selectedIds);
    };

    return <div className={styles.reviewsBody}>
        <div className={styles.reviewsContainerWrapper}>
            <Scrollbar
                fitHeight
                height="100%"
                className={styles.scrollbar}
                theme={{
                    trackVertical: styles.trackVertical
                }}
            >
                <div className={cx(styles.reviewsContainer)}>
                    {reviews && reviews.map(review => {
                        const { reviewId, starRating, comment, updateTime, profilePhotoUrl, displayName, reviewUrl } = review;
                        const isSelected = selectedReviewsIds.includes(reviewId);
                        return <ReviewCell
                            layoutType={ReviewViewType.CARD}
                            key={reviewId}
                            name={displayName}
                            profilePhoto={profilePhotoUrl}
                            rating={starRating}
                            comment={comment}
                            date={updateTime}
                            onClick={() => onReviewClick({ reviewId, isSelected })}
                            customClass={cx(styles.card, { [styles.selected]: isSelected })}
                            reviewUrl={reviewUrl}
                            reviewTimeText={reviewTimeText}
                        />;
                    })}
                </div>
            </Scrollbar>
        </div>
        <div className={styles.selectedText}>
            {
                selectedReviewsIds.length !== 1 ?
                    <Msg
                        k="component.googleReviews.reviewsSelected"
                        params={{ count: selectedReviewsIds.length }}
                    >
                        {`{count} reviews selected`}
                    </Msg> :
                    <Msg
                        k="component.googleReviews.reviewSelected"
                        params={{ count: selectedReviewsIds.length }}
                    >
                        {`{count} review selected`}
                    </Msg>
            }
        </div>
    </div>;
};

export {
    SelectReviewsView
};
