import { BLACK_THEME_COLOR, WHITE_THEME_COLOR } from '../../ThemeGlobalData/constants';

export const MveHeaderTitleType = {
        PAGE: 'page',
        WEBSITE: 'website',
    },
    HEADER_TITLE_COLOR = '#4d4d4d',
    THEME_HEADER_TITLE_COLOR = BLACK_THEME_COLOR,
    HEADER_BG_COLOR = '#ffffff',
    THEME_HEADER_BG_COLOR = WHITE_THEME_COLOR,
    MenuStyleDefaults = {
        FONT_SIZE: 16,
        LINE_HEIGHT: 3,
        CLOSED_ICON_COLOR: '#4d4d4d',
        THEME_CLOSED_ICON_COLOR: BLACK_THEME_COLOR,
        OPEN_ICON_COLOR: '#4d4d4d',
        THEME_OPEN_ICON_COLOR: BLACK_THEME_COLOR,
        OPEN_TEXT_COLOR: '#4d4d4d',
        THEME_OPEN_TEXT_COLOR: BLACK_THEME_COLOR,
        OPEN_BG_COLOR: 'rgba(255,255,255, 1)',
        THEME_OPEN_BG_COLOR: WHITE_THEME_COLOR,
        SELECTED_TEXT_COLOR: '#4d4d4d',
        THEME_SELECTED_TEXT_COLOR: BLACK_THEME_COLOR,
        SELECTED_BG_COLOR: 'rgba(255,255,255, 0)',
        THEME_SELECTED_BG_COLOR: WHITE_THEME_COLOR,
    };
