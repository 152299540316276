import { put, take } from "redux-saga/effects"; // eslint-disable-line node/file-extension-in-import
import type { ApiAction } from "../../redux/middleware/api/flowTypes";
import CALL_API from "../../redux/middleware/api/CALL_API";
import raceGen from "./raceGen";

type ActionTakeWrapper = (action: string) => any;
type FailureHandler = (payload: any) => any;

export default function* (
    apiAction: ApiAction,
    onFailure?: null | FailureHandler,
    actionTakeWrapper?: null | ActionTakeWrapper
): Generator<any, any, any> {
    yield put(apiAction);

    const
        actionTaker = actionTakeWrapper || take,
        [, successAction, failureAction] = apiAction[CALL_API].types;

    const { success, failure } = yield* raceGen({
        success: actionTaker(successAction),
        failure: actionTaker(failureAction)
    });

    if (failure) {
        if (onFailure) {
            yield* onFailure(failure.payload);
            yield put(failure);
            return null;
        }
        throw new Error(failureAction);
    }

    return success.payload;
}
