import type { SideBarItemDef } from '../../SideBar/types';

import imageStyles from '../../Images/imageStyle.css';

export const pageURL = 'pageURL';
export const ONLINE_PRESENCE_PAGE_SIDE_BAR_ITEM_ID = 'ONLINE_PRESENCE_PAGE_SIDE_BAR_ITEM_ID';

export const OnlinePresenceSideBarItem: SideBarItemDef = {
    id: ONLINE_PRESENCE_PAGE_SIDE_BAR_ITEM_ID,
    icon: imageStyles.onlinePresence,
    title: 'msg: seo {SEO}',
    header: {
        title: "msg: seoLong {SEO (Search Engine Optimization)}",
        description: "msg: dashboard.onlinePresence.page.description {Manage how this page shows up in search engine results.}"
    }
};
