import { IMAGE_EDITOR_MESSAGE, IMAGE_EDITOR_EDIT_IMAGE_CLICKED } from "./actionTypes";
import type { ImageAsset } from "../App/flowTypes";

export const
    updateImageEditorMessage = (messageCode: string, suffix ?: string) => {
        const payload = { code: messageCode, suffix: '' };
        if (suffix) {
            payload.suffix = suffix;
        }
        return { type: IMAGE_EDITOR_MESSAGE, payload };
    },
    editImageAC = (
        replaceWithEditedImageAction: string,
        asset: ImageAsset,
        extraPayload?: Object
    ) => ({
        type: IMAGE_EDITOR_EDIT_IMAGE_CLICKED,
        payload: {
            replaceWithEditedImageAction,
            asset,
            extraPayload
        }
    });
