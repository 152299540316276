import type { Address } from '../../googleMaps/googleMaps';
import type { GmbOpeningHour } from '../../oneweb/OpeningHours/flowTypes';

export type GmbDataType = {
    name: string,
    addressFields: Address,
    openingHours: { periods: GmbOpeningHour[] },
    phoneNumber: string,
    gmbCategories: string
};

export enum validateOnlyEnum {
    YES = 'yes',
    EMPTY = ''
}

export type GmbCreateDataType = {
    validateOnly: validateOnlyEnum,
    location: string
};

export type GmbConnectDataType = {
    accountPath: string,
    locationPath: string
}

export type ConnectYourListing = 'CONNECT_YOUR_LISTING';
export type CreateYourListing = 'CREATE_YOUR_LISTING';

export type CreateYourListingBusinessInformationStep = 'CREATE_YOUR_LISTING_BUSINESS_INFORMATION_STEP';
export type CreateYourListingContactDetailsStep = 'CREATE_YOUR_LISTING_CONTACT_DETAILS_STEP';
export type CreateYourListingOpeningHoursStep = 'CREATE_YOUR_LISTING_OPENING_HOURS_STEP';

export type gmbErrorType = {
    field: string,
    msg: string
};

export type errorDataType = [string, gmbErrorType | null, string];
export type onGoingGMBActionType = ConnectYourListing | CreateYourListing | null;
export type createYourListingStepType = CreateYourListingBusinessInformationStep |
    CreateYourListingContactDetailsStep | CreateYourListingOpeningHoursStep;

export type BusinessListingServiceDefaultStateType = {
    onGoingAction: onGoingGMBActionType,
    createYourListingStep: createYourListingStepType,
    isWaitingForApi: boolean,
    isGmbAccessAllowed: boolean,
    isLoggedIn: boolean,
    isConnected: boolean,
    gmbData?: GmbDataType | null | undefined,
    doNotShowPublishDialog: boolean,
    errorData: errorDataType,
};

export type BusinessListingServiceScopeType = { isUnsavedCheckingInProgress: boolean; };
