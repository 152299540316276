import { awsAuthAction } from "../src/redux/middleware/aws/actions";
import type { ApiAction } from "../src/redux/middleware/api/flowTypes";
import { apiAction } from "../src/redux/middleware/api/actions";

export const
    SHOW_DEMO_LOGIN_SCREEN_ACTION = 'SHOW_DEMO_LOGIN_SCREEN_ACTION',
    showDemoLoginScreenAction = () => ({
        type: SHOW_DEMO_LOGIN_SCREEN_ACTION
    });

export const
    DEMO_LOGOUT_REQUEST_ACTION = 'DEMO_LOGOUT_REQUEST_ACTION',
    DEMO_LOGOUT_SUCCESS_ACTION = 'DEMO_LOGOUT_SUCCESS_ACTION',
    DEMO_LOGOUT_FAILURE_ACTION = 'DEMO_LOGOUT_FAILURE_ACTION',
    demoSignOutAction = () => awsAuthAction({
        actionTypes: [
            DEMO_LOGOUT_REQUEST_ACTION,
            DEMO_LOGOUT_SUCCESS_ACTION,
            DEMO_LOGOUT_FAILURE_ACTION,
        ],
        serviceMethod: 'signOut',
        serviceMethodParams: []
    });

export const
    DEMO_LOGIN_AUTHENTICATION_SUCCESSFUL_ACTION = 'DEMO_LOGIN_AUTHENTICATION_SUCCESSFUL_ACTION',
    loginAuthenticationSuccessfulAction = () => ({
        type: DEMO_LOGIN_AUTHENTICATION_SUCCESSFUL_ACTION
    });

export const
    DEMO_DOMAIN_AVAILABILITY_REQUEST_ACTION = 'DEMO_DOMAIN_AVAILABILITY_REQUEST_ACTION',
    DEMO_DOMAIN_AVAILABILITY_SUCCESS_ACTION = 'DEMO_DOMAIN_AVAILABILITY_SUCCESS_ACTION',
    DEMO_DOMAIN_AVAILABILITY_FAILURE_ACTION = 'DEMO_DOMAIN_AVAILABILITY_FAILURE_ACTION',
    demoDomainAvailabilityAction = (domainName: string, trialId: string, token: string): ApiAction => {
        return apiAction({
            types: [
                DEMO_DOMAIN_AVAILABILITY_REQUEST_ACTION,
                DEMO_DOMAIN_AVAILABILITY_SUCCESS_ACTION,
                DEMO_DOMAIN_AVAILABILITY_FAILURE_ACTION,
            ],
            endpoint: 'getDomainAvailabilityForTrial',
            endpointParams: [domainName, trialId, token],
        });
    };

