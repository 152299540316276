import * as R from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly } from '../../../../epics/makeCondition';
import valueActionType from "./valueActionType";
import {
    SET_DEFAULT_HEIGHT_HEADER_FLAG,
    SET_DEFAULT_HEIGHT_FOOTER_FLAG,
    RESET_DEFAULT_HEIGHT_HEADER_FLAG,
    RESET_DEFAULT_HEIGHT_FOOTER_FLAG,
} from "./actionTypes";
import { PROP_PANEL_CHANGE_COMPONENT_HEIGHT } from '../../actionTypes';
import { ReceiveOnlyComponentsMap, ReceiveOnlySelectedComponentIdSelector,
    userInteractionModeSelector, componentsMapSelector } from "../componentsEval/selectorActionTypes";
import { componentAttachmentsVAT } from "../componentAttachements/valueActionType";
import { isHeaderSection, isFooterSection } from '../../../oneweb/Section/utils';
import {
    COMPONENTS_RESIZED,
} from "../componentsEval/actionTypes";
import * as BgActionTypes from "../../../oneweb/Background/actionTypes";
import { STRIP_TITLE_CHANGE } from "../../../oneweb/Strip/actionTypes";
import { HEADER_SHARE_BG_IMG_WITH_HEADER_TOGGLE } from "../../../oneweb/Section/actionTypes";
import type { AnyComponent } from "../../../../redux/modules/children/workspace/flowTypes";
import type { DefaultHeaderFooterHeightsState } from "./flowTypes";
import { UNDO_INITIAL_STATE } from "../../../../epics/undoManager/updateReasons";
import componentsEvalVAT from "../componentsEval/valueActionType";
import { IDLE } from '../componentsEval/userInteractionMutations/interactionModes';
import { headerSectionIdVAT } from '../../../oneweb/Section/epics/headerSectionIdEpic';
import { footerSectionIdVAT } from '../../../oneweb/Section/epics/footerSectionIdEpic';

const DEFAULT_HEADER_HEIGHT_RESET = 'DEFAULT_HEADER_HEIGHT_RESET',
    DEFAULT_FOOTER_HEIGHT_RESET = 'DEFAULT_FOOTER_HEIGHT_RESET',
    defaultState: DefaultHeaderFooterHeightsState = {
        isHeader: false,
        isFooter: false,
    },
    getUpdatedState = (state: DefaultHeaderFooterHeightsState, component: AnyComponent) => {
        if (isHeaderSection(component)) {
            return {
                state: {
                    ...state,
                    isHeader: false
                },
                updateReason: DEFAULT_HEADER_HEIGHT_RESET
            };
        } else if (isFooterSection(component)) {
            return {
                state: {
                    ...state,
                    isFooter: false
                },
                updateReason: DEFAULT_FOOTER_HEIGHT_RESET
            };
        }
        return { state };
    };

export default makeEpic({
    defaultState,
    undo: {
        isUndoableChange: R.T,
        undoablePaths: [['isHeader'], ['isFooter']]
    },
    valueActionType,
    updaters: [
        {
            conditions: [SET_DEFAULT_HEIGHT_HEADER_FLAG],
            reducer: ({ state }) => {
                return {
                    state: { ...state, isHeader: true },
                    updateReason: UNDO_INITIAL_STATE
                };
            }
        },
        {
            conditions: [SET_DEFAULT_HEIGHT_FOOTER_FLAG],
            reducer: ({ state }) => {
                return {
                    state: { ...state, isFooter: true },
                    updateReason: UNDO_INITIAL_STATE
                };
            }
        },
        {
            conditions: [RESET_DEFAULT_HEIGHT_HEADER_FLAG],
            reducer: ({ state }) => {
                return {
                    state: { ...state, isHeader: false },
                    updateReason: UNDO_INITIAL_STATE
                };
            }
        },
        {
            conditions: [RESET_DEFAULT_HEIGHT_FOOTER_FLAG],
            reducer: ({ state }) => {
                return {
                    state: { ...state, isFooter: false },
                    updateReason: UNDO_INITIAL_STATE
                };
            }
        },
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                COMPONENTS_RESIZED
            ],
            reducer: ({ values: [componentsMap, { componentsId }], state }) => {
                if (!state.isHeader && !state.isFooter) {
                    return { state };
                }
                const isHeaderResized = componentsId.some(cmpId => isHeaderSection(componentsMap[cmpId]));
                if (isHeaderResized) {
                    return {
                        state: { ...state, isHeader: false },
                        updateReason: DEFAULT_HEADER_HEIGHT_RESET
                    };
                }
                const isFooterResized = componentsId.some(cmpId => isFooterSection(componentsMap[cmpId]));
                if (isFooterResized) {
                    return {
                        state: { ...state, isFooter: false },
                        updateReason: DEFAULT_FOOTER_HEIGHT_RESET
                    };
                }
                return { state };
            }
        },
        ...[...Object.keys(BgActionTypes), PROP_PANEL_CHANGE_COMPONENT_HEIGHT, STRIP_TITLE_CHANGE,
            HEADER_SHARE_BG_IMG_WITH_HEADER_TOGGLE].map(action => ({
            conditions: [
                ReceiveOnlySelectedComponentIdSelector,
                ReceiveOnlyComponentsMap,
                action
            ],
            reducer: ({ values: [selectedComponentId, componentsMap], state }) => {
                if (!state.isHeader && !state.isFooter) {
                    return { state };
                }
                return getUpdatedState(state, componentsMap[selectedComponentId]);
            }
        })),
        {
            keepFullActions: true,
            conditions: [
                receiveOnly(headerSectionIdVAT),
                receiveOnly(footerSectionIdVAT),
                receiveOnly(componentAttachmentsVAT),
                componentsEvalVAT,
            ],
            reducer: ({ values: [
                { payload: headerID },
                { payload: footerID },
                { payload: { attachments } },
                { payload: cmpEvalState }],
            state }) => {
                if (!state.isHeader && !state.isFooter) {
                    return { state };
                }
                if (!headerID || !footerID || userInteractionModeSelector(cmpEvalState) !== IDLE) {
                    return { state };
                }
                const componentsMap = componentsMapSelector(cmpEvalState);
                if (!componentsMap || !componentsMap[headerID] || !componentsMap[footerID]) {
                    return { state };
                }
                if (state.isHeader && attachments[headerID] && attachments[headerID].length) {
                    return {
                        state: {
                            ...state,
                            isHeader: false
                        },
                        updateReason: DEFAULT_HEADER_HEIGHT_RESET
                    };
                }
                if (state.isFooter && attachments[footerID] && attachments[footerID].length) {
                    return {
                        state: {
                            ...state,
                            isFooter: false
                        },
                        updateReason: DEFAULT_FOOTER_HEIGHT_RESET
                    };
                }
                return { state };
            }
        },
    ]
});
