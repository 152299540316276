import React, { useCallback, useState } from 'react';
import { Box, ClickAwayListener } from "@mui/material";
import type { TooltipProps } from '@mui/material/Tooltip';

import { Tooltip } from '../../../Tooltip';
import { useIsMobile } from '../../../../hooks/useIsMobileOrTablet';

import imageStyles from '../../../Images/imageStyle.css';
import { GOOGLE_TAG_LABELS, SUPPORTED_FORMATS_HEADER_TEXT } from './constants';

type SupportedFormatsInfoButtonProps = {
    intl: Intl;
};

export const SupportedFormatsInfoButton = ({ intl }: SupportedFormatsInfoButtonProps) => {
    const [open, setOpen] = useState(false);

    const handleToggle = useCallback(() => setOpen(!open), [open]);
    const handleClose = useCallback(() => setOpen(false), []);

    const isMobile = useIsMobile();
    const placement: TooltipProps["placement"] = isMobile ? "top" : "right";

    return (
        <Tooltip
            arrow
            open={open}
            placement={placement}
            title={
                <ClickAwayListener onClickAway={handleClose}>
                    <Box py="11px" pl="7px" pr="32px" position="relative" style={{ pointerEvents: 'auto', userSelect: 'none' }}>
                        <Box
                            className={imageStyles.closeLightTooltipIcon}
                            width="10px"
                            height="10px"
                            position="absolute"
                            top="6px"
                            right="2px"
                            style={{ cursor: 'pointer' }}
                            onClick={handleClose}
                        />
                        <Box component="h4" fontSize="13px" lineHeight="16px" fontWeight={600}>
                            {intl.msgJoint(SUPPORTED_FORMATS_HEADER_TEXT)}
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                            marginTop="6px"
                            fontSize="12px"
                            lineHeight="15px"
                            fontWeight={400}
                        >
                            {GOOGLE_TAG_LABELS.map((tagLabel, index) => <span key={index}>{intl.msgJoint(tagLabel)}</span>)}
                        </Box>
                    </Box>
                </ClickAwayListener>
            }
        >
            <Box
                component="span"
                className={imageStyles.info}
                display="inline-block"
                width="14px"
                height="14px"
                marginLeft="8px"
                sx={{ verticalAlign: "middle", cursor: 'pointer' }}
                onClick={handleToggle}
            />
        </Tooltip>
    );
};
