import kind from "./kind";
import { componentDependencies } from "../Background/previewConfig";
import calcRenderProps from "../Background/calcRenderProps";
import backgroundViewFactory from '../Background/view/backgroundViewFactory';
import styles from '../Strip/view/StripPreview.css';
import { makeGetBackgroundSpecificStyles } from '../Background/makeGetBackgroundSpecificStyles';
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";
import { getConditionalScript } from "../constants";
import type { GenerateScriptsProps } from "../flowTypes";

export default {
    kind,
    view: backgroundViewFactory(styles),
    calcProps: ({
        component,
        paddingBottom,
        template: { width: templateWidth },
        headerSectionId,
        topMostPageSectionId,
        isModernLayoutActivated,
        page: { shareHeaderAndFirstSectionBgImg, shareModernHeaderAndFirstSectionBgImg },
        siteSettings: { themeSettingsData },
        globalStyles
    }) => {
        return calcRenderProps({
            component,
            paddingBottom,
            componentDependencies: {
                ...componentDependencies,
                themeSettingsData,
                themeColorsData: getThemeColorsFromStylesheet(globalStyles),
                templateWidth,
                headerSectionId,
                topMostPageSectionId,
                // @ts-ignore // TODO define type of shareHeaderAndFirstSectionBgImg
                shareHeaderAndFirstSectionBgImg: {
                    enabled: isModernLayoutActivated ? shareModernHeaderAndFirstSectionBgImg : shareHeaderAndFirstSectionBgImg,
                },
            },
        });
    },
    getSpecificStyles: makeGetBackgroundSpecificStyles(),
    generateScripts: ({ isPublicPreview }: GenerateScriptsProps): string[] => [
        getConditionalScript('/renderStatic/modernLayouts/responsive.js', !isPublicPreview)
    ],
    js: [
        '/renderStatic/strip/stickyStrip.js',
        '/renderStatic/strip/parallax.js'
    ]
};
