import {
    UPGRADE_APPROVE_DIALOG_ID
} from "../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/UpgradeApproveDialog/UpgradeApproveDialog";

export const PublishConfirmationDialogId = 'PublishConfirmationDialogId',
    ContactFormEmailValidationFailed = 'ContactFormEmailValidationFailed',
    SaveAndPublishConfirmationDialogId = 'SaveAndPublishConfirmationDialogId',
    UpgradeFailureDialogId = 'UpgradeFailureDialogId',
    OverwriteAssetsDialogId = 'OverwriteAssetsDialogId',
    OverwriteClobberedAssetsDialogId = 'OverwriteClobberedAssetsDialogId',
    PublishingFailedDialogId = 'PublishingFailedDialogId',
    PublishingFailedInvalidLinksDialogId = 'PublishingFailedInvalidLinksDialogId',
    PublishWarningNoStartPageDialogId = 'PublishWarningNoStartPageDialogId',
    SeamlessUpgradeDialogId = 'SeamlessUpgradeDialogId',
    KeyboardShortcutsId = 'KeyboardShortcutsId',
    UpgradePlansDialogId = 'UpgradePlansDialogId',
    UpgradeSuccessfulDialogId = 'UpgradeSuccessfulDialogId',
    UpgradeApproveDialogId = UPGRADE_APPROVE_DIALOG_ID,
    TutorialVideoDialogId = 'TutorialVideoDialogId';
