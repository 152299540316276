import React from 'react';
import cx from 'classnames';
import * as styles from './icons.css';

const camelToUnderscore = (str) => str.replace(/([A-Z])/g, (g) => `_${g[0].toLowerCase()}`);

const NextGenIcon = ({ className = '', iconName, concept = '' }) => {
    const iconClassName = styles[`nextgen_${camelToUnderscore(iconName)}`] || '';
    const conceptClassName = styles[`nextgen_${camelToUnderscore(concept)}`] || '';
    return <div className={cx(styles.icon, className, iconClassName, conceptClassName, { [styles.noIcon]: !iconClassName })} />;
};

export default NextGenIcon;
