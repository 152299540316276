import {
    makeValuePathReducer,
    makePayloadPathReducer,
    makeValueReducer
} from "../../../../redux/makeReducer";
import type { PageTemplateSelectorState } from "../types";
import {
    DELETE_PAGE_TEMPLATE_FAILURE_ACTION,
    DELETE_PAGE_TEMPLATE_REQUEST_ACTION,
    DELETE_PAGE_TEMPLATE_SUCCESS_ACTION,
    DUPLICATE_PAGE_TEMPLATE_FAILURE_ACTION,
    DUPLICATE_PAGE_TEMPLATE_REQUEST_ACTION,
    DUPLICATE_PAGE_TEMPLATE_SUCCESS_ACTION,
    HIDE_PAGE_TEMPLATE_PREVIEW_ACTION,
    HOLD_PAGE_ITEM_SELECTOR_ITEM_ACTION,
    HOLD_PAGE_TEMPLATE_SELECTOR_ACTION,
    LOAD_PAGE_TEMPLATES_FAILURE_ACTION,
    LOAD_PAGE_TEMPLATES_REQUEST_ACTION,
    LOAD_PAGE_TEMPLATES_SUCCESS_ACTION,
    SELECT_PAGE_TEMPLATE_ACTION,
    SELECT_PAGE_TEMPLATE_PREVIEW_ACTION,
    SET_PAGE_TEMPLATES_ACTION,
    SHOW_PAGE_TEMPLATE_PREVIEW_ACTION,
    UNHOLD_PAGE_ITEM_SELECTOR_ITEM_ACTION,
    UNHOLD_PAGE_TEMPLATE_SELECTOR_ACTION
} from "../actions";
import { PageTemplateSelectorPath as Path } from "./paths";
import {
    holdPageTemplateSelectorItem,
    unholdPageTemplateSelectorItem,
    sortPageTemplates
} from "./functions";
import { DataPageTemplateRef } from "../../../../../dal/model";
import { Lit } from "../../../../lit";

export const PageTemplateSelectorInitialState = {
    [Lit.templates]: [],
    [Lit.isLoading]: false,
    [Lit.onHold]: false,
    [Lit.itemsOnHold]: [],
    [Lit.selectedId]: '',
    [Lit.previewId]: '',
    [Lit.isEqToInitialState]: true
};

export const pageTemplateSelectorReducer = makeValueReducer({
    defaultState: PageTemplateSelectorInitialState,
    handleActions: {
        [LOAD_PAGE_TEMPLATES_REQUEST_ACTION]: makeValuePathReducer(true, Path.isLoading),
        [LOAD_PAGE_TEMPLATES_SUCCESS_ACTION]: makeValuePathReducer(false, Path.isLoading),
        [LOAD_PAGE_TEMPLATES_FAILURE_ACTION]: makeValuePathReducer(false, Path.isLoading),
        [SELECT_PAGE_TEMPLATE_ACTION]: makePayloadPathReducer(Path.selectedId),
        [SET_PAGE_TEMPLATES_ACTION]: (state: PageTemplateSelectorState, action: Record<string, any>) => ({
            ...state,
            [Lit.templates]: sortPageTemplates(action.payload)
        }),
        [HOLD_PAGE_TEMPLATE_SELECTOR_ACTION]: makeValuePathReducer(true, Path.onHold),
        [UNHOLD_PAGE_TEMPLATE_SELECTOR_ACTION]: makeValuePathReducer(false, Path.onHold),
        [HOLD_PAGE_ITEM_SELECTOR_ITEM_ACTION]: (state: PageTemplateSelectorState, action: AnyAction) =>
            holdPageTemplateSelectorItem(action.payload, state),
        [UNHOLD_PAGE_ITEM_SELECTOR_ITEM_ACTION]: (state: PageTemplateSelectorState, action: AnyAction) =>
            unholdPageTemplateSelectorItem(action.payload, state),
        [DUPLICATE_PAGE_TEMPLATE_REQUEST_ACTION]: (state: PageTemplateSelectorState, aciton: AnyAction) => {
            const { payload: [templateId] } = aciton;
            return holdPageTemplateSelectorItem(templateId, state);
        },
        [DUPLICATE_PAGE_TEMPLATE_SUCCESS_ACTION]: (state: PageTemplateSelectorState, action: Record<string, any>) => {
            const
                { endpointParams: [templateId], payload: newTemplateRef } = action,
                { templates } = state;

            return {
                ...state,
                ...unholdPageTemplateSelectorItem(templateId, state),
                [Lit.templates]: sortPageTemplates([...templates, newTemplateRef]),
                [Lit.selectedId]: newTemplateRef.id,
            };
        },
        [DUPLICATE_PAGE_TEMPLATE_FAILURE_ACTION]: (state: PageTemplateSelectorState, action: Record<string, any>) => {
            const { endpointParams: [templateId] } = action;
            return unholdPageTemplateSelectorItem(templateId, state);
        },
        [DELETE_PAGE_TEMPLATE_REQUEST_ACTION]: (state: PageTemplateSelectorState, action: Record<string, any>) => {
            const
                { payload: [templateId] } = action,
                { templates } = state,
                theTemplate: DataPageTemplateRef | undefined = templates.find(t => t.id === templateId);

            if (!theTemplate) {
                throw new Error(`Unknown template: ${templateId}`);
            } else if (theTemplate.pages.length) {
                throw new Error(`Cannot delete template which has pages: ${templateId}`);
            }

            return holdPageTemplateSelectorItem(templateId, state);
        },
        [DELETE_PAGE_TEMPLATE_SUCCESS_ACTION]: (state: PageTemplateSelectorState, action: Record<string, any>) => {
            const
                { endpointParams: [templateId] } = action,
                { templates } = state,
                index = templates.findIndex(t => t.id === templateId),
                nextTemplates = [...templates];

            if (index === -1) {
                throw new Error(`Cannot find template to remove: ${templateId}`);
            }

            nextTemplates.splice(index, 1);

            return {
                ...unholdPageTemplateSelectorItem(templateId, state),
                templates: nextTemplates
            };
        },
        [DELETE_PAGE_TEMPLATE_FAILURE_ACTION]: (state: PageTemplateSelectorState, action: Record<string, any>) => {
            const { endpointParams: [templateId] } = action;
            return unholdPageTemplateSelectorItem(templateId, state);
        },
        [SHOW_PAGE_TEMPLATE_PREVIEW_ACTION]: makePayloadPathReducer(Path.previewId),
        [HIDE_PAGE_TEMPLATE_PREVIEW_ACTION]: makeValuePathReducer('', Path.previewId),
        [SELECT_PAGE_TEMPLATE_PREVIEW_ACTION]: (state: PageTemplateSelectorState, action: Record<string, any>) => ({
            ...state,
            [Lit.selectedId]: action.payload,
            [Lit.previewId]: ''
        })
    }
});
