import React from "react";
import getCenteredDialog from "../../../../DialogManager/getCenteredDialogConfig";
import { injectIntl, Intl } from "../../../../../view/intl/index";
import styles from './FacebookNoPagesDialog.css';
import { StripTypes } from "../../../../../view/common/dialogs/baseDialog/index";
import { DialogHeight, DialogWidth, DialogMessages } from "./constants";
import { closeDialog } from "../../../../App/actionCreators/index";
import { StandardDialog } from "../../../../../view/common/dialogs/StandardDialog/index";
import { facebookSdkLoginAction } from "../../facebookSdkActions";
import { FB_FEED_LOGIN } from '../../constants';

type Props = {
    dispatch: Dispatch,
    intl: Intl,
}

// eslint-disable-next-line max-len
class FacebookNoPagesDialog extends React.Component<Props> {
    render() {
        const { props: { dispatch, intl } } = this;
        return (
            <StandardDialog
                title={DialogMessages.TITLE}
                iconClass={styles.facebookIcon}
                autoFocus={false}
                stripType={StripTypes.NONE}
                mctaText={DialogMessages.RECONNECT_LABEL}
                sctaText={DialogMessages.CANCEL_LABEL}
                titleBoxClassName={styles.titleBoxClassName}
                sctaBtnClass={styles.cancelBtn}
                primaryBtnClass={styles.primaryBtnClass}
                mctaHandler={
                    () => {
                        dispatch(facebookSdkLoginAction(FB_FEED_LOGIN));
                        dispatch(closeDialog());
                    }
                }
                noTopBorderForFooter
                disabled={false}
                sctaHandler={null}
            >
                <div className={styles.message}>{intl.msgJoint(DialogMessages.MESSAGE)}</div>
            </StandardDialog>
        );
    }
}

export default getCenteredDialog({
    width: DialogWidth,
    height: DialogHeight,
    component: injectIntl(FacebookNoPagesDialog),
});
