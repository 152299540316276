import factory from './factoryHover';
import { getShiftBarTopSelectionLeft } from '../../../../utils/handle/shiftBar';
import * as HandleKinds from '../../../../utils/handle/kinds';
import type { BBox } from '../../../../redux/modules/children/workspace/flowTypes';

export default factory(
    {
        kind: HandleKinds.ShiftBarTopHover,
        getLeft: (bBox: BBox) => getShiftBarTopSelectionLeft(bBox)
    }
);
