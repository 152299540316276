// @ts-nocheck
export const MaxMobileWidth = 650,
    MobileViewOrientations = {
        LANDSCAPE: 'LANDSCAPE',
        PORTRAIT: 'PORTRAIT'
    },
    MobileViewDimensions = {
        portrait: {
            width: 339,
            height: 563
        },
        landscape: {
            width: 563,
            height: 339
        }
    },
    MobileContentPosition = {
        portrait: {
            left: 0,
            top: 32
        },
        landscape: {
            left: 32,
            top: 0
        }
    };
