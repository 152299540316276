import { put, takeLatest } from 'redux-saga/effects'; // eslint-disable-line node/file-extension-in-import
import { FC_INIT, _FC_SET_DEFAULT_STATE, FC_INITIALIZED } from "../actionTypes";
import defaultState from '../defaultState';
import putGen from "../../../../../utils/saga/putGen";
import { takeSuccessFromApiActionGen } from "../../../../../utils/saga/index";
import { FC_ONEWEBMEDIA_PATH } from "../constants";
import { NOT_FOUND_ERROR } from "../../../../../../dal/dalErrors";
import { fcCreateWebSpaceFolder, reloadResources } from "../actionCreators/index";
import ls from '../../../../../utils/localStorage';
import { Lit } from "../../../../../lit";
import { getDAL } from '../../../../../../dal/index';

export default function* (): Generator<any, void, void> {
    yield takeLatest(FC_INIT, function* (action: Action<any>) {
        const
            {
                contentTypes: inputContentTypes = defaultState.contentTypes,
                ...rest
            } = action.payload,
            preSelection = action.payload.preSelection || defaultState.selection,
            contentTypes = !Array.isArray(inputContentTypes) ? [inputContentTypes] : inputContentTypes,
            currentPath = preSelection && preSelection.length ? preSelection[0].basePath : defaultState.currentPath;

        yield* putGen(
            _FC_SET_DEFAULT_STATE,
            {
                state: {
                    ...rest,
                    isInitialized: true,
                    contentTypes,
                    selection: preSelection,
                    currentPath,
                    shutterstock: {
                        showPremiumDownloadsMsg: ls.get(Lit.showPremiumDownloadsMsg)
                    }
                }
            }
        );

        let loadOpts;
        if (getDAL().isRepositoryDomain() && currentPath === '/') {
            const glob = `?(${preSelection.map(r => r.href).join('|')})`;
            loadOpts = { glob };
        }

        yield* takeSuccessFromApiActionGen(reloadResources(currentPath, loadOpts), function* (payload) {
            if (currentPath.indexOf(FC_ONEWEBMEDIA_PATH) !== -1 && payload.error === NOT_FOUND_ERROR) {
                // onewebmedia folder doesn't exist -> try to create it
                const success = yield* takeSuccessFromApiActionGen(fcCreateWebSpaceFolder(FC_ONEWEBMEDIA_PATH));
                if (success) {
                    // reload resources
                    // @ts-ignore
                    yield put(reloadResources(currentPath));
                }
            }
        });
        yield* putGen(FC_INITIALIZED);
    });
}
