export const
    DialogWidth = 600,
    DialogHeight = 320,
    PopupWindowWidth = 973,
    PopupWindowHeight = 798,
    DialogMessages = {
        TITLE: 'msg: component.facebookChat.noPages {No page selected}',
        MESSAGE: 'msg: component.facebookChat.noPages.message {It looks like you didn\'t select a page when connecting to Facebook. Try again and make sure you choose a Facebook  Business page.}', // eslint-disable-line max-len
        RECONNECT_LABEL: 'msg: common.tryAgain {Try again}',
        CANCEL_LABEL: 'msg: common.cancel {Cancel}'
    };
