import React from 'react';
import parseComponents from '../parseComponents';
import ShareButtonsBase from './ShareButtonsBase';
import View from './index';

export default class ShareButtons extends ShareButtonsBase {
    componentDidUpdate() {
        parseComponents();
    }

    render() {
        return (<View {...this.props} />);
    }
}
