import React from "react";
import * as R from "ramda";
import cx from "classnames";
import ImageSliderComponentKind from "./ImageSlider/kind";
import GalleryComponentKind from "./Gallery/kind";
import GalleryTypePropsConfig from "./Gallery/galleryTypes";
import TypePropsConfig from "./ImageSlider/sliderTypes";
import styles from "./ImageSlider/propertiesPanel/pages/fullWidth.css";
import { injectIntl } from "../../view/intl/index";
import type { PageProps } from "../PropertiesPanel/flowTypes";
import type { ImageSliderComponent } from "./ImageSlider/flowTypes";
import Page from "../PropertiesPanel/view/PropertiesPage";
import VerticalSpacer from "../../view/common/VerticalSpacer";
import PropertyContainer from "../../view/common/PropertyContainer/index";
import ToggleSelectView from "../presentational/ToggleSelectView/index";
import Slider from "../../view/common/Slider/PPSlider";
import type { GalleryComponent } from "./Gallery/flowTypes";

export const
    updateComponentBaseOnStretch = (
        nextComponent: Record<string, any>,
        prevComponent: Record<string, any>,
        nextComponentDependencies: Record<string, any>,
        prevComponentDependencies: Record<string, any>
    ) => {
        let updatedNextComponent = {
            ...nextComponent
        };
        const changedToStretched = prevComponent && !prevComponent.stretch && nextComponent.stretch;
        if (changedToStretched && nextComponentDependencies) {
            updatedNextComponent = R.pipe(
                R.assocPath(['fullWidthOption', 'originalWidth'], nextComponent.width),
                R.assocPath(['fullWidthOption', 'originalLeft'], nextComponent.left),
                R.assocPath(['width'], nextComponentDependencies.workspaceBBoxWidth),
                R.assocPath(['left'], 0),
            )(updatedNextComponent);
        }
        const changedToNotStretched = prevComponent && prevComponent.stretch && !nextComponent.stretch;
        if (changedToNotStretched) {
            updatedNextComponent = R.pipe(
                R.assocPath(['width'], nextComponent.fullWidthOption.originalWidth || nextComponent.width),
                R.assocPath(['left'], R.isNil(nextComponent.fullWidthOption.originalLeft)
                    ? nextComponent.left : nextComponent.fullWidthOption.originalLeft),
                R.assocPath(['fullWidthOption', 'originalWidth'], 0),
                R.assocPath(['fullWidthOption', 'originalLeft'], null)
            )(updatedNextComponent);
        }
        const workspaceBBoxWidthChanged = prevComponentDependencies && nextComponentDependencies
            && prevComponentDependencies.workspaceBBoxWidth !== nextComponentDependencies.workspaceBBoxWidth;
        if (workspaceBBoxWidthChanged && nextComponent.stretch && nextComponentDependencies) {
            updatedNextComponent = R.assocPath(['width'], nextComponentDependencies.workspaceBBoxWidth)(updatedNextComponent);
        }
        return updatedNextComponent;
    },
    fullWidthCmpConfigMap = {
        [ImageSliderComponentKind]: TypePropsConfig,
        [GalleryComponentKind]: GalleryTypePropsConfig,
    },
    factoryFullWidthPP = (fullWidthAction: string, fullWidthMarginAction: string, fullWidthMaxWidthAction: string,
        dispatchWithCmpId: boolean = false) => {
        const
            fullWidthLabel = 'msg: common.pp.label.fullWidthOptions {Full width options}',
            fullWidthConfigs = [
                {
                    id: true,
                    data: {
                        title: 'msg: common.fullWidth {Full width}',
                        className: cx(styles.item, styles.fullWidth)
                    }
                },
                {
                    id: false,
                    data: {
                        title: 'msg: common.default {Default}',
                        className: cx(styles.item, styles.none)
                    }
                }
            ],
            FullWidthItemView = injectIntl(({ title, selected, className, intl }) => {
                return (
                    <div className={cx({ [styles.selected]: selected })}>
                        <div className={className} />
                        <div className={styles.label}>{intl.msgJoint(title)}</div>
                    </div>
                );
            }),
            FullWidthSettings = {
                margin: {
                    max: 20,
                    min: 0,
                    step: 1,
                    defaultValue: 0
                },
                maxWidth: {
                    max: 3000,
                    min: 1500,
                    step: 100,
                    defaultValue: 2000
                }
            },
            calcMinMaxWidth = (templateWidth) => {
                const remainder = (templateWidth % FullWidthSettings.maxWidth.step);
                if (remainder === 0) {
                    return templateWidth;
                }
                return ((templateWidth + FullWidthSettings.maxWidth.step) - remainder);
            },
            calcMaxWidth = (currentMaxWidth, templateWidth) => {
                if (currentMaxWidth && templateWidth && currentMaxWidth < templateWidth) {
                    return templateWidth;
                }
                return currentMaxWidth;
            };

        return (
            {
                selectedComponent: { stretch, fullWidthOption, id: cmpId }, dispatch, templateWidth
            }: PageProps<ImageSliderComponent|GalleryComponent>
        ) => {
            const minMaxWidth = calcMinMaxWidth(templateWidth) || FullWidthSettings.maxWidth.min,
                maxWidth = calcMaxWidth(fullWidthOption.maxWidth, minMaxWidth) || FullWidthSettings.maxWidth.defaultValue,
                disabled = templateWidth > FullWidthSettings.maxWidth.max;
            return (
                <Page>
                    <VerticalSpacer />
                    <PropertyContainer label={fullWidthLabel}>
                        <ToggleSelectView
                            onToggle={config => {
                                if (dispatchWithCmpId) {
                                    dispatch({ type: fullWidthAction, payload: { id: cmpId } });
                                    return;
                                }
                                if (stretch !== config.id) {
                                    dispatch({ type: fullWidthAction, payload: config.id });
                                }
                            }}
                            configs={fullWidthConfigs}
                            toggleItemTemplate={FullWidthItemView}
                            selectedId={stretch}
                            className={styles.itemContainer}
                        />
                    </PropertyContainer>
                    <VerticalSpacer />
                    {
                        stretch &&
                        <div>
                            <Slider
                                label="msg: imageSlider.ppFullWidth.margin {Margin:}"
                                min={FullWidthSettings.margin.min}
                                max={FullWidthSettings.margin.max}
                                step={FullWidthSettings.margin.step}
                                value={fullWidthOption.margin || FullWidthSettings.margin.defaultValue}
                                valueLabel={`${fullWidthOption.margin || 0}%`}
                                onChange={fullWidthMargin =>
                                    dispatch({ type: fullWidthMarginAction, payload: fullWidthMargin, amendToSelf: true })}
                            />
                            <VerticalSpacer />
                            <Slider
                                label="msg: imageSlider.ppFullWidth.maxWidth {Max width:}"
                                min={minMaxWidth}
                                max={FullWidthSettings.maxWidth.max}
                                step={FullWidthSettings.maxWidth.step}
                                value={maxWidth}
                                disabled={disabled}
                                valueLabel={`${disabled ? templateWidth : maxWidth}px`}
                                onChange={fullWidthMaxWidth =>
                                    dispatch({ type: fullWidthMaxWidthAction, payload: fullWidthMaxWidth, amendToSelf: true })}
                            />
                        </div>
                    }
                </Page>
            );
        };
    };
