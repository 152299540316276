import Rbush from 'rbush';
import type { AnyComponent, ComponentsMap } from "../../../../redux/modules/children/workspace/flowTypes";
import memo from "../../../../../utils/memo";
import isStretchComponentKind from "../../../oneweb/isStretchComponentKind";
import { getComponentZIndex } from "../../zIndex";
import { getNonGhostCmps } from "../componentAttachements/util";

export type RBushTreeCmp = {
    minX: number,
    minY: number,
    maxX: number,
    maxY: number,
    id: string,
    kind: string,
    orderIndex: number,
    inTemplate: boolean,
    isStickyToHeader: boolean,
};

export const
    tree = new Rbush(),

    getEmptyTree = () => {
        tree.clear();
        return tree;
    },

    clearAndFillTree = memo((tree, items) => {
        tree.clear();
        tree.load(items);
    }),

    prepareItem = (cmp: AnyComponent, minLeft: number, maxRight: number) => {
        const { id, left, top, height, width, kind, inTemplate, onHover, stretch = false } = cmp,
            orderIndex = getComponentZIndex(cmp);
        return {
            minX: isStretchComponentKind(kind, stretch) ? minLeft : left,
            minY: top,
            maxX: isStretchComponentKind(kind, stretch) ? maxRight : (left + width),
            maxY: top + height,
            id,
            kind,
            orderIndex,
            inTemplate,
            onHover,
            isStickyToHeader: !!cmp.isStickyToHeader
        };
    },

    prepareCmpsForTree = (
        components: Array<AnyComponent>,
        minLeft: number,
        maxRight: number
    ): Array<RBushTreeCmp> => components.map(cmp => prepareItem(cmp, minLeft, maxRight)),

    getCmpsTreeByCmpsMap = (componentsMap: ComponentsMap, minLeft: number, maxRight: number) => {
        const cmps = getNonGhostCmps(componentsMap),
            cmpsData = prepareCmpsForTree(cmps, minLeft, maxRight);
        let tree = getEmptyTree();
        clearAndFillTree(tree, cmpsData);
        return { cmps, tree, cmpsData };
    };
