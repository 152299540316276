import * as R from 'ramda';
import { MIN_WIDTH, MIN_HEIGHT } from './constants';
import type {
    ShareButtonsComponent,
    ShareButtonsComponentExtension,
    ShareButtonsComponentDependsOn
} from './flowTypes';
import type { AdjustmentHook } from '../../Workspace/epics/componentsEval/flowTypes';

// @ts-ignore
type ShareButtonsHook = AdjustmentHook<ShareButtonsComponent, ShareButtonsComponentDependsOn, ShareButtonsComponentExtension, void> // eslint-disable-line max-len

const
    hook: ShareButtonsHook = ({ isTransient, component }) => [
        R.evolve({ width: R.max(MIN_WIDTH), height: R.max(MIN_HEIGHT) }, component),
        { minDimensions: { width: MIN_WIDTH, height: MIN_HEIGHT }, isTransient }
    ],
    hookConfig = {
        hook,
        shouldCallHook: () => true
    };

export default hookConfig;
