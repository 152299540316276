import React from "react";
import WarningDialog from '../../../../../view/common/dialogs/WarningDialog/index';
import makeAppStandardErrorDialog from "../../../../App/dialogs/makeAppStandardErrorDialog";
import injectIntl from "../../../../../view/intl/injectIntl";
import { MissingEmailWarningSettings } from "../../constants";
import { CONTACT_FORM_HANDLE_MISSING_EMAIL } from "../../actionTypes";

export const MissingEmailWarningDialog = injectIntl(({ userEmail, dispatch, intl }) => {
    return (
        <WarningDialog
            title={MissingEmailWarningSettings.title}
            mctaText={MissingEmailWarningSettings.mctaText}
            mctaHandler={() => dispatch({ type: CONTACT_FORM_HANDLE_MISSING_EMAIL })}
        >
            <div>
                <span>{intl.msgJoint(MissingEmailWarningSettings.emailText)}</span>
                <b>{' ' + userEmail + ' '}</b>
                <span>{intl.msgJoint(MissingEmailWarningSettings.missingEmailInfo)}</span>
            </div>
            <div style={{ marginTop: 10 }}>
                <span>{intl.msgJoint(MissingEmailWarningSettings.description)}</span>
            </div>
        </WarningDialog>
    );
});

export default makeAppStandardErrorDialog(MissingEmailWarningDialog);
