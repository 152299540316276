type OpenOptions = {
    url?: string,
    windowName?: string,
    windowFeatures?: Record<string, any> | null,
    center?: boolean,
};

type CbFn = (win: WindowProxy) => void;

type Params = {
    openOptions?: OpenOptions,
    onOpen?: CbFn,
    onClose?: CbFn,
};

type Subscriber = (event: MessageEvent) => void;

type CommunicationWindow = {
    win: WindowProxy,
    subscribe: (sub: Subscriber) => () => void,
};

const DefaultOpenOptions: OpenOptions = {
    url: '',
    windowName: '',
    windowFeatures: null,
    center: false,
};

const makeWindowFeaturesStr = (openOptions: OpenOptions): string => {
    if (!openOptions.windowFeatures) return '';

    const features = openOptions.windowFeatures;

    // center
    if (openOptions.center && features.width) {
        const left = (window.innerWidth - features.width) / 2;
        if (left > 0) {
            features.left = left;
        }
    }

    if (openOptions.center && features.height) {
        const top = (window.innerHeight - features.height) / 2;
        if (top > 0) {
            features.top = top;
        }
    }

    return Object.keys(features).map(k => `${k}=${features[k]}`).join(',');
};

export const openCommunicationWindow = ({
    onOpen,
    onClose,
    openOptions: inOpenOptions = {},
}: Params): CommunicationWindow => {
    const openOptions = { ...DefaultOpenOptions, ...inOpenOptions };

    // open popup win
    const win = window.open(
        openOptions.url,
        openOptions.windowName,
        makeWindowFeaturesStr(openOptions),
    );
    if (!win) {
        throw new Error('Could not open communication window');
    }

    // init popup win
    if (onOpen) {
        onOpen(win);
    }

    // listen to communication message
    const targetOrigin = (window.origin || window.location.origin);

    let subscribers: Array<Subscriber> = [];

    window.addEventListener('message', (event: MessageEvent) => {
        if (event.origin !== targetOrigin) return;

        subscribers.forEach(sub => {
            sub(event);
        });
    }, false);

    if (onClose) {
        let id = setInterval(() => {
            if (win.closed) {
                clearInterval(id);
                subscribers = [];
                if (onClose) onClose(win);
            }
        }, 1000);
    }

    return {
        win,
        subscribe(sub: Subscriber) {
            subscribers.push(sub);
            return () => {
                // @ts-ignore
                const index = subscribers.findIndex(sub);
                subscribers.splice(index, 1);
            };
        }
    };
};
