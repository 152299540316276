/* eslint-disable import/no-named-as-default-member */

import React from "react";
import { path } from 'ramda';
import { Editor } from '@tinymce/tinymce-react';
import { $ } from 'tinymce';
import 'tinymce/themes/modern'; // eslint-disable-line node/file-extension-in-import
import 'tinymce/plugins/link'; // eslint-disable-line node/file-extension-in-import
import 'tinymce/plugins/image'; // eslint-disable-line node/file-extension-in-import
import 'tinymce/plugins/table'; // eslint-disable-line node/file-extension-in-import
import 'tinymce/plugins/advlist'; // eslint-disable-line node/file-extension-in-import
import 'tinymce/plugins/textcolor'; // eslint-disable-line node/file-extension-in-import
import 'tinymce/plugins/colorpicker'; // eslint-disable-line node/file-extension-in-import
import 'tinymce/plugins/lists'; // eslint-disable-line node/file-extension-in-import
import 'tinymce/plugins/paste/index'; // eslint-disable-line node/file-extension-in-import
import 'tinymce/plugins/nonbreaking'; // eslint-disable-line node/file-extension-in-import
import 'tinymce/plugins/noneditable/index'; // eslint-disable-line node/file-extension-in-import
import { getEditorConfig } from "../editorSetup";
import { getEditorContent, getEditorTextContent } from "../../../App/epics/tinyMceEpic/editorUtils/methods/getters/getEditorContent";
import { rafThrottle } from "../../../../../utils/rafThrottle";
import { isIE11, isSafari } from '../../../../../utils/browser';
import { PickLinkedValueCtxMenu } from './PickLinkedValueCtxMenu';
import makeUuid from "../../../../../utils/makeUuid";
import { pickLinkedFieldTriggerChar } from "../../../App/epics/tinyMceEpic/tinyMcePickLinkedFieldEpic";
import { SelectedLinkedValueToolbar } from './SelectedLinkedValueToolbar';
import { getDAL } from '../../../../../dal/index';
import { validateCharacterLimit, getRemainingCharacterCount, WARNING, EXCEEDED } from "./validateTextLimit";
import { textLimitWarningEnum } from "../../../App/epics/textLimitWarning/types";

const
    getSpacing = path(['component', 'characterSpacing']);

    type TextInEditModeProps = {
        measureRef?: string;
        textChanged: (newText: string) => any;
        onEnterKeyDown: (arg0: {
            preventDefault: (...args: Array<any>) => any;
        }) => any;
        onHashKeyEntered?: (arg0: {
            positionRightBelowHash: {
                left: number;
                top: number;
            };
            hashSpanNodeId: string;
        }) => any;
        onUnmount: () => any;
        onTextSelectionChanged: (e: any, isMouseDown: boolean) => any;
        onTextLimitExceeded: () => any;
        onTextLimitWarning: (payload: {
            showWarning?: boolean,
            remainingCharacters?: number,
            warningType?: textLimitWarningEnum,
        }) => any;
        onEditorInit: () => any;
        content: string;
        height: number;
        className?: string;
        style?: Record<string, any>;
        onUndo: (...args: Array<any>) => any;
        onRedo: (...args: Array<any>) => any;
        onInputChange: (...args: Array<any>) => any;
        onCtrlShiftA: (...args: Array<any>) => any;
        onCtrlShiftE: (...args: Array<any>) => any;
        onCtrlShiftP: (...args: Array<any>) => any;
        onCtrlShiftW: (...args: Array<any>) => any;
    };

function preventDefault(e) {
    const eventInsideTinyMce = $(e.srcElement).closest('.mce-content-body').length;
    if (!eventInsideTinyMce && e.srcElement.nodeName !== "INPUT") {
        e.preventDefault();
    }
}

const isIE11orSafari = () => isIE11() || isSafari();

// WBTGEN-24671: added for prevent validation for text limit on keypres
const textLimitPreventedKeyCodes = [16, 17, 18, 20, 91, 92, 93, 224];
export default class TextInEditMode extends React.Component<TextInEditModeProps> {
    onTextSelectionChanged: (e: any) => any;
    onTextChanged: (string) => any;

    constructor(props: TextInEditModeProps) {
        super(props);
        this.onTextSelectionChanged = rafThrottle((e) => {
            this.props.onTextSelectionChanged(e, true);
        });
        this.onTextChanged = rafThrottle(this.props.textChanged);
        this.validateCharacterOnPaste = this.validateCharacterOnPaste.bind(this);
        this.validateCharacterOnKeyPress = this.validateCharacterOnKeyPress.bind(this);
        this.updateTextLimitWarning = this.updateTextLimitWarning.bind(this);
    }

    updateTextLimitWarning = (ed) => {
        const text = getEditorTextContent(ed);
        const characterLimitCondition = validateCharacterLimit(text);
        let remainingCharacterCount = getRemainingCharacterCount(text);
        let showWarning = false;

        if (
            characterLimitCondition === WARNING ||
            characterLimitCondition === EXCEEDED
        ) {
            showWarning = true;
        }

        this.props.onTextLimitWarning({
            showWarning,
            remainingCharacters: remainingCharacterCount,
        });
    }

    validateCharacterOnPaste = (text, e) => {
        let characterLimitCondition = validateCharacterLimit(text);
        if (characterLimitCondition === EXCEEDED) {
            e.preventDefault();
            this.props.onTextLimitWarning({
                warningType: textLimitWarningEnum.ON_PASTE,
            });
            this.props.onTextLimitExceeded();
        }
    }

    validateCharacterOnKeyPress = (text, e) => {
        const characterLimitCondition = validateCharacterLimit(text);
        if (characterLimitCondition === EXCEEDED && e.keyCode !== 8) {
            e.preventDefault();
            this.props.onTextLimitWarning({
                warningType: textLimitWarningEnum.ON_TYPE,
            });
            this.props.onTextLimitExceeded();
            return;
        }
    }

    shouldComponentUpdate(nextProps): boolean {
        return getSpacing(nextProps) !== getSpacing(this.props) ||
            nextProps.style !== this.props.style;
    }

    componentDidMount() {
        if (isIE11orSafari()) {
            window.document.addEventListener('mousedown', preventDefault);
        }
    }

    componentWillUnmount() {
        this.props.onUnmount();
        if (isIE11orSafari()) {
            window.document.removeEventListener('mousedown', preventDefault);
        }
    }

    render() {
        const {
            height,
            className,
            style,
            content,
            measureRef,
            onUndo,
            onRedo,
            onInputChange,
            onCtrlShiftA,
            onCtrlShiftE,
            onCtrlShiftP,
            onCtrlShiftW,
            onEnterKeyDown,
        } = this.props;
        const renderConfig = {
            ...getEditorConfig({ onUndo, onRedo, onCtrlShiftA, onCtrlShiftE, onCtrlShiftP, onCtrlShiftW }),
            height,
            setup: (ed) => {
                ed.on('init', () => {
                    this.updateTextLimitWarning(ed);
                });
                ed.on('SelectionChange', this.onTextSelectionChanged);
                ed.on('MouseUp', (e) => {
                    this.onTextSelectionChanged(e);
                });
                ed.on('keydown', (e) => {
                    const text = getEditorTextContent(ed);
                    if (e.keyCode === 13) {
                        onEnterKeyDown({ preventDefault: () => e.preventDefault() });
                    }

                    if (text && !textLimitPreventedKeyCodes.includes(e.keyCode) && !(e.ctrlKey || e.metaKey)) {
                        this.validateCharacterOnKeyPress(text, e);
                    }
                });
                ed.on('paste', (e) => {
                    const clipboardData = e.clipboardData || window.clipboardData;
                    let text = getEditorTextContent(ed);
                    // WBTGEN-24671: \n%MCEPASTEBIN% is added in the tiny mce editor content while copyng the content from editor
                    text = text.replace('\n%MCEPASTEBIN%', '');
                    text += clipboardData.getData('text');
                    this.validateCharacterOnPaste(text, e);
                });
                ed.on('change keyup', (e) => {
                    const selection = document.getSelection();
                    let canUseLinkedValues = getDAL().isRepositoryDomain(); // TODO: (WBTGEN-11968) remove when linked-values are to be made available to all
                    if (canUseLinkedValues && selection && this.props.onHashKeyEntered) {
                        const { onHashKeyEntered } = this.props;
                        const { focusNode, focusOffset } = selection;
                        if (e.type === 'keyup') {
                            if (e.key === pickLinkedFieldTriggerChar) {
                                if (focusNode && focusNode.nodeName === '#text') {
                                    // @ts-ignore
                                    focusNode.splitText(focusOffset);
                                    // @ts-ignore
                                    focusNode.splitText(focusOffset - 1);
                                    const { nextSibling, parentNode } = focusNode;
                                    if (!parentNode || !nextSibling) return;
                                    const hashSpan = document.createElement('span');
                                    hashSpan.innerText = pickLinkedFieldTriggerChar;
                                    const hashTextNode = nextSibling;
                                    parentNode.insertBefore(hashSpan, nextSibling);
                                    parentNode.removeChild(hashTextNode);
                                    const uuid = makeUuid();
                                    hashSpan.setAttribute('id', uuid);
                                    hashSpan.setAttribute('style', 'outline: 1px solid lightblue;');
                                    const { right, bottom } = hashSpan.getBoundingClientRect();
                                    onHashKeyEntered({
                                        positionRightBelowHash: { left: right, top: bottom },
                                        hashSpanNodeId: uuid
                                    });
                                }
                            }
                        }
                    }
                    this.onTextChanged(getEditorContent(ed));
                    this.updateTextLimitWarning(ed);
                });
                ed.on('input', onInputChange);
            }
        };

        return (
            // This extra div is necessary for IE11
            // in IE11 the div with measureRef prefers parent height which will be 100% if this div is not there
            <div>
                <div
                    className={className}
                    style={style}
                    ref={measureRef}
                    spellCheck
                >
                    <Editor
                        value={content}
                        init={renderConfig}
                        onInit={this.props.onEditorInit}
                    />
                </div>
                <PickLinkedValueCtxMenu />
                <SelectedLinkedValueToolbar />
            </div>
        );
    }
}
