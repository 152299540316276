import { getCmpVerticalCenter } from "../MobileViewEditor/util";
import type { Group } from "../MobileViewEditor/flowTypes";
import type { AnyComponent } from "../../redux/modules/children/workspace/flowTypes";
import { getMobileSettingScale } from "./Image/utils";

const defaultMargin = 5;

type GroupCmpPositionStylesForMobile = {
    mobileStyles: Record<string, any>,
    mobileStylesStr: string
};

export const getGroupCmpPositionStylesForMobile = (
    group: Group,
    component: AnyComponent,
    mobileSetting: Record<string, any> = {}
): GroupCmpPositionStylesForMobile => {
    let result: GroupCmpPositionStylesForMobile = { mobileStyles: {}, mobileStylesStr: '' };
    if (!group) {
        return result;
    }
    const
        //do not use the component coming from input as it has relative top
        cmp = group.find(cmp => cmp.id === component.id),
        otherCmp = group.find(cmp => cmp.id !== component.id);
    if (!cmp || !otherCmp) {
        return result;
    }
    const
        otherCmpMid = getCmpVerticalCenter(otherCmp),
        cmpMid = getCmpVerticalCenter(cmp);

    if (cmp.width < otherCmp.width) {
        const scale = getMobileSettingScale(mobileSetting);
        result.mobileStyles = { minWidth: cmp.width * scale };
        result.mobileStylesStr = `min-width: ${cmp.width * scale}px;\n`;
    }
    if (Math.abs(otherCmpMid - cmpMid) > 8 && otherCmp.height >= (cmp.height * 1.5)) {
        const toWardsTop = cmpMid < otherCmpMid;
        result.mobileStyles = {
            ...result.mobileStyles,
            alignSelf: toWardsTop ? 'flex-start' : 'flex-end',
            marginTop: toWardsTop ? (cmp.top - otherCmp.top + defaultMargin) : defaultMargin,
            marginBottom: !toWardsTop ?
                ((otherCmp.top + otherCmp.height) - (cmp.top + cmp.height) + defaultMargin) :
                defaultMargin
        };
        result.mobileStylesStr += `align-self: ${result.mobileStyles.alignSelf} !important;
                            margin-top: ${result.mobileStyles.marginTop}px !important;
                            margin-bottom: ${result.mobileStyles.marginBottom}px !important;`;
    }
    return result;
};
