import React from "react";
import cx from "classnames";
import { injectIntl } from "../../../view/intl";
import type { MsgJointInput, Intl } from "../../../view/intl";
import {
    Dialog,
    DialogBody,
    DialogFooterBare
} from "../../../view/common/dialogs/baseDialog";
import styles from "./UnsavedChangesDialogCom.css";
import { StripTypes } from "../../../view/common/dialogs/baseDialog/Dialog";
import {
    PrimaryButton,
    SecondaryButton
} from "../../../view/common/Button";

type Props = {
    msg: MsgJointInput,
    onSaveClick: React.MouseEventHandler,
    onDontSaveClick: () => void,
    onCancelClick: () => void,
    intl: Intl,
    isSaveDisabled?: boolean,
};

export const UnsavedChangesDialogCom = injectIntl((
    {
        msg,
        onSaveClick,
        onDontSaveClick,
        onCancelClick,
        isSaveDisabled,
        intl
    }: Props
) => {
    return (
        <Dialog
            stripType={StripTypes.NONE}
            closeBtnClassName={styles.closeButton}
            className={cx(styles.dialogMain)}
            onClose={onCancelClick}
        >
            <DialogBody className={cx(styles.dialogBody)}>
                <div className={styles.title}>{intl.msgJoint('msg: pageChangesDialog.title {Unsaved Changes}')}</div>
                <div className={styles.description}>
                    {intl.msgJoint(msg)}
                </div>
            </DialogBody>
            <DialogFooterBare className={styles.dialogFooter}>
                <SecondaryButton
                    onClick={onCancelClick}
                    className={styles.button}
                >
                    {intl.msgJoint('msg: common.cancel {Cancel}')}
                </SecondaryButton>
                <SecondaryButton
                    onClick={onDontSaveClick}
                    className={cx(styles.button, styles.styledButton)}
                >
                    {intl.msgJoint('msg: common.doNotSave {Don’t save}')}
                </SecondaryButton>
                <PrimaryButton
                    onClick={onSaveClick}
                    autoFocus
                    disabled={isSaveDisabled}
                    className={styles.button}
                >
                    {intl.msgJoint('msg: common.save {Save}')}
                </PrimaryButton>
            </DialogFooterBare>
        </Dialog>
    );
});
