import React from 'react';

import VerticalSpacer from "../../../../../../../view/common/VerticalSpacer";

import { useIntl } from '../../../../../../../view/intl';

import styles from './styles.css';

export const ErrorMessage = (props) => {
    const {
        iconClass,
        content,
        subContent
    } = props;

    const intl = useIntl();

    return (
        <div className={styles.errorMessageContainer}>
            <div className={iconClass} />
            <VerticalSpacer y={8} />
            <div className={styles.errorMessageContent}>
                {intl.msgJoint(content)}
            </div>
            <div className={styles.errorMessageSubContent}>
                {intl.msgJoint(subContent)}
            </div>
        </div>
    );
};
