import type { AppStore } from "../../../redux/modules/flowTypes";
import { FONT_SELECTED } from "./actionTypes";
import { closeDialog } from "../../App/actionCreators/index";
import { fontSelectedAction } from "./actions";

export const fontFamilyMiddleware = (store: AppStore) => (next: Dispatch) => (action: AnyAction) => {
    if (action.type === FONT_SELECTED) {
        const { payload: { onChangeAction, fontFamily }, forwardTo } = action;
        store.dispatch(fontSelectedAction(onChangeAction, fontFamily, forwardTo));
        store.dispatch(closeDialog());
    }
    return next(action);
};
