/* eslint-disable max-len */

import React from 'react';
import cx from 'classnames';
import { SortableElement } from 'react-sortable-hoc';
import { ComponentMaskBackground } from '../presentational/ComponentMask/constants';
import ComponentMask from '../presentational/ComponentMask/index';
import Image from '../oneweb/Image/view/Image';
import { getImageNameFromUrl } from "./utils";
import styles from './SortImageDialog.css';
import imgStyles from '../oneweb/Image/view/Image.css';
import { injectIntl } from "../../view/intl/index";

export default injectIntl(SortableElement<any>( // eslint-disable-line new-cap
    ({ imageAttributes, imageAvailable, imageIdx, containerCss, onEditIconClick, onDeleteIconClick, isImageEditorAllowed, intl }) => (
        <li className={containerCss}>
            <ComponentMask
                forceShowMask={false}
                maskStyle={ComponentMaskBackground.DARK}
                previewOnlyLayerContent={[
                    (<div key="1" className={styles.componentMask}>
                        <span className={styles.hoverImageName}>{getImageNameFromUrl(imageAttributes.src)}</span>
                        <div className={styles.cellOverlayActions}>
                            <div
                                className={styles.deleteImageIcon}
                                data-title={intl.msgJoint("msg: common.delete {Delete}")}
                                onClick={onDeleteIconClick}
                            />
                            {isImageEditorAllowed &&
                                <div
                                    className={styles.editImageIcon}
                                    data-title={intl.msgJoint("msg: common.edit {Edit}")}
                                    onClick={onEditIconClick}
                                />}
                        </div>
                    </div>
                    )
                ]}
            >
                <Image
                    imageAvailable={imageAvailable}
                    imageAttributes={imageAttributes}
                    divClassName={cx(
                        imgStyles.imageComponent,
                        styles.imageComponent,
                        { [`${imgStyles.imgNotFound}`]: !imageAvailable }
                    )}
                    divStyle={{ width: styles.THUMB_WIDTH, height: styles.THUMB_HEIGHT }}
                    width={styles.THUMB_WIDTH}
                    height={styles.THUMB_HEIGHT}
                    isWorkspace
                    isSortableItem
                />
                <div className={styles.imageIndex}>{imageIdx + 1}</div>
            </ComponentMask>
        </li>
    )
));
