import React from "react";

import isTestEnv from "../../../../../../wbtgen/src/debug/isTestEnv";

import type { State } from "./types";
import { ErrorMessage, ErrorMessageProps } from "./ErrorMessage";

type ErrorBoundaryProps = ErrorMessageProps & {
    invisible?: boolean;
    children: React.ReactNode;
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error: any) {
        if (!isTestEnv()) {
            window.TraceKit.report(error);
        }
    }

    render() {
        if (this.state.hasError) {
            if (this.props.invisible) {
                return null;
            }

            return (
                <ErrorMessage
                    icon={this.props.icon}
                    reloadButton={this.props.reloadButton}
                    inline={this.props.inline}
                    classes={this.props.classes}
                />
            );
        }

        return this.props.children;
    }
}
