import React from "react";
import { useDispatch } from "react-redux";
import cx from "classnames";
import { Button, DialogContentText, DialogContent, DialogActions } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import { useIntl } from "../../../../../../wbtgen/src/view/intl";

import { closeDialogAction } from "../../Dialog/actions";
import { BasicDialog } from "../../Dialog/BasicDialog";
import { changeEnableSeoAction } from "../../SiteSettings/actionCreators";

import imageStyles from '../../Images/imageStyle.css';

const useStyles = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.down('sm')]: {
            position: "absolute",
            bottom: 0,
            maxWidth: "100%",
            margin: 0,
        },
    },
    container: {
        maxWidth: "700px",
        [theme.breakpoints.up('sm')]: {
            padding: "48px"
        }
    },
    dialogContent: {
        display: "flex",
        flexDirection: "column",
        margin: "0px",
        textAlign: "left",
        alignItems: "flex-start"
    },
    title: {
        fontSize: "24px",
        lineHeight: "30px",
        margin: "16px 0px 0px",
    },
    description: {
        fontSize: "14px",
        lineHeight: "22px",
        marginBottom: "0px",
        margin: "16px 0px 0px",
    },
    dialogActions: {
        display: "flex",
        justifyContent: "center",
        marginTop: "24px",
        flexDirection: "column",
        gap: "10px",
        [theme.breakpoints.up('sm')]: {
            justifyContent: "flex-start",
            flexDirection: "row",
        },
    },
    closeButtonStyleClass: {
        padding: "20px",
        "& svg": {
            width: "24px",
            height: "24px"
        },
        [theme.breakpoints.up('sm')]: {
            padding: "18px",
            "& svg": {
                width: "22px",
                height: "22px"
            },
        },
    },
    actionBtn: {
        fontSize: "14px",
        lineHeight: "40px",
        fontWeight: 600,
        padding: "0px 24px",
        marginLeft: "8px !important",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: "0px",
            margin: "0px !important"
        },
    },
    turnOffBtn: {
        color: "white",
        backgroundColor: theme.palette.custom.colorBloodOrange2,
        border: `1px solid ${theme.palette.custom.colorBloodOrange2}`,
        "&:hover": {
            border: `1px solid ${theme.palette.custom.colorSpanishRed}`,
            backgroundColor: theme.palette.custom.colorSpanishRed
        }
    },
    cancelBtn: {
        border: `1px solid ${theme.palette.custom.colorGray_bb}`,
        "&:hover": {
            border: `1px solid ${theme.palette.custom.colorGray_3c}`,
            backgroundColor: "white"
        }
    }
}), { name: "HideWebsiteWarningDialog" });

type HideWebsiteWarningDialogProps = {
    config: any;
};

export const HideWebsiteWarningDialog = ({ config }: HideWebsiteWarningDialogProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleClose = () => {
        dispatch(closeDialogAction());
    };

    return (
        <BasicDialog
            onClose={() => {
                handleClose();
            }}
            classes={{ paper: classes.paper }}
            containerClass={classes.container}
            closeButtonStyleClass={classes.closeButtonStyleClass}
            maxWidth="md"
            {...config}
        >
            <DialogContent className={classes.dialogContent}>
                <div className={imageStyles.hideWebsite} />

                <DialogContentText className={classes.title}>
                    {intl.msgJoint("msg: dashboard.hideWebsiteWarningDialog.title {Are you sure you want to hide your website?}")}
                </DialogContentText>

                <DialogContentText className={classes.description} component="div">
                    {intl.msgJoint("msg: dashboard.hideWebsiteWarningDialog.description {Turning this off means your site won’t appear in search engines, so your audience will not find you. You also won’t be able to use our SEO Scan tool to improve your visibility.}")}
                </DialogContentText>
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
                <Button
                    onClick={handleClose}
                    className={cx(classes.actionBtn, classes.cancelBtn)}
                >
                    {intl.msgJoint("msg: common.cancel {Cancel}")}
                </Button>
                <Button
                    className={cx(classes.actionBtn, classes.turnOffBtn)}
                    onClick={() => {
                        dispatch(changeEnableSeoAction(false));
                        handleClose();
                    }}
                >
                    {intl.msgJoint("msg: preview.mobileOption.turnOff {Turn off}")}
                </Button>
            </DialogActions>
        </BasicDialog>
    );
};

export const HIDE_WEBSITE_WARNING_DIALOG_ID = 'HIDE_WEBSITE_WARNING_DIALOG_ID';
