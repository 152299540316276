import { getSelectedNode } from "../../utils/nodeUtils/getSelectedNode";

import type { TinyMceEditor } from "../../../flowTypes";

export const addClass = (editor: TinyMceEditor, className: string | null | undefined, node: HTMLElement | null | undefined) => {
    const selectedNode = node || getSelectedNode(editor);
    if (selectedNode) {
        editor.dom.addClass(selectedNode, className);
    }
};
