import * as React from 'react';
import previewConfig from "./previewConfig";
import getImageUrl from "./getImageUrl/index";
import type { ImageComponent } from "./flowTypes";
import { getScaledStyles, getScaledStylesForContainers } from "../../../utils/getScaledStyles";
import type { CmpSpecificStyles } from "../../Preview/flowTypes";
import { encodeUrl } from "../../../../utils/encoding";
import { getAssetUrl } from "../../../utils/assetUtils";
import { LOGO_KIND } from "../Logo/kind";
import Icons from "../../../view/Icons/index";
import { GroupTypes } from "../../MobileViewEditor/constants";
import { getGroupCmpPositionStylesForMobile } from "../getGroupCmpPositionStylesForMobile";
import { getMobileSettingScale } from "./utils";
import type { ExtendCmpPropsForGroupItemProps } from "../../MobileViewEditor/flowTypes";

export const imageMobileEditorCalcProps = (props: any): any => {
    let { component } = props;

    if (props.isLogo && props.globalVariables.logoAsset) {
        component = { ...component, asset: props.globalVariables.logoAsset };
    }

    const
        { renderedWidth, hasChildren } = props,
        { width, height } = component;

    let calculatedProps = previewConfig.calcProps(props);
    if (renderedWidth && hasChildren) {
        // WBTGEN-7742: Subpixel rendering issue caused by floating values for image height in webkit browsers.
        // Rendering image with floating points will have bad effects on components below it.
        calculatedProps.divStyle.minHeight = Math.round(renderedWidth * (height / width));
    }

    if (!props.isLogo && calculatedProps.imageAttributes && calculatedProps.imageAttributes.src) {
        const { src, srcSet } = getImageUrl(component, false, true);

        calculatedProps.imageAttributes.src = src;
        calculatedProps.imageAttributes.srcSet = srcSet;
    }

    return calculatedProps;
};

export default {
    ...previewConfig,
    getHiddenElementData: (props: ImageComponent, componentDependencies: Record<string, any>) => {
        const
            { kind, asset } = props,
            isLogo = kind === LOGO_KIND,
            globalVariables = componentDependencies.globalVariables;

        let imgSrc = '';

        if (isLogo && globalVariables) {
            const {
                logoAsset,
                websiteTitle
            } = globalVariables;
            if (logoAsset) {
                imgSrc = encodeUrl(getAssetUrl(logoAsset));
            } else {
                return {
                    icon: <Icons.LOGO_INSERTER />,
                    content: websiteTitle
                };
            }
        } else {
            imgSrc = encodeUrl(getAssetUrl(asset));
        }
        const icon = <div style={{ backgroundImage: `url(${imgSrc})` }} />;
        return { icon };
    },
    calcProps: imageMobileEditorCalcProps,
    getSpecificStyles: ({ component, hasChildren }: CmpSpecificStyles<ImageComponent>): string => {
        if (hasChildren) {
            return getScaledStylesForContainers(component);
        }
        return getScaledStyles(component);
    },
    extendCmpPropsForGroupItem: ({
        componentProps,
        settings,
        actualId,
        groupType,
        group
    }: ExtendCmpPropsForGroupItemProps) => {
        const
            { width: inpWidth, height: inpHeight, divStyle, component, imageAttributes } = componentProps,
            scale = getMobileSettingScale(settings[actualId]);
        let
            width = inpWidth * scale,
            height = inpHeight * scale;

        if (groupType === GroupTypes.imageAndText) {
            width = Math.round(width);
            height = Math.round(height);
        }

        const cmpProps = {
            ...componentProps,
            width,
            height,
            divStyle: {
                ...divStyle,
                width,
                height
            },
            imageAttributes: {
                ...imageAttributes,
                width: imageAttributes.width * scale,
                height: imageAttributes.height * scale
            }
        };

        if (groupType === GroupTypes.images) {
            return cmpProps;
        }
        if (groupType === GroupTypes.imageAndText) {
            return {
                ...cmpProps,
                divStyle: {
                    ...cmpProps.divStyle,
                    ...getGroupCmpPositionStylesForMobile(group, component, settings[actualId]).mobileStyles
                }
            };
        }
        return componentProps;
    }
};

