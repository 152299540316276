import React from 'react';
import { connect } from "react-redux";

import DirectoryCell from './DirectoryCell';
import ResourceCell from './ResourceCell';
import { cellCommonMapDispatchToProps } from "../../utils";
import { DemoTip } from '../../../../../../demo/modules/tip/DemoTip';
import { TooltipPosition } from '../../../../../components/presentational/Tooltip/constants';

import styles from '../FileChooserList.css';

const DeleteCell = (props) => {
    const
        { resource, deleteResource } = props,
        cbProps = { ...props, column: 'delete' },
        ItemCell = resource.isDirectory() ? DirectoryCell : ResourceCell,
        showDelete = !resource.isSystemResource();

    const onDeleteClick = (event) => {
        if (deleteResource) {
            deleteResource(resource);
        }
        event.stopPropagation();
    };

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ItemCell {...cbProps}>
        {/* @ts-ignore */}
        { showDelete && <DemoTip position={TooltipPosition.BOTTOM} hideOnTargetLeave>
            <span onClick={onDeleteClick} className={styles.deleteIcn} />
        </DemoTip>}
    </ItemCell>;
};

export default connect(null, cellCommonMapDispatchToProps)(DeleteCell);
