import React from 'react';
import { connect } from 'react-redux';
import { makeEpicStateSelector } from "../../../../../epics/makeEpic";
import styles from './LinkChooserDialog.css';
import TabDialog, { Tab } from '../../TabDialog/index';
import { PageTab, FileTab, UrlTab, PhoneTabCom, EmailTab, SectionLinkTab, LocationTab } from './tabs/index';
import { LcTabName } from '../constants';
import { LC_SWITCH_TAB, LC_SAVE, LC_CANCEL } from "../actionTypes";
import stateToResult from "../utils/stateToResult";
import type { LinkChooserInterchangeType, LinkChooserDialogProps, TabNames } from "../flowTypes";
import { MSG as EXTERNAL_URL_MSG } from "../../../ExternalUrl";
import isResultEqualToInput from "../utils/isResultEqualToInput";
import { LinkChooserFooter } from './LinkChooserFooter';
import { SECTION_COMPONENTS_EPIC_VAT } from '../../../../../components/PagesTree/sectionComponents/epic/sectionComponentsEpic';
import currentPageIdVAT from '../../../../../components/App/epics/currentPageId/valueActionType';
import { StripTypes } from "../../baseDialog/index";
import { SectionLinkTabSubtitle } from '../../../../../components/PagesTree/NewPageDialog/view/tabs/SectionLinkTab/SectionLinkTabSubtitle';
import LoadingIndicator from '../../../LoadingIndicator/index';
import { Msg } from '../../../../intl/index';
import { GlobalVariableContactEmailKey, GlobalVariablePhoneNumberKey, isAddressSet } from '../../../../../constants';
import { getPageIdForFileLinks } from "../utils/getPageIdForFileLinks";

const {
    renderAddressToStringSingleline, renderAddressToStringMultiline
} = require('../../../../../../../server/shared/address/utils.js');

export const isValidSectionLinkSelection = (props: Record<string, any>) => {
    const { pageSections, isLoading, pageOptions, sectionId, selection } = props;
    const isValidPageId = pageOptions.some(({ value }) => value === selection),
        sectionOptions = pageSections[selection] || [],
        isInvalidSectionId = !isLoading && !sectionOptions.find(({ value }) => sectionId && sectionId === value);
    return isValidPageId && !isInvalidSectionId;
};

class LinkChooserDialog extends React.PureComponent<LinkChooserDialogProps> {
    _getOnSaveResult(): null | undefined | LinkChooserInterchangeType {
        const resultFromState = stateToResult(this.props.state, this.props.globalVariables);
        const result = this.resetFileLinksToPageLinks(resultFromState);

        // check if not the same already selected
        if (result && this.props.state.currentResult && isResultEqualToInput(result, this.props.state.currentResult)) {
            return null;
        }
        if (result && result.link.type === LcTabName.SECTION_LINK) {
            const sectionInkTabProps: Record<string, any> = this.props.state[result.link.type],
                { sectionComponents: { pageSections, isLoading, pageOptions } } = this.props,
                { sectionId, selection } = sectionInkTabProps;

            const isValidSectionLink = isValidSectionLinkSelection({
                pageSections,
                isLoading,
                pageOptions,
                sectionId,
                selection
            });
            if (!isValidSectionLink) {
                return null;
            }
        }
        return result;
    }

    resetFileLinksToPageLinks(linkChooserObj: null | undefined | LinkChooserInterchangeType) {
        if (linkChooserObj) {
            const { link: { type, value } } = linkChooserObj;
            const pageId = getPageIdForFileLinks({ type, value, site: this.props.site });
            if (pageId) {
                return {
                    ...linkChooserObj,
                    link: { type: LcTabName.PAGE, value: pageId }
                };
            }
        }
        return linkChooserObj;
    }

    _saveIsDisabled() {
        if (this.props.input && !this.props.state.currentResult) return false;
        return !this._getOnSaveResult();
    }

    _switchTab(tab: any) {
        this.props.dispatch({ type: LC_SWITCH_TAB, payload: { tabName: tab.props.id } });
    }

    _tabIsHidden(tabName: TabNames): boolean {
        return this.props.tabs !== undefined && !this.props.tabs.includes(tabName);
    }

    onSave = () => {
        if (this._saveIsDisabled()) return;
        this.props.dispatch({
            type: LC_SAVE,
            payload: { setLinkAction: this.props.setLinkAction, linkAction: this._getOnSaveResult() }
        });
    }

    onCancel() {
        this.props.dispatch({ type: LC_CANCEL });
    }

    renderPageTab() {
        const
            tab = LcTabName.PAGE,
            props = {
                ...this.props.state[tab],
                globalVariables: this.props.globalVariables,
                site: this.props.site,
                subscriptionData: this.props.subscriptionData,
                currentResult: this.props.currentResult,
                dispatch: this.props.dispatch
            };

        return (
            <Tab
                id={tab}
                label="msg: lc.tab.page.label {Page}"
                title="msg: lc.tab.page.title {Link to a page in Website Builder}"
                isHidden={this._tabIsHidden(tab)}
            >
                <PageTab {...props} />
            </Tab>
        );
    }

    renderSectionLinkTab() {
        const
            tab = LcTabName.SECTION_LINK,
            { dispatch, sectionComponents: { pageSections, isLoading, pageOptions }, currentPageId } = this.props,
            sectionInkTabProps = this.props.state[tab],
            isValidPageId = pageOptions.some(({ value }) => value === sectionInkTabProps.selection),
            props = {
                ...sectionInkTabProps,
                dispatch,
                pageSections,
                currentPageId,
                isLoading,
                pageOptions,
                isValidPageId,
            };
        return (
            <Tab
                id={tab}
                label="msg: lc.tab.sectionlink.label {Section link}"
                title="msg: lc.tab.sectionlink.title {Link to a section on your site}"
                isHidden={this._tabIsHidden(tab)}
                subTitleContent={<SectionLinkTabSubtitle />}
                subTitleClass={styles.tabSubTitle}
                tabBodyCustomStyle={styles.sectionLinkTabBody}
            >
                <SectionLinkTab {...props} />
                {isLoading && <LoadingIndicator absoluteCenter />}
            </Tab>
        );
    }

    renderFileTab() {
        const
            tab = LcTabName.FILE,
            props = {
                ...this.props.state[tab],
                onSave: this.onSave,
                dispatch: this.props.dispatch,
            };

        return (
            <Tab
                id={tab}
                label="msg: lc.tab.file.label {File}"
                isHidden={this._tabIsHidden(tab)}
                className={styles.fcTab}
            >
                <FileTab title="msg: lc.tab.file.title {Link to a file}" {...props} />
            </Tab>
        );
    }

    renderUrlTab() {
        const
            tab = LcTabName.URL,
            props = {
                ...this.props.state[tab],
                onSave: this.onSave,
                dispatch: this.props.dispatch
            };

        return (
            <Tab
                id={tab}
                label="msg: common.externalUrl {External URL}"
                title={EXTERNAL_URL_MSG}
                isHidden={this._tabIsHidden(tab)}
            >
                <UrlTab {...props} />
            </Tab>
        );
    }

    renderPhoneTab() {
        const
            tab = LcTabName.PHONE,
            props = {
                currentLinkValue: this.props.state[tab].value,
                phoneNumberFromSiteSettings: this.props.globalVariables[GlobalVariablePhoneNumberKey],
                input: this.props.state[tab].input,
                dispatch: this.props.dispatch,
                onSave: this.onSave
            };

        return (
            <Tab
                id={tab}
                label="msg: common.phoneNumber {Phone number}"
                title="msg: linkToPhoneNumber {Link to a phone number}"
                subTitleContent={
                    <Msg k="lc.phone.subTitle">
                        This generates a click-to-call link on your website.
                        Your visitors can click this link to call you, if they are using a phone or a tablet.
                    </Msg>
                }
                subTitleClass={styles.tabSubTitle}
                isHidden={this._tabIsHidden(tab)}
            >
                <PhoneTabCom
                    {...props}
                />
            </Tab>
        );
    }

    renderEmailTab() {
        const
            tab = LcTabName.EMAIL,
            props: any = {
                ...this.props.state[tab],
                onSave: this.onSave,
                dispatch: this.props.dispatch
            };

        return (
            <Tab
                id={tab}
                label="msg: common.email {Email}"
                title="msg: lc.tab.email.label {Link to an email}"
                subTitleContent={
                    <Msg k="lc.tab.email.help">
                        This generates a link on your website.
                        When visitors click this link it will open a new email with their preferred email program.
                    </Msg>
                }
                subTitleClass={styles.tabSubTitle}
                isHidden={this._tabIsHidden(tab)}
            >
                <EmailTab
                    {...(props)}
                    contactEmailFromSiteSettings={this.props.globalVariables[GlobalVariableContactEmailKey]}
                />
            </Tab>
        );
    }

    renderLocationTab() {
        const
            tab = LcTabName.LOCATION,
            props: any = {
                ...this.props.state[tab],
                onSave: this.onSave,
                dispatch: this.props.dispatch
            };

        return (
            <Tab
                id={tab}
                label="msg: common.location {Location}"
                title="msg: lc.tab.location.label {Link to a location}"
                subTitleContent={
                    <Msg k="lc.tab.location.help">
                        This generates a map link on your website.
                        Your visitors can click this link to open the default map application on their device.
                    </Msg>
                }
                subTitleClass={styles.tabSubTitle}
                isHidden={this._tabIsHidden(tab)}
            >
                <LocationTab
                    {...(props)}
                    singleLineAddressFromSiteSettings={renderAddressToStringSingleline(this.props.globalVariables)}
                    multiLineAddressFromSiteSettings={renderAddressToStringMultiline(this.props.globalVariables)}
                    // @ts-ignore
                    isAddressSet={isAddressSet(this.props.globalVariables)}
                />
            </Tab>
        );
    }

    renderFooter() {
        const props = {
            state: this.props.state,
            site: this.props.site,
            dispatch: this.props.dispatch,
            sectionComponents: this.props.sectionComponents,
            globalVariables: this.props.globalVariables,
        };
        return (
            <LinkChooserFooter {...props} />
        );
    }

    render() {
        const
            onCancel = this.onCancel.bind(this),
            { activeTabName } = this.props.state;
        return (
            <TabDialog
                stripType={StripTypes.NONE}
                onTabSwitch={this._switchTab.bind(this)}
                activeTabKey={activeTabName}
                mctaHandler={this.onSave}
                sctaHandler={onCancel}
                onClose={onCancel}
                disabled={this._saveIsDisabled()}
                footer={this.renderFooter()}
                dispatch={this.props.dispatch}
                dialogbodyClassName={styles.pageDialogBody}
                tabHeadingCustomStyle={styles.pageHeading}
            >
                {this.renderPageTab()}
                {this.renderSectionLinkTab()}
                {this.renderFileTab()}
                {this.renderUrlTab()}
                {this.renderEmailTab()}
                {this.renderPhoneTab()}
                {this.renderLocationTab()}
            </TabDialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sectionComponents: makeEpicStateSelector(SECTION_COMPONENTS_EPIC_VAT)(state),
        currentPageId: makeEpicStateSelector(currentPageIdVAT)(state),
    };
};

export default connect(mapStateToProps)(LinkChooserDialog);

