export const
    CONTINUE_TO_APP_START = 'CONTINUE_TO_APP_START',
    HIDE_UNSUPPORTED_DIMENSION_MESSAGE = 'HIDE_UNSUPPORTED_DIMENSION_MESSAGE',
    HIDE_NO_TOUCH_SCREEN_SUPPORT_DIALOG = 'HIDE_NO_TOUCH_SCREEN_SUPPORT_DIALOG',
    HIDE_DEPRECATED_BROWSER_DIALOG = 'HIDE_DEPRECATED_BROWSER_DIALOG',
    NO_TOUCH_SCREEN_SUPPORT_TOGGLE_DONT_SHOW_AGAIN = 'NO_TOUCH_SCREEN_SUPPORT_TOGGLE_DONT_SHOW_AGAIN',
    UNSUPPORTED_MESSAGES_COMPLETED = 'UNSUPPORTED_MESSAGES_COMPLETED',
    UPGRADE_BROWSER = 'UPGRADE_BROWSER',
    TRACK_UNSUPPORTED_TOUCH_SCREEN_CONTINUE = 'TRACK_UNSUPPORTED_TOUCH_SCREEN_CONTINUE',
    TRACK_UNSUPPORTED_TOUCH_SCREEN_DIALOG_SHOW = 'TRACK_UNSUPPORTED_TOUCH_SCREEN_DIALOG_SHOW';
