import React from 'react';
import sectionStyles from "./workspace.css";
import { injectIntl, Msg } from "../../../../view/intl/index";

export const headerText = 'msg: sectionDefault.header {header}';
export const footerText = 'msg: sectionDefault.footer {footer}';
export const DefaultSectionContent = injectIntl(({ intl, isHeader }) => {
    const headerOrFooterText = intl.msgJoint(isHeader ? headerText : footerText);
    return (
        <div className={sectionStyles.defaultTextContainer}>
            <div>
                <Msg
                    k="blankSection.defaultTextHeading"
                    className={sectionStyles.heading}
                    params={{ headerOrFooterText }}
                >
                    {`This is your {headerOrFooterText}`}
                </Msg>
            </div>
            <div>
                <Msg k="blankSection.defaultText.addSomeContent" className={sectionStyles.info}>
                    Add some content to make it appear on your website.
                </Msg>
            </div>
        </div>
    );
});
