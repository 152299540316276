import React from "react";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';

import { useIntl } from '../../../../../../../wbtgen/src/view/intl';

const useStyles = makeStyles(
    {
        title: {
            fontSize: "24px",
            lineHeight: "30px",
            letterSpacing: "0.15px",
        }
    },
    { name: "WidgetTitle" }
);

type WidgetTitleProps = {
    title: string;
};

export const WidgetTitle = ({ title }: WidgetTitleProps) => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Typography className={classes.title}>
            {intl.msgJoint(title)}
        </Typography>
    );
};
