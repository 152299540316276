import type { LinkChooserLinkType } from "../../../../view/common/dialogs/LinkChooserDialog/flowTypes";
import { LcTabName } from "../../../../view/common/dialogs/LinkChooserDialog/constants";
import Url from "../../../../../utils/Url";
import { getDAL, WEBSPACE_PREFIX } from "../../../../../dal";
import Resource from "../../../../redux/modules/children/fileChooser/Resource";
import * as pathUtils from "../../../../../utils/path";

type Input = {
    url: string;
    linkId: string | null | undefined;
};

export default ({ url, linkId }: Input): LinkChooserLinkType => {
    let
        value,
        type;

    if (linkId) { // page case
        [type, value] = [LcTabName.PAGE, linkId];
    } else {
        const
            parsedUrl = Url(url),
            parsedPath = parsedUrl.getPath();
        if (
            (
                parsedUrl.host === getDAL().getDomain() &&
                parsedUrl.href.indexOf('#') === -1 &&
                parsedUrl.href.indexOf('?') === -1
            ) ||
            parsedUrl.protocol === WEBSPACE_PREFIX
        ) { // file case
            [type, value] = [
                LcTabName.FILE,
                new Resource(pathUtils.ltrim(parsedPath.dir), { href: parsedPath.base })
            ];
        } else if (parsedUrl.host) { // url case
            [type, value] = [LcTabName.URL, url];
        } else {
            throw new Error(`Unsupported link page url '${url}' to make link from`);
        }
    }

    return { type, value };
};
