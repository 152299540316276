import { useState, useEffect } from "react";

export default (time = 200) => {
    const [render, enableRender] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            enableRender(true);
        }, time);
        return () => clearTimeout(timeoutId);
    }, []);

    return render;
};
