import { parseDOM } from "../../../../../utils/parseDOM";
import { InstagramSrcLink } from '../constants';
import { type InstagramComponent } from '../types';

export const parseInstagramPostCode = (code: string): InstagramComponent => {
    let params = <InstagramComponent>{};

    const configs = [
        {
            nodeName: 'BLOCKQUOTE',
            validator: node => node.classList.contains('instagram-media'),
            children: [
                {
                    nodeName: 'DIV',
                    children: [
                        {},
                        {},
                        {
                            nodeName: 'P',
                            children: [
                                {
                                    nodeName: 'A',
                                    parser: node => {
                                        const href = node.href;
                                        return {
                                            link: `${InstagramSrcLink}${href.split('/')[4]}`
                                        };
                                    }
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ];

    params = parseDOM(configs, code);

    return params;
};
