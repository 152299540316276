import { CLOSE_BANNER, OPEN_BANNER } from "./epic/actionTypes";
import { type BannerProps } from './types';

export const
    BannerDefaultState = {
        iconCss: '',
        texts: [],
    },
    openBanner = (bannerProps: BannerProps) => ({
        type: OPEN_BANNER,
        payload: bannerProps
    }),
    closeBanner = () => ({
        type: CLOSE_BANNER
    });
