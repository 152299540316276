import * as R from 'ramda';
import { MOUSE_DOWN_ON_DRAGGABLE_AREA_OF_DIALOG } from "../actionTypes";
import { dialogManagerDefaultScope } from "./constants";
import { WINDOW_MOUSE_UP } from "../../../redux/modules/actionTypes";
import type { DialogManagerEpicUpdater } from "../flowTypes";
import { getPositionTransformation } from "./shared";
import { WINDOW_MOUSE_MOVE } from "../../App/actionTypes";
import { idleUIMode, mouseDownUIMode, mouseMoveUIMode } from "./interactionModes";

export const
    onDraggableAreaMouseDownUpdater: DialogManagerEpicUpdater = {
        conditions: [MOUSE_DOWN_ON_DRAGGABLE_AREA_OF_DIALOG],
        reducer: ({ values: [{ id, x, y }], scope, state }) => {
            if (id) {
                return {
                    state: {
                        ...state,
                        isDragging: true
                    },
                    scope: R.assoc('userInteraction', {
                        mode: 'DOWN',
                        payload: {
                            currentDialogId: id,
                            startPosition: { x, y },
                            currentPosition: { x, y }
                        }
                    }, scope)
                };
            }

            return {
                state,
                scope
            };
        }
    },
    onMouseMoveUpdater: DialogManagerEpicUpdater = {
        conditions: [WINDOW_MOUSE_MOVE],
        reducer: ({ values: [payload], state, scope }) => {
            if (scope.userInteraction.mode === idleUIMode) {
                return {
                    state,
                    scope
                };
            }

            const
                updateScope = R.pipe(
                    R.assocPath(['userInteraction', 'mode'], mouseMoveUIMode),
                    R.assocPath(['userInteraction', 'payload', 'currentPosition'], payload)
                );

            return {
                state,
                scope: updateScope(scope)
            };
        }
    },
    onMouseUpUpdater: DialogManagerEpicUpdater = {
        conditions: [WINDOW_MOUSE_UP],
        reducer: ({ state, scope }) => {
            const ui = scope.userInteraction;
            if (ui.mode === mouseDownUIMode || ui.mode === mouseMoveUIMode) {
                const
                    { browserDimensions } = scope,
                    { currentPosition, startPosition, currentDialogId } = ui.payload,
                    isCurrentConfig = R.propEq('id', currentDialogId),
                    updateConfigPosition = config => {
                        const { dimensions } = config;
                        return R.evolve({
                            position: getPositionTransformation({
                                currentPosition, startPosition, dimensions, browserDimensions
                            })
                        })(config);
                    };
                return {
                    state: {
                        ...state,
                        isDragging: false
                    },
                    scope: R.evolve({
                        openedDialogConfigs: R.map(R.when(isCurrentConfig, updateConfigPosition)),
                        userInteraction: () => dialogManagerDefaultScope.userInteraction
                    }, scope)
                };
            }

            return { state, scope };
        }
    };
