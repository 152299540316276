import * as R from 'ramda';
import cloneDeep from 'clone-deep';
import { topSorter } from '../../../Preview/flattening/util';
import type { ComponentsMap, AnyComponent } from "../../../../redux/modules/children/workspace/flowTypes";
import type { RelationsMap } from "./flowTypes";
import {
    getLayers,
} from "../../../Preview/flattening/Flattening";
import isStretchComponentKind from '../../../oneweb/isStretchComponentKind';
import Resource from "../../../../redux/modules/children/fileChooser/Resource";
import isGhost from "../../../oneweb/Code/isGhost";
import cmpRoundDecimalValues from "../../../../utils/componentsMap/cmpRoundDecimalValues";
import { customSendReport } from "../../../../customSendCrashReport";
import { getDAL } from "../../../../../dal/index";
import { fixNullAsset } from "../../../oneweb/Image/utils";

const relInProps = ['id', 'top', 'bottom', 'right', 'left'],
    isRelInsEqual = (prevRelIn, relIn) => {
        if (prevRelIn && relIn) {
            return relInProps.every(key => prevRelIn[key] === relIn[key]);
        }
        return prevRelIn === relIn;
    },

    /*
     * Returns true if lowerComponent is vertically below upperComponent and is in
     * upperComponent's projection (shares vertical slice).
     */
    isInProjection = (lowerComponent: AnyComponent, upperComponent: AnyComponent): boolean => {
        return (lowerComponent.left < (upperComponent.left + upperComponent.width)
            && upperComponent.left < (lowerComponent.left + lowerComponent.width)
            && (upperComponent.top + upperComponent.height) <= lowerComponent.top);
    },
    getRelTo = (refComponent, other) => {
        const { top: refTop } = refComponent,
            { id, top, height } = other;
        return {
            id,
            below: refTop - (top + height)
        };
    },
    getProjection = (aLeft, aRight, bLeft, bRight) => {
        return Math.min(aRight, bRight) - Math.max(aLeft, bLeft);
    },

    /*
     * Find the best component to use for setting relTo.
     * https://confluence.one.com/display/WED/3rd+Generation+Component+Relations
     * 1. Component with greatest projection
     * 2. Component with lowest bottom
     * 3. Component in template
     * 4. Component with lowest orderIndex
     */
    getRelToComponent = (refComponent, components) => {
        const refLeft = refComponent.left;
        const refWidth = refComponent.width;
        const refRight = refLeft + refWidth;

        let candidates = [...components];

        if (refComponent.inTemplate) {
            // Template components may only have relTo to other template components
            candidates = candidates.filter(component => component.inTemplate);
        }

        if (!candidates.length) {
            return null;
        }

        if (candidates.length === 1) {
            return components[0];
        }

        // Sort by projection
        candidates.sort((a, b) => {
            const aProjection = getProjection(a.left, a.left + a.width, refLeft, refRight);
            const bProjection = getProjection(b.left, b.left + b.width, refLeft, refRight);

            return aProjection - bProjection;
        });
        // Reverse it for convenience - we need the component with the biggest projection
        candidates.reverse();

        const maxProjection = getProjection(candidates[0].left, candidates[0].left + candidates[0].width,
            refLeft, refRight);
        candidates = candidates.filter(({ left, width }) => {
            return getProjection(left, left + width, refLeft, refRight) === maxProjection;
        });

        candidates.sort((a, b) => {
            return (a.top + a.height) - (b.top + b.height);
        });
        // Reverse for convenience - we need component with lowest bottom
        candidates.reverse();

        const lowestBottom = candidates[0].top + candidates[0].height;
        candidates = candidates.filter(component => {
            return lowestBottom === component.top + component.height;
        });

        candidates.sort((a, b) => {
            if (a.inTemplate === b.inTemplate) {
                return 0;
            } else if (a.inTemplate) {
                return 1;
            } else {
                return -1;
            }
        });
        candidates.reverse();

        const inTemplate = candidates[0].inTemplate;
        candidates = candidates.filter(component => {
            return inTemplate === component.inTemplate;
        });

        candidates.sort((a, b) => {
            return a.orderIndex - b.orderIndex;
        });
        candidates.reverse();

        return candidates[0];
    },
    addRelTo = (component: AnyComponent, relComponents: Array<AnyComponent>) => {
        const relToComponent = getRelToComponent(component, relComponents);

        component.relTo = (relToComponent ? getRelTo(component, relToComponent) : null); // eslint-disable-line no-param-reassign
    },

    skipRelTopCandidate = (
        component: AnyComponent,
        componentCandidate: AnyComponent,
        componentsMap: ComponentsMap
    ): boolean =>
        // skip if both(component and componentCandidate) are in template or
        // both are in page or
        // if the page component has already relto to another page component
        ((component.inTemplate && componentCandidate.inTemplate) ||
            (!component.inTemplate && (
                (!componentCandidate.inTemplate) ||
                (component.relTo && componentsMap[component.relTo.id] && !componentsMap[component.relTo.id].inTemplate)
            ))) || false,
    updateRelTo =
        (
            layerComponents: Array<AnyComponent>,
            componentsMap: ComponentsMap,
            block: AnyComponent,
            adjustPageToTemplateMode: boolean = false,
        ) => {
            // Order by bottom coordinate. From top to bottom.
            const
                components = [...layerComponents].sort((a, b) => {
                    return (a.top + a.height) - (b.top + b.height);
                }),
                pageComponents: Array<AnyComponent> = [],
                templateComponents: Array<AnyComponent> = [];

            components.forEach((component, outerIndex) => {
                const isRefComponentInTemplate = component.inTemplate;

                if (!adjustPageToTemplateMode) {
                    component.relTo = null; // eslint-disable-line no-param-reassign
                }

                if (isGhost(component)) {
                    return; // ghost components should not have any relations
                }

                if (isRefComponentInTemplate) {
                    templateComponents.push(component);
                } else {
                    pageComponents.push(component);
                }

                let relToCandidates: Array<AnyComponent> = [];
                components.slice(0, outerIndex).forEach(candidateComponent => {
                    if (
                        adjustPageToTemplateMode
                        && skipRelTopCandidate(component, candidateComponent, componentsMap)
                    ) {
                        return;
                    }

                    if (!isGhost(candidateComponent) && isInProjection(component, candidateComponent)) {
                        if (!isRefComponentInTemplate || candidateComponent.inTemplate) {
                            relToCandidates.push(candidateComponent);
                        }
                    }
                });

                // Candidate components that project onto other candidates should be disqualified
                // this has to be done for both relPage and relTo
                // We need 2 arrays as it is possible for a template component during disqualification we might
                // skip a template component which is needed for relTo as there might be a page component below it
                // and also in projection of 'component'

                // Candidate components that project onto other candidates should be disqualified
                // this is only needed for relTo and not relPage as per my understanding
                relToCandidates = relToCandidates.filter((candidateComponent, idx) => {
                    let i = idx + 1;
                    if (i === relToCandidates.length) {
                        return true;
                    }

                    if (isStretchComponentKind(candidateComponent.kind)) {
                        return false; // implies more items below which are also in projection of 'component'
                    }

                    for (; i < relToCandidates.length; i++) {
                        if (isInProjection(relToCandidates[i], candidateComponent)) {
                            break;
                        }
                    }

                    return i === relToCandidates.length;
                });

                if (relToCandidates.length) {
                    addRelTo(component, relToCandidates);
                }
            });
            return componentsMap;
        },
    processIntersectingComponents = (layerZeroGroup: any, group: any) => {
        const
            { block: { id: blockId, top: t1, height: h1 }, items } = group,
            b1 = t1 + h1;

        const layerZeroItems = layerZeroGroup.items;

        layerZeroItems.sort(topSorter);

        for (let i = layerZeroItems.length - 1; i > -1; i--) {
            let item = layerZeroItems[i];
            if (item.id === blockId || !item.inTemplate || isStretchComponentKind(item.kind)) {
                continue;
            }

            const
                { top: t2, height: h2 } = item,
                b2 = t2 + h2,
                mid = t2 + (h2 / 2);

            if (b2 < t1) {
                break; // its before block
            }

            if (mid >= t1 && mid <= b1) {
                items.push(layerZeroItems[i]);
            }
        }
    },
    processRelations = (
        componentsMap: ComponentsMap,
        template: any,
        adjustPageToTemplateMode: boolean = false,
    ) => {
        const
            components = Object.keys(componentsMap).map(id => componentsMap[id]),
            layers = getLayers(components, componentsMap, template);
        if (!layers.length) {
            return;
        }
        const layerZeroGroup = layers[0][0]; // layer 0 has only 1 group with template as block

        layers.forEach((groups, layerIdx) => {
            groups.forEach(group => {
                const { block } = group;
                if (layerIdx === 1 && isStretchComponentKind(block.kind) && !block.inTemplate) {
                    // get layer 0 template items intersecting with this block
                    processIntersectingComponents(layerZeroGroup, group);
                }

                updateRelTo(group.items, componentsMap, block, adjustPageToTemplateMode);
            });
        });
    },
    syncRelation = (relationsMap: RelationsMap,
        componentsMap: ComponentsMap,
        template: any,
        currentPageId?: string,
        sendCrashReportForDecimalValues?: boolean): ComponentsMap => {
        let newComponentsMap = cloneDeep(componentsMap, o => {
            if (o instanceof Resource) return o;
            throw new Error('Storing object with prototypes in state is deprecated');
        });
        Object.keys(newComponentsMap).forEach(id => {
            newComponentsMap[id] = cmpRoundDecimalValues(newComponentsMap[id]);
        });
        if (sendCrashReportForDecimalValues && !R.equals(newComponentsMap, componentsMap)) {
            customSendReport({
                crashReportId: 'VALUE_HAS_DECIMALS',
                message: `Domain: ${getDAL().getDomain()}, Page Id: ${currentPageId || ''}`,
                additionalInfo: componentsMap
            });
        }

        Object.keys(newComponentsMap).forEach(id => {
            // If changes does not have entry means its the case of relIn been removed
            if (!relationsMap[id] || !isRelInsEqual(relationsMap[id].relIn, newComponentsMap[id].relIn)) {
                newComponentsMap[id].relIn = (relationsMap[id] ? relationsMap[id].relIn : null);
            }
        });

        newComponentsMap = fixNullAsset(newComponentsMap, true);
        processRelations(newComponentsMap, template);
        return newComponentsMap;
    };

export default syncRelation;
export { processRelations, isInProjection };
