import React from "react";
import { Skeleton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    loader: {
        width: "100%",
        height: "190px",
        [theme.breakpoints.down("sm")]: {
            height: "450px"
        },
    }
}),
{ name: "OnlineShopWidgetLoader" });

export const OnlineShopWidgetLoader = () => {
    const classes = useStyles();

    return <Skeleton variant="rectangular" className={classes.loader} data-testid="online-shop-widget-loader" />;
};
