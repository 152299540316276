import { withSelector } from "../../../../epics/makeCondition";
import { tinyMceVAT } from "./valueActionType";

export const TinyMceExistSelector = withSelector(tinyMceVAT, ({ tinyMceExist }) => tinyMceExist);
export const TinyMceLinkedNodeBottomPositionSelector = withSelector(
    tinyMceVAT,
    ({ selectedLinkedNode }) => selectedLinkedNode && selectedLinkedNode.bottomPosition
);
export const TinyMceLinkedNodeSelected = withSelector(
    tinyMceVAT,
    ({ selectedLinkedNode }) => !!selectedLinkedNode
);
