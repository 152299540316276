import { Button } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { Msg } from '../../../../../../../view/intl';
import styles from './styles.css';
import VerticalSpacer from '../../../../../../../view/common/VerticalSpacer';
import { TermsCheckbox } from "../EditingAssistant/TermsCheckbox";
import { AI_TEXT_EDIT_INTRO_CLICKED, AI_TEXT_EDIT_INTRO_CLOSED } from '../../../../epics/EditAITextEpic/actionTypes';

export const Onboard = ({ closeAction }) => {
    const dispatch = useDispatch();
    const showTerms = true;
    return <div className={styles.wrapper}>
        <div className={styles.leftContainer}>
            <span className={styles.welcomeTitle}>
                <Msg k="component.text.onboardEditAiText.dialog.title">Edit with AI</Msg>
            </span>
            <VerticalSpacer y={15} />
            <span className={styles.welcomeMessage}>
                <Msg k="component.text.onboardEditAiText.dialog.msg">
                    Select from our suggested editing options or write your own input, and let Writing Assistant create new versions.
                </Msg>
            </span>
            <div className={styles.termsAndConditions}><TermsCheckbox showTerms={showTerms} /></div>
            <div className={styles.mainBtns} data-testid="ai-text-edit-buttons">
                <Button
                    variant="outlined"
                    color="inherit"
                    className={styles.skipBtn}
                    onClick={() => {
                        dispatch({ type: AI_TEXT_EDIT_INTRO_CLOSED });
                        closeAction();
                    }}
                >
                    <Msg k="common.cancel">Cancel</Msg>
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className={styles.btn}
                    onClick={() => {
                        dispatch({ type: AI_TEXT_EDIT_INTRO_CLICKED });
                        closeAction();
                    }}
                >
                    <Msg k="common.continue">Continue</Msg>
                </Button>
            </div>
        </div>
        <div className={cx(styles.rightContainer, styles.editingIllustration)} />
    </div>;
};
