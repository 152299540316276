import type { FeaturedProductsCalcRenderProps, FeaturedProductsViewProps } from "./flowTypes";
/* TODO - WBTGEN-18324: Move any calculations done in view here. */
/* CalcRenderProps is created to pass selectedParentTheme for now.*/

import {
    getViewdetailsButtonStyleForPreview,
    getFirstButtonClassname,
    getWebshopLinkStyleForPreview,
    getWebshopLinkClassname,
} from "../WebShop/utils";

import { getBackgroundColor } from './utils';

export default (props: FeaturedProductsCalcRenderProps): FeaturedProductsViewProps => {
    const {
        component,
        selectedParentTheme,
    } = props;

    const {
        globalStyles,
        themeSettingsData,
        localeDetails: { current: locale },
    } = props.componentDependencies;

    const { autoColorMode } = themeSettingsData;

    const linkClassName = getWebshopLinkClassname(globalStyles);
    const linkStyle = getWebshopLinkStyleForPreview({
        autoColorMode,
        globalStyles,
        selectedParentTheme
    });
    const buttonClassname = getFirstButtonClassname(autoColorMode, globalStyles, selectedParentTheme);
    const { buttonClassname: viewDetailsButtonClassname, buttonStyle: viewDetailsButtonStyle } =
        getViewdetailsButtonStyleForPreview(
            autoColorMode,
            component.viewDetailsButtonId,
            globalStyles,
            selectedParentTheme,
            component.viewDetailsButtonThemeSelected
        );
    const { buttonClassname: buyNowButtonClassName, buttonStyle: buyNowButtonStyle } =
        getViewdetailsButtonStyleForPreview(
            autoColorMode,
            component.buyNowButtonId,
            globalStyles,
            selectedParentTheme,
            component.buyNowButtonThemeSelected
        );
    const backgroundColor = getBackgroundColor(selectedParentTheme, globalStyles);
    return {
        ...props,
        ...props.componentDependencies,
        linkClassName,
        linkStyle,
        buttonClassname,
        viewDetailsButtonClassname,
        viewDetailsButtonStyle,
        backgroundColor,
        buyNowButtonClassName,
        buyNowButtonStyle,
        locale
    };
};
