import * as R from "ramda";
import { applyMappers, makePlainMappers } from "../../utils";
import * as actionMapper from "../Common/action";
import { evolvePath } from "../../../../src/utils/ramdaEx";
import type { ButtonComponent } from "../../../../src/components/oneweb/Button/flowTypes";
import { buttonStyleMapper } from "./styleMapper";

const plainPropsMapper = makePlainMappers({
    btnText: "text",
    mobileDown: "mobileDown",
    mobileHide: "mobileHide",
    mobileSettings: "mobileSettings",
    styleButton: "style",
    action: "linkAction",
    buttonThemeSelected: 'buttonThemeSelected',
    modernLayoutOptions: 'modernLayoutOptions',
});

export function to(componentData: any): ButtonComponent {
    return applyMappers(
        componentData,
        R.pipe(
            plainPropsMapper.to,
            evolvePath(['linkAction'])(actionMapper.to),
            evolvePath(['style'])(buttonStyleMapper.to)
        )
    );
}

export function back(componentData: ButtonComponent): any {
    return applyMappers(
        componentData,
        R.pipe(
            plainPropsMapper.back,
            evolvePath(['action'])(actionMapper.back),
            evolvePath(['styleButton'])(buttonStyleMapper.back)
        )
    );
}
