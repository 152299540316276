import type { SideBarItemDef } from '../../SideBar/types';

import imageStyles from '../../Images/imageStyle.css';

export const HOME_PAGE_SIDE_BAR_ITEM_ID = 'HOME_PAGE_SIDE_BAR_ITEM_ID';

export const HOME_PAGE_SIDEBAR_ITEM: SideBarItemDef = {
    id: HOME_PAGE_SIDE_BAR_ITEM_ID,
    icon: imageStyles.dashboard,
    title: 'msg: go.to.dashboard {Dashboard}',
    header: {
        title: "msg: dashboard.overview.title {Website Builder Overview}",
        description: "msg: dashboard.overview.description {Manage your website settings and follow the steps to create your site}"
    }
};
