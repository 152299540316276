import React from "react";
import { Msg, injectIntl } from "../../../../../view/intl/index";
import TopBarDropdownMenuItem from '../../TopBarDropdownMenuItem/index';
import Popup from "../index";
import type { TopBarHelpDropdownMenuPopupProps } from "../../../flowTypes";
import styles from "./TopBarHelpPopup.css";
import { START_QUICK_TOUR, START_SECTION_TOUR } from "../../../../QuickTour/actionTypes";
import { WINDOW_SHOW_WIDGET } from "../../../../../redux/middleware/window/actionTypes";
import { KEYBOARD_SHORTCUTS_CLICKED, TUTORIAL_VIDEO_CLICKED } from "../../../actionTypes";

// @ts-ignore
export default injectIntl(({
    isShown,
    chatLoaded,
    showQuickTour,
    showTutorial,
    popupContainerClassName,
    tipClassName,
    dispatch,
    intl
}: TopBarHelpDropdownMenuPopupProps) => {
    return (
        isShown &&
        <Popup
            containerClassName={popupContainerClassName || styles.popupContainer}
            tipClassName={tipClassName || styles.popupTip}
        >
            {/* <TopBarDropdownMenuItem icon={styles.tipsIcon}>
                    <Msg k="starting.tips">Starting Tips</Msg>
                </TopBarDropdownMenuItem>
                */}
            {
                chatLoaded &&
                <TopBarDropdownMenuItem
                    icon={styles.chatIcon}
                    onClick={() => {
                        dispatch({ type: WINDOW_SHOW_WIDGET, payload: {} });
                    }}
                >
                    <Msg k="common.chatWithUs">Chat with us</Msg>
                </TopBarDropdownMenuItem>
            }
            {showQuickTour && <TopBarDropdownMenuItem
                icon={styles.quickTour}
                onClick={() => dispatch({ type: START_QUICK_TOUR })}
            >
                <Msg k="quickTour.title">Quick tour</Msg>
            </TopBarDropdownMenuItem>}
            {showQuickTour && <TopBarDropdownMenuItem
                icon={styles.sectionsTour}
                onClick={() => dispatch({ type: START_SECTION_TOUR })}
            >
                <Msg k="sectionsTour.title">Sections tour</Msg>
            </TopBarDropdownMenuItem>}
            {showTutorial && <TopBarDropdownMenuItem
                icon={styles.videoTutorial}
                onClick={() => dispatch({ type: TUTORIAL_VIDEO_CLICKED })}
            >
                <Msg k="common.videoTutorial">Video Tutorial</Msg>
            </TopBarDropdownMenuItem>}
            <TopBarDropdownMenuItem
                icon={styles.keyboardIcon}
                onClick={() => dispatch({ type: KEYBOARD_SHORTCUTS_CLICKED })}
            >
                <Msg k="common.keyboardShortcuts">Keyboard shortcuts</Msg>
            </TopBarDropdownMenuItem>
            <TopBarDropdownMenuItem
                icon={styles.languageIcon}
                href={intl.msgJoint('msg: common.faq.url {https://help.one.com/hc/en-us/categories/115000796585-Website-Builder}')}
                target="_blank"
            >
                <Msg k="wsb.faq">Guides</Msg>
            </TopBarDropdownMenuItem>
        </Popup>
    );
});
