/* eslint-disable max-len */

import injectIntl from "../../../intl/injectIntl";
import {
    IMAGE_EDITOR_MESSAGE,
    IMAGE_CLEAR_EDITOR_MESSAGE
} from "../../../../components/ImageEditor/actionTypes";
import ImageEditorDialog from './ImageEditorDialog';
import getCenteredDialogConfig from "../../../../components/DialogManager/getCenteredDialogConfig";
import { getCenterPositionForDialog } from "../../../../components/DialogManager/utility";
import { IMAGE_EDITOR_DIALOG_ID } from "./diaogId";

const
    getDialogDimensions = (browserWidth, browserHeight) => ({
        width: Math.max(0.9 * browserWidth, 850),
        height: Math.max(0.95 * browserHeight, 475)
    });

export const
    defaultDialogState = {
        show: true,
        message: null // can have text and type of message
    },
    dialogId = IMAGE_EDITOR_DIALOG_ID,
    dialog = {
        ...getCenteredDialogConfig(900, 600, injectIntl(ImageEditorDialog)),
        confFactory: ({ browserWidth, browserHeight }) => {
            const { width, height } = getDialogDimensions(browserWidth, browserHeight);
            return {
                position: getCenterPositionForDialog(width, height, browserWidth, browserHeight),
                modal: true,
                dimensions: { width, height }
            };
        },
        moveToCenter: true,
        updateOnBrowserDimensionsChanged: (config, { width: browserWidth, height: browserHeight }) => {
            const dimensions = getDialogDimensions(browserWidth, browserHeight);
            return {
                ...config,
                position: getCenterPositionForDialog(dimensions.width, dimensions.height, browserWidth, browserHeight),
                dimensions
            };
        },
        reducer: (state = defaultDialogState, { type, payload }) => {
            if (type === IMAGE_EDITOR_MESSAGE) {
                return {
                    ...state,
                    message: payload
                };
            }

            if (type === IMAGE_CLEAR_EDITOR_MESSAGE) {
                return {
                    ...state,
                    message: null
                };
            }

            return state;
        }
    };

export default dialog;

