import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';

import { useIntl } from "../../../../../../wbtgen/src/view/intl/injectIntl";
import { UpdateStatusActionButton } from './constants';

import imageStyles from "../../Images/imageStyle.css";

const useStyles = makeStyles(
    (theme) => ({
        updateCampaignStatusButton: {
            fontSize: '14px',
            lineHeight: '17px',
            padding: '0px',
            transition: 'none',
            fontWeight: 400,
            whiteSpace: 'pre',
            '&, &:hover': {
                color: theme.palette.custom.colorScienceBlue,
                backgroundColor: 'transparent'
            }
        }
    }),
    { name: "UpdateStatusButton" }
);

export const UpdateStatusButton = () => {
    const classes = useStyles();
    const intl = useIntl();
    const dispatch = useDispatch();

    return (
        <Button
            disableRipple
            disableElevation
            variant="text"
            className={classes.updateCampaignStatusButton}
            onClick={() => dispatch(UpdateStatusActionButton.action)}
        >
            <Box component="span" className={cx(imageStyles.updateStatus)} marginRight="6px" />
            {intl.msgJoint(UpdateStatusActionButton.label)}
        </Button>
    );
};
