import { $Diff } from "utility-types";

import type { PageTemplateSelectorDialogProps } from "../PageTemplateSelector/types";
import { openPageTemplateSelectorAction } from "../PageTemplateSelector/actions";
import { apiAction } from "../../../redux/middleware/api/actions";

export const
    SWITCH_PAGE_TEMPLATE_ACTION = 'SWITCH_PAGE_TEMPLATE_ACTION',
    switchPageTemplateAction = (id: string) => ({
        type: SWITCH_PAGE_TEMPLATE_ACTION,
        payload: id
    });

export type OpenPageTemplateSwitchParams = $Diff<PageTemplateSelectorDialogProps, { onSaveAction: string }>;

export const
    openPageTemplateSwitchAction = (
        params: OpenPageTemplateSwitchParams = {}
    ) => openPageTemplateSelectorAction({
        ...params,
        onSaveAction: SWITCH_PAGE_TEMPLATE_ACTION,
    });

export const
    LOAD_TEMPLATE_SWITCH_TEMPLATE_SET_REQUEST_ACTION = 'LOAD_TEMPLATE_SWITCH_TEMPLATE_SET_REQUEST_ACTION',
    LOAD_TEMPLATE_SWITCH_TEMPLATE_SET_SUCCESS_ACTION = 'LOAD_TEMPLATE_SWITCH_TEMPLATE_SET_SUCCESS_ACTION',
    LOAD_TEMPLATE_SWITCH_TEMPLATE_SET_FAILURE_ACTION = 'LOAD_TEMPLATE_SWITCH_TEMPLATE_SET_FAILURE_ACTION',
    loadPageTemplateSwitchTemplateSetAction = (templateId: string) => apiAction({
        types: [
            LOAD_TEMPLATE_SWITCH_TEMPLATE_SET_REQUEST_ACTION,
            LOAD_TEMPLATE_SWITCH_TEMPLATE_SET_SUCCESS_ACTION,
            LOAD_TEMPLATE_SWITCH_TEMPLATE_SET_FAILURE_ACTION
        ],
        endpoint: 'getTemplateDataSet',
        endpointParams: [templateId],
    });
