import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly } from "../../../../epics/makeCondition";
import {
    ReceiveOnlyComponentsMap,
} from '../componentsEval/selectorActionTypes';
import { componentAttachmentsVAT } from "../componentAttachements/valueActionType";
import topMostHandleValueActionType from "../topMostHandle/valueActionType";
import { hoveredComponentInsideTemplateSectionUpdater } from "./updaters";

export const defaultState = { isInsideHeaderOrFooter: false };
export const
    hoveredComponentIsInsideTemplateSectionVAT = 'HOVERED_COMPONENT_IS_INSIDE_TEMPLATE_SECTION',
    hoveredComponentIsInsideTemplateSectionEpic = makeEpic({
        defaultState,
        valueActionType: hoveredComponentIsInsideTemplateSectionVAT,
        updaters: [
            {
                conditions: [
                    topMostHandleValueActionType,
                    ReceiveOnlyComponentsMap,
                    receiveOnly(componentAttachmentsVAT),
                ],
                reducer: hoveredComponentInsideTemplateSectionUpdater
            }
        ]
    });

