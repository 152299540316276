import {
    lengthInputValidation,
    urlPathInputValidation,
    blackListInputValidation
} from "../../../../../../inputControls/input/commonValidations";
import { PAGE_INDEX_URL, PAGE_INDEX_HTML_URL } from "../../../../../../../../dal/model/DataPageRef";
import { PAGE_INFO_URL_CHANGE } from "../../actions";
import makeInputReducer from "../../../../../../inputControls/input/reducerFactory/makeInputReducer";
import isDuplicatePageUrlPathInputValidation from "../isDuplicatePageUrlPathInputValidation";
import { containsHtmlExtensionInURL } from "../containsHtmlExtensionInURL";
import { containsInvalidCharactersInURL } from "../containsInvalidCharactersInURL";
import { decodeWebspaceUri } from '../../../../../../../../dal/utils/webspaceUriTransformers';
import type { InputState } from '../../../../../../inputControls/input/flowTypes';

const validations = [
    { validation: lengthInputValidation, options: { min: 1, max: 100 } },
    { validation: urlPathInputValidation },
    { validation: blackListInputValidation, options: { list: [PAGE_INDEX_URL, PAGE_INDEX_HTML_URL] } },
    { validation: isDuplicatePageUrlPathInputValidation },
    { validation: containsHtmlExtensionInURL },
    { validation: containsInvalidCharactersInURL }
];

const inputReducer = makeInputReducer({ actionType: PAGE_INFO_URL_CHANGE, validations });

export default (state: InputState, action: Action) => inputReducer(
    state,
    {
        ...action,
        payload: decodeWebspaceUri(action.payload),
    },
);
