import makeEpic from "../../../../epics/makeEpic";
import { DOCUMENT_COMPONENT_VAT } from "./documentComponentVAT";
import { FC_TOGGLE_RESOURCE_SELECTION } from "../../../../redux/modules/children/fileChooser/actionTypes";
import {
    selectFileDocumentAction,
    SAVE_DOCUMENT_CONFIG_ACTION,
    UPDATE_DOCUMENT_CONFIG_ACTION
} from "../actions";
import Resource from "../../../../redux/modules/children/fileChooser/Resource";
import { componentConfigurationCompleteAction } from "../../../../redux/modules/children/workspace/actionCreators";
import { closeDialogAC } from "../../../App/actionCreators/closeDialog";

export const documentComponentEpic = makeEpic({
    defaultState: null,
    valueActionType: DOCUMENT_COMPONENT_VAT,
    updaters: [
        {
            conditions: [FC_TOGGLE_RESOURCE_SELECTION],
            reducer: ({ state, values: [{ resource }] }) => ({
                state,
                actionToDispatch: selectFileDocumentAction(resource)
            })
        },
        {
            conditions: [SAVE_DOCUMENT_CONFIG_ACTION],
            reducer: ({ state, values: [selection] }) => {
                const src = selection instanceof Resource
                    ? selection.getWebSpaceProtocolRelativePath()
                    : selection.value;

                return {
                    state,
                    actionToDispatch: componentConfigurationCompleteAction({ settings: { src } })
                };
            }
        },
        {
            conditions: [UPDATE_DOCUMENT_CONFIG_ACTION],
            reducer: ({ state }) => ({
                state,
                actionToDispatch: closeDialogAC()
            })
        }
    ]
});
