import React from 'react';
import { connect } from 'react-redux';
import Button from '../../../src/view/common/Button/Button';
import { demoBuyButtonClickAction } from '../actions';
import type { MsgJointInput } from '../../../src/view/intl/index';
import { SAVE_CLICKED } from "../../../src/components/App/actionTypes";
import { calculateUpgradeTierBeforeSeamlessUpgrade } from "../../../src/components/ComponentTierManager/actionCreators";

type Props = {
    className: string,
    dispatch: Dispatch,
    label?: MsgJointInput
};

class DemoBuyBtnClass extends React.Component<Props> {
    static defaultProps = {
        label: 'msg: common.buyNow {Buy now}',
    };

    onClick = () => {
        this.props.dispatch({ type: SAVE_CLICKED });
        this.props.dispatch(calculateUpgradeTierBeforeSeamlessUpgrade("trial:buynow", "", "", false));
        this.props.dispatch(demoBuyButtonClickAction());
    };

    render() {
        return (
            <Button className={this.props.className} onClick={this.onClick} isIntl>
                {this.props.label}
            </Button>
        );
    }
}

export const DemoBuyBtn = connect(null)(DemoBuyBtnClass);
