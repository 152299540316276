import React from "react";
import { getIFrameLink } from "../utils";

export const GoogleMapsPreview = props => {
    let { height, zoom, locale, addressText, addressLocation, addressUrl } = props;

    if (!addressText && props?.siteSettings?.generalData) {
        let generalData = props?.siteSettings?.generalData;
        addressText = generalData.address;
        addressLocation = generalData.addressLocation;
        addressUrl = generalData.addressUrl;
    }

    return (
        <iframe
            src={getIFrameLink({ addressText, addressLocation, addressUrl, locale, zoom })}
            width="100%"
            height={height}
            frameBorder="0"
            allowFullScreen
        />
    );
};
