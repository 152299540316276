import { withSelector, receiveOnly } from "../../../../../epics/makeCondition";
import { tableEditModeVAT } from "./valueActionType";

const
    TableEditModeCellIndexSelector = withSelector(
        tableEditModeVAT,
        ({ cellInEditModeIndex }) => cellInEditModeIndex
    ),
    TableCellIsInEditModeSelector = withSelector(
        tableEditModeVAT,
        ({ cellInEditModeIndex }) => cellInEditModeIndex !== -1
    ),
    TableFirstSelectedCellIndexSelector = withSelector(
        tableEditModeVAT,
        ({ selectedCellsIndexes }) => (selectedCellsIndexes.length === 0 ? 0 : selectedCellsIndexes[0])
    ),
    ROTableCellInEditModeIndexSelectorFromFullAction = receiveOnly(
        tableEditModeVAT,
        ({ payload: { cellInEditModeIndex } }) => cellInEditModeIndex
    );

export {
    TableEditModeCellIndexSelector,
    TableCellIsInEditModeSelector,
    TableFirstSelectedCellIndexSelector,
    ROTableCellInEditModeIndexSelectorFromFullAction
};
