import React from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';

import styles from './WhatsNewDialog.css';
import { Msg, useIntl } from '../../../view/intl/index';
import type { WhatsNewCombinedItem } from '../types';
import { whatsNewAppSel } from '../selectors';
import Scrollbar from '../../../view/common/Scrollbar/index';
import { isWhatsNewItem } from '../functions/isWhatsNewItem';
import { closeWhatsNewDialogAction } from '../actions';
import { getAppConfig } from "../../App/epics/appConfig/appConfig";
import { makeItemMsg } from "../functions/makeItemMsg";
import { isPartnerCheckDomain, isPartnerHostnet } from '../../ComponentTierManager/utils';
import { subscriptionDataSelector } from '../../App/epics/subscriptionData/selectors';

const configHideWhatsNewLearnMore = pathOr(
    false,
    ['oneWeb', 'topBar', 'whatsNew', 'learnMore', 'hide'],
    getAppConfig()
);

export const WhatsNewDialog = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const whatsNewState = useSelector(whatsNewAppSel);
    const subscriptionData = useSelector(subscriptionDataSelector);

    const { list, latestTimestamp } = whatsNewState;
    const { subscriptionType } = subscriptionData;

    const onCloseClick = () => {
        dispatch(closeWhatsNewDialogAction());
    };

    return (
        <div className={styles.container} data-testid="whats-new-dialog">
            <header className={styles.header}>
                <Msg k="common.whatsNew" className={styles.headerTab}>What's new</Msg>
                <span
                    data-testid="whats-new-dialog-close-icon"
                    className={styles.headerCloseBtn}
                    onClick={onCloseClick}
                />
            </header>
            <div className={styles.body}>
                <Scrollbar height="100%">
                    <ul className={styles.list}>
                        {list.map((item: WhatsNewCombinedItem) => {
                            const isNewItem = isWhatsNewItem(item.releaseDate, latestTimestamp);
                            let learnMoreLink = item.learnMoreLink
                                ? intl.msgJoint(item.learnMoreLink)
                                : false;
                            if (
                                (isPartnerCheckDomain() && parseInt(item.id, 10) === 46) ||
                                (item.disableLinkForPartners && (isPartnerHostnet() || isPartnerCheckDomain()))
                            ) {
                                learnMoreLink = false;
                            }
                            const showLearnMoreLink = learnMoreLink && !configHideWhatsNewLearnMore;
                            return (
                                <li className={cx({ [styles.newItem]: isNewItem })} key={item.id} data-testid="whats-new-item">
                                    <div className={styles.itemDate}>
                                        {/* @ts-ignore */}
                                        {intl.date(item.releaseDate, 'LL')}
                                    </div>
                                    <div className={styles.itemTitle}>
                                        {makeItemMsg({
                                            item,
                                            prop: 'title',
                                            dispatch,
                                            linkClassName: styles.learnMoreLink,
                                            intl
                                        })}
                                    </div>
                                    <div className={styles.itemDescription}>
                                        {makeItemMsg({
                                            item,
                                            prop: 'description',
                                            dispatch,
                                            linkClassName: styles.learnMoreLink,
                                            disabledLinkClassName: styles.disabledLink,
                                            intl,
                                            subscriptionType
                                        })}
                                    </div>
                                    {showLearnMoreLink && learnMoreLink && (
                                        <div className={styles.itemLearnMore} data-testid="whats-new-learnmore-link">
                                            <a
                                                className={styles.learnMoreLink}
                                                href={learnMoreLink}
                                                target="_blank"
                                            >
                                                <Msg k="common.learnMore">Learn more</Msg>
                                            </a>
                                        </div>
                                    )}
                                    <span className={styles.itemNewIndicator} />
                                </li>
                            );
                        })}
                    </ul>
                </Scrollbar>
            </div>
        </div>
    );
};
