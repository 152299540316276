// @ts-nocheck
import type { BBox } from "../../redux/modules/children/workspace/flowTypes";

export default function getBBoxesIntersection(bbox1: BBox, bbox2: BBox): BBox {
    const
        t: number = Math.max(bbox1.top, bbox2.top),
        r: number = Math.min(bbox1.right, bbox2.right),
        b: number = Math.min(bbox1.bottom, bbox2.bottom),
        l: number = Math.max(bbox1.left, bbox2.left);

    if (b >= t && r >= l) {
        return {
            top: t,
            left: l,
            bottom: b,
            right: r
        };
    }
    return false;
}
