import React from 'react';
import { Flex } from '../../../../../view/reflexbox/index';
import { TextHorizontalAlignmentButtonsGroup } from '../../../../presentational/IconButtonsGroup/TextAlignmentButtonGroup/index';  // eslint-disable-line max-len
import Page from "../../../../PropertiesPanel/view/PropertiesPage";
import ComboBox from '../../../../../view/common/Combobox/IntlCombobox';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import { PropertiesButton } from '../../../../../view/common/Button/index';
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';
import PropertiesCheckBox from '../../../../../view/common/CheckBox/PropertiesCheckBox';
import * as actionTypes from '../../actionTypes';
import * as pageIds from '../pageIds';
import pageTitles from '../pageTitles';
import { captionPlacementOptions } from '../constants';
import type { PageProps } from '../../../../PropertiesPanel/flowTypes';
import type { ImageSliderComponent } from '../../flowTypes';

const
    id = pageIds.CAPTIONS,
    title = pageTitles[id],
    manageCaptionsLabel = "msg: component.imageSlider.pp.caption.helpText {Click \"Manage captions\", then select each image and add a caption.}", // eslint-disable-line max-len
    view = ({
        selectedComponent: { captionsEnabled, captionsAlignment, captionsPlacement },
        dispatch
    }: PageProps<ImageSliderComponent>) => (
        <Page>
            <PropertiesCheckBox
                isChecked={captionsEnabled}
                label="msg: component.imageSlider.pp.captions.enableCaptions {Show captions}"
                onClick={() => dispatch({ type: actionTypes.IMAGE_SLIDER_TOGGLE_CAPTIONS })}
            />
            {
                captionsEnabled &&
                <div>
                    <VerticalSpacer />
                    <PropertyContainer label={manageCaptionsLabel}>
                        <PropertiesButton
                            text="msg: component.imageSlider.pp.caption.manageCaptions {Manage captions}"
                            onClick={() => dispatch({ type: actionTypes.IMAGE_SLIDER_PP_MANAGE_LINKS_CLICK })}
                        />
                    </PropertyContainer>
                    <VerticalSpacer y={10} />
                    <PropertyContainer label="msg: component.imageSlider.pp.captions.alignment {Alignment:}">
                        <Flex>
                            <TextHorizontalAlignmentButtonsGroup
                                value={captionsAlignment}
                                onBtnClick={alignment => dispatch({
                                    type: actionTypes.IMAGE_SLIDER_CAPTIONS_ALIGNMENT_CHANGED,
                                    payload: alignment
                                })}
                                isPropertiesPanel
                            />
                        </Flex>
                    </PropertyContainer>
                    <VerticalSpacer y={10} />
                    <PropertyContainer label="msg: component.imageSlider.pp.captions.placement {Placement:}">
                        <ComboBox
                            searchable={false}
                            options={captionPlacementOptions}
                            value={captionsPlacement}
                            onChange={({ value }) => dispatch({
                                type: actionTypes.IMAGE_SLIDER_CAPTIONS_PLACEMENT_CHANGED,
                                payload: value
                            })}
                        />
                    </PropertyContainer>
                </div>
            }
        </Page>
    );

export { id, title, view };
