import kind from '../../kind';
import makeEpic from '../../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import {
    FEATURED_PRODUCTS_CTA_CLICKED,
    FEATURED_PRODUCTS_UPDATE_MOBILE_PREVIEW,
    FEATURED_PRODUCTS_UPDATE_PRODUCTS,
    FEATURED_PRODUCTS_UPDATE_PRODUCTS_PROPERTY,
    FEATURED_PRODUCTS_UPDATE_NEW_HEIGHT,
    FEATURED_PRODUCTS_UPDATE_WIDTH,
    FEATURED_PRODUCTS_UPDATE_PRODUCTS_PER_ROW,
    FEATURED_PRODUCTS_UPDATE_TIMESTAMP,
    FEATURED_PRODUCTS_UPDATE_SHOP_COMPONENT_EXISTS,
    FEATURED_PRODUCTS_COMPONENT_DID_MOUNT,
} from '../../actionTypes';
import { shopPageIdsListVat } from '../../../WebShop/epics/shopPageIdsList/valueActionType';
import {
    MOUSE_UP_ON_PREVIEW_BUTTON,
    MOBILE_PREVIEW,
    DESKTOP_PREVIEW,
    BACK_TO_EDITOR
} from "../../../../App/actionTypes";
import { SHOW_FEATURED_PRODUCTS_SELECTOR_DIALOG } from '../../SelectorDialog/actionTypes';
import { ReceiveOnlySelectedComponentSelector } from "../../../../Workspace/epics/componentsEval/selectorActionTypes";
import { getProductsCount, getHeight, getWidth, getProductsPerRow } from '../../utils';
import {
    BACK_TO_DESKTOP_EDITOR_FROM_MOBILE_EDITOR,
} from '../../../../MobileViewEditor/actionTypes';

const updateMobilePreview = val =>
    ({ type: FEATURED_PRODUCTS_UPDATE_MOBILE_PREVIEW, payload: { mobilePreview: val } });

const replaceProductsAction = (selectedComponent) => {
    let featuredProducts = [];
    if (selectedComponent && selectedComponent.kind === kind && selectedComponent.products) {
        featuredProducts = selectedComponent.products.split(',')
            .map((productEntry) => {
                return productEntry
                    .replace('product:', '')
                    .replace(/'/g, '')
                    .trim();
            });
    }
    return {
        type: SHOW_FEATURED_PRODUCTS_SELECTOR_DIALOG,
        payload: { featuredProducts }
    };
};

const getUpdateShopComponentExistsAction = (shopPageIds) => {
    const actionToDispatch = { type: FEATURED_PRODUCTS_UPDATE_SHOP_COMPONENT_EXISTS, payload: { shopComponentExists: false } };
    if (Array.isArray(shopPageIds) && shopPageIds.length > 0) {
        actionToDispatch.payload = { shopComponentExists: true };
    }
    return actionToDispatch;
};

export default makeEpic({
    defaultState: {},
    valueActionType,
    updaters: [
        {
            conditions: [
                MOUSE_UP_ON_PREVIEW_BUTTON,
                MOBILE_PREVIEW
            ],
            reducer: ({ state }) => {
                const actionToDispatch = updateMobilePreview(true);
                return { state, actionToDispatch };
            }
        },
        {
            conditions: [
                DESKTOP_PREVIEW
            ],
            reducer: ({ state }) => {
                const actionToDispatch = updateMobilePreview(false);
                return { state, actionToDispatch };
            }
        },
        {
            conditions: [
                BACK_TO_EDITOR
            ],
            reducer: ({ state }) => {
                const actionToDispatch = updateMobilePreview(false);
                return { state, actionToDispatch };
            }
        },
        {
            conditions: [
                ReceiveOnlySelectedComponentSelector,
                FEATURED_PRODUCTS_CTA_CLICKED
            ],
            reducer: ({ values: [selectedComponent] }) => {
                return { state: null, actionToDispatch: replaceProductsAction(selectedComponent) };
            }
        },
        {
            conditions: [
                ReceiveOnlySelectedComponentSelector,
                FEATURED_PRODUCTS_UPDATE_PRODUCTS
            ],
            reducer: ({ values: [selectedComponent, { products }] }) => {
                if (selectedComponent && selectedComponent.kind === kind) {
                    const productsCount = getProductsCount(products);
                    const height = getHeight(productsCount, selectedComponent.productsPerRow);
                    const width = getWidth(productsCount, selectedComponent.width);
                    const productsPerRow = getProductsPerRow(productsCount, selectedComponent.productsPerRow);

                    let multipleActionsToDispatch: Action[] = [
                        { type: FEATURED_PRODUCTS_UPDATE_TIMESTAMP, payload: { timestamp: Date.now() } },
                        {
                            type: FEATURED_PRODUCTS_UPDATE_PRODUCTS_PROPERTY,
                            payload: { componentId: selectedComponent.id, products }
                        }
                    ];

                    if (!selectedComponent.isSingleProduct) {
                        multipleActionsToDispatch = [
                            ...multipleActionsToDispatch,
                            {
                                type: FEATURED_PRODUCTS_UPDATE_NEW_HEIGHT,
                                payload: { componentId: selectedComponent.id, newHeight: height }
                            },
                            {
                                type: FEATURED_PRODUCTS_UPDATE_WIDTH,
                                payload: { componentId: selectedComponent.id, width }
                            },
                            {
                                type: FEATURED_PRODUCTS_UPDATE_PRODUCTS_PER_ROW,
                                payload: { componentId: selectedComponent.id, productsPerRow }
                            }
                        ];
                    }

                    return {
                        state: null,
                        multipleActionsToDispatch
                    };
                }
                return { state: null };
            }
        },
        {
            conditions: [BACK_TO_DESKTOP_EDITOR_FROM_MOBILE_EDITOR],
            reducer: ({ state }) => {
                const actionToDispatch = { type: FEATURED_PRODUCTS_UPDATE_TIMESTAMP, payload: { timestamp: Date.now() } };
                return {
                    state,
                    actionToDispatch
                };
            }
        },
        {
            conditions: [
                FEATURED_PRODUCTS_COMPONENT_DID_MOUNT,
                shopPageIdsListVat,
            ],
            reducer: ({ state, values }) => {
                const shopPageIds = values[1];
                const actionToDispatch = getUpdateShopComponentExistsAction(shopPageIds);
                return {
                    state,
                    actionToDispatch
                };
            }
        },
    ]
});
