import React from "react";
import { DemoLoginMessages } from '../../main/messages';
import styles from "./MobileBlockingPage.css";
import VerticalSpacer from "../../../../../src/view/common/VerticalSpacer";
import type { MobileBlockingPagePropTypes } from "../../types";
import { isMobileDevice } from "../../validators";
import { showSignInPage } from "../../signUp/actions";

const MobileBlockingPage = ({
    intl,
    dispatch
}: MobileBlockingPagePropTypes) => {
    const {
        welcomeFreeTrialMsg,
        mobileTrialBuildWebsiteMsg,
        mobileTrialTouchDeviceMsg,
        mobileTrialComputerUseMsg
    } = DemoLoginMessages;

    if (!isMobileDevice()) {
        dispatch(showSignInPage());
    }
    return (
        <div className={styles.mobileBlockingIconContainer}>
            <span className={styles.mobileBlockingIcon} />
            <VerticalSpacer y={37} />
            <div className={styles.welcomeFreeTrialMsg}>
                {intl.msgJoint(welcomeFreeTrialMsg)}
            </div>
            <VerticalSpacer y={26} />
            <div className={styles.mobileBlockingMsg}>
                <span> {intl.msgJoint(mobileTrialBuildWebsiteMsg)} </span>
                <span> {intl.msgJoint(mobileTrialTouchDeviceMsg)}</span>
                <span> {intl.msgJoint(mobileTrialComputerUseMsg)} </span>
            </div>
        </div>
    );
};

export {
    MobileBlockingPage
};
