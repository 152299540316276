import { WidgetsGoogleMapsKind } from './kind';
import { calcRenderProps } from './calcRenderProps';
import { GoogleMapsPreview } from './view/preview';

// See wbtgen/src/view/oneweb/registry/preview.js
const WidgetsGoogleMapsPreviewConfig = {
    kind: WidgetsGoogleMapsKind,
    view: GoogleMapsPreview,
    calcProps: ({ component }) => calcRenderProps({ component, componentExtension: { isTransient: false } }),
};

export { WidgetsGoogleMapsPreviewConfig };
