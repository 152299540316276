import * as PagesIds from './ids';
import * as mobileView from '../../../../PropertiesPanel/view/MobileView/page';
import { LOGO_KIND } from "../../kind";
import { ComponentNames } from "../../../constants";

export default {
    [PagesIds.MAIN]: ComponentNames[LOGO_KIND],
    [PagesIds.TITLE_STYLE]: 'msg: common.titleStyle {Title style}',
    [mobileView.id]: mobileView.title
};
