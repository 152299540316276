/* eslint-disable camelcase */
import { getDomainLink } from '../getDomainLink';
import { openingHoursWithMsTimeToGmbTime } from '../../../../oneweb/OpeningHours/utils';
import type { GmbCreateDataType } from "../../flowTypes";
import { validateOnlyEnum } from "../../flowTypes";
import type { GeneralGlobalData } from "../../../General/generalGlobalDataEpic/generalGlobalDataEpic";
import type { PublishConfig } from '../../../../App/epics/siteSettings/flowTypes';
/*:: import type { CountryFormat } from '../../../../../../../server/shared/address/types' */

const { getCountryFormat } = require('../../../../../../../server/shared/address/getCountryFormat.js');

type WsbDataType = {
    generalData: GeneralGlobalData,
    publishConfig: PublishConfig,
};

const WsbToGmb = ({
    wsbData,
    validateOnly,
    removeRegularHours,
}: {
    wsbData: WsbDataType,
    validateOnly?: boolean,
    removeRegularHours?: boolean,
}): GmbCreateDataType => {
    const
        {
            generalData: {
                // addressUrl,
                addressName,
                // addressPlaceId,
                addressLocation,
                // addressViewport: { south: 0, west: 0, north: 0, east: 0 },
                addressStreetAddress = '',
                addressCity,
                addressArea,
                addressZip,
                addressCountryCode,
                gmbCategories = '',
                // addressFloor: '',
                phoneNumber,
                openingHours = [],
            },
            publishConfig: { publishWithSSL },
        } = wsbData,
        // @ts-ignore
        countryFormat: CountryFormat = getCountryFormat(addressCountryCode);

    const
        address: {
            region_code: string | null | undefined,
            locality: string | null | undefined,
            postalCode: string | null | undefined,
            address_lines: Array<string>,
            administrative_area?: string | null | undefined
        } = {
            region_code: addressCountryCode,
            locality: addressCity,
            postalCode: addressZip,
            address_lines: addressStreetAddress.split(',').map((item) => item.trim())
        };
    if (
        countryFormat === 'CountryFormat3' ||
        countryFormat === 'CountryFormat4' ||
        countryFormat === 'CountryFormat5'
    ) {
        /* Refer wbtgen/src/components/SiteSettings/General/renderAddress.js for logic.
        * For this, use 'administrative_area_level_1' or 'administrative_area_level_2' (CountryFormat5) from Google Places data */
        address.administrative_area = addressArea;
    }

    const
        category = gmbCategories.split(',')[0],
        categoryId = category ? 'gcid:' + category : null,
        websiteURL = getDomainLink(publishWithSSL),
        locationObj = {
            languageCode: 'en',

            phoneNumbers: { // NEW-CODE
                primaryPhone: phoneNumber,
                additionalPhones: []
            },
            // primaryPhone: phoneNumber, // OLD-CODE

            websiteUri: websiteURL, // NEW-CODE
            // websiteUrl: websiteURL, // OLD-CODE

            regularHours: removeRegularHours ? {} : { periods: openingHoursWithMsTimeToGmbTime(openingHours) },

            title: addressName, // NEW-CODE
            // location_name: addressName, // OLD-CODE

            storefrontAddress: address, // NEW-CODE
            // address, // OLD-CODE

            // NEW-CODE
            categories: {
                primaryCategory: {
                    name: categoryId
                }
            },
            // OLD-CODE
            // primary_category: {
            //     category_id: categoryId
            // },

            latlng: {
                latitude: addressLocation?.lat,
                longitude: addressLocation?.lng
            }
        };

    const gmbFields = {
        validateOnly: validateOnlyEnum.EMPTY,
        location: JSON.stringify(locationObj)
    };
    if (validateOnly) {
        gmbFields.validateOnly = validateOnlyEnum.YES;
    }

    return gmbFields;
};

export { WsbToGmb };
