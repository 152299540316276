import React from "react";
import { pure } from 'recompose';
import {
    makeCodeComponentsRenderer
} from '../../../components/Workspace/CodeComponentsRenderer/CodeComponentsRenderer';
import ComponentsRenderer from "./ComponentsRenderer";
import Decorations from '../Decorations/index';
import TemplateLines from "./TemplateLines/index";
import HandlesOverlay from '../../../components/Debug/HandlesOverlay';
import MousePositionCrossWithRespectToTemplateArea from '../../../components/Debug/MousePositionCrossWithRespectToTemplateArea';
import { showVirtualHandles, showMousePositionWithRespectToTemplateArea } from "../../../utils/isDebug";
import ErrorBoundary from "../../../redux/recoverAfterException/ErrorBoundary/ErrorBoundary";
import { CodeComponentHostKinds } from "../../../components/Workspace/CodeComponentsRenderer/codeComponentsHostKinds";
import * as codeComponentsRendererStyles from "../../../components/Workspace/CodeComponentsRenderer/CodeComponentsRenderer.css";
import Msg from "../../intl/Msg";
import wsStyles from '../Workspace.css';
import {
    GHOST_COMPONENTS_BODY_END_SECTION_MOUSE_LEAVE,
    GHOST_COMPONENTS_BODY_END_SECTION_MOUSE_OVER,
    GHOST_COMPONENTS_HEAD_SECTION_MOUSE_LEAVE,
    GHOST_COMPONENTS_HEAD_SECTION_MOUSE_OVER
} from "../../../components/Workspace/actionTypes";
import { SectionDropZone, SectionDropZoneHeight } from "./SectionDropZone/dropZone";

const
    HeadTemplateCodeComponentRenderer = makeCodeComponentsRenderer({ kind: CodeComponentHostKinds.headTemplate }),
    HeadPageCodeComponentRenderer = makeCodeComponentsRenderer({ kind: CodeComponentHostKinds.headPage }),
    BodyTemplateCodeComponentRenderer = makeCodeComponentsRenderer({ kind: CodeComponentHostKinds.bodyTemplate }),
    BodyPageCodeComponentRenderer = makeCodeComponentsRenderer({ kind: CodeComponentHostKinds.bodyPage }),

    DragComponentsHereToExpandBox = ({ isHideDragBox }) => (
        <div className={wsStyles.dragComponentHereToExpandBox}>
            { !isHideDragBox && <Msg k="dragComponentsHereToExpandBoxMessage">Drag components here to expand the height</Msg> }
        </div>
    );

export default pure(props => {
    const
        { inProgress: showDropZone, placeholderPosition: dropZonePosition } = props.sectionInsertion,
        { dndAddComponent: { isDroppable }, scroll: { hasHorizontalScroll }, codeComponentsHeadSectionHeight } = props.decorationsProps,
        width = props.templateWidth,
        left = props.templateRenderOffset.x,
        codeComponentAreaStyle: React.CSSProperties = {
            width,
            left,
            position: 'relative'
        },
        templateAreaStyle = {
            ...codeComponentAreaStyle,
            height: showDropZone ? (props.height + SectionDropZoneHeight) : props.height
        },
        subscriptionType = props.subscriptionType,
        selectedComponentsIds = props.selectedComponentsIds,
        isWebShopFooterStripCmpsExists = props.isWebShopFooterStripCmpsExists;

    return (
        <div>
            <div className={codeComponentsRendererStyles.container}>
                <div
                    style={codeComponentAreaStyle}
                    onMouseOver={() => props.dispatch({ type: GHOST_COMPONENTS_HEAD_SECTION_MOUSE_OVER })}
                    onMouseLeave={() => props.dispatch({ type: GHOST_COMPONENTS_HEAD_SECTION_MOUSE_LEAVE })}
                >
                    <ErrorBoundary invisible> <HeadTemplateCodeComponentRenderer /> </ErrorBoundary>
                    <ErrorBoundary invisible> <HeadPageCodeComponentRenderer /> </ErrorBoundary>
                </div>
            </div>
            <div style={templateAreaStyle}>
                <ComponentsRenderer
                    components={props.components}
                    componentsDependencies={props.componentsDependencies}
                    componentsProps={props.componentsProps}
                    editModeComponentId={props.editModeComponentId}
                    stylesheetsIdToNameMap={props.stylesheetsIdToNameMap}
                    attachments={props.attachments}
                    dispatch={props.dispatch}
                    subscriptionType={subscriptionType}
                    selectedComponentsIds={selectedComponentsIds}
                />
                <ErrorBoundary invisible> <Decorations {...props.decorationsProps} /> </ErrorBoundary>
                {
                    !props.decorationsProps.showAutoColorLeftPanel &&
                    // @ts-ignore
                    <ErrorBoundary invisible> <TemplateLines /> </ErrorBoundary>
                }
                {
                    showVirtualHandles() && <HandlesOverlay />
                }
                {
                    showMousePositionWithRespectToTemplateArea() && <MousePositionCrossWithRespectToTemplateArea />
                }
            </div>
            <DragComponentsHereToExpandBox
                isHideDragBox={props.isModernLayoutActivated || isWebShopFooterStripCmpsExists}
            />
            <ErrorBoundary invisible> {
                showDropZone && <SectionDropZone
                    dropZonePosition={dropZonePosition + (codeComponentsHeadSectionHeight || 0)}
                    isDroppable={isDroppable}
                    hasHorizontalScroll={hasHorizontalScroll}
                />
            }
            </ErrorBoundary>
            <div className={codeComponentsRendererStyles.container}>
                <div
                    style={codeComponentAreaStyle}
                    onMouseOver={() => props.dispatch({ type: GHOST_COMPONENTS_BODY_END_SECTION_MOUSE_OVER })}
                    onMouseLeave={() => props.dispatch({ type: GHOST_COMPONENTS_BODY_END_SECTION_MOUSE_LEAVE })}
                >
                    <ErrorBoundary invisible> <BodyTemplateCodeComponentRenderer /> </ErrorBoundary>
                    <ErrorBoundary invisible> <BodyPageCodeComponentRenderer /> </ErrorBoundary>
                </div>
            </div>
        </div>
    );
});
