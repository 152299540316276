import React from "react";
import cx from "classnames";
import * as R from 'ramda';
import { Box, Flex } from "../../../view/reflexbox/index";
import { getAllStylesheets, getStylesByType } from '../../Workspace/epics/stylesheets/selectors';
import Scrollbar from '../../../view/common/Scrollbar/index';
import TextGlobalStyleKind from "../../oneweb/Text/globalStyle/kind";
import BaseComponentPreview from "../../../view/oneweb/BaseComponentPreview";
import { injectIntl, Msg } from '../../../view/intl/index';
import { textTabLabel, globalStyleLabels, types } from './constants';
import {
    getAllActionsForStyle,
    getPreviewBackgroundCss
} from './utils';
import type { TextStylesheet } from '../../Workspace/epics/stylesheets/flowTypes';
import type { TextGlobalstyleDialogProps } from '../flowTypes';
import styles from "../GlobalstylesDialog.css";
import { renderTextStyleSection } from "./renderTextStyleSection";
import { getThemeRulesForBackground } from "../../ThemeGlobalData/themeRules";
import { BACKGROUND_THEME_WHITE } from "../../ThemeGlobalData/constants";
import * as colorMapper from "../../../mappers/color";

export const
    Tab = injectIntl(({ intl }) => (<div id="text-globalstyles-tab">{intl.msgJoint(textTabLabel)}</div>)),
    TabPanel = injectIntl(({
        scrollHeight,
        previewScrollHeight,
        stylesheets,
        stylesheetsIdToNameMap,
        siteFonts,
        intl,
        themeSettingsData,
        themeColorsData,
        dispatch
    }: TextGlobalstyleDialogProps) => {
        const
            { autoColorMode } = themeSettingsData,
            textThemeColor = themeColorsData[getThemeRulesForBackground(BACKGROUND_THEME_WHITE, themeColorsData).text],
            renderPreviewThemeStyle = autoColorMode ? { color: colorMapper.toCss(textThemeColor) } : {},
            nonLogoTextStyles = R.pipe(
                getAllStylesheets,
                getStylesByType(TextGlobalStyleKind),
                R.filter(({ ref }) => ref !== types.LOGO)
            )(stylesheets),
            renderPreview = (id, ref, color, stylesheetsIdToNameMap, index) => (
                <BaseComponentPreview
                    key={index}
                    globalStyleIds={[stylesheetsIdToNameMap[id]]}
                    className={cx(styles.textPreview, getPreviewBackgroundCss(
                        autoColorMode ? textThemeColor : color
                    ))}
                    style={renderPreviewThemeStyle}
                >
                    {intl.msgJoint(globalStyleLabels[ref])}
                </BaseComponentPreview>
            );

        return (
            <Box className={styles.tabPanelContents}>
                <Flex className={cx(styles.borderBottom, styles.infoBar)} align="center" justify="flex-start">
                    <Msg k="gs.text.styleTextTitle">
                        Create predefined text styles to use across your pages.
                    </Msg>
                </Flex>
                <Flex className={cx(styles.tabPanel, styles.styleSelectorsStylePreview)}>
                    <Box className={styles.styleSelectors}>
                        <Scrollbar height={`${scrollHeight}px`}>
                            {
                                nonLogoTextStyles.map((style: TextStylesheet, index: number) => renderTextStyleSection({
                                    data: style,
                                    actions: getAllActionsForStyle(style.ref),
                                    index,
                                    dispatch,
                                    siteFonts,
                                    intl,
                                    autoColorMode
                                }))
                            }
                        </Scrollbar>
                    </Box>
                    <Box className={cx(styles.borderLeft, styles.stylePreview)}>
                        <div className={styles.previewLabel}>{intl.msgJoint('msg: common.preview.noun {Preview}')}</div>
                        <Scrollbar height={`${previewScrollHeight}px`}>
                            <Box className={styles.preview} align="center" justify="flex-start">
                                {nonLogoTextStyles.map(({ id, ref, color }, index) =>
                                    renderPreview(id, ref, color, stylesheetsIdToNameMap, index))}
                            </Box>
                        </Scrollbar>
                    </Box>
                </Flex>
            </Box>
        );
    });
