import React from 'react';

type ConsentBannerScriptProps = {
    consentBannerData: null | undefined | {
       settings: Record<string, any>;
       config: Record<string, any>;
    },
};

export function ConsentBannerScript({ consentBannerData }: ConsentBannerScriptProps) {
    if (!consentBannerData) {
        return null;
    }

    const { settings, config } = consentBannerData;
    const autoBlock = settings.autoBlock === "off" ? "off" : "on";
    const bannerScriptUrl = config.bannerScriptUrl.replace("{uuid}", settings.uuid).replace("{autoBlock}", autoBlock);
    const customBlockingMap = config.whitelistDomains.reduce((map, domain) => ({ ...map, [domain]: 'essential' }), {});

    return (
        <React.Fragment>
            <link href="/renderStatic/consentBanner/consentBanner.css" rel="stylesheet" type="text/css" />
            <script
                data-termly-config
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `
                        window.TERMLY_CUSTOM_BLOCKING_MAP = ${JSON.stringify(customBlockingMap)};
                        window.TERMLY_AUTOBLOCK_ENABLED = ${autoBlock === "on"};
                    `
                }}
            />
            <script src="/renderStatic/consentBanner/consentBanner.js" />
            <script type="text/javascript" src={bannerScriptUrl} data-termly-onload="onTermlyLoaded();" />
        </React.Fragment>
    );
}
