import * as R from 'ramda';
import * as workspaceActionTypes from '../../actionTypes';
import { getMaxOrderIndex } from "../../../../redux/modules/children/workspace/reducers/utils/index";
import * as updateReasons from './updateReasons';
import type { ComponentsEvalEpicUpdater } from './flowTypes';
import {
    getSelectedComponentIdsAlongWithWrappedIds
} from "./selectors";
import { getAllAttachmentsForCmpIds, removeDuplicatesFromAnArrayOfStrings, getTopMostParentId } from "../componentAttachements/util";
import { componentAttachmentsVAT } from "../componentAttachements/valueActionType";
import { receiveOnly } from "../../../../epics/makeCondition";
import { isVerticalMenu, isHorizontalMenu } from '../../../oneweb/Menu/utils';

const
    makeUpdater = (updateActionType, inTemplate): ComponentsEvalEpicUpdater => ({
        conditions: [receiveOnly(componentAttachmentsVAT), updateActionType],
        reducer: ({ state: epicState, values: [{ attachments }] }) => {
            const
                oldCmpsMap = epicState.state.componentsMap,
                selectedComponentIds = getSelectedComponentIdsAlongWithWrappedIds(epicState),
                componentIdsToProcess = removeDuplicatesFromAnArrayOfStrings([
                    ...getAllAttachmentsForCmpIds(attachments, selectedComponentIds),
                    ...selectedComponentIds
                ]),
                maxOrderIndex = getMaxOrderIndex(epicState.state.componentsMap),
                newEpicState = R.evolve({
                    state: {
                        componentsMap: (oldComponentsMap) => {
                            let componentsMap = { ...oldComponentsMap };

                            const sortedSelectedComponents = R.sort(
                                (a, b) => componentsMap[a].orderIndex - componentsMap[b].orderIndex,
                                componentIdsToProcess
                            );

                            sortedSelectedComponents.forEach((componentId, index) => {
                                componentsMap[componentId] = {
                                    ...componentsMap[componentId],
                                    inTemplate,
                                    orderIndex: maxOrderIndex + index + 1
                                };
                                if (isVerticalMenu(componentsMap[componentId])) {
                                    const isStickyToHeader = inTemplate &&
                                        !componentsMap[getTopMostParentId(componentId, attachments)].inTemplate;
                                    componentsMap[componentId] = {
                                        ...componentsMap[componentId],
                                        isStickyToHeader,
                                    };
                                } else if (isHorizontalMenu(componentsMap[componentId])) {
                                    componentsMap[componentId] = {
                                        ...componentsMap[componentId],
                                        isStickyToHeader: false,
                                    };
                                }
                            });

                            return componentsMap;
                        }
                    }
                }, epicState),
                actionToDispatch: AnyAction = {
                    type: updateReasons.MOVED_TO_TEMPLATE_OR_PAGE,
                    payload: { selectedComponentIds: componentIdsToProcess, oldCmpsMap }
                };

            return {
                state: newEpicState,
                updateReason: updateReasons.MOVED_TO_TEMPLATE_OR_PAGE,
                actionToDispatch
            };
        }
    });

export const
    toPageUpdater = makeUpdater(workspaceActionTypes.MOVE_SELECTED_COMPONENTS_TO_PAGE, false),
    toTemplateUpdater = makeUpdater(workspaceActionTypes.MOVE_SELECTED_COMPONENTS_TO_TEMPLATE, true);
