import React, { useState } from "react";
import cx from "classnames";
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import styles from "./styles.css";
import { Msg } from "../../../view/intl/index";
import { PrimaryButton } from "../../../view/common/Button/index";
import { OnboardingTopBar } from "../../TopBar/view/OnboardingTopBar";
import WelcomeStepRightSideView from "./LottieAnimations/WelcomeStepRightSideView";
import stepStyles from "./WelcomeStep.css";
import { INITIATE_WSB_ONBOARDING } from "../actionTypes";

export type WelcomeStepProps = {
    dispatch: Dispatch;
};
export const WelcomeStep = ({ dispatch }: WelcomeStepProps) => {
    const [isLetsDoButtonPressed, setIsLetsDoButtonPressed] = useState(false);
    return (
        <div className={stepStyles.topContainer}>
            <div
                className={cx(stepStyles.topBar, {
                    [stepStyles.topBarWidth]: isLetsDoButtonPressed
                })}
            >
                <OnboardingTopBar />
            </div>
            <div className={styles.contentContainer}>
                <div className={cx(styles.leftContentContainer)}>
                    <div className={styles.flex1} />
                    <div
                        className={cx(styles.leftContent, stepStyles.leftContent, {
                            [stepStyles.hidden]: isLetsDoButtonPressed
                        })}
                    >
                        <div className={cx(styles.question, stepStyles.question)}>
                            <Msg k="onboarding.welcomeTitle">Welcome to Website Builder!</Msg>
                        </div>
                        <VerticalSpacer y={22} />
                        <div className={cx(styles.tip, stepStyles.tip)}>
                            <Msg k="onboarding.welcomeTip">We are here to help you start building the website you\'ll love.</Msg>
                        </div>
                        <VerticalSpacer y={45} />
                        <PrimaryButton
                            className={stepStyles.letsDoItButton}
                            onClick={() => {
                                dispatch({ type: INITIATE_WSB_ONBOARDING, payload: { oldOnboarding: true } });
                                setIsLetsDoButtonPressed(true);
                            }}
                        >
                            <Msg k="common.letsDoIt">Let\'s do it!</Msg>
                        </PrimaryButton>
                    </div>
                    <div className={styles.flex1} />
                </div>
                <div
                    className={cx(styles.rightContentContainer, {
                        [stepStyles.bgColor]: isLetsDoButtonPressed
                    })}
                >
                    <WelcomeStepRightSideView isLetsDoButtonPressed={isLetsDoButtonPressed} dispatch={dispatch} />
                </div>
            </div>
        </div>
    );
};
