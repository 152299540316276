import { WidgetsFacebookKind } from './kind';
import { componentMainActions } from './componentMainAction';
import { propertiesPanel } from './propertiesPanel/index';
import { reducer } from './reducer';
import { configurationDialogId } from './configurationDialog';
import { calcRenderProps } from './calcRenderProps';
import { FacebookWorkspace } from './view/workspace';
import { FacebookView } from './view/index';
import { getMVEHiddenElementData } from '../mobileEditorConfig';
import { WidgetsFacebookConfig } from '../constants';

const WidgetsFacebook = {
    kind: WidgetsFacebookKind,
    label: WidgetsFacebookConfig.title,
    shortcutIconName: 'widgetsFacebook',
    workspace: FacebookWorkspace,
    view: FacebookView,
    requireConfigurationOnDrop: () => true,
    configurationDialog: configurationDialogId,
    calcRenderProps,
    componentMainActions,
    propertiesPanel,
    reducer,
    mobileEditorConfig: {
        kind: WidgetsFacebookKind,
        calcProps: calcRenderProps,
        view: FacebookView,
        getHiddenElementData: getMVEHiddenElementData(WidgetsFacebookConfig),
    },
};

export { WidgetsFacebook };
