export const
    ComponentMaskBackground = {
        TRANSPARENT: 'TRANSPARENT',
        LIGHT: 'LIGHT',
        DARK: 'DARK'
    },
    ComponentMaskBackgroundStyle = {
        [ComponentMaskBackground.TRANSPARENT]: 'transparentBackground',
        [ComponentMaskBackground.LIGHT]: 'lightBackground',
        [ComponentMaskBackground.DARK]: 'darkBackground'
    },
    DefaultComponentMaskBackground = ComponentMaskBackground.DARK;
