
import React from 'react';
import * as R from 'ramda';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import * as actionTypes from '../../actionTypes';
import * as pageIds from './ids';
import pagesTitles from "./titles";
import { getAllStylesheets, getFirstButtonStyleId } from '../../../../Workspace/epics/stylesheets/selectors';
import optionMapper from '../../../Button/globalStyle/options';
import * as path from '../../../../../mappers/path';
import injectIntl from '../../../../../view/intl/injectIntl';
import ButtonGlobalStyleKind from "../../../Button/globalStyle/kind";
import { optionRenderer } from "../../../Button/globalStyle/optionRenderer";
import { themeColoroptionRenderer } from "../../../Button/globalStyle/themeColoroptionRenderer";
import PropertyGlobalStyleSelector from '../../../../../view/common/PropertyGlobalstyleSelector/index';
import { MCTA_THEME_OPTIONS_BUTTON } from "../../../../componentMainActions/constants";
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';
import { Flex } from '../../../../../view/reflexbox/index';
import InputField from '../../../../../view/common/Input/PropertiesInputField';
import stylePropertyContainer from '../../../../../view/common/PropertyContainer/PropertyContainer.css';
import lengthInputValidation from "../../../../inputControls/input/commonValidations/lengthInputValidation";
import { MAX_REVIEW_BUTTON_TEXT_LENGTH } from "../../constants";
import CheckBox from '../../../../../view/common/CheckBox/PropertiesCheckBox';
import { BACKGROUND_THEME_WHITE } from '../../../../ThemeGlobalData/constants';

const
    id = pageIds.REVIEW_BUTTON,
    title = pagesTitles[id],
    view = injectIntl(({
        stylesheets,
        stylesheetsIdToNameMap,
        selectedComponent,
        dispatch,
        intl,
        themeSettingsData: { autoColorMode },
    }: any) => {
        const
            styles = getAllStylesheets(stylesheets),
            buttonGlobalStyleId = R.path(path.styleButtonGlobalId, selectedComponent);

        let
            optRenderer,
            options,
            colorThemeOnChangeAction,
            selectedButtonTheme,
            onChangeAction,
            currentStylesheetId;

        if (autoColorMode) {
            currentStylesheetId = getFirstButtonStyleId(stylesheets);
            options = MCTA_THEME_OPTIONS_BUTTON;

            const btnName = stylesheetsIdToNameMap[currentStylesheetId];
            optRenderer = (value, label) => themeColoroptionRenderer(value, label, BACKGROUND_THEME_WHITE, btnName);

            onChangeAction = actionTypes.CHANGE_REVIEW_BUTTON_THEME;
            // TODO: WBTGEN-17574
            colorThemeOnChangeAction = (value) => {
                dispatch({ type: onChangeAction, payload: value });
            };
            selectedButtonTheme = selectedComponent.buttonThemeSelected;
        } else {
            options = optionMapper(styles, intl);
            onChangeAction = actionTypes.CHANGE_REVIEW_BUTTON_GLOBAL_STYLE;
            optRenderer = (value, label) => optionRenderer(value, label, stylesheetsIdToNameMap);
        }

        return (
            <Page>
                <CheckBox
                    label={'msg: component.googleReviews.showReviewButton {Show \"Leave a review\" button}'}
                    disabled={!selectedComponent.reviewButtonText}
                    isChecked={!!selectedComponent.reviewButtonText && selectedComponent.showReviewButton}
                    onClick={() => {
                        dispatch({ type: actionTypes.TOGGLE_LEAVE_REVIEW_BUTTON });
                    }}
                />
                <VerticalSpacer />
                <PropertyContainer
                    label="msg: component.button.buttonText {Button text}"
                    className={stylePropertyContainer.pointer}
                >
                    <Flex>
                        <InputField
                            value={selectedComponent.reviewButtonText || ''}
                            onChange={payload => dispatch({ type: actionTypes.CHANGE_REVIEW_BUTTON_TEXT, payload })}
                            placeholder="msg: common.buttonText.placeholder {Enter button text here}"
                            inputValidations={[
                                {
                                    validation: lengthInputValidation,
                                    options: { max: MAX_REVIEW_BUTTON_TEXT_LENGTH },
                                }
                            ]}
                            rejectInvalidValue
                            trimValueOnPaste
                            counter={{
                                maxLength: MAX_REVIEW_BUTTON_TEXT_LENGTH,
                                minLength: 3
                            }}
                        />
                    </Flex>
                </PropertyContainer>
                <VerticalSpacer />
                <PropertyGlobalStyleSelector
                    label="msg: common.buttonStyle {Button style}"
                    globalStyleId={selectedButtonTheme || buttonGlobalStyleId}
                    onChangeAction={onChangeAction}
                    options={options}
                    optionRenderer={optRenderer}
                    kind={ButtonGlobalStyleKind}
                    dispatch={dispatch}
                    showEditLink
                    colorThemeOnChangeAction={colorThemeOnChangeAction}
                    autoColorMode={autoColorMode}
                    currentStylesheetId={currentStylesheetId}
                />
            </Page>
        );
    });

export { id, title, view };
