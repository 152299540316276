import React from "react";
import {
    DraggingNewComponentInWorkspaceIconZIndex,
    DraggingNewComponentInWorkspaceZIndex, DraggingNewComponentOutWorkspaceZIndex
} from "../../constants/app";
import { blue95, red390, transparentblue20p, transparentred30p } from "../variables.css";
import registry from '../../view/oneweb/registry/index';
import styles from './DndAddComponent.css';
import type { DndAddComponentState } from './flowTypes';

type Props = {
    state: DndAddComponentState,
}

const
    transitionTime = '.3s',
    makeTransition = transitions => transitions.map(transition => `${transition} ${transitionTime}`).join(', '),
    makeView = (inWorkspace: boolean) =>
        ({
            state: {
                isVisible,
                position,
                inWorkspaceIconX,
                inWorkspacePosition,
                dimensions,
                inWorkspaceDimensions,
                isDroppable,
                componentKind
            }
        }: Props) => {
            if (!isVisible) {
                return <div />;
            }

            const
                { x, y } = inWorkspace ? inWorkspacePosition : position,
                { width, height } = dimensions,
                { width: inWorkspaceWidth, height: inWorkspaceHeight } = inWorkspaceDimensions,
                basePlaceholderState = {
                    left: x - 1,
                    top: y - 1,
                    backgroundColor: isDroppable ? transparentblue20p : transparentred30p,
                    borderColor: `${isDroppable ? blue95 : red390}`,
                    borderStyle: 'solid',
                    position: 'absolute',
                    cursor: 'grabbing',
                    pointerEvents: 'none'
                },
                record: Record<string, any> = registry[componentKind],
                Icon = <record.shortcut fill="white" />;

            let placeholderState;
            if (inWorkspace) {
                const isInvisible = inWorkspaceWidth === 0;

                placeholderState = {
                    ...basePlaceholderState,
                    width: inWorkspaceWidth + (isInvisible ? 0 : 2),
                    height: inWorkspaceHeight + 2,
                    zIndex: DraggingNewComponentInWorkspaceZIndex,
                    transition: makeTransition(['height', 'background-color', 'opacity']),
                    borderWidth: isInvisible ? 0 : 1
                };
            } else {
                placeholderState = {
                    ...basePlaceholderState,
                    width,
                    height: height + 2,
                    zIndex: DraggingNewComponentOutWorkspaceZIndex,
                    transition: makeTransition(['width', 'height', 'background-color', 'opacity']),
                    borderWidth: 1
                };
            }

            if (isDroppable !== inWorkspace) {
                placeholderState.backgroundColor = 'transparent';
                placeholderState.borderWidth = 0;
                placeholderState.zIndex = DraggingNewComponentOutWorkspaceZIndex - 10;
            }

            if (inWorkspace !== isDroppable) {
                return <div />;
            }

            if (inWorkspace) {
                const iconPlaceholderState = {
                    ...placeholderState,
                    backgroundColor: 'transparent',
                    borderWidth: 0,
                    zIndex: DraggingNewComponentInWorkspaceIconZIndex
                };

                return (
                    <div>
                        <div style={placeholderState} />
                        <div style={iconPlaceholderState}>
                            <div className={styles.iconContainer} style={{ left: inWorkspaceIconX }}>
                                <div
                                    className={styles.iconBlock}
                                    style={{
                                        backgroundColor: 'rgba(0,0,0,0.6)',
                                        transition: makeTransition(['background-color'])
                                    }}
                                >{Icon}</div>
                            </div>
                        </div>
                    </div>
                );
            }
            return (
                <div style={placeholderState}>
                    <div className={styles.iconContainer} style={inWorkspace ? { left: inWorkspaceIconX } : undefined}>
                        <div
                            className={styles.iconBlock}
                            style={{
                                backgroundColor: 'rgba(0,0,0,0.6)',
                                transition: makeTransition(['background-color'])
                            }}
                        >{Icon}</div>
                    </div>
                </div>
            );
        },
    InWorkspaceView = makeView(true),
    OutWorkspaceView = makeView(false);

export {
    InWorkspaceView,
    OutWorkspaceView
};
