import { type TinyMceEditor } from "../../../flowTypes";
import { linkedValueTag } from "../../../../../../oneweb/Text/constants";
import { insertBrIntoEmptyParagraphs } from '../../../../../../oneweb/Text/insertBrIntoEmptyParagraphs';

export const getEditorContent = (editor: TinyMceEditor) => {
    const psuedoDom: HTMLDivElement = document.createElement('div');
    psuedoDom.innerHTML = editor.getContent();

    Array.from(psuedoDom.querySelectorAll(linkedValueTag)).forEach((node: any) => node.innerText = '') // eslint-disable-line
    // TODO find all dataLinkedValue and remove content
    return insertBrIntoEmptyParagraphs(psuedoDom.innerHTML);
};

export const getEditorTextContent = (editor: TinyMceEditor) => {
    // $FlowFixMe
    return editor.getContent({ format: 'text' });
};
