import { $Diff } from "utility-types";
import * as React from 'react';
import cx from 'classnames';
import { PopupCom, composeStylesTheme } from '@sepo27/react-redux-lib';
import styles from './Tooltip.css';
import tooltipStyles from '../../Tooltip/view/Tooltip.css';
import type { ReactElementRef } from "../../../globalTypes";
import { injectIntl, Intl, isMsgJointInput, type MsgJointInput } from "../../../view/intl/index";
import type { TooltipPositionT } from "./types";
import { calculateTooltip } from "./calculateTooltip";
import { parseIntDec } from "../../../../utils/number";

const TIP_TRANSITION_DURATION = parseIntDec(styles.tipTransitionDuration);

// TODO: import from '@sepo27/react-redux-lib'
type PopupRef = ReactElementRef<HTMLDivElement>;

type PopupTheme = {
    container?: string,
    show?: string,
    pointer?: string,
    tipInfo?: string,
};

// TODO: import from '@sepo27/react-redux-lib'
type PopupProps = {
    show: boolean,
    top: number,
    left: number,
    children: React.ReactNode,
    theme?: PopupTheme,
    removeTimeout?: number,
    popupRef?: PopupRef,
    onClick?: React.MouseEventHandler,
    onMouseEnter?: React.MouseEventHandler | null,
    onMouseLeave?: React.MouseEventHandler | null,
    isSmallTipInfo?: boolean
};

export type TooltipProps = $Diff<PopupProps, { top: number, left: number, popupRef?: PopupRef }> & {
    targetRef: ReactElementRef<any>,
    position: TooltipPositionT,
    children: React.ReactNode | MsgJointInput,
    distance?: number,
    intl: Intl,
    hideOnTargetLeave?: boolean
};

type State = {
    targetEl: null | undefined | HTMLElement,
    pointerRef: null | undefined | ReactElementRef<HTMLDivElement>,
    popupRef: null | undefined | ReactElementRef<HTMLDivElement>,
    top: number,
    left: number,
    pointerStyle?: Record<string, any>,
    prevShow: boolean,
};

class TooltipClass extends React.Component<TooltipProps, State> {
    static defaultProps = {
        distance: 0
    };

    static getDerivedStateFromProps(nextProps: TooltipProps, prevState: State) {
        const
            { show, position, distance } = nextProps,
            { targetEl, pointerRef, popupRef, prevShow } = prevState,
            pointerEl = (pointerRef && pointerRef.current) || null,
            popupEl = (popupRef && popupRef.current) || null;

        if (targetEl && pointerEl && popupEl && show && show !== prevShow) {
            const
                targetRect = targetEl.getBoundingClientRect(),
                popupRect = popupEl.getBoundingClientRect(),
                tooltipDimensions = {
                    width: popupRect.width,
                    height: popupRect.height
                },
                pointerDimensions = {
                    width: pointerEl.offsetWidth,
                    height: pointerEl.offsetHeight,
                },
                windowDimensions = {
                    width: window.innerWidth,
                    height: window.innerHeight,
                },
                { top, left, pointerStyle } = calculateTooltip({
                    position,
                    targetRect,
                    tooltipDimensions,
                    pointerDimensions,
                    windowDimensions,
                    distance
                });

            return { top, left, pointerStyle, prevShow: show };
        } else if ((!targetEl && show !== prevShow) || (!show && prevShow)) {
            return { prevShow: show };
        }

        return null;
    }

    state: State;
    pointerRef: ReactElementRef<HTMLDivElement>;
    popupRef: PopupRef;

    constructor() {
        // @ts-ignore
        super();
        this.state = {
            targetEl: null,
            pointerRef: null,
            popupRef: null,
            top: 0,
            left: 0,
            pointerStyle: undefined,
            prevShow: false
        };
        this.pointerRef = React.createRef();
        this.popupRef = React.createRef();
    }

    componentDidMount() {
        const targetEl = this.props.targetRef.current;
        if (!targetEl) {
            throw new Error('Missing target element');
        }
        this.setState({ targetEl });
    }

    componentDidUpdate() {
        const
            { show } = this.props,
            { prevShow } = this.state;

        if (show && show !== prevShow) {
            this.setState({ // eslint-disable-line react/no-did-update-set-state
                pointerRef: this.pointerRef,
                popupRef: this.popupRef,
            });
        }
    }

    render() {
        const
            {
                children: propChildren,
                theme: inTheme = {},
                intl,
                isSmallTipInfo = true,
                ...popupProps
            } = this.props,
            { top, left, pointerStyle } = this.state,
            theme = composeStylesTheme(
                {
                    container: cx(tooltipStyles.container, styles.container),
                    show: styles.show,
                    pointer: cx(tooltipStyles.pointer, styles.pointer),
                    tipInfo: cx(tooltipStyles.tipInfo, { [styles.tipInfo]: isSmallTipInfo }),
                },
                inTheme,
            ),
            children = isMsgJointInput(propChildren) ? intl.msgJoint(propChildren) : propChildren;

        return (
            <PopupCom
                {...popupProps}
                top={top}
                left={left}
                ref={this.popupRef}
                theme={theme}
                removeTimeout={TIP_TRANSITION_DURATION}
            >
                <div
                    className={theme.pointer}
                    style={pointerStyle}
                    ref={this.pointerRef}
                />
                <div className={theme.tipInfo}>
                    {children}
                </div>
            </PopupCom>
        );
    }
}

export const TooltipCom = injectIntl(TooltipClass);
