import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly } from "../../../../epics/makeCondition";
import valueActionType from './valueActionType';
import workspaceBBoxValueActionType from '../workspaceBBox/valueActionType';
import { getHandles } from "../../../../utils/handle/index";
import { memoMaxOne } from '../../../../../utils/memo';
import { DndAddComponentVisible } from "../../../DndAddComponent/epic/selectorActionTypes";
import {
    transientInteractionModesByMouse,
    MOUSE_DOWN_ON_HANDLE
} from "../componentsEval/userInteractionMutations/interactionModes";
import { ReceiveOnlyTemplateWidthActionType } from '../../../oneweb/Template/epics/template/selectorActionTypes';
import {
    ForHandlesSelector,
    EditModeComponentIdSelector, ReceiveOnlyComponentsMap
} from "../componentsEval/selectorActionTypes";
import {
    CodeComponentsRendererBodyHeightSelector,
    CodeComponentsRendererHeadHeightSelector
} from "../../CodeComponentsRenderer/epic/selectorActionTypes";
import { WRAP_POSITION_CONTROLS_VAT } from '../../WrapPositionControls/epic/valueActionType';
import templateLinesValueActionType from "../../../oneweb/Template/epics/templateLines/valueActionType";
import { getComponentsBBox } from "../../../../utils/componentsMap/getComponentsBBox";
import { getSectionWhoseEdgeIsInBoxArea } from "../isPageMode/utils";
import { isStickyToHeader } from "../componentsEval/isStickyToHeader";
import { ReceiveOnlyAttachments } from "../componentAttachements/selectorActionTypes";
import { getComponentsMapForHoverBox } from "../../../oneweb/HoverBox/utils";
import { getSectionsOrderdByTop } from "../../../oneweb/Section/utils";
import { headerFooterSectionsPositionVAT } from "../../../oneweb/Section/epics/headerFooterSectionsPositionEpic";
import { isModernLayoutActivatedVAT } from "../isModernLayoutActivatedEpic/valueActionType";
import { getWebShopStripCmpIds } from '../../../ModernLayouts/layoutsData/webshopMHFDataUtils';

const defaultState = [],
    getRelevantComponentsMapDuringTransientUI = memoMaxOne((componentsMap, selectedComponentsIds) =>
        selectedComponentsIds.reduce((acc, componentId) => {
            acc[componentId] = componentsMap[componentId]; // eslint-disable-line no-param-reassign
            return acc;
        }, {})),
    getHandlesMemoized = memoMaxOne(getHandles);

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                ReceiveOnlyTemplateWidthActionType,
                EditModeComponentIdSelector,
                workspaceBBoxValueActionType,
                ForHandlesSelector,
                templateLinesValueActionType,
                DndAddComponentVisible,
                CodeComponentsRendererBodyHeightSelector,
                CodeComponentsRendererHeadHeightSelector,
                WRAP_POSITION_CONTROLS_VAT,
                ReceiveOnlyAttachments,
                receiveOnly(isModernLayoutActivatedVAT),
                receiveOnly(headerFooterSectionsPositionVAT),
            ],
            reducer: ({
                values: [
                    componentsMap,
                    templateWidth,
                    editModeComponentId,
                    workspaceBBox,
                    { componentsMapNoGhosts, selectedComponentsIdsNoGhosts, interactionMode },
                    templateLinesState,
                    dndAddComponentVisible,
                    bodyHeight,
                    headHeight,
                    wrapPositionControls,
                    attachments,
                    isModernLayoutActivated,
                    headerFooterPositions,
                ]
            }) => {
                let relevantComponentsMap = componentsMapNoGhosts,
                    hideShiftBarHandles;
                const { state: { isActive: templateLinesActive } } = templateLinesState;

                /* TODO WBTGEN-6501: To make resizeDecos work without handles */
                if (dndAddComponentVisible) {
                    relevantComponentsMap = {};
                } else if (transientInteractionModesByMouse[interactionMode] ||
                    interactionMode === MOUSE_DOWN_ON_HANDLE) {
                    relevantComponentsMap = getRelevantComponentsMapDuringTransientUI(
                        componentsMapNoGhosts,
                        [
                            ...selectedComponentsIdsNoGhosts,
                            ...(
                                editModeComponentId && componentsMapNoGhosts[editModeComponentId] ?
                                    [editModeComponentId] : []
                            )
                        ]
                    );
                } else {
                    relevantComponentsMap = {
                        ...getComponentsMapForHoverBox(componentsMapNoGhosts, attachments),
                        ...selectedComponentsIdsNoGhosts.reduce((acc, id) => ({
                            ...acc,
                            [id]: componentsMapNoGhosts[id]
                        }), {})
                    };
                }
                const
                    sections = getSectionsOrderdByTop(componentsMap),
                    selectedComponentsBBox = getComponentsBBox(
                        selectedComponentsIdsNoGhosts.map(id => componentsMapNoGhosts[id]), workspaceBBox
                    ),
                    webshopFooterStripCmpIds = getWebShopStripCmpIds(componentsMap);
                hideShiftBarHandles =
                    !!getSectionWhoseEdgeIsInBoxArea(selectedComponentsBBox, componentsMap) ||
                    !!selectedComponentsIdsNoGhosts.find(id => isStickyToHeader(componentsMapNoGhosts[id]));

                const state = getHandlesMemoized(
                    relevantComponentsMap,
                    selectedComponentsIdsNoGhosts,
                    workspaceBBox,
                    editModeComponentId,
                    interactionMode,
                    templateLinesActive,
                    templateWidth,
                    bodyHeight,
                    headHeight,
                    wrapPositionControls,
                    sections,
                    hideShiftBarHandles,
                    isModernLayoutActivated,
                    headerFooterPositions,
                    webshopFooterStripCmpIds
                );
                return {
                    state
                };
            }
        }
    ]
});
