import factory from './factoryHover';
import { getShiftBarTopSelectionLeft } from '../../../../utils/handle/shiftBar';
import * as HandleKinds from '../../../../utils/handle/kinds';

export default factory(
    {
        kind: HandleKinds.ShiftBarBottomHover,
        getLeft: bBox => getShiftBarTopSelectionLeft(bBox)
    }
);
