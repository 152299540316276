import React from 'react';
import { connect } from "react-redux";
import * as R from 'ramda';
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import * as InserterStyles from "../../../../Panel/view/Inserter.css";
import * as styles from "../styles.css";
import { DroppableCmpShortcut } from "../../../../Panel/view/DroppableCmpShortcut";
import BackgroundKind from "../../kind";
import type { AppState } from "../../../../../redux/modules/flowTypes";
import calcRenderProps from "../../calcRenderProps";
import View from "../../view/index";
import { circleTemplates, circlesConfig, circlesStylesMap, circlesLabelMap } from './config';
import { getDefaultState, getThemeOverridesFromComponentData } from "../utils";
import type { BackgroundCalcRenderPropsResult } from "../../flowTypes";

const circlesLabel = 'msg: common.circles {Circles}',
    boxDefaultState = getDefaultState(BackgroundKind),
    CircleWidth = 340,
    CircleHeight = 340;

const Circle = (props) => {
    const { type, componentsDependencies } = props;
    const componentDependencies = componentsDependencies[BackgroundKind],
        component = R.mergeDeepRight(boxDefaultState, R.mergeDeepRight(circlesConfig, circlesStylesMap[type])),
        themeOverrides = getThemeOverridesFromComponentData(
            component, componentDependencies.themeSettingsData.autoColorMode, componentDependencies.themeColorsData
        ),
        cmpViewProps: BackgroundCalcRenderPropsResult =
            calcRenderProps({ isWorkspace: false, component: R.mergeDeepRight(component, themeOverrides), componentDependencies });

    const payload = {
        style: component.style,
        width: CircleWidth,
        height: CircleHeight,
        ...(componentDependencies.themeSettingsData.autoColorMode ? R.omit(['selectedBorderTheme'], themeOverrides) : {}),
    };

    const view = View({
        ...cmpViewProps,
        className: styles.circle
    });
    return <DroppableCmpShortcut className={styles.circleWrapper} {...props} payload={payload} kind={BackgroundKind} isOverlayExtended>
        {view}
        <span className={styles.circleLabel}>{props.intl.msgJoint(circlesLabelMap[type])}</span>
    </DroppableCmpShortcut>;
};

const circlesComponent = (props) => {
    return <div>
        <div className={InserterStyles.subTitle}>{props.intl.msgJoint(circlesLabel)}</div>
        <VerticalSpacer y={20} />
        <div className={`${InserterStyles.shortCutsContainer} ${styles.circleShortcutContainer}`}>
            {circleTemplates.map((type, index) => <Circle {...props} type={type} key={index} />)}
        </div>
        <VerticalSpacer y={20} />
    </div>;
};

const mapStateToProps = (appState: AppState) => ({
    componentsDependencies: appState.selectedWorkspace.componentsDependencies
});

export const Circles = connect(mapStateToProps)(circlesComponent);
