/* eslint-disable max-len */

import React from 'react';
import { Box, Flex } from "../../../../../../view/reflexbox";
import styles from './ConnectCongratsView.css';
import { replaceWithBusinessListTabDialog } from "../../actionCreators";
import PrimaryButton from "../../../../../../view/common/Button/PrimaryButton";
import { Msg } from "../../../../../../view/intl";
import { IMPORT_YOUR_LISTING } from '../../actionTypes';
import type { Address } from '../../../../../googleMaps/googleMaps';
import type { GmbOpeningHour } from '../../../../../oneweb/OpeningHours/flowTypes';
import { GoogleMapView } from "../../GoogleMap/view";
import { reviewConfigSuccessAction } from '../../../../../oneweb/GoogleReviews/epic/actionCreators';

type Props = {
    addressFields: Address,
    dispatch: Dispatch,
    openingHours: {
        periods: GmbOpeningHour[]
    },
    phoneNumber: string,
    reviewsConfigInProgress: boolean,
};

export const ConnectCongratsView = (props: Props) => {
    const { addressFields, openingHours, phoneNumber, dispatch, reviewsConfigInProgress } = props;
    const onNoThanksAction = reviewsConfigInProgress ? reviewConfigSuccessAction : replaceWithBusinessListTabDialog;
    return (
        <React.Fragment>
            <Box className={styles.congratsTitle}>
                <Msg k="BusinessListing.connectCongrats.congrats">Congrats, your listing is now connected to your website!</Msg>
            </Box>
            <Flex className={styles.gmbDisplayContainer}>
                <GoogleMapView addressFields={addressFields} openingHours={openingHours} phoneNumber={phoneNumber} />
            </Flex>
            <Box className={styles.congratsDescription}>
                <Msg k="BusinessListing.connectCongrats.importDescription">
                    Your listing is now connected to your website. Would you like to import the information from this listing and use it to update your contact and opening hours in the website settings? This will only be done once.
                </Msg>
            </Box>
            <PrimaryButton
                className={styles.importButton}
                onClick={() => dispatch({ type: IMPORT_YOUR_LISTING })}
            >
                <Msg k="common.import">
                    Import
                </Msg>
            </PrimaryButton>
            <Box
                className={styles.noThanks}
                onClick={() => { dispatch(onNoThanksAction()); }}
            >
                <Msg k="BusinessListing.connectCongrats.noThanks">
                    No, thanks
                </Msg>
            </Box>
        </React.Fragment>
    );
};
