export const boxesConfig = {
        width: 99,
        height: 99,
        style: {
            border: {
                style: null,
                corners: [0, 0, 0, 0]
            },
            background: {
                colorData: {
                    color: null,
                    gradient: null
                },
                assetData: null
            }
        }
    },

    WHITE_BOX = 'whiteBox',
    DARK_BOX = 'darkBox',
    ROUNDED_BOX = 'roundedBox',
    BORDER_BOX = 'borderBox',
    GRADIENT_BOX = 'gradientBox',
    IMAGE_BOX = 'imageBox',
    WHITE_HOVER_BOX = 'whiteHoverBox',
    DARK_HOVER_BOX = 'darkHoverBox',

    boxesStylesMap = {
        [WHITE_BOX]: { style: { background: { colorData: { color: ["HSL", 0.5645, 0, 1, 1] } } } },
        [DARK_BOX]: { style: { background: { colorData: { color: ["HSL", 0.5645, 0, 0.2, 1] } } } },
        [WHITE_HOVER_BOX]: { style: { background: { colorData: { color: ["HSL", 0.5645, 0, 1, 1] } } } },
        [DARK_HOVER_BOX]: { style: { background: { colorData: { color: ["HSL", 0.5645, 0, 0.2, 1] } } } },
        [BORDER_BOX]: {
            style: {
                border: {
                    style: 'solid',
                    color: ["HSL", 0.3992, 0, 0.2, 0.77],
                    width: [1, 1, 1, 1],
                    corners: [8, 8, 8, 8]
                },
                background: { colorData: { color: null } }
            }
        },
        [ROUNDED_BOX]: {
            style: {
                border: { corners: [8, 8, 8, 8] },
                background: { colorData: { color: ["HSL", 0.5645, 0, 1, 1] } }
            }
        },
        [GRADIENT_BOX]: {
            style: {
                background: {
                    colorData: {
                        color: ["HSL", 0.4965, 0.8563, 0.3275, 1],
                        gradient: {
                            color: ["HSL", 0.5327, 0.6986, 0.1431, 1],
                            fadePoint: 50,
                            degree: 135
                        }
                    }
                }
            }
        },
        [IMAGE_BOX]: {
            style: {
                background: {
                    assetData: {
                        asset: {
                            contentType: 'image/jpeg',
                            width: 2783,
                            height: 2783,
                            animated: false,
                            recommendedFormat: null,
                            url: 'repository:/boxBgImage.png'
                        },
                        repeat: [false, false],
                        position: ["50%", "50%"],
                        size: 'cover',
                        fixed: false,
                        opacity: 1
                    }
                }
            }
        }
    },
    boxesLabelMap = {
        [WHITE_BOX]: 'msg: leftPanel.containers.box.whiteBox {White box}',
        [DARK_BOX]: 'msg: leftPanel.containers.box.darkBox {Dark box}',
        [BORDER_BOX]: 'msg: leftPanel.containers.box.borderBox {Box with border}',
        [ROUNDED_BOX]: 'msg: leftPanel.containers.box.roundedBox {Rounded box}',
        [GRADIENT_BOX]: 'msg: leftPanel.containers.box.gradientBox {Gradient box}',
        [IMAGE_BOX]: 'msg: leftPanel.containers.box.imageBox {Box with image}',
        [WHITE_HOVER_BOX]: 'msg: leftPanel.containers.box.whiteHoverBox {White hoverbox}',
        [DARK_HOVER_BOX]: 'msg: leftPanel.containers.box.darkHoverBox {Dark hoverbox}',
    },
    boxTemplates = [WHITE_BOX, DARK_BOX, ROUNDED_BOX, BORDER_BOX, GRADIENT_BOX, IMAGE_BOX],
    hoverBoxTemplates = [WHITE_HOVER_BOX, DARK_HOVER_BOX];
