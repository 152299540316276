import * as React from 'react';
import styles from '../../../view/Workspace/Decorations/ComponentMainActions/ComponentMainActions.css';

type Props = { top: number, left: number, children: React.ReactNode, parentStyle?: any };
const view = ({
    top,
    left,
    parentStyle = {},
    children
}: Props) => <div
    onMouseDown={event => {
        event.stopPropagation();
        event.preventDefault();
    }}
    className={styles.toolBarContainer}
    style={{
        top,
        left,
        zIndex: 10000000001
    }}
>
    <div className={styles.toolBarMCTAContainer} data-title-position="top">
        <div className={styles.secondaryToolBar} style={parentStyle}>
            {children}
        </div>
    </div>
</div>;

export default view;
