import React from 'react';
import type { OpacitySliderFlowTypes } from "../../presentational/OpacitySlider/flowTypes";
import { MctaSlider } from "./MctaSlider";

const MctaOpacitySlider = (props: OpacitySliderFlowTypes) => {
    const {
        opacity,
        onChange,
        onAfterChange,
        disabled,
        label = 'msg: common.opacity {Opacity}',
        min = 0,
        max = 100
    } = props;
    return (
        <MctaSlider
            label={label}
            valueLabel="%"
            value={opacity}
            onChange={onChange}
            onAfterChange={onAfterChange}
            disabled={disabled}
            min={min}
            max={max}
        />
    );
};

export {
    MctaOpacitySlider as default,
    MctaOpacitySlider
};
