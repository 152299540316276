import inputDefaultState from "../../../../../inputControls/input/reducerFactory/inputDefaultState";
import type { PageInfoDialogState } from "../flowTypes";
import { PageInfoTab } from "../../../constants";
import { SeoPageTitleInTabOptions } from "../view/constants";

const defaultState: PageInfoDialogState = {
    activeTabId: PageInfoTab.NAMES,
    names: {
        name: inputDefaultState,
        url: inputDefaultState,
        defaultNonHomePageNameInBrowserTab: '',
        defaultHomePageNameInBrowserTab: '',
    },
    seo: {
        excludeFromIndexing: false,
        description: '',
        title: inputDefaultState,
        customTitle: inputDefaultState,
        defaultNonHomePageNameInBrowserTab: '',
        defaultHomePageNameInBrowserTab: '',
        pageNameOptionInBrowserTab: SeoPageTitleInTabOptions.DEFAULT,
    },
    [PageInfoTab.SOCIAL_SHARE]: {
        socialShareAsset: null,
        defaultNonHomePageNameInBrowserTab: '',
        defaultHomePageNameInBrowserTab: '',
    },
    visibility: {
        isHidden: false,
        isPublished: true
    },
    [PageInfoTab.TEMPLATE]: {
        isLoading: false,
        selectedTemplate: {
            id: '',
            name: ''
        },
        templates: [],
        templateIsChanged: false,
    },
    isHomePage: false,
    currentTemplateId: '',
    isEqToInitialState: true
} as any;

export default defaultState;
