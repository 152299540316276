export const getTweetLink = (tweetHTML: string): string => {
    const match = tweetHTML.match(/href="(https:\/\/twitter\.com\/[^\/]+\/status\/\d+)(?:.*)/);
    return Array.isArray(match) && match[1] ? match[1] : tweetHTML;
};

export const getTweetHTMLFromCode = (
    tweetLink: string,
    locale: string,
    direction: string
): string => {
    const parts = tweetLink.replace(/^https:\/\//, '').split('/'),
        entity = parts[1],
        message = `Tweet by ${entity}`;

    return `<p data-lang=${locale} dir=${direction}>${message}<p> 
    - ${entity} 
    <a href="${tweetLink}">${message}</a>`;
};
