// @ts-nocheck
/* globals $R */

import React, { useState } from 'react';
import axios from 'axios';

const GMBUpdate = function () {
    const domain = $R.store.getState().preferences.owner;
    let url = `/api/v1/${domain}/gmb/updateConnectedBusinessData`;

    const [websiteUrl, setWebsiteUrl] = useState('http://www.example.com/');
    const [phoneNumber, setPhoneNumber] = useState('01234567');

    const update = async function ({ validateOnly }) {
        try {
            url += '?websiteUrl=' + encodeURIComponent(websiteUrl);
            url += '&primaryPhone=' + encodeURIComponent(phoneNumber);
            if (validateOnly) {
                url += '&validateOnly=yes';
            }

            await axios.get(url);
            if (validateOnly) {
                alert('Validated');
            } else {
                alert('Updated');
            }
        } catch (e: any) {
            if (validateOnly) {
                alert('Failed to validate');
            } else {
                alert('Failed to update');
            }
        }
    };

    return (
        <div>
            <div style={{ display: 'flex' }}>
                <div style={{ width: 100 }}>
                    Website URL:&nbsp;
                </div>
                <input
                    type="text"
                    onChange={function (evt) {
                        setWebsiteUrl(evt.target.value);
                    }}
                    value={websiteUrl}
                />
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ width: 100 }}>
                    Phone number:&nbsp;
                </div>
                <input
                    type="text"
                    onChange={function (evt) {
                        setPhoneNumber(evt.target.value);
                    }}
                    value={phoneNumber}
                />
            </div>

            <button
                type="button"
                onClick={async function () {
                    await update({ validateOnly: true });
                }}
                style={{ marginTop: 5 }}
            >
                Validate only
            </button>

            <button
                type="button"
                onClick={async function () {
                    await update({});
                }}
                style={{ marginTop: 5 }}
            >
                Update
            </button>
        </div>
    );
};

export {
    GMBUpdate
};
