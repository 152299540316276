import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import { valueActionType as siteDataVAT } from "../siteData/valueActionType";
import { HIGH_PAGE_COUNT } from "../../../../constants/app";
import { openDialog } from "../../actionCreators/index";
import { HighPageCountDialogId } from "../../dialogs/appDialogIds";
import { SITE_DATA_UPDATE_SUCCESS } from "../../actionTypes";
import { receiveOnly } from "../../../../epics/makeCondition";

const every3rdPage = 3;
const epicConfig = {
    defaultState: {
        publicPages: 0
    },
    valueActionType,
    updaters: [
        {
            conditions: [receiveOnly(siteDataVAT), SITE_DATA_UPDATE_SUCCESS],
            reducer: ({ values: [siteData], state }) => {
                const
                    publicPages = siteData.getAllPageRefs().filter(page => page.public).length,
                    hasHighPageCount = publicPages >= HIGH_PAGE_COUNT,
                    hasAddedPage = publicPages > state.publicPages,
                    is3rdPage = ((publicPages - HIGH_PAGE_COUNT) % every3rdPage) === 0,
                    show = hasHighPageCount
                        && hasAddedPage
                        && is3rdPage;

                let actionToDispatch: Action | null = null;
                if (show) {
                    actionToDispatch = openDialog(HighPageCountDialogId, { publicPages });
                }

                return {
                    state: {
                        ...state,
                        publicPages
                    },
                    actionToDispatch
                };
            }
        }
    ]
};

export default makeEpic(epicConfig);

