import type { PageInfoUrlPath, PageInfoAllPagesUrlPaths } from "../flowTypes";
import type { InputValidationFunction, InputValidationResult } from "../../../../../inputControls/input/flowTypes";
import { isDuplicatePageUrlPath } from "../../../../utils";
import { makeInputValidationResult } from "../../../../../inputControls/input/commonValidations/utils";
import pathsAreEqual from "../../../../../../utils/pathsAreEqual";
import { decodeWebspaceUri, encodeWebspaceUri } from "../../../../../../../dal/utils/webspaceUriTransformers";

type Options = {
    origPageUrlPath: PageInfoUrlPath;
    allPagesUrlPaths: PageInfoAllPagesUrlPaths;
    origHomePageUrlPath: PageInfoUrlPath | null | undefined;
};

const
    Reason = {
        DUPLICATE_PAGE_URL_PATH: 'DUPLICATE_PAGE_URL_PATH',
        DUPLICATE_HOME_PAGE_URL_PATH: 'DUPLICATE_HOME_PAGE_URL_PATH'
    },
    Message = {
        [Reason.DUPLICATE_PAGE_URL_PATH]: 'msg: validation.isDuplicatePageUrlPath {Sorry, that URL is already taken. Pick something else.}', // eslint-disable-line
        [Reason.DUPLICATE_HOME_PAGE_URL_PATH]: 'msg: validation.isDuplicateHomePageUrlPath {Sorry, that URL is already used by your start page. Pick something else.}' // eslint-disable-line
    };
const isDuplicatePageUrlPathInputValidation: InputValidationFunction<Options> = (
    value: string,
    options?: Options
): InputValidationResult => {
    if (
        !options
        || !options.origPageUrlPath
        || !options.allPagesUrlPaths
        || options.origHomePageUrlPath === undefined
    ) {
        throw new Error("Missing require options: 'origPageUrlPath' / 'allPagesUrlPaths' / 'origHomePageUrlPath'");
    }

    const
        { origPageUrlPath, allPagesUrlPaths, origHomePageUrlPath } = options,
        prefixPath = origPageUrlPath.slice(0, -1),
        pageUrlPath = [...prefixPath, encodeWebspaceUri(decodeWebspaceUri(value))],
        isValid = pathsAreEqual(pageUrlPath, origPageUrlPath) || !isDuplicatePageUrlPath(pageUrlPath, allPagesUrlPaths),
        reason = origHomePageUrlPath && pathsAreEqual(origHomePageUrlPath, pageUrlPath)
            ? Reason.DUPLICATE_HOME_PAGE_URL_PATH
            : Reason.DUPLICATE_PAGE_URL_PATH;

    return makeInputValidationResult(isValid, reason, Message[reason]);
};

export { isDuplicatePageUrlPathInputValidation as default };
