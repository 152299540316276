import Preview from "./view/preview";
import kind from "./kind";
import calcRenderProps from "./calcRenderProps";
import styles from './view/Mobile.css';
import type { CmpSpecificStyles, CalcProps } from "../../Preview/flowTypes";
import type { ImageComponent } from "./flowTypes";
import { getScaledStyles, getScaledStylesForContainers } from "../../../utils/getScaledStyles";
import { getGroupCmpPositionStylesForMobile } from "../getGroupCmpPositionStylesForMobile";
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";

const imagePreviewCalcProps = ({
    component,
    hasChildren,
    siteMap,
    hdImages,
    previewBackupTime,
    globalVariables,
    isLogo,
    isWorkspace,
    viewerDomain,
    componentsDependencies,
    siteSettings,
    globalStyles,
    selectedParentTheme,
    paddingBottom
}: CalcProps<ImageComponent>): any => {
    return {
        ...calcRenderProps({
            component,
            hasChildren,
            componentDependencies: {
                site: siteMap,
                globalVariables,
                isLogo,
                missingAssetUrls: componentsDependencies?.[kind] && componentsDependencies[kind].missingAssetUrls,
                themeColorsData: getThemeColorsFromStylesheet(globalStyles),
                themeSettingsData: siteSettings.themeSettingsData,
            },
            isWorkspace: isWorkspace || false,
            previewBackupTime,
            hdImages,
            viewerDomain,
            selectedParentTheme,
        }, styles),
        paddingBottom
    };
};

const imagePreviewConfig = {
    view: Preview,
    kind,
    calcProps: imagePreviewCalcProps,
    getSpecificStyles: ({ component, hasChildren, group, mobileSetting = {} }: CmpSpecificStyles<ImageComponent>): string => {
        let styles = '',
            groupItemStyle = getGroupCmpPositionStylesForMobile(group, component, mobileSetting).mobileStylesStr;
        styles += groupItemStyle ? `.mobileV div[data-id='${component.id}'] {
            ${groupItemStyle}
        }\n` : '';
        const scaledStyles = hasChildren ? getScaledStylesForContainers(component) : getScaledStyles(component);
        if (scaledStyles) {
            styles += scaledStyles;
        }
        return styles;
    },
    js: [
        '/renderStatic/lightbox/shinybox/jquery.shinybox.modified.js',
        '/renderStatic/lightbox/lightbox_wbtgen.js',
    ],
    css: [
        '/renderStatic/lightbox/shinybox/shinybox.css'
    ]
};

export {
    imagePreviewConfig as default,
    imagePreviewConfig,
    imagePreviewCalcProps
};

