import makeEpic from '../../../epics/makeEpic';
import * as actionTypes from './actionTypes';
import type { ImageAsset } from "../../App/flowTypes";

export type SocialShareGlobalDataStateType = {
    socialShareAsset: ImageAsset | null | undefined,
}

const socialShareGlobalDataDefaultState: SocialShareGlobalDataStateType = {
    socialShareAsset: null,
};

const socialShareGlobalDataEpic = makeEpic({
    defaultState: socialShareGlobalDataDefaultState,
    valueActionType: 'SOCIAL_SHARE_GLOBAL_DATA_EPIC_VALUE',
    saveIntoSiteSettings: { key: 'socialShare' },
    undo: true,
    updaters: [
        {
            conditions: [actionTypes.SOCIAL_SHARE_IMAGE_CHOSEN],
            reducer: ({ state, values: [payload] }) => {
                const { asset: socialShareAsset } = payload;
                return {
                    state: {
                        ...state,
                        socialShareAsset
                    },
                    updateReason: actionTypes.SOCIAL_SHARE_IMAGE_CHOSEN,
                };
            },
        },
        {
            conditions: [actionTypes.SOCIAL_SHARE_IMAGE_REMOVE_PRESSED],
            reducer: ({ state }) => {
                return {
                    state: { ...state, socialShareAsset: null },
                    updateReason: actionTypes.SOCIAL_SHARE_IMAGE_REMOVE_PRESSED,
                };
            },
        },
        {
            conditions: [actionTypes.SOCIAL_SHARE_REPLACE_MISSING_ASSET],
            reducer: ({ values: [replacements], state }) => {
                if (state.socialShareAsset && state.socialShareAsset.url && replacements[state.socialShareAsset.url]) {
                    return {
                        state: {
                            ...state,
                            socialShareAsset: replacements[state.socialShareAsset.url]
                        },
                        updateReason: actionTypes.SOCIAL_SHARE_DELETE_MISSING_ASSET
                    };
                }

                return { state };
            }
        },
        {
            conditions: [actionTypes.SOCIAL_SHARE_DELETE_MISSING_ASSET],
            reducer: ({ values: [missingAssetUrls], state }) => {
                if (state.socialShareAsset && state.socialShareAsset.url && missingAssetUrls.includes(state.socialShareAsset.url)) {
                    return {
                        state: { ...state, socialShareAsset: null },
                        updateReason: actionTypes.SOCIAL_SHARE_DELETE_MISSING_ASSET
                    };
                }

                return { state };
            }
        }
    ],
});

export {
    socialShareGlobalDataEpic
};
