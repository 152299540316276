export const
    GET_DOMAIN_FROM_AUTH_COOKIE = 'GET_DOMAIN_FROM_AUTH_COOKIE',
    DOMAIN_FROM_AUTH_COOKIE_VALUE = 'DOMAIN_FROM_AUTH_COOKIE_VALUE',
    GET_EMAIL_FROM_AUTH_COOKIE = 'GET_EMAIL_FROM_AUTH_COOKIE',
    EMAIL_FROM_AUTH_COOKIE_VALUE = 'EMAIL_FROM_AUTH_COOKIE_VALUE',
    AUTH_COOKIE_EXISTS = 'AUTH_COOKIE_EXISTS',
    AUTH_COOKIE_MISSING = 'AUTH_COOKIE_MISSING',
    SET_COOKIE = 'SET_COOKIE',
    UNSET_COOKIE = 'UNSET_COOKIE',
    GET_COOKIE = 'GET_COOKIE',
    COOKIE_RESULT = 'COOKIE_RESULT';

export const
    REDIRECT_TO_LOGIN = 'REDIRECT_TO_LOGIN',
    RELOAD_PAGE = 'RELOAD_PAGE',
    LOGOUT_ACTION = 'LOGOUT_ACTION',
    FORCE_LOGOUT_ACTION = 'FORCE_LOGOUT_ACTION';

export const
    SERVER_INVALID_OR_MISSING_COOKIE_ACTION = 'SERVER_INVALID_OR_MISSING_COOKIE_ACTION';
