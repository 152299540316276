export const
    DEFAULT_WIDTH = 325,
    DEFAULT_HEIGHT = 735,
    DEFAULT_PROFILE_WIDTH = 300,
    DEFAULT_PROFILE_HEIGHT = 387,
    EmbedTypes = ['tiktok', 'profile'],
    defaultState = {
        link: "",
        mobileHide: false,
    },
    TiktokEmbedUrl = "https://www.tiktok.com/embed/",
    DATA_TEST_ID = 'tiktok-widget';

export enum PostType {
    VIDEO = 'video',
    PROFILE = 'profile',
}
