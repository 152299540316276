import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import YouTubePlayer from 'youtube-player';
import styles from './DemoPublishTip.css';
import { DemoLoginMessages } from "../login/main/messages";
import { demoBuyButtonClickAction, publishVideoTutorialPlayedAction } from "../actions";
import type { DemoPublishTipProps } from "../login/types";
import { DemoTip } from "./DemoTip";
import { TooltipPosition } from "../../../src/components/presentational/Tooltip/constants";
import type { AppState } from "../../../src/redux/modules/flowTypes";
import { isDemoSubscriptionType } from "../../../src/components/App/epics/subscriptionData/isDemoSubscriptionType";
import { calculateUpgradeTierBeforeSeamlessUpgrade } from "../../../src/components/ComponentTierManager/actionCreators";
import { currentLanguageAppSel } from '../../../src/components/TopBar/epics/languages/selectors';

const publishTutorialId = 'publishTutorialId';
let isVideoPlayed = false;
const VideoPublishTipHtml = ({
    intl,
}) => {
    const {
        buyToPublishLabel,
        buyToPublishMsg,
        buyToPublishLink
    } = DemoLoginMessages;

    const [isHooked, setIsHooked] = useState(false);
    const dispatch = useDispatch();
    const playerRef = useRef(null);
    useEffect(() => {
        if (!playerRef.current) {
            const player = YouTubePlayer(publishTutorialId, {
                videoId: intl.msgJoint(buyToPublishLink),
                width: '100%',
                height: '100%',
                playerVars: { fs: 0 }
            });
            playerRef.current = player;
        }
        isVideoPlayed = false;
        return () => {
            if (playerRef.current) {
                // @ts-ignore
                playerRef.current.destroy();
                playerRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        if (playerRef.current && !isHooked) {
            // @ts-ignore
            playerRef.current.on('stateChange', (event) => {
                if ([1, 2, 3, 4].includes(event.data)) {
                    if (!isVideoPlayed) {
                        dispatch(publishVideoTutorialPlayedAction());
                    }
                    isVideoPlayed = true;
                }
            });
            setIsHooked(true);
        }
    }, [isHooked, dispatch]);

    return (
        <div className={styles.buyToPublishVideoContainer}>
            <div className={styles.buyToPublishContainer}>
                <div className={styles.buyToPublishTextContainer}>
                    <div className={styles.buyToPublishHeaderContainer}>
                        <div className={styles.spacer} />
                        <span> {intl.msgJoint(buyToPublishLabel)}</span>
                    </div>
                    <div className={styles.buyToPublishMsgContainer}>
                        {intl.msgJoint(buyToPublishMsg)}
                    </div>
                </div>
                <div
                    className={styles.buyToPublishBtn}
                    onClick={() => {
                        dispatch(calculateUpgradeTierBeforeSeamlessUpgrade("trial:buynow", "", "", false));
                        dispatch(demoBuyButtonClickAction());
                    }}
                >
                    {intl.msgJoint('msg: common.buyplan {Buy plan}')}
                </div>
            </div>
            <div className={styles.videoContainer}>
                <div id={publishTutorialId} />
            </div>
        </div>
    );
};
const PublishTipHtml = ({
    intl,
    dispatch
}) => {
    const {
        upgradeToPublishLabel,
        upgradeToPublishMsg
    } = DemoLoginMessages;
    return (
        <div className={styles.upgradeToPublishContainer}>
            <div className={styles.upgradeToPublishHeaderContainer}>
                <span className={styles.hintIcon} />
                <span> {intl.msgJoint(upgradeToPublishLabel)}</span>
            </div>
            <div className={styles.upgradeToPublishMsgContainer}>
                {intl.msgJoint(upgradeToPublishMsg)}
            </div>
            <div
                className={styles.upgradeToPublishBtn}
                onClick={() => {
                    dispatch(calculateUpgradeTierBeforeSeamlessUpgrade("trial:buynow", "", "", false));
                    dispatch(demoBuyButtonClickAction());
                }}
            >
                {intl.msgJoint('msg: common.upgradeNow {Upgrade now}')}
            </div>
        </div>
    );
};

export class DemoPublishTipClass extends React.Component<DemoPublishTipProps> {
    render() {
        // @ts-ignore
        const { intl, currentLanguage, dispatch } = this.props;
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        // @ts-ignore
        let props = currentLanguage.toLowerCase() === 'da' ? { tipChildren: <VideoPublishTipHtml intl={intl} dispatch={dispatch} />,
            tipTheme: {
                container: styles.videoPublishContainer,
                tipInfo: styles.videoPublishTipInfo,
                pointer: styles.videoPublishPointer,
                closeBtn: styles.videoPublishCloseBtn
            } } : { tipChildren: <PublishTipHtml intl={intl} dispatch={dispatch} />,
            tipTheme: {
                container: styles.container,
                tipInfo: styles.tipInfo,
                pointer: styles.pointer,
                closeBtn: styles.closeBtn
            } };

        return (
            // @ts-ignore
            <DemoTip
                position={TooltipPosition.BOTTOM}
                intl={intl}
                {...props}
            >
                {this.props.children}
            </DemoTip>
        );
    }
}

const mapStateToProps = (appState: AppState) => ({
    isDemo: isDemoSubscriptionType(appState.subscriptionData),
    currentLanguage: currentLanguageAppSel(appState)
});

export const DemoPublishTip = connect(mapStateToProps)(DemoPublishTipClass);
