import React from "react";
import cx from "classnames";
import { useDispatch } from "react-redux";
import { Button, IconButton } from "@mui/material";
import { useIntl } from "../../../../../../wbtgen/src/view/intl";
import imageStyles from '../../Images/imageStyle.css';
import { bookingsTrialLearnMoreClickAction, openBookingsWebsiteAction } from "./actions";
import { savePreferencesAction } from "../../Preferences/actions";
import { CloseIcon } from "../../../common/icons";
import { useStyles } from "./BookingsTrialTooltipStyles";

export const HIDE_BOOKINGS_TRIAL_TOOLTIP = 'hideBookingsTrialTooltip';

export const BookingsTrialTooltip = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const classes = useStyles();

    const hideTooltip = () => dispatch(savePreferencesAction({ [HIDE_BOOKINGS_TRIAL_TOOLTIP]: true }));

    const handleLearnMore = () => {
        dispatch(openBookingsWebsiteAction());
        dispatch(bookingsTrialLearnMoreClickAction(`Tooltip on 'Bookings' in navigation`));
        hideTooltip();
    };

    const stopChildElementClickAction = (event: React.MouseEvent<HTMLElement>) => event.stopPropagation();

    return (
        <div className={cx(classes.container)} onClick={stopChildElementClickAction}>
            <IconButton
                aria-label="close"
                className={cx(classes.closeButton)}
                onClick={hideTooltip}
                data-testid="tooltip-close-icon"
                size="large"
                disableRipple
            >
                <CloseIcon />
            </IconButton>
            <div className={cx(classes.tooltipContent)}>
                <div className={cx(imageStyles.bookingsCalendar, imageStyles.regularImageStyles)} />
                <div className={cx(classes.tooltipText)}>
                    <span className={classes.title}>
                        {intl.msgJoint("msg: bookings.dialogHeader {Exclusive free bookings trial!}")}
                    </span>

                    <span className={classes.description}>
                        {intl.msgJoint("msg: bookings.dialogContent {Do you offer services and want your customers to book on your website 24/7? We've partnered with Trafft to offer you a 3-month, free trial of their booking tool.}")}
                    </span>
                </div>
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.learnMoreBtn}
                    onClick={handleLearnMore}
                >
                    {intl.msgJoint("msg: common.learnMore {Learn more}")}
                </Button>
            </div>
        </div>
    );
};
