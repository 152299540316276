import type { Element, HtmlWriterData } from '../../flowTypes';
import { listItemMapper } from '../mappers/index';
import { addStyleToListItem } from "./utils/index";

export default (
    component: HtmlWriterData,
    styles: Array<Element>,
    listParagraphs: Array<Element>
): Array<Element> => {
    const { paras: paraStyles } = component;
    let styledListItems;

    // @ts-ignore
    styledListItems = addStyleToListItem(paraStyles, listParagraphs);
    styledListItems = addStyleToListItem(styles, styledListItems);

    return styledListItems.map(listItemMapper);
};
