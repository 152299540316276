import * as R from "ramda";

const textStyleParaMapper = (atype: string, propName: string) => ({
    to: R.evolve({
        [propName]: R.map(style => ({
            start: style[0],
            end: style[1],
            ...style[2],
            atype
        }))
    }),
    back: R.evolve({
        /* eslint-disable no-param-reassign */
        [propName]: R.map(style => {
            const type = propName === 'paras' ? 'web.data.styles.StylePara' : 'web.data.styles.StyleText';
            let tmpStyle = { ...style, type };
            delete tmpStyle.start;
            delete tmpStyle.end;
            delete tmpStyle.atype;
            return ([style.start, style.end, { ...tmpStyle }]);
        })
        /* eslint-disable no-param-reassign */
    })
});
export default textStyleParaMapper;
