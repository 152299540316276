/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { makeEpicStateSelector } from '../../../../epics/makeEpic';
import { tinyMcePickLinkedFieldEpic } from '../../../App/epics/tinyMceEpic/tinyMcePickLinkedFieldEpic';
import type { TinyMcePickLinkedFieldEpicState } from '../../../App/epics/tinyMceEpic/tinyMcePickLinkedFieldEpic';
import * as styles from './styles.css';
import NoMouseEventsPropagation from '../../../../view/common/NoMouseEventsPropagation';
import {
    TINY_MCE_PICK_LINKED_VALUE_CTX_MENU_ITEM_CLICK,
    TINY_MCE_PICK_LINKED_VALUE_CTX_MENU_ITEM_HOVER,
    TINY_MCE_PICK_LINKED_VALUE_CTX_MENU_MOUSE_LEAVE
} from '../../../App/epics/tinyMceEpic/actionTypes';
import { RootView } from '../../../RootView/RootView';
import { AppState } from '../../../../redux/modules/flowTypes';

type Props = {
    dispatch: Dispatch,
    state: TinyMcePickLinkedFieldEpicState
}
const stateSelector = makeEpicStateSelector(tinyMcePickLinkedFieldEpic.valueActionType);
export const PickLinkedValueCtxMenu = connect((appState: AppState) => ({ state: stateSelector(appState) }))((props: Props) => {
    const { userInput, pickLinkedFieldCtxMenu } = props.state;

    if (!pickLinkedFieldCtxMenu) return null;

    const { position, fieldNames, focusFieldName } = pickLinkedFieldCtxMenu;
    return (
        <RootView>
            <div className={styles.backdrop} />
            <div
                className={styles.ctxMenu}
                style={position}
                {...NoMouseEventsPropagation}
                onMouseLeave={() => props.dispatch({ type: TINY_MCE_PICK_LINKED_VALUE_CTX_MENU_MOUSE_LEAVE })}
            >
                <div className={styles.userInput}>{userInput}</div>
                {
                    fieldNames.map(fieldName => (
                        <div
                            key={fieldName}
                            className={fieldName === focusFieldName ? styles.hover : null}
                            onClick={() => props.dispatch({ type: TINY_MCE_PICK_LINKED_VALUE_CTX_MENU_ITEM_CLICK, payload: fieldName })}
                            onMouseEnter={() => props.dispatch({ type: TINY_MCE_PICK_LINKED_VALUE_CTX_MENU_ITEM_HOVER, payload: fieldName })}
                        >
                            {fieldName}
                        </div>
                    ))
                }
            </div>
        </RootView>
    );
});
