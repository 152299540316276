import { arrayToTrueMap } from "../../../../utils/arrayToTrueMap";

export const VERTICAL_SINGLE_LEVEL = "VERTICAL_SINGLE_LEVEL",
    VERTICAL_DROPDOWN = "VERTICAL_DROPDOWN",
    VERTICAL_TREE = "VERTICAL_TREE",
    HORIZONTAL_SINGLE_LEVEL = "HORIZONTAL_SINGLE_LEVEL",
    HORIZONTAL_DROPDOWN = "HORIZONTAL_DROPDOWN",
    horizontalLayoutTypesMap = arrayToTrueMap([HORIZONTAL_SINGLE_LEVEL, HORIZONTAL_DROPDOWN]),
    singleLevelMsg = "msg: component.menu.layoutType.singleLevel {Single level}",
    dropDownMsg = "msg: component.menu.layoutType.dropDown {Drop-down}",
    intlDict = {
        [HORIZONTAL_DROPDOWN]: "msg: component.menu.layoutType.horizontalDropdown {Horizontal dropdown}",
        [HORIZONTAL_SINGLE_LEVEL]: "msg: component.menu.layoutType.horizontalSingleLevel {Horizontal single level}",
        [VERTICAL_DROPDOWN]: "msg: component.menu.layoutType.verticalDropdown {Vertical dropdown}",
        [VERTICAL_TREE]: "msg: component.menu.layoutType.verticalTree {Vertical tree}",
        [VERTICAL_SINGLE_LEVEL]: "msg: component.menu.layoutType.verticalSingleLevel {Vertical single level}"
    };
