import React from "react";
import StandardDialog from '../../../../../view/common/dialogs/StandardDialog/index';
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import * as styles from './PublishConfirmation.css';
import Msg from '../../../../../view/intl/Msg';
import { PUBLISH_CONFIRM_BUTTON_CLICKED } from '../../../epics/publishSite/actionTypes';
import { SAVE_AND_PUBLISH_BUTTON_CLICKED } from "../../../../App/actionTypes";

type PublishConfirmationDialogProps = {
    publishViaAutosave ?: boolean;
    dispatch: Dispatch;
}

const
    PublishDialogWidth = parseInt(styles.PublishDialogWidth, 10),
    PublishDialogHeight = parseInt(styles.PublishDialogHeight, 10);

const PublishConfirmationDialog = ({ publishViaAutosave, dispatch }: PublishConfirmationDialogProps) => {
    const actionToDispatch = { type: publishViaAutosave ? SAVE_AND_PUBLISH_BUTTON_CLICKED : PUBLISH_CONFIRM_BUTTON_CLICKED };
    return (
        <StandardDialog
            title="msg: publish.publishDialogTitle {Ready to publish?}"
            mctaText="msg: publish.publishText {Publish}"
            mctaHandler={() => dispatch(actionToDispatch)}
            noTopBorderForFooter
        >
            <div className={styles.contentText}>
                <Msg k="publish.publishDialogDescription">
                    Click \"Publish\" to update your live website with all the changes.
                </Msg>
            </div>
        </StandardDialog>
    );
};

export default getCenteredDialogConfig(PublishDialogWidth, PublishDialogHeight, PublishConfirmationDialog);
