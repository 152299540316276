import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import imageStyles from "../../Images/imageStyle.css";
import { extraThemeConfig } from "../../../app/theme";

const useStyles = makeStyles(
    {
        select: {
            paddingLeft: "20px",
            paddingTop: "14px",
            paddingBottom: "14px",
            paddingRight: "48px !important",
            borderRadius: `${extraThemeConfig.borderRadius}px`,
            '&:focus': {
                borderRadius: `${extraThemeConfig.borderRadius}px`,
            },
        },
        arrow: {
            '& > div > div': {
                display: "inline-block"
            }
        },
        icon: {
            marginRight: "15px"
        }
    },
    { name: "PageSelector" }
);

const InsertArrow = ({ height }) => {
    const paddingLeft = 5 + (height - 1) * 25;

    return (
        <div style={{ paddingLeft }}>
            <div className={imageStyles.subPageArrow} />
        </div>
    );
};

export const createSelectItems = (items, classes) => {
    if (items.length) {
        return items.map((item) => {
            const isSubPage = item.treeHeight > 0;

            return (
                <MenuItem
                    key={item.pageId}
                    value={item.pageId}
                    className={isSubPage ? classes.arrow : undefined}
                >
                    {isSubPage && <InsertArrow height={item.treeHeight} />}
                    {item.name}
                </MenuItem>
            );
        });
    }

    return null;
};

const maxOptionsToShow = 10;
// This is default height, it will be higher in case of submenu. we are just considering default one
const heightOfOption = 36;

const MenuProps = {
    variant: 'menu',
    PaperProps: {
        style: {
            marginTop: "6px",
            width: 240,
            borderRadius: `${extraThemeConfig.borderRadius2}px`,
            maxHeight: heightOfOption * maxOptionsToShow
        }
    },
    MenuListProps: {
        style: {
            paddingTop: "20px",
            paddingBottom: "20px"
        }
    },
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left"
    },
    getContentAnchorEl: null
};

export const PageSelector = ({ items, selectedItemId, onChangeHandler }) => {
    const classes = useStyles();

    return (
        <Select
            classes={{
                select: classes.select,
                icon: classes.icon
            }}
            variant="outlined"
            value={selectedItemId}
            onChange={onChangeHandler}
            // @ts-ignore
            MenuProps={MenuProps}
            IconComponent={KeyboardArrowDownIcon}
        >
            {createSelectItems(items, classes)}
        </Select>
    );
};
