import { errorDialogStandardHeight, errorDialogStandardWidth } from "../../../dialogs/constants";
import { UploadRenameFileDialogComponent } from './UploadRenameFileDialogComponent';
import { uploadRenameDialogMakeDefaultState } from "./uploadRenameDialogMakeDefaultState";
import { uploadRenameDialogReducer } from "./uploadRenameDialogReducer";
import getCenteredDialogConfig from "../../../../../components/DialogManager/getCenteredDialogConfig";
import styles from "../FileChooserUpload.css";

export default getCenteredDialogConfig({
    width: errorDialogStandardWidth,
    height: errorDialogStandardHeight,
    makeDefaultState: uploadRenameDialogMakeDefaultState,
    reducer: uploadRenameDialogReducer,
    component: UploadRenameFileDialogComponent,
    forceModal: true,
    dialogBackgroundClassName: styles.dialogBackground
});
