import { assocPath, path } from 'ramda';

import {
    makeCombineReducer,
    makeComponentBaseReducers,
    makeDefaultStateReducers,
    makePayloadToSetterReducers,
    makeNegationPathPayloadToSetterReducers
} from "../../../redux/makeReducer";
import * as onClickActions from './constants/onClickActions';
import * as CAPTION_ALIGMENT from './constants/captionAligments';
import kind from './kind';
import {
    TOGGLE_MOBILE_DOWN_ACTION
} from "../../PropertiesPanel/view/MobileView/actions";
import mobileDownReducer from '../../PropertiesPanel/view/MobileView/mobileDownReducer';
import { reducers as captionDescriptionTextStyleReducers } from "./reducerCaptionDescriptionTextStyle";
import * as captionTileStyleReducers from "./reducerCaptionBoxStyle";
import * as actionTypes from './actionTypes';
import * as mobileSettings from "./reducerMobileSettings";
import { CaptionStyles } from "../../presentational/CaptionStyle/constants";
import { DRAG_AND_DROP_HEIGHT, DRAG_AND_DROP_WIDTH } from "./constants";
import type { FacebookFeedGalleryComponent } from "./flowTypes";
import { BUTTON_THEME_PRIMARY } from '../../ThemeGlobalData/constants';
import { formatMessage } from "../../../view/intl/CurrentLocale";

export default makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(kind, DRAG_AND_DROP_WIDTH, DRAG_AND_DROP_HEIGHT),
        ...makeDefaultStateReducers({
            images: [],
            rows: 2,
            spacingPx: 14,
            showMoreBtn: false,
            style: null,
            fontSizePx: 15,
            captionsEnabled: true,
            captionsAlignment: CAPTION_ALIGMENT.LEFT,
            captionTitleTextStyle: {},
            captionDescriptionTextStyle: {},
            captionMinHeight: 0,
            previousCaptionStyle: CaptionStyles.TEMPLATE,
            captionStyle: CaptionStyles.TEMPLATE,
            columns: 4,
            onClickAction: onClickActions.OPEN_LIGHT_BOX,
            imageStyle: {},
            captionBoxStyle: {},
            buttonThemeSelected: BUTTON_THEME_PRIMARY,
            mobileDown: false,
            profileImageAsset: null,
            mobileSettings: {
                columns: 2,
                spacingPx: 10
            },
            showMoreTxt: null,
        })
    },
    handleActions: {
        [TOGGLE_MOBILE_DOWN_ACTION]: mobileDownReducer,
        [actionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_FONT_FAMILY_CHANGE]:
            captionDescriptionTextStyleReducers.fontFamilyReducer,
        [actionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_FONT_COLOR_CHANGE]:
            captionDescriptionTextStyleReducers.fontColorReducer,
        [actionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_FONT_COLOR_CHANGE_AUTO_COLOR]:
        (component: FacebookFeedGalleryComponent, { payload }) => (
            assocPath(['captionDescriptionTextStyle', 'themeColor'])(payload.themeColor)(component)
        ),
        [actionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_FONT_SIZE_CHANGE]:
            captionDescriptionTextStyleReducers.fontSizeReducer,
        [actionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_TOGGLE_BOLD]: captionDescriptionTextStyleReducers.boldReducer,
        [actionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_TOGGLE_ITALIC]: captionDescriptionTextStyleReducers.italicReducer,
        [actionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_TOGGLE_UNDERLINE]:
            captionDescriptionTextStyleReducers.underlineReducer,
        [actionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_LINE_HEIGHT_CHANGE]:
            captionDescriptionTextStyleReducers.lineHeightReducer,
        [actionTypes.FACEBOOK_FEED_GALLERY_PP_SHOW_MORE_BTN_ENABLED]: (component, { payload: { isEnabled } }) => {
            const showMoreTxt = component.showMoreTxt || formatMessage({
                id: 'common.showMore',
                defaultMessage: 'Show more'
            });
            return {
                ...component,
                showMoreBtn: isEnabled,
                showMoreTxt,
            };
        },
        [actionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BORDER_STYLE]: captionTileStyleReducers.typeReducer,
        [actionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTION_BOX_BORDER_WIDTH]: captionTileStyleReducers.widthReducer,
        [actionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BORDER_OPACITY]: captionTileStyleReducers.opacityReducer,
        [actionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BORDER_COLOR]: captionTileStyleReducers.colorReducer,
        [actionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BORDER_COLOR_AUTO_COLOR]:
        (component: FacebookFeedGalleryComponent, { payload }) => (
            assocPath(['captionBoxStyle', 'border', 'themeColor'])(payload.themeColor)(component)
        ),
        [actionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_COLOR_SET]:
            captionTileStyleReducers.bgColorReducer,
        [actionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_COLOR_SET_AUTO_COLOR]:
        (component: FacebookFeedGalleryComponent, { payload }) => (
            assocPath(['captionBoxStyle', 'background', 'colorData', 'themeColor'])(payload.themeColor)(component)
        ),
        [actionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_COLOR_SET_IF_UNSET]: (component: FacebookFeedGalleryComponent, { payload }) => { // eslint-disable-line
            return (path(['captionBoxStyle', 'background', 'colorData', 'color'])(component)
                ? component
                : assocPath(['captionBoxStyle', 'background', 'colorData', 'color'])(payload.color)(component));
        },
        [actionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_COLOR_UNSET]: captionTileStyleReducers.bgColorUnsetReducer,
        [actionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_OPACITY_CHANGE]: captionTileStyleReducers.bgColorOpacityReducer,
        [actionTypes.FACEBOOK_FEED_GALLERY_PP_SHOW_MORE_BTN_STYLE_CHANGED]:
        (component: FacebookFeedGalleryComponent, { payload: { stylesheetId, stylesheetName } }) => {
            return {
                ...component,
                style: {
                    // @ts-ignore
                    ...component.style,
                    globalName: stylesheetName,
                    globalId: stylesheetId
                }
            };
        },
        [actionTypes.FACEBOOK_FEED_GALLERY_PP_PROFILE_IMAGE_CHANGE]:
        (component: FacebookFeedGalleryComponent, { payload: { asset } }) => {
            return {
                ...component,
                profileImageAsset: asset
            };
        },
        [actionTypes.FACEBOOK_FEED_GALLERY_PP_PROFILE_IMAGE_CLEAR]:
        (component: FacebookFeedGalleryComponent) => {
            return {
                ...component,
                profileImageAsset: null
            };
        },
        [actionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTIONS_STYLE]: (
            component: FacebookFeedGalleryComponent,
            { payload: captionStyle }: Action<any>
        ) => ({
            ...component,
            previousCaptionStyle: component.captionStyle,
            captionStyle
        }),
        [actionTypes.FACEBOOK_FEED_GALLERY_PP_SHOW_MORE_BTN_STYLE_CHANGED_AUTO_COLOR]: (component, { payload }) => (
            { ...component, buttonThemeSelected: payload }
        ),
        [actionTypes.FACEBOOK_FEED_GALLERY_MOBILE_COLUMN_CHANGE]: mobileSettings.columnReducer,
        [actionTypes.FACEBOOK_FEED_GALLERY_MOBILE_SPACING_CHANGE]: mobileSettings.spacingReducer,
        ...makePayloadToSetterReducers({
            [actionTypes.FACEBOOK_FEED_GALLERY_PP_ROWS_CHANGED]: 'rows',
            [actionTypes.FACEBOOK_FEED_GALLERY_PP_SPACING_CHANGED]: 'spacingPx',
            [actionTypes.FACEBOOK_FEED_GALLERY_PP_SHOW_MORE_BTN_FONTSIZE_CHANGED]: 'fontSizePx',
            [actionTypes.FACEBOOK_FEED_GALLERY_PP_COLUMNS_CHANGED]: 'columns',
            [actionTypes.FACEBOOK_FEED_GALLERY_ON_CLICK_ACTION_CHANGED]: 'onClickAction',
            [actionTypes.FACEBOOK_FEED_GALLERY_CAPTIONS_ALIGN_CHANGED]: 'captionsAlignment',
        }),
        ...makeNegationPathPayloadToSetterReducers({
            [actionTypes.FACEBOOK_FEED_GALLERY_CAPTIONS_ENABLED_CHECKBOX_CLICKED]: ['captionsEnabled']
        }),
    }
});

