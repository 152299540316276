import makeStateSelectorReducer from '../../../../epics/makeStateSelectorReducer';
import { getWidth } from '../../../../utils/bBox';
import valueActionType from './valueActionType';
import epic from './index';

export const workspaceWidthReducer = makeStateSelectorReducer(
        epic.reducer,
        valueActionType,
        state => {
            return state ? getWidth(state) : state;
        }
    ),
    workspaceLeftReducer = makeStateSelectorReducer(
        epic.reducer,
        valueActionType,
        state => {
            return state ? state.left : state;
        }
    );
