import * as R from 'ramda';
import {
    MODERN_LAYOUT_ONBOARDING_PANEL_EPIC_VALUE as ModernLayoutsPanelVAT
} from '../../components/ModernLayouts/epics/onBoardingPanel/valueActionType';
import makeEpic, { fullEpicUndoablePath } from '../makeEpic';
import valueActionType from './valueActionType';
import userFocusValueActionType from '../../components/App/epics/userFocus/valueActionType';
import GlobalstylesConfigurationDialogId from '../../components/Globalstyles/dialogId';
import * as UserFocusKind from '../../components/App/epics/userFocus/kind';
import { ANIMATION_FRAME } from "../../redux/middleware/raf";
import { REDO_BUTTON_PRESSED, UNDO_BUTTON_PRESSED } from '../../components/TopBar/actionTypes';
import * as appActionTypes from '../../components/App/actionTypes';
import * as actionTypes from './actionTypes';
import * as updateReasons from './updateReasons';
import type { Epic } from '../flowTypes';
import { receiveOnly } from "../makeCondition";
import { ROOpenedDialogsIdsSelector } from "../../components/DialogManager/epic/selectorActionTypes";
import { mobileViewEditorVAT } from "../../components/MobileViewEditor/epics/reorder/valueActionType";
import { createScheduleAction } from "../../components/Workspace/epics/resizeDecos/actionCreators";
import {
    makeMobileViewEditorInvisibleAC,
    makeMobileViewEditorVisibleAC
} from "../../components/MobileViewEditor/epics/reorder/actions";
import { UndoRedoTimeGap } from "../../components/MobileViewEditor/constants";
import {
    MOBILE_EDITOR_TOGGLE_HIDE, MOBILE_EDITOR_HIDE_COMPONENTS, MOBILE_EDITOR_COMPONENT_MOUSE_UP,
    MOBILE_EDITOR_DELETE_KEY_PRESSED, MOBILE_EDITOR_UN_HIDE_COMPONENTS, MOBILE_EDITOR_ACTION_HIDE_CLICKED,
    MOBILE_EDITOR_TOGGLE_BOTTOM_LOCK, MOBILE_EDITOR_UNLOCK_COMPONENTS, MOBILE_EDITOR_LOCK_COMPONENTS,
    MOBILE_EDITOR_CHANGE_BG_POSITION, MOBILE_EDITOR_COMPONENT_MOUSE_UP_OUTSIDE
} from "../../components/MobileViewEditor/actionTypes";
import { SiteSettingsDialogId } from "../../components/SiteSettings/SiteSettingsDialog/constants";
import { mobileGlobalDataEpic } from '../../components/MobileViewEditor/header/mobileGlobalDataEpic';
import {
    generalGlobalDataEpic
} from '../../components/SiteSettings/General/generalGlobalDataEpic/generalGlobalDataEpic';
import TemplateWidthDialogId from "../../components/oneweb/Template/templateWidthDialog/TemplateWidthDialogId";
import {
    clearUndoCommandStack,
    resetUndoStackAndApplyChange
} from "../../components/ModernLayouts/epics/leftPanel/undoUpdaters";
import {
    UPDATE_MHF_COMPONENTS_POSITION,
    ACTIVATE_MODERN_HEADER,
    ACTIVATE_MODERN_FOOTER,
    MHF_COMPONENTS_TOGGLE
} from "../../components/ModernLayouts/actionTypes";
import { FLUSH_ADJUSTMENT_CACHE } from "../../components/Workspace/epics/componentsEval/actionTypes";
import { TutorialVideoDialogId } from '../../components/TopBar/view/dialogIds';

type AffectedEpic = {
    newStateParts: Array<AnyValue>
}

type Command = {
    sourceAction: AnyAction,
    affectedEpics: MapT<AffectedEpic>,
    timeStamp?: number
}

type UndoState = {
    commandsStack: Array<Command>,
    commandsStackIndex: number,
    scopedCommandsStackIndex: null | undefined | number,
    canRedoInScopedMode: boolean,
}

export type UndoEpicState = {
    lastEpicStates: {},
    undoState: UndoState
}

const
    MAX_AMEND_INTERVAL_MS = 1500,
    timestampPropName = 'timeStamp',
    skipUpdateReasons = {
        [updateReasons.UNDO]: true,
        [updateReasons.REDO]: true
    },
    skipActionTypes = {
        [appActionTypes.CTRL_Z_PRESSED]: true,
        [appActionTypes.CTRL_SHIFT_Z_PRESSED]: true,
        [appActionTypes.CTRL_Y_PRESSED]: true,
        [UNDO_BUTTON_PRESSED]: true,
        [actionTypes.UNDO]: true,
        [actionTypes.REDO]: true,
        [REDO_BUTTON_PRESSED]: true,
        [UPDATE_MHF_COMPONENTS_POSITION]: true,
    },
    mobileActionTypes = {
        [MOBILE_EDITOR_TOGGLE_HIDE]: true,
        [MOBILE_EDITOR_DELETE_KEY_PRESSED]: true,
        [MOBILE_EDITOR_UN_HIDE_COMPONENTS]: true,
        [MOBILE_EDITOR_HIDE_COMPONENTS]: true,
        [MOBILE_EDITOR_TOGGLE_BOTTOM_LOCK]: true,
        [MOBILE_EDITOR_UNLOCK_COMPONENTS]: true,
        [MOBILE_EDITOR_LOCK_COMPONENTS]: true,
        [MOBILE_EDITOR_ACTION_HIDE_CLICKED]: true,
        [MOBILE_EDITOR_CHANGE_BG_POSITION]: true,
        [MOBILE_EDITOR_COMPONENT_MOUSE_UP]: true,
        [MOBILE_EDITOR_COMPONENT_MOUSE_UP_OUTSIDE]: true,
    },
    skipMhfPositionTypes = {
        [FLUSH_ADJUSTMENT_CACHE]: true
    },
    mhfUpdateActionTypes = {
        [ACTIVATE_MODERN_FOOTER]: true,
        [ACTIVATE_MODERN_HEADER]: true,
        [MHF_COMPONENTS_TOGGLE]: true
    },
    makeAffectedEpic = ({ undoablePaths, undoableEpicState }) => {
        return {
            newStateParts: undoablePaths.map(
                undoablePath => R.path(undoablePath, undoableEpicState)
            )
        };
    },
    initialStateAction = { type: '_UNDO_INITAL_STATE' },
    defaultState = {
        lastEpicStates: {},
        undoState: {
            commandsStackIndex: 0,
            commandsStack: [{
                sourceAction: initialStateAction,
                affectedEpics: {},
            }],
            scopedCommandsStackIndex: null,
            canRedoInScopedMode: false,
        },
    },
    defaultScopedCommandsStackIndex = R.path(['undoState', 'scopedCommandsStackIndex'], defaultState),
    makeAddNewCommandUpdater = ({
        undoablePaths,
        undoableEpicState,
        valueActionType,
        sourceAction,
        now
    }) =>
        (undoState: UndoState) => {
            const
                { commandsStackIndex, commandsStack } = undoState,
                stackIndexIsInTheMiddle = commandsStack.length > commandsStackIndex + 1,
                removeStackTail = R.take(commandsStackIndex + 1),
                affectedEpic = makeAffectedEpic({ undoablePaths, undoableEpicState }),
                lastSourceAction = R.last(undoState.commandsStack).sourceAction,
                lastAmendTimestamp = R.path([commandsStackIndex, timestampPropName], commandsStack),
                timestampFn = R.assoc(timestampPropName, now),
                isAmendToCase = () => (
                    sourceAction.amendTo
                    && sourceAction.amendTo === R.path(['sourceAction', 'type'], commandsStack[commandsStackIndex])
                ),
                isAmendToSelfCase = () => (
                    sourceAction.amendToSelf
                    && sourceAction.type === lastSourceAction.type
                    && (
                        !sourceAction.amendToSelfTag || sourceAction.amendToSelfTag === lastSourceAction.amendToSelfTag
                    )
                    && (!lastAmendTimestamp || now - lastAmendTimestamp < MAX_AMEND_INTERVAL_MS)
                    && !stackIndexIsInTheMiddle
                ),
                isAmendToPreviousCase = () => (
                    sourceAction.amendToPrevious &&
                    commandsStack.length !== 0
                ),
                isMhfPositionUpdateCase = () => (
                    skipMhfPositionTypes[sourceAction.type] && mhfUpdateActionTypes[lastSourceAction.type]
                );

            let commandsStackUpdater,
                commandsStackIndexUpdater;

            if (lastSourceAction === sourceAction) {
                commandsStackUpdater = commandsStack => {
                    const
                        prevCommands = R.dropLast(1, commandsStack),
                        lastCommand = R.last(commandsStack);

                    return [
                        ...prevCommands,
                        R.evolve({ affectedEpics: R.assoc(valueActionType, affectedEpic) }, lastCommand)
                    ];
                };

                commandsStackIndexUpdater = R.identity;
            } else if (isAmendToCase() || isAmendToSelfCase() || isAmendToPreviousCase() || isMhfPositionUpdateCase()) {
                commandsStackUpdater = commandsStack => {
                    const index = undoState.commandsStackIndex,
                        currentCommand = commandsStack[index],
                        newCommandsStack = [...commandsStack];
                    newCommandsStack[index] = R.pipe(
                        R.evolve({
                            affectedEpics: R.assoc(valueActionType,
                                R.mergeDeepRight(
                                    R.path(['affectedEpics', valueActionType], currentCommand),
                                    affectedEpic
                                ))
                        }),
                    )(currentCommand);
                    return newCommandsStack;
                };
            } else {
                commandsStackUpdater = R.append({
                    sourceAction,
                    affectedEpics: { [valueActionType]: affectedEpic }
                });

                commandsStackIndexUpdater = R.inc;
            }

            return R.evolve({
                commandsStack: R.pipe(
                    stackIndexIsInTheMiddle && !sourceAction.amendTo ? removeStackTail : R.identity,
                    commandsStackUpdater,
                    (stack) => [...R.dropLast(1, stack), timestampFn(R.last(stack))]
                ),
                commandsStackIndex: commandsStackIndexUpdater,
                canRedoInScopedMode: R.always(
                    R.not(R.propEq('scopedCommandsStackIndex', defaultScopedCommandsStackIndex, undoState))
                ),
            }, undoState);
        },
    makeUndoableEpicsListenerUpdaters = (epics) => {
        const undoableEpics = epics.filter(({ undo }) => undo);

        return undoableEpics.map(({
            undo,
            valueActionType
        }) => {
            let
                isUndoableChange = R.T,
                undoablePaths = fullEpicUndoablePath,
                amendToLatestPointCases: Record<string, any> = {};

            if (undo !== true && undo !== undefined) {
                if (undo.isUndoableChange) {
                    isUndoableChange = undo.isUndoableChange;
                }
                if (undo.undoablePaths) {
                    undoablePaths = undo.undoablePaths;
                }
                if (undo.amendToLatestPointCases) {
                    amendToLatestPointCases = undo.amendToLatestPointCases;
                }
            }
            const newStatePartsPath = ['affectedEpics', valueActionType, 'newStateParts'];
            return {
                keepFullActions: true,
                conditions: [
                    valueActionType,
                    receiveOnly(ANIMATION_FRAME)
                ],
                reducer: ({
                    values: [{ payload: undoableEpicState, epicUpdateReason }, { payload: { ts } }],
                    state: undoManagerState,
                    sourceAction
                }) => {
                    if (epicUpdateReason === updateReasons.UNDO_INITIAL_STATE) {
                        const affectedEpic = makeAffectedEpic({ undoablePaths, undoableEpicState });
                        return {
                            state: R.evolve({
                                lastEpicStates: R.assoc(valueActionType, undoableEpicState),
                                undoState: {
                                    commandsStack: commandsStack => {
                                        return [
                                            R.assocPath(
                                                ['affectedEpics', valueActionType],
                                                affectedEpic,
                                                commandsStack[0]
                                            )
                                        ];
                                    },
                                    commandsStackIndex: R.always(0),
                                }
                            }, undoManagerState),
                            updateReason: 'RESET_TO_INITIAL_STATE'
                        };
                    }

                    const { commandsStack, commandsStackIndex } = undoManagerState.undoState;
                    if (commandsStackIndex === commandsStack.length - 1) {
                        const amendToLatestPointCaseConfig = amendToLatestPointCases[epicUpdateReason];
                        if (amendToLatestPointCaseConfig) {
                            const nextAffectedEpic = makeAffectedEpic({ undoablePaths, undoableEpicState });

                            return {
                                state: R.evolve({
                                    lastEpicStates: R.assoc(valueActionType, undoableEpicState),
                                    undoState: {
                                        commandsStack: commandsStack => {
                                            const
                                                lastCommand = R.last(commandsStack),
                                                commandsBeforeLast = R.dropLast(1, commandsStack);

                                            if (!lastCommand.affectedEpics[valueActionType]) {
                                                return [
                                                    ...commandsBeforeLast,
                                                    R.assocPath(
                                                        ['affectedEpics', valueActionType],
                                                        nextAffectedEpic,
                                                        lastCommand
                                                    )
                                                ];
                                            }

                                            const
                                                prevNewStateParts = R.path(newStatePartsPath, lastCommand),
                                                nextNewStateParts = nextAffectedEpic.newStateParts;

                                            let mergedNewStateParts;

                                            if (amendToLatestPointCaseConfig === true) {
                                                mergedNewStateParts = nextAffectedEpic.newStateParts;
                                            } else if (amendToLatestPointCaseConfig.merge) {
                                                mergedNewStateParts = amendToLatestPointCaseConfig.merge(
                                                    prevNewStateParts, nextNewStateParts, undoableEpicState
                                                );
                                            } else {
                                                throw new Error(
                                                    'amendToLatestPointCases case should have merge fn, or be a boolean'
                                                );
                                            }

                                            return [
                                                ...commandsBeforeLast,
                                                R.assocPath(newStatePartsPath, mergedNewStateParts, lastCommand)
                                            ];
                                        }
                                    }
                                }, undoManagerState),
                                updateReason: 'NEW_ENTRY_AMENDED'
                            };
                        }
                    }

                    if (
                        skipUpdateReasons[epicUpdateReason] || skipActionTypes[sourceAction.type] ||
                        undoableEpicState === undoManagerState.lastEpicStates[valueActionType]
                    ) {
                        return { state: undoManagerState };
                    }

                    const shoudAddChangeToUndoStack = isUndoableChange({
                        prevState: undoManagerState.lastEpicStates[valueActionType] || undoableEpicState,
                        nextState: undoableEpicState,
                        updateReason: epicUpdateReason,
                        sourceAction
                    });

                    if (shoudAddChangeToUndoStack) {
                        const newState = R.evolve({
                            lastEpicStates: R.assoc(valueActionType, undoableEpicState),
                            undoState: makeAddNewCommandUpdater({
                                undoablePaths,
                                undoableEpicState,
                                valueActionType,
                                sourceAction,
                                now: ts
                            })
                        }, undoManagerState);
                        return {
                            state: newState,
                            actionToDispatch: ({
                                type: actionTypes.UNDO_STACK_MODIFIED, payload: newState.undoState.commandsStackIndex,
                            } as AnyAction),
                            updateReason: 'NEW_ENTRY_ADDED'
                        };
                    } else {
                        return { state: undoManagerState };
                    }
                }
            };
        });
    },
    undoableDialogs = [
        GlobalstylesConfigurationDialogId,
        SiteSettingsDialogId,
        TemplateWidthDialogId,
        TutorialVideoDialogId
    ],
    isUndoableFocus = (userFocus, openedDialogIds) => {
        switch (userFocus.kind) {
            case UserFocusKind.WORKSPACE:
            case UserFocusKind.MOBILE_EDITOR:
            case UserFocusKind.EDIT_TEXT_COMPONENT:
            case UserFocusKind.EDIT_TABLE_COMPONENT:
            case UserFocusKind.EDIT_TABLE_COMPONENT_CELL:
                return true;
            case UserFocusKind.DIALOG:
                return undoableDialogs.some((dialogId) => openedDialogIds.includes(dialogId));
            default:
                return false;
        }
    },
    makeUndoRedoReducer = ({
        actionType,
        getAffectedEpicsVATsIndex,
        commandsStackIndexUpdateOp,
        borderCheck
    }) =>
        ({ state, state: { undoState }, values: [userFocus, modernLayoutPanelState, openedDialogIds] }) => {
            const { show: modernLayoutPanelOpen } = modernLayoutPanelState;
            if (!isUndoableFocus(userFocus, openedDialogIds) || borderCheck(undoState) || modernLayoutPanelOpen) {
                return { state };
            }

            const affectedEpicsVATsIndex = getAffectedEpicsVATsIndex(undoState.commandsStackIndex),
                affectedEpicsVATs = Object.keys(undoState.commandsStack[affectedEpicsVATsIndex].affectedEpics),
                affectedSourceAction = undoState.commandsStack[affectedEpicsVATsIndex].sourceAction,
                nextIndex = commandsStackIndexUpdateOp(undoState.commandsStackIndex),
                affectedEpics = {};

            affectedEpicsVATs.forEach(affectedVat => {
                for (let i = nextIndex; i >= 0; i--) {
                    const affectedEpic = undoState.commandsStack[i].affectedEpics[affectedVat];
                    if (affectedEpic) {
                        affectedEpics[affectedVat] = affectedEpic;
                        break;
                    }
                }
            });

            const undoOrRedoAction = {
                    type: actionType,
                    payload: { affectedEpics },
                    updateReason: actionType
                },
                multipleActionsToDispatch: Action[] = [];

            let nextState = R.assocPath(['undoState', 'commandsStackIndex'], nextIndex, state);

            if (userFocus.kind === UserFocusKind.MOBILE_EDITOR &&
                affectedEpicsVATs.every(
                    v => [
                        mobileViewEditorVAT,
                        generalGlobalDataEpic.valueActionType,
                        mobileGlobalDataEpic.valueActionType
                    ].indexOf(v) === -1,
                )
                && (!mobileActionTypes[affectedSourceAction.type] && !affectedSourceAction.fromMVE)
            ) {
                // make mobile view editor invisible
                // undo/redo again
                multipleActionsToDispatch.push(makeMobileViewEditorInvisibleAC());
                multipleActionsToDispatch.push(createScheduleAction(undoOrRedoAction, UndoRedoTimeGap));
            } else if (
                userFocus.kind !== UserFocusKind.MOBILE_EDITOR && affectedEpicsVATs.includes(mobileViewEditorVAT)
            ) {
                // make mobile view editor visible
                // undo/redo again
                multipleActionsToDispatch.push(makeMobileViewEditorVisibleAC());
                multipleActionsToDispatch.push(createScheduleAction(undoOrRedoAction, UndoRedoTimeGap));
            } else {
                multipleActionsToDispatch.push(undoOrRedoAction);
            }

            return ({
                state: nextState,
                multipleActionsToDispatch,
                updateReason: actionType
            });
        },
    getCommandsStackIndexAtEnd = R.pipe(R.prop('commandsStack'), R.length, R.dec),
    isCommandsStackIndexAt = R.propEq('commandsStackIndex'),
    isCommandsStackIndexAtEnd = undoState => isCommandsStackIndexAt(getCommandsStackIndexAtEnd(undoState), undoState),
    isCommandsStackIndexAtStart = isCommandsStackIndexAt(0),
    isNotInScopedMode = R.propEq('scopedCommandsStackIndex', defaultScopedCommandsStackIndex),
    undoBorderCheck = (undoState: UndoState) => {
        return R.ifElse(
            isNotInScopedMode,
            isCommandsStackIndexAtStart,
            R.propSatisfies(R.lt(undoState.commandsStackIndex - 1), 'scopedCommandsStackIndex'),
        )(undoState);
    },
    redoBorderCheck = (undoState: UndoState) => {
        return R.ifElse(
            isNotInScopedMode,
            isCommandsStackIndexAtEnd,
            R.either(
                R.complement(R.prop('canRedoInScopedMode')),
                isCommandsStackIndexAtEnd,
            ),
        )(undoState);
    },
    undoUpdaterFactory = triggerActionType => ({
        conditions: [
            receiveOnly(userFocusValueActionType),
            receiveOnly(ModernLayoutsPanelVAT),
            ROOpenedDialogsIdsSelector,
            triggerActionType
        ],
        reducer: makeUndoRedoReducer({
            actionType: actionTypes.UNDO,
            getAffectedEpicsVATsIndex: R.identity,
            commandsStackIndexUpdateOp: R.dec,
            borderCheck: undoBorderCheck
        })
    }),
    redoUpdaterFactory = triggerActionType => ({
        conditions: [
            receiveOnly(userFocusValueActionType),
            receiveOnly(ModernLayoutsPanelVAT),
            ROOpenedDialogsIdsSelector,
            triggerActionType
        ],
        reducer: makeUndoRedoReducer({
            actionType: actionTypes.REDO,
            getAffectedEpicsVATsIndex: R.inc,
            commandsStackIndexUpdateOp: R.inc,
            borderCheck: redoBorderCheck
        })
    }),
    enterScopedModeUpdater = {
        conditions: [actionTypes.ENTER_SCOPED_UNDO_MODE],
        reducer: ({ state }) => ({
            state: R.assocPath(['undoState', 'scopedCommandsStackIndex'], state.undoState.commandsStackIndex, state),
            updateReason: actionTypes.ENTER_SCOPED_UNDO_MODE,
        }),
    },
    exitScopedModeUpdater = {
        conditions: [actionTypes.EXIT_SCOPED_UNDO_MODE],
        reducer: ({ state }) => ({
            state: R.mergeDeepRight(state, {
                undoState: R.pick(['scopedCommandsStackIndex', 'canRedoInScopedMode'], defaultState.undoState),
            }),
            updateReason: actionTypes.EXIT_SCOPED_UNDO_MODE,
        }),
    },
    setCommandsStackIndexToAndFlush = {
        conditions: [actionTypes.RESET_UNDO_STACK_INDEX_AND_FLUSH],
        reducer: ({ state, values: [commandsStackIndex] }) => {
            if (commandsStackIndex >= 0 && commandsStackIndex <= getCommandsStackIndexAtEnd(state.undoState)) {
                return {
                    state: {
                        ...state,
                        undoState: {
                            ...state.undoState,
                            commandsStackIndex,
                            commandsStack: state.undoState.commandsStack.slice(0, commandsStackIndex + 1),
                        },
                    },
                    updateReason: actionTypes.RESET_UNDO_STACK_INDEX_AND_FLUSH,
                };
            } else {
                return { state };
            }
        },
    },
    undoEpicFactory = ({ epics }: { epics: Array<Epic<AnyValue, AnyValue, string>> }) => makeEpic({
        defaultState,
        valueActionType,
        updaters: [
            undoUpdaterFactory(appActionTypes.CTRL_Z_PRESSED),
            undoUpdaterFactory(UNDO_BUTTON_PRESSED),
            redoUpdaterFactory(appActionTypes.CTRL_SHIFT_Z_PRESSED),
            redoUpdaterFactory(appActionTypes.CTRL_Y_PRESSED),
            redoUpdaterFactory(REDO_BUTTON_PRESSED),
            enterScopedModeUpdater,
            exitScopedModeUpdater,
            setCommandsStackIndexToAndFlush,
            ...makeUndoableEpicsListenerUpdaters(epics),
            clearUndoCommandStack,
            resetUndoStackAndApplyChange
        ]
    });

export {
    undoEpicFactory as default,
    undoBorderCheck,
    redoBorderCheck
};
