export const FEATURED_PRODUCTS_UPDATE_PRODUCTS = 'FEATURED_PRODUCTS_UPDATE_PRODUCTS';
export const FEATURED_PRODUCTS_UPDATE_PRODUCTS_PROPERTY = 'FEATURED_PRODUCTS_UPDATE_PRODUCTS_PROPERTY';
export const FEATURED_PRODUCTS_UPDATE_PRODUCTS_PER_ROW = 'FEATURED_PRODUCTS_UPDATE_PRODUCTS_PER_ROW';
export const FEATURED_PRODUCTS_FONT_FAMILY_CHANGED = 'FEATURED_PRODUCTS_FONT_FAMILY_CHANGED';
export const FEATURED_PRODUCTS_TEXT_COLOR_CHANGED = 'FEATURED_PRODUCTS_TEXT_COLOR_CHANGED';
export const FEATURED_PRODUCTS_TEXT_COLOR_CHANGED_AUTO_COLOR = 'FEATURED_PRODUCTS_TEXT_COLOR_CHANGED_AUTO_COLOR';
export const FEATURED_PRODUCTS_FOCUS_COLOR_CHANGED = 'FEATURED_PRODUCTS_FOCUS_COLOR_CHANGED';
export const FEATURED_PRODUCTS_FOCUS_COLOR_CHANGED_AUTO_COLOR = 'FEATURED_PRODUCTS_FOCUS_COLOR_CHANGED_AUTO_COLOR';
export const FEATURED_PRODUCTS_HOVER_COLOR_CHANGED = 'FEATURED_PRODUCTS_HOVER_COLOR_CHANGED';
export const FEATURED_PRODUCTS_HOVER_COLOR_CHANGED_AUTO_COLOR = 'FEATURED_PRODUCTS_HOVER_COLOR_CHANGED_AUTO_COLOR';
export const FEATURED_PRODUCTS_HOVER_OPACITY_CHANGING = 'FEATURED_PRODUCTS_HOVER_OPACITY_CHANGING';
export const FEATURED_PRODUCTS_HOVER_OPACITY_CHANGED = 'FEATURED_PRODUCTS_HOVER_OPACITY_CHANGED';
export const FEATURED_PRODUCTS_IMAGE_RATIO_CHANGED = 'FEATURED_PRODUCTS_IMAGE_RATIO_CHANGED';
export const FEATURED_PRODUCTS_PRODUCTS_PER_PAGE_CHANGED = 'FEATURED_PRODUCTS_PRODUCTS_PER_PAGE_CHANGED';
export const FEATURED_PRODUCTS_PRODUCTS_PER_ROW_CHANGED = 'FEATURED_PRODUCTS_PRODUCTS_PER_ROW_CHANGED';
export const FEATURED_PRODUCTS_PRODUCTS_PER_ROW_IN_MOBILE_CHANGED = 'FEATURED_PRODUCTS_PRODUCTS_PER_ROW_IN_MOBILE_CHANGED';
export const FEATURED_PRODUCTS_LABEL_BG_COLOR_CHANGED = 'FEATURED_PRODUCTS_LABEL_BG_COLOR_CHANGED';
export const FEATURED_PRODUCTS_LABEL_BG_COLOR_CHANGED_AUTO_COLOR = 'FEATURED_PRODUCTS_LABEL_BG_COLOR_CHANGED_AUTO_COLOR';
export const FEATURED_PRODUCTS_LABEL_TEXT_COLOR_CHANGED = 'FEATURED_PRODUCTS_LABEL_TEXT_COLOR_CHANGED';
export const FEATURED_PRODUCTS_LABEL_TEXT_COLOR_CHANGED_AUTO_COLOR = 'FEATURED_PRODUCTS_LABEL_TEXT_COLOR_CHANGED_AUTO_COLOR';
export const FEATURED_PRODUCTS_LABEL_POSITION_CHANGED = 'FEATURED_PRODUCTS_LABEL_POSITION_CHANGED';
export const FEATURED_PRODUCTS_CROP_IMAGES_CHANGED = 'FEATURED_PRODUCTS_CROP_IMAGES_CHANGED';
export const FEATURED_PRODUCTS_PRODUCT_APPEARANCE_CHANGED = 'FEATURED_PRODUCTS_PRODUCT_APPEARANCE_CHANGED';
export const FEATURED_PRODUCTS_SPACING_ITEMS_IN_MOBILE_CHANGED = 'FEATURED_PRODUCTS_SPACING_ITEMS_IN_MOBILE_CHANGED';
export const FEATURED_PRODUCTS_CTA_CLICKED = 'FEATURED_PRODUCTS_CTA_CLICKED';
export const FEATURED_PRODUCTS_UPDATE_MOBILE_PREVIEW = 'FEATURED_PRODUCTS_UPDATE_MOBILE_PREVIEW';
export const FEATURED_PRODUCTS_UPDATE_HEIGHT = 'FEATURED_PRODUCTS_UPDATE_HEIGHT';
export const FEATURED_PRODUCTS_UPDATE_WIDTH = 'FEATURED_PRODUCTS_UPDATE_WIDTH';
export const FEATURED_PRODUCTS_UPDATE_TIMESTAMP = 'FEATURED_PRODUCTS_UPDATE_TIMESTAMP';
export const FEATURED_PRODUCTS_CLOSE_WARNING = 'FEATURED_PRODUCTS_CLOSE_WARNING';
export const FEATURED_PRODUCTS_UPDATE_SHOP_COMPONENT_EXISTS = 'FEATURED_PRODUCTS_UPDATE_SHOP_COMPONENT_EXISTS';
export const FEATURED_PRODUCTS_COMPONENT_DID_MOUNT = 'FEATURED_PRODUCTS_COMPONENT_DID_MOUNT';
export const FEATURED_PRODUCTS_UPDATE_NEW_HEIGHT = 'FEATURED_PRODUCTS_UPDATE_NEW_HEIGHT';
export const FEATURED_PRODUCTS_UPDATE_SUBSCRIPTION_COMPATIBILITY_STATUS = 'FEATURED_PRODUCTS_UPDATE_SUBSCRIPTION_COMPATIBILITY_STATUS';

export const FEATURED_PRODUCTS_VIEW_DETAILS_SHOW_BUTTON_EXISTS = 'FEATURED_PRODUCTS_VIEW_DETAILS_SHOW_BUTTON_EXISTS';
export const FEATURED_PRODUCTS_VIEW_DETAILS_UPDATE_BUTTON_TEXT = 'FEATURED_PRODUCTS_VIEW_DETAILS_UPDATE_BUTTON_TEXT';
export const FEATURED_PRODUCTS_VIEW_DETAILS_UPDATE_BUTTON_STYLE = 'FEATURED_PRODUCTS_VIEW_DETAILS_UPDATE_BUTTON_STYLE';
export const FEATURED_PRODUCTS_VIEW_DETAILS_UPDATE_BUTTON_STYLE_THEME = 'FEATURED_PRODUCTS_VIEW_DETAILS_UPDATE_BUTTON_STYLE_THEME';
export const FEATURED_PRODUCTS_VIEW_DETAILS_UPDATE_BUTTON_POSITION = 'FEATURED_PRODUCTS_VIEW_DETAILS_UPDATE_BUTTON_POSITION';
export const FEATURED_PRODUCTS_UPDATE_SHOP_SETUP_STATUS = 'FEATURED_PRODUCTS_UPDATE_SHOP_SETUP_STATUS';
export const FEATURED_PRODUCTS_UPDATE_CMP_HEIGHT_BY_IMAGE_RATIO = 'FEATURED_PRODUCTS_UPDATE_CMP_HEIGHT_BY_IMAGE_RATIO';

export const FEATURED_PRODUCTS_ENABLE_BUY_NOW_CHANGED = 'FEATURED_PRODUCTS_ENABLE_BUY_NOW_CHANGED';
export const FEATURED_PRODUCTS_BUY_NOW_UPDATE_BUTTON_STYLE_THEME = 'FEATURED_PRODUCTS_BUY_NOW_UPDATE_BUTTON_STYLE_THEME';
export const FEATURED_PRODUCTS_BUY_NOW_UPDATE_BUTTON_STYLE = 'FEATURED_PRODUCTS_BUY_NOW_UPDATE_BUTTON_STYLE';
export const FEATURED_PRODUCTS_BUY_NOW_UPDATE_BUTTON_TEXT = 'FEATURED_PRODUCTS_BUY_NOW_UPDATE_BUTTON_TEXT';

export const WEBSHOP_PROMOTION_RIBBON_LABEL_BG_COLOR_CHANGED = 'WEBSHOP_PROMOTION_RIBBON_LABEL_BG_COLOR_CHANGED';
export const WEBSHOP_PROMOTION_RIBBON_LABEL_BG_COLOR_CHANGED_AUTO_COLOR = 'WEBSHOP_PROMOTION_RIBBON_LABEL_BG_COLOR_CHANGED_AUTO_COLOR';
export const WEBSHOP_PROMOTION_RIBBON_LABEL_TEXT_COLOR_CHANGED = 'WEBSHOP_PROMOTION_RIBBON_LABEL_TEXT_COLOR_CHANGED';
export const WEBSHOP_PROMOTION_RIBBON_LABEL_TEXT_COLOR_CHANGED_AUTO_COLOR = 'WEBSHOP_PROMOTION_RIBBON_LABEL_TEXT_COLOR_CHANGED_AUTO_COLOR';
