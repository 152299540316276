import React from 'react';
import styles from './BboxDebugger.css';
import type { BBox } from "../../App/flowTypes";

export const BboxDebugger = ({ top, left, right, bottom }: BBox) => (
    <div className={styles.container} style={{ top, left, width: right - left, height: bottom - top }}>
        <div className={styles.topBottom}>
            <span>{top}</span>
            <span>{bottom}</span>
        </div>
        <div className={styles.leftRight}>
            <span>{left}</span>
            <span>{right}</span>
        </div>
    </div>
);
