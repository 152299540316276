import { Color } from "../../../../mappers/flowTypes";
import { CaptionStyles } from "../../../presentational/CaptionStyle/constants";
import { BLACK_THEME_COLOR, WHITE_THEME_COLOR } from '../../../ThemeGlobalData/constants';

export const
    CROP_RATIO = 6 / 5,
    IMAGE_RATIO_NUM_MAP = {
        SQUARE: 1,
        LANDSCAPE: 6 / 5,
        PORTRAIT: 5 / 6,
        ACTUAL_RATIO: 1
    },
    IMAGE_PIXEL_RATIOS = [1, 2, 3],
    IMAGE_MAX_DIMENSION = 16384, // todo check image implementation, move to common place
    IMAGE_SCALE_MAX_PIXELS = 16777216,
    MINSIDE = 160,
    URL_REQUEST_WIDTH_STEP = 100,
    errorColor = '#D64949',
    LIMIT = {
        showTitleCount: (text: string) => {
            return text.length >= (LIMIT.title.hard - 10);
        },
        showCaptionCount: (text: string) => {
            return text.length >= (LIMIT.caption.hard - 10);
        },
        getTitleBorder: (text: string) => {
            return text.length >= (LIMIT.title.hard - 10) ?  // eslint-disable-line no-nested-ternary
                errorColor : "";
        },
        getCaptionBorder: (text: string) => {
            return text.length >= (LIMIT.caption.hard - 10) ?  // eslint-disable-line no-nested-ternary
                errorColor : "";
        },
        title: {
            hard: 70,
            soft: 60
        },
        caption: {
            threshold: 1000,
            hard: 500,
            soft: 180,
            max: 1500
        }
    },
    label = 'msg: component.gallery.label {Gallery}',
    message = 'msg: fc.multiSelectValidationMessage {The {label} component only supports {limit} images. Deselect other images before selecting new images.}', // eslint-disable-line
    MinGalleryDimensions = {
        width: 40,
        height: 40
    },
    MinThumbnailSize = {
        width: 10,
        height: 10
    },
    Padding = {
        MIN: 5,
        MAX: 80,
        DEFAULT_TOP: 9,
        DEFAULT_BOTTOM: 14,
        DEFAULT_LEFT: 20,
        DEFAULT_RIGHT: 20
    },
    titleDefaultStyle = {
        color: ["HSL", 0, 0, 0.06666666666666667, 1] as Color,
        themeColor: BLACK_THEME_COLOR,
        font: 'Helvetica',
        size: 13,
        bold: true,
        underline: false,
        italic: false,
        lineHeight: 1.3
    },
    descriptionDefaultStyle = {
        ...titleDefaultStyle,
        bold: false,
        lineHeight: 1.2,
        size: 13
    },
    captionDefaults = {
        box: {
            [CaptionStyles.CLASSIC]: {
                padding: {
                    top: 20,
                    bottom: 20,
                    left: 15,
                    right: 15
                },
                background: {
                    colorData: {
                        color: ["HSL", 0, 0.09090909090909068, 0.9784313725490196, 1] as Color,
                        themeColor: WHITE_THEME_COLOR,
                    }
                }
            },
            [CaptionStyles.TEMPLATE]: {
                padding: {
                    top: 9,
                    bottom: 14,
                    left: 5,
                    right: 5
                }
            },
            [CaptionStyles.CUSTOM]: {}
        }
    };
