import * as editorCommands from "../editorUtils/editorCommands";
import { makeExecCommandUpdater } from "./makeExecCommandUpdater";
import { TINY_MCE_INDENT } from "../actionTypes";

const onIndentUpdater = makeExecCommandUpdater({
    triggerActionType: TINY_MCE_INDENT,
    cmd: editorCommands.INDENT
});

export {
    onIndentUpdater
};
