export const rafThrottle = (callback: Function) => {
    let requestId: number | null = null;

    const later = (context, args) => () => {
        requestId = null;
        callback.apply(context, args);
    };

    const throttled = function (this: any, ...args: Array<any>) {
        if (requestId === null) {
            requestId = requestAnimationFrame(later(this, args));
        }
    };

    throttled.cancel = () => {
        if (requestId) {
            cancelAnimationFrame(requestId);
        }
        requestId = null;
    };

    return throttled;
};
