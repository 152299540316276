import * as R from 'ramda';
import { TEXT_CHANGED } from "../../../../oneweb/Text/actionTypes";
import { optional } from "../../../../../epics/makeCondition";
import {
    ReceiveOnlyComponentsMap,
    ROEditModeComponentIdSelector
} from "../../componentsEval/selectorActionTypes";
import {
    dataWrapAttribute,
    getComponentsChanges,
    getWrappedComponentIds
} from "../../../../../utils/htmlWriter/html/render/wrapper/wrapperNodeUtils";
import { unwrapComponent } from '../helpers';
import {
    PERFORM_ALL_WRAPPED_COMPONENTS_POSITION_ADJUSTMENT, UPDATE_WRAP_REQUEST,
    PERFORM_ALL_WRAPPED_COMPONENTS_POSITION_ADJUSTMENT_ON_PAGE_LOAD
} from "../actionTypes";

import type { WrapUpdater } from "../flowTypes";
import { TextComponentKind } from "../../../../oneweb/Text/kind";
import { StylesheetUpdateThatMayCauseTextHeightChangeHappenedSelector } from "../../stylesheets/selectorActionTypes";
import { createScheduleAction } from "../../resizeDecos/actionCreators";
import { WORKSPACE_READY } from "../../../actionTypes";
import { ReceiveOnlyAttachments } from '../../componentAttachements/selectorActionTypes';

export const
    onTextContentChanged: WrapUpdater = {
        conditions: [
            ReceiveOnlyComponentsMap,
            ROEditModeComponentIdSelector,
            ReceiveOnlyAttachments,
            TEXT_CHANGED,
        ],
        reducer: ({ values: [componentsMap, editModeComponentId, attachments,
            { content: newTextContent }], state, scope }) => {
            if (!editModeComponentId) {
                return { state, scope };
            }

            const componentsChanges = getComponentsChanges(
                { ...componentsMap[editModeComponentId], content: newTextContent },
                componentsMap,
                attachments,
            );

            const
                wrappedIdsAfterChange = getWrappedComponentIds(newTextContent),
                wrappedIdsBeforeChange = R.keys(R.filter(component => (
                    component.wrap && component.relIn && component.relIn.id === editModeComponentId
                ), componentsMap));

            // On delete or cut, unwrap removed wrappedComponents
            R.difference(wrappedIdsBeforeChange, wrappedIdsAfterChange).forEach(componentId => {
                componentsChanges[componentId] = unwrapComponent();
            });

            return {
                state,
                scope,
                actionToDispatch: {
                    type: UPDATE_WRAP_REQUEST,
                    payload: {
                        wrappedComponentsUpdateInfo: componentsChanges
                    }
                }
            };
        }
    },
    onTextGlobalStyleChangeThatCanAffectHeightOfText: WrapUpdater = {
        keepFullActions: true,
        conditions: [StylesheetUpdateThatMayCauseTextHeightChangeHappenedSelector],
        reducer: ({
            values: [{ value: stylesheetUpdateThatMayCauseTextHeightChangeHappened }],
            state,
            scope
        }) => {
            if (!stylesheetUpdateThatMayCauseTextHeightChangeHappened) {
                return { state, scope };
            }

            return {
                state,
                scope,
                // We need till render happen and only after that we can find out changes in text components
                actionToDispatch: createScheduleAction({ type: PERFORM_ALL_WRAPPED_COMPONENTS_POSITION_ADJUSTMENT })
            };
        }
    },
    // To fix positions according to new line heights
    // TODO: this update will become dead code once all domains have been migrated
    onPageLoad: WrapUpdater = {
        conditions: [WORKSPACE_READY],
        reducer: ({ state, scope }) => {
            return {
                state,
                scope,
                actionToDispatch: createScheduleAction({
                    type: PERFORM_ALL_WRAPPED_COMPONENTS_POSITION_ADJUSTMENT_ON_PAGE_LOAD
                })
            };
        }
    },
    onPerformAllWrappedComponentsPositionAdjustmentUpdater: WrapUpdater = {
        conditions: [
            ReceiveOnlyComponentsMap,
            ReceiveOnlyAttachments,
            optional(PERFORM_ALL_WRAPPED_COMPONENTS_POSITION_ADJUSTMENT),
            optional(PERFORM_ALL_WRAPPED_COMPONENTS_POSITION_ADJUSTMENT_ON_PAGE_LOAD)
        ],
        reducer: ({ values: [componentsMap, attachments], state, scope }) => {
            const wrappedComponentsUpdateInfo = R.keys(componentsMap).reduce(
                (changesForAllWrappedComponents, componentId) => {
                    const component = componentsMap[componentId];
                    if (
                        component.kind === TextComponentKind
                        && component.content.indexOf(dataWrapAttribute) !== -1
                    ) {
                        return {
                            ...changesForAllWrappedComponents,
                            ...getComponentsChanges(component, componentsMap, attachments)
                        };
                    }
                    return changesForAllWrappedComponents;
                },
                {}
            );

            if (R.keys(wrappedComponentsUpdateInfo).length === 0) {
                return { state, scope };
            }

            return {
                state,
                scope,
                actionToDispatch: {
                    type: UPDATE_WRAP_REQUEST,
                    payload: {
                        wrappedComponentsUpdateInfo,
                    }
                }
            };
        }
    };

export const
    onWrapperContentChangedUpdaters = [
        onPageLoad,
        onTextContentChanged,
        onTextGlobalStyleChangeThatCanAffectHeightOfText,
        onPerformAllWrappedComponentsPositionAdjustmentUpdater
    ];
