import React from 'react';

import { GoogleAdsSettingsTextWithSwitchView } from './GoogleAdsSettingsTextWithSwitchView';
import { BgColorSet, BodyTextSet, GoToDashboardActionButton, HeaderTextSet, IconClassesSet } from './constants';

type GoogleAdsSettingsAdDisabledProps = {
    isCampaignEnabled: boolean;
    handleCampaignStatusChange: (enabled: boolean) => void;
};

export const GoogleAdsSettingsAdDisabled = (props: GoogleAdsSettingsAdDisabledProps) => (
    <GoogleAdsSettingsTextWithSwitchView
        bgColor={BgColorSet.NonActive}
        header={{ iconClass: IconClassesSet.adDisabled, text: HeaderTextSet.adDisabled }}
        text={BodyTextSet.adDisabled}
        isCampaignEnabled={props.isCampaignEnabled}
        handleCampaignStatusChange={props.handleCampaignStatusChange}
        actionButton={GoToDashboardActionButton}
    />
);
