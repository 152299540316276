import * as R from 'ramda';
import p from '../../../utils/pipePath';
import { setToPath } from '../../../utils/ramdaEx';
import { DEFAULT_SHADOW } from "../../oneweb/Text/constants";
import * as path from '../../../mappers/path';
import type { LinkStylesheetEpicUpdater } from '../flowTypes';

type EpicUpdaterFactory = (actionType: string, basePath: string) => LinkStylesheetEpicUpdater;

const
    toggleUnderline = (basePath, stylesheet) => setToPath(
        p(basePath, path.underline),
        !R.path([basePath, path.underline])(stylesheet),
        stylesheet
    ),
    updateGlobalStyleShadow = (stylesheet, basePath, shadowProp) => ({
        ...stylesheet,
        [basePath]: {
            ...stylesheet[basePath],
            [path.shadow]: { ...DEFAULT_SHADOW, ...stylesheet[basePath][path.shadow], ...shadowProp }
        }
    });

export const
    getTextShadowColorChangeEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [color], state: stylesheet }) => ({
            state: updateGlobalStyleShadow(stylesheet, basePath, color)
        })
    }),
    getTextShadowRemvoveEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: setToPath(p(basePath, path.shadow), null, stylesheet)
        })
    }),
    getTextShadowBlurRadiusChangeEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [blur], state: stylesheet }) => ({
            state: updateGlobalStyleShadow(stylesheet, basePath, { blur })
        })
    }),
    getTextShadowHorizontalOffsetChangeEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [left], state: stylesheet }) => ({
            state: updateGlobalStyleShadow(stylesheet, basePath, { left })
        })
    }),
    getTextShadowVerticalOffsetChangeEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [top], state: stylesheet }) => ({
            state: updateGlobalStyleShadow(stylesheet, basePath, { top })
        })
    }),
    getToggleUnderlineEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: toggleUnderline(basePath, stylesheet)
        })
    }),
    getTextColorChangeEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [{ color }], state: stylesheet }) => ({
            state: setToPath(p(basePath, path.color), color, stylesheet)
        })
    }),
    getTextColorRemoveEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: setToPath(p(basePath, path.color), null, stylesheet)
        })
    }),
    getTextHighlightColorChangeEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ values: [{ color }], state: stylesheet }) => ({
            state: setToPath(p(basePath, path.highlight), color, stylesheet)
        })
    }),
    getTextHighlightColorRemoveEpicUpdater: EpicUpdaterFactory = (action, basePath) => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: setToPath(p(basePath, path.highlight), null, stylesheet)
        })
    });
