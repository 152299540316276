import * as R from 'ramda';
import { receiveOnly } from '../../../../epics/makeCondition';
import workspaceBBoxValueActionType from '../workspaceBBox/valueActionType';
import * as workspaceActionTypes from '../../actionTypes';
import * as ctxMenuActionTypes from '../../../ContextMenu/actionTypes';
import { CHANGE_SCOPE, FIXED_OVERLAP_WITH_SECTION } from './updateReasons';
import type { ComponentEvalAdjustAfterUpdate, ComponentsEvalEpicUpdater } from './flowTypes';
import { DndAddComponentVisible } from "../../../DndAddComponent/epic/selectorActionTypes";
import {
    TEMPLATE_SETTINGS,
    CHANGE_TEMPLATE_BACKGROUND
} from "../../../../redux/modules/children/workspace/actionTypes";
import { emptyArray } from "../../../../utils/handle/makeHandle";
import { getComponentsBBox, getComponentsIdsInBBox } from "../../../../utils/componentsMap/index";
import { componentIsGhost, getComponentsMapNoGhosts } from "../../../oneweb/Code/getComponentsMapNoGhosts";
import {
    GHOST_CODE_COMPONENT_SORT_START
} from "../../CodeComponentsRenderer/CodeComponentsRendererActionTypes";
import { resetEditModeComponentId, setEditModeComponentId } from "./editModeComponentId";
import {
    componentsMapSelector,
    selectedComponentIdSelector,
    selectedComponentsIdsSelector,
    SelectedComponentsIdsSelector,
    ReceiveOnlySelectedComponentSelector,
} from "./selectorActionTypes";
import { editModeComponentIdSelector, userInteractionComponentsIdsPath } from "./selectors";
import { MOBILE_EDITOR_COMPONENT_SELECT } from "../../../MobileViewEditor/actionTypes";
import { getTopMostParentId, getAllAttachmentsForCmpIds } from "../componentAttachements/util";
import { componentAttachmentsVAT } from '../componentAttachements/valueActionType';
import { componentsMapPath, selectedComponentsIdsPath } from "./paths";
import { getSelectedComponentsIds, getComponentsMap } from "./getters";
import { getFooterSection, isComponentBelowFooter, isSectionComponent } from "../../../oneweb/Section/utils";
import { fixSelectionOverlapWithSectionByMovingSelection } from './userInteractionMutations/utils';
import { setComponentsMap } from './setters';
import { isStickyToHeader } from './isStickyToHeader';
import { addInfoMessage } from "../../../Toaster/actionCreators";
import { SELECT_UPDATE_HOVER_BOX_STATE } from "../../../oneweb/HoverBox/actionTypes";
import { sanitizeSelectedComponentsForHoverBox, getNearestParentHoverBoxCmp } from "../../../oneweb/HoverBox/utils";
import { ReceiveOnlyAttachments } from "../componentAttachements/selectorActionTypes";
import { REDO, UNDO } from "../../../../epics/undoManager/updateReasons";
import WORKSPACE_COMPONENTS_EVAL_EPIC_VALUE from './valueActionType';
import { isModernLayoutSection } from "../../../ModernLayouts/preview_utils";

const
    defaultState = emptyArray,
    isInvalidSelectedComponentId = R.curry((epicState, id) => {
        const componentsMap = componentsMapSelector(epicState);
        const component = componentsMap[id];

        if (!component) {
            return true;
        }

        const currentSelectedComponentsIds = getSelectedComponentsIds(epicState);

        if (isStickyToHeader(component) && currentSelectedComponentsIds.filter(_id => _id !== id).length > 0) {
            return true;
        }

        return false;
    }),
    makeRemoveInvalidComponentsIdsFromPath = path => invalidComponentsIds =>
        R.evolve(R.assocPath(path, R.filter(id => invalidComponentsIds.indexOf(id) === -1), {})),
    removeInvalidSelectedComponentsIds = makeRemoveInvalidComponentsIdsFromPath(selectedComponentsIdsPath),
    removeInvalidUsrInteractionComponentsIds = makeRemoveInvalidComponentsIdsFromPath(userInteractionComponentsIdsPath),
    _fixSelectedComponentsIdsAndEditModeComponentId =
        (nextEpicState, prevEpicState) => {
            const
                checkComponentIdsIsInvalid = isInvalidSelectedComponentId(nextEpicState),
                selectedComponentsIds = getSelectedComponentsIds(nextEpicState),
                invalidComponentsIds = selectedComponentsIds.filter(checkComponentIdsIsInvalid),
                validComponentIds = R.difference(selectedComponentsIds, invalidComponentsIds),
                validSelectedComponentId = validComponentIds.length === 1 ? validComponentIds[0] : null,
                userInteractionComponentsIds = R.path(userInteractionComponentsIdsPath, nextEpicState) || [],
                invalidUserInteractionComponentsIds = userInteractionComponentsIds.filter(checkComponentIdsIsInvalid),
                transformations: any = [];

            if (invalidComponentsIds.length) {
                transformations.push(removeInvalidSelectedComponentsIds(invalidComponentsIds));
            }
            if (invalidUserInteractionComponentsIds.length) {
                transformations.push(removeInvalidUsrInteractionComponentsIds(invalidUserInteractionComponentsIds));
            }
            if (checkComponentIdsIsInvalid(nextEpicState.scope.editModeComponentId)) {
                transformations.push(resetEditModeComponentId);
            }
            if (!validSelectedComponentId && validComponentIds.length) {
                const invalidSectionComponent = validComponentIds.filter(id => isSectionComponent(nextEpicState.state.componentsMap[id]));
                transformations.push(removeInvalidSelectedComponentsIds(invalidSectionComponent));
            }

            const
                validSelectedComponent = componentsMapSelector(nextEpicState)[validSelectedComponentId],
                editModeComponentId = editModeComponentIdSelector(nextEpicState);

            if (selectedComponentIdSelector(prevEpicState) !== selectedComponentIdSelector(nextEpicState)) {
                transformations.push(resetEditModeComponentId);
            }

            if (
                validSelectedComponent
                && componentIsGhost(validSelectedComponent)
                && editModeComponentId !== validSelectedComponentId
            ) {
                transformations.push(setEditModeComponentId(validSelectedComponentId));
            }

            if (transformations.length) {
                return R.pipe(...transformations)(nextEpicState);
            }
            return nextEpicState;
        },

    setSelectedComponentsIds = R.assocPath(['scope', 'selectedComponentsIds']),
    deselectAllComponents = setSelectedComponentsIds(emptyArray),

    fixSelectedComponentsIdsAndEditModeComponentId: ComponentEvalAdjustAfterUpdate =
        ({ prevState, nextState: nextEpicState }) => {
            return {
                state: _fixSelectedComponentsIdsAndEditModeComponentId(nextEpicState, prevState)
            };
        },
    sanitizeForModernLayout = (isModernLayout: boolean, nextSelection: Array<string>) => {
        if (isModernLayout && nextSelection.length > 1) {
            return [R.head(nextSelection)];
        }
        return nextSelection;
    },
    selectComponentInsideBBoxUpdater: ComponentsEvalEpicUpdater = {
        conditions: [
            ReceiveOnlyAttachments,
            receiveOnly(workspaceBBoxValueActionType),
            workspaceActionTypes.SELECT_COMPONENTS_INSIDE_BBOX
        ],
        reducer: ({
            values: [
                attachments,
                workspaceBBox,
                { bBox, ctrlLikePressedOnMouseDown, shiftPressedOnMouseDown }
            ],
            state: epicState
        }) => {
            let nextSelection: any = [],
                mainComponentId;

            const
                componentsMap = getComponentsMap(epicState),
                componentsMapNoGhosts =
                    getComponentsMapNoGhosts(componentsMap),
                componentsIdsInsideSelection = getComponentsIdsInBBox(
                    componentsMapNoGhosts,
                    workspaceBBox,
                    bBox
                );

            if (ctrlLikePressedOnMouseDown || shiftPressedOnMouseDown) {
                const selection = [...epicState.scope.selectedComponentsIds, ...componentsIdsInsideSelection];

                if (ctrlLikePressedOnMouseDown) {
                    nextSelection = selection;
                } else if (shiftPressedOnMouseDown) {
                    const selectionBBox = getComponentsBBox(
                        selection.map(id => componentsMapNoGhosts[id]),
                        workspaceBBox
                    );
                    nextSelection = getComponentsIdsInBBox(
                        componentsMapNoGhosts,
                        workspaceBBox,
                        selectionBBox
                    );
                }
            } else {
                nextSelection = getComponentsIdsInBBox(
                    componentsMapNoGhosts,
                    workspaceBBox,
                    bBox,
                );
            }

            if (componentsIdsInsideSelection.length) {
                mainComponentId = R.last(componentsIdsInsideSelection);
            } else if (epicState.scope.selectedComponentsIds.length) {
                mainComponentId = R.last(epicState.scope.selectedComponentsIds);
            } else {
                return ({
                    state: setSelectedComponentsIds([], epicState),
                    updateReason: CHANGE_SCOPE
                });
            }

            const sectionId = getTopMostParentId(mainComponentId, epicState.scope.attachments);
            const attachedCmpIds = getAllAttachmentsForCmpIds(epicState.scope.attachments, [sectionId]);
            nextSelection = nextSelection.filter(id => attachedCmpIds.includes(id));
            nextSelection = sanitizeSelectedComponentsForHoverBox(nextSelection, attachments, componentsMap);
            nextSelection = sanitizeForModernLayout(isModernLayoutSection(componentsMap[sectionId]), nextSelection);
            return ({
                state: setSelectedComponentsIds(nextSelection, epicState),
                updateReason: CHANGE_SCOPE
            });
        }
    },
    selectAllComponentsUpdater: ComponentsEvalEpicUpdater = {
        conditions: [
            ReceiveOnlyAttachments,
            workspaceActionTypes.SELECT_ALL_COMPONENTS
        ],
        reducer: ({ values: [attachments], state: epicState }) => {
            const componentsMap = getComponentsMap(epicState);
            const selectedComponentIds = selectedComponentsIdsSelector(epicState);

            if (!R.isEmpty(selectedComponentIds)) {
                const sectionId = getTopMostParentId(R.last(selectedComponentIds), attachments);
                let componentIds = getAllAttachmentsForCmpIds(attachments, [sectionId]);
                componentIds = sanitizeSelectedComponentsForHoverBox(componentIds, attachments, componentsMap);
                componentIds = sanitizeForModernLayout(isModernLayoutSection(componentsMap[sectionId]), componentIds);
                if (componentIds.length) {
                    return {
                        state: setSelectedComponentsIds(
                            componentIds,
                            epicState
                        ),
                        updateReason: CHANGE_SCOPE
                    };
                }
            } else {
                return {
                    state: epicState,
                    actionToDispatch: addInfoMessage(
                        'common.noComponentSelection',
                        'msg: common.noComponentSelection {Click on a section to select all components using Ctrl+A}'
                    )
                };
            }
            return {
                state: epicState
            };
        }
    },
    selectOverlapingComponentUpdater: ComponentsEvalEpicUpdater = {
        conditions: [
            ctxMenuActionTypes.CONTEXT_MENU_SELECT_OVERLAPPING_COMPONENT
        ],
        reducer: ({ values: [{ componentId }], state: epicState }) => ({
            state: setSelectedComponentsIds(
                [componentId],
                epicState
            ),
            updateReason: CHANGE_SCOPE
        })
    },
    removeSelectedCmpIds = ({ state: epicState }) => ({
        state: setSelectedComponentsIds(defaultState, epicState),
        updateReason: CHANGE_SCOPE
    }),
    removeSelectedComponentIdsOnTemplateSettings: ComponentsEvalEpicUpdater = {
        conditions: [TEMPLATE_SETTINGS],
        reducer: removeSelectedCmpIds
    },
    removeSelectedComponentIdsOnChangeTemplateBackground: ComponentsEvalEpicUpdater = {
        conditions: [CHANGE_TEMPLATE_BACKGROUND],
        reducer: removeSelectedCmpIds
    },
    selectComponentOnShowContextMenu: ComponentsEvalEpicUpdater = {
        conditions: [
            ctxMenuActionTypes.SHOW_CONTEXT_MENU
        ],
        reducer: ({ values: [{ shouldSelectComponentId }], state: epicState }) => {
            if (shouldSelectComponentId) {
                return {
                    state: setSelectedComponentsIds([shouldSelectComponentId], epicState),
                    updateReason: CHANGE_SCOPE
                };
            }

            return { state: epicState };
        }
    },
    clearOnAddNewComponent: ComponentsEvalEpicUpdater = {
        conditions: [DndAddComponentVisible],
        reducer: ({ values: [dndAddComponentVisible], state: epicState }) => {
            if (dndAddComponentVisible) {
                return {
                    state: setSelectedComponentsIds(defaultState, epicState),
                    updateReason: CHANGE_SCOPE
                };
            }
            return { state: epicState };
        }
    },
    clearOnGhostComponentDragStart: ComponentsEvalEpicUpdater = {
        conditions: [GHOST_CODE_COMPONENT_SORT_START],
        reducer: ({ state: epicState }) => {
            return {
                state: setSelectedComponentsIds(defaultState, epicState),
                updateReason: CHANGE_SCOPE
            };
        }
    },
    selectedOnMobileEditorComponentPressed: ComponentsEvalEpicUpdater = {
        conditions: [MOBILE_EDITOR_COMPONENT_SELECT],
        reducer: ({ state: epicState, values: [{ cmpId: componentId }] }) => {
            return {
                state: setSelectedComponentsIds([componentId], epicState),
                updateReason: CHANGE_SCOPE
            };
        }
    },
    onSelectedComponentsIdsChangedUpdater: ComponentsEvalEpicUpdater = {
        conditions: [
            receiveOnly(componentAttachmentsVAT),
            SelectedComponentsIdsSelector
        ],
        reducer: ({ state: epicState, values: [{ attachments }, selectedComponentsIds], sourceAction }) => {
            if (sourceAction.type === workspaceActionTypes.WORKSPACE_LEFT_MOUSE_DOWN) { // do not correct on mouse down, wait for mouse up
                return { state: epicState };
            }

            const componentsMap = componentsMapSelector(epicState);
            const sectionsIds = Object.keys(componentsMap).filter(cid => componentsMap[cid].kind === "SECTION");
            const attachmentsWithoutSections = R.omit(sectionsIds, attachments);
            const selectedComponentsContainersNotSectionsIds = selectedComponentsIds
                .map(cid => getTopMostParentId(cid, attachmentsWithoutSections));

            const componentsIds = R.uniq([
                ...getAllAttachmentsForCmpIds(attachments, selectedComponentsContainersNotSectionsIds),
                ...selectedComponentsIds,
                ...selectedComponentsContainersNotSectionsIds
            ]);
            const
                firstSelectedComponent = componentsMap[selectedComponentsIds[0]],
                footer = getFooterSection(componentsMap);
            if (firstSelectedComponent &&
                (isStickyToHeader(firstSelectedComponent) || isComponentBelowFooter(firstSelectedComponent, footer))) {
                return {
                    state: epicState
                };
            }

            const updatedComponentsMap = fixSelectionOverlapWithSectionByMovingSelection(
                componentsIds,
                componentsMap
            );

            if (componentsMap === updatedComponentsMap) {
                return {
                    state: epicState
                };
            }

            return {
                state: setComponentsMap(updatedComponentsMap, epicState),
                updateReason: FIXED_OVERLAP_WITH_SECTION
            };
        }
    },
    selectAndUpdateHoverBoxHoverState: ComponentsEvalEpicUpdater = {
        conditions: [SELECT_UPDATE_HOVER_BOX_STATE],
        reducer: ({ values: [{ hoverBoxId, hoverMode }], state: epicState }) => {
            let newState = setSelectedComponentsIds([hoverBoxId], epicState);
            newState = R.assocPath([...componentsMapPath, hoverBoxId, 'hoverMode'], hoverMode, newState);
            return {
                state: newState,
                updateReason: SELECT_UPDATE_HOVER_BOX_STATE
            };
        }
    },
    hoverBoxUndoRedoSelectionReducer = ({ values: [selectedComponent, attachments, { affectedEpics }], state }) => {
        if (selectedComponent &&
        selectedComponent.onHover &&
        affectedEpics[WORKSPACE_COMPONENTS_EVAL_EPIC_VALUE] &&
        affectedEpics[WORKSPACE_COMPONENTS_EVAL_EPIC_VALUE]
            .newStateParts) {
            let componentsMap = affectedEpics[WORKSPACE_COMPONENTS_EVAL_EPIC_VALUE]
                    .newStateParts.reduce((map, item) => ({ ...map, ...item }), {}),
                hoverBoxId = getNearestParentHoverBoxCmp(selectedComponent.id, attachments, componentsMap),
                hoverBox = hoverBoxId ? componentsMap[hoverBoxId] : null;
            if (hoverBox && selectedComponent.onHover.show !== !!hoverBox.hoverMode) {
                let newState = setSelectedComponentsIds([hoverBox.id], state);
                return {
                    state: newState,
                    updateReason: CHANGE_SCOPE
                };
            }
        }
        return { state };
    },
    selectHoverBoxOnUndo: ComponentsEvalEpicUpdater = {
        conditions: [ReceiveOnlySelectedComponentSelector,
            ReceiveOnlyAttachments, UNDO],
        reducer: hoverBoxUndoRedoSelectionReducer
    },
    selectHoverBoxOnRedo: ComponentsEvalEpicUpdater = {
        conditions: [ReceiveOnlySelectedComponentSelector,
            ReceiveOnlyAttachments, REDO],
        reducer: hoverBoxUndoRedoSelectionReducer
    };

export {
    fixSelectedComponentsIdsAndEditModeComponentId,
    setSelectedComponentsIds,
    deselectAllComponents,
    selectComponentInsideBBoxUpdater,
    selectAllComponentsUpdater,
    selectOverlapingComponentUpdater,
    selectComponentOnShowContextMenu,
    clearOnAddNewComponent,
    removeSelectedComponentIdsOnTemplateSettings,
    removeSelectedComponentIdsOnChangeTemplateBackground,
    defaultState,
    clearOnGhostComponentDragStart,
    selectedOnMobileEditorComponentPressed,
    onSelectedComponentsIdsChangedUpdater,
    selectAndUpdateHoverBoxHoverState,
    selectHoverBoxOnUndo,
    selectHoverBoxOnRedo
};
