import {
    TOP_BAR_PAGE_TREE_ON_EXPAND,
    TOP_BAR_PAGE_TREE_SCROLL_TOP,
    UNDO_BUTTON_PRESSED,
    REDO_BUTTON_PRESSED,
    TOP_BAR_CLOSE_POPUP,
    TOP_BAR_SELECT_LANGUAGE,
    TOP_BAR_LANGUAGE_HAS_SELECTED,
    TOP_BAR_SHOW_LANGUAGES,
    TOP_BAR_HIDE_LANGUAGES,
    PUBLISH_STATUS_UPDATER,
    PUBLISH_CANCELLED_AFTER_ERROR_DIALOG
} from "../actionTypes";
import { Popups } from '../constants';
import openTopBarPopup from './openTopBarPopup';

export { default as logout } from './logout';
export { default as openTopBarPopup } from './openTopBarPopup';

export type SelectLanguageParams = {
    locale: string;
    fromTemplateSelector?: boolean;
}

export type publishUpdaterParams = {
    statusResponse: Record<string, any>;
    saveStatus: string;
    undoState: Record<string, any>;
}

export const
    expandTopBarPageIdsAction = (pageIds: Array<string>) => ({
        type: TOP_BAR_PAGE_TREE_ON_EXPAND,
        payload: pageIds
    }),
    topBarPageTreeScrollTopAction = (scrollTop: number) => ({
        type: TOP_BAR_PAGE_TREE_SCROLL_TOP,
        payload: scrollTop
    }),
    undoButtonPressed = () => ({ type: UNDO_BUTTON_PRESSED }),
    redoButtonPressed = () => ({ type: REDO_BUTTON_PRESSED }),
    topBarClosePopupAction = () => ({ type: TOP_BAR_CLOSE_POPUP }),
    topBarSelectLanguageAction = (payload: SelectLanguageParams) => ({ type: TOP_BAR_SELECT_LANGUAGE, payload }),
    topBarLanguageHasSelectedAction = () => ({ type: TOP_BAR_LANGUAGE_HAS_SELECTED }),
    topBarShowLanguagesAction = (topPosition: number) => ({ type: TOP_BAR_SHOW_LANGUAGES, payload: topPosition }),
    topBarHideLanguagesAction = () => ({ type: TOP_BAR_HIDE_LANGUAGES }),
    updatePublishStatus = (payload: publishUpdaterParams) => ({ type: PUBLISH_STATUS_UPDATER, payload }),
    openTopBarUserProfileAction = () => openTopBarPopup({ id: Popups.USER_PROFILE }),
    publishCancelledAfterErrorDialog = () => ({ type: PUBLISH_CANCELLED_AFTER_ERROR_DIALOG });

export const closeTopBarPagesTreeAction = () => ({
    type: TOP_BAR_CLOSE_POPUP,
    payload: { id: Popups.PAGES },
});

