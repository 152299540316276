import * as pagesIds from './pagesIds';

export default {
    [pagesIds.MAIN]: "msg: component.facebookFeed.propPanel.main.title {Facebook gallery}",
    [pagesIds.LAYOUT]: "msg: component.facebookFeed.propPanel.layout.title {Layout}",
    [pagesIds.ONCLICK]: "msg: component.facebookFeed.propPanel.onClick.title {On click}",
    [pagesIds.CAPTIONS]: "msg: component.facebookFeed.propPanel.captions.title {Captions}",
    [pagesIds.CAPTIONS_BOX_STYLE]: "msg: component.facebookFeed.propPanel.captions.tileStyle {Tile style}",
    [pagesIds.CAPTIONS_DESCRIPTION_STYLE]: "msg: component.facebookFeed.propPanel.captionsDescriptionStyle.title {Description style}", // eslint-disable-line
    [pagesIds.ACCOUNT]: "msg: component.facebookFeed.propPanel.account.title {Account}",
    [pagesIds.PROFILE_IMAGE]: "msg: component.facebookFeed.propPanel.profileImage {Profile Image}",
};
