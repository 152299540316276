import React from 'react';
import DirectoryCell from './DirectoryCell';
import ResourceCell from './ResourceCell';
import Resource from "../../../../../redux/modules/children/fileChooser/Resource";
import type { GridCellPropTypes } from "../../flowTypes";
import styles from '../FileChooserGrid.css';

const EmptyCell = () => (<div className={styles.emptyCell} />);

export default (props: GridCellPropTypes) => {
    const resource: Resource = props.resource;
    const { isVisible = true } = props;

    if (isVisible) {
        return resource.isDirectory()
            ? <DirectoryCell {...props} />
            // @ts-ignore
            : <ResourceCell {...props} />;
    }

    return <EmptyCell />;
};
