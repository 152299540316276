// TODO: give name / move to appropriate place; extracted from wbtgen/src/constants.js which holds ramda :((

export const
    LinkTargetV = {
        BLANK: '_blank',
        SELF: '_self',
        PARENT: '_parent',
        TOP: '_top',
    },
    LinkAnchorHash = '#';
