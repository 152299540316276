import makeEpic from '../../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import {
    FEATURED_PRODUCTS_UPDATE_CMP_HEIGHT_BY_IMAGE_RATIO,
    FEATURED_PRODUCTS_UPDATE_NEW_HEIGHT,
    FEATURED_PRODUCTS_IMAGE_RATIO_CHANGED,
    FEATURED_PRODUCTS_CROP_IMAGES_CHANGED,
    FEATURED_PRODUCTS_PRODUCT_APPEARANCE_CHANGED,
    FEATURED_PRODUCTS_VIEW_DETAILS_UPDATE_BUTTON_POSITION,
    FEATURED_PRODUCTS_UPDATE_PRODUCTS_PROPERTY,
    FEATURED_PRODUCTS_ENABLE_BUY_NOW_CHANGED
} from '../../actionTypes';

const defaultState: { imageRatioChangeStarted: boolean } = {
    imageRatioChangeStarted: false,
};

const getUpdateHeightAction = (componentId, newHeight) => {
    return {
        type: FEATURED_PRODUCTS_UPDATE_NEW_HEIGHT,
        payload: {
            componentId,
            newHeight
        }
    };
};

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                FEATURED_PRODUCTS_IMAGE_RATIO_CHANGED,
            ],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        imageRatioChangeStarted: true
                    }
                };
            }
        },
        {
            conditions: [
                FEATURED_PRODUCTS_CROP_IMAGES_CHANGED,
            ],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        imageRatioChangeStarted: true
                    }
                };
            }
        },
        {
            conditions: [
                FEATURED_PRODUCTS_PRODUCT_APPEARANCE_CHANGED,
            ],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        imageRatioChangeStarted: true
                    }
                };
            }
        },
        {
            conditions: [
                FEATURED_PRODUCTS_VIEW_DETAILS_UPDATE_BUTTON_POSITION,
            ],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        imageRatioChangeStarted: true
                    }
                };
            }
        },
        {
            conditions: [
                FEATURED_PRODUCTS_UPDATE_PRODUCTS_PROPERTY,
            ],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        imageRatioChangeStarted: true
                    }
                };
            }
        },
        {
            conditions: [
                FEATURED_PRODUCTS_UPDATE_CMP_HEIGHT_BY_IMAGE_RATIO
            ],
            reducer: ({ state, values: [{ componentId, height }] }) => {
                if (state.imageRatioChangeStarted) {
                    return {
                        state: { ...state, imageRatioChangeStarted: false },
                        actionToDispatch: getUpdateHeightAction(componentId, height)
                    };
                }
                return { state };
            }
        },
        {
            conditions: [
                FEATURED_PRODUCTS_ENABLE_BUY_NOW_CHANGED
            ],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        imageRatioChangeStarted: true
                    }
                };
            }
        }
    ]
});
