import valueActionType from './valueActionType';
import AppConfig from "../../../../utils/AppConfig";
import type { AppState } from "../../../../redux/modules/flowTypes";
import { pathSelector } from "../../../../utils/pathSelector";
import { Lit } from "../../../../lit";
import { receiveOnly } from "../../../../epics/makeCondition";

export const appConfigAppStateSel = (appState: AppState) => appState.epics[valueActionType].state;

export const sstockQuotaAppStateSel = (appState: AppState) => pathSelector(
    [Lit.oneWeb, Lit.shutterstock, Lit.freeOnecom, Lit.quota]
)(appConfigAppStateSel(appState));

export const
    AppConfigSelector = (path: Array<string> | string) =>
        receiveOnly(valueActionType, (appConfig) => AppConfig(appConfig).getOptional(path)),
    appConfigPublishWithSSLSelector = AppConfigSelector('oneWeb.publish.withSSL');
