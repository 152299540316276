import React from "react";
import cx from 'classnames';
import { pure } from 'recompose';

import * as actionTypes from '../actionTypes';
import {
    MAX_MOBILE_SIZE_SUPPORTED,
    MOBILE_SIZE_CHANGE_BREAKPOINT1,
    MOBILE_SIZE_CHANGE_BREAKPOINT2,
    MAXED_OUT_MOBILE_SIZE_VALUE,
} from '../constants';
import { injectIntl } from "../../../../view/intl/index";
import type { Intl } from "../../../../view/intl/index";
import { MctaButton, MctaVerticalBar } from '../../../componentMainActions/index';
import { MctaOpacitySlider } from '../../../componentMainActions/MctaSlider/MctaOpacitySlider';
import mctaStyles from '../../../componentMainActions/MctaButton/styles.css';
import openLinkChooserDialog from "../../../../view/common/dialogs/LinkChooserDialog/actionCreators/openLinkChooserDialog";
import HorizontalSpacer from '../../../../view/common/HorizontalSpacer';
import { titleClearFormatting } from "../../constants";
import {
    titleImageBigger, titleImageBiggerErr, titleImageSmaller, titleImageSmallerErr
} from "../../../MobileViewEditor/view/Groups/MCTA/ImageMctaScaler";

import type { SvgComponent, ComponentDependencies } from '../flowTypes';
import type { isCTAUrgentType } from '../../flowTypes';
import type { CTAViewParams } from '../../../../view/Workspace/Decorations/ComponentMainActions/flowTypes';

const
    mobileViewEditorV = ({
        dispatch, selectedComponent: { width, widthInMobile }
    }: {
        dispatch: Dispatch;
        selectedComponent: SvgComponent;
    }) => {
        const currentWidth = widthInMobile || width,
            canIncrease = (currentWidth !== MAXED_OUT_MOBILE_SIZE_VALUE),
            canDecrease = (currentWidth > 1),
            increaseSize = () => {
                if (!canIncrease) {
                    // do nothing
                } else if (currentWidth >= MAX_MOBILE_SIZE_SUPPORTED) {
                    dispatch({ type: actionTypes.SVG_MOBILE_SIZE_CHANGE, payload: MAXED_OUT_MOBILE_SIZE_VALUE });
                } else if (currentWidth >= MOBILE_SIZE_CHANGE_BREAKPOINT2) {
                    dispatch({ type: actionTypes.SVG_MOBILE_SIZE_CHANGE, payload: currentWidth + 10 });
                } else if (currentWidth >= MOBILE_SIZE_CHANGE_BREAKPOINT1) {
                    dispatch({ type: actionTypes.SVG_MOBILE_SIZE_CHANGE, payload: currentWidth + 2 });
                } else {
                    dispatch({ type: actionTypes.SVG_MOBILE_SIZE_CHANGE, payload: currentWidth + 1 });
                }
            },
            decreaseSize = () => {
                if (!canDecrease) {
                    // do nothing
                } else if (currentWidth > MAX_MOBILE_SIZE_SUPPORTED) {
                    dispatch({ type: actionTypes.SVG_MOBILE_SIZE_CHANGE, payload: MAX_MOBILE_SIZE_SUPPORTED });
                } else if (currentWidth > MOBILE_SIZE_CHANGE_BREAKPOINT2) {
                    dispatch({ type: actionTypes.SVG_MOBILE_SIZE_CHANGE, payload: currentWidth - 10 });
                } else if (currentWidth > MOBILE_SIZE_CHANGE_BREAKPOINT1) {
                    dispatch({ type: actionTypes.SVG_MOBILE_SIZE_CHANGE, payload: currentWidth - 2 });
                } else {
                    dispatch({ type: actionTypes.SVG_MOBILE_SIZE_CHANGE, payload: currentWidth - 1 });
                }
            };
        return (
            <div>
                <MctaButton
                    btnClass={canDecrease ? '' : mctaStyles.disabled}
                    title={canDecrease ? titleImageSmaller : titleImageSmallerErr}
                    isErrorTitle={!canDecrease || undefined}
                    icon={canDecrease ? "GROUP_IMAGE_SMALLER" : "GROUP_IMAGE_SMALLER_DISABLED"}
                    onClick={decreaseSize}
                />
                <HorizontalSpacer x={5} />
                <MctaVerticalBar />
                <HorizontalSpacer x={5} />
                <MctaButton
                    btnClass={canIncrease ? '' : mctaStyles.disabled}
                    title={canIncrease ? titleImageBigger : titleImageBiggerErr}
                    isErrorTitle={!canIncrease || undefined}
                    icon={canIncrease ? "GROUP_IMAGE_BIGGER" : "GROUP_IMAGE_BIGGER_DISABLED"}
                    onClick={increaseSize}
                />
                <HorizontalSpacer x={5} />
                <MctaVerticalBar />
                <HorizontalSpacer x={5} />
                <MctaButton
                    title={titleClearFormatting}
                    icon="CLEARFORMATING"
                    onClick={() => dispatch({ type: actionTypes.SVG_MOBILE_CLEAR_FORMATTING })}
                />
            </div>
        );
    },
    isCTAUrgent: isCTAUrgentType<SvgComponent, ComponentDependencies> =
        ({ asset: { url } }, { missingAssetUrls }) => missingAssetUrls.includes(url),
    EditView = pure(({ dispatch, selectedComponent, children }) => { // eslint-disable-line no-unused-vars
        const { linkAction, opacity } = selectedComponent,
            titleLink = linkAction
                ? "msg: common.changeLink {Change link}"
                : "msg: common.addLink {Add link}";
        return (
            <React.Fragment>
                <MctaButton
                    title={titleLink}
                    icon="LINK"
                    className={cx({ [mctaStyles.mctaLinkButtonWithLinkSet]: !!linkAction })}
                    onMouseDown={e => e.stopPropagation()}
                    onClick={() => dispatch(openLinkChooserDialog({ setLinkAction: actionTypes.SVG_SET_LINK, input: linkAction }))}
                />
                <HorizontalSpacer x={5} />
                <MctaVerticalBar />
                <HorizontalSpacer x={5} />
                <MctaOpacitySlider
                    opacity={opacity}
                    onChange={payload => dispatch({ type: actionTypes.SVG_SET_OPACITY, payload, amendToSelf: true })}
                />
                {children && <HorizontalSpacer />}
                {children}
            </React.Fragment>
        );
    });

export default {
    editView: EditView,
    editButtonText: 'msg: component.svg.editSvg {Edit SVG}',
    // @ts-ignore
    CtaButtonTextView: injectIntl((props: { intl: Intl }) =>
        props.intl.msgJoint('msg: component.svg.replaceSvg {Replace SVG}')),
    isCTAUrgent,
    ctaOnClick: ({ dispatch, selectedComponent }: CTAViewParams<SvgComponent>) => {
        dispatch({ type: actionTypes.SVG_REPLACE_SVG, payload: selectedComponent.asset.url });
    },
    mobileViewEditorV,
};
