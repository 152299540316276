import CALL_API from "../../../redux/middleware/api/CALL_API";
import type { ApiAction, ApiActionTypes } from "../../../redux/middleware/api/flowTypes";

export default function (url: string, webSpacePath: string, types: ApiActionTypes): ApiAction {
    return {
        [CALL_API]: {
            types,
            endpoint: 'uploadFromUrl',
            endpointParams: [url, webSpacePath]
        }
    };
}
