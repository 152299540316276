import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import cx from "classnames";

import dayjs from "../../../../../../../wbtgen/src/dayjs";
import { useIntl } from "../../../../../../../wbtgen/src/view/intl";

import { selectMarketgooIsLoading, seoLastScanSelector } from "../../../../common/selectors";
import { SeoScoreProgress } from "./SeoScoreProgress";

import imageStyles from "../../../Images/imageStyle.css";

const formatLastScanTime = (datetime: number) => {
    return dayjs(datetime).calendar();
};

const useStyles = makeStyles(
    (theme) => ({
        scoreHeader: {
            fontSize: "22px",
            lineHeight: "30px",
            letterSpacing: "0.2px",
        },
        scoreDescription: {
            fontSize: "14px",
            lineHeight: "20px",
            marginTop: "12px",
        },
        scoreLastScanTime: {
            fontSize: "12px",
            lineHeight: "20px",
            letterSpacing: "0.2px",
            marginTop: "16px",
            color: theme.palette.custom.colorGray_8a,
        },
        scoreTooltipIcon: {
            width: "14px",
            height: "14px",
            marginRight: "6px",
        },
        scoreTooltip: {
            fontSize: "12px",
            lineHeight: "15px",
            letterSpacing: "0.2px",
        },
    }),
    { name: "WebsiteSeoScore" }
);

const LastScan = () => {
    const intl = useIntl();
    const classes = useStyles();

    const
        isLoading = useSelector(selectMarketgooIsLoading),
        lastScanTimestamp: number = useSelector(seoLastScanSelector);

    if (!isLoading && lastScanTimestamp > 0) {
        return (
            <Typography className={classes.scoreLastScanTime}>
                {intl.msgJoint("msg: seoScan.lastScan {Last scan}")}:{" "}
                {formatLastScanTime(lastScanTimestamp)}
            </Typography>
        );
    }

    return null;
};

export const WebsiteSeoScore = ({ score = 0 }) => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Box flex={1} display="flex" alignItems="flex-start" style={{ userSelect: 'none' }} data-testid="website-seo-score">
            <SeoScoreProgress score={score} />

            <Box flex={1} marginLeft="24px">
                <Typography className={classes.scoreHeader}>
                    {intl.msgJoint("msg: seoScan.websiteScore {Your website score}")}
                </Typography>

                <Typography className={classes.scoreDescription}>
                    {intl.msgJoint("msg: seoScan.improveScore {Improve your score by fixing the issues listed below and publishing.}")}
                </Typography>

                <LastScan />

                <Box display="flex" alignItems="flex-start" marginTop="8px">
                    <Box className={cx(classes.scoreTooltipIcon, imageStyles.info)} />
                    <Typography className={classes.scoreTooltip}>
                        {intl.msgJoint("msg: seoScan.rememberToPublish {Remember to publish to see your new score}")}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};
