import React from "react";
import { connect } from 'react-redux';
import { makeBorderProps } from "../../../view/Workspace/Decorations/makeBorderProps";
import { red270 } from '../../variables.css';
import { Border } from "../../presentational/Border/Border";
import type { TemplateEdgeDecorationsState } from "./templateEdgeDecos/index";
import { makeEpicStateSelector } from "../../../epics/makeEpic";
import { templateEdgeDecosEpicVAT } from "./templateEdgeDecos/valueActionType";

type Props = {
    state: TemplateEdgeDecorationsState
}

const
    templateEdgeEpicStateSelector = makeEpicStateSelector(templateEdgeDecosEpicVAT),
    mapStateToProps = (appState) => ({ state: templateEdgeEpicStateSelector(appState) }),
    Component = ({ state }: Props) => {
        const
            { templateEdgeCoordinate, isVisible } = state,
            borderProps = {
                bBox: templateEdgeCoordinate,
                opacity: 0.7,
                color: red270
            };

        return (
            <div>
                {
                    isVisible &&
                    <Border {...makeBorderProps(borderProps)} />
                }
            </div>
        );
    },
    TemplateEdgeDecorations = connect(mapStateToProps)(Component);

export {
    TemplateEdgeDecorations
};
