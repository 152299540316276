import React from 'react';
import cx from 'classnames';
import styles from '../view/Inserter.css';
import { injectIntl } from "../../../view/intl/index";
import { ALL_FILTER_KEY } from "../epics/sectionsBlocks/index";
import { NewTag } from "../../../view/common/Tag/index";

type Data = Array<{
    id: string;
    title: string;
    isNew?: boolean;
}>;

const Filters = injectIntl(({
    selected,
    intl,
    data,
    setFilter
}: {
    selected: string,
    data: Data,
    intl: Intl,
    setFilter: Function
}) => {
    React.useEffect(() => {
        return () => setFilter(ALL_FILTER_KEY);
    }, []);

    return <div className={styles.filtersContainer}>
        {
            data.map(category => <div
                key={category.id}
                className={cx({
                    "filterItem": true,
                    [styles.filterItem]: true,
                    [styles.selected]: category.id === selected
                })}
                onClick={() => setFilter(category.id)}
            >
                {intl.msgJoint(category.title)}
                {category.isNew && <NewTag containerClassName={styles.newLabelContainer} labelClassName={styles.newLabelText} />}
            </div>)
        }
    </div>;
});

export default Filters;
