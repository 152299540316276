import * as mobileView from '../../../../PropertiesPanel/view/MobileView/page';
import * as language from './pages/languages';
import * as main from './pages/main';
import makePages from "../../../../PropertiesPanel/view/makePages";

const propertiesPanel = {
    pages: makePages(main, language, mobileView),
    defaultPageId: main.id,
};

export { propertiesPanel };
