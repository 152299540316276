import React from "react";
import { SHOW_CUSTOM_STICKY_TOOLTIP } from "../../Tooltip/stickyTooltip/actionTypes";
import * as ToolTipIds from "../../Tooltip/ids";
import { SectionLinkOutdated } from "./view/sectionLinkOutdatedTooltip";
import { SectionLinkPageDeleted } from "./view/sectionLinkInvalidPage";

type Props = {
    top: number;
    left: number;
} & Record<string, any>;

export const
    showPageDeletedToolTipAC = ({ top, left, ...rest }: Props) => {
        return {
            type: SHOW_CUSTOM_STICKY_TOOLTIP,
            payload: {
                id: ToolTipIds.SectionLinkPageDeleted,
                style: { zIndex: 1000000 },
                context: { y: top, x: left },
                customHTML: <SectionLinkPageDeleted />,
                showIcon: false,
                crossIconStyle: { position: 'absolute', top: 18, right: 13, opacity: 0.2 },
                ...rest
            }
        };
    },
    showOutDatedLinkToolTipAC = ({ top, left, ...rest }: Props) => {
        return {
            type: SHOW_CUSTOM_STICKY_TOOLTIP,
            payload: {
                id: ToolTipIds.SectionLinkOutdated,
                style: { zIndex: 1000000 },
                context: { y: top, x: left },
                customHTML: <SectionLinkOutdated />,
                showIcon: false,
                crossIconStyle: { position: 'absolute', top: 18, right: 13, opacity: 0.2 },
                ...rest
            }
        };
    };
