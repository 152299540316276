import React, { ReactNode, CSSProperties, MouseEventHandler, ReactEventHandler } from 'react';
import cx from 'classnames';
import styles from './TopBarItemMenu.css';
import Icons, { customizedIcon } from '../../../../view/Icons/index';
import LongTextTip from '../../../../view/common/LongTextTip/index';
import getStyleIntValue from "../../../../utils/getStyleIntValue";

const PAGE_NAME_WIDTH = getStyleIntValue(styles, 'pageNameWidth');

type Props = {
    iconName?: string;
    iconStyle?: CSSProperties;
    disabled?: boolean;
    selected?: boolean;
    className?: string;
    style?: CSSProperties;
    onClick?: MouseEventHandler<HTMLDivElement>;
    onMouseOver?: ReactEventHandler<HTMLDivElement>;
    onMouseOut?: ReactEventHandler<HTMLDivElement>;
    children: ReactNode;
    LeftSideIcon?: ReactNode;
    RightSideIcon?: ReactNode;
    titleMsg?: string;
    titleClass?: string;
};

export default ({
    iconName,
    iconStyle,
    disabled,
    selected,
    className,
    style,
    onClick,
    onMouseOver,
    onMouseOut,
    children,
    LeftSideIcon,
    RightSideIcon,
    titleMsg,
    titleClass
}: Props) => {
    const Icon = iconName ? customizedIcon(Icons[iconName], { style: iconStyle }) : null;

    return (
        <div
            data-title={titleMsg}
            data-title-class={titleClass}
            className={cx(styles.container, className, {
                [styles.containerDisabled]: disabled,
                [styles.selected]: selected
            })}
            onClick={onClick}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            style={style}
        >
            {
                Icon
                    ? <span className={styles.menuIconContainer}><Icon /></span>
                    : LeftSideIcon
            }
            <span className={cx(styles.menuNameContainer, 'menuNameContainer')}>
                <LongTextTip maxWidth={PAGE_NAME_WIDTH} className={styles.menuName}>
                    {children}
                </LongTextTip>
            </span>
            { RightSideIcon }
        </div>
    );
};
