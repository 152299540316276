import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import { openDialog } from "../../../App/actionCreators/index";
import { TUTORIAL_VIDEO_CLICKED } from "../../actionTypes";
import { TutorialVideoDialogId } from "../../view/dialogIds";
import { dialogManagerVAT } from '../../../DialogManager/epic/valueActionType';
import { receiveOnly } from "../../../../epics/makeCondition";
import { openedDialogIdsSelector } from "../../../DialogManager/epic/selectors";
import deleteMessage from '../../../Toaster/actionCreators/deleteMessage';

export default makeEpic({
    defaultState: null,
    valueActionType,
    updaters: [
        {
            conditions: [receiveOnly(dialogManagerVAT), TUTORIAL_VIDEO_CLICKED],
            reducer: ({ values: [dialogState], state }) => {
                if (dialogState && openedDialogIdsSelector(dialogState).includes(TutorialVideoDialogId)) {
                    return { state };
                }
                const multipleActionsToDispatch: Array<Action> = [
                    //deleting verify email toaster
                    deleteMessage(0),
                    openDialog(TutorialVideoDialogId, { enableBackground: true })
                ];
                return {
                    state,
                    multipleActionsToDispatch
                };
            }
        }
    ]
});
