/* eslint-disable max-len */
import React from "react";
import WarningDialog from "../../../../view/common/dialogs/WarningDialog/index";
import getCenteredDialogConfig from "../../../DialogManager/getCenteredDialogConfig";
import { injectIntl } from "../../../../view/intl/index";
import { socialLinksConfigsMap } from "../socialLinksConfigs";
import { socialServiceEpic } from "../epics/socialServiceEpic/socialServiceEpic";
import { SOCIAL_DELETE_LINK_DIALOG_DELETE_PRESSED, SOCIAL_DELETE_LINK_DIALOG_CANCEL_PRESSED } from "../actionTypes";

const _dialog = injectIntl(({ dispatch, intl, socialService }) => {
    const conf = socialLinksConfigsMap[socialService.deleteDialogPlatformKind];
    return (
        <WarningDialog
            title={intl.msgJoint(conf.deleteUrlDialogTitle)}
            mctaText="msg: common.delete {Delete}"
            mctaHandler={() => dispatch({ type: SOCIAL_DELETE_LINK_DIALOG_DELETE_PRESSED })}
            sctaHandler={() => dispatch({ type: SOCIAL_DELETE_LINK_DIALOG_CANCEL_PRESSED })}
            noTopBorderForFooter
        >
            <div style={{ whiteSpace: 'pre-line' }}>
                {intl.msgJoint("msg: social.removeLinkDialogDescription {The link will be deleted from all \"Social links\" components across your site.}")}
                <br />
                <br />
                {intl.msgJoint("msg: social.removeLinkDialogDescription2 {Are you sure you want to delete this link?}")}
            </div>
        </WarningDialog>
    );
});

const SocialRemoveLinkConfirmationDialog = getCenteredDialogConfig({
    width: 482,
    height: 327,
    component: _dialog,
    dependsOn: {
        socialService: socialServiceEpic.reducer
    }
});

export {
    SocialRemoveLinkConfirmationDialog
};

