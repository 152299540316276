import { put, take, race } from 'redux-saga/effects'; // eslint-disable-line node/file-extension-in-import
import { checkUnsavedChangesAction } from "./actionCreators";
import { CANCEL_UNSAVED_CHANGES, UNSAVED_CHANGES_CHECKED } from "./actionTypes";

const unsavedChangesSagaRace = race({
    checked: take(UNSAVED_CHANGES_CHECKED),
    cancel: take(CANCEL_UNSAVED_CHANGES)
});

const saga = function* (): Generator<any, any, any> {
    yield put(checkUnsavedChangesAction());
    const { cancel } = yield unsavedChangesSagaRace;
    return !cancel;
};

export { saga as default, unsavedChangesSagaRace };
