/* eslint-disable max-len */
import React from "react";

import { Msg } from "../../../../../view/intl";
import * as styles from "./VideoSizeLimitExceededDialog.css";
import { CLOSE_DIALOG } from "../../../../../redux/modules/actionTypes";
import PrimaryButton from "../../../../../view/common/Button/PrimaryButton";
import { Dialog, DialogBody } from "../../../../../view/common/dialogs/baseDialog/index";
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import { VideoSizeLimitExceededDialogId } from "../../dialogIds";

const DialogWidth = parseInt(styles.VideoSizeLimitExceededDialogWidth, 10),
    DialogHeight = parseInt(styles.VideoSizeLimitExceededDialogHeight, 10);

const VideoSizeLimitExceededDialog = ({ dispatch }) => {
    const closeDialog = () => { dispatch({ type: CLOSE_DIALOG }); };

    return (
        <Dialog onClose={closeDialog}>
            <DialogBody className={styles.uploadLimitDialogBody}>
                <div>
                    <div className={styles.uploadLimitDialogTitle}>
                        <Msg k="component.video.videoSizeLimitExceededHeader">
                            Video file size limit exceeded
                        </Msg>
                    </div>
                    <div className={styles.uploadLimitDialogText}>
                        <Msg k="component.video.videoSizeLimitExceededMessage.5gb">
                            Website Builder supports uploading video files which are less than 5 GB in size. Please make sure to upload a smaller file.
                        </Msg>
                    </div>
                </div>
                <div className={styles.acknowledgeButton}>
                    <div>
                        <PrimaryButton onClick={closeDialog}>
                            <Msg k="common.gotIt">
                                Got it
                            </Msg>
                        </PrimaryButton>
                    </div>
                </div>
            </DialogBody>
        </Dialog>
    );
};

const openVideoFileSizeLimitDialog = {
    type: "OPEN_DIALOG",
    payload: { dialogId: VideoSizeLimitExceededDialogId }
};

export default getCenteredDialogConfig(DialogWidth, DialogHeight, VideoSizeLimitExceededDialog);

export {
    getCenteredDialogConfig,
    openVideoFileSizeLimitDialog
};
