import * as R from 'ramda';
import type { ComponentEvalAdjustAfterUpdate } from "./flowTypes";
import { UNDO, REDO, UNDO_INITIAL_STATE } from '../../../../epics/undoManager/updateReasons';

const
    dimensionsChanged = (c1, c2) => {
        return c1.width !== c2.width || c1.height !== c2.height;
    },
    addComponentDimenionsChangeIds: ComponentEvalAdjustAfterUpdate = props => {
        if (props.updateReason === UNDO || props.updateReason === REDO
            || props.updateReason === UNDO_INITIAL_STATE) return { state: props.nextState };

        const
            { componentsMap: nextComponentsMap } = props.nextState.state,
            { componentsMap: prevComponentsMap } = props.prevState.state;

        if (nextComponentsMap !== prevComponentsMap) {
            const changedDimensionsComponentIds = R.keys(nextComponentsMap).reduce((acc, nextComponentId) => {
                const
                    prevComponent = prevComponentsMap[nextComponentId],
                    nextComponent = nextComponentsMap[nextComponentId];

                if (
                    prevComponent && prevComponent !== nextComponent &&
                    dimensionsChanged(prevComponent, nextComponent)
                ) {
                    return [...acc, nextComponentId];
                }

                return acc;
            }, []);

            if (changedDimensionsComponentIds.length) {
                return { state: props.nextState, addCustomFieldToEpicChangeAction: { changedDimensionsComponentIds } };
            } else {
                return { state: props.nextState };
            }
        }

        return { state: props.nextState };
    };

export { addComponentDimenionsChangeIds };
