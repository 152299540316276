import { put, take } from 'redux-saga/effects'; // eslint-disable-line node/file-extension-in-import
import { preparePersistentModel, prepareNewPersistentModel } from "../../../../dal/model/utils";
import type { UpdateSiteDataInput } from "../../../../dal/flowTypes";
import loadPageAction from "../actionCreators/loadPageAction";
import saveSiteDataFromSagaAction from "../actionCreators/saveSiteDataFromSagaAction";
import { UPDATE_SITE_DATA_SAGA_AFTER_SAVE } from "../actionTypes";
import { DataPage } from '../../../../dal/model';

export type UpdateSiteDataSagaInput = UpdateSiteDataInput & {
    nextCurrentPageId?: string;
    isExternalUpdate?: boolean;
};

export default function* ({
    site,
    deletedPages,
    addedPages,
    updatedTemplate,
    nextCurrentPageId,
    isExternalUpdate = false
}: UpdateSiteDataSagaInput): Generator<any, void, any> {
    // prepare models to save
    const
        newSite = preparePersistentModel(site),
        newPages = addedPages && addedPages.map(p => prepareNewPersistentModel(p)) as DataPage[],
        saveSiteDataInput = { site: newSite, addedPages: newPages, deletedPages, updatedTemplate };

    // save site data
    yield put(saveSiteDataFromSagaAction(saveSiteDataInput, isExternalUpdate));
    yield take(UPDATE_SITE_DATA_SAGA_AFTER_SAVE);

    if (nextCurrentPageId) {
        yield put(loadPageAction(nextCurrentPageId));
    }
}
