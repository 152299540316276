import * as R from 'ramda';
import makeEpic, { fullEpicUndoablePath } from '../../../epics/makeEpic';
import { OriginalSiteSettingsSelector } from '../../App/epics/siteSettings/selectorActionTypes';
import * as actionTypes from './actionTypes';
import { OPEN_DIALOG } from '../../../redux/modules/actionTypes';
import { arrayToTrueMap } from "../../../utils/arrayToTrueMap";
import { ON_ORIGINAL_SITE_SETTINGS_SYNC } from "./updateReasons";
import { receiveOnly } from "../../../epics/makeCondition";
import { STYLESHEETS_EPIC_VALUE } from "../../Workspace/epics/stylesheets/valueActionType";
import { COLOR_THEME_SITE_SETTINGS_EPIC_VALUE } from "../ColorThemeData/colorThemeSiteSettingsVAT";
import { getThemeColorsFromStylesheet, ThemeColorSelector } from "../../Workspace/epics/stylesheets/selectors";
import { oneComLangToChatWidgetLang } from "./constants";
import { getLocaleCookie } from "../../Locale/cookie";
import { toHex } from "../../../mappers/color";

export type ChatWidgetGlobalDataStateType = {
    enableChatWidget: boolean,
    chatWidgetLanguage: string,
    chatWidgetColor: string,
    seenChatWidgetSetDialog: boolean
}

export const chatWidgetGlobalDataDefaultState: ChatWidgetGlobalDataStateType = {
    enableChatWidget: false,
    chatWidgetLanguage: "en_US",
    chatWidgetColor: '#0084FF',
    seenChatWidgetSetDialog: false
};

const getValuesWhenColorThemeEnabled = ({
    state,
    scope,
    stylesheets,
    autoColorMode,
}) => {
    let newScope = scope, newState = state;
    if (autoColorMode && state.enableChatWidget) {
        let { accentColor, mainColor, whiteColor, blackColor } = getThemeColorsFromStylesheet(stylesheets),
            palette = [accentColor, mainColor, whiteColor, blackColor];
        newScope = {
            ...newScope,
            colorThemingPalette: palette,
            autoColorMode
        };
        newState.chatWidgetColor = toHex(mainColor);
    }
    return {
        newState,
        newScope
    };
};
const
    notUndoableReasonsMap = arrayToTrueMap([
        OPEN_DIALOG,
    ]),
    setPreviousChatWidgetData = R.assoc('previousChatWidgetData');

const chatWidgetGlobalDataEpic = makeEpic({
    defaultState: chatWidgetGlobalDataDefaultState,
    defaultScope: {
        previousChatWidgetData: chatWidgetGlobalDataDefaultState,
        colorThemingPalette: null,
        autoColorMode: false
    },
    valueActionType: 'CHAT_WIDGET_GLOBAL_DATA_EPIC_VALUE',
    saveIntoSiteSettings: { key: 'chatWidgetData' },
    undo: {
        isUndoableChange: ({ updateReason }) => !notUndoableReasonsMap[updateReason],
        undoablePaths: fullEpicUndoablePath
    },
    updaters: [
        {
            conditions: [OriginalSiteSettingsSelector],
            reducer: ({ state, scope, values: [{ chatWidgetData: previousChatWidgetData }] }) => {
                if (!previousChatWidgetData || previousChatWidgetData === scope) {
                    return { state, scope };
                }

                return ({
                    state,
                    scope: setPreviousChatWidgetData(previousChatWidgetData, scope),
                    updateReason: ON_ORIGINAL_SITE_SETTINGS_SYNC
                });
            }
        },

        {
            conditions: [
                actionTypes.CHAT_WIDGET_TAB_MOUNT
            ],
            reducer: ({ state, scope }) => {
                return {
                    state,
                    scope
                };
            }
        },
        {
            conditions: [
                receiveOnly(STYLESHEETS_EPIC_VALUE),
                receiveOnly(COLOR_THEME_SITE_SETTINGS_EPIC_VALUE),
                actionTypes.ENABLE_CHAT_WIDGET_TOGGLE
            ],
            reducer: ({ state, scope, values: [stylesheets, { autoColorMode }, payload] }) => {
                const { enableChatWidget: oldValue, } = state;
                let enableChatWidget = payload;
                if (payload === undefined) {
                    enableChatWidget = !oldValue;
                }
                const {
                    newState,
                    newScope
                } = getValuesWhenColorThemeEnabled({
                    state: {
                        ...state,
                        enableChatWidget
                    },
                    scope,
                    stylesheets,
                    autoColorMode,
                });
                newState.seenChatWidgetSetDialog = false;
                if (enableChatWidget) {
                    /*
                        In case user plays around with cookie, then it should pickup default chatWidgetLanguage as
                        oneComLangToChatWidgetLang will return null.
                     */
                    newState.chatWidgetLanguage = oneComLangToChatWidgetLang(getLocaleCookie()) || state.chatWidgetLanguage;
                }
                return {
                    state: newState,
                    scope: newScope,
                    updateReason: actionTypes.ENABLE_CHAT_WIDGET_TOGGLE
                };
            }
        },
        {
            conditions: [
                actionTypes.CHANGE_CHAT_WIDGET_LANGUAGE,
            ],
            reducer: ({ state, scope, values: [payload] }) => {
                return {
                    state: {
                        ...state,
                        chatWidgetLanguage: payload
                    },
                    scope,
                    updateReason: actionTypes.CHANGE_CHAT_WIDGET_LANGUAGE,
                };
            }
        },
        {
            conditions: [
                actionTypes.CHANGE_CHAT_WIDGET_COLOR,
            ],
            reducer: ({ state, scope, values: [payload] }) => {
                return {
                    state: {
                        ...state,
                        chatWidgetColor: toHex(payload.color)
                    },
                    scope,
                    updateReason: actionTypes.CHANGE_CHAT_WIDGET_COLOR,
                };
            }
        },
        {
            conditions: [
                receiveOnly(STYLESHEETS_EPIC_VALUE),
                COLOR_THEME_SITE_SETTINGS_EPIC_VALUE
            ],
            reducer: ({ state, scope, values: [stylesheets, { autoColorMode }] }) => {
                const {
                    newState,
                    newScope
                } = getValuesWhenColorThemeEnabled({
                    state,
                    scope,
                    stylesheets,
                    autoColorMode,
                });
                return {
                    state: newState,
                    scope: newScope
                };
            }
        },
        {
            conditions: [
                receiveOnly(STYLESHEETS_EPIC_VALUE),
                receiveOnly(COLOR_THEME_SITE_SETTINGS_EPIC_VALUE),
                ThemeColorSelector
            ],
            reducer: ({ state, scope, values: [stylesheets, { autoColorMode }] }) => {
                const {
                    newState,
                    newScope
                } = getValuesWhenColorThemeEnabled({
                    state,
                    scope,
                    stylesheets,
                    autoColorMode,
                });

                return {
                    state: newState,
                    scope: newScope
                };
            }
        },
        {
            conditions: [
                actionTypes.SEEN_CHAT_WIDGET_SET_DIALOG
            ],
            reducer: ({ state, scope }) => {
                const newState = state;
                newState.seenChatWidgetSetDialog = true;
                return {
                    state: newState,
                    scope
                };
            }
        }
    ]
});

export {
    chatWidgetGlobalDataEpic
};
