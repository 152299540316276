export const FIT = "fit",
    LEFT = "left",
    CENTER = "center",
    RIGHT = "right",
    intlDict = {
        [LEFT]: "msg: component.menu.horizontalAlign.left {Left}",
        [CENTER]: "msg: component.menu.horizontalAlign.center {Center}",
        [RIGHT]: "msg: component.menu.horizontalAlign.right {Right}",
        [FIT]: "msg: common.fit {Fit}"
    };
