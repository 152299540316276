import React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import cx from 'classnames';
import {
    Loading,
    CreateEmailAddressLink,
    LoadingWithDots,
    CreateEmailAddress,
    EmailPlaceHolder
} from "../constants";
import * as styles from './View.css';
import injectIntl from "../../../view/intl/injectIntl";
import {
    OWNER_EMAILS_DROPDOWN_ONLOAD, OWNER_EMAILS_DROPDOWN_ONOPEN,
    OWNER_EMAILS_OPEN_CREATE_EMAIL_URL
} from "../actionTypes";
import type { AppState } from '../../../redux/modules/flowTypes';
import { isDemoSubscriptionType } from '../../App/epics/subscriptionData/isDemoSubscriptionType';
import * as validationUtils from '../../../utils/validation';

const
    createEmailOption = email => ({ value: email, label: email, title: email }),
    prepareEmailOptions = (ownerEmailIds, selectedEmail, allowOtherEmails, subscriptionData) => {
        const { emailIdsLoading, emails } = ownerEmailIds,
            createEmailLink = createEmailOption(CreateEmailAddressLink);
        let allMailIds = emails.map(email => createEmailOption(email)),
            loadingOptions = [createEmailOption(Loading)],
            selectedMailInvalid = false;
        if (selectedEmail) {
            loadingOptions.unshift(createEmailOption(selectedEmail));
            if (!allMailIds.find(mail => selectedEmail === mail.value)) {
                allMailIds.unshift(createEmailOption(selectedEmail));
                selectedMailInvalid = !allowOtherEmails;
            }
        }

        if (!isDemoSubscriptionType(subscriptionData)) {
            loadingOptions.push(createEmailLink);
            allMailIds.push(createEmailLink);
        }

        return { options: (emailIdsLoading ? loadingOptions : allMailIds), selectedMailInvalid };
    };

class OwnerEmails extends React.Component<any> {
    constructor(props) {
        super(props);
        this.onOpen = this.onOpen.bind(this);
    }

    componentDidMount() {
        this.props.dispatch({ type: OWNER_EMAILS_DROPDOWN_ONLOAD });
    }

    onOpen() {
        this.props.dispatch({ type: OWNER_EMAILS_DROPDOWN_ONOPEN });
    }

    render() {
        const {
                ownerEmailIds,
                onChangeAction,
                onInputChange,
                allowOtherEmails,
                selectedEmail,
                showRedIfNoEmailSelected,
                subscriptionData,
                dispatch,
                intl
            } = this.props,
            { options, selectedMailInvalid } = prepareEmailOptions(
                ownerEmailIds,
                selectedEmail,
                null,
                subscriptionData,
            ),
            RenderOption = option => {
                if (selectedMailInvalid && option.value === selectedEmail) {
                    return <div className={styles.invalidOption} />;
                }
                if (option.value === Loading) {
                    return <div className={styles.option}>{intl.msgJoint(LoadingWithDots)}</div>;
                }
                if (option.value === CreateEmailAddressLink) {
                    return (
                        <div className={styles.option}>
                            <a className={styles.link}>{intl.msgJoint(CreateEmailAddress)}</a>
                        </div>
                    );
                }
                return <div className={styles.option}>{option.value}</div>;
            },
            onSelect = option => {
                switch (option.value) {
                    case CreateEmailAddressLink:
                        dispatch({ type: OWNER_EMAILS_OPEN_CREATE_EMAIL_URL });
                        break;
                    case Loading:
                        break;
                    default:
                        dispatch({ type: onChangeAction, payload: option.value });
                }
            },
            showRed =
                allowOtherEmails
                    ? !validationUtils.isValidEmail(selectedEmail)
                    : (!ownerEmailIds.emailIdsLoading && (selectedMailInvalid || (showRedIfNoEmailSelected && !selectedEmail)));

        return (
            <Select
                name="select_email_id"
                options={options}
                optionRenderer={RenderOption}
                className={cx(styles.container, { [styles.showRed]: showRed })}
                onOpen={this.onOpen}
                onChange={onSelect}
                onInputChange={onInputChange}
                value={selectedEmail}
                placeholder={intl.msgJoint(EmailPlaceHolder)}
                searchable
                matchPos="start"
            />
        );
    }
}

const mapStateToProps = (appState: AppState) => ({
    subscriptionData: appState.subscriptionData,
});

export default connect(mapStateToProps)(injectIntl(OwnerEmails));
