import type { PropertiesPanelState } from "../../../../PropertiesPanel/flowTypes";
import type { SiteSettingsEpicState } from "../../../../App/epics/siteSettings/flowTypes";
import { setInstancePropertiesPanelState } from "../../../../PropertiesPanel/instance/setInstancePropertiesPanelState";
import { getMveHeaderStateFromSiteSettings } from "../../getMveHeaderStateFromSiteSettings";
import type { ComponentsMap } from "../../../../../redux/modules/children/workspace/flowTypes";
import type { Stylesheets } from '../../../../Workspace/epics/stylesheets/flowTypes';

type Params = {
    state: PropertiesPanelState,
    siteSettings: SiteSettingsEpicState,
    componentsMap: ComponentsMap,
    stylesheet: Stylesheets,
};

export const setMveHeaderPpStateFromSiteSettings = (
    { state, siteSettings, componentsMap, stylesheet }: Params
): PropertiesPanelState => setInstancePropertiesPanelState(getMveHeaderStateFromSiteSettings({
    siteSettings,
    componentsMap,
    stylesheet,
}), state);
