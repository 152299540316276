import { parseDOM } from "../../../../../utils/parseDOM";
import { type TwitterComponent } from '../types';

export const parseTweetCode = (code: string): TwitterComponent => {
    let params = <TwitterComponent>{};

    const configs = [
        {
            nodeName: 'BLOCKQUOTE',
            validator: node => node.classList.contains("twitter-tweet"),
            innerHTML: 'tweetHTML',
            attributes: {
                'data-lang': 'locale',
                'data-theme': 'theme',
                'data-dnt': 'doNotTrack',
            },
            children: [
                {
                    nodeName: 'P',
                    attributes: {
                        dir: 'direction',
                    }
                }
            ]
        }
    ];

    params = parseDOM(configs, code);

    return params;
};

