let typeCheck = require('type-check').typeCheck;

export default function (input) {
    return {
        isString() {
            return typeCheck('String', input);
        },

        isArray() {
            return typeCheck('Array', input);
        },

        check(type) {
            return typeCheck(type, input);
        }
    };
}
