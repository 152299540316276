import type { AppStore } from "../../../../redux/modules/flowTypes";
import {
    SELECT_SHUTTERSTOCK_IMAGE_ACTION,
    openShutterstockQuotaLimitDialogAction,
    openMaxDownloadsPerRequestDialogAction
} from "../../shutterstock/actions";
import {
    shutterstockDownloadsAppSel,
    shutterstockDownloadedImagesLoadingAppSel,
    shutterstockImagesLoadingAppSel,
    shutterstockSelectedImagesIdsAppSel
} from "../../shutterstock/selectors";
import { sstockQuotaAppStateSel } from "../../../App/epics/appConfig/selectors";
import { subscriptionTypeStateSel } from "../../../App/epics/subscriptionData/selectors";
import { checkSstockImagesDownloadsQuota } from '../../../../../../server/shared/checkSstockImagesDownloadsQuota';
import { fcDialogActiveTabIdAppSel } from "../../../../redux/modules/children/fileChooser/selectors";
import {
    ShutterstockDailyLimitMessages,
    ShutterstockNormalLimitMessages,
} from "../../shutterstock/components/dialogs/messages";
import { isSutterstockKindAction } from "../../shutterstock/isSutterstockKindAction";
import { ShutterstockKind } from "../../shutterstock/constants";
import { isUnknownSubscription, isNormalSubscription } from "../../../App/epics/subscriptionData/utils";

export const freeOnecomMiddleware = (store: AppStore) => (next: Dispatch) => (action: Action) => {
    if (!isSutterstockKindAction(ShutterstockKind.FREE, action)) return next(action);

    if (action.type === SELECT_SHUTTERSTOCK_IMAGE_ACTION) {
        const
            state = store.getState(),
            activeTabId = fcDialogActiveTabIdAppSel(state),
            imagesLoading = shutterstockImagesLoadingAppSel(activeTabId)(state),
            downloadedImagesLoading = shutterstockDownloadedImagesLoadingAppSel(activeTabId)(state),
            existingDownloads = shutterstockDownloadsAppSel(activeTabId)(state),
            selectedImagesIds = shutterstockSelectedImagesIdsAppSel(activeTabId)(state),
            lastImageSelectedId = action.payload.imageId,
            subscription = subscriptionTypeStateSel(state),
            quotaConfig = sstockQuotaAppStateSel(state),
            isMultiSelect = state.fileChooser.isMultiSelect;

        // Handle the mess of "unknown" subscription
        if (isUnknownSubscription(subscription)) {
            return; // eslint-disable-line consistent-return
        }

        if (downloadedImagesLoading || imagesLoading) {
            throw new Error(`No images to select.`);
        }

        let requestedDownloadsIds;
        if (isMultiSelect) {
            if (selectedImagesIds.indexOf(lastImageSelectedId) === -1) {
                requestedDownloadsIds = [...selectedImagesIds, lastImageSelectedId];
            } else {
                requestedDownloadsIds = [...selectedImagesIds];
            }
        } else {
            requestedDownloadsIds = [lastImageSelectedId];
        }

        if (requestedDownloadsIds.length > quotaConfig.perRequest) {
            return store.dispatch(openMaxDownloadsPerRequestDialogAction(activeTabId, quotaConfig.perRequest));
        }

        const
            checkParams = {
                requestedDownloadsIds,
                existingDownloads,
                subscription,
                quotaConfig,
            },
            checkRes = checkSstockImagesDownloadsQuota(checkParams);

        if (!checkRes) {
            let dialogProps;
            if (isNormalSubscription(subscription)) {
                dialogProps = ShutterstockNormalLimitMessages;
            } else {
                dialogProps = ShutterstockDailyLimitMessages;
            }

            return store.dispatch(openShutterstockQuotaLimitDialogAction(dialogProps));
        }

        return next(action);
    }

    return next(action);
};
