import cx from "classnames";
import getCenteredDialog from "../../../DialogManager/getCenteredDialogConfig";
import { configurationDialogFactory } from '../configurationDialogFactory';
import { EventbriteDialogHeight, DialogWidth } from '../constants';
import { WIDGETS_EVENTBRITE_SET_LINK } from "./actionTypes";
import { validateLinkOrCode } from '../utils';
import { parseEventbriteCode } from './utils/parseEventbriteCode';
import Icons from '../view/Icons.css';

const validateEventbriteData = validateLinkOrCode([], parseEventbriteCode);

const
    configurationDialogId = "WIDGETS_EVENTBRITE_CONFIG_DIALOG",
    configurationDialogView = configurationDialogFactory({
        saveAction: WIDGETS_EVENTBRITE_SET_LINK,
        dialogIconClass: cx(Icons.eventbrite, Icons.dialog),
        dialogTitle: "msg: widgets.eventbrite.label {Eventbrite}",
        info: "msg: widgets.eventbrite.config.info {Add an Eventbrite button or embedded checkout to your page to sell event tickets.}",
        subInfo: "msg: widgets.eventbrite.configDialog.copyPaste {Go to the published Eventbrite event that you want to share. Select the share icon, then \'Copy event URL\', and paste the URL below. }", // eslint-disable-line max-len
        learnMore: "msg: widgets.eventbrite.config.learnMore {https://help.one.com/hc/en-us/articles/11406194678673}",
        inputPlaceHolder: "msg: widgets.eventbrite.configDialog.placeHolder {Paste Eventbrite embed URL}",
        isLinkValid: validateEventbriteData,
        termsOfService: "msg: widgets.eventbrite.config.termsOfService {By using this widget, you agree to Eventbrite\'s {termsOfServiceLink}.}", // eslint-disable-line max-len
        termsOfServiceLink: "msg: widgets.eventbrite.config.termsOfServiceLink {https://www.eventbrite.co.uk/support/articles/en_US/Troubleshooting/eventbrite-terms-of-service?lg=en_GB}",
    }),
    configurationDialog = getCenteredDialog({
        width: DialogWidth,
        height: EventbriteDialogHeight,
        component: configurationDialogView
    });

export { configurationDialogId, configurationDialog };
