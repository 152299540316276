import { $ } from 'tinymce';
import React from "react";
import Measure from 'react-measure';
import cx from 'classnames';
import TextStyles from './styles.css';
import {
    TINY_MCE_DESTROYED,
    TINY_MCE_FOCUS_LAST_NODE,
    TINY_MCE_INITIALIZED,
    TINY_MCE_SELECTION_CHANGED,
    TINY_MCE_PICK_LINKED_INPUT_TRIGGER_CHAR_ENTERED,
    TYNY_MCE_ENTER_KEY_DOWN,
    TINY_MCE_INSERT_CONTENT,
} from "../../../App/epics/tinyMceEpic/actionTypes";
import { isBrowser } from "../../../../utils/isBrowser";
import textNormalClassName from "../globalStyle/textNormalClassName";
import { CTRL_Z_PRESSED, CTRL_SHIFT_Z_PRESSED } from '../../../App/actionTypes';
import { replaceTagsWithContentDOM } from "./replaceTagsWithContentDOM";
import { DataSite } from '../../../../../dal/model/index';
import type {
    ThemeColorDataType,
    ThemeSiteSettingsDataType,
    ThemeColorTypes,
    ThemeBackgroundType
} from "../../../ThemeGlobalData/flowTypes";
import { THEME_TEXT_CLASS } from '../../../ThemeGlobalData/constants';
import { makeThemeHighlightAndShadowStyles } from './makeThemeHighlightAndShadowStyles';
import { replaceDialog } from '../../../App/actionCreators/index';
import TextCharacterLimitExceededDialogId from "./Dialog/TextCharacterLimitExceededDialogId";
import { TEXT_LIMIT_SHOW_WARNING } from "../../../App/epics/textLimitWarning/actionTypes";
import { SPELLING_FIX_APPLIED } from '../actionTypes';

const TextInEditMode = isBrowser() ?
    require('./TextInEditMode').default :
    () => {
        throw new Error('TextInEditMode should not be used on server side');
    };

type Props = {
    inEditMode: boolean,
    content: string,
    className?: string,
    onHeightChanged: (number) => any,
    height: number,
    dispatch: Dispatch,
    handleEditorChangeActionType: string,
    handleEditorChangeIdentificationData: Object,
    focusLastNodeOnEdit?: boolean,
    occupy100PctOfHeight?: true,
    disableMeasure?: true,
    globalVariables?: Object,
    site: DataSite,
    verticalAlignment: 'top' | 'middle' | 'bottom',
    selectedParentTheme: ThemeBackgroundType,
    themeOverrideColor: ThemeColorTypes,
    themeSettingsData: ThemeSiteSettingsDataType,
    themeColorsData: ThemeColorDataType,
    themeHighlightColor?: ThemeColorTypes|null,
    themeShadowBlurRadius: number,
    themeShadowColor?: ThemeColorTypes|null,
    themeShadowOffsetX: number,
    themeShadowOffsetY: number,
}

type State = {
    tinyMceActive: boolean
};

const EMPTY_THEME_HIGHLIGHT_AND_SHADOW_STYLES = {};

export default class TextWrapper extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.setTinyMceState = this.setTinyMceState.bind(this);
        this.state = { tinyMceActive: false };
    }

    setTinyMceState(tinyMceState: boolean) {
        this.setState({ tinyMceActive: tinyMceState });
    }

    render() {
        const
            {
                props: {
                    inEditMode,
                    content,
                    className,
                    dispatch,
                    onHeightChanged,
                    height,
                    handleEditorChangeActionType,
                    handleEditorChangeIdentificationData,
                    occupy100PctOfHeight,
                    disableMeasure,
                    verticalAlignment,
                    globalVariables,
                    site,
                    selectedParentTheme,
                    themeSettingsData,
                    themeColorsData,
                    themeOverrideColor,
                    themeHighlightColor,
                    themeShadowBlurRadius,
                    themeShadowColor,
                    themeShadowOffsetX,
                    themeShadowOffsetY,
                }
            } = this,
            { autoColorMode = false } = themeSettingsData,
            onContentChanged = (content) => {
                dispatch({
                    type: handleEditorChangeActionType,
                    payload: {
                        content,
                        ...handleEditorChangeIdentificationData
                    }
                });
            },
            onResize = ({ offset: { height } }: MeasureBounds, fromEditMode = false) => {
                let tinyMceStateBefore = this.state.tinyMceActive;
                // setTimeout is used because onContentChange should always be fired before onResize
                setTimeout(() => {
                    if (!inEditMode || this.state.tinyMceActive) {
                        // this logic is to avoid resizing while attaching tinymce
                        if (fromEditMode) {
                            if (tinyMceStateBefore === true) {
                                onHeightChanged(Math.round(height));
                            }
                        } else {
                            onHeightChanged(Math.round(height));
                        }
                    }
                }, 300);
            },
            finalClassName = cx(className, 'textnormal'),
            contentInsideDiv = (() => {
                // @ts-ignore
                return replaceTagsWithContentDOM(content, { globalVariables, site }, true);
            })(),
            textInEditModeProps = {
                textChanged: onContentChanged,
                content: contentInsideDiv,
                height,
                className: finalClassName,
                style: autoColorMode ? makeThemeHighlightAndShadowStyles(
                    themeColorsData,
                    themeHighlightColor,
                    themeShadowBlurRadius,
                    themeShadowColor,
                    themeShadowOffsetX,
                    themeShadowOffsetY
                ) : EMPTY_THEME_HIGHLIGHT_AND_SHADOW_STYLES,
                onInputChange: (e) => {
                    if (e.inputType !== 'insertReplacementText') return;
                    dispatch({ type: SPELLING_FIX_APPLIED });
                },
                onTextSelectionChanged: (e, isChangedUsingMouse) => {
                    dispatch({ type: TINY_MCE_SELECTION_CHANGED, payload: isChangedUsingMouse });
                },
                onTextLimitExceeded: () => {
                    dispatch(replaceDialog(TextCharacterLimitExceededDialogId));
                },
                onTextLimitWarning: (payload) => {
                    dispatch({ type: TEXT_LIMIT_SHOW_WARNING, payload });
                },
                onEditorInit: () => {
                    this.setTinyMceState(true);
                    dispatch({
                        type: TINY_MCE_INITIALIZED,
                        payload: {
                            removeFocus: false
                        }
                    });
                },
                onHashKeyEntered: (payload) => dispatch({
                    type: TINY_MCE_PICK_LINKED_INPUT_TRIGGER_CHAR_ENTERED,
                    payload
                }),
                onUnmount: () => {
                    this.setTinyMceState(false);
                    dispatch({ type: TEXT_LIMIT_SHOW_WARNING, payload: { showWarning: false } });
                    dispatch({ type: TINY_MCE_DESTROYED });
                },
                onUndo: () => dispatch({ type: CTRL_Z_PRESSED }),
                onRedo: () => dispatch({ type: CTRL_SHIFT_Z_PRESSED }),
                onCtrlShiftA: () => dispatch({ type: 'CTRL_SHIFT_A_PRESSED' }),
                onCtrlShiftE: () => dispatch({ type: 'CTRL_SHIFT_E_PRESSED' }),
                onCtrlShiftP: () => dispatch({ type: 'CTRL_SHIFT_P_PRESSED' }),
                onCtrlShiftW: () => dispatch({ type: 'CTRL_SHIFT_W_PRESSED' }),
                onEnterKeyDown: (payload) => dispatch({ type: TYNY_MCE_ENTER_KEY_DOWN, payload }),
            };

        if (inEditMode) {
            const autoColorClassNames = cx(
                autoColorMode && [TextStyles.invalidateHighlightAndShadows, selectedParentTheme, THEME_TEXT_CLASS],
                themeOverrideColor
            );
            if (occupy100PctOfHeight) {
                const className = cx(
                    TextStyles.textWrapperInEditMode,
                    TextStyles.occupy100PctOfHeight,
                    autoColorClassNames,
                );
                const makeView = (textView) => {
                    const fillSpaceDiv = <div
                        contentEditable
                        style={{ outline: "none", lineHeight: 0 }}
                        onInput={(e) => {
                            e.preventDefault();
                            // @ts-ignore
                            e.target.innerHTML = '';
                        }}
                        onPaste={(e) => {
                            e.preventDefault();
                            const clipboardData: any = e.clipboardData || window.clipboardData;
                            // WBTGEN-11385: require instead of import, to prevent error in server side preview about tinymce
                            const parseClipboardData =
                            require('../../../../utils/parseClipboardData').parseClipboardData;
                            const { html } = parseClipboardData(clipboardData);
                            dispatch({ type: TINY_MCE_INSERT_CONTENT, payload: html });
                        }}
                        className={TextStyles.fillspace}
                        onClick={() => dispatch({ type: TINY_MCE_FOCUS_LAST_NODE })}
                        onFocus={() => dispatch({ type: TINY_MCE_FOCUS_LAST_NODE })}
                    />;

                    switch (verticalAlignment) {
                        case 'top':
                            return <div className={className}>{textView}{fillSpaceDiv}</div>;
                        case 'middle':
                            return <div className={className}>{fillSpaceDiv}{textView}{fillSpaceDiv}</div>;
                        case 'bottom':
                            return <div className={className}>{fillSpaceDiv}{textView}</div>;
                        default:
                            /*:: (verticalAlignment: empty); */
                            return <div className={cx(autoColorMode && selectedParentTheme, themeOverrideColor)} />;
                    }
                };
                if (disableMeasure) {
                    return makeView(<TextInEditMode {...textInEditModeProps} />);
                }
                return makeView(
                    <Measure offset onResize={(e) => onResize(e, true)}>
                        {({ measureRef }) => <TextInEditMode {...textInEditModeProps} measureRef={measureRef} />}
                    </Measure>
                );
            }

            if (disableMeasure) {
                return (<div className={autoColorClassNames}><TextInEditMode {...textInEditModeProps} /></div>);
            }
            return (
                <Measure offset onResize={onResize}>
                    {({ measureRef }) => <div className={autoColorClassNames}>
                        <TextInEditMode {...textInEditModeProps} measureRef={measureRef} />
                    </div>}
                </Measure>
            );
        }

        // @ts-ignore TODO: Fix TS
        const makeView = ({ measureRef } = {}) => {
            const contentDiv = (style = {}) => {
                const attributes = { style, className: finalClassName, ref: measureRef };
                return (
                    <div
                        className={cx(
                            autoColorMode && TextStyles.invalidateHighlightAndShadows,
                            autoColorMode && selectedParentTheme,
                            autoColorMode && THEME_TEXT_CLASS,
                            themeOverrideColor
                        )}
                        style={{
                            width: '100%',
                            ...(autoColorMode ? makeThemeHighlightAndShadowStyles(
                                themeColorsData,
                                themeHighlightColor,
                                themeShadowBlurRadius,
                                themeShadowColor,
                                themeShadowOffsetX,
                                themeShadowOffsetY
                            ) : {})
                        }}
                    >
                        <div {...attributes} dangerouslySetInnerHTML={{ __html: contentInsideDiv }} />
                    </div>
                );
            };

            switch (verticalAlignment) {
                case 'top':
                    return contentDiv();
                case 'middle':
                case 'bottom':
                    return <div style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: verticalAlignment === 'middle' ? 'center' : 'flex-end'
                    }}
                    >
                        {contentDiv({ width: '100%' })}
                    </div>;
                default:
                    /*:: (verticalAlignment: empty); */
                    return <div />;
            }
        };
        if (disableMeasure) {
            return makeView();
        }
        return (
            <Measure
                offset
                onResize={onResize}
            >
                {makeView}
            </Measure>
        );
    }
}

const isEventOnTextLink = (evnt) => {
    const
        $target = $(evnt.target || evnt.srcElement),
        targetIsALink = $target.is('a'),
        targetHasParentAnchor = $target.closest('a').length !== 0,
        textNormalParentExist = $target.closest(`.${textNormalClassName}`).length !== 0;

    if ((targetHasParentAnchor || targetIsALink) && textNormalParentExist) {
        return true;
    }
    return false;
};

document.addEventListener("click", function (evnt: any) {
    if (isEventOnTextLink(evnt)) {
        evnt.preventDefault();
    }
});

document.ondragstart = function (e) {
    if (isEventOnTextLink(e)) {
        return false;
    }
    return undefined;
};
