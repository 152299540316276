import React from 'react';
import { useSelector } from "react-redux";

import { setStreetAddressAction } from "./action";
import { selectAddressCountryCode, selectStreetAddress } from "../../../../../common/selectors";
import { placeholderFieldFormatMap } from "../../../../../../../../wbtgen/src/components/SiteSettings/General/utils";
import {
    STREET_ADDRESS
} from "../../../../../../../../wbtgen/src/components/SiteSettings/BusinessListing/GoogleMyBusiness/Mappers/gmbErrorFields";
import { AddressSettingsCommonForm } from "./AddressSettingsCommonForm";

const { getCountryFormat } = require('../../../../../../../../server/shared/address/getCountryFormat.js');

export const AddressSettingsStreetAddressForm = () => {
    const countryFormat = getCountryFormat(useSelector(selectAddressCountryCode));
    return (
        <AddressSettingsCommonForm
            label="msg: generalInfo.street.label {Street address}"
            placeholder={placeholderFieldFormatMap[countryFormat].street}
            selector={selectStreetAddress}
            handleChangeAction={setStreetAddressAction}
            errorField={STREET_ADDRESS}
        />
    );
};
