import type { AppState } from "../../../redux/modules/flowTypes";
import type { MobileHeaderState } from "../../Mobile/header/types";
import { getMveHeaderStateFromSiteSettings } from "./getMveHeaderStateFromSiteSettings";
import { siteSettingsAppSel } from "../../App/epics/siteSettings/selectorActionTypes";
import { workspaceComponentsMapSelector } from "../../Workspace/selectors";
import { stylesheetAppSel } from "../../Workspace/epics/stylesheets/selectors";
import { memoMaxOne } from "../../../../utils/memo";

const mveHeaderStateAppSelMemo = memoMaxOne(
    (siteSettings, componentsMap, stylesheet) => getMveHeaderStateFromSiteSettings({
        siteSettings,
        componentsMap,
        stylesheet,
    }),
);

export const mveHeaderStateAppSel = (appState: AppState): MobileHeaderState => mveHeaderStateAppSelMemo(
    siteSettingsAppSel()(appState),
    workspaceComponentsMapSelector(appState),
    stylesheetAppSel(appState),
);
