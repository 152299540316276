/* eslint-disable max-len */

import * as R from "ramda";

import makeEpic from "../../../../../epics/makeEpic";
import { VIDEO_COMPONENT_VAT } from "./videoComponentVAT";
import {
    VIDEO_SET, VIDEO_FILE_UPDATE, CALL_CHECK_TRANSCODE_STATUS_REQUEST
} from "../../actionTypes";
import { OPEN_DIALOG } from "../../../../../redux/modules/actionTypes";
import * as ActionTypes from "../../actionTypes";
import { componentConfigurationCompleteAction } from "../../../../../redux/modules/children/workspace/actionCreators";
import buildNewVideoComponentState from "../../buildNewVideoComponentState";
import { getOpenVideoFileConfig } from "../../actionCreators/videoFileActionCreator";
import { VideoTypes } from "../../constants";
import makeStateSelectorReducer from "../../../../../epics/makeStateSelectorReducer";
import { videoStatusToaster, updateVideoStatusProgress, closeVideoStatusToaster } from "../../toaster/VideoStatusToaster";
import { UPLOADING_FILE, OPTIMIZING_VIDEO, UPLOAD_COMPLETE, UPLOAD_FAILED } from "../../toaster/videoStatus";
import { createScheduledAction } from "../../../../../redux/middleware/schedule/actionCreators";
import {
    FC_NAVIGATE_SUB_DIRECTORY,
    FC_REMOVE_GHOST_RESOURCE_BY_NAME,
    FC_RESOURCE_UPLOAD_CANCELED
} from "../../../../../redux/modules/children/fileChooser/actionTypes";

/*
    Video Upload Lifecycle from /wsbVideoStatus api
    uploaded -> progressing -> completed
                            -> error
*/
const
    UPLOADED = "uploaded",
    TRANSCODING = "progressing",
    FAILED = "error";

const defaultState = {
    uploadingList: [], // List of videos which are in the entire upload process. Eg: [ 'test.flv' ]
    clientUploadQ: [], // List of videos which are being uploaded by the browser. Eg: [ 'test.mp4' ]
    transcodingQ: {}, // List of transcoding videos with progress. Eg: { 'test.flv': 70 }
    /*
        List of videos which are failed during upload / transcode. Eg: [ 'test.mp4' ]
        Persists videos which got failed during upload itself as its not available status in polling response.
    */
    failedVideos: []
};

const filterByStatus = (response: Record<string, any>, statuses: Array<string>) => {
    return Object.keys(response).filter(k => statuses.includes(response[k].status));
};

const removeGhostVideoAction = (videoName: string) => ({
    type: FC_REMOVE_GHOST_RESOURCE_BY_NAME,
    payload: { resource: videoName }
});

// Refreshes the file chooser contents if its open
const refreshFiles = {
    type: FC_NAVIGATE_SUB_DIRECTORY,
    payload: { subDirectory: '/' }
};

const updateTranscodeProgress = (transcodingQ: Record<string, any>, transcodingVideos: Array<string>, statusResponse: Record<string, any>) => {
    const transcodeCompletedList = R.without(transcodingVideos, Object.keys(transcodingQ));

    let
        updatedTranscodingQ = { ...transcodingQ },
        toasterActions: any = [];

    // Toaster actions for failed / completed videos
    transcodeCompletedList.forEach(video => {
        if (updatedTranscodingQ[video] !== undefined) {
            delete updatedTranscodingQ[video];
            const isStatusFailed = statusResponse[video].status === FAILED;
            toasterActions.push(
                updateVideoStatusProgress(OPTIMIZING_VIDEO, video, 100),
                closeVideoStatusToaster(OPTIMIZING_VIDEO, video),
                videoStatusToaster({
                    status: isStatusFailed ? UPLOAD_FAILED : UPLOAD_COMPLETE, permanent: isStatusFailed, videoName: video
                }),
                removeGhostVideoAction(video),
                refreshFiles
            );
        }
    });

    // Toaster action to update optimizing %
    transcodingVideos.forEach(video => {
        let updateProgressAction: any = null;
        if (updatedTranscodingQ[video] === undefined) {
            updatedTranscodingQ[video] = 0;
            updateProgressAction = videoStatusToaster({ status: OPTIMIZING_VIDEO, videoName: video });
        } else if (updatedTranscodingQ[video] !== undefined && updatedTranscodingQ[video] < 90) {
            updatedTranscodingQ[video] = updatedTranscodingQ[video] + 10;
            updateProgressAction = updateVideoStatusProgress(OPTIMIZING_VIDEO, video, updatedTranscodingQ[video]);
        }
        if (updateProgressAction) toasterActions.push(updateProgressAction);
    });

    return [updatedTranscodingQ, transcodeCompletedList, toasterActions];
};

export const videoComponentEpic = makeEpic({
    defaultState,
    valueActionType: VIDEO_COMPONENT_VAT,
    updaters: [
        {
            conditions: [VIDEO_SET],
            reducer: ({ state, values: [asset] }) => {
                return {
                    state,
                    actionToDispatch: componentConfigurationCompleteAction({ ...asset, ...buildNewVideoComponentState(null) })
                };
            }
        },
        {
            conditions: [ActionTypes.VIDEO_REPLACE_VIDEO_FILE],
            reducer: ({ state, values: [{ component }] }) => {
                const isYoutube = component.state.type === VideoTypes.YOUTUBE,
                    { dialogId, dialogProps } = getOpenVideoFileConfig({ onSaveAction: VIDEO_FILE_UPDATE });
                let actionToDispatch: Action = {
                    type: OPEN_DIALOG,
                    payload: { dialogId, props: dialogProps }
                };

                if (isYoutube) {
                    actionToDispatch = { type: ActionTypes.YOUTUBE_REPLACE_VIDEO };
                }
                return {
                    state,
                    actionToDispatch,
                };
            }
        },
        {
            conditions: [ActionTypes.VIDEO_UPLOAD_STARTED],
            reducer: ({ state, values: [{ fileName }] }) => {
                let videoName = encodeURIComponent(fileName);
                const
                    modifiedFailedVideos = state.failedVideos.filter(name => name !== fileName),
                    modifiedUploadingList = state.uploadingList.includes(videoName) ? [...state.uploadingList] : [...state.uploadingList, videoName],
                    modifiedClientUploadQ = state.clientUploadQ.includes(videoName) ? [...state.clientUploadQ] : [...state.clientUploadQ, videoName],
                    newState = {
                        ...state,
                        failedVideos: modifiedFailedVideos,
                        uploadingList: modifiedUploadingList,
                        clientUploadQ: modifiedClientUploadQ
                    };

                let multipleActionsToDispatch: Action[] = [];
                multipleActionsToDispatch.push(videoStatusToaster({ status: UPLOADING_FILE, videoName }));

                // if (state.transcodingQ.length) {
                //     multipleActionsToDispatch.push( { type: ActionTypes.FETCH_TRANSCODE_STATUS_UNTIL_VIDEO_UPLOADS });
                // }

                return {
                    state: newState,
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [FC_RESOURCE_UPLOAD_CANCELED],
            reducer: ({ state }) => {
                return {
                    state,
                    multipleActionsToDispatch: [
                        { type: ActionTypes.FETCH_TRANSCODE_STATUS_UNTIL_VIDEO_UPLOADS }
                    ]
                };
            }
        },
        {
            conditions: [ActionTypes.CHECK_TRANSCODE_STATUS_FAILURE],
            reducer: ({ state }) => {
                return {
                    state,
                    multipleActionsToDispatch: [
                        { type: ActionTypes.FETCH_TRANSCODE_STATUS_UNTIL_VIDEO_UPLOADS }
                    ]
                };
            }
        },
        {
            conditions: [ActionTypes.FETCH_TRANSCODE_STATUS_UNTIL_VIDEO_UPLOADS],
            reducer: ({ state }) => {
                let multipleActionsToDispatch: Action[] = [];
                if (state.uploadingList.length) {
                    multipleActionsToDispatch.push(
                        { type: CALL_CHECK_TRANSCODE_STATUS_REQUEST },
                        createScheduledAction({
                            actionToDispatch: {
                                type: ActionTypes.FETCH_TRANSCODE_STATUS_UNTIL_VIDEO_UPLOADS
                            },
                            timeout: 15000
                        })
                    );
                }
                return {
                    state,
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [ActionTypes.VIDEO_UPLOAD_FINISHED],
            reducer: ({ state, values: [{ fileName }] }) => {
                let modifiedList = state.uploadingList.filter(name => name !== fileName);
                return {
                    state: {
                        ...state,
                        uploadingList: modifiedList
                    }
                };
            }
        },
        {
            conditions: [ActionTypes.VIDEO_UPLOAD_FAILED],
            reducer: ({ state, values: [{ fileName }] }) => {
                let videoName = encodeURIComponent(fileName);
                let
                    newFailedVideos = state.failedVideos.includes(videoName) ? [...state.failedVideos] : [...state.failedVideos, videoName],
                    newUploadingList = state.uploadingList.filter(name => name !== videoName),
                    newClientUploadQ = state.clientUploadQ.filter(name => name !== videoName),
                    multipleActionsToDispatch = [
                        closeVideoStatusToaster(UPLOADING_FILE, videoName),
                        videoStatusToaster({ status: UPLOAD_FAILED, permanent: true, videoName }),
                        removeGhostVideoAction(videoName),
                        refreshFiles
                    ];

                return {
                    state: {
                        ...state,
                        uploadingList: newUploadingList,
                        clientUploadQ: newClientUploadQ,
                        failedVideos: newFailedVideos
                    },
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [ActionTypes.TRANSCODE_STATUS_UPDATER],
            reducer: ({ values: [payload], state }) => {
                const
                    { statusResponse } = payload,
                    uploadedVideos = filterByStatus(statusResponse, [UPLOADED, TRANSCODING]); // currently optimising videos

                const
                    newClientUploadQ = R.without(uploadedVideos, state.clientUploadQ), // Removing videos from Q which are uploaded by browser
                    uploadingToastersCloseList = R.intersection(uploadedVideos, state.clientUploadQ), // Removed videos from Q which are uploaded by browser
                    uploadingToastersCloseAction = uploadingToastersCloseList.map(video => closeVideoStatusToaster(UPLOADING_FILE, video));

                const
                    [newTranscodingQ, transcodeCompletedList, otherToasterActions] = updateTranscodeProgress(state.transcodingQ, uploadedVideos, statusResponse),
                    newUploadingQ = R.without(transcodeCompletedList, state.uploadingList);

                const multipleActionsToDispatch = [
                    ...uploadingToastersCloseAction,
                    ...otherToasterActions
                ];

                // TODO : check if { type: AUTO_CLEANUP_MESSAGE } needs to be dispatched

                const newState = {
                    ...state,
                    clientUploadQ: newClientUploadQ,
                    transcodingQ: newTranscodingQ,
                    uploadingList: newUploadingQ
                };

                return {
                    state: newState,
                    multipleActionsToDispatch
                };
            }
        }
    ]
});

export const videoUploadStatus = makeStateSelectorReducer(
    videoComponentEpic.reducer,
    VIDEO_COMPONENT_VAT,
    R.pipe(R.path(['uploadingList']), R.defaultTo([]))
);

export const uploadFailedVideos = makeStateSelectorReducer(
    videoComponentEpic.reducer,
    VIDEO_COMPONENT_VAT,
    R.pipe(R.path(['failedVideos']), R.defaultTo([]))
);
