/* eslint-disable max-len */

import React from 'react';
import cx from 'classnames';
import Scrollbar from '../../../../../view/common/Scrollbar/index';
import { Msg } from '../../../../../view/intl/index';
import * as styles from './PublishErrorDialogs.css';
import closeDialog from "../../../../App/actionCreators/closeDialog";
import { PUBLISH } from "../../../../App/actionTypes";
import { publishCancelledAfterErrorDialog } from "../../../actionCreators/index";
import { APP_STANDARD_ERROR_DIALOG_HEIGHT } from "../../../../App/dialogs/constants";
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import WarningDialog from '../../../../../view/common/dialogs/WarningDialog/index';
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import { isHtAccessInModifiedURLs } from "../utils";
import { openSiteSettingsDialog } from "../../../../SiteSettings/SiteSettingsDialog/actionCreators";
import { SiteSettingsTabName } from "../../../../SiteSettings/SiteSettingsDialog/constants";

const OverwriteAssets = ({
    publishConfig,
    appConfigPublishWithSSL,
    urls,
    siteOriginFrom,
    publicationId,
    dispatch
}) => {
    const htAccessInOverwrittenAssets = appConfigPublishWithSSL && isHtAccessInModifiedURLs(urls, publishConfig);

    let contents: Array<React.ReactElement<any, any>> = [];

    if (htAccessInOverwrittenAssets) {
        const
            SiteSettingsLink = () => (
                <a
                    onClick={() => {
                        dispatch(closeDialog());
                        dispatch(openSiteSettingsDialog({ activeTabKey: SiteSettingsTabName.SECURITY }));
                    }}
                    className={styles.websiteSettings}
                >
                    <Msg k="publish.websiteSettings">Website settings</Msg>
                </a>
            ),
            siteSettingsLink = <SiteSettingsLink />;

        if (publishConfig.publishWithSSL) {
            contents.push(
                (
                    <Msg k="publish.overwriteFiles.htAccessForSSL" key={1}>
                        The .htaccess file is used to enable a secure connection (https) to your website.
                    </Msg>
                )
            );
        } else if (publishConfig.dropHtmlExtension) {
            contents.push(
                (
                    <Msg k="publish.overwriteFiles.htAccessForBeautifulURL" key={1}>
                        The .htaccess file is used to remove the .html ending from URLs.
                    </Msg>
                )
            );
        }

        contents.push(
            (<VerticalSpacer y={19} key={4} />),
            (
                <div className={styles.overwriteInfo} key={5}>
                    <Msg k="common.click">Click</Msg>
                    <strong> <Msg k="common.Continue">Continue</Msg> </strong>
                    <Msg k="publish.overwriteFiles.overwriteInfo">
                        to replace the existing pages with the Website Builder pages.
                    </Msg>
                </div>
            )
        );

        if (publishConfig.publishWithSSL || publishConfig.dropHtmlExtension) {
            contents.push(
                (<VerticalSpacer y={19} key={6} />),
                (
                    <Msg key={7} k="publish.publishFailure.changeSiteSettings" params={{ link: siteSettingsLink }}>
                        {`To keep your current .htaccess file, click cancel. Then go to {link}, disable both Use SSL & Beautify URLs and publish again.`}
                    </Msg>
                )
            );
        }
    } else {
        contents = [
            (
                <div className={styles.overwriteInfo} key={5}>
                    <Msg k="common.click">Click</Msg>
                    <strong> <Msg k="common.Continue">Continue</Msg> </strong>
                    <Msg k="publish.overwriteFiles.overwriteInfo">
                        to replace the existing pages with the Website Builder pages.
                    </Msg>
                </div>
            )
        ];
    }

    if (siteOriginFrom === 'simplesite' && !publicationId) {
        const faqsLink = (
            <a
                // It will auto-redirect the user to the correct language
                href="https://help.one.com/hc/articles/7811627598737-FAQ-about-the-migration-from-SimpleSite-123-sites-to-one-com"
                className={styles.link}
                target="_blank"
            >
                <Msg k="simpleSite.publish.faqsLink">FAQ</Msg>
            </a>
        );
        return (
            <WarningDialog
                title="msg: simpleSite.publish.overwriteFiles.title {Publish with Website Builder}"
                mctaText="msg: common.Continue {Continue}"
                mctaHandler={() => dispatch({ type: PUBLISH, payload: { force: true } })}
                sctaHandler={() => { dispatch(publishCancelledAfterErrorDialog()); dispatch(closeDialog()); }}
            >
                <div>
                    <Msg k="simpleSite.publish.overwriteFiles.msgTitle">Your website has been successfully migrated, and you're about to publish from one.com's Website Builder for the first time. We suggest that you double-check how your website looks in the editor before doing so.</Msg>
                </div>

                <div style={{ marginTop: 50 }}>
                    <Msg
                        k="simpleSite.publish.aboutMigrationFaq"
                        params={{ link: faqsLink }}
                    >{`You can find more details about this migration in our {link}.`}</Msg>
                </div>
            </WarningDialog>
        );
    } else {
        return (
            <WarningDialog
                title="msg: publish.overwriteFiles.title {Pages modified outside of Website Builder}"
                mctaText="msg: common.Continue {Continue}"
                mctaHandler={() => dispatch({ type: PUBLISH, payload: { force: true } })}
                sctaHandler={() => { dispatch(publishCancelledAfterErrorDialog()); dispatch(closeDialog()); }}
            >
                <div className={styles.title}>
                    <Msg k="publish.overwriteFiles.msgTitle">The following pages have been modified outside of Website Builder:</Msg>
                </div>
                <Scrollbar height="70px">
                    <div
                        className={
                            cx(
                                (htAccessInOverwrittenAssets ? styles.container : styles.containerFull),
                                styles.desc
                            )
                        }
                    >
                        <ul className={styles.list}>
                            {urls.map((url, idx) => <li key={idx}>{url}</li>)}
                        </ul>
                    </div>
                </Scrollbar>

                <VerticalSpacer y={15} />
                {contents}
            </WarningDialog>
        );
    }
};

const dialog = getCenteredDialogConfig({
    width: styles.DialogWidth,
    height: (APP_STANDARD_ERROR_DIALOG_HEIGHT + 125), // To support - de
    component: OverwriteAssets
});

export default {
    ...dialog,
    updateConfFn: (config, dimensions, props) => {
        return { ...config, props: { ...config.props, ...props } };
    }
};
