import type { FeaturedProductsViewProps } from '../flowTypes';
import { getWidgetId } from '../utils';
import { BODY_BACKGROUND_CLASS_NAME } from '../../../Preview/View/constants';
import { ViewDetailsButtonPostions } from '../constants';

const getHTMLCode = (viewProps: FeaturedProductsViewProps) => {
    const {
        component: {
            id,
            labelPosition,
            products,
            imageRatio,
            cropImages,
            productsPerRow,
            productListStyle,
            productsPerRowInMobile,
            spacingItemsInMobile,
            viewDetailsShowButton,
            viewDetailsButtonText,
            viewDetailsButtonPosition,
            isSingleProduct,
            enableBuyNowButton,
            buyNowButtonText,
        },
        shopComponentExists,
        linkClassName,
        buttonClassname,
        viewDetailsButtonClassname,
        themeSettingsData,
        backgroundColor,
        buyNowButtonClassName,
    } = viewProps;

    const viewDetailsButtonPositionValue: string = !viewDetailsButtonPosition ?
        ViewDetailsButtonPostions.BELOW.value : viewDetailsButtonPosition;
    const { autoColorMode } = themeSettingsData;

    const params2 = {
        imageRatio,
        cropImages,
        productsPerRow,
        productListStyle,
        labelPosition,
        productsPerRowInMobile,
        spacingItemsInMobile,
        shopComponentExists,
        linkClassName,
        bodyBackground: BODY_BACKGROUND_CLASS_NAME,
        buttonClassname,
        viewDetailsShowButton,
        viewDetailsButtonText,
        viewDetailsButtonClassname,
        viewDetailsButtonPosition: viewDetailsButtonPositionValue,
        isSingleProduct,
        autoColorMode,
        backgroundColor,
        enableBuyNowButton,
        buyNowButtonClassName,
        buyNowButtonText,
    };

    const htmlCode: string = `
    <!--#begin code ${id}-->
    <onecom-featured-products
        id="${getWidgetId(id)}"
        class="onecom-featured-products"
        products="${products}"
        params2='${JSON.stringify(params2)}'
    />
    <!--#end code ${id}-->
    `;
    return htmlCode;
};

export default getHTMLCode;
