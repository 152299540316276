import CALL_API from "../../../redux/middleware/api/CALL_API";
import {
    PUT_NEW_TEMPLATE_REQUEST,
    PUT_NEW_TEMPLATE_SUCCESS,
    PUT_NEW_TEMPLATE_FAILURE
} from "../actionTypes";
import type { ApiAction } from "../../../redux/middleware/api/flowTypes";

export default (template: any): ApiAction => ({
    [CALL_API]: {
        types: [
            PUT_NEW_TEMPLATE_REQUEST,
            PUT_NEW_TEMPLATE_SUCCESS,
            PUT_NEW_TEMPLATE_FAILURE
        ],
        endpoint: 'putNewTemplate',
        endpointParams: template
    }
});
