import { produce } from 'immer';

import type { Reducer, ReducerConfig } from "../common/types";

export const combineReducers = <S extends object>(
    reducersConfig: {[key: string]: ReducerConfig},
    initialState: S = {} as S,
): Reducer<S> => {
    const
        childReducers: {[key: string]: Reducer} = {},
        globalReducers: Reducer[] = [];

    Object.keys(reducersConfig).forEach((key) => {
        const { reducer, global } = reducersConfig[key];
        if (global) {
            globalReducers.push(reducer);
        } else {
            childReducers[key] = reducer;
        }
    });

    return (state: S = initialState, action: Action<S>) => {
        // eslint-disable-next-line consistent-return
        return produce(state, (draft) => {
            // eslint-disable-next-line no-restricted-syntax
            for (let globalReducer of globalReducers) {
                const afterGlobalReducerState = globalReducer(state, action);

                if (afterGlobalReducerState) {
                    return afterGlobalReducerState;
                }
            }

            Object.keys(childReducers).forEach(key => {
                const reducer = childReducers[key];
                draft[key] = reducer(draft[key], action);
            });
        }) as S;
    };
};
