import { getSelectedTemplateAction } from "../../../../../redux/middleware/api/commonApiActions";
import {
    GET_SELECTED_TEMPLATE_REQUEST,
    GET_SELECTED_TEMPLATE_SUCCESS,
    GET_SELECTED_TEMPLATE_FAILED,
    WIZARD_STEP_CHANGED
} from "../actionTypes";

const stepChangeAction = (payload) => ({
    type: WIZARD_STEP_CHANGED,
    payload
});

const fetchSelectedTemplateAction = (params) => getSelectedTemplateAction({
    types: [
        GET_SELECTED_TEMPLATE_REQUEST,
        GET_SELECTED_TEMPLATE_SUCCESS,
        GET_SELECTED_TEMPLATE_FAILED
    ],
    endpointParams: params
});

export {
    stepChangeAction,
    fetchSelectedTemplateAction
};
