export const
    CHANGE_SCOPE = 'CHANGE_SCOPE',
    PROPERTY_CHANGE = 'PROPERTY_CHANGE',
    MOVED_BY_MOUSE = 'MOVED_BY_MOUSE',
    MOVED_BY_KEYBOARD = 'MOVED_BY_KEYBOARD',
    SECTION_PANEL_MOVE_SECTION_UP = 'SECTION_PANEL_MOVE_SECTION_UP',
    SECTION_PANEL_MOVE_SECTION_DOWN = 'SECTION_PANEL_MOVE_SECTION_DOWN',
    SHIFTBAR = 'SHIFTBAR',
    RESIZED_BY_MOUSE = 'RESIZED_BY_MOUSE',
    RESIZED_FROM_PROP_PANEL = 'RESIZED_FROM_PROP_PANEL',
    SHIFTDOWN_DURING_TYPING = 'SHIFTDOWN_DURING_TYPING',
    COMPONENT_HEIGHT_CHANGE_DURING_TYPING = 'COMPONENT_HEIGHT_CHANGE_DURING_TYPING',
    COPY = 'COPY',
    PASTE = 'PASTE',
    MOVED_TO_TEMPLATE_OR_PAGE = 'MOVED_TO_TEMPLATE_OR_PAGE',
    COMPONENT_REDUCER = 'COMPONENT_REDUCER',
    IMPLICIT_WRAP_UPDATE = 'IMPLICIT_WRAP_UPDATE',
    COMPONENT_REDUCER_AND_DEPS = 'COMPONENT_REDUCER_AND_DEPS',
    COMPONENT_DEPS = 'COMPONENT_DEPS',
    DELETED = 'DELETED',
    RELATIONS_UPDATED = 'RELATIONS_UPDATED',
    ORDER_CHANGED = 'ORDER_CHANGED',
    COMPONENT_ADDED = 'COMPONENT_ADDED',
    ADJUSTMENT_DATA_APPLIED = 'ADJUSTMENT_DATA_APPLIED',
    ADJUSTMENT_DATA_APPLIED_AFTER_COMPONENT_ADD = 'ADJUSTMENT_DATA_APPLIED_AFTER_COMPONENT_ADD',
    MOUSE_DOWN_ON_HANDLE = 'MOUSE_DOWN_ON_HANDLE',
    FULL_WIDTH_CHANGE_DUE_TO_WINDOW_RESIZE = 'FULL_WIDTH_CHANGE_DUE_TO_WINDOW_RESIZE',
    REPLACE_MISSING_ASSETS = 'REPLACE_MISSING_ASSETS',
    DELETE_MISSING_ASSETS = 'DELETE_MISSING_ASSETS',
    SYNC_RELATIONS = 'SYNC_RELATIONS',
    CHANGE_WRAP_POSITION = 'CHANGE_WRAP_POSITION',
    SWITCHED_TO_MOBILE_EDITOR = 'SWITCHED_TO_MOBILE_EDITOR',
    SWITCHED_TO_PREVIEW = 'SWITCHED_TO_PREVIEW',
    UPDATE_ASSET_URLS = 'UPDATE_ASSET_URLS',
    SET_SELECTED_COMPONENTS = 'SET_SELECTED_COMPONENTS',
    FOOTER_HEIGHT_CHANGED = 'FOOTER_HEIGHT_CHANGED',
    SECTION_HEIGHT_CHANGED = 'SECTION_HEIGHT_CHANGED',
    MOVED_TO_TEMPLATE = 'MOVED_TO_TEMPLATE',
    PROPERTY_CHANGE_ON_HIDDEN_CMP_HOVER = 'PROPERTY_CHANGE_ON_HIDDEN_CMP_HOVER',
    FIXED_OVERLAP_WITH_SECTION = 'FIXED_OVERLAP_WITH_SECTION',
    SELECT_UPDATE_HOVER_BOX_STATE = 'SELECT_UPDATE_HOVER_BOX_STATE',
    ACTIVATE_MODERN_HEADER = 'ACTIVATE_MODERN_HEADER',
    ACTIVATE_MODERN_FOOTER = 'ACTIVATE_MODERN_FOOTER',
    DEACTIVATE_MODERN_HEADER = 'DEACTIVATE_MODERN_HEADER',
    DEACTIVATE_MODERN_FOOTER = 'DEACTIVATE_MODERN_FOOTER',
    SECTION_OPACITY_CHANGED = 'SECTION_OPACITY_CHANGED',
    UPGRADE_MODERN_HEADER_FOOTER = 'UPGRADE_MODERN_HEADER_FOOTER',
    MHF_COMPONENTS_TOGGLE = 'MHF_COMPONENTS_TOGGLE',
    REMOVE_WEBSHOP_MHF_DATA = 'REMOVE_WEBSHOP_MHF_DATA';
