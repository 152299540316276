import { put } from 'redux-saga/effects'; // eslint-disable-line node/file-extension-in-import
import { DataSite } from "../../../../../dal/model";
import type { SectionLinkFormResult } from "../../sectionLinkForm/flowTypes";
import { preparePersistentModel } from "../../../../../dal/model/utils";
import { makeSectionLink } from "../../sectionLinkForm/utils";
import updateSiteDataSaga from "../../saga/updateSiteDataSaga";
import { closePersistentDialog } from "../../../App/actionCreators";
import { insertPageToSiteData } from "../../utils";
import { receiveOnly } from '../../../../epics/makeCondition';
import makeResolveWhenFulfilledSaga from "../../../../epics/makeResolveWhenFulfilledSaga";
import { siteDataReceiveOnlyExternalValueActionType } from '../../../App/epics/siteData/valueActionType';
import currentPageIdValueActionType from '../../../App/epics/currentPageId/valueActionType';
import { NEW_PAGE_DIALOG_OPEN, NEW_SECTION_LINK_ADD } from "../actions";
import type { NewPageDialogPayload } from '../flowTypes';

const addSectionLinkSagaWorker = function* (
    site: DataSite,
    currentPageId: string,
    newPageDialogPayload: NewPageDialogPayload,
    input: SectionLinkFormResult
): Generator<any, void, any> {
    const
        newSectionLink = makeSectionLink(input, site, {}),
        selectedPageRefParent = site.getPageRefByPageId(currentPageId),
        selectedPageId = newPageDialogPayload?.selectedPage?.pageId,
        parentPageId = selectedPageId || site.getPageRefParentPageId(selectedPageRefParent.id),
        newSite = preparePersistentModel(insertPageToSiteData(newSectionLink, site, parentPageId));
    yield* updateSiteDataSaga({ site: newSite });

    yield put(closePersistentDialog());
};

const listener = makeResolveWhenFulfilledSaga(
    siteDataReceiveOnlyExternalValueActionType,
    receiveOnly(currentPageIdValueActionType),
    NEW_PAGE_DIALOG_OPEN,
    NEW_SECTION_LINK_ADD,
    addSectionLinkSagaWorker
);

export {
    listener as default,
    addSectionLinkSagaWorker
};
