import CALL_API from "../../../redux/middleware/api/CALL_API";
import {
    COPY_REPOSITORY_TEMPLATE_REQUEST,
    COPY_REPOSITORY_TEMPLATE_SUCCESS,
    COPY_REPOSITORY_TEMPLATE_FAILURE
} from "../actionTypes";
import type { ApiAction } from "../../../redux/middleware/api/flowTypes";

export default (designAccountName: string, templateIds: Array<string>): ApiAction => ({
// export default (templateId: string): ApiAction => ({
    [CALL_API]: {
        types: [
            COPY_REPOSITORY_TEMPLATE_REQUEST,
            COPY_REPOSITORY_TEMPLATE_SUCCESS,
            COPY_REPOSITORY_TEMPLATE_FAILURE
        ],
        endpoint: 'copyRepositoryTemplate',
        endpointParams: {
            designAccountName,
            templateIds
        }
    }
});
