/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useIntl } from '../../../../../../../view/intl/index';

import { FIELD_LABELS, VALIDATION_FIELDS } from "../constants";

import { ValidateInput } from '../Components/ValidateInput';

import { selectErrorFields } from '../../../../epics/AITextEpic/selectors';

type Props = {
    gmbKey: string,
    onChangeAction: Function
}

export const AboutField = ({ onChangeAction, gmbKey = '' }: Props) => {
    const intl = useIntl();
    const errorFields = useSelector(selectErrorFields);

    const fieldKey = FIELD_LABELS.gmbKey;
    const isInvalidField = errorFields.includes(fieldKey);

    const { max: maxLength, min: minLength } = VALIDATION_FIELDS[fieldKey];
    const [isLong, setIsLong] = useState(
        gmbKey?.trim().length >= minLength && gmbKey?.trim().length > maxLength
    );

    const onChange = (key, value, errorflag) => {
        setIsLong(errorflag);
        onChangeAction(key, value);
    };

    return (
        <ValidateInput
            fieldKey={fieldKey}
            fieldLabel={intl.msgJoint("msg: component.text.createAIText.dialog.generalPreferences.about.label {What is your website about?}")}
            placeholder="msg: component.text.createAIText.dialog.generalPreferences.about.placeholder {e.g. café}"
            fieldValue={gmbKey}
            errorFields={errorFields}
            isInvalid={isInvalidField || isLong}
            onChange={onChange}
            validationMsg={isLong ?
                intl.msgJoint("msg: common.error.longInput {The entered text is too long.}") :
                intl.msgJoint("msg: common.error.requiredField {This field is required.}")}
            dataTestId="ai-text-about-field"
        />);
};
