import React from "react";

import stepStyles from "../steps.css";

import { useIntl } from "../../../../../../view/intl";

type Props = {
    message: string,
    counter?: {
        limit: number,
        current: number
    }
}

export const FieldError = ({ message, counter }: Props) => {
    const intl = useIntl();

    return (
        <div className={stepStyles.stepFieldErrorContainer}>
            <div>
                {intl.msgJoint(message)}
            </div>
            {
                counter && (
                    <div>
                        {`${counter?.current}/${counter.limit}`}
                    </div>
                )
            }
        </div>
    );
};
