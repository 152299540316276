import { withSelector, receiveOnly } from "../../../../epics/makeCondition";
import subscriptionDataVat from "./valueActionType";

const SubscriptionTypeSelector = withSelector(subscriptionDataVat, s => s.subscriptionType),
    ROSubscriptionTypeSelector = receiveOnly(subscriptionDataVat, s => s.subscriptionType);

export {
    SubscriptionTypeSelector,
    ROSubscriptionTypeSelector
};

