import type { DocumentComponent } from "../flowTypes";
import Resource from "../../../../redux/modules/children/fileChooser/Resource";
import { googleDocViewerUrl } from "./googleDocViewerUrl";

export const documentComponentStateToViewerUrl = (component: DocumentComponent, locale?: string): string => {
    const
        { settings: { src } } = component,
        url = Resource.isWebspaceUrl(src)
            ? Resource.fromWebspaceUrl(src).getFullUrl()
            : src;

    return googleDocViewerUrl({ url, lang: locale });
};
