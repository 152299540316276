import React, { useState } from "react";
import Scrollbar from '../../../view/common/Scrollbar';

import LoadingIndicator from '../../../view/common/LoadingIndicator/index';
import Icons from '../../../view/Icons/index';
import { Msg } from "../../../view/intl/index";

import { getPremiumPackageNameFromMetadata } from '../../ComponentTierManager/utils';
import { NoMatchModal } from "./NoMatchModal";
import { TemplateOverlay } from "./TemplateOverlay";

import { TemplateCategory } from '../../oneweb/Template/constants';
import { TEMPLATE_FILTER } from "../constants";

import styles from './TemplateSelector.css';
import { isOnlineShopCmpsNotAllowedBySubscriptionType } from "./utils";

export const TemplatesList = (props) => {
    const {
        // width,
        selectorListState: {
            filter = TEMPLATE_FILTER.all,
            searchText = '',
        },
        onePageDesignMap = {},
        filteredDesigns,
        subscriptionData,
        themeSettings,
        previewTemplate,
        subscriptionType,
        onScroll
    } = props;

    const [timeStamp] = useState(Date.now());

    let listOfDesigns = filteredDesigns || [];
    const filterOutOnlineShop = isOnlineShopCmpsNotAllowedBySubscriptionType();

    if (filterOutOnlineShop) {
        listOfDesigns = listOfDesigns.filter(function (design) {
            const hasOnlineStore = design.categories.indexOf(TemplateCategory.ONLINE_STORE) >= 0;
            return !(hasOnlineStore);
        });
    }

    const premiumPackageNameFromMetadata = getPremiumPackageNameFromMetadata(subscriptionData.metadata);

    return (
        <div className={styles.mainBody}>
            <Scrollbar height="100%" onScroll={!!listOfDesigns.length && onScroll} theme={{ trackVertical: styles.trackVertical }}>
                <div className={styles.templateSelectorListWrapper}>
                    {
                        listOfDesigns.map((design, index) => (
                            <div className={styles.template} key={`${filter}-${design.uuidOfHomePage}-${index}`}>
                                <div className={styles.templateImage}>
                                    <img src={design.asset && design.asset.name ?
                                        '/templateScreenshots/' + design.asset.name.split(".")[0] + '-760x490.jpg' :
                                        '/screenshots/760x490/' + design.uuidOfHomePage + '.jpg?auto=false'}
                                    />
                                    <LoadingIndicator className={styles.imgLoader} />
                                </div>
                                {
                                    design.isPremium &&
                                    <React.Fragment>
                                        <div className={styles.premium} />
                                        <div className={styles.premiumContent}>
                                            <Icons.PREMIUM_UPGRADE_LARGE />
                                            { premiumPackageNameFromMetadata || <Msg k="common.premium">Premium</Msg> }
                                        </div>
                                    </React.Fragment>
                                }
                                <TemplateOverlay
                                    design={design}
                                    onePageDesign={onePageDesignMap[design.accountName]}
                                    themeSettings={themeSettings}
                                    previewTemplate={previewTemplate}
                                    subscriptionType={subscriptionType}
                                    timeStamp={timeStamp}
                                />
                            </div>
                        ))
                    }
                </div>
                {
                    !listOfDesigns.length ? <NoMatchModal searchText={searchText} /> : <div />
                }
            </Scrollbar>
        </div>
    );
};
