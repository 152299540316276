import React from "react";
import { ThemesAccordion } from "../../ThemesAccordion/ThemesAccordion";
import { ColorTheme } from "./Components/ColorTheme";
import * as styles from "../AutoColorLeftPanel.css";
import { TURN_ON_EDIT_MODE, SET_SELECTED_PALETTE } from "../actionTypes";
import { CurrentThemeColors } from "./Components/CurrentThemeColors";
import { Intl } from "../../../view/intl/index";
import type { ThemeColorDataType } from "../../ThemeGlobalData/flowTypes";
import { FullPaletteHsl } from "../../ThemesAccordion/flowTypes";

type PopularPaletteSelectionProps = {
    themeColors: ThemeColorDataType;
    intl: Intl;
    dispatch: Dispatch;
};
export const PopularPaletteSelection = (props: PopularPaletteSelectionProps) => {
    const { dispatch, themeColors = {}, intl } = props;
    const { mainColor, accentColor, whiteColor, blackColor } = themeColors as ThemeColorDataType;
    const palette: FullPaletteHsl = [accentColor, mainColor, whiteColor, blackColor];
    return (
        <div
            style={{
                marginLeft: "25px"
            }}
        >
            <ColorTheme currentAutoColorMode intl={intl} dispatch={dispatch} />
            <div>
                <CurrentThemeColors themeColors={palette} turnOnEditModeActionType={TURN_ON_EDIT_MODE} />
            </div>
            <div className={styles.fullWidthSeparator} />
            <div className={styles.popularAccordionSection}>
                <ThemesAccordion
                    currentPalette={palette}
                    onPaletteSelected={selectedPalette => {
                        dispatch({
                            type: SET_SELECTED_PALETTE,
                            payload: selectedPalette
                        });
                    }}
                />
            </div>
        </div>
    );
};
