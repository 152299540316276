import { $Diff } from "utility-types";
import React from 'react';
import { CornerPopupCom } from "@sepo27/react-redux-lib";
import cx from 'classnames';
import styles from './HoverBoxPreviewCom.css';
import type { ReactElementRef } from "../../../globalTypes";
import { parseIntDec } from "../../../../utils/number";
import LazyImage from '../../../view/common/LazyImage/index';
import Scrollbar from '../../../view/common/Scrollbar/index';
import { HoverBoxContext, type HoverBoxContextFn, type HoverBoxContextValue } from "./HoverBoxContext";

const
    TRANSITION_DURATION = parseIntDec(styles.transitionDuration),
    SHOW_TIMEOUT = 300,
    HIDE_TIMEOUT = SHOW_TIMEOUT;

export type RenderHoverBoxPreviewParams = {
    src: string,
    shouldLoad: boolean,
};

export type RenderHoverBoxPreview = (e: RenderHoverBoxPreviewParams) => React.ReactNode;

const defaultRenderPreview = ({ src, shouldLoad }: RenderHoverBoxPreviewParams) => (
    <Scrollbar height="100%">
        <LazyImage
            src={src}
            shouldLoad={shouldLoad}
            className={styles.img}
        />
    </Scrollbar>

);

type Props = {
    targetRef: ReactElementRef<any>,
    src: string,
    onPreviewOver: HoverBoxContextFn,
    onPreviewOut: HoverBoxContextFn,
    className?: string,
    renderPreview?: RenderHoverBoxPreview,
};

type State = {
    show: boolean,
    shouldImageLoad: boolean,
    over: boolean,
};

class HoverBoxPreviewCom extends React.Component<Props, State> {
    static defaultProps = {
        renderPreview: defaultRenderPreview,
    };

    state: State;
    showTimer: undefined | NodeJS.Timeout;
    hideTimer: undefined | NodeJS.Timeout;

    constructor() {
        // @ts-ignore
        super();

        this.state = {
            show: false,
            shouldImageLoad: false,
            over: false
        };
    }

    onTargetMouseEnter = () => {
        this.showTimer = setTimeout(() => {
            if (!this.showTimer) return;
            this.clearShowTimer();

            this.setState({ show: true, shouldImageLoad: true });
        }, SHOW_TIMEOUT);
    }

    onTargetMouseLeave = () => {
        if (this.state.over) return;
        else if (this.showTimer) {
            this.clearShowTimer();
            return;
        }

        this.hideTimer = setTimeout(() => {
            if (!this.hideTimer) return;
            this.clearHideTimer();

            this.setState({ show: false });
        }, HIDE_TIMEOUT);
    }

    onPreviewMouseEnter = () => {
        this.clearHideTimer();
        this.setState({ over: true });
        this.props.onPreviewOver();
    }

    onPreviewMouseLeave = () => {
        this.setState({ show: false, over: false });
        this.props.onPreviewOut();
    }

    clearShowTimer() {
        this.showTimer = undefined;
        clearTimeout(this.showTimer);
    }

    clearHideTimer() {
        this.hideTimer = undefined;
        clearTimeout(this.hideTimer);
    }

    componentDidMount() {
        const
            { targetRef } = this.props,

            targetEl = targetRef && targetRef.current;

        if (!targetEl) {
            throw new Error('Missing target ref');
        }

        targetEl.addEventListener('mouseenter', this.onTargetMouseEnter);
        targetEl.addEventListener('mouseleave', this.onTargetMouseLeave);
    }

    render() {
        const
            { targetRef, src, className, renderPreview } = this.props,
            { show, shouldImageLoad } = this.state,
            theme = {
                container: cx(styles.container, className),
                show: styles.show
            };

        return (
            <CornerPopupCom
                targetRef={targetRef}
                show={show}
                theme={theme}
                removeTimeout={TRANSITION_DURATION}
                onMouseEnter={this.onPreviewMouseEnter}
                onMouseLeave={this.onPreviewMouseLeave}
            >
                { /* $FlowFixMe: doesn't understand defaultProps */ }
                {renderPreview?.({ src, shouldLoad: shouldImageLoad })}
            </CornerPopupCom>
        );
    }
}

type WrapperProps = $Diff<Props, HoverBoxContextValue>;

const HoverBoxPreviewWrapperCom = (props: WrapperProps) => (
    <HoverBoxContext.Consumer>
        {(context: HoverBoxContextValue) => (
            <HoverBoxPreviewCom
                {...props}
                {...context}
            />
        )}
    </HoverBoxContext.Consumer>
);

export { HoverBoxPreviewWrapperCom as HoverBoxPreviewCom };
