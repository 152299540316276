import { SAVE_SITE_DATA } from "../../../App/epics/siteData/actionTypes";
import type { AppStore } from "../../../../redux/modules/flowTypes";
import {
    setPageTreeLoadingAction,
    unsetPageTreeLoadingAction
} from "./actions";
import {
    SITE_DATA_UPDATE_SUCCESS,
    SITE_DATA_UPDATE_FAILURE,
    SITE_PAGES_LOAD_REQUEST,
    SITE_PAGES_LOAD_SUCCESS,
    SITE_PAGES_LOAD_FAILURE,
} from "../../../App/actionTypes";
import { TOP_BAR_OPEN_POPUP } from "../../actionTypes";
import { Popups } from "../../constants";
import loadSitePagesAction from "../../../App/actionCreators/loadSitePages";
import isNeedToUpdatedPageRefsTemplateId from "../../../App/epics/siteData/utils/isNeedToUpdatedPageRefsTemplateId";

const IS_LOADING_DELAY = 100;

let
    timer: ReturnType<typeof setTimeout> | null = null,
    isLoading = false;

export const topBarTreeStateMiddleware = (store: AppStore) => (next: Dispatch) => (action: AnyAction) => {
    switch (action.type) {
        case SAVE_SITE_DATA: {
            timer = setTimeout(() => {
                isLoading = true;
                store.dispatch(setPageTreeLoadingAction());
                timer = null;
            }, IS_LOADING_DELAY);
            break;
        }

        case SITE_DATA_UPDATE_SUCCESS:
        case SITE_DATA_UPDATE_FAILURE: {
            if (timer) {
                clearTimeout(timer);
                timer = null;
            }
            if (isLoading) {
                isLoading = false;
                store.dispatch(unsetPageTreeLoadingAction());
            }
            break;
        }

        case TOP_BAR_OPEN_POPUP:
            if (action.payload.id === Popups.PAGES) {
                const siteData = store.getState().siteMap;
                if (isNeedToUpdatedPageRefsTemplateId(siteData)) {
                    store.dispatch(loadSitePagesAction(true));
                }
            }
            break;

        case SITE_PAGES_LOAD_REQUEST:
            store.dispatch(setPageTreeLoadingAction());
            break;

        case SITE_PAGES_LOAD_SUCCESS:
        case SITE_PAGES_LOAD_FAILURE:
            store.dispatch(unsetPageTreeLoadingAction());
            break;

        default:
            break;
    }

    return next(action);
};
