import makeEpic from '../../../epics/makeEpic';
import valueActionType from './valueActionType';
import { WorkspaceGTMUpdaters } from "./updaters/workspace/updater";
import type { GoogleTagManagerEpicStateType } from "../flowTypes";
import type { Epic } from "../../../epics/flowTypes";
import { DashboardGTMUpdaters } from "./updaters/dashboard/updater";
import { PageViewsGTMUpdaters } from "./updaters/pageviews";
import { GoalsGTMUpdaters } from "./updaters/goals";

export const googleTagMangerEpic: Epic<GoogleTagManagerEpicStateType, void, string> = makeEpic({
    defaultState: null,
    valueActionType,
    updaters: [
        ...WorkspaceGTMUpdaters,
        ...DashboardGTMUpdaters,
        ...PageViewsGTMUpdaters,
        ...GoalsGTMUpdaters
    ]
});
