import { DataLinkPage, DataSite } from "../../../../../dal/model";
import type { LinkPageFormResult } from "../../linkPageForm/flowTypes";
import { makeLinkPageUrl } from "../../linkPageForm/utils";
import applyPatchToSitePage from "./applyPatchToSitePage";

export default (page: DataLinkPage, input: LinkPageFormResult, site: DataSite) => {
    const
        { linkData: { link, openInNewWindow }, name, isHidden } = input,
        { url, linkId } = makeLinkPageUrl(link, site),
        patch = {
            name,
            url,
            hidden: isHidden,
            linkId,
            target: openInNewWindow ? '_blank' : '_self'
        };
    applyPatchToSitePage(page, patch);
};

