import makeEpic from '../../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import {
    PRODUCT_WIDGET_COMPONENT_DROPPED,
    PRODUCT_WIDGET_SETUP_PRODUCT_TOOLTIP_POSITION_UPDATED,
    PRODUCT_WIDGET_SHOW_SETUP_PRODUCT_TOOLTIP
} from '../../actionTypes';
import {
    SHOW_TOOLTIP_FOR_SINGLE_PRODUCT_COMPONENT
} from "../../../../Tooltip/actionTypes";
import currentPageIdValueActionType from "../../../../App/epics/currentPageId/valueActionType";
import { COMPONENT_ADDED, PASTE, DELETED } from "../../../../Workspace/epics/componentsEval/updateReasons";
import { REDO, UNDO, UNDO_INITIAL_STATE } from "../../../../../epics/undoManager/updateReasons";
import { receiveOnly, whenWithSelector } from "../../../../../epics/makeCondition";
import { ComponentsEvalValueActionType } from "../../../../Workspace/epics/componentsEval/valueActionType";
import {
    incrementSetupProductTooltipViewCount,
    mustShowSetupProductTooltip,
    getProductWidgetComponentsCount,
    decrementSetupProductTooltipViewCount
} from '../../utils';

const defaultState: {
    tooltipPayload: Record<string, any> | null,
    singleProductWidgetAdded: boolean,
    productWidgetsCount: number
} = {
    tooltipPayload: null,
    singleProductWidgetAdded: false,
    productWidgetsCount: 0
};

const addReasonsToCheck = [
    COMPONENT_ADDED,
    PASTE,
    REDO,
];
const componentsMapSelectorWhenAdded = whenWithSelector(
    ComponentsEvalValueActionType,
    a => a.payload.state.componentsMap,
    a => addReasonsToCheck.indexOf(a.epicUpdateReason) !== -1
);

const removeReasonsToCheck = [
    DELETED,
    UNDO,
];
const componentsMapSelectorWhenRemoved = whenWithSelector(
    ComponentsEvalValueActionType,
    a => a.payload.state.componentsMap,
    a => removeReasonsToCheck.indexOf(a.epicUpdateReason) !== -1
);

const initialReasonsToCheck = [
    UNDO_INITIAL_STATE
];
const componentsMapSelectorInitial = whenWithSelector(
    ComponentsEvalValueActionType,
    a => a.payload.state.componentsMap,
    a => initialReasonsToCheck.indexOf(a.epicUpdateReason) !== -1
);

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                PRODUCT_WIDGET_SETUP_PRODUCT_TOOLTIP_POSITION_UPDATED
            ],
            reducer: ({ state, values: [tooltipPayload] }) => {
                return {
                    state: { ...state, tooltipPayload },
                    actionToDispatch: {
                        type: PRODUCT_WIDGET_SHOW_SETUP_PRODUCT_TOOLTIP,
                    }
                };
            }
        },
        {
            conditions: [
                PRODUCT_WIDGET_SHOW_SETUP_PRODUCT_TOOLTIP,
            ],
            reducer: ({ state, dispatchAsync }) => {
                if (mustShowSetupProductTooltip() &&
                    state && state.singleProductWidgetAdded && state.tooltipPayload) {
                    const actionToDispatch: { type: string, payload: Record<string, any> } = {
                        type: SHOW_TOOLTIP_FOR_SINGLE_PRODUCT_COMPONENT,
                        payload: state.tooltipPayload
                    };
                    setTimeout(() => {
                                                dispatchAsync(actionToDispatch);
                    }, 100); // render the tool tip after 100ms
                    return {
                        state: { ...state, singleProductWidgetAdded: false, tooltipPayload: null },
                    };
                }
                return { state };
            }
        },
        {
            keepFullActions: true,
            conditions: [
                receiveOnly(currentPageIdValueActionType),
                componentsMapSelectorInitial
            ],
            reducer: ({ values: [, componentsMap], state }) => {
                const cmpCount = getProductWidgetComponentsCount(componentsMap);
                return { state: { ...state, productWidgetsCount: cmpCount } };
            }
        },
        {
            keepFullActions: true,
            conditions: [
                receiveOnly(currentPageIdValueActionType),
                componentsMapSelectorWhenAdded
            ],
            reducer: ({ values: [, componentsMap], state }) => {
                const existingCount = state.productWidgetsCount;
                const cmpCount = getProductWidgetComponentsCount(componentsMap);
                if (cmpCount > existingCount) {
                    incrementSetupProductTooltipViewCount();
                    return {
                        state: {
                            ...state,
                            productWidgetsCount: cmpCount,
                            singleProductWidgetAdded: true
                        },
                        actionToDispatch: { type: PRODUCT_WIDGET_COMPONENT_DROPPED }
                    };
                }
                return {
                    state: {
                        ...state,
                        productWidgetsCount: cmpCount,
                        singleProductWidgetAdded: false
                    }
                };
            }
        },
        {
            keepFullActions: true,
            conditions: [
                receiveOnly(currentPageIdValueActionType),
                componentsMapSelectorWhenRemoved
            ],
            reducer: ({ values: [, componentsMap], state }) => {
                const existingCount = state.productWidgetsCount;
                const cmpCount = getProductWidgetComponentsCount(componentsMap);
                if (cmpCount < existingCount) {
                    decrementSetupProductTooltipViewCount(existingCount - cmpCount);
                }
                return { state: {
                    ...state,
                    singleProductWidgetAdded: false,
                    tooltipPayload: null,
                    productWidgetsCount: cmpCount
                } };
            }
        },
    ]
});
