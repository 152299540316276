import { WidgetsTiktokKind } from './kind';
import { calcRenderProps } from './calcRenderProps';
import { TiktokView } from './view/index';

const WidgetsTiktokPreviewConfig = {
    kind: WidgetsTiktokKind,
    view: TiktokView,
    calcProps: ({ component }) => calcRenderProps({
        component,
        componentExtension: { isTransient: false },
    }),
};

export { WidgetsTiktokPreviewConfig };
