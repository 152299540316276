/* eslint-disable max-len */
/* eslint-disable max-classes-per-file */

import $ from 'jquery';
import Measure from 'react-measure';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import cx from 'classnames';
import React from 'react';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import * as pagesIds from '../pagesIds';
import pagesTitles from '../pagesTitles';
import { Msg, injectIntl, Intl } from '../../../../../view/intl/index';
import * as styles from './links.css';
import {
    SOCIAL_LINK_ADD_INPUT_CHANGE,
    SOCIAL_LINK_CONTEXT_MENU_BTN_CLICKED,
    SOCIAL_LINK_CONTEXT_MENU_EDIT_CLICKED,
    SOCIAL_LINK_CONTEXT_MENU_DELETE_CLICKED,
    SOCIAL_LINKS_SORT_END,
    SOCIAL_LINK_CONTEXT_MENU_SHOW_OR_HIDE_CLICKED,
    SOCIAL_ADD_LINK_WARNING_TOOLTIP_TEXT_WIDTH,
    SOCIAL_LINKS_SORT_START,
    SOCIAL_LINK_HIDDEN_ICON_CLICKED,
    SOCIAL_LINK_INPUT_FOCUSED,
    SOCIAL_LINK_INPUT_BLUR,
    SOCIAL_LINK_INPUT_BOTTOM_CENTER_POINT_AFTER_RENDER,
    SOCIAL_LINK_INPUT_PASTE,
    SOCIAL_LINK_PP_ITEM_MOUSE_OVER,
    SOCIAL_LINK_PP_ITEM_MOUSE_LEAVE,
} from '../../actionTypes';
import ContextMenuIcon from '../../../../PropertiesPanel/view/ContextMenuIcon';
import type { SocialGlobalData, SocialComponent } from '../../flowTypes';
import { socialLinksConfigsMap, socialLinksConfigs } from '../../socialLinksConfigs';
import type { SocialServiceEpicState } from '../../epics/socialServiceEpic/socialServiceEpic';
import NoMouseEventsPropagation from '../../../../../view/common/NoMouseEventsPropagation';
import { RootView } from '../../../../RootView/RootView';
import { SvgSocialHidden } from '../../svgs/SocialHidden';
import { removeUrlProtocolAndWWW, removeUrlProtocolAndWWWAndLastSlash } from '../../utils';
import { SvgSocialPlatformNotRecognizedCircle } from '../../svgs/SocialPlatformNotRecognizedCircle';
import { SvgSocialLinksDragHandlePatched } from '../../svgs/SocialLinksDragHandlePatched';
import InputField from '../../../../../view/common/Input/PropertiesInputField';
import { SocialColorSourceType, SocialStyleType } from '../../constants';

type Props = {
    dispatch: Dispatch,
    socialData: SocialGlobalData,
    service: SocialServiceEpicState,
    selectedComponent: SocialComponent,
    intl: Intl
}

const ctxBtnContainerId = 'ctxBtnContainerId';
const SortableLinkElement = injectIntl(SortableElement( // eslint-disable-line new-cap
    class Item extends React.Component<any> {
        constructor(props: any) {
            super(props);
            this.onMouseEnter = this.onMouseEnter.bind(this);
            this.onMouseLeave = this.onMouseLeave.bind(this);
        }

        onMouseEnter() {
            this.props.dispatch({ type: SOCIAL_LINK_PP_ITEM_MOUSE_OVER, payload: this.props.linkId });
        }

        onMouseLeave() {
            this.props.dispatch({ type: SOCIAL_LINK_PP_ITEM_MOUSE_LEAVE });
        }

        render() {
            const { itemIndex, SvgCmp, url, dispatch, hidden, linkId, intl, forceHoverLinkId } = this.props;

            return (
                <li
                    key={itemIndex}
                    className={cx(styles.linkContainer, { [styles.hidden]: hidden, [styles.hover]: forceHoverLinkId === linkId })}
                    onMouseEnter={this.onMouseEnter}
                    onMouseLeave={this.onMouseLeave}
                >
                    <div className={styles.dragHandleArea} />
                    <SvgSocialLinksDragHandlePatched
                        className={styles.dragHandle}
                        hover={forceHoverLinkId === linkId}
                    />
                    <div className={styles.icon}><SvgCmp /></div>
                    <div className={styles.url}>{removeUrlProtocolAndWWW(url)}</div>
                    {hidden && <div
                        className={styles.hiddenLink}
                        data-title={intl.msgJoint("msg: social.showLink {Show link}")}
                        onClick={() => dispatch({ type: SOCIAL_LINK_HIDDEN_ICON_CLICKED, payload: linkId })}
                    >
                        <SvgSocialHidden />
                    </div>}
                    {
                        <div id={ctxBtnContainerId}>
                            <ContextMenuIcon
                                onClick={({ clientX, clientY }) => dispatch({ type: SOCIAL_LINK_CONTEXT_MENU_BTN_CLICKED, payload: { clientX, clientY, linkId } })}
                            />
                        </div>
                    }
                </li>
            );
        }
    }
));

// @ts-ignore
const SortableLinksList = SortableContainer(({ dispatch, linksForComponentInEditMode, forceHoverLinkId, sortingInprogress }) => {
    return <ul>{linksForComponentInEditMode.map(({ id, kind, url, hidden }, index) => {
        return (<SortableLinkElement
            key={id}
            SvgCmp={socialLinksConfigsMap[kind].svgCmpsByColorByType[SocialColorSourceType.DARK][SocialStyleType.REGULAR]}
            url={url || socialLinksConfigsMap[kind].urlPlaceholder}
            index={index}
            itemIndex={index}
            dispatch={dispatch}
            hidden={hidden}
            linkId={id}
            forceHoverLinkId={forceHoverLinkId}
            sortingInprogress={sortingInprogress}
        />);
    })}</ul>;
});

const checkEmailAndTryAgainWarning = 'msg: social.checkEmailAndTryAgain {Check the email and try again.}';
const socialPlatmformNotSupportedWarning = 'msg: social.socialPlatmformNotSupported {Sorry, this social platform is not supported}';
const profileIdIsRequiredWarning = 'msg: social.profileIdIsRequired {Add your profile id}';

class LinksView extends React.Component<Props> {
    inputContainerRef: React.RefObject<HTMLDivElement>;
    inputRef: React.RefObject<HTMLInputElement>;
    constructor(props: Props) {
        super(props);
        this.inputContainerRef = React.createRef();
        this.inputRef = React.createRef();
    }
    render() {
        const { props } = this;
        const sortingWorkaroundKey = props.service.linksForComponentInEditMode.map(link => link.id).join('');

        const getwarningToolTipBox = () => {
            let message = socialPlatmformNotSupportedWarning;

            if (props.service.addLinkInputValue.includes('@')) {
                message = checkEmailAndTryAgainWarning;
            }

            const exactPlaceholderUrlConfigMatch = socialLinksConfigs
                .find(c => removeUrlProtocolAndWWWAndLastSlash(c.urlPlaceholder) === removeUrlProtocolAndWWWAndLastSlash(props.service.addLinkVisibleInputValue.toLowerCase()));
            if (exactPlaceholderUrlConfigMatch) {
                message = profileIdIsRequiredWarning;
            }

            return <div className={styles.box}>
                <span>{props.intl.msgJoint(message)}</span>
            </div>;
        };

        const warningLabelOpacity = props.service.inputWarningVisible ? 1 : 0;

        let addSvgIcon = <span />;
        if (props.service.addLinkPlatformKind) {
            const Svg = socialLinksConfigsMap[props.service.addLinkPlatformKind].svgCmpsByColorByType[SocialColorSourceType.DARK][SocialStyleType.REGULAR];

            addSvgIcon = <Svg width="100%" height="100%" />;
        }

        return (
            <Page halfSpacing>
                <div className={styles.linksGlobalWarning}>
                    <Msg k="social.propertiesPanel.linksGlobalWarning">
                        Your social links may appear in more than one place on your website. Edit a link here and, it will be updated everywhere.
                    </Msg>
                </div>
                <div className={styles.linksContainer} key={sortingWorkaroundKey}>
                    <SortableLinksList
                        // @ts-ignore
                        linksForComponentInEditMode={props.service.linksForComponentInEditMode}
                        forceHoverLinkId={props.service.forceHoverLinkId}
                        sortingInprogress={props.service.sortingInprogress}
                        dispatch={props.dispatch}
                        delay={50}
                        pressDelay={1}
                        onSortStart={({ index }) => {
                            props.dispatch({ type: SOCIAL_LINKS_SORT_START, payload: { index } });
                        }}
                        onSortEnd={(payload) => props.dispatch({
                            type: SOCIAL_LINKS_SORT_END,
                            payload
                        })}
                        lockToContainerEdges
                        lockAxis="y"
                        shouldCancelStart={(e) => $(e.target).parents(`#${ctxBtnContainerId}`).length > 0 || $(e.target).parents(`.${styles.hiddenLink}`).length > 0}
                    />
                </div>
                <div className={styles.addLinkInputContainer} ref={this.inputContainerRef}>
                    <div>
                        {props.service.addLinkPlatformKind ? addSvgIcon : <SvgSocialPlatformNotRecognizedCircle style={{ width: '100%', height: '100%' }} />}
                    </div>
                    <InputField
                        inputRef={this.inputRef}
                        isInvalid={props.service.unrecognizedLink}
                        style={{ paddingLeft: 35 }}
                        value={props.service.addLinkInputValue}
                        onChange={payload => {
                            props.dispatch({
                                type: SOCIAL_LINK_ADD_INPUT_CHANGE,
                                payload,
                            });
                        }}
                        onPaste={e => {
                            e.preventDefault();
                            props.dispatch({
                                type: SOCIAL_LINK_INPUT_PASTE,
                                payload: e.clipboardData.getData('Text'),
                            });
                        }}
                        placeholder="msg: component.social.propPanel.AddLinkOrEmail {Add social link or email}"
                        onFocus={() => props.dispatch({ type: SOCIAL_LINK_INPUT_FOCUSED })}
                        onBlur={() => props.dispatch({ type: SOCIAL_LINK_INPUT_BLUR })}
                        autocompleteValue={props.service.addLinkVisibleInputValue}
                    />
                </div>
                <RootView>
                    {
                        props.service.ctx && <div className={styles.backdrop} />
                    }
                    {
                        props.service.ctx && (
                            <div
                                className={styles.linkCtxMenu}
                                style={props.service.ctx && props.service.ctx.position}
                                {...NoMouseEventsPropagation}
                            >
                                <div onClick={() => props.dispatch({ type: SOCIAL_LINK_CONTEXT_MENU_EDIT_CLICKED })}>
                                    <Msg k="common.edit">Edit</Msg>
                                </div>
                                <div onClick={() => props.dispatch({ type: SOCIAL_LINK_CONTEXT_MENU_DELETE_CLICKED })}>
                                    <Msg k="common.delete">Delete</Msg>
                                </div>
                                <div onClick={() => props.dispatch({ type: SOCIAL_LINK_CONTEXT_MENU_SHOW_OR_HIDE_CLICKED })}>
                                    {props.service.linksForComponentInEditMode.find(link => link.id === (props.service.ctx && props.service.ctx.linkId) && !link.hidden) ? <Msg k="common.hide">Hide</Msg> : <Msg k="common.show">Show</Msg>}
                                </div>
                            </div>
                        )
                    }
                    <div className={styles.tooltipOffscreenRender}>
                        <Measure
                            offset
                            onResize={({ offset }) => props.dispatch({ type: SOCIAL_ADD_LINK_WARNING_TOOLTIP_TEXT_WIDTH, payload: offset.width })}
                        >
                            {
                                ({ measureRef }) => (
                                    <span ref={measureRef} style={{ display: 'inline-block', maxWidth: 205 }}>
                                        {getwarningToolTipBox()}
                                    </span>
                                )
                            }
                        </Measure>
                    </div>
                    <div
                        className={styles.editUrlInputInvalidTooltipContainer}
                        style={{
                            ...props.service.inputWarningTooltipPosition,
                            width: props.service.inputWarningTooltipWidth,
                            opacity: warningLabelOpacity
                        }}
                    >
                        <div style={{ opacity: warningLabelOpacity }}>
                            <div className={styles.arrow} />
                            {getwarningToolTipBox()}
                        </div>
                    </div>
                </RootView>
            </Page>
        );
    }
    componentDidUpdate() {
        if (this.inputContainerRef.current) {
            const { left, right, bottom } = this.inputContainerRef.current.getBoundingClientRect();
            const inputBottomCenterPoint = { left: (left + right) / 2, top: bottom };
            this.props.dispatch({
                type: SOCIAL_LINK_INPUT_BOTTOM_CENTER_POINT_AFTER_RENDER,
                payload: inputBottomCenterPoint
            });
        }
    }
}

const
    id = pagesIds.LINKS,
    title = pagesTitles[pagesIds.LINKS],
    view = injectIntl(LinksView);

export { id, view, title };
