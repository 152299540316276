import React from 'react';
import { connect } from "react-redux";

import { cellCommonMapDispatchToProps } from '../utils';
import { DemoTip } from '../../../../../demo/modules/tip/DemoTip';
import { TooltipPosition } from '../../../../components/presentational/Tooltip/constants';
import { HoverBoxDeleteBtnCom } from '../../../../components/presentational/HoverBoxControls/index';
import styles from './FileChooserGrid.css';

export const FmDeleteHoverBoxCom = connect(null, cellCommonMapDispatchToProps)((props: any) => {
    const
        { resource, deleteResource, hideDelete } = props,
        showDelete = !resource.isSystemResource() && !hideDelete;

    const onDeleteClick = (event) => {
        if (deleteResource) {
            deleteResource(resource);
        }
        event.stopPropagation();
    };

    // @ts-ignore
    return showDelete ? (<DemoTip position={TooltipPosition.BOTTOM} hideOnTargetLeave>
        <HoverBoxDeleteBtnCom title={false} className={styles.cellOverlayViewBtn} onClick={onDeleteClick} />
    </DemoTip>) : null;
});
