import React from "react";
import cx from "classnames";

import { DataSiteItem, isDataPageRefType, isLinkPage, isSectionLink } from "../../../../../dal/model/utils/dataSiteItemUtils";
import { useIntl } from "../../../../view/intl";
import { PAGE_TYPES, PageOptionsTitles } from "../constants";

import styles from './PagesTree.css';
import { DataSite } from "../../../../utils/htmlWriter/flowTypes";

type Props = {
    page: DataSiteItem,
    site: DataSite
}

export const PageTypeIcon = ({ page, site }: Props) => {
    const intl = useIntl();
    let pageType;

    if (isDataPageRefType(page.type) && site.isHomePageId(page?.pageId)) {
        pageType = PAGE_TYPES.HOME;
    } else if (isLinkPage(page)) {
        pageType = PAGE_TYPES.LINK;
    } else if (isSectionLink(page)) {
        pageType = PAGE_TYPES.SECTION_LINK;
    } else {
        pageType = PAGE_TYPES.PAGE;
    }

    const title = intl.msgJoint(PageOptionsTitles[pageType]);
    return <span
        className={cx(styles.pageTypeIcn, styles[pageType])}
        data-title-position="top"
        data-title={title}
    />;
};
