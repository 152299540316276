import React from "react";
import cx from "classnames";
import { closeDialog } from "../App/actionCreators/index";
import {
    Dialog, DialogBody, DialogTitleBox, StripTypes
} from "../../view/common/dialogs/baseDialog/index";
import styles from "./pinDialog.css";

import getCenteredDialogConfig from "../DialogManager/getCenteredDialogConfig";
import { componentPinToBottomAC, componentPinToTopAC, componentUnpinAC } from "./actionCreators/updateComponentPin";
import { selectedComponentReducer } from "../Workspace/epics/componentsEval/index";
import type { StripComponent } from "../oneweb/Strip/flowTypes";
import { injectIntl, Msg } from "../../view/intl/index";
import type { Intl } from "../../view/intl/index";
import PropertyContainer from "../../view/common/PropertyContainer/index";

type Props = {
    intl: Intl,
    dispatch: Dispatch,
    selectedComponent: StripComponent,
    componentId: string
}

const title = 'msg: dialog.pin.title {Pin settings}',
    description = 'msg: dialog.pin.description {Where should the section be pinned?}',

    dontPinHintMsg = `msg: dialog.pin.dontPinHint {Section not pinned}`,
    topHintMsg = 'msg: dialog.pin.pinTopHint {Section sticks to top of browser}',
    bottomHintMsg = 'msg: dialog.pin.pinBottomHint {Section sticks to bottom of browser}',

    dontPin = `msg: common.dontPin {Don't pin}`,
    top = 'msg: common.top {Top}',
    bottom = 'msg: common.bottom {Bottom}';

const width = 290,
    minHeight = 327;

const PinDialogComponent = ({ intl, dispatch, selectedComponent }: Props) => {
    const handlePinToTop = () => dispatch(componentPinToTopAC()),
        handlePinToBottom = () => dispatch(componentPinToBottomAC()),
        handleUnpin = () => dispatch(componentUnpinAC()),
        pin = selectedComponent.pin,

        dontPinLabel = intl.msgJoint(dontPin),
        topLabel = intl.msgJoint(top),
        bottomLabel = intl.msgJoint(bottom),

        dontPinHint = intl.msgJoint(dontPinHintMsg),
        topHint = intl.msgJoint(topHintMsg),
        bottomHint = intl.msgJoint(bottomHintMsg);

    return (
        <Dialog stripType={StripTypes.NONE} showCloseBtn={false}>
            <DialogTitleBox title={title} className={styles.header} titleClass={styles.title} />

            <DialogBody className={styles.dialogBody}>
                <PropertyContainer label={description} className={styles.positionDescription} vsHeight={5} />
                <div className={styles.pinOptions}>
                    <div
                        className={cx(styles.pinOptionWrapper, { [styles.selected]: pin > 0 })}
                        onClick={handlePinToTop}
                        data-title={topHint}
                        data-title-position="top"
                    >
                        <div className={styles.pinTop} />
                        <span className={styles.pinOptionLabel}>{topLabel}</span>
                    </div>
                    <div
                        className={cx(styles.pinOptionWrapper, { [styles.selected]: pin < 0 })}
                        onClick={handlePinToBottom}
                        data-title={bottomHint}
                        data-title-position="top"
                    >
                        <div className={styles.pinBottom} />
                        <span className={styles.pinOptionLabel}>{bottomLabel}</span>
                    </div>
                    <div
                        className={cx(styles.pinOptionWrapper, { [styles.selected]: pin === 0 })}
                        onClick={handleUnpin}
                        data-title={dontPinHint}
                        data-title-position="top"
                    >
                        <div className={styles.unpin} />
                        <span className={styles.pinOptionLabel}>{dontPinLabel}</span>
                    </div>
                </div>
                <div className={styles.footer}>
                    <div className={styles.footerHintIcon}> </div>
                    <div className={styles.footerHint}>
                        <Msg k="tip" className={styles.footerTip}>Tip:</Msg>
                        <Msg k="dialog.pin.footer.tip.msg">
                            Preview or publish your site to see this in action.</Msg>
                    </div>
                </div>
            </DialogBody>
        </Dialog>
    );
};

export default getCenteredDialogConfig({
    width,
    minHeight,
    dialogClassName: styles.dialog,
    draggableClassName: styles.draggable,
    component: injectIntl(PinDialogComponent),
    forceModal: true,
    dependsOn: {
        selectedComponent: selectedComponentReducer
    },
    onModalClickAction: closeDialog()
});
