import { types } from './constants';
import * as euh from './epicUpdateHelpers';
import * as actionTypes from "./actionTypes";
import { SAVE_GOOGLE_FONT } from '../../Fonts/actionTypes';

const epicUpdaters = [
    ...euh.googleFontEpicUpdater([
        { action: SAVE_GOOGLE_FONT, ref: types.NORMAL }
    ]),
    ...euh.getFontFamilyEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_FONT_FAMILY_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_FONT_FAMILY_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_FONT_FAMILY_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_FONT_FAMILY_CHANGED, ref: types.ALTERNATE }
    ]),
    ...euh.getFontSizeEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_FONT_SIZE_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_FONT_SIZE_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_FONT_SIZE_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_FONT_SIZE_CHANGED, ref: types.ALTERNATE }
    ]),
    ...euh.getFontBoldToggleEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_TOGGLE_BOLD, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_TOGGLE_BOLD, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_TOGGLE_BOLD, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_TOGGLE_BOLD, ref: types.ALTERNATE }
    ]),
    ...euh.getFontItalicToggleEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_TOGGLE_ITALIC, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_TOGGLE_ITALIC, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_TOGGLE_ITALIC, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_TOGGLE_ITALIC, ref: types.ALTERNATE }
    ]),
    ...euh.getFontUnderlineToggleEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_TOGGLE_UNDERLINE, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_TOGGLE_UNDERLINE, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_TOGGLE_UNDERLINE, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_TOGGLE_UNDERLINE, ref: types.ALTERNATE }
    ]),
    ...euh.getHorizontalAlignmentEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_HORIZONTAL_ALIGNMENT_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_HORIZONTAL_ALIGNMENT_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_HORIZONTAL_ALIGNMENT_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_HORIZONTAL_ALIGNMENT_CHANGED, ref: types.ALTERNATE }
    ]),
    ...euh.getVerticalAlignmentEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_VERTICAL_ALIGNMENT_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_VERTICAL_ALIGNMENT_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_VERTICAL_ALIGNMENT_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_VERTICAL_ALIGNMENT_CHANGED, ref: types.ALTERNATE }
    ]),
    ...euh.getBorderStyleEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_BORDER_STYLE_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_BORDER_STYLE_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_BORDER_STYLE_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_BORDER_STYLE_CHANGED, ref: types.ALTERNATE }
    ]),
    ...euh.getBorderColorEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_BORDER_COLOR_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_BORDER_COLOR_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_BORDER_COLOR_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_BORDER_COLOR_CHANGED, ref: types.ALTERNATE }
    ]),
    ...euh.getBorderColorOpacityEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_BORDER_COLOR_OPACITY_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_BORDER_COLOR_OPACITY_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_BORDER_COLOR_OPACITY_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_BORDER_COLOR_OPACITY_CHANGED, ref: types.ALTERNATE }
    ]),
    ...euh.getCellSpacingEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_CELL_SPACING_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_CELL_SPACING_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_CELL_SPACING_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_CELL_SPACING_CHANGED, ref: types.ALTERNATE }
    ]),
    ...euh.getTextShadowColorUpdateEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_FONT_SHADOW_COLOR_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_FONT_SHADOW_COLOR_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_FONT_SHADOW_COLOR_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_FONT_SHADOW_COLOR_CHANGED, ref: types.ALTERNATE }
    ]),
    ...euh.getTextShadowColorRemoveEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_FONT_SHADOW_COLOR_REMOVED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_FONT_SHADOW_COLOR_REMOVED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_FONT_SHADOW_COLOR_REMOVED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_FONT_SHADOW_COLOR_REMOVED, ref: types.ALTERNATE }
    ]),
    ...euh.getTextShadowBlurRadiusChangeEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_FONT_SHADOW_BLUR_RADIUS_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_FONT_SHADOW_BLUR_RADIUS_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_FONT_SHADOW_BLUR_RADIUS_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_FONT_SHADOW_BLUR_RADIUS_CHANGED, ref: types.ALTERNATE }
    ]),
    ...euh.getTextShadowHorizontalOffsetChangeEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_FONT_SHADOW_HORIZONTAL_OFFSET_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_FONT_SHADOW_HORIZONTAL_OFFSET_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_FONT_SHADOW_HORIZONTAL_OFFSET_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_FONT_SHADOW_HORIZONTAL_OFFSET_CHANGED, ref: types.ALTERNATE }
    ]),
    ...euh.getTextShadowVerticalOffsetChangeEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_FONT_SHADOW_VERTICAL_OFFSET_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_FONT_SHADOW_VERTICAL_OFFSET_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_FONT_SHADOW_VERTICAL_OFFSET_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_FONT_SHADOW_VERTICAL_OFFSET_CHANGED, ref: types.ALTERNATE }
    ]),
    ...euh.getBackgroundColorEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_BACKGROUND_COLOR_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_BACKGROUND_COLOR_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_BACKGROUND_COLOR_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_BACKGROUND_COLOR_CHANGED, ref: types.ALTERNATE }
    ]),
    ...euh.getBackgroundColorRemoveEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_BACKGROUND_COLOR_REMOVED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_BACKGROUND_COLOR_REMOVED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_BACKGROUND_COLOR_REMOVED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_BACKGROUND_COLOR_REMOVED, ref: types.ALTERNATE }
    ]),
    ...euh.getBackgroundOpacityEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_BACKGROUND_OPACITY_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_BACKGROUND_OPACITY_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_BACKGROUND_OPACITY_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_BACKGROUND_OPACITY_CHANGED, ref: types.ALTERNATE }
    ]),
    ...euh.getBackgroundGradientColorEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_BACKGROUND_GRADIENT_COLOR_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_BACKGROUND_GRADIENT_COLOR_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_BACKGROUND_GRADIENT_COLOR_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_BACKGROUND_GRADIENT_COLOR_CHANGED, ref: types.ALTERNATE }
    ]),
    ...euh.getBackgroundGradientColorRemoveEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_BACKGROUND_GRADIENT_COLOR_REMOVED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_BACKGROUND_GRADIENT_COLOR_REMOVED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_BACKGROUND_GRADIENT_COLOR_REMOVED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_BACKGROUND_GRADIENT_COLOR_REMOVED, ref: types.ALTERNATE }
    ]),
    ...euh.getBackgroundGradientDirectionUpdateEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_BACKGROUND_GRADIENT_DIRECTION_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_BACKGROUND_GRADIENT_DIRECTION_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_BACKGROUND_GRADIENT_DIRECTION_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_BACKGROUND_GRADIENT_DIRECTION_CHANGED, ref: types.ALTERNATE }
    ]),
    ...euh.getBackgroundGradientFadePointUpdateEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_BACKGROUND_GRADIENT_FADEPOINT_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_BACKGROUND_GRADIENT_FADEPOINT_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_BACKGROUND_GRADIENT_FADEPOINT_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_BACKGROUND_GRADIENT_FADEPOINT_CHANGED, ref: types.ALTERNATE }
    ]),
    ...euh.getBackgroundAssetEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_BACKGROUND_ASSET_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_BACKGROUND_ASSET_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_BACKGROUND_ASSET_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_BACKGROUND_ASSET_CHANGED, ref: types.ALTERNATE }
    ]),
    ...euh.getBackgroundAssetRemoveEpicUpdater([
        { action: actionTypes.GS_TABLE_NORMAL_BACKGROUND_ASSET_REMOVED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_BACKGROUND_ASSET_REMOVED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_BACKGROUND_ASSET_REMOVED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_BACKGROUND_ASSET_REMOVED, ref: types.ALTERNATE }
    ]),
    ...euh.getBackgroundAssetRepeatChangeEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_BACKGROUND_ASSET_REPEAT_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_BACKGROUND_ASSET_REPEAT_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_BACKGROUND_ASSET_REPEAT_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_BACKGROUND_ASSET_REPEAT_CHANGED, ref: types.ALTERNATE }
    ]),
    ...euh.getBackgroundAssetPositionChangeEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_BACKGROUND_ASSET_POSITION_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_BACKGROUND_ASSET_POSITION_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_BACKGROUND_ASSET_POSITION_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_BACKGROUND_ASSET_POSITION_CHANGED, ref: types.ALTERNATE }
    ]),
    ...euh.getBackgroundAssetSizeChangeEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_BACKGROUND_ASSET_SIZE_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_BACKGROUND_ASSET_SIZE_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_BACKGROUND_ASSET_SIZE_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_BACKGROUND_ASSET_SIZE_CHANGED, ref: types.ALTERNATE }
    ]),
    ...euh.getTextColorEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_FONT_COLOR_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_FONT_COLOR_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_FONT_COLOR_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_FONT_COLOR_CHANGED, ref: types.ALTERNATE }
    ]),
    ...euh.getTextHighlightColorEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_HIGHLIGHT_COLOR_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_HIGHLIGHT_COLOR_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_HIGHLIGHT_COLOR_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_HIGHLIGHT_COLOR_CHANGED, ref: types.ALTERNATE }
    ]),
    ...euh.getTextHighlightColorRemoveEpicUpdaters([
        { action: actionTypes.GS_TABLE_NORMAL_HIGHLIGHT_COLOR_REMOVED, ref: types.NORMAL },
        { action: actionTypes.GS_TABLE_HEADING1_HIGHLIGHT_COLOR_REMOVED, ref: types.HEADING1 },
        { action: actionTypes.GS_TABLE_HEADING2_HIGHLIGHT_COLOR_REMOVED, ref: types.HEADING2 },
        { action: actionTypes.GS_TABLE_ALTERNATE_HIGHLIGHT_COLOR_REMOVED, ref: types.ALTERNATE }
    ])
];

export {
    epicUpdaters
};
