/* eslint-disable max-len */
/* eslint-disable no-cond-assign */
import React from "react";
import { makeStyles } from '@mui/styles';

import { colorGray_3c as colorGray3c } from "../../../../../variables.css";

const useStyles = makeStyles(() => ({
    highlight: {
        color: colorGray3c,
        fontWeight: "600"
    }
}));

export const RenderCategoryOption = ({ props, label, value, indices }) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            {
                value && !indices.length ? (
                    <li {...props} key={label}>
                        {label}
                    </li>
                ) :
                    <li {...props} key={label}>
                        {
                            !indices.length && label ? label : null
                        }
                        {
                            indices.length && !indices[0].includes(0) ? <pre>{label.substring(0, indices[0][0])}</pre> : null
                        }
                        {
                            indices.map((index, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <pre className={classes.highlight}>
                                            {label.substring(index[0], index[1] + 1)}
                                        </pre>
                                        {
                                            i === indices.length - 1 ? (
                                                <pre>
                                                    {label.substring(index[1] + 1)}
                                                </pre>
                                            ) : (
                                                <pre>
                                                    {label.substring(index[1] + 1, indices[i + 1][0])}
                                                </pre>
                                            )
                                        }
                                    </React.Fragment>
                                );
                            })
                        }
                    </li>
            }
        </React.Fragment>
    );
};
