import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material/styles';

export const NORMAL_SIDE_PADDING = 60;
export const SMALL_DEVICE_SIDE_PADDING = 22;

export const useStyles = makeStyles(
    (theme: Theme) => ({
        dialogContainer: {
            [theme.breakpoints.down('sm')]: {
                alignItems: "flex-end",
            }
        },
        closeButton: {
            position: "absolute",
            right: "0px",
            cursor: "pointer",
            zIndex: 1
        },
        container: {
            padding: `48px ${NORMAL_SIDE_PADDING}px`,
            [theme.breakpoints.down('sm')]: {
                padding: `48px ${SMALL_DEVICE_SIDE_PADDING}px`,
            },
        },
    }),
    { name: "BasicDialog" }
);
