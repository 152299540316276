import * as React from "react";
import { connect } from "react-redux";
import View from './index';
import { socialComponentComputeWidthOnDrop, socialComponentInitialState } from "../index";
import { SocialColorSourceType, SocialStyleType } from "../constants";
import { makeEpicStateSelector } from "../../../../epics/makeEpic";
import { calProps } from "../calcRenderProps";
import { socialGlobalDataEpic } from "../epics/socialGlobalDataEpic/socialGlobalDataEpic";
import type { SocialComponent } from "../flowTypes";
import { getDefaultSocialLinks, getDefaultSocialLinksInCmp } from "../utils";
import { BACKGROUND_THEME_WHITE } from '../../../ThemeGlobalData/constants';

const
    Social = (props) => {
        const { dark, round, socialData: { links }, themeColorsData, themeSettingsData } = props,
            socialData = { ...props.socialData, links: getDefaultSocialLinks(links) },
            component: SocialComponent = {
                ...socialComponentInitialState,
                links: getDefaultSocialLinksInCmp(socialData.links),
                colorType: dark ? SocialColorSourceType.DARK : SocialColorSourceType.MULTI_COLOR,
                styleType: round ? SocialStyleType.CIRCLE_FILL : SocialStyleType.REGULAR
            },
            socialCmpProps = calProps({
                socialData, component, themeColorsData, themeSettingsData, selectedParentTheme: BACKGROUND_THEME_WHITE
            }),
            width = socialComponentComputeWidthOnDrop({ component, socialData });
        return (
            <div style={{ width, pointerEvents: 'none' }}>
                <View {...socialCmpProps} />
            </div>
        );
    },

    mapStateToProps = (appState) => ({
        socialData: makeEpicStateSelector(socialGlobalDataEpic.valueActionType)(appState)
    }),

    SocialView = connect(mapStateToProps)(Social);

export {
    SocialView,
    SocialView as default
};
