import type { AppState } from "../../../../redux/modules/flowTypes";
import valueActionType from "./valueActionType";
import { pathSelector } from "../../../../utils/pathSelector";
import { makeEpicStateSelector } from "../../../../epics/makeEpic";

export const
    subscriptionTypeStateSel = (appState: AppState) =>
        pathSelector(['subscriptionType'])(appState.epics[valueActionType].state),
    subscriptionStateSel = (appState: AppState) =>
        appState.epics[valueActionType].state,
    subscriptionDataSelector = makeEpicStateSelector(valueActionType);
