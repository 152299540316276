/* @flow */

const { makeUri } = require('@libj/make-uri');
const Url = require('url-parse');
const { TRIAL_URI_ENTRY } = require("../constants.js");
const { checkTrialUriPrefix } = require('./checkTrialUriPrefix.js');
const { PartnerTldRegexGroup } = require('../../partners/partnerTld');

/*::
    type Options = {|
        query?: Object,
        fragment?: Object,
        path?: string,
    |}
 */

const
    LocalHosts = [
        'localhost',
        'local.one.com',
        'master.one.com',
        'fork.one.com',
    ],
    TrialNextStagingHostMap = {
        'webeditor-next.one.com': 'websitebuilder-next.one.com',
        'webeditor-staging.one.com': 'websitebuilder-staging.one.com',
    };

const makeTrialRedirectUrl = (inputUri/*: string */, { query, fragment, path }/*: Options */ = {}) => {
    const
        url = new Url(inputUri),
        params/*: Object */ = {
            scheme: url.protocol,
            authority: {
                host: url.hostname,
                port: url.port,
            },
            query,
            fragment,
            path,
        },
        hasTryPrefix = checkTrialUriPrefix(inputUri);

    if (TrialNextStagingHostMap[params.authority.host]) {
        params.authority.host = TrialNextStagingHostMap[params.authority.host];
    }

    const isMatchingHostname = (
        new RegExp(`${PartnerTldRegexGroup}`).test(params.authority.host)
        || LocalHosts.indexOf(params.authority.host) > -1
    );

    if (isMatchingHostname && !hasTryPrefix) {
        params.authority.host = `${TRIAL_URI_ENTRY}-${params.authority.host}`;
    } else if (!hasTryPrefix) {
        throw new Error(`Invalid prefix given: ${inputUri}. Should be within .one.com or local`);
    }

    return makeUri(params);
};

module.exports = { makeTrialRedirectUrl };
