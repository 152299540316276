import { types as textGlobalstyleTypes } from '../../Globalstyles/Text/constants';

export const
    types = textGlobalstyleTypes,

    MIN_BLUR_RADIUS = 0,
    MAX_BLUR_RADIUS = 50,

    MIN_SHADOW_HORIZONTAL_OFFSET = -20,
    MAX_SHADOW_HORIZONTAL_OFFSET = 20,

    MIN_SHADOW_VERTICAL_OFFSET = -20,
    MAX_SHADOW_VERTICAL_OFFSET = 20;
