// TODO: test / refactor
// TODO: drop mockCookieValueGetter

import cookie from 'cookie';

import { getCookieTLD } from '../../../server/shared/utils/getCookieTLD';
import { getAppConfig } from "../components/App/epics/appConfig/appConfig";

type CookieValueGetter = () => string;

const ONE_COM_BASE_COOKIE_DOMAIN = getCookieTLD(getAppConfig());

const DefaultSetOptions = {
    path: '/',
};

let getCookieValue = () => document.cookie;

const mockCookieValueGetter = (getter: CookieValueGetter) => {
    getCookieValue = getter;
};

const getCookie = (name: string, def: any = null) => {
    const data = cookie.parse(getCookieValue());
    return data.hasOwnProperty(name) ? data[name] : def;
};

// TODO: https://group-one.atlassian.net/browse/WBTGEN-23159
const setCookie = (name: string, value: string, inOptions?: Record<string, any>, dropDomain?: boolean) => {
    const options: Record<string, any> = inOptions ? { ...DefaultSetOptions, ...inOptions } : { ...DefaultSetOptions };
    if (!dropDomain) {
        options.domain = ONE_COM_BASE_COOKIE_DOMAIN;
    }
    document.cookie = cookie.serialize(name, value, options);
};

// TODO: https://group-one.atlassian.net/browse/WBTGEN-23159
const unsetCookie = (name: string, options?: Record<string, any> | null, dropDomain?: boolean) => {
    setCookie(name, '', {
        domain: null, // IMPORTANT: Domain ought to be unset in order to delete the cookie
        ...options,
        expires: new Date('1970-01-01T00:00:01.000Z'),
    }, dropDomain);
};

const setManyCookies = (cookies: Record<string, any>, options?: Record<string, any>, dropDomain?: boolean) => {
    Object.keys(cookies).forEach(name => {
        setCookie(name, cookies[name], options, dropDomain);
    });
};

const unsetManyCookies = (cookieNames: Array<string>, options?: Record<string, any>, dropDomain?: boolean) => {
    cookieNames.forEach(cookieName => {
        unsetCookie(cookieName, options, dropDomain);
    });
};

export {
    getCookie,
    unsetCookie,
    setCookie,
    setManyCookies,
    unsetManyCookies,
    mockCookieValueGetter,
    ONE_COM_BASE_COOKIE_DOMAIN,
};
