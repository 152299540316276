import * as R from "ramda";
import {
    ADD_SECTION_BUTTON_CLICKED, COMPONENT_DIMENSIONS_CHANGED_FROM_PP,
    COMPONENTS_RESIZED,
    REMOVE_SECTION_DROP_ZONE
} from "./actionTypes";
import { ReceiveOnlyComponentsMap } from "./selectorActionTypes";
import { setSectionInsertion, setComponentsMap } from "./setters";
import * as updateReasons from "./updateReasons";
import * as actionTypes from "../../../Panel/actionTypes";
import { SectionsKind } from "../../../Panel/configs/constants";
import { getSectionInsertionProgressState, getComponentsMap, getSectionPlaceholderValue, getSectionInsertion } from "./getters";
import { LeftPanelOpenStateSelector } from "../../../Panel/epics/extendedPanel/index";
import { deselectAllComponents, setSelectedComponentsIds } from "./selectedComponentsIds";
import {
    adjustSectionTopsAtPosition,
    removeEmptySpacesBetweenSections,
    getClosestSectionEndPoint,
    getHeaderSection,
    getFooterSection,
    createSection,
    isFooterSection, getPrevOrNextSectionInfo, adjustSectionTop
} from "../../../oneweb/Section/utils";
import { SectionDropZoneHeight } from "../../../../view/Workspace/TemplateArea/SectionDropZone/dropZone";
import DndAddComponentValueActionType from "../../../DndAddComponent/epic/valueActionType";
import { receiveOnly } from "../../../../epics/makeCondition";
import { isSectionKind } from "../../../oneweb/componentKinds";
import { ScrollValueActionType } from "../scroll/valueActionType";
import viewportDimensionsValueActionType from "../viewportDimensions/valueActionType";
import { scrollWorkspaceToPositionAction } from "../scroll/actions";
import {
    SPLIT_SECTION_CHECK_CAN_BE_SPLIT,
    SPLIT_SECTION_COMPONENT
} from "../../../oneweb/Section/epics/splitSectionDecoration/actionTypes";
import { getTitleForSection } from "./getSectionTitles";
import { componentAttachmentsVAT } from "../componentAttachements/valueActionType";
import valueActionType from "./valueActionType";
import { MIN_SECTION_HEIGHT } from "../../../oneweb/Section/constants";
import { getCmpsMapWithUpdatedSectionColor, isModernHeaderOrFooter } from "../../../ModernLayouts/utils";
import { HEADER_SHARE_BG_IMG_WITH_HEADER_TOGGLE, SECTION_FOOTER_RESIZED_BY_ADDING_COMPONENT } from "../../../oneweb/Section/actionTypes";
import { REDO, UNDO } from "../../../../epics/undoManager/actionTypes";
import componentsRegistry from "../../../../view/oneweb/registry/index";
import { getDefaultReducerState } from "../../../../redux/makeReducer/index";
import { SharedBackgroundForHeaderEnabledSelector } from "../../../oneweb/Section/epics/shareHeaderAndFirstSectionBgImgEpic";

const updateSectionInsertState = R.curry((isTop, componentId, epicState) => {
    const section = getComponentsMap(epicState)[componentId];
    if (!section) { return epicState; }

    const { top, height, id: sectionId } = section,
        placeholderPosition = isTop ? top : top + height;

    return setSectionInsertion({
        inProgress: true,
        sectionId,
        isTop,
        placeholderPosition
    })(epicState);
});

const resetSectionInsertState = setSectionInsertion({ inProgress: false });

const openLeftPanelAction = (kind: string) => ({
    type: actionTypes.PANEL_ITEM_LEFT_MOUSE_DOWN,
    payload: { componentKind: kind, isComponentCompatible: true }
});

const cancelAddSectionUpdater = {
        conditions: [
            LeftPanelOpenStateSelector,
            receiveOnly(DndAddComponentValueActionType),
            receiveOnly(componentAttachmentsVAT),
        ],
        reducer: ({
            values: [{ open: isLeftPanelOpen, itemKind: leftPanelItemKind }, { isVisible: dndGhostVisible }, { attachments }],
            state: epicState
        }) => {
            const isAddSectionInProgress = getSectionInsertionProgressState(epicState);
            let newState = epicState;
            if (
                (isLeftPanelOpen && leftPanelItemKind !== SectionsKind) ||
                (isAddSectionInProgress && !isLeftPanelOpen && !dndGhostVisible)
            ) {
                const cmpMap = getComponentsMap(epicState),
                    newCmpMap = removeEmptySpacesBetweenSections(attachments, cmpMap);
                newState = R.pipe(
                    resetSectionInsertState,
                    setComponentsMap(newCmpMap)
                )(newState);
            }
            return {
                state: newState,
                updateReason: updateReasons.CHANGE_SCOPE
            };
        }
    },
    removeDropZone = [UNDO, REDO, REMOVE_SECTION_DROP_ZONE].map((action: string) => ({
        conditions: [
            ReceiveOnlyComponentsMap,
            receiveOnly(componentAttachmentsVAT),
            action
        ],
        reducer: ({ values: [componentsMap, { attachments }], state: epicState }) => {
            const newCmpMap = removeEmptySpacesBetweenSections(attachments, componentsMap);
            return {
                state: R.pipe(
                    resetSectionInsertState,
                    setComponentsMap(newCmpMap)
                )(epicState),
                updateReason: updateReasons.CHANGE_SCOPE
            };
        }
    })),
    dropZoneUpdater = {
        conditions: [
            DndAddComponentValueActionType,
            ReceiveOnlyComponentsMap,
            receiveOnly(ScrollValueActionType),
            receiveOnly(viewportDimensionsValueActionType),
            receiveOnly(componentAttachmentsVAT),
        ],
        reducer: ({
            values: [dnd, componentsMap, { y: viewportTop, x: viewportLeft }, { height: workspaceHeight }, { attachments }],
            state: epicState,
            dispatchAsync
        }) => {
            const {
                    componentKind,
                    isVisible,
                    inWorkspaceDimensions: { height: sectionHeight },
                    inWorkspacePosition: { y: yPosition }
                } = dnd,
                { inProgress, placeholderPosition } = getSectionInsertion(epicState);
            let actionToDispatch,
                newState = epicState,
                newCmpMap = removeEmptySpacesBetweenSections(attachments, componentsMap),
                headerSection = getHeaderSection(newCmpMap),
                footerSection = getFooterSection(newCmpMap);

            if (isVisible && isSectionKind(componentKind)) {
                const center = yPosition + (sectionHeight / 2),
                    headerBottom = headerSection.top + headerSection.height;

                let dropZoneTop = center;

                if (dropZoneTop <= headerBottom) {
                    dropZoneTop = headerBottom;
                } else if (dropZoneTop >= footerSection.top) {
                    dropZoneTop = footerSection.top;
                } else {
                    dropZoneTop = getClosestSectionEndPoint(center, newCmpMap);
                }

                const dropZoneBottom = dropZoneTop + SectionDropZoneHeight,
                    viewportBottom = viewportTop + workspaceHeight;

                if (placeholderPosition !== dropZoneTop) {
                    newCmpMap = adjustSectionTopsAtPosition(dropZoneTop, SectionDropZoneHeight, newCmpMap, attachments);
                    newState = R.pipe(
                        setSectionInsertion({ inProgress: true, placeholderPosition: dropZoneTop }),
                        setComponentsMap(newCmpMap)
                    )(epicState);
                    if (dropZoneTop < viewportTop || dropZoneBottom > viewportBottom) {
                        const scroll = viewportTop + (dropZoneTop > viewportTop ?
                            dropZoneBottom - viewportBottom : dropZoneTop - viewportTop);

                        actionToDispatch = scrollWorkspaceToPositionAction(viewportLeft, scroll);
                    }
                }
            } else if (!isVisible && inProgress) {
                dispatchAsync({ type: REMOVE_SECTION_DROP_ZONE });
            }
            return {
                state: newState,
                actionToDispatch,
                updateReason: updateReasons.CHANGE_SCOPE
            };
        }
    },
    addSectionUpdater = {
        conditions: [
            receiveOnly(componentAttachmentsVAT),
            ADD_SECTION_BUTTON_CLICKED
        ],
        reducer: ({ values: [{ attachments }, { isTop, sectionId }], state: epicState }) => {
            return {
                state: R.pipe(
                    updateSectionInsertState(isTop, sectionId),
                    epicsState => {
                        const cmpMap = R.converge(
                            adjustSectionTopsAtPosition,
                            [getSectionPlaceholderValue, () => SectionDropZoneHeight, getComponentsMap, () => attachments]
                        )(epicsState);
                        return setComponentsMap(cmpMap, epicsState);
                    },
                    deselectAllComponents
                )(epicState),
                actionToDispatch: openLeftPanelAction(SectionsKind),
                updateReason: updateReasons.CHANGE_SCOPE
            };
        }
    },
    splitSectionUpdater = {
        conditions: [
            ReceiveOnlyComponentsMap,
            SPLIT_SECTION_COMPONENT
        ],
        reducer: ({ values: [componentsMap, splitPoint], state: epicState }) => {
            let newCmpsMap = { ...componentsMap };
            const { sectionId, topOfGap, gapHeight } = splitPoint;
            const top = topOfGap + (gapHeight / 2); // split in the middle of the gap
            const sectionBeingSplit = componentsMap[sectionId];

            const newSection = createSection({
                top,
                bottom: sectionBeingSplit.top + sectionBeingSplit.height,
                inTemplate: false,
                orderIndex: sectionBeingSplit.orderIndex
            });
            const record = componentsRegistry[sectionBeingSplit.kind];
            const id = newSection.id;
            newCmpsMap[id] = newSection;
            newCmpsMap[id] = {
                ...getDefaultReducerState(record.reducer),
                ...newSection,
                title: getTitleForSection(newCmpsMap, newSection),
                style: {
                    ...sectionBeingSplit.style,
                    background: {
                        ...sectionBeingSplit.style.background,
                        assetData: sectionBeingSplit.style.background.assetData ? {
                            ...sectionBeingSplit.style.background.assetData,
                            scrollEffect: null
                        } : null
                    }
                }
            };
            newCmpsMap[sectionId] = {
                ...sectionBeingSplit,
                height: top - sectionBeingSplit.top
            };
            const newState = R.pipe(
                setComponentsMap(newCmpsMap)
            )(epicState);
            const toBeSelectedSectionId = newCmpsMap[id].height > newCmpsMap[sectionId].height ? id : sectionId;
            return {
                state: setSelectedComponentsIds([toBeSelectedSectionId], newState),
                multipleActionsToDispatch: [
                    { type: COMPONENTS_RESIZED, payload: { componentsId: [sectionId] } },
                    { type: SPLIT_SECTION_CHECK_CAN_BE_SPLIT, payload: toBeSelectedSectionId }
                ],
                updateReason: SPLIT_SECTION_COMPONENT
            };
        }
    },
    resizeFooterUpdater = {
        keepFullActions: true,
        conditions: [valueActionType],
        reducer: ({ values: [{ epicUpdateReason }], state: epicState }) => {
            let componentsMap = {
                ...epicState.state.componentsMap
            };
            if (
                epicUpdateReason === updateReasons.MOVED_BY_KEYBOARD
                || epicUpdateReason === updateReasons.MOVED_BY_MOUSE
                || epicUpdateReason === updateReasons.RESIZED_BY_MOUSE
                || epicUpdateReason === updateReasons.RESIZED_FROM_PROP_PANEL
                || epicUpdateReason === updateReasons.PASTE
                || epicUpdateReason === updateReasons.COMPONENT_ADDED
                || epicUpdateReason === updateReasons.COMPONENT_REDUCER
                || epicUpdateReason === updateReasons.PROPERTY_CHANGE
                || epicUpdateReason === updateReasons.SHIFTDOWN_DURING_TYPING
                || epicUpdateReason === updateReasons.COMPONENT_HEIGHT_CHANGE_DURING_TYPING
                || epicUpdateReason === updateReasons.ADJUSTMENT_DATA_APPLIED
                || epicUpdateReason === updateReasons.SHIFTBAR
            ) {
                let
                    pageHeight = 0,
                    footerCmp: any = null;
                R.pipe(
                    R.values,
                    R.forEach(c => {
                        const isCodeComponent = c.location;
                        if (!isCodeComponent) {
                            if (!footerCmp && isFooterSection(c)) {
                                footerCmp = c;
                            } else if (!isSectionKind(c.kind)) {
                                pageHeight = Math.max(pageHeight, c.top + c.height);
                            }
                        }
                    })
                )(componentsMap);
                if (!footerCmp) return { state: epicState };
                const footerBottom = footerCmp.top + footerCmp.height;
                if (footerBottom < pageHeight) {
                    componentsMap[footerCmp.id] = {
                        ...footerCmp,
                        height: Math.max(footerCmp.height + (pageHeight - footerBottom), MIN_SECTION_HEIGHT)
                    };
                    return {
                        state: R.pipe(
                            setComponentsMap(componentsMap)
                        )(epicState),
                        actionToDispatch: epicUpdateReason === updateReasons.COMPONENT_ADDED ? {
                            type: SECTION_FOOTER_RESIZED_BY_ADDING_COMPONENT,
                            payload: {
                                newComponentsIds: [...epicState.scope.selectedComponentsIds]
                            }
                        } : null,
                        updateReason: updateReasons.FOOTER_HEIGHT_CHANGED
                    };
                }
            }
            return { state: epicState };
        }
    },
    sectionResizeUpdater = {
        conditions: [
            receiveOnly(componentAttachmentsVAT),
            COMPONENT_DIMENSIONS_CHANGED_FROM_PP
        ],
        reducer: ({ values: [{ attachments }, { selectedComponentId, diffPx, ppValue }], state: epicState }) => {
            let componentsMap = { ...epicState.state.componentsMap };
            const selectedComponent = componentsMap[selectedComponentId];
            if (selectedComponent && isSectionKind(selectedComponent.kind)) {
                let diff = diffPx;
                const isHeightDecreased = diff < 0;

                if (isHeightDecreased) {
                    const componentIdsAttachedToSection = attachments[selectedComponent.id] || [];
                    let contentBottom = selectedComponent.top;

                    if (isModernHeaderOrFooter(selectedComponent)) {
                        const {
                            modernLayout: { minDimensions: { height: minSectionHeight = MIN_SECTION_HEIGHT } = {} }
                        } = selectedComponent;
                        contentBottom = selectedComponent.top + minSectionHeight;
                    } else {
                        R.pipe(
                            R.map(id => componentsMap[id]),
                            R.forEach(c => {
                                contentBottom = Math.max(contentBottom, c.top + c.height);
                            })
                        )(componentIdsAttachedToSection);
                    }

                    const updatedComponent = {
                        ...selectedComponent,
                        height: Math.max(contentBottom - selectedComponent.top, ppValue, MIN_SECTION_HEIGHT)
                    };
                    componentsMap[selectedComponent.id] = { ...updatedComponent };
                    const { section: nextSection } = getPrevOrNextSectionInfo(selectedComponent.id, componentsMap, true);
                    if (nextSection) {
                        diff = updatedComponent.height + updatedComponent.top - nextSection.top;
                        componentsMap = adjustSectionTop(nextSection, diff, componentsMap, attachments);
                    }
                    return {
                        state: R.pipe(
                            setComponentsMap(componentsMap)
                        )(epicState),
                        updateReason: updateReasons.SECTION_HEIGHT_CHANGED
                    };
                }
            }
            return { state: epicState };
        }
    },
    sharedBackgroundUpdater = {
        conditions: [
            SharedBackgroundForHeaderEnabledSelector
        ],
        reducer: ({ values: [sharedBackgroundEnabled], state: epicState, sourceAction: { type: sourceAction } }) => {
            const componentsMap = getComponentsMap(epicState),
                headerSection = getHeaderSection(componentsMap);

            if (headerSection && sharedBackgroundEnabled && sourceAction === HEADER_SHARE_BG_IMG_WITH_HEADER_TOGGLE) {
                return {
                    state: setComponentsMap(
                        getCmpsMapWithUpdatedSectionColor(componentsMap, headerSection.id, 0),
                        epicState
                    ),
                    updateReason: updateReasons.SECTION_OPACITY_CHANGED
                };
            }
            return { state: epicState };
        }
    };

export {
    removeDropZone,
    addSectionUpdater,
    cancelAddSectionUpdater,
    dropZoneUpdater,
    splitSectionUpdater,
    resizeFooterUpdater,
    sectionResizeUpdater,
    sharedBackgroundUpdater
};
