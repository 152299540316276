import React from 'react';
import styles from './dialog.css';
import type { ShutterstockSelectedImages, ShutterstockSelectedImageItem, ShutterstockKindT } from "../../types";
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import WarningDialog from '../../../../../view/common/dialogs/WarningDialog/index';
import { injectIntl } from "../../../../../view/intl/index";
import LongTextTip from '../../../../../view/common/LongTextTip/index';
import {
    cancelShutterstockPartialDownloadsRetryAction,
    retryFailedShutterstockPartialDownloadsAction
} from "../../actions";

const
    WIDTH = 470,
    HEIGHT = 345;

export type ShutterStockPartialDialogProps = {
    kind: ShutterstockKindT,
    multi: boolean,
    images: ShutterstockSelectedImages,
    intl: Intl,
    dispatch: Dispatch,
};

const Messages = {
    title: (plural: boolean): MsgJointInput => [
        'msg: fm.focPartialDownload.title {Failed to get {plural, select, true {images} false {image}}}',
        { plural },
    ],
    topMessage: (plural: boolean): MsgJointInput => [
        'msg: fm.focPartialDownload.topMessage {Sorry, we could not get the following {plural, select, true {images} false {image}}:}', // eslint-disable-line max-len
        { plural },
    ],
    hint: 'msg: fm.focPartialDownload.hint {Contact our support if the problem continues.}',
    retryQuestion: (plural: boolean): MsgJointInput => [
        'msg: fm.focPartialDownload.retryQuestion {Do you want to try adding {plural, select, true {them} false {it}} again?}', // eslint-disable-line max-len
        { plural },
    ],
};

class ShutterstockPartialDownloadDialogClass extends React.Component<ShutterStockPartialDialogProps> {
    retry = () => {
        this.props.dispatch(retryFailedShutterstockPartialDownloadsAction(this.props.kind));
    };

    cancel = () => {
        this.props.dispatch(cancelShutterstockPartialDownloadsRetryAction(this.props.kind));
    };

    render() {
        const { multi, images, intl } = this.props;

        return (
            <WarningDialog
                title={Messages.title(multi)}
                mctaText="msg: fm.focPartialDownload.mcta {Try again}"
                mctaHandler={this.retry}
                sctaText="msg: common.addToPage {Add to page}"
                sctaHandler={this.cancel}
                onClose={this.cancel}
                bodyClassName={styles.partialDownloadDialogBody}
            >
                <p>{intl.msgJoint(Messages.topMessage(multi))}</p>
                <ul className={styles.partialDownloadDialogList}>
                    {images.map((img: ShutterstockSelectedImageItem) => (
                        <li key={img.imageId}>
                            <LongTextTip className={styles.partialDownloadDialogListText}>
                                {img.description}
                            </LongTextTip>
                        </li>
                    ))}
                </ul>
                <p>{intl.msgJoint(Messages.hint)}</p>
                <p>{intl.msgJoint(Messages.retryQuestion(multi))}</p>
            </WarningDialog>
        );
    }
}

export const shutterstockPartialDownloadDialog = getCenteredDialogConfig({
    width: WIDTH,
    height: HEIGHT,
    component: injectIntl(ShutterstockPartialDownloadDialogClass),
});
