// @ts-nocheck
import WebFont from 'webfontloader';

let _exports; // eslint-disable-line import/no-mutable-exports

if (window.___nodeTests) {
    // running tests
    _exports = {
        load: ({ active }: any) => setTimeout(active, 1)
    };
} else {
    _exports = WebFont;
}

export default _exports;
