import * as R from 'ramda';
import { getMinComponentHeight } from './utils';
import { MIN_WIDTH, MAX_WIDTH } from './constants';
import type {
    FacebookPageComponent,
    FacebookPageComponentDependsOn,
    FacebookPageComponentExtension
} from "./flowTypes";
import type { AdjustmentHook } from '../../Workspace/epics/componentsEval/flowTypes';

type FacebookPageHook = AdjustmentHook<FacebookPageComponent, FacebookPageComponentDependsOn, FacebookPageComponentExtension, undefined> // eslint-disable-line max-len

/**
 * IMPORTANT: Height and Width calculation have been kept here instead of calcRenderProps because the component
 *            must be updated in componentsMap if the height / width changes. And subsequently the base will also be
 *            updated accordingly.
 */

const
    hook: FacebookPageHook = (
        {
            isTransient: nextIsTransient,
            component: nextComponent,
            componentExtension
        }
    ) => {
        const
            minHeight = getMinComponentHeight(nextComponent),
            newWidth = R.pipe(R.clamp(MIN_WIDTH, MAX_WIDTH), Math.round)(nextComponent.width),
            newHeight = R.pipe(R.max(minHeight), Math.round)(nextComponent.height),
            updatedComponent = nextComponent.width === newWidth
                && nextComponent.height === newHeight ? nextComponent : {
                    ...nextComponent,
                    width: newWidth,
                    height: newHeight
                },
            updatedComponentExtension = componentExtension
                && componentExtension.minDimensions
                && componentExtension.minDimensions.height === minHeight
                && componentExtension.isTransient === nextIsTransient ? componentExtension : {
                    minDimensions: { width: MIN_WIDTH, height: minHeight },
                    isTransient: nextIsTransient
                };

        return [updatedComponent, updatedComponentExtension];
    },
    hookConfig = {
        hook,
        shouldCallHook: () => true
    };

export default hookConfig;
