import React from 'react';
import cx from 'classnames';
import styles from './FileChooserHeader.css';
import UploadFileMenu from './UploadFileMenu';
import { injectIntl } from '../../../intl/index';
import type { MsgJointInput } from '../../../intl/index';

type Props = {
    title?: MsgJointInput,
    intl: Intl,
    className?: string,
    uploadMenuClassName?: string,
    headlessMode?: null | boolean,
    acceptTypes?: string,
    mode?: string,
    isFreeOneComVideoFileChooser?: boolean,
};

export const FcUploadPaneCom = injectIntl(
    ({ title, intl, className, uploadMenuClassName, headlessMode, acceptTypes, mode, isFreeOneComVideoFileChooser = false }: Props) => {
        return (
            <div className={cx(styles.topPane, className)}>
                {title && <div className={cx(styles.title, styles.uploadTitle)}>{intl.msgJoint(title)}</div>}
                <UploadFileMenu
                    isFreeOneComVideoFileChooser={isFreeOneComVideoFileChooser}
                    className={uploadMenuClassName}
                    headlessMode={headlessMode}
                    acceptTypes={acceptTypes}
                    mode={mode}
                />
            </div>
        );
    }
);
