import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { Button, InputLabel, Link } from '@mui/material';
import cx from 'classnames';

import type { AppState } from "../../../common/types";
import type { SocialShareImageChooserComponentProps } from "./types";

import {
    fetchImageContents,
    getResourceMetaData,
} from "../../ImageChooser/actions";
import { selectImageChooserData } from "../../../common/selectors";
import { getFileNameFromAssetUrl } from "../../ImageChooser/utils";

import { useStyles } from './styles';
import imageStyles from "../../Images/imageStyle.css";

const ImageChooserComponent = (props: SocialShareImageChooserComponentProps): JSX.Element | null => {
    const {
        file,
        asset,
        label,
        placeholder,
        hint,
        hintWarning,
        inputClass,
        labelClass,
        hintClass,
        hintWarningClass,
        isResourcesLoaded,
        isAssetMetaDataLoaded,
        onChange,
        onRemove,
        intl,
        dispatch
    } = props;
    const classes = useStyles();
    const isAssetAvailable = asset && asset.url;

    useEffect(() => {
        if (!isResourcesLoaded) {
            dispatch(fetchImageContents());
        }
    }, []);

    useEffect(() => {
        if (file && isResourcesLoaded && !isAssetMetaDataLoaded) {
            dispatch(getResourceMetaData(file));
        }
    }, [isResourcesLoaded]);

    return (
        <React.Fragment>
            <InputLabel
                className={labelClass}
            >
                {label}
            </InputLabel>
            <div className={classes.imageChooseContainer} data-testid="image-chooser-container">
                <Button
                    disableRipple
                    disableFocusRipple
                    disableElevation
                    className={cx(inputClass, isAssetAvailable && classes.imageChooserInputSelected)}
                >
                    <span className={classes.inputLabelContainer}>
                        <span
                            className={classes.inputLabel}
                            onClick={() => onChange()}
                        >
                            { isAssetAvailable ? getFileNameFromAssetUrl(asset.url) : placeholder}
                        </span>
                        {
                            isAssetAvailable &&
                            <span
                                className={classes.closeIcon}
                                onClick={(e) => onRemove(e)}
                                data-testid="image-chooser-close-icon"
                            >
                                <span className={imageStyles.circularCloseIcon} />
                            </span>
                        }
                    </span>
                </Button>
                {
                    isAssetAvailable &&
                    <span className={classes.imageChangeContainer}>
                        <Link
                            underline="none"
                            onClick={() => onChange()}
                            className={classes.imageChangeLink}
                        >
                            {intl.msgJoint("msg: component.gallery.changeImage {Change image}")}
                        </Link>
                    </span>
                }
            </div>
            {
                hintWarning ?
                    <div className={hintWarningClass} data-testid="image-chooser-hint-warning">{intl.msgJoint(hintWarning)}</div> :
                    <div className={hintClass} data-testid="image-chooser-hint">{hint}</div>
            }
        </React.Fragment>
    );
};

const mapStateToProps = (state: AppState) => ({
    isResourcesLoaded: selectImageChooserData(state).isResourcesLoaded,
    isAssetMetaDataLoaded: selectImageChooserData(state).isAssetMetaDataLoaded,
});

export const ImageChooser = connect(mapStateToProps)(ImageChooserComponent);
