import Template from '../../../oneweb/Template/kind';
import isStretchComponentKind from '../../../oneweb/isStretchComponentKind';
import type { ComponentsMap } from "../../../../redux/modules/children/workspace/flowTypes";
import { getLayers, fixPositions } from '../../../Preview/flattening/Flattening';
import type { Template as TemplateType, TemplateComponent } from "../../../oneweb/Template/flowTypes";
import { getCmps } from "../componentAttachements/util";
import { isFooterSection } from "../../../oneweb/Section/utils";
import isGhost from "../../../oneweb/Code/isGhost";

export default (
    componentsMap: ComponentsMap,
    template: TemplateComponent | TemplateType
): ComponentsMap => {
    let
        newComponentsMap = {};
    Object.keys(componentsMap).forEach(cmpId => { newComponentsMap[cmpId] = { ...componentsMap[cmpId] }; });
    const cmps = getCmps(newComponentsMap);

    const layers = getLayers(cmps, newComponentsMap, template);
    layers.reverse().forEach(groups => {
        groups.forEach(group => fixPositions(group));
    });

    // fix the top of components
    layers.reverse().forEach(groups => {
        groups.forEach(group => {
            const
                { block: { kind, top: blockTop, left: blockLeft, stretch = false } } = group,
                isNonStripBlock = !isStretchComponentKind(kind, stretch),
                isNonTemplateBlock = kind !== Template;
            group.items.forEach(cmp => {
                newComponentsMap[cmp.id] = cmp;
                if (isFooterSection(cmp)) {
                    let pageHeight = 0, footerBottom = cmp.top + cmp.height;
                    cmps.forEach(c => {
                        if (!isGhost(c)) {
                            pageHeight = Math.max(
                                newComponentsMap[c.id].top + newComponentsMap[c.id].height,
                                pageHeight
                            );
                        }
                    });
                    if (footerBottom < pageHeight) {
                        newComponentsMap[cmp.id].height = newComponentsMap[cmp.id].height + (pageHeight - footerBottom);
                    }
                }
                if (isNonTemplateBlock && cmp.relIn) {
                    newComponentsMap[cmp.id].top = blockTop + cmp.top; // true top is applied in fixPositions
                    if (isNonStripBlock) {
                        newComponentsMap[cmp.id].left = blockLeft + cmp.left; // true left is applied in fixPositions
                    }
                }
            });
        });
    });

    return newComponentsMap;
};
