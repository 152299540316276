export const
    ONBOARDING_TARGET_LOCALE_CHANGED = "ONBOARDING_TARGET_LOCALE_CHANGED",
    ONBOARDING_GOOGLE_PLACE_RESPONSE = 'ONBOARDING_GOOGLE_PLACE_RESPONSE',
    ONBOARDING_WEBSITE_TITLE_CHANGED = 'ONBOARDING_WEBSITE_TITLE_CHANGED',
    ONBOARDING_WEBSITE_TITLE_CHANGED_ON_BLUR = 'ONBOARDING_WEBSITE_TITLE_CHANGED_ON_BLUR',
    ONBOARDING_CONTINUE_PRESSED = 'ONBOARDING_CONTINUE_PRESSED',
    ONBOARDING_INITIALIZE_TEMPLATE_PREVIEW = 'ONBOARDING_INITIALIZE_TEMPLATE_PREVIEW',
    ONBOARDING_COMPLETE = 'ONBOARDING_COMPLETE',
    ONBOARDING_LOGO_ADD_IMAGE_CLICKED = 'ONBOARDING_LOGO_ADD_IMAGE_CLICKED',
    ONBOARDING_LOGO_BOX_DROP_ZONE_FOR_IMAGE_UPLOAD_CLICKED = 'ONBOARDING_LOGO_BOX_DROP_ZONE_FOR_IMAGE_UPLOAD_CLICKED',
    ONBOARDING_STATE_UPDATED = 'ONBOARDING_STATE_UPDATED',
    ONBOARDING_FILL_LATER_PRESSED = 'ONBOARDING_FILL_LATER_PRESSED',
    ONBOARDING_LOGO_CHOOSEN = 'ONBOARDING_LOGO_CHOOSEN',
    ONBOARDING_REMOVE_LOGO_PRESSED = 'ONBOARDING_REMOVE_LOGO_PRESSED',
    ONBOARDING_DELETE_OR_BACKSPACE_PRESSED_WHEN_LOGO_INPUT_IN_FOCUS = 'ONBOARDING_DELETE_OR_BACKSPACE_PRESSED_WHEN_LOGO_INPUT_IN_FOCUS',
    ONBOARDING_STEP_BACK_BUTTON_PRESSED = 'ONBOARDING_STEP_BACK_BUTTON_PRESSED',
    ONBOARDING_THEMING_BACK_BUTTON_PRESSED = 'ONBOARDING_THEMING_BACK_BUTTON_PRESSED',
    ONBOARDING_ADDRESS_CHANGED = 'ONBOARDING_ADDRESS_CHANGED',
    ONBOARDING_ADDRESS_CHANGED_ON_BLUR = 'ONBOARDING_ADDRESS_CHANGED_ON_BLUR',
    ONBOARDING_ADDRESS_URL_ERASED_BY_USER_INPUT = 'ONBOARDING_ADDRESS_URL_ERASED_BY_USER_INPUT',
    ONBOARDING_PHONE_NUMBER_CHANGED = 'ONBOARDING_PHONE_NUMBER_CHANGED',
    ONBOARDING_PHONE_NUMBER_CHANGED_ON_BLUR = 'ONBOARDING_PHONE_NUMBER_CHANGED_ON_BLUR',
    ONBOARDING_CONTACT_EMAIL_CHANGED = 'ONBOARDING_CONTACT_EMAIL_CHANGED',
    ONBOARDING_CONTACT_EMAIL_CHANGED_ON_BLUR = 'ONBOARDING_CONTACT_EMAIL_CHANGED_ON_BLUR',
    ONBOARDING_ADDRESS_EMAIL_AND_PHONE_STEP_OPENED = 'ONBOARDING_ADDRESS_EMAIL_AND_PHONE_STEP_OPENED',
    ONBOARDING_WELCOME_STEP_OPENED = 'ONBOARDING_WELCOME_STEP_OPENED',
    ONBOARDING_TEMPLATE_SELECTOR_STEP_OPENED = 'ONBOARDING_TEMPLATE_SELECTOR_STEP_OPENED',
    ONBOARDING_GMB_CATEGORIES_STEP_OPENED = 'ONBOARDING_GMB_CATEGORIES_STEP_OPENED',
    ONBOARDING_TITLE_STEP_OPENED = 'ONBOARDING_TITLE_STEP_OPENED',
    ONBOARDING_LOGO_STEP_OPENED = 'ONBOARDING_LOGO_STEP_OPENED',
    GOOGLE_ADDRESS_FETCHED_FROM_GEOLOCATION_AFTER_CONTINUE_PRESSED = 'GOOGLE_ADDRESS_FETCHED_FROM_GEOLOCATION_AFTER_CONTINUE_PRESSED',
    ONBOARDING_TEMPLATE_TOPIC_CHANGED = 'ONBOARDING_TEMPLATE_TOPIC_CHANGED',
    ONBOARDING_GMB_CATEGORY_CHOSEN = 'ONBOARDING_GMB_CATEGORY_CHOSEN',
    ONBOARDING_TEMPLATE_SELECTED = 'ONBOARDING_TEMPLATE_SELECTED',
    TEMPLATE_SELECTOR_CHANGE_THEME_PREVIEW_PAGE = 'TEMPLATE_SELECTOR_CHANGE_THEME_PREVIEW_PAGE',
    CHANGE_COLOR_PICKER_THEME_METHOD = 'CHANGE_COLOR_PICKER_THEME_METHOD',
    CHANGE_SELECTED_COLOR_PALETTE = 'CHANGE_SELECTED_COLOR_PALETTE',
    CANCEL_COLOR_PICKER_CUSTOM_THEME_COLOR = 'CANCEL_COLOR_PICKER_CUSTOM_THEME_COLOR',
    CHANGE_COLOR_PICKER_CUSTOM_THEME_COLOR = 'CHANGE_COLOR_PICKER_CUSTOM_THEME_COLOR',
    SEE_ALL_TEMPLATES_CLICKED = 'SEE_ALL_TEMPLATES_CLICKED',
    ONBOARDING_TEMPLATE_LIST_LEFT_ARROW_CLICKED = 'ONBOARDING_TEMPLATE_LIST_LEFT_ARROW_CLICKED',
    ONBOARDING_TEMPLATE_LIST_RIGHT_ARROW_CLICKED = 'ONBOARDING_TEMPLATE_LIST_RIGHT_ARROW_CLICKED',
    INITIATE_WSB_ONBOARDING = 'INITIATE_WSB_ONBOARDING';
