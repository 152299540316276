import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Lottie from "react-web-lottie";

import animationData from "./animation.json";
import { getStepMarkers, LOTTIE_DEFAULT_OPTIONS } from "../../../view/LottieAnimations/constant";

import { WIZARD_STEP_ID } from "../../constants";
import { STEP_MARKERS } from "./constants";

import { animationDataSelector } from "../../Epic/selectors";

const markers = animationData.markers;
const stepMarkers = getStepMarkers(markers, WIZARD_STEP_ID.CONTACT_INFO, STEP_MARKERS[WIZARD_STEP_ID.CONTACT_INFO]);

export const ContactInfoAnimation = () => {
    const animationEpicData = useSelector(animationDataSelector);
    const { contactInfo: {
        email,
        phone,
        address,
        focusedField
    } } = animationEpicData;

    const animationRef = useRef(null);

    let initialEndPoint = stepMarkers.contact_info_start_point;

    if (address) {
        initialEndPoint = stepMarkers.contact_info_end_point + 1;
    } else if (phone) {
        initialEndPoint = stepMarkers.contact_info_phone_focused + 1;
    } else if (email) {
        initialEndPoint = stepMarkers.contact_info_email_focused + 1;
    }

    const [startPoint, setStartPoint] = useState(stepMarkers.description_end_point);
    const [stopPoint, setStopPoint] = useState(initialEndPoint);

    const initialSegment = [startPoint, stopPoint];

    const defaultOptions = {
        ...LOTTIE_DEFAULT_OPTIONS,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid meet"
        },
        initialSegment,
        animationData
    };

    const setPointsForFields = (conditionPoint) => {
        setStartPoint(conditionPoint);
        setStopPoint(conditionPoint + 1);
    };

    const setPointsForFocusFields = (conditionPoint) => {
        if (stopPoint !== conditionPoint) {
            setStartPoint(stopPoint);
            setStopPoint(conditionPoint);
        }

        if (email) {
            setStartPoint(conditionPoint);
            setStopPoint(conditionPoint + 1);
        }
    };

    useEffect(() => {
        // @ts-ignore
        animationRef.current.playSegments([[startPoint, stopPoint]], true);
        // // @ts-ignore
        // @ts-ignore
        if (animationRef.current?.renderer) {
            // @ts-ignore
            animationRef.current.renderer.elements[4].elements[2].updateDocumentData({ t: email });
            // @ts-ignore
            animationRef.current.renderer.elements[4].elements[1].updateDocumentData({ t: phone });
            // @ts-ignore
            animationRef.current.renderer.elements[4].elements[0].updateDocumentData({ t: address });
        }

        if (address) {
            setPointsForFields(stepMarkers.contact_info_end_point);
        } else if (phone) {
            setPointsForFields(stepMarkers.contact_info_phone_focused);
        } else if (email) {
            setPointsForFields(stepMarkers.contact_info_email_focused);
        } else if (focusedField === null) {
            if (stopPoint !== stepMarkers.contact_info_start_point) {
                setStartPoint(stopPoint);
                setStopPoint(stepMarkers.contact_info_start_point);
            }
        } else if (focusedField === 'email') {
            setPointsForFocusFields(stepMarkers.contact_info_email_focused);
        } else if (focusedField === 'phone') {
            setPointsForFocusFields(stepMarkers.contact_info_phone_focused);
        } else if (focusedField === 'address') {
            setPointsForFocusFields(stepMarkers.contact_info_end_point);
        }
    }, [focusedField, email, phone, address]);

    useEffect(() => {
        // @ts-ignore
        animationRef.current.playSegments([[startPoint, stopPoint]], true);
    }, [startPoint, stopPoint]);

    return (
        <Lottie lottiRef={animationRef} options={defaultOptions} isClickToPauseDisabled />
    );
};
