import makeUuid from '../../../../utils/makeUuid';
import { VideoSettings, VideoTypes, defaultSettings } from './constants';
import type { NewVideoCompDefaultState } from "./view/flowTypes";
import { youtubeUrl } from './videoUrlsUtility';

export default (videoId: string | null): NewVideoCompDefaultState => {
    let settings = defaultSettings,
        state = { id: makeUuid() };
    if (videoId) {
        settings = [
            {
                ref: VideoSettings.href,
                // @ts-ignore
                value: {
                    input: youtubeUrl(videoId)
                }
            },
            ...settings,
        ];
        state = {
            ...state,
            // @ts-ignore
            type: VideoTypes.YOUTUBE,
            settings
        };
    } else {
        settings = [
            {
                ref: VideoSettings.muted,
                value: false
            },
            ...settings,
        ];
        state = {
            ...state,
            // @ts-ignore
            type: VideoTypes.FILE,
            settings,
        };
    }
    return {
        mobileDown: false,
        // @ts-ignore
        state
    };
};
