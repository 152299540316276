
import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { useIntl } from "../../../../../../../wbtgen/src/view/intl";

import { analyticsGetStartedAction, analyticsOpenTermsLinkAction } from "../actions";
import { useStyles } from "../styles";
import { makeKlikenTermsUrl } from "../utils";

type AnalyticsGetStartedActionProps = {
    language: string;
    dispatch: Dispatch;
};

export const AnalyticsGetStartedAction = ({ language, dispatch }: AnalyticsGetStartedActionProps) => {
    const intl = useIntl();
    const classes = useStyles();

    const handleAction = () => {
        dispatch(analyticsGetStartedAction());
    };

    const handleTermsLinkClick = () => {
        dispatch(analyticsOpenTermsLinkAction());
    };

    return (
        <Box
            maxWidth={{ xs: '328px', sm: '372px' }}
            marginLeft={{ xs: 'auto', md: '0px' }}
            marginRight="auto"
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems={{ xs: 'center', md: 'flex-start' }}
        >
            <Button
                aria-label="get started"
                variant="contained"
                color="primary"
                className={classes.analyticsAction}
                onClick={handleAction}
            >
                {intl.msgJoint("msg: common.getStarted {Get started}")}
            </Button>
            <Box height={15} />
            <Typography align="left" className={classes.analyticsTerms}>
                {intl.msgJoint("msg: analyticsTab.acceptTerms {By continuing you accept that we share information with Kliken, our service provider. See their}")}
                {" "}
                <Link
                    href={makeKlikenTermsUrl(language)}
                    target="_blank"
                    onClick={handleTermsLinkClick}
                >
                    {intl.msgJoint("msg: common.termsAndConditions {terms and conditions}")}
                </Link>
            </Typography>
        </Box>
    );
};
