// @ts-nocheck
import React, { useEffect, useRef, useState, useMemo } from "react";
import cx from 'classnames';
import styles from './WSModernSection.css';
import { convertLayoutStyleToStyleObject, getCmpIdsMapInRow } from "../../../../components/ModernLayouts/preview_utils";
import { MinGapBtwCols } from "../../../../components/ModernLayouts/constants";
import { RendererContext, SectionContext } from "./ModernSectionsRenderer";
import { evaluateElementRectValue, getWidthsOfColElements, getMinWidthOfCol } from './utils';
import { ColumnView } from "./ColumnRenderer";
import {
    MENU,
    WEBSHOP_POLICIES,
    WEBSHOP_PAYMENT_METHODS,
    PHONE_KIND,
    EMAIL_KIND,
    ADDRESS_KIND
} from "../../../../components/oneweb/componentKinds";

const flexColumnKind = {
    [MENU]: true,
    [WEBSHOP_POLICIES]: true,
    [WEBSHOP_PAYMENT_METHODS]: true,
    [ADDRESS_KIND]: true,
    [PHONE_KIND]: true,
    [EMAIL_KIND]: true
};

const RowView = ({ row, isInRowGroup }) => {
    const { componentsMapExtension, workspaceBBox, modernCmpsMap } = React.useContext(RendererContext);
    const { mhfLayout: layout } = React.useContext(SectionContext);
    const [colsStyle, setColsStyle] = useState([]),
        rowRef = useRef(null);

    let { cols, vResponsive, style: { margin = {} } = {} } = row;
    let style = { ...convertLayoutStyleToStyleObject({ margin }) },
        cmpsInRow = useMemo(() => Object.keys(getCmpIdsMapInRow(row, {})), [row]),
        cmpDimensionSum = cmpsInRow.reduce((res, id) => {
            const { width, height } = modernCmpsMap[id] || { width: 0, height: 0 };
            return {
                width: res.width + width,
                height: res.height + height,
            };
        }, { width: 0, height: 0 });
    const hasSubLayout = cols.length === 1 && layout[cols[0].cmps[0].id],
        updateTriggeringValues = [
            cols,
            ...Object.values(evaluateElementRectValue(rowRef.current, workspaceBBox)),
            ...Object.values(cmpDimensionSum),
        ];

    const updateStylesForThreeColumnsInRow = () => {
        const colElements = [...rowRef.current.querySelectorAll(`:scope > .${styles.flexColumn}`)];
        let centerCol = cols[1],
            firstCol = cols[0],
            lastCol = cols[2],
            otherCols = [firstCol, lastCol];

        if (!centerCol.isExpandable) {
            const flexColWidths = getWidthsOfColElements(colElements),
                [, centerColWidth] = flexColWidths,
                colsMinWidths = cols.map((col, i) => {
                    if (!col.isExpandable) {
                        return flexColWidths[i];
                    }
                    return getMinWidthOfCol(col, componentsMapExtension);
                }),
                otherColMinWidth = Math.max(colsMinWidths[0], colsMinWidths[2]),
                sideColStyles = {
                    maxWidth: `calc(50% - ${MinGapBtwCols}px - ${centerColWidth / 2}px)`,
                    minWidth: otherColMinWidth
                };
            setColsStyle([sideColStyles, {}, sideColStyles]);
            return;
        }

        let otherColIsExpandable = otherCols.some((col) => col.isExpandable);

        const minTotalGapBetweenCols = MinGapBtwCols * (cols.length - 1);

        if (otherColIsExpandable) {
            let calcColsStyle = [];
            cols.forEach((c, i) => {
                calcColsStyle[i] = { minWidth: `calc((100% - ${minTotalGapBetweenCols}px)/3)` };
            });
            setColsStyle(calcColsStyle);
            return;
        }
        const otherColWidths = getWidthsOfColElements([colElements[0], colElements[2]]);

        let colMaxWidth = Math.max(...otherColWidths);
        const calcColsStyle = {
            width: colMaxWidth,
            maxWidth: colMaxWidth
        };
        const centerColStyles = {
            maxWidth: `calc(100% - ${colMaxWidth * 2}px - ${minTotalGapBetweenCols}px)`,
            flex: 1
        };
        setColsStyle([calcColsStyle, centerColStyles, calcColsStyle]);
    };

    const updateStylesForTwoColumnsInRow = () => {
        let style = [];
        cols.forEach((col) => {
            const isFlexColumn = col.cmps.some(({ id }) => flexColumnKind[modernCmpsMap[id].kind]);
            style.push({
                maxWidth: !isFlexColumn ? `calc(50% - ${MinGapBtwCols / 2}px)` : undefined,
                flex: isFlexColumn ? 1 : 0
            });
        });
        setColsStyle(style);
        return;
    };

    useEffect(() => {
        if (cols.length === 3) {
            updateStylesForThreeColumnsInRow();
            return;
        }
        if (cols.length === 2) {
            updateStylesForTwoColumnsInRow();
            return;
        }
        setColsStyle([]);
    }, updateTriggeringValues);

    return (<div
        ref={rowRef}
        className={cx(styles.flexRow, { [styles.vResponsive]: vResponsive && !isInRowGroup, [styles.subLayoutRow]: hasSubLayout })}
        style={style}
    >
        { cols.map((col, i) => <ColumnView key={i} colsLength={cols.length} col={col} colsStyle={colsStyle[i] || {}} />) }
    </div>);
};

const RowGroupView = ({ rows }) => {
    const rowGroupRef = useRef(null);

    return (<div ref={rowGroupRef} className={cx(styles.flexRowGroup)}>
        {rows.map((row, i) => <RowView row={row} key={i} isInRowGroup />)}
    </div>);
};

export {
    RowView,
    RowGroupView
};
