import React from "react";
export const SvgFacebookSquareFillDark = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <path fill="#3c3c3c" d="M0 0h48v48H0z" />
            <path
                fill="#ffffff"
                d="M28.313 24.63l.572-3.742h-3.578V18.46c0-1.024.5-2.022 2.102-2.022h1.627v-3.185S27.559 13 26.148 13c-2.947 0-4.873 1.792-4.873 5.036v2.852H18v3.742h3.275v9.045a12.954 12.954 0 004.032 0V24.63h3.006"
            />
        </g>
    </svg>
);
