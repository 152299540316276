import React from 'react';
import { injectIntl } from "../../../view/intl/index";
import NoPages from './NoPages';
import styles from './Backup.css';

export default injectIntl(class DesktopPreview extends React.Component<any> {
    render() {
        if (this.props.hasPages) {
            return (
                <div className={styles['desktop-container']}>
                    <iframe
                        src={this.props.src}
                        allowFullScreen
                        // @ts-ignore
                        type="text/html"
                        width="100%"
                        height="100%"
                        frameBorder="0"
                    />
                </div>
            );
        } else {
            return (
                <div style={{ flex: 1 }}>
                    <NoPages />
                </div>
            );
        }
    }
});
