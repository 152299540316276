import React from 'react';
import makeAppStandardErrorDialog from "../../../App/dialogs/makeAppStandardErrorDialog";
import { PAGE_TREE_PAGE_DELETE_CONFIRM } from "../actionTypes";
import { WarningConfirmationDialog } from '../../../../view/common/dialogs/WarningConfirmationDialog';
import { Msg } from '../../../../view/intl';
import VerticalSpacer from "../../../../view/common/VerticalSpacer";

type Props = {
    dispatch: Dispatch;
};

export default makeAppStandardErrorDialog(({ dispatch }: Props) => (
    <WarningConfirmationDialog
        title="msg: deleteSectionLink {Delete section link?}"
        onConfirm={() => dispatch({ type: PAGE_TREE_PAGE_DELETE_CONFIRM })}
        dispatch={dispatch}
    >
        <VerticalSpacer y={10} />
        <p>
            <Msg k="deleteSectionLinkInfo">You are about to delete a section link from your site menu.</Msg>
        </p>
        <VerticalSpacer y={15} />
        <p>
            <Msg k="confirmDeleteSectionLink">Are you sure you want to delete the section link?</Msg>
        </p>
    </WarningConfirmationDialog>
));
