/* eslint-disable react/jsx-fragments */
/* eslint-disable max-len */
import React from 'react';

import { formatMessage } from '../../../../view/intl/CurrentLocale';
import { PHONE_KIND } from './kind';
import { ComponentNames } from '../../constants';
import PropertiesCheckBox from '../../../../view/common/CheckBox/PropertiesCheckBox';
import VerticalSpacer from '../../../../view/common/VerticalSpacer';
import InputField from '../../../../view/common/Input/InputField';
import styles from '../textLike.css';
import { phoneNumberReducer } from '../../../App/epics/globalVariablesEpic';
import { getPhonePlaceholder } from "../placeholders";
import { textLikeWorkspaceConfigFactory } from "../factories/textLikeWorkspaceConfigFactory";
import { phoneComponentView } from "./phoneComponentView";
import { computeComponentDependenciesForPreviewForPhone } from "./computeComponentDependenciesForPreviewForPhone";
import { getPlaceholderTextForPhone } from "./getPlaceholderTextForPhone";

const { config: phoneComponentConfig } = textLikeWorkspaceConfigFactory({
    View: phoneComponentView,
    label: ComponentNames[PHONE_KIND],
    kind: PHONE_KIND,
    dependsOn: {
        phoneNumber: phoneNumberReducer
    },
    isValueSet: deps => !!deps.phoneNumber,
    reducer: (state = {}) => {
        return state;
    },
    pp: {
        hideCapitalizationOptionForValue: true,
        showLineSpacingOptionForSingleLineMode: false,
        mainPageIntroText: 'msg: phone.pp.mainPageIntroText {This component needs a phone number stored in website settings.}',
        mainPageFooterText: 'msg: phone.pp.mainPageFooterText {You will get more styling options as soon as you have added a number.}',
        addValueBtnLabel: 'msg: phone.pp.addValueBtn {Add phone number}',
        updateValueBtnLabel: 'msg: phone.pp.updateValueBtn {Update phone number}',
        valueStyleLabel: 'msg: common.phoneNumber {Phone number}',
        mainPageRender: props => <React.Fragment>
            <PropertiesCheckBox
                containerClassName={styles.checkboxOverride}
                isChecked={props.component.generic.showCustomTitleFirst}
                label="msg: textlike.showCustomTitleFirst {Show custom title first}"
                onClick={() => props.dispatch({ type: props.TEXT_LIKE_SHOW_CUSTOM_TITLE_FIRST_TOGGLE })}
            />
            {
                props.component.generic.showCustomTitleFirst &&
                <React.Fragment>
                    <VerticalSpacer y={5} />
                    <InputField
                        value={props.component.generic.customPrefixText}
                        placeholder="msg: phone.pp.phonePrefixPlaceholder {e.g. Phone}"
                        onChange={payload => props.dispatch({ type: props.TEXT_LIKE_CUSTOM_PREFIX_TEXT_CHANGED, payload })}
                        maxLength={100}
                    />
                    <VerticalSpacer y={2} />
                    <PropertiesCheckBox
                        containerClassName={styles.checkboxOverride}
                        isChecked={!props.component.generic.showOnOneLine}
                        label="msg: phone.pp.showOnMultipleLines {Show on multiple lines}"
                        onClick={() => props.dispatch({ type: props.TEXT_LIKE_SHOW_ON_ONE_LINE_TOGGLE })}
                    />
                </React.Fragment>
            }
        </React.Fragment>
    },
    mcta: {
        changeValueLabel: 'msg: common.changePhone {Change phone number}',
        addValueLabel: 'msg: common.addPhone {Add phone number}',
    },
    getPrefixInitialValue: () => formatMessage({
        id: 'common.phone',
        defaultMessage: 'Phone'
    }),
    computeComponentDependenciesForPreview: computeComponentDependenciesForPreviewForPhone,
    defaultLineSpacing: 0.5,
    sequenceNumberUnderMore: 100,
    getStateAdjustmentsOnComponentAdd: () => {
        return {
            placeholderText: getPhonePlaceholder()
        };
    },
    getPlaceholderText: getPlaceholderTextForPhone
});

export {
    phoneComponentConfig,
};

