import React from "react";
import cx from "classnames";
import styles from "./reflexbox.css";

type BoxProps = {
    style?: Record<string, any>;
    className?: string;
    align?: "flex-start" | "center" | "flex-end" | "stretch" | "baseline" | "right";
    justify?: "flex-start" | "center" | "space-around" | "space-between" | "flex-end";
    children: React.ReactNode | React.ReactNode[];
    onClick?: React.MouseEventHandler<HTMLDivElement>;
    id ?: string;
};

type FlexProps = BoxProps & {
    column?: boolean;
    wrap?: boolean;
    onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
};

const _Box = <T extends BoxProps>(props: T, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { align, justify, className, children, ...restProps } = props,
        classNames = [className];

    if (align) {
        classNames.push(styles[`align-${align}`]);
    }
    if (justify) {
        classNames.push(styles[`justify-${justify}`]);
    }

    return (
        <div ref={ref} style={props.style} className={cx(classNames)} {...restProps}>
            {children}
        </div>
    );
};

export const Box = React.forwardRef(_Box);

const _Flex = <T extends FlexProps>(props: T, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { column = false, wrap = false, className, children, ...restProps } = props,
        classNames = [className, styles.flex];

    classNames.push(column ? styles["direction-column"] : styles["direction-row"]);
    classNames.push(wrap ? styles.wrap : styles.nowrap);

    return (
        <Box ref={ref} className={cx(classNames)} {...restProps}>
            {children}
        </Box>
    );
};

export const Flex = React.forwardRef(_Flex);
