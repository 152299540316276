import React from 'react';
import { MVEMaxWidth, WidgetsPinterestConfig } from '../constants';
import { getMVEHiddenElementData } from '../mobileEditorConfig';
import { calcRenderProps } from './calcRenderProps';
import { componentMainActions } from './componentMainActions';
import { configurationDialogId } from './configurationDialog';
import { WidgetsPinterestKind } from './kind';
import { propertiesPanel } from './propertiesPanel/index';
import { reducer } from './reducer';
import { PinterestView } from './view/index';
import { PinterestWorkspaceView } from './view/workspace';

const WidgetsPinterest = {
    kind: WidgetsPinterestKind,
    label: WidgetsPinterestConfig.title,
    shortcutIconName: 'widgetsPinterest',
    view: PinterestView,
    workspace: PinterestWorkspaceView,
    requireConfigurationOnDrop: () => true,
    configurationDialog: configurationDialogId,
    calcRenderProps,
    componentMainActions,
    propertiesPanel,
    reducer,
    mobileEditorConfig: {
        kind: WidgetsPinterestKind,
        calcProps: calcRenderProps,
        view: (props) => (<PinterestView {...props} width={MVEMaxWidth} />),
        getHiddenElementData: getMVEHiddenElementData(WidgetsPinterestConfig),
    },
};

export { WidgetsPinterest };
