import {
    IMAGE_REPLACE_IMAGE,
    IMAGE_SELECTED_FOR_REPLACE,
    IMAGE_SET_IMAGE
} from "../actionTypes";
import {
    emptyImageSrcForSelectedCmpAction, replaceImageForSelectedCmpAction,
    replaceMissingAssetsAction, updateImageOnReplaceForwardToCmpAction,
    imageCmpConfigurationCompleteAction
} from "./actionCreators";

const setOrReplaceImageMiddleware = (store: Store) => (next: Dispatch) => (action: AnyAction) => {
    if (action.type === IMAGE_REPLACE_IMAGE) {
        store.dispatch(replaceImageForSelectedCmpAction());
    }

    if (action.type === IMAGE_SELECTED_FOR_REPLACE) {
        const imageResource = action.payload.asset;
        store.dispatch(emptyImageSrcForSelectedCmpAction());
        store.dispatch(updateImageOnReplaceForwardToCmpAction(imageResource));
        store.dispatch(replaceMissingAssetsAction(imageResource));
    }

    if (action.type === IMAGE_SET_IMAGE) {
        const { payload: { asset } } = action;
        store.dispatch(imageCmpConfigurationCompleteAction(asset));
    }

    return next(action);
};

export {
    setOrReplaceImageMiddleware
};
