import { SIZE_TYPES } from "../../../constants";

export const
    MaxCharactersForButtonText = 350,
    MIN_DIMENSIONS = { width: 20, height: 20 },
    MCTA_RESIZE_OPTIONS = {
        [SIZE_TYPES.SIZE_SMALL]: { fontSize: 14, width: 120, height: 40 },
        [SIZE_TYPES.SIZE_MEDIUM]: { fontSize: 16, width: 150, height: 50 },
        [SIZE_TYPES.SIZE_LARGE]: { fontSize: 22, width: 250, height: 60 }
    };
