/* eslint-disable no-console */

import { WINDOW_DELETE_DOM_BY_ID, WINDOW_DELETE_GLOBAL_OBJECTS, WINDOW_REMOVE_SCRIPT_TAGS } from "./window/actionTypes";
import { FACEBOOK_API_BASE_URL } from "../../components/oneweb/Facebook/constants";
import { LOCALE_CHANGED } from "../../components/presentational/LocaleList/actionTypes";
import { LinkedInAPI, TwitterAPI } from "../../components/oneweb/ShareButtons/constants";
import { REDO, UNDO } from "../../epics/undoManager/actionTypes";
import templateValueActionType from '../../components/oneweb/Template/epics/template/valueActionType';
import { getEpicStateFromAppStateSelector } from "../modules/selectors";

function dispatchResetScriptsActions(dispatch) {
    dispatch({
        type: WINDOW_REMOVE_SCRIPT_TAGS,
        payload: [FACEBOOK_API_BASE_URL, LinkedInAPI, TwitterAPI]
    });

    dispatch({
        type: WINDOW_DELETE_DOM_BY_ID,
        payload: ['fb-root']
    });

    dispatch({
        type: WINDOW_DELETE_GLOBAL_OBJECTS,
        payload: ['FB', 'IN']
    });
}

function getTemplateLocaleFromAppState(appState) {
    return getEpicStateFromAppStateSelector(appState, templateValueActionType).locale;
}

let templateLocaleBefore = null;

export default (store: Store) => (next: Dispatch) => (action: Action) => {
    if (action.type === LOCALE_CHANGED) {
        dispatchResetScriptsActions(store.dispatch);
        const result = next(action);
        templateLocaleBefore = getTemplateLocaleFromAppState(store.getState());
        return result;
    } else if (action.type === UNDO || action.type === REDO) {
        const
            currentTemplateLocale = getTemplateLocaleFromAppState(store.getState());

        if (templateLocaleBefore && templateLocaleBefore !== currentTemplateLocale) {
            dispatchResetScriptsActions(store.dispatch);
            templateLocaleBefore = currentTemplateLocale;
        }
    }

    return next(action);
};
