import u from 'updeep';

import type { PageInfoDialogInProps, PageInfoDialogState, PageInfoDialogDependencies } from "../flowTypes";
import defaultState from "./defaultState";
import { PageInfoTab } from "../../../constants";
import { defineExcludeFromIndexing } from "../../../utils";
import { SeoPageTitleInTabOptions } from "../view/constants";
import { getDAL } from "../../../../../../../dal";
import { getDefaultNonHomePageNameInBrowserTab } from "../utils";

export default (props: PageInfoDialogInProps, dependencies: PageInfoDialogDependencies): PageInfoDialogState => {
    const
        { pageRefId, tabId = PageInfoTab.NAMES } = props,
        { site, currentTemplate, websiteTitle } = dependencies,
        page = site.getPageRefById(pageRefId),
        isHomePage = site.isHomePageId(page.pageId);

    const
        defaultHomePageNameInBrowserTab = websiteTitle || getDAL().getDomain(),
        defaultNonHomePageNameInBrowserTab = getDefaultNonHomePageNameInBrowserTab(page.name, getDAL().getDomain(), websiteTitle),
        pageNameOptionInBrowserTab = page.customTitle ? SeoPageTitleInTabOptions.CUSTOM : SeoPageTitleInTabOptions.DEFAULT;

    const pageTitle = isHomePage ? defaultHomePageNameInBrowserTab : defaultNonHomePageNameInBrowserTab;

    const state = u({
        activeTabId: tabId,
        // assume values on server are valid
        [PageInfoTab.NAMES]: {
            name: {
                value: page.name,
                isValid: true,
            },
            url: {
                value: page.url,
                isValid: true
            },
            defaultNonHomePageNameInBrowserTab,
            defaultHomePageNameInBrowserTab,
        },
        [PageInfoTab.SEO]: {
            excludeFromIndexing: defineExcludeFromIndexing(page.robots),
            description: page.description,
            title: {
                value: pageTitle,
                isValid: true,
            },
            customTitle: {
                value: page.customTitle || pageTitle,
                isValid: true,
            },
            defaultNonHomePageNameInBrowserTab,
            defaultHomePageNameInBrowserTab,
            pageNameOptionInBrowserTab,
        },
        [PageInfoTab.SOCIAL_SHARE]: {
            socialShareAsset: page.socialShareAsset,
            defaultNonHomePageNameInBrowserTab,
            defaultHomePageNameInBrowserTab,
        },
        [PageInfoTab.VISIBILITY]: {
            isHidden: page.hidden,
            isPublished: page.public
        },
        [PageInfoTab.TEMPLATE]: {
            selectedTemplate: currentTemplate
        },
        isHomePage,
        currentTemplateId: currentTemplate.id
    }, defaultState) as PageInfoDialogState;

    return state;
};
