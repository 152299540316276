export const
    LOGIN_FROM_DEMO_ACTION = 'LOGIN_FROM_DEMO_ACTION',
    loginFromDemoAction = () => ({
        type: LOGIN_FROM_DEMO_ACTION,
    });

export const
    DEMO_BUY_BUTTON_CLICK_ACTION = 'DEMO_BUY_BUTTON_CLICK_ACTION',
    demoBuyButtonClickAction = () => ({
        type: DEMO_BUY_BUTTON_CLICK_ACTION,
    });

export const
    DEMO_TRIAL_ENDED_SELECT_PLAN_ACTION = 'DEMO_TRIAL_ENDED_SELECT_PLAN_ACTION',
    demoTrialEndedSelectPlanAction = () => ({
        type: DEMO_TRIAL_ENDED_SELECT_PLAN_ACTION
    });

export const
    TOGGLE_DEMO_BAR_ACTION = 'TOGGLE_DEMO_BAR_ACTION',
    toggleDemoBarAction = () => ({
        type: TOGGLE_DEMO_BAR_ACTION,
    });

export const
    EXPAND_DEMO_BAR_ACTION = 'EXPAND_DEMO_BAR_ACTION',
    expandDemoBarAction = () => ({
        type: EXPAND_DEMO_BAR_ACTION,
    });

export const
    COLLAPSE_DEMO_BAR_ACTION = 'COLLAPSE_DEMO_BAR_ACTION',
    collapseDemoBarAction = () => ({
        type: COLLAPSE_DEMO_BAR_ACTION,
    });

export const
    HIDE_DEMO_BAR_ACTION = 'HIDE_DEMO_BAR_ACTION',
    hideDemoBarAction = () => ({
        type: HIDE_DEMO_BAR_ACTION,
    });

export const
    SET_TRIAL_REMAINING_DAYS_ACTION = 'SET_TRIAL_REMAINING_DAYS_ACTION',
    setTrialRemainingDaysAction = (trialDaysRemaining: number) => ({
        type: SET_TRIAL_REMAINING_DAYS_ACTION,
        payload: {
            trialDaysRemaining
        }
    });

export const
    SET_TRIAL_USER_SUB_ID_ACTION = 'SET_TRIAL_USER_SUB_ID_ACTION',
    setTrialUserSubIdAction = (userSubId: string) => ({
        type: SET_TRIAL_USER_SUB_ID_ACTION,
        payload: {
            userSubId
        }
    });

export const
    SET_TRIAL_CONTROL_PANEL_USER_ACTION = 'SET_TRIAL_CONTROL_PANEL_USER_ACTION',
    setTrialControlPanelUserAction = (controlPanelUser: boolean) => ({
        type: SET_TRIAL_CONTROL_PANEL_USER_ACTION,
        payload: {
            controlPanelUser
        }
    });

export const
    SET_TRIAL_USER_HASHED_TOKEN_ACTION = 'SET_TRIAL_USER_HASHED_TOKEN_ACTION',
    setTrialUserHashedTokenAction = (userHashedToken: string) => ({
        type: SET_TRIAL_USER_HASHED_TOKEN_ACTION,
        payload: {
            userHashedToken
        }
    });

export const
    SET_TRIAL_USER_EMAIL_VERIFIED_STATUS_ACTION = 'SET_TRIAL_USER_EMAIL_VERIFIED_STATUS_ACTION',
    setTrialEmailVerifiedStatusAction = (trialUserEmailVerified: boolean) => ({
        type: SET_TRIAL_USER_EMAIL_VERIFIED_STATUS_ACTION,
        payload: {
            trialUserEmailVerified
        }
    });

export const
    SET_TRIAL_USER_EMAIL_ACTION = 'SET_TRIAL_USER_EMAIL_ACTION',
    setTrialUserEmailAction = (trialUserEmail: string) => ({
        type: SET_TRIAL_USER_EMAIL_ACTION,
        payload: {
            trialUserEmail
        }
    });

export const
    SET_TRIAL_DOMAIN_NAME_ACTION = 'SET_TRIAL_DOMAIN_NAME_ACTION',
    setTrialDomainNameAction = (trialDomainName: string) => ({
        type: SET_TRIAL_DOMAIN_NAME_ACTION,
        payload: {
            trialDomainName
        }
    });

export const
    DEMO_BUY_BUTTON_TRACK_ACTION = 'DEMO_BUY_BUTTON_TRACK_ACTION',
    demoBuyGoalsTrackerAction = () => ({
        type: DEMO_BUY_BUTTON_TRACK_ACTION
    });

export const
    INITIATE_DEMO_DOMAIN_AVAILABILITY_ACTION = 'INITIATE_DEMO_DOMAIN_AVAILABILITY_ACTION',
    initiateDemoDomainAvailabilityAction = () => ({
        type: INITIATE_DEMO_DOMAIN_AVAILABILITY_ACTION
    });

export const
    SET_TRIAL_DOMAIN_AVAILABLE_ACTION = 'SET_TRIAL_DOMAIN_AVAILABLE_ACTION',
    setTrialDomainAvailableAction = (trialDomainNameForPurchase: string) => ({
        type: SET_TRIAL_DOMAIN_AVAILABLE_ACTION,
        payload: {
            trialDomainNameForPurchase
        }
    });

export const
    CLEAR_AVAILABLE_TRIAL_DOMAIN_ACTION = 'CLEAR_AVAILABLE_TRIAL_DOMAIN_ACTION',
    clearAvailableTrialDomainAction = () => ({
        type: CLEAR_AVAILABLE_TRIAL_DOMAIN_ACTION,
    });

export const
    PUBLISH_VIDEO_TUTORIAL_PLAYED = 'PUBLISH_VIDEO_TUTORIAL_PLAYED',
    publishVideoTutorialPlayedAction = () => ({
        type: PUBLISH_VIDEO_TUTORIAL_PLAYED,
    });
