import * as R from 'ramda';
import { MaxCharactersForButtonText } from "../constants";
import type { ButtonComponent } from "../flowTypes";

type ChangeTextAndWidthProps = {
    payload: {
        text: string,
        fontSize: number,
        width: number
    }
}

export default (
    component: ButtonComponent, { payload: { width, text, fontSize } }: ChangeTextAndWidthProps
): ButtonComponent => {
    let newText = text.substring(0, MaxCharactersForButtonText),
        btnWidth = width > component.width ? width : component.width;

    return R.unless(
        R.allPass([
            R.pathSatisfies(
                R.ifElse(
                    R.isNil,
                    R.always(R.isNil(fontSize)),
                    R.equals(fontSize)
                ),
                ['style', 'text', 'size']
            ),
            R.pathEq(['text'], newText),
            R.pathEq(['width'], btnWidth)
        ]),
        R.evolve({
            style: { text: { size: R.always(fontSize) } },
            text: R.always(newText),
            width: R.always(btnWidth),
        })
    )(component);
};
