import * as Actions from './actionTypes';
import CALL_API from "../../../../redux/middleware/api/CALL_API";
import type { ApiAction } from "../../../../redux/middleware/api/flowTypes";
import type { SiteSettings } from "./flowTypes";

const
    loadSiteSettings = (): ApiAction => {
        return {
            [CALL_API]: {
                types: [
                    Actions.LOAD_SITE_SETTINGS_REQUEST,
                    Actions.LOAD_SITE_SETTINGS_SUCCESS,
                    Actions.LOAD_SITE_SETTINGS_FAILURE
                ],
                endpoint: 'loadSiteSettings'
            }
        };
    },
    createDefaultSiteSettings = (siteSettingsDefaultState: SiteSettings): ApiAction => {
        return {
            [CALL_API]: {
                types: [
                    Actions.CREATE_DEFAULT_SITE_SETTINGS_REQUEST,
                    Actions.CREATE_DEFAULT_SITE_SETTINGS_SUCCESS,
                    Actions.CREATE_DEFAULT_SITE_SETTINGS_FAILURE
                ],
                endpoint: "createDefaultSiteSettings",
                endpointParams: siteSettingsDefaultState
            }
        };
    };

export {
    loadSiteSettings,
    createDefaultSiteSettings
};
