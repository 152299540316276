import React from "react";
import { injectIntl } from '../../../../../view/intl/index';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import { GoogleMapsAutoCompleteInput } from '../../../../inputControls/input/view/GoogleMapsAutoCompleteInput';
import { GoogleMapsView } from "./index";
import WidgetStyles from '../../view/Widgets.css';
import { type GoogleMapsWidgetProps } from '../types';

const GoogleMapsWidget = injectIntl((props: GoogleMapsWidgetProps) => {
    const {
        intl,
        locale,
        zoom, setZoom,
        addressText, setAddressText, setAddressUrl,
        addressLocation, setAddressLocation, addressUrl,
        stretch,
    } = props;

    const onPlaceChanged = (payload) => {
        setAddressLocation(payload.addressLocation);
        setAddressText(payload.address);
        setAddressUrl(payload.addressUrl);
    };

    const onChange = (payload: string) => {
        setAddressText(payload);
        setAddressLocation(null);
        setAddressUrl('');
        const pacContainer = $('.pac-container');
        pacContainer.css("margin-top", "10px");
    };

    return (
        <React.Fragment>
            { /* @ts-ignore */ }
            <GoogleMapsAutoCompleteInput
                value={addressText}
                onChange={payload => onChange(payload)}
                onPlaceChanged={payload => onPlaceChanged(payload)}
                usePropsValue
                placeholder={intl.msgJoint("msg: common.address {Address}")}
                style={{
                    padding: "15px 20px",
                    border: "1px solid #BBBBBB",
                    borderRadius: "100px",
                    fontSize: "16px",
                    height: "50px",
                }}
            />
            <VerticalSpacer y={28} />
            <GoogleMapsView
                locale={locale}
                zoom={zoom}
                setZoom={setZoom}
                addressText={addressText}
                addressLocation={addressLocation}
                addressUrl={addressUrl}
                onPlaceChanged={onPlaceChanged}
                height={WidgetStyles.googleElementHeight}
                width={WidgetStyles.googleElementWidth}
                stretch={stretch}
            />
        </React.Fragment>
    );
});

export { GoogleMapsWidget };
