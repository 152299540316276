import { complement, find, findLast, partial, partialRight } from 'ramda';
import { dom } from 'tinymce';
import {
    STYLES_APPLICABLE_ON_LIST_ITEMS,
    TEXT_GLOBAL_STYLE_RULES,
    VALID_CONTENT_BLOCK_TAGS
} from '../../../../../../oneweb/Text/constants';
import { getMatchingClassNames, isEmptyNode, isTextNode } from '../../../../../../../utils/dom';

export const isBookmarkNode = dom.BookmarkManager.isBookmarkNode;

export const isValidContentBlock = (node: Node): boolean => {
    return !!(node.nodeName && VALID_CONTENT_BLOCK_TAGS.includes(node.nodeName));
};

const isNotEmptyNode = complement(isEmptyNode);

export const isValidFirstChild = (node: Node): boolean => {
    if (node.parentNode && node === node.parentNode.firstChild) return true;

    let firstNonEmptyChildOfParent;

    if (node.parentNode) {
        const { childNodes } = node.parentNode;
        firstNonEmptyChildOfParent = childNodes && find(partialRight(isNotEmptyNode, [true]), childNodes);
    }
    return node === firstNonEmptyChildOfParent;
};

export const isValidLastChild = (node: Node): boolean => {
    if (node.parentNode && node === node.parentNode.lastChild) return true;

    let lastNonEmptyChildOfParent;

    if (node.parentNode) {
        const { childNodes } = node.parentNode;
        lastNonEmptyChildOfParent = findLast(partialRight(isNotEmptyNode, [true]), childNodes);
    }
    return node === lastNonEmptyChildOfParent;
};

export const getClosestContentBlock = (node: HTMLElement, shouldCheckFirstChild: boolean = false): HTMLElement | null | undefined => {
    let selectedNode = node,
        isContentBlock = isValidContentBlock(selectedNode);

    while (
        !isContentBlock && selectedNode.parentNode && selectedNode.parentNode instanceof HTMLElement &&
        (!shouldCheckFirstChild || isValidFirstChild(selectedNode))
    ) {
        selectedNode = selectedNode.parentNode;
        isContentBlock = isValidContentBlock(selectedNode);
    }

    if (isContentBlock) {
        return selectedNode;
    }

    return null;
};

export const unwrapNode = (node: HTMLElement) => {
    const parentNode = node.parentNode;
    const referenceNode = node.nextSibling;

    if (parentNode) {
        while (node.firstChild) {
            parentNode.insertBefore(node.firstChild, referenceNode);
        }

        parentNode.removeChild(node);
    }
};

const getMatchingClass = (matcher: RegExp, node: HTMLElement): string | null | undefined => {
    if (node.nodeType !== window.Node.ELEMENT_NODE) return null;

    const matchResult = getMatchingClassNames(node, matcher);
    return matchResult ? matchResult[0] : null;
};

export const getTextGlobalStyle = partial(getMatchingClass, [/text(?:normal|heading\d)/]);
export const getLinkGlobalStyle = partial(getMatchingClass, [/link\d/]);

export const getGlobalStyle = (node: HTMLElement): string => {
    return getTextGlobalStyle(node) || getLinkGlobalStyle(node);
};

export const hasNonEmptyTextNodes = (element: HTMLElement) => (
    Array.from(element.childNodes).some(childNode => (
        isTextNode(childNode) && !isEmptyNode(childNode, true)
    ))
);

// eslint-disable-next-line max-len
export const copyListStylesFromChildren = (element: HTMLElement | null | undefined, originalElement: HTMLElement | null | undefined = element) => {
    if (!element || element.children.length !== 1 || hasNonEmptyTextNodes(element)) return;

    const childNode = element.children[0] as HTMLElement;
    const globalStyle = getTextGlobalStyle(childNode);
    if (globalStyle && originalElement) {
        originalElement.className = globalStyle; // eslint-disable-line
        const originalElementStyle = originalElement.style;

        TEXT_GLOBAL_STYLE_RULES.forEach(style => {
            originalElementStyle.removeProperty(style);
        });
    }

    const styles: Record<string, any> = childNode.style;
    const originalStyles: Record<string, any> = (originalElement && originalElement.style) || {};

    STYLES_APPLICABLE_ON_LIST_ITEMS.forEach(style => {
        if (styles[style]) {
            originalStyles[style] = styles[style]; // eslint-disable-line
        }
    });

    copyListStylesFromChildren(childNode, originalElement);
};

export const DEFAULT_GLOBAL_CLASS = 'textnormal';
export const GLOBAL_STYLE_CLASSES = ['textnormal', 'textheading1', 'textheading2', 'textheading3'];
export const GLOBAL_STYLE_SELECTOR = `.${GLOBAL_STYLE_CLASSES.join(', .')}`;
