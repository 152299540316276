import React from "react";
import cx from 'classnames';
import styles from './shortcut.css';
import { Intl, injectIntl } from "../../../intl/index";
import { ucFirst } from "../../../../../utils/string";

type Props = {
    panelExpanded: boolean,
    intl: Intl,
    disabled?: boolean,
    fill?: string,
    className?: string,
    containerStyleClassName?: string
}

export default ({ label, shortcutIconName }: { label: string, shortcutIconName: string }) => injectIntl(
    ({ panelExpanded, disabled, fill, className, intl, containerStyleClassName }: Props) => (
        <div className={cx(styles.container, containerStyleClassName)}>
            <span
                className={cx(
                    styles.icon,
                    styles[shortcutIconName],
                    className,
                    { [styles.disabled]: disabled },
                    fill && styles[`fill${ucFirst(fill)}`]
                )}
            />
            {panelExpanded && <span className={styles.label}>{intl.msgJoint(label)}</span>}
        </div>
    )
);
