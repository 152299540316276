// TODO: annotate state / action; after introducing test fixtures

import * as borderSetter from "../../setters/borderSetter";
import * as paddingSetter from "../../setters/paddingSetter";
import * as backgroundSetter from "../../setters/backgroundSetter";
import * as path from "../../mappers/path";
import { getByPath, setToPath } from "../../utils/ramdaEx";
import { DEFAULT_BORDER_STYLE, DefaultBorderData } from "../presentational/BorderSettings/constants";

export const
    makeComponentStyleBorderStyleReducer = () => (state: any, action: any) => {
        const { payload: borderStyle } = action;
        let borderData = getByPath(path.styleBorder, state) || {};
        const oldBorderStyle = borderData[path.style];

        if (!oldBorderStyle && borderStyle !== DEFAULT_BORDER_STYLE) {
            // in case style changed from NONE to something populate defaults / preserve old NOT EMPTY ones: color, widths
            const existingBorderData = Object.keys(borderData).reduce((data, prop) => {
                return (borderData[prop] && { ...data, [prop]: borderData[prop] }) || data;
            }, {});
            borderData = { ...DefaultBorderData, ...existingBorderData };
        }
        borderData = borderSetter.setBorderStyle([], borderStyle, borderData);

        return { ...state, ...setToPath(path.styleBorder, borderData, state) };
    },
    makeComponentStyleBorderColorReducer = () => (state: any, { payload: { color } }: any) => ({
        ...state,
        ...borderSetter.setBorderColor(path.styleBorder, color, state)
    }),
    makeComponentStyleBorderOpacityReducer = () => (state: any, { payload: opacity }: any) => ({
        ...state,
        ...borderSetter.setBorderOpacity(path.styleBorder, opacity, state)
    }),
    makeComponentStyleBorderWidthReducer = () => (state: any, { payload: widths }: any) => ({
        ...state,
        ...borderSetter.setBorderWidths(path.styleBorder, widths.map(width => (width || 0)), state)
    }),
    makeComponentStyleBorderCornersReducer = () => (state: any, { payload: corners }: any) => ({
        ...state,
        ...borderSetter.setBorderCorners(path.styleBorder, corners.map(corner => (corner || 0)), state)
    }),
    makeComponentStylePaddingTopReducer = () => (state: any, { payload: paddingTop }: any) => ({
        ...state,
        ...paddingSetter.setPaddingTopStyle(path.stylePadding, paddingTop, state)
    }),
    makeComponentStylePaddingBottomReducer = () => (state: any, { payload: paddingBottom }: any) => ({
        ...state,
        ...paddingSetter.setPaddingBottomStyle(path.stylePadding, paddingBottom, state)
    }),
    makeComponentStylePaddingLeftReducer = () => (state: any, { payload: paddingLeft }: any) => ({
        ...state,
        ...paddingSetter.setPaddingLeftStyle(path.stylePadding, paddingLeft, state)
    }),
    makeComponentStylePaddingRightReducer = () => (state: any, { payload: paddingRight }: any) => ({
        ...state,
        ...paddingSetter.setPaddingRightStyle(path.stylePadding, paddingRight, state)
    }),
    makeComponentStyleBackgroundColorReducer = () => (state: any, { payload: { color } }: any) => ({
        ...state,
        ...backgroundSetter.setBackgroundSolidColor(path.styleBackground, color, state)
    }),
    makeComponentStyleBackgroundColorUnsetReducer = () => (state: any) => ({
        ...state,
        ...backgroundSetter.unsetBackgroundColor(path.styleBackground, state)
    }),
    makeComponentStyleBackgroundColorOpacityReducer = () => (state: any, { payload: opacity }: any) => {
        return ({
            ...state,
            ...backgroundSetter.setBackgroundOpacity(path.styleBackground, opacity, state)
        });
    };

