export const
    PRIMARY_PHONE = 'primary_phone',
    ADMINISTRATIVE_AREA_INVALID = 'administrative_area_invalid',
    ADMINISTRATIVE_AREA_REQUIRED = 'administrative_area_required',
    POSTAL_CODE = 'postal_code',
    LATLNG = 'latlng',
    ADDRESS = 'address',
    LOCALITY = 'locality',
    LOCALITY_REQUIRED = 'locality_required',
    LOCALITY_NOT_REQUIRED = 'locality_not_required',

    PROVINCE = 'province',
    STREET_ADDRESS = 'street_address';
