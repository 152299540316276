import { SCHEDULE_ACTION, CANCEL_SCHEDULED_ACTION } from "./actionTypes";
import type { ApiAction } from "../api/flowTypes";

type CreateScheduledActionParams = {
    actionToDispatch: Action | ApiAction | PlainAction,
    timeout: number
}

export const
    createScheduledAction = ({ actionToDispatch, timeout }: CreateScheduledActionParams): Action => ({
        type: SCHEDULE_ACTION,
        payload: { actionToDispatch, timeout }
    }),
    cancelScheduledAction = (actionToDispatch ?: PlainAction): Action => ({
        type: CANCEL_SCHEDULED_ACTION,
        payload: { actionToDispatch }
    });
