import React from "react";
export const SvgSnapchatCircleFillLight = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none" fillRule="evenodd">
            <circle cx={24} cy={24} r={24} fill="#ffffff" fillRule="nonzero" />
            <path
                fill="#3c3c3c"
                d="M24.282 13.5c.916 0 4.018.255 5.478 3.535.492 1.103.374 2.978.28 4.484l-.005.064c-.01.164-.02.321-.028.474a.848.848 0 00.371.093c.277-.013.598-.104.955-.271a1.02 1.02 0 01.423-.086c.16 0 .324.031.462.088h.001c.41.147.668.443.674.775.008.421-.368.786-1.119 1.083a5.292 5.292 0 01-.321.109c-.424.134-1.064.338-1.237.748-.09.212-.055.485.106.811a.368.368 0 01.01.023c.057.13 1.41 3.223 4.42 3.72a.46.46 0 01.384.48.612.612 0 01-.05.207c-.225.526-1.177.913-2.912 1.182-.058.078-.118.355-.154.523a7.3 7.3 0 01-.127.515.5.5 0 01-.512.384h-.025c-.123 0-.291-.021-.5-.062a5.872 5.872 0 00-1.18-.126c-.275 0-.56.024-.846.072-.56.093-1.035.43-1.586.82-.788.557-1.681 1.19-3.039 1.19-.06 0-.118-.002-.162-.004a1.52 1.52 0 01-.112.004c-1.357 0-2.25-.632-3.038-1.19-.55-.39-1.026-.727-1.586-.82a5.184 5.184 0 00-.846-.072c-.495 0-.89.078-1.18.134a2.79 2.79 0 01-.5.07c-.349 0-.485-.213-.537-.39a7.293 7.293 0 01-.127-.52c-.037-.17-.097-.447-.155-.526-1.734-.27-2.687-.656-2.912-1.185a.62.62 0 01-.05-.206.46.46 0 01.385-.48c3.008-.496 4.363-3.589 4.42-3.72.002-.008.006-.015.01-.022.16-.327.196-.6.106-.812-.174-.41-.814-.614-1.238-.748a5.106 5.106 0 01-.32-.11c-1.026-.405-1.169-.87-1.108-1.19.084-.444.624-.741 1.076-.741a.82.82 0 01.35.072c.386.18.729.272 1.019.272a.88.88 0 00.43-.094l-.033-.54c-.094-1.504-.212-3.377.28-4.48 1.46-3.278 4.554-3.533 5.467-3.533l.383-.004h.055z"
            />
        </g>
    </svg>
);
