/* eslint-disable max-len */

import React from 'react';

import currentPageNameEpic from '../App/epics/currentPageName/index';

import { injectIntl } from '../../view/intl/index';
import Scrollbar from '../../view/common/Scrollbar/index';
import VerticalSpacer from '../../view/common/VerticalSpacer';
import { SecondaryButton } from '../../view/common/Button/index';
import getCenteredDialogConfig from '../DialogManager/getCenteredDialogConfig';
import { StripTypes, Dialog, DialogTitleBox, DialogBody, DialogFooter } from '../../view/common/dialogs/baseDialog/index'; // eslint-disable-line max-len

import * as actionTypes from './actionTypes';
import missingAssetsEpic from './epic/index';

import type { Intl } from '../../view/intl/injectIntl';
import type { MissingAssetEpicState } from './epic/flowTypes';
import type { BrowserDimensions } from '../../redux/modules/children/dimensions/index';

import styles from './MissingAssets.css';
import { decodeWebspaceUri } from "../../../dal/utils/webspaceUriTransformers";
import warningStyles from '../../view/common/dialogs/WarningDialog/WarningDialog.css';
import { TRIAL_STORAGE_PROTOCOL } from "../../../../trial/services/storage/constants";

type MissingAssetDialogProps = {
    missingAssetEpic: MissingAssetEpicState,
    currentPageName: string,
    dispatch: Dispatch,
    intl: Intl
}

const getDialogFooter = (dispatch, showUploadButtons, saveButtonDisabled) => {
    if (showUploadButtons) {
        return (
            <DialogFooter
                noTopBorder
                className={warningStyles.warningDialogFooter}
                mctaText="msg: common.missingAssets.dialog.save {Save}"
                mctaHandler={() => dispatch({ type: actionTypes.MISSING_ASSET_DIALOG_SAVE_BUTTON_CLICKED })}
                tctaText="msg: common.cancel {Cancel}"
                tctaHandler={() => dispatch({ type: actionTypes.MISSING_ASSET_DIALOG_CLOSE_DIALOG })}
                disabled={saveButtonDisabled}
            />
        );
    }

    return (
        <DialogFooter
            noTopBorder
            className={warningStyles.warningDialogFooter}
            mctaText="msg: common.missingAssets.dialog.replaceEachFile {Replace each file}"
            mctaHandler={() => dispatch({ type: actionTypes.MISSING_ASSET_DIALOG_REPLACE_IMAGES_BUTTON_CLICKED })}
            sctaText="msg: common.missingAssets.dialog.deleteAllMissingFiles {Delete all missing files}"
            sctaHandler={() => dispatch({ type: actionTypes.MISSING_ASSET_DIALOG_DELETE_ASSETS_BUTTON_CLICKED })}
            tctaText="msg: common.cancel {Cancel}"
            tctaHandler={() => dispatch({ type: actionTypes.MISSING_ASSET_DIALOG_CLOSE_DIALOG })}
            tctaButtonClassName={styles.tctaButton}
        />
    );
};

const
    width = 750,
    height = 450,
    MissingAssetsDialog = injectIntl(
        ({
            missingAssetEpic: { missingAssetUrls, showUploadButtons, saveButtonDisabled },
            currentPageName,
            dispatch,
            intl
        }: MissingAssetDialogProps) => (
            <Dialog stripType={StripTypes.WARNING} showCloseBtn={false}>
                <DialogTitleBox
                    title="msg: common.missingAssets.dialog.title {Missing files}"
                    className={warningStyles.warningDialogTitle}
                    iconClass={warningStyles.iconWarning}
                />
                <DialogBody>
                    <Scrollbar height="248px">
                        {
                            missingAssetUrls.map((assetUrl, index) => {
                                const
                                    content = (
                                        <div key={index}>
                                            <div>{decodeWebspaceUri(assetUrl.replace(TRIAL_STORAGE_PROTOCOL, ''))}</div>
                                            <div>{intl.msgJoint("msg: common.missingAssets.dialog.affectedPages {Affected pages}")}: {currentPageName}</div>
                                        </div>
                                    );

                                if (!showUploadButtons) {
                                    return content;
                                }

                                return (
                                    <div key={index}>
                                        {content}
                                        <SecondaryButton
                                            className={styles.replaceImageButton}
                                            onClick={() => dispatch({
                                                type: actionTypes.MISSING_ASSET_DIALOG_UPLOAD_BUTTON_CLICKED,
                                                payload: assetUrl
                                            })}
                                        >
                                            {intl.msgJoint("msg: common.missingAssets.dialog.replaceFile {Replace file}")}
                                        </SecondaryButton>
                                    </div>
                                );
                            })
                        }
                    </Scrollbar>
                    <VerticalSpacer y={10} />
                    <div style={{ fontSize: 11 }}>
                        {intl.msgJoint("msg: common.missingAssets.dialog.message  {They have most likely been deleted or moved to another folder. You can choose to delete all missing files from the components and then insert them again, or replace each file with another one.}")}
                    </div>
                </DialogBody>
                {getDialogFooter(dispatch, showUploadButtons, saveButtonDisabled)}
            </Dialog>
        )
    ),
    config = {
        ...getCenteredDialogConfig(width, height, MissingAssetsDialog),
        updateConfFn: (config: Record<string, any>, dimensions: BrowserDimensions, props: Record<string, any>) =>
            ({ ...config, props: { ...config.props, ...props } }),
        dependsOn: {
            missingAssetEpic: missingAssetsEpic.reducer,
            currentPageName: currentPageNameEpic.reducer
        }
    };

export default config;
