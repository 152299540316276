import React from "react";

import type { TextLikeViewType, CalcRenderPropsResult } from "../types";
import { textLikeComponentViewFactory } from "../factories/textLikeComponentViewFactory";
import { ReactElement } from "../types";
import { EmailComponentIcon } from "../svgs";

const renderForEmail = (props: CalcRenderPropsResult<any, any>): ReactElement<any> => {
    const { specific: { showPlaceholderValue } = {} } = props,
        emailText = (!showPlaceholderValue && props.deps.contactEmail) || props.placeholderText;
    return <span>{emailText}</span>;
};

const iconRenderForEmail = (
    { style }: any
):React.ReactElement<React.ComponentProps<any>, any> => <EmailComponentIcon style={style} />;

const getHrefForEmail = (props: any): string | null => (props.deps.contactEmail ? `mailto:${props.deps.contactEmail}` : null);

export const emailComponentView: TextLikeViewType<any> = textLikeComponentViewFactory({
    defaultLineSpacing: 0.5,
    getHref: getHrefForEmail,
    // @ts-ignore
    render: renderForEmail,
    iconRender: iconRenderForEmail
});
