import React from 'react';
import cx from 'classnames';
import { Msg } from '../../../../../view/intl/index';
import { closeDialog } from "../../../../App/actionCreators/index";
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import StandardDialog from '../../../../../view/common/dialogs/StandardDialog/index';
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import { StripTypes } from '../../../../../view/common/dialogs/baseDialog/index';
import * as styles from './PublishErrorDialogs.css';

const ComponentsValidationsFailedDialog = (props) => {
    const { dispatch } = props;

    return (
        // @ts-ignore
        <StandardDialog
            mctaText="msg: common.OK {OK}"
            mctaHandler={() => dispatch(closeDialog())}
            sctaHandler={null}
            stripType={StripTypes.NONE}
            showTitle={false}
            footerClassName={styles.footerClassName}
            noTopBorderForFooter
            footerV2
        >
            <div className={styles.errorInfoRootContainer}>
                <div className={styles.errorInfoIcon} />
                <VerticalSpacer y={25} />
                <Msg k="publish.publishingFailed.errorMsg" className={cx(styles.txtCommonStyle, styles.titleMsgInfo)}>
                    An error occurred during publishing.
                </Msg>
                <VerticalSpacer y={12} />
                <Msg className={cx(styles.txtCommonStyle, styles.contactSupport)} k="common.contactSupport">
                    Contact our support for further assistance if the problem persists.
                </Msg>
            </div>
        </StandardDialog>
    );
};

export default getCenteredDialogConfig({
    width: 600,
    height: 428,
    component: ComponentsValidationsFailedDialog
});
