import React from 'react';
import cx from 'classnames';
import { Msg } from '../../../../../view/intl/index';
import { closeDialog } from "../../../../App/actionCreators/index";
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import StandardDialog from '../../../../../view/common/dialogs/StandardDialog/index';
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import { StripTypes } from '../../../../../view/common/dialogs/baseDialog';
import * as styles from './PublishErrorDialogs.css';

interface PublicationError {
    data: {
        invalidUrls: Array<string>,
        pageUrl: string,
        preview: string
    }
}
interface Props {
    dispatch: Dispatch,
    lastPublicationError: PublicationError
}

const ComponentsValidationsFailedDialog = ({ dispatch, lastPublicationError }: Props) => {
    return (
        // @ts-ignore
        <StandardDialog
            mctaText="msg: common.OK {OK}"
            mctaHandler={() => dispatch(closeDialog())}
            sctaHandler={null}
            stripType={StripTypes.NONE}
            showTitle={false}
            footerClassName={styles.footerClassName}
            noTopBorderForFooter
            footerV2
        >
            <div className={styles.errorInvalidUrlRootContainer}>
                <div className={styles.errorInfoIcon} />
                <VerticalSpacer y={25} />
                <Msg k="publish.publishingFailed.errorMsg" className={cx(styles.txtCommonStyle, styles.titleMsgInfo)}>
                    An error occurred during publishing.
                </Msg>
                <VerticalSpacer y={20} />
                <Msg
                    className={cx(styles.txtCommonStyle, styles.contactSupport)}
                    k="publishPage.pageUrl"
                    params={{ pageUrl: lastPublicationError.data.pageUrl || lastPublicationError.data.preview }}
                >
                    {`Fix invalid links in page {pageUrl}`}
                </Msg>
                <VerticalSpacer y={20} />
                <Msg
                    className={cx(styles.txtCommonStyle, styles.contactSupport)}
                    k="publishPage.invalidLinks"
                    params={{ pageUrl: lastPublicationError.data.pageUrl || lastPublicationError.data.preview }}
                >
                    Invalid links
                </Msg>
                <VerticalSpacer y={12} />
                <ul>
                    {
                        lastPublicationError.data.invalidUrls.map((brokenLink, index) =>
                            (<li className={cx(styles.txtCommonStyle, styles.contactSupport)} key={index}>
                                <i>{brokenLink}</i>
                            </li>))
                    }
                </ul>
                <VerticalSpacer y={20} />
                <Msg className={cx(styles.txtCommonStyle, styles.contactSupport)} k="common.contactSupport">
                    Contact our support for further assistance if the problem persists.
                </Msg>
            </div>
        </StandardDialog>
    );
};

export default getCenteredDialogConfig({
    width: 700,
    minHeight: 630,
    component: ComponentsValidationsFailedDialog
});
