import * as R from 'ramda';
import getCmpTypeById from "./getCmpTypeById";
import { cmpTypes } from "./constants";
import type { ComponentsMap } from "../../redux/modules/children/workspace/flowTypes";
import calcMobileEnablement from '../PropertiesPanel/epic/utils/calcMobileEnablement';
import type { Groups, Sequence } from "./flowTypes";
import type { RelationsMap } from "../Workspace/epics/relations/flowTypes";
import Image from '../oneweb/Image/kind';
import getId from './getId';
import type { Attachments } from "../Workspace/epics/componentAttachements/flowTypes";
import { getNearestParentHoverBoxCmp } from "../oneweb/HoverBox/utils";
import { WEBSHOP_PAYMENT_METHODS, WEBSHOP_POLICIES } from '../oneweb/componentKinds';

const getComponentMobileProp = (prop: string, cmpId: string, componentsMap: ComponentsMap,
    groups: Groups, data: Sequence): boolean => {
    if (!cmpId) {
        return false;
    }
    if (getCmpTypeById(cmpId) === cmpTypes.group) {
        return groups[cmpId].every(id => R.path([id, prop])(componentsMap));
    }
    if (data[cmpId]) {
        let mobileDown = true;
        data[cmpId].every(id => {
            if (!data[id] && getCmpTypeById(id) !== cmpTypes.group) {
                mobileDown = R.path([id, prop])(componentsMap);
            } else {
                mobileDown = getComponentMobileProp(prop, id, componentsMap, groups, data);
            }
            return mobileDown;
        });
        return mobileDown;
    }
    return R.path([cmpId, prop])(componentsMap);
};

export const isLockToBottomEnabled = (cmpId: string, componentsMap: ComponentsMap,
    groups: Groups, data: Sequence, relations: RelationsMap, attachments: Attachments): boolean => {
    const inTemplate = getComponentMobileProp('inTemplate', cmpId, componentsMap, groups, data),
        parentHoverBoxId = getNearestParentHoverBoxCmp(cmpId, attachments, componentsMap);
    if (!inTemplate || parentHoverBoxId) {
        return false;
    }
    const isMobileDown = getComponentMobileProp('mobileDown', cmpId, componentsMap, groups, data);
    if (R.path([cmpId, 'kind'])(componentsMap) === Image) {
        return calcMobileEnablement(componentsMap, relations, cmpId).enableMobileDown &&
            !isMobileDown;
    }
    return !isMobileDown;
};

export const isUnlockFromBottomEnabled = (cmpId: string, componentsMap: ComponentsMap,
    groups: Groups, data: Sequence): boolean => {
    const inTemplate = getComponentMobileProp('inTemplate', cmpId, componentsMap, groups, data);
    if (!inTemplate) {
        return false;
    }
    if (data[cmpId] && data[cmpId].some(id => componentsMap[id]
            && (componentsMap[id].kind === WEBSHOP_PAYMENT_METHODS ||
                componentsMap[id].kind === WEBSHOP_POLICIES))) {
        return false;
    }
    return getComponentMobileProp('mobileDown', cmpId, componentsMap, groups, data);
};

export const getLatestLockedCmpId = (latestLockedCmpId: string | null, latestUnlockedCmpId: string | null,
    mobileDownData: Sequence, groups: Groups) => {
    if (latestUnlockedCmpId) {
        return latestUnlockedCmpId;
    }
    if (latestLockedCmpId) {
        let lockedCmpId = latestLockedCmpId;
        Object.keys(mobileDownData).forEach(id => {
            if (getId(id) === latestLockedCmpId) {
                lockedCmpId = id;
            }
        });
        if (lockedCmpId !== latestLockedCmpId) {
            return lockedCmpId;
        }
        Object.keys(groups).forEach(groupId => {
            if (groups[groupId].indexOf(latestLockedCmpId) !== -1) {
                lockedCmpId = groupId;
            }
        });
        return lockedCmpId;
    }
    return null;
};
