import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import { handlesUnderMouseValueActionType } from "../handlesUnderMouse/valueActionType";
import { showVirtualHandles } from "../../../../utils/isDebug";
import { log } from "../../../../../utils/log";
import UserFocusValueActionType from '../../../App/epics/userFocus/valueActionType';
import { DIALOG } from '../../../App/epics/userFocus/kind';

export default makeEpic({
    defaultState: null,
    valueActionType,
    updaters: [
        {
            conditions: [
                handlesUnderMouseValueActionType,
                UserFocusValueActionType
            ],
            reducer: ({
                values: [
                    handlesUnderMouse,
                    userFocus
                ]
            }) => {
                if (showVirtualHandles()) {
                    log('handlesUnderMouse', handlesUnderMouse);
                }
                if (userFocus.kind === DIALOG) return { state: null };

                return { state: handlesUnderMouse[0] || null };
            }
        }
    ]
});
