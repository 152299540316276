/* eslint-disable max-len */

import React from "react";
import getCenteredDialogConfig from "../../../DialogManager/getCenteredDialogConfig";
import styles from "./styles.css";
import { injectIntl, Intl, Msg } from "../../../../view/intl/index";
import { Dialog, DialogBody, StripTypes } from "../../../../view/common/dialogs/baseDialog/index";
import {
    CLICK_START_SECTION_TOUR,
    SECTION_INTRO_DIALOG_CLOSE,
    SECTION_INTRO_NOT_NOW
} from "../epics/sectionIntroDialog/actionTypes";
import { PrimaryButton } from "../../../../view/common/Button/index";

type SectionIntroDialogPropsType = {
    intl: Intl,
    dispatch: Function
}
class SectionIntroDialog extends React.Component<SectionIntroDialogPropsType> {
    constructor(props: SectionIntroDialogPropsType) {
        super(props);
        this.onClose = this.onClose.bind(this);
        this.startSectionTour = this.startSectionTour.bind(this);
        this.notNowHandler = this.notNowHandler.bind(this);
    }

    startSectionTour() {
        this.props.dispatch({
            type: CLICK_START_SECTION_TOUR
        });
    }

    onClose() {
        this.props.dispatch({
            type: SECTION_INTRO_DIALOG_CLOSE
        });
    }

    notNowHandler() {
        this.props.dispatch({
            type: SECTION_INTRO_NOT_NOW
        });
    }
    render() {
        const { intl } = this.props;
        const Sections = (<strong>{intl.msgJoint('msg: common.sections {Sections}')}</strong>);
        const Template = (<strong>{intl.msgJoint('msg: common.template {Template}')}</strong>);
        return (
            <Dialog
                onClose={this.onClose}
                stripType={StripTypes.NONE}
            >
                <DialogBody className={styles.container}>
                    <div className={styles.leftContainer}>
                        <div className={styles.newAndImproved}>
                            {intl.msgJoint('msg: sections.intro.newAndImproved {New and improved ways to build your site!}')}
                        </div>
                        <div className={styles.newAndImprovedListContainer}>
                            <ul>
                                <li>
                                    <Msg k="sections.intro.organiseContent" params={{ Sections }}>
                                        {`Organise your content and create layouts using the new {Sections}.`}
                                    </Msg>
                                </li>
                                <li>
                                    <Msg k="sections.intro.headerAndFooter" params={{ Template }}>
                                        {`Edit your website\'s header and footer without switching to the {Template} mode.`}
                                    </Msg>
                                </li>
                            </ul>
                        </div>
                        <div className={styles.actionsContainer}>
                            <PrimaryButton onClick={this.startSectionTour}>
                                {intl.msgJoint('msg: sections.intro.seeHowSectionWorks {See how sections work}')}
                            </PrimaryButton>
                            <a className={styles.notNow} onClick={this.notNowHandler}>
                                {intl.msgJoint('msg: sections.intro.notNow {Not now}')}
                            </a>
                        </div>
                    </div>
                    <div className={styles.rightContainer}>
                        <div className={styles.sectionIntroIllustration} />
                    </div>
                </DialogBody>
            </Dialog>
        );
    }
}
const
    width = 730,
    minHeight = 346,
    sectionIntroDialogConfig = getCenteredDialogConfig({
        width,
        minHeight,
        component: injectIntl(SectionIntroDialog),
        dialogBackgroundClassName: styles.backgroundClassName,
        dialogClassName: styles.dialogContainer,
    });

export { sectionIntroDialogConfig };
