import {
    makeDefaultStateReducers,
    makeCombineReducer,
    makeNegationPathPayloadToSetterReducer,
    makePathPayloadToSetterReducer,
    makeDefaultStateReducer,
} from "../../../../../../redux/makeReducer";
import defaultState from "./defaultState";
import * as Actions from "../actions";
import { PageInfoTab } from "../../../constants";
import makeInputReducer from "../../../../../inputControls/input/reducerFactory/makeInputReducer";
import {
    urlChangeReducer,
    pageIsPublishedToggleReducer,
    pageIsHiddenToggleReducer,
    switchTabReducer,
    makeTemplateIsLoadingReducer,
    saveTemplateSelectionReducer
} from "./reducers";
import {
    LOAD_TEMPLATE_TAB_TEMPLATES_FAILURE_ACTION,
    LOAD_TEMPLATE_TAB_TEMPLATES_REQUEST_ACTION,
    LOAD_TEMPLATE_TAB_TEMPLATES_SUCCESS_ACTION,
    SAVE_TEMPLATE_SELECTION_ACTION,
    SET_TEMPLATE_TAB_TEMPLATES_ACTION,
} from "../view/tabs/template-tab/actions";
import { sortPageTemplates } from "../../../../PageTemplateSelector/reducer/functions";
import { Lit } from "../../../../../../lit";
import type { PageInfoDialogState } from "../flowTypes";
import {
    DELETE_PAGE_TEMPLATE_SUCCESS_ACTION,
    DUPLICATE_PAGE_TEMPLATE_SUCCESS_ACTION
} from "../../../../PageTemplateSelector/actions";

export default makeCombineReducer({
    combineReducers: {
        ...makeDefaultStateReducers(defaultState),
        [PageInfoTab.NAMES]: makeCombineReducer({
            combineReducers: {
                name: makeInputReducer({ actionType: Actions.PAGE_INFO_NAME_CHANGE }),
                url: urlChangeReducer
            }
        }),
        [PageInfoTab.SEO]: makeCombineReducer({
            combineReducers: {
                title: makeInputReducer({ actionType: Actions.PAGE_INFO_TITLE_CHANGE }),
                customTitle: makeInputReducer({ actionType: Actions.PAGE_INFO_CUSTOM_TITLE_CHANGE }),
                description: makeDefaultStateReducer(defaultState[PageInfoTab.SEO].description),
                excludeFromIndexing: makeDefaultStateReducer(defaultState[PageInfoTab.SEO].excludeFromIndexing),
                pageNameOptionInBrowserTab: makeDefaultStateReducer(defaultState[PageInfoTab.SEO].pageNameOptionInBrowserTab),
                defaultHomePageNameInBrowserTab: makeDefaultStateReducer(defaultState[PageInfoTab.SEO].defaultHomePageNameInBrowserTab),
                // eslint-disable-next-line max-len
                defaultNonHomePageNameInBrowserTab: makeDefaultStateReducer(defaultState[PageInfoTab.SEO].defaultNonHomePageNameInBrowserTab),
            }
        }),
    },
    handleActions: {
        [Actions.SWITCH_PAGE_INFO_TAB_ACTION]: switchTabReducer,
        [Actions.PAGE_INFO_EXCLUDE_FROM_INDEXING_TOGGLE]: makeNegationPathPayloadToSetterReducer(
            [PageInfoTab.SEO, 'excludeFromIndexing']
        ),
        [Actions.PAGE_INFO_DESCRIPTION_CHANGE]: makePathPayloadToSetterReducer(
            [PageInfoTab.SEO, 'description']
        ),
        [Actions.PAGE_INFO_SEO_PAGE_NAME_IN_BROWSER_TAB_TOGGLE]: (state: PageInfoDialogState, action: Record<string, any>) => {
            return {
                ...state,
                [PageInfoTab.SEO]: {
                    ...state[PageInfoTab.SEO],
                    pageNameOptionInBrowserTab: action.payload,
                }
            };
        },
        [Actions.PAGE_INFO_HIDDEN_TOGGLE]: pageIsHiddenToggleReducer,
        [Actions.PAGE_INFO_PUBLISHED_TOGGLE]: pageIsPublishedToggleReducer,
        [Actions.PAGE_INFO_IS_HOME_PAGE_TOGGLE]: makeNegationPathPayloadToSetterReducer(['isHomePage']),
        [Actions.PAGE_INFO_SOCIAL_SHARE_ASSET_ADDED]: (state: PageInfoDialogState, action: Record<string, any>) => {
            const { asset: socialShareAsset } = action.payload;
            return {
                ...state,
                [PageInfoTab.SOCIAL_SHARE]: {
                    ...state[PageInfoTab.SOCIAL_SHARE],
                    socialShareAsset
                },
            };
        },
        [Actions.PAGE_INFO_SOCIAL_SHARE_ASSET_REMOVED]: makeNegationPathPayloadToSetterReducer(
            [PageInfoTab.SOCIAL_SHARE, 'socialShareAsset']
        ),
        [LOAD_TEMPLATE_TAB_TEMPLATES_REQUEST_ACTION]: makeTemplateIsLoadingReducer(true),
        [LOAD_TEMPLATE_TAB_TEMPLATES_SUCCESS_ACTION]: makeTemplateIsLoadingReducer(false),
        [LOAD_TEMPLATE_TAB_TEMPLATES_FAILURE_ACTION]: makeTemplateIsLoadingReducer(false),
        [SET_TEMPLATE_TAB_TEMPLATES_ACTION]: (state: PageInfoDialogState, action: Record<string, any>) => ({
            ...state,
            [PageInfoTab.TEMPLATE]: {
                ...state[PageInfoTab.TEMPLATE],
                [Lit.templates]: sortPageTemplates(action.payload)
            }
        }),
        // TOOD: will be refactored in WBTGEN-6945
        [DUPLICATE_PAGE_TEMPLATE_SUCCESS_ACTION]: (state: PageInfoDialogState, action: Record<string, any>) => {
            const { payload: newTemplateRef } = action,
                { templates } = state.template;
            return {
                ...state,
                [PageInfoTab.TEMPLATE]: {
                    ...state[PageInfoTab.TEMPLATE],
                    [Lit.templates]: sortPageTemplates([...templates, newTemplateRef])
                }
            };
        },
        // TOOD: will be refactored in WBTGEN-6945
        [DELETE_PAGE_TEMPLATE_SUCCESS_ACTION]: (state: PageInfoDialogState, action: Record<string, any>) => {
            const
                { endpointParams: [templateId] } = action,
                { templates } = state.template,
                deletedTemplateIndex = templates.findIndex(t => t.id === templateId);

            let nextTemplates = [...templates];
            if (deletedTemplateIndex >= 0) {
                nextTemplates.splice(deletedTemplateIndex, 1);
            }

            return {
                ...state,
                [PageInfoTab.TEMPLATE]: {
                    ...state[PageInfoTab.TEMPLATE],
                    [Lit.templates]: sortPageTemplates(nextTemplates)
                }
            };
        },
        [SAVE_TEMPLATE_SELECTION_ACTION]: saveTemplateSelectionReducer,
    }
});
