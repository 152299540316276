import { receiveOnly } from '../../../../../epics/makeCondition';
import {
    UPDATE_WRAP_REQUEST
} from '../actionTypes';

import {
    SELECTED_COMPONENT_WRAP_TOP_LEFT,
    SELECTED_COMPONENT_WRAP_TO_CENTER,
    SELECTED_COMPONENT_WRAP_TOP_RIGHT,
    SELECTED_COMPONENT_WRAP_ABOVE,
    SELECTED_COMPONENT_WRAP_BELOW
} from '../../../actionTypes';

import {
    ReceiveOnlyComponentsMap,
    ReceiveOnlySelectedComponentIdSelector
} from '../../componentsEval/selectorActionTypes';

import {
    appendWrappedNode,
    getComponentsChanges
} from '../../../../../utils/htmlWriter/html/render/wrapper/wrapperNodeUtils';
import workspaceBBoxValueACtionType from '../../workspaceBBox/valueActionType';

import { getComponentBBox } from "../../../../../utils/componentsMap/index";
import { getWrapperIdFromWrappedComponent } from '../helpers';

import type { WrapUpdater } from '../flowTypes';
import { WrapPositionMap } from "../../../../../utils/htmlWriter/html/render/wrapper/wrapPosition";
import { ReceiveOnlyAttachments } from '../../componentAttachements/selectorActionTypes';

const
    { left, right, center, above, below } = WrapPositionMap,
    positionToActionTypeMap = {
        [left]: SELECTED_COMPONENT_WRAP_TOP_LEFT,
        [right]: SELECTED_COMPONENT_WRAP_TOP_RIGHT,
        [center]: SELECTED_COMPONENT_WRAP_TO_CENTER,
        [above]: SELECTED_COMPONENT_WRAP_ABOVE,
        [below]: SELECTED_COMPONENT_WRAP_BELOW
    };

const makeWrapUpdater = (position): WrapUpdater => {
    return {
        conditions: [
            ReceiveOnlyComponentsMap,
            ReceiveOnlySelectedComponentIdSelector,
            receiveOnly(workspaceBBoxValueACtionType),
            ReceiveOnlyAttachments,
            positionToActionTypeMap[position]
        ],
        reducer: ({ values: [componentsMap, wrappedComponentId, workspaceBBox, attachments], state, scope }) => {
            const
                wrappedComponent = componentsMap[wrappedComponentId],

                wrapperId = getWrapperIdFromWrappedComponent(wrappedComponent, componentsMap) || "",
                wrapperComponent = componentsMap[wrapperId],
                wrapperComponentBBox = getComponentBBox(wrapperComponent, workspaceBBox),

                // Wrapper component is always a text component, it should have content defined
                wrapperComponentContentUpdate = appendWrappedNode(
                    position,
                    wrapperComponent.content,
                    wrappedComponent
                ),

                componentsChanges = getComponentsChanges(
                    { ...wrapperComponent, content: wrapperComponentContentUpdate },
                    componentsMap,
                    attachments,
                ),

                wrappedComponentUpdatedRelIn = {
                    ...componentsChanges[wrappedComponentId].relIn,
                    id: wrapperId
                };

            const
                wrappedComponentUpdate = {
                    relIn: wrappedComponentUpdatedRelIn,
                    relPara: componentsChanges[wrappedComponentId].relPara,
                    // Set new bbox value for the component corresponding to the chosen position
                    top: wrapperComponentBBox.top + wrappedComponentUpdatedRelIn.top,
                    left: wrapperComponentBBox.left + wrappedComponentUpdatedRelIn.left,
                    right: wrapperComponentBBox.right + wrappedComponentUpdatedRelIn.right,
                    wrap: true
                };

            return {
                state,
                scope,
                actionToDispatch: {
                    type: UPDATE_WRAP_REQUEST,
                    payload: {
                        textComponentUpdateInfo: {
                            [wrapperId]: { content: wrapperComponentContentUpdate }
                        },
                        wrappedComponentsUpdateInfo: {
                            ...componentsChanges,
                            [wrappedComponentId]: wrappedComponentUpdate
                        },
                        explicit: true
                    }
                }
            };
        }
    };
};

export const
    wrapLeftUpdater = makeWrapUpdater(left),
    wrapRightUpdater = makeWrapUpdater(right),
    wrapAboveUpdater = makeWrapUpdater(above),
    wrapBelowUpdater = makeWrapUpdater(below),
    wrapCenterUpdater = makeWrapUpdater(center);

export const
    wrapDirectionUpdaters = [
        wrapLeftUpdater,
        wrapRightUpdater,
        wrapAboveUpdater,
        wrapBelowUpdater,
        wrapCenterUpdater
    ];
