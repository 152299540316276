/* eslint-disable max-len */
import React, { useState } from "react";
import cx from "classnames";
import {
    ONBOARDING_ADDRESS_CHANGED,
    ONBOARDING_PHONE_NUMBER_CHANGED,
    ONBOARDING_CONTACT_EMAIL_CHANGED,
    ONBOARDING_ADDRESS_CHANGED_ON_BLUR,
    ONBOARDING_PHONE_NUMBER_CHANGED_ON_BLUR,
    ONBOARDING_CONTACT_EMAIL_CHANGED_ON_BLUR,
    ONBOARDING_GOOGLE_PLACE_RESPONSE,
    ONBOARDING_CONTINUE_PRESSED,
    ONBOARDING_STEP_BACK_BUTTON_PRESSED,
    ONBOARDING_FILL_LATER_PRESSED
} from "../actionTypes";
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import type { MsgJointInput } from "../../../view/intl/index";
import { Msg, Intl } from "../../../view/intl/index";
import { ValidatedInput } from "../../../view/common/Input/ValidatedInputField";
import { GoogleMapsAutoCompleteInput } from "../../inputControls/input/view/GoogleMapsAutoCompleteInput";
import { Buttons } from "./Buttons";
import styles from "./styles.css";
import { OnboardingTopBar } from "../../TopBar/view/OnboardingTopBar";
import ContactStepRightSideView from "./LottieAnimations/ContactStepRightSideView";
import stepStyles from "./ContactStep.css";

export type ContactStepProps = {
    address: string;
    phoneNumber: string;
    phoneValidationErrorMsg: MsgJointInput;
    contactEmail: string;
    contactEmailIsInvalid: boolean;
    isForwardDirection: boolean;
    dispatch: Dispatch;
    intl: Intl;
};
export const ContactStep = (props: ContactStepProps) => {
    const {
            address,
            phoneNumber,
            phoneValidationErrorMsg,
            contactEmail,
            contactEmailIsInvalid,
            isForwardDirection,
            dispatch,
            intl
        } = props,
        mctaDisabled = address.length === 0 && phoneNumber.length === 0 && contactEmail.length === 0;

    const mctaRef = React.useRef<HTMLDivElement>(null),
        onEnterPress = () => {
            if (!mctaDisabled && mctaRef && mctaRef.current) {
                mctaRef.current.focus();
            }
        };

    const [isEmailFocused, setEmailFocused] = useState(true),
        [isPhoneFocused, setPhoneFocused] = useState(true);
    return (
        <div className={styles.contentContainer}>
            <div className={cx(styles.leftContentContainer)}>
                <OnboardingTopBar />
                <div className={styles.flex1} />
                <div className={styles.leftContent}>
                    <div className={styles.textProgress}>
                        <Msg
                            k="onboarding.step"
                            params={{
                                step: 3
                            }}
                        >{`Step {step} of 5`}</Msg>
                    </div>
                    <div className={cx(styles.question)}>
                        <Msg k="onboarding.contactInfo">Add your contact info</Msg>
                    </div>
                    <div className={cx(styles.tip)}>
                        <Msg k="onboarding.contactInfoTip">
                            Make it easier for your visitors to get in touch with you by adding your contact details.
                        </Msg>
                    </div>
                    <VerticalSpacer y={38} />
                    <label className={styles.inputLabel}>
                        <VerticalSpacer y={8} />
                        <ValidatedInput
                            tabIndex="1" // eslint-disable-line jsx-a11y/tabindex-no-positive
                            className={styles.inputBox}
                            placeholder={`${intl.msgJoint("msg: common.email {Email}")} (${intl.msgJoint(
                                "msg: common.optional {optional}"
                            )})`}
                            value={contactEmail}
                            validationMsg="msg: common.emailInvalid {Check that the email is valid.}"
                            isInvalid={!isEmailFocused && contactEmailIsInvalid}
                            invalidInputClass={stepStyles.inputInvalidMsg}
                            onChange={payload =>
                                dispatch({
                                    type: ONBOARDING_CONTACT_EMAIL_CHANGED,
                                    payload
                                })}
                            onFocus={() => {
                                setEmailFocused(true);
                            }}
                            onBlur={() => {
                                setEmailFocused(false);
                            }}
                            intl={intl}
                            changedByUserOnBlur={payload =>
                                dispatch({
                                    type: ONBOARDING_CONTACT_EMAIL_CHANGED_ON_BLUR,
                                    payload
                                })}
                            usePropsValue
                            onEnterPress={onEnterPress}
                        />
                    </label>
                    <label className={styles.inputLabel}>
                        <VerticalSpacer y={8} />
                        <ValidatedInput
                            tabIndex="2" // eslint-disable-line jsx-a11y/tabindex-no-positive
                            className={styles.inputBox}
                            placeholder={`${intl.msgJoint("msg: common.phone {Phone}")} (${intl.msgJoint(
                                "msg: common.optional {optional}"
                            )})`}
                            value={phoneNumber}
                            validationMsg={phoneValidationErrorMsg}
                            isInvalid={!isPhoneFocused && !!phoneValidationErrorMsg}
                            invalidInputClass={stepStyles.inputInvalidMsg}
                            onChange={payload =>
                                dispatch({
                                    type: ONBOARDING_PHONE_NUMBER_CHANGED,
                                    payload
                                })}
                            onFocus={() => {
                                setPhoneFocused(true);
                            }}
                            onBlur={() => {
                                setPhoneFocused(false);
                            }}
                            intl={intl}
                            changedByUserOnBlur={payload =>
                                dispatch({
                                    type: ONBOARDING_PHONE_NUMBER_CHANGED_ON_BLUR,
                                    payload
                                })}
                            usePropsValue
                            onEnterPress={onEnterPress}
                        />
                    </label>
                    <label className={styles.inputLabel}>
                        <VerticalSpacer y={8} />
                        { /* @ts-ignore */ }
                        <GoogleMapsAutoCompleteInput
                            tabIndex="3" // eslint-disable-line jsx-a11y/tabindex-no-positive
                            className={styles.inputBox}
                            placeholder={`${intl.msgJoint("msg: common.address {Address}")} (${intl.msgJoint(
                                "msg: common.optional {optional}"
                            )})`}
                            value={address}
                            intl={intl}
                            onChange={payload =>
                                dispatch({
                                    type: ONBOARDING_ADDRESS_CHANGED,
                                    payload
                                })}
                            changedByUserOnBlur={payload =>
                                dispatch({
                                    type: ONBOARDING_ADDRESS_CHANGED_ON_BLUR,
                                    payload
                                })}
                            onPlaceChanged={payload =>
                                dispatch({
                                    type: ONBOARDING_GOOGLE_PLACE_RESPONSE,
                                    payload
                                })}
                            usePropsValue
                            onEnterPress={onEnterPress}
                        />
                    </label>
                </div>
                <div className={styles.flex1} />
                <Buttons
                    mctaRef={mctaRef}
                    mctaDisabled={mctaDisabled}
                    mctaHandler={() =>
                        !contactEmailIsInvalid &&
                        !phoneValidationErrorMsg &&
                        dispatch({
                            type: ONBOARDING_CONTINUE_PRESSED
                        })}
                    sctaHandler={() =>
                        dispatch({
                            type: ONBOARDING_STEP_BACK_BUTTON_PRESSED
                        })}
                    tctaHandler={() =>
                        dispatch({
                            type: ONBOARDING_FILL_LATER_PRESSED
                        })}
                    intl={intl}
                />
            </div>
            <div className={cx(styles.rightContentContainer, stepStyles.rightContentContainer)}>
                <ContactStepRightSideView
                    email={contactEmail}
                    phone={phoneNumber}
                    address={address}
                    isForwardDirection={isForwardDirection}
                />
            </div>
        </div>
    );
};
