import React from 'react';
import { useSelector } from "react-redux";

import { setAddressZipAction } from "./action";
import { selectAddressZip, selectAddressCountryCode } from "../../../../../common/selectors";
import { placeholderFieldFormatMap } from "../../../../../../../../wbtgen/src/components/SiteSettings/General/utils";
import {
    POSTAL_CODE
} from "../../../../../../../../wbtgen/src/components/SiteSettings/BusinessListing/GoogleMyBusiness/Mappers/gmbErrorFields";
import { AddressSettingsCommonForm } from "./AddressSettingsCommonForm";

const { getCountryFormat } = require('../../../../../../../../server/shared/address/getCountryFormat.js');

export const AddressSettingsZipCodeForm = () => {
    const countryFormat = getCountryFormat(useSelector(selectAddressCountryCode));
    return (
        <AddressSettingsCommonForm
            label="msg: generalInfo.zip.label {Zip code}"
            placeholder={placeholderFieldFormatMap[countryFormat].zip}
            selector={selectAddressZip}
            handleChangeAction={setAddressZipAction}
            errorField={POSTAL_CODE}
        />
    );
};
