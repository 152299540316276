import React from 'react';
import cx from 'classnames';
import VerticalSpacer from '../../../../view/common/VerticalSpacer';
import Page from '../../../PropertiesPanel/view/PropertiesPage';
import * as styles from './ScrollEffects.css';
import { getEffectProps, ScrollEffectFactory } from "./scrollEffectsFactory";
import injectIntl from "../../../../view/intl/injectIntl";
import type { ScrollEffectPageProps } from "./flowTypes";
import { SCROLL_EFFECTS, scrollEffectsTitle } from "./constants";

const
    id = SCROLL_EFFECTS,
    title = scrollEffectsTitle,
    view = injectIntl(({
        hasImage,
        scrollEffect,
        description,
        noScrollExtraProps = {},
        revealExtraProps = {},
        actionOnScrollEffectChange,
        dispatch,
        customClassName,
        kind,
        intl
    }: ScrollEffectPageProps) => {
        const result = getEffectProps({
            hasImage,
            scrollEffect,
            actionOnScrollEffectChange,
            noScrollExtraProps,
            revealExtraProps,
            dispatch,
            intl
        });
        return <Page>
            <VerticalSpacer />
            <span className={styles.description}>
                {intl.msgJoint(kind ? [description, { componentKind: kind.toLowerCase() }] : description)}
            </span>
            <div className={cx(styles.iconsContainer, customClassName)}>
                <ScrollEffectFactory {...result.noScrollProps} />
                <ScrollEffectFactory {...result.revealProps} />
                <ScrollEffectFactory {...result.parallaxProps} />
            </div>
        </Page>;
    });

export default { id, title, view };
