import React from 'react';

import { WarningConfirmationDialog } from '../../dialogs/WarningConfirmationDialog/index';
import { Msg } from '../../../intl/index';
import injectIntl from "../../../intl/injectIntl";
import { FC_DELETE_CANCEL, FC_DELETE_RETRY } from "../../../../redux/modules/children/fileChooser/actionTypes";
import VerticalSpacer from "../../VerticalSpacer";
import makeAppStandardErrorDialog from "../../../../components/App/dialogs/makeAppStandardErrorDialog";
import styles from './FileChooserDelete.css';

const
    tryAgainText = 'msg: fc.uploadFailedDialog.err.common.tryAgain {Click \"{btn}\" to try again.}',
    mctaText = 'msg: common.ok {OK}';

const FailedErrorDialog = injectIntl(({ intl, dispatch }) => {
    const tryAgainMsg = intl.msgJoint([
            tryAgainText,
            { btn: `<span class="${styles.bold}">${intl.msgJoint(mctaText)}</span>` }
        ]),
        cancel = () => dispatch({ type: FC_DELETE_CANCEL });

    return (
        <WarningConfirmationDialog
            title="msg: fc.deleteError.title {Deletion failed}"
            confirmLabel={mctaText}
            onConfirm={() => dispatch({ type: FC_DELETE_RETRY })}
            onCancel={cancel}
            onClose={cancel}
            dispatch={dispatch}
        >
            <Msg k="fc.deleteError.description">
                An unexpected error occurred.
            </Msg>
            <VerticalSpacer y={20} />
            <p dangerouslySetInnerHTML={{ __html: tryAgainMsg }} />
        </WarningConfirmationDialog>
    );
});

export const fileChooserDeleteErrorDialog = makeAppStandardErrorDialog(FailedErrorDialog);
