import { path } from 'ramda';
import type { ButtonComponent } from './flowTypes';

export default (component: ButtonComponent, componentDependencies: Record<string, any>): ButtonComponent => {
    const { defaultStyleId, globalstyles: { styles } } = componentDependencies,
        btnStyleId = path(['style', 'globalId'], component) || defaultStyleId,
        style = styles.filter(({ id }) => id === btnStyleId)[0],
        fontSize = path(['style', 'text', 'size'], component) || null;
    return {
        ...component,
        style: {
            type: style.type,
            globalName: style.name,
            globalId: btnStyleId,
            text: { size: fontSize }
        }
    };
};
