import { connect } from 'react-redux';
import view from './view/index';
import { makeEpicStateSelector } from '../../epics/makeEpic';
import languageValueActionType from "../TopBar/epics/languages/valueActionType";
import type { AppState } from "../../redux/modules/flowTypes";

export default connect(
    function (appState: AppState) {
        let localeFromWorkspaceTemplateEpicStateSelector = makeEpicStateSelector(languageValueActionType),
            topBarLanguage = localeFromWorkspaceTemplateEpicStateSelector(appState);

        return { ...appState.backup, uiLocale: topBarLanguage.current, subscriptionData: { ...appState.subscriptionData } };
    },
    function (dispatch) {
        return { dispatch };
    }
// @ts-ignore
)(view);
