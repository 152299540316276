import { DataSite } from "../../../../../../dal/model/index";
import type { DataSiteItem } from "../../../../../../dal/model/utils/dataSiteItemUtils";

type pageIdMapCombinedType = {
    pathToPageIdMap: { [k: string]: string }
    pathToPageIdMapStartsWithSlash: { [k: string]: string }
}
// Function to generate path to pageId map to be used later
export const getPathToPageIdMap = (site: DataSite): pageIdMapCombinedType => {
    const _getPathToPageIdMap = (items: Array<DataSiteItem>, parents: Array<string> = []) => {
        let pathToPageIdMap = {};
        items.forEach(function (item) {
            // @ts-ignore
            let path = (item.pageId === site.homePageId ? 'index' : [...parents, item.url].join('/'));
            // @ts-ignore
            path += (item.pageId !== site.homePageId && Array.isArray(item.items) && item.items.length ? '/index' : '') + '.html';

            pathToPageIdMap[path] = item.id;
            // @ts-ignore
            if (Array.isArray(item.items) && item.items.length) {
                pathToPageIdMap = {
                    ...pathToPageIdMap,
                    // @ts-ignore
                    ..._getPathToPageIdMap(item.items, [...parents, item.url])
                };
            }
        });
        return pathToPageIdMap;
    };
    const items = site.folder.items;
    const pathToPageIdMap = _getPathToPageIdMap(items);
    const pathToPageIdMapStartsWithSlash = Object.keys(pathToPageIdMap)
        .reduce((result, key) => {
            // eslint-disable-next-line no-param-reassign
            result['/' + key] = pathToPageIdMap[key];
            return result;
        }, {});
    return { pathToPageIdMap, pathToPageIdMapStartsWithSlash };
};
