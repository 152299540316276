/* eslint-disable max-len */
import React, { useContext, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { WizardFooter } from "../../WizardFooter";
import { StepDescription } from "../Common/StepDescription";
import { WebsiteCategorySelector } from "./WebsiteCategorySelector";
import { DynamicTemplateContext } from "../../Context";

import { validateWebsiteCategoryStep } from "../validations";
import { getGMBCategoryOptions, getDefaultGMBOption, getGMBEntry } from "./helpers";
import { useIntl } from "../../../../../../view/intl";

import stepStyles from "../steps.css";
import {
    DYNAMIC_TEMPLATE_GET_CONCEPT_FOR_GMB_KEY,
    DYNAMIC_TEMPLATE_SET_UNKNOWN_GMB_KEY,
    DYNAMIC_TEMPLATE_UPDATE_CONCEPT_FOR_GMB_KEY
} from "../../../Epic/actionTypes";
import localStorage from '../../../../../../utils/localStorage';
import { DT_GMBKEY } from "../../../constants";

export const WebsiteCategoryStep = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { gmbKey, wizardState: { goNext }, conceptMap, isLoading, shopDisabled } = useContext(DynamicTemplateContext);
    const [showError, setShowError] = useState(false);

    const options = useMemo(() => getGMBCategoryOptions(intl, shopDisabled), [intl, shopDisabled]);

    const stepDescriptionProps = {
        title: "msg: onboarding.dynamic.step0.title {Find your website category}",
        description: "msg: onboarding.dynamic.step0.description {Select the category that most precisely represents your business or project.}"
    };

    const websiteCategorySelectorProps = {
        options,
        showError,
        setShowError,
    };

    const goNextHandler = () => {
        localStorage.set(DT_GMBKEY, gmbKey);

        if (gmbKey in conceptMap) {
            dispatch({ type: DYNAMIC_TEMPLATE_UPDATE_CONCEPT_FOR_GMB_KEY, payload: conceptMap[gmbKey] });
            goNext();
            return;
        }
        if (validateWebsiteCategoryStep(gmbKey)) {
            const gmbEntryFromList = getGMBEntry(getDefaultGMBOption(options, gmbKey).label, shopDisabled, intl);
            if (!gmbEntryFromList) {
                dispatch({ type: DYNAMIC_TEMPLATE_SET_UNKNOWN_GMB_KEY, payload: gmbKey });
                goNext();
                return;
            }
            if (isLoading) return;
            dispatch({ type: DYNAMIC_TEMPLATE_GET_CONCEPT_FOR_GMB_KEY, payload: { gmbKey: gmbEntryFromList[0] } });
        }
    };

    const [showContinueAnyway, setShowContinueAnyway] = useState(false);
    return (
        <React.Fragment>
            <div className={stepStyles.stepContainer} data-testid="dynamic-onboarding-website-category">
                <StepDescription {...stepDescriptionProps} />
                <WebsiteCategorySelector showContinueAnyway={showContinueAnyway} setShowContinueAnyway={setShowContinueAnyway} {...websiteCategorySelectorProps} />
            </div>
            <WizardFooter disableButton={showError || !gmbKey} showContinueAnyway={showContinueAnyway} goNext={goNextHandler} />
        </React.Fragment>
    );
};
