import * as R from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import valueActionType from "./valueActionType";
import {
    ComponentsMapAndSelectedComponentIdSelector
} from "../../epics/componentsEval/selectorActionTypes";
import CodeComponentLocationTypes from "../../../oneweb/Code/locationTypes";
import CodeComponentKind from "../../../oneweb/Code/kind";
import { memoMaxOne } from "../../../../../utils/memo";

import type { CodeComponent } from "../../../oneweb/Code/flowTypes";

const
    sortByOrderIndex = (c1, c2) => c1.orderIndex - c2.orderIndex,
    getCodeComponents = memoMaxOne((componentsMap) => {
        const
            headTemplate: any = [],
            headPage: any = [],
            bodyTemplate: any = [],
            bodyPage: any = [];

        Object.keys(componentsMap).forEach(componentId => {
            const component = componentsMap[componentId];
            if (component.kind === CodeComponentKind) {
                if (component.location === CodeComponentLocationTypes.BeforeClosingHead) {
                    if (component.inTemplate) {
                        headTemplate.push(component);
                    } else {
                        headPage.push(component);
                    }
                } else if (component.location === CodeComponentLocationTypes.BeforeClosingBody) {
                    if (component.inTemplate) {
                        bodyTemplate.push(component);
                    } else {
                        bodyPage.push(component);
                    }
                }
            }
        });

        headTemplate.sort(sortByOrderIndex);
        headPage.sort(sortByOrderIndex);
        bodyTemplate.sort(sortByOrderIndex);
        bodyPage.sort(sortByOrderIndex);

        return {
            headTemplate,
            headPage,
            bodyTemplate,
            bodyPage
        };
    }),
    ghostCodeComponentHeight = 70,
    ghostCodeComponentMarginTop = 5,
    calcHeights = (codeComponents) => {
        const
            heights = R.mapObjIndexed(({ length: codeComponentCount }) => {
                if (codeComponentCount === 0) {
                    return 0;
                }

                return codeComponentCount * (ghostCodeComponentHeight + ghostCodeComponentMarginTop);
            }, codeComponents);

        if ((heights.headTemplate && heights.headPage) || heights.headPage) {
            heights.headPage += ghostCodeComponentMarginTop;
        } else if (heights.headTemplate) {
            heights.headTemplate += ghostCodeComponentMarginTop;
        }

        if ((heights.bodyTemplate && heights.bodyPage) || heights.bodyPage) {
            heights.bodyPage += ghostCodeComponentMarginTop;
        } else if (heights.bodyTemplate) {
            heights.bodyTemplate += ghostCodeComponentMarginTop;
        }

        return heights;
    };

export type CodeComponentsRendererState = {
    codeComponents: {
        headTemplate: Array<CodeComponent>,
        headPage: Array<CodeComponent>,
        bodyTemplate: Array<CodeComponent>,
        bodyPage: Array<CodeComponent>
    },
    heights: {
        headTemplate: number,
        headPage: number,
        bodyTemplate: number,
        bodyPage: number
    },
    selectedComponentId: null | string
}

const defaultState: CodeComponentsRendererState = {
    codeComponents: {
        headTemplate: [],
        headPage: [],
        bodyTemplate: [],
        bodyPage: []
    },
    heights: {
        headTemplate: 0,
        headPage: 0,
        bodyTemplate: 0,
        bodyPage: 0
    },
    selectedComponentId: null
};

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                ComponentsMapAndSelectedComponentIdSelector
            ],
            reducer: ({ values: [{ componentsMap, selectedComponentId }], state }) => {
                const codeComponents = getCodeComponents(componentsMap);

                if (state.codeComponents === codeComponents && state.selectedComponentId === selectedComponentId) {
                    return { state };
                }

                return {
                    state: {
                        codeComponents,
                        heights: calcHeights(codeComponents),
                        selectedComponentId
                    }
                };
            }
        }
    ]
});

export {
    ghostCodeComponentHeight,
    ghostCodeComponentMarginTop
};
