import { makeActionForwardToSelectedComponent } from "../../../../redux/forwardTo";
import { COMPONENT_CONFIGURATION_COMPLETE } from "../../../../redux/modules/children/workspace/actionTypes";
import { openImageChooserAction } from "../../../FileManager/imageChooser/actions";
import { MISSING_ASSET_REPLACE_IMAGE } from "../../../MissingAssets/actionTypes";
import { IMAGE_EMPTY_SRC, IMAGE_SELECTED_FOR_REPLACE, IMAGE_UPDATE_ON_REPLACE } from "../actionTypes";

const replaceImageForSelectedCmpAction = () => openImageChooserAction({
    onSaveAction: IMAGE_SELECTED_FOR_REPLACE,
    forwardToComponent: true,
});

const emptyImageSrcForSelectedCmpAction = () => makeActionForwardToSelectedComponent({
    type: IMAGE_EMPTY_SRC
});

const updateImageOnReplaceForwardToCmpAction = (imageResource) => makeActionForwardToSelectedComponent({
    type: IMAGE_UPDATE_ON_REPLACE,
    payload: {
        asset: imageResource
    },
    amendTo: IMAGE_EMPTY_SRC
});

const replaceMissingAssetsAction = (imageResource) => ({
    type: MISSING_ASSET_REPLACE_IMAGE,
    payload: {
        asset: imageResource
    },
});

const imageCmpConfigurationCompleteAction = (asset) => ({ type: COMPONENT_CONFIGURATION_COMPLETE,
    payload: { asset } });

export {
    replaceImageForSelectedCmpAction,
    emptyImageSrcForSelectedCmpAction,
    updateImageOnReplaceForwardToCmpAction,
    replaceMissingAssetsAction,
    imageCmpConfigurationCompleteAction
};
