import type { GmbDataType } from "../../flowTypes";
import type { GeneralGlobalData } from "../../../General/generalGlobalDataEpic/generalGlobalDataEpic";
import { openingHoursWithMsTimeToGmbTime } from "../../../../oneweb/OpeningHours/utils";
import { GmbOpeningHour } from "../../../../oneweb/OpeningHours/flowTypes";

const GeneralDataToWsb = (generalData: GeneralGlobalData): GmbDataType => {
    const {
        name,
        gmbCategories,
        addressName,
        address,
        addressUrl,
        addressId,
        addressPlaceId,
        addressLocation,
        addressStreetAddress,
        addressCity,
        addressZip,
        addressArea,
        addressViewport,
        phoneNumber,
        addressCountryCode,
        addressFloor,
        openingHours
    } = generalData;

    const
        addressFields = {
            address,
            addressUrl,
            addressName,
            addressId,
            addressPlaceId,
            addressViewport,
            addressLocation,
            addressStreetAddress,
            addressCity,
            addressArea,
            addressZip,
            addressCountryCode,
            addressFloor,
        };

    let wsbOpeningHours: { periods: GmbOpeningHour[] } = { periods: [] };
    if (openingHours && openingHours.length) {
        wsbOpeningHours = { periods: openingHoursWithMsTimeToGmbTime(openingHours) };
    }

    const wsbFields = {
        name: name || '',
        addressFields: addressFields || '',
        openingHours: wsbOpeningHours,
        phoneNumber: phoneNumber || '',
        gmbCategories: gmbCategories || ''
    };

    return wsbFields;
};

export { GeneralDataToWsb };
