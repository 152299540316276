import React from 'react';
import { connect } from "react-redux";
import { GoogleMyBusinessListing } from './GoogleMyBusiness/GoogleMyBusinessListing';
import { injectIntl, Intl } from "../../../view/intl/index";
import { makeEpicStateSelector } from "../../../epics/makeEpic";
import { BUSINESS_LISTING_SITE_SETTINGS_SERVICE_EPIC_VALUE } from "./GoogleMyBusiness/businessListingSiteSettingsServiceVAT";
import SITE_SUBSCRIPTION_DATA_EPIC_VALUE from "../../App/epics/subscriptionData/valueActionType";
import type { BusinessListingServiceDefaultStateType } from "./flowTypes";
import * as styles from "./BusinessListingTab.css";
import { generalGlobalDataValueActionType } from "../General/generalGlobalDataEpic/valueActionType";
import type { SubscriptionData } from "../../../redux/modules/flowTypes";
import type { GeneralGlobalData } from "../General/generalGlobalDataEpic/generalGlobalDataEpic";

type Props = {
    dispatch: Dispatch,
    intl: Intl,
    serviceData: BusinessListingServiceDefaultStateType,
    subscriptionData: SubscriptionData,
    generalData: GeneralGlobalData,
};

const TabView = function (props: Props) {
    const {
        dispatch,
        intl,
        serviceData: {
            isGmbAccessAllowed,
            isLoggedIn,
            isConnected,
            isWaitingForApi,
        },
        subscriptionData,
        generalData
    } = props;
    return (
        <div className={styles.gmbListing}>
            <GoogleMyBusinessListing
                dispatch={dispatch}
                intl={intl}
                isGmbAccessAllowed={isGmbAccessAllowed}
                isLoggedIn={isLoggedIn}
                isConnected={isConnected}
                isWaitingForApi={isWaitingForApi}
                generalData={generalData}
                subscriptionData={subscriptionData}
            />
            <div className={styles.hr} />
        </div>
    );
};

const mapStateToProps = (appState) => ({
    serviceData: makeEpicStateSelector(BUSINESS_LISTING_SITE_SETTINGS_SERVICE_EPIC_VALUE)(appState),
    generalData: makeEpicStateSelector(generalGlobalDataValueActionType)(appState),
    subscriptionData: makeEpicStateSelector(SITE_SUBSCRIPTION_DATA_EPIC_VALUE)(appState),
});

export const BusinessListingTab = injectIntl(connect(mapStateToProps)(TabView));
