import React from 'react';
import styles from './SectionLinkTab.css';
import { SectionLinkForm } from '../../../../sectionLinkForm/view';
import type { NewPageDialogSectionLinkProps } from "../../../flowTypes";
import InfoTip from "../../../../../../view/common/InfoTip";
import { sectionLinkDialogInfoTip } from "../../../../Tree/constants";
import LoadingIndicator from '../../../../../../view/common/LoadingIndicator';

type Props = { dispatch: Dispatch } & NewPageDialogSectionLinkProps;

export default (props: Props) => {
    return (
        <div>
            <SectionLinkForm {...props} className={styles.sectionLinkForm} />
            <InfoTip
                className={styles.tipContainer}
                textKey={sectionLinkDialogInfoTip}
            />
            {props.isLoading && <LoadingIndicator absoluteCenter />}
        </div>
    );
};
