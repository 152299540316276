import u from 'updeep';

import type { PageInfoDialogState } from "../../flowTypes";
import { PageInfoTab } from "../../../../constants";

export default (state: PageInfoDialogState): PageInfoDialogState => {
    const
        { [PageInfoTab.VISIBILITY]: { isHidden, isPublished } } = state,
        nextIsPublished = !isPublished,
        nextIsHidden = !nextIsPublished ? true : isHidden;
    return u({ [PageInfoTab.VISIBILITY]: { isHidden: nextIsHidden, isPublished: nextIsPublished } }, state) as PageInfoDialogState;
};
