import { RESET_RESIZE_DECOS } from "./epics/resizeDecos/index";
import { transitionSpeed as transitionSpeedMs } from '../variables.css';

export const
    transitionSpeed = parseInt(transitionSpeedMs, 10),
    DELAY_ACTION_TYPE = 'DELAY_ACTION_TYPE',
    delayAC = () => ({ type: DELAY_ACTION_TYPE });

export const delayMiddleware = (store: Store) => (next: Dispatch) => (action: AnyAction) => {
    if (action.type === RESET_RESIZE_DECOS) {
        // Dispatch the action after the delay
        setTimeout(() => {
            store.dispatch(delayAC());
        }, transitionSpeed);
    }
    return next(action);
};
