import React from 'react';
import cx from 'classnames';
import { Switch as MuiSwitch, FormGroup, FormControlLabel } from '@mui/material';

import { injectIntl } from "../../../../../wbtgen/src/view/intl/index";
import { HEIGHT, WIDTH, BACKGROUND_COLOR_DISABLED, BACKGROUND_COLOR_ACTIVE } from "./constants";
import { useStyles } from './styles';

const SwichComponent = injectIntl((props) => {
    const {
        checked,
        disabled,
        label,
        labelComponent,
        height = HEIGHT,
        width = WIDTH,
        backgroundColorDisabled = BACKGROUND_COLOR_DISABLED,
        backgroundColorActive = BACKGROUND_COLOR_ACTIVE,
        formControlLabelClass,
        labelPadding,
        labelFontSize,
        intl
    } = props;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.handleChange) {
            props.handleChange(event.target.checked);
        }
    };

    const classes = useStyles({
        width,
        height,
        backgroundColorDisabled,
        backgroundColorActive,
        labelPadding,
        labelFontSize,
    });

    return (
        <FormGroup>
            <FormControlLabel
                className={cx(classes.formControlLabel, formControlLabelClass)}
                control={
                    <MuiSwitch
                        disableRipple
                        checked={checked}
                        disabled={disabled}
                        onChange={handleChange}
                        classes={{
                            root: classes.root,
                            switchBase: classes.switchBase,
                            thumb: classes.thumb,
                            track: classes.track,
                            checked: classes.checked,
                        }}
                        focusVisibleClassName={classes.focusVisible}
                    />
                }
                label={labelComponent || intl.msgJoint(label)}
            />
        </FormGroup>
    );
});

export const Switch = SwichComponent;
