import * as R from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import * as appActionTypes from '../../actionTypes';
import valueActionType from './valueActionType';
import { pathNotEq } from "../../../../utils/ramdaEx";

const
    keyNamesMap = {
        shift: 'shift',
        ctrl: 'ctrl',
        alt: 'alt',
        meta: 'meta',
        left: 'left',
        up: 'up',
        right: 'right',
        down: 'down',
        delete: 'delete',
        tab: 'tab'
    },
    // TODO WBTGEN-7670 read codes from keyCodes file instead of '16', '17' ...
    keyNameToCodesMap = {
        [keyNamesMap.shift]: ['16'],
        [keyNamesMap.ctrl]: ['17'],
        [keyNamesMap.alt]: ['18'],
        // meta1 is left command on mac
        // it is called meta because browser spec "e.meta" on mouse down event
        [keyNamesMap.meta]: ['91', '93'],
        [keyNamesMap.left]: ['37'],
        [keyNamesMap.up]: ['38'],
        [keyNamesMap.right]: ['39'],
        [keyNamesMap.down]: ['40'],
        [keyNamesMap.delete]: ['46'],
        [keyNamesMap.tab]: ['9']

    },
    keyNameByCodeMap = Object.keys(keyNameToCodesMap).reduce((acc, key) => {
        keyNameToCodesMap[key].forEach(code => {
            acc[code] = key;
        });
        return acc;
    }, {}),
    setIfChanged = (path, value) => R.when(pathNotEq(path, value), R.assocPath(path, value)),
    makeSetKeyPressedStateIfChanged = (keyName, pressed) => setIfChanged(['pressedKeysMap', keyName], pressed),
    makeUpdater = (actionType, isPressed) => ({
        conditions: [actionType],
        reducer: ({ values: [{ keyCode }], state }) => {
            let keyName;

            if (typeof keyCode !== 'undefined') {
                keyName = keyNameByCodeMap[keyCode.toString()];
            }

            if (!keyName) {
                return { state };
            }

            return ({
                state: makeSetKeyPressedStateIfChanged(keyName, isPressed)(state)
            });
        }
    }),

    // To handle the case if we do keydown in the browser and keyup outside the focus of browser, we will have wrong value in state
    // This issue still happens with arrow keys
    makeMouseDownUpdater = actionType => ({
        conditions: [actionType],
        reducer: ({ values: [{ shiftKey, ctrlKey, metaKey, altKey }], state }) => {
            return ({
                state: R.pipe(
                    makeSetKeyPressedStateIfChanged(keyNamesMap.shift, shiftKey),
                    makeSetKeyPressedStateIfChanged(keyNamesMap.ctrl, ctrlKey),
                    makeSetKeyPressedStateIfChanged(keyNamesMap.meta, metaKey),
                    makeSetKeyPressedStateIfChanged(keyNamesMap.alt, altKey),
                )(state)
            });
        },
        keepFullActions: true
    });

const defaultState = {
    pressedKeysMap: {}
};

const epic = makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        makeUpdater(appActionTypes.KEY_UP, false),
        makeUpdater(appActionTypes.KEY_DOWN, true),
        makeMouseDownUpdater(appActionTypes.APP_LEFT_MOUSE_DOWN),
        makeMouseDownUpdater(appActionTypes.APP_RIGHT_MOUSE_DOWN),
        makeMouseDownUpdater(appActionTypes.WINDOW_MOUSE_UP),
        {
            conditions: [appActionTypes.WINDOW_BLUR],
            reducer: () => ({ state: defaultState })
        }
    ]
});

export {
    keyNamesMap,
    keyNameToCodesMap,
    epic as default
};
