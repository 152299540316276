import { WidgetsPinterestKind } from './kind';
import { PinterestSDK } from './constants';
import { PinterestView } from './view/index';
import { calcRenderProps } from './calcRenderProps';

// See wbtgen/src/view/oneweb/registry/preview.js
const WidgetsPinterestPreviewConfig = {
    kind: WidgetsPinterestKind,
    view: PinterestView,
    calcProps: ({ component }) => calcRenderProps({ component, componentExtension: { isTransient: false } }),
    js: [PinterestSDK],
};

export { WidgetsPinterestPreviewConfig };
