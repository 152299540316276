import DataSite from "../../../../../../dal/model/DataSite";
import type { DataSiteItem } from "../../../../../../dal/model/utils/dataSiteItemUtils";
import { isPageRef } from "../../../../../../dal/model/utils/dataSiteItemUtils";

function isTemplateIdMissing(item) {
    if (isPageRef(item)) {
        return !(item as any).templateId;
    }
    return false;
}

function isTemplateIdUpdateRequired(items: Array<DataSiteItem>) {
    if (items && items.length) {
        return items.some(item =>
            isTemplateIdMissing(item) ||
            isTemplateIdUpdateRequired((item as any).items));
    }
    return false;
}

export default (site: DataSite): boolean => {
    return isTemplateIdUpdateRequired(site.getItems());
};
