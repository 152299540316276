import * as React from 'react';
import { injectIntl, Intl } from '../../../../../view/intl/index';
import WarningDialog from '../../../../../view/common/dialogs/WarningDialog/index';
import type { ApiErrorHandlerDialogProps } from '../types';
import warningDialogStyles from '../../../../../view/common/dialogs/WarningDialog/WarningDialog.css';

const SUPPORT_MESSAGE = 'msg: common.tryAgainOrContactSupport {Please try again later or contact our support for further assistance.}';

type Props = ApiErrorHandlerDialogProps & {
    mctaHandler?: Function,
    intl: Intl,
};

export const ApiErrorHandlerCommonDialog = injectIntl(
    ({
        messages,
        title = 'msg: common.errorOccurred {An error occurred}',
        showSupportMessage,
        mctaText = 'msg: common.ok {OK}',
        mctaHandler,
        sctaText,
        intl,
    }: Props) => {
        const finalMessages = Array.isArray(messages) ? messages : [messages];

        if (showSupportMessage) {
            finalMessages.push(SUPPORT_MESSAGE);
        }

        return (
            <WarningDialog
                title={title}
                mctaText={mctaText}
                mctaHandler={mctaHandler}
                sctaText={sctaText}
            >
                <div>
                    {finalMessages.map(msg => <p className={warningDialogStyles.message}>{intl.msgJoint(msg)}</p>)}
                </div>
            </WarningDialog>
        );
    }
);
