/* @flow */

const {
    isNormalSubscription,
    isPremiumSubscription,
    isEcommerceSubscription,
    isInvalidSubscription
} = require('../shared/subscriptionStatus/utils.js');
const { utcNow } = require("./utils/date.js");

/*::
    import type { SubscriptionTypeT } from './subscriptionStatus/types'
    import type { SstockQuotaConfig } from './config/types'
 */

/*::
    type Params = {
        requestedDownloadsIds: Array<string>,
        existingDownloads: Array<{
            imageId: string,
            latestDownloadTime: number,
        }>,
        subscription: SubscriptionTypeT,
        quotaConfig: SstockQuotaConfig,
    }
 */

const checkSstockImagesDownloadsQuota = (params /*: Params */) /*: boolean */ => {
    const {
        requestedDownloadsIds,
        existingDownloads,
        subscription,
        quotaConfig,
    } = params;

    if (isInvalidSubscription(subscription)) {
        throw new Error(`Invalid subscription value given: ${subscription}`);
    }

    const newDownloadsCount = existingDownloads.length
        ? requestedDownloadsIds
            .filter(imageId => !existingDownloads.find(d => d.imageId === imageId))
            .length
        : requestedDownloadsIds.length;

    if (isNormalSubscription(subscription)) {
        const existingDownloadsCount = existingDownloads.length;

        if (newDownloadsCount + existingDownloadsCount > quotaConfig.standard) {
            return false;
        }
    }

    if (isPremiumSubscription(subscription) || isEcommerceSubscription(subscription)) {
        const utcToday = utcNow();
        utcToday.setUTCHours(0, 0, 0);

        const todaysDownloadsCount = existingDownloads
            .filter(d => d.latestDownloadTime >= utcToday.getTime())
            .length;

        if (newDownloadsCount + todaysDownloadsCount > quotaConfig.perDay) {
            return false;
        }
    }

    return true;
};

module.exports = {
    checkSstockImagesDownloadsQuota,
};
