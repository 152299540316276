import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(
    (theme) => ({
        title: {
            fontSize: "26px",
            lineHeight: "34px",
            letterSpacing: "0.25px",
            fontWeight: 500,
            marginTop: "16px",
            [theme.breakpoints.up("sm")]: {
                fontSize: "32px",
                lineHeight: "40px",
                letterSpacing: "normal",
                fontWeight: 400,
            },
        },
        description: {
            fontSize: "22px",
            lineHeight: "30px",
            letterSpacing: "0.25px",
            fontWeight: 500,
            marginTop: "4px",
            [theme.breakpoints.up("sm")]: {
                fontSize: "24px",
                lineHeight: "32px",
                letterSpacing: "0.15px",
                fontWeight: 400,
                marginTop: "8px",
            }
        },
    }),
    { name: "SiteTitle" }
);
