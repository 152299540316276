import React from "react";
import cx from "classnames";

import { useIntl } from '../../../../../../view/intl/index';

import styles from "./styles.css";

export const ErrorBanner = ({ show, onClose }) => {
    const intl = useIntl();
    return (
        <div className={cx(styles.bannerContainer, { [styles.hide]: !show })}>
            <div className={styles.bannerContentWrapper}>
                <div className={styles.alertIcon} />
                <div>
                    <div className={styles.bannerLabel}>
                        {intl.msgJoint("msg: onboarding.dynamic.step0.errorBanner.label {Couldn’t fetch categories}")}
                    </div>
                    <div className={styles.bannerDesc}>
                        {intl.msgJoint("msg: onboarding.dynamic.step0.errorBanner.description {Please click “Continue” to try again. }")}
                    </div>
                </div>
            </div>
            <div>
                <div className={styles.dismissIcon} onClick={() => onClose(false)} />
            </div>
        </div>
    );
};
