import type { NewState } from "./view/flowTypes";
import getDefaultState from "./getDefaultState";

export default (recipientEmail: string, subject: string): NewState => {
    const defaultState = getDefaultState();
    return {
        formElements: {
            name: defaultState.name,
            email: defaultState.email,
            message: defaultState.message
        },
        recipientEmail,
        subject,
        submitBtn: defaultState.submitBtnText,
        successMessage: defaultState.successMessage,
        isCaptchaEnabled: defaultState.isCaptchaEnabled,
        captchaLang: defaultState.captchaLang
    };
};
