import type { ApiAction } from "../../../redux/middleware/api/flowTypes";
import { apiAction } from "../../../redux/middleware/api/actions";

export const
    SET_INSTAGRAM_ACCESS_CREDENTIALS_ACTION = 'SET_INSTAGRAM_ACCESS_CREDENTIALS_ACTION',
    setInstagramAccessCredentials = (instagramAccessToken: string, instagramAccountName: string) => ({
        type: SET_INSTAGRAM_ACCESS_CREDENTIALS_ACTION,
        payload: {
            instagramAccessToken,
            instagramAccountName
        }
    });

export const
    UNSET_INSTAGRAM_ACCESS_TOKEN_ACTION = 'UNSET_INSTAGRAM_ACCESS_TOKEN_ACTION',
    unSetInstagramAccessToken = () => ({
        type: UNSET_INSTAGRAM_ACCESS_TOKEN_ACTION,
    });

export const
    GET_INSTAGRAM_USER_MEDIA_REQUEST_ACTION = 'GET_INSTAGRAM_USER_MEDIA_REQUEST_ACTION',
    GET_INSTAGRAM_USER_MEDIA_SUCCESS_ACTION = 'GET_INSTAGRAM_USER_MEDIA_SUCCESS_ACTION',
    GET_INSTAGRAM_USER_MEDIA_FAILURE_ACTION = 'GET_INSTAGRAM_USER_MEDIA_FAILURE_ACTION',
    getInstagramUserMediaAction = (accessToken: string): ApiAction => {
        return apiAction({
            types: [
                GET_INSTAGRAM_USER_MEDIA_REQUEST_ACTION,
                GET_INSTAGRAM_USER_MEDIA_SUCCESS_ACTION,
                GET_INSTAGRAM_USER_MEDIA_FAILURE_ACTION,
            ],
            endpoint: 'getInstagramUserMedia',
            endpointParams: [accessToken],
        });
    };

export const
    GET_INSTAGRAM_ACCESS_TOKEN_REQUEST_ACTION = 'GET_INSTAGRAM_ACCESS_TOKEN_REQUEST_ACTION',
    GET_INSTAGRAM_ACCESS_TOKEN_SUCCESS_ACTION = 'GET_INSTAGRAM_ACCESS_TOKEN_SUCCESS_ACTION',
    GET_INSTAGRAM_ACCESS_TOKEN_FAILURE_ACTION = 'GET_INSTAGRAM_ACCESS_TOKEN_FAILURE_ACTION',
    getInstagramAccessTokenAction = (): ApiAction => {
        return apiAction({
            types: [
                GET_INSTAGRAM_ACCESS_TOKEN_REQUEST_ACTION,
                GET_INSTAGRAM_ACCESS_TOKEN_SUCCESS_ACTION,
                GET_INSTAGRAM_ACCESS_TOKEN_FAILURE_ACTION,
            ],
            endpoint: 'getInstagramAccessToken',
        });
    };

export const
    DISCONNECT_INSTAGRAM_REQUEST_ACTION = 'DISCONNECT_INSTAGRAM_REQUEST_ACTION',
    DISCONNECT_INSTAGRAM_SUCCESS_ACTION = 'DISCONNECT_INSTAGRAM_SUCCESS_ACTION',
    DISCONNECT_INSTAGRAM_FAILURE_ACTION = 'DISCONNECT_INSTAGRAM_FAILURE_ACTION',
    disconnectInstagramAction = (): ApiAction => {
        return apiAction({
            types: [
                DISCONNECT_INSTAGRAM_REQUEST_ACTION,
                DISCONNECT_INSTAGRAM_SUCCESS_ACTION,
                DISCONNECT_INSTAGRAM_FAILURE_ACTION,
            ],
            endpoint: 'disconnectInstagram',
        });
    };

export const
    OPEN_CONNECT_INSTAGRAM_ACCOUNT_ACTION = 'OPEN_CONNECT_INSTAGRAM_ACCOUNT_ACTION',
    openConnectInstagramAccountAction = () => ({
        type: OPEN_CONNECT_INSTAGRAM_ACCOUNT_ACTION,
    });

export const
    SET_TOTAL_INSTAGRAM_GALLERY_COMPONENTS = 'SET_TOTAL_INSTAGRAM_GALLERY_COMPONENTS',
    setTotalInstagramGalleryComponentsAction = (totalComponents: number) => ({
        type: SET_TOTAL_INSTAGRAM_GALLERY_COMPONENTS,
        payload: {
            totalComponents,
        }
    });

export const
    SET_TOTAL_CURRENT_PAGE_INSTAGRAM_GALLERY_COMPONENTS = 'SET_TOTAL_CURRENT_PAGE_INSTAGRAM_GALLERY_COMPONENTS',
    setTotalCurrentPageInstagramGalleryComponentsAction = (totalComponents: number) => ({
        type: SET_TOTAL_CURRENT_PAGE_INSTAGRAM_GALLERY_COMPONENTS,
        payload: {
            totalComponents,
        }
    });

export const
    SET_TOTAL_OTHER_PAGE_INSTAGRAM_GALLERY_COMPONENTS = 'SET_TOTAL_OTHER_PAGE_INSTAGRAM_GALLERY_COMPONENTS',
    setTotalOtherPageInstagramGalleryComponentsAction = (totalComponents: number) => ({
        type: SET_TOTAL_OTHER_PAGE_INSTAGRAM_GALLERY_COMPONENTS,
        payload: {
            totalComponents,
        }
    });
