import calcRenderProps from "./calcRenderProps";
import propertiesPanel from "./propertiesPanel/index";
import kind from './kind';
import reducer from './reducer/index';
import workspace from './view/workspace';
import componentMainActions from './componentMainActions/index';
import { adjustCodeComponentOnAdd } from "./adjustComponentOnAdd";
import type { CodeComponent } from "./flowTypes";
import { ComponentNames } from "../constants";
import { headerFooterSectionsPositionEpic } from "../Section/epics/headerFooterSectionsPositionEpic";
import { siteDataEpic } from "../../App/epics/siteData/index";
import { componentTierManagerEpic } from "../../ComponentTierManager/epics/index";
import View from "./view/index";
import { isModernLayoutActivatedEpic } from "../../Workspace/epics/isModernLayoutActivatedEpic/index";
import configurationDialogId from "./configurationDialogId";

export default {
    kind,
    label: ComponentNames[kind],
    shortcutIconName: 'code',
    workspace,
    calcRenderProps,
    propertiesPanel,
    reducer,
    componentMainActions,
    adjustComponentOnAdd: adjustCodeComponentOnAdd,
    dependsOn: {
        headerFooterSectionsPosition: headerFooterSectionsPositionEpic.reducer,
        isModernLayoutActivated: isModernLayoutActivatedEpic.reducer,
        site: siteDataEpic.reducer,
        componentTierData: componentTierManagerEpic.reducer,
    },
    mobileEditorConfig: {
        kind,
        view: View,
        getHiddenElementData: (props: CodeComponent) => ({ content: props.name })
    },
    requireConfigurationOnDrop: () => true,
    configurationDialog: configurationDialogId,
};
