/* eslint-disable max-len */

import { combineReducers } from 'redux';
import { defaultForgotPasswordState, forgotPasswordReducer } from "./forgotPassword/reducer/forgotPasswordReducer";
import { defaultSigninState, signinReducer } from "./signIn/reducer/signinReducer";
import { defaultSignUpState, signupReducer } from "./signUp/reducer/signupReducer";
import { defaultResetPasswordState, resetPasswordReducer } from "./resetPassword/reducer/resetPasswordReducer";
import { makeInitialStateReducer } from '../../../src/redux/makeReducer/makeInitialStateReducer';
import { Lit } from '../../../src/lit';
import { DEMO_SHOW_FORGOT_PASSWORD_PAGE_ACTION, DEMO_SHOW_SIGNUP_PAGE_ACTION } from "./signIn/actions";
import { DEMO_SHOW_SIGNIN_PAGE_ACTION } from "./signUp/actions";
import { DEMO_SHOW_RESET_PASSWORD_PAGE_ACTION } from "./resetPassword/actions";
import { FORGOT_PASSWORD_PAGE,
    RESET_PASSWORD_PAGE,
    SIGNIN_PAGE,
    SIGNUP_PAGE,
    EMAIL_VERIFICATION_SUCCESSFUL_PAGE,
    TRIAL_ENDED_PAGE,
    EMAIL_ALREADY_VERIFIED_PAGE,
    USER_NOT_FOUND_PAGE,
    VERIFICATION_EMAIL_REQUIRED_PAGE,
    MOBILE_BLOCKING_PAGE } from "./constants";
import { TrialAuthPage } from '../../../../server/shared/trial/TrialAuthPage';
import { DEMO_SHOW_EMAIL_VERIFICATION_PAGE_ACTION } from "./emailVerification/actions";
import { DEMO_SHOW_TRIAL_ENDED_PAGE_ACTION } from "./trialEnded/actions";
import { DEMO_SHOW_EMAIL_ALREADY_VERIFIED_PAGE_ACTION } from "./emailAlreadyVerified/actions";
import { DEMO_SHOW_USER_NOT_FOUND_PAGE_ACTION } from "./userNotFound/actions";
import { DEMO_SHOW_VERIFICATION_EMAIL_REQUIRED_PAGE_ACTION } from "./verificationEmailRequired/actions";
import {
    defaultVerificationEmailRequiredState,
    verificationEmailRequiredReducer
} from "./verificationEmailRequired/reducer/verificationEmailRequiredReducer";
import { DEMO_SHOW_MOBILE_BLOCKING_PAGE_ACTION } from "./mobileBlocking/actions";

export const demoLoginDefaultState = {
    [Lit.signin]: defaultSigninState,
    [Lit.signup]: defaultSignUpState,
    [Lit.resetPassword]: defaultResetPasswordState,
    [Lit.forgotPassword]: defaultForgotPasswordState,
    [Lit.currentPage]: SIGNUP_PAGE,
    [Lit.verificationEmailRequired]: defaultVerificationEmailRequiredState
};

export const demoLoginReducer = combineReducers({
    [Lit.signup]: makeInitialStateReducer(defaultSignUpState, signupReducer),
    [Lit.signin]: makeInitialStateReducer(defaultSigninState, signinReducer),
    [Lit.forgotPassword]: makeInitialStateReducer(defaultForgotPasswordState, forgotPasswordReducer),
    [Lit.resetPassword]: makeInitialStateReducer(defaultResetPasswordState, resetPasswordReducer),
    [Lit.verificationEmailRequired]:
        makeInitialStateReducer(defaultVerificationEmailRequiredState, verificationEmailRequiredReducer),
    [Lit.currentPage]: makeInitialStateReducer(SIGNUP_PAGE,
        // @ts-ignore
        (state, action: Action) => {
            switch (action.type) {
                case DEMO_SHOW_SIGNUP_PAGE_ACTION:
                    window.location.hash = TrialAuthPage.SIGNUP;
                    return SIGNUP_PAGE;
                case DEMO_SHOW_SIGNIN_PAGE_ACTION:
                    window.location.hash = TrialAuthPage.SIGNIN;
                    return SIGNIN_PAGE;
                case DEMO_SHOW_FORGOT_PASSWORD_PAGE_ACTION:
                    window.location.hash = TrialAuthPage.FORGOT_PASSWORD;
                    return FORGOT_PASSWORD_PAGE;
                case DEMO_SHOW_RESET_PASSWORD_PAGE_ACTION:
                    window.location.hash = TrialAuthPage.RESET_PASSWORD;
                    return RESET_PASSWORD_PAGE;
                case DEMO_SHOW_EMAIL_VERIFICATION_PAGE_ACTION:
                    window.location.hash = TrialAuthPage.VERIFICATION;
                    return EMAIL_VERIFICATION_SUCCESSFUL_PAGE;
                case DEMO_SHOW_TRIAL_ENDED_PAGE_ACTION:
                    window.location.hash = TrialAuthPage.TRIAL_ENDED;
                    return TRIAL_ENDED_PAGE;
                case DEMO_SHOW_EMAIL_ALREADY_VERIFIED_PAGE_ACTION:
                    window.location.hash = TrialAuthPage.EMAIL_ALREADY_VERIFIED;
                    return EMAIL_ALREADY_VERIFIED_PAGE;
                case DEMO_SHOW_USER_NOT_FOUND_PAGE_ACTION:
                    window.location.hash = TrialAuthPage.USER_NOT_FOUND;
                    return USER_NOT_FOUND_PAGE;
                case DEMO_SHOW_VERIFICATION_EMAIL_REQUIRED_PAGE_ACTION:
                    window.location.hash = TrialAuthPage.VERIFICATION_EMAIL_REQUIRED;
                    return VERIFICATION_EMAIL_REQUIRED_PAGE;
                case DEMO_SHOW_MOBILE_BLOCKING_PAGE_ACTION:
                    window.location.hash = TrialAuthPage.MOBILE_DEVICES_BLOCKED;
                    return MOBILE_BLOCKING_PAGE;
                default:
                    return state;
            }
        },),
});
