import defaultState from '../defaultState';
import type { FileManagerState } from "../flowTypes";

export default (state: FileManagerState): FileManagerState => ({
        ...state,
        ...defaultState,
        paidShutterstock: {
            ...defaultState.paidShutterstock,
            mctaDisabled: state.paidShutterstock.mctaDisabled
        },
        ghostResources: state.ghostResources,
    });
