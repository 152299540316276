import {
    WELCOME_SECTION_KEY,
    ABOUT_SECTION_KEY,
    PRODUCTS_SECTION_KEY,
    SERVICES_SECTION_KEY,
} from "../../../../../Panel/epics/sectionsBlocks";
import { ConnectionError, DailyLimitError, HighTrafficError, InappropriateLanguageError } from "./ErrorMessages";

export const WHY_CHOOSE_US_SECTION_KEY = 'whyChooseUs';

export const
    CONTENT_TYPES = {
        title: "title",
        paragraph1: "paragraph1",
        paragraph2: "paragraph2",
    },
    TEXT_CLASS_CONTENT_TYPE_MAP = {
        textnormal: CONTENT_TYPES.paragraph1,
        textheading1: CONTENT_TYPES.title,
        textheading2: CONTENT_TYPES.title,
        textheading3: CONTENT_TYPES.title
    },
    CONTENT_TYPE_TO_CLASS_MAP = {
        [CONTENT_TYPES.title]: 'textheading1',
        [CONTENT_TYPES.paragraph1]: 'textnormal',
        [CONTENT_TYPES.paragraph2]: 'textnormal'
    },
    CONTENT_TYPE_OPTIONS = [
        {
            label: "msg: component.text.createAIText.dialog.textBuilder.contentType.title {Title}",
            value: CONTENT_TYPES.title
        },
        {
            label: "msg: component.text.createAIText.dialog.textBuilder.contentType.paragraph1 {1 paragraph}",
            value: CONTENT_TYPES.paragraph1
        },
        {
            label: "msg: component.text.createAIText.dialog.textBuilder.contentType.paragraph2 {2 paragraphs}",
            value: CONTENT_TYPES.paragraph2
        }
    ],
    TOPIC_TYPE_OPTIONS = {
        standard: "standard",
        custom: "custom"
    },
    TOPIC_STANDARD_SECTIONS = [
        {
            value: WELCOME_SECTION_KEY,
            label: "msg: common.welcome {Welcome}"
        },
        {
            value: ABOUT_SECTION_KEY,
            label: "msg: inserter.menuPreview.about {About}"
        },
        {
            value: PRODUCTS_SECTION_KEY,
            label: "msg: common.products {Products}"
        },
        {
            value: SERVICES_SECTION_KEY,
            label: "msg: common.services {Services}"
        },
        {
            value: WHY_CHOOSE_US_SECTION_KEY,
            label: "msg: common.whyChooseUs {Why choose us}"
        },
        {
            value: TOPIC_TYPE_OPTIONS.custom,
            label: "msg: common.myOwnTopic {My own topic}"
        }
    ],
    ERROR_STATES = {
        CONNECTION_ERROR: 'CONNECTION_ERROR',
        DAILY_LIMIT_ERROR: 'DAILY_LIMIT_ERROR',
        INNAPPROPRIATE_LANGUAGE_ERROR: 'INNAPPROPRIATE_LANGUAGE_ERROR',
        HIGH_TRAFFIC_ERROR: 'HIGH_TRAFFIC_ERROR',
    },
    ERROR_STATE_CODES = {
        500: ERROR_STATES.CONNECTION_ERROR,
        429: ERROR_STATES.DAILY_LIMIT_ERROR,
        451: ERROR_STATES.INNAPPROPRIATE_LANGUAGE_ERROR,
        503: ERROR_STATES.HIGH_TRAFFIC_ERROR,
    },
    AITextErrorMap = {
        [ERROR_STATES.CONNECTION_ERROR]: 'Connection error',
        [ERROR_STATES.DAILY_LIMIT_ERROR]: 'Individual limit error',
        [ERROR_STATES.INNAPPROPRIATE_LANGUAGE_ERROR]: 'Moderation error',
        [ERROR_STATES.HIGH_TRAFFIC_ERROR]: 'High traffic error',
    },
    ERROR_STATE_SCREENS = {
        [ERROR_STATES.CONNECTION_ERROR]: ConnectionError,
        [ERROR_STATES.DAILY_LIMIT_ERROR]: DailyLimitError,
        [ERROR_STATES.INNAPPROPRIATE_LANGUAGE_ERROR]: InappropriateLanguageError,
        [ERROR_STATES.HIGH_TRAFFIC_ERROR]: HighTrafficError,
    },
    FIELD_LABELS = {
        gmbKey: 'gmbKey',
        topic: 'topic',
        websiteTitle: 'websiteTitle',
        targetAudience: 'targetAudience',
        extras: 'extras',
        agreeTerms: 'agreeTerms'
    },
    REQUIRED_FIELDS = {
        agreeTerms: 'agreeTerms'
    },
    VALIDATION_FIELDS = {
        gmbKey: {
            min: 1,
            max: 100
        },
        topic: {
            min: 1,
            max: 100
        },
        websiteTitle: {
            max: 100
        },
        targetAudience: {
            max: 100
        },
        extras: {
            max: 250
        }
    };
