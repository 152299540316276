import React from "react";
import cx from 'classnames';
import * as styles from './FieldContainerStyles.css';
import View from './View/index';
import type { FormElement, ContactFormComponent } from "../../flowTypes";
import type { StylesheetsIdToNameMap } from "../../../../Workspace/epics/stylesheets/flowTypes";
import { LINE_HEIGHT_PERCENTAGE } from "../constants";
import { DataSite } from "../../../../../../dal/model/index";
import { replaceTagsWithContent } from '../../../Text/view/replaceTagsWithContent';

type Props = {
    element: FormElement,
    fontSize: number,
    style?: Object,
    containerClassName?: string | null,
    globalVariables: Object,
    component: ContactFormComponent,
    classList?: any[],
    fuButtonStyle: { fontSize: number },
    stylesheetsIdToNameMap: StylesheetsIdToNameMap,
    autoColorMode: boolean,
    firstButtonStylesheetId: string
    site: DataSite
};

export default ({
    element,
    fontSize,
    globalVariables,
    site,
    style = {},
    containerClassName,
    ...fuButtonProps
}: Props) => {
    /* Hard coding line-height as 1.2 times of font-size: WBTGEN-4903*/
    const { name, inputType, values, isRequired, autocomplete = "on" } = element,
        FieldView = View[inputType],
        lineHeight = Math.round(fontSize * LINE_HEIGHT_PERCENTAGE) + "px",
        textStyle = { lineHeight };

    const label = replaceTagsWithContent(name, { globalVariables, site });

    return (
        <div className={cx(styles.container, 'contact-form-field-container', containerClassName)} style={style}>
            {
                (isRequired || !!name)
                    ? <label data-label={label} className={styles.label} style={textStyle}>{label}{isRequired && " *"}</label> // eslint-disable-line max-len
                    : null
            }
            <FieldView
                type={inputType}
                name={name}
                values={values}
                textStyle={textStyle}
                autoComplete={autocomplete}
                globalVariables={globalVariables}
                site={site}
                {...fuButtonProps}
            />
        </div>
    );
};
