import { DataSite } from "../../../../../dal/model";
import type { DataSiteItem } from "../../../../../dal/model/utils/dataSiteItemUtils";
import { PAGE_TREE_PAGE_HIDE } from "../actionTypes";
import updateSiteDataSaga from "../../saga/updateSiteDataSaga";
import type { UpdateSiteDataSagaInput } from "../../saga/updateSiteDataSaga";
import makeResolveWhenFulfilledSaga from "../../../../epics/makeResolveWhenFulfilledSaga";
import { siteDataReceiveOnlyExternalValueActionType } from '../../../App/epics/siteData/valueActionType';
import togglePageHide from "../utils/togglePageHide";

const hidePageSagaWorker = function* (
    site: DataSite,
    targetPage: DataSiteItem
): Generator<any, void, any> {
    // prepare update site map data
    const newSite = togglePageHide(site, targetPage);

    const updateSiteInput: UpdateSiteDataSagaInput = { site: newSite };
    yield* updateSiteDataSaga(updateSiteInput);
};

const listener = makeResolveWhenFulfilledSaga(
    siteDataReceiveOnlyExternalValueActionType,
    PAGE_TREE_PAGE_HIDE,
    hidePageSagaWorker
);

export {
    listener as default,
    hidePageSagaWorker
};
