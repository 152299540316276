import type { MailToData } from "./types";
import Url from "../../../../../../utils/Url";
import { GlobalVariableContactEmailKey } from '../../../../../constants';

const subjectSearchStr = '?subject=';
export const parseMailTo = (input: string): MailToData => {
    if (input.includes(`#${GlobalVariableContactEmailKey}`)) {
        return {
            email: `#${GlobalVariableContactEmailKey}`,
            subject: input.includes(subjectSearchStr) ?
                decodeURIComponent(input.substring(input.indexOf(subjectSearchStr) + subjectSearchStr.length))
                : null
        };
    }

    const inputData = Url(input);

    if (!inputData.protocol || inputData.protocol !== 'mailto:') {
        throw new Error(`Invalid input to parse mailto: ${input}`);
    }

    return {
        email: `${inputData.auth}@${inputData.host}`,
        subject: inputData.query.get('subject', null),
    };
};
