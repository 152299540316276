import getCenteredDialogConfig from "../../../DialogManager/getCenteredDialogConfig";
import { DocumentConfigDialogComponent } from "./components/DocumentConfigDialogComponent";
import { documentConfigDialogReducer } from "./reducer/documentConfigDialogReducer";
import { makeDocumentDefaultState } from "./reducer/makeDocumentDefaultState";

const
    width = 900,
    height = 585;

export default getCenteredDialogConfig({
    width,
    height,
    component: DocumentConfigDialogComponent,
    reducer: documentConfigDialogReducer,
    makeDefaultState: makeDocumentDefaultState
});
