import type { ComponentsMap } from "../../../../redux/modules/children/workspace/flowTypes";
import { isContainer } from "../../../../utils/component";
import { getCmps } from "../componentAttachements/util";

//WBTGEN-19107
//This limits should be applied to relInTop, relInBottom, height of nonContainerCmp
//So that the cmps do not grow very big - there is some max limit of size of div in each browser and if this
// divs exceed that limit, pushdown do not work correctly

const limit = 2000000;

const limitSize = (size: number): number => {
    if (size > limit) { return limit; }
    if (size < (-1 * limit)) { return (-1 * limit); }
    return size;
};

export const limitAllSizes = (cmpsMap: ComponentsMap): ComponentsMap => {
    let cmps = getCmps(cmpsMap), newCmpsMap = {};
    cmps.forEach(cmp => {
        const { id, kind, relIn, height } = cmp;
        newCmpsMap[id] = {
            ...cmp,
            height: !isContainer(kind) ? limitSize(height) : height,
            relIn: relIn ? {
                ...relIn,
                top: limitSize(relIn.top),
                bottom: limitSize(relIn.bottom)
            } : null
        };
    });
    return newCmpsMap;
};
