import React from 'react';
import StandardDialog from '../../../../../view/common/dialogs/StandardDialog/index';
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import styles from "./PublishSitewit.css";
import { injectIntl, Intl } from "../../../../../view/intl/index";
import { PrimaryButton } from "../../../../../view/common/Button/index";
import { closeDialogByIdAction } from "../../../../App/actionCreators/index";
import PublishSiteWitDialogId from "./PublishSiteWitDialogId";
import { replaceWithSiteSettingsDialog } from "../../../../SiteSettings/SiteSettingsDialog/actionCreators";
import { SiteSettingsTabName } from "../../../../SiteSettings/SiteSettingsDialog/constants";

type Props = {
    dispatch: Function;
    intl: Intl;
};

const
    width = 490,
    minHeight = 389;

class PublishSitewitDialog extends React.Component<Props> {
    constructor(props) {
        super(props);
        this.onPublishSitewitClose = this.onPublishSitewitClose.bind(this);
    }

    onPublishSitewitClose() {
        this.props.dispatch(closeDialogByIdAction(PublishSiteWitDialogId));
    }

    onDialogCloseBtnClick = () => {
        this.onPublishSitewitClose();
    };

    onDialogOkBtnClick = () => {
        this.onPublishSitewitClose();
    };

    onSettingsClickHandler = () => {
        this.props.dispatch(replaceWithSiteSettingsDialog({ activeTabKey: SiteSettingsTabName.COOKIE_BANNER }));
    };

    render() {
        const
            googleTrackLabel = "msg: publish.publishSitewit.googleTrack.label {Google Ads tracking added}", //eslint-disable-line
            googleTrackSubLabel = "msg: publish.publishSitewit.googleTrack.subLabel {Access campaign statistics via Google Ads in the control panel.}", //eslint-disable-line
            publishSitewitTip = "msg: publish.publishSitewit.tip {Add a cookie banner so your visitors can opt out of campaign tracking.}", //eslint-disable-line
            {
                intl,
            } = this.props;
        return (
            <StandardDialog
                titleBoxClassName={styles.titleBoxClassName}
                onClose={this.onDialogCloseBtnClick}
            >
                <div className={styles.displayContainer}>
                    <div className={styles.checkBoxCircle} />
                    <div className={styles.headLabel}>
                        {intl.msgJoint(googleTrackLabel)}
                    </div>
                    <div className={styles.subLabel}>
                        {intl.msgJoint(googleTrackSubLabel)}
                    </div>
                    <div className={styles.doneButtonContainer}>
                        <PrimaryButton
                            onClick={this.onDialogOkBtnClick}
                            className={styles.doneButton}
                            autoFocus
                        >
                            {intl.msgJoint("msg: common.Ok {Ok}")}
                        </PrimaryButton>
                    </div>

                </div>
            </StandardDialog>
        );
    }
}

export default getCenteredDialogConfig({ width, minHeight, component: injectIntl(PublishSitewitDialog) });
