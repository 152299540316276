/**
 * !!! THIS MODULE IS DEPRECTED !!!
 * DO NOT EXTEND IT
 *
 * (See https://jira.one.com/browse/WBTGEN-12059 for more)
 */

import Auth from "@aws-amplify/auth";
import type { AppStore } from '../../modules/flowTypes';
import { AWS_SERVICE_ACTION, awsAuthenticationUnsuccessful } from './actions';
import type { AwsActionParams, AWSError } from './types';
import { AwsServiceMap, AwsServiceName } from '../../../services/aws/awsServiceRegistry';
import { joinDisaptchResults } from '../joinDispatchResults';
import { callAwsAmplifyAuth } from "../../../services/aws/callAwsAmplifyAuth";

export const awsMiddleware = (store: AppStore) => (next: Dispatch) => (action: Action) => {
    if (action.type === AWS_SERVICE_ACTION) {
        const {
            serviceName,
            serviceMethod,
            serviceMethodParams = [],
            actionTypes: [requestType, successType, failureType],
        }: AwsActionParams = action.payload;

        if (!Array.isArray(action.payload.actionTypes) || action.payload.actionTypes.length !== 3) {
            throw new Error('Expected an array of three action types for aws service action');
        }

        const requestActionDispatchResults = store.dispatch({
            type: requestType,
            payload: serviceMethodParams,
        });

        const serviceConf = AwsServiceMap[serviceName];
        if (!serviceConf) {
            throw new Error(`Unknown aws service ${serviceName}`);
        }

        let service = serviceConf;
        let promisify = true;
        let fetchCredentialBeforeCall = false;

        if (typeof serviceConf.service !== "undefined") {
            service = serviceConf.service;
        }
        if (typeof serviceConf.promisify !== 'undefined') {
            promisify = serviceConf.promisify;
        }
        fetchCredentialBeforeCall = serviceConf.fetchCredentialBeforeCall === true;

        const onPromiseSuccess = (resolve, data) => {
            const responseActionDispatchResult = store.dispatch({
                type: successType,
                actionParams: serviceMethodParams,
                payload: data,
            });

            resolve(responseActionDispatchResult);
        };
        const onPromiseError = (error: AWSError) => {
            store.dispatch(awsAuthenticationUnsuccessful());
            store.dispatch({
                type: failureType,
                actionParams: serviceMethodParams,
                payload: error,
            });
        };

        const responsePromise = new Promise((resolve) => {
            if (serviceName === AwsServiceName.AUTH) {
                callAwsAmplifyAuth(serviceMethod, serviceMethodParams)
                    .then((data?: any) => onPromiseSuccess(resolve, data))
                    .catch((error: AWSError) => onPromiseError(error));
            } else if (fetchCredentialBeforeCall) {
                Auth.currentUserCredentials()
                    .then((credentials) => {
                        return new Promise((resolve, reject) => {
                            // @ts-ignore
                            Auth.user.getUserData(function (err) {
                                if (err) {
                                    reject(err);
                                } else {
                                    resolve(credentials);
                                }
                            }, { bypassCache: true });
                        });
                    })
                    .then((credentials) => {
                        let servicePromise;
                        if (typeof service === "function") {
                            servicePromise = service({ credentials })[serviceMethod](...serviceMethodParams);
                        } else {
                            servicePromise = service[serviceMethod](...serviceMethodParams);
                        }

                        if (promisify) {
                            servicePromise = servicePromise.promise();
                        }
                        return servicePromise;
                    })
                    .then((data?: any) => onPromiseSuccess(resolve, data))
                    .catch((error: AWSError) => onPromiseError(error));
            } else {
                let servicePromise;
                if (typeof service === "function") {
                    servicePromise = service()[serviceMethod](...serviceMethodParams);
                } else {
                    servicePromise = service[serviceMethod](...serviceMethodParams);
                }

                if (promisify) {
                    servicePromise = servicePromise.promise();
                }
                servicePromise
                    .then((data?: any) => onPromiseSuccess(resolve, data))
                    .catch((error: AWSError) => onPromiseError(error));
            }
        });

        return joinDisaptchResults(requestActionDispatchResults, responsePromise);
    }

    return next(action);
};
