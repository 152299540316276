import cx from 'classnames';
import React, { Component } from "react";
import DialogV2 from '../../../../../view/common/dialogs/DialogV2/index';
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import { ConfigDialogSettings } from "../../constants";
import {
    COMPONENT_CONFIGURATION_COMPLETE,
    COMPONENT_CONFIGURATION_CANCELED
} from "../../../../../redux/modules/children/workspace/actionTypes";
import buildNewState from "../../buildNewState";
import * as styles from './ConfigurationDialog.css';
import ownerEmailIdsEpic from '../../../../OwnerEmails/epic/index';
import type { ConfigDialogProps } from "../flowTypes";
import InputField from '../../../../../view/common/Input/InputField';
import { ValidatedInput } from "../../../../../view/common/Input/ValidatedInputField";
import OwnerEmails from '../../../../OwnerEmails/View/index';
import {
    CONTACT_FORM_EMAIL_CHANGED,
    CONTACT_FORM_SUBJECT_CHANGED,
    CONTACT_FORM_SET_EMAIL,
    CONTACT_FORM_SET_MAIL_CANCELED,
    CONTACT_FORM_CONFIGURATION_DIALOG_MOUNTED
} from "../../actionTypes";
import setEmailEpic from '../../epics/setEmail/index';
import { Msg, injectIntl } from '../../../../../view/intl/index';
import { contactFormAllowArbitraryEmailReducer } from "../../../../App/epics/appConfig/index";
import { isValidEmail } from "../../../../../utils/validation";

class ConfigurationDialog extends Component<ConfigDialogProps> {
    isEmailValid: boolean;
    constructor(props) {
        super(props);
        this.isEmailValid = true;
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeSubject = this.onChangeSubject.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onSave = this.onSave.bind(this);
    }
    componentDidMount() {
        this.props.dispatch({
            type: CONTACT_FORM_CONFIGURATION_DIALOG_MOUNTED
        });
    }
    componentDidUpdate(newProps) {
        this.isEmailValid = this.props.allowArbitraryEmail ? isValidEmail(newProps.setEmailState.email) : true;
    }

    onChangeSubject(value) {
        const { email } = this.props.setEmailState;
        return this.props.dispatch({ type: CONTACT_FORM_SUBJECT_CHANGED, payload: { subject: value, email } });
    }

    onSave() {
        const { email, subject, setEmail } = this.props.setEmailState;
        if (setEmail) {
            this.props.dispatch({
                type: CONTACT_FORM_SET_EMAIL,
                payload: { email, subject }
            });
        } else {
            this.props.dispatch({
                type: COMPONENT_CONFIGURATION_COMPLETE,
                payload: buildNewState(email, subject)
            });
        }
    }

    onClose() {
        const { setEmail } = this.props.setEmailState;
        if (setEmail) {
            this.props.dispatch({ type: CONTACT_FORM_SET_MAIL_CANCELED });
        } else {
            this.props.dispatch({ type: COMPONENT_CONFIGURATION_CANCELED });
        }
    }

    onChangeEmail(email) {
        if (this.props.allowArbitraryEmail) {
            this.isEmailValid = isValidEmail(email);
            this.props.dispatch({ type: CONTACT_FORM_EMAIL_CHANGED, payload: email });
        }
    }

    renderEmailInput() {
        const { allowArbitraryEmail, ownerEmailIds, setEmailState, intl, dispatch } = this.props,
            { email } = setEmailState;

        if (allowArbitraryEmail) {
            return (
                <ValidatedInput
                    className={styles.inputBox}
                    placeholder={`${intl.msgJoint("msg: common.email {Email}")}`}
                    value={email}
                    invalidInputClass={styles.errorMessage}
                    validationMsg="msg: common.emailInvalid {Check that the email is valid.}"
                    isInvalid={!isValidEmail(email)}
                    onChange={this.onChangeEmail}
                    intl={intl}
                    usePropsValue
                />
            );
        } else {
            return (
                <OwnerEmails
                    //@ts-ignore
                    ownerEmailIds={ownerEmailIds}
                    onChangeAction={CONTACT_FORM_EMAIL_CHANGED}
                    selectedEmail={email}
                    dispatch={dispatch}
                />
            );
        }
    }

    render() {
        const { setEmailState: { subject, saveDisabled } } = this.props,
            mctaDisabled = saveDisabled || !this.isEmailValid,
            subjectPlaceHolder = "msg: component.contactForm.writeASubjectOptional {Write a subject (optional)}";

        return (
            <DialogV2
                onClose={this.onClose}
                title={ConfigDialogSettings.title}
                subTitle={ConfigDialogSettings.subTitle}
                bodyClassName={cx(styles.container, styles.dummyContainer)}
                mctaText={ConfigDialogSettings.mctaText}
                mctaHandler={this.onSave}
                sctaHandler={this.onClose}
                noTopBorder
                disabled={mctaDisabled}
            >
                <div className={styles.emailContainer}>
                    <div className={styles.emailLabel}>
                        <Msg k="common.email">Email</Msg>
                    </div>
                    {this.renderEmailInput()}
                </div>

                <div className={styles.subjectContainer}>
                    <div className={styles.subjectLabel}>
                        <Msg k="contact.form.config.emailSubject">Email subject (optional)</Msg>
                    </div>
                    <InputField
                        className={styles.subjectInput}
                        value={subject}
                        onChange={this.onChangeSubject}
                        placeholder={subjectPlaceHolder}
                        title={subject || ''}
                        titleLanguageDependent={!subject}
                    />
                </div>
            </DialogV2>
        );
    }
}

export default {
    ...getCenteredDialogConfig(
        ConfigDialogSettings.width, ConfigDialogSettings.height, injectIntl(ConfigurationDialog)
    ),
    dependsOn: {
        allowArbitraryEmail: contactFormAllowArbitraryEmailReducer,
        ownerEmailIds: ownerEmailIdsEpic.reducer,
        setEmailState: setEmailEpic.reducer
    }
};
