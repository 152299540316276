import type { ImageComponent } from '../flowTypes';
import ScaleStrategy from '../scaleStrategy';
import * as selectors from '../componentSelectors';

export const updateOnScaleStrategyChange = (component: ImageComponent, scaleStrategy: string): ImageComponent => {
    let changedComponent: ImageComponent = { ...component, scaleStrategy };

    if (scaleStrategy === ScaleStrategy.CROP) {
        changedComponent.scale = Math.max(changedComponent.scale, selectors.getMinZoom(changedComponent));
    }

    return changedComponent;
};

export default function (component: ImageComponent, { payload: scaleStrategy }: any): ImageComponent {
    return updateOnScaleStrategyChange(component, scaleStrategy);
}
