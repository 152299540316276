import type { ReorderCmpsEpicState } from "./flowTypes";
import type { ComponentsMap } from "../../redux/modules/children/workspace/flowTypes";
import getCmpTypeById from "./getCmpTypeById";
import { cmpTypes } from "./constants";

export default (
    { relations, groups: oldGroups, groupsForView: newGroups, settings }: ReorderCmpsEpicState,
    componentsMap: ComponentsMap
) => {
    let pageMobileData: any = { relations: [], groups: {}, styles: {}, settings: {} },
        templateMobileData: any = { relations: [], groups: {}, styles: {}, settings: {} };
    (relations || []).forEach(r => {
        if (r.isTemplate) {
            templateMobileData.relations.push(r);
        }
        pageMobileData.relations.push(r);
    });
    let groups = { ...newGroups, ...oldGroups };
    Object.keys(groups).forEach(groupId => {
        const filteredGroup = groups[groupId].filter(id => !!componentsMap[id]);
        if (filteredGroup.length) {
            if (componentsMap[filteredGroup[0]].inTemplate) {
                templateMobileData.groups[groupId] = groups[groupId];
            } else {
                pageMobileData.groups[groupId] = groups[groupId];
            }
        }
    });
    groups = { ...oldGroups, ...newGroups };
    Object.keys(settings).forEach(cmpId => {
        if (getCmpTypeById(cmpId) === cmpTypes.group && groups[cmpId]) {
            const filteredGroup = groups[cmpId].filter(id => !!componentsMap[id]);
            if (filteredGroup.length < 2) return;
            const saveInTemplate = filteredGroup.reduce((count, id) => {
                if (count > 1) return count;
                if (componentsMap[id].inTemplate) count++; // eslint-disable-line
                return count;
            }, 0) > 1;

            if (saveInTemplate) {
                templateMobileData.settings[cmpId] = settings[cmpId];
            } else {
                pageMobileData.settings[cmpId] = settings[cmpId];
            }
        }
    });
    return {
        pageMobileData,
        templateMobileData
    };
};
