import React from "react";
import styles from "./view.css";
import Msg from "../../../view/intl/Msg";
import { closeDialog } from "../../App/actionCreators/index";
import OpenUpgradePlanDialog from "../../TopBar/view/Upgrade/UpgradePlansDialog/OpenUpgradePlanDialog";
import { upgradeDialogOpenedAction } from "../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions";
import { UpgradePlansDialogId } from "../../TopBar/view/dialogIds";
import { SubscriptionData } from "../../../redux/modules/flowTypes";

export const findOutMoreLabel = (
    { dispatch, trackingSource, subscriptionData }: {
        dispatch: Dispatch;
        trackingSource: string;
        subscriptionData: SubscriptionData
    }
) => {
    return (
        <a
            onClick={() => {
                dispatch(closeDialog());
                dispatch(OpenUpgradePlanDialog(subscriptionData));
                dispatch(upgradeDialogOpenedAction({
                    feature: trackingSource,
                    dialogId: UpgradePlansDialogId
                }));
            }}
            className={styles.findOutMoreLabel}
        ><Msg k="demo.login.findOutMore">Find out more</Msg></a>
    );
};
