import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    loaderContent: {
        fontSize: "14px",
        lineHeight: "22px",
        textAlign: "center"
    },
    root: {
        padding: "30px 0px",
        marginTop: "10px",
        [theme.breakpoints.up('md')]: {
            marginTop: "56px",
            padding: "0px",
        },
        userSelect: "none",
    },
    divider: {
        borderBottom: `1px solid ${theme.palette.custom.colorGray_ed}`,
    },
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingRight: "53px",
        [theme.breakpoints.down('md')]: {
            padding: "0",
        },
    },
    leftItem: {
        [theme.breakpoints.down('md')]: {
            width: "100%",
            maxWidth: "100%",
        },
    },
    rightItem: {
        paddingLeft: "130px",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
    },
    content: {
        paddingLeft: "44px",
    },
    contentDescription: {
        fontSize: "15px",
    },
    contentTerms: {
        fontSize: "14px",
        lineHeight: "20px",
        [theme.breakpoints.down('md')]: {
            lineHeight: "25px",
        },
    },
    label: {
        margin: "0px"
    },
    labelText: {
        fontSize: "20px",
        lineHeight: "24px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "18px",
            lineHeight: "26px",
        }
    },
    checkbox: {
        padding: "0px",
        marginRight: "8px"
    },
    checkboxHint: {
        fontSize: "15px",
        marginLeft: "32px",
    }
}), { name: "ConsentBanner" });
