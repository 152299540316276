import { connect } from 'react-redux';
import React from "react";
import { injectIntl, Intl } from "../../../view/intl/index";
import * as styles from "./SeoTab.css";
import CheckBoxWithLabel from "../../../view/common/CheckBox/CheckBoxWithLabel";
import * as actionTypes from "./actionTypes";
import { makeEpicStateSelector } from '../../../epics/makeEpic';
import { seoGlobalDataEpic } from './seoGlobalDataEpic';
import type { SeoGlobalDataStateType } from './seoGlobalDataEpic';

type SeoTabPropsWithIntl = {
    intl: Intl,
    dispatch: Dispatch,
    seo: SeoGlobalDataStateType
}
class SeoTab extends React.Component<SeoTabPropsWithIntl> {
    componentDidMount() {
        this.props.dispatch({ type: actionTypes.SEO_TAB_MOUNT });
    }

    displayEnableSeoArea() {
        const
            {
                intl,
            } = this.props,
            seoTipLabel = "msg: tip {Tip:}",
            learnMoreMsg = "msg: common.learnMore {Learn more}",
            learnMoreMsgLink = "msg: seoTab.tip.msgLink {https://help.one.com/hc/en-us/articles/360000346498}",
            seoTipMsg = "msg: seoTab.tip.msg {Add text to your site that use the keywords you want your website to be found on. You can also add meta descriptions to your individual pages via the page options icon in the page list. This will  make your website more visible to users searching for you.}"; // eslint-disable-line max-len
        return (
            <div className={styles.tipContainer}>
                <div className={styles.tipLabel}>
                    {intl.msgJoint(seoTipLabel)}
                </div>
                <div>
                    <div>{intl.msgJoint(seoTipMsg)}</div>
                    <a
                        className={styles.learnMoreLink}
                        href={intl.msgJoint(learnMoreMsgLink)}
                        target="_blank"
                    >
                        {intl.msgJoint(learnMoreMsg)}
                    </a>
                </div>
            </div>
        );
    }

    render() {
        const
            {
                dispatch,
                seo: {
                    enableSeo,
                },
            } = this.props,
            enableSeoLabel = "msg: seoTab.enableSeoBanner {Allow search engines to show your site in their search results}"; // eslint-disable-line max-len

        return (

            <div className={styles.bodyContainer}>
                <div>
                    <CheckBoxWithLabel
                        label={enableSeoLabel}
                        isChecked={enableSeo}
                        onClick={() => {
                            dispatch({ type: actionTypes.ENABLE_SEO_TOGGLE });
                        }}
                        isSliderStyle
                        className={styles.enableCheckboxLabel}
                    />
                </div>
                {enableSeo && this.displayEnableSeoArea()}
            </div>
        );
    }
}
const seoGlobalDataSelector = makeEpicStateSelector(seoGlobalDataEpic.valueActionType);

const mapStateToProps = (appState) => ({ seo: seoGlobalDataSelector(appState) });
export default injectIntl(connect(mapStateToProps)(SeoTab));
