/* eslint-disable max-len */

import React from 'react';
import { connect } from "react-redux";
import cx from 'classnames';
import { injectIntl } from "../../../view/intl/index";
import styles from './ThemePagePreview.css';
import { THEME_COLORS } from "../constants";
import { ColoredSwatch } from "../../ColorPicker/view/ColoredSwatch";
import { makeEpicStateSelector } from "../../../epics/makeEpic";
import { currentPageIdEpicStateFromAppStateSelector } from "../../App/epics/currentPageId/index";
import LoadingCom from "../../../view/common/LoadingIndicator/index";
import ThemePagePreview from "./ThemePagePreview";
import type { ColorTabServiceDefaultStateType } from "../../SiteSettings/ColorThemeData/colorThemeServiceSiteSettingsEpic";
import { siteSettingsValueActionType } from "../../App/epics/siteSettings/valueActionType";
import currentPageNameValueActionType from "../../App/epics/currentPageName/valueActionType";
import { processDataForThemePreview } from "../utils/processDataForThemePreview";
import { siteDataValueActionType } from "../../App/epics/siteData/valueActionType";
import getPageAndTemplateMobileData from "../../MobileViewEditor/getPageAndTemplateMobileData";
import { DataSite } from "../../../../dal/model/index";
import type { SiteSettings } from "../../App/epics/siteSettings/flowTypes";
import { colorThemeServiceSiteSettingsDataSelector } from "../../SiteSettings/ColorThemeData/selectorActionTypes";

type Props = {
    themeService: ColorTabServiceDefaultStateType,
    currentPageId: string,
    currentPageName: string,
    siteMap: DataSite,
    siteSettings: SiteSettings
};

class ThemePagePreviewWithColors extends React.Component<Props> {
    render() {
        const
            {
                themeService: { computedThemeColorsForOnBoarding, computedDataPageSetForOnBoarding },
                currentPageId,
                currentPageName,
                siteSettings,
                siteMap
            } = this.props,
            getThemePagePreview = () => {
                if (computedDataPageSetForOnBoarding && computedThemeColorsForOnBoarding) {
                    const
                        { pages: dataPages, template: dataTemplate, stylesheet: dataStylesheet } = computedDataPageSetForOnBoarding,
                        {
                            page,
                            componentsMap,
                            modifiedSiteSettings,
                            modifiedStylesheets,
                            template,
                        } = processDataForThemePreview({
                            dataPages,
                            dataTemplate,
                            dataStylesheet,
                            currentPageId,
                            siteSettings,
                            themeColors: computedThemeColorsForOnBoarding,
                        });
                    return (<ThemePagePreview
                        stylesheets={modifiedStylesheets}
                        currentPageId={currentPageId}
                        currentPageName={currentPageName}
                        template={template}
                        componentsMap={componentsMap}
                        siteSettings={modifiedSiteSettings}
                        siteMap={siteMap}
                        mobileData={getPageAndTemplateMobileData(page.mobileData, template.mobileData)}
                        /*localizationsByTemplateId={localizationsByTemplateId}
                        messages={messages}*/
                    />);
                }
                return <LoadingCom />;
            },
            renderColorSwatches = () => {
                if (computedThemeColorsForOnBoarding) {
                    return THEME_COLORS.map((colorKey) => <ColoredSwatch
                        key={colorKey}
                        style={{ pointerEvents: 'none', height: 80, width: 80 }}
                        color={computedThemeColorsForOnBoarding[colorKey]}
                    />);
                } else {
                    return <LoadingCom />;
                }
            };

        return (
            <div className={cx(styles.container)}>
                <div className={styles.swatchContainer}>
                    {renderColorSwatches()}
                </div>
                <div className={styles.pagePreviewContainer}>
                    {getThemePagePreview()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (appState) => ({
    themeService: colorThemeServiceSiteSettingsDataSelector(appState),
    currentPageId: currentPageIdEpicStateFromAppStateSelector(appState),
    currentPageName: makeEpicStateSelector(currentPageNameValueActionType)(appState),
    siteMap: makeEpicStateSelector(siteDataValueActionType)(appState),
    siteSettings: makeEpicStateSelector(siteSettingsValueActionType)(appState).current
});
export default injectIntl(connect(mapStateToProps)(ThemePagePreviewWithColors));
