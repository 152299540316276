import makeEpic from "../../../../epics/makeEpic";
import { receiveOnly } from "../../../../epics/makeCondition";
import { LOCALE_CHANGED } from "../../../presentational/LocaleList/actionTypes";
import TopBarLanguagesEpicVAT from '../../../TopBar/epics/languages/valueActionType';
import { COMPONENT_CONFIGURATION_COMPLETE } from "../../../../redux/modules/children/workspace/actionTypes";
import { makeActionForwardToSelectedComponent } from '../../../../redux/forwardTo';
import { openDialog, closeDialog } from '../../../App/actionCreators/index';
import { type WidgetEpicConfig } from '../types';

const epicFactory = (epicConfig: WidgetEpicConfig) => {
    const {
        defaultState,
        valueActionType,
        setLinkAction,
        updatePropsAction,
        showUpdateLinkDialogAction,
        closeDialogActions,
        configurationDialogId,
        codeParser,
        payloadExtractor,
    } = epicConfig;

    const addComponentAction = params => ({ type: COMPONENT_CONFIGURATION_COMPLETE, payload: params }),
        updateComponentPropsAction = params => makeActionForwardToSelectedComponent({ type: updatePropsAction, payload: params });

    const closeDialogUpdaters = (actions) => actions.map(action => ({
        conditions: [action],
        reducer: ({ state }) => ({ state, actionToDispatch: closeDialog() })
    }));

    const epic = makeEpic({
        defaultState,
        valueActionType,
        updaters: [
            {
                conditions: [LOCALE_CHANGED],
                reducer: ({ state, values: [locale] }) => ({
                    state,
                    actionToDispatch: updateComponentPropsAction({ locale })
                })
            },
            {
                conditions: [
                    setLinkAction,
                    receiveOnly(TopBarLanguagesEpicVAT)
                ],
                reducer: ({ state, values: [payload, { uiLocale }] }) => {
                    const
                        { replace = false } = payload,
                        params = codeParser(payloadExtractor(payload));

                    // Use UI locale if no explicit local comes from code parsing
                    // @ts-ignore
                    params.locale = params.locale || uiLocale;

                    return {
                        state,
                        actionToDispatch: replace
                            ? updateComponentPropsAction(params)
                            : addComponentAction(params)
                    };
                }
            },
            {
                conditions: [showUpdateLinkDialogAction],
                reducer: ({ state, values: [props] }) => ({
                    state,
                    actionToDispatch: openDialog(configurationDialogId, { ...props, replace: true })
                })
            },
            ...closeDialogUpdaters([...closeDialogActions, setLinkAction])
        ]
    });

    return epic;
};

export { epicFactory };
