import cx from "classnames";
import getCenteredDialog from "../../../DialogManager/getCenteredDialogConfig";
import { configurationDialogFactory } from '../configurationDialogFactory';
import { InstagramDialogHeight, DialogWidth } from '../constants';
import { WIDGETS_INSTAGRAM_SET_LINK } from "./actionTypes";
import { validateLinkOrCode } from '../utils';
import { parseInstagramCode } from './utils/parseInstagramCode';
import Icons from '../view/Icons.css';

const validateInstagramData = validateLinkOrCode([], parseInstagramCode);

const
    configurationDialogId = "WIDGETS_INSTAGRAM_CONFIG_DIALOG",
    configurationDialogView = configurationDialogFactory({
        saveAction: WIDGETS_INSTAGRAM_SET_LINK,
        dialogIconClass: cx(Icons.instagram, Icons.dialog),
        dialogTitle: "msg: widgets.instagram.label {Instagram}",
        info: "msg: widgets.instagram.config.info {Add an Instagram post to your page.}",
        subInfo: "msg: widgets.instagram.configDialog.copyPaste {Go to Instagram and select \'…\' on the post you want to share, click \'Copy link\' and paste it below.}", // eslint-disable-line max-len
        learnMore: "msg: widgets.instagram.config.learnMore {https://help.one.com/hc/en-us/articles/10120387778705}",
        inputPlaceHolder: "msg: widgets.instagram.configDialog.placeHolder {Paste Instagram link}",
        isLinkValid: validateInstagramData,
        termsOfService: "msg: widgets.instagram.config.termsOfService {By using this widget, you agree to Instagram\'s {termsOfServiceLink}.}", // eslint-disable-line max-len
        termsOfServiceLink: "msg: widgets.instagram.config.termsOfServiceLink {https://help.instagram.com/581066165581870 }",
    }),
    configurationDialog = getCenteredDialog({
        width: DialogWidth,
        height: InstagramDialogHeight,
        component: configurationDialogView
    });

export { configurationDialogId, configurationDialog };
