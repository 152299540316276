import { arrayToMapById } from "./globalStyle/arrayToMap";
import styleLink from "../Link/globalStyle/kind";
import styleText from "./globalStyle/kind";
import * as selectors from "../../Workspace/epics/stylesheets/selectors";
import type { Stylesheets } from "../../Workspace/epics/stylesheets/flowTypes";

export const makeTextGlobalStylesNamesMap = (stylesheets: Stylesheets) => {
    const
        textGlobalStyle = selectors.getStylesByType(styleText)(stylesheets.styles),
        linkGlobalStyle = selectors.getStylesByType(styleLink)(stylesheets.styles),
        styles = [...textGlobalStyle, ...linkGlobalStyle],
        globalStylesMap = arrayToMapById(styles);

    return globalStylesMap;
};
