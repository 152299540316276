import React from 'react';
import styles from './Slider.css';
import RCSlider from './RCSlider';
import PropertyContainer from "../PropertyContainer/index";
import { type PpOrMCTASliderProps } from "./flowTypes";

const PPSlider = (
    { label, valueLabel, style, disabled, valueLabelClassName, topSpacing, dataTestId, ...rest }: PpOrMCTASliderProps,
) => (
    <PropertyContainer
        label={label}
        valueLabel={valueLabel}
        dataTestId={dataTestId}
        style={style}
        disabled={disabled}
        valueLabelClassName={valueLabelClassName}
        className={topSpacing && styles.ppSliderContainer}
    >
        <div style={{ padding: '5px 0' }}>
            <RCSlider {...rest} disabled={disabled} />
        </div>
    </PropertyContainer>
);

export {
    PPSlider as default,
    PPSlider,
};
