import { makeApplyEditorChangesReducer } from "./helpers/helpers";
import { getSelectedText } from "../editorUtils/methods/getters/getSelectedText";
import { applyFormatValue } from "../editorUtils/methods/helpers/applyFormatValue";
import { mergeNodes } from "../editorUtils/methods/helpers/mergeNodes";
import { TEXT_GLOBAL_STYLE_FORMATS } from '../../../../oneweb/Text/constants';
import { TINY_MCE_SET_GLOBAL_STYLE } from "../actionTypes";
import { selectWord } from "../editorUtils/methods/helpers/selection";
import { removeGlobalStylesAndFormats } from './removeGlobalStylesAndFormatsUpdater';
import { TEXT_STYLE_TYPE } from '../../../../oneweb/Text/globalStyle/kind';
import { getGlobalstyleClassNameFromStyle } from '../../../../RenderGlobalstyles/getGlobalstyleClassName';
import { isSafari } from "../../../../../../utils/browser";

const onSelectGlobalStyleUpdater = {
    conditions: [TINY_MCE_SET_GLOBAL_STYLE],
    reducer: makeApplyEditorChangesReducer(({ state, scope, values: [{ stylesheetId }], editor }) => {
        const { stylesheets } = scope,
            textGlobalStyles = stylesheets.styles.filter(s => s.type === TEXT_STYLE_TYPE),
            textGlobalStyleIds = textGlobalStyles.map(({ id }) => id),
            textGlobalStyleClasses = textGlobalStyles.map(getGlobalstyleClassNameFromStyle),
            selectedGlobalStyle = textGlobalStyleClasses[textGlobalStyleIds.indexOf(stylesheetId)];

        if (!getSelectedText(editor)) {
            selectWord(editor);
        }

        /**
         * Entire Paragraph will be selected when a part of it is selected for global style changes.
         */
        if (isSafari()) {
            // @ts-ignore
            editor.selection.select(editor.selection.getNode(), true);
        }

        let selection = editor.selection.getSel();
        let range = selection.getRangeAt(0);
        let selectedBlocks = editor.selection.getSelectedBlocks();
        if (selectedBlocks && selectedBlocks.length) {
            let startNode = selectedBlocks[0];
            let endNode = selectedBlocks[selectedBlocks.length - 1];

            range.setStart(startNode, 0);
            range.setEnd(endNode, endNode.childNodes.length);

            editor.selection.setRng(range);
        }

        const selectedNode = editor.selection.getNode();

        if (
            !selectedNode.textContent
            && selectedNode.parentElement
            && selectedNode.parentElement.tagName === 'P'
            && selectedNode.parentElement.firstChild === selectedNode
            && selectedNode.parentElement.lastChild === selectedNode
        ) {
            // $FlowFixMe
            editor.selection.select(selectedNode.parentElement, false);
        }

        removeGlobalStylesAndFormats(TEXT_GLOBAL_STYLE_FORMATS, editor);

        applyFormatValue(editor, selectedGlobalStyle, 'true');
        mergeNodes(editor);

        return {
            state: {
                ...state,
                selectedGlobalStyle,
            },
            scope
        };
    })
};

export {
    onSelectGlobalStyleUpdater,
};
