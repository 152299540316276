/* eslint-disable max-len */

import React from 'react';
import { injectIntl } from '../../view/intl/index';
import { Dialog, DialogBody } from '../../view/common/dialogs/baseDialog/index';
import getCenteredDialogConfig from '../DialogManager/getCenteredDialogConfig';
import { HIDE_NO_TOUCH_SCREEN_SUPPORT_DIALOG, NO_TOUCH_SCREEN_SUPPORT_TOGGLE_DONT_SHOW_AGAIN } from './actionTypes';
import DialogTitleBox from "../../view/common/dialogs/baseDialog/DialogTitleBox";
import HorizontalSpacer from "../../view/common/HorizontalSpacer";
import DialogCheckBox from "../../view/common/dialogs/baseDialog/DialogCheckBox";
import { StripTypes } from "../../view/common/dialogs/baseDialog/Dialog";
import warningStyles from '../../view/common/dialogs/WarningDialog/WarningDialog.css';
import styles from './styles.css';
import DialogFooter from "../../view/common/dialogs/baseDialog/DialogFooter";
import { unsupportedMessageDialogEpic } from "./epic";

const
    width = 480,
    height = 340,
    touchDeviceTitle = 'msg: unsupported.touchScreen.title {Unsupported touch screen}',
    getTouchMessage = intl => intl.msgJoint('msg: unsupported.touchScreen.message {Website Builder does not support the touch screen on your device, but you are welcome to continue anyway.}'),
    upgradeBrowserDialogComponent = injectIntl(({ intl, dispatch, config }) => {
        const mctaHandler = () => dispatch({ type: HIDE_NO_TOUCH_SCREEN_SUPPORT_DIALOG });
        return (
            <Dialog stripType={StripTypes.WARNING} onClose={mctaHandler}>
                <DialogTitleBox
                    title={touchDeviceTitle}
                    iconClass={warningStyles.iconWarning}
                    titleClass={styles.unsupportedBrowserTitle}
                    className={warningStyles.warningDialogTitle}
                />
                <DialogBody>
                    <p>{getTouchMessage(intl)}</p>
                    <HorizontalSpacer />
                    <DialogCheckBox
                        isChecked={config.doNotShowAgain}
                        label="msg: common.dontShowAgain {Don't show this again}"
                        onClick={() => { dispatch({ type: NO_TOUCH_SCREEN_SUPPORT_TOGGLE_DONT_SHOW_AGAIN }); }}
                    />
                </DialogBody>
                <DialogFooter
                    mctaText="msg: common.continue {Continue}"
                    mctaHandler={mctaHandler}
                    noTopBorder
                    className={warningStyles.warningDialogFooter}
                />
            </Dialog>
        );
    }),
    upgradeBrowserDialog = {
        ...getCenteredDialogConfig(width, height, upgradeBrowserDialogComponent),
        dependsOn: {
            config: unsupportedMessageDialogEpic.reducer
        }
    };

export const dialog = upgradeBrowserDialog;

