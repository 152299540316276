import * as R from 'ramda';
import { createSelector } from 'reselect';
import * as path from "../../mappers/path";
import { getColorOpacity, alphaToOpacity } from "../../components/oneweb/Background/utils/index";
import * as bgMappers from "../../mappers/background/index";
import type { AnyComponent } from "../../redux/modules/children/workspace/flowTypes";
import type { Color } from '../../mappers/flowTypes';

function fixer(attribute, defaultValue) {
    return R.pipe(
        R.defaultTo({}),
        R.ifElse(
            R.both(R.has(attribute), R.pipe(R.prop(attribute), R.isNil, R.not)),
            R.prop(attribute),
            R.always(defaultValue)
        )
    );
}

export const
    getWidth = ({ width }: any) => width, // TODO fix for test
    getHeight = ({ height }: any) => height,
    getStyle = ({ style }: any) => style,
    getBorder = createSelector(
        getStyle,
        style => fixer("border", {})(style)
    ),
    getBorderStyle = createSelector(
        getBorder,
        border => fixer('style', null)(border)
    ),
    getBorderColor = createSelector(
        getBorder,
        border => fixer('color', null)(border)
    ),
    getBorderWidth = createSelector(
        getBorder,
        border => fixer('width', null)(border)
    ),
    getBorderOpacity = createSelector(
        getBorderColor,
        color => color && getColorOpacity(color)
    ),
    getCorner = createSelector(
        getBorder,
        border => fixer('corners', null)(border)
    ),
    getPadding = createSelector(
        getStyle,
        style => fixer('padding', {})(style)
    ),
    getPaddingTop = createSelector(
        getPadding,
        padding => fixer('top', 0)(padding)
    ),
    getPaddingBottom = createSelector(
        getPadding,
        padding => fixer('bottom', 0)(padding)
    ),
    getPaddingLeft = createSelector(
        getPadding,
        padding => fixer('left', 0)(padding)
    ),
    getPaddingRight = createSelector(
        getPadding,
        padding => fixer('right', 0)(padding)
    ),
    // TODO: default values should not be in selectors
    getSpacing = createSelector(
        getBorderWidth,
        inBorderWidth => {
            const
                borderWidth = inBorderWidth || [0, 0, 0, 0],
                [top, right, bottom, left] = borderWidth;
            return { top, right, bottom, left };
        }
    ),
    getInnerWidth = createSelector(
        getWidth,
        getSpacing,
        (width, spacing) => Math.max(0, width - spacing.left - spacing.right)
    ),
    getInnerHeight = createSelector(
        getHeight,
        getSpacing,
        (height, spacing) => Math.max(0, height - spacing.top - spacing.bottom)
    ),
    getBackground = createSelector(
        getStyle,
        style => R.path([path.background], style)
    ),
    getBackgroundColor = createSelector(
        getBackground,
        background => R.path([path.colorData, path.color], background)
    ),
    getBackgroundBrightColor = createSelector(
        getBackgroundColor,
        color => color && [color[0], color[1], color[2], color[3], 1], // full alpha
    ),
    getBackgroundGradient = createSelector(
        getBackground,
        background => R.path([path.colorData, path.gradient], background)
    ),
    getComponentColorGradientSettings =
        (component: AnyComponent, autoColorMode: boolean): { color: Color, gradient: any, opacity: number } => {
            const
                color = getBackgroundBrightColor(component),
                gradient = getBackgroundGradient(component),
                { selectedGradientTheme } = component,
                defaultAlpha = 0,
                alpha = !autoColorMode ?
                    bgMappers.toAlpha(getBackground(component), defaultAlpha) :
                    bgMappers.toAlphaWithAutoColorMode(getBackground(component), selectedGradientTheme, defaultAlpha)
                        || defaultAlpha,
                opacity = alphaToOpacity(alpha || defaultAlpha);
            return { color, gradient, opacity };
        },
    getBackgroundAssetData = createSelector(getBackground, background => R.path([path.assetData], background)),
    getBackgroundAsset = createSelector(getBackgroundAssetData, assetData => R.path([path.asset], assetData)),
    getBackgroundRepeat = createSelector(getBackgroundAssetData, assetData => R.path([path.repeat], assetData)),
    getBackgroundOverlay = createSelector(getBackgroundAssetData, assetData => R.path([path.overlay], assetData)),
    getBackgroundPosition = createSelector(getBackgroundAssetData, assetData => R.path([path.position], assetData)),
    getBackgroundSize = createSelector(getBackgroundAssetData, assetData => R.path([path.size], assetData)),
    getBackgroundImageScrollEffect =
        createSelector(getBackgroundAssetData, assetData => R.path([path.scrollEffect], assetData)),
    getBackgroundImageOpacity =
        createSelector(getBackgroundAssetData, assetData => alphaToOpacity(R.path([path.opacity], assetData))),
    getComponentImageSettings = (component: Record<string, any>) => {
        const
            asset = getBackgroundAsset(component),
            repeat = getBackgroundRepeat(component),
            overlay = getBackgroundOverlay(component),
            position = getBackgroundPosition(component),
            size = getBackgroundSize(component),
            scrollEffect = getBackgroundImageScrollEffect(component);

        return { asset, repeat, overlay, position, size, scrollEffect };
    };
