/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Select } from '../Components/Select';

import { useIntl } from '../../../../../../../view/intl';
import { selectLanguages } from '../../../../epics/AITextEpic/selectors';

import styles from "../styles.css";
import languageStyles from "./LanguageField.css";

type Languages = {
    map: MapT<string>,
    current: string,
};

type Props = {
    aiTextLanguage: string,
    onChangeAction: Function
};

export const LanguageField = ({ onChangeAction, aiTextLanguage }: Props) => {
    const intl = useIntl();
    const languages: Languages = useSelector(selectLanguages);
    const { map } = languages;

    let languageOptionsMap: Array<{ value: string, label: string }> = [];
    Object.keys(map).forEach((item) => {
        languageOptionsMap.push({
            value: item,
            label: map[item],
        });
    });

    const languageOptions = languageOptionsMap.sort((a, b) => {
        return (a.label > b.label ? 1 : 0) - 0.5;
    });

    const [selectedLanguage, setSelectedLanguage] = useState(aiTextLanguage || 'en_gb');
    useEffect(() => {
        if (!languageOptions.map(x => x.value).includes(aiTextLanguage)) {
            setSelectedLanguage('en_gb');
            onChangeAction("language", 'en_gb');
        }
    }, [aiTextLanguage, languageOptions, onChangeAction]);

    const handleChange = (e) => {
        const value = e.target.value;
        setSelectedLanguage(value);
        onChangeAction("language", value);
    };

    const selectProps = {
        value: selectedLanguage,
        data: languageOptions,
        handleChange,
        inputClasses: {
            root: languageStyles.root,
        }
    };

    return (
        <div className={styles.fieldWrapper} data-testid="ai-text-language-field">
            <div className={styles.fieldLabel}>
                {intl.msgJoint("msg: component.text.createAIText.dialog.generalPreferences.language.label {Text language}")}
            </div>
            <div className={styles.fieldContainer}>
                <Select {...selectProps} />
            </div>
        </div>
    );
};
