import * as R from "ramda";
import { makeEpic } from "../../../../../epics/makeEpic";
import { splitSectionDecorationValueActionType as valueActionType } from './valuaActionType';
import { SPLIT_SECTION_LOCATION, FIX_ALL_HEADER_LAYOUT, SPLIT_SECTION_COMPONENT, SPLIT_SECTION_CHECK_CAN_BE_SPLIT } from "./actionTypes";
import { ReceiveOnlyAttachments } from "../../../../Workspace/epics/componentAttachements/selectorActionTypes";
import {
    ReceiveOnlyComponentsMap,
    SelectedComponentSelector
} from "../../../../Workspace/epics/componentsEval/selectorActionTypes";
import { anyOf, receiveOnly } from "../../../../../epics/makeCondition";
import { SHOW_CONTEXT_MENU } from "../../../../ContextMenu/actionTypes";
import { divideInToGroups } from "./divideInToGroups";
import { LeftPanelOpenStateSelector } from "../../../../Panel/epics/extendedPanel/index";
import { isSectionKind } from "../../../componentKinds";
import { COMPONENTS_DUPLICATED } from "../../../../Workspace/epics/componentsEval/actionTypes";
import { fixAllHeaderSection } from "./requests/index";
import { FIX_ALL_HEADER_SECTION_SUCCESS, FIX_ALL_HEADER_SECTION_FAILURE } from "./requests/actionTypes";
import { workspaceTemplateVAT } from "../../../Template/epics/template/valueActionType";
import { openProgressDialogAction } from "../../../../App/actionCreators/openProgressDialogAction";
import { closeDialogAC } from '../../../../App/actionCreators/closeDialog';
import { addInfoMessage } from "../../../../Toaster/actionCreators";

const defaultState = {
    splitPoints: [],
    canBeSplit: false
};
const LIMIT_OF_GAP_POINTS = 4;
const getSplitPoints = (groups: Record<string, any>, sectionId: string) => {
    const splitPoints: Record<string, any>[] = [];
    groups.forEach((group, index) => {
        const nextGroupIndex = index + 1;
        const nextGroup = groups[nextGroupIndex];
        const gapHeight = nextGroup ? nextGroup.top - group.bottom : null;
        if (gapHeight) {
            splitPoints.push({
                topOfGap: group.bottom,
                bottomOfGap: nextGroup.top,
                gapHeight,
                sectionId
            });
        }
    });
    return splitPoints;
};
const getGroups = (componentsMap: Record<string, any>, componentAttachments: Record<string, any>, id: string) => {
    const componentIdsAttachedToSection = componentAttachments[id] || [];
    if (componentIdsAttachedToSection.length) {
        const idToCmpObjectFn = id => componentsMap[id];
        const componentsInsideSection = R.pipe(
            R.filter(idToCmpObjectFn),
            R.map(idToCmpObjectFn)
        )(componentIdsAttachedToSection);
        return divideInToGroups(componentsInsideSection) || [];
    }
    return [];
};
export const canBeSplitReducer = (componentsMap: Record<string, any>, componentAttachments: Record<string, any>, selectedComponent: Record<string, any>, state: Record<string, any>) => { // eslint-disable-line
    if (!selectedComponent) return { state };
    const { kind, id } = selectedComponent;
    if (isSectionKind(kind)) {
        const groups = getGroups(componentsMap, componentAttachments, id);
        const splitPoints = getSplitPoints(groups, id);
        return {
            state: {
                ...state,
                canBeSplit: !!splitPoints.length
            }
        };
    }
    return { state };
};
export const splitSectionLocationReducer = ({ values: [componentAttachments, componentsMap, sectionId], state: epicState }: Record<string, any>) => { // eslint-disable-line
    const groups = getGroups(componentsMap, componentAttachments, sectionId);
    if (groups.length > 1) {
        const splitPoints = getSplitPoints(groups, sectionId);
        const topSplitPointsWithBiggestGap = R.pipe(
            R.sortBy(R.prop('gapHeight')),
            R.uniq,
            R.reverse,
            R.take(LIMIT_OF_GAP_POINTS)
        )(splitPoints);

        return {
            state: {
                ...epicState,
                splitPoints: topSplitPointsWithBiggestGap
            }
        };
    }
    return {
        state: epicState
    };
};

const getErrorActionMessageOnSplit = (isOkOverall) => {
    return isOkOverall ?
        addInfoMessage(
            "common.pleaseTryAgainLater",
            "msg: common.pleaseTryAgainLater {Please try again later.}"
        ) :
        addInfoMessage(
            "common.conflictError.message.p1",
            "msg: common.conflictError.message.p1 {Website Builder found a conflict while saving some of the documents.}"
        );
};

const epics = makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                anyOf(
                    SPLIT_SECTION_COMPONENT,
                    SHOW_CONTEXT_MENU,
                    SelectedComponentSelector
                )
            ],
            reducer: ({ state }) => ({
                state: {
                    ...state,
                    splitPoints: []
                }
            })
        },
        {
            conditions: [
                LeftPanelOpenStateSelector
            ],
            reducer: ({ values: [{ open: isLeftPanelOpen }], state }) => {
                if (isLeftPanelOpen) {
                    return {
                        state: {
                            ...state,
                            splitPoints: []
                        }
                    };
                }
                return { state };
            }
        },
        {
            conditions: [
                ReceiveOnlyAttachments,
                ReceiveOnlyComponentsMap,
                SelectedComponentSelector
            ],
            reducer: ({ values: [componentAttachments, componentsMap, selectedComponent], state }) => {
                return canBeSplitReducer(componentsMap, componentAttachments, selectedComponent, state);
            }
        },
        {
            conditions: [
                ReceiveOnlyAttachments,
                ReceiveOnlyComponentsMap,
                SPLIT_SECTION_CHECK_CAN_BE_SPLIT
            ],
            reducer: ({ values: [componentAttachments, componentsMap, selectedComponentId], state }) => {
                const selectedComponent = componentsMap[selectedComponentId];
                return canBeSplitReducer(componentsMap, componentAttachments, selectedComponent, state);
            }
        },
        {
            conditions: [
                ReceiveOnlyAttachments,
                ReceiveOnlyComponentsMap,
                COMPONENTS_DUPLICATED
            ],
            reducer: ({ values: [componentAttachments, componentsMap, { newComponentsIds }], state }) => {
                const newSection = R.pipe(
                    R.map(id => componentsMap[id]),
                    R.filter(c => isSectionKind(c.kind)),
                    R.head
                )(newComponentsIds);
                return canBeSplitReducer(componentsMap, componentAttachments, newSection, state);
            }
        },
        {
            conditions: [
                ReceiveOnlyAttachments,
                ReceiveOnlyComponentsMap,
                SPLIT_SECTION_LOCATION
            ],
            reducer: splitSectionLocationReducer
        },
        {
            conditions: [
                receiveOnly(workspaceTemplateVAT),
                FIX_ALL_HEADER_LAYOUT
            ],
            reducer: ({ values: [{ id: templateId },
                selectedComponentsIds], state: epicState }: Record<string, any>) => { // eslint-disable-line
                return {
                    state: epicState,
                    multipleActionsToDispatch: [
                        fixAllHeaderSection({ selectedComponentsIds, templateId }),
                        openProgressDialogAction(
                            'msg: fc.metadataFetch.request.title {Please wait}',
                            'msg: fc.metadataFetch.request.fetching {Fetching file information...}'
                        )
                    ]
                };
            }
        },
        {
            conditions: [
                FIX_ALL_HEADER_SECTION_SUCCESS
            ],
            reducer: ({ values: [{ result, isOkOverall }], state: epicState }: Record<string, any>) => {
                if (result) {
                    window.location.reload();
                    return {
                        state: epicState
                    };
                }
                return {
                    state: epicState,
                    multipleActionsToDispatch: [
                        closeDialogAC(),
                        getErrorActionMessageOnSplit(isOkOverall),
                    ]
                };
            }
        },
        {
            conditions: [
                FIX_ALL_HEADER_SECTION_FAILURE
            ],
            reducer: ({ values: [{ isOkOverall }], state: epicState }: Record<string, any>) => {
                return {
                    state: epicState,
                    multipleActionsToDispatch: [
                        closeDialogAC(),
                        getErrorActionMessageOnSplit(isOkOverall),
                    ]
                };
            }
        }
    ]
});

export {
    epics as default
};
