import React from 'react';
import { connect } from 'react-redux';
import PropertiesPanel from './view/index';
import epic from './epic/index';
import valueActionType from './epic/valueActionType';
import makeStateSelectorReducer from '../../epics/makeStateSelectorReducer';
import type { AppState } from '../../redux/modules/flowTypes';
import {
    getControlsDependenciesForSelectedComponentEpicStateFromAppState
} from "../Workspace/epics/controlsDependenciesForSelectedComponent/index";
import { selectedComponentPropsSelector } from '../Workspace/selectors';
import { makeEpicStateSelector } from "../../epics/makeEpic";
import AUTO_COLOR_LEFT_PANEL_EPIC_VALUE from '../AutoColorLeftPanel/epic/valueActionType';
import {
    isModernLayoutActivatedVAT
} from "../Workspace/epics/isModernLayoutActivatedEpic/valueActionType";
import {
    selectedComponentIsInsideHeaderOrFooterVAT
} from "../Workspace/epics/selectedComponentIsInsideHeaderOrFooterEpic/valueActionType";

const View = props => (
    <div>
        {!props.leftPanel.show && props.state.show && <PropertiesPanel {...props} />}
    </div>
);

export const
    reducer = makeStateSelectorReducer(epic.reducer, valueActionType),
    view = connect((state: AppState) => ({
        state: state.propertiesPanel,
        selectedComponentProps: selectedComponentPropsSelector(state),
        // TODO WBTGEN-7356 this injection should happen in propPanel epic. But PropPanel epic is not easy to extend. Refactoring required.
        controlsDependenciesForSelectedComponent:
            getControlsDependenciesForSelectedComponentEpicStateFromAppState(state),
        leftPanel: makeEpicStateSelector(AUTO_COLOR_LEFT_PANEL_EPIC_VALUE)(state),
        selectedCmpPosition: makeEpicStateSelector(selectedComponentIsInsideHeaderOrFooterVAT)(state),
        isModernLayoutActive: makeEpicStateSelector(isModernLayoutActivatedVAT)(state),
    }))(View);
