import React from 'react';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import * as pagesIds from '../pagesIds';
import pagesTitles from '../pagesTitles';
import type { Props } from '../flowTypes';
import { CaptionStyleSettings } from '../../../../presentational/CaptionStyleSettings/index';
import * as Actions from '../../actionTypes';
import { getDefaultCaptionStyle, getTitleClassicStyle, getTitleGlobalStyle } from "../../utils";
import { findSuitableTextColorName } from "../../../../ThemeGlobalData/utils/commonUtils";
import { getThemeRulesForBackground } from '../../../../ThemeGlobalData/themeRules';
import { CaptionStyles } from '../../../../presentational/CaptionStyle/constants';

const
    id = pagesIds.CAPTIONS_TITLE_STYLE,
    title = pagesTitles[id],
    view = ({
        dispatch,
        selectedComponent,
        selectedComponentProps,
        globalStyles,
        themeColorsData,
        siteFonts,
        navigateToPage,
        themeSettingsData,
        selectedComponentExtension
    }: Props) => {
        const
            { selectedParentTheme } = selectedComponentProps,
            titleGlobalStyle = getTitleGlobalStyle(globalStyles, themeSettingsData.autoColorMode, themeColorsData, selectedParentTheme),
            titleClassicStyle = getTitleClassicStyle(themeSettingsData.autoColorMode, themeColorsData),
            defaultStyleSheet = getDefaultCaptionStyle(
                selectedComponent,
                titleGlobalStyle,
                titleClassicStyle
            );
        return (
            <Page>
                <CaptionStyleSettings
                    autoColorMode={themeSettingsData.autoColorMode}
                    themeColor={selectedComponent.captionTitleTextStyle.themeColor}
                    defaultThemeColor={
                        selectedComponent.previousCaptionStyle === CaptionStyles.TEMPLATE
                            ? findSuitableTextColorName(getThemeRulesForBackground(
                                selectedParentTheme, themeColorsData
                            ).background, themeColorsData)
                            : titleClassicStyle.themeColor
                    }
                    selectedParentTheme={selectedParentTheme}
                    themeColorsData={themeColorsData}
                    styleSheet={selectedComponent.captionTitleTextStyle}
                    defaultStyleSheet={defaultStyleSheet}
                    dispatch={dispatch}
                    selectedComponent={selectedComponent}
                    selectedComponentProps={selectedComponentProps}
                    selectedComponentExtension={selectedComponentExtension}
                    siteFonts={siteFonts}
                    navigateToPage={navigateToPage}
                    fontFamilyChangeAction={Actions.GALLERY_PP_CAPTIONS_TITLE_STYLE_FONT_FAMILY_CHANGE}
                    fontSizeChangeAction={Actions.GALLERY_PP_CAPTIONS_TITLE_STYLE_FONT_SIZE_CHANGE}
                    fontColorChangeAction={
                        themeSettingsData.autoColorMode
                            ? Actions.GALLERY_PP_CAPTIONS_TITLE_STYLE_FONT_COLOR_CHANGE_AUTO_COLOR
                            : Actions.GALLERY_PP_CAPTIONS_TITLE_STYLE_FONT_COLOR_CHANGE
                    }
                    toggleBoldAction={Actions.GALLERY_PP_CAPTIONS_TITLE_STYLE_TOGGLE_BOLD}
                    toggleItalicAction={Actions.GALLERY_PP_CAPTIONS_TITLE_STYLE_TOGGLE_ITALIC}
                    toggleUnderlineAction={Actions.GALLERY_PP_CAPTIONS_TITLE_STYLE_TOGGLE_UNDERLINE}
                    lineHeightChangeAction={Actions.GALLERY_PP_CAPTIONS_TITLE_STYLE_LINE_HEIGHT_CHANGE}
                />
            </Page>
        );
    };

export { id, view, title };
