import React from 'react';
import makeAppStandardErrorDialog from "../../../App/dialogs/makeAppStandardErrorDialog";
import { PAGE_TREE_PAGE_DELETE_CONFIRM } from "../actionTypes";
import { WarningConfirmationDialog } from '../../../../view/common/dialogs/WarningConfirmationDialog';
import { Msg } from '../../../../view/intl';

type Props = {
    dispatch: Dispatch;
};

export default makeAppStandardErrorDialog(({ dispatch }: Props) => (
    <WarningConfirmationDialog
        title="msg: deleteLink {Delete Link}"
        onConfirm={() => dispatch({ type: PAGE_TREE_PAGE_DELETE_CONFIRM })}
        dispatch={dispatch}
    >
        <Msg k="confirmDeleteLinkPage">Delete this link?</Msg>
    </WarningConfirmationDialog>
));
