import { documentConfigDialogDefaultState } from "./documentConfigDialogDefaultState";
import {
    makeCombineReducer,
    makeDefaultStateReducers,
    makePathPayloadToSetterReducer,
    makePayloadToSetterReducer
} from "../../../../../redux/makeReducer/index";
import {
    CHANGE_DOCUMENT_EXTERNAL_URL_ACTION,
    SELECT_FILE_DOCUMENT_ACTION,
    SWITCH_DOCUMENT_CONFIG_TAB_ACTION
} from "../../actions";
import { makeUrlInputReducer } from "../../../../inputControls/input/reducerFactory/index";
import { _FC_SET_SELECTION } from "../../../../../redux/modules/children/fileChooser/actionTypes";
import { DocumentConfigDialogTabIdEnum } from "../constants";

export const documentConfigDialogReducer = makeCombineReducer({
    combineReducers: {
        ...makeDefaultStateReducers(documentConfigDialogDefaultState),
        url: makeUrlInputReducer({ actionType: CHANGE_DOCUMENT_EXTERNAL_URL_ACTION }),
    },
    handleActions: {
        [SWITCH_DOCUMENT_CONFIG_TAB_ACTION]: makePayloadToSetterReducer('currentTabId'),
        [SELECT_FILE_DOCUMENT_ACTION]: makePayloadToSetterReducer(DocumentConfigDialogTabIdEnum.FILE),
        [_FC_SET_SELECTION]: (state, action) =>
            makePathPayloadToSetterReducer([DocumentConfigDialogTabIdEnum.FILE], ['selection', '0'])(state, action)
    }
});
