/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { makeEpicStateSelector } from '../../../../epics/makeEpic';
import * as styles from './styles.css';
import NoMouseEventsPropagation from '../../../../view/common/NoMouseEventsPropagation';
import { RootView } from '../../../RootView/RootView';
import { tinyMceVAT } from '../../../App/epics/tinyMceEpic/valueActionType';
import type { TinyMceEpicState } from '../../../App/epics/tinyMceEpic/flowTypes';
import { TEXT_LINKED_VALUE_TOOLBAR_EDIT_CLIKED, TEXT_LINKED_VALUE_TOOLBAR_CONVERT_TO_TEXT_CLIKED } from '../actionTypes';
import { AppState } from '../../../../redux/modules/flowTypes';

type Props = {
    dispatch: Dispatch,
    state: TinyMceEpicState
}
const stateSelector = makeEpicStateSelector(tinyMceVAT);
export const SelectedLinkedValueToolbar = connect((appState: AppState) => ({ state: stateSelector(appState) }))((props: Props) => {
    const { selectedLinkedNode } = props.state;

    if (!selectedLinkedNode) return null;

    const { bottomPosition, fieldName } = selectedLinkedNode;
    return (
        <RootView>
            <div
                className={styles.linkedValueToolbar}
                style={bottomPosition}
                {...NoMouseEventsPropagation}
            >
                <span style={{ paddingRight: 10 }}>
                    This element is linked to&nbsp;
                    <a
                        data-title="Click to edit."
                        className={styles.link}
                        onClick={() => props.dispatch({ type: TEXT_LINKED_VALUE_TOOLBAR_EDIT_CLIKED })}
                    >
                        {fieldName}
                    </a>
                    <br />
                    You can
                    <a
                        className={styles.link}
                        onClick={() => props.dispatch({ type: TEXT_LINKED_VALUE_TOOLBAR_CONVERT_TO_TEXT_CLIKED })}
                    >
                        &nbsp;convert it&nbsp;
                    </a>
                    to normal text.
                </span>
            </div>
        </RootView>
    );
});
