import React from "react";
export const SvgSocialHidden = props => (
    <svg viewBox="0 0 16 13" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                d="M8 10.562a4.02 4.02 0 01-4.015-4.024A4.02 4.02 0 018 2.513a4.02 4.02 0 014.015 4.025A4.02 4.02 0 018 10.562M8 1C3.582 1 0 5.766 0 6.5 0 7.278 3.582 12 8 12s8-4.744 8-5.5C16 5.746 12.418 1 8 1"
            />
            <path
                fill="currentColor"
                d="M9.812 6.52c0 .997-.807 1.806-1.801 1.806A1.804 1.804 0 016.21 6.52c0-.997.806-1.806 1.801-1.806.994 0 1.801.809 1.801 1.806"
            />
            <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M.47 1.437l15.082 10.166"
            />
        </g>
    </svg>
);
