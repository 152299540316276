import React from 'react';
import Msg from '../../../../view/intl/Msg';
import styles from './styles.css';
import {
    CLOSE_TOOLTIP
} from "../../../Tooltip/actionTypes";

type Props = {
    dispatch: Function,
    productWidgetTooltip?: boolean,
    manageShopTooltip?: boolean,
}

export const AfterSetupTooltips = (props: Props) => {
    const { dispatch } = props;
    return (
        <div className={styles.afterSetupTooltipBody}>
            <div className={styles.afterSetupTooltipcloseBtn}>
                <div
                    className={styles.afterSetupTooltipcloseIconBlack}
                    onClick={() => {
                        dispatch({ type: CLOSE_TOOLTIP });
                    }}
                />
            </div>
            <div className={styles.afterSetupTooltipTitle}>
                {props.productWidgetTooltip &&
                    <Msg
                        k="webshopInserter.productWidget.tooltipTitle"
                    >
                        Add product to your site
                    </Msg>}
                {props.manageShopTooltip &&
                    <Msg
                        k="webshopInserter.manageShop.tooltipTitle"
                    >
                        Manage products and orders
                    </Msg>}
            </div>
            <div className={styles.afterSetupTooltipMessage}>
                {props.productWidgetTooltip &&
                    <Msg
                        k="webshopInserter.productWidget.tooltipMessage"
                    >
                        Use the product widget to display your product on your website
                        and make it available for visitors to purchase.
                    </Msg>}
                {props.manageShopTooltip &&
                    <Msg
                        k="webshopInserter.manageShop.tooltipMessage"
                    >
                        Click here to manage your product, set up new ones,
                        and keep track of your orders.
                    </Msg>}
            </div>
            <div className={styles.afterSetupTooltipActions}>
                <a
                    className={styles.afterSetupTooltipLink}
                    onClick={() => {
                        dispatch({ type: CLOSE_TOOLTIP });
                    }}
                >
                    <Msg k="common.gotIt">Got it</Msg>
                </a>
            </div>
        </div>
    );
};
