import makeEpic from '../../../../epics/makeEpic';
import getPanelWidth from '../../getPanelWidth';
import expandedValueActionType from '../expanded/valueActionType';
import valueActionType from './valueActionType';

export default makeEpic({
    valueActionType,
    updaters: [
        {
            conditions: [
                expandedValueActionType
            ],
            reducer: ({ values: [expanded] }) => ({ state: getPanelWidth(expanded) })
        }
    ]
});
