/* eslint-disable react/button-has-type */

import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { BACK_TO_EDITOR } from '../../../App/actionTypes';
import styles from "./PreviewToolbar.css";
import { injectIntl, Msg } from "../../../../view/intl/index";
import PreviewSwitcher from './previewSwitcher';
import { mobilePreviewAction, desktopPreviewAction } from '../actionCreators';
import { OnecomLogo } from "../../../../view/Logo/index";

const PreviewToolbar = injectIntl(({ mobileView, dispatch }) => {
    return (
        <div className={styles.previewToolbar}>
            <OnecomLogo />
            <div className={styles.rightSideContents}>
                <button
                    className={cx(styles.button, styles.buttonBack)}
                    onClick={() => dispatch({ type: BACK_TO_EDITOR })}
                >
                    <Msg k="preview.toolbar.backToEditor">Back to editor</Msg>
                </button>
                <PreviewSwitcher
                    mobileView={mobileView}
                    onMobilePreview={() => dispatch(mobilePreviewAction())}
                    onDesktopPreview={() => dispatch(desktopPreviewAction())}
                />
            </div>
        </div>
    );
});

export default connect(() => ({}), (dispatch: Dispatch) => ({ dispatch }))(PreviewToolbar);
