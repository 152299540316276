import React from "react";
import { Paper, Tabs, Tab, Box, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import type { TabDataItem } from "./types";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    boxContainer: {
        padding: 0,
        marginTop: "2px",
        borderTop: `1px solid ${theme.palette.custom.colorGray_ed}`,
        borderBottom: `1px solid ${theme.palette.custom.colorGray_ed}`
    },
    tabRoot: {
        padding: "11px 25px 14px",
        fontSize: "18px",
        lineHeight: "26px",
        color: "inherit",
        opacity: 0.7,
        "&.Mui-selected": {
            color: "inherit",
            opacity: 1
        }
    }
}));

function TabPanel(props) {
    const classes = useStyles();
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
            {value === index && (
                <Box className={classes.boxContainer} p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`
    };
}

type TabComponentProps = {
    tabData: TabDataItem[];
    initialTabIndex: number;
};

export const TabComponent = (props: TabComponentProps) => {
    const { tabData, initialTabIndex = 0 } = props;
    const classes = useStyles();
    const [value, setValue] = React.useState(initialTabIndex);

    const handleChange = (_e, newValue) => {
        setValue(newValue);
    };

    return (
        <Grid className={classes.root}>
            <Paper elevation={0}>
                <Tabs value={value} onChange={handleChange} aria-label="tabs">
                    {tabData.map((tab, i) => {
                        return (
                            <Tab
                                key={i}
                                label={tab.label}
                                {...a11yProps(i)}
                                classes={{
                                    root: classes.tabRoot
                                }}
                            />
                        );
                    })}
                </Tabs>
            </Paper>
            {tabData.map((tab, i) => (
                <TabPanel key={i} value={value} index={i}>
                    {tab.component}
                </TabPanel>
            ))}
        </Grid>
    );
};
