import React from 'react';
import { Flex } from '../../reflexbox/index';
import GlobalstyleSelector from '../../../components/presentational/GlobalstyleSelector/index';
import { GlobalstyleDialogInvoker } from '../../../components/presentational/GlobalstyleDialogInvoker/index';
import PropertyContainer from '../PropertyContainer/index';
import type { Props } from "./flowTypes";
import { BUTTON_PROP_PANEL_BUTTON_GLOBAL_STYLE_BTN_PRESSED } from '../../../components/oneweb/Button/actionTypes';

export default ({
    label,
    globalStyleId,
    onChangeAction,
    options,
    optionRenderer,
    kind,
    dispatch,
    autoColorMode,
    currentStylesheetId,
    className,
    ...restProps
}: Props) =>
    <PropertyContainer label={label} className={className}>
        <Flex align="center" justify="space-between">
            <GlobalstyleSelector
                selected={globalStyleId}
                selectedKind={kind}
                onChangeAction={onChangeAction}
                forwardToSelectedComponent
                options={options}
                optionRenderer={optionRenderer}
                dispatch={dispatch}
                currentStylesheetId={currentStylesheetId}
                {...restProps}
            />
            <GlobalstyleDialogInvoker
                onClick={() => dispatch({ type: BUTTON_PROP_PANEL_BUTTON_GLOBAL_STYLE_BTN_PRESSED })}
            />
        </Flex>
    </PropertyContainer>;
