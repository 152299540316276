import React from "react";
import { useDispatch } from "react-redux";
import { Grid, InputBase, IconButton, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import { TEMPLATE_SELECTOR_SEARCHED } from "../actionTypes";

import { useIntl } from '../../../view/intl/index';

import styles from './TemplateSelector.css';

const useStyles = makeStyles((theme) => ({
    templateSearchFieldContainer: {
        display: "flex",
        width: "350px",
        alignItems: "flex-end",
        [theme.breakpoints.down('sm')]: {
            width: "100%"
        },
    },
    searchFieldPaper: {
        width: "100%",
        display: "flex",
        paddingLeft: "30px",
        height: "50px",
        borderRadius: "100px !important",
        alignItems: "center"
    },
    inputField: {
        '& > input': {
            height: "100%",
            padding: "0",
            fontSize: "14px",
            borderRadius: "0px"
        },
        '& > input::placeholder': {
            opacity: 1
        }
    }
}), { name: "templatesSearchField" });

export const TemplateSearchField = ({ value, setValue }) => {
    const classnames = useStyles();
    const dispatch = useDispatch();
    const intl = useIntl();
    return (
        <Grid className={classnames.templateSearchFieldContainer}>
            <Paper elevation={0} variant="outlined" className={classnames.searchFieldPaper}>
                <InputBase
                    value={value}
                    sx={{ ml: 1, flex: 1, margin: 0 }}
                    placeholder={intl.msgJoint("msg: templateSelector.searchTemplates {Search templates}")}
                    inputProps={{ 'aria-label': 'search templates' }}
                    className={classnames.inputField}
                    onChange={(e) => {
                        const v = e.target.value;
                        setValue(v);
                        const makeCall = () => {
                            dispatch({ type: TEMPLATE_SELECTOR_SEARCHED, payload: v });
                        };
                        clearTimeout(e.target.dataset.timer);
                        e.target.dataset.timer = `${setTimeout(makeCall, 500)}`;
                    }}
                    onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                            dispatch({ type: TEMPLATE_SELECTOR_SEARCHED, payload: value });
                        }
                    }}
                />
                {value && <ClearOutlinedIcon
                    className={styles.clearIcon}
                    onClick={() => { setValue(''); dispatch({ type: TEMPLATE_SELECTOR_SEARCHED, payload: '' }); }}
                />}
                <IconButton
                    type="button"
                    sx={{ p: 0 }}
                    aria-label="search"
                    className={styles.searchIcon}
                    onClick={() => dispatch({ type: TEMPLATE_SELECTOR_SEARCHED, payload: value })}
                >
                    <div className={styles.icon} />
                </IconButton>
            </Paper>
        </Grid>
    );
};
