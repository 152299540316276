
import makeEpic from '../../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import { ContentDimensionsSelector } from '../../../../Workspace/epics/componentsEval/selectorActionTypes';
import browserDimensionsValueActionType from '../../../../App/epics/browserDimensions/valueActionType';
import leftPanelWidthValueActionType from '../../../../Panel/epics/width/valueActionType';
import { TemplateWidthActionType } from '../template/selectorActionTypes';

export default makeEpic({
    valueActionType,
    updaters: [
        {
            conditions: [
                browserDimensionsValueActionType,
                leftPanelWidthValueActionType,
                TemplateWidthActionType,
                ContentDimensionsSelector
            ],
            reducer: ({
                values: [
                    { width: browserWidth },
                    leftPanelWidth,
                    templateWidth,
                    contentDimensions
                ]
            }) => {
                const
                    workspaceWidth = browserWidth - leftPanelWidth;

                let x;

                if (workspaceWidth > contentDimensions.width) {
                    x = Math.round((workspaceWidth - templateWidth) / 2);
                } else {
                    x = Math.round((contentDimensions.width - templateWidth) / 2);
                }

                return {
                    state: {
                        x,
                        y: 0
                    }
                };
            }
        }
    ]
});
