import React from 'react';
import { pure } from 'recompose';
import { MctaVerticalBar, MctaCheckbox } from '../../../componentMainActions/index';
import { VideoSettings, VideoTypes } from '../constants';
import * as actionTypes from '../actionTypes';
import { settingsSelector, getSettingValue } from '../selector';
import type { CTAViewParams } from '../../../../view/Workspace/Decorations/ComponentMainActions/flowTypes';
import HorizontalSpacer from '../../../../view/common/HorizontalSpacer';
import type { VideoComponent } from '../flowTypes';
import { videoStatusToaster } from '../toaster/VideoStatusToaster';
import { MUTED_ON_AUTOPLAY } from '../toaster/videoStatus';
import { injectIntl } from '../../../../view/intl/index';
import { VIDEO_AUTOPLAY, VIDEO_HIDE_CONTROLS, VIDEO_LOOP, VIDEO_MUTE, VIDEO_REPEAT,
    VIDEO_AUTO_RESIZE, VIDEO_REPLACE, VIDEO_SETTINGS, VIDEO_SHOW_CONTROLS } from '../VideoFile/translations';

const
    MCTAView = pure(({ selectedComponent, dispatch, children }) => {
        const settings = settingsSelector(selectedComponent);
        const isVideoFile = selectedComponent.state.type === VideoTypes.FILE;
        return isVideoFile ?
            <div>
                <HorizontalSpacer x={5} />
                <MctaCheckbox
                    isChecked={getSettingValue(settings, VideoSettings.muted)}
                    label={VIDEO_MUTE}
                    onClick={() => dispatch({
                        type: actionTypes.VIDEO_PROP_PANEL_PLAY_MUTED,
                        payload: { setting: VideoSettings.muted }
                    })}
                    disabled={getSettingValue(settings, VideoSettings.autoPlay)}
                />
                <HorizontalSpacer x={12} />
                <MctaVerticalBar />
                <HorizontalSpacer x={12} />
                <MctaCheckbox
                    isChecked={getSettingValue(settings, VideoSettings.repeat)}
                    label={VIDEO_LOOP}
                    onClick={() => dispatch({
                        type: actionTypes.VIDEO_PROP_PANEL_PLAY_AGAIN,
                        payload: { setting: VideoSettings.repeat }
                    })}
                />
                <HorizontalSpacer x={12} />
                <MctaVerticalBar />
                <HorizontalSpacer x={12} />
                <MctaCheckbox
                    isChecked={getSettingValue(settings, VideoSettings.autoPlay)}
                    label={VIDEO_AUTOPLAY}
                    onClick={() => {
                        dispatch({
                            type: actionTypes.VIDEO_PROP_PANEL_AUTO_PLAY_MUTED,
                            payload: { setting: VideoSettings.autoPlay }
                        });
                        // @ts-ignore
                        dispatch(videoStatusToaster({ status: MUTED_ON_AUTOPLAY, permanent: false }));
                    }}
                />
                <HorizontalSpacer x={12} />
                <MctaVerticalBar />
                <HorizontalSpacer x={12} />
                <MctaCheckbox
                    isChecked={getSettingValue(settings, VideoSettings.fit)}
                    label={VIDEO_AUTO_RESIZE}
                    onClick={() => {
                        dispatch({
                            type: actionTypes.VIDEO_PROP_PANEL_AUTO_RESIZE,
                            payload: { setting: VideoSettings.fit }
                        });
                    }}
                />
                <HorizontalSpacer x={12} />
                <MctaVerticalBar />
                <HorizontalSpacer x={12} />
                <MctaCheckbox
                    isChecked={!getSettingValue(settings, VideoSettings.showControls)}
                    label={VIDEO_HIDE_CONTROLS}
                    onClick={() => dispatch({
                        type: actionTypes.VIDEO_PROP_PANEL_SHOW_CONTROLS,
                        payload: { setting: VideoSettings.showControls }
                    })}
                />
                {children ? <HorizontalSpacer /> : <HorizontalSpacer x={5} />}
                {children}
            </div> :
            <div>
                <HorizontalSpacer x={5} />
                <MctaCheckbox
                    isChecked={getSettingValue(settings, VideoSettings.autoPlay)}
                    label={VIDEO_AUTOPLAY}
                    onClick={() => dispatch({
                        type: actionTypes.VIDEO_PROP_PANEL_PLAY_AUTOMATICALLY,
                        payload: { setting: VideoSettings.autoPlay }
                    })}
                />
                <HorizontalSpacer x={12} />
                <MctaVerticalBar />
                <HorizontalSpacer x={12} />
                <MctaCheckbox
                    isChecked={getSettingValue(settings, VideoSettings.repeat)}
                    label={VIDEO_REPEAT}
                    onClick={() => dispatch({
                        type: actionTypes.VIDEO_PROP_PANEL_PLAY_AGAIN,
                        payload: { setting: VideoSettings.repeat }
                    })}
                />
                <HorizontalSpacer x={12} />
                <MctaVerticalBar />
                <HorizontalSpacer x={12} />
                <MctaCheckbox
                    isChecked={getSettingValue(settings, VideoSettings.showControls)}
                    label={VIDEO_SHOW_CONTROLS}
                    onClick={() => dispatch({
                        type: actionTypes.VIDEO_PROP_PANEL_SHOW_CONTROLS,
                        payload: { setting: VideoSettings.showControls }
                    })}
                />
                {children ? <HorizontalSpacer /> : <HorizontalSpacer x={5} />}
                {children}
            </div>;
    });

const isCTAUrgent = (component: VideoComponent, dependencies: Record<string, any>) => {
    const
        { asset } = component,
        { missingAssetUrls } = dependencies;

    return asset && asset.url && missingAssetUrls.indexOf(asset.url) > -1;
};

export default {
    editView: MCTAView,
    isCTAUrgent,
    editButtonText: VIDEO_SETTINGS,
    CtaButtonTextView: injectIntl(({ intl }) => intl.msgJoint(VIDEO_REPLACE)),
    ctaOnClick: ({ dispatch, selectedComponent }: CTAViewParams<VideoComponent>) =>
        dispatch({ type: actionTypes.VIDEO_REPLACE_VIDEO_FILE, payload: { component: selectedComponent } })
};
