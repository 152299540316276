import * as React from 'react';
import type { ReactElementRef } from "../../../globalTypes";
import { HoverBoxViewBtnCom } from "./HoverBoxViewBtnCom";
import { HoverBoxPreviewCom } from "../HoverBox/index";
import type { RenderHoverBoxPreview } from "../HoverBox/HoverBoxPreviewCom";

type Props = {
    src: string,
    btnTitle?: boolean,
    btnClassName?: string,
    previewClassName?: string,
    renderPreview?: RenderHoverBoxPreview,
};

export class HoverBoxPreviewBtnCom extends React.Component<Props> {
    static defaultProps = {
        btnTitle: true,
    };

    btnRef:ReactElementRef<HTMLSpanElement>;

    constructor() {
        // @ts-ignore
        super();
        this.btnRef = React.createRef();
    }

    render() {
        const { src, previewClassName, btnTitle, btnClassName, renderPreview } = this.props;

        return (
            <React.Fragment>
                <HoverBoxViewBtnCom ref={this.btnRef} title={btnTitle} className={btnClassName} />
                <HoverBoxPreviewCom
                    targetRef={this.btnRef}
                    src={src}
                    className={previewClassName}
                    renderPreview={renderPreview}
                />
            </React.Fragment>
        );
    }
}
