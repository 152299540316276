const DYNAMIC_TEMPLATE_EPIC = 'DYNAMIC_TEMPLATE_EPIC';
const DYNAMIC_PREVIEW_EPIC = 'DYNAMIC_PREVIEW_EPIC';
const DYNAMIC_SECTION_GENERATION_EPIC = 'DYNAMIC_SECTION_GENERATION_EPIC';

export {
    DYNAMIC_TEMPLATE_EPIC,
    DYNAMIC_PREVIEW_EPIC,
    DYNAMIC_SECTION_GENERATION_EPIC,
    DYNAMIC_TEMPLATE_EPIC as default
};
