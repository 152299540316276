import type { TinyMceEditor } from "../../flowTypes";

export const queryEditor = <T>(func: (arg: TinyMceEditor) => T): T | null => {
    const editor = window.tinyMCE.activeEditor;
    if (!editor || editor.removed) {
        return null;
    }

    return func(editor);
};
