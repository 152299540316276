import React from 'react';
import TextIconButton from '../TextIconButton/index';
import Icons from '../../../view/Icons/index';
import { OPEN_DIALOG } from '../../../redux/modules/actionTypes';
import { injectIntl } from '../../../view/intl/index';
import type { TextShadowButtonPropTypes } from './flowTypes';

export default injectIntl(({ dialogId, intl, dispatch, ...rest }: TextShadowButtonPropTypes) => (
    <TextIconButton
        large
        text={intl.msgJoint('msg: common.spacing {Spacing}')}
        Icon={Icons.BOTTOM}
        onClick={() => dispatch({
            type: OPEN_DIALOG,
            payload: {
                dialogId,
                props: { ...rest, dispatch }
            }
        })}
    />
));
