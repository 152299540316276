export function ItemsFrame(this: any, items: Array<Record<string, any>>) {
    let
        top = Infinity, left = Infinity, bottom = 0, right = 0,
        topItem: Record<string, any> | null = null;

    for (let i = 0; i < items.length; i++) {
        const anItem = items[i];

        top = Math.min(anItem.bbox.top, top);
        bottom = Math.max(anItem.bbox.bottom, bottom);

        // stretch components' widths take whole workspace; don't take them into account
        left = anItem.stretch ? left : Math.max(Math.min(anItem.bbox.left, left), 0);
        right = anItem.stretch ? right : Math.max(anItem.bbox.right, right);

        if (!topItem || anItem.bbox.top < topItem.bbox.top) topItem = anItem;
    }

    this.items = (): Array<Record<string, any>> => [...items];
    this.width = (): number => (right - left);
    this.height = (): number => bottom - top;
    this.minTop = (): number => top;
    this.minLeft = (): number => left;
    this.maxBottom = (): number => bottom;
    this.maxRight = (): number => right;
    this.relTop = (): number => {
        if (!topItem) return 0;

        return topItem.relTo && topItem.relTo.below !== undefined
            ? topItem.relTo.below
            : topItem.bbox.top;
    };
}
