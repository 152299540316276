import React from 'react';
import { Flex } from '../../../../../view/reflexbox/index';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import { getVideoEmbedLink, getDisplayLink } from '../utils';
import { DATA_TEST_ID, DefaultAllowed } from '../constants';
import { WidgetsVimeoKind } from '../kind';
import '../../view/Preview.css';

const TextLinkHeight = 25;
const SpacerHeight = 5;

const VimeoVideoView = props => {
    const { width, height, link, autoplay, loop, portrait, title, byline, showTextLink } = props;
    const videEmbedLink = getVideoEmbedLink(link, { autoplay, loop, portrait, title, byline });
    const videoLink = getDisplayLink(link);
    const displayHeight = showTextLink ? (height - (TextLinkHeight + SpacerHeight)) : height;

    return (
        <Flex data-testid={DATA_TEST_ID} className="widget" column={showTextLink}>
            <iframe
                data-kind={WidgetsVimeoKind}
                src={videEmbedLink}
                width={width}
                height={displayHeight}
                frameBorder="0"
                allowFullScreen
                allow={DefaultAllowed}
            />
            {
                showTextLink &&
                    <Flex column align="center" justify="center">
                        <VerticalSpacer y={SpacerHeight} />
                        <a href={videoLink}>{videoLink}</a>
                    </Flex>
            }
        </Flex>
    );
};

export { VimeoVideoView };

