/* eslint-disable max-len */
import React from 'react';
import * as styles from './styles.css';
import * as actionTypes from "../../actionTypes";
import { closeDialog } from "../../../../App/actionCreators/index";
import { Dialog, DialogBody } from '../../../../../view/common/dialogs/baseDialog/index';
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import { StripTypes } from "../../../../../view/common/dialogs/baseDialog/Dialog";
import { ExceptionGoogleReviewsDialogId } from '../dialogIds';
import { ExceptionGoogleReviewsCommonView } from './ExceptionCommonHandlerView';

type Props = {
    dispatch: Dispatch,
    actionType: string,
};

const DialogWidth = parseInt(styles.DialogWidth, 10),
    DialogHeight = parseInt(styles.DialogHeight, 10);

const ExceptionGoogleReviewsDialog = (props: Props) => {
    const
        { dispatch, actionType } = props,
        closeDialogAC = () => dispatch(closeDialog());
    const getView = () => {
        let view;
        switch (actionType) {
            case actionTypes.NO_REVIEWS:
                view = (
                    <ExceptionGoogleReviewsCommonView
                        titleMessage="msg: component.googleReviews.noReviewsHeader {No Google reviews available}"
                        description="msg: component.googleReviews.noReviewsMessage {It looks like your business hasn’t been reviewed yet. Once you’ve gotten your first reviews, you can come back here to add them to your website.}"
                        primaryBtnText="msg: common.OK {OK}"
                        primaryBtnAction={closeDialogAC}
                    />
                );
                break;
            case actionTypes.LISTING_NOT_VERIFIED:
                view = (
                    <ExceptionGoogleReviewsCommonView
                        titleMessage="msg: component.googleReviews.notVerifiedHeader {Business listing not verified}"
                        description="msg: component.googleReviews.notVerifiedMessage {Website Builder can’t fetch your Google reviews because it looks like your business listing hasn’t been verified yet. Verify your business on Google and come back here to add reviews to your website.}"
                        primaryBtnText="msg: common.goToGoogleToVerify {Go to Google to verify}"
                        secondaryBtnText="msg: common.doItLater {I'll do it later}"
                        secondaryBtnAction={closeDialogAC}
                    />
                );
                break;
            default: return null;
        }

        return view;
    };

    return (
        <Dialog onClose={closeDialogAC} stripType={StripTypes.NONE}>
            <DialogBody className={styles.dialogBody}>
                { getView() }
            </DialogBody>
        </Dialog>
    );
};

const openGoogleReviewDialogAC = {
    type: "OPEN_DIALOG",
    payload: { dialogId: ExceptionGoogleReviewsDialogId }
};

export default getCenteredDialogConfig(DialogWidth, DialogHeight, ExceptionGoogleReviewsDialog);

export {
    openGoogleReviewDialogAC
};

