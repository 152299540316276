// @ts-nocheck
import React, { Component } from "react";
import { Provider } from "react-redux";
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { IntlProvider } from "react-intl";
import { createAppTheme } from "../../../src/dashboard/src/app/theme/index";
import { htmlTheme } from "../../../src/dashboard/src/common/config";
import Toaster from "../components/Toaster/view/index";

import App from "./App";
import ErrorBoundary from '../redux/recoverAfterException/ErrorBoundary/ErrorBoundary';

export default class Root extends Component {
    render() {
        const { store, locale, messages } = this.props;
        const appTheme = createAppTheme(htmlTheme);

        return (
            <Provider store={store}>
                <IntlProvider
                    locale={locale}
                    messages={messages}
                    onError={(err) => {
                        // https://github.com/formatjs/formatjs/issues/465#issuecomment-527634135
                        console.log({ // eslint-disable-line no-console
                            err
                        });
                    }}
                >
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={appTheme}>
                            <CssBaseline />
                            <ErrorBoundary invisible>
                                <Toaster />
                            </ErrorBoundary>
                            <ErrorBoundary>
                                <App />
                            </ErrorBoundary>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </IntlProvider>
            </Provider>
        );
    }
}
