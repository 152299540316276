import kind from './kind';
import { ContactFormAlreadyExist } from './constants';
import type { validateComponentOnAddType } from '../../../redux/modules/children/workspace/flowTypes';

export default (dataToValidate: validateComponentOnAddType) => {
    const { componentsMap } = dataToValidate;
    if (Object.keys(componentsMap).some(key => componentsMap[key].kind === kind)) {
        return ContactFormAlreadyExist;
    }
    return null;
};
