import * as React from "react";
import cx from 'classnames';
import * as styles from './Social.css';
import type { SocialCalcRenderPropsResult } from '../flowTypes';
import { socialLinksConfigsMap } from "../socialLinksConfigs";
import { Msg } from "../../../../view/intl/index";
import { SocialColorSourceType, THEME_DEFAULT } from '../constants';

const Social = (props: SocialCalcRenderPropsResult) => {
    const hasNoContent = props.links.length === 0 && !props.isWorkspace;

    if (hasNoContent) return <div />;

    let width = 0;

    // @ts-ignore
    if (props.base) {
        // @ts-ignore
        width = props.base.width;
    }

    return (
        <div
            className={cx(
                styles.container,
                {
                    [THEME_DEFAULT]:
                        props.themeSettingsData.autoColorMode &&
                        props.links && props.links[0] &&
                        props.links[0].colorType !== SocialColorSourceType.MULTI_COLOR
                }
            )}
            style={{ fontSize: props.size }}
        >
            {
                props.links.length === 0 && <div className={styles.noLinks}>
                    {width < 80 ? null : <Msg k="social.noSocialLinksAdded">No social links added.</Msg>}
                </div>
            }
            {
                (props.links).map((link, index) => {
                    const { svgCmpsByColorByType } = socialLinksConfigsMap[link.kind];
                    const Svg = svgCmpsByColorByType[link.colorType][link.styleType];

                    return <a
                        key={index}
                        href={link.url || undefined}
                        target="_blank"
                        style={{ width: props.size, height: props.size }}
                    >
                        <Svg style={{ width: props.size, height: props.size }} />
                    </a>;
                })
            }
        </div>
    );
};

export default Social;
