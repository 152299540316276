import React from 'react';

import styles from './LinkPageForm.css';
import type { LinkPageFormProps } from "../flowTypes";
import { Input, LabeledInput } from '../../../inputControls/input/view';
import { InputLabel } from '../../../inputControls/common/view';
import DialogCheckBox from '../../../../view/common/dialogs/baseDialog/DialogCheckBox';
import Icons from '../../../../view/Icons';
import LongTextTip from '../../../../view/common/LongTextTip';
import getStyleIntValue from "../../../../utils/getStyleIntValue";

const LABEL_MAX_WIDTH = getStyleIntValue(styles, 'fileOrPageMaxWidth');

type FileOrPageProps = {
    link: string;
    unsetLinkAction: string;
    dispatch: Dispatch;
};

const FileOrPage = ({ link, unsetLinkAction, dispatch }: FileOrPageProps) => (
    <span className={styles.formFileOrPage}>
        <LongTextTip maxWidth={LABEL_MAX_WIDTH}>{link}</LongTextTip>
        &nbsp;
        <Icons.CROSS_WITH_CIRCLE
            onClick={() => dispatch({ type: unsetLinkAction })}
            className={styles.formFileOrPageCloseBtn}
        />
    </span>
);

type Props = LinkPageFormProps & {
    dispatch: Dispatch;
    className?: string;
};

export default (props: Props) => {
    const {
        linkTo: {
            url,
            resource
        },
        unsetLinkAction,
        openInNewWindow,
        name,
        urlOnChangeAction,
        nameOnChangeAction,
        openInNewWindowToggleAction,
        dispatch,
        className
    } = props;

    return (
        <div className={className}>
            <div className={styles.formLinkRow}>
                <InputLabel>{'msg: linkTo {Link to}'}</InputLabel>
                {
                    resource
                        ? <FileOrPage link={resource} unsetLinkAction={unsetLinkAction} dispatch={dispatch} />
                        : <Input
                            state={url}
                            onChangeAction={urlOnChangeAction}
                            dispatch={dispatch}
                            autoFocus
                            decodeValue={false}
                        />
                }
            </div>
            <LabeledInput
                label="msg: linkTitle {Link title:}"
                state={name}
                onChangeAction={nameOnChangeAction}
                inputContainerClassName={styles.formInputContainer}
                dispatch={dispatch}
            />
            <DialogCheckBox
                label="msg: openInNewWindow {Open in new window}"
                isChecked={openInNewWindow}
                onClick={() => dispatch({ type: openInNewWindowToggleAction })}
                containerClassName={styles.formCheckBox}
            />
        </div>
    );
};
