import { Dispatch } from "redux";
import { cloneDeep } from "lodash";
import type { AppStore } from "../../../../redux/modules/flowTypes";
import {
    EXCHANGE_FOR_FACEBOOK_LONG_ACCESS_TOKEN_FAILURE_ACTION,
    EXCHANGE_FOR_FACEBOOK_LONG_ACCESS_TOKEN_SUCCESS_ACTION,
    INITIATE_FB_EXCHANGE_TOKEN_ACTION,
    GET_FB_OWN_MANAGED_ACCOUNTS_SUCCESS_ACTION,
    GET_FB_OWN_MANAGED_ACCOUNTS_FAILURE_ACTION,
    setFacebookFeedAccessCredentials,
    exchangeForFacebookLongAccessTokenAction,
    setManagedFbPagesAction,
    SET_FACEBOOK_FEED_ACCESS_CREDENTIALS_ACTION,
    getFacebookFeedUserMediaAction,
    GET_FACEBOOK_FEED_USER_MEDIA_FAILURE_ACTION,
    unSetFacebookFeedAccessToken,
    GET_FACEBOOK_FEED_ACCESS_TOKEN_SUCCESS_ACTION,
    GET_FACEBOOK_FEED_ACCESS_TOKEN_FAILURE_ACTION,
    DISCONNECT_FACEBOOK_FEED_SUCCESS_ACTION,
    setIsFbPageModeAction,
    TOGGLE_FACEBOOK_FEED_PAGE_VISIBILITY_SUCCESS_ACTION
} from "./actions";
import { setFacebookFeedAccessCredentialsInDemoAction } from "./trialActions";
import { addInfoMessage } from "../../../Toaster/actionCreators";
import { socialAccountsFacebookFeedManagedPages, socialAccountsFacebookFeedPageMode } from "./selectors";
import { openDialog } from "../../../App/actionCreators/index";
import FacebookNoPagesDialogId from "./FacebookNoPagesDialog/FacebookNoPagesDialogId";
import { unsetCookie, setCookie } from "../../../../services/cookie";
import { FACEBOOK_FEED_ACCOUNT_DOMAIN_NAME } from "../../../../../../server/shared/facebookFeed/facebookFeedCookie";
import { getDAL } from "../../../../../dal/index";
import { isWsbDemo } from "../../../../debug/isWsbDemo";
import { TOKEN_EXPIRED_MESSAGE } from "../../../../../../server/shared/facebookFeed/constants";
import { createScheduledAction } from "../../../../redux/middleware/schedule/actionCreators";
import FacebookFeedDisconnectedDialogId from "./FacebookFeedDisconnectedDialog/facebookFeedDisconnectedDialogId";
import { totalFacebookFeedGalleryComponentsSelector } from "../../selectors";
import { fetchTokenMeAccountsData } from "../facebookCommonAction";
import { getAccessTokenCustomExpiry } from "../../../../../../server/shared/facebookFeed/getAccessTokenCustomExpiry";

export const facebookFeedMiddleware = (store: AppStore) => (next: Dispatch) => (action: Action) => {
    if (action.type === INITIATE_FB_EXCHANGE_TOKEN_ACTION) {
        setCookie(FACEBOOK_FEED_ACCOUNT_DOMAIN_NAME, getDAL().getDomain(), { domain: document.location.hostname }, true);
        const isPageMode = socialAccountsFacebookFeedPageMode(store.getState());
        if (isPageMode) {
            fetchTokenMeAccountsData({
                dispatch: store.dispatch,
                successAction: GET_FB_OWN_MANAGED_ACCOUNTS_SUCCESS_ACTION,
                failureAction: GET_FB_OWN_MANAGED_ACCOUNTS_FAILURE_ACTION
            });
        } else {
            // eslint-disable-next-line no-unused-expressions
            window.FB && window.FB.getLoginStatus(function (response) {
                store.dispatch(exchangeForFacebookLongAccessTokenAction(response.authResponse.accessToken));
            });
        }
    }

    if (action.type === GET_FB_OWN_MANAGED_ACCOUNTS_SUCCESS_ACTION) {
        const response = action.payload;
        if (response && response.data && response.data.length > 0) {
            const fbPages = response.data.map(fbPage => {
                return {
                    ...fbPage,
                    isVisible: true
                };
            });
            store.dispatch(setManagedFbPagesAction(fbPages));

            response.data.forEach((pageData) => {
                store.dispatch(exchangeForFacebookLongAccessTokenAction(pageData.access_token, true));
            });
        } else {
            store.dispatch(openDialog(FacebookNoPagesDialogId));
        }
    }

    if (action.type === EXCHANGE_FOR_FACEBOOK_LONG_ACCESS_TOKEN_SUCCESS_ACTION) {
        const {
            access_token: longLivedAccessToken,
            fbUsername,
            isPageAccount,
            // eslint-disable-next-line camelcase
            expires_in,
            fbAccountId,
        } = action.payload;
        unsetCookie(FACEBOOK_FEED_ACCOUNT_DOMAIN_NAME, { domain: '' }, true);
        const managedFbAccounts = cloneDeep(socialAccountsFacebookFeedManagedPages(store.getState()));
        const isPageAccountBool = isPageAccount === 'true';
        // eslint-disable-next-line camelcase
        const accountExpiry = expires_in || Number(getAccessTokenCustomExpiry());
        managedFbAccounts.forEach((managedAccount) => {
            if (managedAccount.id === fbAccountId) {
                managedAccount.access_token = longLivedAccessToken; // eslint-disable-line no-param-reassign
                managedAccount.isPageAccount = isPageAccountBool; // eslint-disable-line no-param-reassign
                managedAccount.isVisible = true; // eslint-disable-line no-param-reassign
                // eslint-disable-next-line camelcase
                managedAccount.expires_in = accountExpiry; // eslint-disable-line no-param-reassign
            }
        });
        store.dispatch(setManagedFbPagesAction(managedFbAccounts));
        store.dispatch(setFacebookFeedAccessCredentials(longLivedAccessToken, fbUsername, isPageAccount === "true", fbAccountId));

        if (isWsbDemo()) {
            if (isPageAccountBool) {
                store.dispatch(setFacebookFeedAccessCredentialsInDemoAction(managedFbAccounts));
            } else {
                store.dispatch(setFacebookFeedAccessCredentialsInDemoAction([{
                    access_token: longLivedAccessToken,
                    id: fbAccountId,
                    isVisible: true,
                    name: fbUsername,
                    expires_in: accountExpiry, // eslint-disable-line camelcase
                    isPageAccount: isPageAccountBool
                }]));
            }
        }
    }

    if (action.type === EXCHANGE_FOR_FACEBOOK_LONG_ACCESS_TOKEN_FAILURE_ACTION) {
        unsetCookie(FACEBOOK_FEED_ACCOUNT_DOMAIN_NAME, { domain: '' }, true);
    }

    if (action.type === SET_FACEBOOK_FEED_ACCESS_CREDENTIALS_ACTION) {
        const isFbPageMode = socialAccountsFacebookFeedPageMode(store.getState());
        if (isFbPageMode) {
            const managedFbAccounts = socialAccountsFacebookFeedManagedPages(store.getState());
            const validAccessTokens: any = [];

            managedFbAccounts.forEach(fbPage => {
                if (fbPage.isVisible) {
                    validAccessTokens.push(fbPage.access_token);
                }
            });
            store.dispatch(getFacebookFeedUserMediaAction(validAccessTokens));
        } else {
            store.dispatch(getFacebookFeedUserMediaAction([action.payload.facebookFeedAccessToken]));
        }
    }

    if (action.type === GET_FACEBOOK_FEED_USER_MEDIA_FAILURE_ACTION) {
        store.dispatch(unSetFacebookFeedAccessToken());
    }

    if (action.type === GET_FACEBOOK_FEED_ACCESS_TOKEN_SUCCESS_ACTION) {
        if (action.payload.areAllPageAccounts) {
            const fbPages: any = [];
            const accessTokens: any = [];
            action.payload.accountInformation.forEach((pageAccountInfo) => {
                const pageData = {
                    access_token: pageAccountInfo.accessToken,
                    id: pageAccountInfo.fbAccountId,
                    name: pageAccountInfo.fbUsername,
                    isVisible: !!pageAccountInfo.isVisible,
                };
                fbPages.push(pageData);
                if (pageData.isVisible) {
                    accessTokens.push(pageData.access_token);
                }
            });
            store.dispatch(setManagedFbPagesAction(fbPages));
            store.dispatch(setIsFbPageModeAction(true));
            store.dispatch(getFacebookFeedUserMediaAction(accessTokens));
        } else {
            const accountInformation = action.payload.accountInformation[0];
            store.dispatch(
                setFacebookFeedAccessCredentials(
                    accountInformation.accessToken,
                    accountInformation.fbUsername,
                    accountInformation.isPageAccount,
                    accountInformation.fbAccountId
                )
            );
        }
    }

    if (action.type === DISCONNECT_FACEBOOK_FEED_SUCCESS_ACTION) {
        store.dispatch(addInfoMessage(
            "cookie.banner.footer.tipText",
            "msg: cookie.banner.footer.tipText {Remember to publish your site to make these changes live.}"
        ));
    }

    if (action.type === GET_FACEBOOK_FEED_ACCESS_TOKEN_FAILURE_ACTION) {
        const totalFacebookFeedComponents = totalFacebookFeedGalleryComponentsSelector(store.getState());
        if (
            action.payload
            && action.payload.message
            && action.payload.message === TOKEN_EXPIRED_MESSAGE
            && totalFacebookFeedComponents > 0
        ) {
            if (action.payload.areAllPageAccounts) {
                store.dispatch(setIsFbPageModeAction(true));
            }
            // Dispatch the dialog a bit later to let the workspace settle in and show the dialog after a delay.
            store.dispatch(
                createScheduledAction({
                    actionToDispatch: openDialog(FacebookFeedDisconnectedDialogId),
                    timeout: 2 * 1000 // Time in milliseconds
                })
            );
        }
    }

    if (action.type === TOGGLE_FACEBOOK_FEED_PAGE_VISIBILITY_SUCCESS_ACTION) {
        const managedFbAccounts = socialAccountsFacebookFeedManagedPages(store.getState());
        const updatedFbPageId = action.payload.fbAccountId;
        const updatedVisibilityMode = action.payload.pageVisibilityMode;

        const validAccessTokens: any = [];

        managedFbAccounts.forEach(fbPage => {
            if (fbPage.id === updatedFbPageId) {
                // eslint-disable-next-line no-param-reassign
                fbPage.isVisible = updatedVisibilityMode;
            }
            if (fbPage.isVisible) {
                validAccessTokens.push(fbPage.access_token);
            }
        });
        store.dispatch(setManagedFbPagesAction(managedFbAccounts));
        store.dispatch(getFacebookFeedUserMediaAction(validAccessTokens));
    }

    return next(action);
};
