
import makeEpic from "../../../epics/makeEpic";
import { GENERAL_INFO_SITE_SETTINGS_TAB_FOCUS_INPUT } from "./actionTypes";

export const generateFieldNameInputId = (fieldName: string | number) => `general-info-input-${fieldName}`;
export const generateFieldNameDivId = (fieldName: string | number) => `general-info-div-${fieldName}`;

export const generalInfoServiceEpic = makeEpic({
    defaultState: {
    },
    valueActionType: 'GENERAL_INFO_SERVICE_EPIC_VALUE',
    updaters: [
        {
            conditions: [GENERAL_INFO_SITE_SETTINGS_TAB_FOCUS_INPUT],
            reducer: ({ state, values: [fieldName] }) => {
                const input = document.getElementById(generateFieldNameInputId(fieldName));
                if (input) {
                    input.focus();
                }
                return { state };
            }
        }
    ]
});
