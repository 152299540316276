import React from 'react';
import * as pageIds from '../../../Background/propertiesPanel/pageIds';
import * as hoverBoxPageIds from '../pageIds';
import pageTitles from '../pageTitles';
import type { HoverBoxProps } from "../flowTypes";
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import NavigationGroup from '../../../../PropertiesPanel/view/IntlNavigationGroup';

const
    id = pageIds.MAIN,
    title = pageTitles[id],
    view = ({ navigateToPage }: HoverBoxProps) => (
        <Page>
            <NavigationGroup
                navigateToPage={navigateToPage}
                pagesTitles={pageTitles}
                targetPagesIds={[pageIds.GRADIENT_SETTINGS, pageIds.IMAGE_PATTERN, pageIds.BORDER, hoverBoxPageIds.HOVER_EFFECT]}
            />
        </Page>
    );

export default { id, title, view };
