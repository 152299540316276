import * as R from 'ramda';
import cx from 'classnames';
import React from "react";
import viewsRegistry from "../viewsRegistry";
import styles from './ShadowRenderer.css';
import type { State } from '../epics/state/flowTypes';
import forwardTo from '../../../redux/forwardTo';
import SHADOW_RENDERER_KIND from './SHADOW_RENDERER_KIND';

type Props = {
    state: State,
    dispatch: Dispatch
}

export default ({ state, dispatch }: Props) => {
    const
        views = R.pipe(
            R.keys,
            R.map(key => {
                const
                    entry = state[key],
                    View = viewsRegistry[entry.kind],
                    forwardedDistatch = forwardTo(SHADOW_RENDERER_KIND, key, dispatch);

                return <View key={key} {...entry.props} dispatch={forwardedDistatch} componentId={key} />;
            })
        )(state);

    return (
        <div className={cx(styles.container, 'ShadowRenderContainer')}>
            {views}
        </div>
    );
};
