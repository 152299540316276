export const ShutterstockCategoryMessages = {
    "-1": "msg: fm.category.all {All}",
    "0": "msg: fm.category.transportation {Transportation}",
    "1": "msg: fm.category.animalsWildlife {Animals/Wildlife}",
    "2": "msg: fm.category.buildingsLandmarks {Buildings/Landmarks}",
    "3": "msg: fm.category.backgroundsTextures {Backgrounds/Textures}",
    "4": "msg: fm.category.business {Business/Finance}",
    "5": "msg: fm.category.education {Education}",
    "6": "msg: fm.category.foodAndDrink {Food and Drink}",
    "7": "msg: fm.category.healthcareMedical {Healthcare/Medical}",
    "8": "msg: fm.category.holidays {Holidays}",
    "9": "msg: fm.category.objects {Objects}",
    "10": "msg: fm.category.industrial {Industrial}",
    "11": "msg: fm.category.arts {The Arts}",
    "12": "msg: fm.category.nature {Nature}",
    "13": "msg: fm.category.people {People}",
    "14": "msg: fm.category.religion {Religion}",
    "15": "msg: fm.category.science {Science}",
    "16": "msg: fm.category.technology {Technology}",
    "17": "msg: fm.category.signsSymbols {Signs/Symbols}",
    "18": "msg: fm.category.sports {Sports/Recreation}",
    "21": "msg: fm.category.interiors {Interiors}",
    "22": "msg: fm.category.miscellaneous {Miscellaneous}",
    "24": "msg: fm.category.vintage {Vintage}",
    "25": "msg: fm.category.parksOutdoor {Parks/Outdoor}",
    "26": "msg: fm.category.abstract {Abstract}",
    "27": "msg: fm.category.beautyFashion {Beauty/Fashion}"
};
