import type { TinyMceEditor } from "../../flowTypes";
import { makeRemoveFormatter } from "../../editorUtils/methods/helpers/makeRemoveFormatter";
import { makeApplyFormatter } from "../../editorUtils/methods/helpers/makeApplyFormatter";
import { isBold } from "../../editorUtils/methods/state/isBold";
import { isItalic } from "../../editorUtils/methods/state/isItalic";
import { isUnderline } from "../../editorUtils/methods/state/isUnderline";

const makeToggleFormat = (
    getCurrentValue: (arg: TinyMceEditor) => AnyValue,
    [enabledFormat, disabledFormat]: [string, string?]
) =>
    (editor: TinyMceEditor) => {
        const
            isEnabled = getCurrentValue(editor),
            removeFormatFromEditorSelection = makeRemoveFormatter(editor),
            applyFormatToEditorSelection = makeApplyFormatter(editor);

        if (isEnabled) {
            removeFormatFromEditorSelection(enabledFormat);
            // TODO: FixGlobalStyles might needs to be called here, this remove might not be followed by apply

            if (disabledFormat) {
                applyFormatToEditorSelection(disabledFormat);
            }
        } else {
            if (disabledFormat) {
                removeFormatFromEditorSelection(disabledFormat);
            }
            applyFormatToEditorSelection(enabledFormat);
        }
    };

export const handleItalicButtonClick = makeToggleFormat(
    isItalic,
    ['italic', 'not_italic']
);

export const handleBoldButtonClick = makeToggleFormat(
    isBold,
    ['bold', 'not_bold']
);

// No need to force normal because, globalstyles doesn't have underline
export const handleUnderlineButtonClick = makeToggleFormat(
    isUnderline,
    ['underline']
);
