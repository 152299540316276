import React from 'react';
import { MctaPropertyContainer, MctaCombobox, MctaVerticalBar, MctaCheckbox } from '../../../componentMainActions/index';
import { intlCreateOptions } from '../../../../view/common/Combobox/utils';
import { injectIntl } from '../../../../view/intl/index';
import { WIDGETS_SOUNDCLOUD_SHOW_UPDATE_LINK_DIALOG,
    WIDGETS_SOUNDCLOUD_TOGGLE_AUTOPLAY,
    WIDGETS_SOUNDCLOUD_UPDATE_COLOR } from './actionTypes';
import HorizontalSpacer from '../../../../view/common/HorizontalSpacer';
import { ctaOnClickFactory, linkExtractor } from '../utils';
import { getSoundcloudPostType } from './utils/parseSoundcloudCode';
import { PostType, ColorOptions, Default } from './constants';
import styles from '../view/Widgets.css';

const EditView = injectIntl(props => {
    const { selectedComponent: { autoplay, color }, intl, dispatch } = props;
    return (
        <div>
            <HorizontalSpacer x={5} />
            <MctaCheckbox
                isChecked={autoplay}
                label="msg: component.soundcloud.cma.autoplay {Autoplay}"
                onClick={() => dispatch({ type: WIDGETS_SOUNDCLOUD_TOGGLE_AUTOPLAY })}
            />
            <HorizontalSpacer x={12} />
            <MctaVerticalBar />
            <HorizontalSpacer x={12} />
            <MctaPropertyContainer label="msg: widgets.soundcloud.cma.color {Colour}">
                <MctaCombobox
                    searchable={false}
                    options={intlCreateOptions(ColorOptions, intl)}
                    useOptionsAsIs
                    value={color || ColorOptions[Default]}
                    className={styles.ctaTheme}
                    onChange={({ value: color }) => {
                        dispatch({ type: WIDGETS_SOUNDCLOUD_UPDATE_COLOR, payload: color });
                    }}
                />
            </MctaPropertyContainer>
        </div>

    );
});

const CtaButtonTextView = injectIntl((props) => {
    const { selectedComponent: { link }, intl } = props;
    const postType = getSoundcloudPostType(link);

    if (postType === PostType.PLAYLIST) {
        return intl.msgJoint("msg: widgets.soundcloud.cta.changePlaylist {Change playlist}");
    } else if (postType === PostType.TRACK) {
        return intl.msgJoint("msg: widgets.soundcloud.cta.changeTrack {Change track}");
    }

    return intl.msgJoint("msg: widgets.cta.changeWidget {Change widget}");
});

const componentMainActions = {
    editView: EditView,
    editButtonText: 'msg: common.settings {Settings}',
    CtaButtonTextView,
    ctaOnClick: ctaOnClickFactory(
        WIDGETS_SOUNDCLOUD_SHOW_UPDATE_LINK_DIALOG,
        component => ({ link: linkExtractor(component) })
    ),
};

export { componentMainActions };

