import React, { useState } from "react";
import cx from "classnames";
import {
    ONBOARDING_REMOVE_LOGO_PRESSED,
    ONBOARDING_LOGO_CHOOSEN,
    ONBOARDING_LOGO_BOX_DROP_ZONE_FOR_IMAGE_UPLOAD_CLICKED,
    ONBOARDING_CONTINUE_PRESSED,
    ONBOARDING_STEP_BACK_BUTTON_PRESSED,
    ONBOARDING_FILL_LATER_PRESSED
} from "../actionTypes";
import styles from "./styles.css";
import { Msg, Intl } from "../../../view/intl/index";
import { LogoImagePreview } from "./LogoImagePreview";
import { Buttons } from "./Buttons";
import { FcContentTypes } from "../../../redux/modules/children/fileChooser/FcContentTypes";
import type { ImageAsset } from "../../App/flowTypes";
import "../../App/flowTypes";
import { OnboardingTopBar } from "../../TopBar/view/OnboardingTopBar";
import LogoStepRightSideView from "./LottieAnimations/LogoStepRightSideView";
import stepStyles from "./LogoStep.css";

export type LogoStepProps = {
    websiteTitle: string;
    logoAsset: ImageAsset | null | undefined;
    dispatch: Dispatch;
    intl: Intl;
};
const LogoPreviewWidth = 323;
const LogoPreviewHeight = 133;
export const LogoStep = (props: LogoStepProps) => {
    const { websiteTitle, logoAsset, dispatch, intl } = props,
        [isImgOnError, SetIsImgOnError] = useState(false),
        mctaDisabled = !logoAsset;
    return (
        <div className={styles.contentContainer}>
            <div className={cx(styles.leftContentContainer)}>
                <OnboardingTopBar />
                <div className={styles.flex1} />
                <div className={styles.leftContent}>
                    <div className={styles.textProgress}>
                        <Msg
                            k="onboarding.step"
                            params={{
                                step: 2
                            }}
                        >{`Step {step} of 5`}</Msg>
                    </div>
                    <div className={cx(styles.question)}>
                        <Msg k="onboarding.websiteLogo">Do you have a logo?</Msg>
                    </div>
                    <div className={cx(styles.tip)}>
                        <Msg k="onboarding.websiteLogoTip">
                            Logos are important in creating a brand for your website. Upload your logo to show it at the top of your site.
                        </Msg>
                    </div>
                    <div
                        className={stepStyles.logoContainer}
                        // @ts-ignore
                        onClick={
                            logoAsset
                                ? null
                                : () =>
                                    dispatch({
                                        type: ONBOARDING_LOGO_BOX_DROP_ZONE_FOR_IMAGE_UPLOAD_CLICKED
                                    })
                        }
                    >
                        <LogoImagePreview
                            logoAsset={logoAsset}
                            alt={websiteTitle}
                            height={LogoPreviewHeight}
                            width={LogoPreviewWidth}
                            removeLogoActionType={ONBOARDING_REMOVE_LOGO_PRESSED}
                            logoChoosenActionType={ONBOARDING_LOGO_CHOOSEN}
                            dispatch={dispatch}
                            headlessMode
                            extraImageContentTypes={FcContentTypes.SVG}
                            onError={() => SetIsImgOnError(true)}
                        />
                    </div>
                </div>
                <div className={styles.flex1} />
                <Buttons
                    mctaDisabled={mctaDisabled}
                    mctaHandler={() =>
                        dispatch({
                            type: ONBOARDING_CONTINUE_PRESSED
                        })}
                    sctaHandler={() =>
                        dispatch({
                            type: ONBOARDING_STEP_BACK_BUTTON_PRESSED
                        })}
                    tctaHandler={() =>
                        dispatch({
                            type: ONBOARDING_FILL_LATER_PRESSED
                        })}
                    intl={intl}
                />
            </div>
            <div className={cx(styles.rightContentContainer, stepStyles.rightContentContainer)}>
                <LogoStepRightSideView logoAsset={logoAsset} websiteTitle={websiteTitle} isImgOnError={isImgOnError} />
            </div>
        </div>
    );
};
