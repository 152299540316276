import { SAVE_SITE_DATA } from "./actionTypes";
import type { UpdateSiteDataInput } from "../../../../../dal/flowTypes";

type Params = {
    saveSiteDataInput: UpdateSiteDataInput;
    afterSaveActionType?: string;
    afterSaveUpdateReason?: string;
};

export const
    saveSiteDataAction = ({ saveSiteDataInput, afterSaveActionType, afterSaveUpdateReason }: Params) => ({
        type: SAVE_SITE_DATA,
        payload: { saveSiteDataInput, afterSaveActionType, afterSaveUpdateReason }
    });

