
/* Widgets */
import { WidgetsTwitter } from "./Twitter/index";
import { WidgetsInstagram } from "./Instagram/index";
import { WidgetsFacebook } from "./Facebook/index";
import { WidgetsSpotify } from "./Spotify/index";
import { WidgetsPinterest } from "./Pinterest/index";
import { WidgetsVimeo } from "./Vimeo/index";
import { WidgetsGoogleMaps } from "./GoogleMaps/index";
import { WidgetsSoundcloud } from "./Soundcloud/index";
import { WidgetsTiktok } from "./Tiktok/index";
import { WidgetsEventbrite } from "./Eventbrite/index";
import { WidgetsBingMaps } from "./BingMaps/index";
import { WidgetsGoFundMe } from "./GoFundMe/index";
import { WidgetsMailchimp } from "./Mailchimp";
import { WidgetsPaypal } from "./Paypal/index";
import { WidgetsBookings } from "./Bookings/index";

export const WidgetsRegistry = [
    WidgetsTwitter,
    WidgetsInstagram,
    WidgetsFacebook,
    WidgetsSpotify,
    WidgetsPinterest,
    WidgetsVimeo,
    WidgetsGoogleMaps,
    WidgetsSoundcloud,
    WidgetsTiktok,
    WidgetsEventbrite,
    WidgetsBingMaps,
    WidgetsGoFundMe,
    WidgetsMailchimp,
    WidgetsPaypal,
    WidgetsBookings,
];
