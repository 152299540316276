import makeEpic, { makeEpicStateSelector } from '../../../../epics/makeEpic';
import { controlsDependenciesForSelectedComponentValueActionType } from './valueActionType';
import { getDefaultReducerState, makeDependsOnReducer } from "../../../../redux/makeReducer/index";
import registry from "../../../../view/oneweb/registry/index";
import { MatchAnyActionType } from "../../../../epics/constants";
import { SelectedComponentKindSelector } from "../componentsEval/selectorActionTypes";

const
    controlsDependenciesReducer = makeDependsOnReducer(registry, 'controlsDependsOn'),
    controlsDependenciesForSelectedComponentEpic = makeEpic({
        defaultState: null,
        defaultScope: {
            selectedComponentKind: null,
            dependencies: getDefaultReducerState(controlsDependenciesReducer)
        },
        valueActionType: controlsDependenciesForSelectedComponentValueActionType,
        afterUpdate: ({ nextScope }) => {
            return { state: nextScope.dependencies[nextScope.selectedComponentKind] || null, scope: nextScope };
        },
        updaters: [
            {
                keepFullActions: true,
                conditions: [MatchAnyActionType],
                reducer: ({ values: [action], state, scope }) => {
                    return {
                        state,
                        scope: { ...scope, dependencies: controlsDependenciesReducer(scope.dependencies, action) }
                    };
                }
            },
            {
                conditions: [SelectedComponentKindSelector],
                reducer: ({ values: [selectedComponentKind], state, scope }) => {
                    return { state, scope: { ...scope, selectedComponentKind } };
                }
            }
        ]
    }),
    getControlsDependenciesForSelectedComponentEpicStateFromAppState =
        makeEpicStateSelector(controlsDependenciesForSelectedComponentValueActionType);

export {
    controlsDependenciesForSelectedComponentEpic,
    getControlsDependenciesForSelectedComponentEpicStateFromAppState
};
