import React from 'react';
import { CLOSE_MOBILE_DOWN_TOOLTIP, SHOW_TOOLTIP } from "../../../Tooltip/actionTypes";
import {
    MOBILE_VIEW,
    MobileDownTitle,
    MobileViewTitle
} from './constants';
import { MobileDownDisabled } from "../../../Tooltip/ids";
import { toggleMobileDownAction } from "./actions";
import CheckBox from '../../../../view/common/CheckBox/PropertiesCheckBox';
import Page from '../PropertiesPage';
import type { Props } from './flowTypes';
import styles from "./MobileView.css";

class MobileOptions extends React.Component<Props> {
    mobileDownCheckboxRef: any;
    render() {
        const {
                dispatch,
                selectedComponent: { mobileDown },
                mobileEnablement: {
                    enableMobileDown,
                    mobileDownDisableReason
                }
            } = this.props,
            mobileDownCheckboxProps = {
                ref: elt => {
                    this.mobileDownCheckboxRef = elt;
                },
                onMouseEnter: () => {
                    if (mobileDownDisableReason) {
                        const { width, height, top, left } = this.mobileDownCheckboxRef.getBoundingClientRect();
                        dispatch({
                            type: SHOW_TOOLTIP,
                            payload: {
                                id: MobileDownDisabled,
                                position: { x: left + (width / 2), y: top + height },
                                msg: { text: mobileDownDisableReason },
                                showIcon: false,
                                elementDimensions: { height }
                            }
                        });
                    }
                },
                onMouseLeave: () => {
                    if (mobileDownDisableReason) {
                        dispatch({ type: CLOSE_MOBILE_DOWN_TOOLTIP });
                    }
                }
            };

        return (
            <Page>
                <CheckBox
                    label={MobileDownTitle}
                    isChecked={(enableMobileDown ? mobileDown : false)}
                    onClick={() => {
                        dispatch(toggleMobileDownAction());
                    }}
                    disabled={!enableMobileDown}
                    checkboxProps={mobileDownCheckboxProps}
                    containerClassName={styles.ppMobileView}
                    className={styles.ppMobileViewlabel}
                />
            </Page>
        );
    }
}

const
    id = MOBILE_VIEW,
    title = MobileViewTitle,
    view = MobileOptions;

export { id, title, view };
