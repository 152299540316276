import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
    (theme) => ({
        paper: {
            width: 610,
            maxWidth: 610,
            [theme.breakpoints.down('md')]: {
                position: "absolute",
                bottom: 0,
                width: "100%",
                maxWidth: "100%",
                margin: 0,
                padding: 0,
            },
        },
        dialogTitle: {
            fontSize: "30px",
            lineHeight: "34px",
        },
        dialogDesc: {
            fontSize: "15px",
            lineHeight: "24px",
        },
        buttonContainer: {
            margin: "16px 0",
            justifyContent: "flex-end",
            gap: "16px",
        },
        actionButton: {
            height: "40px",
        },
        linkText: {
            "&, &:hover": {
                color: theme.palette.custom.colorGray_88,
                textDecorationColor: theme.palette.custom.colorGray_88,
            }
        }
    }),
    { name: "UpgradeApproveDialog" }
);
