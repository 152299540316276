import React from "react";
import { pure } from 'recompose';
import StripConfig from "../../Strip/previewConfig";
import calcRenderProps from "../../Background/calcRenderProps";
import type { BackgroundComponent } from "../../Background/flowTypes";
import { componentDependencies } from "../../Background/previewConfig";
import type { SectionComponent } from "../flowTypes";
import styles from "./SharedBgImgView.css";

type Props = {
    offsetTop: number, // TODO WBTGEN-16805 remove once all customer migratied to have consistent data without overlaps
    headerHeight: number,
    firstSectionComponent: SectionComponent,
    isWorkspace?: boolean,
}

export const SharedBgImgView = pure(({ headerHeight, firstSectionComponent, isWorkspace = false, offsetTop }: Props) => {
    const component: BackgroundComponent = ({
        ...firstSectionComponent,
        height: headerHeight + firstSectionComponent.height - offsetTop,
    } as any);
    return (
        <div data-bg-id="SHARED_BG_IMG" className={styles.container} style={{ height: component.height, marginTop: offsetTop }}>
            <StripConfig.view
                {...calcRenderProps({
                    component,
                    componentDependencies,
                    componentId: component.id,
                    componentExtension: null,
                    isWorkspace,
                    inEditMode: false
                })}
            />
        </div>
    );
});
