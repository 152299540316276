import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";

import { Header } from '../Header';
import { ImageChooser } from "./ImageChooser";
import { ImagePreview } from "./ImagePreview";
import { SocialShareSideBarItem } from "./constants";
import { socialShareAssetSelector } from '../../../common/selectors';
import { validateSocialShareAsset } from "../../../../../../wbtgen/src/components/SocialShare/utils";
import { uploadFromComputerAction } from "../../ImageChooser/actions";
import { setSocialShareAssetAction } from "./actions";
import { PageLayout } from '../PageLayout';
import { PageContentLayout } from '../PageContentLayout';
import { useStyles } from './styles';
import { useIntl } from '../../../../../../wbtgen/src/view/intl';
import { imageChooserAssetKeyMap } from "../../ImageChooser/config";

export const SocialShare = (): JSX.Element | null => {
    const classes = useStyles();
    const intl = useIntl();
    const dispatch = useDispatch();

    const socialShareAsset = useSelector(socialShareAssetSelector);

    const [file, setFile] = useState(null);
    const fileInputRef = React.useRef<HTMLInputElement>(null);

    const onFileChange = (e) => {
        const files = e.target.files;
        if (files.length) {
            setFile(files[0]);
        }
        dispatch(uploadFromComputerAction(files, imageChooserAssetKeyMap.socialShare));
    };

    const onFileRemove = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setFile(null);
        // @ts-ignore
        dispatch(setSocialShareAssetAction(null));
    };

    const onChange = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <PageLayout withFooterBar>
            <PageContentLayout>
                <Header header={SocialShareSideBarItem.header} />

                <div className={classes.root} data-testid="social-share-root">
                    <Grid className={classes.container}>
                        <Grid className={classes.leftItem}>
                            <ImageChooser
                                file={file}
                                asset={socialShareAsset}
                                label={intl.msgJoint('msg: socialShare.imageChooser.label {Thumbnail image}')}
                                placeholder={intl.msgJoint('msg: socialShare.imageChooser.placeholder {Choose an image}')}
                                hint={intl.msgJoint('msg: pageInfo.socialShare.asset.hint {Get the best results by using an image that is minimum 200x200 px.}')}
                                hintWarning={validateSocialShareAsset(socialShareAsset)}
                                inputClass={classes.imageChooserInput}
                                labelClass={classes.imageChooserLabel}
                                hintClass={classes.imageChooserHint}
                                hintWarningClass={classes.imageChooserHintWarning}
                                onChange={onChange}
                                onRemove={onFileRemove}
                                intl={intl}
                            />
                        </Grid>
                        <Grid className={classes.rightItem}>
                            <ImagePreview
                                asset={socialShareAsset}
                                onChange={onChange}
                                onRemove={onFileRemove}
                                intl={intl}
                            />
                        </Grid>
                        <input
                            ref={fileInputRef}
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={onFileChange}
                        />
                    </Grid>
                </div>
            </PageContentLayout>
        </PageLayout>
    );
};
