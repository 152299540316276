import {
    getSectionsListDataApiAction,
} from '../../redux/middleware/api/commonApiActions';

export const
    PANEL_RESIZE_HANDLE_CLICKED = 'PANEL_RESIZE_HANDLE_CLICKED',
    PANEL_COMPONENT_SHORTCUT_MOUSE_DOWN = "PANEL_COMPONENT_SHORTCUT_MOUSE_DOWN",
    PANEL_COMPONENT_SHORTCUT_CLICK = "PANEL_COMPONENT_SHORTCUT_CLICK",
    EXTENDED_PANEL_CLOSE = 'EXTENDED_PANEL_CLOSE',
    PANEL_ITEM_CLICK = 'PANEL_ITEM_CLICK',
    PANEL_ITEM_LEFT_MOUSE_DOWN = 'PANEL_ITEM_LEFT_MOUSE_DOWN',
    OPEN_ITEM_PANEL = 'OPEN_ITEM_PANEL',
    EXPANDED_PANEL_COMPONENT_CLICK = "EXPANDED_PANEL_COMPONENT_CLICK",
    EXPANDED_PANEL_COMPONENT_MOUSE_DOWN = "EXPANDED_PANEL_COMPONENT_MOUSE_DOWN",
    SET_FILTER = "SET_FILTER",
    SECTIONS_BLOCKS_FETCHED = "SECTIONS_BLOCKS_FETCHED";

export const
    LOAD_SECTION_LIST_REQUEST_ACTION = 'LOAD_SECTION_LIST_REQUEST_ACTION',
    LOAD_SECTION_LIST_SUCCESS_ACTION = 'LOAD_SECTION_LIST_SUCCESS_ACTION',
    LOAD_SECTION_LIST_FAILURE_ACTION = 'LOAD_SECTION_LIST_FAILURE_ACTION',
    loadSectionsListDataApiAction = () => getSectionsListDataApiAction({
        types: [
            LOAD_SECTION_LIST_REQUEST_ACTION,
            LOAD_SECTION_LIST_SUCCESS_ACTION,
            LOAD_SECTION_LIST_FAILURE_ACTION
        ]
    });
