import React from 'react';
import { Box } from '@mui/material';

import { CampaignStatusSwitch } from './CampaignStatusSwitch';
import { GoogleAdsSettingsViewBodyText } from './GoogleAdsSettingsViewBodyText';
import type { GoogleAdsSettingsViewBodyTextProps } from './GoogleAdsSettingsViewBodyText';

type GoogleAdsSettingsViewBodyTextWithSwitchProps = GoogleAdsSettingsViewBodyTextProps & {
    switchEnabled: boolean;
    handleSwitchStatusChange: (status: boolean) => void;
};

export const GoogleAdsSettingsViewBodyTextWithSwitch = (props: GoogleAdsSettingsViewBodyTextWithSwitchProps) => (
    <React.Fragment>
        <GoogleAdsSettingsViewBodyText text={props.text} />

        <Box marginTop="16px">
            <CampaignStatusSwitch
                enabled={props.switchEnabled}
                handleChange={props.handleSwitchStatusChange}
            />
        </Box>
    </React.Fragment>
);
