
import {
    makeCombineReducer,
    makeDefaultStateReducers,
    makeComponentBaseReducers
} from "../../../../redux/makeReducer/index";
import * as actions from "./actionTypes";
import { WidgetsTwitterKind } from "./kind";
import { defaultState, TwitterLinks, DEFAULT_HEIGHT, DEFAULT_WIDTH } from "./constants";

const reducer = makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(WidgetsTwitterKind, DEFAULT_WIDTH, DEFAULT_HEIGHT),
        ...makeDefaultStateReducers(defaultState)
    },
    handleActions: {
        [actions.WIDGETS_TWITTER_TIMELINE_UPDATE_PROPS]: (component, { payload: params }) => {
            const updateLinks = Object
                .keys(TwitterLinks)
                .some(link => params.hasOwnProperty(link));

            return updateLinks
                ? { ...component, ...TwitterLinks, ...params }
                : { ...component, ...params };
        },
        [actions.WIDGETS_TWITTER_TIMELINE_UPDATE_LINK]: (component, { payload: link }) => ({
            ...component, link
        }),
        [actions.WIDGETS_TWITTER_TIMELINE_SET_THEME]: (component, { payload: theme }) => ({
            ...component, theme
        })
    }
});

export { reducer };

