import React from 'react';

import { SettingsViewContainer } from './SettingsViewContainer';
import { GoogleAdsSettingsViewBodyText } from './GoogleAdsSettingsViewBodyText';

export type GoogleAdsSettingsTextViewProps = {
    bgColor: string;
    header: {
        iconClass: string;
        text: string;
    }
    text: string;
    actionButton: {
        label: string;
        url: string;
        action: Action;
    };
};

export const GoogleAdsSettingsTextView = (props: GoogleAdsSettingsTextViewProps) => (
    <SettingsViewContainer
        bgColor={props.bgColor}
        header={props.header}
        actionButton={props.actionButton}
    >
        <GoogleAdsSettingsViewBodyText text={props.text} />
    </SettingsViewContainer>
);
