export const
    SORT_IMAGES_DIALOG_IMAGE_SELECTED_TO_SORT = 'SORT_IMAGES_DIALOG_IMAGE_SELECTED_TO_SORT',
    SORT_IMAGES_DIALOG_SORTING_ENDED = 'SORT_IMAGES_DIALOG_SORTING_ENDED',
    SORT_IMAGES_DIALOG_SAVE_BTN_CLICKED = 'SORT_IMAGES_DIALOG_SAVE_BTN_CLICKED',
    SORT_IMAGES_DIALOG_SORTING_DONE = 'SORT_IMAGES_DIALOG_SORTING_DONE',
    SORT_IMAGES_DIALOG_FC_IMAGES_ADDED = 'SORT_IMAGES_DIALOG_FC_IMAGES_ADDED',
    SORT_IMAGES_DIALOG_IMAGE_DELETE_ICON_CLICKED = 'SORT_IMAGES_DIALOG_IMAGE_DELETE_ICON_CLICKED',
    SORT_IMAGES_DIALOG_SET_IMAGE_LINK = 'SORT_IMAGES_DIALOG_SET_IMAGE_LINK',
    SORT_IMAGES_DIALOG_UNSET_IMAGE_ACTION_CLICK = 'SORT_IMAGES_DIALOG_UNSET_IMAGE_ACTION_CLICK',
    SORT_IMAGES_DIALOG_UPDATE_IMAGE_CAPTION = 'SORT_IMAGES_DIALOG_UPDATE_IMAGE_CAPTION',
    SORT_IMAGES_DIALOG_UPDATE_IMAGE_ALT_TEXT = 'SORT_IMAGES_DIALOG_UPDATE_IMAGE_ALT_TEXT',
    SORT_IMAGES_DIALOG_UPDATE_IMAGE_TITLE = 'SORT_IMAGES_DIALOG_UPDATE_IMAGE_TITLE',
    SORT_IMAGES_DIALOG_ADD_IMAGES_BTN_CLICKED = 'SORT_IMAGES_DIALOG_ADD_IMAGES_BTN_CLICKED',
    SORT_IMAGES_DIALOG_SHOW_IMAGES = 'SORT_IMAGES_DIALOG_SHOW_IMAGES',
    SORT_IMAGES_DIALOG_SET_IMAGE_LINK_BTN_CLICKED = 'SORT_IMAGES_DIALOG_SET_IMAGE_LINK_BTN_CLICKED',
    SORT_IMAGES_DIALOG_REPLACE_WITH_EDITED_IMAGE = 'SORT_IMAGES_DIALOG_REPLACE_WITH_EDITED_IMAGE',
    SORT_IMAGES_DIALOG_SAVE_RESULT = 'SORT_IMAGES_DIALOG_SAVE_RESULT',
    SORT_IMAGES_DIALOG_UNMOUNT = 'SORT_IMAGES_DIALOG_UNMOUNT',
    SORT_IMAGES_UPLOAD_FILES = 'SORT_IMAGES_UPLOAD_FILES',
    SORT_IMAGES_DIALOG_TOGGLE_CAPTION = 'SORT_IMAGES_DIALOG_TOGGLE_CAPTION';
