import * as R from 'ramda';
import * as cssBackgroundMapper from '../../../mappers/background/index';
import headerStyles from '../../Mobile/header/MobileHeader.css';
import { parseIntDec } from "../../../../utils/number";
import type { TemplateComponent } from "../../oneweb/Template/flowTypes";
import type { ThemeColorDataType } from "../../ThemeGlobalData/flowTypes";
import { processBackgroundForThemeColorAndGradient } from "../../oneweb/Background/makeStyle";
import { getThemeRulesForBackground } from "../../ThemeGlobalData/themeRules";

const HEADER_HEIGHT = parseIntDec(headerStyles.containerHeight);

type Props = {
    topContainer: boolean,
    template: TemplateComponent,
    autoColorMode: boolean,
    themeColorsData: ThemeColorDataType,
};

export const getMveBackgroundStyle = ({
    topContainer,
    template,
    autoColorMode,
    themeColorsData,
}: Props): null | undefined | Record<string, any> => {
    let
        cssOptions = {},
        templateBackground = R.path(['style', 'background'])(template);
    const { selectedTheme, selectedGradientTheme } = template;
    if (autoColorMode && selectedTheme) {
        const
            backgroundColorFromTheme = themeColorsData[getThemeRulesForBackground(selectedTheme, themeColorsData).background],
            backgroundGradientColorFromTheme = selectedGradientTheme ? themeColorsData[selectedGradientTheme] : undefined;
        if (backgroundColorFromTheme) {
            templateBackground = processBackgroundForThemeColorAndGradient(
                templateBackground,
                backgroundColorFromTheme,
                backgroundGradientColorFromTheme
            );
        }
    }

    let style = cssBackgroundMapper.toCss(templateBackground, cssOptions);

    if (style) {
        if (topContainer) {
            style.top = HEADER_HEIGHT;
        }
    }

    return style;
};
