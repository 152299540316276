import React from "react";
import Box from "@mui/material/Box";
import { Theme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
    (theme: Theme) => ({
        rootContainer: {
            backgroundColor: theme.palette.custom.colorWhite,
            padding: "40px 24px",
            userSelect: "none",
            [theme.breakpoints.up("sm")]: {
                padding: "32px 48px",
                border: `1px solid ${theme.palette.custom.colorGray_ce}`,
            },
        }
    }),
    { name: "WidgetContainer" }
);

type WidgetContainerProps = {
    children: React.ReactElement | React.ReactElement[];
};

export const WidgetContainer = ({ children, ...rest }: WidgetContainerProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.rootContainer} {...rest}>
            <Box
                marginX="auto"
                display="flex"
                flexDirection="column"
                alignItems="stretch"
            >
                {children}
            </Box>
        </Box>
    );
};
