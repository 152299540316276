import React from "react";
export const SvgFoursquareSquareFillLight = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <path fill="#ffffff" d="M0 0h48v48H0z" />
            <path
                fill="#3c3c3c"
                d="M34.56 13.187a1.686 1.686 0 00-1.358-.687H17.186c-.931 0-1.686.755-1.686 1.686V34.42a1.686 1.686 0 002.878 1.192l6.25-6.25h4.36c.737 0 1.389-.48 1.609-1.184l4.215-13.489c.16-.512.066-1.07-.251-1.502zm-4.705 6.057H23.93a1.686 1.686 0 000 3.373h4.872l-1.054 3.372H23.93c-.447 0-.876.178-1.192.494l-3.866 3.866V15.872h12.037l-1.054 3.372z"
            />
        </g>
    </svg>
);
