import { FrontClient } from "@one.com/event-tracking-front-client";
import * as sha224 from "crypto-js/sha224";
import { getDAL } from "../../dal";
import { getAppConfig } from "../components/App/epics/appConfig/appConfig";
import { ApplicationSource, EventTrackingProps } from "../components/EventTracking/constants";

const singletons = {};

interface TrackingClientFactoryProps {
    userSessionId: string;
    adminId?: string;
    email?: string;
    trialEmail?: string;
    trialDomain?: string;
}

const appConfig = getAppConfig();

export const trackingClientFactory = (factoryProps: TrackingClientFactoryProps): FrontClient => {
    const { userSessionId, adminId, trialDomain } = factoryProps;
    if (!userSessionId) {
        throw Error("No User Session ID found, can not initialize tracking");
    }
    const domain = trialDomain || getDAL().getDomain();
    const domainHash = sha224(domain).toString();
    const singletonKey = `${userSessionId}+${domainHash}`;
    if (!singletons[singletonKey]) {
        const serverBaseUrl = `/api/v1/${domain}/track`;
        singletons[singletonKey] = new FrontClient({
            serverBaseUrl,
            userSessionId,
            options: {
                hashUserSessionId: userSessionId !== adminId
            },
            commonProperties: {
                [EventTrackingProps.brand]: appConfig.partnerName,
                [EventTrackingProps.application]: ApplicationSource,
                [EventTrackingProps.domain]: domainHash,
                [EventTrackingProps.server]: appConfig.env
            }
        });
    }
    return singletons[singletonKey];
};
