import React from "react";
import cx from 'classnames';
import styles from "./styles.css";
import type { ReviewButtonProps } from "../../flowTypes";

const ReviewButton = ({
    classList = [],
    reviewButtonText,
    leaveReviewLink,
}: ReviewButtonProps) => {
    return <a href={leaveReviewLink} className={styles.reviewButton} target="_blank">
        <div className={cx(classList)}>
            {reviewButtonText}
        </div>
    </a>;
};

export {
    ReviewButton
};
