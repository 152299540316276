import type { ImageComponent } from '../flowTypes';
import * as selectors from '../componentSelectors';
import { DEFAULT_BORDER_STYLE } from "../../../presentational/BorderSettings/constants";
import { getBorderStyle, getSpacing } from "../../../../view/oneweb/commonComponentSelectors";

export const fixHeightForFitMode = (component: ImageComponent): ImageComponent => {
    const
        { rotation } = component,
        assetHeight = selectors.getAssetHeight(component),
        assetWidth = selectors.getAssetWidth(component),
        borderStyle = getBorderStyle(component);

    let
        innerWidth = selectors.getImageInnerWidth(component),
        innerHeight;

    if (rotation % 180 === 90) {
        innerHeight = innerWidth * (assetWidth / assetHeight);
    } else {
        innerHeight = innerWidth * (assetHeight / assetWidth);
    }

    let height = innerHeight;
    // TODO why old WE needed this top added to innerHeight?
    // if (Math.abs(innerWidth / innerHeight - assetWidth / assetHeight) > 0.01) {
    //     height = top + innerHeight;
    // }

    if (borderStyle !== DEFAULT_BORDER_STYLE) {
        const spacing = getSpacing(component);
        height += spacing.top + spacing.bottom;
    }

    return {
        ...component,
        height: Math.round(height)
    };
};

export const fixWidthForFitMode = (component: ImageComponent): ImageComponent => {
    const
        { rotation } = component,
        assetHeight = selectors.getAssetHeight(component),
        assetWidth = selectors.getAssetWidth(component),
        borderStyle = getBorderStyle(component);

    let
        innerHeight = selectors.getImageInnerHeight(component),
        innerWidth;

    if (rotation % 180 === 90) {
        innerWidth = innerHeight * (assetHeight / assetWidth);
    } else {
        innerWidth = innerHeight * (assetWidth / assetHeight);
    }

    let width = innerWidth;
    if (borderStyle !== DEFAULT_BORDER_STYLE) {
        const spacing = getSpacing(component);
        width += spacing.left + spacing.right;
    }

    return {
        ...component,
        width: Math.round(width)
    };
};
