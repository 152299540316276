import { windowGetComputedStyleSafe } from "../../../../../../../utils/dom";

const getStyleNode = (node: HTMLElement, ignoreNodes: Array<string> = []) => {
    if (node.nodeName === '#text') {
        do {
            // $FlowFixMe: WBTGEN-9962: parentNode can be null, put checks
            node = node.parentNode as any; // eslint-disable-line
            // $FlowFixMe: WBTGEN-9962: parentNode can be null, put checks
        } while (ignoreNodes.includes(node.tagName));

        return node;
    }

    if (node.hasChildNodes()) {
        // $FlowFixMe: WBTGEN-9962: firstChild can be null, put checks
        // @ts-ignore
        return getStyleNode(node.firstChild);
    }

    return node;
};

export const makeComputedParentStyleSelector =
(property: string, ignoreNodes?: Array<string>) =>
    (node: HTMLElement) => {
        // $FlowFixMe
        const computedStyle = windowGetComputedStyleSafe(getStyleNode(node, ignoreNodes));

        // EDGE 17, 18 behaving wierdly, i was able to identify this place using error stack trace
        // not sure what is going wrong here WBTGEN-9814
        if (!computedStyle) {
            return '';
        }

        return computedStyle.getPropertyValue(property);
    };
