import React from 'react';

import { GoogleAdsSettingsTextWithSwitchView } from './GoogleAdsSettingsTextWithSwitchView';
import { BgColorSet, BodyTextSet, GoToDashboardActionButton, HeaderTextSet, IconClassesSet } from './constants';

type GoogleAdsSettingsAdCreatedProps = {
    isCampaignEnabled: boolean;
    handleCampaignStatusChange: (enabled: boolean) => void;
};

export const GoogleAdsSettingsAdCreated = (props: GoogleAdsSettingsAdCreatedProps) => {
    return (
        <GoogleAdsSettingsTextWithSwitchView
            bgColor={BgColorSet.NonActive}
            header={{ iconClass: IconClassesSet.adCreated, text: HeaderTextSet.adCreated }}
            text={BodyTextSet.adCreated}
            isCampaignEnabled={props.isCampaignEnabled}
            handleCampaignStatusChange={props.handleCampaignStatusChange}
            actionButton={GoToDashboardActionButton}
        />
    );
};
