
import React from 'react';
import cx from 'classnames';
import { Msg } from "../../../../../../view/intl/index";
import styles from './UpgradePlansDialogImages.css';
import { RenderPlanSellOnlineDescription } from "./RenderPlanSellOnlineDescription";

const RenderPremiumPaymentPlanImages = () => {
    return (
        <div className={styles.subContainerPremium}>
            <div className={cx(styles.premiumPaymentPlanStats, styles.imageCommonStyles)} />
            <div className={cx(styles.paymentPlanShop, styles.imageCommonStyles)} />
            <Msg className={cx(styles.txtStyle, styles.imageHeaderTxtStyle)} k="upgrade.plans.dialog.premium.img2.header">
                Site analytics
            </Msg>
            <Msg className={cx(styles.txtStyle, styles.imageHeaderTxtStyle)} k="upgrade.plans.dialog.premium.img3.header">
                Sell online
            </Msg>
            <Msg className={cx(styles.txtStyle, styles.imageBodyTxtStyle)} k="upgrade.plans.dialog.premium.img2.body">
                Use Google Analytics to keep track of your business
            </Msg>
            <RenderPlanSellOnlineDescription />
        </div>
    );
};

export default RenderPremiumPaymentPlanImages;
