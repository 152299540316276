import React from 'react';
import { errorDialogStandardHeight, errorDialogStandardWidth } from '../../../dialogs/constants';
import styles from '../FileChooserUpload.css';
import WarningDialog from '../../../dialogs/WarningDialog/index';
import injectIntl from "../../../../intl/injectIntl";
import ErrorDialogFileList from './ErrorDialogFileList';
import getCenteredDialogConfig from "../../../../../components/DialogManager/getCenteredDialogConfig";

const
    width = errorDialogStandardWidth,
    height = errorDialogStandardHeight,
    title = 'msg: fc.uploadFailedDialog.err.pixelSize.title {Invalid image size.}',
    error = 'msg: fc.uploadFailedDialog.err.pixelSize.error {Sorry, this image is too big. Please add images smaller than {maxPixelSize} megapixels.}', // eslint-disable-line
    mctaText = 'msg: common.ok {OK}';

const ImageSizeErrorDialog = injectIntl(({ fileNames, maxPixelSize, intl }) => {
    return (
        <WarningDialog title={title} mctaText={mctaText}>
            <div className={styles.labelSmall}>
                <span className={styles.bold}>{intl.msgJoint([error, { maxPixelSize }])}</span>
                <ErrorDialogFileList fileNames={fileNames} />
            </div>
        </WarningDialog>
    );
});

export default getCenteredDialogConfig(width, height, ImageSizeErrorDialog);
