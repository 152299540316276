import * as actions from '../actionTypes';
import { WIDGETS_BOOKINGS_EPIC_VAT } from "./valueActionType";
import { configurationDialogId } from '../configurationDialog';
import { parseTrafftCode } from '../utils/parseTrafftCode';
import { epicFactory } from '../../epics/factory';
import { linkExtractor } from '../../utils';

const WidgetsBookingsEpic = epicFactory({
    defaultState: null,
    valueActionType: WIDGETS_BOOKINGS_EPIC_VAT,
    configurationDialogId,
    codeParser: parseTrafftCode,
    payloadExtractor: linkExtractor,
    setLinkAction: actions.WIDGETS_BOOKINGS_SET_LINK,
    updatePropsAction: actions.WIDGETS_BOOKINGS_UPDATE_PROPS,
    showUpdateLinkDialogAction: actions.WIDGETS_BOOKINGS_SHOW_UPDATE_BOOKING_DIALOG,
    closeDialogActions: [actions.WIDGETS_BOOKINGS_UPDATE_PROPS],
});

export { WidgetsBookingsEpic };

