import React from 'react';
import cssStyles from './MobileViewEditor.css';
import { getMveBackgroundStyle } from "./getMveBackgroundStyle";
import type { ThemeColorDataType } from "../../ThemeGlobalData/flowTypes";
import type { TemplateComponent } from "../../oneweb/Template/flowTypes";

type Props = {
    topContainer: boolean,
    hasItems: boolean,
    template: TemplateComponent,
    autoColorMode: boolean,
    themeColorsData: ThemeColorDataType
};

export const MveBackground = ({ topContainer, hasItems, template, autoColorMode, themeColorsData }: Props) => {
    if ((topContainer && hasItems) || (!topContainer && !hasItems)) return null;

    const style = getMveBackgroundStyle({ topContainer, template, autoColorMode, themeColorsData });

    if (!style) return null;

    return (
        <div className={cssStyles.templateBackground} style={style} />
    );
};

