import { pipe, dissocPath, omit } from 'ramda';
import type { EpicUpdaterReducerReturnType } from "../../epics/flowTypes";
import type { EpicState, UpdateReason } from "../Workspace/epics/componentsEval/flowTypes";
import type { ComponentsDependencies } from "../../redux/modules/children/workspace/flowTypes";
import type { LayoutsDataMap, MHFDataEpicState } from "./flowTypes";
import { getComponentsMap, getComponentsMapExtension } from "../Workspace/epics/componentsEval/getters";
import { isSectionComponent } from '../oneweb/Section/utils';
import {
    calcNewHeightOfContainerBasedOnChildren,
    createComponentsFromLayoutAndRearrange,
    getCmpsMapWithUpdatedSectionColor,
    getTemplateSectionByName,
    upgradeModernSections,
    isAddWebshopMHFCmps,
    arrangeComponentsAndUpdateSectionHeight,
} from "./utils";
import { getAllCmpsInAContainer, getToggleOffCmpsMap, getFlattenedOptions } from "./preview_utils";
import { setComponentsMap, setComponentsMapExtension } from "../Workspace/epics/componentsEval/setters";
import { COMPONENTS_DELETED, WORKSPACE_NEW_COMPONENT_ADDED,
    WORKSPACE_SCROLL_TO_COMPONENT } from "../Workspace/actionTypes";
import { MHFADActionTypes, sectionNames, MHFCurrentActionSteps } from "./constants";
import defaultSectionStyle from "../oneweb/Section/defaultSectionStyle";
import {
    MODERN_HEADER_TOGGLE_CMPS_INITIALIZED,
    MODERN_FOOTER_TOGGLE_CMPS_INITIALIZED,
    MODERN_HEADER_FOOTER_TOGGLE_CMPS_SET,
} from "./actionTypes";
import type { WebShopMHFEpicState } from "../oneweb/WebShopMHF/flowTypes";
import { removeWebshopMHFDataFromLayout } from "./layoutsData/webshopMHFDataUtils";
import { UPGRADE_MODERN_HEADER_FOOTER } from "../Workspace/epics/componentsEval/updateReasons";

const getOldSectionProps = (section, shareSectionBgImg) => {
    let result: Record<string, any> = {};

    if (section.selectedBorderTheme) {
        result.selectedBorderTheme = section.selectedBorderTheme;
    }

    if (section.selectedGradientTheme) {
        result.selectedGradientTheme = section.selectedGradientTheme;
    }

    if (section.selectedTheme) {
        result.selectedTheme = section.selectedTheme;
    }

    result.pin = section.pin || 0;
    result.style = section.style;
    result.shareSectionBgImg = shareSectionBgImg;
    return result;
};

const moveOutAndStoreOldCmpsData = (state, sectionName, mhfData?) => {
    const
        componentsMap = getComponentsMap(state),
        componentsMapExtension = getComponentsMapExtension(state),
        section = getTemplateSectionByName(sectionName, componentsMap),
        allCmpsInSection = getAllCmpsInAContainer(componentsMap, section.id);
    let
        oldCmpsMap = {},
        oldCmpsMapExtension = {},
        cmpIdsToDelete: string[] = [],
        cmpsMap = { ...componentsMap },
        cmpsMapExtension = { ...componentsMapExtension },
        multipleActionsToDispatch: Array<AnyAction> = [];
    allCmpsInSection.forEach(cmp => {
        cmpIdsToDelete.push(cmp.id);
        oldCmpsMap[cmp.id] = cmp;
        delete cmpsMap[cmp.id];
        if (componentsMapExtension.hasOwnProperty(cmp.id)) {
            oldCmpsMapExtension[cmp.id] = componentsMapExtension[cmp.id];
            // delete cmpsMapExtension[cmp.id];
            //Actually when a cmp is deleted, it is supposed to be deleted from componentsMap and componentsMapExtension,
            //but currently it is not getting deleted from componentsMapExtension due to this commit
            //58aa781489e4a5abc2cc11052f6a75c3eed1c863
            //WBTGEN-4943 Fix wrapped item delete so that text re-renders.
            //if later in point of time we decide to delete it from componentsMapExtension, then uncomment this line
        }
    });
    if (cmpIdsToDelete.length) {
        multipleActionsToDispatch.push({
            type: COMPONENTS_DELETED,
            payload: cmpIdsToDelete
        });
    }

    if (!section.modernLayout || !section.modernLayout.active) {
        multipleActionsToDispatch.push({
            type: MHFADActionTypes[sectionName].activate.newCmpsAddedActionType,
            payload: {
                cmpsMap: oldCmpsMap,
                cmpsMapExtension: oldCmpsMapExtension
            }
        });
    }
    if (section.modernLayout && section.modernLayout.active) {
        const { layoutId, layout, options } = section.modernLayout,
            toggleOffCmps = !mhfData ? {} : mhfData.toggleOffData[sectionName][layoutId];

        multipleActionsToDispatch.push({
            type: MHFADActionTypes[sectionName].deactivate.oldCmpsRestoredActionType,
            payload: {
                layoutId,
                data: {
                    layout,
                    options,
                    cmpsMap: { ...oldCmpsMap, ...toggleOffCmps },
                    cmpsMapExtension: oldCmpsMapExtension
                }
            }
        });
    }
    return {
        section,
        cmpsMap,
        cmpsMapExtension,
        multipleActionsToDispatch
    };
};

const adjustSectionsHeights = (sectionName, sectionId, cmpsMap, newCmpIds) => {
    let updatedCmpsMap = { ...cmpsMap },
        oldSectionHeight = updatedCmpsMap[sectionId].height,
        newSectionHeight = oldSectionHeight;
    if (newCmpIds.length) {
        newSectionHeight = calcNewHeightOfContainerBasedOnChildren(
            sectionId,
            newCmpIds.map(id => cmpsMap[id])
        );
        updatedCmpsMap[sectionId] = {
            ...updatedCmpsMap[sectionId],
            height: newSectionHeight
        };
    }

    if (sectionName === sectionNames.header) {
        const diff = newSectionHeight - oldSectionHeight;
        if (diff !== 0) {
            Object.keys(updatedCmpsMap).forEach(id => {
                let cmp = updatedCmpsMap[id];
                if (
                    id !== sectionId &&
                    !newCmpIds.includes(id) &&
                    (cmp.top + (cmp.height / 2)) >= oldSectionHeight
                ) {
                    updatedCmpsMap[id] = {
                        ...cmp,
                        top: cmp.top + diff
                    };
                }
            });
        }
    }
    if (sectionName === sectionNames.footer && newCmpIds.length) {
        let footerCmp = updatedCmpsMap[newCmpIds.find(id => updatedCmpsMap[id].relIn.id === sectionId)],
            diff = footerCmp ? ((footerCmp.relIn.top + updatedCmpsMap[sectionId].top) - footerCmp.top) : 0;
        if (diff !== 0) {
            newCmpIds.forEach(id => {
                updatedCmpsMap[id] = {
                    ...updatedCmpsMap[id],
                    top: updatedCmpsMap[id].top + diff
                };
            });
        }
    }
    return updatedCmpsMap;
};

const getSectionCmpToggleAction = (result, sectionName, sectionId, layoutId) => {
    let type = MODERN_FOOTER_TOGGLE_CMPS_INITIALIZED,
        payloadKey = 'toggleOffFooterCmpsMap';
    const toggleCmpMap = getToggleOffCmpsMap(result.componentsMap[sectionId], result.componentsMap);
    if (sectionName === sectionNames.header) {
        type = MODERN_HEADER_TOGGLE_CMPS_INITIALIZED;
        payloadKey = 'toggleOffHeaderCmpsMap';
    }
    return {
        type,
        payload: {
            layoutId,
            [payloadKey]: toggleCmpMap
        }
    };
};

const updateState = ({
    state,
    actionsToDispatch,
    dispatchAsync,
    sectionName,
    MHFCurrentActionStep,
    componentsMap,
    componentsMapExtension,
    newComponentsIds,
    srcActionType,
    resultActionType
}) => {
    let multipleActionsToDispatch: Action[] = [...actionsToDispatch];
    if (newComponentsIds.length) {
        let newCmpId = newComponentsIds[0];
        if (sectionName === sectionNames.footer) {
            newCmpId = newComponentsIds.map(id => componentsMap[id])
                .sort((a, b) => b.top - a.top)[0].id;
        }
        multipleActionsToDispatch.push({
            type: WORKSPACE_NEW_COMPONENT_ADDED,
            payload: {
                id: newCmpId,
                component: newCmpId,
                newCmpIds: newComponentsIds
            }
        });
        if ([MHFCurrentActionSteps.ACTIVATE_LAYOUT_STEP, MHFCurrentActionSteps.SWITCH_LAYOUT_STEP].includes(MHFCurrentActionStep)) {
            setTimeout(() => dispatchAsync({
                type: WORKSPACE_SCROLL_TO_COMPONENT,
                payload: newCmpId
            }));
        }
    }

    multipleActionsToDispatch.push({ type: resultActionType });

    const newUpdatedState = pipe(
        setComponentsMap(componentsMap),
        setComponentsMapExtension(componentsMapExtension)
    )(state);
    return {
        state: newUpdatedState,
        multipleActionsToDispatch,
        updateReason: srcActionType
    };
};

type upgradeInp = {
    epicState: EpicState,
    layoutsMap: {
        header: LayoutsDataMap,
        footer: LayoutsDataMap
    }
};

const upgradeSection = ({
    epicState,
    layoutsMap
}: upgradeInp): EpicUpdaterReducerReturnType<EpicState, void, UpdateReason> => {
    const componentsMap = getComponentsMap(epicState),
        componentsMapExtension = getComponentsMapExtension(epicState),
        newCmpsMap = upgradeModernSections(componentsMap, componentsMapExtension, layoutsMap),
        newUpdatedState = pipe(
            setComponentsMap(newCmpsMap),
        )(epicState);

    return {
        state: newUpdatedState,
        updateReason: UPGRADE_MODERN_HEADER_FOOTER,
    };
};

type ActivateInp = {
    sectionName: string,
    epicState: EpicState,
    componentsDependencies: ComponentsDependencies,
    templateWidth: number,
    mhfData: MHFDataEpicState,
    layoutId: string,
    layoutsMap: {
        header: LayoutsDataMap,
        footer: LayoutsDataMap
    },
    shareSectionBgImg?: boolean,
    dispatchAsync: Dispatch,
    webshopMHFData: WebShopMHFEpicState,
}

const activateSection = ({
    sectionName,
    epicState,
    componentsDependencies,
    templateWidth,
    mhfData,
    layoutId,
    layoutsMap,
    shareSectionBgImg,
    dispatchAsync,
    webshopMHFData,
}: ActivateInp): EpicUpdaterReducerReturnType<EpicState, void, UpdateReason> => {
    let {
            section,
            cmpsMap,
            cmpsMapExtension,
            multipleActionsToDispatch
        } = moveOutAndStoreOldCmpsData(epicState, sectionName),
        MHFCurrentActionStep,
        result: Record<string, any> = {};
    const sectionProps = getOldSectionProps(section, shareSectionBgImg);
    if (!section.modernLayout) {
        //first time activation
        MHFCurrentActionStep = MHFCurrentActionSteps.ACTIVATE_LAYOUT_STEP;
        result = createComponentsFromLayoutAndRearrange(
            layoutsMap[sectionName][layoutId],
            section,
            {
                componentsMap: cmpsMap,
                componentsMapExtension: cmpsMapExtension
            },
            componentsDependencies,
            templateWidth
        );
        multipleActionsToDispatch.push(
            getSectionCmpToggleAction(result, sectionName, section.id, layoutId)
        );
    } else {
        //restore layout
        MHFCurrentActionStep = MHFCurrentActionSteps.RESTORE_LAYOUT_STEP;
        const {
                cmpsMap: allCmpsInSectionMap,
                cmpsMapExtension: newCmpsMapExtension
            } = mhfData.newData[sectionName][section.modernLayout.layoutId],
            layoutVersion = section.modernLayout && section.modernLayout.layoutVersion;
        const toggleOffCmpIds = getFlattenedOptions(section.modernLayout.options)
            .filter(({ show, id }) => !show && !!allCmpsInSectionMap[id])
            .map(({ id }) => id);

        const newCmpsMap = omit(toggleOffCmpIds, allCmpsInSectionMap),
            toggleOffCmps = toggleOffCmpIds.map(id => allCmpsInSectionMap[id]);

        result = {
            componentsMap: { ...cmpsMap, ...newCmpsMap },
            componentsMapExtension: { ...cmpsMapExtension, ...newCmpsMapExtension },
            newComponentsIds: Object.keys(newCmpsMap).filter(id => !isSectionComponent(newCmpsMap[id]))
        };
        result.componentsMap = adjustSectionsHeights(
            sectionName,
            section.id,
            result.componentsMap,
            result.newComponentsIds
        );
        if (webshopMHFData && sectionName === sectionNames.footer) {
            let [newCmps, actionsToDispatch, deletedCmpIds] =
                removeWebshopMHFDataFromLayout(section.id, result.componentsMap, webshopMHFData);
            result.componentsMap = newCmps;
            multipleActionsToDispatch.push(...actionsToDispatch);
            result.newComponentsIds = result.newComponentsIds.filter(id => !deletedCmpIds.includes(id));
        }
        //upgrading the layout if the user has already activated MHF before but is currently in deactivated state
        if (!layoutVersion || (sectionName === sectionNames.footer
            && isAddWebshopMHFCmps(webshopMHFData, result.componentsMap, layoutId, mhfData))) {
            result = {
                ...result,
                componentsMap: upgradeModernSections(result.componentsMap, result.componentsMapExtension, layoutsMap, mhfData.newData),
            };
        }
        multipleActionsToDispatch.push(
            getSectionCmpToggleAction(result, sectionName, section.id, layoutId)
        );
        multipleActionsToDispatch.push({
            type: MODERN_HEADER_FOOTER_TOGGLE_CMPS_SET,
            payload: { cmpList: toggleOffCmps, layoutId, sectionName }
        });
    }
    const sectionCmp = result.componentsMap[section.id];
    if (sectionCmp.modernLayout) {
        // store old style and pin settings and remove current border and bg image and set default pin value
        result.componentsMap[section.id] = {
            ...sectionCmp,
            modernLayout: {
                ...sectionCmp.modernLayout,
                active: true,
                sectionProps: {
                    ...(sectionCmp.modernLayout.sectionProps || {}),
                    old: sectionProps
                }
            },
            style: defaultSectionStyle,
            pin: 0
        };

        delete result.componentsMap[section.id].selectedBorderTheme;

        delete result.componentsMap[section.id].selectedGradientTheme;
    }

    if (!section.modernLayout) {
        // change bg color to layout section color
        result.componentsMap = getCmpsMapWithUpdatedSectionColor(result.componentsMap, section.id, 1);
    } else {
        // set style and other section props data from old data
        const cmp = result.componentsMap[section.id];
        if (cmp.modernLayout) {
            const layoutProps = cmp.modernLayout.sectionProps.new &&
                cmp.modernLayout.sectionProps.new[layoutId];
            if (layoutProps) {
                result.componentsMap[section.id] = {
                    ...cmp,
                    ...layoutProps,
                    style: {
                        ...cmp.style,
                        ...layoutProps.style
                    },
                };
            }
            result = dissocPath(
                ['componentsMap', section.id, 'modernLayout', 'sectionProps', 'new', layoutId],
                result
            );
        }
    }

    // @ts-ignore
    return updateState({
        state: epicState,
        actionsToDispatch: multipleActionsToDispatch,
        sectionName,
        MHFCurrentActionStep,
        dispatchAsync,
        ...result,
        srcActionType: MHFADActionTypes[sectionName].activate.srcAction,
        resultActionType: MHFADActionTypes[sectionName].activate.activatedActionType
    });
};

type DeactivateInp = {
    sectionName: string,
    epicState: EpicState,
    mhfData: MHFDataEpicState,
    dispatchAsync: Dispatch
};

const deactivateSection = (
    { sectionName, epicState, mhfData, dispatchAsync }: DeactivateInp
): EpicUpdaterReducerReturnType<EpicState, void, UpdateReason> => {
    const {
            section,
            cmpsMap,
            cmpsMapExtension,
            multipleActionsToDispatch
        } = moveOutAndStoreOldCmpsData(epicState, sectionName),
        oldSectionData = mhfData.oldData[sectionName],
        newComponentsIds = Object.keys(oldSectionData.cmpsMap),
        // @ts-ignore this may cause a bug
        sectionProps = getOldSectionProps(section);
    let
        componentsMap = adjustSectionsHeights(
            sectionName,
            section.id,
            {
                ...cmpsMap,
                ...oldSectionData.cmpsMap
            },
            newComponentsIds
        ),
        MHFCurrentActionStep = MHFCurrentActionSteps.DEACTIVATE_LAYOUT_STEP;
    const sectionCmp = componentsMap[section.id];
    if (sectionCmp.modernLayout) {
        componentsMap[section.id] = {
            ...sectionCmp,
            modernLayout: {
                ...sectionCmp.modernLayout,
                active: false,
                sectionProps: {
                    new: {
                        ...(sectionCmp.modernLayout.sectionProps.new || {}),
                        [sectionCmp.modernLayout.layoutId]: sectionProps
                    }
                },
            },
            ...sectionCmp.modernLayout.sectionProps.old,
            style: { ...sectionCmp.style, ...sectionCmp.modernLayout.sectionProps.old.style },
        };
    }

    return updateState({
        state: epicState,
        actionsToDispatch: multipleActionsToDispatch,
        sectionName,
        MHFCurrentActionStep,
        dispatchAsync,
        componentsMap,
        componentsMapExtension: {
            ...cmpsMapExtension,
            ...oldSectionData.cmpsMapExtension
        },
        newComponentsIds,
        srcActionType: MHFADActionTypes[sectionName].deactivate.srcAction,
        resultActionType: MHFADActionTypes[sectionName].deactivate.deactivatedActionType
    });
};

const switchSection = ({
    sectionName,
    epicState,
    componentsDependencies,
    templateWidth,
    mhfData,
    layoutId,
    layoutsMap,
    shareSectionBgImg,
    dispatchAsync,
    webshopMHFData,
}: ActivateInp): EpicUpdaterReducerReturnType<EpicState, void, UpdateReason> => {
    let {
            section,
            cmpsMap,
            cmpsMapExtension,
            multipleActionsToDispatch
        } = moveOutAndStoreOldCmpsData(epicState, sectionName, mhfData),
        result: Record<string, any> = {},
        MHFCurrentActionStep = MHFCurrentActionSteps.SWITCH_LAYOUT_STEP,
        sectionProps = getOldSectionProps(section, shareSectionBgImg);

    if (mhfData.newData[sectionName][layoutId]) {
        //restore layout
        const {
            cmpsMap: allCmpsInSectionMap,
            cmpsMapExtension: newCmpsMapExtension,
            options: optionsFromNewData,
        } = mhfData.newData[sectionName][layoutId];
        const toggleOffCmpIds = getFlattenedOptions(optionsFromNewData)
            .filter(({ show, id }) => !show && !!allCmpsInSectionMap[id])
            .map(({ id }) => id);

        const newCmpsMap = omit(toggleOffCmpIds, allCmpsInSectionMap),
            toggleOffCmps = toggleOffCmpIds.map(id => allCmpsInSectionMap[id]);
        result = {
            componentsMap: { ...cmpsMap, ...newCmpsMap },
            componentsMapExtension: { ...cmpsMapExtension, ...newCmpsMapExtension },
            newComponentsIds: Object.keys(newCmpsMap).filter(id => !isSectionComponent(newCmpsMap[id]))
        };
        result.componentsMap[section.id] = {
            ...result.componentsMap[section.id],
            modernLayout: {
                ...result.componentsMap[section.id].modernLayout,
                layout: mhfData.newData[sectionName][layoutId].layout,
                options: optionsFromNewData,
                layoutId,
            }
        };
        if (webshopMHFData && sectionName === sectionNames.footer) {
            let [newCmps, actionsToDispatch, deletedCmpIds] =
                removeWebshopMHFDataFromLayout(section.id, result.componentsMap, webshopMHFData);
            result.componentsMap = newCmps;
            multipleActionsToDispatch.push(...actionsToDispatch);
            result.newComponentsIds = result.newComponentsIds.filter(id => !deletedCmpIds.includes(id));
        }
        // set style and other section props data from old data
        const cmp = result.componentsMap[section.id];
        const layoutProps = cmp.modernLayout.sectionProps.new &&
            cmp.modernLayout.sectionProps.new[layoutId];
        if (layoutProps) {
            result.componentsMap[section.id] = {
                ...cmp,
                ...layoutProps,
                style: {
                    ...cmp.style,
                    ...layoutProps.style
                },
            };
            result = dissocPath(
                ['componentsMap', section.id, 'modernLayout', 'sectionProps', 'new', layoutId],
                result
            );
            delete result.componentsMap[section.id].selectedBorderTheme;
            delete result.componentsMap[section.id].selectedGradientTheme;
        }
        result.componentsMap = arrangeComponentsAndUpdateSectionHeight(
            result.componentsMap,
            section.id,
            false,
        );
        multipleActionsToDispatch.push({
            type: MODERN_HEADER_FOOTER_TOGGLE_CMPS_SET,
            payload: { cmpList: toggleOffCmps, layoutId, sectionName }
        });
    } else {
        //first time activation
        result = createComponentsFromLayoutAndRearrange(
            layoutsMap[sectionName][layoutId],
            section,
            {
                componentsMap: cmpsMap,
                componentsMapExtension: cmpsMapExtension
            },
            componentsDependencies,
            templateWidth
        );

        delete result.componentsMap[section.id].selectedBorderTheme;

        delete result.componentsMap[section.id].selectedGradientTheme;
        // layout section color
        result.componentsMap = getCmpsMapWithUpdatedSectionColor(result.componentsMap, section.id, 1);

        multipleActionsToDispatch.push(
            getSectionCmpToggleAction(result, sectionName, section.id, layoutId)
        );
    }

    const sectionCmp = result.componentsMap[section.id];
    // store old style and pin settings and remove current border and bg image and set default pin value
    const oldSectionProps = (section.modernLayout && section.modernLayout.sectionProps) || {},
        oldLayoutId = section.modernLayout && section.modernLayout.layoutId;
    if (sectionCmp.modernLayout) {
        result.componentsMap[section.id] = {
            ...sectionCmp,
            modernLayout: {
                ...sectionCmp.modernLayout,
                sectionProps: {
                    ...oldSectionProps,
                    new: {
                        ...(oldSectionProps.new || {}),
                        [oldLayoutId]: sectionProps
                    }
                },
            }
        };
    }

    // @ts-ignore
    return updateState({
        state: epicState,
        actionsToDispatch: multipleActionsToDispatch,
        sectionName,
        MHFCurrentActionStep,
        dispatchAsync,
        ...result,
        srcActionType: MHFADActionTypes[sectionName].activate.srcAction,
        resultActionType: MHFADActionTypes[sectionName].activate.activatedActionType
    });
};

export {
    activateSection,
    deactivateSection,
    switchSection,
    upgradeSection
};
