import generateHtml from './generateHtml';
import {
    isTrialSubscription,
    isPremiumSubscription,
    isEcommerceSubscription
} from "../../App/epics/subscriptionData/utils";

export default (props: any): string => {
    const {
        preview: {
            data,
            siteMap,
            siteSettings,
            currentPageId,
            currentPageName,
            shareHeaderAndFirstSectionBgImg,
            mobileData,
            previewHTML,
            template,
            fonts,
            domain,
            componentsMap,
            dynamicHeightsCmpInfo,
            dynamicHeightComponentAttachments,
            childToParentMap,
            webshopMHFData
        },
        componentsDependencies,
        globalStyles,
        stylesheetsIdToNameMap,
        previewLoaded,
        subscriptionData,
        mobileStyle,
        menuState,
        config
    } = props;
    const { subscriptionType } = subscriptionData;
    let html = '';
    if (!previewHTML) {
        const props = {
            data,
            mobileData,
            componentsDependencies,
            globalStyles,
            stylesheetsIdToNameMap,
            siteMap,
            siteSettings,
            page: {
                id: currentPageId,
                name: currentPageName,
                shareHeaderAndFirstSectionBgImg: shareHeaderAndFirstSectionBgImg.enabledBeforeModernLayout,
                shareModernHeaderAndFirstSectionBgImg: shareHeaderAndFirstSectionBgImg.enabledInModernLayout,
                shareBgImgOffsetTop: shareHeaderAndFirstSectionBgImg.offsetTop
            },
            template,
            fonts,
            isServerPreview: false,
            domain,
            hdImages: (
                isEcommerceSubscription(subscriptionType) ||
                isPremiumSubscription(subscriptionType) ||
                isTrialSubscription(subscriptionType)
            ),
            componentsMap,
            mobileStyle,
            menuState,
            dynamicHeightsCmpInfo,
            dynamicHeightComponentAttachments,
            childToParentMap,
            // WBTGEN-16354
            userHasEcommerceSubscription: false,
            userHasPremiumSubscription: false,
            flagPublish: false,
            gaAllowed: false,
            fbPixelAllowed: false,
            config,
            webshopMHFData,
        };
        html = generateHtml(props);
        previewLoaded(html);
    }
    return previewHTML || html;
};
