/* eslint-disable max-len */
import React, { useEffect } from "react";
import cx from "classnames";
import { equals } from "ramda";
import { UPDATE_THEME_PALETTE } from "../../Workspace/epics/stylesheets/actionTypes";
import Scrollbar from "../../../view/common/Scrollbar/index";
import * as styles from "../AutoColorLeftPanel.css";
import {
    HIDE_AUTO_COLOR_LEFT_PANEL,
    TURN_OFF_EDIT_MODE,
    DONE_AUTO_COLOR_LEFT_PANEL,
    SAVE_COLOR_THEME,
    ADD_THEME_HISTORY,
    THEME_SINGLE_COLOR_CHANGE
} from "../actionTypes";
import { PopularPaletteSelection } from "./PopularPaletteSelection";
import { EditColors } from "./EditColors";
import { Intl, Msg } from "../../../view/intl/index";
import type { ThemeColorDataType } from "../../ThemeGlobalData/flowTypes";
import type { EpicState } from "../flowTypes";
import { RESET_UNDO_STACK_INDEX_AND_FLUSH } from "../../../epics/undoManager/actionTypes";

type PropsType = {
    intl: Intl;
    dispatch: Dispatch;
    themeColors: ThemeColorDataType;
    leftPanel: EpicState;
};

const AutoColorModeOn = (props: PropsType) => {
    const { intl, dispatch, themeColors, leftPanel } = props;
    const { mainColor, accentColor, whiteColor, blackColor } = themeColors || {};
    const palette = [accentColor, mainColor, whiteColor, blackColor],
        { initialPalette, edit, paletteBeforeEdit, animation } = leftPanel,
        revertDisabled = equals(initialPalette, palette);
    useEffect(() => {
        dispatch({
            type: ADD_THEME_HISTORY,
            payload: palette
        }); // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <React.Fragment>
            <Scrollbar height="100%">
                {!edit ? (
                    <PopularPaletteSelection dispatch={dispatch} themeColors={themeColors} intl={intl} />
                ) : (
                    <EditColors
                        dispatch={dispatch}
                        themeColors={themeColors}
                        animation={animation}
                        themeSingleColorChangeAction={THEME_SINGLE_COLOR_CHANGE}
                    />
                )}
            </Scrollbar>
            <div className={cx(styles.footer)}>
                <div
                    className={styles.cancelBtn}
                    onClick={() => {
                        if (!revertDisabled) {
                            if (edit) {
                                dispatch({
                                    type: UPDATE_THEME_PALETTE,
                                    payload: paletteBeforeEdit
                                });
                            } else {
                                dispatch({
                                    type: UPDATE_THEME_PALETTE,
                                    payload: initialPalette
                                });
                                dispatch({
                                    type: RESET_UNDO_STACK_INDEX_AND_FLUSH,
                                    payload: leftPanel.undoStackIndex
                                });
                            }
                        }

                        if (edit) {
                            dispatch({
                                type: TURN_OFF_EDIT_MODE
                            });
                        } else {
                            dispatch({
                                type: HIDE_AUTO_COLOR_LEFT_PANEL
                            });
                        }
                    }}
                >
                    {edit ? <Msg k="common.back">Back</Msg> : <Msg k="common.cancel">Cancel</Msg>}
                </div>
                {edit ? (
                    <button
                        type="button"
                        className={styles.finishEditingBtn}
                        onClick={() => {
                            dispatch({
                                type: SAVE_COLOR_THEME,
                                payload: palette
                            });
                            dispatch({
                                type: ADD_THEME_HISTORY,
                                payload: palette
                            });
                        }}
                    >
                        <Msg k="common.save">Save</Msg>
                    </button>
                ) : (
                    <button
                        type="button"
                        className={styles.doneBtn}
                        onClick={() => {
                            dispatch({
                                type: DONE_AUTO_COLOR_LEFT_PANEL
                            });
                        }}
                    >
                        <Msg k="common.done">Done</Msg>
                    </button>
                )}
            </div>
        </React.Fragment>
    );
};

export { AutoColorModeOn };
