/* eslint-disable max-len */

export const DemoLoginMessages = {
    signInHeaderLabel: "msg: demo.login.signin.header.label {Sign in to existing account}",
    signUpHeaderLabel: "msg: demo.signup.header.label {Start your 14-day free trial}",
    resetEmailHeaderLabel: "msg: demo.reset.email.label {Reset your password}",
    resetEmailSuccessHeaderLabel: "msg: demo.reset.email.success.label {Check your inbox}",
    resetPasswordSuccessHeaderLabel: "msg: common.done.title {Done!}",
    resetPasswordHeaderLabel: "msg: demo.reset.password.label {Reset your password}",
    resetPasswordHeaderMsg: "msg: demo.reset.password.msg {Enter your new password and your’re good to go.}",
    resetPasswordSuccessHeaderMsg: "msg: demo.reset.password.success.msg{All you need to do is go back and log in.}",
    resetEmailSuccessHeaderMsg: "msg: demo.reset.email.success.msg{Almost there}",
    resetEmailHeaderMsg: "msg: demo.reset.email.msg {We’ll email you instructions to reset your password.}",
    resetEmailBodyMsg: "msg: demo.reset.email.body.msg {We’ll email you a password reset link.}",
    doneLabel: "msg: common.done.title {Done!}",
    showLabel: "msg: common.show {Show}",
    hideLabel: "msg: common.hide {Hide}",
    emailLabel: "msg: common.email{Email}",
    passwordLabel: "msg: common.password {Password}",
    newPasswordLabel: "msg: common.newPassword {New password}",
    expiredLinkErrorMsg: "msg: demo.login.forgotPassword.expiredLinkError{That link has expired. Please enter your email, so we can send a new link.}",
    termsConditionNotCheckedError: "msg: demo.login.signup.validation.termsConditionNotChecked {Looks like you forgot to read and check the terms.}",
    unmatchedPasswordsError: "msg: demo.login.error.unmatchedPasswordsError{Passwords don’t match.}",
    invalidPasswordMatchError: "msg:demo.login.error.passwordNotMatching {Passwords don’t match.}",
    invalidEmailError: "msg: demo.login.error.invalidEmail {Check that the email is valid.}",
    invalidPasswordError: "msg: demo.login.error.invalidPassword {Sorry, the password is not correct.}",
    invalidEmailCredentialsError: "msg: demo.login.error.invalidCredentials {Sorry, we couldn’t find an account with this email.}",
    userAlreadyExistsError: "msg: demo.login.error.userAlreadyExists {Another user with this email already exists. Try to log in instead.}",
    userAlreadyExistsErrorForNewSignup: "msg: demo.login.newSignup.error.userAlreadyExists {Another user with this email already exists.}",
    attempLimitExceedsError: "msg: demo.login.error.attemptLimitExceedsError {Too many failed attempts. Please try again in 3 minutes.}",
    incorrectUsernameOrPasswordError: "msg: demo.login.error.incorrectUsernameOrPasswordError {Incorrect email or password.}",
    trialSignUpButtonLabel: "msg: demo.login.signup.button.label {Start free trial}",
    trialSignInButtonLabel: "msg: demo.login.signin.button.label {Log in}",
    forgotPasswordLabel: "msg: common.forgotPassword{Forgot password?}",
    returnToLoginLabel: "msg:demo.login.reset.returnToLogin.label {Return to login}",
    resetPasswordLabel: "msg: demo.login.reset.resetPassword.label {Reset password}",
    confirmPasswordLabel: "msg: common.confirmPassword { Confirm new password}",
    passwordUpdatedLabel: "msg: demo.login.reset.passwordUpdated.label {Your password has been updated.}",
    emailConfirmedLabel: "msg: demo.login.verification.email.label {Your email has been confirmed.}",
    checkInboxLabel: "msg: demo.login.reset.checkInbox.label{ We\'ve emailed password reset instructions. If an account with that email address exists it should be in your inbox!}",
    checkInboxMsg: "msg: demo.login.reset.checkInbox.msg {If the email doesn’t show up soon, check your spam folder.}",

    privacyPolicyLinkHostnet: "msg: hostnet.demo.login.termsAndCondition.privacyPolicy {https://www.hostnet.nl/over-hostnet/privacy-en-cookieverklaring}",
    termsOfUseLinkHostnet: "msg: hostnet.demo.login.termsAndCondition.termsOfUse.link {https://www.hostnet.nl/over-hostnet/algemene-voorwaarden}",

    privacyPolicyLink: "msg: demo.login.termsAndCondition.privacyPolicy {https://www.one.com/en/info/privacy-policy}",
    termsOfUseLink: "msg: demo.login.termsAndCondition.termsOfUse.link {https://www.one.com/en/terms-and-conditions-eur}",
    cookiesPolicyLink: "msg: demo.login.cookiePolicy.link {https://www.one.com/en/info/cookie-policy }",

    websiteBuilderPageLink: "msg: demo.login.websiteBuilder.page.link {https://www.one.com/en/websitebuilder}",
    trialEndedLabel: "msg: demo.login.trialEnded.label {Your trial has ended}",
    trialEndedMsg: "msg: demo.login.trialEnded.msg {Choose a plan to activate your website today.}",
    trialProgressKeeperMsg: "msg: demo.login.progressKeeper.msg {We will keep the progress of your website until}",
    trialSelectPlanLabel: "msg: demo.login.selectPlan.label {Select plan}",
    invalidCaptchaErrorMessage: "msg: demo.login.invalidCaptcha.error.message {Sorry, something went wrong with authentication or the CAPTCHA. Please try again.}",
    trialUserEmailAlreadyVerifiedMessage: "msg: demo.emailVerification.alreadyVerified {Your email is already verified.}",
    userNotFoundLabel: "msg: demo.user.notFound.label {User not found}",
    trialUserNotFoundLabel: "msg: demo.login.userNotFound.label {Consider signing up again.}",
    goToSignUpLabel: "msg:demo.login.emailVerification.goToSignup.label {Go to sign up}",
    userIsDisabledError: "msg: demo.login.error.userDisabledError {Sorry, this user is disabled. Please contact our support.}",
    verificationEmailSentMsg: "msg: demo.login.verificationEmailRequired.verificationEmailSent.message {We’ve sent an email with a link to verify your account.}",
    userEmailVerificationRequiredHeader: "msg: demo.login.error.userEmailVerificationRequired.checkInbox {Check your inbox!}",
    userEmailVerificationRequiredResendLabel: "msg: demo.login.error.userEmailVerificationRequired.resendLabel {Resend email}",
    termsForTrialTipHeader: "msg: demo.login.termsForTrial.tip.header {Special conditions that apply to your free trial}",
    termsForTrialTipDeleteTerm: "msg: demo.login.termsForTrial.tip.deleteTerm {We will delete your data 14 days after your free trial ends, if you do not upgrade to one of our subscription plans.}",
    termsForTrialTipSubscriptionTerm: "msg: demo.login.termsForTrial.tip.subscriptionTerm {Your free trial will not automatically convert to a subscription.}",
    termsForTrialTipEmailTerm: "msg: demo.login.termsForTrial.tip.emailTerm {We will send you emails about Website Builder to get you started.}",
    termsForTrialTipPersonalDataDisclaimer: "msg: demo.login.termsForTrial.tip.personalDataDisclaimer {During the trial checkdomain GmbH might carry out processing of personal data (identifiable information in data you entered in oneWebsitebuilder like mail-addresses) on behalf of yours according to Art 28 GDPR.  For this purpose, checkdomain GmbH engaged one.com Group AB for the provision of oneWebsitebuilder (Sweden) and AWS for hosting (Germany).}",
    verifyEmailToasterMessage: "msg: demo.login.verify.email.toaster.msg {Please verify your email address to finish the account activation.}",
    userVerificationEmailSentMessage: "msg: demo.login.verify.emailSent.msg {We’ve sent an email with a link to verify your account. If the email does not show up soon, check your spam folder.}",
    buyToPublishLabel: "msg: buyToPublishVideo.title {Buy a plan to publish}",
    buyToPublishMsg: "msg: buyToPublishVideo.msg {You cannot publish with the trial version of Website Builder. Choose a domain and plan to bring your website online. Watch our video for more information.}",
    buyToPublishLink: "msg: buyToPublishVideo.link {ZvAwqGooY28}",
    upgradeToPublishLabel: "msg: common.tip.upgradeToPublish.label {Upgrade to publish}",
    upgradeToPublishMsg: "msg: demo.tip.upgradeToPublish.msg {You cannot publish with the trial version of Website Builder. Please upgrade to make your website go live.}",
    welcomeFreeTrialMsg: "msg: demo.login.welcomeFreeTrial.msg {Welcome to the free trial}",
    mobileTrialBuildWebsiteMsg: "msg: demo.login.mobileBlock.msg {You are all set to build your website,}",
    mobileTrialTouchDeviceMsg: "msg: demo.login.touchDevice.msg {but not from a mobile touch device.}",
    mobileTrialComputerUseMsg: "msg: demo.login.computerUse.msg { Please use a computer.}",
    customError: "msg: demo.login.custom.error {An error occurred, please try again later or contact support.}",
    tryForFreeHeader: "msg: demo.login.tryForFree.header {Turn your idea into reality}",
    tryForFreeMsg: "msg: demo.login.tryForFree.msg {Build the website of your dreams, fast and easily, without any coding.}",
    tryForFreeBtnLabel: "msg: demo.login.tryForFree.btnLabel {Try it for FREE}",
    tryForFreeBtnLabel2: "msg: demo.login.tryForFree.btnLabel2 {Try it for free}",
    tryForFreeFooterMsg: "msg: demo.login.tryForFree.footer.msg {14 days trial. No credit card required.}",
    fastAndEasyHeader: "msg: demo.login.fastAndEasy.header {Fast and easy}",
    fastAndEasyHeader2: "msg: demo.login.fastAndEasy.header2 {Fast & easy}",
    fastAndEasyMsg: "msg: demo.login.fastAndEasy.msg {Drag and drop components using our ready-made designs and free image library.}",
    getStartedBtnLabel: "msg: demo.login.getStarted {Get started}",
    uniqueTemplatesHeader: "msg: demo.login.uniqueTemplates.header {Unique templates}",
    uniqueTemplatesMsg: "msg: demo.login.uniqueTemplates.msg {Choose from over 140 professionally designed templates - each tailor-made to help you achieve your vision.}",
    customerSupportHeader: "msg: demo.login.customerSupport.header {24 hour support}",
    customerSupportMsg: "msg: demo.login.customerSupport.msg {Get in touch with one of our trained supporters all day, every day of the week.}",
    whyChooseLabel: "msg: demo.login.whyChoose.label {Why choose one.com?}",
    oneStopLabel: "msg: demo.login.oneStop.label {One stop.}",
    oneStopMsg: "msg: demo.login.oneStop.msg {Domain, email, website in a complete solution, all on one single platform.}",
    fastLabel: "msg: demo.login.fast.label {Fast.}",
    fastMsg: "msg: demo.login.fast.msg {Reliable and fast performance for all your web hosting needs.}",
    secureLabel: "msg: demo.login.secure.label {Secure.}",
    secureMsg: "msg: demo.login.secure.msg {The Backup & Restore feature secures and protects your website to keep your data safe.}",
    getStartedExclamation: "msg: demo.login.getStartedExclamation {Get started!}",
    newSignupDialogLabel: "msg: demo.login.newSignup.dialog.label {Start your 14 day free trial now. No credit card required.}",
    newSignupDialogMsg: "msg: demo.login.newSignup.dialog.msg {Start your free trial}",
    sevenCharactersMinimumPasswordChecker: "msg: demo.login.password.validation.minimumLength.label {7 characters minimum}",
    noSpaceAllowedPasswordChecker: "msg: demo.login.password.validation.noSpaces.label {No spaces allowed}",
    exampleEmailSignupPage: "msg: demo.login.newSignup.dialog.exampleEmail {email@example.com}",
    needHelpLabel: "msg: demo.login.needHelp {Need help?}",
    websiteBuilderLabel: "msg: common.websiteBuilder{Website Builder}",
    findOutMoreLabel: "msg: demo.login.findOutMore {Find out more}",
    oneComSupporter: "msg: demo.login.oneComSupporter {One.com Supporter}",
    emailVerificationReminderMessage: "msg: demo.login.forgotPassword.emailVerificationReminder.message {Remember your email address needs to be verified before resetting your password.}",
    emailVerificationSentMessage: "msg: demo.login.forgotPassword.emailVerificationSent.message {We’ve sent you a verification email. Didn’t get it?}",
    sendVerificationEmailMessage: "msg: demo.login.forgotPassword.sendVerificationEmailMessage {Resend verification email.}",
    compareAllOurPlans: 'msg: common.compareAllOurPlans {<link>Compare all our plans</link>}',
    doTheOneThingYouLove: 'msg: trial.lander.doTheOneThingYouLove {Do the one thing you love}',
    topMessage: 'msg: trial.lander.dreamWebsiteMsg {Whatever you’re passionate about, we make it easy for you to build your dream website and bring your dream online. }',
    free14daysTrialMsg: 'msg: trial.lander.free14daysTrialMsg {Free 14 day trial. No credit card required. Cancel any time.}',
    middleMessage: 'msg: trial.lander.weWillAnswerYourEmail {We\'ll answer your email within 24 hours. Chat support is available during business hours.}',
    contactUs: 'msg: common.contactUs {<link>Contact us</link>}',
    createAWebsite: 'msg: trial.lander.createAWebsite {Create a website in a few easy steps}',
    ourWebsiteBuilderMakes: 'msg: trial.lander.ourWebsiteBuilderMakes {Our Website Builder makes creating a website easy. You don\'t need to have any experience and you can have your website online the same day!}',
    chooseATemplate: 'msg: trial.lander.chooseATemplate {Choose a template}',
    addYourOwnTouch: 'msg: trial.lander.addYourOwnTouch {Add your own touch}',
    publishWebsite: 'msg: trial.lander.publishWebsite {Publish your website}',
    youDontNeedCodingSkills: 'msg: trial.lander.youDontNeedCodingSkills {You don’t need coding skills to create a professional website.}',
    customizeToYourNeeds: 'msg: trial.lander.customizeToYourNeeds {Customise to your needs}',
    chooseOver140Templates: 'msg: trial.lander.chooseOver140Templates {Choose from over 140 professionally designed templates. Each is made to help you reach your vision. Every template is responsive and looks great on any device.}',
    mailCampaignAgreementMsg: 'msg: demo.signup.mailCampaignAgreement {I want to receive tips and special offers from {partnerInfo} via email.}'
};
