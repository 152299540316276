import { getAppConfig } from "./components/App/epics/appConfig/appConfig";
import { SubscriptionFeatureManager } from "../../server/shared/subscriptionStatus/SubscriptionFeatureManager";
import { SubscriptionTypes } from "./constants/app";

let subscriptionFeatureManager: SubscriptionFeatureManager = null;

const getSubscriptionFeatureManager = () => {
    if (subscriptionFeatureManager) return subscriptionFeatureManager;
    // By default it always sets to unknown and on load of application or when subscription type changes,
    // we should update it with new subscription type by calling setCurrentSubscriptin method
    const currentSubscription = SubscriptionTypes.UNKNOWN;
    const rawAppConfig = getAppConfig();
    const subscriptionsConfig = rawAppConfig.subscriptions;
    const partnerName = rawAppConfig.partnerName;
    subscriptionFeatureManager = new SubscriptionFeatureManager(subscriptionsConfig, partnerName);

    subscriptionFeatureManager.setCurrentSubscription(currentSubscription);
    return subscriptionFeatureManager;
};

export const setSubscriptionFeatureManagerToNull = () => {
    subscriptionFeatureManager = null;
};

export default getSubscriptionFeatureManager;
