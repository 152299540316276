const
    ONBOARDING_WELCOME_TRACKER = {
        CATEGORY: "Onboarding",
        ACTION: "entered_onboarding",
        LABEL: "days_seen_on_boarding_welcome",
    },
    PUBLISHED_WITH_OWN_CONTENT_TRACKER = {
        CATEGORY: "Publishing",
        ACTION: "days_published",
        LABEL: "days_published_with_own_content",
    };

export const events = [
    ONBOARDING_WELCOME_TRACKER,
    PUBLISHED_WITH_OWN_CONTENT_TRACKER
];
