import React from 'react';
import type { Dimensions } from '../../../../redux/modules/children/workspace/flowTypes';
import styles from './Image.css';

// NOTE: 20 subtracted from height to compensate the line-height of the text "Broken image" in Image.css

const brokeImageCssLineHeight = 20;

// TODO: WBTGEN-15530: replace usage of this with BrokenMultimedia component
export default ({ width, height }: Dimensions) => (
    <img
        role="presentation"
        className={styles.brokenImageCls}
        style={{ width, height: (height - brokeImageCssLineHeight) }}
    />
);
