import React from 'react';
import View from './index';
import BaseComponent from '../../../../view/oneweb/BaseComponent';
import type { Props } from '../flowTypes';

export default (props: Props) => {
    return (
        <BaseComponent {...props}>
            {React.createElement(View, { ...props, isWorkspace: true })}
        </BaseComponent>
    );
};
