import React from 'react';
import { Intl, injectIntl } from '../../../view/intl/index';
import InputField from '../../../view/common/Input/PropertiesInputField';
import styles from './MctaInput.css';
import type { MsgJointInput } from "../../../view/intl/injectIntl";

type Props = {
    label: string;
    value: string|number;
    placeholder: MsgJointInput;
    className?: string;
    inputStyle?: Style;
    onChange?: (...arg: any) => void;
    intl: Intl;
    maxLength?: number;
    focusOnLoad?: boolean;
}

export default injectIntl(({ label, value, placeholder, onChange, className, inputStyle, intl, maxLength, focusOnLoad }: Props) => (
    <div className={styles.mctaInput}>
        <label>{intl.msgJoint(label)}:
            <InputField
                value={value}
                placeholder={placeholder}
                className={className}
                onChange={onChange}
                style={inputStyle}
                maxLength={maxLength}
                focusOnLoad={focusOnLoad}
            />
        </label>
    </div>
));
