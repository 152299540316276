import { pathOr } from 'ramda';
import React from 'react';
import BorderSettings from "../../../../presentational/BorderSettings/index";
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import type { PageProps } from "../../../../PropertiesPanel/flowTypes";
import type { GalleryComponent } from "../../flowTypes";
import * as ActionTypes from '../../actionTypes';
import * as pageIds from '../pagesIds';
import pageTitles from '../pagesTitles';
import {
    getBorderColor,
    getBorderOpacity,
    getBorderStyle,
    getBorderWidth, getCorner
} from "../../../../../view/oneweb/commonComponentSelectors";
import { calcGalleryMaxThumbnailDimensions } from "../../calcRenderProps";
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import ImageRatioComboBox from "../../../../presentational/ImageRatioComboBox/index";
import styles from "./styles.css";
import Msg from "../../../../../view/intl/Msg";
import { getThemeRulesForBackground } from '../../../../ThemeGlobalData/themeRules';

const
    id = pageIds.IMAGE_STYLES,
    title = pageTitles[id],
    view = (props: PageProps<GalleryComponent>) => {
        const
            { selectedComponent } = props,
            styleObj = { style: { ...selectedComponent.imageStyle } },
            values = {
                style: getBorderStyle(styleObj),
                opacity: getBorderOpacity(styleObj),
                widths: getBorderWidth(styleObj),
                corners: getCorner(styleObj)
            },
            { maxThumbnailWidth: width, maxThumbnailHeight: height } =
                calcGalleryMaxThumbnailDimensions(selectedComponent),
            captionLink = (
                <a onClick={() => props.navigateToPage(pageIds.CAPTIONS)}>
                    <Msg k="component.gallery.propPanel.captions.title">Captions</Msg>
                </a>
            );

        return (
            <Page>
                <VerticalSpacer />
                <ImageRatioComboBox
                    value={selectedComponent.imageRatio}
                    onChangeAction={ActionTypes.GALLERY_PP_IMAGE_RATIO}
                    dispatch={props.dispatch}
                />
                {
                    props.themeSettingsData.autoColorMode
                        ? <BorderSettings.view
                            autoColorMode={props.themeSettingsData.autoColorMode}
                            themeColor={pathOr(
                                getThemeRulesForBackground(props.selectedComponentProps.selectedParentTheme, props.themeColorsData).text,
                                ['selectedComponent', 'imageStyle', 'border', 'themeColor'],
                                props
                            )}
                            themeColorsData={props.themeColorsData}
                            styleChangeAction={ActionTypes.GALLERY_PP_IMAGE_BORDER_TYPE_CHANGE}
                            colorChangeAction={
                                props.themeSettingsData.autoColorMode
                                    ? ActionTypes.GALLERY_PP_IMAGE_BORDER_COLOR_CHANGE_AUTO_COLOR
                                    : ActionTypes.GALLERY_PP_IMAGE_BORDER_COLOR_CHANGE
                            }
                            opacityChangeAction={ActionTypes.GALLERY_PP_IMAGE_BORDER_OPACITY_CHANGE}
                            widthChangeAction={ActionTypes.GALLERY_PP_IMAGE_BORDER_WIDTH_CHANGE}
                            cornerChangeAction={ActionTypes.GALLERY_PP_IMAGE_BORDER_CORNER_CHANGE}
                            dispatch={props.dispatch}
                            {...values}
                            selectedComponent={{ width, height }}
                        />
                        : <BorderSettings.view
                            styleChangeAction={ActionTypes.GALLERY_PP_IMAGE_BORDER_TYPE_CHANGE}
                            colorChangeAction={
                                props.themeSettingsData.autoColorMode
                                    ? ActionTypes.GALLERY_PP_IMAGE_BORDER_COLOR_CHANGE_AUTO_COLOR
                                    : ActionTypes.GALLERY_PP_IMAGE_BORDER_COLOR_CHANGE
                            }
                            opacityChangeAction={ActionTypes.GALLERY_PP_IMAGE_BORDER_OPACITY_CHANGE}
                            widthChangeAction={ActionTypes.GALLERY_PP_IMAGE_BORDER_WIDTH_CHANGE}
                            cornerChangeAction={ActionTypes.GALLERY_PP_IMAGE_BORDER_CORNER_CHANGE}
                            dispatch={props.dispatch}
                            color={getBorderColor(styleObj)}
                            {...values}
                            selectedComponent={{ width, height }}
                        />
                }
                <VerticalSpacer />
                <Msg
                    className={styles.message}
                    k="component.gallery.pp.captionBox.navigation.helpText"
                    params={{ captionLink }}
                >
                    {`Do you want to edit the border of a caption box?
                    Go up one level to {captionLink} and choose "Custom" as your caption style.`}
                </Msg>
            </Page>
        );
    };

export { id, title, view };
