import * as React from 'react';
import { connect } from "react-redux";
import styles from './FileManagerDialog.css';
import { isString } from '../../../../../utils/string';
import { Dialog, DialogBody, DialogFooter } from '../../../../view/common/dialogs/baseDialog/index';
import { FcUploadPaneCom } from "../../../../view/common/FileChooser/FileChooserHeader/FcUploadPaneCom";
import type { AppState } from '../../../../redux/modules/flowTypes';
import { injectIntl, Msg } from '../../../../view/intl/index';
import { sstockQuotaAppStateSel } from "../../../App/epics/appConfig/selectors";
import type { FileManagerDialogProps } from "../types";
import { FileManagerDialogTabsCom } from "./FileManagerDialogTabsCom";
import { FmTabId } from "../constants";
import Resource from "../../../../redux/modules/children/fileChooser/Resource";
import { SecondaryButton } from "../../../../view/common/Button/index";
import { deselectAllShutterstockImagesAction } from "../../shutterstock/actions";
import { deselectAllUnsplashImagesAction } from "../../unsplash/actions";
import { fcDeselectAllMyFilesAction } from '../../../../redux/modules/children/fileChooser/actionCreators/index';
import { ShutterstockKind } from "../../shutterstock/constants";
import { switchFmTabAction } from "../actions";
import { ThirdPartyImageKind } from "../../unsplash/components/constants";

class FileManagerDialogClass extends React.Component<FileManagerDialogProps> {
    static defaultProps = {
        isMultiSelect: false,
        forwardToComponent: false,
    };

    tabsRef: React.RefObject<typeof FileManagerDialogTabsCom>;

    constructor(props: FileManagerDialogProps) {
        super(props);
        this.tabsRef = React.createRef();
    }

    onDeselectAll = () => {
        const { state: { activeTabId } } = this.props;

        const action = (() => {
            if (activeTabId === FmTabId.MY_IMAGES || activeTabId === FmTabId.MY_VIDEOS) {
                return fcDeselectAllMyFilesAction();
            } else if (activeTabId === FmTabId.FREE_ONECOM_IMAGES) {
                return deselectAllShutterstockImagesAction(ShutterstockKind.FREE);
            } else if (activeTabId === FmTabId.UNSPLASH) {
                return deselectAllUnsplashImagesAction();
            }
            return deselectAllShutterstockImagesAction(ShutterstockKind.PAID);
        })();

        this.props.dispatch(action);
    }

    getTabsCom(): null | undefined | typeof FileManagerDialogTabsCom {
        return this.tabsRef.current;
    }

    isSaveDisabled() {
        const { state: { activeTabId, selection } } = this.props;

        if (activeTabId === FmTabId.FREE_ONECOM_IMAGES) {
            const isVideoFileDialog = !Array.isArray(this.props.contentTypes) &&
                this.props.contentTypes &&
                this.props.contentTypes.startsWith("^video");
            return isVideoFileDialog ? !selection.length :
                (!this.props.state[ShutterstockKind.FREE].selectedImages.length ||
                this.props.state[ShutterstockKind.FREE].mctaDisabled);
        } else if (activeTabId === FmTabId.PAID_SHUTTERSTOCK) {
            return !this.props.state[ShutterstockKind.PAID].selectedImages.length ||
                this.props.state[ShutterstockKind.PAID].mctaDisabled;
        } else if (activeTabId === FmTabId.UNSPLASH) {
            return !this.props.state[FmTabId.UNSPLASH].selectedImages.length ||
                    this.props.state[FmTabId.UNSPLASH].mctaDisabled;
        }
        return !selection.length;
    }

    getSelectedCount() {
        const { state: { activeTabId, selection } } = this.props;
        switch (activeTabId) {
            case FmTabId.MY_IMAGES:
                return selection.length;
            case FmTabId.MY_VIDEOS:
                return selection.length;
            case FmTabId.FREE_ONECOM_IMAGES:
                return this.props.state[ShutterstockKind.FREE].selectedImages.length;
            case FmTabId.PAID_SHUTTERSTOCK:
                return this.props.state[ShutterstockKind.PAID].selectedImages.length;
            case FmTabId.UNSPLASH:
                return this.props.state[ThirdPartyImageKind.UNSPLASH].selectedImages.length;
            default:
                throw new Error(`Unknown tab ${activeTabId}`);
        }
    }

    renderFooter() {
        const tabs = this.getTabsCom();

        if (!tabs) return null;

        const
            { state: { isMultiSelect, fileListUploadProgress } } = this.props,
            children: React.JSX.Element[] = [],
            // @ts-ignore
            activeTab = tabs.getActiveTab(this.props.state.activeTabId),
            selectedCount = this.getSelectedCount();

        if (isMultiSelect && selectedCount) {
            children.push(
                <SecondaryButton
                    className={styles.dialogFooterDeselectAllBtn}
                    onClick={this.onDeselectAll}
                    isIntl
                    key={0}
                >
                    {`msg: fm.deselectAll {Deselect all}`}
                </SecondaryButton>
            );

            children.push(
                <Msg k="fm.selectedCount" params={{ selectedCount }} key={1}>
                    {`{selectedCount, plural, one {# image selected} other {# images selected}}`}
                </Msg>
            );
        }

        if (activeTab.footerChildren) {
            children.push(<div key={4}>{activeTab.footerChildren}</div>);
        }

        if (fileListUploadProgress) {
            children.push(
                <Msg
                    k="fc.fileListUploadProgress"
                    params={fileListUploadProgress}
                    className={styles.dialogFooterUploadMsg}
                    key={2}
                >
                    {`{done, number} of {total, number} uploaded`}
                </Msg>
            );
        }
        const mctaText = ((label) => {
            if (label && typeof label === "function") return label(!!selectedCount, this.props.state);
            return label;
        });

        const
            mcta = activeTab.mcta,
            scta = activeTab.scta,
            footerProps = {
                mctaHandler: mcta && mcta.handler,
                mctaText: mctaText(mcta && mcta.label),
                sctaHandler: scta && scta.handler,
                sctaText: scta && scta.label,
                disabled: this.isSaveDisabled(),
                className: styles.dialogFooter,
                contentClassName: styles.dialogFooterContent,
                footerButtonContainerClassName: styles.dialogFooterButtonContainer,
            };

        return <DialogFooter {...footerProps}>{children}</DialogFooter>;
    }

    componentDidMount() {
        const activeTabId = this.props.activeTabId || this.props.state.activeTabId;
        this.props.dispatch(switchFmTabAction(activeTabId));
    }

    render() {
        const
            {
                title,
                dimensions: { height: dialogHeight },
                preSelection: propPreselection,
                contentTypes: propContentTypes,
                extraImageContentTypes = [],
                acceptTypes,
                mode,
                ...restProps
            } = this.props,
            // TODO: should be refactored to use Array<Resource> everywhere
            preSelection = propPreselection && isString(propPreselection)
                // @ts-ignore
                ? [Resource.fromWebspaceUrl(propPreselection)]
                : propPreselection,
            tabsProps = {
                ...restProps,
                dialogHeight,
                preSelection,
            };

        let contentTypes;
        if (propContentTypes) {
            contentTypes = Array.isArray(propContentTypes) ? propContentTypes : [propContentTypes];
            contentTypes = contentTypes.concat(extraImageContentTypes);
            // @ts-ignore
            tabsProps.contentTypes = contentTypes;
        }

        const
            tabs = this.getTabsCom(),
            // @ts-ignore
            isFreeOneComTab = (tabs && tabs.getActiveTab(this.props.state.activeTabId).id === "freeOnecom") || false,
            // @ts-ignore
            scta = tabs && tabs.getActiveTab(this.props.state.activeTabId).scta.handler;

        return (
            // @ts-ignore
            <Dialog className={styles.dialog} onClose={scta} applyOldStyleForCloseBtn>
                <DialogBody className={styles.dialogBody}>
                    <FcUploadPaneCom
                        isFreeOneComVideoFileChooser={isFreeOneComTab}
                        title={title}
                        className={styles.uploadPane}
                        acceptTypes={acceptTypes}
                        mode={mode}
                    />
                    <FileManagerDialogTabsCom ref={this.tabsRef} {...tabsProps} />
                </DialogBody>
                { /* @ts-ignore */ }
                {this.renderFooter()}
            </Dialog>
        );
    }
}

const mapStateToProps = (appState: AppState) => {
    const
        {
            fileChooser,
            subscriptionData: { subscriptionType, metadata: subscriptionMetadata }
        } = appState,
        quotaConf = sstockQuotaAppStateSel(appState);

    return {
        state: fileChooser,
        subscriptionType,
        subscriptionMetadata,
        quotaConf
    };
};

export const FileManagerDialogCom = connect(mapStateToProps)(injectIntl(FileManagerDialogClass));
