import CALL_API from '../../../../redux/middleware/api/CALL_API';
import {
    YOUTUBE_VIDEOS_FETCH_REQUEST,
    YOUTUBE_VIDEOS_FETCH_SUCCESS,
    YOUTUBE_VIDEOS_FETCH_FAILURE
} from '../actionTypes';

import type { ApiAction } from '../../../../redux/middleware/api/flowTypes';
import type { Params } from '../view/flowTypes';

export default function (params: Params): ApiAction {
    return {
        [CALL_API]: {
            types: [
                YOUTUBE_VIDEOS_FETCH_REQUEST,
                YOUTUBE_VIDEOS_FETCH_SUCCESS,
                YOUTUBE_VIDEOS_FETCH_FAILURE
            ],
            endpoint: 'searchYoutubeVideos',
            endpointParams: params
        }
    };
}
