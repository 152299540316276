import type { ButtonStyleData } from "../../../../src/components/oneweb/Button/flowTypes";

const buttonState = {
    block: {
        border: null,
        background: null,
        padding: null
    },
    text: {
        size: null
    },
    verticalAlign: null,
    horizontalAlign: null
};

export const buttonStyleMapper = {
    to: (style: any): ButtonStyleData => ({
        globalId: style.globalId,
        globalName: style.globalName,
        type: style.type,
        text: {
            size: style.inactive ? (style.inactive.text.size || null) : null
        }
    }),
    back: (style: ButtonStyleData): any => ({
        // old data might be missing type
        // type is required to find matching global style when switching the template
        type: style.type || 'web.data.styles.StyleButton',
        globalId: style.globalId,
        globalName: style.globalName,
        inactive: {
            ...buttonState,
            text: {
                size: style.text.size
            }
        },
        visited: buttonState,
        hover: buttonState,
        press: buttonState,
        disabled: buttonState
    })
};
