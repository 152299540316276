export const
    DEFAULT_WIDTH = 250,
    DEFAULT_HEIGHT = 90,
    EmbedTypes = ['paypal', 'profile'],
    defaultState = {
        link: "",
        label: "",
        shape: "",
        color: "",
        layout: "",
        scriptLink: "",
        purchaseUnit: "",
        mobileHide: false,
    },
    paypal = 'paypal',
    checkout = 'checkout',
    buynow = 'buynow',
    pay = 'pay',
    donate = 'donate',
    DATA_TEST_ID = 'paypal-widget',
    Labels = {
        [`${paypal}`]: 'msg: widgets.paypal.pp.label.paypal {PayPal}',
        [`${checkout}`]: 'msg: widgets.paypal.pp.label.checkout {Checkout}',
        [`${buynow}`]: 'msg: widgets.paypal.pp.label.buynow {Buy now}',
        [`${pay}`]: 'msg: widgets.paypal.pp.label.pay {Pay With}',
        [`${donate}`]: 'msg: widgets.paypal.pp.label.donate {Donate}',
    },
    SupportedColor = {
        WHITE: 'white',
        BLACK: 'black',
        GOLD: 'gold',
        BLUE: 'blue',
        SILVER: 'silver'
    },
    supportedColor = {
        [SupportedColor.WHITE]: 'msg: widgets.paypal.pp.color.white {White}',
        [SupportedColor.BLACK]: 'msg: widgets.paypal.pp.color.black {Black}',
        [SupportedColor.GOLD]: 'msg: widgets.paypal.pp.color.gold {Gold}',
        [SupportedColor.BLUE]: 'msg: widgets.paypal.pp.color.blue {Blue}',
        [SupportedColor.SILVER]: 'msg: widgets.paypal.pp.color.silver {Silver}',
    },
    SupportedShape = {
        RECTANGLE: 'rect',
        PILL: 'pill'
    },
    supportedShape = {
        [SupportedShape.RECTANGLE]: 'msg: widgets.paypal.pp.shape.rect {Rectangle}',
        [SupportedShape.PILL]: 'msg: widgets.paypal.pp.shape.pill {Pill}',
    },
    SupportedLayout = {
        VERTICAL: 'vertical',
        HORIZONTAL: 'horizontal'
    },
    supportedLayouts = {
        [SupportedLayout.VERTICAL]: "msg: widgets.paypal.pp.layout.vertical {Vertical}",
        [SupportedLayout.HORIZONTAL]: "msg: widgets.paypal.pp.layout.horizontal {Horizontal}",
    };
