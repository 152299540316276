import kind from "./kind";
import propertiesPanel from "./propertiesPanel/index";
import view from './view/index';
import workspace from './view/workspace';
import reducer from './reducer/index';
import previewConfig from './previewConfig';
import { ComponentNames } from "../constants";
import { ConnectToGoogleDialogId } from "./dialog/dialogIds";
import componentMainActions from './componentMainActions';
import adjustmentHookConfig from "./adjustmentHookConfig";
import calcRenderProps from "./calcRenderProps";
import getHiddenElementData from "./mobileEditorConfig/hiddenElementsData";
import defaultStyleIdEpic from '../commonEpics/buttonDefaultStyleId';
import stylesheetsEpic from '../../Workspace/epics/stylesheets/index';
import stylesheetsIdToNameMapEpic from '../../Workspace/epics/stylesheets/idToNameMap';
import { globalVariablesEpic } from "../../App/epics/globalVariablesEpic";
import { colorThemeSiteSettingsEpic } from "../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import { adjustComponentOnAdd } from "./adjustComponentOnAdd";
import { widthReducer as templateWidth } from "../Template/epics/template/index";
import { componentTierManagerEpic } from "../../ComponentTierManager/epics/index";

export default {
    kind,
    label: ComponentNames[kind],
    view,
    workspace,
    calcRenderProps,
    reducer,
    dependsOn: {
        globalStyles: stylesheetsEpic.reducer,
        stylesheetsIdToNameMap: stylesheetsIdToNameMapEpic.reducer,
        buttonDefaultStyleId: defaultStyleIdEpic.reducer,
        globalVariables: globalVariablesEpic.reducer,
        themeSettingsData: colorThemeSiteSettingsEpic.reducer,
        templateWidth,
        componentTierData: componentTierManagerEpic.reducer,
    },
    componentMainActions,
    propertiesPanel,
    requireConfigurationOnDrop: () => true,
    configurationDialog: {
        dialogId: ConnectToGoogleDialogId,
    },
    adjustComponentOnAdd,
    adjustmentHookConfig,
    mobileEditorConfig: {
        ...previewConfig,
        getHiddenElementData
    }
};

