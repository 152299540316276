import React from 'react';
import { connect } from 'react-redux';
import cx from "classnames";
import { Box, FormControl } from "@mui/material";

import { PlaceholderInput, SettingsInputLabel } from '../../../../SettingsControls';

import { getDomainNameToRenderForSSL } from "../../../../../../../../wbtgen/src/components/SiteSettings/publishConfig/utils";
import { selectDomain } from '../../../../../common/selectors';

import imageStyles from "../../../../Images/imageStyle.css";
import { useStyles } from "../styles";

import type { SSLPreviewSectionProps } from "../types";

export const SSLPreviewSectionComponent = (props: SSLPreviewSectionProps) => {
    const
        styles = useStyles(),
        { intl, publishConfig: { publishWithSSL }, domain } = props,
        domainToRender = getDomainNameToRenderForSSL(domain, publishWithSSL);

    let content;

    if (publishWithSSL) {
        content = (
            <div>
                <span className={cx(styles.icon, imageStyles.blueLock)} />
                <span className={cx(styles.label, styles.labelBlue)}>https</span>
                <span className={cx(styles.label, styles.labelGrey)}>://{domainToRender}</span>
            </div>
        );
    } else {
        content = (
            <div>
                <span className={cx(styles.icon, imageStyles.greyInfo)} />
                <span className={cx(styles.label, styles.labelBlue)}>
                    {intl.msgJoint("msg: securityTab.notSecure {Not Secure}")}
                </span>
                <span className={styles.bordered}>&nbsp;</span>
                <span className={cx(styles.label, styles.labelBlue)}>http</span>
                <span className={cx(styles.label, styles.labelGrey)}>://{domainToRender}</span>
            </div>
        );
    }

    return (
        <Box mt={{ md: '36px' }}>
            <FormControl fullWidth>
                <SettingsInputLabel>
                    {intl.msgJoint('msg: socialShare.preview {Preview}')}
                </SettingsInputLabel>
                <PlaceholderInput>
                    {content}
                </PlaceholderInput>
            </FormControl>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    domain: selectDomain(state),
});

export const SSLPreviewSection = connect(mapStateToProps)(SSLPreviewSectionComponent);
