import {
    copyPageTemplateRefApiAction,
    deletePageTemplateAllApiAction,
    getTemplatesWithPagesApiAction
} from "../../../redux/middleware/api/commonApiActions";
import { openDialog } from "../../App/actionCreators";
import { PAGE_TEMPLATE_SELECTOR_DIALOG_ID } from "./dialogId";
import type { PageTemplates, PageTemplateSelectorDialogProps } from "./types";
import {
    PAGE_TEMPLATE_SELECTOR_DELETE_CONFIRM_DIALOG_ID
} from "./dialogs/deleteConfirmDialog/pageTemlateSelectorDeleteConfirmDialog";

export const
    OPEN_PAGE_TEMPLATE_SELECTOR_ACTION = 'OPEN_PAGE_TEMPLATE_SELECTOR_ACTION',
    openPageTemplateSelectorAction = (props: PageTemplateSelectorDialogProps) => ({
        type: OPEN_PAGE_TEMPLATE_SELECTOR_ACTION,
        payload: props
    });

export const
    openPageTemplateSelectorDialogAction = (props: PageTemplateSelectorDialogProps) => openDialog(
        PAGE_TEMPLATE_SELECTOR_DIALOG_ID,
        props
    );

export const
    SET_PAGE_TEMPLATES_ACTION = 'SET_PAGE_TEMPLATES_ACTION',
    setPageTemplatesAction = (templates: PageTemplates) => ({
        type: SET_PAGE_TEMPLATES_ACTION,
        payload: templates
    });

export const
    LOAD_PAGE_TEMPLATES_REQUEST_ACTION = 'LOAD_PAGE_TEMPLATES_REQUEST_ACTION',
    LOAD_PAGE_TEMPLATES_SUCCESS_ACTION = 'LOAD_PAGE_TEMPLATES_SUCCESS_ACTION',
    LOAD_PAGE_TEMPLATES_FAILURE_ACTION = 'LOAD_PAGE_TEMPLATES_FAILURE_ACTION',
    loadPageTemplatesApiAction = () => getTemplatesWithPagesApiAction({
        types: [
            LOAD_PAGE_TEMPLATES_REQUEST_ACTION,
            LOAD_PAGE_TEMPLATES_SUCCESS_ACTION,
            LOAD_PAGE_TEMPLATES_FAILURE_ACTION
        ]
    });

export const
    SELECT_PAGE_TEMPLATE_ACTION = 'SELECT_PAGE_TEMPLATE_ACTION',
    selectPageTemplateAction = (id: string) => ({
        type: SELECT_PAGE_TEMPLATE_ACTION,
        payload: id
    });

export const
    savePageTemplateSelectionAction = (type: string, templateId: string) => ({
        type,
        payload: templateId
    });

export const
    DUPLICATE_PAGE_TEMPLATE_ACTION = 'DUPLICATE_PAGE_TEMPLATE_ACTION',
    duplicatePageTemplateAction = (id: string) => ({
        type: DUPLICATE_PAGE_TEMPLATE_ACTION,
        payload: id
    });

export const
    DUPLICATE_PAGE_TEMPLATE_REQUEST_ACTION = 'DUPLICATE_PAGE_TEMPLATE_REQUEST_ACTION',
    DUPLICATE_PAGE_TEMPLATE_SUCCESS_ACTION = 'DUPLICATE_PAGE_TEMPLATE_SUCCESS_ACTION',
    DUPLICATE_PAGE_TEMPLATE_FAILURE_ACTION = 'DUPLICATE_PAGE_TEMPLATE_FAILURE_ACTION',
    duplicatePageTemplateApiAction = (templateId: string) => copyPageTemplateRefApiAction({
        types: [
            DUPLICATE_PAGE_TEMPLATE_REQUEST_ACTION,
            DUPLICATE_PAGE_TEMPLATE_SUCCESS_ACTION,
            DUPLICATE_PAGE_TEMPLATE_FAILURE_ACTION
        ],
        templateId
    });

export const
    DELETE_PAGE_TEMPLATE_ACTION = 'DELETE_PAGE_TEMPLATE_ACTION',
    deletePageTemplateAction = (id: string) => ({
        type: DELETE_PAGE_TEMPLATE_ACTION,
        payload: id
    });

export const openPageTemplateSelectorDeleteConfirmDialog = () => openDialog(
    PAGE_TEMPLATE_SELECTOR_DELETE_CONFIRM_DIALOG_ID
);

export const
    CONFIRM_DELETE_PAGE_TEMPLATE_ACTION = 'CONFIRM_DELETE_PAGE_TEMPLATE_ACTION',
    confirmDeletePageTemplateAction = () => ({
        type: CONFIRM_DELETE_PAGE_TEMPLATE_ACTION
    });

export const
    DELETE_PAGE_TEMPLATE_REQUEST_ACTION = 'DELETE_PAGE_TEMPLATE_REQUEST_ACTION',
    DELETE_PAGE_TEMPLATE_SUCCESS_ACTION = 'DELETE_PAGE_TEMPLATE_SUCCESS_ACTION',
    DELETE_PAGE_TEMPLATE_FAILURE_ACTION = 'DELETE_PAGE_TEMPLATE_FAILURE_ACTION',
    deletePageTemplateApiAction = (templateId: string) =>
        deletePageTemplateAllApiAction({
            types: [
                DELETE_PAGE_TEMPLATE_REQUEST_ACTION,
                DELETE_PAGE_TEMPLATE_SUCCESS_ACTION,
                DELETE_PAGE_TEMPLATE_FAILURE_ACTION
            ],
            templateId
        });

export const
    HOLD_PAGE_TEMPLATE_SELECTOR_ACTION = 'HOLD_PAGE_TEMPLATE_SELECTOR_ACTION',
    holdPageTemplateSelectorAction = () => ({
        type: HOLD_PAGE_TEMPLATE_SELECTOR_ACTION
    });

export const
    UNHOLD_PAGE_TEMPLATE_SELECTOR_ACTION = 'UNHOLD_PAGE_TEMPLATE_SELECTOR_ACTION',
    unholdPageTemplateSelectorAction = () => ({
        type: UNHOLD_PAGE_TEMPLATE_SELECTOR_ACTION
    });

export const
    HOLD_PAGE_ITEM_SELECTOR_ITEM_ACTION = 'HOLD_PAGE_ITEM_SELECTOR_ITEM_ACTION',
    holdPageItemSelectorItemAction = (id: string) => ({
        type: HOLD_PAGE_ITEM_SELECTOR_ITEM_ACTION,
        payload: id
    });

export const
    UNHOLD_PAGE_ITEM_SELECTOR_ITEM_ACTION = 'UNHOLD_PAGE_ITEM_SELECTOR_ITEM_ACTION',
    unholdPageItemSelectorItemAction = (id: string) => ({
        type: UNHOLD_PAGE_ITEM_SELECTOR_ITEM_ACTION,
        payload: id
    });

export const
    SHOW_PAGE_TEMPLATE_PREVIEW_ACTION = 'SHOW_PAGE_TEMPLATE_PREVIEW_ACTION',
    showPageTemplatePreviewAction = (id: string) => ({
        type: SHOW_PAGE_TEMPLATE_PREVIEW_ACTION,
        payload: id
    });

export const
    HIDE_PAGE_TEMPLATE_PREVIEW_ACTION = 'HIDE_PAGE_TEMPLATE_PREVIEW_ACTION',
    hidePageTemplatePreviewAction = () => ({
        type: HIDE_PAGE_TEMPLATE_PREVIEW_ACTION
    });

export const
    SELECT_PAGE_TEMPLATE_PREVIEW_ACTION = 'SELECT_PAGE_TEMPLATE_PREVIEW_ACTION',
    selectPageTemplatePreviewAction = (id: string) => ({
        type: SELECT_PAGE_TEMPLATE_PREVIEW_ACTION,
        payload: id
    });
