export const
    WORKSPACE: 'WORKSPACE' = "WORKSPACE",
    EDIT_TEXT_COMPONENT: 'EDIT_TEXT_COMPONENT' = "EDIT_TEXT_COMPONENT",
    EDIT_TABLE_COMPONENT: 'EDIT_TABLE_COMPONENT' = "EDIT_TABLE_COMPONENT",
    EDIT_TABLE_COMPONENT_CELL: 'EDIT_TABLE_COMPONENT_CELL' = "EDIT_TABLE_COMPONENT_CELL",
    PREVIEW: 'PREVIEW' = "PREVIEW",
    DIALOG: 'DIALOG' = "DIALOG",
    MOBILE_EDITOR = "MOBILE_EDITOR",
    PRE_BACKUP = "PRE_BACKUP",
    BACKUP = "BACKUP";
