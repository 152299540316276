import React from "react";
import * as R from "ramda";
import cx from "classnames";
import Button from "../Button/index";
import HorizontalSpacer from "../../../view/common/HorizontalSpacer";
import type { TextIconButtonPropTypes } from "./flowTypes";
import styles from "../../buttons.css";

export default ({
    Icon,
    text,
    onClick,
    iconBeforeText = false,
    disabled = false,
    large = false,
    extraLarge = false,
    isSelected = false,
    isError = false,
    className
}: TextIconButtonPropTypes) => {
    const
        btnProps = {
            large,
            isSelected,
            isError,
            onClick: (disabled ? R.always(null) : onClick),
            className: cx(
                { [styles.buttonLarge]: large && !extraLarge, [styles.buttonExtraLarge]: extraLarge },
                className
            )
        },
        children = [
            <Icon key="child1" />,
            <HorizontalSpacer x={8} key="child2" />,
            <span key="child3">{text}</span>
        ];

    return (
        <Button {...btnProps}>
            {iconBeforeText ? children : children.reverse()}
        </Button>
    );
};

