import React, { useRef } from "react";
import Lottie from "react-web-lottie";
import animationData from "./animation.json";
import { TURN_OFF_ANIMATION } from "../../../actionTypes";
import injectIntl from "../../../../../view/intl/injectIntl";
import { Intl } from "../../../../../view/intl/index";
import styles from "./animation.css";

type Props = {
    dispatch: Dispatch;
    intl: Intl;
};
const ContrastAdjustAnimation = injectIntl(({ dispatch, intl }: Props) => {
    const animationRef = useRef(null);
    const defaultOptions = {
        animationData,
        autoplay: true,
        loop: true,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid meet"
        }
    };
    setTimeout(() => {
        dispatch({
            type: TURN_OFF_ANIMATION
        });
    }, 2000);
    return (
        <div className={styles.container}>
            <div className={styles.animationWrapper}>
                <Lottie lottiRef={animationRef} options={defaultOptions} isClickToPauseDisabled />
                <div className={styles.animationText}>
                    {intl.msgJoint("msg: autoColor.leftPanel.adjustThemeColor.animationText {Adjusting your theme colours}")}
                </div>
            </div>
        </div>
    );
});
export default ContrastAdjustAnimation;
