import React from 'react';
import { Dispatch } from "redux";
import Measure from "react-measure";
import { connect } from "react-redux";
import styles, { componentNameTagHeight } from "../../App.css";
import { NoMouseUpDownAndMove } from "../../common/NoMouseEventsPropagation";
import { OPEN_PROPERTIES_PANEL } from "../../../components/Workspace/actionTypes";
import { propPanelNavigateToPageAC } from "../../../components/PropertiesPanel/actionCreators";
import * as pageIds from "../../../components/oneweb/Strip/propertiesPanel/pageIds";
import type { BBox } from "../../../components/App/flowTypes";
import type { ScrollEpicState } from "../../../components/Workspace/epics/scroll/flowTypes";
import type { ComponentMainActionsEpicState } from "../../../components/Workspace/epics/componentMainActions/flowTypes";
import { SET_SELECTED_COMPONENTS } from "../../../components/Workspace/epics/componentsEval/actionTypes";
import type { AnyComponent } from "../../../components/oneweb/flowTypes";
import { isSectionKind } from "../../../components/oneweb/componentKinds";

const TitleTagLeftPosition = 40;

type Props = {
    component: AnyComponent,
    bBox: BBox,
    scroll: ScrollEpicState,
    dispatch: Dispatch,
    componentMainActions?: ComponentMainActionsEpicState & { mctaBottom: number, mctaLeft: number },
}

const TitleTag = connect()(({ component, bBox, scroll,
    componentMainActions, dispatch }: Props) => {
    const { title, id, kind } = component;

    if (!isSectionKind(kind)) { return null; }

    const [titleDimensions, setTitleDimensions] = React.useState<Record<string, any>>(() => ({})),
        left = bBox.left + TitleTagLeftPosition + scroll.x;

    let top = (bBox.top - (componentNameTagHeight / 2)) - 1;
    if (componentMainActions) {
        const { mctaBottom, mctaLeft, show: isMCTAVisible } = componentMainActions;
        if (isMCTAVisible &&
            ((mctaLeft < (left + titleDimensions.width)) &&
                (bBox.top > mctaBottom && bBox.top < mctaBottom + 20))) {
            top = bBox.top;
        }
    }

    top = top < 0 ? 0 : top;

    return <Measure
        bounds
        onResize={contentRect => {
            setTitleDimensions(contentRect.bounds);
        }}
    >
        {({ measureRef }) => (
            <div
                ref={measureRef}
                {...NoMouseUpDownAndMove}
                onClick={() => {
                    dispatch({ type: SET_SELECTED_COMPONENTS, payload: [id] });
                    setTimeout(() => {
                        dispatch({ type: OPEN_PROPERTIES_PANEL });
                        dispatch(propPanelNavigateToPageAC(pageIds.TITLE, { autoFocus: true }));
                    });
                }}
                className={styles.componentTag}
                style={{ top, left }}
            >{title}</div>
        )}
    </Measure>;
});

export { TitleTag };
