
import {
    makeCombineReducer,
    makeComponentBaseReducers,
    makeDefaultStateReducers,
    makePayloadToSetterReducers,
    makeNegationPathPayloadToSetterReducers
} from "../../../redux/makeReducer/index";
import * as onClickActions from './constants/onClickActions';
import kind from './kind';
import {
    TOGGLE_MOBILE_DOWN_ACTION
} from "../../PropertiesPanel/view/MobileView/actions";
import mobileDownReducer from '../../PropertiesPanel/view/MobileView/mobileDownReducer';
import * as actionTypes from './actionTypes';
import * as mobileSettings from "./reducerMobileSettings";
import { DRAG_AND_DROP_HEIGHT, DRAG_AND_DROP_WIDTH } from "./constants/index";
import { BUTTON_THEME_PRIMARY } from '../../ThemeGlobalData/constants';
import { formatMessage } from "../../../view/intl/CurrentLocale";

export default makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(kind, DRAG_AND_DROP_WIDTH, DRAG_AND_DROP_HEIGHT),
        ...makeDefaultStateReducers({
            images: [],
            rows: 2,
            spacingPx: 14,
            showMoreBtn: false,
            style: null,
            buttonThemeSelected: BUTTON_THEME_PRIMARY,
            fontSizePx: 15,
            captionsEnabled: false,
            columns: 3,
            onClickAction: onClickActions.OPEN_LIGHT_BOX,
            imageStyle: {},
            mobileDown: false,
            mobileSettings: {
                columns: 2,
                spacingPx: 10
            },
            showMoreTxt: null,
        })
    },
    handleActions: {
        [TOGGLE_MOBILE_DOWN_ACTION]: mobileDownReducer,
        [actionTypes.INSTAGRAM_GALLERY_PP_SHOW_MORE_BTN_ENABLED]: (component, { payload: { isEnabled } }) => {
            const showMoreTxt = component.showMoreTxt || formatMessage({
                id: 'common.showMore',
                defaultMessage: 'Show more'
            });
            return {
                ...component,
                showMoreBtn: isEnabled,
                showMoreTxt,
            };
        },
        [actionTypes.INSTAGRAM_GALLERY_PP_SHOW_MORE_BTN_STYLE_CHANGED]: (component, { payload: { stylesheetId, stylesheetName } }) => {
            return {
                ...component,
                style: {
                    ...component.style,
                    globalName: stylesheetName,
                    globalId: stylesheetId
                }
            };
        },
        [actionTypes.INSTAGRAM_GALLERY_PP_SHOW_MORE_BTN_STYLE_CHANGED_AUTO_COLOR]: (component, { payload }) => (
            { ...component, buttonThemeSelected: payload }
        ),
        [actionTypes.INSTAGRAM_GALLERY_MOBILE_COLUMN_CHANGE]: mobileSettings.columnReducer,
        [actionTypes.INSTAGRAM_GALLERY_MOBILE_SPACING_CHANGE]: mobileSettings.spacingReducer,
        ...makePayloadToSetterReducers({
            [actionTypes.INSTAGRAM_GALLERY_PP_ROWS_CHANGED]: 'rows',
            [actionTypes.INSTAGRAM_GALLERY_PP_SPACING_CHANGED]: 'spacingPx',
            [actionTypes.INSTAGRAM_GALLERY_PP_SHOW_MORE_BTN_FONTSIZE_CHANGED]: 'fontSizePx',
            [actionTypes.INSTAGRAM_GALLERY_PP_COLUMNS_CHANGED]: 'columns',
            [actionTypes.INSTAGRAM_GALLERY_ON_CLICK_ACTION_CHANGED]: 'onClickAction',
        }),
        ...makeNegationPathPayloadToSetterReducers({
            [actionTypes.INSTAGRAM_GALLERY_PP_CAPTIONS_ENABLED_CHECKBOX_CLICKED]: ['captionsEnabled']
        }),
    }
});

