import React from "react";
import { connect } from "react-redux";
import * as styles from './provideJsonToDb.css';
import Textarea from "../../../view/common/Input/Textarea";
import * as actionTypes from "../actionTypes";
import { makeEpicStateSelector } from "../../../epics/makeEpic";
import { injectIntl } from "../../../view/intl/index";
import { provideJsonToDbEpic } from "./provideJsonToDbEpic";

type epicDataProps = {
    isInvalidInput: boolean
};

type Props = {
    dispatch: Dispatch,
    epicData: epicDataProps
};

const ProvideJsonToDbTab = ({ dispatch, epicData: { isInvalidInput } }: Props) => {
    return (
        <div className={styles.bodyContainer}>
            <div className={styles.labelTop}>
                <p>
                    {"Eg: {page} / {stylesheet}, {template}, {siteSettings} {siteData} ."}
                </p>
            </div>
            <div className={styles.textAreaContainer}>
                <Textarea
                    className={styles.textArea}
                    onChange={
                        (jsonToDbData) => {
                            dispatch({ type: actionTypes.JSON_TO_DB_CHANGE, payload: { jsonToDbData } });
                        }
                    }
                />
            </div>
            {
                isInvalidInput &&
                <div className={styles.validationInvalidLabel}>
                    Invalid input provided. Please provide the correct type
                </div>
            }
        </div>
    );
};

const
    epicDataSelector = makeEpicStateSelector(provideJsonToDbEpic.valueActionType),
    mapStateToProps = (appState) => ({
        epicData: epicDataSelector(appState)
    });

export const ProvideJsonToDb = injectIntl(connect(mapStateToProps)(ProvideJsonToDbTab));
