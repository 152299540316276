import type { MailToData } from "./types";
import { encodeWebspaceUri } from "../../../../../../dal/utils/webspaceUriTransformers";

export const buildMailTo = (input: MailToData): string => {
    let res = `mailto:${input.email}`;

    if (input.subject) {
        res += `?subject=${encodeWebspaceUri(input.subject)}`;
    }

    return res;
};
