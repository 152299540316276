import type { SideBarDef } from '../components/SideBar/types';
import { HOME_PAGE_SIDEBAR_ITEM, HOME_PAGE_SIDE_BAR_ITEM_ID } from '../components/Pages/Home';
import { MarketingAndSEOSideBarItem } from "../components/Pages/MarketingAndSEO";
import { WebsiteSettingsSideBarItem } from "../components/Pages/WebsiteSettings";
import { AnalyticsSideBarItem } from '../components/Pages/Analytics';
import { OnlineShopSideBarItem } from '../components/Pages/OnlineShop/constants';
import { BookingsSideBarItem } from "../components/Pages/Bookings/constants";

export const
    AUTH_COOKIE_KEY = 'BoneAuth',
    DRAWER_WIDTH = '240px';

export const
    ACTION_MAX_RETRY_AFTER_ERROR = 3;

export const DEFAULT_PAGE_SIDE_BAR_ITEM_ID = HOME_PAGE_SIDE_BAR_ITEM_ID;

export const DASHBOARD_SESSION_DEFAULT_PAGE_DATA_KEY = 'DASHBOARD_SESSION_DEFAULT_PAGE_ID_KEY';

export const
    setDashboardSessionDefaultPage = (id: string, options?: any) => {
        window.sessionStorage.setItem(
            DASHBOARD_SESSION_DEFAULT_PAGE_DATA_KEY,
            JSON.stringify({ id, options }),
        );
    },
    getAndClearDashboardSessionDefaultPage = (): { id: string, options?: any } | null => {
        const data = window.sessionStorage.getItem(
            DASHBOARD_SESSION_DEFAULT_PAGE_DATA_KEY
        );

        if (!data) {
            return null;
        }

        try {
            const { id, options } = JSON.parse(data);

            if (!id) throw new Error();

            window.sessionStorage.removeItem(
                DASHBOARD_SESSION_DEFAULT_PAGE_DATA_KEY
            );

            return { id, options };
        } catch {
            return null;
        }
    };

export const
    SIDE_BAR_ITEMS: SideBarDef[] = [
        HOME_PAGE_SIDEBAR_ITEM,
        AnalyticsSideBarItem,
        MarketingAndSEOSideBarItem,
        WebsiteSettingsSideBarItem,
        OnlineShopSideBarItem,
        BookingsSideBarItem
    ],
    // TODO: not used, keep only for future needs
    COMPANION_APP_URL = {
        android: 'msg: companion.android.appUrl {https://play.google.com/store/apps/details?id=com.dotcompanionapp}',
        iphone: 'msg: companion.iphone.appUrl {https://apps.apple.com/ua/app/one-com-companion/id1522498353}',
    },
    ONE_HELP_URL = 'msg: common.oneHelpUrl {https://help.one.com/hc/en-us}',
    // TODO: not used, keep only for possible future needs
    ONE_TIP_URL = 'msg: common.oneTipUrl {https://www.one.com/en/academy}';
