import React from 'react';
import factory from '../../presentational/Button/factory';
import styles from './styles.css';
import { injectIntl } from "../../../view/intl/index";
import type { Intl } from "../../../view/intl/injectIntl";
import type { MsgJointInput } from "../../../view/intl/index";

type Props = {
    msg: MsgJointInput;
    style?: Record<string, any>;
    className?: string;
    onClick?: (e: React.MouseEvent<any>) => void;
    intl: Intl;
};

export default injectIntl(({ msg, style, onClick, intl, ...rest }: Props) => {
    const
        buttonClassname = styles.mctaButton,
        MctaButton = factory({
            style,
            containerType: 'div',
            containerExtraPropNames: [],
            additionalClassName: buttonClassname
        });

    return (
        <MctaButton onClick={onClick} {...rest}>
            <span>{intl.msgJoint(msg)}</span>
        </MctaButton>
    );
});
