// @ts-nocheck
/* globals $R */

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const GMBConnectedData = function () {
    const domain = $R.store.getState().preferences.owner;
    const url = `/api/v1/${domain}/gmb/getConnectedBusinessData`;

    const [data, setData] = useState({
        status: 'not-initiated'
    });

    useEffect(() => {
        async function fetchData() {
            try {
                let response = await axios.get(url);
                setData({
                    status: 'loaded',
                    json: response.data
                });
            } catch (e: any) {
                setData({
                    status: 'error'
                });
            }
        }

        fetchData();
    }, [url]);

    return (
        <div>
            {
                data.status === 'not-initialized' &&
                <div>Loading ...</div>
            }
            {
                data.status === 'loaded' &&
                <div>
                    <textarea
                        value={JSON.stringify(data.json, null, '    ')}
                        style={{ width: '100%', height: 300 }}
                        readOnly
                    />
                </div>
            }
            {
                data.status === 'error' &&
                <div>Error</div>
            }
        </div>
    );
};

export {
    GMBConnectedData
};
