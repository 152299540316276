import makeEpic from '../../../epics/makeEpic';
import { dialogManagerVAT } from './valueActionType';
import { dialogManagerDefaultScope, dialogManagerDefaultState } from "./constants";
import {
    onCloseAllDialogsUpdater,
    onComponentConfigurationCompleteUpdater,
    onCloseDialogByIdUpdater,
    onCloseDialogByIdOnlyIfTopmostUpdater,
    onDialogClosedUpdater,
    onMouseDownOnDialogsUpdater,
    onClosePersistentDialogUpdater
} from "./closeUpdaters";
import {
    onDraggableAreaMouseDownUpdater,
    onMouseMoveUpdater,
    onMouseUpUpdater
} from "./moveUpdaters";
import {
    onOpenDialogUpdater,
    onReplaceDialogUpdater
} from "./openUpdaters";
import { dialogManagerAfterUpdate } from "./afterUpdate";
import { onAnyActionUpdater, onUpdateDialogUpdater, onWindowResizeUpdater } from "./updateUpdaters";

/*
* Actual state of opened dialogs are stored in scope.openedDialogConfigs
* if any of that configs state changed, that we recompute render props for that dialog
* otherwise we will return old render props for dialog and it will not be rerendered
*
* If dialog marked as persistentState: true, dialog state will be saved in scope.persistentStates map
* And next time we open dialog it will contain previous state
* To clean up that state we use CLOSE_PERSISTENT_DIALOG
* */

const dialogsManagerEpic = makeEpic({
    valueActionType: dialogManagerVAT,
    defaultState: dialogManagerDefaultState,
    defaultScope: dialogManagerDefaultScope,
    afterUpdate: dialogManagerAfterUpdate,
    updaters: [
        onOpenDialogUpdater,
        onReplaceDialogUpdater,
        onCloseAllDialogsUpdater,
        onDialogClosedUpdater,
        onCloseDialogByIdUpdater,
        onCloseDialogByIdOnlyIfTopmostUpdater,
        onClosePersistentDialogUpdater,
        onComponentConfigurationCompleteUpdater,
        onMouseDownOnDialogsUpdater,
        onDraggableAreaMouseDownUpdater,
        onMouseMoveUpdater,
        onMouseUpUpdater,
        onWindowResizeUpdater,
        onUpdateDialogUpdater,
        onAnyActionUpdater
    ]
});

export {
    dialogsManagerEpic
};
