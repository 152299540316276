import type { LayoutsDataMap } from "../flowTypes";
import { H_ALIGN, V_ALIGN } from "../constants";
import { getMLayoutMessages } from "./mLayoutMessages";
import { addWebshopMHFData } from './webshopMHFDataUtils';
import type { WebShopMHFEpicState } from "../../oneweb/WebShopMHF/flowTypes";

//Make sure the layouts width will come up to 1040 px including cmps width and gap between cmps/cols
//excluding the gaps at the edges

const defaultFooterId = 'Footer-Layout-1';
let _footerLayoutMap: LayoutsDataMap | null = null;

const WebshopMHFDataAsBottomStripLayout = ['Footer-Layout-1', 'Footer-Layout-2'];

const getFooterLayoutsMap = ({ showSpecialFooterStrip, data: { policies, paymentMethods } }: WebShopMHFEpicState): LayoutsDataMap => {
    const mLayoutMessages = getMLayoutMessages();
    _footerLayoutMap = {
        'Footer-Layout-1': {
            id: 'Footer-Layout-1',
            title: 'msg: modernLayout.layoutTitle.keepItSimple {Keep it simple}',
            version: 2,
            layout: {
                root: {
                    rows: [{
                        bindingId: 'Row-1',
                        cols: [{
                            bindingId: 'Col-1',
                            hAlign: H_ALIGN.center,
                            vAlign: V_ALIGN.middle,
                            hPositioned: false,
                            cmps: [
                                { id: 'Cmp-logo1', bindingId: 'Cmp-logo1' },
                                { id: 'Cmp-text1', bindingId: 'Cmp-text1', style: { margin: { top: 35 } } },
                            ],
                        }],
                        vResponsive: true,
                    }, {
                        bindingId: 'Row-2',
                        cols: [
                            {
                                bindingId: 'Col-1',
                                hAlign: H_ALIGN.center,
                                vAlign: V_ALIGN.middle,
                                hPositioned: true,
                                cmps: [
                                    { id: 'Cmp-strip1', bindingId: 'Cmp-strip1' }
                                ],
                            }
                        ],
                    }]
                },
                'Cmp-strip1': {
                    rows: [{
                        bindingId: 'Row-3',
                        cols: [
                            {
                                bindingId: 'Col-1',
                                hAlign: H_ALIGN.left,
                                vAlign: V_ALIGN.middle,
                                hPositioned: true,
                                cmps: [
                                    { id: 'Cmp-phone1', bindingId: 'Cmp-phone1' }
                                ],
                            }, {
                                bindingId: 'Col-2',
                                hAlign: H_ALIGN.center,
                                vAlign: V_ALIGN.middle,
                                hPositioned: true,
                                cmps: [
                                    { id: 'Cmp-email1', bindingId: 'Cmp-email1' }
                                ],
                            },
                            {
                                bindingId: 'Col-3',
                                hAlign: H_ALIGN.right,
                                vAlign: V_ALIGN.middle,
                                hPositioned: true,
                                cmps: [
                                    { id: 'Cmp-address1', bindingId: 'Cmp-address1' }
                                ],
                            }
                        ]
                    }],
                    style: { padding: { top: 20, bottom: 20 } }
                },
            },
            componentsMap: {
                'Cmp-logo1': {
                    "id": "Cmp-logo1",
                    "kind": "LOGO",
                    "top": 463,
                    "left": 485,
                    "width": 80,
                    "height": 80,
                    "scaleStrategy": "logo",
                    "modernLayoutOptions": {
                        "size": "medium"
                    },
                },
                'Cmp-text1': {
                    "id": "Cmp-text1",
                    "kind": "TEXT",
                    "top": 538,
                    "left": 225,
                    "width": 600,
                    "height": 36,
                    "content": `<p class="textheading2" style="text-align: center;">
                                <span class="textheading2"  style="color: #3c3c3c;">
                                    ${mLayoutMessages.L1_F_TEXT}
                                </span>
                            </p>`,
                    "verticalAlignment": "top",
                    "text": mLayoutMessages.L1_F_TEXT,
                },
                'Cmp-strip1': {
                    "id": 'Cmp-strip1',
                    "kind": 'STRIP',
                    "top": 678,
                    "left": 0,
                    "width": 600,
                    "height": 90,
                    "selectedTheme": "Accented",
                    "style": {
                        "border": null,
                        "background": {
                            "colorData": {
                                "color": ['HSL', 0.4909090909090909, 0.9166666666666667, 0.23529411764705882, 1],
                                "gradient": null
                            },
                            "assetData": null
                        }
                    }
                },
                'Cmp-phone1': {
                    "id": "Cmp-phone1",
                    "kind": "PHONE",
                    "left": 36,
                    "top": 698,
                    "width": 200,
                    "height": 24,
                    "generic": {
                        "customPrefixText": "Phone: ",
                        "horizontalAlignment": "left",
                        "iconSize": 24,
                        "iconTextSpacing": 16,
                        "mobileFontSize": null,
                        "mobileIconSize": 40,
                        "mobileHorizontalAlignment": null,
                        "showCustomTitleFirst": false,
                        "showIcon": true,
                        "showOnOneLine": true,
                        "textStyle": {
                            "bold": null,
                            "color": ['HSL', 0, 0, 1, 1],
                            "fontFamily": null,
                            "fontSize": null,
                            "italic": null,
                            "letterSpacing": 0,
                            "lineHeight": 1.2,
                            "prefixBold": true,
                            "prefixCase": null,
                            "prefixItalic": null,
                            "prefixUnderline": null,
                            "underline": null,
                            "valueCase": null
                        }
                    },
                    "specific": {
                        "placeholderText": "+45 12345678"
                    },
                    "modernLayoutOptions": {
                        "size": "medium",
                        "mobileSize": "medium"
                    },
                },
                'Cmp-email1': {
                    "id": "Cmp-email1",
                    "kind": "EMAIL",
                    "left": 240,
                    "top": 698,
                    "width": 160,
                    "height": 24,
                    "generic": {
                        "customPrefixText": "Email: ",
                        "horizontalAlignment": "left",
                        "iconSize": 24,
                        "iconTextSpacing": 16,
                        "mobileIconSize": 40,
                        "showCustomTitleFirst": false,
                        "showIcon": true,
                        "showOnOneLine": true,
                        "textStyle": {
                            "letterSpacing": 0,
                            "lineHeight": 1.2,
                            "prefixBold": true,
                            "color": ['HSL', 0, 0, 1, 1],
                        }
                    },
                    "specific": {
                        "placeholderText": "your@email.com"
                    },
                    "modernLayoutOptions": {
                        "size": "medium",
                        "mobileSize": "medium"
                    },
                },
                'Cmp-address1': {
                    "id": "Cmp-address1",
                    "kind": "ADDRESS",
                    "left": 450,
                    "top": 698,
                    "width": 150,
                    "height": 24,
                    "generic": {
                        "customPrefixText": "Address: ",
                        "horizontalAlignment": "left",
                        "iconSize": 24,
                        "iconTextSpacing": 16,
                        "mobileIconSize": 40,
                        "showCustomTitleFirst": false,
                        "showIcon": true,
                        "showOnOneLine": false,
                        "textStyle": {
                            "letterSpacing": 0,
                            "lineHeight": 1.2,
                            "prefixBold": true,
                            "color": ['HSL', 0, 0, 1, 1],
                        }
                    },
                    "specific": {
                        "placeholder": {
                            "addressAreaProvince": "Province",
                            "addressAreaState": "State",
                            "addressCity": "City",
                            "addressCountry": "Country",
                            "addressName": "Business title",
                            "addressStreetAddress": "Street address",
                            "addressZip": "Zip code"
                        },
                        "showCountry": true,
                        "showWebsiteTitleBeforeAddress": true
                    },
                    "modernLayoutOptions": {
                        "size": "medium",
                        "mobileSize": "medium"
                    },
                },
            },
            options: [
                {
                    id: "Cmp-logo1",
                    bindingId: "Cmp-logo1",
                    title: mLayoutMessages.Logo,
                    show: true
                },
                {
                    id: "Cmp-text1",
                    bindingId: "Cmp-text1",
                    title: mLayoutMessages.Heading2,
                    show: true
                },
                {
                    id: "Cmp-strip1",
                    bindingId: "Cmp-strip1",
                    title: mLayoutMessages.ContactComponents,
                    show: true,
                    children: [
                        {
                            id: "Cmp-phone1",
                            bindingId: "Cmp-phone1",
                            title: mLayoutMessages.PhoneNumber,
                            show: true,
                        },
                        {
                            id: "Cmp-email1",
                            bindingId: "Cmp-email1",
                            title: mLayoutMessages.Mail,
                            show: true,
                        }, {
                            id: "Cmp-address1",
                            bindingId: "Cmp-address1",
                            title: mLayoutMessages.Address,
                            show: true,
                        }
                    ]
                }
            ]
        },
        'Footer-Layout-2': {
            id: 'Footer-Layout-2',
            title: 'msg: modernLayout.layoutTitle.encourageAction {Encourage action}',
            version: 1,
            layout: {
                root: {
                    rows: [{
                        bindingId: 'Row-1',
                        cols: [{
                            bindingId: 'Col-1',
                            hAlign: H_ALIGN.center,
                            vAlign: V_ALIGN.middle,
                            hPositioned: false,
                            cmps: [
                                { id: 'Cmp-Heading1', bindingId: 'Cmp-Heading1' },
                                { id: 'Cmp-Heading3', bindingId: 'Cmp-Heading3', style: { margin: { top: 10 } } },
                            ],
                        }],
                        vResponsive: true,
                    }, {
                        bindingId: 'Row-2',
                        cols: [{
                            bindingId: 'Col-1',
                            hAlign: H_ALIGN.center,
                            vAlign: V_ALIGN.middle,
                            hPositioned: true,
                            cmps: [
                                { id: 'Cmp-PrimaryButton', bindingId: 'Cmp-PrimaryButton' },
                                { id: 'Cmp-SecondaryButton', bindingId: 'Cmp-SecondaryButton', style: { margin: { left: 30 } } },
                            ],
                        }],
                        style: { margin: { top: 40 } },
                        vResponsive: true,
                    }],
                }
            },
            componentsMap: {
                "Cmp-Heading1": {
                    "id": "Cmp-Heading1",
                    "kind": "TEXT",
                    "top": 50,
                    "left": 198,
                    "width": 654,
                    "height": 48,
                    "text": "",
                    "content": `<p class="textheading1" style="text-align: center; font-size: 40px;">
                                    <span class="textheading1" style="font-size: 40px;">
                                        ${mLayoutMessages.L3_F_HEADING1}
                                    </span>
                                </p>`,
                    "verticalAlignment": "top"
                },
                "Cmp-Heading3": {
                    "id": "Cmp-Heading3",
                    "kind": "TEXT",
                    "top": 108,
                    "left": 198,
                    "width": 654,
                    "height": 22,
                    "text": "",
                    "content": `<p class="textheading3" style="text-align: center; font-size: 18px;">
                                    <span class="textheading3" style="font-weight: normal; font-size: 18px;">
                                        ${mLayoutMessages.L3_F_HEADING3}
                                    </span>
                                </p>`,
                    "verticalAlignment": "top"
                },
                "Cmp-PrimaryButton": {
                    "id": "Cmp-PrimaryButton",
                    "kind": "BUTTON",
                    "top": 180,
                    "left": 360,
                    "width": 150,
                    "height": 50,
                    "text": mLayoutMessages.ContactUs,
                    "buttonThemeSelected": "primary",
                    "modernLayoutOptions": {
                        "size": "medium"
                    },
                    "style": {
                        "text": {
                            "size": 16
                        }
                    }
                },
                "Cmp-SecondaryButton": {
                    "id": "Cmp-SecondaryButton",
                    "kind": "BUTTON",
                    "top": 180,
                    "left": 540,
                    "width": 150,
                    "height": 50,
                    "text": mLayoutMessages.GiveFeedback,
                    "buttonThemeSelected": "secondary",
                    "modernLayoutOptions": {
                        "size": "medium"
                    },
                    "style": {
                        "text": {
                            "size": 16
                        }
                    }
                }
            },
            options: [
                {
                    id: "Cmp-Heading1",
                    bindingId: "Cmp-Heading1",
                    title: mLayoutMessages.Heading1,
                    show: true
                },
                {
                    id: "Cmp-Heading3",
                    bindingId: "Cmp-Heading3",
                    title: mLayoutMessages.Heading3,
                    show: true
                },
                {
                    id: "Buttons",
                    bindingId: "Buttons",
                    title: mLayoutMessages.Buttons,
                    show: true,
                    children: [
                        {
                            id: "Cmp-PrimaryButton",
                            bindingId: "Cmp-PrimaryButton",
                            title: mLayoutMessages.ContactUs,
                            show: true
                        },
                        {
                            id: "Cmp-SecondaryButton",
                            bindingId: "Cmp-SecondaryButton",
                            title: mLayoutMessages.GiveFeedback,
                            show: false
                        },
                    ]
                }
            ]
        }
    };

    if (showSpecialFooterStrip) {
        _footerLayoutMap = addWebshopMHFData(_footerLayoutMap, WebshopMHFDataAsBottomStripLayout,
            !!policies.length, !!paymentMethods.length);
    }
    return (_footerLayoutMap as LayoutsDataMap);
};

export {
    getFooterLayoutsMap,
    defaultFooterId,
};
