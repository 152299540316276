import {
    ImageSliderBasicType,
    ImageSliderNavigationType,
    ImageSliderPaginationType,
    ImageSliderCaptionBoxType
} from "./kind";
import { ShowNavigator, ShowIndicator } from "./constants";

const TypePropsConfig = {
    [ImageSliderBasicType]: {
        captionsEnabled: false,
        navigator: ShowNavigator.NEVER,
        indicator: ShowIndicator.NEVER,
        stretch: true
    },
    [ImageSliderNavigationType]: {
        captionsEnabled: false,
        navigator: ShowNavigator.ALWAYS,
        indicator: ShowIndicator.NEVER,
        stretch: false
    },
    [ImageSliderPaginationType]: {
        captionsEnabled: false,
        navigator: ShowNavigator.NEVER,
        indicator: ShowIndicator.ALWAYS,
        stretch: true
    },
    [ImageSliderCaptionBoxType]: {
        captionsEnabled: true,
        navigator: ShowNavigator.NEVER,
        indicator: ShowIndicator.NEVER,
        stretch: false
    }
};

export default TypePropsConfig;
