export const
    GS_LINK_GLOBAL_STYLE_SELECTED = 'GS_LINK_GLOBAL_STYLE_SELECTED',

    GS_LINK_INACTIVE_SHADOW_COLOR_CHANGED = 'GS_LINK_INACTIVE_SHADOW_COLOR_CHANGED',
    GS_LINK_INACTIVE_SHADOW_REMOVED = 'GS_LINK_INACTIVE_SHADOW_REMOVED',
    GS_LINK_INACTIVE_SHADOW_BLUR_RADIUS_CHANGED = 'GS_LINK_INACTIVE_SHADOW_BLUR_RADIUS_CHANGED',
    GS_LINK_INACTIVE_SHADOW_HORIZONTAL_OFFSET_CHANGED = 'GS_LINK_INACTIVE_SHADOW_HORIZONTAL_OFFSET_CHANGED',
    GS_LINK_INACTIVE_SHADOW_VERTICAL_OFFSET_CHANGED = 'GS_LINK_INACTIVE_SHADOW_VERTICAL_OFFSET_CHANGED',
    GS_LINK_INACTIVE_TOGGLE_UNDERLINE = 'GS_LINK_INACTIVE_TOGGLE_UNDERLINE',
    GS_LINK_INACTIVE_TEXT_COLOR_CHANGED = 'GS_LINK_INACTIVE_TEXT_COLOR_CHANGED',
    GS_LINK_INACTIVE_TEXT_COLOR_REMOVED = 'GS_LINK_INACTIVE_TEXT_COLOR_REMOVED',
    GS_LINK_INACTIVE_HIGHLIGHT_COLOR_CHANGED = 'GS_LINK_INACTIVE_HIGHLIGHT_COLOR_CHANGED',
    GS_LINK_INACTIVE_HIGHLIGHT_COLOR_REMOVED = 'GS_LINK_INACTIVE_HIGHLIGHT_COLOR_REMOVED',

    GS_LINK_HOVER_SHADOW_COLOR_CHANGED = 'GS_LINK_HOVER_SHADOW_COLOR_CHANGED',
    GS_LINK_HOVER_SHADOW_REMOVED = 'GS_LINK_HOVER_SHADOW_REMOVED',
    GS_LINK_HOVER_SHADOW_BLUR_RADIUS_CHANGED = 'GS_LINK_HOVER_SHADOW_BLUR_RADIUS_CHANGED',
    GS_LINK_HOVER_SHADOW_HORIZONTAL_OFFSET_CHANGED = 'GS_LINK_HOVER_SHADOW_HORIZONTAL_OFFSET_CHANGED',
    GS_LINK_HOVER_SHADOW_VERTICAL_OFFSET_CHANGED = 'GS_LINK_HOVER_SHADOW_VERTICAL_OFFSET_CHANGED',
    GS_LINK_HOVER_TOGGLE_UNDERLINE = 'GS_LINK_HOVER_TOGGLE_UNDERLINE',
    GS_LINK_HOVER_TEXT_COLOR_CHANGED = 'GS_LINK_HOVER_TEXT_COLOR_CHANGED',
    GS_LINK_HOVER_TEXT_COLOR_REMOVED = 'GS_LINK_HOVER_TEXT_COLOR_REMOVED',
    GS_LINK_HOVER_HIGHLIGHT_COLOR_CHANGED = 'GS_LINK_HOVER_HIGHLIGHT_COLOR_CHANGED',
    GS_LINK_HOVER_HIGHLIGHT_COLOR_REMOVED = 'GS_LINK_HOVER_HIGHLIGHT_COLOR_REMOVED';
