import * as R from 'ramda';
import { makeCombineReducer, makeDefaultStateReducers, makeComponentBaseReducers } from "../../../../redux/makeReducer/index";
import { DEFAULT_WIDTH, DEFAULT_HEIGHT, defaultState } from '../constants';
import { TOGGLE_MOBILE_DOWN_ACTION } from "../../../PropertiesPanel/view/MobileView/actions";
import mobileDownReducer from '../../../PropertiesPanel/view/MobileView/mobileDownReducer';
import * as actionTypes from "../actionTypes";
import * as mp from "../../../../mappers/path";
import * as selectors from "../../../Workspace/epics/stylesheets/selectors";
import kind from '../kind';
import { addRowReducer } from "./addRowReducer";
import { addColumnReducer } from "./addColumnReducer";
import { removeRowReducer } from "./removeRowReducer";
import { removeColumnReducer } from "./removeColumnReducer";
import { setCellHeightReducer } from "./setCellHeightReducer";
import { setCellWidthReducer } from "./setCellWidthReducer";
import { setCellSpacingReducer } from "./setCellSpacingReducer";
import { unsetCellTextStylesReducer } from "./unsetCellTextStylesReducer";

import { makeCellsBackgroundReducer } from "./makeCellsBackgroundReducer";
import { onApplyTextCellChangeReducer } from "./onApplyTextCellChangeReducer";
import { makeCellsBorderReducer } from "./makeCellsBorderReducer";
import { makeCellsSinglePropUpdater } from "./makeCellsUpdater";

import {
    BackgroundSize, DefaultAssetData, ReverseBackgroundRepeat
} from "../../../presentational/BackgroundImageSettings/options";

import type { TableComponent, TableComponentCell, TableComponentDependsOn, CommonTableComponentCell } from "../flowTypes";
import { TINY_MCE_CHANGE_FOR_MULTIPLE_INSTANCES_APPLIED } from "../../../App/epics/tinyMceEpic/actionTypes";
import { mapIndexed } from "../../../../utils/ramdaEx";
import { clearContentReducer } from "./clearContentReducer";

const reducer = makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(kind, DEFAULT_WIDTH, DEFAULT_HEIGHT),
        ...makeDefaultStateReducers(defaultState)
    },
    handleActions: {
        [TOGGLE_MOBILE_DOWN_ACTION]: mobileDownReducer,
        [actionTypes.TABLE_REMOVE_ROW_AFTER_EPIC_UPDATE]: removeRowReducer,
        [actionTypes.TABLE_REMOVE_COLUMN_AFTER_EPIC_UPDATE]: removeColumnReducer,
        [actionTypes.APPLY_TEXT_CELL_CHANGE]: onApplyTextCellChangeReducer
    }
});

const
    asset = 'asset',
    isAssetUpdatedToNull = update => update.hasOwnProperty(asset) && R.pipe(R.prop(asset), R.isNil)(update),
    getProp = (propGetterFn, defaultPropName) => (cell) =>
        (propGetterFn([mp.style])(cell) || DefaultAssetData[defaultPropName]),
    getCellBackgroundUpdatedAsset = (cell, update) => {
        const
            assetUpdatedToNull = isAssetUpdatedToNull(update),
            originalSize = selectors.getBackgroundSize([mp.style])(cell);

        let assetData = null;

        if (!assetUpdatedToNull) {
            assetData = {
                asset: getProp(selectors.getBackgroundAsset, mp.asset)(cell),
                position: getProp(selectors.getBackgroundPosition, mp.position)(cell),
                repeat: getProp(selectors.getBackgroundRepeat, mp.repeat)(cell),
                size: R.isNil(originalSize) ? BackgroundSize.FIT : originalSize,
                ...update,
            };
        }

        return R.assocPath([mp.block, mp.background, mp.assetData], assetData, cell.style);
    },
    updateTableCellStyle = (getStyleToApply) => (
        cell: TableComponentCell,
        commonCellsData?: CommonTableComponentCell
    ) => {
        let updatedCell = { ...cell };
        // This is passed only when the data is set for selected cells.
        if (commonCellsData && !updatedCell.style) {
            updatedCell.style = commonCellsData.style;
        }

        return R.evolve({
            style: (style) => R.merge(style, getStyleToApply(updatedCell))
        }, updatedCell);
    },
    makeCellTextStylePropToThemeColorUpdater = (prop) => makeCellsSinglePropUpdater({
        makeCellPropUpdater: (action) => (
            updateTableCellStyle((cell) => ({ text: { ...cell.style.text, [prop]: action.payload.themeColor } }))
        )
    }),
    removeCellTextStylePropFromThemeColorUpdater = (prop) => makeCellsSinglePropUpdater({
        isActionHandledByTableReducerAsWell: true,
        makeCellPropUpdater: () => (
            updateTableCellStyle((cell) => ({ text: { ...cell.style.text, [prop]: null } }))
        ),
    }),
    makeCellTextStylePropUpdater = (prop) => makeCellsSinglePropUpdater({
        makeCellPropUpdater: (action) => (
            updateTableCellStyle((cell) => ({ text: { ...cell.style.text, [prop]: action.payload } }))
        )
    }),
    setContent = R.assoc('content');

const handleActions = {
    [actionTypes.TABLE_CELL_SPACING_CHANGED]: setCellSpacingReducer,

    [actionTypes.TABLE_ADD_ROW]: addRowReducer,
    [actionTypes.TABLE_ADD_COLUMN]: addColumnReducer,

    [actionTypes.TABLE_CELL_WIDTH_CHANGED_FORWARDED]: setCellWidthReducer,
    [actionTypes.TABLE_CELL_HEIGHT_CHANGED_FORWARDED]: setCellHeightReducer,

    [actionTypes.TABLE_CELL_GLOBAL_STYLE_CHANGED]: makeCellsSinglePropUpdater({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        makeCellPropUpdater: (action) => updateTableCellStyle((cell) => ({ // eslint-disable-line no-unused-vars
            block: defaultState.cells[0].style.block,
            globalId: action.payload.stylesheetId,
            globalName: action.payload.stylesheetName
        }))
    }),
    [actionTypes.TABLE_CELL_CLEAR_CONTENT]: clearContentReducer,
    [actionTypes.TABLE_CELL_VERTICAL_ALIGNMENT_CHANGED]: makeCellsSinglePropUpdater({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        makeCellPropUpdater: (action) => updateTableCellStyle((cell) => ({ verticalAlign: action.payload })) // eslint-disable-line no-unused-vars
    }),

    [actionTypes.TABLE_TEXT_COLOR_CHANGED_AUTO_COLOR]: makeCellTextStylePropToThemeColorUpdater('themeColor'),
    [actionTypes.TABLE_TEXT_HIGHLIGHT_COLOR_REMOVED]: removeCellTextStylePropFromThemeColorUpdater('themeHighlightColor'),
    [actionTypes.TABLE_TEXT_HIGHLIGHT_COLOR_CHANGED_AUTO_COLOR]: makeCellTextStylePropToThemeColorUpdater('themeHighlightColor'),
    [actionTypes.TABLE_TEXT_SHADOW_COLOR_REMOVED]: removeCellTextStylePropFromThemeColorUpdater('themeShadowColor'),
    [actionTypes.TABLE_TEXT_SHADOW_COLOR_CHANGED_AUTO_COLOR]: makeCellTextStylePropToThemeColorUpdater('themeShadowColor'),
    [actionTypes.TABLE_TEXT_SHADOW_BLUR_RADIUS_CHANGED_AUTO_COLOR]: makeCellTextStylePropUpdater('themeShadowBlurRadius'),
    [actionTypes.TABLE_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED_AUTO_COLOR]: makeCellTextStylePropUpdater('themeShadowOffsetX'),
    [actionTypes.TABLE_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED_AUTO_COLOR]: makeCellTextStylePropUpdater('themeShadowOffsetY'),
    [actionTypes.TABLE_CELL_BORDER_COLOR_CHANGED_AUTO_COLOR]:
        makeCellsBorderReducer(action => ({ type: 'solidThemeColor', value: action.payload.themeColor })),
    [actionTypes.TABLE_CELL_BORDER_COLOR_REMOVED_AUTO_COLOR]:
        makeCellsBorderReducer(() => ({ type: 'solidThemeColor', value: null })),
    [actionTypes.TABLE_CELL_BACKGROUND_COLOR_CHANGED_AUTO_COLOR]:
        makeCellsBackgroundReducer(action => ({ type: 'solidThemeColor', value: action.payload.themeColor })),
    [actionTypes.TABLE_CELL_BACKGROUND_COLOR_UNSET_AUTO_COLOR]:
        makeCellsBackgroundReducer(() => ({ type: 'solidThemeColor', value: null })),
    [actionTypes.TABLE_CELL_BACKGROUND_GRADIENT_CHANGED_AUTO_COLOR]:
        makeCellsBackgroundReducer(action => ({ type: 'gradientThemeColor', value: action.payload.themeColor })),
    [actionTypes.TABLE_CELL_BACKGROUND_GRADIENT_UNSET_AUTO_COLOR]:
        makeCellsBackgroundReducer(() => ({ type: 'gradientThemeColor', value: null })),
    [actionTypes.TABLE_CELL_BACKGROUND_OPACITY_CHANGED_AUTO_COLOR]:
        makeCellsBackgroundReducer((action) => ({ type: 'opacity', value: action.payload.opacity })),

    [actionTypes.TABLE_CELL_BACKGROUND_COLOR_CHANGED]:
        makeCellsBackgroundReducer(action => ({ type: 'solidColor', value: action.payload.color })),
    [actionTypes.TABLE_CELL_BACKGROUND_COLOR_REMOVED]:
        makeCellsBackgroundReducer(() => ({ type: 'solidColor', value: null })),
    [actionTypes.TABLE_CELL_BACKGROUND_OPACITY_CHANGED]:
        makeCellsBackgroundReducer(action => ({ type: 'opacity', value: action.payload })),
    [actionTypes.TABLE_CELL_BACKGROUND_GRADIENT_CHANGED]:
        makeCellsBackgroundReducer(action => ({ type: 'gradientColor', value: action.payload.color })),
    [actionTypes.TABLE_CELL_BACKGROUND_GRADIENT_REMOVED]:
        makeCellsBackgroundReducer(() => ({ type: 'gradientColor', value: null })),
    [actionTypes.TABLE_CELL_BACKGROUND_GRADIENT_DIRECTION_CHANGED]:
        makeCellsBackgroundReducer(action => ({ type: 'gradientDirection', value: action.payload })),
    [actionTypes.TABLE_CELL_BACKGROUND_GRADIENT_FADE_POINT_CHANGED]:
        makeCellsBackgroundReducer(action => ({ type: 'gradientFadePoint', value: action.payload })),

    [actionTypes.TABLE_CELL_BORDER_STYLE_CHANGED]:
        makeCellsBorderReducer(action => ({ type: 'style', value: action.payload })),
    [actionTypes.TABLE_CELL_BORDER_COLOR_CHANGED]:
        makeCellsBorderReducer(action => ({ type: 'solidColor', value: action.payload.color })),
    [actionTypes.TABLE_CELL_BORDER_COLOR_REMOVED]:
        makeCellsBorderReducer(() => ({ type: 'solidColor', value: null })),
    [actionTypes.TABLE_CELL_BORDER_OPACITY_CHANGED]:
        makeCellsBorderReducer(action => ({ type: 'opacity', value: action.payload })),
    [actionTypes.TABLE_CELL_BORDER_WIDTH_CHANGED]:
        makeCellsBorderReducer(action => ({ type: 'widths', value: action.payload })),

    [TINY_MCE_CHANGE_FOR_MULTIPLE_INSTANCES_APPLIED]: (state, action, dependencies) => {
        return R.evolve({
            cells: mapIndexed((cell, index) => {
                const
                    allCellsAreSelected = dependencies.tableEditModeState.selectedCellsIndexes.length === 0,
                    indexInSelection = dependencies.tableEditModeState.selectedCellsIndexes.indexOf(index);

                if (!allCellsAreSelected && indexInSelection === -1) {
                    return cell;
                }

                const contentIndex = allCellsAreSelected ? index : indexInSelection;

                if (contentIndex !== -1) {
                    const content = action.payload.updatedContentsArr[contentIndex];
                    return R.pipe(
                        setContent(content)
                    )(cell);
                }

                return cell;
            })
        }, state);
    },
    [actionTypes.TABLE_TEXT_CLEAR_FORMATTING]: unsetCellTextStylesReducer,

    [actionTypes.TABLE_CELL_BACKGROUND_ASSET_CHANGED]: makeCellsSinglePropUpdater({
        makeCellPropUpdater: ({ payload: { asset } }) => updateTableCellStyle(
            cell => getCellBackgroundUpdatedAsset(cell, { asset })
        )
    }),
    [actionTypes.TABLE_CELL_BACKGROND_ASSET_REMOVED]: makeCellsSinglePropUpdater({
        makeCellPropUpdater: () => updateTableCellStyle(
            cell => getCellBackgroundUpdatedAsset(cell, { asset: null })
        )
    }),
    [actionTypes.TABLE_CELL_BACKGROUND_ASSET_REPEAT_CHANGED]: makeCellsSinglePropUpdater({
        makeCellPropUpdater: ({ payload: repeat }) => updateTableCellStyle(
            cell => getCellBackgroundUpdatedAsset(cell, { repeat: ReverseBackgroundRepeat[repeat] })
        )
    }),
    [actionTypes.TABLE_CELL_BACKGROUND_ASSET_POSITION_CHANGED]: makeCellsSinglePropUpdater({
        makeCellPropUpdater: ({ payload: position }) => updateTableCellStyle(
            cell => getCellBackgroundUpdatedAsset(cell, { position })
        )
    }),
    [actionTypes.TABLE_CELL_BACKGROUND_ASSET_SCALE_CHANGED]: makeCellsSinglePropUpdater({
        makeCellPropUpdater: ({ payload: size }) => updateTableCellStyle(
            cell => getCellBackgroundUpdatedAsset(cell, { size })
        )
    }),
    [actionTypes.TABLE_SET_ALL_CELLS_EMPTY]: (component) => {
        return R.evolve({
            cells: R.map(cell => R.evolve({
                content: () => ''
            }, cell))
        }, component);
    }
};

export default (state: TableComponent, action: AnyAction, dependencies?: TableComponentDependsOn) => {
    return handleActions[action.type]
        ? handleActions[action.type](state, action, dependencies)
        : reducer(state, action);
};
