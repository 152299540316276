import React from 'react';

import { useIntl } from '../../../../../../../view/intl/index';

import VerticalSpacer from "../../../../../../../view/common/VerticalSpacer";

import { ContentTypeField } from './ContentTypeField';
import { TopicField } from './TopicField';
import { ExtrasField } from './ExtrasField';
import { TermsCheckbox } from './TermsCheckbox';

import styles from "../styles.css";

export const TextGenerator = () => {
    const intl = useIntl();

    return (
        <div className={styles.settingsContainer}>
            <div className={styles.settingsBody}>
                <div
                    className={styles.headerContainer}
                >
                    {intl.msgJoint('msg: component.text.createAIText.dialog.userInputPanel.textBuilder {About the text}')}
                </div>

                <div className={styles.fieldsContainer}>
                    <TopicField />
                    <ContentTypeField />
                    <ExtrasField />
                    <TermsCheckbox />
                </div>
            </div>
            <VerticalSpacer y={8} />
        </div>
    );
};
