import { AppStore } from "../../../../redux/modules/flowTypes";
import ls from "../../../../utils/localStorage";
import styles from './styles.css';
import { WORKSPACE_READY } from "../../../Workspace/actionTypes";
import { CHECK_PUBLISH_STATUS_SUCCESS, GET_HELP_BTN_CLIENT_RECT,
    HELP_BTN_CLIENT_RECT, USER_PUBLISH_ACTION_SUCCESSFUL } from "../../actionTypes";
import { showTutorialButtonOnTopBarSelector } from "../../epics/checkPublishStatus/selectors";
import { helpBtnClientRectSelector } from "../../../QuickTour/epics/quickTour/selector";
import { SHOW_STICKY_TOOLTIP } from "../../../Tooltip/stickyTooltip/actionTypes";
import { FindVideoTip } from "../../../Tooltip/ids";
import { shouldShowTutorial } from "../TopBarItemMenu/TopBarTutorial";
import { currentLanguageAppSel } from "../../epics/languages/selectors";
import { OPEN_MOBILE_VIEW_EDITOR_CLICKED } from "../../../MobileViewEditor/actionTypes";
import { closeDialogByIdAction } from "../../../App/actionCreators";
import { TutorialVideoDialogId } from "../dialogIds";
import { BACKUP_CLICKED } from "../../../Backup/actionTypes";

const TutorialInsideHelpTooltipShown = 'TutorialInsideHelpTooltipShown';

const showTooltipAC = ({ left, width }) => {
    return {
        type: SHOW_STICKY_TOOLTIP,
        payload: {
            position: {
                x: left + (width / 2),
                y: 20
            },
            id: FindVideoTip,
            showIcon: false,
            textStyle: { textAlign: 'center' },
            customClass: styles.tooltip
        }
    };
};

const tooltipDelay = 5000;

export const videoTutorialMiddleware = (store: AppStore) => {
    let isWorkspaceReady = false;
    let publishStatusCallSuccess = false;
    let tooltipShown = false;
    let helpBtnRect = null;
    let timeoutId = null;

    const showTutorialInsideHelpTooltip = (helpBtnRect) => {
        const { left, width } = helpBtnRect;
        setTimeout(() => {
            store.dispatch(showTooltipAC({ left, width }));
        }, tooltipDelay);
        ls.set(TutorialInsideHelpTooltipShown, 'yes', true);
    };

    const attemptToShowTutorialInsideHelpTooltip = () => {
        const appState = store.getState();
        const isTutorialButtonOnTopBar = showTutorialButtonOnTopBarSelector(appState);

        helpBtnRect = helpBtnClientRectSelector(appState) || helpBtnRect;
        if (!helpBtnRect) {
            store.dispatch({ type: GET_HELP_BTN_CLIENT_RECT });
            return;
        }
        // eslint-disable-next-line max-len
        if (isWorkspaceReady && publishStatusCallSuccess && !isTutorialButtonOnTopBar && shouldShowTutorial(currentLanguageAppSel(appState))) {
            const shown = ls.get(TutorialInsideHelpTooltipShown, true) === 'yes';
            if (!shown && !tooltipShown) {
                tooltipShown = true;
                showTutorialInsideHelpTooltip(helpBtnRect);
            }
        }
    };
    return (next: Dispatch) => (action: AnyAction) => {
        switch (action.type) {
            case WORKSPACE_READY:
                isWorkspaceReady = true;
                attemptToShowTutorialInsideHelpTooltip();
                break;
            case USER_PUBLISH_ACTION_SUCCESSFUL:
            case CHECK_PUBLISH_STATUS_SUCCESS:
                publishStatusCallSuccess = true;
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
                // wait until the state is updated
                setTimeout(attemptToShowTutorialInsideHelpTooltip, 100);
                break;
            case HELP_BTN_CLIENT_RECT:
                helpBtnRect = action.payload;
                attemptToShowTutorialInsideHelpTooltip();
                break;
            case OPEN_MOBILE_VIEW_EDITOR_CLICKED:
            case BACKUP_CLICKED:
                store.dispatch(closeDialogByIdAction(TutorialVideoDialogId));
                break;
            default:
                break;
        }

        return next(action);
    };
};

