/* eslint-disable max-len */

import React from 'react';
import { Msg } from '../../../../view/intl/index';
import getCenteredDialogConfig from '../../../DialogManager/getCenteredDialogConfig';
import { closeDialog } from '../../../App/actionCreators/index';
import WarningDialog from "../../../../view/common/dialogs/WarningDialog/index";

type Props = {
    dispatch: Dispatch;
};

const
    InstagramDisabledModalId = 'InstagramDisabledModalId',
    width = 490,
    height = 350,
    InstagramDisabledModal = ({ dispatch }: Props) => {
        return (
            <WarningDialog
                title="msg: imageEditor.common.error {Error}"
                mctaText="msg: common.ok {OK}"
                mctaHandler={() => dispatch(closeDialog())}
            >
                <Msg k="instagram.accountDisableMsg.instagramFeed">
                    {`We’re sorry, but this feature is currently not available. We're working on resolving our connection with Instagram. In the meantime, delete this component in order to publish your website. Please come back later and try again. We apologise for the inconvenience.`}
                </Msg>
            </WarningDialog>
        );
    },
    dialog = getCenteredDialogConfig(width, height, InstagramDisabledModal);

export { InstagramDisabledModalId, dialog };
