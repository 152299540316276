import { DEFAULT_WIDTH, TRACK_DEFAULT_HEIGHT, PLAYLIST_DEFAULT_HEIGHT, Keyword, PostType } from '../constants';
import { isSoundcloudLinkValid } from './linkValidator';
import { type SoundcloudComponent } from '../types';
import { parseSoundcloudHTMLCode } from './parseSoundcloudHTMLCode';

export const getSoundcloudPostType = (code: string): string => {
    const url = code.split('?')[0];
    if (url.includes(Keyword.SETS) || url.includes(Keyword.PLAYLISTS)) {
        return PostType.PLAYLIST;
    }
    return PostType.TRACK;
};

export const parseSoundcloudCode = (code: string): SoundcloudComponent => {
    let params = <SoundcloudComponent>{};
    if (code.length) {
        if (isSoundcloudLinkValid(code)) {
            params.link = code.replace(/\/$/, '');
            params.color = 'ff5500';
            params.autoplay = false;
        } else {
            params = parseSoundcloudHTMLCode(code);
        }
        params.height = getSoundcloudPostType(params.link || '') === PostType.PLAYLIST ? PLAYLIST_DEFAULT_HEIGHT : TRACK_DEFAULT_HEIGHT;
    }
    return params.link ? { width: DEFAULT_WIDTH, ...params, } : {};
};
