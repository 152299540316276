import React from 'react';
import { PopupCom } from "@sepo27/react-redux-lib";
// $FlowFixMe: TODO
import "@sepo27/react-redux-lib/lib/popup/Popup.css";
import styles from './HoverBoxModalCom.css';
import LazyImage from '../../../view/common/LazyImage/index';
import SecondaryButton from '../../../view/common/Button/SecondaryButton';
import PrimaryButton from '../../../view/common/Button/PrimaryButton';

export type HoverBoxModalProps = {
    show: boolean,
    title: string,
    subTitle: string,
    previewWidth: number,
    previewHeight: number,
    src: string,
    isSelected: boolean,
    onSelectClick: React.MouseEventHandler,
    onBackClick: () => void,
};

export class HoverBoxModalCom extends React.Component<HoverBoxModalProps> {
    constructor() {
        // @ts-ignore
        super();
        this.onClick = this.onClick.bind(this);
    }

    onClick(e: React.MouseEvent<any>) { // eslint-disable-line class-methods-use-this
        e.stopPropagation();
    }

    render() {
        const {
            show,
            title,
            subTitle,
            previewWidth,
            previewHeight,
            src,
            isSelected,
            onSelectClick,
            onBackClick
        } = this.props;

        return (
            <PopupCom
                show={show}
                top={0}
                left={0}
                theme={{
                    container: styles.container
                }}
                onClick={this.onClick}
            >
                <section>
                    <header>
                        <h1>{title}</h1>
                        <h2>{subTitle}</h2>
                    </header>
                    <article
                        style={{
                            width: previewWidth,
                            height: previewHeight
                        }}
                    >
                        <LazyImage src={src} />
                    </article>
                    <footer>
                        <SecondaryButton
                            isIntl
                            className={styles.backBtn}
                            onClick={onBackClick}
                        >{'msg: hoverBoxModal.back {Back}'}</SecondaryButton>

                        <PrimaryButton
                            isIntl
                            disabled={isSelected}
                            onClick={onSelectClick}
                        >{'msg: hoverBoxModal.select {Select}'}</PrimaryButton>
                    </footer>
                </section>
                <div className={styles.overlay} onClick={onBackClick} />
            </PopupCom>
        );
    }
}
