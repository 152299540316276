import { parseIntDec } from '../../../../../utils/number';
import {
    LinkTypes,
    ComponentHeightsByType,
    DefaultTheme,
    AltTheme,
    DEFAULT_WIDTH,
    DEFAULT_HEIGHT
} from './constants';
import { getMatch, parseIframeCode, regexpsValidator } from '../utils';
import { type IframeParserOutput, type LinkValidator } from '../types';
import { type SpotifyComponent, type LinkParams } from './types';

const
    BaseURL = "https://open.spotify.com",
    BaseURLRegex = new RegExp("^" + BaseURL + "(\/embed)?"),
    EmbedURL = `${BaseURL}/embed`;
const AltThemeQP = "theme=0"; export const
    isSpotifyLinkValid: LinkValidator = regexpsValidator([
        new RegExp(
            '^(?:https://)?open.spotify.com/(?:embed/)?(' +
            Object.keys(LinkTypes).join('|') +
            ')/[\\w\\d]+'
        )
    ]);
export const getLink = (link: string, { theme, startTime }: LinkParams): string => {
    const params: Array<string> = [];

    if (theme === AltTheme) params.push(AltThemeQP);
    if (startTime > 0) params.push(`t=${startTime}`);

    return link.replace(BaseURLRegex, EmbedURL) + (params.length ? `?${params.join('&')}` : '');
};

export const parseLinkType = (link: string): string => {
    const match = link.match(/^(?:https?:\/\/)?open\.spotify\.com\/(?:embed\/)?([^\/]+)\//);
    return Array.isArray(match) ? match[1] : LinkTypes.playlist;
};

export const parseSpotifyCode = (code): SpotifyComponent => {
    const params = <SpotifyComponent>{};
    const str = code.trim();

    if (isSpotifyLinkValid(str)) {
        params.link = str.split("?")[0];
    } else {
        const { src, width, height }: IframeParserOutput = parseIframeCode(str, { width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT });
        if (src) {
            const [link, query] = src.split('?');

            if (isSpotifyLinkValid(link)) {
                params.link = link.replace(EmbedURL, BaseURL);
                params.width = width;
                params.height = height;
                params.theme = query.includes(AltThemeQP) ? AltTheme : DefaultTheme;

                // @ts-ignore
                const startTime = getMatch(query, { regex: /t=(\d+)/, postMatch: parseIntDec }) as number;
                if (startTime) params.startTime = startTime;
            }
        }
    }

    if (params.link) {
        params.theme = params.theme || DefaultTheme;
        params.width = params.width || DEFAULT_WIDTH;
        params.height = params.height || ComponentHeightsByType[parseLinkType(params.link)] || DEFAULT_HEIGHT;
    }

    return params;
};
