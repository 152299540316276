
import {
    makeCombineReducer,
    makeDefaultStateReducers,
    makeComponentBaseReducers
} from "../../../../redux/makeReducer/index";
import * as actions from "./actionTypes";
import { WidgetsBookingsKind } from "./kind";
import { defaultState, DEFAULT_HEIGHT, DEFAULT_WIDTH } from "./constants";

const reducer = makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(WidgetsBookingsKind, DEFAULT_WIDTH, DEFAULT_HEIGHT),
        ...makeDefaultStateReducers(defaultState)
    },
    handleActions: {
        [actions.WIDGETS_BOOKINGS_UPDATE_PROPS]: (component, { payload: params }) => ({
            ...component, ...params
        })
    }
});

export { reducer };

