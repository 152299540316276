import React from "react";
import cx from "classnames";

import {
    isPageRef,
    isPageStateHidden,
    isSectionLink,
    isPageStateLocked,
    DataSiteItem
} from "../../../../../dal/model/utils/dataSiteItemUtils";
import { createPageIdIndexMap, createSectionLinksIdIndexMap } from "../../../App/epics/siteData/utils/pages";
import { getPageLimitBySubscriptionType, getSectionLinksLimitBySubscriptionType } from "../../../App/epics/subscriptionData/utils";
import LongTextTip from "../../../../view/common/LongTextTip";
import { replaceTagsWithContent } from "../../../oneweb/Text/view/replaceTagsWithContent";
import { RenderPremiumIcon } from "./RenderPremiumIcon";
import { DisabledNodeOverlay } from "./DisabledNodeOverlay";
import { PageSettings } from "./PageSettings";
import { DataSite } from "../../../../utils/htmlWriter/flowTypes";
import { PageStates } from "./PageStates";
import { PageTypeIcon } from "./PageTypeIcon";

import styles from './PagesTree.css';

type Props = {
    site: DataSite,
    page: DataSiteItem,
    subscriptionType: string,
    showPageSettings?: boolean,
    globalVariables: Record<string, any>,
    disabled: boolean
}

export const TitleContents = (props: Props) => {
    const {
        site,
        page,
        subscriptionType,
        showPageSettings,
        globalVariables,
        disabled
    } = props;

    const
        pageLimit = getPageLimitBySubscriptionType(subscriptionType),
        sectionLinksLimit = getSectionLinksLimitBySubscriptionType(subscriptionType),
        pageIndexMap = createPageIdIndexMap(site),
        sectionLinksIndexMap = createSectionLinksIdIndexMap(site);

    const isNodePositionExceedsLimit = () => {
        let limit, nodePosition;
        if (isPageRef(page)) {
            limit = pageLimit;
            nodePosition = pageIndexMap ? pageIndexMap[page.id] : -1;
        } else if (isSectionLink(page)) {
            limit = sectionLinksLimit;
            nodePosition = sectionLinksIndexMap ? sectionLinksIndexMap[page.id] : -1;
        }

        return limit && nodePosition > -1 && nodePosition >= limit;
    };

    const showPremiumIcon = isNodePositionExceedsLimit();

    return (
        <span className={styles.pageTitle}>
            <span className={cx(styles.pageNameContainer,
                {
                    [styles.twoStatesVisible]: isPageStateHidden(page) && isPageStateLocked(page),
                    [styles.oneStatesVisible]: isPageStateHidden(page) || isPageStateLocked(page),
                    [styles.premiumIcnVisible]: !!showPremiumIcon
                })}
            >
                <PageTypeIcon page={page} site={site} />
                <LongTextTip className={styles.pageName}>
                    {
                        replaceTagsWithContent(page.name, { site, globalVariables })
                    }
                </LongTextTip>
            </span>
            <PageStates page={page} />
            {showPageSettings ? <PageSettings page={page} /> : null}
            {disabled ? <DisabledNodeOverlay /> : null}
            {showPremiumIcon ? <RenderPremiumIcon /> : null}
        </span>
    );
};
