import React from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from "@mui/material";

import { useIntl } from "../../../../../../wbtgen/src/view/intl/injectIntl";

import { bannerCloseButtonClickedAction } from "../actions";

import imageStyles from "../../Images/imageStyle.css";

const useStyles = makeStyles(
    {
        bannerTitle: {
            fontSize: "18px",
            lineHeight: "26px",
        },
        bannerDescription: {
            marginTop: "8px",
            fontSize: "14px",
            lineHeight: "20px",
        },
    },
    { name: "BannerBox" }
);

type BannerBoxProps = {
    id: string;
    title: string;
    description: string;
    persistent?: boolean;
};

export const BannerBox = ({ id, title, description, persistent }: BannerBoxProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();

    const closeButton = persistent ? null : (
        <Box
            className={imageStyles.closeIcon}
            position="absolute"
            top="22px"
            right="22px"
            width={{ xs: 14, sm: 16 }}
            height={{ xs: 14, sm: 16 }}
            style={{ cursor: 'pointer' }}
            onClick={() => dispatch(bannerCloseButtonClickedAction(id))}
        />
    );

    return (
        <Box
            maxWidth="80%"
            bgcolor={theme.palette.custom.colorEtherealWhite}
            padding="32px 28px 28px 28px"
            position="relative"
            marginX="auto"
            style={{ userSelect: 'none' }}
        >
            {closeButton}
            <Box>
                <Typography component="h3" className={classes.bannerTitle}>
                    {intl.msgJoint(title)}
                </Typography>
                <Typography component="p" className={classes.bannerDescription}>
                    {intl.msgJoint(description)}
                </Typography>
            </Box>
        </Box>
    );
};
