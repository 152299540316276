// @ts-nocheck
import getColumns from './getColumns';
import { getWrapperHtmlWithRelIn } from './getWrapperHtml';

export default (components = []) => {
    // $FlowFixMe: relIn should exist here
    components.sort((a, b) => a.relIn.top - b.relIn.top);
    const columns = getColumns(components);
    const scope = { prevWidth: 0, prevHeight: 0, height: 0 };

    return Object.keys(columns).reduce((html, alignment) => {
        const components = columns[alignment];

        if (!components.length) {
            return html;
        }

        return html + getWrapperHtmlWithRelIn(alignment, components, scope);
    }, '');
};
