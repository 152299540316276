import makePages from "../../../PropertiesPanel/view/makePages";
import addGoogleFont from '../../../PropertiesPanel/view/AddGoogleFont/index';
import * as mobileView from "../../../PropertiesPanel/view/MobileView/page";
import * as main from "./pages/main";
import * as textStyle from "./pages/textStyle";
import * as sizeAndSpacing from "./pages/sizeAndSpacing";
import * as background from "./pages/background";
import * as border from "./pages/border";

const propertiesPanelConfig = {
    pages: makePages(main, textStyle, sizeAndSpacing, background, border, mobileView, addGoogleFont),
    defaultPageId: main.id,
    reducer: () => {}
};

export default propertiesPanelConfig;
