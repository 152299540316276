import * as React from 'react';
import makeAppStandardErrorDialog from '../../../../../components/App/dialogs/makeAppStandardErrorDialog';
import { ApiErrorHandlerCommonDialog } from '../commonDialog/ApiErrorHandlerCommonDialog';

const Messages = [
    'msg: common.conflictError.message.p1 {Website Builder found a conflict while saving some of the documents.}',
    'msg: common.conflictError.message.p2 {This usually means more than one active session (tab/browser) of Website Builder are running.}', // eslint-disable-line max-len
    'msg: common.conflictError.message.p3 {Make sure to use only one session and reload the page.}',
];

const ApiErrorHandlerConflictDialog = () => (
    <ApiErrorHandlerCommonDialog
        title="msg: common.conflictError.title {Conflict while saving documents}"
        messages={Messages}
        showSupportMessage={false}
    />
);

export default makeAppStandardErrorDialog(ApiErrorHandlerConflictDialog);
