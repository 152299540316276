import { getHeaderLayoutsMap } from "./layoutsData/headerLayouts";
import { getFooterLayoutsMap } from "./layoutsData/footerLayouts";
import type { WebShopMHFEpicState } from "../oneweb/WebShopMHF/flowTypes";

const getLayoutsMap = (webshopMHFData: WebShopMHFEpicState) => ({
    header: getHeaderLayoutsMap(),
    footer: getFooterLayoutsMap(webshopMHFData)
});

export default getLayoutsMap;
