import kind from './kind';
import reducer from './reducer';
import workspace from './view/workspace';
import { ComponentNames } from "../constants";
import configurationDialogId from "./configurationDialog/configurationDialogId";
import { ErrorMsgsOnAdd } from "./errorMessagesOnAdd";
import validateComponentAdd from './validateComponentAdd';
import { socialAccountsReducer } from "../../SocialAccounts/socialAccountsReducer";

export default {
    workspace,
    kind,
    label: ComponentNames[kind],
    shortcutIconName: 'facebookChat',
    requireConfigurationOnDrop: () => true,
    configurationDialog: {
        dialogId: configurationDialogId,
        dialogProps: {
            socialAccounts: socialAccountsReducer,
        }
    },
    reducer,
    validateComponentAdd,
    errorMsgsOnAdd: ErrorMsgsOnAdd,
};
