import { pathSelector } from "../../../utils/pathSelector";
import { Lit } from "../../../lit";
import type { AppStateSel } from "../../../globalTypes";
import { generalGlobalDataValueActionType } from "./generalGlobalDataEpic/valueActionType";
import { makeEpicStateSelector } from "../../../epics/makeEpic";

export const
    generalDataStateSelector = makeEpicStateSelector(generalGlobalDataValueActionType),
    websiteTitleStateSel: AppStateSel<string> = pathSelector([
        Lit.epics,
        generalGlobalDataValueActionType,
        Lit.state,
        Lit.websiteTitle
    ]);
