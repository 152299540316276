import * as R from 'ramda';
import { getSelectedComponentIdsAlongWithWrappedIds } from "./selectors";
import type { ComponentEvalAdjustAfterUpdate } from './flowTypes';
import { isTransient } from './userInteractionMutations/interactionModes';
import { getAllAttachmentsForCmpIds } from '../componentAttachements/util';

const
    HorizontalComponentsBoundariesSide = 10000,
    adjustComponentsBoundariesAfterUpdate: ComponentEvalAdjustAfterUpdate = props => {
        const
            {
                nextState,
                nextState: {
                    state: { componentsMap },
                    scope: { templateWidth, userInteraction: { mode }, attachments }
                }
            } = props;
        if (!isTransient(mode)) {
            return { state: nextState };
        }

        const
            leftBoundary = -HorizontalComponentsBoundariesSide,
            rightBoundary = HorizontalComponentsBoundariesSide + templateWidth,
            selectedComponentsIds = getSelectedComponentIdsAlongWithWrappedIds(nextState),
            componentsMapAdjustments = selectedComponentsIds.reduce((componentsMapAdjustments, componentId) => {
                const { top, left, width } = componentsMap[componentId];

                let nextComponentsMapAdjustments = componentsMapAdjustments;
                const
                    // $FlowFixMe
                    existingComponentAdjustment = nextComponentsMapAdjustments[componentId],
                    thereIsNoHigherTopAdjustment = (
                        !existingComponentAdjustment
                        || !existingComponentAdjustment.top
                        // $FlowFixMe
                        || existingComponentAdjustment.top(top) < 0
                    );

                if (top < 0 && thereIsNoHigherTopAdjustment) {
                    const adjustment = R.subtract(R.__, top),
                        attachedCmpIds =
                            getAllAttachmentsForCmpIds(attachments || {}, [componentId], selectedComponentsIds);
                    nextComponentsMapAdjustments = [...selectedComponentsIds, ...attachedCmpIds]
                        // $FlowFixMe
                        .reduce(
                            (acc, componentId): Object => R.assocPath([componentId, 'top'], adjustment, acc),
                            nextComponentsMapAdjustments
                        );
                }
                if (left < leftBoundary) {
                    nextComponentsMapAdjustments = R.assocPath(
                        [componentId, 'left'],
                        () => leftBoundary,
                        nextComponentsMapAdjustments
                    );
                    // we will not handle this situation for preserving distances between component, like top doing, due to -10000px left is not a real case
                }
                if (left + width > rightBoundary) {
                    nextComponentsMapAdjustments = R.assocPath(
                        [componentId, 'left'],
                        () => rightBoundary - width,
                        nextComponentsMapAdjustments
                    );
                    // we will not handle this situation for preserving distances between component, like top doing, due to 10000px right is not a real case
                }

                return nextComponentsMapAdjustments;
            }, {});

        if (Object.keys(componentsMapAdjustments).length === 0) {
            return { state: nextState };
        } else {
            return { state: R.evolve({ state: { componentsMap: componentsMapAdjustments } }, nextState) };
        }
    };

export {
    HorizontalComponentsBoundariesSide,
    adjustComponentsBoundariesAfterUpdate as default
};
