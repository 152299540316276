/* eslint-disable max-len */

import React from "react";
import { Msg } from "../../../view/intl/index";
import WarningDialog from "../../../view/common/dialogs/WarningDialog/index";
import getCenteredDialogConfig from "../../DialogManager/getCenteredDialogConfig";
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import * as styles from "./DetectTableWrap.css";

type DocInfo = {
    id: string,
    name: string
}

type Doc = DocInfo & {
    template ?: DocInfo
}

type DocsProps = {
    docs: Array<Doc>
}

const renderPageName = (doc: Doc) => (<span className={styles.pageName}>{doc.name}</span>),
    renderTemplateName = (doc: Doc) => {
        if (doc.template) {
            return (<span> (<Msg k="contextmenu.item.editTemplate.title">Edit template</Msg>)</span>);
        }
        return null;
    };

const WrapInTableDialog = ({ docs }: DocsProps) => (
    <WarningDialog
        title="msg: tableWrap.dialog.title {Sorry, some pages look wrong}"
        mctaText="msg: common.ok {OK}"
    >
        <div className={styles.messageBox}>
            <Msg className={styles.message} k="tableWrap.dialog.message.sorry">
                Some of your pages have a table with other components inside it.
            </Msg>
            <VerticalSpacer />
            <Msg className={styles.message} k="tableWrap.dialog.message.noSupport">
                Website Builder no longer supports images and other components inside table cells. Please check the following pages where you have used this feature:
            </Msg>
            <VerticalSpacer />
            <ul className={styles.message}>
                {docs.map(doc => (
                    <li key={doc.id}>
                        {renderPageName(doc)}
                        {renderTemplateName(doc)}
                    </li>
                ))}
            </ul>
            <VerticalSpacer />
            <Msg className={styles.message} k="tableWrap.dialog.message.recommend">
                We recommend that you use the Box or the Gallery component instead. Please contact our support if you need help.
            </Msg>
        </div>
    </WarningDialog>
);

export default getCenteredDialogConfig(500, 452, WrapInTableDialog);
