import * as R from 'ramda';
import type { AnyComponent, BBox } from "../../redux/modules/children/workspace/flowTypes";
import makeBBox from './makeBBoxMemoized';
import isStretchComponentKind from '../../components/oneweb/isStretchComponentKind';

const
    maximumCoordinateValue = 100000000,
    minimumCoordinateValue = -maximumCoordinateValue,
    initialLeft = maximumCoordinateValue,
    initialRight = minimumCoordinateValue,
    calculateComponentsBBox =
        (withStretchLeftAndRight: boolean) =>
            (components: Array<AnyComponent>, workspaceBBox?: BBox): BBox => {
                const updatedCmps = R.reject(R.isNil)(components);
                if (updatedCmps.length === 0) {
                    return makeBBox(0, 0, 0, 0);
                }

                const componentsBBox = updatedCmps
                    .reduce((prev, component) => {
                        const
                            top = component.top,
                            bottom = top + component.height,
                            isStretch = isStretchComponentKind(component.kind, component.stretch);
                        let
                            left = component.left,
                            right = left + component.width;

                        if (isStretch) {
                            left = withStretchLeftAndRight ? workspaceBBox?.left : initialLeft;
                            right = withStretchLeftAndRight ? workspaceBBox?.right : initialRight;
                        }

                        return {
                            top: Math.min(prev.top, top),
                            left: Math.min(prev.left, left),
                            bottom: Math.max(prev.bottom, bottom),
                            right: Math.max(prev.right, right)
                        };
                    }, {
                        top: maximumCoordinateValue,
                        left: initialLeft,
                        bottom: minimumCoordinateValue,
                        right: initialRight
                    });

                if (componentsBBox.left === initialLeft) {
                    componentsBBox.left = workspaceBBox?.left;
                }

                if (componentsBBox.right === initialRight) {
                    componentsBBox.right = workspaceBBox?.right;
                }
                return componentsBBox;
            },
    getComponentsBBoxWithStretch = calculateComponentsBBox(true),
    getComponentsBBoxWithoutStretch = calculateComponentsBBox(false),
    getComponentsBBox =
        (components: Array<AnyComponent>, workspaceBBox?: BBox): BBox => (
            getComponentsBBoxWithStretch(components, workspaceBBox)
        ),
    getComponentsBBoxWithoutStretchLeftAndRight =
        (components: Array<AnyComponent>, workspaceBBox: BBox): BBox => (
            getComponentsBBoxWithoutStretch(components, workspaceBBox)
        );

export {
    getComponentsBBox as default,
    getComponentsBBox,
    getComponentsBBoxWithoutStretchLeftAndRight
};
