import React from 'react';
import { ShortCut } from "./DroppableCmpShortcut";
import { Intl } from "../../../view/intl/injectIntl";
import styles from "./Inserter.css";
import iconStyles from '../configs/Icons.css';
import { openGlobalStylesDialogAC } from "../../presentational/GlobalstyleDialogInvoker/index";
import { openSiteSettingsDialog } from "../../SiteSettings/SiteSettingsDialog/actionCreators";
import type { SiteSettingsDialogConfigWithInput } from '../../SiteSettings/SiteSettingsDialog/actionCreators';

type IconTextCmpProps = {
    iconClassName: string,
    text: string,
    intl: Intl,
    onClick?: (arg: any) => any,
    children?: any
};

type EditGlobalStyleProps = {
    styleSheetId?: string;
    globalStyleKind: string,
    intl: Intl,
    dispatch: Dispatch,
    children?: any
};

type OpenSiteSettingsProps = {
    text: string,
    intl: Intl,
    dispatch: Dispatch,
    fieldFocus?: number,
    children?: any
};

const IconTextCmp = ({ iconClassName, text, intl, onClick, children }: IconTextCmpProps) => (
    <div className={styles.editExtendedPanelContentContainer}>
        <div className={styles.editExtendedPanelContentWrapper} onClick={onClick}>
            <ShortCut
                iconClassName={iconClassName}
                text={text}
                intl={intl}
            />
        </div>
        {children}
    </div>
);

export const OpenSiteSettings = ({ text, intl, dispatch, fieldFocus, children }: OpenSiteSettingsProps) => (
    <IconTextCmp
        text={text}
        intl={intl}
        iconClassName={iconStyles.edit}
        onClick={() => dispatch(openSiteSettingsDialog(({ activateInputWithId: fieldFocus } as SiteSettingsDialogConfigWithInput)))}
    >{children}</IconTextCmp>
);

export const EditGlobalStyle = ({ intl, globalStyleKind, dispatch, styleSheetId = '', children }: EditGlobalStyleProps) => {
    const editStyleText = 'msg: extendedPanel.editStyles {Edit styles}';
    return (
        <IconTextCmp
            text={editStyleText}
            intl={intl}
            iconClassName={iconStyles.edit}
            onClick={() => dispatch(openGlobalStylesDialogAC(styleSheetId, globalStyleKind))}
        >{children}</IconTextCmp>
    );
};
