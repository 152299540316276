import view from "./view/index";
import kind from "./kind";
import type { CalcProps, CmpSpecificStyles } from "../../Preview/flowTypes";
import { getGroupCmpPositionStylesForMobile } from "../getGroupCmpPositionStylesForMobile";
import type { TextComponent } from "./flowTypes";
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";

export default {
    kind,
    view,
    calcProps: ({
        component,
        selectedParentTheme,
        siteSettings: { themeSettingsData },
        globalStyles
    }: CalcProps<TextComponent>) => {
        const { autoColorMode } = themeSettingsData;
        return {
            themeSettingsData,
            themeColorsData: getThemeColorsFromStylesheet(globalStyles),
            selectedParentTheme: autoColorMode && selectedParentTheme,
            themeOverrideColor: component.themeOverrideColor,
            themeHighlightColor: component.themeHighlightColor,
            themeShadowBlurRadius: component.themeShadowBlurRadius,
            themeShadowColor: component.themeShadowColor,
            themeShadowOffsetX: component.themeShadowOffsetX,
            themeShadowOffsetY: component.themeShadowOffsetY,
        };
    },
    getSpecificStyles: ({ component, group }: CmpSpecificStyles<TextComponent>): string => {
        let result = `div[data-id='${component.id}'][data-specific-kind="TEXT"] { margin: auto; }`;

        const groupItemStyles = getGroupCmpPositionStylesForMobile(group, component).mobileStylesStr;
        const groupStyles = groupItemStyles ? `.mobileV div[data-id='${component.id}'] {
            ${groupItemStyles}
        }` : '';

        result += groupStyles;
        if (component.verticalAlignment && component.verticalAlignment !== 'top') {
            const verticalAlignStyles = `div[data-id='${component.id}'] { display: flex; width: 100%; }`;
            result += `\n${verticalAlignStyles}`;
        }

        return result;
    }
};
