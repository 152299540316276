import * as React from 'react';
import cx from 'classnames';
import { composeStylesTheme } from "@sepo27/react-redux-lib";
import styles from './HoverBoxCom.css';
import LazyImage from '../../../view/common/LazyImage/index';
import { HoverBoxContext } from "./HoverBoxContext";
import type { HoverBoxContextValue } from "./HoverBoxContext";
import { InlineLoadingIndicatorCom } from "../../../view/common/LoadingIndicator/InlineLoadingIndicatorCom";

// TODO: use LazyImageLoader instead of LazyImage

type Theme = {
    box?: string,
    bg?: string,
    bgLoader?: string,
    overlay?: string,
    interlay?: string,
};

type Props = {
    bgSrc: string,
    children: React.ReactNode,
    selected?: boolean,
    bgShouldLoad?: boolean,
    onHold?: boolean,
    className?: string,
    onClick?: React.MouseEventHandler,
    onDoubleClick?: React.MouseEventHandler,
    theme?: Theme,
    dataTid?: string,
    interlay?: React.ReactNode,
};

type State = {
    over: boolean,
};

export class HoverBoxCom extends React.Component<Props, State> {
    static defaultProps = {
        selected: false,
        bgShouldLoad: true,
        onHold: false,
        theme: {},
    };

    theme: Theme;

    contextValue: HoverBoxContextValue;

    constructor({ theme }: Props) {
        // @ts-ignore
        super();

        this.state = { over: false };

        this.contextValue = {
            onPreviewOver: this.onPreviewOver.bind(this),
            onPreviewOut: this.onPreviewOut.bind(this)
        };

        this.theme = composeStylesTheme(styles, theme);
    }

    onPreviewOver() {
        this.setState({ over: true });
    }

    onPreviewOut() {
        this.setState({ over: false });
    }

    render() {
        const
            {
                bgSrc,
                bgShouldLoad,
                className,
                selected,
                onHold,
                onClick,
                onDoubleClick,
                children,
                dataTid,
                interlay,
            } = this.props,
            { over } = this.state,
            boxClassName = cx(
                this.theme.box,
                className,
                { [styles.selected]: selected, [styles.over]: over }
            );

        return (
            <div className={boxClassName} onClick={onClick} onDoubleClick={onDoubleClick} data-tid={dataTid}>
                <section className={styles.content}>
                    <HoverBoxContext.Provider value={this.contextValue}>
                        {children}
                    </HoverBoxContext.Provider>
                </section>
                <LazyImage
                    src={bgSrc}
                    shouldLoad={bgShouldLoad}
                    className={this.theme.bg}
                    defaultProxyClassName={this.theme.bgLoader}
                />
                <div className={this.theme.overlay} />
                {interlay}
                <span className={styles.cornerSelectedIcn} />
                {onHold && <InlineLoadingIndicatorCom theme={{ inlineContainer: styles.onHoldLoader }} />}
            </div>
        );
    }
}
