import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly } from '../../../../epics/makeCondition';
import valueActionType from './valueActionType';
import workspaceBBoxValueActionType from '../workspaceBBox/valueActionType';
import {
    ReceiveOnlyComponentsMap,
    UserInteractionModeSelector,
    ReceiveOnlyUserInteractionComponentsIds,
} from "../componentsEval/selectorActionTypes";
import { getComponentBBox, zeroBBox } from "../../../../utils/componentsMap/index";
import type { ComponentAttachDecorationState, ComponentAttachDecorationScope } from './flowTypes';
import type { Epic } from '../../../../epics/flowTypes';
import { SHOW_ATTACH_COMPONENT_DECORATION } from './actionTypes';
import scrollValueActionType from "../scroll/valueActionType";
import isStretchComponentKind from '../../../oneweb/isStretchComponentKind';
import { getCmpsTreeByCmpsMap, prepareCmpsForTree } from "../relations/rBushTree";
import * as interactionModes from '../componentsEval/userInteractionMutations/interactionModes';
import {
    mousePositionWithRespectToTemplateAreaValueActionType
} from "../mousePositionWithRespectToTemplateArea/valueActionType";
import { isLeftMouseDownVAT } from "../isLeftMouseDown/valueActionType";
import { processAttachments, isAttachedToComponent, MinLeft, MaxRight, getTopMostParentId } from "../componentAttachements/util";
import type { RBushTreeCmp } from "../relations/rBushTree";
import { componentAttachmentsVAT } from "../componentAttachements/valueActionType";
import dndAddComponentVAT from "../../../DndAddComponent/epic/valueActionType";
import * as appActionTypes from "../../../App/actionTypes";
import { getCmpPositionOnDrop } from "../../../DndAddComponent/epic/util";
import {
    COMPONENT_ADD_CANCELLED,
    COMPONENT_ADD_STARTED,
    NEW_COMPONENT_DROPED_ON_WORKSPACE
} from "../../../DndAddComponent/actionTypes";
import { NEW_COMPONENT_DROPPED_IS_ADDED_TO_ATTACHMENTS } from "../componentAttachements/actionTypes";
import { isFooterSection, isHeaderOrFooterSection, isHeaderSection } from "../../../oneweb/Section/utils";
import { SectionTypes } from "../../../oneweb/Section/constants";
import { isHoverBoxKind, isSectionKind } from "../../../oneweb/componentKinds";
import { isPositionInsideTemplateZone } from "../isPageMode/utils";
import { NonTemplateComponents } from "../../../oneweb/Template/constants";
import {
    ROCodeComponentsRendererHeadHeightSelector
} from "../../CodeComponentsRenderer/epic/selectorActionTypes";
import { isComponentKindAllowedInHoverBox } from "../../../oneweb/HoverBox/utils";
import { SHOW_EASIER_WS_GUIDELINES_ON_ADD_CMP, HIDE_EASIER_WS_GUIDELINES_ON_ADD_CMP } from "../resizeDecos/actionTypes";
import { isModernHeaderOrFooter } from "../../../ModernLayouts/utils";

export const
    defaultState: ComponentAttachDecorationState = {
        isVisible: false,
        cmpId: null,
        mainCmp: {
            workspaceBBox: zeroBBox,
            componentBBox: zeroBBox,
            attachTop: 0,
            kind: '',
        },
        headerFooterCmp: null
    },
    defaultScope: ComponentAttachDecorationScope = {},
    dispatchAttachToDeco = (state: ComponentAttachDecorationState, scope: ComponentAttachDecorationScope,
        treeSnapshot?: RBushTreeCmp, cmpId?: string, headerFooterId?: string) => {
        let newState, actionToDispatch;
        if (cmpId) {
            newState = state;
            actionToDispatch = {
                type: SHOW_ATTACH_COMPONENT_DECORATION,
                payload: { cmpId, headerFooterId }
            };
        } else {
            newState = state.isVisible ? defaultState : state;
        }
        return {
            state: newState,
            scope: {
                ...scope,
                treeSnapshot,
            },
            actionToDispatch,
        };
    },
    maxCmpOrderIndex = 10000;

const getCmpProps = (cmpId, componentsMap, workspaceBBox, scroll, codeComponentHeadHeight) => {
        if (!cmpId) {
            return null;
        }
        const component = componentsMap[cmpId],
            attachBbox = { ...getComponentBBox(component, workspaceBBox) },
            attachTop = (scroll.y - codeComponentHeadHeight) > attachBbox.top ?
                (scroll.y - codeComponentHeadHeight) : attachBbox.top,
            componentBBox = {
                top: attachBbox.top,
                bottom: attachBbox.bottom,
                left: attachBbox.left,
                right: attachBbox.right,
            };
        let { kind, stretch = false } = component;
        if (isStretchComponentKind(kind, stretch)) {
            componentBBox.left = componentBBox.left - 2;
            componentBBox.right = componentBBox.right + 2;
        }
        if (isSectionKind(kind)) {
            if (isHeaderSection(component)) {
                kind = SectionTypes.HeaderSection;
            }
            if (isFooterSection(component)) {
                kind = SectionTypes.FooterSection;
            }
        }
        return {
            componentBBox,
            kind,
            attachTop,
        };
    },
    getHeaderFooterId = (cmpId, componentsMap, attachments) => {
        let headerFooterId;
        const topMostparentId = getTopMostParentId(cmpId, attachments),
            topMostParent = componentsMap[topMostparentId];
        if (topMostparentId !== cmpId && (isHeaderSection(topMostParent)
            || isFooterSection(topMostParent))) {
            headerFooterId = topMostparentId;
        }
        return headerFooterId;
    };

const epic: Epic<ComponentAttachDecorationState, void, string> = makeEpic({
    defaultState,
    defaultScope,
    valueActionType,
    updaters: [
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                receiveOnly(workspaceBBoxValueActionType),
                receiveOnly(scrollValueActionType),
                ROCodeComponentsRendererHeadHeightSelector,
                SHOW_ATTACH_COMPONENT_DECORATION
            ],
            reducer: ({
                values: [
                    componentsMap,
                    workspaceBBox,
                    scroll,
                    codeComponentHeadHeight,
                    { cmpId, headerFooterId },
                ],
                state,
                scope
            }) => {
                if (!cmpId) {
                    return { state: defaultState, scope };
                }

                return {
                    state: {
                        ...state,
                        isVisible: true,
                        cmpId,
                        mainCmp: getCmpProps(cmpId, componentsMap, workspaceBBox, scroll, codeComponentHeadHeight),
                        headerFooterCmp: getCmpProps(headerFooterId, componentsMap, workspaceBBox, scroll, codeComponentHeadHeight),
                    },
                    scope
                };
            }
        },
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                COMPONENT_ADD_STARTED
            ],
            reducer: ({ state, scope, values: [componentsMap] }) => {
                return {
                    state,
                    scope: {
                        ...scope,
                        tree: getCmpsTreeByCmpsMap(componentsMap, MinLeft, MaxRight).tree
                    }
                };
            }
        },
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                receiveOnly(appActionTypes.WINDOW_MOUSE_MOVE),
                receiveOnly(mousePositionWithRespectToTemplateAreaValueActionType),
                receiveOnly(componentAttachmentsVAT),
                dndAddComponentVAT
            ],
            reducer: ({
                state,
                scope,
                values: [componentsMap,
                    mousePosition,
                    mousePositionWithRespectToTemplateArea,
                    { attachments },
                    dndData]
            }) => {
                const { isVisible: cmpDecoVisible, isDroppable, position, dimensions, componentKind } = dndData;
                let newScope = scope;
                const hideEasierWSGuidelinesAction = {
                    type: HIDE_EASIER_WS_GUIDELINES_ON_ADD_CMP
                };
                if (cmpDecoVisible) {
                    if (isDroppable) {
                        const multipleActionsToDispatch: Array<Action> = [];
                        const cmpPosition = getCmpPositionOnDrop({
                            positionWithRespectToBrowser: position,
                            mousePosition,
                            mousePositionWithRespectToTemplateArea
                        });
                        const inTemplate = isPositionInsideTemplateZone(
                            { ...cmpPosition, y: cmpPosition.y + (dimensions.height / 2) },
                            componentsMap
                        );
                        if (cmpPosition.y > 0) {
                            if (!newScope.tree) { // COMPONENT_ADD_STARTED is not dispatched some times
                                newScope = {
                                    ...newScope,
                                    tree: getCmpsTreeByCmpsMap(componentsMap, MinLeft, MaxRight).tree
                                };
                            }
                            const cmpToAdd = {
                                id: 'NEW',
                                kind: componentKind,
                                ...dimensions,
                                top: cmpPosition.y,
                                left: cmpPosition.x,
                                orderIndex: maxCmpOrderIndex,
                                inTemplate
                            };
                            const cmpData = prepareCmpsForTree([cmpToAdd], MinLeft, MaxRight),
                                attachmentData =
                                    Object.keys(processAttachments(newScope.tree, cmpData[0], componentsMap, {}));
                            if (!attachmentData.length) {
                                return { state: defaultState, scope: newScope };
                            }
                            const cmpId = attachmentData[0],
                                component = componentsMap[cmpId],
                                headerFooterId = getHeaderFooterId(cmpId, componentsMap, attachments),
                                showDecoration = !(NonTemplateComponents[componentKind] &&
                                    (headerFooterId || isHeaderOrFooterSection(component)))
                                    && !(isHoverBoxKind(component.kind) && !isComponentKindAllowedInHoverBox(componentKind));
                            multipleActionsToDispatch.push(isSectionKind(component.kind) ? {
                                type: SHOW_EASIER_WS_GUIDELINES_ON_ADD_CMP,
                                payload: { parentContainerId: cmpId, cmpToAdd }
                            } : hideEasierWSGuidelinesAction);
                            if (showDecoration) {
                                multipleActionsToDispatch.push({
                                    type: SHOW_ATTACH_COMPONENT_DECORATION,
                                    payload: { cmpId, headerFooterId }
                                });
                                return {
                                    state,
                                    scope: newScope,
                                    multipleActionsToDispatch,
                                };
                            }
                            return { state: defaultState, scope: newScope, actionToDispatch: hideEasierWSGuidelinesAction };
                        }
                    } else {
                        return { state: defaultState, scope: newScope, actionToDispatch: hideEasierWSGuidelinesAction };
                    }
                }
                return { state, scope: newScope, actionToDispatch: hideEasierWSGuidelinesAction };
            }
        },
        {
            conditions: [NEW_COMPONENT_DROPED_ON_WORKSPACE],
            reducer: ({ state, scope }) => {
                return { state: { ...defaultState, cmpId: state.cmpId }, scope: { ...scope, tree: null } };
            }
        },
        ...[
            NEW_COMPONENT_DROPPED_IS_ADDED_TO_ATTACHMENTS,
            COMPONENT_ADD_CANCELLED
        ].map(action => ({
            conditions: [action],
            reducer: ({ scope }) => ({ state: defaultState, scope: { ...scope, tree: null } })
        })),
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                receiveOnly(componentAttachmentsVAT),
                UserInteractionModeSelector,
                ReceiveOnlyUserInteractionComponentsIds,
                isLeftMouseDownVAT,
                mousePositionWithRespectToTemplateAreaValueActionType
            ],
            reducer: ({
                values: [
                    componentsMap,
                    { attachments },
                    userInteractionMode,
                    componentsIds,
                    isLeftMouseDown,
                ],
                state,
                scope
            }) => {
                if (scope.tree) {
                    return { state, scope };
                }
                if (!isLeftMouseDown ||
                    userInteractionMode !== interactionModes.MOVING_COMPONENTS) {
                    return dispatchAttachToDeco(state, scope);
                }
                const cmpIds = componentsIds.filter(cmpId => componentsMap[cmpId] && !componentsMap[cmpId].wrap);
                if (cmpIds.length === 0 ||
                    cmpIds.length > 1) {
                    return dispatchAttachToDeco(state, scope);
                }

                let treeSnapshot = scope.treeSnapshot;
                const movingCmpId = cmpIds[0],
                    movingCmp = componentsMap[movingCmpId];

                if (!treeSnapshot) {
                    const newComponentsMap = { ...componentsMap };
                    delete newComponentsMap[movingCmpId];
                    treeSnapshot = getCmpsTreeByCmpsMap(newComponentsMap, MinLeft, MaxRight).tree;
                }

                const cmpData = prepareCmpsForTree([movingCmp], MinLeft, MaxRight)[0],
                    cmpAttachments = processAttachments(treeSnapshot, cmpData, componentsMap),
                    attachToCmpID = Object.keys(cmpAttachments)[0];
                if (attachToCmpID && !isAttachedToComponent(movingCmpId, attachToCmpID, attachments)) {
                    const headerFooterId = getHeaderFooterId(attachToCmpID, componentsMap, attachments);
                    const showAttachDecoration =
                        !(NonTemplateComponents[movingCmp.kind] &&
                        (headerFooterId || isHeaderOrFooterSection(componentsMap[attachToCmpID]))) &&
                        !isModernHeaderOrFooter(componentsMap[attachToCmpID]);

                    if (showAttachDecoration) {
                        return dispatchAttachToDeco(state, scope, treeSnapshot, attachToCmpID, headerFooterId);
                    }
                }
                return dispatchAttachToDeco(state, scope, treeSnapshot);
            }
        },
    ]
});

export {
    epic as default
};
