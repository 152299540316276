import React from 'react';
import NoMouseEventsPropagation from '../../../common/NoMouseEventsPropagation';

type Props = {
    style?: Record<string, any>,
    children?: React.JSX.Element,
    className?: string
}

export default ({ style, children, className }: Props): React.JSX.Element => {
    return (
        <div
            className={className}
            style={style}
            {...NoMouseEventsPropagation}
        >
            {children}
        </div>
    );
};
