import React from 'react';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import { dialogView as TextShadow } from '../../../../presentational/TextShadow/index';
import ColorProperty from '../../../../presentational/ColorProperty/index';
import pagesTitles from "./titles";
import * as pagesIds from "./ids";
import * as actionTypes from '../../actionTypes';
import type { Props } from '../flowTypes';
import { THEME_COLOR_OVERRIDE_COLOR_PICKER, DEFAULT_COLOR_PICKER } from '../../../../ColorPicker/constants';
import { getThemeColorsFromStylesheet } from "../../../../Workspace/epics/stylesheets/selectors";

const
    id: any = pagesIds.TEXT_EFFECTS,
    title = pagesTitles[id],
    view = ({
        dispatch,
        tinyMceState: { blurRadius, highLightColor, shadowColor, shadowOffsetX, shadowOffsetY },
        stylesheets,
        autoColor,
    }: Props) => {
        const themeColorsData = getThemeColorsFromStylesheet(stylesheets);
        return (
            <Page>
                <VerticalSpacer />
                {
                    autoColor
                        ? <ColorProperty
                            colorPickerKind={THEME_COLOR_OVERRIDE_COLOR_PICKER}
                            themeColorsData={themeColorsData}
                            themeColor={autoColor.themeHighlightColor || null}
                            label="msg: common.highlightColor {Highlight colour}"
                            onChangeAction={actionTypes.TEXT_HIGHLIGHT_COLOR_CHANGED_AUTO_COLOR}
                            onRemoveAction={actionTypes.TEXT_HIGHLIGHT_COLOR_REMOVED_AUTO_COLOR}
                            dispatch={dispatch}
                        />
                        : <ColorProperty
                            colorPickerKind={DEFAULT_COLOR_PICKER}
                            label="msg: common.highlightColor {Highlight colour}"
                            color={highLightColor}
                            onChangeAction={actionTypes.TEXT_HIGHLIGHT_COLOR_CHANGED}
                            onRemoveAction={actionTypes.TEXT_HIGHLIGHT_COLOR_REMOVED}
                            dispatch={dispatch}
                        />
                }
                <VerticalSpacer />
                {
                    autoColor
                        ? <TextShadow
                            colorPickerKind={THEME_COLOR_OVERRIDE_COLOR_PICKER}
                            themeColorsData={themeColorsData}
                            themeColor={autoColor.themeShadowColor || null}
                            shadowOffsetX={autoColor.themeShadowOffsetX}
                            shadowOffsetY={autoColor.themeShadowOffsetY}
                            blurRadius={autoColor.themeShadowBlurRadius}
                            shadowColorChangeAction={actionTypes.TEXT_SHADOW_COLOR_CHANGED_AUTO_COLOR}
                            shadowRemoveAction={actionTypes.TEXT_SHADOW_COLOR_REMOVED_AUTO_COLOR}
                            blurRadiusChangeAction={actionTypes.TEXT_COMPONENT_BLUR_RADIUS_CHANGED_AUTO_COLOR}
                            shadowOffsetXChangeAction={actionTypes.TEXT_COMPONENT_SHADOWOFFSETX_CHANGED_AUTO_COLOR}
                            shadowOffsetYChangeAction={actionTypes.TEXT_COMPONENT_SHADOWOFFSETY_CHANGED_AUTO_COLOR}
                            dispatch={dispatch}
                        />
                        : <TextShadow
                            colorPickerKind={DEFAULT_COLOR_PICKER}
                            shadowColor={shadowColor}
                            shadowOffsetX={shadowOffsetX}
                            shadowOffsetY={shadowOffsetY}
                            blurRadius={blurRadius}
                            shadowColorChangeAction={actionTypes.TEXT_SHADOW_COLOR_CHANGED}
                            shadowRemoveAction={actionTypes.TEXT_SHADOW_COLOR_REMOVED}
                            blurRadiusChangeAction={actionTypes.TEXT_COMPONENT_BLUR_RADIUS_CHANGED}
                            shadowOffsetXChangeAction={actionTypes.TEXT_COMPONENT_SHADOWOFFSETX_CHANGED}
                            shadowOffsetYChangeAction={actionTypes.TEXT_COMPONENT_SHADOWOFFSETY_CHANGED}
                            dispatch={dispatch}
                        />
                }
            </Page>
        );
    };

export { id, title, view };
