/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import * as styles from './styles.css';
import { closeDialog } from "../../../../App/actionCreators/index";
import { dispatchForwardToSelectedComponent } from "../../../../../redux/forwardTo";
import { Msg } from "../../../../../view/intl";
import { Dialog, DialogBody, DialogFooter } from '../../../../../view/common/dialogs/baseDialog/index';
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import { StripTypes } from "../../../../../view/common/dialogs/baseDialog/Dialog";
import { GoogleReviewsDialogId } from '../dialogIds';
import GoogleReviewsLayoutStep from './View/LayoutStep';
import { makeEpicStateSelector } from '../../../../../epics/makeEpic';
import GOOGLE_REVIEWS_EPIC from '../../epic/valueActionType';
import { ReviewsDataContext } from './dialogContexts';
import { SelectReviewsView } from './View/ReviewsView';
import { ADD_GOOGLE_REVIEWS_COMPONENT } from '../../epic/actionTypes';
import { UPDATE_COMPONENT_REVIEWS } from '../../actionTypes';
import { getSelectedReviews } from '../../utils';

const DialogWidth = parseInt(styles.DialogWidth, 10),
    DialogHeight = parseInt(styles.DialogHeight, 10);

const GoogleReviewsDialog = ({ dispatch, reviewsEpicState, additionalProps }) => {
    const {
        reviewsApiData,
        reviewsApiStatus,
        changeReviewsMode
    } = reviewsEpicState;
    const {
        reviewIds,
        layout
    } = additionalProps;
    const [layoutMode, setLayoutMode] = React.useState(false);
    const [selectedLayout, setSelectedLayout] = React.useState(layout);
    const [selectedReviewsIds, setSelectedReviewsIds] = React.useState(reviewIds);
    const {
        reviewsData, storeData,
    } = reviewsApiData;
    const forwardedDispatch = dispatchForwardToSelectedComponent(dispatch);
    const closeDialogAC = () => dispatch(closeDialog());
    const primaryBtnOnClick = (selectedLayout) => {
        if (layoutMode) {
            dispatch({ type: ADD_GOOGLE_REVIEWS_COMPONENT, payload: { selectedReviewsIds, layoutType: selectedLayout } });
        } else if (changeReviewsMode) {
            forwardedDispatch({
                type: UPDATE_COMPONENT_REVIEWS,
                payload: { reviews: getSelectedReviews(selectedReviewsIds, reviewsData.reviews), storeData }
            });
            closeDialogAC();
        } else {
            setLayoutMode(true);
        }
    };

    return (
        <Dialog onClose={closeDialogAC} stripType={StripTypes.NONE}>
            <DialogBody className={styles.dialogBody}>
                {layoutMode ?
                    <div>
                        <div className={styles.dialogTitle}>
                            <Msg k="component.googleReviews.pickLayout">
                                Pick a Layout
                            </Msg>
                        </div>
                        <div className={styles.dialogText}>
                            <Msg
                                k="component.googleReviews.pickLayoutMessage"
                            >
                                Choose how the reviews will be shown on your website
                            </Msg>
                        </div>
                        <div className={styles.bodyContent}>
                            <GoogleReviewsLayoutStep onClick={setSelectedLayout} selectedLayout={selectedLayout} />
                        </div>
                    </div> :
                    <div>
                        <div className={styles.dialogTitle}>
                            <Msg k="component.googleReviews.chooseReviews">
                                Choose reviews
                            </Msg>
                        </div>
                        <div className={styles.dialogText}>
                            <Msg
                                k="component.googleReviews.chooseReviewsMessage"
                                params={{ storeName: storeData.title }}
                            >
                                {`Select up to 6 reviews that you would like to display for \"{storeName}\"`}
                            </Msg>
                        </div>
                        <div className={styles.bodyContent}>
                            <ReviewsDataContext.Provider value={{ reviewsApiData, reviewsApiStatus, selectedReviewsIds }}>
                                <SelectReviewsView onClick={setSelectedReviewsIds} />
                            </ReviewsDataContext.Provider>
                        </div>
                    </div>}
            </DialogBody>
            <DialogFooter
                className={styles.footerContainer}
                footerButtonContainerClassName={styles.footerButtons}
                disabled={selectedReviewsIds.length === 0}
                mctaText={layoutMode || changeReviewsMode ? "msg: common.done {Done}" : "msg: common.continue {Continue"}
                sctaText="msg: common.cancel {Cancel}"
                mctaHandler={() => primaryBtnOnClick(selectedLayout)}
                primaryBtnClass={cx(styles.button, styles.okButton)}
                sctaBtnClass={styles.button}
                sctaHandler={closeDialogAC}
                noTopBorder
            />
        </Dialog>
    );
};

const openGoogleReviewDialogAC = {
    type: "OPEN_DIALOG",
    payload: { dialogId: GoogleReviewsDialogId }
};

const mapStateToProps = (appState) => {
    const reviewsEpicState = makeEpicStateSelector(GOOGLE_REVIEWS_EPIC)(appState);
    return {
        reviewsEpicState,
    };
};

export default getCenteredDialogConfig(DialogWidth, DialogHeight,
    connect(mapStateToProps)(GoogleReviewsDialog));

export {
    openGoogleReviewDialogAC
};
