import * as R from 'ramda';
import makeEpic, { fullEpicUndoablePath } from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import {
    COMPONENTS_MAP_INITIALIZED,
} from '../componentsEval/actionTypes';
import {
    COMPONENTS_DELETED
} from "../../actionTypes";
import * as undoManagerUpdateReasons from "../../../../epics/undoManager/updateReasons";
import {
    ReceiveOnlyTemplateWidthActionType,
    ReceiveOnlyTemplateWidthFromFullActionSelector
} from "../../../oneweb/Template/epics/template/selectorActionTypes";
import {
    ReceiveOnlyComponentsMap,
    ReceiveOnlySelectedComponentsSelectorFromFullAction, userInteractionModeSelector
} from "../componentsEval/selectorActionTypes";
import * as componentsEvalUpdateReasons from '../componentsEval/updateReasons';
import { ComponentsEvalValueActionType } from "../componentsEval/valueActionType";
import { IDLE } from "../componentsEval/userInteractionMutations/interactionModes";
import componentsMapIsUndoableChange from '../componentsEval/isUndoableChange';
import { calcRelIn } from './calcRelIn';
import {
    SEPARATE_FOOTER_BY_FOOTER_TMP_CMPS,
    REMOVE_EMPTY_GAPS,
    REMOVE_EMPTY_GAPS_IN_CONTAINERS,
    REMOVE_EMPTY_STRIPS_OVERLAPPING_WITH_HEADER,
    SUPPORT_FIX_TOPS,
    GROUP_TEXT_COMPONENTS_INTO_ONE,
    ADD_BOXES_TO_PAGE_SECTION,
    WRAP_IMG_TEXT_IN_BOX
} from "../../../TopBar/Support/actions";
import { extractRelInsMap } from "../../../Preview/expandTemplateWidthAndAdjustComponents";
import { updateComponentsRelIns } from "./updateComponentsRelIns";
import {
    MODERN_FOOTER_ACTIVATED,
    MODERN_FOOTER_DEACTIVATED, MODERN_HEADER_ACTIVATED,
    MODERN_HEADER_DEACTIVATED, MHF_COMPONENTS_TOGGLE_COMPLETED,
    ADD_REMOVE_WEBSHOP_STRIP_SUCCESS
} from "../../../ModernLayouts/actionTypes";
import { GALLERY_FULL_WIDTH } from "../../../oneweb/Gallery/actionTypes";

export const
    defaultState = {
        changes: {}
    },
    STATE_CHANGED = 'STATE_CHANGED',
    AMEND_CASE = 'AMEND_CASE',
    setLastComponentsMap = R.assoc('lastComponentsMap'),
    setPrevComponentsEval = R.assoc('prevComponentsEval');

export default makeEpic({
    defaultState,
    defaultScope: { lastComponentsMap: null },
    undo: {
        isUndoableChange: R.T,
        undoablePaths: fullEpicUndoablePath,
        amendToLatestPointCases: { AMEND_CASE: true }
    },
    valueActionType,
    updaters: [
        {
            keepFullActions: true,
            conditions: [
                ReceiveOnlySelectedComponentsSelectorFromFullAction,
                ReceiveOnlyTemplateWidthFromFullActionSelector,
                ComponentsEvalValueActionType
            ],
            reducer: ({
                values: [
                    selectedComponents,
                    templateWidth,
                    componentsEvalAction
                ],
                state,
                scope,
                sourceAction
            }: Record<string, any>) => {
                const componentsEval = componentsEvalAction.payload,
                    { state: { componentsMap } } = componentsEval,
                    userInteractionMode = userInteractionModeSelector(componentsEval);

                if (componentsMap !== scope.lastComponentsMap && userInteractionMode === IDLE) {
                    const possiblyNewState = calcRelIn(selectedComponents, componentsMap, state, templateWidth);
                    if (possiblyNewState !== state) {
                        const isComponentsMapChangeUndoable = componentsMapIsUndoableChange({
                            prevState: scope.prevComponentsEval,
                            nextState: componentsEvalAction.payload,
                            updateReason: componentsEvalAction.epicUpdateReason,
                            sourceAction
                        });
                        return {
                            state: possiblyNewState,
                            updateReason: isComponentsMapChangeUndoable ? STATE_CHANGED : AMEND_CASE,
                            scope: R.pipe(
                                setLastComponentsMap(componentsMap),
                                setPrevComponentsEval(componentsEval)
                            )(scope)
                        };
                    }
                    return { state, scope: setPrevComponentsEval(componentsEval, scope) };
                }

                return { state, scope: setPrevComponentsEval(componentsEval, scope) };
            }
        },
        {
            conditions: [
                COMPONENTS_DELETED,
                ReceiveOnlyComponentsMap,
                ReceiveOnlyTemplateWidthActionType
            ],
            reducer: ({ values: [deletedComponentsId, componentsMap, templateWidth], state, scope }) => {
                let componentIds: Array<string> = [],
                    newState = { ...state };
                deletedComponentsId.forEach(id => {
                    let changes = { ...newState.changes };
                    delete changes[id];
                    componentIds = componentIds.concat(
                        Object.keys(changes)
                            .filter(cmpId => changes[cmpId].relIn.id === id)
                            .reduce((prev: Array<string>, cmpId) => {
                                if (componentsMap[cmpId]) {
                                    prev.push(cmpId);
                                }
                                return prev;
                            }, [])
                    );
                });

                if (componentIds.length) {
                    newState = calcRelIn(
                        componentIds.map(cmpId => componentsMap[cmpId]),
                        componentsMap,
                        newState,
                        templateWidth
                    );
                }

                return {
                    state: newState,
                    updateReason: STATE_CHANGED,
                    scope: setLastComponentsMap(componentsMap, scope)
                };
            }
        },
        {
            conditions: [COMPONENTS_MAP_INITIALIZED],
            reducer: ({ values: [{ componentsMap }], scope }) => ({
                state: {
                    changes: Object.keys(componentsMap).reduce((acc, id) => {
                        const cmp = componentsMap[id];
                        if (cmp.relIn && cmp.relIn.id) {
                            const { relIn: { id: relInId, top, bottom, left, right } } = cmp;
                            if (!acc[relInId] || (acc[relInId] && acc[relInId].relIn.id !== id)) {
                                acc[id] = { // eslint-disable-line no-param-reassign
                                    relIn: {
                                        id: relInId,
                                        top,
                                        bottom,
                                        left,
                                        right
                                    }
                                };
                            }
                        }
                        return acc;
                    }, {})
                },
                updateReason: undoManagerUpdateReasons.UNDO_INITIAL_STATE,
                scope: setLastComponentsMap(componentsMap, scope)
            })
        },
        {
            conditions: [
                componentsEvalUpdateReasons.MOVED_TO_TEMPLATE_OR_PAGE,
                ReceiveOnlyComponentsMap,
                ReceiveOnlyTemplateWidthActionType
            ],
            reducer: ({ values: [{ selectedComponentIds }, componentsMap, templateWidth], state, scope }) => {
                const newState = calcRelIn(
                    selectedComponentIds.map(cmpId => componentsMap[cmpId]),
                    componentsMap,
                    state,
                    templateWidth
                );
                if (newState !== state) {
                    return {
                        state: newState,
                        updateReason: STATE_CHANGED,
                        scope: setLastComponentsMap(componentsMap, scope)
                    };
                }
                return { state, scope };
            }
        },
        ...[
            SEPARATE_FOOTER_BY_FOOTER_TMP_CMPS,
            REMOVE_EMPTY_GAPS,
            REMOVE_EMPTY_GAPS_IN_CONTAINERS,
            REMOVE_EMPTY_STRIPS_OVERLAPPING_WITH_HEADER,
            SUPPORT_FIX_TOPS,
            MODERN_HEADER_ACTIVATED,
            MODERN_FOOTER_ACTIVATED,
            MODERN_HEADER_DEACTIVATED,
            MODERN_FOOTER_DEACTIVATED,
            ADD_REMOVE_WEBSHOP_STRIP_SUCCESS,
            MHF_COMPONENTS_TOGGLE_COMPLETED,
            GALLERY_FULL_WIDTH,
            ADD_BOXES_TO_PAGE_SECTION,
            WRAP_IMG_TEXT_IN_BOX,
            GROUP_TEXT_COMPONENTS_INTO_ONE
        ].map(action => ({
            conditions: [
                ReceiveOnlyComponentsMap,
                ReceiveOnlyTemplateWidthActionType,
                action
            ],
            reducer: ({ values: [componentsMap, templateWidth], scope }) => {
                const newComponentsMap = updateComponentsRelIns(componentsMap, templateWidth);
                return {
                    state: { changes: extractRelInsMap(newComponentsMap) },
                    updateReason: STATE_CHANGED,
                    scope: setLastComponentsMap(componentsMap, scope)
                };
            }
        }))
    ]
});
