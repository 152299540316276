import React from "react";
import { Dispatch } from "redux";
import { MctaButton, MctaVerticalBar } from "../../../../componentMainActions/index";
import mctaStyles from "../../../../componentMainActions/MctaButton/styles.css";
import HorizontalSpacer from "../../../../../view/common/HorizontalSpacer";
import { MAX_FONT_SIZE, MIN_FONT_SIZE } from "../../../../oneweb/Text/constants";
import {
    titleTextBigger, titleTextBiggerErr, titleTextSmaller, titleTextSmallerErr
} from "../../../../oneweb/constants";
import { MOBILE_EDITOR_TEXT_FONT_CHANGE_IN_GROUP } from "../../../actionTypes";

type PropTypes = {
    dispatch: Dispatch,
    id: string,
    minSize: number,
    maxSize: number
};

class TextFontMctaScaler extends React.PureComponent<PropTypes> {
    decreaseTextSize: Function;
    increaseTextSize: Function;

    constructor(props: PropTypes) {
        super(props);
        this.decreaseTextSize = this.changeTextSize.bind(this, -1);
        this.increaseTextSize = this.changeTextSize.bind(this, 1);
    }

    changeTextSize(factor: number) {
        const { id, dispatch } = this.props;
        dispatch({ type: MOBILE_EDITOR_TEXT_FONT_CHANGE_IN_GROUP, payload: { id, factor } });
    }

    render() {
        const { maxSize, minSize } = this.props,
            canIncrease = maxSize < MAX_FONT_SIZE,
            canDecrease = minSize > MIN_FONT_SIZE;

        return (
            <React.Fragment>
                <MctaButton
                    btnClass={canDecrease ? '' : mctaStyles.disabled}
                    title={canDecrease ? titleTextSmaller : titleTextSmallerErr}
                    isErrorTitle={!canDecrease}
                    icon="FONT_SIZE_DECREASE"
                    onClick={canDecrease ? this.decreaseTextSize : null}
                />
                <HorizontalSpacer x={5} />
                <MctaVerticalBar />
                <HorizontalSpacer x={5} />
                <MctaButton
                    btnClass={canIncrease ? '' : mctaStyles.disabled}
                    title={canIncrease ? titleTextBigger : titleTextBiggerErr}
                    isErrorTitle={!canIncrease}
                    icon="FONT_SIZE_INCREASE"
                    onClick={canIncrease ? this.increaseTextSize : null}
                />
            </React.Fragment>
        );
    }
}

export default TextFontMctaScaler;
