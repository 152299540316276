import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(
    (theme) => ({
        imageChooserLabel: {
            fontSize: "14px",
            lineHeight: "22px",
            marginBottom: "4px"
        },
        imageChooserContainer: {
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
            [theme.breakpoints.down("xs")]: {
                flexDirection: "column",
                alignItems: "flex-start"
            }
        },
        imageChooserInput: {
            width: "100%",
            border: `1px solid ${theme.palette.custom.colorGray_bb}`,
            height: "46px",
            padding: "11px 52px 11px 20px",
            fontSize: "16px",
            lineHeight: "26px",
            cursor: "pointer",
            justifyContent: "left",
            fontWeight: 400,
            color: theme.palette.custom.colorScienceBlue
        },
        imageChooserInputSelected: {
            color: theme.palette.custom.colorGray_3c
        },
        inputLabelContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%"
        },
        inputLabel: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
            textAlign: "left",
            [theme.breakpoints.up("md")]: {
                maxWidth: "315px"
            }
        },
        closeIcon: {
            display: "flex",
            alignItems: "center",
            marginRight: "-34px"
        },
        previewCloseIcon: {
            display: "flex",
            alignItems: "center"
        },
        imageChooserHint: {
            width: "100%",
            marginTop: "4px",
            color: theme.palette.custom.colorGray_8a,
            fontSize: "14px",
            lineHeight: "20px"
        },
        imageChooserHintWarning: {
            width: "100%",
            marginTop: "10px",
            fontSize: "14px",
            lineHeight: "20px",
            color: theme.palette.custom.colorRedEpiphyllum
        },
        previewWrapper: {
            display: "flex",
            width: "100%",
            height: "59px",
            borderRadius: "10px 10px 0 0",
            margin: "19.5px 0"
        },
        previewContent: {
            display: "flex",
            alignItems: "center"
        },
        leftWrapper: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "71px",
            borderRadius: "10px 0 10px 0",
            background: theme.palette.custom.colorGray_e2
        },
        circle: {
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            background: theme.palette.custom.colorGray_bb,
            margin: "4px"
        },
        middleWrapper: {
            display: "flex",
            width: "calc(100% - 10% - 71px)",
            flexDirection: "column",
            justifyContent: "flex-end",
            background: theme.palette.custom.colorGray_e2
        },
        previewContainer: {
            display: "flex",
            width: "100%",
            height: "46px",
            background: theme.palette.custom.colorWhite,
            borderRadius: "10px 10px 0 0",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 15px 0 13px"
        },
        rightWrapper: {
            height: "59px",
            width: "10%",
            background: theme.palette.custom.colorGray_e2,
            borderRadius: "0 10px 0 0"
        },
        imagePreview: {
            width: 28,
            height: 28,
            border: `1px dashed ${theme.palette.custom.colorScienceBlue}`,
            borderRadius: "5px",
            background: theme.palette.custom.colorLavenderWeb
        },
        yourTitle: {
            paddingLeft: "8px",
            fontSize: "16px",
            lineHeight: "24px"
        },
        image: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }
    }),
    { name: "GeneralFaviconStyles" }
);
