const defaultSectionStyle = {
    border: null,
    background: {
        colorData: {
            color: [
                "HSL",
                0,
                0,
                0.933,
                0
            ],
            gradient: null
        },
        assetData: null
    }
};

export default defaultSectionStyle;
