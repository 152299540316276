import { DEFAULT_WIDTH, DEFAULT_HEIGHT, InstagramSrcLink } from '../constants';
import { isInstagramLinkValid } from './linkValidator';
import { parseInstagramPostCode } from "./parseInstagramPostCode";
import { type InstagramComponent } from '../types';

export const parseInstagramCode = (code: string): InstagramComponent => {
    let params = <InstagramComponent>{};
    if (code.length) {
        if (isInstagramLinkValid(code)) {
            params.link = `${InstagramSrcLink}${code.split('/')[4]}`;
        } else {
            params = parseInstagramPostCode(code);
        }
    }
    return params.link ? { width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT, ...params, } : {};
};
