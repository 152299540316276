import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import { receiveOnly, withSelector } from '../../../../epics/makeCondition';
import { ANIMATION_FRAME } from '../../../../redux/middleware/raf';
import {
    GET_ANALYTICS_DEPENDENCIES_SUCCESS_ACTION,
    GET_ANALYTICS_DEPS_FAILURE_ACTION,
    getAnalyticsDependenciesActionAC,
    REMEMBER_GOAL_ACHIEVED,
    analyticsGoalAchievedAC
} from './actions';
import { UserDataMigrationVAT } from '../../../UserDataMigration/epic/valueActionType';
import {
    noUserDataMigrationRequired,
    userDataMigratedSuccessfully
} from '../../../UserDataMigration/utils';
import { createScheduledAction } from '../../../../redux/middleware/schedule/actionCreators';

const epicConfig = {
    defaultState: {
        computedUserCreationClientTimestamp: 0,
        oldestAvailableTimestampForSiteData: 0,
        serverTimestamp: 0,
        clientTimestamp: 0,
        serverClientDelta: 0,
        encryptedDomain: '',
        goalsTimestamps: {},
    },
    valueActionType,
    updaters: [
        {
            conditions: [
                UserDataMigrationVAT,
            ],
            reducer: ({ state, values: [userDataMigrationStatus] }) => {
                if (
                    noUserDataMigrationRequired(userDataMigrationStatus) ||
                    userDataMigratedSuccessfully(userDataMigrationStatus)
                ) {
                    return {
                        state,
                        actionToDispatch: getAnalyticsDependenciesActionAC()
                    };
                }
                return { state };
            }
        },
        {
            conditions: [
                receiveOnly(ANIMATION_FRAME),
                GET_ANALYTICS_DEPENDENCIES_SUCCESS_ACTION
            ],
            reducer: ({ values: [{ ts: clientTimestamp }, data], state }) => ({
                state: { ...state, ...data, clientTimestamp }
            })
        },
        {
            conditions: [
                withSelector(valueActionType, s => s.oldestAvailableTimestampForSiteData),
                withSelector(valueActionType, s => s.serverTimestamp),
                withSelector(valueActionType, s => s.clientTimestamp),
            ],
            reducer: ({
                state,
                values: [
                    oldestAvailableTimestampForSiteData,
                    serverTimestamp,
                    clientTimestamp
                ]
            }) => {
                let computedUserCreationClientTimestamp = 0;
                let serverClientDelta;
                if (clientTimestamp && serverTimestamp && oldestAvailableTimestampForSiteData) {
                    serverClientDelta = serverTimestamp - clientTimestamp;
                    computedUserCreationClientTimestamp = oldestAvailableTimestampForSiteData - serverClientDelta;
                }

                if (state.computedUserCreationClientTimestamp !== computedUserCreationClientTimestamp) {
                    return {
                        state: {
                            ...state,
                            computedUserCreationClientTimestamp,
                            serverClientDelta
                        }
                    };
                }

                return { state };
            }
        },
        {
            conditions: [GET_ANALYTICS_DEPS_FAILURE_ACTION],
            reducer: ({ state }) => {
                return {
                    state,
                    actionToDispatch: createScheduledAction({ actionToDispatch: getAnalyticsDependenciesActionAC(), timeout: 5000 })
                };
            }
        },
        {
            conditions: [
                receiveOnly(ANIMATION_FRAME),
                REMEMBER_GOAL_ACHIEVED
            ],
            reducer: ({ state, values: [{ ts }, { goalId }] }) => {
                return {
                    state: {
                        ...state,
                        goalsTimestamps: {
                            ...state.goalsTimestamps,
                            [goalId]: ts + state.serverClientDelta
                        }
                    },
                    actionToDispatch: analyticsGoalAchievedAC(goalId)
                };
            }
        }
    ]
};

export default makeEpic(epicConfig);
