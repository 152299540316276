import React from 'react';

import styles from '../PageInfoDialog.css';
import type { PageInfoNamesTabProps } from "../../flowTypes";
import { LabeledInput } from '../../../../../../inputControls/input/view';
import * as Actions from "../../actions";
import { switchPageInfoTabAction } from "../../actions";
import injectIntl from "../../../../../../../view/intl/injectIntl";
import LongTextTip from '../../../../../../../view/common/LongTextTip';
import getStyleIntValue from "../../../../../../../utils/getStyleIntValue";
import { decodeWebspaceUri } from "../../../../../../../../dal/utils/webspaceUriTransformers";
import isDuplicatePageUrlPathInputValidation from "../../reducer/isDuplicatePageUrlPathInputValidation";
import { TooltipPosition } from '../../../../../../presentational/Tooltip/constants';
import { PageInfoTab } from "../../../../constants";
import { LinkButtonCom } from "../../../../../../presentational/LinkButton/LinkButton";
import { Msg } from "../../../../../../../view/intl";
import { QuestionTip } from "../../../../../../presentational/Tooltip/questionTip/QuestionTip";
import { PAGE_NAME_TIP_MSG, SeoPageTitleInTabOptions } from "../constants";
import { getDefaultNonHomePageNameInBrowserTab } from "../../utils";

const DOMAIN_LABEL_MAX_WIDTH = getStyleIntValue(styles, 'domainLabelMaxWidth');
const YOUR_TITLE_WIDTH = getStyleIntValue(styles, 'yourTitleWidth');

export default injectIntl((props: PageInfoNamesTabProps) => {
    const {
            state: {
                name,
                url: stateUrl,
            },
            seoState: {
                customTitle,
                defaultHomePageNameInBrowserTab,
                pageNameOptionInBrowserTab,
            },
            origPageUrlPath,
            allPagesUrlPaths,
            origHomePageUrlPath,
            isHomePage,
            isParentPage,
            urlParams,
            isDemo,
            intl,
            dispatch,
            websiteTitle,
            domainName,
        } = props,
        url = { ...stateUrl, value: decodeWebspaceUri(stateUrl.value) },
        urlActionParams = {
            [isDuplicatePageUrlPathInputValidation.name]: { origPageUrlPath, allPagesUrlPaths, origHomePageUrlPath }
        };

    const defaultNonHomePageNameInBrowserTab = getDefaultNonHomePageNameInBrowserTab(name.value, domainName, websiteTitle);
    const defaultDisplayTitle = isHomePage ? defaultHomePageNameInBrowserTab : defaultNonHomePageNameInBrowserTab;
    const displayTitle = pageNameOptionInBrowserTab === SeoPageTitleInTabOptions.CUSTOM ? customTitle.value : defaultDisplayTitle;
    return (
        <div className={styles.tab}>
            <div className={styles.pageNameContainer}>
                <LabeledInput
                    label="msg: pageNameInMenu {Page name in menu}"
                    state={name}
                    onChangeAction={Actions.PAGE_INFO_NAME_CHANGE}
                    helptip={{
                        position: TooltipPosition.TOP,
                        /* eslint-disable-next-line max-len */
                        message: "msg: pageNameInMenuTabHelpTip {This name will only be shown in the menu for your website. Keep it short, to make it fit in the mobile menu as well.}"
                    }}
                    errorOpts={{ _name: 'Name' }}
                    dispatch={dispatch}
                />
            </div>

            <div>
                <div className={styles.pageDescriptionLabelContainer}>
                    <Msg className={styles.pageDescriptionLabel} k="pageNameInBrowserTab">Page name in browser tab and search results</Msg>
                    <QuestionTip
                        position={TooltipPosition.TOP}
                        className={styles.pageDescriptionTipIcn}
                        theme={{
                            container: styles.pageDescriptionTipContainer,
                            tipInfo: styles.pageDescriptionTipInfo
                        }}
                    >{PAGE_NAME_TIP_MSG}</QuestionTip>
                </div>

                <div className={styles.faviconWrapper}>
                    <div>
                        <div className={styles.yourTitleBackground}>
                            <div className={styles.grayDots} />
                            <div className={styles.tabStart} />
                            <div className={styles.faviconDropZone}> </div>
                            <div className={styles.tabArea}>
                                <div className={styles.faviconDisplayArea} />
                                <div className={styles.yourTitle}>
                                    <LongTextTip maxWidth={YOUR_TITLE_WIDTH}>
                                        {displayTitle}
                                    </LongTextTip>
                                </div>
                                <div className={styles.closeBtn} />
                            </div>
                            <div className={styles.tabEnd} />
                            <div className={styles.tabPlus} />
                        </div>
                    </div>
                    <LinkButtonCom
                        className={styles.editName}
                        onClick={() => {
                            dispatch(switchPageInfoTabAction(PageInfoTab.SEO));
                        }}
                    >
                        {'msg: pageInfo.name.editName {Edit name}'}
                    </LinkButtonCom>
                </div>
            </div>

            <div className={styles.namesUrlContainer}>
                <div className={styles.namesUrlPrefixContainer}>
                    <span className={styles.urlLabel}>{intl.msg('pageUrl', 'URL:')}</span>
                    <LongTextTip maxWidth={DOMAIN_LABEL_MAX_WIDTH} className={styles.namesUrlPrefix}>
                        {urlParams.prefix}
                    </LongTextTip>
                </div>
                {!isHomePage && (
                    <div className={styles.namesUrlInputContainer}>
                        <LabeledInput
                            state={url}
                            onChangeAction={Actions.PAGE_INFO_URL_CHANGE}
                            actionParams={urlActionParams}
                            errorOpts={{ _name: 'URL' }}
                            label={urlParams.label}
                            subLabel={urlParams.subLabel}
                            dispatch={dispatch}
                            labelClassName={styles.namesUrlLabel}
                        />
                        <span className={styles.namesUrlSuffix}>{urlParams.suffix}</span>
                    </div>
                )}
                {!isDemo && (
                    <a className={styles.namesUrlVisitPageLink} href={urlParams.absoluteUrl} target="_blank">
                        {intl.msg('pt.names.url.visitPage', 'Visit page')}
                    </a>
                )}
            </div>
            {isHomePage && isParentPage && (
                <LabeledInput
                    state={url}
                    onChangeAction={Actions.PAGE_INFO_URL_CHANGE}
                    actionParams={urlActionParams}
                    errorOpts={{ _name: 'Url' }}
                    label={urlParams.label}
                    subLabel={urlParams.subLabel}
                    dispatch={dispatch}
                    labelClassName={styles.namesUrlLabel}
                    containerClassName={styles.namesUrlInputBelow}
                />
            )}
        </div>
    );
});
