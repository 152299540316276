import * as React from "react";
import cx from 'classnames';
import styles from './DocumentComponent.css';
import type { DocumentComponentProps } from "./flowTypes";
import LoadingIndicator from "../../../../view/common/LoadingIndicator/index";
import { DOCUMENT_IFRAME_RETRY_INTERVAL, DOCUMENT_IFRAME_RETRY_NR } from "../constants";
import { ComponentMaskBackground } from "../../../presentational/ComponentMask/constants";
import ComponentMask from '../../../presentational/ComponentMask/index';
import Url from "../../../../../utils/Url";
import { ua } from "../../../../utils/user-agent/UserAgent";
import { BrowserName } from "../../../../utils/user-agent/constants";

const makeReloadUrl = (url: string, nr: number): string =>
    `${url.replace(/&_r=\d+/, '')}&_r=${nr}`;

const getOrigUrl = (url: string): string => {
    const urlData = Url(url);
    return urlData.query.get('url');
};

type State = {
    isLoading: boolean,
    initialized: boolean,
    isResizing: boolean,
    prevUrl: string,
    prevReload: boolean,
    url: string,
};

export class DocumentComponent extends React.Component<DocumentComponentProps, State> {
    state: State = {
        isLoading: false,
        initialized: false,
        isResizing: false,
        prevUrl: '',
        prevReload: false,
        url: ''
    };

    iframe: any;
    timer?: ReturnType<typeof setInterval>;
    retries: number = 0;

    static getDerivedStateFromProps(nextProps: DocumentComponentProps, prevState: State) {
        if (!prevState.initialized) {
            return {
                initialized: true,
                isLoading: true,
                url: nextProps.url,
                prevUrl: nextProps.url,
                prevReload: nextProps.reload,
            };
        }

        if (nextProps.isResizing && !prevState.isResizing) {
            return { isLoading: true, isResizing: true };
        } else if (!nextProps.isResizing && prevState.isResizing) {
            return { isLoading: false, isResizing: false };
        } else if (nextProps.url !== prevState.prevUrl) {
            return { isLoading: true, url: nextProps.url, prevUrl: nextProps.url, prevReload: true };
        } else if (nextProps.reload && !prevState.prevReload) {
            return { isLoading: true, prevReload: true };
        } else if (!nextProps.reload && prevState.prevReload) {
            return { prevReload: false };
        }

        return null;
    }

    onIframeLoad = () => {
        this.setState({ isLoading: false });
        this.clearInterval();
    }

    onIframeInterval = () => {
        const { isLoading } = this.state;
        if (isLoading) {
            if (this.retries >= DOCUMENT_IFRAME_RETRY_NR) {
                this.clearInterval();
                return;
            }
            this.retries++;

            // reload iframe
            const nextUrl = makeReloadUrl(this.state.url, this.retries);
            this.setState({ url: nextUrl });
        } else {
            this.clearInterval();
        }
    }

    setupInterval() {
        this.retries = 0;
        this.timer = setInterval(this.onIframeInterval, DOCUMENT_IFRAME_RETRY_INTERVAL);
    }

    clearInterval() {
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = undefined;
        }
    }

    shouldRenderShortcut() {
        return this.props.isMve && ua().isBrowser(BrowserName.IE);
    }

    componentDidUpdate() {
        if (!this.timer && this.state.prevReload) {
            this.setupInterval();
        }
    }

    componentDidMount() {
        if (this.shouldRenderShortcut()) return;

        this.iframe.addEventListener('load', this.onIframeLoad);
        this.setupInterval();
    }

    renderShortcut() {
        const
            { shortcut: Shortcut, url: propUrl } = this.props,
            url = getOrigUrl(propUrl);

        return (
            <div className={styles.shortcutContainer}>
                {/* @ts-ignore */}
                <Shortcut panelExpanded />
                <span className={styles.shortcutText}>{url}</span>
            </div>
        );
    }

    renderFull() {
        const
            { url, isLoading } = this.state,
            { isResizing, height } = this.props,
            iframeClassName = cx(
                styles.iframe,
                { [styles.hidden]: isLoading, [styles.noDisplay]: isResizing }
            );

        return (
            <div className={styles.container} style={{ height }}>
                <ComponentMask maskStyle={ComponentMaskBackground.LIGHT}>
                    <iframe
                        src={url}
                        className={iframeClassName}
                        ref={ref => { this.iframe = ref; }}
                    />
                    {isLoading && <LoadingIndicator className={styles.loadingIndicator} />}
                </ComponentMask>
            </div>
        );
    }

    render() {
        return this.shouldRenderShortcut()
            ? this.renderShortcut()
            : this.renderFull();
    }
}
