import React from 'react';

import styles from '../PageInfoDialog.css';
import type { PageInfoVisibilityTabProps } from "../../flowTypes";
import { PAGE_INFO_HIDDEN_TOGGLE, PAGE_INFO_PUBLISHED_TOGGLE } from "../../actions";
import DialogCheckBox from '../../../../../../../view/common/dialogs/baseDialog/DialogCheckBox';
import { Msg } from '../../../../../../../view/intl';
import Icons from '../../../../../../../view/Icons';
import HorizontalSpacer from '../../../../../../../view/common/HorizontalSpacer';

export default (props: PageInfoVisibilityTabProps) => {
    const { state: { isHidden, isPublished }, dispatch } = props;

    return (
        <div className={styles.tab}>
            <div>
                <div className={styles.checkBoxWrapper}>
                    <DialogCheckBox
                        label="msg: pageInfo.hidePage.hideInMenus {Don't show in menus}"
                        isChecked={isHidden}
                        onClick={() => dispatch({ type: PAGE_INFO_HIDDEN_TOGGLE })}
                        labelIcon="HIDDEN_PAGE"
                    />
                    <HorizontalSpacer x={5} />
                    <Icons.HIDDEN_PAGE_ICON className={styles.hiddenPage} />
                </div>
                <div>
                    <Msg k="pageInfo.hidePage.hideInMenusDesc">
                        Hide the page and all of its subpages in your menus. The page is still active and you can link to it.
                    </Msg>
                </div>
            </div>
            <br /><br />
            <div>
                <div className={styles.checkBoxWrapper}>
                    <DialogCheckBox
                        label="msg: pageInfo.hidePage.dontPulish {Don't publish this page}"
                        isChecked={!isPublished}
                        onClick={() => dispatch({ type: PAGE_INFO_PUBLISHED_TOGGLE })}
                    />
                    <HorizontalSpacer x={5} />
                    <Icons.LOCKED_PAGE className={styles.lockedPage} />
                </div>
                <div>
                    <Msg k="pageInfo.hidePage.dontPublishDesc">
                        Hide the page completely. The page will only be accessible from Website Builder.
                    </Msg>
                </div>
            </div>
        </div>
    );
};
