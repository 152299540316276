import reducer from "../reducer/index";
import propertiesPanel from "../propertiesPanel/index";
import kind from './kind';
import componentMainActions from '../componentMainActions/index';
import workspace from '../view/Workspace';
import copyHook from "../copyHook";
import previewConfig from './previewConfig';
import { ComponentNames } from "../../constants";
import { MIN_DIMENSIONS } from "../constants";
import adjustComponentOnAdd from "../adjustComponentOnAdd";
import calcProps from "../calcProps";
import { missingAssetUrls } from "../../../MissingAssets/epic/index";
import { transcodeStatus } from "../epics/checkTranscodeStatus/index";
import { videoUploadStatus, uploadFailedVideos } from "../epics/videoComponent/index";
import validateComponentAdd from "./validateComponentAdd";
import { ErrorMsgsOnAdd } from "./errorMessagesOnAdd";

export default {
    kind,
    workspace,
    label: ComponentNames[kind],
    shortcutIconName: 'video',
    calcRenderProps: (props: any) => calcProps(props, true),
    dependsOn: {
        missingAssetUrls,
        transcodeStatus,
        videoUploadStatus,
        uploadFailedVideos
    },
    adjustComponentOnAdd,
    componentMainActions,
    propertiesPanel,
    copyHook,
    reducer,
    minDimensions: MIN_DIMENSIONS,
    mobileEditorConfig: {
        ...previewConfig,
        calcProps: (props: any): any => {
            const { component: { width, height }, renderedWidth } = props,
                finalProps = calcProps(props, true);
            if (renderedWidth) {
                finalProps.height = (renderedWidth * (height / width));
            }
            return finalProps;
        }
    },
    validateComponentAdd,
    errorMsgsOnAdd: ErrorMsgsOnAdd,
};
