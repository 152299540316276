export const
    SET_SUPPORT_ON = 'SET_SUPPORT_ON',
    OPEN_SUPPORT_TOOL = 'OPEN_SUPPORT_TOOL',
    CLOSE_SUPPORT_TOOL = 'CLOSE_SUPPORT_TOOL',
    SEPARATE_FOOTER_BY_FOOTER_TMP_CMPS = 'SEPARATE _FOOTER_BY_FOOTER_TMP_CMPS',
    REMOVE_EMPTY_GAPS = 'REMOVE_EMPTY_GAPS',
    REMOVE_EMPTY_GAPS_IN_CONTAINERS = 'REMOVE_EMPTY_GAPS_IN_CONTAINERS',
    REMOVE_EMPTY_STRIPS_OVERLAPPING_WITH_HEADER = 'REMOVE_EMPTY_STRIPS_OVERLAPPING_WITH_HEADER',
    SUPPORT_DELETE_SELECTED_SECTION = 'SUPPORT_DELETE_SELECTED_SECTION',
    DELETE_STRIP_AND_ADD_BACKGROUND_TO_SECTION = 'DELETE_STRIP_AND_ADD_BACKGROUND_TO_SECTION',
    SUPPORT_DELETE_SELECTED_CONTAINER_ONLY = 'SUPPORT_DELETE_SELECTED_CONTAINER_ONLY',
    SUPPORT_FIX_TOPS = 'SUPPORT_FIX_TOPS',
    WRAP_IMG_TEXT_IN_BOX = 'WRAP_IMG_TEXT_IN_BOX',
    ADD_BOXES_TO_PAGE_SECTION = 'ADD_BOXES_TO_PAGE_SECTION',
    GROUP_TEXT_COMPONENTS_INTO_ONE = 'GROUP_TEXT_COMPONENTS_INTO_ONE';
