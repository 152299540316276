import * as React from 'react';
import styles from './FmImagePreview.css';
import type { FmImagePreviewDimensionsT } from "./types";
import type { RenderHoverBoxPreview } from "../../presentational/HoverBox/HoverBoxPreviewCom";
import { makeFmImageRenderPreview } from "./fmImageRenderPreview";
import { HoverBoxPreviewBtnCom } from "../../presentational/HoverBoxControls/index";
import type { Dimensions } from "../../../globalTypes";

type Props = {
    url: string,
    title: string,
    dimensions: FmImagePreviewDimensionsT,
    previewDimensions?: Dimensions,
    btnClassName?: string,
    imgContainerClassName?: string,
    photographerName?: string | null,
    photographerProfileLink?: string
};

export class FmImagePreview extends React.Component<Props> {
    renderPreview: RenderHoverBoxPreview;

    constructor(props: Props) {
        super(props);
        const { title,
            dimensions,
            previewDimensions,
            imgContainerClassName,
            photographerName,
            photographerProfileLink } = props;

        this.renderPreview = makeFmImageRenderPreview({
            title,
            dimensions,
            previewDimensions,
            imgContainerClassName,
            photographerName,
            photographerProfileLink
        });
    }

    render() {
        const { url, btnClassName } = this.props;

        return (
            <HoverBoxPreviewBtnCom
                src={url}
                btnTitle={false}
                btnClassName={btnClassName}
                previewClassName={styles.container}
                renderPreview={this.renderPreview}
            />
        );
    }
}
