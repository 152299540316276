import oneColor from "onecolor";
import makeEpic from '../../../epics/makeEpic';
import valueActionType from './valueActionType';
import defaultState from '../defaultState';
import * as actionTypes from '../actionTypes';
import * as appActionTypes from '../../App/actionTypes';
import { makeActionForwardToSelectedComponent } from '../../../redux/forwardTo';
import type { ColorPicker, ShowColorPickerParams } from '../flowTypes';
import { customSendReport } from '../../../customSendCrashReport';

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [actionTypes.SHOW_COLOR_PICKER],
            reducer: ({ values: [showColorPicker], state }: { values: [ShowColorPickerParams], state: ColorPicker }) => ({
                state: { ...state, ...showColorPicker, opened: true }
            })
        },
        {
            conditions: [actionTypes.HIDE_COLOR_PICKER],
            reducer: ({ state }) => {
                if (!oneColor(state.color).hex) {
                    customSendReport({
                        message: "WBTGEN-18816: Invalid color in color picker state found",
                        additionalInfo: {
                            color: state.color,
                            colorPickerKind: state.colorPickerKind,
                            hasColorChanged: state.hasColorChanged,
                            recentColors: state.recentColors,
                            onChangeCompleteAction: state.onChangeCompleteAction,
                            onChangeCompleteDebounced: state.onChangeCompleteDebounced,
                        },
                    });
                }
                return state.hasColorChanged ? ({
                    state: defaultState,
                    actionToDispatch: { type: actionTypes.UPDATE_RECENT_COLOR, payload: { color: state.color } }
                }) : { state: defaultState };
            }
        },
        {
            conditions: [actionTypes.COLOR_CHANGE_COMPLETE],
            reducer: ({ values: [{ onChangeAction, ...rest }], state }) => {
                const newState = { ...state, color: rest.color, hasColorChanged: true };
                if (onChangeAction) {
                    const actionToDispatch: AnyAction = { type: onChangeAction, payload: rest };
                    return { state: newState, actionToDispatch: makeActionForwardToSelectedComponent(actionToDispatch) };
                } else {
                    return { state: newState };
                }
            }
        },
        {
            conditions: [actionTypes.THEME_COLOR_OVERRIDE_CHANGE],
            reducer: ({ values: [{ onChangeAction, themeOverrideColor }], state }) => {
                const actionToDispatch: AnyAction = { type: onChangeAction, payload: themeOverrideColor };
                return { state, actionToDispatch: makeActionForwardToSelectedComponent(actionToDispatch) };
            }
        },
        {
            conditions: [
                actionTypes.COLOR_CHANGE_COMPLETE_DEBOUNCED
            ],
            reducer: ({ values: [{ onChangeDebouncedAction, ...rest }], state }) => {
                const newState = { ...state, color: rest.color, hasColorChanged: true };

                if (onChangeDebouncedAction) {
                    const actionToDispatch: AnyAction = { type: onChangeDebouncedAction, payload: rest };
                    return { state: newState, actionToDispatch: makeActionForwardToSelectedComponent(actionToDispatch) };
                } else {
                    return { state: newState };
                }
            }
        },
        {
            conditions: [appActionTypes.ESC_KEY_PRESSED],
            reducer: ({ state }) => ({
                state,
                actionToDispatch: { type: actionTypes.HIDE_COLOR_PICKER }
            })
        },
        {
            conditions: [appActionTypes.ENTER_KEY_PRESSED],
            reducer: ({ state }) => ({
                state,
                actionToDispatch: { type: actionTypes.HIDE_COLOR_PICKER }
            })
        }
    ]
});
