import React, * as ReactAll from "react";
import { Flex } from "../../reflexbox/index";
import InputField from '../Input/InputField';
import Label from '../Label';
import styles from "./PropertyInputBox.css";

type Validator = {
    validator: (AnyValue) => boolean
}

type InputBoxProps = {
    inputLabel: string,
    inputValue: string | number,
    onChangeAction: string,
    componentId: string,
    validators?: Array<Validator>,
    dispatch: Dispatch
}

type BatchedInputBoxProps = InputBoxProps & {
    batchChangeTimeout: number,
}

const
    PropertyInputBoxFactory = (getExtraProps) =>
        (props) => {
            const
                { componentId, inputLabel, inputValue, onChangeAction, dispatch } = props,
                dispatchChange = text => dispatch({
                    type: onChangeAction, payload: { text, componentId }, amendToSelf: true
                }),
                extraProps = getExtraProps(
                    props,
                    dispatchChange
                );

            return (
                <Flex align="center" justify="space-between">
                    <Label>{inputLabel}</Label>
                    <InputField
                        value={inputValue}
                        className={styles.productsInputField}
                        {...extraProps}
                    />
                </Flex>
            );
        },
    PropertyInputBox: ReactAll.ComponentType<InputBoxProps> = PropertyInputBoxFactory(
        (props: InputBoxProps, dispatchChange) => ({
            validators: props.validators,
            onChange: (text, isValid) => isValid && dispatchChange(text)
        })
    ),
    PropertyInputBoxWithTimer: ReactAll.ComponentType<BatchedInputBoxProps> = PropertyInputBoxFactory(
        (props: BatchedInputBoxProps, dispatchChange) => (
            {
                validators: props.validators,
                batchChangeTimeout: props.batchChangeTimeout,
                onBatchChange: (text, isValid) => isValid && dispatchChange(text)
            }
        )
    );

export {
    PropertyInputBox,
    PropertyInputBoxWithTimer
};
