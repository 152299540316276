import {
    GALLERY_SET_IMAGES_ON_COMPONENT_DROPED,
    GALLERY_MCTA_OPEN_SORTING_WINDOW_CLICKED,
    GALLERY_PP_OPEN_SORTING_WINDOW_CLICKED
} from "../actionTypes";
import { label, message } from '../constants';
import { openSortedImageDialogAction } from '../actionCreators';
import { componentConfigurationCompleteAction } from "../../../../redux/modules/children/workspace/actionCreators";
import { getFormattedAssets } from "../utils";

export const galleryMiddleware = (store: Store) => (next: Dispatch) => (action: AnyAction) => {
    if (action.type === GALLERY_SET_IMAGES_ON_COMPONENT_DROPED) {
        const { payload: { assets } } = action,
            images = getFormattedAssets(assets);

        store.dispatch(componentConfigurationCompleteAction({ images }));
    }

    if (
        action.type === GALLERY_PP_OPEN_SORTING_WINDOW_CLICKED ||
        action.type === GALLERY_MCTA_OPEN_SORTING_WINDOW_CLICKED
    ) {
        store.dispatch(openSortedImageDialogAction(label, message));
    }

    return next(action);
};
