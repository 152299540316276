import * as R from 'ramda';
import type { DialogManagerScope, DialogManagerState } from "../flowTypes";
import { dialogManagerVAT } from "./valueActionType";
import { makeEpicStateSelector } from "../../../epics/makeEpic";
import { memoMaxDynamicNumOfArgs } from "../../../../utils/memo";
import type { AppState } from "../../../redux/modules/flowTypes";

const
    scopeTopMostDialogSelector = (scope: DialogManagerScope) => R.last(scope.openedDialogConfigs),
    memoOpenedDialogIds = memoMaxDynamicNumOfArgs((...ids) => ids, 1),
    openedDialogIdsSelector = (epicState: DialogManagerState) =>
        memoOpenedDialogIds(...epicState.computed.renderProps.map((x: Record<string, any>) => x.id)),
    topMostDialogIdSelector = R.pipe(openedDialogIdsSelector, R.last),
    topMostDialogSelector = (epicState: DialogManagerState) =>
        R.last(epicState.computed.renderProps),
    topMostDialogStateSelector = (epicState: DialogManagerState) => {
        const last = topMostDialogSelector(epicState);
        return last && last.state;
    },
    dialogManagerStateFromAppStateSelector = makeEpicStateSelector(dialogManagerVAT),
    dialogManagerRenderPropsFromAppStateSelector = (appState: AppState) =>
        dialogManagerStateFromAppStateSelector(appState).computed.renderProps,
    dialogManagerRenderPropsSelector = (epicState: DialogManagerState) => epicState.computed.renderProps,
    dialogManagerIsDraggingSelector = (appState: AppState) =>
        dialogManagerStateFromAppStateSelector(appState).isDragging;

export {
    scopeTopMostDialogSelector,
    openedDialogIdsSelector,
    dialogManagerStateFromAppStateSelector,
    dialogManagerRenderPropsFromAppStateSelector,
    topMostDialogSelector,
    topMostDialogStateSelector,
    dialogManagerRenderPropsSelector,
    topMostDialogIdSelector,
    dialogManagerIsDraggingSelector
};
