import React from 'react';
import Page from "../../../../PropertiesPanel/view/PropertiesPage";
import PropertiesCheckBox from '../../../../../view/common/CheckBox/PropertiesCheckBox';
import Combobox from '../../../../../view/common/Combobox/IntlCombobox';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';
import { VerbsToDisplay } from '../../constants';
import { isSimpleStyle, isExtraInfoStyle } from '../../utils';
import * as actionTypes from '../../actionTypes';
import * as pageIds from '../pageIds';
import pagesTitles from '../pagesTitles';
import type { PageProps } from '../../../../PropertiesPanel/flowTypes';
import type { ShareButtonsComponent } from '../../flowTypes';

const
    id = pageIds.FACEBOOK_LIKE,
    title = pagesTitles[id],
    view = ({
        selectedComponent: {
            styleInfo,
            showFacebook,
            verbToDisplay,
            showFriends
        },
        dispatch
    }: PageProps<ShareButtonsComponent>) => {
        const
            simpleStyle = isSimpleStyle(styleInfo),
            extraInfoStyle = isExtraInfoStyle(styleInfo);

        return (
            <Page>
                <PropertiesCheckBox
                    label={`msg: component.shareButtons.pp.showFacebookLike {Show \"Facebook Like\"}`} // eslint-disable-line no-useless-escape
                    isChecked={!simpleStyle && showFacebook}
                    onClick={() => dispatch({ type: actionTypes.SHAREBUTTONS_TOGGLE_FACEBOOK })}
                    disabled={simpleStyle}
                />
                <VerticalSpacer />
                <PropertyContainer
                    label="msg: component.shareButtons.pp.verbToDisplay {Verb to display}"
                    disabled={simpleStyle || !showFacebook}
                >
                    <Combobox
                        searchable={false}
                        value={verbToDisplay}
                        options={VerbsToDisplay}
                        disabled={simpleStyle || !showFacebook}
                        onChange={({ value }) => dispatch({
                            type: actionTypes.SHAREBUTTONS_VERB_TO_DISPLAY_CHANGED,
                            payload: value
                        })}
                    />
                </PropertyContainer>
                <PropertiesCheckBox
                    label="msg: component.shareButtons.pp.showFriends {Show friends (Extra info style only)}"
                    isChecked={extraInfoStyle && showFriends}
                    disabled={!extraInfoStyle}
                    onClick={() => dispatch({ type: actionTypes.SHAREBUTTONS_TOGGLE_FACEBOOK_SHOW_FRIENDS })}
                />
            </Page>
        );
    };

export { id, title, view };
