export const parseSize = (size: string, refSize: number) => {
    // TODO: add support for decimal values and other size units (check other todo comments)
    type SizeUnitType = "px" | "%";
    const parsed = /^(\d+)(px|%)$/i.exec(size.toLowerCase());

    if (parsed) {
        const value = parseInt(parsed[1], 10);
        const unit = parsed[2] as SizeUnitType;

        return unit === "%" ? ((value * refSize) / 100) : value;
    }

    throw new Error(`Invalid size value "${size}"`);
};
