import React from "react";
import Popup from "../../TopBar/view/Popup/index";
import { PrimaryButton } from "../../../view/common/Button/index";
import { hideAutosavePopup } from "../actions";
import { AUTOSAVE_TEST_ID } from "./constants";
import { injectIntl } from "../../../view/intl";
import * as styles from "./Autosave.css";

type Props = {
    dispatch: Dispatch,
    intl: Intl,
};

export const AutosavePopup = injectIntl((props: Props) => {
    const { dispatch, intl } = props;
    const closeBtnClicked = e => {
        e.stopPropagation();
        dispatch(hideAutosavePopup());
    };

    return (
        <div data-testid={AUTOSAVE_TEST_ID}>
            <Popup
                includeCloseButton
                containerClassName={styles.autosaveContainer}
                tipClassName={styles.autoSaveTip}
                noMouseEvents={false}
                onClose={closeBtnClicked}
            >
                <div className={styles.autosaveIcon} />
                <div className={styles.autosaveHeader}>
                    {intl.msgJoint("msg: autoSave.introduction {Introducing autosave}")}
                </div>
                <div style={{ marginTop: "16px", textAlign: "left" }}>
                    <div className={styles.autosaveBody}>
                        {intl.msgJoint(`msg: autoSave.PopupContent {We now save your changes automatically while you're editing,
                         so you don't have to worry about losing your work.
                        You can still undo changes or restore previous versions of your website
                         if you're not happy with the saved changes.}`)}
                    </div>
                </div>
                <PrimaryButton
                    className={styles.autoSaveButton}
                    onClick={closeBtnClicked}
                >
                    <div className={styles.autoSaveButtonText}>
                        {intl.msgJoint("msg: common.gotIt.exclamation {Got it!}")}
                    </div>
                </PrimaryButton>
            </Popup>
        </div>
    );
});
