import React from 'react';
import * as styles from '../onBoardingPanel.css';
import { Intl } from '../../../../../view/intl/index';

type Props = {
    intl: Intl
};
const savingHeaderTitle = 'msg: modernLayout.onBoardingPanel.savingHeaderSubTitle1 {Next up}';
const savingHeaderSubTitle = 'msg: modernLayout.onBoardingPanel.savingHeaderSubTitle2 {Select and define the layout for your footer.}';

const OnBoardingSaveHeader = ({ intl }: Props) => {
    return <React.Fragment>
        <div className={styles.loadingCircle} />
        <div className={styles.loadingBar} />
        <div className={styles.savingHeaderTitle}>
            {intl.msgJoint(savingHeaderTitle) + ':'}
        </div>
        <div className={styles.savingHeaderSubTitle}>
            {intl.msgJoint(savingHeaderSubTitle)}
        </div>
    </React.Fragment>;
};

export default OnBoardingSaveHeader;
