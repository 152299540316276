import React from 'react';

import Icons from '../../view/Icons/index';
import VerticalSpacer from '../../view/common/VerticalSpacer';
import { injectIntl, Intl } from '../../view/intl/index';
import { PrimaryButtonType } from '../../view/common/Button/PrimaryButton';
import { SecondaryButtonType } from '../../view/common/Button/SecondaryButton';
import LoadingIndicator from '../../view/common/LoadingIndicator/index';
import getCenteredDialogConfig from '../DialogManager/getCenteredDialogConfig';
import { Dialog, DialogTitleBox, DialogBody, DialogFooter } from '../../view/common/dialogs/baseDialog/index'; // eslint-disable-line max-len
import { closeDialog } from '../App/actionCreators/index';
import styles from './GlobalstylesDialog.css';

type Props = {
    title: string,
    message?: string,
    showSpinner?: boolean,
    stripType: string,
    buttonType: string,
    buttonText: string,
    intl: Intl,
    success?: boolean,
    dispatch: Dispatch
}

const
    width = 500,
    height = 350,

    getDialogFooter = ({ buttonType, buttonText, handler }) => {
        if (buttonType === PrimaryButtonType) {
            return (<DialogFooter mctaText={buttonText} mctaHandler={handler} />);
        } else if (buttonType === SecondaryButtonType) {
            return (<DialogFooter sctaText={buttonText} sctaHandler={handler} />);
        }
        return null;
    },

    DeleteGlobalstyleDialog = injectIntl(({
        title,
        message,
        showSpinner,
        stripType,
        buttonType,
        buttonText,
        success,
        intl,
        dispatch
    }: Props) => (
        <Dialog stripType={stripType} showCloseBtn={false}>
            <DialogTitleBox title={title} />
            <DialogBody>
                {showSpinner && <LoadingIndicator />}
                {
                    success &&
                    <div>
                        <Icons.CONFIRMATION_CHECKMARK style={{ fill: "#006fcd" }} />
                        <VerticalSpacer />
                    </div>
                }
                {
                    message &&
                    <div className={success ? styles.textCenter : null}>
                        {intl.msgJoint(message)}
                    </div>
                }
            </DialogBody>
            {getDialogFooter({ buttonType, buttonText, handler: () => dispatch(closeDialog()) }) as React.JSX.Element}
        </Dialog>
    )),
    config = {
        ...getCenteredDialogConfig({ width, height, component: DeleteGlobalstyleDialog }),
        updateConfFn: (config, dimensions, props) => ({ ...config, props: { ...config.props, ...props } })
    };

export default config;
