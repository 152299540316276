/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */

import React from "react";
import * as R from "ramda";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import * as path from '../../mappers/path';

import { Dialog, DialogBody, DialogFooter } from "../../view/common/dialogs/baseDialog/index";
import { closeDialog } from '../App/actionCreators/index';
import { nameReducer as templateNameReducer } from '../oneweb/Template/epics/template/index';
import browserDimensionsEpic from '../App/epics/browserDimensions/index';
import siteFontsReducer from "../Fonts/siteFontsReducer";
import stylesheetsEpic from "../Workspace/epics/stylesheets/index";
import stylesheetsIdToNameMapEpic from '../Workspace/epics/stylesheets/idToNameMap';
import siteDataEpic from "../App/epics/siteData/index";
import currentlyOpenedStyelsheetId from '../Workspace/epics/currentOpenedStylesheetId/index';
import * as stylesheetSelectors from "../Workspace/epics/stylesheets/selectors";

import { width, GlobalStyleTabs, GLOBAL_STYLES_DIALIG_MIN_HEIGHT } from './constants';
import { GLOBAL_STYLES_TAB_SELECTED } from "./actionTypes";

import { Tab as TextGlobalStyelTab, TabPanel as TextGlobalStyleTabPanel } from './Text/index';
import { Tab as LinkGlobalStyleTab, TabPanel as LinkGlobalStyleTabPanel } from './Link/index';
import { Tab as MenuGlobalStyleTab, TabPanel as MenuGlobalStyleTabPanel } from "./Menu/index";
import { Tab as ButtonGlobalStyleTab, TabPanel as ButtonGlobalStyleTabPanel } from "./Button/index";
import { Tab as TableGlobalStyleTab, TabPanel as TableGlobalStyleTabPanel } from "./Table/index";

import { selectedComponentReducer } from "../Workspace/epics/componentsEval/index";
import { Msg } from "../../view/intl/index";

import styles from "./GlobalstylesDialog.css";

import type { DialogProps } from './flowTypes';
import {
    getCenterPositionForDialog,
    getDialogHeightWithRespectToBrowserHeight,
    makeUpdateDialogHeightOnBrowserDimensionsChanged
} from "../DialogManager/utility";
import type { BrowserDimensions } from "../../redux/modules/children/dimensions/index";
import { colorThemeSiteSettingsEpic } from "../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import { LinkButtonCom } from '../presentational/LinkButton/LinkButton';
import { getThemeColorsFromStylesheet } from "../Workspace/epics/stylesheets/selectors";
import currentPageIdEpic from '../App/epics/currentPageId/index';
import { preferencesReducer } from '../Preferences/reducer/preferencesReducer';
import MenuKind from "../oneweb/Menu/globalStyle/kind";
import ThemeStyleMenuType from "../oneweb/Menu/globalStyle/themeColorKind";
import ICONS from '../../view/Icons/index';
import showAutoColorLeftPanel from "../AutoColorLeftPanel/actionCreators/showAutoColorLeftPanel";
import { closeDialogAC } from "../App/actionCreators/closeDialog";
import { EXTENDED_PANEL_CLOSE } from "../Panel/actionTypes";
import LeftPanelEpic from "../Panel/epics/extendedPanel/index";

const _parseInt = val => parseInt(val, 10);

const
    FooterHeight = _parseInt(styles.FooterHeight),
    PreviewLabelHeight = _parseInt(styles.PreviewLabelLineHeight) + _parseInt(styles.PreviewLabelPaddingTop) + _parseInt(styles.PreviewLabelPaddingBottom),
    AllLabelsHeight = _parseInt(styles.DialogPaddingTop)    // 24px
        + _parseInt(styles.TitleLineHeight)                 // 27px
        + (2 * _parseInt(styles.TitlePaddingTopBottom))     // 2 * 2px = 4px
        + _parseInt(styles.SubTitleLineHeight)              // 12px
        + _parseInt(styles.SubTitlePaddingBottom)           // 13px
        + _parseInt(styles.TabTextHeight)                   // 20px
        + _parseInt(styles.InfoBarHeight);                  // 54px

const
    getAllStyleSheets = (...extraSteps) => R.pipe(stylesheetSelectors.getAllStylesheets, ...extraSteps),
    GlobalstylesDialog = ({
        dimensions,
        stylesheets,
        stylesheetsIdToNameMap,
        site,
        selectedComponent,
        currentStylesheetId,
        siteFonts,
        browserDimensions,
        templateName,
        kind,
        themeSettingsData,
        dispatch,
        currentPageId,
        preferences,
        extendedLeftPanel
    }: DialogProps) => {
        let currentStylesheet;
        const { autoColorMode } = themeSettingsData;

        if (currentStylesheetId) {
            currentStylesheet = getAllStyleSheets(stylesheetSelectors.getStyleById(currentStylesheetId))(stylesheets);
        } else if (R.path(['style', 'type'], selectedComponent) === kind) {
            const globalStyleId = R.path(path.styleGlobalId, selectedComponent);
            currentStylesheet = getAllStyleSheets(stylesheetSelectors.getStyleById(globalStyleId))(stylesheets);
        }

        if (!currentStylesheet) {
            currentStylesheet = getAllStyleSheets(
                stylesheetSelectors.getStylesByType(kind),
                R.head
            )(stylesheets);
        }

        let selectedTab = GlobalStyleTabs.indexOf(currentStylesheet.type);

        if (selectedTab === -1 && currentStylesheet.type === ThemeStyleMenuType) {
            selectedTab = GlobalStyleTabs.indexOf(MenuKind);
        }

        let styleType;

        const onTabSelect = index => {
                selectedTab = index;

                if (autoColorMode && GlobalStyleTabs[selectedTab] === MenuKind) {
                    styleType = ThemeStyleMenuType;
                } else {
                    styleType = GlobalStyleTabs[selectedTab];
                }

                const stylesheetId = R.pipe(
                    stylesheetSelectors.getAllStylesheets,
                    stylesheetSelectors.getStylesByType(styleType),
                    R.head,
                    R.prop('id')
                )(stylesheets);

                dispatch({ type: GLOBAL_STYLES_TAB_SELECTED, payload: { stylesheetId } });
            },
            scrollHeight = dimensions.height - AllLabelsHeight - FooterHeight,
            themeColorsData = getThemeColorsFromStylesheet(stylesheets),
            tabPanelProps = {
                scrollHeight,
                previewScrollHeight: (scrollHeight - PreviewLabelHeight),
                stylesheets,
                stylesheetsIdToNameMap,
                site,
                currentStylesheet,
                selectedComponent,
                siteFonts,
                browserDimensions,
                dispatch,
                themeSettingsData,
                themeColorsData,
                currentPageId,
                preferences
            },
            getColorThemeFooterChildren = () => {
                return (autoColorMode &&
                    <div className={styles.footerColorTheme}>
                        <ICONS.COLOR_THEME_ICON className={styles.footerColorThemeIcon} />
                        <div style={{ "fontSize": "13px" }}>
                            <b>
                                <Msg k="gs.footer.colorTheme.title">
                                    Colour theme is on:
                                </Msg>
                            </b>&nbsp;
                            <Msg k="gs.footer.colorTheme.description">
                                the colors of your template styles are dynamic and directed by your color theme. The previews here are examples of how the colors will often apply to your template styles.
                            </Msg>&nbsp;
                            <LinkButtonCom
                                className={styles.underlinedLink}
                                onClick={() => {
                                    dispatch(showAutoColorLeftPanel());
                                    dispatch(closeDialogAC());

                                    if (extendedLeftPanel && extendedLeftPanel.open) {
                                        dispatch({ type: EXTENDED_PANEL_CLOSE });
                                    }
                                }}
                            >
                                {`msg: gs.footer.colorTheme.manage {Manage colour theme}`}
                            </LinkButtonCom>
                        </div>
                    </div>) || '';
            };

        return (
            <Dialog>
                <DialogBody className={styles.dialogBody}>
                    <div className={styles.globalStyles}>
                        <div className={styles.globalStylesTitle}>
                            <Msg k="gs.heading.title">Template styles</Msg>
                        </div>
                        <div className={styles.globalStylesSubtitle}>
                            <Msg k="gs.heading.subTitle" params={{ templateName }}>
                                {"Changing template styles affects all pages using the template \"{templateName}\"."}
                            </Msg>
                        </div>
                        <Tabs selectedIndex={selectedTab} onSelect={onTabSelect}>
                            <TabList>
                                <Tab><TextGlobalStyelTab /></Tab>
                                <Tab><LinkGlobalStyleTab /></Tab>
                                <Tab><MenuGlobalStyleTab /></Tab>
                                <Tab><ButtonGlobalStyleTab /></Tab>
                                <Tab><TableGlobalStyleTab /></Tab>
                            </TabList>
                            <TabPanel><TextGlobalStyleTabPanel {...tabPanelProps} /></TabPanel>
                            <TabPanel><LinkGlobalStyleTabPanel {...tabPanelProps} /></TabPanel>
                            <TabPanel><MenuGlobalStyleTabPanel {...tabPanelProps} /></TabPanel>
                            <TabPanel><ButtonGlobalStyleTabPanel {...tabPanelProps} /></TabPanel>
                            <TabPanel><TableGlobalStyleTabPanel {...tabPanelProps} /></TabPanel>
                        </Tabs>
                    </div>
                </DialogBody>
                <DialogFooter mctaText="msg: common.done {Done}" mctaHandler={() => dispatch(closeDialog())}>
                    {
                        getColorThemeFooterChildren()
                    }
                </DialogFooter>
            </Dialog>
        );
    },
    updateOnBrowserDimensionsChanged = makeUpdateDialogHeightOnBrowserDimensionsChanged({
        minHeight: GLOBAL_STYLES_DIALIG_MIN_HEIGHT
    }),
    getDialogDimensions = (browserWidth, browserHeight) => ({
        width,
        height: getDialogHeightWithRespectToBrowserHeight({
            browserHeight,
            minHeight: GLOBAL_STYLES_DIALIG_MIN_HEIGHT
        })
    }),
    config = {
        updateAfterOpen: true,
        confFactory: ({ browserWidth, browserHeight }: BrowserDimensions) => {
            const { width, height } = getDialogDimensions(browserWidth, browserHeight);
            return {
                position: getCenterPositionForDialog(width, height, browserWidth, browserHeight),
                modal: true,
                dimensions: { width, height }
            };
        },
        component: GlobalstylesDialog,
        dependsOn: {
            stylesheets: stylesheetsEpic.reducer,
            stylesheetsIdToNameMap: stylesheetsIdToNameMapEpic.reducer,
            site: siteDataEpic.reducer,
            currentStylesheetId: currentlyOpenedStyelsheetId.reducer,
            selectedComponent: selectedComponentReducer,
            siteFonts: siteFontsReducer,
            browserDimensions: browserDimensionsEpic.reducer,
            templateName: templateNameReducer,
            themeSettingsData: colorThemeSiteSettingsEpic.reducer,
            currentPageId: currentPageIdEpic.reducer,
            preferences: preferencesReducer,
            extendedLeftPanel: LeftPanelEpic.reducer
        },
        updateOnBrowserDimensionsChanged
    };

export default config;
