// @ts-nocheck
import { isEmpty } from 'ramda';
import React, { useEffect, useContext } from "react";
import { pure } from 'recompose';
import ComponentRenderer from "../ComponentRenderer";
import WORKSPACE_COMPONENT_KIND from "../../../../components/Workspace/epics/componentsEval/WORKSPACE_COMPONENT_KIND";
import forwardTo from "../../../../redux/forwardTo";
import type { Components, ComponentsDependencies } from '../../../../redux/modules/children/workspace/flowTypes';
import type { Attachments } from "../../../../components/Workspace/epics/componentAttachements/flowTypes";
import { memoGetUpdatedLayoutBasedOnOptions } from "../../../../components/ModernLayouts/getUpdatedLayoutBasedOnOptions";
import { ModernSectionLayoutView } from "./LayoutRenderer";
import { UPDATE_MHF_COMPONENTS_POSITION } from "../../../../components/ModernLayouts/actionTypes";
import { findCmpkindIsExpandableInMHF } from '../../../../components/ModernLayouts/preview_utils';
import {
    BUTTON, STRIP, WEBSHOP_POLICIES,
    WEBSHOP_PAYMENT_METHODS, isContactComponentKind
} from "../../../../components/oneweb/componentKinds";
import { getWebShopLayoutWithIds, getWebShopStripId } from '../../../../components/ModernLayouts/layoutsData/webshopMHFDataUtils';

type Props = {
    attachments: Attachments,
    components: Components,
    componentsProps: ObjectMap,
    componentsDependencies: ComponentsDependencies,
    dispatch: Dispatch,
    editModeComponentId: string | null,
    subscriptionType: string,
    selectedComponentsIds: Array<string>
}

const defaultComponentsExtension = {};

const RendererContext = React.createContext(null);
const SectionContext = React.createContext(null);
const DefaultLayoutState = { mhfCmpsData: {}, cDimensions: {} };

class MHFCmpsStateClass {
    constructor() {
        this._state = DefaultLayoutState;
        this.timeoutKey = null;
        this.dispatch = () => {};
    }
    get state() {
        return this._state;
    }
    update(value) {
        const { cmpMHFData, cmpDimension, componentId } = value;
        clearTimeout(this.timeoutKey);
        this._state = {
            ...this.state,
            cDimensions: {
                ...this.state.cDimensions,
                [componentId]: cmpDimension,
            },
            mhfCmpsData: {
                ...this.state.mhfCmpsData,
                [componentId]: cmpMHFData
            },
        };
        setTimeout(() => {
            const { mhfCmpsData, cDimensions } = this.state;
            if (isEmpty({ ...cDimensions, ...mhfCmpsData })) {
                return;
            }
            this.dispatch({
                type: UPDATE_MHF_COMPONENTS_POSITION,
                payload: this.state,
                amendToSelf: true
            });
            this.reset();
        });
    }
    reset() {
        clearTimeout(this.timeoutKey);
        this._state = DefaultLayoutState;
    }
    setDispatchFn(fn) {
        this.dispatch = fn;
    }
}

const MHFCmpsState = new MHFCmpsStateClass();

const UPDATE_MHF_REACT_STATE = 'UPDATE_MHF_REACT_STATE',
    RESET_MHF_REACT_STATE = 'RESET_MHF_REACT_STATE';

const ModernSectionComponentsView = (({ component, isModernSection = false,
    children = null, isOnlyStrip }) => {
    const { componentsProps,
            componentsDependencies,
            editModeComponentId,
            subscriptionType,
            selectedComponentsIds,
            componentsMapExtension,
            dispatch } = React.useContext(RendererContext),
        { id: componentId } = component,
        componentExtension = componentsMapExtension[componentId] || defaultComponentsExtension,
        componentDependencies = componentsDependencies[component.kind],
        renderProps = componentsProps[componentId],
        inSelectedMode = selectedComponentsIds.includes(componentId),
        inEditMode = editModeComponentId === componentId;

    let mhfCmpStyle = {
        left: 'unset',
    };

    if (!isModernSection) {
        mhfCmpStyle = {
            top: 'unset'
        };
    }
    if (findCmpkindIsExpandableInMHF(component.kind)) {
        mhfCmpStyle = { width: 'auto' };
    }
    if (component.kind === WEBSHOP_POLICIES || component.kind === WEBSHOP_PAYMENT_METHODS) {
        mhfCmpStyle = { width: 'auto', height: 'auto' };
    }
    if (component.kind === STRIP) {
        mhfCmpStyle = {
            width: '100%',
        };
        if (!isOnlyStrip) {
            mhfCmpStyle = {
                ...mhfCmpStyle,
                height: 'auto',
            };
        }
    }
    if (component.kind === BUTTON) {
        const { minDimensions: { width: minWidth } = {} } = componentExtension;
        mhfCmpStyle = { ...mhfCmpStyle, minWidth };
    }
    if (isContactComponentKind(component.kind)) {
        mhfCmpStyle = {
            height: 'auto',
            width: 'auto',
        };
    }

    return <ComponentRenderer
        key={componentId}
        inEditMode={inEditMode}
        inSelectedMode={inSelectedMode}
        component={component}
        componentExtension={componentExtension}
        componentId={componentId}
        componentDependencies={componentDependencies}
        renderProps={renderProps}
        modernCmp={!isModernSection}
        dispatch={forwardTo(WORKSPACE_COMPONENT_KIND, componentId, dispatch)}
        subscriptionType={subscriptionType}
        mhfCmpStyle={mhfCmpStyle}
    >
        {children}
    </ComponentRenderer>;
});

const RenderSectionView = ({ section }) => {
    const { modernCmpsMap } = useContext(RendererContext);
    const { newLayout: updatedLayout } = memoGetUpdatedLayoutBasedOnOptions(section, modernCmpsMap),
        sectionContext = {
            section,
            mhfLayout: updatedLayout,
        };

    if (!section) {
        return null;
    }

    return (
        <SectionContext.Provider value={sectionContext}>
            <ModernSectionComponentsView
                component={section}
                isModernSection
            >
                <ModernSectionLayoutView
                    section={section}
                    layout={updatedLayout}
                />
            </ModernSectionComponentsView>
        </SectionContext.Provider>
    );
};

const RenderStripView = () => {
    const { modernCmpsMap } = useContext(RendererContext);
    const webshopStripId = getWebShopStripId(modernCmpsMap);
    if (!webshopStripId) {
        return null;
    }
    const webshopStrip = modernCmpsMap[webshopStripId];
    const mhfLayout = getWebShopLayoutWithIds(modernCmpsMap);
    if (!mhfLayout) {
        return null;
    }
    const section = { id: webshopStrip.id, height: webshopStrip.height };
    const sectionContext = {
        section,
        mhfLayout,
    };

    return (
        <SectionContext.Provider value={sectionContext}>
            <ModernSectionComponentsView
                component={webshopStrip}
                isOnlyStrip
            >
                <ModernSectionLayoutView
                    section={section}
                    layout={mhfLayout}
                />
            </ModernSectionComponentsView>
        </SectionContext.Provider>
    );
};

const ModernSectionsRenderer = pure((props: Props) => {
    const { modernSections, dispatch, webshopFooterCmpsMap } = props,
        MHFContext = props;
    useEffect(() => {
        MHFCmpsState.setDispatchFn(dispatch);
    }, []);

    useEffect(() => {
        const mhfCmpsState = MHFCmpsState.state;
        const { cDimensions, mhfCmpsData } = mhfCmpsState;
        if (isEmpty({ ...cDimensions, ...mhfCmpsData })) {
            return;
        }
        dispatch({
            type: UPDATE_MHF_COMPONENTS_POSITION,
            payload: mhfCmpsState,
            amendToPrevious: true
        });
        MHFCmpsState.reset();
    }, [props.mhfCmpsState]);

    if (webshopFooterCmpsMap && !isEmpty(webshopFooterCmpsMap)) {
        return <RendererContext.Provider value={{
            ...MHFContext,
            modernCmpsMap: webshopFooterCmpsMap
        }}
        >
            <div>
                <RenderStripView />
            </div>
        </RendererContext.Provider>;
    }

    if (!modernSections || !modernSections.length) {
        return null;
    }

    return <RendererContext.Provider value={MHFContext}>
        <div>{
            modernSections.map((section, i) => {
                return <RenderSectionView key={i} section={section} />;
            })
        }</div>
    </RendererContext.Provider>;
});

export {
    SectionContext,
    ModernSectionComponentsView,
    RendererContext,
    UPDATE_MHF_REACT_STATE,
    RESET_MHF_REACT_STATE,
    MHFCmpsState,
    ModernSectionsRenderer
};
