/** Note:
 * Premium features doc update is blocked from the frontend for security reasons
 * (Any starter user may add premium component and can remove from the frontend if we allow modification from the frontend)
 *
 * Sometimes we may need to remove blocking of update to handle customer edge cases.
 * Added support in the frontend whereas we need to remove filtering from the backend and deploy to an environment to make it work.
 * Search: isDocToFilter
 */
const
    PAGE_DATA_COMPONENT = 'web.data.components.Page',
    TEMPLATE_DATA_COMPONENT = 'web.data.components.Template',
    STYLESHEET_DATA = 'web.data.styles.Stylesheet',
    SITE_SETTINGS = 'web.data.SiteSettings',
    SITE = 'web.data.Site',
    PREMIUMFEATURES = 'web.data.PremiumFeatures',
    componentToTypeMap = {
        [PAGE_DATA_COMPONENT]: 'page',
        [TEMPLATE_DATA_COMPONENT]: 'template',
        [STYLESHEET_DATA]: 'stylesheet',
        [SITE_SETTINGS]: 'siteSettings',
        [SITE]: 'site',
        [PREMIUMFEATURES]: 'premiumFeatures'
    },
    changeToUpdateSiteDocumentsFormat = (jsonToDbData: string): Record<string, any> | null => {
        const docs = {};
        if (jsonToDbData) {
            const jsonToDbDataParsed = JSON.parse(jsonToDbData);
            if (jsonToDbDataParsed && componentToTypeMap[jsonToDbDataParsed.type]) {
                docs[componentToTypeMap[jsonToDbDataParsed.type]] = jsonToDbDataParsed;
                return docs;
            }
        }
        return null;
    };

export {
    changeToUpdateSiteDocumentsFormat,
    componentToTypeMap,
    SITE
};
