import type { FileManagerState } from "../flowTypes";

export default (state: FileManagerState, action: any) => {
    const
        { payload: { entries } } = action,
        // sometimes server responds with no contentType set ...
        freeOneComVideos = entries.map(data => ({ contentType: 'X', ...data }));

    return { ...state, freeOneComVideos, resourcesLoadProgress: false };
};
