import React from 'react';
import cx from 'classnames';
import styles from './Common.css';
import injectIntl from "../intl/injectIntl";

export type LabelProps = {
    children: MsgJointInput | React.ReactNode;
    intl: Intl;
    isValue?: boolean;
    style?: Record<string, any>;
    disabled?: boolean;
    className?: string;
}

export default injectIntl(({ isValue, disabled, style, children, className, intl }: LabelProps) => {
    const
        labelClassName = cx(
            styles.label,
            {
                [styles.valueLabel]: isValue,
                [styles.disabled]: disabled
            },
            className
        ),
        label = !isValue ? intl.msgJoint(children as MsgJointInput) : children;

    return (
        <div className={labelClassName} style={style}>{label as string}</div>
    );
});
