import { reducerDefaultData as featuredProductsDefaultData } from '../../FeaturedProducts/reducer/reducerDefaultData';
import { ViewDetailsButtonPostions } from '../../FeaturedProducts/constants';

export const reducerDefaultData = {
    ...featuredProductsDefaultData,
    cropImages: true,
    productsPerRow: 1,
    width: ViewDetailsButtonPostions.RIGHT.width,
    height: ViewDetailsButtonPostions.RIGHT.height,
};
