type IntersectionType<T extends Record<string, any>, K extends Record<string, any>> = {
    [P in keyof T & keyof K]: T[P]
}

/**
 * Creates object which contains intersection of object properies
 * @param {Object} a
 * @param {Object} b
 */
const intersect = <T extends Record<string, any>, K extends Record<string, any>>(a: T, b: K): IntersectionType<T, K> => {
    return Object.keys(a)
        .filter(prop => b[prop] === a[prop])
        .reduce((obj, prop) => {
            return {
                ...obj,
                [prop]: a[prop]
            };
        }, {} as IntersectionType<T, K>);
};

type DifferenceType<T extends Record<string, any>, K extends Record<string, any>> = {
    [P in Exclude<keyof T, keyof K>]: T[P]
}

/**
 * Creates object which contains difference of object properties
 * @param {Object} a
 * @param {Object} b
 */
const difference = <T extends Record<string, any>, K extends Record<string, any>>(a: T, b: K): DifferenceType<T, K> => {
    return Object.keys(a)
        .filter(prop => b[prop] !== a[prop])
        .reduce((obj, prop) => {
            return {
                ...obj,
                [prop]: a[prop]
            };
        }, {} as DifferenceType<T, K>);
};

type ExceptResult<T extends Record<string, any>, K extends (keyof T)[]> = Pick<T, Exclude<keyof T, K[number]>>;

/**
 * Creates object without selected properties
 */
const except = <T extends Record<string, any>, K extends (keyof T)[]>(properties: K) => (a: T) => {
    return Object.keys(a)
        .filter((prop) => properties.indexOf(prop) < 0)
        .reduce((obj, prop) => {
            return {
                ...obj,
                [prop]: a[prop]
            };
        }, {} as ExceptResult<T, K>);
};

type IncludeOnlyProperties<T extends Record<string, any>, K extends keyof T> = {
    [P in K]: T[P]
}

/**
 * Creates object from object includes only selected properties
 */
const include = <T extends Record<string, any>, K extends keyof T>(properties: K[]) => (a: T): IncludeOnlyProperties<T, K> => {
    return Object.keys(a)
        .filter(prop => properties.indexOf(prop as K) >= 0)
        .reduce((obj, prop) => {
            return {
                ...obj,
                [prop]: a[prop]
            };
        }, {} as IncludeOnlyProperties<T, K>);
};

export {
    intersect,
    difference,
    except,
    include
};
