import type { Scope } from "./flowTypes";
import { getDefaultReducerState } from "../../../../redux/makeReducer/index";
import * as interactionModes from "./userInteractionMutations/interactionModes";
import * as selectedComponentsIds from "./selectedComponentsIds";
import { zeroBBox } from "../../../../utils/componentsMap/index";
import { snappingDecosDefaultState } from "../snappingDecos/index";
import { _componentsDependenciesReducer } from "./_componentsDependenciesReducer";

const
    NEW_COMPONENT_DISPLACEMENT = 20,
    scopeDefaultState: Scope = {
        attachments: {},
        componentsAdjustmentData: {},
        relationsMap: {},
        relationNeededFlag: false,
        currentPageId: '',
        componentsAdjustmentDataCache: {},
        componentsDependencies: getDefaultReducerState(_componentsDependenciesReducer),
        stateBeforeTransientChanges: undefined,
        userInteraction: {
            mode: interactionModes.IDLE,
            payload: null
        },
        sectionInsertion: {
            inProgress: false,
        },
        addingComponent: {
            componentAddedByClickCounter: 0,
            componentsAddedByPasteCounter: 1,
            componentConfigurationInProgress: false,
            componentConfigurationData: {}
        },
        dragAndDrop: {
            componentAddedCounter: 0,
            inProgress: false,
            configurationData: {}
        },
        movingComponents: {},
        selectedComponentsIds: selectedComponentsIds.defaultState,
        isPageMode: true,
        template: null,
        contentBBox: zeroBBox,
        templateWidth: 0,
        snappingState: snappingDecosDefaultState,
        pushDownHappened: false,
        inserterSectionOrBlockData: null,
        templateOffset: { x: 50, y: 50 },
        mhfCmpsData: {}
    },
    componentsEvalDefaultState = {
        scope: scopeDefaultState,
        state: {
            componentsMap: {},
            componentsMapExtension: {}
        }
    };

const PASTE_OFFSET_WHEN_THERE_IS_NOT_ENOUGH_SECTION_HEIGHT = 15;

export {
    componentsEvalDefaultState,
    NEW_COMPONENT_DISPLACEMENT,
    PASTE_OFFSET_WHEN_THERE_IS_NOT_ENOUGH_SECTION_HEIGHT
};
