import React from "react";
export const SvgFacebookCircleFillLight = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <circle cx={24} cy={24} r={24} fill="#ffffff" />
            <path
                fill="#3c3c3c"
                d="M28.314 24.63l.572-3.741h-3.578V18.46c0-1.023.5-2.021 2.102-2.021h1.627v-3.186S27.561 13 26.149 13c-2.947 0-4.873 1.792-4.873 5.037v2.852H18v3.742h3.276v9.046a12.955 12.955 0 004.032 0v-9.046h3.006"
            />
        </g>
    </svg>
);
