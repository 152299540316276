import React from 'react';
import { Dispatch } from "redux";
import ModernLayoutItem from '../../LeftPanel/screens/ModernLayoutItem';
import ModernLayoutComponentsToggle from '../../LeftPanel/screens/ModernLayoutComponentsToggle';
import { getFooterLayoutsMap } from '../../../layoutsData/footerLayouts';
import { Intl } from '../../../../../view/intl/index';
import * as styles from '../onBoardingPanel.css';
import type { Stylesheets } from '../../../../Workspace/epics/stylesheets/flowTypes';
import type { TemplateComponent } from '../../../../oneweb/Template/flowTypes';
import type { SectionComponent } from '../../../../oneweb/Section/flowTypes';
import type { LeftPanelEpicState, OnBoardingPanelEpicState } from '../../../flowTypes';
import type { WebShopMHFEpicState } from "../../../../oneweb/WebShopMHF/flowTypes";

type Props = {
    intl: Intl,
    dispatch: Dispatch,
    leftPanel: LeftPanelEpicState,
    onBoardingPanel: OnBoardingPanelEpicState,
    template: TemplateComponent,
    componentsEval: Object,
    globalVariables: Object,
    styleSheets: Stylesheets,
    footerSection: SectionComponent,
    webshopMHFData: WebShopMHFEpicState,
};

const OnBordingToggleFooter = ({
    intl,
    leftPanel,
    onBoardingPanel,
    template,
    componentsEval,
    globalVariables,
    styleSheets,
    dispatch,
    footerSection,
    webshopMHFData,
}: Props) => {
    const footerLayouts = getFooterLayoutsMap(webshopMHFData),
        activatedFooterId = onBoardingPanel.activatedFooterId || '',
        layoutItem = footerLayouts[activatedFooterId],
        shouldAmendToActivation = true,
        footerMessage = 'msg: modernLayout.leftPanel.footerToggleDisable {You have to have at least one element in your footer}';
    return <React.Fragment>
        <div className={styles.layoutTitle}>
            {'"' + intl.msgJoint(layoutItem.title) + '"'}
        </div>
        <div className={styles.layoutCmpToggleContainer}>
            <ModernLayoutItem
                layoutItem={layoutItem}
                leftPanel={leftPanel}
                template={template}
                componentsEval={componentsEval}
                globalVariables={globalVariables}
                styleSheets={styleSheets}
                dispatch={dispatch}
                section={footerSection}
                MHFStoredLayoutsData={{}}
                isForceUpdateRequired
                webshopMHFData={webshopMHFData}
            />
            <ModernLayoutComponentsToggle
                intl={intl}
                dispatch={dispatch}
                section={footerSection}
                disableToggleMsg={footerMessage}
                shouldAmendToActivation={shouldAmendToActivation}
            />
        </div>
    </React.Fragment>;
};

export default OnBordingToggleFooter;
