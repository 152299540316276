import type { Gradient } from "../../../../mappers/background/flowTypes";
import type { Color } from "../../../../mappers/flowTypes";
import { DEFAULT_COLOR } from "../../ColorProperty/constants";

export const getDefaultGradientColor = (solidColor: null | undefined | Color): Color => {
    if (!solidColor) return DEFAULT_COLOR;

    let lightness = solidColor[3] - 0.2;
    if (lightness < 0.4) lightness += 0.4;

    return [solidColor[0], solidColor[1], solidColor[2], lightness, solidColor[4]];
};

export default (gradient: Gradient) => {
    let
        color: Color | null = null,
        degree = 0,
        fadePoint = 0;

    if (gradient) {
        ({ color, fadePoint, degree } = gradient);
    }

    return { color, fadePoint, degree };
};
