import { formatMessage } from '../../../view/intl/CurrentLocale';
import { memoMaxOne } from "../../../../utils/memo";
import { getLocaleCookie } from "../../Locale/cookie";

export default memoMaxOne(() => {
    const
        option1 = formatMessage({ id: "component.contactForm.fieldOptions.option1", defaultMessage: "Option 1" }),
        option2 = formatMessage({ id: "component.contactForm.fieldOptions.option2", defaultMessage: "Option 2" }),
        option3 = formatMessage({ id: "component.contactForm.fieldOptions.option3", defaultMessage: "Option 3" });
    return {
        name: {
            name: formatMessage({ id: "common.name", defaultMessage: "Name" }),
            inputType: "text",
            values: null,
            isRequired: true,
            hasCustomErrMsg: false,
            errorMessage: formatMessage({
                id: "component.contactForm.fieldErrorMessage.name",
                defaultMessage: "Please enter a valid name"
            })
        },
        subject: {
            name: formatMessage({ id: "component.contactForm.fields.subject", defaultMessage: "Subject" }),
            inputType: "text",
            values: null,
            isRequired: true,
            hasCustomErrMsg: false,
            errorMessage: formatMessage({
                id: "component.contactForm.fieldErrorMessage.subject",
                defaultMessage: "Please enter a valid subject"
            })
        },
        company: {
            name: formatMessage({ id: "component.contactForm.fields.company", defaultMessage: "Company" }),
            inputType: "text",
            values: null,
            isRequired: true,
            hasCustomErrMsg: false,
            errorMessage: formatMessage({
                id: "component.contactForm.fieldErrorMessage.companyName",
                defaultMessage: "Please enter a valid company name"
            })
        },
        text: {
            name: formatMessage({ id: "component.contactForm.fields.text", defaultMessage: "Short Text" }),
            inputType: "text",
            values: null,
            isRequired: true,
            hasCustomErrMsg: false,
            errorMessage: formatMessage({
                id: "component.contactForm.fieldErrorMessage.text",
                defaultMessage: "Please enter a valid text"
            })
        },
        email: {
            name: formatMessage({ id: "common.email", defaultMessage: "Email" }),
            inputType: "email",
            values: null,
            isRequired: true,
            hasCustomErrMsg: false,
            errorMessage: formatMessage({
                id: "component.contactForm.fieldErrorMessage.emailAddress",
                defaultMessage: "Please enter a valid email address"
            })
        },
        phone: {
            name: formatMessage({ id: "common.phone", defaultMessage: "Phone" }),
            inputType: "tel",
            values: null,
            isRequired: true,
            hasCustomErrMsg: false,
            errorMessage: formatMessage({
                id: "component.contactForm.fieldErrorMessage.phoneNumber",
                defaultMessage: "Please enter a valid phone number"
            })
        },
        message: {
            name: formatMessage({ id: "component.contactForm.fields.message", defaultMessage: "Long Text" }),
            inputType: "textarea",
            values: null,
            isRequired: true,
            hasCustomErrMsg: false,
            errorMessage: formatMessage({
                id: "component.contactForm.fieldErrorMessage.message",
                defaultMessage: "Please enter a valid message"
            })
        },
        website: {
            name: formatMessage({ id: "component.contactForm.fields.website", defaultMessage: "Website" }),
            inputType: "url",
            values: null,
            isRequired: true,
            hasCustomErrMsg: false,
            errorMessage: formatMessage({
                id: "component.contactForm.fieldErrorMessage.website",
                defaultMessage: "Please enter a valid website"
            })

        },
        number: {
            name: formatMessage({ id: "component.contactForm.fields.number", defaultMessage: "Number" }),
            inputType: "number",
            values: null,
            isRequired: true,
            hasCustomErrMsg: false,
            errorMessage: formatMessage({
                id: "component.contactForm.fieldErrorMessage.number",
                defaultMessage: "Please enter a valid number"
            })
        },
        checkbox: {
            name: formatMessage({ id: "component.contactForm.fields.checkboxes", defaultMessage: "Checkboxes" }),
            inputType: "checkbox",
            values: [option1, option2, option3],
            isRequired: true,
            hasCustomErrMsg: false,
            errorMessage: formatMessage({
                id: "component.contactForm.fieldErrorMessage.radioButton",
                defaultMessage: "Please select at least 1 option"
            })
        },
        radio: {
            name: formatMessage({ id: "component.contactForm.fields.radiobuttons", defaultMessage: "Radio buttons" }),
            inputType: "radio",
            values: [option1, option2],
            isRequired: true,
            hasCustomErrMsg: false,
            errorMessage: formatMessage({
                id: "component.contactForm.fieldErrorMessage.option",
                defaultMessage: "Please select an option"
            })
        },
        fileupload: {
            name: formatMessage({ id: "component.contactForm.fields.fileupload", defaultMessage: "File upload" }),
            inputType: "all",
            values: null,
            isRequired: true,
            hasCustomErrMsg: false,
            errorMessage: formatMessage({
                id: "component.contactForm.fieldErrorMessage.fileupload",
                defaultMessage: "Please upload a file"
            })
        },
        dropdown: {
            name: formatMessage({ id: "component.contactForm.fields.dropdown", defaultMessage: "Dropdown" }),
            inputType: "dropdown",
            values: [option1, option2, option3],
            isRequired: true,
            hasCustomErrMsg: false,
            errorMessage: formatMessage({
                id: "component.contactForm.fieldErrorMessage.option",
                defaultMessage: "Please select an option"
            })
        },
        isCaptchaEnabled: true,
        captchaLang: getLocaleCookie(),
        submitBtnText: formatMessage({ id: "component.contactForm.submitButtonText", defaultMessage: "Send" }),
        successMessage: formatMessage({
            id: "component.contactForm.defaultSuccessMessage",
            defaultMessage: "Thank you."
        }),
        errorMessage: formatMessage({
            id: 'component.ContactForm.errorMessage',
            defaultMessage: 'Something went wrong. Please try again later.'
        }),
        newOption: formatMessage({ id: "component.contactForm.newOption", defaultMessage: "New option" })
    };
});

export const getDefaultSubject = (pageName: string, domain: string) => [
    'msg: component.contactForm.mailSubject {New message via {domain} - {pageName}}',
    { domain, pageName }
];
