import React from 'react';

import styles from './LinkPageForm.css';
import DialogCheckBox from '../../../../view/common/dialogs/baseDialog/DialogCheckBox';
import { Msg } from '../../../../view/intl';
import type { LinkPageFormIsHiddenCheckBoxProps } from "../flowTypes";
import Icons from '../../../../view/Icons';
import HorizontalSpacer from '../../../../view/common/HorizontalSpacer';

type Props = LinkPageFormIsHiddenCheckBoxProps & {
    dispatch: Dispatch;
};

export default ({ isChecked, isHiddenToggleAction, dispatch }: Props) => (
    <div>
        <div className={styles.checkBoxWrapper}>
            <DialogCheckBox
                label="msg: doNotPublishLink {Do not publish link}"
                isChecked={isChecked}
                onClick={() => dispatch({ type: isHiddenToggleAction })}
                containerClassName={styles.formCheckBox}
            />
            <HorizontalSpacer x={5} />
            <Icons.LOCKED_PAGE className={styles.lockedPage} />
        </div>
        <div>
            <Msg k="linkWillNotBePublished" className={styles.isHiddenCheckBoxMessage}>
                The link will not be published and will only exist in Website Builder.
            </Msg>
        </div>
    </div>
);

