import React from 'react';
import styles from './SectionLinkForm.css';
import { Msg } from '../../../../view/intl';
import { closeDialog } from '../../../App/actionCreators';
import { OPEN_ITEM_PANEL } from '../../../Panel/actionTypes';
import { SectionsKind } from '../../../Panel/configs/constants';

type Props = {
    dispatch: Dispatch;
};

export default (props: Props) => {
    const
        { dispatch } = props,
        onClick = () => {
            dispatch(closeDialog());
            dispatch({
                type: OPEN_ITEM_PANEL,
                payload: { componentKind: SectionsKind }
            });
        };

    return (
        <div>
            <Msg
                k="newPage.sectionLink.addNewSection"
                onClick={onClick}
                className={styles.linkToFileOrPage}
            >Add new section</Msg>
        </div>
    );
};
