import Resource from "./Resource";

const SortColumns = {
    NAME: 'name',
    TYPE: 'type',
    SIZE: 'size',
    ADDED: 'added'
};

const SortDirections = { ASC: 1, DESC: -1 };

const sortFunctions = {
    [SortColumns.NAME]: (a: Resource, b: Resource): number => a.getName().localeCompare(b.getName()),
    [SortColumns.TYPE]: (a: Resource, b: Resource): number =>
        a.getRenderContentType().localeCompare(b.getRenderContentType()),
    [SortColumns.SIZE]: (a: Resource, b: Resource): number => a.getRawSize() - b.getRawSize(),
    [SortColumns.ADDED]: (a: Resource, b: Resource): number => a.getRawCreated() - b.getRawCreated()
};

const FmGridSortOptionValue = {
    NAME: 'name',
    NEWEST: 'newest',
    OLDEST: 'oldest',
};

export { SortColumns, SortDirections, sortFunctions, FmGridSortOptionValue };
