import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
    (theme) => ({
        root: {
            [theme.breakpoints.down('md')]: {
                border: "none",
                margin: "-16px -24px",
            }
        },
        header: {
            fontSize: "22px",
            lineHeight: "30px",
            padding: "16px 24px"
        },
        accordionRoot: {
            padding: "24px !important",
            borderTop: `1px solid ${theme.palette.custom.colorGray_ed}`,
        },
        accordionSummaryContainer: {
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
        },
        summaryHeaderContainer: {
            display: "flex",
        },
        seeMore: {
            fontSize: "14px",
            lineHeight: "20px",
            color: theme.palette.custom.colorScienceBlue,
            paddingLeft: "16px",
            whiteSpace: "nowrap",
        },
        pending: {
            padding: "8px 15px",
            background: theme.palette.custom.colorLavenderWeb,
            border: `1px solid ${theme.palette.custom.colorLinkWater}`,
            borderRadius: "4px",
            whiteSpace: "nowrap",
        },
        accordionTitle: {
            fontSize: "16px",
            lineHeight: "24px",
            paddingLeft: "14px",
        },
        accordionDetailsRoot: {
            [theme.breakpoints.down('sm')]: {
                padding: "0px 47px 24px !important",
            },
            flexDirection: "column",
            padding: "0px 62px 24px !important",
            "& > *:not(:first-child)": {
                marginTop: "22px",
            },
        },
        accordionDetailsHeader: {
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "20px",
        },
        taskReasonWrapper: {
            "& > *:not(:first-child)": {
                marginTop: "22px",
            },
        },
        accordionDetailsDescription: {
            fontSize: "14px",
            lineHeight: "22px",
            "&:not(:first-child)": {
                marginTop: "8px",
            },
        },
        accordionIssuesContainer: {
            paddingLeft: "12px",
        },
        accordionIssuesList: {
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "22px",
            paddingBottom: "4px",
            '&:last-child': {
                paddingBottom: "0px",
            }
        },
        accordionIssuesSubContainer: {
            listStyleType: "none",
            paddingTop: "2px",
        },
        accordionIssuesSubList: {
            fontSize: "14px",
            lineHeight: "22px",
            paddingBottom: "4px",
            wordBreak: "break-word",
            '&:last-child': {
                paddingBottom: "0px",
            },
        },
        ctaButton: {
            padding: "0 30px",
            fontSize: "14px",
            fontWeight: 500,
            minHeight: "40px",
            lineHeight: "18px"
        },
        mobileContainer: {
            padding: "24px "
        },
        expandIcon: {
            transform: "rotate(0deg)",
            transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        expandIconExpanded: {
            transform: "rotate(180deg)",
        },
    }),
    { name: "MarketgooSiteReport" }
);
