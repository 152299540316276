import type { ClassicCaptionStyleType, TemplateCaptionStyleType, CustomCaptionStyleType } from './flowTypes';

export const
    CLASSIC: ClassicCaptionStyleType = 'CLASSIC',
    TEMPLATE: TemplateCaptionStyleType = 'TEMPLATE',
    CUSTOM: CustomCaptionStyleType = 'CUSTOM',
    CaptionStyles = {
        [CLASSIC]: CLASSIC,
        [TEMPLATE]: TEMPLATE,
        [CUSTOM]: CUSTOM,
    };
