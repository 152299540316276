// @ts-nocheck
import { memoMaxOne } from '../../utils/memo';
import { getComponentZIndex } from '../components/Workspace/zIndex';
import type {
    ComponentBase
} from '../redux/modules/children/workspace/flowTypes';
import { ContainerKinds } from './containerKinds';

export function isContainer(kind) {
    return ContainerKinds[kind];
}

export const getAboveComponent = memoMaxOne((component1: ComponentBase, component2: ComponentBase) => {
    const
        index1: number = getComponentZIndex(component1),
        index2: number = getComponentZIndex(component2);

    return index1 < index2 ? component2 : component1;
});
