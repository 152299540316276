import React from 'react';

import makeAppStandardErrorDialog from "../../../../../App/dialogs/makeAppStandardErrorDialog";
import { UnsavedChangesDialogCom } from "../../../../../presentational/UnsavedChangesDialog/UnsavedChangesDialogCom";
import {
    cancelPageInfoChangesAction,
    dontSavePageInfoUnsavedChangesAction,
    savePageInfoUnsavedChangesAction
} from "../actions";

// eslint-disable-next-line max-len
const MSG = 'msg: pageInfoDialog.unsavedChnagesWarning {Please save the changes before continuing, otherwise these changes will be lost.}';
// eslint-disable-next-line max-len
const MSG_2 = 'msg: pageInfoDialog.unsavedChangesWarning_2 {Please save changes, otherwise these changes will be lost.}';

export type PageInfoUnsavedChangesDialogProps = {
    pageRefId: string,
    isSaveDisabled?: boolean,
    unsavedChangesDialogType?: string
};

export const UNSAVED_CHANGES_DIALOG_TYPE_2 = "UNSAVED_CHANGES_DIALOG_TYPE_2";

type Props = PageInfoUnsavedChangesDialogProps & {
    dispatch: Dispatch,
};

export default makeAppStandardErrorDialog((
    { pageRefId, isSaveDisabled, dispatch, unsavedChangesDialogType }: Props
) => {
    const
        commonDialogProps = {
            msg: MSG,
            onSaveClick: () => dispatch(savePageInfoUnsavedChangesAction(pageRefId)),
            onCancelClick: () => dispatch(cancelPageInfoChangesAction()),
            isSaveDisabled
        },
        type1DialogProps = {
            onDontSaveClick: () => dispatch(dontSavePageInfoUnsavedChangesAction())
        },
        type2DialogProps = {
            msg: MSG_2
        };

    let props;
    if (unsavedChangesDialogType === UNSAVED_CHANGES_DIALOG_TYPE_2) {
        props = {
            ...commonDialogProps,
            ...type2DialogProps
        };
    } else {
        props = {
            ...commonDialogProps,
            ...type1DialogProps
        };
    }

    return (
        <UnsavedChangesDialogCom {...props} />
    );
});
