import { formatMessage } from '../../../../../view/intl/CurrentLocale';
import { getAppConfig } from "../../../../App/epics/appConfig/appConfig";
import * as actionTypes from "../actionTypes";

const
    height = 675,
    width = 950,
    top = (window as any).top.outerHeight / 2 + (window as any).top.screenY - (height / 2),
    left = (window as any).top.outerWidth / 2 + (window as any).top.screenX - (width / 2),
    windowName = "Google My Business",
    windowFeatures = `height=${height},width=${width},scrollbars=yes,resizable=yes, top=${top}, left=${left}`; // scrollbars=yes is required for some browsers (like FF & IE)

const getOrigin = () => {
    const location = window.location;

    if (location.origin) {
        return location.origin;
    }

    // IE and some other browsers don't have window.location.origin
    // See: http://tosbourn.com/a-fix-for-window-location-origin-in-internet-explorer/
    const { protocol, hostname, port } = location;

    return protocol + "//" + hostname + (port ? ':' + port : '');
};

const onLoginComplete = dispatch => event => {
    const data = event.data;

    if (event.origin !== getOrigin() || data.purpose !== 'gmb-login') {
        return;
    }

    if (data.status === 'success') {
        dispatch({ type: actionTypes.GOOGLE_AUTH_SUCCESS });
    } else {
        dispatch({
            type: actionTypes.GOOGLE_AUTH_FAILURE,
            payload: {
                errorCode: data.errorCode
            }
        });
    }
};

const OpenGoogleAuthPage = (
    { dispatch }: { dispatch: Dispatch }
) => {
    if (!window.gmbOAuth2EventListenerAdded) {
        window.addEventListener('message', onLoginComplete(dispatch), false);
        window.gmbOAuth2EventListenerAdded = true;
    }

    const host = window.location.host;

    const
        appConfig = getAppConfig(),
        gmbAuthUrlBasic = `${appConfig.oneWeb.gmb.gmbOAuth2PopupUrl}&redirect_uri=https://${host}/gmb/oAuth2RedirectUrl`,
        localeCodeForGoogle = formatMessage({ id: 'common.GoogleLocaleCode', defaultMessage: 'en' });

    let gmbAuthUrl = gmbAuthUrlBasic + '&hl=' + encodeURIComponent(localeCodeForGoogle);
    (window as any).open(gmbAuthUrl, windowName, windowFeatures).focus();
};

export {
    OpenGoogleAuthPage
};
