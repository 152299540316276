import { ls as LocalStorage } from "../../utils/localStorage";
import { AI_TOOLTIP_SEEN } from "../oneweb/Text/epics/AITextEpic/actionTypes";

const HEADER_SECTON_TOOLTIP_SHOWN = 'headerSectionTooltipShown',
    FOOTER_SECTON_TOOLTIP_SHOWN = 'footerSectionTooltipShown';

const setHeaderSectionTooltipShownInLS = () => {
    return LocalStorage.set(HEADER_SECTON_TOOLTIP_SHOWN, true, true);
};

const getHeaderSectionTooltipShownInLS = () => {
    return LocalStorage.get(HEADER_SECTON_TOOLTIP_SHOWN, true);
};

const setFooterSectionTooltipShownInLS = () => {
    LocalStorage.set(FOOTER_SECTON_TOOLTIP_SHOWN, true, true);
};

const getFooterSectionTooltipShownInLS = () => {
    return LocalStorage.get(FOOTER_SECTON_TOOLTIP_SHOWN, true);
};

export const hideAITooltipAC = () => ({
    type: AI_TOOLTIP_SEEN
});

export const setHeaderFooterTooltipShownInLS = (isHeader?: boolean) => {
    if (isHeader) {
        setHeaderSectionTooltipShownInLS();
    } else {
        setFooterSectionTooltipShownInLS();
    }
};

export const getHeaderFooterTooltipShownInLS = (isHeader?: boolean) => {
    if (isHeader === undefined) {
        return getHeaderSectionTooltipShownInLS() && getFooterSectionTooltipShownInLS();
    }
    if (isHeader) {
        return getHeaderSectionTooltipShownInLS();
    }
    return getFooterSectionTooltipShownInLS();
};

export const shouldResetState = (isCloseExplicit, closeTooltipFlag, isCloseOnCmpAction) =>
    (isCloseExplicit && closeTooltipFlag) || isCloseOnCmpAction;

