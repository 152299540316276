import makeEpic from "../../../epics/makeEpic";
import { UiLocaleSelector } from "../../TopBar/epics/languages/selectorActionTypes";
import { AudienceLocaleSelector } from "../../SiteSettings/General/generalGlobalDataEpic/generalGlobalDataEpic";
import { TEMPLATE_LIST_LOCALE_SELECTED } from "../actionTypes";
import { optional, withSelector } from "../../../epics/makeCondition";
import { getDefultLocaleId } from "../../Locale/getDefaultLocale";

const DEFAULT_LOCALE = getDefultLocaleId();

export const templateSelectorTemplateLocaleEpic = makeEpic({
    defaultState: DEFAULT_LOCALE,
    valueActionType: 'TEMPLATE_SELECTOR_TEMPLATE_LOCALE_EPIC_VALUE',
    updaters: [
        {
            conditions: [
                UiLocaleSelector,
                AudienceLocaleSelector,
                optional(TEMPLATE_LIST_LOCALE_SELECTED)
            ],
            reducer: ({ values: [uiLocale, audienceLocale, localeSelectedFromTemplateList] }) => ({
                state: localeSelectedFromTemplateList || audienceLocale || uiLocale
            })
        }
    ]
});

export const TemplateSelectorTemplateLocale2Selector = withSelector(
    templateSelectorTemplateLocaleEpic.valueActionType,
    s => s.substring(0, 2)
);

