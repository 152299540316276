// @ts-nocheck
import type {
    Element
} from '../../flowTypes';

/**
 * Applies styles to from paragraphs to style element
 */
export default (styles: Array<Element>, paragraphs: Array<Element>): Array<Element> => {
    const result = [];
    let index = 0;

    while (index < styles.length) {
        const style = styles[index];
        const paragraph = paragraphs.find(p => style.start === p.start && style.end === p.end);

        if (!paragraph) {
            result.push(style);
            index++;
            continue;
        }

        // full styles i.e <p style="font-size:bold"><span>Text</span></p>
        // child styles i.e. <p>T<span style="font-size: bold">ex</span>t</p>
        let isFullStyle = true;
        let nextIndex = index++;
        while (++nextIndex < styles.length) {
            const nextStyle = styles[nextIndex];
            if (nextStyle.start === style.start && nextStyle.end === style.end) {
                isFullStyle = true;
            } else if (nextStyle.start >= style.start && nextStyle.end <= style.end) {
                isFullStyle = false;
            } else {
                break;
            }
        }

        if (isFullStyle) {
            result.push({ ...style, full: true });
        } else {
            result.push(style);
        }
    }

    return result;
};
