import type { AnyComponent } from "../../redux/modules/children/workspace/flowTypes";
import isStretchComponentKind from '../oneweb/isStretchComponentKind';
import isStickyToHeaderComponent from '../oneweb/isStickyToHeaderComponent';
import { isSectionKind } from "../oneweb/componentKinds";

const OrderFactor = {
    SECTION: 100,
    STRETCH: 200,
    FULL_WIDTH_CMP: 300,
    TEMPLATE: 500,
    VERTICAL_MENU_PAGE_REGION: 2000,
    PAGE: 1000
};

export const
    WorkspaceZIndex = 1,
    SelectionZIndex = 10000,
    WrapPositionControlsZIndex = 2147483647, // maximum supported across browsers
    getComponentZIndex = (component: AnyComponent) => {
        let orderFactor;
        let cmpOrderIndex = component.orderIndex * 2;

        if (isStickyToHeaderComponent(component)) {
            orderFactor = OrderFactor.VERTICAL_MENU_PAGE_REGION;
        } else if (isSectionKind(component.kind)) {
            const sectionOIRange = OrderFactor.STRETCH - OrderFactor.SECTION - 1;
            cmpOrderIndex = (cmpOrderIndex * 2) % sectionOIRange;
            orderFactor = OrderFactor.SECTION;
        } else if (isStretchComponentKind(component.kind)) {
            orderFactor = OrderFactor.STRETCH;
        } else if (isStretchComponentKind(component.kind, component.stretch)) {
            orderFactor = OrderFactor.FULL_WIDTH_CMP;
        } else {
            orderFactor = component.inTemplate ? OrderFactor.TEMPLATE : OrderFactor.PAGE;
        }

        return (cmpOrderIndex + orderFactor - 1); // we need to preserve 'space' for stretch template masks
    },
    getComponentZIndexOld = (component: AnyComponent) => {
        let orderFactor;

        if (isStretchComponentKind(component.kind)) {
            orderFactor = OrderFactor.STRETCH;
        } else {
            orderFactor = component.inTemplate ? OrderFactor.TEMPLATE : OrderFactor.PAGE;
        }

        return ((component.orderIndex * 2) + orderFactor - 1); // we need to preserve 'space' for stretch template masks
    };
