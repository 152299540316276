import type { VideoComponent } from "../flowTypes";
import * as path from "../../../../mappers/path";
import { setToPath } from "../../../../utils/ramdaEx";

export const
    updateVideoFileReducer = (component: VideoComponent, { payload }: any): VideoComponent => {
        return ({ ...component, ...payload });
    },
    updateYoutubeLinkReducer = (component: VideoComponent, { payload }: any): VideoComponent =>
        setToPath(path.stateSettingsVideoUrlValue, payload, component);
export {
    updateYoutubeLinkReducer as default
};

