import React from 'react';
import ModernLayoutItem from '../../LeftPanel/screens/ModernLayoutItem';
import { getFooterLayoutsMap } from '../../../layoutsData/footerLayouts';
import * as styles from '../onBoardingPanel.css';
import { Intl } from '../../../../../view/intl/index';
import type { Stylesheets } from "../../../../Workspace/epics/stylesheets/flowTypes";
import type { SectionComponent } from "../../../../oneweb/Section/flowTypes";
import type { LeftPanelEpicState, OnBoardingPanelEpicState } from "../../../flowTypes";
import type { TemplateComponent } from "../../../../oneweb/Template/flowTypes";
import { ACTIVATE_MODERN_FOOTER, SWITCH_MODERN_FOOTER, ACTIVATE_MODERN_HEADER } from "../../../actionTypes";
import type { WebShopMHFEpicState } from "../../../../oneweb/WebShopMHF/flowTypes";

type Props = {
    intl: Intl,
    dispatch: Dispatch,
    leftPanel: LeftPanelEpicState,
    onBoardingPanel: OnBoardingPanelEpicState,
    template: TemplateComponent,
    componentsEval: Record<string, any>,
    globalVariables: Record<string, any>,
    styleSheets: Stylesheets,
    footerSection: SectionComponent,
    webshopMHFData: WebShopMHFEpicState,
};

const OnBoardingSelectHeader = ({
    intl,
    leftPanel,
    onBoardingPanel,
    template,
    componentsEval,
    globalVariables,
    styleSheets,
    dispatch,
    footerSection,
    webshopMHFData,
}: Props) => {
    const footerLayouts = getFooterLayoutsMap(webshopMHFData),
        footerIds: Array<string> = Object.keys(footerLayouts) || [],
        updatedFooterSection = {
            ...footerSection,
            modernLayout: undefined
        };

    return footerIds.map((footerId: string) => {
        const layoutItem = footerLayouts[footerId],
            isActivated = onBoardingPanel.isModernFooterActivated,
            activatedFooterId = onBoardingPanel.activatedFooterId;
        return <React.Fragment key={layoutItem.id}>
            <div className={styles.layoutTitle}>
                {'"' + intl.msgJoint(layoutItem.title) + '"'}
            </div>
            <div
                className={styles.layoutItemWrapper}
                onClick={() => {
                    if (!isActivated) {
                        dispatch({ type: ACTIVATE_MODERN_FOOTER, payload: footerId, amendTo: ACTIVATE_MODERN_HEADER });
                    } else if (activatedFooterId !== footerId) {
                        dispatch({ type: SWITCH_MODERN_FOOTER, payload: footerId, amendTo: ACTIVATE_MODERN_HEADER });
                    }
                }}
            >
                <ModernLayoutItem
                    isActivated={isActivated && activatedFooterId === footerId}
                    layoutItem={layoutItem}
                    leftPanel={leftPanel}
                    template={template}
                    componentsEval={componentsEval}
                    globalVariables={globalVariables}
                    styleSheets={styleSheets}
                    dispatch={dispatch}
                    section={updatedFooterSection}
                    MHFStoredLayoutsData={{}}
                    webshopMHFData={webshopMHFData}
                />
            </div>
        </React.Fragment>;
    });
};

export default OnBoardingSelectHeader;
