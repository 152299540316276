export const
    LinkChooserDialogId = 'LinkChoserDialog',
    width = 900,
    height = 600,
    SUPPORTED_EXTERNAL_URL_SCHEMES = ['http', 'https', 'javascript'],
    LcTabName = {
        PAGE: 'page',
        SECTION_LINK: 'sectionLink',
        FILE: 'file',
        URL: 'url',
        PHONE: 'phone',
        EMAIL: 'email',
        LOCATION: 'location',
    } as const,
    LC_CURRENT_LINK_MAX_WIDTH = 333,
    BROKEN_LINK_URL = 'wsb://broken-link',
    BROKEN_LINK_MSG = 'msg: common.brokenLink {Broken link}';
