import {
    makeCombineReducer,
} from "../../../../redux/makeReducer/index";
import { combineReducers } from './combineReducers';
import { actionsReducers } from './actionsReducers';

export default makeCombineReducer({
    combineReducers,
    handleActions: actionsReducers
});
