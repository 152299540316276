import * as React from 'react';
import styles from './BrokenLink.css';
import LongTextTip from "../../../../view/common/LongTextTip";
import Msg from "../../../../view/intl/Msg";

type Props = {
    onClose: React.MouseEventHandler;
}

export const BrokenLink = ({ onClose }: Props) => {
    return (
        <span className={styles.brokenLink}>
            <LongTextTip
                className={styles.brokenLinkText}
            >
                <Msg k="sectionLinkInfoDialog.brokenLink">Broken link</Msg>
            </LongTextTip>
            <span
                onClick={onClose}
                className={styles.brokenLinkCancel}
            />
        </span>
    );
};
