import React from "react";
import TextInGroup from './TextInGroup';
import { getTextAndTextGroupDefaultSettings } from "../../../constants";

export default ({ groupProps }: Record<string, any>) => {
    return groupProps.map((cmpProps, i) => {
        const props = {
            ...cmpProps,
            textId: cmpProps.component.id,
            settings: { ...getTextAndTextGroupDefaultSettings(), ...(cmpProps.settings[cmpProps.groupId] || {}) }
        };
        return <TextInGroup key={i} {...props} />;
    });
};
