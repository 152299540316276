import { makeEpic } from '../../../../epics/makeEpic';
import { receiveOnly } from '../../../../epics/makeCondition';
import { TEMPLATE_PP_EDIT_LOCALIZATION_PRESSED } from '../../../oneweb/Template/actionTypes';
import openDialogAC from '../../actionCreators/openDialog';
import { editLocalizationDialogId } from '../../../oneweb/Template/editLocalizationsDialog/editLocalizationDialogId';
import { DESIGN_ADD_LOCALIZATION, DESIGN_SET_LOCALIZATION } from './actionTypes';
import { designLocalizationEpicVat } from './valueActionType';
import { WhenRepositoryDomainSelector } from '../../../../redux/middleware/cookie/selectorActionTypes';
import { getDesignLocalizations } from '../../../TemplateSelector_DEPRECATED/actionCreators/getDesignLocalizations';
import { GET_DESIGN_LOCALIZATIONS_SUCCESS } from '../../../TemplateSelector_DEPRECATED/actionTypes';
import { saveDesignLocalizationsAC } from './saveDesignLocalizationsAC';
import { AUTH_COOKIE_EXISTS } from "../auth/actionTypes";

const defaultI18n = {
    field_name: {
        cs: null,
        da: null,
        de: null,
        en: "Field Name",
        es: null,
        fi: null,
        fr: null,
        it: null,
        nb: null,
        nl: null,
        pl: null,
        pt: null,
        sv: null
    }
};

const TRANSLATE_DESIGN_I18N_SUCCESS = 'TRANSLATE_DESIGN_I18N_SUCCESS';

const designLocalizationEpic = makeEpic({
    valueActionType: designLocalizationEpicVat,
    defaultState: {
        i18n: {}
    },
    updaters: [
        {
            conditions: [
                WhenRepositoryDomainSelector,
                AUTH_COOKIE_EXISTS,
            ],
            reducer: ({ state, values: [isRepo, { domain }] }) => {
                //TODO WhenRepositoryDomainSelector should not return undefined and
                // this reducer should not be called when WhenRepositoryDomainSelector returns falsy value
                return isRepo ? { state, actionToDispatch: getDesignLocalizations(domain) } : { state };
            }
        },
        {
            conditions: [GET_DESIGN_LOCALIZATIONS_SUCCESS],
            reducer: ({ state, values: [i18n] }) => {
                return {
                    state: {
                        ...state,
                        i18n
                    }
                };
            }
        },
        {
            conditions: [TEMPLATE_PP_EDIT_LOCALIZATION_PRESSED],
            reducer: ({ state }) => ({
                state,
                actionToDispatch: openDialogAC(
                    editLocalizationDialogId,
                    { code: JSON.stringify(state.i18n ? state.i18n : defaultI18n, null, '\t') }
                )
            })
        },
        {
            conditions: [receiveOnly(DESIGN_ADD_LOCALIZATION), 'SAVE'],
            reducer: ({ state, values: [{ fieldName, value }] }) => {
                const i18n = { ...(state.i18n || {}), [fieldName]: { en: value } };
                return ({
                    state: {
                        ...state,
                        i18n
                    },
                    actionToDispatch: saveDesignLocalizationsAC(i18n),
                });
            }
        },
        {
            conditions: [DESIGN_SET_LOCALIZATION],
            reducer: ({ state, values: [i18n] }) => ({
                state: { ...state, i18n },
                actionToDispatch: saveDesignLocalizationsAC(i18n),
            })
        },
        {
            keepFullActions: true,
            conditions: [TRANSLATE_DESIGN_I18N_SUCCESS],
            reducer: ({ state/* , values: [{ endpointParams: { fieldName }, payload: { translations } }] */ }) => {
                return { state };

                /* return ({ // Uncomment to enable translations
                    state: {
                        ...state,
                        i18n: { ...state.i18n, [fieldName]: { ...state.i18n[fieldName], ...translations } }
                    },
                    updateReason: TRANSLATE_DESIGN_I18N_SUCCESS,
                }); */
            }
        },
    ]
});

export {
    designLocalizationEpic,
};

