import backgroundReducerFactory from "../../Background/reducer/backgroundReducerFactory";
import { STRIP_DEFAULT_BG_COLOR, StripDefaultAssetData } from "../constants";
import * as ActionTypes from "../actionTypes";

const
    defaultState = {
        left: 0,
        height: 200,
        pin: 0,
        title: '',
        style: {
            background: {
                colorData: {
                    color: STRIP_DEFAULT_BG_COLOR,
                    gradient: null
                },
                assetData: null
            }
        }
    },
    stripComponentHandlers = {
        [ActionTypes.STRIP_PIN_SET]: (state, action) => {
            const { payload: pin } = action;
            return { ...state, pin };
        },
        [ActionTypes.STRIP_TITLE_CHANGE]: (state, action) => {
            const { payload: title } = action;
            return { ...state, title };
        }
    };

export const
    stretchReducerFactory = (kind: string) => backgroundReducerFactory(kind, defaultState, StripDefaultAssetData, stripComponentHandlers);
