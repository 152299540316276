import type { ApiAction } from "../../redux/middleware/api/flowTypes";
import { apiAction } from "../../redux/middleware/api/actions";

export const
    GET_SOCIAL_MEDIA_COMPONENTS_USAGE_REQUEST_ACTION = 'GET_SOCIAL_MEDIA_COMPONENTS_USAGE_REQUEST_ACTION',
    GET_SOCIAL_MEDIA_COMPONENTS_USAGE_SUCCESS_ACTION = 'GET_SOCIAL_MEDIA_COMPONENTS_USAGE_SUCCESS_ACTION',
    GET_SOCIAL_MEDIA_COMPONENTS_USAGE_FAILURE_ACTION = 'GET_SOCIAL_MEDIA_COMPONENTS_USAGE_FAILURE_ACTION',
    getSocialMediaComponentsUsageAction = (): ApiAction => {
        return apiAction({
            types: [
                GET_SOCIAL_MEDIA_COMPONENTS_USAGE_REQUEST_ACTION,
                GET_SOCIAL_MEDIA_COMPONENTS_USAGE_SUCCESS_ACTION,
                GET_SOCIAL_MEDIA_COMPONENTS_USAGE_FAILURE_ACTION,
            ],
            endpoint: 'getSocialMediaComponentsUsage',
        });
    };
