// @ts-nocheck
import Menu from "../../oneweb/Menu/kind";
import Image from "../../oneweb/Image/kind";
import { MobileBlocks } from "./getComponentClass";
import { AnyComponent } from "../../../redux/modules/children/workspace/flowTypes";
import { backgroundKindsMap } from "../../MobileViewEditor/constants";
import { isAnyParentWrapped } from "./isAnyParentWrapped";
import isStretchComponentKind from "../../oneweb/isStretchComponentKind";
import { isBoxKind } from "../../oneweb/componentKinds";
import { isBoxWithNoAssetOrSmallSize } from "../../oneweb/Background/utils";

export type MobileHideMap = {
    [id: string]: boolean
};

const isAnyChildrenVisible = (component, structure, mobileHideMap) => {
    const { id, kind } = component;
    let isVisible = false;
    if (!structure[id] || !MobileBlocks[kind]) {
        return false;
    }
    const checkVisibility = (cmpId) => {
        if (!mobileHideMap[cmpId]) {
            isVisible = true;
        }
        if (structure[cmpId]) {
            checkChildren(structure[cmpId]); // eslint-disable-line
        }
    };
    const checkChildren = column => {
        if (isVisible) {
            return;
        }
        if (column.component) {
            checkVisibility(column.component.id);
        } else if (column.rows) {
            column.rows.forEach(row => {
                row.columns.forEach(col => {
                    checkChildren(col);
                });
            });
        }
        if (column.floating) {
            column.floating.forEach(float => {
                checkVisibility(float.id);
            });
        }
    };
    checkChildren(structure[id]);
    return isVisible;
};

export default (component: AnyComponent, isParent: boolean, structure: any, mobileHideMap: MobileHideMap): boolean => {
    const { kind, mobileHide, wrap } = component;
    if (!backgroundKindsMap[kind] && ((mobileHide === false) || (wrap && kind === Image))) {
        return false;
    }
    if (kind === Image && isAnyParentWrapped(component, structure)) {
        return false;
    }
    if ((isStretchComponentKind(kind) && !isParent) || (kind === Menu)) {
        return !isStretchComponentKind(kind);
    }
    if (isBoxKind(kind) && !isBoxWithNoAssetOrSmallSize(component, true)) {
        return !!mobileHide;
    }
    if (isBoxKind(kind) && !isParent) {
        return true;
    }
    if (!isParent || isStretchComponentKind(kind)) { // dont hide strip if its empty.
        return !!mobileHide;
    }
    if (mobileHide && (backgroundKindsMap[kind] || kind === Image)) {
        return true;
    }
    if (isParent && isAnyChildrenVisible(component, structure, mobileHideMap)) {
        return false;
    }
    if (backgroundKindsMap[kind]) {
        return true;
    }
    return mobileHide;
};
