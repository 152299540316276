import React from 'react';
import { connect } from 'react-redux';
import styles from './FileChooserUpload.css';
import wdStyles from '../../dialogs/WarningDialog/WarningDialog.css';
import { errorDialogStandardHeight, errorDialogStandardWidth } from '../../dialogs/constants';
import { Dialog, DialogTitleBox, DialogBody, DialogFooter } from '../../dialogs/baseDialog/index';
import { StripTypes } from "../../dialogs/baseDialog/Dialog";
import injectIntl from "../../../intl/injectIntl";
import {
    FC_UPLOAD_OVERWRITE,
    FC_CONFLICT_DIALOG_CLOSE
} from "../../../../redux/modules/children/fileChooser/actionTypes";
import getCenteredDialogConfig from "../../../../components/DialogManager/getCenteredDialogConfig";
import { decodeWebspaceUri } from "../../../../../dal/utils/webspaceUriTransformers";
import { openRenameDialogAction } from "./renameDialog/actions";
import type { AppState } from "../../../../redux/modules/flowTypes";
import { resourcesSelector } from "../../../../redux/modules/children/fileChooser/selectors";
import Resource from "../../../../redux/modules/children/fileChooser/Resource";

const
    width = errorDialogStandardWidth,
    height = errorDialogStandardHeight,
    title = 'msg: fc.fileNameConflictDialog.title {Filename conflict}',
    description = 'msg: fc.fileNameConflictDialog.description {Sorry, a file named \"{fileName}\" already exists. Either rename the file or overwrite the existing file.}', // eslint-disable-line
    mctaText = 'msg: common.overwrite {Overwrite}',
    sctaText = 'msg: common.rename {Rename}';

type Props = { fileName, overwrite, conflictDialogClosed, rename, resources, intl: Intl };

const FileNameConflictDialog = injectIntl((props: Props) => {
    const
        { fileName, overwrite, conflictDialogClosed, rename, resources, intl } = props,
        existingFileNames = resources.filter((r: Resource) => !r.isDirectory()).map((r: Resource) => r.getName());

    return (
        <Dialog
            stripType={StripTypes.WARNING}
            onClose={conflictDialogClosed}
        >
            <DialogTitleBox
                title={title}
                iconClass={wdStyles.warningIcon}
                className={wdStyles.warningDialogTitle}
            />
            <DialogBody><p className={styles.labelSmall}>{intl.msgJoint(
                [description, { fileName: decodeWebspaceUri(fileName) }]
            )}</p></DialogBody>
            <DialogFooter
                mctaText={mctaText}
                mctaHandler={overwrite}
                sctaText={sctaText}
                sctaHandler={rename.bind(null, fileName, existingFileNames)}
                noTopBorder
                className={wdStyles.warningDialogFooter}
            />
        </Dialog>
    );
});

const mapStateToProps = ({ fileChooser }: AppState) => ({
    resources: resourcesSelector(fileChooser)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    overwrite: () => dispatch({ type: FC_UPLOAD_OVERWRITE }),
    conflictDialogClosed: () => dispatch({ type: FC_CONFLICT_DIALOG_CLOSE }),
    rename: (fileName: string, existingFileNames: Array<string>) =>
        dispatch(openRenameDialogAction(fileName, existingFileNames))
});

const component = connect(mapStateToProps, mapDispatchToProps)(FileNameConflictDialog);

export default getCenteredDialogConfig({
    width, height, component, forceModal: true, dialogBackgroundClassName: styles.dialogBackground
});
