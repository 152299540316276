import { makeToggleUpdater } from "./makeToggleUpdater";
import { getSelectedNodes } from '../editorUtils/utils/nodeUtils/getSelectedNodes';
import { listItemFromats } from '../../../../oneweb/Text/editorSetup';
import { closest } from '../../../../../utils/dom';
import { getClosestContentBlock, copyListStylesFromChildren } from '../editorUtils/utils/nodeUtils/utils';
import { customSendReport } from '../../../../../customSendCrashReport';

const getStyleMap = (elt: HTMLElement, styles: string[]): Object => (
    styles.reduce((acc, style) => (
        elt.style.getPropertyValue(style) ? { ...acc, [style]: elt.style.getPropertyValue(style) } : acc
    ), {})
);

const setStyleMap = (elt: HTMLElement, styles: Object) => {
    Object.keys(styles).forEach(property => elt.style.setProperty(property, styles[property]));
};

type MakeToggleListUpdaterConfig = {
    editorCmd: string;
    listStyle: {
        "list-style-type": string;
    };
    propToToggle: string;
    triggerActionType: string;
};

export const makeToggleListUpdater =
    ({ editorCmd, listStyle, propToToggle, triggerActionType }: MakeToggleListUpdaterConfig) => makeToggleUpdater({
        triggerActionType,
        doWithEditor: (editor, shouldToggleOff) => {
            const bookmark = editor.selection.getBookmark();
            editor.undoManager.transact(() => {
                let styles;
                if (shouldToggleOff) {
                    // before toggling off, preserve listItemFromats
                    const liList: any[] = getSelectedNodes(editor).map(closest('LI'));
                    if (liList.every((e) => e && e.nodeName === 'LI')) {
                        // $FlowFixMe: flow doesn't understand that li can't be null because of the 'every' check above
                        styles = liList.map((li) => getStyleMap(li, listItemFromats));
                    } else {
                        customSendReport({
                            message: `Error in removing list: ${Math.random()}`,    //NOSONAR
                            additionalInfo: {
                                errorMessage: 'LI list contains unexpected items',
                                unexpectedItems: liList.filter(e => e && e.nodeName !== 'LI').map(e => e && e.nodeName),
                            },
                        });
                    }
                }

                editor.execCommand(editorCmd, false, shouldToggleOff ? undefined : listStyle);

                if (shouldToggleOff && styles) {
                    // after toggling off, restore listItemFromats
                    const pList: any[] = getSelectedNodes(editor).map(closest('P'));
                    if (pList.every((e) => e && e.nodeName === 'P')) {
                        if (pList.length === styles.length) {
                            // $FlowFixMe: flow doesn't understand that p can't be null because of the 'every' check above
                            pList.forEach((p, i) => setStyleMap(p, styles[i]));
                        } else {
                            const errorMessage = 'Paragraph count is different from the LI count';
                            customSendReport({
                                message: `Error in removing list: ${Math.random()}`,    //NOSONAR
                                additionalInfo: { errorMessage, pCount: pList.length, liCount: styles.length },
                            });
                        }
                    } else {
                        customSendReport({
                            message: `Error in removing list: ${Math.random()}`,    //NOSONAR
                            additionalInfo: {
                                errorMessage: 'Paragraph list contains unexpected items',
                                unexpectedItems: pList.filter(e => e && e.nodeName !== 'P').map(e => e && e.nodeName),
                            },
                        });
                    }
                }

                getSelectedNodes(editor).forEach(node => {
                    copyListStylesFromChildren(getClosestContentBlock(node));
                });
                editor.selection.moveToBookmark(bookmark);
            });
        },
        propToToggle,
    });
