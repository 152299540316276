// @ts-nocheck
import type { AppStore } from '../../../modules/flowTypes';
import type { ApiErrorHandler } from './types';
import { openApiErrorHandlerDialog } from './actions';
import { customSendReport } from '../../../../customSendCrashReport';
import { DalErrorName } from '../../../../../dal/constants';
import { openApiErrorConflictDialog } from './conflictDialog/actions';
import { isObj, isStr } from '../../../../isType';
import { isMsgJointInput } from '../../../../view/intl/injectIntl';
import { openDialogAC } from '../../../../components/App/actionCreators/openDialog';
import { API_ERROR_HANDLER_DEFAULT_ERROR_MESSAGE } from './constants';

const
    isDialogId = dialogConfig => isStr(dialogConfig) && !isMsgJointInput(dialogConfig),
    isDialogErrorMap = (dialogConfig, error) => isObj(dialogConfig) && !!error.error && !!dialogConfig[error.error],
    makeCommonDialogAction = dialogConfig => {
        const dialogProps = isStr(dialogConfig) ? { messages: dialogConfig } : dialogConfig;
        return openApiErrorHandlerDialog(dialogProps);
    };

export const apiErrorHandler = (store: AppStore, handler: null | undefined | ApiErrorHandler, error: Record<string, any>) => {
    if (error && error.error && error.error === DalErrorName.CONFLICT) {
        store.dispatch(openApiErrorConflictDialog());
        return;
    }

    if (handler) {
        if (handler.errorMatcher && handler.errorMatcher.skipErrors.indexOf(error.error) > -1) return;

        // show error dialog
        if (handler.dialog) {
            const dialogConfig = handler.dialog;

            let dialogAction;

            // custom error dialog cases
            if (isDialogId(dialogConfig)) {
                dialogAction = openDialogAC(dialogConfig);
            } else if (isDialogErrorMap(dialogConfig, error) && isDialogId(dialogConfig[error.error])) {
                dialogAction = openDialogAC(dialogConfig[error.error]);

            // common error dialog cases
            } else if (isMsgJointInput(dialogConfig) || (isObj(dialogConfig) && dialogConfig.messages)) {
                dialogAction = makeCommonDialogAction(dialogConfig);
            } else if (isDialogErrorMap(dialogConfig, error) && !isDialogId(dialogConfig[error.error])) {
                dialogAction = makeCommonDialogAction(dialogConfig[error.error]);
            } else {
                // fallback to common error dialog
                dialogAction = makeCommonDialogAction(API_ERROR_HANDLER_DEFAULT_ERROR_MESSAGE);
            }

            store.dispatch(dialogAction);
        }

        if (handler.customCrashReport) {
            customSendReport({
                ...handler.customCrashReport,
                message: error,
            });
        }
    }
};
