/* eslint-disable no-useless-escape */

import type { InputValidationResult, InputValidationFunction } from "../flowTypes";
import { makeInputValidationResult } from "./utils/index";

type Options = {
    list: Array<string>;
};

export type BlackListInputValidation = InputValidationFunction<Options>;

const
    REASON = 'BLACK_LIST',
    MESSAGE = "msg: validation.reservedWords {Sorry, {_name} cannot be \"{literal}\" as it is already reserved. Please use another word.}"; // eslint-disable-line max-len

const blackListInputValidation: BlackListInputValidation = (
    value: string,
    options = {} as Options
): InputValidationResult => {
    if (!options.list) throw new Error("Missing required 'list' option");

    const isValid = options.list.indexOf(value) === -1;

    return makeInputValidationResult(isValid, REASON, [MESSAGE, { literal: value }]);
};

export {
    blackListInputValidation as default,
    REASON as BLACK_LIST_INPUT_VALIDATION_REASON
};
