import * as R from 'ramda';
import { toHex } from "../../../mappers/color";
import { getFontName } from '../../presentational/AddGoogleFont/utils';
import { getStylesByType, textNormalGlobalstyle } from '../../Workspace/epics/stylesheets/selectors';
import type { Stylesheets } from "../../Workspace/epics/stylesheets/flowTypes";
import type { Color } from "../../../mappers/flowTypes";
import * as path from "../../../mappers/path";
import StyleButtonType from "../Button/globalStyle/kind";
import type { ContactFormComponent } from "./flowTypes";
import { FontFamiliesFallback } from '../../presentational/FontFamily/fontFamilies';

type FontSettings = {
    font: string,
    fontSize: number,
    fontColor: Color,
    buttonFontSize: number,
    buttonLineHeight: string,
    fuButtonFontSize: number,
    fuButtonLineHeight: string
}

type FontStyle = {
    textStyle: {
        fontFamily: string,
        fontSize: number,
        color: string
    },
    buttonStyle: {
        fontSize: number,
        lineHeight: string,
        textAlign: string
    },
    fuButtonStyle: {
        fontSize: number,
        lineHeight: string,
        textAlign: string
    }
}

// For font name having spaces
const wrapInQuotesIfRequired = (v: string) => {
    return (/"/.test(v) ? v : `"${v}"`);
};

export const
    getFontSettings = ({
        component,
        globalStyles,
        lineHeightPercentage,
        autoColorMode
    }: {
        component: ContactFormComponent,
        globalStyles: Stylesheets,
        lineHeightPercentage: number,
        autoColorMode: boolean
    }): FontSettings => {
        const
            { styleForm, styleButton, fileUploadButtonStyle } = component,
            globalStyle = textNormalGlobalstyle(globalStyles),
            buttonGlobalStyles = getStylesByType(StyleButtonType)(globalStyles.styles),
            selectedButtonStyle = autoColorMode ?
                R.head(buttonGlobalStyles) : R.find(R.propEq('id', styleButton.globalId))(buttonGlobalStyles),
            buttonGlobalFontSize = R.path(path.inactiveTextSize, selectedButtonStyle),
            // eslint-disable-next-line no-nested-ternary
            fuButtonStyle = autoColorMode ?
                R.head(buttonGlobalStyles) :
                (fileUploadButtonStyle ? R.find(R.propEq('id', fileUploadButtonStyle.globalId))(buttonGlobalStyles) : {}),
            fuButtonGlobalFontSize = R.path(path.inactiveTextSize, fuButtonStyle),
            fuBtnFntSize = fileUploadButtonStyle && fileUploadButtonStyle.text ?
                (fileUploadButtonStyle.text.size || fuButtonGlobalFontSize) : 14;
        let fontSettings = {
            font: getFontName((styleForm.font || globalStyle.font).toString()),
            fontSize: styleForm.fontSize || globalStyle.size,
            fontColor: styleForm.fontColor || globalStyle.color,
            buttonFontSize: styleButton.text.size || buttonGlobalFontSize,
            buttonLineHeight: "0px",
            fuButtonFontSize: fuBtnFntSize,
            fuButtonLineHeight: "0px"
        };
        fontSettings.buttonLineHeight = Math.round(fontSettings.buttonFontSize * lineHeightPercentage) + "px";
        fontSettings.fuButtonLineHeight = Math.round(fontSettings.fuButtonFontSize * lineHeightPercentage) + "px";
        return fontSettings;
    },

    getFontStyle = ({
        component,
        globalStyles,
        lineHeightPercentage,
        autoColorMode
    }: {
        component: ContactFormComponent,
        globalStyles: Stylesheets,
        lineHeightPercentage: number,
        autoColorMode: boolean
    }): FontStyle => {
        const {
            font,
            fontSize,
            fontColor,
            buttonFontSize,
            buttonLineHeight,
            fuButtonFontSize,
            fuButtonLineHeight
        } = getFontSettings({ component, globalStyles, lineHeightPercentage, autoColorMode });
        return {
            textStyle: {
                fontFamily: FontFamiliesFallback[font] || wrapInQuotesIfRequired(font),
                fontSize,
                color: toHex(fontColor)
            },
            buttonStyle: {
                fontSize: buttonFontSize,
                lineHeight: buttonLineHeight,
                textAlign: "center"
            },
            fuButtonStyle: {
                fontSize: fuButtonFontSize,
                lineHeight: fuButtonLineHeight,
                textAlign: "center"
            }
        };
    };
