/* eslint-disable max-len */

import React, { Component } from 'react';
import * as R from 'ramda';
import cx from 'classnames';
import { Box, Flex } from "../../../view/reflexbox/index";
import HorizontalSpacer from '../../../view/common/HorizontalSpacer';
import VerticalSpacer from '../../../view/common/VerticalSpacer';
import { UpgradeButton } from '../../../view/common/Button/index';
import ResizeHandle from '../../Panel/view/ResizeHandle';
import LoadingIndicator from '../../../view/common/LoadingIndicator/index';
import Scrollbar from '../../../view/common/Scrollbar/index';
import { Msg, injectIntl } from '../../../view/intl/index';
import { openDialog } from '../../App/actionCreators/index';
import { dateToRelativeDays, timestampToDate } from '../../../utils/date';
import { TopBarHeight } from '../../TopBar/constants';
import { introDialogId, upgradeDialogId } from '../dialogs/index';
import { BACKUP_SET_PREVIEW_RECORD, BACKUP_TOGGLE_LEFT_PANEL } from '../actionTypes';
import type { LeftPanelProps } from '../flowTypes';
import styles from './Backup.css';
import { getPremiumPackageNameFromMetadata } from '../../ComponentTierManager/utils';
import OpenUpgradePlanDialog from "../../TopBar/view/Upgrade/UpgradePlansDialog/OpenUpgradePlanDialog";
import { upgradeDialogOpenedAction } from '../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions';
import { FEATURE_BACKUP_RESTORE } from '../../TopBar/view/Upgrade/tracking/contants';
import { UpgradePlansDialogId } from '../../TopBar/view/dialogIds';

// @ts-ignore
const SavePointsIntl = injectIntl(class SavePoints extends Component<LeftPanelProps, void> {
    componentDidUpdate() {
        // Initialize jQuery
        const
            { timestamp } = this.props,
            jQuery = window.$,
            panel = jQuery(`.${styles.panel}`),
            datesDom = jQuery(`.${styles.date}`),
            timesDom = jQuery(`.${styles.time}`);

        if (!panel.attr('date-click-attached')) {
            // hide all time list in a given date
            jQuery(`.${styles.timeContainer}`).hide();
            // show the first time container
            jQuery(`.${styles.timeContainer}:first`).show();
            // show expanded icon of the first date in the date list
            jQuery(`.${styles.dateIcon}:first`).toggleClass(`${styles.dateIconExpanded} ${styles.dateIconCollapsed}`);
        }

        if (!panel.attr('date-click-attached') && datesDom.length > 0) {
            datesDom.on("click", function () {
                // @ts-ignore
                jQuery(this).find(`.${styles.dateIcon}`).toggleClass(`${styles.dateIconExpanded}`).toggleClass(`${styles.dateIconCollapsed}`);
                // @ts-ignore
                jQuery(this).parents(`.${styles.dateContainer}`).children(`.${styles.timeContainer}`).toggle(250);
            });

            panel.attr('date-click-attached', 'true');
        }

        if (!panel.attr('time-click-attached') && timesDom.length > 0) {
            timesDom.on("click", function () {
                // @ts-ignore
                if (timestamp === jQuery(this).attr("data-time")) {
                    jQuery(`.${styles.highlighted}`).removeClass(`${styles.highlighted}`);
                    // @ts-ignore
                    jQuery(this).addClass(`${styles.highlighted}`);
                }
            });
            panel.attr('time-click-attached', 'true');
        }
    }

    componentWillUnmount() {
        // Cleanup jQuery
        const jQuery = window.$;
        jQuery(`.${styles.date}`).unbind("click");
        jQuery(`.${styles.time}`).unbind("click");
        jQuery(`.${styles.panel}`).removeAttr('date-click-attached').removeAttr('time-click-attached');
    }

    render() {
        if (this.props.loading) {
            return (<LoadingIndicator className={styles.leftPanelLoading} />);
        }

        const
            { panelExpanded, dimensions: { height }, timestamp, timeline, isBackupAndRestoreAllowed, dispatch, intl, subscriptionData } = this.props,
            { metadata: subscriptionMetadata } = subscriptionData,
            scrollHeight = height - (TopBarHeight + parseInt(styles.SavedBackupTopOffset, 10) + parseInt(styles.SavedBackupContainerMarginBottom, 10)),
            premiumPackageNameFromMetadata = getPremiumPackageNameFromMetadata(subscriptionMetadata);

        let sortedDates: any = [], sortedTimelines = {};

        R.reverse(timeline).forEach(({ time, published }) => {
            const
                d = dateToRelativeDays(timestampToDate(time, intl), intl),
                data = { published, time, display: intl.time(time) };

            if (sortedDates.indexOf(d) === -1) {
                sortedDates.push(d);
            }

            if (sortedTimelines[d]) {
                sortedTimelines[d].push(data);
            } else {
                sortedTimelines[d] = [data];
            }
        });

        return (
            <div className={cx(styles.panel, { [styles.panelExpaned]: panelExpanded })}>
                <Box>
                    <table className={styles.flexStyle}>
                        <tbody>
                            <tr>
                                <td style={{ width: 19 }}><HorizontalSpacer x={19} /></td>
                                <td><Msg k="component.backup.savedBackup">Saved backups</Msg></td>
                                <td style={{ width: 18 }}>
                                    <div
                                        className={styles.help}
                                        onClick={() => dispatch(openDialog(introDialogId, { showHideIntro: false }))}
                                    />
                                </td>
                                <td style={{ width: 9 }}><HorizontalSpacer x={9} /></td>
                            </tr>
                        </tbody>
                    </table>
                    <Scrollbar height={`${scrollHeight}px`}>
                        {sortedDates.map((date) => (
                            <div key={date} className={styles.dateContainer}>
                                <div className={styles.date}>
                                    <Flex align="center" justify="flex-start">
                                        <div className={cx(styles.dateIcon, styles.dateIconCollapsed)} />
                                        <HorizontalSpacer x={9} />
                                        <span>{date}</span>
                                    </Flex>
                                </div>
                                <div className={styles.timeContainer}>
                                    {sortedTimelines[date].map(({ published, time, display }) => (
                                        <div
                                            key={time}
                                            data-time={time}
                                            className={cx(styles.time, { [styles.highlighted]: time === timestamp })}
                                            onClick={() => dispatch({ type: BACKUP_SET_PREVIEW_RECORD, payload: time })}
                                        >
                                            {display}&nbsp;
                                            {published && <span className={styles.published}>-</span>}&nbsp;
                                            {published && <Msg className={styles.published} k="common.published">Published</Msg>}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </Scrollbar>
                </Box>
                {
                    !isBackupAndRestoreAllowed &&
                    <div className={styles.leftPanelUpgrade}>
                        <Box>
                            <VerticalSpacer y={41} />
                            <Flex align="center" justify="center">
                                <div className={styles.backupIcon} />
                            </Flex>
                            <VerticalSpacer y={15} />
                            <Flex align="center" justify="center">
                                <Msg className={styles.centerText} k="component.backup.needBackup">Need backups?</Msg>
                            </Flex>
                            <VerticalSpacer y={6} />
                            <Flex align="center" justify="center">
                                <Msg
                                    className={styles.leftPanelUpgradeMsg}
                                    k="component.backup.upgradeToPremium"
                                    params={{ packageName: premiumPackageNameFromMetadata || intl.msgJoint('msg: common.premium {Premium}') }}
                                >{`
                                    Upgrade to {packageName} and get access to saved versions of your website.
                                `}</Msg>
                            </Flex>
                            <VerticalSpacer y={16} />
                            <Flex align="center" justify="center">
                                <UpgradeButton
                                    onClick={() => {
                                        dispatch(OpenUpgradePlanDialog(subscriptionData, 'RestorePage:LeftPanelUpgradeButton'));
                                        dispatch(upgradeDialogOpenedAction({
                                            feature: FEATURE_BACKUP_RESTORE,
                                            dialogId: UpgradePlansDialogId
                                        }));
                                    }}
                                >
                                    <Msg k="common.upgrade">Upgrade</Msg>
                                </UpgradeButton>
                            </Flex>
                            <VerticalSpacer y={10} />
                            <Flex align="center" justify="center">
                                <a
                                    href="#"
                                    className={cx(styles.leftPanelLearnMore, styles.learnMore)}
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        dispatch(openDialog(upgradeDialogId));
                                        dispatch(upgradeDialogOpenedAction({
                                            feature: FEATURE_BACKUP_RESTORE,
                                            dialogId: upgradeDialogId
                                        }));
                                    }}
                                >
                                    <Msg k="common.learnMore">Learn more</Msg>
                                </a>
                            </Flex>
                            <VerticalSpacer y={10} />
                        </Box>
                    </div>
                }
                <ResizeHandle
                    onClick={() => dispatch({ type: BACKUP_TOGGLE_LEFT_PANEL })}
                    expanded={panelExpanded}
                />
            </div>
        );
    }
});

export default SavePointsIntl;
