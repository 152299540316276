import cx from "classnames";
import React from "react";
import { connect } from "react-redux";
import VerticalSpacer from '../../../../view/common/VerticalSpacer';
import getCenteredDialog from "../../../DialogManager/getCenteredDialogConfig";
import { ConfigDialogSettings, DialogHeight, DialogWidth, } from "../constants/configurationDialog";
import type { ConnectFacebookFeedAccountDialogPropTypes, ConnectFacebookFeedAccountDialogStateTypes } from "../flowTypes";
import { injectIntl, makeBoldIntlTag, Msg } from "../../../../view/intl/index";
import ComponentConfigurationDialog from "../../../../view/common/dialogs/ComponentConfigurationDialog/index";
import styles from './configurationDialog.css';
import { StripTypes } from "../../../../view/common/dialogs/baseDialog/index";
import { FACEBOOK_FEED_GALLERY_SET_IMAGES_ON_COMPONENT_DROPPED } from "../actionTypes";
import type { AppState } from "../../../../redux/modules/flowTypes";
import { socialAccountsFacebookFeedAssets, socialAccountsFacebookFeedConnected } from "../selectors";
import { componentConfigurationCompleteAction } from "../../../../redux/modules/children/workspace/actionCreators";
import { setIsFbPageModeAction } from "../../../SocialAccounts/Facebook/FacebookFeed/actions";
import { socialAccountsFacebookFeedPageMode } from "../../../SocialAccounts/Facebook/FacebookFeed/selectors";
import { facebookSdkLoginAction } from "../../../SocialAccounts/Facebook/facebookSdkActions";
import { FB_FEED_LOGIN } from '../../../SocialAccounts/Facebook/constants';

const FacebookConnectionModeButton = ({ intl, isActive, value, text, subText, containerClassName, onClick }) => {
    return (
        <div
            className={
                cx(
                    styles.connectionModeButton,
                    containerClassName,
                    {
                        [styles.active]: isActive,
                    }
                )
            }
            onClick={() => onClick(value)}
        >
            <div className={styles.connectionModeContainer} />
            <div className={styles.text}>{intl.msgJoint(text)}</div>
            <div className={styles.subText}>{intl.msgJoint(subText)}</div>
        </div>
    );
};

// eslint-disable-next-line max-len
class ConnectFacebookFeedAccountDialog extends React.Component<ConnectFacebookFeedAccountDialogPropTypes, ConnectFacebookFeedAccountDialogStateTypes> {
    render() {
        // $FlowFixMe
        const { props: { dispatch, intl, isFacebookFeedConnected, facebookFeedUserMediaAssets, isFbPageMode } } = this;

        const setAccountMode = (value) => {
            const isPageMode = value === 'page';
            dispatch(setIsFbPageModeAction(isPageMode));
        };

        if (isFacebookFeedConnected) {
            dispatch(componentConfigurationCompleteAction());
            dispatch({
                type: FACEBOOK_FEED_GALLERY_SET_IMAGES_ON_COMPONENT_DROPPED,
                payload: {
                    assets: facebookFeedUserMediaAssets
                }
            });
            return '';
        } else {
            return (
                <ComponentConfigurationDialog
                    title={ConfigDialogSettings.TITLE}
                    iconClass={styles.facebookFeedIcon}
                    autoFocus={false}
                    stripType={StripTypes.NONE}
                    onSaveText="msg: component.facebookFeed.configDialog.addSaveLabel {Connect Facebook}"
                    onSave={
                        () => {
                            dispatch(facebookSdkLoginAction(FB_FEED_LOGIN));
                            // dispatch(facebookSdkLoginAction());
                            dispatch(componentConfigurationCompleteAction());
                        }
                    }
                    hideCancel
                >
                    <div className={styles.message}>{intl.msgJoint(ConfigDialogSettings.MESSAGE)}</div>
                    <VerticalSpacer y={10} />

                    <VerticalSpacer y={10} />
                    <Msg
                        className={styles.subMessage}
                        k="component.facebookFeedFeed.configDialog.subMessage"
                        params={{
                            b: makeBoldIntlTag,
                        }}
                    >
                        {`
                             <b>Tip</b>: If the Facebook login pop-up doesn’t open, try turning
                             your pop-up blocker off or updating your browser.
                        `}
                    </Msg>

                    <div className={styles.buttonsBox}>
                        <FacebookConnectionModeButton
                            intl={intl}
                            isActive={!isFbPageMode}
                            value="profile"
                            text="msg: component.facebookFeedFeed.personalProfile {Personal profile}"
                            subText="msg: component.facebookFeedFeed.personalProfile.subText {Used to share personal information. Requires reconnection every 60 days}" // eslint-disable-line max-len
                            containerClassName={styles.profileModeConnection}
                            onClick={setAccountMode}
                        />
                        <FacebookConnectionModeButton
                            intl={intl}
                            isActive={isFbPageMode}
                            value="page"
                            text="msg: component.facebookFeedFeed.businessPage {Business page}"
                            subText="msg: component.facebookFeedFeed.businessPage.subText {Used by businesses and nonprofits to keep in touch with their customers.}" // eslint-disable-line max-len
                            containerClassName={styles.pageModeConnection}
                            onClick={setAccountMode}
                        />
                    </div>

                </ComponentConfigurationDialog>
            );
        }
    }
}

const mapStateToProps = (appState: AppState) => ({
    isFacebookFeedConnected: socialAccountsFacebookFeedConnected(appState),
    facebookFeedUserMediaAssets: socialAccountsFacebookFeedAssets(appState),
    isFbPageMode: socialAccountsFacebookFeedPageMode(appState)
});

export default getCenteredDialog({
    width: DialogWidth,
    height: DialogHeight,
    component: connect(mapStateToProps)(injectIntl(ConnectFacebookFeedAccountDialog))
});
