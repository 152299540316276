import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import { APP_MOUNT } from "../../actionTypes";
import { WINDOW_REPLACE_LOCATION } from "../../../../redux/middleware/window/actionTypes";
import { replaceLocationAction } from "../../../../redux/middleware/window/actionCreators";
import { receiveOnly } from "../../../../epics/makeCondition";
import { init as initDAL } from '../../../../../dal';
import { init as initDALWithTrial } from '../../../../../dal/dalWithTrial';
import {
    REDIRECT_TO_LOGIN_ACTION,
    SERVER_INVALID_OR_MISSING_COOKIE_ACTION,
} from "../../../../redux/middleware/api/actions";
import { AUTH_COOKIE_EXISTS, AUTH_COOKIE_MISSING } from "./actionTypes";
import type { Epic } from "../../../../epics/flowTypes";
import { UNSUPPORTED_MESSAGES_COMPLETED } from "../../../UnsupportedMessagesDialogs/actionTypes";
import {
    DOMAIN_FROM_AUTH_COOKIE_VALUE,
    GET_DOMAIN_FROM_AUTH_COOKIE,
    GET_EMAIL_FROM_AUTH_COOKIE
} from "../../../../redux/middleware/cookie/actionTypes";
import { AppConfigSelector } from "../appConfig/selectors";
import { getLoginRedirectUrl } from "./getLoginRedirectUrl";
import { isWsbDemo } from '../../../../debug/isWsbDemo';
import { demoLoginAction } from "../../../../../demo/modules/login/signIn/actions";
import { parseTrialDomainString } from "../../../../../../server/shared/trial/domain/trialDomain";
import { readWsbCustomerProfileCookiePayload } from '../../../../utils/wsbCustomerProfile/wsbCustomerProfileUtils';
import { TEMPLATE_DESIGN } from './constants';
import { DIFM } from '../../../../../../server/shared/constants';

type AuthState = {
    md5Hex: string;
    timestamp: Date;
    domain: string;
    email: string | null | undefined;
} | null | undefined;

const makeNavigateToLoginUpdater = (actionType: string) => ({
    conditions: [
        AppConfigSelector('oneWeb.auth'),
        actionType
    ],
    reducer: ({ state, state: { domain, email } = {} as any, values: [authConfig] }) => {
        return {
            state,
            actionToDispatch: {
                type: WINDOW_REPLACE_LOCATION,
                payload: getLoginRedirectUrl({ authConfig, domain, email }),
            }
        };
    }
});

const epic: Epic<AuthState, void, string> = makeEpic({
    valueActionType,
    updaters: [
        {
            conditions: [APP_MOUNT],
            reducer: ({ state }) => {
                if (isWsbDemo()) {
                    return {
                        state,
                        actionToDispatch: demoLoginAction(),
                    };
                }

                return {
                    state,
                    actionToDispatch: { type: GET_DOMAIN_FROM_AUTH_COOKIE }
                };
            }
        },
        {
            conditions: [
                receiveOnly(DOMAIN_FROM_AUTH_COOKIE_VALUE),
                UNSUPPORTED_MESSAGES_COMPLETED
            ],
            reducer: ({ state, values: [domain] }) => {
                if (domain) {
                    if (isWsbDemo()) {
                        const [trialUserIdentityId, trialExampleDomain] = parseTrialDomainString(domain);
                        initDALWithTrial(trialExampleDomain, trialUserIdentityId);
                    } else {
                        initDAL(domain);
                    }

                    return {
                        state,
                        multipleActionsToDispatch: [
                            { type: AUTH_COOKIE_EXISTS, payload: { domain } },
                            { type: GET_EMAIL_FROM_AUTH_COOKIE }
                        ]
                    };
                }
                return { state, actionToDispatch: { type: AUTH_COOKIE_MISSING } };
            }
        },
        {
            conditions: [
                AppConfigSelector('oneWeb.auth.logoutUrl'),
                AppConfigSelector('oneWeb.auth.difm.logoutUrl'),
                REDIRECT_TO_LOGIN_ACTION
            ],
            reducer: ({ state, values: [logoutUrl, difmLogoutUrl] }) => {
                const customerProfileData = readWsbCustomerProfileCookiePayload(),
                    { customerType } = customerProfileData || {};

                if ((customerType === DIFM || window.location.href.includes(TEMPLATE_DESIGN)) && difmLogoutUrl) {
                    return {
                        state,
                        actionToDispatch: replaceLocationAction(difmLogoutUrl)
                    };
                }
                return {
                    state,
                    actionToDispatch: replaceLocationAction(logoutUrl)
                };
            }
        },
        makeNavigateToLoginUpdater(AUTH_COOKIE_MISSING),
        makeNavigateToLoginUpdater(SERVER_INVALID_OR_MISSING_COOKIE_ACTION)
    ]
});

export default epic;
