
import {
    makeCombineReducer,
    makeDefaultStateReducers,
    makeComponentBaseReducers
} from "../../../../redux/makeReducer/index";
import { toggleValue } from '../../../../utils/ramdaEx';
import * as actions from "./actions";
import { WidgetsVimeoKind } from "./kind";
import { defaultState, DEFAULT_HEIGHT, DEFAULT_WIDTH } from "./constants";

const makeToggleReducer = prop => component => toggleValue([prop])(component);

const reducer = makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(WidgetsVimeoKind, DEFAULT_WIDTH, DEFAULT_HEIGHT),
        ...makeDefaultStateReducers(defaultState)
    },
    handleActions: {
        [actions.WIDGETS_VIMEO_UPDATE_PROPS]: (component, { payload: params }) => ({
            ...component, ...params
        }),
        [actions.WIDGETS_VIMEO_UPDATE_LINK]: (component, { payload: link }) => ({
            ...component, link
        }),
        [actions.WIDGETS_VIMEO_TOGGLE_AUTOPLAY]: makeToggleReducer('autoplay'),
        [actions.WIDGETS_VIMEO_TOGGLE_LOOP]: makeToggleReducer('loop'),
        [actions.WIDGETS_VIMEO_TOGGLE_SHOW_TEXT_LINK]: makeToggleReducer('showTextLink'),
        [actions.WIDGETS_VIMEO_TOGGLE_PORTRAIT]: makeToggleReducer('portrait'),
        [actions.WIDGETS_VIMEO_TOGGLE_TITLE]: makeToggleReducer('title'),
        [actions.WIDGETS_VIMEO_TOGGLE_BYLINE]: makeToggleReducer('byline'),
    }
});

export { reducer };

