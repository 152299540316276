import { pure } from 'recompose';
import * as React from 'react';
import cx from 'classnames';
import styles from './TopBarPagesTree.css';
import type { PagesTreeTopBarProps } from "../../flowTypes";
import openTopBarPopup from "../../actionCreators/openTopBarPopup";
import { Popups } from "../../constants";
import Icons from '../../../../view/Icons/index';
import { Msg } from "../../../../view/intl/index";
import LongTextTip from '../../../../view/common/LongTextTip/index';
import getStyleIntValue from "../../../../utils/getStyleIntValue";
import Popup from '../Popup/index';
import Scrollbar from '../../../../view/common/Scrollbar/index';
import { AddPageButton, PagesTree } from "../../../PagesTree/index";
import { selectPageAction } from "../../../PagesTree/Tree/actionCreators/index";
import type { PageTreeIds } from "../../../PagesTree/Tree/flowTypes";
import { expandTopBarPageIdsAction, topBarPageTreeScrollTopAction } from "../../actionCreators/index";
import { PAGES_TREE_CONTAINER_CLIENT_RECT } from "../../actionTypes";
import { HIDE_CONTEXT_MENU } from '../../../ContextMenu/actionTypes';
import { replaceTagsWithContent } from '../../../oneweb/Text/view/replaceTagsWithContent';
import { UpgradeBanner } from '../UpgradeBanner';
import { shouldShowUpgradeBanner } from '../utils';

const
    AUTO_SCROLL_MARGIN = 50,
    PAGE_NAME_WIDTH = getStyleIntValue(styles, 'pageNameWidth'),
    PAGE_HEIGHT = getStyleIntValue(styles, 'pageHeight');

type PageTreeTopBarState = { pageTreeConstructed: boolean };

class TopBarPagesTree extends React.Component<PagesTreeTopBarProps, PageTreeTopBarState> {
    containerRef: null|Element;
    scrollRef: any;

    constructor(props: PagesTreeTopBarProps) {
        super(props);
        this.containerRef = null;

        this.onDragEnter = this.onDragEnter.bind(this);
        this.onTreeClick = this.onTreeClick.bind(this);
        this.state = {
            pageTreeConstructed: false
        };
    }

    onTreeClick() {
        this.props.dispatch({
            type: HIDE_CONTEXT_MENU
        });
    }

    onDragEnter(params: Record<string, any>) {
        const
            treeFrameHeight = this.scrollRef.scrollRef.current.container.getBoundingClientRect().height,
            li$ = params.node.refs.li,
            offsetTop = li$.offsetTop,
            { state: { treeState: { scrollTop = 0 } } } = this.props;

        let nextScrollTop;
        if (offsetTop - scrollTop < AUTO_SCROLL_MARGIN) {
            nextScrollTop = Math.max(scrollTop - PAGE_HEIGHT, 0);
        } else if (offsetTop - scrollTop > treeFrameHeight - AUTO_SCROLL_MARGIN) {
            nextScrollTop = scrollTop + PAGE_HEIGHT;
        }

        if (nextScrollTop === undefined) return;

        this.props.dispatch(topBarPageTreeScrollTopAction(nextScrollTop));
    }

    shouldComponentUpdate(nextProps) {
        if (!this.state.pageTreeConstructed && nextProps.isShown) {
            this.setState({
                pageTreeConstructed: true
            });
        }
        return true;
    }

    componentDidUpdate(prevProps: PagesTreeTopBarProps) {
        if (
            !prevProps.pagesTreeContainerGetClientRect &&
            this.props.pagesTreeContainerGetClientRect &&
            this.containerRef
        ) {
            this.props.dispatch({
                type: PAGES_TREE_CONTAINER_CLIENT_RECT, payload: this.containerRef.getBoundingClientRect()
            });
        }
    }

    render() {
        const
            {
                isShown,
                themeEditMode,
                state: {
                    globalVariables,
                    site,
                    currentPageId,
                    currentPageName,
                    saveStatus,
                    treeState: { expandedPageIds, scrollTop, activeHoverItemId, isLoading }
                },
                subscriptionType,
                dispatch
            } = this.props,
            { pageTreeConstructed } = this.state,
            currentPageRefId = currentPageId && site.getPageRefByPageId(currentPageId).id,
            showUpgradeBanner = shouldShowUpgradeBanner(site, subscriptionType);

        return (
            <div
                className={styles.container}
                ref={(containerRef) => { this.containerRef = containerRef; }}
                onClick={this.onTreeClick}
            >
                <div
                    className={cx(styles.titleContainer, {
                        [styles.selected]: isShown
                    })}
                    onClick={() => dispatch(openTopBarPopup({ id: Popups.PAGES }))}
                >
                    <Icons.PAGES className={styles.pagesIcn} />
                    <span className={styles.pageNameContainer}>
                        <Msg k="common.PageColon">Page:</Msg>
                        &nbsp;
                        <LongTextTip maxWidth={PAGE_NAME_WIDTH} className={styles.pageName}>
                            {
                                currentPageName ?
                                    replaceTagsWithContent(currentPageName, { site, globalVariables })
                                    : currentPageName
                            }
                        </LongTextTip>
                    </span>
                    <span className={cx(styles.pageToggle, { [styles.opened]: isShown })} />
                </div>
                {
                    (isShown || pageTreeConstructed) &&
                    <Popup
                        containerClassName={cx(styles.popupContainer, { [styles.pageTreeHidden]: !isShown })}
                        bodyClassName={styles.popUpBody}
                    >
                        {!themeEditMode && (
                            <AddPageButton
                                containerClassName={styles.addPageButtonContainer}
                            />
                        )}
                        <div className={cx(styles.pageTreeContainer, { [styles.pageTreeContainerReduced]: showUpgradeBanner })}>
                            <Scrollbar
                                height="100%"
                                onScroll={scrollData => dispatch(topBarPageTreeScrollTopAction(scrollData.scrollTop))}
                                scrollToVertical={scrollTop}
                                ref={ref => { this.scrollRef = ref; }}
                            >
                                {site && (
                                    <PagesTree
                                        site={site}
                                        subscriptionType={subscriptionType}
                                        globalVariables={globalVariables}
                                        // @ts-ignore
                                        saveStatus={saveStatus}
                                        disableTemplateChange={themeEditMode}
                                        onSelect={page => dispatch(selectPageAction(page.pageId))}
                                        draggable={!themeEditMode}
                                        showPageSettings={!themeEditMode}
                                        useSelectionTag={styles.treeSelectionTag}
                                        itemLeftShift={[10, 20]}
                                        className={styles.tree}
                                        selectedId={currentPageRefId}
                                        expandAll={themeEditMode}
                                        expandPageIds={expandedPageIds}
                                        onExpand={(pageIds: PageTreeIds) =>
                                            dispatch(expandTopBarPageIdsAction(pageIds))}
                                        enableContextMenu={!themeEditMode}
                                        activeHoverItemId={activeHoverItemId}
                                        onDragEnter={this.onDragEnter}
                                        isLoading={isLoading}
                                        dispatch={dispatch}
                                    />
                                )}
                            </Scrollbar>
                        </div>
                        {
                            showUpgradeBanner && <UpgradeBanner />
                        }
                    </Popup>
                }
            </div>
        );
    }
}

export default pure(TopBarPagesTree);
