import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";

import { WizardFooter } from "../../WizardFooter";
import { StepDescription } from "../Common/StepDescription";
import { KeywordsField } from "./KeywordsField";

import { DynamicTemplateContext } from "../../Context";
import { validateKeywordsStep } from "../validations";
import { DYNAMIC_TEMPLATE_KEYWORDS_CHANGED, DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA } from "../../../Epic/actionTypes";

import stepStyles from "../steps.css";
import { FIELD_NAMES, STEP_NAMES, TEXT_CHARACTER_LIMIT_ON_STEP_FIELD } from "../../../constants";
import { getKeyForStep } from "../utils";

export const KeywordsStep = () => {
    const dispatch = useDispatch();
    const { concept: { name, type }, gmbKey, keywords, wizardState: { goNext: _goNext } } = useContext(DynamicTemplateContext);
    const counterLimit = TEXT_CHARACTER_LIMIT_ON_STEP_FIELD.keywords.max;
    const [value, setValue] = useState(keywords);
    const [showError, setShowError] = useState(keywords.length > counterLimit);
    const isValid = validateKeywordsStep(value);

    const StepDescriptionProps = {
        title: getKeyForStep(name, type, gmbKey, STEP_NAMES.ABOUT_KEYWORDS, FIELD_NAMES.TITLE),
        /* eslint-disable max-len */
        description: "msg: onboarding.dynamic.step3.description {Let us know what should be mentioned in the \"About us\" section on your website. List up to 3 things.}"
    };

    const keywordsFieldProps = {
        value,
        setValue,
        showError,
        setShowError
    };

    const goNext = () => {
        if (isValid && !showError) {
            dispatch({ type: DYNAMIC_TEMPLATE_KEYWORDS_CHANGED, payload: value });
            _goNext();
        }
    };

    const skipStep = () => {
        dispatch({ type: DYNAMIC_TEMPLATE_KEYWORDS_CHANGED, payload: '' });
        dispatch({
            type: DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA,
            payload: {
                path: "keywords",
                value: ''
            }
        });
        _goNext();
    };

    return (
        <React.Fragment>
            <div className={stepStyles.stepContainer} data-testid="dynamic-onboarding-keywords">
                <StepDescription {...StepDescriptionProps} />
                <KeywordsField {...keywordsFieldProps} />
            </div>
            <WizardFooter disableButton={!isValid || showError} goNext={goNext} skipStep={skipStep} useSkipText />
        </React.Fragment>
    );
};
