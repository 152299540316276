export const
    SAVE_GOOGLE_FONT = 'SAVE_GOOGLE_FONT',
    FONTS_LOADING_IN_PROGRESS = 'FONTS_LOADING_IN_PROGRESS',
    FONTS_LOADING_SUCCESS = 'FONTS_LOADING_SUCCESS',
    NO_PAGE_FONTS_TO_LOAD = 'NO_PAGE_FONTS_TO_LOAD',
    FONTS_LOADING_FAILED = 'FONTS_LOADING_FAILED',
    LOAD_GOOGLE_FONT_FOR_PREVIEW = 'LOAD_GOOGLE_FONT_FOR_PREVIEW',
    FONT_LOADING_FOR_PREVIEW_IN_PROGRESS = 'FONTS_LOADING_FOR_PREVIEW_IN_PROGRESS',
    FONT_LOADING_FOR_PREVIEW_SUCCESS = 'FONTS_LOADING_FOR_PREVIEW_SUCCESS',
    FONT_LOADING_FOR_PREVIEW_FAILED = 'FONTS_LOADING_FOR_PREVIEW_FAILED';
