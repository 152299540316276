import React from 'react';
import cx from 'classnames';
import { connect } from "react-redux";
import styles from '../FileChooserGrid.css';
import Resource from "../../../../../redux/modules/children/fileChooser/Resource";
import {
    isResourceSelected,
    resourceCellMapDispatchToProps,
    getFcTitleAndLabel
} from "../../utils";
import { makeClickHandler } from '../../../makeClickHandler';
import type { AppState } from "../../../../../redux/modules/flowTypes";
import type { GridResourceCellPropTypes } from "../../flowTypes";
import { GRID_SHORT_NAME_LENGTH } from "../../constants";
import { ProgressBar } from "../../ProgressBar";
import { ResourceCellImage } from "./ResourceCellImage";
import { FmImagePreview } from "../../../../../components/FileManager/imagePreview/FmImagePreview";
import type { FmImagePreviewDimensionsT } from "../../../../../components/FileManager/imagePreview/types";
import { getDAL } from "../../../../../../dal/index";
import { FmDeleteHoverBoxCom } from "../FmDeleteHoverBoxCom";

class ResourceCell extends React.Component<GridResourceCellPropTypes> {
    onClick: React.MouseEventHandler;

    constructor() {
        // @ts-ignore
        super();
        this.onClick = makeClickHandler({
            onClick: () => {
                const { resource, toggleResourceSelection } = this.props;
                toggleResourceSelection(resource);
            },
            onDbClick: () => this.props.onResourceDoubleClick()
        });
    }

    _isSelected() {
        return isResourceSelected(this.props.resource, this.props.selection);
    }

    previewDimensions: FmImagePreviewDimensionsT = () =>
        getDAL()
            .getResourceMetadata(this.props.resource._getFullPath())
            .then(({ body: { width, height } }) => ({ width, height }));

    render() {
        const
            resource: Resource = this.props.resource,
            { isProxy, isFreeOneComVideo } = this.props,
            isSelected = this._isSelected();

        const { label, title } = getFcTitleAndLabel(resource.getName(), GRID_SHORT_NAME_LENGTH);

        return (
            <div
                className={cx(styles.cell, styles.resourceCell, { [styles.selected]: isSelected })}
                onClick={this.onClick}
            >
                <ResourceCellImage resource={resource} shouldLoad={!isProxy} isFreeOneComVideo={isFreeOneComVideo} />
                <div className={styles.cellOverlay}>
                    <span className={styles.cellOverlayLabel} data-title={title}>
                        {label}
                    </span>
                    <span className={styles.cellOverlayActions}>
                        {
                            !resource.isVideo() &&
                            <FmImagePreview
                                url={resource.getImagePath(450, 300, { withoutEnlargement: true, crop: false })}
                                title={resource.getName()}
                                dimensions={this.previewDimensions}
                                btnClassName={styles.cellOverlayViewBtn}
                                imgContainerClassName={styles.cellOverlayPreviewImgContainer}
                            />
                        }
                        {
                            !(resource.isVideo() && resource.isGhost) &&
                            <FmDeleteHoverBoxCom resource={resource} hideDelete={isFreeOneComVideo} />
                        }
                    </span>
                </div>
                <span className={styles.resourceCellCornerSelectedIcn} />
                <ProgressBar show={resource.isGhost && resource.isLoading} className={styles.progressBar} />
            </div>
        );
    }
}

const mapStateToProps = ({ fileChooser }: AppState) => ({
    selection: fileChooser.selection,
    isMultiSelect: fileChooser.isMultiSelect
});

export default connect(mapStateToProps, resourceCellMapDispatchToProps)(ResourceCell);
