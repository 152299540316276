export function getScrollTop(): number {
    if (document) {
        if (document.documentElement && document.documentElement.scrollTop) {
            return document.documentElement.scrollTop;
        } else if (document.body && document.body.scrollTop) {
            return document.body.scrollTop;
        }
    }
    throw new Error('wrong environment?');
}

export const getWindowOrigin = (win: any) => {
    const location = win.location;
    // IE and some other browsers don't have window.location.origin
    // See: http://tosbourn.com/a-fix-for-window-location-origin-in-internet-explorer/
    return location.origin ||
        (location.protocol + "//" + location.hostname + (location.port ? ':' + location.port : ''));
};
