import React from "react";
import Combobox from "../../../view/common/Combobox/index";
import { createOption } from "../../../view/common/Combobox/utils";
import type { Props } from "./flowTypes";
import mctaComboboxStyles from '../../componentMainActions/MctaCombobox/MctaCombobox.css';

const
    defaultWidth = 62,
    defaultOptions: number[] = [];

export const MIN_TEXT_FONT_SIZE = 6;
export const MAX_TEXT_FONT_SIZE = 150;

for (let i = MIN_TEXT_FONT_SIZE; i <= MAX_TEXT_FONT_SIZE; i += 1) {
    defaultOptions.push(i);
}

class FontSizeSelector extends React.Component<Props> {
    options: number[];

    constructor(props: Props) {
        super(props);
        this.options = [...defaultOptions];
    }

    getFontSize(size: number): Array<number> {
        const options = [...this.options];

        // Add size if not found in current options
        if (options.indexOf(size) === -1) {
            options.push(size);
        }

        options.sort((a, b) => a - b);

        return options;
    }

    render() {
        const { mcta, selected, className, searchable, width, onChangeAction, menuStyle, dispatch } = this.props;

        return (
            <div {... (mcta ? { className: mctaComboboxStyles.mctaCombobox } : {})}>
                <Combobox
                    style={{ width: (width || defaultWidth) }}
                    className={className}
                    value={selected}
                    searchable={searchable}
                    menuStyle={menuStyle}
                    options={this.getFontSize(selected).map(createOption)}
                    onChange={({ value }) => dispatch({ type: onChangeAction, payload: value })}
                />
            </div>
        );
    }
}

export {
    FontSizeSelector as default,
    FontSizeSelector,
};

