/* eslint-disable max-len */

import * as R from "ramda";
import * as globalStylesMapper from "../../../../dal/pageMapAdapter/mappers/Globalstyles/index";
import { getInitializedTemplate } from "../../oneweb/Template/epics/template/index";
import { DataPageSet } from "../../../../dal/model/index";
import { mapPageDataToComponentsMap } from "../../../../dal/pageMapAdapter/index";
import DataPage from "../../../../dal/model/DataPage";
import DataPageTemplate from "../../../../dal/model/DataPageTemplate";
import DataPageStylesheet from "../../../../dal/model/DataPageStylesheet";
import type { SiteSettings } from "../../App/epics/siteSettings/flowTypes";
import type { Stylesheets } from "../../Workspace/epics/stylesheets/flowTypes";
import type { ThemeColorDataType } from "../flowTypes";
import type { ComponentsMap } from "../../../redux/modules/children/workspace/flowTypes";
import type { TemplateComponentWithMobileData } from "../../oneweb/Template/flowTypes";
import { globalVariablesFrom } from "../../App/epics/globalVariablesEpic";
import { templateLocale2Selector } from "../../oneweb/Template/epics/template/selectorActionTypes";
import type { GeneralGlobalData } from "../../SiteSettings/General/generalGlobalDataEpic/generalGlobalDataEpic";

type Props = {
    dataPages: Array<DataPage>,
    dataTemplate: DataPageTemplate,
    dataStylesheet: DataPageStylesheet,
    currentPageId: string,
    siteSettings: SiteSettings,
    themeColors: ThemeColorDataType,
    localizationsByTemplateId?: Record<string, any>,
    generalData?: GeneralGlobalData,
};

type ReturnType = {
    page: DataPage,
    componentsMap: ComponentsMap,
    modifiedSiteSettings: SiteSettings,
    modifiedStylesheets: Stylesheets,
    template: TemplateComponentWithMobileData,
};
const addInMemoryThemeChangesToSiteSettings = (siteSettings: SiteSettings, generalData?: GeneralGlobalData) => {
    const { openingHours, ...generalDataWithoutOpeningHours } = generalData || {};
    return {
        ...siteSettings,
        themeSettingsData: {
            ...siteSettings.themeSettingsData,
            autoColorMode: true
        },
        generalData: {
            ...siteSettings.generalData,
            ...generalDataWithoutOpeningHours,
            ...(openingHours ? { openingHours } : {}), // show sample data from template, instead of 'closed' for all days from user's (missing) data
        },
        mobile: {
            ...siteSettings.mobile,
            ...(generalData || {}).mobile
        }
    };
};

export const addInMemoryThemeChangesToStylesheets = (stylesheets: Stylesheets, themeColors: ThemeColorDataType) =>
    (themeColors ? R.assocPath(['theme', 'colors'], themeColors)(stylesheets) : stylesheets);

const
    processDataForThemePreview = ({
        dataPages,
        dataTemplate,
        dataStylesheet,
        currentPageId,
        siteSettings,
        themeColors,
        localizationsByTemplateId,
        generalData,
    }: Props): ReturnType => {
        const
            page = dataPages.filter(({ id }) => id === currentPageId)[0],
            stylesheets = globalStylesMapper.to(dataStylesheet),
            pageDataSet = new DataPageSet({
                page,
                template: dataTemplate,
                stylesheet: stylesheets
            }),
            template = getInitializedTemplate(dataTemplate, dataStylesheet),
            modifiedSiteSettings = addInMemoryThemeChangesToSiteSettings(siteSettings, generalData),
            modifiedStylesheets = addInMemoryThemeChangesToStylesheets(stylesheets, themeColors);
        let componentsMap;
        if (localizationsByTemplateId) {
            const
                templateI18n = localizationsByTemplateId[template.id],
                globalVariables = globalVariablesFrom({
                // siteSettings may be unavailable in restore-backup preview
                    generalGlobalData: (
                    // previewGeneralData ||
                        (
                            (
                                siteSettings &&
                            siteSettings.generalData
                            ) ||
                        {}
                        )
                    ),
                    locale: templateLocale2Selector(template),
                    i18n: templateI18n
                });
            componentsMap = mapPageDataToComponentsMap(
                pageDataSet,
                null,
                false,
                false,
                globalVariables,
                true,
            );
        } else {
            componentsMap = mapPageDataToComponentsMap(pageDataSet);
        }
        return {
            page,
            componentsMap,
            // @ts-ignore
            modifiedSiteSettings,
            modifiedStylesheets,
            template,
        };
    };

export {
    processDataForThemePreview
};
