/* eslint-disable max-len */

import * as R from 'ramda';
import { makeSiteSettingsChildEpic } from "../../App/epics/siteSettings/makeSiteSettingsChildEpic";
import * as constants from './constants';
import * as actions from "./propertiesPanel/actions";
import type { MobileSiteSettings } from "../../Mobile/types";
import { setColorOpacity, getAlpha, setColorAlpha } from "../../oneweb/Background/utils/index";
import * as colorMapper from "../../../../dal/pageMapAdapter/mappers/Base/color";
import type { Color } from "../../../mappers/flowTypes";
import { MenuStyleDefaults } from "./constants";
import { toCss } from "../../../mappers/color";
import { MobileHeaderTitleType } from '../../Mobile/header/constants';
import { PAGE_OR_TEMPLATE_IMPORT_BEFORE_SAVE } from "../../TemplateSelector_DEPRECATED/actionTypes";
import { receiveOnly, when } from "../../../epics/makeCondition";
import { generalGlobalDataEpic, updateMobileGlobalDataOnTemplateImport } from "../../SiteSettings/General/generalGlobalDataEpic/generalGlobalDataEpic";
import { FILL_SPECIFIC_SITE_SETTINGS_DURING_PAGE_OR_TEMPLATE_IMPORT } from "../../App/epics/siteSettings/actionTypes";

export const mobileGlobalDataDefaultState: MobileSiteSettings = {
    useCustomStyle: false,
    pin: 0,
    logo: {
        show: false,
    },
    title: {
        show: true,
        type: MobileHeaderTitleType.PAGE,
        style: {
            fontWeight: 'normal',
            fontStyle: 'normal',
            underline: false,
            color: constants.HEADER_TITLE_COLOR,
            themeColor: constants.THEME_HEADER_TITLE_COLOR,
        },
    },
    background: {
        useColor: true,
        themeColor: constants.THEME_HEADER_BG_COLOR,
        color: colorMapper.toHsl(constants.HEADER_BG_COLOR),
        showBottomShadow: true,
        showBottomBorder: false,
    },
    menu: {
        closed: {
            iconThemeColor: constants.MenuStyleDefaults.THEME_CLOSED_ICON_COLOR,
            iconColor: colorMapper.toHsl(MenuStyleDefaults.CLOSED_ICON_COLOR)
        },
        open: {
            iconThemeColor: constants.MenuStyleDefaults.THEME_OPEN_ICON_COLOR,
            iconColor: colorMapper.toHsl(MenuStyleDefaults.OPEN_ICON_COLOR),
            fontFamily: undefined,
            bold: false,
            italic: false,
            underline: false,
            fontSize: MenuStyleDefaults.FONT_SIZE,
            lineHeight: MenuStyleDefaults.LINE_HEIGHT,
            themeColor: constants.MenuStyleDefaults.THEME_OPEN_TEXT_COLOR,
            color: colorMapper.toHsl(MenuStyleDefaults.OPEN_TEXT_COLOR),
            backgroundThemeColor: constants.MenuStyleDefaults.THEME_OPEN_BG_COLOR,
            backgroundColor: colorMapper.toHsl(MenuStyleDefaults.OPEN_BG_COLOR),
        },
        selected: {
            bold: true,
            italic: false,
            underline: false,
            themeColor: constants.MenuStyleDefaults.THEME_SELECTED_TEXT_COLOR,
            color: colorMapper.toHsl(MenuStyleDefaults.SELECTED_TEXT_COLOR),
            backgroundThemeColor: constants.MenuStyleDefaults.THEME_SELECTED_BG_COLOR,
            backgroundColor: colorMapper.toHsl(MenuStyleDefaults.SELECTED_BG_COLOR)
        }
    }
};

const getColorWithNewOpacity = (color: Color, opacity: number): Color => {
    const newColor = color.slice(0) || colorMapper.toHsl('#fff');
    newColor[4] = opacity;
    return newColor as Color;
};

const getColorWithOldOpacity = (oldColor: Color, newColor: Color): Color => {
    const opacity = oldColor ? (getAlpha(oldColor) || 1) : 1;
    return setColorAlpha(newColor, opacity);
};

export const mobileGlobalDataEpic = makeSiteSettingsChildEpic/* ::<MobileSiteSettings, empty> */({
    key: 'mobile',
    defaultState: mobileGlobalDataDefaultState,
    undo: true,
    updaters: [
        {
            conditions: [
                receiveOnly(generalGlobalDataEpic.valueActionType),
                when(PAGE_OR_TEMPLATE_IMPORT_BEFORE_SAVE, a => a.payload.emptySiteImport)
            ],
            reducer: ({ state, values: [{ websiteTitle, logoAsset }] }) => {
                return {
                    state: updateMobileGlobalDataOnTemplateImport({ websiteTitle, logoAsset })(state as MobileSiteSettings),
                    updateReason: PAGE_OR_TEMPLATE_IMPORT_BEFORE_SAVE
                };
            }
        },
        {
            conditions: [FILL_SPECIFIC_SITE_SETTINGS_DURING_PAGE_OR_TEMPLATE_IMPORT],
            reducer: ({ state, values: [{ designSiteSettings }] }) => {
                let newSettings = state;
                const setting = R.path(['mobile', 'pin'], designSiteSettings);
                if (!R.isNil(setting)) {
                    newSettings = R.assocPath(['pin'], setting, newSettings);
                }
                if (!R.equals(state, newSettings)) {
                    return {
                        state: newSettings,
                        updateReason: FILL_SPECIFIC_SITE_SETTINGS_DURING_PAGE_OR_TEMPLATE_IMPORT
                    };
                }

                return { state };
            }
        }
    ],
    handleActions: {
        [actions.TOGGLE_MVE_SHOW_LOGO_ACTION]: state => ({ ...state, logo: { ...state.logo, show: !state.logo.show } }),
        [actions.TOGGLE_MVE_SHOW_TITLE_ACTION]: state => ({ ...state, title: { ...state.title, show: !state.title.show } }),
        [actions.SET_MVE_TITLE_TYPE_ACTION]: (state, { payload: type }) => ({ ...state, title: { ...state.title, type } }),
        [actions.TOGGLE_MVE_USE_CUSTOM_STYLE_ACTION]: state => ({ ...state, useCustomStyle: !state.useCustomStyle }),

        // Background
        [actions.TOGGLE_INCLUDE_MVE_BACKGROUND_COLOR_ACTION]: state => ({
            ...state,
            background: {
                ...state.background,
                useColor: !state.background.useColor
            }
        }),
        [actions.CHANGE_MVE_BACKGROUND_COLOR_ACTION]: (state, { payload: { color } }) => ({
            ...state,
            background: {
                ...state.background,
                color
            }
        }),
        [actions.CHANGE_MVE_BACKGROUND_COLOR_ACTION_AUTO_COLOR]: (state, { payload: { themeColor } }) => ({
            ...state,
            background: {
                ...state.background,
                themeColor
            }
        }),
        [actions.CHANGE_MVE_BACKGROUND_OPACITY_ACTION]: (state, { payload: opacity }) => ({
            ...state,
            background: {
                ...state.background,
                color: setColorOpacity(
                    state.background.color,
                    opacity
                )
            }
        }),
        [actions.CHANGE_MVE_FIXED_HEADER_ACTION]: (state, { payload: { pin } }) => ({ ...state, pin }),
        [actions.TOGGLE_USE_MVE_BOTTOM_BACKGROUND_SHADOW_ACTION]: state => ({
            ...state,
            background: {
                ...state.background,
                showBottomShadow: !state.background.showBottomShadow
            }
        }),
        [actions.TOGGLE_USE_MVE_BOTTOM_BACKGROUND_BORDER_ACTION]: state => ({
            ...state,
            background: {
                ...state.background,
                showBottomBorder: !state.background.showBottomBorder
            }
        }),

        // menu
        [actions.CHANGE_MVE_MENU_STYLE_CLOSED_ICON_COLOR_ACTION]: (state, { payload: { color } }) => ({
            ...state,
            menu: {
                ...state.menu,
                closed: {
                    ...state.menu.closed,
                    iconColor: getColorWithOldOpacity(
                        state.menu.closed.iconColor,
                        color
                    )
                }
            }
        }),
        [actions.CHANGE_MVE_MENU_STYLE_CLOSED_ICON_COLOR_ACTION_AUTO_COLOR]: (state, { payload: { themeColor } }) => ({
            ...state,
            menu: {
                ...state.menu,
                closed: {
                    ...state.menu.closed,
                    iconThemeColor: themeColor,
                }
            }
        }),
        [actions.CHANGE_MVE_MENU_STYLE_CLOSED_OPACITY_ACTION]: (state, { payload: value }) => ({
            ...state,
            menu: {
                ...state.menu,
                closed: {
                    ...state.menu.closed,
                    iconColor: getColorWithNewOpacity(
                        state.menu.closed.iconColor,
                        value
                    )
                }
            }
        }),
        [actions.CHANGE_MVE_MENU_STYLE_OPEN_ICON_COLOR_ACTION]: (state, { payload: { color } }) => ({
            ...state,
            menu: {
                ...state.menu,
                open: {
                    ...state.menu.open,
                    iconColor: getColorWithOldOpacity(
                        state.menu.open.iconColor,
                        color
                    )
                }
            }
        }),
        [actions.CHANGE_MVE_MENU_STYLE_OPEN_ICON_COLOR_ACTION_AUTO_COLOR]: (state, { payload: { themeColor } }) => ({
            ...state,
            menu: {
                ...state.menu,
                open: {
                    ...state.menu.open,
                    iconThemeColor: themeColor,
                }
            }
        }),
        [actions.CHANGE_MVE_MENU_STYLE_OPEN_OPACITY_ACTION]: (state, { payload: value }) => ({
            ...state,
            menu: {
                ...state.menu,
                open: {
                    ...state.menu.open,
                    iconColor: getColorWithNewOpacity(
                        state.menu.open.iconColor,
                        value
                    )
                }
            }
        }),
        [actions.CHANGE_MVE_MENU_STYLE_OPEN_FONTFAMILY_ACTION]: (state, { payload: fontFamily }) => ({
            ...state,
            menu: {
                ...state.menu,
                open: {
                    ...state.menu.open,
                    fontFamily
                }
            }
        }),
        [actions.TOGGLE_MVE_MENU_STYLE_OPEN_BOLD_ACTION]: state => ({
            ...state,
            menu: {
                ...state.menu,
                open: {
                    ...state.menu.open,
                    bold: !state.menu.open.bold
                }
            }
        }),
        [actions.TOGGLE_MVE_MENU_STYLE_OPEN_ITALIC_ACTION]: state => ({
            ...state,
            menu: {
                ...state.menu,
                open: {
                    ...state.menu.open,
                    italic: !state.menu.open.italic
                }
            }
        }),
        [actions.TOGGLE_MVE_MENU_STYLE_OPEN_UNDERLINE_ACTION]: state => ({
            ...state,
            menu: {
                ...state.menu,
                open: {
                    ...state.menu.open,
                    underline: !state.menu.open.underline
                }
            }
        }),
        [actions.TOGGLE_MVE_MENU_STYLE_OPEN_FONTSIZE_ACTION]: (state, { payload: fontSize }) => ({
            ...state,
            menu: {
                ...state.menu,
                open: {
                    ...state.menu.open,
                    fontSize
                }
            }
        }),
        [actions.TOGGLE_MVE_MENU_STYLE_OPEN_LINEHEIGHT_ACTION]: (state, { payload: lineHeight }) => ({
            ...state,
            menu: {
                ...state.menu,
                open: {
                    ...state.menu.open,
                    lineHeight
                }
            }
        }),
        [actions.CHANGE_MVE_MENU_STYLE_OPEN_TEXT_COLOR_ACTION_AUTO_COLOR]: (state, { payload: { themeColor } }) => ({
            ...state,
            menu: {
                ...state.menu,
                open: {
                    ...state.menu.open,
                    themeColor,
                }
            }
        }),
        [actions.CHANGE_MVE_MENU_STYLE_OPEN_TEXT_COLOR_ACTION]: (state, { payload: { color } }) => ({
            ...state,
            menu: {
                ...state.menu,
                open: {
                    ...state.menu.open,
                    color
                }
            }
        }),
        [actions.CHANGE_MVE_MENU_STYLE_OPEN_BG_COLOR_ACTION_AUTO_COLOR]: (state, { payload: { themeColor } }) => ({
            ...state,
            menu: {
                ...state.menu,
                open: {
                    ...state.menu.open,
                    backgroundThemeColor: themeColor,
                }
            }
        }),
        [actions.CHANGE_MVE_MENU_STYLE_OPEN_BG_COLOR_ACTION]: (state, { payload: { color: backgroundColor } }) => ({
            ...state,
            menu: {
                ...state.menu,
                open: {
                    ...state.menu.open,
                    backgroundColor
                }
            }
        }),
        [actions.TOGGLE_MVE_MENU_STYLE_SELECTED_BOLD_ACTION]: state => ({
            ...state,
            menu: {
                ...state.menu,
                selected: {
                    ...state.menu.selected,
                    bold: !state.menu.selected.bold
                }
            }
        }),
        [actions.TOGGLE_MVE_MENU_STYLE_SELECTED_ITALIC_ACTION]: state => ({
            ...state,
            menu: {
                ...state.menu,
                selected: {
                    ...state.menu.selected,
                    italic: !state.menu.selected.italic
                }
            }
        }),
        [actions.TOGGLE_MVE_MENU_STYLE_SELECTED_UNDERLINE_ACTION]: state => ({
            ...state,
            menu: {
                ...state.menu,
                selected: {
                    ...state.menu.selected,
                    underline: !state.menu.selected.underline
                }
            }
        }),
        [actions.CHANGE_MVE_MENU_STYLE_SELECTED_TEXT_COLOR_ACTION_AUTO_COLOR]: (state, { payload: { themeColor } }) => ({
            ...state,
            menu: {
                ...state.menu,
                selected: {
                    ...state.menu.selected,
                    themeColor,
                }
            }
        }),
        [actions.CHANGE_MVE_MENU_STYLE_SELECTED_TEXT_COLOR_ACTION]: (state, { payload: { color } }) => ({
            ...state,
            menu: {
                ...state.menu,
                selected: {
                    ...state.menu.selected,
                    color
                }
            }
        }),
        [actions.CHANGE_MVE_MENU_STYLE_SELECTED_BG_COLOR_ACTION_AUTO_COLOR]: (state, { payload: { themeColor } }) => ({
            ...state,
            menu: {
                ...state.menu,
                selected: {
                    ...state.menu.selected,
                    backgroundThemeColor: themeColor,
                }
            }
        }),
        [actions.CHANGE_MVE_MENU_STYLE_SELECTED_BG_COLOR_ACTION]: (state, { payload: { color: backgroundColor } }) => ({
            ...state,
            menu: {
                ...state.menu,
                selected: {
                    ...state.menu.selected,
                    backgroundColor
                }
            }
        }),
        [actions.CHANGE_MVE_MENU_STYLE_SELECTED_BG_OPACITY_ACTION]: (state, { payload }) => ({
            ...state,
            menu: {
                ...state.menu,
                selected: {
                    ...state.menu.selected,
                    backgroundColor: getColorWithNewOpacity(
                        state.menu.selected.backgroundColor,
                        payload
                    )
                }
            }
        }),

        // Title style
        [actions.CHANGE_MVE_TITLE_FONT_FAMILY_ACTION]: (state, { payload: fontFamily }) => ({
            ...state,
            title: {
                ...state.title,
                style: {
                    ...state.title.style,
                    fontFamily
                }
            }
        }),
        [actions.TOGGLE_MVE_TITLE_FONT_WEIGHT_ACTION]: state => ({
            ...state,
            title: {
                ...state.title,
                style: {
                    ...state.title.style,
                    fontWeight: state.title.style.fontWeight === 'normal' ? 'bold' : 'normal'
                }
            }
        }),
        [actions.TOGGLE_MVE_TITLE_FONT_STYLE_ACTION]: state => ({
            ...state,
            title: {
                ...state.title,
                style: {
                    ...state.title.style,
                    fontStyle: state.title.style.fontStyle === 'normal' ? 'italic' : 'normal'
                }
            }
        }),
        [actions.TOGGLE_MVE_TITLE_UNDERLINE_ACTION]: state => ({
            ...state,
            title: {
                ...state.title,
                style: {
                    ...state.title.style,
                    underline: !state.title.style.underline
                }
            }
        }),
        [actions.CHANGE_TITLE_TEXT_COLOR_ACTION]: (state, { payload: { color } }) => ({
            ...state,
            title: {
                ...state.title,
                style: {
                    ...state.title.style,
                    color: toCss(color)
                }
            }
        }),
        [actions.CHANGE_TITLE_TEXT_COLOR_ACTION_AUTO_COLOR]: (state, { payload: { themeColor } }) => ({
            ...state,
            title: {
                ...state.title,
                style: {
                    ...state.title.style,
                    themeColor,
                }
            }
        }),
    }
});
