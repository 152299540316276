const CodeComponentHostKinds = {
    headTemplate: 'headTemplate',
    headPage: 'headPage',
    bodyTemplate: 'bodyTemplate',
    bodyPage: 'bodyPage'
} as const;

export {
    CodeComponentHostKinds
};
