import * as R from 'ramda';
import * as actionTypes from './actionTypes';
import * as updateReasons from './updateReasons';
import { when } from '../makeCondition';

import type { MakeUndoStateUpdaters } from './helpers.flowTypes';

const
    makeUndoRedoUpdaters: MakeUndoStateUpdaters<any, any, any /* UNDO / REDO update reasons are private */> =
        ({
            valueActionType,
            undoablePaths,
            undoablePathsSetter,
        }) => {
            const undoRedoEpicUpdaterFactory = ({
                triggerActionType,
                updateReason
            }) => (
                {
                    conditions: [
                        when(triggerActionType,
                            ({ payload: { affectedEpics } }) =>
                                affectedEpics[valueActionType] !== undefined)
                    ],
                    reducer: ({ values: [{ affectedEpics }], state: epicState, scope }) => {
                        const
                            { newStateParts } = affectedEpics[valueActionType],
                            transforamtions = newStateParts.reduce(
                                (acc, state, index) => {
                                    // @ts-ignore
                                    const setter = undoablePathsSetter && undoablePathsSetter[undoablePaths[index]]
                                        // @ts-ignore
                                        ? undoablePathsSetter[undoablePaths[index]](state)
                                        : R.assocPath(undoablePaths[index], state);

                                    return [...acc, setter];
                                },
                                []
                            );

                        return { state: R.pipe(...transforamtions)(epicState), updateReason, scope };
                    }
                }
            );

            return {
                undoEpicUpdater: undoRedoEpicUpdaterFactory({
                    triggerActionType: actionTypes.UNDO,
                    updateReason: updateReasons.UNDO
                }),
                redoEpicUpdater: undoRedoEpicUpdaterFactory({
                    triggerActionType: actionTypes.REDO,
                    updateReason: updateReasons.REDO
                })
            };
        };

export {
    makeUndoRedoUpdaters
};
