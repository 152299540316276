import * as R from "ramda";
import makeEpic from "../../../../../epics/makeEpic";
import valueActionType from "./valueActionType";
import { ReceiveOnlySelectedComponentSelector } from "../../../../Workspace/epics/componentsEval/selectorActionTypes";
import { ComponentsEvalValueActionType } from "../../../../Workspace/epics/componentsEval/valueActionType";
import {
    isTransient as isTransientUserInteractionMode
} from "../../../../Workspace/epics/componentsEval/userInteractionMutations/interactionModes";
import {
    MENU_PROP_PANEL_MENU_GLOBAL_STYLE_BTN_PRESSED,
    MENU_PROP_PANEL_SUBMENU_GLOBAL_STYLE_BTN_PRESSED,
    MENU_PROP_PANEL_MENU_GLOBAL_THEME_STYLE_BTN_PRESSED,
    MENU_PROP_PANEL_SUBMENU_GLOBAL_THEME_STYLE_BTN_PRESSED,
    MENU_PROP_PANEL_DROPDOWN_WIDTH_CHANGE,
    MENU_RENDER_DIMENSIONS_CHANGED
} from "../../actionTypes";
import { openGlobalStylesDialogAC } from "../../../../presentational/GlobalstyleDialogInvoker/index";
import gsKind from "../../globalStyle/kind";
import menuKind from "../../kind";
import { styleGlobalId, submenuStyleGlobalId, styleThemeGlobalId, submenuThemeStyleGlobalId } from "../../selectors";
import { UPDATE_STYLESHEET } from "../../../../Workspace/epics/stylesheets/actionTypes";
import { getAllStylesheets, getStyleById } from "../../../../Workspace/epics/stylesheets/selectors";
import * as path from "../../../../../mappers/path";
import SHADOW_RENDERER_KIND from "../../../../ShadowRenderer/view/SHADOW_RENDERER_KIND";
import WORKSPACE_COMPONENT_KIND from "../../../../Workspace/epics/componentsEval/WORKSPACE_COMPONENT_KIND";
import { PAGE_DATA_LOAD_REQUEST } from "../../../../PagesTree/actionTypes";
import { addComponentAdjustmentDataEntry } from "../../../../Workspace/epics/componentsEval/adjustmentDataDispatchCache";
import ThemeStyleMenuType from "../../globalStyle/themeColorKind";
import WORKSPACE_STATUS_EPIC_VALUE from "../../../../Workspace/epics/status/valueActionType";
import { READY } from "../../../../Workspace/epics/status/types";
import { whenWithSelector, receiveOnly } from "../../../../../epics/makeCondition";
import { siteDataValueActionType } from "../../../../App/epics/siteData/valueActionType";
import { SiteSettingsSelector } from "../../../../App/epics/siteSettings/selectorActionTypes";
import { getIsMenuCartAllowed } from "../../utils";
import { componentsInHeaderFooterSectionsEpic } from "../../../Section/epics/componentsInHeaderFooterSectionsEpic";

const
    NonTransientComponentsMapSelector = whenWithSelector(
        ComponentsEvalValueActionType,
        a => a.state.componentsMap,
        a => !isTransientUserInteractionMode(a.payload.scope.userInteraction.mode)
    );

const makeOpenGsDialogUpdater = ({ triggerActionType, globalStyleIdSelector, kind }) => ({
    conditions: [ReceiveOnlySelectedComponentSelector, triggerActionType],
    reducer: ({ values: [selectedComponent], state, scope }) => {
        if (!selectedComponent || selectedComponent.kind !== menuKind) {
            return {
                state
            };
        }
        return {
            state,
            scope,
            actionToDispatch: openGlobalStylesDialogAC(globalStyleIdSelector(selectedComponent), kind)
        };
    }
});

export default makeEpic({
    defaultState: {
        isMenuCartAllowed: false
    },
    defaultScope: {
        shadowRenderCache: {},
        renderCache: {}
    },
    valueActionType,
    updaters: [
        makeOpenGsDialogUpdater({
            triggerActionType: MENU_PROP_PANEL_MENU_GLOBAL_STYLE_BTN_PRESSED,
            globalStyleIdSelector: styleGlobalId,
            kind: gsKind
        }),
        makeOpenGsDialogUpdater({
            triggerActionType: MENU_PROP_PANEL_SUBMENU_GLOBAL_STYLE_BTN_PRESSED,
            globalStyleIdSelector: submenuStyleGlobalId,
            kind: gsKind
        }),
        makeOpenGsDialogUpdater({
            triggerActionType: MENU_PROP_PANEL_MENU_GLOBAL_THEME_STYLE_BTN_PRESSED,
            globalStyleIdSelector: styleThemeGlobalId,
            kind: ThemeStyleMenuType
        }),
        makeOpenGsDialogUpdater({
            triggerActionType: MENU_PROP_PANEL_SUBMENU_GLOBAL_THEME_STYLE_BTN_PRESSED,
            globalStyleIdSelector: submenuThemeStyleGlobalId,
            kind: ThemeStyleMenuType
        }),
        {
            conditions: [MENU_PROP_PANEL_DROPDOWN_WIDTH_CHANGE],
            reducer: ({ values: [updatedDropDown], state, scope }) => {
                let styleSheets = getAllStylesheets(updatedDropDown.stylesheets);
                let width = updatedDropDown.width;
                let updatedStylesheetIds = updatedDropDown.updatedStylesheetIds;
                let multipleActionsToDispatch: MultipleActionsToDispatch<any> = [];
                updatedStylesheetIds.forEach(updatedStylesheetId => {
                    let stylesheet = getStyleById(updatedStylesheetId)(styleSheets);
                    let updatedStylesheet: Record<string, any> = R.assoc(path.cascadeWidth, width, stylesheet);
                    multipleActionsToDispatch.push({
                        type: UPDATE_STYLESHEET,
                        payload: updatedStylesheet
                    });
                });
                return {
                    state,
                    scope,
                    multipleActionsToDispatch
                };
            }
        },
        {
            keepFullActions: true,
            conditions: [MENU_RENDER_DIMENSIONS_CHANGED],
            reducer: ({ values: [{ payload, forwardTo }], state, scope }) => {
                const componentId = forwardTo.id;
                let { shadowRenderCache, renderCache } = scope;

                if (forwardTo.kind === SHADOW_RENDERER_KIND) {
                    shadowRenderCache = { ...shadowRenderCache, [componentId]: payload };
                } else if (forwardTo.kind === WORKSPACE_COMPONENT_KIND) {
                    renderCache = { ...renderCache, [componentId]: payload };
                } else {
                    throw new Error("invalid forwardTo.kind: " + forwardTo.kind);
                }

                const renderDimensions = renderCache[componentId],
                    shadowRenderDimensions = shadowRenderCache[componentId];

                if (!renderDimensions || !shadowRenderDimensions) {
                    return {
                        state,
                        scope: { ...scope, shadowRenderCache, renderCache }
                    };
                }

                addComponentAdjustmentDataEntry(componentId, {
                    minDimensionsForHook: {
                        width: Math.min(renderDimensions.width, shadowRenderDimensions.width),
                        height: Math.min(renderDimensions.height, shadowRenderDimensions.height)
                    }
                });
                return {
                    state,
                    scope: { ...scope, shadowRenderCache, renderCache }
                };
            }
        },
        {
            conditions: [PAGE_DATA_LOAD_REQUEST],
            reducer: ({ state, scope }) => {
                return {
                    state,
                    scope: { ...scope, shadowRenderCache: {}, renderCache: {} }
                };
            }
        },
        {
            conditions: [
                NonTransientComponentsMapSelector,
                SiteSettingsSelector,
                receiveOnly(siteDataValueActionType),
                receiveOnly(componentsInHeaderFooterSectionsEpic.valueActionType),
                WORKSPACE_STATUS_EPIC_VALUE
            ],
            reducer: ({ state, scope, values: [componentsMap, siteSettings, siteMap, { headerSection }, status] }) => {
                if (status !== READY) return { state, scope };

                const isMenuCartAllowed = getIsMenuCartAllowed(componentsMap, siteSettings, siteMap, headerSection.cmpsInSection);
                const newState = state.isMenuCartAllowed === isMenuCartAllowed ? state : {
                    ...state,
                    isMenuCartAllowed
                };
                return {
                    state: newState,
                    scope
                };
            }
        }
    ]
});
