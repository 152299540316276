import CALL_API from "../../../../redux/middleware/api/CALL_API";
import { WebShopMHFDataActionTypes } from "./actionTypes";

export const
    fetchWebShopMHFDataOnPageLoad = {
        [CALL_API]: {
            types: WebShopMHFDataActionTypes,
            endpoint: 'fetchWebShopMHFData'
        },
    },
    fetchWebShopMHFData = {
        ...fetchWebShopMHFDataOnPageLoad,
        amendToPrevious: true,
    };

