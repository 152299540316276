import * as main from './pages/main';
import * as style from './pages/style';
import * as buttons from './pages/buttons';
import * as successMessage from './pages/successMessage';
import * as captcha from './pages/captcha';
import * as emailSettings from './pages/emailSettings';
import * as marketingConsent from './pages/marketingConsent';
import makePages from '../../../PropertiesPanel/view/makePages';
import addGoogleFont from '../../../PropertiesPanel/view/AddGoogleFont/index';

const propertiesPanelConfig = {
    pages: makePages(main, marketingConsent, style, buttons, emailSettings, successMessage, captcha, addGoogleFont),
    defaultPageId: main.id,
    reducer: () => {}
};

export default propertiesPanelConfig;
