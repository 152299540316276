import { openDialog } from "../../../App/actionCreators";
import { PageInfoDialogId, LinkPageInfoDialogId, SectionLinkInfoDialogId } from "../dialogs/ids";
import type {
    PageInfoUrlPath,
    PageInfoAllPagesUrlPaths,
    ToggleIsHomePageAction,
    PageInfoDialogInProps
} from "../dialogs/PageInfoDialog/flowTypes";
import { PAGE_INFO_IS_HOME_PAGE_TOGGLE } from "../dialogs/PageInfoDialog/actions";
import { DataLinkPage, DataPageRef, DataSectionLink } from "../../../../../dal/model";
import {
    PAGE_TREE_PAGE_DELETE,
    PAGE_TREE_PAGE_DELETE_CONFIRM,
    PAGE_TREE_PAGE_DUPLICATE,
    PAGE_TREE_PAGE_DO_DUPLICATE,
    PAGE_TREE_PAGE_SELECTED,
    PAGE_TREE_PAGE_HIDE,
} from "../actionTypes";
import type { DataSiteItem } from "../../../../../dal/model/utils/dataSiteItemUtils";
import { LOAD_SECTION_COMPONENTS_FOR_PAGE } from '../../sectionComponents/actions';

const openPageInfoDialogAction = (props: PageInfoDialogInProps) =>
    openDialog(PageInfoDialogId, props);

const openLinkPageInfoDialog = (page: DataLinkPage) => openDialog(LinkPageInfoDialogId, { page });

const openSectionLinkInfoDialog = (page: DataSectionLink) => openDialog(SectionLinkInfoDialogId, { page });

const loadSectionComponentsForPageId = (pageId?: string | null) => ({
    type: LOAD_SECTION_COMPONENTS_FOR_PAGE,
    payload: pageId
});

const loadSectionComponentsForPage = (page?: DataSectionLink) => (loadSectionComponentsForPageId(page ? page.pageId : null));

const toggleIsHomePage = (
    origPageUrlPath: PageInfoUrlPath,
    allPagesUrlPaths: PageInfoAllPagesUrlPaths
): ToggleIsHomePageAction => ({
    type: PAGE_INFO_IS_HOME_PAGE_TOGGLE,
    payload: {
        origPageUrlPath,
        allPagesUrlPaths
    }
});

const selectPageAction = (pageId: string) => ({
    type: PAGE_TREE_PAGE_SELECTED,
    payload: pageId
});

const deletePageAction = (page: DataSiteItem) => ({
    type: PAGE_TREE_PAGE_DELETE,
    payload: page
});

const hidePageAction = (page: DataSiteItem) => ({
    type: PAGE_TREE_PAGE_HIDE,
    payload: page
});

const confirmDeletePageAction = () => ({
    type: PAGE_TREE_PAGE_DELETE_CONFIRM
});

const duplicatePageAction = (page: DataPageRef) => ({
    type: PAGE_TREE_PAGE_DUPLICATE,
    payload: page
});

const doDuplicatePageAction = (page: DataPageRef) => ({
    type: PAGE_TREE_PAGE_DO_DUPLICATE,
    payload: page,
});

export {
    openPageInfoDialogAction,
    openLinkPageInfoDialog,
    openSectionLinkInfoDialog,
    loadSectionComponentsForPageId,
    loadSectionComponentsForPage,
    toggleIsHomePage,
    selectPageAction,
    deletePageAction,
    hidePageAction,
    confirmDeletePageAction,
    duplicatePageAction,
    doDuplicatePageAction,
};
