import React from 'react';
import Measure from 'react-measure';
import BaseComponent from "../../../../view/oneweb/BaseComponent";
import { BUTTON_UPDATE_COMPONENT_ON_RESIZE } from "../actionTypes";
import ButtonViewFactory from './index';
import type { Props, OffScreenRenderState } from '../flowTypes';
import styles from './Button.css';
import { ComponentMaskBackground } from "../../../presentational/ComponentMask/constants";
import ComponentMask from '../../../presentational/ComponentMask/index';

class OffScreenRender extends React.Component<Props, OffScreenRenderState> {
    shouldComponentUpdate(nextProps) {
        return (nextProps.text !== this.props.text) ||
            (nextProps.style !== this.props.style);
    }

    onResize({ bounds: { width } }) {
        const { isBtnInModernLayout, inEditMode } = this.props;
        this.props.dispatch({
            type: BUTTON_UPDATE_COMPONENT_ON_RESIZE,
            payload: {
                text: this.props.text,
                fontSize: this.props.fontSize,
                width: Math.ceil(width),
                isBtnInModernLayout,
            },
            amendToPrevious: isBtnInModernLayout && !inEditMode,
        });
    }

    render() {
        return (
            <div className={styles.offScreen}>
                <Measure bounds onResize={this.onResize.bind(this)}>
                    {({ measureRef }) => (
                        <BaseComponent measureRef={measureRef} extraClass={styles.initialDimensions} {...this.props}>
                            <ButtonViewFactory
                                {...this.props}
                                style={{ fontSize: this.props.fontSize }}
                                text={this.props.localizedText}
                            />
                        </BaseComponent>
                    )}
                </Measure>
            </div>
        );
    }
}

// TODO: WBTGEN-6680: Use single global style class name for workspace and preview
export default (props: Props) => (
    <BaseComponent {...props}>
        <ComponentMask showPreviewOnlyLayer={false} maskStyle={ComponentMaskBackground.TRANSPARENT}>
            {
                (props.isBtnInModernLayout || props.inEditMode) &&
                <OffScreenRender
                    {...props}
                    text={props.offScreenRendererText}
                    localizedText={props.localizedOffScreenRendererText}
                    fontSize={props.offScreenRendererFontSize}
                />
            }
            <ButtonViewFactory {...props} styles={styles} />
        </ComponentMask>
    </BaseComponent>
);
