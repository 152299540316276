import * as R from "ramda";
import { setCells } from "./setAllCells";
import { isTextNode } from '../../../../utils/dom';
import type { TableComponent, TableComponentDependsOn } from "../flowTypes";
import { DefaultCellContentOnDrop } from "../constants";

const emptyElementKeepFirstNode = (node: HTMLElement) => {
    let count = node.childNodes.length;
    if (!count) {
        if (node.tagName !== 'BR') {
            node.appendChild(document.createElement('br'));
        }
        return;
    }

    while (--count) {
        // @ts-ignore WBTGEN-9962: lastChild can be null, put checks
        node.removeChild(node.lastChild);
    }

    const firstChild = node.firstChild;
    // $FlowFixMe: WBTGEN-9962: firstChild can be null, put checks
    if (isTextNode(firstChild)) {
        // @ts-ignore WBTGEN-9962: firstChild can be null, put checks
        node.replaceChild(document.createElement('br'), firstChild);
        return;
    }

    // @ts-ignore WBTGEN-9962: firstChild can be null, put checks
    emptyElementKeepFirstNode(firstChild);
};

const keepStyles = (content: string) => {
    const psuedoDom: HTMLElement = document.createElement('div');
    psuedoDom.innerHTML = content || DefaultCellContentOnDrop;
    emptyElementKeepFirstNode(psuedoDom);
    return psuedoDom.innerHTML;
};

export const
    clearContentReducer = (
        component: TableComponent,
        action: any,
        dependencies: TableComponentDependsOn
    ): TableComponent => {
        const { tableEditModeState: { selectedCellsIndexes } } = dependencies;

        return setCells({
            cellUpdater: R.evolve({
                content: keepStyles
            }),
            selectedCellsIndexes
        }, component);
    };
