import React from 'react';
import * as pageIds from '../pageIds';
import pagesTitles from '../pagesTitles';
import Page from "../../../../PropertiesPanel/view/PropertiesPage";
import NavigationGroup from "../../../../PropertiesPanel/view/IntlNavigationGroup";
import { MOBILE_VIEW } from "../../../../PropertiesPanel/view/MobileView/constants";
import { LANGUAGE } from "../../../../PropertiesPanel/view/Language/constants";
import type { PageProps } from '../../../../PropertiesPanel/flowTypes';
import type { ShareButtonsComponent } from '../../flowTypes';

const
    id = pageIds.MAIN,
    title = pagesTitles[id],
    view = ({ navigateToPage }: PageProps<ShareButtonsComponent>) => (
        <Page>
            <NavigationGroup
                navigateToPage={navigateToPage}
                pagesTitles={pagesTitles}
                targetPagesIds={[pageIds.FACEBOOK_LIKE, pageIds.ALIGNMENT, LANGUAGE, MOBILE_VIEW]}
            />
        </Page>
    );

export { id, title, view };
