import type { SwitchFmTabReducerAction } from '../actions';
import type { FileManagerState } from '../../../../redux/modules/children/fileChooser/flowTypes';
import { Lit } from '../../../../lit';

export const switchFmTabReducer = (state: FileManagerState, action: SwitchFmTabReducerAction) => {
    const activeTabId = action.payload.tabId ? action.payload.tabId : action.payload;
    return {
        ...state,
        [Lit.activeTabId]: activeTabId
    };
};
