import React from 'react';
import cx from 'classnames';
import Button from './Button';
import styles from './Button.css';

type PropTypes = {
    disabled?: boolean,
    onClick: React.MouseEventHandler<any>,
    children?: any
}

export default (props: PropTypes) => (
    <Button
        className={cx(
            styles.button,
            styles.buttonUpgrade,
            { [styles.disabled]: props.disabled }
        )}
        {...props}
    >
        {props.children}
    </Button>
);

export const UpgradeButtonTypes = 'UpgradeButtonType';
