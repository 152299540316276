import styles from './WhatsNewDialog.css';
import topBarStyles from '../../TopBar/view/TopBar.css';
import { WhatsNewDialog } from './WhatsNewDialog';
import { closeWhatsNewDialogAction } from '../actions';

export const whatsNewDialogConfig = {
    confFactory: () => ({
        dimensions: {
            width: 357,
            height: 0, // will be overwritten in css
        },
        position: {
            top: topBarStyles.topBarHeight,
            left: 0, // will be overwritten in css
        },
        modal: true,
    }),
    component: WhatsNewDialog,
    dialogClassName: styles.dialogContainer,
    dialogBackgroundClassName: styles.dialogBackground,
    dragEnabled: false,
    updateOnBrowserDimensionsChange: false,
    onModalClickAction: closeWhatsNewDialogAction(),
};
