import { DataPageRef, DataPage, DataSite } from "../../../../dal/model";
import makeUuid from "../../../../utils/makeUuid";
import makeNewPageUrl from "./makeNewPageUrl";
import { PAGE_ROBOTS_ALL } from "../../../../dal/model/DataPageRef";
import { LinkPage } from "../../../utils/page";
import { replaceTagsWithContent } from "../../oneweb/Text/view/replaceTagsWithContent";

const PUBLIC_KEY = 'public';

export default (
    page: DataPage,
    site: DataSite,
    parentPageId: string | null | undefined,
    pageRefId: string | null | undefined,
    { name, title, layoutCategory, hidden = false }: { name: string, title: string, layoutCategory: string, hidden: boolean },
    globalVariables: Object
): DataPageRef => {
    const
        parentPagePath = parentPageId ? site.getPageRefUrlPath(site.getPageRefByPageId(parentPageId).id) : [],
        allPagesUrlPaths = site.getAllPageRefsUrlPaths();

    const nameProcessed = replaceTagsWithContent(name, { globalVariables, site });
    return new DataPageRef({
        id: pageRefId || makeUuid(),
        type: LinkPage,
        name: nameProcessed,
        title: replaceTagsWithContent(title, { globalVariables, site }),
        url: makeNewPageUrl(nameProcessed, parentPagePath, allPagesUrlPaths, layoutCategory),
        description: '',
        pageId: page.id,
        templateId: page.templateId,
        hidden,
        [PUBLIC_KEY]: true,
        items: [],
        categories: [],
        robots: PAGE_ROBOTS_ALL
    });
};
