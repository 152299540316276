import * as R from "ramda";
import { evolvePath } from "../../../../src/utils/ramdaEx";
import { applyMappers, makePlainMappers } from "../../utils";
import { buttonStyleMapper } from '../Button/styleMapper';
import * as fontFamilyMapper from "../Base/fontFamily";
import * as colorMapper from "../Base/color";
import type { ContactFormComponent } from "../../../../src/components/oneweb/ContactForm/flowTypes";
import type { StyleForm } from "../../../../src/redux/modules/children/workspace/flowTypes";

const plainPropsMapper = makePlainMappers({
    formElements: "formElements",
    mobileSettings: "mobileSettings",
    mobileHide: 'mobileHide',
    submitBtn: "submitBtn",
    successMessage: "successMessage",
    formElementsOrder: "formElementsOrder",
    recipientEmail: "recipientEmail",
    styleButton: "styleButton",
    fileUploadButtonStyle: "fileUploadButtonStyle",
    styleForm: "styleForm",
    buttonThemeSelected: "buttonThemeSelected",
    fuButtonThemeSelected: "fuButtonThemeSelected",
    isCaptchaEnabled: "isCaptchaEnabled",
    captchaLang: "captchaLang",
    isMarketingConsentEnabled: "isMarketingConsentEnabled",
    marketingConsentCheckBoxText: "marketingConsentCheckBoxText",
    readPrivacyPolicyText: "readPrivacyPolicyText",
});

const styleFormMapper = {
    to: (style: any): StyleForm => ({
        font: fontFamilyMapper.to(style.font ? style.font.toString() : null),
        fontSize: style.fontSize,
        fontColor: colorMapper.toHsl(style.fontColor),
        themeOverrideColor: style.themeOverrideColor,
    }),
    back: (style: StyleForm): any => ({
        type: "web.data.styles.StyleForm",
        font: fontFamilyMapper.back(style.font ? style.font.toString() : null),
        fontSize: style.fontSize,
        fontColor: style.fontColor,
        themeOverrideColor: style.themeOverrideColor,
        primaryColor: null,
        fieldBgColor: null,
        fieldBorderColor: null
    })
};

const
    Text = 'text',
    handleNullFormElement = (component) => {
        if (component.formElements.null) {
        //convert null field to text by default as observed in this case WBTGEN-9841
            let newComponent = {
                    ...component,
                    formElements: { ...component.formElements },
                    formElementsOrder: [...component.formElementsOrder]
                },
                textLastIndex = 0,
                updatedElts: any = [];

            component.formElementsOrder.forEach((eltName, index) => {
                let elt = component.formElements[eltName],
                    parts = eltName.split(Text);
                if (parts.length > 1) {
                    textLastIndex = Math.max((parseInt(parts[1], 10) || (textLastIndex + 1)), textLastIndex);
                }
                // there can be multiple null items, null, null1, null2..
                if (eltName.split('null').length > 1) {
                    updatedElts.push({ index, elt: { ...elt, inputType: Text }, oldName: eltName });
                }
            });
            updatedElts.forEach(({ index, elt, oldName }) => {
                textLastIndex++;
                let newKey = Text + textLastIndex;
                newComponent.formElementsOrder.splice(index, 1, newKey);
                delete newComponent.formElements[oldName];
                newComponent.formElements[newKey] = elt;
            });
            return newComponent;
        }
        return component;
    };

const fixDefaultEmail = component => {
    return R.evolve({
        recipientEmail: (recipient) => {
            return recipient === "debug@one.com" ? "mail@example.com" : recipient;
        },
    }, component);
};

export function to(componentData: any): ContactFormComponent {
    //WBTGEN-9841
    //This is for handling null data(it is due to data corruption in old wsb)
    let component = handleNullFormElement(componentData);
    return {
        subject: (component.subject || ''),
        ...applyMappers(
            component,
            R.pipe(
                plainPropsMapper.to,
                fixDefaultEmail,
                evolvePath(['styleButton'])(buttonStyleMapper.to),
                evolvePath(['fileUploadButtonStyle'])(buttonStyleMapper.to),
                evolvePath(['styleForm'])(styleFormMapper.to)
            )
        )
    };
}

export function back(componentData: ContactFormComponent): any {
    return {
        subject: componentData.subject,
        ...applyMappers(
            componentData,
            R.pipe(
                plainPropsMapper.back,
                evolvePath(['styleButton'])(buttonStyleMapper.back),
                evolvePath(['fileUploadButtonStyle'])(buttonStyleMapper.back),
                evolvePath(['styleForm'])(styleFormMapper.back)
            )
        )
    };
}
