/* eslint-disable max-len */

import React from 'react';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import * as pagesIds from "./ids";
import pagesTitles from "./titles";
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import type { TemplatePageViewProps } from '../flowTypes';
import BackgroundImageSettings from '../../../../presentational/BackgroundImageSettings/index';
import {
    getComponentImageSettings,
    getComponentColorGradientSettings
} from "../../../../../view/oneweb/commonComponentSelectors";
import * as ActionTypes from '../../actionTypes';
import ColorGradientSettings from '../../../../presentational/ColorGradientSettings/index';
import { DEFAULT_COLOR_PICKER, THEME_BG_OVERRIDE_COLOR_PICKER } from "../../../../ColorPicker/constants";
import { BACKGROUND_THEME_WHITE } from "../../../../ThemeGlobalData/constants";

const
    id = pagesIds.BACKGROUND,
    title = pagesTitles[id],
    TooltipTextForTemplateColorPicker = 'msg: component.template.themeColorTooltip {Sets the background of your template and directs the colours used for the components you add directly on this background.}',
    view = (props: TemplatePageViewProps) => {
        const
            { selectedComponent: templateComponent, dispatch, themeSettingsData: { autoColorMode }, themeColorsData } = props,
            { selectedTheme, selectedGradientTheme } = templateComponent,
            { asset, repeat, position, size, scrollEffect } = getComponentImageSettings(templateComponent),
            { color, gradient, opacity } = getComponentColorGradientSettings(templateComponent, autoColorMode);

        return (
            <Page>
                <VerticalSpacer />
                {
                    autoColorMode
                        ? <ColorGradientSettings
                            colorPickerKind={THEME_BG_OVERRIDE_COLOR_PICKER}
                            themeColorsData={themeColorsData}
                            backgroundTheme={selectedTheme || BACKGROUND_THEME_WHITE}
                            gradientTheme={selectedGradientTheme || null}

                            colorChangeAction={ActionTypes.TEMPLATE_THEME_CHANGE}

                            gradient={gradient}
                            gradientColorChangeAction={ActionTypes.TEMPLATE_GRADIENT_COLOR_SET_AUTO_COLOR}
                            gradientColorRemoveAction={ActionTypes.TEMPLATE_GRADIENT_COLOR_UNSET_AUTO_COLOR}
                            gradientDirectionChangeAction={ActionTypes.TEMPLATE_GRADIENT_DIRECTION_CHANGE}
                            gradientFadePointChangeAction={ActionTypes.TEMPLATE_GRADIENT_FADE_POINT_CHANGE}

                            opacity={opacity}
                            opacityChangeAction={ActionTypes.TEMPLATE_OPACITY_CHANGE_AUTO_COLOR}

                            dispatch={dispatch}
                            tooltipTop={TooltipTextForTemplateColorPicker}
                        />
                        : <ColorGradientSettings
                            colorPickerKind={DEFAULT_COLOR_PICKER}
                            color={color}
                            colorChangeAction={ActionTypes.TEMPLATE_COLOR_CHANGE}
                            colorRemoveAction={ActionTypes.TEMPLATE_COLOR_REMOVE}
                            colorOnTheFlyChange
                            gradient={gradient}
                            gradientColorChangeAction={ActionTypes.TEMPLATE_GRADIENT_COLOR_CHANGE}
                            gradientColorRemoveAction={ActionTypes.TEMPLATE_GRADIENT_COLOR_REMOVE}
                            gradientDirectionChangeAction={ActionTypes.TEMPLATE_GRADIENT_DIRECTION_CHANGE}
                            gradientFadePointChangeAction={ActionTypes.TEMPLATE_GRADIENT_FADE_POINT_CHANGE}
                            opacity={opacity}
                            opacityChangeAction={ActionTypes.TEMPLATE_OPACITY_CHANGE}
                            dispatch={dispatch}
                        />
                }
                <VerticalSpacer />
                <BackgroundImageSettings
                    asset={asset}
                    assetChangeAction={ActionTypes.TEMPLATE_ASSET_CHANGE}
                    assetRemoveAction={ActionTypes.TEMPLATE_ASSET_UNSET}

                    repeat={repeat}
                    assetRepeatChangeAction={ActionTypes.TEMPLATE_ASSET_CHANGE_REPEAT}

                    position={position}
                    assetPositionChangeAction={ActionTypes.TEMPLATE_ASSET_CHANGE_POSITION}

                    size={size}
                    assetSizeChangeAction={ActionTypes.TEMPLATE_ASSET_CHANGE_SIZE}

                    scrollEffect={scrollEffect}

                    dispatch={dispatch}
                />
            </Page>
        );
    };

export { id, title, view };
