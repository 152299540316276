import { showTutorialButtonOnTopBarSelector } from "../../epics/checkPublishStatus/selectors";
import { VIDEO_TUTORIAL_PLAYED } from "./actionTypes";
import { PUBLISH_VIDEO_TUTORIAL_PLAYED } from "../../../../../demo/modules/actions";

const EventNames = {
    videoTutorialWatched: 'WSB video tutorial watched',
    publishVideoWatched: 'WSB upgrade video watched'
};

const PropertyNames = {
    source: 'Video tutorial source'
};

const videoBtnSource = {
    help: 'Help',
    TutorialBtn: 'Tutorial Button'
};

const VideoTutorialEvents = {
    [VIDEO_TUTORIAL_PLAYED]: {
        eventName: EventNames.videoTutorialWatched,
        propsHandler: (appState) => {
            const tutorialBtnSource = showTutorialButtonOnTopBarSelector(appState);

            return {
                [PropertyNames.source]: tutorialBtnSource ? videoBtnSource.TutorialBtn : videoBtnSource.help
            };
        }
    },
    [PUBLISH_VIDEO_TUTORIAL_PLAYED]: {
        eventName: EventNames.publishVideoWatched,
    }
};

export {
    VideoTutorialEvents
};
