/* eslint-disable max-len */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Msg, useIntl } from "../../../../view/intl";
import OpenUpgradePlanDialog from '../Upgrade/UpgradePlansDialog/OpenUpgradePlanDialog';
import {
    getPageLimitBySubscriptionType,
    getSectionLinksLimitBySubscriptionType,
    isBasicSubscription
} from '../../../App/epics/subscriptionData/utils';
import { subscriptionDataSelector } from '../../../App/epics/subscriptionData/selectors';
import type { SubscriptionData } from '../../../../redux/modules/flowTypes';
import { upgradeDialogOpenedAction } from '../../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions';
import { FEATURE_PAGES_MENU } from '../Upgrade/tracking/contants';
import { UpgradePlansDialogId } from '../dialogIds';

import styles from './UpgradeBanner.css';

export const UpgradeBanner = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const subscriptionData: SubscriptionData = useSelector(subscriptionDataSelector);
    const { subscriptionType } = subscriptionData;

    const pageLimit = getPageLimitBySubscriptionType(subscriptionType);
    const sectionLimit = getSectionLinksLimitBySubscriptionType(subscriptionType);

    const getDescription = () => {
        if (isBasicSubscription(subscriptionType)) {
            return (
                <Msg
                    k="upgradeBanner.basic.description"
                    params={{ pagesCount: pageLimit, sectionCount: sectionLimit }}
                >
                    {
                        `Your current plan allows you to publish {pagesCount} page and show {sectionCount} section links in your menu. Upgrade to publish more pages on your website.`
                    }
                </Msg>
            );
        } else {
            return (
                <Msg
                    k="upgradeBanner.nonbasic.description"
                    params={{ pagesCount: pageLimit, sectionCount: sectionLimit }}
                >
                    {
                        `Your current plan allows you to publish {pagesCount} pages and to show {sectionCount} section links in your menu. Upgrade to get access to unlimited pages and section links.`
                    }
                </Msg>
            );
        }
    };

    return (
        <div className={styles.contentWrapper} data-testid="pagetree-upgrade-banner-container">
            <div className={styles.iconContainer}>
                <div className={styles.icon} />
            </div>
            <div className={styles.header}>
                {intl.msgJoint("msg: upgradeBanner.limitReached {Plan limit reached}")}
            </div>
            <div className={styles.description}>
                {getDescription()}
            </div>
            <div
                className={styles.buttonContainer}
                onClick={() => {
                    dispatch(OpenUpgradePlanDialog(subscriptionData, 'Workspace:PagesTree'));
                    dispatch(upgradeDialogOpenedAction({
                        feature: FEATURE_PAGES_MENU,
                        dialogId: UpgradePlansDialogId
                    }));
                }}
            >
                <Msg
                    k="common.upgradeNow"
                    className={styles.label}
                >
                    Upgrade now
                </Msg>
            </div>
        </div>
    );
};
