import React from "react";
import { useDispatch } from "react-redux";

import { useIntl } from "../../../../../view/intl";

import { Dialog } from '../../../../../view/common/dialogs/baseDialog';
import { StripTypes } from "../../../../../view/common/dialogs/baseDialog/Dialog";

import { PrimaryButton, SecondaryButton } from "../../../../../view/common/Button";

import { closeDialog } from "../../../../App/actionCreators";
import getCenteredDialog from "../../../../DialogManager/getCenteredDialogConfig";

import styles from "./ErrorModel.css";
import { ONBOARDING_TEMPLATE_SELECTED_ADD_TEMPLATE } from "../../../../TemplateSelector_DEPRECATED/actionTypes";

export const ContinueToATemplateDialog = () => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const handleClose = () => {
        dispatch(closeDialog());
    };

    return (
        <Dialog
            stripType={StripTypes.NONE}
            containerClassName={styles.standardContainer}
            onClose={handleClose}
        >
            <div className={styles.standardDialogContent}>
                <div className={styles.continueToATemplateLogo} />
                <div className={styles.smTitle}>
                    {intl.msgJoint("msg: onboarding.dynamic.discardOnboarding.title {Discard progress and continue with a template?}")}
                </div>
                <div className={styles.smDescription}>
                    {
                        // eslint-disable-next-line max-len
                        intl.msgJoint("msg: onboarding.dynamic.discardOnboarding.description {If you choose to build your website with one of our pre-designed templates, we won\'t be able to create customised texts for you.}")
                    }
                </div>
            </div>

            <div className={styles.standardButtonContainer}>
                <SecondaryButton
                    className={styles.button}
                    onClick={handleClose}
                >
                    {intl.msgJoint("msg: common.cancel {Cancel}")}
                </SecondaryButton>
                <PrimaryButton
                    className={styles.button}
                    onClick={() => {
                        dispatch(closeDialog());
                        dispatch({ type: ONBOARDING_TEMPLATE_SELECTED_ADD_TEMPLATE, payload: { skipWarningDialog: true } });
                    }}
                >
                    {intl.msgJoint("msg: onboarding.dynamic.discardOnboarding.continue {Continue to template}")}
                </PrimaryButton>
            </div>

        </Dialog>
    );
};

const config = getCenteredDialog({
    width: 700,
    height: 364,
    component: ContinueToATemplateDialog,
    dragEnabled: false
});

const CONTINUE_TO_A_TEMPLATE_DIALOG = "CONTINUE_TO_A_TEMPLATE_DIALOG";

export {
    config,
    CONTINUE_TO_A_TEMPLATE_DIALOG
};
