/* eslint-disable max-len */

import React from "react";
import cx from "classnames";
import { connect } from "react-redux";
import * as styles from "./Mobile.css";
import { DesktopPreview } from "../Desktop/index";
import Icons from '../../../../view/Icons/index';
import { MobileViewOrientations, MobileViewDimensions, MobileContentPosition } from "./constants";
import CheckBox from '../../../../view/common/CheckBox/CheckBoxWithLabel';
import { Msg, injectIntl } from '../../../../view/intl/index';
import NoPages from "../../../Backup/view/NoPages";
import { makeEpicStateSelector } from "../../../../epics/makeEpic";
import { chatWidgetGlobalDataEpic } from "../../../SiteSettings/chatWidgetTabData/chatWidgetGlobalDataEpic";
import type { AppState } from "../../../../redux/modules/flowTypes";
import { ChatWidgetPreview } from "../../../oneweb/FacebookChat/view/preview";

export const MobilePreviewContainer = injectIntl(({
    Contents,
    mobileOptionsProps,
    showMobileActivateOptions,
    onClickOrientation,
    orientationClassName,
    extraContainerClassName = '',
    onScroll,
    intl,
    chatWidgetState
}) => {
    const rotateTitle = "msg: common.rotate {Rotate}";
    return (
        <div className={cx(styles.container, extraContainerClassName)} onScroll={onScroll}>
            { /* eslint-disable-next-line @typescript-eslint/no-use-before-define */ }
            {showMobileActivateOptions && <MobileOptions {...mobileOptionsProps} />}
            <div className={`${styles.mobileContainer} ${orientationClassName}`}>
                {Contents}
                {
                    chatWidgetState && chatWidgetState.enableChatWidget && <ChatWidgetPreview
                        intl={intl}
                        chatWidgetColor={chatWidgetState.chatWidgetColor}
                        isMobilePreview
                    />
                }
            </div>

            <div className={styles.mobileViewOrientationWrapper}>
                <div
                    className={styles.mobileViewOrientation}
                    onClick={onClickOrientation}
                    data-title={intl.msgJoint(rotateTitle)}
                >
                    <Icons.ROTATE_MOBILE style={{ fill: '#006fcd', transform: 'rotatez(90deg)' }} />
                </div>
            </div>
        </div>
    );
});

export const getOrientationStyles = (orientation: string) => {
    let dimensions = MobileViewDimensions.portrait,
        orientationClassName = styles.portraitView,
        style = MobileContentPosition.portrait;
    if (orientation === MobileViewOrientations.LANDSCAPE) {
        dimensions = MobileViewDimensions.landscape;
        orientationClassName = styles.landscapeView;
        style = MobileContentPosition.landscape;
    }
    return {
        orientationClassName,
        contentProps: {
            className: styles.content,
            style,
            ...dimensions
        }
    };
};

type MobileOptionPropType = {
    activateMobileView: boolean;
    onToggleMobileVersion: () => void;
    orientation: string;
    showAutoColorLeftPanel?: boolean;
};

export const MobileOptions = (mobileOption: MobileOptionPropType) => {
    const
        {
            activateMobileView,
            onToggleMobileVersion,
            orientation,
            showAutoColorLeftPanel
        } = mobileOption,
        mobileActivationLabel = activateMobileView
            ? "msg: template.mobileViewActive {Mobile view is active}"
            : "msg: template.mobileViewNotActive {Mobile view is not active}",
        note = activateMobileView
            ? <Msg k="mobile.active.note">When mobile view is active, your visitors using a mobile device will see this mobile friendly view.</Msg>
            : (<div>
                <Msg k="mobile.not.active.note1">Your visitors will not see this mobile friendly view. Instead, they will see the desktop version of your site.</Msg>
                <p className={styles.mobileNotActiveLastNote}>
                    <Msg className={styles.noteColon} k="common.noteColon">Note:</Msg>&nbsp;
                    <Msg k="mobile.not.active.note2">It is not possible to preview this; it will only show on your published website.</Msg>
                </p>
            </div>);
    return (
        <div className={cx('mobile-view-active', styles.mobileViewActiveMsgToggleContainer,
            (orientation === MobileViewOrientations.LANDSCAPE)
                ? styles.adjustToggleViewInLandscape : styles.adjustToggleViewInPortrait,
            showAutoColorLeftPanel && styles.mobileViewActiveAutoColorLeftPanel)}
        >
            <div
                className={styles.mobileOptions}
                key={0}
            >
                <div className={styles.activateMobileVersion}>
                    <CheckBox
                        label={mobileActivationLabel}
                        isChecked={activateMobileView}
                        onClick={onToggleMobileVersion}
                        isSliderStyle
                        className={styles.checkboxLabel}
                    />
                </div>
            </div>
            <div
                className={cx(styles.mobileOptions,
                    styles.mobileNote,
                    (orientation === MobileViewOrientations.LANDSCAPE)
                        ? styles.hideMobileViewTextInLandscape : styles.hideMobileViewTextInPortrait)}
                key={1}
            >
                {note}
            </div>
        </div>
    );
};
export class DefaultMobilePreview extends DesktopPreview {
    // Class "webshop-preview-in-website-builder" is added for webshop to be able to detect its parent iframe as
    // WSB preview and run some JavaScript code as required for WBTGEN-5856 and other such tasks where Webshop
    // needs to adjust the preview UI.
    // Class "x-panel-body-noheader" is added to support WBTGEN-5856
    // Once webshop changes are done for WEBSHOP-6664, code here should remove x-panel-body-noheader
    componentDidMount() {
        super.componentDidMount();
        this.previewIframeRef.contentWindow.addEventListener('DOMContentLoaded', () => {
            this.previewIframeRef.contentWindow.document.documentElement.style.overflowY = 'scroll';
            this.previewIframeRef.contentWindow.document.body.style.overflowY = 'hidden';
        });
    }

    render() {
        const
            {
                onClickOrientation, preview: { mobileView: { orientation } },
                onToggleMobileVersion, showMobileActivateOptions = true, extraContainerClassName = '',
                hasPages = true,
                mobileStyle,
                menuState,
                siteMap: { activateMobileView },
                chatWidgetState,
            } = this.props,
            { orientationClassName, contentProps } = getOrientationStyles(orientation);
        let Contents = <iframe
            key={this.state.iFrameKey}
            ref={this.setIframeRef}
            allowFullScreen
            // @ts-ignore
            type="text/html"
            {...contentProps}
            frameBorder="0"
            className="x-panel-body-noheader webshop-preview-in-website-builder"
        />;
        if (!hasPages) {
            Contents = <NoPages {...contentProps} />;
        }

        const mobileOptionsProps = {
            onToggleMobileVersion,
            activateMobileView,
            orientation
        };

        const props = {
            Contents,
            mobileOptionsProps,
            showMobileActivateOptions,
            onClickOrientation,
            orientationClassName,
            extraContainerClassName,
            mobileStyle,
            menuState,
            chatWidgetState
        };

        return <MobilePreviewContainer {...props} />;
    }
}

const chatWidgetEpicStateSelector = makeEpicStateSelector(chatWidgetGlobalDataEpic.valueActionType);
const mapStateToProps = (appState: AppState) => ({
    chatWidgetState: chatWidgetEpicStateSelector(appState)
});

export default connect(mapStateToProps)(injectIntl(DefaultMobilePreview));
