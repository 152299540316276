
import * as onClickActions from './constants/onClickActions';
import * as constants from './constants/index';
import { DataSite } from "../../../../dal/model/index";
import type { InstagramMediaAsset } from "../../SocialAccounts/instagram/types";
import {
    INSTAGRAM_VIDEO,
    INSTAGRAM_CAROUSEL_ALBUM,
    SHINYBOX_VIDEO_MEDIA_TYPE,
    SHINYBOX_ALBUM_MEDIA_TYPE
} from "./constants/index";

type CalcAProps = {
    onClickAction: string,
    caption: string,
    componentId: string,
    index: number,
    image: InstagramMediaAsset,
    previewBackupTime?: number,
    site: DataSite
}

export const

    /**
     * Finds a factor such that when the width and height are multiplied with it, the
     * new size will follow the constraints:
     * 1. Max size is not more then 16mp or whatever is global limit
     * 2. Either side is not more then sanity limit
     * 3. Either side is not more then original side (specified as maxW and maxH)
     * @public
     * @param {Number} w Width
     * @param {Number} h Height
     * @param {Number} maxW (optional) Max width
     * @param {Number} maxH (optional) Max height
     * @returns {Number} A value between 0 .. 1
     */
    getReduceFactor = (w: number, h: number, maxW: number, maxH: number) => {
        let
            maxPixels = constants.IMAGE_SCALE_MAX_PIXELS,
            maxSide = constants.IMAGE_MAX_DIMENSION - 1,
            newScale = 1;

        // Check for maxPixels limit
        if (w * h > maxPixels) {
            newScale = maxPixels / (w * h);
        }

        // Check for original size constraints
        if (maxW && w > maxW) {
            newScale = Math.min(newScale, maxW / w);
        }
        if (maxH && h > maxH) {
            newScale = Math.min(newScale, maxH / h);
        }

        // Check for either side limit constraint
        if (w > maxSide) {
            newScale = Math.min(newScale, maxSide / w);
        }
        if (h > maxSide) {
            newScale = Math.min(newScale, maxSide / h);
        }

        return newScale;
    },

    calcA = ({
        onClickAction,
        componentId,
        index,
        image,
    }: CalcAProps) => {
        if (onClickAction === onClickActions.OPEN_LIGHT_BOX) {
            let aProps = {
                href: image.media_type === INSTAGRAM_VIDEO ? image.thumbnail_url : image.media_url,
                title: '',
                alt: '',
                caption: image.caption || '',
                rel: `lightbox[oneweb] ${componentId}`,
                className: "shinybox",
                'data-dom-index': index,
            };

            if (image.media_type === INSTAGRAM_VIDEO) {
                aProps['data-force-media-type'] = SHINYBOX_VIDEO_MEDIA_TYPE;
                aProps['data-force-media-url'] = image.media_url;
            } else if (image.media_type === INSTAGRAM_CAROUSEL_ALBUM) {
                if (image.children && image.children.data) {
                    aProps['data-force-media-type'] = SHINYBOX_ALBUM_MEDIA_TYPE;
                    aProps['data-media-album-items'] = JSON.stringify(image.children.data);
                }
            }

            return aProps;
        } else if (onClickAction === onClickActions.OPEN_LINK) {
            return {
                href: image.permalink,
                target: '_blank',
                alt: image.caption,
                className: 'open-instagram-post-new-tab',
            };
        }
        return null;
    },
    getMediaAssetsToBeShown = (mediaAssets: Array<InstagramMediaAsset>, columns: number, rows: number): Array<InstagramMediaAsset> => {
        return mediaAssets.slice(0, (columns * rows));
    };
