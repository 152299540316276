/* eslint-disable max-len */

import TextGlobalStyleType from "../oneweb/Text/globalStyle/kind";
import TableCellGlobalStyleType from "../oneweb/Table/globalStyle/kind";
import type { Stylesheet } from '../Workspace/epics/stylesheets/flowTypes';

const
    getGlobalstyleClassName = (stylesheetId: string) => `gs-${stylesheetId}`,
    getTextGlobalstyleClassNameRef = (ref: string): string => {
        return `text${ref.replace('.', '')}`;
    },
    getCellGlobalstyleClassNameRef = (ref: string): string => {
        return `cell${ref.replace('.', '')}`;
    },
    getGlobalstyleClassNameFromStyle = (style: Stylesheet): string => {
        if (style.type === TextGlobalStyleType) {
            return getTextGlobalstyleClassNameRef(style.ref);
        } else if (style.type === TableCellGlobalStyleType) {
            return getCellGlobalstyleClassNameRef(style.ref);
        } else {
            return style.ref.replace('.', '');
        }
    },
    getGlobalstyleHoverClassName = (stylesheetId: string) => `${stylesheetId}-hover`,
    getGlobalstyleClassNameForRadium = (stylesheet: Stylesheet): string => '.' + getGlobalstyleClassNameFromStyle(stylesheet);

export {
    getGlobalstyleClassName as default,
    getGlobalstyleClassNameFromStyle,
    getCellGlobalstyleClassNameRef,
    getGlobalstyleHoverClassName,
    getGlobalstyleClassNameForRadium
};

