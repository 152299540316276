import React from "react";
export const SvgFoursquareRoundedFillMulticolor = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <rect width={48} height={48} fill="#f94877" rx={6} />
            <path
                fill="#ffffff"
                d="M34.56 13.187a1.686 1.686 0 00-1.36-.687H17.186c-.931 0-1.686.755-1.686 1.686v20.232a1.686 1.686 0 002.878 1.192l6.25-6.25h4.359c.737 0 1.389-.479 1.609-1.183L34.81 14.69c.16-.512.067-1.07-.25-1.502zm-4.705 6.057h-5.926a1.686 1.686 0 000 3.372h4.872l-1.054 3.372H23.93c-.447 0-.876.178-1.192.494l-3.865 3.866V15.872h12.036l-1.053 3.372z"
            />
        </g>
    </svg>
);
