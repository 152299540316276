import type { ShutterstockKindT } from "./types";
import type { SstockCollectionT } from "../../../../dal/flowTypes";
import { SstockCollection } from "../../../../dal/constants";
import { ShutterstockKind } from "./constants";

const map = {
    [ShutterstockKind.FREE]: SstockCollection.FREE,
    [ShutterstockKind.PAID]: SstockCollection.FULL,
};

export const getShutterstockCollectionByKind = (kind: ShutterstockKindT): SstockCollectionT => {
    if (map[kind]) {
        return map[kind];
    }

    throw new Error(`Unknown shutterstock kind: ${kind}`);
};
