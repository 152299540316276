import { AITextErrorMap, CONTENT_TYPES, TOPIC_TYPE_OPTIONS } from "../../AIText/Dialogs/CreateAIText/constants";
import * as AiTextActions from "./actionTypes";
import { selectAiTextDataSiteSettings, selectContentType, selectExtras, selectGeneralDataSiteSettings, selectLanguage,
    selectTopicType } from "./selectors";
import { gmbCategories as GMB_CATEGORIES } from '../../../Template/gmbCategories';
import { getIntl } from "../../../../../view/intl/injectIntl";

const AITextEventNames = {
    generated: 'WSB AI text generated',
    textUsed: 'WSB AI text used',
    apiError: 'WSB AI text - API failure'
};

const PropertyNames = {
    keywordsFilled: 'Keywords filled in',
    audienceFilled: 'Audience filled in',
    paragraphSelected: 'Paragraph selected',
    businessCategoryFilled: 'Business category entered',
    ownTopicUsed: 'Own topic used',
    languageSameAsWSB: 'Language as in user settings',
    errorType: 'Error type',
    textInserted: 'Text inserted',
    aiTextType: 'WSB AI text type',
};

const aiTextReplacedEvent = (textInserted: boolean) => ({
    eventName: AITextEventNames.textUsed,
    propsHandler: () => ({
        [PropertyNames.textInserted]: textInserted
    })
});

const categoryChanged = (gmbCategories, gmbKey) => {
    const intl = getIntl();
    let category = GMB_CATEGORIES[(gmbCategories || '').split(',')[0]];
    try {
        category = intl && intl.msgJoint(category);
    } catch (e) {
        // translations might not be available for the category GMBkey
        console.warn(e);
    }
    return !gmbCategories || (!category && gmbKey) || category !== gmbKey;
};

const AITextMixpanelEvents = {
    [AiTextActions.GENERATE_AI_TEXT_COMPLETE_EVENT]: {
        eventName: AITextEventNames.generated,
        propsHandler: (appState) => {
            const { gmbCategories, websiteCategories } = selectGeneralDataSiteSettings(appState);
            const { targetAudience: audience, language, gmbKey } = selectAiTextDataSiteSettings(appState);
            const wsbLanguage = selectLanguage(appState);
            const keywords = selectExtras(appState),
                isParagraph = selectContentType(appState) !== CONTENT_TYPES.title,
                ownTopic = selectTopicType(appState) === TOPIC_TYPE_OPTIONS.custom;

            return {
                [PropertyNames.keywordsFilled]: !!keywords,
                [PropertyNames.audienceFilled]: !!audience,
                [PropertyNames.paragraphSelected]: isParagraph,
                [PropertyNames.businessCategoryFilled]: categoryChanged(gmbCategories || websiteCategories, gmbKey),
                [PropertyNames.ownTopicUsed]: ownTopic,
                [PropertyNames.languageSameAsWSB]: wsbLanguage === language,
            };
        }
    },
    [AiTextActions.GENERATE_AI_TEXT_FAILED_EVENT]: {
        eventName: AITextEventNames.apiError,
        propsHandler: (_, errorType) => ({
            [PropertyNames.errorType]: AITextErrorMap[errorType],
            [PropertyNames.aiTextType]: 'create'
        })
    },
    [AiTextActions.AI_TEXT_REPLACE_BTN_CLICKED]: aiTextReplacedEvent(true),
    [AiTextActions.AI_TEXT_COPY_BTN_CLICKED]: aiTextReplacedEvent(false),
};

export {
    AITextEventNames,
    AITextMixpanelEvents
};
