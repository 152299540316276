import { $Values } from "utility-types";
import UAParser from 'ua-parser-js';
import { BrowserName } from "./constants";

type UserAgentNameT = $Values<typeof BrowserName>;
type UserAgentVersionPattern = string | RegExp;

class UserAgent {
    /*::#*/parser: UAParser;

    constructor() {
        this./*::#*/parser = new UAParser();
    }

    isBrowser(vendor: UserAgentNameT, versionPattern?: UserAgentVersionPattern): boolean {
        if (!Object.values(BrowserName).some(name => name === vendor)) {
            throw new Error(`UserAgent vendor name is not supported. Update if needed`);
        }

        const browser = this./*::#*/parser.getBrowser();

        let assert = browser.name === vendor;

        if (assert && versionPattern) {
            // $FlowFixMe: incorrect flow type
            if (typeof versionPattern === 'string') assert = assert && versionPattern === browser.major;
            else if (versionPattern instanceof RegExp) assert = assert && versionPattern.test(browser.version);
            else {
                throw new Error(`UserAgent version pattern is not supported. Should be string or RegExp`);
            }
        }

        return assert;
    }
}

export type UserAgentT = UserAgent;

let instance: UserAgent;
export const ua = (): UserAgent => {
    if (instance) return instance;
    instance = new UserAgent();
    return instance;
};
