import React, { useEffect, useState } from 'react';
import { loadScript, Status } from '../../../../../utils/loadScript';
import { PinterestSDK, PinterestTypes } from '../constants';
import { getEmbedPinDo, isIframeLink } from '../utils';

const HrefView = (props) => {
    const { width, link } = props;
    const [key, setKey] = useState(Date.now());

    const
        dataPinDo = getEmbedPinDo(link),
        hrefProps = {
            href: link,
            'data-pin-do': dataPinDo,
            'data-pin-board-width': width,
        };

    if (dataPinDo === PinterestTypes.user || PinterestTypes.board) {
        hrefProps['data-pin-scale-width'] = 240;
        hrefProps['data-pin-scale-height'] = 80;
    }

    useEffect(() => {
        loadScript(PinterestSDK)
            .then(status => {
                if (status === Status.PRELOADED) {
                    setKey(Date.now());
                    setInterval(() => {
                        window.PinUtils.build();
                    }, 300);
                }
            });
    }, [link]);

    return (<a key={key} {...hrefProps}>{link}</a>);
};

const IframeView = ({ link, width, height }) => (
    <iframe
        src={link}
        height={height}
        width={width}
        frameBorder="0"
        scrolling="no"
    />
);

const PinterestView = props => {
    const isIframe = isIframeLink(props.link);
    return (
        <div className="widgets">
            {isIframe ? <IframeView {...props} /> : <HrefView {...props} />}
        </div>
    );
};

export { PinterestView };
