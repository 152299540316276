import makeEpic from '../../../../../epics/makeEpic';
import * as Actions from './actionTypes';
import { default as valueActionType } from './valueActionType';

import type { EditAITextType } from "./types";
import getRegeneratedAiText from './actionCreators/getRegeneratedAiText';
import { EDIT_AI_TEXT_DIALOG_ID } from '../../AIText/Dialogs/EditAIText/EditAITextDialog';
import { REPLACE_DIALOG } from '../../../../../redux/modules/actionTypes';
import {
    ReceiveOnlySelectedComponentSelector,
    ReceiveOnlySelectedComponentsIdsSelector
} from '../../../../Workspace/epics/componentsEval/selectorActionTypes';
import makeUuid from '../../../../../convertToSections/makeUuid';
import { ERROR_STATE_CODES, ERROR_STATES } from '../../AIText/Dialogs/CreateAIText/constants';
import { AI_TEXT_REPLACE_TEXT_CONTENT } from '../AITextEpic/actionTypes';
import WORKSPACE_COMPONENT_KIND from '../../../../Workspace/epics/componentsEval/WORKSPACE_COMPONENT_KIND';
import { termsAndConditionsGlobalDataVAT } from '../../../../SiteSettings/termsAndConditions/termsAndConditionsEpic';
import { receiveOnly } from '../../../../../epics/makeCondition';
import { savePreferencesAction } from '../../../../Preferences/actions';
import { closeDialog } from '../../../../App/actionCreators';
import { OPEN_AI_TERMS_UPDATE } from '../../../../SiteSettings/termsAndConditions/actionTypes';
import { REQUIRED_FIELDS } from '../../AIText/Dialogs/EditAIText/EditingAssistant/constants';
import {
    updateErrorFields,
    validateRequiredFields,
    simplifyContent,
    sanitizeDisplayHtml,
    transformOnInsert,
    getPlainContent,
    createSlackAlert
} from './utils';
import { PREFERENCES_EPIC_VALUE_VAT } from '../../../../Preferences/preferencesVat';
import { CLOSE_STICKY_TOOLTIP } from '../../../../Tooltip/stickyTooltip/actionTypes';
import { containsWrappedItems } from '../../constants';

const initialState: EditAITextType = {
    agreeTerms: false,
    isQueryInProgress: false,
    generatingText: '',
    generatingVersion: '',
    queryVersions: [],
    promptText: '',
    isScrolledWhileStreaming: false,
    generationReminder: {
        showBanner: false,
        counter: 0
    },
    errorFields: [],
    showTermsCheckbox: true,
    hasWrappedElements: false,
    insertedVersion: 0,
    copiedVersion: 0,
    colorMap: {}
};

const addOrUpdateQueryVersion = (newVersion, queryVersions) => {
    let exist = false;
    let updatedVersions = queryVersions.map((version) => {
        if (version.id !== newVersion.id) {
            if (version.isSelected) { return { ...version, isSelected: false }; }
            return version;
        }
        exist = true;
        return newVersion;
    });
    if (!exist) {
        return [...updatedVersions, newVersion];
    }
    return updatedVersions;
};

const isVersionAlreadySelected = (versionId, allVersions) => {
    const version = allVersions.find((version) => version.id === versionId);
    return !!version?.isSelected;
};

const updateVersionItem = (versionId, props, allVersions) => {
    const version = allVersions.find((version) => version.id === versionId);
    if (!version) { return allVersions; }
    return addOrUpdateQueryVersion({ ...version, ...props }, allVersions);
};

export const editAITextEpic = makeEpic({
    defaultState: initialState,
    valueActionType,
    updaters: [
        {
            conditions: [
                ReceiveOnlySelectedComponentSelector,
                receiveOnly(termsAndConditionsGlobalDataVAT),
                receiveOnly(PREFERENCES_EPIC_VALUE_VAT),
                REPLACE_DIALOG
            ],
            reducer: ({ state, values: [selectedComponent, termsAndConditions, preferences, dialog] }) => {
                const { dialogId, } = dialog;
                if (dialogId !== EDIT_AI_TEXT_DIALOG_ID) {
                    return { state };
                }

                let agreeTermsOpenAI = termsAndConditions?.openAI;
                const showTermsCheckbox = !agreeTermsOpenAI;
                const content = selectedComponent.content;
                let simplifiedContent, colorMap;
                try {
                    const simplifiedContentMap = simplifyContent(content);
                    simplifiedContent = simplifiedContentMap.simplifiedContent;
                    colorMap = simplifiedContentMap.colorMap;
                } catch (e) {
                    simplifiedContent = getPlainContent(content);
                    colorMap = {};
                    createSlackAlert(content, e);
                }
                let multipleActionsToDispatch: any = [];
                if (!preferences.hideWritingAssistantTooltip) {
                    multipleActionsToDispatch.push(savePreferencesAction({
                        hideWritingAssistantTooltip: true
                    }));
                    multipleActionsToDispatch.push({ type: CLOSE_STICKY_TOOLTIP });
                }

                const originalQueryItem = {
                    id: makeUuid(),
                    isOriginal: true,
                    text: simplifiedContent || '',
                    isSelected: true,
                };

                return {
                    state: {
                        ...initialState,
                        agreeTerms: agreeTermsOpenAI,
                        showTermsCheckbox,
                        queryVersions: [originalQueryItem],
                        hasWrappedElements: containsWrappedItems(content),
                        colorMap
                    },
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [
                Actions.REGENERATE_TEXT_BUTTON_CLICKED
            ],
            reducer: ({ state, values: [payload] }) => {
                if (state.isQueryInProgress) {
                    return { state };
                }
                const { instructions, inputText, mode } = payload,
                    queryVersions = state.queryVersions,
                    generatingVersion = makeUuid();

                const newVersion = { id: generatingVersion, text: '', isSelected: true, mode, instructions };

                const multipleActionsToDispatch = [
                    getRegeneratedAiText({ inputText, mode, instructions }),
                ];

                let generationReminder = state.generationReminder;

                if (instructions) {
                    //When instructions are given then dont show the generation reminder at all
                    generationReminder = initialState.generationReminder;
                } else if (generationReminder.prevActionType === mode) {
                    // If the action performed is same as previous one then increment the counter
                    generationReminder.counter += 1;
                } else {
                    // If new action is done on text then reset the generation banner in state
                    generationReminder = {
                        counter: 1,
                        prevActionType: mode,
                        showBanner: false
                    };
                }

                return {
                    state: {
                        ...state,
                        isQueryInProgress: true,
                        promptText: '',
                        generatingVersion,
                        isScrolledWhileStreaming: false,
                        queryVersions: addOrUpdateQueryVersion(newVersion, queryVersions),
                        errorState: '',
                        generationReminder
                    },
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [
                Actions.REGENERATE_TEXT_SUCCESS
            ],
            reducer: ({ state, values: [{ data, done }] }) => {
                let queryVersions = state.queryVersions;
                let isQueryInProgress = state.isQueryInProgress,
                    generatingText = state.generatingText,
                    generatingVersion = state.generatingVersion,
                    isScrolledWhileStreaming = state.isScrolledWhileStreaming;

                let queryData = generatingText + data;
                let generationReminder = state.generationReminder;
                let actionToDispatch;
                queryVersions = updateVersionItem(
                    generatingVersion,
                    { text: sanitizeDisplayHtml(queryData) },
                    queryVersions
                );
                if (done) {
                    queryData = data;
                    isQueryInProgress = false;
                    generatingVersion = '';
                    generatingText = '';
                    actionToDispatch = { type: Actions.REGENERATE_TEXT_COMPLETE_EVENT };
                    // Showing the generation banner when user does same action 3 or more times consecutively
                    generationReminder.showBanner = generationReminder.counter >= 3;
                } else {
                    isQueryInProgress = true;
                }

                return {
                    state: {
                        ...state,
                        isScrolledWhileStreaming,
                        isQueryInProgress,
                        queryVersions,
                        generatingVersion,
                        generatingText: queryData,
                        errorState: '',
                        generationReminder
                    },
                    actionToDispatch
                };
            },
        },
        {
            conditions: [
                Actions.REGENERATE_TEXT_FAILED
            ],
            reducer: ({ state, values: [{ statusCode }] }) => {
                let queryVersions = state.queryVersions;
                const generatingVersion = state.generatingVersion,
                    isScrolledWhileStreaming = state.isScrolledWhileStreaming,
                    currentVersion = queryVersions.find((version) => version.id === generatingVersion) || {};

                queryVersions = queryVersions.filter((version) => version.id !== generatingVersion);
                const errorState = ERROR_STATE_CODES[statusCode] || ERROR_STATES.CONNECTION_ERROR;
                const actionToDispatch = { type: Actions.REGENERATE_EDIT_TEXT_FAILED_EVENT, payload: { errorState, currentVersion } };

                // In case of error, not considering the attept while deciding to show generation banner
                let generationReminder = {
                    ...state.generationReminder,
                    showBanner: false
                };

                if (generationReminder.counter) {
                    generationReminder.counter -= 1;
                }

                return {
                    state: {
                        ...state,
                        errorState,
                        isScrolledWhileStreaming,
                        isQueryInProgress: false,
                        queryVersions,
                        generatingVersion: '',
                        generatingText: '',
                        generationReminder
                    },
                    actionToDispatch
                };
            },
        },
        {
            conditions: [
                Actions.REGENERATED_TEXT_SELECTED
            ],
            reducer: ({ state, values: [{ versionId }] }) => {
                if (state.isQueryInProgress) {
                    return { state };
                }

                if (isVersionAlreadySelected(versionId, state.queryVersions)) {
                    return { state };
                }

                const queryVersions = updateVersionItem(versionId, { isSelected: true }, state.queryVersions);
                return {
                    state: {
                        ...state,
                        queryVersions,
                        promptText: ''
                    }
                };
            },
        },
        {
            conditions: [
                Actions.AI_EDIT_INSERT_BUTTON_CLICKED,
                ReceiveOnlySelectedComponentsIdsSelector,
            ],
            reducer: ({ state, values: [{ content, versionNumber }, selectedComponentsIds] }) => {
                const newContent = content.replace(/\n\s*/g, '');
                const transformedText = transformOnInsert(newContent, state.colorMap);

                return {
                    state: {
                        ...state,
                        insertedVersion: versionNumber
                    },
                    actionToDispatch: {
                        type: AI_TEXT_REPLACE_TEXT_CONTENT,
                        payload: {
                            content: transformedText,
                            componentId: selectedComponentsIds[0]
                        },
                        forwardTo: {
                            kind: WORKSPACE_COMPONENT_KIND,
                            id: selectedComponentsIds[0]
                        }
                    }
                };
            }
        },
        {
            conditions: [Actions.AI_EDIT_COPY_BUTTON_CLICKED],
            reducer: ({ state, values: [{ versionNumber }] }) => ({ state: { ...state, copiedVersion: versionNumber } })
        },
        {
            conditions: [Actions.AI_EDIT_TEXT_PANEL_SCROLLED],
            reducer: ({ state }) => ({ state: { ...state, isScrolledWhileStreaming: true } })
        },
        {
            conditions: [Actions.AI_EDIT_TEXT_PANEL_SCROLLED_TO_BOTTOM],
            reducer: ({ state }) => ({ state: { ...state, isScrolledWhileStreaming: false } })
        },
        {
            conditions: [Actions.UPDATE_PROMPT_TEXT],
            reducer: ({ state, values: [{ promptText }] }) => ({ state: { ...state, promptText } })
        },
        {
            conditions: [Actions.AI_TEXT_EDIT_INTRO_CLOSED],
            reducer: ({ state }) => {
                return {
                    state,
                    multipleActionsToDispatch: [savePreferencesAction({
                        hideAIEditTextIntro: true
                    }), closeDialog()]
                };
            }
        },
        {
            conditions: [receiveOnly(termsAndConditionsGlobalDataVAT), Actions.AI_TEXT_EDIT_INTRO_CLICKED],
            reducer: ({ state, values: [termsAndConditions] }) => {
                const errorFields = validateRequiredFields(state);
                if (errorFields.length) {
                    return {
                        state: {
                            ...state,
                            errorFields
                        }
                    };
                }
                const multipleActionsToDispatch:Array<Object> = [];
                multipleActionsToDispatch.push(savePreferencesAction({
                    hideAIEditTextIntro: true
                }));
                if (!termsAndConditions?.openAI) {
                    multipleActionsToDispatch.push({
                        type: OPEN_AI_TERMS_UPDATE,
                        payload: state.agreeTerms
                    });
                }
                return { state: { ...state,
                    agreeTerms: true,
                    showTermsCheckbox: false },
                multipleActionsToDispatch };
            }
        },
        {
            conditions: [
                Actions.AI_TEXT_EDIT_AGREE_TERMS_CHECKED
            ],
            reducer: ({ state }) => {
                const agreeTerms = !state.agreeTerms;
                const errorFields = updateErrorFields(state.errorFields, REQUIRED_FIELDS.agreeTerms, agreeTerms);

                return {
                    state: {
                        ...state,
                        agreeTerms,
                        errorFields
                    }
                };
            }
        },
    ]
});
