/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import styles from './ProductSelector.css';
import CategoriesContainer from './CategoriesContainer';
import ProductsContainer from './ProductsContainer';

type Props = {
    appDispatch: Dispatch,
    selectedProducts: Set<any>,
    updateSelectedProducts: Function,
    hasOnlyDigitalShop: boolean
}

const ProductSelector = ({
    appDispatch,
    selectedProducts,
    updateSelectedProducts,
    hasOnlyDigitalShop
}: Props) => {
    const [products, setProducts] = useState([]);
    const [productsTotal, setProductsTotal] = useState([]);
    const [filters, setFilters] = useState({ categoryId: '', productName: '' });

    const applyFilters = (f) => {
        setFilters({ ...filters, ...f });
    };

    const setProductRecords = ({
        totalRecords,
        products: f
    }, reset = false) => {
        setProductsTotal(totalRecords);
        setProducts(reset ? f : [...products, ...f]);
    };

    const params = {
        filters,
        applyFilters,
        products,
        productsTotal,
        setProductRecords,
        selectedProducts,
        updateSelectedProducts,
        appDispatch,
        hasOnlyDigitalShop
    };

    return (
        <div className={styles.container}>
            <CategoriesContainer
                selectedCategoryId={filters.categoryId}
                applyFilters={applyFilters}
                appDispatch={appDispatch}
            />
            <ProductsContainer {...params} />
        </div>
    );
};

export default ProductSelector;
