/* eslint-disable max-len */

import { connect } from "react-redux";
import React from 'react';
import styles from './styles.css';
import { Dialog, DialogBody, StripTypes } from "../../../../../../view/common/dialogs/baseDialog/index";
import LoadingIndicator from "../../../../../../view/common/LoadingIndicator/index";
import { makeEpicStateSelector } from "../../../../../../epics/makeEpic";
import { BUSINESS_LISTING_SITE_SETTINGS_SERVICE_EPIC_VALUE } from "../../businessListingSiteSettingsServiceVAT";
import type { BusinessListingServiceDefaultStateType, GmbDataType } from "../../../flowTypes";
import {
    replaceWithBusinessListTabDialog,
    replaceWithCreateListingDialog,
} from "../../actionCreators";
import { ConnectCongratsView } from "./ConnectCongratsView";
import { GMBListingView } from "../../GMBListingDialog/view/index";
import * as actionTypes from "../../actionTypes";
import { CongratsOrErrorHandlerCommonView } from "./CongratsOrErrorHandlerCommonView";
import { GOOGLE_REVIEWS_EPIC } from "../../../../../oneweb/GoogleReviews/epic/valueActionType";
import { replaceWithConnectToReviewDialog, reviewConfigSuccessAction } from "../../../../../oneweb/GoogleReviews/epic/actionCreators";
import { closeDialogAction } from "../../../../../../../../src/dashboard/src/components/Dialog/actions";

type Props = {
    dispatch: Dispatch,
    actionType: string,
    gmbDataList: Array<GmbDataType>,
    accountName: string,
    serviceData: BusinessListingServiceDefaultStateType,
    reviewsConfigInProgress: boolean,
};

const View = (props: Props) => {
    const
        { dispatch, actionType, gmbDataList, accountName, serviceData, reviewsConfigInProgress } = props,
        { isWaitingForApi, gmbData } = serviceData;
    let onCloseAction = reviewsConfigInProgress ? replaceWithConnectToReviewDialog : replaceWithBusinessListTabDialog;
    const getView = () => {
        let view,
            onSuccessAction = reviewsConfigInProgress ? reviewConfigSuccessAction : replaceWithBusinessListTabDialog;
        const onFailureAction = reviewsConfigInProgress ? replaceWithConnectToReviewDialog : replaceWithBusinessListTabDialog;

        switch (actionType) {
            case actionTypes.CONNECT_SUCCESS:
                onCloseAction = onSuccessAction;
                view = (
                    gmbData
                        ? (
                            <ConnectCongratsView
                                addressFields={gmbData.addressFields}
                                dispatch={dispatch}
                                openingHours={(gmbData.openingHours)}
                                phoneNumber={gmbData.phoneNumber}
                                reviewsConfigInProgress={reviewsConfigInProgress}
                            />
                        )
                        : null
                );
                break;
            case actionTypes.CONNECT_LISTINGS:
                view = (
                    <GMBListingView
                        gmbDataList={gmbDataList}
                        accountName={accountName}
                        titleMessage="msg: BusinessListing.connectListing.titleMessage {Select the listing you would like to connect to your site.}"
                        description="msg: BusinessListing.connectListing.description {You have multiple listings associated with this Google account. Please choose the listing you would like to sync with your site.}"
                        primaryBtnText="msg: BusinessListing.connectListing.connectToListing {Connect to the listing}"
                        primaryBtnAction={(data) => { dispatch({ type: actionTypes.CONNECT_TO_THIS_LISTING, payload: data }); }}
                    />
                );
                break;
            case actionTypes.CONNECT_LISTINGS_ON_CREATE:
                view = (
                    <GMBListingView
                        gmbDataList={gmbDataList}
                        accountName={accountName}
                        titleMessage={[
                            "msg: BusinessListing.connectListing.onCreateTitleMessageMultipleListings {Looks like you already have {listingsCount, plural, one {a listing} other {other listings}}}",
                            { listingsCount: gmbDataList && gmbDataList.length },
                        ]}
                        description={[
                            "msg: BusinessListing.connectListing.onCreateDescriptionMultipleListings {Would you like to connect to {listingsCount, plural, one {this existing listing} other {one of these existing listings}}?}",
                            { listingsCount: gmbDataList && gmbDataList.length },
                        ]}
                        primaryBtnText="msg: BusinessListing.connectListing.onCreateConnectToListing {Connect to this listing}"
                        primaryBtnAction={(data) => { dispatch({ type: actionTypes.CONNECT_TO_THIS_LISTING, payload: data }); }}
                        secondaryBtnText="msg: BusinessListing.connectListing.createNew {Create a new listing}"
                        secondaryBtnAction={() => { dispatch(replaceWithCreateListingDialog()); }}
                    />
                );
                break;
            case actionTypes.CONNECT_FAILURE:
                view = <CongratsOrErrorHandlerCommonView
                    iconClass={styles.errorIcon}
                    titleMessage="msg: common.oopsSomethingWentWrong {Oops, it looks like something went wrong}"
                    descriptionMain="msg: BusinessListing.connectError.descLine1 {Your Google My Business listing could not be connected.}"
                    descriptionSub="msg: common.pleaseTryAgain {Please try again.}"
                    /* TODO: WBTGEN-20148: try again implementation */
                    primaryBtnAction={() => { dispatch(onFailureAction()); }}
                    primaryBtnText="msg: common.tryAgain {Try again}"
                />;
                break;
            case actionTypes.NO_GMB_FAILURE:
                view = <CongratsOrErrorHandlerCommonView
                    iconClass={styles.errorIcon}
                    titleMessage="msg: common.noGMBtitleMessage {Looks like you don't have a listing yet}"
                    descriptionMain="msg: BusinessListing.noGMBError.descLine1 {We couldn't find a listing registered under this Google account.}"
                    primaryBtnAction={() => {
                        dispatch({ type: actionTypes.CREATE_YOUR_LISTING_BTN_CLICKED_ACTION });
                    }}
                    primaryBtnText="msg: gmb.createAListing {Create a listing}"
                />;
                break;
            case actionTypes.IMPORT_SUCCESS: {
                onSuccessAction = reviewsConfigInProgress ? reviewConfigSuccessAction : replaceWithBusinessListTabDialog;
                view = <CongratsOrErrorHandlerCommonView
                    iconContainer={styles.successIconContainer}
                    iconClass={styles.successIcon}
                    titleMessage="msg: BusinessListing.importSuccess.title {Your listing info is imported!}"
                    descriptionMain="msg: BusinessListing.importSuccess.descLine1 {Your business contact information was successfully imported from your listing to your website.}"
                    primaryBtnText="msg: common.ok {OK}"
                    primaryBtnAction={() => { dispatch(onSuccessAction()); }}
                />;
                break;
            }
            case actionTypes.IMPORT_FAILURE:
                view = <CongratsOrErrorHandlerCommonView
                    iconClass={styles.errorIcon}
                    titleMessage="msg: BusinessListing.importError.title {Your listing couldn't be imported}"
                    descriptionMain="msg: BusinessListing.importError.descLine1 {There was an issue with importing your business contact information from your listing to the Website Builder.}"
                    descriptionSub="msg: BusinessListing.importError.descLine2 {Don't worry, you can always enter it manually in the Website Builder.}"
                    primaryBtnText="msg: common.ok {OK}"
                    primaryBtnAction={() => { dispatch(onFailureAction()); }}
                />;
                break;
            case actionTypes.CREATE_SUCCESS: {
                if (reviewsConfigInProgress) {
                    view = <CongratsOrErrorHandlerCommonView
                        iconContainer={styles.successIconContainer}
                        iconClass={styles.successIcon}
                        titleMessage="msg: BusinessListing.googleReviews.createSuccess.title {Verify your new business listing}"
                        descriptionMain="msg: BusinessListing.googleReviews.createSuccess.descLine1 {You’ve now created a business listing on Google. Verify it through Google and then ask your customers to write reviews. Once you’ve gotten some reviews, you can come back here to add them to your website.}"
                        primaryBtnText="msg: common.goToGoogleToVerify {Go to Google to verify}"
                        secondaryBtnText="msg: common.doItLater {I'll do it later}"
                        secondaryBtnAction={() => dispatch(closeDialogAction())}
                        showSecondaryBtn
                    />;
                } else {
                    view = <CongratsOrErrorHandlerCommonView
                        iconContainer={styles.successIconContainer}
                        iconClass={styles.successIcon}
                        titleMessage="msg: BusinessListing.createSuccess.title {Congrats!}"
                        descriptionMain="msg: BusinessListing.createSuccess.descLine1 {You now have a Google My Business Listing.}"
                        descriptionSub="msg: BusinessListing.createSuccess.descLine2 {You'll need to verify it through Google.}"
                        primaryBtnText="msg: common.done {Done}"
                        primaryBtnAction={() => dispatch(replaceWithBusinessListTabDialog())}
                        secondaryBtnText="msg: common.goToGoogle {Go to Google}"
                    />;
                }
                break;
            }
            case actionTypes.CREATE_FAILURE:
                view = <CongratsOrErrorHandlerCommonView
                    iconClass={styles.errorIcon}
                    titleMessage="msg: common.oopsSomethingWentWrong {Oops, it looks like something went wrong}"
                    descriptionMain="msg: BusinessListing.createError.descLine1 {Your Google My Business listing could not be created.}"
                    descriptionSub="msg: common.pleaseTryAgain {Please try again.}"
                    primaryBtnAction={() => { dispatch(onFailureAction()); }}
                    primaryBtnText="msg: common.tryAgain {Try again}"
                />;
                break;
            default: return null;
        }

        return view;
    };

    return (
        <Dialog
            stripType={StripTypes.NONE}
            onClose={() => dispatch(onCloseAction())}
        >
            <DialogBody className={styles.dialogBody}>
                { isWaitingForApi ? <LoadingIndicator /> : getView() }
            </DialogBody>
        </Dialog>
    );
};

const mapStateToProps = (appState) => {
    const { reviewsConfigInProgress } = makeEpicStateSelector(GOOGLE_REVIEWS_EPIC)(appState);
    return ({
        serviceData: makeEpicStateSelector(BUSINESS_LISTING_SITE_SETTINGS_SERVICE_EPIC_VALUE)(appState),
        reviewsConfigInProgress
    });
};

export const CongratsOrErrorHandlerDialogsView = connect(mapStateToProps)(View);

