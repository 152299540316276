/* eslint-disable max-len */

import * as R from 'ramda';
import React from "react";
import cx from "classnames";
import platform from "platform";
import { DataSite } from "../../../../../dal/model/index";
import * as mp from "../../../../mappers/path";
import { injectIntl, Intl } from "../../../../view/intl/index";
import FontSizeSelector from "../../../presentational/FontSize/index";
import ColorProperty from "../../../presentational/ColorProperty/index";
import HorizontalSpacer from "../../../../view/common/HorizontalSpacer";
import GlobalstyleSelector from '../../../presentational/GlobalstyleSelector/index';
import { MctaVerticalBar, MctaButton } from '../../../componentMainActions/index';
import { DEFAULT_MCTA_COMBOBOX_MENUSTYLE } from '../../../componentMainActions/constants';
import { DEFAULT_TEXT_TOOLBAR_COLOR_BOX_STYLE, BROKEN_LINK } from "../../Text/constants";
import * as selectors from '../../../Workspace/epics/stylesheets/selectors';
import openLinkChooserDialog from '../../../../view/common/dialogs/LinkChooserDialog/actionCreators/openLinkChooserDialog'; // eslint-disable-line max-len
import TableGlobalStyleKind from "../globalStyle/kind";
import TableKind from '../kind';
import * as actionTypes from "../actionTypes";
import { getFont, getNumRowsAndCols, getHorizontalAlign, getCellToReadValues } from "../utils";
import AlignmentControls from '../../../componentMainActions/MctaAlignmentControls/alignmentControls';
import tableOptionMapper from "../globalStyle/options";
import mctaStyles from '../../../componentMainActions/MctaButton/styles.css';
import tblStyles from "../view/Table.css";
import { MacSymbol } from "../../../ContextMenu/view/MacSymbols";
import type { TableComponent, TableCalcRenderPropsResult } from "../flowTypes";
import type { TinyMceEpicState } from "../../../App/epics/tinyMceEpic/flowTypes";
import type { TableEditModeState } from "../epics/tableEditMode/flowTypes";
import type { Stylesheets, StylesheetsIdToNameMap } from "../../../Workspace/epics/stylesheets/flowTypes";
import { getTableThemeRulesFromBackgroundTheme } from '../../../ThemeGlobalData/themeRules';
import { THEME_COLOR_PICKER } from '../../../ColorPicker/constants';
import { getStyleById } from '../../../Workspace/epics/stylesheets/selectors';
import type { ThemeSiteSettingsDataType, ThemeColorDataType } from '../../../ThemeGlobalData/flowTypes';

const
    getButtonLinkClassName = (linkAction) => {
        if (!linkAction) {
            return null;
        } else if (linkAction.link === BROKEN_LINK) {
            return mctaStyles.mctaLinkButtonWithBrokenLinkSet;
        }
        return mctaStyles.mctaLinkButtonWithLinkSet;
    },
    getTitleLink = (linkAction, canSetLink, selectedText) => {
        let titleLink = '';

        if (linkAction) {
            titleLink = "msg: common.changeLink {Change link}";
        } else if (canSetLink) {
            titleLink = "msg: common.addLink {Add link}";
        } else if (selectedText !== '') {
            titleLink = "msg: mcta.text.selectLessText {Try selecting less text}";
        }

        return titleLink;
    };

type Props = {
    site: DataSite,
    selectedComponent: TableComponent,
    tinyMceState: TinyMceEpicState,
    stylesheets: Stylesheets,
    tableEditModeState: TableEditModeState,
    stylesheetsIdToNameMap: StylesheetsIdToNameMap,
    colorPickerOpened: boolean,
    dispatch: Dispatch,
    children?: any,
    intl: Intl,
    themeSettingsData: ThemeSiteSettingsDataType,
    selectedComponentProps: TableCalcRenderPropsResult,
    themeColorsData: ThemeColorDataType,
}

const MCTAView = injectIntl((props: Props) => {
    const
        {
            stylesheets,
            selectedComponent,
            tinyMceState,
            tableEditModeState,
            stylesheetsIdToNameMap,
            colorPickerOpened,
            intl,
            children,
            dispatch,
            selectedComponentProps
        } = props,
        { numRows, numCols } = getNumRowsAndCols(selectedComponent.cells),
        { mctaHorizontalAlignControlExpanded } = tableEditModeState,
        styles = selectors.getAllStylesheets(stylesheets),
        tableGlobalStyleOptions = tableOptionMapper(styles, intl),
        configCell = getCellToReadValues(selectedComponent, tableEditModeState),
        tableGlobalstyleId = R.path(mp.styleGlobalId, configCell),
        horizontalAlign = getHorizontalAlign(tinyMceState),
        prefixTextStyle = platform.os.family === 'OS X' ? MacSymbol.commandK : 'Ctrl',
        titleBold = ["msg: mcta.text.fontWeight {Bold ({prefixTextStyle} + B)}", { prefixTextStyle }],
        titleItalic = ["msg: mcta.text.styleItalic {Italic ({prefixTextStyle} + I)}", { prefixTextStyle }],
        titleUnderline = ["msg: mcta.text.styleUnderline {Underline ({prefixTextStyle} + U)}", { prefixTextStyle }],
        textColorTitle = intl.msgJoint("msg: mcta.textColor {Text colour}"),
        autoColorMode = props.themeSettingsData.autoColorMode,
        selectedParentTheme = selectedComponentProps.selectedParentTheme,

        getFontProp = getFont({ tinyMceState, stylesheets }),

        { value: bold } = getFontProp(mp.bold, false),
        { value: italic } = getFontProp(mp.italic, false),
        { value: underline } = getFontProp(mp.underline, false),

        { value: color } = getFontProp(mp.color),
        { value: fontSize } = getFontProp(mp.size),

        canSetLink = tableEditModeState.cellInEditModeIndex > -1 ? tinyMceState.canSetLink : true,
        selectedText = '',
        optionRenderer = (value, label) => (
            <div className={cx(stylesheetsIdToNameMap[value], `${stylesheetsIdToNameMap[value]}-ddo`, autoColorMode && selectedParentTheme)}>
                {label}
            </div>
        ),
        { linkAction } = tinyMceState,
        titleLink = getTitleLink(linkAction, canSetLink, selectedText);

    const generateIcon = (className, action, disabled, title) => (
        <div
            className={cx(tblStyles.tblCMAIcons, className)}
            onClick={() => !disabled && dispatch({ type: action })}
            data-title={title}
        />
    );

    return (
        <div>
            <GlobalstyleSelector
                onSelectAction={actionTypes.TABLE_CELL_GLOBAL_STYLE_UPDATED}
                options={tableGlobalStyleOptions}
                dispatch={dispatch}
                style={{ width: 200 }}
                menuStyle={DEFAULT_MCTA_COMBOBOX_MENUSTYLE}
                selected={tableGlobalstyleId}
                selectedKind={TableGlobalStyleKind}
                optionRenderer={optionRenderer}
                className={autoColorMode ? 'autoColorTextMctaDropdown' : ''}
                showEditLink
                mcta
            />
            <HorizontalSpacer x={5} />
            <MctaVerticalBar />
            <HorizontalSpacer x={5} />
            <MctaButton
                title={titleBold}
                icon="BOLD"
                selected={Boolean(bold)}
                onClick={() => dispatch({ type: actionTypes.TABLE_TEXT_BOLD_TOGGLE, payload: !bold })}
            />
            <HorizontalSpacer x={5} />
            <MctaVerticalBar />
            <HorizontalSpacer x={5} />
            <MctaButton
                title={titleItalic}
                icon="ITALIC"
                selected={Boolean(italic)}
                onClick={() => dispatch({ type: actionTypes.TABLE_TEXT_ITALIC_TOGGLE, payload: !italic })}
            />
            <HorizontalSpacer x={5} />
            <MctaVerticalBar />
            <HorizontalSpacer x={5} />
            <MctaButton
                title={titleUnderline}
                icon="UNDERLINE"
                selected={Boolean(underline)}
                onClick={() => dispatch({ type: actionTypes.TABLE_TEXT_UNDERLINE_TOGGLE, payload: !underline })}
            />
            <HorizontalSpacer x={5} />
            <MctaVerticalBar />
            <HorizontalSpacer x={5} />
            <div className={styles.fontSize}>
                <FontSizeSelector
                    onChangeAction={actionTypes.TABLE_TEXT_FONT_SIZE_CHANGED}
                    selected={fontSize}
                    dispatch={dispatch}
                    searchable={false}
                    menuStyle={DEFAULT_MCTA_COMBOBOX_MENUSTYLE}
                    mcta
                />
            </div>
            <HorizontalSpacer x={5} />
            {
                autoColorMode
                    ? <ColorProperty
                        colorPickerKind={THEME_COLOR_PICKER}
                        themeColor={configCell.style.text.themeColor || null}
                        themeColorsData={props.themeColorsData}
                        defaultThemeColor={
                            getTableThemeRulesFromBackgroundTheme(props.selectedComponentProps.selectedParentTheme, props.themeColorsData)[
                                getStyleById(configCell.style.globalId)(props.stylesheets.styles).ref
                            ]
                        }
                        dispatch={dispatch}
                        onChangeAction={actionTypes.TABLE_TEXT_COLOR_CHANGED_AUTO_COLOR}
                        title={textColorTitle}
                        colorBoxStyle={DEFAULT_TEXT_TOOLBAR_COLOR_BOX_STYLE}
                        colorPickerOpened={colorPickerOpened}
                        showCaret
                    />
                    : <ColorProperty
                        dispatch={dispatch}
                        onChangeAction={actionTypes.TABLE_TEXT_COLOR_CHANGED}
                        color={color}
                        title={textColorTitle}
                        colorBoxStyle={DEFAULT_TEXT_TOOLBAR_COLOR_BOX_STYLE}
                        colorPickerOpened={colorPickerOpened}
                        showCaret
                    />
            }
            <HorizontalSpacer x={10} />
            <MctaVerticalBar />
            <HorizontalSpacer x={5} />
            <MctaButton
                title={titleLink}
                className={getButtonLinkClassName(linkAction)}
                icon="LINK"
                disabled={!canSetLink}
                onClick={() => dispatch(openLinkChooserDialog({
                    setLinkAction: actionTypes.TABLE_TEXT_SET_LINK,
                    input: linkAction
                }))}
            />
            <HorizontalSpacer x={5} />
            <MctaVerticalBar />
            <HorizontalSpacer x={5} />
            <AlignmentControls
                actionTypeToDispatch={actionTypes.TABLE_CELL_HORIZONTAL_ALIGNMENT_CHANGED}
                onMouseEnterCollapsedViewActionType={actionTypes.TABLE_MCTA_ALIGN_MOUSE_ENTER}
                onMouseLeaveExpandedViewActionType={actionTypes.TABLE_MCTA_ALIGN_MOUSE_LEAVE}
                alignment={horizontalAlign}
                expanded={mctaHorizontalAlignControlExpanded}
                dispatch={dispatch}
                kind={TableKind}
            />
            <HorizontalSpacer x={5} />
            <MctaVerticalBar />
            <HorizontalSpacer x={5} />
            {generateIcon(tblStyles.tblAddRow, actionTypes.TABLE_ADD_ROW, false, intl.msgJoint("msg: component.table.addRow {Add row}"))}
            <HorizontalSpacer x={5} />
            <MctaVerticalBar />
            <HorizontalSpacer x={5} />
            {generateIcon(tblStyles.tblRemoveRow, actionTypes.TABLE_REMOVE_ROW, numRows === 1, intl.msgJoint("msg: component.table.removeRow {Remove row}"))}
            <HorizontalSpacer x={5} />
            <MctaVerticalBar />
            <HorizontalSpacer x={5} />
            {generateIcon(tblStyles.tblAddColumn, actionTypes.TABLE_MCTA_ADD_COLUMN_PRESSED, false, intl.msgJoint("msg: component.table.addColumn {Add column}"))}
            <HorizontalSpacer x={5} />
            <MctaVerticalBar />
            <HorizontalSpacer x={5} />
            {generateIcon(tblStyles.tblRemoveColumn, actionTypes.TABLE_REMOVE_COLUMN, numCols === 1, intl.msgJoint("msg: component.table.removeColumn {Remove column}"))}
            {children && <HorizontalSpacer x={5} />}
            {children}
        </div>
    );
});

export default {
    editView: MCTAView,
    editButtonText: 'msg: component.table.editTable {Edit table}'
};
