import React from 'react';
import cx from 'classnames';
import Msg from '../../../../view/intl/Msg';
import {
    PRODUCT_WIDGET_SETUP_PRODUCT_TOOLTIP_POSITION_UPDATED
} from "../actionTypes";
import { SingleProductComponentTip } from "../../../Tooltip/ids";
import { Intl } from "../../../../view/intl/injectIntl";
import { SetupProductTooltip } from './SetupProductTooltip';
import styles from './style.css';

type Props = {
    intl: Intl,
    dispatch: Function,
    componentId: string,
    isSubscriptionTypeCompatible: boolean
}

const calcToRightAndVCenter = ({ top, width, height, left }) => {
    // position relative to the help tip
    return { x: left + width, y: (top + (height / 2)) - 4 };
};

export const SetupProductButton = (props: Props) => {
    const { dispatch, intl, componentId } = props;
    const ref = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        if (props.isSubscriptionTypeCompatible) {
            const clientRect = ref.current && ref.current.getBoundingClientRect();
            const { top, width, height, left } = clientRect || {};

            dispatch({
                type: PRODUCT_WIDGET_SETUP_PRODUCT_TOOLTIP_POSITION_UPDATED,
                payload: {
                    position: calcToRightAndVCenter({ top, width, height, left }),
                    id: SingleProductComponentTip,
                    elementDimensions: { top, width, height, left },
                    customClass: styles.setupProductTooltipContainer,
                    msg: { text: '' },
                    timeout: 0,
                    showIcon: false,
                    showTipText: false,
                    textStyle: { display: 'none' },
                    customHTML: <SetupProductTooltip
                        dispatch={dispatch}
                        componentId={componentId}
                    />,
                }
            });
        }
    }, [ref]);

    const classNames = cx({
        [styles.setupProduct]: props.isSubscriptionTypeCompatible,
        [styles.setupProductDisabled]: !props.isSubscriptionTypeCompatible
    });

    return (
        <div
            ref={ref}
            data-title={(!props.isSubscriptionTypeCompatible) ?
                intl.msgJoint('msg: component.productWidget.DisabledTooltip {Upgrade your plan to set up product}') : ''}
        >
            <Msg
                className={classNames}
                k="component.webshop.productWidget.setupProduct"
            >
                Set up product
            </Msg>
        </div>
    );
};

