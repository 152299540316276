import { facebookGraphApiAction } from "./facebookSdkActions";

type Props = {
    dispatch: Dispatch,
    successAction: string,
    failureAction: string
};

export const fetchTokenMeAccountsData = ({
    dispatch,
    successAction,
    failureAction
}: Props) => {
    dispatch(facebookGraphApiAction(
        'me/accounts/',
        'GET',
        { fields: 'id,name,access_token' },
        successAction,
        failureAction
    ));
};
