/* eslint-disable max-len */

import React from 'react';
import styles from './styles.css';
import { Dialog, DialogBody, StripTypes } from "../../../../../view/common/dialogs/baseDialog/index";
import { Intl, Msg } from "../../../../../view/intl/index";
import {
    PrimaryButton
} from '../../../../../view/common/Button/index';
import * as actionTypes from "../actionTypes";
import { replaceDialogWithUpgradePlanDialog } from "../../../../TopBar/view/Upgrade/UpgradePlansDialog/OpenUpgradePlanDialog";
import Scrollbar from '../../../../../view/common/Scrollbar/index';
import { replaceWithBusinessListTabDialog } from "../actionCreators";
import { isTrialSubscription, isUnknownSubscription } from "../../../../App/epics/subscriptionData/utils";
import type { SubscriptionData } from "../../../../../redux/modules/flowTypes";
import { upgradeDialogOpenedAction } from '../../../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions';
import { UpgradePlansDialogId } from '../../../../TopBar/view/dialogIds';
import { FEATURE_BUSINESS_LISTING } from '../../../../TopBar/view/Upgrade/tracking/contants';

export type PromoPageDialogProps = {
    intl: Intl;
    dispatch: Dispatch;
    isGmbAccessAllowed: boolean;
    subscriptionData: SubscriptionData,
}

const PromoPageDialog = (props: PromoPageDialogProps) => {
    const { intl, dispatch, isGmbAccessAllowed, subscriptionData } = props;
    const { subscriptionType } = subscriptionData;

    const UpgradeButton = () => (
        <span
            className={styles.upgrade}
            onClick={() => {
                dispatch(replaceDialogWithUpgradePlanDialog(subscriptionData, 'SiteSettings:BusinessListing:LearnMore'));
                dispatch(upgradeDialogOpenedAction({
                    feature: FEATURE_BUSINESS_LISTING,
                    dialogId: UpgradePlansDialogId
                }));
            }}
        >
            <Msg k="common.upgrade">Upgrade</Msg>
        </span>
    );

    const isDemoUserOrUnknown = isTrialSubscription(subscriptionType) || isUnknownSubscription(subscriptionType);
    const upgradeButton = !isDemoUserOrUnknown ? <UpgradeButton /> : undefined;

    return (
        <Dialog
            stripType={StripTypes.NONE}
            onClose={() => dispatch(replaceWithBusinessListTabDialog())}
        >
            <Scrollbar height="100vh" className={styles.scrollbar}>
                <DialogBody className={styles.dialogBody}>
                    <div className={styles.haveListing}>
                        <div className={styles.group}>
                            <div className={styles.gmbTitle}>
                                { intl.msgJoint("msg: gmb.googleMyBusiness {Google My Business}") }
                            </div>
                            <div className={styles.gmbDescription}>
                                { intl.msgJoint("msg: gmb.promoPage.gmbDescription {By connecting your website to your Google My Business listing, you can easily update your contact information and opening hours in one place.}") }
                            </div>
                            <div style={{ display: 'flex' }}>
                                {
                                    isGmbAccessAllowed ?
                                        <PrimaryButton
                                            className={styles.connectYourListing}
                                            onClick={() => {
                                                dispatch(replaceWithBusinessListTabDialog());
                                                dispatch({ type: actionTypes.CONNECT_YOUR_LISTING });
                                            }}
                                        >
                                            <Msg k="gmb.connectYourListing">Connect your listing</Msg>
                                        </PrimaryButton> :
                                        upgradeButton
                                }
                            </div>
                        </div>
                        <div className={styles.shop} />
                    </div>
                    <div className={styles.doNotHaveListing}>
                        <div className={styles.doNotHaveListingTitle}>
                            { intl.msgJoint("msg: gmb.promoPage.doNotHaveListingTitle {Don\'t have a listing yet?}") }
                        </div>
                        <div className={styles.doNotHaveListingDescription}>
                            { intl.msgJoint("msg: gmb.promoPage.doNotHaveListingDescription {With a Google My Business account, you get a free company profile that makes it easy to interact with customers anywhere on Google Search and Maps.}") }
                        </div>
                    </div>
                    <div className={styles.items}>
                        <div className={styles.item}>
                            <div className={styles.promoteYourBusinessIcon} />
                            <div className={styles.itemTitle}>
                                { intl.msgJoint("msg: gmb.promoPage.promoteYourBusinessTitle {Promote your business}") }
                            </div>
                            <div className={styles.itemDescription}>
                                { intl.msgJoint("msg: gmb.promoPage.promoteYourBusinessDescription {Put your business in front of many potential customers.}") }
                            </div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.shareCustomerReviewsIcon} />
                            <div className={styles.itemTitle}>
                                { intl.msgJoint("msg: gmb.promoPage.shareCustomerReviewsTitle {Share customer reviews}") }
                            </div>
                            <div className={styles.itemDescription}>
                                { intl.msgJoint("msg: gmb.promoPage.shareCustomerReviewsDescription {Let your potential customers see how great your business is.}") }
                            </div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.trackYourGrowthIcon} />
                            <div className={styles.itemTitle}>
                                { intl.msgJoint("msg: gmb.promoPage.trackYourGrowthTitle {Track your growth}") }
                            </div>
                            <div className={styles.itemDescription}>
                                { intl.msgJoint("msg: gmb.promoPage.trackYourGrowthDescription {See how your customers interact with your company profile.}") }
                            </div>
                        </div>
                    </div>
                    <div className={styles.createListingBtn}>
                        {
                            isGmbAccessAllowed ?
                                <PrimaryButton
                                    className={styles.createListing}
                                    onClick={() => {
                                        dispatch(replaceWithBusinessListTabDialog());
                                        dispatch({ type: actionTypes.CREATE_YOUR_LISTING_BTN_CLICKED_ACTION });
                                    }}
                                >
                                    <Msg k="gmb.createAListing">Create a listing</Msg>
                                </PrimaryButton> :
                                upgradeButton
                        }
                    </div>
                </DialogBody>
            </Scrollbar>
        </Dialog>
    );
};

export default PromoPageDialog;
