/* eslint-disable max-len */

import React from 'react';
import ellipsis from 'text-ellipsis';

import styles from '../PageInfoDialog.css';
import type { PageInfoSeoTabProps } from "../../flowTypes";
import { Msg } from '../../../../../../../view/intl';
import DialogCheckBox from '../../../../../../../view/common/dialogs/baseDialog/DialogCheckBox';
import * as Actions from "../../actions";
import { LabeledTextArea } from '../../../../../../inputControls/textArea';
import { TooltipPosition } from "../../../../../../presentational/Tooltip/constants";
import { QuestionTip } from "../../../../../../presentational/Tooltip/questionTip/QuestionTip";
import { parseIntDec } from "../../../../../../../../utils/number";
import { RadioButtonGroup } from "../../../../../../../view/common/RadioButton";
import { SeoPageTitleInTabOptions, PAGE_TITLE_TIP_MSG } from "../constants";
import Label from "../../../../../../../view/common/Label";
import { LabeledInput } from "../../../../../../inputControls/input/view";
import VerticalSpacer from "../../../../../../../view/common/VerticalSpacer";
import { getDefaultNonHomePageNameInBrowserTab } from "../../utils";

const
    PAGE_DESCRIPTION_TIP_DISTANCE = parseIntDec(styles.templateTipDistance),
    PAGE_DESCRIPTION_TIP_MSG = "msg: pageInfo.seo.pageDescription.tipMsg {Tell people more about what your site has to offer and encourage them to view this page. Your description should be between 50-300 characters (including spaces).}";

export default (props: PageInfoSeoTabProps) => {
    const
        {
            namesState: {
                name,
            },
            state: {
                customTitle,
                excludeFromIndexing,
                description,
                defaultHomePageNameInBrowserTab,
                pageNameOptionInBrowserTab,
            },
            isHomePage,
            urlParams,
            dispatch,
            isSeoEnabledForSiteSettings,
            settingsClickHandler,
            websiteTitle,
            domainName,
        } = props,
        renderPageDescriptionLabel = () => { // eslint-disable-line class-methods-use-this
            return (
                <div className={styles.pageDescriptionLabelContainer}>
                    <Msg className={styles.pageDescriptionLabel} k="pageDescription">Page description</Msg>
                    <QuestionTip
                        position={TooltipPosition.TOP}
                        distance={PAGE_DESCRIPTION_TIP_DISTANCE}
                        className={styles.pageDescriptionTipIcn}
                        theme={{
                            container: styles.pageDescriptionTipContainer,
                            tipInfo: styles.pageDescriptionTipInfo
                        }}
                    >{PAGE_DESCRIPTION_TIP_MSG}</QuestionTip>
                </div>
            );
        };

    const defaultNonHomePageNameInBrowserTab = getDefaultNonHomePageNameInBrowserTab(name.value, domainName, websiteTitle);
    const defaultDisplayTitle = isHomePage ? defaultHomePageNameInBrowserTab : defaultNonHomePageNameInBrowserTab;
    const displayTitle = pageNameOptionInBrowserTab === SeoPageTitleInTabOptions.CUSTOM ? customTitle.value : defaultDisplayTitle;

    const pageNameInBrowserTabOptions = [
        {
            label: "msg: pageInfo.seo.pageTitleInBrowserTab.default {Default title (Website title)}",
            value: SeoPageTitleInTabOptions.DEFAULT,
            sideChildren: (
                <Label isValue className={styles.defaultPageTitle}>
                    {defaultDisplayTitle}
                </Label>
            ),
        },
        {
            label: "msg: pageInfo.seo.pageTitleInBrowserTab.custom {Custom title}",
            value: SeoPageTitleInTabOptions.CUSTOM,
            sideChildren: (
                <div
                    id="seoCustomPageTitleContainer"
                    className={styles.seoCustomPageTitleContainer}
                    style={{ display: pageNameOptionInBrowserTab === SeoPageTitleInTabOptions.DEFAULT ? 'none' : 'block' }}
                >
                    <LabeledInput
                        label=""
                        state={customTitle}
                        onChangeAction={Actions.PAGE_INFO_CUSTOM_TITLE_CHANGE}
                        errorOpts={{ _name: 'Custom title' }}
                        dispatch={dispatch}
                    />
                </div>
            )
        }
    ];

    const previewDescription = description || 'Description here is taken from the page description above, and if there is none, it will automatically be generated based on content.';

    return (
        <div className={styles.tab}>
            <div style={{ paddingTop: '10px' }}>
                <Msg k="pageInfo.seo.header1" className={styles.headerText}>
                    Website Builder automatically informs major search engines (Google and Bing etc.) about your site.
                </Msg>
                <VerticalSpacer y={2} />
                <Msg k="pageInfo.seo.header2" className={styles.headerText}>
                    Add text and content to your pages that use the words you want your website to be found on.
                </Msg>
            </div>
            <DialogCheckBox
                label="msg: pageInfo.seo.excludeFromIndexing {Exclude this page in search results}"
                isChecked={excludeFromIndexing || !isSeoEnabledForSiteSettings}
                onClick={() => dispatch({ type: Actions.PAGE_INFO_EXCLUDE_FROM_INDEXING_TOGGLE })}
                disabled={!isSeoEnabledForSiteSettings}
                showWhiteCheckMark={!isSeoEnabledForSiteSettings}
            />
            <div className={styles.excludeCheckBoxMsgContainer}>
                {
                    isSeoEnabledForSiteSettings ?
                        <div className={styles.excludeCheckBoxDefaultMsg}>
                            <Msg k="pageInfo.seo.excludeCheckBoxMsg">
                                Applies to all subpages of this page
                            </Msg>
                        </div> :
                        <div className={styles.seoDisableMsgContainer}>
                            <Msg k="pageInfo.seo.seoDisabledMsgPart1">
                                Your whole website is currently excluded from search engines.
                            </Msg>
                            <Msg
                                onClick={settingsClickHandler}
                                className={styles.seoDisableMsgPart2}
                                k="pageInfo.seo.seoDisabledMsgPart2"
                            >
                                Go to the global SEO settings to change this.
                            </Msg>
                        </div>
                }
            </div>
            <div>
                <div style={{ display: 'flex' }}>
                    <Msg k="pageInfo.seo.pageTitleInBrowserTab" className={styles.pageNameInBrowserTab}>
                        Page title in search results & browser tabs
                    </Msg>
                    <QuestionTip
                        position={TooltipPosition.TOP}
                        className={styles.pageTitleTipIcn}
                        theme={{
                            container: styles.pageDescriptionTipContainer,
                            tipInfo: styles.pageDescriptionTipInfo
                        }}
                    >
                        {PAGE_TITLE_TIP_MSG}
                    </QuestionTip>
                </div>

                <div className={styles.displayFlex}>
                    <div className={styles.bodySeoLeftHalf}>
                        <RadioButtonGroup
                            optionsConfig={pageNameInBrowserTabOptions}
                            value={pageNameOptionInBrowserTab}
                            radioButtonClassName={styles.seoPageTitleInTabOptionsSelector}
                            checkedRadioButtonContainerClassName={styles.seoPageTitleInTabOptionsCheckedSelector}
                            onChange={(changedValue) => {
                                dispatch({ type: Actions.PAGE_INFO_SEO_PAGE_NAME_IN_BROWSER_TAB_TOGGLE, payload: changedValue });
                            }}
                        />

                        <VerticalSpacer y={25} />

                        <div className={styles.pageDescriptionContainer}>
                            <LabeledTextArea
                                label={renderPageDescriptionLabel()}
                                isLabelValue
                                placeholder="msg: pageInfo.seo.pageDescription.placeholder {Add a description summarising what your site is about.}"
                                value={description || ''}
                                onChangeAction={Actions.PAGE_INFO_DESCRIPTION_CHANGE}
                                dispatch={dispatch}
                                className={styles.seoDescription}
                            />
                            {/*To be removed after implementing the tooltip. Added this just for translation*/}
                            <div style={{ display: 'none' }}>
                                <Msg k="pageInfo.seo.pageDescription.tipMsg">
                                    Tell people more about what your site has to offer and encourage them to view this page. Your description should be between 50-300 characters (including spaces).
                                </Msg>
                            </div>
                        </div>

                    </div>

                    <div className={styles.bodySeoRightHalf}>
                        <div className={styles.seoPreviewContainer}>
                            <Label className={styles.previewGoogle}>{"msg: pageInfo.seo.previewSearchResults {Preview in search results}"}</Label>
                            <div className={styles.seoPreviewDetailsContainer}>
                                <div className={styles.domainUrlContainer}>
                                    <span className={styles.domainUrl}>{urlParams.cleanedDisplayUrl}</span>
                                </div>
                                <div>
                                    <span className={styles.websiteTitle}>{displayTitle}</span>
                                </div>
                                <div>
                                    <span className={styles.seoDescriptionContainer}>
                                        {previewDescription.length > 300 ? ellipsis(previewDescription, 300) : previewDescription}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
