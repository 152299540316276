// @ts-nocheck
import React from 'react';

type Rotations = [number, number, number, number];
type Result = [AnyReactElement, AnyReactElement, AnyReactElement, AnyReactElement];

// $FlowFixMe: flow doesn't understand tupple can be mapped ...
export default (Icon: AnyReactComopnent, rotations: Rotations): Result => rotations.map(r => (
    <Icon style={{ transform: `rotate(${r}deg)` }} />
));
