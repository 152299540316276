import { RECOVER_AFTER_EXCEPTION, RENDER_EXCEPTION } from "./actionTypes";
import * as exceptionTypes from "./exceptionTypes";
import * as OldAppActionTypes from "../modules/actionTypes";
import { SYNC_SCROLL_TO_DOM } from "../../components/Workspace/epics/scroll/selectorActionTypes";
import { registerException } from "../../debug/index";

export default (store: Store) => {
    let
        stateBeforePossibleException,
        prevAction;

    let recoveryTimeouts: ReturnType<typeof setTimeout>[] = [];
    return (next: Dispatch) => (action: Action): any => {
        if (action.type === RENDER_EXCEPTION) {
            const appStateWithRenderException = store.getState();
            registerException({
                type: "RENDER_EXCEPTION",
                appStateWithRenderException,
                exception: action.payload.error,
                stateBeforeException: stateBeforePossibleException,
                action: prevAction
            });

            const timeoutId: ReturnType<typeof setTimeout> = setTimeout(() => {
                // multiple UI components may throw exception during single render, we will request to recover only once
                recoveryTimeouts.forEach(id => clearTimeout(id));
                recoveryTimeouts = [];

                const { recoveryData } = store.getState();

                if (!recoveryData.rerenderBrokenUI) {
                    store.dispatch({
                        type: RECOVER_AFTER_EXCEPTION,
                        payload: { type: exceptionTypes.RENDER }
                    });
                }
            });
            recoveryTimeouts.push(timeoutId);
        }

        if (action.type === RECOVER_AFTER_EXCEPTION) {
            const nextResult = next(action);

            // In base browser dimensions or workspace scroll was changed, we will sync them just after recover
            store.dispatch({
                type: OldAppActionTypes.WINDOW_RESIZED,
                payload: {
                    width: window.innerWidth,
                    height: window.innerHeight
                }
            });
            store.dispatch({
                type: SYNC_SCROLL_TO_DOM,
                payload: { type: exceptionTypes.RENDER }
            });

            return nextResult;
        }

        prevAction = action;
        stateBeforePossibleException = store.getState();
        return next(action);
    };
};
