import * as path from '../../../mappers/path';
import * as actionTypes from './actionTypes';
import * as euh from './epicUpdateHelpers';
import { TextTransform } from './constants';
import { SAVE_GOOGLE_FONT } from '../../Fonts/actionTypes';

/* How menu global style data object get updated based on old ui */
/* *******************************************************************************************
    Common:
        font family, font size, bold, italic, text align, text transform,  border:
            item, selected, expandable, expanded:
                inactive, visited, hover, press, disabled
        spacing:
            item, selected:
                inactive, visited, hover, press, disabled

    Normal:
        Underline:
            item:
                inactive, visited, hover, press, disabled
        Text color:
        Text shadow:
        Background: (visible on item, selected, expandable, expanded)
        Corner: (visible on item, selected, expandable, expanded))
            item:
                inactive, visited, press, disabled

    Mouse over:
        Underline:
        Text color:
        Text shadow:
        Background: (visible on item, selected, expandable, expanded)
        Corner:(visible on item, selected, expandable, expanded)
            item:
                hover
            selected:
                hover

    Selected
        Underline:
            selected:
                inactive, visited, hover, press, disabled
        Text color:
        Text shadow:
        Background:
        Corner:
            selected:
                inactive, visited, press, disabled

    Expandable:
        Underline:
            expandable:
                inactive, visited, hover, press, disabled
        Text color:
        Text shadow:
        Background:
            expandable:
                inactive, visited, press, disabled
        Spacing:
            expandable:
            expanded:
                inactive, visited, hover, press, disabled

    Expanded:
        Underline:
        Background:
            expanded:
                inactive, visited, hover, press, disabled
        Text color:
            expandable:
               hover
            expanded:
                inactive, visited, press, disabled
        Text shadow:
            expandable:
                hover
            expanded:
                inactive, visited, hover, press, disabled

******************************************************************************************* */

const
    paths = [path.inactive, path.visited, path.press, path.disabled],
    pathsWithHover = [...paths, path.hover],
    itemPath = { [path.item]: paths },
    itemPathWithHover = { [path.item]: pathsWithHover },
    itemSelectedPathsWithHover = { [path.item]: [path.hover], [path.selected]: [path.hover] },
    selectedPathWithHover = { [path.selected]: pathsWithHover },
    selectedPath = { [path.selected]: paths },
    expandablePathWithHover = { [path.expandable]: pathsWithHover },
    expandablePath = { [path.expandable]: paths },
    expandedPathWithHover = { [path.expanded]: pathsWithHover },
    expandedPath = { [path.expanded]: paths },
    expandableHoverExpandedPaths = { ...expandedPath, [path.expandable]: [path.hover] },
    expandableHoverExpandedPathsWithHover = { ...expandedPathWithHover, [path.expandable]: [path.hover] },
    allPathsWithHover = {
        ...itemPathWithHover,
        ...selectedPathWithHover,
        ...expandablePathWithHover,
        ...expandedPathWithHover
    };

const
    epicUpdatersForCommonStyle = [
        euh.googleFontEpicUpdater(
            SAVE_GOOGLE_FONT,
            allPathsWithHover
        ),
        euh.fontFamilyEpicUpdater(
            actionTypes.GS_MENU_FONT_FAMILY_CHANGED,
            allPathsWithHover
        ),
        euh.fontSizeEpicUpdater(
            actionTypes.GS_MENU_FONT_SIZE_CHANGED,
            allPathsWithHover
        ),
        euh.toggleBoldEpicUpdater(
            actionTypes.GS_MENU_TOGGLE_BOLD,
            allPathsWithHover
        ),
        euh.toggleItalicEpicUpdater(
            actionTypes.GS_MENU_TOGGLE_ITALIC,
            allPathsWithHover
        ),
        euh.textAlignmentEpicUpdater(
            actionTypes.GS_MENU_TEXT_ALIGN_CHANGED,
            allPathsWithHover
        ),
        euh.textTransformEpicUpdater(
            actionTypes.GS_MENU_TEXT_TRANSFORM_UPPERCASE,
            allPathsWithHover,
            TextTransform.uppercase
        ),
        euh.textTransformEpicUpdater(
            actionTypes.GS_MENU_TEXT_TRANSFORM_LOWERCASE,
            allPathsWithHover,
            TextTransform.lowercase
        ),
        euh.borderStyleEpicUpdater(
            actionTypes.GS_MENU_BORDER_STYLE_CHANGED,
            allPathsWithHover
        ),
        euh.borderColorEpicUpdater(
            actionTypes.GS_MENU_BORDER_COLOR_CHANGED,
            allPathsWithHover
        ),
        euh.borderColorOpacityEpicUpdater(
            actionTypes.GS_MENU_BORDER_COLOR_OPACITY_CHANGED,
            allPathsWithHover
        ),
        euh.borderColorRemoveEpicUpdater(
            actionTypes.GS_MENU_BORDER_COLOR_REMOVED,
            allPathsWithHover
        ),
        euh.borderWidthEpicUpdater(
            actionTypes.GS_MENU_BORDER_WIDTH_CHANGED,
            allPathsWithHover
        ),
        euh.spacingEpicUpdater(
            actionTypes.GS_MENU_CELL_SPACING_CHANGED,
            {
                [path.item]: pathsWithHover,
                [path.selected]: pathsWithHover
            }
        )
    ],
    epicUpdatersForNormalStyle = [
        euh.toggleUnderlineEpicUpdater(
            actionTypes.GS_MENU_NORMAL_TOGGLE_UNDERLINE,
            itemPath
        ),
        euh.textColorEpicUpdater(
            actionTypes.GS_MENU_NORMAL_TEXT_COLOR_CHANGED,
            itemPath
        ),
        euh.textColorOpacityEpicUpdater(
            actionTypes.GS_MENU_NORMAL_TEXT_COLOR_OPACITY_CHANGED,
            itemPath
        ),
        euh.textColorRemoveEpicUpdater(
            actionTypes.GS_MENU_NORMAL_TEXT_COLOR_REMOVED,
            itemPath
        ),
        euh.textShadowColorEpicUpdater(
            actionTypes.GS_MENU_NORMAL_TEXT_SHADOW_COLOR_CHANGED,
            itemPath
        ),
        euh.textShadowColorRemoveEpicUpdater(
            actionTypes.GS_MENU_NORMAL_TEXT_SHADOW_COLOR_REMOVED,
            itemPath
        ),
        euh.textShadowBlurRadiusEpicUpdater(
            actionTypes.GS_MENU_NORMAL_TEXT_SHADOW_BLUR_RADIUS_CHANGED,
            itemPath
        ),
        euh.textShadowVerticalOffsetEpicUpdater(
            actionTypes.GS_MENU_NORMAL_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED,
            itemPath
        ),
        euh.textShadowHorizontalOffsetEpicUpdater(
            actionTypes.GS_MENU_NORMAL_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED,
            itemPath
        ),
        euh.bgColorEpicUpdater(
            actionTypes.GS_MENU_NORMAL_BG_COLOR_CHANGED,
            itemPath
        ),
        euh.bgColorOpacityEpicUpdater(
            actionTypes.GS_MENU_NORMAL_BG_COLOR_OPACITY_CHANGED,
            itemPath
        ),
        euh.bgColorRemoveEpicUpdater(
            actionTypes.GS_MENU_NORMAL_BG_COLOR_REMOVED,
            itemPath
        ),
        euh.bgGradientColorEpicUpdater(
            actionTypes.GS_MENU_NORMAL_BG_GRADIENT_CHANGED,
            itemPath
        ),
        euh.bgGradientColorOpacityEpicUpdater(
            actionTypes.GS_MENU_NORMAL_BG_GRADIENT_OPACITY_CHANGED,
            itemPath
        ),
        euh.bgGradientRemoveEpicUpdater(
            actionTypes.GS_MENU_NORMAL_BG_GRADIENT_CANCELLED,
            itemPath
        ),
        euh.bgGradientDirectionEpicUpdater(
            actionTypes.GS_MENU_NORMAL_BG_GRADIENT_DIRECTION_CHANGED,
            itemPath
        ),
        euh.bgGradientFadePointEpicUpdater(
            actionTypes.GS_MENU_NORMAL_BG_GRADIENT_FADEPOINT_CHANGED,
            itemPath
        ),
        euh.bgAssetEpicUpdater(
            actionTypes.GS_MENU_NORMAL_BG_ASSET_CHANGED,
            itemPath
        ),
        euh.bgAssetRemoveEpicUpdater(
            actionTypes.GS_MENU_NORMAL_BG_ASSET_REMOVED,
            itemPath
        ),
        euh.bgAssetPositionEpicUpdater(
            actionTypes.GS_MENU_NORMAL_BG_ASSET_POSITION_CHANGED,
            itemPath
        ),
        euh.bgAssetRepeatEpicUpdater(
            actionTypes.GS_MENU_NORMAL_BG_ASSET_REPEAT_CHANGED,
            itemPath
        ),
        euh.bgAssetSizeEpicUpdater(
            actionTypes.GS_MENU_NORMAL_BG_ASSET_SIZE_CHANGED,
            itemPath
        ),
        euh.borderRadiusEpicUpdater(
            actionTypes.GS_MENU_NORMAL_BORDER_RADUIS_CHANGED,
            itemPath
        )
    ],
    epicUpdatersForMouseOverStyle = [
        euh.toggleUnderlineEpicUpdater(
            actionTypes.GS_MENU_MOUSE_OVER_TOGGLE_UNDERLINE,
            itemSelectedPathsWithHover
        ),
        euh.textColorEpicUpdater(
            actionTypes.GS_MENU_MOUSE_OVER_TEXT_COLOR_CHANGED,
            itemSelectedPathsWithHover
        ),
        euh.textColorOpacityEpicUpdater(
            actionTypes.GS_MENU_MOUSE_OVER_TEXT_COLOR_OPACITY_CHANGED,
            itemSelectedPathsWithHover
        ),
        euh.textColorRemoveEpicUpdater(
            actionTypes.GS_MENU_MOUSE_OVER_TEXT_COLOR_REMOVED,
            itemSelectedPathsWithHover
        ),
        euh.textShadowColorEpicUpdater(
            actionTypes.GS_MENU_MOUSE_OVER_TEXT_SHADOW_COLOR_CHANGED,
            itemSelectedPathsWithHover
        ),
        euh.textShadowColorRemoveEpicUpdater(
            actionTypes.GS_MENU_MOUSE_OVER_TEXT_SHADOW_COLOR_REMOVED,
            itemSelectedPathsWithHover
        ),
        euh.textShadowBlurRadiusEpicUpdater(
            actionTypes.GS_MENU_MOUSE_OVER_TEXT_SHADOW_BLUR_RADIUS_CHANGED,
            itemSelectedPathsWithHover
        ),
        euh.textShadowVerticalOffsetEpicUpdater(
            actionTypes.GS_MENU_MOUSE_OVER_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED,
            itemSelectedPathsWithHover
        ),
        euh.textShadowHorizontalOffsetEpicUpdater(
            actionTypes.GS_MENU_MOUSE_OVER_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED,
            itemSelectedPathsWithHover
        ),
        euh.bgColorEpicUpdater(
            actionTypes.GS_MENU_MOUSE_OVER_BG_COLOR_CHANGED,
            itemSelectedPathsWithHover
        ),
        euh.bgColorOpacityEpicUpdater(
            actionTypes.GS_MENU_MOUSE_OVER_BG_COLOR_OPACITY_CHANGED,
            itemSelectedPathsWithHover
        ),
        euh.bgColorRemoveEpicUpdater(
            actionTypes.GS_MENU_MOUSE_OVER_BG_COLOR_REMOVED,
            itemSelectedPathsWithHover
        ),
        euh.bgGradientColorEpicUpdater(
            actionTypes.GS_MENU_MOUSE_OVER_BG_GRADIENT_CHANGED,
            itemSelectedPathsWithHover
        ),
        euh.bgGradientColorOpacityEpicUpdater(
            actionTypes.GS_MENU_MOUSE_OVER_BG_GRADIENT_OPACITY_CHANGED,
            itemSelectedPathsWithHover
        ),
        euh.bgGradientRemoveEpicUpdater(
            actionTypes.GS_MENU_MOUSE_OVER_BG_GRADIENT_CANCELLED,
            itemSelectedPathsWithHover
        ),
        euh.bgGradientDirectionEpicUpdater(
            actionTypes.GS_MENU_MOUSE_OVER_BG_GRADIENT_DIRECTION_CHANGED,
            itemSelectedPathsWithHover
        ),
        euh.bgGradientFadePointEpicUpdater(
            actionTypes.GS_MENU_MOUSE_OVER_BG_GRADIENT_FADEPOINT_CHANGED,
            itemSelectedPathsWithHover
        ),
        euh.bgAssetEpicUpdater(
            actionTypes.GS_MENU_MOUSE_OVER_BG_ASSET_CHANGED,
            itemSelectedPathsWithHover
        ),
        euh.bgAssetRemoveEpicUpdater(
            actionTypes.GS_MENU_MOUSE_OVER_BG_ASSET_REMOVED,
            itemSelectedPathsWithHover
        ),
        euh.bgAssetPositionEpicUpdater(
            actionTypes.GS_MENU_MOUSE_OVER_BG_ASSET_POSITION_CHANGED,
            itemSelectedPathsWithHover
        ),
        euh.bgAssetRepeatEpicUpdater(
            actionTypes.GS_MENU_MOUSE_OVER_BG_ASSET_REPEAT_CHANGED,
            itemSelectedPathsWithHover
        ),
        euh.bgAssetSizeEpicUpdater(
            actionTypes.GS_MENU_MOUSE_OVER_BG_ASSET_SIZE_CHANGED,
            itemSelectedPathsWithHover
        ),
        euh.borderRadiusEpicUpdater(
            actionTypes.GS_MENU_MOUSE_OVER_BORDER_RADUIS_CHANGED,
            itemSelectedPathsWithHover
        )
    ],
    epicUpdatersForSelectedStyle = [
        euh.toggleUnderlineEpicUpdater(
            actionTypes.GS_MENU_SELECTED_TOGGLE_UNDERLINE,
            selectedPathWithHover
        ),
        euh.textColorEpicUpdater(
            actionTypes.GS_MENU_SELECTED_TEXT_COLOR_CHANGED,
            selectedPath
        ),
        euh.textColorOpacityEpicUpdater(
            actionTypes.GS_MENU_SELECTED_TEXT_COLOR_OPACITY_CHANGED,
            selectedPath
        ),
        euh.textColorRemoveEpicUpdater(
            actionTypes.GS_MENU_SELECTED_TEXT_COLOR_REMOVED,
            selectedPath
        ),
        euh.textShadowColorEpicUpdater(
            actionTypes.GS_MENU_SELECTED_TEXT_SHADOW_COLOR_CHANGED,
            selectedPath
        ),
        euh.textShadowColorRemoveEpicUpdater(
            actionTypes.GS_MENU_SELECTED_TEXT_SHADOW_COLOR_REMOVED,
            selectedPath
        ),
        euh.textShadowBlurRadiusEpicUpdater(
            actionTypes.GS_MENU_SELECTED_TEXT_SHADOW_BLUR_RADIUS_CHANGED,
            selectedPath
        ),
        euh.textShadowVerticalOffsetEpicUpdater(
            actionTypes.GS_MENU_SELECTED_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED,
            selectedPath
        ),
        euh.textShadowHorizontalOffsetEpicUpdater(
            actionTypes.GS_MENU_SELECTED_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED,
            selectedPath
        ),
        euh.bgColorEpicUpdater(
            actionTypes.GS_MENU_SELECTED_BG_COLOR_CHANGED,
            selectedPath
        ),
        euh.bgColorOpacityEpicUpdater(
            actionTypes.GS_MENU_SELECTED_BG_COLOR_OPACITY_CHANGED,
            selectedPath
        ),
        euh.bgColorRemoveEpicUpdater(
            actionTypes.GS_MENU_SELECTED_BG_COLOR_REMOVED,
            selectedPath
        ),
        euh.bgGradientColorEpicUpdater(
            actionTypes.GS_MENU_SELECTED_BG_GRADIENT_CHANGED,
            selectedPath
        ),
        euh.bgGradientColorOpacityEpicUpdater(
            actionTypes.GS_MENU_SELECTED_BG_GRADIENT_OPACITY_CHANGED,
            selectedPath
        ),
        euh.bgGradientRemoveEpicUpdater(
            actionTypes.GS_MENU_SELECTED_BG_GRADIENT_CANCELLED,
            selectedPath
        ),
        euh.bgGradientDirectionEpicUpdater(
            actionTypes.GS_MENU_SELECTED_BG_GRADIENT_DIRECTION_CHANGED,
            selectedPath
        ),
        euh.bgGradientFadePointEpicUpdater(
            actionTypes.GS_MENU_SELECTED_BG_GRADIENT_FADEPOINT_CHANGED,
            selectedPath
        ),
        euh.bgAssetEpicUpdater(
            actionTypes.GS_MENU_SELECTED_BG_ASSET_CHANGED,
            selectedPath
        ),
        euh.bgAssetRemoveEpicUpdater(
            actionTypes.GS_MENU_SELECTED_BG_ASSET_REMOVED,
            selectedPath
        ),
        euh.bgAssetPositionEpicUpdater(
            actionTypes.GS_MENU_SELECTED_BG_ASSET_POSITION_CHANGED,
            selectedPath
        ),
        euh.bgAssetRepeatEpicUpdater(
            actionTypes.GS_MENU_SELECTED_BG_ASSET_REPEAT_CHANGED,
            selectedPath
        ),
        euh.bgAssetSizeEpicUpdater(
            actionTypes.GS_MENU_SELECTED_BG_ASSET_SIZE_CHANGED,
            selectedPath
        ),
        euh.borderRadiusEpicUpdater(
            actionTypes.GS_MENU_SELECTED_BORDER_RADUIS_CHANGED,
            selectedPath
        )
    ],
    epicUpdatersForExpandableStyle = [
        euh.toggleUnderlineEpicUpdater(
            actionTypes.GS_MENU_EXPANDABLE_TOGGLE_UNDERLINE,
            expandablePathWithHover
        ),
        euh.textColorEpicUpdater(
            actionTypes.GS_MENU_EXPANDABLE_TEXT_COLOR_CHANGED,
            expandablePath
        ),
        euh.textColorOpacityEpicUpdater(
            actionTypes.GS_MENU_EXPANDABLE_TEXT_COLOR_OPACITY_CHANGED,
            expandablePath
        ),
        euh.textColorRemoveEpicUpdater(
            actionTypes.GS_MENU_EXPANDABLE_TEXT_COLOR_REMOVED,
            expandablePath
        ),
        euh.textShadowColorEpicUpdater(
            actionTypes.GS_MENU_EXPANDABLE_TEXT_SHADOW_COLOR_CHANGED,
            expandablePath
        ),
        euh.textShadowColorRemoveEpicUpdater(
            actionTypes.GS_MENU_EXPANDABLE_TEXT_SHADOW_COLOR_REMOVED,
            expandablePath
        ),
        euh.textShadowBlurRadiusEpicUpdater(
            actionTypes.GS_MENU_EXPANDABLE_TEXT_SHADOW_BLUR_RADIUS_CHANGED,
            expandablePath
        ),
        euh.textShadowVerticalOffsetEpicUpdater(
            actionTypes.GS_MENU_EXPANDABLE_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED,
            expandablePath
        ),
        euh.textShadowHorizontalOffsetEpicUpdater(
            actionTypes.GS_MENU_EXPANDABLE_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED,
            expandablePath
        ),
        euh.bgColorEpicUpdater(
            actionTypes.GS_MENU_EXPANDABLE_BG_COLOR_CHANGED,
            expandablePath
        ),
        euh.bgColorOpacityEpicUpdater(
            actionTypes.GS_MENU_EXPANDABLE_BG_COLOR_OPACITY_CHANGED,
            expandablePath
        ),
        euh.bgColorRemoveEpicUpdater(
            actionTypes.GS_MENU_EXPANDABLE_BG_COLOR_REMOVED,
            expandablePath
        ),
        euh.bgGradientColorEpicUpdater(
            actionTypes.GS_MENU_EXPANDABLE_BG_GRADIENT_CHANGED,
            expandablePath
        ),
        euh.bgGradientColorOpacityEpicUpdater(
            actionTypes.GS_MENU_EXPANDABLE_BG_GRADIENT_OPACITY_CHANGED,
            expandablePath
        ),
        euh.bgGradientRemoveEpicUpdater(
            actionTypes.GS_MENU_EXPANDABLE_BG_GRADIENT_CANCELLED,
            expandablePath
        ),
        euh.bgGradientDirectionEpicUpdater(
            actionTypes.GS_MENU_EXPANDABLE_BG_GRADIENT_DIRECTION_CHANGED,
            expandablePath
        ),
        euh.bgGradientFadePointEpicUpdater(
            actionTypes.GS_MENU_EXPANDABLE_BG_GRADIENT_FADEPOINT_CHANGED,
            expandablePath
        ),
        euh.bgAssetEpicUpdater(
            actionTypes.GS_MENU_EXPANDABLE_BG_ASSET_CHANGED,
            expandablePath
        ),
        euh.bgAssetRemoveEpicUpdater(
            actionTypes.GS_MENU_EXPANDABLE_BG_ASSET_REMOVED,
            expandablePath
        ),
        euh.bgAssetPositionEpicUpdater(
            actionTypes.GS_MENU_EXPANDABLE_BG_ASSET_POSITION_CHANGED,
            expandablePath
        ),
        euh.bgAssetRepeatEpicUpdater(
            actionTypes.GS_MENU_EXPANDABLE_BG_ASSET_REPEAT_CHANGED,
            expandablePath
        ),
        euh.bgAssetSizeEpicUpdater(
            actionTypes.GS_MENU_EXPANDABLE_BG_ASSET_SIZE_CHANGED,
            expandablePath
        ),
        euh.horizontalSpacingEpicUpdater(
            actionTypes.GS_MENU_EXPANDABLE_PADDING_CHANGED,
            { ...expandablePathWithHover, ...expandedPathWithHover }
        ),
        euh.verticalSpacingEpicUpdater(
            actionTypes.GS_MENU_CELL_SPACING_CHANGED,
            { ...expandablePathWithHover, ...expandedPathWithHover }
        ),
        euh.showDropDownPressedUpdater
    ],
    epicUpdatersForExpandedStyle = [
        euh.toggleUnderlineEpicUpdater(
            actionTypes.GS_MENU_EXPANDED_TOGGLE_UNDERLINE,
            expandedPathWithHover
        ),
        euh.textColorEpicUpdater(
            actionTypes.GS_MENU_EXPANDED_TEXT_COLOR_CHANGED,
            expandedPath
        ),
        euh.textColorOpacityEpicUpdater(
            actionTypes.GS_MENU_EXPANDED_TEXT_COLOR_OPACITY_CHANGED,
            expandableHoverExpandedPaths
        ),
        euh.textColorRemoveEpicUpdater(
            actionTypes.GS_MENU_EXPANDED_TEXT_COLOR_REMOVED,
            expandableHoverExpandedPaths
        ),
        euh.textShadowColorEpicUpdater(
            actionTypes.GS_MENU_EXPANDED_TEXT_SHADOW_COLOR_CHANGED,
            expandableHoverExpandedPathsWithHover
        ),
        euh.textShadowColorRemoveEpicUpdater(
            actionTypes.GS_MENU_EXPANDED_TEXT_SHADOW_COLOR_REMOVED,
            expandableHoverExpandedPathsWithHover
        ),
        euh.textShadowBlurRadiusEpicUpdater(
            actionTypes.GS_MENU_EXPANDED_TEXT_SHADOW_BLUR_RADIUS_CHANGED,
            expandableHoverExpandedPathsWithHover
        ),
        euh.textShadowVerticalOffsetEpicUpdater(
            actionTypes.GS_MENU_EXPANDED_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED,
            expandableHoverExpandedPathsWithHover
        ),
        euh.textShadowHorizontalOffsetEpicUpdater(
            actionTypes.GS_MENU_EXPANDED_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED,
            expandableHoverExpandedPathsWithHover
        ),
        euh.bgColorEpicUpdater(
            actionTypes.GS_MENU_EXPANDED_BG_COLOR_CHANGED,
            expandedPathWithHover
        ),
        euh.bgColorOpacityEpicUpdater(
            actionTypes.GS_MENU_EXPANDED_BG_COLOR_OPACITY_CHANGED,
            expandedPathWithHover
        ),
        euh.bgColorRemoveEpicUpdater(
            actionTypes.GS_MENU_EXPANDED_BG_COLOR_REMOVED,
            expandedPathWithHover
        ),
        euh.bgGradientColorEpicUpdater(
            actionTypes.GS_MENU_EXPANDED_BG_GRADIENT_CHANGED,
            expandedPathWithHover
        ),
        euh.bgGradientColorOpacityEpicUpdater(
            actionTypes.GS_MENU_EXPANDED_BG_GRADIENT_OPACITY_CHANGED,
            expandedPathWithHover
        ),
        euh.bgGradientRemoveEpicUpdater(
            actionTypes.GS_MENU_EXPANDED_BG_GRADIENT_CANCELLED,
            expandedPathWithHover
        ),
        euh.bgGradientDirectionEpicUpdater(
            actionTypes.GS_MENU_EXPANDED_BG_GRADIENT_DIRECTION_CHANGED,
            expandedPathWithHover
        ),
        euh.bgGradientFadePointEpicUpdater(
            actionTypes.GS_MENU_EXPANDED_BG_GRADIENT_FADEPOINT_CHANGED,
            expandedPathWithHover
        ),
        euh.bgAssetEpicUpdater(
            actionTypes.GS_MENU_EXPANDED_BG_ASSET_CHANGED,
            expandedPathWithHover
        ),
        euh.bgAssetRemoveEpicUpdater(
            actionTypes.GS_MENU_EXPANDED_BG_ASSET_REMOVED,
            expandedPathWithHover
        ),
        euh.bgAssetPositionEpicUpdater(
            actionTypes.GS_MENU_EXPANDED_BG_ASSET_POSITION_CHANGED,
            expandedPathWithHover
        ),
        euh.bgAssetRepeatEpicUpdater(
            actionTypes.GS_MENU_EXPANDED_BG_ASSET_REPEAT_CHANGED,
            expandedPathWithHover
        ),
        euh.bgAssetSizeEpicUpdater(
            actionTypes.GS_MENU_EXPANDED_BG_ASSET_SIZE_CHANGED,
            expandedPathWithHover
        )
    ],
    epicUpdatersForDivider = [
        euh.menuDividerBorderRadiusEpicUpdater(actionTypes.GS_MENU_DIVIDER_BORDER_RADUIS_CHANGED),
        euh.menuDividerBorderStyleEpicUpdater(actionTypes.GS_MENU_DIVIDER_BORDER_STYLE_CHANGED),
        euh.menuDividerBorderColorEpicUpdater(actionTypes.GS_MENU_DIVIDER_BORDER_COLOR_CHANGED),
        euh.menuDividerBorderColorRemoveEpicUpdater(actionTypes.GS_MENU_DIVIDER_BORDER_COLOR_REMOVED),
        euh.menuDividerBorderColorOpacityEpicUpdater(actionTypes.GS_MENU_DIVIDER_BORDER_COLOR_OPACITY_CHANGED),
        euh.menuDividerBorderWidthEpicUpdater(actionTypes.GS_MENU_DIVIDER_BORDER_WIDTH_CHANGED),
        euh.menuDividerSpacingEpicUpdater(actionTypes.GS_MENU_DIVIDER_PADDING_CHANGED),
        euh.menuDividerBgColorEpicUpdater(actionTypes.GS_MENU_DIVIDER_BG_COLOR_CHANGED),
        euh.menuDividerBgColorRemoveEpicUpdater(actionTypes.GS_MENU_DIVIDER_BG_COLOR_REMOVED),
        euh.menuDividerBgColorOpacityEpicUpdater(actionTypes.GS_MENU_DIVIDER_BG_COLOR_OPACITY_CHANGED),
        euh.menuDividerBgGradientColorEpicUpdater(actionTypes.GS_MENU_DIVIDER_BG_GRADIENT_CHANGED),
        euh.menuDividerGradientRemoveEpicUpdater(actionTypes.GS_MENU_DIVIDER_BG_GRADIENT_CANCELLED),
        euh.menuDividerBgGradientDirectionEpicUpdater(actionTypes.GS_MENU_DIVIDER_BG_GRADIENT_DIRECTION_CHANGED),
        euh.menuDividerBgGradientFadePointEpicUpdater(actionTypes.GS_MENU_DIVIDER_BG_GRADIENT_FADEPOINT_CHANGED),
        euh.menuDividerBgAssetEpicUpdater(actionTypes.GS_MENU_DIVIDER_BG_ASSET_CHANGED),
        euh.menuDividerBgAssetRemoveEpicUpdater(actionTypes.GS_MENU_DIVIDER_BG_ASSET_REMOVED),
        euh.menuDividerBgAssetRepeatEpicUpdater(actionTypes.GS_MENU_DIVIDER_BG_ASSET_REPEAT_CHANGED),
        euh.menuDividerBgAssetPositionEpicUpdater(actionTypes.GS_MENU_DIVIDER_BG_ASSET_POSITION_CHANGED),
        euh.menuDividerBgAssetSizeEpicUpdater(actionTypes.GS_MENU_DIVIDER_BG_ASSET_SIZE_CHANGED)
    ],
    epicUpdatersForMenuLevelContainer = [
        euh.borderRadiusEpicUpdater(actionTypes.GS_MENU_LEVEL_CONTAINER_BORDER_RADUIS_CHANGED),
        euh.borderStyleEpicUpdater(actionTypes.GS_MENU_LEVEL_CONTAINER_BORDER_STYLE_CHANGED),
        euh.borderColorEpicUpdater(actionTypes.GS_MENU_LEVEL_CONTAINER_BORDER_COLOR_CHANGED),
        euh.borderColorRemoveEpicUpdater(actionTypes.GS_MENU_LEVEL_CONTAINER_BORDER_COLOR_REMOVED),
        euh.borderColorOpacityEpicUpdater(actionTypes.GS_MENU_LEVEL_CONTAINER_BORDER_COLOR_OPACITY_CHANGED),
        euh.borderWidthEpicUpdater(actionTypes.GS_MENU_LEVEL_CONTAINER_BORDER_WIDTH_CHANGED),
        euh.spacingEpicUpdater(actionTypes.GS_MENU_LEVEL_CONTAINER_PADDING_CHANGED),
        euh.bgColorEpicUpdater(actionTypes.GS_MENU_LEVEL_CONTAINER_BG_COLOR_CHANGED),
        euh.bgColorRemoveEpicUpdater(actionTypes.GS_MENU_LEVEL_CONTAINER_BG_COLOR_REMOVED),
        euh.bgColorOpacityEpicUpdater(actionTypes.GS_MENU_LEVEL_CONTAINER_BG_COLOR_OPACITY_CHANGED),
        euh.bgGradientColorEpicUpdater(actionTypes.GS_MENU_LEVEL_CONTAINER_BG_GRADIENT_CHANGED),
        euh.bgGradientRemoveEpicUpdater(actionTypes.GS_MENU_LEVEL_CONTAINER_BG_GRADIENT_CANCELLED),
        euh.bgGradientDirectionEpicUpdater(actionTypes.GS_MENU_LEVEL_CONTAINER_BG_GRADIENT_DIRECTION_CHANGED),
        euh.bgGradientFadePointEpicUpdater(actionTypes.GS_MENU_LEVEL_CONTAINER_BG_GRADIENT_FADEPOINT_CHANGED),
        euh.bgAssetEpicUpdater(actionTypes.GS_MENU_LEVEL_CONTAINER_BG_ASSET_CHANGED),
        euh.bgAssetRemoveEpicUpdater(actionTypes.GS_MENU_LEVEL_CONTAINER_BG_ASSET_REMOVED),
        euh.bgAssetRepeatEpicUpdater(actionTypes.GS_MENU_LEVEL_CONTAINER_BG_ASSET_REPEAT_CHANGED),
        euh.bgAssetPositionEpicUpdater(actionTypes.GS_MENU_LEVEL_CONTAINER_BG_ASSET_POSITION_CHANGED),
        euh.bgAssetSizeEpicUpdater(actionTypes.GS_MENU_LEVEL_CONTAINER_BG_ASSET_SIZE_CHANGED)
    ],
    epicUpdatersForMultipleLevel = [
        euh.treeMenuTextIndentEpicUpdater(actionTypes.GS_MENU_TREE_MENU_TEXT_INDENT_CHANGED)
    ];

export default [
    ...epicUpdatersForCommonStyle,
    ...epicUpdatersForNormalStyle,
    ...epicUpdatersForMouseOverStyle,
    ...epicUpdatersForSelectedStyle,
    ...epicUpdatersForExpandableStyle,
    ...epicUpdatersForExpandedStyle,
    ...epicUpdatersForDivider,
    ...epicUpdatersForMenuLevelContainer,
    ...epicUpdatersForMultipleLevel
];
