import { showPropertiesPanelByInstanceIdAction } from '../../../PropertiesPanel/instance/actions';
import { InstancePropertiesPanelRegistryId } from '../../../PropertiesPanel/instance/InstancePropertiesPanelRegistryId';
import type { ImageAsset } from "../../../App/flowTypes";
import type { MobileHeaderTitleTypeT } from "../../../Mobile/header/types";

export const
    showMveHeaderPropertiesPanelAction = () =>
        showPropertiesPanelByInstanceIdAction(
            InstancePropertiesPanelRegistryId.MVE_HEADER_PROPERTIES_PANEL_INSTANCE_ID,
        );

export const
    TOGGLE_MVE_SHOW_LOGO_ACTION = 'TOGGLE_MVE_SHOW_LOGO_ACTION',
    toggleMveShowLogoAction = () => ({
        type: TOGGLE_MVE_SHOW_LOGO_ACTION,
    });

export const
    SET_MVE_HEADER_LOGO_ACTION = 'SET_MVE_HEADER_LOGO_ACTION',
    setMveHeaderLogoAction = (asset: ImageAsset) => ({
        type: SET_MVE_HEADER_LOGO_ACTION,
        payload: { asset },
    });

export const
    UNSET_MVE_HEADER_LOGO_ACTION = 'UNSET_MVE_HEADER_LOGO_ACTION',
    unsetMveHeaderLogoAction = () => ({
        type: UNSET_MVE_HEADER_LOGO_ACTION,
    });

export const
    TOGGLE_MVE_SHOW_TITLE_ACTION = 'TOGGLE_MVE_SHOW_TITLE_ACTION',
    toggleMveShowTitleAction = () => ({
        type: TOGGLE_MVE_SHOW_TITLE_ACTION,
    });

export const
    SET_MVE_TITLE_TYPE_ACTION = 'SET_MVE_TITLE_TYPE_ACTION',
    setMveTitleTypeAction = (type: MobileHeaderTitleTypeT) => ({
        type: SET_MVE_TITLE_TYPE_ACTION,
        payload: type,
    });

export const
    CHANGE_MVE_WEBSITE_TITLE_ACTION = 'CHANGE_MVE_WEBSITE_TITLE_ACTION',
    changeMveWebsiteTitleAction = (value: string) => ({
        type: CHANGE_MVE_WEBSITE_TITLE_ACTION,
        payload: value,
    });

export const
    TOGGLE_MVE_USE_CUSTOM_STYLE_ACTION = 'TOGGLE_MVE_USE_CUSTOM_STYLE_ACTION',
    toggleMveUseCustomStyleAction = () => ({
        type: TOGGLE_MVE_USE_CUSTOM_STYLE_ACTION,
    });

export const CHANGE_MVE_MENU_STYLE_CLOSED_ICON_COLOR_ACTION = 'CHANGE_MVE_MENU_STYLE_CLOSED_ICON_COLOR_ACTION';
export const CHANGE_MVE_MENU_STYLE_CLOSED_ICON_COLOR_ACTION_AUTO_COLOR = 'CHANGE_MVE_MENU_STYLE_CLOSED_ICON_COLOR_ACTION_AUTO_COLOR';

export const
    CHANGE_MVE_MENU_STYLE_CLOSED_OPACITY_ACTION = 'CHANGE_MVE_MENU_STYLE_CLOSED_OPACITY_ACTION',
    changeMveMenuStyleClosedOpacityAction = (value: number) => ({
        type: CHANGE_MVE_MENU_STYLE_CLOSED_OPACITY_ACTION,
        payload: value,
    });

export const CHANGE_MVE_MENU_STYLE_OPEN_ICON_COLOR_ACTION = 'CHANGE_MVE_MENU_STYLE_OPEN_ICON_COLOR_ACTION';
export const CHANGE_MVE_MENU_STYLE_OPEN_ICON_COLOR_ACTION_AUTO_COLOR = 'CHANGE_MVE_MENU_STYLE_OPEN_ICON_COLOR_ACTION_AUTO_COLOR';

export const
    CHANGE_MVE_MENU_STYLE_OPEN_OPACITY_ACTION = 'CHANGE_MVE_MENU_STYLE_OPEN_OPACITY_ACTION',
    changeMveMenuStyleOpenOpacityAction = (value: number) => ({
        type: CHANGE_MVE_MENU_STYLE_OPEN_OPACITY_ACTION,
        payload: value,
    });

export const
    CHANGE_MVE_TITLE_FONT_FAMILY_ACTION = 'CHANGE_MVE_TITLE_FONT_FAMILY_ACTION',
    changeMveTitleFontFamilyAction = (fontFamily: string) => ({
        type: CHANGE_MVE_TITLE_FONT_FAMILY_ACTION,
        payload: fontFamily,
    });

export const
    TOGGLE_MVE_TITLE_FONT_WEIGHT_ACTION = 'TOGGLE_MVE_TITLE_FONT_WEIGHT_ACTION',
    toggleMveTitleFontWeightAction = () => ({
        type: TOGGLE_MVE_TITLE_FONT_WEIGHT_ACTION,
    });

export const
    TOGGLE_MVE_TITLE_FONT_STYLE_ACTION = 'TOGGLE_MVE_TITLE_FONT_STYLE_ACTION',
    toggleMveTitleFontStyleAction = () => ({
        type: TOGGLE_MVE_TITLE_FONT_STYLE_ACTION,
    });

export const
    TOGGLE_MVE_TITLE_UNDERLINE_ACTION = 'TOGGLE_MVE_TITLE_UNDERLINE_ACTION',
    toggleMveTitleUnderlineAction = () => ({
        type: TOGGLE_MVE_TITLE_UNDERLINE_ACTION,
    });

export const CHANGE_TITLE_TEXT_COLOR_ACTION = 'CHANGE_TITLE_TEXT_COLOR_ACTION';
export const CHANGE_TITLE_TEXT_COLOR_ACTION_AUTO_COLOR = 'CHANGE_TITLE_TEXT_COLOR_ACTION_AUTO_COLOR';

export const
    TOGGLE_INCLUDE_MVE_BACKGROUND_COLOR_ACTION = 'TOGGLE_INCLUDE_MVE_BACKGROUND_COLOR_ACTION',
    toggleIncludeMveBackgroundColorAction = () => ({
        type: TOGGLE_INCLUDE_MVE_BACKGROUND_COLOR_ACTION,
    });

export const CHANGE_MVE_BACKGROUND_COLOR_ACTION = 'CHANGE_MVE_BACKGROUND_COLOR_ACTION';
export const CHANGE_MVE_BACKGROUND_COLOR_ACTION_AUTO_COLOR = 'CHANGE_MVE_BACKGROUND_COLOR_ACTION_AUTO_COLOR';

export const
    CHANGE_MVE_BACKGROUND_OPACITY_ACTION = 'CHANGE_MVE_BACKGROUND_OPACITY_ACTION',
    changeMveBackgroundOpacityAction = (opacity: number) => ({
        type: CHANGE_MVE_BACKGROUND_OPACITY_ACTION,
        payload: opacity,
    });

export const
    TOGGLE_USE_MVE_BOTTOM_BACKGROUND_SHADOW_ACTION = 'TOGGLE_USE_MVE_BOTTOM_BACKGROUND_SHADOW_ACTION',
    toggleUseMveBottomBackgroundShadowAction = () => ({
        type: TOGGLE_USE_MVE_BOTTOM_BACKGROUND_SHADOW_ACTION,
    });

export const
    TOGGLE_USE_MVE_BOTTOM_BACKGROUND_BORDER_ACTION = 'USE_MVE_BOTTOM_BACKGROUND_BORDER_ACTION',
    toggleUseMveBottomBackgroundBorderAction = () => ({
        type: TOGGLE_USE_MVE_BOTTOM_BACKGROUND_BORDER_ACTION,
    });

export const
    CHANGE_MVE_FIXED_HEADER_ACTION = 'CHANGE_MVE_FIXED_HEADER_ACTION',
    toggleMveFixedHeaderAction = (pin: 0 | 1) => ({
        type: CHANGE_MVE_FIXED_HEADER_ACTION,
        payload: { pin }
    });

export const
    CHANGE_MVE_MENU_STYLE_OPEN_FONTFAMILY_ACTION = 'CHANGE_MVE_MENU_STYLE_OPEN_FONTFAMILY_ACTION';

export const
    TOGGLE_MVE_MENU_STYLE_OPEN_BOLD_ACTION = 'TOGGLE_MVE_MENU_STYLE_OPEN_BOLD_ACTION';

export const
    TOGGLE_MVE_MENU_STYLE_OPEN_ITALIC_ACTION = 'TOGGLE_MVE_MENU_STYLE_OPEN_ITALIC_ACTION';

export const
    TOGGLE_MVE_MENU_STYLE_OPEN_UNDERLINE_ACTION = 'TOGGLE_MVE_MENU_STYLE_OPEN_UNDERLINE_ACTION';

export const
    TOGGLE_MVE_MENU_STYLE_OPEN_FONTSIZE_ACTION = 'TOGGLE_MVE_MENU_STYLE_OPEN_FONTSIZE_ACTION';

export const
    TOGGLE_MVE_MENU_STYLE_OPEN_LINEHEIGHT_ACTION = 'TOGGLE_MVE_MENU_STYLE_OPEN_LINEHEIGHT_ACTION';

export const CHANGE_MVE_MENU_STYLE_OPEN_TEXT_COLOR_ACTION = 'CHANGE_MVE_MENU_STYLE_OPEN_TEXT_COLOR_ACTION';
export const CHANGE_MVE_MENU_STYLE_OPEN_TEXT_COLOR_ACTION_AUTO_COLOR = 'CHANGE_MVE_MENU_STYLE_OPEN_TEXT_COLOR_ACTION_AUTO_COLOR';

export const CHANGE_MVE_MENU_STYLE_OPEN_BG_COLOR_ACTION = 'CHANGE_MVE_MENU_STYLE_OPEN_BG_COLOR_ACTION';
export const CHANGE_MVE_MENU_STYLE_OPEN_BG_COLOR_ACTION_AUTO_COLOR = 'CHANGE_MVE_MENU_STYLE_OPEN_BG_COLOR_ACTION_AUTO_COLOR';

export const
    TOGGLE_MVE_MENU_STYLE_SELECTED_BOLD_ACTION = 'TOGGLE_MVE_MENU_STYLE_SELECTED_BOLD_ACTION';

export const
    TOGGLE_MVE_MENU_STYLE_SELECTED_ITALIC_ACTION = 'TOGGLE_MVE_MENU_STYLE_SELECTED_ITALIC_ACTION';

export const
    TOGGLE_MVE_MENU_STYLE_SELECTED_UNDERLINE_ACTION = 'TOGGLE_MVE_MENU_STYLE_SELECTED_UNDERLINE_ACTION';

export const CHANGE_MVE_MENU_STYLE_SELECTED_TEXT_COLOR_ACTION_AUTO_COLOR = 'CHANGE_MVE_MENU_STYLE_SELECTED_TEXT_COLOR_ACTION_AUTO_COLOR';
export const CHANGE_MVE_MENU_STYLE_SELECTED_TEXT_COLOR_ACTION = 'CHANGE_MVE_MENU_STYLE_SELECTED_TEXT_COLOR_ACTION';

export const CHANGE_MVE_MENU_STYLE_SELECTED_BG_COLOR_ACTION_AUTO_COLOR = 'CHANGE_MVE_MENU_STYLE_SELECTED_BG_COLOR_ACTION_AUTO_COLOR';
export const CHANGE_MVE_MENU_STYLE_SELECTED_BG_COLOR_ACTION = 'CHANGE_MVE_MENU_STYLE_SELECTED_BG_COLOR_ACTION';

export const
    CHANGE_MVE_MENU_STYLE_SELECTED_BG_OPACITY_ACTION = 'CHANGE_MVE_MENU_STYLE_SELECTED_BG_OPACITY_ACTION',
    changeMveMenuStyleSelectedBgOpacityAction = (value: number) => ({
        type: CHANGE_MVE_MENU_STYLE_SELECTED_BG_OPACITY_ACTION,
        payload: value,
    });

export const
    MVE_HEADER_PP_MENU_STYLE_PAGE_TAB_CLICKED = 'MVE_HEADER_PP_MENU_STYLE_PAGE_TAB_CLICKED',
    mveHeaderPPMenuStylePageTabClickedAC = (id: string) => ({
        type: MVE_HEADER_PP_MENU_STYLE_PAGE_TAB_CLICKED,
        payload: id,
    });
