import { connect } from 'react-redux';
import InserterView from './view/inserter';
import type { AppState } from "../../redux/modules/flowTypes";
import injectIntl from "../../view/intl/injectIntl";

const mapStateToProps = (appState: AppState) => ({
    state: appState.panel,
    subscriptionType: appState.subscriptionData.subscriptionType,
    subscriptionMetadata: appState.subscriptionData.metadata,
});

export default injectIntl(connect(mapStateToProps)(InserterView));
