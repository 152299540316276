import * as ActionTypes from './actionTypes';

/**
 * How to use this feature allInOne?
 * 1) Go to console and type $WebDev.allInOne().
 * 2) A dialog will appear and select suitable actions
 * ProvideJsonToDbTab -->
 *  Copy page, template, stylesheet, site settings , site data from network tab.
 *  Paste and click on done button.
 *  OnSuccess: Dialog will disappear.
 *  Failure: Will throw an error
 */

export const webDevComponent = (dispatch: Dispatch) => {
    const $WebDev: any = {};

    const allInOne = () => {
        dispatch({ type: ActionTypes.SHOW_ALL_IN_ONE_DIALOG });
    };

    $WebDev.allInOne = allInOne;

    /* Global variable set up for Developer access */
    if (window) {
        window.$WebDev = $WebDev;
    }
};
