import { uniq } from "ramda";
import getGoogleFonts from "../Fonts/getPageGoogleFonts";
import type { ComponentsMap } from "../../redux/modules/children/workspace/flowTypes";
import type { Stylesheet } from "../Workspace/epics/stylesheets/flowTypes";
import type { SiteSettings } from "../App/epics/siteSettings/flowTypes";

export const getAllFonts = (
    componentsMap: ComponentsMap,
    stylesheet: Stylesheet,
    siteSettings?: SiteSettings,
): Array<string> =>
    uniq([...getGoogleFonts(componentsMap), ...getGoogleFonts(stylesheet), ...getGoogleFonts(siteSettings)]);
