/* eslint-disable max-len */

import React, { Component } from 'react';
import { connect } from "react-redux";
import type { AppState } from "../../../../../src/redux/modules/flowTypes";
import { demoLoginAppSel } from "../../../selectors";
import { injectIntl } from "../../../../../src/view/intl/index";
import type { DemoLoginViewPropTypes } from '../../types';
import languagesVAT from "../../../../../src/components/TopBar/epics/languages/valueActionType";
import { makeEpicStateSelector } from '../../../../../src/epics/makeEpic';
import { DemoLoginPageRouter } from "./DemoLoginPageRouter";

class DemoLoginPageView extends Component <DemoLoginViewPropTypes> {
    render() {
        const {
            intl,
            dispatch,
            languages,
            state: {
                signin,
                signup,
                forgotPassword,
                resetPassword,
                currentPage,
                verificationEmailRequired,
            },
            queryParams = {},
        } = this.props;

        const routerProps = {
            intl,
            dispatch,
            languages,
            signin,
            signup,
            forgotPassword,
            resetPassword,
            currentPage,
            verificationEmailRequired,
            queryParams,
        };

        return <DemoLoginPageRouter {...routerProps} />;
    }
}

const languagesEpicStateSelector = makeEpicStateSelector(languagesVAT);

const mapStateToProps = (appState: AppState) => ({
    state: demoLoginAppSel(appState),
    languages: languagesEpicStateSelector(appState)
});

export const DemoLoginPage = connect(mapStateToProps)(injectIntl(DemoLoginPageView));
