export const
    OPEN_MOBILE_VIEW_EDITOR_CLICKED = 'OPEN_MOBILE_VIEW_EDITOR_CLICKED',
    MOBILE_VIEW_EDITOR_PAGE_DATA_LOAD_SUCCESS = 'MOBILE_VIEW_EDITOR_PAGE_DATA_LOAD_SUCCESS',
    BACK_TO_DESKTOP_EDITOR_FROM_MOBILE_EDITOR = 'BACK_TO_DESKTOP_VIEW_FROM_MOBILE_EDITOR',
    MOBILE_EDITOR_COMPONENT_MOUSE_MOVE = 'MOBILE_EDITOR_COMPONENT_MOUSE_MOVE',
    MOBILE_EDITOR_COMPONENT_UPDATE_ARROWS_AFTER_MOVE = 'MOBILE_EDITOR_COMPONENT_UPDATE_ARROWS_AFTER_MOVE',
    MOBILE_EDITOR_COMPONENT_MOUSE_DOWN = 'MOBILE_EDITOR_COMPONENT_MOUSE_DOWN',
    MOBILE_EDITOR_COMPONENT_MOUSE_UP = 'MOBILE_EDITOR_COMPONENT_MOUSE_UP',
    MOBILE_EDITOR_COMPONENT_MOUSE_UP_OUTSIDE = 'MOBILE_EDITOR_COMPONENT_MOUSE_UP_OUTSIDE',
    MOBILE_EDITOR_COMPONENT_SELECT = 'MOBILE_EDITOR_COMPONENT_SELECT',
    MOBILE_EDITOR_REMOVE_OUTLINE_AND_SELECTION = 'MOBILE_EDITOR_REMOVE_OUTLINE_AND_SELECTION',
    MOBILE_EDITOR_REMOVE_OUTLINE = 'MOBILE_EDITOR_REMOVE_OUTLINE',
    MOBILE_EDITOR_ROOT_CONTAINER_RECT = 'MOBILE_EDITOR_ROOT_CONTAINER_RECT',
    MOBILE_EDITOR_COMPONENT_MOVED = 'MOBILE_EDITOR_COMPONENT_MOVED',
    MOBILE_EDITOR_COMPONENT_DRAGGING_STARTED = 'MOBILE_EDITOR_COMPONENT_DRAGGING_STARTED',
    MOBILE_EDITOR_COMPONENT_DRAGGING_ENDED = 'MOBILE_EDITOR_COMPONENT_DRAGGING_ENDED',
    MOBILE_EDITOR_COMPONENT_WIDTHS_ON_LOAD = 'MOBILE_EDITOR_COMPONENT_WIDTHS_ON_LOAD',
    MOBILE_EDITOR_COMPONENTS_SPECIFIC_STYLES_ON_LOAD = 'MOBILE_EDITOR_COMPONENTS_SPECIFIC_STYLES_ON_LOAD',
    MOBILE_EDITOR_ARROW_MOUSE_OVER = 'MOBILE_EDITOR_ARROW_MOUSE_OVER',
    MOBILE_EDITOR_ARROW_MOUSE_OUT = 'MOBILE_EDITOR_ARROW_MOUSE_OUT',
    MOBILE_EDITOR_VIEW_SCROLLED = 'MOBILE_EDITOR_VIEW_SCROLLED',
    MOBILE_EDITOR_UPDATE_ARROW_FOR_SCROLL = 'MOBILE_EDITOR_UPDATE_ARROW_FOR_SCROLL',
    MOBILE_EDITOR_MOVE_DOWN_ARROW_CLICKED = 'MOBILE_EDITOR_MOVE_DOWN_ARROW_CLICKED',
    MOBILE_EDITOR_MOVE_UP_ARROW_CLICKED = 'MOBILE_EDITOR_MOVE_UP_ARROW_CLICKED',
    MOBILE_EDITOR_HEADER_CLICKED = 'MOBILE_EDITOR_HEADER_CLICKED',
    OPEN_MOBILE_VIEW_EDITOR_WELCOME_MODAL = 'OPEN_MOBILE_VIEW_EDITOR_WELCOME_MODAL',
    TOGGLE_MVE_MODAL_DO_NOT_SHOW_AGAIN = 'TOGGLE_MVE_MODAL_DO_NOT_SHOW_AGAIN',
    MOBILE_EDITOR_UPDATED_SCROLL_TOP = "MOBILE_EDITOR_UPDATED_SCROLL_TOP",
    MOBILE_EDITOR_BETA_HOVER = "MOBILE_EDITOR_BETA_HOVER",
    MOBILE_EDITOR_DELETE_KEY_PRESSED = "MOBILE_EDITOR_DELETE_KEY_PRESSED",
    MOBILE_DOWN_ON_MOUSE_OVER = "MOBILE_DOWN_ON_MOUSE_OVER",
    MOBILE_DOWN_ON_MOUSE_OUT = "MOBILE_DOWN_ON_MOUSE_OUT",
    MOBILE_EDITOR_CMP_REORDER_TIP = "MOBILE_EDITOR_CMP_REORDER_TIP",
    MOBILE_EDITOR_CMP_REORDER_TIP_SHOWN = "MOBILE_EDITOR_CMP_REORDER_TIP_SHOWN",
    UPDATE_SCROLL_TOP_VALUE = "UPDATE_SCROLL_TOP_VALUE",
    MOBILE_EDITOR_WORKSPACE_UNFOCUSED = "MOBILE_EDITOR_WORKSPACE_UNFOCUSED",
    MOBILE_MCTA_ALIGN_MOUSE_ENTER = 'MOBILE_MCTA_ALIGN_MOUSE_ENTER',
    MOBILE_MCTA_ALIGN_MOUSE_LEAVE = 'MOBILE_MCTA_ALIGN_MOUSE_LEAVE',
    MOBILE_EDITOR_ALIGNMENT_CHANGE = 'MOBILE_EDITOR_ALIGNMENT_CHANGE',
    MOBILE_EDITOR_ALIGNMENT_UPDATE = 'MOBILE_EDITOR_ALIGNMENT_UPDATE',
    MOBILE_EDITOR_TOGGLE_HIDE = 'MOBILE_EDITOR_TOGGLE_HIDE',
    MOBILE_EDITOR_UN_HIDE_COMPONENTS = 'MOBILE_EDITOR_UN_HIDE_COMPONENTS',
    MOBILE_EDITOR_HIDE_COMPONENTS = 'MOBILE_EDITOR_HIDE_COMPONENTS',
    MOBILE_EDITOR_EXPAND_COLLAPSE_HIDDEN_PANEL = 'MOBILE_EDITOR_EXPAND_COLLAPSE_HIDDEN_PANEL',
    MOBILE_EDITOR_RESET_STATUS_FLAGS = 'MOBILE_EDITOR_RESET_STATUS_FLAGS',
    MOBILE_EDITOR_MOUSE_ACTION_HIDDEN_PANEL = 'MOBILE_EDITOR_MOUSE_ACTION_HIDDEN_PANEL',
    MOBILE_EDITOR_UPDATE_SCROLL_FOR_CMP = 'MOBILE_EDITOR_UPDATE_SCROLL_FOR_CMP',
    MOBILE_EDITOR_UPDATE_COMPONENT_EXTENSION_PROPS = 'MOBILE_EDITOR_UPDATE_COMPONENT_EXTENSION_PROPS',
    MOBILE_EDITOR_REMOVE_COMPONENT_EXTENSION_PROPS = 'MOBILE_EDITOR_REMOVE_COMPONENT_EXTENSION_PROPS',
    MOBILE_EDITOR_HEADER_HEIGHT = 'MOBILE_EDITOR_HEADER_HEIGHT',
    MOBILE_EDITOR_TOGGLE_BOTTOM_LOCK = 'MOBILE_EDITOR_TOGGLE_BOTTOM_LOCK',
    MOBILE_EDITOR_CHANGE_BG_POSITION = 'MOBILE_EDITOR_CHANGE_BG_POSITION',
    MOBILE_EDITOR_UNLOCK_COMPONENTS = 'MOBILE_EDITOR_UNLOCK_COMPONENTS',
    MOBILE_EDITOR_LOCK_COMPONENTS = 'MOBILE_EDITOR_LOCK_COMPONENTS',
    MOBILE_EDITOR_ACTION_HIDE_CLICKED = 'MOBILE_EDITOR_ACTION_HIDE_CLICKED',
    MOBILE_EDITOR_GROUP_IMAGE_SCALE_CHANGE = 'MOBILE_EDITOR_GROUP_IMAGE_SCALE_CHANGE',
    MOBILE_EDITOR_UPDATE_VIEW_DATA_TEXT_SIZE_IN_IMG_AND_TEXT_GROUP = 'MOBILE_EDITOR_UPDATE_VIEW_DATA_TEXT_SIZE_IN_IMG_AND_TEXT_GROUP',
    MOBILE_EDITOR_UPDATE_VIEW_DATA_TEXT_SIZE_IN_TEXT_AND_TEXT_GROUP = 'MOBILE_EDITOR_UPDATE_VIEW_DATA_TEXT_SIZE_IN_TEXT_AND_TEXT_GROUP',
    MOBILE_EDITOR_REMOVE_VIEW_DATA = 'MOBILE_EDITOR_REMOVE_VIEW_DATA',
    MOBILE_EDITOR_TEXT_FONT_CHANGE_IN_GROUP = 'MOBILE_EDITOR_TEXT_FONT_CHANGE_IN_GROUP',
    MOBILE_EDITOR_GROUP_CLEAR_FORMATTING = 'MOBILE_EDITOR_GROUP_CLEAR_FORMATTING',
    MOBILE_EDITOR_ENABLE_EDIT_MODE = 'MOBILE_EDITOR_ENABLE_EDIT_MODE';

export const
    backToDesktopEditorFromMobileEditorAction = () => ({
        type: BACK_TO_DESKTOP_EDITOR_FROM_MOBILE_EDITOR,
    });

export const
    selectMveComponent = (payload: Object) => ({
        type: MOBILE_EDITOR_COMPONENT_SELECT,
        payload,
    });
