import * as HorizontalAlign from './HorizontalAlign';

type AlignmentTableState = {
    verticalAlign: string,
    horizontalAlign: string
}

export const isAligmentButtonActive = (
    horizontalAlign: string,
    verticalAlign: string,
    state: AlignmentTableState
): boolean => state.verticalAlign === verticalAlign &&
        (state.horizontalAlign === HorizontalAlign.FIT || state.horizontalAlign === horizontalAlign);
