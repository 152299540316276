/* eslint-disable max-len */

import { connect } from "react-redux";
import React from 'react';
import cx from 'classnames';
import styles from './styles.css';
import { Dialog, DialogBody, DialogFooter, DialogTitleBox, StripTypes } from "../../../../../../view/common/dialogs/baseDialog/index";
import { injectIntl, Intl, Msg } from "../../../../../../view/intl/index";
import BusinessInformationStep from "./BusinessInformationStep";
import ContactDetailsStep from "./ContactDetailsStep";
import { OpeningHoursStep } from "./OpeningHoursStep";
import Icons, { customizedIcon } from "../../../../../../view/Icons/index";
import { generalDataStateSelector } from "../../../../General/selectors";
import { valueActionType as PUBLISH_CONFIG_EPIC_VALUE } from '../../../../publishConfig/publishConfigGlobalDataEpic';
import { makeEpicStateSelector } from '../../../../../../epics/makeEpic';
import { generalInfoTabEpic } from '../../../../General/GeneralInfoTabEpic';
import type { GeneralInfoTabData } from '../../../../General/types';
import type { GeneralGlobalData } from "../../../../General/generalGlobalDataEpic/generalGlobalDataEpic";
import Scrollbar from '../../../../../../view/common/Scrollbar/index';
import * as actionTypes from "../../actionTypes";
import { BUSINESS_LISTING_SITE_SETTINGS_SERVICE_EPIC_VALUE } from "../../businessListingSiteSettingsServiceVAT";
import type { BusinessListingServiceDefaultStateType } from "../../../flowTypes";
import LoadingIndicator from "../../../../../../view/common/LoadingIndicator/index";
import {
    CREATE_YOUR_LISTING_BUSINESS_INFORMATION_STEP,
    CREATE_YOUR_LISTING_CONTACT_DETAILS_STEP,
    CREATE_YOUR_LISTING_OPENING_HOURS_STEP,
} from "../../constants";

type Props = {
    intl: Intl;
    dispatch: Dispatch;
    generalData: GeneralGlobalData;
    generalInfoTabData: GeneralInfoTabData;
    publishWithSSL: boolean;
    serviceData: BusinessListingServiceDefaultStateType;
}

type State = {
    attemptedInvalidSubmit: boolean;
    isOneField: boolean;
};

const steps = {
    [CREATE_YOUR_LISTING_BUSINESS_INFORMATION_STEP]:
        { view: BusinessInformationStep, validator: ({ generalData }) => generalData.gmbCategories && !!generalData.gmbCategories.length, stepNumber: 1 },
    [CREATE_YOUR_LISTING_CONTACT_DETAILS_STEP]:
        { view: ContactDetailsStep, validator: ({ generalData }) => generalData.addressUrl, stepNumber: 2 },
    [CREATE_YOUR_LISTING_OPENING_HOURS_STEP]:
        { view: OpeningHoursStep, validator: () => true, stepNumber: 3 }, // eslint-disable-line no-unused-vars,
};
const Icon = customizedIcon(Icons.GMB_LISTING_ICON, { style: { fill: '#ffffff' } });

class CreateListingDialog extends React.Component<Props, State> {
    state: State;

    constructor(props) {
        super(props);
        this.state = { attemptedInvalidSubmit: false, isOneField: true };
    }

    render() {
        const
            {
                intl,
                dispatch,
                generalData: {
                    websiteTitle,
                    gmbCategories,
                    address,
                    addressUrl,
                    isVirtualAddress
                },
                generalInfoTabData: {
                    contactEmail,
                    phoneNumber
                },
                publishWithSSL,
                serviceData: { isWaitingForApi, errorData, createYourListingStep },
            } = this.props,
            setIsOneField = (val) => {
                this.setState({ isOneField: val });
            },
            Step = steps[createYourListingStep].view,
            backBtnHandler = () => {
                dispatch({ type: actionTypes.CREATE_YOUR_LISTING_BACK_ACTION });
            },
            nextBtnHandler = () => {
                if (steps[createYourListingStep].validator(this.props)) {
                    this.setState({ attemptedInvalidSubmit: false });
                    dispatch({ type: actionTypes.CREATE_YOUR_LISTING_VALIDATE_ACTION });
                } else {
                    this.setState({ attemptedInvalidSubmit: true });
                }
            },
            mctaText = createYourListingStep === CREATE_YOUR_LISTING_OPENING_HOURS_STEP ?
                "msg: common.create {Create}" : "msg: common.next {Next}",
            subTitles = [
                "msg: BusinessListing.createListing.businessInformationStepSubHeading {Let\'s start by confirming your business information}",
                'msg: BusinessListing.createListing.contactDetailsStepSubHeading {Confirm your contact details}',
                'msg: BusinessListing.createListing.openingHoursStepSubHeading {Opening hours}'
            ],
            stepNumber = steps[createYourListingStep].stepNumber,
            subTitleContent = <div className={styles.description}>
                <span><Msg k="BusinessListing.createListing.stepNumber" params={{ step: stepNumber }}>{`{step} of 3`}</Msg></span> - {intl.msgJoint(subTitles[stepNumber - 1])}
            </div>,
            getStep = () => {
                let stepProps;

                switch (createYourListingStep) {
                    case CREATE_YOUR_LISTING_BUSINESS_INFORMATION_STEP: stepProps = {
                        websiteTitle,
                        gmbCategories,
                        contactEmail,
                        publishWithSSL
                    };
                        break;
                    case CREATE_YOUR_LISTING_CONTACT_DETAILS_STEP: stepProps = {
                        address,
                        addressUrl,
                        phoneNumber,
                        isVirtualAddress,
                        errorData,
                        generalGlobalData: this.props.generalData,
                        setIsOneField,
                        isOneField: this.state.isOneField,
                    };
                        break;
                    case CREATE_YOUR_LISTING_OPENING_HOURS_STEP: stepProps = {
                        generalGlobalData: this.props.generalData
                    };
                        break;
                    default: stepProps = {};
                }

                return <Step {...stepProps} intl={intl} dispatch={dispatch} attemptedInvalidSubmit={this.state.attemptedInvalidSubmit} />;
            };

        return (
            <Dialog stripType={StripTypes.NONE}>
                <DialogTitleBox className={styles.dialogTitleBox}>
                    <div className={styles.header}>
                        <Icon />
                        <div className={styles.heading}>
                            <div className={styles.title}>
                                <Msg k="BusinessListing.createListing.heading">Create a Google My Business listing</Msg>
                            </div>
                            { subTitleContent }
                        </div>
                    </div>
                </DialogTitleBox>
                <Scrollbar height="100%" className={styles.scrollbar}>
                    <DialogBody className={styles.dialogBody}>
                        { isWaitingForApi ? <LoadingIndicator /> : getStep() }
                    </DialogBody>
                </Scrollbar>
                <DialogFooter
                    mctaText={mctaText}
                    sctaText="msg: common.back {Back}"
                    mctaHandler={nextBtnHandler}
                    className={cx(styles.footer)}
                    footerButtonContainerClassName={styles.footerButton}
                    primaryBtnClass={styles.footerPrimaryBtn}
                    sctaBtnClass={styles.footerSctaBtn}
                    sctaHandler={backBtnHandler}
                    autoFocus
                />
            </Dialog>
        );
    }
}

const mapStateToProps = (appState) => {
    return {
        generalData: generalDataStateSelector(appState),
        generalInfoTabData: makeEpicStateSelector(generalInfoTabEpic.valueActionType)(appState),
        publishWithSSL: makeEpicStateSelector(PUBLISH_CONFIG_EPIC_VALUE)(appState).publishWithSSL,
        serviceData: makeEpicStateSelector(BUSINESS_LISTING_SITE_SETTINGS_SERVICE_EPIC_VALUE)(appState),
    };
};

export default injectIntl(connect(mapStateToProps)(CreateListingDialog));
