import type { Dimensions } from "../../../globalTypes";
import type { TooltipPositionT } from "./types";
import { TooltipPosition } from "./constants";

const transform = (x: string, y: string) => `translate(${x}px, ${y}px) rotate(45deg)`;

/**
 * TODO:
 * - read BASE_TRANSFORM from tooltiopStyles.css ?
 */

type Params = {
    position: TooltipPositionT,
    targetRect: ClientRect,
    tooltipDimensions: Dimensions,
    pointerDimensions: Dimensions,
    windowDimensions: Dimensions,
    distance?: number,
};

type Result = {
    top: number,
    left: number,
    pointerStyle: Record<string, any>,
};

const PositionRound = [
    TooltipPosition.TOP, TooltipPosition.LEFT, TooltipPosition.BOTTOM, TooltipPosition.RIGHT,
];

const validatePosition = position => {
    if (PositionRound.indexOf(position) < 0) {
        throw new Error(`Unknown tooltip position: ${position}`);
    }
};

const getPositionRound = (position: TooltipPositionT) => {
    const idx = PositionRound.indexOf(position);

    if (idx === 0) return PositionRound;

    return PositionRound
        .slice(idx)
        .concat(PositionRound.slice(0, idx));
};

const defineIsFit = ({ top, left, tooltipDimensions, windowDimensions }) => (
    top >= 0
    && left + tooltipDimensions.width <= windowDimensions.width
    && top + tooltipDimensions.height <= windowDimensions.height
    && left >= 0
);

const defineVisibilityArea = ({ top, left, tooltipDimensions, windowDimensions }) => {
    const
        normalizedLeft = Math.max(Math.min(left, windowDimensions.width), 0),
        normalizedRight = Math.max(Math.min(left + tooltipDimensions.width, windowDimensions.width), 0),
        areaWidth = Math.round(normalizedRight - normalizedLeft),
        normalizedTop = Math.max(Math.min(top, windowDimensions.height), 0),
        normalizedBottom = Math.max(Math.min(top + tooltipDimensions.height, windowDimensions.height), 0),
        areaHeight = Math.round(normalizedBottom - normalizedTop);

    return areaWidth * areaHeight;
};

const calculateTooltipForPosition = (params: Params): { result: Result, isFit: boolean, visibilityArea: number } => {
    const
        { position, targetRect, tooltipDimensions, pointerDimensions, distance = 0 } = params,
        pointerSize = Math.ceil(pointerDimensions.width / 2),
        targetTop = targetRect.top,
        targetLeft = targetRect.left,
        pointerDiagonal = Math.sqrt(Math.pow(pointerDimensions.width, 2) + Math.pow(pointerDimensions.height, 2));

    let top, left, pointerStyle;

    if (position === TooltipPosition.BOTTOM) {
        top = targetTop + targetRect.height + Math.round(pointerDiagonal / 2) + distance;
        left = targetLeft + Math.round(targetRect.width / 2) - Math.round(tooltipDimensions.width / 2);
        pointerStyle = {
            top: 0,
            left: '50%',
            transform: transform(`-${pointerSize}`, `-${pointerSize}`),
        };
    } else if (position === TooltipPosition.TOP) {
        top = targetTop - tooltipDimensions.height - Math.round(pointerDiagonal / 2) - distance;
        left = targetLeft + Math.round(targetRect.width / 2) - Math.round(tooltipDimensions.width / 2);
        pointerStyle = {
            bottom: 0,
            left: '50%',
            transform: transform(`-${pointerSize}`, `${pointerSize}`),
        };
    } else if (position === TooltipPosition.RIGHT) {
        top = targetTop + Math.round(targetRect.height / 2) - Math.round(tooltipDimensions.height / 2);
        left = targetLeft + targetRect.width + Math.round(pointerDiagonal / 2);
        pointerStyle = {
            top: '50%',
            left: 0,
            transform: transform(`-${pointerSize}`, `-${pointerSize}`),
        };
    } else if (position === TooltipPosition.LEFT) {
        top = targetTop + Math.round(targetRect.height / 2) - Math.round(tooltipDimensions.height / 2);
        left = targetLeft - tooltipDimensions.width - Math.round(pointerDiagonal / 2);
        pointerStyle = {
            top: '50%',
            right: 0,
            transform: transform(`${pointerSize}`, `-${pointerSize}`),
        };
    } else {
        throw new Error(`Unknown tooltip position: ${position}`);
    }

    const
        { windowDimensions } = params,
        isFit = defineIsFit({ top, left, tooltipDimensions, windowDimensions }),
        visibilityArea = defineVisibilityArea({ top, left, tooltipDimensions, windowDimensions });

    return {
        result: { top, left, pointerStyle },
        isFit,
        visibilityArea,
    };
};

export const calculateTooltip = (params: Params): Result => {
    validatePosition(params.position);

    const round = getPositionRound(params.position);

    let maxArea = 0, optimalResult;

    for (let i = 0; i < round.length; i++) {
        const { result, isFit, visibilityArea } = calculateTooltipForPosition({
            ...params,
            position: round[i],
        });

        if (isFit) {
            return result;
        }

        if (visibilityArea > maxArea) {
            maxArea = visibilityArea;
            optimalResult = result;
        }
    }

    // $FlowFixMe: origResult will be defined 100%
    return optimalResult;
};
