import React from "react";
import cx from 'classnames';
import styles from '../LinkChooserDialog.css';
import Combobox from '../../../../Combobox/index';
import loadEmails from "../../actionCreators/loadEmails";
import { LC_EMAIL_SET, LC_EMAIL_SUBJECT_INPUT_CHANGE_ACTION } from "../../actionTypes";
import { Msg } from '../../../../../intl/index';
import injectIntl from "../../../../../intl/injectIntl";
import type { EmailTabPropTypes } from "../../flowTypes";
import { isValidEmail } from "../../../../../../utils/validation";
import { CreateEmailAddress } from "../../../../../../components/OwnerEmails/constants";
import { OWNER_EMAILS_OPEN_CREATE_EMAIL_URL } from "../../../../../../components/OwnerEmails/actionTypes";
import { DemoTip } from '../../../../../../../demo/modules/tip/DemoTip';
import { TooltipPosition } from '../../../../../../components/presentational/Tooltip/constants';
import LabeledInput from '../../../../../../components/inputControls/input/view/LabeledInput';
import { LinkChooserOption } from '../LinkChooserOption';
import VerticalSpacer from '../../../../VerticalSpacer';
import { openSiteSettingsDialog } from '../../../../../../components/SiteSettings/SiteSettingsDialog/actionCreators';
import { SiteSettingsTabName } from '../../../../../../components/SiteSettings/SiteSettingsDialog/constants';
import { KindToInputId } from '../../../../../../components/oneweb/TextLike/kindToInputId';
import { EMAIL_KIND } from '../../../../../../components/oneweb/TextLike/Email/kind';
import { GlobalVariableContactEmailKey } from '../../../../../../constants';
import inputStyles from '../../../../../../components/inputControls/input/view/Input.css';
import { ENTER } from "../../../../../../components/App/epics/isKeyPressed/keyboardKeys";
import focusHandler from '../../../../../../utils/inputFocusSelect';

import type { SiteSettingsDialogConfigWithInput } from '../../../../../../components/SiteSettings/SiteSettingsDialog/actionCreators';

type EmailTabState = {
    activeOptionKey: string;
    customEmailValue: string;
    domainEmailSelection: string;
};

const
    NO_RESULTS_MSG = 'msg: common.noResults {No results to display}',
    STORED_IN_SITE_SETTINGS = 'STORED_IN_SITE_SETTINGS',
    DOMAIN = 'DOMAIN',
    CUSTOM = 'CUSTOM';

class EmailTab extends React.Component<EmailTabPropTypes, EmailTabState> {
    customEmailInput?: HTMLElement | null;

    constructor(props) {
        super(props);
        this.state = { activeOptionKey: STORED_IN_SITE_SETTINGS, customEmailValue: '', domainEmailSelection: '' };
        this.setActiveOptionKey = this.setActiveOptionKey.bind(this);
    }

    setActiveOptionKey(key: string) {
        this.setState({ activeOptionKey: key });
    }

    _update(payload) {
        this.props.dispatch({ type: LC_EMAIL_SET, payload });
    }

    UNSAFE_componentWillMount() {
        if (this.props.selection === `#${GlobalVariableContactEmailKey}`) {
            this.setState({ activeOptionKey: STORED_IN_SITE_SETTINGS });
        } else if (typeof this.props.selection === "string" && this.props.emails.includes(this.props.selection)) {
            this.setState({ activeOptionKey: DOMAIN, domainEmailSelection: this.props.selection });
        } else {
            this.setState({ activeOptionKey: CUSTOM, customEmailValue: this.props.selection || '' });
        }
    }

    componentDidMount() {
        if (this.state.activeOptionKey === CUSTOM && this.customEmailInput) {
            this.customEmailInput.focus();
        }
    }

    render() {
        const
            { contactEmailFromSiteSettings, emails, subject, intl, dispatch } = this.props,
            { activeOptionKey } = this.state,
            focusEmailFieldInSiteSettingsDialogDispatcher = () => dispatch(openSiteSettingsDialog(({
                activeTabKey: SiteSettingsTabName.GENERAL, activateInputWithId: KindToInputId[EMAIL_KIND],
            } as SiteSettingsDialogConfigWithInput))),
            options = emails.map(e => ({ value: e, label: e }));

        return (
            <div className={styles.emailTab}>
                <Msg k="lc.email.introText" className={styles.introText}>Which email should the link go to?</Msg>
                <VerticalSpacer y={23} />
                <div className={styles.optionsWrapper}>
                    <LinkChooserOption
                        isActive={activeOptionKey === STORED_IN_SITE_SETTINGS}
                        onClick={() => {
                            this.setActiveOptionKey(STORED_IN_SITE_SETTINGS);
                            this._update(`#${GlobalVariableContactEmailKey}`);
                        }}
                        title={<Msg k="lc.email.option.siteSettings">Email stored in website settings</Msg>}
                        contentRenderer={() => {
                            if (contactEmailFromSiteSettings) {
                                return <React.Fragment>
                                    <VerticalSpacer y={9} />
                                    {contactEmailFromSiteSettings}
                                    <VerticalSpacer y={8} />
                                    <a onClick={focusEmailFieldInSiteSettingsDialogDispatcher}><Msg k="common.edit">Edit</Msg></a>
                                </React.Fragment>;
                            } else {
                                return <React.Fragment>
                                    <VerticalSpacer y={9} />
                                    <Msg k="lc.email.option.noEmailInSiteSettings">No email added yet.</Msg>
                                    <VerticalSpacer y={8} />
                                    <a onClick={focusEmailFieldInSiteSettingsDialogDispatcher}>
                                        <Msg k="lc.email.option.addEmailInSiteSettings">Add your email</Msg>
                                    </a>
                                </React.Fragment>;
                            }
                        }}
                    />
                    <VerticalSpacer y={15} />
                    <LinkChooserOption
                        isActive={activeOptionKey === DOMAIN}
                        onClick={() => {
                            this.setActiveOptionKey(DOMAIN);
                            this._update(this.state.domainEmailSelection);
                        }}
                        title={<Msg k="lc.email.option.domain">Email on your domain</Msg>}
                        contentRenderer={() => {
                            return <React.Fragment>
                                <VerticalSpacer y={6} />
                                <Combobox
                                    value={this.state.domainEmailSelection}
                                    options={options}
                                    onOpen={() => this.props.dispatch(loadEmails())}
                                    onChange={({ value }) => {
                                        this.setState({ domainEmailSelection: value });
                                        this._update(value);
                                    }}
                                    placeholder={intl.msgJoint('msg: common.emailPlaceHolder {Select email}')}
                                    noResultsText={intl.msgJoint(NO_RESULTS_MSG)}
                                />
                                <VerticalSpacer y={4} />
                                {/* @ts-ignore */}
                                <DemoTip position={TooltipPosition.BOTTOM}>
                                    <a
                                        onClick={() => dispatch({ type: OWNER_EMAILS_OPEN_CREATE_EMAIL_URL })}
                                    >{intl.msgJoint(CreateEmailAddress)}</a>
                                </DemoTip>
                            </React.Fragment>;
                        }}
                    />
                    <VerticalSpacer y={15} />
                    <LinkChooserOption
                        isActive={activeOptionKey === CUSTOM}
                        onClick={() => {
                            this.setActiveOptionKey(CUSTOM);
                            this._update(this.state.customEmailValue);
                            setTimeout(() => this.customEmailInput && this.customEmailInput.focus());
                        }}
                        title={<Msg k="lc.email.option.custom">Custom email address</Msg>}
                        contentRenderer={() => {
                            return <React.Fragment>
                                <VerticalSpacer y={6} />
                                <input
                                    type="email"
                                    spellCheck="false"
                                    placeholder={intl.msgJoint("msg: common.emailExample {e.g. hi@example.com}")}
                                    value={this.state.customEmailValue}
                                    onChange={e => {
                                        this.setState({ customEmailValue: e.target.value });
                                        this._update(e.target.value);
                                    }}
                                    onFocus={e => focusHandler(e)}
                                    className={cx(inputStyles.input, {
                                        [inputStyles.invalid]: this.state.customEmailValue && !isValidEmail(this.state.customEmailValue)
                                    })}
                                    ref={customEmailInput => { this.customEmailInput = customEmailInput; }}
                                    onKeyDown={e => { if (e.key === ENTER) this.props.onSave(); }}
                                />
                            </React.Fragment>;
                        }}
                    />
                </div>
                <VerticalSpacer y={20} />
                <LabeledInput
                    label={{ value: 'msg: common.optionalEmailSubject {Email subject (Optional)}', translate: true }}
                    state={subject}
                    placeholder="msg: common.addSubjectLine {Add a subject line}"
                    onChangeAction={LC_EMAIL_SUBJECT_INPUT_CHANGE_ACTION}
                    dispatch={dispatch}
                />
            </div>
        );
    }
}

export default injectIntl(EmailTab);
