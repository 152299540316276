import type {
    InstancePropertiesPanelRegistryConfigPropertiesPanelParams,
    InstancePropertiesPanelRegistryConfigT,
    InstancePropertiesPanelRegistryConfigUpdater,
    InstancePropertiesPanelRegistryIdT,
    InstancePropertiesPanelRegistryPage,
    InstancePropertiesPanelRegistryT,
} from './types';
import { InstancePropertiesPanelRegistryId } from './InstancePropertiesPanelRegistryId';
import {
    MveHeaderPropertiesPanelConfig,
} from '../../MobileViewEditor/header/propertiesPanel/MveHeaderPropertiesPanelConfig';

export const InstancePropertiesPanelRegistry: InstancePropertiesPanelRegistryT = {
    [InstancePropertiesPanelRegistryId.MVE_HEADER_PROPERTIES_PANEL_INSTANCE_ID]: MveHeaderPropertiesPanelConfig,
};

export class InstancePropertiesPanelConfig {
    /*::#*/instanceId: string;
    /*::#*/config: InstancePropertiesPanelRegistryConfigT;

    constructor(instanceId: InstancePropertiesPanelRegistryIdT) {
        const config = InstancePropertiesPanelRegistry[instanceId];
        if (!config) {
            throw new Error(`Missing instance properties panel config in registry by id: ${instanceId}`);
        }
        this./*::#*/instanceId = instanceId;
        this./*::#*/config = config;
    }

    getOnShowUpdater(): InstancePropertiesPanelRegistryConfigUpdater {
        return this._getRequiredParam('onShowUpdater');
    }

    getUpdaters(): Array<InstancePropertiesPanelRegistryConfigUpdater> {
        return this._getRequiredParam('updaters');
    }

    getAllPages(): Array<InstancePropertiesPanelRegistryPage> {
        return this._getRequiredParam('Pages');
    }

    getDefaultPage(): InstancePropertiesPanelRegistryPage {
        const pages = this.getAllPages();
        return pages.find(p => p.isDefault) || pages[0];
    }

    getPage(id: string): InstancePropertiesPanelRegistryPage {
        const page = this.getAllPages().find(p => p.id === id);
        if (!page) {
            throw new Error(
                `Unable to find instance properties panel page by id: ${id}; for instanceId: ${this./*::#*/instanceId}`,
            );
        }
        return page;
    }

    getPropertiesPanelParams(): InstancePropertiesPanelRegistryConfigPropertiesPanelParams {
        return this./*::#*/config.propertiesPanelParams || {};
    }

    _getRequiredParam(name: string) {
        if (!this./*::#*/config[name]) {
            throw new Error(
                `Missing '${name}' in properties panel instance config for id: ${this./*::#*/instanceId}`,
            );
        }
        return this./*::#*/config[name];
    }
}
