import * as R from 'ramda';
import isEmptyParagraphNode from './isEmptyParagraphNode';
import getLastNode from './getLastNode';
import { mergeTextNodes } from './utils/index';
import nodeFactory from './nodeFactory/index';
import * as nodeTypes from './nodeFactory/nodeTypes';
import isEmptyHeaderNode from './isEmptyHeaderNode';
import type { Element } from '../../flowTypes';
import Node from './Node';

const isTextElement = R.pipe(R.prop('atype'), R.equals('text'));

const makeTreeFactory = (editMode: boolean) => {
    const treeFactory = (text: string, elements: Array<Element>, parent?: Node): Array<Node> => {
        const nodes: Node[] = [];
        let i = 0;

        while (i < elements.length) {
            const
                isFirstChild = i === 0,
                currentElement = elements[i],
                currentNode = nodeFactory(nodeTypes.ELEMENT, {
                    element: currentElement,
                    editMode,
                    isFirstChild,
                    parent
                });

            if (!isTextElement(currentElement)) {
                const childElements: Element[] = [];
                while (i < elements.length - 1) {
                    let nextElement = elements[i + 1];
                    if (
                        nextElement.start >= currentElement.start &&
                        nextElement.end <= currentElement.end
                    ) {
                        i++;
                        childElements.push(nextElement);
                    } else {
                        break;
                    }
                }

                const childNodes = treeFactory(text, childElements, currentNode);
                currentNode.childNodes.push(...childNodes);
            }

            if ((currentNode.tag === 'p' && isEmptyParagraphNode(currentNode)) || isEmptyHeaderNode(currentNode)) {
                const lastNode = getLastNode(currentNode);
                const breakNode = nodeFactory(nodeTypes.BREAK, {
                    element: currentNode,
                    isFirstChild,
                    parent: lastNode
                });
                lastNode.childNodes.push(breakNode);
            }

            nodes.push(currentNode);
            i++;
        }

        return mergeTextNodes(nodes);
    };

    return treeFactory;
};

export default makeTreeFactory;
