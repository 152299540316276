/* eslint-disable react/jsx-fragments */
/* eslint-disable max-len */
import React from 'react';
import Measure from 'react-measure';
import cx from "classnames";
import * as selectors from '../../../Workspace/epics/stylesheets/selectors';
import { colorToCss } from '../../../../mappers/color';
import { addComponentAdjustmentDataEntry } from '../../../Workspace/epics/componentsEval/adjustmentDataDispatchCache';

import { DEFAULT_LINE_HEIGHT } from '../../../App/epics/tinyMceEpic/editorUtils/constants';
import { createSelectedParentThemeWithThemeTextClass } from "../../../ThemeGlobalData/utils/commonUtils";
import { computePrefixMarginBottom } from "../utils/computePrefixMarginBottom";
import type { TextLikeComponentViewFactoryType, TextLikeViewType } from "../types";

const horizontalAlignmentToSpacingDirection = align => {
    if (align === 'left') {
        return 'marginRight';
    } else if (align === 'right') {
        return 'marginLeft';
    }
    return 'marginBottom';
};

const horizontalAlignmentToFlexDirection = horizontalAlignment => {
    if (horizontalAlignment === 'left') return 'row';
    if (horizontalAlignment === 'right') return 'row-reverse';
    return 'column';
};

export const textLikeComponentViewFactory = ({
    defaultLineSpacing,
    getHref,
    openInNewTab,
    render,
    iconRender
}: TextLikeComponentViewFactoryType<any, any>): TextLikeViewType<any> => (props) => {
    const style = {
        fontWeight: props.generic.textStyle.bold === null ? null : (props.generic.textStyle.bold ? 'bold' : 'normal'), // eslint-disable-line no-nested-ternary
        fontStyle: props.generic.textStyle.italic === null ? null : (props.generic.textStyle.italic ? 'italic' : 'normal'), // eslint-disable-line no-nested-ternary
        textDecoration: props.generic.textStyle.underline === null ? null : (props.generic.textStyle.underline ? 'underline' : 'none'), // eslint-disable-line no-nested-ternary
        fontSize: props.generic.textStyle.fontSize,
        color: props.generic.textStyle.color ? colorToCss(props.generic.textStyle.color) : null,
        fontFamily: props.generic.textStyle.fontFamily,
        letterSpacing: props.generic.textStyle.letterSpacing,
    };
    const valueStyle = {
        ...style,
        textTransform: props.generic.textStyle.valueCase,
    };
    const { iconTextSpacing, iconSize } = props.generic;
    const textNormalStyle = selectors.textNormalGlobalstyle(props.deps.stylesheets);
    const textFontSize = props.generic.textStyle.fontSize || textNormalStyle.size;
    const lineHeight = props.generic.textStyle.lineHeight === null ? (defaultLineSpacing || DEFAULT_LINE_HEIGHT) : props.generic.textStyle.lineHeight;
    const textLineHeight = Math.max(lineHeight, DEFAULT_LINE_HEIGHT);
    const href = getHref((props as any));
    const linkRenderer = (render, style, additionalProps) => <a
        className="textnormal"
        href={href}
        target={openInNewTab ? '_blank' : undefined}
        style={{ ...valueStyle, ...style, lineHeight: textLineHeight }}
        data-preserve-whitespace
    >
        {render({ ...props, ...additionalProps })}
    </a>;
    let text;
    if (href) {
        text = linkRenderer(render, {}, {});
    } else {
        // @ts-ignore
        text = <span style={valueStyle} data-preserve-whitespace>
            {/* @ts-ignore */}
            {render(props) as any}
        </span>;
    }

    const containterFontSize = props.generic.textStyle.fontSize ? '1px' : null;

    let contentView;
    const contentViewStyle = {
        whiteSpace: 'pre-wrap',
        lineHeight: textLineHeight,
        textAlign: props.generic.horizontalAlignment,
        width: '100%',
    };
    if (props.generic.showCustomTitleFirst) {
        const prefixStyle = {
            ...style,
            display: props.generic.showOnOneLine ? null : 'block',
            marginBottom: computePrefixMarginBottom(textFontSize, lineHeight),
            textTransform: props.generic.textStyle.prefixCase,
            fontWeight: props.generic.textStyle.prefixBold === null ? null : (props.generic.textStyle.prefixBold ? 'bold' : 'normal'), // eslint-disable-line no-nested-ternary
            fontStyle: props.generic.textStyle.prefixItalic === null ? null : (props.generic.textStyle.prefixItalic ? 'italic' : 'normal'), // eslint-disable-line no-nested-ternary
            textDecoration: props.generic.textStyle.prefixUnderline === null ? null : (props.generic.textStyle.prefixUnderline ? 'underline' : 'none'), // eslint-disable-line no-nested-ternary
        };// @ts-ignore
        contentView = (measureRef) => <div ref={measureRef} className="textnormal" style={{ fontSize: containterFontSize, ...contentViewStyle }}>
            { /* @ts-ignore */ }
            <span style={prefixStyle} className="prefix">
                {props.generic.showCustomTitleFirst && (props.generic.customPrefixText || ' ')}
            </span>
            {
                props.generic.showOnOneLine && props.generic.customPrefixText
                // @ts-ignore
                && <span style={props.generic.textStyle.fontSize ? { fontSize: props.generic.textStyle.fontSize } : null}> </span>
            }
            {text}
        </div>;
    } else {
        // @ts-ignore
        contentView = (measureRef) => <div ref={measureRef} className="textnormal" style={{ fontSize: containterFontSize, ...contentViewStyle }}>{text}</div>;
    }

    const color = props.generic.textStyle.color ? colorToCss(props.generic.textStyle.color) : null;
    const iconRenderProps = { style: { width: iconSize } };
    const iconElement = props.generic.showIcon && <div style={{ width: 'auto' }}>
        <div
            className="svgContainer"
            style={{
                // @ts-ignore
                color,
                fontSize: iconSize,
                display: 'flex',
                justifyContent: 'center',
                width: iconSize,
                height: iconSize,
                [horizontalAlignmentToSpacingDirection(props.generic.horizontalAlignment)]: iconTextSpacing
            }}
        >
            {
                href
                    ? linkRenderer(
                        iconRender,
                        {
                            color,
                            lineHeight: '1px',
                            fontSize: '1px', // hack for lineHeight inheritance
                            width: iconSize,
                        },
                        iconRenderProps,
                    )
                    : iconRender(iconRenderProps)
            }
        </div>
    </div>;
    let contentProps = {};
    if (props.offScreenRef) {
        contentProps = { ref: props.offScreenRef };
    }

    return (
        <div
            className={cx([
                createSelectedParentThemeWithThemeTextClass({ selectedParentTheme: props.selectedParentTheme }),
                props.generic.themeOverrideColor
            ])}
            style={{ height: '100%', width: '100%' }}
        >
            <div
                className={cx("textnormal")}
                style={{
                    wordBreak: 'break-word',
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: horizontalAlignmentToFlexDirection(props.generic.horizontalAlignment),
                }}
                {...contentProps}
            >
                {iconElement}
                <div style={{ width: props.offScreenRef ? 'auto' : '100%' }}>
                    { props.offScreenRef ?
                        contentView() :
                        <Measure
                            offset
                            onResize={
                                props.isWorkspace ? ({ offset }) => {
                                    const height = Math.round(offset.height);
                                    return addComponentAdjustmentDataEntry(
                                        props.componentId,
                                        { minDimensions: { height } }
                                    );
                                } : null
                            }
                        >
                            {({ measureRef }) => contentView(measureRef)}
                        </Measure>}
                </div>
            </div>
        </div>
    );
};
