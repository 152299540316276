import Node from '../Node';
import type { Element } from '../../../flowTypes';
import { getTag, getStyles } from '../../getters/index';

export type ElementNodeArgs = {
    element: Element,
    childNodes: Array<Node>,
    editMode: null | undefined | boolean,
    isFirstChild?: boolean,
    parent?: Node
};

export default (args: ElementNodeArgs): Node => {
    const {
        element,
        childNodes,
        editMode,
        isFirstChild,
        parent
    } = args;

    const tag = getTag(element, editMode);
    const styles = getStyles(element);

    return new Node({
        ...element,
        childNodes,
        tag,
        styles,
        isFirstChild,
        parent
    });
};
