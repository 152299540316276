export const
    RowHeight = 24,
    RowPadding = 4,

    MIN_WIDTH = 76,
    MIN_HEIGHT = 1,

    LinkedInAPI = 'https://platform.linkedin.com/in.js',
    TwitterAPI = 'https://platform.twitter.com/widgets.js',

    VerbToDisplayLike = 'like',
    VerbTodisplayRecommend = 'recommend',

    VerbToDisplay = [VerbToDisplayLike, VerbTodisplayRecommend],
    Alignments = ['left', 'center', 'right'],

    DefaultWidth = 600,
    DefaultHeight = 80,

    HorizontalCountStyle = 'horizontalcount',
    VerticalCountStyle = 'verticalcount',
    WithoutCountStyle = 'withoutcount',
    ExtraInfoStyle = 'extrainfo',
    RoundStyle = 'simple1',
    SquareStyle = 'simple2',

    DefaultStyle = HorizontalCountStyle,
    DefaultVerbToDisplay = VerbToDisplayLike,
    DefaultAlignment = 'center',

    LocaleMap = {
        facebook: {
            'da': 'da_DK',
            'de': 'de_DE',
            'en_gb': 'en_GB',
            'en_us': 'en_US',
            'es': 'es_LA',
            'fr': 'fr_FR',
            'it': 'it_IT',
            'nl': 'nl_NL',
            'nb': 'nb_NO',
            'pt': 'pt_PT',
            'fi': 'fi_FI',
            'sv': 'sv_SE'
        },
        linkedin: {
            'da': 'da_DK',
            'de': 'de_DE',
            'en_gb': 'en_US',    // No support for English UK
            'en_us': 'en_US',
            'es': 'es_ES',
            'fr': 'fr_FR',
            'it': 'it_IT',
            'nl': 'nl_NL',
            'nb': 'no_NO',
            'pt': 'pt_BR',    // No support for pt_PT
            'fi': 'en_US',    // No support for Finnish
            'sv': 'sv_SE'
        },
        twitter: {
            'da': 'da',
            'de': 'de',
            'en_gb': 'en-gb',
            'en_us': 'en',
            'es': 'es',
            'fr': 'fr',
            'it': 'it',
            'nl': 'nl',
            'nb': 'nb',
            'pt': 'pt',   // Defaults to pt_PT. No support for pt_BR.
            'fi': 'fi',
            'sv': 'sv'
        }
    },

    VerbsToDisplay = [{
        label: 'msg: component.sharebuttons.pp.verbstodisplay.like {Like}',
        value: VerbToDisplayLike
    }, {
        label: 'msg: component.sharebutton.pp.verbtodisplay.recommend {Recommend}',
        value: VerbTodisplayRecommend
    }],

    StyleOptions = [{
        label: 'msg: component.shareButtons.tb.styles.verticalCount {Vertical count}',
        value: VerticalCountStyle
    }, {
        label: 'msg: component.shareButtons.tb.styles.horizontalCount {Horizontal count}',
        value: HorizontalCountStyle
    }, {
        label: 'msg: component.shareButtons.tb.styles.withoutCount {Without count}',
        value: WithoutCountStyle
    }, {
        label: 'msg: component.shareButtons.tb.styles.extraInfo {Extra info}',
        value: ExtraInfoStyle
    }, {
        label: 'msg: component.shareButtons.tb.styles.round {Round}',
        value: RoundStyle
    }, {
        label: 'msg: component.shareButtons.tb.styles.square {Square}',
        value: SquareStyle
    }];
