import u from 'updeep';

import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import PageInfoDialog from "./view";
import reducer from './reducer';
import makeDefaultState from "./reducer/makeDefaultState";
import siteDataEpic from '../../../../App/epics/siteData';
import calcRenderProps from "./view/calcRenderProps";
import currentPageIdEpic from '../../../../App/epics/currentPageId';
import { pageDataSetTemplateInfoSelectorReducer } from "../../../../App/epics/pageDataset/pageDataSetTemplateNameSelectorReducer";
import { stateIsEqInitialComparator } from './reducer/stateIsEqInitialComparator';
import { PageInfoTab } from '../../constants';
import type { PageInfoDialogDependencies, PageInfoDialogState } from './flowTypes';
import subscriptionDataEpic from '../../../../App/epics/subscriptionData';
import { seoGlobalDataEpic } from "../../../../SiteSettings/seoGlobalData/seoGlobalDataEpic";
import { publishConfigReducer } from "../../../../SiteSettings/publishConfig/publishConfigGlobalDataEpic";
import { websiteTitleReducer } from "../../../../App/epics/globalVariablesEpic";

export default getCenteredDialogConfig({
    width: 900,
    height: 600,
    component: PageInfoDialog,
    reducer,
    makeDefaultState,
    dependsOn: {
        site: siteDataEpic.reducer,
        websiteTitle: websiteTitleReducer,
        seoGlobalData: seoGlobalDataEpic.reducer,
        subscriptionData: subscriptionDataEpic.reducer,
        currentPageId: currentPageIdEpic.reducer,
        currentTemplate: pageDataSetTemplateInfoSelectorReducer,
        publishConfig: publishConfigReducer
    },
    updateStateDependencies: (state: PageInfoDialogState, deps: PageInfoDialogDependencies) => u({
        [PageInfoTab.TEMPLATE]: {
            selectedTemplate: deps.currentTemplate,
        },
    }, state),
    calcRenderProps,
    addIsEqToInitialStateFlag: true,
    stateIsEqInitialComparator
});
