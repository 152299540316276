import makeEpic from '../../../../epics/makeEpic';
import { textLimitWarningEpicInitialState } from "./state";
import valueActionType from "./valueActionType";
import { TEXT_LIMIT_SHOW_WARNING } from "./actionTypes";

export const textLimitWarningEpic = makeEpic({
    defaultState: textLimitWarningEpicInitialState,
    defaultScope: {},
    valueActionType,
    updaters: [
        {
            conditions: [TEXT_LIMIT_SHOW_WARNING],
            reducer: ({ state, scope, values: [data] }) => {
                return {
                    state: {
                        ...state,
                        ...data
                    },
                    scope
                };
            },
        }
    ]
});
