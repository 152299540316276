// @ts-nocheck
import { loadRequestAnimationFramePolyfill } from "./commonPolyfills/LoadRequestAnimationFramePolyfill";
import { childNodeRemovePolyfill } from './commonPolyfills/ChildNodeRemovePolyfill';

loadRequestAnimationFramePolyfill();
childNodeRemovePolyfill();

if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector;
}
