import { pure } from 'recompose';
import React from "react";
import * as R from "ramda";
import { InWorkspaceView as DndAddComponent } from "../../../components/DndAddComponent/view";
import ComponentsSelectionDecoration from './ComponentsSelectionDecoration';
import { HoverBoxDecoration } from "./HoverBoxDecoration/HoverBoxDecoration";
import RedDecoration from './RedDecorations';
import HoverDecorations from './HoverDecorations';
import AttachDecorations from './AttachDecorations';
import ShiftBarTopDecorations from './ShiftBarTopDecorations';
import ResizeDecorations from './ResizeDecorations';
import SelectionFrame from "./SelectionFrame";
import SnappingDecorations from "./SnappingDecorations";
import { ResizingDots } from "./ResizingDots";
import CornerDecoration from "./CornerDecorations";
import ComponentMainActions from './ComponentMainActions/index';
import ComponentPanelActions from './ComponentPanelActions/index';
import * as interactionModes from '../../../components/Workspace/epics/componentsEval/userInteractionMutations/interactionModes';
import ErrorBoundary from "../../../redux/recoverAfterException/ErrorBoundary/ErrorBoundary";
import { dontShowSelectionHandles } from "../../../components/Workspace/epics/componentsEval/editModeComponentId";
import { TemplateEdgeDecorations } from "../../../components/Workspace/TemplateEdgeDecorations/TemplateEdgeDecorations";
import { WrapPositionControls } from '../../../components/Workspace/WrapPositionControls/view';
import { isSectionKind } from "../../../components/oneweb/componentKinds";
import { AddIcon } from "./addDecorations";
import { TitleTag } from "./TitleTag";
import { SectionHoverDecorations } from "./sectionHoverDecorations";
import { TextLimitWarning } from "../../common/Warning/TextLimitWarning";

export function dontShowResizeHandles(interactionMode: string) {
    return dontShowSelectionHandles(interactionMode) || interactionMode === interactionModes.SHIFTBAR_MOVING;
}
const Decorations = ({
    componentsSelectionDecoration,
    componentHoverDecorations,
    componentAttachDecorations,
    selectionFrameDecoration,
    redDecorations,
    handles,
    userInteraction,
    componentMainActions,
    componentPanelActions,
    shiftBarDecorations,
    resizeDecos,
    editModeComponentId,
    snappingDecos,
    selectedComponentsIds,
    isMouseOverMCTA,
    dndAddComponent,
    scroll,
    showAutoColorLeftPanel,
    dispatch
}: Record<string, any> /* TODO WBTGEN-4867 */) => {
    if (showAutoColorLeftPanel) {
        return null;
    }

    const interactionMode = R.path(['mode'], userInteraction),
        cornerDecosVisible = interactionMode === interactionModes.MOVING_COMPONENTS
            || interactionMode === interactionModes.RESIZE_HANDLE_MOVING,
        userInteractionHandleKind = R.path(['payload', 'handleKind'], userInteraction),
        { selectedComponent } = componentMainActions,
        { sectionDecorations } = componentHoverDecorations,
        {
            sectionDecorations: selectedCmpSectionDecoration,
            hoverBoxDecorations: selectedHoverBoxDecoration,
        } = componentsSelectionDecoration,
        sectionHoverDecorations = [sectionDecorations, selectedCmpSectionDecoration],
        hoverBoxDecorations = [selectedHoverBoxDecoration];

    return (
        <div>
            <ErrorBoundary invisible> <DndAddComponent state={dndAddComponent} /></ErrorBoundary>
            <ErrorBoundary invisible> <RedDecoration state={redDecorations} /></ErrorBoundary>
            <ErrorBoundary invisible> <SnappingDecorations state={snappingDecos} /></ErrorBoundary>
            <ErrorBoundary invisible> <TemplateEdgeDecorations /></ErrorBoundary>
            <ErrorBoundary invisible> <ShiftBarTopDecorations state={shiftBarDecorations} /></ErrorBoundary>
            <ErrorBoundary invisible> <ResizeDecorations state={resizeDecos} /></ErrorBoundary>
            <ErrorBoundary invisible>
                <ComponentsSelectionDecoration
                    state={componentsSelectionDecoration}
                    handles={handles}
                    userInteractionHandleKind={userInteractionHandleKind}
                    interactionMode={interactionMode}
                    dontShowHandles={
                        dontShowSelectionHandles(interactionMode)
                    }
                />
            </ErrorBoundary>
            <ErrorBoundary invisible>
                <HoverDecorations state={componentHoverDecorations} />
            </ErrorBoundary>
            <ErrorBoundary invisible>
                <AttachDecorations state={componentAttachDecorations} />
            </ErrorBoundary>
            <ErrorBoundary invisible>
                <SelectionFrame state={selectionFrameDecoration.state} />
            </ErrorBoundary>
            <ErrorBoundary invisible> <SectionHoverDecorations
                sectionDecorations={sectionHoverDecorations}
                componentMainActions={componentMainActions}
                interactionMode={interactionMode}
                scroll={scroll}
            /> </ErrorBoundary>
            <ErrorBoundary invisible>
                <CornerDecoration
                    isVisible={cornerDecosVisible}
                    scroll={scroll}
                    bBox={componentsSelectionDecoration.bBox}
                    isPosition={interactionMode === interactionModes.MOVING_COMPONENTS}
                    isDimensions={interactionMode === interactionModes.RESIZE_HANDLE_MOVING}
                />
            </ErrorBoundary>
            <ErrorBoundary invisible>
                {
                    selectedComponentsIds.length > 0
                    && !dontShowResizeHandles(interactionMode)
                    && (
                        <ResizingDots
                            handles={handles}
                            componentsSelectionDecoration={componentsSelectionDecoration}
                            userInteractionHandleKind={userInteractionHandleKind}
                            interactionMode={interactionMode}
                        />
                    )
                }
            </ErrorBoundary>
            <ErrorBoundary invisible>
                {
                    selectedComponent &&
                    isSectionKind(selectedComponent.kind)
                    && (
                        <AddIcon dispatch={dispatch} handles={handles} interactionMode={interactionMode} />
                    )
                }
            </ErrorBoundary>

            <ErrorBoundary invisible>
                {
                    componentMainActions.show && (
                        <ComponentMainActions
                            dispatch={dispatch}
                            componentMainActions={componentMainActions}
                            editModeComponentId={editModeComponentId}
                            isMouseOverMCTA={isMouseOverMCTA}
                        />
                    )
                }
            </ErrorBoundary>
            <ErrorBoundary invisible>
                {/* <TextLimitWarning componentMainActions={componentMainActions} /> */}
                {
                    componentMainActions && componentMainActions.selectedComponentNotGhost ?
                        <TextLimitWarning componentMainActions={componentMainActions} />
                        : (null)
                }
            </ErrorBoundary>
            <ErrorBoundary invisible>
                { /* @ts-ignore */ }
                <HoverBoxDecoration
                    dispatch={dispatch}
                    hoverBoxDecorations={hoverBoxDecorations}
                />
            </ErrorBoundary>
            <ErrorBoundary invisible>
                {
                    componentPanelActions.component && (
                        <ComponentPanelActions
                            dispatch={dispatch}
                            componentPanelActions={componentPanelActions}
                        />
                    )
                }
            </ErrorBoundary>
            <ErrorBoundary invisible>
                {
                    selectedComponent && <TitleTag
                        component={selectedComponent}
                        bBox={componentsSelectionDecoration.bBox}
                        scroll={scroll}
                        componentMainActions={componentMainActions}
                    />
                }
            </ErrorBoundary>
            <ErrorBoundary invisible>
                <WrapPositionControls />
            </ErrorBoundary>
        </div>
    );
};

export default pure(Decorations);
