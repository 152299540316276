import * as editorCommands from "../editorUtils/editorCommands";
import { makeExecCommandUpdater } from "./makeExecCommandUpdater";
import { TINY_MCE_OUTDENT } from "../actionTypes";

const onOutdentUpdater = makeExecCommandUpdater({
    triggerActionType: TINY_MCE_OUTDENT,
    cmd: editorCommands.OUTDENT
});

export {
    onOutdentUpdater
};
