import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import * as Actions from '../../actionTypes';
import copyRepositoryTemplate from "../../actionCreators/copyRepositoryTemplate";
import putNewStylesheet from "../../actionCreators/putNewStylesheet";
import putNewTemplate from "../../actionCreators/putNewTemplate";
// import getRepositoryPage from "../../actionCreators/getRepositoryPage";
import getDesignPage from "../../actionCreators/getDesignPage";
import getDesignSiteData from "../../actionCreators/getDesignSiteData";
import getDesignSiteSettings from "../../actionCreators/getDesignSiteSettings";
import copyObjectAndReplaceWithNewUUIDs from "../../../../utils/copyObjectAndReplaceWithNewUUIDs";
import getPageGoogleFonts from "../../../Fonts/getPageGoogleFonts";
import { valueActionType as siteDataVAT } from '../../../App/epics/siteData/valueActionType';
import { makeNewPageRef } from "../../../PagesTree/utils/index";
import {
    SITE_DATA_UPDATE_REQUEST,
    SITE_DATA_UPDATE_SUCCESS,
    SITE_DATA_UPDATE_FAILURE,
    UPGRADE_SUCCESS,
    SITE_SETTINGS_DOCUMENTS_UPDATE_REQUEST,
    SITE_SETTINGS_DOCUMENTS_UPDATE_SUCCESS,
    SITE_SETTINGS_DOCUMENTS_UPDATE_FAILURE,
} from "../../../App/actionTypes";
import { optionalReceiveOnly, receiveOnly, reset, optional } from "../../../../epics/makeCondition";
import { DataSite } from "../../../../../dal/model/index";
import { NewComponentTypes } from "../../../../../dal/pageMapAdapter/componentTypesMap";
import { prepareNewPersistentModel, preparePersistentModel } from "../../../../../dal/model/utils/index";
import { saveSiteDataAction } from "../../../App/epics/siteData/actionCreators";
import loadPageAction from "../../../PagesTree/actionCreators/loadPageAction";
import MenuTypeOld from '../../../oneweb/Menu/constants/MenuTypeOld';
import { makeUuid } from "../../../../../utils/makeUuid";
import currentPageIdValueActionType from '../../../App/epics/currentPageId/valueActionType';
import { closeDialog, openDialog } from "../../../App/actionCreators/index";
import { ImportErrorDialogId } from "../../view/dialogs/importErrorDialog";
// import templatesListVAT from '../templatesList/valueActionType';
import { sendEventToAec, sendShownErrorEventToAecAC } from "../../../App/actionCreators/sendEventToAecActionCreator";
import { showWelcomeMessageOnTemplateImportKey } from "../../../WelcomeMessage/epic/index";
import LocalStorage from "../../../../utils/localStorage";
import {
    isConflictErrorAction,
} from '../../../../redux/middleware/api/errorHandler/conflictDialog/isConflictErrorAction';
import { fixSocialComponentsLinks } from '../../../oneweb/Social/fixSocialComponentsLinks';
import { SiteSettingsSelector } from '../../../App/epics/siteSettings/selectorActionTypes';
import { globalVariablesFrom } from '../../../App/epics/globalVariablesEpic';
import registry from '../../../../view/oneweb/registry/index';
import {
    generalGlobalDataEpic,
} from '../../../SiteSettings/General/generalGlobalDataEpic/generalGlobalDataEpic';
import { replaceTagsWithContent } from '../../../oneweb/Text/view/replaceTagsWithContent';
import { TemplateSelectorTemplateLocale2Selector } from '../templateSelectorTemplateLocaleEpic';
import { mapPageDataToComponentsMap } from '../../../../../dal/pageMapAdapter/index';
import DataPageSet from '../../../../../dal/model/DataPageSet';
import { getDesignLocalizations } from '../../actionCreators/getDesignLocalizations';
import { isWsbDemo } from "../../../../debug/isWsbDemo";
import { DATA_PAGE_REF_TYPE, DATA_LINK_PAGE_TYPE, DATA_LINK_SECTION_TYPE } from '../../../../../dal/model/utils/dataSiteItemTypes';
import makeSectionLink from '../../../PagesTree/sectionLinkForm/utils/makeSectionLink';
import { ImportTemplateErrorActions } from "./importTemplateErrorActions";
import { addInMemoryThemeChangesToStylesheets } from "../../../ThemeGlobalData/utils/processDataForThemePreview";
import { ADDRESS_KIND, EMAIL_KIND, OPENING_HOURS_KIND, PHONE_KIND } from '../../../oneweb/componentKinds';
import { clearGptDataInUrl } from '../../../Onboarding/Dynamic/Epic/utils';

// TODO: Cleanup. This won't be required
const changeMenuLevel = (obj) => ({
    ...obj,
    items: obj.items.map(cmp => (cmp.type === MenuTypeOld ? { ...cmp, level: (cmp.level - 2) } : cmp))
});

const getPageRefIds = (pageItems, uidDict) => {
    let pageRefIdsDict = {};
    pageItems.forEach(item => {
        if (item.type !== DATA_PAGE_REF_TYPE) {
            return;
        }
        pageRefIdsDict[item.id] = (uidDict.oldToNew[item.id] || makeUuid());
        if (item.items.length) {
            pageRefIdsDict = { ...pageRefIdsDict, ...getPageRefIds(item.items, uidDict) };
        }
    });
    return pageRefIdsDict;
};

const getAllPageIds = (items, uidDict) => {
    let allPagesIds: any[] = [], pageIdsDict = {};
    items.forEach(item => {
        if (item.type !== DATA_PAGE_REF_TYPE) {
            return;
        }
        if (item.public) {
            allPagesIds.push(item.pageId);
        }
        pageIdsDict[item.pageId] = (uidDict.oldToNew[item.pageId] || makeUuid());
        if (item.items.length) {
            const result = getAllPageIds(item.items, uidDict);
            allPagesIds = [...allPagesIds, ...result.allPagesIds];
            pageIdsDict = { ...pageIdsDict, ...result.pageIdsDict };
        }
    });
    return { allPagesIds, pageIdsDict };
};

const prepareSaveInput = (
    // items,
    designSiteData,
    site,
    newPagesMap,
    pageIdsDict,
    pageRefIdsDict,
    parentPageIdInp,
    allPagesIds,
    template,
    stylesheet,
    globalVariables,
    uidDictPerPage,
) => {
    const items = designSiteData.folder.items;
    let list = items.slice(), childParentMap = {},
        updatedSite = {
            ...site,
            folder: {
                ...site.folder,
                items: site.folder.items.slice()
            },
            fonts: site.fonts.slice()
        };
    updatedSite = new DataSite(updatedSite);
    let parentPageRef;
    if (parentPageIdInp) {
        parentPageRef = updatedSite.getPageRefByPageId(parentPageIdInp);
    }
    let addedPages: any[] = [];
    for (let i = 0; i < list.length; i++) {
        const importedItem = list[i];
        if (importedItem.type === DATA_PAGE_REF_TYPE) {
            if (importedItem.public) {
                let page = newPagesMap[pageIdsDict[importedItem.pageId]];
                parentPageRef = childParentMap[importedItem.pageId] || parentPageRef;
                let parenPageId = parentPageRef && parentPageRef.pageId,
                    pageRef = makeNewPageRef(
                        page,
                        updatedSite,
                        parenPageId,
                        pageRefIdsDict[importedItem.id],
                        importedItem,
                        globalVariables
                    );
                addedPages.push(prepareNewPersistentModel(page));
                if (parentPageRef) {
                    parentPageRef.items.push(pageRef);
                } else {
                    updatedSite.folder.items.push(pageRef);
                }
                updatedSite.fonts.push(...getPageGoogleFonts(page));
                if (importedItem.items.length) {
                    for (let j = 0; j < importedItem.items.length; j++) {
                        const id = importedItem.items[j].type === DATA_PAGE_REF_TYPE
                            ? importedItem.items[j].pageId : importedItem.items[j].id;
                        childParentMap[id] = pageRef;
                        list.push(importedItem.items[j]);
                    }
                }
            }
        } else if (importedItem.type === DATA_LINK_SECTION_TYPE) {
            const newPageId = pageIdsDict[importedItem.pageId];
            const sectionLink = makeSectionLink({
                ...importedItem,
                pageId: newPageId,
                sectionId: uidDictPerPage[newPageId][importedItem.sectionId] || importedItem.sectionId,
            }, updatedSite, globalVariables);
            parentPageRef = childParentMap[importedItem.id] || parentPageRef;
            if (parentPageRef) {
                parentPageRef.items.push(sectionLink);
            } else {
                updatedSite.folder.items.push(sectionLink);
            }
        } else if (importedItem.type === DATA_LINK_PAGE_TYPE) {
            parentPageRef = childParentMap[importedItem.id] || parentPageRef;
            if (parentPageRef) {
                parentPageRef.items.push({ ...importedItem });
            } else {
                updatedSite.folder.items.push({ ...importedItem });
            }
        }
    }
    if (template) {
        updatedSite.fonts.push(...getPageGoogleFonts(template));
    }
    if (stylesheet) {
        updatedSite.fonts.push(...getPageGoogleFonts(stylesheet));
    }
    //remove duplicates from font list
    let fontsMap = {}, actualFonts: any[] = [];
    updatedSite.fonts.forEach(font => {
        if (!fontsMap[font] && font) {
            fontsMap[font] = true;
            actualFonts.push(font);
        }
    });
    updatedSite.fonts = actualFonts;
    let newSite = preparePersistentModel(updatedSite);
    // set home page if previously site has no page
    if (!site.getAllPageRefs().length) {
        newSite.homePageId = pageIdsDict[allPagesIds[0]];
    }
    return {
        site: newSite,
        addedPages
    };
};

const isTextLikeCmp = (item) => [ADDRESS_KIND, PHONE_KIND, EMAIL_KIND].includes(item.type);

const changeComponentDataBeforeImport = (page, globalVariables, site, template, stylesheet, hasSeparateLang = false) => {
    const pageDataSet = new DataPageSet({ page, template, stylesheet });
    const componentsMap = mapPageDataToComponentsMap(pageDataSet, site, false, true, globalVariables);

    return {
        ...page,
        items: page.items.map(item => {
            const record = registry[NewComponentTypes[item.type]];
            if (!record || !record.changeOldComponentDataBeforeImport) return item;
            if (hasSeparateLang && item.type === OPENING_HOURS_KIND) return item;
            if (hasSeparateLang && isTextLikeCmp(item)) {
                // eslint-disable-next-line no-param-reassign
                item.generic = {
                    ...item.generic,
                    customPrefixText: replaceTagsWithContent(item.generic.customPrefixText, { globalVariables })
                };
                return item;
            }
            // @ts-ignore
            return record.changeOldComponentDataBeforeImport({
                component: item,
                globalVariables,
                site,
                template,
                componentsMap
            });
        })
    };
};

const saveRequestOnAllDataReceived = (state, generalGlobalData) => {
    const {
        // items, site, siteSettings, allPagesIds, newPagesMap, pageIdsDict,
        designSiteData, site, siteSettings, allPagesIds, newPagesMap, pageIdsDict,
        styleSheetPutSuccess, template, templatePutSuccess, uidDict, getDesignSiteSettingsSuccess,
        parentPageId, pageRefIdsDict, stylesheet, designLocalizations, designSiteSettings, hasSeparateLang
    } = state;

    const allPagesDataReceived = allPagesIds && allPagesIds.every(pageId => !!newPagesMap[pageIdsDict[pageId]]);
    const uidDictPerPage = {};
    if (
        allPagesDataReceived &&
        styleSheetPutSuccess &&
        templatePutSuccess &&
        getDesignSiteSettingsSuccess
    ) {
        const globalVariables = globalVariablesFrom({
            generalGlobalData,
            locale: template.locale,
            i18n: designLocalizations
        });

        let updatedUidDict = { ...uidDict, ...pageIdsDict, ...pageRefIdsDict },
            updatedPagesMap = Object.keys(newPagesMap).reduce((acc, id) => {
                const { copiedObject, updatedUidDict: newUidDict } =
                    copyObjectAndReplaceWithNewUUIDs(newPagesMap[id], updatedUidDict, true);
                acc[id] = copiedObject;
                uidDictPerPage[id] = newUidDict;
                acc[id] = fixSocialComponentsLinks(siteSettings, acc[id]);
                acc[id] = changeComponentDataBeforeImport(acc[id], globalVariables, site, template, stylesheet, hasSeparateLang);
                // TODO localize menu more button
                return acc;
            }, {});

        // All pages data is received and template and stylesheet is saved
        const
            saveSiteDataInput = prepareSaveInput(
                // items,
                designSiteData,
                site,
                updatedPagesMap,
                pageIdsDict,
                pageRefIdsDict,
                parentPageId,
                allPagesIds,
                template,
                stylesheet,
                globalVariables,
                uidDictPerPage,
            ),
            multipleActionsToDispatch: Action[] = [
                saveSiteDataAction({ saveSiteDataInput })
            ],
            addedPages = saveSiteDataInput.addedPages;

        if (addedPages && addedPages.length) {
            multipleActionsToDispatch.push({
                type: Actions.PAGE_OR_TEMPLATE_IMPORT_BEFORE_SAVE,
                payload: {
                    pages: [...addedPages, template],
                    emptySiteImport: site.getAllPageRefs().length === 0,
                    designSiteSettings,
                }
            });
        }

        return {
            state,
            multipleActionsToDispatch
        };
    } else {
        return { state };
    }
};

const ROGeneralGlobalData = receiveOnly(generalGlobalDataEpic.valueActionType);

export default makeEpic({
    defaultState: null,
    valueActionType,
    updaters: [
        {
            conditions: [
                receiveOnly(siteDataVAT),
                Actions.TEMPLATE_SELECTOR_IMPORT_TEMPLATE_CLICK
            ],
            reducer: ({ values: [siteData, template] }) => {
                // TODO: Rename template to design wherever that change is relevant
                const design = template;

                const
                    // needForUpgrade = template && template.isPremium &&
                    //needForUpgrade = design && design.isPremium && isNormalSubscription(subscription.subscriptionType),
                    multipleActionsToDispatch = [{ type: Actions.TEMPLATE_SELECTOR_IMPORT_TEMPLATE }];

                if (siteData.folder.items.length === 0) {
                    LocalStorage.set(showWelcomeMessageOnTemplateImportKey, "yes", true);
                }

                return {
                    state: { // clearing up state on each import start
                        design
                    },
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [
                UPGRADE_SUCCESS
            ],
            reducer: ({ state }) => {
                // if (state && state.template) {
                if (state && state.design) {
                    return {
                        state,
                        multipleActionsToDispatch: [
                            closeDialog(),
                            { type: Actions.TEMPLATE_SELECTOR_IMPORT_TEMPLATE }
                        ]
                    };
                }
                return { state };
            }
        },
        {
            conditions: [
                receiveOnly(siteDataVAT),
                receiveOnly(SiteSettingsSelector),
                optionalReceiveOnly(currentPageIdValueActionType),
                Actions.TEMPLATE_SELECTOR_IMPORT_TEMPLATE
            ],
            // reducer: ({ values: [site, siteSettings, currentPageId], state: { template } }) => {
            //     const { templateId, allPageIds, items } = template;
            reducer: ({ values: [site, siteSettings, currentPageId], state: { design } }) => {
                let parentPageId;
                if (currentPageId) {
                    let selectedPageRefParent = site.getPageRefByPageId(currentPageId);
                    parentPageId = site.getPageRefParentPageId(selectedPageRefParent.id);
                }
                if (isWsbDemo()) {
                    window.sessionStorage.setItem(showWelcomeMessageOnTemplateImportKey, "yes");
                }
                return {
                    // state: { site, siteSettings, template, templateId, allPageIds, items, parentPageId },
                    state: { site, siteSettings, design, parentPageId },
                    // actionToDispatch: copyRepositoryTemplate(templateId)
                    actionToDispatch: copyRepositoryTemplate(design.accountName, design.templateIds)
                };
            }
        },
        {
            conditions: [
                Actions.COPY_REPOSITORY_TEMPLATE_SUCCESS,
                optionalReceiveOnly(currentPageIdValueActionType),
            ],
            reducer: ({
                values: [
                    response,
                    currentPageId
                ],
                state
            }) => {
                const
                    { uidDict } = response,
                    stylesheet = prepareNewPersistentModel(response.stylesheet),
                    template = prepareNewPersistentModel(response.template),
                    accountName = state.design.accountName,
                    multipleActionsToDispatch = [
                        getDesignSiteData(accountName),
                        getDesignLocalizations(accountName),
                    ];
                let getDesignSiteSettingsSuccess = true;
                if (!currentPageId) {
                    multipleActionsToDispatch.push(getDesignSiteSettings(accountName));
                    getDesignSiteSettingsSuccess = false;
                }

                return {
                    state: {
                        ...state,
                        uidDict,
                        stylesheet,
                        template,
                        getDesignSiteSettingsSuccess,
                    },
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [
                receiveOnly(TemplateSelectorTemplateLocale2Selector),
                // receiveOnly(templatesListVAT),
                receiveOnly(SiteSettingsSelector),
                ROGeneralGlobalData,
                receiveOnly(siteDataVAT),
                receiveOnly(reset(Actions.TEMPLATE_SELECTOR_IMPORT_TEMPLATE_CLICK)),
                Actions.GET_DESIGN_SITE_DATA_SUCCESS,
                Actions.GET_DESIGN_LOCALIZATIONS_SUCCESS,
            ],
            reducer: ({
                // values: [response, locale2, { allTemplates }, siteSettings, generalGlobalData, site],
                values: [
                    locale2,
                    siteSettings,
                    generalGlobalData,
                    site,
                    { themeColors }, // eslint-disable-line no-unused-vars
                    responseDesignSiteData,
                    designLocalizations
                ],
                state
            }) => {
                const {
                    uidDict,
                    stylesheet: actualStylesheet
                } = state;

                let stylesheet = actualStylesheet;
                if (themeColors) {
                    stylesheet = addInMemoryThemeChangesToStylesheets(stylesheet, themeColors);
                }
                const { items } = responseDesignSiteData.folder;

                // const selectedTemplateSiteData =
                //     allTemplates.find(temp => temp.templateId === state.template.templateId);

                const pageRefIdsDict = getPageRefIds(items, uidDict);
                const { allPagesIds, pageIdsDict } = getAllPageIds(items, uidDict);

                let { copiedObject: template } =
                    copyObjectAndReplaceWithNewUUIDs(
                        // prepareNewPersistentModel(changeMenuLevel(responseDesignSiteData.template)),
                        state.template,
                        { ...pageIdsDict, ...pageRefIdsDict, ...uidDict.oldToNew },
                        true,
                        uidDict.newToOld
                    );

                template = fixSocialComponentsLinks(siteSettings, template);

                const globalVariables = globalVariablesFrom({
                    locale: locale2,
                    i18n: designLocalizations,
                    generalGlobalData,
                });
                template = changeComponentDataBeforeImport(template, globalVariables, site, template, stylesheet);
                template = {
                    ...template,
                    locale: locale2,
                    name: replaceTagsWithContent(
                        template.name,
                        { globalVariables, site }
                    )
                };

                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { i18n, ...rest } = template;
                const templateWithoutI18n = rest;

                return {
                    state: {
                        ...state,
                        designSiteData: responseDesignSiteData,
                        designLocalizations,
                        stylesheet,
                        template,
                        uidDict: uidDict.oldToNew,
                        newPagesMap: {},
                        allPagesIds,
                        pageIdsDict,
                        pageRefIdsDict,
                        fonts: []
                    },
                    multipleActionsToDispatch: [
                        putNewStylesheet(stylesheet),
                        putNewTemplate(templateWithoutI18n),
                        ...allPagesIds.map(id => {
                            // return getRepositoryPage(id);
                            return getDesignPage(id, state.design.accountName);
                        })
                    ]
                };
            }
        },
        {
            conditions: [
                Actions.PUT_NEW_STYLESHEET_SUCCESS,
                ROGeneralGlobalData
            ],
            reducer: ({ values: [response, generalGlobalData], state }) => {
                const newState = {
                    ...state,
                    styleSheetPutSuccess: response
                };
                return saveRequestOnAllDataReceived(newState, generalGlobalData);
            }
        },
        {
            conditions: [
                Actions.GET_DESIGN_SITE_SETTINGS_SUCCESS,
                ROGeneralGlobalData
            ],
            reducer: ({ values: [response, generalGlobalData], state }) => {
                const newState = {
                    ...state,
                    getDesignSiteSettingsSuccess: true,
                    designSiteSettings: response,
                };
                return saveRequestOnAllDataReceived(newState, generalGlobalData);
            }
        },
        {
            conditions: [
                Actions.PUT_NEW_TEMPLATE_SUCCESS,
                ROGeneralGlobalData
            ],
            reducer: ({ values: [response, generalGlobalData], state }) => {
                const newState = {
                    ...state,
                    templatePutSuccess: response
                };
                return saveRequestOnAllDataReceived(newState, generalGlobalData);
            }
        },
        {
            conditions: [
                // Actions.GET_REPOSITORY_PAGE_SUCCESS,
                Actions.GET_DESIGN_PAGE_SUCCESS,
                ROGeneralGlobalData
            ],
            reducer: ({ values: [response, generalGlobalData], state }) => {
                const { newPagesMap, pageIdsDict } = state || {};
                if (newPagesMap) {
                    // TODO: Ensure that either it shouldn't be used or it should get used appropriately
                    let page = changeMenuLevel(response),
                        newState = {
                            ...state,
                            newPagesMap: {
                                ...newPagesMap,
                                [pageIdsDict[page.id]]: page
                            }
                        };
                    return saveRequestOnAllDataReceived(newState, generalGlobalData);
                }
                return { state };
            }
        },
        {
            conditions: [SITE_DATA_UPDATE_REQUEST],
            reducer: ({ state }) => ({ state: { ...state, siteDataUpdateRequestInProgress: true } })
        },
        {
            conditions: [
                optional(SITE_DATA_UPDATE_SUCCESS),
                optional(SITE_DATA_UPDATE_FAILURE)
            ],
            reducer: ({ state }) => ({ state: { ...state, siteDataUpdateRequestInProgress: false } })
        },
        {
            conditions: [SITE_SETTINGS_DOCUMENTS_UPDATE_REQUEST],
            reducer: ({ state }) => ({ state: { ...state, siteSettingUpdateRequestInProgress: true } })
        },
        {
            conditions: [
                optional(SITE_SETTINGS_DOCUMENTS_UPDATE_SUCCESS),
                optional(SITE_SETTINGS_DOCUMENTS_UPDATE_FAILURE)
            ],
            reducer: ({ state }) => ({ state: { ...state, siteSettingUpdateRequestInProgress: false } })
        },
        {
            conditions: [
                SITE_DATA_UPDATE_SUCCESS,
                optional(SITE_SETTINGS_DOCUMENTS_UPDATE_SUCCESS),
                optional(SITE_SETTINGS_DOCUMENTS_UPDATE_FAILURE),
            ],
            reducer: ({ state }) => {
                const { allPagesIds, pageIdsDict, template } = state || {},
                    gaEvent = {
                        // TODO: Check if we would want to change the eventCategory
                        eventCategory: 'Template',
                        eventAction: 'select',
                        eventLabel: template && template.name
                    },
                    { eventCategory: category, eventAction: action, eventLabel: opt_label } = gaEvent; // eslint-disable-line

                const processAhead = state &&
                    !state.siteDataUpdateRequestInProgress &&
                    !state.siteSettingUpdateRequestInProgress &&
                    pageIdsDict;

                return processAhead ? {
                    state: null,
                    multipleActionsToDispatch: [
                        { type: Actions.TEMPLATE_IMPORT_COMPLETE, payload: true },
                        loadPageAction(pageIdsDict[allPagesIds[0]]),
                        { type: Actions.HIDE_TEMPLATE_LIST },
                        sendEventToAec({
                            category,
                            action,
                            opt_label
                        })
                    ]
                } : { state };
            }
        },
        ...ImportTemplateErrorActions.map(actionType => ({
            keepFullActions: actionType === SITE_DATA_UPDATE_FAILURE,
            conditions: [actionType],
            reducer: ({ values: [action] }) => {
                // 409 Conflict is taken care of generically by api/errorHandler
                if (isConflictErrorAction(SITE_DATA_UPDATE_FAILURE, action)) {
                    return { state: null };
                }

                return ({
                    state: null,
                    multipleActionsToDispatch: [
                        openDialog(ImportErrorDialogId),
                        sendShownErrorEventToAecAC('Import template failed')
                    ]
                });
            }
        })),
        {
            conditions: [
                receiveOnly(TemplateSelectorTemplateLocale2Selector),
                receiveOnly(SiteSettingsSelector),
                ROGeneralGlobalData,
                receiveOnly(siteDataVAT),
                Actions.DYNAMIC_TEMPLATE_IMPORT_TEMPLATE
            ],
            reducer: ({ values: [
                locale2,
                siteSettings,
                generalGlobalData,
                site,
                { design, previewData, designSiteMap, localizationsByTemplateId, designSiteSettings, themeColors },
            ], state }) => {
                const
                    uidDict = {
                        oldToNew: {},
                        newToOld: {}
                    },
                    templateId = design.templateIds[0],
                    locale = previewData.template.locale,
                    designLocalizations = localizationsByTemplateId[templateId],
                    responseDesignSiteData = designSiteMap,
                    uiDictBefore = {},
                    hasSeparateLang = locale2 !== locale;

                // TODO: check if premium check is required

                if (site.folder.items.length === 0 || isWsbDemo()) {
                    LocalStorage.set(showWelcomeMessageOnTemplateImportKey, "yes", true);
                }

                let designStyleSheet = previewData.stylesheet;
                if (themeColors) {
                    designStyleSheet = addInMemoryThemeChangesToStylesheets(designStyleSheet, themeColors);
                }

                const { copiedObject: stylesheet, updatedUidDict: uiDictLocalAfterStylesheet } =
                    copyObjectAndReplaceWithNewUUIDs(prepareNewPersistentModel(designStyleSheet), uiDictBefore, true);
                const { copiedObject: designTemplate, updatedUidDict: uiDictLocalAfterTemplate } =
                    copyObjectAndReplaceWithNewUUIDs(prepareNewPersistentModel(previewData.template), uiDictLocalAfterStylesheet, true);

                uidDict.oldToNew = { ...uiDictLocalAfterTemplate };
                uidDict.newToOld = Object.keys(uidDict.oldToNew).reduce((acc, key) => {
                    acc[uidDict.oldToNew[key]] = key;
                    return acc;
                }, {});

                const { items } = responseDesignSiteData.folder,
                    pageRefIdsDict = getPageRefIds(items, uidDict),
                    { allPagesIds, pageIdsDict } = getAllPageIds(items, uidDict);

                let { copiedObject: template } =
                    copyObjectAndReplaceWithNewUUIDs(
                        // prepareNewPersistentModel(changeMenuLevel(responseDesignSiteData.template)),
                        designTemplate,
                        { ...pageIdsDict, ...pageRefIdsDict, ...uidDict.oldToNew },
                        true,
                        uidDict.newToOld
                    );

                template = fixSocialComponentsLinks(siteSettings, template);

                const globalVariables = globalVariablesFrom({
                    locale,
                    i18n: designLocalizations, // TODO: designLocalizations
                    generalGlobalData,
                });
                template = changeComponentDataBeforeImport(template, globalVariables, site, template, stylesheet, hasSeparateLang);
                template = {
                    ...template,
                    locale,
                    name: replaceTagsWithContent(
                        template.name,
                        { globalVariables, site }
                    )
                };

                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { i18n, ...rest } = template;
                const templateWithoutI18n = rest;

                const newPagesMap = {};
                previewData.pages.forEach(page => {
                    newPagesMap[pageIdsDict[page.id]] = changeMenuLevel(page);
                });

                clearGptDataInUrl();

                return {
                    state: {
                        ...state,
                        design,
                        site,
                        siteSettings,
                        parentPageId: null,
                        uidDict: uidDict.oldToNew,
                        stylesheet,
                        template,
                        designSiteData: responseDesignSiteData,
                        designSiteSettings,
                        designLocalizations,
                        newPagesMap,
                        allPagesIds,
                        pageIdsDict,
                        pageRefIdsDict,
                        fonts: [],
                        getDesignSiteSettingsSuccess: true,
                        siteDataUpdateRequestInProgress: false,
                        siteSettingUpdateRequestInProgress: false,
                        hasSeparateLang
                    },
                    multipleActionsToDispatch: [
                        putNewStylesheet(stylesheet),
                        putNewTemplate(templateWithoutI18n),
                    ]
                };
            }
        }
    ]
});
