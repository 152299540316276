import type { SideBarItemDef } from '../../../SideBar/types';

export const SECURITY_PAGE_SIDE_BAR_ITEM_ID = 'SECURITY_PAGE_SIDE_BAR_ITEM_ID';

export const SecuritySideBarItem: SideBarItemDef = {
    id: SECURITY_PAGE_SIDE_BAR_ITEM_ID,
    title: 'msg: common.security {Security}',
    header: {
        title: 'msg: common.security {Security}',
        description: 'msg: securityTab.header.label {These settings keep your website secure and improve the way your address appears in the browser.}'
    }
};
