import { put } from 'redux-saga/effects'; // eslint-disable-line node/file-extension-in-import
import { DataSite } from "../../../../../dal/model";
import type { LinkPageFormResult } from "../../linkPageForm/flowTypes";
import { preparePersistentModel } from "../../../../../dal/model/utils";
import { makeLinkPage } from "../../linkPageForm/utils";
import updateSiteDataSaga from "../../saga/updateSiteDataSaga";
import { closePersistentDialog } from "../../../App/actionCreators";
import { insertPageToSiteData } from "../../utils";
import { receiveOnly } from '../../../../epics/makeCondition';
import makeResolveWhenFulfilledSaga from "../../../../epics/makeResolveWhenFulfilledSaga";
import { siteDataReceiveOnlyExternalValueActionType } from '../../../App/epics/siteData/valueActionType';
import currentPageIdValueActionType from '../../../App/epics/currentPageId/valueActionType';
import { NEW_PAGE_DIALOG_OPEN, NEW_PAGE_LINK_ADD } from "../actions";
import type { NewPageDialogPayload } from "../flowTypes";

const addLinkPageSagaWorker = function* (
    site: DataSite,
    currentPageId: string,
    newPageDialogPayload: NewPageDialogPayload,
    input: LinkPageFormResult
): Generator<any, void, any> {
    const
        newLinkPage = makeLinkPage(input, site),
        selectedPageRefParent = site.getPageRefByPageId(currentPageId),
        selectedPageId = newPageDialogPayload?.selectedPage?.pageId,
        parentPageId = selectedPageId || site.getPageRefParentPageId(selectedPageRefParent.id),
        newSite = preparePersistentModel(insertPageToSiteData(newLinkPage, site, parentPageId));
    yield* updateSiteDataSaga({ site: newSite });

    yield put(closePersistentDialog());
};

const listener = makeResolveWhenFulfilledSaga(
    siteDataReceiveOnlyExternalValueActionType,
    receiveOnly(currentPageIdValueActionType),
    NEW_PAGE_DIALOG_OPEN,
    NEW_PAGE_LINK_ADD,
    addLinkPageSagaWorker
);

export {
    listener as default,
    addLinkPageSagaWorker
};
