export const workspaceStylesheet = {
    styles: [
        {
            type: "web.data.styles.StyleText",
            id: "AC611279-595C-462A-AF62-30A69E6D62A1",
            name: "[paragraph]",
            ref: "normal",
            font: "google://Open Sans",
            size: 16,
            bold: false,
            italic: false,
            underline: false,
            color: [
                "RGB",
                0.1450980392156863,
                0.1450980392156863,
                0.1450980392156863,
                1
            ]
        },
        {
            type: "web.data.styles.StyleText",
            id: "AC611279-595C-462A-AF62-30A69E6D62H3",
            name: "[heading.3]",
            ref: "heading.3",
            font: "google://Open Sans",
            size: 16,
            bold: false,
            italic: false,
            underline: false,
            color: [
                "RGB",
                0.1450980392156863,
                0.1450980392156863,
                0.1450980392156863,
                1
            ]
        },
        {
            type: 'web.data.styles.StyleLink',
            ref: 'blah',
            name: '[blah]',
        },
        {
            type: "web.data.styles.StyleCell",
            id: "3B335BBB-C4D0-4C97-A255-926FFB94B4AA",
            name: "[normal]",
            ref: "normal",
            block: {
                border: null,
                background: {
                    color: [
                        "HSL",
                        0,
                        0,
                        0.9215686274509803,
                        1
                    ],
                    gradient: null,
                    asset: null,
                    repeat: null,
                    position: null,
                    size: null,
                },
                padding: [
                    3,
                    3,
                    3,
                    3
                ]
            },
            text: {
                font: "google://Arimo",
                size: 14,
                bold: false,
                italic: false,
                underline: false,
                color: [
                    "HSL",
                    0,
                    0,
                    0,
                    1
                ],
                shadow: false
            },
            verticalAlign: "middle",
            horizontalAlign: "left",
        }
    ]
};
