import { epicStateAppSel } from "../../../../epics/selectors";
import { templateSelectorPreviewVAT } from './valueActionType';
import { makeEpicStateSelector } from '../../../../epics/makeEpic';

const templateSelectorPreviewEpicSelector = makeEpicStateSelector(templateSelectorPreviewVAT),
    selectedColorPickerTypeSelector = epicStateAppSel(templateSelectorPreviewVAT, ['selectedColorPickerPalette', 'type']),
    templateSelectorPreviewStateSelector = (state) => templateSelectorPreviewEpicSelector(state),
    isNextGenPreviewSelector = (state) => templateSelectorPreviewStateSelector(state).isNextGenPreview;

export {
    selectedColorPickerTypeSelector,
    templateSelectorPreviewStateSelector,
    isNextGenPreviewSelector
};
