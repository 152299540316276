// @ts-nocheck

import { makeActionForwardToSelectedComponent } from "../../../../../redux/forwardTo";
import {
    SELECT_UPDATE_HOVER_BOX_STATE,
    SET_HOVER_BOX_MODE
} from "../../../../../components/oneweb/HoverBox/actionTypes";

export const
    setHoverBoxModeAsHover = (val: boolean) => makeActionForwardToSelectedComponent({
        type: SET_HOVER_BOX_MODE,
        payload: val
    }),
    selectHoverBoxAndSetState = (hoverBoxId: string, hoverMode: boolean) => ({
        type: SELECT_UPDATE_HOVER_BOX_STATE,
        payload: { hoverBoxId, hoverMode }
    });
