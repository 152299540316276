import { applyMappers, makePlainMappers } from "../../utils";
import type { AnyComponentData } from "../../../../src/components/App/flowTypes";
import type { DocumentComponent } from "../../../../src/components/oneweb/Document/flowTypes";

const plainPropsMapper = makePlainMappers({
    settings: 'settings',
    mobileHide: 'mobileHide'
});

export const to = (componentData: AnyComponentData): DocumentComponent => applyMappers(
    componentData,
    plainPropsMapper.to
);

export const back = (component: DocumentComponent): AnyComponentData => applyMappers(
    component,
    plainPropsMapper.back
);
