import Icons from '../../../view/Icons/index';
import * as VerticalAlign from './VerticalAlign';
import * as HorizontalAlign from './HorizontalAlign';

export const alignments = {
    LEFT_TOP: [Icons.LEFT_TOP, VerticalAlign.TOP, HorizontalAlign.LEFT],
    TOP: [Icons.TOP, VerticalAlign.TOP, HorizontalAlign.CENTER],
    RIGHT_TOP: [Icons.RIGHT_TOP, VerticalAlign.TOP, HorizontalAlign.RIGHT],
    LEFT: [Icons.LEFT, VerticalAlign.MIDDLE, HorizontalAlign.LEFT],
    CENTER_BOX: [Icons.CENTER_BOX, VerticalAlign.MIDDLE, HorizontalAlign.CENTER],
    RIGHT: [Icons.RIGHT, VerticalAlign.MIDDLE, HorizontalAlign.RIGHT],
    LEFT_BOTTOM: [Icons.LEFT_BOTTOM, VerticalAlign.BOTTOM, HorizontalAlign.LEFT],
    BOTTOM: [Icons.BOTTOM, VerticalAlign.BOTTOM, HorizontalAlign.CENTER],
    RIGHT_BOTTOM: [Icons.RIGHT_BOTTOM, VerticalAlign.BOTTOM, HorizontalAlign.RIGHT],
};

export default [
    [
        alignments.LEFT_TOP,
        alignments.TOP,
        alignments.RIGHT_TOP
    ],
    [
        alignments.LEFT,
        alignments.CENTER_BOX,
        alignments.RIGHT,
    ],
    [
        alignments.LEFT_BOTTOM,
        alignments.BOTTOM,
        alignments.RIGHT_BOTTOM,
    ]
];
