import { partialRight, evolve, map } from 'ramda';
import propertiesPanel from "./propertiesPanel/index";
import kind from './kind';
import reducer from './reducer/index';
import workspace from './view/workspace';
import calcRenderProps from './calcRenderProps';
import { tableAdjustmentHookConfig } from './adjustmentHookConfig';
import view from './view/index';
import componentMainActions from './componentMainActions/index';
import adjustComponentOnAdd from "./adjustComponentOnAdd";
import adjustComponentOnPaste from "./adjustComponentOnPaste";
import fontStatusReducer from '../../Fonts/fontStatusReducer';
import stylesheetsEpic from '../../Workspace/epics/stylesheets/index';
import stylesheetsIdToNameMapEpic from '../../Workspace/epics/stylesheets/idToNameMap';
import siteDataEpic from "../../App/epics/siteData/index";
import siteFontsReducer from '../../Fonts/siteFontsReducer';
import colorPickerEpic from '../../ColorPicker/epic/index';
import colorPickerEpicValueActionType from '../../ColorPicker/epic/valueActionType';
import makeStateSelectorReducer from "../../../epics/makeStateSelectorReducer";
import { tableEditModeEpic } from "./epics/tableEditMode/index";
import { selectedTextStateEpic } from "../Text/epics/selectedTextStateEpic/index";
import { tinyMceStateEpic } from "../../App/epics/tinyMceEpic/tinyMceStateEpic";
import previewConfig from "./previewConfig";
import { ComponentNames } from "../constants";
import { globalVariablesEpic } from "../../App/epics/globalVariablesEpic";
import { replaceTagsWithContentDOM } from "../Text/view/replaceTagsWithContentDOM";
import { tableFirstCellReductionWidth } from "./constants";
import type { CmpSpecificStyles } from "../../Preview/flowTypes";
import type { TableComponent } from "./flowTypes";
import { colorThemeSiteSettingsEpic } from "../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";

export default {
    kind,
    label: ComponentNames[kind],
    shortcutIconName: 'table',
    view,
    workspace,
    adjustmentHookConfig: tableAdjustmentHookConfig,
    calcRenderProps,
    propertiesPanel,
    reducer,
    componentMainActions,
    adjustComponentOnAdd,
    adjustComponentOnPaste,
    dependsOn: {
        globalVariables: globalVariablesEpic.reducer,
        stylesheets: stylesheetsEpic.reducer,
        stylesheetsIdToNameMap: stylesheetsIdToNameMapEpic.reducer,
        tableEditModeState: tableEditModeEpic.reducer,
        site: siteDataEpic.reducer,
        themeColorsData: makeStateSelectorReducer(
            stylesheetsEpic.reducer,
            stylesheetsEpic.valueActionType,
            getThemeColorsFromStylesheet
        ),
        themeSettingsData: colorThemeSiteSettingsEpic.reducer,
    },
    controlsDependsOn: {
        globalVariables: globalVariablesEpic.reducer,
        siteFonts: siteFontsReducer,
        fontStatus: fontStatusReducer,
        colorPickerOpened: makeStateSelectorReducer(
            colorPickerEpic.reducer,
            colorPickerEpicValueActionType,
            state => state.opened
        ),
        selectedTextState: selectedTextStateEpic.reducer,
        tinyMceState: tinyMceStateEpic.reducer
    },
    mobileEditorConfig: {
        ...previewConfig,
        getSpecificStyles: ({ component, widthInMobile }: CmpSpecificStyles<TableComponent>) => {
            let styles = '';
            const { id, cells, width } = component,
                cols = cells.filter(cell => cell.cellInfo.rowIndex === 0),
                firstCell = cells.find(cell => cell.cellInfo.colIndex === 0),
                firstColWidth = firstCell && firstCell.cellInfo.flexWidth * width;
            if (
                firstCell &&
                firstColWidth &&
                cols.length > 1 &&
                (firstColWidth + tableFirstCellReductionWidth) > widthInMobile
            ) {
                const newColWidth = widthInMobile - tableFirstCellReductionWidth;
                let newTableWidth = width;
                cols.forEach(() => {
                    newTableWidth = newTableWidth - (firstColWidth - newColWidth);
                });
                styles += '.mobileV div[data-id="' + id + '"] tr td { word-break: break-all; }\n';
                styles += '.mobileV div[data-id="' + id + '"] table { width: ' + newTableWidth + 'px !important; }\n';
                styles += '.mobileV div[data-id="' + id +
                    '"] colgroup col { width: ' + newColWidth + 'px !important; }\n';
            }
            return styles;
        }
    },
        changeOldComponentDataBeforeImport: ({ component, globalVariables, site, }) => evolve({
        cellsData: map(
            evolve({
                content: partialRight(replaceTagsWithContentDOM, [{ globalVariables, site }, true]),
            })
        ),
    })(component),
};
