import React from 'react';
import { injectIntl, Intl } from "../../../../view/intl/index";
import Locations from "../locationTypes";
import { NoMouseUpDownAndMove } from "../../../../view/common/NoMouseEventsPropagation";
import { propPanelNavigateToPageAC } from "../../../PropertiesPanel/actionCreators";
import * as pageIds from "../propertiesPanel/pages/ids";
import { COMPONENT_EDIT } from "../../../../redux/modules/children/workspace/actionTypes";
import { SET_SELECTED_COMPONENTS } from "../../../Workspace/epics/componentsEval/actionTypes";
import type { CodeComponent } from '../flowTypes';
import styles from './Code.css';

type ViewProps = {
    component: CodeComponent;
    intl: Intl;
    dispatch: Dispatch;
    style ?: Record<string, any>;
}

const View = injectIntl((props: ViewProps) => {
    const { component, intl, style = {}, dispatch } = props;
    const onClickCodeTemplateBadge = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch({ type: SET_SELECTED_COMPONENTS, payload: [component.id] });
        setTimeout(() => {
            dispatch({ type: COMPONENT_EDIT });
            dispatch(propPanelNavigateToPageAC(pageIds.PLACEMENT));
        });
    };
    return (
        <div className={styles.container} style={style.container}>
            {(component.location === Locations.BeforeClosingBody
                || component.location === Locations.BeforeClosingHead)
            && component.inTemplate
            && <div
                className={styles.codeTemplateBadge}
                {...NoMouseUpDownAndMove}
                onClick={onClickCodeTemplateBadge}
                data-title={intl.msgJoint('msg: code.appliedToAllPages {Applied to all pages}')}
                data-title-position="right"
            />}
            <div className={styles.valign}>
                <div className={styles.codeIcon} />
                <div className={styles.title}>{component.name}</div>
                <div className={styles.codeText} style={style.text}>
                    {intl.msgJoint('msg: component.code.label {Code}')}
                </div>
            </div>
        </div>
    );
});

export default View;
