import { ComponentNames } from "../../constants";
import FacebookFeedGallery from "../kind";

export const
    DialogWidth = 591,
    DialogHeight = 506,
    PopupWindowWidth = 973,
    PopupWindowHeight = 798,
    ConfigDialogSettings = {
        TITLE: ComponentNames[FacebookFeedGallery],
        MESSAGE: 'msg: component.facebookFeedFeed.configDialog.message {Choose your account type and connect to add your Facebook feed gallery to your site.}', // eslint-disable-line max-len
        MESSAGE2: 'msg: component.facebookFeedFeed.configDialog.message2 {If your account is private, then FacebookFeed requires you to reconnect every 60 days. We’ll let you know when you need to do this.}', // eslint-disable-line max-len
        CONNECT_LABEL: 'msg: component.facebookFeedFeed.configDialog.addSaveLabel {Connect Facebook}',
        DISCONNECT_LABEL: 'msg: component.facebookFeedFeed.configDialog.updateSaveLabel {Disconnect Facebook}'
    };
