import React from "react";
import cx from "classnames";
import * as captchaConstants from "./constants";
import * as styles from './captcha.css';

export default ({ isServerPreview, textStyle, autoColorMode, captchaLang }) => {
    return (!isServerPreview ? <div className={styles.frcCaptcha} data-attached="1">
        <div className={styles.frcContainer}>
            {/* The below SVG is used for static rendering of FC Icon in workspace and client side preview.
                Since intl is not available in preview we don't use Icons component.*/}
            <svg
                className={styles.frcIcon}
                aria-hidden="true"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                height="32"
                width="32"
                viewBox="0 0 24 24"
                style={{ "fill": !autoColorMode ? textStyle.color : "", "stroke": !autoColorMode ? textStyle.color : "" }}
            >
                {/* eslint-disable-next-line max-len */}
                <path d="M17,11c0.34,0,0.67,0.04,1,0.09V6.27L10.5,3L3,6.27v4.91c0,4.54,3.2,8.79,7.5,9.82c0.55-0.13,1.08-0.32,1.6-0.55 C11.41,19.47,11,18.28,11,17C11,13.69,13.69,11,17,11z" />
                <path d="M17,13c-2.21,0-4,1.79-4,4c0,2.21,1.79,4,4,4s4-1.79,4-4C21,14.79,19.21,13,17,13z M17,14.38" />
            </svg>
            {captchaLang && <div className={styles.frcContent}>
                <span className={styles.frcText}>{captchaConstants[captchaLang.split("_")[0].toUpperCase()].text_ready}</span>
                <button
                    type="button"
                    className={styles.frcButton}
                    style={{ "color": !autoColorMode ? textStyle.color : "" }}
                >{captchaConstants[captchaLang.split("_")[0].toUpperCase()].button_start}</button>
            </div>}
        </div>
        <span className={cx(styles.frcBanner, "frc-banner")}>
            <a lang="en" href="https://friendlycaptcha.com/" rel="noopener" target="_blank" style={{ "color": !autoColorMode ? textStyle.color : "" }}>
                <b>Friendly</b>Captcha ⇗
            </a>
        </span>
    </div> : <div id="frcCaptchaCtn" />
    );
};
