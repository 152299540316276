import React from "react";
import { useDispatch } from "react-redux";

import { DataSiteItem } from "../../../../../dal/model/utils/dataSiteItemUtils";
import * as Actions from '../actionTypes';

import styles from './PagesTree.css';

type Props = {
    page: DataSiteItem
}

export const PageSettings = ({ page }: Props) => {
    const dispatch = useDispatch();
    const
        pageSettingsOnClick = e => {
            e.stopPropagation();
            dispatch({
                type: Actions.PAGE_TREE_PAGE_OPEN_CONTEXT_MENU,
                payload: { pageTreeItem: page, position: { x: e.pageX, y: e.pageY } }
            });
        };

    return (
        <span
            className={styles.pageSettings}
            onClick={pageSettingsOnClick}
        >
            <span className={styles.wrench} />
        </span>
    );
};
