import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import MuiTooltip from "@mui/material/Tooltip";
import type { TooltipProps } from "@mui/material/Tooltip";

type Props = TooltipProps & {
    color?: string;
    background?: string;
    width?: string;
};

const useStyles = makeStyles(
    theme => ({
        arrow: {
            color: (props: Props) => props.background || theme.palette.primary.main
        },
        tooltip: {
            backgroundColor: (props: Props) => props.background || theme.palette.primary.main,
            color: (props: Props) => props.color || theme.palette.primary.contrastText,
            width: (props: Props) => props.width
        }
    }),
    { name: "CustomToolTip" }
);

export const Tooltip = (props: Props) => {
    const { arrow = true, children, classes: classesProp, ...remainingProps } = props;

    const defaultClasses = useStyles(props);

    const classes = { ...defaultClasses, ...classesProp };

    return (
        <MuiTooltip
            arrow={arrow}
            classes={classes}
            componentsProps={{
                popper: {
                    disablePortal: true
                }
            }}
            {...remainingProps}
        >
            {children}
        </MuiTooltip>
    );
};
