import parseComponents from './parseComponents';
import type { CalcRenderer } from '../../Workspace/epics/componentsProps/flowTypes';
import type {
    ShareButtonsComponent,
    ShareButtonsComponentExtension,
    ShareButtonsComponentDependsOn,
    ShareButtonsCalcRenderPropsResult
} from './flowTypes';

type ShareButtonsCalcRenderProps = CalcRenderer<
    ShareButtonsComponent,
    ShareButtonsComponentExtension,
    ShareButtonsComponentDependsOn,
    ShareButtonsCalcRenderPropsResult
>

const
    calcRenderProps: ShareButtonsCalcRenderProps = ({
        component: {
            height,
            width,
            showFriends,
            showFacebook,
            showFacebookShare,
            showLinkedIn,
            showTwitter,
            styleInfo,
            verbToDisplay,
            align
        },
        componentDependencies: { locale },
        componentExtension: { isTransient }
    }): ShareButtonsCalcRenderPropsResult => ({
        isTransient,
        height,
        width,
        showFriends,
        showFacebook,
        showFacebookShare,
        showLinkedIn,
        showTwitter,
        styleInfo,
        verbToDisplay,
        align,
        locale,
        onScriptLoaded: parseComponents
    });

export default calcRenderProps;
