import isStretchComponentKind from '../../components/oneweb/isStretchComponentKind';
import type { ComponentsMap, BBox } from "../../redux/modules/children/workspace/flowTypes";
import { emptyArray } from "../handle/makeHandle";

export default (componentsMap: ComponentsMap,
    workspaceBBox: BBox,
    bBox: BBox) => {
    const result = Object.keys(componentsMap)
        .filter(key => {
            const
                component = componentsMap[key],
                isStretch = isStretchComponentKind(component.kind, component.stretch),
                left = isStretch ? workspaceBBox.left : component.left,
                right = isStretch ? workspaceBBox.right : component.left + component.width;

            return (
                component.top >= bBox.top &&
                left >= bBox.left &&
                (component.top + component.height) <= bBox.bottom &&
                right <= bBox.right
            );
        });

    if (result.length === 0) {
        return emptyArray;
    }
    return result;
};
