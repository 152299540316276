
import * as React from "react";
import { pure } from 'recompose';
import styles from './TemplatePagesDialog.css';
import type { TemplatePagesDialogProps } from "../flowTypes";
import StandardDialog from '../../../../view/common/dialogs/StandardDialog/index';
import PreviewTree from "../../../PagesTree/PreviewTree/index";
import { TEMPLATE_PAGES_SELECT_PAGE } from "../actionTypes";
import getStyleIntValue from "../../../../utils/getStyleIntValue";
import { closeDialog } from "../../../App/actionCreators/index";

export const TemplatePagesDialog = pure((props: TemplatePagesDialogProps) => {
    const {
            globalVariables,
            site,
            subscriptionData,
            selectedId: propsSelectedId,
            templatePageRefIds,
            dispatch,
            state: { selectedId: stateSelectedId }
        } = props,
        selectedId = stateSelectedId || propsSelectedId;

    return (
        <StandardDialog
            className={styles.dialog}
            bodyClassName={styles.dialogBody}
            title="msg: template.pagesUsingThisTemplate {Pages using this template}"
            mctaText="msg: common.ok {OK}"
            mctaHandler={() => dispatch(closeDialog())}
            sctaHandler={null}
        >
            <PreviewTree
                globalVariables={globalVariables}
                containerHeight={getStyleIntValue(styles, 'treeHeight')}
                site={site}
                subscriptionData={subscriptionData}
                selectedId={selectedId}
                onSelectAction={TEMPLATE_PAGES_SELECT_PAGE}
                expandedIds={templatePageRefIds}
                autoExpandParent
                accentOnlyPageIds={templatePageRefIds}
                dispatch={dispatch}
            />
        </StandardDialog>
    );
});
