import React from 'react';

import type { AddOnecomTemplateState } from "../types";
import type { AppDispatch } from "../../../../redux/modules/flowTypes";
import StandardDialog from '../../../../view/common/dialogs/StandardDialog';
import LoadingCom from "../../../../view/common/LoadingIndicator";

type Props = {
    state: AddOnecomTemplateState,
    dispatch: AppDispatch,
};

export class AddOnecomTemplateCom extends React.Component<Props> {
    renderGrid() { // eslint-disable-line
        return (
            <div>
                Grid
            </div>
        );
    }

    render() {
        const { state: { isLoading } } = this.props;

        return (
            <StandardDialog
                title="TODO"
                subTitle="TODO"
                mctaText="msg: common.add {Add}"
                mctaHandler={() => console.log('===TODO')}
            >
                {isLoading ? <LoadingCom /> : this.renderGrid()}
            </StandardDialog>
        );
    }
}
