import makeEpic from '../../../../epics/makeEpic';
import valueActionType from "./valueActionType";
import * as types from './types';
import * as fontActionTypes from "../../../Fonts/actionTypes";
import pageDatasetLoadedActionType from "../../../App/epics/pageDataset/pageDatasetLoadedActionType";
import pageDataSetPublicRequestActionType from "../../../App/epics/pageDataset/pageDataSetPublicRequestActionType";
import { WORKSPACE_READY } from "../../actionTypes";
import {
    NEW_PAGE_LAYOUT_ADD, CLOSE_NEW_PAGE_NAME_DIALOG_ACTION,
    LOAD_NEW_PAGE_LAYOUT_TEMPLATE_SET_FAILURE_ACTION
} from "../../../PagesTree/NewPageDialog/actions";
import { SITE_DATA_UPDATE_FAILURE } from "../../../App/actionTypes";
import { PAGE_TREE_PAGE_SELECTED } from "../../../PagesTree/Tree/actionTypes";
import { RESET_CURRENT_PAGE_ID } from "../../../App/epics/currentPageId/actionTypes";
import { CANCEL_UNSAVED_CHANGES } from "../../../UnsavedChanges/actionTypes";
import { PAGE_TREE_LOAD_PAGE } from "../../../PagesTree/actionTypes";

const makeActionTypeToStateUpdater = ({ actionType, state }) => ({
    conditions: [actionType],
    reducer: () => ({ state })
});

const loadingUpdaters = [
    pageDataSetPublicRequestActionType(),
    NEW_PAGE_LAYOUT_ADD,
    PAGE_TREE_PAGE_SELECTED,
    PAGE_TREE_LOAD_PAGE,
    RESET_CURRENT_PAGE_ID // reset happens on page deletion
].map(actionType => makeActionTypeToStateUpdater({ actionType, state: types.LOADING }));

const cancelLoadingUpdaters = [
    CANCEL_UNSAVED_CHANGES,
    CLOSE_NEW_PAGE_NAME_DIALOG_ACTION
].map(actionType => makeActionTypeToStateUpdater({ actionType, state: types.READY }));

const workspaceReadyAfterLoadingUpdaters = [
    SITE_DATA_UPDATE_FAILURE,
    LOAD_NEW_PAGE_LAYOUT_TEMPLATE_SET_FAILURE_ACTION
].map(actionType => ({
    conditions: [actionType],
    reducer: ({ state }) => ({ state: state === types.LOADING ? types.READY : state })
}));

const
    workspaceReadyUpdaters = [
        fontActionTypes.NO_PAGE_FONTS_TO_LOAD,
        fontActionTypes.FONTS_LOADING_SUCCESS
    ].map(action => ({
        conditions: [
            action
        ],
        reducer: () => ({ state: types.READY, actionToDispatch: { type: WORKSPACE_READY } })
    }));

export default makeEpic({
    defaultState: types.UNINITIALIZED,
    valueActionType,
    updaters: [
        ...loadingUpdaters,
        ...cancelLoadingUpdaters,
        makeActionTypeToStateUpdater({
            actionType: pageDatasetLoadedActionType,
            state: types.PREPARING
        }),
        makeActionTypeToStateUpdater({
            actionType: fontActionTypes.FONTS_LOADING_IN_PROGRESS,
            state: types.FONTS_LOADING_IN_PROGRESS
        }),
        makeActionTypeToStateUpdater({
            actionType: fontActionTypes.FONTS_LOADING_FAILED,
            state: types.FONTS_LOADING_FAILED
        }),
        ...workspaceReadyUpdaters,
        ...workspaceReadyAfterLoadingUpdaters
    ]
});
