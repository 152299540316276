import * as actionTypes from "./actionTypes";
import CALL_API from '../../../../redux/middleware/api/CALL_API';
import type { ApiAction } from '../../../../redux/middleware/api/flowTypes';
import type { GmbConnectDataType } from "../flowTypes";

export const connectToThisListing = (params: GmbConnectDataType): ApiAction => ({
    [CALL_API]: {
        types: [
            actionTypes.CONNECT_TO_THIS_LISTING_REQUEST,
            actionTypes.CONNECT_TO_THIS_LISTING_SUCCESS,
            actionTypes.CONNECT_TO_THIS_LISTING_FAILURE
        ],
        endpoint: 'connectWithLocation',
        endpointParams: params
    }
});
