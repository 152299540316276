/* eslint-disable max-len */

import * as R from "ramda";
import React from "react";
import cx from 'classnames';
import { Flex } from '../../../../../view/reflexbox/index';
import { injectIntl } from '../../../../../view/intl/index';
import * as mp from '../../../../../mappers/path';
import Label from "../../../../../view/common/Label";
import GlobalstyleSelector from '../../../../presentational/GlobalstyleSelector/index';
import FontFamilySelector from "../../../../presentational/FontFamily/index";
import { GlobalstyleDialogInvoker, openGlobalStylesDialogAC } from '../../../../presentational/GlobalstyleDialogInvoker/index';
import * as actionTypes from "../../actionTypes";
import * as pageIds from "../pageIds";
import pagesTitles from "../pagesTitles";
import * as selectors from '../../../../Workspace/epics/stylesheets/selectors';
import textGlobalstyleKind from "../../../Text/globalStyle/kind";
import linkGlobalstyleKind from "../../../Link/globalStyle/kind";
import textOptionMapper from "../../../Text/globalStyle/options";
import { linkOptionMapper, createThemeLinkOptions } from "../../../Link/globalStyle/options";
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import addGoogleFontView from '../../../../PropertiesPanel/view/AddGoogleFont/index';
import FontSizeSelector from "../../../../presentational/FontSize/index";
import { DEFAULT_MCTA_COMBOBOX_MENUSTYLE } from '../../../../componentMainActions/constants';
import TextFormatButtonGroup from "../../../../presentational/IconButtonsGroup/TextFormat";
import IconButtonsGroup from "../../../../presentational/IconButtonsGroup/index";
import Icons from "../../../../../view/Icons/index";
import ColorProperty from '../../../../presentational/ColorProperty/index';
import TextShadowView from "../../../../presentational/TextShadow/dialogView";
import TableComponentKind from "../../kind";
import { getFont, getHorizontalAlign, getVerticalAlign, getCellToReadValues, getCellsToReadValues } from "../../utils";
import PropertyContainer from "../../../../../view/common/PropertyContainer/index";
import htmlToJson from "../../../Text/htmlToJson/index";
import { makeTextGlobalStylesNamesMap } from "../../../Text/makeTextGlobalStylesNamesMap";
import {
    TextFullHorizontalAlignmentButtonsGroup,
    TextVerticalAlignmentButtonsGroup
} from "../../../../presentational/IconButtonsGroup/TextAlignmentButtonGroup/index";
import type { PropertyPanelViewProps } from "../../flowTypes";
import { optionRenderer as textOptionRenderer } from "../../../Text/globalStyle/optionRenderer";
import { optionRenderer as linkOptionRenderer } from "../../../Link/globalStyle/optionRenderer";
import { getTableThemeRulesFromBackgroundTheme, themeRulesBackgroundForLinkColor } from '../../../../ThemeGlobalData/themeRules';
import { THEME_COLOR_PICKER, THEME_COLOR_OVERRIDE_COLOR_PICKER } from '../../../../ColorPicker/constants';
import { getStyleById } from '../../../../Workspace/epics/stylesheets/selectors';
import { THEME_LINK_CLASS_LIST, themeunderlined } from '../../../../App/epics/tinyMceEpic/editorUtils/constants';
import { themeColorOptionRenderer } from "../../../Link/globalStyle/themeColorOptionRenderer";
import { toCss } from "../../../../../mappers/color";
import linkStyles from '../../../Text/propertiesPanel/styles.css';

const
    subscriptsBtnDefs = {
        superscript: {
            title: "msg: common.superscript {Superscript}",
            icon: Icons.SUPERSCRIPT,
            onClickAction: actionTypes.TABLE_TEXT_SUPERSCRIPT_TOGGLE
        },
        subscript: {
            title: "msg: common.subscript {Subscript}",
            icon: Icons.SUBSCRIPT,
            onClickAction: actionTypes.TABLE_TEXT_SUBSCRIPT_TOGGLE
        }
    },
    clearTextFormatBtn = [{
        title: "msg: common.clearFormatting {Clear formatting}",
        icon: Icons.CLEARFORMATING,
        onClickAction: actionTypes.TABLE_TEXT_CLEAR_FORMATTING,
        isSelected: false
    }];

const setIsSelected = R.assoc('isSelected');

const
    id = pageIds.TextStyle,
    title = pagesTitles[id],
    view = injectIntl((props: PropertyPanelViewProps) => {
        const {
            selectedComponent,
            tinyMceState,
            tableEditModeState,
            site,
            stylesheets,
            stylesheetsIdToNameMap,
            siteFonts,
            navigateToPage,
            intl,
            dispatch
        } = props;
        const
            globalStyles = selectors.getAllStylesheets(stylesheets),
            textGlobalstylesOptions = textOptionMapper(globalStyles, intl),
            linkGlobalstyleOptions = linkOptionMapper(globalStyles, intl),
            configCell = getCellToReadValues(selectedComponent, tableEditModeState),
            globalStylesMap = stylesheets ? makeTextGlobalStylesNamesMap(stylesheets) : {},
            json = htmlToJson({ content: configCell.content, site, globalStylesMap }),
            firstCellFirstStyle = json.styles[0],
            textNormalGlobalstyle = selectors.textNormalGlobalstyle(stylesheets),
            textGlobalStyleId = (firstCellFirstStyle && firstCellFirstStyle.globalId) || textNormalGlobalstyle.id,
            cellsToReadValues = getCellsToReadValues(selectedComponent, tableEditModeState),
            jsonForNonEmptyCells = cellsToReadValues.map(cell => htmlToJson({ content: cell.content, site, globalStylesMap })),
            cellsWithLink = jsonForNonEmptyCells.filter(json => json.links.length > 0),
            linkThemeStyle = (
                cellsWithLink.length
                && cellsWithLink.every(json => json.links[0].style.themeStyle === cellsWithLink[0].links[0].style.themeStyle)
            )
                ? cellsWithLink[0].links[0].style.themeStyle
                : null,
            linkGlobalStyleId = json.links.length ? json.links[0].style.globalId : null,
            autoColorMode = props.themeSettingsData.autoColorMode;

        const getFontProp = getFont({ tinyMceState, stylesheets });

        let
            { value: fontFamily } = getFontProp(mp.font),
            { value: fontSize } = getFontProp(mp.size),
            { value: fontColor } = getFontProp(mp.color, undefined, autoColorMode),
            { value: highlightColor, removable: highlightColorRemovable } =
                getFontProp(mp.highlight, undefined, autoColorMode),
            mayBeHighlightColorRemoveAction = (
                (
                    highlightColorRemovable
                    || (autoColorMode && configCell.style.text.themeHighlightColor)
                )
                    ? { onRemoveAction: actionTypes.TABLE_TEXT_HIGHLIGHT_COLOR_REMOVED }
                    : {}
            ),
            { value: bold } = getFontProp(mp.bold, false),
            { value: italic } = getFontProp(mp.italic, false),
            { value: underline } = getFontProp(mp.underline, false),
            { value: superscript } = getFontProp(mp.superscript, false),
            { value: subscript } = getFontProp(mp.subscript, false),
            subscriptsBtns = [
                setIsSelected(superscript, subscriptsBtnDefs.superscript),
                setIsSelected(subscript, subscriptsBtnDefs.subscript)
            ],
            { value: shadow, removable: shadowRemovable } = (
                getFontProp(mp.shadow, { color: null, blur: 0, left: 0, top: 0 }, autoColorMode)
            ),
            mayBeShadowRemoveAction = (
                (
                    shadowRemovable
                    || (autoColorMode && configCell.style.text.themeShadowColor)
                )
                    ? { shadowRemoveAction: actionTypes.TABLE_TEXT_SHADOW_COLOR_REMOVED }
                    : {}
            ),
            horizontalAlign = getHorizontalAlign(tinyMceState),
            verticalAlign = getVerticalAlign(configCell, stylesheets);

        let textOptRenderer,
            className,
            selectedLinkStyle,
            linkStyleOnChangeAction,
            linkStyleOptions,
            linkOptRenderer;
        if (autoColorMode) {
            const themeRulesForLink = themeRulesBackgroundForLinkColor(props.themeColorsData);
            const linkThemeColors = THEME_LINK_CLASS_LIST.reduce((acc, linkClass) => ({
                ...acc,
                [linkClass]: toCss(props.themeColorsData[themeRulesForLink[linkClass][props.selectedComponentProps.selectedParentTheme]])
            }), {});
            textOptRenderer = (value, label) => textOptionRenderer({
                value, label, stylesheetsIdToNameMap, selectedParentTheme: props.selectedComponentProps.selectedParentTheme
            });
            className = cx("autoColorLinkPPDropdown", props.selectedComponentProps.selectedParentTheme);
            selectedLinkStyle = linkThemeStyle;
            linkStyleOnChangeAction = actionTypes.TABLE_LINK_GLOBAL_STYLE_CHANGED;
            linkStyleOptions = createThemeLinkOptions(intl);
            linkOptRenderer = (value, label) => {
                const classname = (value === themeunderlined ? linkStyles.linkPPunderline : '');
                return themeColorOptionRenderer(value, label, linkThemeColors[value], classname);
            };
        } else {
            textOptRenderer = (value, label) => textOptionRenderer({ value, label, stylesheetsIdToNameMap, selectedParentTheme: "White" });
            selectedLinkStyle = linkGlobalStyleId;
            linkStyleOnChangeAction = actionTypes.TABLE_LINK_GLOBAL_STYLE_CHANGED;
            linkStyleOptions = linkGlobalstyleOptions;
            linkOptRenderer = (value, label) => linkOptionRenderer(value, label, stylesheetsIdToNameMap);
        }

        return (
            <Page>
                <VerticalSpacer />
                <PropertyContainer label="msg: common.textStyle {Text style}">
                    <Flex align="center" justify="space-between">
                        <GlobalstyleSelector
                            selected={textGlobalStyleId}
                            onChangeAction={actionTypes.TABLE_TEXT_GLOBAL_STYLE_CHANGED}
                            options={textGlobalstylesOptions}
                            optionRenderer={textOptRenderer}
                            dispatch={dispatch}
                            className={autoColorMode ? 'autoColorTextMctaDropdown' : ''}
                            selectedKind={textGlobalstyleKind}
                            showEditLink
                        />
                        <GlobalstyleDialogInvoker
                            onClick={() => dispatch(openGlobalStylesDialogAC(textGlobalStyleId, textGlobalstyleKind))}
                        />
                    </Flex>
                </PropertyContainer>
                { json.links.length > 0 && <VerticalSpacer y={10} /> }
                {
                    json.links.length > 0 &&
                    <PropertyContainer label="msg: common.linkStyle {Link style}">
                        <Flex align="center" justify="space-between">
                            { /* @ts-ignore */ }
                            <GlobalstyleSelector
                                className={className}
                                selected={selectedLinkStyle}
                                onSelectAction={linkStyleOnChangeAction}
                                options={linkStyleOptions}
                                optionRenderer={linkOptRenderer}
                                dispatch={dispatch}
                            />
                            <GlobalstyleDialogInvoker
                                disabled={R.isNil(linkGlobalStyleId)}
                                onClick={() => linkGlobalStyleId && dispatch(openGlobalStylesDialogAC(linkGlobalStyleId, linkGlobalstyleKind))}
                            />
                        </Flex>
                    </PropertyContainer>
                }
                <VerticalSpacer y={10} />
                <PropertyContainer label="msg: common.font {Font}">
                    <FontFamilySelector
                        selected={fontFamily}
                        onChangeAction={actionTypes.TABLE_TEXT_FONT_FAMILY_CHANGED}
                        siteFonts={siteFonts}
                        onAddFontClicked={() => navigateToPage(addGoogleFontView.id, { source: TableComponentKind })}
                        forwardToSelectedComponent
                        isPropertiesPanel
                    />
                </PropertyContainer>
                <VerticalSpacer />
                <Flex justify="space-between" align="center">
                    <Label>{"msg: common.fontSize {Font size}"}</Label>
                    <FontSizeSelector
                        width={70}
                        searchable={false}
                        selected={fontSize}
                        dispatch={dispatch}
                        menuStyle={DEFAULT_MCTA_COMBOBOX_MENUSTYLE}
                        onChangeAction={actionTypes.TABLE_TEXT_FONT_SIZE_CHANGED}
                    />
                </Flex>
                <VerticalSpacer y={15} />
                <Flex align="center" justify="space-between">
                    <TextFormatButtonGroup
                        bold={{
                            isSelected: bold,
                            onClickAction: actionTypes.TABLE_TEXT_BOLD_TOGGLE,
                        }}
                        italic={{
                            isSelected: italic,
                            onClickAction: actionTypes.TABLE_TEXT_ITALIC_TOGGLE,
                        }}
                        underline={{
                            isSelected: underline,
                            onClickAction: actionTypes.TABLE_TEXT_UNDERLINE_TOGGLE,
                        }}
                        dispatch={dispatch}
                    />
                    <IconButtonsGroup buttonGroup={subscriptsBtns} dispatch={dispatch} />
                    <IconButtonsGroup buttonGroup={clearTextFormatBtn} dispatch={dispatch} />
                </Flex>
                <VerticalSpacer y={10} />
                <Flex>
                    <TextFullHorizontalAlignmentButtonsGroup
                        value={horizontalAlign}
                        onBtnClick={alignment => dispatch({
                            type: actionTypes.TABLE_CELL_HORIZONTAL_ALIGNMENT_CHANGED,
                            payload: alignment
                        })}
                    />
                </Flex>
                <VerticalSpacer y={10} />
                <Flex align="center">
                    <TextVerticalAlignmentButtonsGroup
                        value={verticalAlign}
                        onBtnClick={alignment => dispatch({
                            type: actionTypes.TABLE_CELL_VERTICAL_ALIGNMENT_CHANGED,
                            payload: alignment
                        })}
                    />
                </Flex>
                <VerticalSpacer />
                {
                    autoColorMode
                        ? <ColorProperty
                            colorPickerKind={THEME_COLOR_PICKER}
                            themeColor={(configCell.style.text.themeColor || null)}
                            themeColorsData={props.themeColorsData}
                            defaultThemeColor={
                                getTableThemeRulesFromBackgroundTheme(props.selectedComponentProps.selectedParentTheme, props.themeColorsData)[
                                    getStyleById(configCell.style.globalId)(props.stylesheets.styles).ref
                                ]
                            }
                            label="msg: common.textColor {Text colour}"
                            onChangeAction={actionTypes.TABLE_TEXT_COLOR_CHANGED_AUTO_COLOR}
                            dispatch={dispatch}
                        />
                        : <ColorProperty
                            label="msg: common.textColor {Text colour}"
                            color={fontColor}
                            onChangeAction={actionTypes.TABLE_TEXT_COLOR_CHANGED}
                            dispatch={dispatch}
                        />
                }
                <VerticalSpacer />
                {
                    autoColorMode
                        ? <ColorProperty
                            colorPickerKind={THEME_COLOR_OVERRIDE_COLOR_PICKER}
                            themeColor={configCell.style.text.themeHighlightColor || null}
                            themeColorsData={props.themeColorsData}
                            label="msg: common.highlightColor {Highlight colour}"
                            onChangeAction={actionTypes.TABLE_TEXT_HIGHLIGHT_COLOR_CHANGED_AUTO_COLOR}
                            {...mayBeHighlightColorRemoveAction}
                            dispatch={dispatch}
                        />
                        : <ColorProperty
                            label="msg: common.highlightColor {Highlight colour}"
                            color={highlightColor}
                            onChangeAction={actionTypes.TABLE_TEXT_HIGHLIGHT_COLOR_CHANGED}
                            {...mayBeHighlightColorRemoveAction}
                            dispatch={dispatch}
                        />
                }
                <VerticalSpacer />
                {
                    autoColorMode
                        ? <TextShadowView
                            colorPickerKind={THEME_COLOR_OVERRIDE_COLOR_PICKER}
                            themeColor={configCell.style.text.themeShadowColor || null}
                            themeColorsData={props.themeColorsData}
                            shadowColorChangeAction={actionTypes.TABLE_TEXT_SHADOW_COLOR_CHANGED_AUTO_COLOR}
                            {...mayBeShadowRemoveAction}
                            blurRadius={configCell.style.text.themeShadowBlurRadius}
                            blurRadiusChangeAction={actionTypes.TABLE_TEXT_SHADOW_BLUR_RADIUS_CHANGED_AUTO_COLOR}
                            shadowOffsetX={configCell.style.text.themeShadowOffsetX}
                            shadowOffsetXChangeAction={actionTypes.TABLE_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED_AUTO_COLOR}
                            shadowOffsetY={configCell.style.text.themeShadowOffsetY}
                            shadowOffsetYChangeAction={actionTypes.TABLE_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED_AUTO_COLOR}
                            dispatch={dispatch}
                        />
                        : <TextShadowView
                            shadowColor={shadow.color}
                            shadowColorChangeAction={actionTypes.TABLE_TEXT_SHADOW_COLOR_CHANGED}
                            {...mayBeShadowRemoveAction}
                            blurRadius={shadow.blur}
                            blurRadiusChangeAction={actionTypes.TABLE_TEXT_SHADOW_BLUR_RADIUS_CHANGED}
                            shadowOffsetX={shadow.left}
                            shadowOffsetXChangeAction={actionTypes.TABLE_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED}
                            shadowOffsetY={shadow.top}
                            shadowOffsetYChangeAction={actionTypes.TABLE_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED}
                            dispatch={dispatch}
                        />
                }
            </Page>
        );
    });

export { id, title, view };
