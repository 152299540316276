import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly } from '../../../../epics/makeCondition';
import { GLOBAL_STYLES_DIALOG_OPENED } from '../../../presentational/GlobalstyleDialogInvoker/actionTypes';
import { GLOBAL_STYLE_SELECTED } from "../../../presentational/GlobalstyleSelector/actionTypes";
import { GLOBAL_STYLES_TAB_SELECTED } from '../../../Globalstyles/actionTypes';
import { STYLESHEET_DUPLICATED, STYLESHEET_DELETED } from "../stylesheets/actionTypes";
import stylesheetsValueActionType from '../stylesheets/valueActionType';
import * as selectors from '../stylesheets/selectors';
import valueActionType from "./valueActionType";

export default makeEpic({
    valueActionType,
    updaters: [
        {
            conditions: [GLOBAL_STYLES_DIALOG_OPENED],
            reducer: ({ values: [{ stylesheetId }] }) => {
                return ({
                    state: stylesheetId
                });
            }
        },
        {
            conditions: [GLOBAL_STYLE_SELECTED],
            reducer: ({ values: [{ stylesheetId }] }) => ({ state: stylesheetId })
        },
        {
            conditions: [STYLESHEET_DUPLICATED],
            reducer: ({ values: [{ stylesheetId }] }) => ({ state: stylesheetId })
        },
        {
            conditions: [
                STYLESHEET_DELETED,
                receiveOnly(stylesheetsValueActionType)
            ],
            reducer: ({ values: [{ stylesheetType }, stylesheets] }) => {
                const stylesheet = selectors.getFirstStylesheetByType(stylesheetType)(stylesheets);
                return { state: stylesheet.id };
            }
        },
        {
            conditions: [GLOBAL_STYLES_TAB_SELECTED],
            reducer: ({ values: [{ stylesheetId }] }) => ({ state: stylesheetId })
        }
    ]
});
