import React from "react";
import MctaVerticalBar from '../../../../componentMainActions/MctaVerticalBar/index';
import HorizontalSpacer from "../../../../../view/common/HorizontalSpacer";
import Alignment from './Alignment';
import type { MobileGroupMCTAProps } from "../../../flowTypes";
import TextFontMctaScaler from "./TextFontMctaScaler";
import { titleClearFormatting } from '../../../../oneweb/constants';
import { MOBILE_EDITOR_GROUP_CLEAR_FORMATTING } from "../../../actionTypes";
import { MctaButton } from "../../../../componentMainActions/index";
import { ALIGNMENT_TYPES } from "../../../../../constants";

class MobileViewEditorV extends React.PureComponent<MobileGroupMCTAProps> {
    render() {
        const
            { id, settings, dispatch, mobileEltExtension } = this.props,
            { minSize, maxSize } = mobileEltExtension[id],
            onClearFormattingClick = () => {
                if (settings[id]) {
                    dispatch({ type: MOBILE_EDITOR_GROUP_CLEAR_FORMATTING, payload: id });
                }
            },
            props = {
                ...this.props,
                defaultAlign: ALIGNMENT_TYPES.ALIGN_LEFT
            };
        return <div>
            <Alignment
                mobileMCTA={props.mobileMCTA}
                dispatch={props.dispatch}
                id={props.id}
                selectedComponent={props.selectedComponent}
                settings={props.settings}
                mobileEltExtension={props.mobileEltExtension}
                defaultAlign={props.defaultAlign}
            />
            <HorizontalSpacer x={5} />
            <MctaVerticalBar />
            <HorizontalSpacer x={5} />
            <TextFontMctaScaler
                dispatch={dispatch}
                minSize={minSize}
                maxSize={maxSize}
                id={id}
            />
            <HorizontalSpacer x={5} />
            <MctaVerticalBar />
            <HorizontalSpacer x={5} />
            <MctaButton
                title={titleClearFormatting}
                icon="REMOVE_FORMAT"
                onClick={onClearFormattingClick}
            />
        </div>;
    }
}

export default (props: MobileGroupMCTAProps) => <MobileViewEditorV {...props} />;
