export {
    SITE_SUBSCRIPTION_DATA_LOAD_SUCCESS_FOR_GA_CAMP,
    SITE_SUBSCRIPTION_DATA_LOAD_FAIL_FOR_GA_CAMP
} from '../../../../../../wbtgen/src/components/App/actionTypes';

export const
    GOOGLE_ADS_SETTINGS_LEARN_MORE_LINK_CLICKED_ACTION = 'GOOGLE_ADS_SETTINGS_LEARN_MORE_LINK_CLICKED_ACTION',
    GOOGLE_ADS_SETTINGS_SYNC_BUTTON_CLICKED_ACTION = 'GOOGLE_ADS_SETTINGS_SYNC_BUTTON_CLICKED_ACTION',
    GOOGLE_ADS_SETTINGS_BUY_CAMPAIGN_BUTTON_CLICKED_ACTION = 'GOOGLE_ADS_SETTINGS_BUY_CAMPAIGN_BUTTON_CLICKED_ACTION',
    GOOGLE_ADS_SETTINGS_GO_TO_DASHBOARD_BUTTON_CLICKE_DACTION = 'GOOGLE_ADS_SETTINGS_GO_TO_DASHBOARD_BUTTON_CLICKE_DACTION',
    GOOGLE_ADS_SETTINGS_CAMPAIGN_STATUS_CHANGED_ACTION = 'GOOGLE_ADS_SETTINGS_CAMPAIGN_STATUS_CHANGED_ACTION';

export const
    googleAdsSettingsLearnMoreLinkClickedAction = () => ({
        type: GOOGLE_ADS_SETTINGS_LEARN_MORE_LINK_CLICKED_ACTION
    }),
    googleAdsSettingsBuyCampaignButtonClickedAction = () => ({
        type: GOOGLE_ADS_SETTINGS_BUY_CAMPAIGN_BUTTON_CLICKED_ACTION
    }),
    googleAdsSettingsUpdateStatusAction = () => ({
        type: GOOGLE_ADS_SETTINGS_SYNC_BUTTON_CLICKED_ACTION
    }),
    googleAdsSettingsGoToDashboardButtonClickedAction = () => ({
        type: GOOGLE_ADS_SETTINGS_GO_TO_DASHBOARD_BUTTON_CLICKE_DACTION
    }),
    googleAdsSettingsCampaignStatusChangedAction = (status: boolean) => ({
        type: GOOGLE_ADS_SETTINGS_CAMPAIGN_STATUS_CHANGED_ACTION,
        payload: status
    });
