export const styleToCss = (style: Record<string, any>): string => {
    const keys = Object.keys(style);
    return keys.reduce((acc, k) => {
        const
            ruleName = k.replace(/[A-Z]/g, g => `-${g[0].toLowerCase()}`),
            rule = `${ruleName}:${style[k]};`;

        return `${acc}\n${rule}`;
    }, '');
};
