import { getLinkUrlForText } from '../../../../components/oneweb/Text/getLinkUrl';

export default (link: any): any => {
    const {
        start,
        end,
        atype,
        linkAction,
        style: {
            globalName,
            globalId
        }
    } = link;
    let linkUrl = getLinkUrlForText(linkAction);
    return {
        start,
        end,
        atype,
        href: linkUrl.href,
        target: linkUrl.target,
        globalName,
        globalId
    };
};
