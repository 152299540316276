/* eslint-disable max-len */

import * as R from "ramda";
import * as React from "react";
import cx from 'classnames';
import styles from './MobileHeader.css';
import type { MobileHeaderTitleState, MenuItem } from "./types";
import { MveHeaderTitleType } from "../../MobileViewEditor/header/constants";
import type { MobileStyle } from "../style/types";
import type { ReactElementRef } from "../../../globalTypes";
import { getElementPureWidth } from "../../../utils/dom";
import { replaceTagsWithContent } from '../../oneweb/Text/view/replaceTagsWithContent';
import { DataSite } from "../../../../dal/model/index";
import { toCss } from "../../../mappers/color";
import type { ThemeColorDataType } from '../../ThemeGlobalData/flowTypes';
import { FontFamiliesFallback } from '../../presentational/FontFamily/fontFamilies';

type CommonProps = {
    state: MobileHeaderTitleState,
    pageName: string,
    mobileStyle: MobileStyle,
    site: DataSite,
    globalVariables: Record<string, any>,
    goHomeOnClick?: boolean,
    homeMenuItem?: MenuItem,
};

type PropsWhenAutoColorModeIsOn = CommonProps & {
    autoColorMode: true,
    themeColorsData: ThemeColorDataType,
};

type PropsWhenAutoColorModeIsOff = CommonProps & {
    autoColorMode: false,
};

type Props = PropsWhenAutoColorModeIsOn | PropsWhenAutoColorModeIsOff;

type State = {
    titleScale: null | undefined | number,
};

const TitleLink = (props) => {
    const onClick = e => {
        e.stopImmediatePropagation();
    };
    const { children, goHomeOnClick, homeMenuItem, isCustomTitle } = props;
    if (isCustomTitle && goHomeOnClick && homeMenuItem) {
        return (
            <a className={styles.logoAnchor} href={homeMenuItem.href} onClick={onClick}>
                {children}
            </a>
        );
    }
    return children;
};

export class MobileHeaderTitle extends React.Component<Props, State> {
    state: State = { titleScale: null };
    containerRef: ReactElementRef<HTMLDivElement> = React.createRef();
    titleRef: ReactElementRef<HTMLSpanElement> = React.createRef();

    shouldComponentUpdate(nextProps: Props, nextState: State) {
        return !R.equals(this.props, nextProps) || this.state.titleScale !== nextState.titleScale;
    }

    updateTitleScale() {
        const
            container = this.containerRef.current,
            title = this.titleRef.current;

        if (container && title) {
            const
                containerWidth = getElementPureWidth(container),
                titleWidth = Math.round(title.offsetWidth);

            if (titleWidth > containerWidth) {
                const titleScale = Math.round((containerWidth / titleWidth) * 100) / 100;
                this.setState({ titleScale });
                return;
            }
        }

        if (this.state.titleScale) {
            // clean up
            this.setState({ titleScale: null });
        }
    }

    componentDidMount() {
        this.updateTitleScale();
    }

    componentDidUpdate() {
        this.updateTitleScale();
    }

    render() {
        const
            {
                state: { type, value },
                pageName,
                mobileStyle,
                site,
                globalVariables,
                goHomeOnClick,
                homeMenuItem,
            } = this.props,
            isCustomTitle = type === MveHeaderTitleType.WEBSITE,
            title = isCustomTitle ? value : pageName;

        if (!title) return null;

        const
            { titleScale } = this.state,
            fontFamily = mobileStyle.title.fontFamily,
            titleTextStyle: Record<string, any> = {
                color: this.props.autoColorMode ? toCss(this.props.themeColorsData[mobileStyle.title.themeColor]) : mobileStyle.title.color,

                // @ts-ignore
                fontFamily: FontFamiliesFallback[fontFamily] || fontFamily,
                fontWeight: mobileStyle.title.fontWeight,
                fontStyle: mobileStyle.title.fontStyle,
                textDecoration: mobileStyle.title.underline ? 'underline' : null,
                ...(titleScale && isCustomTitle ? { transform: `scale(${titleScale})` } : null),
            },
            titleTextClassName = cx(styles.titleText, { [styles.scale]: titleScale && isCustomTitle });

        return (
            <div
                className={cx(styles.title, {
                    [`mobile-title ${styles.customTitle}`]: isCustomTitle,
                    [styles.pageTitle]: !isCustomTitle
                })}
                ref={this.containerRef}
            >
                <TitleLink goHomeOnClick={goHomeOnClick} homeMenuItem={homeMenuItem} isCustomTitle={isCustomTitle}>
                    <span className={titleTextClassName} style={titleTextStyle} ref={this.titleRef}>
                        {replaceTagsWithContent(title, { site, globalVariables })}
                    </span>
                </TitleLink>
            </div>
        );
    }
}
