import React from 'react';
import WarningDialog from '../../../view/common/dialogs/WarningDialog';
import { Msg } from '../../../view/intl';
import makeAppStandardErrorDialog from "./makeAppStandardErrorDialog";

const SiteDataSaveFailureDialog = () => (
    <WarningDialog
        title="msg: common.updateToSaveSite {Unable to save the site}"
        mctaText="msg: common.ok {OK}"
    >
        <div>
            <Msg k="common.contactSupport">
                Contact our support for further assistance if the problem persists.
            </Msg>.
        </div>
    </WarningDialog>
);

export default makeAppStandardErrorDialog(SiteDataSaveFailureDialog);
