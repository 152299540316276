import type { Position } from '../../../redux/modules/flowTypes';
import { CLOSE_DIALOG_BY_ID } from "../../../redux/modules/actionTypes";
import {
    APP_MOUNT,
    WINDOW_MOUSE_MOVE,
    WINDOW_MOUSE_UP,
    WINDOW_RESIZED,
    LEFT_PANEL_ON_MOUSE_ENTER,
    LEFT_PANEL_ON_MOUSE_LEAVE,
    SAVE
} from "../actionTypes";
import { SELECT_ALL_COMPONENTS } from "../../Workspace/actionTypes";
import type { Dimensions } from "../../../redux/modules/children/workspace/flowTypes";

export { default as loadSiteData } from "./loadSiteData";
export { default as loadSubscriptionData } from "./loadSubscriptionData";
export { loadSubscriptionMetadataAction } from "./loadSubscriptionData";
export { loadSubscriptionDataForGoogleAdsCampaign } from "./loadSubscriptionData";
export { default as loadSubscriptionStatusBackupRestoreData } from './loadSubscriptionStatusBackupRestoreData';
export { default as openDialog } from "./openDialog";
export { default as updateDialog } from "./updateDialog";
export { default as closeDialog } from "./closeDialog";
export { default as closePersistentDialog } from "./closePersistentDialog";
export { openProgressDialogAction } from "./openProgressDialogAction";
export { default as replaceDialog } from "./replaceDialog";
export { default as updateSiteData } from "./updateSiteData";
export { default as updateSiteDocuments } from './updateSiteDocument';

export const
    mouseMoveAC = (position: Position) => ({ type: WINDOW_MOUSE_MOVE, payload: position }),
    mouseUpAC = (
        position: Position,
        shiftKey: boolean = false,
        metaKey: boolean = false,
        ctrlKey: boolean = false,
        altKey: boolean = false
    ) =>
        ({ type: WINDOW_MOUSE_UP, payload: position, shiftKey, metaKey, ctrlKey, altKey }),
    appMountAC = () => ({ type: APP_MOUNT }),
    closeDialogByIdAction = (dialogId: string) => ({ type: CLOSE_DIALOG_BY_ID, payload: dialogId }),
    windowResizedAC = (dimensions: Dimensions) => ({ type: WINDOW_RESIZED, payload: dimensions }),
    selectAllComponentsAC = () => ({ type: SELECT_ALL_COMPONENTS }),
    leftPanelOnMouseOverAC = () => ({ type: LEFT_PANEL_ON_MOUSE_ENTER }),
    leftPanelOnMouseLeaveAC = () => ({ type: LEFT_PANEL_ON_MOUSE_LEAVE }),
    saveAction = () => ({ type: SAVE });
