/* eslint-disable max-len */

import * as React from 'react';
import cx from 'classnames';
import { Box } from "../../../../../../view/reflexbox/index";
import styles from './CongratsOrErrorHandlerCommonView.css';
import PrimaryButton from "../../../../../../view/common/Button/PrimaryButton";
import SecondaryButton from "../../../../../../view/common/Button/SecondaryButton";
import { injectIntl, Intl } from "../../../../../../view/intl/index";

type Props = {
    intl: Intl,
    iconClass: string,
    iconContainer?: string,
    titleMessage: string,
    descriptionMain: string,
    descriptionSub?: string,
    primaryBtnText: string,
    primaryBtnAction?: () => void,
    secondaryBtnText?: string,
    secondaryBtnAction?: () => void,
    showSecondaryBtn?: boolean
};

export const CongratsOrErrorHandlerCommonView = injectIntl((props: Props) => {
    const {
        intl,
        iconContainer,
        iconClass,
        titleMessage,
        descriptionMain,
        descriptionSub,
        primaryBtnText,
        secondaryBtnText,
        primaryBtnAction,
        secondaryBtnAction,
        showSecondaryBtn = false
    } = props;

    const
        linkToGmbListings = 'https://business.google.com?hl=' + decodeURIComponent(intl.msgJoint("msg: common.GoogleLocaleCode {en}")),
        getIcon = () => (<div className={iconClass} />),
        getIconContainer = () => {
            if (iconContainer) {
                return (<div className={iconContainer}>{getIcon()}</div>);
            }
            return getIcon();
        };

    return (
        <React.Fragment>
            {getIconContainer()}
            <Box className={styles.titleMessage}>
                {intl.msgJoint(titleMessage)}
            </Box>
            <Box className={cx(styles.descriptionMain, { [styles.wrapDescription]: showSecondaryBtn })}>
                {intl.msgJoint(descriptionMain)}
            </Box>
            {
                descriptionSub &&
                <Box className={styles.descriptionsSub}>
                    {intl.msgJoint(descriptionSub)}
                </Box>
            }
            {showSecondaryBtn ?
                <div className={styles.footerButtons}>
                    <a href={linkToGmbListings} className={cx(styles.button, styles.primaryBtn)} target="_blank">
                        <div>{intl.msgJoint(primaryBtnText)}</div>
                    </a>
                    <SecondaryButton
                        className={styles.button}
                        // @ts-ignore
                        onClick={secondaryBtnAction}
                    >
                        {
                            // @ts-ignore
                            intl.msgJoint(secondaryBtnText)
                        }
                    </SecondaryButton>
                </div> : <div className={styles.divContainer}>
                    <PrimaryButton
                        className={styles.primaryBtn}
                        // @ts-ignore
                        onClick={primaryBtnAction}
                    >
                        {intl.msgJoint(primaryBtnText)}
                    </PrimaryButton>
                    {secondaryBtnText && <a href={linkToGmbListings} className={styles.secondaryBtn} target="_blank">
                        {intl.msgJoint(secondaryBtnText)}
                    </a>}
                </div>}
        </React.Fragment>
    );
});
