/* eslint-disable max-len */

import React from 'react';
import { Msg } from '../../../view/intl/index';
import { StripTypes, Dialog, DialogTitleBox, DialogBody, DialogFooter } from '../../../view/common/dialogs/baseDialog/index';
import getCenteredDialogConfig from '../../DialogManager/getCenteredDialogConfig';
import { closeDialog } from '../../App/actionCreators/index';
import warningStyles from '../../../view/common/dialogs/WarningDialog/WarningDialog.css';

const
    dialogId = 'BackupsRestoreFailedDialog',
    width = 490,
    height = 350,
    BackupRestoreFailedDialog = ({ dispatch }) => (
        <Dialog stripType={StripTypes.WARNING}>
            <DialogTitleBox
                title="msg: component.backup.restoreFailed.dialog.title {Restoring failed}"
                className={warningStyles.warningDialogTitle}
            />
            <DialogBody>
                <Msg k="component.backup.restoreFailed.dialog.message">
                    Sorry, we could not restore your website. Please select another version and try again.
                </Msg>
            </DialogBody>
            <DialogFooter
                noTopBorder
                mctaText="msg: common.ok {OK}"
                mctaHandler={() => dispatch(closeDialog())}
                className={warningStyles.warningDialogFooter}
            />
        </Dialog>
    ),
    dialog = getCenteredDialogConfig(width, height, BackupRestoreFailedDialog);

export { dialogId, dialog };
