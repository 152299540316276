import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { useIntl } from '../../../../../../wbtgen/src/view/intl';
import { getAppConfig } from "../../../../../../wbtgen/src/components/App/epics/appConfig/appConfig";
import AppConfig from "../../../../../../wbtgen/src/utils/AppConfig";
import { PartnerName } from '../../../../../../server/shared/partners/PartnerName';

import { ErrorBoundary } from '../../Error/ErrorBoundary';
import { PageLayout } from '../PageLayout';
import { PageContentLayout } from '../PageContentLayout';
import { Hero } from './Hero';
import { AnalyticsWidget } from './AnalyticsWidget';
import { SeoScanWidget } from '../Marketgoo';
import { Companion } from './Companion';
import { Help } from './Help';
import { useStyles } from './styles';
import { OnlineShopWidget } from './OnlineShopWidget';

const appConfig = AppConfig(getAppConfig());

export const Home = () => {
    const intl = useIntl();
    const classes = useStyles();
    const isOneComPartner = appConfig.getOptional("partnerName") === PartnerName.ONECOM;

    return (
        <PageLayout>
            <PageContentLayout fullSize>
                <Hero />

                <Box className={classes.contentRoot} data-testid="home-content-section">
                    <Box className={classes.content}>
                        <Stack spacing={{ xs: "8px", sm: "48px" }} pt={{ xs: "8px", sm: "0px" }}>
                            <OnlineShopWidget />

                            <Box className={classes.widgetsRow}>
                                <Box className={classes.widgetWrapper} minWidth={{ xs: "320px", sm: "432px" }} data-testid="analytics-widget-wrapper">
                                    <ErrorBoundary classes={{ root: classes.widgetErrorRoot }}>
                                        <AnalyticsWidget />
                                    </ErrorBoundary>
                                </Box>

                                <Box className={classes.widgetWrapper} minWidth={{ xs: "320px", sm: "432px" }} data-testid="seo-scan-widget-wrapper">
                                    <ErrorBoundary classes={{ root: classes.widgetErrorRoot }}>
                                        <SeoScanWidget />
                                    </ErrorBoundary>
                                </Box>
                            </Box>

                            <Stack direction="column" spacing="16px" className={classes.section}>
                                <Typography component="h4" className={classes.sectionTitle}>
                                    {intl.msgJoint("msg: common.makeYourSideBetter {Make your site even better}")}
                                </Typography>

                                <Box className={classes.widgetsRow}>
                                    {
                                        isOneComPartner ?
                                            <Box className={classes.widgetWrapper} minWidth={{ xs: "320px", sm: "432px" }} data-testid="companion-widget-wrapper">
                                                <ErrorBoundary classes={{ root: classes.borderlessWidgetErrorRoot }}>
                                                    <Companion />
                                                </ErrorBoundary>
                                            </Box>
                                            : null
                                    }

                                    <Box className={classes.widgetWrapper} minWidth={{ xs: "320px", sm: "432px" }} data-testid="help-widget-wrapper">
                                        <ErrorBoundary classes={{ root: classes.borderlessWidgetErrorRoot }}>
                                            <Help />
                                        </ErrorBoundary>
                                    </Box>
                                    {
                                        isOneComPartner ? null : <Box className={classes.widgetWrapper} minWidth={{ xs: "320px", sm: "432px" }} data-testid="help-widget-wrapper" />
                                    }
                                </Box>
                            </Stack>
                        </Stack>
                    </Box>
                </Box>
            </PageContentLayout>
        </PageLayout>
    );
};
