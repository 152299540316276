import ContrastAdjustDialog from "./view/index";
import getCenteredDialogConfig from "../../DialogManager/getCenteredDialogConfig";
import { getCenterPositionForDialog } from "../../DialogManager/utility";

const HEIGHT = 394,
    WIDTH = 600,
    contrastAdjustDialog = {
        ...getCenteredDialogConfig(WIDTH, HEIGHT, ContrastAdjustDialog),
        confFactory: ({ browserWidth, browserHeight }) => {
            return {
                position: getCenterPositionForDialog(WIDTH, HEIGHT, browserWidth, browserHeight),
                modal: true,
                dimensions: {
                    width: WIDTH,
                    height: HEIGHT
                }
            };
        },
        moveToCenter: true
    };
export { contrastAdjustDialog };
