import { WINDOW_RESIZED_DEBOUNCED } from "../../../App/actionTypes";
import { fullWidthCmpMap } from "../../../oneweb/isStretchComponentKind";
import { setComponentsMap, setComponentsMapExtension } from "./setters";
import * as updateReasons from "./updateReasons";
import * as actionTypes from "../../../oneweb/Gallery/actionTypes";
import { optional, receiveOnly } from "../../../../epics/makeCondition";
import { componentAttachmentsVAT } from "../componentAttachements/valueActionType";
import { getChildToParentMap, getTopMostParentId } from "../componentAttachements/util";
import { getSectionChildren, updateSectionHeightAndAdjustOtherSectionsPositions } from "../../../oneweb/Section/utils";
import { calcWorkspaceWidthWithMargin } from "../../../oneweb/Gallery/calcRenderProps";
import { calcGalleryHeight } from "../../../oneweb/Gallery/calcGalleryHeight";
import type { ComponentsMap } from "../../../../redux/modules/children/workspace/flowTypes";
import type { Attachments } from "../componentAttachements/flowTypes";
import templateValueActionType from '../../../oneweb/Template/epics/template/valueActionType';

interface ComponentsOnHeightChange {
    diff: number;
    parentSectionId: string;
    cmpId: string;
    componentsMap: ComponentsMap;
    attachments: Attachments;
}

const
    updateComponentsOnHeightChange = (
        { diff, parentSectionId, cmpId, componentsMap, attachments }: ComponentsOnHeightChange
    ): ComponentsMap => {
        const component = componentsMap[cmpId];
        const childToParentMap = getChildToParentMap(attachments);
        const parentIds: Array<string> = [];
        let currentId = cmpId;
        while (childToParentMap[currentId] !== parentSectionId) {
            currentId = childToParentMap[currentId];
            parentIds.push(currentId);
        }
        const parentSection = componentsMap[parentSectionId];
        const sectionChildren = getSectionChildren(parentSection, componentsMap);
        const sectionChildrenBelowGallery = sectionChildren
            .filter(cmp => (cmp.top >= (component.top + component.height)))
            .reduce((sectionChildrenMap, cmp) => ({
                ...sectionChildrenMap,
                [cmp.id]: {
                    ...cmp,
                    top: cmp.top + diff,
                    relIn: null,
                    relTo: null
                }
            }), {});
        const adjustedParents = parentIds.map(parentId => (componentsMap[parentId])).reduce((parentsCmpsMap, cmp) => {
            return {
                ...parentsCmpsMap,
                [cmp.id]: {
                    ...cmp,
                    height: cmp.height + diff
                }
            };
        }, {});
        const newSectionHeight = parentSection.height + diff;
        return {
            ...updateSectionHeightAndAdjustOtherSectionsPositions(
                newSectionHeight,
                parentSectionId,
                componentsMap
            ),
            ...adjustedParents,
            ...sectionChildrenBelowGallery,
        };
    };

export const
    resizeUpdater = {
        conditions: [
            receiveOnly(componentAttachmentsVAT),
            receiveOnly(templateValueActionType),
            WINDOW_RESIZED_DEBOUNCED,
            optional(actionTypes.GALLERY_CAPTIONS_ENABLED_CHECKBOX_CLICKED),
            optional(actionTypes.GALLERY_PP_COLUMNS_CHANGED),
            optional(actionTypes.GALLERY_PP_SPACING_CHANGED),
            optional(actionTypes.GALLERY_PP_FULL_WIDTH_MARGIN),
            optional(actionTypes.GALLERY_PP_FULL_WIDTH_MAX_WIDTH),
            optional(actionTypes.GALLERY_PP_IMAGE_RATIO)
        ],
        reducer: ({ state, values: [{ attachments }, { width: templateWidth }] }) => {
            const
                { componentsMap, componentsMapExtension } = state.state,
                fullWidthGalleryIds = Object.keys(componentsMap).filter(id => {
                    const { kind, stretch = false } = componentsMap[id];
                    return fullWidthCmpMap.hasOwnProperty(kind) && stretch;
                });
            let newComponentsMapExtension = { ...componentsMapExtension };
            if (fullWidthGalleryIds.length) {
                const cmpsToBeUpdated = fullWidthGalleryIds
                    .filter(id => (componentsMapExtension[id] && componentsMapExtension[id].heightDiff < 0)
                        && componentsMap[id].width !== templateWidth);
                if (cmpsToBeUpdated.length) {
                    let newComponentsMap = cmpsToBeUpdated
                        .reduce((acc, id) => {
                            const galleryCmp = acc[id];
                            const diff = componentsMapExtension[id].heightDiff;
                            const childToParentMap = getChildToParentMap(attachments);
                            const parentSectionId = getTopMostParentId(id, attachments);
                            const parentIds: Array<string> = [];
                            let currentId = id;
                            while (childToParentMap[currentId] !== parentSectionId) {
                                currentId = childToParentMap[currentId];
                                parentIds.push(currentId);
                            }
                            const parentSection = acc[parentSectionId];
                            const sectionChildren = getSectionChildren(parentSection, acc);
                            const sectionChildrenBelowGallery = sectionChildren
                                .filter(cmp => (cmp.top >= (galleryCmp.top + galleryCmp.height)))
                                .reduce((sectionChildrenMap, cmp) => ({
                                    ...sectionChildrenMap,
                                    [cmp.id]: {
                                        ...cmp,
                                        top: cmp.top + diff
                                    }
                                }), {});
                            const adjustedParents = parentIds.map(parentId => (acc[parentId])).reduce((parentsCmpsMap, cmp) => {
                                return {
                                    ...parentsCmpsMap,
                                    [cmp.id]: {
                                        ...cmp,
                                        height: cmp.height + diff
                                    }
                                };
                            }, {});
                            const newSectionHeight = parentSection.height + diff;
                            const updatedComponentsMap = updateSectionHeightAndAdjustOtherSectionsPositions(
                                newSectionHeight,
                                parentSectionId,
                                { ...acc, ...sectionChildrenBelowGallery, ...adjustedParents }
                            );
                            newComponentsMapExtension[id].heightDiff = 0;
                            return {
                                ...acc,
                                ...updatedComponentsMap,
                            };
                        }, { ...componentsMap });
                    let newState;
                    newState = setComponentsMapExtension({ ...newComponentsMapExtension }, state);
                    return {
                        state: setComponentsMap({ ...newComponentsMap }, newState),
                        updateReason: updateReasons.FULL_WIDTH_CHANGE_DUE_TO_WINDOW_RESIZE,
                    };
                }
            }

            return { state };
        }
    },
    fullWidthUpdater = {
        conditions: [actionTypes.GALLERY_FULL_WIDTH, receiveOnly(componentAttachmentsVAT)],
        reducer: ({ state, values: [{ id }, { attachments }] }) => {
            const { componentsMap, componentsMapExtension } = state.state;
            const { componentsDependencies } = state.scope;
            let gallery = {
                ...componentsMap[id]
            };
            const stretch = !gallery.stretch;
            const parentSectionId = getTopMostParentId(id, attachments);
            if (stretch) {
                gallery = {
                    ...gallery,
                    stretch,
                    fullWidthOption: {
                        ...gallery.fullWidthOption,
                        originalLeft: gallery.left,
                        originalWidth: gallery.width
                    },
                    width: calcWorkspaceWidthWithMargin(
                        gallery.fullWidthOption.margin,
                        gallery.fullWidthOption.maxWidth,
                        componentsDependencies.GALLERY.workspaceBBoxWidth,
                        componentsDependencies.GALLERY.templateWidth,
                    ),
                    left: 0,
                };
            } else {
                gallery = {
                    ...componentsMap[id],
                    stretch,
                    width: componentsMap[id].fullWidthOption.originalWidth,
                    left: componentsMap[id].fullWidthOption.originalLeft,
                    fullWidthOption: {
                        ...componentsMap[id].fullWidthOption,
                        originalLeft: 0,
                        originalWidth: null
                    }
                };
            }
            // @ts-ignore this need to be fixed
            const height = calcGalleryHeight({
                component: {
                    ...gallery
                },
                highestCaptionPx: !gallery.captionsEnabled ? 0 : componentsMapExtension[id].highestCaptionPx
            });
            const oldHeight = gallery.height;
            const diff = height - oldHeight;
            gallery = {
                ...gallery,
                height
            };
            if (diff < 0) {
                componentsMap[id] = {
                    ...gallery
                };
            }
            const updatedComponentsMap = diff ? updateComponentsOnHeightChange({
                diff,
                parentSectionId,
                cmpId: id,
                componentsMap,
                attachments,
            }) : { ...componentsMap };

            return {
                state: setComponentsMap({ ...updatedComponentsMap, [id]: { ...gallery } }, state),
                updateReason: updateReasons.PROPERTY_CHANGE,
            };
        }
    };

