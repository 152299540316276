import * as R from 'ramda';
import registry from '../../../../view/oneweb/registry/index';
import componentsEvalValueActionType from '../componentsEval/valueActionType';
import * as componentsEvalSelectors from '../componentsEval/selectorActionTypes';
import { getAttachments } from '../componentsEval/getters';
import { getUpdatedEpicsState as getEpicsUpdate } from '../../../../epics/updateManager';
import resizeDecosVAT from "../resizeDecos/valueActionType";
import { isResizingVisibleSelector } from "../resizeDecos/selectors";
import { editModeComponentIdSelector, templateSelectedThemeSelector } from "../componentsEval/selectors";
import { getComponentBase } from '../componentsEval/componentsMapBase';
import { getParentThemeMap } from "../../../ThemeGlobalData/utils/getParentThemeMap";
import { BACKGROUND_THEME_WHITE } from '../../../ThemeGlobalData/constants';
import { getTopMostParentId } from '../componentAttachements/util';

const dependencyValueActionTypes = [
    componentsEvalValueActionType
];
const defaultComponentsExtension = {};
export const getComponentsProps = (
    {
        componentsMap,
        componentsMapExtension,
        componentsDependencies,
        editModeComponentId,
        templateSelectedTheme,
        prevState,
        selectedComponentsIds,
        isResizing,
        attachments,
        mhfCmpsData
    }: Record<string, any>
) => {
    const
        newState = {},
        parentThemeMap: { [a: string]: string } = getParentThemeMap(componentsMap, templateSelectedTheme);
    let somethingChanged = false;

    Object.keys(componentsMap).forEach(componentId => {
        const
            component = componentsMap[componentId],
            componentExtension = componentsMapExtension[componentId] || defaultComponentsExtension,
            componentDependencies = componentsDependencies[component.kind],
            // $FlowFixMe: WBTGEN-2411
            { calcRenderProps } = registry[component.kind],
            selectedParentTheme = parentThemeMap[componentId] || BACKGROUND_THEME_WHITE,
            inEditMode = editModeComponentId === componentId,
            isComponentSelected = selectedComponentsIds.indexOf(componentId) !== -1,
            isComponentResizing = isComponentSelected && isResizing,
            parentSection = componentsMap[getTopMostParentId(componentId, attachments)],
            mhfCmpData = mhfCmpsData[component.id];

        if (calcRenderProps) { // todo remove this if after every component will implement calcRenderProps
            const renderProps = calcRenderProps(
                componentId,
                component,
                componentExtension,
                componentDependencies,
                true,
                getComponentBase(component),
                inEditMode,
                isComponentSelected,
                isComponentResizing,
                selectedParentTheme,
                parentSection,
                mhfCmpData
            );

            newState[componentId] = renderProps;
            if (!somethingChanged && !R.equals(prevState[componentId], renderProps)) {
                somethingChanged = true;
            }
        }
    });

    return somethingChanged ? newState : prevState;
};

// This reducer is exist only for performance optimization reasons. It should be part of components eval and recomputed there.
// So far, in case of relations got updated (and relations are living in separate epic), there is a posibility of componentsEval.componentsMap is updated twice.
// So having reducer outside of epic make it to be called only once, even components eval updated multiple times
export default function (prevState: ObjectMap, action: AnyAction) {
    if (prevState === undefined) {
        return {};
    }

    const anyDependencyChanged =
        // @ts-ignore
        action.epicsUpdate && dependencyValueActionTypes.some(depType => action.epicsUpdate.updatedEpicsTypes[depType]);

    if (anyDependencyChanged) {
        const
            epicsState = getEpicsUpdate(action),
            componentsEvalState = epicsState[componentsEvalValueActionType].state,
            { componentsMap, componentsMapExtension } =
                componentsEvalSelectors.componentsSelector(componentsEvalState),
            { mhfCmpsData } = componentsEvalSelectors.scopeSelector(componentsEvalState),
            attachments = getAttachments(componentsEvalState),
            componentsDependencies = componentsEvalSelectors.componentsDependenciesSelector(componentsEvalState),
            templateSelectedTheme = templateSelectedThemeSelector(componentsEvalState),
            editModeComponentId = editModeComponentIdSelector(componentsEvalState),
            selectedComponentsIds = componentsEvalSelectors.selectedComponentsIdsSelector(componentsEvalState),
            isResizing = isResizingVisibleSelector(epicsState[resizeDecosVAT].state.state);

        return getComponentsProps({
            componentsMap,
            componentsMapExtension,
            componentsDependencies,
            editModeComponentId,
            templateSelectedTheme,
            prevState,
            selectedComponentsIds,
            isResizing,
            attachments,
            mhfCmpsData
        });
    }
    return prevState;
}
