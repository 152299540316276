import * as R from 'ramda';
import * as workspaceActionTypes from "../../actionTypes";
import * as oldWorkspaceActionTypes from "../../../../redux/modules/children/workspace/actionTypes";
import { selectedComponentSelector } from "./selectorActionTypes";
import { MOVING_COMPONENTS } from "./userInteractionMutations/interactionModes";
import { CHANGE_SCOPE } from "./updateReasons";
import { pathToEditModeComponentId } from "./paths";
import { optional } from '../../../../epics/makeCondition';
import { ButtonKind } from '../../../oneweb/Button/kind';
import type { ComponentsEvalEpicUpdater } from "./flowTypes";
import { COMPONENT_ENTERED_EDIT_MODE_USING_DOUBLE_CLICK } from './actionTypes';
import { SHOW_MODERN_FOOTER_LAYOUT_LEFT_PANEL, SHOW_MODERN_HEADER_LAYOUT_LEFT_PANEL } from '../../../ModernLayouts/actionTypes';

const
    getEditModeComponentId = R.path(pathToEditModeComponentId),
    setEditModeComponentId = R.assocPath(pathToEditModeComponentId),
    resetEditModeComponentId = setEditModeComponentId(null),
    onEnteringEditModeUpdaterReducer = (isDoubleClickedToEnterEditMode: boolean = false) => ({ state }) => {
        const selectedComponent = selectedComponentSelector(state);

        let actionToDispatch;
        if (isDoubleClickedToEnterEditMode && selectedComponent && getEditModeComponentId(state) === null) {
            actionToDispatch = { type: COMPONENT_ENTERED_EDIT_MODE_USING_DOUBLE_CLICK, payload: selectedComponent };
        }

        if (selectedComponent) {
            let newState = setEditModeComponentId(selectedComponent.id, state);

            if (selectedComponent.kind === ButtonKind) {
                newState = R.assocPath(
                    ['state', 'componentsMapExtension', selectedComponent.id, 'offScreenRendererText'],
                    selectedComponent.text
                )(newState);
            }

            return {
                state: newState,
                updateReason: CHANGE_SCOPE,
                // $FlowFixMe
                actionToDispatch,
            };
        }

        return { state };
    },
    setEditModeComponentIdOnComponentEditUpdater: ComponentsEvalEpicUpdater = {
        conditions: [
            oldWorkspaceActionTypes.COMPONENT_EDIT
        ],
        reducer: onEnteringEditModeUpdaterReducer()
    },
    setEditModeComponentIdOnWorkspaceDblClickUpdater: ComponentsEvalEpicUpdater = {
        conditions: [workspaceActionTypes.WORKSPACE_DOUBLE_CLICK],
        reducer: onEnteringEditModeUpdaterReducer(true)
    },
    openPropertiesPanelHandler: ComponentsEvalEpicUpdater = {
        conditions: [workspaceActionTypes.OPEN_PROPERTIES_PANEL],
        reducer: onEnteringEditModeUpdaterReducer()
    },
    resetEditModeComponentIdUpdater: ComponentsEvalEpicUpdater = {
        conditions: [
            optional(workspaceActionTypes.SELECTED_COMPONENT_WRAP_TOP_LEFT),
            optional(workspaceActionTypes.SELECTED_COMPONENT_WRAP_TOP_RIGHT),
            optional(workspaceActionTypes.SELECTED_COMPONENT_WRAP_TO_CENTER),
            optional(workspaceActionTypes.SELECTED_COMPONENT_WRAP_ABOVE),
            optional(workspaceActionTypes.SELECTED_COMPONENT_WRAP_BELOW),
            optional(workspaceActionTypes.SELECTED_COMPONENT_UNWRAP),
        ],
        reducer: ({ state }) => (
            { state: resetEditModeComponentId(state), updateReason: CHANGE_SCOPE }
        ),
    },
    removeEditModeComponentId: ComponentsEvalEpicUpdater = {
        conditions: [
            optional(SHOW_MODERN_HEADER_LAYOUT_LEFT_PANEL),
            optional(SHOW_MODERN_FOOTER_LAYOUT_LEFT_PANEL)
        ],
        reducer: ({ state }) => (
            { state: resetEditModeComponentId(state), updateReason: CHANGE_SCOPE }
        ),
    },
    dontShowSelectionHandles = (interactionMode: string) => interactionMode === MOVING_COMPONENTS;

export {
    setEditModeComponentId,
    setEditModeComponentIdOnComponentEditUpdater,
    setEditModeComponentIdOnWorkspaceDblClickUpdater,
    resetEditModeComponentIdUpdater,
    resetEditModeComponentId,
    openPropertiesPanelHandler,
    removeEditModeComponentId,
    dontShowSelectionHandles
};
