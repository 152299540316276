import { DESKTOP_PREVIEW, MOBILE_PREVIEW, PREVIEW_LOADED } from "../App/actionTypes";
import { PreviewEventIds } from "../EventTracking/constants";
import { IsDesktopPreviewSelector, PreviewShownSelector } from "./epics/preview/selectors";

const EventProps = {
    desktop: 'Desktop'
};

const PreviewTrackingEvents = {
    [PREVIEW_LOADED]: {
        eventName: PreviewEventIds.PREVIEW,
        isDisabled: (state) => !PreviewShownSelector(state),
        propsHandler: (appState) => ({
            [EventProps.desktop]: IsDesktopPreviewSelector(appState),
        })
    },
    [DESKTOP_PREVIEW]: {
        eventName: PreviewEventIds.PREVIEW,
        propsHandler: () => ({
            [EventProps.desktop]: true
        })
    },
    [MOBILE_PREVIEW]: {
        eventName: PreviewEventIds.PREVIEW,
        propsHandler: () => ({
            [EventProps.desktop]: false
        })
    },
};

export {
    PreviewTrackingEvents
};
