/* @flow */

const _BONEAUTH = 'BoneAuth';
const _WSBCLUSTERINFO = '_wsbclusterinfo';
const _THIRDPARTYAUTH = '_thirdpartyauth';

module.exports = {
    _BONEAUTH,
    _WSBCLUSTERINFO,
    _THIRDPARTYAUTH
};
