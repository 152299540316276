import React from 'react';
import cx from 'classnames';
import styles from './FileChooserHeader.css';
import type { FileChooserHeaderPropTypes } from "../flowTypes";
import { FcUploadPaneCom } from './FcUploadPaneCom';
import { FcBreadCrumbsPaneCom } from "./FcBreadCrumbsPane";

class FileChooserHeader extends React.Component<FileChooserHeaderPropTypes> {
    render() {
        const {
            hideUploadPane = false,
            title,
            viewMode,
            className,
            breadCrumbsClassName,
            uploadComboboxClassName,
            headlessMode,
            isFreeOneComVideoFileChooser,
        } = this.props;

        return (
            <div className={cx(styles.header, className)}>
                <div style={{ display: hideUploadPane ? 'none' : 'block' }}>
                    <FcUploadPaneCom
                        isFreeOneComVideoFileChooser={isFreeOneComVideoFileChooser}
                        title={title}
                        uploadMenuClassName={uploadComboboxClassName}
                        headlessMode={headlessMode}
                    />
                </div>
                {
                    !isFreeOneComVideoFileChooser ? <FcBreadCrumbsPaneCom
                        // @ts-ignore
                        viewMode={viewMode}
                        isFreeOneComVideoFileChooser={isFreeOneComVideoFileChooser}
                        className={breadCrumbsClassName}
                    /> : <div />
                }
            </div>
        );
    }
}

export default FileChooserHeader;
