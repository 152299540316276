import { DataPageRef } from "../index";
import { DataSite } from "../DataSite";

type CanonicalUrlParams = {
    publicRootUrl: null | undefined | string,
    siteMap: DataSite,
    dropHtmlExtension: boolean,
    item: DataPageRef
}

export const getCanonicalUrl = ({
    publicRootUrl,
    siteMap,
    dropHtmlExtension,
    item,
}: CanonicalUrlParams) => {
    const arrPagePath = siteMap.getPageRefUrlPath(item.id);
    /**
     * publicRootUrl is sent from the backend during the server side previe generation
     * To handle preview from the frontend and calls to generateHtml, canonical url is created as relative
     */

    let canonicalUrl = publicRootUrl || '/';

    if (siteMap.isHomePageId(item.pageId)) {
        // Do nothing
    } else {
        canonicalUrl += arrPagePath.join("/");

        if (Array.isArray(item.items) && item.items.length) {
            canonicalUrl += '/';
        } else if (!dropHtmlExtension) {
            canonicalUrl += ".html";
        }
    }

    return canonicalUrl;
};
