import type { ShutterstockKindT } from "../shutterstock/types";
import type { AppState } from "../../../redux/modules/flowTypes";
import type { GetImagesQuery } from "../../../../dal/flowTypes";
import { Lit } from "../../../lit";
import { normalizedCurrentLangaugeAppSel } from "../../TopBar/epics/languages/selectors";
import { UnsplashImageOrientation } from "../../../../dal/constants";
import { AllCategoryId } from "../shutterstock/components/ImagesCategories";
import { pathSelector } from "../../../utils/pathSelector";

const baseSel = (kind, path = []) => pathSelector([Lit.fileChooser, kind, ...path]);

export const unsplashQueryAppSel = (kind: ShutterstockKindT, isCategoryClicked: boolean) => (appState: AppState): GetImagesQuery => {
    const
        unsplash = baseSel(kind)(appState),
        filters = unsplash[Lit.filters],
        currentLanguage = normalizedCurrentLangaugeAppSel(appState),
        query = {
            ...filters,
            [Lit.language]: currentLanguage,
            [Lit.search]: isCategoryClicked ? '' : filters.search.value,
        };

    if (query[Lit.category] === AllCategoryId) {
        delete query[Lit.category];
    }

    if (unsplash[Lit.isPortraitMode] !== unsplash[Lit.isLandscapeMode]) {
        query[Lit.orientation] = unsplash[Lit.isPortraitMode] ?
            UnsplashImageOrientation.VERTICAL : UnsplashImageOrientation.HORIZONTAL;
    }

    return query;
};

