import { makeNodeRender, wrapperRender } from './render/index';
import type { Stylesheet } from '../flowTypes';
import Node from '../parser/tree/Node';

export default (
    nodes: Array<Node>,
    defaultGlobalStyles: Array<Stylesheet>,
    wrappedItemsForRender: Record<string, any>,
    componentRenderer: null | undefined | Function,
    editMode: boolean = true,
    shouldKeepSpan: boolean = false
): string => {
    const nodeRender = makeNodeRender(defaultGlobalStyles, editMode, shouldKeepSpan);
    const toNestedHtml = (nodes, firstNode) =>
        nodes.reduce((html, node, index) => {
            const {
                childNodes
            } = node;

            const content = toNestedHtml(childNodes, firstNode && !index);

            return html + nodeRender(node, content);
        }, '');

    let html = nodes.reduce((html, node, index) => {
        const wrappedHtml = wrapperRender(wrappedItemsForRender[index]);
        const content = toNestedHtml(node.childNodes, true);
        return html + wrappedHtml + nodeRender(node, content);
    }, '');

    // render last wrapped component;
    if (wrappedItemsForRender[nodes.length]) {
        html += wrapperRender(wrappedItemsForRender[nodes.length]);
    }
    return html;
};
