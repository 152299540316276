// @ts-nocheck
import getHorizontalAlign from './getHorizontalAlign';

export default components => components.reduce((cols, component) => {
    // getHorizontalAlign result is of the form { [alignment]:true } (eg: { left: true })
    let alignment = getHorizontalAlign(component);
    if (alignment.left) {
        alignment = 'left';
    } else if (alignment.right) {
        alignment = 'right';
    } else {
        alignment = 'center';
    }

    return { ...cols, [alignment]: (cols[alignment] || []).concat(component) };
}, {});
