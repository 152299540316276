import { useEffect, DependencyList } from 'react';
import { useStore } from 'react-redux';

/**
 * Subscribes to redux store events
 *
 * @param effect
 * @param type
 * @param deps
 */
export default function useReduxEffect(effect, type, deps: DependencyList = []) {
    const store = useStore();

    const handleChange = () => {
        const state = store.getState();
        const action = state.action;
        if (!action) { return; }
        const expectedActions = Array.isArray(type) ? type : [type];
        if (
            expectedActions.includes(action.type)
        ) {
            effect(action);
        }
    };

    useEffect(() => {
        const unsubscribe = store.subscribe(handleChange);
        return () => unsubscribe();
    }, deps);
}
