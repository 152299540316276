import type { Border } from "../../../../src/mappers/border/flowTypes";
import * as colorMapper from "../Base/color";
import type { BorderIn } from "./flowTypes";

/**
 * Old border width: [top, right, bottom, left]
 */

const to = (border: null | undefined | BorderIn): null | undefined | Border => {
    const corners = border && border.corners;
    return border && {
        style: border.style ? border.style[0] : null,
        color: border.color && colorMapper.toHsl(border.color[0]),
        width: border && border.width,
        themeColor: border.themeColor,
        corners: corners && [corners.topLeft, corners.topRight, corners.bottomRight, corners.bottomLeft]
    };
};

const back = (border: null | undefined | Border): null | undefined | BorderIn => {
    if (!border) {
        return null;
    }
    const corners = border && border.corners;

    return border && {
        style: border.style ? [border.style, border.style, border.style, border.style] : null,
        color: border.color && [border.color, border.color, border.color, border.color],
        width: border.width,
        themeColor: border.themeColor,
        corners: corners && {
            topLeft: corners[0],
            topRight: corners[1],
            bottomRight: corners[2],
            bottomLeft: corners[3]
        }
    };
};

export { to, back };
