// @flow

const difference = require('ramda/src/difference');
const flip = require('ramda/src/flip');
const keys = require('ramda/src/keys');
const pipe = require('ramda/src/pipe');

// extracted from https://www.one.com/en/order country dropdown
const ALL_COUNTRIES = {
    AF: "Afghanistan",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia",
    BQ: "Bonaire, Saint Eustatius and Saba",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island (Bouvetoya)",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    VG: "British Virgin Islands",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    CV: "Cape Verde",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands",
    CO: "Colombia",
    KM: "Comoros",
    CD: "Congo (Democratic Republic of the)",
    CG: "Congo (Republic of the)",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: "Cote D’Ivoire (Ivory Coast)",
    CW: "Curacao",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    TL: "East Timor",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Ethiopia",
    FK: "Falkland Islands (Malvinas)",
    FO: "Faroe Islands",
    FJ: "Fiji Islands",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadaloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island and McDonald Islands",
    VA: "Holy See (Vatican City State)",
    HN: "Honduras",
    HK: "Hong Kong",
    HR: "Hrvatska (Croatia)",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KW: "Kuwait",
    KG: "Kyrgyz Republic",
    LA: "Lao",
    LV: "Latvia",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libyan Arab Jamahiriya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MK: "Macedonia",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia",
    MD: "Moldova",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestinian Territory",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn Island",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Reunion",
    RO: "Romania",
    RU: "Russian Federation",
    RW: "Rwanda",
    BL: "Saint Barthelemy",
    MF: "Saint Martin",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SX: "Sint Maarten (Dutch Part)",
    SK: "Slovakia (Slovak Republic)",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia and the South Sandwich Islands",
    KR: "South Korea",
    SS: "South Sudan",
    ES: "Spain",
    LK: "Sri Lanka",
    SH: "St. Helena",
    KN: "St. Kitts and Nevis",
    LC: "St. Lucia",
    PM: "St. Pierre and Miquelon",
    VC: "St. Vincent and the Grenadines",
    SR: "Suriname",
    SJ: "Svalbard & Jan Mayen Islands",
    SZ: "Swaziland",
    SE: "Sweden",
    CH: "Switzerland",
    TW: "Taiwan",
    TJ: "Tajikistan",
    TZ: "Tanzania",
    TH: "Thailand",
    TG: "Togo",
    TK: "Tokelau (Tokelau Islands)",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks and Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    US: "United States",
    UM: "United States Minor Outlying Islands",
    UY: "Uruguay",
    VI: "US Virgin Islands",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VN: "Viet Nam",
    WF: "Wallis and Futuna Islands",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
    AX: "Åland Islands",

    // google countries
    AC: "Ascension Island",
    IC: "Canary Islands",
    EA: "Ceuta &amp; Melilla",
    CP: "Clipperton Island",
    CU: "Cuba",
    DG: "Diego Garcia",
    IR: "Iran",
    XK: "Kosovo",
    LB: "Lebanon",
    KP: "North Korea",
    SD: "Sudan",
    SY: "Syria",
    TA: "Tristan da Cunha",
    VE: "Venezuela"
};

const ALL_COUNTRIES_CODES/*: $ObjMapi<{[$Keys<typeof ALL_COUNTRIES>]: string}, <K, V>(K, *) => K> */ =
    Object.keys(ALL_COUNTRIES).reduce((a, k) => ({ ...a, [k]: k }), {});

const GROUP_1_COUNTRIES = [
    ALL_COUNTRIES_CODES.AT,
    ALL_COUNTRIES_CODES.BE,
    ALL_COUNTRIES_CODES.DK,
    ALL_COUNTRIES_CODES.FI,
    ALL_COUNTRIES_CODES.DE,
    ALL_COUNTRIES_CODES.GR,
    ALL_COUNTRIES_CODES.IS,
    ALL_COUNTRIES_CODES.NL,
    ALL_COUNTRIES_CODES.NO,
    ALL_COUNTRIES_CODES.PT,
    ALL_COUNTRIES_CODES.SE,
    ALL_COUNTRIES_CODES.CH,
    ALL_COUNTRIES_CODES.FR,
    ALL_COUNTRIES_CODES.LU,
    ALL_COUNTRIES_CODES.MC
];
const GROUP_2_COUNTRIES = [ALL_COUNTRIES_CODES.GB];
const GROUP_3_COUNTRIES = [ALL_COUNTRIES_CODES.US, ALL_COUNTRIES_CODES.CA, ALL_COUNTRIES_CODES.AU];
const GROUP_5_COUNTRIES = [ALL_COUNTRIES_CODES.IT, ALL_COUNTRIES_CODES.ES];
const GROUP_4_COUNTRIES = pipe(
    keys,
    flip(difference)(GROUP_1_COUNTRIES),
    flip(difference)(GROUP_2_COUNTRIES),
    flip(difference)(GROUP_3_COUNTRIES),
    flip(difference)(GROUP_5_COUNTRIES)
)(ALL_COUNTRIES_CODES);

const multiLineAddressSeparator = '\n';
const singleLineAddressSeparator = ', ';

module.exports = {
    ALL_COUNTRIES,
    ALL_COUNTRIES_CODES,
    GROUP_1_COUNTRIES,
    GROUP_2_COUNTRIES,
    GROUP_3_COUNTRIES,
    GROUP_4_COUNTRIES,
    GROUP_5_COUNTRIES,
    multiLineAddressSeparator,
    singleLineAddressSeparator,
};
