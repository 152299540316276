import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

import { FOOTER_BAR_HEIGHT } from "../../Pages/styles";

export const useStyles = makeStyles(
    (theme: Theme) => ({
        rootContainer: {
            userSelect: "none",
            width: "100%",
            background: theme.palette.custom.colorDoeskin,
            position: "relative",
            padding: "20px 30px",
            [theme.breakpoints.up("sm")]: {
                padding: "30px 40px"
            }
        },
        stickyContainer: {
            position: "absolute",
            margin: "0px auto",
            left: 0,
            right: 0,
            zIndex: 1000,
            [theme.breakpoints.down("sm")]: {
                bottom: FOOTER_BAR_HEIGHT
            },
            [theme.breakpoints.up("sm")]: {
                top: 0
            }
        },
        content: {
            position: "relative",
            maxWidth: "1386px",
            margin: "auto"
        },
        icon: {
            width: "40px",
            height: "40px",
            backgroundSize: "40px",
            [theme.breakpoints.up("md")]: {
                width: "48px",
                height: "48px",
                backgroundSize: "48px"
            }
        },
        bannerTitle: {
            fontSize: "18px",
            lineHeight: "32px",
            fontWeight: 400
        },
        bannerDescription: {
            marginTop: "4px",
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 400,
            [theme.breakpoints.down("sm")]: {
                fontSize: "15px",
                lineHeight: "24px"
            }
        }
    }),
    { name: "DashboardBanner" }
);
