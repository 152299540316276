import * as PagesIds from './ids';
import * as mobileView from '../../../../PropertiesPanel/view/MobileView/page';
import CODE from "../../kind";
import { ComponentNames } from "../../../constants";

export default {
    [PagesIds.MAIN]: ComponentNames[CODE],
    [PagesIds.HTML_CODE]: "msg: component.code.htmlCode {Edit code}",
    [PagesIds.PLACEMENT]: "msg: component.code.placement {Placement}",
    [mobileView.id]: mobileView.title
};
