import { toHsl } from '../../../dal/pageMapAdapter/mappers/Base/color';
import type { CategoryType, MiniPaletteHex, MiniPaletteHsl } from './flowTypes';
import type { Color } from '../../mappers/flowTypes';
import { areSameColors } from "../../mappers/color";

const COLOR_CATEGORIES: Array<CategoryType<MiniPaletteHex>> = [
    {
        key: "minimalist",
        title: "msg: theme.minimalist.title {Minimalist}",
        palettes: [
            ["C58F56", "E6D8CA"],
            ["9DA2AE", "4E68A5"],
            ["667371", "EBC898"],
            ["AED7B3", "726E67"],
            ["2A66A1", "81B9D6"],
        ],
    },
    {
        key: "rustic",
        title: "msg: theme.rustic.title {Rustic}",
        palettes: [
            ["656739", "989B40"],
            ["804D41", "BD8855"],
            ["B03D2E", "EAC089"],
            ["555088", "B6802C"],
            ["64A49D", "82403E"],
        ],
    },
    {
        key: "modern",
        title: "msg: theme.modern.title {Modern}",
        palettes: [
            ["4555FF", "31377A"],
            ["D51900", "FF7461"],
            ["205E63", "E16C40"],
            ["8B9066", "DEE126"],
            ["092D7E", "FFDFE8"],
        ],
    },
    {
        key: "soft",
        title: "msg: theme.soft.title {Soft}",
        palettes: [
            ["C8D4CC", "719898"],
            ["B78879", "ECCDC4"],
            ["7E7EB7", "E6C5D6"],
            ["94944C", "BFD6AB"],
            ["AE74A0", "BCCCC8"],
        ],
    },
];

export const CATEGORIES_INPUT: Array<CategoryType<MiniPaletteHsl>> = COLOR_CATEGORIES.map(category => {
    const { key, title, palettes } = category;

    return {
        key,
        title,
        palettes: palettes.map((palette) => [toHsl(palette[0]), toHsl(palette[1])])
    };
});

export const getSuggestedColorsForColorPicker = (givenAccentColor: Color) => {
    const allColors = [
        ...CATEGORIES_INPUT.map(({ palettes }) => palettes[0][0]),
        ...CATEGORIES_INPUT.map(({ palettes }) => palettes[1][0]),
    ];
    const acc: any[] = [];
    for (let i = 0; (i < allColors.length && acc.length < 5); i++) {
        if (!areSameColors(givenAccentColor, allColors[i])) {
            acc.push(allColors[i]);
        }
    }
    return acc;
};

export const makeColorCategoryFromConceptPallete = (palettes): Array<CategoryType<MiniPaletteHsl>> => {
    return [{
        key: 'concept',
        title: '',
        palettes: palettes.map((palette) => [toHsl(palette[0]), toHsl(palette[1])])
    }];
};
