type Patch = {
    // rev: number;
    etag: string;
};

export default <T>(model: T, patch: Patch): T => {
    const
        proto = Object.getPrototypeOf(model),
        newModel = { ...model, etag: patch.etag };
    Object.setPrototypeOf(newModel, proto);

    return newModel;
};
