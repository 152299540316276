/* eslint-disable no-useless-escape */

import * as pageIds from '../pageIds';
import pageTitles from '../pageTitles';
import {
    IMAGE_SLIDER_PP_FULL_WIDTH,
    IMAGE_SLIDER_PP_FULL_WIDTH_MARGIN,
    IMAGE_SLIDER_PP_FULL_WIDTH_MAX_WIDTH
} from "../../actionTypes";
import { factoryFullWidthPP } from "../../../fullWidthUtils";

const
    id = pageIds.FULL_WIDTH,
    title = pageTitles[id],
    view = factoryFullWidthPP(IMAGE_SLIDER_PP_FULL_WIDTH, IMAGE_SLIDER_PP_FULL_WIDTH_MARGIN, IMAGE_SLIDER_PP_FULL_WIDTH_MAX_WIDTH);

export { id, title, view };
