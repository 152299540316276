import React from 'react';
import styles from './LinkPageTab.css';
import { LinkPageForm } from '../../../../linkPageForm/view';
import type { NewPageDialogLinkPageProps } from "../../../flowTypes";

type Props = { dispatch: Dispatch } & NewPageDialogLinkPageProps;

export default (props: Props) => {
    return (
        <div>
            <LinkPageForm {...props} className={styles.linkPageForm} />
        </div>
    );
};
