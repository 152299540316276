import * as R from 'ramda';
import type { VideoComponent } from "../flowTypes";
import { getDefultSettingValue } from "../constants";

const settingsReducer = (
        component: VideoComponent,
        action: any
    ): VideoComponent => {
        const { payload: { setting } } = action,
            invertValue = R.evolve({ value: R.not }),
            whenRefIsEqualsToSettingInvertValue = R.when(R.propEq('ref', setting), invertValue);

        return R.evolve({
            state: {
                settings: R.ifElse(
                    R.find(R.propEq('ref', setting)),
                    R.map(whenRefIsEqualsToSettingInvertValue),
                    R.append({ 'ref': setting, value: !getDefultSettingValue(setting) })
                )
            }
        },
        component);
    },
    muteVideoByDefault = (
        updatedComponent: VideoComponent,
        autoPlayKey: string,
        mutedKey: string
    ): boolean => {
        const { state: { settings } } = updatedComponent;
        const isAutoPlayOn = R.find(R.propEq('ref', autoPlayKey), settings).value;
        const isMuted = R.find(R.propEq('ref', mutedKey), settings).value;

        return isAutoPlayOn && !isMuted;
    };

export {
    settingsReducer as default,
    muteVideoByDefault
};
