/* eslint-disable max-len */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useIntl } from '../../../../view/intl';

import styles from './newPageDialog.css';
import { subscriptionDataSelector } from '../../../App/epics/subscriptionData/selectors';
import {
    getPageLimitBySubscriptionType,
    getSectionLinksLimitBySubscriptionType,
    isBasicSubscription
} from '../../../App/epics/subscriptionData/utils';
import Msg from "../../../../view/intl/Msg";
import type { SubscriptionData } from '../../../../redux/modules/flowTypes';
import { findOutMoreLabel } from '../../../ComponentTierManager/view/findOutMoreLabel';

type Props = {
    trackingSource: string
}

export const UpgradeMessageBanner = (props: Props) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const subscriptionData: SubscriptionData = useSelector(subscriptionDataSelector);
    const { subscriptionType } = subscriptionData;
    const isBasic = isBasicSubscription(subscriptionType);
    const {
        trackingSource
    } = props;

    const getTitle = () => {
        if (isBasic) {
            return intl.msgJoint("msg: component.facebookFeedGallery.starterFeature {Starter feature}");
        } else {
            return intl.msgJoint("msg: component.facebookFeedGallery.premiumFeature {Premium feature}");
        }
    };

    const getDescription = () => {
        const maxPageLimit = getPageLimitBySubscriptionType(subscriptionType);
        const maxSectionLimit = getSectionLinksLimitBySubscriptionType(subscriptionType);

        const findOutMore = findOutMoreLabel({ dispatch, trackingSource, subscriptionData });

        const pageString = maxPageLimit === 1 ? `${maxPageLimit} page` : `${maxPageLimit} pages`;

        return (
            <Msg
                k="newPage.upgrageMessage"
                params={{ pageString, sectionLimit: maxSectionLimit, findOutMore }}
            >
                {
                    `Your current plan allows you to publish {pageString} and {sectionLimit} section links. Upgrade to publish more pages and section links on your website and to get access to other great features. {findOutMore}`
                }
            </Msg>
        );
    };

    return (
        <div className={styles.upgradeMsgContainer}>
            <div className={styles.premiumIconContainer}>
                <div className={styles.premiumIcon} />
            </div>
            <div className={styles.upgradeMsgContent}>
                <div className={styles.upgradeMsgTitle}>
                    {getTitle()}
                </div>
                <div className={styles.upgradeMsgDesc}>
                    {getDescription()}
                </div>
            </div>
        </div>
    );
};
