import type { CalcRenderer } from "../../Workspace/epics/componentsProps/flowTypes";
import type { CodeCalcRenderPropsResult, CodeComponent } from "./flowTypes";

type CodeCalcRenderProps = CalcRenderer<CodeComponent, void, void, CodeCalcRenderPropsResult>
const stylesForOverflowingIconAndText = (component) => {
    let codeModifiedStyles = {
        text: {},
        container: {}
    };
    if (component.width < 20 || component.height < 38) {
        codeModifiedStyles.container = {
            overflow: 'hidden'
        };
    }
    if (component.width < 33 || component.height < 40) {
        codeModifiedStyles.text = {
            display: 'none'
        };
    }
    return codeModifiedStyles;
};

const calcRenderProps: CodeCalcRenderProps = ({ component }) => {
    return {
        component,
        style: stylesForOverflowingIconAndText(component)
    };
};

export default calcRenderProps;
