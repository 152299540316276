import makeEpic from "../../../../epics/makeEpic";
import {
    OPEN_ONECOME_TEMPLATE_ACTION,
    addOneComTemplatesApiAction,
    openAddOnecomTemplateDialogAction
} from "../actions";

export const ADD_ONECOM_TEMPLATE_VAT = 'ADD_ONECOM_TEMPLATE_VAT';

export const addOnecomTemplateEpic = makeEpic({
    defaultState: null,
    valueActionType: ADD_ONECOM_TEMPLATE_VAT,
    updaters: [
        {
            conditions: [OPEN_ONECOME_TEMPLATE_ACTION],
            reducer: ({ state }) => ({
                state,
                multipleActionsToDispatch: [
                    openAddOnecomTemplateDialogAction(),
                    addOneComTemplatesApiAction()
                ]
            })
        }
    ]
});
