/**
 * Please update the version in mveModalVersionConfig for forcibly showing the modal popup again to the user.
 */

import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import { OPEN_MOBILE_VIEW_EDITOR_WELCOME_MODAL,
    TOGGLE_MVE_MODAL_DO_NOT_SHOW_AGAIN,
    BACK_TO_DESKTOP_EDITOR_FROM_MOBILE_EDITOR } from "../../actionTypes";
import { openDialog, closeDialog } from "../../../App/actionCreators/index";
import { MOUSE_UP_ON_PREVIEW_BUTTON, MOBILE_PREVIEW } from "../../../App/actionTypes";
import { MVE_WELCOME_MODAL_DIALOG_ID } from "../../ids";
import localStorage from "../../../../utils/localStorage";
import { mobileViewEditorVersion } from "./mveModalVersionConfig";

export type modalStateType = {
    doNotShowAgain: boolean
}

const
    defaultState: modalStateType = {
        doNotShowAgain: false,
    },
    localStorageKey = 'mveModalVersion';

function setLocalStorageDataForWelcomeModal(doNotShowAgain) {
    localStorage.set(localStorageKey, {
        mobileViewEditorVersion,
        doNotShowAgain
    });
}

function shouldShowWelcomeDialog() {
    const localStorageData = localStorage.get(localStorageKey);
    if (localStorageData &&
        localStorageData.mobileViewEditorVersion === mobileViewEditorVersion &&
        localStorageData.doNotShowAgain) {
        return false;
    }
    setLocalStorageDataForWelcomeModal(false);
    return true;
}

const mobileViewEditorWelcomeModalEpic = makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                OPEN_MOBILE_VIEW_EDITOR_WELCOME_MODAL
            ],
            reducer: () => {
                return {
                    state: defaultState,
                    actionToDispatch: shouldShowWelcomeDialog() ? openDialog(MVE_WELCOME_MODAL_DIALOG_ID) : null
                };
            }
        },
        {
            conditions: [
                TOGGLE_MVE_MODAL_DO_NOT_SHOW_AGAIN
            ],
            reducer: ({ state }) => {
                const doNotShowAgain = !state.doNotShowAgain;
                setLocalStorageDataForWelcomeModal(doNotShowAgain);
                return {
                    state: { doNotShowAgain }
                };
            }
        },
        {
            conditions: [
                BACK_TO_DESKTOP_EDITOR_FROM_MOBILE_EDITOR
            ],
            reducer: () => {
                return {
                    state: defaultState,
                    actionToDispatch: closeDialog()
                };
            }
        },
        {
            conditions: [
                MOUSE_UP_ON_PREVIEW_BUTTON,
                MOBILE_PREVIEW
            ],
            reducer: () => {
                return {
                    state: defaultState,
                    actionToDispatch: closeDialog()
                };
            }
        }
    ]
});

export {
    mobileViewEditorWelcomeModalEpic as default,
    mobileViewEditorWelcomeModalEpic
};
