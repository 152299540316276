import React from "react";
import * as R from "ramda";
import * as mp from "../../../../../mappers/path";
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import ColorGradientSettings from "../../../../presentational/ColorGradientSettings/index";
import BackgroundImageSettings from '../../../../presentational/BackgroundImageSettings/index';
import * as pageIds from "../pageIds";
import pagesTitles from "../pagesTitles";
import * as actionTypes from "../../actionTypes";
import * as selectors from "../../../../Workspace/epics/stylesheets/selectors";
import { alphaToOpacity } from "../../../Background/utils/index";
import { getCellToReadValues, getGlobalstyleById } from "../../utils";
import type { PropertyPanelViewProps } from "../../flowTypes";
import { THEME_COLOR_OVERRIDE_COLOR_PICKER } from '../../../../ColorPicker/constants';

const DEFAULT_OPACITY = 1;

const
    id = pageIds.Background,
    title = pagesTitles[id],
    view = (props: PropertyPanelViewProps) => {
        const { stylesheets, selectedComponent, tableEditModeState, dispatch } = props,
            { commonCellsData } = selectedComponent,
            cell = getCellToReadValues(selectedComponent, tableEditModeState),
            cellStyle = cell.style || commonCellsData.style,
            globalStyle = getGlobalstyleById(stylesheets)(cellStyle.globalId);

        let cellData;
        if (cell.style) {
            cellData = cell;
        } else {
            cellData = commonCellsData;
        }

        let color,
            opacity,
            gradient,
            removeOpts = {
                colorRemoveAction: actionTypes.TABLE_CELL_BACKGROUND_COLOR_REMOVED,
                gradientColorRemoveAction: actionTypes.TABLE_CELL_BACKGROUND_GRADIENT_REMOVED
            },
            asset, repeat, position, size;

        color = selectors.getBackgroundColor([mp.style])(cellData);
        opacity = selectors.getBackgroundColorOpacity([mp.style])(cellData);
        gradient = selectors.getBackgroundGradient([mp.style])(cellData);
        asset = selectors.getBackgroundAsset([mp.style])(cellData);
        repeat = selectors.getBackgroundRepeat([mp.style])(cellData);
        position = selectors.getBackgroundPosition([mp.style])(cellData);
        size = selectors.getBackgroundSize([mp.style])(cellData);

        if (!color && !gradient) {
            color = selectors.getBackgroundColor([])(globalStyle);
            opacity = selectors.getBackgroundColorOpacity([])(globalStyle) || DEFAULT_OPACITY;
            removeOpts = R.omit(['colorRemoveAction'], removeOpts);
            gradient = selectors.getBackgroundGradient([])(globalStyle);
            removeOpts = R.omit(['gradientColorRemoveAction'], removeOpts);
        }

        return (
            <Page>
                <VerticalSpacer />
                {
                    props.themeSettingsData.autoColorMode
                        ? <ColorGradientSettings
                            colorPickerKind={THEME_COLOR_OVERRIDE_COLOR_PICKER}
                            themeColorsData={props.themeColorsData}
                            themeColor={R.pathOr(null, ['style', 'block', 'background', 'colorData', 'themeColor'], cellData)}
                            gradientTheme={R.pathOr(
                                null,
                                ['style', 'block', 'background', 'colorData', 'gradient', 'themeColor'],
                                cellData
                            )}

                            colorChangeAction={actionTypes.TABLE_CELL_BACKGROUND_COLOR_CHANGED_AUTO_COLOR}
                            colorRemoveAction={actionTypes.TABLE_CELL_BACKGROUND_COLOR_UNSET_AUTO_COLOR}

                            gradient={gradient}
                            gradientColorChangeAction={actionTypes.TABLE_CELL_BACKGROUND_GRADIENT_CHANGED_AUTO_COLOR}
                            gradientColorRemoveAction={actionTypes.TABLE_CELL_BACKGROUND_GRADIENT_UNSET_AUTO_COLOR}
                            gradientDirectionChangeAction={actionTypes.TABLE_CELL_BACKGROUND_GRADIENT_DIRECTION_CHANGED}
                            gradientFadePointChangeAction={actionTypes.TABLE_CELL_BACKGROUND_GRADIENT_FADE_POINT_CHANGED}

                            opacity={alphaToOpacity(opacity)}
                            opacityChangeAction={actionTypes.TABLE_CELL_BACKGROUND_OPACITY_CHANGED_AUTO_COLOR}

                            dispatch={dispatch}
                        />
                        : <ColorGradientSettings
                            color={color}
                            colorChangeAction={actionTypes.TABLE_CELL_BACKGROUND_COLOR_CHANGED}

                            gradient={gradient}
                            gradientColorChangeAction={actionTypes.TABLE_CELL_BACKGROUND_GRADIENT_CHANGED}
                            gradientDirectionChangeAction={actionTypes.TABLE_CELL_BACKGROUND_GRADIENT_DIRECTION_CHANGED}
                            gradientFadePointChangeAction={actionTypes.TABLE_CELL_BACKGROUND_GRADIENT_FADE_POINT_CHANGED}

                            opacity={alphaToOpacity(opacity)}
                            opacityChangeAction={actionTypes.TABLE_CELL_BACKGROUND_OPACITY_CHANGED}

                            {...removeOpts}

                            dispatch={dispatch}
                        />
                }
                <VerticalSpacer />
                <BackgroundImageSettings
                    asset={asset}
                    assetChangeAction={actionTypes.TABLE_CELL_BACKGROUND_ASSET_CHANGED}
                    assetRemoveAction={actionTypes.TABLE_CELL_BACKGROND_ASSET_REMOVED}

                    repeat={repeat}
                    assetRepeatChangeAction={actionTypes.TABLE_CELL_BACKGROUND_ASSET_REPEAT_CHANGED}

                    position={position}
                    assetPositionChangeAction={actionTypes.TABLE_CELL_BACKGROUND_ASSET_POSITION_CHANGED}

                    size={size}
                    assetSizeChangeAction={actionTypes.TABLE_CELL_BACKGROUND_ASSET_SCALE_CHANGED}

                    dispatch={dispatch}
                />
            </Page>
        );
    };

export { id, title, view };
