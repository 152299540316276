import React from 'react';
import { connect } from "react-redux";
import Cell from './Cell';
import { resourceCellMapDispatchToProps } from "../../utils";
import { makeClickHandler } from '../../../makeClickHandler';
import type { ListResourceCellProps } from "../../flowTypes";

class ResourceCell extends React.Component<ListResourceCellProps> {
    onClick: React.MouseEventHandler;

    constructor() {
        // @ts-ignore
        super();
        this.onClick = makeClickHandler({
            onClick: () => {
                const { resource, toggleResourceSelection } = this.props;
                toggleResourceSelection(resource);
            },
            onDbClick: () => this.props.onDbClick()
        });
    }

    render() {
        return (
            <Cell onClick={this.onClick} {...this.props} />
        );
    }
}

export default connect(null, resourceCellMapDispatchToProps)(ResourceCell);
