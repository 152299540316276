import React from "react";
import type { StylesheetsIdToNameMap } from "../../../Workspace/epics/stylesheets/flowTypes";

const optionRenderer = (value: string, label: string, stylesheetsIdToNameMap: StylesheetsIdToNameMap) => (
    <div className={stylesheetsIdToNameMap[value]}>
        {label}
    </div>
);

const linkOptionRenderer = optionRenderer;

export { optionRenderer, linkOptionRenderer };
