import Url from "../../../../../../utils/Url";
import urlBuild from "../../../../../../utils/urlBuild";

type Query = {
    loginTarget: string,
    targetDomain: string,
    username?: string
}

export const getWebshopLoginUrl = (loginUrlFromConfig, loginTarget, targetDomain, email) => {
    const loginUrlParts = Url(loginUrlFromConfig);
    const query: Query = { loginTarget, targetDomain };

    if (email) query.username = email;

    const loginUrl: string = urlBuild(
        loginUrlParts.path,
        { scheme: loginUrlParts.protocol, host: loginUrlParts.host, query }
    );
    return loginUrl;
};
