import cx from "classnames";
import getCenteredDialog from "../../../DialogManager/getCenteredDialogConfig";
import { configurationDialogFactory } from '../configurationDialogFactory';
import { DialogWidth, TwitterDialogHeight } from '../constants';
import { WIDGETS_TWITTER_TIMELINE_SET_LINK } from "./actionTypes";
import { validateLinkOrCode } from '../utils';
import { isTimelineLinkValid, isListLinkValid } from './utils/linkValidator';
import { parseTwitterCode } from './utils/parseTwitterCode';
import Icons from '../view/Icons.css';
import { WidgetsTwitterKind } from "./kind";

const validateTwitterData = validateLinkOrCode([isTimelineLinkValid, isListLinkValid], parseTwitterCode);

const
    configurationDialogId = "WIDGETS_TWITTER_CONFIG_DIALOG",
    configurationDialogView = configurationDialogFactory({
        saveAction: WIDGETS_TWITTER_TIMELINE_SET_LINK,
        dialogIconClass: cx(Icons.twitter, Icons.dialog),
        dialogTitle: "msg: widgets.twitter.label {X}",
        info: "msg: widgets.twitter.config.info {Add an X timeline or post to your page.}",
        subInfo: "msg: widgets.twitter.config.copyPaste {Go to X (www.twitter.com). Find the timeline or post you want to share, copy the URL and paste it below.}", // eslint-disable-line max-len
        learnMore: "msg: widgets.twitter.config.learnMore {https://help.one.com/hc/en-us/articles/9340463749393}",
        inputPlaceHolder: "msg: widgets.twitter.config.placeHolder {Paste X embed code}",
        isLinkValid: validateTwitterData,
        widgetType: WidgetsTwitterKind,
        termsOfService: "msg: widgets.twitter.config.termsOfService {By using this widget, you agree to X Corp.\'s {developerAgreement} and {developerPolicy}.}", // eslint-disable-line max-len
        developerAgreement: "msg: widgets.twitter.config.developerAgreement {https://developer.twitter.com/en/developer-terms/agreement}",
        developerPolicy: "msg: widgets.twitter.config.developerPolicy {https://developer.twitter.com/en/developer-terms/policy}",
    }),
    configurationDialog = getCenteredDialog({
        width: DialogWidth,
        height: TwitterDialogHeight,
        component: configurationDialogView
    });

export { configurationDialogId, configurationDialog };
