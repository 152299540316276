import cx from 'classnames';

import {
    googleAdsSettingsBuyCampaignButtonClickedAction,
    googleAdsSettingsGoToDashboardButtonClickedAction,
    googleAdsSettingsLearnMoreLinkClickedAction,
    googleAdsSettingsUpdateStatusAction
} from './actions';
import type { SideBarItemDef } from '../../SideBar/types';
import { isFlagMatch } from '../../../utils/configUtils';

import common from "../../../../../../wbtgen/src/view/Theme/common.css";
import imageStyles from "../../Images/imageStyle.css";

export const GOOGLE_ADS_PAGE_SIDE_BAR_ITEM_ID = 'GOOGLE_ADS_PAGE_SIDE_BAR_ITEM_ID';

export const GoogleAdsSideBarItem: SideBarItemDef = {
    id: GOOGLE_ADS_PAGE_SIDE_BAR_ITEM_ID,
    title: 'msg: common.googleAds {Google Ads}',
    header: {
        title: "msg: trackingTab.google.ads.label {Google Ads}",
        description: "msg: dashboard.googleAds.description {Enhance your online visibility and grow your business through paid ads}"
    },
    disabled: isFlagMatch("oneWeb.dashboard.googleAds.enable", false)
};

export const InfoSectionConfig = {
    iconClass: (isCampaignEnabled: boolean) => cx(imageStyles.googleAds, { [imageStyles.disabled]: !isCampaignEnabled }),
    header: 'msg: trackingTab.google.ads.label {Google Ads}',
    description: 'msg: trackingTab.google.ads.Msg {Promote your website online with ads that convert, and measure your success.}',
    learMoreActionLink: {
        label: 'msg: common.learnMore {Learn more}',
        url: 'msg: trackingTab.google.ads.learnMoreLink {https://help.one.com/hc/en-us/articles/360000506898-How-do-I-add-the-Google-Ads-tracking-code-to-my-Website-Builder-site-}',
        action: googleAdsSettingsLearnMoreLinkClickedAction()
    },
};

export const BgColorSet = {
    Active: common.colorDoeskin,
    NonActive: common.colorGray_f7,
};

export const IconClassesSet = {
    noAds: cx(imageStyles.googleAdStatus, imageStyles.createAd),
    adCreated: cx(imageStyles.googleAdStatus, imageStyles.adCreated),
    adLive: cx(imageStyles.googleAdStatus, imageStyles.adLive),
    adDisabled: cx(imageStyles.googleAdStatus, imageStyles.adDisabled),
    adRemoved: cx(imageStyles.googleAdStatus, imageStyles.adRemoved),
};

export const HeaderTextSet = {
    noAds: 'msg: trackingTab.google.ads.createAd {Create your first ad in just a few steps}',
    adCreated: 'msg: trackingTab.google.ads.rememberPublish {Remember to publish}',
    adLive: 'msg: trackingTab.google.ads.youAreSet {You’re all set!}',
    adDisabled: 'msg: trackingTab.google.ads.publishToRemove {Publish to remove tracking code}',
    adRemoved: 'msg: trackingTab.google.ads.codeDeactivated {Your tracking code is deactivated}',
};

export const BodyTextSet = {
    noAds: 'msg: trackingTab.google.ads.adCanAppear {Your ad can appear on Google at the moment someone is looking for products or services like yours.}',
    adCreated: 'msg: trackingTab.google.ads.stillNeedPublish {You still need to publish your website to activate the tracking code for you campaign.}',
    adLive: 'msg: trackingTab.google.ads.campaignRunning {Your campaign seems to be running, and the tracking code is published.}',
    adDisabled: 'msg: trackingTab.google.ads.publishToRemoveInfo {Publish your website to remove the tracking code for Google Ads completely. Remember that you need to go to the control panel to cancel the campaign.}',
    adRemoved: 'msg: trackingTab.google.ads.rememberCancel {Remember that you need to go to the control panel to cancel the campaign. Activate tracking for Google Ads again, by activating the toggle and publish your website.}',
};

export const
    BuyCampaignActionButton = {
        label: 'msg: trackingTab.google.ads.buyCampaign {Buy a campaign}',
        url: 'https://www.one.com/admin/google-adwords.do',
        action: googleAdsSettingsBuyCampaignButtonClickedAction(),
    },
    GoToDashboardActionButton = {
        label: 'msg: trackingTab.google.ads.goToDashboard {Go to campaign}',
        url: 'https://www.one.com/admin/google-adwords.do',
        action: googleAdsSettingsGoToDashboardButtonClickedAction(),
    },
    UpdateStatusActionButton = {
        label: 'msg: common.updateStatus {Update status}',
        action: googleAdsSettingsUpdateStatusAction()
    };
