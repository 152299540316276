import React from "react";
import ImageKind from "../../oneweb/Image/kind";
import * as Icon from './Icons.css';
import { ComponentNames } from "../../oneweb/constants";
import { ImageTemplates as Content } from "../../oneweb/Image/expandedPanel/expandedPanelContent";
import type { ExtendedPanelContentProps } from "../view/inserter";

export default {
    kind: ImageKind,
    shortCut: {
        iconClassName: Icon.image,
        text: ComponentNames[ImageKind]
    },
    extendedPanelData: {
        title: 'msg: extendedPanel.imageTitle {Image}',
        // @ts-ignore
        content: (props: ExtendedPanelContentProps) => <Content {...props} />
    }
};
