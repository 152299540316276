import { createSelector } from 'reselect';
import type { VideoComponent, VideoState, VideoSetting } from './flowTypes';
import getVideoIdFromUrl from "./getVideoIdFromUrl";
import { getDefultSettingValue } from "./constants";

export const
    getSettingValue = (settings: Array<VideoSetting>, ref: string): any => {
        const setting = settings.find(setting => setting.ref === ref);
        return setting ? setting.value : getDefultSettingValue(ref);
    },

    stateSelector = (component: VideoComponent): VideoState => component.state,

    settingsSelector = createSelector(
        stateSelector,
        (state: VideoState): Array<VideoSetting> => state.settings
    ),

    videoIdSelector = createSelector(
        settingsSelector,
        (settings: Array<VideoSetting>): string|null => {
            const hrefSettingValue = getSettingValue(settings, "href");
            if (hrefSettingValue && hrefSettingValue.input) {
                return getVideoIdFromUrl(hrefSettingValue.input);
            }
            return null;
        }
    );
