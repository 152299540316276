import React from "react";
export const SvgVimeoCircleFillLight = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <circle cx={24} cy={24} r={24} fill="#ffffff" />
            <path
                fill="#3c3c3c"
                d="M35.582 18.814c-.108 2.344-1.746 5.558-4.913 9.634-3.276 4.255-6.048 6.387-8.316 6.387-1.403 0-2.593-1.295-3.563-3.888-.65-2.377-1.294-4.753-1.944-7.13-.72-2.593-1.492-3.887-2.32-3.887-.179 0-.81.38-1.892 1.134l-1.134-1.46a295.13 295.13 0 003.516-3.133c1.586-1.37 2.776-2.09 3.572-2.165 1.873-.18 3.03 1.1 3.464 3.845.465 2.96.79 4.8.974 5.52.541 2.457 1.134 3.685 1.783 3.685.504 0 1.262-.795 2.274-2.39 1.007-1.591 1.548-2.806 1.623-3.639.146-1.374-.395-2.066-1.623-2.066-.58 0-1.172.132-1.784.396 1.186-3.878 3.445-5.76 6.786-5.653 2.471.066 3.638 1.671 3.497 4.81z"
            />
        </g>
    </svg>
);
