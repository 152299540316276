import { apiAction } from "../../../redux/middleware/api/actions";
import CALL_API from '../../../redux/middleware/api/CALL_API';
import {
    siteSubscriptionDataLoadActionTypes,
    siteSubscriptionDataLoadActionTypesForGoogleAdsCampaign,
    siteSubscriptionMetadataLoadActionTypes,
} from '../actionTypes';

export default function (): any {
    return {
        [CALL_API]: {
            types: siteSubscriptionDataLoadActionTypes,
            endpoint: 'getSubscriptionStatus'
        }
    };
}

export const loadSubscriptionDataForGoogleAdsCampaign = function (): any {
    return {
        [CALL_API]: {
            types: siteSubscriptionDataLoadActionTypesForGoogleAdsCampaign,
            endpoint: 'getGoogleAdsCampaignFromCRM'
        }
    };
};

export const loadSubscriptionMetadataAction = function (): any {
    return apiAction({
        types: siteSubscriptionMetadataLoadActionTypes,
        endpoint: 'getSubscriptionMetadata',
    });
};
