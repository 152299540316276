// @ts-nocheck
import * as R from 'ramda';
import React, { useEffect, useRef } from "react";
import cx from 'classnames';
import workspaceStyle from "../../Workspace.css";
import styles from './WSModernSection.css';
import { MHFCmpsState, ModernSectionComponentsView, RendererContext } from "./ModernSectionsRenderer";
import {
    evaluateElementRectBasedOnSectionEle,
    getEleDimensions,
} from './utils';
import { MCTA_RESIZE_OPTIONS } from '../../../../components/oneweb/Button/constants';
import { BUTTON, SECTION } from '../../../../components/oneweb/componentKinds';
import isStretchComponentKind from '../../../../components/oneweb/isStretchComponentKind';

const FlexComponentView = ({ children, componentId, style, hasSubLayout }) => {
    const {
            modernCmpsMap,
            componentsMapExtension,
            workspaceBBox,
            mhfCmpsData,
            codeComponentsHeadSectionHeight
        } = React.useContext(RendererContext),
        componentEleRef = useRef(null),
        component = modernCmpsMap[componentId],
        componentExtension = componentsMapExtension[componentId] || {},
        positionUpdater = () => {
            const componentEle = componentEleRef.current;
            const sectionElement = componentEle.closest(`[data-kind="${SECTION}"]`)
                || componentEle.closest(`[data-kind="STRIP"]`);

            const workspaceBodyEle = sectionElement.closest(`.${workspaceStyle.body}`);
            const shouldNotUpdate = (workspaceBodyEle && (workspaceBodyEle.style.transform || '').includes('scale'));
            const cmpDimension = evaluateElementRectBasedOnSectionEle(
                componentEle, sectionElement, workspaceBBox, codeComponentsHeadSectionHeight
            );
            let cmpMHFData = {};

            if (component.kind === 'MENU') {
                cmpMHFData = { maxWidth: getEleDimensions(componentEle.parentElement).width, id: componentId };
            }

            const cmpDimensionsChanged = (
                (Math.abs((component.top - cmpDimension.top)) > 1) ||
                (Math.abs((component.left - cmpDimension.left)) > 1) ||
                (!isStretchComponentKind(component.kind) && component.width !== cmpDimension.width) ||
                component.height !== cmpDimension.height
            );

            const mhfDataChanged = !!mhfCmpsData && !(R.equals(cmpMHFData, mhfCmpsData[componentId]));

            /*
            const changedValues = ['top', 'left', 'width', 'height'].reduce((acc, key) => {
                if (component[key] !== cmpDimension[key]) {
                    let vals = [cmpDimension[key], component[key]];
                    if (key === top) {
                        vals = [
                            ...vals,
                            componentEle.getBoundingClientRect().top,
                            sectionElement.getBoundingClientRect().top
                        ];
                    }
                    return {
                        ...acc,
                        [key]: vals
                    };
                }
                return acc;
            }, {});
            */

            if (!shouldNotUpdate && (cmpDimensionsChanged || mhfDataChanged)) {
                MHFCmpsState.update({
                    cmpMHFData,
                    cmpDimension,
                    componentId
                });
            }
        };

    useEffect(positionUpdater);
    let updatedStyle = style;
    if (componentExtension && componentExtension.minDimensions) {
        const { minDimensions: { width } } = componentExtension;
        if (!isNaN(width)) {
            updatedStyle.minWidth = width;
        }
    }
    if (component && component.kind === BUTTON) {
        updatedStyle.minWidth = MCTA_RESIZE_OPTIONS[component.modernLayoutOptions.size].width;
    }

    return (component &&
    <div
        className={cx(styles.flexComponent, { [styles.fullWidth]: hasSubLayout })}
        style={updatedStyle}
        ref={componentEleRef}
    >
        <ModernSectionComponentsView component={component}>
            {children}
        </ModernSectionComponentsView>
    </div>);
};

export {
    FlexComponentView
};
