import { TopBarHeight } from "../../../TopBar/constants";
import { AddIconN } from "../../../../utils/handle/kinds";
import type { CalcTipStyleProps } from '../../flowTypes';

export const PointerDimensions = { height: 14.68, width: 14.68 };

const
    PointerDiagonalHalfLength =
        (Math.sqrt(Math.pow(PointerDimensions.height, 2) + Math.pow(PointerDimensions.width, 2))) / 2,
    getPointerStartingPosition = (tipSize) => (tipSize / 2) - PointerDiagonalHalfLength,
    topOffset = 35,
    spotlightWidth = 200;

export const calcTipTop = (browserHeight: number, tipHeight: number): number =>
    ((browserHeight - TopBarHeight - tipHeight) / 2) + TopBarHeight;

export const calcEditYourSiteTipStyles = ({
    tipClientRect: { height }, browserDimensions = { height: 0, width: 0 }
}: CalcTipStyleProps) => {
    return {
        tipStyle: { top: calcTipTop(browserDimensions.height, height), opacity: 1 },
        pointerStyle: { top: getPointerStartingPosition(height), ...PointerDimensions }
    };
};

export const calcAddingContentTipStyles = ({ tipClientRect: { height } }: CalcTipStyleProps) => {
    return {
        tipStyle: { opacity: 1 },
        pointerStyle: { top: getPointerStartingPosition(height), ...PointerDimensions }
    };
};

export const calcManagePagesTipsStyles = ({
    tipClientRect,
    pagesTreeContainerClientRect: { left, width }
}: CalcTipStyleProps) => {
    return {
        tipStyle: {
            opacity: 1,
            left: left + (width / 2) - (tipClientRect.width / 2)
        },
        spotLightStyles: {
            width: (2 * width),
            marginLeft: Math.round(left - (width / 2) + 6)
        }
    };
};

export const calcSaveTipStyles = ({
    tipClientRect,
    saveBtnClientRect: { left, width },
    browserDimensions
}: CalcTipStyleProps) => {
    return {
        tipStyle: {
            opacity: 1,
            left: 'calc(100% - ' + (browserDimensions.width - (left + (width / 2) - (tipClientRect.width / 2))) + 'px)'
        },
        spotLightStyles: {
            width: (2 * width),
            marginLeft: 'calc(100% - ' + (browserDimensions.width - (left - (width / 2) + 6)) + 'px)'
        }
    };
};

export const calcvideoTutorialTipStyles = ({
    tipClientRect,
    tutorialBtnClientRect: { left, width },
    browserDimensions
}: CalcTipStyleProps) => {
    return {
        tipStyle: {
            opacity: 1,
            left: 'calc(100% - ' + (browserDimensions.width - (left + (width / 2) - (tipClientRect.width / 2))) + 'px)'
        },
        spotLightStyles: {
            width: (2 * width),
            marginLeft: 'calc(100% - ' + (browserDimensions.width - (left - (width / 2) + 6)) + 'px)'
        }
    };
};

export const calcChangingColorsTipStyles = () => ({
    tipStyle: { opacity: 1 },
    pointerStyle: { bottom: 40, ...PointerDimensions }
});

export const calcAddSectionStyles = ({
    tipClientRect,
    selectedComponent: { top },
    scroll: { y: scrollY },
    templateOffset: { x: offsetX },
    handles,
    codeComponentsRendererHeadHeight,
}: CalcTipStyleProps) => {
    const { minX, maxX } = handles.find(({ kind }) => kind === AddIconN);
    const imageWidth = 288;
    return {
        tipStyle: {
            opacity: 1,
            top: TopBarHeight + (top - scrollY) + topOffset + codeComponentsRendererHeadHeight,
            left: offsetX + minX + ((maxX - minX) / 2) - ((tipClientRect.width - imageWidth) / 2)
        },
        spotLightStyles: {
            width: spotlightWidth,
            marginTop: TopBarHeight + (top - scrollY) + topOffset - (tipClientRect.height / 2) + codeComponentsRendererHeadHeight,
            marginLeft: offsetX + minX + ((maxX - minX) / 2) - (spotlightWidth / 2)
        }
    };
};

export const calcMoveSectionStyles = ({
    tipClientRect,
    scroll: { y: scrollY },
    templateOffset: { x: offsetX },
    componentPanel: { panelTop, panelWidth, panelLeft },
    codeComponentsRendererHeadHeight,
}: CalcTipStyleProps) => {
    const iconHeight = 36, iconPadding = 8;
    let tipTop = TopBarHeight + panelTop - (tipClientRect.height / 2) - scrollY + (iconHeight / 2) + codeComponentsRendererHeadHeight;
    let spotlightTop = TopBarHeight + panelTop - scrollY - (spotlightWidth / 2) + (iconHeight / 2) + codeComponentsRendererHeadHeight;
    tipTop = tipTop + (iconHeight * 2) + (iconPadding * 2);
    spotlightTop = spotlightTop + (iconHeight * 2) + (iconPadding * 2);
    return {
        tipStyle: {
            opacity: 1,
            top: tipTop,
            left: offsetX + panelLeft + iconHeight + (iconHeight / 2),
        },
        pointerStyle: { top: getPointerStartingPosition(tipClientRect.height), ...PointerDimensions },
        spotLightStyles: {
            width: spotlightWidth,
            marginTop: spotlightTop,
            marginLeft: offsetX + panelLeft - (spotlightWidth / 2) + (panelWidth / 2),
        }
    };
};

export const calcDuplicateSectionStyles = ({
    tipClientRect,
    scroll: { y: scrollY },
    templateOffset: { x: offsetX },
    componentPanel: { panelTop, panelWidth, panelLeft },
    codeComponentsRendererHeadHeight,
}: CalcTipStyleProps) => {
    const iconHeight = 36, iconPadding = 8;
    let tipTop = TopBarHeight + panelTop - (tipClientRect.height / 2) - scrollY + (iconHeight / 2) + codeComponentsRendererHeadHeight;
    let spotlightTop = TopBarHeight + panelTop - scrollY - (spotlightWidth / 2) + (iconHeight / 2) + codeComponentsRendererHeadHeight;
    tipTop = tipTop + iconHeight + iconPadding;
    spotlightTop = spotlightTop + iconHeight + iconPadding;
    return {
        tipStyle: {
            opacity: 1,
            top: tipTop,
            left: offsetX + panelLeft + iconHeight + (iconHeight / 2),
        },
        pointerStyle: { top: getPointerStartingPosition(tipClientRect.height), ...PointerDimensions },
        spotLightStyles: {
            width: spotlightWidth,
            marginTop: spotlightTop,
            marginLeft: offsetX + panelLeft - (spotlightWidth / 2) + (panelWidth / 2),
        }
    };
};

export const calcDeleteSectionStyles = ({
    tipClientRect,
    scroll: { y: scrollY },
    templateOffset: { x: offsetX },
    componentPanel: { panelTop, panelWidth, panelLeft },
    codeComponentsRendererHeadHeight,
}: CalcTipStyleProps) => {
    const iconHeight = 36;
    let tipTop = TopBarHeight + panelTop - (tipClientRect.height / 2) - scrollY + (iconHeight / 2) + codeComponentsRendererHeadHeight;
    let spotlightTop = TopBarHeight + panelTop - scrollY - (spotlightWidth / 2) + (iconHeight / 2) + codeComponentsRendererHeadHeight;
    return {
        tipStyle: {
            opacity: 1,
            top: tipTop,
            left: offsetX + panelLeft + iconHeight + (iconHeight / 2),
        },
        pointerStyle: { top: getPointerStartingPosition(tipClientRect.height), ...PointerDimensions },
        spotLightStyles: {
            width: spotlightWidth,
            marginTop: spotlightTop,
            marginLeft: offsetX + panelLeft - (spotlightWidth / 2) + (panelWidth / 2),
        }
    };
};

export const calcSplitSectionStyles = ({
    tipClientRect,
    browserDimensions,
    selectedComponent: { top, height },
    scroll: { y: scrollY },
    templateOffset: { x: offsetX },
    codeComponentsRendererHeadHeight,
}: CalcTipStyleProps) => {
    const leftOffset = 30;
    const workspaceViewportHeight = browserDimensions.height - TopBarHeight;
    return {
        tipStyle: {
            opacity: 1,
            top: TopBarHeight + (top - scrollY) + (Math.min(height, workspaceViewportHeight) / 2)
                - (tipClientRect.height / 2) + codeComponentsRendererHeadHeight,
            left: offsetX + leftOffset,
        },
        pointerStyle: { top: getPointerStartingPosition(tipClientRect.height), ...PointerDimensions },
        spotLightStyles: {
            width: spotlightWidth,
            marginTop: TopBarHeight + (top - scrollY) + (Math.min(height, workspaceViewportHeight) / 2)
                - (spotlightWidth / 2) + codeComponentsRendererHeadHeight,
            marginLeft: offsetX - (spotlightWidth / 2) + leftOffset,
        }
    };
};
