export const
    factoryFacebookFeedStylesReducer = (stylePropName: string) =>
        (borderStyleReducerFn: Function) =>
            (state: any, action: any) => ({
                ...state,
                [stylePropName]: {
                    ...borderStyleReducerFn({
                        style: {
                            ...state[stylePropName]
                        }
                    }, action).style
                }
            });
