import { shallowEqual } from "recompose";
import type { Style } from './flowTypes';

export default (styles: Array<Style>): Array<Style> => {
    const squashedStyles: any = [];

    let lastStartMarker = -Infinity;
    let lastEndMarker = -Infinity;

    styles.forEach(style => {
        if (style.start === lastStartMarker && style.end === lastEndMarker) {
            squashedStyles[squashedStyles.length - 1] = {
                ...squashedStyles[squashedStyles.length - 1],
                ...style
            };
        } else if (style.start === lastEndMarker) {
            const styleAsKey = { ...style };
            const lastStyleAsKey = { ...squashedStyles[squashedStyles.length - 1] };

            delete styleAsKey.start;
            delete styleAsKey.end;
            delete lastStyleAsKey.start;
            delete lastStyleAsKey.end;

            if (shallowEqual(styleAsKey, lastStyleAsKey)) {
                squashedStyles[squashedStyles.length - 1].end = style.end;
            } else {
                squashedStyles.push(style);
            }
        } else {
            squashedStyles.push(style);
        }

        // @ts-ignore
        lastStartMarker = style.start;
        // @ts-ignore
        lastEndMarker = style.end;
    });

    return squashedStyles;
};
