import { equals } from "ramda";
import makeEpic from "../../../epics/makeEpic";
import valueActionType from "./valueActionType";
import * as ActionTypes from "../actionTypes";
import { UPDATE_THEME_PALETTE } from "../../Workspace/epics/stylesheets/actionTypes";
import type { EpicState } from "../flowTypes";
import type { EpicUpdaterConfig } from "../../../epics/flowTypes";
import workspaceSaveStatusValueActionType from "../../Workspace/epics/saveStatus/valueActionType";
import { receiveOnly } from "../../../epics/makeCondition";
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";
import { STYLESHEETS_EPIC_VALUE } from "../../Workspace/epics/stylesheets/valueActionType";
import { SAVE_CLICKED } from "../../App/actionTypes";
import { SaveStatus } from "../../Workspace/epics/saveStatus/SaveStatus";
import { undoManagerValueActionType } from "../../../epics/undoManager/valueActionType";
import { openTopBarPopup } from "../../TopBar/actionCreators/index";
import { Popups, CONTRAST_RATIO_LOWER_LIMIT } from "../constants";
import { openContrastAdjustDialog } from "../ContrastAdjustDialog/actionCreators";
import { findContrastRatio } from "../../ThemeGlobalData/utils/commonUtils";
import { MAIN_THEME_COLOR, getThemeColorIndex } from "../../ThemeGlobalData/constants";
import type { Color } from "../../../mappers/flowTypes";
import { THEME_SINGLE_COLOR_CHANGE } from "../actionTypes";
import { getContrastAdjustedColor } from "../../../utils/colorUtils";

const historyLimit = 10;
export const autoColorLeftPanelEpic = makeEpic({
    defaultState: {
        show: false,
        edit: false,
        animation: false,
        initialPalette: null,
        selectedPalette: null,
        undoStackIndex: -1,
        themeHistory: [],
        paletteBeforeEdit: null
    },
    valueActionType,
    updaters: [
        {
            conditions: [
                receiveOnly(undoManagerValueActionType),
                receiveOnly(STYLESHEETS_EPIC_VALUE),
                ActionTypes.SHOW_AUTO_COLOR_LEFT_PANEL
            ],
            reducer: ({ state, values: [{ undoState }, stylesheets] }) => {
                const { accentColor, mainColor, whiteColor, blackColor } = getThemeColorsFromStylesheet(stylesheets),
                    palette = [accentColor, mainColor, whiteColor, blackColor];
                return {
                    state: {
                        ...state,
                        show: true,
                        initialPalette: palette,
                        selectedPalette: undefined,
                        edit: false,
                        animation: false,
                        undoStackIndex: undoState.commandsStackIndex
                    }
                };
            }
        },
        {
            conditions: [ActionTypes.HIDE_AUTO_COLOR_LEFT_PANEL],
            reducer: ({ state }) => ({
                state: { ...state, show: false }
            })
        },
        {
            conditions: [ActionTypes.TURN_ON_EDIT_MODE],
            reducer: ({ state }) => ({
                state: { ...state, edit: true, paletteBeforeEdit: state.selectedPalette || state.initialPalette }
            })
        },
        {
            conditions: [ActionTypes.TURN_OFF_EDIT_MODE],
            reducer: ({ state }) => ({
                state: { ...state, edit: false, paletteBeforeEdit: null }
            })
        },
        {
            conditions: [ActionTypes.TURN_ON_ANIMATION],
            reducer: ({ state }) => ({
                state: { ...state, animation: true }
            })
        },
        {
            conditions: [ActionTypes.TURN_OFF_ANIMATION],
            reducer: ({ state }) => ({
                state: { ...state, animation: false }
            })
        },
        {
            conditions: [ActionTypes.ADD_THEME_HISTORY],
            reducer: ({ state, values: [historyItem] }) => {
                let themeHistory = state.themeHistory;
                const historyItemString = JSON.stringify(historyItem);
                const newHistory = [historyItem];

                // Remove duplicate entry
                for (let i = 0; i < themeHistory.length; i++) {
                    if (JSON.stringify(themeHistory[i]) !== historyItemString) {
                        newHistory.push(themeHistory[i]);
                    }
                }

                if (newHistory.length > historyLimit) {
                    newHistory.pop();
                }

                return {
                    state: { ...state, themeHistory: newHistory }
                };
            }
        },
        {
            conditions: [ActionTypes.HISTORY_POPUP],
            reducer: ({ state }) => ({
                state,
                actionToDispatch: openTopBarPopup({
                    id: Popups.THEME_HISTORY
                })
            })
        },
        {
            conditions: [ActionTypes.SET_SELECTED_PALETTE],
            reducer: ({ state, values: [palette] }) => {
                let multipleActionsToDispatch: Array<Object> = [];
                multipleActionsToDispatch.push({
                    type: ActionTypes.ADD_THEME_HISTORY,
                    payload: palette
                });
                multipleActionsToDispatch.push({
                    type: UPDATE_THEME_PALETTE,
                    payload: palette
                });
                return {
                    state: { ...state, selectedPalette: palette },
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [ActionTypes.ADJUST_CONTRAST_COLOR],
            reducer: ({ state }) => {
                let palette = state.selectedPalette as Color[];
                let accentColor = palette[0];
                let mainColor = getContrastAdjustedColor(accentColor) as Color;
                palette[1] = mainColor;
                let actionToDispatch = {
                    type: THEME_SINGLE_COLOR_CHANGE,
                    payload: {
                        color: mainColor,
                        themeColorName: MAIN_THEME_COLOR
                    }
                };
                return {
                    state: { ...state, animation: true },
                    actionToDispatch
                };
            }
        },
        {
            conditions: [ActionTypes.KEEP_CONTRAST_COLOR],
            reducer: ({ state }) => {
                return {
                    state: { ...state },
                    actionToDispatch: {
                        type: ActionTypes.TURN_OFF_EDIT_MODE
                    }
                };
            }
        },
        {
            conditions: [ActionTypes.SAVE_COLOR_THEME],
            reducer: ({ state, values: [palette] }) => {
                let actionToDispatch: Object | null = null;
                const contrastRatio = findContrastRatio(palette[0], palette[1]);

                if (
                    contrastRatio &&
                    contrastRatio <= CONTRAST_RATIO_LOWER_LIMIT &&
                    JSON.stringify(palette) !== JSON.stringify(state.paletteBeforeEdit)
                ) {
                    actionToDispatch = openContrastAdjustDialog();
                } else {
                    actionToDispatch = {
                        type: ActionTypes.TURN_OFF_EDIT_MODE
                    };
                }

                return {
                    state: { ...state, selectedPalette: palette },
                    actionToDispatch
                };
            }
        },
        {
            conditions: [ActionTypes.DONE_AUTO_COLOR_LEFT_PANEL],
            reducer: ({ state }) => {
                let multipleActionsToDispatch: Array<Object> = [];
                const selectedPalette = state.selectedPalette;

                if (selectedPalette) {
                    multipleActionsToDispatch.push({
                        type: UPDATE_THEME_PALETTE,
                        payload: selectedPalette
                    });
                }

                multipleActionsToDispatch.push({
                    type: ActionTypes.HIDE_AUTO_COLOR_LEFT_PANEL
                });
                return {
                    state: { ...state, initialPalette: selectedPalette },
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [ActionTypes.THEME_SINGLE_COLOR_CHANGE],
            reducer: ({
                state,
                values: [{ color, themeColorName }]
            }: {
                state: Record<string, any>;
                values: [
                    {
                        color: Color;
                        themeColorName: string;
                    }
                ];
            }) => {
                // @ts-ignore It can be a bug
                const { [themeColorName]: oldColor } = getThemeColorsFromStylesheet(state);
                const selectedPalette = state.selectedPalette || state.initialPalette;

                if (oldColor === color || !selectedPalette) {
                    return {
                        state
                    };
                }

                const colorIndex = getThemeColorIndex(themeColorName);
                const newSelectedPalette = selectedPalette.map(palette => palette.slice());
                newSelectedPalette[colorIndex] = color.slice();
                return {
                    state: { ...state, selectedPalette: newSelectedPalette },
                    actionToDispatch: {
                        type: UPDATE_THEME_PALETTE,
                        payload: newSelectedPalette
                    },
                    updateReason: ActionTypes.THEME_SINGLE_COLOR_CHANGE
                };
            }
        },
        {
            conditions: [SAVE_CLICKED, receiveOnly(STYLESHEETS_EPIC_VALUE), receiveOnly(workspaceSaveStatusValueActionType)],
            reducer: ({ state, values: [, stylesheets, saveStatus] }) => {
                const { accentColor, mainColor, whiteColor, blackColor } = getThemeColorsFromStylesheet(stylesheets),
                    palette = [accentColor, mainColor, whiteColor, blackColor];

                if (saveStatus === SaveStatus.CAN_NOT_SAVE || equals(palette, state.initialPalette)) {
                    return {
                        state
                    };
                }

                return {
                    state: { ...state, initialPalette: palette, selectedPalette: palette }
                };
            }
        }
    ] as Array<EpicUpdaterConfig<EpicState, any, any>>
});
