import makeEpic, { fullEpicUndoablePath } from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import * as undoManagerUpdateReasons from '../../../../epics/undoManager/updateReasons';
import pageDatasetLoadedActionType from "../../../App/epics/pageDataset/pageDatasetLoadedActionType";
import ShiftBarValueActionType from '../shiftBarDecos/valueActionType';
import {
    ComponentsMapSelector, ReceiveOnlyComponentsMap,
    ReceiveOnlySelectedComponentsIdsSelector
} from '../componentsEval/selectorActionTypes';
import {
    COMPONENTS_MAP_INITIALIZED, FLUSH_ADJUSTMENT_CACHE, PUSHDOWN_COMPLETE,
    USER_INTERACTION_DONE_ON_COMPONENTS
} from '../componentsEval/actionTypes';
import * as updateReasons from './updateReasons';
import { PAGE_DATA_LOAD_SUCCESS } from "../../../PagesTree/actionTypes";
import { DIRECTORY_CONTENTS_STATUS_FETCH_SUCCESS } from "../../../MissingAssets/epic/directoryContentFetchActions";
import { ANIMATION_FRAME } from "../../../../redux/middleware/raf";
import { WINDOW_MOUSE_MOVE } from '../../../App/actionTypes';
import { WORKSPACE_SCROLL } from '../../actionTypes';
import { COMPONENT_CHANGING } from '../../../../redux/modules/children/workspace/actionTypes';
import { IMAGE_PROP_PANEL_SCALE_CHANGE } from '../../../oneweb/Image/actionTypes';
import Text from '../../../oneweb/Text/kind';
import ContactForm from '../../../oneweb/ContactForm/kind';
import Menu from '../../../oneweb/Menu/kind';
import Gallery from '../../../oneweb/Gallery/kind';
import defaultHeaderFooterHeightsVAT from "../defaultHeaderFooterHeights/valueActionType";
import { receiveOnly } from '../../../../epics/makeCondition';
import { isHeaderSection, isFooterSection } from '../../../oneweb/Section/utils';
import { UPDATE_MHF_COMPONENTS_POSITION } from '../../../ModernLayouts/actionTypes';

const
    actionsToSkip: { [key: string]: boolean } = {
        [DIRECTORY_CONTENTS_STATUS_FETCH_SUCCESS]: true,
        [PAGE_DATA_LOAD_SUCCESS]: true,
        [pageDatasetLoadedActionType.toString()]: true,
        [ShiftBarValueActionType]: true,
        [COMPONENTS_MAP_INITIALIZED]: true,
        [WORKSPACE_SCROLL]: true, // also a transient action which we should ignore
        [COMPONENT_CHANGING]: true,
        [UPDATE_MHF_COMPONENTS_POSITION]: true,
        [IMAGE_PROP_PANEL_SCALE_CHANGE]: true,
        [FLUSH_ADJUSTMENT_CACHE]: true, // non-explicit changes we can skip
        [ANIMATION_FRAME]: true, // This, workspace scroll and mouse move may trigger transient due to
        [WINDOW_MOUSE_MOVE]: true     // shiftbar & resize changes, which we can skip
    },
    TemplateComponentKindsWhoseHeightsNeedToBeSaved = { // these can be saved as these are new actual heights
        [Text]: 1,
        [ContactForm]: 1,
        [Menu]: 1,
        [Gallery]: 1
    },
    shouldSkipAction = actionType => (actionsToSkip[actionType] || actionType.indexOf('MOUSE_DOWN') > -1),
    componentMapChangeReducer = (selectedComponentIds, componentsMap, oldHeightsMap, updateReason?: string) => {
        const
            updatedHeightsMap = { ...oldHeightsMap },
            isPushdown = updateReason && updateReason === updateReasons.PUSH_DOWN;
        let hasChanges = false;

        selectedComponentIds.forEach(cmpId => {
            const
                oldHeights = oldHeightsMap[cmpId] || {},
                { id, kind, inTemplate, height } = componentsMap[cmpId],
                newHeight = Math.round(height);

            if (isPushdown && inTemplate && !TemplateComponentKindsWhoseHeightsNeedToBeSaved[kind]) {
                return; // no height save for this case
            }

            if (newHeight !== oldHeights.displayHeight) {
                updatedHeightsMap[id] = {
                    displayHeight: newHeight,
                    heightToSave: newHeight
                };
                hasChanges = true;
            }
        });

        if (hasChanges) {
            return {
                state: updatedHeightsMap,
                updateReason: updateReason || updateReasons.COMPONENT_HEIGHT_CHANGED
            };
        }

        return { state: oldHeightsMap };
    },
    updateReasonsToSkip = {
        [updateReasons.PUSH_DOWN]: true,
        [updateReasons.COMPONENT_HEIGHT_CHANGED]: true
    },
    epic = makeEpic({
        defaultState: {},
        undo: {
            isUndoableChange: ({ updateReason }) => !updateReasonsToSkip[updateReason],
            undoablePaths: fullEpicUndoablePath,
            amendToLatestPointCases: { ...updateReasonsToSkip }
        },
        valueActionType,
        updaters: [
            {
                conditions: [pageDatasetLoadedActionType],
                reducer: ({ values: [{ template, page }] }) => {
                    const
                        heightsMap = {},
                        updateMap = ({ id, bbox: { top, bottom } }) => {
                            // Assuming that height property was corrupted in old wsb, so we have to use bbox to get correct heights.
                            // as also can be seen in bbox to mapper wbtgen/dal/pageMapAdapter/mappers/Base/bBox.js
                            const height = Math.round(bottom - top);
                            heightsMap[id] = {
                                displayHeight: height,
                                heightToSave: height
                            };
                        };

                    template.items.forEach(updateMap);
                    page.items.forEach(updateMap);

                    return {
                        state: heightsMap,
                        updateReason: undoManagerUpdateReasons.UNDO_INITIAL_STATE
                    };
                }
            },
            {
                conditions: [
                    COMPONENTS_MAP_INITIALIZED,
                    receiveOnly(defaultHeaderFooterHeightsVAT),
                ],
                reducer: ({ values: [
                    { componentsMap }, { isHeader: isDefaultHeaderHeightSet, isFooter: isDefaultFooterHeightSet }
                ], state }) => {
                    const updatedHeightsMap = { ...state };

                    Object.keys(componentsMap).forEach(id => {
                        const { kind, height } = componentsMap[id],
                            newHeight = Math.round(height);
                        if (kind === Gallery ||
                            (isDefaultHeaderHeightSet && isHeaderSection(componentsMap[id])) ||
                            (isDefaultFooterHeightSet && isFooterSection(componentsMap[id]))) {
                        // Gallery updates height during mapping, and rarely customers put components on top of it
                            updatedHeightsMap[id] = {
                                heightToSave: newHeight,
                                displayHeight: newHeight
                            };
                        } else {
                            updatedHeightsMap[id] = {
                                ...updatedHeightsMap[id],
                                displayHeight: newHeight // workspace now has relIn influenced heights
                            };
                        }
                    });

                    return {
                        state: updatedHeightsMap,
                        updateReason: undoManagerUpdateReasons.UNDO_INITIAL_STATE
                    };
                }
            },
            {
                conditions: [ComponentsMapSelector],
                reducer: ({ values: [componentsMap], state, sourceAction }) => {
                    if (shouldSkipAction(sourceAction.type)) {
                        return { state };
                    }
                    return componentMapChangeReducer(Object.keys(componentsMap), componentsMap, state);
                }
            },
            {
                conditions: [
                    ReceiveOnlySelectedComponentsIdsSelector,
                    ReceiveOnlyComponentsMap,
                    USER_INTERACTION_DONE_ON_COMPONENTS
                ],
                reducer: ({ values: [selectedComponentIds, componentsMap], state }) => {
                    return componentMapChangeReducer(selectedComponentIds, componentsMap, state);
                }
            },
            {
                conditions: [
                    ReceiveOnlyComponentsMap,
                    PUSHDOWN_COMPLETE
                ],
                reducer: ({ values: [componentsMap, { componentIds }], state }) => {
                    return componentMapChangeReducer(componentIds, componentsMap, state, updateReasons.PUSH_DOWN);
                }
            }
        ]
    });

export default epic;
