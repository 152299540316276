import * as R from 'ramda';
import executeAction from './executeAction';
import epicsMap from './epicsMap';
import * as updateManager from './updateManager';
import { RECOVER_AFTER_EXCEPTION } from "../redux/recoverAfterException/actionTypes";
import * as exceptionTypes from "../redux/recoverAfterException/exceptionTypes";
import { error } from '../../utils/log';
import { joinDisaptchResults } from "../redux/middleware/joinDispatchResults";
import { mergeEpics } from "./mergeEpics";
import { registerException } from "../debug/index";
import {
    onErrorDuringProcessingActionByEpicMiddleware,
    onSuccessActionProcessedByEpicMiddleware
} from "./bannedEpics";
import { tracePerf } from '../utils/isDebug';

import type { EpicsStateUpdate } from "./flowTypes";
import type { EpicUpdaterEvalExData } from "../debug/flowTypes";

const
    _tracePerf = tracePerf(),
    process = (store, next, action) => {
        let dispatchResults: any = [];

        const
            prevAppState = store.getState(),
            prevEpicsState = prevAppState.epics,
            epicsUpdate: EpicsStateUpdate = {},
            actionsToDispatch = [],
            errors: Array<any> = [];

        if (_tracePerf) {
            console.time('executeAction_' + action.type); // eslint-disable-line
        }
        // @ts-ignore
        executeAction([action], prevEpicsState, epicsUpdate, actionsToDispatch, errors, a => setTimeout(() => store.dispatch(a), 0));
        if (_tracePerf) {
            console.timeEnd('executeAction_' + action.type); // eslint-disable-line
        }
        if (errors.length) {
            errors.map(e => ({
                ...e,
                stateBeforeException: prevAppState,
                action
            })).forEach((exData: EpicUpdaterEvalExData) => {
                onErrorDuringProcessingActionByEpicMiddleware(exData);
                registerException(exData);
            });
            error('Executing action', action, 'failed in epics middleware:', errors);
            dispatchResults = joinDisaptchResults(
                dispatchResults,
                store.dispatch({ type: RECOVER_AFTER_EXCEPTION, payload: { type: exceptionTypes.EPIC } })
            );
            return Promise.reject(errors);
        } else {
            onSuccessActionProcessedByEpicMiddleware();
        }

        const updatedEpicsTypes = Object.keys(epicsUpdate);
        let nextResult;

        if (updatedEpicsTypes.length) {
            const
                updatedEpicsStatesTypes = updatedEpicsTypes.filter(k => epicsUpdate[k].state !== undefined),
                nextEpicsState = mergeEpics(prevEpicsState, epicsUpdate),
                { updateNum, dispose } = updateManager.putUpdate(nextEpicsState);

            // here reducers will pick up their updates
            nextResult = next({
                ...action,
                epicsUpdate: {
                    num: updateNum,
                    updatedEpicsTypes: updatedEpicsStatesTypes.reduce((a, v) => ({ ...a, [v]: true }), {})
                }
            });

            updatedEpicsStatesTypes.forEach(type => {
                const
                    changedEpic = nextEpicsState[type],
                    epic = epicsMap[type];

                if (epic.dispatchOutside) {
                    dispatchResults = joinDisaptchResults(
                        dispatchResults,
                        store.dispatch({
                            type,
                            payload: changedEpic.state,
                            dispatchOutside: true,
                            epicUpdateReason: changedEpic.lastUpdateReason
                        })
                    );
                }
            });

            // than we remove update reference
            dispose();
        } else {
            nextResult = next(action);
        }

        actionsToDispatch.forEach(a => {
            dispatchResults = joinDisaptchResults(
                dispatchResults,
                // we don't need to process this action again, set dispatchOutside to true and ignore on next catch
                store.dispatch(R.assoc('dispatchOutside', true, a))
            );
        });

        return joinDisaptchResults(nextResult, dispatchResults);
    };

export default (store: Store) => (next: Dispatch) => (action: AnyAction): any => { // eslint-disable-line

    if (action.dispatchOutside && !action.dedicatedRender) {
        return next(action);
    }

    return process(store, next, action);
};
