import * as R from "ramda";
import LinkGlobalStyleKind from '../../../../src/components/oneweb/Link/globalStyle/kind';
import * as actionMapper from "../Common/action";
import type { LinkStylesheet } from '../../../../src/components/Workspace/epics/stylesheets/flowTypes';

const
    fixLinkStyleGlobalName = (styles, linkStyle) => {
        const
            { globalId, globalName } = linkStyle,
            style = R.pipe(R.filter(({ id }) => id === globalId), R.head)(styles);

        if (style && style.name && style.name !== globalName) {
            // Matching id found but name is inconsistent
            return R.evolve({ globalName: R.always(style.name) }, linkStyle);
        } else if (!style && styles[0].id && styles[0].name) {
            // No matching style find
            return R.evolve({ globalId: R.always(styles[0].id), globalName: R.always(styles[0].name) }, linkStyle);
        }

        return linkStyle;
    },
    to = (styles: Array<LinkStylesheet>) => R.evolve({
        links: R.map(([start, end, linkAction]) => ({
            start,
            end,
            linkAction: actionMapper.to(linkAction),
            style: fixLinkStyleGlobalName(styles, linkAction.style),
            atype: 'link'
        }))
    }),
    back = R.evolve({
        links: R.map(({ start, end, linkAction, style }) => [
            start,
            end,
            {
                ...actionMapper.back(linkAction),
                style: { ...style, type: LinkGlobalStyleKind }
            }
        ])
    });

export { to, back };
