import * as tooltipActionTypes from "../../Tooltip/actionTypes";
import * as mouseUtils from "../../../utils/mouse";

const tooltipActionGenerator = (actionType, componentKind, e) => ({
    type: actionType,
    payload: { id: `${componentKind}Disabled`, boundingClientRect: mouseUtils.getBoundingClientRect(e) }
});

type Props = {
    componentKind: string,
    dispatch: Dispatch,
    isPremiumComponent ?: boolean,
}

export default ({ componentKind, dispatch, isPremiumComponent = false }: Props) => ({
    onMouseEnter: (e: React.MouseEvent<any>) => {
        dispatch(tooltipActionGenerator(
            // eslint-disable-next-line max-len
            isPremiumComponent ? tooltipActionTypes.SHOW_TOOLTIP_FOR_PREMIUM_COMPONENT_SHORTCUT : tooltipActionTypes.SHOW_TOOLTIP_FOR_COMPONENT_SHORTCUT,
            componentKind,
            e
        ));
    },
    onMouseLeave: () => dispatch({ type: tooltipActionTypes.CLOSE_COMPONENT_LIST_TOOLTIP })
});
