import { createSelector } from 'reselect';
import {
    getInnerWidth,
    getInnerHeight,
    getSpacing,
    getBorderStyle
} from '../../../view/oneweb/commonComponentSelectors';
import { BorderStyles } from "../../presentational/BorderStyle/constants";
import type { ImageComponent } from './flowTypes';
import type { ImageAsset } from "../../App/flowTypes";

export const
    getAsset = ({ asset }: ImageComponent): ImageAsset => asset,
    getAssetHeight = createSelector(
        getAsset,
        (asset: ImageAsset) => {
            if (!asset) {
                throw new Error('No asset which is needed for getAssetHeight()');
            }

            return asset.height;
        }
    ),
    getAssetWidth = createSelector(
        getAsset,
        (asset: ImageAsset) => {
            if (!asset) {
                throw new Error('No asset which is needed for getAssetHeight()');
            }

            return asset.width;
        }
    ),
    getScale = ({ scale }: { scale: number }): number => scale,
    getRotation = ({ rotation }: { rotation: number }): number => rotation,
    getCropLeft = ({ cropLeft }: { cropLeft: number }): number => cropLeft,
    getCropTop = ({ cropTop }: { cropTop: number }): number => cropTop,

    getImageInnerWidth: (args: ImageComponent) => number = createSelector(
        getInnerWidth,
        getSpacing,
        getBorderStyle,
        (innerWidth: number, spacing: Record<string, number>, borderStyle) => (borderStyle === BorderStyles.NONE ?
            innerWidth + spacing.left + spacing.right : // compensate for default implementation of innerWidth
            innerWidth
        )
    ),
    getImageInnerHeight: (args: ImageComponent) => number = createSelector(
        getInnerHeight,
        getSpacing,
        getBorderStyle,
        (innerHeight: number, spacing: Record<string, number> = {}, borderStyle) => (borderStyle === BorderStyles.NONE ?
            innerHeight + spacing.top + spacing.bottom : // compensate for default implementation of innerHeight
            innerHeight
        )
    ),
    getMinZoom = createSelector(
        getRotation,
        getImageInnerWidth,
        getImageInnerHeight,
        getAssetWidth,
        getAssetHeight,
        (rotation, innerWidth, innerHeight, assetWidth, assetHeight) => {
            let
                widthRatio,
                heightRatio;

            if (rotation % 180 === 90) {
                widthRatio = innerWidth / assetHeight;
                heightRatio = innerHeight / assetWidth;
            } else {
                widthRatio = innerWidth / assetWidth;
                heightRatio = innerHeight / assetHeight;
            }
            return Math.max(widthRatio, heightRatio);
        }
    ),
    getMaxZoom: (args: ImageComponent) => number = createSelector(
        getScale,
        getMinZoom,
        (scale, minZoom) => {
            let userHappiness = 2.0, // if set to 1, users will frustrate thinking that zoom slider is immovable. therefore set it to > 1.0.
                moreZoom = Math.ceil(minZoom * 10 * userHappiness) / 10;
            return Math.max(scale, 1.0, moreZoom);
        }
    ),
    getDefaultDimensions: (args: ImageComponent) => Record<string, number> = createSelector(
        getImageInnerWidth,
        getImageInnerHeight,
        (w, h) => {
            return {
                imageWidth: w,
                imageHeight: h,
                innerWidth: w,
                innerHeight: h,
                altWidth: w,
                altHeight: h,
                left: 0,
                top: 0
            };
        }
    ),
    getCropDimensions = createSelector(
        getRotation,
        getScale,
        getCropTop,
        getCropLeft,
        getImageInnerWidth,
        getImageInnerHeight,
        getAssetWidth,
        getAssetHeight,
        (rotation, scale, cropTop, cropLeft, innerWidth, innerHeight, assetWidth, assetHeight) => {
            let
                imageWidth,
                imageHeight;

            if (rotation % 180 === 90) {
                imageWidth = assetHeight * scale;
                imageHeight = assetWidth * scale;
            } else {
                imageWidth = assetWidth * scale;
                imageHeight = assetHeight * scale;
            }

            return {
                imageWidth,
                imageHeight,
                innerWidth,
                innerHeight,
                altWidth: Math.min(imageWidth, innerWidth),
                altHeight: Math.min(imageHeight, innerHeight),
                cropTop: -cropTop,
                cropLeft: -cropLeft
            };
        }
    ),
    getCropModeStyles = createSelector(
        getRotation,
        getCropDimensions,
        getSpacing,
        getBorderStyle,
        (rotation, cropDimensions, spacing: Record<string, number>, borderStyle: string = BorderStyles.NONE) => _getCropModeStyles( // TODO: default values should not be in selectors
            rotation, cropDimensions, spacing, borderStyle, false
        )
    ),
    getBackgroundCropModeStyles = createSelector(
        getRotation,
        getCropDimensions,
        getSpacing,
        getBorderStyle,
        (rotation, cropDimensions, spacing: Record<string, number>, borderStyle: string) => _getCropModeStyles(
            rotation, cropDimensions, spacing, borderStyle, true
        )
    ),
    getStretchModeStyles = createSelector(
        getRotation,
        getDefaultDimensions,
        (rotation, dimensions) => {
            let style: Record<string, string> = {},
                { innerWidth, innerHeight, top, left }: Record<string, any> = dimensions;

            style.width = rotation % 180 === 90 ? `${innerHeight}px` : `${innerWidth}px`;
            style.height = rotation % 180 === 90 ? `${innerWidth}px` : `${innerHeight}px`;

            // CSS Offset
            if (rotation % 180 === 90) {
                top = top + (innerHeight / 2) - (innerWidth / 2);
                left = left + (innerWidth / 2) - (innerHeight / 2);

                style.marginTop = `${top}px`;
                style.marginLeft = `${left}px`;
            }

            if (rotation) {
                style.transform = `rotate(${rotation}deg)`;
            }

            return style;
        }
    );

function _getCropModeStyles(
    rotation: number,
    cropDimensions: Record<string, any>,
    spacing: Record<string, number>,
    borderStyle: string,
    isBackgroundImage: boolean
) {
    let { imageWidth, imageHeight, innerWidth, innerHeight, cropTop, cropLeft } = cropDimensions;

    let style: Record<string, string> = {};

    if (cropLeft + imageWidth < innerWidth) {
        cropLeft -= cropLeft + imageWidth - innerWidth;
    }

    if (cropTop + imageHeight < innerHeight) {
        cropTop -= cropTop + imageHeight - innerHeight;
    }

    if (rotation % 180 === 90) {
        cropTop = cropTop + (imageHeight / 2) - (imageWidth / 2);
        cropLeft = cropLeft + (imageWidth / 2) - (imageHeight / 2);
    }

    if (isBackgroundImage && borderStyle !== BorderStyles.NONE.toLowerCase()) {
        cropTop += spacing.top;
        cropLeft += spacing.left;
    }

    style.width = rotation % 180 === 90 ? `${imageHeight}px` : `${imageWidth}px`;
    style.height = rotation % 180 === 90 ? `${imageWidth}px` : `${imageHeight}px`;

    style.marginTop = `${cropTop}px`;
    style.marginLeft = `${cropLeft}px`;

    if (rotation) {
        style.transform = `rotate(${rotation}deg)`;
    }

    return style;
}
