import * as pageIds from "./pageIds";
import STRIP from "../kind";
import { ComponentNames } from "../../constants";
import bgPageTitles from "../../Background/propertiesPanel/pageTitles";
import { SCROLL_EFFECTS, scrollEffectsTitle } from "../../commonView/ScrollEffects/constants";

export default {
    ...bgPageTitles,
    [pageIds.MAIN]: ComponentNames[STRIP],
    [pageIds.IMAGE_SETTINGS]: 'msg: common.imageSettings {Image settings}',
    [pageIds.BORDER_STYLE]: 'msg: common.borderStyle {Border style}',
    [SCROLL_EFFECTS]: scrollEffectsTitle,
    [pageIds.TITLE]: 'msg: common.sectionTitle {Section title}',
    [pageIds.SHOP_FOOTER]: "msg: common.shopFooter {Shop footer}"
};
