import { ActionType } from './makeCondition';

export default (actionType: ActionType, action: AnyAction): boolean => {
    if (actionType.predicate && !actionType.predicate(action)) return true;
    else if (actionType.whenReason && actionType.whenReason !== action.epicUpdateReason) return true;
    else if (actionType.whenApiTag && actionType.whenApiTag !== action.apiTag) {
        return true;
    }

    return false;
};
