import { pickAll } from 'ramda';
import { makeEpic } from '../../../epics/makeEpic';
import { receiveOnly, when, optional } from '../../../epics/makeCondition';
import { generalGlobalDataEpic } from './generalGlobalDataEpic/generalGlobalDataEpic';
import { OPEN_DIALOG, REPLACE_DIALOG } from '../../../redux/modules/actionTypes';
import { SiteSettingsDialogId } from '../SiteSettingsDialog/constants';
import {
    GENERAL_INFO_CONTACT_EMAIL_INPUT_CHANGED,
    GENERAL_INFO_PHONE_NUMBER_INPUT_CHANGED,
} from './actionTypes';
import {
    GENERAL_GLOBAL_DATA_SET_CONTACT_EMAIL, GENERAL_GLOBAL_DATA_SET_PHONE_NUMBER
} from './generalGlobalDataEpic/actionTypes';
import { isValidEmail } from '../../../utils/validation';
import { telInputValidation } from '../../inputControls/input/commonValidations/telInputValidation';
import type { GeneralInfoTabData } from "./types";

export const generalInfoTabData: GeneralInfoTabData = {
    contactEmail: undefined, phoneNumber: undefined
};

// TODO: replace this API key as it's from a personal account

export const generalInfoTabEpic = makeEpic({
    valueActionType: 'GENERAL_INTO_TAB_EPIC_VALUE',
    defaultState: generalInfoTabData,
    updaters: [
        {
            conditions: [
                receiveOnly(generalGlobalDataEpic.valueActionType),
                optional(when(OPEN_DIALOG, ({ payload }) => payload.dialogId === SiteSettingsDialogId)),
                optional(when(REPLACE_DIALOG, ({ payload }) => payload.dialogId === SiteSettingsDialogId)),
            ],
            reducer: ({ values: [generalGlobalData] }) => ({
                state: pickAll(['contactEmail', 'phoneNumber'])(generalGlobalData),
            }),
        }, {
            conditions: [GENERAL_INFO_CONTACT_EMAIL_INPUT_CHANGED],
            reducer: ({ state, values: [contactEmail] }) => {
                let actionToDispatch;
                if (isValidEmail(contactEmail) || !contactEmail) {
                    actionToDispatch = ({
                        type: GENERAL_GLOBAL_DATA_SET_CONTACT_EMAIL, payload: contactEmail, amendToSelf: true
                    } as PlainAction);
                }
                return { state: { ...state, contactEmail }, actionToDispatch };
            },
        }, {
            conditions: [GENERAL_INFO_PHONE_NUMBER_INPUT_CHANGED],
            reducer: ({ state, values: [phoneNumber] }) => {
                let actionToDispatch;
                if (telInputValidation(phoneNumber).isValid || !phoneNumber) {
                    actionToDispatch = ({
                        type: GENERAL_GLOBAL_DATA_SET_PHONE_NUMBER, payload: phoneNumber, amendToSelf: true
                    } as PlainAction);
                }
                return { state: { ...state, phoneNumber }, actionToDispatch };
            },
        },
    ],
});
