import React from "react";
import cx from "classnames";
import { ONBOARDING_STEP_BACK_BUTTON_PRESSED, ONBOARDING_CONTINUE_PRESSED, ONBOARDING_FILL_LATER_PRESSED } from "../actionTypes";
import { Msg, Intl } from "../../../view/intl/index";
import { Buttons } from "./Buttons";
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import { OnboardingTopBar } from "../../TopBar/view/OnboardingTopBar";
import GMBCategoriesStepRightSideView from "./LottieAnimations/GMBCategoriesStepRightSideView";
import { GmbCategoriesInput } from "./GmbCategoriesInput";
import styles from "./styles.css";
import stepStyles from "./GMBCategoriesStep.css";

export type GMBCategoriesStepProps = {
    gmbCategories: string;
    isForwardDirection: boolean;
    dispatch: Dispatch;
    intl: Intl;
};
export const GMBCategoriesStep = (props: GMBCategoriesStepProps) => {
    const { gmbCategories, isForwardDirection, dispatch, intl } = props,
        selectedCategoriesCount = gmbCategories ? gmbCategories.split(",").length : 0,
        mctaDisabled = !gmbCategories;
    return (
        <div className={styles.contentContainer}>
            <div className={cx(styles.leftContentContainer)}>
                <OnboardingTopBar />
                <div className={styles.flex1} />
                <div className={styles.leftContent}>
                    <div className={styles.textProgress}>
                        <Msg
                            k="onboarding.step"
                            params={{
                                step: 4
                            }}
                        >{`Step {step} of 5`}</Msg>
                    </div>
                    <div className={cx(styles.question)}>
                        <Msg k="onboarding.gmbTitle">Tell us about your site</Msg>
                    </div>
                    <div className={styles.tip}>
                        <Msg k="onboarding.gmbTip">
                            Add a few keywords that describe what your site is about. This will help us to find the right templates for you
                            to choose from later.
                        </Msg>
                    </div>
                    <VerticalSpacer y={38} />
                    <GmbCategoriesInput gmbCategories={gmbCategories} dispatch={dispatch} intl={intl} />
                </div>
                <div className={styles.flex1} />
                <Buttons
                    mctaDisabled={mctaDisabled}
                    mctaHandler={() =>
                        dispatch({
                            type: ONBOARDING_CONTINUE_PRESSED
                        })}
                    sctaHandler={() =>
                        dispatch({
                            type: ONBOARDING_STEP_BACK_BUTTON_PRESSED
                        })}
                    tctaHandler={() =>
                        dispatch({
                            type: ONBOARDING_FILL_LATER_PRESSED
                        })}
                    intl={intl}
                />
            </div>
            <div className={cx(styles.rightContentContainer, stepStyles.rightContentContainer)}>
                <GMBCategoriesStepRightSideView selectedCategoriesCount={selectedCategoriesCount} isForwardDirection={isForwardDirection} />
            </div>
        </div>
    );
};
