import roundDecimalValues from "../roundDecimalValues";
import type { AnyComponent } from "../../redux/modules/children/workspace/flowTypes";

export default (component: AnyComponent): AnyComponent => {
    const { left, top, height, width } = component;
    // Do not call this roundDecimalValues for component directly,
    // because we would want to round only specific things not deep
    let newComponent = {
        ...component,
        ...roundDecimalValues({ left, top, height, width })
    };

    const cmpPropertyUpdate = (property) => {
        if (component[property]) {
            newComponent[property] = roundDecimalValues(component[property]);
        }
    };
    cmpPropertyUpdate('relIn');
    cmpPropertyUpdate('relTo');
    //cmpPropertyUpdate('relPage');
    return newComponent;
};
