import React, { useEffect } from 'react';
import { GOOGLE_MAPS_ZOOM_LEVEL } from '../constants';
import { loadGoogleMapsScript } from "../../../../googleMaps/googleMaps";
import { UPDATE_ADDRESSLOCATION_FROM_PASTE_IFRAME_FIRST_RENDER } from '../actionTypes';
import { type GoogleMapsIFrameViewProps } from '../types';
import styles from './googleMaps.css';
import '../../view/Preview.css';

export const IFrameView = (props: GoogleMapsIFrameViewProps) => {
    const { addressText, addressLocation, height } = props;
    const ref = React.useRef<HTMLDivElement>(null);

    const renderMap = (mapElement, addressLocationCenter) => {
        const { Map, Marker } = google.maps;
        const map = new Map(mapElement, {
            zoom: GOOGLE_MAPS_ZOOM_LEVEL,
            center: addressLocationCenter,
            mapTypeControl: false,
            streetViewControl: false,
            zoomControl: false,
            fullscreenControl: false,
            keyboardShortcuts: false,
            backgroundColor: styles.colorGray_e5
        });

        new Marker({ position: addressLocationCenter, map }); // eslint-disable-line no-new
    };

    useEffect(() => {
        loadGoogleMapsScript()
            .then(() => {
                if (addressText.length && !addressLocation) {
                    const geocoder = new google.maps.Geocoder();
                    geocoder.geocode({ address: addressText })
                        .then(({ results }) => {
                            if (results[0]) {
                                const addressLocationLocalState = {
                                    lat: results[0].geometry.location.lat(),
                                    lng: results[0].geometry.location.lng(),
                                };
                                props.dispatch({
                                    type: UPDATE_ADDRESSLOCATION_FROM_PASTE_IFRAME_FIRST_RENDER,
                                    payload: addressLocationLocalState
                                });
                                renderMap(ref.current, addressLocationLocalState);
                            }
                        });
                } else {
                    renderMap(ref.current, addressLocation);
                }
            });
    }, [addressLocation]);

    return (
        <div ref={ref} style={{ width: "100%", height }} className="widget" />
    );
};
