import cx from 'classnames';
import memo from '../../../../utils/memo';
import { Transition, ShowNavigator, ShowIndicator, CaptionsAlignment, CaptionsPlacement } from './constants';
import { calculateImagesAttributes } from './utils';
import textNormalClassName from '../Text/globalStyle/textNormalClassName';
import type { CalcRenderer } from '../../Workspace/epics/componentsProps/flowTypes';
import type {
    ImageSliderComponent,
    ImageSliderComponentExtension,
    ImageSliderComponentDependsOn,
    ImageSliderCalcRenderPropsResult
} from './flowTypes';
import styles from './view/ImageSlider.css';

type ImageSliderCalcRenderProps = CalcRenderer<ImageSliderComponent, ImageSliderComponentExtension, ImageSliderComponentDependsOn, ImageSliderCalcRenderPropsResult>; // eslint-disable-line max-len

const
    memoCalculateImagesAttributes = memo(calculateImagesAttributes),
    memoMakeJson = memo((
        { autoNext, delay, transition, navigator, indicator }: ImageSliderComponent,
        lazyLoadCount = 3
    ) => ({
        draggable: true,
        simulateTouch: false,
        lazy: {
            loadPrevNext: true,
            loadPrevNextAmount: lazyLoadCount,
            preloaderClass: 'swiper-lazy-custom-preloader'
        },
        preloadImages: false,
        autoplay: autoNext ? {
            delay: delay * 1000,
            disableOnInteraction: false
        } : false,
        loop: true,
        speed: 1200,
        autoHeight: false,
        arrows: (navigator !== ShowNavigator.NEVER),
        dots: (indicator !== ShowIndicator.NEVER),
        effect: transition === Transition.FADE ? 'fade' : 'slide',
        navigation: {
            nextEl: '.arrow.next-arrow',
            prevEl: '.arrow.prev-arrow'
        },
        pagination: {
            el: '.swiper-pagination',
            dynamicBullets: true,
            type: 'bullets',
            clickable: true
        }
    })),
    calcRenderProps: ImageSliderCalcRenderProps = ({
        componentId,
        component,
        componentDependencies,
        componentExtension: { shouldComponentUpdate },
        isWorkspace,
        isMVEFocus,
        previewBackupTime,
        isSelected
    }) => {
        const
            doNotShowAnyNavigation = component.images.length < 2,
            containerClassname = cx({
                [styles.hideNavigator]: doNotShowAnyNavigation || component.navigator === ShowNavigator.NEVER ||
                    component.navigator === ShowNavigator.MOUSEOVER,
                [styles.hideIndicator]: doNotShowAnyNavigation || component.indicator === ShowIndicator.NEVER ||
                    component.indicator === ShowIndicator.MOUSEOVER,
                [styles.showNavigatorOnHover]: component.navigator === ShowNavigator.MOUSEOVER
                    && !doNotShowAnyNavigation,
                [styles.showIndicatorOnHover]: component.indicator === ShowIndicator.MOUSEOVER
                    && !doNotShowAnyNavigation
            }),
            { hdImages, site, missingAssetUrls, globalVariables, templateWidth } = componentDependencies,
            captionTitleBoxClasses = cx({
                [textNormalClassName]: true,
                [styles.imageCaptionLeft]: component.captionsAlignment === CaptionsAlignment.LEFT,
                [styles.imageCaptionCenter]: component.captionsAlignment === CaptionsAlignment.CENTER,
                [styles.imageCaptionRight]: component.captionsAlignment === CaptionsAlignment.RIGHT
            });

        return {
            shouldComponentUpdate,
            captionOnTop: component.captionsPlacement === CaptionsPlacement.TOP,
            images: memoCalculateImagesAttributes({
                component,
                componentId,
                hdImages: isWorkspace ? false : hdImages,
                site,
                previewBackupTime,
                missingAssetUrls,
                isWorkspace
            }),
            json: memoMakeJson(component, isWorkspace ? 1 : 3),
            containerClassname: cx(containerClassname),
            captionTitleBoxClasses,
            containerStyle: { width: component.width, height: component.height },
            captionsEnabled: component.captionsEnabled,
            onClickAction: component.onClickAction,
            width: component.width,
            height: component.height,
            isWorkspace,
            globalVariables,
            isMVEFocus,
            site,
            isStretch: component.stretch,
            fullWidthOption: component.fullWidthOption,
            crop: component.crop,
            templateWidth,
            isSelected,
            originalSize: component.originalSize
        };
    };

export {
    calcRenderProps as default
};
