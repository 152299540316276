/* @flow */

const { isStr, isObj } = require("./isType.js");

/*::
    type PredicatePattern = string | RegExp

    type Predicate = PredicatePattern | {|
        msg?: PredicatePattern,
        name?: PredicatePattern,
        Type?: Object,
    |}
 */

/** Main **/

const testError = (e/*: Error */, predicate/*: Predicate */) => {
    const
        thePredicate = definePredicate(predicate),
        parts = [];

    if (thePredicate.msg) {
        parts.push([e.message, thePredicate.msg]);
    }

    if (thePredicate.name) {
        parts.push([e.name, thePredicate.name]);
    }

    let res = parts.reduce((acc, [str, pattern]) => acc && testPat(str, pattern), true);

    if (thePredicate.Type) {
        res = res && e instanceof thePredicate.Type;
    }

    return res;
};

/** Lib **/

const PredicateKeys = ['msg', 'name', 'Type'];

function definePredicate(predicate) {
    if (isStr(predicate) || predicate instanceof RegExp) {
        return { msg: predicate };
    } else if (isObj(predicate)) {
        const keys = Object.keys(predicate);
        if (keys.find(k => PredicateKeys.indexOf(k) > -1)) {
            return predicate;
        }
    }

    // $FlowFixMe
    throw new Error(`Invalid predicate given: ${predicate}`);
}

function testPat(str, pattern) {
    return pattern instanceof RegExp ? pattern.test(str) : pattern === str;
}

module.exports = { testError };
