import makeEpic from "../../../../../epics/makeEpic";
import { openDialog, closeDialog, loadSubscriptionData } from "../../../../App/actionCreators/index";
import * as actionTypes from "../actionTypes";
import webshopAdminDialogId from "../dialogId";
import valueActionType from "./valueActionType";
import { AppConfigSelector } from "../../../../App/epics/appConfig/selectors";
import type { WebshopAdminDialog as WebshopAdminDialogType } from "../../flowTypes";

import { upgradeDialogOpenedAction } from "../../../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions";
import { SHOP_PAGES_ROUTES } from "../../../../../../../src/dashboard/src/components/Pages/OnlineShop/constants";
import { mapShopApiRouteToUrl, mapShopRouteToUrl } from "../../../../../../../src/dashboard/src/components/Pages/OnlineShop/utils";

import { AUTH_COOKIE_EXISTS } from "../../../../App/epics/auth/actionTypes";
import { SITE_SUBSCRIPTION_DATA_LOAD_SUCCESS } from "../../../../App/actionTypes";
import type { SubscriptionData } from "../../../../../redux/modules/flowTypes";
import { SubscriptionTypes } from "../../../../../constants/app";
import { EMAIL_FROM_AUTH_COOKIE_VALUE } from "../../../../../redux/middleware/cookie/actionTypes";
import { optionalReceiveOnly, receiveOnly } from "../../../../../epics/makeCondition";
import { loginToWebshop } from "../webshopLogin";
import { DialogTypes } from "../../constants";
import {
    PRODUCT_WIDGET_ONBOARDING_SUCCESS,
    PRODUCT_WIDGET_UPDATE_SHOP_SETUP_STATUS,
    PRODUCT_WIDGET_SET_ITEM
} from "../../../ProductWidget/actionTypes";
import { SetupStatusValues, SetupMethod } from "../../../ProductWidget/epics/productSetup/index";
import ProductWidgetKind from "../../../ProductWidget/kind";
import { FEATURED_PRODUCTS_UPDATE_TIMESTAMP } from "../../../FeaturedProducts/actionTypes";
import { HIDE_TOOLTIP_FOR_SINGLE_PRODUCT_COMPONENT } from "../../../../Tooltip/actionTypes";
import webshopActionTypes from "../../reducer/actionTypes";
import ProductWidgetProductSetupValueActionType from "../../../ProductWidget/epics/productSetup/valueActionType";
import { PartnerName } from "../../../../../../../server/shared/partners/PartnerName";
import { getWebshopLoginUrl } from "./utils";
import { WebshopKind } from "../../kind";

export const LOGGED_IN = "LOGGED_IN";
export const NOT_LOGGED_IN = "NOT_LOGGED_IN";

const defaultState: WebshopAdminDialogType & { webshopLoginStatus: string; dialogType: string } = {
    iframeRefreshCounter: 0,
    webshopLoginStatus: NOT_LOGGED_IN,
    dialogType: ""
};

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [AppConfigSelector("oneWeb.webshop.upgradePath"), actionTypes.SHOW_WEBSHOP_UPGRADE],
            reducer: ({ state, values: [upgradePath] }) => {
                return {
                    state: {
                        ...state,
                        dialogType: DialogTypes.UPGRADE_SHOP
                    },
                    multipleActionsToDispatch: [
                        openDialog(webshopAdminDialogId, {
                            webshopAdminUrl: mapShopRouteToUrl(upgradePath),
                            dialogType: DialogTypes.UPGRADE_SHOP
                        }),
                        upgradeDialogOpenedAction({
                            feature: WebshopKind,
                            dialogId: webshopAdminDialogId
                        })
                    ]
                };
            }
        },
        {
            conditions: [actionTypes.SHOW_WEBSHOP_ADMIN_DIALOG],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        dialogType: DialogTypes.MANAGE_SHOP
                    },
                    multipleActionsToDispatch: [
                        closeDialog(),
                        openDialog(webshopAdminDialogId, {
                            webshopAdminUrl: mapShopRouteToUrl(SHOP_PAGES_ROUTES.HOME),
                            dialogType: DialogTypes.MANAGE_SHOP
                        })
                    ]
                };
            }
        },
        {
            conditions: [actionTypes.HIDE_WEBSHOP_ADMIN_DIALOG],
            reducer: ({ state }) => {
                const timestamp = Date.now();
                const multipleActionsToDispatch = [
                    closeDialog(),
                    {
                        type: FEATURED_PRODUCTS_UPDATE_TIMESTAMP,
                        payload: {
                            timestamp
                        }
                    },
                    {
                        type: webshopActionTypes.WEBSHOP_UPDATE_TIMESTAMP_IN_URL,
                        payload: {
                            timestamp
                        }
                    }
                ];
                return {
                    state: {
                        ...state,
                        iframeRefreshCounter: state.iframeRefreshCounter + 1
                    },
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [actionTypes.HIDE_WEBSHOP_UPGRADE_SUCCESS],
            reducer: ({ state }) => {
                return {
                    state,
                    actionToDispatch: closeDialog()
                };
            }
        },
        {
            conditions: [
                AppConfigSelector("oneWeb.auth.loginUrl"),
                AppConfigSelector("oneWeb.webshop.loginTarget"),
                AppConfigSelector("oneWeb.webshop.checkLoginPath"),
                AppConfigSelector("oneWeb.webshop.skipLogin"),
                AppConfigSelector("oneWeb.webshop.appAuthUrl"),
                AppConfigSelector("partnerName"),
                optionalReceiveOnly(EMAIL_FROM_AUTH_COOKIE_VALUE),
                AUTH_COOKIE_EXISTS,
                SITE_SUBSCRIPTION_DATA_LOAD_SUCCESS
            ],
            reducer: ({
                state,
                values: [
                    loginUrlFromConfig,
                    loginTarget,
                    webshopCheckLoginPath,
                    skipLogin,
                    appAuthUrl,
                    partnerName,
                    email,
                    { domain: targetDomain },
                    subscriptionData
                ],
                dispatchAsync
            }) => {
                const subscriptionStatusData: SubscriptionData = subscriptionData;

                if (subscriptionStatusData.subscriptionType !== SubscriptionTypes.DEMO) {
                    let loginUrl = appAuthUrl;
                    if (partnerName === PartnerName.ONECOM) {
                        loginUrl = getWebshopLoginUrl(loginUrlFromConfig, loginTarget, targetDomain, email);
                    }
                    const checkLoginPath = webshopCheckLoginPath ? webshopCheckLoginPath.replace(/{domainName}/g, targetDomain) : "";
                    const promiseLoginToWebshop = skipLogin ? Promise.resolve(true) :
                        loginToWebshop(mapShopApiRouteToUrl(checkLoginPath), loginUrl, partnerName, targetDomain);
                    promiseLoginToWebshop.then((loginResult: boolean) => {
                        dispatchAsync({ type: actionTypes.LOGIN_TO_WEBSHOP, payload: loginResult });
                    });
                }

                return { state };
            }
        },
        {
            conditions: [actionTypes.LOGIN_TO_WEBSHOP],
            reducer: ({ state, values: [loginResult] }) => {
                return {
                    state: { ...state, webshopLoginStatus: loginResult ? LOGGED_IN : NOT_LOGGED_IN }
                };
            }
        },
        {
            conditions: [AppConfigSelector("oneWeb.webshop.onboardingPath"), actionTypes.SHOW_WEBSHOP_ONBOARDING_DIALOG],
            reducer: ({ state, values: [onboardingPath] }) => {
                return {
                    state: {
                        ...state,
                        dialogType: DialogTypes.ONBOARDING
                    },
                    multipleActionsToDispatch: [
                        openDialog(webshopAdminDialogId, {
                            webshopAdminUrl: mapShopRouteToUrl(onboardingPath),
                            dialogType: DialogTypes.ONBOARDING
                        }),
                        { type: HIDE_TOOLTIP_FOR_SINGLE_PRODUCT_COMPONENT }
                    ]
                };
            }
        },
        {
            conditions: [actionTypes.SHOW_WEBSHOP_UPGRADE_SUCCESS],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        dialogType: DialogTypes.UPGRADE_SHOP_SUCCESS
                    },
                    multipleActionsToDispatch: [
                        loadSubscriptionData(),
                        closeDialog(),
                        openDialog(webshopAdminDialogId, {
                            dialogType: DialogTypes.UPGRADE_SHOP_SUCCESS
                        })
                    ]
                };
            }
        },
        {
            conditions: [actionTypes.CLOSE_WEBSHOP_ONBOARDING_DIALOG, receiveOnly(ProductWidgetProductSetupValueActionType)],
            reducer: ({ dispatchAsync, state, values: [{ productId, isWebshopSetupDone }, { setupMethod }] }) => {
                let multipleActionsToDispatch: Action[] = [
                    closeDialog(),
                    {
                        type: webshopActionTypes.WEBSHOP_UPDATE_TIMESTAMP_IN_URL,
                        payload: {
                            timestamp: Date.now()
                        }
                    }
                ];
                if (isWebshopSetupDone) {
                    multipleActionsToDispatch = [
                        ...multipleActionsToDispatch,
                        {
                            type: PRODUCT_WIDGET_UPDATE_SHOP_SETUP_STATUS
                        }
                    ];
                    setTimeout(() => {
                        dispatchAsync({
                            type: PRODUCT_WIDGET_ONBOARDING_SUCCESS,
                            payload: { setupStatus: SetupStatusValues.COMPLETED }
                        });
                    }, 100);
                }
                if (productId && setupMethod === SetupMethod.PRODUCT_WIDGET) {
                    multipleActionsToDispatch = [
                        ...multipleActionsToDispatch,
                        {
                            type: PRODUCT_WIDGET_SET_ITEM,
                            payload: {
                                componentKind: ProductWidgetKind,
                                isUpdate: true,
                                products: [productId].map(id => `product: '${id}'`).join()
                            }
                        }
                    ];
                }

                return {
                    state,
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [actionTypes.HIDE_WEBSHOP_IFRAME_DIALOGS],
            reducer: ({ state }) => {
                let actionToDispatch = {};
                switch (state.dialogType) {
                    case DialogTypes.MANAGE_SHOP:
                    case DialogTypes.UPGRADE_SHOP:
                        actionToDispatch = { type: actionTypes.HIDE_WEBSHOP_ADMIN_DIALOG };
                        break;
                    case DialogTypes.ONBOARDING:
                        actionToDispatch = { type: actionTypes.HIDE_WEBSHOP_ONBOARDING_DIALOG };
                        break;
                    case DialogTypes.UPGRADE_SHOP_SUCCESS:
                        actionToDispatch = { type: actionTypes.HIDE_WEBSHOP_UPGRADE_SUCCESS };
                        break;
                    default:
                        actionToDispatch = {};
                }
                return {
                    state,
                    actionToDispatch
                };
            }
        },
        {
            conditions: [actionTypes.HIDE_WEBSHOP_UPGRADE],
            reducer: ({ state }) => {
                const multipleActionsToDispatch = [
                    closeDialog(),
                ];
                return {
                    state,
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [actionTypes.HIDE_WEBSHOP_ONBOARDING_DIALOG],
            reducer: ({ state }) => {
                const timestamp = Date.now();
                const multipleActionsToDispatch = [
                    closeDialog(),
                    {
                        type: FEATURED_PRODUCTS_UPDATE_TIMESTAMP,
                        payload: {
                            timestamp
                        }
                    },
                    {
                        type: webshopActionTypes.WEBSHOP_UPDATE_TIMESTAMP_IN_URL,
                        payload: {
                            timestamp
                        }
                    }
                ];
                return {
                    state,
                    multipleActionsToDispatch
                };
            }
        }
    ]
});
