import cx from 'classnames';
import React from 'react';
import { Box, Flex } from "../../../view/reflexbox/index";
import VerticalSpacer from '../../../view/common/VerticalSpacer';
import GrayBgContainer from '../../presentational/GrayBgContainer';
import FontFamilySelector from '../../presentational/FontFamily/index';
import HorizontalSpacer from '../../../view/common/HorizontalSpacer';
import TextFormatButtonGroup from "../../presentational/IconButtonsGroup/TextFormat";
import ColorProperty from "../../presentational/ColorProperty/index";
import FontSizeSelector from "../../presentational/FontSize/index";
import { button as CharacterSpacingButton } from '../../presentational/CharacterSpacing/index';
import { button as TextShadowButton } from '../../presentational/TextShadow/index';
import { globalStyleLabels } from './constants';
import { getCharacterSpacingDialogConfigurationDialogId, getTextShadowDialogConfigurationDialogId } from './utils';
import TextGlobalStyleKind from "../../oneweb/Text/globalStyle/kind";
import { Intl } from '../../../view/intl/index';
import styles from "../GlobalstylesDialog.css";
import { Color } from '../../../mappers/flowTypes';

type Props = {
    index: number,
    data: {
        ref: string,
        font: number | string,
        bold: boolean,
        italic: boolean,
        underline: boolean,
        letterSpacing?: number,
        lineHeight: number,
        size: number,
        color: Color
    },
    actions: {
        fontFamilyChangeAction: string,
        toggleBoldAction: string,
        toggleItalicAction: string,
        toggleUnderlineAction: string,
        fontSizeChangeAction: string,
        spacingChangeAction: string,
        lineHeightChangeAction: string,
        colorChangeAction: string,
        shadowColorChangeAction: string,
        shadowRemoveAction: string,
        blurRadiusChangeAction: string,
        shadowOffsetXChangeAction: string,
        shadowOffsetYChangeAction: string
    },
    siteFonts: Record<string, any>,
    dispatch: Dispatch,
    intl: Intl,
    autoColorMode: boolean
}

const getFontSizeSelector = (size, fontSizeChangeAction, dispatch) => <FontSizeSelector
    width={72}
    selected={size}
    onChangeAction={fontSizeChangeAction}
    dispatch={dispatch}
    searchable={false}
/>;

export const renderTextStyleSection = ({
    index,
    data: {
        ref,
        font,
        bold,
        italic,
        underline,
        letterSpacing = 0,
        lineHeight = 1,
        size,
        color
    },
    actions: {
        fontFamilyChangeAction,
        toggleBoldAction,
        toggleItalicAction,
        toggleUnderlineAction,
        fontSizeChangeAction,
        spacingChangeAction,
        lineHeightChangeAction,
        colorChangeAction,
        shadowColorChangeAction,
        shadowRemoveAction,
        blurRadiusChangeAction,
        shadowOffsetXChangeAction,
        shadowOffsetYChangeAction
    },
    dispatch,
    siteFonts,
    intl,
    autoColorMode,
}: Props) => {
    return (
        <Flex key={index}>
            <Box>
                <VerticalSpacer y={28} />
                <div className={styles.title}>{intl.msgJoint(globalStyleLabels[ref])}</div>
                <VerticalSpacer y={10} />
                <GrayBgContainer className={cx(styles.mainStyleSelectors, styles.globalStylesTextGrayBox)}>
                    <Flex align="center">
                        <FontFamilySelector
                            dispatch={dispatch}
                            selected={font}
                            // @ts-ignore
                            siteFonts={siteFonts}
                            onChangeAction={fontFamilyChangeAction}
                            additionalPayload={{ ref, source: TextGlobalStyleKind }}
                        />
                        <HorizontalSpacer />
                        <TextFormatButtonGroup
                            bold={{ isSelected: bold, onClickAction: toggleBoldAction }}
                            italic={{ isSelected: italic, onClickAction: toggleItalicAction }}
                            underline={{ isSelected: underline, onClickAction: toggleUnderlineAction }}
                            dispatch={dispatch}
                        />
                    </Flex>
                    <VerticalSpacer y={10} />
                    <Flex align="center" justify="flex-start">
                        <CharacterSpacingButton
                            dialogId={getCharacterSpacingDialogConfigurationDialogId(ref) as string}
                            spacing={letterSpacing}
                            spacingChangeAction={spacingChangeAction}
                            lineHeight={lineHeight}
                            lineHeightChangeAction={lineHeightChangeAction}
                            dispatch={dispatch}
                        />
                        <HorizontalSpacer />
                        { autoColorMode ?
                            getFontSizeSelector(size, fontSizeChangeAction, dispatch) :
                            <TextShadowButton
                                dialogId={getTextShadowDialogConfigurationDialogId(ref)}
                                shadowColorChangeAction={shadowColorChangeAction}
                                shadowRemoveAction={shadowRemoveAction}
                                blurRadiusChangeAction={blurRadiusChangeAction}
                                shadowOffsetXChangeAction={shadowOffsetXChangeAction}
                                shadowOffsetYChangeAction={shadowOffsetYChangeAction}
                                dispatch={dispatch}
                            />}
                    </Flex>
                    <VerticalSpacer y={10} />
                    { !autoColorMode &&
                    <Flex align="center">
                        { getFontSizeSelector(size, fontSizeChangeAction, dispatch) }
                        <HorizontalSpacer x={178} />
                        <ColorProperty
                            style={{ width: 130 }}
                            label="msg: common.textColor {Text colour}"
                            color={color}
                            onChangeAction={colorChangeAction}
                            dispatch={dispatch}
                        />
                    </Flex>}
                </GrayBgContainer>
            </Box>
            <Box><HorizontalSpacer x={30} /></Box>
        </Flex>
    );
};
