import makeEpic from '../../../../../epics/makeEpic';
import valueActionType from "./valueActionType";
import editFieldsValueActionType from "../editFields/valueActionType";
import { isFormElementsChanged } from "../../utility";
import { ReceiveOnlySelectedComponentSelector } from "../../../../Workspace/epics/componentsEval/selectorActionTypes";

export default makeEpic({
    defaultState: false,
    valueActionType,
    updaters: [
        {
            conditions: [
                ReceiveOnlySelectedComponentSelector,
                editFieldsValueActionType
            ],
            reducer: ({ values: [component, newData] }) => ({
                state: component && newData && isFormElementsChanged(
                    component.formElements,
                    newData.formElements,
                    component.formElementsOrder,
                    newData.formElementsOrder
                )
            })
        }
    ]
});
