import { makeEpic } from "../../../epics/makeEpic";
import { openDialog } from "../../../components/App/actionCreators/index";
import * as actionTypes from "../actionTypes";
import { allInOneDialogId } from "./allInOneDialogId";
import { AllInOneTabName, DoneBtnActionForAllInOneTabs } from "../constants";

const
    defaultState = {
        selectedTab: AllInOneTabName.GENERAL
    };

const allInOneEpic = makeEpic({
    defaultState,
    valueActionType: "WEB_DEV_VALUE_ACTION_TYPE",
    updaters: [
        {
            conditions: [actionTypes.SHOW_ALL_IN_ONE_DIALOG],
            reducer: ({ state }) => ({
                state,
                actionToDispatch: openDialog(allInOneDialogId)
            })
        },
        {
            conditions: [actionTypes.ALL_IN_ONE_TAB_SWITCH],
            reducer: ({ values: [{ selectedTab }], state }) => ({
                state: { ...state, selectedTab }
            })
        },
        {
            conditions: [actionTypes.ALL_IN_ONE_DONE_ACTION],
            reducer: ({ state }) => {
                const
                    multipleActionsToDispatch: Action[] = [],
                    { selectedTab } = state;

                multipleActionsToDispatch.push(...DoneBtnActionForAllInOneTabs[selectedTab]);

                return {
                    state: { ...state, selectedTab: AllInOneTabName.GENERAL },
                    multipleActionsToDispatch
                };
            }
        }
    ]
});

export {
    allInOneEpic
};
