import * as R from 'ramda';
import { globalStyleLabels, types } from "../../../Globalstyles/Text/constants";
import { Intl } from '../../../../view/intl/index';
import kind from './kind';
import { getStylesByType } from "../../../Workspace/epics/stylesheets/selectors";
import type { Stylesheet } from '../../../Workspace/epics/stylesheets/flowTypes';
import type { ComboboxOptions } from '../../../../view/common/Combobox/flowTypes';

export default (styles: Array<Stylesheet>, intl: Intl): ComboboxOptions =>
    R.pipe(
        getStylesByType(kind),
        R.filter(s => s.ref !== types.LOGO),
        R.map(style => ({
            value: style.id,
            label: intl.msgJoint(globalStyleLabels[style.ref]),
            name: style.name
        }))
    )(styles);
