import type { UpgradeDataType } from "../Main/UpgradePlansDialog/types";
import type { SubscriptionData } from '../../../../../wbtgen/src/redux/modules/flowTypes';
import type { SubscriptionTypeT } from "../../../../../wbtgen/src/components/App/epics/subscriptionData/flowTypes";

export { APP_STATUS_LOADING } from '../../app/actionTypes';

export const
    SUBSCRIPTION_DATA_READY_ACTION = 'SUBSCRIPTION_DATA_READY_ACTION',
    SUBSCRIPTION_DATA_ERROR_ACTION = 'SUBSCRIPTION_DATA_ERROR_ACTION',
    SUBSCRIPTION_TYPE_CHANGE_ACTION = "SUBSCRIPTION_TYPE_CHANGE_ACTION",
    INIT_ONLINE_SHOP_DATA_ACTION = "INIT_ONLINE_SHOP_DATA_ACTION",
    SEAMLESS_UPGRADE = 'SEAMLESS_UPGRADE',
    UPGRADE = 'UPGRADE',
    UPGRADE_COMPLETE = 'UPGRADE_COMPLETE',
    UPGRADE_SUCCESS = 'UPGRADE_SUCCESS',
    UPGRADE_FAILURE = 'UPGRADE_FAILURE',
    UPGRADE_SUCCESS_NOTIFICATION_ACTION = "UPGRADE_SUCCESS_NOTIFICATION_ACTION",
    SET_UPGRADE_LISTENER_ACTIONS = "SET_UPGRADE_LISTENER_ACTIONS";

export const
    subscriptionDataReadyAction = (subscriptionData: SubscriptionData) => ({
        type: SUBSCRIPTION_DATA_READY_ACTION,
        payload: subscriptionData
    }),
    subscriptionDataErrorAction = (error: any) => ({
        type: SUBSCRIPTION_DATA_ERROR_ACTION,
        payload: error
    }),
    initOnlineShopDataAction = () => ({ type: INIT_ONLINE_SHOP_DATA_ACTION }),
    subscriptionTypeChangeAction = (subscriptionType: SubscriptionTypeT) => ({
        type: SUBSCRIPTION_TYPE_CHANGE_ACTION,
        payload: subscriptionType
    }),
    seamlessUpgradeAction = (upgradeType: SubscriptionTypeT) => ({
        type: SEAMLESS_UPGRADE, payload: upgradeType
    }),
    upgradeAction = (upgradeData: UpgradeDataType) => ({
        type: UPGRADE, payload: upgradeData
    }),
    upgradeSuccessAction = () => ({ type: UPGRADE_SUCCESS }),
    upgradeFailureAction = () => ({ type: UPGRADE_FAILURE }),
    upgradeSuccessNotificationAction = (params: { source: string; upgradeType: SubscriptionTypeT; }) => ({
        type: UPGRADE_SUCCESS_NOTIFICATION_ACTION,
        payload: params
    }),
    setUpgradeListenerActions = (params: { onUpgrade?: Nullable<Array<Action>>; }) => ({
        type: SET_UPGRADE_LISTENER_ACTIONS,
        payload: params
    });
