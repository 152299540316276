import type { Element, HtmlWriterData } from '../../flowTypes';
import { textParagraphParser } from '../parsers/index';
import listStyleFilter from '../filters/listStyleFilter';
import { executeOnEnclosingMatch } from './utils/executeOnMatch';

export default (data: HtmlWriterData): {
    textParagraphs: Array<Element>,
    listParagraphs: Array<Element>
} => {
    const { text, paras: paraStyles } = data;
    // @ts-ignore
    const listParaStyles = paraStyles.filter(listStyleFilter);

    // Split text into paras
    const paragraphs = textParagraphParser(text);
    const textParagraphs: Element[] = [];
    const listParagraphs: Element[] = [];

    executeOnEnclosingMatch(
        // @ts-ignore
        paragraphs, listParaStyles,
        (paragraph, style, isFirst: boolean) => { if (isFirst) listParagraphs.push(paragraph); },
        paragraph => { textParagraphs.push(paragraph); }
    );

    return {
        textParagraphs,
        listParagraphs
    };
};
