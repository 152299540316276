import { pathSelector } from '../../src/utils/pathSelector';
import { Lit } from '../../src/lit';
import type { AppStateSel } from '../../src/globalTypes';
import type { DemoBarStatusT } from './types';
import type { PathSelectorPath } from '../../src/utils/pathSelector';
import type { AppState } from '../../src/redux/modules/flowTypes';
import { isDemoSubscriptionType } from '../../src/components/App/epics/subscriptionData/isDemoSubscriptionType';
import { subscriptionTypeStateSel, subscriptionStateSel } from '../../src/components/App/epics/subscriptionData/selectors';
import { componentsTierAppSel } from "../../src/components/ComponentTierManager/epics/selectorActionTypes";

const baseSel = (path: PathSelectorPath) => pathSelector([Lit.demo, ...path]);

export const
    demoBarStatusAppSel: AppStateSel<DemoBarStatusT> = baseSel([Lit.bottomBar, Lit.status]),
    trialDaysRemainingAppSel: AppStateSel<number> = baseSel([Lit.bottomBar, Lit.trialDaysRemaining]),
    trialUserSubIDSel: AppStateSel<DemoBarStatusT> = baseSel([Lit.bottomBar, Lit.trialUserSubId]),
    trialUserTokenSel: AppStateSel<DemoBarStatusT> = baseSel([Lit.bottomBar, Lit.trialUserToken]),
    trialUserEmailStatusSel: AppStateSel<boolean> = baseSel([Lit.bottomBar, Lit.trialUserEmailVerified]),
    trialUserEmailSel: AppStateSel<DemoBarStatusT> = baseSel([Lit.bottomBar, Lit.email]),
    trialUserDomainSel: AppStateSel<DemoBarStatusT> = baseSel([Lit.bottomBar, Lit.domain]),
    // @ts-ignore
    isControlPanelTrialUserSel: AppStateSel<DemoBarStatusT> = baseSel([Lit.bottomBar, Lit.controlPanelUser]),
    isDemoSubscriptionTypeAppSel = (appState: AppState): boolean =>
        isDemoSubscriptionType(subscriptionTypeStateSel(appState)),
    trialDomainNameForPurchaseSel: AppStateSel<DemoBarStatusT> = baseSel([Lit.bottomBar, Lit.trialDomainNameForPurchase]),
    demoLoginAppSel = baseSel([Lit.login]),
    trialWsbPackageUpgradeTypeSel = (appState: AppState): string =>
        componentsTierAppSel()(appState).freeTierUpgradeSubscriptionType,
    subscriptionMetadataSel = (appState: AppState): Record<string, any> =>
        subscriptionStateSel(appState).metadata;
