import * as R from "ramda";
import type { ModernLayoutMap } from "../../../../src/components/ModernLayouts/flowTypes";
import { applyMappers, makePlainMappers } from "../../utils";
import * as mp from "../../../../src/mappers/path";
import * as styleMapper from '../Common/style';
import { evolvePath } from "../../../../src/utils/ramdaEx";
import type { AnyComponentData } from "../../../../src/components/App/flowTypes";
import type { ComponentBaseWithKind } from "../../../../src/components/oneweb/flowTypes";
import isStretchComponentKind from "../../../../src/components/oneweb/isStretchComponentKind";
import { isSectionKind } from "../../../../src/components/oneweb/componentKinds";

const plainPropsMapper = makePlainMappers({
    style: 'style',
    scrollEffect: 'scrollEffect',
    mobileHide: 'mobileHide',
    mobileSettings: 'mobileSettings',
    pin: 'pin',
    title: 'title',
    selectedTheme: 'selectedTheme',
    selectedGradientTheme: 'selectedGradientTheme',
    selectedBorderTheme: 'selectedBorderTheme',
    modernLayout: 'modernLayout',
});

const removeScrollEffectFromCmp = (cmp) => R.dissocPath([mp.scrollEffect], cmp),
    setScrollEffectToBgStyle = (cmp) => {
        let newCmp = cmp;
        if (
            cmp.style &&
            cmp.style.background &&
            cmp.style.background.assetData &&
            !cmp.style.background.assetData.hasOwnProperty(mp.scrollEffect)
        ) {
            newCmp = R.assocPath(mp.styleBackgroundAssetDataScrollEffect, (newCmp.scrollEffect || null), newCmp);
        }
        if (newCmp.hasOwnProperty(mp.scrollEffect)) {
            newCmp = removeScrollEffectFromCmp(newCmp);
        }
        return newCmp;
    },
    resetStripPin = (cmp) => {
        const { type, stretch } = cmp,
            isStrip = stretch && !isSectionKind(type);
        return isStrip ? ({ ...cmp, pin: 0 }) : cmp;
    },
    sanitizeModernLayout = (cmp) => {
        const { modernLayout } = cmp;
        if (!modernLayout) { return cmp; }
        const { layoutVersion } = modernLayout;
        const processCmp = (c, col, index) => {
            const { margin } = c;
            const { hPositioned } = col;
            if (margin) {
                return {
                    ...(R.omit(['margin'], c)),
                    style: { margin }
                };
            }

            // TODO: Remove this condition after 1 year
            // for the 0 version modern layout component dont have margin if there are 2 components
            if (
                !hPositioned &&
                !layoutVersion &&
                index !== 0
            ) {
                return {
                    ...c,
                    style: { margin: { top: 35 } }
                };
            }
            return c;
        };
        const processCol = col => {
            const { cmps = [] } = col;
            return {
                ...col,
                // In Older version margin is available on layout component object now we move it to style object
                cmps: cmps.map((c, i) => processCmp(c, col, i))
            };
        };
        const processRow = row => {
            let { cols, style } = row;
            if (style && style.padding) {
                // Row will not have padding
                style = R.omit(['padding'], style);
            }
            return {
                ...row,
                style,
                cols: cols.map(processCol)
            };
        };
        const processLayout = (layout) => {
            const { rows } = layout;
            return {
                ...layout,
                rows: rows.map(processRow)
            };
        };
        const processLayoutMap = (layoutMap: ModernLayoutMap) => {
            return Object.keys(layoutMap).reduce((res, key) => {
                const layout = layoutMap[key];
                return {
                    ...res,
                    [key]: processLayout(layout),
                };
            }, {});
        };
        return {
            ...cmp,
            modernLayout: {
                ...modernLayout,
                layout: processLayoutMap(modernLayout.layout)
            },
            style: {
                ...cmp.style,
                border: null,
            }
        };
    },
    applyStyleMappersToModernLayoutStyles = (component: any, to: boolean, styleMapperInp: Record<'to' | 'back', Function>) => {
        if (component.modernLayout) {
            const { sectionProps } = component.modernLayout,
                styleMapperFn = to ? styleMapperInp.to : styleMapperInp.back,
                basePath = ['modernLayout', 'sectionProps'];
            let updatedCmp = { ...component };
            if (sectionProps.old) {
                updatedCmp = R.assocPath(
                    [...basePath, 'old', 'style'],
                    styleMapperFn(sectionProps.old.style),
                    updatedCmp
                );
            }
            if (sectionProps.new) {
                let newObj = { ...sectionProps.new };
                Object.keys(newObj).forEach(layoutId => {
                    const { style, pin, selectedTheme } = newObj[layoutId];
                    newObj[layoutId] = {
                        style: styleMapperFn(style),
                        pin,
                        selectedTheme
                    };
                });
                updatedCmp = R.assocPath([...basePath, 'new'], newObj, updatedCmp);
            }
            return updatedCmp;
        }
        return component;
    };

export function to(componentData: AnyComponentData): ComponentBaseWithKind {
    let result = applyMappers(
        componentData,
        R.pipe(
            resetStripPin,
            sanitizeModernLayout,
            plainPropsMapper.to,
            evolvePath([mp.style])(styleMapper.to)
        )
    );
    return applyStyleMappersToModernLayoutStyles(
        setScrollEffectToBgStyle(result),
        true,
        styleMapper
    );
}

export function back(component: ComponentBaseWithKind): AnyComponentData {
    let result = applyMappers(
        component,
        R.pipe(
            plainPropsMapper.back,
            evolvePath([mp.style])(styleMapper.back),
            (itemData: AnyComponentData) =>
                (isStretchComponentKind(component.kind, component.stretch) ? ({ ...itemData, stretch: true }) : itemData)
        )
    );
    return applyStyleMappersToModernLayoutStyles(
        removeScrollEffectFromCmp(result),
        false,
        styleMapper
    );
}

export { applyStyleMappersToModernLayoutStyles };
