import { SVG_KIND } from "./kind";
import { svgCalcRenderProps } from './calcRenderProps';
import { svgWorkspaceView as view } from './view/index';
import { getSpecificStyles } from './getSpecificStyles';
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";

import type { SvgComponent } from './flowTypes';
import type { CalcProps } from '../../Preview/flowTypes';

export default {
    kind: SVG_KIND,
    view,
    getSpecificStyles,
    calcProps: ({
        component,
        componentId,
        componentsDependencies,
        previewBackupTime,
        siteMap,
        globalVariables,
        siteSettings,
        globalStyles,
        selectedParentTheme,
    }: CalcProps<SvgComponent>) => svgCalcRenderProps({
        component,
        componentId,
        componentExtension: null,
        componentDependencies: {
            ...componentsDependencies?.[SVG_KIND],
            site: siteMap,
            globalVariables,
            missingAssetUrls: [],
            themeColorsData: getThemeColorsFromStylesheet(globalStyles),
            themeSettingsData: siteSettings.themeSettingsData,
        },
        isWorkspace: false,
        inEditMode: false,
        previewBackupTime,
        selectedParentTheme,
    }),
};
