import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    divider: {
        margin: '24px 0px',
        borderColor: theme.palette.custom.colorGray_ed
    },
    addPPBtn: {
        padding: "12px 24px"
    },
    generatePolicyBtn: {
        padding: "12px 24px",
    },
    diclaimerBtn: {
        textDecoration: "none",
        "&:hover": {
            textDecoration: "none",
        },
    },
    btns: {
        display: "flex",
        gap: "24px",
        marginBottom: "24px"
    },
    policyForCustomers: {
        color: theme.palette.custom.colorDarkSilver
    },
    footer: {
        marginTop: "16px",
        fontSize: "14px",
        display: "flex",
        justifyContent: "space-between"
    },
    countIsOkay: {
        color: theme.palette.custom.colorZompGreen
    },
    countIsNotOkay: {
        color: theme.palette.custom.colorVenetianRed
    }
}), { name: "PrivacyPolicy" });
