import { REGENERATE_TEXT_BUTTON_CLICKED, REGENERATED_TEXT_SELECTED, UPDATE_PROMPT_TEXT } from "../actionTypes";

const regenerateTextButtonClickedAction = (inputText: string, mode: string, instructions?: string) => ({
    type: REGENERATE_TEXT_BUTTON_CLICKED,
    payload: {
        inputText,
        mode,
        instructions
    }
});

const updatePromptAction = (promptText: string) => ({
    type: UPDATE_PROMPT_TEXT,
    payload: {
        promptText
    }
});

const selectGeneratedTextAction = (versionId: string) => ({
    type: REGENERATED_TEXT_SELECTED,
    payload: {
        versionId
    }
});

export {
    regenerateTextButtonClickedAction,
    updatePromptAction,
    selectGeneratedTextAction,
};
