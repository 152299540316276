import { path } from "ramda";
import {
    sectionInsertionPath,
    sectionInsertionProgressPath,
    componentsMapPath,
    componentsMapExtensionPath,
    sectionInsertionPlaceholderPath,
    selectedComponentsIdsPath,
    stateBeforeTransientChangesPath,
    attachmentsPath,
    mhfCmpDataPath,
} from "./paths";

export const
    getComponentsMap = path(componentsMapPath),
    getComponentsMapExtension = path(componentsMapExtensionPath),
    getAttachments = path(attachmentsPath),
    getSectionInsertion = path(sectionInsertionPath),
    getSectionPlaceholderValue = path(sectionInsertionPlaceholderPath),
    getSectionInsertionProgressState = path(sectionInsertionProgressPath),
    getStateBeforeTransientChanges = path(stateBeforeTransientChangesPath),
    getMHFCmpsData = path(mhfCmpDataPath),
    getSelectedComponentsIds = path(selectedComponentsIdsPath);
