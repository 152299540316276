/* @flow */

const { LocaleCountryMap } = require('./constants.js');

const mapLocaleCountry = (locale/*: string */)/*: string */ => {
    let country;

    if (locale.indexOf('_') > -1) {
        country = locale.split('_')[1];
        if (country === 'us' || country === 'uk') {
            country = 'gb';
        }
    } else {
        country = LocaleCountryMap[locale] === true ? locale : LocaleCountryMap[locale];
    }

    return country;
};

module.exports = { mapLocaleCountry };
