/* @flow */

/*::
    type Options = {|
        groups?: number | number[]
    |}
 */

const matchAll = (
    string/*: string */,
    pattern/*: string | RegExp */,
    // $FlowFixMe: TODO ...
    options/*: ?Options */ = {},
) => {
    const regexp = pattern instanceof RegExp && pattern.global
        ? pattern
        : new RegExp(pattern, 'g');

    let res = [];

    let match;
    while ((match = regexp.exec(string)) !== null) { // eslint-disable-line no-cond-assign
        res.push(match);
    }

    if (options.groups) {
        const groups = Array.isArray(options.groups) ? options.groups : [options.groups];
        // $FlowFixMe
        res = res.map(m => (
            groups.length === 1
                // $FlowFixMe
                ? m[groups[0]]
                // $FlowFixMe
                : m.filter((_, mi) => groups.indexOf(mi) > -1)
        ));
    }

    return res;
};

module.exports = {
    matchAll,
};
