import type { Reducer } from "redux";
import type { ShutterstockKindT, ShutterstockState } from "../types";
import { shutterstockReducer } from "./shutterstockReducer";
import { makeInitialStateReducer } from "../../../../redux/makeReducer/makeInitialStateReducer";
import { ShutterstockInitialState } from "./ShutterstockInitialState";
import { isSutterstockKindAction } from "../isSutterstockKindAction";
import type { MakeCombineReducerConfig } from "../../../../redux/makeReducer/flowTypes";

export const makeShutterstockKindReducer = (
    kind: ShutterstockKindT,
    config?: MakeCombineReducerConfig
): Reducer<ShutterstockState, any> => {
    const reducer = shutterstockReducer(config);
    // @ts-ignore
    return makeInitialStateReducer(ShutterstockInitialState, (state, action) => (
        isSutterstockKindAction(kind, action)
            ? reducer(state, action)
            : state
    ));
};
