import React from "react";
import { getFullPageURL } from '../utils';
import type { FacebookPageCalcRenderPropsResult } from "../flowTypes";

export default ({
    pageURL,
    width,
    height,
    tabs,
    hideCover,
    showFacepile,
    smallHeader
}: FacebookPageCalcRenderPropsResult) => {
    const href = getFullPageURL(pageURL);
    return (
        <div
            className="fb-page"
            data-href={href}
            data-width={width}
            data-height={height}
            data-tabs={tabs}
            data-hide-cover={hideCover}
            data-show-facepile={showFacepile}
            data-show-cta={false}
            data-small-header={smallHeader}
            style={{ height }}
        >
            <blockquote cite={href} className="fb-xfbml-parse-ignore">
                <a href={href}>{href}</a>
            </blockquote>
        </div>
    );
};
