import { regexpsValidator } from '../../utils';
import { type LinkValidator } from '../../types';

export const isFacebookLinkValid: LinkValidator = regexpsValidator([
    /^(?:https:\/\/)?www\.facebook\.com\/watch\/\?v=[\d]+/,
    /^(?:https:\/\/)?fb.watch\/[\d\w]+/,
    /^(?:https:\/\/)?www\.facebook\.com\/[\d\w]+\/videos\/[\d]+/,
    /^(?:https:\/\/)?www\.facebook\.com\/[\d\w]+\/photos\//,
    /^(?:https:\/\/)?www\.facebook\.com\/[\d\w]+\/posts\/[\d\w]+/,
]);
