import type { AppStateSel } from "../../../../globalTypes";
import { pathSelector } from "../../../../utils/pathSelector";
import { Lit } from "../../../../lit";
import { ManagedFacebookPageType } from "./types";

export const
    socialAccountsFacebookFeedPageMode: AppStateSel<boolean> = pathSelector(
        [Lit.socialAccounts, Lit.facebookFeed, Lit.isFbPageMode]
    ),
    socialAccountsFacebookFeedManagedPages: AppStateSel<Array<ManagedFacebookPageType>> = pathSelector(
        [Lit.socialAccounts, Lit.facebookFeed, Lit.managedFbPages]
    ),
    socialAccountsFacebookFeedSelectedPageValue: AppStateSel<string> = pathSelector(
        [Lit.socialAccounts, Lit.facebookFeed, Lit.fbSelectedPageValue]
    );
