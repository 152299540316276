import {
    makeCombineReducer,
    makeDefaultStateReducers,
    makeComponentBaseReducers
} from "../../../../redux/makeReducer/index";
import { ReviewViewType } from "../constants";
import kind from '../kind';
import * as actionTypes from '../actionTypes';
import { GoogleReviewsComponent } from "../flowTypes";

export default makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(kind),
        ...makeDefaultStateReducers({
            width: 400,
            height: 400,
            mobileHide: false,
            mobileDown: false,
            reviews: [],
            showReviewButton: true,
            headerText: 'What our customers are saying',
            reviewButtonText: 'Leave a review',
            layoutType: ReviewViewType.LIST,
            buttonThemeSelected: null,
            styleButton: null,
            storeData: {},
            seeMoreText: 'See more',
            seeLessText: 'See less',
            googleReviewsText: 'Google reviews',
            reviewTimeText: {},
        })
    },
    handleActions: {
        [actionTypes.CHANGE_REVIEWS_LINK_TEXT]:
            (component: GoogleReviewsComponent, { payload: seeMoreText }): GoogleReviewsComponent =>
                ({ ...component, seeMoreText }),
        [actionTypes.UPDATE_COMPONENT_LAYOUT]:
            (component: GoogleReviewsComponent, { payload: layoutType }) => ({ ...component, layoutType }),
        [actionTypes.UPDATE_COMPONENT_REVIEWS]:
            (component: GoogleReviewsComponent, { payload: { reviews, storeData } }): GoogleReviewsComponent =>
                ({ ...component, reviews, storeData }),
        [actionTypes.CHANGE_REVIEWS_HEADER_TEXT]:
            (component: GoogleReviewsComponent, { payload: headerText }): GoogleReviewsComponent => ({ ...component, headerText }),
        [actionTypes.TOGGLE_LEAVE_REVIEW_BUTTON]: (
            component: GoogleReviewsComponent
        ): GoogleReviewsComponent => ({ ...component, showReviewButton: !component.showReviewButton }),
        [actionTypes.CHANGE_REVIEW_BUTTON_TEXT]: (
            component: GoogleReviewsComponent, { payload: reviewButtonText }
        ): GoogleReviewsComponent => ({ ...component, reviewButtonText }),
        [actionTypes.CHANGE_REVIEW_BUTTON_THEME]: (
            component: GoogleReviewsComponent, { payload: buttonThemeSelected }
        ): GoogleReviewsComponent => ({ ...component, buttonThemeSelected }),
        [actionTypes.CHANGE_REVIEW_BUTTON_GLOBAL_STYLE]:
            (component: GoogleReviewsComponent, { payload: { stylesheetId, stylesheetName } }): GoogleReviewsComponent =>
                ({ ...component, styleButton: { globalName: stylesheetName, globalId: stylesheetId } }),
    }
});
