import React from 'react';
import { connect } from 'react-redux';
import { makeEpicStateSelector } from "../../../../epics/makeEpic";
import sectionsBlocksVAT from "../../epics/sectionsBlocks/valueActionType";
import HeaderFilterView from "../../configs/Header";
import { WIDGETS_FILTERS } from "../../../oneweb/Widgets/constants";
import { SET_FILTER_VALUE } from "../../epics/widgets/actionTypes";
import { EXTENDED_PANEL_WIDGETS_EPIC } from "../../epics/widgets/valueActionType";
import { injectIntl } from "../../../../view/intl/index";
import styles from './ExpandedPanel.css';

const HeaderView = injectIntl(
    ({ intl, sectionsBlocksState: { listLoaded, hasError }, filterKey, dispatch }) => {
        if (!listLoaded) {
            return null;
        }

        return (
            <HeaderFilterView
                data={WIDGETS_FILTERS}
                title={intl.msgJoint('msg: extendedPanel.widgetsTitle {Widgets}')}
                selected={filterKey}
                actionName={SET_FILTER_VALUE}
                className={styles.widgetsFilters}
                hasError={hasError}
                dispatch={dispatch}
            />
        );
    }
);

const mapStateToProps = (appState) => {
    return {
        sectionsBlocksState: makeEpicStateSelector(sectionsBlocksVAT)(appState),
        filterKey: (makeEpicStateSelector(EXTENDED_PANEL_WIDGETS_EPIC)(appState)).filterKey
    };
};

const Header = connect(mapStateToProps)(HeaderView);

export { Header };
