import { all, fork } from 'redux-saga/effects'; // eslint-disable-line node/file-extension-in-import

import pagesTreeSaga from '../../PagesTree/saga';

function* root(): any {
    yield all([
        fork(pagesTreeSaga)
    ]);
}

export default root;
