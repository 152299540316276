import React from "react";
import { useDispatch } from "react-redux";

import { ErrorModel, Props } from "./ErrorModel";

import { openDialog } from "../../../../App/actionCreators";
import { CONTINUE_TO_TEMPLATES_DIALOG } from "./ContinueToTemplatesDialog";
import { DYNAMIC_TEMPLATE_RESET_ERROR_TYPE } from "../../Epic/actionTypes";

import styles from "./ErrorModel.css";

export const InappropriateLanguageError = () => {
    const dispatch = useDispatch();

    let props: Props = {
        imageClass: styles.inappropriateLanguageErrorIcon,
        title: "msg: component.text.createAIText.dialog.error.unableToCreate {Unable to create text}",
        // eslint-disable-next-line max-len
        description: "msg: component.text.createAIText.dialog.error.inappropriateLanguageError.subContent {Your request doesn't follow OpenAI's content guidelines. Check if it might contain any inappropriate or harmful language and rewrite it to try again.}",
        primaryButtonText: "msg: common.back {Back}",
        primaryButtonAction: () => {
            dispatch({ type: DYNAMIC_TEMPLATE_RESET_ERROR_TYPE });
        },
        secondaryButtonText: "msg: common.browseTemplates {Browse templates}",
        secondaryButtonAction: () => {
            dispatch(openDialog(CONTINUE_TO_TEMPLATES_DIALOG));
        }
    };

    return (
        <ErrorModel {...props} />
    );
};
