import React from "react";
import styles from './Decorations.css';
import type { RedDecorationState } from '../../../components/Workspace/epics/redDecorations/flowTypes';
import { Border } from '../../../components/presentational/Border/Border';
import { makeBorderProps } from "./makeBorderProps";
import { red390 } from '../../../components/variables.css';

type Props = {
    state: RedDecorationState
}
export default function ({ state: { bBoxes, componentBBoxes } }: Props) {
    if (!bBoxes.length) return <div />;
    const decos = bBoxes.map((bbox, i) => {
        const style = {
            left: bbox.left,
            top: bbox.top,
            width: bbox.right - bbox.left,
            height: bbox.bottom - bbox.top
        };
        return <div className={styles.redDecorations} style={style} key={i} />;
    });
    const componentBorders = componentBBoxes.map((bBox, i) =>
        <Border {...makeBorderProps({ bBox, color: red390 })} key={i} />);
    return (
        <div>
            <div>{decos}</div>
            <div>{componentBorders}</div>
        </div>
    );
}
