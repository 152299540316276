import type { SiteSettings } from '../../App/epics/siteSettings/flowTypes';
import type { AnyComponentData } from "../../App/flowTypes";
import { SOCIAL_KIND } from './kind';

export const fixSocialComponentsLinks =
    (siteSettings: SiteSettings, page: { items: Array<AnyComponentData> }) => {
        const { socialData } = siteSettings;

        if (!socialData) return page;

        const { links } = socialData;

        return {
            ...page,
            // TODO: `item.type` type def doesn't allow `SOCIAL_KIND`
            items: page.items.map((item: any) => (item.type === SOCIAL_KIND ?
                {
                    ...item,
                    links: item.links
                        .filter(link => !link.hidden)
                        .map(
                            visibleLink => {
                                const globalDataMatch = links.find(l => l.kind === visibleLink.kind);

                                if (globalDataMatch) {
                                    return { ...visibleLink, id: globalDataMatch.id };
                                }

                                return visibleLink;
                            }
                        )
                }
                : item))
        };
    };

