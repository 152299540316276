export const
    BANNER_CLOSE_BUTTON_CLICKED_ACTION = 'BANNER_CLOSE_BUTTON_CLICKED_ACTION',

    ADD_PENDING_BANNER_ACTION = 'ADD_PENDING_BANNER_ACTION',
    REMOVE_PENDING_BANNER_ACTION = 'REMOVE_PENDING_BANNER_ACTION',
    ALL_BANNERS_CLOSE_ACTION = 'ALL_BANNERS_CLOSE_ACTION';

export const
    bannerCloseButtonClickedAction = (id: string) => ({
        type: BANNER_CLOSE_BUTTON_CLICKED_ACTION,
        payload: { id }
    }),

    addPendingBannerAction = (id: string) => ({
        type: ADD_PENDING_BANNER_ACTION,
        payload: { id }
    }),
    removePendingBannerAction = (id: string) => ({
        type: REMOVE_PENDING_BANNER_ACTION,
        payload: { id }
    }),
    closeAllBannersAction = () => ({
        type: ALL_BANNERS_CLOSE_ACTION
    });
