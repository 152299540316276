import * as styles from "./components/Review/Review.css";

const ReviewsApiStatus = {
    InProgress: 'InProgress',
    Success: 'Success',
    Failed: 'Failed',
    NotStarted: 'NotStarted',
};
const ReviewViewType = {
    SLIDER: 'slider',
    LIST: 'list',
    CARD: 'card'
};

const REVIEW_HEADER_HEIGHT = parseInt(styles.reviewHeaderHeight, 10);
const REVIEW_FOOTER_HEIGHT = parseInt(styles.reviewFooterHeight, 10);
const MAX_HEADER_TEXT_LENGTH = 100;
const MAX_REVIEW_BUTTON_TEXT_LENGTH = 50;
const MAX_LINK_TEXT_LENGTH = 20;
const MIN_HEADER_FONT_SIZE = 18;
const MAX_HEADER_FONT_SIZE = 34;

const DIMENSIONS = {
    [ReviewViewType.SLIDER]: {
        MIN_HEIGHT: 300,
        MAX_HEIGHT: 400,
        MIN_WIDTH: 390,
        MAX_WIDTH: 1050,
    },
    [ReviewViewType.LIST]: {
        MIN_HEIGHT: 115,
        MAX_HEIGHT: 125,
        MIN_WIDTH: 590,
        MAX_WIDTH: 1050
    },
    [ReviewViewType.CARD]: {
        MIN_WIDTH: 250,
        MAX_WIDTH: 400,
        MIN_HEIGHT: 275,
        MAX_HEIGHT: 400
    }
};

const Layouts = [
    {
        id: ReviewViewType.LIST,
        src: 'wbtgen/images/google_reviews_list.png',
        alt: ReviewViewType.LIST,
        label: 'msg: common.list {List}'
    },
    {
        id: ReviewViewType.CARD,
        src: 'wbtgen/images/google_reviews_grid.png',
        alt: ReviewViewType.CARD,
        label: 'msg: common.grid {Grid}'
    },
    {
        id: ReviewViewType.SLIDER,
        src: 'wbtgen/images/google_reviews_slider.png',
        alt: ReviewViewType.SLIDER,
        label: 'msg: common.slider {Slider}'
    },
];

const LinkToListings = 'https://business.google.com?hl=';

const STATUS = {
    NOT_FOUND: 'NotFound',
    NOT_VERIFIED: 'NotVerified'
};

export {
    Layouts,
    ReviewsApiStatus,
    ReviewViewType,
    LinkToListings,
    DIMENSIONS,
    MAX_HEADER_TEXT_LENGTH,
    MAX_REVIEW_BUTTON_TEXT_LENGTH,
    MAX_LINK_TEXT_LENGTH,
    REVIEW_HEADER_HEIGHT,
    REVIEW_FOOTER_HEIGHT,
    STATUS,
    MIN_HEADER_FONT_SIZE,
    MAX_HEADER_FONT_SIZE,
};
