import { gmbKeyGroup, stepKeysBasedOnGmbKey } from "../gmbKeyLocale";
import { nextGenText, optionSetDescText, onboardingStepsText } from "../locale";

const getOptionsFromOptionSet = (optionSet) => {
    const { set } = optionSet;
    const options = set.map((value) => {
        const label = nextGenText[value];
        return {
            label: label || value,
            value,
            noTranslation: !label,
        };
    });
    return options;
};

const getStaticDataForOptionSet = (type, optionSet: any = {}) => {
    const key = optionSet.key || type;
    return optionSetDescText[key] || optionSetDescText.default;
};

const getKeyForStep = (name, type, gmbKey, stepName, fieldName) => {
    const typesHaveGmbSegregation = [
        "personalWebsite",
        "personalWebsiteCatchall"
    ];

    let key: string = name,
        stepKeysList: any = onboardingStepsText;

    if (typesHaveGmbSegregation.includes(name)) {
        const groupKeys = Object.keys(gmbKeyGroup);

        for (let i = 0; i < groupKeys.length; i++) {
            if (gmbKeyGroup[groupKeys[i]].includes(gmbKey)) {
                key = groupKeys[i];
                break;
            }
        }

        stepKeysList = stepKeysBasedOnGmbKey;
    }

    let stepText = stepKeysList[stepName];
    if (fieldName && stepKeysList[stepName][fieldName]) {
        stepText = stepKeysList[stepName][fieldName];
    }

    return stepText[key] || stepText[type] || stepText.default;
};

export {
    getOptionsFromOptionSet,
    getStaticDataForOptionSet,
    getKeyForStep
};
