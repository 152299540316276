import * as R from 'ramda';

export const
    calculateImageDimensionsByContainerWidth = (assetWidth: number, assetHeight: number, containerWidth: number) => ({
        width: containerWidth,
        height: assetHeight * (containerWidth / assetWidth)
    }),
    calculateImageDimensionsByContainerHeight = (assetWidth: number, assetHeight: number, containerHeight: number) => ({
        width: assetWidth * (containerHeight / assetHeight),
        height: containerHeight
    }),
    roundOutput = R.evolve({ width: Math.round, height: Math.round });
