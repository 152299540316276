import React from "react";
import cx from 'classnames';
import * as styles from './ComponentMainActions.css';
import type { CTAFactoryProps } from './flowTypes';
import type { AnyComponent } from '../../../../redux/modules/children/workspace/flowTypes';
import { isModernLayoutSection } from "../../../../components/ModernLayouts/preview_utils";
import { BetaTag } from "../../../common/Tag";

export default (props: CTAFactoryProps<AnyComponent>): React.JSX.Element => {
    const
        {
            dispatch, buttonText, intl, className, ctaButtonClass, onClickActionType,
            editCta, CtaButtonTextView, selectedComponent,
        } = props,
        isModernSection = !!selectedComponent && isModernLayoutSection(selectedComponent),
        onClick = props.onClick && props.onClick.bind(null, props);

    return (
        <div
            className={cx(className, ctaButtonClass)}
            onClick={() => {
                (onClick || (() => onClickActionType && dispatch({ type: onClickActionType })))();
            }}
        >
            {isModernSection && !editCta &&
                <BetaTag
                    containerClassName={cx(styles.tagContainer)}
                    labelClassName={cx(styles.tagLabel)}
                />}
            {
                (CtaButtonTextView && !editCta) ?
                    <CtaButtonTextView {...props} /> :
                    <span>{intl.msgJoint(buttonText)}</span>
            }
        </div>
    );
};
