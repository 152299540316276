import React from 'react';
import { useIntl } from '../../../../../../../view/intl';
import styles from './styles.css';

export const ButtonGroups = ({ onClick, btnGroups }) => {
    const intl = useIntl();
    return (
        <div className={styles.buttonGroup} data-testid="ai-edit-text-chips">
            {
                btnGroups.map(({ id, label, disable, disableTooltip }) => {
                    return (
                        <button
                            key={id}
                            type="button"
                            data-title={disable ? intl.msgJoint(disableTooltip) : null}
                            data-title-position="bottom"
                            disabled={disable}
                            className={styles.button}
                            onClick={() => onClick(id)}
                        >
                            {intl.msgJoint(label)}
                        </button>
                    );
                })
            }
        </div>
    );
};

