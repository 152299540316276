import { REDO, UNDO } from '../../../../../epics/undoManager/actionTypes';
import ComponentsEvalValueActionType from '../../../../Workspace/epics/componentsEval/valueActionType';
import { getSelectedComponent } from '../../../../Workspace/epics/componentsEval/selectorActionTypes';
import {
    ROTableCellInEditModeIndexSelectorFromFullAction
} from '../../../../oneweb/Table/epics/tableEditMode/selectorActionTypes';
import { TextComponentKind } from '../../../../oneweb/Text/kind';
import { TableComponentKind } from '../../../../oneweb/Table/kind';
import { receiveOnly } from '../../../../../epics/makeCondition';
import { siteDataValueActionType } from '../../siteData/valueActionType';
import { replaceTagsWithContentDOM } from '../../../../oneweb/Text/view/replaceTagsWithContentDOM';
import { globalVariablesEpic } from '../../globalVariablesEpic';

const undoRedoUpdater = ({
    keepFullActions: true,
    conditions: [
        receiveOnly(siteDataValueActionType),
        receiveOnly(globalVariablesEpic.valueActionType),
        ComponentsEvalValueActionType,
        ROTableCellInEditModeIndexSelectorFromFullAction
    ],
    reducer: ({
        state,
        scope,
        values: [
            { payload: site },
            { payload: globalVariables },
            componentsEvalAction,
            tableCellInEditModeIndex
        ]
    }) => {
        if (componentsEvalAction.epicUpdateReason !== UNDO && componentsEvalAction.epicUpdateReason !== REDO) {
            return { state, scope };
        }

        const selectedComponent = getSelectedComponent(componentsEvalAction.payload);
        if (!selectedComponent) {
            return { state, scope };
        }

        const editor = state.tinyMceExist ? window.tinyMCE.activeEditor : null;
        if (!editor || editor.removed) {
            return { state, scope };
        }

        if (
            selectedComponent.kind === TextComponentKind ||
            (selectedComponent.kind === TableComponentKind && tableCellInEditModeIndex !== -1)
        ) {
            let content;
            if (selectedComponent.kind === TextComponentKind) {
                content = selectedComponent.content;
            } else if (selectedComponent.kind === TableComponentKind) {
                content = selectedComponent.cells[tableCellInEditModeIndex].content;
            }
            if (content) {
                editor.setContent(replaceTagsWithContentDOM(content, { site, globalVariables }, true));
            }
        }

        return {
            state,
            scope,
        };
    }
});

export {
    undoRedoUpdater,
};
