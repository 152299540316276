/* eslint-disable max-len */
import React from 'react';
import { path } from 'ramda';
import { Flex } from '../../../../../view/reflexbox/index';
import * as pageIds from './ids';
import pageTitles from './titles';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import { Msg } from '../../../../../view/intl/index';
import styles from './style.css';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';
import FontFamilySelector from '../../../../presentational/FontFamily/index';
import Slider from '../../../../../view/common/Slider/PPSlider';
import {
    LOGO_PP_TEXT_STYLE_FONT_FAMILY_CHANGED,
    LOGO_PP_TEXT_STYLE_BOLD_TOGGLE,
    LOGO_PP_TEXT_STYLE_ITALIC_TOGGLE,
    LOGO_PP_TEXT_STYLE_UNDERLINE_TOGGLE,
    LOGO_PP_TEXT_STYLE_CHARACTER_SPACING_CHANGED,
    LOGO_PP_TEXT_STYLE_COLOR_CHANGED,
    LOGO_PP_TEXT_STYLE_COLOR_CHANGED_AUTO_COLOR,
} from '../../actionTypes';
import { LOGO_KIND } from '../../kind';
import addGoogleFontView from '../../../../PropertiesPanel/view/AddGoogleFont/index';
import TextFormatButtonGroup from "../../../../presentational/IconButtonsGroup/TextFormat";
import { MIN_CHARACTER_SPACING, MAX_CHARACTER_SPACING } from '../../../../presentational/CharacterSpacing/constants';
import ColorProperty from '../../../../presentational/ColorProperty/index';
import { THEME_COLOR_PICKER } from '../../../../ColorPicker/constants';
import { themeRulesBackgroundForLinkColor } from '../../../../ThemeGlobalData/themeRules';
import { themeaccent } from '../../../../App/epics/tinyMceEpic/editorUtils/constants';

class TitleStyle extends React.Component<any> {
    render() {
        const { logoGlobalStyle, siteFonts, dispatch, navigateToPage } = this.props;
        const {
            bold,
            color,
            themeColor,
            font,
            italic,
            letterspacing,
            underline
        } = logoGlobalStyle;

        return (
            <Page>
                <VerticalSpacer y={15} />
                <Msg className={styles.text} k="logo.pp.titleStyle.hint">Customize the look of title used as your logo.</Msg>
                <VerticalSpacer y={10} />
                <PropertyContainer label="msg: common.font {Font}">
                    <FontFamilySelector
                        selected={font}
                        onChangeAction={LOGO_PP_TEXT_STYLE_FONT_FAMILY_CHANGED}
                        siteFonts={siteFonts}
                        onAddFontClicked={() => navigateToPage(addGoogleFontView.id, { source: LOGO_KIND })}
                        forwardToSelectedComponent
                        isPropertiesPanel
                    />
                </PropertyContainer>
                <VerticalSpacer y={17} />
                <Flex align="center" justify="space-between">
                    <TextFormatButtonGroup
                        bold={{
                            isSelected: bold,
                            onClickAction: LOGO_PP_TEXT_STYLE_BOLD_TOGGLE,
                        }}
                        italic={{
                            isSelected: italic,
                            onClickAction: LOGO_PP_TEXT_STYLE_ITALIC_TOGGLE,
                        }}
                        underline={{
                            isSelected: underline,
                            onClickAction: LOGO_PP_TEXT_STYLE_UNDERLINE_TOGGLE,
                        }}
                        dispatch={dispatch}
                    />
                </Flex>
                <VerticalSpacer y={12} />
                <PropertyContainer
                    label="msg: common.characterSpacing {Character spacing}"
                    valueLabel={String(letterspacing)}
                >
                    <Slider
                        defaultValue={letterspacing}
                        value={letterspacing}
                        min={MIN_CHARACTER_SPACING}
                        max={MAX_CHARACTER_SPACING}
                        onChange={newSize => dispatch({
                            type: LOGO_PP_TEXT_STYLE_CHARACTER_SPACING_CHANGED,
                            payload: newSize,
                        })}
                    />
                </PropertyContainer>
                <VerticalSpacer y={10} />
                {
                    this.props.themeSettingsData.autoColorMode ?
                        <ColorProperty
                            colorPickerKind={THEME_COLOR_PICKER}
                            themeColor={themeColor}
                            defaultThemeColor={path(
                                [themeaccent, this.props.selectedComponentProps.selectedParentTheme],
                                themeRulesBackgroundForLinkColor(this.props.themeColorsData)
                            )}
                            themeColorsData={this.props.themeColorsData}
                            label="msg: common.textColor {Text colour}"
                            onChangeAction={LOGO_PP_TEXT_STYLE_COLOR_CHANGED_AUTO_COLOR}
                            dispatch={dispatch}
                        />
                        :
                        <ColorProperty
                            label="msg: common.textColor {Text colour}"
                            color={color}
                            onChangeAction={LOGO_PP_TEXT_STYLE_COLOR_CHANGED}
                            dispatch={dispatch}
                        />
                }
            </Page>
        );
    }
}

const
    id = pageIds.TITLE_STYLE,
    title = pageTitles[id];

export const logoTitleStylePpPageConfig = { id, view: TitleStyle, title };
