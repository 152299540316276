import { documentConfigDialogDefaultState } from "./documentConfigDialogDefaultState";
import type { DocumentConfigDialogInProps, DocumentConfigDialogState } from "../flowTypes";
import Resource from "../../../../../redux/modules/children/fileChooser/Resource";
import { makeInputDefaultState } from "../../../../inputControls/input/reducerFactory/makeInputDefaultState";
import { DocumentConfigDialogTabIdEnum } from "../constants";

export const makeDocumentDefaultState = (props: DocumentConfigDialogInProps): DocumentConfigDialogState => {
    const { src } = props;
    if (!src) return documentConfigDialogDefaultState;

    return Resource.isWebspaceUrl(src)
        ? {
            currentTabId: DocumentConfigDialogTabIdEnum.FILE,
            file: Resource.fromWebspaceUrl(src),
            url: makeInputDefaultState({ value: '' })
        }
        : {
            currentTabId: DocumentConfigDialogTabIdEnum.URL,
            file: null,
            url: makeInputDefaultState({ value: src })
        };
};
