import * as R from "ramda";
import makeEpic from '../../../epics/makeEpic';
import { getAppConfig } from '../../App/epics/appConfig/appConfig';
import {
    SHOW_WAITING_SCREEN_AND_INIT_COMPUTE,
    ENABLE_COLOR_THEME_FAILURE,
    START_ONBOARDING_OF_EXISTING_CUSTOMERS,
    COMPUTE_COLOR_THEME_SUCCESS,
    ENABLE_COLOR_THEME_SUCCESS,
    SET_ONBOARDING_THEME_PALETTE,
} from '../../ThemeGlobalData/actionTypes';
import { openDialog } from "../../App/actionCreators/index";
import { ApplyingThemingDialogId } from "../../AutoColorLeftPanel/ApplyingThemingDialog/ApplyingThemingDialog";
import {
    computeThemeColorsForOneTemplate,
    enableThemingRequest
} from "../../ThemeGlobalData/actionCreators";
import { receiveOnly } from "../../../epics/makeCondition";
import { workspaceTemplateVAT } from "../../oneweb/Template/epics/template/valueActionType";
import type { ThemeColorDataType } from "../../ThemeGlobalData/flowTypes";
import { openThemeOnboardingDialog } from "../../AutoColorLeftPanel/ThemeOnboardingDialog/actionCreators";
import DataPage from "../../../../dal/model/DataPage";
import DataPageTemplate from "../../../../dal/model/DataPageTemplate";
import DataPageStylesheet from "../../../../dal/model/DataPageStylesheet";
import {
    operationDetailsDataPageSetPath,
    operationDetailsThemeColorsPath,
    THEME_ACTIVATED_LOCAL_STORAGE_KEY,
    THEME_ACTIVATED_WORKSPACE_STATE,
    THEME_ACTIVATED_WORKSPACE_STATE_LEFT_PANEL
} from "../../ThemeGlobalData/constants";
import localStorage from "../../../utils/localStorage";
import { WORKSPACE_READY } from "../../Workspace/actionTypes";
import { showAutoColorLeftPanel } from "../../AutoColorLeftPanel/actionCreators/showAutoColorLeftPanel";

export type ColorTabServiceDefaultStateType = {
    isWaitingForTurnOnAutoColor: boolean,
    computedThemeColorsForOnBoarding: ThemeColorDataType | null,
    computedDataPageSetForOnBoarding: {
        pages: Array<DataPage>, template: DataPageTemplate, stylesheet: DataPageStylesheet
    } | null,
    themeActivatedWorkspaceState: string,
};

const appConfig = getAppConfig();

const colorTabServiceDefaultState: ColorTabServiceDefaultStateType = {
    isWaitingForTurnOnAutoColor: false,
    computedThemeColorsForOnBoarding: null,
    computedDataPageSetForOnBoarding: null,
    themeActivatedWorkspaceState: ''
};

const colorThemeServiceSiteSettingsEpic = makeEpic({
    defaultState: colorTabServiceDefaultState,
    valueActionType: 'COLOR_THEME_SERVICE_SITE_SETTINGS_EPIC_VALUE',
    updaters: [
        {
            conditions: [
                receiveOnly(workspaceTemplateVAT),
                SHOW_WAITING_SCREEN_AND_INIT_COMPUTE
            ],
            reducer: ({ dispatchAsync, state, values: [{ id: templateId }] }) => {
                const { computedThemeColorsForOnBoarding } = state;

                setTimeout(() => dispatchAsync(enableThemingRequest(
                    computedThemeColorsForOnBoarding,
                    templateId
                )), appConfig.oneWeb.frontEnd.autoColor.delayForTurnOn);

                // TODO: If possible, avoid opening the dialog with dispatchAsync
                setTimeout(() => dispatchAsync(openDialog(ApplyingThemingDialogId)));

                return {
                    state: { ...state, isWaitingForTurnOnAutoColor: true }
                };
            }
        },
        {
            conditions: [ENABLE_COLOR_THEME_FAILURE],
            reducer: ({ state }) => {
                return {
                    state: { ...state, isWaitingForTurnOnAutoColor: false }
                };
            }
        },
        {
            conditions: [ENABLE_COLOR_THEME_SUCCESS],
            reducer: ({ state }) => {
                localStorage.set(
                    THEME_ACTIVATED_LOCAL_STORAGE_KEY,
                    { [THEME_ACTIVATED_WORKSPACE_STATE]: THEME_ACTIVATED_WORKSPACE_STATE_LEFT_PANEL }
                );
                document.location.reload();

                return {
                    state,
                };
            }
        },
        {
            conditions: [
                WORKSPACE_READY
            ],
            reducer: ({ state }) => {
                const data = localStorage.get(THEME_ACTIVATED_LOCAL_STORAGE_KEY);
                if (data) {
                    localStorage.remove(THEME_ACTIVATED_LOCAL_STORAGE_KEY);
                    return {
                        state,
                        actionToDispatch: showAutoColorLeftPanel(),
                    };
                }
                return {
                    state,
                };
            }
        },
        {
            conditions: [
                receiveOnly(workspaceTemplateVAT),
                START_ONBOARDING_OF_EXISTING_CUSTOMERS
            ],
            reducer: ({ state, values: [{ id: templateId }] }) => {
                return {
                    state: { ...state, computedThemeColorsForOnBoarding: null },
                    multipleActionsToDispatch: [
                        openThemeOnboardingDialog(),
                        computeThemeColorsForOneTemplate(templateId)
                    ]
                };
            }
        },
        {
            conditions: [COMPUTE_COLOR_THEME_SUCCESS],
            reducer: ({ state, values: [result] }) => {
                const computedThemeColorsForOnBoarding = R.path(operationDetailsThemeColorsPath)(result) || null;
                const computedDataPageSetForOnBoarding = R.path(operationDetailsDataPageSetPath)(result) || null;
                return {
                    state: { ...state, computedThemeColorsForOnBoarding, computedDataPageSetForOnBoarding }
                };
            }
        },
        {
            conditions: [SET_ONBOARDING_THEME_PALETTE],
            reducer: ({ state, values: [{ themeColors }] }) => {
                return { state: { ...state, computedThemeColorsForOnBoarding: themeColors } };
            }
        }
    ]
});

export {
    colorThemeServiceSiteSettingsEpic,
};
