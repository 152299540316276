import * as componentReducers from "../componentReducers";
import { factoryFacebookFeedStylesReducer } from "./factoryFacebookFeedStylesReducer";

const
    makeGalleryStylesReducer = factoryFacebookFeedStylesReducer('captionBoxStyle');
export const
    typeReducer = makeGalleryStylesReducer(componentReducers.makeComponentStyleBorderStyleReducer()),
    widthReducer = makeGalleryStylesReducer(componentReducers.makeComponentStyleBorderWidthReducer()),
    colorReducer = makeGalleryStylesReducer(componentReducers.makeComponentStyleBorderColorReducer()),
    opacityReducer = makeGalleryStylesReducer(componentReducers.makeComponentStyleBorderOpacityReducer()),
    bgColorReducer = makeGalleryStylesReducer(componentReducers.makeComponentStyleBackgroundColorReducer()),
    bgColorUnsetReducer = makeGalleryStylesReducer(componentReducers.makeComponentStyleBackgroundColorUnsetReducer()),
    bgColorOpacityReducer = makeGalleryStylesReducer(componentReducers.makeComponentStyleBackgroundColorOpacityReducer()); // eslint-disable-line
