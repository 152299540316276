import CALL_API from '../../../../redux/middleware/api/CALL_API';
import * as Actions from '../../actionTypes';
import type { ApiAction } from '../../../../redux/middleware/api/flowTypes';
import type { SubscriptionTypeT } from "../../../App/epics/subscriptionData/flowTypes";

export default function (upgradeType: SubscriptionTypeT): ApiAction {
    return {
        [CALL_API]: {
            types: [
                Actions.SEAMLESS_UPGRADE_REQUEST,
                Actions.SEAMLESS_UPGRADE_SUCCESS,
                Actions.SEAMLESS_UPGRADE_FAILURE
            ],
            endpoint: 'seamlessUpgradeToPremium',
            endpointParams: [upgradeType]
        }
    };
}
