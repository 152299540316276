import { $Diff } from "utility-types";
import React from 'react';
import { connect } from "react-redux";
import { Intl } from "../../../intl/index";
import { Dialog, StripTypes, DialogTitleBox, DialogBody, DialogFooter } from '../baseDialog/index';
import type { PropTypes as FooterPropTypes } from '../baseDialog/DialogFooter';
import type { PropTypes as DialogPropTypes } from '../baseDialog/Dialog';
import type { PropTypes as TitlePropTypes } from '../baseDialog/DialogTitleBox';
import { closeDialog } from "../../../../components/App/actionCreators/index";

export type CombinedProps = DialogPropTypes & TitlePropTypes & FooterPropTypes & {
    defaultSctaHandler: SimpleFunction;
    children ?: any;
    titleContents?: React.JSX.Element;
    footerContents?: React.JSX.Element;
    footerContentClassName?: string;
    bodyClassName?: string;
    titleBoxClassName?: string;
    primaryBtnClass?: string;
    noTopBorderForFooter?: boolean;
    footerClassName?: string;
    titleClassName?: string;
    showTitle?: boolean;
    titleExtraClassName?: string;
    footerV2 ?: true;
    ctaHandler?: () => any;
}

type Props = $Diff<CombinedProps, {
    intl: Intl,
}>

const StandardDialogComponent = ({
    className,
    bodyClassName,
    style,
    onClose,
    closeBtnClassName,
    title,
    titleIconCmp,
    titleExtraClassName = '',
    subTitle,
    titleContents,
    titleClassName,
    titleBoxClassName,
    subTitleClass = '',
    footerContents,
    footerContentClassName,
    footerButtonContainerClassName,
    iconClass,
    mctaHandler,
    defaultSctaHandler,
    sctaHandler = defaultSctaHandler,
    mctaText,
    autoFocus = true,
    sctaText = 'msg: common.cancel {Cancel}',
    disabled,
    noTopBorderForFooter = false,
    primaryBtnClass,
    sctaBtnClass,
    showCloseBtn,
    children,
    footerClassName,
    stripType = StripTypes.INFO,
    showTitle = true,
    containerClassName = '',
    footerV2,
}: Props) => {
    return (
        // @ts-ignore
        <Dialog
            stripType={stripType}
            className={className}
            style={style}
            onClose={onClose}
            showCloseBtn={showCloseBtn}
            closeBtnClassName={closeBtnClassName}
            containerClassName={containerClassName}
        >
            { showTitle && <DialogTitleBox
                title={title}
                titleIconCmp={titleIconCmp}
                subTitle={subTitle}
                iconClass={iconClass}
                className={titleBoxClassName}
                titleClass={titleClassName}
                extraClass={titleExtraClassName}
                subTitleClass={subTitleClass}
            >
                {titleContents}
            </DialogTitleBox>}
            <DialogBody className={bodyClassName}>
                {children}
            </DialogBody>
            {
                mctaText &&
                <DialogFooter
                    primaryBtnClass={primaryBtnClass}
                    sctaBtnClass={sctaBtnClass}
                    mctaHandler={mctaHandler}
                    autoFocus={autoFocus}
                    sctaHandler={sctaHandler}
                    mctaText={mctaText}
                    sctaText={sctaText}
                    disabled={disabled}
                    contentClassName={footerContentClassName}
                    footerButtonContainerClassName={footerButtonContainerClassName}
                    noTopBorder={noTopBorderForFooter}
                    className={footerClassName}
                    footerV2={footerV2}
                >
                    {footerContents}
                </DialogFooter>
            }
        </Dialog>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    defaultSctaHandler: () => dispatch(closeDialog())
});

const StandardDialog = connect(null, mapDispatchToProps)(StandardDialogComponent);

export {
    StandardDialog as default,
    StandardDialog
};
