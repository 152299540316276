/* eslint-disable max-len */

import React from 'react';
import { connect } from "react-redux";
import cx from "classnames";

import styles from '../UpgradePlansDialog.css';
import StandardDialog from '../../../../../../view/common/dialogs/StandardDialog';
import { StripTypes } from "../../../../../../view/common/dialogs/baseDialog";
import Scrollbar from "../../../../../../view/common/Scrollbar";
import type { UpgradePlansDialogProps } from "../../flowTypes";

import { closeDialog } from '../../../../../App/actionCreators';
import { upgradeCanceledAction } from '../../../../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions';
import { UpgradeCancelReason } from '../../../../../EventTracking/constants';
import { UpgradePlansDialogBody } from '../../../../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/UpgradePlansDialogBody';
import getCenteredDialogConfig from "../../../../../DialogManager/getCenteredDialogConfig";
import { getCenterPositionForDialog } from "../../../../../DialogManager/utility";
import { makeEpicStateSelector } from "../../../../../../epics/makeEpic";
import subscriptionDataVat from "../../../../../App/epics/subscriptionData/valueActionType";

const
    subscriptionDataSelector = makeEpicStateSelector(subscriptionDataVat),
    mapStateToProps = appState => {
        const subscriptionData = subscriptionDataSelector(appState);
        return { subscriptionData };
    };
const
    onecomUpgradePlansDialogComponent = (props: UpgradePlansDialogProps) => {
        const {
            dispatch
        } = props;

        let standardDialogExtraProps: Record<string, any> = {
            showTitle: false,
            containerClassName: styles.containerClassName,
            className: styles.mainContainer,
            showCloseBtn: false,
        };

        return (
            <Scrollbar height="100%">
                <StandardDialog
                    stripType={StripTypes.NONE}
                    closeBtnClassName={styles.closeBtnClassName}
                    onClose={() => {
                        dispatch(closeDialog());
                        dispatch(upgradeCanceledAction(UpgradeCancelReason.UserCancelled));
                    }}
                    {...standardDialogExtraProps}
                >
                    <UpgradePlansDialogBody
                        subscriptionData={props.subscriptionData}
                        allowedUpgradeTypes={[]}
                    />
                </StandardDialog>
            </Scrollbar>
        );
    },
    onecomUpgradePlansDialog = () => {
        const
            WIDTH = 1248,
            HEIGHT = 1300,
            getDialogDimensions = (browserHeight) => ({
                width: WIDTH,
                height: Math.min(Math.max(browserHeight - 100, 475), HEIGHT)
            });
        return {
            ...getCenteredDialogConfig(WIDTH, HEIGHT),
            confFactory: ({ browserWidth, browserHeight }) => {
                const { width, height } = getDialogDimensions(browserHeight);
                return {
                    position: getCenterPositionForDialog(width, height, browserWidth, browserHeight),
                    modal: true,
                    dimensions: { width, height }
                };
            },
            component: connect(mapStateToProps)(onecomUpgradePlansDialogComponent),
            dialogClassName: cx(styles.dialogContainerClassName, styles.dialogContainerFullWidth),
            moveToCenter: true,
            dragEnabled: false,
        };
    };

export {
    onecomUpgradePlansDialog
};
