import * as pageIds from "./pageIds";
import makePages from "../../../PropertiesPanel/view/makePages";
import { mainPage, backgroundColorPage, borderPage, imagePatternPage } from "./pages/index";

type Params = {
    mainPageTitle?: string
};

export default ({ mainPageTitle = mainPage.title }: Params = {}) => ({
    pages: makePages({ ...mainPage, title: mainPageTitle }, backgroundColorPage, imagePatternPage, borderPage),
    defaultPageId: pageIds.MAIN
});
