import * as R from "ramda";
import { applyMappers, makePlainMappers } from "../../utils";
import * as actionMapper from './actionMapper';
import * as onClickAction from "../Gallery/onClickAction";
import * as componentTypeMapper from '../Base/type';
import type { ImageSliderIn } from './flowTypes';
import type { ImageSliderComponent } from "../../../../src/components/oneweb/ImageSlider/flowTypes";

const
    plainPropsMapper = makePlainMappers({
        autoNext: 'autoNext',
        captionsEnabled: 'captionsEnabled',
        crop: 'crop',
        delay: 'delay',
        type: 'type',
        width: 'width',
        height: 'height',
        wrap: 'wrap',
        indicator: 'indicator',
        navigator: 'navigator',
        transition: 'transition',
        captionsAlignment: 'captionsAlignment',
        captionsPlacement: 'captionsPlacement',
        mobileDown: 'mobileDown',
        fullWidthOption: 'fullWidthOption',
        stretch: 'stretch',
        mobileHide: 'mobileHide',
        originalSize: 'originalSize'
    }),

    fixDimensions = R.evolve({
        width: Math.round,
        height: Math.round
    });

export function to(componentData: ImageSliderIn): ImageSliderComponent {
    return applyMappers(
        componentData,
        fixDimensions,
        actionMapper.to,
        onClickAction.to,
        plainPropsMapper.to,
        componentTypeMapper.to
    );
}

export function back(componentData: ImageSliderComponent): ImageSliderIn {
    return applyMappers(
        componentData,
        actionMapper.back,
        onClickAction.back,
        plainPropsMapper.back,
        componentTypeMapper.back
    );
}
