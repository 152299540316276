import React from 'react';
import Page from '../../../../../PropertiesPanel/view/PropertiesPage';
import VerticalSpacer from '../../../../../../view/common/VerticalSpacer';
import PropertyContainer from '../../../../../../view/common/PropertyContainer/index';
import Combobox from '../../../../../../view/common/Combobox/index';
import { intlCreateOptions } from '../../../../../../view/common/Combobox/utils';
import { WIDGET_PAYPAL_COLOR_CHANGE } from '../../actionTypes';
import { supportedColor } from '../../constants';
import { injectIntl } from '../../../../../../view/intl';

const
    id = 'COLOR',
    title = 'msg: common.paypal.color {Colour}',
    view = injectIntl(({ dispatch, selectedComponent, intl }) => {
        const color = selectedComponent.color;
        return (
            <Page>
                <VerticalSpacer />
                <PropertyContainer label={title}>
                    <Combobox
                        searchable={false}
                        value={color}
                        options={intlCreateOptions(supportedColor, intl)}
                        onChange={({ value: color }) => dispatch({ type: WIDGET_PAYPAL_COLOR_CHANGE, payload: color })}
                    />
                </PropertyContainer>
            </Page>
        );
    });

export { id, title, view };
