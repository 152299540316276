import CALL_API from "../../../../../../redux/middleware/api/CALL_API";
import type { ApiAction } from '../../../../../../redux/middleware/api/flowTypes';
import { RequestFixAllHeaderSectionActionTypes } from "./actionTypes";

export const
    fixAllHeaderSection = (input: any): ApiAction => ({
        [CALL_API]: {
            types: RequestFixAllHeaderSectionActionTypes,
            endpoint: 'fixAllHeaderLayout',
            endpointParams: input
        }
    });

