import type { ShutterstockState } from '../../shutterstock/types';
import { Lit } from '../../../../lit';
import {
    PAID_SHUTTERSTOCK_IMAGE_PRICE_SUCCESS_ACTION
} from "../actions";

export const paidShutterstockCombinereducerConfig = {
    combineReducers: {},
    handleActions: {
        [PAID_SHUTTERSTOCK_IMAGE_PRICE_SUCCESS_ACTION]: (state: ShutterstockState, action: Record<string, any>) => ({
            ...state,
            [Lit.imagePrice]: action.payload.price
        })
    }
};
