import {
    getDefaultReducerState,
    getComponentInitialStateCommonPart,
} from "../../../../redux/makeReducer/index";

import { DefaultComponentState } from "../types";

type Props<SS> = {
    kind: string;
    defaultWidth: number;
    defaultHeight: number;
    reducer: (arg0: SS, arg1: AnyAction) => SS;
};

export const getTextLikeDefaultState = ({
    kind, defaultWidth, defaultHeight, reducer
}: Props<any>): DefaultComponentState<any> => {
    const componentInitialStateCommonPart = getComponentInitialStateCommonPart(kind, defaultWidth, defaultHeight);
    return {
        ...componentInitialStateCommonPart,
        generic: {
            horizontalAlignment: 'center',
            textStyle: {
                bold: null,
                italic: null,
                underline: null,
                prefixBold: true,
                prefixItalic: null,
                prefixUnderline: null,
                fontSize: null,
                color: null,
                fontFamily: null,
                letterSpacing: 0,
                lineHeight: null,
                prefixCase: null,
                valueCase: null,
            },
            showCustomTitleFirst: true,
            customPrefixText: '',
            showOnOneLine: false,
            mobileHorizontalAlignment: null,
            mobileFontSize: null,
            mobileIconSize: null,
            showIcon: true,
            iconTextSpacing: null,
            iconSize: null,
            themeOverrideColor: null,
        },
        specific: getDefaultReducerState(reducer)
    };
};
