import React from 'react';
import cx from 'classnames';
import * as R from 'ramda';
import type { Props, FactoryProps } from './flowTypes';
import defaultBtnStyles from '../../buttons.css';

export default ({
    containerType, containerExtraPropNames, additionalClassName, style, btnStyles = defaultBtnStyles
}: FactoryProps) =>
    (props: Props) => {
        const
            { isSelected, isError, onClick, onMouseEnter, onMouseDown, children, className, disabled, title, isErrorTitle, titlePosition } = props, // eslint-disable-line
            finalClassName = cx(
                btnStyles.button,
                additionalClassName,
                className,
                {
                    [btnStyles.error]: isError,
                    [btnStyles.selected]: isSelected,
                    [btnStyles.disabled]: disabled
                }
            ),

            containerProps = R.pipe(
                R.reduce((prev, prop) => ({ ...prev, [prop]: prop }), {}),
                R.mapObjIndexed(R.flip(R.prop)(props))
            )(containerExtraPropNames);

        return React.createElement(containerType, {
            style,
            'data-title': title,
            'data-title-error': isErrorTitle,
            'data-title-position': titlePosition,
            onMouseEnter,
            onMouseDown,
            onClick,
            className: finalClassName,
            ...containerProps
        }, children);
    };
