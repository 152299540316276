export const
    SHOW_WELCOME_MESSAGE = 'SHOW_WELCOME_MESSAGE',
    HIDE_WELCOME_MESSAGE = 'HIDE_WELCOME_MESSAGE',

    SETUP_MY_SHOP_ACTION = 'SETUP_MY_SHOP_ACTION';

export const
    showWelcomeMessageAction = () => ({
        type: SHOW_WELCOME_MESSAGE
    }),
    setupMyShopAction = () => ({
        type: SETUP_MY_SHOP_ACTION
    });
