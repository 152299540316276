import React from "react";
import WarningDialog from '../../view/common/dialogs/WarningDialog/index';
import { AddNotAllowed } from "./constants";
import makeAppStandardErrorDialog from "../App/dialogs/makeAppStandardErrorDialog";
import injectIntl from "../../view/intl/injectIntl";
import styles from './ComponentsAddNotAllowedDialog.css';

export const AddNotAllowedDialog = injectIntl(({ title, texts, intl }) => {
    return (
        <WarningDialog title={title} mctaText={AddNotAllowed.mctaText}>
            {texts.map((text, index) => <div key={index} className={styles.error}>{intl.msgJoint(text)}</div>)}
        </WarningDialog>
    );
});

export default makeAppStandardErrorDialog(AddNotAllowedDialog);
