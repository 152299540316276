// @ts-nocheck
import workspace from './workspace';
import shortcut from './shortcut';
import preview from './preview';

export default {
    workspace,
    shortcut,
    preview
};
