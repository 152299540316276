import { UNDO_STACK_MODIFIED } from "../../epics/undoManager/actionTypes";
import { KEY_UP, KEY_DOWN, KEY_PRESS } from "../../components/App/actionTypes";
import {
    GENERAL_INFO_ADDRESS_STREET_ADDRESS_INPUT_CHANGED,
    GENERAL_INFO_ADDRESS_ZIP_INPUT_CHANGED,
    GENERAL_INFO_ADDRESS_CITY_INPUT_CHANGED,
    GENERAL_INFO_ADDRESS_AREA_INPUT_CHANGED,
    GENERAL_INFO_WEBSITE_TITLE_INPUT_CHANGED,
    GENERAL_INFO_LOGO_ALT_TEXT_INPUT_CHANGED,
    GENERAL_INFO_ADDRESS_NAME_INPUT_CHANGED
} from "../../components/SiteSettings/General/actionTypes";
import { arrayToTrueMap } from '../../utils/arrayToTrueMap';
import { ANIMATION_FRAME } from '../middleware/raf';
import { LOGO_PP_WEBSITE_TITLE_CHANGED } from "../../components/oneweb/Logo/actionTypes";

export const actionTypesSkippedForReduxReducerMapIfEpicsStateNotChanged = arrayToTrueMap([
    UNDO_STACK_MODIFIED,
    KEY_UP,
    KEY_DOWN,
    KEY_PRESS,
    GENERAL_INFO_WEBSITE_TITLE_INPUT_CHANGED,
    GENERAL_INFO_LOGO_ALT_TEXT_INPUT_CHANGED,
    GENERAL_INFO_ADDRESS_NAME_INPUT_CHANGED,
    GENERAL_INFO_ADDRESS_STREET_ADDRESS_INPUT_CHANGED,
    GENERAL_INFO_ADDRESS_ZIP_INPUT_CHANGED,
    GENERAL_INFO_ADDRESS_CITY_INPUT_CHANGED,
    GENERAL_INFO_ADDRESS_AREA_INPUT_CHANGED,
    LOGO_PP_WEBSITE_TITLE_CHANGED,
    ANIMATION_FRAME,
]);
