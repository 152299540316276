import { getFacebookPagePluginApiUrl } from '../Facebook/utils';
import kind from './kind';
import view from './view/index';
import calcRenderProps from './calcRenderProps';
import { LocaleMap, MIN_WIDTH, MIN_HEIGHT } from './constants';
import { getLinkedInApiUrl, getTwitterApiUrl } from './utils';

import type { CalcProps } from "../../Preview/flowTypes";
import type { ShareButtonsComponent } from './flowTypes';
import type { GenerateScriptsProps } from '../flowTypes';
import './view/Preview.css';

export default {
    kind,
    view,
    calcProps: ({ componentId, component, template: { locale } }: CalcProps<ShareButtonsComponent>) => calcRenderProps({
        componentId,
        component,
        componentDependencies: { locale },
        componentExtension: {
            // @ts-ignore
            shouldComponentUpdate: false,
            isTransient: false,
            minDimensions: { width: MIN_WIDTH, height: MIN_HEIGHT }
        },
        inEditMode: false,
        isWorkspace: false,
    }),
    customScripts: (locale: string) => [{
        src: getLinkedInApiUrl(),
        attributes: { locale: LocaleMap.linkedin[locale] },
        innerHTML: `lang: ${LocaleMap.linkedin[locale]}`
    }],
    js: ['/renderStatic/shareButtons/ShareButtons.js'],
    css: ['/renderStatic/shareButtons/ShareButtons.css'],
    generateScripts: ({ locale }: GenerateScriptsProps) => [
        getFacebookPagePluginApiUrl(locale),
        getTwitterApiUrl()
    ]
};
