import React from "react";
import { Box } from "@mui/material";
import cx from "classnames";

import { RenderSwitch } from "../Helpers/RenderWhen";
import { useStyles } from "./styles";
import { parseSize } from "./utils";

type SliderProps = {
    sliderId?: string;
    data: any[];
    width: number | string;
    gap?: number | string;
    selected?: number;
    onChange?: (value: number) => void;
    children: (element: any, index: number) => any;
    slideIndicators?: boolean | JSX.Element;
    slideVisible?: boolean;
    fitAvailable?: boolean;
    classes?: Partial<{
        root: string;
        slide: string;
        activeSlide: string;
    }>;
    disableClickOnSlide?: boolean
};

const MIN_SWIPE_DISTANCE = 40;

export const Slider = ({
    sliderId,
    data,
    width,
    gap,
    selected,
    onChange,
    children,
    classes,
    slideIndicators = true,
    slideVisible = true,
    fitAvailable = false,
    disableClickOnSlide = false
}: SliderProps) => {
    const [sliderViewRef, setSliderViewRef] = React.useState<HTMLDivElement | null>(null);
    const [slidesPerPage, setSlidesPerPage] = React.useState<number>(1);
    const [selectedSlide, setSelectedSlide] = React.useState<number>(selected || 0);

    const _gapProp = typeof gap === "number" ? `${gap}px` : (gap || "0px");
    const _widthProp = typeof width === "number" ? `${width}px` : width;
    // TODO: handle other cases for data.length (check other todo comments)
    const _gap = fitAvailable && data.length === 1 ? "0px" : _gapProp;
    const _width = fitAvailable && data.length === 1 ? "100%" : _widthProp;
    const _classes = useStyles({
        container: sliderViewRef,
        selected: selectedSlide,
        gap: _gap,
        width: _width,
        total: data.length,
        fitAvailable
    });

    const selectSlide = (index: number) => {
        setSelectedSlide(index);
        if (onChange) onChange(index);
    };

    const selectPreviousSlide = () => {
        if (slideVisible && selectedSlide === (data.length - 1) && slidesPerPage < data.length) {
            selectSlide(selectedSlide - slidesPerPage);
        } else if (selectedSlide > 0) {
            selectSlide(selectedSlide - 1);
        }
    };

    const selectNextSlide = () => {
        if (slideVisible && selectedSlide === 0 && slidesPerPage < data.length) {
            selectSlide(slidesPerPage);
        } else if (selectedSlide < (data.length - 1)) {
            selectSlide(selectedSlide + 1);
        }
    };

    React.useEffect(() => {
        if (sliderViewRef) {
            const availableWidth = sliderViewRef.clientWidth;
            const slideWidth = parseSize(_width, availableWidth);
            const slideGap = parseSize(_gap, availableWidth);
            const _slidesPerPage = Math.max(1, Math.floor(availableWidth / (slideWidth + slideGap)));
            setSlidesPerPage(_slidesPerPage);
        }
    }, [sliderViewRef]);

    React.useEffect(() => {
        if (typeof selected === "number") {
            selectSlide(selected);
        }
    }, [selected]);

    const touchStartPosition = React.useRef<number>(-1);

    const handleSwipeStart = (event: React.TouchEvent) => {
        const touch = event.targetTouches.item(0);
        touchStartPosition.current = touch.clientX;
    };

    const handleSwipeEnd = (event: React.TouchEvent) => {
        if (touchStartPosition.current >= 0) {
            const touch = event.changedTouches.item(0);
            const distance = touchStartPosition.current - touch.clientX;

            if (distance > MIN_SWIPE_DISTANCE) {
                selectNextSlide();
            } else if (distance < -MIN_SWIPE_DISTANCE) {
                selectPreviousSlide();
            }

            touchStartPosition.current = -1;
        }
    };

    return (
        <Box className={cx(_classes.sliderRoot, classes?.root)} data-testid={sliderId}>
            <Box className={_classes.sliderContainer}>
                <Box
                    ref={setSliderViewRef}
                    className={_classes.sliderView}
                    onTouchStart={handleSwipeStart}
                    onTouchEnd={handleSwipeEnd}
                >
                    {data.map((element, index) => {
                        const classNames = [_classes.slide];
                        if (classes?.slide) {
                            classNames.push(classes.slide);
                        }
                        if (index === selected && classes?.activeSlide) {
                            classNames.push(classes.activeSlide);
                        }
                        return (
                            <Box
                                key={index}
                                className={cx(classNames)}
                                onClick={() => {
                                    if (!disableClickOnSlide) {
                                        selectSlide(index);
                                    }
                                }}
                            >
                                {children(element, index)}
                            </Box>
                        );
                    })}
                </Box>
            </Box>

            <RenderSwitch>
                <RenderSwitch.Case when={React.isValidElement(slideIndicators)}>
                    <Box className={_classes.sliderIndicators}>
                        {slideIndicators}
                    </Box>
                </RenderSwitch.Case>

                <RenderSwitch.Case when={slideIndicators === true}>
                    <Box className={_classes.sliderIndicators}>
                        {data.map((_, index) => (
                            <span
                                key={index}
                                className={cx(_classes.sliderIndicator, { [_classes.activeIndicator]: index === selected })}
                                onClick={() => selectSlide(index)}
                            />
                        ))}
                    </Box>
                </RenderSwitch.Case>
            </RenderSwitch>
        </Box>
    );
};
