import { parseDOM } from "../../../../utils/parseDOM";
import { type MailchimpParams } from "./types";

export const parseMailchimpCode = (code: string): MailchimpParams => {
    let params = <MailchimpParams>{};

    const embedCodeV2Configs = [
        {
            nodeName: 'DIV',
            validator: node => node.id === "mc_embed_shell",
            parser: () => ({ link: code.trim() }),
            children: [
                {
                    nodeName: "LINK",
                    validator: node => {
                        return (
                            node.type === "text.css"
                            && node.href.length > 0
                        );
                    }
                },
                {},
                {
                    nodeName: "DIV",
                    validator: node => node.id === "mc_embed_signup",
                    children: [
                        {
                            nodeName: "FORM",
                            validator: node => node.id === "mc-embedded-subscribe-form"
                        }
                    ]
                }
            ]
        }
    ];

    params = parseDOM(embedCodeV2Configs, code);

    if (!params.link) {
        const embedCodeV1Configs = [
            {
                nodeName: 'DIV',
                validator: node => node.childNodes.length > 0,
                parser: () => ({ link: code.trim() })
            }
        ];

        params = parseDOM(embedCodeV1Configs, code);
    }

    return params;
};

export const validateMailChimpCode = (code: string): boolean => {
    const regexBeginPrimary = /^<!-- Begin Mailchimp Signup Form -->/;
    const regexBeginSecondary = /^<div id="mc_embed_shell">/;
    const regexEndPrimary = /!--End mc_embed_signup-->$/;
    const regexEndSecondary = /<\/script><\/div>$/;
    const codeInitialChunk = code.substring(0, 50).trim();
    const codeEndingChunk = code.slice(-50).trim();

    if (
        (regexBeginPrimary.test(codeInitialChunk) && regexEndPrimary.test(codeEndingChunk))
        || (regexBeginSecondary.test(codeInitialChunk) && regexEndSecondary.test(codeEndingChunk))
    ) {
        return true;
    }

    const params = parseMailchimpCode(code);
    return params.link ? params.link.length > 0 : false;
};
