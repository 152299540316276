import * as R from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import { topmostHandlesQueueValueActionType } from './valueActionType';
import { topMostHandleVAT } from "../topMostHandle/valueActionType";
import { removeTopmostHandleIfAlreadyExist } from "./removeTopmostHandleIfAlreadyExist";

import type { Handles } from "../../../../redux/modules/children/workspace/flowTypes";

const
    // Usually queue will look like [c2, shiftbar-b-c2, workspace, shiftbar-b-c1, c1]
    // Probably queue length 3 is enough for most of cases
    // [shiftbar-b-c2, workspace, shiftbar-b-c1]
    // [shiftbar-u-c1, c1, shiftbar-b-c1]
    // keeping 5 for safety
    queueLength = 5,
    keepOnly5Handles = (handles: Handles) => {
        if (handles.length <= queueLength) {
            return handles;
        }
        return R.dropLast(handles.length - queueLength, handles);
    },
    epic = makeEpic({
        defaultState: [],
        valueActionType: topmostHandlesQueueValueActionType,
        updaters: [
            {
                conditions: [
                    topMostHandleVAT
                ],
                reducer: ({
                    values: [topMostHandle],
                    state
                }) => {
                    return {
                        state: R.pipe(
                            removeTopmostHandleIfAlreadyExist(topMostHandle),
                            R.prepend(topMostHandle),
                            keepOnly5Handles
                        )(state)
                    };
                }
            }
        ]
    });

export { epic as default };
