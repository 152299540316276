import React from 'react';
import { TextWrapperAdapter } from "./TextWrapperAdapter"; // Include this for non IFrame
// import TextWrapper from "./IFrameMode/TextWrapper"; // Include this for IFrame
import BaseComponent from "../../../../view/oneweb/BaseComponent";

export default (props: any) => {
    return (
        <BaseComponent {...props}>
            <TextWrapperAdapter {...props} />
        </BaseComponent>
    );
};

