import { setSocialShareAssetAction } from "../Pages/SocialShare/actions";
import { setLogoAssetAction } from "../Pages/WebsiteSettings/General/Logo/actions";
import { setFaviconAction } from "../Pages/WebsiteSettings/General/Favicon/actions";
import { FcContentTypes } from "../../../../../wbtgen/src/redux/modules/children/fileChooser/FcContentTypes";

export const IMAGE_CHOOSER_RESOURCE = 'imageChooserResource';

export const imageChooserAssetKeyMap = {
    socialShare: 'socialShare',
    logo: 'logo',
    favicon: 'favicon'
};

export const imageChooserAssetSetFunctionMap = {
    socialShare: setSocialShareAssetAction,
    logo: setLogoAssetAction,
    favicon: setFaviconAction,
};

export const imageChooserSupportedImagesMap = {
    socialShare: FcContentTypes.SUPPORTED_IMAGE,
    logo: FcContentTypes.SUPPORTED_IMAGE_WITH_SVG,
    favicon: FcContentTypes.SUPPORTED_IMAGE_WITH_ICONS,
};
