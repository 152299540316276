import React from "react";
import VerticalSpacer from '../../../../view/common/VerticalSpacer';
import getCenteredDialog from "../../../DialogManager/getCenteredDialogConfig";
import type { FacebookChatPremiumDialogPropTypes } from "../flowTypes";
import { injectIntl } from "../../../../view/intl/index";
import styles from './configurationDialog.css';
import { StripTypes } from "../../../../view/common/dialogs/baseDialog/index";
import StandardDialog from "../../../../view/common/dialogs/StandardDialog/index";
import { tryUpgradeDialogCompleted, tryUpgradeDialogCancelled } from "../../../ComponentTierManager/actionCreators";
import { getEcommercePackageNameFromMetadata } from '../../../ComponentTierManager/utils';
import ComponentTypes from "../../../../view/oneweb/registry/ComponentTypes";
import subscriptionDataEpic from '../../../App/epics/subscriptionData/index';

class FacebookChatPremiumDialog extends React.Component<FacebookChatPremiumDialogPropTypes> {
    render() {
        const { props: { dispatch, subscriptionData, intl } } = this;
        const subscriptionMetadata = subscriptionData?.metadata;
        const eCommercePackageNameFromMetadata = getEcommercePackageNameFromMetadata(subscriptionMetadata);
        return <StandardDialog
            title={intl.msgJoint([
                "msg: componentTier.ecommerceTier.businessEcommerceFeature {{packageName} feature}",
                { packageName: eCommercePackageNameFromMetadata || intl.msgJoint('msg: common.business_ecommerce {Business + Ecommerce}') }
            ])}
            titleExtraClassName={styles.titleContainer}
            titleClassName={styles.title}
            className={styles.dialog}
            iconClass={styles.premiumFeatureIcon}
            mctaText="msg: common.tryIt {Try it!}"
            mctaHandler={() => dispatch(tryUpgradeDialogCompleted(ComponentTypes.FACEBOOK_CHAT))}
            sctaHandler={null}
            onClose={() => dispatch(tryUpgradeDialogCancelled(ComponentTypes.FACEBOOK_CHAT))}
            stripType={StripTypes.NONE}
            footerClassName={styles.footerClass}
            primaryBtnClass={styles.tryItButton}
        >
            <div className={styles.premiumComponentIllustration} />
            <VerticalSpacer y={10} />
            <div className={styles.message}>
                {/* eslint-disable-next-line max-len */}
                {intl.msgJoint('msg: component.facebookChat.bneComponent.message {Messenger chat is great for talking to your visitors directly on your site. Try it out before upgrading to publish.}')}
            </div>
        </StandardDialog>;
    }
}

export default getCenteredDialog({
    width: 364,
    height: 368,
    component: injectIntl(FacebookChatPremiumDialog),
    dependsOn: {
        subscriptionData: subscriptionDataEpic.reducer,
    }
});
