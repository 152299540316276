import { setComponentsMap } from "../../Workspace/epics/componentsEval/setters";
import { REMOVE_EMPTY_STRIPS_OVERLAPPING_WITH_HEADER, SUPPORT_FIX_TOPS } from "./actions";
import { getCmps, simpleTopSorter } from "../../Workspace/epics/componentAttachements/util";
import { deselectAllComponents } from "../../Workspace/epics/componentsEval/selectedComponentsIds";
import { ReceiveOnlyTemplateWidthActionType } from "../../oneweb/Template/epics/template/selectorActionTypes";
import { updateComponentsRelIns } from "../../Workspace/epics/relations/updateComponentsRelIns";
import { wbtgen19612, wbtgen19799 } from "./fixTopsData";
import { isSectionKind } from "../../oneweb/componentKinds";

let fixTopsData: Record<string, any> = [];

if (window.location.href.indexOf('wbtgen19612') !== -1) {
    fixTopsData = wbtgen19612;
}
if (window.location.href.indexOf('wbtgen19799') !== -1) {
    fixTopsData = wbtgen19799;
}

export default {
    conditions: [
        ReceiveOnlyTemplateWidthActionType,
        SUPPORT_FIX_TOPS
    ],
    reducer: ({ values: [templateWidth], state: epicState }) => {
        let { state: { componentsMap } } = epicState,
            newComponentsMap: Record<string, Record<string, any>> = { ...componentsMap },
            found = false;
        fixTopsData.forEach(c => {
            if (!c.inTemplate && newComponentsMap[c.id] && newComponentsMap[c.id].top !== c.top) {
                found = true;
                newComponentsMap[c.id] = {
                    ...newComponentsMap[c.id],
                    top: c.top,
                    left: c.left,
                    relIn: null
                };
            }
        });
        if (found) {
            let
                tmpSections: Array<Record<string, any>> = [],
                pageSections: Array<Record<string, any>> = [],
                sections: Array<Record<string, any>> = [],
                cmps = getCmps(newComponentsMap);
            cmps.forEach(c => {
                let cmp = newComponentsMap[c.id];
                if (isSectionKind(cmp.kind)) {
                    if (cmp.inTemplate) {
                        tmpSections.push(cmp);
                    } else {
                        pageSections.push(cmp);
                    }
                }
            });

            const pageSectionBottom = cmps.reduce((acc, c) => {
                return (!c.inTemplate) ? Math.max(acc, c.top + c.height) : acc;
            }, 0) + 30;
            const lastPageSection = pageSections[pageSections.length - 1];
            newComponentsMap[lastPageSection.id] = {
                ...lastPageSection,
                height: pageSectionBottom - lastPageSection.top
            };

            pageSections.sort(simpleTopSorter);
            tmpSections.sort(simpleTopSorter);
            sections.push(tmpSections[0], ...pageSections, tmpSections[1]);

            let prevSection: Record<string, any> | null = null;
            sections.forEach((s, i) => {
                if (i > 0) {
                    prevSection = sections[i - 1];
                    const previewSectionBottom = newComponentsMap[prevSection.id].top + newComponentsMap[prevSection.id].height;
                    if (previewSectionBottom !== newComponentsMap[s.id].top) {
                        let diff = previewSectionBottom - newComponentsMap[s.id].top;
                        cmps.forEach(cmp => {
                            let c = newComponentsMap[cmp.id];
                            if (c.id === s.id || (c.relIn && c.relIn.id === s.id)) {
                                newComponentsMap[c.id] = {
                                    ...newComponentsMap[c.id],
                                    top: newComponentsMap[c.id].top + diff
                                };
                            }
                        });
                    }
                }
            });
            newComponentsMap = updateComponentsRelIns(newComponentsMap, templateWidth);
        }

        return {
            state: deselectAllComponents(setComponentsMap(newComponentsMap, epicState)),
            updateReason: REMOVE_EMPTY_STRIPS_OVERLAPPING_WITH_HEADER
        };
    }
};
