import React from 'react';
import { WidgetWorkspaceView } from '../../view/workspace';
import { MailchimpView } from './index';

const MailchimpWorkspaceView = props => (
    <WidgetWorkspaceView {...props}>
        <MailchimpView {...props} />
    </WidgetWorkspaceView>
);

export { MailchimpWorkspaceView };
