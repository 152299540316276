export const
    GS_BTN_GLOBAL_STYLE_SELECTED = 'GS_BTN_GLOBAL_STYLE_SELECTED',

    GS_BTN_TOGGLE_BOLD = 'GS_BTN_TOGGLE_BOLD',
    GS_BTN_TOGGLE_ITALIC = 'GS_BTN_TOGGLE_ITALIC',
    GS_BTN_TOGGLE_UNDERLINE = 'GS_BTN_TOGGLE_UNDERLINE',

    GS_BTN_FONT_FAMILY_CHANGED = 'GS_BTN_FONT_FAMILY_CHANGED',
    GS_BTN_FONT_SIZE_CHANGED = 'GS_BTN_FONT_SIZE_CHANGED',

    GS_BTN_INACTIVE_BORDER_STYLE_CHANGED = 'GS_BTN_INACTIVE_BORDER_STYLE_CHANGED',
    GS_BTN_INACTIVE_BORDER_COLOR_CHANGED = 'GS_BTN_INACTIVE_BORDER_COLOR_CHANGED',
    GS_BTN_INACTIVE_BORDER_COLOR_REMOVED = 'GS_BTN_INACTIVE_BORDER_COLOR_REMOVED',
    GS_BTN_INACTIVE_BORDER_COLOR_OPACITY_CHANGED = 'GS_BTN_INACTIVE_BORDER_COLOR_OPACITY_CHANGED',

    GS_BTN_INACTIVE_BG_COLOR_CHANGED = 'GS_BTN_INACTIVE_BG_COLOR_CHANGED',
    GS_BTN_INACTIVE_BG_COLOR_OPACITY_CHANGED = 'GS_BTN_INACTIVE_BG_COLOR_OPACITY_CHANGED',
    GS_BTN_INACTIVE_BG_COLOR_REMOVED = 'GS_BTN_INACTIVE_BG_COLOR_REMOVED',

    GS_BTN_INACTIVE_GRADIENT_CHANGED = 'GS_BTN_INACTIVE_GRADIENT_CHANGED',
    GS_BTN_INACTIVE_GRADIENT_OPACITY_CHANGED = 'GS_BTN_INACTIVE_GRADIENT_OPACITY_CHANGED',
    GS_BTN_INACTIVE_GRADIENT_CANCELLED = 'GS_BTN_INACTIVE_GRADIENT_CANCELLED',
    GS_BTN_INACTIVE_GRADIENT_DIRECTION_CHANGED = 'GS_BTN_INACTIVE_GRADIENT_DIRECTION_CHANGED',
    GS_BTN_INACTIVE_GRADIENT_FADEPOINT_CHANGED = 'GS_BTN_INACTIVE_GRADIENT_FADEPOINT_CHANGED',

    GS_BTN_INACTIVE_ASSET_CHANGED = 'GS_BTN_INACTIVE_ASSET_CHANGED',
    GS_BTN_INACTIVE_ASSET_REMOVED = 'GS_BTN_INACTIVE_ASSET_REMOVED',
    GS_BTN_INACTIVE_ASSET_POSITION_CHANGED = 'GS_BTN_INACTIVE_ASSET_POSITION_CHANGED',
    GS_BTN_INACTIVE_ASSET_REPEAT_CHANGED = 'GS_BTN_INACTIVE_ASSET_REPEAT_CHANGED',
    GS_BTN_INACTIVE_ASSET_SIZE_CHANGED = 'GS_BTN_INACTIVE_ASSET_SIZE_CHANGED',

    GS_BTN_HOVER_BORDER_STYLE_CHANGED = 'GS_BTN_HOVER_BORDER_STYLE_CHANGED',
    GS_BTN_HOVER_BORDER_COLOR_CHANGED = 'GS_BTN_HOVER_BORDER_COLOR_CHANGED',
    GS_BTN_HOVER_BORDER_COLOR_REMOVED = 'GS_BTN_HOVER_BORDER_COLOR_REMOVED',
    GS_BTN_HOVER_BORDER_COLOR_OPACITY_CHANGED = 'GS_BTN_HOVER_BORDER_COLOR_OPACITY_CHANGED',

    GS_BTN_HOVER_BG_COLOR_CHANGED = 'GS_BTN_HOVER_BG_COLOR_CHANGED',
    GS_BTN_HOVER_BG_COLOR_REMOVED = 'GS_BTN_HOVER_BG_COLOR_REMOVED',
    GS_BTN_HOVER_BG_COLOR_OPACITY_CHANGED = 'GS_BTN_HOVER_BG_COLOR_OPACITY_CHANGED',

    GS_BTN_HOVER_GRADIENT_CHANGED = 'GS_BTN_HOVER_GRADIENT_CHANGED',
    GS_BTN_HOVER_GRADIENT_CANCELLED = 'GS_BTN_HOVER_GRADIENT_CANCELLED',
    GS_BTN_HOVER_GRADIENT_OPACITY_CHANGED = 'GS_BTN_HOVERE_GRADIENT_OPACITY_CHANGED',
    GS_BTN_HOVER_GRADIENT_DIRECTION_CHANGED = 'GS_BTN_HOVER_GRADIENT_DIRECTION_CHANGED',
    GS_BTN_HOVER_GRADIENT_FADEPOINT_CHANGED = 'GS_BTN_HOVER_GRADIENT_FADEPOINT_CHANGED',

    GS_BTN_HOVER_ASSET_CHANGED = 'GS_BTN_HOVER_ASSET_CHANGED',
    GS_BTN_HOVER_ASSET_REMOVED = 'GS_BTN_HOVER_ASSET_REMOVED',
    GS_BTN_HOVER_ASSET_POSITION_CHANGED = 'GS_BTN_HOVER_ASSET_POSITION_CHANGED',
    GS_BTN_HOVER_ASSET_REPEAT_CHANGED = 'GS_BTN_HOVER_ASSET_REPEAT_CHANGED',
    GS_BTN_HOVER_ASSET_SIZE_CHANGED = 'GS_BTN_HOVER_ASSET_SIZE_CHANGED',

    GS_BTN_BORDER_WIDTH_CHANGED = 'GS_BTN_BORDER_WIDTH_CHANGED',

    GS_BTN_BORDER_RADIUS_CHANGED = 'GS_BTN_BORDER_RADIUS_CHANGED',

    GS_BTN_DEFAULT_TEXT_COLOR_CHANGED = 'GS_BTN_DEFAULT_TEXT_COLOR_CHANGED',
    GS_BTN_DEFAULT_TEXT_COLOR_REMOVED = 'GS_BTN_DEFAULT_TEXT_COLOR_REMOVED',

    GS_BTN_HOVER_TEXT_COLOR_CHANGED = 'GS_BTN_HOVER_TEXT_COLOR_CHANGED',
    GS_BTN_HOVER_TEXT_COLOR_REMOVED = 'GS_BTN_HOVER_TEXT_COLOR_REMOVED';
