import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useIntl } from "../../../../../../../../wbtgen/src/view/intl";
import { GeneralDataToWsb } from "../../../../../../../../wbtgen/src/components/SiteSettings/BusinessListing/GoogleMyBusiness/Mappers";
import { GoogleMapView } from "../../../../../../../../wbtgen/src/components/SiteSettings/BusinessListing/GoogleMyBusiness/GoogleMap/view";

import { generalDataSelector } from "../../../../../common/selectors";

const useStyles = makeStyles(
    theme => ({
        root: {
            display: "block",
            [theme.breakpoints.up("sm")]: {
                display: "inline-block",
            },
        },
        title: {
            fontSize: "15px",
            lineHeight: "24px",
            letterSpacing: "0.1px"
        },
        previewContainer: {
            marginTop: "10px",
            backgroundColor: theme.palette.custom.colorGray_f7,
            padding: "16px",
            fontSize: "13px",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            gap: "20px",
            [theme.breakpoints.up("sm")]: {
                flexDirection: "row",
                alignItems: "flex-start",
                gap: "16px",
            },
        },
        dataPreview: {
            margin: "0px",
            [theme.breakpoints.up("sm")]: {
                padding: "16px",
            }
        },
        mapContainer: {
            [theme.breakpoints.down("sm")]: {
                width: "100% !important",
            }
        }
    }),
    { name: "GMBListingPreview" }
);

export const GMBListingPreviewMap = () => {
    const intl = useIntl();
    const classes = useStyles();

    const generalData = useSelector(generalDataSelector);
    const { phoneNumber, addressFields, openingHours } = GeneralDataToWsb(generalData);

    return (
        <Box className={classes.root} data-testid="gmb-settings-preview">
            <Typography variant="subtitle2" className={classes.title}>
                {intl.msgJoint("msg: gmb.listingPreview {Listing preview}")}
            </Typography>

            <GoogleMapView
                phoneNumber={phoneNumber}
                addressFields={addressFields}
                openingHours={openingHours}
                classes={{
                    root: classes.previewContainer,
                    dataPreview: classes.dataPreview,
                    mapContainer: classes.mapContainer
                }}
            />
        </Box>
    );
};
