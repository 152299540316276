import * as R from 'ramda';
import {
    ACTIVATE_MODERN_HEADER,
    ACTIVATE_MODERN_FOOTER,
    DEACTIVATE_MODERN_HEADER,
    DEACTIVATE_MODERN_FOOTER,
    SWITCH_MODERN_HEADER,
    SWITCH_MODERN_FOOTER,
    MHF_COMPONENTS_TOGGLE,
    REMOVE_WEBSHOP_MHF_DATA,
    UPDATE_MHF_COMPONENTS_POSITION,
    UPGRADE_MODERN_HEADER_FOOTER,
    ADD_REMOVE_WEBSHOP_STRIP,
    ADD_REMOVE_WEBSHOP_STRIP_SUCCESS,
} from "./actionTypes";
import { CHANGE_SCOPE } from "../Workspace/epics/componentsEval/updateReasons";
import { ReceiveOnlyTemplateShowShopStripInFooterActionType,
    ReceiveOnlyTemplateWidthActionType } from "../oneweb/Template/epics/template/selectorActionTypes";
import type { ComponentsEvalEpicUpdater } from "../Workspace/epics/componentsEval/flowTypes";
import {
    ReceiveOnlyComponentsDependenciesSelector,
    ReceiveOnlyComponentsMap,
} from "../Workspace/epics/componentsEval/selectorActionTypes";
import { receiveOnly } from "../../epics/makeCondition";
import mhfDataVAT from './epics/oldData/valueActionType';
import { activateSection, deactivateSection, switchSection, upgradeSection } from "./activateDeactivateFns";
import { toggleCmpWithChildren, updateCmpsMapAfterToggle } from "./toggleFns";
import { sectionNames } from "./constants";
import getLayoutsMap from "./getLayoutsMap";
import { setComponentsMap, setMHFCmpsData, setComponentsMapExtension } from "../Workspace/epics/componentsEval/setters";
import { getComponentsMap, getComponentsMapExtension, getMHFCmpsData } from "../Workspace/epics/componentsEval/getters";
import {
    arrangeComponentsAndUpdateSectionHeight,
    getTemplateSectionByName,
    setSectionLayoutMinDimension,
    arrangeCmps,
    getComponentIds,
    createNewComponents,
} from "./utils";
import { isHeaderSection, updateSectionHeightAndAdjustOtherSectionsPositions,
    getHeaderAndFooterSection } from "../oneweb/Section/utils";
import {
    shareHeaderAndFirstSectionBgImgEpic
} from '../oneweb/Section/epics/shareHeaderAndFirstSectionBgImgEpic';
import webShopMHFValueActionType from "../oneweb/WebShopMHF/epic/valueActionType";
import { removeWebshopMHFDataFromLayout, getWebShopLayout, webShopCmps, getWebShopOptions,
    isCmpsMapHasWebshopFooterCmps,
    getWebShopPolicyCmpId,
    getWebShopPaymentCmpId,
    webShopFooterCmpSBackupPaths,
    getWebShopStripId,
    getWebShopFooterCmpIdsWithOutStrip } from "./layoutsData/webshopMHFDataUtils";
import type { ModernSectionComponent } from '../oneweb/Section/flowTypes';
import { getAllComponentIdsInModernSection, isModernLayoutSection,
    replaceComponentIdsInLayout, createRelInGivenParentAndChild, createRelInIdsAsPerLayout } from './preview_utils';
import getUpdatedLayoutBasedOnOptions from './getUpdatedLayoutBasedOnOptions';
import { isStripKind } from '../oneweb/componentKinds';
import { COMPONENTS_DELETED } from '../Workspace/actionTypes';
import { getMLayoutMessages } from './layoutsData/mLayoutMessages';
import { PREFERENCES_EPIC_VALUE_VAT } from '../Preferences/preferencesVat';
import { openBanner } from '../Banner/utils';
import { TEMPLATE_DISPLAY_SHOP_STRIP_IN_FOOTER_SET_TRUE } from '../oneweb/Template/actionTypes';
import { POPULATE_WEBSHOP_FOOTER_CMPS_BACKUP_DATA } from '../oneweb/WebShopMHF/epic/actionTypes';
import { ShopFooterAutoAddBannerText } from './banner';
import BannerStyles from './view/banner.css';

const getNewCmpsAfterWebShopFooterDeletion = (componentsMap, footerSection) => {
    let toBeDeletedCmpsIds: Array<string> = [];
    const webShopStripId = getWebShopStripId(componentsMap);
    if (webShopStripId) {
        toBeDeletedCmpsIds.push(webShopStripId);
    }
    toBeDeletedCmpsIds = toBeDeletedCmpsIds.concat(getWebShopFooterCmpIdsWithOutStrip(componentsMap));
    let webShopFooterCmpsBackupData = {};
    if (toBeDeletedCmpsIds.length) {
        let newCmps = { ...componentsMap };
        toBeDeletedCmpsIds.forEach(cmpId => {
            const newCmp = newCmps[cmpId];
            if (!newCmp) {
                return;
            }
            const propPaths = webShopFooterCmpSBackupPaths[newCmp.kind];
            propPaths.forEach(propPath => {
                webShopFooterCmpsBackupData = R.assocPath(
                    [newCmp.kind, ...propPath],
                    R.view(R.lensPath(propPath), newCmp)
                )(webShopFooterCmpsBackupData);
            });
            if (isStripKind(newCmp.kind)) {
                newCmps = R.assocPath(
                    [footerSection.id, 'height'],
                    footerSection.height - newCmp.height,
                )(newCmps);
            }
        });
        newCmps = R.omit(toBeDeletedCmpsIds, newCmps);
        let multipleActionsToDispatch: Action[] = [{
            type: COMPONENTS_DELETED,
            payload: toBeDeletedCmpsIds
        }, {
            type: POPULATE_WEBSHOP_FOOTER_CMPS_BACKUP_DATA,
            payload: webShopFooterCmpsBackupData
        }];
        return {
            cmpsMap: newCmps,
            webShopFooterCmpsBackupData,
            multipleActionsToDispatch
        };
    }
    return {};
};

const
    upgradeModernHeaderFooter: ComponentsEvalEpicUpdater = {
        conditions: [
            receiveOnly(webShopMHFValueActionType),
            UPGRADE_MODERN_HEADER_FOOTER
        ],
        reducer: ({
            values: [webshopMHFData],
            state: epicState
        }) => {
            const layoutsMap = getLayoutsMap(webshopMHFData);
            return upgradeSection({
                epicState,
                layoutsMap
            });
        }
    },
    activateModernHeader: ComponentsEvalEpicUpdater = {
        conditions: [
            ReceiveOnlyComponentsDependenciesSelector,
            ReceiveOnlyTemplateWidthActionType,
            receiveOnly(mhfDataVAT),
            receiveOnly(shareHeaderAndFirstSectionBgImgEpic.valueActionType),
            receiveOnly(webShopMHFValueActionType),
            ACTIVATE_MODERN_HEADER,
        ],
        reducer: ({
            values: [componentsDependencies, templateWidth, mhfData, { enabled }, webshopMHFData, layoutId],
            state: epicState,
            dispatchAsync,
        }) => {
            const layoutsMap = getLayoutsMap(webshopMHFData);
            return activateSection({
                sectionName: sectionNames.header,
                epicState,
                componentsDependencies,
                templateWidth,
                mhfData,
                layoutId,
                layoutsMap,
                shareSectionBgImg: enabled,
                dispatchAsync,
                webshopMHFData,
            });
        }
    },
    activateModernFooter: ComponentsEvalEpicUpdater = {
        conditions: [
            ReceiveOnlyComponentsDependenciesSelector,
            ReceiveOnlyTemplateWidthActionType,
            receiveOnly(mhfDataVAT),
            receiveOnly(webShopMHFValueActionType),
            ACTIVATE_MODERN_FOOTER,
        ],
        reducer: ({
            values: [componentsDependencies, templateWidth, mhfData, webshopMHFData, layoutId],
            state: epicState,
            dispatchAsync,
        }) => {
            const layoutsMap = getLayoutsMap(webshopMHFData);
            return activateSection({
                sectionName: sectionNames.footer,
                epicState,
                componentsDependencies,
                templateWidth,
                mhfData,
                layoutId,
                layoutsMap,
                dispatchAsync,
                shareSectionBgImg: false,
                webshopMHFData,
            });
        }
    },
    deactivateModernHeader: ComponentsEvalEpicUpdater = {
        conditions: [
            receiveOnly(mhfDataVAT),
            DEACTIVATE_MODERN_HEADER,
        ],
        reducer: ({ values: [mhfData], state: epicState, dispatchAsync }) => deactivateSection({
            sectionName: sectionNames.header,
            epicState,
            mhfData,
            dispatchAsync
        })
    },
    deactivateModernFooter: ComponentsEvalEpicUpdater = {
        conditions: [
            receiveOnly(mhfDataVAT),
            DEACTIVATE_MODERN_FOOTER,
        ],
        reducer: ({ values: [mhfData], state: epicState, dispatchAsync }) => deactivateSection({
            sectionName: sectionNames.footer,
            epicState,
            mhfData,
            dispatchAsync
        })
    },
    switchModernHeader: ComponentsEvalEpicUpdater = {
        conditions: [
            ReceiveOnlyComponentsDependenciesSelector,
            ReceiveOnlyTemplateWidthActionType,
            receiveOnly(mhfDataVAT),
            receiveOnly(shareHeaderAndFirstSectionBgImgEpic.valueActionType),
            receiveOnly(webShopMHFValueActionType),
            SWITCH_MODERN_HEADER,
        ],
        reducer: ({ values: [componentsDependencies, templateWidth, mhfData, { enabled },
            webshopMHFData, layoutId], state: epicState, dispatchAsync }) => {
            const layoutsMap = getLayoutsMap(webshopMHFData);
            return switchSection({
                sectionName: sectionNames.header,
                epicState,
                componentsDependencies,
                templateWidth,
                mhfData,
                layoutId,
                layoutsMap,
                shareSectionBgImg: enabled,
                dispatchAsync,
                webshopMHFData,
            });
        }
    },
    switchModernFooter: ComponentsEvalEpicUpdater = {
        conditions: [
            ReceiveOnlyComponentsDependenciesSelector,
            ReceiveOnlyTemplateWidthActionType,
            receiveOnly(mhfDataVAT),
            receiveOnly(webShopMHFValueActionType),
            SWITCH_MODERN_FOOTER,
        ],
        reducer: ({ values: [componentsDependencies, templateWidth, mhfData, webshopMHFData, layoutId],
            state: epicState, dispatchAsync }) => {
            const layoutsMap = getLayoutsMap(webshopMHFData);
            return switchSection({
                sectionName: sectionNames.footer,
                epicState,
                componentsDependencies,
                templateWidth,
                mhfData,
                layoutId,
                layoutsMap,
                dispatchAsync,
                shareSectionBgImg: false,
                webshopMHFData,
            });
        }
    },
    componentToggleMhf: ComponentsEvalEpicUpdater = {
        conditions: [
            ReceiveOnlyComponentsDependenciesSelector,
            receiveOnly(mhfDataVAT),
            receiveOnly(webShopMHFValueActionType),
            MHF_COMPONENTS_TOGGLE
        ],
        reducer: ({ values: [
            componentDependencies,
            mfhData,
            webshopMHFData,
            { toggleCmpId, toggleCmpFlag, sectionId }
        ], state }) => {
            const componentsMap = getComponentsMap(state),
                { updatedCmpsMap, children } = toggleCmpWithChildren(componentsMap, sectionId, toggleCmpId, toggleCmpFlag, webshopMHFData),
                componentsMapExtension = getComponentsMapExtension(state),
                sectionName = isHeaderSection(updatedCmpsMap[sectionId]) ? sectionNames.header : sectionNames.footer,
                layoutId = updatedCmpsMap[sectionId].modernLayout.layoutId;
            let { newCmps, multipleActionsToDispatch } = updateCmpsMapAfterToggle(
                {
                    componentsMap: updatedCmpsMap,
                    componentsMapExtension
                },
                componentDependencies,
                mfhData,
                sectionName,
                sectionId,
                layoutId,
                toggleCmpId,
                toggleCmpFlag,
                children,
                webshopMHFData,
            );
            newCmps = arrangeComponentsAndUpdateSectionHeight(
                newCmps,
                sectionId,
            );
            return {
                state: setComponentsMap(
                    newCmps,
                    state
                ),
                multipleActionsToDispatch,
                updateReason: MHF_COMPONENTS_TOGGLE,
            };
        }
    },
    removeWebshopMHFData: ComponentsEvalEpicUpdater = {
        conditions: [
            receiveOnly(webShopMHFValueActionType),
            REMOVE_WEBSHOP_MHF_DATA
        ],
        reducer: ({ values: [webshopMHFData], state }) => {
            const componentsMap = getComponentsMap(state),
                footerSection = getTemplateSectionByName(sectionNames.footer, componentsMap);

            if (
                !footerSection ||
                !footerSection.modernLayout ||
                !footerSection.modernLayout.active ||
                !footerSection.modernLayout.options
            ) {
                return { state };
            }

            const [newCmps, multipleActionsToDispatch] =
                removeWebshopMHFDataFromLayout(footerSection.id, componentsMap, webshopMHFData);

            return {
                state: setComponentsMap(
                    newCmps,
                    state
                ),
                multipleActionsToDispatch,
                updateReason: REMOVE_WEBSHOP_MHF_DATA,
            };
        }
    },
    updateMHFCmpsPositions: ComponentsEvalEpicUpdater = {
        conditions: [
            ReceiveOnlyComponentsMap,
            UPDATE_MHF_COMPONENTS_POSITION
        ],
        reducer: ({ values: [componentsMap, layoutRect], state }) => {
            const { mhfCmpsData: newMhfCmpsData, cDimensions } = layoutRect,
                componentsMapExtension = getComponentsMapExtension(state);
            let updatedCmpsMap = componentsMap,
                newState = state;

            Object.keys(cDimensions).forEach(id => {
                const position = cDimensions[id],
                    component = componentsMap[id];
                if (!component) { return; }
                if (
                    position.left !== component.left ||
                    position.top !== component.top ||
                    position.height !== component.height ||
                    position.width !== component.width
                ) {
                    updatedCmpsMap = {
                        ...updatedCmpsMap,
                        [id]: { ...component, ...position }
                    };
                }
            });
            if (!R.isEmpty(newMhfCmpsData)) {
                newState = setMHFCmpsData({ ...getMHFCmpsData(state), ...newMhfCmpsData }, state);
            }
            if (updatedCmpsMap !== componentsMap) {
                const { header, footer } = getHeaderAndFooterSection(updatedCmpsMap);
                [header, footer].forEach(({ id: sectionId }) => {
                    const section = updatedCmpsMap[sectionId];
                    if (!section || !isModernLayoutSection(section)) return;
                    const { newLayout } = getUpdatedLayoutBasedOnOptions(section);
                    const {
                            id,
                            modernLayout:
                        { minDimensions:
                            // @ts-ignore
                            { height: sectionMinHeight, width: sectionMinWidth } = {} }
                        } = ((section as any) as ModernSectionComponent),
                        { minHeight, minWidth } = arrangeCmps(
                            componentsMap,
                            newLayout,
                            id,
                            componentsMapExtension
                        );

                    if (minHeight !== sectionMinHeight || minWidth !== sectionMinWidth) {
                        updatedCmpsMap = {
                            ...updatedCmpsMap,
                            [id]: setSectionLayoutMinDimension(section, { height: minHeight, width: minWidth })
                        };
                    }

                    if (section.height >= minHeight) { return; }
                    const allCmpsInSection = getAllComponentIdsInModernSection(section, updatedCmpsMap);

                    updatedCmpsMap = updateSectionHeightAndAdjustOtherSectionsPositions(
                        minHeight,
                        id,
                        updatedCmpsMap,
                        allCmpsInSection
                    );
                });
            }
            if (updatedCmpsMap !== componentsMap) {
                newState = setComponentsMap(updatedCmpsMap, newState);
            }
            return {
                state: newState,
                updateReason: CHANGE_SCOPE
            };
        }
    },
    addWebShopFooteronPageLoad: ComponentsEvalEpicUpdater = {
        conditions: [
            ReceiveOnlyComponentsDependenciesSelector,
            receiveOnly(webShopMHFValueActionType),
            ReceiveOnlyTemplateShowShopStripInFooterActionType,
            receiveOnly(PREFERENCES_EPIC_VALUE_VAT),
            ADD_REMOVE_WEBSHOP_STRIP
        ],
        reducer: ({
            values: [cmpDeps, webshopMHFData, workspaceTemplateShowShopStripInFooter, preferences],
            state
        }) => {
            const showSpecialFooterStrip = webshopMHFData && webshopMHFData.showSpecialFooterStrip,
                componentsMap = getComponentsMap(state),
                componentsMapExtension = getComponentsMapExtension(state),
                isWebshopFooterCmpsExists = isCmpsMapHasWebshopFooterCmps(componentsMap),
                multipleActionsToDispatch: Action[] = [{
                    type: ADD_REMOVE_WEBSHOP_STRIP_SUCCESS
                }];
            let footerSection = getTemplateSectionByName(sectionNames.footer, componentsMap),
                webShopFooterCmpsBackupData = webshopMHFData.webShopFooterCmpsBackupData || {};

            if (!showSpecialFooterStrip || workspaceTemplateShowShopStripInFooter === false) {
                if (!isWebshopFooterCmpsExists) {
                    return { state };
                }
                const newState = getNewCmpsAfterWebShopFooterDeletion(componentsMap, footerSection);
                if (R.isEmpty(newState)) {
                    return { state };
                }
                webShopFooterCmpsBackupData = {
                    ...webShopFooterCmpsBackupData,
                    ...newState.webShopFooterCmpsBackupData
                };
                multipleActionsToDispatch.push(...(newState.multipleActionsToDispatch || []));
                return {
                    state: setComponentsMap(newState.cmpsMap, state),
                    multipleActionsToDispatch,
                    updateReason: ADD_REMOVE_WEBSHOP_STRIP
                };
            }
            const { data: { policies = [], paymentMethods = [], isShopPublished }, privacyPolicyContent } = webshopMHFData,
                isPoliciesExists = !!policies.length || !!privacyPolicyContent,
                isPaymentMethodExists = !!paymentMethods.length,
                isPoliciesCmpExists = getWebShopPolicyCmpId(componentsMap),
                isPaymentMethodCmpExists = getWebShopPaymentCmpId(componentsMap),
                isParentStripExists = getWebShopStripId(componentsMap);

            let newCmpsMap = { ...componentsMap };
            if (isWebshopFooterCmpsExists) {
                const isCmpsRegenerationNotRequired = () => {
                    if (isPoliciesExists && isPaymentMethodExists) {
                        return isPoliciesCmpExists && isPaymentMethodCmpExists;
                    }
                    if (isPoliciesExists) {
                        return isPoliciesCmpExists && !isPaymentMethodCmpExists;
                    }
                    if (isPaymentMethodExists) {
                        return !isPoliciesCmpExists && isPaymentMethodCmpExists;
                    }
                    return false;
                };
                if (isCmpsRegenerationNotRequired() && isParentStripExists) {
                    return { state };
                }
                const newState = getNewCmpsAfterWebShopFooterDeletion(componentsMap, footerSection);
                newCmpsMap = newState.cmpsMap;
                webShopFooterCmpsBackupData = {
                    ...webShopFooterCmpsBackupData,
                    ...newState.webShopFooterCmpsBackupData
                };
                multipleActionsToDispatch.push(...(newState.multipleActionsToDispatch || []));
            }
            if (isShopPublished && (isPoliciesExists || isPaymentMethodExists) &&
                !preferences.webshopStripAddedToFooterInfoSeen) {
                if (!webshopMHFData.isPageLoad) {
                    multipleActionsToDispatch.push(openBanner({
                        iconCss: BannerStyles.bannerIcon,
                        texts: [ShopFooterAutoAddBannerText],
                    }));
                }
            }

            footerSection = newCmpsMap[footerSection.id];
            const webShopLayout = getWebShopLayout(isPoliciesExists, isPaymentMethodExists);
            const componentsToCreate = getComponentIds(getWebShopOptions(getMLayoutMessages(), isPoliciesExists, isPaymentMethodExists),
                webShopCmps);
            let {
                oldToNewIdMap,
                componentsMap: duplicatedCmpsMap,
                componentsMapExtension: duplicatedCmpsMapExtension
            } = createNewComponents(componentsToCreate, newCmpsMap,
                cmpDeps, {});
            const layoutWithIds = replaceComponentIdsInLayout(webShopLayout, oldToNewIdMap);
            const stripId = Object.keys(duplicatedCmpsMap).find(id => isStripKind(duplicatedCmpsMap[id].kind));
            if (!stripId) {
                return { state };
            }
            duplicatedCmpsMap = R.assocPath(
                [stripId, 'top'],
                footerSection.top + footerSection.height,
            )(duplicatedCmpsMap);
            Object.keys(duplicatedCmpsMap).forEach(cmpId => {
                duplicatedCmpsMap[cmpId] = {
                    ...duplicatedCmpsMap[cmpId],
                    ...webShopFooterCmpsBackupData[duplicatedCmpsMap[cmpId].kind]
                };
            });
            const newCmps = arrangeCmps(
                duplicatedCmpsMap,
                layoutWithIds,
                stripId,
                duplicatedCmpsMapExtension
            );
            const newFooterSection = {
                ...footerSection,
                height: footerSection.height + newCmps.newCmpsMap[stripId].height,
            };
            const cmpWithRelIns = createRelInIdsAsPerLayout(newCmps.newCmpsMap, layoutWithIds);
            const newUpdatedState = R.pipe(
                setComponentsMap({
                    ...newCmpsMap,
                    ...cmpWithRelIns,
                    [stripId]: {
                        ...newCmps.newCmpsMap[stripId],
                        relIn: createRelInGivenParentAndChild(newFooterSection, newCmps.newCmpsMap[stripId]),
                    },
                    [footerSection.id]: newFooterSection
                }),
                setComponentsMapExtension({ ...componentsMapExtension, ...duplicatedCmpsMapExtension }),
            )(state);
            multipleActionsToDispatch.push({
                type: TEMPLATE_DISPLAY_SHOP_STRIP_IN_FOOTER_SET_TRUE
            });
            return {
                state: newUpdatedState,
                multipleActionsToDispatch,
                updateReason: ADD_REMOVE_WEBSHOP_STRIP
            };
        }
    };

export const modernHFLayoutUpdaters = [
    activateModernHeader,
    activateModernFooter,
    deactivateModernHeader,
    deactivateModernFooter,
    switchModernHeader,
    switchModernFooter,
    componentToggleMhf,
    removeWebshopMHFData,
    upgradeModernHeaderFooter,
    updateMHFCmpsPositions,
    addWebShopFooteronPageLoad,
];
