import { applyMappers, makePlainMappers } from "../../utils";
import * as bBoxMapper from "./bBox";
import * as typeMapper from "./type";
import relInMapper from "./relIn";
import relToMapper from "./relTo";
import relParaMapper from "./relPara";
import relPageMapper from "./relPage";
import type { AnyComponent } from "../../../../src/redux/modules/children/workspace/flowTypes";
import type { ComponentBase } from '../../../../src/components/oneweb/flowTypes';
import type { AnyComponentData } from "../../../../src/components/App/flowTypes";
import { debugComponentsBbox } from "../../../../src/utils/isDebug";
import makeUuid from "../../../../utils/makeUuid";

const
    plainPropsMap = {
        id: 'id',
        inTemplate: 'inTemplate',
        orderIndex: 'orderIndex',
        stretch: 'stretch',
        wrap: 'wrap',
        mobileDown: 'mobileDown',
        onHover: 'onHover'
    },
    makePropsMapper = (extraProps: Record<string, any> = {}) => makePlainMappers({
        ...plainPropsMap,
        ...extraProps
    });

export function to(component: AnyComponentData): ComponentBase {
    return applyMappers(
        component,
        makePropsMapper(debugComponentsBbox() ? { debugBbox: 'debugBbox' } : {}).to,
        typeMapper.to,
        bBoxMapper.to,
        relInMapper.to,
        relToMapper.to,
        relPageMapper.to,
        relParaMapper.to,
        () => (component.id === undefined ? { id: makeUuid() } : {})
    );
}

export function back(component: AnyComponent): any {
    return applyMappers(
        component,
        typeMapper.back,
        makePropsMapper().back,
        bBoxMapper.back,
        relInMapper.back,
        relToMapper.back,
        relPageMapper.back,
        relParaMapper.back
    );
}
