import Resource from "../../../Resource";

type ReturnType = {
    resources: Array<Resource>,
    currentResource: Resource,
}

export const setResourceIsLoading = (
    resourceId: string,
    resources: Array<Resource>
): ReturnType => {
    let resource: Resource | null = null;
    const nextResources = resources.map((res: Resource) => {
        if (res.getId() === resourceId) {
            resource = new Resource(res.basePath, { ...res, isLoading: true });
            return resource;
        }
        return res;
    });
    if (!resource) throw new Error(`Cannot find resource by id: ${resourceId}`);

    return {
        resources: nextResources,
        currentResource: resource
    };
};
