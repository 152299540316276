import CALL_API from "../../../../../wbtgen/src/redux/middleware/api/CALL_API";

import type { SaveStatusChanges, SiteDataType, SiteSettingsType } from "../../common/types";
import { ThirdPartyConnectionsState } from "../ThirdPartyConnections/types";

export const
    SAVE_BUTTON_CLICKED_ACTION = 'SAVE_BUTTON_CLICKED_ACTION',

    SAVE = 'SAVE',

    SAVE_STATUS_HAS_NO_UNSAVED_CHANGES = 'SAVE_STATUS_HAS_NO_UNSAVED_CHANGES',
    SAVE_STATUS_HAS_UNSAVED_CHANGES = 'SAVE_STATUS_HAS_UNSAVED_CHANGES',

    SAVE_STATUS_IN_PROGRESS_ACTION = 'SAVE_STATUS_IN_PROGRESS_ACTION',
    SAVE_STATUS_SAVED_ACTION = 'SAVE_STATUS_SAVED_ACTION',
    SAVE_STATUS_FAILED_ACTION = 'SAVE_STATUS_FAILED_ACTION',

    UPDATE_SAVE_STATUS_VALUE_ACTION = 'UPDATE_SAVE_STATUS_VALUE_ACTION',

    SAVE_SITE_DOCUMENTS_REQUEST = 'SAVE_SITE_DOCUMENTS_REQUEST',
    SAVE_SITE_DOCUMENTS_SUCCESS = 'SAVE_SITE_DOCUMENTS_SUCCESS',
    SAVE_SITE_DOCUMENTS_FAILURE = 'SAVE_SITE_DOCUMENTS_FAILURE',
    SAVE_SITE_DOCUMENTS_ACTIONS = [
        SAVE_SITE_DOCUMENTS_REQUEST,
        SAVE_SITE_DOCUMENTS_SUCCESS,
        SAVE_SITE_DOCUMENTS_FAILURE
    ];

export const
    saveButtonClickedAction = (onSavedAction?: Action) => ({
        type: SAVE_BUTTON_CLICKED_ACTION,
        payload: { onSavedAction }
    }),

    saveAction = (onSavedAction?: Action) => ({ type: SAVE, payload: { onSavedAction } }),

    saveStatusHasNoUnsavedChanges = () => ({ type: SAVE_STATUS_HAS_NO_UNSAVED_CHANGES }),
    saveStatusHasUnsavedChanges = (config?: { [key in keyof SaveStatusChanges]?: true }) => ({
        type: SAVE_STATUS_HAS_UNSAVED_CHANGES,
        payload: config
    }),

    saveStatusInProgressAction = () => ({ type: SAVE_STATUS_IN_PROGRESS_ACTION }),
    saveStatusSavedAction = () => ({ type: SAVE_STATUS_SAVED_ACTION }),
    saveStatusFailedAction = () => ({ type: SAVE_STATUS_FAILED_ACTION }),

    updateSaveStatusValueAction = () => ({ type: UPDATE_SAVE_STATUS_VALUE_ACTION }),

    saveSiteDocumentsAction = (
        documentsUpdate: {
            siteSettings?: SiteSettingsType,
            siteData?: SiteDataType,
            thirdPartyConnections?: ThirdPartyConnectionsState
        },
        extraPayload?: { successAction?: Action }
    ) => ({
        [CALL_API]: {
            types: [
                SAVE_SITE_DOCUMENTS_REQUEST,
                SAVE_SITE_DOCUMENTS_SUCCESS,
                SAVE_SITE_DOCUMENTS_FAILURE
            ],
            endpoint: 'updateSiteDocuments',
            endpointParams: documentsUpdate,
            extraPayload
        }
    } as unknown as Action);
