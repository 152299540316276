import { SELECTED_GLOBAL_STYLE_REGEX } from '../../constants';
import { getSelectedNodesProperties } from "../../utils/nodeUtils/getSelectedNodesProperties";

import type { TinyMceEditor } from "../../../flowTypes";

const selector = (root: Object) => (node: Object) => {
    const findParentClassname = node => {
        if (node === root) {
            return 'textnormal';
        }

        return SELECTED_GLOBAL_STYLE_REGEX.test(node.className) ? // eslint-disable-line no-nested-ternary
            node.className.match(SELECTED_GLOBAL_STYLE_REGEX).pop() :
            (node.parentNode ? findParentClassname(node.parentNode) : null);
    };

    return findParentClassname(node);
};

export const getSelectedGlobalStyles = (editor: TinyMceEditor) => {
    const root = editor.getBody();
    return getSelectedNodesProperties(editor, selector(root), []);
};
