import React from "react";
import { pure } from 'recompose';
import HorizontalSpacer from '../../../../view/common/HorizontalSpacer';
import { MctaVerticalBar, MctaCheckbox } from '../../../componentMainActions/index';
import { isPagePostVisible } from '../utils';
import * as actionTypes from '../actionTypes';
import type { CTAViewParams } from '../../../../view/Workspace/Decorations/ComponentMainActions/flowTypes';
import type { FacebookPageComponent } from '../flowTypes';

const
    EditView = pure(({ selectedComponent: { showFacepile, smallHeader, hideCover, tabs }, children, dispatch }) => (
        <div>
            <HorizontalSpacer x={5} />
            <MctaCheckbox
                label="msg: component.facebook.tb.largeHeader {Large header}"
                isChecked={!smallHeader}
                onClick={() => dispatch({ type: actionTypes.FACEBOOKPAGE_TOGGLE_USE_SMALL_HEADER })}
            />
            <HorizontalSpacer x={12} />
            <MctaVerticalBar />
            <HorizontalSpacer x={12} />
            <MctaCheckbox
                label="msg: component.facebook.tb.coverPhoto {Cover photo}"
                isChecked={!hideCover}
                onClick={() => dispatch({ type: actionTypes.FACEBOOKPAGE_TOGGLE_SHOW_COVER })}
            />
            <HorizontalSpacer x={12} />
            <MctaVerticalBar />
            <HorizontalSpacer x={12} />
            <MctaCheckbox
                label="msg: component.facebook.tb.showFriends {Show friends}"
                isChecked={showFacepile}
                onClick={() => dispatch({ type: actionTypes.FACEBOOKPAGE_TOGGLE_SHOW_FRIENDS_FACES })}
            />
            <HorizontalSpacer x={12} />
            <MctaVerticalBar />
            <HorizontalSpacer x={12} />
            <MctaCheckbox
                label="msg: component.facebook.tb.posts {Posts}"
                isChecked={isPagePostVisible(tabs)}
                onClick={() => dispatch({ type: actionTypes.FACEBOOKPAGE_TOGGLE_SHOW_PAGE_POSTS })}
            />
            {children ? <HorizontalSpacer /> : <HorizontalSpacer x={5} />}
            {children}
        </div>
    ));

export default {
    editView: EditView,
    editButtonText: 'msg: component.facebook.tb.settings {Settings}',
    ctaButtonText: 'msg: component.facebook.tb.changeFacebookPage {Change Facebook page}',
    ctaOnClick: ({ dispatch, selectedComponent: { pageURL } }: CTAViewParams<FacebookPageComponent>) =>
        dispatch({ type: actionTypes.FACEBOOKPAGE_SHOW_UPDATE_PAGE_URL_DIALOG, payload: { pageURL } })
};
