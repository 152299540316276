import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import browserDimensionsValueActionType from '../../../App/epics/browserDimensions/valueActionType';
import leftPanelWidthValueActionType from '../../../Panel/epics/width/valueActionType';
import { TopBarHeight } from "../../../TopBar/constants";

export default makeEpic({
    valueActionType,
    updaters: [
        {
            conditions: [
                browserDimensionsValueActionType,
                leftPanelWidthValueActionType
            ],
            reducer: ({
                values: [
                    {
                        width: browserWidth,
                        height: browserHeight
                    },
                    leftPanelWidth
                ]
            }) => ({ state: { width: browserWidth - leftPanelWidth, height: browserHeight - TopBarHeight } })
        }
    ]
});
