import React from "react";
import Combobox from "../../../view/common/Combobox/index";
import { intlCreateOptions } from "../../../view/common/Combobox/utils";
import PropertyContainer from "../../../view/common/PropertyContainer/index";
import { IMAGE_RATIO } from "./constants";
import injectIntl from "../../../view/intl/injectIntl";
import type { ImageRatioComboBoxPropTypes } from "./flowTypes";

const CaptionStyleOptions = {
    [IMAGE_RATIO.LANDSCAPE]: "msg: common.imageRatio.option.landscape {Landscape}",
    [IMAGE_RATIO.PORTRAIT]: "msg: common.imageRatio.option.portrait {Portrait}",
    [IMAGE_RATIO.SQUARE]: "msg: common.imageRatio.option.square {Square}",
    [IMAGE_RATIO.ACTUAL_RATIO]: "msg: webshop.Actual {Actual ratio}",
};

export default injectIntl(({ disabled, value, onChangeAction, dispatch, intl }: ImageRatioComboBoxPropTypes) => (
    <PropertyContainer label="msg: common.imageRatio {Image ratio}">
        <Combobox
            editable={false}
            placeholder={intl.msgJoint("msg: common.borderStyle.option.selecttype {Select...}")}
            disabled={disabled}
            value={value}
            options={intlCreateOptions(CaptionStyleOptions, intl)}
            onChange={({ value }) => dispatch({ type: onChangeAction, payload: value })}
            searchable={false}
        />
    </PropertyContainer>
));
