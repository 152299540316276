export const
    RESERVED_CONTENT_TRACKER = {
        CATEGORY: 'Publishing',
        ACTION: 'publish',
        LABEL: 'PUBLISHED_WITHOUT_RESERVED_CONTENT'
    },

    WORKSPACE_READY_TRACKER = {
        CATEGORY: 'Subscription',
        ACTION: 'entered_workspace',
        LABEL: 'WORKSPACE_READY'
    },

    USER_PUBLISHED_TRACKER = {
        CATEGORY: 'Publishing',
        ACTION: 'publish',
        LABEL: 'USER_PUBLISHED',
    };
