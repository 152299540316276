import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import { WIDGET_LOADED, WIDGET_CANNOT_LOAD } from "../../../../constants/app";
import { windowChatAddTags, windowChatRemoveTags } from "./actionCreators";
import { ADD_CHAT_TAG_WSB_TABLE_EJECTS_COMPONENT, ADD_CHAT_TAG_WSB_TRIAL } from "./actionTypes";
import { chatTags } from './constants';

const
    addChatTagUpdaters = [
        ADD_CHAT_TAG_WSB_TABLE_EJECTS_COMPONENT,
        ADD_CHAT_TAG_WSB_TRIAL
    ].map(addTagActionType => ({
        conditions: [addTagActionType, WIDGET_LOADED],
        reducer: ({ values: [tag], state }) => ({
            state,
            actionToDispatch: windowChatAddTags([tag])
        })
    }));

export default makeEpic({
    defaultState: { loaded: false },
    valueActionType,
    updaters: [
        {
            conditions: [WIDGET_LOADED],
            reducer: ({ state }) => {
                return {
                    state: { ...state, loaded: true },
                    actionToDispatch: windowChatRemoveTags([ //reset the chat tags preserve by the widget
                        chatTags.WSB3_TABLE_EJECTS_COMPONENTS,
                        chatTags.WSB_TRIAL
                    ])
                };
            }
        },
        ...addChatTagUpdaters,
        {
            conditions: [WIDGET_CANNOT_LOAD],
            reducer: ({ state }) => {
                return { state: { ...state, loaded: false } };
            }
        }
    ]
});
