import type { AppStore } from "../../../redux/modules/flowTypes";
import {
    FACEBOOK_FEED_GALLERY_PP_DISCONNECT_BTN_PRESSED,
    FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_COLOR_SET_AUTO_COLOR,
    FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_COLOR_SET_IF_UNSET, TOGGLE_FB_PAGE_SHOW_HIDE_ACTION
} from "./actionTypes";
import {
    disconnectFacebookFeedAction,
    toggleFacebookFeedPageVisibilityAction
} from "../../SocialAccounts/Facebook/FacebookFeed/actions";
import { getThemeColorsFromStylesheet } from '../../Workspace/epics/stylesheets/selectors';
import stylesheetVAT from '../../Workspace/epics/stylesheets/valueActionType';

export const facebookFeedGalleryMiddleware = (store: AppStore) => (next: Dispatch) => (action: Action) => {
    if (action.type === FACEBOOK_FEED_GALLERY_PP_DISCONNECT_BTN_PRESSED) {
        store.dispatch(disconnectFacebookFeedAction());
    }

    if (action.type === FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_COLOR_SET_AUTO_COLOR) {
        const themeColorsData = getThemeColorsFromStylesheet(store.getState().epics[stylesheetVAT].state);
        if (themeColorsData && action.payload && action.payload.themeColor) {
            const data = { color: [...themeColorsData[action.payload.themeColor]] };
            store.dispatch({ type: FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_COLOR_SET_IF_UNSET,
                payload: data });
        }
    }

    if (action.type === TOGGLE_FB_PAGE_SHOW_HIDE_ACTION) {
        store.dispatch(
            toggleFacebookFeedPageVisibilityAction(action.payload.pageId, action.payload.isPageVisible)
        );
    }

    return next(action);
};
