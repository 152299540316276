/* eslint-disable default-case, eqeqeq, no-bitwise, no-param-reassign, no-sequences, no-unused-expressions, no-unused-vars */
import { findContrastRatio } from "../ThemeGlobalData/utils/commonUtils";
import { toHsl } from "../../../dal/pageMapAdapter/mappers/Base/color";
import { CONTRAST_RATIO_LOWER_LIMIT, CONTRAST_RATIO_UPPER_LIMIT } from "./constants";

// http://npmjs.com/package/hex-rgb
(function () {
    const hexCharacters = "a-f\\d";
    const match3or4Hex = `#?[${hexCharacters}]{3}[${hexCharacters}]?`;
    const match6or8Hex = `#?[${hexCharacters}]{6}([${hexCharacters}]{2})?`;
    const nonHexChars = new RegExp(`[^#${hexCharacters}]`, "gi");
    const validHexSize = new RegExp(`^${match3or4Hex}$|^${match6or8Hex}$`, "i");

    window.hexRgb = (hex, options: Record<string, string> = {}) => {
        if (typeof hex !== "string" || nonHexChars.test(hex) || !validHexSize.test(hex)) {
            throw new TypeError("Expected a valid hex string");
        }

        hex = hex.replace(/^#/, "");
        let alpha = 1;

        if (hex.length === 8) {
            alpha = Number.parseInt(hex.slice(6, 8), 16) / 255;
            hex = hex.slice(0, 6);
        }

        if (hex.length === 4) {
            alpha = Number.parseInt(hex.slice(3, 4).repeat(2), 16) / 255;
            hex = hex.slice(0, 3);
        }

        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }

        const number = Number.parseInt(hex, 16);
        const red = number >> 16;
        const green = (number >> 8) & 255;
        const blue = number & 255;

        if (options.format === "array") {
            return [red, green, blue, alpha];
        }

        if (options.format === "css") {
            const alphaString = alpha === 1 ? "" : ` / ${Number((alpha * 100).toFixed(2))}%`;
            return `rgb(${red} ${green} ${blue}${alphaString})`;
        }

        return {
            red,
            green,
            blue,
            alpha
        };
    };
}());

// https://gist.github.com/mjackson/5311256

/**
 * Converts an RGB color value to HSV. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSV_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and v in the set [0, 1].
 *
 * @param   Number  r       The red color value
 * @param   Number  g       The green color value
 * @param   Number  b       The blue color value
 * @return  Array           The HSV representation
 */
function rgbToHsv(r, g, b) {
    r /= 255;
    g /= 255;
    b /= 255;
    let max = Math.max(r, g, b),
        min = Math.min(r, g, b);
    let h,
        s,
        v = max;
    let d = max - min;
    s = max == 0 ? 0 : d / max;

    if (max == min) {
        h = 0; // achromatic
    } else {
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;

            case g:
                h = (b - r) / d + 2;
                break;

            case b:
                h = (r - g) / d + 4;
                break;
        }

        h /= 6;
    }

    return [h, s, v];
}

// https://gist.github.com/mjackson/5311256

/**
 * Converts an HSV color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSV_color_space.
 * Assumes h, s, and v are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   Number  h       The hue
 * @param   Number  s       The saturation
 * @param   Number  v       The value
 * @return  Array           The RGB representation
 */
function hsvToRgb(h, s, v) {
    let r, g, b;
    let i = Math.floor(h * 6);
    let f = h * 6 - i;
    let p = v * (1 - s);
    let q = v * (1 - f * s);
    let t = v * (1 - (1 - f) * s);

    switch (i % 6) {
        case 0: {
            r = v;
            g = t;
            b = p;
            break;
        }

        case 1: {
            r = q;
            g = v;
            b = p;
            break;
        }

        case 2: {
            r = p;
            g = v;
            b = t;
            break;
        }

        case 3: {
            r = p;
            g = q;
            b = v;
            break;
        }

        case 4: {
            r = t;
            g = p;
            b = v;
            break;
        }

        case 5: {
            r = v;
            g = p;
            b = q;
            break;
        }
    }

    return [r * 255, g * 255, b * 255];
}

function RGBToHex(r, g, b) {
    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);
    if (r.length == 1) r = "0" + r;
    if (g.length == 1) g = "0" + g;
    if (b.length == 1) b = "0" + b;
    return "#" + r + g + b;
}

// https://dev.to/alvaromontoro/building-your-own-color-contrast-checker-4j7o
// function luminance(r, g, b) {
//     let a = [r, g, b].map(function (v) {
//         v /= 255;
//         return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
//     });
//     return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
// }

const updateHEXMap = (newHex, colorHex, newHEXMap) => {
    let newHSVItem = toHsl(newHex);
    let colorHSVItem = toHsl(colorHex);
    let contrastRatio = findContrastRatio(colorHSVItem, newHSVItem);

    if (contrastRatio >= CONTRAST_RATIO_LOWER_LIMIT && contrastRatio <= CONTRAST_RATIO_UPPER_LIMIT) {
        newHEXMap[newHex] = contrastRatio;
    }

    return newHEXMap;
};

const updateListWithLimit = (list: Array<any>, limit) => {
    let updatedList = [];

    if (list.length > limit) {
        // @ts-ignore
        let spacer = parseInt(list.length / limit, 10);

        for (let i = 0; i < limit; i++) {
            let min = i * spacer;
            let max = min + spacer - 1;
            let index = Math.floor(Math.random() * (max - min + 1) + min);  //NOSONAR
            // @ts-ignore
            updatedList.push(list[index]);
        }
    }

    return updatedList;
};

export const generateSimilarButDifferentColors = (colorHex, limit) => {
    let newHEXMap = {};
    let newHSV = {
        h: 0,
        s: 0,
        v: 0
    };
    let h = 0;

    while (h < 1) {
        newHSV.h = h;
        let s = 0.2;
        let sLimit = 0.8;

        while (s <= sLimit) {
            newHSV.s = s;
            let v = 0.4;
            let vLimit = 0.8;

            while (v <= vLimit) {
                newHSV.v = v;
                let newRGB = hsvToRgb(newHSV.h, newHSV.s, newHSV.v);
                // @ts-ignore
                let newHex = RGBToHex(parseInt(newRGB[0], 10), parseInt(newRGB[1], 10), parseInt(newRGB[2], 10));
                updateHEXMap(newHex, colorHex, newHEXMap);
                v += 0.05;
            }

            s += 0.1;
        }

        h += 0.1;
    }

    let newHEXList = Object.keys(newHEXMap);
    let updatedList = updateListWithLimit(newHEXList, limit);
    return updatedList;
};
export const generateContrastAdjustedColor = colorHex => {
    let RGB = window.hexRgb(colorHex);
    let colorHSV = rgbToHsv(RGB.red, RGB.green, RGB.blue);
    colorHSV = {
        // @ts-ignore
        h: colorHSV[0],
        s: colorHSV[1],
        v: colorHSV[2]
    };
    let newHEXMap = {};
    let newHSV = {
        // @ts-ignore
        h: colorHSV.h,
        s: 0,
        v: 0
    };
    let s = 0.2;
    let sLimit = 0.8;

    while (s < sLimit) {
        newHSV.s = s;
        let v = 0.4;
        let vLimit = 0.8;

        while (v < vLimit) {
            newHSV.v = v;
            let newRGB = hsvToRgb(newHSV.h, newHSV.s, newHSV.v);
            // @ts-ignore
            let newHex = RGBToHex(parseInt(newRGB[0], 10), parseInt(newRGB[1], 10), parseInt(newRGB[2], 10));
            updateHEXMap(newHex, colorHex, newHEXMap);
            v += 0.05;
        }

        s += 0.1;
    }

    let newHEXList = Object.keys(newHEXMap);
    let updatedList = updateListWithLimit(newHEXList, 1);
    return updatedList[0];
};
