import React from "react";
import cx from "classnames";
import * as styles from "./Popup.css";
import { customMouseEvents } from "../../../../view/common/NoMouseEventsPropagation";
import type { PopupTopBarProps } from "../../flowTypes";

export default ({
    children,
    containerClassName,
    bodyClassName,
    tipClassName,
    closeButtonClassName,
    includeCloseButton,
    onClose,
    noMouseEvents = true,
}: PopupTopBarProps) => (
    <div className={cx(styles.container, containerClassName)}>
        {includeCloseButton && (
            <div
                className={cx(styles.closeButton, closeButtonClassName)}
                onClick={onClose}
            />
        )}
        <div className={cx(styles.tip, tipClassName)} />
        <div
            className={cx(styles.body, bodyClassName)}
            {...(noMouseEvents ? customMouseEvents() : {})}
        >
            {children}
        </div>
    </div>
);
