const focusHandler = (e: React.FocusEvent<HTMLElement>) => {
    const targetInput = e.target;
    if (targetInput && window.$) {
        const $this = window.$(targetInput)
            .one('mouseup.mouseupSelect', () => {
                $this.select();
                return false;
            })
            .one('mousedown', () => {
                // compensate for untriggered 'mouseup' caused by focus via tab
                $this.off('mouseup.mouseupSelect');
            })
            .select();
    }
};
export default focusHandler;
