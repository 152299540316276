import { ViewTypes } from "../../TemplateSelector_DEPRECATED/constants";
import { PreviewMode } from "../constants";
import { MobileViewOrientations } from "./Mobile/constants";

// TODO: We may want to fix this check for "page-layout-"" as well
const isInternalLink = (href) => /^\/api\/v1\/design\-([^\.\/\s]+)\/preview\-wbtgen\/[A-F0-9\-]+\./i.test(href);// eslint-disable-line
// const isInternalLink = (href) => /^\/api\/v1\/repository\/preview\-wbtgen\/[A-F0-9\-]+\./i.test(href);// eslint-disable-line
const isWebshopScreenshot = (src) => /^\/screenshots\/webshop\/[A-F0-9\-]/i.test(src);// eslint-disable-line

export const setViewType = (url: string, viewType: string, orientation: string): string => {
    let result = url;
    let regex = new RegExp(`${Object.values(ViewTypes).join('|')}|mobile`);
    if (viewType === PreviewMode.MOBILE && orientation) {
        result = result.replace(
            regex,
            orientation === MobileViewOrientations.LANDSCAPE ?
                ViewTypes.mobileHorizontal :
                ViewTypes.mobileVertical
        );
    }
    return result;
};

export default (iframeBody: any, src: string, viewType: string, orientation: string) => {
    if (!src) {
        return;
    }
    const linkElts = window.$(iframeBody).find('[href]').slice();
    let i = 0;
    for (; i < linkElts.length; i++) {
        let elt = linkElts[i];
        const href = elt.getAttribute('href');
        if (isInternalLink(href)) {
            elt.setAttribute('href', href.replace(/.html$/, '.html?templateselectorpreview=' + viewType));
        }
    }
    const webshopScreenshots = window.$(iframeBody).find('[src]').slice();
    for (i = 0; i < webshopScreenshots.length; i++) {
        let elt = webshopScreenshots[i],
            src = elt.getAttribute('src');
        if (isWebshopScreenshot(src)) {
            elt.setAttribute('src', setViewType(src, viewType, orientation));
        }
    }
};
