import React from "react";
import { connect } from "react-redux";
import styles from "./ExpandedPanelBookings.css";
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import { DroppableCmpShortcut, ExtendedPanelShortCut } from "../../../../Panel/view/DroppableCmpShortcut";
import { WidgetsBookingsConfig } from "../../constants";
import { ownerEmailSelector } from '../../../../App/epics/ownerData/selectors';
import { EPSeparator } from "../../../../Panel/view/EPSeparator";
import { Msg } from "../../../../../view/intl/index";
import { GoToBookings } from "./GoToBookingsButton";

const bookingsTemplates = ({
    dispatch,
    ownerEmail,
}) => {
    return (
        <div>
            <React.Fragment>
                <VerticalSpacer y={20} />
                <div>
                    <div className={styles.bookingsDesc}>
                        <Msg k="widgets.bookings.extendedPanel.content">Try for free or manage your bookings.</Msg>
                    </div>
                </div>
                <VerticalSpacer y={19} />
                <EPSeparator />
                <GoToBookings ownerEmail={ownerEmail} dispatch={dispatch} />
                <EPSeparator />
                <VerticalSpacer y={18} />
                <DroppableCmpShortcut
                    kind={WidgetsBookingsConfig.kind}
                    dispatch={dispatch}
                >
                    <ExtendedPanelShortCut iconClassName={WidgetsBookingsConfig.iconClassName} text={WidgetsBookingsConfig.title} />
                </DroppableCmpShortcut>
            </React.Fragment>
        </div>
    );
};

const mapStateToProps = (appState) => ({ ownerEmail: ownerEmailSelector(appState) });
const BookingsTemplateView = connect(mapStateToProps)(bookingsTemplates);

export { BookingsTemplateView };
