import { assocPath, pathOr } from 'ramda';
import React from "react";
import cx from 'classnames';
import styles from './MobileHeader.css';
import type { MobileHeaderProps } from "./types";
import { MobileHeaderLogo } from "./MobileHeaderLogo";
import { MobileHeaderTitle } from "./MobileHeaderTitle";
import { MobileHeaderBurgerMenuIcon } from "./MobileHeaderBurgerMenuIcon";
import { toCss } from "../../../mappers/color";
import { MobileHeaderMenu } from "./menu/MobileHeaderMenu";
import { mobileContentWidth } from "../../MobileViewEditor/constants";
import { doesShopComponentExists } from '../../oneweb/WebShop/utils';
import { doesFeaturedProductsExist } from '../../oneweb/FeaturedProducts/utils';

const cartIcon = <svg className="cart" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3.65" y="8.65" width="16.7" height="12.7" stroke="currentColor" strokeWidth="1.3" />
    <path d="M15 10C15 6.41016 14.6924 3 12 3C9.30761 3 9 6.41016 9 10" stroke="currentColor" strokeWidth="1.3" />
</svg>;

const cartIconWithItems = <svg className="cart-with-items" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3.65" y="8.65" width="16.7" height="12.7" stroke="currentColor" strokeWidth="1.3" />
    <path d="M15 10C15 6.41016 14.6924 3 12 3C9.30761 3 9 6.41016 9 10" stroke="currentColor" strokeWidth="1.3" />
    <circle cx="19" cy="7" r="4.5" fill="currentColor" stroke="white" />
</svg>;

export class MobileHeader extends React.PureComponent<MobileHeaderProps> {
    render() {
        const
            {
                state: {
                    logo: {
                        show: showLogo,
                        asset: logoAsset,
                    },
                    title: titleState
                },
                mobileStyle,
                menuState,
                pageName,
                isSelected,
                isMenuOpen,
                isMVE,
                onClick,
                goHomeOnLogoClick,
                hideMenu,
                viewerDomain,
                site,
                globalVariables,
                siteSettings
            } = this.props,
            containerClassName = cx(styles.container, {
                [styles.withLogo]: showLogo,
                [styles.shadowBottom]: mobileStyle.background.showBottomShadow,
                [styles.borderBottom]: mobileStyle.background.showBottomBorder,
            }),
            homeMenuItem = menuState.homeMenuItem,
            hasShopComponents = doesShopComponentExists(siteSettings, site) || doesFeaturedProductsExist(siteSettings, site);

        let headerStyle: Record<string, any> = {
            backgroundColor: (
                this.props.state.autoColorMode
                    ? toCss(
                        assocPath(
                            [4],
                            pathOr(1, ['background', 'themeColorOpacity'], mobileStyle)
                        )(this.props.state.themeColorsData[mobileStyle.background.themeColor])
                    )
                    : mobileStyle.background.color && toCss(mobileStyle.background.color)
            ),
        };
        if (isMVE) {
            headerStyle = {
                ...headerStyle,
                width: mobileContentWidth - (this.props.scrollBarWidth || 0)
            };
        }

        const mobileMenu = (
            this.props.state.autoColorMode
                ? <MobileHeaderMenu
                    key="MobileHeader_menu"
                    autoColorMode
                    themeColorsData={this.props.state.themeColorsData}
                    menuState={menuState}
                    isMenuOpen={isMenuOpen}
                    isMVE={isMVE}
                    mobileStyle={mobileStyle}
                    site={site}
                    globalVariables={globalVariables}
                />
                : <MobileHeaderMenu
                    key="MobileHeader_menu"
                    autoColorMode={false}
                    menuState={menuState}
                    isMenuOpen={isMenuOpen}
                    isMVE={isMVE}
                    mobileStyle={mobileStyle}
                    site={site}
                    globalVariables={globalVariables}
                />
        );
        const cartColor = this.props.state.autoColorMode
            ? toCss(this.props.state.themeColorsData[mobileStyle.menu.closed.iconThemeColor])
            : (toCss(mobileStyle.menu.closed.iconColor) || undefined);

        return [
            <header
                key="MobileHeader_container"
                id="MobileHeader_container"
                className={containerClassName}
                style={headerStyle}
                onClick={onClick}
            >
                {showLogo && (
                    this.props.state.autoColorMode
                        ? <MobileHeaderLogo
                            autoColorMode
                            themeColorsData={this.props.state.themeColorsData}
                            asset={logoAsset}
                            altText={globalVariables.logoAltText}
                            mobileStyle={mobileStyle}
                            goHomeOnClick={goHomeOnLogoClick}
                            homeMenuItem={homeMenuItem}
                            viewerDomain={viewerDomain}
                        />
                        : <MobileHeaderLogo
                            autoColorMode={false}
                            asset={logoAsset}
                            altText={globalVariables.logoAltText}
                            mobileStyle={mobileStyle}
                            goHomeOnClick={goHomeOnLogoClick}
                            homeMenuItem={homeMenuItem}
                            viewerDomain={viewerDomain}
                        />
                )}
                {titleState.show && (
                    this.props.state.autoColorMode
                        ? <MobileHeaderTitle
                            autoColorMode
                            themeColorsData={this.props.state.themeColorsData}
                            state={titleState}
                            pageName={pageName}
                            mobileStyle={mobileStyle}
                            goHomeOnClick={goHomeOnLogoClick}
                            homeMenuItem={homeMenuItem}
                            site={site}
                            globalVariables={globalVariables}
                        />
                        : <MobileHeaderTitle
                            autoColorMode={false}
                            state={titleState}
                            pageName={pageName}
                            mobileStyle={mobileStyle}
                            goHomeOnClick={goHomeOnLogoClick}
                            homeMenuItem={homeMenuItem}
                            site={site}
                            globalVariables={globalVariables}
                        />
                )}
                {hasShopComponents &&
                    <div
                        className={styles.cartContainer}
                        id="wsb-ws-cart-container"
                        style={{ color: cartColor }}
                    >
                        <div className="wsb-ws-cart">{cartIcon}</div>
                        <div className="wsb-ws-cart-with-items">{cartIconWithItems}</div>
                    </div>}
                {!hideMenu && <div className={styles.dummyMenuIconContainer} />}
                {isSelected && !isMenuOpen && <div className={styles.outline} />}
            </header>,
            !hideMenu && (
                (isMVE && isMenuOpen) ?
                    <div key="MobileHeader_menu_wrapper" className={styles.mveMenuWrapper}>
                        {mobileMenu}
                    </div> :
                    mobileMenu
            ),
            !hideMenu && (
                this.props.state.autoColorMode
                    ? <MobileHeaderBurgerMenuIcon
                        key="MobileHeader_icon"
                        autoColorMode
                        themeColorsData={this.props.state.themeColorsData}
                        isMenuOpen={isMenuOpen}
                        mobileStyle={mobileStyle}
                    />
                    : <MobileHeaderBurgerMenuIcon
                        key="MobileHeader_icon"
                        autoColorMode={false}
                        isMenuOpen={isMenuOpen}
                        mobileStyle={mobileStyle}
                    />
            ),
        ];
    }
}
