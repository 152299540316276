import { assocPath, path } from 'ramda';
import {
    makeCombineReducer,
    makeComponentBaseReducers,
    makeDefaultStateReducers,
    makePayloadToSetterReducers,
    makeNegationPathPayloadToSetterReducers, makePayloadPathReducer
} from "../../../redux/makeReducer/index";
import * as onClickActions from './constants/onClickActions';
import * as CAPTION_ALIGMENT from './constants/captionAligments';
import kind from './kind';
import {
    TOGGLE_MOBILE_DOWN_ACTION
} from "../../PropertiesPanel/view/MobileView/actions";
import mobileDownReducer from '../../PropertiesPanel/view/MobileView/mobileDownReducer';
import * as actionTypes from './actionTypes';
import * as sortingWindowActionTypes from '../../SortImagesDialog/actionTypes';
import * as captionBoxBorderStyleReducers from "./reducerCaptionBoxStyle";
import { reducers as captionTitleTextStyleReducers } from "./reducerCaptionTitleTextStyle";
import { reducers as captionDescriptionTextStyleReducers } from "./reducerCaptionDescriptionTextStyle";
import * as imageBorderReducers from "./reducerImageBorderStyle";
import { CaptionStyles } from "../../presentational/CaptionStyle/constants";
import { IMAGE_RATIO } from "../../presentational/ImageRatioComboBox/constants";
import type { GalleryComponent } from "./flowTypes";
import * as mobileSettings from "./reducerMobileSettings";
import * as mp from "../../../mappers/path";

export default makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(kind),
        ...makeDefaultStateReducers({
            images: [],
            crop: true,
            captionsEnabled: false,
            captionsAlignment: CAPTION_ALIGMENT.CENTER,
            columns: 3,
            spacingPx: 14,
            onClickAction: onClickActions.OPEN_LIGHT_BOX,
            height: 400,
            imageStyle: {},
            captionBoxStyle: {},
            mobileHide: false,
            mobileDown: false,
            previousCaptionStyle: CaptionStyles.TEMPLATE,
            captionStyle: CaptionStyles.TEMPLATE,
            imageRatio: IMAGE_RATIO.LANDSCAPE,
            captionTitleTextStyle: {},
            captionDescriptionTextStyle: {},
            captionMinHeight: 0,
            mobileSettings: {
                columns: 2,
                spacingPx: 10
            },
            stretch: false,
            fullWidthOption: {
                margin: 0,
                maxWidth: 3000,
                originalLeft: 0,
                originalWidth: 0
            },
            compactView: false
        })
    },
    handleActions: {
        [TOGGLE_MOBILE_DOWN_ACTION]: mobileDownReducer,
        [sortingWindowActionTypes.SORT_IMAGES_DIALOG_SAVE_RESULT]: (component, { payload: { images, captionsEnabled } }) => {
            const onClickAction =
                images.some(image => image.action && image.action.link) ? onClickActions.OPEN_LINK : component.onClickAction;
            return { ...component, images, onClickAction, captionsEnabled };
        },
        [actionTypes.GALLERY_PP_CAPTIONS_TITLE_STYLE_FONT_FAMILY_CHANGE]: captionTitleTextStyleReducers.fontFamilyReducer,
        [actionTypes.GALLERY_PP_CAPTIONS_TITLE_STYLE_FONT_COLOR_CHANGE]: captionTitleTextStyleReducers.fontColorReducer,
        [actionTypes.GALLERY_PP_CAPTIONS_TITLE_STYLE_FONT_COLOR_CHANGE_AUTO_COLOR]: (component: GalleryComponent, { payload }) => (
            assocPath(['captionTitleTextStyle', 'themeColor'])(payload.themeColor)(component)
        ),
        [actionTypes.GALLERY_PP_CAPTIONS_TITLE_STYLE_FONT_SIZE_CHANGE]: captionTitleTextStyleReducers.fontSizeReducer,
        [actionTypes.GALLERY_PP_CAPTIONS_TITLE_STYLE_TOGGLE_BOLD]: captionTitleTextStyleReducers.boldReducer,
        [actionTypes.GALLERY_PP_CAPTIONS_TITLE_STYLE_TOGGLE_ITALIC]: captionTitleTextStyleReducers.italicReducer,
        [actionTypes.GALLERY_PP_CAPTIONS_TITLE_STYLE_TOGGLE_UNDERLINE]: captionTitleTextStyleReducers.underlineReducer,
        [actionTypes.GALLERY_PP_CAPTIONS_TITLE_STYLE_LINE_HEIGHT_CHANGE]: captionTitleTextStyleReducers.lineHeightReducer,
        [actionTypes.GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_FONT_FAMILY_CHANGE]: captionDescriptionTextStyleReducers.fontFamilyReducer,
        [actionTypes.GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_FONT_COLOR_CHANGE]: captionDescriptionTextStyleReducers.fontColorReducer,
        [actionTypes.GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_FONT_COLOR_CHANGE_AUTO_COLOR]: (component: GalleryComponent, { payload }) => (
            assocPath(['captionDescriptionTextStyle', 'themeColor'])(payload.themeColor)(component)
        ),
        [actionTypes.GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_FONT_SIZE_CHANGE]: captionDescriptionTextStyleReducers.fontSizeReducer,
        [actionTypes.GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_TOGGLE_BOLD]: captionDescriptionTextStyleReducers.boldReducer,
        [actionTypes.GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_TOGGLE_ITALIC]: captionDescriptionTextStyleReducers.italicReducer,
        [actionTypes.GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_TOGGLE_UNDERLINE]: captionDescriptionTextStyleReducers.underlineReducer,
        [actionTypes.GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_LINE_HEIGHT_CHANGE]: captionDescriptionTextStyleReducers.lineHeightReducer,
        [actionTypes.GALLERY_PP_CAPTION_BOX_BORDER_STYLE]: captionBoxBorderStyleReducers.typeReducer,
        [actionTypes.GALLERY_PP_CAPTION_BOX_BORDER_WIDTH]: captionBoxBorderStyleReducers.widthReducer,
        [actionTypes.GALLERY_PP_CAPTION_BOX_BORDER_OPACITY]: captionBoxBorderStyleReducers.opacityReducer,
        [actionTypes.GALLERY_PP_CAPTION_BOX_BORDER_COLOR]: captionBoxBorderStyleReducers.colorReducer,
        [actionTypes.GALLERY_PP_CAPTION_BOX_BORDER_COLOR_AUTO_COLOR]: (component: GalleryComponent, { payload }) => (
            assocPath(['captionBoxStyle', 'border', 'themeColor'])(payload.themeColor)(component)
        ),
        [actionTypes.GALLERY_PP_CAPTION_BOX_BORDER_CORNER]: captionBoxBorderStyleReducers.cornerReducer,
        [actionTypes.GALLERY_PP_CAPTION_BOX_PADDING_TOP_CHANGE]: captionBoxBorderStyleReducers.paddingTopReducer,
        [actionTypes.GALLERY_PP_CAPTION_BOX_PADDING_BOTTOM_CHANGE]: captionBoxBorderStyleReducers.paddingBottomReducer,
        [actionTypes.GALLERY_PP_CAPTION_BOX_PADDING_LEFT_CHANGE]: captionBoxBorderStyleReducers.paddingLeftReducer,
        [actionTypes.GALLERY_PP_CAPTION_BOX_PADDING_RIGHT_CHANGE]: captionBoxBorderStyleReducers.paddingRightReducer,
        [actionTypes.GALLERY_PP_CAPTION_BOX_BACKGROUND_COLOR_SET]: captionBoxBorderStyleReducers.bgColorReducer,
        [actionTypes.GALLERY_PP_CAPTION_BOX_BACKGROUND_COLOR_SET_AUTO_COLOR]: (component: GalleryComponent, { payload }) => (
            assocPath(['captionBoxStyle', 'background', 'colorData', 'themeColor'])(payload.themeColor)(component)
        ),
        [actionTypes.GALLERY_PP_CAPTION_BOX_BACKGROUND_OPACITY_SET_IF_UNSET]: (component: GalleryComponent, { payload }) => {
            if (!path(mp.captionBoxStyleBackgroundColor)(component)) {
                // New color set and it has opacity 100
                return assocPath(mp.captionBoxStyleBackgroundColor)(payload.color)(component);
            } else {
                const opacity = path(mp.captionBoxStyleBackgroundColorAlpha)(component);
                if (opacity === 0) {
                    return captionBoxBorderStyleReducers.bgColorOpacityReducer(component, { payload: 100 });
                }
                return component;
            }
        },
        [actionTypes.GALLERY_PP_CAPTION_BOX_BACKGROUND_COLOR_UNSET]: captionBoxBorderStyleReducers.bgColorUnsetReducer,
        [actionTypes.GALLERY_PP_CAPTION_BOX_BACKGROUND_OPACITY_CHANGE]: captionBoxBorderStyleReducers.bgColorOpacityReducer,
        [actionTypes.GALLERY_PP_IMAGE_BORDER_TYPE_CHANGE]: imageBorderReducers.typeReducer,
        [actionTypes.GALLERY_PP_IMAGE_BORDER_WIDTH_CHANGE]: imageBorderReducers.widthReducer,
        [actionTypes.GALLERY_PP_IMAGE_BORDER_COLOR_CHANGE]: imageBorderReducers.colorReducer,
        [actionTypes.GALLERY_PP_IMAGE_BORDER_COLOR_CHANGE_AUTO_COLOR]: (component: GalleryComponent, { payload }) => (
            assocPath(['imageStyle', 'border', 'themeColor'])(payload.themeColor)(component)
        ),
        [actionTypes.GALLERY_PP_IMAGE_BORDER_OPACITY_CHANGE]: imageBorderReducers.opacityReducer,
        [actionTypes.GALLERY_PP_IMAGE_BORDER_CORNER_CHANGE]: imageBorderReducers.cornerReducer,
        [actionTypes.GALLERY_MOBILE_COLUMN_CHANGE]: mobileSettings.columnReducer,
        [actionTypes.GALLERY_MOBILE_SPACING_CHANGE]: mobileSettings.spacingReducer,
        [actionTypes.GALLERY_PP_CAPTIONS_STYLE]: (
            component: GalleryComponent,
            { payload: captionStyle }: Action
        ) => ({
            ...component,
            previousCaptionStyle: component.captionStyle,
            captionStyle
        }),
        ...makePayloadToSetterReducers({
            [actionTypes.GALLERY_PP_IMAGE_RATIO]: 'imageRatio',
            [actionTypes.GALLERY_PP_SPACING_CHANGED]: 'spacingPx',
            [actionTypes.GALLERY_PP_COLUMNS_CHANGED]: 'columns',
            [actionTypes.GALLERY_ON_CLICK_ACTION_CHANGED]: 'onClickAction',
            [actionTypes.GALLERY_CAPTIONS_ALIGN_CHANGED]: 'captionsAlignment',
        }),
        ...makeNegationPathPayloadToSetterReducers({
            [actionTypes.GALLERY_IS_CROP_CHECKBOX_CLICKED]: ['crop'],
            [actionTypes.GALLERY_CAPTIONS_ENABLED_CHECKBOX_CLICKED]: ['captionsEnabled'],
            [actionTypes.GALLERY_COMPACT_VIEW]: ['compactView']
            //[actionTypes.GALLERY_FULL_WIDTH]: ['stretch']
        }),
        [actionTypes.GALLERY_PP_FULL_WIDTH_MARGIN]: makePayloadPathReducer(['fullWidthOption', 'margin']),
        [actionTypes.GALLERY_PP_FULL_WIDTH_MAX_WIDTH]: makePayloadPathReducer(['fullWidthOption', 'maxWidth']),
    }
});

