import defaultState from './defaultState';
import { getUpdatedEpicsState } from './updateManager';

import type { EpicsState } from './flowTypes';

export default (state: EpicsState, action: AnyAction) => {
    if (state === undefined) {
        return defaultState;
    }

    if (action.epicsUpdate) {
        return getUpdatedEpicsState(action);
    }

    return state;
};
