import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import stylesheetsValueActionType from "../stylesheets/valueActionType";
import currentOpenedStylesheetArrayIndexValueActionType from "../currentOpenedStylesheetArrayIndex/valueActionType";
import linkGlobalStyleEpicUpdaters from '../../../Globalstyles/Link/epicUpdaters';
import menuGlobalStyleEpicUpdaters from '../../../Globalstyles/Menu/epicUpdaters';
import buttonGlobalStyleEpicUpdaters from "../../../Globalstyles/Button/epicUpdaters";

const defaultState = null;

export default makeEpic({
    valueActionType,
    defaultState,
    updaters: [
        {
            conditions: [
                stylesheetsValueActionType,
                currentOpenedStylesheetArrayIndexValueActionType
            ],
            reducer: ({ values: [stylesheets, index] }) => ({
                state: stylesheets ? stylesheets.styles[index] || null : null
            })
        },
        ...linkGlobalStyleEpicUpdaters,
        ...menuGlobalStyleEpicUpdaters,
        ...buttonGlobalStyleEpicUpdaters
    ]
});
