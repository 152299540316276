/* @flow */

/*::
    type Seo = {
        enableSeo: boolean
    };

    type SiteSettings = {
        seoData: Seo
    };
 */

const isSiteSettingsSeoEnabled = (siteSettings /*: SiteSettings */) /*: boolean */ => {
    if (siteSettings && siteSettings.seoData && !siteSettings.seoData.enableSeo) {
        return false;
    }
    return true;
};

module.exports = {
    isSiteSettingsSeoEnabled
};
