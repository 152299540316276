// @ts-nocheck
import type { BackgroundComponent } from "./flowTypes";
import calcRenderProps from "./calcRenderProps";
import { componentDependencies } from "./previewConfig";
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";
import type { CalcProps } from "../../Preview/flowTypes";
import { isBoxKind } from "../componentKinds";

export const bgMobileCalcProps = ({
    component,
    siteSettings: { themeSettingsData },
    globalStyles,
    renderedWidth
}: CalcProps<BackgroundComponent>) => {
    const props = calcRenderProps({
        component,
        componentDependencies: {
            ...componentDependencies, themeSettingsData, themeColorsData: getThemeColorsFromStylesheet(globalStyles)
        }
    });

    const { width, height, kind } = component;
    let newHeight = height;

    if (isBoxKind(kind) && renderedWidth) {
        newHeight = Math.round((renderedWidth > width ? width : (renderedWidth - 36)) * (height / width) * 100) / 100;
    }

    return {
        ...props,
        height: newHeight
    };
};
