import { useSelector } from 'react-redux';
import { trackingDataSelector, subscriptionTypeSelector } from '../common/selectors';
import { useFeatureRequiresUpgrade } from "../hooks/useFeatureRequiresUpgrade";
import FeatureTypes from "../../../../wbtgen/src/view/oneweb/registry/FeatureTypes";
import {
    isGoogleAnalyticsAllowedForNonBNEUsers,
    isFacebookPixelAllowedForNonBNEUsers,
} from "../../../../wbtgen/src/components/ComponentTierManager/utils";
import type { AnyFeatureKind } from "../../../../wbtgen/src/redux/modules/children/workspace/flowTypes";

const validators = {
    [`${FeatureTypes.GOOGLE_ANALYTICS_TRACKING}`]: isGoogleAnalyticsAllowedForNonBNEUsers,
    [`${FeatureTypes.FACEBOOK_PIXEL_TRACKING}`]: isFacebookPixelAllowedForNonBNEUsers,
};

export const useFeatureRequiresUpgradeForTracking = (feature: AnyFeatureKind): boolean => {
    const subscriptionType = useSelector(subscriptionTypeSelector);
    const tracking = useSelector(trackingDataSelector);

    return (
        useFeatureRequiresUpgrade(feature)
        && !validators[feature](subscriptionType, tracking)
    );
};
