import * as React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import styles from './FileChooserHeader.css';
import type { BreadcrumbsProps } from '../flowTypes';
import { getFcTitleAndLabel } from '../utils';
import { BREAD_CRUMBS_SHORT_NAME_LENGTH } from '../constants';
import { currentPathSelector } from '../../../../redux/modules/children/fileChooser/selectors';
import { FC_NAVIGATE_DIRECTORY } from '../../../../redux/modules/children/fileChooser/actionTypes';
import { injectIntl } from '../../../intl/index';
import type { AppState } from '../../../../redux/modules/flowTypes';
import type { ReactElementRef } from '../../../../globalTypes';
import { buildBreadcrumbs, BreadcrumbType, BreadcrumbRootDir } from './buildBreadcrumbs';
import type { Breadcrumbs } from './buildBreadcrumbs';

type State = {
    container: null | undefined | HTMLElement,
    breadcrumbs: Breadcrumbs,
};

const Separator = () => (
    <span className={cx(styles.breadcrumbsElement, styles.breadcrumbsSep)} />
);

class BreadcrumbsClass extends React.Component<BreadcrumbsProps, State> {
    static getDerivedStateFromProps(nextProps: BreadcrumbsProps, prevState: State) {
        const
            { currentPath } = nextProps,
            { container } = prevState;

        return { container: null, breadcrumbs: buildBreadcrumbs(currentPath, container) };
    }

    state: State;
    containerRef: ReactElementRef<any>;

    constructor() {
        // @ts-ignore
        super();
        this.state = {
            container: null,
            breadcrumbs: [],
        };
        this.containerRef = React.createRef();
    }

    componentDidMount() {
        this.setState({ container: this.containerRef.current });
    }

    componentDidUpdate(prevProps: BreadcrumbsProps) {
        if (this.props !== prevProps) {
            this.setState({ container: this.containerRef.current }); // eslint-disable-line react/no-did-update-set-state
        }
    }

    render() {
        const
            { navigateDirectory, isDemo, intl } = this.props,
            { breadcrumbs } = this.state;

        return (
            <div className={styles.breadcrumbs} ref={this.containerRef}>{
                breadcrumbs.map((bc, i) => {
                    if (bc.type === (BreadcrumbType.DIR as 'dir')) {
                        const
                            name = i === 0 ? intl.msgJoint(bc.name) : bc.name,
                            { label, title } = getFcTitleAndLabel(name, BREAD_CRUMBS_SHORT_NAME_LENGTH),
                            navigate: React.MouseEventHandler<HTMLSpanElement> = () => navigateDirectory(bc.level),
                            navigationEnabled = !isDemo || bc.name !== BreadcrumbRootDir.name,
                            className = cx(
                                styles.breadcrumbsElement,
                                styles.breadcrumbsDir,
                                { [styles.navigable]: navigationEnabled },
                            ),
                            onClick = (navigationEnabled && navigate) || undefined;

                        return (
                            <span
                                className={className}
                                key={i}
                                onClick={onClick}
                                data-title={title}
                            >
                                {label}
                            </span>
                        );
                    } else if (bc.type === BreadcrumbType.SEP) {
                        return (<Separator key={i} />);
                    } else if (bc.type === BreadcrumbType.ELL) {
                        return (
                            <React.Fragment key={i}>
                                <Separator />
                                <span className={styles.breadcrumbsEllipsis} />
                            </React.Fragment>
                        );
                    }

                    return null;
                })
            }</div>
        );
    }
}

const mapStateToProps = ({ fileChooser }: AppState) => ({
    currentPath: currentPathSelector(fileChooser)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    navigateDirectory: directoryLevel => dispatch({ type: FC_NAVIGATE_DIRECTORY, payload: { directoryLevel } })
});

export const BreadcrumbsCom = connect(mapStateToProps, mapDispatchToProps)(injectIntl(BreadcrumbsClass));
