import { IDLE } from "../../Workspace/epics/componentsEval/userInteractionMutations/interactionModes";

export default {
    state: { show: false, controlsDependenciesForSelectedComponent: null, dimensions: { height: 0, width: 0 } },
    scope: {
        selectedComponentId: null,
        actualPosition: { x: 0, y: 0 },
        isMoving: false,
        startMovePosition: { x: 0, y: 0 },
        startMousePosition: { x: 0, y: 0 },
        mode: IDLE,
        maxHeight: 0,
        enableMobileDown: false
    }
};
