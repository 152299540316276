import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { Dialog, DialogTitleBox, DialogBody } from '../../../../../../view/common/dialogs/baseDialog/index';

import { EditingAssistant } from './EditingAssistant';

import { getBottomOrTopRightDialogPosition } from "../../../../../DialogManager/utility";
import { StripTypes } from "../../../../../../view/common/dialogs/baseDialog/Dialog";

import { closeDialog } from "../../../../../App/actionCreators";

import { Onboard } from './Onboard';
import { preferencesSelector } from '../../../../../Preferences/selectors';

import styles from './styles.css';
import { AI_TEXT_EDIT_INTRO_CLOSED } from '../../../epics/EditAITextEpic/actionTypes';
import { editAiTextSelector } from '../../../epics/EditAITextEpic/selectors';

const
    width = 824,
    height = 552,
    EditAITextDialog = () => {
        const dispatch = useDispatch();
        const hideAIEditTextIntro = useSelector(preferencesSelector).hideAIEditTextIntro;
        const aiTextState = useSelector(editAiTextSelector);
        const { showTermsCheckbox, agreeTerms } = aiTextState;
        const [showOnboarding, setShowOnboarding] = useState(!hideAIEditTextIntro || showTermsCheckbox);
        const onClose = () => {
            if (!hideAIEditTextIntro) return dispatch({ type: AI_TEXT_EDIT_INTRO_CLOSED });
            return dispatch(closeDialog());
        };
        const closeOnboarding = () => {
            if (agreeTerms && showOnboarding) {
                setShowOnboarding(false);
            }
        };
        return (
            <Dialog
                onClose={onClose}
                stripType={StripTypes.NONE}
                className={styles.dialog}
                containerClassName={styles.dialogContainer}
                closeBtnClassName={styles.closeButton}
            >
                <DialogTitleBox
                    title="msg: component.text.createAIText.dialog.title {Writing Assistant}"
                    className={showOnboarding ? styles.onboardDialogTitleBox : styles.editDialogTitleBox}
                    titleClass={styles.dialogTitle}
                    subTitle="msg: component.text.createAIText.dialog.subTitle {(Powered by OpenAI)}"
                    subTitleClass={styles.dialogSubTitle}
                    titleContainerClass={styles.dialogTitleContainer}
                />
                <DialogBody className={showOnboarding ? styles.onboardingDialogBody : styles.editDialogBody}>
                    {
                        showOnboarding ? <Onboard closeAction={closeOnboarding} /> : <EditingAssistant />
                    }
                </DialogBody>
            </Dialog>
        );
    },
    config = {
        confFactory: (browserDimensions, payload) => {
            return {
                position: getBottomOrTopRightDialogPosition(
                    browserDimensions,
                    width,
                    height,
                    payload
                ),
                dimensions: { width, height },
                modal: true
            };
        },
        component: EditAITextDialog
    };

const EDIT_AI_TEXT_DIALOG_ID = "EDIT_AI_TEXT_DIALOG_ID";

export {
    EDIT_AI_TEXT_DIALOG_ID,
    config,
    EditAITextDialog
};

