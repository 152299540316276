import React from "react";
import registry from '../../../../../view/oneweb/registry/index';
import TextInGroup from './TextInGroup';
import Image from "../../../../oneweb/Image/kind";
import { getImgAndTextGroupDefaultSettings } from "../../../constants";

export default ({ groupProps }: Record<string, any>) => {
    return groupProps.map((cmpProps, i) => {
        const { id, kind } = cmpProps.component,
            props = {
                ...cmpProps,
                textId: id,
                settings: { ...getImgAndTextGroupDefaultSettings(), ...(cmpProps.settings[cmpProps.groupId] || {}) }
            },

            View = (kind === Image) ? registry[Image].mobileEditorConfig?.view : TextInGroup;
        return <View key={i} {...props} />;
    });
};
