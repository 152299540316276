import { TEMPLATE_FILTER } from '../../constants';
import { isOnePageDesign, onePageRegex } from '../../../Onboarding/domainHashUtils';
import GMB_CATEGORIES_TO_DESIGNS_MAP from "../../../../../../resources/repository/gmb-categories/gmb-categories-to-designs.json";

export const filterTemplates = (state) => {
    const onePageDesignMap = state.designsData.designs
        .filter(({ accountName }) => isOnePageDesign(accountName))
        .reduce((acc, design) => {
            const designName = design.accountName.replace(onePageRegex, '');
            acc[designName] = design;
            return acc;
        }, {});
    // @ts-ignore
    const filterOutOnePageDesigns = state.filter !== TEMPLATE_FILTER.one_page;

    return ({
        ...state,
        onePageDesignMap,
        searchText: '',
        filteredDesigns: state.designsData.designs.filter((design) => {
            if (filterOutOnePageDesigns && isOnePageDesign(design.accountName)) {
                return false;
            }
            return (state.filter === TEMPLATE_FILTER.all || design.categories.indexOf(state.filter) >= 0);
        })
    });
};

const checkSubCategoriesMatchWithKeyword = (keyword, subCategories) => {
    return subCategories.some(el => el.match(keyword));
};

const checkCategoriesMatchWithKeyword = (keyword, categories) => {
    return categories.some(el => el.match(keyword));
};

const checkDesignMatchWithKeyword = (keyword, design) => {
    return (
        checkCategoriesMatchWithKeyword(keyword, design.categories) ||
        (design.subCategories && checkSubCategoriesMatchWithKeyword(keyword, design.subCategories))
    );
};

const filterGmbMatchedDesigns = (keyword) => {
    return Object.keys(GMB_CATEGORIES_TO_DESIGNS_MAP).reduce((s, gmbKey) => {
        if (gmbKey.match(keyword)) {
            return new Set([...s, ...GMB_CATEGORIES_TO_DESIGNS_MAP[gmbKey]]);
        }
        return s;
    }, new Set());
};

export const filterTemplatesByKeyword = ({ keyword, ...state }) => {
    const keywordReg = new RegExp(keyword, 'i');

    const gmbMatchedDesignsSet = filterGmbMatchedDesigns(keywordReg);
    const nameMatchedSet = new Set();

    const filteredDesigns = state.designsData.designs.filter((design) => {
        const {
            accountName,
            name
        } = design;

        let isDesignMatchWithKeyword;

        if (name.match(keywordReg)) {
            isDesignMatchWithKeyword = true;
            nameMatchedSet.add(design.accountName);
        }

        isDesignMatchWithKeyword = (
            isDesignMatchWithKeyword ||
            checkDesignMatchWithKeyword(keywordReg, design) ||
            gmbMatchedDesignsSet.has(accountName)
        );

        if (isDesignMatchWithKeyword) {
            if (keyword.toLowerCase() === 'one_page') {
                return isOnePageDesign(accountName);
            }
            return !isOnePageDesign(accountName);
        }

        return false;
    });

    filteredDesigns.sort((a, b) => {
        if (nameMatchedSet.has(a.accountName) && nameMatchedSet.has(b.accountName)) {
            return (a.name.toLowerCase().indexOf(keyword.toLowerCase()) - b.name.toLowerCase().indexOf(keyword.toLowerCase()));
        } else if (nameMatchedSet.has(a.accountName)) {
            return -1;
        } else {
            return 1;
        }
    });

    return filteredDesigns;
};
