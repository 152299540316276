import React from "react";

import pagesTitles from "./titles";
import * as pagesIds from "./ids";

import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import NavigationGroup from '../../../../PropertiesPanel/view/IntlNavigationGroup';

const
    id = pagesIds.MAIN,
    title = pagesTitles[pagesIds.MAIN],
    view = ({ navigateToPage }: any) => (
        <Page>
            <NavigationGroup
                navigateToPage={navigateToPage}
                pagesTitles={pagesTitles}
                targetPagesIds={[pagesIds.TEXT, pagesIds.REVIEW_BUTTON]}
            />
        </Page>
    );

export { id, view, title };
