/* eslint-disable max-len */
import React from "react";
import cx from "classnames";
import { Msg } from "../../../../view/intl/index";
import { OnboardingTopBar } from "../../../TopBar/view/OnboardingTopBar";
import styles from "./TrialWelcome.css";
import { PrimaryButton, ContinueButton } from "../../../../view/common/Button/index";
import VerticalSpacer from "../../../../view/common/VerticalSpacer";
import { DYNAMIC_TEMPLATE_START_ONBOARDING } from "../Epic/actionTypes";
import { TEMPLATE_SELECTOR_SHOW_PREVIEW } from "../../../TemplateSelector_DEPRECATED/actionTypes";

const TrialOnboardingView = (props) => {
    const { selectedTemplate, dispatch } = props;
    const assetName = (selectedTemplate.asset.name || "").split(".")[0];

    return (
        <div className={styles.topContainer}>
            <div className={cx(styles.topBar)}>
                <OnboardingTopBar {...({ enableLogoLink: true, showHelp: false })} />
            </div>
            <div className={cx(styles.bodyContainer)}>
                <div className={cx(styles.welcomeText)}>
                    <Msg k="onboarding.welcomeTitle">Welcome to Website Builder!</Msg>
                </div>
                <div className={cx(styles.welcomeSubTitle)}>
                    <Msg k="onboarding.welcomeSubTitle">How do you want to start?</Msg>
                </div>
                <VerticalSpacer y={40} />
                <div className={styles.optionsContainer}>
                    <div className={cx(styles.options)} data-testid="trial-selected-template">
                        <div className={styles.image}>
                            <img
                                src={assetName ?
                                    `/templateScreenshots/${assetName}-760x490.jpg` :
                                    `/screenshots/760x490/${selectedTemplate.uuidOfHomePage}.jpg?auto=false`}
                                className={styles.templateImage}
                                data-testid="trial-selected-template-screenshot"
                            />
                        </div>
                        <div className={styles.option}>
                            <Msg className={styles.optionTitle} k="onboarding.selectedTemplate.title">
                                Continue with the selected template</Msg>
                            <Msg className={styles.optionSubTitle} k="onboarding.selectedTemplate.subTitle">
                                Use this pre-designed template to build your website. Easily edit the template and write your own texts - it’s all up to you.
                            </Msg>
                            <VerticalSpacer y={8} />
                            <ContinueButton
                                label="msg: common.startEditing {Start editing}"
                                className={cx(styles.button, styles.secondaryButton)}
                                onClick={() => {
                                    dispatch({
                                        type: TEMPLATE_SELECTOR_SHOW_PREVIEW,
                                        payload: {
                                            template: selectedTemplate,
                                            isSelectedFromTrial: true,
                                            isNormalType: false
                                        }
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className={cx(styles.options)} data-testid="trial-dynamic-onboarding">
                        <div className={cx(styles.image, styles.aiImage)} />
                        <div className={styles.option}>
                            <Msg className={styles.optionTitle} k="onboarding.dynamic.title">
                                Let us generate a website for you</Msg>
                            <Msg className={styles.optionSubTitle} k="onboarding.dynamic.subTitle">
                                Answer a few questions and get a website with AI-generated texts, tailored to your needs. You can edit everything later.
                            </Msg>
                            <VerticalSpacer y={8} />
                            <PrimaryButton
                                className={cx(styles.button, styles.primaryButton)}
                                onClick={() => {
                                    dispatch({ type: DYNAMIC_TEMPLATE_START_ONBOARDING });
                                }}
                            >
                                <Msg className={styles.buttonText} k="common.getStarted">Get started</Msg>
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {
    TrialOnboardingView
};
