import React from 'react';
import View from './index';
import kind from '../kind';
import { getTestingWithScreenshotPreview } from '../../constants';

import type { FacebookPageCalcRenderPropsResult } from "../flowTypes";

export default (props: FacebookPageCalcRenderPropsResult) => {
    if (props.testingWithScreenshots) {
        return getTestingWithScreenshotPreview(kind);
    }

    return (
        <div
            data-original-width={props.width}
            className="fb-page-container"
            style={{ width: props.width, height: props.height, margin: 'auto', maxWidth: '100%' }}
        >
            <View {...props} />
        </div>
    );
};
