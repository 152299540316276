import type { LayoutsDataMap } from "../flowTypes";
import { H_ALIGN, V_ALIGN } from "../constants";
import { getMLayoutMessages } from "./mLayoutMessages";
//Make sure the layouts width will come up to 1040 px including cmps width and gap between cmps/cols
//excluding the gaps at the edges

let _headerLayoutMap: LayoutsDataMap | null = null;
const defaultHeaderId = 'Header-Layout-1';
const getHeaderLayoutsMap = (): LayoutsDataMap => {
    if (_headerLayoutMap) {
        return _headerLayoutMap;
    }
    const mLayoutMessages = getMLayoutMessages();
    _headerLayoutMap = {
        'Header-Layout-1': {
            id: 'Header-Layout-1',
            title: 'msg: modernLayout.layoutTitle.getInTouch {Get in touch}',
            version: 2,
            layout: {
                root: {
                    rows: [{
                        bindingId: 'Row-1',
                        cols: [
                            {

                                bindingId: 'Col-1',
                                hAlign: H_ALIGN.left,
                                vAlign: V_ALIGN.middle,
                                hPositioned: true,
                                cmps: [
                                    { id: 'cmp-logo1', bindingId: 'cmp-logo1' }
                                ],
                            }, {

                                bindingId: 'Col-2',
                                hAlign: H_ALIGN.center,
                                vAlign: V_ALIGN.middle,
                                hPositioned: true,
                                cmps: [
                                    { id: 'cmp-menu1', bindingId: 'cmp-menu1' }
                                ],
                            },
                            {
                                bindingId: 'Col-3',
                                hAlign: H_ALIGN.right,
                                vAlign: V_ALIGN.middle,
                                hPositioned: true,
                                cmps: [
                                    { id: 'cmp-sociallinks1', bindingId: 'cmp-sociallinks1' }
                                ],
                            }
                        ],
                    }],
                    vResponsive: true,
                }
            },
            componentsMap: {
                'cmp-logo1': {
                    "id": "cmp-logo1",
                    "kind": "LOGO",
                    "left": 0,
                    "top": 36,
                    "width": 80,
                    "height": 80,
                    "scaleStrategy": "logo",
                    "modernLayoutOptions": {
                        "size": "medium"
                    },
                },
                'cmp-menu1': {
                    "id": "cmp-menu1",
                    "kind": "MENU",
                    "width": 477,
                    "height": 38,
                    "layoutType": "HORIZONTAL_DROPDOWN",
                    "horizontalAlign": "center",
                    "verticalAlign": "middle",
                    "moreButtonEnabled": true,
                    "moreText": mLayoutMessages.MENU_MORE
                },
                'cmp-sociallinks1': {
                    "id": "cmp-sociallinks1",
                    "kind": "SOCIAL_LINKS",
                    "width": 136,
                    "height": 34,
                    "colorType": "DARK",
                    "size": 24,
                    "spacing": 28,
                    "mobileSize": 40,
                    "mobileHorizontalAlignment": "center",
                    "styleType": "REGULAR",
                    "modernLayoutOptions": {
                        "size": "medium",
                        "mobileSize": "medium"
                    },
                }
            },
            options: [
                {
                    id: 'cmp-logo1',
                    bindingId: "cmp-logo1",
                    title: mLayoutMessages.Logo,
                    show: true
                },
                {
                    id: 'cmp-menu1',
                    bindingId: "cmp-menu1",
                    title: mLayoutMessages.Menu,
                    show: true
                },
                {
                    bindingId: "cmp-sociallinks1",
                    id: "cmp-sociallinks1",
                    title: mLayoutMessages.SocialIcons,
                    show: true
                }
            ]
        },
        'Header-Layout-2': {
            id: 'Header-Layout-2',
            title: 'msg: modernLayout.layoutTitle.encourageAction {Encourage action}',
            version: 1,
            layout: {
                root: {
                    rows: [{
                        bindingId: 'Row-1',
                        cols: [
                            {
                                bindingId: 'Col-1',
                                hAlign: H_ALIGN.left,
                                vAlign: V_ALIGN.middle,
                                hPositioned: false,
                                cmps: [
                                    { bindingId: 'cmp-menu1', id: 'cmp-menu1' }
                                ],
                            }, {
                                bindingId: 'Col-2',
                                hAlign: H_ALIGN.center,
                                vAlign: V_ALIGN.middle,
                                hPositioned: false,
                                cmps: [
                                    { bindingId: 'cmp-logo1', id: 'cmp-logo1' }
                                ],
                            },
                            {
                                bindingId: 'Col-3',
                                hAlign: H_ALIGN.right,
                                vAlign: V_ALIGN.middle,
                                hPositioned: true,
                                cmps: [
                                    { bindingId: 'cmp-SecondaryButton', id: 'cmp-SecondaryButton' },
                                    { bindingId: 'cmp-PrimaryButton', id: 'cmp-PrimaryButton', style: { margin: { left: 20 } } }
                                ],
                            }
                        ],
                    }],
                    vResponsive: true
                }
            },
            componentsMap: {
                "cmp-menu1": {
                    "id": "cmp-menu1",
                    "kind": "MENU",
                    "top": 50,
                    "left": 0,
                    "width": 300,
                    "height": 52,
                    "layoutType": "HORIZONTAL_DROPDOWN",
                    "horizontalAlign": "fit",
                    "verticalAlign": "top",
                    "moreButtonEnabled": true
                },
                "cmp-logo1": {
                    "id": "cmp-logo1",
                    "kind": "LOGO",
                    "top": 56,
                    "left": 485,
                    "width": 80,
                    "height": 80,
                    "scaleStrategy": "logo",
                    "modernLayoutOptions": {
                        "size": "medium"
                    },
                },
                "cmp-SecondaryButton": {
                    "id": "cmp-SecondaryButton",
                    "kind": "BUTTON",
                    "top": 51,
                    "left": 730,
                    "width": 150,
                    "height": 50,
                    "text": "Menu",
                    "buttonThemeSelected": "secondary",
                    "modernLayoutOptions": {
                        "size": "medium"
                    },
                    "style": {
                        "text": {
                            "size": 16
                        }
                    }
                },
                "cmp-PrimaryButton": {
                    "id": "cmp-PrimaryButton",
                    "kind": "BUTTON",
                    "top": 51,
                    "left": 900,
                    "width": 150,
                    "height": 50,
                    "text": "Booking",
                    "buttonThemeSelected": "primary",
                    "modernLayoutOptions": {
                        "size": "medium"
                    },
                    "style": {
                        "text": {
                            "size": 16
                        }
                    }
                }
            },
            options: [
                {
                    bindingId: "cmp-menu1",
                    id: "cmp-menu1",
                    title: mLayoutMessages.Menu,
                    show: true
                },
                {
                    bindingId: "cmp-logo1",
                    id: "cmp-logo1",
                    title: mLayoutMessages.Logo,
                    show: true
                },
                {
                    bindingId: "Buttons",
                    id: "Buttons",
                    title: mLayoutMessages.Buttons,
                    show: true,
                    children: [
                        {
                            bindingId: "cmp-SecondaryButton",
                            id: "cmp-SecondaryButton",
                            title: mLayoutMessages.Menu,
                            show: true
                        },
                        {
                            bindingId: "cmp-PrimaryButton",
                            id: "cmp-PrimaryButton",
                            title: mLayoutMessages.Booking,
                            show: true
                        },
                    ]
                }
            ]
        },
    };
    return _headerLayoutMap;
};

export {
    getHeaderLayoutsMap,
    defaultHeaderId
};
