export const
    YOUTUBE_VIDEOS_SEARCH = 'YOUTUBE_VIDEOS_SEARCH',
    YOUTUBE_VIDEOS_FETCH_REQUEST = 'YOUTUBE_VIDEOS_FETCH_REQUEST',
    YOUTUBE_VIDEOS_FETCH_SUCCESS = 'YOUTUBE_VIDEOS_FETCH_SUCCESS',
    YOUTUBE_VIDEOS_FETCH_FAILURE = 'YOUTUBE_VIDEOS_FETCH_FAILURE',
    YOUTUBE_REPLACE_VIDEO = 'YOUTUBE_REPLACE_VIDEO',
    YOUTUBE_REPLACE_VIDEO_UPDATE = 'YOUTUBE_REPLACE_VIDEO_UPDATE',
    VIDEO_PROP_PANEL_SHOW_INFO = 'VIDEO_PROP_PANEL_SHOW_INFO',
    TRANSCODE_STATUS_UPDATER = "TRANSCODE_STATUS_UPDATER",
    CALL_CHECK_TRANSCODE_STATUS_REQUEST = 'CALL_CHECK_TRANSCODE_STATUS_REQUEST',
    CHECK_TRANSCODE_STATUS_REQUEST = 'CHECK_TRANSCODE_STATUS_REQUEST',
    CHECK_TRANSCODE_STATUS_SUCCESS = 'CHECK_TRANSCODE_STATUS_SUCCESS',
    CHECK_TRANSCODE_STATUS_FAILURE = 'CHECK_TRANSCODE_STATUS_FAILURE',
    VIDEO_PROP_PANEL_AUTO_PLAY_MUTED = 'VIDEO_PROP_PANEL_AUTO_PLAY_MUTED',
    VIDEO_PROP_PANEL_SHOW_CONTROLS = 'VIDEO_PROP_PANEL_SHOW_CONTROLS',
    VIDEO_PROP_PANEL_PLAY_AUTOMATICALLY = 'VIDEO_PROP_PANEL_PLAY_AUTOMATICALLY',
    VIDEO_PROP_PANEL_PLAY_AGAIN = 'VIDEO_PROP_PANEL_PLAY_AGAIN',
    VIDEO_PROP_PANEL_PLAY_MUTED = 'VIDEO_PROP_PANEL_PLAY_MUTED',
    VIDEO_PROP_PANEL_AUTO_RESIZE = 'VIDEO_PROP_PANEL_AUTO_RESIZE',
    YOUTUBE_VIDEO_PREVIEW = 'YOUTUBE_VIDEO_PREVIEW',
    PASTE_YOUTUBE_VIDEO_LINK = 'PASTE_YOUTUBE_VIDEO_LINK',
    VIDEO_SET = 'VIDEO_SET',
    VIDEO_SELECTED = 'VIDEO_SELECTED',
    VIDEO_REPLACE_VIDEO_FILE = 'VIDEO_REPLACE_VIDEO_FILE',
    VIDEO_FILE_UPDATE = 'VIDEO_FILE_UPDATE',
    VIDEO_UPLOAD_STARTED = 'VIDEO_UPLOAD_STARTED',
    VIDEO_UPLOAD_FINISHED = 'VIDEO_UPLOAD_FINISHED',
    VIDEO_UPLOAD_FAILED = 'VIDEO_UPLOAD_FAILED',
    FETCH_TRANSCODE_STATUS_UNTIL_VIDEO_UPLOADS = 'FETCH_TRANSCODE_STATUS_UNTIL_VIDEO_UPLOADS';
