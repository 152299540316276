// @ts-nocheck
// Should be used only when relIn values and relPara.offset are the source of truth
export default (alignment, components) => {
    const wrapperWidth = components[0].relIn.left + components[0].width - components[0].relIn.right;

    if (alignment === 'left') {
        const width = components.reduce((width, component) => {
            return Math.max(component.relIn.left + component.width, width);
        }, 0);

        return { width, wrapperWidth };
    }

    if (alignment === 'right') {
        const width = components.reduce((width, component) => {
            return Math.max(component.width - component.relIn.right, width);
        }, 0);

        return { width, wrapperWidth };
    }

    const
        centerArea = components.reduce(({ left, rightP, rightN }, component) => {
            return {
                rightP: Math.max(rightP, component.relIn.left + component.width),
                rightN: Math.max(rightN, component.relIn.right),
                left: Math.min(left, component.relIn.left)
            };
        }, { left: Infinity, rightP: 0, rightN: -Infinity }),

        minGapForMaxWidth = Math.min(centerArea.left, 0 - centerArea.rightN);

    return {
        left: minGapForMaxWidth,
        right: 0 - minGapForMaxWidth,
        width: wrapperWidth - (2 * minGapForMaxWidth),
        wrapperWidth
    };
};
