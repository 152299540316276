import React from "react";
import * as R from "ramda";
import MctaVerticalBar from '../../../../componentMainActions/MctaVerticalBar/index';
import { MctaSlider } from '../../../../componentMainActions/MctaSlider/MctaSlider';
import HorizontalSpacer from "../../../../../view/common/HorizontalSpacer";
import { MOBILE_EDITOR_GROUP_IMAGE_SCALE_CHANGE } from "../../../actionTypes";
import Alignment from './Alignment';
import type { MobileGroupMCTAProps } from "../../../flowTypes";
import { ALIGNMENT_TYPES } from "../../../../../constants";

class MobileViewEditorV extends React.PureComponent<MobileGroupMCTAProps> {
    render() {
        const { dispatch, id, settings } = this.props,
            maxScale = 100,
            spacingPx = Math.min(R.pathOr(100, [id, 'scale'], settings), maxScale),
            props = {
                ...this.props,
                defaultAlign: ALIGNMENT_TYPES.ALIGN_CENTER
            };
        return <div>
            <Alignment
                mobileMCTA={props.mobileMCTA}
                dispatch={props.dispatch}
                id={props.id}
                selectedComponent={props.selectedComponent}
                settings={props.settings}
                mobileEltExtension={props.mobileEltExtension}
                defaultAlign={props.defaultAlign}
            />
            <HorizontalSpacer x={5} />
            <MctaVerticalBar />
            <HorizontalSpacer x={10} />
            <MctaSlider
                label="msg: component.group.mve.mcta.scale {Scale}"
                min={50}
                max={maxScale}
                value={spacingPx}
                valueLabel="%"
                onChange={spacing => dispatch({
                    type: MOBILE_EDITOR_GROUP_IMAGE_SCALE_CHANGE,
                    payload: {
                        id,
                        value: spacing
                    },
                    amendToSelf: true
                })}
                forMve
            />
            <HorizontalSpacer x={5} />
        </div>;
    }
}

export default (props: MobileGroupMCTAProps) => <MobileViewEditorV {...props} />;
