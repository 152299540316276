import urlBuild from "../../../../../utils/urlBuild";
import { encodeWebspaceUri } from "../../../../../dal/utils/webspaceUriTransformers";

type Params = {
    url: string,
    lang?: string,
    isEmbedded?: boolean,
};

export const googleDocViewerUrl = ({ url, lang, isEmbedded = true }: Params) => urlBuild('viewer', {
    scheme: 'https',
    host: 'docs.google.com',
    query: {
        url: encodeWebspaceUri(url),
        hl: lang,
        embedded: isEmbedded ? 'true' : 'false'
    }
});
