import React from "react";
import { injectIntl, Intl } from '../../../src/view/intl/index';
import Msg from "../../../src/view/intl/Msg";
import { getAppConfig } from "../../../src/components/App/epics/appConfig/appConfig";
import { PartnerName } from '../../../../server/shared/partners/PartnerName';

type Props = {
    intl: Intl,
    className: '',
    isTermsOfUse?: boolean
};

const
    TermsnConditionsUrlLink = injectIntl((props: Props) => {
        const appConfig = getAppConfig(),
            trialConfig = appConfig.oneWeb.trial;

        const { intl, className, isTermsOfUse } = props;
        return (
            <a
                href={trialConfig.partnerName === PartnerName.ONECOM ? intl.msgJoint(trialConfig.termsUrl) : trialConfig.termsUrl}
                target="_blank"
                className={className}
            >{isTermsOfUse ? <Msg k="demo.signup.termsAndCondition.termsOfUse">Terms of use</Msg> : <Msg k="common.termsDot">
                Terms</Msg>}</a>
        );
    });

export default TermsnConditionsUrlLink;
