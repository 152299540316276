import React from "react";

import makeStyles from '@mui/styles/makeStyles';
import { Button, DialogContent, Hidden } from "@mui/material";
import type { Theme } from '@mui/material/styles';

import VerticalSpacer from "../../../../../../../wbtgen/src/view/common/VerticalSpacer";

import imageStyles from "../../../Images/imageStyle.css";

import { BasicDialog } from "../../../Dialog/BasicDialog";
import { closeDialogAction } from "../../../Dialog/actions";
import { seenChatWidgetSetDialog } from "../actions";

const styles = makeStyles((theme: Theme) => ({
    paper: {
        width: 576,
        [theme.breakpoints.down('md')]: {
            position: "absolute",
            bottom: 0,
            width: "100%",
            maxWidth: "100%",
            margin: 0,
            padding: 0,
        },
    },
    dialogTitle: {
        fontSize: "30px",
        lineHeight: "34px",
        textAlign: "center",
        [theme.breakpoints.down('md')]: {
            textAlign: "left",
        }
    },
    imageWrapper: {
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down('md')]: {
            justifyContent: "flex-start",
        }
    },
    dialogSubTitle: {
        fontSize: "18px",
        lineHeight: "26px",
        textAlign: "center",
        [theme.breakpoints.down('md')]: {
            textAlign: "left",
        }
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down('md')]: {
            justifyContent: "flex-start",
        }

    },
    button: {
        height: 50,
        padding: "0 30px",
        [theme.breakpoints.down('md')]: {
            width: "100%",
        }
    }
}), { name: "MessengerChatSetDialog" });

type Props = {
    intl: Intl,
    dispatch: Dispatch,
    config: any,
};

export const MessengerChatSetDialog = (props: Props) => {
    const
        { intl, dispatch, config } = props,
        classes = styles(),
        handleClose = () => {
            dispatch(seenChatWidgetSetDialog());
            dispatch(closeDialogAction());
        };

    return (
        <BasicDialog
            onClose={handleClose}
            classes={{ paper: classes.paper }}
            {...config}
        >
            <DialogContent>
                <div className={classes.dialogTitle}>
                    {intl.msgJoint("msg:component.productWidget.OnboardingSuccess.title {You're all set!}")}
                </div>
                <Hidden mdDown>
                    <VerticalSpacer y={30} />
                </Hidden>
                {/* @ts-ignore */}
                <Hidden mdUp>
                    <VerticalSpacer y={24} />
                </Hidden>
                <div className={classes.imageWrapper}>
                    <div className={imageStyles.allSetDialogIcon} />
                </div>
                <Hidden mdDown>
                    <VerticalSpacer y={30} />
                </Hidden>
                {/* @ts-ignore */}
                <Hidden mdUp>
                    <VerticalSpacer y={16} />
                </Hidden>
                <div className={classes.dialogSubTitle}>
                    {intl.msgJoint("msg: chatWidget.chat.setDialog.description {Your Messenger chat is ready to go. You just need to publish to make it live on your site.}")}
                </div>
                <Hidden mdDown>
                    <VerticalSpacer y={30} />
                </Hidden>
                {/* @ts-ignore */}
                <Hidden mdUp>
                    <VerticalSpacer y={38} />
                </Hidden>
                <div className={classes.buttonContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleClose}
                    >
                        {intl.msgJoint("msg: common.OK {OK}")}
                    </Button>
                </div>
            </DialogContent>

        </BasicDialog>
    );
};

export const MESSNGER_CHAT_SET_DIALOG_ID = 'MESSNGER_CHAT_SET_DIALOG_ID';
