import { applyMappers, makePlainMappers } from "../../utils";
import type { VideoFileComponent } from "../../../../src/components/oneweb/Video/flowTypes";
import buildNewVideoComponentState from "../../../../src/components/oneweb/Video/buildNewVideoComponentState";

const plainPropsMapper = makePlainMappers({
    mobileDown: "mobileDown",
    mobileHide: "mobileHide",
    wrap: "wrap",
    stretch: "stretch",
    state: "state",
    asset: "asset",
});

const handleNullState = (component) => {
    if (!component.state) {
        return {
            ...component,
            //From old wsb we get null status some times, which will restrict the page to load
            //In such cases we add dummy state WBTGEN-8896
            ...buildNewVideoComponentState('dummy'),
            mobileDown: component.mobileDown
        };
    }
    return component;
};

export function to(componentData: any): VideoFileComponent {
    return handleNullState(applyMappers(
        componentData,
        plainPropsMapper.to
    ));
}

export function back(component: VideoFileComponent): any {
    return applyMappers(
        component,
        plainPropsMapper.back
    );
}
