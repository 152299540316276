import { LangsListType } from "../cookieBannerGlobalData/types";

/* eslint-disable no-unused-vars,max-len */
const CHAT_WIDGET_SUPPORTED_LANGS = {
    'af_ZA': 'Afrikaans',
    'ar_AR': 'Arabic',
    'az_AZ': 'Azerbaijani',
    'be_BY': 'Belarusian',
    'bg_BG': 'Bulgarian',
    'bn_IN': 'Bengali',
    'bs_BA': 'Bosnian',
    'ca_ES': 'Catalan',
    'cs_CZ': 'Czech',
    'cy_GB': 'Welsh',
    'da_DK': 'Danish',
    'de_DE': 'German',
    'el_GR': 'Greek',
    'en_GB': 'English (UK)',
    'en_PI': 'English (Pirate)',
    'en_UD': 'English (Upside Down)',
    'en_US': 'English (US)',
    'eo_EO': 'Esperanto',
    'es_ES': 'Spanish (Spain)',
    'es_LA': 'Spanish',
    'et_EE': 'Estonian',
    'eu_ES': 'Basque',
    'fa_IR': 'Persian',
    'fb_LT': 'Leet Speak',
    'fi_FI': 'Finnish',
    'fo_FO': 'Faroese',
    'fr_CA': 'French (Canada)',
    'fr_FR': 'French (France)',
    'fy_NL': 'Frisian',
    'ga_IE': 'Irish',
    'gl_ES': 'Galician',
    'he_IL': 'Hebrew',
    'hi_IN': 'Hindi',
    'hr_HR': 'Croatian',
    'hy_AM': 'Armenian',
    'id_ID': 'Indonesian',
    'is_IS': 'Icelandic',
    'it_IT': 'Italian',
    'ja_JP': 'Japanese',
    'ka_GE': 'Georgian',
    'km_KH': 'Khmer',
    'ko_KR': 'Korean',
    'ku_TR': 'Kurdish',
    'la_VA': 'Latin',
    'lt_LT': 'Lithuanian',
    'lv_LV': 'Latvian',
    'mk_MK': 'Macedonian',
    'ml_IN': 'Malayalam',
    'ms_MY': 'Malay',
    'nb_NO': 'Norwegian (bokmal)',
    'ne_NP': 'Nepali',
    'nl_NL': 'Dutch',
    'nn_NO': 'Norwegian (nynorsk)',
    'pa_IN': 'Punjabi',
    'pl_PL': 'Polish',
    'ps_AF': 'Pashto',
    'pt_BR': 'Portuguese (Brazil)',
    'pt_PT': 'Portuguese (Portugal)',
    'ro_RO': 'Romanian',
    'ru_RU': 'Russian',
    'sk_SK': 'Slovak',
    'sl_SI': 'Slovenian',
    'sq_AL': 'Albanian',
    'sr_RS': 'Serbian',
    'sv_SE': 'Swedish',
    'sw_KE': 'Swahili',
    'ta_IN': 'Tamil',
    'te_IN': 'Telugu',
    'th_TH': 'Thai',
    'tl_PH': 'Filipino',
    'tr_TR': 'Turkish',
    'uk_UA': 'Ukrainian',
    'vi_VN': 'Vietnamese',
    'zh_CN': 'Simplified Chinese (China)',
    'zh_HK': 'Traditional Chinese (Hong Kong)',
    'zh_TW': 'Traditional Chinese (Taiwan)'
};

const ONE_COM_LANG_TO_CHAT_WIDGET_SUPPORTED_LANGS_MAP = {
    "da": "da_DK",
    "de": "de_DE",
    "en": "en_US",
    "es": "es_ES",
    "fi": "fi_FI",
    "fr": "fr_FR",
    "it": "it_IT",
    "nb": "nb_NO",
    "no": "nn_NO",
    "nl": "nl_NL",
    "pt": "pt_PT",
    "sv": "sv_SE"
};

const ONE_COM_SUPPORTED_LANGS_TRANSLATED_TEXT_CONTINUE_AS_USERNAME = {
    "da_DK": "Fortsæt som brugernavn",
    "de_DE": "Als Benutzer fortfahren",
    "en_GB": "Continue as username",
    "es_ES": "Continuar con nombre de usuario",
    "es_LA": "Continuar con nombre de usuario",
    "fi_FI": "Jatka käyttäjänimellä",
    "fr_FR": "Poursuivre en tant que nom d'utilisateur",
    "fr_CA": "Poursuivre en tant que nom d'utilisateur",
    "it_IT": "Continua come nome utente",
    "nb_NO": "Fortsett som brukernavn",
    "nn_NO": "Fortsett som brukernavn",
    "nl_NL": "Doorgaan met gebruikersnaam",
    "pt_PT": "Continuar como nome de utilizador",
    "pt_BR": "Continuar como nome de utilizador",
    "sv_SE": "Fortsätt som användarnamn"
};

const ONE_COM_SUPPORTED_LANGS_TRANSLATED_TEXT_CONTINUE_AS_GUEST = {
    "da_DK": "Fortsæt som gæst",
    "de_DE": "Als Gast fortfahren",
    "en_GB": "Continue as guest",
    "es_ES": "Continuar como invitado",
    "es_LA": "Continuar como invitado",
    "fi_FI": "Jatka vieraana",
    "fr_FR": "Poursuivre en tant qu'invité",
    "fr_CA": "Poursuivre en tant qu'invité",
    "it_IT": "Continua come ospite",
    "nb_NO": "Fortsett som gjest",
    "nn_NO": "Fortsett som gjest",
    "nl_NL": "Doorgaan als gast",
    "pt_PT": "Continuar como convidado",
    "pt_BR": "Continuar como convidado",
    "sv_SE": "Fortsätt som gäst"
};

const ONE_COM_SUPPORTED_LANGS_TRANSLATED_TEXT_GET_IN_TOUCH = {
    "da_DK": "Kontakt os!",
    "de_DE": "Nehmen Sie Kontakt zu uns auf!",
    "en_GB": "Get in touch with us!",
    "es_ES": "¡Contáctenos!",
    "es_LA": "¡Contáctenos!",
    "fi_FI": "Ota meihin yhteyttä!",
    "fr_FR": "Contactez-nous !",
    "fr_CA": "Contactez-nous !",
    "it_IT": "Mettiti in contatto con noi!",
    "nb_NO": "Kontakt oss!",
    "nn_NO": "Kontakt oss!",
    "nl_NL": "Neem contact met ons op!",
    "pt_PT": "Entre em contacto connosco!",
    "pt_BR": "Entre em contacto connosco!",
    "sv_SE": "Kontakta oss!"
};

export const getChatWidgetLanguageSelectionOptions = () => {
    const chatLangs: LangsListType = [];
    Object.keys(CHAT_WIDGET_SUPPORTED_LANGS).forEach((item) => {
        chatLangs.push({
            value: item,
            label: CHAT_WIDGET_SUPPORTED_LANGS[item],
        });
    });

    return chatLangs.sort((a, b) => {
        return (a.label > b.label ? 1 : 0) - 0.5;
    });
};

export const getTranslatedPreviewLabels = (selectedLanguage) => {
    const previewLabels: Array<string> = [];
    [
        ONE_COM_SUPPORTED_LANGS_TRANSLATED_TEXT_GET_IN_TOUCH,
        ONE_COM_SUPPORTED_LANGS_TRANSLATED_TEXT_CONTINUE_AS_USERNAME,
        ONE_COM_SUPPORTED_LANGS_TRANSLATED_TEXT_CONTINUE_AS_GUEST].forEach((translationsObject) => {
        previewLabels.push(Object.keys(translationsObject).includes(selectedLanguage) ? translationsObject[selectedLanguage] : translationsObject.en_GB);
    });
    return previewLabels;
};

export const oneComLangToChatWidgetLang = (oneComLang: string) => {
    return ONE_COM_LANG_TO_CHAT_WIDGET_SUPPORTED_LANGS_MAP[oneComLang];
};
