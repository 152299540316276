export const
    IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_ACTIONS = "IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_ACTIONS",
    FC_CREATE_WEBSPACE_FOLDER_SUCCESS = "FC_CREATE_WEBSPACE_FOLDER_SUCCESS",
    UPLOAD_FROM_COMPUTER_ACTION = "UPLOAD_FROM_COMPUTER_ACTION",
    FC_COMPUTER_UPLOAD_SUCCESS = "FC_COMPUTER_UPLOAD_SUCCESS",
    GET_RESOURCE_METADATA_ACTION = "GET_RESOURCE_METADATA_ACTION",
    FC_RESOURCE_METADATA_SUCCESS = "FC_RESOURCE_METADATA_SUCCESS",
    FC_RESOURCE_METADATA_FAILURE = "FC_RESOURCE_METADATA_FAILURE";

export const
    fetchImageContents = (): Action => ({
        type: IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_ACTIONS
    }),
    uploadFromComputerAction = (files: Array<File>, source: string): Action => ({
        type: UPLOAD_FROM_COMPUTER_ACTION,
        payload: { files, source },
    }),
    getResourceMetaData = (value: File): Action => ({
        type: GET_RESOURCE_METADATA_ACTION,
        payload: value,
    });

