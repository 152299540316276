import React from "react";
import NavigationGroup from "../../../../PropertiesPanel/view/IntlNavigationGroup";
import Page from "../../../../PropertiesPanel/view/PropertiesPage";
import pagesTitles from "./titles";
import * as pagesIds from "./ids";
import type { Props } from "../flowTypes";

const id = pagesIds.MAIN,
    title = pagesTitles[pagesIds.MAIN],
    view = ({ navigateToPage }: Props) => {
        return (
            <Page>
                <NavigationGroup
                    navigateToPage={navigateToPage}
                    pagesTitles={pagesTitles}
                    targetPagesIds={[pagesIds.ADVANCED, pagesIds.STYLE]}
                />
            </Page>
        );
    };

export { id, view, title };
