import { objectCollectionsAreDiff, objectsAreDiff } from "../../../../utils/objectsDiffChecks";
import { BODY_STYLE_REF } from "../../../oneweb/Template/epics/template/styleMapper";
import * as backgroundMapper from "../../../../../dal/pageMapAdapter/mappers/Common/background";
import type { ThemeColorDataType } from "../../../ThemeGlobalData/flowTypes";

type Params = {
    styles: Array<Record<string, any>>;
    nextStyles: Array<Record<string, any>>;
    templateDataStyle: Record<string, any>;
};

type ThemeColorsDataIsChangedParams = {
    themeData: ThemeColorDataType;
    nextThemeData: ThemeColorDataType;
};

export const stylesheetDataIsChanged = ({ styles, nextStyles, templateDataStyle }: Params) => {
    const bodyStyle = styles.find(s => s.ref === BODY_STYLE_REF);
    if (!bodyStyle) throw new Error('Unable for find body style in stylesheet styles');

    if (templateDataStyle.background) {
        if (!bodyStyle.background) return true;

        const nextBodyStyleBackground = backgroundMapper.back(templateDataStyle.background) as Record<string, any>;
        if (objectsAreDiff(bodyStyle.background, nextBodyStyleBackground)) return true;
    }

    return objectCollectionsAreDiff(styles, nextStyles);
};

export const themeDataIsChanged = ({
    themeData,
    nextThemeData
}: ThemeColorsDataIsChangedParams) => {
    if (themeData) return objectsAreDiff(themeData, nextThemeData);
    return !!nextThemeData;
};
