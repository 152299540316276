import { getDAL } from "../../../../../../wbtgen/dal";
import { makeUrl } from "../../../../../../wbtgen/dal/makeUrl";
import { mapLangCodeForThirdParty } from "../../../../../../server/shared/locale/mapLangCodeForThirdParty";
import dayjs from "../../../../../../wbtgen/src/dayjs";

import { IS_ANALYTICS_RECENTLY_ENABLED_DAYS } from "./constants";
import { AnalyticsSettingsType } from "../../ThirdPartyConnections/types";

export const
    getKlikenSiteStatsUrl = () => {
        return getDAL().getKlikenSiteStatsUrl();
    },

    makeKlikenTermsUrl = (language: string) => {
        const langCode = mapLangCodeForThirdParty(language);

        if (langCode === 'en') {
            return 'https://www.kliken.com/terms-of-service.html';
        }

        return makeUrl({
            path: 'https://www-kliken-com.translate.goog/terms-of-service.html',
            query: {
                '_x_tr_sl': 'en',
                '_x_tr_hl': 'auto',
                '_x_tr_tl': langCode
            }
        });
    },

    getLast30DaysDateRange = () => {
        const
            dateFormat = "YYYY-MM-DD",
            startDate = dayjs().subtract(30, "day").format(dateFormat),
            endDate = dayjs().format(dateFormat);

        return { startDate, endDate };
    },

    isAnalyticsInitialized = (analyticsSettings: AnalyticsSettingsType): boolean => {
        return Boolean(analyticsSettings?.enabledAt);
    },

    isAnalyticsEnabledRecently = (analyticsSettings: AnalyticsSettingsType): boolean => {
        const { enabledAt } = analyticsSettings;
        const enabledSinceDays = dayjs().diff(dayjs(enabledAt), 'day');
        return enabledSinceDays < IS_ANALYTICS_RECENTLY_ENABLED_DAYS;
    },

    isPublishedAfterAnalyticsChange = (analyticsSettings: AnalyticsSettingsType, lastPublishedAt: number) => {
        return lastPublishedAt > analyticsSettings.updatedAt;
    };
