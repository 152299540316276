import React from 'react';
import '../../view/Preview.css';
import { DATA_TEST_ID } from '../constants';

export const EventbriteView = props => (
    <div data-testid={DATA_TEST_ID} className="widget" style={{ width: props.width }}>
        <iframe src={props.link} width="100%" height={props.height} style={{ border: 'none', overflow: 'hidden' }} />
    </div>
);

