import * as actions from '../actionTypes';
import { WIDGETS_MAILCHIMP_EPIC_VAT } from "./valueActionType";
import { parseMailchimpCode } from '../utils';
import { configurationDialogId } from '../configurationDialog';
import { epicFactory } from '../../epics/factory';
import { linkExtractor } from '../../utils';

const WidgetsMailchimpEpic = epicFactory({
    defaultState: null,
    valueActionType: WIDGETS_MAILCHIMP_EPIC_VAT,
    configurationDialogId,
    codeParser: parseMailchimpCode,
    payloadExtractor: linkExtractor,
    setLinkAction: actions.WIDGETS_MAILCHIMP_SET_LINK,
    updatePropsAction: actions.WIDGETS_MAILCHIMP_UPDATE_PROPS,
    showUpdateLinkDialogAction: actions.WIDGETS_MAILCHIMP_SHOW_UPDATE_LINK_DIALOG,
    closeDialogActions: [actions.WIDGETS_MAILCHIMP_UPDATE_LINK, actions.WIDGETS_MAILCHIMP_UPDATE_PROPS],
});

export { WidgetsMailchimpEpic };

