import makeEpic from "../../epics/makeEpic";
import { receiveOnly } from "../../epics/makeCondition";
import {
    ONBOARDING_TEMPLATE_SELECTED,
    ONBOARDING_COMPLETE,
    SEE_ALL_TEMPLATES_CLICKED,
    ONBOARDING_TEMPLATE_LIST_LEFT_ARROW_CLICKED,
    ONBOARDING_TEMPLATE_LIST_RIGHT_ARROW_CLICKED
} from "./actionTypes";
import templateListVAT from "../TemplateSelector_DEPRECATED/epics/templatesList/valueActionType";
import { TEMPLATE_IMPORT_COMPLETE, clickTemplateSelectorImport } from "../TemplateSelector_DEPRECATED/actionTypes";
import appStatusVAT from "../App/epics/status/valueActionType";
import { READY } from "../App/epics/status/appStatusTypes";
import { CLOSE_DIALOG } from "../../redux/modules/actionTypes";
import { ONBOARDING_VAT } from "./onboardingVat";

export type OnboardingTemplateSelectorEpicType = {
    templateCategory?: string;
    selectedTemplateIndex: number;
    templateSelected: boolean;
};

const valueActionType = "Onboarding_TemplateSelector";
const defaultState = {
    templateCategory: null,
    selectedTemplateIndex: 1,
    templateSelected: false
};
const onboardingTemplateSelectorEpic = makeEpic({
    valueActionType,
    defaultState,
    updaters: [
        {
            conditions: [SEE_ALL_TEMPLATES_CLICKED],
            reducer: ({ state }) => {
                return {
                    state
                };
            }
        },
        {
            conditions: [ONBOARDING_TEMPLATE_LIST_LEFT_ARROW_CLICKED],
            reducer: ({ state }) => {
                return {
                    state: { ...state, selectedTemplateIndex: Math.max(0, state.selectedTemplateIndex - 1) }
                };
            }
        },
        {
            conditions: [ONBOARDING_TEMPLATE_LIST_RIGHT_ARROW_CLICKED],
            reducer: ({ state, values: [{ templatesCount }] }) => {
                return {
                    state: { ...state, selectedTemplateIndex: Math.min(state.selectedTemplateIndex + 1, templatesCount - 1) }
                };
            }
        },
        {
            conditions: [receiveOnly(ONBOARDING_VAT), ONBOARDING_TEMPLATE_SELECTED],
            reducer: ({
                state,
                values: [
                    {
                        gmbFilteredDesigns,
                        themePreviewDataForOnboarding: { computedThemeColorsForNewOnBoarding }
                    }
                ]
            }) => {
                const // TODO- To fix in WBTGEN-19101 to use uuid and need to go away with filter criteria
                    template = gmbFilteredDesigns[state.selectedTemplateIndex],
                    multipleActionsToDispatch = [
                        clickTemplateSelectorImport(template, computedThemeColorsForNewOnBoarding),
                        {
                            type: ONBOARDING_COMPLETE
                        }
                    ];

                return {
                    state: { ...state, templateSelected: true },
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [TEMPLATE_IMPORT_COMPLETE],
            reducer: () => ({
                state: defaultState
            })
        },
        {
            conditions: [templateListVAT, receiveOnly(appStatusVAT)],
            reducer: ({ state, values: [templatesList, appStatus] }) => {
                if (appStatus === READY && state.templateCategory && state.templateCategory !== templatesList.filter) {
                    // When user changes category from templates list page
                    return {
                        state: { ...defaultState, templateCategory: templatesList.filter !== "all" ? templatesList.filter : null }
                    };
                }

                return {
                    state
                };
            }
        },
        {
            conditions: [CLOSE_DIALOG],
            reducer: ({ state }) => {
                return {
                    state: { ...state, templateSelected: false }
                };
            }
        }
    ]
});
export { valueActionType, onboardingTemplateSelectorEpic };
