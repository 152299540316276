import * as R from "ramda";

import { getAssetUrl } from '../../../utils/assetUtils';
import { buildWorkspaceVideoUrl } from "./videoUrlsUtility";
import { getSettingValue } from "./selector";
import { VideoSettings } from "./constants";

export default (props: any, workspaceMode: boolean = false) => {
    const
        { component, componentDependencies } = props,
        { width, height, mobileHide, asset, state: { settings }, } = component,
        // eslint-disable-next-line no-nested-ternary
        url = !asset ? buildWorkspaceVideoUrl(component, workspaceMode) :
            getAssetUrl(asset, {});

    let videoAvailable: boolean | null = null,
        videoStatus: string | null = null;
    if (workspaceMode) {
        const { missingAssetUrls, transcodeStatus, videoUploadStatus, uploadFailedVideos } = componentDependencies;
        videoAvailable = asset && !!asset.url && missingAssetUrls.indexOf(asset.url) === -1;
        const videoTranscodeStatus = asset && R.pipe(R.path([asset.href, 'status']), R.defaultTo(''))(transcodeStatus);
        if (['progressing', 'uploaded'].includes(videoTranscodeStatus)) {
            videoStatus = 'Processing';
            videoAvailable = true;
        } else if (videoTranscodeStatus === 'error' || (asset && uploadFailedVideos.includes(asset.href))) {
            videoStatus = 'Failed';
        } else if (asset && videoUploadStatus.includes(asset.href)) {
            videoStatus = 'Uploading';
            videoAvailable = true;
        }
    } else {
        videoAvailable = asset && !!asset.url;
    }

    let videoProps: Record<string, any> | null = null,
        isVideoFile = !!asset,
        isNewVideo: boolean | null = null;
    if (asset) {
        videoProps = { preload: "metadata", fit: getSettingValue(settings, VideoSettings.fit) };
        // @ts-ignore There should be better null/undefined checking
        isNewVideo = url.split("?").shift().split("webspace/").pop() === "";

        const controls = getSettingValue(settings, VideoSettings.showControls),
            autoPlay = getSettingValue(settings, VideoSettings.autoPlay),
            loop = getSettingValue(settings, VideoSettings.repeat),
            muted = getSettingValue(settings, VideoSettings.muted);

        if (autoPlay) {
            videoProps = { ...videoProps, autoPlay };
        }
        if (controls) {
            videoProps = { ...videoProps, controls };
        }
        if (loop) {
            videoProps = { ...videoProps, loop };
        }
        if (workspaceMode) {
            videoProps = { ...videoProps };
        }
        if (muted) {
            videoProps = { ...videoProps, muted };
        }
    }

    return { url, height, width, mobileHide, settings: videoProps, asset, isVideoFile, videoAvailable, isNewVideo, videoStatus };
};
