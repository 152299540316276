import * as React from "react";
import { LogoTextModeView } from './LogoTextModeView';
import ImageView from '../../Image/view/index';
import { ImageCalcRenderPropsResult } from "../../Image/flowTypes";

export const LogoView = (props: ImageCalcRenderPropsResult & { websiteTitle?: string }) => {
    if (props.websiteTitle) {
        return <LogoTextModeView
            {...props}
            // @ts-ignore
            componentHeight={props.base && props.base.height}
        />;
    }

    return <ImageView {...props} />;
};
