
import React from 'react';
import * as R from 'ramda';
import { Flex } from '../../../../../view/reflexbox/index';
import * as path from '../../../../../mappers/path';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import Label from "../../../../../view/common/Label";
import Combobox from "../../../../../view/common/Combobox/index";
import PropertyGlobalStyleSelector from '../../../../../view/common/PropertyGlobalstyleSelector/index';
import { ThemeSelector } from '../../../../PropertiesPanel/view/ThemeSelector';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import * as actionTypes from '../../actionTypes';
import * as pagesIds from '../pagesIds';
import pagesTitles from '../pagesTitles';
import type { Props } from '../flowTypes';
import { DEFAULT_MCTA_COMBOBOX_MENUSTYLE, MCTA_THEME_OPTIONS_BUTTON } from '../../../../componentMainActions/constants';
import {
    columnOptions,
    rowOptions,
    SLIDER_MAX_SIZE,
    SHOW_MORE_BTN_FONT_MAX_SIZE
} from '../../constants/index';
import Slider from '../../../../../view/common/Slider/PPSlider';
import PropertiesCheckBox from '../../../../../view/common/CheckBox/PropertiesCheckBox';
import injectIntl from '../../../../../view/intl/injectIntl';
import { getAllStylesheets } from '../../../../Workspace/epics/stylesheets/selectors';
import ButtonGlobalStyleKind from '../../../Button/globalStyle/kind';
import optionMapper from '../../../Button/globalStyle/options';
import { optionRenderer } from "../../../Button/globalStyle/optionRenderer";
import { TooltipPosition } from "../../../../presentational/Tooltip/constants";
import { QuestionTip } from "../../../../presentational/Tooltip/questionTip/QuestionTip";
import ppStyles from "./styles.css";

const
    id = pagesIds.LAYOUT,
    title = pagesTitles[id],
    view = injectIntl(({
        selectedComponent,
        intl,
        stylesheets,
        stylesheetsIdToNameMap,
        dispatch,
        themeSettingsData,
    }: Props) => {
        const
            { columns, rows, spacingPx, fontSizePx, showMoreBtn } = selectedComponent,
            styles = getAllStylesheets(stylesheets),
            globalStyleId = R.path(path.styleGlobalId, selectedComponent),
            optRenderer = (value, label) => optionRenderer(value, label, stylesheetsIdToNameMap),
            // eslint-disable-next-line max-len
            showMoreBtnTipMsg = "msg: component.instagram.pp.layout.showMoreBtn.tipMsg {Adds a \"Show more\" button underneath the initial selection of images.}";

        return (
            <Page>
                <VerticalSpacer />
                <Flex justify="space-between" align="center">
                    <Label>{"msg: component.instagram.pp.layout.columns {Columns}"}</Label>
                    <Combobox
                        className={ppStyles.comboboxSize}
                        value={columns}
                        searchable={false}
                        menuStyle={DEFAULT_MCTA_COMBOBOX_MENUSTYLE}
                        options={columnOptions}
                        onChange={({ value }) => dispatch({
                            type: actionTypes.INSTAGRAM_GALLERY_PP_COLUMNS_CHANGED,
                            payload: value,
                            amendToSelf: true
                        })}
                    />
                </Flex>
                <VerticalSpacer />
                <Flex justify="space-between" align="center">
                    <Label>{"msg: component.instagram.pp.layout.rows {Rows}"}</Label>
                    <Combobox
                        className={ppStyles.comboboxSize}
                        value={rows}
                        searchable={false}
                        menuStyle={DEFAULT_MCTA_COMBOBOX_MENUSTYLE}
                        options={rowOptions}
                        onChange={({ value }) => dispatch({
                            type: actionTypes.INSTAGRAM_GALLERY_PP_ROWS_CHANGED,
                            payload: value,
                            amendToSelf: true
                        })}
                    />
                </Flex>
                <VerticalSpacer />
                <Slider
                    label="msg: component.gallery.propPanel.thumbnails.spacing {Spacing}"
                    min={0}
                    max={SLIDER_MAX_SIZE}
                    value={spacingPx}
                    valueLabel={`${spacingPx} px`}
                    onChange={value => dispatch({
                        type: actionTypes.INSTAGRAM_GALLERY_PP_SPACING_CHANGED,
                        payload: value,
                        amendToSelf: true
                    })}
                />
                <Flex justify="space-between" align="center">
                    <PropertiesCheckBox
                        isChecked={showMoreBtn}
                        label="msg: component.instagram.pp.showMoreBtn {Show more button}"
                        onClick={() => dispatch(
                            { type: actionTypes.INSTAGRAM_GALLERY_PP_SHOW_MORE_BTN_ENABLED, payload: { isEnabled: !showMoreBtn } }
                        )}
                    />
                    <QuestionTip
                        position={TooltipPosition.TOP}
                        className={ppStyles.tipInfoIcnPos}
                        theme={{
                            container: ppStyles.infoTipContainer,
                            tipInfo: ppStyles.tipInfoColor
                        }}
                    >{showMoreBtnTipMsg}</QuestionTip>
                </Flex>
                {
                    showMoreBtn &&
                    <div>
                        <VerticalSpacer />
                        {
                            themeSettingsData.autoColorMode
                                ? <ThemeSelector // TODO: WBTGEN-17575
                                    dispatch={dispatch}
                                    label="msg: common.buttonStyle {Button style}"
                                    onChange={({ value }) => dispatch({
                                        type: actionTypes.INSTAGRAM_GALLERY_PP_SHOW_MORE_BTN_STYLE_CHANGED_AUTO_COLOR,
                                        payload: value,
                                    })}
                                    selectedTheme={selectedComponent.buttonThemeSelected}
                                    themeOptions={MCTA_THEME_OPTIONS_BUTTON}
                                />
                                : <PropertyGlobalStyleSelector
                                    label="msg: common.buttonStyle {Button style}"
                                    globalStyleId={globalStyleId}
                                    kind={ButtonGlobalStyleKind}
                                    onChangeAction={actionTypes.INSTAGRAM_GALLERY_PP_SHOW_MORE_BTN_STYLE_CHANGED}
                                    options={optionMapper(styles, intl)}
                                    optionRenderer={optRenderer}
                                    dispatch={dispatch}
                                />
                        }
                        <VerticalSpacer />
                        <Slider
                            label="msg: component.instagram.pp.showMoreBtn.fontsize {Button font size}"
                            min={0}
                            max={SHOW_MORE_BTN_FONT_MAX_SIZE}
                            value={fontSizePx}
                            valueLabel={`${fontSizePx} px`}
                            onChange={value => dispatch({
                                type: actionTypes.INSTAGRAM_GALLERY_PP_SHOW_MORE_BTN_FONTSIZE_CHANGED,
                                payload: value,
                                amendToSelf: true
                            })}
                        />
                    </div>
                }
            </Page>
        );
    });

export { id, view, title };
