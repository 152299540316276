// const UA_ID_PATTERN = /^UA-\d{4,10}(-\d{1,4})?$/i; // UA-XXXXXXX-XX
const GA4_ID_PATTERN = /^G-[0-9a-z]{8,18}$/i;      // G-XXXXXXX

// Not sure about the length:
//     WBTGEN-24831 mentions GT-xxxxxx (e.g. GT-938DFS)
//     https://support.google.com/google-ads/answer/12326985?hl=en mentions GT-XXXXXXXXX
const GT_ID_PATTERN = /^GT-[0-9a-z]{6,18}$/i;       // GT-XXXXXXXXX

// No documentation available for validation. Copying same as GT
const GTM_ID_PATTERN = /^GTM-[0-9a-z]{6,18}$/i;       // GTM-XXXXXXX

// Google Ads tag https://developers.google.com/tag-platform/devguides/existing
const AW_ID_PATTERN = /^AW-[0-9a-z]{6,18}$/i;       // AW-XXXXXX

export const isGAIdValid = (ids) => {
    return (ids || '')
        .split(',')
        .map(id => id.trim())
        .filter(x => x)
        .every(id => (
            !id ||
            GA4_ID_PATTERN.test(id) ||
            GT_ID_PATTERN.test(id) ||
            GTM_ID_PATTERN.test(id) ||
            AW_ID_PATTERN.test(id)
        ));
};
