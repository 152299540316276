import { WidgetsEpic } from './epics/index';
import { WidgetsPinterestEpic } from "./Pinterest/epic/index";
import { WidgetsTwitterEpic } from "./Twitter/epic/index";
import { WidgetsInstagramEpic } from "./Instagram/epic/index";
import { WidgetsFacebookEpic } from "./Facebook/epic/index";
import { WidgetsSoundcloudEpic } from "./Soundcloud/epic/index";
import { WidgetsSpotifyEpic } from './Spotify/epic/index';
import { WidgetsTiktokEpic } from './Tiktok/epic/index';
import { WidgetsVimeoEpic } from './Vimeo/epic/index';
import { WidgetsEventbriteEpic } from './Eventbrite/epic/index';
import { WidgetsGoogleMapsEpic } from './GoogleMaps/epic/index';
import { WidgetsBingMapsEpic } from './BingMaps/epic/index';
import { WidgetsGoFundMeEpic } from './GoFundMe/epic/index';
import { WidgetsMailchimpEpic } from './Mailchimp/epic';
import { WidgetsPaypalEpic } from './Paypal/epic/index';
import { WidgetsBookingsEpic } from './Bookings/epic/index';

export const WidgetsEpics = [
    WidgetsEpic,
    WidgetsPinterestEpic,
    WidgetsTwitterEpic,
    WidgetsInstagramEpic,
    WidgetsFacebookEpic,
    WidgetsSpotifyEpic,
    WidgetsSoundcloudEpic,
    WidgetsVimeoEpic,
    WidgetsTiktokEpic,
    WidgetsGoogleMapsEpic,
    WidgetsEventbriteEpic,
    WidgetsBingMapsEpic,
    WidgetsGoFundMeEpic,
    WidgetsMailchimpEpic,
    WidgetsPaypalEpic,
    WidgetsBookingsEpic,
];
