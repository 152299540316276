import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import YouTubePlayer from 'youtube-player';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.css';
import { Dialog, DialogBody, DialogTitleBox } from '../../../../view/common/dialogs/baseDialog';
import { VIDEO_TUTORIAL_PLAYED } from './actionTypes';
import { getCenterPositionForDialog } from '../../../DialogManager/utility';
import { UPDATE_DIALOG } from '../../../../redux/modules/actionTypes';
import { TutorialVideoDialogId } from '../dialogIds';
import { useIntl } from '../../../../view/intl';
import { closeDialogByIdAction } from '../../../App/actionCreators';
import { dialogManagerIsDraggingSelector } from '../../../DialogManager/epic/selectors';

let isVideoPlayed = false;
let width = 480, height = 316;

const
    tutorialId = 'youtube-video-tutorial',
    title = "msg: videoTutorial.title {Start building your website!}",
    videoLink = "msg: videoTutorial.link {K2BoiWw9h38}",
    TutorialDialog = () => {
        const [isHooked, setIsHooked] = useState(false);
        const [isMax, setIsMax] = useState(false);
        const dispatch = useDispatch();
        const playerRef = useRef<YouTubePlayer | null>(null);
        const intl = useIntl();
        const isDragging = useSelector(dialogManagerIsDraggingSelector);
        useEffect(() => {
            if (!playerRef.current) {
                const player = YouTubePlayer(tutorialId, {
                    videoId: intl.msgJoint(videoLink),
                    width: '100%',
                    height: '100%',
                    playerVars: { fs: 0 }
                });
                playerRef.current = player;
            }
            isVideoPlayed = false;
            return () => {
                if (playerRef.current) {
                    playerRef.current.destroy();
                    playerRef.current = null;
                }
            };
        }, []);

        useEffect(() => {
            if (playerRef.current && !isHooked) {
                playerRef.current.on('stateChange', (event) => {
                    if ([1, 2, 3, 4].includes(event.data)) {
                        if (!isVideoPlayed) {
                            dispatch({ type: VIDEO_TUTORIAL_PLAYED });
                        }
                        isVideoPlayed = true;
                    }
                });
                setIsHooked(true);
            }
        }, [isHooked, dispatch]);
        useEffect(() => {
            if (isMax) {
                dispatch({ type: UPDATE_DIALOG, payload: { dialogId: TutorialVideoDialogId, props: { width: 856, height: 559 } } });
            } else {
                dispatch({ type: UPDATE_DIALOG, payload: { dialogId: TutorialVideoDialogId, props: { width: 480, height: 316 } } });
            }
        }, [isMax]);
        return (
            <Dialog
                stripType={null}
                closeBtnClassName={styles.closeBtn}
                onClose={() => dispatch(closeDialogByIdAction(TutorialVideoDialogId))}
            >
                <div className={isMax ? styles.minBtn : styles.maxBtn} onClick={() => setIsMax(!isMax)} />
                <div className={styles.externalLinkBtn} onClick={() => window.open('https://youtu.be/' + intl.msgJoint(videoLink), '_blank')} />
                <DialogTitleBox
                    title={title}
                    className={styles.dialogTitleBox}
                    titleClass={styles.title}
                />
                <DialogBody
                    className={styles.tutorialDialogBody}
                >
                    <div id={tutorialId} className={styles.videoPlayer} />
                    <div className={cx({ [styles.overlay]: isDragging })} />
                </DialogBody>
            </Dialog>
        );
    };

const tutorialConfig = {
    component: TutorialDialog,
    confFactory: ({ browserWidth, browserHeight }) => {
        const _width = width,
            _height = height;

        const position = getCenterPositionForDialog(
            _width, _height, browserWidth, browserHeight
        );

        position.top = 50;
        position.left = browserWidth - width - 20;
        return ({
            position,
            dimensions: {
                width: _width,
                height: _height
            }
        });
    },
    updateConfFn: (config, dimensions, props) => {
        const updatedConfig = { ...config };
        let mid = Math.round(dimensions.browserWidth / 2),
            right = config.position.left + config.dimensions.width;
        //if dialog is on the right side of the screen, change left position
        if (right > mid) {
            updatedConfig.position.left = config.position.left - (props.width - config.dimensions.width);
        }
        updatedConfig.position.left = Math.max(updatedConfig.position.left, 0);
        if (config.position.top + props.height > dimensions.browserHeight) {
            updatedConfig.position.top = Math.max(dimensions.browserHeight - props.height, 0);
        }
        updatedConfig.dimensions = props;
        return updatedConfig;
    },
    onModalClickAction: false,
    draggableClassName: styles.draggable,
    updateOnBrowserDimensionsChange: false
};

export default tutorialConfig;
