import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Box, FormControl, Tooltip } from "@mui/material";

import { SettingsInput, SettingsInputLabel } from '../../../../SettingsControls';
import { useIntl } from "../../../../../../../../wbtgen/src/view/intl";
import { setLogoAltTextAction } from "./actions";

import { selectLogoAltText } from "../../../../../common/selectors";
import { useStyles } from './styles';
import imageStyles from '../../../../Images/imageStyle.css';

export const LogoSettingsAltTextForm = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const logoAltText = useSelector(selectLogoAltText);

    const intl = useIntl();
    const [value, setValue] = useState(logoAltText);

    useEffect(() => {
        if (value !== logoAltText) {
            setValue(logoAltText);
        }
    }, [logoAltText]);

    const handleChange = (value) => {
        dispatch(setLogoAltTextAction(value));
    };

    return (
        <Box>
            <FormControl fullWidth>
                <SettingsInputLabel className={classes.altTextLabelWrapper}>
                    {intl.msgJoint('msg: generalInfo.logo.altText {Alternative text for logo}')}
                    <Tooltip
                        arrow
                        title={intl.msgJoint("msg: generalInfo.logo.altText.hint {This text will be used as \"alt\" text for the image. Add \"alt\" texts to all your images to boost your SEO.}")}
                        enterTouchDelay={0}
                        leaveTouchDelay={4000}
                        classes={{
                            tooltip: classes.tooltip,
                            arrow: classes.tooltipArrow
                        }}
                    >
                        <div className={imageStyles.info} />
                    </Tooltip>
                </SettingsInputLabel>
                <SettingsInput
                    value={value}
                    onChange={(e) => {
                        const logoAltTextValue = e.target.value;
                        setValue(logoAltTextValue);
                        handleChange(logoAltTextValue);
                    }}
                    placeholder={intl.msgJoint('msg: generalInfo.logo.altText.placeholder {Add an alternative text for logo}')}
                />
            </FormControl>
        </Box>
    );
};
