import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";
import cx from "classnames";

import Msg from "../../../../../../wbtgen/src/view/intl/Msg";
import { reloadPageAction } from "../../Auth/actionCreators";
import { useStyles } from "./styles";

import images from "../../Images/imageStyle.css";

export type ErrorMessageProps = {
    classes?: {
        root?: string;
        content?: string
    };
    reloadButton?: boolean;
    reloadHandler?: () => void;
    inline?: boolean;
    icon?: "small" | "large";
};

export const ErrorMessage = ({ classes: classNames, icon = "small", reloadButton, reloadHandler, inline }: ErrorMessageProps) => {
    const dispatch = useDispatch();
    const classes = useStyles({ inline });

    return (
        <div className={cx(classes.root, classNames?.root)} data-testid="error-message">
            <div className={cx(classes.content, classNames?.content)}>
                <span className={cx(classes.icon, icon === "large" ? images.errorLarge : images.errorSmall)} />
                <Msg k="common.error.refreshMessage" className={classes.message}>
                    There has been an error loading the content. Refresh the page or try again later.
                </Msg>
            </div>
            {reloadButton && (
                <Button
                    variant="outlined"
                    className={classes.reloadButton}
                    // this might not work if another exception happens inside the middlewares chain
                    // replace with browser reload if that happens
                    onClick={reloadHandler ?? (() => dispatch(reloadPageAction()))}
                >
                    <Msg k="common.reload">Reload</Msg>
                </Button>
            )}
        </div>
    );
};
