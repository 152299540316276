import React, { useState } from 'react';
import { Box } from '@mui/material';

import { useIntl } from "../../../../../../../../wbtgen/src/view/intl";

import { Task } from "../Task/Task";
import { ONGOING_TASKS_KEYS, TASK_TYPE } from "../constants";
import type { TaskItemKeyT } from "../../types";

const tasks = [
    {
        id: ONGOING_TASKS_KEYS.backlinks,
        type: TASK_TYPE.ongoing,
        passed: false
    },
    {
        id: ONGOING_TASKS_KEYS.updatecontent,
        type: TASK_TYPE.ongoing,
        passed: false
    }
];

export const OnGoingTasks = () => {
    const intl = useIntl();
    const [expanded, setExpanded] = useState<boolean | TaskItemKeyT>(false);

    const handleChange = (key: boolean | TaskItemKeyT) => (_e, isExpanded) => {
        setExpanded(isExpanded ? key : false);
    };

    return (
        <React.Fragment>
            <Box p="24px" sx={{ userSelect: "none" }}>
                {intl.msgJoint("msg: seoScan.tab.furtherOptimisation.intro {These recommendations are for continuous actions to maintain your SEO score and improve your website\'s visibility in search engines. Make sure to repeat them regularly to keep your score high.}")}
            </Box>
            {
                tasks.map((taskItem) => (
                    <Task
                        key={taskItem.id}
                        type={TASK_TYPE.ongoing}
                        taskItemKey={taskItem.id}
                        isPassed={taskItem.passed}
                        expanded={expanded}
                        isPending={false}
                        data={null}
                        handleChange={handleChange}
                    />
                ))
            }
        </React.Fragment>
    );
};
