import * as actionMapper from '../Common/action';
import defaultImageData from '../../../../src/components/SortImagesDialog/defaultImageData';
import type { InstagramGalleryIn } from './flowTypes';
import type { InstagramGalleryComponent } from "../../../../src/components/oneweb/InstagramGallery/flowTypes";

export const
    to = ({ images }: InstagramGalleryIn) => ({

        images: images.map(image => ({
            ...defaultImageData,
            ...image,
            title: "",

            action: image.action && actionMapper.to(image.action)
        }))
    }),
    back = ({ images }: InstagramGalleryComponent) => ({
        images: images.map(image => ({
            ...image,
            action: image.action && actionMapper.back(image.action)
        }))
    });
