import React from 'react';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import * as pagesIds from '../pagesIds';
import pagesTitles from '../pagesTitles';
import type { SocialComponent } from '../../flowTypes';
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';
import { RadioButtonGroup } from '../../../../../view/common/RadioButton/index';
import { SocialColorSourceType, SocialStyleType, THEME_DEFAULT } from '../../constants';
import {
    SOCIAL_COLOR_TYPE_CHANGED_AUTO_COLOR,
    SOCIAL_COLOR_TYPE_CHANGED,
    SOCIAL_STYLE_TYPE_CHANGED
} from '../../actionTypes';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import Combobox from '../../../../../view/common/Combobox/index';
import { intlCreateOptions } from '../../../../../view/common/Combobox/utils';
import { Intl, injectIntl, Msg } from '../../../../../view/intl/index';
import styles from './style.css';
import type { ThemeSiteSettingsDataType } from '../../../../ThemeGlobalData/flowTypes';
import type { Stylesheets } from '../../../../Workspace/epics/stylesheets/flowTypes';

type Props = {
    dispatch: Dispatch,
    selectedComponent: SocialComponent,
    themeSettingsData: ThemeSiteSettingsDataType,
    stylesheets: Stylesheets,
    intl: Intl
}

const
    stylingOptions = {
        [SocialStyleType.REGULAR]: 'msg: component.social.style.regular {Regular}',
        [SocialStyleType.CIRCLE_FILL]: 'msg: component.social.style.circleFill {Circle - fill}',
        [SocialStyleType.CIRCLE_KNOCKOUT]: 'msg: component.social.style.circleKnockout {Circle - knockout}',
        [SocialStyleType.SQUARE_FILL]: 'msg: component.social.style.squareFill {Square - fill}',
        [SocialStyleType.SQUARE_KNOCKOUT]: 'msg: component.social.style.squareKnockout {Square - knockout}',
        [SocialStyleType.ROUNDED_FILL]: 'msg: component.social.style.roundedFill {Rounded - fill}',
        [SocialStyleType.ROUNDED_KNOCKOUT]: 'msg: component.social.style.roundedKnockout {Rounded - knockout}',
    },
    captionStyleOptions = [
        {
            label: "msg: common.option.multiColor {Multi colour}",
            value: SocialColorSourceType.MULTI_COLOR
        },
        {
            label: "msg: common.option.dark {Dark}",
            value: SocialColorSourceType.DARK
        },
        {
            label: "msg: common.option.light {Light}",
            value: SocialColorSourceType.LIGHT
        }
    ],
    themeCaptionStyleOptions = [{
        label: "msg: common.option.multiColor {Multi colour}",
        value: SocialColorSourceType.MULTI_COLOR,
    }, {
        label: "msg: common.default {Default}",
        value: THEME_DEFAULT,
    }],
    id = pagesIds.STYLE,
    title = pagesTitles[pagesIds.STYLE],
    view = injectIntl(({ dispatch, selectedComponent, intl, themeSettingsData }: Props) => (
        <Page>
            <VerticalSpacer />
            <PropertyContainer label="msg: component.social.iconDesign {Icon design}">
                <Msg k="social.customizeLookOfIcons" className={styles.customizeLookOfIcons}>
                    Customize the look of the icons.
                </Msg>
                <Combobox
                    dropDownClassName={styles.comboboxContainer}
                    searchable={false}
                    value={selectedComponent.styleType}
                    options={intlCreateOptions(stylingOptions, intl)}
                    onChange={
                        ({ value }) => dispatch(
                            {
                                type: SOCIAL_STYLE_TYPE_CHANGED,
                                payload: value
                            }
                        )
                    }
                />
            </PropertyContainer>
            <VerticalSpacer />
            <PropertyContainer
                label="msg: common.color {Colour}"
                isGroupLabelRequired={false}
            >
                {
                    themeSettingsData.autoColorMode
                        ? <RadioButtonGroup
                            optionsConfig={themeCaptionStyleOptions}
                            onChange={(value) => dispatch({ type: SOCIAL_COLOR_TYPE_CHANGED_AUTO_COLOR, payload: value })}
                            value={selectedComponent.themeColorType || THEME_DEFAULT}
                        />
                        : <RadioButtonGroup
                            optionsConfig={captionStyleOptions}
                            onChange={(value) => dispatch({ type: SOCIAL_COLOR_TYPE_CHANGED, payload: value })}
                            value={selectedComponent.colorType}
                        />
                }
            </PropertyContainer>
        </Page>
    ));

export { id, view, title };
