import type { MobileData } from "./flowTypes";

const defaultData = { relations: [], groups: {} };

export default (pageMobileData: MobileData, templateMobileData?: MobileData) => {
    const pageMobileInp = pageMobileData || { ...defaultData },
        templateMobileInp = templateMobileData || { ...defaultData },
        groups = { ...pageMobileInp.groups, ...templateMobileInp.groups };
    return {
        relations: [
            ...pageMobileInp.relations,
            ...templateMobileInp.relations.filter(templateRelation =>
                pageMobileInp.relations.findIndex(r => (
                    r.id === templateRelation.id &&
                    r.top === templateRelation.top &&
                    r.bottom === templateRelation.bottom
                )) === -1)
        ],
        groups,
        groupsForView: { ...groups },
        styles: {},
        // @ts-ignore
        settings: { ...pageMobileInp.settings, ...templateMobileInp.settings }
    };
};
