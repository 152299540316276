import * as R from 'ramda';
import {
    TINY_MCE_APPLY_CHANGE_FOR_MULTIPLE_INSTANCES,
    TINY_MCE_CHANGE_FOR_MULTIPLE_INSTANCES_APPLIED, TINY_MCE_MULTI_PROCESSING_CANCELED,
    TINY_MCE_MULTI_PROCESSING_STEP,
} from "../actionTypes";
import { TABLE_CELL_GLOBAL_STYLE_UPDATED } from '../../../../oneweb/Table/actionTypes';
import { siteDataReceiveOnlyExternalValueActionType } from "../../siteData/valueActionType";
import { receiveOnly } from "../../../../../epics/makeCondition";
import { STYLESHEETS_EPIC_VALUE } from '../../../../Workspace/epics/stylesheets/valueActionType';
import { getOffScreenEditor, resetBrowserFocus } from "./getOffScreenEditor";
import { getEditorState } from "../editorUtils/getEditorState";
import { tinyMceSelectAllContent } from "../editorUtils/methods/helpers/selectAllContent";
import { getEditorContent } from "../editorUtils/methods/getters/getEditorContent";
import { makeActionForwardToSelectedComponent } from "../../../../../redux/forwardTo";
import { epicUpdaterReducersByConditionActionType } from "../tinyMceStateEpic";
import {
    incCurrentMultiProcessingContentIndex,
    setCurrentMultiProcessingContentIndex,
    setMultiProcessingActionCmd,
    setMultiProcessingContentsArr,
    setMultiProcessingInProgress,
    setMultiProcessingUpdatedContent,
    setMultiProcessingUpdatedContentsArr,
    tinyMceEpicDefaultScope
} from "../models/scope";
import type { TinyMceEpicUpdater } from "../flowTypes";
import { createScheduleAction } from "../../../../Workspace/epics/resizeDecos/actionCreators";
import { EditModeComponentIdSelector } from "../../../../Workspace/epics/componentsEval/selectorActionTypes";
import { COLOR_THEME_SITE_SETTINGS_EPIC_VALUE } from "../../../../SiteSettings/ColorThemeData/colorThemeSiteSettingsVAT";

const
    updateScopeOnMultiProcessingStart = (contentsArr, actionCmd, scope) => R.pipe(
        setMultiProcessingInProgress(true),
        setMultiProcessingContentsArr(contentsArr),
        setMultiProcessingUpdatedContentsArr([]),
        setMultiProcessingActionCmd(actionCmd),
        setCurrentMultiProcessingContentIndex(0)
    )(scope),
    resetMultiProcessingScope = R.pipe(
        setMultiProcessingInProgress(false),
        setMultiProcessingContentsArr(tinyMceEpicDefaultScope.multiProcessingContentsArr),
        setMultiProcessingUpdatedContentsArr(tinyMceEpicDefaultScope.multiProcessingUpdatedContentsArr),
        setCurrentMultiProcessingContentIndex(tinyMceEpicDefaultScope.currentMultiProcessingContentIndex)
    ),
    onTinyMceApplyChangeForMultipleInstancesUpdater: TinyMceEpicUpdater = {
        conditions: [
            TINY_MCE_APPLY_CHANGE_FOR_MULTIPLE_INSTANCES
        ],
        reducer: ({ values: [{ contentsArr, actionCmd }], state, scope }) => {
            const epicUpdaterReducerForCurrentCmd = epicUpdaterReducersByConditionActionType[actionCmd.type];

            if (!epicUpdaterReducerForCurrentCmd) {
                throw new Error(`
                    ${TINY_MCE_APPLY_CHANGE_FOR_MULTIPLE_INSTANCES} updater for action: ${actionCmd.type} not found
                `);
            }

            let actionToDispatch = { type: TINY_MCE_MULTI_PROCESSING_STEP };

            if (contentsArr.length > 1) {
                actionToDispatch = createScheduleAction(actionToDispatch);
            }

            return {
                state,
                scope: updateScopeOnMultiProcessingStart(contentsArr, actionCmd, scope),
                actionToDispatch
            };
        }
    },
    updateContentForMultiProcessingStep = ({ content, scope, site, stylesheets, autoColorMode }) => {
        if (!scope.multiProcessingActionCmd || !content) {
            return content;
        }

        const
            actionCmd = scope.multiProcessingActionCmd,
            editor = getOffScreenEditor(),
            epicUpdaterReducerForCurrentCmd =
                epicUpdaterReducersByConditionActionType[actionCmd.type];

        // create editor and set content

        editor.setContent(content);

        const editorState = getEditorState({ editor, site, stylesheets, editorWidth: 0, autoColorMode }, false);

        tinyMceSelectAllContent(editor);

        epicUpdaterReducerForCurrentCmd({
            values: [actionCmd.payload],
            editor,
            state: editorState,
            scope: { ...scope, processingMultipleEditors: true }
        });

        return getEditorContent(editor);
    },
    multiProcessingStepUpdater: TinyMceEpicUpdater = {
        conditions: [
            siteDataReceiveOnlyExternalValueActionType,
            receiveOnly(STYLESHEETS_EPIC_VALUE),
            receiveOnly(COLOR_THEME_SITE_SETTINGS_EPIC_VALUE),
            TINY_MCE_MULTI_PROCESSING_STEP,
        ],
        reducer: ({ values: [site, stylesheets, { autoColorMode }], state, scope }) => {
            if (!scope.multiProcessingInProgress) {
                return { state, scope };
            }
            const
                executionStarted = Date.now();

            let
                updatedScope = scope,
                everythingProcessed = false;

            do {
                const
                    content = updatedScope.multiProcessingContentsArr[updatedScope.currentMultiProcessingContentIndex],
                    updatedContent = updateContentForMultiProcessingStep({
                        content, scope: updatedScope, site, stylesheets, autoColorMode
                    });

                updatedScope = R.pipe(
                    setMultiProcessingUpdatedContent(updatedContent),
                    incCurrentMultiProcessingContentIndex
                )(updatedScope);

                everythingProcessed = updatedScope.currentMultiProcessingContentIndex ===
                    updatedScope.multiProcessingContentsArr.length;
            } while ((Date.now() - executionStarted) < 50 && !everythingProcessed);

            // TODO WBTGEN-8816 Put protection from infinity loop for table multiprocessing

            if (everythingProcessed) {
                resetBrowserFocus();
                return {
                    state,
                    scope: resetMultiProcessingScope(updatedScope),
                    actionToDispatch: makeActionForwardToSelectedComponent({
                        type: TINY_MCE_CHANGE_FOR_MULTIPLE_INSTANCES_APPLIED,
                        payload: {
                            updatedContentsArr: updatedScope.multiProcessingUpdatedContentsArr
                        }
                    })
                };
            } else {
                return {
                    state,
                    scope: updatedScope,
                    actionToDispatch: createScheduleAction({
                        type: TINY_MCE_MULTI_PROCESSING_STEP,
                        amendTo: TABLE_CELL_GLOBAL_STYLE_UPDATED,
                    })
                };
            }
        }
    },
    cancelMultiProcessingOnComponentIsNotInEditModeAnyMoreUpdater: TinyMceEpicUpdater = {
        conditions: [
            EditModeComponentIdSelector
        ],
        reducer: ({ values: [editModeComponentId], state, scope }) => {
            if (scope.multiProcessingInProgress && editModeComponentId === null) {
                return {
                    state,
                    scope: resetMultiProcessingScope(scope),
                    actionToDispatch: { type: TINY_MCE_MULTI_PROCESSING_CANCELED }
                };
            }

            return {
                state,
                scope
            };
        }
    };

export {
    onTinyMceApplyChangeForMultipleInstancesUpdater,
    multiProcessingStepUpdater,
    cancelMultiProcessingOnComponentIsNotInEditModeAnyMoreUpdater
};
