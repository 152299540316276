import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import cx from "classnames";
import { CheckIcon, HorizontalRuleIcon } from "../../../../common/icons";
import common from "../../../../../../../wbtgen/src/view/Theme/common.css";
import { Tooltip } from "../../../Tooltip";
import { SubscriptionType } from "../../../../../../../server/shared/subscriptionStatus/constants";
import imageStyles from "../../../Images/imageStyle.css";

const TOOL_TIPS = {
    PREMIUM_WIDGETS: "msg: upgradeDialog.toolTip.premiumWidgets {All widgets, including PayPal buttons, Spotify, Vimeo, and more.}",
    STANDARD_WIDGETS: "msg: upgradeDialog.toolTip.standardWidgets {Google Maps and YouTube widgets only.}",
    WIDGETS: "msg: upgradeDialog.toolTip.widgets {Easily integrate third-party media on your website.}",
    BACKUP_RESTORE: "msg: upgradeDialog.toolTip.backupRestore {Securely save your current website's content and easily revert to this version anytime you need.}",
    EMBED_CUSTOM_CODE: "msg: upgradeDialog.toolTip.embedCustomCode {Add advanced features and integrations by embedding custom HTML code.}",
    SELF_HOSTED_VIDEO: "msg: upgradeDialog.toolTip.selfHostedVideo {Upload and display your own video content.}",
    HD_IMAGE: "msg: upgradeDialog.toolTip.hdImage {Display high-definition images for sharper, clearer visuals on any device.}",
    WRITING_ASSISTANCE: "msg: upgradeDialog.toolTip.writingAssistance {Tell us what you want to write about and get customized, AI-generated website texts within seconds.}",
    MOBILE_EDITOR: "msg: upgradeDialog.toolTip.mobileEditor {Make your website mobile-optimized with responsive design.}",
    GOOGLE_ANALYTICS: "msg: upgradeDialog.toolTip.googleAnalytics {One-step integration to track website visitors on Google Analytics.}",
    FACEBOOK_PIXEL: "msg: upgradeDialog.toolTip.facebookPixel {One-step integration to build an audience for Facebook ads.}",
    SEO_SCAN: "msg: upgradeDialog.toolTip.seoScan {Optimize your website for higher ranking in search results.}",
    SEO_SETTINGS: "msg: upgradeDialog.toolTip.seoSettings {Control how your website appears in search results.}",
    SITE_ANALYTICS: "msg: upgradeDialog.toolTip.siteAnalytics {Get to know your visitors and their journey on your website.}",
    PRODUCT_VARIATIONS: "msg: upgradeDialog.toolTip.productVariations {Expand your shop's offering by creating multiple variants of a product.}",
    PAYMENT_METHODS: "msg: upgradeDialog.toolTip.paymentMethods {Includes credit cards, PayPal, Apple Pay, Google Pay, Klarna, and others.}",
    MANUAL_PAYMENTS: "msg: upgradeDialog.toolTip.manualPayments {Create and add your own custom payment methods.}",
    VAT_HANDLING: "msg: upgradeDialog.toolTip.vatHandling {Set up custom VAT rates by shipping destination and/or product category.}",
    APP_INTEGRATIONS: "msg: upgradeDialog.toolTip.appIntegrations {Integrate with third-party apps to run your business more efficiently.}",
    CONTACT: "msg: upgradeDialog.toolTip.contact {See an overview of your customers and their order history.}",
    DESIGN_CUSTOMIZATION: "msg: upgradeDialog.toolTip.designCustomization {Customize the way your customers see your products.}",
    PRODUCT_SEO: "msg: upgradeDialog.toolTip.productSEO {Manage how your products show up in search results.}",
    MOBILE_APP: "msg: upgradeDialog.toolTip.mobileApp {Manage your shop orders and products on the go with our own Companion app.}",
};

const useStyles = makeStyles(
    (theme) => ({
        toolTipContainer: {
            display: "flex",
            gap: "8px"
        },
        centerAligned: {
            justifyContent: "center"
        },
        tooltip: {
            margin: "0px",
            padding: "8px 10px",
            fontSize: "12px",
            lineHeight: "16px",
            backgroundColor: theme.palette.custom.colorGray_2b,
            marginBottom: "8px !important"
        },
        arrow: {
            color: theme.palette.custom.colorGray_2b
        },
    }),
    { name: "upgradePlansFeatures" }
);

const { ECOMMERCE, PREMIUM, NORMAL, BASIC } = SubscriptionType;
const SUBSCRIPTIONS = [ECOMMERCE, PREMIUM, NORMAL, BASIC];

export const getFeaturesList = (intl, plans) => {
    const
        classes = useStyles(),
        unlimited = intl.msgJoint("msg: common.unlimited {Unlimited}"),
        upTo3 = intl.msgJoint("msg: common.upTo3 {Up to 3}"),
        standardText = "msg: common.standard {Standard}",
        premiumText = "msg: common.premium {Premium}",
        standard = intl.msgJoint(standardText),
        premium = intl.msgJoint(premiumText),
        checkMark = <CheckIcon sx={{ fontSize: 16, color: common.colorGray_3c }} />,
        hyphen = <HorizontalRuleIcon sx={{ fontSize: 14, color: common.colorGray_3c }} />;

    const addToolTip = (content, tipContent, centerAligned = false) => {
        return <div className={cx(classes.toolTipContainer, { [classes.centerAligned]: centerAligned })}>
            <div>{intl.msgJoint(content)}</div>
            <Tooltip
                title={intl.msgJoint(tipContent)}
                placement="top"
                classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.arrow
                }}
                enterTouchDelay={0}
                leaveTouchDelay={4000}
            >
                <div className={imageStyles.info} />
            </Tooltip>
        </div>;
    };

    const features = [
        {
            name: intl.msgJoint("msg: upgradeDialog.section.websiteFeatures {Website features}"),
            rows: [
                [intl.msgJoint("msg: upgradeDialog.feature.publishedPages {Published pages}"), unlimited, unlimited, intl.msgJoint("msg: common.upTo5 {Up to 5}"), 1],
                [addToolTip("msg: common.backupAndRestore {Backup and Restore}", TOOL_TIPS.BACKUP_RESTORE), checkMark, checkMark, hyphen, hyphen],
                [addToolTip("msg: premium.feature.premiumWidgets {Widgets}", TOOL_TIPS.WIDGETS), addToolTip(premiumText, TOOL_TIPS.PREMIUM_WIDGETS, true), premium, addToolTip(standardText, TOOL_TIPS.STANDARD_WIDGETS, true), standard],
                [addToolTip("msg: upgrade.plan.offer.embed.custom.code {Embed custom code}", TOOL_TIPS.EMBED_CUSTOM_CODE), checkMark, checkMark, hyphen, hyphen],
                [intl.msgJoint("msg: whatsNew.42.title {Video background}"), checkMark, checkMark, hyphen, hyphen],
                [addToolTip("msg: upgradeDialog.feature.selfHostedVideo {Self hosted video}", TOOL_TIPS.SELF_HOSTED_VIDEO), checkMark, checkMark, checkMark, hyphen],
                [addToolTip("msg: common.hdImagesSupport {HD image support}", TOOL_TIPS.HD_IMAGE), checkMark, checkMark, checkMark, hyphen],
                [addToolTip("msg: common.aIWritingAssitance {AI writing assistant}", TOOL_TIPS.WRITING_ASSISTANCE), checkMark, checkMark, checkMark, checkMark],
                [intl.msgJoint("msg: upgrade.plan.offer.free.images {Free images}"), checkMark, checkMark, checkMark, checkMark],
                [addToolTip("msg: common.mobileEditor {Mobile editor}", TOOL_TIPS.MOBILE_EDITOR), checkMark, checkMark, checkMark, checkMark],
            ]
        },
        {
            name: intl.msgJoint("msg: upgradeDialog.section.businessAndAnalytics {Business and analytics}"),
            rows: [
                [addToolTip("msg: ecommerce.feature.ga {Google Analytics}", TOOL_TIPS.GOOGLE_ANALYTICS), checkMark, hyphen, hyphen, hyphen],
                [intl.msgJoint("msg: gmb.googleMyBusiness {Google My Business}",), checkMark, hyphen, hyphen, hyphen],
                [intl.msgJoint("msg: common.googleReviews {Google reviews}"), checkMark, hyphen, hyphen, hyphen],
                [addToolTip("msg: ecommerce.feature.fbPixel {Facebook Pixel}", TOOL_TIPS.FACEBOOK_PIXEL), checkMark, hyphen, hyphen, hyphen],
                [addToolTip("msg: common.marketgoo {SEO scan}", TOOL_TIPS.SEO_SCAN), checkMark, hyphen, hyphen, hyphen],
                [addToolTip("msg: common.seoSettings {SEO settings}", TOOL_TIPS.SEO_SETTINGS), checkMark, checkMark, checkMark, checkMark],
                [addToolTip("msg: upgrade.plans.dialog.premium.img2.header {Site analytics}", TOOL_TIPS.SITE_ANALYTICS), checkMark, checkMark, checkMark, checkMark]
            ]
        },
        {
            name: intl.msgJoint("msg: onlineShop {Online Shop}"),
            rows: [
                [intl.msgJoint("msg: onlineShop.numberOfProducts {Number of products}"), unlimited, upTo3, upTo3, upTo3],
                [addToolTip("msg: onlineShop.productVariations {Product variations}", TOOL_TIPS.PRODUCT_VARIATIONS), checkMark, hyphen, hyphen, hyphen],
                [addToolTip("msg: onlineShop.paymentMethods {10+ payment methods}", TOOL_TIPS.PAYMENT_METHODS), checkMark, checkMark, checkMark, checkMark],
                [addToolTip("msg: onlineShop.manualPayments {Manual payments}", TOOL_TIPS.MANUAL_PAYMENTS), checkMark, checkMark, checkMark, checkMark],
                [addToolTip("msg: onlineShop.vatHandling {VAT Handling}", TOOL_TIPS.VAT_HANDLING), checkMark, checkMark, checkMark, checkMark],
                [addToolTip("msg: onlineShop.appIntegrations {App integrations}", TOOL_TIPS.APP_INTEGRATIONS), checkMark, checkMark, checkMark, checkMark],
                [intl.msgJoint("msg: onlineShop.analytics {Shop analytics}"), checkMark, checkMark, checkMark, checkMark],
                [addToolTip("msg: common.contacts {Contacts}", TOOL_TIPS.CONTACT), checkMark, checkMark, checkMark, checkMark],
                [addToolTip("msg: onlineShop.designCustomisation {Design customisation}", TOOL_TIPS.DESIGN_CUSTOMIZATION), checkMark, checkMark, checkMark, checkMark],
                [addToolTip("msg: onlineShop.seo {Product SEO}", TOOL_TIPS.PRODUCT_SEO), checkMark, checkMark, checkMark, checkMark],
                [intl.msgJoint("msg: onlineShop.discount {Discount codes}"), checkMark, checkMark, checkMark, checkMark],
                [intl.msgJoint("msg: onlineShop.giftcard {Gift card creation}"), checkMark, checkMark, checkMark, checkMark],
                [intl.msgJoint("msg: onlineShop.digitalProducts {Digital products}"), checkMark, checkMark, checkMark, checkMark],
                [intl.msgJoint("msg: onlineShop.orderOperations {Order creation and editing}"), checkMark, checkMark, checkMark, checkMark],
                [intl.msgJoint("msg: onlineShop.shippingRates {Custom shipping rates}"), checkMark, checkMark, checkMark, checkMark],
                [addToolTip("msg: common.mobileApp {Mobile app}", TOOL_TIPS.MOBILE_APP), checkMark, checkMark, checkMark, checkMark],
            ]
        }
    ];

    if (plans?.length === 1 || plans?.length === 2) {
        const index = SUBSCRIPTIONS.findIndex(subscription => subscription === plans[0].addonProductToUpgrade);

        return features.map(section => {
            return {
                name: section.name,
                rows: section.rows.map(row => {
                    if (plans.length === 1) return [row[0], row[index + 1]];

                    return [row[0], row[index + 1], row[index + 2]];
                })
            };
        });
    }

    return features;
};
