export const
    WORKSPACE_DID_MOUNT = 'WORKSPACE_DID_MOUNT',
    WORKSPACE_SCROLL_AND_SYNC = 'WORKSPACE_SCROLL_AND_SYNC',
    SET_COMPONENT_ADJUSTMENT_DATA = 'SET_COMPONENT_ADJUSTMENT_DATA', // TODO Drop this action once menu using batched version WBTGEN-9549
    SET_COMPONENT_ADJUSTMENT_DATA_BATCHED = 'SET_COMPONENT_ADJUSTMENT_DATA_BATCHED',
    WORKSPACE_NEW_COMPONENT_ADDED = 'WORKSPACE_NEW_COMPONENT_ADDED',
    WORKSPACE_NEW_SECTION_OR_BLOCK_INSERTED = 'WORKSPACE_NEW_SECTION_OR_BLOCK_INSERTED',
    WORKSPACE_SCROLL_AFTER_SECTION_MOVEMENT = 'WORKSPACE_SCROLL_AFTER_SECTION_MOVEMENT',
    WORKSPACE_SCROLL_TO_SELECTED_COMPONENT = 'WORKSPACE_SCROLL_TO_SELECTED_COMPONENT',
    WORKSPACE_SCROLL_TO_COMPONENT = 'WORKSPACE_SCROLL_TO_COMPONENT',
    PROP_PANEL_CHANGE_COMPONENT_WIDTH = 'PROP_PANEL_CHANGE_COMPONENT_WIDTH',
    PROP_PANEL_CHANGE_COMPONENT_HEIGHT = 'PROP_PANEL_CHANGE_COMPONENT_HEIGHT',
    WORKSPACE_SCROLL = 'WORKSPACE_SCROLL',
    WORKSPACE_LEFT_MOUSE_DOWN = 'WORKSPACE_LEFT_MOUSE_DOWN',
    WORKSPACE_CLICK = 'WORKSPACE_CLICK',
    WORKSPACE_DOUBLE_CLICK = 'WORKSPACE_DOUBLE_CLICK',
    WORKSPACE_ON_MOUSE_LEAVE = 'WORKSPACE_ON_MOUSE_LEAVE',
    WORKSPACE_ON_MOUSE_ENTER = 'WORKSPACE_ON_MOUSE_ENTER',
    GHOST_COMPONENTS_HEAD_SECTION_MOUSE_OVER = 'GHOST_COMPONENTS_HEAD_SECTION_MOUSE_OVER',
    GHOST_COMPONENTS_HEAD_SECTION_MOUSE_LEAVE = 'GHOST_COMPONENTS_HEAD_SECTION_MOUSE_LEAVE',
    GHOST_COMPONENTS_BODY_END_SECTION_MOUSE_OVER = 'GHOST_COMPONENTS_BODY_END_SECTION_MOUSE_OVER',
    GHOST_COMPONENTS_BODY_END_SECTION_MOUSE_LEAVE = 'GHOST_COMPONENTS_BODY_END_SECTION_MOUSE_LEAVE',
    MODE_MASK_MOUSE_DOWN = 'MODE_MASK_MOUSE_DOWN',
    MODE_MASK_CLICK = 'MODE_MASK_CLICK',
    SELECT_COMPONENTS_INSIDE_BBOX = 'SELECT_COMPONENTS_INSIDE_BBOX',

    SELECT_ALL_COMPONENTS = 'SELECT_ALL_COMPONENTS',

    SELECTED_COMPONENT_BRING_TO_FRONT = 'SELECTED_COMPONENT_BRING_TO_FRONT',
    SELECTED_COMPONENT_SEND_FORWARD = 'SELECTED_COMPONENT_SEND_FORWARD',
    SELECTED_COMPONENT_SEND_BACKWARDS = 'SELECTED_COMPONENT_SEND_BACKWARDS',
    SELECTED_COMPONENT_SEND_TO_BACK = 'SELECTED_COMPONENT_SEND_TO_BACK',

    OPEN_PROPERTIES_PANEL = 'OPEN_PROPERTIES_PANEL',

    UPDATE_COMPONENT_PIN = 'UPDATE_COMPONENT_PIN',

    MOVE_SELECTED_COMPONENTS_TO_PAGE = 'MOVE_SELECTED_COMPONENTS_TO_PAGE',
    MOVE_SELECTED_COMPONENTS_TO_TEMPLATE = 'MOVE_SELECTED_COMPONENTS_TO_TEMPLATE',

    DUPLICATE_SELECTED_COMPONENTS = 'DUPLICATE_SELECTED_COMPONENTS',
    DUPLICATE_COMPONENTS_BY_IDS = 'DUPLICATE_COMPONENTS_BY_IDS',
    DUPLICATE_SELECTED_COMPONENTS_SILENT = 'DUPLICATE_SELECTED_COMPONENTS_SILENT',

    CUT_SELECTED_COMPONENTS = 'CUT_SELECTED_COMPONENTS',
    COPY_SELECTED_COMPONENTS = 'COPY_SELECTED_COMPONENTS',
    ADD_SELECTED_COMPONENTS = 'ADD_SELECTED_COMPONENTS',
    PASTE_COPIED_COMPONENTS = 'PASTE_COPIED_COMPONENTS',
    PASTE_FROM_SYSTEM_CLIPBOARD = 'PASTE_FROM_SYSTEM_CLIPBOARD',
    DELETE_SELECTED_COMPONENTS = 'DELETE_SELECTED_COMPONENTS',
    DELETE_SELECTED_SECTION = 'DELETE_SELECTED_SECTION',
    COMPONENTS_DELETED = 'COMPONENTS_DELETED',
    COMPONENTS_MAP_INITIALISED = 'COMPONENTS_MAP_INITIALISED',
    WORKSPACE_MOUSE_UP = 'WORKSPACE_MOUSE_UP',

    SELECTED_COMPONENT_WRAP_TOP_LEFT = 'SELECTED_COMPONENT_WRAP_TOP_LEFT',
    SELECTED_COMPONENT_WRAP_TOP_RIGHT = 'SELECTED_COMPONENT_WRAP_TOP_RIGHT',
    SELECTED_COMPONENT_WRAP_TO_CENTER = 'SELECTED_COMPONENT_WRAP_TO_CENTER',
    SELECTED_COMPONENT_WRAP_ABOVE = 'SELECTED_COMPONENT_WRAP_ABOVE',
    SELECTED_COMPONENT_WRAP_BELOW = 'SELECTED_COMPONENT_WRAP_BELOW',
    SELECTED_COMPONENT_UNWRAP = 'SELECTED_COMPONENT_UNWRAP',
    WORKSPACE_READY = 'WORKSPACE_READY';
