import { getSelectedComponentsIds } from './getters';
import type { EpicState } from './flowTypes';
import { componentsMapSelector } from './selectorActionTypes';

export const isStickyToHeader = (component: Record<string, any>) => {
    return !!component.isStickyToHeader;
};

export const selectedComponentIsStickyToHeader = (componentsEvalEpicState: EpicState) => {
    const selectedComponentsIds = getSelectedComponentsIds(componentsEvalEpicState);
    const componentsMap = componentsMapSelector(componentsEvalEpicState);
    const selectedComponent = componentsMap[selectedComponentsIds[0]];

    if (!selectedComponent) return false;

    return isStickyToHeader(selectedComponent);
};
