import {
    makeCombineReducer,
    makePayloadToSetterReducer,
    makeDefaultStateReducers,
    makePathPayloadToSetterReducer,
    makeValuePathReducer,
    makeIsLoadingReducer
} from "../../../../redux/makeReducer";
import defaultState from "./defaultState";
import * as ActionTypes from '../actions';
import { NewPageTab } from "../constants";
import makeLinkPageReducer from "../../linkPageForm/reducer/makeLinkPageReducer";
import makeSectionLinkReducer from "../../sectionLinkForm/reducer/makeSectionLinkReducer";
import { Lit } from "../../../../lit";
import type { NewPageDialogState } from "../flowTypes";
import { NewPageDialogPath as Path } from "../paths";
import { DataRepositorySite } from "../../../../../dal/model";
import {
    DUPLICATE_PAGE_TEMPLATE_SUCCESS_ACTION
} from "../../PageTemplateSelector/actions";

const linkPageActions = {
    SET_LINK_TO_FILE_OR_PAGE: ActionTypes.NEW_PAGE_LINK_SET_LINK_TO_FILE_OR_PAGE,
    UNSET_LINK_TO_FILE_OR_PAGE: ActionTypes.NEW_PAGE_LINK_UNSET_LINK_TO_FILE_OR_PAGE,
    OPEN_NEW_WINDOW_TOGGLE: ActionTypes.NEW_PAGE_LINK_OPEN_IN_NEW_WINDOW_TOGGLE,
    URL_CHANGE: ActionTypes.NEW_PAGE_LINK_URL_CHANGE,
    NAME_CHANGE: ActionTypes.NEW_PAGE_LINK_NAME_CHANGE,
    IS_HIDDEN_TOGGLE: ActionTypes.NEW_PAGE_LINK_IS_HIDDEN_TOGGLE
};

const sectionLinkActions = {
    NAME_CHANGE: ActionTypes.NEW_SECTION_LINK_NAME_CHANGE,
    PAGE_CHANGE: ActionTypes.NEW_SECTION_LINK_PAGE_CHANGE,
    SECTION_CHANGE: ActionTypes.NEW_SECTION_LINK_SECTION_CHANGE,
    HIDDEN_TOGGLE: '',
    RESET: '',
};

export default makeCombineReducer({
    combineReducers: {
        ...makeDefaultStateReducers(defaultState),
        [NewPageTab.LINK_PAGE]: makeLinkPageReducer(linkPageActions),
        [NewPageTab.SECTION_LINK]: makeSectionLinkReducer(sectionLinkActions)
    },
    handleAllReducers: [
        makeIsLoadingReducer(
            [
                ActionTypes.LOAD_PAGE_LAYOUTS_SITE_REQUEST_ACTION,
                ActionTypes.LOAD_PAGE_LAYOUTS_SITE_SUCCESS_ACTION,
                ActionTypes.LOAD_PAGE_LAYOUTS_SITE_FAILURE_ACTION
                // ActionTypes.LOAD_NEW_PAGE_LAYOUT_REPOSITORY_SITE_REQUEST_ACTION,
                // ActionTypes.LOAD_NEW_PAGE_LAYOUT_REPOSITORY_SITE_SUCCESS_ACTION,
                // ActionTypes.LOAD_NEW_PAGE_LAYOUT_REPOSITORY_SITE_FAILURE_ACTION
            ],
            Path.isLayoutLoading
        )
    ],
    handleActions: {
        [ActionTypes.LOAD_PAGE_LAYOUTS_SITE_SUCCESS_ACTION]:
        // [ActionTypes.LOAD_NEW_PAGE_LAYOUT_REPOSITORY_SITE_SUCCESS_ACTION]:
            (state: NewPageDialogState, action: Record<string, any>) => {
                const repoSite: DataRepositorySite = action.payload;
                return {
                    ...state,
                    [Lit.layoutPage]: {
                        ...state[Lit.layoutPage],
                        [Lit.repositorySiteData]: repoSite,
                        // [Lit.selectedLayout]: repoSite.getBlankPageLayout()
                        [Lit.selectedLayout]: null
                    }
                };
            },
        [ActionTypes.NEW_PAGE_SWITCH_TAB]: makePayloadToSetterReducer('activeTab'),
        [ActionTypes.NEW_PAGE_LAYOUT_SELECTED]: makePathPayloadToSetterReducer(
            [NewPageTab.LAYOUT_PAGE, 'selectedLayout']
        ),
        [ActionTypes.NEW_PAGE_TEMPLATE_SELECTED_ACTION]: (state: NewPageDialogState, action: Record<string, any>) => {
            const
                { templates } = state,
                selectedTemplate = templates.find(t => t.id === action.payload);

            if (!selectedTemplate) {
                throw new Error(`Unknown template id: ${action.payload}`);
            }

            return {
                ...state,
                [Lit.layoutPage]: {
                    ...state[Lit.layoutPage],
                    selectedTemplate
                }
            };
        },
        [ActionTypes.LOAD_NEW_PAGE_LAYOUT_TEMPLATES_REQUEST_ACTION]: makeValuePathReducer(
            true,
            [Lit.templatesAreLoading]
        ),
        [ActionTypes.LOAD_NEW_PAGE_LAYOUT_TEMPLATES_SUCCESS_ACTION]: (state: NewPageDialogState, action: Record<string, any>) =>
            ({
                ...state,
                [Lit.templates]: action.payload,
                [Lit.templatesAreLoading]: false
            }),
        [ActionTypes.LOAD_NEW_PAGE_LAYOUT_TEMPLATES_FAILURE_ACTION]: makeValuePathReducer(
            false,
            [Lit.templatesAreLoading]
        ),
        // TOOD: will be refactored in WBTGEN-6945
        [DUPLICATE_PAGE_TEMPLATE_SUCCESS_ACTION]: (state: NewPageDialogState, action: Record<string, any>) => {
            const
                { payload: newTemplateRef } = action,
                { templates } = state;

            return {
                ...state,
                [Lit.templates]: [...templates, newTemplateRef]
            };
        }
    }
});
