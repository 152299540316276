import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import * as Actions from "./actionTypes";
import { EXTENDED_PANEL_AVAILABLE_WIDTH_MINUS_PADDING } from "../../constants";
import { SECTIONS_BLOCKS_FETCHED } from "../../actionTypes";
import { WIDGETS_ALL, WidgetsCategories } from "../../../oneweb/Widgets/constants";

const initialState = {
    blocks: [],
    filterKey: WIDGETS_ALL
};

export const extendedPanelWidgetsEpic = makeEpic({
    defaultState: initialState,
    valueActionType,
    updaters: [
        {
            conditions: [
                Actions.SET_FILTER_VALUE
            ],
            reducer: ({ state, values: [key] }) => {
                return {
                    state: {
                        ...state,
                        filterKey: key
                    }
                };
            },
        },
        {
            conditions: [
                SECTIONS_BLOCKS_FETCHED
            ],
            reducer: ({ values: [{ blocks }], state }) => ({
                state: {
                    ...state,
                    blocks: blocks ? blocks.filter(category => WidgetsCategories[category.key]) : []
                }
            })
        },
        {
            conditions: [Actions.EXTENDED_PANEL_WIDGETS_MEASURED_SIZE],
            reducer: ({ values: [{ widgetsStyleName, width, height }], state }) => {
                const scale = (width > EXTENDED_PANEL_AVAILABLE_WIDTH_MINUS_PADDING) ?
                    (EXTENDED_PANEL_AVAILABLE_WIDTH_MINUS_PADDING / width) : 1;
                return {
                    state: {
                        ...state,
                        [widgetsStyleName]: {
                            ...state[widgetsStyleName],
                            scale,
                            scaledHeight: height * scale
                        }
                    }
                };
            }
        },
        {
            conditions: [Actions.EXTENDED_PANEL_WIDGETS_MEASURED_HEIGHT],
            reducer: ({ values: [{ widgetsStyleName, height }], state }) => ({
                state: {
                    ...state,
                    [widgetsStyleName]: {
                        ...state[widgetsStyleName],
                        height
                    }
                }
            })
        },
        {
            conditions: [Actions.EXTENDED_PANEL_BTN_WIDGETS_MEASURED_WIDTH],
            reducer: ({ values: [{ widgetsStyleName, width, height }], state }) => ({
                state: {
                    ...state,
                    [widgetsStyleName]: {
                        ...state[widgetsStyleName],
                        width,
                        height,
                        scale: width > EXTENDED_PANEL_AVAILABLE_WIDTH_MINUS_PADDING ?
                            EXTENDED_PANEL_AVAILABLE_WIDTH_MINUS_PADDING / width : 1
                    }
                }
            })
        },
        {
            conditions: [Actions.EXTENDED_PANEL_WIDGETS_CLEAR_DATA],
            reducer: ({ state }) => ({ state: { blocks: state.blocks, filterKey: state.filterKey } })
        }
    ]
});
