import React from 'react';

type TimelineProps = {
    link: string;
    height?: number;
    locale?: string;
    theme ?: "dark";
    doNotTrack ?: boolean;
}

const getIframeHref = (href: string): string => {
    const https = 'https://';
    return href.startsWith(https) ? href : `${https}${href}`;
};

const getInnerText = (href: string): string => {
    if (href.startsWith('https://twitter.com/')) {
        const match = href.match(/https:\/\/twitter\.com\/([^\/]+)/);
        if (Array.isArray(match)) {
            return (href.includes('/lists/') ? `A Twitter List by` : `Tweets by`) + ` @${match[1]}`;
        }
    }

    return href;
};

export const Timeline = (props: TimelineProps) => {
    const
        iframeHref = getIframeHref(props.link),
        widgetProps = {
            href: iframeHref,
            className: "twitter-timeline",
            'data-width': '100%',
            'data-height': props.height,
        };

    if (props.theme) widgetProps['data-theme'] = props.theme;
    if (props.locale) widgetProps['data-locale'] = props.locale;
    if (props.doNotTrack) widgetProps['data-dnt'] = "true";

    return (<a {...widgetProps}>{getInnerText(iframeHref)}</a>);
};

