import React from 'react';
import { DATA_TEST_ID } from '../constants';
import { getIframeSrc } from '../utils';
import '../../view/Preview.css';
import { type BingMapsParams } from '../types';

const BingMapsView = (props: BingMapsParams) => (
    <div className="widget" style={{ overflow: "hidden" }}>
        <iframe
            data-testid={DATA_TEST_ID}
            src={getIframeSrc(props)}
            width={props.width}
            height={props.height}
            frameBorder="0"
            scrolling="no"
        />
    </div>
);

export { BingMapsView };
