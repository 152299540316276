import * as R from 'ramda';
import Rbush from 'rbush';
import GalleryKind from '../../../oneweb/Gallery/kind';
import makeEpic from '../../../../epics/makeEpic';
import { handlesUnderMouseValueActionType } from './valueActionType';
import handlesValueActionType from '../handles/valueActionType';
import mousePositionValueActionType from '../mousePositionWithRespectToTemplateArea/valueActionType';
import memo, { memoMaxDynamicNumOfArgs } from '../../../../../utils/memo';
import isMouseOverLeftPanelValueActionType from '../../../App/epics/isMouseOverLeftPanel/valueActionType';
import isMouseOverWorkspaceValueActionType from "../isMouseOverWorkspace/valueActionType";
import isMouseOverMCTAValueActionType from "../isMouseOverMCTA/valueActionType";
import { DndAddComponentVisible } from "../../../DndAddComponent/epic/selectorActionTypes";
import {
    ComponentHandleKinds,
    LeftPanel,
    TemplateLines
} from '../../../../utils/handle/kinds';
import templateLinesValueActionType from '../../../oneweb/Template/epics/templateLines/valueActionType';
import { ReceiveOnlyComponentsMap } from "../componentsEval/selectorActionTypes";
import { isBoxInModernHeaderOrFooter } from "../../../ModernLayouts/utils";
import { isHoverShiftBarHandle } from "../../../../utils/handle/index";
import { getCmpsRect } from "../componentsEval/utils";

function sortHandle(a, b) {
    return b.zIndex - a.zIndex;
}

const
    fillTree = memo((tree, handles) => {
        tree.clear();
        tree.load(handles);
    }),
    tree = new Rbush(),
    sortHandles = R.sort(sortHandle),
    defaultState = [],
    // we don't want to create new topmostHandleArray on every mouse move if it was not changed
    memoHandles = memoMaxDynamicNumOfArgs((...ids) => ids, 1);

export default makeEpic({
    defaultState,
    valueActionType: handlesUnderMouseValueActionType,
    updaters: [
        {
            conditions: [
                handlesValueActionType,
                mousePositionValueActionType,
                isMouseOverLeftPanelValueActionType,
                isMouseOverWorkspaceValueActionType,
                isMouseOverMCTAValueActionType,
                DndAddComponentVisible,
                templateLinesValueActionType,
                ReceiveOnlyComponentsMap
            ],
            reducer: ({
                values: [
                    handles,
                    { x, y },
                    isMouseOverLeftPanel,
                    isMouseOverWorkspace,
                    isMouseOverMCTA,
                    dndAddComponentVisible,
                    templateLines,
                    componentsMap
                ],
                state
            }) => {
                if (templateLines.state.distanceBetweenLines > 0) { // implies template lines are moving TODO WBTGEN-4680 integrate moving into userInteraction
                    if (state.kind === TemplateLines) { // Template lines should already by the top most handle sorter below
                        return { state };
                    }
                    // never reaches, can simply return state in this block, as it would be set by sorter, but leaving for readability
                    return { state: [{ kind: TemplateLines, componentsIds: [] }] };
                } else if (isMouseOverLeftPanel) {
                    return { state: [{ kind: LeftPanel, componentsIds: [] }] };
                } else if (isMouseOverMCTA || (!isMouseOverWorkspace && !dndAddComponentVisible)) {
                    return { state: defaultState };
                }

                fillTree(tree, handles);

                const
                    result = tree.search({
                        minX: x,
                        maxX: x,
                        minY: y,
                        maxY: y
                    });

                let sortedHandles = sortHandles(result);
                const { componentsIds = [] } = (sortedHandles[0] || {}),
                    components = componentsIds.map(id => componentsMap[id]).filter(c => !!c);
                if (components.length) {
                    if (isBoxInModernHeaderOrFooter(getCmpsRect(components), componentsMap)) {
                        sortedHandles = sortedHandles.filter(handle => handle && !isHoverShiftBarHandle(handle.kind));
                    } else if (components.find(({ kind }) => kind === GalleryKind)) {
                        sortedHandles = sortedHandles.filter(handle => handle && ComponentHandleKinds.ShiftBarBottomHover !== handle.kind);
                    }
                }
                const sortedHandlesUnderMouse = memoHandles(...sortedHandles);
                return { state: sortedHandlesUnderMouse };
            }
        }
    ]
});
