export const
    SCROLL_EFFECTS = 'SCROLL_EFFECTS',
    scrollEffectsTitle = 'msg: common.scrollEffects {Scroll effects}',
    scrollEffects = {
        noScroll: 'noScroll',
        reveal: 'reveal',
        parallax: 'parallax'
    },
    scrollEffectStates = {
        disabled: 'disabled',
        unselected: 'unselected',
        hover: 'hover',
        selected: 'selected'
    },
    scrollEffectDisabledTooltip = 'msg: common.scrollEffects.disabledTooltip {Add an image to enable scroll effects}';
