import * as R from 'ramda';
import handleActions from './handleActions/index';
import combineReducers from './combineReducers';
import reduceReducers from './reduceReducers';
import makeRequestReducer from '../../epics/request/makeReducer';
import { isFunction, getByPath, setToPath } from "../../utils/ramdaEx";
import type { Path } from '../../mappers/path';
import type {
    MakeCombineReducerConfig,
    MakeValueReducerConfig,
    BindReduceReducerConfig,
    AnyReducerWithDeps, AnyReducer
} from './flowTypes';
import type { PathAny } from "../../globalTypes";
import { NaNError } from "../../utils/error";
import { isComponentKindAllowedInHoverBox } from "../../components/oneweb/HoverBox/utils";

function makeCombineReducer(config: MakeCombineReducerConfig): AnyReducer {
    if (config.combineReducers === undefined) {
        throw new Error('combineReducersConfig should be defined');
    }

    const
        { handleAllReducers } = config,
        handleActionsConfig = config.handleActions,
        combineReducersReducer = combineReducers(config.combineReducers),
        handleAllReducer = reduceReducers(...(handleAllReducers || []).filter(x => x));

    if (handleActionsConfig === undefined) {
        if (handleAllReducers === undefined) {
            return combineReducersReducer;
        } else {
            return reduceReducers(handleAllReducer, combineReducersReducer);
        }
    } else {
        const handleActionsReducer = handleActions(handleActionsConfig,
            "TODO rewrite handle actions to not require default state");

        if (handleAllReducers === undefined) {
            return reduceReducers(combineReducersReducer, handleActionsReducer);
        } else {
            return reduceReducers(combineReducersReducer, handleAllReducer, handleActionsReducer);
        }
    }
}

function makeValueReducer(config: MakeValueReducerConfig): AnyReducer {
    const
        handleActionsConfig = config.handleActions,
        { defaultState, handleAllReducers } = config,
        handleAllReducer = reduceReducers(...(handleAllReducers || []));

    if (defaultState === undefined) {
        throw new Error('defaultState should be defined');
    }

    if (handleActionsConfig === undefined) {
        const defaultStateReducer = makeDefaultStateReducer(defaultState);

        if (handleAllReducers === undefined) {
            return defaultStateReducer;
        } else {
            return reduceReducers(defaultStateReducer, handleAllReducer);
        }
    } else {
        const handleActionsReducer = handleActions(handleActionsConfig, defaultState);

        if (handleAllReducers === undefined) {
            return handleActionsReducer;
        } else {
            return reduceReducers(handleActionsReducer, handleAllReducer);
        }
    }
}

function makeDefaultStateReducer(defaultState: any): AnyReducer {
    return (state: any): any => { // eslint-disable-line
        if (state === undefined) {
            return defaultState;
        }
        if (typeof state === 'number' && isNaN(state)) {
            throw new NaNError('NaN detected as state of a reducer.' +
                ' Redux combine reducers will always return new object if any prop is NaN, because NaN !== NaN.' +
                ' It will break reference comparision, redux rely on it heavily.');
        }
        return state;
    };
}

/* returns action payload as new state */
function payloadToStateReducer(_: any, { payload }: Action<any>): any { // eslint-disable-line
    return payload;
}

function getDefaultReducerState(reducer: AnyReducer | AnyReducerWithDeps): any {
    return reducer(undefined, { type: '@@INIT' });
}

/*
 It takes array of reducer configs
 Each reducer config is a tuple of reducer and corresponding parameters
 It returns a reducer that will call all reducers in sequence, and pass result of each reducer to next reducer
 */
function bindReduceReducers(...reducerConfigs: Array<BindReduceReducerConfig>) {
    const bindedReducers = reducerConfigs.map(reducerConfig => {
        const [reducer, ...params] = reducerConfig;

        return state => {
            const finalParams = params.map(param => {
                return isFunction(param) ? param(state) : param;
            });
            return reducer(state, ...finalParams);
        };
    });

    return reduceReducers(...bindedReducers);
}

function makeSetterReducer(propertyName: string) {
    return (state: any, value: any) => ({
        ...state,
        [propertyName]: value
    });
}

function makeActionSetterReducer(propertyName: string) {
    return (state: any, { payload }: Action<any>) => ({
        ...state,
        [propertyName]: payload[propertyName]
    });
}

function makePayloadToSetterReducer(propertyName: string) {
    return (state: any, { payload }: Action<any>) => makeSetterReducer(propertyName)(state, payload);
}

const makeValueToSetterReducer = (value: AnyValue, propertyName: string) =>
    (state: AnyValue) =>
        makeSetterReducer(propertyName)(state, value);

const makeValuePathReducer = (value: AnyValue, path: Path) =>
    (state: AnyValue) => {
        if (getByPath(path, state) === undefined) {
            throw new Error(`Unknown path: ${path.join('.')}`);
        }
        return setToPath(path, value, state);
    };

export const makePayloadToStateReducer = () => (state: AnyValue, action: Action) => action.payload;

const makePayloadPathReducer = (path: Path) =>
    (state: AnyValue, action: Action) =>
        makeValuePathReducer(action.payload, path)(state);

function makePathPayloadToSetterReducer(path: Path, payloadPath: Array<string> = []) {
    return (state: any, { payload }: Action<any>) => setToPath(path, R.path(payloadPath, payload), state);
}

function makeNegationPathPayloadToSetterReducer(path: Path) {
    return (state: any) => setToPath(path, !getByPath(path, state), state);
}

function makePathUnsetterReducer(path: Array<string>) {
    return (state: any) => R.assocPath(path, null, state);
}

function makeOnHoverDefaultStateReducer(kind: string) {
    if (!isComponentKindAllowedInHoverBox(kind)) {
        return {};
    }
    return {
        onHover: makeDefaultStateReducer(null),
    };
}

function makeComponentBaseReducers(kind: string, width?: number, height?: number) {
    return {
        id: makeDefaultStateReducer(''),
        kind: makeDefaultStateReducer(kind),
        top: makeDefaultStateReducer(0),
        left: makeDefaultStateReducer(0),
        width: makeDefaultStateReducer(width || 200),
        height: makeDefaultStateReducer(height || 80),
        orderIndex: makeDefaultStateReducer(0),
        inTemplate: makeDefaultStateReducer(false),
        relTo: makeDefaultStateReducer(null),
        relIn: makeDefaultStateReducer(null),
        relPage: makeDefaultStateReducer(null),
        relPara: makeDefaultStateReducer(null),
        wrap: makeDefaultStateReducer(false),
        ...makeOnHoverDefaultStateReducer(kind),
    };
}

function getComponentInitialStateCommonPart(kind: string, width?: number, height?: number) {
    const baseReducer = combineReducers(makeComponentBaseReducers(kind, width, height));
    return getDefaultReducerState(baseReducer);
}

export const makeIsLoadingReducer = (types: [string, string, string], path: PathAny) =>
    (state: AnyValue, action: Action) => {
        const [request, success, failure] = types;
        switch (action.type) {
            case request:
                return setToPath(path, true, state);
            case success:
                return setToPath(path, false, state);
            case failure:
                return setToPath(path, false, state);
            default:
                return state;
        }
    };

type Registy = {
    [key: string]: any
}

function makeDependsOnReducer(registry: Registy, key: string = 'dependsOn') {
    const
        recordHasDependencies = R.complement(R.propEq(key, undefined)),
        keyRecordTuples = R.filter(
            ([key, record]) => recordHasDependencies(record), // eslint-disable-line
            R.zip(R.keys(registry), R.values(registry))
        ),
        recordToCombineReducers = record => makeCombineReducer({ combineReducers: record[key] }),
        keys = R.map(R.prop(0), keyRecordTuples),
        records = R.map(R.prop(1), keyRecordTuples);

    return makeCombineReducer({
        combineReducers: R.zipObj(
            keys,
            R.map(recordToCombineReducers, records)
        )
    });
}

const
    makeDefaultStateReducers = R.mapObjIndexed(makeDefaultStateReducer),
    makePayloadToSetterReducers = R.mapObjIndexed(makePayloadToSetterReducer),
    makeNegationPathPayloadToSetterReducers = R.mapObjIndexed(makeNegationPathPayloadToSetterReducer);

export {
    reduceReducers,
    handleActions,
    makeCombineReducer,
    makeValueReducer,
    makeDefaultStateReducer,
    makeDefaultStateReducers,
    makeDependsOnReducer,
    bindReduceReducers,
    payloadToStateReducer,
    getDefaultReducerState,
    makeSetterReducer,
    makeActionSetterReducer,
    makePayloadToSetterReducer,
    makeValueToSetterReducer,
    makeValuePathReducer,
    makePayloadToSetterReducers,
    makePathPayloadToSetterReducer,
    makePayloadPathReducer,
    makeNegationPathPayloadToSetterReducer,
    makeNegationPathPayloadToSetterReducers,
    makePathUnsetterReducer,
    makeRequestReducer,
    makeComponentBaseReducers,
    combineReducers,
    getComponentInitialStateCommonPart,
};
