import { DataSectionLink } from "../../../../../dal/model";
import type { SectionLinkFormResult } from "../../sectionLinkForm/flowTypes";
import applyPatchToSitePage from "./applyPatchToSitePage";

export default (page: DataSectionLink, input: SectionLinkFormResult) => {
    const
        { pageId, sectionId, name, hidden } = input,
        patch = {
            name,
            pageId,
            hidden,
            sectionId,
        };
    applyPatchToSitePage(page, patch);
};
