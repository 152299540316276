import * as R from 'ramda';
import componentsEvalValueActionType from '../../../components/Workspace/epics/componentsEval/valueActionType';
import { FLUSH_ADJUSTMENT_CACHE } from '../../../components/Workspace/epics/componentsEval/actionTypes';
import FLUSH_INTERVAL from "./FLUSH_INTERVAL";
import isTestEnv from "../../../debug/isTestEnv";

let
    timerRef: ReturnType<typeof setTimeout> | null = null,
    startComponentsAdjustmentDataCache = null;

export default (store: Store) => (next: Dispatch) => (action: Action) => {
    const
        nextVal = next(action),
        nextState = store.getState(),
        epic = nextState.epics[componentsEvalValueActionType],
        componentsAdjustmentDataCache = epic.state.scope.componentsAdjustmentDataCache;

    /* TODO should return promise */
    if (!R.equals(componentsAdjustmentDataCache, {}) && !timerRef) {
        startComponentsAdjustmentDataCache = componentsAdjustmentDataCache;
        timerRef = setTimeout(() => {
            timerRef = null;
            if (componentsAdjustmentDataCache === startComponentsAdjustmentDataCache) {
                store.dispatch({ type: FLUSH_ADJUSTMENT_CACHE, amendToPrevious: true });
            } else {
                // Needed to execute this function again
                store.dispatch({ type: 'AGAIN' });
            }
        }, isTestEnv() ? 1 : FLUSH_INTERVAL);
    }

    return nextVal;
};
