
import {
    makeCombineReducer,
    makeDefaultStateReducers,
    makeComponentBaseReducers
} from "../../../../redux/makeReducer/index";
import * as actions from "./actionTypes";
import { WidgetsGoogleMapsKind } from "./kind";
import { defaultState, DEFAULT_HEIGHT, DEFAULT_WIDTH } from "./constants";

const reducer = makeCombineReducer(
    {
        combineReducers: {
            ...makeComponentBaseReducers(WidgetsGoogleMapsKind, DEFAULT_WIDTH, DEFAULT_HEIGHT),
            ...makeDefaultStateReducers(defaultState)
        },
        handleActions: {
            [actions.WIDGETS_GOOGLEMAPS_UPDATE_PROPS]: (component, { payload: params }) => ({
                ...component,
                ...params,
            }),
            [actions.UPDATE_ADDRESSLOCATION_FROM_PASTE_IFRAME_FIRST_RENDER]: (component, { payload: addressLocation }) => ({
                ...component, addressLocation
            }),
            [actions.WIDGETS_GOOGLE_MAPS_FULL_WIDTH_TOGGLE]: (component) => ({
                ...component, stretch: !component.stretch
            })
        }
    }
);
export { reducer };

