import * as R from 'ramda';
import type { Position } from "../../../redux/modules/flowTypes";
import type { Dimensions } from "../../../redux/modules/children/workspace/flowTypes";
import { IgnoreDialogIds } from '../constants';

type GetPositionTransformationProps = {
    currentPosition: Position;
    startPosition: Position;
    dimensions: Dimensions;
    browserDimensions: Dimensions;
}

const DialogMinViewDimensions = { top: 0, right: 100, bottom: 100, left: 100 };

const
    dropLastConfig = R.evolve({ openedDialogConfigs: (dialogConfigs) => {
        let reversedDialogConfigs = [...dialogConfigs.reverse()];
        // Find the last dialog that is not in the IgnoreDialogIds list
        let deleteDialogIndex = reversedDialogConfigs.findIndex((dialogConfig) => !IgnoreDialogIds.includes(dialogConfig.id));
        // If the dialog is not found, return the original dialogConfigs
        if (deleteDialogIndex === -1) {
            return dialogConfigs;
        }
        // Return the dialogConfigs without the last dialog
        reversedDialogConfigs.splice(deleteDialogIndex, 1);
        return reversedDialogConfigs.reverse();
    } }),
    getPositionTransformation =
        ({
            currentPosition,
            startPosition,
            dimensions: { width: dialogWidth },
            browserDimensions: { height: browserHeight, width: browserWidth }
        }: GetPositionTransformationProps) =>
            R.evolve({
                top: R.pipe(
                    R.add(currentPosition.y - startPosition.y),
                    R.clamp(DialogMinViewDimensions.top, browserHeight - DialogMinViewDimensions.bottom)
                ),
                left: R.pipe(
                    R.add(currentPosition.x - startPosition.x),
                    R.clamp(
                        (DialogMinViewDimensions.left - dialogWidth),
                        (browserWidth - DialogMinViewDimensions.right)
                    )
                )
            });

export {
    dropLastConfig,
    getPositionTransformation
};
