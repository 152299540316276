import { VALID_CONTENT_BLOCK_TAGS } from '../../../../../../oneweb/Text/constants';
import { DEFAULT_LINE_HEIGHT } from '../../constants';

import type { TinyMceEditor } from '../../../flowTypes';

export const getSelectedLineHeight = (editor: TinyMceEditor) => {
    try {
        let selectedNode: HTMLElement = editor.selection.getStart();
        if (['DIV', 'OL', 'UL'].includes(selectedNode.nodeName)) {
            selectedNode = selectedNode.querySelector(VALID_CONTENT_BLOCK_TAGS.map(t => `${t}:first-of-type`).join(','))!;
        }

        const computedStyle = window.getComputedStyle(selectedNode);
        const computedLineHeight = computedStyle.getPropertyValue('line-height');
        const computedFontSize = computedStyle.getPropertyValue('font-size');
        return (Math.round((Number.parseFloat(computedLineHeight) / Number.parseFloat(computedFontSize)) * 10)) / 10;
    } catch (e) {
        return DEFAULT_LINE_HEIGHT;
    }
};
