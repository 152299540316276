import { UserDataMigrationStatus } from "./epic/constants";
import type { UserDataMigrationStatusType } from "./flowTypes";

const
    noUserDataMigrationRequired = (status: UserDataMigrationStatusType) => {
        return status === UserDataMigrationStatus.NO_USER_DATA_MIGRATION_REQUIRED;
    },

    userDataMigratedSuccessfully = (status: UserDataMigrationStatusType) => {
        return status === UserDataMigrationStatus.USER_DATA_MIGRATION_SUCCESSFUL;
    };

export {
    noUserDataMigrationRequired,
    userDataMigratedSuccessfully
};
