import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { SelectField } from "../Common/SelectField";

import { selectLanguages } from "../../../../../oneweb/Text/epics/AITextEpic/selectors";
import { languageSelector } from "../../../Epic/selectors";
import { DYNAMIC_TEMPLATE_LANGUAGE_CHANGED } from "../../../Epic/actionTypes";

import stepStyles from "../steps.css";

type Languages = {
    map: MapT<string>,
    current: string,
};

export const LanguageField = () => {
    const dispatch = useDispatch();
    const languages: Languages = useSelector(selectLanguages);
    const dynamicLanguage = useSelector(languageSelector);
    const { map } = languages;

    let languageOptionsMap: Array<{ value: string, label: string }> = [];
    Object.keys(map).forEach((item) => {
        languageOptionsMap.push({
            value: item,
            label: map[item],
        });
    });

    const languageOptions = languageOptionsMap.sort((a, b) => {
        return (a.label > b.label ? 1 : 0) - 0.5;
    });

    const [value, setValue] = useState(dynamicLanguage);

    const handleChange = (e) => {
        const val = e.target.value;
        setValue(val);
        dispatch({ type: DYNAMIC_TEMPLATE_LANGUAGE_CHANGED, payload: val });
    };

    const fieldProps = {
        label: "msg: common.language {Language}",
        value,
        data: languageOptions,
        handleChange,
    };

    return (
        <div className={stepStyles.stepFieldContainer}>
            <SelectField {...fieldProps} />
        </div>
    );
};
