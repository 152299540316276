import makeEpic from '../../../../epics/makeEpic';
import { anyOf, receiveOnly } from "../../../../epics/makeCondition";
import valueActionType from "./valueActionType";
import { CLOSE_DIALOG, OPEN_DIALOG } from '../../../../redux/modules/actionTypes';
import type { GoogleReviewsEpicState, AdditionalReviewProps } from "../flowTypes";
import { fetchGoogleReviewData } from './googleReviewDataApi';
import {
    GOOGLE_REVIEWS_FAILURE,
    GOOGLE_REVIEWS_SUCCESS,
    ADD_GOOGLE_REVIEWS_COMPONENT,
    CANCEL_REVIEW_WIDGET_CONFIG,
    REVIEW_WIDGET_CONFIG_SUCCESS,
    RESET_REVIEW_WIDGET_CONFIG
} from './actionTypes';
import { ReviewsApiStatus, ReviewViewType, STATUS } from '../constants';
import {
    GoogleReviewsDialogId,
    ConnectToGoogleDialogId,
    ExceptionGoogleReviewsDialogId,
    FailedGoogleReviewsDialogId,
} from '../dialog/dialogIds';
import { COMPONENT_CONFIGURATION_COMPLETE } from '../../../../redux/modules/children/workspace/actionTypes';
import { replaceDialog, closeDialog } from "../../../App/actionCreators";
import {
    BUSINESS_LISTING_SITE_SETTINGS_SERVICE_EPIC_VALUE
} from '../../../SiteSettings/BusinessListing/GoogleMyBusiness/businessListingSiteSettingsServiceVAT';
import { ReceiveOnlySelectedComponentSelector } from "../../../Workspace/epics/componentsEval/selectorActionTypes";
import { openConnectToReviewDialog } from './actionCreators';
import { getRearrangedReviews, getReviewIds, getSelectedReviewIds, getSelectedReviews } from '../utils';
import { NO_REVIEWS, LISTING_NOT_VERIFIED } from '../actionTypes';

const defaultState: GoogleReviewsEpicState = {
    reviewsApiData: null,
    reviewsConfigInProgress: false,
    reviewsApiStatus: ReviewsApiStatus.NotStarted,
    changeReviewsMode: false
};

const GoogleReviewsDialogEpic = makeEpic({
    valueActionType,
    defaultState,
    updaters: [{
        conditions: [
            OPEN_DIALOG,
            receiveOnly(BUSINESS_LISTING_SITE_SETTINGS_SERVICE_EPIC_VALUE)
        ],
        reducer: ({ values: [dialog, { isConnected }], state }) => {
            const { dialogId, props = {} } = dialog;
            if (dialogId !== ConnectToGoogleDialogId) {
                return { state };
            }

            const { changeReviews = false } = props;

            if (!isConnected) {
                return { state: { ...defaultState, reviewsConfigInProgress: true, changeReviewsMode: changeReviews } };
            }
            return {
                state: { ...defaultState, reviewsApiStatus: ReviewsApiStatus.InProgress, changeReviewsMode: changeReviews },
                actionToDispatch: fetchGoogleReviewData
            };
        }
    }, {
        conditions: [
            ReceiveOnlySelectedComponentSelector,
            GOOGLE_REVIEWS_SUCCESS
        ],
        reducer: ({ values: [selectedComponent, { data }], state }) => {
            let reviewsApiData = { ...data };
            let additionalProps: AdditionalReviewProps = {
                layout: ReviewViewType.LIST,
                reviewIds: []
            };
            if (
                data &&
                data.reviewsData &&
                !(data.reviewsData.reviews && data.reviewsData.reviews.length)
            ) {
                return {
                    state: {
                        ...state,
                        reviewsConfigInProgress: false,
                        reviewsApiStatus: ReviewsApiStatus.Success,
                    },
                    actionToDispatch: replaceDialog(ExceptionGoogleReviewsDialogId, { actionType: NO_REVIEWS })
                };
            }
            if (
                state.changeReviewsMode &&
                selectedComponent &&
                selectedComponent.reviews
            ) {
                const cmpReviewIds = getReviewIds(selectedComponent.reviews);
                additionalProps = {
                    reviewIds: getSelectedReviewIds(cmpReviewIds, data.reviewsData.reviews),
                    layout: selectedComponent.layoutType
                };
            }
            if (additionalProps.reviewIds.length) {
                reviewsApiData.reviewsData.reviews = getRearrangedReviews(data.reviewsData.reviews, additionalProps.reviewIds);
            }
            return {
                state: {
                    ...state,
                    reviewsApiData,
                    reviewsApiStatus: ReviewsApiStatus.Success,
                    reviewsConfigInProgress: false,
                },
                actionToDispatch: replaceDialog(GoogleReviewsDialogId, {
                    onSaveAction: ADD_GOOGLE_REVIEWS_COMPONENT,
                    additionalProps
                })
            };
        }
    }, {
        conditions: [
            GOOGLE_REVIEWS_FAILURE
        ],
        reducer: ({ state, values: [{ status }] }) => {
            let actionToDispatch = replaceDialog(FailedGoogleReviewsDialogId);
            let reviewsConfigInProgress = false;

            if (status === STATUS.NOT_FOUND) {
                actionToDispatch = replaceDialog(ConnectToGoogleDialogId);
                reviewsConfigInProgress = true;
            } else if (status === STATUS.NOT_VERIFIED) {
                actionToDispatch = replaceDialog(ExceptionGoogleReviewsDialogId, { actionType: LISTING_NOT_VERIFIED });
            }

            return {
                state: {
                    ...state,
                    reviewsApiData: null,
                    reviewsApiStatus: ReviewsApiStatus.Failed,
                    reviewsConfigInProgress,
                },
                actionToDispatch
            };
        }
    }, {
        conditions: [
            REVIEW_WIDGET_CONFIG_SUCCESS
        ],
        reducer: ({ state }) => {
            const multipleActionsToDispatch = [
                closeDialog(),
                openConnectToReviewDialog({ changeReviews: state.changeReviewsMode })
            ];
            return { state, multipleActionsToDispatch };
        }
    }, {
        conditions: [
            ADD_GOOGLE_REVIEWS_COMPONENT,
        ],
        reducer: ({ values: [{ selectedReviewsIds, layoutType }], state }) => {
            const allReviews = state.reviewsApiData.reviewsData.reviews;
            const { storeData } = state.reviewsApiData;
            const allSelectedReviews = getSelectedReviews(selectedReviewsIds, allReviews);
            return {
                state,
                actionToDispatch: {
                    type: COMPONENT_CONFIGURATION_COMPLETE,
                    payload: { reviews: allSelectedReviews, layoutType, storeData },
                }
            };
        }
    }, {
        conditions: [CANCEL_REVIEW_WIDGET_CONFIG],
        reducer: () => {
            return {
                state: defaultState,
                actionToDispatch: closeDialog()
            };
        }
    }, {
        conditions: [
            anyOf(
                CLOSE_DIALOG,
                RESET_REVIEW_WIDGET_CONFIG
            )
        ],
        reducer: ({ state }) => {
            // if (state !== defaultState) {
            //     return { state: defaultState };
            // }
            if (state.reviewsConfigInProgress) {
                return {
                    state: {
                        ...state,
                        reviewsConfigInProgress: false
                    }
                };
            }
            return { state };
        }
    }]
});

export {
    GoogleReviewsDialogEpic
};
