import React from 'react';
import cx from 'classnames';
import { getWidgetClassname, getWidgetHtml } from '../utils';
import type { ShareButtonsCalcRenderPropsResult } from '../flowTypes';
import './ShareButtons.css';

export default (props: ShareButtonsCalcRenderPropsResult) => {
    const style: React.CSSProperties = props.isMVEFocus ? { pointerEvents: 'none' } : {};
    return (
        <div className="shareself">
            <div style={style}>
                <ul
                    className={cx(getWidgetClassname(props))}
                    dangerouslySetInnerHTML={{ __html: getWidgetHtml(props) }}
                />
            </div>
        </div>
    );
};
