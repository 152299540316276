import type { AssetsReplacements } from "../../../../utils/assetUtils";
import { COMPONENT_CONFIGURATION_CANCELED } from "../../../../redux/modules/children/workspace/actionTypes";
import {
    _FC_SET_RESOURCES, FC_RESOURCES_FETCH_SUCCESS, FC_RESOURCES_REQUEST,
    FC_SAVE
} from "../../../../redux/modules/children/fileChooser/actionTypes";
import { receiveOnly } from "../../../../epics/makeCondition";
import Resource from "../../../../redux/modules/children/fileChooser/Resource";
import * as updateReasons from "./updateReasons";
import { EpicState } from "./flowTypes";

type StateReducer = (state: EpicState, assetsReplacements: AssetsReplacements) => Record<string, any>;

const TriggerActionTypes = [
    FC_SAVE,
    COMPONENT_CONFIGURATION_CANCELED
];

export const makeReplaceAssetsUpdaters = (
    stateReducer: StateReducer,
    useUpdateReason: boolean = false
) => TriggerActionTypes.map(action => ({
    conditions: [
        receiveOnly(FC_RESOURCES_REQUEST),
        receiveOnly(FC_RESOURCES_FETCH_SUCCESS),
        receiveOnly(_FC_SET_RESOURCES),
        action
    ],
    reducer: ({ state, scope, values: [request, { entries }] }: Record<string, any>) => {
        const assetsReplacements = entries.reduce((acc, entr) => {
            const
                resource = new Resource(request[0], entr),
                url = resource.getWebSpaceProtocolRelativePath();

            return { ...acc, [url]: { etag: entr.etag } };
        }, {});

        if (Object.keys(assetsReplacements).length) {
            const possiblyNewState = stateReducer(state, assetsReplacements);
            return {
                state: possiblyNewState,
                scope,
                updateReason: useUpdateReason && possiblyNewState !== state ?
                    updateReasons.UPDATE_ASSET_URLS : undefined
            };
        }

        return { state, scope };
    }
}));
