import type { AppStore } from "../../../../redux/modules/flowTypes";
import { FC_COMPUTER_UPLOAD } from "../../../../redux/modules/children/fileChooser/actionTypes";
import {
    fcDialogActiveTabIdAppSel,
    fcDialogIsVideoDialog
} from "../../../../redux/modules/children/fileChooser/selectors";
import { CLOSE_FM_DIALOG_ACTION, switchFmTabAction } from "../actions";
import { FmTabId } from "../constants";
import { OPEN_DIALOG } from "../../../../redux/modules/actionTypes";
import { ExternalUrlUploadDialog } from "../../../../view/common/FileChooser/dialogIds";
import { fcResetAction } from "../../../../redux/modules/children/fileChooser/actionCreators/index";
import { closeDialog } from "../../../App/actionCreators/index";

export const fileManagerDialogMiddleware = (store: AppStore) => (next: Dispatch) => (action: Action) => {
    if (
        action.type === FC_COMPUTER_UPLOAD
        ||
        (
            action.type === OPEN_DIALOG && action.payload
            && action.payload.dialogId === ExternalUrlUploadDialog
        )
    ) {
        const
            state = store.getState(),
            activeTabId = fcDialogActiveTabIdAppSel(state);

        if (activeTabId === FmTabId.FREE_ONECOM_IMAGES) {
            const tab = fcDialogIsVideoDialog(state) ? FmTabId.MY_VIDEOS : FmTabId.MY_IMAGES;
            store.dispatch(switchFmTabAction(tab));
            return next(action);
        }
    }

    if (action.type === CLOSE_FM_DIALOG_ACTION) {
        store.dispatch(fcResetAction());
        store.dispatch(closeDialog());
    }

    return next(action);
};
