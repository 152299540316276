import * as R from "ramda";
import { setComponentsMap } from "../../Workspace/epics/componentsEval/setters";
import {
    DELETE_STRIP_AND_ADD_BACKGROUND_TO_SECTION
} from "./actions";
import { getTopMostParentId } from "../../Workspace/epics/componentAttachements/util";
import { ReceiveOnlyTemplateWidthActionType } from "../../oneweb/Template/epics/template/selectorActionTypes";
import { ReceiveOnlySelectedComponentSelector } from "../../Workspace/epics/componentsEval/selectorActionTypes";
import { ReceiveOnlyAttachments } from "../../Workspace/epics/componentAttachements/selectorActionTypes";
import { updateComponentsRelIns } from "../../Workspace/epics/relations/updateComponentsRelIns";
import { isStripKind } from "../../oneweb/componentKinds";
import * as workspaceActionTypes from "../../Workspace/actionTypes";

export const supportDeleteStripAndAddBackgroundToSectionUpdater = {
    conditions: [
        ReceiveOnlySelectedComponentSelector,
        ReceiveOnlyAttachments,
        ReceiveOnlyTemplateWidthActionType,
        DELETE_STRIP_AND_ADD_BACKGROUND_TO_SECTION
    ],
    reducer: ({ values: [selectedCmp, attachments, templateWidth], state: epicState }) => {
        let { state: { componentsMap } } = epicState,
            newComponentsMap = { ...componentsMap };
        if (selectedCmp && isStripKind(selectedCmp.kind)) {
            const sectionId = getTopMostParentId(selectedCmp.id, attachments);
            const sectionChildren = attachments[sectionId];
            if (sectionChildren.length === 1) {
                const pathStyle = 'style';
                const pathTheme = 'selectedTheme';
                const selectedCmpStyle = R.view(R.lensProp(pathStyle), selectedCmp);
                const selectedCmpTheme = R.view(R.lensProp(pathTheme), selectedCmp);
                if (selectedCmpStyle) {
                    newComponentsMap = R.assocPath([sectionId, pathStyle], selectedCmpStyle, newComponentsMap);
                }
                if (selectedCmpTheme) {
                    newComponentsMap = R.assocPath([sectionId, pathTheme], selectedCmpTheme, newComponentsMap);
                }
                delete newComponentsMap[selectedCmp.id];
                newComponentsMap = updateComponentsRelIns(newComponentsMap, templateWidth);
                const componentIdsToBeDeleted: Array<string> = [selectedCmp.id];
                return {
                    state: setComponentsMap(newComponentsMap, epicState),
                    actionToDispatch: {
                        type: workspaceActionTypes.COMPONENTS_DELETED,
                        payload: componentIdsToBeDeleted
                    },
                    updateReason: DELETE_STRIP_AND_ADD_BACKGROUND_TO_SECTION
                };
            }
        }

        return { state: epicState };
    }
};
