import React from 'react';
import { getDAL } from "../../../../../dal/index";
import type { FeaturedProductsComponent } from '../flowTypes';
import { Intl } from "../../../../view/intl/injectIntl";
import Msg from '../../../../view/intl/Msg';
import { SetupProductButton } from '../../ProductWidget/SetupProductButton/index';
import LoadingIndicator from '../../../../view/common/LoadingIndicator/index';

type Props = {
    intl: Intl,
    selectedComponent: FeaturedProductsComponent,
    dispatch: Function,
    isSubscriptionTypeCompatible: boolean
}

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export const getWebshopSetupStatus = async (setFetchStatus = (_arg) => {}) => {
    let result = true;
    setFetchStatus('inprogress');
    try {
        const { response, body } = await getDAL().checkIfWebshopIsSetup();
        if (response && response.status === 200 && !!(body.isWebshopSetupDone || body.isDigitalShopSetupDone)) {
            result = true;
        } else {
            result = false;
        }
        setFetchStatus('completed');
    } catch (err: any) {
        console.log('err: ', err);
        result = false;
        setFetchStatus('completed');
    }
    return result;
};

export const CtaButtonTextView = (props: Props) => {
    const { selectedComponent, dispatch, intl, isSubscriptionTypeCompatible } = props;
    const [isWebshopSetupDone, setIsWebshopSetupDone] = React.useState(true);
    const [fetchStatus, setFetchStatus] = React.useState('initial');

    const updateWebshopSetupStatus = async () => {
        const webshopSetupStatus = await getWebshopSetupStatus(setFetchStatus);
        setIsWebshopSetupDone(webshopSetupStatus);
    };

    React.useEffect(() => {
        updateWebshopSetupStatus();
    }, [selectedComponent]);

    return (fetchStatus === 'completed') ?
        (<React.Fragment>
            {(selectedComponent.isSingleProduct && !isWebshopSetupDone) ?
                <SetupProductButton
                    intl={intl}
                    dispatch={dispatch}
                    componentId={selectedComponent.id}
                    isSubscriptionTypeCompatible={isSubscriptionTypeCompatible}
                /> :
                <Msg k="component.webshop.featuredProducts.replaceProduct">Replace product</Msg>}
        </React.Fragment>) : <LoadingIndicator />;
};
