import * as R from "ramda";
import { Intl } from "../../../view/intl/index";
import { defaultState } from "../../oneweb/Table/constants";
import { fixRowAndColumnIndices } from "../../oneweb/Table/utils";
import * as selectors from "../../Workspace/epics/stylesheets/selectors";
import TableGlobalstyleType from "../../oneweb/Table/globalStyle/kind";
import {
    types,
    globalStyleLabels,
    FontFamilyChangeActions,
    FontSizeChangeActions,
    ToggleBoldActions,
    ToggleItalicActions,
    ToggleUnderlineActions,
    HighlightColorChangeActions,
    HighlightColorRemoveActions,
    FontColorChangeActions,
    FontShadowColorChangeActions,
    FontShadowColorRemoveActions,
    FontShadowBlurRadiusChangeActions,
    FontShadowHorizontalOffsetChangeActions,
    FontShadowVerticalOffsetChangeActions,
    HorizontalAlignmentChangeActions,
    VerticalAlignmentChangeActions,
    BackgroundColorChangeActions,
    BackgroundColorRemoveActions,
    BackgroundGradientColorChangeActions,
    BackgroundGradientColorRemoveActions,
    BackgroundGradientDirectionChangeActions,
    BackgroundGradientFadePointChangeActions,
    BackgroundOpacityChangeAction,
    BorderStyleChangeActions,
    BorderColorChangeActions,
    BorderColorOpacityChangeActions,
    BackgroundAssetChangeAction,
    BackgroundAssetRemoveAction,
    BackgroundAssetPositionChangeAction,
    BackgroundAssetRepeatChangeAction,
    BackgroundAssetSizeChangeAction,
    BorderWidthChangeActions,
    CellSpacingChageActions
} from "./constants";
import { BorderDialogIds, CellSpacingDialogIds, BackgroundDialogIds, TextShadowDialogIds } from "./dialogIds";
import calcRenderProps from "../../oneweb/Table/calcRenderProps";
import type { Stylesheets } from "../../Workspace/epics/stylesheets/flowTypes";
import type { TableComponent } from "../../oneweb/Table/flowTypes";
import { DataSite } from "../../../../dal/model/index";
import { makeDefaultContentOnDrop } from "../../oneweb/Text/constants";
import { tableEditModeEpicDefaultState } from '../../oneweb/Table/epics/tableEditMode/models/state';
import type { ThemeColorDataType, ThemeSiteSettingsDataType } from "../../ThemeGlobalData/flowTypes";

type GetPreviewProps = {
    stylesheets: Stylesheets,
    stylesheetsIdToNameMap: Record<string, any>,
    site: DataSite,
    intl: Intl,
    themeSettingsData: ThemeSiteSettingsDataType,
    themeColorsData: ThemeColorDataType,
}

const
    TableCellGlobalstylePreviewMinHeight = 100,
    createSelectorByRef = ref => R.pipe(
        selectors.getAllStylesheets,
        selectors.getStylesByType(TableGlobalstyleType),
        selectors.getStyleByRef(ref)
    ),
    createCellFromGlobalstyle = (globalstyle, cell, intl) => R.evolve({
        content: () => makeDefaultContentOnDrop(intl.msgJoint(globalStyleLabels[globalstyle.ref])),
        style: {
            block: () => ({ background: null, border: null, padding: null }),
            globalId: () => globalstyle.id,
            globalName: () => globalstyle.name,
            text: () => ({ size: null })
        }
    }, cell);

export const
    getAllActionsForStyle = (ref: string): Record<string, any> => ({
        fontFamilyChangeAction: FontFamilyChangeActions[ref],
        fontSizeChangeAction: FontSizeChangeActions[ref],
        toggleBoldAction: ToggleBoldActions[ref],
        toggleItalicAction: ToggleItalicActions[ref],
        toggleUnderlineAction: ToggleUnderlineActions[ref],
        highlightColorChangeAction: HighlightColorChangeActions[ref],
        highlightColorRemoveAction: HighlightColorRemoveActions[ref],
        fontColorChangeAction: FontColorChangeActions[ref],
        fontShadowColorChangeAction: FontShadowColorChangeActions[ref],
        fontShadowColorRemoveAction: FontShadowColorRemoveActions[ref],
        fontShadowBlurRadiusChangeAction: FontShadowBlurRadiusChangeActions[ref],
        fontShadowHorizontalOffsetChangeAction: FontShadowHorizontalOffsetChangeActions[ref],
        fontShadowVerticalOffsetChangeAction: FontShadowVerticalOffsetChangeActions[ref],
        horizontalAlignmentChangeAction: HorizontalAlignmentChangeActions[ref],
        verticalAlignmentChangeAction: VerticalAlignmentChangeActions[ref],
        backgroundColorChangeAction: BackgroundColorChangeActions[ref],
        backgroundColorRemoveAction: BackgroundColorRemoveActions[ref],
        backgroundGradientColorChangeAction: BackgroundGradientColorChangeActions[ref],
        backgroundGradientColorRemoveAction: BackgroundGradientColorRemoveActions[ref],
        backgroundGradientDirectionChangeAction: BackgroundGradientDirectionChangeActions[ref],
        backgroundGradientFadePointChangeAction: BackgroundGradientFadePointChangeActions[ref],
        backgroundOpacityChangeAction: BackgroundOpacityChangeAction[ref],
        borderStyleChangeAction: BorderStyleChangeActions[ref],
        borderColorChangeAction: BorderColorChangeActions[ref],
        borderColorOpacityChangeAction: BorderColorOpacityChangeActions[ref],
        backgroundAssetChangeAction: BackgroundAssetChangeAction[ref],
        backgroundAssetRemoveAction: BackgroundAssetRemoveAction[ref],
        backgroundAssetPositionChangeAction: BackgroundAssetPositionChangeAction[ref],
        backgroundAssetRepeatChangeAction: BackgroundAssetRepeatChangeAction[ref],
        backgroundAssetSizeChangeAction: BackgroundAssetSizeChangeAction[ref],
        borderWidthChangeAction: BorderWidthChangeActions[ref],
        cellSpacingChangeAction: CellSpacingChageActions[ref]
    }),

    getBorderDialogId = (ref: string): string => BorderDialogIds[ref],

    getCellSpacingDialogId = (ref: string): string => CellSpacingDialogIds[ref],

    getTextShadowDialogId = (ref: string): string => TextShadowDialogIds[ref],

    getBackgroundDialogId = (ref: string): string => BackgroundDialogIds[ref],

    getPreviewProps = ({
        stylesheets,
        stylesheetsIdToNameMap,
        site,
        intl,
        themeSettingsData,
        themeColorsData
    }: GetPreviewProps): TableComponent => {
        const
            cell = R.evolve({
                cellInfo: {
                    minHeight: () => TableCellGlobalstylePreviewMinHeight
                }
            }, defaultState.cells[0]),
            tableNormalGlobalstyle = createSelectorByRef(types.NORMAL)(stylesheets),
            tableHeading1Globalstyle = createSelectorByRef(types.HEADING1)(stylesheets),
            tableHeading2Globalstyle = createSelectorByRef(types.HEADING2)(stylesheets),
            tableAlternateGlobalstyle = createSelectorByRef(types.ALTERNATE)(stylesheets),

            normalStyleCell = createCellFromGlobalstyle(tableNormalGlobalstyle, cell, intl),
            heading1StyleCell = createCellFromGlobalstyle(tableHeading1Globalstyle, cell, intl),
            heading2StyleCell = createCellFromGlobalstyle(tableHeading2Globalstyle, cell, intl),
            alternateStyleCell = createCellFromGlobalstyle(tableAlternateGlobalstyle, cell, intl),

            prevewCells = [
                heading1StyleCell, heading1StyleCell, heading1StyleCell,
                heading2StyleCell, normalStyleCell, normalStyleCell,
                heading2StyleCell, alternateStyleCell, alternateStyleCell,
                heading2StyleCell, normalStyleCell, normalStyleCell
            ],
            cells = fixRowAndColumnIndices({ numRows: 4, numCols: 3 })(prevewCells);

        return calcRenderProps({
            component: { cells, width: 424, height: 221 },
            // @ts-ignore
            componentDependencies: {
                stylesheets,
                stylesheetsIdToNameMap,
                site,
                tableEditModeState: tableEditModeEpicDefaultState,
                themeSettingsData,
                themeColorsData
            },
            inEditMode: false
        });
    };
