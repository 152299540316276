import * as actions from '../actionTypes';
import { WIDGETS_GOOGLEMAPS_EPIC_VAT } from "./valueActionType";
import { configurationDialogId } from '../configurationDialog';
import { epicFactory } from '../../epics/factory';
import { identityExtractor } from '../../utils';

/**
 * IMPORTANT
 *
 * In the epicFactory, although the attibute `showUpdateLinkDialogAction` is being passed, it is of no value.
 * The prop is passed to make it compatible with implementation of other widgets where it does perform some
 * action. Hence, instead of making the property opitonal, just because of one widget, it deemed fit to create
 * a fake, unused prop.
 */

const WidgetsGoogleMapsEpic = epicFactory({
    defaultState: null,
    valueActionType: WIDGETS_GOOGLEMAPS_EPIC_VAT,
    configurationDialogId,
    payloadExtractor: identityExtractor,
    codeParser: identityExtractor,
    setLinkAction: actions.WIDGETS_GOOGLEMAPS_SET_LINK,
    updatePropsAction: actions.WIDGETS_GOOGLEMAPS_UPDATE_PROPS,
    showUpdateLinkDialogAction: 'WIDGETS_GOOGLEMAPS_SHOW_UPDATE_LINK_DIALOG',
    closeDialogActions: [actions.WIDGETS_GOOGLEMAPS_UPDATE_PROPS],
});

export { WidgetsGoogleMapsEpic };
