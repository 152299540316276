import * as React from 'react';
import cx from 'classnames';
import styles from './FileChooserBody.css';
import Msg from '../../../intl/Msg';

type Props = {
    arrowClassName?: string,
};

export default ({ arrowClassName }: Props) => {
    return (
        <div className={styles.emptyFolderMsg}>
            <Msg k="fc.emptyFolderMsg">No files in this folder</Msg>
            {arrowClassName && <span className={cx(styles.emptyFolderArrow, arrowClassName)} />}
        </div>
    );
};
