import * as React from 'react';
import * as styles from './Tag.css';
import Msg from "../../intl/Msg";
import type { TagProps } from "./flowTypes";

const CommonTag = ({
    containerClassName,
    labelClassName,
    children
}: Record<string, any>) => <div className={`${styles.container} ${containerClassName || ''}`}>
    <div className={`${styles.text} ${labelClassName || ''}`} style={{ textTransform: 'uppercase' }}>
        {children}
    </div>
</div>;

export const
    NewTag = (props: TagProps) =>
        <CommonTag {...props}>
            <Msg k="common.New">New</Msg>
        </CommonTag>,
    BetaTag = (props: TagProps) =>
        <CommonTag {...props}>
            <Msg k="common.Beta">Beta</Msg>
        </CommonTag>;

