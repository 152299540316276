/* eslint-disable max-len */

import React from 'react';
import { useIntl } from "../../../../../../../view/intl";
import styles from "./styles.css";
import { rephraseBtn } from './constants';

type Props = {
    mode: string;
}

export const GenerationReminderBanner = ({ mode }: Props) => {
    const intl = useIntl();

    return (
        <div className={styles.generationReminderBanner} data-testid="ai-edit-text-generation-banner">
            <div className={styles.generationReminderBannerIcon} />
            <div className={styles.generationReminderBannerText}>
                {
                    mode === rephraseBtn.id ?
                        intl.msgJoint("msg: component.text.editAIText.dialog.generationBanner.prompt {If the generated text does not meet your expectations, we recommend adding more details and clear information on what should be changed to your instructions.}") :
                        intl.msgJoint("msg: component.text.editAIText.dialog.generationBanner.chip {If the generated text does not meet your expectations, try writing your own instructions with information on what should be changed.}")
                }
            </div>
        </div>
    );
};
