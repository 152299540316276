import React from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { Flex } from "../../../view/reflexbox/index";
import HorizontalSpacer from "../../../view/common/HorizontalSpacer";
import Icons from "../../../view/Icons/index";
import memo from '../../../../utils/memo';
import { getPosition } from '../../../utils/mouse';
import { getFontName } from '../AddGoogleFont/utils';
import { openDialog } from '../../App/actionCreators/index';
import TextIconButton from '../TextIconButton/index';
import fontFamilies from "./fontFamilies";
import addGoogleFontConfigurationDialogId from './addGoogleFontConfigurationDialogId';
import fontSelectionConfigurationDialogId from './fontSelectionConfigurationDialogId';
import type { FontFamilySelectorProps } from "./flowTypes";
import styles from "./FontFamily.css";
import type { AppState } from "../../../redux/modules/flowTypes";
import { epicStateAppSel } from "../../../epics/selectors";
import siteDataVAT from '../../App/epics/siteData/valueActionType';
import { Lit } from "../../../lit";

const getFonts = memo(fonts => fonts.map(getFontName));

const FontFamilySelectorCmp = (props: FontFamilySelectorProps) => {
    const
        {
            selected,
            onChangeAction,
            siteFonts: propSiteFonts,
            connectedSiteFonts,
            onAddFontClicked,
            additionalPayload,
            preserveGooglePrefix,
            isPropertiesPanel,
            forwardToSelectedComponent,
            dispatch
        } = props,
        siteFonts = propSiteFonts || connectedSiteFonts,
        fullFontsList = fontFamilies.concat(getFonts(siteFonts)),
        defaultOnAddFontClick = () => dispatch(openDialog(
            addGoogleFontConfigurationDialogId,
            { dispatch, additionalPayload }
        )),
        showFontSelectionDialog = (e: React.MouseEvent<any>) => dispatch(openDialog(
            fontSelectionConfigurationDialogId,
            {
                onChangeAction,
                fontFamily: selected,
                position: getPosition(e),
                preserveGooglePrefix,
                forwardToSelectedComponent,
                dispatch
            }
        ));

    fullFontsList.sort();

    return (
        <Flex justify="space-between">
            <TextIconButton
                extraLarge
                text={getFontName(String(selected))}
                Icon={Icons.BOTTOM}
                onClick={showFontSelectionDialog}
                className={cx({
                    [styles.buttonSelect]: isPropertiesPanel
                })}
            />
            <HorizontalSpacer />
            <Icons.PLUS_LARGE
                border
                className={styles.plusIco}
                style={{ fill: '#5181cf', width: '36px', height: '36px' }}
                onClick={onAddFontClicked || defaultOnAddFontClick}
                title="msg: common.addGoogleFont {Add Google Font}"
            />
        </Flex>
    );
};

const mapStateToProps = (appState: AppState) => ({
    connectedSiteFonts: epicStateAppSel(siteDataVAT, [Lit.fonts])(appState),
});

const FontFamilySelector = connect(mapStateToProps)(FontFamilySelectorCmp);

export {
    FontFamilySelector as default,
    FontFamilySelector,
};
