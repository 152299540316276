import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import makeStyles from '@mui/styles/makeStyles';

import type { AppState } from '../../../common/types';
import { GoogleAdsSettingsInfo } from './GoogleAdsSettingsInfo';
import { GoogleAdsSettingsNoAds } from './GoogleAdsSettingsNoAds';
import { GoogleAdsSettingsAdCreated } from './GoogleAdsSettingsAdCreated';
import { GoogleAdsSettingsAdLive } from './GoogleAdsSettingsAdLive';
import { GoogleAdsSettingsAdDisabled } from './GoogleAdsSettingsAdDisabled';
import { GoogleAdsSettingsAdRemoved } from './GoogleAdsSettingsAdRemoved';
import { googleAdsCampaignEnabledSelector, siteWitEnabledSelector, trackingScriptPublishedSelector } from '../../../common/selectors';
import { googleAdsSettingsCampaignStatusChangedAction, googleAdsSettingsUpdateStatusAction } from './actions';
import { SettingsSectionWrapper } from '../../SettingsControls';
import { GoogleAdsLoader } from "./Loader/GoogleAdsLoader";
import { PageContentLayout } from '../PageContentLayout';
import { Header } from '../Header';
import { GoogleAdsSideBarItem } from './constants';
import { PageLayout } from '../PageLayout';

type GoogleAdsSettingsComponentProps = {
    isLoading: boolean;
    isSiteWitEnabled: boolean | null;
    isSiteWitEnabledBoolean: boolean;
    isCampaignEnabled: boolean;
    isTrackingScriptPublished: boolean;
    dispatch: Dispatch;
};

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "56px",
        [theme.breakpoints.down('md')]: {
            marginTop: "50px",
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: "40px",
        },
    },
}), { name: "GoogleAdsSettings" });

const GoogleAdsSettingsFormView = (props: GoogleAdsSettingsComponentProps) => {
    const {
        isLoading,
        isSiteWitEnabled,
        isSiteWitEnabledBoolean,
        isCampaignEnabled,
        isTrackingScriptPublished,
        dispatch
    } = props;

    useEffect(() => {
        if (isSiteWitEnabled === null) {
            dispatch(googleAdsSettingsUpdateStatusAction());
        }
    }, [isSiteWitEnabled]);

    const handleCampaignStatusChange = (status: boolean) => {
        dispatch(googleAdsSettingsCampaignStatusChangedAction(status));
    };

    if (isLoading) {
        return (<GoogleAdsLoader />);
    } else if (isSiteWitEnabledBoolean) {
        if (isCampaignEnabled && !isTrackingScriptPublished) {
            return (
                <GoogleAdsSettingsAdCreated
                    isCampaignEnabled={isCampaignEnabled}
                    handleCampaignStatusChange={handleCampaignStatusChange}
                />
            );
        }

        if (isCampaignEnabled && isTrackingScriptPublished) {
            return (
                <GoogleAdsSettingsAdLive
                    isCampaignEnabled={isCampaignEnabled}
                    handleCampaignStatusChange={handleCampaignStatusChange}
                />
            );
        }

        if (!isCampaignEnabled && isTrackingScriptPublished) {
            return (
                <GoogleAdsSettingsAdDisabled
                    isCampaignEnabled={isCampaignEnabled}
                    handleCampaignStatusChange={handleCampaignStatusChange}
                />
            );
        }

        if (!isCampaignEnabled && !isTrackingScriptPublished) {
            return (
                <GoogleAdsSettingsAdRemoved
                    isCampaignEnabled={isCampaignEnabled}
                    handleCampaignStatusChange={handleCampaignStatusChange}
                />
            );
        }
    }

    return <GoogleAdsSettingsNoAds />;
};

const GoogleAdsSettingsComponent = (props: GoogleAdsSettingsComponentProps) => {
    const classes = useStyles();

    return (
        <PageLayout withFooterBar>
            <PageContentLayout>
                <Header header={GoogleAdsSideBarItem.header} />

                <div className={classes.root}>
                    <SettingsSectionWrapper
                        info={<GoogleAdsSettingsInfo />}
                        form={<GoogleAdsSettingsFormView {...props} />}
                    />
                </div>
            </PageContentLayout>
        </PageLayout>
    );
};

const mapStateToProps = (state: AppState) => ({
    isSiteWitEnabled: state.googleAds.isSiteWitEnabled,
    isLoading: state.googleAds.isLoading,
    isSiteWitEnabledBoolean: Boolean(siteWitEnabledSelector(state)),
    isCampaignEnabled: Boolean(googleAdsCampaignEnabledSelector(state)),
    isTrackingScriptPublished: Boolean(trackingScriptPublishedSelector(state))
});

export const GoogleAdsSettings = connect(mapStateToProps)(GoogleAdsSettingsComponent);
