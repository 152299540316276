import React from 'react';
import * as pageIds from '../pageIds';
import pageTitles from '../pageTitles';
import Page from "../../../../../PropertiesPanel/view/PropertiesPage";
import PropertiesCheckBox from '../../../../../../view/common/CheckBox/PropertiesCheckBox';
import * as actions from '../../actions';

const id = pageIds.LAYOUT;
const title = pageTitles[id];
const view = ({ selectedComponent, dispatch }) => {
    const { portrait, title, byline } = selectedComponent;
    return (
        <Page>
            <PropertiesCheckBox
                isChecked={portrait}
                label="msg: widgets.vimeo.cta.intro.portrait {Portrait}"
                onClick={() => dispatch({ type: actions.WIDGETS_VIMEO_TOGGLE_PORTRAIT })}
            />
            <PropertiesCheckBox
                isChecked={title}
                label="msg: widgets.vimeo.cta.intro.title {Title}"
                onClick={() => dispatch({ type: actions.WIDGETS_VIMEO_TOGGLE_TITLE })}
            />
            <PropertiesCheckBox
                isChecked={byline}
                label="msg: widgets.vimeo.cta.intro.byline {Byline}"
                onClick={() => dispatch({ type: actions.WIDGETS_VIMEO_TOGGLE_BYLINE })}
            />
        </Page>
    );
};

export { id, title, view };
