/* @flow */

const mapLangCodeForThirdParty = (code/*: string */) => {
    let langCode = code
        .toLowerCase()
        .replace('_', '-')
        .split('-')[0];

    if (langCode === 'nb') {
        langCode = 'no';
    }

    return langCode;
};

module.exports = { mapLangCodeForThirdParty };
