import React from "react";
import { Flex } from "../../../../../view/reflexbox/index";
import Page from "../../../../PropertiesPanel/view/PropertiesPage";
import ColorProperty from "../../../../presentational/ColorProperty/index";
import actionTypes from "../../reducer/actionTypes";
import injectIntl from "../../../../../view/intl/injectIntl";
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import * as pagesIds from "./ids";
import pagesTitles from "./titles";
import type { PropertiesPanelStyleViewProps } from "../../flowTypes";
import Label from "../../../../../view/common/Label";
import Slider from "../../../../../view/common/Slider/PPSlider";
import { DEFAULT_COLOR_PICKER, THEME_COLOR_PICKER } from "../../../../ColorPicker/constants";
import { getThemeRulesForWebshop } from "../../../../ThemeGlobalData/themeRules";
import { getThemeColorsFromStylesheet } from "../../../../Workspace/epics/stylesheets/selectors";

import { MctaPropertyContainer, MctaCombobox } from "../../../../componentMainActions";
import { productPerPageOptions } from "../../options";

const id: any = pagesIds.STYLE,
    title = pagesTitles[pagesIds.STYLE],
    view = injectIntl((props: PropertiesPanelStyleViewProps) => {
        const {
            dispatch,
            selectedComponent,
            globalStyles,
            themeSettingsData: { autoColorMode }
            // $FlowFixMe
        } = props;
        const themeColorsData = getThemeColorsFromStylesheet(globalStyles);

        const getProductsPerPage = (productsPerPage: number): number => {
            let result = 50; // default value
            if ([25, 50, 75].includes(+productsPerPage)) {
                result = productsPerPage;
            } else if (productsPerPage < 25) {
                result = 25;
            } else if (productsPerPage > 75) {
                result = 75;
            }
            return result;
        };

        return (
            <Page>
                <VerticalSpacer />
                <Flex align="center">
                    <label style={{ flex: "1", cursor: "pointer" }} htmlFor="prodPerPage">
                        <Label>{"msg: component.button.productsPerPage {Products per page}"}</Label>
                    </label>

                    <MctaPropertyContainer>
                        <MctaCombobox
                            useOptionsAsIs
                            options={productPerPageOptions}
                            value={getProductsPerPage(selectedComponent.productsPerPage)}
                            onChange={({ value }) => {
                                dispatch({
                                    type: actionTypes.WEBSHOP_PRODUCTS_PER_PAGE_CHANGED,
                                    payload: value
                                });
                            }}
                            searchable={false}
                        />
                    </MctaPropertyContainer>
                </Flex>
                <VerticalSpacer />
                {autoColorMode
                    ? [
                        <ColorProperty
                            colorPickerKind={THEME_COLOR_PICKER}
                            themeColorsData={themeColorsData}
                            themeColor={selectedComponent.fontColorTheme}
                            defaultThemeColor={
                                getThemeRulesForWebshop(props.selectedComponentProps.selectedParentTheme, themeColorsData).fontColorName
                            }
                            label="msg: component.webshop.productNameFontColour {Product name font colour}"
                            onChangeAction={actionTypes.WEBSHOP_TEXT_COLOR_CHANGED_AUTO_COLOR}
                            dispatch={dispatch}
                        />,
                        <VerticalSpacer />,
                        <ColorProperty
                            colorPickerKind={THEME_COLOR_PICKER}
                            themeColorsData={themeColorsData}
                            themeColor={selectedComponent.focusColorTheme}
                            defaultThemeColor={
                                getThemeRulesForWebshop(props.selectedComponentProps.selectedParentTheme, themeColorsData).focusColorName
                            }
                            label="msg: component.webshop.productPriceFontColour {Product price font colour}"
                            onChangeAction={actionTypes.WEBSHOP_FOCUS_COLOR_CHANGED_AUTO_COLOR}
                            dispatch={dispatch}
                        />
                    ]
                    : [
                        <ColorProperty
                            colorPickerKind={DEFAULT_COLOR_PICKER}
                            onRemoveAction=""
                            label="msg: component.webshop.productNameFontColour {Product name font colour}"
                            color={selectedComponent.fontColor}
                            onChangeAction={actionTypes.WEBSHOP_TEXT_COLOR_CHANGED}
                            dispatch={dispatch}
                        />,
                        <VerticalSpacer />,
                        <ColorProperty
                            onRemoveAction=""
                            label="msg: component.webshop.productPriceFontColour {Product price font colour}"
                            color={selectedComponent.focusColor}
                            onChangeAction={actionTypes.WEBSHOP_FOCUS_COLOR_CHANGED}
                            dispatch={dispatch}
                        />
                    ]}
                <VerticalSpacer />
                <Slider
                    min={2}
                    max={5}
                    label="msg: webshop.ProductsPerRow {Products per row:}"
                    valueLabel={selectedComponent.productsPerRow}
                    value={selectedComponent.productsPerRow}
                    defaultValue={selectedComponent.productsPerRow}
                    onChange={value =>
                        dispatch({
                            type: actionTypes.WEBSHOP_PRODUCTS_PER_ROW_CHANGED,
                            payload: value,
                            amendToSelf: true
                        })}
                />
                <VerticalSpacer />
            </Page>
        );
    });

export { id, title, view };
