import makeEpic from "../../../../epics/makeEpic";
import { ALL_CONTAINERS_FILTER_KEY } from "../../view/containers/constants";
import valueActionType from './valueActionType';
import { LOAD_SECTION_LIST_FAILURE_ACTION, LOAD_SECTION_LIST_SUCCESS_ACTION } from "../../actionTypes";
import { SET_CONTAINERS_FILTER } from "./actionTypes";

export const containersExtendedPanelFilterEpic = makeEpic({
    defaultState: {
        listLoaded: false,
        filterKey: ALL_CONTAINERS_FILTER_KEY,
        hasError: false
    },
    valueActionType,
    updaters: [
        {
            conditions: [
                LOAD_SECTION_LIST_SUCCESS_ACTION
            ],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        listLoaded: true,
                        hasError: false
                    }
                };
            },
        },
        {
            conditions: [
                LOAD_SECTION_LIST_FAILURE_ACTION
            ],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        listLoaded: true,
                        hasError: true
                    }
                };
            },
        },
        {
            conditions: [
                SET_CONTAINERS_FILTER
            ],
            reducer: ({ state, values: [key] }) => {
                return {
                    state: {
                        ...state,
                        filterKey: key
                    }
                };
            },
        },
    ]
});
