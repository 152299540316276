/* eslint-disable max-len */

export const
    GS_TABLE_NORMAL_FONT_FAMILY_CHANGED = 'GS_TABLE_NORMAL_FONT_FAMILY_CHANGED',
    GS_TABLE_HEADING1_FONT_FAMILY_CHANGED = 'GS_TABLE_HEADING1_FONT_FAMILY_CHANGED',
    GS_TABLE_HEADING2_FONT_FAMILY_CHANGED = 'GS_TABLE_HEADING2_FONT_FAMILY_CHANGED',
    GS_TABLE_ALTERNATE_FONT_FAMILY_CHANGED = 'GS_TABLE_ALTERNATE_FONT_FAMILY_CHANGED',

    GS_TABLE_NORMAL_FONT_SIZE_CHANGED = 'GS_TABLE_NORMAL_FONT_SIZE_CHANGED',
    GS_TABLE_HEADING1_FONT_SIZE_CHANGED = 'GS_TABLE_HEADING1_FONT_SIZE_CHANGED',
    GS_TABLE_HEADING2_FONT_SIZE_CHANGED = 'GS_TABLE_HEADING2_FONT_SIZE_CHANGED',
    GS_TABLE_ALTERNATE_FONT_SIZE_CHANGED = 'GS_TABLE_ALTERNATE_FONT_SIZE_CHANGED',

    GS_TABLE_NORMAL_TOGGLE_BOLD = 'GS_TABLE_NORMAL_TOGGLE_BOLD',
    GS_TABLE_HEADING1_TOGGLE_BOLD = 'GS_TABLE_HEADING1_TOGGLE_BOLD',
    GS_TABLE_HEADING2_TOGGLE_BOLD = 'GS_TABLE_HEADING2_TOGGLE_BOLD',
    GS_TABLE_ALTERNATE_TOGGLE_BOLD = 'GS_TABLE_ALTERNATE_TOGGLE_BOLD',

    GS_TABLE_NORMAL_TOGGLE_ITALIC = 'GS_TABLE_NORMAL_TOGGLE_ITALIC',
    GS_TABLE_HEADING1_TOGGLE_ITALIC = 'GS_TABLE_HEADING1_TOGGLE_ITALIC',
    GS_TABLE_HEADING2_TOGGLE_ITALIC = 'GS_TABLE_HEADING2_TOGGLE_ITALIC',
    GS_TABLE_ALTERNATE_TOGGLE_ITALIC = 'GS_TABLE_ALTERNATE_TOGGLE_ITALIC',

    GS_TABLE_NORMAL_TOGGLE_UNDERLINE = 'GS_TABLE_NORMAL_TOGGLE_UNDERLINE',
    GS_TABLE_HEADING1_TOGGLE_UNDERLINE = 'GS_TABLE_HEADING1_TOGGLE_UNDERLINE',
    GS_TABLE_HEADING2_TOGGLE_UNDERLINE = 'GS_TABLE_HEADING2_TOGGLE_UNDERLINE',
    GS_TABLE_ALTERNATE_TOGGLE_UNDERLINE = 'GS_TABLE_ALTERNATE_TOGGLE_UNDERLINE',

    GS_TABLE_NORMAL_HORIZONTAL_ALIGNMENT_CHANGED = 'GS_TABLE_NORMAL_HORIZONTAL_ALIGNMENT_CHANGED',
    GS_TABLE_HEADING1_HORIZONTAL_ALIGNMENT_CHANGED = 'GS_TABLE_HEADING1_HORIZONTAL_ALIGNMENT_CHANGED',
    GS_TABLE_HEADING2_HORIZONTAL_ALIGNMENT_CHANGED = 'GS_TABLE_HEADING2_HORIZONTAL_ALIGNMENT_CHANGED',
    GS_TABLE_ALTERNATE_HORIZONTAL_ALIGNMENT_CHANGED = 'GS_TABLE_ALTERNATE_HORIZONTAL_ALIGNMENT_CHANGED',

    GS_TABLE_NORMAL_VERTICAL_ALIGNMENT_CHANGED = 'GS_TABLE_NORMAL_VERTICAL_ALIGNMENT_CHANGED',
    GS_TABLE_HEADING1_VERTICAL_ALIGNMENT_CHANGED = 'GS_TABLE_HEADING1_VERTICAL_ALIGNMENT_CHANGED',
    GS_TABLE_HEADING2_VERTICAL_ALIGNMENT_CHANGED = 'GS_TABLE_HEADING2_VERTICAL_ALIGNMENT_CHANGED',
    GS_TABLE_ALTERNATE_VERTICAL_ALIGNMENT_CHANGED = 'GS_TABLE_ALTERNATE_VERTICAL_ALIGNMENT_CHANGED',

    GS_TABLE_NORMAL_HIGHLIGHT_COLOR_CHANGED = 'GS_TABLE_NORMAL_HIGHLIGHT_COLOR_CHANGED',
    GS_TABLE_HEADING1_HIGHLIGHT_COLOR_CHANGED = 'GS_TABLE_HEADING1_HIGHLIGHT_COLOR_CHANGED',
    GS_TABLE_HEADING2_HIGHLIGHT_COLOR_CHANGED = 'GS_TABLE_HEADING2_HIGHLIGHT_COLOR_CHANGED',
    GS_TABLE_ALTERNATE_HIGHLIGHT_COLOR_CHANGED = 'GS_TABLE_ALTERNATE_HIGHLIGHT_COLOR_CHANGED',

    GS_TABLE_NORMAL_HIGHLIGHT_COLOR_REMOVED = 'GS_TABLE_NORMAL_HIGHLIGHT_COLOR_REMOVED',
    GS_TABLE_HEADING1_HIGHLIGHT_COLOR_REMOVED = 'GS_TABLE_HEADING1_HIGHLIGHT_COLOR_REMOVED',
    GS_TABLE_HEADING2_HIGHLIGHT_COLOR_REMOVED = 'GS_TABLE_HEADING2_HIGHLIGHT_COLOR_REMOVED',
    GS_TABLE_ALTERNATE_HIGHLIGHT_COLOR_REMOVED = 'GS_TABLE_ALTERNATE_HIGHLIGHT_COLOR_REMOVED',

    GS_TABLE_NORMAL_FONT_COLOR_CHANGED = 'GS_TABLE_NORMAL_FONT_COLOR_CHANGED',
    GS_TABLE_HEADING1_FONT_COLOR_CHANGED = 'GS_TABLE_HEADING1_FONT_COLOR_CHANGED',
    GS_TABLE_HEADING2_FONT_COLOR_CHANGED = 'GS_TABLE_HEADING2_FONT_COLOR_CHANGED',
    GS_TABLE_ALTERNATE_FONT_COLOR_CHANGED = 'GS_TABLE_ALTERNATE_FONT_COLOR_CHANGED',

    GS_TABLE_NORMAL_FONT_SHADOW_COLOR_CHANGED = 'GS_TABLE_NORMAL_FONT_SHADOW_COLOR_CHANGED',
    GS_TABLE_HEADING1_FONT_SHADOW_COLOR_CHANGED = 'GS_TABLE_HEADING1_FONT_SHADOW_COLOR_CHANGED',
    GS_TABLE_HEADING2_FONT_SHADOW_COLOR_CHANGED = 'GS_TABLE_HEADING2_FONT_SHADOW_COLOR_CHANGED',
    GS_TABLE_ALTERNATE_FONT_SHADOW_COLOR_CHANGED = 'GS_TABLE_ALTERNATE_FONT_SHADOW_COLOR_CHANGED',

    GS_TABLE_NORMAL_FONT_SHADOW_COLOR_REMOVED = 'GS_TABLE_NORMAL_FONT_SHADOW_COLOR_REMOVED',
    GS_TABLE_HEADING1_FONT_SHADOW_COLOR_REMOVED = 'GS_TABLE_HEADING1_FONT_SHADOW_COLOR_REMOVED',
    GS_TABLE_HEADING2_FONT_SHADOW_COLOR_REMOVED = 'GS_TABLE_HEADING2_FONT_SHADOW_COLOR_REMOVED',
    GS_TABLE_ALTERNATE_FONT_SHADOW_COLOR_REMOVED = 'GS_TABLE_ALTERNATE_FONT_SHADOW_COLOR_REMOVED',

    GS_TABLE_NORMAL_FONT_SHADOW_BLUR_RADIUS_CHANGED = 'GS_TABLE_NORMAL_FONT_SHADOW_BLUR_RADIUS_CHANGED',
    GS_TABLE_HEADING1_FONT_SHADOW_BLUR_RADIUS_CHANGED = 'GS_TABLE_HEADING1_FONT_SHADOW_BLUR_RADIUS_CHANGED',
    GS_TABLE_HEADING2_FONT_SHADOW_BLUR_RADIUS_CHANGED = 'GS_TABLE_HEADING2_FONT_SHADOW_BLUR_RADIUS_CHANGED',
    GS_TABLE_ALTERNATE_FONT_SHADOW_BLUR_RADIUS_CHANGED = 'GS_TABLE_ALTERNATE_FONT_SHADOW_BLUR_RADIUS_CHANGED',

    GS_TABLE_NORMAL_FONT_SHADOW_HORIZONTAL_OFFSET_CHANGED = 'GS_TABLE_NORMAL_FONT_SHADOW_HORIZONTAL_OFFSET_CHANGED',
    GS_TABLE_HEADING1_FONT_SHADOW_HORIZONTAL_OFFSET_CHANGED = 'GS_TABLE_HEADING1_FONT_SHADOW_HORIZONTAL_OFFSET_CHANGED',
    GS_TABLE_HEADING2_FONT_SHADOW_HORIZONTAL_OFFSET_CHANGED = 'GS_TABLE_HEADING2_FONT_SHADOW_HORIZONTAL_OFFSET_CHANGED',
    GS_TABLE_ALTERNATE_FONT_SHADOW_HORIZONTAL_OFFSET_CHANGED = 'GS_TABLE_ALTERNATE_FONT_SHADOW_HORIZONTAL_OFFSET_CHANGED',

    GS_TABLE_NORMAL_FONT_SHADOW_VERTICAL_OFFSET_CHANGED = 'GS_TABLE_NORMAL_FONT_SHADOW_VERTICAL_OFFSET_CHANGED',
    GS_TABLE_HEADING1_FONT_SHADOW_VERTICAL_OFFSET_CHANGED = 'GS_TABLE_HEADING1_FONT_SHADOW_VERTICAL_OFFSET_CHANGED',
    GS_TABLE_HEADING2_FONT_SHADOW_VERTICAL_OFFSET_CHANGED = 'GS_TABLE_HEADING2_FONT_SHADOW_VERTICAL_OFFSET_CHANGED',
    GS_TABLE_ALTERNATE_FONT_SHADOW_VERTICAL_OFFSET_CHANGED = 'GS_TABLE_ALTERNATE_FONT_SHADOW_VERTICAL_OFFSET_CHANGED',

    GS_TABLE_NORMAL_BACKGROUND_COLOR_CHANGED = 'GS_TABLE_NORMAL_BACKGROUND_COLOR_CHANGED',
    GS_TABLE_HEADING1_BACKGROUND_COLOR_CHANGED = 'GS_TABLE_HEADING1_BACKGROUND_COLOR_CHANGED',
    GS_TABLE_HEADING2_BACKGROUND_COLOR_CHANGED = 'GS_TABLE_HEADING2_BACKGROUND_COLOR_CHANGED',
    GS_TABLE_ALTERNATE_BACKGROUND_COLOR_CHANGED = 'GS_TABLE_ALTERNATE_BACKGROUND_COLOR_CHANGED',

    GS_TABLE_NORMAL_BACKGROUND_COLOR_REMOVED = 'GS_TABLE_NORMAL_BACKGROUND_COLOR_REMOVED',
    GS_TABLE_HEADING1_BACKGROUND_COLOR_REMOVED = 'GS_TABLE_HEADING1_BACKGROUND_COLOR_REMOVED',
    GS_TABLE_HEADING2_BACKGROUND_COLOR_REMOVED = 'GS_TABLE_HEADING2_BACKGROUND_COLOR_REMOVED',
    GS_TABLE_ALTERNATE_BACKGROUND_COLOR_REMOVED = 'GS_TABLE_ALTERNATE_BACKGROUND_COLOR_REMOVED',

    GS_TABLE_NORMAL_BACKGROUND_GRADIENT_COLOR_CHANGED = 'GS_TABLE_NORMAL_BACKGROUND_GRADIENT_COLOR_CHANGED',
    GS_TABLE_HEADING1_BACKGROUND_GRADIENT_COLOR_CHANGED = 'GS_TABLE_HEADING1_BACKGROUND_GRADIENT_COLOR_CHANGED',
    GS_TABLE_HEADING2_BACKGROUND_GRADIENT_COLOR_CHANGED = 'GS_TABLE_HEADING2_BACKGROUND_GRADIENT_COLOR_CHANGED',
    GS_TABLE_ALTERNATE_BACKGROUND_GRADIENT_COLOR_CHANGED = 'GS_TABLE_ALTERNATE_BACKGROUND_GRADIENT_COLOR_CHANGED',

    GS_TABLE_NORMAL_BACKGROUND_GRADIENT_COLOR_REMOVED = 'GS_TABLE_NORMAL_BACKGROUND_GRADIENT_COLOR_REMOVED',
    GS_TABLE_HEADING1_BACKGROUND_GRADIENT_COLOR_REMOVED = 'GS_TABLE_HEADING1_BACKGROUND_GRADIENT_COLOR_REMOVED',
    GS_TABLE_HEADING2_BACKGROUND_GRADIENT_COLOR_REMOVED = 'GS_TABLE_HEADING2_BACKGROUND_GRADIENT_COLOR_REMOVED',
    GS_TABLE_ALTERNATE_BACKGROUND_GRADIENT_COLOR_REMOVED = 'GS_TABLE_ALTERNATE_BACKGROUND_GRADIENT_COLOR_REMOVED',

    GS_TABLE_NORMAL_BACKGROUND_GRADIENT_DIRECTION_CHANGED = 'GS_TABLE_NORMAL_BACKGROUND_GRADIENT_DIRECTION_CHANGED',
    GS_TABLE_HEADING1_BACKGROUND_GRADIENT_DIRECTION_CHANGED = 'GS_TABLE_HEADING1_BACKGROUND_GRADIENT_DIRECTION_CHANGED',
    GS_TABLE_HEADING2_BACKGROUND_GRADIENT_DIRECTION_CHANGED = 'GS_TABLE_HEADING2_BACKGROUND_GRADIENT_DIRECTION_CHANGED',
    GS_TABLE_ALTERNATE_BACKGROUND_GRADIENT_DIRECTION_CHANGED = 'GS_TABLE_ALTERNATE_BACKGROUND_GRADIENT_DIRECTION_CHANGED',

    GS_TABLE_NORMAL_BACKGROUND_GRADIENT_FADEPOINT_CHANGED = 'GS_TABLE_NORMAL_BACKGROUND_GRADIENT_FADEPOINT_CHANGED',
    GS_TABLE_HEADING1_BACKGROUND_GRADIENT_FADEPOINT_CHANGED = 'GS_TABLE_HEADING1_BACKGROUND_GRADIENT_FADEPOINT_CHANGED',
    GS_TABLE_HEADING2_BACKGROUND_GRADIENT_FADEPOINT_CHANGED = 'GS_TABLE_HEADING2_BACKGROUND_GRADIENT_FADEPOINT_CHANGED',
    GS_TABLE_ALTERNATE_BACKGROUND_GRADIENT_FADEPOINT_CHANGED = 'GS_TABLE_ALTERNATE_BACKGROUND_GRADIENT_FADEPOINT_CHANGED',

    GS_TABLE_NORMAL_BACKGROUND_OPACITY_CHANGED = 'GS_TABLE_NORMAL_BACKGROUND_OPACITY_CHANGED',
    GS_TABLE_HEADING1_BACKGROUND_OPACITY_CHANGED = 'GS_TABLE_HEADING1_BACKGROUND_OPACITY_CHANGED',
    GS_TABLE_HEADING2_BACKGROUND_OPACITY_CHANGED = 'GS_TABLE_HEADING2_BACKGROUND_OPACITY_CHANGED',
    GS_TABLE_ALTERNATE_BACKGROUND_OPACITY_CHANGED = 'GS_TABLE_ALTERNATE_BACKGROUND_OPACITY_CHANGED',

    GS_TABLE_NORMAL_BACKGROUND_ASSET_CHANGED = 'GS_TABLE_NORMAL_BACKGROUND_ASSET_CHANGED',
    GS_TABLE_HEADING1_BACKGROUND_ASSET_CHANGED = 'GS_TABLE_HEADING1_BACKGROUND_ASSET_CHANGED',
    GS_TABLE_HEADING2_BACKGROUND_ASSET_CHANGED = 'GS_TABLE_HEADING2_BACKGROUND_ASSET_CHANGED',
    GS_TABLE_ALTERNATE_BACKGROUND_ASSET_CHANGED = 'GS_TABLE_ALTERNATE_BACKGROUND_ASSET_CHANGED',

    GS_TABLE_NORMAL_BACKGROUND_ASSET_REMOVED = 'GS_TABLE_NORMAL_BACKGROUND_ASSET_REMOVED',
    GS_TABLE_HEADING1_BACKGROUND_ASSET_REMOVED = 'GS_TABLE_HEADING1_BACKGROUND_ASSET_REMOVED',
    GS_TABLE_HEADING2_BACKGROUND_ASSET_REMOVED = 'GS_TABLE_HEADING2_BACKGROUND_ASSET_REMOVED',
    GS_TABLE_ALTERNATE_BACKGROUND_ASSET_REMOVED = 'GS_TABLE_ALTERNATE_BACKGROUND_ASSET_REMOVED',

    GS_TABLE_NORMAL_BACKGROUND_ASSET_REPEAT_CHANGED = 'GS_TABLE_NORMAL_BACKGROUND_ASSET_REPEAT_CHANGED',
    GS_TABLE_HEADING1_BACKGROUND_ASSET_REPEAT_CHANGED = 'GS_TABLE_HEADING1_BACKGROUND_ASSET_REPEAT_CHANGED',
    GS_TABLE_HEADING2_BACKGROUND_ASSET_REPEAT_CHANGED = 'GS_TABLE_HEADING2_BACKGROUND_ASSET_REPEAT_CHANGED',
    GS_TABLE_ALTERNATE_BACKGROUND_ASSET_REPEAT_CHANGED = 'GS_TABLE_ALTERNATE_BACKGROUND_ASSET_REPEAT_CHANGED',

    GS_TABLE_NORMAL_BACKGROUND_ASSET_POSITION_CHANGED = 'GS_TABLE_NORMAL_BACKGROUND_ASSET_POSITION_CHANGED',
    GS_TABLE_HEADING1_BACKGROUND_ASSET_POSITION_CHANGED = 'GS_TABLE_HEADING1_BACKGROUND_ASSET_POSITION_CHANGED',
    GS_TABLE_HEADING2_BACKGROUND_ASSET_POSITION_CHANGED = 'GS_TABLE_HEADING2_BACKGROUND_ASSET_POSITION_CHANGED',
    GS_TABLE_ALTERNATE_BACKGROUND_ASSET_POSITION_CHANGED = 'GS_TABLE_ALTERNATE_BACKGROUND_ASSET_POSITION_CHANGED',

    GS_TABLE_NORMAL_BACKGROUND_ASSET_SIZE_CHANGED = 'GS_TABLE_NORMAL_BACKGROUND_ASSET_SIZE_CHANGED',
    GS_TABLE_HEADING1_BACKGROUND_ASSET_SIZE_CHANGED = 'GS_TABLE_HEADING1_BACKGROUND_ASSET_SIZE_CHANGED',
    GS_TABLE_HEADING2_BACKGROUND_ASSET_SIZE_CHANGED = 'GS_TABLE_HEADING2_BACKGROUND_ASSET_SIZE_CHANGED',
    GS_TABLE_ALTERNATE_BACKGROUND_ASSET_SIZE_CHANGED = 'GS_TABLE_ALTERNATE_BACKGROUND_ASSET_SIZE_CHANGED',

    GS_TABLE_NORMAL_BORDER_STYLE_CHANGED = 'GS_TABLE_NORMAL_BORDER_STYLE_CHANGED',
    GS_TABLE_HEADING1_BORDER_STYLE_CHANGED = 'GS_TABLE_HEADING1_BORDER_STYLE_CHANGED',
    GS_TABLE_HEADING2_BORDER_STYLE_CHANGED = 'GS_TABLE_HEADING2_BORDER_STYLE_CHANGED',
    GS_TABLE_ALTERNATE_BORDER_STYLE_CHANGED = 'GS_TABLE_ALTERNATE_BORDER_STYLE_CHANGED',

    GS_TABLE_NORMAL_BORDER_COLOR_CHANGED = 'GS_TABLE_NORMAL_BORDER_COLOR_CHANGED',
    GS_TABLE_HEADING1_BORDER_COLOR_CHANGED = 'GS_TABLE_HEADING1_BORDER_COLOR_CHANGED',
    GS_TABLE_HEADING2_BORDER_COLOR_CHANGED = 'GS_TABLE_HEADING2_BORDER_COLOR_CHANGED',
    GS_TABLE_ALTERNATE_BORDER_COLOR_CHANGED = 'GS_TABLE_ALTERNATE_BORDER_COLOR_CHANGED',

    GS_TABLE_NORMAL_BORDER_COLOR_OPACITY_CHANGED = 'GS_TABLE_NORMAL_BORDER_COLOR_OPACITY_CHANGED',
    GS_TABLE_HEADING1_BORDER_COLOR_OPACITY_CHANGED = 'GS_TABLE_HEADING1_BORDER_COLOR_OPACITY_CHANGED',
    GS_TABLE_HEADING2_BORDER_COLOR_OPACITY_CHANGED = 'GS_TABLE_HEADING2_BORDER_COLOR_OPACITY_CHANGED',
    GS_TABLE_ALTERNATE_BORDER_COLOR_OPACITY_CHANGED = 'GS_TABLE_ALTERNATE_BORDER_COLOR_OPACITY_CHANGED',

    GS_TABLE_NORMAL_BORDER_WIDTH_CHANGED = 'GS_TABLE_NORMAL_BORDER_WIDTH_CHANGED',
    GS_TABLE_HEADING1_BORDER_WIDTH_CHANGED = 'GS_TABLE_HEADING1_BORDER_WIDTH_CHANGED',
    GS_TABLE_HEADING2_BORDER_WIDTH_CHANGED = 'GS_TABLE_HEADING2_BORDER_WIDTH_CHANGED',
    GS_TABLE_ALTERNATE_BORDER_WIDTH_CHANGED = 'GS_TABLE_ALTERNATE_BORDER_WIDTH_CHANGED',

    GS_TABLE_NORMAL_CELL_SPACING_CHANGED = 'GS_TABLE_NORMAL_CELL_SPACING_CHANGED',
    GS_TABLE_HEADING1_CELL_SPACING_CHANGED = 'GS_TABLE_HEADING1_CELL_SPACING_CHANGED',
    GS_TABLE_HEADING2_CELL_SPACING_CHANGED = 'GS_TABLE_HEADING2_CELL_SPACING_CHANGED',
    GS_TABLE_ALTERNATE_CELL_SPACING_CHANGED = 'GS_TABLE_ALTERNATE_CELL_SPACING_CHANGED';

