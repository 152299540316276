import CALL_API from "../../../redux/middleware/api/CALL_API";
import {
    REPOSITORY_PAGE_DATA_LOAD_REQUEST,
    REPOSITORY_PAGE_DATA_LOAD_SUCCESS,
    REPOSITORY_PAGE_DATA_LOAD_FAILURE
} from "../actionTypes";

export default function (accountName: string, layoutId: string, config: any = {}): any {
    return {
        [CALL_API]: {
            types: [
                REPOSITORY_PAGE_DATA_LOAD_REQUEST,
                REPOSITORY_PAGE_DATA_LOAD_SUCCESS,
                REPOSITORY_PAGE_DATA_LOAD_FAILURE
            ],
            endpoint: 'getPageLayoutDataSet',
            // endpoint: 'getRepositoryPageDataSet',
            endpointParams: [
                accountName,
                layoutId,
                {
                    flagAutoColorActive: config.flagAutoColorActive
                }
            ]
        }
    };
}
