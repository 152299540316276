import CALL_API from "../../../redux/middleware/api/CALL_API";
import { BACKUP_RESTORE_FAIL, BACKUP_RESTORE_REQUEST, BACKUP_RESTORE_SUCCESS } from "../actionTypes";
import type { ApiAction } from "../../../redux/middleware/api/flowTypes";

export default function (time: any): any {
    const action: ApiAction = {
        [CALL_API]: {
            types: [
                BACKUP_RESTORE_REQUEST,
                BACKUP_RESTORE_SUCCESS,
                BACKUP_RESTORE_FAIL
            ],
            endpoint: 'requestBackupRestore',
            endpointParams: parseInt(time, 10)
        }
    };
    return action;
}
