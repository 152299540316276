import React from 'react';
import { connect } from 'react-redux';
import styles from './FileChooserUpload.css';
import StandardDialog from '../../dialogs/StandardDialog/index';
import { CLOSE_DIALOG } from "../../../../redux/modules/actionTypes";
import { FC_URL_UPLOAD } from "../../../../redux/modules/children/fileChooser/actionTypes";
import getCenteredDialogConfig from "../../../../components/DialogManager/getCenteredDialogConfig";
import ExternalUrl from '../../ExternalUrl';
import type { FileChooserUrlDialogProps } from "../flowTypes";

const
    width = styles.urlDialogWidth,
    height = styles.urlDialogHeight,
    title = 'msg: fc.externalUrlDialog.title {Upload files}',
    mctaText = 'msg: common.upload {Upload}',
    sctaText = 'msg: common.cancel {Cancel}';

type State = {
    url: string,
    isValid: boolean
};

class UrlDialog extends React.Component<FileChooserUrlDialogProps, State> {
    constructor() {
        // @ts-ignore
        super();
        this.state = {
            url: 'http://',
            isValid: false
        };
    }

    onChange(url, isValid) {
        this.setState({ url, isValid });
    }

    render() {
        const
            { closeDialog } = this.props,
            { isValid, url } = this.state,
            invalid = !isValid,
            upload = () => { if (this.state.isValid) this.props.upload(this.state.url); };

        return (
            <StandardDialog
                title={title}
                mctaText={mctaText}
                sctaText={sctaText}
                mctaHandler={upload}
                sctaHandler={closeDialog}
                disabled={invalid}
                noTopBorderForFooter
            >
                <ExternalUrl
                    className={styles.urlDialogInput}
                    url={url}
                    onChange={this.onChange.bind(this)}
                    onEnter={upload}
                    validatePathPresence
                />
            </StandardDialog>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    upload: (url: string) => dispatch({ type: FC_URL_UPLOAD, payload: { url } }),
    closeDialog: () => dispatch({ type: CLOSE_DIALOG })
});

export default getCenteredDialogConfig(width, height, connect(null, mapDispatchToProps)(UrlDialog));
