import type { ButtonComponent } from "../flowTypes";

export default (component: ButtonComponent, { payload: { stylesheetId, stylesheetName } }: any): ButtonComponent => {
    return {
        ...component,
        style: {
            ...component.style,
            globalName: stylesheetName,
            globalId: stylesheetId
        }
    };
};
