import * as kinds from "./kinds";
import factory from "./factory";
import positionsFactory from "./positionsFactory";

import type { HandleCreator } from './flowTypes';

export const
    HandleWidthPx = 200,
    HandleHeightPx = 24;
/*
*         -
*         N
*   -           +
*         S
*         +
*/

const

    handleCreatorFactory = factory(() => HandleWidthPx, () => HandleHeightPx),
    {
        horizontalCenter, top, bottom
    } = positionsFactory(HandleWidthPx, HandleHeightPx),
    addIconHandleCreatorN = handleCreatorFactory(top, horizontalCenter, kinds.AddIconN),
    addIconHandleCreatorS = handleCreatorFactory(bottom, horizontalCenter, kinds.AddIconS),
    addIcon: Array<HandleCreator> = [
        addIconHandleCreatorN,
        addIconHandleCreatorS
    ];

export {
    addIcon,
    addIconHandleCreatorN,
    addIconHandleCreatorS,
};

