import type { ScrollTopAdjustProps, Sequence, CmpHeights } from "../../flowTypes";

const isSelectedCmpInsideContainer = (parent, data, selectedCmpId) => {
    let isFound = false;
    const processChildren = (children) => {
        if (children && children.length) {
            for (let i = 0; i < children.length; i++) {
                if (children[i] === selectedCmpId) {
                    isFound = true;
                    break;
                }
                processChildren(data[children[i]]);
            }
        }
    };
    processChildren(data[parent]);
    return isFound;
};

export const getSelectedCmpTopWRTContent = (root: string, latestData: Sequence, cmpsHeights: CmpHeights,
    selectedCmpId: string, cmpStyles: Record<string, any>) => {
    let found = false, top = 0;
    const processChildren = (children) => {
        let childrenHeight = 0;
        if (!found && children) {
            for (let i = 0; i < children.length; ++i) {
                if (children[i] === selectedCmpId) {
                    found = true;
                    break;
                }
                if (!found && latestData[children[i]] && latestData[children[i]].length) {
                    if (isSelectedCmpInsideContainer(children[i], latestData, selectedCmpId)) {
                        processChildren(latestData[children[i]]);
                    } else {
                        top += cmpsHeights[children[i]];
                    }
                } else if (!found) {
                    childrenHeight = cmpsHeights[children[i]] +
                        (cmpStyles[children[i]].marginTop || 0) +
                        (cmpStyles[children[i]].marginBottom || 0);
                    top += childrenHeight;
                    childrenHeight = 0;
                }
            }
        }
    };

    processChildren(latestData[root]);
    return top;
};

export default ({
    root,
    currentScrollTop,
    cmpHeights,
    oldData,
    oldStyles,
    latestData,
    cmpStyles,
    selectedCmpId,
    down
}: ScrollTopAdjustProps) => {
    let newScrollTopValue = currentScrollTop;
    if (cmpHeights) {
        const newTopWRTContent = getSelectedCmpTopWRTContent(root, latestData, cmpHeights, selectedCmpId, cmpStyles),
            oldTopWRTOldContent = getSelectedCmpTopWRTContent(root, oldData, cmpHeights, selectedCmpId, oldStyles);

        if (down) {
            newScrollTopValue = currentScrollTop + newTopWRTContent - oldTopWRTOldContent;
        } else {
            newScrollTopValue = currentScrollTop - oldTopWRTOldContent + newTopWRTContent;
        }
    }
    return newScrollTopValue;
};
