import { makeApplyEditorChangesReducer } from "./helpers/helpers";
import { applyFormatValue } from "../editorUtils/methods/helpers/applyFormatValue";
import { TINY_MCE_SET_LINE_SPACING } from "../actionTypes";

const onSetLineSpacingUpdater = {
    conditions: [TINY_MCE_SET_LINE_SPACING],
    reducer: makeApplyEditorChangesReducer(({ state, scope, values: [lineHeight], editor }) => {
        applyFormatValue(editor, 'lineHeight', lineHeight);

        return {
            state: {
                ...state,
                lineHeight
            },
            scope
        };
    })
};

export {
    onSetLineSpacingUpdater
};
