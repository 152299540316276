import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { injectIntl, Intl } from "../../../../view/intl/index";
import { makeEpicStateSelector } from "../../../../epics/makeEpic";
import MODERN_LAYOUT_ONBOARDING_PANEL_EPIC_VALUE from '../../epics/onBoardingPanel/valueActionType';
import MODERN_LAYOUT_LEFT_PANEL_EPIC_VALUE from '../../epics/leftPanel/valueActionType';
import * as styles from './onBoardingPanel.css';
import { OnBoardingModeOn } from './screens/OnBoardingModeOn';
import type { LeftPanelEpicState, OnBoardingPanelEpicState } from '../../flowTypes';
import { ComponentsEvalValueActionType } from "../../../Workspace/epics/componentsEval/valueActionType";
import { headerSectionIdVAT } from '../../../oneweb/Section/epics/headerSectionIdEpic';
import { footerSectionIdVAT } from '../../../oneweb/Section/epics/footerSectionIdEpic';
import { stylesheetAppSel } from "../../../Workspace/epics/stylesheets/selectors";
import { globalVariablesEpic } from '../../../App/epics/globalVariablesEpic';
import type { Stylesheets } from "../../../Workspace/epics/stylesheets/flowTypes";
import workspaceTemplateVAT from "../../../oneweb/Template/epics/template/valueActionType";
import type { TemplateComponent } from "../../../oneweb/Template/flowTypes";
import NoMouseEventsPropagation from "../../../../view/common/NoMouseEventsPropagation";
import { MODERN_LAYOUT_ONBOARDING_BKGD_CLICKED } from "../../actionTypes";
import { ONBOARDING_STEPS, HEADER_FOOTER_LAYOUT_STEP } from '../../constants';
import webShopMHFValueActionType from "../../../oneweb/WebShopMHF/epic/valueActionType";
import type { WebShopMHFEpicState } from "../../../oneweb/WebShopMHF/flowTypes";

type Props = {
    intl: Intl,
    dispatch: Dispatch,
    onBoardingPanel: OnBoardingPanelEpicState,
    leftPanel: LeftPanelEpicState,
    template: TemplateComponent,
    componentsEval: Record<string, any>,
    headerSectionId: string,
    footerSectionId: string,
    globalVariables: Record<string, any>,
    styleSheets: Stylesheets,
    webshopMHFData: WebShopMHFEpicState,
};
const ModernLayoutOnBoardingPanel = (props: Props) => {
    const {
        intl,
        dispatch,
        onBoardingPanel,
        leftPanel,
        template,
        componentsEval,
        headerSectionId,
        footerSectionId,
        globalVariables,
        styleSheets,
        webshopMHFData,
    } = props;
    const headerSection = componentsEval.state.componentsMap[headerSectionId],
        footerSection = componentsEval.state.componentsMap[footerSectionId];

    if (!onBoardingPanel.show) {
        return <div className={cx(styles.modernLayoutOnBoardingPanel, styles.fullHeight)} {...NoMouseEventsPropagation} />;
    }

    const currentStepNumber = onBoardingPanel.currentStep,
        currentStepName = ONBOARDING_STEPS[currentStepNumber];

    let View = <OnBoardingModeOn
        onBoardingPanel={onBoardingPanel}
        leftPanel={leftPanel}
        intl={intl}
        dispatch={dispatch}
        headerSection={headerSection}
        footerSection={footerSection}
        template={template}
        componentsEval={componentsEval}
        globalVariables={globalVariables}
        styleSheets={styleSheets}
        webshopMHFData={webshopMHFData}
    />;
    return (
        <React.Fragment>
            <div className={cx(styles.modernLayoutOnBoardingPanel, styles.active, styles.fullHeight)} {...NoMouseEventsPropagation}>
                {View}
            </div>
            {currentStepName !== HEADER_FOOTER_LAYOUT_STEP &&
            <React.Fragment>
                <div
                    onClick={() => dispatch({ type: MODERN_LAYOUT_ONBOARDING_BKGD_CLICKED })}
                    className={styles.backgroundPanel}
                    {...NoMouseEventsPropagation}
                />
                <div
                    onClick={() => dispatch({ type: MODERN_LAYOUT_ONBOARDING_BKGD_CLICKED })}
                    className={styles.topBarBackgroundPanel}
                    {...NoMouseEventsPropagation}
                />
            </React.Fragment>}
        </React.Fragment>
    );
};

export const
    onBoardingPanelDataSelector = makeEpicStateSelector(MODERN_LAYOUT_ONBOARDING_PANEL_EPIC_VALUE),
    leftPanelDataSelector = makeEpicStateSelector(MODERN_LAYOUT_LEFT_PANEL_EPIC_VALUE);

const mapStateToProps = (appState) => {
    const onBoardingPanel = onBoardingPanelDataSelector(appState),
        leftPanel = leftPanelDataSelector(appState);
    return {
        onBoardingPanel,
        leftPanel,
        template: makeEpicStateSelector(workspaceTemplateVAT)(appState),
        componentsEval: makeEpicStateSelector(ComponentsEvalValueActionType)(appState),
        headerSectionId: makeEpicStateSelector(headerSectionIdVAT)(appState),
        footerSectionId: makeEpicStateSelector(footerSectionIdVAT)(appState),
        styleSheets: stylesheetAppSel(appState),
        globalVariables: makeEpicStateSelector(globalVariablesEpic.valueActionType)(appState),
        webshopMHFData: makeEpicStateSelector(webShopMHFValueActionType)(appState),
    };
};
export default injectIntl(connect(mapStateToProps)(ModernLayoutOnBoardingPanel));
