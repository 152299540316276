import React, { useRef, useEffect } from "react";
import Lottie from "react-web-lottie";

import animationData from "./loaderAnimation.json";
import { getStepMarkers, LOTTIE_DEFAULT_OPTIONS } from "../../../view/LottieAnimations/constant";

import { STEP_MARKERS } from "./constants";
import { useIntl } from "../../../../../view/intl";

const markers = animationData.markers;
const stepMarkers = getStepMarkers(markers, "TEMPLATE_LOADER", STEP_MARKERS.TEMPLATE_LOADER);
let startPoint, stopPoint, initialSegment;

export const TemplateLoaderAnimation = () => {
    const intl = useIntl();
    const animationRef = useRef(null);

    startPoint = stepMarkers.pronouns_added;
    stopPoint = stepMarkers.template_loader_start_point;
    initialSegment = [startPoint, stopPoint];

    const defaultOptions = {
        ...LOTTIE_DEFAULT_OPTIONS,
        loop: true,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid meet"
        },
        initialSegment,
        animationData
    };

    const texts = [
        "msg: onboarding.dynamic.loading.step1 {Designing your website based on your category...}",
        "msg: onboarding.dynamic.loading.step2 {Writing your customised website texts...}",
        "msg: onboarding.dynamic.loading.step3 {Finding perfect images for you...}",
        "msg: onboarding.dynamic.loading.step4 {Selecting colours that match your design...}",
        "msg: onboarding.dynamic.loading.step5 {Adjusting sections to fit your needs...}"
    ];

    useEffect(() => {
        // @ts-ignore
        animationRef.current.playSegments([[startPoint, stopPoint]], true);
        // @ts-ignore
        if (animationRef.current?.renderer) {
            for (let i = 0; i < texts.length; i++) {
                // @ts-ignore
                animationRef.current.renderer.elements[4 - i].updateDocumentData({
                    t: intl.msgJoint(texts[i])
                });
            }
        }
        startPoint = stepMarkers.template_loader_start_point;
        stopPoint = stepMarkers.template_loader_end_point;

        // @ts-ignore
        animationRef.current.playSegments([[startPoint, stopPoint]], true);
    }, []);

    return (
        <Lottie lottiRef={animationRef} options={defaultOptions} isClickToPauseDisabled />
    );
};
