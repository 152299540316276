/* @flow */

function textContentHasLinkGlobalstyle(content /*: string */, name /*: string */) {
    let found = false;

    // IMPORTANT: See https://regexr.com/39rsv
    const linkTags = content.match(/<a[\s]+([^>]+)>((?:.(?!<\/a>))*.)<\/a>/g);  //NOSONAR

    if (Array.isArray(linkTags)) {
        const linkClasses /*: string[] */ = linkTags.reduce((acc /*: string[] */, linkTag /*: string */) => {
            const linkClass = linkTag.match(/class=(['"])([^\1])*?\1/g);
            return linkClass ? [].concat(acc).concat(linkClass) : acc;
        }, []);

        const linkGlobalstyles = linkClasses.filter(linkClass => {
            const linkGlobalstyle = linkClass.match(/\blink\d+\b/g);
            return Array.isArray(linkGlobalstyle) ? linkGlobalstyle[0] : null;
        });

        found = linkGlobalstyles.indexOf(name) > -1;
    }

    return found;
}

// NOTE: Exported this way as it used in server side code also
module.exports = textContentHasLinkGlobalstyle;
module.exports.contentKey = 'content';
