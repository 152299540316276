import React from 'react';
import { IMAGE_PROP_PANEL_ON_CLICK_ACTION_CHANGED, IMAGE_SET_LINK } from '../../actionTypes';
import * as pageIds from './ids';
import pageTitles from './titles';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import getOnClickAction from "../../getOnClickAction";
import type { ImageComponent } from '../../flowTypes';
import PpOnClickActionComboBox from '../../../Gallery/PpOnClickActionComboBox';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import * as OnClickActions from '../../../Gallery/constants/onClickActions';
import { PropertiesButton } from '../../../../../view/common/Button/index';
import openLinkChooserDialog from "../../../../../view/common/dialogs/LinkChooserDialog/actionCreators/openLinkChooserDialog"; // eslint-disable-line max-len
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';

export type PropTypes = {
    selectedComponent: ImageComponent,
    dispatch: Dispatch
};

const
    id: string = pageIds.ONCLICK,
    title = pageTitles[id],
    view = ({ selectedComponent, dispatch }: PropTypes) => {
        const onClickAction = getOnClickAction(selectedComponent);
        return (
            <Page>
                <VerticalSpacer />
                <PpOnClickActionComboBox
                    actionType={IMAGE_PROP_PANEL_ON_CLICK_ACTION_CHANGED}
                    value={onClickAction}
                    dispatch={dispatch}
                />
                {
                    onClickAction === OnClickActions.OPEN_LINK &&
                    <div>
                        <VerticalSpacer />
                        <PropertyContainer>
                            <PropertiesButton
                                text={selectedComponent.linkAction ?
                                    'msg: component.image.pp.onClick.editLink {Edit link}' :
                                    'msg: component.image.pp.onClick.addLink {Add link}'}
                                onClick={() => dispatch(
                                    openLinkChooserDialog({
                                        setLinkAction: IMAGE_SET_LINK,
                                        input: selectedComponent.linkAction
                                    })
                                )}
                            />
                        </PropertyContainer>
                    </div>
                }
            </Page>
        );
    };

export default { id, view, title };
