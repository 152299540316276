import React from 'react';

type Props = {
    x?: number,
    className?: string,
    inline?: boolean,
}

const DEFAULT_WIDTH = 10;

export default ({ x = DEFAULT_WIDTH, className, inline = false }: Props) => {
    const additionalProps: Record<string, any> = {};
    if (inline === true) {
        additionalProps.display = 'inline-block';
    }
    return (
        <div
            className={className}
            style={{
                ...additionalProps,
                height: 'inherit',
                width: x,
            }}
        >&nbsp;</div>
    );
};
