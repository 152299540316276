import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
    (theme: Theme) => ({
        topProgressBar: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
        },
        scoreProgressMainText: {
            fontSize: "26px",
            lineHeight: "32px",
            letterSpacing: "0.1px",
            textAlign: "center",
            [theme.breakpoints.up('sm')]: {
                fontSize: "34px",
                lineHeight: "41px",
                letterSpacing: "0.2px",
            },
        },
        scoreProgressSecondaryText: {
            fontSize: "10px",
            lineHeight: "12px",
            letterSpacing: "0.07px",
            textAlign: "center",
            [theme.breakpoints.up('sm')]: {
                fontSize: "15px",
                lineHeight: "18px",
                letterSpacing: "0.1px",
            },
        },
    }),
    { name: "CircularProgress" }
);
