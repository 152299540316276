/* eslint-disable max-len */
import React from "react";
import cx from "classnames";
import * as styles from "../../AutoColorLeftPanel.css";
import { Intl } from "../../../../view/intl/index";

type ColorThemeDescriptionProps = {
    currentAutoColorMode: boolean;
    intl: Intl;
};
export const ColorThemeDescription = (props: ColorThemeDescriptionProps) => {
    const { currentAutoColorMode, intl } = props;
    const descriptionList = [
        intl.msgJoint("msg: autoColor.leftPanel.themeOff.descriptionItem1 {Set and update site colours from one single place}"),
        intl.msgJoint("msg: autoColor.leftPanel.themeOff.descriptionItem2 {Style entire groups of components in just one click}"),
        intl.msgJoint("msg: autoColor.leftPanel.themeOff.descriptionItem3 {Get inspired by a selection of predefined colour themes}")
    ];
    return (
        <div className={cx(styles.colourThemeDescription)}>
            {currentAutoColorMode ? (
                intl.msgJoint("msg: autoColor.leftPanel.topSubTitle {Edit your colour theme or select from a number of predefined themes.}")
            ) : (
                <div>
                    {intl.msgJoint(
                        "msg: autoColor.leftPanel.topSubTitleThemeOff {Your colour theme is currently switched off. Turn it back on to:}"
                    )}
                    <ul className={styles.list}>
                        {descriptionList.map((item, i) => {
                            return (
                                <li key={i} className={styles.item}>
                                    <span>{item}</span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
            <div className={styles.learnMoreLinkWrapper}>
                <a
                    className={styles.learnMoreLink}
                    href={intl.msgJoint(
                        "msg: autoColor.leftPanel.themeOff.learnMore {https://help.one.com/hc/en-us/articles/360015355958}"
                    )}
                    target="_blank"
                >
                    {intl.msgJoint("msg: common.learnMore {Learn more}")}
                </a>
            </div>
        </div>
    );
};
