import * as React from "react";
import cx from "classnames";
import * as colorMapper from "../../../mappers/color";
import * as parseColor from "../utils/parseColor";
import styles from "./ColoredSwatch.css";

import type { Color } from "../../../mappers/flowTypes";

type Props = {
    color: Color;
    onMouseOut?: Function;
    onMouseEnter?: Function;
    onClick?: Function;
    selected?: boolean;
    style?: any;
    className?: any;
};

export const ColoredSwatch = ({
    style,
    color,
    onClick,
    selected,
    className,
    onMouseEnter,
    onMouseOut
}: Props) => {
    const toColor = { hsl: parseColor.toColorPicker(color) };
    let backgroundColor = [...color] as Color;
    backgroundColor[backgroundColor.length - 1] = 1; // Set opacity to 1 to the color as we dont consider opacity for recent colors
    return (
        <div
            className={cx(className, {
                [styles.coloredSwatch]: true,
                [styles.selected]: selected,
                [styles.dark]: colorMapper.isDark(color),
                [styles.light]: colorMapper.isLight(color),
                [styles.veryLight]: colorMapper.isVeryLight(color),
                [styles.clickable]: !!onClick
            })}
            style={{
                ...style,
                backgroundColor:
                colorMapper.toCss(backgroundColor)
            }}
            onClick={e => onClick && onClick(toColor, e)}
            onMouseOut={onMouseOut ? () => onMouseOut(toColor) : undefined}
            onMouseEnter={onMouseEnter ? () => onMouseEnter(toColor) : undefined}
        />
    );
};
