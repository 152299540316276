import React from 'react';
import TextIconButton from '../TextIconButton/factory';
import type { CellSpacingPropTypes } from "./flowTypes";

const button = ({ dialogId, spacing, spacingChangeAction, dispatch, additionalDialogProps = {} }: CellSpacingPropTypes) => ( // eslint-disable-line max-len
    // @ts-ignore
    <TextIconButton
        text="msg: common.spacing {Spacing}"
        dialogId={dialogId}
        dialogProps={{ values: spacing, onChangeAction: spacingChangeAction, ...additionalDialogProps }}
        dispatch={dispatch}
    />
);

export {
    button
};
