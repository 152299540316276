import React from "react";
import cx from "classnames";
import { Flex } from "../../../../view/reflexbox/index";
import Icons from '../../../../view/Icons/index';
import { injectIntl, Intl } from '../../../../view/intl/index';
import Button from "../../Button/index";
import buttonStyles from '../../../buttons.css';
import styles from '../IconButtonsGroup.css';
import type { VerticalAlignment } from "../../../Workspace/epics/stylesheets/flowTypes";

type Props = {
    value: VerticalAlignment;
    onBtnClick: (value: VerticalAlignment) => void;
    disabled?: boolean;
    isPropertiesPanel?: boolean;
    intl: Intl;
}

const
    textVerticalAlignmentButtons = { // todo WBTGEN-2272 IconButtonsGroup/TextAlignmentButtonGroup.js titles should use Intl
        top: { title: 'msg: common.top {Top}', icon: Icons.CELLALIGNTOP },
        middle: { title: 'msg: common.middle {Middle}', icon: Icons.CELLALIGNMIDDLE },
        bottom: { title: 'msg: common.bottom {Bottom}', icon: Icons.CELLALIGNBOTTOM }
    },
    factory = values => injectIntl(({ value, onBtnClick, disabled, isPropertiesPanel, intl }: Props) => (
        <Flex align="center" justify="space-between">
            {
                values
                    .map(key => ({ value: key, ...textVerticalAlignmentButtons[key] }))
                    .map((item, index) => (
                        <Button
                            key={index}
                            className={cx(styles.iconButton, buttonStyles.buttonGroup, {
                                [styles.selected]: item.value === value,
                                [styles.iconButtonPropertiesPanel]: isPropertiesPanel
                            })}
                            isSelected={item.value === value}
                            title={intl.msgJoint(item.title)}
                            onClick={disabled ? undefined : () => onBtnClick(item.value)}
                            disabled={disabled}
                        >
                            <item.icon />
                        </Button>
                    ))
            }
        </Flex>
    )),
    TextVerticalAlignmentButtonsGroup = factory(['top', 'middle', 'bottom']);

export {
    TextVerticalAlignmentButtonsGroup
};
