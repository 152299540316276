import type { LinkChooserInterchangeType } from "../flowTypes";
import { LcTabName } from "../constants";
import { Lit } from "../../../../../lit";
import { isEmailValid } from './isEmailValid';
import { isLocationValid } from './isLocationValid';
import { isPhoneValid } from './isPhoneValid';

export default (state, globalVariables: Record<string, any>): null | undefined | LinkChooserInterchangeType => {
    const
        { activeTabName: type, openInNewWindow } = state,
        selection = state[type] && (state[type].selection || state[type].value);

    if (!selection
        || (type === LcTabName.URL && !state[type].isValid)

        || (type === LcTabName.EMAIL && !isEmailValid(state[type], globalVariables))

        || (type === LcTabName.LOCATION && !isLocationValid(state[type], globalVariables))

        || (type === LcTabName.PHONE && !isPhoneValid(state[type], globalVariables))
    ) {
        return null;
    }

    let value;

    if (type === LcTabName.EMAIL) {
        value = { selection, [Lit.subject]: state[type][Lit.subject][Lit.value] };
    } else if (type === LcTabName.LOCATION) {
        value = { selection, [Lit.name]: state[type][Lit.name] };
    } else if (type === LcTabName.SECTION_LINK) {
        value = { [Lit.pageId]: selection, [Lit.sectionId]: state[type][Lit.sectionId] };
    } else {
        value = selection;
    }

    return {
        link: {
            type,
            value,
        },
        openInNewWindow
    };
};
