/* @flow */

const getChatWidgetData = (siteSettings /*: Object */) /*: Object */ => {
    const result = {
        chatWidgetLanguage: "en_US",
        enableChatWidget: false,
        themeColor: "#0084FF"
    };
    if (siteSettings && siteSettings.chatWidgetData && siteSettings.chatWidgetData.enableChatWidget) {
        result.enableChatWidget = true;
        result.chatWidgetLanguage = siteSettings.chatWidgetData.chatWidgetLanguage;
        result.themeColor = siteSettings.chatWidgetData.chatWidgetColor;
    }
    return result;
};



module.exports = {
    getChatWidgetData
};
