export const
    AlignmentTableTitleMsg = {
        '0%0%': 'msg: bg.imagePosition.topLeft {Top, Left}',
        '0%50%': 'msg: bg.imagePosition.topCenter {Top, Center}',
        '0%100%': 'msg: bg.imagePosition.topRight {Top, Right}',
        '50%0%': 'msg: bg.imagePosition.middleLeft {Middle, Left}',
        '50%50%': 'msg: bg.imagePosition.middleCenter {Middle, Center}',
        '50%100%': 'msg: bg.imagePosition.middleRight {Middle, Right}',
        '100%0%': 'msg: bg.imagePosition.bottomLeft {Bottom, Left}',
        '100%50%': 'msg: bg.imagePosition.bottomCenter {Bottom, Center}',
        '100%100%': 'msg: bg.imagePosition.bottomRight {Bottom, Right}',
    };
