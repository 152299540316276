import * as R from "ramda";
import { applyMappers, makePlainMappers } from "../../utils";
import * as mp from "../../../../src/mappers/path";
import * as styleMapper from '../Common/style';
import { evolvePath } from "../../../../src/utils/ramdaEx";
import type { AnyComponentData } from "../../../../src/components/App/flowTypes";
import type { ComponentBaseWithKind } from "../../../../src/components/oneweb/flowTypes";
import * as actionMapper from "../Common/action";

const plainPropsMapper = makePlainMappers({
    style: 'style',
    linkAction: 'linkAction',
    mobileHide: 'mobileHide',
    selectedTheme: 'selectedTheme',
    selectedGradientTheme: 'selectedGradientTheme',
    selectedBorderTheme: 'selectedBorderTheme',
    hover: 'hover',
    mobileSettings: 'mobileSettings'
});

export function to(componentData: AnyComponentData): ComponentBaseWithKind {
    return applyMappers(
        componentData,
        R.pipe(
            plainPropsMapper.to,
            evolvePath([mp.style])(styleMapper.to),
            evolvePath(['linkAction'])(actionMapper.to)
        )
    );
}

export function back(component: ComponentBaseWithKind): AnyComponentData {
    return applyMappers(
        component,
        R.pipe(
            plainPropsMapper.back,
            evolvePath([mp.style])(styleMapper.back),
            evolvePath(['linkAction'])(actionMapper.back)
        )
    );
}
