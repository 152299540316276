import makeEpic from "../../../../../epics/makeEpic";
import valueActionType from "./valueActionType";
import {
    YOUTUBE_VIDEOS_FETCH_REQUEST,
    YOUTUBE_VIDEOS_FETCH_SUCCESS,
    YOUTUBE_VIDEOS_FETCH_FAILURE,
    YOUTUBE_VIDEO_PREVIEW,
    PASTE_YOUTUBE_VIDEO_LINK,
    YOUTUBE_REPLACE_VIDEO_UPDATE,
    YOUTUBE_VIDEOS_SEARCH
} from "../../actionTypes";
import {
    COMPONENT_CONFIGURATION_COMPLETE,
    COMPONENT_CONFIGURATION_CANCELED
} from "../../../../../redux/modules/children/workspace/actionTypes";
import type { YoutubeVideosList } from "../../view/flowTypes";
import youTubeVideosSearchActionCreator from "../../actionCreators/searchYoutubeVideos";
import { UPDATE_DIALOG } from "../../../../../redux/modules/actionTypes";
import { YoutubeConfigDialogId } from "../../dialogIds";

export const updateRecords = (state: YoutubeVideosList, data: any): YoutubeVideosList => {
    const nextPageToken = (data && data.nextPageToken) || '',
        { fullLoad } = state;
    let records = [];

    if (data && data.items.length) {
        records = data.items.map(item => {
            const { snippet } = item;
            return {
                videoId: item.id.videoId,
                title: snippet.title,
                author: (function (raw) {
                    if (raw) {
                        return 'by ' + raw;
                    }
                    return '';
                }(snippet.channelTitle)),
                description: (function (raw) {
                    if (raw.length > 120) {
                        return raw.substring(0, 120) + '...';
                    }
                    return raw;
                }(snippet.description)),
                published: (function (raw) {
                    // Format date
                    // @ts-ignore
                    let time: string | Date = new Date(new Date() - new Date(raw)),
                        temp,
                        text;

                    if ((temp = (time.getFullYear() - 1970)) > 0) { // eslint-disable-line no-cond-assign
                        time = temp + ' year';
                    } else if (time.getMonth()) {
                        time = time.getMonth() + ' month';
                    } else if (time.getHours()) {
                        time = time.getHours() + ' hour';
                    } else if (time.getMinutes()) {
                        time = time.getMinutes() + ' minute';
                    } else {
                        text = 'Few seconds ago';
                    }

                    if (!text) {
                        text = time.toString() + ' ago';
                    }

                    return text;
                }(snippet.publishedAt)),
                // remove specific http protocol to avoid unnecessary security errors
                thumbnailSrc: snippet.thumbnails.default.url.replace(/^http:/, '')
            };
        });
    }

    return {
        loading: false,
        nextPageToken,
        records: fullLoad ? records : [...(state.records ? state.records : []), ...records],
        fullLoad
    };
};

const
    defaultState: YoutubeVideosList = { loading: false, fullLoad: false, nextPageToken: '', records: null },
    makeDefaultStateUpdater = (actionType: string) => ({
        conditions: [actionType],
        reducer: () => ({ state: defaultState })
    });

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [YOUTUBE_VIDEOS_FETCH_REQUEST],
            reducer: ({ values: [{ fullLoad }], state }) => ({
                state: {
                    ...state,
                    loading: true,
                    records: (fullLoad ? [] : state.records),
                    fullLoad
                },
                actionToDispatch: {
                    type: UPDATE_DIALOG,
                    payload: { dialogId: YoutubeConfigDialogId }
                }
            })
        },
        {
            conditions: [YOUTUBE_VIDEOS_FETCH_SUCCESS],
            reducer: ({ values: [response], state }) => ({ state: updateRecords(state, response) })
        },
        {
            conditions: [YOUTUBE_VIDEOS_FETCH_FAILURE],
            reducer: ({ state }) => ({ state: { ...state, loading: false } })
        },
        makeDefaultStateUpdater(COMPONENT_CONFIGURATION_COMPLETE),
        makeDefaultStateUpdater(COMPONENT_CONFIGURATION_CANCELED),
        makeDefaultStateUpdater(YOUTUBE_REPLACE_VIDEO_UPDATE),
        {
            conditions: [YOUTUBE_VIDEO_PREVIEW],
            reducer: ({ values: [{ videoId }], state }) => ({ state: { ...state, previewVideoId: videoId } })
        },
        {
            conditions: [PASTE_YOUTUBE_VIDEO_LINK],
            reducer: ({ state }) => ({ state: { ...state, pasteLink: true } })
        },
        {
            conditions: [YOUTUBE_VIDEOS_SEARCH],
            reducer: ({ values: [payload], state }) => ({
                state,
                actionToDispatch: youTubeVideosSearchActionCreator(payload)
            })
        }
    ]
});
