import * as R from 'ramda';
import { TextComponentKind } from '../../components/oneweb/Text/kind';
import { getBBoxesIntersection, makeBBox } from '.';
import type {
    AnyComponent, ComponentsMap
} from '../../redux/modules/children/workspace/flowTypes';
import type { TextComponent } from '../../components/oneweb/Text/flowTypes';

export default (
    selectedComponent: AnyComponent, componentsMap: ComponentsMap
): Array<TextComponent> => {
    const { id, orderIndex } = selectedComponent;
    const selectedBBox = makeBBox(
        selectedComponent.left,
        selectedComponent.top,
        selectedComponent.width,
        selectedComponent.height
    );
    const selectedBBoxCenter = {
        top: (selectedBBox.bottom + selectedBBox.top) / 2,
        right: (selectedBBox.right + selectedBBox.left) / 2,
        bottom: 0,
        left: 0
    };

    // Create a 0x0 bbox at the center of the selected component
    selectedBBoxCenter.bottom = selectedBBoxCenter.top;
    selectedBBoxCenter.left = selectedBBoxCenter.right;

    return R.values(
        R.filter(component => {
            if (component.id === undefined || component.kind === undefined) {
                return false;
            }

            // Skip self and skip non-text components
            if (component.id === id || component.kind !== TextComponentKind) {
                return false;
            }

            // Skip if text component is on top of selectedComponent
            if (component.orderIndex > orderIndex) {
                return false;
            }

            // Find text components that intersect selected component
            return Boolean(
                getBBoxesIntersection(
                    selectedBBoxCenter,
                    makeBBox(component.left, component.top, component.width, component.height)
                )
            );
        }, componentsMap)
    );
};
