import type {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    DaysOfTheWeek,
    DaysOfTheWeekStartingFromSunday,
    GroupGroupingOption,
    DontGroupGroupingOption
} from "./flowTypes";

export const DEFAULT_HEIGHT = 80,
    DEFAULT_OPENING_HOURS_COLOR_BOX_STYLE = {
        height: "26px",
        width: "26px",
        borderRadius: "13px"
    },
    DEFAULT_WIDTH = 214,
    OpeningHours = "OpeningHours";
export const GROUPING_OPTIONS: {
    GROUP: GroupGroupingOption;
    DONT_GROUP: DontGroupGroupingOption;
} = {
    GROUP: "GROUP",
    DONT_GROUP: "DONT_GROUP"
};
export const INTL_GROUPING_OPTIONS = {
    GROUP: "msg: component.openingHours.group {Group}",
    DONT_GROUP: "msg: component.openingHours.dontGroup {Don't group}"
};
const SUNDAY: Sunday = "SUNDAY";
const MONDAY: Monday = "MONDAY";
const TUESDAY: Tuesday = "TUESDAY";
const WEDNESDAY: Wednesday = "WEDNESDAY";
const THURSDAY: Thursday = "THURSDAY";
const FRIDAY: Friday = "FRIDAY";
const SATURDAY: Saturday = "SATURDAY";
export const DAYS_OF_THE_WEEK: DaysOfTheWeek = {
    [SUNDAY]: SUNDAY,
    [MONDAY]: MONDAY,
    [TUESDAY]: TUESDAY,
    [WEDNESDAY]: WEDNESDAY,
    [THURSDAY]: THURSDAY,
    [FRIDAY]: FRIDAY,
    [SATURDAY]: SATURDAY
};
export const DAYS_OF_THE_WEEK_STARTING_FROM_SUNDAY: DaysOfTheWeekStartingFromSunday = [
    DAYS_OF_THE_WEEK.SUNDAY,
    DAYS_OF_THE_WEEK.MONDAY,
    DAYS_OF_THE_WEEK.TUESDAY,
    DAYS_OF_THE_WEEK.WEDNESDAY,
    DAYS_OF_THE_WEEK.THURSDAY,
    DAYS_OF_THE_WEEK.FRIDAY,
    DAYS_OF_THE_WEEK.SATURDAY
];
export const INTL_DAYS_OF_THE_WEEK = {
    [SUNDAY]: "msg: common.sunday {Sunday}",
    [MONDAY]: "msg: common.monday {Monday}",
    [TUESDAY]: "msg: common.tuesday {Tuesday}",
    [WEDNESDAY]: "msg: common.wednesday {Wednesday}",
    [THURSDAY]: "msg: common.thursday {Thursday}",
    [FRIDAY]: "msg: common.friday {Friday}",
    [SATURDAY]: "msg: common.saturday {Saturday}"
};
export const getMidnightToday = () => new Date().setHours(0, 0, 0, 0);
export const TWENTY_FOUR_HOUR_TIME_OPTIONS: number[] = Array(
    24 *
        /* hours */
        4
    /* quarters */
)
    .fill(0)
    .map(
        (_, i) =>
            1000 *
            /* ms */
            60 *
            /* seconds */
            15 *
            /* minutes */
            i
    );
export const OPEN_24_HOURS_VALUE = -Infinity;
export const INVALID_OPENING_HOURS_VALUE = NaN;
export const DEFAULT_FROM_TIME = TWENTY_FOUR_HOUR_TIME_OPTIONS[9 * 4]; // 9 hours and 4 quarters from 00:00 to make it 9AM

export const DEFAULT_TO_TIME = TWENTY_FOUR_HOUR_TIME_OPTIONS[17 * 4]; // 17 hours and 4 quarters from 00:00 to make it 5PM
