import u from 'updeep';

import type { LinkPageInfoDialogInProps } from "../../Tree/dialogs/LinkPageInfoDialog/flowTypes";
import linkFormDefaultState from "./linkPageDefaultState";
import inputDefaultState from "../../../inputControls/input/reducerFactory/inputDefaultState";
import { makeLinkPageLink } from "../utils";
import { LcTabName } from "../../../../view/common/dialogs/LinkChooserDialog/constants";

export default (props: LinkPageInfoDialogInProps) => {
    const
        { page } = props,
        link = makeLinkPageLink({ url: page.url, linkId: page.linkId }),
        openInNewWindow = page.target === '_blank';
    let linkTo;
    if (link.type === LcTabName.URL) {
        linkTo = {
            url: { ...inputDefaultState, value: link.value, isValid: true }, // assume data from server is correct
            resource: null
        };
    } else {
        linkTo = {
            resource: link,
            url: linkFormDefaultState.linkTo.url
        };
    }

    return u({
        linkTo,
        origPageRefUrl: link.type === LcTabName.PAGE ? page.url : null,
        openInNewWindow,
        name: { ...inputDefaultState, value: page.name, isValid: true }, // assume data from server is correct
        isHidden: page.hidden
    }, linkFormDefaultState);
};
