import makeEpic from "../../../../../epics/makeEpic";
import { WEBSHOP_ONBOARD_STATUS } from "./valueActionType";

const defaultState = {
    hasOnlyDigitalShop: false,
    isWebshopSetupDone: false,
};

export const WebshopOnboardStatusEpic = makeEpic({
    defaultState,
    valueActionType: WEBSHOP_ONBOARD_STATUS,
    updaters: [
        {
            conditions: ['UPDATE_SHOW_DIGITAL_PRODUCT_FLAG'],
            reducer: ({ state, values: [{ hasOnlyDigitalShop }] }) => {
                return {
                    state: {
                        ...state,
                        hasOnlyDigitalShop
                    }
                };
            }
        },
        {
            conditions: ['UPDATE_WEBSHOP_ONBOARD_STATUS'],
            reducer: ({ state, values: [{ isWebshopSetupDone }] }) => {
                return {
                    state: {
                        ...state,
                        isWebshopSetupDone
                    }
                };
            }
        }
    ]
});
