import SCALE_STRATEGY from "../scaleStrategy";
import type { ImageComponent } from '../flowTypes';
import { encodeUrl } from '../../../../../utils/encoding';
import { getAssetUrl } from '../../../../utils/assetUtils';
import { getDefaultDimensions, getCropDimensions } from "../componentSelectors";
import applyTransforms from "../applyTransforms";
import { applyTransformsFitStretchMode, applyTransformsFitStretchModeHD } from "../applyTransformsFitStretchMode";
import {
    JpegCompressionQuality,
    SVG_CONTENT_TYPES
} from "../constants";

export default function (
    component: ImageComponent,
    isWorkspace: boolean,
    hdImages: boolean,
    imageAvailable: boolean = true,
    domain?: string
) {
    const
        { scaleStrategy } = component,
        { FIT, STRETCH, CROP, LOGO } = SCALE_STRATEGY;

    switch (scaleStrategy) {
        case FIT:
        case STRETCH:
        case CROP:
        case LOGO: {
            if (isWorkspace || !imageAvailable) {
                let query: Record<string, any> = {};
                if (component.asset.contentType === 'image/jpeg') {
                    query.quality = JpegCompressionQuality;
                }
                let imgSrc = component.asset.url
                    ? encodeUrl(getAssetUrl(component.asset, query, domain))
                    : '';
                imgSrc = (isWorkspace || imageAvailable) ? imgSrc : imgSrc + '&t=' + new Date().getTime();
                return {
                    src: imgSrc,
                    srcSet: [],
                    cssUrls: {}
                };
            }

            // preview
            let transformer;
            if (scaleStrategy === CROP) {
                transformer = applyTransforms;
            } else if (scaleStrategy === LOGO) {
                transformer = applyTransformsFitStretchModeHD;
            } else {
                transformer = applyTransformsFitStretchMode;
            }

            const
                dims: Record<string, any> = scaleStrategy === CROP ? getCropDimensions(component) : getDefaultDimensions(component),
                { src, srcSet, cssUrls } = transformer(component, {
                    hdImages, // doc.hdImages. TODO when users status as Premium is there
                    w: dims.imageWidth,
                    h: dims.imageHeight,
                    innerWidth: dims.innerWidth,
                    innerHeight: dims.innerHeight,
                    left: dims.cropLeft,
                    top: dims.cropTop
                }, domain);

            let verifiedSrcSet;
            if (SVG_CONTENT_TYPES.includes(component.asset.contentType)) {
                verifiedSrcSet = [];
            } else {
                verifiedSrcSet = srcSet;
            }
            return {
                src,
                srcSet: verifiedSrcSet,
                cssUrls
            };
        }
        default:
            throw Error(`Component ${component.id} has unknown image scale strategy: "${scaleStrategy}"`);
    }
}
