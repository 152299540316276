import React from "react";
import cx from 'classnames';
import styles from './TopBar.css';
import type { TopBarItemContainer } from "../flowTypes";

export default ({
    left,
    disableMouseDown,
    children,
    extraStyle = {},
    extraClassName,
    onMouseOver,
    onMouseOut
}: TopBarItemContainer) => {
    const style = { ...extraStyle, float: left ? 'left' : 'right' };
    return (
        <div
            style={style}
            className={cx(styles.topBarItem, extraClassName)}
            onMouseDown={e => disableMouseDown && e.stopPropagation()}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        >
            {children}
        </div>
    );
};
