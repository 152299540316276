import React from 'react';
import View from './index';
import { ComponentMaskBackground } from '../../../presentational/ComponentMask/constants';
import ComponentMask from '../../../presentational/ComponentMask/index';
import BaseComponent, { StretchBase } from '../../../../view/oneweb/BaseComponent';
import type { Props } from '../flowTypes';

export default (props: Props) => {
    if (props.stretch) {
        return (
            <StretchBase {...props}>
                <ComponentMask
                    showPreviewOnlyLayer={false}
                    maskStyle={ComponentMaskBackground.TRANSPARENT}
                >
                    {React.createElement(View, { ...props, isWorkspace: true })}
                </ComponentMask>
            </StretchBase>
        );
    }
    return (
        <BaseComponent {...props}>
            <ComponentMask
                showPreviewOnlyLayer={false}
                maskStyle={ComponentMaskBackground.TRANSPARENT}
            >
                {React.createElement(View, { ...props, isWorkspace: true })}
            </ComponentMask>
        </BaseComponent>
    );
};
