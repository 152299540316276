import type { Style, Element } from '../../flowTypes';
/**
 * Returns ol/ul
 */
export default (listStyles: Array<Style>): Array<Element> => {
    if (!listStyles.length) {
        return [];
    }
    let result: Style[] = [];
    let i = 0;
    let start = listStyles[i].start;
    for (; i < listStyles.length - 1; i++) {
        // if end === start-1 it means list items belong to the same list
        // otherwise its end of the list
        if (listStyles[i].end === listStyles[i + 1].start - 1 && listStyles[i].bullet === listStyles[i + 1].bullet) {
            continue;
        } else {
            result.push({
                ...listStyles[i],
                start
            });
            start = listStyles[i + 1].start;
        }
    }
    result.push({
        ...listStyles[i],
        start
    });
    return result;
};
