import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { WizardFooter } from "../../WizardFooter";
import { StepDescription } from "../Common/StepDescription";
import stepStyles from "../steps.css";
import { DynamicTemplateContext } from "../../Context";
import { validateWebsiteCategoryStep } from "../validations";
import { OptionsSet } from "../Common/OptionSet/OptionsSet";
import { CatchAllConcepts, GenericCatchAllConcept } from "./Concepts";
import styles from "./styles.css";
import {
    DYNAMIC_TEMPLATE_CALL_ALL_CONCEPT_KEY_CHANGED,
    DYNAMIC_TEMPLATE_GET_CONCEPT_FOR_GMB_KEY,
    DYNAMIC_TEMPLATE_UPDATE_CONCEPT_FOR_GMB_KEY
} from "../../../Epic/actionTypes";
import Scrollbar from "../../../../../../view/common/Scrollbar";

const getCatchallConcepts = (concepts, shopDisabled) => {
    if (!shopDisabled) return [...concepts];
    return concepts.filter(o => o.value !== 'catch_all_online_shop');
};

const CatchAllStep = () => {
    const dispatch = useDispatch();
    const { wizardState, catchAllConceptKey, conceptMap, shopDisabled, showGenericConcept } = useContext(DynamicTemplateContext);
    const [selectedConceptCategory, setSelectedConceptCategory] = React.useState([catchAllConceptKey]);

    const title = "msg: onboarding.dynamic.catchAll.title {What type of business or project is this website for?}";
    // eslint-disable-next-line max-len
    const description = "msg: onboarding.dynamic.catchAll.description {Select the option that best describes your business or project, so we can provide you with a relevant design and texts.}";

    const onSelectionChange = (option) => {
        setSelectedConceptCategory(option);
        dispatch({ type: DYNAMIC_TEMPLATE_CALL_ALL_CONCEPT_KEY_CHANGED, payload: option[0] });
    };
    const goNext = () => {
        const selectedGMBKey = selectedConceptCategory[0];
        if (!selectedGMBKey) {
            return;
        }
        let action;
        if (selectedGMBKey in conceptMap) {
            action = { type: DYNAMIC_TEMPLATE_UPDATE_CONCEPT_FOR_GMB_KEY, payload: conceptMap[selectedGMBKey] };
            wizardState.goNext();
        } else if (validateWebsiteCategoryStep(selectedGMBKey)) {
            action = {
                type: DYNAMIC_TEMPLATE_GET_CONCEPT_FOR_GMB_KEY,
                payload: { catchAllConceptKey: selectedGMBKey }
            };
        }

        if (action) { dispatch(action); }
    };
    let concepts = getCatchallConcepts(CatchAllConcepts, shopDisabled);
    if (showGenericConcept) {
        concepts.push(GenericCatchAllConcept);
    }
    return (
        <div className={styles.containerWrapper}>
            <div className={`${stepStyles.stepContainer} ${styles.stepContainer}`}>
                <Scrollbar height="100%">
                    <StepDescription className={styles.description} title={title} description={description} />
                    <OptionsSet
                        className={styles.optionSet}
                        optionClass={styles.optionClass}
                        options={concepts}
                        selectedOptions={selectedConceptCategory}
                        onSelectionChange={onSelectionChange}
                    />
                </Scrollbar>
            </div>
            <WizardFooter goNext={goNext} disableButton={!selectedConceptCategory[0]} />
        </div>

    );
};

export {
    CatchAllStep,
    CatchAllStep as default
};
