import React, { PureComponent } from 'react';
import type { PropertiesPanelProps } from '../flowTypes';
import { InstancePropertiesPanelController } from "../instance/components/InstancePropertiesPanelController";
import { CompoundPropertiesPanel } from "./CompoundPropertiesPanel";
import dispatchFunctions from "./dispatchFunctions";
import { modernLayoutComponentKindsWithoutPP, modernLayoutComponentKindsExceptionPP } from "../../ModernLayouts/constants";

class PropertiesPanel extends PureComponent<PropertiesPanelProps> { // eslint-disable-line react/no-multi-comp
    render() {
        const
            { state, dispatch, selectedCmpPosition, isModernLayoutActive } = this.props,
            { navigateToPage } = dispatchFunctions(dispatch),
            selectedComponentKind = state.selectedComponentWithId && state.selectedComponentWithId.component.kind;

        // donot display properties panel if Menu component is in modern header
        if ((selectedCmpPosition && selectedCmpPosition.isInsideHeaderOrFooter) && isModernLayoutActive) {
            const showPPException = modernLayoutComponentKindsExceptionPP[selectedComponentKind];
            if (modernLayoutComponentKindsWithoutPP[selectedComponentKind]) {
                return null;
            }
            if (showPPException && state.currentPage.id !== showPPException) {
                return null;
            }
        }

        if (state.instance) {
            return (
                <InstancePropertiesPanelController
                    state={state}
                    navigateToPage={navigateToPage}
                    dispatch={dispatch}
                />
            );
        }

        return (
            <CompoundPropertiesPanel {...this.props} />
        );
    }
}

export default PropertiesPanel;
