/* @flow */

// Below list of components should match the list of components in the features inside
// the common.cjson
const ConfigComponentList = {
    "TOP_BAR": "TOP_BAR",
    "FILE_CHOOSER": "FILE_CHOOSER",
    "SITE_SETTINGS": "SITE_SETTINGS",
    "SOCIAL_WIDGETS": "SOCIAL_WIDGETS",
    "DASHBOARD": "DASHBOARD",
    "ONLINE_SHOP": "ONLINE_SHOP"
};

class FeatureManager {
    /*:: #_featuresConfig: Object; */

    constructor(featuresConfig/*: Object */ = {}) {
        this./*::#*/_featuresConfig = featuresConfig;
    }

    isPublishButtonEnabled() {
        const topBar = this./*::#*/_featuresConfig[ConfigComponentList.TOP_BAR];
        return topBar && topBar.publishButton && topBar.publishButton.enabled;
    }

    isSubscriptionTypeLabelShown() {
        const topBar = this./*::#*/_featuresConfig[ConfigComponentList.TOP_BAR];
        return topBar && topBar.subscriptionTypeLabel && topBar.subscriptionTypeLabel.show;
    }

    isUpgradeButtonShown() {
        /* This includes all type of components related to webshop */
        const topBar = this./*::#*/_featuresConfig[ConfigComponentList.TOP_BAR];
        return topBar && topBar.upgradeButton && topBar.upgradeButton.show;
    }

    isTopBarCPLinkShown() {
        const topBar = this./*::#*/_featuresConfig[ConfigComponentList.TOP_BAR];
        return topBar && topBar.goToCPLink && topBar.goToCPLink.show;
    }

    isShutterstockShown() {
        const fileChooser = this./*::#*/_featuresConfig[ConfigComponentList.FILE_CHOOSER];
        return (
            fileChooser &&
            fileChooser.shutterstock &&
            fileChooser.shutterstock.show
        );
    }

    isUnsplashShown() {
        const fileChooser = this./*::#*/_featuresConfig[ConfigComponentList.FILE_CHOOSER];
        return (
            fileChooser &&
            fileChooser.unsplash &&
            fileChooser.unsplash.show
        );
    }

    isFileChooserShown() {
        const fileChooser = this./*::#*/_featuresConfig[ConfigComponentList.FILE_CHOOSER];
        return fileChooser && fileChooser.goToFileManager && fileChooser.goToFileManager.show;
    }

    isSeoTabShown() {
        const siteSettings = this./*::#*/_featuresConfig[ConfigComponentList.SITE_SETTINGS];
        return siteSettings && siteSettings.seoTab && siteSettings.seoTab.show;
    }

    isSocialShareTabShown() {
        const siteSettings = this./*::#*/_featuresConfig[ConfigComponentList.SITE_SETTINGS];
        return siteSettings && siteSettings.socialShareTab && siteSettings.socialShareTab.show;
    }

    isTrackingTabShown() {
        const siteSettings = this./*::#*/_featuresConfig[ConfigComponentList.SITE_SETTINGS];
        return siteSettings && siteSettings.trackingTab && siteSettings.trackingTab.show;
    }

    isSecurityTabShown() {
        const siteSettings = this./*::#*/_featuresConfig[ConfigComponentList.SITE_SETTINGS];
        return siteSettings && siteSettings.securityTab && siteSettings.securityTab.show;
    }

    isBusinessListingTabShown() {
        const siteSettings = this./*::#*/_featuresConfig[ConfigComponentList.SITE_SETTINGS];
        return siteSettings && siteSettings.businessListingTab && siteSettings.businessListingTab.show;
    }

    isSocialWidgetsShown() {
        const socialWidgets = this./*::#*/_featuresConfig[ConfigComponentList.SOCIAL_WIDGETS];
        return socialWidgets && socialWidgets.show;
    }

    isInstagramComponentEnabled() {
        const socialWidgets = this./*::#*/_featuresConfig[ConfigComponentList.SOCIAL_WIDGETS];
        return socialWidgets && socialWidgets.instagramComponent && socialWidgets.instagramComponent.enabled
    }

    isInstagramComponentForceDisabled() {
        const socialWidgets = this./*::#*/_featuresConfig[ConfigComponentList.SOCIAL_WIDGETS];
        return socialWidgets && socialWidgets.instagramComponent && socialWidgets.instagramComponent.forceDisabled;
    }

    isFacebookComponentEnabled() {
        const socialWidgets = this./*::#*/_featuresConfig[ConfigComponentList.SOCIAL_WIDGETS];
        return socialWidgets && socialWidgets.facebookComponent && socialWidgets.facebookComponent.enabled;
    }

    isGoogleReviewsComponentEnabled() {
        const socialWidgets = this./*::#*/_featuresConfig[ConfigComponentList.SOCIAL_WIDGETS];
        return socialWidgets && socialWidgets.googleReviewsComponent && socialWidgets.googleReviewsComponent.enabled;
    }

    isGoogleReviewsComponentShown() {
        const socialWidgets = this./*::#*/_featuresConfig[ConfigComponentList.SOCIAL_WIDGETS];
        return socialWidgets && socialWidgets.googleReviewsComponent && socialWidgets.googleReviewsComponent.show;
    }

    isDashboardEnabled() {
        const dashboard = this./*::#*/_featuresConfig[ConfigComponentList.DASHBOARD];
        return dashboard && dashboard.enabled;
    }
}

module.exports = {
    FeatureManager
};
