import * as R from 'ramda';
import { getFontName } from '../../presentational/AddGoogleFont/utils';
import {
    textNormalGlobalstyle
} from '../../Workspace/epics/stylesheets/selectors';
import type { FeaturedProductsComponent, ComponentDependencies } from '../FeaturedProducts/flowTypes';
import { font } from "../../../mappers/path";
import { getGSNormalTextColor } from '../WebShop/utils';

export default (
    defaultState: FeaturedProductsComponent,
    {
        globalStyles,
    }: ComponentDependencies
): FeaturedProductsComponent => {
    const FeaturedProductsData = R.evolve({
        font: R.always(R.pipe(textNormalGlobalstyle, R.path([font]), getFontName)(globalStyles)),
        fontColor: R.always(getGSNormalTextColor(globalStyles)),
    })(defaultState);

    return FeaturedProductsData;
};
