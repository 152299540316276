/* eslint-disable max-len */

import React, { useContext, useState, useEffect } from "react";

import { WizardFooter } from "../../WizardFooter";
import { StepDescription } from "../Common/StepDescription";
import { BusinessNameField } from "./BusinessNameField";

import { validateBusinessNameStep } from "../validations";
import { DynamicTemplateContext } from "../../Context";

import stepStyles from "../steps.css";
import { FIELD_NAMES, STEP_NAMES, TEXT_CHARACTER_LIMIT_ON_STEP_FIELD } from "../../../constants";
import { getKeyForStep } from "../utils";

export const BusinessNameStep = () => {
    const { concept: { name, type }, businessName, gmbKey } = useContext(DynamicTemplateContext);
    const [valid, setValid] = useState(validateBusinessNameStep(businessName));
    const counterLimit = TEXT_CHARACTER_LIMIT_ON_STEP_FIELD.businessName.max;
    const [showError, setShowError] = useState(businessName.length > counterLimit);

    const businessNameFieldProps = {
        showError,
        setShowError,
    };

    const StepDescriptionProps = {
        title: getKeyForStep(
            name,
            type,
            gmbKey,
            STEP_NAMES.BUSINESS_NAME,
            FIELD_NAMES.TITLE
        ),
        description: getKeyForStep(
            name,
            type,
            gmbKey,
            STEP_NAMES.BUSINESS_NAME,
            FIELD_NAMES.DESC
        )
    };

    useEffect(() => {
        setValid(validateBusinessNameStep(businessName));
    }, [businessName]);

    return (
        <React.Fragment>
            <div className={stepStyles.stepContainer} data-testid="dynamic-onboarding-business-name">
                <StepDescription {...StepDescriptionProps} />
                <BusinessNameField {...businessNameFieldProps} />
            </div>
            <WizardFooter disableButton={!valid || showError} />
        </React.Fragment>
    );
};
