import type { ImageComponent } from '../flowTypes';
import * as componentReducers from "../../componentReducers";
import ScaleStrategies from '../scaleStrategy';
import { fixHeightForFitMode, fixWidthForFitMode } from './fixHeightForFitMode';
import { getMinZoom } from '../componentSelectors';
import { getSpacing } from "../../../../view/oneweb/commonComponentSelectors";

export default (component: ImageComponent, action: Action<any>): ImageComponent => {
    const
        { scaleStrategy } = component,
        changedComponent = componentReducers.makeComponentStyleBorderWidthReducer()(component, action);
    if (ScaleStrategies.FIT === scaleStrategy) {
        const
            oldBorders = getSpacing(component),
            newBorders = getSpacing(changedComponent),
            hChange = oldBorders.left !== newBorders.left && oldBorders.right !== newBorders.right,
            vChange = oldBorders.top !== newBorders.top || oldBorders.bottom !== newBorders.bottom;

        if (vChange && !hChange) {
            return fixWidthForFitMode(changedComponent); // vertical has changed
        }

        // all same or horizonatal border are same so we fix height
        return fixHeightForFitMode(changedComponent);
    } else if (ScaleStrategies.CROP === scaleStrategy) {
        return {
            ...changedComponent,
            scale: Math.max(changedComponent.scale, getMinZoom(changedComponent))
        };
    }

    return changedComponent;
};
