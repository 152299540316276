import { WidgetsPaypalKind } from './kind';
import { calcRenderProps } from './calcRenderProps';
import { PaypalView } from './view/index';

const WidgetsPaypalPreviewConfig = {
    kind: WidgetsPaypalKind,
    view: PaypalView,
    js: ['/renderStatic/widgets/paypal.js'],
    calcProps: ({ component }) => calcRenderProps({
        component,
        componentExtension: { isTransient: false },
    }),
};

export { WidgetsPaypalPreviewConfig };
