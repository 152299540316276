// @ts-nocheck
import { VideoExtensions, videoExtMimeTypeMap } from '../components/oneweb/Video/constants';

const EXT_REGEX = /\.(\w+)$/i;

/*
    {
        'mp4': 'video/mp4',
        ...,
        ....
    }
*/
// TODO: Add all supported file extensions in this map
const fileExtMimeMap = {
    ...videoExtMimeTypeMap
};

/*
    input: test_video.mp4
    output: mp4
*/
const getFileExt = (fileName) => {
    return fileName.match(EXT_REGEX).pop();
};

const renameFile = (file, newFileName) => {
    return new File([file], newFileName, { type: file.type });
};

const updateFileTypeIfMissing = (file) => {
    if (!file.type) {
        let type = fileExtMimeMap[getFileExt(file.name)];
        return new File([file], file.name, { type });
    }
    return file;
};

// WBTGEN-25925 :: Fix for empty file type
const updateFileTypesIfMissing = (files) => {
    return Array.from(files).map(updateFileTypeIfMissing);
};

const
    mp4 = '.mp4',
    MAX_VIDEO_SIZE = 5120,
    isFileNameValid = (fileName) => !(fileName.startsWith("http://") || fileName.startsWith("https://")) && fileName.length,
    isVideoFile = (fileName) => isFileNameValid(fileName) && VideoExtensions.includes(getFileExt(fileName).toLowerCase()),
    isMp4File = (fileName) => isVideoFile(fileName) && fileName.toLowerCase().endsWith(mp4),
    hasMultipleVideos = (files) => Array.from(files).reduce((videoCount, file) =>
        (isVideoFile(file.name) ? videoCount + 1 : videoCount), 0) > 1,
    isVideoExceedingMaxSize = (file) => (file.size / (1024 ** 2) > MAX_VIDEO_SIZE),
    hasVideosExceedingMaxSize = (files) => Array.from(files).some(isVideoExceedingMaxSize),
    renameFileToMP4 = (file) => renameFile(file, file.name.replace(EXT_REGEX, mp4)),
    renameFilesToMP4 = (files) => Array.from(files).map(renameFileToMP4);

export const FileUtils = {
    fileExtMimeMap,
    getFileExt,
    renameFile,
    updateFileTypeIfMissing,
    updateFileTypesIfMissing
};

export const VideoUtils = {
    isVideoFile,
    isMp4File,
    renameFileToMP4,
    renameFilesToMP4,
    hasMultipleVideos,
    isVideoExceedingMaxSize,
    hasVideosExceedingMaxSize
};

export default {
    FileUtils,
    VideoUtils
};
