import React from "react";
import cx from "classnames";
import styles from "./MarketingConsent.css";
import HorizontalSpacer from "../../../../../view/common/HorizontalSpacer";
import { shouldUseOneConnectAPI } from '../../utility';
import { replaceTagsWithContent } from "../../../Text/view/replaceTagsWithContent";

const MarketingConsentView = ({
    isMarketingConsentEnabled,
    marketingConsentCheckBoxText,
    readPrivacyPolicyText,
    privacyPolicy,
    site,
    globalVariables,
    textStyle,
    domain,
}) => {
    if (!shouldUseOneConnectAPI({ domainName: domain })) {
        return null;
    }

    const privacyPolicyContent = privacyPolicy?.content;

    if (!isMarketingConsentEnabled || !marketingConsentCheckBoxText) {
        return null;
    }

    return <div className={styles.marketingConsent}>
        <input type="checkbox" id="marketingConsentCheckbox" name="optedInForMarketing" />
        <label
            style={textStyle}
            className={styles.marketingConsentLabel}
        >
            {replaceTagsWithContent(marketingConsentCheckBoxText, { site, globalVariables })}
            <HorizontalSpacer inline x={2} />
            {
                privacyPolicyContent &&
                <a style={textStyle} className={cx(styles.readPrivacyPolicy, "openPrivacyPolicyDialog")}>
                    {replaceTagsWithContent(readPrivacyPolicyText, { site, globalVariables })}
                </a>
            }
        </label>
    </div>;
};

export { MarketingConsentView };
