/* eslint-disable max-len */

import { always } from 'ramda';
import p from "../../../../utils/pipePath";
import * as mp from "../../../../mappers/path";
import {
    setBorderStyle,
    setBorderColor,
    setBorderThemeColor,
    setBorderOpacity,
    setBorderWidths as setBorderWidth
} from "../../../../setters/borderSetter";
import {
    getBorderStyle as getGSBorderStyle,
    getBorderColor as getGSBorderColor,
    getBorderColorOpacity as getGSBorderOpacity,
    getBorderWidth as getGSBorderWidth
} from "../../../Workspace/epics/stylesheets/selectors";
import {
    getBorderStyle, getBorderColor, getBorderThemeColor, getBorderOpacity, getBorderWidth
} from "../../../../getters/borderGetters";
import type { TableComponent, TableComponentDependsOn } from "../flowTypes";
import type { Color } from "../../../../mappers/flowTypes";
import { makeCellsUpdater } from "./makeCellsUpdater";
import type { ThemeColorTypes } from '../../../ThemeGlobalData/flowTypes';

const
    borderPath = p(mp.style, mp.blockBorder),
    propUpdatersMap = {
        style: {
            getter: getBorderStyle(borderPath),
            gsGetter: getGSBorderStyle(),
            setter: setBorderStyle(borderPath)
        },
        solidColor: {
            getter: getBorderColor(borderPath),
            gsGetter: getGSBorderColor(),
            setter: setBorderColor(borderPath)
        },
        solidThemeColor: {
            getter: getBorderThemeColor(borderPath),
            gsGetter: always(null),
            setter: setBorderThemeColor(borderPath)
        },
        opacity: {
            getter: getBorderOpacity(borderPath),
            gsGetter: getGSBorderOpacity(),
            setter: setBorderOpacity(borderPath)
        },
        widths: {
            getter: getBorderWidth(borderPath),
            gsGetter: getGSBorderWidth(),
            setter: setBorderWidth(borderPath)
        }
    };

type CellBorderPropToUpdate
    = { type: 'style', value: string}
    | { type: 'solidColor', value: null | undefined | Color }
    | { type: 'solidThemeColor', value: ThemeColorTypes | null }
    | { type: 'opacity', value: number }
    | { type: 'widths', value: Array<number> }

const
    makeCellsBorderReducer =
        (propToUpdateMaker: (AnyAction) => CellBorderPropToUpdate) =>
            (component: TableComponent, action: AnyAction, dependencies: TableComponentDependsOn) =>
                makeCellsUpdater({
                    propUpdatersMap,
                    propToUpdate: propToUpdateMaker(action),
                    globalStyles: dependencies.stylesheets,
                    component,
                    selectedCellsIndexes: dependencies.tableEditModeState.selectedCellsIndexes
                });

export {
    makeCellsBorderReducer
};
