import React from "react";

import pagesTitles from "./titles";
import * as pagesIds from "./ids";

import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import NavigationGroup from '../../../../PropertiesPanel/view/IntlNavigationGroup';

import type { PageViewPropTypes } from '../../../../PropertiesPanel/flowTypes';
import type { SvgComponent, ComponentDependencies } from '../../flowTypes';
import { MOBILE_VIEW } from "../../../../PropertiesPanel/view/MobileView/constants";

type Props = PageViewPropTypes<SvgComponent> & ComponentDependencies & {
    intl: () => any
};

const
    id = pagesIds.MAIN,
    title = pagesTitles[pagesIds.MAIN],
    view = ({ navigateToPage }: Props) => (
        <Page>
            <NavigationGroup
                navigateToPage={navigateToPage}
                pagesTitles={pagesTitles}
                targetPagesIds={[pagesIds.COLOR, pagesIds.ON_CLICK, pagesIds.SEO, MOBILE_VIEW]}
            />
        </Page>
    );

export { id, view, title };
