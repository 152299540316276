/* @flow */

const path = require('ramda/src/path');
const isEqual = require('lodash/isEqual');

const CONSENT_BANNER_DEFAULT_LOCALE = "en-gb";

const CONSENT_BANNER_DEFAULT_SETTINGS = {
    enabled: false,
    locale: CONSENT_BANNER_DEFAULT_LOCALE
};

/**
 * @param {string} value
 * @returns {string}
 */
function validateLocaleValue(value/*: string */)/*: string */ {
    // TODO: validate locale value
    return value || CONSENT_BANNER_DEFAULT_LOCALE;
}

/**
 * @param {string} value
 * @returns {("on" | "off")}
 */
function validateAutoBlockValue(value/*: string */)/*: "on" | "off" */ {
    return value === "off" ? "off" : "on";
}

/**
 * @param {Object} settings
 * @returns {Object}
 */
const getConsentBannerSettings = (settings /*: Object */) /*: Object */ => {
    if (settings && isConsentBannerConfigured(settings.consentBanner)) {
        return {
            ...settings.consentBanner,
            locale: settings.consentBanner.locale || CONSENT_BANNER_DEFAULT_SETTINGS.locale,
            autoBlock: validateAutoBlockValue(settings.consentBanner.autoBlock)
        };
    }

    return CONSENT_BANNER_DEFAULT_SETTINGS;
};

/**
 * @param {Object} bannerSettings
 * @returns {boolean}
 */
function isConsentBannerEnabled(bannerSettings/*: Object */)/*: boolean */ {
    return Boolean(isConsentBannerConfigured(bannerSettings) && bannerSettings.enabled);
}

/**
 * @param {Object} bannerSettings
 * @returns {boolean}
 */
function isConsentBannerAutoBlockEnabled(bannerSettings/*: Object */)/*: boolean */ {
    return Boolean(isConsentBannerEnabled(bannerSettings) && bannerSettings.autoBlock !== "off");
}

/**
 * @param {Object} bannerSettings
 * @returns {boolean}
 */
function isTermlySiteScanned(bannerSettings/*: Object */)/*: boolean */ {
    return Boolean(isConsentBannerConfigured(bannerSettings) && bannerSettings.lastScanReportId);
}

/**
 * @param {Object} bannerSettings
 * @returns {boolean}
 */
function isConsentBannerConfigured(bannerSettings/*: Object */)/*: boolean */ {
    return Boolean(bannerSettings && bannerSettings.uuid && bannerSettings.websiteId);
}

/**
 * @param {Object} newSettings
 * @param {Object} oldSettings
 * @returns {boolean}
 */
function isConsentBannerSettingsModified(newSettings/*: Object */, oldSettings/*: Object */)/*: boolean */ {
    return !isEqual(path(['locale'], newSettings), path(['locale'], oldSettings));
}

/**
 * @param {string} domainName
 * @param {Object} currentSettings
 * @returns {boolean}
 */
function isConsentBannerCompanyDetailsModified(domainName/*: string */, currentSettings/*: Object */)/*: boolean */ {
    return !isEqual(
        buildCompanyData(domainName),
        path(['company'], currentSettings)
    );
}

/**
 * @param {Object} scanReport
 * @returns {boolean}
 *
 * {@link https://github.com/termly/apidocs/blob/main/endpoints/get_websites-scan_report.md}
 */
function isSiteScanFinished(scanReport/*: Object */)/*: boolean */ {
    return scanReport && scanReport.status === "scan_finish";
}

/**
 * @param {string} domainName
 * @returns {Object}
 */
function buildCompanyData(domainName/*: string */)/*: Object */ {
    return {
        legal_name: domainName,
        email: "",
        phone: "",
        fax: "",
        address: "",
        zip: "",
        state: "",
        city: "",
        country: ""
    };
}

module.exports = {
    CONSENT_BANNER_DEFAULT_LOCALE,
    CONSENT_BANNER_DEFAULT_SETTINGS,
    validateLocaleValue,
    validateAutoBlockValue,
    isConsentBannerEnabled,
    isConsentBannerAutoBlockEnabled,
    isTermlySiteScanned,
    isConsentBannerConfigured,
    isSiteScanFinished,
    buildCompanyData,
    getConsentBannerSettings,
    isConsentBannerSettingsModified,
    isConsentBannerCompanyDetailsModified
};
