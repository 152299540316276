import * as R from 'ramda';
import { getDialogFromRegistry } from "../registry";
import initAction from "../../../redux/initAction";
import { OPEN_DIALOG, REPLACE_DIALOG } from "../../../redux/modules/actionTypes";
import { dropLastConfig } from "./shared";

import type { DialogConfig, DialogManagerEpicUpdater, DialogManagerState } from "../flowTypes";

type OpenDialogFactoryProps = { triggerAction: string, beforeUpdate?: DialogManagerState }

const
    makeOpenDialogScopeTransform = ({
        dialogId,
        dialogProps,
        browserDimensions,
        dialogsDependencies,
        persistentStates
    }) => {
        const
            record = getDialogFromRegistry(dialogId),
            dependencies = dialogsDependencies[dialogId];

        let
            persistentState = persistentStates[dialogId],
            state;

        if (!persistentState) {
            state = record.makeDefaultState
                ? record.makeDefaultState(dialogProps, dependencies)
                : record.reducer(undefined, initAction(), dialogProps);
        } else {
            state = persistentState;
        }

        const dialogConfig: DialogConfig = {
            ...record.confFactory({ // TODO remove dimensions structure mapping WBTGEN-6411
                browserWidth: browserDimensions.width,
                browserHeight: browserDimensions.height
            }, dialogProps),
            state,
            initialState: state,
            stateChanged: false,
            props: dialogProps,
            id: dialogId,
            dialogClassName: record.dialogClassName,
            dialogBackgroundClassName: record.dialogBackgroundClassName,
            draggableClassName: record.draggableClassName,
            forceModal: record.forceModal,
            dragEnabled: record.dragEnabled,
            onModalClickAction: record.onModalClickAction,
        };

        return R.evolve({ openedDialogConfigs: R.append(dialogConfig) });
    },
    openDialogUpdaterFactory = ({ triggerAction, beforeUpdate }: OpenDialogFactoryProps) => ({
        conditions: [triggerAction],
        reducer: ({ values: [{ dialogId, props: dialogProps }], state, scope }) => {
            const
                { dialogsDependencies, browserDimensions, persistentStates } = scope,
                openDialogTransform = makeOpenDialogScopeTransform({
                    dialogId,
                    dialogProps,
                    browserDimensions,
                    dialogsDependencies,
                    persistentStates
                });

            return {
                state,
                scope: R.pipe(beforeUpdate || R.identity, openDialogTransform)(scope)
            };
        }
    }),
    onOpenDialogUpdater: DialogManagerEpicUpdater = openDialogUpdaterFactory({
        triggerAction: OPEN_DIALOG
    }),
    onReplaceDialogUpdater: DialogManagerEpicUpdater = openDialogUpdaterFactory({
        triggerAction: REPLACE_DIALOG,
        beforeUpdate: dropLastConfig
    });

export {
    onOpenDialogUpdater,
    onReplaceDialogUpdater
};
