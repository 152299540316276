import React from 'react';

import { GoogleAdsSettingsTextView } from './GoogleAdsSettingsTextView';
import { BgColorSet, BodyTextSet, BuyCampaignActionButton, HeaderTextSet, IconClassesSet } from './constants';

export const GoogleAdsSettingsNoAds = () => (
    <GoogleAdsSettingsTextView
        bgColor={BgColorSet.Active}
        header={{ iconClass: IconClassesSet.noAds, text: HeaderTextSet.noAds }}
        text={BodyTextSet.noAds}
        actionButton={BuyCampaignActionButton}
    />
);
