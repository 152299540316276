import u from 'updeep';
import type { FileManagerState } from "../flowTypes";
import { SortColumns } from "../sortFunctions";

export default (state: FileManagerState, { payload: { column, direction: payloadDirection } }: any) => {
    const
        direction = payloadDirection || (-1) * state.sort.columns[column],
        { sort: { currentDirectoryColumn: prevCurrentDirectoryColumn } } = state,
        currentDirectoryColumn = column === SortColumns.NAME || column === SortColumns.ADDED
            ? column
            : prevCurrentDirectoryColumn;

    return u({ sort: { columns: { [column]: direction }, currentColumn: column, currentDirectoryColumn } }, state);
};
