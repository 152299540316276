import * as R from "ramda";
import kind from "./kind";
import type { Stylesheet } from "../../../Workspace/epics/stylesheets/flowTypes";
import type { ComboboxOptions } from "../../../../view/common/Combobox/flowTypes";

export default (styles: Array<Stylesheet>, intl: any, type?: string | null | undefined): ComboboxOptions => {
    const styleType = type || kind;
    return R.pipe(
        R.filter(style => style.type === styleType),
        R.map(style => ({
            value: style.id,
            label: intl.msgJoint("msg: common.gs.menuStyle {Menu style}") + " " + style.index,
            name: style.name
        }))
    )(styles);
};
