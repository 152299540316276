import React from "react";
import type {
    OnboardingStepType,
    OnboardingWelcomeStepType,
    OnboardingWebsiteTitleStepType,
    OnboardingLogoStepType,
    OnboardingContactStepType,
    OnboardingGmbCategoriesStepType,
    OnboardingTemplateSelectorStepType,
    OnboardingThemingStepType,
    OnboardingStepConfigType
} from "./flowTypes";
import {
    ONBOARDING_WELCOME_STEP_OPENED,
    ONBOARDING_ADDRESS_EMAIL_AND_PHONE_STEP_OPENED,
    ONBOARDING_GMB_CATEGORIES_STEP_OPENED,
    ONBOARDING_TITLE_STEP_OPENED,
    ONBOARDING_LOGO_STEP_OPENED,
    ONBOARDING_TEMPLATE_SELECTOR_STEP_OPENED
} from "./actionTypes";
import type { WelcomeStepProps } from "./view/WelcomeStep";
import { WelcomeStep } from "./view/WelcomeStep";
import type { TitleStepProps } from "./view/TitleStep";
import { TitleStep } from "./view/TitleStep";
import type { LogoStepProps } from "./view/LogoStep";
import { LogoStep } from "./view/LogoStep";
import type { ContactStepProps } from "./view/ContactStep";
import { ContactStep } from "./view/ContactStep";
import type { GMBCategoriesStepProps } from "./view/GMBCategoriesStep";
import { GMBCategoriesStep } from "./view/GMBCategoriesStep";
import type { TemplateSelectorStepProps } from "./view/TemplateSelectorStep";
import { TemplateSelectorStep } from "./view/TemplateSelectorStep";
import type { PreviewStepProps } from "./view/PreviewStep";
import { PreviewStep } from "./view/PreviewStep";

export const ONBOARDING_STEPS: {
    WELCOME: OnboardingWelcomeStepType;
    WEBSITE_TITLE: OnboardingWebsiteTitleStepType;
    LOGO: OnboardingLogoStepType;
    CONTACT: OnboardingContactStepType;
    GMB_CATEGORIES: OnboardingGmbCategoriesStepType;
    TEMPLATE_SELECTOR: OnboardingTemplateSelectorStepType;
    THEMING: OnboardingThemingStepType;
} = {
    WELCOME: "WELCOME",
    WEBSITE_TITLE: "WEBSITE_TITLE",
    LOGO: "LOGO",
    CONTACT: "CONTACT",
    GMB_CATEGORIES: "GMB_CATEGORIES",
    TEMPLATE_SELECTOR: "TEMPLATE_SELECTOR",
    THEMING: "THEMING"
};
const ONBOARDING_STEP_SEQUENCE = [
    ONBOARDING_STEPS.WELCOME,
    ONBOARDING_STEPS.WEBSITE_TITLE,
    ONBOARDING_STEPS.LOGO,
    ONBOARDING_STEPS.CONTACT,
    ONBOARDING_STEPS.GMB_CATEGORIES,
    ONBOARDING_STEPS.TEMPLATE_SELECTOR,
    ONBOARDING_STEPS.THEMING
];
export const getNextStep = (currentStep: OnboardingStepType): OnboardingStepType => {
    const currentStepIndex = ONBOARDING_STEP_SEQUENCE.indexOf(currentStep);
    const nextStepIndex = Math.min(currentStepIndex + 1, ONBOARDING_STEP_SEQUENCE.length - 1);
    return ONBOARDING_STEP_SEQUENCE[nextStepIndex];
};
export const getPreviousStep = (currentStep: OnboardingStepType): OnboardingStepType => {
    const currentStepIndex = ONBOARDING_STEP_SEQUENCE.indexOf(currentStep);
    const previousStepIndex = Math.max(currentStepIndex - 1, 0);
    return ONBOARDING_STEP_SEQUENCE[previousStepIndex];
};
export const stepConfig: Record<OnboardingStepType, OnboardingStepConfigType> = {
    WELCOME: {
        goal: {
            goalId: "seen_on_boarding_welcome",
            releasedOn: "2021-02-02",
            triggerOnActionType: ONBOARDING_WELCOME_STEP_OPENED
        },
        view: (props: WelcomeStepProps) => <WelcomeStep {...props} />,
        analyticsTitle: "welcome",
        virtualPageViewTitle: "onboarding-welcome"
    },
    WEBSITE_TITLE: {
        goal: {
            goalId: "seen_on_boarding_title",
            releasedOn: "2020-09-15",
            triggerOnActionType: ONBOARDING_TITLE_STEP_OPENED
        },
        view: (props: TitleStepProps) => <TitleStep {...props} />,
        analyticsTitle: "title",
        virtualPageViewTitle: "onboarding-title"
    },
    LOGO: {
        goal: {
            goalId: "seen_on_boarding_logo",
            releasedOn: "2020-09-15",
            triggerOnActionType: ONBOARDING_LOGO_STEP_OPENED
        },
        view: (props: LogoStepProps) => <LogoStep {...props} />,
        analyticsTitle: "logo",
        virtualPageViewTitle: "onboarding-logo"
    },
    CONTACT: {
        goal: {
            goalId: "seen_on_boarding_address_phone_and_email",
            releasedOn: "2020-09-15",
            triggerOnActionType: ONBOARDING_ADDRESS_EMAIL_AND_PHONE_STEP_OPENED
        },
        view: (props: ContactStepProps) => <ContactStep {...props} />,
        analyticsTitle: "address, phone and email",
        virtualPageViewTitle: "onboarding-address-phone-and-email"
    },
    GMB_CATEGORIES: {
        goal: {
            goalId: "seen_on_boarding_gmb_categories",
            releasedOn: "2021-02-02",
            triggerOnActionType: ONBOARDING_GMB_CATEGORIES_STEP_OPENED
        },
        view: (props: GMBCategoriesStepProps) => <GMBCategoriesStep {...props} />,
        analyticsTitle: "google my business categories",
        virtualPageViewTitle: "onboarding-gmb-categories"
    },
    TEMPLATE_SELECTOR: {
        goal: {
            goalId: "seen_on_boarding_template_categories",
            releasedOn: "2021-02-02",
            triggerOnActionType: ONBOARDING_TEMPLATE_SELECTOR_STEP_OPENED
        },
        view: (props: TemplateSelectorStepProps) => <TemplateSelectorStep {...props} />,
        analyticsTitle: "template selector",
        virtualPageViewTitle: "onboarding-template-selector"
    },
    THEMING: {
        goal: null,
        view: (props: PreviewStepProps) => <PreviewStep {...props} />,
        analyticsTitle: "theming",
        virtualPageViewTitle: "onboarding-color-theme"
    }
};
