import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cloneDeep from 'clone-deep';

import {
    getMarketgooAccountAction,
    updateMarketgooScoreHistory
} from '../actions';
import {
    selectLastPublished,
    selectMarketgooDetectedIssues,
    marketgooLastRescanSelector,
    subscriptionTypeSelector,
    marketgooPreferencesSelector
} from '../../../../common/selectors';
import { PAGE_TASKS_KEYS, SITE_TASKS_KEYS, TASK_TYPE } from '../Report/constants';
import { MARKETGOO_TASK_STATUS } from '../constants';
import {
    calculateScore,
    getKeywordTasks,
    getPreferencesKeyForPageTask,
    getNewScoreHistory
} from '../Report/utils';
import { isEcommerceSubscription } from "../../../../../../../wbtgen/src/components/App/epics/subscriptionData/utils";

const keysOfKeywordTasksFromServer = [PAGE_TASKS_KEYS.keyonh1, PAGE_TASKS_KEYS.keyonhxorpara];

export const useMarketgooReport = () => {
    const dispatch = useDispatch();

    const detectedIssues = useSelector(selectMarketgooDetectedIssues);
    const lastRescanTimestamp = useSelector(marketgooLastRescanSelector);
    const marketgooPreferences = useSelector(marketgooPreferencesSelector) || {};
    const lastPublishedAt = useSelector(selectLastPublished);
    const subscriptionType = useSelector(subscriptionTypeSelector);

    useEffect(() => {
        if (
            detectedIssues &&
            isEcommerceSubscription(subscriptionType) &&
            (lastPublishedAt > (lastRescanTimestamp || 0))
        ) {
            dispatch(getMarketgooAccountAction());
        }
    }, [lastPublishedAt]);

    if (!detectedIssues || !marketgooPreferences) return null;

    const newDetectedIssues = cloneDeep(detectedIssues);

    newDetectedIssues.tasks.push({
        id: SITE_TASKS_KEYS.consenlpro,
        type: TASK_TYPE.site,
        passed: marketgooPreferences.consenlpro ? true : false
    });

    const tasksWithStatus = newDetectedIssues.tasks.map(task => {
        if (task.type === TASK_TYPE.site) {
            task.status = task.passed ? MARKETGOO_TASK_STATUS.empty : marketgooPreferences[task.id];

            return task;
        }

        // Page tasks

        const { pageId, fullUrl, title, isHomePage, keyword: serverKeyword } = task.data.page;

        const keyword = marketgooPreferences[getPreferencesKeyForPageTask(pageId, PAGE_TASKS_KEYS.keyword)];
        const keywordTasks = getKeywordTasks({ keyword, title, fullUrl, isHomePage });

        if (!keyword) {
            task.data.tasks = task.data.tasks.filter(task => !keysOfKeywordTasksFromServer.includes(task.id));
        } else if ((keyword || "").toLowerCase() !== serverKeyword) {
            task.data.tasks = task.data.tasks.map(task => {
                if (keysOfKeywordTasksFromServer.includes(task.id)) {
                    return {
                        ...task,
                        passed: false
                    };
                }

                return task;
            });
        }

        const pageTasks = [...keywordTasks, ...task.data.tasks];

        task.data.page.keyword = keyword;
        task.passed = pageTasks.every(pageTask => pageTask.passed);
        task.status = task.passed ? MARKETGOO_TASK_STATUS.empty : marketgooPreferences[pageId];
        task.data.tasks = pageTasks.map(pageTask => {
            const pageTaskKey = getPreferencesKeyForPageTask(pageId, pageTask.id);
            pageTask.status = pageTask.passed ? MARKETGOO_TASK_STATUS.empty : marketgooPreferences[pageTaskKey];

            return pageTask;
        });

        return task;
    });

    const siteTasks = tasksWithStatus.filter(task => task.type === TASK_TYPE.site);
    const pageTasks = tasksWithStatus.filter(task => task.type === TASK_TYPE.page);

    siteTasks.sort((taskA, taskB) => taskA.passed - taskB.passed);

    newDetectedIssues.tasks = [...siteTasks, ...pageTasks];
    const score = calculateScore(newDetectedIssues.tasks, newDetectedIssues.backLinksScore) || 0;

    const newScoreHistory = getNewScoreHistory(score, marketgooPreferences);

    if (newScoreHistory) {
        dispatch(updateMarketgooScoreHistory(newScoreHistory));
    }

    newDetectedIssues.score = score;

    return newDetectedIssues;
};
