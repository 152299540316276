import { evolve, trim } from 'ramda';
import {
    FC_TOGGLE_RESOURCE_SELECTION,
} from "../../../../redux/modules/children/fileChooser/actionTypes";
import type { AppStore } from "../../../../redux/modules/flowTypes";
import {
    LC_SAVE,
    LC_CANCEL,
    LC_RESET_CURRENT_RESULT,
} from "./actionTypes";
import { LcTabName } from './constants';
import { makeActionForwardToSelectedComponent } from '../../../../redux/forwardTo';
import { fcResetAction } from '../../../../redux/modules/children/fileChooser/actionCreators';
import closeDialogAC from '../../../../components/App/actionCreators/closeDialog';
import { linkChooserFileSelectAC, linkChooserValueResetAC, fileChooserSelectionResetAC } from './actions';

export const linkChooserMiddleware = (store: AppStore) => (next: Dispatch) => (action: AnyAction) => {
    if (action.type === LC_SAVE) {
        const { setLinkAction, ...payload } = action.payload;

        let newPayload = payload;
        if (newPayload.linkAction && newPayload.linkAction.link.type === LcTabName.PHONE) {
            newPayload = evolve({ linkAction: { link: { value: trim } } }, newPayload);
        }
        store.dispatch(makeActionForwardToSelectedComponent({
            type: setLinkAction,
            payload: newPayload
        }));

        store.dispatch(fcResetAction());
        store.dispatch(closeDialogAC());
    }

    if (action.type === FC_TOGGLE_RESOURCE_SELECTION) {
        const { payload: { resource } } = action;
        store.dispatch(linkChooserFileSelectAC({ resource }));
    }

    if (action.type === LC_CANCEL) {
        store.dispatch(fcResetAction());
        store.dispatch(closeDialogAC());
    }

    if (action.type === LC_RESET_CURRENT_RESULT) {
        store.dispatch(linkChooserValueResetAC());
        store.dispatch(fileChooserSelectionResetAC());
    }

    return next(action);
};
