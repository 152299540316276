import React from 'react';
import makeAppStandardErrorDialog from "../../../App/dialogs/makeAppStandardErrorDialog";
import { PAGE_TREE_PAGE_DELETE_CONFIRM } from "../actionTypes";
import { WarningConfirmationDialog } from '../../../../view/common/dialogs/WarningConfirmationDialog';
import { Msg } from '../../../../view/intl';

type Props = {
    pageName: string;
    dispatch: Dispatch;
};

export default makeAppStandardErrorDialog(({ pageName, dispatch }: Props) => (
    <WarningConfirmationDialog
        title="msg: deletePageAndSubPages {Delete page and subpages}"
        confirmLabel="msg: delete {Delete}"
        onConfirm={() => dispatch({ type: PAGE_TREE_PAGE_DELETE_CONFIRM })}
        dispatch={dispatch}
    >
        <Msg k="confirmDeletePage" params={{ pageName }}>
            {`Are you sure you want to delete "{pageName}"?`}
        </Msg>
    </WarningConfirmationDialog>
));
