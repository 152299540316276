import { WidgetsMailchimpKind } from './kind';
import { MailchimpView } from './view/index';
import { calcRenderProps } from './calcRenderProps';

// See wbtgen/src/view/oneweb/registry/preview.js
const WidgetsMailchimpPreviewConfig = {
    kind: WidgetsMailchimpKind,
    view: MailchimpView,
    calcProps: ({ component }) => calcRenderProps({ component, componentExtension: { isTransient: false } }),
};

export { WidgetsMailchimpPreviewConfig };
