import React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from '../../../../view/intl/index';
import { Dialog, DialogBody, DialogTitleBox, DialogFooter } from '../../../../view/common/dialogs/baseDialog/index';
import getCenteredDialogConfig from '../../../DialogManager/getCenteredDialogConfig';
import { getCenterPositionForDialog } from '../../../DialogManager/utility';
import { HIDE_FEATURED_PRODUCTS_SELECTOR_DIALOG, FEATURED_PRODUCTS_SET_ITEMS } from './actionTypes';

import ProductSelector from '../ProductSelector/components/ProductSelector';
import { useStateProductSelected } from '../ProductSelector/useState';
import styles from "../ProductSelector/components/ProductSelector.css";
import DialogFooterContent from './DialogFooterContent';
import FeaturedProductKind from '../kind';

import { makeEpicStateSelector } from '../../../../epics/makeEpic';
import { WEBSHOP_ONBOARD_STATUS } from '../../WebShop/epics/onboardStatus/valueActionType';

const FeaturedProductsSelectionDialog = injectIntl(({ dispatch, featuredProducts = [], hasOnlyDigitalShop }) => {
    const onClose = () => dispatch({ type: HIDE_FEATURED_PRODUCTS_SELECTOR_DIALOG });
    const isUpdate = Array.isArray(featuredProducts) && featuredProducts.length > 0;
    const {
        selectedProducts,
        updateSelectedProducts,
        resetSelectedProducts
    } = useStateProductSelected(featuredProducts);

    return (
        <Dialog onClose={onClose}>
            <DialogTitleBox
                title="msg: component.webshop.featuredProducts.dialog.title {Choose one or multiple products to feature}"
            />
            <DialogBody className={styles.dialogBody}>
                <ProductSelector
                    appDispatch={dispatch}
                    selectedProducts={selectedProducts}
                    updateSelectedProducts={updateSelectedProducts}
                    hasOnlyDigitalShop={hasOnlyDigitalShop}
                />
            </DialogBody>
            <DialogFooter
                noTopBorder
                className={styles.dialogFooter}
                contentClassName={styles.dialogFooterContent}
                disabled={!selectedProducts.size}
                mctaText={!isUpdate ?
                    "msg: common.addToPage {Add to page}" :
                    "msg: component.webshop.featuredProducts.dialog.UpdateProducts {Update products}"}
                mctaHandler={() => {
                    return selectedProducts.size ?
                        dispatch({
                            type: FEATURED_PRODUCTS_SET_ITEMS,
                            payload: {
                                componentKind: FeaturedProductKind,
                                isUpdate,
                                products: [...selectedProducts].map(id => `product: '${id}'`).join()
                            }
                        }) : null;
                }}
                sctaHandler={onClose}
            >
                <DialogFooterContent
                    selectedProductsCount={selectedProducts.size}
                    deSelectAll={resetSelectedProducts}
                />
            </DialogFooter>
        </Dialog>
    );
});

const
    width = 1162,
    height = 820;

const mapStateToProps = appState => {
    const { hasOnlyDigitalShop } = makeEpicStateSelector(WEBSHOP_ONBOARD_STATUS)(appState);
    return { hasOnlyDigitalShop };
};

const config = getCenteredDialogConfig({
    width,
    height,
    component: connect(mapStateToProps)(FeaturedProductsSelectionDialog),
    draggableClassName: ''
});

const getDialogDimensions = (browserHeight) => Math.min(Math.max(browserHeight - 100, 475), height);

export default {
    ...config,
    confFactory: ({ browserWidth, browserHeight }) => {
        const height = getDialogDimensions(browserHeight);
        return {
            position: getCenterPositionForDialog(width, height, browserWidth, browserHeight),
            modal: true,
            dimensions: { width, height }
        };
    },
    moveToCenter: true,
};
