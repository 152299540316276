export default (ref?: any, width?: number) => {
    window.setTimeout(function () {
        if (ref && width) {
            const span = window.$(ref).children('div.fb-page').children('span');

            if (span.length > 0) {
                const updatedStyle = span.attr('style').replace(/width:\s?\d+px/, `width: ${width}px`);
                span.attr('style', updatedStyle);
            }
        }

        /**
         * The above statment has intentionally been wrapped in try-catch block to avoid reporting
         * errors on production enviroment.
         * Upon investigation, it was found that using Mozilla Firefox in PRIVATE mode causes failure
         * in loading of third-party scripts. This needs to be investigated further.
         * Vishal Raj
         *
         * https://jira.one.com/browse/WBTGEN-6015
         */

        try {
            window.FB.XFBML.parse();
        } catch (e) { } // eslint-disable-line no-empty
    }, 500);
};
