import * as R from 'ramda';
import isStretchComponentKind from '../../../oneweb/isStretchComponentKind';
import { getWrapperIdFromWrappedComponent } from './helpers';
import {
    getNumberOfParaNodes, isWrappedLeft, isWrappedRight
} from '../../../../utils/htmlWriter/html/render/wrapper/wrapperNodeUtils';

import type {
    ComponentsMap,
    ComponentWrapOptions
} from '../../../../redux/modules/children/workspace/flowTypes';

const isWrappedCenter = R.both(R.complement(isWrappedLeft), R.complement(isWrappedRight));

const getWrapOptions = (
    componentsMap: ComponentsMap,
    selectedComponentIds: string[]
): ComponentWrapOptions => {
    const wrapOptions: ComponentWrapOptions | Record<string, any> = {};

    if (selectedComponentIds.length !== 1) {
        return wrapOptions;
    }

    const wrappedComponent = componentsMap[selectedComponentIds[0]];

    // No wrap options possible if component not selected or strip component selected
    if (!wrappedComponent || isStretchComponentKind(wrappedComponent.kind, wrappedComponent.stretch)) {
        return wrapOptions;
    }

    const computedWrapperId = getWrapperIdFromWrappedComponent(wrappedComponent, componentsMap);

    if (typeof computedWrapperId !== 'string') {
        return wrapOptions;
    }

    wrapOptions.wrapperId = computedWrapperId;

    const wrapperComponent = componentsMap[wrapOptions.wrapperId];
    if (wrappedComponent.inTemplate !== wrapperComponent.inTemplate) {
        delete wrapOptions.wrapperId;
        return wrapOptions;
    }

    if (wrappedComponent.wrap && wrappedComponent.relPara && wrappedComponent.relIn) {
        const currentParaIndex = wrappedComponent.relPara.index;

        /*
        * If a component is already wrapped, its wrap options will be:
        *   - unwrap
        *   - left (if it's already wrapped to the right)
        *   - right (if it's already wrapped to the left)
        *   - down (if there are more paragraphs below)
        *   - up (if there are more paragraphs above)
        */
        wrapOptions.unwrap = true;
        wrapOptions.left = !isWrappedLeft(wrappedComponent);
        wrapOptions.right = !isWrappedRight(wrappedComponent);
        wrapOptions.center = !isWrappedCenter(wrappedComponent);

        // $FlowFixMe
        const paraNodes = getNumberOfParaNodes(wrapperComponent.content);

        if (currentParaIndex < paraNodes) {
            wrapOptions.below = true;
        }
        if (currentParaIndex > 0) {
            wrapOptions.above = true;
        }
    } else {
        // Dont show wrap options if wrappedComponent is bigger than wrapperComponent
        if (wrappedComponent.width > wrapperComponent.width) {
            delete wrapOptions.wrapperId;
            return wrapOptions;
        }

        wrapOptions.left = true;
        wrapOptions.right = true;
        wrapOptions.center = true;
    }

    return wrapOptions;
};

export {
    getWrapOptions
};
