import { type TinyMceEpicState } from "../flowTypes";
import { TEXT_ALIGN_LEFT } from "../alignmentTypes";
import { DEFAULT_LINE_HEIGHT } from "../editorUtils/constants";

const
    tinyMceEpicDefaultState: TinyMceEpicState = {
        fontSize: 16,
        alignment: TEXT_ALIGN_LEFT,
        lineHeight: DEFAULT_LINE_HEIGHT,
        canSetLink: false,
        bold: false,
        italic: false,
        underline: false,
        color: null,
        highLightColor: null,
        shadowColor: null,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
        blurRadius: 0,
        superscript: false,
        subscript: false,
        fontFamily: null,
        spacing: [0, 0, 0, 0],
        unorderedList: false,
        orderedList: false,
        linkAction: null,
        selectedGlobalStyle: null,
        selectedLinkGlobalStyle: null,
        characterSpacing: 0,
        selectedText: '',
        isLinkable: false,
        isLinkBroken: false,
        isIndented: false,
        canBeIndented: false,
        tinyMceExist: false,
        selectedLinkedNode: null
    };

export {
    tinyMceEpicDefaultState
};
