/* eslint-disable max-len */
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from "classnames";

import Textarea from "../../../../../../../view/common/Input/Textarea";

import { useIntl } from '../../../../../../../view/intl';

import {
    AI_TEXT_EXTRAS_CHANGED
} from "../../../../epics/AITextEpic/actionTypes";

import styles from "../styles.css";
import { FIELD_LABELS, VALIDATION_FIELDS } from '../constants';
import { selectErrorFields, selectExtras } from '../../../../epics/AITextEpic/selectors';

export const ExtrasField = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const extras = useSelector(selectExtras);
    const errorFields = useSelector(selectErrorFields);

    const fieldKey = FIELD_LABELS.extras;
    const isInvalidField = errorFields.includes(fieldKey);
    const { max: maxLength } = VALIDATION_FIELDS[fieldKey];
    const [isLong, setIsLong] = useState(isInvalidField);
    const countAboveMaxLength = true;

    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const scrollIntoView = () => {
            const isFirstErrorField = errorFields && errorFields.indexOf(fieldKey) === 0;
            return isFirstErrorField && ref?.current?.scrollIntoView({ behavior: 'smooth' });
        };

        scrollIntoView();
    }, [errorFields, fieldKey]);

    return (
        <div className={styles.fieldWrapper}>
            <div className={cx(styles.fieldLabel, isLong && styles.errorLabel)}>
                {intl.msgJoint("msg: component.text.createAIText.dialog.textBuilder.extras.label {What should be mentioned? (optional)}")}
            </div>
            <div>
                <Textarea
                    placeholder="msg: component.text.createAIText.dialog.textBuilder.extras.placeholder {e.g. great breakfast, organic ingredients, sustainability.}"
                    value={extras || ''}
                    onChange={value => {
                        const errorflag = value?.trim().length > maxLength;
                        setIsLong(errorflag);
                        dispatch({ type: AI_TEXT_EXTRAS_CHANGED, payload: value });
                    }}
                    counter={{ maxLength }}
                    countBeyondMax={countAboveMaxLength}
                    className={cx(styles.inputFieldExpanded, { [styles.invalidInputField]: isLong })}
                />
                {
                    isLong ? (
                        <div className={styles.invalidInputError}>
                            {intl.msgJoint("msg: common.error.longInput {The entered text is too long.}")}
                        </div>
                    ) : (null)
                }
            </div>
        </div>
    );
};
