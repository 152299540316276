import {
    WORKSPACE_LEFT_MOUSE_DOWN,
    WORKSPACE_SCROLL,
    SELECT_ALL_COMPONENTS,
    WORKSPACE_ON_MOUSE_LEAVE,
    WORKSPACE_ON_MOUSE_ENTER,
    WORKSPACE_MOUSE_UP,
    DELETE_SELECTED_COMPONENTS,
    WORKSPACE_READY
} from '../actionTypes';
import type { Position } from '../../../redux/modules/flowTypes';

export const
    /* TODO WBTGEN-7462 consider removing workspace specific mouse down and use app mouse down */
    mouseDownAC = (position: Position) => ({ type: WORKSPACE_LEFT_MOUSE_DOWN, payload: position }),
    scrollAC = (payload: Position) => ({ type: WORKSPACE_SCROLL, payload }),
    onMouseLeave = () => ({ type: WORKSPACE_ON_MOUSE_LEAVE }),
    onMouseEnterAC = () => ({ type: WORKSPACE_ON_MOUSE_ENTER }),
    selectAllComponents = () => ({ type: SELECT_ALL_COMPONENTS }),
    onMouseUp = () => ({ type: WORKSPACE_MOUSE_UP }),
    deleteSelectedComponentAction = () => ({ type: DELETE_SELECTED_COMPONENTS }),
    workspaceReadyAction = () => ({
        type: WORKSPACE_READY,
    });

export { default as checkStylesheetDeletable } from './checkStylesheetDeletable';
