import { SUPPORT_DELETE_SELECTED_SECTION } from "./actions";
import { DELETE_SELECTED_SECTION } from "../../Workspace/actionTypes";
import { ReceiveOnlySelectedComponentSelector } from "../../Workspace/epics/componentsEval/selectorActionTypes";
import { getNonGhostCmps, simpleTopSorter } from "../../Workspace/epics/componentAttachements/util";
import { isSectionKind } from "../../oneweb/componentKinds";

export default {
    conditions: [
        ReceiveOnlySelectedComponentSelector,
        SUPPORT_DELETE_SELECTED_SECTION
    ],
    reducer: ({ state: epicState, values: [selectedComponent] }) => {
        let { state: { componentsMap } } = epicState,
            sectionId = selectedComponent && selectedComponent.id,
            sections = getNonGhostCmps(componentsMap).filter(c => isSectionKind(c.kind)).sort(simpleTopSorter);
        const sameState = {
            state: epicState,
            updateReason: SUPPORT_DELETE_SELECTED_SECTION
        };
        if (sectionId) {
            let index = sections.findIndex(c => c.id === sectionId);
            if (index === -1) {
                return sameState;
            }
            sections.splice(index, 1);
            if (sections.length < 3 || !sections[0].inTemplate || !sections[sections.length - 1].inTemplate) {
                return sameState;
            }

            return {
                state: epicState,
                updateReason: SUPPORT_DELETE_SELECTED_SECTION,
                actionToDispatch: {
                    type: DELETE_SELECTED_SECTION, payload: { sectionId: selectedComponent.id, templateSection: true }
                }
            };
        }
        return sameState;
    }
};
