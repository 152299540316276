import valueActionType from './valueActionType';
import { whenReason, receiveOnlyWhenReason } from '../../../../epics/makeCondition';
import { PAGE_DATASET_LOADED } from "./updateReasons";

const
    pageDatasetLoadedActionType = whenReason(valueActionType, PAGE_DATASET_LOADED),
    receiveOnlyPageDataSetLoadedActionType = receiveOnlyWhenReason(valueActionType, PAGE_DATASET_LOADED);

export {
    pageDatasetLoadedActionType as default,
    receiveOnlyPageDataSetLoadedActionType
};

