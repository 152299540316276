import makeEpic from '../../../../epics/makeEpic';
import { selectedComponentIsHeaderVAT } from './valueActionType';
import {
    UserInteractionModeSelectedComponentNoGhostsSelector,
} from '../componentsEval/selectorActionTypes';
import { isHeaderSection } from "../../../oneweb/Section/utils";

const defaultState = false;

export const selectedComponentIsHeaderEpic = makeEpic({
    defaultState,
    valueActionType: selectedComponentIsHeaderVAT,
    updaters: [
        {
            conditions: [UserInteractionModeSelectedComponentNoGhostsSelector],
            reducer: ({ values: [{ selectedComponentNotGhost }] }) => {
                if (!selectedComponentNotGhost) {
                    return { state: defaultState };
                }
                return { state: isHeaderSection(selectedComponentNotGhost) };
            }
        },
    ]
});
