import React from "react";
import { OPEN_DIALOG } from "../../../redux/modules/actionTypes";
import TextIconButton from '../TextIconButton/index';
import Icons from '../../../view/Icons/index';
import { injectIntl } from '../../../view/intl/index';
import type { BorderStyleButtonPropTypes } from "./flowTypes";

export default injectIntl(({
    dialogId,
    borderStyleChangeAction,
    borderColorChangeAction,
    borderColorRemoveAction,
    borderColorOpacityChangeAction,
    borderWidthChangeAction,
    dispatch,
    intl
}: BorderStyleButtonPropTypes) => (
    <TextIconButton
        large
        text={intl.msgJoint('msg: common.border {Border}')}
        Icon={Icons.BOTTOM}
        onClick={() => dispatch({
            type: OPEN_DIALOG,
            payload: {
                dialogId,
                props: {
                    borderStyleChangeAction,
                    borderColorChangeAction,
                    borderColorRemoveAction,
                    borderColorOpacityChangeAction,
                    borderWidthChangeAction,
                    dispatch
                }
            }
        })}
    />
));
