import React from "react";
export const SvgLinkedinRegularLight = props => (
    <svg viewBox="0 0 43 43" {...props}>
        <path
            fill="#ffffff"
            fillRule="evenodd"
            d="M39.34 0c1.731 0 3.142 1.371 3.142 3.063v36.354c0 1.692-1.41 3.066-3.143 3.066H3.133C1.405 42.483 0 41.109 0 39.417V3.063C0 1.371 1.405 0 3.133 0H39.34zM9.45 5.849a3.652 3.652 0 00-3.656 3.653A3.655 3.655 0 109.45 5.848zm-3.155 30.35h6.307V15.927H6.295V36.2zm10.26-20.273V36.2h6.293V26.173c0-2.645.5-5.208 3.779-5.208 3.233 0 3.274 3.025 3.274 5.376V36.2h6.3V25.08c0-5.46-1.179-9.658-7.559-9.658-3.065 0-5.121 1.681-5.961 3.275h-.087v-2.772h-6.04z"
        />
    </svg>
);
