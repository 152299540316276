import pageTitles from "../../../Background/propertiesPanel/pageTitles";
import { BORDER } from "../../../Background/propertiesPanel/pageIds";
import borderPageFactory from "../../../Background/propertiesPanel/pages/borderPageFactory";

const
    id = BORDER,
    title = pageTitles[id],
    options = {
        showTopAndBottomElements: true,
        showLeftAndRightElements: false,
        showCorners: false,
        showOpacity: true
    };

export default borderPageFactory(id, title, options);
