import { ProductListStyles, ViewDetailsButtonPostions, MIN_DIMENSIONS } from "../constants";
import { BUTTON_THEME_PRIMARY, BUTTON_THEME_SECONDARY } from "../../../ThemeGlobalData/constants";

export const reducerDefaultData = {
    font: '',
    fontColor: ["HSL", 0, 0, 0, 1],
    fontColorTheme: null,
    focusColor: ["HSL", 0.5680555555555555, 0.6249999999999999, 0.6235294117647059, 1],
    focusColorTheme: null,
    hoverColor: ["HSL", 0, 0, 1, 0.2],
    hoverColorTheme: null,
    temporaryHoverOpacity: 0,
    imageRatio: "square",
    cropImages: false,
    productsPerRow: 3,
    productsPerRowInMobile: 1,
    spacingItemsInMobile: 20,
    labelBgColor: ["HSL", 0.5680555555555555, 0.6249999999999999, 0.6235294117647059, 1],
    labelBgColorTheme: null,
    labelTextColor: ["HSL", 0, 0, 1, 1],
    labelTextColorTheme: null,
    labelPosition: "topLeft",
    productListStyle: ProductListStyles.Default,
    width: MIN_DIMENSIONS.width,
    height: MIN_DIMENSIONS.height,
    viewDetailsShowButton: true,
    viewDetailsButtonText: '',
    viewDetailsButtonStyle: '',
    viewDetailsButtonPosition: ViewDetailsButtonPostions.RIGHT.value,
    viewDetailsButtonId: '',
    viewDetailsButtonThemeSelected: BUTTON_THEME_SECONDARY,
    viewDetailsButtonClassname: '',
    buttonClassname: '',
    isSingleProduct: false,
    isWebshopSetupDone: true,
    newHeight: 0,
    enableBuyNowButton: false,
    buyNowButtonText: '',
    buyNowButtonStyle: '',
    buyNowButtonId: '',
    buyNowButtonThemeSelected: BUTTON_THEME_PRIMARY,
    buyNowButtonClassName: '',
    promoRibbonBgColor: ["HSL", 0.5680555555555555, 0.6249999999999999, 0.6235294117647059, 1],
    promoRibbonBgColorTheme: null,
    promoRibbonTextColor: ["HSL", 0, 0, 1, 1],
    promoRibbonTextColorTheme: null,
};
