import { getCurrentPage, getInstanceCurrentPage, navigateForward, updateSequenceOrder } from "../utils/navigation";
import type { StateHolder, PageId } from "../../flowTypes";
import { getDimensions } from "../utils/dimensions";

export default ({ pageId, additionalPayload }: PageId, { scope, state }: StateHolder): StateHolder => {
    const { currentPage: { id: currentPageId } } = state;
    if (currentPageId === pageId) {
        return { scope, state };
    }

    const pageIndex = state.navigation.sequence.indexOf(pageId),
        activeIndex = state.navigation.activePageIndex,

        updatedNavigation = (pageIndex !== -1 && pageIndex < activeIndex) ?
            updateSequenceOrder(state.navigation, pageId, pageIndex) :
            navigateForward(state.navigation, pageId),
        currentPage = state.instance
            ? getInstanceCurrentPage(state.instance.id, updatedNavigation)
            : getCurrentPage(updatedNavigation, state.selectedComponentWithId.component.kind),
        dimensions = getDimensions(scope.maxHeight, currentPage.height);

    return {
        scope,
        state: { ...state, navigation: updatedNavigation, currentPage, dimensions, additionalPayload }
    };
};
