import makeEpic from '../../../../epics/makeEpic';
import { optional } from "../../../../epics/makeCondition";
import {
    loadSectionsListDataApiAction,
    LOAD_SECTION_LIST_SUCCESS_ACTION,
    SET_FILTER,
    LOAD_SECTION_LIST_FAILURE_ACTION,
    SECTIONS_BLOCKS_FETCHED
} from "../../actionTypes";
import valueActionType from './valueActionType';
import {
    SITE_DATA_LOAD_SUCCESS_PAGES_EXIST,
    SITE_DATA_LOAD_SUCCESS_NO_PAGES,
} from "../../../App/actionTypes";
import { stripsStylesMap, WHITE_STRIP } from "../../../oneweb/Background/extendedPanel/strips/config";

export const ALL_FILTER_KEY = "all",
    WELCOME_SECTION_KEY = "welcome",
    ABOUT_SECTION_KEY = "about",
    PRODUCTS_SECTION_KEY = "products",
    SERVICES_SECTION_KEY = "services",
    CONTACT_SECTION_KEY = "contact",
    TEXT_AND_BUTTONS = 'text-&-buttons';

export const SectionDataMap = [
    {
        id: ALL_FILTER_KEY,
        title: "msg: common.sections.all {All}",
    },
    {
        id: WELCOME_SECTION_KEY,
        title: "msg: common.sections.welcome {welcome}",
        full_title: "msg: common.sections.welcomesections {Welcome sections}",
    },
    {
        id: ABOUT_SECTION_KEY,
        title: "msg: common.sections.about {about}",
        full_title: "msg: common.sections.aboutsections {About sections}",
    },
    {
        id: PRODUCTS_SECTION_KEY,
        title: "msg: common.sections.products {products}",
        full_title: "msg: common.sections.productssections {Products sections}",
    },
    {
        id: SERVICES_SECTION_KEY,
        title: "msg: common.sections.services {services}",
        full_title: "msg: common.sections.servicessections {Services sections}",
    },
    {
        id: CONTACT_SECTION_KEY,
        title: "msg: common.sections.contact {contact}",
        full_title: "msg: common.sections.contactsections {Contact sections}",
    },
    {
        id: TEXT_AND_BUTTONS,
        title: "msg: common.sections.textAndButtons {Text & buttons}",
        full_title: "msg: common.sections.textAndButtonssections {Text & buttons sections}",
    }
];

export const SectionDataMessageMap = SectionDataMap.reduce((acc, section) => ({
    ...acc,
    [section.id]: section.full_title || section.title
}), {});

const cacheImages = (items) => {
    let cache: any = [],
        img;
    items.forEach(category => {
        category.items.forEach(item => {
            if (item.image) {
                img = new Image();
                img.src = item.image;
                cache.push(img);
            }
        });
    });
};

const getSectionsBlocksMap = (sectionsBlocks) => {
    const sectionsBlocksMap = {};
    const blocksMap = {};
    Object.keys(sectionsBlocks).forEach(kind => {
        cacheImages(sectionsBlocks[kind]);
        sectionsBlocks[kind].forEach(category => {
            category.items.forEach(section => {
                sectionsBlocksMap[section.id] = section;
                if (kind === 'blocks') {
                    blocksMap[section.id] = section;
                }
            });
        });
    });
    return {
        sectionsBlocksMap,
        blocksMap,
    };
};

export default makeEpic({
    defaultState: {
        componentKind: null,
        sectionsBlocks: {},
        sectionsBlocksMap: {},
        blocksMap: {},
        listLoaded: false,
        filterKey: ALL_FILTER_KEY,
        hasError: false
    },
    valueActionType,
    updaters: [
        {
            conditions: [
                LOAD_SECTION_LIST_SUCCESS_ACTION
            ],
            reducer: ({ state, values: [
                data
            ] }) => {
                let completeData = { ...data };
                completeData.sections.push({
                    id: "blank",
                    items: [{
                        data: {
                            componentsMap: {
                                '58CDFBC1-7574-44C1-953F-14EFB79C09C2': {
                                    "id": "58CDFBC1-7574-44C1-953F-14EFB79C09C2",
                                    "kind": "SECTION",
                                    "top": 80,
                                    "left": -318,
                                    "width": 1700,
                                    "height": 400,
                                    ...stripsStylesMap[WHITE_STRIP]
                                }
                            },
                            templateWidth: 1700
                        },
                        id: 'blank',
                        height: 400,
                        key: 'all',
                        width: 1700
                    }],
                    key: "all"
                });
                return {
                    state: {
                        ...state,
                        listLoaded: true,
                        hasError: false,
                        sectionsBlocks: completeData,
                        ...getSectionsBlocksMap(completeData)
                    },
                                        actionToDispatch: {
                        type: SECTIONS_BLOCKS_FETCHED,
                        payload: data
                    }
                };
            },
        },
        {
            conditions: [
                LOAD_SECTION_LIST_FAILURE_ACTION
            ],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        listLoaded: true,
                        hasError: true
                    }
                };
            },
        },
        {
            conditions: [
                SET_FILTER
            ],
            reducer: ({ state, values: [key] }) => {
                return {
                    state: {
                        ...state,
                        filterKey: key
                    }
                };
            },
        },
        {
            conditions: [
                optional(SITE_DATA_LOAD_SUCCESS_PAGES_EXIST),
                optional(SITE_DATA_LOAD_SUCCESS_NO_PAGES),
            ],
            reducer: ({ state }) => (state.listLoaded ? { state } : ({
                state,
                actionToDispatch: loadSectionsListDataApiAction(),
            }))
        },
    ]
});
