import React from "react";
export const SvgLinkedinRegularMulticolor = props => (
    <svg viewBox="0 0 43 43" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="#2867b2"
                d="M39.34 0H3.132C1.405 0 0 1.371 0 3.063v36.354c0 1.692 1.405 3.066 3.133 3.066H39.34c1.732 0 3.143-1.374 3.143-3.066V3.063C42.483 1.371 41.073 0 39.34 0"
            />
            <path
                fill="#ffffff"
                d="M9.45 5.849a3.654 3.654 0 11-.005 7.308A3.654 3.654 0 019.45 5.85zm-3.155 30.35h6.307V15.927H6.295V36.2zm10.26-20.273h6.04v2.772h.086c.84-1.594 2.896-3.275 5.961-3.275 6.38 0 7.56 4.198 7.56 9.658V36.2H29.9v-9.86c0-2.351-.04-5.376-3.274-5.376-3.279 0-3.78 2.563-3.78 5.208V36.2h-6.292V15.926"
            />
        </g>
    </svg>
);
