import * as R from 'ramda';
import { applyMappers, makePlainMappers } from "../../utils";
import type { WebShopPoliciesComponent } from
    "../../../../src/components/oneweb/WebShopMHF/flowTypes";

const plainPropsMapper = makePlainMappers({
    mobileHide: 'mobileHide',
    mobileDown: 'mobileDown',
    generic: 'generic',
});

export function to(componentData: any): WebShopPoliciesComponent {
    return applyMappers(
        componentData,
        R.pipe(
            plainPropsMapper.to,
        )
    );
}

export function back(componentData: WebShopPoliciesComponent): any {
    return applyMappers(
        componentData,
        R.pipe(
            plainPropsMapper.back,
        )
    );
}
