import React from "react";
import { connect } from "react-redux";
import { injectIntl } from '../../../../view/intl/index';
import { PremiumIcon } from '../../../../view/common/PremiumIcon/index';
import VerticalSpacer from "../../../../view/common/VerticalSpacer";
import { WIDGETS_ALL, WIDGETS_FILTERS } from "../../../oneweb/Widgets/constants";
import { EPSeparator } from "../EPSeparator";
import { DroppableCmpShortcut, ExtendedPanelShortCut } from "../DroppableCmpShortcut";
import { makeEpicStateSelector } from "../../../../epics/makeEpic";
import WIDGETS_FILTER_VALUE_ACTION_TYPE from "../../epics/widgets/valueActionType";
import { checkSubscriptionCompatibilityFromKind } from "../../../ComponentTierManager/utils";
import styles from '../Inserter.css';
import { isEcommerceSubscription } from "../../../App/epics/subscriptionData/utils";

const CategoryView = injectIntl((props) => {
    const { subscriptionType, category, subCategories, intl, dispatch } = props;
    return (
        <div>
            <VerticalSpacer y={17} />
            <div className={styles.subTitle}>{intl.msgJoint(category.title)}</div>
            <VerticalSpacer y={12} />
            <div className={styles.shortCutsContainer}>
                {
                    subCategories.map(subCat => {
                        const showCmpIcon = subCat.isEcommerce ? isEcommerceSubscription(subscriptionType) : true;
                        const showPremiumIcon = subCat.isPremium
                            ? !checkSubscriptionCompatibilityFromKind(subCat.kind, subscriptionType)
                            : false;

                        return (
                            showCmpIcon && (<DroppableCmpShortcut
                                key={subCat.id}
                                kind={subCat.kind}
                                payload={subCat.payload}
                                dispatch={dispatch}
                            >
                                {showPremiumIcon &&
                                    <PremiumIcon iconCustomClass={styles.premiumSocialMediaIconOnWidget} />}
                                <ExtendedPanelShortCut iconClassName={subCat.iconClassName} text={subCat.title} />
                            </DroppableCmpShortcut>)
                        );
                    })
                }
            </div>
        </div>
    );
});

const CategoriesView = (props) => {
    const { subscriptionType, categories, dispatch } = props;

    return categories
        .map(category => (
            <React.Fragment key={category.id}>
                <CategoryView
                    category={category}
                    subCategories={category.subCategories}
                    subscriptionType={subscriptionType}
                    dispatch={dispatch}
                />
                <VerticalSpacer y={25} />
                <EPSeparator />
            </React.Fragment>
        ));
};

const ContentView = (props) => {
    const { subscriptionType, filterKey, dispatch } = props;
    const categories = filterKey === WIDGETS_ALL
        ? WIDGETS_FILTERS.filter(cat => cat.id !== WIDGETS_ALL)
        : WIDGETS_FILTERS.filter(cat => cat.id === filterKey);

    return (
        <div>
            <VerticalSpacer y={17} />
            <CategoriesView
                subscriptionType={subscriptionType}
                categories={categories}
                dispatch={dispatch}
            />
        </div>
    );
};

const mapStateToProps = (appState) => ({
    filterKey: (makeEpicStateSelector(WIDGETS_FILTER_VALUE_ACTION_TYPE)(appState)).filterKey
});

export const Content = connect(mapStateToProps)(ContentView);
