
import {
    makeCombineReducer,
    makeDefaultStateReducers,
    makeComponentBaseReducers
} from "../../../../redux/makeReducer/index";
import * as actions from "./actionTypes";
import { WidgetsMailchimpKind } from "./kind";
import { defaultState, DEFAULT_HEIGHT, DEFAULT_WIDTH } from "./constants";

const reducer = makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(WidgetsMailchimpKind, DEFAULT_WIDTH, DEFAULT_HEIGHT),
        ...makeDefaultStateReducers(defaultState)
    },
    handleActions: {
        [actions.WIDGETS_MAILCHIMP_UPDATE_LINK]: (component, { payload: link }) => ({
            ...component, link
        }),
        [actions.WIDGETS_MAILCHIMP_UPDATE_PROPS]: (component, { payload: params }) => ({
            ...component, ...params
        }),
    }
});

export { reducer };
