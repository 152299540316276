import { UnsplashInitialState } from "./UnsplashInitialState";
import * as Actions from "../actions";
import { Lit } from "../../../../lit";
import type { ShutterstockState } from "../../shutterstock/types";

import { makeCombineReducer,
    makeDefaultStateReducers,
    makeValuePathReducer,
    makePayloadPathReducer } from "../../../../redux/makeReducer/index";
import makeInputReducer from "../../../inputControls/input/reducerFactory/makeInputReducer";
import { lengthInputValidation } from "../../../inputControls/input/commonValidations/index";
import { selectShutterstockImageReducer } from "../../shutterstock/reducer/selectShutterstockImageReducer";
import { shutterstockToggleReducer } from "../../shutterstock/reducer/shutterstockToggleReducer";

export const unsplashReducer = makeCombineReducer({
    combineReducers: {
        ...makeDefaultStateReducers(UnsplashInitialState),
        [Lit.filters]: makeCombineReducer({
            combineReducers: {
                ...makeDefaultStateReducers(UnsplashInitialState.filters),
                [Lit.search]: makeInputReducer({
                    actionType: Actions.UNSPLASH_SEARCH_INPUT_CHANGE_ACTION,
                    validations: [
                        {
                            validation: lengthInputValidation,
                            options: { min: 0, max: 200 },
                        }
                    ],
                    rejectInvalidValue: true,
                }),
            },
        })
    },
    handleActions: {
        [Actions.INITIALIZE_UNSPLASH_ACTION]: makeValuePathReducer(true, [Lit.initialized]),
        [Actions.SELECT_UNSPLASH_CATEGORY_ACTION]: (state: ShutterstockState, action: Record<string, any>) => ({
            ...state,
            [Lit.filters]: {
                ...state[Lit.filters],
                [Lit.category]: action.payload.categoryId
            }
        }),
        [Actions.GET_UNSPLASH_CATEGORIES_ACTION]: makeValuePathReducer(true, [Lit.categoriesLoading]),
        [Actions.GET_UNSPLASH_CATEGORIES_SUCCESS_ACTION]: (state: ShutterstockState, action: Record<string, any>) => ({
            ...state,
            [Lit.categoriesLoading]: false,
            [Lit.categories]: action.payload.categories
        }),
        [Actions.GET_UNSPLASH_CATEGORIES_FAILURE_ACTION]: makeValuePathReducer(false, [Lit.categoriesLoading]),
        [Actions.SEARCH_UNSPLASH_IMAGES_REQUEST_ACTION]: makeValuePathReducer(true, [Lit.imagesLoading]),
        [Actions.SEARCH_UNSPLASH_IMAGES_SUCCESS_ACTION]: (state: ShutterstockState, action: Record<string, any>) => ({
            ...state,
            [Lit.imagesLoading]: false,
            [Lit.images]: action.payload.images,
            [Lit.imagesSearchId]: action.payload.imagesSearchId,
            [Lit.filters]: {
                ...state[Lit.filters],
                [Lit.page]: 1,
            },
            [Lit.emptySearchResults]: false,
        }),
        [Actions.HIDE_UNSPLASH_PREMIUM_DOWNLOADS_MSG_ACTION]: (state: ShutterstockState) => ({
            ...state,
            [Lit.showPremiumDownloadsMsg]: false
        }),
        [Actions.DESELECT_ALL_UNSPLASH_IMAGES_ACTION]: makeValuePathReducer([], [Lit.selectedImages]),
        [Actions.SEARCH_UNSPLASH_IMAGES_FAILURE_ACTION]: makeValuePathReducer(false, [Lit.imagesLoading]),
        [Actions.UNSPLASH_GRID_SCROLL_TOP_ACTION]: makePayloadPathReducer([Lit.gridScrollTop]),
        [Actions.SELECT_UNSPLASH_IMAGE_ACTION]: selectShutterstockImageReducer,
        [Actions.LOAD_UNSPLASH_IMAGES_PAGE_REQUEST_ACTION]: makeValuePathReducer(true, [Lit.imagesPageLoading]),
        [Actions.LOAD_UNSPLASH_IMAGES_PAGE_SUCCESS_ACTION]: (state: ShutterstockState, action: Record<string, any>) => {
            const
                { payload: { images: pageImages }, endpointParams: [, { page: nextPage }] } = action,
                nextImages = [...state.images, ...pageImages];

            return {
                ...state,
                [Lit.filters]: {
                    ...state[Lit.filters],
                    [Lit.page]: nextPage,
                },
                [Lit.images]: nextImages,
                [Lit.imagesPageLoading]: false,
                [Lit.emptySearchResults]: !pageImages.length
            };
        },
        [Actions.LOAD_UNSPLASH_IMAGES_PAGE_FAILURE_ACTION]: makeValuePathReducer(false, [Lit.imagesPageLoading]),
        [Actions.UNSPLASH_PORTRAIT_ORIENTATION_CLICKED_ACTION]: shutterstockToggleReducer(
            Lit.isPortraitMode, Lit.isLandscapeMode
        ),
        [Actions.UNSPLASH_LANDSCAPE_ORIENTATION_CLICKED_ACTION]: shutterstockToggleReducer(
            Lit.isLandscapeMode, Lit.isPortraitMode
        ),
        [Actions.DOWNLOAD_UNSPLASH_IMAGES_REQUEST_ACTION]: makeValuePathReducer(true, [Lit.isUploading]),
        [Actions.DOWNLOAD_UNSPLASH_IMAGES_SUCCESS_ACTION]: makeValuePathReducer(false, [Lit.isUploading]),
        [Actions.DOWNLOAD_UNSPLASH_IMAGES_FAILURE_ACTION]: makeValuePathReducer(false, [Lit.isUploading]),
        [Actions.UNSPLASH_CLEAN_SEARCH_ACTION]: (state: ShutterstockState) => ({
            ...state,
            [Lit.filters]: {
                ...state[Lit.filters],
                [Lit.search]: {
                    ...state[Lit.filters][Lit.search],
                    value: '',
                },
            },
        }),
    }
});
