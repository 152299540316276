/* eslint-disable max-len */
import React from "react";
import cx from "classnames";

import { Msg, useIntl } from "../../../../../../view/intl";
import styles from "./styles.css";

export const NoMatchTag = ({ searchText, hide = false }) => {
    const intl = useIntl();

    return (
        <div className={cx(styles.noMatchContainer, { [styles.hide]: hide })}>
            <div className={styles.noMatchIcon} />
            <div className={styles.noMatchContent}>
                <div className={styles.bannerLabel}>
                    <Msg
                        k="onboarding.dynamic.step0.noMatchList.title"
                        params={{ value: searchText }}
                    >
                        {`Continue with \"{value}\"`}
                    </Msg>
                </div>
                <div className={styles.bannerDesc}>
                    {intl.msgJoint("msg: onboarding.dynamic.step0.noMatchList.msg {We couldn’t find the precise website category that you’re looking for. You can continue with the entered category or try searching again with a different word.}")}
                </div>
            </div>
        </div>
    );
};
