import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Lottie from "react-web-lottie";

import animationData from "./animation.json";
import { getStepMarkers, LOTTIE_DEFAULT_OPTIONS } from "../../../view/LottieAnimations/constant";

import { WIZARD_STEP_ID } from "../../constants";
import { STEP_MARKERS } from "./constants";
import { purposeSelector, catchAllConceptKeySelector, optionSetSelector } from "../../Epic/selectors";
import { SVG_MAP } from "./svgUtils";

const markers = animationData.markers;
const stepMarkers = getStepMarkers(markers, WIZARD_STEP_ID.WEBSITE_PURPOSE, STEP_MARKERS[WIZARD_STEP_ID.WEBSITE_PURPOSE]);

export const WebsitePurposeAnimation = () => {
    const purpose = useSelector(purposeSelector);
    const { key: optionSetKey } = useSelector(optionSetSelector) || {};
    const catchAllConceptKey = useSelector(catchAllConceptKeySelector);

    const animationRef = useRef(null);

    const [startPoint, setStartPoint] = useState(stepMarkers.concept_loading);
    const [stopPoint, setStopPoint] = useState(stepMarkers.website_purpose_start_point);

    const initialSegment = [startPoint, stopPoint];

    const defaultOptions = {
        ...LOTTIE_DEFAULT_OPTIONS,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid meet"
        },
        initialSegment,
        animationData
    };

    const replaceAllImagesWithDefaultHtml = () => {
        for (let i = 0; i < 3; i++) {
            let element = document.querySelectorAll(`div[class*="animationContainer"] g[class="png"] image`);
            if (element) {
                // @ts-ignore
                element[i].href.baseVal = "";
            }
        }
    };

    const replaceWithImages = (itemList) => {
        for (let i = 0; i < itemList.length; i++) {
            let element = document.querySelectorAll(`div[class*="animationContainer"] g[class="png"] image`);
            if (element) {
                // @ts-ignore
                element[i].href.baseVal = "data:image/svg+xml;base64," + btoa(
                    (SVG_MAP[`${optionSetKey}_${itemList[i]}`] ||
                    SVG_MAP[itemList[i]] || '').trim()
                );
            }
        }
    };

    const setPointsIfNoItems = () => {
        if (stopPoint !== stepMarkers.website_purpose_start_point) {
            setStartPoint(stepMarkers.website_purpose_one_added);
            setStopPoint(stepMarkers.website_purpose_start_point);
        }
    };

    const setPointsIfOneItem = () => {
        if (stopPoint === stepMarkers.website_purpose_start_point) {
            setStartPoint(stopPoint);
            setStopPoint(stepMarkers.website_purpose_one_added);
        } else if (stopPoint === stepMarkers.website_purpose_one_added) {
            setStartPoint(stepMarkers.website_purpose_start_point);
            setStopPoint(stepMarkers.website_purpose_one_added);
        } else {
            setStartPoint(stepMarkers.website_purpose_two_added);
            setStopPoint(stepMarkers.website_purpose_one_added_scroll_up);
        }
    };

    const setPointsIfTwoItems = () => {
        if (stopPoint === stepMarkers.website_purpose_one_added) {
            setStartPoint(stepMarkers.website_purpose_one_added_scroll_up);
            setStopPoint(stepMarkers.website_purpose_two_added);
        } else {
            setStartPoint(stepMarkers.website_purpose_end_point);
            setStopPoint(stepMarkers.website_purpose_two_added_scroll_up);
        }
    };

    const setPointsIfThreeItems = () => {
        if (stopPoint !== stepMarkers.website_purpose_end_point) {
            setStartPoint(stepMarkers.website_purpose_two_added_scroll_up);
            setStopPoint(stepMarkers.website_purpose_end_point);
        }
    };

    useEffect(() => {
        let itemList = [...purpose];
        if (catchAllConceptKey) {
            itemList.push(catchAllConceptKey);
        }

        const l = itemList.length;
        if (l === 0) {
            setPointsIfNoItems();
        } else if (l === 1) {
            setPointsIfOneItem();
        } else if (l === 2) {
            setPointsIfTwoItems();
        } else if (l > 2) {
            setPointsIfThreeItems();
        }

        replaceAllImagesWithDefaultHtml();
        replaceWithImages(itemList);
    }, [purpose.join(), catchAllConceptKey]);

    useEffect(() => {
        // @ts-ignore
        animationRef.current.playSegments([[startPoint, stopPoint]], true);
    }, [startPoint, stopPoint]);

    return (
        <Lottie lottiRef={animationRef} options={defaultOptions} isClickToPauseDisabled />
    );
};
