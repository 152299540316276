import u from 'updeep';

import {
    makeCombineReducer,
    makeDefaultStateReducers,
    makeDefaultStateReducer,
    makePathUnsetterReducer,
    makeNegationPathPayloadToSetterReducer
} from "../../../../redux/makeReducer";
import type { LinkPageFormReducerActionTypes, LinkPageFormReducer } from "../flowTypes";
import defaultState from "./linkPageDefaultState";
import { makeUrlInputReducer, makeInputReducer } from "../../../inputControls/input/reducerFactory";

export default (actions: LinkPageFormReducerActionTypes): LinkPageFormReducer => makeCombineReducer({
    combineReducers: {
        ...makeDefaultStateReducers(defaultState),
        linkTo: makeCombineReducer({
            combineReducers: {
                url: makeUrlInputReducer({ actionType: actions.URL_CHANGE, defaultState: defaultState.linkTo.url }),
                resource: makeDefaultStateReducer(defaultState.linkTo.resource)
            }
        }),
        name: makeInputReducer({ actionType: actions.NAME_CHANGE })
    },
    handleActions: {
        [actions.SET_LINK_TO_FILE_OR_PAGE]: (state, action) => {
            const
                { openInNewWindow: stateOpenInNewWindow } = state,
                { payload: { linkAction } } = action,
                resource = linkAction ? linkAction.link : null,
                openInNewWindow = linkAction ? linkAction.openInNewWindow : stateOpenInNewWindow;
            return u({ linkTo: { resource }, openInNewWindow }, state);
        },
        [actions.UNSET_LINK_TO_FILE_OR_PAGE]: makePathUnsetterReducer(['linkTo', 'resource']),
        [actions.OPEN_NEW_WINDOW_TOGGLE]: makeNegationPathPayloadToSetterReducer(['openInNewWindow']),
        [actions.IS_HIDDEN_TOGGLE]: makeNegationPathPayloadToSetterReducer(['isHidden'])
    }
});
