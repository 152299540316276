/* eslint-disable max-len */
import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Lottie from "react-web-lottie";

import animationData from "./animation.json";
import { getStepMarkers, LOTTIE_DEFAULT_OPTIONS } from "../../../view/LottieAnimations/constant";

import { WIZARD_STEP_ID } from "../../constants";
import { STEP_MARKERS } from "./constants";

import { languageSelector } from "../../Epic/selectors";

const markers = animationData.markers;
const stepMarkers = getStepMarkers(markers, WIZARD_STEP_ID.PRONOUNS, STEP_MARKERS[WIZARD_STEP_ID.LANGUAGE]);

export const LanguageAnimation = () => {
    const language = useSelector(languageSelector);
    const animationRef = useRef(null);

    const [startPoint, setStartPoint] = useState(stepMarkers.language_start_point);
    const [stopPoint, setStopPoint] = useState(stepMarkers.language_changed);

    const initialSegment = [startPoint, stopPoint];

    const defaultOptions = {
        ...LOTTIE_DEFAULT_OPTIONS,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid meet"
        },
        initialSegment,
        animationData
    };

    useEffect(() => {
        setStartPoint(stopPoint);
        setStopPoint(stepMarkers.language_changed);
    }, [language]);

    useEffect(() => {
        // @ts-ignore
        animationRef.current.playSegments([[startPoint, stopPoint]], true);
    }, [startPoint, stopPoint]);

    return (
        <Lottie lottiRef={animationRef} options={defaultOptions} isClickToPauseDisabled />
    );
};
