import * as nodeTypes from './nodeTypes';
import createBreakNode from './createBreakNode';
import createElementNode from './createElementNode';
import Node from '../Node';

export default (type: string, args: any): Node => {
    switch (type) {
        case nodeTypes.BREAK:
            return createBreakNode(args);
        case nodeTypes.ELEMENT:
            return createElementNode(args);
        default:
            return new Node({});
    }
};
