import React from 'react';
import { Box } from '@mui/material';
import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import Scrollbar from '../../../../../wbtgen/src/view/common/Scrollbar';

import { DASHBOARD_PAGE_CONTENT_MAX_WIDTH } from './constants';
import { RenderWhen } from '../Helpers/RenderWhen';

const useStyles = makeStyles(
    (theme: Theme) => ({
        rootContainer: {
            margin: 0,
            overflowY: 'auto',
            [theme.breakpoints.down('md')]: {
                padding: "0",
                margin: "0",
            }
        },
        pageContainer: {
            maxWidth: DASHBOARD_PAGE_CONTENT_MAX_WIDTH,
            margin: "0px auto",
            padding: "24px",
            paddingTop: "0px",
            [theme.breakpoints.up("sm")]: {
                padding: "32px",
                paddingTop: "4px",
            },
            [theme.breakpoints.up("md")]: {
                padding: "48px",
                paddingBottom: "52px",
            },
        },
        fullSizePage: {
            maxWidth: "100%",
            padding: "0px",
        },
        overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }
    }),
    { name: "PageContentLayout" }
);

type PageContentLayoutProps = {
    fullSize?: boolean;
    children: any;
    disabled?: boolean;
};

export const PageContentLayout = ({ fullSize, children, disabled }: PageContentLayoutProps) => {
    const classes = useStyles();

    return (
        <Scrollbar fitHeight height="100%">
            <Box className={cx(classes.rootContainer)} style={disabled ? { filter: "grayscale(1)", opacity: "0.4" } : {}}>
                <RenderWhen when={disabled}>
                    <div className={classes.overlay} />
                </RenderWhen>
                <Box className={cx(classes.pageContainer, { [classes.fullSizePage]: fullSize })}>
                    {children}
                </Box>
            </Box>
        </Scrollbar>
    );
};
