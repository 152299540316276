export default ({ component,
    componentDependencies: { webshopMHFData, stylesheets, themeSettingsData: { autoColorMode } },
    selectedParentTheme }) => {
    return {
        ...component,
        webshopMHFData,
        stylesheets,
        selectedParentTheme: autoColorMode && selectedParentTheme
    };
};
