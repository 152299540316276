import { equals } from 'ramda';
import { optional, receiveOnly, withSelector } from '../../../../../epics/makeCondition';
import makeEpic from "../../../../../epics/makeEpic";
import { makeActionForwardToSelectedComponent } from '../../../../../redux/forwardTo';
import { COMPONENT_EDIT } from '../../../../../redux/modules/children/workspace/actionTypes';
import { closeDialogByIdAction } from '../../../../App/actionCreators/index';
import { openDialogAC } from '../../../../App/actionCreators/openDialog';
import {
    APP_LEFT_MOUSE_DOWN,
    APP_RIGHT_MOUSE_DOWN,
    KEY_DOWN,
} from '../../../../App/actionTypes';
import { PROPERTIES_PANEL_NAVIGATE_TO_PAGE } from '../../../../PropertiesPanel/epic/actionTypes';
import { WORKSPACE_NEW_COMPONENT_ADDED, PASTE_FROM_SYSTEM_CLIPBOARD } from '../../../../Workspace/actionTypes';
import {
    ReceiveOnlySelectedComponentsSelector,
    SelectedComponentSelector,
    UserInteractionModeSelector,
    EditModeComponentSelector,
    ReceiveOnlyComponentsMap,
    SelectedComponentIdSelector,
    ComponentWidthChangedFromPropertiesPanel
} from '../../../../Workspace/epics/componentsEval/selectorActionTypes';
import {
    IDLE,
    RESIZE_HANDLE_MOVING,
    SHIFTBAR_MOVING
} from '../../../../Workspace/epics/componentsEval/userInteractionMutations/interactionModes';
import { ReceiveOnlyTemplateWidthActionType } from '../../../Template/epics/template/selectorActionTypes';
import {
    SOCIAL_CHANGE_GLOBAL_SOCIAL_LINKS_CTA_CLICKED,
    SOCIAL_EDIT_DIALOG_CLOSE_PRESSED,
    SOCIAL_EDIT_DIALOG_OK_PRESSED,
    SOCIAL_EDIT_LINK_DIALOG_INPUT_CHANGED,
    SOCIAL_LINK_ADD_INPUT_CHANGE,
    SOCIAL_LINK_ADD_INPUT_ENTER_PRESSED,
    SOCIAL_LINK_CONTEXT_MENU_BTN_CLICKED,
    SOCIAL_LINK_CONTEXT_MENU_DELETE_CLICKED,
    SOCIAL_LINK_CONTEXT_MENU_EDIT_CLICKED,
    SOCIAL_LINK_CONTEXT_MENU_SHOW_OR_HIDE_CLICKED,
    SOCIAL_SET_COMPONENT_HORIZONTAL_SPACING,
    SOCIAL_SET_VISIBLE_PLATFORMS_FOR_COMPONENT,
    SOCIAL_TOGGLE_PLATFORM_VISIBILITY,
    SOCIAL_ADD_LINK_WARNING_TOOLTIP_TEXT_WIDTH,
    SOCIAL_DELETE_LINK_DIALOG_DELETE_PRESSED,
    SOCIAL_DELETE_LINK_DIALOG_CANCEL_PRESSED,
    SOCIAL_LINKS_SORT_START,
    SOCIAL_LINKS_SORT_END,
    SOCIAL_LINK_HIDDEN_ICON_CLICKED,
    SOCIAL_LINK_INPUT_FOCUSED,
    SOCIAL_LINK_INPUT_BLUR,
    SOCIAL_LINK_INPUT_BOTTOM_CENTER_POINT_AFTER_RENDER,
    SOCIAL_LINK_INPUT_PASTE,
    SOCIAL_OUTDATED_LINKS_LOCAL_STATE_FIX,
    SOCIAL_FIX_COMPONENT_LINKS_ON_PASTE,
    SOCIAL_LINK_PP_ITEM_MOUSE_OVER,
    SOCIAL_LINK_PP_ITEM_MOUSE_LEAVE,
} from '../../actionTypes';
import { SOCIAL_KIND } from '../../kind';
import { LINKS } from '../../propertiesPanel/pagesIds';
import { SocialEditLinkDialogId } from '../../SocialEditLinkDialog/socialEditLinkDialogId';
import { socialLinksConfigs, socialLinksConfigsMap, SocialPlatformTypes } from '../../socialLinksConfigs';
import {
    SOCIAL_GLOBAL_DATA_ADD_PLATFORM,
    SOCIAL_GLOBAL_DATA_DELETE_PLATFORM,
    SOCIAL_GLOBAL_DATA_SET_LINKS,
    SOCIAL_GLOBAL_DATA_SET_PLATFORM_URL,
    SOCIAL_GLOBAL_DATA_UPDATE_PLATFORM_URL
} from '../socialGlobalDataEpic/actionTypes';
import { WINDOW_RESIZED } from '../../../../../redux/modules/actionTypes';
import { makeUuidNoReplaceOnDuplicate } from '../../../../../../utils/makeUuid';
import { PropertiesPanelIsMoving, PropertiesPanelPosition } from '../../../../PropertiesPanel/epic/selectorActionTypes';
import {
    SocialDeleteLinkConfirmationDialogId
} from '../../SocialRemoveLinkConfirmationDialog/socialDeleteLinkConfirmationDialogId';
import { removeUrlProtocolAndWWW, getDefaultSocialLinks } from '../../utils';
import { KEYBOARD_KEY_CODES } from '../../../../App/epics/isKeyPressed/keyCodes';
import { socialGlobalDataEpic } from '../socialGlobalDataEpic/socialGlobalDataEpic';
import { socialServiceVat } from './socialServiceVat';
import { COMPONENTS_PASTED } from '../../../../Workspace/epics/componentsEval/actionTypes';
import type { SocialComponent } from '../../flowTypes';
import WORKSPACE_COMPONENT_KIND from '../../../../Workspace/epics/componentsEval/WORKSPACE_COMPONENT_KIND';

type LinkForPropertiesPanel = {
    id: string,
    kind: string,
    url: string,
    hidden: boolean,
}
export type SocialServiceEpicState = {
    linksForComponentInEditMode: Array<LinkForPropertiesPanel>,
    addLinkInputValue: string,
    addLinkVisibleInputValue: string,
    readonly addLinkInputSuggestedValues: Array<string>,
    addInputInlineSuggestionDisabled: boolean,
    unrecognizedLinkInput: boolean,
    unrecognizedLink: boolean,
    addLinkPlatformKind: string | null,
    editLinkDialogInputValue: string,
    editDialogPlatformId: string | null,
    editDialogPlatformKind: string | null,
    deleteDialogLinkId: null,
    deleteDialogPlatformKind: string | null,
    editUrlInputIsInvalid: boolean,
    inputWarningVisible: boolean,
    inputWarningTooltipWidth: number,
    inputWarningTooltipPosition: { left: number, top: number },
    sortingInprogress: boolean,
    forceHoverLinkId: null | string,
    ctx: {
        position: { left: number, top: number },
        linkId: string
    } | null,
}

const defaultState: SocialServiceEpicState = {
    linksForComponentInEditMode: [],
    addLinkInputValue: '',
    addLinkVisibleInputValue: '',
    addLinkInputSuggestedValues: [],
    addInputInlineSuggestionDisabled: false,
    unrecognizedLinkInput: false, // this set to true when user presses enter and input is not valid url. It is used to show warning message.
    unrecognizedLink: false, // this set to true when user input characters that can not be recognized as platform url nor autocompleted. It used for input border color.
    addLinkPlatformKind: null,
    editLinkDialogInputValue: '',
    editDialogPlatformId: null,
    editDialogPlatformKind: null,
    deleteDialogLinkId: null,
    deleteDialogPlatformKind: null,
    editUrlInputIsInvalid: false,
    inputWarningVisible: false,
    inputWarningTooltipWidth: 204, // 204 is english tooltip width on chrome on mac, using it as default
    inputWarningTooltipPosition: { left: 0, top: 0 },
    sortingInprogress: false,
    forceHoverLinkId: null,
    ctx: null,
};

const findPlatform = (str) => socialLinksConfigs.reduce(
    // @ts-ignore
    (result, { linkRegex, kind }) => (linkRegex.test(str.toLowerCase().replace('mailto:', '')) ? kind : result),
    null
);

const computeComponentIsOnTheRightSideOfTemplate = ({ selectedComponent, templateWidth }) => {
    const distanceToLeftEdge = selectedComponent.left;
    const distanceToRightEdge = templateWidth - selectedComponent.left - selectedComponent.width;

    return distanceToLeftEdge > distanceToRightEdge;
};

const computeSpacingForSocialComponent = (component) => {
    // |X_X_X_X| X - icon size
    // | Y Y Y | Y - spacing
    // |   w   | w - width
    // n - number of icons
    // 4x + 3y = w
    // nx + (n - 1)y = w
    // y = (w - nx) / (n - 1)
    const n = component.links.filter(link => !link.hidden).length;

    if (n < 2) return component.spacing;

    const sz = component.size;
    const w = component.width;

    return Math.round((w - (n * sz)) / (n - 1));
};

const CTX_MENU_HEIGHT = 86;

const computeInputWarningTooltipPosition = ({ inputBottomCenterPoint, inputWarningTooltipWidth }) => ({
    left: inputBottomCenterPoint.left - (inputWarningTooltipWidth / 2),
    top: inputBottomCenterPoint.top
});

const recomputeInputWarningTooltipPosition = (state, scope, propertiesPanelPosition) => ({
    ...state,
    inputWarningTooltipPosition: state.inputWarningVisible ? computeInputWarningTooltipPosition({
        inputBottomCenterPoint: {
        // eslint-disable-next-line max-len
            left: scope.inputBottomCenterPoint.left - (scope.propertiesPanelPositionAtTheTimeInputWarningPositionWasCaptured.x - propertiesPanelPosition.x),
            // eslint-disable-next-line max-len
            top: scope.inputBottomCenterPoint.top - (scope.propertiesPanelPositionAtTheTimeInputWarningPositionWasCaptured.y - propertiesPanelPosition.y)
        },
        inputWarningTooltipWidth: state.inputWarningTooltipWidth
    }) : defaultState.inputWarningTooltipPosition
});

const fixUrl = (linkKind, url) => {
    const urlWithoutProtocol = removeUrlProtocolAndWWW(url);

    if (linkKind === SocialPlatformTypes.EMAIL) {
        return 'mailto:' + urlWithoutProtocol;
    } else {
        return 'https://' + urlWithoutProtocol;
    }
};

const makeAdjustSocialComponentsAction = selectedComponents => selectedComponents
    .filter(({ kind }) => kind === SOCIAL_KIND)
    .map(component => makeActionForwardToSelectedComponent(({
        type: SOCIAL_SET_COMPONENT_HORIZONTAL_SPACING,
        payload: computeSpacingForSocialComponent(component),
    } as any)));

const socialGlobalDataVat = socialGlobalDataEpic.valueActionType;

const socialServiceEpic = makeEpic({
    defaultState,
    defaultScope: {
        adjustSpacingAfterUserInteractionComplete: false,
        inputBottomCenterPoint: { left: 0, top: 0 },
        propertiesPanelPositionAtTheTimeInputWarningPositionWasCaptured: { left: 0, top: 0 }
    },
    valueActionType: socialServiceVat,
    updaters: [
        {
            conditions: [
                receiveOnly(socialGlobalDataVat),
                ReceiveOnlyComponentsMap,
                SelectedComponentIdSelector
            ],
            reducer: ({
                state,
                scope,
                values: [
                    { links: globalLinks },
                    componentsMap,
                    selectedComponentId
                ],
                sourceAction
            }) => {
                if (sourceAction.type === PASTE_FROM_SYSTEM_CLIPBOARD) {
                    return { state, scope };
                }
                const selectedComponent = componentsMap[selectedComponentId];

                if (!selectedComponent || selectedComponent.kind !== SOCIAL_KIND) return { state, scope };

                const linkIdsToCleanup = selectedComponent.links.filter(
                    link => !globalLinks.find(globalLink => globalLink.id === link.id)
                ).map(link => link.id);

                const missingLinks = globalLinks.filter(
                    globalLink => !selectedComponent.links.find(localLink => localLink.id === globalLink.id)
                ).map(({ id, kind }) => ({ id, kind }));

                if (linkIdsToCleanup.length === 0 && missingLinks.length === 0) return { state, scope };

                return {
                    state,
                    scope,
                    actionToDispatch: makeActionForwardToSelectedComponent({
                        type: SOCIAL_OUTDATED_LINKS_LOCAL_STATE_FIX,
                        payload: {
                            linkIdsToCleanup,
                            missingLinks
                        }
                    })
                };
            }
        },
        {
            conditions: [
                socialGlobalDataVat,
                EditModeComponentSelector
            ],
            reducer: ({ values: [{ links: globalLinks }, editModeComponent], state, scope }) => {
                if (!editModeComponent || editModeComponent.kind !== SOCIAL_KIND) {
                    return { state, scope };
                }

                if (state.ctx) { // context menu should be closed, to avoid undo/redo issues on deleted items.
                    return { state: { ...state, ctx: defaultState.ctx }, scope };
                }

                return {
                    state: {
                        ...state,
                        linksForComponentInEditMode: editModeComponent.links.map(localLink => {
                            const globalLink = globalLinks.find(globalLink => localLink.id === globalLink.id);

                            if (globalLink) {
                                return { ...globalLink, hidden: localLink.hidden };
                            }
                            return null;
                        }).filter(x => x)
                    },
                    scope
                };
            }
        },
        {
            conditions: [
                receiveOnly(socialGlobalDataVat),
                WORKSPACE_NEW_COMPONENT_ADDED,
            ],
            reducer: ({ state, scope, values: [socialData, { component: { kind: addedComponentKind } }] }) => {
                if (addedComponentKind === SOCIAL_KIND && socialData.links.length === 0) {
                    const defaultLinks = getDefaultSocialLinks([]);
                    return ({
                        state,
                        scope,
                        multipleActionsToDispatch: [
                            // $FlowFixMe
                            {
                                type: SOCIAL_GLOBAL_DATA_SET_LINKS,
                                payload: defaultLinks
                            },
                            makeActionForwardToSelectedComponent({
                                type: SOCIAL_SET_VISIBLE_PLATFORMS_FOR_COMPONENT,
                                payload: {
                                    links: defaultLinks.map(({ id, kind }) => ({ id, kind })),
                                }
                            })
                        ]
                    });
                }
                return { state, scope };
            }
        },
        {
            conditions: [
                receiveOnly(socialGlobalDataVat),
                ReceiveOnlyComponentsMap,
                COMPONENTS_PASTED
            ],
            reducer: ({ state, scope, values: [socialGlobalData, componentsMap, { newComponentsIds }] }) => {
                const platformsToAddMap = {};
                const fixComponentDataActions = newComponentsIds.reduce((acc, componentId) => {
                    if (componentsMap[componentId].kind !== SOCIAL_KIND) return acc;

                    const component: SocialComponent = componentsMap[componentId];
                    const updatedComponentLinks = component.links.map(link => {
                        if (socialGlobalData.links.find(globalLink => globalLink.id === link.id)) {
                            return link;
                        } else {
                            const globalLinkByKind = socialGlobalData.links
                                .find(globalLink => globalLink.kind === link.kind);
                            if (globalLinkByKind) {
                                return { ...link, id: globalLinkByKind.id };
                            } else if (platformsToAddMap[link.kind]) {
                                return { ...link, id: platformsToAddMap[link.kind] };
                            } else {
                                platformsToAddMap[link.kind] = makeUuidNoReplaceOnDuplicate();
                                return { ...link, id: platformsToAddMap[link.kind] };
                            }
                        }
                    });
                    if (!equals(updatedComponentLinks, component.links)) {
                        acc.push({
                            type: SOCIAL_FIX_COMPONENT_LINKS_ON_PASTE,
                            payload: updatedComponentLinks,
                            forwardTo: {
                                kind: WORKSPACE_COMPONENT_KIND,
                                id: component.id
                            }
                        });
                    }

                    return acc;
                }, []);

                return ({
                    state,
                    scope,
                    multipleActionsToDispatch: [
                        ...fixComponentDataActions,
                        ...Object.keys(platformsToAddMap).map(linkKind => ({
                            type: SOCIAL_GLOBAL_DATA_ADD_PLATFORM,
                            payload: {
                                id: platformsToAddMap[linkKind],
                                linkKind,
                                url: ''
                            }
                        }))
                    ]
                });
            }
        },
        {
            conditions: [SOCIAL_CHANGE_GLOBAL_SOCIAL_LINKS_CTA_CLICKED],
            reducer: ({ state, scope }) => {
                return ({
                    state,
                    scope,
                    multipleActionsToDispatch: [
                        { type: COMPONENT_EDIT },
                        { type: PROPERTIES_PANEL_NAVIGATE_TO_PAGE, payload: { pageId: LINKS } }
                    ]
                });
            }
        },
        {
            conditions: [SOCIAL_LINK_INPUT_FOCUSED],
            reducer: ({ state, scope }) => {
                return ({
                    state: { ...state, addLinkInputInFocus: true },
                    scope,
                });
            }
        },
        {
            conditions: [SOCIAL_LINK_INPUT_BLUR],
            reducer: ({ state, scope }) => {
                return ({
                    state: {
                        ...state,
                        addLinkInputInFocus: false,
                    },
                    scope,
                });
            }
        },
        {
            conditions: [KEY_DOWN],
            reducer: ({ state, scope, values: [{ keyCode, preventDefault }] }) => {
                if (!state.addLinkInputInFocus) return { state, scope };

                if (keyCode === KEYBOARD_KEY_CODES.DELETE || keyCode === KEYBOARD_KEY_CODES.BACKSPACE) {
                    return { state: { ...state, addInputInlineSuggestionDisabled: true }, scope };
                }

                if (keyCode === KEYBOARD_KEY_CODES.RIGHT_ARROW || keyCode === KEYBOARD_KEY_CODES.TAB) {
                    if (keyCode === KEYBOARD_KEY_CODES.TAB) {
                        preventDefault();
                    }
                    if (state.addLinkVisibleInputValue.length > state.addLinkInputValue.length) {
                        return {
                            state: {
                                ...state,
                                addLinkInputValue: state.addLinkVisibleInputValue
                            },
                            scope
                        };
                    }
                }

                if (keyCode === KEYBOARD_KEY_CODES.ENTER) {
                    return {
                        state: {
                            ...state,
                            addLinkInputValue: state.addLinkVisibleInputValue,
                            addLinkInputSuggestedValues: defaultState.addLinkInputSuggestedValues,
                            addInputInlineSuggestionDisabled: false
                        },
                        scope,
                        actionToDispatch: { type: SOCIAL_LINK_ADD_INPUT_ENTER_PRESSED }
                    };
                }

                if (state.addInputInlineSuggestionDisabled) {
                    return { state: { ...state, addInputInlineSuggestionDisabled: false }, scope };
                }

                return { state, scope };
            }
        },
        {
            conditions: [
                receiveOnly(PropertiesPanelPosition),
                SOCIAL_LINK_INPUT_BOTTOM_CENTER_POINT_AFTER_RENDER
            ],
            reducer: ({ state, scope, values: [propertiesPanelPosition, inputBottomCenterPoint] }) => {
                return {
                    state,
                    scope: {
                        ...scope,
                        inputBottomCenterPoint,
                        propertiesPanelPositionAtTheTimeInputWarningPositionWasCaptured: propertiesPanelPosition,
                    }
                };
            }
        },
        {
            conditions: [SOCIAL_LINK_INPUT_PASTE],
            reducer: ({ state, scope, values: [pastedValue] }) => {
                return {
                    state,
                    scope,
                    actionToDispatch: ({
                        type: SOCIAL_LINK_ADD_INPUT_CHANGE,
                        payload: removeUrlProtocolAndWWW(pastedValue)
                    } as any)
                };
            }
        },
        {
            conditions: [SOCIAL_LINK_ADD_INPUT_CHANGE],
            reducer: ({
                state,
                scope,
                values: [addLinkInputValue]
            }) => {
                let linkKind = findPlatform(addLinkInputValue)
                    || findPlatform(addLinkInputValue + 'a')
                    || findPlatform(addLinkInputValue + '/a');

                const unrecognizedLinkInput = !addLinkInputValue ? false : !linkKind;

                let addLinkInputSuggestedValues: string[] = [];
                let addLinkVisibleInputValue = addLinkInputValue;
                let unrecognizedLink = unrecognizedLinkInput;

                if (addLinkInputValue.length > 0 && !state.addInputInlineSuggestionDisabled) {
                    const inputValueWithoutProtocolAndWWW = addLinkInputValue.replace('http://', '').replace('https://', '').replace('www.', '');
                    addLinkInputSuggestedValues = socialLinksConfigs
                        .map(lc => removeUrlProtocolAndWWW(lc.urlPlaceholder))
                        .filter(urlPlaceholder =>
                            urlPlaceholder.indexOf(inputValueWithoutProtocolAndWWW.toLowerCase()) === 0);

                    if (addLinkInputSuggestedValues.length > 0) {
                        const visibleInputValue = addLinkInputValue
                            + addLinkInputSuggestedValues[0].substring(inputValueWithoutProtocolAndWWW.length);
                        linkKind = findPlatform(visibleInputValue + 'a');
                        if (visibleInputValue.length > addLinkInputValue.length) {
                            addLinkVisibleInputValue = visibleInputValue;
                            unrecognizedLink = false;
                        }
                    }
                }

                if (!linkKind && addLinkInputValue.includes('@')) {
                    // @ts-ignore
                    linkKind = SocialPlatformTypes.EMAIL;
                }
                return ({
                    state: {
                        ...state,
                        addLinkPlatformKind: linkKind,
                        addLinkInputValue,
                        addLinkVisibleInputValue,
                        addLinkInputSuggestedValues,
                        unrecognizedLinkInput,
                        unrecognizedLink,
                        inputWarningVisible: false,
                        inputWarningTooltipPosition: defaultState.inputWarningTooltipPosition
                    },
                    scope
                });
            }
        },
        {
            conditions: [receiveOnly(PropertiesPanelPosition), PropertiesPanelIsMoving],
            reducer: ({ state, scope, values: [propertiesPanelPosition, propertiesPanelIsMoving] }) => {
                if (propertiesPanelIsMoving) {
                    return ({
                        state: {
                            ...state,
                            inputWarningVisible: false,
                            inputWarningTooltipPosition: defaultState.inputWarningTooltipPosition
                        },
                        scope,
                    });
                } else if (state.inputWarningVisible !== state.unrecognizedLinkInput) {
                    return {
                        state: recomputeInputWarningTooltipPosition(
                            {
                                ...state,
                                inputWarningVisible: state.unrecognizedLinkInput,
                            },
                            scope,
                            propertiesPanelPosition
                        ),
                        scope
                    };
                }

                return { state, scope };
            }
        },
        {
            conditions: [
                receiveOnly(PropertiesPanelPosition),
                receiveOnly(SelectedComponentSelector),
                ReceiveOnlyTemplateWidthActionType,
                receiveOnly(socialGlobalDataVat),
                SOCIAL_LINK_ADD_INPUT_ENTER_PRESSED
            ],
            reducer: ({
                state,
                scope,
                values: [propertiesPanelPosition, selectedComponent, templateWidth, socialData]
            }) => {
                if (!state.addLinkInputValue) return { state, scope };
                const linkKind = findPlatform(state.addLinkInputValue);

                if (!linkKind) {
                    return {
                        state: recomputeInputWarningTooltipPosition(
                            {
                                ...state,
                                unrecognizedLinkInput: true,
                                unrecognizedLink: true,
                                inputWarningVisible: true,
                            },
                            scope,
                            propertiesPanelPosition
                        ),
                        scope
                    };
                }

                const url = fixUrl(linkKind, state.addLinkInputValue);

                const updatedState = {
                    ...state,
                    addLinkInputValue: defaultState.addLinkInputValue,
                    addLinkVisibleInputValue: defaultState.addLinkVisibleInputValue,
                    unrecognizedLinkInput: defaultState.unrecognizedLinkInput,
                    unrecognizedLink: defaultState.unrecognizedLink,
                    addLinkPlatformKind: defaultState.addLinkPlatformKind,
                };

                const existingSocialLinkOfKind = socialData.links.find(link => link.kind === linkKind);
                if (existingSocialLinkOfKind) {
                    const { id: existingSocialLinkOfKindId } = existingSocialLinkOfKind;
                    const platformForSelectedComponentIsHidden =
                        !selectedComponent.links.find(link => link.kind === linkKind);

                    return ({
                        state: updatedState,
                        scope,
                        multipleActionsToDispatch: [
                            {
                                type: SOCIAL_GLOBAL_DATA_UPDATE_PLATFORM_URL,
                                payload: {
                                    id: existingSocialLinkOfKindId,
                                    url
                                }
                            },
                            platformForSelectedComponentIsHidden ? makeActionForwardToSelectedComponent({
                                type: SOCIAL_TOGGLE_PLATFORM_VISIBILITY,
                                payload: {
                                    linkId: existingSocialLinkOfKindId,
                                    linkKind,
                                    componentIsOnTheRightSideOfTemplate: computeComponentIsOnTheRightSideOfTemplate({
                                        selectedComponent,
                                        templateWidth
                                    })
                                },
                            }) : null
                        ]
                    });
                }

                const linkId = makeUuidNoReplaceOnDuplicate();
                return ({
                    state: updatedState,
                    scope,
                    multipleActionsToDispatch: [{
                        type: SOCIAL_GLOBAL_DATA_ADD_PLATFORM,
                        payload: {
                            id: linkId,
                            linkKind,
                            url
                        }
                    },
                    makeActionForwardToSelectedComponent({
                        type: SOCIAL_TOGGLE_PLATFORM_VISIBILITY,
                        payload: {
                            linkId,
                            linkKind,
                            componentIsOnTheRightSideOfTemplate: computeComponentIsOnTheRightSideOfTemplate({
                                selectedComponent,
                                templateWidth
                            })
                        }
                    })
                    ]
                });
            }
        },
        {
            conditions: [
                receiveOnly(socialGlobalDataVat),
                SOCIAL_LINK_CONTEXT_MENU_DELETE_CLICKED
            ],
            reducer: ({ state, scope, values: [socialData] }) => ({
                state: {
                    ...state,
                    deleteDialogLinkId: state.ctx.linkId,
                    deleteDialogPlatformKind: socialData.links.find(link => link.id === state.ctx.linkId).kind,
                    ctx: defaultState.ctx
                },
                scope,
                actionToDispatch: openDialogAC(SocialDeleteLinkConfirmationDialogId)
            })
        },
        {
            conditions: [
                receiveOnly(SelectedComponentSelector),
                ReceiveOnlyTemplateWidthActionType,
                SOCIAL_DELETE_LINK_DIALOG_DELETE_PRESSED
            ],
            reducer: ({ state, scope, values: [selectedComponent, templateWidth] }) => {
                return ({
                    state: {
                        ...state,
                        inputWarningVisible: false,
                        inputWarningTooltipPosition: defaultState.inputWarningTooltipPosition,
                        deleteDialogLinkId: null,
                        deleteDialogPlatformKind: null
                    },
                    scope,
                    multipleActionsToDispatch: [
                        closeDialogByIdAction(SocialDeleteLinkConfirmationDialogId),
                        {
                            type: SOCIAL_GLOBAL_DATA_DELETE_PLATFORM,
                            payload: { linkId: state.deleteDialogLinkId }
                        },
                        selectedComponent.links.find(({ id }) => id === state.deleteDialogLinkId) ?
                            makeActionForwardToSelectedComponent({
                                type: SOCIAL_TOGGLE_PLATFORM_VISIBILITY,
                                linkKind: state.deleteDialogPlatformKind,
                                payload: {
                                    linkId: state.deleteDialogLinkId,
                                    componentIsOnTheRightSideOfTemplate: computeComponentIsOnTheRightSideOfTemplate({
                                        selectedComponent,
                                        templateWidth
                                    })
                                }
                            }) : null
                    ]
                });
            }
        },
        {
            conditions: [SOCIAL_DELETE_LINK_DIALOG_CANCEL_PRESSED],
            reducer: ({ state, scope }) => {
                return ({
                    state: {
                        ...state,
                        deleteDialogLinkId: null,
                        deleteDialogPlatformKind: null
                    },
                    scope,
                    actionToDispatch: closeDialogByIdAction(SocialDeleteLinkConfirmationDialogId),
                });
            }
        },
        {
            conditions: [
                receiveOnly(WINDOW_RESIZED),
                SOCIAL_LINK_CONTEXT_MENU_BTN_CLICKED
            ],
            reducer: ({ state, scope, values: [{ height: windowHeight }, { clientX, clientY, linkId }] }) => {
                const ctxBottom = clientY + CTX_MENU_HEIGHT;
                return ({
                    state: {
                        ...state,
                        ctx: {
                            position: {
                                left: clientX - 124,
                                top: ctxBottom < windowHeight ? clientY : clientY - CTX_MENU_HEIGHT
                            },
                            linkId
                        }
                    },
                    scope,
                });
            }
        },
        {
            conditions: [
                optional(APP_LEFT_MOUSE_DOWN),
                optional(APP_RIGHT_MOUSE_DOWN)
            ],
            reducer: ({ state, scope }) => {
                if (!state.ctx) return { state, scope };

                return ({
                    state: { ...state, ctx: defaultState.ctx },
                    scope,
                });
            }
        },
        {
            conditions: [
                receiveOnly(socialGlobalDataVat),
                SOCIAL_LINK_CONTEXT_MENU_EDIT_CLICKED
            ],
            reducer: ({ state, scope, values: [socialData] }) => {
                const { url, kind } = socialData.links.find(link => link.id === state.ctx.linkId);
                const editLinkDialogInputValue = (url || socialLinksConfigsMap[kind].urlPlaceholder)
                    .replace('mailto:', '');

                return ({
                    state: {
                        ...state,
                        ctx: defaultState.ctx,
                        editDialogPlatformId: state.ctx.linkId,
                        editDialogPlatformKind: kind,
                        editLinkDialogInputValue,
                        editUrlInputIsInvalid: !socialLinksConfigsMap[kind]
                            .linkRegex.test(editLinkDialogInputValue)
                    },
                    scope,
                    actionToDispatch: openDialogAC(SocialEditLinkDialogId)
                });
            }
        },
        {
            conditions: [
                receiveOnly(PropertiesPanelPosition),
                SOCIAL_ADD_LINK_WARNING_TOOLTIP_TEXT_WIDTH
            ],
            reducer: ({
                state,
                scope,
                values: [propertiesPanelPosition, inputWarningTooltipWidth]
            }) => (
                {
                    state: recomputeInputWarningTooltipPosition(
                        { ...state, inputWarningTooltipWidth },
                        scope,
                        propertiesPanelPosition
                    ),
                    scope
                }
            )
        },
        {
            conditions: [SOCIAL_EDIT_LINK_DIALOG_INPUT_CHANGED],
            reducer: ({ state, scope, values: [editLinkDialogInputValue] }) => {
                return ({
                    state: {
                        ...state,
                        editLinkDialogInputValue,
                        editUrlInputIsInvalid: !socialLinksConfigsMap[state.editDialogPlatformKind]
                            .linkRegex.test(editLinkDialogInputValue.toLowerCase().replace('mailto:', ''))
                    },
                    scope,
                });
            }
        },
        {
            conditions: [SOCIAL_EDIT_DIALOG_OK_PRESSED],
            reducer: ({ state, scope }) => {
                const url = fixUrl(state.editDialogPlatformKind, state.editLinkDialogInputValue);

                return ({
                    state: {
                        ...state,
                        editLinkDialogInputValue: defaultState.editLinkDialogInputValue,
                        editDialogPlatformId: defaultState.editDialogPlatformId,
                        editDialogPlatformKind: defaultState.editDialogPlatformKind
                    },
                    scope,
                    multipleActionsToDispatch: [
                        {
                            type: SOCIAL_GLOBAL_DATA_SET_PLATFORM_URL,
                            payload: {
                                id: state.editDialogPlatformId,
                                url
                            }
                        },
                        closeDialogByIdAction(SocialEditLinkDialogId)
                    ]
                });
            }
        },
        {
            conditions: [SOCIAL_EDIT_DIALOG_CLOSE_PRESSED],
            reducer: ({ state, scope }) => {
                return ({
                    scope,
                    state: {
                        ...state,
                        editDialogPlatformId: defaultState.editDialogPlatformId,
                        editDialogPlatformKind: defaultState.editDialogPlatformKind
                    },
                    actionToDispatch: closeDialogByIdAction(SocialEditLinkDialogId)
                });
            }
        },
        {
            conditions: [
                ReceiveOnlySelectedComponentsSelector,
                UserInteractionModeSelector
            ],
            reducer: ({ state, scope, values: [selectedComponents, userInteractonMode] }) => {
                const socialComponentInSelection = selectedComponents.some(s => s.kind === SOCIAL_KIND);
                if (!socialComponentInSelection) return { state, scope };

                if (userInteractonMode !== IDLE) {
                    if (userInteractonMode === SHIFTBAR_MOVING || userInteractonMode === RESIZE_HANDLE_MOVING) {
                        return {
                            state: { ...state },
                            scope: { ...scope, adjustSpacingAfterUserInteractionComplete: true }
                        };
                    }
                    return { state, scope };
                }

                if (scope.adjustSpacingAfterUserInteractionComplete) {
                    return ({
                        state,
                        scope: { ...scope, adjustSpacingAfterUserInteractionComplete: false },
                        multipleActionsToDispatch: makeAdjustSocialComponentsAction(selectedComponents)
                    });
                }

                return { state, scope };
            }
        },
        {
            conditions: [
                ReceiveOnlySelectedComponentsSelector,
                ComponentWidthChangedFromPropertiesPanel
            ],
            reducer: ({ state, scope, values: [selectedComponents] }) => {
                const socialComponentInSelection = selectedComponents.some(s => s.kind === SOCIAL_KIND);
                if (!socialComponentInSelection) return { state, scope };

                return ({
                    state,
                    scope,
                    multipleActionsToDispatch: makeAdjustSocialComponentsAction(selectedComponents)
                });
            }
        },
        {
            conditions: [SOCIAL_LINK_PP_ITEM_MOUSE_OVER],
            reducer: ({ state, scope, values: [id] }) => ({
                state: state.sortingInprogress ? state : {
                    ...state,
                    forceHoverLinkId: id
                },
                scope
            })
        },
        {
            conditions: [SOCIAL_LINK_PP_ITEM_MOUSE_LEAVE],
            reducer: ({ state, scope }) => ({
                state: state.sortingInprogress ? state : {
                    ...state,
                    forceHoverLinkId: null
                },
                scope
            })
        },
        {
            conditions: [SOCIAL_LINKS_SORT_START],
            reducer: ({ state, scope, values: [{ index }] }) => ({
                state: {
                    ...state,
                    sortingInprogress: true,
                    forceHoverLinkId: state.linksForComponentInEditMode[index].id
                },
                scope
            })
        },
        {
            conditions: [SOCIAL_LINKS_SORT_END],
            reducer: ({ state, scope }) => ({
                state: { ...state, sortingInprogress: false },
                scope
            })
        },
        {
            conditions: [
                receiveOnly(socialGlobalDataVat),
                receiveOnly(SelectedComponentSelector),
                ReceiveOnlyTemplateWidthActionType,
                optional(SOCIAL_LINK_HIDDEN_ICON_CLICKED),
                optional(SOCIAL_LINK_CONTEXT_MENU_SHOW_OR_HIDE_CLICKED),
            ],
            reducer: ({
                state,
                scope,
                values: [socialData, selectedComponent, templateWidth, hiddenIconClickedLinkId],
                conditionActionType
            }) => {
                const linkId = conditionActionType.toString() === SOCIAL_LINK_HIDDEN_ICON_CLICKED ?
                    hiddenIconClickedLinkId : state.ctx.linkId;
                return ({
                    state: {
                        ...state,
                        ctx: defaultState.ctx
                    },
                    scope,
                    actionToDispatch: makeActionForwardToSelectedComponent({
                        type: SOCIAL_TOGGLE_PLATFORM_VISIBILITY,
                        payload: {
                            linkId,
                            linkKind: socialData.links.find(link => link.id === linkId).kind,
                            componentIsOnTheRightSideOfTemplate: computeComponentIsOnTheRightSideOfTemplate({
                                selectedComponent,
                                templateWidth
                            })
                        }
                    })
                });
            }
        },
    ]
});

const SocialIconsSortingInProgressSelector =
    withSelector(socialServiceEpic.valueActionType, ({ sortingInprogress }) => sortingInprogress);

export { socialServiceEpic, SocialIconsSortingInProgressSelector };

