
import type { TemplatePagesDialogCalcRenderPropsParams } from "../flowTypes";

export default ({
    dependencies: {
        site,
        subscriptionData,
        currentPageId,
        pages,
    },
}: TemplatePagesDialogCalcRenderPropsParams) => ({
    site,
    subscriptionData,
    selectedId: site.getPageRefByPageId(currentPageId).id,
    // $FlowFixMe
    templatePageRefIds: pages.map(p => {
        const page = site.getOptionalPageRefByPageId(p.id);
        return page ? page.id : null;
    })
});
