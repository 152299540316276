/* global $ */

import * as R from 'ramda';
import cx from 'classnames';
import { Dispatch } from "redux";
import { connect } from "react-redux";
import React, { Component } from "react";
import { memoMaxOne } from '../../../utils/memo';
import * as mouseUtils from "../../utils/mouse";
import LoadingIndicator from '../common/LoadingIndicator/index';
import ShadowRenderer from "../../components/ShadowRenderer/view/index";
import InlineStylesheet from "../../components/RenderGlobalstyles/view";
import * as actionCreators from '../../components/Workspace/actionCreators/index';
import StylesShadowRenderer from "../../components/StylesShadowRenderer/view/index";
import * as StatusTypes from '../../components/Workspace/epics/status/types';
import { isStretchComponentKind } from '../../components/oneweb/isStretchComponentKind';
import TemplateArea from "./TemplateArea/index";
import ComponentsRenderer from './TemplateArea/ComponentsRenderer';
import type { AppState, Position } from "../../redux/modules/flowTypes";
import appStyles from '../App.css';
import wsStyles from './Workspace.css';
import topBarStyles from '../../components/TopBar/view/TopBar.css';
import ErrorBoundary from "../../redux/recoverAfterException/ErrorBoundary/ErrorBoundary";
import type { ScrollEpicState } from "../../components/Workspace/epics/scroll/flowTypes";
import type { Components, ComponentsDependencies, Dimensions, Handle,
    Handles, UserInteraction,
    SectionInsertion, BBox } from "../../redux/modules/children/workspace/flowTypes";
import type { StylesShadowRendererState } from "../../components/StylesShadowRenderer/epics/index";
import getPanelWidth from "../../components/Panel/getPanelWidth";
import { getTemplateBackgroundCssStyle } from "../../components/oneweb/Template/getTemplateBackgroundStyle";
import getStyleIntValue from "../../utils/getStyleIntValue";
import type {
    ComponentsSelectionDecorationState
} from "../../components/Workspace/epics/componentsSelectionDecoration/flowTypes";
import type {
    ComponentHoverDecorationState
} from "../../components/Workspace/epics/componentHoverDecorations/flowTypes";
import type {
    ComponentAttachDecorationState
} from "../../components/Workspace/epics/componentAttachDecorations/flowTypes";
import type {
    SelectionFrameDecorationState
} from "../../components/Workspace/epics/selectionFrameDecorationEval/flowTypes";
import type { RedDecorationState } from "../../components/Workspace/epics/redDecorations/flowTypes";
import type { ComponentMainActionsEpicState } from "../../components/Workspace/epics/componentMainActions/flowTypes";
import type { ComponentPanelActionsEpicState } from "../../components/Workspace/epics/componentPanelActions/flowTypes";
import type { ShiftBarDecorationsState } from "../../components/Workspace/epics/shiftBarDecos/index";
import type { ResizeDecorationsState } from "../../components/Workspace/epics/resizeDecos/index";
import type { SnappingDecorations } from "../../components/Workspace/epics/snappingDecos/index";
import type { DndAddComponentState } from "../../components/DndAddComponent/flowTypes";
import {
    codeComponentHeadSectionSizeFromAppStateSelection
} from "../../components/Workspace/CodeComponentsRenderer/epic/selectorActionTypes";
import { BLOCK_NATIVE_CONTEXT_MENU_CLASS } from "../../constants";
import { isTemplateBackgroundFixed } from "../../components/oneweb/Template/utils";
import { UnwrapTip } from '../../components/UnwrapTip/UnwrapTip';
import { WORKSPACE_DID_MOUNT } from '../../components/Workspace/actionTypes';
import { FileUploader } from "../../components/FileUploader/index";
import { ADD_COMPONENT_FILE_DND_INTO_WORKSPACE } from "../../components/DndFilesIntoWorkspace/epic/actions";
import {
    colorThemeDataEpicStateFromAppStateSelector
} from "../../components/SiteSettings/ColorThemeData/selectorActionTypes";
import type { ThemeColorDataType, ThemeSiteSettingsDataType } from "../../components/ThemeGlobalData/flowTypes";
import { getThemeColorsFromStylesheet } from "../../components/Workspace/epics/stylesheets/selectors";
import { SharedBgImgView } from '../../components/oneweb/Section/SharedBgImgView/SharedBgImgView';
import type { ShareHeaderAndFirstSectionBgImgEpicState } from '../../components/oneweb/Section/epics/shareHeaderAndFirstSectionBgImgEpic';
import AUTO_COLOR_LEFT_PANEL_EPIC_VALUE from "../../components/AutoColorLeftPanel/epic/valueActionType";
import { makeEpicStateSelector } from "../../epics/makeEpic";
import openDialogAC from '../../components/App/actionCreators/openDialog';
import colorChangesDialogId from '../../components/AutoColorLeftPanel/colorChangesDialog/dialogId';
import { HIDE_AUTO_COLOR_LEFT_PANEL } from '../../components/AutoColorLeftPanel/actionTypes';
import type { EpicState as AutoColorLeftPanel } from '../../components/AutoColorLeftPanel/flowTypes';
import type { Attachments } from "../../components/Workspace/epics/componentAttachements/flowTypes";
import MODERN_LAYOUT_LEFT_PANEL_EPIC_VALUE from "../../components/ModernLayouts/epics/leftPanel/valueActionType";
import MODERN_LAYOUT_ONBOARDING_PANEL_EPIC_VALUE from "../../components/ModernLayouts/epics/onBoardingPanel/valueActionType";
import ComponentsEvalValueActionType from "../../components/Workspace/epics/componentsEval/valueActionType";
import { HIDE_MODERN_LAYOUT_LEFT_PANEL } from '../../components/ModernLayouts/actionTypes';
import type {
    LeftPanelEpicState as ModernLayoutLeftPanel, OnBoardingPanelEpicState as ModernLayoutOnBoardingPanel
} from '../../components/ModernLayouts/flowTypes';
import languagesVAT from "../../components/TopBar/epics/languages/valueActionType";
import type { TopBarLanguagesState } from "../../components/TopBar/epics/languages/index";
import { isModernLayoutActivatedVAT } from '../../components/Workspace/epics/isModernLayoutActivatedEpic/valueActionType';
import { getAllCmpIdsMapInLayout, isModernLayoutSection } from "../../components/ModernLayouts/preview_utils";
import { ModernSectionsRenderer } from "./TemplateArea/SectionsRenderer/ModernSectionsRenderer";
import WorkspaceBBoxValueActionType from '../../components/Workspace/epics/workspaceBBox/valueActionType';
import { WEBSHOP_PAYMENT_METHODS, WEBSHOP_POLICIES } from '../../components/oneweb/componentKinds';
import Banner from '../../components/Banner/Banner';
import BANNER_EPIC_VAT from '../../components/Banner/epic/valueActionType';
import type { BannerEpicState } from '../../components/Banner/epic/index';

const cmpsGetCmpsMap = (components) => components.reduce((acc, cmp) => {
    acc[cmp.id] = cmp;
    return acc;
}, {});

const getCmpsData = (components, componentsMapExtension) => ({
    componentsMap: cmpsGetCmpsMap(components),
    componentsMapExtension
});

const getAllCmps = memoMaxOne(({ componentsMap, componentsMapExtension }) => {
    let stretchComponents: any = [],
        normalComponents: any = [],
        mhfCmpsIdsMapPerSection = {},
        modernCmpsMap: Record<string, any> = {},
        modernSections: any = [],
        webshopFooterCmpsMap = {};
    Object.keys(componentsMap).forEach(id => {
        const cmp = componentsMap[id];
        if (isStretchComponentKind(cmp.kind, cmp.stretch)) {
            if (isModernLayoutSection(cmp)) {
                modernSections.push(cmp);
            } else {
                stretchComponents.push(cmp);
            }
        } else {
            normalComponents.push(cmp);
        }
    });
    if (modernSections.length) {
        modernSections.forEach(section => {
            const { layout } = section.modernLayout;
            mhfCmpsIdsMapPerSection = {
                ...mhfCmpsIdsMapPerSection,
                [section.id]: getAllCmpIdsMapInLayout(layout, section.id)
            };
        });
        normalComponents = normalComponents.filter(cmp => {
            const isModernCmp = modernSections.some(section => mhfCmpsIdsMapPerSection[section.id][cmp.id]);
            if (isModernCmp) {
                modernCmpsMap[cmp.id] = cmp;
            }
            return !isModernCmp;
        });
        stretchComponents = stretchComponents.filter(cmp => {
            const isModernCmp = modernSections.some(section => mhfCmpsIdsMapPerSection[section.id][cmp.id]);
            if (isModernCmp) {
                modernCmpsMap[cmp.id] = cmp;
            }
            return !isModernCmp;
        });
    } else {
        let webshopStripId;
        normalComponents.forEach(cmp => {
            if (!webshopStripId && (cmp.kind === WEBSHOP_POLICIES ||
                cmp.kind === WEBSHOP_PAYMENT_METHODS)) {
                webshopStripId = webshopStripId || (cmp.relIn && cmp.relIn.id);
            }
        });
        if (webshopStripId) {
            normalComponents = normalComponents.filter(cmp => {
                if (cmp.kind === WEBSHOP_POLICIES || cmp.kind === WEBSHOP_PAYMENT_METHODS) {
                    webshopFooterCmpsMap[cmp.id] = cmp;
                    return false;
                }
                return true;
            });
            stretchComponents = stretchComponents.filter(cmp => {
                if (cmp.id === webshopStripId) {
                    webshopFooterCmpsMap[cmp.id] = cmp;
                    return false;
                }
                return true;
            });
        }
    }
    return {
        stretchComponents: getCmpsData(stretchComponents, componentsMapExtension),
        normalComponents: getCmpsData(normalComponents, componentsMapExtension),
        modernCmpsMap,
        modernSections,
        webshopFooterCmpsMap,
        componentsMapExtension
    };
});

const
    TOP_BAR_HEIGHT = getStyleIntValue(topBarStyles, 'topBarHeight'),
    BOTTOM_DRAG_STRIP_HEIGHT = 120;

type WorkspaceProps = {
    dispatch: Dispatch;
    scroll: ScrollEpicState,
    status: string,
    componentsDependencies: ComponentsDependencies,
    components: Components,
    componentsProps: ObjectMap,
    renderGlobalstyles: Record<string, any>,
    stylesheetsIdToNameMap: MapT<string>,
    shadowRenderer: Record<string, any>,
    stylesShadowRenderer: StylesShadowRendererState,
    templateWidth: number,
    templateStyle: Record<string, any>,
    templateRenderOffset: Position,
    contentDimensions: Dimensions,
    viewportDimensions: Dimensions,
    isMouseOverWorkspace: boolean,
    isPanelExpanded: boolean,
    inserter: boolean,
    componentsSelectionDecoration: ComponentsSelectionDecorationState,
    componentHoverDecorations: ComponentHoverDecorationState,
    componentAttachDecorations: ComponentAttachDecorationState,
    selectionFrameDecoration: SelectionFrameDecorationState,
    redDecorations: RedDecorationState,
    handles: Handles,
    attachments: Attachments,
    userInteraction: UserInteraction,
    sectionInsertion: SectionInsertion,
    componentMainActions: ComponentMainActionsEpicState,
    componentPanelActions: ComponentPanelActionsEpicState,
    shiftBarDecorations: ShiftBarDecorationsState,
    resizeDecos: ResizeDecorationsState,
    editModeComponentId: string | null,
    snappingDecos: SnappingDecorations,
    selectedComponentsIds: Array<string>,
    topMostHandle: Handle,
    isMouseOverMCTA: boolean,
    dndAddComponent: DndAddComponentState,
    codeComponentsHeadSectionHeight: number,
    themeSettingsData: ThemeSiteSettingsDataType,
    themeColorsData: ThemeColorDataType,
    topMostPageSectionId: string,
    headerSectionId: string,
    shareHeaderAndFirstSectionBgImg: ShareHeaderAndFirstSectionBgImgEpicState,
    isModernLayoutActivated: boolean,
    autoColorLeftPanel: AutoColorLeftPanel,
    modernLayoutLeftPanel: ModernLayoutLeftPanel,
    modernLayoutOnBoardingPanel: ModernLayoutOnBoardingPanel,
    subscriptionType: string,
    mhfCmpsData: Record<string, any>,
    workspaceBBox: BBox,
    languages: TopBarLanguagesState,
    bannerData: BannerEpicState
};

type State = {
    visible: boolean
};

export class WorkspaceComponent extends Component<WorkspaceProps, State> {
    onScroll: React.UIEventHandler<HTMLDivElement>;
    scrollBarRef: any;
    templateRef: any;
    shouldUpdateYScroll!: boolean;
    shouldAnimateYScroll!: boolean;
    shouldUpdateXScroll!: boolean;
    tryingSyncHorizontalScroll!: boolean;

    constructor(props: WorkspaceProps) {
        super(props);
        if (props.scroll.x !== 0) {
            this.shouldUpdateXScroll = true;
        }

        this.state = { visible: false };

        this.onScroll = ({ target }) => {
            if (target === this.scrollBarRef) {
                // @ts-ignore
                this.props.dispatch(actionCreators.scrollAC({ x: target.scrollLeft, y: target.scrollTop }));
            }
        };
    }

    getTemplateBackgroundStyle(): null | undefined | Record<string, any> {
        const
            { templateStyle, isPanelExpanded, themeSettingsData: { autoColorMode }, themeColorsData } = this.props,
            panelWidth = getPanelWidth(isPanelExpanded);

        const templateStyleDependentParams = (() => {
            if (templateStyle) {
                const { background, selectedTheme, selectedGradientTheme } = templateStyle;
                return {
                    bgStyle: background,
                    selectedTheme,
                    selectedGradientTheme
                };
            } else {
                return {
                    bgStyle: null,
                    selectedTheme: null,
                    selectedGradientTheme: null
                };
            }
        })();

        return getTemplateBackgroundCssStyle({
            ...templateStyleDependentParams,
            autoColorMode,
            panelWidth,
            topBarHeight: TOP_BAR_HEIGHT,
            bottomDragStripHeight: BOTTOM_DRAG_STRIP_HEIGHT,
            themeColorsData
        });
    }

    isBackgroundFixed(): boolean {
        const { templateStyle } = this.props;
        return isTemplateBackgroundFixed(templateStyle && templateStyle.background);
    }

    renderBanner() {
        const { dispatch, bannerData } = this.props;
        return bannerData.visible ? (
            <Banner {...bannerData} dispatch={dispatch} />
        ) : null;
    }

    renderWorkspaceBody() {
        const
            {
                dispatch,
                status,
                componentsDependencies,
                componentsProps,
                renderGlobalstyles,
                stylesheetsIdToNameMap,
                shadowRenderer,
                stylesShadowRenderer,
                templateWidth,
                templateStyle,
                templateRenderOffset,
                contentDimensions,
                viewportDimensions,
                isMouseOverWorkspace,
                scroll,
                mhfCmpsData,
                componentsSelectionDecoration,
                componentHoverDecorations,
                componentAttachDecorations,
                selectionFrameDecoration,
                redDecorations,
                handles,
                components,
                userInteraction,
                sectionInsertion,
                componentMainActions,
                componentPanelActions,
                shiftBarDecorations,
                resizeDecos,
                editModeComponentId,
                snappingDecos,
                selectedComponentsIds,
                topMostHandle,
                isMouseOverMCTA,
                dndAddComponent,
                codeComponentsHeadSectionHeight,
                themeSettingsData,
                themeColorsData,
                topMostPageSectionId,
                headerSectionId,
                shareHeaderAndFirstSectionBgImg,
                isModernLayoutActivated,
                autoColorLeftPanel,
                modernLayoutLeftPanel,
                modernLayoutOnBoardingPanel,
                attachments,
                workspaceBBox,
                subscriptionType
            } = this.props,
            showAutoColorLeftPanel = autoColorLeftPanel && autoColorLeftPanel.show,
            showModernLayoutLeftPanel = modernLayoutLeftPanel && modernLayoutLeftPanel.show,
            showModernLayoutOnBoardingPanel = modernLayoutOnBoardingPanel && modernLayoutOnBoardingPanel.show,
            autoColorMode = themeSettingsData && themeSettingsData.autoColorMode,
            decorationsProps = {
                codeComponentsHeadSectionHeight,
                componentsSelectionDecoration,
                componentHoverDecorations,
                componentAttachDecorations,
                selectionFrameDecoration,
                redDecorations,
                handles,
                userInteraction,
                componentMainActions,
                componentPanelActions,
                shiftBarDecorations,
                resizeDecos,
                editModeComponentId,
                snappingDecos,
                selectedComponentsIds,
                topMostHandle,
                isMouseOverMCTA,
                dndAddComponent,
                scroll,
                showAutoColorLeftPanel,
                showModernLayoutLeftPanel,
                showModernLayoutOnBoardingPanel,
                dispatch
            },
            {
                stretchComponents,
                normalComponents,
                modernCmpsMap,
                modernSections,
                componentsMapExtension,
                webshopFooterCmpsMap
            } = getAllCmps(components),
            isWebShopFooterStripCmpsExists = webshopFooterCmpsMap && !R.isEmpty(webshopFooterCmpsMap);

        switch (status) {
            case StatusTypes.UNINITIALIZED:
            case StatusTypes.LOADING:
            case StatusTypes.FONTS_LOADING_IN_PROGRESS:
            case StatusTypes.PREPARING:
                return (
                    <div className={wsStyles.loadingIcn}>
                        <LoadingIndicator />
                    </div>
                );
            case StatusTypes.FONTS_LOADING_FAILED:
            case StatusTypes.READY: {
                const
                    wsTransform: Record<string, any> = {},
                    fileUploaderStyle: Record<string, any> = {};

                if (showAutoColorLeftPanel) {
                    const leftPanelOverlapWidth = 80;
                    const scalePoint = (viewportDimensions.width - leftPanelOverlapWidth) / viewportDimensions.width;

                    wsTransform.transform = `scale(${scalePoint})`;
                    fileUploaderStyle.height = `${(scalePoint * contentDimensions.height)}px`;
                }

                const
                    style: React.CSSProperties = {
                        ...((!this.isBackgroundFixed() && this.getTemplateBackgroundStyle()) || {}),
                        visibility: this.state.visible ? 'visible' : 'hidden',
                        width: Math.max(viewportDimensions.width, contentDimensions.width),
                        ...wsTransform
                    },
                    templateRef = (el) => {
                        this.templateRef = el;
                        return this.templateRef;
                    },
                    inlineStyleSheetProps = {
                        ...renderGlobalstyles,
                        themeSettingsData,
                        themeColorsData
                    };

                return (
                    <div
                        style={style}
                        className={cx(wsStyles.body,
                            { [wsStyles.transformWorkspace]: showAutoColorLeftPanel || showModernLayoutLeftPanel
                                || showModernLayoutOnBoardingPanel })}
                        onMouseLeave={() => dispatch(actionCreators.onMouseLeave())}
                        onMouseOver={() => {
                            // we use mouseOver due mouse enter is not happens for all cases
                            if ((!showAutoColorLeftPanel || !showModernLayoutLeftPanel || !showModernLayoutOnBoardingPanel)
                                && !isMouseOverWorkspace) {
                                dispatch(actionCreators.onMouseEnterAC());
                            }
                        }}
                        onMouseUp={() => dispatch(actionCreators.onMouseUp())}
                        onClick={() => {
                            if (showModernLayoutLeftPanel) {
                                dispatch({ type: HIDE_MODERN_LAYOUT_LEFT_PANEL });
                            } else if (showAutoColorLeftPanel) {
                                if (!autoColorMode) {
                                    dispatch({ type: HIDE_AUTO_COLOR_LEFT_PANEL });
                                } else {
                                    const
                                        { accentColor, mainColor, whiteColor, blackColor } = themeColorsData,
                                        palette = [accentColor, mainColor, whiteColor, blackColor],
                                        initialPalette = autoColorLeftPanel.initialPalette;

                                    if (!initialPalette || R.equals(initialPalette, palette)) {
                                        dispatch({ type: HIDE_AUTO_COLOR_LEFT_PANEL });
                                    } else {
                                        dispatch(
                                            openDialogAC(
                                                colorChangesDialogId,
                                                { initialPalette, undoStackIndex: autoColorLeftPanel.undoStackIndex },
                                            )
                                        );
                                    }
                                }
                            }
                        }}
                    >
                        {/* @ts-ignore this can be a bug */}
                        <ErrorBoundary invisible> <InlineStylesheet {...inlineStyleSheetProps} /> </ErrorBoundary>
                        <ErrorBoundary invisible>
                            <ShadowRenderer state={shadowRenderer} dispatch={dispatch} />
                        </ErrorBoundary>
                        <ErrorBoundary invisible>
                            {/* @ts-ignore is props really required ? */}
                            <StylesShadowRenderer
                                state={stylesShadowRenderer}
                                renderGlobalstyles={renderGlobalstyles}
                                dispatch={dispatch}
                            />
                        </ErrorBoundary>
                        <FileUploader noOverlay onDrop={this.onDrop} style={{ ...fileUploaderStyle }}>
                            <div
                                className={wsStyles.componentsRendererForStretchContainer}
                                style={{ marginTop: codeComponentsHeadSectionHeight }}
                            >
                                {
                                    shareHeaderAndFirstSectionBgImg.enabled
                                    && components.componentsMap[topMostPageSectionId]
                                    && <SharedBgImgView
                                        isWorkspace
                                        offsetTop={shareHeaderAndFirstSectionBgImg.offsetTop}
                                        firstSectionComponent={components.componentsMap[topMostPageSectionId]}
                                        headerHeight={components.componentsMap[headerSectionId].height}
                                    />
                                }
                                {!!modernSections.length && <ModernSectionsRenderer
                                    componentsProps={componentsProps}
                                    componentsDependencies={componentsDependencies}
                                    codeComponentsHeadSectionHeight={codeComponentsHeadSectionHeight}
                                    editModeComponentId={editModeComponentId}
                                    stylesheetsIdToNameMap={stylesheetsIdToNameMap}
                                    dispatch={dispatch}
                                    subscriptionType={subscriptionType}
                                    selectedComponentsIds={selectedComponentsIds}
                                    modernCmpsMap={modernCmpsMap}
                                    modernSections={modernSections}
                                    componentsMapExtension={componentsMapExtension}
                                    workspaceBBox={workspaceBBox}
                                    mhfCmpsData={mhfCmpsData}
                                />}
                                {isWebShopFooterStripCmpsExists && <ModernSectionsRenderer
                                    componentsProps={componentsProps}
                                    componentsDependencies={componentsDependencies}
                                    codeComponentsHeadSectionHeight={codeComponentsHeadSectionHeight}
                                    editModeComponentId={editModeComponentId}
                                    stylesheetsIdToNameMap={stylesheetsIdToNameMap}
                                    dispatch={dispatch}
                                    subscriptionType={subscriptionType}
                                    selectedComponentsIds={selectedComponentsIds}
                                    componentsMapExtension={componentsMapExtension}
                                    workspaceBBox={workspaceBBox}
                                    webshopFooterCmpsMap={webshopFooterCmpsMap}
                                />}
                                <ComponentsRenderer
                                    components={stretchComponents}
                                    componentsProps={componentsProps}
                                    componentsDependencies={componentsDependencies}
                                    editModeComponentId={editModeComponentId}
                                    stylesheetsIdToNameMap={stylesheetsIdToNameMap}
                                    dispatch={dispatch}
                                    subscriptionType={subscriptionType}
                                    selectedComponentsIds={selectedComponentsIds}
                                />
                            </div>
                            <TemplateArea
                                ref={templateRef}
                                dispatch={dispatch}
                                templateWidth={templateWidth}
                                templateStyle={templateStyle}
                                height={Math.max(viewportDimensions.height, contentDimensions.height)}
                                components={normalComponents}
                                attachments={attachments}
                                componentsProps={componentsProps}
                                isModernLayoutActivated={isModernLayoutActivated}
                                componentsDependencies={componentsDependencies}
                                editModeComponentId={editModeComponentId}
                                templateRenderOffset={templateRenderOffset}
                                stylesheetsIdToNameMap={stylesheetsIdToNameMap}
                                sectionInsertion={sectionInsertion}
                                decorationsProps={decorationsProps}
                                subscriptionType={subscriptionType}
                                selectedComponentsIds={selectedComponentsIds}
                                isWebShopFooterStripCmpsExists={isWebShopFooterStripCmpsExists}
                                // style={templateBgStyle}
                            />
                            <UnwrapTip />
                        </FileUploader>
                    </div>
                );
            }
            default:
                throw new Error(`unknown workspace status: ${status}`);
        }
    }

    UNSAFE_componentWillReceiveProps(newProps: WorkspaceProps) {
        if (newProps.scroll.shouldScrollToVertically) {
            this.shouldUpdateYScroll = true;
        }
        if (newProps.scroll.shouldScrollToYAnimated) {
            this.shouldAnimateYScroll = true;
        }
        if (newProps.scroll.x !== this.props.scroll.x) {
            this.shouldUpdateXScroll = true;
        }
    }

    componentDidMount() {
        const uiLocale = this.props.languages.current;
        if (typeof window.hj === 'function') {
            window.hj('event', `workspace_loaded_${uiLocale}`);
        }

        this.props.dispatch({ type: WORKSPACE_DID_MOUNT });
    }

    componentDidUpdate() {
        if (this.shouldUpdateXScroll && !this.tryingSyncHorizontalScroll) {
            this.shouldUpdateXScroll = false;

            const trySyncHorizontalScroll = () => {
                this.tryingSyncHorizontalScroll = true;
                if (this.scrollBarRef.scrollLeft !== this.props.scroll.x) {
                    this.scrollBarRef.scrollLeft = this.props.scroll.x;
                    setTimeout(trySyncHorizontalScroll, 30);
                } else {
                    this.setState({ visible: true });
                    this.tryingSyncHorizontalScroll = false;
                }
            };
            trySyncHorizontalScroll();
        } else if (!this.tryingSyncHorizontalScroll && !this.state.visible) {
            this.setState({ visible: true }); // eslint-disable-line react/no-did-update-set-state
        }

        if (this.shouldUpdateYScroll) {
            if (this.shouldAnimateYScroll) {
                $(this.scrollBarRef).animate({
                    scrollTop: this.props.scroll.y
                });
            } else {
                this.scrollBarRef.scrollTop = this.props.scroll.y;
            }
            this.shouldUpdateYScroll = false;
            this.shouldAnimateYScroll = false;
        }
    }

    onDrop = (e: MouseEvent, files: FileList) => {
        this.props.dispatch({
            type: ADD_COMPONENT_FILE_DND_INTO_WORKSPACE,
            payload: {
                files,
                left: e.clientX,
                top: e.clientY,
            }
        });
    };

    render() {
        const
            { dispatch, scroll: { hasHorizontalScroll } } = this.props,
            onScroll = this.onScroll,
            onMouseDown = e => mouseUtils.onMouseDown(
                e,
                position => dispatch(actionCreators.mouseDownAC(position)),
                () => null
            ),
            ref = (el) => {
                this.scrollBarRef = el;
                return this.scrollBarRef;
            },
            style: React.CSSProperties = {
                overflowX: hasHorizontalScroll ? 'scroll' : 'hidden',
                ...((this.isBackgroundFixed() && this.getTemplateBackgroundStyle()) || {})
            };

        return (
            <ErrorBoundary>
                <div
                    className={cx(appStyles.workspace, BLOCK_NATIVE_CONTEXT_MENU_CLASS)}
                    style={style}
                    onMouseDown={onMouseDown}
                    onScroll={onScroll}
                    ref={ref}
                >
                    {this.renderBanner()}
                    {this.renderWorkspaceBody()}
                </div>
            </ErrorBoundary>
        );
    }
}

const languagesEpicStateSelector = makeEpicStateSelector(languagesVAT);

function mapStateToProps(state: AppState) {
    const {
            selectedWorkspace: {
                templateRenderOffset,
                status,
                scroll,
                viewportDimensions,
                components,
                componentsDependencies,
                componentsProps,
                attachments,
                renderGlobalstyles,
                stylesheetsIdToNameMap,
                shadowRenderer,
                stylesShadowRenderer,
                templateWidth,
                templateStyle,
                editModeComponentId,
                contentDimensions,
                isMouseOverWorkspace,
                topMostPageSectionId,
                headerSectionId,
                shareHeaderAndFirstSectionBgImg,

                componentsSelectionDecoration,
                componentHoverDecorations,
                componentAttachDecorations,
                selectionFrameDecoration,
                redDecorations,
                handles,
                userInteraction,
                sectionInsertion,
                componentMainActions,
                componentPanelActions,
                shiftBarDecorations,
                resizeDecos,
                snappingDecos,
                selectedComponentsIds,
                topMostHandle,
                isMouseOverMCTA
            },
            dimensions: {
                browserHeight
            },
            panel,
            dndAddComponent,
        } = state,
        codeComponentsHeadSectionHeight = codeComponentHeadSectionSizeFromAppStateSelection(state),
        themeSettingsData = colorThemeDataEpicStateFromAppStateSelector(state),
        stylesheets = R.prop('stylesheets', renderGlobalstyles),
        themeColorsData = getThemeColorsFromStylesheet(stylesheets),
        isModernLayoutActivated = makeEpicStateSelector(isModernLayoutActivatedVAT)(state),
        autoColorLeftPanel = makeEpicStateSelector(AUTO_COLOR_LEFT_PANEL_EPIC_VALUE)(state),
        modernLayoutLeftPanel = makeEpicStateSelector(MODERN_LAYOUT_LEFT_PANEL_EPIC_VALUE)(state),
        modernLayoutOnBoardingPanel = makeEpicStateSelector(MODERN_LAYOUT_ONBOARDING_PANEL_EPIC_VALUE)(state),
        workspaceBBox = makeEpicStateSelector(WorkspaceBBoxValueActionType)(state),
        subscriptionType = state.subscriptionData.subscriptionType,
        languages = languagesEpicStateSelector(state),
        mhfCmpsData = makeEpicStateSelector(ComponentsEvalValueActionType)(state).scope.mhfCmpsData,
        bannerData = makeEpicStateSelector(BANNER_EPIC_VAT)(state);

    return {
        status,
        scroll,
        viewportDimensions,
        componentsDependencies,
        componentsProps,
        attachments,
        renderGlobalstyles,
        stylesheetsIdToNameMap,
        shadowRenderer,
        stylesShadowRenderer,
        templateWidth,
        templateStyle,
        browserHeight,
        templateRenderOffset,
        contentDimensions,
        isMouseOverWorkspace,
        isPanelExpanded: panel.expanded,
        mhfCmpsData,
        bannerData,
        // decorations
        componentsSelectionDecoration,
        componentHoverDecorations,
        componentAttachDecorations,
        selectionFrameDecoration,
        redDecorations,
        handles,
        components,
        userInteraction,
        sectionInsertion,
        componentMainActions,
        componentPanelActions,
        shiftBarDecorations,
        resizeDecos,
        editModeComponentId,
        snappingDecos,
        selectedComponentsIds,
        topMostHandle,
        isMouseOverMCTA,
        dndAddComponent,
        codeComponentsHeadSectionHeight,
        themeSettingsData,
        themeColorsData,
        isModernLayoutActivated,
        autoColorLeftPanel,
        modernLayoutLeftPanel,
        modernLayoutOnBoardingPanel,
        topMostPageSectionId,
        headerSectionId,
        shareHeaderAndFirstSectionBgImg,
        subscriptionType,
        workspaceBBox,
        languages
    };
}

// @ts-ignore
export default connect(mapStateToProps)(WorkspaceComponent);

export {
    BOTTOM_DRAG_STRIP_HEIGHT
};
