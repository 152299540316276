import * as R from 'ramda';
import type { ComponentsMap } from "../../../redux/modules/children/workspace/flowTypes";
import localStorage from "../../../utils/localStorage";
import ProductWidgetKind from './kind';

const localStorageKey = 'setupProductTooltipViewCount';

export const mustShowSetupProductTooltip = () => {
    let result = false;
    const viewCount = localStorage.get(localStorageKey);
    if (viewCount) {
        const count = parseInt(viewCount.value, 10);
        result = count === 0;
    }
    return result;
};

export const incrementSetupProductTooltipViewCount = () => {
    let viewCount = localStorage.get(localStorageKey);
    if (!viewCount) {
        viewCount = { value: -1 };
        localStorage.set(localStorageKey, viewCount);
    }
    let count = parseInt(viewCount.value, 10) + 1;
    localStorage.set(localStorageKey, { value: count });
};

export const decrementSetupProductTooltipViewCount = (decrementVal = 1) => {
    let viewCount = localStorage.get(localStorageKey);
    if (viewCount && !isNaN(viewCount.value)) {
        let count = parseInt(viewCount.value, 10) - decrementVal;
        localStorage.set(localStorageKey, { value: count });
    }
};

export const findProductWidgetComponent = (componentsMap: ComponentsMap) =>
    R.filter(comp => comp.kind === ProductWidgetKind)(componentsMap);

export const isProductWidgetComponentFound = (componentsMap: ComponentsMap) => !R.isEmpty(
    findProductWidgetComponent(componentsMap)
);

export const getProductWidgetComponentsCount = (componentsMap: ComponentsMap) => {
    const cmps = findProductWidgetComponent(componentsMap);
    return Object.keys(cmps).length;
};

