import Icons from '../../../view/Icons/index';

export const
    COMMON_OPTIONS = {
        icon: {
            left: Icons.LEFT_ALIGN,
            right: Icons.RIGHT_ALIGN,
            center: Icons.CENTER_ALIGN
        },
        iconKey: {
            left: 'LEFT_ALIGN',
            right: 'RIGHT_ALIGN',
            center: 'CENTER_ALIGN'
        },
        hoverMessage: {
            left: "msg: common.alignLeft {Align left}",
            right: "msg: common.alignRight {Align right}",
            center: "msg: common.alignCenter {Align center}"
        }
    },
    BUTTON_OPTIONS = {
        icon: {
            ...COMMON_OPTIONS.icon,
            justify: Icons.STRETCH_ALIGN
        },
        iconKey: {
            ...COMMON_OPTIONS.iconKey,
            justify: 'STRETCH_ALIGN'
        },
        hoverMessage: {
            left: "msg: mcta.button.alignLeft {Left align button}",
            right: "msg: mcta.button.alignRight {Right align button}",
            center: "msg: mcta.button.alignCenter {Center button}",
            justify: "msg: mcta.button.alignJustify {Justify button}"
        }
    },
    TEXT_OPTIONS = {
        icon: {
            left: Icons.TEXT_ALIGN_LEFT,
            right: Icons.TEXT_ALIGN_RIGHT,
            center: Icons.TEXT_ALIGN_CENTER,
            justify: Icons.TEXT_ALIGN_FULL
        },
        iconKey: {
            left: 'TEXT_ALIGN_LEFT',
            right: 'TEXT_ALIGN_RIGHT',
            center: 'TEXT_ALIGN_CENTER',
            justify: 'TEXT_ALIGN_FULL'
        },
        hoverMessage: {
            left: COMMON_OPTIONS.hoverMessage.left,
            right: COMMON_OPTIONS.hoverMessage.right,
            center: "msg: mcta.text.alignCenter {Center text}",
            justify: "msg: mcta.text.alignJustify {Justify text}"
        }
    };

export const
    RESIZE_OPTIONS = {
        defaultHoverMessage: {
            small: "msg: mcta.size.smallIcon {Small icon}",
            medium: "msg: mcta.size.mediumIcon {Medium icon}",
            large: "msg: mcta.size.largeIcon {Large icon}",
        },
        imageHoverMessage: {
            small: "msg: mcta.size.smallLogo {Small logo}",
            medium: "msg: mcta.size.mediumLogo {Medium logo}",
            large: "msg: mcta.size.largeLogo {Large logo}",
        },
        buttonHoverMessage: {
            small: "msg: mcta.size.smallButton {Small button}",
            medium: "msg: mcta.size.mediumButton {Medium button}",
            large: "msg: mcta.size.largeButton {Large button}",
        },
        optionLabel: {
            small: "S",
            medium: "M",
            large: "L",
        }
    };
