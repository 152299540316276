/* eslint-disable max-len */

import React from 'react';
import { Box } from "../../../../view/reflexbox";
import { Msg, Intl } from '../../../../view/intl';
import {
    PrimaryButton,
    ContinueButton
} from '../../../../view/common/Button';
import { replaceWithPromoPageDialog, replaceWithDisconnectListingDialog } from './actionCreators';

import * as styles from "./GoogleMyBusinessListing.css";
import * as actionTypes from "./actionTypes";
import { TooltipPosition } from "../../../presentational/Tooltip/constants";
import VerticalSpacer from "../../../../view/common/VerticalSpacer";
import { QuestionTip } from "../../../presentational/Tooltip/questionTip/QuestionTip";
import { replaceDialogWithUpgradePlanDialog } from "../../../TopBar/view/Upgrade/UpgradePlansDialog/OpenUpgradePlanDialog";
import LoadingIndicator from "../../../../view/common/LoadingIndicator";
import type { GeneralGlobalData } from "../../General/generalGlobalDataEpic/generalGlobalDataEpic";
import type { SubscriptionData } from "../../../../redux/modules/flowTypes";
import { GeneralDataToWsb } from "./Mappers";
import { GoogleMapView } from "./GoogleMap/view";
import { getEcommercePackageNameFromMetadata } from '../../../ComponentTierManager/utils';
import { isTrialSubscription, isUnknownSubscription } from '../../../App/epics/subscriptionData/utils';
import { RESET_REVIEW_WIDGET_CONFIG } from '../../../oneweb/GoogleReviews/epic/actionTypes';
import { upgradeDialogOpenedAction } from '../../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions';
import { FEATURE_BUSINESS_LISTING } from '../../../TopBar/view/Upgrade/tracking/contants';
import { UpgradePlansDialogId } from '../../../TopBar/view/dialogIds';

type Props = {
    dispatch: Dispatch,
    intl: Intl,
    isLoggedIn: boolean,
    isConnected: boolean,
    isGmbAccessAllowed: boolean,
    isWaitingForApi: boolean,
    generalData: GeneralGlobalData,
    subscriptionData: SubscriptionData,
};

const GoogleMyBusinessListing = function (props: Props) {
    const
        {
            dispatch,
            intl,
            isLoggedIn,
            isConnected,
            isGmbAccessAllowed,
            isWaitingForApi,
            generalData,
            subscriptionData,
        } = props,
        { subscriptionType } = subscriptionData,
        wsbData = GeneralDataToWsb(generalData),
        {
            phoneNumber,
            addressFields,
            openingHours
        } = wsbData,
        subscriptionMetadata = subscriptionData?.metadata,
        eCommercePackageNameFromMetadata = getEcommercePackageNameFromMetadata(subscriptionMetadata),
        isDemoUserOrUnknown = isTrialSubscription(subscriptionType) ||
                              isUnknownSubscription(subscriptionType);

    const
        standardUserView = () => (
            <div className={styles.container}>
                <div className={styles.gmb}>
                    <div className={styles.gmbTitle}>
                        <div className={styles.gmbLogo} />
                        <div>{ intl.msgJoint("msg: gmb.googleMyBusiness {Google My Business}") }</div>
                        <div className={styles.upgradePlan} />
                    </div>
                    <div className={styles.gmbDescription}>
                        {intl.msgJoint("msg: gmb.googleMyBusinessDescription {Google My Business is a free tool that allows you to promote your business profile and business website on Google Search and Maps.}")}
                    </div>
                    <span
                        className={styles.learnMore}
                        onClick={() => dispatch(replaceWithPromoPageDialog({ subscriptionData, isGmbAccessAllowed, dispatch, intl }))}
                    >
                        <Msg k="common.learnMore">Learn more</Msg>
                    </span>
                </div>
                <div className={styles.bPlusE}>
                    <div className={styles.bPlusETitle}>
                        {intl.msgJoint([
                            "msg: componentTier.ecommerceTier.businessEcommerceFeature {{packageName} feature}",
                            { packageName: eCommercePackageNameFromMetadata || intl.msgJoint('msg: common.business_ecommerce {Business + Ecommerce}') }
                        ])}
                    </div>
                    <div className={styles.bPlusEDescription}>
                        <Msg k="gmb.doesNotIncludeGMB">Your current Website Builder plan does not include Google My Business.</Msg>
                        <br />
                        <Msg
                            k="gmb.upgradeToBusinessPlusEcommerce"
                            params={{ packageName: eCommercePackageNameFromMetadata || intl.msgJoint('msg: common.business_ecommerce {Business + Ecommerce}') }}
                        >{`
                            Upgrade to {packageName} to get this and other great features.
                        `}</Msg>
                    </div>
                    {!isDemoUserOrUnknown && (
                        <span
                            className={styles.upgrade}
                            onClick={() => {
                                dispatch(replaceDialogWithUpgradePlanDialog(subscriptionData, 'SiteSettings:BusinessListing'));
                                dispatch(upgradeDialogOpenedAction({
                                    feature: FEATURE_BUSINESS_LISTING,
                                    dialogId: UpgradePlansDialogId
                                }));
                            }}
                        >
                            <Msg k="common.upgrade">Upgrade</Msg>
                        </span>
                    )}
                </div>
            </div>
        ),
        ecommerceUserView = () => (
            <div className={styles.container}>
                <div className={styles.gmb}>
                    <div className={styles.gmbTitle}>
                        <div className={styles.gmbLogo} />
                        <div>{ intl.msgJoint("msg: gmb.googleMyBusiness {Google My Business}") }</div>
                    </div>
                    <div className={styles.gmbDescription}>
                        {intl.msgJoint("msg: gmb.promoteYourBusiness {Promote your business profile and website on Google Search and Maps.}")}
                    </div>
                    <span
                        className={styles.learnMore}
                        onClick={() => dispatch(replaceWithPromoPageDialog({ subscriptionData, isGmbAccessAllowed, dispatch, intl }))}
                    >
                        <Msg k="common.learnMore">Learn more</Msg>
                    </span>
                </div>
                <div className={styles.buttons}>
                    <div>
                        <PrimaryButton onClick={() => {
                            dispatch({ type: RESET_REVIEW_WIDGET_CONFIG });
                            dispatch({ type: actionTypes.CREATE_YOUR_LISTING_BTN_CLICKED_ACTION });
                        }}
                        >
                            <Msg k="gmb.createAListing">Create a listing</Msg>
                        </PrimaryButton>
                    </div>
                    <div>
                        <ContinueButton
                            onClick={() => {
                                dispatch({ type: RESET_REVIEW_WIDGET_CONFIG });
                                dispatch({ type: actionTypes.CONNECT_YOUR_LISTING });
                            }}
                            label="msg: gmb.connectYourListing {Connect your listing}"
                        />
                    </div>
                </div>
            </div>
        ),
        connectedUserView = () => (
            <div className={styles.container}>
                <div className={styles.gmbConnected}>
                    <div className={styles.gmbTitle}>
                        <div className={styles.gmbLogo} />
                        <div>{ intl.msgJoint("msg: gmb.googleMyBusiness {Google My Business}") }</div>
                    </div>
                    <div className={styles.gmbDescription} style={{ fontSize: '12px' }}>
                        <span>{intl.msgJoint("msg: gmb.websiteConnected {Your website is currently connected to your listing.}")}</span>
                        <QuestionTip
                            position={TooltipPosition.RIGHT}
                            distance={2}
                            theme={{ tipInfo: styles.tipContent, container: styles.tipContainer }}
                            className={styles.questionTip}
                        >
                            <div>
                                <div className={styles.tipHeader}>
                                    {intl.msgJoint("msg: gmb.connectedListing {Connected listing}")}
                                </div>
                                <VerticalSpacer y={6} />
                                <div className={styles.tipDesc}>
                                    {intl.msgJoint("msg: gmb.connectedListingDesc {Your business information, such as address and phone number, is synced from your website to your listing. You can update this information in the General settings and then publish it to refresh your listing.}")}
                                </div>
                            </div>
                        </QuestionTip>
                    </div>
                    <div className={styles.disconnectListingBtnContainer}>
                        <ContinueButton
                            className={styles.disconnectListingBtn}
                            onClick={() => dispatch(replaceWithDisconnectListingDialog())}
                            label="msg: gmb.disconnectListing {Disconnect listing}"
                        />
                    </div>
                </div>
                <Box className={styles.mapContainer}>
                    <GoogleMapView addressFields={addressFields} openingHours={openingHours} phoneNumber={phoneNumber} />
                </Box>
            </div>
        );

    if (isGmbAccessAllowed) {
        if (isWaitingForApi) {
            return <LoadingIndicator />;
        } else if (isLoggedIn && isConnected) {
            return connectedUserView();
        } else {
            return ecommerceUserView();
        }
    }

    return standardUserView();
};

export { GoogleMyBusinessListing };
