import type { DialogConfig, UpdateOnBrowserDimensionsChanged } from './flowTypes';
import type { Position, TopLeftPosition } from '../../redux/modules/flowTypes';
import positionCalculator, { Positions, CollisionAlignment } from '../../utils/Position';
import type { Dimensions } from "../../redux/modules/children/workspace/flowTypes";

export const
    isMouseDownOutsideDialog = (
        { position: { left, top }, dimensions: { width, height } }: DialogConfig,
        { x, y }: Position
    ): boolean => {
        return !((x > left && x < (left + width)) && (y > top && y < (top + height)));
    },
    getCenterPositionForDialog = (
        dialogWidth: number,
        dialogHeight: number,
        parentWidth: number,
        parentHeight: number
    ): TopLeftPosition => {
        const left = (parentWidth - dialogWidth) / 2,
            top = (parentHeight - dialogHeight) / 2;

        return { left: (left >= 0) ? left : 0, top: (top >= 0) ? top : 0 };
    },
    getPositionForDialogWithRespectToMouseClick = (
        dialogWidth: number,
        dialogHeight: number,
        parentWidth: number,
        parentHeight: number,
        { x, y }: Position
    ): TopLeftPosition => {
        const { x: left, y: top } = positionCalculator(
            { position: Positions.TopLeft, dimension: { width: dialogWidth, height: dialogHeight } },
            { position: Positions.TopLeft, bbox: { top: y, right: (x + 1), bottom: (y + 1), left: x } },
            { top: 0, right: parentWidth, bottom: parentHeight, left: 0 },
            CollisionAlignment.Intelligent
        );
        return { left, top };
    },
    getDialogHeightWithRespectToBrowserHeight = (
        {
            browserHeight,
            minHeight,
            maxHeight = Number.POSITIVE_INFINITY
        }: { browserHeight: number, minHeight: number, maxHeight?: number }
    ) => Math.min(
        Math.max(
            Math.floor(0.9 * browserHeight),
            minHeight
        ),
        maxHeight
    ),
    makeUpdateDialogHeightOnBrowserDimensionsChanged = (
        {
            minHeight,
            maxHeight
        }: { minHeight: number, maxHeight?: number }
    ): UpdateOnBrowserDimensionsChanged => (
        dialogConfig: DialogConfig, { height: browserHeight }: Dimensions
    ) => ({
        ...dialogConfig,
        dimensions: {
            ...dialogConfig.dimensions,
            height: getDialogHeightWithRespectToBrowserHeight({ browserHeight, minHeight, maxHeight })
        }
    }),
    getBottomOrTopRightDialogPosition = (
        { browserHeight, browserWidth }: { browserHeight: number, browserWidth: number },
        dialogWidth: number,
        dialogHeight: number,
        selectedCmpDimensions: any
    ): TopLeftPosition => {
        const DIALOG_PADDING = 50;
        const dialogCoordinates = {
            rightPosition: Math.round(browserWidth - (DIALOG_PADDING + dialogWidth)),
            leftPosition: DIALOG_PADDING,
            topPosition: DIALOG_PADDING,
            bottomPosition: Math.round(browserHeight - (DIALOG_PADDING + dialogHeight)),
            middlePositionX: Math.round(browserWidth / 2 - dialogWidth / 2),
            middlePositionY: Math.round(browserHeight / 2 - dialogHeight / 2),
        };

        function getCmpDialogIntersection(underlyingBox, overlappingBox) {
            const underlyingArea = underlyingBox.w * underlyingBox.h,
                intersectionWidth =
                    Math.min(underlyingBox.x + underlyingBox.w, overlappingBox.x + overlappingBox.w) -
                    Math.max(underlyingBox.x, overlappingBox.x),
                intersectionHeight =
                    Math.min(underlyingBox.y + underlyingBox.h, overlappingBox.y + overlappingBox.h) -
                    Math.max(underlyingBox.y, overlappingBox.y);
            let intersectionArea = intersectionWidth * intersectionHeight;

            //in case intersection width and height are negative (=> not overlapping)
            //the intersectionArea should also be negative, in order to not be misinterpreted as overlapping
            if (intersectionWidth < 0 && intersectionHeight < 0) {
                intersectionArea *= -1;
            }

            // Calculate the percentage of intersection
            return Math.round((intersectionArea / underlyingArea) * 100);
        }

        //set possible positions for the dialog overlay and calculate the intersection with the component
        const dialogPositions =
        [
            //Bottom Right
            {
                left: dialogCoordinates.rightPosition,
                top: dialogCoordinates.bottomPosition,
                intersectionPercentage:
                    getCmpDialogIntersection(
                        selectedCmpDimensions,
                        {
                            x: dialogCoordinates.rightPosition,
                            y: dialogCoordinates.bottomPosition,
                            w: dialogWidth,
                            h: dialogHeight
                        }
                    )
            },
            //Top Right
            {
                left: dialogCoordinates.rightPosition,
                top: dialogCoordinates.topPosition,
                intersectionPercentage:
                    getCmpDialogIntersection(
                        selectedCmpDimensions, {
                            x: dialogCoordinates.rightPosition,
                            y: dialogCoordinates.topPosition,
                            w: dialogWidth,
                            h: dialogHeight
                        }
                    )
            },
            //Bottom Left
            {
                left: dialogCoordinates.leftPosition,
                top: dialogCoordinates.bottomPosition,
                intersectionPercentage:
                    getCmpDialogIntersection(
                        selectedCmpDimensions, {
                            x: dialogCoordinates.leftPosition,
                            y: dialogCoordinates.bottomPosition,
                            w: dialogWidth,
                            h: dialogHeight
                        }
                    )
            },
            //Top Left
            {
                left: dialogCoordinates.leftPosition,
                top: dialogCoordinates.topPosition,
                intersectionPercentage:
                    getCmpDialogIntersection(
                        selectedCmpDimensions, {
                            x: dialogCoordinates.leftPosition,
                            y: dialogCoordinates.topPosition,
                            w: dialogWidth,
                            h: dialogHeight
                        }
                    )
            },
            //Middle
            {
                left: dialogCoordinates.middlePositionX,
                top: dialogCoordinates.middlePositionY,
                intersectionPercentage:
                    getCmpDialogIntersection(
                        selectedCmpDimensions, {
                            x: dialogCoordinates.middlePositionX,
                            y: dialogCoordinates.middlePositionY,
                            w: dialogWidth,
                            h: dialogHeight
                        }
                    )
            }
        ];

        //find all dialog positions that don't overlap with the component
        const nonOverlappingPositions = dialogPositions.filter(position => position.intersectionPercentage <= 0);

        //in case every position overlaps with the component, use the position that overlaps the least
        if (nonOverlappingPositions.length === 0) {
            dialogPositions.sort((a, b) => a.intersectionPercentage - b.intersectionPercentage);

            return {
                left: dialogPositions[0].left,
                top: dialogPositions[0].top
            };
        }

        //use the closest position to the component, which doesn't overlap
        nonOverlappingPositions.sort((a, b) =>
            Math.abs(a.left - selectedCmpDimensions.x) + Math.abs(a.top - selectedCmpDimensions.y)
            - (Math.abs(b.left - selectedCmpDimensions.x) + Math.abs(b.top - selectedCmpDimensions.y)));

        return {
            left: nonOverlappingPositions[0].left,
            top: nonOverlappingPositions[0].top
        };
    };
