import { getSelectedNode } from "../../utils/nodeUtils/getSelectedNode";
import type { TinyMceEditor } from "../../../flowTypes";

export const getPaddingNode = (editor: TinyMceEditor) => {
    const selectedNode = getSelectedNode(editor);
    if (!selectedNode) {
        return null;
    }

    const _$ = editor.dom.$;
    let $selectedNode = _$(selectedNode);
    while ($selectedNode.is('span')) {
        $selectedNode = $selectedNode.parent();
    }
    if ($selectedNode.hasClass('mb-indent')) {
        $selectedNode = $selectedNode.find('*:eq(0)');
    }
    // Sometimes tinymce selection api does not work correctly and return the fourField input field
    if (!$selectedNode.closest('.mb-indent').length) {
        $selectedNode = _$(editor.getBody());
        $selectedNode = $selectedNode.find('.mb-indent > *:eq(0)');
    }
    // For new text component, mb-content won't be available.
    if (!$selectedNode.length) {
        $selectedNode = _$(getSelectedNode(editor));
        while ($selectedNode.is('span')) {
            $selectedNode = $selectedNode.parent();
        }
    }
    return $selectedNode;
};
