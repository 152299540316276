import valueActionType from './valueActionType';
import makeEpic from "../../../../../epics/makeEpic";
import { featuredProductsIdsListVat } from "../featuredProductsPageIdsList/valueActionType";

export default makeEpic({
    defaultState: false,
    valueActionType,
    updaters: [
        {
            conditions: [
                featuredProductsIdsListVat
            ],
            reducer: ({ values: [featuredProductsIdsList] }) => {
                return {
                    state: !!featuredProductsIdsList.length
                };
            }
        }
    ]
});
