import CALL_API from "../../../redux/middleware/api/CALL_API";
import { CheckComponentWrapInTableActionTypes } from "./actionTypes";

export const
    getAllDocsWithComponentWrappedInTable = () => ({
        [CALL_API]: {
            types: CheckComponentWrapInTableActionTypes,
            endpoint: "getAllDocsWithComponentWrappedInTable"
        }
    });
