import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "56px",
        padding: "0px",
        [theme.breakpoints.down('md')]: {
            marginTop: "50px",
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: "40px",
        }
    },
    container: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            padding: "0px",
        },
    },
    leftItem: {
        width: "497px",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
    },
    rightItem: {
        width: "50%",
        maxWidth: "600px",
        paddingLeft: "89px",
        [theme.breakpoints.down('lg')]: {
            paddingLeft: "54px",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            paddingTop: "34px",
            paddingLeft: "0px",
        },
    },
    imageChooseContainer: {
        display: "flex",
        alignItems: "center",
        flexWrap: "nowrap",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "flex-start",
        },
    },
    imageChooserInput: {
        width: "100%",
        border: `1px solid ${theme.palette.custom.colorGray_bb}`,
        height: "52px",
        padding: "14px 52px 14px 20px",
        fontSize: "15px",
        cursor: "pointer",
        justifyContent: "left",
        fontWeight: 400,
        color: theme.palette.custom.colorScienceBlue,
    },
    imageChooserHint: {
        width: "100%",
        marginTop: "10px",
        color: theme.palette.custom.colorGray_8a,
        fontSize: "14px",
        lineHeight: "20px",
    },
    imageChooserHintWarning: {
        width: "100%",
        marginTop: "10px",
        fontSize: "14px",
        lineHeight: "20px",
        color: theme.palette.custom.colorRedEpiphyllum,
    },
    imageChooserInputSelected: {
        color: theme.palette.custom.colorGray_3c,
    },
    inputLabelContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
    },
    inputLabel: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
        textAlign: "left",
    },
    closeIcon: {
        display: "flex",
        alignItems: "center",
        marginRight: "-40px",
    },
    imageChooserLabel: {
        fontSize: "15px",
        lineHeight: "18px",
        marginBottom: "10px",
    },
    imageChangeContainer: {
        whiteSpace: "nowrap",
        paddingLeft: "10px",
        [theme.breakpoints.down('sm')]: {
            paddingLeft: "0px",
            paddingTop: "10px",
        },
    },
    imageChangeLink: {
        fontSize: "15px",
    },
    imagePreviewLabel: {
        fontSize: "15px",
        lineHeight: "24px",
        marginBottom: "10px",
    },
    imageWrapper: {
        display: "flex",
        height: "296px",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        border: `1px dashed ${theme.palette.custom.colorScienceBlue}`,
        background: theme.palette.custom.colorLavenderWeb,
        cursor: "pointer",
    },
    imageWrapperSelected: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        border: `1px solid ${theme.palette.custom.colorGray_3c}`,
        cursor: "pointer",
    },
    imageCloseIcon: {
        display: "flex",
        position: "absolute",
        left: "18px",
        bottom: "18px",
        width: "24px",
        height: "24px",
    },
    placeholderPreviewContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        color: theme.palette.custom.colorScienceBlue,
    },
    imagePreviewContainer: {
        display: "flex",
        position: "relative",
        width: "100%",
    },
    image: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
    },
    imageHoverContainer: {
        position: "absolute",
        width: "100%",
        height: "100%",
    },
}), { name: "SocialShare" });
