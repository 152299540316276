/* eslint-disable max-len */
import { LOGO_KIND } from './kind';
import imageComponentConfig from '../Image/index';
import type { ImageComponent } from '../Image/flowTypes';
import { ComponentNames } from '../constants';
import { globalVariablesEpic } from '../../App/epics/globalVariablesEpic';
import { LogoView } from './view/index';
import { LogoWorkspaceView } from './view/workspace';
import { imageMobileEditorCalcProps } from '../Image/mobileEditorConfig';
import { makeImageComponentMainActions } from '../Image/componentMainActions/index';
import { injectIntl, Intl } from '../../../view/intl/index';
import type { ImageAsset } from '../../App/flowTypes';
import { getLogoInitialDimensions } from './getLogoInitialDimensions';
import { LOGO_MCTA_SET_LOGO_PRESSED } from './actionTypes';
import { logoPpConfig } from './propertiesPanel/index';
import { logoServiceEpic } from './logoServiceEpic';
import { logoGlobalStyleReducer } from '../../Workspace/epics/stylesheets/index';
import { generalGlobalDataEpic } from '../../SiteSettings/General/generalGlobalDataEpic/generalGlobalDataEpic';

type Props = { globalVariables: { logoAsset: ImageAsset }, intl: Intl };

const logoComponentsMainActions = makeImageComponentMainActions({
    // @ts-ignore
    CtaButtonTextView: injectIntl(({ globalVariables: { logoAsset }, intl }: Props) => {
        if (logoAsset) {
            return intl.msgJoint('msg: component.logo.replaceLogo {Replace logo}');
        }
        return intl.msgJoint('msg: component.logo.setLogo {Set logo}');
    }),
    ctaOnClick: ({ dispatch }: { dispatch: Dispatch }) =>
        dispatch({ type: LOGO_MCTA_SET_LOGO_PRESSED }),
});

export const logoComponentConfig = {
    ...imageComponentConfig,
    shortcutIconName: 'logo',
    view: LogoView,
    workspace: LogoWorkspaceView,
    kind: LOGO_KIND,
    componentMainActions: logoComponentsMainActions,
    label: ComponentNames[LOGO_KIND],
    dependsOn: {
        ...imageComponentConfig.dependsOn,
        isLogo: () => true,
        globalVariables: globalVariablesEpic.reducer
    },
    controlsDependsOn: {
        service: logoServiceEpic.reducer,
        logoGlobalStyle: logoGlobalStyleReducer,
        generalGlobalData: generalGlobalDataEpic.reducer,
    },
    requireConfigurationOnDrop: () => false,
    adjustComponentOnAdd: (state: ImageComponent, { globalVariables }: Props) => {
        return {
            ...state,
            scaleStrategy: 'logo',
            kind: LOGO_KIND,
            ...getLogoInitialDimensions({ globalVariables }),
            horizontalAlignment: 'left',
            mobileHide: false
        };
    },
    mobileEditorConfig: {
        ...imageComponentConfig.mobileEditorConfig,
        calcProps: (props: Object) => imageMobileEditorCalcProps({
            ...props,
            isLogo: true,
            isWorkspace: true
        }),
        view: LogoView
    },
    propertiesPanel: logoPpConfig,
    minDimensions: { width: 16, height: 16 }
};
