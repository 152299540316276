/* eslint-disable max-len */

export const
    SOCIAL_CHANGE_GLOBAL_SOCIAL_LINKS_CTA_CLICKED = "SOCIAL_CHANGE_GLOBAL_SOCIAL_LINKS_CTA_CLICKED",
    SOCIAL_MCTA_CHANGE_SIZE = 'SOCIAL_MCTA_CHANGE_SIZE',
    SOCIAL_MCTA_HORIZONTAL_SPACING_SLIDER_VALUE_CHANGED = 'SOCIAL_MCTA_HORIZONTAL_SPACING_SLIDER_VALUE_CHANGED',
    SOCIAL_MCTA_SIZE_SLIDER_VALUE_CHANGED = 'SOCIAL_MCTA_SIZE_SLIDER_VALUE_CHANGED',
    SOCIAL_COLOR_TYPE_CHANGED = "SOCIAL_COLOR_TYPE_CHANGED",
    SOCIAL_COLOR_TYPE_CHANGED_AUTO_COLOR = "SOCIAL_COLOR_TYPE_CHANGED_AUTO_COLOR",
    SOCIAL_STYLE_TYPE_CHANGED = "SOCIAL_STYLE_TYPE_CHANGED",
    SOCIAL_LINK_PP_ITEM_MOUSE_OVER = 'SOCIAL_LINK_PP_ITEM_MOUSE_OVER',
    SOCIAL_LINK_PP_ITEM_MOUSE_LEAVE = 'SOCIAL_LINK_PP_ITEM_MOUSE_LEAVE',
    SOCIAL_LINK_CONTEXT_MENU_BTN_CLICKED = 'SOCIAL_LINK_CONTEXT_MENU_BTN_CLICKED',
    SOCIAL_LINK_HIDDEN_ICON_CLICKED = 'SOCIAL_LINK_HIDDEN_ICON_CLICKED',
    SOCIAL_LINK_CONTEXT_MENU_EDIT_CLICKED = 'SOCIAL_LINK_CONTEXT_MENU_EDIT_CLICKED',
    SOCIAL_LINK_CONTEXT_MENU_DELETE_CLICKED = 'SOCIAL_LINK_CONTEXT_MENU_DELETE_CLICKED',
    SOCIAL_LINK_CONTEXT_MENU_SHOW_OR_HIDE_CLICKED = 'SOCIAL_LINK_CONTEXT_MENU_SHOW_OR_HIDE_CLICKED',
    SOCIAL_LINK_ADD_INPUT_CHANGE = 'SOCIAL_LINK_ADD_INPUT_CHANGE',
    SOCIAL_LINK_INPUT_PASTE = 'SOCIAL_LINK_INPUT_PASTE',
    SOCIAL_LINK_INPUT_BOTTOM_CENTER_POINT_AFTER_RENDER = 'SOCIAL_LINK_INPUT_BOTTOM_CENTER_POINT_AFTER_RENDER',
    SOCIAL_LINK_INPUT_FOCUSED = 'SOCIAL_LINK_INPUT_FOCUSED',
    SOCIAL_LINK_INPUT_BLUR = 'SOCIAL_LINK_INPUT_BLUR',
    SOCIAL_LINK_ADD_INPUT_ENTER_PRESSED = 'SOCIAL_LINK_ADD_INPUT_ENTER_PRESSED',
    SOCIAL_ADD_LINK_WARNING_TOOLTIP_TEXT_WIDTH = 'SOCIAL_ADD_LINK_WARNING_TOOLTIP_TEXT_WIDTH',
    SOCIAL_EDIT_DIALOG_OK_PRESSED = 'SOCIAL_EDIT_DIALOG_OK_PRESSED',
    SOCIAL_EDIT_LINK_DIALOG_INPUT_CHANGED = 'SOCIAL_EDIT_LINK_DIALOG_INPUT_CHANGED',
    SOCIAL_EDIT_DIALOG_CLOSE_PRESSED = 'SOCIAL_EDIT_DIALOG_CLOSE_PRESSED',
    SOCIAL_DELETE_LINK_DIALOG_DELETE_PRESSED = 'SOCIAL_DELETE_LINK_DIALOG_DELETE_PRESSED',
    SOCIAL_DELETE_LINK_DIALOG_CANCEL_PRESSED = 'SOCIAL_DELETE_LINK_DIALOG_CANCEL_PRESSED',
    SOCIAL_LINKS_SORT_START = 'SOCIAL_LINKS_SORT_START',
    SOCIAL_LINKS_SORT_END = 'SOCIAL_LINKS_SORT_END',
    SOCIAL_GLOBAL_LINKS_SORTED = 'SOCIAL_GLOBAL_LINKS_SORTED',
    SOCIAL_TOGGLE_PLATFORM_VISIBILITY = 'SOCIAL_TOGGLE_PLATFORM_VISIBILITY',
    SOCIAL_SET_COMPONENT_HORIZONTAL_SPACING = 'SOCIAL_SET_COMPONENT_HORIZONTAL_SPACING',
    SOCIAL_MOBILE_MCTA_CHANGE_SIZE = 'SOCIAL_MOBILE_MCTA_CHANGE_SIZE',
    SOCIAL_MOBILE_MCTA_SIZE_SLIDER_VALUE_CHANGED = 'SOCIAL_MOBILE_MCTA_SIZE_SLIDER_VALUE_CHANGED',
    SOCIAL_MOBILE_MCTA_HORIZONTAL_ALIGNMENT_CHANGED = 'SOCIAL_MOBILE_MCTA_HORIZONTAL_ALIGNMENT_CHANGED',
    SOCIAL_SET_VISIBLE_PLATFORMS_FOR_COMPONENT = 'SOCIAL_SET_VISIBLE_PLATFORMS_FOR_COMPONENT',
    SOCIAL_OUTDATED_LINKS_LOCAL_STATE_FIX = 'SOCIAL_OUTDATED_LINKS_LOCAL_STATE_FIX',
    SOCIAL_FIX_COMPONENT_LINKS_ON_PASTE = 'SOCIAL_FIX_COMPONENT_LINKS_ON_PASTE';
