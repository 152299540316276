import * as R from 'ramda';
import { withSelector, receiveOnly, reset, optionalResetReceiveOnly, when } from '../../../../epics/makeCondition';
import valueActionType from './valueActionType';
import memo, { memoMaxDynamicNumOfArgs, memoMaxOne, memoMaxOneCheckOneArg } from '../../../../../utils/memo';
import { getBBoxDimensionsMemoized } from "../../../../utils/bBox";
import { getComponentsMapNoGhosts } from "../../../oneweb/Code/getComponentsMapNoGhosts";
import {
    selectedComponentsIdsSelector, editModeComponentIdSelector, editModeComponentSelector, makeEditModeComponentSelectorByKind,
} from "./selectors";
import { isTransient as isTransientUserInteractionMode } from './userInteractionMutations/interactionModes';
import * as updateReasons from './updateReasons';

import type { EpicState as ComponentsEvalState, EpicState } from './flowTypes';
import { withPayload } from "../../../../../utils/action";
import { emptyArray } from "../../../../utils/handle/makeHandle";
import { getComponentsMapBases } from './componentsMapBase';
import { COMPONENT_DIMENSIONS_CHANGED_FROM_PP } from './actionTypes';
import { getAttachments, getMHFCmpsData, getSectionInsertion } from "./getters";
import { getComponentsMapForHoverBox } from "../../../oneweb/HoverBox/utils";

const
    userInteractionSelector = ({ scope: { userInteraction } }: EpicState) => userInteraction,
    makeSelectedComponentIdSelector = (selectedComponentsIdsSelector) => (epicState: EpicState) => {
        const ids = selectedComponentsIdsSelector(epicState);
        return ids.length === 1 ? ids[0] : null;
    },
    selectedComponentIdSelector = makeSelectedComponentIdSelector(selectedComponentsIdsSelector),
    userInteractionModeSelector = (state: EpicState) => userInteractionSelector(state).mode,
    componentsDependenciesSelector = (state: EpicState) => state.scope.componentsDependencies,
    userInteractionHandleKindSelector = (state: EpicState) => {
        const { payload } = userInteractionSelector(state);
        if (payload) {
            return payload.handleKind;
        } else {
            return null;
        }
    },
    adjustmentDataSelector = ({ scope: { componentsAdjustmentData } }: EpicState) => componentsAdjustmentData,
    userInteractionComponentsIdsSelector = (state: EpicState) => {
        const { payload } = userInteractionSelector(state);
        if (payload) {
            return payload.componentsIds;
        } else {
            return [];
        }
    },
    userInteractionComponentsIdsWithWrapSelector = (state: EpicState) => {
        const { payload } = userInteractionSelector(state);
        if (payload) {
            return (payload.componentsIds || []).concat(payload.wrappedIds);
        } else {
            return [];
        }
    },
    UserInteractionModeSelector = withSelector(valueActionType, userInteractionModeSelector),
    componentsMapSelector = ({ state: { componentsMap } }: EpicState) => componentsMap,
    componentsMapNoGhostsSelector = (epicState: EpicState) =>
        getComponentsMapNoGhosts(componentsMapSelector(epicState)),
    componentsMapNoGhostsBasesSelector = (epicState: EpicState) => {
        const attachments = getAttachments(epicState),
            getValidCmpMap = (cmpMap) => getComponentsMapForHoverBox(cmpMap, attachments);
        return R.pipe(
            componentsMapSelector,
            getValidCmpMap,
            getComponentsMapNoGhosts,
            getComponentsMapBases
        )(epicState);
    },
    getSelectedComponent = (state: EpicState) => {
        const selectedComponentId = selectedComponentIdSelector(state);
        if (!selectedComponentId) {
            return null;
        }

        return componentsMapSelector(state)[selectedComponentId];
    },
    contentDimensionsSelector = ({ scope: { contentBBox } }: EpicState) => getBBoxDimensionsMemoized(contentBBox),
    contentDimensionsFullActionMemoized = memo(
        (contentDimensions, updateReason) => ({ contentDimensions, updateReason })
    ),
    contentDimensionsSelectorFullAction =
        (action: Action<EpicState>) => {
            // @ts-ignore
            const { payload: { scope: { contentBBox } }, epicUpdateReason } = action;
            return contentDimensionsFullActionMemoized(getBBoxDimensionsMemoized(contentBBox), epicUpdateReason);
        },
    contentBBoxSelector = ({ scope: { contentBBox } }: EpicState) => contentBBox,
    snappingStateSelector = ({ scope: { snappingState } }: EpicState) => snappingState,
    componentsSelector = ({ state }: EpicState) => state,
    makeUserInteractionModePlusSelectedComponent = memoMaxOneCheckOneArg((userInteractionMode,
        componentsMap,
        selectedComponentsIds) => {
        if (selectedComponentsIds.length === 1) {
            return ({ userInteractionMode, selectedComponent: componentsMap[selectedComponentsIds[0]] });
        }

        return { userInteractionMode, selectedComponent: null };
    }),
    userInteractionModePlusSelectedComponentSelector = (state: EpicState) =>
        makeUserInteractionModePlusSelectedComponent(
            userInteractionModeSelector(state),
            componentsMapSelector(state),
            selectedComponentsIdsSelector(state)
        ),
    memoComponentsMapAndDeps = memoMaxOne(
        (componentsMap, componentsDependencies) => ({ componentsMap, componentsDependencies })
    ),
    componentsMapAndDepsSelector = (state: EpicState) =>
        memoComponentsMapAndDeps(
            componentsMapSelector(state),
            componentsDependenciesSelector(state)
        ),
    memoComponentsMapAndSelectedComponentId = memoMaxOne(
        (componentsMap, selectedComponentId) => ({ componentsMap, selectedComponentId })
    ),
    componentsMapAndSelectedComponentIdSelector = (state: EpicState) =>
        memoComponentsMapAndSelectedComponentId(
            componentsMapSelector(state),
            selectedComponentIdSelector(state)
        ),
    memoSelectedComponentsIdsNoGhosts = memoMaxOne((componentsMap, selectedComponentsIds) => {
        return selectedComponentsIds.filter(id => componentsMap[id]);
    }),
    returnSameReferenceForComponentsIdsNoGhosts = memoMaxDynamicNumOfArgs((...ids) => ids, 1),
    selectedComponentsIdsNoGhostsSelector = (state: EpicState) => {
        return returnSameReferenceForComponentsIdsNoGhosts(...memoSelectedComponentsIdsNoGhosts(
            componentsMapNoGhostsSelector(state),
            selectedComponentsIdsSelector(state)
        ));
    },
    selectedComponentIdNotGhostSelector = makeSelectedComponentIdSelector(selectedComponentsIdsNoGhostsSelector),
    memoGetUserInteractionSelectedComponentId = memoMaxOne((mode, selectedComponentIdNotGhost) =>
        ({ mode, selectedComponentIdNotGhost })),
    memoGetUserInteractionSelectedComponent = memoMaxOne((mode, selectedComponentNotGhost) =>
        ({ mode, selectedComponentNotGhost })),
    getUserInteractionAndSelectedComponentIdNoGhostsSelector = state => {
        const
            { mode } = userInteractionSelector(state),
            selectedComponentId = selectedComponentIdNotGhostSelector(state);

        return memoGetUserInteractionSelectedComponentId(mode, selectedComponentId);
    },
    getUserInteractionAndSelectedComponentNoGhostsSelector = (state: EpicState) => {
        const
            { mode } = userInteractionSelector(state),
            selectedComponentId = selectedComponentIdNotGhostSelector(state),
            selectedComponent = state.state.componentsMap[selectedComponentId];

        return memoGetUserInteractionSelectedComponent(mode, selectedComponent);
    },
    memoForDecos = memoMaxOne((componentsMapNoGhosts,
        selectedComponentsIdsNoGhosts,
        userInteractionMode,
        userInteractionComponentsIds,
        userInteractionHandleKind,
        snappingState) => (
        {
            componentsMapNoGhosts,
            selectedComponentsIdsNoGhosts,
            userInteractionMode,
            userInteractionComponentsIds,
            userInteractionHandleKind,
            snappingState
        }
    )),
    forDecosSelector = (state: EpicState) =>
        memoForDecos(
            componentsMapNoGhostsSelector(state),
            selectedComponentsIdsNoGhostsSelector(state),
            userInteractionModeSelector(state),
            userInteractionComponentsIdsSelector(state),
            userInteractionHandleKindSelector(state),
            snappingStateSelector(state),
        ),
    selectedComponentSelector = (state: EpicState) =>
        state.state.componentsMap[selectedComponentIdSelector(state)] || null,
    selectedComponentKindSelector = (state: EpicState) =>
        R.path(['kind'], selectedComponentSelector(state)) || null,
    selectedComponentsSelector = (state: EpicState) =>
        // $FlowFixMe
        selectedComponentsIdsSelector(state).map(id => state.state.componentsMap[id]),
    makeComponentWithDeps = memoMaxOne((component, dependencies) => ({ component, dependencies })),
    selectedComponentWithDepsSelector = (state: EpicState) => {
        const
            component = selectedComponentSelector(state),
            componentsDependencies = componentsDependenciesSelector(state);

        if (!component) {
            return null;
        }

        return makeComponentWithDeps(component, componentsDependencies[component.kind]);
    },
    selectedComponentDepsSelector = (state: EpicState) => {
        const
            component = selectedComponentSelector(state),
            componentsDependencies = componentsDependenciesSelector(state);

        if (!component) {
            return null;
        }

        return componentsDependencies[component.kind];
    },
    ForDecosSelector =
        withSelector(valueActionType, forDecosSelector),
    ComponentsMapAndDeps =
        withSelector(valueActionType, componentsMapAndDepsSelector),

    UserInteractionModePlusSelectedComponentSelector =
        withSelector(valueActionType, userInteractionModePlusSelectedComponentSelector),
    ReceiveOnlySelectedComponentSelector =
        receiveOnly(valueActionType, selectedComponentSelector),
    SelectedComponentSelector =
        withSelector(valueActionType, selectedComponentSelector),
    memoSelectedComponentAndEditModeComponentIdSelector = memoMaxOne((selectedComponent, editModeComponentId) => ({
        selectedComponent,
        editModeComponentId
    })),
    selectedComponentAndEditModeComponentIdSelector = (epicState) =>
        memoSelectedComponentAndEditModeComponentIdSelector(
            selectedComponentSelector(epicState),
            editModeComponentIdSelector(epicState)
        ),
    SelectedComponentAndEditModeComponentIdSelector =
        withSelector(valueActionType, selectedComponentAndEditModeComponentIdSelector),
    ResetSelectedComponentSelector =
        reset(valueActionType, selectedComponentSelector),
    ReceiveOnlySelectedComponentIdSelector =
        receiveOnly(valueActionType, selectedComponentIdSelector),
    ROSelectedComponentKindSelector =
        receiveOnly(valueActionType, selectedComponentKindSelector),
    SelectedComponentKindSelector =
        withSelector(valueActionType, selectedComponentKindSelector),
    SelectedComponentsSelector =
        withSelector(valueActionType, selectedComponentsSelector),
    ReceiveOnlySelectedComponentsSelector =
        receiveOnly(valueActionType, selectedComponentsSelector),
    ReceiveOnlySelectedComponentsSelectorFromFullAction =
        receiveOnly(valueActionType, withPayload(selectedComponentsSelector)),
    SelectedComponentWithDepsSelector = withSelector(valueActionType, selectedComponentWithDepsSelector),
    SelectedComponentDepsSelector = withSelector(valueActionType, selectedComponentDepsSelector),
    ReceiveOnlyComponentsMapAndUserInteractionModePlusComponentsIds =
        receiveOnly(valueActionType, userInteractionModePlusSelectedComponentSelector),
    ReceiveOnlyUserInteractionModeSelector = receiveOnly(valueActionType, userInteractionModeSelector),
    ReceiveOnlyComponentsMap = receiveOnly(valueActionType, componentsMapSelector),
    ReceiveOnlySectionInsertionState = withSelector(valueActionType, getSectionInsertion),
    ReceiveOnlyComponentsMapFromFullAction = receiveOnly(valueActionType, withPayload(componentsMapSelector)),
    ReceiveOnlyComponentsMapNoGhostsSelector = receiveOnly(valueActionType, componentsMapNoGhostsSelector),
    ReceiveOnlyComponentsMapNoGhostsFromFullActionSelector = receiveOnly(
        valueActionType, R.pipe(R.prop('payload'), componentsMapNoGhostsSelector)
    ),
    ComponentsMapSelector = withSelector(valueActionType, componentsMapSelector),
    ComponentsMapNoGhostBasesSelector = withSelector(valueActionType, componentsMapNoGhostsBasesSelector),
    ComponentsSelector = withSelector(valueActionType, componentsSelector),
    ReceiveOnlyComponents = receiveOnly(valueActionType, componentsSelector),
    ReceiveOnlyUserInteractionComponentsIds = receiveOnly(valueActionType, userInteractionComponentsIdsSelector),
    ReceiveOnlyUserInteractionComponentsIdsWithWrap =
        receiveOnly(valueActionType, userInteractionComponentsIdsWithWrapSelector),
    ReceiveOnlyUserInteractionHandleKind = receiveOnly(valueActionType, userInteractionHandleKindSelector),
    ReceiveOnlyAdjustmentData = receiveOnly(valueActionType, adjustmentDataSelector),
    ContentDimensionsSelector = withSelector(valueActionType, contentDimensionsSelector),
    ReceiveOnlyContentDimensionsSelector = receiveOnly(valueActionType, contentDimensionsSelector),
    ContentDimensionsSelectorFullAction = withSelector(valueActionType, contentDimensionsSelectorFullAction),
    ContentBBoxSelector = withSelector(valueActionType, contentBBoxSelector),
    SnappingStateSelector = withSelector(valueActionType, snappingStateSelector),
    ComponentsDependenciesSelector = withSelector(valueActionType, componentsDependenciesSelector),
    ReceiveOnlyComponentsDependenciesSelector = receiveOnly(valueActionType, componentsDependenciesSelector),
    ReceiveOnlySelectedComponentsIdsSelector = receiveOnly(valueActionType, selectedComponentsIdsSelector),
    ReceiveOnlySelectedComponentsIdsNoGhostsSelector =
        receiveOnly(valueActionType, selectedComponentsIdsNoGhostsSelector),
    ReceiveOnlySelectedComponentsIdsNoGhostsFromFullActionSelector =
        receiveOnly(valueActionType, R.pipe(R.prop('payload'), selectedComponentsIdsNoGhostsSelector)),
    SelectedComponentsIdsSelector = withSelector(valueActionType, selectedComponentsIdsSelector),
    SelectedComponentsIdsNoGhostsSelector =
        withSelector(valueActionType, selectedComponentsIdsNoGhostsSelector),
    SelectedComponentIdSelector = withSelector(valueActionType, selectedComponentIdSelector),
    UserInteractionModeSelectedComponentNoGhostsSelector =
        withSelector(valueActionType, getUserInteractionAndSelectedComponentNoGhostsSelector),
    ComponentsMapAndSelectedComponentIdSelector =
        withSelector(valueActionType, componentsMapAndSelectedComponentIdSelector),

    getComponentsEvalPartsMemoized = memoMaxOne((componentsMapNoGhosts, selectedComponentsIdsNoGhosts,
        interactionMode) => ({
        componentsMapNoGhosts,
        selectedComponentsIdsNoGhosts,
        interactionMode
    })),
    componentsEvalPartsSelector = (epicState: ComponentsEvalState) => getComponentsEvalPartsMemoized(
        componentsMapNoGhostsSelector(epicState),
        selectedComponentsIdsNoGhostsSelector(epicState),
        userInteractionModeSelector(epicState)
    ),
    ForHandlesSelector = withSelector(valueActionType, componentsEvalPartsSelector),
    ReceiveOnlyUserInteractionModeSelectedComponentIdSelector = receiveOnly(valueActionType, getUserInteractionAndSelectedComponentIdNoGhostsSelector), // eslint-disable-line max-len
    EditModeComponentIdSelector = withSelector(valueActionType, editModeComponentIdSelector),
    EditModeComponentSelector = withSelector(valueActionType, editModeComponentSelector),
    MakeEditModeComponentSelectorByKind = (kind: string) => withSelector(valueActionType, makeEditModeComponentSelectorByKind(kind)),
    OptionalResetEditModeComponentIdSelector = optionalResetReceiveOnly(valueActionType, editModeComponentIdSelector),
    ROEditModeComponentIdSelector = receiveOnly(valueActionType, editModeComponentIdSelector),
    ROEditModeComponentIdSelectorFromFullAction = receiveOnly(
        valueActionType,
        R.pipe(R.prop('payload'), editModeComponentIdSelector)
    ),
    UserInteractionSelector = withSelector(valueActionType, userInteractionSelector),

    ComponentsMovedByMouseOrKeyboardOrMovedToTemplateSelector =
        withSelector(
            valueActionType,
            (action) => {
                const
                    epicState: EpicState = action.payload,
                    updateReason = action.epicUpdateReason,
                    userInteractionMode = userInteractionModeSelector(epicState);

                if (
                    !isTransientUserInteractionMode(userInteractionMode) && (
                        updateReason === updateReasons.MOVED_BY_KEYBOARD ||
                        updateReason === updateReasons.MOVED_BY_MOUSE ||
                        updateReason === updateReasons.MOVED_TO_TEMPLATE_OR_PAGE ||
                        updateReason === updateReasons.ORDER_CHANGED
                    )
                ) {
                    return Date.now();
                }
                return false;
            }
        ),
    ResizedComponentIdsSelector =
        withSelector(valueActionType, (action) => action.changedDimensionsComponentIds || emptyArray),
    ComponentWidthChangedFromPropertiesPanel = when(
        COMPONENT_DIMENSIONS_CHANGED_FROM_PP,
        a => a.payload.prop === 'width'
    ),
    scopeSelector = ({ scope }: EpicState) => scope,
    ReceiveOnlyMHFCmpsData = receiveOnly(valueActionType, getMHFCmpsData);

/* TODO WBTGEN-4490 Rename lower case 'componentsSelector' to 'selectComponents' */
export {
    getSelectedComponent,
    componentsSelector,
    componentsDependenciesSelector,
    ForDecosSelector,
    ComponentsMapAndDeps,
    selectedComponentsSelector,
    ReceiveOnlyComponentsMapAndUserInteractionModePlusComponentsIds,
    ReceiveOnlyUserInteractionModeSelectedComponentIdSelector,
    UserInteractionModeSelector,
    ReceiveOnlyUserInteractionModeSelector,
    ReceiveOnlyUserInteractionComponentsIds,
    ReceiveOnlyUserInteractionComponentsIdsWithWrap,
    ComponentsMapSelector,
    ComponentsMapNoGhostBasesSelector,
    ComponentsSelector,
    ReceiveOnlyComponentsMap,
    ReceiveOnlyComponentsMapNoGhostsSelector,
    ReceiveOnlyComponentsMapNoGhostsFromFullActionSelector,
    ReceiveOnlyComponentsMapFromFullAction,
    ReceiveOnlyAdjustmentData,
    ReceiveOnlyComponents,
    SelectedComponentsIdsSelector,
    ReceiveOnlySelectedComponentsIdsSelector,
    ContentDimensionsSelector,
    ReceiveOnlyContentDimensionsSelector,
    ContentDimensionsSelectorFullAction,
    UserInteractionModePlusSelectedComponentSelector,
    ContentBBoxSelector,
    contentDimensionsSelector,
    SnappingStateSelector,
    selectedComponentsIdsSelector,
    ReceiveOnlySelectedComponentSelector,
    SelectedComponentSelector,
    componentsMapSelector,
    UserInteractionModeSelectedComponentNoGhostsSelector,
    ReceiveOnlyUserInteractionHandleKind,
    SelectedComponentWithDepsSelector,
    SelectedComponentDepsSelector,
    ComponentsDependenciesSelector,
    ReceiveOnlyComponentsDependenciesSelector,
    SelectedComponentIdSelector,
    SelectedComponentsSelector,
    ReceiveOnlySelectedComponentsSelector,
    ReceiveOnlySelectedComponentsSelectorFromFullAction,
    ReceiveOnlySelectedComponentIdSelector,
    ResetSelectedComponentSelector,
    selectedComponentIdSelector,
    userInteractionModeSelector,
    selectedComponentSelector,
    componentsMapNoGhostsSelector,
    selectedComponentsIdsNoGhostsSelector,
    SelectedComponentsIdsNoGhostsSelector,
    ReceiveOnlySelectedComponentsIdsNoGhostsSelector,
    ReceiveOnlySelectedComponentsIdsNoGhostsFromFullActionSelector,
    ComponentsMapAndSelectedComponentIdSelector,
    ForHandlesSelector,
    EditModeComponentIdSelector,
    EditModeComponentSelector,
    MakeEditModeComponentSelectorByKind,
    OptionalResetEditModeComponentIdSelector,
    ROEditModeComponentIdSelector,
    SelectedComponentKindSelector,
    ROSelectedComponentKindSelector,
    SelectedComponentAndEditModeComponentIdSelector,
    ROEditModeComponentIdSelectorFromFullAction,
    UserInteractionSelector,
    ComponentsMovedByMouseOrKeyboardOrMovedToTemplateSelector,
    ResizedComponentIdsSelector,
    ReceiveOnlySectionInsertionState,
    scopeSelector,
    ReceiveOnlyMHFCmpsData,
    ComponentWidthChangedFromPropertiesPanel
};
