import * as R from "ramda";
import * as actionTypes from "../../../../epics/undoManager/actionTypes";

const getAffectedEpics = (stackIndex, commandsStack) => {
    let affectedEpicsVATs: string[] = [];
    for (let i = stackIndex; i >= 0; i--) {
        affectedEpicsVATs = [...affectedEpicsVATs, ...(Object.keys(commandsStack[R.identity(i)].affectedEpics))];
    }

    const affectedEpics = {};
    affectedEpicsVATs.forEach(affectedVat => {
        for (let i = stackIndex; i >= 0; i--) {
            const affectedEpic = commandsStack[i].affectedEpics[affectedVat];
            if (affectedEpic) {
                affectedEpics[affectedVat] = affectedEpic;
                break;
            }
        }
    });

    return affectedEpics;
};
export const
    clearUndoCommandStack = {
        conditions: [actionTypes.CLEAR_UNDO_STACK_WITH_LATEST_ENTRY],
        reducer: ({ state }: Record<string, any>) => {
            const { commandsStack } = state.undoState;

            if (!commandsStack.length) { return { state }; }

            const newCommandStack = [commandsStack[commandsStack.length - 1]];
            const newCommandsStackIndex = 0;

            return {
                state: {
                    ...state,
                    undoState: {
                        ...state.undoState,
                        commandsStack: newCommandStack,
                        commandsStackIndex: newCommandsStackIndex,
                    }
                },
                updateReason: actionTypes.CLEAR_UNDO_STACK_WITH_LATEST_ENTRY
            };
        }
    },
    resetUndoStackAndApplyChange = {
        conditions: [actionTypes.RESET_UNDO_STACK_INDEX_AND_APPLY],
        reducer: ({ state, values: [commandsStackIndex] }: Record<string, any>) => {
            const newCommandsStackIndex: number = commandsStackIndex; // needed to make flow happy
            const { commandsStack } = state.undoState;
            const isCommandStackNotEmpty = commandsStack.length !== 0;
            const isCommandStackIndexExist = newCommandsStackIndex >= 0
                && newCommandsStackIndex <= (commandsStack.length - 1);

            if (isCommandStackNotEmpty && isCommandStackIndexExist) {
                const newCommandStack = commandsStack.slice(0, newCommandsStackIndex + 1);
                const affectedEpics = getAffectedEpics(newCommandsStackIndex, newCommandStack);
                const undoOrRedoAction = {
                    type: actionTypes.UNDO,
                    payload: { affectedEpics },
                    updateReason: actionTypes.UNDO
                };
                return {
                    state,
                    multipleActionsToDispatch: [
                        undoOrRedoAction,
                        { type: actionTypes.RESET_UNDO_STACK_INDEX_AND_FLUSH, payload: newCommandsStackIndex }
                    ],
                    updateReason: actionTypes.RESET_UNDO_STACK_INDEX_AND_APPLY
                };
            }
            return { state, multipleActionsToDispatch: [] };
        }
    };
