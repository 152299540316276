import view from "./view/View";
import kind from "./kind";
import calcProps from './calcProps';
import './view/mobile.css';

export default {
    kind,
    view,
    calcProps
};
