import { WidgetsEventbriteKind } from './kind';
import { componentMainActions } from './componentMainAction';
import { propertiesPanel } from './propertiesPanel/index';
import { reducer } from './reducer';
import { configurationDialogId } from './configurationDialog';
import { calcRenderProps } from './calcRenderProps';
import { EventbriteWorkspace } from './view/workspace';
import { EventbriteView } from './view/index';
import { getMVEHiddenElementData } from '../mobileEditorConfig';
import { WidgetsEventbriteConfig } from '../constants';

const WidgetsEventbrite = {
    kind: WidgetsEventbriteKind,
    label: WidgetsEventbriteConfig.title,
    shortcutIconName: 'widgetsEventbrite',
    workspace: EventbriteWorkspace,
    view: EventbriteView,
    requireConfigurationOnDrop: () => true,
    configurationDialog: configurationDialogId,
    calcRenderProps,
    componentMainActions,
    propertiesPanel,
    reducer,
    mobileEditorConfig: {
        kind: WidgetsEventbriteKind,
        calcProps: calcRenderProps,
        view: EventbriteView,
        getHiddenElementData: getMVEHiddenElementData(WidgetsEventbriteConfig),
    },
};

export { WidgetsEventbrite };

