import makeEpic from '../../../../epics/makeEpic';
import { isLeftMouseDownVAT } from './valueActionType';
import { APP_LEFT_MOUSE_DOWN, WINDOW_MOUSE_UP } from "../../../App/actionTypes";

const defaultState = false;

export const isLeftMouseDownEpic = makeEpic({
    defaultState,
    valueActionType: isLeftMouseDownVAT,
    updaters: [
        {
            conditions: [APP_LEFT_MOUSE_DOWN],
            reducer: () => ({ state: true })
        },
        {
            conditions: [WINDOW_MOUSE_UP],
            reducer: () => ({ state: false })
        }
    ]
});
