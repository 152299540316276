import { getUpdatedEpicsState } from './updateManager';

import * as undoHelpers from './undoManager/helpers';
import type {
    Epic,
    MakeEpicConfig,
    AdjustAfterUpdate,
    AdjustAfterUpdateProps, EpicUpdater
} from './flowTypes';
import type { AppState } from "../redux/modules/flowTypes";
import { anyOf } from "./makeCondition";
import { makeEpicStateSelector as makeEpicStateSelectorFunc } from "./makeEpicStateSelector";

// @ts-ignore
function makeReducer(defaultState?: AnyValue, valueActionType: string) {
    return (state: AnyValue, action: AnyAction) => {
        if (state === undefined) {
            return defaultState === undefined ? null : defaultState;
        }

        if (action.type === valueActionType) {
            return action.payload;
        }

        if (action.epicsUpdate && action.epicsUpdate.updatedEpicsTypes[valueActionType]) {
            const newEpicsState = getUpdatedEpicsState(action);

            return newEpicsState[valueActionType].state;
        }

        return state;
    };
}

type AnyAdjustHookProps = AdjustAfterUpdateProps<AnyValue, AnyValue, AnyValue>;
type AnyAdjustHook = AdjustAfterUpdate<AnyValue, AnyValue, AnyValue>;

const
    pipeAfterUpdate = (...hooks: Array<AnyAdjustHook>) => (props: AnyAdjustHookProps) => hooks.reduce(
        // @ts-ignore
        (acc, hook) => {
            const { state, scope, afterUpdateActions, addCustomFieldToEpicChangeAction } = hook({
                ...props,
                nextState: acc.state,
                nextScope: acc.scope,
            });

            return {
                state,
                scope,
                afterUpdateActions: [...acc.afterUpdateActions, ...(afterUpdateActions || [])],
                addCustomFieldToEpicChangeAction: {
                    ...acc.addCustomFieldToEpicChangeAction,
                    ...(addCustomFieldToEpicChangeAction || {})
                },
            };
        },
        {
            state: props.nextState,
            scope: props.nextScope,
            afterUpdateActions: [],
            addCustomFieldToEpicChangeAction: {}
        }
    ),
    fullEpicUndoablePath = [[]],
    appendUndoUpdaters = ({ updaters, undo, valueActionType }) => {
        const {
            undoEpicUpdater,
            redoEpicUpdater
        } = undoHelpers.makeUndoRedoUpdaters({
            valueActionType,
            undoablePaths: undo === true ? fullEpicUndoablePath : undo.undoablePaths,
            undoablePathsSetter: undo === true ? undefined : undo.undoablePathsSetter,
        });

        return [
            ...updaters,
            undo === true ? undoEpicUpdater : (undo.undoEpicUpdater || undoEpicUpdater),
            undo === true ? redoEpicUpdater : (undo.redoEpicUpdater || redoEpicUpdater)
        ];
    },
    /**
     * TODO: MakeEpicConfig<any, any, any>) should be MakeEpicConfig<*, *, *>)
     *      refer to WBTGEN-2851
     */
    makeEpic = ({
        defaultState,
        defaultScope,
        valueActionType,
        updaters,
        handleActions,
        dispatchOutside,
        afterUpdate,
        undo,
        saveIntoSiteSettings
    }: MakeEpicConfig<any, any, any>): Epic<any, any, any> => {
        let finalUpdaters = updaters || [];
        if (handleActions) {
            finalUpdaters = [
                ...finalUpdaters,
                ...Object.keys(handleActions).map(actionType => ({
                    keepFullActions: true,
                    conditions: [actionType],
                    reducer: ({ state, scope, values: [action] }) => ({
                        state: handleActions[actionType](state, action),
                        scope,
                        updateReason: actionType
                    })
                }))
            ];
        }
        const setExecuteImmediately = (updater, index: number) => {
            const liveConditionsCount = updater.conditions.filter(c => {
                if (typeof c === 'string') {
                    return true;
                }
                return !c.receiveOnly;
            }).length;
            if (liveConditionsCount === 0) {
                throw new Error(`${valueActionType} updater index ${index} has no live conditions`);
            }

            return {
                ...updater,
                executeImmediately: liveConditionsCount === 1
            };
        };
        finalUpdaters = undo ? appendUndoUpdaters({ updaters: finalUpdaters, undo, valueActionType }) : finalUpdaters;
        finalUpdaters = finalUpdaters.map(setExecuteImmediately);

        return (
            {
                saveIntoSiteSettings,
                undo,
                reducer: makeReducer(defaultState, valueActionType),
                defaultState,
                defaultScope,
                valueActionType,
                updaters: finalUpdaters,
                dispatchOutside,
                afterUpdate
            }
        );
    },
    makeEpicStateSelector = makeEpicStateSelectorFunc,
    makeEpicScopeSelector = (vat: string) => (appState: AppState) => appState.epics[vat].scope,
    makeForwardActionUpdater =
        (triggerActionType: string | Array<string>, targetActionType: string): EpicUpdater<any, any, any, any> => (
            {
                conditions: [Array.isArray(triggerActionType) ? anyOf(...triggerActionType) : triggerActionType],
                reducer: ({ state, scope, values: [payload] }) => {
                    return {
                        state,
                        scope,
                        actionToDispatch: ({ type: targetActionType, payload } as AnyAction)
                    };
                }
            }
        );

/*
 Case for using epic is
 - piece of data is use in more than one component
 - calculation of this piece of data is depends on more than one action
 */

export {
    makeForwardActionUpdater,
    makeReducer,
    fullEpicUndoablePath,
    pipeAfterUpdate,
    makeEpicStateSelector,
    makeEpicScopeSelector,
    makeEpic as default,
    makeEpic,
};
