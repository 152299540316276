import {
    makeCombineReducer,
    makeDefaultStateReducers,
    makeComponentBaseReducers
} from "../../../../redux/makeReducer/index";
import { toggleValue } from '../../../../utils/ramdaEx';
import { defaultState, DEFAULT_WIDTH, DEFAULT_HEIGHT, TABS_TIMELINE } from "../constants";
import { TOGGLE_MOBILE_DOWN_ACTION } from "../../../PropertiesPanel/view/MobileView/actions";
import mobileDownReducer from '../../../PropertiesPanel/view/MobileView/mobileDownReducer';
import * as actionTypes from "../actionTypes";
import type { FacebookPageComponent } from '../flowTypes';
import kind from '../kind';

const
    updatePageURL = (component: FacebookPageComponent, { payload: pageURL }: AnyValue): FacebookPageComponent => ({
        ...component, pageURL
    }),
    toggleShowFriendsFaces = (component: FacebookPageComponent): FacebookPageComponent => toggleValue(['showFacepile'])(component),
    toggleUseSmallHeader = (component: FacebookPageComponent) => toggleValue(['smallHeader'])(component),
    toggleShowCover = (component: FacebookPageComponent) => toggleValue(['hideCover'])(component),
    toggleShowPagePosts = (component: FacebookPageComponent) => ({
        ...component,
        tabs: component.tabs.length ? [] : [TABS_TIMELINE]
    });

export default makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(kind, DEFAULT_WIDTH, DEFAULT_HEIGHT),
        ...makeDefaultStateReducers(defaultState)
    },
    handleActions: {
        [TOGGLE_MOBILE_DOWN_ACTION]: mobileDownReducer,
        [actionTypes.FACEBOOKPAGE_UPDATE_PAGE_URL]: updatePageURL,
        [actionTypes.FACEBOOKPAGE_TOGGLE_SHOW_FRIENDS_FACES]: toggleShowFriendsFaces,
        [actionTypes.FACEBOOKPAGE_TOGGLE_USE_SMALL_HEADER]: toggleUseSmallHeader,
        [actionTypes.FACEBOOKPAGE_TOGGLE_SHOW_COVER]: toggleShowCover,
        [actionTypes.FACEBOOKPAGE_TOGGLE_SHOW_PAGE_POSTS]: toggleShowPagePosts
    }
});
