import Resource from "../Resource";

type Params = {
    resourceName: string,
    resources: Array<Resource>,
    resourcePatch?: Record<string, any>,
};

type ReturnType = {
    resources: Array<Resource>,
    currentResource: Resource,
}

export const unghostResourceByName = ({ resourceName, resources, resourcePatch = {} }: Params): ReturnType => {
    let resource: Resource | null = null;
    const nextResources = resources.map((res: Resource) => {
        if (res.getName() === resourceName) {
            resource = new Resource(res.basePath, { ...res, isGhost: false, isLoading: false, ...resourcePatch });
            return resource;
        }
        return res;
    });
    if (!resource) throw new Error(`Cannot find resource '${resourceName}' to unghost`);

    return {
        resources: nextResources,
        currentResource: resource
    };
};
