import type { ContactFormComponent } from "../flowTypes";

export default (
    component: ContactFormComponent, { payload: { stylesheetId, stylesheetName } }: any
): ContactFormComponent => {
    return {
        ...component,
        fileUploadButtonStyle: {
            ...component.styleButton,
            globalName: stylesheetName,
            globalId: stylesheetId
        }
    };
};
