import type { DarkColorType, LightColorType, MultiColorType, ThemeDefaultType } from './flowTypes';
import { SIZE_TYPES } from "../../../constants";

const DARK: DarkColorType = 'DARK';
const LIGHT: LightColorType = 'LIGHT';
const MULTI_COLOR: MultiColorType = 'MULTI_COLOR';

export const SocialColorSourceType = {
    [MULTI_COLOR]: MULTI_COLOR,
    [DARK]: DARK,
    [LIGHT]: LIGHT
};

export const SocialStyleType = {
    REGULAR: 'REGULAR',

    CIRCLE_FILL: 'CIRCLE_FILL',
    CIRCLE_KNOCKOUT: 'CIRCLE_KNOCKOUT',

    SQUARE_FILL: 'SQUARE_FILL',
    SQUARE_KNOCKOUT: 'SQUARE_KNOCKOUT',

    ROUNDED_FILL: 'ROUNDED_FILL',
    ROUNDED_KNOCKOUT: 'ROUNDED_KNOCKOUT',
};

export const MHFMinSpaceBetweenSocialLinkIcons = 28;

export const THEME_DEFAULT: ThemeDefaultType = 'THEME_DEFAULT';

export const MCTA_RESIZE_OPTIONS = {
    [SIZE_TYPES.SIZE_SMALL]: { defaultSize: 20, mobileSize: 32 },
    [SIZE_TYPES.SIZE_MEDIUM]: { defaultSize: 24, mobileSize: 40 },
    [SIZE_TYPES.SIZE_LARGE]: { defaultSize: 32, mobileSize: 50 }
};
