import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { LINE_HEIGHT, LINE_HEIGHT_XS, SWITCH_BASE_PADDING } from "./constants";
import { CustomSwitchProps } from "./types";

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        height: (props: CustomSwitchProps) => props.height,
        width: (props: CustomSwitchProps) => props.width,
        margin: (props: CustomSwitchProps) => `${(LINE_HEIGHT - props.height) / 2}px 0px`,
        [theme.breakpoints.down('sm')]: {
            margin: (props: CustomSwitchProps) => `${(LINE_HEIGHT_XS - props.height) / 2}px 0px`,
        },
        padding: 0,
    },
    // TODO: apply css from props
    switchBase: {
        padding: SWITCH_BASE_PADDING,
        '&.Mui-checked': {
            color: theme.palette.common.white,
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.custom.switch || theme.palette.primary.main,
                opacity: 1,
            },
        }
    },
    thumb: {
        width: (props: CustomSwitchProps) => props.height - (2 * SWITCH_BASE_PADDING),
        height: (props: CustomSwitchProps) => props.height - (2 * SWITCH_BASE_PADDING),
        boxShadow: "none",
    },
    track: {
        borderRadius: (props: CustomSwitchProps) => props.width / 2,
        border: `1px solid ${theme.palette.custom.colorGray_3c}`,
        background: (props: CustomSwitchProps) => props.backgroundColorDisabled,
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {
        transform: (props) => `translateX(${props.width - props.height}px) !important`,
    },
    focusVisible: {},
    formControlLabel: {
        display: "flex",
        alignItems: "unset",
        margin: 0,
        '& > span:not(:first-of-type)': {
            paddingLeft: (props: CustomSwitchProps) => props.labelPadding || theme.spacing(2),
            fontSize: (props: CustomSwitchProps) => props.labelFontSize || "20px",
            lineHeight: `${LINE_HEIGHT}px`,
            [theme.breakpoints.down('sm')]: {
                fontSize: "18px",
                lineHeight: `${LINE_HEIGHT_XS}px`,
            },
        },
    }
}));
