import type { PageInfoTabId } from "./flowTypes";
import { inputOnChange } from "../../../../inputControls/input/actionCreator";
import { openDialog } from "../../../../App/actionCreators";
import { PAGE_INFO_UNSAVED_CHANGES_DIALOG_ID } from "./unsavedChangesDialog/dialogId";
import type { PageInfoUnsavedChangesDialogProps } from "./unsavedChangesDialog/dialog";

export const
    PAGE_INFO_URL_CHANGE = 'PAGE_INFO_URL_CHANGE',
    PAGE_INFO_EXCLUDE_FROM_INDEXING_TOGGLE = 'PAGE_INFO_EXCLUDE_FROM_INDEXING_TOGGLE',
    PAGE_INFO_DESCRIPTION_CHANGE = 'PAGE_INFO_DESCRIPTION_CHANGE',
    PAGE_INFO_HIDDEN_TOGGLE = 'PAGE_INFO_HIDDEN_TOGGLE',
    PAGE_INFO_PUBLISHED_TOGGLE = 'PAGE_INFO_PUBLISHED_TOGGLE',
    PAGE_INFO_IS_HOME_PAGE_TOGGLE = 'PAGE_INFO_IS_HOME_PAGE_TOGGLE',
    PAGE_INFO_SEO_PAGE_NAME_IN_BROWSER_TAB_TOGGLE = 'PAGE_INFO_SEO_PAGE_NAME_IN_BROWSER_TAB_TOGGLE',
    PAGE_INFO_SOCIAL_SHARE_ASSET_ADDED = 'PAGE_INFO_SOCIAL_SHARE_ASSET_ADDED',
    PAGE_INFO_SOCIAL_SHARE_ASSET_REMOVED = 'PAGE_INFO_SOCIAL_SHARE_ASSET_REMOVED';

export const
    SWITCH_PAGE_INFO_TAB_ACTION = 'SWITCH_PAGE_INFO_TAB_ACTION',
    switchPageInfoTabAction = (id: PageInfoTabId) => ({
        type: SWITCH_PAGE_INFO_TAB_ACTION,
        payload: id
    });

export const
    PAGE_INFO_TITLE_CHANGE = 'PAGE_INFO_TITLE_CHANGE',
    pagInfoTitleChangeAction = (title: string) => inputOnChange(PAGE_INFO_TITLE_CHANGE, title);

export const
    PAGE_INFO_CUSTOM_TITLE_CHANGE = 'PAGE_INFO_CUSTOM_TITLE_CHANGE',
    pagInfoCustomTitleChangeAction = (customTitle: string) => inputOnChange(PAGE_INFO_CUSTOM_TITLE_CHANGE, customTitle);

export const
    PAGE_INFO_NAME_CHANGE = 'PAGE_INFO_NAME_CHANGE',
    pageInfoNameChangeAction = (name: string) => inputOnChange(PAGE_INFO_NAME_CHANGE, name);

export type SavePageInfoActionParams = {
    pageRefId: string,
    keepDialogOpenOnSave?: boolean,
};

export const
    PAGE_INFO_SAVE = 'PAGE_INFO_SAVE',
    savePageInfoAction = (params: SavePageInfoActionParams) => ({
        type: PAGE_INFO_SAVE,
        payload: params
    });

export const
    CLOSE_PAGE_INFO_DIALOG_ACTION = 'CLOSE_PAGE_INFO_DIALOG_ACTION',
    closePageInfoDialogAction = (pageRefId: string) => ({
        type: CLOSE_PAGE_INFO_DIALOG_ACTION,
        payload: pageRefId,
    });

export const
    NAVIGATE_PAGE_INFO_TO_SITE_SETTINGS_ACTION = 'NAVIGATE_PAGE_INFO_TO_SITE_SETTINGS_ACTION',
    navigatePageInfoToSiteSettingsAction = (pageRefId: string) => ({
        type: NAVIGATE_PAGE_INFO_TO_SITE_SETTINGS_ACTION,
        payload: pageRefId,
    });

export const
    openPageInfoUnsavedChangesDialogAction = (props: PageInfoUnsavedChangesDialogProps) =>
        openDialog(PAGE_INFO_UNSAVED_CHANGES_DIALOG_ID, props);

export const
    SAVE_PAGE_INFO_UNSAVED_CHANGES_ACTION = 'SAVE_PAGE_INFO_CHANGES_ACTION',
    savePageInfoUnsavedChangesAction = (pageRefId: string) => ({
        type: SAVE_PAGE_INFO_UNSAVED_CHANGES_ACTION,
        payload: pageRefId
    });

export const
    DONT_SAVE_PAGE_INFO_UNSAVED_CHANGES_ACTION = 'DONT_SAVE_PAGE_INFO_CHANGES_ACTION',
    dontSavePageInfoUnsavedChangesAction = () => ({
        type: DONT_SAVE_PAGE_INFO_UNSAVED_CHANGES_ACTION
    });

export const
    CANCEL_PAGE_INFO_CHANGES_ACTION = 'CANCEL_PAGE_INFO_CHANGES_ACTION',
    cancelPageInfoChangesAction = () => ({
        type: CANCEL_PAGE_INFO_CHANGES_ACTION
    });

export const changePageInfoUrlAction = (value: string, params: Record<string, any> | null = null) =>
    // @ts-ignore
    inputOnChange(PAGE_INFO_URL_CHANGE, value, params);
