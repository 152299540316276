
import { setComponentsMap } from "../../Workspace/epics/componentsEval/setters";
import { WRAP_IMG_TEXT_IN_BOX } from "./actions";
import { getCmps } from "../../Workspace/epics/componentAttachements/util";
import { ReceiveOnlyTemplateWidthActionType } from "../../oneweb/Template/epics/template/selectorActionTypes";
import { updateComponentsRelIns } from "../../Workspace/epics/relations/updateComponentsRelIns";
import { isBoxKind, isSectionKind } from "../../oneweb/componentKinds";
import { getParentChildrenMap } from "../../Workspace/epics/componentsEval/userInteractionMutations/getParentChildrenMap";
import makeUuid from "../../../convertToSections/makeUuid";
import { ImageKind } from "../../oneweb/Image/kind";
import { TextComponentKind } from "../../oneweb/Text/kind";

export default {
    conditions: [
        ReceiveOnlyTemplateWidthActionType,
        WRAP_IMG_TEXT_IN_BOX
    ],
    reducer: ({ values: [templateWidth], state: epicState }) => {
        let { state: { componentsMap } } = epicState,
            newComponentsMap = { ...componentsMap };

        const cmps = getCmps(newComponentsMap);
        const pageSections = cmps.filter(x => isSectionKind(x.kind) && !x.inTemplate);
        const parentChildrenMap = getParentChildrenMap(cmps);
        const simpleTopSorter = (a, b) => newComponentsMap[a].top - newComponentsMap[b].top;

        const groupCmpsByTop = (items) => {
            let cmpsIdList = [...items].sort(simpleTopSorter),
                groups: Array<any> = [],
                group: Array<any> = [],
                prevBottom = 0;
            const addToGroups = () => {
                if (group.length && group.filter(cmp => cmp.kind === ImageKind).length) {
                    groups.push(group);
                } else if (group.length && !group.filter(cmp => cmp.kind !== TextComponentKind).length) {
                    // If image is not present and only text is present, then putting all components in previous group
                    const prevGroup = groups.pop() || [];
                    prevGroup.push(...group);
                    groups.push(prevGroup);
                }
                group = [];
            };
            cmpsIdList.forEach((cmpId) => {
                let cmp = newComponentsMap[cmpId];
                if (cmp.top >= prevBottom) {
                    addToGroups();
                }
                group.push(cmp);
                prevBottom = Math.max(prevBottom, (cmp.top + cmp.height));
            });
            addToGroups();
            return groups;
        };

        pageSections.forEach(pageSection => {
            const { id: pageSectionId } = pageSection;
            const pageSectionChildren = parentChildrenMap[pageSectionId];
            let boxContainers = pageSectionChildren.filter(x => isBoxKind(newComponentsMap[x].kind));
            boxContainers.forEach(containerCmpId => {
                let childrentoBox = parentChildrenMap[containerCmpId];
                let groupedCmps = groupCmpsByTop(childrentoBox);
                groupedCmps.forEach((group: any) => {
                    const firstCmp = group[0];
                    let bbox = {
                        left: firstCmp.left || 0,
                        top: firstCmp.top || 0,
                        right: firstCmp.left + firstCmp.width || 0,
                        bottom: firstCmp.top + firstCmp.height || 0
                    };
                    if (group.length > 1) {
                        group.forEach(cmp => {
                            bbox.top = Math.min(bbox.top, cmp.top);
                            bbox.left = Math.min(bbox.left, cmp.left);
                            bbox.bottom = Math.max(bbox.bottom, cmp.top + cmp.height);
                            bbox.right = Math.max(bbox.right, cmp.left + cmp.width);
                        });

                        const newBoxComponent = {
                            ...newComponentsMap[containerCmpId],
                            id: makeUuid(),
                            left: bbox.left,
                            top: bbox.top,
                            width: bbox.right - bbox.left,
                            height: bbox.bottom - bbox.top
                        };
                        newComponentsMap[newBoxComponent.id] = newBoxComponent;
                    }
                });
            });
        });

        newComponentsMap = updateComponentsRelIns(newComponentsMap, templateWidth);

        return {
            state: setComponentsMap(newComponentsMap, epicState),
            updateReason: WRAP_IMG_TEXT_IN_BOX
        };
    }
};
