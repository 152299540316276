import type { Position } from "../../redux/modules/flowTypes";
import { WINDOW_MOUSE_MOVE } from "../App/actionTypes";

export default (state: Position = { x: 0, y: 0 }, action: AnyAction) => {
    if (action.type === WINDOW_MOUSE_MOVE) {
        return action.payload;
    }

    return state;
};
