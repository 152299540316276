import React from 'react';
import type { FeaturedProductsViewProps } from '../flowTypes';
import { FrameComponent } from './FrameComponent';
import MVEFrameComponent from './MVEFrameComponent';
import getHTMLCode from './getHTMLCode';

const getGeneratedView = (viewProps: FeaturedProductsViewProps) => {
    const htmlCode = getHTMLCode(viewProps);
    return (<div className="web-widget" dangerouslySetInnerHTML={{ __html: htmlCode }} />);
};

const getPreview = (viewProps: FeaturedProductsViewProps) => {
    if (viewProps.isMVEFocus) {
        return <MVEFrameComponent {...viewProps} />;
    }
    return <FrameComponent {...viewProps} />;
};

const FeaturedProductsView = (
    viewProps: FeaturedProductsViewProps
) => {
    const { isServerPreview } = viewProps;
    if (isServerPreview) { // is published page
        return getGeneratedView(viewProps);
    }
    return getPreview(viewProps);
};

export default FeaturedProductsView;
