import * as main from "./pages/main";
import * as format from "./pages/format";
import * as style from "./pages/style";
import * as mobileView from "../../../PropertiesPanel/view/MobileView/page";
import makePages from "../../../PropertiesPanel/view/makePages";
import addGoogleFontView from "../../../PropertiesPanel/view/AddGoogleFont/index";

export const openingHoursPPConfig = {
    pages: makePages(main, format, style, mobileView, addGoogleFontView),
    defaultPageId: main.id
};
