import SectionKind from './Section/kind';
import type { AnyComponent } from "./flowTypes";

const map = {
    [SectionKind]: true
};

const
    // @ts-ignore
    isSectionComponent = (cmp: AnyComponent) => map.hasOwnProperty(cmp.kind);

export {
    isSectionComponent
};
