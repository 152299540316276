import React from 'react';
import styles from "./styles.css";
import { Msg, Intl } from "../../../../view/intl/index";
import { LOCATION_RELOAD } from "../../../../redux/middleware/window/actionTypes";

type ErrorMessageProps = {
    intl: Intl;
    dispatch: Function;
}

class SectionBlockErrorMessage extends React.Component<ErrorMessageProps> {
    render() {
        const { intl, dispatch } = this.props;
        const reloadThePageLink = (<a onClick={() => dispatch({ type: LOCATION_RELOAD })} className={styles.textSectionsErrorTextLink}>
            {intl.msgJoint('msg: common.reloadThePage {Reload the page}')}
        </a>);
        return (
            <div className={styles.textSectionsErrorText}>
                <Msg k="component.textInserter.textSectionErrorMsg" params={{ reloadThePageLink }}>
                    {`
                        We could not load the text sections.
                        Please {reloadThePageLink} and try again.
                        `}
                </Msg>
            </div>
        );
    }
}

export default SectionBlockErrorMessage;
