import * as R from 'ramda';
import textKind from '../../../oneweb/Text/kind';
import { getWrappedComponentIds } from '../../../../utils/htmlWriter/html/render/wrapper/wrapperNodeUtils';
import getIntersectingTextComponents from '../../../../utils/componentsMap/getIntersectingTextComponents';
import type { TextComponent } from '../../../oneweb/Text/flowTypes';
import type { AnyComponent, ComponentsMap } from "../../../../redux/modules/children/workspace/flowTypes";

export const unwrapComponent = (component: AnyComponent | Object = {}) => {
    return {
        ...component,
        relPara: null,
        wrap: false
    };
};

export const getWrapperComponent = (wrappedComponentId: string, componentsMap: ComponentsMap): AnyComponent => {
    return R.values(
        R.filter((component) => (
            component.kind === textKind &&
            R.contains(wrappedComponentId, getWrappedComponentIds(component.content))
        ), componentsMap)
    )[0];
};

export const getWrapperIdFromWrappedComponent = (
    wrappedComponent: AnyComponent,
    componentsMap: ComponentsMap
): string | null | undefined => {
    let wrapperId;
    const intersectingTextComponents = getIntersectingTextComponents(wrappedComponent, componentsMap);
    if (intersectingTextComponents.length) {
        wrapperId = intersectingTextComponents[0].id;
        if (wrappedComponent.wrap) {
            for (let i = 0; i < intersectingTextComponents.length; i++) {
                const componentId = intersectingTextComponents[i].id;
                const component: TextComponent = componentsMap[componentId];

                if (component.content.indexOf(wrappedComponent.id) > -1) {
                    wrapperId = componentId;
                    break;
                }
            }
        }
    }

    return wrapperId;
};
