export const
    SHOW_AUTO_COLOR_LEFT_PANEL = 'SHOW_AUTO_COLOR_LEFT_PANEL',
    HIDE_AUTO_COLOR_LEFT_PANEL = 'HIDE_AUTO_COLOR_LEFT_PANEL',
    TURN_ON_EDIT_MODE = 'TURN_ON_EDIT_MODE',
    TURN_OFF_EDIT_MODE = 'TURN_OFF_EDIT_MODE',
    TURN_ON_ANIMATION = 'TURN_ON_ANIMATION',
    TURN_OFF_ANIMATION = 'TURN_OFF_ANIMATION',
    SET_SELECTED_PALETTE = 'SET_SELECTED_PALETTE',
    SET_SELECTED_PALETTE_FOR_ONBOARDING = 'SET_SELECTED_PALETTE_FOR_ONBOARDING',
    SET_SELECTED_PALETTE_FOR_TEMPLATE_SELECTOR = 'SET_SELECTED_PALETTE_FOR_TEMPLATE_SELECTOR',
    DONE_AUTO_COLOR_LEFT_PANEL = 'DONE_AUTO_COLOR_LEFT_PANEL',
    SAVE_COLOR_THEME = 'SAVE_COLOR_THEME',
    ADD_THEME_HISTORY = 'ADD_THEME_HISTORY',
    HISTORY_POPUP = 'HISTORY_POPUP',
    THEME_SINGLE_COLOR_CHANGE = "THEME_SINGLE_COLOR_CHANGE",
    ADJUST_CONTRAST_COLOR = 'ADJUST_CONTRAST_COLOR',
    KEEP_CONTRAST_COLOR = 'KEEP_CONTRAST_COLOR';
