import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
    {
        rootContainer: {
            position: "relative",
            userSelect: "none",

            "& > *": {
                height: "100%",
            },
        },
    },
    { name: "AnalyticsWidgetDashboardPreview" }
);
