import { $Diff } from "utility-types";

import type { PageTemplateSelectorErrorDialogProps } from "./types";
import { openDialog } from "../../../../App/actionCreators";
import { PAGE_TEMPLATE_SELECTOR_ERROR_DIALOG_ID } from "./pageTemplateSelectorErrorDialog";
import { Intl } from "../../../../../view/intl";

export const
    OPEN_PAGE_TEMPLATE_SELECTOR_ERROR_DIALOG_ACTION = 'OPEN_PAGE_TEMPLATE_SELECTOR_ERROR_DIALOG_ACTION',
    openPageTemplateSelectorErrorDialog = (
        props: $Diff<PageTemplateSelectorErrorDialogProps, { intl: Intl }>
    ) => openDialog(
        PAGE_TEMPLATE_SELECTOR_ERROR_DIALOG_ID,
        props
    );
