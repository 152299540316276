import { $ } from 'tinymce';
import { getSelectedNodes } from '../../utils/nodeUtils/getSelectedNodes';
import {
    DEFAULT_GLOBAL_CLASS,
    GLOBAL_STYLE_CLASSES,
    isValidContentBlock
} from '../../utils/nodeUtils/utils';
import { fixGlobalStyles } from './fixGlobalStyles';
import { customSendReport } from '../../../../../../../customSendCrashReport';

import type { FormatKeys } from "../../../../../../oneweb/Text/editorSetup";
import type { TinyMceEditor } from "../../../flowTypes";

// This is a method copied from tinyMCE, which is not exposed as of now
// TODO: replace this with the tinyMCE exposed method whenever available
const replaceVars = (value, vars) => {
    if (typeof value === 'function') {
        return value(vars);
    } else if (typeof value === 'string' && vars) {
        return value.replace(/%(\w+)/g, (str, name) => {
            return vars[name] || str;
        });
    }
    return value;
};

const makeApplyFormatToNode = (format, vars) => node => {
    if (
        format.copy_to_content_block === true ||
        format.copy_to_content_block === node.tagName
    ) {
        if (format.styles) {
            Object.keys(format.styles).forEach(prop => {
                node.style.setProperty(prop, replaceVars(format.styles[prop], vars));
            });
            node.dataset.mceStyle = node.style.cssText; // eslint-disable-line
        }

        if (format.classes) {
            const $node = $(node);
            format.classes.forEach(classNamePattern => {
                const className = replaceVars(classNamePattern, vars);

                GLOBAL_STYLE_CLASSES.forEach(globalStyleClass => {
                    $node.removeClass(globalStyleClass);
                });

                if (className !== DEFAULT_GLOBAL_CLASS) {
                    $node.addClass(className);
                }
            });
        }

        if (format.remove_styles) {
            format.remove_styles.forEach(prop => {
                node.style.removeProperty(prop);
            });
            node.dataset.mceStyle = node.style.cssText; // eslint-disable-line
        }
    }
};

export const applyFormatValue = (
    editor: TinyMceEditor,
    formatName: FormatKeys,
    value: string | null,
    node?: HTMLElement
) => {
    let vars = {};
    if (value !== null) {
        // If value is null, its for bold and italic, they should not change focus
        vars = { value };
        editor.focus();
    }

    try {
        editor.formatter.apply(formatName, vars, node);
    } catch (e: any) {
        customSendReport({
            message: `Error from TinyMCE in applying format: ${Math.random()}`, //NOSONAR // TODO: remove this when WBTGEN-10923 is fixed
            additionalInfo: {
                errorMessage: e.message,
                errorStack: e.stack,
                selectionText: editor.selection.getRng().toString(),
                // $FlowFixMe
                selectionHtml: editor.selection.getContent(),
                selectedNodeHtml: editor.selection.getNode().outerHTML,
            },
        });
    }

    const
        format = editor.formatter.get(formatName)[0],
        applyFormatToNode = makeApplyFormatToNode(format, vars),
        selectedNodes = getSelectedNodes(editor, true);

    selectedNodes.forEach(node => {
        if (isValidContentBlock(node)) {
            applyFormatToNode(node);
        }
    });

    const changedStyles = Object.keys(format.styles || {}),
        isGlobalStyleChange = !!format.classes;

    fixGlobalStyles(editor, changedStyles, isGlobalStyleChange, false);

    return editor;
};
