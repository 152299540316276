import * as pageIds from "./pageIds";
import makePages from "../../../PropertiesPanel/view/makePages";
import { mainPage, backgroundColorPage, borderPage, imagePatternPage, hoverEffectPage } from "./pages/index";

type Params = {
    mainPageTitle?: string
};

export default ({ mainPageTitle = mainPage.title }: Params = {}) => ({
    pages: makePages(
        { ...mainPage, title: mainPageTitle },
        backgroundColorPage,
        imagePatternPage,
        borderPage,
        hoverEffectPage
    ),
    defaultPageId: pageIds.MAIN
});
