import { getPaddingNodes } from "../editorUtils/methods/getters/getPaddingNodes";
import { makeApplyEditorChangesReducer } from "./helpers/helpers";
import { TINY_MCE_SET_PARAGRAPH_SPACING } from "../actionTypes";
import { toCss as paddingToCss } from '../../../../../mappers/block/padding';

const onSetParagraphSpacingUpdater = {
    conditions: [TINY_MCE_SET_PARAGRAPH_SPACING],
    reducer: makeApplyEditorChangesReducer(({ state, scope, values: [spacing], editor }) => {
        editor.focus(true);

        const paragraphNodes = getPaddingNodes(editor);
        for (let i = 0; i < paragraphNodes.length; i++) {
            // $FlowFixMe: WBTGEN-9962: put checks
            editor.dom.setStyle(paragraphNodes[i] as HTMLElement, 'padding', paddingToCss(spacing));
        }

        return {
            state: {
                ...state,
                spacing
            },
            scope
        };
    })
};

export {
    onSetParagraphSpacingUpdater
};
