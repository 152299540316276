import * as R from "ramda";
import type {
    AnyComponent,
    ComponentsDependencies,
    ComponentsMap
} from "../../../../redux/modules/children/workspace/flowTypes";
import makeUuid from "../../../../../utils/makeUuid";
import { isSectionKind } from "../../../oneweb/componentKinds";
import componentsRegistry from "../../../../view/oneweb/registry/index";
import { getMaxOrderIndex } from "../../../../redux/modules/children/workspace/reducers/utils/index";
import { getDefaultReducerState } from "../../../../redux/makeReducer/index";

const createNewComponent = (currentComponent: AnyComponent, topmostHandleKind: string, componentsMap: ComponentsMap,
    isPageMode: boolean, componentsDependencies: ComponentsDependencies, noAdjust: boolean = false) => {
    const
        { kind } = currentComponent,
        record = componentsRegistry[kind],
        orderIndex = getMaxOrderIndex(componentsMap) + 1,
        newComponentBeforeAdjusting = record.onBeforeComponentAdded({
            ...getDefaultReducerState(record.reducer),
            ...currentComponent,
            id: makeUuid(),
            inTemplate: isSectionKind(kind) ? false : !isPageMode,
            orderIndex
        }, componentsMap),
        adjustComponentOnAdd = record.adjustComponentOnAdd ?
            component => record.adjustComponentOnAdd(component, componentsDependencies[kind], topmostHandleKind) :
            R.identity,
        newComponent = noAdjust ? newComponentBeforeAdjusting : adjustComponentOnAdd(newComponentBeforeAdjusting),
        { reducerForExtension } = componentsRegistry[newComponent.kind];

    return {
        component: newComponent,
        componentExtension: reducerForExtension ? { [newComponent.id]: getDefaultReducerState(reducerForExtension) } : null
    };
};

export { createNewComponent };
