import React from 'react';

const ReviewsDataContext = React.createContext({
    reviewsApiData: {
        reviewsData: {
            reviews: []
        }
    },
    reviewsApiStatus: '',
    selectedReviewsIds: [],
});

export {
    ReviewsDataContext,
};
