import React from "react";
import { Dispatch } from "redux";
import cx from "classnames";
import { MOBILE_EDITOR_GROUP_IMAGE_SCALE_CHANGE } from "../../../actionTypes";
import HorizontalSpacer from "../../../../../view/common/HorizontalSpacer";
import { MctaButton, MctaVerticalBar } from "../../../../componentMainActions/index";
import mctaStyles from "../../../../componentMainActions/MctaButton/styles.css";
import type { AnyComponent } from "../../../../oneweb/flowTypes";

export const
    titleImageBigger = "msg: mcta.image.mve.imageBigger {Make image bigger}",
    titleImageSmaller = "msg: mcta.image.mve.imageSmaller {Make image smaller}",
    titleImageSmallerErr = "msg: mcta.image.minImageSize {Minimum image size}",
    titleImageBiggerErr = "msg: mcta.image.maxImageSize {Maximum image size}";

const
    maxScale = 100,
    minScale = 50;

type MobileGroupMCTAScalerProps = {
    dispatch: Dispatch;
    selectedComponent: AnyComponent;
    groupId: string;
    scale: number;
};

class ImageMctaScaler extends React.PureComponent<MobileGroupMCTAScalerProps> {
    increaseImageDimension: Function;
    decreaseImageDimension: Function;

    constructor(props: MobileGroupMCTAScalerProps) {
        super(props);

        this.increaseImageDimension = this.changeImageDimension.bind(this, 1);
        this.decreaseImageDimension = this.changeImageDimension.bind(this, -1);
    }

    canIncrease() {
        const currentScale = this.props.scale;
        return currentScale < maxScale;
    }

    canDecrease() {
        const currentScale = this.props.scale;
        return currentScale > minScale;
    }

    changeImageDimension(factor: number) {
        const
            { dispatch, groupId: id, scale, selectedComponent: { width } } = this.props,
            currentWidth = width * (scale / 100),
            newWidth = currentWidth + factor,
            newScale = (newWidth / width) * 100;

        dispatch({
            type: MOBILE_EDITOR_GROUP_IMAGE_SCALE_CHANGE,
            payload: {
                id,
                value: factor === -1 ? Math.max(newScale, minScale) : Math.min(newScale, maxScale)
            },
            amendToSelf: true
        });
    }

    render() {
        const
            canIncrease = this.canIncrease(),
            canDecrease = this.canDecrease();
        return (
            <React.Fragment>
                <MctaButton
                    btnClass={cx({ [mctaStyles.disabled]: !canDecrease })}
                    title={canDecrease ? titleImageSmaller : titleImageSmallerErr}
                    isErrorTitle={!canDecrease}
                    icon={canDecrease ? "GROUP_IMAGE_SMALLER" : "GROUP_IMAGE_SMALLER_DISABLED"}
                    onClick={canDecrease ? this.decreaseImageDimension : null}
                />
                <HorizontalSpacer x={3} />
                <MctaVerticalBar />
                <HorizontalSpacer x={3} />
                <MctaButton
                    btnClass={cx({ [mctaStyles.disabled]: !canIncrease })}
                    title={canIncrease ? titleImageBigger : titleImageBiggerErr}
                    isErrorTitle={!canIncrease}
                    icon={canIncrease ? "GROUP_IMAGE_BIGGER" : "GROUP_IMAGE_BIGGER_DISABLED"}
                    onClick={canIncrease ? this.increaseImageDimension : null}
                />
            </React.Fragment>
        );
    }
}

export default ImageMctaScaler;
