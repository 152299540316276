import * as Actions from "./actions";

export default [
    {
        conditions: [Actions.SET_SUPPORT_ON],
        reducer: ({ state }: Record<string, any>) => ({
            state: {
                ...state,
                support: { show: true, showPopUp: false }
            }
        })
    },
    {
        conditions: [Actions.OPEN_SUPPORT_TOOL],
        reducer: ({ state }: Record<string, any>) => ({
            state: {
                ...state,
                support: { ...state.support, show: true, showPopUp: true }
            }
        })
    },
    ...([
        Actions.CLOSE_SUPPORT_TOOL,
        Actions.SEPARATE_FOOTER_BY_FOOTER_TMP_CMPS,
        Actions.REMOVE_EMPTY_GAPS_IN_CONTAINERS,
        Actions.REMOVE_EMPTY_STRIPS_OVERLAPPING_WITH_HEADER,
        Actions.SUPPORT_DELETE_SELECTED_SECTION,
        Actions.DELETE_STRIP_AND_ADD_BACKGROUND_TO_SECTION,
        Actions.SUPPORT_DELETE_SELECTED_CONTAINER_ONLY,
        Actions.SUPPORT_FIX_TOPS,
        Actions.ADD_BOXES_TO_PAGE_SECTION,
        Actions.WRAP_IMG_TEXT_IN_BOX,
        Actions.GROUP_TEXT_COMPONENTS_INTO_ONE
    ].map(action => ({
        conditions: [action],
        reducer: ({ state }: Record<string, any>) => ({
            state: {
                ...state,
                support: { ...state.support, showPopUp: false }
            }
        })
    })))
];
