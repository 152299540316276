import styles from './FileChooserHeader.css';
import { getElementFullWidth, htmlCollectionToArray } from '../../../../utils/dom';

export const BreadcrumbType = {
    DIR: 'dir',
    SEP: 'sep',
    ELL: 'ell',
};

export const BreadcrumbRootDir = {
    type: BreadcrumbType.DIR,
    name: 'msg: fc.webSpaceDirLabel {Web space}',
    level: 0,
};

export type Breadcrumbs = Array<
    {
        type: 'dir',
        name: string,
        level: number,
    } | {
        type: 'sep' | 'ell',
    }
>;

const excludeBreadcrumbs = (container) => {
    const
        containerWidth = getElementFullWidth(container),
        elements = htmlCollectionToArray(container.getElementsByClassName(styles.breadcrumbsElement))
            .map(el => ({
                text: el.innerText,
                class: el.className,
                left: el.offsetLeft,
                width: getElementFullWidth(el),
            })),
        totalWidth = elements.reduce((acc, { width }) => acc + width, 0);

    if (totalWidth < containerWidth) return false;

    const
        middleWidth = totalWidth / 2,
        middleIdx = elements.findIndex(
            (el, i, arr) => el.left <= middleWidth && arr[i + 1] && arr[i + 1].left >= middleWidth,
        );

    // normally this should never happen, just in case layout got messed up for some reason
    if (middleIdx === -1) return false;

    const startIdx = elements[middleIdx].class.indexOf(styles.breadcrumbsSep) > -1
        ? middleIdx
        : middleIdx - 1;

    let
        nextTotalWidth = totalWidth + 45, // buffer,
        idx = startIdx,
        count = 0;

    while (nextTotalWidth > containerWidth) {
        count += 2;
        nextTotalWidth -= (elements[idx].width + elements[idx + 1].width);
        idx += 2;
    }

    return { startIdx, count };
};

export const buildBreadcrumbs = (currentPath: null | undefined | string, container: null | undefined | HTMLElement): Breadcrumbs => {
    if (!currentPath || currentPath === '/') return [BreadcrumbRootDir] as Breadcrumbs;

    const breadcrumbs = [BreadcrumbRootDir]
        .concat(
            currentPath
                .split('/')
                .map((name, i) => ({ type: BreadcrumbType.DIR, name, level: i + 1 }))
        );

    // insert separators
    const count = (2 * breadcrumbs.length) - 1;
    // @ts-ignore
    for (let i = 1; i < count; i += 2) breadcrumbs.splice(i, 0, { type: BreadcrumbType.SEP });

    // custom items for long breadcrumbs
    if (container) {
        const exclude = excludeBreadcrumbs(container);
        if (exclude) {
            // @ts-ignore
            breadcrumbs.splice(exclude.startIdx, exclude.count, { type: BreadcrumbType.ELL });
        }
    }

    // @ts-ignore
    return breadcrumbs;
};
