const MAX_IMAGE_DROP_HEIGHT = 90;

export type Props = {
    globalVariables: {
        logoAsset: {
            width: number,
            height: number
        }
    }
}

export const getLogoInitialDimensions = (
    { globalVariables }: Props
) => {
    const { logoAsset } = globalVariables;
    if (logoAsset) {
        let { width, height } = logoAsset;

        if (height > MAX_IMAGE_DROP_HEIGHT) {
            height = MAX_IMAGE_DROP_HEIGHT;
            width = Math.round(width * (height / logoAsset.height));
        }

        return {
            width,
            height
        };
    } else {
        return {
            width: 500,
            height: MAX_IMAGE_DROP_HEIGHT
        };
    }
};
