import React from 'react';
import * as mouseUtils from "../../../utils/mouse";
import * as styles from "./PropertiesPanel.css";
import * as actionTypes from "../epic/actionTypes";
import { injectIntl } from "../../../view/intl/index";
import { NoMouseDownAndUp } from "../../../view/common/NoMouseEventsPropagation";
import { MCTA_ON_MOUSE_LEAVE } from "../../../view/Workspace/Decorations/ComponentMainActions/actionTypes";

const
    MenuIconStyle = {
        transition: 'background 100ms',
        fill: '#818181'
    };

const ContextMenuIcon = injectIntl(({ onClick, dispatch, MCTA, intl, isMobileV }) => {
    const title = intl.msgJoint("msg: common.showActions {Show actions}"),
        iconProps = {
            className: `${styles.settingsIcon} ${MCTA ? styles.settingsIconInMCTA : ''}`,
            style: MenuIconStyle
        };
    let props: Record<string, any> = {
        className: styles.settingsIconContainer,
        onClick: onClick || ((e) => {
            e.stopPropagation();
            dispatch({
                type: actionTypes.PROPERTIES_PANEL_SHOW_CONTEXT_MENU,
                payload: { position: mouseUtils.getPosition(e), isMobileV }
            });
        }),
        "data-title": title
    };

    if (MCTA) {
        props = {
            ...props,
            ...NoMouseDownAndUp,
            onMouseLeave: () => dispatch({ type: MCTA_ON_MOUSE_LEAVE })
        };
    }

    return (
        <div {...props}>
            <span {...iconProps} />
        </div>
    );
});

export {
    ContextMenuIcon as default,
    ContextMenuIcon
};

