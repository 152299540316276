import { WidgetsInstagramKind } from './kind';
import { calcRenderProps } from './calcRenderProps';
import { InstagramView } from './view/index';

const WidgetsInstagramPreviewConfig = {
    kind: WidgetsInstagramKind,
    view: InstagramView,
    calcProps: ({ component }) => calcRenderProps({
        component,
        componentExtension: { isTransient: false },
    }),
};

export { WidgetsInstagramPreviewConfig };
