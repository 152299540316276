import React from 'react';
import { Dispatch } from "redux";
import * as styles from '../ModernLayoutLeftPanel.css';
import { Intl } from '../../../../../view/intl/index';
import type { SectionComponent } from "../../../../oneweb/Section/flowTypes";
import ICONS from "../../../../../view/Icons/index";
import { ACTIVATE_MODERN_HEADER, MHF_COMPONENTS_TOGGLE } from "../../../actionTypes";
import ModernLayoutToggleOption from "./ModernLayoutToggleOption";
import { disableMhfComponentToggle } from "../../../utils";

type ModernLayoutComponentsToggleProps = {
    intl: Intl,
    section: SectionComponent,
    dispatch: Dispatch,
    disableToggleMsg: string,
    shouldAmendToActivation?: boolean
};

const ModernLayoutComponentsToggle = ({
    intl,
    section,
    dispatch,
    disableToggleMsg,
    shouldAmendToActivation
}: ModernLayoutComponentsToggleProps) => {
    const { options = [] } = section.modernLayout || {},
        [disableCmpToggle, setDisableCmpToggle] = React.useState(false),
        toggleCurrentComponent = (currentComponent, parentId) => {
            if (disableMhfComponentToggle(options, parentId, currentComponent.show)) {
                setDisableCmpToggle(true);
            } else {
                let dispatchAction: AnyAction = {
                    type: MHF_COMPONENTS_TOGGLE,
                    payload: {
                        toggleCmpFlag: !currentComponent.show,
                        toggleCmpId: currentComponent.id,
                        sectionId: section.id,
                    }
                };
                if (shouldAmendToActivation) {
                    dispatchAction.amendTo = ACTIVATE_MODERN_HEADER;
                }
                setDisableCmpToggle(false);
                dispatch(dispatchAction);
            }
        };

    return <div className={styles.yourLayoutToggleCmpContainer}>
        {options.map(currentComponent => {
            return <ModernLayoutToggleOption
                key={currentComponent.id}
                option={currentComponent}
                onClick={toggleCurrentComponent}
            />;
        })}
        { disableCmpToggle &&
            <div className={styles.disableToggleMsgContainer}>
                <ICONS.EXCLAMATION className={styles.disableToggleAlertIcon} />
                <div className={styles.disableToggleMsg}>{intl.msgJoint(disableToggleMsg)}</div>
            </div> }
    </div>;
};

export default ModernLayoutComponentsToggle;
