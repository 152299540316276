import React from 'react';
import { WidgetWorkspaceView } from '../../view/workspace';
import { IFrameView } from './iframe';
import { GoogleMapsView } from './index';

const GoogleMapsWorkspaceView = props => {
    const iframe = props.source === 'iframe';
    return (
        <WidgetWorkspaceView {...props}>
            {iframe ? <IFrameView {...props} /> : <GoogleMapsView {...props} />}
        </WidgetWorkspaceView>
    );
};

export { GoogleMapsWorkspaceView };

