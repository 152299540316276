export const
    SHOW_TOOLTIP = 'SHOW_TOOLTIP',
    SHOW_TOOLTIP_FOR_COMPONENT_MINVALUES = 'SHOW_TOOLTIP_FOR_COMPONENT_MINVALUES',
    SHOW_TOOLTIP_FOR_SECTION_MINVALUES = 'SHOW_TOOLTIP_FOR_SECTION_MINVALUES',
    SHOW_TOOLTIP_FOR_STRIP_TITLE = 'SHOW_TOOLTIP_FOR_STRIP_TITLE',
    SHOW_TOOLTIP_FOR_EXTENDED_PANEL = 'SHOW_TOOLTIP_FOR_EXTENDED_PANEL',
    SHOW_TOOLTIP_FOR_HEADER_FOOTER_SECTION = 'SHOW_TOOLTIP_FOR_HEADER_FOOTER_SECTION',
    SHOW_TOOLTIP_FOR_SECTION_INSERTER = 'SHOW_TOOLTIP_FOR_SECTION_INSERTER',
    SHOW_TOOLTIP_FOR_MODERN_LAYOUT = 'SHOW_TOOLTIP_FOR_MODERN_LAYOUT',
    SHOW_TOOLTIP_FOR_COMPONENT_SHORTCUT = 'SHOW_TOOLTIP_FOR_COMPONENT_SHORTCUT',
    SHOW_TOOLTIP_FOR_UNAVAILABLE_COMPONENT_CLICK = 'SHOW_TOOLTIP_FOR_UNAVAILABLE_COMPONENT_CLICK',
    SHOW_TOOLTIP_FOR_SECTION_ONBOARDING = 'SHOW_TOOLTIP_FOR_SECTION_ONBOARDING',
    SHOW_TOOLTIP_FOR_IMAGE_REPALCE_INIT = 'SHOW_TOOLTIP_FOR_IMAGE_REPALCE_INIT',
    SHOW_TOOLTIP_FOR_IMAGE_REPALCE = 'SHOW_TOOLTIP_FOR_IMAGE_REPALCE',
    CLOSE_TOOLTIP = 'CLOSE_TOOLTIP',
    CLOSE_COMPONENT_LIST_TOOLTIP = 'CLOSE_COMPONENT_LIST_TOOLTIP',
    CLOSE_FB_CHAT_TOOLTIP = 'CLOSE_FB_CHAT_TOOLTIP',
    TOOLTIP_CLIENT_RECT = 'TOOLTIP_CLIENT_RECT',
    CLOSE_MOBILE_DOWN_TOOLTIP = 'CLOSE_MOBILE_DOWN_TOOLTIP',
    SHOW_TOOLTIP_FOR_MVE_MENU = 'SHOW_TOOLTIP_FOR_MVE_MENU',
    SHOW_TOOLTIP_FOR_MOBILE_DOWN_MASK = 'SHOW_TOOLTIP_FOR_MOBILE_DOWN_MASK',
    SHOW_TOOLTIP_FOR_MVE_CMP_REORDER = 'SHOW_TOOLTIP_FOR_MVE_CMP_REORDER',
    MOBILE_EDITOR_REORDER_TIP_CLOSED = 'MOBILE_EDITOR_REORDER_TIP_CLOSED',
    MOBILE_EDITOR_REORDER_TIP_CLOSE = "MOBILE_EDITOR_REORDER_TIP_CLOSE",
    MOBILE_EDITOR_REORDER_TIP_DO_NOT_SHOW = "MOBILE_EDITOR_REORDER_TIP_DO_NOT_SHOW",
    MOBILE_EDITOR_GROUP_TIP_DO_NOT_SHOW = "MOBILE_EDITOR_GROUP_TIP_DO_NOT_SHOW",
    SHOW_TOOLTIP_FOR_MVE_GROUP = "SHOW_TOOLTIP_FOR_MVE_GROUP",
    MOBILE_EDITOR_TOGGLE_TIP_CHKBOX = "MOBILE_EDITOR_TOGGLE_TIP_CHKBOX",
    HIDE_TOOLTIP_FOR_ADDRESS = "HIDE_TOOLTIP_FOR_ADDRESS",
    HIDE_TOOLTIP_FOR_EMAIL = "HIDE_TOOLTIP_FOR_EMAIL",
    HIDE_TOOLTIP_FOR_PHONE = "HIDE_TOOLTIP_FOR_PHONE",
    SHOW_TOOLTIP_FOR_ADDRESS = "SHOW_TOOLTIP_FOR_ADDRESS",
    SHOW_TOOLTIP_FOR_EMAIL = "SHOW_TOOLTIP_FOR_EMAIL",
    SHOW_TOOLTIP_FOR_PHONE = "SHOW_TOOLTIP_FOR_PHONE",
    NEVER_SHOW_TOOLTIP_AGAIN_FOR_ADDRESS = "NEVER_SHOW_TOOLTIP_AGAIN_FOR_ADDRESS",
    NEVER_SHOW_TOOLTIP_AGAIN_FOR_EMAIL = "NEVER_SHOW_TOOLTIP_AGAIN_FOR_EMAIL",
    NEVER_SHOW_TOOLTIP_AGAIN_FOR_PHONE = "NEVER_SHOW_TOOLTIP_AGAIN_FOR_PHONE",
    SHOW_TOOLTIP_FOR_PREMIUM_COMPONENT_SHORTCUT = "SHOW_TOOLTIP_FOR_PREMIUM_COMPONENT_SHORTCUT",
    SHOW_TOOLTIP_FOR_SINGLE_PRODUCT_COMPONENT = "SHOW_TOOLTIP_FOR_SINGLE_PRODUCT_COMPONENT",
    HIDE_TOOLTIP_FOR_SINGLE_PRODUCT_COMPONENT = "HIDE_TOOLTIP_FOR_SINGLE_PRODUCT_COMPONENT",
    SHOW_DISABLED_FB_CHAT_TOOLTIP = "SHOW_DISABLED_FB_CHAT_TOOLTIP",
    SHOW_FORCE_DISABLED_FB_CHAT_TOOLTIP = "SHOW_FORCE_DISABLED_FB_CHAT_TOOLTIP",
    SHOW_FORCE_DISABLED_INSERTER_TOOLTIP = "SHOW_FORCE_DISABLED_INSERTER_TOOLTIP";
