import { makeApplyEditorChangesReducer } from "./helpers/helpers";
import { applyFormatValue } from "../editorUtils/methods/helpers/applyFormatValue";
import { TINY_MCE_SET_CHARACTER_SPACING } from "../actionTypes";

const onSetCharacterSpacingUpdater = {
    conditions: [TINY_MCE_SET_CHARACTER_SPACING],
    reducer: makeApplyEditorChangesReducer(({ state, scope, values: [characterSpacing], editor }) => {
        if (characterSpacing === 0) {
            applyFormatValue(editor, 'characterSpacing', 'normal');
        } else {
            // characterSpacing value maps 1:0.1 to letter-spacing CSS value
            const convertedCharacterSpacing = characterSpacing / 10;
            applyFormatValue(editor, 'characterSpacing', `${convertedCharacterSpacing}em`);
        }
        return {
            state: {
                ...state,
                characterSpacing
            },
            scope
        };
    })
};

export {
    onSetCharacterSpacingUpdater
};
