import React from "react";
import cx from 'classnames';
import styles from './ContextMenuIcon.css';
import { ucFirst } from "../../../../utils/string";

type ContextMenuIconProps = {
    iconName: string
}

type Props = {
    disabled?: boolean,
    fill?: string,
    className?: string,
    containerStyleClassName?: string
}

export default ({ iconName }: ContextMenuIconProps) =>
    ({ disabled, fill, className, containerStyleClassName }: Props) => (
        <div className={cx(styles.container, containerStyleClassName)}>
            <span
                className={cx(
                    styles.icon,
                    styles[iconName],
                    className,
                    { [styles.disabled]: disabled },
                    fill && styles[`fill${ucFirst(fill)}`]
                )}
            />
        </div>
    );
