import React from "react";
import styles from "./MHFDeactivationDialog.css";
import { Dialog, DialogBody, DialogFooter, StripTypes } from "../../../../../view/common/dialogs/baseDialog/index";
import { Msg } from "../../../../../view/intl/index";
import { closeDialog } from "../../../../App/actionCreators/index";
import { DEACTIVATE_MODERN_HEADER_FOOTER } from "../../../actionTypes";

type Props = {
    dispatch: Dispatch;
};

class MHFDeactivationDialog extends React.Component<Props> {
    render() {
        const { dispatch } = this.props,
            onDeactivate = () => {
                dispatch(closeDialog());
                dispatch({
                    type: DEACTIVATE_MODERN_HEADER_FOOTER
                });
            };

        return (
            <Dialog stripType={StripTypes.NONE} className={styles.dialogMain} closeBtnClassName={styles.closeBtn}>
                <DialogBody className={styles.dialogBody}>
                    <div className={styles.title}>
                        <Msg k="MHF.deactivation.title">Disable header and footer layouts</Msg>
                    </div>
                    <div className={styles.description}>
                        <Msg k="MHF.deactivation.description">
                            Are you sure you want to disable?
                        </Msg>
                    </div>
                </DialogBody>
                <DialogFooter
                    className={styles.footerContainer}
                    footerButtonContainerClassName={styles.footerButtonContainer}
                    mctaText="msg: common.yesDisableOff {Yes, disable}"
                    sctaText="msg: common.noKeepIt {No, keep it}"
                    mctaHandler={onDeactivate}
                    noTopBorder
                    primaryBtnClass={styles.footerPrimaryBtn}
                    sctaBtnClass={styles.footerSctaBtn}
                    sctaHandler={() => dispatch(closeDialog())}
                    autoFocus
                />
            </Dialog>
        );
    }
}

export {
    MHFDeactivationDialog
};
