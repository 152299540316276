import React from 'react';
import cx from 'classnames';
import { Box, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { injectIntl } from '../../../../../../wbtgen/src/view/intl/index';

import imageStyles from '../../Images/imageStyle.css';

const useStyles = makeStyles({
    mobileDrawerTopBar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    backButton: {
        paddingLeft: '0px',
        '&:hover, &:active': {
            background: 'none',
            backgroundColor: 'transparent',
        },
    },
    backButtonIcon: {
        width: '12px',
        height: '12px',
        marginRight: '12px',
    },
    backButtonText: {
        fontSize: '15px',
        lineHeight: '24px',
        fontWeight: 400,
        fontStyle: 'normal',
        letterSpacing: '0.1px',
    },
}, { name: "SideBarNavigation" });

type SideBarNavigationProps = {
    intl: Intl;
    backButtonHandler?: () => void;
};

const SideBarNavigationComponent = ({ intl, backButtonHandler }: SideBarNavigationProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.mobileDrawerTopBar}>
            <Button
                disableRipple
                disableElevation
                className={classes.backButton}
                onClick={backButtonHandler}
            >
                <span className={cx(classes.backButtonIcon, imageStyles.chervonLeftIcon)} />
                <span className={classes.backButtonText}>
                    {intl.msgJoint('msg: common.back {Back}')}
                </span>
            </Button>
        </Box>
    );
};

export const SideBarNavigation = injectIntl(SideBarNavigationComponent);
