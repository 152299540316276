import makeEpic from '../../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import * as Actions from "./actionTypes";
import { EXTENDED_PANEL_AVAILABLE_WIDTH_MINUS_PADDING } from "../../../../Panel/constants";
import {
    SECTIONS_BLOCKS_FETCHED
} from "../../../../Panel/actionTypes";
import {
    ALL_FILTER_KEY,
    BlocksCategoryMap
} from "./constants";

const initialState = {
    blocks: [],
    filterKey: ALL_FILTER_KEY
};

export const extendedPanelTextStyleEpic = makeEpic({
    defaultState: initialState,
    valueActionType,
    updaters: [
        {
            conditions: [
                Actions.SET_FILTER_VALUE
            ],
            reducer: ({ state, values: [key] }) => {
                return {
                    state: {
                        ...state,
                        filterKey: key
                    }
                };
            },
        },
        {
            conditions: [
                SECTIONS_BLOCKS_FETCHED
            ],
            reducer: ({ values: [{ blocks }], state }) => ({
                state: {
                    ...state,
                    blocks: blocks ? blocks.filter(category => BlocksCategoryMap[category.key]) : []
                }
            })
        },
        {
            conditions: [Actions.EXTENDED_PANEL_TEXT_MEASURED_SIZE],
            reducer: ({ values: [{ textStyleName, width, height }], state }) => {
                const scale = (width > EXTENDED_PANEL_AVAILABLE_WIDTH_MINUS_PADDING) ?
                    (EXTENDED_PANEL_AVAILABLE_WIDTH_MINUS_PADDING / width) : 1;
                return {
                    state: {
                        ...state,
                        [textStyleName]: {
                            ...state[textStyleName],
                            scale,
                            scaledHeight: height * scale
                        }
                    }
                };
            }
        },
        {
            conditions: [Actions.EXTENDED_PANEL_TEXT_MEASURED_HEIGHT],
            reducer: ({ values: [{ textStyleName, height }], state }) => ({
                state: {
                    ...state,
                    [textStyleName]: {
                        ...state[textStyleName],
                        height
                    }
                }
            })
        },
        {
            conditions: [Actions.EXTENDED_PANEL_BTN_TEXT_MEASURED_WIDTH],
            reducer: ({ values: [{ textStyleName, width, height }], state }) => ({
                state: {
                    ...state,
                    [textStyleName]: {
                        ...state[textStyleName],
                        width,
                        height,
                        scale: width > EXTENDED_PANEL_AVAILABLE_WIDTH_MINUS_PADDING ?
                            EXTENDED_PANEL_AVAILABLE_WIDTH_MINUS_PADDING / width : 1
                    }
                }
            })
        },
        {
            conditions: [Actions.EXTENDED_PANEL_TEXT_CLEAR_DATA],
            reducer: ({ state }) => ({ state: { blocks: state.blocks, filterKey: state.filterKey } })
        }
    ]
});
