import * as React from 'react';
import styles from './SectionLinkForm.css';
import { injectIntl } from "../../../../view/intl";

const SectionLinkPageDeletedView = ({ intl }) => {
    const description = `msg: tooltip.sectionLinkPageDeleted.text {The section link is broken because the page with the section was deleted. Choose a new page and section to link to. You can also delete the section link from your site menu.}`; //eslint-disable-line
    const heading = `msg: tooltip.sectionLinkPageDeleted.heading.text {Page was deleted}`;
    return (
        <div>
            <div className={styles.headingContainer}>
                <span className={styles.warningIcon} />
                <span className={styles.tooltipHeading}>{intl.msgJoint(heading)}</span>
            </div>
            <div className={styles.tooltipWarningText}>
                {intl.msgJoint(description)}
            </div>
        </div>
    );
};

export const SectionLinkPageDeleted = injectIntl(SectionLinkPageDeletedView);
