import { Lit } from "../../lit";
import type { AppStateSel } from "../../globalTypes";
import { pathSelector } from "../../utils/pathSelector";

export const
    totalFacebookFeedGalleryComponentsSelector: AppStateSel<number> = pathSelector(
        [Lit.socialAccounts, Lit.facebookFeed, Lit.totalFacebookFeedGalleryComponents]
    ),
    totalInstagramGalleryComponentsSelector: AppStateSel<number> = pathSelector(
        [Lit.socialAccounts, Lit.instagram, Lit.totalInstagramGalleryComponents]
    ),
    totalOtherPageFacebookFeedGalleryComponentsSelector: AppStateSel<number> = pathSelector(
        [Lit.socialAccounts, Lit.facebookFeed, Lit.totalOtherPagesFacebookFeedGalleryComponents]
    ),
    totalOtherPageInstagramGalleryComponentsSelector: AppStateSel<number> = pathSelector(
        [Lit.socialAccounts, Lit.instagram, Lit.totalOtherPagesInstagramGalleryComponents]
    ),
    totalCurrentPageFacebookFeedGalleryComponentsSelector: AppStateSel<number> = pathSelector(
        [Lit.socialAccounts, Lit.facebookFeed, Lit.totalCurrentPageFacebookFeedGalleryComponents]
    ),
    totalCurrentPageInstagramGalleryComponentsSelector: AppStateSel<number> = pathSelector(
        [Lit.socialAccounts, Lit.instagram, Lit.totalCurrentPageInstagramGalleryComponents]
    );
