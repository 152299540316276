import Locales from './Locales';

type Result = Array<{
    value: string,
    label: string,
}>;

export const
    getLocaleList = (localeMap?: Record<string, any>): Result => Object.keys(Locales).map(l => {
        const value = localeMap ? localeMap[l] : l;
        return { value, label: Locales[value] };
    }),
    getLocaleListNoBr = () =>
        getLocaleList().filter(({ value }) => value !== 'pt_br');
