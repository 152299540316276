import React from "react";
import cx from "classnames";
import * as styles from '../App.css';

type BaseComponentPreviewProps = {
    globalStyleIds: Array<string>,
    children?: any,
    className?: string,
    wrap?: boolean,
    style?: Record<string, any>,
}

export default React.forwardRef((
    { children, globalStyleIds, className: previewClassName, wrap = true, style = {} }: BaseComponentPreviewProps,
    ref: React.ForwardedRef<HTMLDivElement>
) => (
    <div ref={ref} className={cx({ [styles.baseComponent]: wrap }, previewClassName, ...globalStyleIds)} style={style}>
        {children}
    </div>
));
