export const
    WebshopNotAllowedInTemplateMode = 'WebshopNotAllowedInTemplateMode',
    WebshopExistsOnAnotherPage = 'WebshopExistsOnAnotherPage',
    WebshopExistsOnCurrentPage = 'WebshopExistsOnCurrentPage',
    WebshopIsDisabledInDemo = 'WebshopIsDisabledInDemo',

    ErrorMsgsOnAdd = {
        [WebshopExistsOnCurrentPage]: {
            "title": "msg: web.dd.creators.WebShop.perPageTitle {Only one Online Shop per page}",
            "text": "msg: tooltip.webshopOnePerSite {You can only have one Online Shop on your website. Looks like you already have one.}" // eslint-disable-line max-len
        },
        [WebshopExistsOnAnotherPage]: {
            "title": "msg: web.dd.creators.WebShop.perPageTitle {Only one Online Shop per page}",
            titleForDisabledShortCut: "msg: web.dd.creators.WebShop.perPageTitle {Only one Online Shop per page}",
            "text": "msg: tooltip.webshopOnePerSite {You can only have one Online Shop on your website. Looks like you already have one.}" // eslint-disable-line max-len
        },
        [WebshopIsDisabledInDemo]: {
            text: 'msg: demo.disabledWebshopText {The Online Shop component is not available in the trial version.}',
        },
    };

