import * as R from 'ramda';
import { makeUuid } from "../../utils/makeUuid";

type UidDict = {
    [id: string]: string
};

type SkipIdsMap = {
    [id: string]: boolean
};

export const
    uuidRegex = /^(?:[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12})$/i,
    allUuidsRegex = /([a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12})/ig,
    // Find UUID strings. If the old UUID exists in uidDic, use
    // the already-generated new value. Otherwise make a new UUID.
    isUuid = (text: any): boolean => (typeof text === 'string' && uuidRegex.test(text));

// Can be used for pure data objects only(no functions involved)
export default (
    obj: any,
    uidDict: UidDict = {},
    changeKeyAlso?: boolean,
    skipIdsMap: SkipIdsMap = {},
    adjustOnlyFromUidDict?: boolean
): any => {
    let updatedUidDict = { ...uidDict };
    const getNewUid = (id) => {
        if (skipIdsMap[id] || (adjustOnlyFromUidDict && !updatedUidDict[id])) {
            return id;
        }
        if (updatedUidDict[id]) {
            return updatedUidDict[id];
        } else {
            const newUid = makeUuid();
            updatedUidDict[id] = newUid;
            return newUid;
        }
    };
    const copy = (obj: any) => {
        let output: any = Array.isArray(obj) ? [] : {};
        const objKeys = Object.keys(obj);
        objKeys.forEach(key => {
            const
                val = obj[key],
                updatedKey = (changeKeyAlso && isUuid(key) ? getNewUid(key) : key);

            if (isUuid(val)) {
                output[updatedKey] = getNewUid(val);
            } else if (R.is(Object, val) || Array.isArray(val)) {
                output[updatedKey] = copy(val);
            } else if (typeof val === 'string') {
                const allUUids = val.match(allUuidsRegex);
                if (allUUids) {
                    output[updatedKey] = allUUids.reduce(
                        (val, uuid) => val.replace(uuid, getNewUid(uuid)),
                        val
                    );
                } else {
                    output[updatedKey] = val;
                }
            } else {
                output[updatedKey] = val;
            }
        });
        return output;
    };
    return { copiedObject: copy(obj), updatedUidDict };
};
