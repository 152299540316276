export const
    ResizeCrop = {
        EAST: 'east',
        WEST: 'west',
        CENTER: 'center',
        NORTHWEST: 'northwest',
        NORTHEAST: 'northeast',
        SOUTHWEST: 'southwest',
        SOUTHEAST: 'southeast',
        ATTENTION: 'attention',
        ENTROPY: 'entropy'
    };
