import { clone, intersection, unnest } from 'ramda';
import type { HtmlWriterData, Stylesheet, Element } from '../../flowTypes';
import { spliceOverToDifferentLists, addGlobalStyleToStyle } from './utils/index';

const splitStyle = (style, key) => {
    const { [key]: value, ...remainingStyle } = style;
    remainingStyle.bold = true;
    return [remainingStyle, style];
};

export default (
    data: HtmlWriterData,
    defaultGlobalStyles: Array<Stylesheet>,
    textParagraphs: Array<Element>,
    listParagraphs: Array<Element>
) => {
    let { styles } = data;

    // process styles and add missing fields
    styles = addGlobalStyleToStyle(defaultGlobalStyles, styles);

    // split styles over paragraphs
    let {
        match: paragraphStyles,
        noMatch: stylesNotMatchingAnyParagraphs,
    } = spliceOverToDifferentLists(clone(styles), textParagraphs);

    // split styles over listItems
    let {
        match: listStyles,
        noMatch: stylesNotMatchingAnyLists,
    } = spliceOverToDifferentLists(clone(styles), listParagraphs, { listItemElement: true });

    styles = [
        ...(intersection(stylesNotMatchingAnyParagraphs, stylesNotMatchingAnyLists)),
        ...paragraphStyles,
        ...listStyles,
    ];

    // seperate subscript and superscript from other styles
    styles = unnest(styles.map(style => {
        if (style.superscript) {
            return splitStyle(style, 'superscript');
        }

        if (style.subscript) {
            return splitStyle(style, 'subscript');
        }

        return style;
    }));

    return styles;
};
