import { memoMax, memoMaxDynamicNumOfArgs } from '../../utils/memo';
import { MaxComponentsOnPage } from '../constants/app';

const dispatchForwardTo = memoMax((kind: string, id: string, dispatch: Dispatch) => {
    return (action: Action) => {
        return dispatch({
            ...action,
            ...makeActionForwardTo(kind, id)
        });
    };
}, MaxComponentsOnPage * 2);

export function makeActionForwardTo(kind: string, id: string) {
    return {
        forwardTo: {
            kind,
            id
        }
    };
}

export const
    SELECTED_COMPONENT = 'SELECTED_COMPONENT',
    makeActionForwardToSelectedComponent = (action: Action, fromMVE: boolean = false): Action => {
        return {
            ...action,
            forwardTo: {
                kind: SELECTED_COMPONENT
            },
            fromMVE
        };
    },
    // TODO probably it should be memoMaxOne
    dispatchForwardToSelectedComponent = memoMaxDynamicNumOfArgs((dispatch: Dispatch, fromMVE: boolean = false) => action => dispatch(makeActionForwardToSelectedComponent(action, fromMVE)), 2); // eslint-disable-line

export default dispatchForwardTo;
