import React from 'react';
import TableCell from './TableCell';
import type { TableRowProps } from "../flowTypes";

export default ({
    firstCellIndex,
    rowProps,
    commonCellsData,
    inEditMode,
    dispatch,
    tableEditModeState,
    stylesheetsIdToNameMap,
    componentId,
    TextView,
    stylesheets,
    globalVariables,
    site,
    themeSettingsData,
    themeColorsData,
    selectedParentTheme,
}: TableRowProps) => (
    <tr>
        {rowProps.map((cellProps, index) => {
            const cellIndex = firstCellIndex + index;
            return (
                <TableCell
                    key={index}
                    cellIndex={cellIndex}
                    cellProps={cellProps}
                    commonCellsData={commonCellsData}
                    tableInEditMode={inEditMode}
                    stylesheetsIdToNameMap={stylesheetsIdToNameMap}
                    dispatch={dispatch}
                    isSelected={inEditMode && tableEditModeState.selectedCellsIndexes.includes(cellIndex)}
                    cellInEditMode={inEditMode && tableEditModeState.cellInEditModeIndex === cellIndex}
                    componentId={componentId}
                    TextView={TextView}
                    stylesheets={stylesheets}
                    globalVariables={globalVariables}
                    site={site}
                    themeSettingsData={themeSettingsData}
                    themeColorsData={themeColorsData}
                    selectedParentTheme={selectedParentTheme}
                />
            );
        })}
    </tr>
);
