import { getCookie, setCookie } from "../../services/cookie";
import { ONE_LANG_COOKIE_KEY } from "../../constants/app";
import { normalizeLocale } from "../../../../server/shared/utils/normalizeLocale";
import { getDefultLocaleId } from "./getDefaultLocale";

const DEFAULT_LOCALE = getDefultLocaleId();

export const
    getLocaleCookie = (): string => getCookie(ONE_LANG_COOKIE_KEY, DEFAULT_LOCALE),
    getNormalizedLocaleCookie = (): string => normalizeLocale(getLocaleCookie()),
    setLocaleCookie = (locale: string) => {
        setCookie(ONE_LANG_COOKIE_KEY, locale);
    };
