import React from "react";
import oneColor from "onecolor";
import { Flex } from '../../../../../view/reflexbox/index';
import type {
    InstancePropertiesPanelProps,
    InstancePropertiesPanelRegistryPage
} from "../../../../PropertiesPanel/instance/types";
import { MveHeaderPpPageId } from "./MveHeaderPpPageId";
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';
import {
    PropertiesPanelFontFamilySelector,
} from "../../../../PropertiesPanel/view/components/PropertiesPanelFontFamilySelector";
import type { MobileHeaderState } from "../../../../Mobile/header/types";
import {
    CHANGE_MVE_TITLE_FONT_FAMILY_ACTION,
    CHANGE_TITLE_TEXT_COLOR_ACTION,
    CHANGE_TITLE_TEXT_COLOR_ACTION_AUTO_COLOR,
    TOGGLE_MVE_TITLE_FONT_STYLE_ACTION,
    TOGGLE_MVE_TITLE_FONT_WEIGHT_ACTION,
    TOGGLE_MVE_TITLE_UNDERLINE_ACTION,
} from "../actions";
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import TextFormatButtonGroup from "../../../../presentational/IconButtonsGroup/TextFormat";
import ColorProperty from '../../../../presentational/ColorProperty/index';
import {
    PropertiesPanelTextSection,
} from "../../../../PropertiesPanel/view/components/Section/PropertiesPanelTextSection";
import { THEME_COLOR_OVERRIDE_COLOR_PICKER } from '../../../../ColorPicker/constants';

class TitleStyleMvePpPageClass extends React.Component<InstancePropertiesPanelProps> {
    render() {
        const
            {
                title: {
                    style: {
                        fontFamily,
                        fontWeight,
                        fontStyle,
                        underline,
                        color,
                        themeColor,
                    },
                },
            } = this.props.state.instance?.state as MobileHeaderState,
            { dispatch } = this.props,
            isBold = fontWeight === 'bold',
            isItalic = fontStyle === 'italic';

        return (
            <div>
                <PropertiesPanelTextSection>{
                    `msg: mve.pp.titleStyle.hintText {Customize the look of the title in your header.}`
                }</PropertiesPanelTextSection>
                <PropertyContainer label="msg: common.font {Font}">
                    <PropertiesPanelFontFamilySelector
                        selected={fontFamily}
                        dispatch={dispatch}
                        onChangeAction={CHANGE_MVE_TITLE_FONT_FAMILY_ACTION}
                        isPropertiesPanel
                    />
                </PropertyContainer>
                <VerticalSpacer y={10} />
                <Flex align="center" justify="space-between">
                    <TextFormatButtonGroup
                        bold={{
                            isSelected: isBold,
                            onClickAction: TOGGLE_MVE_TITLE_FONT_WEIGHT_ACTION,
                        }}
                        italic={{
                            isSelected: isItalic,
                            onClickAction: TOGGLE_MVE_TITLE_FONT_STYLE_ACTION,
                        }}
                        underline={{
                            isSelected: underline,
                            onClickAction: TOGGLE_MVE_TITLE_UNDERLINE_ACTION,
                        }}
                        dispatch={dispatch}
                    />
                </Flex>
                <VerticalSpacer y={10} />
                {
                    (this.props.state.instance?.state.autoColorMode)
                        ? <ColorProperty
                            colorPickerKind={THEME_COLOR_OVERRIDE_COLOR_PICKER}
                            themeColorsData={this.props.state.instance.state.themeColorsData}
                            themeColor={themeColor}
                            label="msg: common.textColor {Text colour}"
                            onChangeAction={CHANGE_TITLE_TEXT_COLOR_ACTION_AUTO_COLOR}
                            dispatch={dispatch}
                        />
                        : <ColorProperty
                            label="msg: common.textColor {Text colour}"
                            color={oneColor(color).hsl().toJSON()}
                            onChangeAction={CHANGE_TITLE_TEXT_COLOR_ACTION}
                            onTheFlyChange
                            dispatch={dispatch}
                        />
                }
            </div>
        );
    }
}

export const TitleStyleMveHeaderPpPage: InstancePropertiesPanelRegistryPage = {
    id: MveHeaderPpPageId.TITLE_STYLE,
    title: 'msg: common.titleStyle {Title style}',
    View: TitleStyleMvePpPageClass,
};
