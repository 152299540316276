import isStretchComponentKind from "../../oneweb/isStretchComponentKind";
import type { Block } from "../flowTypes";
import type { SectionComponent } from "../../oneweb/Section/flowTypes";
import { ContainerKinds } from "../../../utils/containerKinds";
import getUpdatedLayoutBasedOnOptions from "../../ModernLayouts/getUpdatedLayoutBasedOnOptions";

const ContainerKindsForBorderOffsetAdjustment: { [k: string]: boolean } = { ...ContainerKinds };

export const getBorderWidths = ({ kind, style, stretch = false }: Block): any => {
        if (!ContainerKindsForBorderOffsetAdjustment[kind]) {
            return null; // we are only concerned with container components which can influence offsets of children
        }

        if (!style || !style.border || style.border.style === "none") {
            return null;
        }

        const borderWidth = style.border.width;

        if (borderWidth) {
            return {
                top: borderWidth[0],
                left: isStretchComponentKind(kind, stretch) ? 0 : borderWidth[3],
                right: isStretchComponentKind(kind, stretch) ? 0 : borderWidth[1],
                bottom: borderWidth[2]
            };
        }

        return null;
    }, adjustColumnsForModernSection = (section: SectionComponent, columns: Record<string, any>[], createEmptyCol: Function) => {
        const { newLayout: layout } = getUpdatedLayoutBasedOnOptions(section);
        if (!Object.keys(layout).length) {
            return columns;
        }
        const [row] = layout[section.id].rows,
            { cols } = row;
        if (columns.length !== cols.length) {
            return columns;
        }
        let updatedColumns: any = [];
        cols.forEach(({ hAlign }, i) => {
            if (updatedColumns.length === 0 && hAlign !== 'left') {
                updatedColumns.push(createEmptyCol(i));
            }
            if (updatedColumns.length === 1 && hAlign !== 'center') {
                updatedColumns.push(createEmptyCol(i));
            }
            if (updatedColumns.length === 2 && hAlign !== 'right') {
                updatedColumns.push(createEmptyCol(i));
            }
            updatedColumns.push(columns[i]);
            return updatedColumns;
        });
        return updatedColumns;
    };

