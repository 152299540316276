/* eslint-disable max-len */

import {
    demoCurrentAuthenticatedUserAction,
    demoLogInAction,
    demoLoginCompleteAction,
    demoCurrentUserInfoAction,
    demoInitializeUserMediaPath,
    DEMO_CURRENT_AUTHENTICATED_USER_FAILURE_ACTION,
    DEMO_CURRENT_AUTHENTICATED_USER_SUCCESS_ACTION,
    DEMO_CURRENT_USER_INFO_SUCCESS_ACTION,
    DEMO_CURRENT_USER_INFO_FAILURE_ACTION,
    DEMO_LOGIN_ACTION,
    DEMO_LOGIN_COMPLETE_ACTION,
    DEMO_LOGIN_SUCCESS_ACTION,
    DEMO_LOGIN_FAILURE_ACTION,
    showForgotPasswordPage,
} from "./signIn/actions";

import { loginAuthenticationSuccessfulAction,
    showDemoLoginScreenAction,
    demoSignOutAction } from '../../actions';
import { DOMAIN_FROM_AUTH_COOKIE_VALUE } from "../../../src/redux/middleware/cookie/actionTypes";
import {
    EXPIRED_CODE_EXCEPTION,
    CODE_MISMATCH_EXCEPTION,
    KEEP_LOGGED_IN_SESSION,
    USER_DISABLED_EXCEPTION, USER_LAMBDA_VALIDATION_EXCEPTION
} from "../../constants";
import type { AppStore } from "../../../src/redux/modules/flowTypes";
import AppConfig from "../../../src/utils/AppConfig";
import { getAppConfig } from "../../../src/components/App/epics/appConfig/appConfig";
import {
    setTrialRemainingDaysAction,
    setTrialUserHashedTokenAction,
    setTrialUserSubIdAction,
    setTrialEmailVerifiedStatusAction,
    setTrialUserEmailAction,
    setTrialControlPanelUserAction,
    setTrialDomainNameAction,
} from "../actions";
import { createScheduledAction } from "../../../src/redux/middleware/schedule/actionCreators";
import isTestEnv from "../../../src/debug/isTestEnv";
import { dateDiffInDays } from "../../../src/utils/date";
import { TrialUserCustomAttributes } from "../../../../trial/lambda-functions/src/TrialUserCustomAttributes";
import { TrialUserAttributes } from "../../../../trial/lambda-functions/src/TrialUserAttributes";
import {
    TRIAL_IMPRO_COOKIES_TIMEOUT,
    GOOGLE_ANALYTICS_EMAIL_VERIFIED_LOCAL_STORAGE_KEY,
    LOCAL_STORAGE_VALUE_LANDING_PAGE_WITHOUT_MODAL,
    LOCAL_STORAGE_VALUE_LANDING_PAGE_WITH_MODAL,
    LOCAL_STORAGE_LANDING_PAGE_KEY,
    LOCAL_STORAGE_LANDING_PAGE_SOCIAL_SIGNUP,
    LOCAL_STORAGE_SIGNUP_SUCCESSFUL_GTM_EVENT_KEY
} from "./constants";
import { showExpiredPasswordError } from "./forgotPassword/actions";
import { DEMO_RESET_PASSWORD_FAILURE_ACTION } from "./resetPassword/actions";
import { getTrialAuth } from "../../../../trial/services/trialAuth";
import { TrialImproCookieKey } from '../../../../server/shared/trial/TrialImproCookieKey';
import { AwsAmplifyCognitoUser } from "../../../../server/shared/awsServices/cognito/AwsAmplifyCognitoUser";
import { getCookie, ONE_COM_BASE_COOKIE_DOMAIN, setManyCookies, unsetCookie } from "../../../src/services/cookie";
import { setHandyInterval } from "../../../../server/shared/utils/timers/setHandyInterval";
import { LOCALHOST_DOMAIN } from "../../../src/debug/constants";
import { isLocalhost } from "../../../src/debug/isLocalhost";
import { makeTrialDomainString } from "../../../../server/shared/trial/domain/trialDomain";
import { TrialAuthPage } from "../../../../server/shared/trial/TrialAuthPage";
import { addInfoMessage } from "../../../src/components/Toaster/actionCreators";
import styles from "./main/demoLoginPage/DemoLoginPage.css";
import { demoLoginAppSel } from "../selectors";
import {
    DEMO_SIGNUP_SUCCESS_ACTION,
    DEMO_GET_CLIENT_IP_REQUEST_ACTION,
    demoGetClientIpFailureAction,
    demoGetClientIpSuccessAction, showSignInPage
} from "./signUp/actions";
import { getClientIPV4 } from "../../../src/utils/getClientIP";
import { isMobileDevice } from "./validators";
import { normalizeEmail } from "../../../../server/shared/utils/normalizeEmail";
import { trackEmailVerificationGoal } from "./emailVerification/actions";
import { sendDemoLoginGTMEvent } from "../gtm/actions";
import { TrialLoginGTMAction } from "../gtm/GTMAction";
import { trySsoTrialWbtgen } from "./ssoLogin/ssoTrialWbtgen";
import {
    trySelectedPublicTemplateLogIn,
} from "../../../src/components/TemplateSelector/public/selectedPublicTemplate";
import { TRIAL_ENDED_COOKIE, TRIAL_SOCIAL_SSO_FAILURE } from "../../../../server/shared/trial/constants";
import { DEMO_LOGIN_GTM_CATEGORY, DEMO_LOGIN_GTM_EVENT, DEMO_SIGNUP_COMPLETED_SIGNUP } from "../gtm/events";
import { getTrialDomainName } from "../../../../server/shared/trial/utils/trialUtils";

const
    appConfig = AppConfig(getAppConfig()),
    trialValidDuration = appConfig.getOptional('oneWeb.trial.awsConfig.serverless.trialValidDuration');

const getTrialUserIdentityId = trialUser => AwsAmplifyCognitoUser(trialUser).storage.getIdentityId();

const setImproCookies = (refresh/*: boolean */) => getTrialAuth()
    .getAccessTokenAndEssentialCredentials(refresh)
    .then(({ accessToken, credentials }) => setManyCookies({
        [TrialImproCookieKey.TOKEN]: accessToken,
        [TrialImproCookieKey.ACCESS_KEY]: credentials.accessKeyId,
        [TrialImproCookieKey.SECRET_KEY]: credentials.secretAccessKey,
        [TrialImproCookieKey.SESSION_ID]: credentials.sessionToken,
    }, (isLocalhost() ? { domain: LOCALHOST_DOMAIN } : {}), true));

export const demoLoginMiddleware = (store: AppStore) => {
    let improCookiesTimer;

    const completeLogin = trialUser => {
        store.dispatch(demoLoginCompleteAction(getTrialUserIdentityId(trialUser)));

        setImproCookies(false);

        if (!isTestEnv() && !improCookiesTimer) {
            improCookiesTimer = setHandyInterval(setImproCookies, TRIAL_IMPRO_COOKIES_TIMEOUT, true /* refresh */);
        }
    };

    return (next: Dispatch) => (action: Action) => {
        if (action.type === DEMO_LOGIN_ACTION) {
            if (trySelectedPublicTemplateLogIn()) {
                return store.dispatch(demoSignOutAction());
            }

            const
                appClientId = appConfig.get('oneWeb.trial.awsConfig.cognito.appClientId'),
                ssoUser = trySsoTrialWbtgen(appClientId);

            if (ssoUser) {
                return store.dispatch(demoLogInAction({ username: ssoUser }));
            }

            const trialEndedCookie = getCookie(TRIAL_ENDED_COOKIE, null);
            if (trialEndedCookie) {
                store.dispatch(showDemoLoginScreenAction());
                store.dispatch(showSignInPage());
                store.dispatch({
                    type: DEMO_LOGIN_FAILURE_ACTION,
                    payload: {
                        code: USER_DISABLED_EXCEPTION,
                        message: trialEndedCookie
                    }
                });
                return unsetCookie(TRIAL_ENDED_COOKIE, { domain: ONE_COM_BASE_COOKIE_DOMAIN });
            }
            const trialSocialSSOFailureCookie = getCookie(TRIAL_SOCIAL_SSO_FAILURE, null);
            if (trialSocialSSOFailureCookie) {
                store.dispatch(showDemoLoginScreenAction());
                store.dispatch(showSignInPage());
                store.dispatch({
                    type: DEMO_LOGIN_FAILURE_ACTION,
                    payload: {
                        code: USER_LAMBDA_VALIDATION_EXCEPTION,
                        message: trialSocialSSOFailureCookie
                    }
                });
                return unsetCookie(TRIAL_SOCIAL_SSO_FAILURE, { domain: ONE_COM_BASE_COOKIE_DOMAIN });
            }

            store.dispatch(demoCurrentAuthenticatedUserAction());
        }

        if (action.type === DEMO_LOGIN_COMPLETE_ACTION) {
            const partner = appConfig.get('partnerName');
            const trialDomain = makeTrialDomainString(action.payload, getTrialDomainName(partner));
            store.dispatch(loginAuthenticationSuccessfulAction());
            store.dispatch({
                type: DOMAIN_FROM_AUTH_COOKIE_VALUE,
                payload: trialDomain
            });
            store.dispatch(demoCurrentUserInfoAction());
            if (window.location.hash.replace(/^#/, "") === TrialAuthPage.VERIFICATION) {
                store.dispatch(addInfoMessage(
                    "demo.login.emailVerification.loggedInemailVerified.msg",
                    "msg: demo.login.emailVerification.loggedInemailVerified.msg {Great! Your email has successfully been verified.}",
                    '',
                    null,
                    styles.emailSuccessfullyVerifiedToasterMessage,
                    styles.emailSuccessfullyVerifiedToasterClose
                ));
                history.pushState("", document.title, window.location.pathname);
                if (window.localStorage && !window.localStorage.getItem(GOOGLE_ANALYTICS_EMAIL_VERIFIED_LOCAL_STORAGE_KEY)) {
                    store.dispatch(trackEmailVerificationGoal());
                    // @ts-ignore
                    window.localStorage.setItem(GOOGLE_ANALYTICS_EMAIL_VERIFIED_LOCAL_STORAGE_KEY, true);
                }
            }
        }

        if (action.type === DEMO_SIGNUP_SUCCESS_ACTION) {
            // @ts-ignore
            window.localStorage.setItem(LOCAL_STORAGE_SIGNUP_SUCCESSFUL_GTM_EVENT_KEY, true);

            const actionParams = action.actionParams[0];
            const userData = {
                username: normalizeEmail(actionParams.username),
                password: actionParams.password,
            };
            store.dispatch(demoLogInAction(userData));
        }

        if (action.type === DEMO_LOGIN_SUCCESS_ACTION) {
            const loginState = demoLoginAppSel(store.getState());
            const keepLoggedIn = loginState.signin.keepLoggedIn;
            if (keepLoggedIn) {
                // @ts-ignore
                window.localStorage.setItem(KEEP_LOGGED_IN_SESSION, true);
                // @ts-ignore
                window.sessionStorage.setItem(KEEP_LOGGED_IN_SESSION, false);
            } else {
                // @ts-ignore
                window.localStorage.setItem(KEEP_LOGGED_IN_SESSION, false);
                // @ts-ignore
                window.sessionStorage.setItem(KEEP_LOGGED_IN_SESSION, true);
            }

            const userIdentityId = getTrialUserIdentityId(action.payload);

            if (window.location.hash) {
                history.pushState("", document.title, window.location.pathname + window.location.search);
            }

            completeLogin(action.payload);
            store.dispatch(demoInitializeUserMediaPath(userIdentityId));

            const userSubId = AwsAmplifyCognitoUser(action.payload).storage.getSubId();

            const isSocialSignupFromLandingPage = window.localStorage.getItem(LOCAL_STORAGE_LANDING_PAGE_SOCIAL_SIGNUP);
            const gtmEventForSignupSuccess = window.localStorage.getItem(LOCAL_STORAGE_SIGNUP_SUCCESSFUL_GTM_EVENT_KEY);
            if (isSocialSignupFromLandingPage) {
                window.localStorage.removeItem(LOCAL_STORAGE_LANDING_PAGE_SOCIAL_SIGNUP);
                store.dispatch(sendDemoLoginGTMEvent({
                    eventAction: TrialLoginGTMAction.DEMO_SIGNUP_SUCCESSFUL_NEW_SIGNUP_PAGE_WITH_MODAL,
                    trialId: "Trial_" + userSubId
                }));
            }

            if (gtmEventForSignupSuccess) {
                store.dispatch(sendDemoLoginGTMEvent({
                    eventCategory: DEMO_LOGIN_GTM_CATEGORY,
                    eventAction: TrialLoginGTMAction.DEMO_SIGNUP_SUCCESSFUL,
                    eventLabel: DEMO_SIGNUP_COMPLETED_SIGNUP,
                    trialId: "Trial_" + userSubId,
                    event: DEMO_LOGIN_GTM_EVENT
                }));
            }
        }

        if (action.type === DEMO_CURRENT_AUTHENTICATED_USER_SUCCESS_ACTION) {
            const user = action.payload;
            user.getUserData(function (err) {
                if (err) {
                    store.dispatch(showDemoLoginScreenAction());
                } else {
                    store.dispatch(demoInitializeUserMediaPath(getTrialUserIdentityId(user)));
                    completeLogin(action.payload);
                }
            }, { bypassCache: true });
        }

        if (action.type === DEMO_CURRENT_AUTHENTICATED_USER_FAILURE_ACTION) {
            store.dispatch(showDemoLoginScreenAction());
        }

        if (action.type === DEMO_CURRENT_USER_INFO_SUCCESS_ACTION) {
            const localStorageSession = window.localStorage.getItem(KEEP_LOGGED_IN_SESSION) === "true";
            const sessionStorageSession = window.sessionStorage.getItem(KEEP_LOGGED_IN_SESSION) === "true";

            if (!localStorageSession && !sessionStorageSession) {
                store.dispatch(demoSignOutAction());
            }

            const user = action.payload,
                customAttrCreatedAt = user.attributes[TrialUserCustomAttributes.CREATED_AT],
                userCreatedDate = new Date(parseInt(customAttrCreatedAt, 10)),
                userHashedToken = user.attributes[TrialUserCustomAttributes.HASHED_SUB_ID],
                validDuration = parseInt(trialValidDuration, 10),
                userSubId = user.attributes[TrialUserAttributes.SUB],
                trialUserEmailVerified = user.attributes[TrialUserAttributes.EMAIL_VERIFIED],
                controlPanelUser = !!user.attributes[TrialUserCustomAttributes.IS_ONE_COM_CP_USER],
                trialDomainName = controlPanelUser ? user.username : null,
                trialUserEmail = user.attributes[TrialUserAttributes.EMAIL],
                now = new Date();

            if (trialDomainName) {
                store.dispatch(setTrialDomainNameAction(trialDomainName));
            }
            store.dispatch(setTrialUserEmailAction(trialUserEmail));
            store.dispatch(setTrialUserSubIdAction(userSubId));
            store.dispatch(setTrialControlPanelUserAction(controlPanelUser));
            store.dispatch(setTrialUserHashedTokenAction(encodeURIComponent(userHashedToken)));
            store.dispatch(setTrialEmailVerifiedStatusAction(trialUserEmailVerified));

            // For New Signup Trial GTM Event
            const landingPageValue = window.localStorage.getItem(LOCAL_STORAGE_LANDING_PAGE_KEY);
            if (landingPageValue === LOCAL_STORAGE_VALUE_LANDING_PAGE_WITH_MODAL) {
                store.dispatch(sendDemoLoginGTMEvent({
                    eventAction: TrialLoginGTMAction.DEMO_SIGNUP_SUCCESSFUL_NEW_SIGNUP_PAGE_WITH_MODAL,
                    trialId: "Trial_" + userSubId
                }));
            } else if (landingPageValue === LOCAL_STORAGE_VALUE_LANDING_PAGE_WITHOUT_MODAL) {
                store.dispatch(sendDemoLoginGTMEvent({
                    eventAction: TrialLoginGTMAction.DEMO_SIGNUP_SUCCESSFUL_NEW_SIGNUP_PAGE_WITHOUT_MODAL,
                    trialId: "Trial_" + userSubId
                }));
            }
            window.localStorage.removeItem(LOCAL_STORAGE_LANDING_PAGE_KEY);
            if (isMobileDevice()) {
                store.dispatch(sendDemoLoginGTMEvent({
                    eventAction: TrialLoginGTMAction.DEVICE_BLOCKED,
                    trialId: "Trial_" + userSubId
                }));
                //WBTGEN-19089 Adding delay of 1 sec to logout user on signup in mobile device
                const timeout = 1000;
                store.dispatch(createScheduledAction({
                    actionToDispatch: demoSignOutAction(),
                    timeout
                }));
            }

            const daysRemaining = validDuration - dateDiffInDays(userCreatedDate, now);

            // Refresh the timer every day for the first x days
            let timeout = 24 * 60 * 60 * 1000;
            if (daysRemaining <= 1) {
                // Refresh the data every hour once it reaches last day of the trial
                timeout = 60 * 60 * 1000;
            }

            store.dispatch(setTrialRemainingDaysAction(daysRemaining));

            if (!isTestEnv()) {
                store.dispatch(createScheduledAction({
                    actionToDispatch: demoCurrentUserInfoAction(),
                    timeout
                }));
            }
        }

        // Commented out as per: WBTGEN-9121
        // if (action.type === WORKSPACE_READY) {
        //     store.dispatch(initiateDemoDomainAvailabilityAction());
        // }

        if (action.type === DEMO_CURRENT_USER_INFO_FAILURE_ACTION) {
            if (!isTestEnv()) {
                store.dispatch(createScheduledAction({
                    actionToDispatch: demoCurrentUserInfoAction(),
                    timeout: 30 * 1000
                }));
            }
        }

        if (action.type === DEMO_RESET_PASSWORD_FAILURE_ACTION) {
            if ([EXPIRED_CODE_EXCEPTION, CODE_MISMATCH_EXCEPTION].indexOf(action.payload.code) > -1) {
                store.dispatch(showForgotPasswordPage());
                store.dispatch(showExpiredPasswordError());
            }
        }

        if (action.type === DEMO_GET_CLIENT_IP_REQUEST_ACTION) {
            getClientIPV4()
                .then(data => {
                    store.dispatch(demoGetClientIpSuccessAction(data));
                })
                .catch(() => {
                    store.dispatch(demoGetClientIpFailureAction());
                });
        }

        return next(action);
    };
};
