import * as colorMapper from "../../../../dal/pageMapAdapter/mappers/Base/color";
import type { Color } from '../../../mappers/flowTypes';

// TODO: react colors from css; fix tests

export const
    DEFAULT_COLOR: Color = colorMapper.toHsl('#f7f7f7'), // TODO: Should-we / Can-we replace it with '#f6f6f6'
    DefaultGradient = {
        color: ['HSL', 0, 0, 0, 0],
        fadePoint: 50,
        degree: 0
    };
