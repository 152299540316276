import * as R from "ramda";
import { featuredProductsIdsListVat } from './valueActionType';
import makeEpic from "../../../../../epics/makeEpic";
import currentPageIdValueActionType from "../../../../App/epics/currentPageId/valueActionType";
import { COMPONENT_ADDED, DELETED, PASTE } from "../../../../Workspace/epics/componentsEval/updateReasons";
import { REDO, UNDO, UNDO_INITIAL_STATE } from "../../../../../epics/undoManager/updateReasons";
import { receiveOnly, whenWithSelector, whenReason } from "../../../../../epics/makeCondition";
import { ComponentsEvalValueActionType } from "../../../../Workspace/epics/componentsEval/valueActionType";
import { isFeaturedProductsComponentFound } from "../../utils";
import { FEATURED_PRODUCTS_FETCH_PAGE_IDS_SUCCESS } from '../../../WebShop/epics/actionTypes';
import { siteDataValueActionType } from "../../../../App/epics/siteData/valueActionType";
import { getPublicPages } from "../../../../App/epics/siteData/utils/pages";
import { getValidPageIds } from "../../../WebShop/utils";
import { FILL_SPECIFIC_SITE_SETTINGS_DURING_PAGE_OR_TEMPLATE_IMPORT } from "../../../../App/epics/siteSettings/actionTypes";
import { getFeaturedProductsPageIds } from "../../../../../../../server/shared/utils/getWebshopPageIds";

export const
    featuredProductIdsDefaultState: Array<string> = [];

const
    updateReasonsToCheck = [
        COMPONENT_ADDED,
        DELETED,
        PASTE,
        UNDO,
        REDO,
        UNDO_INITIAL_STATE
    ],
    ComponentsMapSelector = whenWithSelector(
        ComponentsEvalValueActionType,
        a => a.payload.state.componentsMap,
        a => updateReasonsToCheck.indexOf(a.epicUpdateReason) !== -1
    );

const getSitePageIds = (publicPages) => {
    let result: string[] = [];
    if (Array.isArray(publicPages)) {
        result = publicPages.map((page) => page.pageId);
    }
    return result;
};

export default makeEpic({
    defaultState: featuredProductIdsDefaultState,
    valueActionType: featuredProductsIdsListVat,
    saveIntoSiteSettings: { key: 'featuredProductsPageIds' },
    updaters: [
        {
            keepFullActions: true,
            conditions: [
                receiveOnly(currentPageIdValueActionType),
                receiveOnly(siteDataValueActionType),
                ComponentsMapSelector,
            ],
            reducer: ({ values: [{ payload: currentPageId }, { payload: siteData }, componentsMap], state }) => {
                const currentPageIds: String[] = getSitePageIds(getPublicPages(siteData));
                let featuredProductsPageIds = state;
                const onCurrentPage = currentPageIds.indexOf(currentPageId) !== -1;
                const isPageIdAdded = featuredProductsPageIds.indexOf(currentPageId) !== -1;

                const featuredProductsComponentFound = isFeaturedProductsComponentFound(componentsMap);

                if (onCurrentPage) {
                    if (!isPageIdAdded && featuredProductsComponentFound) {
                        // add page id to featuredProductsPageIds
                        featuredProductsPageIds = [...featuredProductsPageIds, currentPageId];
                    } else if (isPageIdAdded && !featuredProductsComponentFound) {
                        // remove page id from featuredProductsPageIds
                        const pageIdIndex = featuredProductsPageIds.indexOf(currentPageId);
                        featuredProductsPageIds = [
                            ...featuredProductsPageIds.slice(0, pageIdIndex),
                            ...featuredProductsPageIds.slice(pageIdIndex + 1, featuredProductsPageIds.length),
                        ];
                    }
                }
                return { state: featuredProductsPageIds };
            }
        },
        {
            conditions: [
                FEATURED_PRODUCTS_FETCH_PAGE_IDS_SUCCESS
            ],
            reducer: ({ values: [{ featuredProductsPageIds }], state }) => {
                if (Array.isArray(featuredProductsPageIds) && featuredProductsPageIds.length > 0) {
                    return { state: featuredProductsPageIds };
                }
                return { state };
            }
        },
        // Page with featured products is deleted. DB is updated on next immediate save.
        // Issue here: Just delete and not make any save. DB still have reference to stale id.
        // But, we are doing an extra check for stale ids in publishing.
        {
            conditions: [
                siteDataValueActionType
            ],
            reducer: ({ values: [siteData], state }) => {
                /* Validate the list of pageIds here. */
                const featuredProductsPageIds = getValidPageIds(state, siteData.getPageIds());
                if (state !== featuredProductsPageIds) {
                    return { state: featuredProductsPageIds };
                }
                return { state };
            }
        },
        // Add new page or template with featured products
        {
            conditions: [FILL_SPECIFIC_SITE_SETTINGS_DURING_PAGE_OR_TEMPLATE_IMPORT],
            reducer: ({ values: [{ pages }], state }) => {
                const { featuredProductsPageIds } = getFeaturedProductsPageIds({ pages });
                if (featuredProductsPageIds.length) {
                    return { state: R.concat(state, featuredProductsPageIds) };
                }
                return { state };
            }
        },
        // Removing deleted pages on page load
        {
            conditions: [
                whenReason(featuredProductsIdsListVat, UNDO_INITIAL_STATE),
                receiveOnly(siteDataValueActionType)
            ],
            reducer: ({ values: [pages, siteData] }) => {
                const featuredProductsPageIds = getValidPageIds(pages, siteData.getPageIds());
                return { state: featuredProductsPageIds };
            }
        }
    ]
});
