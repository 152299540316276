import * as R from 'ramda';

import type { ComponentsAdjustmentData, EpicState, UpdateReason } from './flowTypes';
import type { Components } from '../../../../redux/modules/children/workspace/flowTypes';
import type { IsUndoableChangeHook } from '../../../../epics/undoManager/helpers.flowTypes';

import * as interactionModes from './userInteractionMutations/interactionModes';
import * as updateReasons from './updateReasons';
import { NonUndoableActions } from '../../../../view/oneweb/registry/index';
import {
    SHOW_MODERN_FOOTER_LAYOUT_LEFT_PANEL,
    SHOW_MODERN_HEADER_LAYOUT_LEFT_PANEL
} from "../../../ModernLayouts/actionTypes";

const
    skipReasons: { [key: string]: boolean } = {
        [updateReasons.MOUSE_DOWN_ON_HANDLE]: true,
        [updateReasons.CHANGE_SCOPE]: true,
        [updateReasons.FULL_WIDTH_CHANGE_DUE_TO_WINDOW_RESIZE]: true,
        [updateReasons.RELATIONS_UPDATED]: true,
        [updateReasons.ADJUSTMENT_DATA_APPLIED]: true,
        [updateReasons.ADJUSTMENT_DATA_APPLIED_AFTER_COMPONENT_ADD]: true,
        [updateReasons.SHIFTDOWN_DURING_TYPING]: true,
        [updateReasons.COMPONENT_HEIGHT_CHANGE_DURING_TYPING]: true,
        [updateReasons.SYNC_RELATIONS]: true,
        [updateReasons.COMPONENT_DEPS]: true,
        [updateReasons.SWITCHED_TO_MOBILE_EDITOR]: true,
        [updateReasons.SWITCHED_TO_PREVIEW]: true,
        [updateReasons.UPGRADE_MODERN_HEADER_FOOTER]: true,
        [updateReasons.REMOVE_WEBSHOP_MHF_DATA]: true,
        [SHOW_MODERN_FOOTER_LAYOUT_LEFT_PANEL]: true,
        [SHOW_MODERN_HEADER_LAYOUT_LEFT_PANEL]: true,
        [updateReasons.PROPERTY_CHANGE_ON_HIDDEN_CMP_HOVER]: true
    };

const isUndoableChange: IsUndoableChangeHook<EpicState, UpdateReason> =
    ({ prevState, nextState, updateReason, sourceAction }) => {
        if (NonUndoableActions[sourceAction.type]) {
            return false;
        }

        const
            nextComponentsMap = nextState.state.componentsMap,
            notIdleChange = nextState.scope.userInteraction.mode !== interactionModes.IDLE,
            userInteractionWasTransientAndBecomeIdle =
                prevState.scope.userInteraction.mode !== interactionModes.IDLE &&
                nextState.scope.userInteraction.mode === interactionModes.IDLE,
            componentsMapNotChanged = prevState.state.componentsMap === nextComponentsMap,
            componentsMapNotChangedDuringPermamentChange =
                componentsMapNotChanged && !userInteractionWasTransientAndBecomeIdle;

        const notUndoable = skipReasons[updateReason] || notIdleChange || componentsMapNotChangedDuringPermamentChange;

        return !notUndoable;
    };

type UndoStateParts = [Components, ComponentsAdjustmentData]

const mergeOnAdjustmentDataApplied = (
    undoStatePartsBefore: UndoStateParts,
    undoStatePartsAfter: UndoStateParts,
    epicState: EpicState
): UndoStateParts => {
    const
        componentsMapBefore = undoStatePartsBefore[0],
        componentsMapAfter = undoStatePartsAfter[0];

    if (interactionModes.isTransientByMouse(epicState.scope.userInteraction.mode)) {
        return undoStatePartsBefore;
    }

    const mergedComponentsMap = R.mapObjIndexed((component, componentId) => {
        if (componentsMapAfter[componentId]) {
            const { height, width, top } = componentsMapAfter[componentId];
            return { ...component, height, width, top };
        } else {
            return component;
        }
    }, componentsMapBefore);

    return [mergedComponentsMap, undoStatePartsAfter[1]];
};

export const componentEvalUndoAmendCasesMap = {
    [updateReasons.ADJUSTMENT_DATA_APPLIED]: { merge: mergeOnAdjustmentDataApplied },
    [updateReasons.ADJUSTMENT_DATA_APPLIED_AFTER_COMPONENT_ADD]: { merge: mergeOnAdjustmentDataApplied },
    [updateReasons.COMPONENT_HEIGHT_CHANGE_DURING_TYPING]: { merge: mergeOnAdjustmentDataApplied },
    [updateReasons.SHIFTDOWN_DURING_TYPING]: { merge: mergeOnAdjustmentDataApplied },
    [updateReasons.SELECT_UPDATE_HOVER_BOX_STATE]: true,
    [updateReasons.IMPLICIT_WRAP_UPDATE]: true
};

export default isUndoableChange;
