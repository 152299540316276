import type { InputLabelProps } from "../flowTypes";

type Result = {
    filled: boolean;
    value: string;
    translate: boolean;
};

const defaults = {
    filled: true,
    value: '',
    translate: true
};

export default (label?: InputLabelProps): Result => {
    if (!label) return { ...defaults, filled: false };
    else if (typeof label === 'string') return { ...defaults, value: label };
    return { ...defaults, ...label };
};
