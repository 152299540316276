import * as React from "react";
import { pure } from 'recompose';
import {
    MctaPropertyContainer,
} from "../../../../componentMainActions/index";
import { DEFAULT_COLOR_PICKER, THEME_COLOR_PICKER } from '../../../../ColorPicker/constants';
import ColorProperty from "../../../../presentational/ColorProperty";
import { DEFAULT_TEXT_TOOLBAR_COLOR_BOX_STYLE } from "../../../Text/constants";
import { findSuitableTextColorName } from "../../../../ThemeGlobalData/utils/commonUtils";
import { getThemeRulesForBackground } from "../../../../ThemeGlobalData/themeRules";
import { WEBSHOP_FOOTER_CMP_COLOR_CHANGED, WEBSHOP_FOOTER_CMP_COLOR_CHANGED_AUTO_COLOR } from "../../actionTypes";

const
    WebShopFooterCmpsMCTAView = pure((props) => {
        const {
            intl,
            selectedComponent,
            themeSettingsData,
            themeColorsData,
            dispatch,
            selectedComponentProps
        } = props;

        const
            themeOverrideColor = selectedComponent.generic && selectedComponent.generic.themeOverrideColor,
            selectedColor = selectedComponent.generic && selectedComponent.generic.textStyle && selectedComponent.generic.textStyle.color,
            { autoColorMode } = themeSettingsData;
        const textColorTitle = intl.msgJoint("msg: mcta.textColor {Text colour}");
        return (
            <div>
                <MctaPropertyContainer label="msg: common.color {Colour}">
                    {
                        autoColorMode ?
                            <ColorProperty
                                colorPickerKind={THEME_COLOR_PICKER}
                                themeColorsData={themeColorsData}
                                themeColor={themeOverrideColor}
                                defaultThemeColor={findSuitableTextColorName(
                                    getThemeRulesForBackground(selectedComponentProps.selectedParentTheme, themeColorsData).background,
                                    themeColorsData
                                )}
                                onChangeAction={WEBSHOP_FOOTER_CMP_COLOR_CHANGED_AUTO_COLOR}
                                dispatch={dispatch}
                                title={textColorTitle}
                                colorBoxStyle={DEFAULT_TEXT_TOOLBAR_COLOR_BOX_STYLE}
                                showCaret
                            /> :
                            <ColorProperty
                                colorPickerKind={DEFAULT_COLOR_PICKER}
                                dispatch={dispatch}
                                onChangeAction={WEBSHOP_FOOTER_CMP_COLOR_CHANGED}
                                onTheFlyChange
                                color={selectedColor}
                                title={textColorTitle}
                                colorBoxStyle={DEFAULT_TEXT_TOOLBAR_COLOR_BOX_STYLE}
                                showCaret
                            />
                    }
                </MctaPropertyContainer>
            </div>
        );
    });

export default WebShopFooterCmpsMCTAView;
