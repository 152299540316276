import { SHUTTERSTOCK_ACTION_KIND } from "./constants";
import type { ShutterstockKindT } from "./types";
import { SHUTTERSTOCK_SEARCH_INPUT_CHANGE_ACTION } from "./actions";

export const isSutterstockKindAction = (kind: ShutterstockKindT, action: Record<string, any>): boolean => {
    let actionKind;

    if (action[SHUTTERSTOCK_ACTION_KIND] !== undefined) {
        actionKind = action[SHUTTERSTOCK_ACTION_KIND];
    } else if (action.extraPayload && action.extraPayload[SHUTTERSTOCK_ACTION_KIND] !== undefined) {
        actionKind = action.extraPayload[SHUTTERSTOCK_ACTION_KIND];
    } else if (
        action.type === SHUTTERSTOCK_SEARCH_INPUT_CHANGE_ACTION
        && action.params
        && action.params[SHUTTERSTOCK_ACTION_KIND] !== undefined
    ) {
        actionKind = action.params[SHUTTERSTOCK_ACTION_KIND];
    }

    return !!actionKind && actionKind === kind;
};
