import { DataSite, DataPageRef, DataSectionLink } from "../../../../../../dal/model/index";
import { isPagesOrSectionLinksLimitExceededGivenSubscriptionType } from "../../subscriptionData/utils";

export const getPublicPages = (siteData: DataSite): Array<DataPageRef> => {
    return siteData.getAllPageRefs().filter(page => page.public);
};

export const getPublicPageIds = (siteData: DataSite) => {
    return getPublicPages(siteData).map(page => page.id);
};

export const createPageIdIndexMap = (siteData: DataSite) => {
    let map = {};
    const pageIds = getPublicPageIds(siteData);

    for (let i = 0; i < pageIds.length; i++) {
        map[pageIds[i]] = i;
    }

    return map;
};

export const getPublicSectionLinks = (siteData: DataSite): Array<DataSectionLink> => {
    return siteData.getAllSectionLinkRefs().filter(sectionLink => !sectionLink.hidden);
};

export const getPublicSectionLinkIds = (siteData: DataSite): Array<string> => {
    return getPublicSectionLinks(siteData).map(section => section.id);
};

export const createSectionLinksIdIndexMap = (siteData: DataSite) => {
    let map = {};
    const sectionLinkIds = getPublicSectionLinkIds(siteData);

    for (let i = 0; i < sectionLinkIds.length; i++) {
        map[sectionLinkIds[i]] = i;
    }

    return map;
};

export const isPagesOrSectionLinksLimitExceededGivenSiteDataAndSubscriptionType = (
    siteData: DataSite, subscriptionType: string
): boolean => {
    const pages = getPublicPages(siteData),
        sectionLinks = getPublicSectionLinks(siteData);
    return isPagesOrSectionLinksLimitExceededGivenSubscriptionType(pages.length, sectionLinks.length, subscriptionType);
};

