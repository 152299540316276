import React from 'react';
import type { ShutterstockState } from "../../shutterstock/types";
import { Shutterstock } from "../../shutterstock/components/Shutterstock";
import { ThirdPartyImageKind } from "./constants";
import styles from "../../freeOnecom/components/styles.css";
import { Msg } from "../../../../view/intl/index";
import type { SstockQuotaConfig } from "../../../../../../server/shared/config/types";

type Props = {
    state: ShutterstockState,
    isMultiSelect?: boolean,
    subscriptionType: string,
    dispatch: Dispatch,
    quotaConf: SstockQuotaConfig
};

export class Unsplash extends React.Component<Props> {
    getCellChildren = () => {
        return <Msg className={styles.freeButton} k="fm.dialog.tab.freeOnecom.label">Free</Msg>;
    };

    render() {
        return (
            <Shutterstock
                {...this.props}
                kind={ThirdPartyImageKind.UNSPLASH}
                showDownloadBanners
                getCellChildren={this.getCellChildren}
            />
        );
    }
}
