import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import { WORKSPACE_ON_MOUSE_ENTER, WORKSPACE_ON_MOUSE_LEAVE } from "../../actionTypes";

const defaultState = false;

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [WORKSPACE_ON_MOUSE_ENTER],
            reducer: () => ({ state: true })
        },
        {
            conditions: [WORKSPACE_ON_MOUSE_LEAVE],
            reducer: () => ({ state: false })
        }
    ]
});
