/* eslint-disable max-len */
// @ts-nocheck
import React from 'react';
import cx from 'classnames';
import { Flex } from "../../../view/reflexbox/index";
import * as colorMapper from "../../../mappers/color";
import Label from '../../../view/common/Label';
import Icons from "../../../view/Icons/index";
import HorizontalSpacer from "../../../view/common/HorizontalSpacer";
import { SHOW_COLOR_PICKER } from '../../ColorPicker/actionTypes';
import { getBoundingClientRect, getPosition } from "../../../utils/mouse";
import type { BasePropTypes } from "./flowTypes";
import styles from './ColorProperty.css';
import getStyleIntValue from "../../../utils/getStyleIntValue";
import colorPickerStyles from '../../ColorPicker/view/ColorPicker.css';
import mctaColorStyle from '../../componentMainActions/MctaColor/MctaColorProperty.css';
import type { ShowColorPickerParams } from '../../ColorPicker/flowTypes';
import {
    THEME_COLOR_PICKER, THEME_BG_OVERRIDE_COLOR_PICKER, THEME_COLOR_OVERRIDE_COLOR_PICKER, DEFAULT_COLOR_PICKER
} from '../../ColorPicker/constants';
import { getColorsFromBackgroundTheme } from '../../ThemeGlobalData/themeRules';
import type { Color } from "../../../mappers/flowTypes";

const COLOR_PICKER_WIDTH = getStyleIntValue(colorPickerStyles, 'width');
export default (props: BasePropTypes) => {
    const {
        label,
        isDefaultValue,
        changeActionPayload,
        style,
        colorBoxStyle,
        beforeOpen,
        dispatch,
        disabled,
        colorPickerOpened,
        showCaret,
        title,
        containerClassName,
    } = props;
    const getColor = (): Color|null => {
        if (props.colorPickerKind === THEME_COLOR_PICKER) {
            return props.themeColorsData[props.themeColor || props.defaultThemeColor];
        } else if (props.colorPickerKind === THEME_BG_OVERRIDE_COLOR_PICKER) {
            const { backgroundColor } = getColorsFromBackgroundTheme(props.backgroundTheme, props.themeColorsData);
            return props.themeColorsData[backgroundColor];
        } else if (props.colorPickerKind === THEME_COLOR_OVERRIDE_COLOR_PICKER) {
            return props.themeColor ? props.themeColorsData[props.themeColor] : null;
        } else {
            return props.color || props.defaultColor;
        }
    };
    const onRemoveAction: null | undefined | string = (() => {
        if (props.colorPickerKind === THEME_COLOR_OVERRIDE_COLOR_PICKER || props.colorPickerKind === DEFAULT_COLOR_PICKER) {
            return isDefaultValue ? null : props.onRemoveAction;
        }
        return null;
    })();
    return <div
        className={cx(styles.container, styles.colorContainer, containerClassName, { [styles.disabledWithoutBorder]: disabled })}
        style={style}
        onClick={e => {
            if (beforeOpen) beforeOpen();
            const
                ePosition = getPosition(e),
                boundingClientRect = getBoundingClientRect(e),
                position = showCaret
                    ? { ...ePosition, x: ePosition.x - COLOR_PICKER_WIDTH }
                    : { ...ePosition, x: boundingClientRect.left + boundingClientRect.width - (COLOR_PICKER_WIDTH - boundingClientRect.width) };

            switch (props.colorPickerKind) {
                case THEME_COLOR_PICKER: {
                    dispatch(({
                        type: SHOW_COLOR_PICKER,
                        payload: {
                            colorPickerKind: THEME_COLOR_PICKER,
                            position,
                            ...changeActionPayload,
                            themeColor: props.themeColor,
                            defaultThemeColor: props.defaultThemeColor,
                        }
                    } as { type: 'SHOW_COLOR_PICKER', payload: ShowColorPickerParams }));
                    break;
                }
                case THEME_BG_OVERRIDE_COLOR_PICKER: {
                    dispatch(({
                        type: SHOW_COLOR_PICKER,
                        payload: {
                            colorPickerKind: THEME_BG_OVERRIDE_COLOR_PICKER,
                            position,
                            ...changeActionPayload,
                            backgroundTheme: props.backgroundTheme,
                            tooltipTop: props.tooltipTop
                        }
                    } as { type: 'SHOW_COLOR_PICKER', payload: ShowColorPickerParams }));
                    break;
                }
                case THEME_COLOR_OVERRIDE_COLOR_PICKER: {
                    dispatch(({
                        type: SHOW_COLOR_PICKER,
                        payload: {
                            colorPickerKind: THEME_COLOR_OVERRIDE_COLOR_PICKER,
                            position,
                            ...changeActionPayload,
                            themeColor: props.themeColor,
                        }
                    } as { type: 'SHOW_COLOR_PICKER', payload: ShowColorPickerParams }));
                    break;
                }
                default: {
                    dispatch(({
                        type: SHOW_COLOR_PICKER,
                        payload: {
                            colorPickerKind: DEFAULT_COLOR_PICKER,
                            position,
                            ...changeActionPayload,
                            onTheFlyChange: props.onTheFlyChange,
                            color: props.color,
                        }
                    } as { type: 'SHOW_COLOR_PICKER', payload: ShowColorPickerParams }));
                    break;
                }
            }
        }}
    >
        {label && <Label disabled={disabled}>{label}</Label>}
        <Flex>
            {
                onRemoveAction && <Icons.CROSS_WITH_CIRCLE
                    className={cx(styles.crossIcon, { [styles.disabled]: disabled })}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        dispatch({ type: onRemoveAction });
                    }}
                />
            }
            {onRemoveAction && <HorizontalSpacer x={10} />}
            <div className={styles.colorWrapper}>
                <div
                    className={cx(styles.colorBox, {
                        [styles.disabled]: disabled,
                        [styles.dark]: (() => {
                            const color = getColor();
                            return !isDefaultValue && color && colorMapper.isDark(color);
                        })(),
                        [styles.light]: (() => {
                            const color = getColor();
                            return !isDefaultValue && color && colorMapper.isLight(color);
                        })(),
                        [styles.default]: isDefaultValue
                    })}
                    style={colorBoxStyle}
                    data-title={title}
                />
                {
                    showCaret &&
                        <HorizontalSpacer x={5} />
                }
                {
                    showCaret &&
                    <span
                        className={cx(mctaColorStyle.toggle, {
                            [mctaColorStyle.opened]: colorPickerOpened
                        })}
                    >
                        <span />
                    </span>
                }
            </div>
        </Flex>
    </div>;
};
