import React from "react";
import { injectIntl, Msg } from '../../../../../view/intl/index';
import { SAVE_AND_PUBLISH_BUTTON_CLICKED } from "../../../../App/actionTypes";
import WarningDialog from '../../../../../view/common/dialogs/WarningDialog/index';
import makeAppStandardErrorDialog from "../../../../App/dialogs/makeAppStandardErrorDialog";
import { closeDialog } from "../../../../App/actionCreators/index";
import { PUBLISH_CONFIRM_BUTTON_CLICKED } from '../../../epics/publishSite/actionTypes';

const PublishWarningNoStartPage = injectIntl(({ saveAndPublish, intl, dispatch }) => (
    <WarningDialog
        title="msg: publish.noStartPage.title {No Start Page is set}"
        mctaText={
            saveAndPublish
                ? "msg: publish.saveAndPublishText {Save & Publish}"
                : "msg: publish.publishText {Publish}"
        }
        mctaHandler={() => dispatch({ type: saveAndPublish ? SAVE_AND_PUBLISH_BUTTON_CLICKED : PUBLISH_CONFIRM_BUTTON_CLICKED })}
        sctaHandler={() => dispatch(closeDialog())}
        noTopBorderForFooter
    >
        <br />
        <Msg k="publish.noStartPage.text">
            You did not set a Start Page for your website.
        </Msg>
        <br />
        <br />
        <Msg k="publish.noStartPage.description">
            The website will be published, but will not appear when the domain name is entered in a browser window.
        </Msg>
        <br />
        <br />
        <a target="_blank" href={intl.msgJoint("msg: help.links.howToSetHomePageLink {https://help.one.com/hc/en-us/articles/360000306625}")}>
            <Msg k="publish.noStartPage.linkText">
                Show me how to set a Start Page
            </Msg>
        </a>
    </WarningDialog>
));

export default makeAppStandardErrorDialog(PublishWarningNoStartPage);
