import { TemplateLines } from './kinds';
import type { BBox, Handle } from "../../redux/modules/children/workspace/flowTypes";

const
    componentsIds = [],
    HalfTemplateWidth = 10;

export default ({ top, bottom }: BBox, templateWidth: number): Array<Handle> => {
    let
        left = -HalfTemplateWidth,
        right = HalfTemplateWidth,
        handleOffsets = [0, templateWidth]; // left, right template lines

    return handleOffsets.map(offset => {
        left += offset;
        right += offset;
        return {
            bBox: {
                top,
                left,
                right,
                bottom
            },
            kind: TemplateLines,
            minY: top,
            minX: left,
            maxX: right,
            maxY: bottom,
            zIndex: 10000,
            componentsIds
        };
    });
};
