import store from 'store';
import { getDAL } from '../../dal/index';

type LocalStorage = {
    get: (key: string, useNative?: boolean) => AnyValue | null;
    getRequired: (key: string) => AnyValue;
    set: (key: string, value: AnyValue, useNative?: boolean) => void;
    remove: (key: string) => void;
    clear: () => void
};

const
    makeKey = (key: string) => `${getDAL().getDomain()}.uiState.${key}`,
    ls: LocalStorage = {
        set: (key, value, useNative) => {
            if (useNative) {
                // If we don't want to use serialization of string by the third-party package "store"
                // (because serialization adds double-quotes as part of value itself)
                try {
                    window.localStorage.setItem(makeKey(key), value);
                } catch (e) {
                    // do nothing
                }
            } else {
                store.set(makeKey(key), value);
            }
        },
        get: (key, useNative) => {
            if (useNative) {
                try {
                    return window.localStorage.getItem(makeKey(key));
                } catch (e) {
                    return undefined;
                }
            } else {
                return store.get(makeKey(key));
            }
        },
        getRequired: key => {
            const value = store.get(makeKey(key));
            if (value === null) throw new Error(`Missing require local storage value by key: '${key}'`);
            return value;
        },
        remove: key => store.remove(makeKey(key)),
        clear: () => store.clearAll()
    };

export {
    ls as default,
    ls,
    makeKey,
};
