import React from 'react';
import factory from '../../presentational/Button/factory';
import styles from './styles.css';

type Props = {
    msg: string;
    style?: Record<string, any>;
    className?: string;
    onClick?: (e: React.MouseEvent<any>) => void;
    onMouseEnter: any
};

export default ({ msg, style, onClick, ...rest }: Props) => {
    const
        buttonClassname = styles.mctaButton,
        MctaButton = factory({
            style,
            containerType: 'div',
            containerExtraPropNames: [],
            additionalClassName: buttonClassname
        });

    return (
        <MctaButton onClick={onClick} {...rest}>
            <span>{msg}</span>
        </MctaButton>
    );
};
