import React from 'react';
import { pure } from 'recompose';
import { MctaVerticalBar } from '../../../componentMainActions/index';
import { MctaSlider } from '../../../componentMainActions/MctaSlider/MctaSlider';
import type { CTAViewParams } from '../../../../view/Workspace/Decorations/ComponentMainActions/flowTypes';
import HorizontalSpacer from '../../../../view/common/HorizontalSpacer';
import { LINKS } from '../propertiesPanel/pagesIds';
import type { SocialComponent, SocialGlobalData } from '../flowTypes';
import {
    SOCIAL_MCTA_SIZE_SLIDER_VALUE_CHANGED,
    SOCIAL_MCTA_HORIZONTAL_SPACING_SLIDER_VALUE_CHANGED,
    SOCIAL_MOBILE_MCTA_SIZE_SLIDER_VALUE_CHANGED,
    SOCIAL_MOBILE_MCTA_HORIZONTAL_ALIGNMENT_CHANGED,
    SOCIAL_CHANGE_GLOBAL_SOCIAL_LINKS_CTA_CLICKED,
    SOCIAL_MCTA_CHANGE_SIZE,
    SOCIAL_MOBILE_MCTA_CHANGE_SIZE,
} from '../actionTypes';
import {
    default as AlignmentControls,
    StatefulAlignmentControls
} from '../../../componentMainActions/MctaAlignmentControls/alignmentControls';
import { SOCIAL_KIND } from '../kind';
import type { MCTA_PROPS } from '../../../MobileViewEditor/flowTypes';
import { MOBILE_MCTA_ALIGN_MOUSE_ENTER, MOBILE_MCTA_ALIGN_MOUSE_LEAVE } from '../../../MobileViewEditor/actionTypes';
import { injectIntl, Intl } from '../../../../view/intl/index';
import styles from './styles.css';
import { COMPONENT_CHANGING } from '../../../Workspace/epics/componentsEval/actionTypes';
import { SOCIAL_MIN_SIZE, SOCIAL_MAX_SIZE, SOCIAL_MIN_SPACING, SOCIAL_MAX_SPACING } from '../../constants';

type PropTypes = {
    dispatch: Dispatch,
    selectedComponent: SocialComponent,
    mobileMCTA: MCTA_PROPS,
    isModernLayoutActivated: boolean,
    isInsideHeaderOrFooter: boolean
};

const
    EditView = pure(({
        selectedComponent: { spacing, size, modernLayoutOptions },
        dispatch,
        children,
        isModernLayoutActivated,
        isInsideHeaderOrFooter
    }) => (
        <div>
            {isModernLayoutActivated && isInsideHeaderOrFooter ?
                <StatefulAlignmentControls
                    label="msg: common.sizeColon {Size:}"
                    actionTypeToDispatch={SOCIAL_MCTA_CHANGE_SIZE}
                    size={modernLayoutOptions && modernLayoutOptions.size}
                    dispatch={dispatch}
                    kind={SOCIAL_KIND}
                />
                : <React.Fragment>
                    <HorizontalSpacer x={8} />
                    <MctaSlider
                        label="msg: component.social.size {Size}"
                        min={SOCIAL_MIN_SIZE}
                        max={SOCIAL_MAX_SIZE}
                        step={2}
                        value={size}
                        valueLabel="px"
                        defaultValue={size}
                        onChange={spacing => dispatch({
                            type: COMPONENT_CHANGING,
                            payload: {
                                type: SOCIAL_MCTA_SIZE_SLIDER_VALUE_CHANGED,
                                payload: spacing,
                            }
                        })}
                    />
                    <HorizontalSpacer x={13} />
                    <MctaVerticalBar />
                    <HorizontalSpacer x={13} />
                    <MctaSlider
                        label="msg: common.spacing {Spacing}"
                        min={SOCIAL_MIN_SPACING}
                        max={SOCIAL_MAX_SPACING}
                        step={1}
                        value={spacing}
                        valueLabel="px"
                        defaultValue={spacing}
                        onChange={spacing => dispatch({
                            type: COMPONENT_CHANGING,
                            payload: {
                                type: SOCIAL_MCTA_HORIZONTAL_SPACING_SLIDER_VALUE_CHANGED,
                                payload: spacing
                            }
                        })}
                    />
                    {children ? <HorizontalSpacer /> : <HorizontalSpacer x={8} />}
                    {children}
                </React.Fragment>}
        </div>
    )),
    mobileViewEditorV = (props: PropTypes) => {
        const {
            dispatch,
            selectedComponent,
            mobileMCTA,
            isModernLayoutActivated,
            isInsideHeaderOrFooter
        } = props;

        const { mctaHorizontalAlignControlExpanded, availableSpace } = mobileMCTA;

        return (
            <div>
                {isModernLayoutActivated && isInsideHeaderOrFooter ?
                    <StatefulAlignmentControls
                        label="msg: common.sizeColon {Size:}"
                        actionTypeToDispatch={SOCIAL_MOBILE_MCTA_CHANGE_SIZE}
                        size={selectedComponent.modernLayoutOptions && selectedComponent.modernLayoutOptions.mobileSize}
                        dispatch={dispatch}
                        kind={SOCIAL_KIND}
                    />
                    : <React.Fragment>
                        <AlignmentControls
                            availableSpace={availableSpace}
                            actionTypeToDispatch={SOCIAL_MOBILE_MCTA_HORIZONTAL_ALIGNMENT_CHANGED}
                            onMouseEnterCollapsedViewActionType={MOBILE_MCTA_ALIGN_MOUSE_ENTER}
                            onMouseLeaveExpandedViewActionType={MOBILE_MCTA_ALIGN_MOUSE_LEAVE}
                            alignment={selectedComponent.mobileHorizontalAlignment}
                            expanded={mctaHorizontalAlignControlExpanded}
                            dispatch={dispatch}
                            kind={SOCIAL_KIND}
                        />
                        <HorizontalSpacer x={5} />
                        <MctaVerticalBar />
                        <HorizontalSpacer x={12} />
                        <MctaSlider
                            label="msg: component.social.size {Size}"
                            min={32}
                            max={64}
                            step={1}
                            value={selectedComponent.mobileSize}
                            valueLabel="px"
                            onChange={spacing => dispatch({
                                type: SOCIAL_MOBILE_MCTA_SIZE_SLIDER_VALUE_CHANGED,
                                payload: spacing,
                                amendToSelf: true
                            })}
                            forMve
                        />
                    </React.Fragment>}
            </div>
        );
    };

export default {
    editView: EditView,
    mobileViewEditorV,
    editButtonText: 'msg: common.editIcons {Edit icons}',
    isCtaVisible: ({ propertiesPanelPageId }: { propertiesPanelPageId: string }) => {
        return propertiesPanelPageId !== LINKS;
    },
    CtaButtonTextView: injectIntl((props: { socialData: SocialGlobalData, intl: Intl }) => {
        const someLinksDoesNotHaveUrl = props.socialData.links.some(link => !link.url);
        return <span className={someLinksDoesNotHaveUrl ? styles.noLinksSet : null}>
            {props.intl.msgJoint(someLinksDoesNotHaveUrl ?
                'msg: component.social.setGlobalSocialLinks {Set links}' :
                'msg: component.social.changeGlobalSocialLinks {Change links}')}
        </span>;
    }),
    ctaOnClick: ({ dispatch }: CTAViewParams<SocialComponent>) => dispatch({ type: SOCIAL_CHANGE_GLOBAL_SOCIAL_LINKS_CTA_CLICKED })
};
