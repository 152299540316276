import * as R from 'ramda';
import kind from './kind';
import { getStylesByType } from '../../../Workspace/epics/stylesheets/selectors';
import type { Stylesheet } from '../../../Workspace/epics/stylesheets/flowTypes';
import type { ComboboxOptions } from '../../../../view/common/Combobox/flowTypes';
import { Intl } from '../../../../view/intl/injectIntl';
import { THEME_LINK_LIST } from "../../../ThemeGlobalData/themeRules";

const linkOptionMapper = (styles: Array<Stylesheet>, intl: Intl): ComboboxOptions =>
    R.pipe(
        getStylesByType(kind),
        R.map(style => ({
            value: style.id,
            label: intl.msgJoint('msg: common.linkStyle {Link style}') + ' ' + style.index,
            name: style.name
        }))
    )(styles);

const createThemeLinkOptions = (intl: Intl): ComboboxOptions => R.map(themeLink => ({
    value: themeLink.value,
    label: intl.msgJoint(themeLink.label),
    name: themeLink.value
}))(THEME_LINK_LIST);

export {
    linkOptionMapper as default,
    linkOptionMapper,
    createThemeLinkOptions
};

