import * as R from 'ramda';
import { getDialogFromRegistry } from "../registry";

import type { DialogConfig, DialogManagerScope, DialogManagerState } from "../flowTypes";
import type { AdjustAfterUpdate } from "../../../epics/flowTypes";
import { getPositionTransformation } from "./shared";
import { mouseDownUIMode, mouseMoveUIMode } from "./interactionModes";
import { objectsAreDiff } from "../../../utils/objectsDiffChecks";

type CalcRenderPropsParams = {
    dialogConfig: DialogConfig,
    dependencies: Object
};

const
    calcRenderProps = ({ dialogConfig, dependencies }: CalcRenderPropsParams): Object | null => {
        const
            { id, state, props } = dialogConfig,
            record = getDialogFromRegistry(id),
            renderProps = record.calcRenderProps ? record.calcRenderProps({
                state,
                props,
                dependencies
            }) : null;
        return renderProps;
    },
    getTopMostModalDialogIndex = (configs: Array<DialogConfig>): number => {
        let index = 0;

        configs.forEach((config: DialogConfig, i: number) => {
            index = config.modal ? i : index;
        });

        return index;
    },
    dialogManagerAfterUpdate: AdjustAfterUpdate<DialogManagerState, DialogManagerScope, void> =
        ({ prevState, nextState, prevScope, nextScope }) => {
            const topMostModalIndex = getTopMostModalDialogIndex(nextScope.openedDialogConfigs),
                stateTransformations: any = [];

            if (nextState.computed.topMostModalIndex === topMostModalIndex) {
                stateTransformations.push(R.assocPath(['computed', 'topMostModalIndex'], topMostModalIndex));
            }

            if (nextScope.userInteraction.mode === mouseDownUIMode ||
                nextScope.userInteraction.mode === mouseMoveUIMode
            ) {
                const
                    { currentPosition, startPosition, currentDialogId } = nextScope.userInteraction.payload,
                    { browserDimensions } = nextScope,
                    isCurrentDialog = ({ id }: DialogConfig) => id === currentDialogId,
                    currentDialogConfig = nextScope.openedDialogConfigs.find(isCurrentDialog) as DialogConfig,
                    currentDialogPosition = R.path(['position'], currentDialogConfig),
                    dimensions = R.path(['dimensions'], currentDialogConfig),
                    currentDialogIndex = nextScope.openedDialogConfigs.indexOf(currentDialogConfig),
                    newPosition = getPositionTransformation(
                        { currentPosition, startPosition, dimensions, browserDimensions }
                    )(currentDialogPosition);

                stateTransformations.push(
                    R.assocPath(['computed', 'renderProps', currentDialogIndex, 'position'], newPosition)
                );
            } else if (
                prevScope.openedDialogConfigs !== nextScope.openedDialogConfigs
                || prevScope.dialogsDependencies !== nextScope.dialogsDependencies
            ) {
                const newRenderProps = nextScope.openedDialogConfigs.map((dialogConfig, index) => {
                    const
                        { id: dialogId } = dialogConfig,
                        getDeps = R.path(['dialogsDependencies', dialogConfig.id]),
                        prevDeps = getDeps(prevScope),
                        nextDeps = getDeps(nextScope);
                    if (
                        prevScope.openedDialogConfigs[index] === dialogConfig
                        && prevDeps === nextDeps
                    ) {
                        return prevState.computed.renderProps[index];
                    } else {
                        const
                            getDeps = R.path(['dialogsDependencies', dialogConfig.id]),
                            prevDeps = getDeps(prevScope),
                            nextDeps = getDeps(nextScope),
                            dialogDeps = nextScope.dialogsDependencies[dialogId],
                            record = getDialogFromRegistry(dialogId),
                            state = record.updateStateDependencies && objectsAreDiff(prevDeps, nextDeps)
                                ? record.updateStateDependencies(dialogConfig.state, nextDeps)
                                : dialogConfig.state,
                            dialogSpecificProps =
                                calcRenderProps({
                                    dialogConfig,
                                    dependencies: dialogDeps
                                })
                                || {
                                    ...dialogConfig.props,
                                    ...dialogDeps
                                },
                            renderProps = {
                                ...dialogSpecificProps,
                                id: dialogConfig.id,
                                position: dialogConfig.position,
                                dimensions: dialogConfig.dimensions,
                                state,
                                dragEnabled: dialogConfig.dragEnabled === undefined,
                                dialogClassName: dialogConfig.dialogClassName,
                                dialogBackgroundClassName: dialogConfig.dialogBackgroundClassName,
                                draggableClassName: dialogConfig.draggableClassName,
                                forceModal: dialogConfig.forceModal,
                                onModalClickAction: dialogConfig.onModalClickAction,
                            };

                        return renderProps;
                    }
                });

                stateTransformations.push(R.assocPath(['computed', 'renderProps'], newRenderProps));
            }

            return {
                state: stateTransformations.length ? R.pipe(...stateTransformations)(nextState) : nextState,
                scope: nextScope
            };
        };

export {
    dialogManagerAfterUpdate
};
