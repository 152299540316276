// @ts-nocheck
/* eslint-disable object-shorthand */

export const calendar = {
    lastWeek: function () {
        return this.format('[el] dddd [pasado a la' + (this.hour() !== 1 ? 's' : '') + '] LT');
    },
    lastDay: function () {
        return this.format('[ayer a la' + (this.hour() !== 1 ? 's' : '') + '] LT');
    },
    sameDay: function () {
        return this.format('[hoy a la' + (this.hour() !== 1 ? 's' : '') + '] LT');
    },
    nextDay: function () {
        return this.format('[mañana a la' + (this.hour() !== 1 ? 's' : '') + '] LT');
    },
    nextWeek: function () {
        return this.format('dddd [a la' + (this.hour() !== 1 ? 's' : '') + '] LT');
    },
    sameElse: function () {
        return this.format('LL [a la' + (this.hour() !== 1 ? 's' : '') + '] LT');
    },
};
