
import * as R from 'ramda';
import makeEpic from '../../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import { MOUSE_DOWN_ON_TEMPLATE_LINE, TEMPLATE_WIDTH_CHANGED, TEMPLATE_WIDTH_CHANGE_FINISHED } from './actionTypes';
import { WINDOW_MOUSE_MOVE } from "../../../../../redux/modules/actionTypes";
import { optional, receiveOnly } from "../../../../../epics/makeCondition";
import { ReceiveOnlyTemplateWidthActionType } from '../template/selectorActionTypes';
import workspaceScrollValueActionType from '../../../../Workspace/epics/scroll/valueActionType';
import { MaxTemplateWidth, MinTemplateWidth } from '../template/constants';
import { WINDOW_MOUSE_UP } from "../../../../App/actionTypes";
import { TopBarHeight } from "../../../../TopBar/constants";
import { TEMPLATE_LEFT_LINE } from './kinds';
import * as updateReasons from './updateReasons';
import { propertiesPanelValueActionType } from "../../../../PropertiesPanel/epic/valueActionType";
import ComponentTypes from "../../../../../view/oneweb/registry/ComponentTypes";
import { headerFooterSectionsPositionVAT } from "../../../Section/epics/headerFooterSectionsPositionEpic";
import { Lit } from '../../../../../lit';
import { isModernLayoutActivatedVAT } from "../../../../Workspace/epics/isModernLayoutActivatedEpic/valueActionType";

const
    labelHeight = 26,
    defaultState = {
        state: {
            isActive: false,
            distanceBetweenLines: 0,
            labelTop: 0
        },
        scope: {
            startMouseLeft: 0,
            templateWidth: 0,
            templateKind: null
        }
    },
    setIsActive = R.assocPath(['state', 'isActive']),
    setStartMouseLeft = R.assocPath(['scope', 'startMouseLeft']),
    setTemplateWidth = R.assocPath(['scope', 'templateWidth']),
    setTemplateKind = R.assocPath(['scope', 'templateKind']),
    setDistanceBetweenLines = R.assocPath(['state', 'distanceBetweenLines']),
    setLabelTop = R.assocPath(['state', 'labelTop']),
    setTop = R.assocPath(['state', 'top']),
    setHeight = R.assocPath(['state', 'height']),
    getLabelTop = posY => Math.max(posY - (labelHeight / 2) - TopBarHeight, 0),
    setDottedLineTop = R.assocPath(['state', 'dottedLineTop']),
    getDottedLineTop = labelTop => (labelTop + (labelHeight / 2)),
    getTemplateWidth = (templateWidth, kind, widthChange): number =>
        (templateWidth + ((kind === TEMPLATE_LEFT_LINE ? -1 : 1) * widthChange * 2));

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [propertiesPanelValueActionType],
            reducer: ({ values: [{ state: propPanelState }], state: epicState }) => {
                let { show: isActive, selectedComponentWithId } = propPanelState;
                if (isActive && selectedComponentWithId) {
                    const { component: { kind } } = selectedComponentWithId;
                    isActive = kind === ComponentTypes.TEMPLATE;
                }
                return { state: setIsActive(isActive, epicState) };
            }
        },
        {
            conditions: [
                ReceiveOnlyTemplateWidthActionType,
                MOUSE_DOWN_ON_TEMPLATE_LINE,
                receiveOnly(workspaceScrollValueActionType)
            ],
            reducer: ({ values: [templateWidth, mouseDown, scroll], state: epicState }) => {
                const
                    { x, y, kind } = mouseDown,
                    labelTop = getLabelTop(y) + scroll.y,
                    newState = R.pipe(
                        setStartMouseLeft(x),
                        setTemplateKind(kind),
                        setDistanceBetweenLines(templateWidth),
                        setTemplateWidth(templateWidth),
                        setLabelTop(labelTop),
                        setDottedLineTop(getDottedLineTop(labelTop))
                    )(epicState);
                return { state: newState, updateReason: updateReasons.MOUSE_DOWN_ON_TEMPLATE_LINE };
            }
        },
        {
            conditions: [WINDOW_MOUSE_MOVE, receiveOnly(workspaceScrollValueActionType)],
            reducer: ({ values: [mousePosition, scroll], state: epicState }) => {
                const { scope } = epicState;

                if (!scope.templateKind) {
                    return { state: epicState };
                }

                const
                    { startMouseLeft, templateWidth, templateKind } = scope,
                    newWidthChange = mousePosition.x - startMouseLeft,
                    distanceBetweenLines = getTemplateWidth(templateWidth, templateKind, newWidthChange),
                    labelTop = getLabelTop(mousePosition.y) + scroll.y,
                    dottedLineTop = getDottedLineTop(labelTop);

                let newTemplateWidth = distanceBetweenLines;
                if (distanceBetweenLines < MinTemplateWidth) {
                    newTemplateWidth = MinTemplateWidth;
                } else if (distanceBetweenLines > MaxTemplateWidth) {
                    newTemplateWidth = MaxTemplateWidth;
                }

                return {
                    state: R.pipe(
                        setDistanceBetweenLines(newTemplateWidth),
                        setLabelTop(labelTop),
                        setDottedLineTop(dottedLineTop),
                    )(epicState),
                    // $FlowFixMe
                    actionToDispatch: {
                        type: TEMPLATE_WIDTH_CHANGED,
                        payload: newTemplateWidth
                    },
                    updateReason: updateReasons.MOVING_TEMPLATE_LINES
                };
            }
        },
        {
            conditions: [WINDOW_MOUSE_UP],
            reducer: ({ state: epicState }) => {
                const { scope: { templateKind } } = epicState;
                if (templateKind) {
                    return {
                        state: R.pipe(
                            setTemplateKind(null),
                            setDistanceBetweenLines(0),
                        )(epicState),
                        actionToDispatch: { type: TEMPLATE_WIDTH_CHANGE_FINISHED },
                        updateReason: updateReasons.MOUSE_UP_AFTER_MOVING_TEMPLATE_LINES
                    };
                }

                return { state: epicState };
            }
        },
        {
            conditions: [
                optional(headerFooterSectionsPositionVAT),
                optional(isModernLayoutActivatedVAT)
            ],
            reducer: ({ values: [positions, isModernLayoutActivated], state: epicState }) => {
                if (!isModernLayoutActivated || !positions) {
                    return { state: R.pipe(
                        setTop(null),
                        setHeight(null),
                    )(epicState),
                    updateReason: updateReasons.MOUSE_DOWN_ON_TEMPLATE_LINE };
                }
                const
                    header = positions[Lit.headerSection],
                    footer = positions[Lit.footerSection],
                    top = header.height,
                    height = footer.top - header.height;
                return { state: R.pipe(
                    setTop(top),
                    setHeight(height),
                )(epicState),
                updateReason: updateReasons.MOUSE_DOWN_ON_TEMPLATE_LINE };
            }
        }
    ]
});
