import * as actionMapper from '../Common/action';
import defaultImageData from '../../../../src/components/SortImagesDialog/defaultImageData';
import type { FacebookFeedGalleryIn } from './flowTypes';
import type { FacebookFeedGalleryComponent } from "../../../../src/components/oneweb/FacebookFeedGallery/flowTypes";

export const
    to = ({ images }: FacebookFeedGalleryIn) => ({

        images: images.map(image => ({
            ...defaultImageData,
            ...image,
            title: "",

            action: image.action && actionMapper.to(image.action)
        }))
    }),
    back = ({ images }: FacebookFeedGalleryComponent) => ({
        images: images.map(image => ({
            ...image,
            action: image.action && actionMapper.back(image.action)
        }))
    });
