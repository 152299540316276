import React from 'react';
import { Flex } from "../../../view/reflexbox/index";
import { Dialog, DialogBody, StripTypes } from "../../../view/common/dialogs/baseDialog/index";
import Msg from "../../../view/intl/Msg";
import getCenteredDialogConfig from "../../DialogManager/getCenteredDialogConfig";
import { LoadingIndicator } from "../../../view/common/LoadingIndicator/index";
import styles from "./FilesUploadingDialog.css";

const FilesUploadingLoader = () => (
    <Dialog stripType={StripTypes.NONE} showCloseBtn={false}>
        <DialogBody className={styles.dialogBody}>
            <Flex className={styles.title} align="center" justify="center">
                <Msg k="workspace.uploadingFiles">Uploading files to workspace</Msg>
            </Flex>
            <Flex className={styles.message} align="center" justify="center">
                <Msg k="common.pleaseWait">Please wait.</Msg>
            </Flex>
            <LoadingIndicator className={styles.loader} />
        </DialogBody>
    </Dialog>
);

export default getCenteredDialogConfig({
    width: 371,
    height: 173,
    component: FilesUploadingLoader,
    dialogClassName: styles.dialogContainer,
    forceModal: true,
    dragEnabled: false
});
