import React from 'react';
import styles from '../LinkChooserDialog.css';
import FileChooser from '../../../../FileChooser/index';
import { ViewModes } from "../../../../FileChooser/constants";
import getStyleIntValue from "../../../../../../utils/getStyleIntValue";
import type { FileTabPropTypes } from "../../flowTypes";

const
    FILE_TAB_WIDTH = getStyleIntValue(styles, 'fileTabWidth'),
    FC_LIST_TABLE_HEIGHT = getStyleIntValue(styles, 'fcListTableHeight');

export default ({ title, selection, onSave }: FileTabPropTypes) => {
    return (
        <FileChooser
            listTableHeight={FC_LIST_TABLE_HEIGHT}
            title={title}
            viewMode={ViewModes.LIST}
            showPreview
            width={FILE_TAB_WIDTH}
            preSelection={(selection && [selection]) || []}
            onResourceDoubleClick={onSave}
            theme={{
                emptyFolderArrow: styles.emptyFolderArrow,
                uploadComboboxClassName: styles.uploadMenu,
            }}
        />
    );
};
