import Image from '../oneweb/Image/kind';
import { TextComponentKind as TextKind } from '../oneweb/Text/kind';
import {
    cmpTypes, GroupTypes, ImageSpaceLimit, ImageTextSpaceLimit,
    TextAndTextSpaceLimit,
    isSmallImage,
    LargeTextMinHeightLimit,
    LargeTextMinWidthLimit
} from "./constants";
import type { Sequence } from "./flowTypes";
import type { ComponentsMap } from "../../redux/modules/children/workspace/flowTypes";
import { makeUuid } from "../../../utils/makeUuid";
import { isAnyParentWrapped } from "../Preview/View/isAnyParentWrapped";
import getPossibleGroups from "./getPossibleGroups";
import { isSmallText, getGroupTypeByData, getCmpVerticalCenter, canBeBigText } from "./util";
import { getWrappedComponentIds } from "../../utils/htmlWriter/html/render/wrapper/wrapperNodeUtils";

// Image grouping rules
// Less than 75px wide
// Less than 75px height
// Horizontal spacing cannot be more than 60px width.
const
    areTogether = (prevCmp, cmp, spaceLimit, childParentMap) => (
        ((Math.round(cmp.left) - Math.round(prevCmp.left + prevCmp.width)) <= spaceLimit) &&
        prevCmp.inTemplate === cmp.inTemplate &&
        (!!prevCmp.mobileDown) === (!!cmp.mobileDown) &&
        childParentMap[prevCmp.id] === childParentMap[cmp.id]
    ),
    imagesGroup = ({ componentGroup, cmp, childParentMap }) => {
        const prevCmp = componentGroup[componentGroup.length - 1];
        return (
            componentGroup.every(c => c.kind === Image) &&
            cmp.kind === Image &&
            isSmallImage(cmp) &&
            (!prevCmp || (
                (prevCmp.top < getCmpVerticalCenter(cmp) && getCmpVerticalCenter(prevCmp) > cmp.top) &&
                areTogether(prevCmp, cmp, ImageSpaceLimit, childParentMap))
            )
        );
    },

    isBigText = (cmp) => (
        canBeBigText(cmp) &&
        cmp.height >= LargeTextMinHeightLimit &&
        cmp.width >= LargeTextMinWidthLimit
    ),
    getCmpBottom = (cmp) => cmp.top + cmp.height,
    isCenterInside = (cmp1, cmp2) => {
        const cmp1Center = getCmpVerticalCenter(cmp1),
            cmp2Center = getCmpVerticalCenter(cmp2);
        return (cmp1Center > cmp2.top && cmp1Center < getCmpBottom(cmp2)) ||
            (cmp2Center > cmp1.top && cmp2Center < getCmpBottom(cmp1));
    },
    imageAndTextGroup = ({ componentGroup, cmp, childParentMap }) => {
        if (
            componentGroup.length > 1 ||
            (cmp.kind !== TextKind && cmp.kind !== Image) ||

            (cmp.kind === TextKind && getWrappedComponentIds(cmp.content).length > 0)
        ) {
            return false;
        }
        const prevCmp = componentGroup[componentGroup.length - 1];
        if (!prevCmp) {
            return (cmp.kind === Image) ? isSmallImage(cmp) : canBeBigText(cmp);
        } else if (
            !isCenterInside(prevCmp, cmp) ||
            ((prevCmp.kind === Image && (cmp.kind !== TextKind || !canBeBigText(cmp))) ||
            (prevCmp.kind === TextKind && (cmp.kind !== Image || !isSmallImage(cmp))))
        ) {
            return false;
        }
        return areTogether(prevCmp, cmp, ImageTextSpaceLimit, childParentMap);
    },
    isNeighboursNotText = (group, index) => (index === 0 || group[index - 1].kind !== TextKind) &&
        (index + 2 === group.length || group[index + 2].kind !== TextKind),
    textAndTextGroup = ({ componentGroup, cmp, childParentMap, possibleGroup, cmpIndex }) => {
        if (componentGroup.length > 1 || cmp.kind !== TextKind || getWrappedComponentIds(cmp.content).length > 0) {
            return false;
        }
        const prevCmp = componentGroup[componentGroup.length - 1];
        if (!prevCmp) {
            return isSmallText(cmp);
        } else if (!(
            isSmallText(prevCmp) &&
            isBigText(cmp) &&
            isCenterInside(prevCmp, cmp) &&
            (possibleGroup.length === 2 || isNeighboursNotText(possibleGroup, cmpIndex - 1))
        )) {
            return false;
        }
        return areTogether(prevCmp, cmp, TextAndTextSpaceLimit, childParentMap);
    },
    canAddToGroupFns = {
        [GroupTypes.images]: imagesGroup,
        [GroupTypes.imageAndText]: imageAndTextGroup,
        [GroupTypes.textAndText]: textAndTextGroup
    },
    canCmpBeAddedToGroup = (cmpAndGroupData) =>
        Object.keys(canAddToGroupFns).some(type => canAddToGroupFns[type](cmpAndGroupData)),
    getSeqChildParentMap = (seq, root) => {
        let childParentMap = {};
        const parseSeq = (parentIds) => {
            if (parentIds.length) {
                let nextSetOfChildIds: any[] = [];
                parentIds.forEach(parentId => {
                    if (seq[parentId]) {
                        seq[parentId].forEach(childId => {
                            childParentMap[childId] = parentId;
                            nextSetOfChildIds.push(childId);
                        });
                    }
                });
                parseSeq(nextSetOfChildIds);
            }
        };
        parseSeq([root]);
        return childParentMap;
    };

// This function gives new data with groups of components which can be grouped
// and shown side by side in mobile view and mobile view editor - WBTGEN-8370
// Generic rules
// 1. All components should belong to same parent
// 2. All components should come one after the other in mobile data
// 3. All components should be in proximity to each other and have approximately same top(+/- 5 tolerance)
// Each component group can have different rules

// As this fn works on default sequence(basically already sequenced cmps),
// so, it does not need to check same parent case, mobile hide(is taken care by css)
// and mobile down case

export default (data: Sequence, componentsMap: ComponentsMap, structure: any, root: string) => {
    let componentGroups: any[] = [],
        componentGroup: any[] = [],
        newData = data,
        validGroups = {},
        possibleGroups = getPossibleGroups(componentsMap),
        childParentMap = getSeqChildParentMap(data, root);
    const addCmpToGroup = (cmp, group?, cmpIndex?) => {
        if (
            cmp &&
            !data[cmp.id] &&
            !cmp.wrap &&
            !isAnyParentWrapped(cmp, structure) &&
            childParentMap[cmp.id] &&
            canCmpBeAddedToGroup({
                componentGroup,
                cmp,
                childParentMap,
                possibleGroup: (group || []),
                cmpIndex: (cmpIndex || -1)
            })
        ) {
            componentGroup.push(cmp);
            return true;
        }
        return false;
    };
    const addCmpGroup = (newCmp?) => {
        if (componentGroup.length > 1) {
            componentGroups.push(componentGroup);
        }
        componentGroup = [];
        addCmpToGroup(newCmp);
    };
    possibleGroups.forEach(group => {
        group.forEach((cmp, i) => {
            const isCmpAdded = addCmpToGroup(cmp, group, i);
            if (!isCmpAdded) {
                addCmpGroup(cmp);
            }
        });
        addCmpGroup();
        componentGroups.forEach(componentGroup => {
            let newCmpGroup: any[] = [], parentId = '', index = -1, lowestIndex = 10000;
            componentGroup.forEach((cmp) => {
                if (data === newData) {
                    newData = { ...data };
                }
                parentId = childParentMap[cmp.id];
                newCmpGroup.push(cmp.id);
            });
            if (parentId && componentGroup.length) {
                const newGroupId = cmpTypes.group + '-' + getGroupTypeByData(componentGroup) + '-' + makeUuid();
                if (newData[parentId] === data[parentId]) {
                    newData[parentId] = data[parentId].slice();
                }
                lowestIndex = 10000;
                componentGroup.forEach(cmp => {
                    index = newData[parentId].indexOf(cmp.id);
                    lowestIndex = Math.min(index, lowestIndex);
                    newData[parentId].splice(index, 1);
                });
                newData[parentId].splice(lowestIndex, 0, newGroupId);
                validGroups[newGroupId] = newCmpGroup;
            }
        });
        componentGroups = [];
    });
    return {
        data: newData,
        groups: validGroups
    };
};
