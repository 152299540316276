import React, { useState, useEffect, createRef } from 'react';
import cx from 'classnames';
import type { SliderProps } from "./flowTypes";
import styles from './style.css';

const convertTo360Degree = (degree) => {
    if (degree < 0) return 360 - Math.abs(degree);
    return degree;
};

export default (props: SliderProps) => {
    const [isMouseMoving, setMouseMove] = useState(false);
    const ref = createRef<HTMLDivElement>();

    useEffect(() => {
        const handlMouseUp = () => {
            setMouseMove(false);
        };

        const handleMouseMove = event => {
            window.requestAnimationFrame(() => {
                if (ref.current && isMouseMoving) {
                    const { height, width, left, top } = ref.current.getBoundingClientRect();
                    const leftX = event.clientX - (left + (width / 2));
                    const rightY = -1 * (event.clientY - (top + (height / 2)));
                    const degree = convertTo360Degree(Math.floor(Math.atan2(leftX, rightY) * (180 / Math.PI)));

                    props.onChange(degree);
                }
            });
        };

        window.addEventListener("mouseup", handlMouseUp);
        window.addEventListener("mousemove", handleMouseMove);

        return () => {
            window.removeEventListener('mouseup', handlMouseUp);
            window.removeEventListener('mousemove', handleMouseMove);
        };
    });

    return <div
        ref={ref}
        style={{
            transform: `rotate(${props.value}deg)`
        }}
        className={cx({
            [styles.circle]: true,
            [styles.active]: isMouseMoving
        })}
    >
        <div className={styles.indicator} />
        <div
            className={styles.drggable}
            onMouseDown={() => setMouseMove(true)}
        >
            <div className={styles.blueCircle} />
        </div>
    </div>;
};
