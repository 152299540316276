/* eslint-disable max-len */

import React from 'react';
import { $Diff } from "utility-types";
import BorderSettings from "../../../../presentational/BorderSettings/index";
import type {
    DefaultPropTypesWithCorners, DefaultPropTypesWithoutCorners, ThemePropTypesWithCorners, ThemePropTypesWithoutCorners
} from "../../../../presentational/BorderSettings/flowtypes";
import Page from '../../PropertiesPage';
import {
    getCorner,
    getBorderWidth,
    getBorderOpacity,
    getBorderStyle,
    getBorderColor
} from '../../../../../view/oneweb/commonComponentSelectors';
import type { Color } from '../../../../../mappers/flowTypes';
import type { FourFieldState } from '../../../../presentational/FourFields/flowTypes';

type Props
    = $Diff<DefaultPropTypesWithCorners, { style: string, color: Color, opacity: null | undefined | number, widths: FourFieldState, corners: null | undefined | Record<string, any> }>
    // @ts-ignore
    | $Diff<DefaultPropTypesWithoutCorners, { style: string, color: Color, opacity: null | undefined | number, widths: FourFieldState, corners: null | undefined | Record<string, any> }>
    | $Diff<ThemePropTypesWithCorners, { style: string, opacity: null | undefined | number, widths: FourFieldState, corners: null | undefined | Record<string, any> }>
    // @ts-ignore
    | $Diff<ThemePropTypesWithoutCorners, { style: string, opacity: null | undefined | number, widths: FourFieldState, corners: null | undefined | Record<string, any> }>;

const ComponentBorderSettings = (props: Props) => {
    const
        { selectedComponent } = props,
        values = {
            style: getBorderStyle(selectedComponent),
            opacity: getBorderOpacity(selectedComponent),
            widths: getBorderWidth(selectedComponent),
        };

    const makeView = (props) => {
        const commonProps = {
            dispatch: props.dispatch,
            styleChangeAction: props.styleChangeAction,
            opacityChangeAction: props.opacityChangeAction,
            widthChangeAction: props.widthChangeAction,
            colorChangeAction: props.colorChangeAction,
            colorRemoveAction: props.colorRemoveAction,
            colorOnTheFlyChange: props.colorOnTheFlyChange,
            selectedComponent: props.selectedComponent,
            showOpacity: props.showOpacity,
            showTopAndBottomElements: props.showTopAndBottomElements,
            showLeftAndRightElements: props.showLeftAndRightElements,
        };
        if (props.showCorners !== false) {
            if (props.autoColorMode) {
                return <BorderSettings.view
                    {...commonProps}
                    {...values}

                    autoColorMode={props.autoColorMode}
                    themeColor={props.themeColor}
                    themeColorsData={props.themeColorsData}

                    showCorners
                    corners={getCorner(selectedComponent)}
                    cornerChangeAction={props.cornerChangeAction}
                />;
            } else {
                return <BorderSettings.view
                    {...props}
                    {...values}

                    autoColorMode={false}
                    color={getBorderColor(selectedComponent)}

                    showCorners
                    corners={getCorner(selectedComponent)}
                    cornerChangeAction={props.cornerChangeAction}
                />;
            }
        } else if (props.autoColorMode) {
            return <BorderSettings.view
                {...commonProps}
                {...values}

                autoColorMode={props.autoColorMode}
                themeColor={props.themeColor}
                themeColorsData={props.themeColorsData}

                showCorners={false}
            />;
        } else {
            return <BorderSettings.view
                {...commonProps}
                {...values}

                autoColorMode={false}
                color={getBorderColor(selectedComponent)}

                showCorners={false}
            />;
        }
    };

    return <Page>{makeView(props)}</Page>;
};

export default {
    id: BorderSettings.id,
    title: BorderSettings.title,
    view: ComponentBorderSettings
};
