import * as R from "ramda";
import { applyMappers, makePlainMappers } from "../../utils";
import * as layoutTypeMapper from "./layoutType";
import globalStyleKind from "../../../../src/components/oneweb/Menu/globalStyle/kind";
import type { MenuComponent } from "../../../../src/components/oneweb/Menu/flowTypes";
import type { MenuIn } from './flowTypes';
import { VERTICAL_TREE } from "../../../../src/components/oneweb/Menu/constants/layoutType";

const
    plainPropsMapper = makePlainMappers({
        horizontalAlign: "horizontalAlign",
        verticalAlign: "verticalAlign",
        level: "startLevel",
        menuStyle: "style",
        moreButtonEnabled: "moreButtonEnabled",
        moreText: "moreText",
        themeStyles: 'themeStyles',
        isStickyToHeader: "isStickyToHeader"
    }),
    addStyleType = R.assocPath(['style', 'type'], globalStyleKind);

function setDefaultMoreTextIfEmpty(componentData: MenuIn) {
    if (componentData.moreText) {
        return componentData;
    }

    return R.assoc('moreText', 'More', componentData);
}

const
    makeSubmenuStyleMapperTo = layoutType => (componentData: MenuIn) => {
        if (componentData.levelStyles) {
            // note from old wsb: Level styles is deprecated and replaced iwth: menuStyle, accordionStyle and cascadeStyle
            return {
                style: componentData.levelStyles[0]
            };
        }

        if (layoutType === VERTICAL_TREE) {
            return {
                subStyle: componentData.accordionStyles[0] || componentData.menuStyle
            };
        }

        return {
            subStyle: componentData.cascadeStyles[0] || componentData.menuStyle
        };
    },
    assocStyleType = R.assoc('type', globalStyleKind),
    submenuStyleMapperBack = (component: MenuComponent) => {
        return {
            accordionStyles: [assocStyleType(component.subStyle)],
            cascadeStyles: [assocStyleType(component.subStyle)]
        };
    };

export function to(componentData: MenuIn): MenuComponent {
    const { layoutType } = layoutTypeMapper.to(componentData);

    return applyMappers(
        setDefaultMoreTextIfEmpty(componentData),
        () => ({ layoutType }),
        plainPropsMapper.to,
        makeSubmenuStyleMapperTo(layoutType),
    );
}

export function back(component: MenuComponent): MenuIn {
    return applyMappers(
        addStyleType(component),
        layoutTypeMapper.back,
        plainPropsMapper.back,
        component => ({ accordionStyles: component.cascadeStyles }),
        submenuStyleMapperBack,
    );
}
