import * as R from 'ramda';
import p from "../utils/pipePath";
import * as mp from "../mappers/path";
import { setToPath } from "../utils/ramdaEx";
import type { Path } from "../mappers/path"; // eslint-disable-line

export const
    unsetGradientColor = (gradientPath: Path, data: any) =>
        setToPath(p(gradientPath), null, data),
    setGradientDirectionCorrect = R.curry((gradientPath: Path, degree: number, data: any) =>
        setToPath(p(gradientPath, mp.colorData, mp.gradient, mp.degree), degree, data)),
    setGradientDirection = R.curry((gradientPath: Path, degree: number, data: any) =>
        setToPath(p(gradientPath, mp.degree), degree, data)),
    setGradientFadePointCorrect = R.curry((gradientPath: Path, direction: string, data: any) =>
        setToPath(p(gradientPath, mp.colorData, mp.gradient, mp.fadePoint), direction, data)),
    setGradientFadePoint = R.curry((gradientPath: Path, fadePoint: number, data: any) =>
        setToPath(p(gradientPath, mp.fadePoint), fadePoint, data));

