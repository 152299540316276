import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
    (theme) => ({
        rootContainer: {
            height: "100%",
            border: `1px solid ${theme.palette.custom.colorGray_ce}`,
            padding: "30px",
            minWidth: "196px",
            background: "white",
            "& > *:not(:first-child)": {
                marginTop: "14px",
            },
        },
    }),
    { name: "VisitStatsLoader" },
);

const StatsItemSkeleton = () => {
    return (
        <Grid item xs={6}>
            <Skeleton
                variant="text"
                height={20}
                width="80%"
                style={{
                    borderRadius: "4px"
                }}
            />

            <Skeleton
                variant="text"
                height={30}
                width="50%"
                style={{
                    marginTop: "4px",
                    borderRadius: "4px"
                }}
            />
        </Grid>
    );
};

export const VisitStatsLoader = () => {
    const classes = useStyles();

    return (
        <Box className={classes.rootContainer}>
            <Skeleton variant="rectangular" height="68px" width="80px" />
            <Skeleton variant="text" height={26} width="80px" />

            <Grid container spacing={3}>
                <StatsItemSkeleton />
                <StatsItemSkeleton />
                <StatsItemSkeleton />
                <StatsItemSkeleton />
            </Grid>

            <Skeleton variant="text" height={20} width="65px" />
        </Box>
    );
};
