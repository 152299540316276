export const

    FETCH_USER_DATA_MIGRATION_INFO_REQUEST = 'FETCH_USER_DATA_MIGRATION_INFO_REQUEST',
    FETCH_USER_DATA_MIGRATION_INFO_SUCCESS = 'FETCH_USER_DATA_MIGRATION_INFO_SUCCESS',
    FETCH_USER_DATA_MIGRATION_INFO_FAILURE = 'FETCH_USER_DATA_MIGRATION_INFO_FAILURE',

    FetchUserDataMigrationActionTypes = [
        FETCH_USER_DATA_MIGRATION_INFO_REQUEST,
        FETCH_USER_DATA_MIGRATION_INFO_SUCCESS,
        FETCH_USER_DATA_MIGRATION_INFO_FAILURE
    ],

    // because/todo/data-migration/ensure-that-failed-requests-send-error-like-http-status-500.md
    // TODO: Make these variable names consistent
    BEGIN_USER_DATA_MIGRATION_REQUEST = 'BEGIN_USER_DATA_MIGRATION_REQUEST',
    USER_DATA_MIGRATION_REQUEST_SUCCESS = 'USER_DATA_MIGRATION_REQUEST_SUCCESS',
    USER_DATA_MIGRATION_REQUEST_FAILURE = 'USER_DATA_MIGRATION_REQUEST_FAILURE',

    RequestUserDataMigrationActionTypes = [
        BEGIN_USER_DATA_MIGRATION_REQUEST,
        USER_DATA_MIGRATION_REQUEST_SUCCESS,
        USER_DATA_MIGRATION_REQUEST_FAILURE
    ];
