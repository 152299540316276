import type { ModernSectionComponent } from '../oneweb/Section/flowTypes';
import type { ComponentsMap, ComponentChange } from "../../redux/modules/children/workspace/flowTypes";
import { updateModernSectionHeightAndCenterChildComponents } from './utils';

const getChangesForCenteringCmpsOnSectionHeightChange = (
    oldChanges: ComponentChange[],
    section: ModernSectionComponent,
    componentsMap: ComponentsMap,
    newHeight: number
): ComponentChange[] => {
    let changedValues: MapT<ComponentChange> = oldChanges.reduce((acc, val) => ({ ...acc, [val.id]: val }), {});

    const cmpChanges = updateModernSectionHeightAndCenterChildComponents({
        oldHeight: section.height,
        newHeight,
        sectionId: section.id,
    }, componentsMap);

    Object.keys(cmpChanges).forEach((id) => {
        const value = cmpChanges[id],
            oldCmpChanges = changedValues[id] || { value: {}, id };
        changedValues[id] = { ...oldCmpChanges, value: { ...oldCmpChanges.value, ...value, } };
    });

    return ((Object.values(changedValues) as any) as ComponentChange[]);
};
export default getChangesForCenteringCmpsOnSectionHeightChange;
