import type {
    ConnectYourListing,
    CreateYourListing,
} from '../flowTypes';

export const
    CONNECT_YOUR_LISTING: ConnectYourListing = 'CONNECT_YOUR_LISTING',
    CREATE_YOUR_LISTING: CreateYourListing = 'CREATE_YOUR_LISTING',
    IMPORT_YOUR_LISTING = 'IMPORT_YOUR_LISTING',
    CREATE_YOUR_LISTING_VALIDATE_ACTION = 'CREATE_YOUR_LISTING_VALIDATE_ACTION',
    CREATE_YOUR_LISTING_BTN_CLICKED_ACTION = 'CREATE_YOUR_LISTING_BTN_CLICKED_ACTION',
    CREATE_YOUR_LISTING_BACK_ACTION = 'CREATE_YOUR_LISTING_BACK_ACTION',
    GOOGLE_AUTH_SUCCESS = 'GOOGLE_AUTH_SUCCESS',
    GOOGLE_AUTH_FAILURE = 'GOOGLE_AUTH_FAILURE',
    CONNECT_SUCCESS = 'CONNECT_SUCCESS',
    CONNECT_FAILURE = 'CONNECT_FAILURE',
    CONNECT_LISTINGS = 'CONNECT_LISTINGS',
    CONNECT_LISTINGS_ON_CREATE = 'CONNECT_LISTINGS_ON_CREATE',
    NO_GMB_FAILURE = 'NO_GMB_FAILURE',
    IMPORT_SUCCESS = 'IMPORT_SUCCESS',
    IMPORT_FAILURE = 'IMPORT_FAILURE',
    CREATE_SUCCESS = 'CREATE_SUCCESS',
    CREATE_FAILURE = 'CREATE_FAILURE',
    CONNECT_TO_THIS_LISTING = 'CONNECT_TO_THIS_LISTING',
    CONNECT_TO_THIS_LISTING_REQUEST = 'CONNECT_TO_THIS_LISTING_REQUEST',
    CONNECT_TO_THIS_LISTING_SUCCESS = 'CONNECT_TO_THIS_LISTING_SUCCESS',
    CONNECT_TO_THIS_LISTING_FAILURE = 'CONNECT_TO_THIS_LISTING_FAILURE',
    GMB_GET_LOCATIONS_REQUEST = 'GMB_GET_LOCATIONS_REQUEST',
    GMB_GET_LOCATIONS_SUCCESS = 'GMB_GET_LOCATIONS_SUCCESS',
    GMB_GET_LOCATIONS_FAILURE = 'GMB_GET_LOCATIONS_FAILURE',
    GOOGLE_MY_BUSINESS_FETCH_REQUEST = 'GOOGLE_MY_BUSINESS_FETCH_REQUEST',
    GOOGLE_MY_BUSINESS_FETCH_SUCCESS = 'GOOGLE_MY_BUSINESS_FETCH_SUCCESS',
    GOOGLE_MY_BUSINESS_FETCH_FAILURE = 'GOOGLE_MY_BUSINESS_FETCH_FAILURE',
    GOOGLE_MY_BUSINESS_CONNECTION_STATUS_REQUEST = 'GOOGLE_MY_BUSINESS_CONNECTION_STATUS_REQUEST',
    GOOGLE_MY_BUSINESS_CONNECTION_STATUS_SUCCESS = 'GOOGLE_MY_BUSINESS_CONNECTION_STATUS_SUCCESS',
    GOOGLE_MY_BUSINESS_CONNECTION_STATUS_FAILURE = 'GOOGLE_MY_BUSINESS_CONNECTION_STATUS_FAILURE',
    COPY_GMB_DATA_TO_GENERAL_DATA = 'COPY_GMB_DATA_TO_GENERAL_DATA',
    GOOGLE_MY_BUSINESS_DISCONNECT_LISTING_REQUEST = 'GOOGLE_MY_BUSINESS_DISCONNECT_LISTING_REQUEST',
    GOOGLE_MY_BUSINESS_DISCONNECT_LISTING_SUCCESS = 'GOOGLE_MY_BUSINESS_DISCONNECT_LISTING_SUCCESS',
    GOOGLE_MY_BUSINESS_DISCONNECT_LISTING_FAILURE = 'GOOGLE_MY_BUSINESS_DISCONNECT_LISTING_FAILURE',
    GOOGLE_MY_BUSINESS_SAVE_TO_GMB_REQUEST = 'GOOGLE_MY_BUSINESS_SAVE_TO_GMB_REQUEST',
    GOOGLE_MY_BUSINESS_SAVE_TO_GMB_SUCCESS = 'GOOGLE_MY_BUSINESS_SAVE_TO_GMB_SUCCESS',
    GOOGLE_MY_BUSINESS_SAVE_TO_GMB_FAILURE = 'GOOGLE_MY_BUSINESS_SAVE_TO_GMB_FAILURE',
    GOOGLE_MY_BUSINESS_VALIDATE_TO_GMB_REQUEST = 'GOOGLE_MY_BUSINESS_VALIDATE_TO_GMB_REQUEST',
    GOOGLE_MY_BUSINESS_VALIDATE_TO_GMB_SUCCESS = 'GOOGLE_MY_BUSINESS_VALIDATE_TO_GMB_SUCCESS',
    GOOGLE_MY_BUSINESS_VALIDATE_TO_GMB_FAILURE = 'GOOGLE_MY_BUSINESS_VALIDATE_TO_GMB_FAILURE',
    TOGGLE_SHOWING_GMB_PUBLISH_DIALOG_ACTION = 'TOGGLE_SHOWING_GMB_PUBLISH_DIALOG_ACTION',
    GMB_PUBLISH_UPDATE_USER_CONSENT_ACTION = 'GMB_PUBLISH_UPDATE_USER_CONSENT_ACTION';
