// @ts-nocheck
import { always, assocPath, evolve, path } from 'ramda';
import u from 'updeep';
import defaultState from './defaultState';
import inputToState from "../utils/inputToState";
import type { LinkChooserDialogProps } from "../flowTypes";
import { LcTabName } from "../constants";
import { GlobalVariableAddressUrlKey, GlobalVariableContactEmailKey, GlobalVariablePhoneNumberKey } from '../../../../../constants';

const { renderAddressToStringSingleline } = require('../../../../../../../server/shared/address/utils.js');

export default (props: LinkChooserDialogProps, { globalVariables }: { globalVariables: Record<string, any> }) => {
    let state = { ...defaultState };

    if (props.input) {
        state = u(inputToState(props.input), state);
    }

    let phoneNumber = `#${GlobalVariablePhoneNumberKey}`;
    let contactEmail = `#${GlobalVariableContactEmailKey}`;
    let location = `#${GlobalVariableAddressUrlKey}`;
    let locationName = renderAddressToStringSingleline(globalVariables);

    switch (path(['input', 'link', 'type'], props)) {
        case LcTabName.PHONE: {
            phoneNumber = state[LcTabName.PHONE].input.value;
            if (phoneNumber === `#${GlobalVariablePhoneNumberKey}`) {
                state = assocPath([LcTabName.PHONE, 'input', 'value'], '', state);
            }
            break;
        }
        case LcTabName.EMAIL: {
            contactEmail = state[LcTabName.EMAIL].selection;
            break;
        }
        case LcTabName.LOCATION: {
            location = state[LcTabName.LOCATION].selection;
            locationName = state[LcTabName.LOCATION].name;
            break;
        }
        default: break;
    }

    state = evolve({
        [LcTabName.PHONE]: { value: always(phoneNumber) },
        [LcTabName.EMAIL]: { selection: always(contactEmail) },
        [LcTabName.LOCATION]: { selection: always(location), name: always(locationName) },
    })(state);

    return state;
};
