import { path, pathOr } from 'ramda';
import React from 'react';
import BorderSettings from "../../../../presentational/BorderSettings"; // eslint-disable-line
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import type { PageProps } from "../../../../PropertiesPanel/flowTypes";
import type { FacebookFeedGalleryComponent } from "../../flowTypes";
import * as ActionTypes from '../../actionTypes';
import * as pageIds from '../pagesIds';
import pageTitles from '../pagesTitles';
import {
    getBorderColor,
    getBorderOpacity,
    getBorderStyle,
    getBorderWidth, getComponentColorGradientSettings,
} from "../../../../../view/oneweb/commonComponentSelectors";
import { calcGalleryMaxThumbnailDimensions } from "../../calcRenderProps";
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import OpacitySlider from '../../../../presentational/OpacitySlider/index';
import ColorProperty from '../../../../presentational/ColorProperty/index';
import { captionDefaults } from "../../constants/index";
import { DEFAULT_COLOR_PICKER, THEME_COLOR_PICKER } from '../../../../ColorPicker/constants';
import { getThemeRulesForBackground } from '../../../../ThemeGlobalData/themeRules';
import { getCaptionBoxBorderColorForTheme } from '../../utils';
import { Color } from '../../../../../mappers/flowTypes';

const
    getCaptionTileDimension = (component: FacebookFeedGalleryComponent) => {
        const
            maxThumbnailDimensions =
                calcGalleryMaxThumbnailDimensions(component),
            maxThumbnailWidth = maxThumbnailDimensions.maxThumbnailWidth,
            maxThumbnailHeight = maxThumbnailDimensions.maxThumbnailHeight,
            row = Math.ceil(component.images.length / component.columns),
            maxHeightCaption = (component.height / row) - maxThumbnailHeight;

        return {
            width: maxThumbnailWidth,
            height: maxHeightCaption
        };
    },
    id = pageIds.CAPTIONS_BOX_STYLE,
    title = pageTitles[id],
    view = (props: PageProps<FacebookFeedGalleryComponent>) => {
        const
            { selectedComponent, dispatch } = props,
            styleObj = {
                style: {
                    ...captionDefaults.box[selectedComponent.previousCaptionStyle],
                    ...selectedComponent.captionBoxStyle
                }
            },
            captionTileBorderStyle = {
                style: getBorderStyle(styleObj),
                opacity: getBorderOpacity(styleObj),
                widths: getBorderWidth(styleObj),
            },
            backgroundStyle = getComponentColorGradientSettings(styleObj, props.themeSettingsData.autoColorMode),
            { color } = backgroundStyle,
            fillColor: Color = color && [color[0], color[1], color[2], color[3], 1],
            onOpacityChange = opacity => {
                return dispatch({
                    type: ActionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_OPACITY_CHANGE,
                    payload: opacity,
                    amendToSelf: true
                });
            };
        return (
            <Page>
                <div>
                    <VerticalSpacer />
                    {
                        props.themeSettingsData.autoColorMode
                            ? <ColorProperty
                                colorPickerKind={THEME_COLOR_PICKER}
                                themeColorsData={props.themeColorsData}
                                themeColor={
                                    props.selectedComponent.captionBoxStyle.background
                                    && props.selectedComponent.captionBoxStyle.background.colorData
                                    && props.selectedComponent.captionBoxStyle.background.colorData.themeColor
                                }
                                defaultThemeColor={getThemeRulesForBackground(
                                    props.selectedComponentProps.selectedParentTheme,
                                    props.themeColorsData
                                ).background}
                                label="msg: common.solidColor {Fill colour}"
                                onChangeAction={ActionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_COLOR_SET_AUTO_COLOR}
                                dispatch={dispatch}
                            />
                            : <ColorProperty
                                colorPickerKind={DEFAULT_COLOR_PICKER}
                                label="msg: common.solidColor {Fill colour}"
                                color={fillColor}
                                onChangeAction={ActionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_COLOR_SET}
                                onRemoveAction={ActionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_COLOR_UNSET}
                                dispatch={dispatch}
                            />
                    }
                    <VerticalSpacer />
                    {(
                        fillColor || path(['selectedComponent', 'captionBoxStyle', 'background', 'colorData', 'themeColor'], props)
                    ) && <React.Fragment>
                        <OpacitySlider
                            opacity={backgroundStyle.opacity}
                            onChange={onOpacityChange}
                            onAfterChange={onOpacityChange}
                        />
                        <VerticalSpacer />
                    </React.Fragment>}
                </div>
                {
                    props.themeSettingsData.autoColorMode
                        ? <BorderSettings.view
                            autoColorMode={props.themeSettingsData.autoColorMode}
                            themeColor={pathOr(
                                getCaptionBoxBorderColorForTheme(
                                    props.selectedComponent.captionStyle,
                                    props.selectedComponent.previousCaptionStyle,
                                    path(['selectedComponent', 'captionBoxStyle', 'border'], props),
                                    props.selectedComponentProps.selectedParentTheme,
                                    props.themeColorsData
                                ),
                                ['selectedComponent', 'captionBoxStyle', 'border', 'themeColor'],
                                props
                            )}
                            themeColorsData={props.themeColorsData}
                            styleChangeAction={ActionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BORDER_STYLE}
                            colorChangeAction={ActionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BORDER_COLOR_AUTO_COLOR}
                            opacityChangeAction={ActionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BORDER_OPACITY}
                            widthChangeAction={ActionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTION_BOX_BORDER_WIDTH}
                            dispatch={props.dispatch}
                            {...captionTileBorderStyle}
                            selectedComponent={getCaptionTileDimension(selectedComponent)}
                            showCorners={false}
                        />
                        : <BorderSettings.view
                            autoColorMode={props.themeSettingsData.autoColorMode}
                            color={getBorderColor(styleObj)}
                            styleChangeAction={ActionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BORDER_STYLE}
                            colorChangeAction={ActionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BORDER_COLOR}
                            opacityChangeAction={ActionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BORDER_OPACITY}
                            widthChangeAction={ActionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTION_BOX_BORDER_WIDTH}
                            dispatch={props.dispatch}
                            {...captionTileBorderStyle}
                            selectedComponent={getCaptionTileDimension(selectedComponent)}
                            showCorners={false}
                        />
                }
            </Page>
        );
    };

export { id, title, view };
