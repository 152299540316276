import { awsDynamoDbAction } from "../../../../redux/middleware/aws/actions";

export const
    SET_FACEBOOK_FEED_ACCESS_TOKEN_TRIAL_REQUEST_ACTION = 'SET_FACEBOOK_FEED_ACCESS_TOKEN_TRIAL_REQUEST_ACTION',
    SET_FACEBOOK_FEED_ACCESS_TOKEN_TRIAL_SUCCESS_ACTION = 'SET_FACEBOOK_FEED_ACCESS_TOKEN_TRIAL_SUCCESS_ACTION',
    SET_FACEBOOK_FEED_ACCESS_TOKEN_TRIAL_FAILURE_ACTION = 'SET_FACEBOOK_FEED_ACCESS_TOKEN_TRIAL_FAILURE_ACTION',
    setFacebookFeedAccessCredentialsInDemoAction = (connectedAccountDetails: Array<Object>) => awsDynamoDbAction({
        actionTypes: [
            SET_FACEBOOK_FEED_ACCESS_TOKEN_TRIAL_REQUEST_ACTION,
            SET_FACEBOOK_FEED_ACCESS_TOKEN_TRIAL_SUCCESS_ACTION,
            SET_FACEBOOK_FEED_ACCESS_TOKEN_TRIAL_FAILURE_ACTION,
        ],
        serviceMethod: 'storeFacebookFeedCredentials',
        serviceMethodParams: [connectedAccountDetails]
    });
