import React, { useState, useEffect } from 'react';
import { loadScript } from '../../../../../utils/loadScript';
import { DATA_TEST_ID, TwitterTimelineSDK } from '../constants';
import { Timeline } from './timeline';
import { Tweet } from './tweet';
import { type TwitterComponent } from '../types';
import '../../view/Preview.css';

const TwitterView = (props: TwitterComponent) => {
    const ref = React.createRef<HTMLDivElement>();
    const [key, setKey] = useState(Date.now());
    const { timelineLink, listLink, tweetHTML, ...restProps } = props;

    const parseWidget = async () => {
        if (window.twttr && window.twttr.widgets && ref.current) {
            setKey(Date.now());
            setTimeout(() => {
                window.twttr.widgets.load(ref.current);
            }, 1000);
        }
    };

    useEffect(() => {
        loadScript(TwitterTimelineSDK).then(parseWidget);
    }, []);

    useEffect(() => {
        parseWidget();
    }, [
        props.timelineLink,
        props.listLink,
        props.tweetHTML,
        props.theme,
        props.locale,
    ]);

    return (
        <div
            data-testid={DATA_TEST_ID}
            ref={ref}
            key={key}
            className="widget"
            lang={props.locale}
            style={{ width: props.width, height: props.height, overflow: 'hidden' }}
        >
            {timelineLink && <Timeline link={timelineLink} {...restProps} />}
            {listLink && <Timeline link={listLink} {...restProps} />}
            {tweetHTML && <Tweet tweetHTML={tweetHTML} {...restProps} />}
        </div>
    );
};

export { TwitterView };
