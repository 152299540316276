import React from "react";

import { useIntl } from "../../../../../../view/intl";
import { Select } from "../../../../../oneweb/Text/AIText/Dialogs/CreateAIText/Components/Select";

import styles from "./SelectField.css";

export const SelectField = (props) => {
    const { label, value, data, handleChange } = props;
    const intl = useIntl();

    const selectProps = {
        value,
        data,
        handleChange,
        classes: {
            formControl: styles.formControl,
            menuPaper: styles.menuPaper,
            menuItem: styles.menuItem
        },
        inputClasses: {
            root: styles.root,
            input: styles.input
        }
    };

    return (
        <div className={styles.selectFieldContainer}>
            {
                label && (
                    <div className={styles.stepFieldLabel}>
                        {intl.msgJoint(label)}
                    </div>
                )
            }
            <Select {...selectProps} />
        </div>
    );
};
