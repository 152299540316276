import React, { useState } from 'react';

import pagesTitles from "./titles";
import * as pagesIds from "./ids";
import { SVG_PP_ON_CLICK_ACTION_CHANGED, SVG_SET_LINK } from "../../actionTypes";

import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import ComboBox from '../../../../../view/common/Combobox/index';
import { intlCreateOptions } from '../../../../../view/common/Combobox/utils';
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';
import { PropertiesButton } from '../../../../../view/common/Button/index';
import { OPEN_LINK, NOTHING } from '../../../Gallery/constants/onClickActions';
import openLinkChooserDialog from "../../../../../view/common/dialogs/LinkChooserDialog/actionCreators/openLinkChooserDialog";
import injectIntl from "../../../../../view/intl/injectIntl";

import type { PPPageViewPropTypes } from '../../flowTypes';

const
    id = pagesIds.ON_CLICK,
    title = pagesTitles[pagesIds.ON_CLICK],
    view = injectIntl(({ selectedComponent, dispatch, intl }: PPPageViewPropTypes) => {
        const [onClickAction, setOnClickAction] = useState(selectedComponent.linkAction ? OPEN_LINK : NOTHING);
        return (
            <Page>
                <VerticalSpacer />
                <PropertyContainer label="msg: component.common.pp.onClickOfAnImage {On click of an image}">
                    <ComboBox
                        searchable={false}
                        value={onClickAction}
                        options={intlCreateOptions({
                            [NOTHING]: 'msg: component.common.pp.onClick.actions.doNothing {Do nothing}',
                            [OPEN_LINK]: 'msg: component.common.pp.onClick.actions.openLink {Open link}'
                        }, intl)}
                        onChange={({ value }) => {
                            setOnClickAction(value);
                            dispatch({ type: SVG_PP_ON_CLICK_ACTION_CHANGED, payload: value });
                        }}
                    />
                </PropertyContainer>
                {
                    onClickAction === OPEN_LINK &&
                    <React.Fragment>
                        <VerticalSpacer />
                        <PropertyContainer>
                            <PropertiesButton
                                text={selectedComponent.linkAction ?
                                    'msg: component.image.pp.onClick.editLink {Edit link}' :
                                    'msg: component.image.pp.onClick.addLink {Add link}'}
                                onClick={() => {
                                    dispatch(openLinkChooserDialog({ setLinkAction: SVG_SET_LINK, input: selectedComponent.linkAction }));
                                }}
                            />
                        </PropertyContainer>
                    </React.Fragment>
                }
            </Page>
        );
    });

export { id, view, title };
