import React from 'react';
import styles from './Input.css';
import type { LabeledInputDecoratorProps } from "../flowTypes";
import resolveInputLabel from "./resolveInputLabel";
import { InputLabel, InputSubLabel } from "../../common/view/index";
import { QuestionTip } from '../../../presentational/Tooltip/questionTip/QuestionTip';

export const LabeledInputDecorator = (props: LabeledInputDecoratorProps) => {
    const {
            containerClassName,
            inputContainerClassName,
            labelClassName,
            helptip,
            Input,
            ...inputProps
        } = props,
                label = resolveInputLabel(props.label),
                subLabel = resolveInputLabel(props.subLabel),
        errorsClassName = subLabel.filled ? styles.labeledInputErrors : undefined;

    return (
        <div className={containerClassName}>
            {label.filled && (
                <InputLabel isValue={!label.translate} className={labelClassName}>
                    {label.value}
                </InputLabel>
            )}
            {
                helptip && (
                    <QuestionTip
                        position={helptip.position}
                        distance={3}
                        className={styles.helptipIcon}
                        theme={{
                            container: styles.helptipContainer,
                            tipInfo: styles.helptipInfo
                        }}
                    >{helptip.message}</QuestionTip>
                )
            }
            {<Input
                containerClassName={inputContainerClassName}
                errorsClassName={errorsClassName}
                {...inputProps}
            />}
            {subLabel.filled && <InputSubLabel isValue={!subLabel.translate}>{subLabel.value}</InputSubLabel>}
        </div>
    );
};
