import React from "react";
import registry from '../../../../../view/oneweb/registry/index';
import Image from '../../../../oneweb/Image/kind';

export default ({ groupProps }: Record<string, any>) => {
    const View = registry[Image].mobileEditorConfig?.view;
    return groupProps.map((cmpProps, i) => {
        return <View key={i} {...cmpProps} />;
    });
};
