export const
    IDLE = 'IDLE',
    MOUSE_DOWN_ON_HANDLE = 'MOUSE_DOWN_ON_HANDLE',
    MOVING_COMPONENTS = 'MOVING_COMPONENTS',
    ADDING_COMPONENT = 'ADDING_COMPONENT',
    COMPONENT_CHANGING = 'COMPONENT_CHANGING',
    SHIFTBAR_MOVING = 'SHIFTBAR_MOVING',
    RESIZE_HANDLE_MOVING = 'RESIZE_HANDLE_MOVING',
    MOVING_COMPONENTS_BY_ARROW_KEYS = 'MOVING_COMPONENTS_BY_ARROW_KEYS',

    transientInteractionModesByMouse = {
        [RESIZE_HANDLE_MOVING]: true,
        [MOVING_COMPONENTS]: true,
        [SHIFTBAR_MOVING]: true,
        [COMPONENT_CHANGING]: true
    },
    transientInteractionModes = {
        ...transientInteractionModesByMouse,
        [MOVING_COMPONENTS_BY_ARROW_KEYS]: true
    },
    isTransientByMouse = (mode: string) => !!transientInteractionModesByMouse[mode],
    isTransient = (mode: string) => !!transientInteractionModes[mode];
