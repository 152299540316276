import { siteSettingsValueActionType } from "../../../../App/epics/siteSettings/valueActionType";
import mveHoveringAndSelectionVAT from '../../../epics/hoveringAndSelection/valueActionType';
import { Lit } from "../../../../../lit";
import {
    MVE_HEADER_PP_MENU_STYLE_PAGE_TAB_CLICKED,
    showMveHeaderPropertiesPanelAction,
} from "../actions";
import PropertiesPanelInitialState from "../../../../PropertiesPanel/epic/defaultState";
import { setMveHeaderPpStateFromSiteSettings } from "./setMveHeaderPpStateFromSiteSettings";
import { receiveOnly } from "../../../../../epics/makeCondition";
import { ComponentsMapSelector } from "../../../../Workspace/epics/componentsEval/selectorActionTypes";
import stylesheetsValueActionType from "../../../../Workspace/epics/stylesheets/valueActionType";
import { ClosedTab } from "../pages/MenuStyleMveHeaderPpPage";
import { setMveOpenMenuAction } from "../../../epics/hoveringAndSelection/actions";
import type { InstancePropertiesPanelRegistryConfigUpdater } from "../../../../PropertiesPanel/instance/types";

export const MveHeaderPropertiesPanelUpdaters: Array<InstancePropertiesPanelRegistryConfigUpdater> = [
    {
        conditions: [
            receiveOnly(ComponentsMapSelector),
            receiveOnly(stylesheetsValueActionType),
            siteSettingsValueActionType,
        ],

        reducer: ({ state: stateHolder, values: [componentsMap, stylesheet, siteSettings] }) => {
            if (!stateHolder.state.instance) {
                return { state: stateHolder };
            }

            return {
                state: {
                    state: setMveHeaderPpStateFromSiteSettings({
                        state: stateHolder.state,
                        siteSettings,
                        componentsMap,
                        stylesheet,
                    }),
                    scope: stateHolder.scope,
                },
            };
        },
    },
    {
        conditions: [mveHoveringAndSelectionVAT],

        reducer: ({ state, values: [hoveringAndSelection] }) => {
            const isHeaderSelected = hoveringAndSelection[Lit.isHeaderSelected];

            if (isHeaderSelected) {
                return {
                    state,
                    actionToDispatch: showMveHeaderPropertiesPanelAction(),
                };
            }

            return {
                state: PropertiesPanelInitialState,
            };
        },
    },
    {
        conditions: [MVE_HEADER_PP_MENU_STYLE_PAGE_TAB_CLICKED],

        reducer: ({ state, values: [tabId] }) => {
            return {
                state,
                actionToDispatch: setMveOpenMenuAction(tabId !== ClosedTab)
            };
        },
    },
];
