export const instagramStockImagesList = [
    "ampersand-creative.jpg",
    "andrew-small.jpg",
    "aron-visuals.jpg",
    "charles-deluvio.jpg",
    "clement.jpg",
    "eberhard-grossgasteiger-1.jpg",
    "eberhard-grossgasteiger-2.jpg",
    "erol-ahmed.jpg",
    "harley-davidson.jpg",
    "ian-dooley.jpg",
    "igor-son.jpg",
    "imani-bahati.jpg",
    "joel-filipe-1.jpg",
    "joel-filipe-2.jpg",
    "julien-moreau.jpg",
    "laurine-bailly.jpg",
    "maarten-deckers.jpg",
    "maja-petric.jpg",
    "marita-kavelashvili.jpg",
    "mourad-saadi-1.jpg",
    "mourad-saadi-2.jpg",
    "nicholas-kampouris.jpg",
    "patrick-hendry.jpg",
    "sharad-bhat.jpg",
    "sherman.jpg",
    "simone-hutsch.jpg",
    "stephan-schmid.jpg",
    "wang-loko.jpg",
    "wil-stewart.jpg",
    "wu-jianxiong.jpg"
];
