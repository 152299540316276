import { DataSite } from "../../../../dal/model/index";
import type { CalcRenderer } from '../../Workspace/epics/componentsProps/flowTypes';
import type { AnyComponent, ComponentsMap } from '../../../redux/modules/children/workspace/flowTypes';
import type { TextComponent } from './flowTypes';
import type { Stylesheets, StylesheetsIdToNameMap } from '../../Workspace/epics/stylesheets/flowTypes';
import type {
    ThemeSiteSettingsDataType,
    ThemeColorTypes,
    ThemeBackgroundType,
    ThemeColorDataType
} from '../../ThemeGlobalData/flowTypes';
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";
import { BACKGROUND_THEME_WHITE } from '../../ThemeGlobalData/constants';

type TextUIProps = {
    className: string,
    componentId: string,
    content: string,
    height: number,
    globalVariables: Object,
    site: DataSite,
    verticalAlignment: 'top' | 'middle' | 'bottom',
    selectedParentTheme: ThemeBackgroundType,
    themeOverrideColor: ThemeColorTypes | null | undefined,
    themeSettingsData: ThemeSiteSettingsDataType,
    themeColorsData: ThemeColorDataType,
    themeHighlightColor?: ThemeColorTypes|null,
    themeShadowBlurRadius: number,
    themeShadowColor?: ThemeColorTypes|null,
    themeShadowOffsetX: number,
    themeShadowOffsetY: number,
}

type ComponentDependencies = {
    stylesheets: Stylesheets,
    site: DataSite,
    componentsMap: ComponentsMap,
    stylesheetsIdToNameMap: StylesheetsIdToNameMap,
    globalVariables: Object,
    themeSettingsData: ThemeSiteSettingsDataType,
    templateStyle: Object,
}

type TextCalcRenderProps = CalcRenderer<TextComponent, null, ComponentDependencies, TextUIProps>;

export const getWrappedItemBlocks = (wrappedItems: Array<AnyComponent>) => {
    if (!wrappedItems) {
        return {};
    }
    const wrappedItemBlocks = wrappedItems.reduce((acc, wrappedItem) => {
        const relPara = wrappedItem.relPara;
        if (relPara) {
            acc[relPara.index] = acc[relPara.index] || [];// eslint-disable-line no-param-reassign
            acc[relPara.index].push(wrappedItem);
        }
        return acc;
    }, {});
    return wrappedItemBlocks;
};

const
    textCalcRenderProps: TextCalcRenderProps =
        ({
            component,
            componentId,
            componentDependencies,
            selectedParentTheme
        }) => {
            const
                {
                    content,
                    verticalAlignment = 'top',
                    themeOverrideColor,
                    themeHighlightColor,
                    themeShadowBlurRadius,
                    themeShadowColor,
                    themeShadowOffsetX,
                    themeShadowOffsetY,
                } = component,
                { themeSettingsData, stylesheets } = componentDependencies,
                themeColorsData = getThemeColorsFromStylesheet(stylesheets),
                className = 'tinyMceContent';

            return {
                componentId,
                className,
                content,
                height: component.height,
                globalVariables: componentDependencies.globalVariables,
                site: componentDependencies.site,
                verticalAlignment,
                ...({ selectedParentTheme: selectedParentTheme || BACKGROUND_THEME_WHITE }),
                themeOverrideColor,
                themeSettingsData,
                themeColorsData,
                themeHighlightColor,
                themeShadowBlurRadius,
                themeShadowColor,
                themeShadowOffsetX,
                themeShadowOffsetY,
            };
        };

export {
    textCalcRenderProps
};
