import React from 'react';
import * as styles from './styles.css';
import { Msg } from "../../../../../view/intl";
import { closeDialog } from "../../../../App/actionCreators/index";
import PrimaryButton from "../../../../../view/common/Button/PrimaryButton";
import { Dialog, DialogBody } from '../../../../../view/common/dialogs/baseDialog/index';
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import { StripTypes } from "../../../../../view/common/dialogs/baseDialog/Dialog";
import { FailedGoogleReviewsDialogId } from '../dialogIds';

const DialogWidth = parseInt(styles.DialogWidth, 10),
    DialogHeight = parseInt(styles.DialogHeight, 10);

const NoGoogleReviewsDialog = ({ dispatch }) => {
    const closeDialogAC = () => { dispatch(closeDialog()); };
    return (
        <Dialog onClose={closeDialogAC} stripType={StripTypes.NONE}>
            <DialogBody className={styles.dialogBody}>
                <div className={styles.errorIcon} />
                <h3 className={styles.noReviewTitle}>
                    <Msg k="component.googleReviews.failureHeader">
                        {`Couldn\'t import Google reviews`}
                    </Msg>
                </h3>
                <p className={styles.noReviewMessage}>
                    <Msg k="component.googleReviews.failureMessage">
                        {`Seems like we can't receive your reviews from Google at the moment. Please try again later.`}
                    </Msg>
                </p>
                <div className={styles.footerButtons}>
                    <PrimaryButton onClick={closeDialogAC} className={`${styles.button}`}>
                        <Msg k="common.OK">
                            OK
                        </Msg>
                    </PrimaryButton>
                </div>
            </DialogBody>
        </Dialog>);
};
const openGoogleReviewDialogAC = {
    type: "OPEN_DIALOG",
    payload: { dialogId: FailedGoogleReviewsDialogId }
};

export default getCenteredDialogConfig(DialogWidth, DialogHeight, NoGoogleReviewsDialog);

export {
    openGoogleReviewDialogAC
};
