import { normalizeLocale } from "../../../../server/shared/utils/normalizeLocale";
import { DEFAULT_LOCALE } from "../../../../server/shared/constants";
import { getAppConfig } from "../App/epics/appConfig/appConfig";

export const getDefultLocaleId = () => {
    const { i18n: { defaultLocaleId = DEFAULT_LOCALE } = {} } = getAppConfig();
    return defaultLocaleId;
};

export const getDefultLocaleIdNormalized = () => normalizeLocale(getDefultLocaleId());
