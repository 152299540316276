import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localizedFormat from "dayjs/plugin/localizedFormat";
import calendar from "dayjs/plugin/calendar";
import updateLocale from "dayjs/plugin/updateLocale";

import { FuncProxy } from "../../../server/shared/utils/proxy/FuncProxy";
import { CALENDAR_DEFAULT_LOCALE_FORMAT, CALENDAR_LOCALE_FORMATS } from "./locale";

dayjs.extend(weekday);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.extend(calendar);

const loadLocaleFile = (locale: string) => {
    try {
        require(`dayjs/locale/${locale}`); // eslint-disable-line import/no-dynamic-require
    } catch (err: any) {
        console.log(err);
    }
};

const updateCalendarFormat = (locale) => {
    const format = (
        CALENDAR_LOCALE_FORMATS[locale] ||
        CALENDAR_DEFAULT_LOCALE_FORMAT
    );

    dayjs.updateLocale(locale, { calendar: format });
};

export default FuncProxy(dayjs, {
    locale: (locale: string) => {
        loadLocaleFile(locale);
        dayjs.locale(locale);
        updateCalendarFormat(locale);
    },
});
