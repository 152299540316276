import React from 'react';
import cx from 'classnames';
import styles from './PremiumIcon.css';

type PremiumIconProps = {
    iconCustomClass?: string;
    large ?: true;
}

export const PremiumIcon = ({ iconCustomClass, large }: PremiumIconProps) => (
    <div
        className={
            cx(
                { [`${styles.premiumIcon}`]: !large, [`${styles.premiumIconLarge}`]: large },
                iconCustomClass,
            )
        }
    />
);
