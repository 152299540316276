// @ts-nocheck
import getCmpZIndex from "./getCmpZIndex";
import makeUuid from "./makeUuid";
import { minPercentOverlap, minPxOverlap, minPageSectionHeight, REASONS } from "./constants";
import menuKind from '../components/oneweb/Menu/kind';
import stripKind from '../components/oneweb/Strip/kind';
import WebshopKind from '../components/oneweb/WebShop/kind';
import imageKind from '../components/oneweb/Image/kind';
import sectionKind from '../components/oneweb/Section/kind';
import copyObjectAndReplaceWithNewUUIDs from "../utils/copyObjectAndReplaceWithNewUUIDs";
import { isBoxKind } from "../components/oneweb/componentKinds";
import isGhost from "../components/oneweb/Code/isGhost";
import CodeComponentKind from "../components/oneweb/Code/kind";
import defaultSectionStyle from "../components/oneweb/Section/defaultSectionStyle";

const PAGE_KEY = 'page',
    TEMPLATE_KEY = 'template';

export const
    isStripKind = (kind) => kind === stripKind,
    isWebShopKind = (kind) => kind === WebshopKind,
    isBgKind = (kind) => isStripKind(kind) || isBoxKind(kind),
    isContainerKind = (kind) => isBgKind(kind) || kind === imageKind,
    isSectionKind = (kind) => kind === sectionKind,
    isSectionCmp = (cmp) => (cmp && cmp.kind === sectionKind),
    isHeaderSection = (component) => {
        const { kind, inTemplate, top } = component;
        return isSectionKind(kind) && inTemplate && !top;
    },
    isFooterSection = (component) => {
        const { kind, inTemplate, top } = component;
        return isSectionKind(kind) && inTemplate && !!top;
    },
    getCmpRect = ({ top, left, width, height }) => ({
        top, left, right: (left + width), bottom: (top + height)
    }),
    isNegCmpOverlappingHeader = (cmp) => {
        const { bottom, top } = getCmpRect(cmp);
        if (bottom <= 0 || top >= 0) {
            return false;
        }
        const { height } = cmp,
            visibleHeight = bottom,
            visiblePortion = (visibleHeight / height) * 100,
            isVisibleNegativeCmp = visiblePortion > 20 && visibleHeight > 25;

        return isVisibleNegativeCmp;
    },
    topSorter = (c1, c2) => c1.top - c2.top,
    depthSorter = (c1, c2) => getCmpZIndex(c1) - getCmpZIndex(c2),
    orderIndexSorter = (a, b) => (b.orderIndex - a.orderIndex),
    divideInToGroups = (cmps, allowMinOverlaps, depthSort) => {
        let groups = [], group = {};
        cmps.sort(topSorter).forEach(cmp => {
            const { top, height } = cmp,
                bottom = top + height;
            if (!group.bottom) {
                group = { top, bottom, cmps: [cmp] };
            } else {
                const overlappingHeight = (top < group.bottom && bottom > group.bottom) && (group.bottom - top),
                    divideGroup = (top >= group.bottom) ||
                        (
                            allowMinOverlaps &&
                            overlappingHeight &&
                            ((overlappingHeight <= minPxOverlap) ||
                                (overlappingHeight <= (height * (minPercentOverlap / 100))))
                        );
                if (divideGroup) {
                    if (depthSort) {
                        group.cmps.sort(depthSorter);
                    }
                    groups.push(group);
                    group = { top, bottom, cmps: [cmp] };
                } else {
                    group.bottom = Math.max(group.bottom, bottom);
                    group.cmps.push(cmp);
                }
            }
        });
        if (!isNaN(group.bottom)) {
            if (depthSort) {
                group.cmps.sort(depthSorter);
            }
            groups.push(group);
        }
        return groups;
    },
    getCmps = (componentsMap) => Object.keys(componentsMap).map(id => componentsMap[id]),
    getCmpsMap = (cmps) => cmps.reduce((acc, c) => {
        acc[c.id] = c;
        return acc;
    }, {}),
    getTmpCmpsFromCmpsMap = (cmpsMap) => {
        let result = [];
        Object.keys(cmpsMap).forEach(id => {
            if (cmpsMap[id].inTemplate) {
                result.push(cmpsMap[id]);
            }
        });
        return result;
    },
    getDataByDeletingUncommonTmpCmps = (pageIds, inputDataPerPageId) => {
        let commonTmpCmps = [], delTmpCmps = [], outputDataPerPageId = { ...inputDataPerPageId };
        pageIds.forEach((pageId, i) => {
            let cmpsMap = inputDataPerPageId[pageId].cmpsMap;
            if (i === 0) {
                commonTmpCmps = getTmpCmpsFromCmpsMap(cmpsMap);
            } else {
                for (let j = 0; j < commonTmpCmps.length; j++) {
                    if (!cmpsMap[commonTmpCmps[j].id]) {
                        delTmpCmps[commonTmpCmps[j].id] = true;
                        commonTmpCmps.splice(j, 1);
                        j--;
                    }
                }
            }
        });
        if (delTmpCmps.length) {
            pageIds.forEach(pageId => {
                let newCmpsMap = { ...outputDataPerPageId[pageId].cmpsMap };
                delTmpCmps.forEach(c => {
                    delete newCmpsMap[c.id];
                });

                outputDataPerPageId[pageId] = {
                    ...outputDataPerPageId[pageId],
                    cmpsMap: newCmpsMap
                };
            });
        }
        return outputDataPerPageId;
    },
    getNonGhostCmps = (componentsMap) => getCmps(componentsMap).filter(cmp => !cmp.location),
    getCmpVerticalCenter = c => c.top + (c.height / 2),
    getCmpHorizontalCenter = c => c.left + (c.width / 2),
    getCmpBottom = (c) => c.top + c.height,
    getStrips = (cmps) => cmps.filter(cmp => isStripKind(cmp.kind)),
    getWebShops = (cmps) => cmps.filter(cmp => isWebShopKind(cmp.kind)),
    isVerticalMenu = (c) => c.kind === menuKind && c.layoutType.indexOf("VERTICAL_") > -1,
    getVerticalMenu = (cmps) => cmps.find(c => isVerticalMenu(c)),
    getVerticalMenus = (cmps) => cmps.filter(c => isVerticalMenu(c)),
    isValidComponent = (component) => {
        const { width, height, kind } = component;
        return !(width <= 0 || height <= 0 || (width === 1 && height === 1 && kind !== CodeComponentKind));
    },
    isTemplateStripInRange = (strip, top, bottom) => (
        strip.inTemplate &&
        strip.top === top &&
        getCmpBottom(strip) === bottom),
    getHeaderStripAndSharedBgStrip = (groupsOfStrips, headerEndingAt) => {
        const stripsGroupsAtHeader = groupsOfStrips.filter(g => g.top < headerEndingAt),
            isPageStripWithBgImage = (strip) => (
                !strip.inTemplate &&
                strip.top < headerEndingAt &&
                strip.style &&
                strip.style.background &&
                strip.style.background.assetData &&
                getCmpBottom(strip) === stripsGroupsAtHeader[0].bottom &&
                stripsGroupsAtHeader[0].bottom > headerEndingAt
            );
        if (stripsGroupsAtHeader.length !== 1) {
            return { headerStrip: null, sharedBgStrip: null };
        }
        const strips = stripsGroupsAtHeader[0].cmps;
        if (
            strips.length === 1 &&
            isPageStripWithBgImage(strips[0])
        ) {
            return { headerStrip: null, sharedBgStrip: strips[0] };
        }
        if (isTemplateStripInRange(strips[0], 0, headerEndingAt)) {
            return { headerStrip: strips[0], sharedBgStrip: null };
        }
        if (
            strips.length > 1 &&
            isTemplateStripInRange(strips[1], 0, headerEndingAt) &&
            isPageStripWithBgImage(strips[0])
        ) {
            return { headerStrip: strips[1], sharedBgStrip: strips[0] };
        }
        return { headerStrip: null, sharedBgStrip: null };
    },
    getFooterStrip = (groupsOfStrips, footerStartingAt, footerHeight) => {
        const stripsGroupsAtFooter = groupsOfStrips.filter(g => g.bottom > footerStartingAt);
        if (stripsGroupsAtFooter.length !== 1) {
            return null;
        }
        const strips = stripsGroupsAtFooter[0].cmps;
        return isTemplateStripInRange(strips[0], footerStartingAt, footerStartingAt + footerHeight) ? strips[0] : null;
    },
    getLowestTemplateStripAtPosition = (strips, top, bottom) => strips
        .filter(s => s.inTemplate && s.top >= top && s.top <= bottom)
        .sort(depthSorter)
        .find((s, i) => i === 0 && s.top === top && s.top + s.height === bottom),
    getDummyPageDataByTemplateId = (templateId) => ({
        // all these are dummy values
        type: "web.data.components.Page",
        id: makeUuid(),
        templateId,
        name: "Dummy",
        width: 1000,
        height: 2000,
        bbox: {
            top: 0,
            right: 1000,
            bottom: 2000,
            left: 0
        },
        items: [],
        orderIndex: 2,
        wrap: false,
        stretch: false,
        mobileDown: false,
        mobileData: { relations: [], styles: {}, groups: {}, settings: {} },
        time: 1594020408483,
        etag: "1-68a3235b6ee4a0150eb5ac27e78a6b77",
        rev: 1594020408483,
        shareHeaderAndFirstSectionBgImg: false,
        shareBgImgOffsetTop: 0
    }),
    createSection = ({ top, bottom, inTemplate, orderIndex }) => ({
        id: makeUuid(),
        inTemplate: !!inTemplate,
        top,
        height: bottom - top,
        "kind": sectionKind,
        "left": 0,
        "width": 5000,
        "orderIndex": orderIndex,
        "relTo": null,
        "relIn": null,
        "relPage": null,
        "relPara": null,
        "wrap": false,
        stretch: true,
        "style": defaultSectionStyle,
        "pin": 0,
    }),
    getTitleForSection = (cmp, newCmpsMap) => {
        const defaultStripNameRegex = /^(Template)?Strip[\d]+$/;
        let title = cmp.title;
        if (title && !defaultStripNameRegex.test(title)) {
            return title;
        }
        if (cmp.inTemplate && !cmp.top) {
            title = 'Header';
            return title;
        }
        if (cmp.inTemplate && !!cmp.top) {
            title = 'Footer';
            return title;
        }
        if (title) {
            title = title.replace('Template', '');
            title = title.replace('Strip', 'Section');
        }
        const sectionTitles = Object.entries(newCmpsMap).map(([, sectionCmp]) => sectionCmp)
            .filter(({ id, title, kind }) => (id !== cmp.id && !!title && isSectionKind(kind)))
            .map(({ title }) => title && title.replace('Strip', 'Section'));
        let count = 0;
        while (!title || sectionTitles.includes(title)) {
            title = 'Section' + (++count);
        }
        return title;
    },
    getMaxGapAndIndexOfNextGroupAfterMaxGap = (groups) => {
        let maxGap = 0, indexOfNextGroupAfterMaxGap = -1;
        groups.forEach((g, i) => {
            if (i > 0) {
                const gap = g.top - groups[i - 1].bottom;
                if (gap > maxGap) {
                    maxGap = gap;
                    indexOfNextGroupAfterMaxGap = i;
                }
            }
        });
        return { maxGap, indexOfNextGroupAfterMaxGap };
    },
    sectionsTopSorter = (a, b) => {
        if (a.top === b.top) {
            if (a.inTemplate !== b.inTemplate) {
                return a.inTemplate ? -1 : 1;
            } else {
                return a.id ? 1 : -1;
            }
        }
        return a.top - b.top;
    },
    applyTopDiffOnComponentsFrom = (top, diff, cmpsMap) => {
        return Object.values(cmpsMap).reduce((acc, cmp) => {
            const { top: cmpTop, height: cmpHeight } = cmp,
                center = cmpTop + (cmpHeight / 2);
            if (center >= top && center) {
                return { ...acc, [cmp.id]: { ...cmp, top: cmp.top + diff } };
            }
            return { ...acc, [cmp.id]: cmp };
        }, {});
    },
    reorderCmpsByLayersFrom = (top, cmps) => {
        let newTop = top;
        const groups = divideInToGroups(cmps);
        const cmpMap = {};
        groups.forEach(({ top, bottom, cmps: grpCmps }) => {
            const height = bottom - top;
            grpCmps.forEach(cmp => {
                const firstCmpTop = grpCmps[0].top,
                    currentCmpTop = cmp.top;
                cmpMap[cmp.id] = { ...cmp, top: newTop + currentCmpTop - firstCmpTop };
            });
            newTop = newTop + height;
        });
        return { cmpMap, top, bottom: Math.ceil(newTop) };
    },
    createSectionWithComponents = (top, cmps) => {
        const { cmpMap, bottom } = reorderCmpsByLayersFrom(top, cmps);
        const sectionConfig = {
            top,
            bottom: Math.max(bottom, minPageSectionHeight),
            inTemplate: false,
            orderIndex: 0
        };
        const section = createSection(sectionConfig);
        return { cmpMap, section };
    },
    handleNegativeTopPageCmps = (cmpsMap, cmps) => {
        const allSections = Object.values(cmpsMap)
            .filter(cmp => isSectionKind(cmp.kind))
            .sort(sectionsTopSorter);
        const { top: firstSectionTop } = allSections[1],
            {
                section,
                section: { top, height, id: sectionId },
                cmpMap: sectionCmpMap
            } = createSectionWithComponents(firstSectionTop, cmps),
            newCmpMap = {
                ...applyTopDiffOnComponentsFrom(top, height, cmpsMap),
                ...sectionCmpMap,
                [sectionId]: section
            };
        return { newCmpMap, section };
    },
    adjustNegativeTopCmpsWhichBelongToHeader = (cmpsMap) => {
        let negativeCmps = [];
        let newCmpsMap = { ...cmpsMap };
        let isNegativeCmpFound = false;
        Object.values(cmpsMap).forEach(cmp => {
            if (cmp.top < 0) {
                isNegativeCmpFound = isNegativeCmpFound || true;
                if (cmp.inTemplate) {
                    newCmpsMap[cmp.id] = { ...cmp, top: 0 };
                } else if ((cmp.top + cmp.height) > 0) {
                    negativeCmps.push(cmp);
                }
            }
        });
        const { cmpMap: negativeTemplateAndOverlappingPageCmpsMap } = reorderCmpsByLayersFrom(0, negativeCmps);
        return {
            isNegativeCmpFound,
            cmpsMap: { ...newCmpsMap, ...negativeTemplateAndOverlappingPageCmpsMap }
        };
    },
    isComponentWithinParent = (component, parent) => {
        if (isContainerKind(parent.kind)) {
            const
                cmpRect = getCmpRect(component),
                parentRect = getCmpRect(parent),
                cmpVCenter = getCmpVerticalCenter(component);

            if (isStripKind(parent.kind)) {
                return parentRect.top <= cmpVCenter && parentRect.bottom >= cmpVCenter;
            }
            return (
                parentRect.top <= cmpRect.top &&
                parentRect.left <= cmpRect.left &&
                parentRect.right >= cmpRect.right &&
                parentRect.bottom >= cmpRect.bottom
            );
        }
        return false;
    },
    getMobileHiddenComponents = (negativeTopCmpsMap, overlappingCmpsMap) => {
        const componentsMap = { ...negativeTopCmpsMap, ...overlappingCmpsMap };
        let templateId = 'TEMPLATE-ID',
            hiddenComponents = {},
            parentChildIdsMap = { [templateId]: [] };

        const components = getNonGhostCmps(componentsMap)
            .sort(depthSorter)
            .reverse();

        components.forEach((cmp, index) => {
            const { id, relIn, mobileHide, kind } = cmp;

            if (mobileHide && isBgKind(kind) && parentChildIdsMap[cmp.id]) {
                const children = parentChildIdsMap[cmp.id];
                while (children.length) {
                    const id = children.shift();
                    if (parentChildIdsMap[id]) {
                        children.push(...parentChildIdsMap[id]);
                    }
                    hiddenComponents[id] = true;
                }
            }
            if (mobileHide || (isBgKind(kind) && !parentChildIdsMap[cmp.id])) {
                hiddenComponents[id] = true;
                return;
            }
            if (relIn) {
                const parentCmp = componentsMap[relIn.id];
                if (parentCmp && isComponentWithinParent(cmp, parentCmp)) {
                    parentChildIdsMap = {
                        ...parentChildIdsMap,
                        [parentCmp.id]: [...(parentChildIdsMap[parentCmp.id] || []), id]
                    };
                    return;
                }
            }
            for (let currentIndex = index + 1; currentIndex < components.length; currentIndex++) {
                const parentCmp = components[currentIndex],
                    canBeParent = parentCmp && !(!!parentCmp.inTemplate === false && !!cmp.inTemplate === true);
                if (canBeParent && isComponentWithinParent(cmp, parentCmp)) {
                    if (parentCmp.mobileHide || hiddenComponents[parentCmp.id]) {
                        hiddenComponents[id] = true;
                        return;
                    }
                    parentChildIdsMap = {
                        ...parentChildIdsMap,
                        [parentCmp.id]: [...(parentChildIdsMap[parentCmp.id] || []), id]
                    };
                    return;
                }
            }
            parentChildIdsMap[templateId].push(id);
        });
        const filterMobileHiddenCmps = (ids) => {
            ids.forEach((id) => {
                const cmp = componentsMap[id];
                if (parentChildIdsMap[id]) {
                    filterMobileHiddenCmps(parentChildIdsMap[id]);
                } else if (cmp && isBgKind(cmp.kind)) {
                    hiddenComponents[id] = true;
                }
            });
        };
        filterMobileHiddenCmps(Object.keys(parentChildIdsMap));
        return {
            parentChildIdsMap,
            hiddenComponents: Object.keys(hiddenComponents).reduce((acc, id) => {
                if (overlappingCmpsMap[id]) { return acc; }
                acc[id] = true;
                return acc;
            }, {})
        };
    },
    getComponentsMapWithoutNegativeTopComponents = (cmpsMap) => {
        let negativeTopComponents = {},
            newCmpsMap = {},
            overlappingCmps = {},
            negativeCmpFound = false;
        let deletedTemplateCmp = {};
        Object.values(cmpsMap).forEach(cmp => {
            if (cmp.top < 0 && !isGhost(cmp)) {
                negativeCmpFound = true;
                if (isNegCmpOverlappingHeader(cmp)) {
                    overlappingCmps[cmp.id] = cmp;
                } else {
                    let component = { ...cmp };
                    if (menuKind === cmp.kind) {
                        if (cmp.inTemplate) {
                            deletedTemplateCmp[cmp.id] = true;
                        }
                        deletedTemplateCmp[cmp.id] = true;
                        return;
                    }
                    if (CodeComponentKind === cmp.kind) {
                        component = { ...component, mobileHide: false };
                    }
                    negativeTopComponents = { ...negativeTopComponents, [cmp.id]: component };
                }
            } else {
                newCmpsMap = { ...newCmpsMap, [cmp.id]: cmp };
            }
        });
        const { hiddenComponents, parentChildIdsMap } = getMobileHiddenComponents(negativeTopComponents, overlappingCmps); // eslint-disable-line

        overlappingCmps = Object.values(overlappingCmps).reduce((acc, cmp) => {
            const { id, mobileHide } = cmp;
            const getOverlappingComponentsWithChildren = (children) => {
                children.forEach((childId) => {
                    if (acc[childId] || hiddenComponents[childId]) { return; }
                    if (parentChildIdsMap[childId]) {
                        getOverlappingComponentsWithChildren(parentChildIdsMap[childId]);
                    }
                    acc[childId] = cmpsMap[childId];
                });
            };
            if (!mobileHide && parentChildIdsMap[id]) {
                getOverlappingComponentsWithChildren(parentChildIdsMap[id]);
            }
            acc[id] = cmp;
            return acc;
        }, {});

        negativeTopComponents = Object.values(negativeTopComponents).reduce((acc, cmp) => {
            const { id } = cmp;
            if (overlappingCmps[id]) { return acc; }
            acc[id] = cmp;
            return acc;
        }, {});

        return {
            negativeCmpFound,
            hiddenComponents: Object.keys(hiddenComponents),
            negativeTopComponents,
            cmpsMap: newCmpsMap,
            overlappingCmps: Object.values(overlappingCmps),
            deletedTemplateCmp
        };
    },
    getCmpMapFromArray = (cmps) => cmps.reduce((acc, cmp) => ({ ...acc, [cmp.id]: cmp }), {}),
    getTotalAreaByAllCmps = (cmps) => cmps.reduce((acc, c) => {
        return acc + (c.height * c.width);
    }, 0),
    isIntersecting = (r1, r2) => {
        return !(r2.left > r1.right ||
            r2.right < r1.left ||
            r2.top > r1.bottom ||
            r2.bottom < r1.top);
    },
    getIntersectingRectArea = (r1, r2) => {
        return ((Math.min(r1.bottom, r2.bottom) - Math.max(r1.top, r2.top)) *
            (Math.min(r1.right, r2.right) - Math.max(r1.left, r2.left)));
    },

    getAreaOccupiedByCmps = (cmps) => {
        let rects = [], area = getTotalAreaByAllCmps(cmps);
        cmps.slice().sort((a, b) => a.orderIndex - b.orderIndex).forEach((c, i) => {
            const r2 = getCmpRect(c);
            rects.push(r2);
            if (i) {
                rects.forEach(r1 => {
                    if (isIntersecting(r1, r2)) {
                        area -= getIntersectingRectArea(r1, r2);
                    }
                });
            }
        });
        return area;
    },

    mergeGroupsByRegion = (groups) => {
        let newGroups = [], t = [];
        groups.forEach(g => {
            if (!t.length) {
                t.push(g);
                return;
            }
            const l = t.length - 1;
            if (t[l].isPageRegion === g.isPageRegion) {
                t[l] = {
                    top: t[l].top,
                    bottom: g.bottom,
                    cmps: [...t[l].cmps, ...g.cmps],
                    isPageRegion: g.isPageRegion
                };
            } else {
                newGroups.push(...t);
                t = [g];
            }
        });
        newGroups.push(...t);
        return newGroups;
    },
    mergeTopTemplateRegionInToHeaderAndBottomTemplateRegionInToFooter = (groups) => {
        let headerGroupIndex = -1, footerGroupIndex = -1;
        groups.forEach((g, i) => {
            if (!g.isPageRegion && headerGroupIndex === -1) {
                headerGroupIndex = i;
            }
            if (
                !g.isPageRegion &&
                headerGroupIndex > -1 &&
                groups[headerGroupIndex].bottom <= (groups[i].top - minPageSectionHeight) &&
                i > headerGroupIndex + 1
            ) {
                footerGroupIndex = i;
            }
        });
        if (headerGroupIndex > -1 && footerGroupIndex > -1) {
            let newGroups = [], headerGroup = {}, footerGroup = {};
            groups.forEach((g, i) => {
                if (i <= headerGroupIndex) {
                    if (i === 0) {
                        headerGroup.top = g.top;
                    }
                    headerGroup.bottom = g.bottom;
                    headerGroup.isPageRegion = false;
                    headerGroup.cmps = [...(headerGroup.cmps || []), ...g.cmps];
                    return;
                }
                if (!newGroups.length) {
                    newGroups.push(headerGroup);
                }

                if (i >= footerGroupIndex) {
                    if (i === footerGroupIndex) {
                        footerGroup.top = g.top;
                    }
                    footerGroup.bottom = g.bottom;
                    footerGroup.isPageRegion = false;
                    footerGroup.cmps = [...(footerGroup.cmps || []), ...g.cmps];
                } else {
                    newGroups.push(g);
                }
            });
            newGroups.push(footerGroup);
            return newGroups;
        }
        return groups;
    },
    // mergeSmallerTmpGroupsInToBigNeighbourPageGroups = (oldGroups) => {
    //     let groups = [...oldGroups];
    //     for (let i = 0; i < groups.length; i++) {
    //         if (i === 0 || i === groups.length - 1) {
    //             continue;
    //         }
    //         const { top, bottom, isPageRegion, cmps } = groups[i],
    //             prevGroup = groups[i - 1],
    //             prevGroupHeight = prevGroup.bottom - prevGroup.top,
    //             nextGroup = groups[i + 1],
    //             nextGroupHeight = nextGroup.bottom - nextGroup.top,
    //             currentGroupHeight = bottom - top;
    //         if (
    //             !isPageRegion &&
    //             prevGroup.isPageRegion &&
    //             nextGroup.isPageRegion &&
    //             (
    //                 currentGroupHeight <= (Math.min(prevGroupHeight, nextGroupHeight) * 0.3) &&
    //                 prevGroupHeight >= 300 && nextGroupHeight >= 300
    //             )
    //         ) {
    //             groups.splice(
    //                 i - 1,
    //                 3,
    //                 {
    //                     top: prevGroup.top,
    //                     bottom: nextGroup.bottom,
    //                     isPageRegion: true,
    //                     cmps: [...prevGroup.cmps, ...cmps, ...nextGroup.cmps]
    //                 }
    //             );
    //             i--;
    //         }
    //     }
    //     return groups;
    // },
    getTmpCmps = (cmps) => cmps.filter(c => c.inTemplate),
    getPageCmps = (cmps) => cmps.filter(c => !c.inTemplate),
    isBigCmp = ({ kind, width }, templateWidth, percent) =>
        isStripKind(kind) || width >= (templateWidth * (percent / 100)),
    getLastBottom = (cmps) => cmps.reduce((acc, c) => {
        return Math.max(c.top + c.height, acc);
    }, 0),
    getFirstTop = (cmps) => cmps.reduce((acc, c) => {
        return Math.min(c.top, acc);
    }, 10000),
    splitPageAndTmpCmps = (cmps) => {
        let pageCmps = [], templateCmps = [];
        cmps.forEach(c => {
            if (c.inTemplate) {
                templateCmps.push(c);
            } else {
                pageCmps.push(c);
            }
        });
        return { pageCmps, templateCmps };
    },
    getRect = (cmps) => {
        let rect = null;
        if (cmps.length) {
            rect = getCmpRect(cmps[0]);
            cmps.forEach(c => {
                const cRect = getCmpRect(c);
                rect.top = Math.min(c.top, rect.top);
                rect.bottom = Math.max(cRect.bottom, rect.bottom);
                rect.right = Math.max(cRect.right, rect.right);
                rect.left = Math.min(c.left, rect.left);
            });
        }
        return rect;
    },

    // isSeparatedByZones = (templateCmps, pageCmps, templateWidth, templateVerticalMenu) => {
    //     const templateRect = getRect(templateCmps),
    //         templateRectWidth = templateRect.right - templateRect.left;
    //     if (templateVerticalMenu && !pageCmps.length && templateRectWidth <= (templateWidth * 0.5)) {
    //         return true;
    //     }
    //     const pageRect = pageCmps.length && getRect(pageCmps),
    //         pageRectWidth = pageRect && (pageRect.right - pageRect.left),
    //         tvmRect = templateVerticalMenu && getCmpRect(templateVerticalMenu);
    //     //TODO THIS IF CONDITIONS NEEDS TO BE OPTIMIZED PROPERLY
    //     if (
    //         pageRect &&
    //         templateVerticalMenu &&
    //         templateVerticalMenu.width <= (templateWidth * 0.5) &&
    //         (pageRect.left - tvmRect.right >= -50 || tvmRect.left - pageRect.right >= -50)
    //     ) {
    //         return true;
    //     }
    //     return (
    //         (
    //             pageRect &&
    //             pageRectWidth >= (templateWidth * 0.4) &&
    //             templateRect &&
    //             pageRect.left - templateRect.right >= -50 ||
    //             templateRect.left - pageRect.right >= -50
    //         ) &&
    //         templateRectWidth <= (templateWidth * 0.4)
    //     );
    // },
    // isPageStripAboveAndBelowTemplateStrip = (allStrips, components, top, bottom) => {
    //     let isPageStripAbove = false, isPageStripBelow = false, templateStripIndex = -1;
    //     const strips = allStrips.filter(c => {
    //         const rect = getCmpRect(c);
    //         return (rect.top < bottom && rect.bottom > top);
    //     }).sort(orderIndexSorter);
    //     strips.forEach((s, i) => {
    //         if (s.inTemplate) {
    //             if (templateStripIndex < 0) {
    //                 templateStripIndex = i;
    //                 if (i > 0) {
    //                     isPageStripAbove = true;
    //                 }
    //             }
    //         } else if (templateStripIndex > -1 && !isPageStripBelow) {
    //             isPageStripBelow = true;
    //         }
    //     });
    //     return isPageStripAbove && isPageStripBelow;
    // },
    // divideGroupsInToPageOrTemplate = (groups, templateWidth) => {
    //     let newGroups = [], reasons = [], reasonsFooter = [];
    //     groups.forEach((group, i) => {
    //         const { templateCmps, pageCmps } = splitPageAndTmpCmps(group.cmps),
    //             templateVerticalMenu = getVerticalMenu(templateCmps);
    //         // if (isPageStripAboveAndBelowTemplateStrip(allStrips, components, group.top, group.bottom)) {
    //         //     newGroups.push({ ...group, isPageRegion: true });
    //         //     return;
    //         // }
    //         if (
    //             !templateVerticalMenu &&
    //             (!pageCmps.length || templateCmps.some((c) => isBigCmp(c, templateWidth, 50)))
    //         ) {
    //             newGroups.push({ ...group, isPageRegion: false });
    //             return;
    //         }
    //         if (
    //             !templateCmps.length ||
    //             isSeparatedByZones(templateCmps, pageCmps, templateWidth, templateVerticalMenu)
    //         ) {
    //             newGroups.push({ ...group, isPageRegion: true });
    //             if (i === 0) {
    //                 reasons.push(REASONS.separatedByZones);
    //             }
    //             if (i === groups.length - 1) {
    //                 reasonsFooter.push(REASONS.separatedByZones);
    //             }
    //             return;
    //         }
    //         const pageArea = getAreaOccupiedByCmps(pageCmps),
    //             templateArea = getAreaOccupiedByCmps(templateCmps);
    //         if (i === 0 && pageArea >= templateArea) {
    //             reasons.push(REASONS.separatedByArea);
    //         }
    //         if (i === groups.length - 1 && pageArea >= templateArea) {
    //             reasonsFooter.push(REASONS.separatedByArea);
    //         }
    //         newGroups.push({ ...group, isPageRegion: pageArea >= templateArea });
    //     });
    //     return { groups: newGroups, reasons, reasonsFooter };
    // },
    getBiggestPageGroupIndex = (groups) => {
        let maxHeight = 0, index = -1;
        groups.forEach((g, i) => {
            if (g.isPageRegion && (g.bottom - g.top) > maxHeight) {
                maxHeight = Math.max(maxHeight, g.bottom - g.top);
                index = i;
            }
        });
        return index;
    },

    getPageHeight = (cmps) => {
        let pageHeight = 0;
        cmps.forEach(c => {
            pageHeight = Math.max(pageHeight, getCmpBottom(c));
        });
        return pageHeight;
    },
    getSectionBottom = (s) => (s.id ? s.top + s.height : s.bottom),
    mergeSectionsIfComponentsOverlaps = (components, sections, newSectionPositions, extendBelowHeader) => {
        let allSections = sections.concat(newSectionPositions).sort(sectionsTopSorter),
            mergedSections = [allSections[0], allSections[1]],
            i = 2;
        if (extendBelowHeader.id && extendBelowHeader.id === allSections[1].id && allSections.length > 3) {
            i = 3;
            mergedSections.push(allSections[2]);
        }

        for (; i < allSections.length - 1; i++) {
            const s = allSections[i],
                intersectingCmps = components.filter(c => {
                    const cRect = getCmpRect(c),
                        overlappingHeight = c.height - (s.top - c.top);
                    return (
                        !isStripKind(c.kind) &&
                        (c.top < s.top) &&
                        (cRect.bottom > s.top) &&
                        (overlappingHeight > 0)
                    );
                });
            if (intersectingCmps.length) {
                let lastSection = mergedSections.pop();
                mergedSections.push({ top: lastSection.top, bottom: getSectionBottom(s) });
            } else {
                mergedSections.push(s);
            }
        }
        if (allSections.length > 2) {
            mergedSections.push(allSections[allSections.length - 1]);
        }

        return mergedSections;
    },
    extendHeader = (headerEndingAt, footerStartingAt, components, templateWidth, allowExtensionOfHeader) => {
        // const tmpsCmps = getTmpCmps(components),
        //     overlappingTmpCmp = tmpsCmps.find(c =>
        //         c.inTemplate &&
        //         getCmpCenter(c) >= headerEndingAt &&
        //         !tmpsCmps.find(cmp =>
        //             cmp.inTemplate &&
        //             getCmpBottom(cmp) === headerEndingAt &&
        //             isBigCmp(cmp, templateWidth, 50)) &&
        //         tmpsCmps.some(cmp =>
        //             cmp.id !== c.id &&
        //             cmp.inTemplate &&
        //             getCmpCenter(cmp) < headerEndingAt &&
        //             isIntersecting(getCmpRect(cmp), getCmpRect(c))));
        // if (overlappingTmpCmp) {
        //     return { headerEndingAt: 0, reasons: ['templateCmpsInHeaderOverlappingWithPageRegion'] };
        // }
        // to increase header height up to below(in layer) page strip
        if (allowExtensionOfHeader) {
            const pageStripInHeader = components.find(c =>
                !c.inTemplate &&
                isStripKind(c.kind) &&
                getCmpVerticalCenter(c) < headerEndingAt &&
                getCmpBottom(c) > headerEndingAt &&
                (headerEndingAt + 250) >= getCmpBottom(c) &&
                getCmpBottom(c) < footerStartingAt &&
                !components.some(cmp =>
                    cmp.id !== c.id &&
                    !cmp.inTemplate &&
                    getCmpVerticalCenter(cmp) > headerEndingAt &&
                    isIntersecting(getCmpRect(cmp), getCmpRect(c))));
            if (pageStripInHeader) {
                return { headerEndingAt: getCmpBottom(pageStripInHeader), reasons: [] };
            }
        }

        if (
            !components.some(c =>
                c.inTemplate &&
            isStripKind(c.kind) &&
            getCmpBottom(c) === headerEndingAt)
        ) {
            let i = 0;
            for (;i < components.length; i++) {
                if (components[i].top >= headerEndingAt) {
                    break;
                }
            }
            if (components[i] && !components.some(c =>
                isIntersecting(
                    getCmpRect(c),
                    { top: headerEndingAt + 1, left: -1000, right: 1000, bottom: components[i].top - 1 }
                )) && headerEndingAt && allowExtensionOfHeader) {
                if (
                    (isStripKind(components[i].kind) ||
                        (components.find(c => isStripKind(c.kind) && !c.inTemplate && c.top === components[i].top))) &&
                    (components[i].top - headerEndingAt) <= headerEndingAt
                ) {
                    return { headerEndingAt: components[i].top, reasons: [] };
                }
                const { templateCmps } = splitPageAndTmpCmps(components),
                    templateRectTop = getRect(templateCmps).top;
                // to increase header height to occupy some or all free space below
                return { headerEndingAt: (headerEndingAt + templateRectTop >= components[i].top ?
                    (headerEndingAt + ((components[i].top - headerEndingAt) / 2)) : headerEndingAt + templateRectTop),
                reasons: [] };
            }
            // to decrease header height
            const lastBigTmpCmpBottom = getTmpCmps(components)
                .filter(c => getCmpVerticalCenter(c) < headerEndingAt && isBigCmp(c, templateWidth, 80))
                .reduce((acc, c) => Math.max(getCmpBottom(c), acc), 0);
            if (lastBigTmpCmpBottom && (components.some(c =>
                !c.inTemplate &&
                !isStripKind(c.kind) &&
                c.top >= lastBigTmpCmpBottom &&
                getCmpVerticalCenter(c) < headerEndingAt))) {
                return { headerEndingAt: lastBigTmpCmpBottom, reasons: [] };
            }
        }
        return { headerEndingAt, reasons: [] };
    },
    mergeGroupsWithSmallerGap = (groups, minGap) => {
        let newGroups = [];
        groups.forEach((g, i) => {
            if (i > 0 && g.top - groups[i - 1].bottom < minGap) {
                const lastGroup = newGroups.pop();
                newGroups.push({
                    top: lastGroup.top,
                    bottom: g.bottom,
                    cmps: [...lastGroup.cmps, ...g.cmps]
                });
            } else {
                newGroups.push(g);
            }
        });
        return newGroups;
    },
    getTmpCmpsInRegion = (groups, components, templateWidth, pageRect, pageRectCenter, belowPageRectCenter) => {
        let isSeparatedByZones = false, cmps = [];
        groups.forEach(g => {
            if (belowPageRectCenter) {
                if (g.bottom < pageRectCenter) {
                    cmps.push(...getTmpCmps(g.cmps));
                }
            } else if (g.top > pageRectCenter) {
                cmps.push(...getTmpCmps(g.cmps));
            }
        });
        if (!cmps.length) {
            cmps = components.filter(c => {
                const cmpCenterVertically = getCmpVerticalCenter(c),
                    cmpCenterHorizontally = getCmpHorizontalCenter(c),
                    cmpBottom = getCmpBottom(c);
                if (belowPageRectCenter) {
                    if (cmpCenterVertically >= pageRectCenter) {
                        return false;
                    }
                } else if (cmpCenterVertically <= pageRectCenter) {
                    return false;
                }
                if (
                    !isStripKind(c.kind) &&
                    (cmpCenterHorizontally < templateWidth * 0.25 || cmpCenterHorizontally > templateWidth * 0.75)
                ) {
                    const intersectionHeight = Math.min(cmpBottom, pageRect.bottom) - Math.max(c.top, pageRect.top);
                    isSeparatedByZones = (
                        (intersectionHeight >= c.height * 0.4) ||
                        (intersectionHeight >= (pageRect.bottom - pageRect.top) * 0.4)
                    );
                    return !isSeparatedByZones;
                }
                return true;
            });
        }

        return { cmps, isSeparatedByZones };
    },
    getAllRects = (nonRelInTmpCmps, nonRelInPageCmps, templateWidth, pageHeight) => {
        const templateRect = getRect(nonRelInTmpCmps),
            pageCmpsRect = getRect(nonRelInPageCmps),
            edges = { left: pageCmpsRect.left, right: pageCmpsRect.right },
            pageCenter = pageHeight / 2;
        if (templateRect.bottom < pageCenter) {
            return { pageRect: { top: templateRect.bottom, bottom: pageHeight, ...edges } };
        }
        if (templateRect.top > pageCenter) {
            return { pageRect: { top: 0, bottom: templateRect.top, ...edges } };
        }
        if (
            nonRelInTmpCmps[0].top >= 500 &&
            nonRelInPageCmps.find(c => getCmpVerticalCenter(c) <= nonRelInTmpCmps[0].top)
        ) {
            const rect = getRect(
                nonRelInPageCmps.filter(c => getCmpVerticalCenter(c) > 0 && getCmpVerticalCenter(c) < nonRelInTmpCmps[0].top)
            );
            return { pageRect: { top: 0, bottom: nonRelInTmpCmps[0].top, left: rect.left, right: rect.right } };
        }

        if (pageCmpsRect.top >= templateRect.bottom || pageCmpsRect.bottom <= templateRect.top) {
            return { pageRect: pageCmpsRect };
        }

        const tmpGroups = divideInToGroups(nonRelInTmpCmps);
        if (tmpGroups.length === 1) {
            if ((tmpGroups[0].bottom - tmpGroups[0].top) / 2 < pageCenter) {
                return { pageRect: { top: tmpGroups[0].bottom, bottom: pageHeight, ...edges } };
            } else {
                return { pageRect: { top: 0, bottom: tmpGroups[0].top, ...edges } };
            }
        }

        let tmpGrpsLengthAbovePageRect = 0, tmpGrpsLengthBelowPageRect = 0, totalTmpBlocksHeight = 0;
        tmpGroups.forEach(g => {
            if (g.bottom <= pageCmpsRect.top) {
                tmpGrpsLengthAbovePageRect++;
            }
            if (g.top >= pageCmpsRect.bottom) {
                tmpGrpsLengthBelowPageRect++;
            }
            totalTmpBlocksHeight += g.bottom - g.top;
        });
        if (
            tmpGrpsLengthAbovePageRect &&
                tmpGrpsLengthBelowPageRect &&
                (
                    nonRelInPageCmps.some(c => isStripKind(c.kind)) ||
                        (pageCmpsRect.left + ((pageCmpsRect.right - pageCmpsRect.left) / 2) < templateWidth)
                ) &&
                (pageCmpsRect.bottom - pageCmpsRect.top) >= totalTmpBlocksHeight * 0.1
        ) {
            return { pageRect: pageCmpsRect };
        }

        const tmpRectAbovePageRect = getRect(nonRelInTmpCmps.filter(c => getCmpVerticalCenter(c) < pageCmpsRect.top)),
            tmpRectBelowPageRect = getRect(nonRelInTmpCmps.filter(c => getCmpVerticalCenter(c) > pageCmpsRect.bottom));
        if (tmpRectAbovePageRect &&
            tmpRectBelowPageRect &&
            (
                nonRelInPageCmps.some(pCmp => isStripKind(pCmp.kind)) ||
                    (pageCmpsRect.left + ((pageCmpsRect.right - pageCmpsRect.left) / 2) < templateWidth)
            ) &&
            tmpGroups.every(g => !isIntersecting(g, pageCmpsRect))) {
            return { pageRect: pageCmpsRect };
        }

        const minBottom = Math.min(templateRect.bottom, pageCmpsRect.bottom),
            maxTop = Math.max(templateRect.top, pageCmpsRect.top),
            pageRectHeight = pageCmpsRect.bottom - pageCmpsRect.top;
        if ((minBottom - maxTop >= pageRectHeight * 0.5) &&
            (
                (pageCmpsRect.left - templateRect.right >= -50) ||
                (templateRect.left - pageCmpsRect.right >= -50)
            )
        ) {
            return { isSeparatedByZones: true, pageRect: pageCmpsRect };
        }

        const { maxGap, indexOfNextGroupAfterMaxGap } = getMaxGapAndIndexOfNextGroupAfterMaxGap(tmpGroups);
        if (maxGap && indexOfNextGroupAfterMaxGap > -1) {
            const
                lastHeaderTmpGrpBottom = tmpGroups[indexOfNextGroupAfterMaxGap - 1].bottom,
                firstFooterTmpGrpTop = tmpGroups[indexOfNextGroupAfterMaxGap].top,
                pageCmpsRectBtwTmp = getRect(nonRelInPageCmps.filter(c => {
                    const vCenter = getCmpVerticalCenter(c),
                        hCenter = getCmpHorizontalCenter(c);
                    if (vCenter > templateRect.top && vCenter < templateRect.bottom) {
                        return isStripKind(c.kind) ? true : (hCenter >= templateRect.left && hCenter <= templateRect.right);
                    }
                    return false;
                }));
            if (pageCmpsRectBtwTmp) {
                const pageRectHeight = pageCmpsRectBtwTmp.bottom - pageCmpsRectBtwTmp.top,
                    pageRectCenter = pageCmpsRectBtwTmp.top + (pageRectHeight / 2);
                if (
                    pageRectHeight > maxGap &&
                    pageRectCenter > lastHeaderTmpGrpBottom &&
                    pageRectCenter < firstFooterTmpGrpTop &&
                    (Math.min(firstFooterTmpGrpTop, pageCmpsRectBtwTmp.bottom) -
                        Math.max(pageCmpsRectBtwTmp.top, lastHeaderTmpGrpBottom) >= (pageRectHeight * 0.5))
                ) {
                    return { pageRect: pageCmpsRectBtwTmp };
                }
            }

            if (
                firstFooterTmpGrpTop - lastHeaderTmpGrpBottom >= 100 ||
                nonRelInPageCmps.some(c =>
                    c.top >= lastHeaderTmpGrpBottom && getCmpBottom(c) <= firstFooterTmpGrpTop) ||
                (
                    (firstFooterTmpGrpTop - pageCenter >= pageHeight * 0.15) &&
                    (pageCenter - lastHeaderTmpGrpBottom >= pageHeight * 0.15)
                )
            ) {
                const rect = getRect(nonRelInPageCmps.filter(c =>
                    getCmpVerticalCenter(c) > lastHeaderTmpGrpBottom && getCmpVerticalCenter(c) < firstFooterTmpGrpTop));
                return {
                    pageRect: {
                        top: lastHeaderTmpGrpBottom,
                        bottom: firstFooterTmpGrpTop,
                        left: rect ? rect.left : 0,
                        right: rect ? rect.right : templateWidth
                    }
                };
            } else {
                return { onlyHeader: true };
            }
        }
        return { pageRect: pageCmpsRect };
    },
    // removeRelIns = (cmps) => {
    //     let cmpsMap = getCmpsMap(cmps), newCmps = [];
    //     cmps.forEach(c => {
    //         newCmps.push(c.relIn && !cmpsMap[c.relIn.id] ? { ...c, relIn: null } : c);
    //     });
    //     return newCmps;
    // },
    areDynamicHeightCmpsInHeader = (headerEndingAt, dynamicHeightTmpCmpsIdsMap, components) => (
        headerEndingAt > 0 &&
        !!Object.keys(dynamicHeightTmpCmpsIdsMap).length &&
        components.some(c =>
            c.inTemplate &&
            dynamicHeightTmpCmpsIdsMap.hasOwnProperty(c.id) &&
            getCmpVerticalCenter(c) <= headerEndingAt)),
    getHeaderAndFooterPositions = (
        inpComponents,
        templateWidth,
        pageHeight,
        doNotDetectMinHeader,
        dynamicHeightTmpCmpsIdsMap,
    ) => {
        let headerEndingAt = 0,
            footerTemplateCmps = [],
            footerStartingAt = pageHeight,
            components = inpComponents.slice().sort(topSorter),
            allPageCmps = [],
            // allTemplateCmps = [],
            nonRelInPageCmps = [],
            nonRelInTmpCmps = [],
            allNonRelInCmps = [],
            groups = [],
            reasons = [],
            reasonsFooter = [];
            // tmpVerticalMenu = null;
        components.forEach(c => {
            if (c.inTemplate) {
                if (isVerticalMenu(c)) {
                    // tmpVerticalMenu = c;
                    return;
                }
                if (!c.relIn) {
                    nonRelInTmpCmps.push(c);
                    allNonRelInCmps.push(c);
                }
                // allTemplateCmps.push(c);
            } else {
                if (!c.relIn) {
                    nonRelInPageCmps.push(c);
                    allNonRelInCmps.push(c);
                }
                allPageCmps.push(c);
            }
        });
        if (components.length) {
            if (allPageCmps.length === components.length || !nonRelInTmpCmps.length) {
                groups = divideInToGroups(components);
                let r = (groups[0].top >= minPageSectionHeight) ? groups[0].top : 0;
                return {
                    headerEndingAt: doNotDetectMinHeader ? 0 : r,
                    footerStartingAt: groups[groups.length - 1].bottom,
                    footerTemplateCmps,
                    reasons: [REASONS.allCmpsArePageCmps],
                    reasonsFooter: [REASONS.allCmpsArePageCmps]
                };
            }
            if (!nonRelInPageCmps.length) {
                groups = divideInToGroups(nonRelInTmpCmps);
                const { maxGap, indexOfNextGroupAfterMaxGap } = getMaxGapAndIndexOfNextGroupAfterMaxGap(groups);
                if (maxGap < 25 && groups.length > 1) {
                    groups = [{
                        top: groups[0].top,
                        bottom: groups[groups.length - 1].bottom,
                        cmps: groups.reduce((acc, g) => {
                            acc.push(...g.cmps);
                            return acc;
                        }, [])
                    }];
                }
                if (groups.length === 1) {
                    if (groups[0].top <= 200) {
                        headerEndingAt = groups[0].bottom;
                        footerStartingAt = groups[0].bottom + 400;
                        reasonsFooter.push(REASONS.noCmpsNearBottomOfPage);
                    } else {
                        headerEndingAt = 0;
                        reasons.push(REASONS.noCmpsNearTopOfPage);
                        footerStartingAt = groups[0].top;
                        footerTemplateCmps = getTmpCmps(groups[0].cmps);
                    }
                } else {
                    headerEndingAt = groups[indexOfNextGroupAfterMaxGap - 1].bottom;
                    footerStartingAt = groups[indexOfNextGroupAfterMaxGap].top;
                    footerTemplateCmps = groups.reduce((acc, g, i) => {
                        if (i >= indexOfNextGroupAfterMaxGap) {
                            acc.push(...g.cmps);
                        }
                        return acc;
                    }, []);
                }
                const headerHasDynamicHeightCmps = areDynamicHeightCmpsInHeader(
                    headerEndingAt, dynamicHeightTmpCmpsIdsMap, components
                );
                return {
                    headerEndingAt,
                    footerStartingAt,
                    footerTemplateCmps,
                    reasons,
                    reasonsFooter,
                    headerHasDynamicHeightCmps
                };
            }
        } else {
            return {
                headerEndingAt,
                footerStartingAt: 400,
                footerTemplateCmps,
                reasons: [REASONS.noCmps],
                reasonsFooter: [REASONS.noCmps]
            };
        }
        const
            { pageRect, isSeparatedByZones, onlyHeader } = getAllRects(
                nonRelInTmpCmps, nonRelInPageCmps, templateWidth, pageHeight
            );
        if (onlyHeader) {
            return {
                headerEndingAt: pageHeight,
                footerStartingAt: pageHeight + 400,
                footerTemplateCmps: [],
                reasons: [REASONS.all_page_is_header],
                reasonsFooter: [REASONS.all_page_is_header]
            };
        }
        const
            pageRectCenter = pageRect.top + ((pageRect.bottom - pageRect.top) / 2);
        if (isSeparatedByZones) {
            let r = components[0].top >= minPageSectionHeight ? components[0].top : 0;
            return {
                headerEndingAt: doNotDetectMinHeader ? 0 : r,
                footerStartingAt: pageHeight,
                footerTemplateCmps: [],
                reasons: [REASONS.separatedByZones],
                reasonsFooter: [REASONS.separatedByZones]
            };
        }
        groups = divideInToGroups(allNonRelInCmps);
        if (groups.length === 1) {
            if (groups[0].top <= 200) {
                headerEndingAt = groups[0].bottom;
                footerStartingAt = groups[0].bottom + 400;
                reasonsFooter.push(REASONS.noCmpsNearBottomOfPage);
            } else {
                headerEndingAt = 0;
                reasons.push(REASONS.noCmpsNearTopOfPage);
                footerStartingAt = groups[0].top;
                footerTemplateCmps = getTmpCmps(groups[0].cmps);
            }
            const headerHasDynamicHeightCmps = areDynamicHeightCmpsInHeader(
                headerEndingAt, dynamicHeightTmpCmpsIdsMap, components
            );
            return {
                headerEndingAt,
                footerStartingAt,
                footerTemplateCmps,
                reasons,
                reasonsFooter,
                headerHasDynamicHeightCmps
            };
        }
        const
            hr = getTmpCmpsInRegion(groups, nonRelInTmpCmps, templateWidth, pageRect, pageRectCenter, true),
            fr = getTmpCmpsInRegion(groups, nonRelInTmpCmps, templateWidth, pageRect, pageRectCenter);
        if (hr.cmps.length) {
            headerEndingAt = getCmpBottom(hr.cmps[0]);
            for (let i = 0; i < hr.cmps.length; i++) {
                if (hr.cmps[i].top - headerEndingAt >= 200) {
                    break;
                }
                headerEndingAt = Math.max(getCmpBottom(hr.cmps[i]), headerEndingAt);
            }
        } else {
            let r = components[0].top >= minPageSectionHeight ? components[0].top : 0;
            headerEndingAt = doNotDetectMinHeader ? 0 : r;
            reasons.push(hr.isSeparatedByZones ? REASONS.separatedByZones : REASONS.noCmpsNearTopOfPage);
        }
        if (fr.cmps.length) {
            footerStartingAt = fr.cmps[fr.cmps.length - 1].top;
            for (let i = fr.cmps.length - 1; i >= 0; i--) {
                if (footerStartingAt - getCmpBottom(fr.cmps[i]) >= 200) {
                    break;
                }
                footerStartingAt = fr.cmps[i].top;
            }
            footerTemplateCmps = fr.cmps.filter(c => c.top >= footerStartingAt);
        } else {
            reasonsFooter.push(fr.isSeparatedByZones ? REASONS.separatedByZones : REASONS.noCmpsNearBottomOfPage);
        }
        const allowExtensionOfHeader = !areDynamicHeightCmpsInHeader(
            headerEndingAt, dynamicHeightTmpCmpsIdsMap, components
        );
        let r = extendHeader(headerEndingAt, footerStartingAt, components, templateWidth, allowExtensionOfHeader);
        headerEndingAt = r.headerEndingAt;
        if (footerStartingAt - headerEndingAt <= minPageSectionHeight) {
            headerEndingAt = pageHeight;
            footerTemplateCmps = [];
            footerStartingAt = pageHeight + 400;
            reasonsFooter = [REASONS.all_page_is_header];
        }
        reasons.push(...r.reasons);
        if (!headerEndingAt && components.length && components[0].top >= 25) {
            // headerEndingAt = components[0].top;
            if (headerEndingAt < 0) {
                reasons.push(REASONS.negativeTopCmps);
            }
            if (headerEndingAt === 0 && !reasons.length) {
                reasons.push(REASONS.needsToFindReasonStill);
            }
        }

        return {
            headerEndingAt,
            footerStartingAt,
            footerTemplateCmps,
            reasons,
            reasonsFooter,
            headerHasDynamicHeightCmps: !allowExtensionOfHeader
        };
        // console.log('==========came==============');
        // groups = divideInToGroups(
        //     // components,
        //     components.filter(c => !c.relIn && (!tmpVerticalMenu || (c.id !== tmpVerticalMenu.id)) && c.top >= 0),
        //     true
        // );
        // if (tmpVerticalMenu) {
        //     groups.forEach(g => {
        //         const minBottom = Math.min(g.bottom, getCmpBottom(tmpVerticalMenu)),
        //             maxTop = Math.max(g.top, tmpVerticalMenu.top),
        //             intersectingHeight = minBottom - maxTop;
        //         if (intersectingHeight >= ((g.bottom - g.top) * 0.40)) {
        //             g.cmps.push({
        //                 ...tmpVerticalMenu,
        //                 top: maxTop,
        //                 height: intersectingHeight
        //             });
        //         }
        //     });
        // }
        //
        // const r = divideGroupsInToPageOrTemplate(groups, templateWidth);
        // reasons = r.reasons.slice();
        // reasonsFooter = r.reasonsFooter.slice();
        // groups = r.groups;
        // if (groups.every(g => !g.isPageRegion)) {
        //     const { maxGap, indexOfNextGroupAfterMaxGap } = getMaxGapAndIndexOfNextGroupAfterMaxGap(groups);
        //     if (maxGap < 25 && groups.length > 1) {
        //         groups = [{
        //             top: groups[0].top,
        //             bottom: groups[groups.length - 1].bottom,
        //             cmps: groups.reduce((acc, g) => {
        //                 acc.push(...g.cmps);
        //                 return acc;
        //             }, [])
        //         }];
        //     }
        //     if (groups.length === 1) {
        //         if (groups[0].top <= 180) {
        //             headerEndingAt = groups[0].bottom;
        //             footerStartingAt = groups[0].bottom + 400;
        //             reasonsFooter.push(REASONS.noCmpsNearBottomOfPage);
        //         } else {
        //             headerEndingAt = 0;
        //             reasons.push(REASONS.noCmpsNearTopOfPage);
        //             footerStartingAt = groups[0].top;
        //             footerTemplateCmps = getTmpCmps(groups[0].cmps);
        //         }
        //     } else {
        //         headerEndingAt = groups[indexOfNextGroupAfterMaxGap - 1].bottom;
        //         footerStartingAt = groups[indexOfNextGroupAfterMaxGap].top;
        //         footerTemplateCmps = groups.reduce((acc, g, i) => {
        //             if (i >= indexOfNextGroupAfterMaxGap) {
        //                 acc.push(...g.cmps);
        //             }
        //             return acc;
        //         }, []);
        //     }
        //     return { headerEndingAt, footerStartingAt, footerTemplateCmps, reasons, reasonsFooter };
        // }
        // groups = mergeGroupsByRegion(groups);
        // groups = mergeTopTemplateRegionInToHeaderAndBottomTemplateRegionInToFooter(groups);
        // groups = mergeSmallerTmpGroupsInToBigNeighbourPageGroups(groups);
        // const biggestPageGroupIndex = getBiggestPageGroupIndex(groups);
        // if (biggestPageGroupIndex >= 0 && biggestPageGroupIndex < groups.length - 1) {
        //     let allFooterCmps = groups[biggestPageGroupIndex + 1].cmps;
        //     if (tmpVerticalMenu) {
        //         allFooterCmps = allFooterCmps.filter(c => c.id !== tmpVerticalMenu.id);
        //     }
        //     footerTemplateCmps = getTmpCmps(allFooterCmps);
        //     footerStartingAt = getFirstTop(footerTemplateCmps);
        // }
        // if (biggestPageGroupIndex > 0) {
        //     headerEndingAt = getLastBottom(getTmpCmps(groups[biggestPageGroupIndex - 1].cmps));
        //     let r = extendHeader(headerEndingAt, footerStartingAt, components, templateWidth);
        //     headerEndingAt = r.headerEndingAt;
        //     reasons.push(...r.reasons);
        // }
        // if (!headerEndingAt && !biggestPageGroupIndex && components.length && components[0].top >= 25) {
        //     headerEndingAt = components[0].top;
        //     if (headerEndingAt < 0) {
        //         reasons.push(REASONS.negativeTopCmps);
        //     }
        //     if (headerEndingAt === 0 && !reasons.length) {
        //         reasons.push(REASONS.needsToFindReasonStill);
        //     }
        // }
        // return { headerEndingAt, footerStartingAt, footerTemplateCmps, reasons, reasonsFooter };
    },
    getHeaderAndFooterPositions1 = (inpComponents, templateWidth, pageHeight, doNotDetectMinHeader) => {
        let reasons = [], reasonsFooter = [];
        if (!inpComponents.length) {
            return {
                headerEndingAt: 0,
                footerStartingAt: 400,
                footerTemplateCmps: [],
                reasons: [REASONS.noCmps],
                reasonsFooter: [REASONS.noCmps]
            };
        }

        let headerEndingAt = 0,
            footerTemplateCmps = [],
            footerStartingAt = pageHeight,
            components = inpComponents.slice().sort(topSorter),
            allTemplateCmps = getTmpCmps(components),
            nonRelInTmpCmps = allTemplateCmps.filter(c => !c.relIn),
            groups = [];
        if (components.length && !allTemplateCmps.length) {
            groups = divideInToGroups(components);
            let r = (groups[0].top >= minPageSectionHeight) ? groups[0].top : 0;
            return {
                headerEndingAt: doNotDetectMinHeader ? 0 : r,
                footerStartingAt: groups[groups.length - 1].bottom,
                footerTemplateCmps,
                reasons: [REASONS.allCmpsArePageCmps],
                reasonsFooter: [REASONS.allCmpsArePageCmps]
            };
        }
        const
            pageCmps = components.filter(c => !c.relIn && !c.inTemplate),
            templateRect = getRect(nonRelInTmpCmps),
            pageRect = getRect(pageCmps);
        if (pageRect && templateRect) {
            const minBottom = Math.min(templateRect.bottom, pageRect.bottom),
                maxTop = Math.max(templateRect.top, pageRect.top),
                pageRectHeight = pageRect.bottom - pageRect.top;
            if ((minBottom - maxTop >= pageRectHeight * 0.4) &&
                (
                    Math.abs(pageRect.left - templateRect.right) <= templateWidth * 0.2 ||
                    Math.abs(templateRect.left - pageRect.right) <= templateWidth * 0.2
                )
            ) {
                return {
                    headerEndingAt: 0,
                    footerStartingAt: pageHeight,
                    footerTemplateCmps: [],
                    reasons: [REASONS.separatedByZones],
                    reasonsFooter: [REASONS.separatedByZones]
                };
            }
        }
        groups = divideInToGroups(nonRelInTmpCmps.filter(c => !isVerticalMenu(c)));
        groups = mergeGroupsWithSmallerGap(groups, minPageSectionHeight);
        const { maxGap, indexOfNextGroupAfterMaxGap } = getMaxGapAndIndexOfNextGroupAfterMaxGap(groups);
        const set = (headerIndex, footerIndex) => {
            headerEndingAt = groups[headerIndex].bottom;
            footerStartingAt = groups[footerIndex].top;
            footerTemplateCmps = components.filter(c => c.inTemplate && getCmpVerticalCenter(c) > footerStartingAt);
        };
        if (indexOfNextGroupAfterMaxGap > 0 && maxGap < minPageSectionHeight) {
            groups = [{
                top: groups[0].top,
                bottom: groups[groups.length - 1].bottom,
                cmps: []
            }];
        }
        if (groups.length === 1) {
            const pageCmpsAboveGroup = pageCmps.filter(c => getCmpVerticalCenter(c) <= groups[0].top),
                pageCmpsBelowGroup = pageCmps.filter(c => getCmpVerticalCenter(c) >= groups[0].bottom);
            let header = false;
            if (!pageCmpsAboveGroup.length && pageCmpsBelowGroup.length) {
                header = true;
            }
            if (pageCmpsAboveGroup.length && pageCmpsBelowGroup.length) {
                const rectAbove = getRect(pageCmpsAboveGroup),
                    rectBelow = getRect(pageCmpsBelowGroup);
                if (rectAbove.bottom - rectAbove.top < rectBelow.bottom - rectBelow.top) {
                    header = true;
                }
            }
            if (!pageCmpsAboveGroup.length && !pageCmpsBelowGroup.length && groups[0].top <= 180) {
                header = true;
            }
            if (header) {
                headerEndingAt = groups[0].bottom;
                footerStartingAt = groups[0].bottom + 400;
                reasonsFooter.push(REASONS.noCmpsNearBottomOfPage);
            } else {
                let r = components[0].top >= minPageSectionHeight ? components[0].top : 0;
                headerEndingAt = doNotDetectMinHeader ? 0 : r;
                reasons.push(REASONS.noCmpsNearTopOfPage);
                footerStartingAt = groups[0].top;
                footerTemplateCmps = components.filter(c => c.inTemplate && getCmpVerticalCenter(c) > footerStartingAt);
            }
        } else if (groups.length === 2) {
            set(indexOfNextGroupAfterMaxGap - 1, indexOfNextGroupAfterMaxGap);
        } else {
            let headerFound = -1, footerFound = -1;
            for (let i = 1; i < groups.length; i++) {
                if (headerFound < 0 && groups[i].top - groups[i - 1].bottom >= 200) {
                    headerFound = i - 1;
                    break;
                }
            }
            for (let i = groups.length - 2; i >= 0; i--) {
                if (footerFound < 0 && groups[i + 1].top - groups[i].bottom >= 200) {
                    footerFound = i + 1;
                    break;
                }
            }
            if (headerFound >= 0 && footerFound > 0) {
                set(headerFound, footerFound);
            } else {
                set(indexOfNextGroupAfterMaxGap - 1, indexOfNextGroupAfterMaxGap);
            }
        }
        return { headerEndingAt, footerStartingAt, footerTemplateCmps, reasons, reasonsFooter };
    },
    setHeaderAndFooterConsideringAllPages = (data, pageIds) => {
        let minHeaderHeight = pageIds.reduce((acc, id) => Math.max(acc, data[id].headerEndingAt), 0),
            commonFooterCmps = data[pageIds[0]].footerTemplateCmps,
            footerStartingAtPerPage = {},
            footerHeight = 0;
        pageIds.forEach(pageId => {
            const { headerEndingAt, footerTemplateCmps, nonGhostCmps } = data[pageId];
            if (nonGhostCmps.length && pageIds.length > 1) {
                if (minHeaderHeight < 0) {
                    minHeaderHeight = headerEndingAt;
                    commonFooterCmps = footerTemplateCmps;
                    return;
                }
                minHeaderHeight = Math.min(headerEndingAt, minHeaderHeight);
                const footerCmpsMap = footerTemplateCmps.reduce((acc, cmp) => {
                    acc[cmp.id] = cmp;
                    return acc;
                }, {});
                commonFooterCmps = commonFooterCmps.filter(cmp => footerCmpsMap[cmp.id]);
            }
        });

        let footerTopCmpId = commonFooterCmps.length && commonFooterCmps[0].id;
        pageIds.forEach(pageId => {
            const { headerEndingAt, cmpsMap, pageHeight, nonGhostCmps } = data[pageId];
            if (footerTopCmpId) {
                let minTop = pageHeight;
                commonFooterCmps.forEach(c => {
                    minTop = Math.min(cmpsMap[c.id].top, minTop);
                });
                footerStartingAtPerPage[pageId] = minTop;
                footerHeight = Math.max(pageHeight - footerStartingAtPerPage[pageId], footerHeight);
            } else if (!nonGhostCmps.length || pageHeight <= 0) {
                footerStartingAtPerPage[pageId] = minHeaderHeight + 400;
            } else if (pageHeight === headerEndingAt) {
                footerStartingAtPerPage[pageId] = headerEndingAt + 400;
            } else if (nonGhostCmps.some(c => isStripKind(c.kind) && getCmpBottom(c) === pageHeight)) {
                footerStartingAtPerPage[pageId] = pageHeight;
            } else {
                footerStartingAtPerPage[pageId] = pageHeight + 100;
            }
        });
        return { minHeaderHeight, footerHeight, footerStartingAtPerPage, commonFooterCmps };
    },
    // getNonGhostCmpsRemovingBigCmps = (allNonGhostCmps, pageHeight) => (pageHeight >= 500 ?
    //     allNonGhostCmps.filter(c => !isContainerKind(c.kind) || c.height <= pageHeight * 0.95) :
    //     allNonGhostCmps),
    getDynamicHeightTemplateCmpsIdsMap = (pagesData, pageIds) => {
        let tmpCmpsHeightsMap = {},
            dynamicHeightTmpCmpsIdsMap = {};
        pageIds.forEach(pageId => {
            const tmpCmps = getTmpCmps(getNonGhostCmps(pagesData[pageId]));
            tmpCmps.forEach(({ id, height }) => {
                if (!tmpCmpsHeightsMap.hasOwnProperty(id)) {
                    tmpCmpsHeightsMap[id] = height;
                } else if (tmpCmpsHeightsMap[id] !== height) {
                    tmpCmpsHeightsMap[id] = Math.min(tmpCmpsHeightsMap[id], height);
                    dynamicHeightTmpCmpsIdsMap[id] = tmpCmpsHeightsMap[id];
                }
            });
        });
        return dynamicHeightTmpCmpsIdsMap;
    },
    getSectionHeight = (s) => (s.id ? s.height : (s.bottom - s.top)),
    handleSmallPageSections = (sections) => {
        let newSections = [], lastSection = null;
        const setNewSections = () => {
            sections.forEach((s, i) => {
                if (i > 1) {
                    const prevSection = sections[i - 1],
                        prevSectionHeight = getSectionHeight(prevSection);
                    if (prevSectionHeight < minPageSectionHeight) {
                        if (s.inTemplate) {
                            if (!sections[i - 2].inTemplate) {
                                newSections.pop();
                                lastSection = newSections.pop();
                                newSections.push({
                                    top: lastSection.top,
                                    bottom: s.top
                                });
                            }
                            newSections.push(s);
                        } else {
                            lastSection = newSections.pop();
                            newSections.push({
                                top: lastSection.top,
                                bottom: s.id ? (s.top + s.height) : s.bottom
                            });
                        }
                    } else {
                        newSections.push(s);
                    }
                } else {
                    newSections.push(s);
                }
            });
        };

        // for (let i = 0; i < 4; i++) {
        setNewSections();
        // if (newSections.length < 4 || !newSections.some(s => !s.inTemplate && getSectionHeight(s) < minPageSectionHeight)) {
        //     break;
        // }
        // }

        return newSections;
    },
    allItemsInArrayEqual = arr => arr.every(v => v === arr[0]),
    arrayEquals = (a, b) => (
        a.length === b.length &&
        a.every((val, index) => val === b[index])
    ),
    findTopRelIn = (id, componentsMap, firstLevel) => {
        if (componentsMap[id] && componentsMap[id].relIn) {
            return findTopRelIn(componentsMap[id].relIn.id, componentsMap);
        }
        return firstLevel ? null : id;
    },
    isPageCmpOverlappingWithHeader = (componentsMap, extendBelowHeader, oldCmpsMap, oldTemplateCmpIdToNewPageCmpId) => {
        const cmpsMap = getCmps(componentsMap);
        const headerSection = cmpsMap.find(cmp => (isSectionKind(cmp.kind) && !cmp.top));
        if (!headerSection) {
            return false;
        }
        const headerEndingAt = headerSection.height;
        return cmpsMap.some(cmp => {
            if (cmp.inTemplate) { return false; }
            if (cmp.top >= headerEndingAt || (cmp.top + cmp.height) <= headerEndingAt) {
                return false;
            }
            if (extendBelowHeader && extendBelowHeader.id === cmp.id) {
                return false;
            }
            if (oldCmpsMap) {
                const oldCmp = oldCmpsMap[cmp.id] ||
                    oldCmpsMap[oldTemplateCmpIdToNewPageCmpId && oldTemplateCmpIdToNewPageCmpId[cmp.id]];
                if (!oldCmp || !oldCmp.relIn || !oldCmp.relIn.id) {
                    return true;
                }
                const oldTopRelIn = findTopRelIn(oldCmp.relIn.id, oldCmpsMap);
                if (oldCmpsMap[oldTopRelIn] && isContainerKind(oldCmpsMap[oldTopRelIn].kind)) {
                    return false;
                }
                return findTopRelIn(oldCmp.relIn.id, componentsMap) !== headerSection.id;
            }
            return true;
        });
    }, getIfMobileUserDataModified = (allPagesOfThisTemplate, outputDataPerPageId, pagesData) => {
        let mobileUserDataModified = false;
        if (!allPagesOfThisTemplate || !allPagesOfThisTemplate.length) {
            return false;
        }
        for (let i = 0; i < allPagesOfThisTemplate.length; i++) {
            const pageData = allPagesOfThisTemplate[i],
                pageId = pageData.id,
                componentsMap = outputDataPerPageId[pageId].newCmpsMap,
                oldTemplateCmpIdToNewPageCmpId = outputDataPerPageId[pageId].oldTemplateCmpIdToNewPageCmpId,
                oldCmpsMap = pagesData[pageId].cmpsMap,
                extendBelowHeader = outputDataPerPageId[pageId].extendBelowHeader;
            if (isPageCmpOverlappingWithHeader(componentsMap, extendBelowHeader, oldCmpsMap, oldTemplateCmpIdToNewPageCmpId)) {
                mobileUserDataModified = true;
                outputDataPerPageId[pageId].reasonsMobileFailure.push(REASONS.mobile_pageCmpOverlappingWithHeader);
            }
            const relations = pageData.mobileData && pageData.mobileData.relations;
            if (relations && relations.length) {
                for (let j = 0; j < relations.length; j++) {
                    const { id, bottom, top } = relations[j];
                    const mainCmp = componentsMap[id];
                    if (isSectionCmp(mainCmp) &&
                        (findTopRelIn(top, componentsMap) !== findTopRelIn(bottom, componentsMap))) {
                        continue;
                    }
                    if ((!bottom || (top === 'boxTop' && !componentsMap[bottom]))
                        && mainCmp && !mainCmp.inTemplate && !(oldCmpsMap[id] && oldCmpsMap[id].relIn)) {
                        mobileUserDataModified = true;
                        outputDataPerPageId[pageId].reasonsMobileFailure.push(REASONS.mobile_mobileSequenceIsChanged);
                        continue;
                    }
                    if (top === 'boxTop' && !(oldCmpsMap[id] && oldCmpsMap[id].relIn)) {
                        const newMainRelIn = componentsMap[id] && componentsMap[id].relIn && componentsMap[id].relIn.id,
                            newMainRelInCmp = componentsMap[newMainRelIn];
                        if (newMainRelInCmp && !newMainRelInCmp.inTemplate && isSectionCmp(newMainRelInCmp)) {
                            mobileUserDataModified = true;
                            outputDataPerPageId[pageId].reasonsMobileFailure.push(REASONS.mobile_mobileSequenceIsChanged);
                            continue;
                        }
                    }
                    let idsToCheck = [top, id, bottom];
                    idsToCheck = idsToCheck.filter(cmpId => !!componentsMap[cmpId]);
                    const topRelIns = idsToCheck.map(cmpId => findTopRelIn(cmpId, componentsMap))
                        .filter(cmpId => isSectionKind(componentsMap[cmpId].kind));
                    if (allItemsInArrayEqual(topRelIns)) {
                        continue;
                    }
                    const sortedTopRelIns = topRelIns.map(id => componentsMap[id])
                        .sort(sectionsTopSorter)
                        .map(cmp => cmp.id);
                    if (!arrayEquals(topRelIns, sortedTopRelIns)) {
                        mobileUserDataModified = true;
                        outputDataPerPageId[pageId].reasonsMobileFailure.push(REASONS.mobile_mobileSequenceIsChanged);
                    }
                }
            }
        }
        return mobileUserDataModified;
    },
    divideInToSections = (components, headerEndingAt, footerHeight, footerStartingAt) => {
        let sections = [],
            prevSectionBottom = headerEndingAt,
            newSectionPositions = [],
            extendBelowHeader = {},
            newBottom = null;
        const strips = getStrips(components),
            webShops = getWebShops(components),
            groupsOfStripsAndWebShops = divideInToGroups([...strips, ...webShops], false, true),
            groupsOfStrips = divideInToGroups(strips, false, true),
            { headerStrip, sharedBgStrip } = getHeaderStripAndSharedBgStrip(groupsOfStrips, headerEndingAt),
            footerStrip = getFooterStrip(groupsOfStrips, footerStartingAt, footerHeight);
        if (headerStrip) {
            sections.push(headerStrip);
        } else {
            newSectionPositions.push({ top: 0, bottom: headerEndingAt, inTemplate: true });
        }
        groupsOfStripsAndWebShops.forEach(({ top, bottom, cmps }) => {
            if (bottom < headerEndingAt) { return; }
            if (sharedBgStrip && sharedBgStrip.id === cmps[0].id) {
                extendBelowHeader = {
                    id: cmps[0].id,
                    diff: headerEndingAt - cmps[0].top
                };
                sections.push(cmps[0]);
                prevSectionBottom = bottom;
                return;
            }

            if (top >= footerStartingAt) { return; }
            if (top < prevSectionBottom && bottom > prevSectionBottom) {
                newBottom = (bottom < footerStartingAt) ? bottom : footerStartingAt;
                newSectionPositions.push({
                    top: prevSectionBottom,
                    bottom: newBottom
                });
                prevSectionBottom = newBottom;
                return;
            }
            if (top > prevSectionBottom) {
                newSectionPositions.push({
                    top: prevSectionBottom,
                    bottom: top
                });
            }
            if (top >= prevSectionBottom) {
                newBottom = (bottom > footerStartingAt) ? footerStartingAt : bottom;
                if (cmps[0].top === top && getCmpBottom(cmps[0]) === newBottom) {
                    if (isWebShopKind(cmps[0].kind)) {
                        newSectionPositions.push({ top, bottom: newBottom });
                    } else {
                        sections.push(cmps[0]);
                    }
                } else {
                    newSectionPositions.push({ top, bottom: newBottom });
                }
                prevSectionBottom = newBottom;
            }
        });
        if (prevSectionBottom < footerStartingAt) {
            newSectionPositions.push({ top: prevSectionBottom, bottom: footerStartingAt });
        }
        if (footerStrip) {
            sections.push(footerStrip);
        } else {
            newSectionPositions.push({ top: footerStartingAt, bottom: footerStartingAt + footerHeight, inTemplate: true });
        }
        const mergedSections = handleSmallPageSections(mergeSectionsIfComponentsOverlaps(
            components, sections, newSectionPositions, extendBelowHeader
        ));

        return {
            newSections: mergedSections.filter(s => !s.id),
            existingSections: mergedSections.filter(s => s.id),
            extendBelowHeader
        };
    },
    getTextProps = (pages, template) => {
        // return { pages, template, textProps: {} };
        let result = { pages: [], template: null, textProps: { [PAGE_KEY]: {}, [TEMPLATE_KEY]: {} } };
        const processItem = (item, pageTemplateKey, pageTemplateId) => {
            if (item.type === "web.data.components.Text") {
                result.textProps[pageTemplateKey][pageTemplateId]
                    = result.textProps[pageTemplateKey][pageTemplateId] || {};
                result.textProps[pageTemplateKey][pageTemplateId][item.id] = {
                    styles: item.styles,
                    content: item.content,
                    text: item.text,
                    links: item.links
                };
                // let content = '';
                // if (typeof item.content === 'string') {
                //     const divsWithWrappedItems =
                //         item.content.match(/<div(?:[^>]+data-wrap-id=\"(.*?)\"[^>]*)?>(.*?)<\/div>/g) || [];
                //     content = '<div>' + divsWithWrappedItems.join('') + '</div>';
                // }
                return { ...item, styles: [], content: '', text: '', links: [] };
            }
            return item;
        };
        result.pages = pages.map(page => ({
            ...page,
            items: (page.items || []).map((item) => processItem(item, PAGE_KEY, page.id))
        }));
        result.template = {
            ...template,
            items: (template.items || []).map((item) => processItem(item, TEMPLATE_KEY, template.id))
        };
        return result;
    },
    replacePageCmpIdWithTmpCmpId = (content, oldTemplateCmpIdToNewPageCmpId, tmpIds) => {
        if (typeof content !== 'string') {
            return content;
        }
        let result = content;
        tmpIds.forEach(tmpId => {
            result = result.replace(tmpId, oldTemplateCmpIdToNewPageCmpId[tmpId]);
        });
        return result;
    },
    setTextProps = (pageOrTemplate, textPropsInp, oldTemplateCmpIdToNewPageCmpId,
        pageTemplateKey, pageId, templateId) => {
        const tmpIds = Object.keys(oldTemplateCmpIdToNewPageCmpId),
            pageTemplateId = pageTemplateKey === PAGE_KEY ? pageId : templateId;
        let textProps = { ...textPropsInp };
        if (pageTemplateKey === PAGE_KEY && tmpIds.length) {
            tmpIds.forEach(tmpCmpId => {
                const textProp = textPropsInp[TEMPLATE_KEY] && textPropsInp[TEMPLATE_KEY][templateId]
                    && textPropsInp[TEMPLATE_KEY][templateId][tmpCmpId];
                if (textProp && oldTemplateCmpIdToNewPageCmpId[tmpCmpId]) {
                    textProps[pageTemplateKey] = textProps[pageTemplateKey] || {};
                    textProps[pageTemplateKey][pageTemplateId] = textProps[pageTemplateKey][pageTemplateId] || {};
                    textProps[pageTemplateKey][pageTemplateId][oldTemplateCmpIdToNewPageCmpId[tmpCmpId]]
                        = copyObjectAndReplaceWithNewUUIDs(
                            textProp,
                            {},
                            false,
                            {},
                            true
                        ).copiedObject;
                }
            });
        }
        return {
            ...pageOrTemplate,
            items: pageOrTemplate.items.map(item => {
                const textProp = textProps[pageTemplateKey] && textProps[pageTemplateKey][pageTemplateId]
                    && textProps[pageTemplateKey][pageTemplateId][item.id];
                return (
                    textProp ?
                        {
                            ...item,
                            ...textProp,
                            links: copyObjectAndReplaceWithNewUUIDs(
                                { links: textProp.links },
                                oldTemplateCmpIdToNewPageCmpId,
                                false,
                                {},
                                true
                            ).copiedObject.links,
                            content: replacePageCmpIdWithTmpCmpId(
                                textProp.content,
                                oldTemplateCmpIdToNewPageCmpId,
                                tmpIds
                            )
                        } : item);
            })
        };
    },
    stripType = "web.data.components.Block",
    convertSectionToStrip = (data) => {
        if (data && data.items && data.items.length && data.items.some(c => c.type === sectionKind)) {
            return {
                ...data,
                items: data.items.map(c => {
                    if (c.type === sectionKind) {
                        return {
                            ...c,
                            type: stripType
                        };
                    }
                    return c;
                })
            };
        }
        return data;
    },
    reduceHeaderEndingAtIfTmpCmpsBecamePageCmps = (cmps, oldHeaderEndingAt) => {
        let maxBottom = 0;
        cmps.forEach(c => {
            if (c.inTemplate && getCmpBottom(c) <= oldHeaderEndingAt && !isVerticalMenu(c)) {
                maxBottom = Math.max(getCmpBottom(c), maxBottom);
            }
        });
        return maxBottom;
    };
