import React from 'react';
import cx from "classnames";
import { Flex } from "../../../../../view/reflexbox/index";
import Msg from "../../../../../view/intl/Msg";
import Page from "../../../../PropertiesPanel/view/PropertiesPage";
import injectIntl, { Intl } from '../../../../../view/intl/injectIntl';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import HorizontalSpacer from '../../../../../view/common/HorizontalSpacer';
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';
import InputField from '../../../../../view/common/Input/InputField';
import * as pagesIds from './ids';
import pagesTitles from './titles';
import type { PropertiesPanelStyleViewProps } from '../../flowTypes';
import Label from '../../../../../view/common/Label';
import styles from "../webshopPropertiesPanel.css";
import PropertyGlobalStyleSelector from '../../../../../view/common/PropertyGlobalstyleSelector/index';
import buttonKind from '../../../Button/globalStyle/kind';
import * as actionTypes from '../../actionTypes';
import { ViewDetailsButtonPostions } from '../../constants';
import { getButtonStyleGlobalPropertiesSelector } from '../../utils';
import CheckBox from '../../../../../view/common/CheckBox/CheckBoxWithLabel';
import ICONS from "../../../../../view/Icons/index";

const HorizontalBar = () => {
    return <div className={styles.horizontalBar} />;
};

type ButtonPositionProps = {
    dispatch: Function,
    intl: Intl,
    viewDetailsButtonPosition: string,
};

const ButtonPositionBelow = ({ intl, dispatch, viewDetailsButtonPosition }: ButtonPositionProps) => {
    return (
        <div
            className={
                cx(
                    styles.btnPositionBox,
                    styles.btnPositionBelow,
                    { [styles.selected]: viewDetailsButtonPosition === ViewDetailsButtonPostions.BELOW.value }
                )
            }
            data-title-position="top"
            data-title={intl.msgJoint('msg: component.buybutton.ButtonBelowTooltip {Button is below product}')}
            onClick={() => {
                dispatch({
                    type: actionTypes.FEATURED_PRODUCTS_VIEW_DETAILS_UPDATE_BUTTON_POSITION,
                    payload: ViewDetailsButtonPostions.BELOW
                });
            }}
        />
    );
};

const ButtonPositionRight = ({ intl, dispatch, viewDetailsButtonPosition }: ButtonPositionProps) => {
    return (
        <div
            className={
                cx(
                    styles.btnPositionBox,
                    styles.btnPositionRight,
                    { [styles.selected]: viewDetailsButtonPosition === ViewDetailsButtonPostions.RIGHT.value }
                )
            }
            data-title-position="top"
            data-title={intl.msgJoint('msg: component.buybutton.ButtonRightTooltip {Button is at the right of the product}')}
            onClick={() => {
                dispatch({
                    type: actionTypes.FEATURED_PRODUCTS_VIEW_DETAILS_UPDATE_BUTTON_POSITION,
                    payload: ViewDetailsButtonPostions.RIGHT
                });
            }}
        />
    );
};

const ButtonPositionLeft = ({ intl, dispatch, viewDetailsButtonPosition }: ButtonPositionProps) => {
    return (
        <div
            className={
                cx(
                    styles.btnPositionBox,
                    styles.btnPositionLeft,
                    { [styles.selected]: viewDetailsButtonPosition === ViewDetailsButtonPostions.LEFT.value }
                )
            }
            data-title-position="top"
            data-title={intl.msgJoint('msg: component.buybutton.ButtonLeftTooltip {Button is at the left of the product}')}
            onClick={() => {
                dispatch({
                    type: actionTypes.FEATURED_PRODUCTS_VIEW_DETAILS_UPDATE_BUTTON_POSITION,
                    payload: ViewDetailsButtonPostions.LEFT
                });
            }}
        />
    );
};

const id: any = pagesIds.BUTTON,
    title = pagesTitles[pagesIds.BUTTON],
    view = injectIntl((
        {
            dispatch,
            intl,
            selectedComponentProps,
            globalStyles,
            themeSettingsData: {
                autoColorMode
            },
            stylesheetsIdToNameMap,
        }: PropertiesPanelStyleViewProps
    ) => {
        const { component, selectedParentTheme } = selectedComponentProps;
        const {
            isSingleProduct,
            viewDetailsButtonPosition,
        } = component;

        const {
            buttonStyleOptRenderer,
            buttonStyleOtions,
            buttonStyleColorThemeOnChangeAction,
            buttonStyleOnChangeAction,
            buttonStyleCurrentStylesheetId,
            buyNowButtonStyleOnChangeAction,
            buyNowButtonStyleColorThemeOnChangeAction
        } = getButtonStyleGlobalPropertiesSelector(
            dispatch,
            intl,
            component,
            selectedParentTheme,
            globalStyles,
            autoColorMode,
            stylesheetsIdToNameMap
        );

        const { viewDetailsButtonThemeSelected, buyNowButtonThemeSelected } = component;
        let { viewDetailsButtonId, buyNowButtonId } = component;

        if (!autoColorMode && !viewDetailsButtonId) {
            viewDetailsButtonId = `${buttonStyleOtions[0].value}`;
        }

        if (!autoColorMode && !buyNowButtonId) {
            buyNowButtonId = `${buttonStyleOtions[0].value}`;
        }

        return (
            <Page key="button">
                <Flex column>
                    <div className={styles.buyBtnHelpText}>
                        <Msg
                            k="component.buybutton.HelpText"
                        >This button directs the user to the details page of the product.</Msg>
                    </div>
                    <CheckBox
                        label="msg: component.buybutton.enabledViewDetailsButton {Enable View details button}"
                        onClick={() => {
                            dispatch({
                                type: actionTypes.FEATURED_PRODUCTS_VIEW_DETAILS_SHOW_BUTTON_EXISTS,
                                payload: !component.viewDetailsShowButton
                            });
                        }}
                        isChecked={component.viewDetailsShowButton}
                        isSliderStyle
                    />
                </Flex>
                <VerticalSpacer />
                {component.viewDetailsShowButton && <React.Fragment>
                    <Flex align="center" justify="space-between">
                        <PropertyGlobalStyleSelector
                            label="msg: component.webshop.ViewDetailsButtonStyle {View details button style}"
                            globalStyleId={(autoColorMode) ? viewDetailsButtonThemeSelected : viewDetailsButtonId}
                            onChangeAction={buttonStyleOnChangeAction}
                            options={buttonStyleOtions}
                            optionRenderer={buttonStyleOptRenderer}
                            kind={buttonKind}
                            dispatch={dispatch}
                            showEditLink
                            colorThemeOnChangeAction={buttonStyleColorThemeOnChangeAction}
                            autoColorMode={autoColorMode}
                            currentStylesheetId={buttonStyleCurrentStylesheetId}
                            className={styles.btnStyleSelector}
                        />
                    </Flex>
                    <VerticalSpacer />
                    <Flex column>
                        <label className={styles.buyBtnTxtInputLabel} htmlFor="buyBtnTxt">
                            <Label>
                                {'msg: component.webshop.ViewDetailsButtonText {View details button text}'}
                            </Label>
                        </label>
                        <InputField
                            value={component.viewDetailsButtonText}
                            onChange={
                                text =>
                                    dispatch({
                                        type: actionTypes.FEATURED_PRODUCTS_VIEW_DETAILS_UPDATE_BUTTON_TEXT,
                                        payload: text,
                                        amendToSelf: true
                                    })
                            }
                            placeholder="msg: webshopInserter.singleProduct.ViewDetails {View details}"
                            className={styles.buyBtnTxtInputField}
                            id="buyBtnTxt"
                        />
                    </Flex>
                    <VerticalSpacer />
                </React.Fragment>}
                <HorizontalBar />
                <VerticalSpacer />
                { /* @ts-ignore */ }
                <Flex row justify="space-between">
                    <CheckBox
                        label="msg: webshop.enableBuyNowButton {Enable Buy now button}"
                        onClick={() => dispatch({ type: actionTypes.FEATURED_PRODUCTS_ENABLE_BUY_NOW_CHANGED })}
                        isChecked={component.enableBuyNowButton}
                        isSliderStyle
                    />
                    <ICONS.HELPICON
                        title="msg: component.buyNowbutton.HelpText
                        {Takes the customer directly to your checkout, where they can purchase the product.}"
                    />
                </Flex>
                {component.enableBuyNowButton && <React.Fragment>
                    <VerticalSpacer />
                    <Flex align="center" justify="space-between">
                        <PropertyGlobalStyleSelector
                            label="msg: common.buyNowButtonStyle {Buy now button style}"
                            globalStyleId={(autoColorMode) ? buyNowButtonThemeSelected : buyNowButtonId}
                            onChangeAction={buyNowButtonStyleOnChangeAction}
                            options={buttonStyleOtions}
                            optionRenderer={buttonStyleOptRenderer}
                            kind={buttonKind}
                            dispatch={dispatch}
                            showEditLink
                            colorThemeOnChangeAction={buyNowButtonStyleColorThemeOnChangeAction}
                            autoColorMode={autoColorMode}
                            currentStylesheetId={buttonStyleCurrentStylesheetId}
                            className={styles.btnStyleSelector}
                        />
                    </Flex>
                    <VerticalSpacer />
                    <Flex column>
                        <label className={styles.buyBtnTxtInputLabel} htmlFor="buyNowBtnTxt">
                            <Label>
                                {'msg: webshop.buyNowButtonText {Buy now button text}'}
                            </Label>
                        </label>
                        <InputField
                            value={component.buyNowButtonText}
                            onChange={
                                text =>
                                    dispatch({
                                        type: actionTypes.FEATURED_PRODUCTS_BUY_NOW_UPDATE_BUTTON_TEXT,
                                        payload: text,
                                        amendToSelf: true
                                    })
                            }
                            placeholder="msg: webshop.buyNow {Buy now}"
                            className={styles.buyBtnTxtInputField}
                            id="buyNowBtnTxt"
                        />
                    </Flex>
                </React.Fragment>}
                <VerticalSpacer />
                {isSingleProduct && (component.enableBuyNowButton || component.viewDetailsShowButton) &&
                    <React.Fragment>
                        <HorizontalBar />
                        <VerticalSpacer />
                        <PropertyContainer label="msg: component.buybutton.ButtonPosition {Button position}">
                            <Flex>
                                <ButtonPositionRight
                                    dispatch={dispatch}
                                    intl={intl}
                                    viewDetailsButtonPosition={viewDetailsButtonPosition}
                                />
                                <HorizontalSpacer x={20} />
                                <ButtonPositionBelow
                                    dispatch={dispatch}
                                    intl={intl}
                                    viewDetailsButtonPosition={viewDetailsButtonPosition}
                                />
                                <HorizontalSpacer x={20} />
                                <ButtonPositionLeft
                                    dispatch={dispatch}
                                    intl={intl}
                                    viewDetailsButtonPosition={viewDetailsButtonPosition}
                                />
                            </Flex>
                        </PropertyContainer>
                    </React.Fragment>}
            </Page>
        );
    });

export { id, title, view };
