import type { AvailableUpgrade } from "../../../../../../../wbtgen/src/redux/modules/flowTypes";
import {
    EcommerceOffersList,
    PremiumOffersList,
    StarterOffersList,
    OfferListLocalizationKeyMap,
    BasicOffersList,
    StarterKeyFeatures,
    BasicKeyFeatures,
    EcommerceKeyFeatures,
    PremiumKeyFeatures
} from "../../../../../../../wbtgen/src/components/TopBar/view/Upgrade/UpgradePlansDialog/constants";
import { SubscriptionType } from "../../../../../../../server/shared/subscriptionStatus/constants";

export const filterAvailableUpgrades = (availableUpgrades: Array<AvailableUpgrade>, allowedUpgradeTypes: Array<string> = []) => {
    if (Array.isArray(allowedUpgradeTypes) && allowedUpgradeTypes.length) {
        return availableUpgrades.filter(
            upgrade => allowedUpgradeTypes.includes(upgrade.addonProductToUpgrade)
        );
    }

    return availableUpgrades;
};

export const getOffersList = (upgradeType) => {
    const offersList = {
        [SubscriptionType.BASIC]: BasicOffersList,
        [SubscriptionType.NORMAL]: StarterOffersList,
        [SubscriptionType.PREMIUM]: PremiumOffersList,
        [SubscriptionType.ECOMMERCE]: EcommerceOffersList
    };
    return offersList[upgradeType];
};

export const getKeyFeatures = (upgradeType) => {
    const offersList = {
        [SubscriptionType.BASIC]: BasicKeyFeatures,
        [SubscriptionType.NORMAL]: StarterKeyFeatures,
        [SubscriptionType.PREMIUM]: PremiumKeyFeatures,
        [SubscriptionType.ECOMMERCE]: EcommerceKeyFeatures
    };
    return offersList[upgradeType];
};

export const getOfferMsgKey = (offerType) => {
    return OfferListLocalizationKeyMap[offerType];
};
