import * as R from 'ramda';
import makeEpic from '../../../epics/makeEpic';
import * as ActionTypes from '../actionTypes';
import openImageEditorDialog from "../../../view/common/dialogs/ImageEditorDialog/openImageEditorDialog";
import { receiveOnly, when } from "../../../epics/makeCondition";
import appConfigValueActionType from "../../App/epics/appConfig/valueActionType";
import AppConfig from "../../../utils/AppConfig";
import { closeDialogByIdAction } from "../../App/actionCreators/index";
import { dialogId as ImageDialogId } from "../../../view/common/dialogs/ImageEditorDialog/index";
import { addIntlMessage } from "../../Toaster/actionCreators";
import MessageTypes from "../../Toaster/MessageTypes";
import { updateImageEditorMessage } from "../actionCreators";
import { MessageCodes } from "../Messages";
import pageDataSetValueActionType from "../../App/epics/pageDataset/valueActionType";
import { valueActionType as siteDataValueActionType } from "../../App/epics/siteData/valueActionType";
import googleFontMetrics from "../../../../googleFontsMetrics.json";
import getPageGoogleFonts from "../../Fonts/getPageGoogleFonts";
import { commonFontsMetrics } from "../../presentational/FontFamily/fontFamilies";

const IMAGE_EDIT_VALUE_ACTION_TYPE = "IMAGE_EDIT_VALUE_ACTION_TYPE";
const photoEditorFonts = [
    'aleo',
    'amatic sc',
    'bernier',
    'cheque',
    'open sans',
    'gagalin',
    'hagin',
    'intro',
    'lobster',
    'nexa',
    'ostrich',
    'panton',
    'perfograma',
    'trashhand'
];
export default makeEpic({
    defaultState: null,
    defaultScope: {
        saving: false
    },
    valueActionType: IMAGE_EDIT_VALUE_ACTION_TYPE,
    updaters: [
        {
            conditions: [
                receiveOnly(appConfigValueActionType),
                receiveOnly(pageDataSetValueActionType),
                receiveOnly(siteDataValueActionType),
                ActionTypes.IMAGE_EDITOR_EDIT_IMAGE_CLICKED
            ],
            reducer: ({ values: [
                appConfig,
                pageDataSet,
                siteData,
                { replaceWithEditedImageAction, asset, extraPayload }
            ], state, scope }) => {
                const
                    license = AppConfig(appConfig).getOptional('oneWeb.photoEditor.license', ''),
                    googleFonts = R.uniq([...siteData.fonts, ...getPageGoogleFonts(pageDataSet)])
                        .map((siteDataFont) => {
                            return googleFontMetrics.filter((googleFont) => {
                                return siteDataFont.toLowerCase().indexOf(googleFont.fontFamily.toLowerCase()) > -1;
                            }).pop();
                        }).filter((googleFont) => {
                            return googleFont && !photoEditorFonts.some((photoEditorFont) => {
                                return photoEditorFont === googleFont.fontFamily.toLowerCase();
                            });
                        }).map((googleFont) => {
                            return {
                                ...googleFont,
                                variations: googleFont.variations.filter((variation) => {
                                    const identifier = variation.identifier.split("_") || [],
                                        identifierStyle = identifier.length === 2 ? identifier[1] : "";
                                    return identifierStyle === 'regular'
                                    || identifierStyle === 'italic'
                                    || identifierStyle === '700'
                                    || identifierStyle === '700italic';
                                })
                            };
                        }),
                    fonts = [
                        ...commonFontsMetrics,
                        ...googleFonts
                    ].sort((font1, font2) => {
                        const
                            fontFamily1 = font1.fontFamily.toLowerCase(),
                            fontFamily2 = font2.fontFamily.toLowerCase();
                        if (fontFamily1 < fontFamily2) {
                            return -1;
                        }
                        if (fontFamily1 > fontFamily2) {
                            return 1;
                        }
                        return 0;
                    });
                return ({
                    state,
                    scope,
                    actionToDispatch: openImageEditorDialog({
                        replaceWithEditedImageAction,
                        asset,
                        extraPayload,
                        imageEditorConfig: {
                            license
                        },
                        fonts
                    })
                });
            }
        },
        {
            conditions: [
                ActionTypes.IMAGE_EDITOR_SHOW_REPLACED_IMAGE_MSG
            ],
            reducer: ({ values: [newImageFileName], state, scope }) => {
                return {
                    state,
                    scope: {
                        ...scope,
                        saving: false
                    },
                    multipleActionsToDispatch: [
                        addIntlMessage(
                            MessageTypes.SUCCESS,
                            "imageEditor.addedImage",
                            "Added {0}",
                            [newImageFileName]
                        ),
                        { type: ActionTypes.IMAGE_EDITOR_CLOSE_EDITOR }
                    ]
                };
            }
        },
        {
            conditions: [
                ActionTypes.IMAGE_EDITOR_CLOSE_EDITOR
            ],
            reducer: ({ state, scope }) => {
                if (!scope.saving) {
                    return {
                        state,
                        scope: {
                            ...scope,
                            saving: false
                        },
                        actionToDispatch: closeDialogByIdAction(ImageDialogId)
                    };
                }
                return {
                    state,
                    scope
                };
            }
        },
        {
            conditions: [
                ActionTypes.IMAGE_EDITOR_SERIALIZED_SAVE_CLOSE_EDITOR
            ],
            reducer: ({ state, scope }) => {
                return {
                    state,
                    scope: {
                        ...scope,
                        saving: false
                    },
                    actionToDispatch: closeDialogByIdAction(ImageDialogId)
                };
            }
        },
        {
            conditions: [
                ActionTypes.IMAGE_EDITOR_SAVE_CLICKED
            ],
            reducer: ({ state, scope }) => {
                return {
                    state,
                    scope: {
                        ...scope,
                        saving: true
                    },
                    actionToDispatch: updateImageEditorMessage(MessageCodes.PROCESSING)
                };
            }
        },
        {
            conditions: [
                ActionTypes.IMAGE_EDITOR_SHOW_PROCESSING_NEW_IMAGE_ERROR
            ],
            reducer: ({ state, scope }) => {
                return {
                    state,
                    scope: {
                        ...scope,
                        saving: false
                    },
                    actionToDispatch: updateImageEditorMessage(MessageCodes.GENERAL_ERROR)
                };
            }
        },
        {
            conditions: [
                when(ActionTypes.IMAGE_EDITOR_MESSAGE, ({ payload: { code } }) => code === MessageCodes.GENERAL_ERROR)
            ],
            reducer: ({ state, scope }) => ({
                state,
                scope: {
                    ...scope,
                    saving: false
                },
                actionToDispatch: { type: ActionTypes.IMAGE_EDITOR_TRACK_ERROR }
            })
        }
    ]
});
