import cx from 'classnames';
import * as styles from './SortImageDialog.css';

export const
    width = 900,
    height = 668,
    inactiveNodeClassName = cx(styles.floatLeft, styles.sortableHelper, styles.inactiveNode),
    activeNodeClassName = cx(styles.floatLeft, styles.sortableHelper, styles.activeNode),
    pixelRatio = 1,
    thumbWidth = 152,
    thumbHeight = 152;
