import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import SectionLinkInfoDialog from "./view/SectionLinkInfoDialog";
import reducer from './reducer';
import siteDataEpic from '../../../../App/epics/siteData';
import makeDefaultState from "../../../sectionLinkForm/reducer/makeSectionLinkDefaultState";
import calcRenderProps from "./view/calcRenderProps";
import { sectionComponentsEpic } from '../../../sectionComponents/epic/sectionComponentsEpic';
import currentPageIdEpic from '../../../../App/epics/currentPageId';

export default getCenteredDialogConfig({
    width: 568,
    height: 615,
    component: SectionLinkInfoDialog,
    dependsOn: {
        site: siteDataEpic.reducer,
        sectionComponents: sectionComponentsEpic.reducer,
        currentPageId: currentPageIdEpic.reducer
    },
    makeDefaultState,
    reducer,
    calcRenderProps
});
