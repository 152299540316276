import {
    ONE_LANG_COOKIE_KEY,
    TOP_BAR_LANGUAGES_COOKIE_CALLER_ID
} from "../../../../constants/app";
import { receiveOnly, whenCookieKey } from "../../../../epics/makeCondition";
import makeEpic from '../../../../epics/makeEpic';
import AppConfig from "../../../../utils/AppConfig";
import { windowLoadChat } from "../../actionCreators/loadChat";
import loadOwnerData from '../../actionCreators/loadOwnerData';
import {
    ADMIN_ID_AVAILABLE,
    ADMIN_ID_FETCH_FAILURE,
    OWNER_INFO_FAILURE,
    OWNER_INFO_RETRY_REQUEST,
    OWNER_INFO_SUCCESS
} from "../../actionTypes";
import { ANALYTICS_GOAL_WSB_TRIAL_CONVERTED_LOGIN } from "../analyticsDependencies/actions";
import { getAppConfig } from "../appConfig/appConfig";
import { AppConfigSelector } from "../appConfig/selectors";
import { AUTH_COOKIE_EXISTS } from "../auth/actionTypes";
import valueActionType from './valueActionType';

const appConfig = AppConfig(getAppConfig());

const epicConfig = {
    defaultState: {
        ownerData: {},
        ownerDataRetryCount: 0,
        ownerDataLoading: false,
        ownerDataFailed: false,
        adminIdFetchFailed: false,
    },
    valueActionType,
    updaters: [
        {
            conditions: [
                receiveOnly(AppConfigSelector('server.fetchOwnerInfo')),
                AUTH_COOKIE_EXISTS,
                OWNER_INFO_RETRY_REQUEST,
            ],
            reducer: ({ state, values: [fetchOwnerInfo] }) => {
                if (!fetchOwnerInfo) {
                    return { state };
                }
                return {
                    state: {
                        ...state,
                        ownerDataLoading: true,
                        ownerDataFailed: false,
                        adminIdFetchFailed: false,
                    },
                    actionToDispatch: loadOwnerData(),
                };
            }
        },
        {
            conditions: [
                OWNER_INFO_SUCCESS,
                whenCookieKey(TOP_BAR_LANGUAGES_COOKIE_CALLER_ID, ONE_LANG_COOKIE_KEY)
            ],
            reducer: ({ values: [ownerData, { value: current }], state }) => {
                let multipleActionsToDispatch: Action[] = [];
                if (appConfig.getOptional('oneWeb.zDesk.editor.enable')) {
                    multipleActionsToDispatch.push(
                        windowLoadChat({
                            ownerData,
                            language: current && current.toLowerCase()
                        })
                    );
                }
                if (ownerData?.websiteBuilderTrialId) {
                    multipleActionsToDispatch.push({
                        type: ANALYTICS_GOAL_WSB_TRIAL_CONVERTED_LOGIN
                    });
                }
                if (ownerData?.adminId) {
                    multipleActionsToDispatch.push({
                        type: ADMIN_ID_AVAILABLE,
                        payload: ownerData.adminId,
                    });
                } else {
                    multipleActionsToDispatch.push({
                        type: ADMIN_ID_FETCH_FAILURE,
                    });
                }
                return {
                    state: {
                        ...state,
                        ownerData,
                        ownerDataRetryCount: 0,
                        ownerDataLoading: false,
                        adminIdFetchFailed: !ownerData?.adminId,
                    },
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [
                OWNER_INFO_FAILURE,
            ],
            reducer: ({ state }) => {
                if (state.ownerDataRetryCount > 2) {
                    return {
                        state: {
                            ...state,
                            ownerDataFailed: true,
                            ownerDataLoading: false,
                            adminIdFetchFailed: true,
                        },
                        actionToDispatch: { type: ADMIN_ID_FETCH_FAILURE },
                    };
                }
                return {
                    state: {
                        ...state,
                        ownerDataRetryCount: state.ownerDataRetryCount + 1
                    },
                    actionToDispatch: { type: OWNER_INFO_RETRY_REQUEST },
                };
            }
        }
    ]
};

export default makeEpic(epicConfig);

