import { getWidth, getHeight } from "../bBox";
import type { BBox } from "../../redux/modules/children/workspace/flowTypes";

export default (handleWidth: number, handleHeight: number) => {
    const
        halfHandleWidth = Math.floor(handleWidth / 2),
        halfHandleHeight = Math.floor(handleHeight / 2);

    return {
        horizontalCenter: (componentBBox: BBox): number => {
            return componentBBox.left + (getWidth(componentBBox) / 2) - halfHandleWidth;
        },
        horizontalCenterAfter: (componentBBox: BBox): number => {
            return componentBBox.left + (getWidth(componentBBox) / 2) + halfHandleWidth;
        },
        left: (componentBBox: BBox): number => {
            return componentBBox.left - halfHandleWidth - 1;
        },
        leftAfter: (componentBBox: BBox): number => {
            return componentBBox.left + halfHandleWidth - 1;
        },
        right: (componentBBox: BBox): number => {
            return componentBBox.right - halfHandleWidth;
        },
        verticalCenter: (componentBBox: BBox): number => {
            return componentBBox.top + (getHeight(componentBBox) / 2) - halfHandleHeight;
        },
        top: (componentBBox: BBox) => {
            return componentBBox.top - halfHandleHeight - 1;
        },
        topBelow: (componentBBox: BBox) => {
            return componentBBox.top + halfHandleHeight - 1;
        },
        bottom: (componentBBox: BBox) => {
            return componentBBox.bottom - halfHandleHeight;
        }
    };
};
