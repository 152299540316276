import React from 'react';
import cx from 'classnames';
import * as hoverBoxPageIds from '../pageIds';
import pageTitles from '../pageTitles';
import type { HoverBoxProps } from "../flowTypes";
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import ToggleSelectView from "../../../../presentational/ToggleSelectView/index";
import { SET_HOVER_BOX_EFFECT } from "../../actionTypes";
import { injectIntl, Msg } from "../../../../../view/intl/index";
import { HoverEffect } from "../../constants";
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import PropertyContainer from "../../../../../view/common/PropertyContainer/index";
import styles from './hoverEffectPage.css';

const HoverEffectView = injectIntl(({ title, selected, className, intl }) => {
    return (
        <div className={cx({ [styles.selected]: selected })}>
            <div className={className} />
            <div className={styles.label}>{intl.msgJoint(title)}</div>
        </div>
    );
});

const
    id = hoverBoxPageIds.HOVER_EFFECT,
    title = pageTitles[id],
    view = (props: HoverBoxProps) => {
        const { selectedComponent: { hover }, dispatch } = props;
        const hoverEffectConfigs = [
            {
                id: HoverEffect.instant,
                data: {
                    title: 'msg: hoverbox.pp.instantHoverEffect {Instant}',
                    className: cx(styles.effect, styles.instant)
                }
            },
            {
                id: HoverEffect.fade,
                data: {
                    title: 'msg: hoverbox.pp.fadeHoverEffect {Fade}',
                    className: cx(styles.effect, styles.fade)
                }
            },
            {
                id: HoverEffect.dynamic,
                data: {
                    title: 'msg: hoverbox.pp.dynamicHoverEffect {Dynamic}',
                    className: cx(styles.effect, styles.dynamic)
                }
            },
        ];
        return (
            <Page>
                <VerticalSpacer />
                <PropertyContainer label="msg: hoverBox.pp.effect {Effects}">
                    <ToggleSelectView
                        className={styles.effectsContainer}
                        selectedId={hover.animation}
                        toggleItemTemplate={HoverEffectView}
                        configs={hoverEffectConfigs}
                        onToggle={(hoverEffect) => dispatch({ type: SET_HOVER_BOX_EFFECT, payload: hoverEffect.id })}
                    />
                </PropertyContainer>
                <div className={styles.tipContainer}>
                    <div className={styles.footerHintIcon} />
                    <div>
                        <div className={styles.tipLabel}>
                            <Msg k="tip">Tip:</Msg>
                        </div>
                        <div>
                            <Msg k="hoverEffects.tipMsg">
                                Preview or publish your site to see this in action.
                            </Msg>
                        </div>
                    </div>
                </div>
            </Page>
        );
    };

export default { id, title, view };
