import React from "react";
import pagesTitles from "./titles";
import * as pagesIds from "./ids";
import { INTL_DAYS_OF_THE_WEEK, INTL_GROUPING_OPTIONS } from "../../constants";
import Page from "../../../../PropertiesPanel/view/PropertiesPage";
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import PropertyContainer from "../../../../../view/common/PropertyContainer/index";
import Combobox from "../../../../../view/common/Combobox/index";
import { intlCreateOptions } from "../../../../../view/common/Combobox/utils";
import { getDaysOfTheWeek } from "../../utils";
import injectIntl from "../../../../../view/intl/injectIntl";
import {
    OPENING_HOURS_GROUPING_CHANGED,
    OPENING_HOURS_HOUR_FORMAT_CHANGED,
    OPENING_HOURS_WEEK_STARTS_FROM_CHANGED
} from "../../actionTypes";
import type { PPPageViewPropTypes } from "../../flowTypes";

const id = pagesIds.FORMAT,
    title = pagesTitles[pagesIds.FORMAT],
    view = injectIntl(({ intl, dispatch, selectedComponent }: PPPageViewPropTypes) => {
        const daysOfTheWeek = getDaysOfTheWeek(selectedComponent.startDayOfTheWeek);
        return (
            <Page>
                <VerticalSpacer />
                <PropertyContainer dataTestId="week-starts-on" label="msg: component.openingHours.weekStartsOn {Week starts on}">
                    <Combobox
                        searchable={false}
                        value={selectedComponent.startDayOfTheWeek}
                        options={intlCreateOptions(
                            daysOfTheWeek.reduce((acc, d) => ({ ...acc, [d]: INTL_DAYS_OF_THE_WEEK[d] }), {}),
                            intl
                        )}
                        onChange={({ value }) =>
                            dispatch({
                                type: OPENING_HOURS_WEEK_STARTS_FROM_CHANGED,
                                payload: value
                            })}
                    />
                </PropertyContainer>
                <VerticalSpacer />
                <PropertyContainer dataTestId="hour-format" label="msg: component.openingHours.hourFormatLabel {Hour format}">
                    <Combobox
                        searchable={false}
                        value={selectedComponent.hourFormat}
                        options={intlCreateOptions(
                            {
                                HH: "msg: component.openingHours.24Hour {24 hour}",
                                hh: "msg: component.openingHours.AMPM {AM/PM}"
                            },
                            intl
                        )}
                        onChange={({ value }) =>
                            dispatch({
                                type: OPENING_HOURS_HOUR_FORMAT_CHANGED,
                                payload: value
                            })}
                    />
                </PropertyContainer>
                <VerticalSpacer />
                <PropertyContainer dataTestId="group-days" label="msg: component.openingHours.groupDays {Group days}">
                    <Combobox
                        searchable={false}
                        value={selectedComponent.groupDays}
                        options={intlCreateOptions(INTL_GROUPING_OPTIONS, intl)}
                        onChange={({ value }) =>
                            dispatch({
                                type: OPENING_HOURS_GROUPING_CHANGED,
                                payload: value
                            })}
                    />
                </PropertyContainer>
            </Page>
        );
    });
export { id, view, title };
