import * as colorMapper from '../Base/color';
import { overPath } from '../../../../src/utils/ramdaEx';

const
    colorMap = {
        to: overPath([['fontColor'], ['primaryColor'], ['fieldBgCoor'], ['fieldBorderColor']])(colorMapper.toHsl),
        back: overPath([['fontColor'], ['primaryColor'], ['fieldBgCoor'], ['fieldBorderColor']])(color => color)
    };

export const
    to = colorMap.to,
    back = colorMap.back;
