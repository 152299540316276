import React from 'react';
import { connect } from 'react-redux';

import { SettingsSectionWrapper } from '../../../../SettingsControls';

import { SSLSettingsSection } from "./SSLSettingsSection";
import { SSLPreviewSection } from "./SSLPreviewSection";

import imageStyles from "../../../../Images/imageStyle.css";

import { changePublishWithSSLAction } from "../actions";
import { publishConfigDataSelector } from "../../../../../common/selectors";
import { useIntl } from '../../../../../../../../wbtgen/src/view/intl';

import type { AppState } from '../../../../../common/types';
import type { SSLSettingsProps } from "../types";

export const SSLSettingsComponent = (props: SSLSettingsProps) => {
    const { dispatch, publishConfig } = props;
    const intl = useIntl();

    const clickHandler = (value) => {
        dispatch(changePublishWithSSLAction(value));
    };

    return (
        <SettingsSectionWrapper
            info={
                <SSLSettingsSection
                    value={publishConfig.publishWithSSL}
                    iconClassname={imageStyles.ssl}
                    label={intl.msgJoint('msg: securityTab.ssl.title {SSL/HTTPS}')}
                    description={intl.msgJoint('msg: securityTab.enableHttps.description {SSL allows your visitors to use your website and submit information securely via HTTPS.}')}
                    link={{
                        label: intl.msgJoint('msg: common.learnMore {Learn more}'),
                        url: intl.msgJoint('msg: securityTab.https.learnMore {https://help.one.com/hc/en-us/articles/360000715429-Always-use-HTTPS-on-your-Website-Builder-site}')
                    }}
                    form={{
                        label: intl.msgJoint("msg: securityTab.enableHttps {Use HTTPS on my website}")
                    }}
                    clickHandler={clickHandler}
                />
            }
            form={
                <SSLPreviewSection intl={intl} publishConfig={publishConfig} />
            }
        />
    );
};

const mapStateToProps = (state: AppState) => ({
    publishConfig: publishConfigDataSelector(state),
});

export const SSLSettings = connect(mapStateToProps)(SSLSettingsComponent);
