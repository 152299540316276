import React, { useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { InputField } from "../Common/InputField";
import { businessNameSelector } from "../../../Epic/selectors";
import { DYNAMIC_TEMPLATE_BUSINESS_NAME_CHANGED } from "../../../Epic/actionTypes";
import { STEP_NAMES, FIELD_NAMES, TEXT_CHARACTER_LIMIT_ON_STEP_FIELD } from "../../../constants";
import { DynamicTemplateContext } from "../../Context";
import stepStyles from "../steps.css";
import { getKeyForStep } from "../utils";

export const BusinessNameField = (props) => {
    const { showError, setShowError } = props;
    const dispatch = useDispatch();
    const businessName = useSelector(businessNameSelector);
    const counterLimit = TEXT_CHARACTER_LIMIT_ON_STEP_FIELD.businessName.max;
    const { concept: { name, type }, gmbKey } = useContext(DynamicTemplateContext);

    const [value, setValue] = useState(businessName);

    return (
        <div className={stepStyles.stepFieldContainer}>
            <InputField
                label="msg: onboarding.dynamic.step2.label {Name}"
                error={showError}
                errorMsg="msg: common.error.tooLong {The text you entered is too long.}"
                msgCounter={{
                    limit: counterLimit,
                    current: value.length
                }}
                value={value}
                onChange={(e) => {
                    const titleValue = e.target.value;

                    if (titleValue.length > counterLimit) {
                        setShowError(true);
                    } else {
                        setShowError(false);
                    }

                    dispatch({ type: DYNAMIC_TEMPLATE_BUSINESS_NAME_CHANGED, payload: titleValue });
                    setValue(titleValue);
                }}
                placeholder={getKeyForStep(
                    name,
                    type,
                    gmbKey,
                    STEP_NAMES.BUSINESS_NAME,
                    FIELD_NAMES.PLACEHOLDER
                )}
            />
        </div>
    );
};
