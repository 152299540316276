import * as R from 'ramda';
import { DOM, $ } from 'tinymce';
import renderWrapHtml from './renderWrapHtml';
import { getWrapperHtmlWithoutRelIn } from './getWrapperHtml';
import { WrapPositionMap } from "./wrapPosition";
import type { WrapPosition } from "./wrapPosition";
import type { AnyComponent } from "../../../../../components/oneweb/flowTypes";
import type { ComponentsMap } from "../../../../../redux/modules/children/workspace/flowTypes";
import type { TextComponent } from "../../../../../components/oneweb/Text/flowTypes";
import { makeMemoMax } from "../../../../../../utils/memo";
import { MaxComponentsOnPage } from "../../../../../constants/app";
import { getAllAttachmentsForCmpIds } from "../../../../../components/Workspace/epics/componentAttachements/util";
import type { Attachments } from "../../../../../components/Workspace/epics/componentAttachements/flowTypes";

export const wrapperNodeClass = 'mceNonEditable';
export const dataWrapAttribute = 'data-wrap-id';

const {
    left,
    right,
    above,
    below,
    center
} = WrapPositionMap;

const indexChange = {
    [left]: 0,
    [right]: 0,
    [center]: 0,
    [above]: -1,
    [below]: 1
};

/*
    Reference:
    editorNode => TinyMce editor element containing para nodes and wrapped components
    wrapperNode => A node containing multiple wrapped components, one per alignment & para node combination
    wrappedNode => Place holder for wrapped component
*/

function getElementFromHtml(html: string): HTMLElement {
    return DOM.create('div', {}, html);
}

function getWrappedComponentsIdsFromNode(node: HTMLElement): Array<string> {
    const wrappedComponentIds: any[] = [];

    $(node).find(`[${dataWrapAttribute}]`).each(function (this: any) {
        wrappedComponentIds.push(this.dataset.wrapId);
    });

    return wrappedComponentIds;
}

const memoMax3TimeOfMaxComponentCount = makeMemoMax(MaxComponentsOnPage * 3);
export const getNumberOfParaNodes = memoMax3TimeOfMaxComponentCount(
    (wrapperComponentContent: string): number => {
        const editorNode = getElementFromHtml(wrapperComponentContent);
        return $(editorNode).children().filter(`:not(.${wrapperNodeClass})`).length;
    }
);

export const getWrappedComponentIds = memoMax3TimeOfMaxComponentCount(
    (wrapperComponentContent: string): Array<string> => {
        const editorNode = getElementFromHtml(wrapperComponentContent);
        return getWrappedComponentsIdsFromNode(editorNode);
    }
);

// Minimum width of component should take paragraph padding into account
// Add up max left and max right padding of all paragraphs
// Add some extra width to show at least 1 character per line
// Should consider wrapped component widths
export const getMinWrapperComponentWidth = memoMax3TimeOfMaxComponentCount(
    (wrapperComponentContent: string): number => {
        const
            minTextNodeContentWidth = 10, //TODO: this should be max width a single character takes instead of static
            editorNode = getElementFromHtml(wrapperComponentContent);

        let
            maxWrapperNodeWidth = 0,
            maxTextNodeWidth = 0;

        $(editorNode).children().each(function (this: any) {
            if ($(this).hasClass(wrapperNodeClass)) {
                const width = parseFloat(this.style.width);
                if (width > maxWrapperNodeWidth) {
                    maxWrapperNodeWidth = width;
                }
            } else {
                const
                    paddingLeft = parseFloat(this.style.paddingLeft || 0),
                    paddingRight = parseFloat(this.style.paddingRight || 0),
                    minTextNodeWidth = paddingLeft + minTextNodeContentWidth + paddingRight;

                if (minTextNodeWidth > maxTextNodeWidth) {
                    maxTextNodeWidth = minTextNodeWidth;
                }
            }
        });

        return Math.max(maxWrapperNodeWidth, maxTextNodeWidth);
    }
);

export const stripWrappedComponents = (content: string): string => {
    const editorNode = getElementFromHtml(content);
    $(editorNode).find(`.${wrapperNodeClass}`).remove();
    return editorNode.innerHTML;
};

/***
 * margins should be updated for a special case, applied only when following criteria met
 * 1. When a wrappedNode is added to or removed from wrapperNode
 * 2. Center aligned wrapperNode
 * 3. Contains a wrappedNode with old website build position
***/
function updateMarginsOfWrappedNodes(wrapperNode, oldWidth, newWidth) {
    const
        gap = (newWidth - oldWidth) / 2,
        $wrapperNode = $(wrapperNode);

    if ($wrapperNode.hasClass(center)) {
        $wrapperNode.children().each(function (this: any) {
            let
                marginLeft = this.style.marginLeft,
                marginRight = this.style.marginRight;

            if (marginLeft !== 'auto' && marginRight !== 'auto') {
                this.style.marginLeft = `${parseFloat(marginLeft) + gap}px`;
                this.style.marginRight = `${parseFloat(marginRight) + gap}px`;
            }
        });
    }
}

function updateWrapperNodeDimensions(wrapperNode: HTMLElement) {
    let
        newWidthWithMargins = 0,
        newWidthWithoutMargins = 0,
        oldWidth = parseFloat(wrapperNode.style.width),
        minMargin = 0;

    $(wrapperNode).children().each(function (this: any) {
        const
            width = parseFloat(this.style.width),
            marginLeft = parseFloat(this.style.marginLeft) || 0,
            marginRight = parseFloat(this.style.marginRight) || 0;

        // If center aligned old WSB component, adjust margin to occupy min possible width
        if (this.style.marginLeft !== 'auto' && this.style.marginRight !== 'auto') {
            minMargin = Math.min(minMargin || Infinity, marginLeft, marginRight);
        }

        newWidthWithMargins = Math.max(width + marginLeft + marginRight, newWidthWithMargins);
        newWidthWithoutMargins = Math.max(width, newWidthWithoutMargins);
    });

    newWidthWithMargins -= 2 * minMargin;
    const newWidth = Math.max(newWidthWithMargins, newWidthWithoutMargins);

    wrapperNode.style.width = `${newWidth}px`; // eslint-disable-line
    updateMarginsOfWrappedNodes(wrapperNode, oldWidth, newWidth);
}

function removeNodeFromWrapper(editorNode: HTMLElement, wrappedComponentId: string) {
    let index;
    const wrappedNode = $(editorNode).find(`[${dataWrapAttribute}="${wrappedComponentId}"]`)[0];

    if (wrappedNode) {
        // WBTGEN-9970: node may not be HTMLElement, fix types
        const wrapperNode: HTMLElement = wrappedNode.parentNode;
        if (wrapperNode) {
            // @ts-ignore _relParaIndex property is missing in Node
            index = wrapperNode._relParaIndex; // eslint-disable-line

            // Every wrapper node contains a wrapPlaceHolder element other than wrappedNodes
            // It is used in mobile view editor
            if (wrapperNode.children.length === 1) {
                // Remove wrapper, if current node is the only wrappedNode
                editorNode.removeChild(wrapperNode);
            } else {
                // Remove the node
                wrapperNode.removeChild(wrappedNode);
                updateWrapperNodeDimensions(wrapperNode);
            }
        }
    }

    return index;
}

function getRelParaIndex(position, existingIndex) {
    let index;
    index = (existingIndex || 0) + indexChange[position];
    return index;
}

function updateRelParaIndexesAndGetParaNodes(editorNode) {
    const paraNodes: any[] = [];

    $(editorNode).children().each(function (this: any) {
        if ($(this).hasClass(wrapperNodeClass)) {
            this._relParaIndex = paraNodes.length;
        } else {
            paraNodes.push(this);
        }
    });

    return paraNodes;
}

export const isWrappedLeft = R.pathEq(['relIn', 'left'], 0);
export const isWrappedRight = R.pathEq(['relIn', 'right'], 0);

function getAlignmentOfWrappedComponent(wrappedComponent: AnyComponent): string {
    if (isWrappedLeft(wrappedComponent)) {
        return left;
    } else if (isWrappedRight(wrappedComponent)) {
        return right;
    } else {
        return center;
    }
}

export const appendWrappedNode = (
    position: WrapPosition,
    wrapperComponentContent: string,
    wrappedComponent: AnyComponent
) => {
    const editorNode = getElementFromHtml(wrapperComponentContent),
        currentAlignment = getAlignmentOfWrappedComponent(wrappedComponent),
        alignment = (position === above || position === below) ? currentAlignment : position,
        paraNodes = updateRelParaIndexesAndGetParaNodes(editorNode),

        existingIndex = removeNodeFromWrapper(editorNode, wrappedComponent.id),
        relParaIndex = getRelParaIndex(position, existingIndex),

        relParaNode = paraNodes[relParaIndex],
        wrapperNodesForRelParaNode = (
            relParaNode ?
                $(relParaNode).prevUntil(paraNodes[relParaIndex - 1]) :
                $(paraNodes[relParaIndex - 1]).nextUntil()
        ),
        wrapperNodeForAlignment = wrapperNodesForRelParaNode.filter(`.${alignment}`),
        shouldCreateNewWrapperNode = !wrapperNodeForAlignment.length;

    if (shouldCreateNewWrapperNode) {
        const wrapperNode = DOM.createFragment(
            getWrapperHtmlWithoutRelIn(alignment, wrappedComponent)
        );

        const referenceNode = alignment === 'center' ? (wrapperNodesForRelParaNode[0] || relParaNode) : relParaNode;
        editorNode.insertBefore(wrapperNode, referenceNode);
    } else {
        const
            wrapperNode = wrapperNodeForAlignment[0],
            wrappedNode = DOM.createFragment(renderWrapHtml(alignment, wrappedComponent));

        // $FlowFixMe: WBTGEN-9970: wrapperNode can be null, put checks
        wrapperNode.appendChild(wrappedNode);

        // Update dimensions of wrapperNode
        // $FlowFixMe: WBTGEN-9970: wrapperNode can be null, put checks
        const oldWidth = parseFloat(wrapperNode.style.width);
        const newWidth = Math.max(oldWidth, wrappedComponent.width);
        // $FlowFixMe: WBTGEN-9970: wrapperNode can be null, put checks
        wrapperNode.style.width = `${newWidth}px`;
        updateMarginsOfWrappedNodes(wrapperNode, oldWidth, newWidth);
    }

    return editorNode.innerHTML;
};

export const removeWrappedNode = (
    wrapperComponentContent: string,
    wrappedComponentId: string
) => {
    const editorNode = getElementFromHtml(wrapperComponentContent);
    removeNodeFromWrapper(editorNode, wrappedComponentId);
    return editorNode.innerHTML;
};

export const updateWrappedNodeDimensions = (
    wrapperComponentContent: string,
    wrappedComponent: AnyComponent
) => {
    const
        editorNode = getElementFromHtml(wrapperComponentContent),
        // $FlowFixMe: WBTGEN-9970: Node cannot be HTMLElement, fix types
        wrappedNode: HTMLElement = $(editorNode).find(`[${dataWrapAttribute}=${wrappedComponent.id}]`)[0];

    if (!wrappedNode) return "";

    wrappedNode.style.width = `${wrappedComponent.width}px`;
    wrappedNode.style.minHeight = `${wrappedComponent.height}px`;
    if (wrappedNode.parentNode) {
        // @ts-ignore WBTGEN-9970: node may not be HTMLElement, fix types
        updateWrapperNodeDimensions(wrappedNode.parentNode);
    }

    return editorNode.innerHTML;
};

function appendElementToBodyOutsideViewport(element: HTMLElement) {
    element.style.position = 'fixed';// eslint-disable-line
    element.style.opacity = '0';// eslint-disable-line
    element.style.top = '-100000px';// eslint-disable-line

    document.body.appendChild(element);
}

export const getComponentsChanges = (
    wrapperComponent: TextComponent,
    componentsMap: ComponentsMap,
    attachments: Attachments = {},
) => {
    const
        componentsChanges = {},
        $componentDom = $(`#${wrapperComponent.id}`);

    // This is to fix the problem, some component heights change while duplicating or switching pages
    if (!$componentDom[0]) {
        return componentsChanges;
    }

    const
        componentClone = $componentDom[0].cloneNode(true),
        $editorNode = $(componentClone).find('.tinyMceContent');

    if (!$editorNode[0]) {
        return componentsChanges;
    }

    // $FlowFixMe: WBTGEN-9970: Node cannot be HTMLElement, fix types
    const editorNode: HTMLElement = $editorNode[0];

    // $FlowFixMe: WBTGEN-9970: componentClone may not have style attribute, put checks
    componentClone.style.width = `${wrapperComponent.width}px`;
    // $FlowFixMe: WBTGEN-9970: componentClone may not have style attribute, put checks
    componentClone.style.height = `${wrapperComponent.height}px`;
    editorNode.innerHTML = wrapperComponent.content;
    // $FlowFixMe: WBTGEN-9970: node may not be HTMLElement, fix types
    appendElementToBodyOutsideViewport(componentClone);

    const
        wrapperWidth = editorNode.offsetWidth,
        wrapperHeight = editorNode.offsetHeight;

    let relParaIndex = 0;
    $editorNode.children().each(function (this: any) {
        const $currentNode = $(this);
        if ($currentNode.hasClass(wrapperNodeClass)) {
            const
                wrapperNodeOffsetTop = this.offsetTop,
                wrapperNodeOffsetLeft = this.offsetLeft;

            $currentNode.children().each(function (this: any) {
                const
                    wrappedNodeOffsetTop = this.offsetTop,
                    wrappedNodeOffsetLeft = this.offsetLeft;

                const
                    offsetTop = wrapperNodeOffsetTop + wrappedNodeOffsetTop,
                    offsetLeft = wrapperNodeOffsetLeft + wrappedNodeOffsetLeft;

                const
                    componentId = this.dataset.wrapId,
                    component = componentsMap[componentId],
                    newTop = wrapperComponent.top + offsetTop,
                    newLeft = wrapperComponent.left + offsetLeft;

                componentsChanges[componentId] = {
                    relPara: {
                        index: relParaIndex,
                        offset: wrappedNodeOffsetTop
                    },
                    relIn: {
                        ...component.relIn,
                        id: wrapperComponent.id,
                        top: offsetTop,
                        left: offsetLeft,
                        right: 0 - (wrapperWidth - (offsetLeft + component.width)),
                        bottom: 0 - (wrapperHeight - (offsetTop + component.height))
                    },
                    top: newTop,
                    left: newLeft
                };

                const attachedIds = getAllAttachmentsForCmpIds(attachments, [componentId]);
                if (attachedIds.length) {
                    const topDiff = newTop - component.top,
                        leftDiff = newLeft - component.left;
                    attachedIds.forEach(cmpId => {
                        const attachedCmp = componentsMap[cmpId];
                        componentsChanges[cmpId] = {
                            ...componentsChanges[cmpId],
                            top: attachedCmp.top + topDiff,
                            left: attachedCmp.left + leftDiff,
                        };
                    });
                }
            });
        } else {
            relParaIndex++;
        }
    });

    $(componentClone).remove();
    return componentsChanges;
};
