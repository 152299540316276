// @ts-nocheck
import { WINDOW_RESIZED } from "../../../actionTypes";
import { makeValueReducer } from "../../../../makeReducer/index";
import type { BrowserDimensions } from "../../dimensions/index";

export const reducer = makeValueReducer({
    defaultState: {},
    handleActions: {
        [WINDOW_RESIZED]: (state: BrowserDimensions, { payload: { width, height } }): BrowserDimensions => ({
            // can't use Payload to state reducer since ./redux/modules/children/dimensions/reducers/windowResize
            browserWidth: width,
            browserHeight: height
        })
    }
});
