import type { ComponentsMap } from "../../flowTypes";
import type { Position } from "../../../../flowTypes";
import { getComponentZIndex } from "../../../../../../components/Workspace/zIndex";

export function compareComponentsZIndex(componentIdA: string,
    componentIdB: string,
    componentsMap: ComponentsMap): number {
    const
        componentA = componentsMap[componentIdA],
        componentB = componentsMap[componentIdB],
        zIndexA = getComponentZIndex(componentA),
        zIndexB = getComponentZIndex(componentB);

    return zIndexB - zIndexA;
}

export function getMouseMovedDiff(start: Position, current: Position): Position {
    return {
        x: current.x - start.x,
        y: current.y - start.y
    };
}

const orderIndexFactory = (selector: Function) => (componentsMap: ComponentsMap) => {
    const
        componentsIds = Object.keys(componentsMap),
        orderIndexes = componentsIds
            .map((componentId: string): number => componentsMap[componentId].orderIndex);

    return orderIndexes.length === 0 ? 0 : selector(...orderIndexes);
};

export const
    getMaxOrderIndex = orderIndexFactory(Math.max),
    getMinOrderIndex = orderIndexFactory(Math.min);
