import { makeEpic } from "../../../../epics/makeEpic";
import { COMPONENTS_MAP_INITIALIZED } from "../../../Workspace/epics/componentsEval/actionTypes";
import { getFooterSectionId } from "../utils";

export const footerSectionIdVAT = "FOOTER_SECTION_ID";

export const footerSectionIdEpic = makeEpic({
    defaultState: null,
    valueActionType: "FOOTER_SECTION_ID",
    updaters: [
        {
            conditions: [COMPONENTS_MAP_INITIALIZED],
            reducer: ({ values: [{ componentsMap }] }) => {
                return {
                    state: getFooterSectionId(componentsMap),
                };
            },
        },
    ],
});
