import React from "react";
import { Button, InputLabel } from "@mui/material";
import cx from "classnames";

import { getFileNameFromAssetUrl } from "../../../../ImageChooser/utils";

import { useStyles } from "./styles";
import imageStyles from "../../../../Images/imageStyle.css";

import { useIntl } from "../../../../../../../../wbtgen/src/view/intl";
import type { ImageAsset } from "../../../../../../../../wbtgen/src/components/App/flowTypes";

type FaviconSettingsImageChooserProps = {
    asset: ImageAsset | null | undefined;
    hintWarning: string;
    onClick: () => void;
    onRemove: Function;
};

export const FaviconSettingsImageChooser = (props: FaviconSettingsImageChooserProps): JSX.Element | null => {
    const { asset, hintWarning, onClick, onRemove } = props;
    const intl = useIntl();
    const classes = useStyles();
    const isAssetAvailable = asset && asset.url;

    return (
        <React.Fragment>
            <InputLabel className={classes.imageChooserLabel}>{intl.msgJoint("msg: generalInfo.favicon.title {Favicon}")}</InputLabel>
            <div className={classes.imageChooserContainer}>
                <Button
                    disableRipple
                    disableFocusRipple
                    disableElevation
                    className={cx(classes.imageChooserInput, isAssetAvailable && classes.imageChooserInputSelected)}
                    onClick={onClick}
                >
                    <span className={classes.inputLabelContainer}>
                        <span className={classes.inputLabel}>
                            {isAssetAvailable ? getFileNameFromAssetUrl(asset.url) : intl.msgJoint("msg: addImage {Add image}")}
                        </span>
                        {isAssetAvailable ? (
                            <span className={classes.closeIcon} onClick={e => onRemove(e)}>
                                <span className={imageStyles.closeIcon16} />
                            </span>
                        ) : (
                            <span className={classes.closeIcon}>
                                <span className={imageStyles.plusIcon} />
                            </span>
                        )}
                    </span>
                </Button>
            </div>
            {hintWarning ? (
                <div className={classes.imageChooserHintWarning}>{intl.msgJoint(hintWarning)}</div>
            ) : (
                <div className={classes.imageChooserHint}>
                    {intl.msgJoint("msg: generalInfo.favicon.shortHintNew {Use a .png or .ico or jpg file.}")}
                </div>
            )}
        </React.Fragment>
    );
};
