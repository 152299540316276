import * as ActionTypes from "../../../actionTypes";
import makeSectionLinkReducer from "../../../../sectionLinkForm/reducer/makeSectionLinkReducer";

const Actions = {
    NAME_CHANGE: ActionTypes.SECTION_LINK_INFO_NAME_CHANGE,
    PAGE_CHANGE: ActionTypes.SECTION_LINK_INFO_PAGE_CHANGE,
    RESET: ActionTypes.SECTION_LINK_INFO_RESET,
    SECTION_CHANGE: ActionTypes.SECTION_LINK_INFO_SECTION_CHANGE,
    HIDDEN_TOGGLE: ActionTypes.SECTION_LINK_INFO_HIDDEN_TOGGLE
};

export default makeSectionLinkReducer(Actions);
