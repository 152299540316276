import React from "react";
export const SvgYelpSquareFillDark = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <path fill="#3c3c3c" d="M0 0h48v48H0z" />
            <path
                fill="#ffffff"
                d="M26.768 26.704c-.415.42-.064 1.181-.064 1.181l3.12 5.22s.512.689.956.689c.446 0 .887-.367.887-.367l2.467-3.533s.248-.446.254-.837c.009-.555-.827-.707-.827-.707l-5.84-1.879s-.572-.152-.953.233zm-.296-2.63c.298.507 1.123.359 1.123.359l5.827-1.706s.794-.324.907-.755c.112-.432-.13-.953-.13-.953l-2.785-3.286s-.242-.416-.742-.458c-.552-.047-.892.623-.892.623l-3.293 5.191s-.29.517-.015.984zm-2.754-2.025c.687-.169.795-1.168.795-1.168l-.046-8.312s-.104-1.025-.564-1.303c-.72-.439-.935-.21-1.141-.18l-4.838 1.802s-.474.157-.72.553c-.353.561.357 1.383.357 1.383l5.029 6.867s.496.515 1.128.358zm-1.194 3.364c.017-.641-.769-1.026-.769-1.026l-5.2-2.632s-.77-.319-1.145-.097c-.286.17-.54.477-.565.748l-.339 4.178s-.05.723.137 1.053c.265.467 1.137.142 1.137.142l6.071-1.345c.236-.159.65-.173.673-1.02zm1.51 2.254c-.522-.268-1.146.288-1.146.288l-4.065 4.483s-.507.686-.378 1.107c.122.394.323.59.608.729l4.082 1.29s.495.104.87-.005c.532-.154.434-.989.434-.989l.092-6.07s-.02-.585-.498-.833z"
            />
        </g>
    </svg>
);
