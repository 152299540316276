export const
    PAGE_TREE_PAGE_SELECTED = 'PAGE_TREE_PAGE_SELECTED',
    PAGE_TREE_PAGE_OPEN_CONTEXT_MENU = 'PAGE_TREE_PAGE_OPEN_CONTEXT_MENU',
    PAGE_TREE_PAGE_DUPLICATE = 'PAGE_TREE_PAGE_DUPLICATE',
    PAGE_TREE_PAGE_DO_DUPLICATE = 'PAGE_TREE_PAGE_DO_DUPLICATE',
    PAGE_TREE_PAGE_DELETE = 'PAGE_TREE_PAGE_DELETE',
    PAGE_TREE_PAGE_HIDE = 'PAGE_TREE_PAGE_HIDE',
    PAGE_TREE_PAGE_DELETE_CONFIRM = 'PAGE_TREE_PAGE_DELETE_CONFIRM',

    LINK_PAGE_INFO_SET_LINK_TO_FILE_OR_PAGE = 'LINK_PAGE_INFO_SET_LINK_TO_FILE_OR_PAGE',
    LINK_PAGE_INFO_UNSET_LINK_TO_FILE_OR_PAGE = 'LINK_PAGE_INFO_UNSET_LINK_TO_FILE_OR_PAGE',
    LINK_PAGE_INFO_OPEN_NEW_WINDOW_TOGGLE = 'LINK_PAGE_INFO_OPEN_NEW_WINDOW_TOGGLE',
    LINK_PAGE_INFO_URL_CHANGE = 'LINK_PAGE_INFO_URL_CHANGE',
    LINK_PAGE_INFO_NAME_CHANGE = 'LINK_PAGE_INFO_NAME_CHANGE',
    LINK_PAGE_INFO_IS_HIDDEN_TOGGLE = 'LINK_PAGE_INFO_IS_HIDDEN_TOGGLE',
    LINK_PAGE_INFO_SAVE = 'LINK_PAGE_INFO_SAVE',

    SECTION_LINK_INFO_NAME_CHANGE = 'SECTION_LINK_INFO_NAME_CHANGE',
    SECTION_LINK_INFO_PAGE_CHANGE = 'SECTION_LINK_INFO_PAGE_CHANGE',
    SECTION_LINK_INFO_SECTION_CHANGE = 'SECTION_LINK_INFO_SECTION_CHANGE',
    SECTION_LINK_INFO_RESET = 'SECTION_LINK_INFO_RESET',
    SECTION_LINK_INFO_HIDDEN_TOGGLE = 'SECTION_LINK_INFO_HIDDEN_TOGGLE',
    SECTION_LINK_INFO_SAVE = 'SECTION_LINK_INFO_SAVE',
    SECTION_LINK_DUPLICATE = 'SECTION_LINK_DUPLICATE';
