import { calcGalleryHeight } from "./calcGalleryHeight";
import { MinGalleryDimensions, CAPTION_CONTAINER_HEIGHT_MAP } from "./constants/index";

import type {
    AdjustmentHook,
    AdjustmentHookConfig,
    ShouldCallAdjustmentHook
} from "../../Workspace/epics/componentsEval/flowTypes";
import type {
    FacebookFeedGalleryComponent,
    FacebookFeedGalleryComponentDependsOn,
    GalleryComponentExtension
} from './flowTypes';
import { calcGalleryMaxThumbnailDimensions } from "./calcRenderProps";
import { getMediaAssetsToBeShown } from "./utils";

type AdjustmentData = Object // { 'caption-height-of-image-0': 10, 'caption-height-of-image-1': 20 }
type Hook = AdjustmentHook<FacebookFeedGalleryComponent, FacebookFeedGalleryComponentDependsOn, GalleryComponentExtension, AdjustmentData>
type HookConfig =
    AdjustmentHookConfig<FacebookFeedGalleryComponent, FacebookFeedGalleryComponentDependsOn, GalleryComponentExtension, AdjustmentData>
type GalleryShouldCallAdjustmentHook =
    ShouldCallAdjustmentHook<FacebookFeedGalleryComponent, FacebookFeedGalleryComponentDependsOn, GalleryComponentExtension, AdjustmentData>

const
    setHighestCaptionPxIfChanged = (extension, highestCaptionPx) => {
        if (extension.highestCaptionPx === highestCaptionPx) {
            return extension;
        }

        return { ...extension, highestCaptionPx };
    },
    setHeightAndCaptionMinHeightIfChanged = (component: FacebookFeedGalleryComponent, height: number, captionMinHeight: number) => {
        if (component.height === height && component.captionMinHeight === captionMinHeight) {
            return component;
        }

        return { ...component, height, captionMinHeight };
    },
    hook: Hook = ({
        originalComponent,
        component,
        componentExtension = { highestCaptionPx: 0 },
        componentDependencies,
    }) => {
        const
            totalColumns = component.columns,
            totalRows = component.rows,
            facebookFeedAssetUrls = getMediaAssetsToBeShown(
                // @ts-ignore
                componentDependencies.socialAccounts.facebookFeed.facebookFeedAssetUrls, totalRows, totalColumns
            );
        if (!facebookFeedAssetUrls.length) {
            let finalComponent = component;
            if (originalComponent) {
                if (component.width !== originalComponent.width) {
                    finalComponent = { ...component, width: originalComponent.width };
                }
                if (component.height !== originalComponent.height) {
                    finalComponent = { ...finalComponent, height: originalComponent.height };
                }
            }
            return [finalComponent, componentExtension];
        }
        const
            highestCaptionPx = CAPTION_CONTAINER_HEIGHT_MAP[totalColumns],
            { maxThumbnailWidth } = calcGalleryMaxThumbnailDimensions(component),
            { columns, spacingPx } = component,
            componentWithAdjustedWidth = {
                ...component,
                width: Math.round((maxThumbnailWidth * columns) + (spacingPx * (columns - 1)))
            },
            calculatedHeight = facebookFeedAssetUrls.length === 0 ?
                MinGalleryDimensions.height :
                calcGalleryHeight({ component: componentWithAdjustedWidth, facebookFeedMediaAssets: facebookFeedAssetUrls });
        return [
            setHeightAndCaptionMinHeightIfChanged(componentWithAdjustedWidth, calculatedHeight, highestCaptionPx),
            setHighestCaptionPxIfChanged(componentExtension, highestCaptionPx)
        ];
    },
    shouldCallHook: GalleryShouldCallAdjustmentHook = (prevProps, { adjustmentData, component }) =>
        !!adjustmentData ||
        !component.captionsEnabled ||
        // @ts-ignore
        prevProps.componentDependencies.socialAccounts.facebookFeed.facebookFeedConnected ||
        // @ts-ignore
        prevProps.componentDependencies.socialAccounts.facebookFeed.facebookFeedAssetUrls.length > 0 ||
        (
            prevProps.component
            && prevProps.component !== component
            && !component.captionsEnabled
        ),
    hookConfig: HookConfig = {
        hook,
        shouldCallHook
    };

export {
    hookConfig as default
};
