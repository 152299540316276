import React from "react";
import Label from '../../../../../view/common/Label';
import * as styles from './defaultStateEditFields.css';

export default () => (
    <div className={styles.container}>
        <div className={styles.defaultSvgCtn}>
            <span className={styles.defaultSvg} />
        </div>
        <div className={styles.defaultTxtCtn}>
            <Label className={styles.defaultTxt}>
                {
                    // eslint-disable-next-line max-len
                    "msg: component.contactForm.editDialogDefaultText {Click \"Add\" to manage your contact form or select the field that you want to edit.}"
                }
            </Label>
        </div>
    </div>
);
