import { FontFamiliesFallback } from "../../components/presentational/FontFamily/fontFamilies";
import * as colorMapper from '../color';
import { GOOGLE_PREFIX } from '../../components/presentational/AddGoogleFont/constants';
import type { Color } from "../flowTypes";

const mergeWithFallbackFontFamilies = font => [
    `"${font}"`,
    `"Open Sans"`,
    `"Helvetica Neue"`,
    `"Helvetica"`,
    `"sans-serif"`
].join(', ');

export default {
    color: (color: Color): string => colorMapper.toCss(color),
    size: (size: number): string => (size ? `${size}px` : ''),
    weight: (bold: boolean): string => (bold ? 'bold' : 'normal'),
    italic: (italic: boolean): string => (italic ? 'italic' : 'normal'),
    underline: (underline: boolean): string => (underline ? 'underline' : 'none'),
    family: (fontFamily: string) => {
        if (fontFamily.indexOf(GOOGLE_PREFIX) > -1) {
            return mergeWithFallbackFontFamilies(fontFamily.replace(GOOGLE_PREFIX, ''));
        } else if (FontFamiliesFallback[fontFamily]) {
            return FontFamiliesFallback[fontFamily];
        }

        return mergeWithFallbackFontFamilies(fontFamily);
    }
};
