import * as R from "ramda";
import { getNumRowsAndCols, getRowAndColumnForAddOrRemoveAction, fixRowAndColumnIndices } from "../utils";
import type { TableComponent } from "../flowTypes";

export const removeColumnReducer = (component: TableComponent, action: AnyAction): TableComponent => {
    const
        { cells } = component,
        { payload: { fisrtSelectedCellIndex } } = action,
        { numRows, numCols } = getNumRowsAndCols(cells),
        { columnIndex: selectedCellColumnIndex } = getRowAndColumnForAddOrRemoveAction(
            { numRows, numCols }, fisrtSelectedCellIndex
        );

    if (numCols > 1) {
        const
            newFlexWidthSum = 1 - cells[selectedCellColumnIndex].cellInfo.flexWidth,
            dropCellsFromSelectedColumn = selectedCellColumnIndex => cells => cells.filter(
                (cell, index) => index % numCols !== selectedCellColumnIndex
            ),
            updateFlexwidth = newFlexWidthSum => R.map(R.evolve({
                cellInfo: {
                    flexWidth: fw => (fw / newFlexWidthSum)
                }
            }));

        return R.evolve({
            width: (width) => (width * newFlexWidthSum),
            cells: R.pipe(
                dropCellsFromSelectedColumn(selectedCellColumnIndex),
                fixRowAndColumnIndices({ numRows, numCols: (numCols - 1) }),
                updateFlexwidth(newFlexWidthSum)
            )
        }, component);
    }

    return component;
};
