import { assocPath, mergeDeepRight, path, pathOr, prop, propOr } from 'ramda';
import { pure } from 'recompose';
import React from 'react';
import cx from 'classnames';
import TableStyles from './Table.css';
import {
    TABLE_CELL_MOUSE_DOWN,
    TABLE_CELL_DBL_CLICKED,
    TABLE_ACTIVE_CELL_TEXT_CHANGED
} from "../actionTypes";
import singleAndDoubleClickHandler from "../../../../view/common/singleAndDoubleClickHandler";
import { noop } from "../../../../utils/ramdaEx";
import { generateInlineStyle, dropUndefinedValues, dropValues } from "../utils";
import type { TableCellProps } from "../flowTypes";
import { getThemeRulesForBackground } from '../../../ThemeGlobalData/themeRules';
import { DefaultGradient } from '../../../presentational/ColorProperty/constants';

const propsToDrop = { backgroundImage: 'none' };

const TRANSPARENT_COLOR = ['HSL', 0, 0, 0, 0];
const getCellStyles = (style, autoColorMode, themeColorsData, selectedParentTheme) => {
    if (!autoColorMode) return style;

    const backgroundThemeColor = path(['block', 'background', 'colorData', 'themeColor'], style);
    const isBackgroundOverridden = !!backgroundThemeColor;
    const backgroundColorWithOpacity = assocPath(
        [4],
        pathOr(0, ['block', 'background', 'colorData', 'color', 4], style),
        propOr(TRANSPARENT_COLOR, backgroundThemeColor, themeColorsData)
    );
    const gradientThemeColor = path(['block', 'background', 'colorData', 'gradient', 'themeColor'], style);
    const isGradientOverridden = !!gradientThemeColor;
    const gradientColorWithOpacity = assocPath(
        [4],
        pathOr(0, ['block', 'background', 'colorData', 'gradient', 'color', 4], style),
        propOr(TRANSPARENT_COLOR, gradientThemeColor, themeColorsData)
    );

    return mergeDeepRight(style, {
        block: {
            background: {
                colorData: {
                    color: (isBackgroundOverridden ? backgroundColorWithOpacity : null),
                    gradient: (
                        (isGradientOverridden || isBackgroundOverridden) // isBackgroundOverridden is checked to override backgroundImage from global styles
                            ? mergeDeepRight(
                                pathOr(DefaultGradient, ['block', 'background', 'colorData', 'gradient'], style), // for fade point and rotation degrees
                                { color: (gradientThemeColor ? gradientColorWithOpacity : backgroundColorWithOpacity) }
                            )
                            : null
                    ),
                },
            },
            border: {
                color: assocPath(
                    [4],
                    pathOr(1, ['block', 'border', 'color', 4], style),
                    prop(
                        pathOr(
                            getThemeRulesForBackground(selectedParentTheme, themeColorsData).text,
                            ['block', 'border', 'themeColor'],
                            style
                        ),
                        themeColorsData,
                    )
                ),
            },
        }
    });
};

const TableCell = pure((props: TableCellProps) => {
    const
        {
            componentId,
            cellIndex,
            cellProps: { content, style, isWorkspace },
            commonCellsData,
            isSelected,
            tableInEditMode,
            stylesheetsIdToNameMap,
            cellInEditMode,
            dispatch,
            TextView,
            stylesheets,
            globalVariables,
            site,
            themeSettingsData,
            themeColorsData,
            selectedParentTheme
        } = props;

    const
        cellStyle = style || commonCellsData.style,
        height = props.cellProps.minHeight,
        inlineStyle = dropValues(
            dropUndefinedValues({
                ...generateInlineStyle(getCellStyles(cellStyle, themeSettingsData.autoColorMode, themeColorsData, selectedParentTheme)),
                height,
                ...(isWorkspace ? { boxSizing: 'content-box' } : {})
            }),
            propsToDrop
        ),
        tdClassname = stylesheetsIdToNameMap[cellStyle.globalId],

        cellIsInteractive = tableInEditMode && !cellInEditMode,
        catchDoubleClick = !tableInEditMode || cellIsInteractive,

        onMouseDown = cellIsInteractive ? () => dispatch({
            type: TABLE_CELL_MOUSE_DOWN,
            payload: cellIndex
        }) : noop, // eslint-disable-line max-len
        onDblClickInEditMode = () => dispatch({ type: TABLE_CELL_DBL_CLICKED, payload: cellIndex }),
        onClick = catchDoubleClick ? e => singleAndDoubleClickHandler(e, noop, onDblClickInEditMode) : noop;

    return (
        <td
            style={inlineStyle}
            align={cellStyle.horizontalAlign}
            className={cx(tdClassname, { [TableStyles.selectedCell]: isSelected })}
            onClick={onClick}
            onMouseDown={onMouseDown}
        >
            <TextView
                inEditMode={cellInEditMode}
                height={height}
                content={content}
                onHeightChanged={noop}
                handleEditorChangeActionType={TABLE_ACTIVE_CELL_TEXT_CHANGED}
                handleEditorChangeIdentificationData={{
                    componentId,
                    cellIndex
                }}
                dispatch={dispatch}
                disableMeasure
                component={{ content }}
                renderedWrappedComponentContentsMap={{}}
                siteMap={site}
                globalStyles={stylesheets}
                globalVariables={globalVariables}
                verticalAlignment="top"
                themeSettingsData={themeSettingsData}
                themeColorsData={themeColorsData}
                selectedParentTheme={selectedParentTheme}
                themeOverrideColor={cellStyle.text.themeColor}
                themeHighlightColor={cellStyle.text.themeHighlightColor}
                themeShadowBlurRadius={cellStyle.text.themeShadowBlurRadius}
                themeShadowColor={cellStyle.text.themeShadowColor}
                themeShadowOffsetX={cellStyle.text.themeShadowOffsetX}
                themeShadowOffsetY={cellStyle.text.themeShadowOffsetY}
            />
        </td>
    );
});

export default TableCell;
