import { receiveOnly } from '../../../../epics/makeCondition';
import { componentAttachmentsVAT } from './valueActionType';
import { withPayload } from "../../../../../utils/action";

const attachmentsSelector = ({ attachments }) => attachments;

export
const ReceiveOnlyAttachments =
    receiveOnly(componentAttachmentsVAT, attachmentsSelector),
    ReceiveOnlyAttachmentsFromFullAction =
    receiveOnly(componentAttachmentsVAT, withPayload(attachmentsSelector));
