export const
    DASHBOARD_REDIRECT_TRIGGER_KEY = 'DASHBOARD_REDIRECT_TRIGGER_KEY';

export type SessionData = {
    key: string,
    payload?: string
};

export const
    setDataInSessionStorage = (sessionData: SessionData) => {
        window.sessionStorage.setItem(
            DASHBOARD_REDIRECT_TRIGGER_KEY,
            JSON.stringify(sessionData)
        );
    };
