import { makeValueReducer } from '../../../makeReducer/index';

/*  CONSTANTS  */
export const
    TIPS_NEXT = 'TIPS_NEXT',
    TIPS_PREV = 'TIPS_PREV',
    OPEN_TIPS = 'OPEN_TIPS',
    CLOSE_TIPS = 'CLOSE_TIPS';

export type Tips = {
    page: number,
    opened: boolean
}

export const defaultState: Tips = {
    page: 0,
    opened: false
};

export default makeValueReducer({
    defaultState,
    handleActions: {
        [TIPS_NEXT]: (state: any): any => ({ ...state, page: state.page + 1 }),
        [TIPS_PREV]: (state: any): any => ({ ...state, page: state.page - 1 }),
        [OPEN_TIPS]: (state: any): any => ({ ...state, opened: true }),
        [CLOSE_TIPS]: (state: any): any => ({ ...state, opened: false })
    }
});
