import { clamp } from 'ramda';

const minWidth = 325, maxWidth = 605;

const hook = ({
    component,
    componentExtension
}) => {
    const updatedComponent = {
        ...component,
        width: clamp(minWidth, maxWidth, component.width),
    };

    return [updatedComponent, componentExtension];
};

const adjustmentHookConfig = {
    hook,
    shouldCallHook: () => true,
};

export { adjustmentHookConfig };
