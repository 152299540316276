import React, { ReactNode } from "react";
import cx from "classnames";

import { FormControl, MenuItem, Select as MuiSelect, Divider } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import { colorGray_8a as colorGray8a, colorGray_e2 as colorGraye2 } from "../../../../../../variables.css";
import imageStyles from "../../../../../../../../../src/dashboard/src/components/Images/imageStyle.css";

const useStyles = makeStyles(
    {
        formControl: {
            margin: "0",
            width: "100%",
            height: "30px"
        },
        select: {
            fontSize: "14px",
            lineHeight: "22px",
            borderRadius: "100px !important",
            border: `1px solid #cccccc`,
            width: "100%",
            height: "30px",
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
            },
            "&:before": {
                content: "none",
                borderBottom: 'none',
            },
            "&:after": {
                content: "none",
                borderBottom: 'none',
            },
            "& > div": {
                padding: "4px 20px",
            },
            "& > div:focus": {
                backgroundColor: "rgba(0,0,0,0)"
            }
        },
        dropdownIcon: {
            position: "absolute",
            width: "16px",
            height: "16px",
            right: "20px",
            padding: "7px 0px 7px 20px !important",
            pointerEvents: "none"
        },
        menuPaper: {
            marginTop: "4px !important",
            boxShadow: "none",
            border: `1px solid ${colorGray8a}`
        },
        menuList: {
            padding: "10px 5px !important",
            display: "flex",
            flexDirection: "column",
            gap: "5px"
        },
        menuItem: {
            padding: "5px 15px !important",
            fontSize: "12px",
            lineHeight: "18px",
            borderRadius: "4px !important"
        },
        divider: {
            margin: "0px 15px !important",
            background: colorGraye2
        }
    }
);

const getMenuProps = (classes, classesProps) => {
    return (
        {
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
            },
            transformOrigin: {
                vertical: "top",
                horizontal: "left"
            },
            classes: {
                paper: cx(classes.menuPaper, classesProps?.menuPaper),
                list: cx(classes.menuList, classesProps?.menuList)
            },
            getContentAnchorEl: null
        }
    );
};

export const Select = (props) => {
    const classes = useStyles();

    const {
        value,
        data,
        handleChange,
        formControlStyles,
        inputStyles,
        inputClasses,
        separators = [],
        classes: classesProps,
        iconsMap
    } = props;

    return (
        <FormControl style={formControlStyles} className={cx(classes.formControl, classesProps?.formControl)}>
            <MuiSelect
                id="select"
                value={value}
                renderValue={(val): ReactNode => {
                    return data.filter((el) => el.value === val)[0].label;
                }}
                onChange={handleChange}
                style={inputStyles}
                className={classes.select}
                classes={inputClasses}
                // @ts-ignore
                MenuProps={getMenuProps(classes, classesProps)}
                IconComponent={() => (
                    <div className={cx(imageStyles.chevronDownIcon, classes.dropdownIcon, classesProps?.dropdownIcon)} />
                )}
            >
                {
                    [...Array(separators.length * 2 + 1).keys()].map((index) => {
                        let startIndex = 0;
                        let endIndex = 0;

                        if (index % 2 === 0) {
                            startIndex = index === 0 ? index : separators[index / 2 - 1];
                            endIndex = separators.length > index / 2 ? separators[index / 2] : data.length;
                        }

                        return (
                            index % 2 !== 0 ? <Divider key={index} className={classes.divider} /> : (
                                data.slice(startIndex, endIndex).map((item) => {
                                    return (
                                        <MenuItem
                                            key={item.value}
                                            className={cx(classes.menuItem, classesProps?.menuItem)}
                                            value={item.value}
                                        >
                                            {
                                                iconsMap && item.value in iconsMap ? (
                                                    <span className={iconsMap[item.value]} />
                                                ) : (
                                                    null
                                                )
                                            }
                                            {item.label}
                                        </MenuItem>
                                    );
                                })
                            )
                        );
                    })
                }
            </MuiSelect>
        </FormControl>
    );
};
