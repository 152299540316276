import * as PagesIds from './ids';
import { MOBILE_VIEW, MobileViewTitle } from "../../../PropertiesPanel/view/MobileView/constants";
import BUTTON from "../kind";
import { ComponentNames } from "../../constants";

export default {
    [PagesIds.MAIN]: ComponentNames[BUTTON],
    [PagesIds.LINK]: "msg: common.link {Link}",
    [PagesIds.STYLE]: "msg: component.button.style {Style}",
    [MOBILE_VIEW]: MobileViewTitle
};
