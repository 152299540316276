import makeUuid from '../../../../utils/makeUuid';
import type { VideoComponent } from "./flowTypes";

export default (component: VideoComponent): VideoComponent => {
    return {
        ...component,
        state: {
            ...component.state,
            id: makeUuid()
        }
    };
};
