import React from 'react';
import * as R from "ramda";
import cx from 'classnames';
import { connect } from "react-redux";
import * as Icon from './Icons.css';
import { SECTION as SectionKind } from "../../oneweb/componentKinds";
import { SectionsKind, Sections } from "./constants";
import { DroppableCmpShortcut, ShortCut } from "../view/DroppableCmpShortcut";
import type { ExtendedPanelContentProps } from "../view/inserter";
import styles, { sectionTitleHeight } from '../view/Inserter.css';
import { makeEpicStateSelector } from "../../../epics/makeEpic";
import sectionsBlocksVAT from "../epics/sectionsBlocks/valueActionType";
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import EPSeparator from "../view/EPSeparator";
import { injectIntl } from "../../../view/intl/index";
import { SectionDataMap, ALL_FILTER_KEY, SectionDataMessageMap } from "../epics/sectionsBlocks/index";
import {
    SET_FILTER
} from "../actionTypes";
import LoadingIndicator from '../../../view/common/LoadingIndicator/index';
import HeaderFilterView from "./Header";
import type { AppState } from "../../../redux/modules/flowTypes";
import type { ReactElementRef } from "../../../globalTypes";
import sectionInfoTooltipVAT from "../../Tooltip/epics/sectionInfoTooltip/valueActionType";
import noMouseEventPropagation from "../../../view/common/NoMouseEventsPropagation";
import { PrimaryButton } from "../../../view/common/Button/index";
import { LOCATION_RELOAD } from "../../../redux/middleware/window/actionTypes";
import componentsRegistry from "../../../view/oneweb/registry/index";
import { getDefaultReducerState } from "../../../redux/makeReducer/index";
import { stripsConfig, stripsStylesMap, WHITE_STRIP } from "../../oneweb/Background/extendedPanel/strips/config";
import calcRenderProps from "../../oneweb/Background/calcRenderProps";
import View from "../../oneweb/Strip/view/index";
import sectionStyle from "../../oneweb/Background/extendedPanel/styles.css";
import SectionBlockError from "../view/SectionBLockError/index";
import { BLANK_SECTION_HEIGHT } from "../constants";
import { ComponentsKindsToDisableMap } from '../flowTypes';
import { ComponentsMap } from '../../../redux/modules/children/workspace/flowTypes';

type SectionInserterProps = {
    iconClassName: string,
    text: string,
    textClassName?: string,
    kind: string,
    hasUpdate: boolean
};

const tipMsg = 'msg: extendedPanel.sectionTip {Sections work as building blocks for your website and help you create beautiful pages in no time. Drag one to your workspace to make it fit with the styling of your template.}'; // eslint-disable-line

const getTitle = (intl, title) => {
    if (title) {
        return intl.msgJoint(title);
    }
    return "Entry Missing for this section at wbtgen/src/components/Panel/epics/sectionsBlocks/index";
};

class SectionInserter extends React.Component<SectionInserterProps> {
    ref: ReactElementRef<any>;
    constructor(props: SectionInserterProps) {
        super(props);
        this.ref = React.createRef();
    }
    /* WBTGEN-32384 Tooltip no longer needed */
    // componentDidUpdate() {
    //     const {
    //         dispatch,
    //         showSectionTooltip,
    //         intl
    //     } = this.props;

    //     if (showSectionTooltip && this.ref.current) {
    //         const
    //             clientRect = this.ref.current && this.ref.current.getBoundingClientRect(),
    //             { top, width, height, left } = clientRect || {},
    //             customHTML = (
    //                 <div className={styles.tooltipContainer}>
    //                     <div className={styles.tooltipHeader}>
    //                         <div className={styles.tooltipHeaderText}>
    //                             {intl.msgJoint('msg: tooltipInfo.section.header {New!}')}
    //                         </div>
    //                         <div
    //                             className={styles.tooltipHeaderClose}
    //                             onClick={() => {
    //                                 dispatch({ type: CLOSE_TOOLTIP });
    //                             }}
    //                         />
    //                     </div>
    //                     <div className={styles.tooltipBody}>
    //                         {intl.msgJoint('msg: tooltipInfo.section.body {Sections are groups of styled content designed to help you make great looking sites, fast.}')} {/*eslint-disable-line*/}
    //                     </div>
    //                     <div className={styles.tooltipFooter}>
    //                         <a
    //                             onClick={() => {
    //                                 dispatch({ type: CLOSE_TOOLTIP });
    //                             }}
    //                         >
    //                             {intl.msgJoint('msg: tooltipInfo.section.linkText {Got it}')}
    //                         </a>
    //                     </div>
    //                 </div>
    //             );
    //         dispatch({
    //             type: SHOW_TOOLTIP_FOR_SECTION_INSERTER,
    //             payload: {
    //                 position: {
    //                     x: left + width,
    //                     y: top + (height / 2)
    //                 },
    //                 elementDimensions: { top, width, height, left },
    //                 id: SectionShortcutInfoTooltip,
    //                 customClass: styles.sectionToolTip,
    //                 customHTML
    //             }
    //         });
    //     }
    // }

    render() {
        const {
            iconClassName,
            text,
            textClassName,
            kind,
            hasUpdate
        } = this.props;

        return [
            <ShortCut
                key={1}
                iconClassName={iconClassName}
                text={text}
                textClassName={textClassName}
                kind={kind}
                hasUpdate={hasUpdate}
            />,
            <span key={2} ref={this.ref} />
        ];
    }
}

export const SectionShortcut = connect((appState: AppState) => {
    return {
        showSectionTooltip: makeEpicStateSelector(sectionInfoTooltipVAT)(appState).show
    };
})(injectIntl(SectionInserter));

const Header = injectIntl(({ intl, sectionsBlocksState: { listLoaded, hasError }, filterKey, dispatch }) => {
    if (!listLoaded) return null;

    const props = {
        data: SectionDataMap,
        title: intl.msgJoint('msg: extendedPanel.sectionsTitle {Sections}'),
        selected: filterKey,
        actionName: SET_FILTER,
        tipMessage: tipMsg,
        dispatch,
        hasError
    };

    return <HeaderFilterView {...props} />;
});

const SectionBlockImage = ({ image }) => {
    return (
        <img
            className={cx(styles.sectionWrapper)}
            src={image}
            draggable={false}
            onDragStart={(e) => e.preventDefault()}
        />
    );
};

export const getDisabledComponentKind = (
    componentsKindsToDisableMap: ComponentsKindsToDisableMap, componentsMap: ComponentsMap
) => {
    let result = "", cmpIds = Object.keys(componentsMap);
    for (let i = 0; i < cmpIds.length; i++) {
        const { kind } = componentsMap[cmpIds[i]];
        if (componentsKindsToDisableMap[kind]) {
            result = kind;
            break;
        }
    }
    return result;
};

const SectionBlockCmp = injectIntl((props) => {
    const { sectionsBlocksState: { sectionsBlocks }, componentsKindsToDisableMap, intl, filterKey } = props;
    const allSections = sectionsBlocks.sections;
    let filteredSections = filterKey === ALL_FILTER_KEY ? allSections : allSections.filter(a => a.key === filterKey);

    let allCategoryWithoutAll = R.tail(SectionDataMap);
    filteredSections = allCategoryWithoutAll.map(category => filteredSections.find(o => o.key === category.id)).filter(a => !!a);

    return (
        filteredSections.map(({ id, items, key }, index) => {
            return (
                <div key={id}>
                    {index !== 0 && <React.Fragment><VerticalSpacer y={22} /> <EPSeparator /></React.Fragment>}
                    <VerticalSpacer y={23} />
                    <h3 className={styles.categoryHeader}>
                        {getTitle(intl, SectionDataMessageMap[key])}
                    </h3>
                    <VerticalSpacer y={12} />
                    {/* <div className={styles.divider} /> */}
                    <div>
                        {
                            items.map(({ image, id: sectionId, width, height, key, data: { componentsMap } }, index) => {
                                return (
                                    <React.Fragment key={key}>
                                        { index !== 0 && <VerticalSpacer y={7} />}
                                        <DroppableCmpShortcut
                                            key={sectionId}
                                            {...props}
                                            payload={{ sectionOrBlockId: sectionId, width, height, key }}
                                            kind={SectionKind}
                                            disabledComponentKind={getDisabledComponentKind(componentsKindsToDisableMap, componentsMap)}
                                        >
                                            <SectionBlockImage image={image} />
                                        </DroppableCmpShortcut>
                                    </React.Fragment>
                                );
                            })
                        }
                    </div>
                </div>
            );
        })
    );
});

const mapStateToProps = (appState, props) => {
    const sectionsBlocksState = makeEpicStateSelector(sectionsBlocksVAT)(appState),
        filterKey = props.filterKey || sectionsBlocksState.filterKey;
    return {
        sectionsBlocksState,
        filterKey,
        componentsDependencies: appState.selectedWorkspace.componentsDependencies
    };
};
const BlankSection = (props) => {
    const { reducer } = componentsRegistry[SectionKind],
        ComponentDefaultState = getDefaultReducerState(reducer),
        componentDependencies = props.componentsDependencies[SectionKind],
        component = R.mergeDeepRight(ComponentDefaultState, R.mergeDeepRight(stripsConfig, stripsStylesMap[WHITE_STRIP])),
        cmpViewProps = calcRenderProps({
            isWorkspace: false,
            component,
            componentDependencies
        }),
        payload = { style: component.style, sectionOrBlockId: 'blank', height: BLANK_SECTION_HEIGHT, key: 'blank_section' },
        view = View({
            ...cmpViewProps,
            className: cx(sectionStyle.strip, sectionStyle.blankSection)
        });
    return (<div>
        <VerticalSpacer y={20} />
        <div className={sectionStyle.blankSectionContainer}>
            <DroppableCmpShortcut {...props} kind={SectionKind} payload={payload}>
                {view}
                <div className={sectionStyle.blankLabel}>Blank section</div>
            </DroppableCmpShortcut>
        </div>
    </div>);
};

// @ts-ignore
const SectionsBlocks = connect(mapStateToProps)(injectIntl((props) => {
    const { sectionsBlocksState, intl, dispatch } = props;
    if (sectionsBlocksState.hasError) {
        return ([
            <div key={0}>
                <BlankSection {...props} />
            </div>,
            <SectionBlockError key={1}>
                <p>
                    {/* eslint-disable max-len */}
                    {intl.msgJoint('msg: extendedPanel.sectionFetchError {We are having trouble loading the sections. Please reload your page and try again.}')}
                </p>
                <VerticalSpacer y={20} />
                <div>
                    <PrimaryButton
                        onClick={() => dispatch({ type: LOCATION_RELOAD })}
                        {...noMouseEventPropagation}
                    >
                        {intl.msgJoint('msg: common.reloadPage {Reload page}')}
                    </PrimaryButton>
                </div>
            </SectionBlockError>
        ]);
    }

    return props.sectionsBlocksState.listLoaded ?
        <div className={styles.panelWithFilter}>
            {props.filterKey === ALL_FILTER_KEY && <BlankSection {...props} />}
            <SectionBlockCmp {...props} />
        </div>
        :
        <LoadingIndicator className={styles.loadingIndicator} />;
}));

const HeaderView = connect(mapStateToProps)(Header);

export default {
    kind: SectionsKind,
    shortCut: {
        iconClassName: Icon.sections,
        text: Sections
    },
    extendedPanelData: {
        headerHeight: sectionTitleHeight,
        title: <HeaderView />,
        content: (props: ExtendedPanelContentProps) => <SectionsBlocks {...props} />
    }
};
