import { TRACK_RESERVED_CONTENT_NOT_IN_WEBSITE } from "../../../../Workspace/epics/saveStatus/actionTypes";
import { RESERVED_CONTENT_TRACKER, USER_PUBLISHED_TRACKER, WORKSPACE_READY_TRACKER } from "./events";
import { sendGTMEvent } from "../../../../App/actionCreators/googleTagManager";
import { WSB_GTM_EVENT } from "../../../events";
import type { EventParamType } from "../../../flowTypes";
import { WORKSPACE_READY } from "../../../../Workspace/actionTypes";
import { USER_PUBLISH_ACTION_SUCCESSFUL } from "../../../../TopBar/actionTypes";

const trackEventCheckReservedWords = (params: EventParamType) => sendGTMEvent({
    event: WSB_GTM_EVENT,
    eventCategory: RESERVED_CONTENT_TRACKER.CATEGORY,
    ...params,
});

const trackEventUserEnteredWorkspace = (params: EventParamType) => sendGTMEvent({
    event: WSB_GTM_EVENT,
    eventCategory: WORKSPACE_READY_TRACKER.CATEGORY,
    ...params
});

const trackEventUserPublished = (params: EventParamType) => sendGTMEvent({
    event: WSB_GTM_EVENT,
    eventCategory: USER_PUBLISHED_TRACKER.CATEGORY,
    ...params,
});

export const WorkspaceGTMUpdaters = [
    {
        conditions: [
            TRACK_RESERVED_CONTENT_NOT_IN_WEBSITE
        ],
                reducer: ({ state }) => ({
            state,
            actionToDispatch: trackEventCheckReservedWords(
                {
                    eventAction: RESERVED_CONTENT_TRACKER.ACTION,
                    eventLabel: RESERVED_CONTENT_TRACKER.LABEL
                }
            )
        })
    },
    {
        conditions: [
            WORKSPACE_READY
        ],
                reducer: ({ state }) => ({
            state,
            actionToDispatch: trackEventUserEnteredWorkspace(
                {
                    eventAction: WORKSPACE_READY_TRACKER.ACTION,
                    eventLabel: WORKSPACE_READY_TRACKER.LABEL
                }
            )
        })
    },
    {
        conditions: [
            USER_PUBLISH_ACTION_SUCCESSFUL
        ],
        reducer: ({ state }) => ({
            state,
            actionToDispatch: trackEventUserPublished({
                eventAction: USER_PUBLISHED_TRACKER.ACTION,
                eventLabel: USER_PUBLISHED_TRACKER.LABEL
            })
        })
    }
];
