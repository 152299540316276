import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { injectIntl, Intl } from "../../../../view/intl/index";
import { makeEpicStateSelector } from "../../../../epics/makeEpic";
import MODERN_LAYOUT_LEFT_PANEL_EPIC_VALUE from '../../epics/leftPanel/valueActionType';
import mhfOldDataVAT from '../../epics/oldData/valueActionType';
import * as styles from './ModernLayoutLeftPanel.css';
import { ModernLayoutModeOn } from './screens/ModernLayoutModeOn';
import { ModernLayoutRestore } from './screens/RestoreLayouts';
import type { LeftPanelEpicState, NewLayoutDataPerSection } from '../../flowTypes';
import { ComponentsEvalValueActionType } from "../../../Workspace/epics/componentsEval/valueActionType";
import { headerSectionIdVAT } from '../../../oneweb/Section/epics/headerSectionIdEpic';
import { footerSectionIdVAT } from '../../../oneweb/Section/epics/footerSectionIdEpic';
import { stylesheetAppSel } from "../../../Workspace/epics/stylesheets/selectors";
import { globalVariablesEpic } from '../../../App/epics/globalVariablesEpic';
import type { Stylesheets } from "../../../Workspace/epics/stylesheets/flowTypes";
import workspaceTemplateVAT from "../../../oneweb/Template/epics/template/valueActionType";
import type { TemplateComponent } from "../../../oneweb/Template/flowTypes";
import NoMouseEventsPropagation from "../../../../view/common/NoMouseEventsPropagation";
import webShopMHFValueActionType from "../../../oneweb/WebShopMHF/epic/valueActionType";
import type { WebShopMHFEpicState } from "../../../oneweb/WebShopMHF/flowTypes";

type Props = {
    intl: Intl,
    dispatch: Dispatch,
    leftPanel: LeftPanelEpicState,
    template: TemplateComponent,
    componentsEval: Record<string, any>,
    headerSectionId: string,
    footerSectionId: string,
    globalVariables: Record<string, any>,
    styleSheets: Stylesheets,
    MHFStoredLayoutsData: NewLayoutDataPerSection,
    webshopMHFData: WebShopMHFEpicState,
};
const ModernLayoutLeftPanel = (props: Props) => {
    const {
        intl,
        dispatch,
        leftPanel,
        template,
        componentsEval,
        headerSectionId,
        footerSectionId,
        globalVariables,
        styleSheets,
        MHFStoredLayoutsData,
        webshopMHFData,
    } = props;
    const headerSection = componentsEval.state.componentsMap[headerSectionId],
        footerSection = componentsEval.state.componentsMap[footerSectionId],
        { show: showLeftPanel, showRestoreLayouts } = leftPanel;

    if (!showLeftPanel) {
        return null;
    }

    let View = <ModernLayoutModeOn
        leftPanel={leftPanel}
        intl={intl}
        dispatch={dispatch}
        headerSection={headerSection}
        footerSection={footerSection}
        template={template}
        componentsEval={componentsEval}
        globalVariables={globalVariables}
        MHFStoredLayoutsData={MHFStoredLayoutsData}
        webshopMHFData={webshopMHFData}
        styleSheets={styleSheets}
    />;
    if (showRestoreLayouts) {
        View = <ModernLayoutRestore
            intl={intl}
            dispatch={dispatch}
        />;
    }

    return (
        <div className={cx(styles.modernLayoutLeftPanel, styles.active, styles.fullHeight)} {...NoMouseEventsPropagation}>
            {View}
        </div>
    );
};

export const
    leftPanelDataSelector = makeEpicStateSelector(MODERN_LAYOUT_LEFT_PANEL_EPIC_VALUE);

const mapStateToProps = (appState) => {
    const leftPanel = leftPanelDataSelector(appState),
        { newData } = makeEpicStateSelector(mhfOldDataVAT)(appState);
    return {
        leftPanel,
        template: makeEpicStateSelector(workspaceTemplateVAT)(appState),
        MHFStoredLayoutsData: leftPanel.isFooter ? newData.footer : newData.header,
        componentsEval: makeEpicStateSelector(ComponentsEvalValueActionType)(appState),
        headerSectionId: makeEpicStateSelector(headerSectionIdVAT)(appState),
        footerSectionId: makeEpicStateSelector(footerSectionIdVAT)(appState),
        styleSheets: stylesheetAppSel(appState),
        globalVariables: makeEpicStateSelector(globalVariablesEpic.valueActionType)(appState),
        webshopMHFData: makeEpicStateSelector(webShopMHFValueActionType)(appState),
    };
};
export default injectIntl(connect(mapStateToProps)(ModernLayoutLeftPanel));
