import bowser from 'bowser';

const key: string = 'hideBrowserZoomWarning';
const { safari } = bowser;
const safariTreshold = 0.12;

const getWindowPixelRatio = (): number => {
    let pixelRatio;
    if (safari) {
        pixelRatio = window.outerWidth / window.innerWidth;
    } else {
        pixelRatio = window.devicePixelRatio;
    }
    return Math.round(pixelRatio * 100) / 100;
};

let defaultPixelRatio: number = getWindowPixelRatio();

export const isBrowserZoomEnabled = (): boolean => {
    const pixelRatio = getWindowPixelRatio();
    if (safari) {
        return Math.abs(pixelRatio - defaultPixelRatio) > safariTreshold;
    }
    // If devicePixelRatio is same as on page load, don't show message
    return defaultPixelRatio !== pixelRatio;
};

export const isBrowserZoomedIn = (): boolean => {
    const pixelRatio = getWindowPixelRatio();
    return pixelRatio > defaultPixelRatio;
};

export const isHideBrowserWarning = (): boolean => {
    return window.sessionStorage.getItem(key) === 'true';
};

export const setHideBrowserWarning = (val: string = 'true'): void => {
    window.sessionStorage.setItem(key, val);
};

