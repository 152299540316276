import CALL_API from '../../../redux/middleware/api/CALL_API';
import type { ApiAction } from "../../../redux/middleware/api/flowTypes";

import { siteDataBackupLoadActionTypes, siteDataLoadActionTypes } from '../actionTypes';

const loadSiteData = (time?: any): ApiAction => {
    return {
        [CALL_API]: {
            types: time ? siteDataBackupLoadActionTypes : siteDataLoadActionTypes,
            endpoint: 'getSiteData',
            endpointParams: { time }
        }
    };
};

export {
    loadSiteData as default,
    loadSiteData
};
