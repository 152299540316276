
import { clone } from "ramda";
import type { Background } from "../../../mappers/background/flowTypes";
import * as cssBackgroundMapper from "../../../mappers/background/index";
import { getQualityAssetUrlParam } from "../Image/utils";
import { scrollEffects } from "../commonView/ScrollEffects/constants";
import type { ThemeBackgroundType, ThemeColorDataType, ThemeColorTypes } from '../../ThemeGlobalData/flowTypes';
import { getThemeRulesForBackground } from "../../ThemeGlobalData/themeRules";
import { processBackgroundForThemeColorAndGradient } from "../Background/makeStyle";
import { Color } from "../../../mappers/flowTypes";

type Params = {
    bgStyle: null | undefined | Background,
    selectedTheme: ThemeBackgroundType|null,
    selectedGradientTheme: ThemeColorTypes|null,
    panelWidth: number,
    topBarHeight: number,
    bottomDragStripHeight: number,
    autoColorMode: boolean,
    themeColorsData: ThemeColorDataType
};

const
    calcDeltaPlus = (main, delta) => `calc(${main} + ${delta}px)`,
    calcDeltaMinus = (main, delta) => `calc(${main} - ${delta}px)`;

export const getTemplateBackgroundCssStyle = (
    {
        bgStyle,
        selectedTheme,
        selectedGradientTheme,
        panelWidth,
        topBarHeight,
        bottomDragStripHeight,
        autoColorMode,
        themeColorsData
    }: Params
): null | undefined | Record<string, any> => {
    if (!bgStyle) return null;

    let finalBgStyle = clone(bgStyle);
    let cssOptions = {};
    if (autoColorMode && selectedTheme) {
        const
            backgroundColorFromTheme = themeColorsData[getThemeRulesForBackground(selectedTheme, themeColorsData).background],
            backgroundGradientColorFromTheme = selectedGradientTheme ? themeColorsData[selectedGradientTheme] as Color : undefined;
        if (backgroundColorFromTheme) {
            finalBgStyle = processBackgroundForThemeColorAndGradient(
                finalBgStyle,
                backgroundColorFromTheme,
                backgroundGradientColorFromTheme
            );
        }
    }

    // fix background-position with respect to leftPanel width / topBar height
    const { assetData } = finalBgStyle;
    if (assetData && assetData.position) {
        let position = assetData.position;
        if (assetData.scrollEffect && assetData.scrollEffect === scrollEffects.reveal) {
            let [left, top] = position;
            if (left !== '100%') {
                const delta = left === '0%' ? panelWidth : panelWidth / 2;
                left = calcDeltaPlus(left, delta);
            }
            if (top !== '100%') {
                const delta = top === '0%' ? topBarHeight : topBarHeight / 2;
                top = calcDeltaPlus(top, delta);
            }
            position = [left, top];
        } else if (position[1] === '100%') {
            position = [position[0], calcDeltaMinus(position[1], bottomDragStripHeight)];
        }
        finalBgStyle = { ...finalBgStyle, assetData: { ...assetData, position } };
    }
    if (assetData && assetData.asset) {
        // @ts-ignore
        cssOptions.assetUrlQuery = getQualityAssetUrlParam(assetData.asset, 80);
    }

    return cssBackgroundMapper.toCss(finalBgStyle, cssOptions);
};
