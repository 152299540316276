import { replaceWithSiteSettingsDialog } from '../../../SiteSettings/SiteSettingsDialog/actionCreators';
import { SiteSettingsTabName } from '../../../SiteSettings/SiteSettingsDialog/constants';
import { ENABLE_CHAT_WIDGET_TOGGLE } from '../../../SiteSettings/chatWidgetTabData/actionTypes';

export const openChatWidgetInSiteSettings = (dispatch: Dispatch, chatWidgetToggle: boolean = true, openSiteSettings: boolean = true) => {
    dispatch({ type: ENABLE_CHAT_WIDGET_TOGGLE, payload: chatWidgetToggle });
    if (openSiteSettings) {
        dispatch(replaceWithSiteSettingsDialog({ activeTabKey: SiteSettingsTabName.MESSENGER_CHAT }));
    }
};
