
const currentSectionPath = ['sectionsState', 'currentSection'];
const sectionDataLoadingPath = ['sectionsState', 'sectionDataLoading'];
const sectionGenerationLimitReachedPath = ['sectionsState', 'limitReached'];
const getSectionPath = (sectionId) => ['sectionsState', sectionId];
const getCurrentVersionsPath = (sectionId) => [...getSectionPath(sectionId), 'currentVersion'];
const getIsLastVersionPath = (sectionId) => [...getSectionPath(sectionId), 'isLastVersion'];
const getIsFirstVersionPath = (sectionId) => [...getSectionPath(sectionId), 'isFirstVersion'];
const getSectionCmpIdsPath = (sectionId) => [...getSectionPath(sectionId), 'textComponentIds'];
const getAllVersionsPath = (sectionId) => [...getSectionPath(sectionId), 'versions'];
const getCmpsHtmlPath = (sectionId, version = 0) => [...getSectionPath(sectionId), 'versions', version, 'rawHtml'];
const getCmpsDataPath = (sectionId, version = 0) => [...getSectionPath(sectionId), 'versions', version, 'cmpData'];

const DynamicPreviewPaths = {
    currentSectionPath,
    getSectionPath,
    getCurrentVersionsPath,
    getSectionCmpIdsPath,
    getCmpsHtmlPath,
    getCmpsDataPath,
    sectionDataLoadingPath,
    getIsLastVersionPath,
    getAllVersionsPath,
    getIsFirstVersionPath,
    sectionGenerationLimitReachedPath,
};

export {
    DynamicPreviewPaths
};
