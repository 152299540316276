import { getUpdatedEpicsState } from './updateManager';

type Selector = (state: any) => AnyValue

export default (epicReducer: any, valueActionType: string, selector: Selector = epicState => epicState.state) =>
    (state: any, action: any) => {
        if (state === undefined) {
            return selector(epicReducer(undefined, action));
        } else if (action.type === valueActionType) {
            return selector(action.payload);
        } else if (action.epicsUpdate && action.epicsUpdate.updatedEpicsTypes[valueActionType]) {
            const newEpicsState = getUpdatedEpicsState(action);
            return selector(newEpicsState[valueActionType].state);
        }

        return state;
    };
