import React from 'react';
import { Msg } from '../../../../view/intl/index';
import { SecondaryButton } from "../../../../view/common/Button/index";
import styles from "../ProductSelector/components/ProductSelector.css";

type Props = {
    selectedProductsCount: number,
    deSelectAll: () => void
};

const DialogFooterContent = ({
    selectedProductsCount,
    deSelectAll
}: Props) => {
    const children: React.JSX.Element[] = [];
    if (selectedProductsCount) {
        children.push(
            <SecondaryButton
                className={styles.dialogFooterDeselectAllBtn}
                onClick={deSelectAll}
                isIntl
                key={1}
            >
                {`msg: component.webshop.featuredProducts.deselectAll {Deselect all}`}
            </SecondaryButton>
        );

        children.push(
            <Msg
                k="component.webshop.featuredProducts.selectedCountInfo"
                params={{ selectedCount: selectedProductsCount }}
                key={2}
                className={styles.countText}
            >
                {`{selectedCount, plural, one {# product selected} other {# products selected}}`}
            </Msg>
        );
    }

    return (
        <React.Fragment>
            { children }
        </React.Fragment>
    );
};

export default DialogFooterContent;
