import * as React from 'react';
import cx from 'classnames';
import styles from './TabDialog.css';
import { Dialog, DialogBody, DialogFooter } from '../baseDialog/index';
import { Tab, Tabs } from "./Tabs";
import injectIntl from "../../../intl/injectIntl";

class TabDialogWithFullLeftPanelClass extends Tabs {
    render() {
        const {
                mctaText,
                mctaHandler,
                sctaHandler,
                onClose,
                disabled,
                footer,
                containerClassName,
                stripType,
                primaryBtnClass,
                footerStyles
            } = this.props,
            activeTab = this.getActiveTab();

        return (
            <Dialog
                stripType={stripType}
                containerClassName={containerClassName}
                onClose={onClose}
                className={styles.dialog}
            >
                {super.renderPanel()}
                <DialogBody className={cx(styles.container, styles.containerForFullLeftPanel)}>
                    {super.renderTabBody()}
                    <DialogFooter
                        primaryBtnClass={primaryBtnClass}
                        mctaText={mctaText}
                        mctaHandler={() => {
                            mctaHandler();
                        }}
                        sctaHandler={sctaHandler}
                        disabled={disabled}
                        className={footerStyles}
                    >
                        <div className={styles.footer}>{activeTab.props.footer || footer || null}</div>
                    </DialogFooter>
                </DialogBody>
            </Dialog>
        );
    }
}
const TabDialogWithFullLeftPanel = injectIntl(TabDialogWithFullLeftPanelClass);

export { TabDialogWithFullLeftPanel, Tab };
