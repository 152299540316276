import { getCookie } from '../src/services/cookie';
import { AUTH_COOKIE_KEY } from '../src/components/App/epics/auth/constants';
import getLogin from '../src/components/App/epics/auth/utils/getLogin';

const getLoginData = () => {
    const authCookie = getCookie(AUTH_COOKIE_KEY);
    return authCookie && getLogin(authCookie);
};

const getLoginDomain = () => {
    const login = getLoginData();
    return login && login.domain;
};

const getLoginEmail = () => {
    const login = getLoginData();
    return login && login.email;
};

export {
    getLoginDomain,
    getLoginEmail,
};
