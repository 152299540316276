import { path } from 'ramda';
import { ReceiveOnlyComponentsMap } from "../../../Workspace/epics/componentsEval/selectorActionTypes";
import { makeEpic } from "../../../../epics/makeEpic";
import { UNDO_INITIAL_STATE } from "../../../../epics/undoManager/updateReasons";
import { PAGE_DATA_LOAD_SUCCESS } from "../../../PagesTree/actionTypes";
import { HEADER_SHARE_BG_IMG_WITH_HEADER_TOGGLE } from "../actionTypes";
import {
    topMostPageSectionBackgroundImageIsSetEpic
} from './topMostPageSectionBackgroundImageIsSetEpic';
import {
    ACTIVATE_MODERN_HEADER,
    MODERN_HEADER_DEACTIVATED,
    SWITCH_MODERN_HEADER
} from "../../../ModernLayouts/actionTypes";
import { getHeaderSection } from "../utils";
import { withSelector } from '../../../../epics/makeCondition';

const getShareBgImageAfterModernLayout = (componentsMap: Record<string, any>) => {
    const { modernLayout } = getHeaderSection(componentsMap);
    if (modernLayout) {
        const layoutProp = path(['sectionProps', 'new', modernLayout.layoutId], modernLayout);
        return layoutProp ? !!layoutProp.shareSectionBgImg : false;
    }
    return false;
};

const isModernHeaderActivated = (componentsMap: Record<string, any>) => {
    const headerSection = getHeaderSection(componentsMap);
    return !!path(['modernLayout', 'active'], headerSection);
};

export const SHARE_BG_IMG_OF_HEADER_AND_FIRST_SECTION_VAT = "SHARE_BG_IMG_OF_HEADER_AND_FIRST_SECTION",
    SharedBackgroundForHeaderEnabledSelector =
    withSelector(SHARE_BG_IMG_OF_HEADER_AND_FIRST_SECTION_VAT, state => state.enabled);

export type ShareHeaderAndFirstSectionBgImgEpicState = {
    enabled: boolean,
    offsetTop: number,
    enabledBeforeModernLayout: boolean,
    enabledInModernLayout: boolean,
}

const defaultState: ShareHeaderAndFirstSectionBgImgEpicState = {
    enabled: false,
    offsetTop: 0, // TODO WBTGEN-16805 remove once all customer migratied to have consistent data without overlaps,
    enabledBeforeModernLayout: false,
    enabledInModernLayout: false,
};

export const shareHeaderAndFirstSectionBgImgEpic = makeEpic({
    undo: true,
    defaultState,
    defaultScope: {},
    valueActionType: SHARE_BG_IMG_OF_HEADER_AND_FIRST_SECTION_VAT,
    updaters: [
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                PAGE_DATA_LOAD_SUCCESS
            ],
            reducer: ({ values: [componentsMap, pageData], scope }) => {
                const isModernSection = isModernHeaderActivated(componentsMap);
                let updatedState = {
                    offsetTop: pageData.page.shareBgImgOffsetTop || 0,
                    enabledBeforeModernLayout: !!pageData.page.shareHeaderAndFirstSectionBgImg,
                    enabled: !!pageData.page.shareHeaderAndFirstSectionBgImg,
                    enabledInModernLayout: !!pageData.page.shareModernHeaderAndFirstSectionBgImg
                };

                if (isModernSection) {
                    updatedState.enabled = !!pageData.page.shareModernHeaderAndFirstSectionBgImg;
                }

                return {
                    state: updatedState,
                    scope,
                    updateReason: UNDO_INITIAL_STATE
                };
            }
        },
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                HEADER_SHARE_BG_IMG_WITH_HEADER_TOGGLE
            ],
            reducer: ({ values: [componentsMap], state, scope }) => {
                const isModernSection = isModernHeaderActivated(componentsMap);

                if (isModernSection) {
                    return {
                        state: {
                            ...state,
                            enabled: !state.enabledInModernLayout,
                            enabledInModernLayout: !state.enabledInModernLayout,
                        },
                        scope,
                        updateReason: HEADER_SHARE_BG_IMG_WITH_HEADER_TOGGLE
                    };
                }

                return {
                    state: {
                        ...state,
                        enabled: !state.enabled,
                        enabledBeforeModernLayout: !state.enabled,
                        offsetTop: 0
                    },
                    scope,
                    updateReason: HEADER_SHARE_BG_IMG_WITH_HEADER_TOGGLE
                };
            }
        },
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                SWITCH_MODERN_HEADER
            ],
            reducer: ({ values: [componentsMap], state, scope }) => {
                const enabled = getShareBgImageAfterModernLayout(componentsMap);
                return {
                    state: {
                        ...state,
                        enabled,
                        enabledInModernLayout: enabled,
                        offsetTop: 0
                    },
                    scope,
                    updateReason: SWITCH_MODERN_HEADER
                };
            }
        },
        {
            conditions: [ACTIVATE_MODERN_HEADER],
            reducer: ({ state, scope }) => {
                return {
                    state: {
                        ...state,
                        enabled: state.enabledInModernLayout,
                        enabledInModernLayout: state.enabledInModernLayout,
                        offsetTop: 0
                    },
                    scope,
                    updateReason: ACTIVATE_MODERN_HEADER
                };
            }
        },
        {
            conditions: [MODERN_HEADER_DEACTIVATED],
            reducer: ({ state, scope }) => {
                return {
                    state: {
                        ...state,
                        enabled: state.enabledBeforeModernLayout,
                        enabledBeforeModernLayout: state.enabledBeforeModernLayout,
                        enabledInModernLayout: state.enabled,
                        offsetTop: 0
                    },
                    scope,
                    updateReason: MODERN_HEADER_DEACTIVATED
                };
            }
        },
        {
            conditions: [topMostPageSectionBackgroundImageIsSetEpic.valueActionType],
            reducer: ({ state, scope, values: [topMostPageSectionBackgroundImageIsSet] }) => {
                if (topMostPageSectionBackgroundImageIsSet) {
                    if (!scope.topMostPageSectionBackgroundImageWasSet) {
                        return { state, scope: { ...scope, topMostPageSectionBackgroundImageWasSet: true } };
                    }
                } else if (scope.topMostPageSectionBackgroundImageWasSet) {
                    return {
                        state: {
                            ...state,
                            enabled: false,
                            enabledBeforeModernLayout: false,
                            enabledInModernLayout: false,
                            offsetTop: 0
                        },
                        scope: { ...scope, topMostPageSectionBackgroundImageWasSet: false },
                        updateReason: 'topMostPageSectionBackgroundImageWasIsFalse'
                    };
                }

                return {
                    state,
                    scope
                };
            }
        }
    ]
});
