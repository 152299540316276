import React from "react";
import layouts from "./layouts/index";
import { filterHiddenPagesRecursive, makeSetCurrentPageIdAndHasChildren, makeSetPageNamesFromGlobalVariables } from "../utils";
import { selectPagesFromLevel } from "../selectors";
import kind from "../kind";
import { getTestingWithScreenshotPreview } from "../../constants";
import type { Props, LayoutRenderProps } from "../flowTypes";
import "../flowTypes";

const Menu = ({
    currentPageId,
    siteData,
    component,
    componentExtension,
    componentId,
    dispatch,
    isWorkspace,
    isShadowRender,
    previewBackupTime,
    stylesheetsIdToNameMap,
    globalVariables,
    testingWithScreenshots,
    selectedParentTheme,
    computedStyles,
    themeSettingsData,
    menuState
}: Props) => {
    if (testingWithScreenshots) {
        return getTestingWithScreenshotPreview(kind);
    }

    const {
            folder: { items }
        } = siteData,
        isMenuCartAllowed = !!menuState?.isMenuCartAllowed,
        Layout = layouts[component.layoutType],
        preparePages = makeSetCurrentPageIdAndHasChildren(currentPageId),
        topLevelPages = preparePages(items),
        autoColorMode = themeSettingsData && themeSettingsData.autoColorMode;
    let pages = selectPagesFromLevel({
        pages: topLevelPages,
        level: component.startLevel
    });
    pages = filterHiddenPagesRecursive(pages);
    pages = makeSetPageNamesFromGlobalVariables(globalVariables, siteData)(pages);
    const layoutRenderProps: LayoutRenderProps = {
        topLevelPages,
        pages,
        component: { ...component, moreText: globalVariables[component.moreText] || component.moreText },
        componentExtension,
        componentId,
        dispatch,
        isWorkspace,
        isShadowRender,
        previewBackupTime,
        stylesheetsIdToNameMap,
        selectedParentTheme,
        autoColorMode,
        isMenuCartAllowed,
        computedStyles
    };
    return <Layout {...layoutRenderProps} />;
};

export default Menu;
