import React from 'react';
import { connect } from 'react-redux';
import cx from "classnames";
import { Box, FormControl } from "@mui/material";

import { PlaceholderInput, SettingsInputLabel } from '../../../../SettingsControls';

import { getDomainNameToRenderForBeautifulURL } from "../../../../../../../../wbtgen/src/components/SiteSettings/publishConfig/utils";
import { selectDomain } from '../../../../../common/selectors';

import imageStyles from "../../../../Images/imageStyle.css";
import { useStyles } from "../styles";

import type { URLPreviewSectionProps } from "../types";

export const URLPreviewSectionComponent = (props: URLPreviewSectionProps) => {
    const
        styles = useStyles(),
        { intl, publishConfig: { publishWithSSL, dropHtmlExtension }, domain } = props,
        domainToRender = getDomainNameToRenderForBeautifulURL(domain, publishWithSSL, dropHtmlExtension);

    let content;

    if (publishWithSSL) {
        content = (
            <div>
                <span className={cx(styles.icon, imageStyles.greyLock)} />
                <span className={cx(styles.label, styles.labelBlue)}>https</span>
                <span className={cx(styles.label, styles.labelGrey)}>://{domainToRender}/contact</span>
                {!dropHtmlExtension && <span className={cx(styles.label, styles.labelBlue)}>.html</span>}
            </div>
        );
    } else {
        content = (
            <div>
                <span className={cx(styles.icon, imageStyles.greyInfo)} />
                <span className={cx(styles.label, styles.labelGrey)}>
                    {intl.msgJoint("msg: securityTab.notSecure {Not Secure}")}
                </span>
                <span className={styles.bordered}>&nbsp;</span>
                <span className={cx(styles.label, styles.labelGrey)}>http</span>
                <span className={cx(styles.label, styles.labelGrey)}>://{domainToRender}/contact</span>
                {!dropHtmlExtension && <span className={cx(styles.label, styles.labelBlue)}>.html</span>}
            </div>
        );
    }

    return (
        <Box mt={{ md: '36px' }}>
            <FormControl fullWidth>
                <SettingsInputLabel>
                    {intl.msgJoint('msg: socialShare.preview {Preview}')}
                </SettingsInputLabel>
                <PlaceholderInput>
                    {content}
                </PlaceholderInput>
            </FormControl>
        </Box>
    );
};

const mapStateToProps = (state) => ({
    domain: selectDomain(state),
});

export const URLPreviewSection = connect(mapStateToProps)(URLPreviewSectionComponent);
