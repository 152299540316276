import {
    GallerySquareType,
    GalleryRoundType,
    GalleryCaptionsType,
    GalleryRoundedType,
} from "./kind";
import { IMAGE_RATIO } from "../../presentational/ImageRatioComboBox/constants";

const TypePropsConfig = {
    [GallerySquareType]: {
        imageRatio: IMAGE_RATIO.SQUARE,
        stretch: true
    },
    [GalleryRoundType]: {
        imageRatio: IMAGE_RATIO.SQUARE,
        imageStyle: {
            border: {
                corners: [999, 999, 999, 999]
            }
        }
    },
    [GalleryCaptionsType]: {
        captionsEnabled: true,
    },
    [GalleryRoundedType]: {
        stretch: true,
        imageStyle: {
            border: {
                corners: [25, 25, 25, 25]
            }
        }
    }
};

export default TypePropsConfig;
