import CodeLocationTypes from "../../../../src/components/oneweb/Code/locationTypes";
import { OldComponentTypes } from "../../componentTypesMap";
import type { AnyComponentData, AnyRelation } from "../../../../src/components/App/flowTypes";

export default (component: AnyComponentData, relation?: AnyRelation): null | undefined | AnyRelation => {
    return (relation && component.type === OldComponentTypes.CODE &&
        // @ts-ignore
        (CodeLocationTypes.BeforeClosingHead === component.location ||
            // @ts-ignore
            CodeLocationTypes.BeforeClosingBody === component.location)
    ) ? null : relation;
};
