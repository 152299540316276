import React from "react";
import Box from "@mui/material/Box";
import { Theme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';

import { WidgetContainer, WidgetTitle, WidgetDescription, WidgetButtons } from "../../Widgets";
import { AnalyticsWidgetIllustration } from "../common/AnalyticsWidgetIllustration";
import { PublishButton } from "../../../../Publish/PublishButton/PublishButton";

const useStyles = makeStyles(
    (theme: Theme) => ({
        actionButton: {
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                width: "auto",
            },
        },
    }),
    { name: "AnalyticsWidgetPublish" }
);

export const AnalyticsWidgetPublish = () => {
    const classes = useStyles();

    return (
        <WidgetContainer>
            <AnalyticsWidgetIllustration />

            <Box alignSelf="stretch" height="18px" />

            <WidgetTitle title="msg: analyticsTab.banner.title {Don’t see any analytics?}" />

            <Box alignSelf="stretch" height="15px" />

            <WidgetDescription
                description="msg: analyticsTab.banner.description {You’ll need to publish your site for analytics to show up. After publishing, it can take a little while for them to show up but check back again soon.}"
            />

            <Box alignSelf="stretch" height="26px" />

            <WidgetButtons>
                <PublishButton classes={classes.actionButton} />
            </WidgetButtons>
        </WidgetContainer>
    );
};
