import makeEpic from '../../../../../epics/makeEpic';
import makeValueActionType from './makeValueActionType';
import stylesheetsEpicValueActionType from '../valueActionType';
import TextGlobalStyleType from '../../../../oneweb/Text/globalStyle/kind';
import * as stylesheetSelectors from '../selectors';

export default (type: string) => makeEpic({
    valueActionType: makeValueActionType(type),
    updaters: [
        {
            conditions: [stylesheetsEpicValueActionType],
            reducer: ({ values: [stylesheets] }) => ({
                state: type === TextGlobalStyleType
                    ? stylesheetSelectors.textNormalGlobalstyleId(stylesheets)
                    : stylesheetSelectors.getFirstStylesheetIdByType(type)(stylesheets)
            })
        }
    ]
});
