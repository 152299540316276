import type { SideBarAccordianDef } from '../../SideBar/types';
import imageStyles from '../../Images/imageStyle.css';

import { ConsentBannerSideBarItem } from '../ConsentBanner';
import { ConnectionsSideBarItem } from '../Connections';
import { GeneralSideBarItem } from "./General";
import { SecuritySideBarItem } from "./Security";
import { PrivacyPolicyItem } from '../PrivacyPolicy';

export const WEBSITE_SETTINGS_PAGE_SIDE_BAR_ITEM_ID = 'WEBSITE_SETTINGS_PAGE_SIDE_BAR_ITEM_ID';

export const WebsiteSettingsSideBarItem: SideBarAccordianDef = {
    id: WEBSITE_SETTINGS_PAGE_SIDE_BAR_ITEM_ID,
    icon: imageStyles.websiteSettings,
    title: 'msg: publish.websiteSettings {Website settings}',
    subItems: [
        GeneralSideBarItem,
        ConsentBannerSideBarItem,
        ConnectionsSideBarItem,
        SecuritySideBarItem,
        PrivacyPolicyItem
    ],
};
