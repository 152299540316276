import makeEpic from "../../../../epics/makeEpic";
import {
    getComponentsTitlesByPageAndKind,
    CHECK_COMPONENT_TITLE_SUCCESS,
    CHECK_COMPONENT_TITLE_FAILURE,
    LOAD_SECTION_COMPONENTS_FOR_PAGE,
} from "../actions";
import { receiveOnly } from "../../../../epics/makeCondition";
import { ReceiveOnlyComponentsMap } from "../../../Workspace/epics/componentsEval/selectorActionTypes";
import currentPageIdValueActionType from '../../../App/epics/currentPageId/valueActionType';
import { siteDataValueActionType } from "../../../App/epics/siteData/valueActionType";
import getComponentsMap from '../../../Workspace/epics/componentsEval/getComponentsMap';
import { initializeSectionTitles } from '../../../Workspace/epics/componentsEval/getSectionTitles';
import { getSectionsOrderdByTop } from "../../../oneweb/Section/utils";
import isStretchComponentKind from "../../../oneweb/isStretchComponentKind";
import type { AnyComponent } from "../../../oneweb/flowTypes";

export const SECTION_COMPONENTS_EPIC_VAT = 'SECTION_COMPONENTS_EPIC_VAT';

type Section = {
    label: string,
    value: string,
    kind: string,
}

export type PageSections = {
    [id: string]: Array<Section>
}

export type SectionComponents = {
    pageSections: PageSections,
    isLoading: boolean,
    pageOptions: Array<Section>
}

const getStateWithUpdateSections = (state, componentsMap, pageId, currPageCmpsMap?) => {
    const cmpNamesMap = {};
    if (currPageCmpsMap) {
        getSectionsOrderdByTop(currPageCmpsMap)
            .forEach(cmp => { cmpNamesMap[cmp.id] = cmp.title; });
    }
    const components: Array<AnyComponent> = Object.values(componentsMap);
    const sections = components
        .filter(cmp => isStretchComponentKind(cmp.kind) && cmp.title)
        .sort((a, b) => a.top - b.top)
        .map(cmp => ({
            label: cmpNamesMap[cmp.id] || cmp.title,
            value: cmp.id,
            kind: cmp.kind
        }));
    return {
        state: {
            ...state,
            pageSections: {
                ...state.pageSections,
                [pageId]: sections
            },
            isLoading: false
        }
    };
};

export const sectionComponentsEpic = makeEpic({
    defaultState: {
        pageSections: {
        },
        isLoading: false
    },
    valueActionType: SECTION_COMPONENTS_EPIC_VAT,
    updaters: [
        {
            conditions: [
                LOAD_SECTION_COMPONENTS_FOR_PAGE,
                ReceiveOnlyComponentsMap,
                receiveOnly(currentPageIdValueActionType)
            ],
            reducer: ({ state, values: [pageId, componentsMap, currentPageId] }) => {
                if (pageId && pageId !== currentPageId) {
                    return {
                        state: {
                            ...state,
                            isLoading: true
                        },
                        actionToDispatch: getComponentsTitlesByPageAndKind(pageId)
                    };
                }
                return getStateWithUpdateSections(state, componentsMap, currentPageId);
            }
        },
        {
            conditions: [
                CHECK_COMPONENT_TITLE_SUCCESS,
                ReceiveOnlyComponentsMap,
                receiveOnly(siteDataValueActionType)
            ],
            reducer: ({ state, values: [pageDataset, componentsMap, site] }) => {
                const pageId = pageDataset.page.id;
                // TODO: Change logic to get correct section ids from other pages
                return getStateWithUpdateSections(
                    state,
                    initializeSectionTitles(getComponentsMap(site, pageDataset).componentsMap),
                    pageId,
                    componentsMap
                );
            }
        },
        {
            conditions: [
                CHECK_COMPONENT_TITLE_FAILURE,
            ],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        isLoading: false
                    }
                };
            }
        },
        {
            conditions: [
                siteDataValueActionType
            ],
            reducer: ({ values: [siteData], state }) => {
                return {
                    state: {
                        ...state,
                        pageOptions: siteData.getAllPageRefs()
                            .map(page => {
                                return { label: page.name, value: page.pageId };
                            })
                    }
                };
            }
        },
    ]
});
