import { PostType, Keyword } from '../constants';
import { isFacebookLinkValid } from './linkValidator';
import { parseFacebookPostCode } from "./parseFacebookPostCode";
import { getWidgetDimensionsByPostType } from './index';
import { type FacebookComponent } from '../types';

export const getFacebookPostType = (code: string): string => {
    if (code.includes(Keyword.WATCH) || code.includes(Keyword.VIDEOS)) {
        return PostType.VIDEO;
    }
    if (code.includes(Keyword.PHOTOS) || code.includes(Keyword.POSTS)) {
        return PostType.POST;
    }
    return '';
};

export const parseFacebookCode = (code: string): FacebookComponent => {
    let params = <FacebookComponent>{};
    if (code.length) {
        if (isFacebookLinkValid(code)) {
            params.link = code.replace(/\/$/, '');
            params.postType = getFacebookPostType(code);
        } else {
            params = parseFacebookPostCode(code);
        }
    }

    return params.link ? { ...getWidgetDimensionsByPostType(params.postType), ...params, } : {};
};
