import React from 'react';
import { Flex } from '../../../../../view/reflexbox/index';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import Page from "../../../../PropertiesPanel/view/PropertiesPage";
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';
import { TextHorizontalAlignmentButtonsGroup } from '../../../../presentational/IconButtonsGroup/TextAlignmentButtonGroup/index'; // eslint-disable-line max-len
import * as actionTypes from '../../actionTypes';
import * as pageIds from '../pageIds';
import pagesTitles from '../pagesTitles';
import { ExtraInfoStyle } from '../../constants';
import type { PageProps } from '../../../../PropertiesPanel/flowTypes';
import type { ShareButtonsComponent } from '../../flowTypes';

const
    id = pageIds.ALIGNMENT,
    title = pagesTitles[id],
    extraInfoAlignmentValue = 'justify',
    view = ({ selectedComponent: { styleInfo, align }, dispatch }: PageProps<ShareButtonsComponent>) => {
        const extraInfoStyle = styleInfo === ExtraInfoStyle;
        return (
            <Page>
                <VerticalSpacer />
                <PropertyContainer label="msg: component.imageSlider.pp.captions.alignment {Alignment:}">
                    <Flex>
                        <TextHorizontalAlignmentButtonsGroup
                            disabled={extraInfoStyle}
                            value={extraInfoStyle ? extraInfoAlignmentValue : align}
                            onBtnClick={alignment => dispatch({
                                type: actionTypes.SHAREBUTTONS_ALIGNMENT_CHANGED,
                                payload: alignment
                            })}
                            isPropertiesPanel
                        />
                    </Flex>
                </PropertyContainer>
            </Page>
        );
    };

export { id, title, view };
