import type { Page, PagesConfig } from '../flowTypes';

function makePages(...pages: Array<Page>): PagesConfig {
    return pages.reduce((result, { id, view, title, TitleEltInNavigation }) => ({
        ...result,
        [id]: {
            content: view,
            title,
            TitleEltInNavigation
        }
    }), {});
}

export default makePages;
