import { svgTextToSvgJsonSync } from './utils';
import { EMPTY_SVG } from '../../../../../server/shared/constants';

export const DEFAULT_HEIGHT = 100;
export const DEFAULT_WIDTH = 100;

export const defaultState = {
    // TODO: put some svg asset here, just like how we have one in the image component
    asset: { url: '' },
    colors: [],
    defaultFillColor: '',
    linkAction: null,
    mobileDown: false,
    opacity: 100,
    rawSvg: EMPTY_SVG,
    seo: '',
    svgJson: svgTextToSvgJsonSync(EMPTY_SVG),
    useOriginalColors: false,
};
