import getId from "../../getId";
import { cmpTypes } from "../../constants";
import getCmpTypeById from "../../getCmpTypeById";
import type { Relations, Groups } from "../../flowTypes";
import type { ComponentsMap } from "../../../../redux/modules/children/workspace/flowTypes";

export default (relations: Relations, componentsMap: ComponentsMap, groups: Groups) => {
    const isInTemplate = (id ?: string) => {
        if (!id) return false;
        const cmpType = getCmpTypeById(id);
        if (cmpType === cmpTypes.group && groups[id]) {
            return componentsMap[groups[id][0]].inTemplate;
        }
        return componentsMap[getId(id)] ? componentsMap[getId(id)].inTemplate : false;
    };

    return relations.map(r => {
        return { ...r, isTemplate: (isInTemplate(r.id) && (isInTemplate(r.top) || isInTemplate(r.bottom))) };
    });
};
