import * as R from 'ramda';
import { styleBackgroundAsset } from "../../../../mappers/path";
import p from "../../../../utils/pipePath";
import { isBoxKind } from "../../componentKinds";
import { BackgroundComponent } from '../flowTypes';

const MIN_WIDTH = 50, MIN_HEIGHT = 15;

const isBoxWithNoAssetOrSmallSize = (component: BackgroundComponent, isAssetStyleBg: boolean = false) => {
    const { kind, width, height } = component;
    let asset;
    if (isAssetStyleBg) {
        asset = R.path(p(styleBackgroundAsset))(component);
    } else {
        asset = component.asset;
    }
    if (!isBoxKind(kind)) {
        return false;
    }
    if (!asset || !asset.url) {
        return true;
    }
    if (width <= MIN_WIDTH || height <= MIN_HEIGHT) {
        return true;
    }
    return false;
};

export default isBoxWithNoAssetOrSmallSize;
