import React from "react";
export const SvgPinterestSquareFillDark = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <path fill="#3c3c3c" d="M0 0h48v48H0z" />
            <path
                fill="#ffffff"
                d="M24.008 12.5C17.66 12.5 12.5 17.66 12.5 24.008c0 6.347 5.16 11.508 11.508 11.508 6.347 0 11.508-5.16 11.508-11.508 0-6.347-5.16-11.508-11.508-11.508zm0 21.478c-1.03 0-2.019-.157-2.954-.445.405-.66 1.016-1.748 1.241-2.611l.62-2.37c.324.62 1.277 1.147 2.284 1.147 3.007 0 5.174-2.765 5.174-6.204 0-3.295-2.688-5.758-6.145-5.758-4.302 0-6.585 2.89-6.585 6.033 0 1.46.777 3.281 2.022 3.861.19.09.288.05.333-.135.032-.14.202-.814.279-1.133a.307.307 0 00-.068-.287c-.413-.5-.741-1.42-.741-2.275 0-2.198 1.663-4.329 4.5-4.329 2.45 0 4.162 1.668 4.162 4.055 0 2.697-1.362 4.567-3.133 4.567-.98 0-1.713-.81-1.475-1.803.284-1.186.827-2.463.827-3.317 0-.764-.409-1.403-1.263-1.403-1.002 0-1.802 1.034-1.802 2.423 0 .881.296 1.48.296 1.48s-.989 4.175-1.168 4.953c-.203.863-.122 2.072-.036 2.859a9.958 9.958 0 01-6.339-9.278c0-5.507 4.464-9.97 9.97-9.97 5.507 0 9.971 4.463 9.971 9.97 0 5.506-4.464 9.97-9.97 9.97z"
            />
        </g>
    </svg>
);
