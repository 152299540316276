// @ts-nocheck
import * as R from 'ramda';
import * as fontFamilyMapper from "../../../../../../dal/pageMapAdapter/mappers/Base/fontFamily";
import { GOOGLE_PREFIX } from "../../../../../components/presentational/AddGoogleFont/constants";
import { INDENT_WIDTH } from '../../constants';
import makeStyleMapper from './makeStyleMapper';
import {
    toSuperscriptFontSize
} from "../../../../../components/App/epics/tinyMceEpic/editorUtils/utils/helpers/convertSuperscriptFontSize";

const pixelMapper = (val) => `${val}px`;

export const bold = makeStyleMapper('font-weight', (val) => (val ? 'bold' : 'normal'));

export const italic = makeStyleMapper('font-style', (val) => (val ? 'italic' : 'normal'));

export const underline = makeStyleMapper('text-decoration', (val) => (val ? 'underline' : 'none'));

export const color = makeStyleMapper('color');

export const font = makeStyleMapper('font-family', (val) => {
    const fontFamily = fontFamilyMapper.to(val);

    // For falsy values of fontFamily, we should still render a valid font value
    if (fontFamily) {
        return fontFamily.replace(GOOGLE_PREFIX, '');
    } else {
        return 'inherit';
    }
});

export const align = makeStyleMapper('text-align');

export const height = makeStyleMapper('line-height');

export const highlight = makeStyleMapper('background-color');

export const bullet = makeStyleMapper('list-style-type');

export const indent = makeStyleMapper('padding-left', R.pipe((val) => (`${val * INDENT_WIDTH}`), pixelMapper));

export const paddingTop = makeStyleMapper('padding-top', pixelMapper);

export const paddingRight = makeStyleMapper('padding-right', pixelMapper);

export const paddingBottom = makeStyleMapper('padding-bottom', pixelMapper);

export const paddingLeft = makeStyleMapper('padding-left', pixelMapper);

// letter-spacing is 1:10 of the letterSpacing value from style
export const letterspacing = makeStyleMapper('letter-spacing', (val) => (val === 0 ? 'normal' : ((val / 10) + 'em')));

export const shadow = makeStyleMapper('text-shadow', (val) => (val || 'none'));

export const size = makeStyleMapper('font-size',
    R.pipe(
        (val, { superscript, subscript }) =>
            ((superscript || subscript) ? toSuperscriptFontSize(val) : val),
        pixelMapper
    ));
