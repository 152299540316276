import { pipe } from 'ramda';
import { applyMappers, makeMapper } from "../../utils";
import type { AnyComponentData } from "../../../../src/components/App/flowTypes";
import type { SvgComponent } from "../../../../src/components/oneweb/Svg/flowTypes";
import { svgTextToSvgJsonSync } from '../../../../src/components/oneweb/Svg/utils';

const keys = {
        asset: 'asset',
        colors: 'colors',
        defaultFillColor: 'defaultFillColor',
        linkAction: 'linkAction',
        mobileDown: 'mobileDown',
        mobileHide: 'mobileHide',
        opacity: 'opacity',
        seo: 'seo',
        widthInMobile: 'widthInMobile',
        rawSvg: 'rawSvg',
        useOriginalColors: 'useOriginalColors',
    },
    plainPropsMapper = makeMapper(keys);

export const to = (componentData: AnyComponentData): SvgComponent => applyMappers(
    componentData,
    pipe(
        plainPropsMapper,
        (component) => {
            return { ...component,
                svgJson: svgTextToSvgJsonSync(component.rawSvg, { readThroughStyleAttributes: true }) };
        }
    )
);

export const back = (component: SvgComponent): AnyComponentData => applyMappers(
    component,
    pipe(
        plainPropsMapper,
    )
);
