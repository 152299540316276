export const
    DefaultFourFields = [0, 0, 0, 0] as Tuple4<number>,
    DefaultFourFieldsLocked = true,
    FourFieldsCorners = {
        TOP_LEFT: 'topLeft',
        TOP_RIGHT: 'topRight',
        BOTTOM_RIGHT: 'bottomRight',
        BOTTOM_LEFT: 'bottomLeft'
    } as const,
    FourFieldsDefaultValueRenderMap = {
        [FourFieldsCorners.TOP_LEFT]: 0,
        [FourFieldsCorners.TOP_RIGHT]: 1,
        [FourFieldsCorners.BOTTOM_RIGHT]: 2,
        [FourFieldsCorners.BOTTOM_LEFT]: 3
    };

