import { WINDOW_RESIZED } from "../../actionTypes";

export type BrowserDimensions = {
    browserWidth: number,
    browserHeight: number
}

export const defaultState = {} as BrowserDimensions;

export default (state: BrowserDimensions, action: any): BrowserDimensions => {
    if (state === undefined) return defaultState;

    if (action.type === WINDOW_RESIZED) {
        const { payload: { width, height } } = action;
        return {
            browserWidth: width,
            browserHeight: height
        };
    }

    return state;
};
