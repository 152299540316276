import React, { CSSProperties } from "react";
import cx from "classnames";
import { THEME_BUTTON_CLASS } from '../../../ThemeGlobalData/constants';
import type { ButtonComponentUIProps } from '../flowTypes';

export const buttonViewFactory = (additionalProps: Record<string, any>): React.ElementType => ({
    text,
    style,
    linkProps,
    globalStyleClass = '',
    styles = {},
    themeStyle,
    autoColorMode,
    selectedParentTheme,
    buttonThemeSelected,
}: ButtonComponentUIProps) => {
    return <div
        className={cx(
            styles.container,
            styles.buttonTransition,
            globalStyleClass,
            autoColorMode && [selectedParentTheme, buttonThemeSelected, THEME_BUTTON_CLASS],
        )}
        style={themeStyle}
    >
        <div {...additionalProps} className={styles.buttonComponent}>
            <a {...linkProps} className={styles.textContainer}>
                <span style={style as CSSProperties}>{text}</span>
            </a>
        </div>
    </div>;
};

export default buttonViewFactory({});
