import { ADD_MESSAGE, DELETE_MESSAGE, AUTO_DELETE_MESSAGE, MODIFY_HTML_MESSAGE_CONTENT, DELETE_MESSAGE_WITH_KEY } from './actionTypes';
import type { ToasterMessage } from "./flowTypes";
import MessageTypes from './MessageTypes';

export const
    addMessage = (message: ToasterMessage) => ({
        type: ADD_MESSAGE,
        payload: message
    }),
    addInfoMessage = (msgKey: string, text: string, source ?: string,
        msgHtml?: null|React.ReactElement, msgStyles?: Record<string, any>, closeStyles?: Record<string, any>, permanent?: boolean) => ({
        type: ADD_MESSAGE,
        payload: {
            type: MessageTypes.INFO,
            msgKey,
            text,
            source,
            msgHtml,
            msgStyles,
            closeStyles,
            permanent
        }
    }),
    addHtmlToasterMessage = (msgHtml?: null|React.ReactElement, source?: string, msgStyles?: Record<string, any>,
        closeStyles?: Record<string, any>, permanent?: boolean, type?: string) => ({
        type: ADD_MESSAGE,
        payload: {
            type,
            source,
            msgHtml,
            msgStyles,
            closeStyles,
            permanent
        }
    }),
    addHtmlToasterMessageWithKey = (msgHtml?: null|React.ReactElement, source?: string, msgStyles?: Record<string, any>,
        closeStyles?: Record<string, any>, permanent?: boolean, type?: string, msgKey?: string) => ({
        type: ADD_MESSAGE,
        payload: {
            type,
            source,
            msgHtml,
            msgStyles,
            closeStyles,
            permanent,
            msgKey
        }
    }),
    modifyHtmlToasterMessage = (msgKey: string, modifiedDataList: any) => ({
        type: MODIFY_HTML_MESSAGE_CONTENT,
        payload: {
            msgKey,
            modifiedDataList
        }
    }),
    addIntlMessage = (msgType: string, msgKey: string, defaultMsg: string, params: any) => ({
        type: ADD_MESSAGE,
        payload: { type: msgType, msgKey, text: defaultMsg, params, useIntl: true }
    }),
    deleteMessage = (index: number) => ({
        type: DELETE_MESSAGE,
        payload: index
    }),
    deleteMessageWithKey = (msgKey: string) => ({
        type: DELETE_MESSAGE_WITH_KEY,
        payload: msgKey
    }),
    autoDeleteMessage = (timeout: number) => ({
        type: AUTO_DELETE_MESSAGE,
        payload: timeout
    });
