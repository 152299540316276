import * as PagesIds from './ids';
import CONTACT_FORM from "../../kind";
import { ComponentNames } from "../../../constants";

export default {
    [PagesIds.MAIN]: ComponentNames[CONTACT_FORM],
    [PagesIds.MARKETING_CONSENT]: "msg: component.contactForm.marketingConsent {Marketing Consent}",
    [PagesIds.STYLE]: "msg: component.contactForm.style {Style}",
    [PagesIds.BUTTONS]: "msg: component.contactForm.buttons {Buttons}",
    [PagesIds.EMAILSETTINGS]: "msg: component.contactForm.emailSettings {Email Settings}",
    [PagesIds.SUCCESSMESSAGE]: "msg: component.contactForm.successMessageTitle {Success message}",
    [PagesIds.CAPTCHA]: "msg: component.contactForm.captcha {Captcha}"
};
