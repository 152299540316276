import { documentComponentDefaultState } from "./documentComponentDefaultState";
import kind from "../kind";
import {
    makeCombineReducer,
    makeComponentBaseReducers,
    makeDefaultStateReducers
} from "../../../../redux/makeReducer/index";
import type { DocumentComponent } from "../flowTypes";
import { UPDATE_DOCUMENT_CONFIG_ACTION } from "../actions";
import Resource from "../../../../redux/modules/children/fileChooser/Resource";
import {
    TOGGLE_MOBILE_DOWN_ACTION
} from "../../../PropertiesPanel/view/MobileView/actions";
import mobileDownReducer from "../../../PropertiesPanel/view/MobileView/mobileDownReducer";

export const documentComponentReducer = makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(kind),
        ...makeDefaultStateReducers({
            ...documentComponentDefaultState
        })
    },
    handleActions: {
        [UPDATE_DOCUMENT_CONFIG_ACTION]: (state: DocumentComponent, action: AnyAction): DocumentComponent => {
            const
                { payload } = action,
                nextSrc = payload instanceof Resource
                    ? payload.getWebSpaceProtocolRelativePath()
                    : payload.value;

            return {
                ...state,
                settings: {
                    src: nextSrc
                }
            };
        },
        [TOGGLE_MOBILE_DOWN_ACTION]: mobileDownReducer
    }
});
