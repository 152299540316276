
import React from 'react';
import type { TemplatePageViewProps } from '../flowTypes';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import * as pagesIds from './ids';
import pagesTitles from './titles';
import { PREMIUM_TEMPLATE_CATEGORY, TemplateCategory } from '../../constants';
import { PREMIUM_TEMPLATE_CATEGORY_NAME, TemplateCategoryName } from '../../messages';
import CheckBox from '../../../../../view/common/CheckBox/PropertiesCheckBox';
import { toggleTemplateCategoryAction } from '../../actionTypes';

const TemplateCategoriesPage = (props: TemplatePageViewProps) => {
    const
        { selectedComponent: { categories }, dispatch } = props,
        ids = Object.values(TemplateCategory).concat(PREMIUM_TEMPLATE_CATEGORY),
        names = { ...TemplateCategoryName, [PREMIUM_TEMPLATE_CATEGORY]: PREMIUM_TEMPLATE_CATEGORY_NAME };

    return (
        <Page>
            {ids.map(tid => {
                const isChecked = categories.indexOf(tid) > -1;

                return (
                    <CheckBox
                        // $FlowFixMe
                        label={names[tid]}
                        isChecked={isChecked}
                        onClick={() => dispatch(toggleTemplateCategoryAction(tid))}
                        // $FlowFixMe
                        key={tid}
                    />
                );
            })}
        </Page>
    );
};

const
    id = pagesIds.CATEGORIES,
    title = pagesTitles[pagesIds.CATEGORIES],
    view = TemplateCategoriesPage;

export { id, title, view };
