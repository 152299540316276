import type { AppStore } from '../../src/redux/modules/flowTypes';
import {
    LOGIN_FROM_DEMO_ACTION,
    DEMO_BUY_BUTTON_CLICK_ACTION,
    DEMO_TRIAL_ENDED_SELECT_PLAN_ACTION,
    INITIATE_DEMO_DOMAIN_AVAILABILITY_ACTION,
    demoBuyGoalsTrackerAction,
    setTrialDomainAvailableAction,
    clearAvailableTrialDomainAction,
} from './actions';
import {
    navigateToBlankLocationAction,
    replaceLocationAction
} from '../../src/redux/middleware/window/actionCreators';
import {
    trialUserSubIDSel,
    trialUserTokenSel,
    demoLoginAppSel,
    trialDomainNameForPurchaseSel,
    trialWsbPackageUpgradeTypeSel,
    subscriptionMetadataSel
} from './selectors';
import {
    DEMO_LOGOUT_SUCCESS_ACTION,
    DEMO_DOMAIN_AVAILABILITY_FAILURE_ACTION,
    DEMO_DOMAIN_AVAILABILITY_SUCCESS_ACTION,
    demoDomainAvailabilityAction,
} from "../actions";
import { showSignInPage } from "./login/signUp/actions";
import { isMobileDevice } from "./login/validators";
import { showMobileBlockingPage } from "./login/mobileBlocking/actions";
import { websiteTitleStateSel } from "../../src/components/SiteSettings/General/selectors";
import { normalizeWebsiteTitleToDomainName } from "../../../server/shared/utils/normalizeWebsiteTitleToDomainName";
import { getTrialAuth } from "../../../trial/services/trialAuth";
import { getAppConfig } from "../../src/components/App/epics/appConfig/appConfig";
import { makeBuyTrialRegularUrl, makeBuyTrialUrl } from "../utils/makeBuyTrialUrl";
import { readSelectedPublicTemplate } from "../../src/components/TemplateSelector/public/selectedPublicTemplate";
import { showSignUpPage } from "./login/signIn/actions";
import { unsetManyCookies } from "../../src/services/cookie";
import { SsoTrialCookie } from "../../../server/shared/trial/SsoTrialCookie";
import { TrialImproCookieKey } from "../../../server/shared/trial/TrialImproCookieKey";
import { getPackageNameFromMetadata } from "../../src/components/ComponentTierManager/utils";

/*** Main ***/

// let domainAvailabilityActionScheduled = false;

export const demoMiddleware = (store: AppStore) => (next: Dispatch) => (action: Action) => {
    if (action.type === DEMO_LOGOUT_SUCCESS_ACTION) {
        const showPageAction = readSelectedPublicTemplate() ? showSignUpPage() : showSignInPage();

        let redirectPage = (isMobileDevice() ? showMobileBlockingPage() : showPageAction);
        window.sessionStorage.clear();
        store.dispatch(redirectPage);

        unsetManyCookies([
            SsoTrialCookie.ACCESS_TOKEN,
            SsoTrialCookie.ID_TOKEN,
            SsoTrialCookie.REFRESH_TOKEN,
            SsoTrialCookie.USERNAME,
        ], {}, true);

        unsetManyCookies([
            TrialImproCookieKey.TOKEN,
            TrialImproCookieKey.ACCESS_KEY,
            TrialImproCookieKey.SECRET_KEY,
            TrialImproCookieKey.SESSION_ID,
        ], {}, true);

        window.location.reload();
        return;
    }

    if (action.type === LOGIN_FROM_DEMO_ACTION) {
        // eslint-disable-next-line consistent-return
        return store.dispatch(replaceLocationAction('https://login.one.com/cp'));
    }

    if (action.type === DEMO_BUY_BUTTON_CLICK_ACTION) {
        const purchaseDomain = trialDomainNameForPurchaseSel(store.getState());
        const wsbUpgradeType = trialWsbPackageUpgradeTypeSel(store.getState());
        const subscriptionMetadata = subscriptionMetadataSel(store.getState());
        makeBuyUrl(purchaseDomain, wsbUpgradeType, subscriptionMetadata).then(trialBuyLinkUrl => {
            store.dispatch(demoBuyGoalsTrackerAction());
            return store.dispatch(navigateToBlankLocationAction(trialBuyLinkUrl)); // eslint-disable-line consistent-return
        });
    }

    if (action.type === DEMO_TRIAL_ENDED_SELECT_PLAN_ACTION) {
        let loginState = demoLoginAppSel(store.getState());
        let trialEndedDetails = loginState.signin.userTrialEndedDetails;
        const wsbUpgradeType = trialWsbPackageUpgradeTypeSel(store.getState());
        const subscriptionMetadata = subscriptionMetadataSel(store.getState());
        let trialBuyLink = createPartnerBuyLink(trialEndedDetails.trialId, wsbUpgradeType, subscriptionMetadata);
        if (!trialBuyLink) {
            trialBuyLink = makeBuyTrialRegularUrl(trialEndedDetails.trialId, trialEndedDetails.token);
        }
        store.dispatch(navigateToBlankLocationAction(trialBuyLink));
    }

    // Commented out as per: WBTGEN-9121
    // if (
    //     (action.type === ONBOARDING_STATE_UPDATED ||
    //     action.type === GENERAL_INFO_WEBSITE_TITLE_INPUT_CHANGED)
    // ) {
    //     if (!domainAvailabilityActionScheduled) {
    //         store.dispatch(
    //             createScheduledAction({
    //                 actionToDispatch: initiateDemoDomainAvailabilityAction(),
    //                 timeout: 5 * 1000 // Time in milli seconds
    //             })
    //         );
    //         domainAvailabilityActionScheduled = true;
    //     }
    // }

    if (action.type === INITIATE_DEMO_DOMAIN_AVAILABILITY_ACTION) {
        const storeState = store.getState();
        const websiteTitle = websiteTitleStateSel(storeState);

        if (websiteTitle) {
            const requiredDomainNameToBuy = normalizeWebsiteTitleToDomainName(websiteTitle);
            const trialId = trialUserSubIDSel(storeState);
            const token = trialUserTokenSel(storeState);

            store.dispatch(demoDomainAvailabilityAction(requiredDomainNameToBuy, trialId, token));

            // Commented out as per: WBTGEN-9121
            // Refresh the data for domain availability every hour
            // store.dispatch(
            //     createScheduledAction({
            //         actionToDispatch: initiateDemoDomainAvailabilityAction(),
            //         timeout: 60 * 60 * 1000 // Time in milli seconds
            //     })
            // );
        }
        // domainAvailabilityActionScheduled = false;
    }

    if (action.type === DEMO_DOMAIN_AVAILABILITY_SUCCESS_ACTION) {
        const { domainName } = action.payload;
        store.dispatch(setTrialDomainAvailableAction(domainName));
    }

    if (action.type === DEMO_DOMAIN_AVAILABILITY_FAILURE_ACTION) {
        store.dispatch(clearAvailableTrialDomainAction());
    }

    // eslint-disable-next-line consistent-return
    return next(action);
};

/*** Lib ***/

function createPartnerBuyLink(trialId, wsbUpgradeType, subscriptionMetadata) {
    let partnerPlanBuyLink = "";
    const partnerBuyNowUrl = getAppConfig().oneWeb.trial.partnerBuyNowUrl;
    if (partnerBuyNowUrl) {
        if (!trialId) {
            throw new Error('Unable to retrieve user trialId');
        }
        const partnerPackageName = getPackageNameFromMetadata(wsbUpgradeType, subscriptionMetadata);
        partnerPlanBuyLink = partnerBuyNowUrl.replace('{trialId}', trialId).replace('{packageName}', partnerPackageName);
    }

    return partnerPlanBuyLink;
}

function makeBuyUrl(purchaseDomain, wsbUpgradeType, subscriptionMetadata) {
    return getTrialAuth().getUserInfo().then(user => {
        const partnerBuyLink = createPartnerBuyLink(user.attributes.sub, wsbUpgradeType, subscriptionMetadata);

        if (partnerBuyLink) {
            return partnerBuyLink;
        }
        const cpUpgradeUrl = getAppConfig().oneWeb.trial.cpUpgradeUrl;
        return makeBuyTrialUrl(user, cpUpgradeUrl, purchaseDomain, wsbUpgradeType);
    });
}
