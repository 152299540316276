import type { ButtonComponent } from './flowTypes';

export default (component: ButtonComponent, isBtnInModernLayout: boolean): Record<string, any> => {
    const
        style: Record<string, any> = {},
        localFontSize = component.style.text.size;

    if (localFontSize) {
        style.fontSize = localFontSize;
    }
    if (isBtnInModernLayout) {
        style.whiteSpace = 'no-wrap';
        style.overflow = 'hidden';
        style.textOverflow = 'ellipsis';
    }

    return style;
};
