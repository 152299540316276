import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { Switch } from '../../Switch';

const useStyles = makeStyles({
    campaignEnabledSwitchLabel: {
        '& .MuiFormControlLabel-label': {
            fontSize: '15px !important',
            lineHeight: '24px !important',
            fontWeight: 400
        }
    },
});

type Props = {
    enabled: boolean;
    handleChange: Function;
};

export const CampaignStatusSwitch = (props: Props) => {
    const classes = useStyles();

    return (
        <Switch
            checked={props.enabled}
            width={44}
            height={24}
            label="msg: dashboard.marketing.googleAds.campaignEnabled {Campaign enabled}"
            formControlLabelClass={classes.campaignEnabledSwitchLabel}
            handleChange={props.handleChange}
        />
    );
};
