import React from 'react';
import { errorDialogStandardHeight, errorDialogStandardWidth } from '../../../dialogs/constants';
import styles from '../FileChooserUpload.css';
import WarningDialog from '../../../dialogs/WarningDialog/index';
import injectIntl from "../../../../intl/injectIntl";
import ErrorDialogFileList from './ErrorDialogFileList';
import getCenteredDialogConfig from "../../../../../components/DialogManager/getCenteredDialogConfig";

const
    width = errorDialogStandardWidth,
    height = errorDialogStandardHeight,
    title = 'msg: fc.uploadFailedDialog.err.svgSize.title {Invalid SVG size}',
    error = 'msg: fc.uploadFailedDialog.err.svgSize.error {Sorry, this SVG is too big. Please keep the file size below {maxSvgFileSize} MB.}', // eslint-disable-line
    mctaText = 'msg: common.ok {OK}';

type Props = { fileNames, maxSvgFileSize, intl: Intl }

const ImageSizeErrorDialog = injectIntl(({ fileNames, maxSvgFileSize, intl }: Props) => {
    return (
        <WarningDialog title={title} mctaText={mctaText}>
            <div className={styles.labelSmall}>
                <span className={styles.bold}>{intl.msgJoint([error, { maxSvgFileSize }])}</span>
                <ErrorDialogFileList fileNames={fileNames} />
            </div>
        </WarningDialog>
    );
});

export default getCenteredDialogConfig(width, height, ImageSizeErrorDialog);
