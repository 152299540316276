import React from "react";
import Page from "../../../../PropertiesPanel/view/PropertiesPage";
import FontFamily from "../../../../presentational/FontFamily/index";
import ColorProperty from "../../../../presentational/ColorProperty/index";
import * as actionTypes from "../../actionTypes";
import injectIntl from "../../../../../view/intl/injectIntl";
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import PropertyContainer from "../../../../../view/common/PropertyContainer/index";
import * as pagesIds from "./ids";
import pagesTitles from "./titles";
import type { PropertiesPanelStyleViewProps } from "../../flowTypes";
import addGoogleFontView from "../../../../PropertiesPanel/view/AddGoogleFont/index";
import Slider from "../../../../../view/common/Slider/PPSlider";
import WebShopComponentKind from "../../kind";
import { THEME_COLOR_PICKER } from "../../../../ColorPicker/constants";
import { getThemeRulesForWebshop } from "../../../../ThemeGlobalData/themeRules";
import { getThemeColorsFromStylesheet } from "../../../../Workspace/epics/stylesheets/selectors";

const id: any = pagesIds.STYLE,
    title = pagesTitles[pagesIds.STYLE],
    view = injectIntl(
        ({
            dispatch,
            selectedComponent,
            siteFonts,
            selectedComponentProps,
            globalStyles,
            themeSettingsData: { autoColorMode },
            // @ts-ignore
            navigateToPage
        }: PropertiesPanelStyleViewProps) => {
            const themeColorsData = getThemeColorsFromStylesheet(globalStyles);
            const { component, selectedParentTheme } = selectedComponentProps;
            const { isSingleProduct } = component;

            return (
                <Page key="style">
                    <VerticalSpacer />
                    <PropertyContainer label="msg: component.webshop.font {Font}">
                        <FontFamily
                            selected={selectedComponent.font}
                            siteFonts={siteFonts}
                            onChangeAction={actionTypes.FEATURED_PRODUCTS_FONT_FAMILY_CHANGED}
                            onAddFontClicked={() => navigateToPage(addGoogleFontView.id, { source: WebShopComponentKind })}
                            isPropertiesPanel
                        />
                    </PropertyContainer>
                    <VerticalSpacer />
                    { autoColorMode
                        ? [
                            <ColorProperty
                                key="text-color"
                                colorPickerKind={THEME_COLOR_PICKER}
                                themeColorsData={themeColorsData}
                                themeColor={selectedComponent.fontColorTheme}
                                defaultThemeColor={getThemeRulesForWebshop(selectedParentTheme, themeColorsData).fontColorName}
                                label="msg: component.webshop.textColor {Text colour}"
                                onChangeAction={actionTypes.FEATURED_PRODUCTS_TEXT_COLOR_CHANGED_AUTO_COLOR}
                                dispatch={dispatch}
                            />,
                            <VerticalSpacer />,
                            <ColorProperty
                                key="accent-color"
                                colorPickerKind={THEME_COLOR_PICKER}
                                themeColorsData={themeColorsData}
                                themeColor={selectedComponent.focusColorTheme}
                                defaultThemeColor={getThemeRulesForWebshop(selectedParentTheme, themeColorsData).focusColorName}
                                label="msg: component.webshop.accentColor {Accent colour}"
                                onChangeAction={actionTypes.FEATURED_PRODUCTS_FOCUS_COLOR_CHANGED_AUTO_COLOR}
                                dispatch={dispatch}
                            />
                        ]
                        : [
                            <ColorProperty
                                key="text-color"
                                onRemoveAction=""
                                label="msg: component.webshop.textColor {Text colour}"
                                color={selectedComponent.fontColor}
                                onChangeAction={actionTypes.FEATURED_PRODUCTS_TEXT_COLOR_CHANGED}
                                dispatch={dispatch}
                            />,
                            <VerticalSpacer />,
                            <ColorProperty
                                key="accent-color"
                                onRemoveAction=""
                                label="msg: component.webshop.accentColor {Accent colour}"
                                color={selectedComponent.focusColor}
                                onChangeAction={actionTypes.FEATURED_PRODUCTS_FOCUS_COLOR_CHANGED}
                                dispatch={dispatch}
                            />
                        ]}
                    <VerticalSpacer />
                    {!isSingleProduct && (
                        <React.Fragment>
                            <VerticalSpacer />
                            <Slider
                                min={2}
                                max={5}
                                label="msg: webshop.ProductsPerRow {Products per row:}"
                                valueLabel={selectedComponent.productsPerRow}
                                value={selectedComponent.productsPerRow}
                                defaultValue={selectedComponent.productsPerRow}
                                onChange={value =>
                                    dispatch({
                                        type: actionTypes.FEATURED_PRODUCTS_PRODUCTS_PER_ROW_CHANGED,
                                        payload: value,
                                        amendToSelf: true
                                    })}
                            />
                            <VerticalSpacer />
                        </React.Fragment>
                    )}
                </Page>
            );
        }
    );

export { id, title, view };
