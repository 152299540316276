import makeEpic from "../../../epics/makeEpic";
import { WORKSPACE_READY } from "../../Workspace/actionTypes";
import { getAndClearRedirectSessionData } from "../../Marketgoo/utils";

export const RedirectReceiverVAT = "REDIRECT_RECEIVER_EPIC_VALUE";

export const redirectReceiverEpic = makeEpic({
    valueActionType: RedirectReceiverVAT,
    updaters: [{
        conditions: [WORKSPACE_READY],
        reducer: (): any => {
            const sessionData = getAndClearRedirectSessionData();
            return { state: sessionData };
        }
    }]
});
