import * as R from "ramda";
import * as path from "../../../mappers/path";
import { getAllStylesheets } from "../../Workspace/epics/stylesheets/selectors";
import { formatMessage } from "../../../view/intl/CurrentLocale";
import type { MenuComponent } from "./flowTypes";
import type { Stylesheets } from "../../Workspace/epics/stylesheets/flowTypes";

type DependsOn = {
    defaultStyleId: string;
    globalstyles: Stylesheets;
};
export default (component: MenuComponent, { defaultStyleId, globalstyles }: DependsOn): MenuComponent => {
    const menuGlobalId = R.path(path.styleGlobalId, component),
        submenuGlobalId = R.path(path.subMenuStyleGlobalId, component),
        hasMatchingMenuGlobalStyle = R.pipe(
            getAllStylesheets,
            R.any(({ id }) => id === menuGlobalId)
        )(globalstyles),
        hasMatchingSubmenuGlobalStyle = R.pipe(
            getAllStylesheets,
            R.any(({ id }) => id === submenuGlobalId)
        )(globalstyles);
    // eslint-disable-line max-len
    return hasMatchingMenuGlobalStyle && hasMatchingSubmenuGlobalStyle
        ? component
        : R.pipe(
            R.assocPath(path.styleGlobalId, defaultStyleId),
            R.assocPath(path.subMenuStyleGlobalId, defaultStyleId),
            R.assoc(
                "moreText",
                formatMessage({
                    id: "common.More",
                    defaultMessage: "More"
                })
            )
        )(component);
};
