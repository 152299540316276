export const
    SeoPageTitleInTabOptions = {
        DEFAULT: 'default',
        CUSTOM: 'custom',
    };

// eslint-disable-next-line max-len
export const PAGE_NAME_TIP_MSG = "msg: pageNameInBrowserTabHelpTip {This name will only be used for the browser tab and search engines. To change the name in the website’s menu, use the field \"Page name in menu\" above.}";
// eslint-disable-next-line max-len
export const PAGE_TITLE_TIP_MSG = "msg: pageTitleInBrowserTabHelpTip {You can change the website title in settings via the burger menu in the top bar. The page name can be edited in the page name tab in the sidebar here.}";
