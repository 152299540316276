import makeEpic, { makeEpicStateSelector } from '../../../../epics/makeEpic';
import pageDatasetLoadedActionType from "../pageDataset/pageDatasetLoadedActionType";
import valueActionType from './valueActionType';
import { setLastPageId } from '../../../PagesTree/utils/lastPageId';
import { RESET_CURRENT_PAGE_ID } from "./actionTypes";
import DataPageSet from "../../../../../dal/model/DataPageSet";

type ReducerInput = { values: Tuple10Any };

const makeUpdater = (actionType, payloadSelector) => ({
    conditions: [actionType],
    reducer: ({ values: [payload] }: ReducerInput) => {
        const pageId = payloadSelector(payload);
        setLastPageId(pageId);
        return { state: pageId };
    }
});

const
    currentPageIdEpic = makeEpic({
        dispatchOutside: true,
        valueActionType,
        updaters: [
            makeUpdater(pageDatasetLoadedActionType, (pageDataSet: DataPageSet) => pageDataSet.page.id),
            makeUpdater(RESET_CURRENT_PAGE_ID, nextCurrentPageId => nextCurrentPageId)
        ]
    }),
    currentPageIdEpicStateFromAppStateSelector = makeEpicStateSelector(valueActionType);

export {
    currentPageIdEpic as default,
    currentPageIdEpicStateFromAppStateSelector
};
