import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(
    (theme) => ({
        logoSettingsFormContainer: {
            width: "calc(100% + 32px)",
            margin: "-16px",
            [theme.breakpoints.down("md")]: {
                width: "calc(100% + 24px)",
                margin: "-12px"
            },
            [theme.breakpoints.down("sm")]: {
                width: "calc(100% + 16px)",
                margin: "-8px"
            },
            "& > .MuiGrid-item": {
                padding: "16px",
                [theme.breakpoints.down("md")]: {
                    padding: "12px"
                },
                [theme.breakpoints.down("sm")]: {
                    padding: "8px"
                }
            }
        },
        imageWrapper: {
            display: "flex",
            height: "156px",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            border: `1px dashed ${theme.palette.custom.colorScienceBlue}`,
            background: theme.palette.custom.colorLavenderWeb,
            cursor: "pointer"
        },
        imageWrapperSelected: {
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            border: `1px solid ${theme.palette.custom.colorGray_3c}`,
            cursor: "pointer"
        },
        placeholderPreviewContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: theme.palette.custom.colorScienceBlue
        },
        imagePreviewText: {
            fontSize: "16px",
            lineHeight: "24px"
        },
        imagePreviewSupportText: {
            fontSize: "14px",
            lineHeight: "22px"
        },
        imagePreviewContainer: {
            display: "flex",
            position: "relative"
        },
        image: {
            width: "100%",
            height: "100%",
            objectFit: "contain"
        },
        imageHoverContainer: {
            position: "absolute",
            width: "100%",
            height: "100%"
        },
        imageCloseIcon: {
            display: "flex",
            position: "absolute",
            right: "8px",
            top: "8px",
            width: "24px",
            height: "24px"
        },
        imageChooserContainer: {
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                alignItems: "flex-start"
            }
        },
        imageChooserInput: {
            width: "100%",
            border: `1px solid ${theme.palette.custom.colorGray_bb}`,
            height: "46px",
            padding: "11px 52px 11px 20px",
            fontSize: "16px",
            lineHeight: "26px",
            cursor: "pointer",
            justifyContent: "left",
            fontWeight: 400,
            color: theme.palette.custom.colorScienceBlue
        },
        imageChooserInputSelected: {
            color: theme.palette.custom.colorGray_3c
        },
        inputLabelContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%"
        },
        inputLabel: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%",
            textAlign: "left",
            [theme.breakpoints.up("md")]: {
                maxWidth: "315px"
            }
        },
        closeIcon: {
            display: "flex",
            alignItems: "center",
            marginRight: "-34px"
        },
        imageChangeContainer: {
            whiteSpace: "nowrap",
            paddingLeft: "10px",
            [theme.breakpoints.down("sm")]: {
                paddingLeft: "0px",
                paddingTop: "10px"
            }
        },
        imageChangeLink: {
            fontSize: "15px"
        },
        imageChooserLabel: {
            fontSize: "14px",
            lineHeight: "22px",
            marginBottom: "4px"
        },
        imageChooserHint: {
            width: "100%",
            marginTop: "4px",
            color: theme.palette.custom.colorGray_8a,
            fontSize: "14px",
            lineHeight: "20px"
        },
        imageChooserHintWarning: {
            width: "100%",
            marginTop: "10px",
            fontSize: "14px",
            color: theme.palette.custom.colorRedEpiphyllum,
            lineHeight: "20px",
        },
        altTextLabelWrapper: {
            display: "flex",
            justifyContent: "space-between"
        },
        tooltip: {
            margin: "10px 0px",
            padding: "10px 14px 12px",
            width: "230px",
            fontSize: "16px",
            lineHeight: "22px",
            backgroundColor: theme.palette.custom.colorGray_3c,
            color: theme.palette.custom.colorWhite
        },
        tooltipArrow: {
            color: theme.palette.custom.colorGray_3c
        }
    }),
    { name: "GeneralLogoStyles" }
);
