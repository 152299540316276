import * as React from "react";
import cx from "classnames";
import getCenteredDialogConfig from "../../../components/DialogManager/getCenteredDialogConfig";
import { allInOneEpic } from "./allInOneEpic";
import { StripTypes } from "../../../view/common/dialogs/baseDialog/index";
import styles from "./allInOneDialog.css";
import { CLOSE_DIALOG } from "../../../redux/modules/actionTypes";
import { Tab, TabDialogWithFullLeftPanel } from "../../../view/common/dialogs/TabDialog/TabDialogWithFullLeftPanel";
import { AllInOneTabName, width, height } from "../constants";
import { GeneralTab } from "../general/generalTab";
import { ProvideJsonToDb } from "../provideJsonToDb/provideJsonToDb";
import * as actionTypes from "../actionTypes";
import { getCenterPositionForDialog } from "../../../components/DialogManager/utility";

type allInOneDataTyoe = {
    selectedTab: string
};

type Props = {
    dispatch: Dispatch,
    allInOneData: allInOneDataTyoe
};

class allInOneDialogComponent extends React.Component<Props> {
    onTabSwitch = (tab: any) => {
        this.props.dispatch({ type: actionTypes.ALL_IN_ONE_TAB_SWITCH, payload: { selectedTab: tab.props.id } });
    };

    render() {
        const {
            dispatch,
            allInOneData: { selectedTab }
        } = this.props;

        return (
            <TabDialogWithFullLeftPanel
                onTabSwitch={this.onTabSwitch}
                stripType={StripTypes.NONE}
                dispatch={dispatch}
                mctaHandler={() => dispatch({ type: actionTypes.ALL_IN_ONE_DONE_ACTION })}
                onClose={() => dispatch({ type: CLOSE_DIALOG })}
                mctaText="msg: common.done {Done}"
                primaryBtnClass={styles.mctaStyle}
                activeTabKey={selectedTab}
                containerClassName={cx(styles.container, styles.containerFlexDirRow)}
                tabBodyCustomStyle={styles.tabBody}
                tabHeadingCustomStyle={styles.tabHeading}
                panelHeaderText="All In One"
                panelStyle={styles.panelStyle}
                panelItemStyle={styles.panelItem}
                panelItemActiveStyle={styles.panelItemActive}
            >
                <Tab
                    id={AllInOneTabName.GENERAL}
                    label="General"
                    title="General"
                >
                    <GeneralTab />
                </Tab>
                <Tab
                    id={AllInOneTabName.PROVIDE_JSON_TO_DB}
                    className={styles.tabStyle}
                    label="Provide JSON to DB"
                    title="Enter page/stylesheet/template data (JSON)"
                >
                    <ProvideJsonToDb />
                </Tab>
            </TabDialogWithFullLeftPanel>
        );
    }
}

const
    getDialogDimensions = (browserHeight) => ({
        width,
        height: Math.min(Math.max(browserHeight - 100, 475), 950)
    });

export const
    allInDialog = {
        ...getCenteredDialogConfig({
            width,
            height,
            component: allInOneDialogComponent
        }),
        confFactory: ({ browserWidth, browserHeight }) => {
            const { width, height } = getDialogDimensions(browserHeight);
            return {
                position: getCenterPositionForDialog(width, height, browserWidth, browserHeight),
                modal: true,
                dimensions: { width, height }
            };
        },
        moveToCenter: true,
        dependsOn: {
            allInOneData: allInOneEpic.reducer
        }
    };
