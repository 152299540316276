export const
    DialogWidth = 600,
    DialogHeight = 392,
    PopupWindowWidth = 973,
    PopupWindowHeight = 798,
    DialogMessages = {
        TITLE: 'msg: component.facebookChat.multipleBusinessPages {Choose business page}',
        MESSAGE: 'msg: component.facebookChat.multipleBusinessPages.message {You have selected multiple Facebook Business pages when connecting. Messenger chat only allows one connected page at a time. Select a page to connect to your site.}', // eslint-disable-line max-len
        RECONNECT_LABEL: 'msg: common.continue {Continue}',
        CANCEL_LABEL: 'msg: common.cancel {Cancel}'
    };
