import React from 'react';
import cx from 'classnames';
import { Flex } from '../../../../../view/reflexbox/index';
import * as Actions from "../../actionTypes";
import * as styles from './Fields.css';
import { selectionBorder } from '../ContactForm.css';
import type { DragDropField } from "../flowTypes";
import LongTextTip from '../../../../../view/common/LongTextTip/index';
import { MaxWidthOfFieldText, inputTypeMap } from "../../constants";
import { useIntl } from '../../../../../view/intl/index';

const Field = (props: DragDropField) => {
    const { fieldObj, selected } = props;
    const intl = useIntl();

    const onSelect = (e: React.MouseEvent) => {
        const { field, dispatch } = props;
        e.stopPropagation();
        dispatch({ type: Actions.CONTACT_FORM_SELECT_FIELD, payload: field });
    };

    return (
        !fieldObj ? null : (<Flex
            align="center"
            justify="space-between"
            className={cx(styles.fieldContainer, { [selectionBorder]: selected })}
        >
            <div className={styles.fieldInfoCtr}>
                <div className={styles.draggingHandle} />
                <div className={styles.fieldInfo}>
                    <div className={styles.fieldName}>
                        <LongTextTip maxWidth={MaxWidthOfFieldText}>{fieldObj.name + (fieldObj.isRequired ? " *" : "")}</LongTextTip>
                    </div>
                    <div className={styles.fieldName}>
                        <LongTextTip maxWidth={MaxWidthOfFieldText}>{intl.msgJoint(inputTypeMap[fieldObj.inputType])}</LongTextTip>
                    </div>
                </div>
            </div>
            <div onMouseDown={(e) => onSelect(e)} className={styles.editIconContainer}>
                <span className={styles.editIcon} />
            </div>
        </Flex>)
    );
};

export default Field;
