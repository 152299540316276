import { makeCommonProperties } from './pages/Common';
import Border from './pages/Border';
import OnClick from './pages/OnClick';
import Scaling from './pages/Scaling';
import Seo from './pages/Seo';
import * as mobileView from '../../../PropertiesPanel/view/MobileView/page';
import makePages from "../../../PropertiesPanel/view/makePages";
import { ComponentNames } from '../../constants';
import ImageKind from '../kind';

const CommonProperties = makeCommonProperties({ componentTitle: ComponentNames[ImageKind] });

export const imagePpConfig = {
    pages: makePages(CommonProperties, Scaling, Border, OnClick, Seo, mobileView),
    defaultPageId: CommonProperties.id
};
