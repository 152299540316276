/**
 *
 * @param visitorFn Function to be called with the part
 * @param part Slice to be iterated over
 */
function iterate(visitorFn: Function, part: any) {
    if (part) {
        if (typeof (part) === 'object' && part.length) {
            // Array
            part.forEach(item => {
                if (item) {
                    iterate(visitorFn, item);
                }
            });
        } else if (typeof (part) === 'object') {
            visitorFn(part);

            for (var prop in part) { // eslint-disable-line
                if (part.hasOwnProperty(prop)) {
                    if (part[prop]) {
                        iterate(visitorFn, part[prop]);
                    }
                }
            }
        }
    }
}

export default iterate;
