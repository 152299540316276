import React from 'react';

import * as styles from '../FacebookFeedGallery.css';

type Props = {
    title: string;
    description: string;
    iconClass: string;
    onClose: () => void;
};

export const Banner = ({ title, description, iconClass, onClose }: Props) => {
    return (
        <div className={styles.bannerRootContainer}>
            <div
                className={iconClass}
                onClick={onClose}
                ref={(node) => node?.style.setProperty("cursor", "pointer", "important")}
            />

            <div className={styles.bannerContent}>
                <div className={styles.bannerIcon} />

                <div className={styles.bannerTextContainer}>
                    <h3 className={styles.bannerTitle}>{title}</h3>
                    <p className={styles.bannerDescription}>{description}</p>
                </div>
            </div>
        </div>
    );
};
