import cx from "classnames";
import getCenteredDialog from "../../../DialogManager/getCenteredDialogConfig";
import { configurationDialogFactory } from '../configurationDialogFactory';
import { TiktokDialogHeight, DialogWidth } from '../constants';
import { WIDGETS_TIKTOK_SET_LINK } from "./actionTypes";
import { validateLinkOrCode } from '../utils';
import { parseTiktokCode } from './utils/parseTiktokCode';
import Icons from '../view/Icons.css';

const validateTiktokData = validateLinkOrCode([], parseTiktokCode);

const
    configurationDialogId = "WIDGETS_TIKTOK_CONFIG_DIALOG",
    configurationDialogView = configurationDialogFactory({
        saveAction: WIDGETS_TIKTOK_SET_LINK,
        dialogIconClass: cx(Icons.tiktok, Icons.dialog),
        dialogTitle: "msg: widgets.tiktok.label {TikTok}",
        info: "msg: widgets.tiktok.config.info {Add a TikTok video or profile to your page.}",
        subInfo: "msg: widgets.tiktok.configDialog.copyPaste {Go to TikTok and select the share icon on the video or profile you want to add. Click \'Copy link\' and paste it below. }", // eslint-disable-line max-len
        learnMore: "msg: widgets.tiktok.config.learnMore {https://help.one.com/hc/en-us/articles/10282492477073}",
        inputPlaceHolder: "msg: widgets.tiktok.configDialog.placeHolder {Paste TikTok link}",
        isLinkValid: validateTiktokData,
        termsOfService: "msg: widgets.tiktok.config.termsOfService {By using this widget, you agree to TikTok\'s {termsOfServiceLink}.}",
        termsOfServiceLink: "msg: widgets.tiktok.config.termsOfServiceLink {https://www.tiktok.com/legal/page/eea/terms-of-service/en}",
    }),
    configurationDialog = getCenteredDialog({
        width: DialogWidth,
        height: TiktokDialogHeight,
        component: configurationDialogView
    });

export { configurationDialogId, configurationDialog };
