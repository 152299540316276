// @ts-nocheck
import * as R from 'ramda';
import * as mappers from './mappers';
import {
    makeIsStyleOverriden
} from './isStyleOverriden/index';
import overrideStyle from './overrideStyle';

const getStyleRef = (node) => {
    if (node.ref) {
        return node.ref;
    }
    return node.parent ? getStyleRef(node.parent) : '';
};

const getNodeFontSizeFromGlobals = (node, defaultGlobalStyles) => {
    const ref = getStyleRef(node);
    const globalStyle = defaultGlobalStyles.find(style => style.ref === ref);

    return globalStyle ? globalStyle.size : null;
};

export default (defaultGlobalStyles: Array < Stylesheet >) => {
    const isStyleOverriden = makeIsStyleOverriden(defaultGlobalStyles);

    return (node: any): string => {
        const {
            styles
        } = node;

        const stylesWithGlobalSize = { ...styles };

        // Old WSB may not set font-size for super/subscript text so
        // we have to set the missing styles for super/subscripted text
        if (styles.superscript || styles.subscript) {
            if (!styles.size) {
                stylesWithGlobalSize.size = getNodeFontSizeFromGlobals(node, defaultGlobalStyles);
            }
        }

        const supportedMappers =
            R.keysIn(mappers)
                .filter(key => {
                    return R.contains(key, R.keysIn(stylesWithGlobalSize));
                });

        return supportedMappers.reduce((css, prop, index) => {
            const isOverriden = isStyleOverriden(prop, node);
            const mapper = R.pipe(R.prop(prop, mappers), isOverriden ? overrideStyle : R.identity);
            const value = R.prop(prop, stylesWithGlobalSize);
            const style = mapper(value, stylesWithGlobalSize);

            if (R.isEmpty(style)) {
                return css;
            }

            return `${css}${style}${index !== supportedMappers.length - 1 ? '; ' : ';'}`;
        }, '').trim();
    };
};
