import { allowWsbMocks } from '../../debug/allowWsbMocks';
import { getWindowLocationMock } from '../../debug/getWindowLocationMock';

// TODO: set real release date !

// let date = '2019-04-24T00:00:00Z;
let date = '2018-08-01T00:00:00Z';

if (allowWsbMocks()) {
    const mockReleaseDate = getWindowLocationMock('whatsNewReleaseDate');
    if (mockReleaseDate) {
        date = mockReleaseDate;
    }
}

export const WHATS_NEW_RELEASE_DATE = date;
