import Url from "url-parse";

import { getDAL } from "../../../../../../dal/index";
import { getPathToPageIdMap } from "./getPathToPageIdMap";
import { LcTabName } from "../constants";
import type { DataSite } from "../../../../../../dal/model/index";
import type { TabNames } from "../flowTypes";

type Params = {
    type: TabNames,
    value: any,
    site: DataSite
}
export const getPageIdForFileLinks = ({ type, value, site }: Params) => {
    const { pathToPageIdMap, pathToPageIdMapStartsWithSlash } = getPathToPageIdMap(site);

    let pageId;
    if (type === LcTabName.FILE) {
        const { basePath, href } = value;
        const path = basePath ? basePath + '/' + href : href;
        pageId = pathToPageIdMap[path];
    } else if (type === LcTabName.URL) {
        const domainName = getDAL().getDomain();
        const url = Url(value);
        // Correct path for local dev. Check for trial as well.
        // pathname has '/' at the starting. So add '/' to map before comparing.
        const { hostname, pathname, query, hash } = url;

        // Do not map the URL to pageId if the users domain and URL domain are different -> domain !== link
        // or if they are same but the link contains hash from webshop -> domain === link && hash === '#!/<remainingURL>'
        // WBTGEN-28074: Edge case where customer adds a special link which has same domain but different hash than section link
        // -> domain === link && hash === '#/<remainingURL>' (whereas section link will have only '#<sectionId>')
        // -> domain === link && query === '?<something>' where user wants some parameter for some purpose
        if (domainName !== hostname || (hash && /^#!?\/.+$/.test(hash)) || query.length) {
            return pageId;
        }

        if (pathname.endsWith('.html')) {
            pageId = pathToPageIdMapStartsWithSlash[pathname]; // /contact.html
        } else {
            // /contact/
            const pathnameWithLastSlashRemoved = (pathname.endsWith('/')) ? pathname.slice(0, -1) : pathname;
            const pathnameWithHtml = pathnameWithLastSlashRemoved + '.html'; // /contact.html
            pageId = pathToPageIdMapStartsWithSlash[pathnameWithHtml];
            if (!pageId) {
                const pathnameWithIndexHtml = pathnameWithLastSlashRemoved + '/index.html'; // /contact/index.html
                pageId = pathToPageIdMapStartsWithSlash[pathnameWithIndexHtml];
            }
        }
    }
    return pageId;
};
