// @ts-nocheck
import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import styles from './Categories.css';
import LoadingIndicator from '../../../../../view/common/LoadingIndicator/index';
import { openApiErrorHandlerDialog } from '../../../../../redux/middleware/api/errorHandler/actions';
import Scrollbar from '../../../../../view/common/Scrollbar/index';
import injectIntl from "../../../../../view/intl/injectIntl";
import { getDAL } from "../../../../../../dal/index";

import type { Intl } from "../../../../../view/intl/injectIntl";

const DOMAIN_ERR = 'DomainNotCreated';

type Props = {
    intl: Intl,
    appDispatch: Dispatch,
    selectedCategoryId: String,
    applyFilters: Function
};

const loadCategories = async () => {
    let loadedCategories: any = [];
    let totalCategoriesCount = 0;
    const dal = getDAL();
    let pageNumber = 0;
    const MAX_ITERATIONS = 10;
    let currentIteration = 0;
    do {
        pageNumber += 1;
        const response = await dal.getWebshopData('/category' // eslint-disable-line no-await-in-loop
            + '?visibility=VISIBLE&sortBy=NAME&sort=ASC&'
            + `pageNumber=${pageNumber}&pageSize=200`);
        if (!response.body) {
            throw new Error('Empty response body');
        }

        if (!response.body.status
            || response.body.status !== 'Success'
            || !response.body.data
            || !response.body.data.result) {
            if (response.body.error === DOMAIN_ERR) {
                throw new Error(response.body.error);
            }
            throw new Error('Response error');
        }
        totalCategoriesCount = response.body.data.totalCount && !isNaN(response.body.data.totalCount) ?
            parseInt(response.body.data.totalCount, 10) : 0;
        loadedCategories = [...loadedCategories, ...response.body.data.result];
        currentIteration += 1;
    } while (currentIteration < MAX_ITERATIONS && totalCategoriesCount
        && loadedCategories.length < totalCategoriesCount);
    return loadedCategories;
};

const CategoriesContainer = ({
    intl,
    selectedCategoryId,
    applyFilters,
    appDispatch,
}: Props) => {
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState<Record<string, any>[]>([]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const loadedCategories = await loadCategories();
                setCategories([{
                    id: '',
                    name: intl.msgJoint('msg: component.webshop.featuredProducts.categoryAll {All}')
                }, ...loadedCategories]);
            } catch (err: any) {
                if (err.message === DOMAIN_ERR) {
                    setCategories([{
                        id: '',
                        name: intl.msgJoint('msg: component.webshop.featuredProducts.categoryAll {All}')
                    }]);
                } else {
                    appDispatch(openApiErrorHandlerDialog({
                        messages: 'msg: component.webshop.featuredProducts.fetchError {Sorry, loading failed.}',
                    }));
                }
            }
            setLoading(false);
        };
        fetchCategories();
    }, []);

    if (loading) {
        return <div className={styles.container}><LoadingIndicator /></div>;
    }

    return (
        <Scrollbar className={styles.container}>
            {!categories.length && <LoadingIndicator />}
            {categories.length && categories.map(({ id, name }) => {
                const isSelected = selectedCategoryId === id;
                return (
                    <div
                        key={id}
                        className={cx(styles.category, { [styles.selectedCategory]: isSelected })}
                        onClick={() => !isSelected && applyFilters({ categoryId: id, categoryName: name })}
                    >
                        {name}
                    </div>
                );
            })}
        </Scrollbar>
    );
};

export default injectIntl(CategoriesContainer);
