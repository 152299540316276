// @ts-nocheck
function isStaticPositioned(el: HTMLElement) {
    return ['', 'static'].includes(el.style.position);
}

function getOffsetParent(el: HTMLElement) {
    if (el.style.display === 'none') return null;

    if (el.offsetParent) {
        return el.offsetParent;
    } else {
        let parentEl = (el.parentElement as any);
        while (parentEl && isStaticPositioned(parentEl)) {
            parentEl = parentEl.parentElement;
        }
        return parentEl;
    }
}

function simpleScrollIntoViewWithOffsetTop(el: HTMLElement, offsetTop: number = 0) {
    const offsetParent = getOffsetParent(el);
    if (offsetParent) {
        offsetParent.scrollTop = el.offsetTop - offsetTop;
    }
}

export { simpleScrollIntoViewWithOffsetTop };
