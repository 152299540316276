import React from "react";
export const SvgInstagramRoundedFillDark = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <rect width={48} height={48} fill="#3c3c3c" rx={6} />
            <g fill="#ffffff">
                <path d="M23.887 15.37c2.775 0 3.103.013 4.195.061 1.014.045 1.562.215 1.927.357.483.187.832.414 1.193.775.365.365.588.71.779 1.193.142.365.312.917.357 1.927.048 1.096.06 1.424.06 4.195 0 2.772-.012 3.104-.06 4.196-.045 1.014-.215 1.562-.357 1.927a3.212 3.212 0 01-.775 1.193c-.365.365-.71.588-1.193.779-.365.142-.917.312-1.927.357-1.096.048-1.424.06-4.195.06-2.771 0-3.104-.012-4.196-.06-1.014-.045-1.562-.215-1.927-.357a3.212 3.212 0 01-1.193-.775 3.255 3.255 0 01-.779-1.193c-.142-.365-.312-.917-.357-1.927-.048-1.096-.06-1.424-.06-4.196 0-2.77.012-3.103.06-4.195.045-1.014.215-1.562.357-1.927.187-.483.414-.832.775-1.193.365-.365.71-.588 1.193-.779.365-.142.917-.312 1.927-.357 1.092-.048 1.42-.06 4.196-.06zm0-1.87c-2.82 0-3.173.012-4.28.06-1.104.05-1.863.228-2.52.484a5.07 5.07 0 00-1.842 1.2 5.09 5.09 0 00-1.201 1.839c-.256.66-.434 1.416-.483 2.52-.049 1.11-.061 1.464-.061 4.284s.012 3.172.06 4.28c.05 1.104.228 1.862.484 2.52a5.07 5.07 0 001.2 1.841 5.077 5.077 0 001.839 1.197c.66.256 1.416.434 2.52.483 1.107.049 1.46.061 4.28.061 2.82 0 3.172-.012 4.28-.06 1.103-.05 1.862-.228 2.52-.484a5.077 5.077 0 001.837-1.197 5.077 5.077 0 001.197-1.837c.256-.662.434-1.416.483-2.52.049-1.108.06-1.46.06-4.28 0-2.82-.011-3.173-.06-4.28-.049-1.104-.227-1.863-.483-2.52a4.865 4.865 0 00-1.189-1.846 5.077 5.077 0 00-1.837-1.197c-.662-.256-1.416-.434-2.52-.483-1.112-.053-1.465-.065-4.284-.065z" />
                <path d="M23.606 18.27a5.337 5.337 0 00-5.335 5.336 5.337 5.337 0 005.335 5.335 5.337 5.337 0 005.335-5.335 5.337 5.337 0 00-5.335-5.335zm0 8.797a3.461 3.461 0 110-6.923 3.461 3.461 0 010 6.923zm6.792-9.007a1.246 1.246 0 11-2.492 0 1.246 1.246 0 012.492 0z" />
            </g>
        </g>
    </svg>
);
