import type { Handle } from "../../redux/modules/children/workspace/flowTypes";
import type {
    HandleCreator,
    HandleCreatorFactory,
    FactoryOfHandleCreatorFactory,
    CreateHandleProps,
    GetNumber
} from "./flowTypes";
import makeHandle from './makeHandle';
import makeBBoxMemoized from '../componentsMap/makeBBoxMemoized';
import type { BBox } from "../../components/App/flowTypes";

export const
    minWidth = 50,
    minHeight = 50;

export function keepMinBBox(bBox: BBox) {
    const bboxWidth = bBox.right - bBox.left,
        bboxHeight = bBox.bottom - bBox.top,
        bboxHCenter = bBox.right - (bboxWidth / 2),
        bboxVCenter = bBox.bottom - (bboxHeight / 2);

    return {
        left: bboxWidth < minWidth ? bboxHCenter - (minWidth / 2) : bBox.left,
        right: bboxWidth < minWidth ? bboxHCenter + (minWidth / 2) : bBox.right,
        top: bboxHeight < minHeight ? bboxVCenter - (minHeight / 2) : bBox.top,
        bottom: bboxHeight < minHeight ? bboxVCenter + (minHeight / 2) : bBox.bottom
    };
}

const factory: FactoryOfHandleCreatorFactory =
    (getWidth: GetNumber, getHeight: GetNumber): HandleCreatorFactory =>
        (getTop: GetNumber, getLeft: GetNumber, kind: string): HandleCreator =>
            (props: CreateHandleProps): Handle => {
                const
                    { bBox, componentsIds } = props,
                    modifiedBBox = keepMinBBox(bBox),
                    left = getLeft(modifiedBBox, kind),
                    top = getTop(modifiedBBox, kind),
                    handleBBox = makeBBoxMemoized(
                        left,
                        top,
                        getWidth(modifiedBBox, kind),
                        getHeight(modifiedBBox, kind)
                    );

                return makeHandle(
                    componentsIds,
                    kind,
                    handleBBox,
                    props.initialZIndex + 1
                );
            };

export default factory;
