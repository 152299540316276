import type { ShutterstockState } from "../types";

export const shutterstockToggleReducer = (
    mainProp: string,
    otherProp: string,
) => (state: ShutterstockState): ShutterstockState => {
    const newMainPropValue = !state[mainProp];

    if (newMainPropValue && state[otherProp]) {
        return {
            ...state,
            [mainProp]: true,
            [otherProp]: false
        };
    }

    return {
        ...state,
        [mainProp]: newMainPropValue
    };
};
