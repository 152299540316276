import kind from './kind';
import viewStyles from './view/DocumentComponent.css';
import { PreviewDocumentComponent } from "./preview/PreviewDocumentComponent";
import { previewDocumentComponentCalcProps } from "./preview/previewDocumentComponentCalcProps";
import { DOCUMENT_IFRAME_RETRY_INTERVAL, DOCUMENT_IFRAME_RETRY_NR } from "./constants";

export default {
    kind,
    view: PreviewDocumentComponent,
    calcProps: previewDocumentComponentCalcProps,
    js: [
        {
            src: '/renderStatic/document/iframeLoad.js',
            id: 'iframeLoad',
            params: {
                styles: viewStyles,
                loader: { interval: DOCUMENT_IFRAME_RETRY_INTERVAL, retries: DOCUMENT_IFRAME_RETRY_NR }
            }
        }
    ]
};
