import valueActionType from './valueActionType';
import makeEpic from "../../../../epics/makeEpic";
import ComponentsKindToDisabledMapVAT from "../componentsKindsToDisableMap/valueActionType";
import registry from "../../../../view/oneweb/registry/index";
import { receiveOnly } from "../../../../epics/makeCondition";
import { ReceiveOnlyComponentsMap } from "../../../Workspace/epics/componentsEval/selectorActionTypes";
import subscriptionDataVAT from "../../../App/epics/subscriptionData/valueActionType";
import { shopPageIdsListVat } from "../../../oneweb/WebShop/epics/shopPageIdsList/valueActionType";
import { siteSettingsValueActionType } from "../../../App/epics/siteSettings/valueActionType";

const getDisabledReason = (componentRegistry, componentsMap, subscriptionData, siteSettings, shopPageIds) => {
    const { validateComponentAdd } = componentRegistry;
    return validateComponentAdd({ componentsMap, subscriptionData, shopPageIds, siteSettings });
};
const getComponentsDisabledReasonMap = (componentsMap, subscriptionData, componentsKindsToDisableMap, siteSettings, shopPageIds) => {
    return Object.keys(componentsKindsToDisableMap).reduce((componentsDisabledReasonMap, componentKind) => {
        const
            componentRegistry = registry[componentKind],
            error = getDisabledReason(componentRegistry, componentsMap, subscriptionData, siteSettings, shopPageIds);

        if (error) {
            return {
                ...componentsDisabledReasonMap,
                [componentKind]: error
            };
        }
        return componentsDisabledReasonMap;
    }, {});
};

const defaultState = {};

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                receiveOnly(subscriptionDataVAT),
                ReceiveOnlyComponentsMap,
                ComponentsKindToDisabledMapVAT,
                siteSettingsValueActionType,
                shopPageIdsListVat
            ],
            reducer: ({ values: [subscriptionData, componentsMap, componentsKindsToDisableMap, siteSettings, shopPageIds] }) => {
                return {
                    state: getComponentsDisabledReasonMap(
                        componentsMap, subscriptionData, componentsKindsToDisableMap, siteSettings,
                        shopPageIds
                    )
                };
            }
        }
    ]
});
