import type {
    InputValidationType,
    InputState,
    InputActionParams
} from "../flowTypes";

type Result = (value: string, params?: InputActionParams) => InputState;

export default (validations: Array<InputValidationType<any>>): Result => {
    if (!validations.length) {
        return (value: string) => ({
            isValid: true,
            value,
            errors: [],
            isTouched: false
        });
    }

    return (value: string, params?: InputActionParams) => {
        const allErrors: any = [];
        let allIsValid = true;

        validations.forEach(v => {
            const
                { validation, options } = v,
                validationParams = params && params[validation.name],
                finalOptions = options || validationParams ? { ...options, ...validationParams } : undefined,
                // $FlowFixMe: TODO
                validationResult = validation(value, finalOptions);

            allIsValid = allIsValid && validationResult.isValid;

            if (validationResult.isValid === false) allErrors.push(validationResult.error);
        });

        return {
            isValid: allIsValid,
            errors: allErrors,
            value,
            isTouched: false
        };
    };
};
