import * as React from 'react';
import cx from 'classnames';
import styles from './HoverBoxControls.css';
import { injectIntl, Intl, type MsgJointInput } from "../../../view/intl/index";

const Title = injectIntl(({ children, intl }: { children: MsgJointInput, intl: Intl }) => (
    <span className={styles.btnLabel}>{intl.msgJoint(children)}</span>
));

export type Props = {
    icoClassName: string,
    className?: string,
    title?: MsgJointInput,
    onClick?: React.MouseEventHandler;
    onMouseLeave?: React.MouseEventHandler;
};

export const HoverBoxBtnCom = React.forwardRef(
    ({ icoClassName, className, title, onClick, onMouseLeave }: Props, ref: React.ForwardedRef<HTMLSpanElement>) => (
        <span className={cx(styles.btn, className)} onClick={onClick} onMouseLeave={onMouseLeave} ref={ref}>
            <span className={cx(styles.ico, icoClassName)} />
            {title && <Title>{title}</Title>}
        </span>
    )
);
