import React from "react";
export const SvgPinterestRoundedFillMulticolor = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <rect width={48} height={48} fill="#e60019" rx={6} />
            <path
                fill="#ffffff"
                d="M24.007 12.5C17.66 12.5 12.5 17.66 12.5 24.007c0 6.347 5.16 11.507 11.507 11.507 6.347 0 11.507-5.16 11.507-11.507 0-6.347-5.16-11.507-11.507-11.507zm0 21.477a9.999 9.999 0 01-2.953-.445c.404-.66 1.016-1.749 1.24-2.612l.62-2.368c.324.62 1.277 1.146 2.284 1.146 3.007 0 5.174-2.765 5.174-6.203 0-3.295-2.688-5.758-6.145-5.758-4.301 0-6.585 2.89-6.585 6.032 0 1.46.778 3.281 2.023 3.861.189.09.288.05.333-.135.031-.14.202-.813.278-1.132a.307.307 0 00-.067-.288c-.414-.5-.742-1.42-.742-2.275 0-2.198 1.663-4.328 4.5-4.328 2.45 0 4.162 1.667 4.162 4.054 0 2.697-1.362 4.567-3.133 4.567-.98 0-1.713-.809-1.474-1.802.283-1.187.827-2.464.827-3.318 0-.764-.41-1.402-1.263-1.402-1.003 0-1.803 1.034-1.803 2.423 0 .88.297 1.479.297 1.479s-.989 4.175-1.169 4.953c-.202.863-.121 2.072-.036 2.859a9.957 9.957 0 01-6.338-9.278c0-5.506 4.464-9.97 9.97-9.97s9.97 4.464 9.97 9.97-4.464 9.97-9.97 9.97z"
            />
        </g>
    </svg>
);
