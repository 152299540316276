// @ts-nocheck
export const
    minPxOverlap = 30,
    minPercentOverlap = 15,
    maxShareBgTolerance = 10,
    minHeaderAndFooterGap = 150,
    defaultHeaderOrFooterHeight = 100,
    defaultDistanceBetweenHeaderAndFooter = 400,
    minPageSectionHeight = 25,
    cmpReqProps = [
        "id",
        "inTemplate",
        "orderIndex",
        "stretch",
        "kind",
        "left",
        "top",
        "width",
        "height",
        "relIn",
        "layoutType",
        "location"
    ],
    REASONS = {
        noCmps: 'noCmps',
        allCmpsArePageCmps: 'allCmpsArePageCmps',
        separatedByZones: 'separatedByZones',
        separatedByArea: 'separatedByArea',
        noCmpsNearTopOfPage: 'noCmpsNearTopOfPage',
        noCmpsNearBottomOfPage: 'noCmpsNearBottomOfPage',
        needsToFindReasonStill: 'needsToFindReasonStill',
        noCmpsNearTopOfPageLessThan180pxAndNoPageCmps: 'noCmpsNearTopOfPageLessThan180pxAndNoPageCmps',
        negativeTopCmps: 'negativeTopCmps',
        templateCmpsInHeaderOverlappingWithPageRegion: 'templateCmpsInHeaderOverlappingWithPageRegion',
        sameTemplateCmpsDiffHeightInDiffPages: 'sameTemplateCmpsDiffHeightInDiffPages',
        headerNotDetectedInAtleast1PageInThisTemplate: 'headerNotDetectedInAtleast1PageInThisTemplate',
        noCommonFooterCmpsAmongPagesOfThisTemplate: 'noCommonFooterCmpsAmongPagesOfThisTemplate',
        mobile_TemplateCmpMobileDownConvertedToPage: 'mobile_TemplateCmpMobileDownConvertedToPage',
        mobile_pageCmpOverlappingWithHeader: 'mobile_pageCmpOverlappingWithHeader',
        mobile_mobileSequenceIsChanged: 'mobile_mobileSequenceIsChanged',
        mobile_footerCmpConvertedToPage: 'mobile_footerCmpConvertedToPage',
        all_page_is_header: 'all_page_is_header'
    },
    containerKindsMap = {
        'STRIP': true,
        'SECTION': true,
        'BACKGROUND': true,
        'IMAGE': true,
    };

