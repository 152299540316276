import { getPaddingNode } from "./getPaddingNode";

import type { TinyMceEditor } from "../../../flowTypes";
import type { Padding } from "../../../../../../../mappers/flowTypes";

const getPaddingFromStyle = (string: string): number => {
    return parseInt(string, 10) || 0;
};

export const getPadding = (editor: TinyMceEditor): Padding => {
    let selectedNode = getPaddingNode(editor);
    if (selectedNode === null) {
        return [0, 0, 0, 0];
    }

    selectedNode = editor.dom.$(selectedNode);
    return [
        getPaddingFromStyle(selectedNode.css('paddingTop')),
        getPaddingFromStyle(selectedNode.css('paddingRight')),
        getPaddingFromStyle(selectedNode.css('paddingBottom')),
        getPaddingFromStyle(selectedNode.css('paddingLeft'))
    ];
};
