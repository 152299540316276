import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import { SITE_SUBSCRIPTION_DATA_LOAD_SUCCESS } from "../../../App/actionTypes";
import { SEAMLESS_UPGRADE_SUCCESS } from "../../actionTypes";
import { isFreeUpgradeAvailable } from './utils';

export default makeEpic({
    defaultState: { isFreeUpgrade: false },
    valueActionType,
    updaters: [
        {
            conditions: [SITE_SUBSCRIPTION_DATA_LOAD_SUCCESS],
            reducer: ({ values: [subscriptionData] }) => {
                return { state: { isFreeUpgrade: isFreeUpgradeAvailable(subscriptionData) } };
            }
        },
        {
            conditions: [SEAMLESS_UPGRADE_SUCCESS],
            reducer: ({ state }) => {
                return { state: { ...state, isFreeUpgrade: false } };
            }
        }
    ]
});
