import type { FileManagerState } from "../flowTypes";
import * as ActionTypes from "../actionTypes";

export default (state: FileManagerState, { payload: { resource }, type }: any): FileManagerState => {
    if (type === ActionTypes.FC_ADD_GHOST_RESOURCE) {
        return {
            ...state,
            ghostResources: state.ghostResources ? state.ghostResources.concat(resource) : resource
        };
    } else if (type === ActionTypes.FC_REMOVE_GHOST_RESOURCE_BY_NAME) {
        return {
            ...state,
            ghostResources: state.ghostResources ? state.ghostResources.filter(r => r.href !== resource) : state.ghostResources
        };
    } else {
        return {
            ...state,
            ghostResources: state.ghostResources ? state.ghostResources.filter(r => r.id !== resource.id) : state.ghostResources
        };
    }
};

