import { DefaultLocale } from '../constants';

export const
    GOOGLE_MAPS_ZOOM_LEVEL = 14,
    DEFAULT_WIDTH = 450,
    DEFAULT_HEIGHT = 500,
    defaultState = {
        addressLocation: null,
        addressText: '',
        addressUrl: '',
        locale: DefaultLocale,
        zoom: GOOGLE_MAPS_ZOOM_LEVEL,
        source: 'input',
        mobileHide: false,
        stretch: false,
        left: 0,
    },
    DATA_TEST_ID = 'google-maps-widget';

