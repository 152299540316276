import { toHex } from "../../../mappers/color";
import { textHeading2Globalstyle } from '../../Workspace/epics/stylesheets/selectors';
import type { Stylesheets } from "../../Workspace/epics/stylesheets/flowTypes";
import type { Color } from "../../../mappers/flowTypes";
import { FontFamiliesFallback } from '../../presentational/FontFamily/fontFamilies';
import { MAX_HEADER_FONT_SIZE, MIN_HEADER_FONT_SIZE } from "./constants";

type FontSettings = {
    font: string,
    fontSize: number,
    fontColor: Color
}

type FontStyle = {
    headerStyles: {
        fontFamily: string,
        fontSize: number,
        color: string
    }
}

// For font name having spaces
const wrapInQuotesIfRequired = (v: string) => {
    return (/"/.test(v) ? v : `"${v}"`);
};

const
    getFontSettings = ({
        globalStyles,
    }: { globalStyles: Stylesheets }): FontSettings => {
        const
            globalStyle = textHeading2Globalstyle(globalStyles);

        let fontSettings = {
            font: (globalStyle.font).toString(),
            fontSize: globalStyle.size,
            fontColor: globalStyle.color,
        };
        return fontSettings;
    },

    getGoogleReviewStyles = ({
        globalStyles,
    }: {
        globalStyles: Stylesheets
    }): FontStyle => {
        let {
            font,
            fontSize,
            fontColor,
        } = getFontSettings({ globalStyles });
        fontSize = Math.max(MIN_HEADER_FONT_SIZE, Math.min(fontSize, MAX_HEADER_FONT_SIZE));
        return {
            headerStyles: {
                fontFamily: FontFamiliesFallback[font] || wrapInQuotesIfRequired(font),
                fontSize,
                color: toHex(fontColor)
            },

        };
    };

export {
    getGoogleReviewStyles
};
