import React from 'react';
import cx from 'classnames';
import styles from './MctaSlider.css';
import RCSlider from '../../../view/common/Slider/RCSlider';
import { MctaPropertyContainer } from '../index';
import type { CommonSliderProps } from "../../../view/common/Slider/flowTypes";
import type { MsgJointInput } from "../../../view/intl";
import Label from "../../../view/common/Label";

type ValueLabel = true | string;

type Props = CommonSliderProps & {
    label?: MsgJointInput;
    valueLabel?: ValueLabel;
    sliderContainerClassName?: string;
    sliderClassName?: string;
    forMve?: boolean;
};

const defineValueLabel = ({ valueLabel, value }) => {
    if (value === undefined || value === null) return valueLabel;
    if (valueLabel === true) return `${value}`;
    else if (valueLabel === '%') return `${value}%`;
    else if (valueLabel === 'px') return `${value} px`;
    else if (valueLabel) return valueLabel;
    return null;
};

export const MctaSlider = ({
    label,
    valueLabel: valueLabelInput,
    sliderContainerClassName,
    sliderClassName,
    forMve = false,
    ...sliderProps
}: Props) => {
    const valueLabel = defineValueLabel({ valueLabel: valueLabelInput, value: sliderProps.value });
    return (
        <MctaPropertyContainer
            label={label}
            labelStyle={{ fontWeight: 600 }}
            className={cx(styles.propertyContainer, { [styles.forMve]: forMve })}
            childrenContainerClassName={styles.childrenContainer}
        >
            <div className={cx(styles.sliderContainer, sliderContainerClassName)}>
                <RCSlider {...sliderProps} className={cx(styles.slider, sliderClassName)} />
                {valueLabel && <Label isValue className={styles.valueLabel}>{valueLabel}</Label>}
            </div>
        </MctaPropertyContainer>
    );
};
