// todo WBTGEN-2273 Use single implementation of Button
import * as React from 'react';
import type { PropTypes } from "./flowTypes";
import { injectIntl } from "../../intl/index";

const ButtonBody = injectIntl(
    ({ isIntl, intl, children }: Omit<PropTypes, 'onClick'>) => (
        (isIntl && intl.msgJoint(children)) || children
    ),
);

export default React.forwardRef(
    ({ isIntl, children, dataTid, ...props }: Omit<PropTypes, 'intl'>, ref: React.ForwardedRef<any>) => (
        <button {...props} ref={ref} data-tid={dataTid}>
            <ButtonBody isIntl={isIntl}>{children}</ButtonBody>
        </button>
    ),
);
