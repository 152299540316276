import { $Diff } from "utility-types";

import type {
    GetImagesQuery,
    PostThirdPartyImageDownloadsRequest,
    SstockImageDownload,
} from "../../../../dal/flowTypes";
import { closeDialogByIdAction, openDialog } from "../../App/actionCreators/index";
import {
    SHUTTERSTOCK_UPLOADING_DIALOG_ID,
    SHUTTERSTOCK_QUOTA_LIMIT_DIALOG_ID,
    SHUTTERSTOCK_PARTIAL_DOWNLOADS_DIALOG_ID
} from "./components/dialogs/ids";
import {
    downloadFreeShutterstockImagesApiAction,
    getShutterstockCategoriesApiAction,
    getFreeShutterstockDownloadedImagesApiAction,
    searchShutterstockImagesApiAction
} from '../../../redux/middleware/api/commonApiActions';
import type { ShutterStockQuotaLimitDialogProps } from "./components/dialogs/quotaLimitDialog";
import { Intl } from "../../../view/intl/index";
import type { ShutterStockUploadingDialogProps } from "./components/dialogs/shutterstockUploadingDialog";
import type { ShutterStockPartialDialogProps } from "./components/dialogs/shutterstockPartialDownloadDialog";
import type { ShutterstockKindT } from "./types";
import { ShutterstockRequestLimitMessages } from "./components/dialogs/messages";
import type { ApiAction } from "../../../redux/middleware/api/flowTypes";
import { extendedApiAction } from "../../../redux/middleware/api/actions";
import { SHUTTERSTOCK_ACTION_KIND } from "./constants";
import { getShutterstockCollectionByKind } from "./getShutterstockCollectionByKind";

export const shutterstockAction = (kind: ShutterstockKindT, action: Action) => ({
    ...action,
    [SHUTTERSTOCK_ACTION_KIND]: kind,
});

export const shutterstockApiAciton = (kind: ShutterstockKindT, action: ApiAction): ApiAction =>
    extendedApiAction({ extraPayload: { [SHUTTERSTOCK_ACTION_KIND]: kind } }, action);

export type SelectShutterstockImageActionParams = {
    kind?: ShutterstockKindT,
    imageId: string,
    isMultiSelect?: boolean,
}

export const
    SELECT_SHUTTERSTOCK_IMAGE_ACTION = 'SELECT_SHUTTERSTOCK_IMAGE_ACTION',
    selectShutterstockImageAction = ({ kind, imageId, isMultiSelect }: SelectShutterstockImageActionParams) =>
        // @ts-ignore
        shutterstockAction(kind, {
            type: SELECT_SHUTTERSTOCK_IMAGE_ACTION,
            payload: { imageId, isMultiSelect },
        });

export const
    INITIALIZE_SHUTTERSTOCK_ACTION = 'INITIALIZE_SHUTTERSTOCK_ACTION',
    initializeShutterstockAction = (kind: ShutterstockKindT) => shutterstockAction(kind, {
        type: INITIALIZE_SHUTTERSTOCK_ACTION
    });

export const
    DESELECT_ALL_SHUTTERSTOCK_IMAGES_ACTION = 'DESELECT_ALL_SHUTTERSTOCK_IMAGES_ACTION',
    deselectAllShutterstockImagesAction = (kind: ShutterstockKindT) => shutterstockAction(kind, {
        type: DESELECT_ALL_SHUTTERSTOCK_IMAGES_ACTION,
    });

export const
    SHUTTERSTOCK_TYPE_IMAGES_CLICKED_ACTION = 'SHUTTERSTOCK_TYPE_IMAGES_CLICKED_ACTION',
    shutterstockTypeImagesClickedAction = (kind: ShutterstockKindT) => shutterstockAction(kind, {
        type: SHUTTERSTOCK_TYPE_IMAGES_CLICKED_ACTION,
    });

export const
    SHUTTERSTOCK_TYPE_ILLUSTRATIONS_CLICKED_ACTION = 'SHUTTERSTOCK_TYPE_ILLUSTRATIONS_CLICKED_ACTION',
    shutterstockTypeIllustrationsClickedAction = (kind: ShutterstockKindT) => shutterstockAction(kind, {
        type: SHUTTERSTOCK_TYPE_ILLUSTRATIONS_CLICKED_ACTION,
    });

export const
    SHUTTERSTOCK_PORTRAIT_ORIENTATION_CLICKED_ACTION = 'SHUTTERSTOCK_PORTRAIT_ORIENTATION_CLICKED_ACTION',
    shutterstockPortraitOrientationClickedAction = (kind: ShutterstockKindT) => shutterstockAction(kind, {
        type: SHUTTERSTOCK_PORTRAIT_ORIENTATION_CLICKED_ACTION,
    });

export const
    SHUTTERSTOCK_LANDSCAPE_ORIENTATION_CLICKED_ACTION = 'SHUTTERSTOCK_LANDSCAPE_ORIENTATION_CLICKED_ACTION',
    shutterstockLandscapeOrientationClickedAction = (kind: ShutterstockKindT) => shutterstockAction(kind, {
        type: SHUTTERSTOCK_LANDSCAPE_ORIENTATION_CLICKED_ACTION,
    });

export const
    SELECT_SHUTTERSTOCK_CATEGORY_ACTION = 'SELECT_SHUTTERSTOCK_CATEGORY_ACTION',
    selectShutterstockCategoryAction = (
        { kind, categoryId, categoryName }: {
            kind: ShutterstockKindT,
            categoryId: string,
            categoryName: string,
        },
    ) => shutterstockAction(kind, {
        type: SELECT_SHUTTERSTOCK_CATEGORY_ACTION,
        payload: { categoryId, categoryName }
    });

export const
    GET_SHUTTERSTOCK_CATEGORIES_ACTION = 'GET_SHUTTERSTOCK_CATEGORIES_ACTION',
    GET_SHUTTERSTOCK_CATEGORIES_SUCCESS_ACTION = 'GET_SHUTTERSTOCK_CATEGORIES_SUCCESS_ACTION',
    GET_SHUTTERSTOCK_CATEGORIES_FAILURE_ACTION = 'GET_SHUTTERSTOCK_CATEGORIES_FAILURE_ACTION',
    getShutterstockCategoriesAction = (kind: ShutterstockKindT) =>
        shutterstockApiAciton(kind, getShutterstockCategoriesApiAction({
            types: [
                GET_SHUTTERSTOCK_CATEGORIES_ACTION,
                GET_SHUTTERSTOCK_CATEGORIES_SUCCESS_ACTION,
                GET_SHUTTERSTOCK_CATEGORIES_FAILURE_ACTION,
            ],
            collection: getShutterstockCollectionByKind(kind),
        }));

export const
    SEARCH_SHUTTERSTOCK_IMAGES_REQUEST_ACTION = 'SEARCH_SHUTTERSTOCK_IMAGES_REQUEST_ACTION',
    SEARCH_SHUTTERSTOCK_IMAGES_SUCCESS_ACTION = 'SEARCH_SHUTTERSTOCK_IMAGES_SUCCESS_ACTION',
    SEARCH_SHUTTERSTOCK_IMAGES_FAILURE_ACTION = 'SEARCH_SHUTTERSTOCK_IMAGES_FAILURE_ACTION',
    searchShutterstockImagesAction = (kind: ShutterstockKindT, query: GetImagesQuery = {}) =>
        shutterstockApiAciton(kind, searchShutterstockImagesApiAction({
            types: [
                SEARCH_SHUTTERSTOCK_IMAGES_REQUEST_ACTION,
                SEARCH_SHUTTERSTOCK_IMAGES_SUCCESS_ACTION,
                SEARCH_SHUTTERSTOCK_IMAGES_FAILURE_ACTION,
            ],
            collection: getShutterstockCollectionByKind(kind),
            query,
        }));

export const
    GET_SHUTTERSTOCK_DOWNLOADED_IMAGES_REQUEST_ACTION = 'GET_SHUTTERSTOCK_DOWNLOADED_IMAGES_REQUEST_ACTION',
    GET_SHUTTERSTOCK_DOWNLOADED_IMAGES_SUCCESS_ACTION = 'GET_SHUTTERSTOCK_DOWNLOADED_IMAGES_SUCCESS_ACTION',
    GET_SHUTTERSTOCK_DOWNLOADED_IMAGES_FAILURE_ACTION = 'GET_SHUTTERSTOCK_DOWNLOADED_IMAGES_FAILURE_ACTION',
    getShutterstockDownloadedImagesAction = (kind: ShutterstockKindT) =>
        shutterstockApiAciton(kind, getFreeShutterstockDownloadedImagesApiAction({
            types: [
                GET_SHUTTERSTOCK_DOWNLOADED_IMAGES_REQUEST_ACTION,
                GET_SHUTTERSTOCK_DOWNLOADED_IMAGES_SUCCESS_ACTION,
                GET_SHUTTERSTOCK_DOWNLOADED_IMAGES_FAILURE_ACTION,
            ],
        }));

export const
    HIDE_SHUTTERSTOCK_PREMIUM_DOWNLOADS_MSG_ACTION = "HIDE_UPGRADE_MSG_ACTION",
    hideShutterstockPremiumDownloadsMsgAction = (kind: ShutterstockKindT) => shutterstockAction(kind, {
        type: HIDE_SHUTTERSTOCK_PREMIUM_DOWNLOADS_MSG_ACTION,
    });

export const
    SHUTTERSTOCK_GRID_SCROLL_TOP_ACTION = 'SHUTTERSTOCK_GRID_SCROLL_TOP_ACTION',
    shutterstockGridScrollTopAction = (kind: ShutterstockKindT, scrollTop: number) =>
        shutterstockAction(kind, {
            type: SHUTTERSTOCK_GRID_SCROLL_TOP_ACTION,
            payload: scrollTop
        });

export const
    SHUTTERSTOCK_GRID_SCROLLED_TO_BOTTOM_ACTION = 'SHUTTERSTOCK_GRID_SCROLLED_TO_BOTTOM_ACTION',
    shutterstockGridScrolledToBottomAction = (kind: ShutterstockKindT) => shutterstockAction(kind, {
        type: SHUTTERSTOCK_GRID_SCROLLED_TO_BOTTOM_ACTION
    });

export const
    LOAD_SHUTTERSTOCK_IMAGS_PAGE_REQUEST_ACTION = 'LOAD_SHUTTERSTOCK_IMAGS_PAGE_REQUEST_ACTION',
    LOAD_SHUTTERSTOCK_IMAGS_PAGE_SUCCESS_ACTION = 'LOAD_SHUTTERSTOCK_IMAGS_PAGE_SUCCESS_ACTION',
    LOAD_SHUTTERSTOCK_IMAGS_PAGE_FAILURE_ACTION = 'LOAD_SHUTTERSTOCK_IMAGS_PAGE_FAILURE_ACTION',
    loadShutterstockImagesPageAction = (kind: ShutterstockKindT, query?: GetImagesQuery) =>
        shutterstockApiAciton(kind, searchShutterstockImagesApiAction({
            types: [
                LOAD_SHUTTERSTOCK_IMAGS_PAGE_REQUEST_ACTION,
                LOAD_SHUTTERSTOCK_IMAGS_PAGE_SUCCESS_ACTION,
                LOAD_SHUTTERSTOCK_IMAGS_PAGE_FAILURE_ACTION,
            ],
            collection: getShutterstockCollectionByKind(kind),
            query,
        }));

export type ShutterstockSaveActionParams = {
    isMultiSelect: boolean,
    onSaveAction: string,
};

export const
    SHUTTERSTOCK_SAVE_ACTION = 'SHUTTERSTOCK_SAVE_ACTION',
    shutterstockSaveAction = (params: ShutterstockSaveActionParams) => ({
        type: SHUTTERSTOCK_SAVE_ACTION,
        payload: params
    });

export const
    DOWNLOAD_SHUTTERSTOCK_IMAGES_REQUEST_ACTION = 'DOWNLOAD_SHUTTERSTOCK_IMAGES_REQUEST_ACTION',
    DOWNLOAD_SHUTTERSTOCK_IMAGES_SUCCESS_ACTION = 'DOWNLOAD_SHUTTERSTOCK_IMAGES_SUCCESS_ACTION',
    DOWNLOAD_SHUTTERSTOCK_IMAGES_FAILURE_ACTION = 'DOWNLOAD_SHUTTERSTOCK_IMAGES_FAILURE_ACTION',
    downloadShutterstockImagesApiActionAction = (kind: ShutterstockKindT, request: PostThirdPartyImageDownloadsRequest) =>
        shutterstockApiAciton(kind, downloadFreeShutterstockImagesApiAction({
            types: [
                DOWNLOAD_SHUTTERSTOCK_IMAGES_REQUEST_ACTION,
                DOWNLOAD_SHUTTERSTOCK_IMAGES_SUCCESS_ACTION,
                DOWNLOAD_SHUTTERSTOCK_IMAGES_FAILURE_ACTION,
            ],
            request,
        }));

type OpenQuotaLimitDialogActionParams = $Diff<ShutterStockQuotaLimitDialogProps, { intl: Intl }>;

export const
    openShutterstockQuotaLimitDialogAction = (props: OpenQuotaLimitDialogActionParams) =>
        openDialog(SHUTTERSTOCK_QUOTA_LIMIT_DIALOG_ID, props);

export const
    SHUTTERSTOCK_SEARCH_INPUT_CHANGE_ACTION = 'SHUTTERSTOCK_SEARCH_INPUT_CHANGE_ACTION';

export const
    SHUTTERSTOCK_SEARCH_CLICK_ACTION = 'SHUTTERSTOCK_SEARCH_CLICK_ACTION',
    shutterstockSearchClickAction = (kind: ShutterstockKindT) => shutterstockAction(kind, {
        type: SHUTTERSTOCK_SEARCH_CLICK_ACTION,
    });

export const
    SHUTTERSTOCK_SEARCH_ENTER_PRESS_ACTION = 'SHUTTERSTOCK_SEARCH_ENTER_PRESS_ACTION ',
    shutterstockSearchEnterPressAction = (kind: ShutterstockKindT) => shutterstockAction(kind, {
        type: SHUTTERSTOCK_SEARCH_ENTER_PRESS_ACTION
    });

export const
    openShutterstockUploadingDialogAction = (props: ShutterStockUploadingDialogProps) =>
        openDialog(SHUTTERSTOCK_UPLOADING_DIALOG_ID, props),
    closeShutterstockUploadingDialogAction = () => closeDialogByIdAction(SHUTTERSTOCK_UPLOADING_DIALOG_ID);

export const
    SHUTTERSTOCK_CLEAN_SEARCH_ACTION = 'SHUTTERSTOCK_CLEAN_SEARCH_ACTION',
    shutterstockCleanSearchAction = (kind: ShutterstockKindT) => shutterstockAction(kind, {
        type: SHUTTERSTOCK_CLEAN_SEARCH_ACTION,
    });

type OpenShutterstockPartialDownloadsParams = $Diff<ShutterStockPartialDialogProps, {
    intl: Intl,
    dispatch: Dispatch,
}>;

export const
    openShutterstockPartialDownloadsDialogAction = (props: OpenShutterstockPartialDownloadsParams) =>
        openDialog(SHUTTERSTOCK_PARTIAL_DOWNLOADS_DIALOG_ID, props),
    closeShutterstockPartialDownloadsDialogAction = () =>
        closeDialogByIdAction(SHUTTERSTOCK_PARTIAL_DOWNLOADS_DIALOG_ID);

export const
    RETRY_FAILED_SHUTTERSTOCK_PARTIAL_DOWNLOADS_ACTION = 'RETRY_FAILED_SHUTTERSTOCK_PARTIAL_DOWNLOADS_ACTION',
    retryFailedShutterstockPartialDownloadsAction = (kind: ShutterstockKindT) => shutterstockAction(kind, {
        type: RETRY_FAILED_SHUTTERSTOCK_PARTIAL_DOWNLOADS_ACTION,
    });

export const
    STORE_SUCCESSFUL_SHUTTERSTOCK_PARTIAL_DOWNLOADS_ACTION = 'STORE_SUCCESSFUL_SHUTTERSTOCK_PARTIAL_DOWNLOADS_ACTION',
    storeSuccessfulshutterstockPartialDownloadsAction = (
        kind: ShutterstockKindT,
        downlodas: Array<SstockImageDownload>,
    ) => shutterstockAction(kind, {
        type: STORE_SUCCESSFUL_SHUTTERSTOCK_PARTIAL_DOWNLOADS_ACTION,
        payload: downlodas,
    });

export const
    CANCEL_FAILED_SHUTTERSTOCK_PARTIAL_DOWNLOADS_ACTION = 'CANCEL_FAILED_SHUTTERSTOCK_PARTIAL_DOWNLOADS_ACTION',
    cancelShutterstockPartialDownloadsRetryAction = (kind: ShutterstockKindT) => shutterstockAction(kind, {
        type: CANCEL_FAILED_SHUTTERSTOCK_PARTIAL_DOWNLOADS_ACTION,
    });

export const openMaxDownloadsPerRequestDialogAction = (kind: ShutterstockKindT, limit: number) =>
    shutterstockAction(kind, openShutterstockQuotaLimitDialogAction({
        title: [ShutterstockRequestLimitMessages.title, { limit }],
        messages: [
            [ShutterstockRequestLimitMessages.messages[0], { limit }],
            ShutterstockRequestLimitMessages.messages[1],
        ],
    }));

export const
    DISABLE_SHUTTERSTOCK_MCTA_ACTION = 'DISABLE_SHUTTERSTOCK_MCTA_ACTION',
    disableShutterstockMctaAction = (kind: ShutterstockKindT) => shutterstockAction(kind, {
        type: DISABLE_SHUTTERSTOCK_MCTA_ACTION,
    });

export const
    ENABLE_SHUTTERSTOCK_MCTA_ACTION = 'ENABLE_SHUTTERSTOCK_MCTA_ACTION',
    enableShutterstockMctaAction = (kind: ShutterstockKindT) => shutterstockAction(kind, {
        type: ENABLE_SHUTTERSTOCK_MCTA_ACTION
    });

export const
    SKIP_ADD_IMAGE_COMPONENT_POST_DOWNLOAD_ACTION = 'SKIP_ADD_IMAGE_COMPONENT_POST_DOWNLOAD_ACTION',
    skipAddImageToComponentPostDownloadAction = (kind: ShutterstockKindT) => shutterstockAction(kind, {
        type: SKIP_ADD_IMAGE_COMPONENT_POST_DOWNLOAD_ACTION
    });

export const
    RESET_SKIP_ADD_IMAGE_COMPONENT_POST_DOWNLOAD_ACTION = 'RESET_SKIP_ADD_IMAGE_COMPONENT_POST_DOWNLOAD_ACTION',
    resetSkipAddImageToComponentPostDownloadAction = (kind: ShutterstockKindT) => shutterstockAction(kind, {
        type: RESET_SKIP_ADD_IMAGE_COMPONENT_POST_DOWNLOAD_ACTION
    });
