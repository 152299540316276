import { WidgetsGoogleMapsKind } from './kind';
import { componentMainActions } from './componentMainActions';
import { configurationDialogId } from './configurationDialog';
import { propertiesPanel } from './propertiesPanel/index';
import { reducer } from './reducer';
import { GoogleMapsView } from './view/index';
import { GoogleMapsWorkspaceView } from './view/workspace';
import { calcRenderProps } from './calcRenderProps';
import { generalGlobalDataEpic } from '../../../SiteSettings/General/generalGlobalDataEpic/generalGlobalDataEpic';
import makeStateSelectorReducer from "../../../../epics/makeStateSelectorReducer";
import { adjustComponentOnAdd } from './adjustComponentOnAdd';
import { WidgetsGoogleMapsConfig } from '../constants';
import { getMVEHiddenElementData } from '../mobileEditorConfig';

const WidgetsGoogleMaps = {
    kind: WidgetsGoogleMapsKind,
    label: WidgetsGoogleMapsConfig.title,
    shortcutIconName: 'widgetsGoogleMaps',
    view: GoogleMapsView,
    workspace: GoogleMapsWorkspaceView,
    requireConfigurationOnDrop: (componentConfig, componentDependencies) => !componentDependencies.generalData.addressLocation,
    configurationDialog: configurationDialogId,
    calcRenderProps,
    componentMainActions,
    propertiesPanel,
    reducer,
    adjustComponentOnAdd,
    dependsOn: {
        generalData: makeStateSelectorReducer(
            generalGlobalDataEpic.reducer,
            generalGlobalDataEpic.valueActionType,
            ({ addressLocation, address, addressUrl }) => ({ addressLocation, addressText: address, addressUrl })
        )
    },
    mobileEditorConfig: {
        kind: WidgetsGoogleMapsKind,
        calcProps: calcRenderProps,
        view: GoogleMapsView,
        getHiddenElementData: getMVEHiddenElementData(WidgetsGoogleMapsConfig),
    },
};

export { WidgetsGoogleMaps };
