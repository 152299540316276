import * as R from 'ramda';
import {
    LinkedInAPI,
    TwitterAPI,
    LocaleMap,

    HorizontalCountStyle,
    VerticalCountStyle,
    WithoutCountStyle,
    ExtraInfoStyle,
    RoundStyle,
    SquareStyle,

    RowHeight,
    RowPadding
} from './constants';
import { getFacebookPagePluginApiUrl } from '../Facebook/utils';
import * as htmlFactory from './view/factory';
import type { ShareButtonsComponent, ShareButtonsCalcRenderPropsResult } from './flowTypes';
import styles from './view/ShareButtons.css';

const getULClassnames = (styleInfo, align, className) => ({
    [styles.shareButtonCntnr]: true,
    [styles.left]: styleInfo === ExtraInfoStyle || align === 'left',
    [styles.center]: styleInfo !== ExtraInfoStyle && align === 'center',
    [styles.right]: styleInfo !== ExtraInfoStyle && align === 'right',
    [className]: true
});

const addTimeToURL = (url: string) => (time ?: number) => {
    return url + (time ? `?r=${time}` : '');
};

export const
    getLinkedInApiUrl = addTimeToURL(LinkedInAPI),

    getTwitterApiUrl = addTimeToURL(TwitterAPI),

    getShareButtonsApiUrls = (props: ShareButtonsCalcRenderPropsResult, time: number) => {
        const
            urls: Record<string, any> = [],
            { showFacebookShare, showLinkedIn, showTwitter, locale } = props;

        if (showFacebookShare) {
            urls.push(getFacebookPagePluginApiUrl(locale));
        }

        if (showLinkedIn) {
            urls.push([
                getLinkedInApiUrl(time),
                { locale: LocaleMap.linkedin[locale] },
                `lang: ${LocaleMap.linkedin[locale]}`
            ]);
        }

        if (showTwitter) {
            urls.push([
                getTwitterApiUrl(time),
                { locale: LocaleMap.twitter[locale] }
            ]);
        }

        return urls;
    },

    isSimpleStyle = (styleInfo: string): boolean => [RoundStyle, SquareStyle].indexOf(styleInfo) > -1,

    isExtraInfoStyle = (styleInfo: string): boolean => styleInfo === ExtraInfoStyle,

    getWidgetClassname = ({ styleInfo, align }: ShareButtonsCalcRenderPropsResult) => {
        let containerClassname: Record<string, boolean> | string | null = null;
        switch (styleInfo) {
            case HorizontalCountStyle:
                containerClassname = getULClassnames(styleInfo, align, styles.horizontalCountCls); break;
            case VerticalCountStyle:
                containerClassname = getULClassnames(styleInfo, align, styles.verticalCountCls); break;
            case WithoutCountStyle:
                containerClassname = getULClassnames(styleInfo, align, styles.withoutCountCls); break;
            case ExtraInfoStyle:
                containerClassname = getULClassnames(styleInfo, align, styles.extraInfoCls); break;
            case RoundStyle:
                containerClassname = getULClassnames(styleInfo, align, styles.roundStyleCls); break;
            case SquareStyle:
                containerClassname = getULClassnames(styleInfo, align, styles.squareStyleCls); break;
            default: containerClassname = '';
        }
        return containerClassname;
    },

    getWidgetHtml = (props: ShareButtonsCalcRenderPropsResult) => [
        htmlFactory.getFacebookWidgetHtml,
        htmlFactory.getFacebookShareWidgetHtml,
        htmlFactory.getLinkedInWidgetHtml,
        htmlFactory.getTwitterWidgetHtml
    ].map(fn => fn(props)).join(""),

    adjustHeightForExtraInfoStyle = (component: ShareButtonsComponent): ShareButtonsComponent => {
        const { styleInfo, showTwitter, showLinkedIn, showFacebook, showFacebookShare } = component;
        if (styleInfo === ExtraInfoStyle) {
            let height = 0;

            height += showTwitter ? RowHeight : 0;
            height += showLinkedIn ? RowHeight : 0;
            height += showFacebook ? RowHeight : 0;
            height += showFacebookShare ? RowHeight : 0;
            height = height > 0 ? (height + RowPadding) : 0;

            return R.evolve({ height: R.max(height) }, component);
        }

        return component;
    },

    evalComponent = (prop: string, evalFn: Function): Function => R.pipe(
        R.evolve({ [prop]: evalFn }),
        adjustHeightForExtraInfoStyle
    );
