import { OPEN_DIALOG } from "../../../redux/modules/actionTypes";

const openDialogAC = (dialogId: string, props: Record<string, any> = {}) => ({
    type: OPEN_DIALOG, payload: { dialogId, props }
}) as Action;

export {
    openDialogAC as default,
    openDialogAC
};

