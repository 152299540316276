import React from 'react';
import cx from 'classnames';
import * as styles from "./ScrollEffects.css";
import { scrollEffectDisabledTooltip, scrollEffects, scrollEffectStates } from "./constants";
import type { ScrollEffectFactoryProps, ScrollEffectFactoryState } from "./flowTypes";
import type { Intl } from "../../../../view/intl/index";

const
    noScrollDefaultProps = {
        name: scrollEffects.noScroll,
        iconsMap: {
            [scrollEffectStates.disabled]: styles.noScrollDisabledIcon,
            [scrollEffectStates.unselected]: styles.noScrollUnselectedIcon,
            [scrollEffectStates.selected]: styles.noScrollSelectedIcon
        },
        msg: 'msg: common.none {None}',
        payload: null,
        tooltip: 'msg: common.scrollEffects.noScrollTooltip {No scroll effects}',
        state: scrollEffectStates.disabled
    },
    revealDefaultProps = {
        name: scrollEffects.reveal,
        iconsMap: {
            [scrollEffectStates.disabled]: styles.revealDisabledIcon,
            [scrollEffectStates.unselected]: styles.revealUnselectedIcon,
            [scrollEffectStates.selected]: styles.revealSelectedIcon
        },
        payload: scrollEffects.reveal,
        msg: 'msg: common.reveal {Reveal}',
        tooltip: 'msg: common.scrollEffects.revealTooltip {Page moves and image is static}',
        state: scrollEffectStates.disabled
    },
    parallaxDefaultProps = {
        name: scrollEffects.parallax,
        iconsMap: {
            [scrollEffectStates.disabled]: styles.parallaxDisabledIcon,
            [scrollEffectStates.unselected]: styles.parallaxUnselectedIcon,
            [scrollEffectStates.selected]: styles.parallaxSelectedIcon
        },
        msg: 'msg: common.parallax {Parallax}',
        payload: scrollEffects.parallax,
        tooltip: 'msg: common.scrollEffects.parallaxTooltip {Page and image move at different speeds}',
        state: scrollEffectStates.disabled
    };

type EffectProps = {
    hasImage: Boolean,
    scrollEffect?: string|null,
    actionOnScrollEffectChange: string,
    revealExtraProps?: Record<string, any>,
    noScrollExtraProps?: Record<string, any>,
    dispatch: Function,
    intl: Intl
};

export const getEffectProps = ({
    hasImage,
    scrollEffect,
    actionOnScrollEffectChange,
    noScrollExtraProps,
    revealExtraProps,
    dispatch,
    intl
}: EffectProps) => {
    const defaults = { actionOnScrollEffectChange, dispatch, intl };
    let result = {
        noScrollProps: { ...defaults, ...noScrollDefaultProps, ...(noScrollExtraProps || {}) },
        revealProps: { ...defaults, ...revealDefaultProps, ...(revealExtraProps || {}) },
        parallaxProps: { ...defaults, ...parallaxDefaultProps }
    };
    if (!hasImage) {
        result.noScrollProps.state = scrollEffectStates.disabled;
        result.revealProps.state = scrollEffectStates.disabled;
        result.parallaxProps.state = scrollEffectStates.disabled;
        return result;
    }
    result.noScrollProps.state = scrollEffect ? scrollEffectStates.unselected : scrollEffectStates.selected;
    result.revealProps.state = (scrollEffect === scrollEffects.reveal) ?
        scrollEffectStates.selected : scrollEffectStates.unselected;
    result.parallaxProps.state = scrollEffect === scrollEffects.parallax ?
        scrollEffectStates.selected :
        scrollEffectStates.unselected;
    return result;
};

export class ScrollEffectFactory extends React.Component<ScrollEffectFactoryProps, ScrollEffectFactoryState> {
    constructor(props: ScrollEffectFactoryProps) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        const { dispatch, actionOnScrollEffectChange, state, payload } = this.props;
        if (state !== scrollEffectStates.disabled) {
            dispatch({ type: actionOnScrollEffectChange, payload });
        }
    }

    render() {
        const { iconsMap, msg, tooltip, state, intl } = this.props,
            defaultIconClassName = iconsMap[scrollEffectStates.unselected],
            iconClassName = iconsMap[state],
            isDisabled = state === scrollEffectStates.disabled,
            selected = state === scrollEffectStates.selected,
            updatedTooltip = isDisabled ? scrollEffectDisabledTooltip : tooltip;
        return <div
            onClick={this.onClick}
            data-title={intl.msgJoint(updatedTooltip)}
            data-title-position="top"
            data-title-error={isDisabled}
            className={cx(styles.wrapper, { [styles.selected]: selected })}
        >
            <div className={defaultIconClassName}>
                <div className={cx(styles.icon, iconClassName)} />
            </div>
            <div className={styles.text}>
                {intl.msgJoint(msg)}
            </div>
        </div>;
    }
}
