import { DEFAULT_WIDTH, DEFAULT_HEIGHT } from '../constants';
import { type BookingsComponent } from '../types';
import { parseTrafftPostCode } from './parseTrafftPostCode';

export const parseTrafftCode = (code: string): BookingsComponent => {
    let params = <BookingsComponent>{};
    if (code.length) {
        params = parseTrafftPostCode(code);
    }
    return params.link ? { width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT, ...params, } : {};
};
