import type { ComponentBase } from '../components/oneweb/flowTypes';
import type { BBox, Dimensions } from "../redux/modules/children/workspace/flowTypes";
import memo from "../../utils/memo";

export function getBBox(component: ComponentBase): BBox {
    const { top, left, width, height } = component;

    return {
        top,
        left,
        right: left + width,
        bottom: top + height
    };
}

export function getBBoxDimensions(bBox: BBox): Dimensions {
    return {
        width: getWidth(bBox),
        height: getHeight(bBox)
    };
}

export const getBBoxDimensionsMemoized = memo(getBBoxDimensions);

export function getWidth(bBox: BBox): number {
    return bBox.right - bBox.left;
}

export function getHeight(bBox: BBox): number {
    return bBox.bottom - bBox.top;
}

export function getComponentOffsetTopInBBox(bBox: BBox, component: ComponentBase): number {
    return component.top - bBox.top;
}

export function getComponentOffsetLeftInBBox(bBox: BBox, component: ComponentBase): number {
    return component.left - bBox.left;
}

export function isPointInsideBBox(x: number, y: number, bBox: BBox): boolean {
    return bBox.left <= x && x <= bBox.right && bBox.top <= y && y <= bBox.bottom;
}

export function getSelectionBBox(startX: number, startY: number, currentX: number, currentY: number): BBox {
    return {
        left: startX > currentX ? currentX : startX,
        top: startY > currentY ? currentY : startY,
        right: startX < currentX ? currentX : startX,
        bottom: startY < currentY ? currentY : startY
    };
}

export function getBBoxRatio(bBox: BBox) {
    const dimensions = getBBoxDimensions(bBox);
    return dimensions.width / dimensions.height;
}

export const union = (bbox1: BBox, bbox2: BBox): BBox => {
    return {
        top: Math.min(bbox1.top, bbox2.top),
        right: Math.max(bbox1.right, bbox2.right),
        bottom: Math.max(bbox1.bottom, bbox2.bottom),
        left: Math.min(bbox1.left, bbox2.left)
    };
};

export const getBBoxesBoudaries = (bBoxArr: Array<BBox>): BBox => {
    if (bBoxArr.length === 0) {
        throw new Error('getBBoxesBoudaries: bBoxArr can not be empty');
    }

    let
        left = Infinity,
        top = Infinity,
        right = -Infinity,
        bottom = -Infinity;

    bBoxArr.forEach(bBox => {
        if (left > bBox.left) {
            left = bBox.left;
        }

        if (top > bBox.top) {
            top = bBox.top;
        }

        if (right < bBox.right) {
            right = bBox.right;
        }

        if (bottom < bBox.bottom) {
            bottom = bBox.bottom;
        }
    });

    return {
        left,
        top,
        right,
        bottom
    };
};
