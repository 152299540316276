import React, { useRef } from "react";
import Lottie from "react-web-lottie";
import type { ImageAsset } from "../../../App/flowTypes";
import "../../../App/flowTypes";
import animationData from "./animation.json";
import {
    getStepMarkers,
    ONBOARDING_STEPS,
    ELEMENT_INDEXES,
    LOTTIE_DEFAULT_OPTIONS,
    updateLogoImage,
    updateStaticTextData,
    LOGO_PREVIEW_HEIGHT,
    BROKEN_IMAGE_BASE_64
} from "./constant";

type LogoStepRightSideViewProps = {
    logoAsset: ImageAsset | null | undefined;
    websiteTitle: string;
    isImgOnError?: boolean;
};
const markers = animationData.markers;
const stepMarkers = getStepMarkers(markers, ONBOARDING_STEPS.LOGO);
export default function LogoStepRightSideView({ logoAsset, websiteTitle, isImgOnError }: LogoStepRightSideViewProps) {
    const animationRef = useRef(null);

    if (!animationRef.current) {
        updateStaticTextData({
            animationData,
            layerIndex: ELEMENT_INDEXES.email
        });
        updateStaticTextData({
            animationData,
            layerIndex: ELEMENT_INDEXES.phone
        });
        updateStaticTextData({
            animationData,
            layerIndex: ELEMENT_INDEXES.address
        });
    }

    if (logoAsset) {
        if (isImgOnError) {
            animationData.assets[ELEMENT_INDEXES.logoImage].p = BROKEN_IMAGE_BASE_64;
            animationData.assets[ELEMENT_INDEXES.logoImage].w = LOGO_PREVIEW_HEIGHT;
            animationData.assets[ELEMENT_INDEXES.logoImage].h = LOGO_PREVIEW_HEIGHT;
        } else {
            updateLogoImage(animationData, logoAsset);
        }

        updateStaticTextData({
            animationData,
            layerIndex: ELEMENT_INDEXES.title
        });
    } else {
        animationData.assets[ELEMENT_INDEXES.logoImage].p = "";
        updateStaticTextData({
            animationData,
            layerIndex: ELEMENT_INDEXES.title,
            text: websiteTitle
        });
    }

    const defaultOptions = {
        ...LOTTIE_DEFAULT_OPTIONS,
        initialSegment: [stepMarkers.logo_stop_point, stepMarkers.logo_stop_point + 1],
        animationData: JSON.parse(JSON.stringify(animationData))
    };
    return <Lottie lottiRef={animationRef} options={defaultOptions} isClickToPauseDisabled />;
}
