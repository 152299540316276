import React from 'react';
import { WidgetsSoundcloudKind } from './kind';
import { componentMainActions } from './componentMainAction';
import { propertiesPanel } from './propertiesPanel/index';
import { reducer } from './reducer';
import { configurationDialogId } from './configurationDialog';
import { calcRenderProps } from './calcRenderProps';
import { SoundcloudWorkspace } from './view/workspace';
import { SoundcloudView } from './view/index';
import { getMVEHiddenElementData } from '../mobileEditorConfig';
import { WidgetsSoundcloudConfig } from '../constants';

const WidgetsSoundcloud = {
    kind: WidgetsSoundcloudKind,
    label: WidgetsSoundcloudConfig.title,
    shortcutIconName: 'widgetsSoundcloud',
    workspace: SoundcloudWorkspace,
    view: SoundcloudView,
    requireConfigurationOnDrop: () => true,
    configurationDialog: configurationDialogId,
    calcRenderProps,
    componentMainActions,
    propertiesPanel,
    reducer,
    mobileEditorConfig: {
        kind: WidgetsSoundcloudKind,
        calcProps: calcRenderProps,
        view: props => (<SoundcloudView {...props} autoplay={false} />),
        getHiddenElementData: getMVEHiddenElementData(WidgetsSoundcloudConfig),
    },
};

export { WidgetsSoundcloud };

