import { MHFDeactivationDialog } from "./view/index";
import getCenteredDialogConfig from "../../../DialogManager/getCenteredDialogConfig";
import { getCenterPositionForDialog } from "../../../DialogManager/utility";

const HEIGHT = 386,
    WIDTH = 600,
    mhfDeactivationDialog = {
        ...getCenteredDialogConfig(WIDTH, HEIGHT, MHFDeactivationDialog),
        confFactory: ({ browserWidth, browserHeight }) => {
            return {
                position: getCenterPositionForDialog(WIDTH, HEIGHT, browserWidth, browserHeight),
                modal: true,
                dimensions: {
                    width: WIDTH,
                    height: HEIGHT,
                    minHeight: 330
                }
            };
        },
        moveToCenter: true
    };
export { mhfDeactivationDialog };
