import React from "react";
import { Dispatch } from "redux";
import cx from 'classnames';
import styles from './Inserter.css';
import { injectIntl } from "../../../view/intl/index";
import * as expandedPanelActions from "../actionCreators/actions";
import * as mouseUtils from "../../../utils/mouse";
import { NewTag } from "../../../view/common/Tag/index";
import { PremiumIcon } from "../../../view/common/PremiumIcon/index";
import { isComponentOrFeatureTierDisabledByConfiguration } from "../../ComponentTierManager/utils";
import disabledCmpTooltipHandlers from "./disabledCmpTooltipHandlers";

type DroppableCmpProps = {
    dispatch: Dispatch,
    kind: string,
    children?: any, // Fix this
    payload?: Record<string, any>,
    className?: string,
    isOverlayExtended?: boolean,
    isNoOverlay?: boolean,
    disabledComponentKind?: string,
}

export const ShortCut = injectIntl(
    // @ts-ignore
    ({ iconClassName, text, textClassName = '', kind, hasUpdate = false, intl }) => {
        return [
            <div key={1} className={cx(styles.icon, iconClassName)}>
                {hasUpdate &&
                    <div className={styles.hasUpdate} data-kind={kind} />}
            </div>,
            <div key={2} className={cx(styles.shortCutText, textClassName)}>
                {intl.msgJoint(text)}
            </div>
        ];
    }
);

export const ExtendedPanelShortCut = (props: Record<string, any>) => (
    <div className={styles.extendedPanelShortCut} {...props.mouseEventHandlers}>
        <ShortCut {...props} />
        {props.isNew && <NewTag containerClassName={styles.newLabelContainer} />}
        {props.showPremiumIcon && <PremiumIcon />}
    </div>
);

export const DroppableCmpShortcut = ({
    dispatch,
    children,
    kind,
    payload = {},
    className,
    disabledComponentKind,
    isOverlayExtended
}: DroppableCmpProps) => {
    if (isComponentOrFeatureTierDisabledByConfiguration((kind as any))) {
        return null;
    }
    if (disabledComponentKind) {
        return (
            <div className={
                cx(styles.droppableCmp, className,
                    { [styles.overlayExtended]: isOverlayExtended })
            }
            >
                <div
                    className={styles.disabledOverlay}
                    {...disabledCmpTooltipHandlers({ componentKind: disabledComponentKind, dispatch })}
                />
                {children}
            </div>);
    }
    const
        onMouseDown = (e) => {
            mouseUtils.onMouseDown(
                e,
                () => dispatch(expandedPanelActions.onShortCutMouseDown(kind, payload)),
                () => null
            );
        },
        onClick = () => dispatch(expandedPanelActions.onShortCutClick(kind, payload));

    return (
        <div
            onMouseDown={onMouseDown}
            onClick={onClick}
            className={
                cx(styles.droppableCmp, className,
                    { [styles.overlayExtended]: isOverlayExtended })
            }
        >
            {children}
        </div>
    );
};
