import makeEpic from '../../../../epics/makeEpic';
import valueActionType from "./valueActionType";
import selectionFrameDecorationEvalActionType from '../selectionFrameDecorationEval/valueActionType';

export default makeEpic({
    valueActionType,
    updaters: [
        {
            conditions: [selectionFrameDecorationEvalActionType],
            reducer: ({ values: [{ state }] }) => ({ state })
        }
    ]
});

