import { AbTest } from "../../../server/shared/abt/AbTest";
import { ABT_VARIANTS_KEY } from "../../../server/shared/abt/constants";
import isTestEnv from "../debug/isTestEnv";

let singleton;

export const wbtgenAbt = () => {
    if (!singleton || isTestEnv()) {
        singleton = new AbTest(window[ABT_VARIANTS_KEY]);
    }
    return singleton;
};
