import React, { useEffect, useRef } from "react";
import { Button, DialogContent } from "@mui/material";
import { useDispatch } from "react-redux";

import VerticalSpacer from "../../../../../../wbtgen/src/view/common/VerticalSpacer";
import { isUnknownSubscription } from "../../../../../../wbtgen/src/components/App/epics/subscriptionData/utils";
import { UpgradeCancelReason } from "../../../../../../wbtgen/src/components/EventTracking/constants";

import { SubscriptionTypes } from "./SubscriptionTypes/index";
import { useStyles } from "./styles";
import { useIntl } from "../../../../../../wbtgen/src/view/intl";
import { SubscriptionData } from "../../../../../../wbtgen/src/redux/modules/flowTypes";
import { filterAvailableUpgrades } from "./SubscriptionTypes/utils";
import { ArrowBackIcon } from "../../../common/icons";
import common from "../../../../../../wbtgen/src/view/Theme/common.css";
import { useIsMobile } from "../../../hooks/useIsMobileOrTablet";
import { closeDialogAction } from "../../Dialog/actions";
import { upgradeCanceledAction } from "./actions";

type Props = {
    subscriptionData: SubscriptionData,
    allowedUpgradeTypes: string[],
}

export const UpgradePlansDialogBody = ({ subscriptionData, allowedUpgradeTypes } : Props) => {
    const classes = useStyles();
    const intl = useIntl();
    const isMobile = useIsMobile();
    const dispatch = useDispatch();
    const { availableUpgrades, subscriptionType } = subscriptionData;
    const paymentPlanContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isUnknownSubscription(subscriptionType)) {
            dispatch(closeDialogAction());
        }
    }, []);

    if (isUnknownSubscription(subscriptionType)) return null;

    const isFreeUpgradeAvailable = filterAvailableUpgrades(availableUpgrades, allowedUpgradeTypes)
        .filter(plan => subscriptionType !== plan.addonProductToUpgrade)
        .some(plan => plan.freeUpgradeAvailable);

    const description = isFreeUpgradeAvailable ?
        "msg: upgrade.plans.dialog.description.free {We believe in your vision, here is a free-upgrade for you!}" :
        "msg: upgrade.plans.dialog.paidDescription {Upgrade Website Builder today to access advanced features to get the most out of your website}";

    return (
        <DialogContent className={classes.dialogContent}>
            <Button
                disableRipple
                disableElevation
                className={classes.backBtn}
                onClick={() => {
                    dispatch(upgradeCanceledAction(UpgradeCancelReason.UserCancelled));
                    dispatch(closeDialogAction());
                }}
            >
                <ArrowBackIcon sx={{ color: common.colorGray_8a, fontSize: 18 }} />
                <span className={classes.backBtnText}>{intl.msgJoint('msg: common.back {Back}')}</span>
            </Button>
            <VerticalSpacer y={isMobile ? 16 : 4} />
            <div className={classes.dialogTitle}>
                {intl.msgJoint("msg: upgrade.plans.dialog.title {Upgrade your Website Builder}")}
            </div>
            <VerticalSpacer y={8} />
            <div className={classes.dialogSubTitle}>
                {intl.msgJoint(description)}
            </div>
            <VerticalSpacer y={isMobile ? 24 : 40} />
            <div className={classes.paymentPlanContainer} ref={paymentPlanContainerRef}>
                <SubscriptionTypes
                    subscriptionData={subscriptionData}
                    allowedUpgradeTypes={allowedUpgradeTypes}
                />
            </div>
            <VerticalSpacer y={35} />
        </DialogContent>
    );
};
