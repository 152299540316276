import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
    PrimaryButton,
    SecondaryButton
} from '../../../../../../view/common/Button';

import { useIntl } from '../../../../../../view/intl/index';

import {
    AI_TEXT_CLOSE_DIALOG,
    AI_TEXT_GENERATE_CLICKED
} from "../../../epics/AITextEpic/actionTypes";

import styles from './styles.css';
import { aiTextSelector } from '../../../epics/AITextEpic/selectors';

export const FooterSection = ({ aiData }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const aiTextState = useSelector(aiTextSelector);

    const {
        isQueryInProgress,
        isAnyFieldChanged
    } = aiTextState;

    const getGenerateButtonContent = () => {
        if (isAnyFieldChanged) {
            return intl.msgJoint("msg: common.createText {Create text}");
        }

        return intl.msgJoint("msg: common.tryAgain {Try again}");
    };

    return (
        <div className={styles.footerContainer}>
            <div className={styles.buttonContainer}>
                <SecondaryButton
                    className={styles.actionButton}
                    onClick={() => {
                        dispatch({ type: AI_TEXT_CLOSE_DIALOG });
                    }}
                >
                    {intl.msgJoint("msg: common.cancel {Cancel}")}
                </SecondaryButton>
                <PrimaryButton
                    className={styles.actionButton}
                    onClick={() => {
                        if (!isQueryInProgress) {
                            dispatch({ type: AI_TEXT_GENERATE_CLICKED, payload: aiData });
                        }
                    }}
                    disabled={isQueryInProgress}
                >
                    {getGenerateButtonContent()}
                </PrimaryButton>
            </div>
        </div>
    );
};
