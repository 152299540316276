import type { ImageComponent } from "../flowTypes";
import ScaleStrategies, { isFitMode } from '../scaleStrategy';
import { fixHeightForFitMode } from './fixHeightForFitMode';
import { getMinZoom } from '../componentSelectors';
import { customSendReport } from "../../../../customSendCrashReport";

export default (component: ImageComponent, { payload: { asset } }: any) => {
    if (!asset) {
        customSendReport({
            crashReportId: 'IMAGE_ASSET_NULL',
            message: 'Setting image asset to null',
            additionalInfo: {
                imageId: component.id,
                stackTrace: console.trace() // eslint-disable-line
            }
        });
        return component;
    }
    const newComponent: ImageComponent = { ...component, asset };

    if (newComponent.scaleStrategy === ScaleStrategies.CROP) {
        return {
            ...newComponent,
            scale: getMinZoom(newComponent)
        };
    }

    return isFitMode(newComponent) ? fixHeightForFitMode(newComponent) : newComponent;
};
