import CALL_API from "../../../redux/middleware/api/CALL_API";
import {
    GET_DESIGN_SITE_SETTINGS_REQUEST,
    GET_DESIGN_SITE_SETTINGS_SUCCESS,
    GET_DESIGN_SITE_SETTINGS_FAILURE
} from "../actionTypes";
import type { ApiAction } from "../../../redux/middleware/api/flowTypes";

export default (designAccountName: string): ApiAction => ({
    [CALL_API]: {
        types: [
            GET_DESIGN_SITE_SETTINGS_REQUEST,
            GET_DESIGN_SITE_SETTINGS_SUCCESS,
            GET_DESIGN_SITE_SETTINGS_FAILURE
        ],
        endpoint: 'getDesignSiteSettings',
        endpointParams: designAccountName
    }
});
