import * as R from 'ramda';
import { CaptionStyles } from "../../../presentational/CaptionStyle/constants";
import { BLACK_THEME_COLOR, WHITE_THEME_COLOR } from "../../../ThemeGlobalData/constants";
import { Color } from '../../../../mappers/flowTypes';

export const
    IMAGE_RATIO_NUM_MAP = {
        SQUARE: 1,
        LANDSCAPE: 2,
    },
    IMAGE_MAX_DIMENSION = 16384, // todo check image implementation, move to common place
    IMAGE_SCALE_MAX_PIXELS = 16777216,
    errorColor = '#D64949',
    columnOptions = R.range(1, 7).map(v => ({ value: v, label: v })),
    rowOptions = R.range(1, 6).map(v => ({ value: v, label: v })),
    SHOW_MORE_BTN_HEIGHT = 75,
    SLIDER_MAX_SIZE = 120,
    SLIDER_MAX_SIZE_MVE = 20,
    SHOW_MORE_BTN_FONT_MAX_SIZE = 50,
    PROFILE_IMAGE_HOLDER_SIZE = 90,
    EXPANDED_PROFILE_IMAGE_HOLDER_SIZE = 500,
    LIMIT = {
        showTitleCount: (text: string) => {
            return text.length >= (LIMIT.title.hard - 10);
        },
        showCaptionCount: (text: string) => {
            return text.length >= (LIMIT.caption.hard - 10);
        },
        getTitleBorder: (text: string) => {
            return text.length >= (LIMIT.title.hard - 10) ?  // eslint-disable-line no-nested-ternary
                errorColor : "";
        },
        getCaptionBorder: (text: string) => {
            return text.length >= (LIMIT.caption.hard - 10) ?  // eslint-disable-line no-nested-ternary
                errorColor : "";
        },
        title: {
            hard: 70,
            soft: 60
        },
        caption: {
            threshold: 1000,
            hard: 500,
            soft: 180,
            max: 1500
        }
    },
    MinGalleryDimensions = {
        width: 40,
        height: 40
    },
    MinThumbnailSize = {
        width: 10,
        height: 10
    },
    Padding = {
        MIN: 5,
        MAX: 80,
        DEFAULT_TOP: 9,
        DEFAULT_BOTTOM: 14,
        DEFAULT_LEFT: 20,
        DEFAULT_RIGHT: 20
    },
    titleDefaultStyle = {
        color: ["HSL", 0, 0, 0.06666666666666667, 1] as Color,
        themeColor: BLACK_THEME_COLOR,
        font: 'Helvetica',
        size: 12,
        bold: false,
        underline: false,
        italic: false,
        lineHeight: 1.3,
        opacity: 0.5,
    },
    descriptionDefaultStyle = {
        ...titleDefaultStyle,
        bold: false,
        lineHeight: 1.2,
        size: 13,
        opacity: 0.5,
    },
    captionDefaults = {
        box: {
            [CaptionStyles.CLASSIC]: {
                padding: {
                    top: 20,
                    bottom: 20,
                    left: 15,
                    right: 15
                },
                background: {
                    colorData: {
                        color: ["HSL", 0, 0.09090909090909068, 0.9784313725490196, 1] as Color,
                        themeColor: WHITE_THEME_COLOR,
                    }
                }
            },
            [CaptionStyles.TEMPLATE]: {
                padding: {
                    top: 9,
                    bottom: 14,
                    left: 5,
                    right: 5
                }
            },
            [CaptionStyles.CUSTOM]: {}
        }
    },
    CAPTION_CONTAINER_HEIGHT_MAP = {
        "1": 158,
        "2": 163,
        "3": 164,
        "4": 161,
        "5": 161,
        "6": 161,
    },
    FB_POST_IMAGE_MEDIA_TYPE = 'photo',
    FB_POST_VIDEO_AUTOPLAY_MEDIA_TYPE = 'video_autoplay',
    FB_POST_VIDEO_INLINE_MEDIA_TYPE = 'video_inline',
    FB_POST_VIDEO_MEDIA_TYPE = 'video',
    FB_POST_SHARE_MEDIA_TYPE = 'share',
    FB_POST_PROFILE_MEDIA_TYPE = "profile_media",
    FB_VIDEO_MEDIA_TYPES = [
        FB_POST_VIDEO_AUTOPLAY_MEDIA_TYPE,
        FB_POST_VIDEO_INLINE_MEDIA_TYPE,
        FB_POST_VIDEO_MEDIA_TYPE,
    ],
    MAX_COOKIE_CHECK_COUNT = 120,
    FACEBOOK_FEED_IMAGE = 'IMAGE',
    FACEBOOK_FEED_VIDEO = 'VIDEO',
    FACEBOOK_FEED_CAROUSEL_ALBUM = 'CAROUSEL_ALBUM',
    SHINYBOX_VIDEO_MEDIA_TYPE = 'SELF_HOSTED_VIDEO',
    SHINYBOX_NO_FACEBOOK_MEDIA_TYPE = 'NO_FACEBOOK_MEDIA',
    SHINYBOX_ALBUM_MEDIA_TYPE = 'ALBUM',
    DRAG_AND_DROP_WIDTH = 149,
    DRAG_AND_DROP_HEIGHT = 108;

