// @ts-nocheck
import isTestEnv from '../../../../../../debug/isTestEnv';
import type {
    Node
} from '../../../../flowTypes';
import makeIsSizeOverriden from './makeIsSizeOverriden';

export default (defaultGlobalStyles: Array < Stylesheet >) => {
    const isSizeOverriden = makeIsSizeOverriden(defaultGlobalStyles);

    return (styleName: string, node: Node) => {
        if (isTestEnv()) {
            return false;
        }

        switch (styleName) {
            case 'size':
                return isSizeOverriden(node);
            default:
                return false;
        }
    };
};
