export const
    IMAGE_PROP_PANEL_SCALE_STRATEGY = 'IMAGE_PROP_PANEL_SCALE_STRATEGY',
    IMAGE_PROP_PANEL_SCALE_CHANGE = 'IMAGE_PROP_PANEL_SCALE_CHANGE',
    LOGO_TITLE_SCALE_CHANGED = 'LOGO_TITLE_SCALE_CHANGED',
    IMAGE_PROP_PANEL_ROTATE_RIGHT = 'IMAGE_PROP_PANEL_ROTATE_RIGHT',
    IMAGE_PROP_PANEL_ROTATE_LEFT = 'IMAGE_PROP_PANEL_ROTATE_LEFT',
    IMAGE_REPLACE_IMAGE = 'IMAGE_REPLACE_IMAGE',
    IMAGE_SELECTED_FOR_REPLACE = 'IMAGE_SELECTED_FOR_REPLACE',
    IMAGE_UPDATE_ON_REPLACE = 'IMAGE_UPDATE_ON_REPLACE',
    IMAGE_PROP_PANEL_ALT_TEXT = 'IMAGE_PROP_PANEL_ALT_TEXT',
    IMAGE_PROP_PANEL_BORDER_WIDTH = 'IMAGE_PROP_PANEL_BORDER_WIDTH',
    IMAGE_PROP_PANEL_BORDER_CORNER = 'IMAGE_PROP_PANEL_BORDER_CORNER',
    IMAGE_PROP_PANEL_BORDER_OPACITY = 'IMAGE_PROP_PANEL_BORDER_OPACITY',
    IMAGE_PROP_PANEL_BORDER_TYPE = 'IMAGE_PROP_PANEL_BORDER_TYPE',
    IMAGE_PROP_PANEL_BORDER_COLOR = 'IMAGE_PROP_PANEL_BORDER_COLOR',
    IMAGE_PROP_PANEL_BORDER_COLOR_AUTO_COLOR = 'IMAGE_PROP_PANEL_BORDER_COLOR_AUTO_COLOR',
    IMAGE_PROP_PANEL_ON_CLICK_ACTION_CHANGED = 'IMAGE_PROP_PANEL_ON_CLICK_ACTION_CHANGED',
    IMAGE_SET_LINK = 'IMAGE_SET_LINK',
    IMAGE_REMOVE_LINK = 'IMAGE_REMOVE_LINK',
    IMAGE_EMPTY_SRC = 'IMAGE_EMPTY_SRC',
    IMAGE_SET_IMAGE = 'IMAGE_SET_IMAGE',
    IMAGE_MCTA_HORIZONTAL_ALIGNMENT_CHANGED = 'IMAGE_MCTA_HORIZONTAL_ALIGNMENT_CHANGED',
    LOGO_MCTA_RESIZE_CHANGED = 'LOGO_MCTA_RESIZE_CHANGED';
