import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from "@mui/material";

import { StandardDialog } from "../../../Dialog/StandardDialog";
import { resetSelectedDevice } from "./actions";
import { closeDialogAction } from "../../../Dialog/actions";

import imageStyles from "../../../Images/imageStyle.css";

const Styles = makeStyles(() => ({
    containerClass: {
        padding: "71px 60px 56px",
    },
    iconWrapper: {
        width: "182px",
        height: "182px",
        margin: "auto",
    },
    qrTextDescription: {
        fontSize: "18px",
        textAlign: "center",
        paddingTop: "20px",
    }
}), { name: "QRCodeDialog" });

const getButtons = (intl, dispatch) => {
    return [
        {
            type: "contained",
            text: (
                <div>
                    <span>{intl.msgJoint("msg: tooltip.BrowserZoom.close {Got it!}")}</span>
                </div>
            ),
            onClick: () => {
                dispatch(closeDialogAction());
                dispatch(resetSelectedDevice());
            },
        },
    ];
};

type QRCodeDialogProps = {
    intl: Intl,
    selectedDevice: string,
    dispatch: Dispatch,
}

export const QRCodeDialogId = 'QRCodeDialogId';

const QRCodeDialogComponent = ({ intl, selectedDevice, dispatch }: QRCodeDialogProps) => {
    const
        classes = Styles(),
        onClose = () => {
            dispatch(closeDialogAction());
            dispatch(resetSelectedDevice());
        };
    return (
        <StandardDialog
            onClose={onClose}
            title={intl.msgJoint("msg: dashboard.overview.companion.copy.title {Copy the QR code}")}
            buttons={getButtons(intl, dispatch)}
            containerClass={classes.containerClass}
            dialogId={QRCodeDialogId}
        >
            <Grid container justifyContent="center">
                <Grid item>
                    <div className={classes.iconWrapper}>
                        <div className={cx(imageStyles.image, imageStyles[selectedDevice + "QrCode"])} />
                    </div>
                    <div className={classes.qrTextDescription}>
                        {intl.msgJoint("msg: dashboard.overview.companion.copy.description {Point your mobile phone camera at the QR code and you’ll automatically be sent to the app download.}")}
                    </div>
                </Grid>
            </Grid>
        </StandardDialog>
    );
};

const mapStateToProps = (state) => ({
    selectedDevice: state.overviewCompanion.selectedDevice,
});

export const QRCodeDialog = connect(mapStateToProps)(QRCodeDialogComponent);
