import * as React from 'react';
import cx from 'classnames';
import OnClickLink from '../../../presentational/OnClickLink/index';
import './Mobile.css';

export type imagePreview = {
    imageAttributes: Record<string, any>,
    divClassName: string,
    divStyle: Record<string, any>,
    children: React.CElement<any, any>,
    width: string,
    height: string,
    a: {
        href: string,
        target: string,
        title?: string,
        rel?: string,
        className?: string,
        style?: any,
        'data-dom-index'?: number,
    },
    groupType?: string,
    paddingBottom: number
};

const linkClassname = 'has-link';
export const imageContainerClass = 'image-container';

export default ({ a, imageAttributes, divClassName, divStyle, children, width, height, paddingBottom }: imagePreview) => {
    if (children) { // possible only on preview
        let newStyle: Record<string, any> = { ...divStyle };
        let aProps = { ...a };
        if (a) {
            aProps = {
                ...aProps,
                style: {
                    ...aProps.style,
                    paddingBottom
                }
            };
        } else {
            newStyle.paddingBottom = paddingBottom;
        }

        delete newStyle.width;
        return (
            <div
                className={cx(imageContainerClass, divClassName, { [linkClassname]: !!a })}
                style={newStyle}
                data-width={width}
                data-height={height}
                title={imageAttributes.title}
            >
                {a ? (<OnClickLink {...aProps}>{children}</OnClickLink>) : children}
            </div>
        );
    }
    const img = (<img
        // once the api is stable, convert "data-loading" to "loading"
        loading="lazy" // Lazy-load an offscreen image when the user scrolls near it
        role="presentation"
        {...imageAttributes}
        data-width={width}
        data-height={height}
    />);
    return (
        <div className={`${divClassName}`} style={divStyle} data-width={width} data-height={height}>
            {a ? (<a {...a} data-stop-propagation="true">{img}</a>) : img}
        </div>
    );
};
