/* eslint-disable max-len */
import * as actionTypes from './actionTypes';
import { makeSiteSettingsChildEpic } from "../../App/epics/siteSettings/makeSiteSettingsChildEpic";

export type TrackingGlobalDataType = {
    gaMeasurementId: string,
    pixelId: string,
    enableGoogleAds: boolean,
}

export const trackingGlobalDataDefaultState: TrackingGlobalDataType = {
    gaMeasurementId: '',
    pixelId: '',
    enableGoogleAds: true,
};

export const trackingGlobalDataEpic = makeSiteSettingsChildEpic/* ::<TrackingGlobalDataType, empty> */({
    key: 'trackingData',
    defaultState: trackingGlobalDataDefaultState,
    undo: true,
    updaters: [
        {
            conditions: [actionTypes.GOOGLE_ANALYTICS_VALUE_CHANGE],
            reducer: ({ state, values: [{ gaMeasurementId }] }: { state: TrackingGlobalDataType, values: [{ gaMeasurementId: string }] }) => ({
                state: { ...state, gaMeasurementId },
                updateReason: actionTypes.GOOGLE_ANALYTICS_VALUE_CHANGE
            })
        },
        {
            conditions: [actionTypes.FACEBOOK_PIXEL_VALUE_CHANGE],
            reducer: ({ state, values: [{ pixelId }] }) => ({
                state: { ...state, pixelId },
                updateReason: actionTypes.FACEBOOK_PIXEL_VALUE_CHANGE
            })
        },
        {
            conditions: [actionTypes.ENABLE_GOOGLE_ADS_TOGGLE],
            reducer: ({ state }) => {
                const
                    { enableGoogleAds: oldValue } = state,
                    enableGoogleAds = !oldValue;

                return {
                    state: { ...state, enableGoogleAds },
                    updateReason: actionTypes.ENABLE_GOOGLE_ADS_TOGGLE
                };
            }
        }
    ]
});
