// @ts-nocheck
import Resource from './Resource';

export default class VideoResource extends Resource {
    constructor(basePath, data) {
        super(basePath, data);
        this.metadata = { ...data, url: this.getWebSpaceProtocolRelativePath() };
    }

    getAssetMetadata() {
        return this.metadata;
    }
}
