import { width, height } from './constants';
import LinkChooserDialog from './view/index';
import getCenteredDialogConfig from "../../../../components/DialogManager/getCenteredDialogConfig";
import siteDataEpic from '../../../../components/App/epics/siteData/index';
import reducer from './reducer/index';
import makeDefaultState from "./reducer/makeDefaultState";
import subscriptionDataEpic from '../../../../components/App/epics/subscriptionData/index';
import { globalVariablesEpic } from '../../../../components/App/epics/globalVariablesEpic';

export default getCenteredDialogConfig({
    width,
    height,
    component: LinkChooserDialog,
    dependsOn: {
        site: siteDataEpic.reducer,
        globalVariables: globalVariablesEpic.reducer,
        subscriptionData: subscriptionDataEpic.reducer,
    },
    reducer,
    makeDefaultState,
});
