/* eslint-disable max-len */
import React, { useContext } from "react";
import { useDispatch } from "react-redux";

import { InputField } from "../Common/InputField";
import { DynamicTemplateContext } from "../../Context";

import { FIELD_NAMES, STEP_NAMES, TEXT_CHARACTER_LIMIT_ON_STEP_FIELD } from "../../../constants";

import stepStyles from "../steps.css";
import { DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA } from "../../../Epic/actionTypes";
import { getKeyForStep } from "../utils";

export const KeywordsField = (props) => {
    const dispatch = useDispatch();
    const { showError, setShowError, value, setValue } = props;
    const counterLimit = TEXT_CHARACTER_LIMIT_ON_STEP_FIELD.keywords.max;
    const { concept: { name, type }, gmbKey } = useContext(DynamicTemplateContext);

    return (
        <div className={stepStyles.stepFieldContainer}>
            <InputField
                multiline
                label="msg: onboarding.dynamic.step3.label {Important to mention}"
                error={showError}
                errorMsg="msg: common.error.tooLong {The text you entered is too long.}"
                msgCounter={{
                    limit: counterLimit,
                    current: value.length
                }}
                value={value}
                onKeyDown={(event) => {
                    if (!['PageUp', 'PageDown'].includes(event.key)) { return; }
                    event.preventDefault();
                    const isPageUp = event.key === 'PageUp';
                    const cursorPosition = isPageUp ? 0 : event.target.textLength;
                    event.target.setSelectionRange(cursorPosition, cursorPosition);
                    event.target.scrollTo(0, cursorPosition);
                }}
                onChange={(e) => {
                    const v = e.target.value;
                    if (v.length > counterLimit) {
                        setShowError(true);
                    } else {
                        setShowError(false);
                    }

                    setValue(v);
                    dispatch({
                        type: DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA,
                        payload: { path: "keywords", value: v }
                    });
                }}
                rootclass={stepStyles.multilineRootClass}
                inputclass={stepStyles.multilineInputClass}
                placeholder={getKeyForStep(name, type, gmbKey, STEP_NAMES.ABOUT_KEYWORDS, FIELD_NAMES.PLACEHOLDER)}
            />
        </div>
    );
};
