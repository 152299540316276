import * as R from "ramda";
import makeEpic from "../../../epics/makeEpic";
import pageDatasetValueActionType from "../../App/epics/pageDataset/valueActionType";
import {
    LOAD_TEMPLATE_SWITCH_TEMPLATE_SET_REQUEST_ACTION,
    SWITCH_PAGE_TEMPLATE_ACTION,
    LOAD_TEMPLATE_SWITCH_TEMPLATE_SET_SUCCESS_ACTION,
    LOAD_TEMPLATE_SWITCH_TEMPLATE_SET_FAILURE_ACTION,
    loadPageTemplateSwitchTemplateSetAction
} from "./actions";
import { holdPageTemplateSelectorAction, unholdPageTemplateSelectorAction } from "../PageTemplateSelector/actions";
import mergePageLayoutWithTemplate from "../pagesLogic/mergePageLayoutWithTemplate";
import {
    UPDATE_PAGE_TEMPLATE_FAILURE_ACTION,
    UPDATE_PAGE_TEMPLATE_SUCCESS_ACTION,
    updatePageTemplateActionApiAction
} from "../../App/epics/pageDataset/actions";
import { openPageTemplateSelectorErrorDialog } from "../PageTemplateSelector/dialogs/errorDialog/actions";
import { closeDialog } from "../../App/actionCreators";
import type { MsgJointInput } from "../../../view/intl";
import { preparePersistentModel } from "../../../../dal/model/utils";
import { receiveOnly } from "../../../epics/makeCondition";
import { checkUnsavedChangesAction } from "../../UnsavedChanges/actionCreators";
import { CANCEL_UNSAVED_CHANGES, UNSAVED_CHANGES_CHECKED } from "../../UnsavedChanges/actionTypes";
import { componentAttachmentsVAT } from "../../Workspace/epics/componentAttachements/valueActionType";
import { ReceiveOnlyComponentsMap } from "../../Workspace/epics/componentsEval/selectorActionTypes";
import { getHeaderAndFooterSection } from "../../oneweb/Section/utils";

const makeRequestFailureUpdater = (action: string, msg: MsgJointInput) => ({
    conditions: [action],
    reducer: ({ state, scope }) => ({
        state,
        scope,
        multipleActionsToDispatch: [
            unholdPageTemplateSelectorAction(),
            openPageTemplateSelectorErrorDialog({ msg })
        ]
    })
});
const getPageComponents = (sectionId: string, cmpsMap: Object, attachments: Object) => {
    const sectionAttachments = attachments[sectionId] || [];
    return R.pipe(
        R.map(id => cmpsMap[id]),
        R.filter(cmp => !cmp.inTemplate),
        R.sort((cmp1, cmp2) => (cmp1.top - cmp2.top))
    )(sectionAttachments);
};

export const PAGE_TEMPLATE_SWITCH_VAT = 'PAGE_TEMPLATE_SWITCH_VAT';
export const pageTemplateSwitchEpic = makeEpic({
    defaultState: null,
    defaultScope: { checkingUnsavedChanges: false },
    valueActionType: PAGE_TEMPLATE_SWITCH_VAT,
    updaters: [
        {
            conditions: [SWITCH_PAGE_TEMPLATE_ACTION],
            reducer: ({ state, scope, values: [newTemplateId] }) => ({
                state,
                scope,
                actionToDispatch: loadPageTemplateSwitchTemplateSetAction(newTemplateId)
            })
        },
        {
            conditions: [LOAD_TEMPLATE_SWITCH_TEMPLATE_SET_REQUEST_ACTION],
            reducer: ({ state, scope }) => ({
                state,
                scope,
                actionToDispatch: holdPageTemplateSelectorAction()
            })
        },
        {
            conditions: [LOAD_TEMPLATE_SWITCH_TEMPLATE_SET_SUCCESS_ACTION],
            reducer: ({ state }) => ({
                state,
                scope: { checkingUnsavedChanges: true },
                actionToDispatch: checkUnsavedChangesAction()
            })
        },
        makeRequestFailureUpdater(
            LOAD_TEMPLATE_SWITCH_TEMPLATE_SET_FAILURE_ACTION,
            'msg: common.unableToFetchTemplateSet {Unable to load page template data.}'
        ),
        {
            conditions: [UPDATE_PAGE_TEMPLATE_SUCCESS_ACTION],
            reducer: ({ state, scope }) => ({
                state,
                scope,
                actionToDispatch: closeDialog()
            })
        },
        makeRequestFailureUpdater(
            UPDATE_PAGE_TEMPLATE_FAILURE_ACTION,
            'msg: common.unableToUpdatePageTemplate {Unable to save page template.}'
        ),
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                receiveOnly(componentAttachmentsVAT),
                receiveOnly(pageDatasetValueActionType),
                receiveOnly(LOAD_TEMPLATE_SWITCH_TEMPLATE_SET_SUCCESS_ACTION),
                UNSAVED_CHANGES_CHECKED
            ],
            reducer: ({ state, scope, values: [cmpsMap, { attachments }, pageDataSet, newTemplateDataSet] }) => {
                if (!scope.checkingUnsavedChanges) return { state, scope };

                const
                    { header, footer } = getHeaderAndFooterSection(cmpsMap),
                    headerPageCmps = header ? getPageComponents(header.id, cmpsMap, attachments) : [],
                    footerPageCmps = footer ? getPageComponents(footer.id, cmpsMap, attachments) : [];

                // merge page with template
                const newPageDataSet = mergePageLayoutWithTemplate(
                    pageDataSet,
                    newTemplateDataSet,
                    pageDataSet.page.id,
                    headerPageCmps,
                    footerPageCmps
                );

                return {
                    state,
                    scope: { checkingUnsavedChanges: false },
                    actionToDispatch: updatePageTemplateActionApiAction({
                        page: preparePersistentModel(newPageDataSet.page),
                        template: preparePersistentModel(newPageDataSet.template),
                        stylesheet: preparePersistentModel(newPageDataSet.stylesheet)
                    })
                };
            }
        },
        {
            conditions: [CANCEL_UNSAVED_CHANGES],
            reducer: ({ state, scope }) => (
                scope.checkingUnsavedChanges
                    ? { state, scope: { ...scope, checkingUnsavedChanges: false } }
                    : { state, scope }
            )
        }
    ]
});
