import * as React from 'react';
import styles from './dialog.css';
import WarningDialog from "../../../../../view/common/dialogs/WarningDialog/index";
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import { MsgJointInput, injectIntl, Intl } from "../../../../../view/intl/index";
import subscriptionDataEpic from '../../../../App/epics/subscriptionData/index';
import { getPremiumPackageNameFromMetadata } from '../../../../ComponentTierManager/utils';
import type { SubscriptionData } from '../../../../../redux/modules/flowTypes';

const
    WIDTH = 480,
    HEIGHT = 240;

export type ShutterStockQuotaLimitDialogProps = {
    title: MsgJointInput,
    messages: Array<MsgJointInput>,
    intl: Intl,
};

type ShutterStockQuotaLimitDialogCmpProps = ShutterStockQuotaLimitDialogProps & {
    subscriptionData: SubscriptionData,
};

const ShutterStockQuotaLimitDialogCom = injectIntl(
    ({ title, messages, intl, subscriptionData: { metadata: subscriptionMetadata } }: ShutterStockQuotaLimitDialogCmpProps) => {
        const premiumPackageNameFromMetadata = getPremiumPackageNameFromMetadata(subscriptionMetadata);
        return (
            <WarningDialog title={title} mctaText="msg: common.ok {OK}">
                <React.Fragment>
                    {messages.map((msg, i) => (
                        <p className={styles.quotaLimitParagraph} key={i}>{intl.msgJoint([
                            msg,
                            {
                                premiumPackageName: premiumPackageNameFromMetadata || intl.msgJoint('msg: common.premium {Premium}')
                            }
                        ] as MsgJointInput)}</p>
                    ))}
                </React.Fragment>
            </WarningDialog>
        );
    }
);

export const shutterstockQuotaLimitDialog = {
    ...getCenteredDialogConfig(
        WIDTH,
        HEIGHT,
        ShutterStockQuotaLimitDialogCom
    ),
    dependsOn: {
        subscriptionData: subscriptionDataEpic.reducer,
    },
};
