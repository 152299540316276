import mainPage from './pages/home';
import htmlCodePage from './pages/htmlCode';
import placementPage from './pages/placement';
import * as mobileView from "../../../PropertiesPanel/view/MobileView/page";
import makePages from "../../../PropertiesPanel/view/makePages";

export default {
    pages: makePages(mainPage, htmlCodePage, placementPage, mobileView),
    defaultPageId: mainPage.id
};
