import React, { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";

import { InputField } from "../Common/InputField";

import isTestEnv from '../../../../../../debug/isTestEnv';
import { loadGoogleMapsScript, placeToAddress } from "../../../../../googleMaps/googleMaps";
import { DEFAULT_COMPLETE_ADDRESS, DEFAULT_ADDRESS_LOCATION } from "../../../constants";
import { DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA } from "../../../Epic/actionTypes";

type AutocompleteServiceType = {
    current: any;
}

const autocompleteService: AutocompleteServiceType = { current: null };

export const AddressField = (props) => {
    const dispatch = useDispatch();
    const { address, setAddress, setCompleteAddress } = props;

    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!isTestEnv()) {
            loadGoogleMapsScript().then(() => {
                if (window.google && ref.current) {
                    autocompleteService.current = new window.google.maps.places.Autocomplete(ref.current);
                    autocompleteService.current.addListener('place_changed', () => {
                        const place = autocompleteService.current.getPlace();
                        const address = place.formatted_address;
                        if (address) {
                            setAddress({ value: address, showError: false });
                            setCompleteAddress(placeToAddress(place));
                            dispatch({
                                type: DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA,
                                payload: { path: "contactInfo.address", value: address }
                            });
                        }
                    });
                }

                return undefined;
            });
        }
    }, [setAddress, setCompleteAddress]);

    useEffect(() => {
        dispatch({
            type: DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA,
            payload: { path: "contactInfo.address", value: address.value }
        });
    }, [address]);

    return (
        <InputField
            label="msg: common.address.optional {Address (optional)}"
            value={address.value}
            inputRef={ref}
            onChange={(e) => {
                const value = e.target.value;

                setAddress({ value, showError: false });
                setCompleteAddress({
                    ...({ ...DEFAULT_COMPLETE_ADDRESS, ...DEFAULT_ADDRESS_LOCATION }),
                    addressName: value
                });
            }}
            onFocus={() => {
                dispatch({
                    type: DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA,
                    payload: { path: "contactInfo.focusedField", value: 'address' }
                });
            }}
            onBlur={() => {
                dispatch({
                    type: DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA,
                    payload: { path: "contactInfo.focusedField", value: null }
                });
            }}
            data-testid="dynamic-onboarding-address-field"
        />
    );
};
