
import { setComponentsMap } from "../../Workspace/epics/componentsEval/setters";
import { ADD_BOXES_TO_PAGE_SECTION, REMOVE_EMPTY_STRIPS_OVERLAPPING_WITH_HEADER } from "./actions";
import { getCmps } from "../../Workspace/epics/componentAttachements/util";
import { deselectAllComponents } from "../../Workspace/epics/componentsEval/selectedComponentsIds";
import { ReceiveOnlyTemplateWidthActionType } from "../../oneweb/Template/epics/template/selectorActionTypes";
import { updateComponentsRelIns } from "../../Workspace/epics/relations/updateComponentsRelIns";
import { isBoxKind, isSectionKind } from "../../oneweb/componentKinds";
import { getParentChildrenMap } from "../../Workspace/epics/componentsEval/userInteractionMutations/getParentChildrenMap";
import makeUuid from "../../../convertToSections/makeUuid";

const BOX_MAX_HEIGHT = 5000;

export default {
    conditions: [
        ReceiveOnlyTemplateWidthActionType,
        ADD_BOXES_TO_PAGE_SECTION
    ],
    reducer: ({ values: [templateWidth], state: epicState }) => {
        let { state: { componentsMap } } = epicState,
            newComponentsMap = { ...componentsMap };

        const cmps = getCmps(newComponentsMap);
        const pageSections = cmps.filter(x => isSectionKind(x.kind) && !x.inTemplate);
        const parentChildrenMap = getParentChildrenMap(cmps);
        pageSections.forEach(pageSection => {
            const { id: pageSectionId } = pageSection;
            const pageSectionChildren = parentChildrenMap[pageSectionId];
            let _topBoxComponent = pageSectionChildren.filter(x => isBoxKind(newComponentsMap[x].kind)
                && newComponentsMap[x].top === pageSection.top);
            if (!_topBoxComponent || _topBoxComponent.length !== 1) {
                return;
            }
            // @ts-ignore this need to be fixed, it may be a bug
            let topBoxComponent = { ...newComponentsMap[_topBoxComponent] };
            if (topBoxComponent.height >= pageSection.height) {
                return;
            }
            let noBoxHeight = pageSection.height - topBoxComponent.height;
            let newBoxTop = topBoxComponent.top + topBoxComponent.height;
            // create new box components with 10000 height
            while (noBoxHeight > BOX_MAX_HEIGHT) {
                const newBoxComponent = {
                    ...topBoxComponent,
                    id: makeUuid(),
                    height: BOX_MAX_HEIGHT,
                    top: newBoxTop
                };
                newComponentsMap[newBoxComponent.id] = newBoxComponent;
                newBoxTop += BOX_MAX_HEIGHT;
                noBoxHeight -= BOX_MAX_HEIGHT;
            }
            if (noBoxHeight < BOX_MAX_HEIGHT) {
                const newBoxComponent = {
                    ...topBoxComponent,
                    id: makeUuid(),
                    height: noBoxHeight,
                    top: newBoxTop
                };
                newComponentsMap[newBoxComponent.id] = newBoxComponent;
            }
        });

        newComponentsMap = updateComponentsRelIns(newComponentsMap, templateWidth);

        return {
            state: deselectAllComponents(setComponentsMap(newComponentsMap, epicState)),
            updateReason: REMOVE_EMPTY_STRIPS_OVERLAPPING_WITH_HEADER
        };
    }
};
