import React from 'react';
import { connect } from 'react-redux';
import styles from '../FileChooserUpload.css';
import StandardDialog from '../../../dialogs/StandardDialog/index';
import type { FileChooserRenameDialogProps } from "../../flowTypes";
import { Input } from "../../../../../components/inputControls/input/view/index";
import { Msg } from "../../../../intl/index";
import { CHANGE_RENAME_UPLOAD_FILENAME_ACTION } from "./actions";
import { extname, filename } from '../../../../../../utils/path';
import { uploadRenameDialogInputValidation } from './uploadRenameDialogInputValidation';
import { fcUploadRenameAction } from "../../../../../redux/modules/children/fileChooser/actionCreators/index";
import { FC_CONFLICT_RENAME_DIALOG_CLOSE } from '../../../../../redux/modules/children/fileChooser/actionTypes';

export const UploadRenameFileDialogComponent = connect()(
    class _ extends React.Component <FileChooserRenameDialogProps> {
        constructor() {
            // @ts-ignore
            super();
            this.mctaHandler = this.mctaHandler.bind(this);
        }

        mctaHandler() {
            if (this.isDisabled()) return;

            const
                { state, fileName, dispatch } = this.props,
                ext = extname(fileName),
                nextFileName = filename(state.value, ext);

            dispatch(fcUploadRenameAction(nextFileName));
        }

        isDisabled(): boolean {
            return !this.props.state.isTouched || !this.props.state.isValid;
        }

        render() {
            const
                { state, fileName, existingFileNames, dispatch } = this.props,
                ext = extname(fileName, true /* dotted */),
                actionParams = {
                    [uploadRenameDialogInputValidation.name]: {
                        ext,
                        existingFileNames
                    }
                };

            return (
                <StandardDialog
                    title="msg: common.rename {Rename}"
                    mctaText="msg: common.ok {OK}"
                    mctaHandler={this.mctaHandler}
                    disabled={this.isDisabled()}
                    noTopBorderForFooter
                    onClose={() => this.props.dispatch({ type: FC_CONFLICT_RENAME_DIALOG_CLOSE })}
                >
                    <Msg k="fc.renameFileDialog.inputLabel" className={styles.renameDialogLabel}>
                        Enter a new filename:
                    </Msg>
                    <div className={styles.renameDialogFileNameContainer}>
                        <Input
                            state={state}
                            onChangeAction={CHANGE_RENAME_UPLOAD_FILENAME_ACTION}
                            actionParams={actionParams}
                            onEnterPress={this.mctaHandler}
                            containerClassName={styles.renameDialogInputContainer}
                            dispatch={dispatch}
                        />
                        <span className={styles.renameDialogExt}>{ext}</span>
                    </div>
                </StandardDialog>
            );
        }
    }
);
