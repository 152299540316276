import React from "react";
export const SvgSnapchatRoundedFillMulticolor = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <rect width={48} height={48} fill="#f5ea30" rx={6} />
            <path
                fill="#ffffff"
                d="M24.055 33.918c-.056 0-.112-.002-.167-.004a1.445 1.445 0 01-.11.004c-1.293 0-2.123-.585-2.926-1.15-.554-.39-1.077-.759-1.693-.861-.301-.05-.6-.075-.89-.075-.521 0-.932.08-1.232.139a2.71 2.71 0 01-.46.066c-.125 0-.26-.027-.319-.228a7.135 7.135 0 01-.124-.504c-.091-.418-.156-.675-.332-.702-2.052-.316-2.64-.747-2.77-1.052a.39.39 0 01-.032-.13.23.23 0 01.194-.24c3.153-.518 4.567-3.73 4.626-3.866l.005-.011c.193-.39.231-.728.113-1.005-.216-.508-.922-.732-1.39-.88a5.016 5.016 0 01-.308-.103c-.932-.367-1.01-.744-.973-.936.062-.328.502-.556.857-.556a.61.61 0 01.255.05c.42.197.797.296 1.123.296.451 0 .648-.189.672-.214l-.04-.664c-.094-1.485-.21-3.33.261-4.384 1.414-3.159 4.412-3.404 5.297-3.404l.388-.004h.053c.887 0 3.891.246 5.306 3.406.472 1.054.355 2.901.261 4.386l-.004.064c-.013.207-.026.408-.036.6.022.023.203.197.612.212.31-.012.668-.11 1.06-.293a.8.8 0 01.328-.065c.133 0 .267.026.379.072l.007.003c.316.112.524.333.528.564.004.218-.162.545-.98.867a5.283 5.283 0 01-.308.104c-.468.148-1.174.37-1.39.879-.118.277-.08.615.113 1.005l.005.011c.058.136 1.471 3.347 4.626 3.865.117.019.2.123.194.24a.385.385 0 01-.033.131c-.13.304-.716.734-2.769 1.05-.167.025-.232.243-.332.7a7.918 7.918 0 01-.124.5c-.044.149-.137.219-.294.219h-.025a2.62 2.62 0 01-.46-.058 6.15 6.15 0 00-1.232-.13c-.29 0-.59.025-.89.075-.616.102-1.138.47-1.692.86-.804.566-1.634 1.15-2.928 1.15"
            />
        </g>
    </svg>
);
