import * as React from "react";
import type {
    InstancePropertiesPanelProps,
    InstancePropertiesPanelRegistryPage,
} from "../../../../PropertiesPanel/instance/types";
import { MveHeaderPpPageId } from "./MveHeaderPpPageId";
import type { MobileHeaderState } from "../../../../Mobile/header/types";
import CheckBox from '../../../../../view/common/CheckBox/PropertiesCheckBox';
import {
    toggleIncludeMveBackgroundColorAction,
    CHANGE_MVE_BACKGROUND_COLOR_ACTION,
    CHANGE_MVE_BACKGROUND_COLOR_ACTION_AUTO_COLOR,
    changeMveBackgroundOpacityAction,
    toggleUseMveBottomBackgroundShadowAction,
    toggleUseMveBottomBackgroundBorderAction,
} from "../actions";
import ColorProperty from '../../../../presentational/ColorProperty/index';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import Slider from '../../../../../view/common/Slider/PPSlider';
import { getColorOpacity } from "../../../../oneweb/Background/utils/index";
import { THEME_COLOR_OVERRIDE_COLOR_PICKER } from '../../../../ColorPicker/constants';

class BackgroundMveHeaderPpPageClass extends React.Component<InstancePropertiesPanelProps> {
    onIncludeBackgroundClick = () => this.props.dispatch(toggleIncludeMveBackgroundColorAction());

    onOpacityChange = opacity => this.props.dispatch(changeMveBackgroundOpacityAction(opacity));

    onShowBottomShadowClick = () => this.props.dispatch(toggleUseMveBottomBackgroundShadowAction());

    onShowBottomBorderClick = () => this.props.dispatch(toggleUseMveBottomBackgroundBorderAction());

    render() {
        const
            {
                background: {
                    useColor,
                    color,
                    themeColor,
                    showBottomShadow,
                    showBottomBorder,
                },
            } = this.props.state.instance?.state as MobileHeaderState,
            { dispatch } = this.props,
            opacity = color && getColorOpacity(color);

        return (
            <div>
                <CheckBox
                    label="msg: mve.pp.includeBackgroundColor {Include background colour}"
                    isChecked={useColor}
                    onClick={this.onIncludeBackgroundClick}
                />
                {useColor && (
                    <React.Fragment>
                        <VerticalSpacer y={10} />
                        {
                            this.props.state.instance?.state.autoColorMode
                                ? <ColorProperty
                                    colorPickerKind={THEME_COLOR_OVERRIDE_COLOR_PICKER}
                                    themeColorsData={this.props.state.instance.state.themeColorsData}
                                    themeColor={themeColor}
                                    label="msg: common.backgroundColor {Background colour}"
                                    onChangeAction={CHANGE_MVE_BACKGROUND_COLOR_ACTION_AUTO_COLOR}
                                    dispatch={dispatch}
                                />
                                : <ColorProperty
                                    label="msg: common.backgroundColor {Background colour}"
                                    color={color}
                                    onChangeAction={CHANGE_MVE_BACKGROUND_COLOR_ACTION}
                                    onTheFlyChange
                                    dispatch={dispatch}
                                />
                        }
                        <Slider
                            label="msg: mve.pp.backgroundOpacity {Background opacity}"
                            valueLabel={`${opacity}%`}
                            value={opacity}
                            onChange={this.onOpacityChange}
                            topSpacing
                        />
                    </React.Fragment>
                )}
                <CheckBox
                    label="msg: mve.pp.showBottomShadow {Show drop shadow below header}"
                    isChecked={showBottomShadow}
                    onClick={this.onShowBottomShadowClick}
                />
                <CheckBox
                    label="msg: mve.pp.showBottomBorder {Show border below header}"
                    isChecked={showBottomBorder}
                    onClick={this.onShowBottomBorderClick}
                />
            </div>
        );
    }
}

export const BackgroundMveHeaderPpPage: InstancePropertiesPanelRegistryPage = {
    id: MveHeaderPpPageId.BACKGROUND,
    title: 'msg: common.background {Background}',
    View: BackgroundMveHeaderPpPageClass,
};
