import React from "react";
import View from "../index";
import BaseComponent from "../../../../../view/oneweb/BaseComponent";
import type { Props } from "../../flowTypes";

export default (props: Props) => {
    let newProps = props;

    if (props.component && props.component.horizontalAlign && props.component.horizontalAlign === "fit") {
        newProps = { ...props, component: { ...props.component, horizontalAlign: "left" } };
    }

    return <BaseComponent {...props}>{React.createElement(View, { ...newProps, isWorkspace: true, isShadowRender: true })}</BaseComponent>;
};
