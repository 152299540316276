
import * as pagesIds from './pagesIds';
import InstagramGalleryKind from "../kind";
import { ComponentNames } from "../../constants";

export default {
    [pagesIds.MAIN]: ComponentNames[InstagramGalleryKind],
    [pagesIds.LAYOUT]: "msg: component.instagram.propPanel.layout.title {Layout}",
    [pagesIds.ONCLICK]: "msg: component.instagram.propPanel.onClick.title {On click}",
    [pagesIds.ACCOUNT]: "msg: component.instagram.propPanel.account.title {Account}",
};
