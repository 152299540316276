import type { TinyMceEditor } from "../../../flowTypes";
import { getSelectedTextNodes } from "../../utils/nodeUtils/getSelectedNodes";

export const getPaddingNodes = (editor: TinyMceEditor) => {
    let selectedNodes = getSelectedTextNodes(editor);
    const root = editor.getBody();

    return selectedNodes
        .reduce((result: Node[], selectedNode) => {
            let node: any = selectedNode;
            while (node.nodeName !== 'P' && node !== root) {
                // $FlowFixMe: WBTGEN-9962: parentNode can be null, put checks
                node = node.parentNode;
            }

            if (node !== root && !result.includes(node)) {
                return [
                    ...result,
                    node
                ];
            }
            return result;
        }, []);
};
