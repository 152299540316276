import React from 'react';
import cx from "classnames";
import styles from './webShopPaymentMethods.css';
import { getTextStyles } from '../utils';
import { createSelectedParentThemeWithThemeTextClass } from "../../../../ThemeGlobalData/utils/commonUtils";
import { toHex } from "../../../../../mappers/color";

const getPaymentMethodImages = (paymentMethods) => {
    let paymentMethodImages;
    if (paymentMethods && paymentMethods.length) {
        paymentMethods.forEach(paymentMethod => {
            if (paymentMethod.images && paymentMethod.images.length) {
                paymentMethodImages = [...(paymentMethodImages || []), ...paymentMethod.images];
            }
        });
    }
    return paymentMethodImages || [];
};

const View = ({ webshopMHFData: { data: { paymentMethods, translatedLabels } }, generic,
    stylesheets, selectedParentTheme }) => {
    let textStyle = getTextStyles(stylesheets),
        paymentMethodImages = getPaymentMethodImages(paymentMethods),
        themeOverrideColor = generic && generic.themeOverrideColor,
        selectedColor = generic && generic.textStyle && generic.textStyle.color,
        payText = translatedLabels && translatedLabels.payWith;
    selectedColor = selectedColor && toHex(selectedColor);
    textStyle = { ...textStyle, color: selectedColor || textStyle.color };

    return (<div
        className={cx(
            styles.container,
            createSelectedParentThemeWithThemeTextClass({ selectedParentTheme }),
            themeOverrideColor
        )}
    >
        <div className={cx(styles.payText, 'webShopPayWithText')} style={textStyle}>{payText}:</div>
        <div
            className={cx(styles.imagesContainer, 'webShopPaymentMethodImageContainer')}
        >
            {(paymentMethodImages && paymentMethodImages.length) ? paymentMethodImages.map((image, j) => (
                <img key={`${j}`} src={image} className="webShopPaymentMethodImage" />)) : null}
        </div>
    </div>);
};

export default View;
