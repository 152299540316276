import * as React from 'react';
import cx from 'classnames';
import styles from './MobileHeader.css';
import type { ImageAsset } from "../../App/flowTypes";
import Resource from "../../../redux/modules/children/fileChooser/Resource";
import { parseIntDec } from "../../../../utils/number";
import type { MobileStyle } from "../style/types";
import type { MenuItem } from "./types";
import { ScaledWebspaceImage } from "../../presentational/ScaledWebspaceImage/ScaledWebspaceImage";
import { isSVG } from "../../oneweb/Image/utils";
import type { ThemeColorDataType } from '../../ThemeGlobalData/flowTypes';

const LOGO_SIZE = parseIntDec(styles.logoSize);

class Logo extends React.Component<any> {
    onClick = e => {
        e.stopImmediatePropagation();
    };

    render() {
        const { children, goHomeOnClick, homeMenuItem } = this.props;
        if (goHomeOnClick && homeMenuItem) {
            return (
                <a className={styles.logoAnchor} href={homeMenuItem.href} onClick={this.onClick}>
                    {children}
                </a>
            );
        }
        return children;
    }
}

type CommonProps = {
    asset: null | undefined | ImageAsset,
    altText: null | undefined | string,
    mobileStyle: MobileStyle,
    goHomeOnClick?: boolean,
    homeMenuItem?: MenuItem,
    viewerDomain?: string,
};

type PropsWhenAutoColorModeIsOn = CommonProps & {
    autoColorMode: true,
    themeColorsData: ThemeColorDataType,
};

type PropsWhenAutoColorModeIsOff = CommonProps & {
    autoColorMode: false,
};

type Props = PropsWhenAutoColorModeIsOn | PropsWhenAutoColorModeIsOff;

export const MobileHeaderLogo = (props: Props) => {
    const { asset, altText, goHomeOnClick, homeMenuItem, viewerDomain } = props;

    if (asset) {
        const resource: Resource = Resource.fromWebspaceUrl(asset.url),
            logoWidth = (LOGO_SIZE * asset.width) / asset.height,
            path = resource._getFullPath(),
            additionalAttrs: Record<string, any> = {},
            { src, srcSet } = ScaledWebspaceImage.getResolutionState({
                path,
                height: LOGO_SIZE,
                width: logoWidth,
                scale: [1, 2],
                viewerDomain,
            });

        let imageStyle = {};
        if (isSVG(asset.contentType)) {
            imageStyle = {
                height: LOGO_SIZE,
                width: "auto",
            };
        }

        if (altText) {
            additionalAttrs.alt = altText;
        }

        return (
            <div className={styles.logo}>
                <Logo goHomeOnClick={goHomeOnClick} homeMenuItem={homeMenuItem}>
                    <img
                        className={styles.logoImage}
                        style={imageStyle}
                        src={src}
                        srcSet={srcSet}
                        {...additionalAttrs}
                    />
                </Logo>
            </div>
        );
    }

    return (
        <div className={cx(styles.logo, styles.default)}>
            <Logo goHomeOnClick={goHomeOnClick} homeMenuItem={homeMenuItem}>
                <div className={styles.staticLogo} />
            </Logo>
        </div>
    );
};
