import React from 'react';
import { errorDialogStandardHeight, errorDialogStandardWidth } from '../../../dialogs/constants';
import styles from '../FileChooserUpload.css';
import WarningDialog from '../../../dialogs/WarningDialog/index';
import injectIntl from "../../../../intl/injectIntl";
import getCenteredDialogConfig from "../../../../../components/DialogManager/getCenteredDialogConfig";
import Scrollbar from '../../../Scrollbar/index';

const
    width = errorDialogStandardWidth,
    height = errorDialogStandardHeight,
    mctaText = 'msg: common.ok {OK}';

const MupltipleErrorsDialog = injectIntl((props: { title: string, instuctions, groups, intl: Intl }) => {
    const { title, instuctions, groups, intl } = props;
    return (
        <WarningDialog title={title} mctaText={mctaText}>
            <div className={styles.labelSmall}>
                <Scrollbar className={styles.fileList} height={styles.fileListHeight} autoHeight>
                    <ul>
                        {groups.map((g, i) => (
                            <li key={i}>
                                <span className={styles.bold}>{intl.msgJoint(g.message)}</span>
                                <ul>{g.fileNames.map((f, i) => <li key={i}>{f}</li>)}</ul>
                            </li>
                        ))}
                    </ul>
                </Scrollbar>
                <span>{intl.msgJoint(instuctions)}</span>
            </div>
        </WarningDialog>
    );
});

export default getCenteredDialogConfig(width, height, MupltipleErrorsDialog);

