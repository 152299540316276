import React from 'react';
import * as pageIds from '../pageIds';
import pageTitles from '../pageTitles';
import type { BackgroundPageProps } from "../flowTypes";
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import NavigationGroup from '../../../../PropertiesPanel/view/IntlNavigationGroup';

const
    id = pageIds.MAIN,
    title = pageTitles[id],
    view = ({ navigateToPage }: BackgroundPageProps) => (
        <Page>
            <NavigationGroup
                navigateToPage={navigateToPage}
                pagesTitles={pageTitles}
                targetPagesIds={[pageIds.GRADIENT_SETTINGS, pageIds.IMAGE_PATTERN, pageIds.BORDER]}
            />
        </Page>
    );

export default { id, title, view };
