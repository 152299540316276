import { Theme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
    (theme: Theme) => ({
        contentRoot: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            [theme.breakpoints.up(1150)]: {
                flexDirection: "row",
                gap: "50px"
            },
        },
        descriptionContainer: {
            margin: "0px",
            marginTop: "12px",
            fontSize: "14px",
            lineHeight: "22px",
            [theme.breakpoints.up('sm')]: {
                marginTop: "16px",
                fontSize: "16px",
                lineHeight: "24px",
            },
            [theme.breakpoints.up('md')]: {
                marginTop: "10px"
            },
        },
        title: {
            fontSize: "30px",
            lineHeight: "34px",
        },
        description: {
            marginTop: "16px",
            [theme.breakpoints.up('sm')]: {
                marginTop: "10px",
            },
        },
        actionButton: {
            marginTop: "24px",
            width: "100%",
            padding: "12px 24px",
            [theme.breakpoints.up('sm')]: {
                width: "auto",
            },
        },
        upgradeButton: {
            backgroundColor: theme.palette.custom.colorHelloDarknessMyOldFriend,
            borderColor: theme.palette.custom.colorHelloDarknessMyOldFriend,
            "&:hover": {
                backgroundColor: theme.palette.custom.colorHelloDarknessMyOldFriend,
                borderColor: theme.palette.custom.colorHelloDarknessMyOldFriend,
            }
        },
        actionButtonColor: {
            color: "white",
            backgroundColor: theme.palette.custom.colorScienceBlue,
        },
        illustrationContainer: {
            flex: 1,
            margin: "0px",
            marginTop: "20px",
            [theme.breakpoints.up('md')]: {
                marginTop: "0px",
            }
        },
        seoIllustration: {
            minHeight: "252px",
            minWidth: "0px",
            [theme.breakpoints.up('sm')]: {
                minHeight: "450px",
            }
        },
        freeMoths: {
            marginTop: "8px",
            fontSize: "14px",
            lineHeight: "22px",
            color: theme.palette.custom.colorDarkSilver
        }
    }),
    { name: "SeoScanGetStarted" }
);
