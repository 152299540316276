/* global TraceKit */

import './extendError';
import type { ExData } from './flowTypes';
import { getUpdatedEpicsState } from '../epics/updateManager';
import isTestEnv from "./isTestEnv";

function registerException(exData: ExData) {
    const originalException = exData.exception;

    if (originalException.catched) {
        throw originalException;
    }

    delete exData.exception; // eslint-disable-line no-param-reassign

    // exData is passed via error due to it's only way to get stack trace from traceKit
    // once we receive it here, we need to remove it from error obj

    originalException.exData = exData; // eslint-disable-line no-param-reassign

    // we will pass all errors thru TraceKit to get crossbrowser stacktrace
    if (!isTestEnv()) {
        // @ts-ignore
        TraceKit.report(originalException);
    }

    if ("action" in exData && exData.action && exData.action.epicsUpdate) {
        exData.action = { // eslint-disable-line no-param-reassign
            ...exData.action,
            epicsUpdate: {
                ...exData.action.epicsUpdate,
                nextEpicsState: getUpdatedEpicsState(exData.action)
            }
        };
    }

    originalException.catched = true;
}

export {
    registerException
};
