/* eslint-disable max-len */

import { googleAdsStateIconsMap } from "../SiteSettingsDialog/constants";

export const
    BUY_CAMPAIGN_PROMOTION_ADS_STATE = "buyCampaignPromotion",
    PUBLISH_REMEMBER_ADS_STATE = "publishRemember",
    ALL_SET_ADS_STATE = "allSet",
    CODE_DEACTIVATED_ADS_STATE = "codeDeactivated",
    PUBLISH_TO_REMOVE_ADS_STATE = "publishToRemove",
    googleAdsStates = {
        buyCampaignPromotion: {
            stateKey: BUY_CAMPAIGN_PROMOTION_ADS_STATE,
            heading: "msg: trackingTab.google.ads.createAd {Create your first ad in just a few steps}",
            content: "msg: trackingTab.google.ads.adCanAppear {Your ad can appear on Google at the moment someone is looking for products or services like yours.}",
            linkText: "msg: trackingTab.google.ads.buyCampaign {Buy a campaign}",
            linkUrl: 'https://www.one.com/admin/google-adwords.do',
            tooltipText: "msg: trackingTab.google.ads.updateStatus {Update campaign status}",
            informationContainer: true
        },
        publishRemember: {
            stateKey: PUBLISH_REMEMBER_ADS_STATE,
            heading: "msg: trackingTab.google.ads.rememberPublish {Remember to publish}",
            content: "msg: trackingTab.google.ads.stillNeedPublish {You still need to publish your website to activate the tracking code for you campaign.}",
            linkText: "msg: trackingTab.google.ads.goToDashboard {Go to campaign}",
            linkUrl: 'https://www.one.com/admin/google-adwords.do',
            tooltipText: "msg: trackingTab.google.ads.updateStatus {Update campaign status}",
            informationContainer: false,
            iconName: googleAdsStateIconsMap.EXCLAMATION
        },
        allSet: {
            stateKey: ALL_SET_ADS_STATE,
            heading: "msg: trackingTab.google.ads.youAreSet {You’re all set!}",
            content: "msg: trackingTab.google.ads.campaignRunning {Your campaign seems to be running, and the tracking code is published.}",
            linkText: "msg: trackingTab.google.ads.goToDashboard {Go to campaign}",
            linkUrl: 'https://www.one.com/admin/google-adwords.do',
            tooltipText: "msg: trackingTab.google.ads.updateStatus {Update campaign status}",
            informationContainer: true,
            iconName: googleAdsStateIconsMap.CHECKBOX
        },
        codeDeactivated: {
            stateKey: CODE_DEACTIVATED_ADS_STATE,
            heading: "msg: trackingTab.google.ads.codeDeactivated {Your tracking code is deactivated}",
            linkText: "msg: go.to.control.panel.small {Go to control panel}",
            linkUrl: 'https://www.one.com/admin/google-adwords.do',
            tooltipText: "msg: trackingTab.google.ads.updateStatus {Update campaign status}",
            content: "msg: trackingTab.google.ads.rememberCancel {Remember that you need to go to the control panel to cancel the campaign. Activate tracking for Google Ads again, by activating the toggle and publish your website.}",
            informationContainer: false,
            iconName: googleAdsStateIconsMap.DEACTIVATED
        },
        publishToRemove: {
            stateKey: PUBLISH_TO_REMOVE_ADS_STATE,
            heading: "msg: trackingTab.google.ads.publishToRemove {Publish to remove tracking code}",
            content: "msg: trackingTab.google.ads.publishToRemoveInfo {Publish your website to remove the tracking code for Google Ads completely. Remember that you need to go to the control panel to cancel the campaign.}",
            linkText: "msg: go.to.control.panel.small {Go to control panel}",
            linkUrl: 'https://www.one.com/admin/google-adwords.do',
            tooltipText: "msg: trackingTab.google.ads.updateStatus {Update campaign status}",
            informationContainer: false,
            iconName: googleAdsStateIconsMap.EXCLAMATION_PUBLISH
        }
    };
