/* eslint-disable max-len */
import React from "react";

import { Msg } from "../../../../../view/intl";
import * as styles from "./VideoUploadLimitDialog.css";
import { CLOSE_DIALOG } from "../../../../../redux/modules/actionTypes";
import PrimaryButton from "../../../../../view/common/Button/PrimaryButton";
import { Dialog, DialogBody } from "../../../../../view/common/dialogs/baseDialog/index";
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import { VideoUploadLimitDialogId } from "../../dialogIds";

const DialogWidth = parseInt(styles.VideoUploadLimitDialogWidth, 10),
    DialogHeight = parseInt(styles.VideoUploadLimitDialogHeight, 10);

const VideoUploadLimitDialog = ({ dispatch }) => {
    const closeDialog = () => { dispatch({ type: CLOSE_DIALOG }); };

    return (
        <Dialog onClose={closeDialog}>
            <DialogBody className={styles.uploadLimitDialogBody}>
                <div>
                    <div className={styles.uploadLimitDialogTitle}>
                        <Msg k="component.video.uploadLimitExceededHeader">
                            Upload limit exceeded
                        </Msg>
                    </div>
                    <div className={styles.uploadLimitDialogText}>
                        <Msg k="component.video.uploadLimitExceededMessage">
                            Currently, Website Builder supports uploading one video at a time. Please wait until the ongoing upload has been completed before you upload the next video.
                        </Msg>
                    </div>
                </div>
                <div className={styles.acknowledgeButton}>
                    <div>
                        <PrimaryButton onClick={closeDialog}>
                            <Msg k="common.gotIt">
                                Got it
                            </Msg>
                        </PrimaryButton>
                    </div>
                </div>
            </DialogBody>
        </Dialog>
    );
};

const openVideoUploadLimitDialog = {
    type: "OPEN_DIALOG",
    payload: { dialogId: VideoUploadLimitDialogId }
};

export default getCenteredDialogConfig(DialogWidth, DialogHeight, VideoUploadLimitDialog);

export {
    getCenteredDialogConfig,
    openVideoUploadLimitDialog
};
