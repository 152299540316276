import React from "react";
export const SvgYelpCircleFillLight = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <circle cx={24} cy={24} r={24} fill="#ffffff" />
            <path
                fill="#3c3c3c"
                d="M26.769 26.706c-.415.419-.064 1.181-.064 1.181l3.12 5.22s.513.69.957.69c.446 0 .887-.368.887-.368l2.467-3.533s.248-.446.254-.836c.009-.556-.826-.708-.826-.708l-5.842-1.88s-.572-.151-.953.234zm-.296-2.631c.299.508 1.123.36 1.123.36l5.828-1.707s.794-.324.908-.755c.111-.432-.131-.953-.131-.953l-2.785-3.286s-.242-.416-.742-.458c-.553-.047-.892.623-.892.623l-3.293 5.191s-.292.518-.016.985zm-2.754-2.024c.687-.17.796-1.169.796-1.169l-.047-8.313s-.103-1.025-.563-1.303c-.722-.439-.936-.21-1.142-.18l-4.839 1.802s-.474.157-.72.553c-.353.562.358 1.384.358 1.384l5.029 6.868s.496.514 1.128.358zm-1.194 3.364c.017-.641-.769-1.027-.769-1.027l-5.2-2.632s-.771-.318-1.146-.096c-.286.17-.54.476-.565.748l-.339 4.177s-.05.724.137 1.054c.265.467 1.138.142 1.138.142l6.071-1.345c.236-.159.65-.173.673-1.021zm1.51 2.254c-.522-.268-1.146.288-1.146.288l-4.065 4.484s-.507.685-.378 1.107c.121.394.322.59.607.729l4.083 1.29s.495.104.87-.005c.532-.155.434-.99.434-.99l.092-6.07s-.02-.585-.498-.833z"
            />
        </g>
    </svg>
);
