
import { getDAL } from '../../../../../dal';
import {
    JS_VOID_VALUE, GlobalVariableAddressUrlKey,
    GlobalVariableContactEmailKey, GlobalVariablePhoneNumberKey
} from '../../../../constants';
import { GlobalVariablesKeysMap } from '../../../App/epics/globalVariablesEpic';
import { isBrowser } from '../../../../utils/isBrowser';
import { Deps } from './flowTypes';

const regexp = /\B\#(\w+\.?)*\w+\b/g;   //NOSONAR

const fieldExceptionList = [GlobalVariableAddressUrlKey, GlobalVariableContactEmailKey, GlobalVariablePhoneNumberKey];

export const replaceTagsWithContent = (content: string = '', deps: Deps, shouldMatchAgainstExceptionList: boolean = false) => {
    if (!content) {
        return content;
    }

    const tags = content.match(regexp);
    if (!tags) return content;

    return tags.reduce(
        (content, tag) => {
            const fieldName: any = tag.replace('#', '');

            if (shouldMatchAgainstExceptionList && fieldExceptionList.includes(fieldName)) {
                return content;
            }

            if (tag.indexOf('#i18n.') === 0) {
                return content.replace(tag,
                    (deps.globalVariables && deps.globalVariables[fieldName]) || tag);
            }

            if (
                !content ||
                (isBrowser() && getDAL().isRepositoryDomain()) // repository team should not have values replaced, as they always work with references. TODO for Fast templates part 2 users also should start work with references
            ) {
                return content;
            }

            if (!GlobalVariablesKeysMap[fieldName]) {
                return content;
            }

            return content.replace(tag,
                (deps.globalVariables && deps.globalVariables[fieldName]) || tag);
        },
        content
    );
};

const hrefJsVoid = `href="${JS_VOID_VALUE}"`;

export const fixInvalidHrefs = (content: string) => {
    if (!content) return content;

    return content
        .replace(/<a ([^>])*/g, (hrefInsideAnchorTags) => { // To make sure everything come inside first part of anchor tags
            return hrefInsideAnchorTags
                .replace(/href="tel:"/g, hrefJsVoid) // render update
                .replace(/href="phone:"/g, hrefJsVoid) // data structure update
                .replace(/href="mailto:"/g, hrefJsVoid) // render update
                .replace(/href="email:"/g, hrefJsVoid) // data structure update
                .replace(/href=""/g, hrefJsVoid) // render update
                // data structure update. Space is added before href to avoid text content. Eg. "I am href"
                .replace(/ href([^=])/g, (hrefWithSymbolAfterHref, symbolAfterHref) => hrefJsVoid + symbolAfterHref);
        });
};
