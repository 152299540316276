import React from 'react';
import Slider from '../../../view/common/Slider/PPSlider';
import type { OpacitySliderFlowTypes } from "./flowTypes";

export default (props: OpacitySliderFlowTypes) => {
    const
        {
            opacity = 1,
            onChange,
            onAfterChange,
            disabled,
            label = 'msg: common.opacity {Opacity}',
            min = 0,
            max = 100
        } = props,
        valueLabel = typeof opacity === 'number' ? `${opacity}%` : '';

    return (
        <Slider
            value={opacity}
            label={label}
            valueLabel={valueLabel}
            min={min}
            max={max}
            onChange={onChange}
            onAfterChange={onAfterChange}
            disabled={disabled}
        />
    );
};
