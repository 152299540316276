import * as R from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import type { Epic } from '../../../../epics/flowTypes';
import * as Actions from "./actionTypes";
import {
    SITE_SETTINGS_DOCUMENTS_UPDATE_FAILURE,
    SITE_SETTINGS_DOCUMENTS_UPDATE_REQUEST,
    SITE_SETTINGS_DOCUMENTS_UPDATE_SUCCESS,
    SITE_DOCUMENTS_UPDATE_SUCCESS,
    SITE_DOCUMENTS_UPDATE_REQUEST,
    SITE_DATA_LOAD_SUCCESS_PAGES_EXIST,
    SITE_DATA_LOAD_SUCCESS_NO_PAGES,
    SITE_DOCUMENTS_UPDATE_FAILURE,
} from "../../actionTypes";
import { siteSettingsValueActionType } from './valueActionType';
import { loadSiteSettings, createDefaultSiteSettings } from "./actionCreators";
import { SITE_SETTINGS } from "../../../../../dal/index";
import type { SiteSettings } from "./flowTypes";
import { optional, withSelector, receiveOnly } from "../../../../epics/makeCondition";
import { PAGE_DATA_LOAD_SUCCESS } from "../../../PagesTree/actionTypes";
import { updateSiteDocumentWithCustomActions } from "../../actionCreators/updateSiteDocument";
import { PAGE_OR_TEMPLATE_IMPORT_BEFORE_SAVE } from '../../../TemplateSelector_DEPRECATED/actionTypes';
import { UNDO_INITIAL_STATE } from "../../../../epics/undoManager/updateReasons";
import { SiteSettingsSyncSelector } from './selectorActionTypes';
import { DONT_SAVE_UNSAVED_CHANGES } from '../../../UnsavedChanges/actionTypes';
import { siteDataValueActionType } from "../siteData/valueActionType";

export const
    siteSettingsDefaultState: SiteSettings = ({
        type: SITE_SETTINGS,
        id: "settings",
    } as any); // object will be filled up with all required fields dynamically on script load

const
    updateSiteSettings = updateSiteDocumentWithCustomActions([
        SITE_SETTINGS_DOCUMENTS_UPDATE_REQUEST,
        SITE_SETTINGS_DOCUMENTS_UPDATE_SUCCESS,
        SITE_SETTINGS_DOCUMENTS_UPDATE_FAILURE
    ]),
    mandatoryKeysForSiteSettingsData: Array<string> = [],
    addMissingSiteSettingsKeys = (siteSettings: SiteSettings) => {
        const siteSettingsKeys = Object.keys(siteSettings);
        const missingKeys = R.difference(mandatoryKeysForSiteSettingsData, siteSettingsKeys);

        if (missingKeys.length) {
            const newSiteSettings = missingKeys.reduce(
                (siteSettings, key) => ({
                    ...siteSettings,
                    [key]: siteSettingsDefaultState[key]
                }),
                siteSettings,
            );

            return newSiteSettings;
        }

        return siteSettings;
    };

const epicConfig = {
    dispatchOutside: true,
    defaultState: {
        original: siteSettingsDefaultState,
        current: siteSettingsDefaultState,
        loaded: false,
    },
    defaultScope: {
        saveInProgress: false,
        shouldSaveAgainAfterSaveComplete: false,
        dbLayerData: {
            etag: "unknown",
            time: 0,
            rev: -1
        }
    },
    valueActionType: siteSettingsValueActionType,
    updaters: [
        {
            conditions: [
                optional(SITE_DATA_LOAD_SUCCESS_PAGES_EXIST),
                optional(SITE_DATA_LOAD_SUCCESS_NO_PAGES),
            ],
            reducer: ({ state, scope }) => (state.loaded ? { state, scope } : ({
                state,
                scope,
                actionToDispatch: loadSiteSettings(),
            }))
        },
        {
            /* WARNING: do not add any conditions here, if you need additional data for migration read next warning inside */
            conditions: [Actions.LOAD_SITE_SETTINGS_SUCCESS],
            reducer: ({ values: [siteSettings], state, scope }) => {
                // Site Settings object is already created and some mandatory fields are missing
                let migratedSiteSettings = addMissingSiteSettingsKeys(siteSettings);

                if (migratedSiteSettings.siteSettingsDialogData) {
                    migratedSiteSettings.seoData = (migratedSiteSettings.siteSettingsDialogData as any).seo;
                    migratedSiteSettings.cookieBannerData = (migratedSiteSettings.siteSettingsDialogData as any).cookieBanner;
                    delete migratedSiteSettings.siteSettingsDialogData;
                }

                if (migratedSiteSettings.onboarding) {
                    migratedSiteSettings.generalData = (migratedSiteSettings as any).onboarding;
                    delete migratedSiteSettings.onboarding;
                }

                /*
                    WARNING: DO NOT put your migration code here
                    consider server side migration first
                    if that not works try to use SITE_SETTINGS_MIGRATE_HOOK in individual siteSettings child epic
                    if that not work because your migration depends on another network request that may happen after site settings loaded,
                        check example with wbtgen/src/components/SiteSettings/General/generalGlobalDataEpic/generalGlobalDataEpic.js
                        on OWNER_INFO_SUCCESS
                        and implement similarly by dispatching 'SAVE_SITE_SETTINGS' along with returning migratied state for your epic
                */

                const dbLayerData = {
                    etag: siteSettings.etag,
                    time: siteSettings.time,
                    rev: siteSettings.rev,
                };

                return {
                    state: {
                        ...state,
                        original: siteSettings,
                        current: migratedSiteSettings,
                        loaded: false
                    },
                    scope: {
                        ...scope,
                        dbLayerData
                    },
                    multipleActionsToDispatch: [
                        { type: Actions.SITE_SETTINGS_MIGRATE_HOOK },
                        { type: Actions.AFTER_MIGRATE_SITE_SETTINGS },
                        { type: Actions.SITE_SETTINGS_DB_LAYER_DATA_UPDATED, payload: dbLayerData }
                    ],
                    updateReason: UNDO_INITIAL_STATE
                };
            }
        },
        {
            conditions: [Actions.AFTER_MIGRATE_SITE_SETTINGS],
            reducer: ({ state, scope }) => {
                if (state.original !== state.current) {
                    return {
                        state: {
                            ...state,
                            original: state.current,
                            loaded: true
                        },
                        scope,
                        multipleActionsToDispatch: [
                            { type: Actions.SAVE_SITE_SETTINGS },
                        ],
                        updateReason: UNDO_INITIAL_STATE
                    };
                }

                return {
                    state: {
                        ...state,
                        loaded: true
                    },
                    scope,
                    updateReason: UNDO_INITIAL_STATE
                };
            }
        },
        {
            conditions: [Actions.SAVE_SITE_SETTINGS],
            reducer: ({ state, scope }) => {
                if (scope.saveInProgress === true) {
                    return {
                        state,
                        scope: {
                            ...scope,
                            shouldSaveAgainAfterSaveComplete: true,
                        }
                    };
                }
                return {
                    state,
                    scope: { ...scope, saveInProgress: true },
                    actionToDispatch: updateSiteSettings({ 'siteSettings': { ...state.current, ...scope.dbLayerData } })
                };
            }
        },
        {
            conditions: [
                receiveOnly(siteDataValueActionType),
                Actions.LOAD_SITE_SETTINGS_FAILURE
            ],
            reducer: ({ state, scope, values: [{ themingOnByDefault }, response] }) => {
                // Send request to create siteSettings if the response is 404
                let newDefaultState, newEpicState;
                if (themingOnByDefault) {
                    newDefaultState = R.assocPath(['themeSettingsData', 'autoColorMode'], true)(siteSettingsDefaultState);
                    newEpicState = R.evolve({
                        original: R.always(newDefaultState),
                        current: R.always(newDefaultState)
                    })(state);
                } else {
                    newDefaultState = siteSettingsDefaultState;
                    newEpicState = state;
                }
                if (response.error && response.error === "NotFound") {
                    return {
                        state: newEpicState,
                        scope,
                        actionToDispatch: createDefaultSiteSettings(newDefaultState)
                    };
                }

                return { state, scope };
            }
        },
        {
            conditions: [Actions.CREATE_DEFAULT_SITE_SETTINGS_SUCCESS],
            reducer: ({ state, scope, values: [siteSettingsServerParams] }) => {
                const { etag, time, rev } = siteSettingsServerParams;
                const dbLayerData = { etag, time, rev };

                return {
                    state: {
                        ...state,
                        loaded: true
                    },
                    scope: {
                        ...scope,
                        dbLayerData
                    },
                    updateReason: UNDO_INITIAL_STATE,
                    actionToDispatch: { type: Actions.SITE_SETTINGS_DB_LAYER_DATA_UPDATED, payload: dbLayerData }
                };
            }
        },
        {
            conditions: [PAGE_OR_TEMPLATE_IMPORT_BEFORE_SAVE],
            reducer: ({ state, scope, values: [payload] }) => {
                const multipleActionsToDispatch = [
                    // $FlowFixMe
                    { type: Actions.FILL_SPECIFIC_SITE_SETTINGS_DURING_PAGE_OR_TEMPLATE_IMPORT, payload },
                    { type: Actions.SAVE_SITE_SETTINGS }
                ];
                return {
                    state,
                    scope,
                    multipleActionsToDispatch
                };
            }
        },
        {
            /*
            Why we need this updater?
            Steps:
            Refresh page.
            change site settings, don't save. This is unwanted data.
            Import new page / template .
            See site settings now. It will have unwanted data. */
            conditions: [DONT_SAVE_UNSAVED_CHANGES],
            reducer: ({ state, scope }) => ({
                state: { ...state, current: state.original },
                scope,
                updateReason: DONT_SAVE_UNSAVED_CHANGES
            })
        },
        {
            conditions: [withSelector(siteSettingsValueActionType, ({ loaded }) => loaded)],
            reducer: ({ state, scope, values: [loaded] }) => {
                if (loaded) {
                    return {
                        state,
                        scope,
                        actionToDispatch: ({ type: Actions.SITE_SETTINGS_LOADED, payload: state.current } as any)
                    };
                }

                return { state, scope };
            }
        },
        {
            conditions: [SITE_DOCUMENTS_UPDATE_REQUEST],
            reducer: ({ state, scope }) => {
                return {
                    state,
                    scope: { ...scope, saveInProgress: true }
                };
            }
        },
        ...[SITE_DOCUMENTS_UPDATE_SUCCESS, SITE_SETTINGS_DOCUMENTS_UPDATE_SUCCESS].map(actionType => ({
            conditions: [actionType],
            reducer: ({ state, scope, values: [updatePatch] }) => {
                if (updatePatch && updatePatch.siteSettings) {
                    const { etag, time, rev } = updatePatch.siteSettings;
                    const dbLayerData = { etag, time, rev };
                    return {
                        state: { ...state, original: state.current },
                        scope: {
                            ...scope,
                            saveInProgress: false,
                            dbLayerData,
                            shouldSaveAgainAfterSaveComplete: false
                        },
                        multipleActionsToDispatch: [
                            scope.shouldSaveAgainAfterSaveComplete ? { type: Actions.SAVE_SITE_SETTINGS } : null,
                            { type: Actions.SITE_SETTINGS_DB_LAYER_DATA_UPDATED, payload: dbLayerData }
                        ]
                    };
                }

                return { state, scope };
            }
        })),
        ...[SITE_DOCUMENTS_UPDATE_FAILURE, SITE_SETTINGS_DOCUMENTS_UPDATE_FAILURE].map(actionType => ({
            //TODO handle failure cases properly
            conditions: [actionType],
            reducer: ({ state, scope }) => {
                return {
                    state,
                    scope: {
                        ...scope,
                        saveInProgress: false,
                        shouldSaveAgainAfterSaveComplete: false
                    },
                    actionToDispatch: scope.shouldSaveAgainAfterSaveComplete ? { type: Actions.SAVE_SITE_SETTINGS } : null
                };
            }
        })),
        {
            conditions: [
                optional(PAGE_DATA_LOAD_SUCCESS)
            ],
            reducer: ({ state, scope }) => {
                /**
                 * current should not be reset if saving is in progress.
                 * Because, current is the one which is being saved.
                 * SITE_SETTINGS_DOCUMENTS_UPDATE_SUCCESS will updated original in that case.
                 **/
                if (scope.saveInProgress) {
                    return { state, scope };
                }
                // reseting current to last saved version. This is mostly for page change.
                return {
                    state: {
                        ...state,
                        current: state.original
                    },
                    scope,
                    updateReason: UNDO_INITIAL_STATE
                };
            }
        },
    ]
};

const makeSiteSettingsEpic = (epics: Array<Epic<any, any, any>>) => {
    epics.forEach(epic => {
        if (epic.saveIntoSiteSettings) {
            const { key } = epic.saveIntoSiteSettings;

            mandatoryKeysForSiteSettingsData.push(key);
            siteSettingsDefaultState[key] = epic.defaultState;
            epicConfig.updaters.push({
                conditions: [epic.valueActionType],
                reducer: ({ state, scope, values: [data] }) => {
                    if (state.current && data === state.current[key]) {
                        return { state, scope };
                    }
                    return {
                        state: R.assocPath(['current', key], data, state),
                        scope,
                        updateReason: 'BACK_SYNC'
                    };
                }
            });
            epic.updaters.push({
                keepFullActions: true,
                conditions: [SiteSettingsSyncSelector],
                reducer: ({ state, scope, values: [{ payload: { current } }] }) => {
                    const data = current[key];
                    if (data === state) {
                        return { state, scope };
                    }

                    return {
                        state: data || epic.defaultState,
                        scope,
                        updateReason: UNDO_INITIAL_STATE
                    };
                }
            });
        }
    });

    return makeEpic(epicConfig);
};

export {
    makeSiteSettingsEpic,
};

