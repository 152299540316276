import { ComponentNames } from "../../constants";
import InstagramGallery from "../kind";

export const
    DialogWidth = 470,
    DialogHeight = 295,
    PopupWindowWidth = 973,
    PopupWindowHeight = 798,
    ConfigDialogSettings = {
        TITLE: ComponentNames[InstagramGallery],
        MESSAGE: 'msg: component.instagramFeed.configDialog.message {Add your Instagram feed to your site. Simply login to Instagram to connect your account.}', // eslint-disable-line max-len
        MESSAGE2: 'msg: component.instagramFeed.configDialog.message2 {If your account is private, then Instagram requires you to reconnect every 60 days. We’ll let you know when you need to do this.}', // eslint-disable-line max-len
        CONNECT_LABEL: 'msg: component.instagramFeed.configDialog.addSaveLabel {Connect Instagram}',
        DISCONNECT_LABEL: 'msg: component.instagramFeed.configDialog.updateSaveLabel {Disconnect Instagram}'
    };
