/* eslint-disable max-len */

import * as R from "ramda";
import { MIN_CELL_WIDTH } from "../constants";
import { getNumRowsAndCols } from "../utils";
import type { TableComponent, TableComponentDependsOn } from "../flowTypes";

const getUniqueColumnIdsFromSelectedCells = (selectedCellsIndexes, numCols) => R.uniq(
    selectedCellsIndexes.map(index => index % numCols)
);

export const setCellWidthReducer =
    (component: TableComponent, { payload: width }: any, dependencies: TableComponentDependsOn): TableComponent => {
        const newWidth = parseInt(width, 10);

        if (newWidth >= MIN_CELL_WIDTH) {
            const
                { tableEditModeState: { selectedCellsIndexes } } = dependencies,
                { cells, width: componentWidth } = component,
                { numCols } = getNumRowsAndCols(cells),
                uniqueColumnIds = selectedCellsIndexes.length > 0
                    ? getUniqueColumnIdsFromSelectedCells(selectedCellsIndexes, numCols)
                    : R.range(0, numCols),
                originalCellsWidth = uniqueColumnIds.reduce((acc, index) => {
                    return acc + (cells[index].cellInfo.flexWidth * componentWidth);
                }, 0),
                updatedCellsWidth = uniqueColumnIds.length * newWidth,
                updatedComponentWidth = componentWidth + (updatedCellsWidth - originalCellsWidth) + uniqueColumnIds.length, // eslint-disable-line max-len
                flexWidthForSelectedCells = newWidth / updatedComponentWidth;

            return R.evolve({
                width: () => updatedComponentWidth,
                cells: R.map(cell => {
                    const { cellInfo: { colIndex } } = cell;

                    if (uniqueColumnIds.indexOf(colIndex) > -1) {
                        return R.evolve({
                            cellInfo: {
                                flexWidth: () => flexWidthForSelectedCells
                            }
                        }, cell);
                    }

                    return R.evolve({
                        cellInfo: {
                            flexWidth: fw => (fw * componentWidth) / updatedComponentWidth
                        }
                    }, cell);
                })
            }, component);
        }

        return component;
    };
