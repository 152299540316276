import { isSectionKind } from "../../../oneweb/componentKinds";
import { boxTop } from "../../constants";
import type { Relations } from "../../flowTypes";
import type { ComponentsMap } from "../../../../redux/modules/children/workspace/flowTypes";

export const removeHeaderFooterRelations = (
    relations: Relations,
    headerId: string = '',
    footerId: string = '',
    componentsMap: ComponentsMap
): Relations => {
    const
        isIdHeaderOrFooter = id => id === headerId || id === footerId,
        isComponentMovedInMVE = (relations, id) => relations.map(({ id }) => id).indexOf(id) !== -1;

    return relations
        .map(relation => {
            const
                { top: topId, bottom: bottomId } = relation,
                newRelation = { ...relation };

            if (
                topId === footerId ||
                (topId === headerId && isComponentMovedInMVE(relations, headerId))
            ) {
                delete newRelation.top;
            }

            if (
                bottomId === headerId ||
                (bottomId === footerId && isComponentMovedInMVE(relations, footerId))
            ) {
                delete newRelation.bottom;
            }

            return newRelation;
        })
        .filter(({ id, top: topId, bottom: bottomId }) => {
            const
                relationHaveTopOrBottomId = topId || bottomId,
                headerOrFooterRelation = isIdHeaderOrFooter(id),
                cmp = componentsMap[id],
                componentMovedToBottomMostRelation = cmp && isSectionKind(cmp.kind) && topId && !bottomId,
                componentMovedToTopMostRelation = cmp && isSectionKind(cmp.kind) && topId === boxTop;

            return relationHaveTopOrBottomId && !(
                headerOrFooterRelation
                || componentMovedToBottomMostRelation // component moved and became the last component in MVE
                || componentMovedToTopMostRelation // component moved and became the first component in MVE
            );
        });
};
