import * as componentReducers from "../componentReducers";
import { factoryGalleryStylesReducer } from "./factoryGalleryStylesReducer";

const
    makeGalleryStylesReducer = factoryGalleryStylesReducer('captionBoxStyle');
export const
    typeReducer = makeGalleryStylesReducer(componentReducers.makeComponentStyleBorderStyleReducer()),
    widthReducer = makeGalleryStylesReducer(componentReducers.makeComponentStyleBorderWidthReducer()),
    colorReducer = makeGalleryStylesReducer(componentReducers.makeComponentStyleBorderColorReducer()),
    opacityReducer = makeGalleryStylesReducer(componentReducers.makeComponentStyleBorderOpacityReducer()),
    cornerReducer = makeGalleryStylesReducer(componentReducers.makeComponentStyleBorderCornersReducer()),
    paddingTopReducer = makeGalleryStylesReducer(componentReducers.makeComponentStylePaddingTopReducer()),
    paddingBottomReducer = makeGalleryStylesReducer(componentReducers.makeComponentStylePaddingBottomReducer()),
    paddingLeftReducer = makeGalleryStylesReducer(componentReducers.makeComponentStylePaddingLeftReducer()),
    paddingRightReducer = makeGalleryStylesReducer(componentReducers.makeComponentStylePaddingRightReducer()),
    bgColorReducer = makeGalleryStylesReducer(componentReducers.makeComponentStyleBackgroundColorReducer()),
    bgColorUnsetReducer = makeGalleryStylesReducer(componentReducers.makeComponentStyleBackgroundColorUnsetReducer()),
    bgColorOpacityReducer = makeGalleryStylesReducer(componentReducers.makeComponentStyleBackgroundColorOpacityReducer()); // eslint-disable-line
