import { WINDOW_CHAT_ADD_TAG, WINDOW_CHAT_REMOVE_TAG } from "../../../../redux/middleware/window/actionTypes";
import { ADD_CHAT_TAG_WSB_TABLE_EJECTS_COMPONENT, ADD_CHAT_TAG_WSB_TRIAL } from "./actionTypes";

export const
    windowChatAddTags = (payload: Array<string>) => ({
        type: WINDOW_CHAT_ADD_TAG,
        payload
    }),
    windowChatRemoveTags = (payload: Array<string>) => ({
        type: WINDOW_CHAT_REMOVE_TAG,
        payload
    }),
    addChatTagWSBTrialAC = (payload: string) => ({
        type: ADD_CHAT_TAG_WSB_TRIAL,
        payload
    }),
    addChatTagWSBTableEjectsComponent = (payload: string) => ({
        type: ADD_CHAT_TAG_WSB_TABLE_EJECTS_COMPONENT,
        payload
    });
