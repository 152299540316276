const CHARACTER_LIMIT_PER_TEXT_COMPONENT = 50000;
const WARNING_CHARACTER_START_LIMIT = 200;

export const
    EXCEEDED = 'exceeded',
    WARNING = 'warning',
    VALID = 'valid';

export const validateCharacterLimit = (text) => {
    if (text.length >= CHARACTER_LIMIT_PER_TEXT_COMPONENT) {
        return EXCEEDED;
    } else if (CHARACTER_LIMIT_PER_TEXT_COMPONENT - text.length <= WARNING_CHARACTER_START_LIMIT) {
        return WARNING;
    }

    return VALID;
};

export const getRemainingCharacterCount = (text) => {
    return CHARACTER_LIMIT_PER_TEXT_COMPONENT - text.length;
};
