/* eslint-disable max-len */

import { BackgroundSize } from "../../presentational/BackgroundImageSettings/options";

export const
    STRIP_DEFAULT_BG_COLOR = ['HSL', 0, 0, 0.933, 1],
    TooltipTextForStripColorPicker = 'msg: component.strip.themeColorTooltip {Strip styles direct the colours used for the background and components of a strip. The styles are based on the colour theme of your website.}',
    StripDefaultAssetData = {
        asset: null,
        repeat: [false, false] as [boolean, boolean],
        position: ['50%', '50%'] as [string, string],
        size: BackgroundSize.FILL,
        scrollEffect: null,
        opacity: 1,
    };
