import { sendGTMEvent } from "../../../../App/actionCreators/googleTagManager";
import { VIRTUAL_PAGE_EVENT, WSB_GTM_EVENT } from "../../../events";
import type { EventParamType } from "../../../flowTypes";
import {
    ONBOARDING_ADDRESS_EMAIL_AND_PHONE_STEP_OPENED,
    ONBOARDING_GMB_CATEGORIES_STEP_OPENED,
    ONBOARDING_INITIALIZE_TEMPLATE_PREVIEW,
    ONBOARDING_LOGO_STEP_OPENED,
    ONBOARDING_TEMPLATE_SELECTOR_STEP_OPENED,
    ONBOARDING_TITLE_STEP_OPENED,
    ONBOARDING_WELCOME_STEP_OPENED,
    TEMPLATE_SELECTOR_CHANGE_THEME_PREVIEW_PAGE
} from "../../../../Onboarding/actionTypes";
import { receiveOnly } from "../../../../../epics/makeCondition";
import { subscriptionDataVat } from "../../../../App/epics/subscriptionData/valueActionType";
import { COLOR_THEME_SITE_SETTINGS_EPIC_VALUE } from "../../../../SiteSettings/ColorThemeData/colorThemeSiteSettingsVAT";

const trackVirtualPageEvent = (params: EventParamType) => sendGTMEvent({
    event: WSB_GTM_EVENT,
    eventCategory: VIRTUAL_PAGE_EVENT,
    ...params
});

export const PageViewsGTMUpdaters = [
    {
        conditions: [
            receiveOnly(subscriptionDataVat),
            ONBOARDING_WELCOME_STEP_OPENED
        ],
                reducer: ({ state, values: [subscriptionData] }) => {
            let subscriptionType = subscriptionData.subscriptionType;
            return {
                state,
                actionToDispatch: trackVirtualPageEvent(
                    {
                        virtualPageUrl: "/onboarding-welcome",
                        virtualPageTitle: "Welcome Page",
                        userSubscription: subscriptionType.toLowerCase()
                    }
                )
            };
        }
    },

    {
        conditions: [
            receiveOnly(subscriptionDataVat),
            ONBOARDING_TITLE_STEP_OPENED
        ],
                reducer: ({ state, values: [subscriptionData] }) => {
            let subscriptionType = subscriptionData.subscriptionType;
            return {
                state,
                actionToDispatch: trackVirtualPageEvent(
                    {
                        virtualPageUrl: "/onboarding-title",
                        virtualPageTitle: "Title Page",
                        userSubscription: subscriptionType.toLowerCase()
                    }
                )
            };
        }
    },

    {
        conditions: [
            receiveOnly(subscriptionDataVat),
            ONBOARDING_LOGO_STEP_OPENED
        ],
                reducer: ({ state, values: [subscriptionData] }) => {
            let subscriptionType = subscriptionData.subscriptionType;
            return {
                state,
                actionToDispatch: trackVirtualPageEvent(
                    {
                        virtualPageUrl: "/onboarding-logo",
                        virtualPageTitle: "Logo Page",
                        userSubscription: subscriptionType.toLowerCase()
                    }
                )
            };
        }
    },

    {
        conditions: [
            receiveOnly(subscriptionDataVat),
            ONBOARDING_ADDRESS_EMAIL_AND_PHONE_STEP_OPENED
        ],
                reducer: ({ state, values: [subscriptionData] }) => {
            let subscriptionType = subscriptionData.subscriptionType;
            return {
                state,
                actionToDispatch: trackVirtualPageEvent(
                    {
                        virtualPageUrl: "/onboarding-address-phone-and-email",
                        virtualPageTitle: "Contact information Page",
                        userSubscription: subscriptionType.toLowerCase()
                    }
                )
            };
        }
    },

    {
        conditions: [
            receiveOnly(subscriptionDataVat),
            ONBOARDING_GMB_CATEGORIES_STEP_OPENED
        ],
                reducer: ({ state, values: [subscriptionData] }) => {
            let subscriptionType = subscriptionData.subscriptionType;
            return {
                state,
                actionToDispatch: trackVirtualPageEvent(
                    {
                        virtualPageUrl: "/onboarding-gmb-categories",
                        virtualPageTitle: "Template category Page",
                        userSubscription: subscriptionType.toLowerCase()
                    }
                )
            };
        }
    },

    {
        conditions: [
            receiveOnly(subscriptionDataVat),
            ONBOARDING_TEMPLATE_SELECTOR_STEP_OPENED
        ],
                reducer: ({ state, values: [subscriptionData] }) => {
            let subscriptionType = subscriptionData.subscriptionType;
            return {
                state,
                actionToDispatch: trackVirtualPageEvent(
                    {
                        virtualPageUrl: "/onboarding-template-selector",
                        virtualPageTitle: "Template selector Page",
                        userSubscription: subscriptionType.toLowerCase()
                    }
                )
            };
        }
    },

    {
        conditions: [
            receiveOnly(subscriptionDataVat),
            receiveOnly(COLOR_THEME_SITE_SETTINGS_EPIC_VALUE),
            TEMPLATE_SELECTOR_CHANGE_THEME_PREVIEW_PAGE,
            ONBOARDING_INITIALIZE_TEMPLATE_PREVIEW
        ],
                reducer: ({ state, values: [subscriptionData, { autoColorMode }] }) => {
            let subscriptionType = subscriptionData.subscriptionType,
                actionToDispatch: Action | null = null;

            if (autoColorMode) {
                actionToDispatch = trackVirtualPageEvent(
                    {
                        virtualPageUrl: "/onboarding-color-theme",
                        virtualPageTitle: "Color choice Page",
                        userSubscription: subscriptionType.toLowerCase()
                    }
                );
            }

            return {
                state,
                actionToDispatch
            };
        }
    }
];
