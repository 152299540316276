/* eslint-disable max-len */

export const validGmbKeyList = [
    "aadhar_center",
    "abarth_dealer",
    "abbey",
    "aboriginal_and_torres_strait_islander_organisation",
    "aboriginal_art_gallery",
    "abortion_clinic",
    "abrasives_supplier",
    "abundant_life_church",
    "acai_shop",
    "acaraje_restaurant",
    "accountant",
    "accounting_firm",
    "accounting_school",
    "accounting_software_company",
    "acoustical_consultant",
    "acrobatic_diving_pool",
    "acrylic_store",
    "acupuncture_clinic",
    "acupuncture_school",
    "acupuncturist",
    "acura_dealer",
    "addiction_treatment_center",
    "administrative_attorney",
    "adoption_agency",
    "adult_day_care_center",
    "adult_dvd_store",
    "adult_education_school",
    "adult_entertainment_club",
    "adult_entertainment_store",
    "adult_foster_care_service",
    "adventure_sports",
    "adventure_sports_center",
    "advertising_agency",
    "advertising_photographer",
    "aerated_drinks_supplier",
    "aerial_installation_service",
    "aerial_photographer",
    "aerial_sports_center",
    "aero_dance_class",
    "aerobics_instructor",
    "aeroclub",
    "aeromodel_shop",
    "aeronautical_engineer",
    "aerospace_company",
    "afghani_restaurant",
    "african_goods_store",
    "african_restaurant",
    "after_school_program",
    "afterscool_care_center",
    "agenzia_entrate",
    "aggregate_supplier",
    "agistment_service",
    "agricultural_association",
    "agricultural_cooperative",
    "agricultural_engineer",
    "agricultural_high_school",
    "agricultural_machinery_manufacturer",
    "agricultural_organization",
    "agricultural_product_wholesaler",
    "agricultural_production",
    "agricultural_service",
    "agricultural_service_supply_agency",
    "agriculture_cooperative",
    "agrochemicals_supplier",
    "aikido_club",
    "aikido_school",
    "air_compressor_repair_service",
    "air_compressor_supplier",
    "air_conditioning_contractor",
    "air_conditioning_repair_service",
    "air_conditioning_store",
    "air_conditioning_system_supplier",
    "air_duct_cleaning_service",
    "air_filter_supplier",
    "air_force_base",
    "air_taxi",
    "airbrushing_service",
    "airbrushing_supply_store",
    "aircraft_dealer",
    "aircraft_maintenance_company",
    "aircraft_manufacturer",
    "aircraft_rental_service",
    "aircraft_supply_store",
    "airline",
    "airline_ticket_agency",
    "airplane_exhibit",
    "airport",
    "airport_shuttle_service",
    "airsoft_gun_shop",
    "airstrip",
    "alcohol_manufacturer",
    "alcohol_retail_monopoly",
    "alcoholic_beverage_wholesaler",
    "alcoholism_treatment_program",
    "alfa_romeo_dealer",
    "allergist",
    "alliance_church",
    "alsace_restaurant",
    "alternative_fuel_station",
    "alternative_medicine_practitioner",
    "alternator_supplier",
    "aluminium_supplier",
    "aluminum_frames_supplier",
    "aluminum_welder",
    "aluminum_window",
    "amateur_theatre",
    "ambulance_service",
    "american_grocery_store",
    "american_restaurant",
    "amish_furniture_store",
    "ammunition_supplier",
    "amphitheatre",
    "amusement_center",
    "amusement_machine_supplier",
    "amusement_park",
    "amusement_park_ride",
    "amusement_ride_supplier",
    "an_hui_restaurant",
    "anago_restaurant",
    "andalusian_restaurant",
    "andhra_restaurant",
    "anesthesiologist",
    "anganwadi_center",
    "angler_fish_restaurant",
    "anglican_church",
    "animal_control_service",
    "animal_hospital",
    "animal_park",
    "animal_protection_organization",
    "animal_rescue_service",
    "animal_shelter",
    "animal_watering_hole",
    "animation_studio",
    "anime_club",
    "antique_furniture_restoration_service",
    "antique_furniture_store",
    "antique_store",
    "apartment_building",
    "apartment_complex",
    "apartment_rental_agency",
    "apostolic_church",
    "appliance_parts_supplier",
    "appliance_rental_service",
    "appliance_repair_service",
    "appliance_store",
    "appliances_customer_service",
    "appraiser",
    "apprenticeship_center",
    "aquaculture_farm",
    "aquarium",
    "aquarium_shop",
    "aquatic_center",
    "arab_restaurant",
    "arboretum",
    "arborist_and_tree_surgeon",
    "archaeological_museum",
    "archery_club",
    "archery_event",
    "archery_hall",
    "archery_range",
    "archery_store",
    "architect",
    "architects_association",
    "architectural_and_engineering_model_maker",
    "architectural_designer",
    "architectural_salvage_store",
    "architecture_firm",
    "architecture_school",
    "archive",
    "arena",
    "argentinian_restaurant",
    "armed_forces_association",
    "armenian_church",
    "armenian_restaurant",
    "army_and_navy_store",
    "army_base",
    "army_facility",
    "army_museum",
    "aromatherapy_class",
    "aromatherapy_service",
    "aromatherapy_supply_store",
    "art_cafe",
    "art_center",
    "art_dealer",
    "art_gallery",
    "art_handcraft",
    "art_museum",
    "art_restoration_service",
    "art_school",
    "art_studio",
    "art_supply_store",
    "artificial_plant_supplier",
    "artist",
    "arts_and_craft",
    "arts_organization",
    "asador",
    "asbestos_testing_service",
    "ashram",
    "asian_fusion_restaurant",
    "asian_grocery_store",
    "asian_household_goods_store",
    "asian_restaurant",
    "asphalt_contractor",
    "asphalt_mixing_plant",
    "assamese_restaurant",
    "assemblies_of_god_church",
    "assembly_hall",
    "assembly_room",
    "assistante_maternelle",
    "assisted_living_facility",
    "association_or_organization",
    "aston_martin_dealer",
    "astrologer",
    "asturian_restaurant",
    "athletic_club",
    "athletic_field",
    "athletic_park",
    "athletic_track",
    "atm",
    "attorney_referral_service",
    "atv_dealer",
    "atv_rental_service",
    "atv_repair_shop",
    "auction_house",
    "audi_dealer",
    "audio_visual_consultant",
    "audio_visual_equipment_rental_service",
    "audio_visual_equipment_repair_service",
    "audio_visual_equipment_supplier",
    "audiologist",
    "auditor",
    "auditorium",
    "australian_goods_store",
    "australian_restaurant",
    "austrian_restaurant",
    "auto_accessories_wholesaler",
    "auto_air_conditioning_service",
    "auto_auction",
    "auto_body_parts_supplier",
    "auto_body_shop",
    "auto_bodywork_mechanic",
    "auto_broker",
    "auto_chemistry_shop",
    "auto_dent_removal_service",
    "auto_electrical_service",
    "auto_glass_repair_service",
    "auto_glass_shop",
    "auto_insurance_agency",
    "auto_machine_shop",
    "auto_market",
    "auto_painting",
    "auto_parts_manufacturer",
    "auto_parts_market",
    "auto_parts_store",
    "auto_radiator_repair_service",
    "auto_repair_shop",
    "auto_restoration_service",
    "auto_rickshaw_stand",
    "auto_spring_shop",
    "auto_sunroof_shop",
    "auto_tag_agency",
    "auto_tune_up_service",
    "auto_upholsterer",
    "auto_window_tinting_service",
    "auto_wrecker",
    "automation_company",
    "automobile_storage_facility",
    "aviation_consultant",
    "aviation_training_institute",
    "awadhi_restaurant",
    "awning_supplier",
    "ayam_penyet_restaurant",
    "ayurvedic_clinic",
    "azerbaijani_restaurant",
    "baby_clothing_store",
    "baby_store",
    "baby_swimming_school",
    "babysitter",
    "baden_restaurant",
    "badminton_club",
    "badminton_complex",
    "badminton_court",
    "bag_shop",
    "bagel_shop",
    "bahai_house_of_worship",
    "bail_bonds_service",
    "bailiff",
    "bait_shop",
    "bakery",
    "bakery_equipment",
    "baking_supply_store",
    "bakso_restaurant",
    "balinese_restaurant",
    "ballet_school",
    "ballet_theater",
    "balloon_artist",
    "balloon_ride_tour_agency",
    "balloon_store",
    "ballroom_dance_instructor",
    "band",
    "bangladeshi_restaurant",
    "bangle_shop",
    "bank",
    "bankruptcy_attorney",
    "bankruptcy_service",
    "banner_store",
    "banquet_hall",
    "baptist_church",
    "bar",
    "bar_and_grill",
    "bar_pmu",
    "bar_restaurant_furniture_store",
    "bar_stool_supplier",
    "bar_tabac",
    "barbecue_restaurant",
    "barbecue_spots",
    "barber_school",
    "barber_shop",
    "barber_supply_store",
    "bariatric_surgeon",
    "bark_supplier",
    "barrel_supplier",
    "barrister",
    "bartending_school",
    "baseball",
    "baseball_club",
    "baseball_field",
    "baseball_goods_store",
    "basilica",
    "basket_supplier",
    "basketball_club",
    "basketball_court",
    "basketball_court_contractor",
    "basque_restaurant",
    "batak_restaurant",
    "bathroom_remodeler",
    "bathroom_supply_store",
    "batik_clothing_store",
    "battery_manufacturer",
    "battery_store",
    "battery_wholesaler",
    "batting_cage_center",
    "bavarian_restaurant",
    "bazar",
    "bbq_area",
    "beach_cleaning_service",
    "beach_clothing_store",
    "beach_entertainment_shop",
    "beach_pavillion",
    "beach_volleyball_club",
    "beach_volleyball_court",
    "bead_store",
    "bead_wholesaler",
    "bearing_supplier",
    "beautician",
    "beauty_product_supplier",
    "beauty_products_vending_machine",
    "beauty_products_wholesaler",
    "beauty_salon",
    "beauty_school",
    "beauty_supply_store",
    "bed_and_breakfast",
    "bed_shop",
    "bedding_store",
    "bedroom_furniture_store",
    "bee_relocation_service",
    "beef_rice_bowl_restaurant",
    "beer_distributor",
    "beer_garden",
    "beer_hall",
    "beer_store",
    "belgian_restaurant",
    "belt_shop",
    "bengali_restaurant",
    "bentley_dealer",
    "berry_restaurant",
    "berufsfachschule",
    "betawi_restaurant",
    "betting_agency",
    "beverage_distributor",
    "bicycle_club",
    "bicycle_rack",
    "bicycle_rental_service",
    "bicycle_repair_shop",
    "bicycle_store",
    "bicycle_wholesale",
    "bike_wash",
    "bikram_yoga_studio",
    "bilingual_school",
    "billiards_supply_store",
    "bingo_hall",
    "biochemical_supplier",
    "biochemistry_lab",
    "biofeedback_therapist",
    "biotechnology_company",
    "biotechnology_engineer",
    "bird_control_service",
    "bird_shop",
    "bird_watching_area",
    "birth_center",
    "birth_certificate_service",
    "birth_control_center",
    "biryani_restaurant",
    "bistro",
    "blacksmith",
    "blast_cleaning_service",
    "blind_school",
    "blinds_shop",
    "blood_bank",
    "blood_donation_center",
    "blood_testing_service",
    "blueprint_service",
    "blues_club",
    "bmw_dealer",
    "bmw_motorcycle_dealer",
    "bmx_club",
    "bmx_park",
    "bmx_track",
    "board_game_club",
    "board_of_education",
    "board_of_trade",
    "boarding_house",
    "boarding_school",
    "boat_accessories_supplier",
    "boat_builder",
    "boat_club",
    "boat_cover_supplier",
    "boat_dealer",
    "boat_ramp",
    "boat_rental_service",
    "boat_repair_shop",
    "boat_storage_facility",
    "boat_tour_agency",
    "boat_trailer_dealer",
    "boating_instructor",
    "bocce_ball_court",
    "bodega",
    "body_piercing_shop",
    "body_shaping_class",
    "boiler_manufacturer",
    "boiler_supplier",
    "bonesetting_house",
    "bonsai_plant_supplier",
    "book_publisher",
    "book_store",
    "bookbinder",
    "bookkeeping_service",
    "bookmaker",
    "books_wholesaler",
    "boot_camp",
    "boot_repair_shop",
    "boot_store",
    "border_crossing_station",
    "border_guard",
    "botanical_garden",
    "bottle_and_can_redemption_center",
    "bottled_water_supplier",
    "bouncy_castle_hire",
    "boutique",
    "bowling_alley",
    "bowling_club",
    "bowling_supply_shop",
    "box_lunch_supplier",
    "boxing_club",
    "boxing_gym",
    "boxing_ring",
    "boys_high_school",
    "bpo_company",
    "bpo_placement_agency",
    "brake_shop",
    "branding_agency",
    "brazilian_pastelaria",
    "brazilian_restaurant",
    "breakfast_restaurant",
    "brewery",
    "brewing_supply_store",
    "brewpub",
    "brick_manufacturer",
    "bricklayer",
    "bridal_shop",
    "bridge",
    "bridge_club",
    "british_restaurant",
    "brunch_restaurant",
    "bubble_tea_store",
    "buddhist_supplies_store",
    "buddhist_temple",
    "budget_japanese_inn",
    "buffet_restaurant",
    "bugatti_dealer",
    "buick_dealer",
    "building_consultant",
    "building_design_company",
    "building_designer",
    "building_equipment_hire_service",
    "building_firm",
    "building_inspector",
    "building_materials_market",
    "building_materials_store",
    "building_materials_supplier",
    "building_restoration_service",
    "building_society",
    "building_support_supplier",
    "building_surveyor",
    "bulgarian_restaurant",
    "bullring",
    "bungee_jumping_center",
    "burglar_alarm_store",
    "burmese_restaurant",
    "burrito_restaurant",
    "bus_and_coach_company",
    "bus_charter",
    "bus_company",
    "bus_depot",
    "bus_ticket_agency",
    "bus_tour_agency",
    "business_administration_service",
    "business_banking_service",
    "business_broker",
    "business_center",
    "business_development_service",
    "business_management_consultant",
    "business_networking_company",
    "business_park",
    "business_school",
    "business_to_business_service",
    "butane_gas_supplier",
    "butcher_shop",
    "butcher_shop_deli",
    "butsudan_store",
    "ca_indian_reserve",
    "cabaret_club",
    "cabin_rental_agency",
    "cabinet_maker",
    "cabinet_store",
    "cable_company",
    "cadillac_dealer",
    "cafe",
    "cafeteria",
    "cajun_restaurant",
    "cake_decorating_equipment_shop",
    "cake_shop",
    "californian_restaurant",
    "call_center",
    "call_shop",
    "calligraphy_lesson",
    "calvary_chapel_church",
    "cambodian_restaurant",
    "camera_repair_shop",
    "camera_store",
    "camp",
    "camper_shell_supplier",
    "campground",
    "camping_cabin",
    "camping_farm",
    "camping_store",
    "canadian_pacific_northwest_restaurant",
    "canadian_restaurant",
    "cancer_treatment_center",
    "candle_store",
    "candy_store",
    "cane_furniture_store",
    "cannabis_club",
    "cannabis_store",
    "cannery",
    "canoe_and_kayak_club",
    "canoe_and_kayak_rental_service",
    "canoe_and_kayak_store",
    "canoe_and_kayak_tour_agency",
    "canoeing_area",
    "cantabrian_restaurant",
    "cantonese_restaurant",
    "cape_verdean_restaurant",
    "capoeira_school",
    "capsule_hotel",
    "car_accessories_store",
    "car_alarm_supplier",
    "car_battery_store",
    "car_dealer",
    "car_detailing_service",
    "car_factory",
    "car_finance_and_loan_company",
    "car_inspection_station",
    "car_leasing_service",
    "car_manufacturer",
    "car_race_track",
    "car_rental_agency",
    "car_repair",
    "car_security_system_installer",
    "car_service",
    "car_sharing_location",
    "car_stereo_store",
    "car_wash",
    "carabinieri_police",
    "cardiologist",
    "cardiovascular_and_thoracic_surgeon",
    "career_guidance_service",
    "caribbean_restaurant",
    "carnival_club",
    "carpenter",
    "carpet_cleaning_service",
    "carpet_installer",
    "carpet_manufacturer",
    "carpet_store",
    "carpet_wholesaler",
    "carpool",
    "carport_and_pergola_builder",
    "carriage_ride_service",
    "carvery_restaurant",
    "cash_and_carry_wholesaler",
    "casino",
    "casket_service",
    "castilian_restaurant",
    "castle",
    "casual_japanese_style_restaurant",
    "casual_sushi_restaurant",
    "cat_boarding_service",
    "cat_breeder",
    "cat_hostel",
    "cat_trainer",
    "catalonian_restaurant",
    "catering_food_and_drink_supplies",
    "catering_service",
    "cathedral",
    "catholic_cathedral",
    "catholic_church",
    "catholic_school",
    "cattery",
    "cattle_farm",
    "cattle_market",
    "caucasian_restaurant",
    "cbse_school",
    "cd_store",
    "ceiling_supplier",
    "cell_phone_accessory_store",
    "cell_phone_charging_station",
    "cell_phone_store",
    "cement_manufacturer",
    "cement_supplier",
    "cemetery",
    "cendol_restaurant",
    "central_american_restaurant",
    "central_authority",
    "central_bank",
    "central_european_restaurant",
    "central_javanese_restaurant",
    "ceramic_manufacturer",
    "ceramics_wholesaler",
    "certification_agency",
    "certified_public_accountant",
    "chalet",
    "chamber_of_agriculture",
    "chamber_of_commerce",
    "chamber_of_handicrafts",
    "champon_noodle_restaurant",
    "chankonabe_restaurant",
    "chapel",
    "charcuterie",
    "charity",
    "charter_school",
    "chartered_accountant",
    "check_cashing_service",
    "cheese_manufacturer",
    "cheese_shop",
    "cheesesteak_restaurant",
    "chemical_engineer",
    "chemical_exporter",
    "chemical_manufacturer",
    "chemical_plant",
    "chemical_wholesaler",
    "chemistry_faculty",
    "chemistry_lab",
    "chesapeake_restaurant",
    "chess_and_card_club",
    "chess_club",
    "chess_instructor",
    "chettinad_restaurtant",
    "chevrolet_dealer",
    "chicken_hatchery",
    "chicken_restaurant",
    "chicken_shop",
    "chicken_wings_restaurant",
    "child_care_agency",
    "child_health_care_centre",
    "child_psychiatrist",
    "child_psychologist",
    "childbirth_class",
    "children_amusement_center",
    "children_hall",
    "children_policlinic",
    "childrens_book_store",
    "childrens_cafe",
    "childrens_clothing_store",
    "childrens_club",
    "childrens_farm",
    "childrens_furniture_store",
    "childrens_home",
    "childrens_hospital",
    "childrens_library",
    "childrens_museum",
    "childrens_party_buffet",
    "childrens_party_service",
    "childrens_store",
    "childrens_theater",
    "chilean_restaurant",
    "chimney_services",
    "chimney_sweep",
    "chinaware_store",
    "chinese_language_instructor",
    "chinese_language_school",
    "chinese_medicine_clinic",
    "chinese_medicine_store",
    "chinese_noodle_restaurant",
    "chinese_pastry",
    "chinese_restaurant",
    "chinese_supermarket",
    "chinese_takeaway",
    "chinese_tea_house",
    "chiropractor",
    "chocolate_artisan",
    "chocolate_cafe",
    "chocolate_factory",
    "chocolate_shop",
    "choir",
    "chop_bar",
    "chophouse_restaurant",
    "christian_book_store",
    "christian_church",
    "christian_college",
    "christmas_market",
    "christmas_store",
    "christmas_tree_farm",
    "chrysler_dealer",
    "church",
    "church_of_christ",
    "church_of_jesus_christ_of_latter_day_saints",
    "church_of_the_nazarene",
    "church_office",
    "church_supply_store",
    "churreria",
    "cider_bar",
    "cider_mill",
    "cig_kofte_restaurant",
    "cigar_shop",
    "cinema_equipment_supplier",
    "circular_distribution_service",
    "circus",
    "citizen_information_bureau",
    "citizens_advice_bureau",
    "citroen_dealer",
    "city_administration",
    "city_bus",
    "city_clerks_office",
    "city_courthouse",
    "city_department_of_environment",
    "city_department_of_public_safety",
    "city_department_of_transportation",
    "city_district_office",
    "city_employment_department",
    "city_government_office",
    "city_hall",
    "city_park",
    "city_pillar_shine",
    "city_tax_office",
    "civic_center",
    "civil_defence",
    "civil_engineer",
    "civil_engineering_company",
    "civil_examinations_academy",
    "civil_law_attorney",
    "civil_police",
    "civil_registry",
    "class",
    "classified_ads_newspaper_publisher",
    "cleaners",
    "cleaning_products_supplier",
    "clergyman",
    "clock_repair_service",
    "clock_watch_maker",
    "closed_circuit_television",
    "clothes_and_fabric_manufacturer",
    "clothes_and_fabric_wholesaler",
    "clothes_market",
    "clothing_alteration_service",
    "clothing_store",
    "clothing_supplier",
    "clothing_wholesale_market_place",
    "clothing_wholesaler",
    "club",
    "cng_fittment_center",
    "cng_station",
    "coach_therapist",
    "coaching_center",
    "coal_exporter",
    "coal_supplier",
    "coalfield",
    "coast_guard_station",
    "coat_wholesaler",
    "cocktail_bar",
    "coed_school",
    "coffee_machine_supplier",
    "coffee_roasters",
    "coffee_shop",
    "coffee_stand",
    "coffee_store",
    "coffee_vending_machine",
    "coffee_wholesaler",
    "coffin_supplier",
    "coin_dealer",
    "coin_operated_laundry_equipment_supplier",
    "coin_operated_locker",
    "cold_cut_store",
    "cold_noodle_restaurant",
    "cold_storage_facility",
    "collectibles_store",
    "college",
    "college_of_agriculture",
    "colombian_restaurant",
    "combined_primary_and_secondary_school",
    "comedy_club",
    "comic_book_store",
    "comic_cafe",
    "commercial_agent",
    "commercial_cleaning_service",
    "commercial_printer",
    "commercial_real_estate_agency",
    "commercial_real_estate_inspector",
    "commercial_refrigeration",
    "commercial_refrigerator_supplier",
    "commissioner_for_oaths",
    "communications_central",
    "community_center",
    "community_college",
    "community_garden",
    "community_health_center",
    "community_school",
    "company_registry",
    "comprehensive_secondary_school",
    "computer_accessories_store",
    "computer_club",
    "computer_consultant",
    "computer_desk_store",
    "computer_hardware_manufacturer",
    "computer_networking_center",
    "computer_repair_service",
    "computer_security_service",
    "computer_service",
    "computer_software_store",
    "computer_store",
    "computer_support_and_services",
    "computer_training_school",
    "computer_wholesaler",
    "concert_hall",
    "concrete_contractor",
    "concrete_factory",
    "concrete_metal_framework_supplier",
    "concrete_product_supplier",
    "condiments_supplier",
    "condominium_complex",
    "condominium_rental_agency",
    "confectionery",
    "confectionery_wholesaler",
    "conference_center",
    "congregation",
    "conservation_department",
    "conservative_club",
    "conservative_synagogue",
    "conservatory_construction_contractor",
    "conservatory_of_music",
    "conservatory_specialist",
    "consignment_shop",
    "construction_and_maintenance_office",
    "construction_company",
    "construction_equipment_supplier",
    "construction_machine_dealer",
    "construction_machine_rental_service",
    "construction_material_wholesaler",
    "consultant",
    "consumer_advice_center",
    "contact_lenses_supplier",
    "container_service",
    "container_supplier",
    "container_terminal",
    "containers_supplier",
    "contemporary_louisiana_restaurant",
    "continental_restaurant",
    "contractor",
    "convenience_store",
    "convenience_stores_organization",
    "convention_center",
    "convention_information_bureau",
    "conveyancer",
    "cookie_shop",
    "cooking_class",
    "cooking_school",
    "cooling_plant",
    "cooperative_bank",
    "copier_repair_service",
    "copper_supplier",
    "coppersmith",
    "copy_shop",
    "copying_supply_store",
    "corporate_campus",
    "corporate_entertainment_service",
    "corporate_gift_supplier",
    "corporate_office",
    "correctional_services_department",
    "cosmetic_dentist",
    "cosmetic_products_manufacturer",
    "cosmetic_surgeon",
    "cosmetics_and_parfumes_supplier",
    "cosmetics_industry",
    "cosmetics_store",
    "cosmetics_wholesaler",
    "cosplay_cafe",
    "costa_rican_restaurant",
    "costume_jewelry_shop",
    "costume_rental_service",
    "costume_store",
    "cottage",
    "cottage_rental",
    "cottage_village",
    "cotton_exporter",
    "cotton_mill",
    "cotton_supplier",
    "council",
    "counselor",
    "countertop_contractor",
    "countertop_store",
    "country_club",
    "country_food_restaurant",
    "country_house",
    "country_park",
    "county_government_office",
    "courier_service",
    "course_center",
    "court_executive_officer",
    "court_reporter",
    "couscous_restaurant",
    "couture_store",
    "coworking_space",
    "crab_dish_restaurant",
    "craft_store",
    "cramming_school",
    "crane_dealer",
    "crane_rental_agency",
    "crane_service",
    "craniosacral_therapy",
    "creche",
    "credit_counseling_service",
    "credit_reporting_agency",
    "credit_union",
    "cremation_service",
    "creole_restaurant",
    "creperie",
    "cricket_club",
    "cricket_ground",
    "cricket_shop",
    "crime_victim_service",
    "criminal_law_attorney",
    "croatian_restaurant",
    "crop_grower",
    "croquet_club",
    "cruise_agency",
    "cruise_line",
    "cruise_terminal",
    "crushed_stone_supplier",
    "crypto_atm",
    "cuban_restaurant",
    "culinary_school",
    "cultural_association",
    "cultural_center",
    "cultural_landmark",
    "cupcake_shop",
    "cupra_dealer",
    "cured_ham_bar",
    "cured_ham_store",
    "cured_ham_warehouse",
    "curling_club",
    "curling_hall",
    "currency_exchange_service",
    "curtain_and_upholstery_cleaner",
    "curtain_store",
    "curtain_supplier_and_maker",
    "custom_confiscated_goods_store",
    "custom_home_builder",
    "custom_label_printer",
    "custom_t_shirt_store",
    "custom_tailor",
    "customs_broker",
    "customs_consultant",
    "customs_department",
    "customs_office",
    "customs_warehouse",
    "cutlery_store",
    "cycle_rickshaw_stand",
    "cycling_park",
    "czech_restaurant",
    "dacia_dealer",
    "daihatsu_dealer",
    "dairy",
    "dairy_farm",
    "dairy_farm_equipment_supplier",
    "dairy_store",
    "dairy_supplier",
    "dan_dan_noodle_restaurant",
    "dance_association",
    "dance_club",
    "dance_company",
    "dance_conservatory",
    "dance_hall",
    "dance_pavillion",
    "dance_restaurant",
    "dance_school",
    "dance_store",
    "danish_restaurant",
    "dart_bar",
    "dart_supply_store",
    "data_entry_service",
    "data_recovery_service",
    "database_management_company",
    "dating_service",
    "day_care_center",
    "day_spa",
    "deaf_church",
    "deaf_school",
    "deaf_service",
    "dealer_of_fiat_professional",
    "debris_removal_service",
    "debt_collecting",
    "debt_collection_agency",
    "decal_supplier",
    "deck_builder",
    "deli",
    "delivery_chinese_restaurant",
    "delivery_service",
    "demolition_contractor",
    "denim_wear_store",
    "dental_clinic",
    "dental_hygienist",
    "dental_implants_periodontist",
    "dental_implants_provider",
    "dental_insurance_agency",
    "dental_lab",
    "dental_radiology",
    "dental_school",
    "dental_supply_store",
    "dentist",
    "denture_care_center",
    "department_for_regional_development",
    "department_of_education",
    "department_of_finance",
    "department_of_housing",
    "department_of_motor_vehicles",
    "department_of_public_safety",
    "department_of_social_services",
    "department_of_transportation",
    "department_store",
    "dept_of_city_treasure",
    "dept_of_state_treasure",
    "dermatologist",
    "desalination_plant",
    "design_agency",
    "design_engineer",
    "design_institute",
    "desktop_publishing_service",
    "dessert_buffet",
    "dessert_restaurant",
    "dessert_shop",
    "detective",
    "detention_center",
    "dhaba",
    "diabetes_center",
    "diabetes_equipment_supplier",
    "diabetologist",
    "diagnostic_center",
    "dialysis_center",
    "diamond_buyer",
    "diamond_dealer",
    "diaper_service",
    "diesel_engine_dealer",
    "diesel_engine_repair_service",
    "diesel_fuel_supplier",
    "dietitian",
    "digital_printer",
    "digital_printing_service",
    "dim_sum_restaurant",
    "diner",
    "dinner_theater",
    "direct_mail_advertising",
    "dirt_supplier",
    "disability_equipment_supplier",
    "disability_services_and_support_organization",
    "disabled_sports_center",
    "disc_golf_course",
    "disciples_of_christ_church",
    "disco",
    "discount_store",
    "discount_supermarket",
    "display_home_center",
    "display_stand_manufacturer",
    "disposable_tableware_supplier",
    "distance_learning_center",
    "distillery",
    "distribution_service",
    "district_attorney",
    "district_council",
    "district_government_office",
    "district_justice",
    "district_office",
    "dive_club",
    "dive_shop",
    "diving_center",
    "diving_service",
    "divorce_attorney",
    "divorce_service",
    "dj",
    "dj_supply_store",
    "do_it_yourself_store",
    "dock_builder",
    "doctor",
    "dodge_dealer",
    "dog_breeder",
    "dog_cafe",
    "dog_day_care_center",
    "dog_hostel",
    "dog_park",
    "dog_trainer",
    "dog_walker",
    "dogsled_ride_service",
    "dojo_restaurant",
    "doll_restoration_service",
    "doll_store",
    "dollar_store",
    "dome",
    "domestic_abuse_treatment_center",
    "domestic_airport",
    "dominican_restaurant",
    "donations_center",
    "doner_kebab_restaurant",
    "donut_shop",
    "door_manufacturer",
    "door_shop",
    "door_supplier",
    "door_warehouse",
    "double_glazing_supplier",
    "doula",
    "down_home_cooking_restaurant",
    "drafting_equipment_supplier",
    "drafting_service",
    "drainage_service",
    "drama_school",
    "drama_theater",
    "drawing_lessons",
    "dress_and_tuxedo_rental_service",
    "dress_shop",
    "dressmaker",
    "dried_flower_shop",
    "dried_seafood_store",
    "drilling_contractor",
    "drilling_equipment_supplier",
    "drinking_water_fountain",
    "drive_in_movie_theater",
    "driver_vehicle_licensing_agency",
    "drivers_license_office",
    "drivers_license_training_school",
    "driveshaft_shop",
    "driving_school",
    "driving_test_centre",
    "drone_shop",
    "drug_testing_service",
    "drugstore",
    "drum_school",
    "drum_store",
    "dry_cleaner",
    "dry_fruit_store",
    "dry_ice_supplier",
    "dry_wall_contractor",
    "dry_wall_supply_store",
    "dryer_vent_cleaning_service",
    "ds_automobiles_dealer",
    "dude_ranch",
    "dump_truck_dealer",
    "dumpling_restaurant",
    "dumpster_rental_service",
    "durum_restaurant",
    "dutch_restaurant",
    "duty_free_store",
    "dvd_store",
    "dye_store",
    "dyeworks",
    "dynamometer_supplier",
    "e_commerce_agency",
    "e_commerce_service",
    "ear_piercing_service",
    "earth_works_company",
    "east_african_restaurant",
    "east_javanese_restaurant",
    "eastern_european_restaurant",
    "eastern_orthodox_church",
    "eating_disorder_treatment_center",
    "eclectic_restaurant",
    "ecological_park",
    "ecologists_association",
    "economic_consultant",
    "economic_development_agency",
    "ecuadorian_restaurant",
    "education_center",
    "educational_consultant",
    "educational_institution",
    "educational_supply_store",
    "educational_testing_service",
    "eftpos_equipment_supplier",
    "egg_supplier",
    "egyptian_restaurant",
    "elder_law_attorney",
    "electric_motor_repair_shop",
    "electric_motor_scooter_dealer",
    "electric_motor_store",
    "electric_motor_vehicle_dealer",
    "electric_motorcycle_dealer",
    "electric_utility_company",
    "electric_utility_manufacturer",
    "electric_vehicle_charging_station",
    "electric_vehicle_charging_station_contractor",
    "electrical_appliance_wholesaler",
    "electrical_engineer",
    "electrical_equipment_supplier",
    "electrical_installation_service",
    "electrical_repair_shop",
    "electrical_substation",
    "electrical_supply_store",
    "electrical_wholesaler",
    "electrician",
    "electrolysis_hair_removal_service",
    "electronic_engineer",
    "electronic_parts_supplier",
    "electronics_accessories_wholesaler",
    "electronics_company",
    "electronics_engineer",
    "electronics_exporter",
    "electronics_hire_shop",
    "electronics_manufacturer",
    "electronics_repair_shop",
    "electronics_store",
    "electronics_vending_machine",
    "electronics_wholesaler",
    "elementary_school",
    "elevator_manufacturer",
    "elevator_service",
    "embassy",
    "embossing_service",
    "embroidery_service",
    "embroidery_shop",
    "emergency_call_station",
    "emergency_care_physician",
    "emergency_care_service",
    "emergency_dental_service",
    "emergency_locksmith_service",
    "emergency_management_ministry",
    "emergency_room",
    "emergency_training",
    "emergency_training_school",
    "emergency_veterinarian_service",
    "employment_agency",
    "employment_attorney",
    "employment_center",
    "employment_consultant",
    "employment_search_service",
    "endocrinologist",
    "endodontist",
    "endoscopist",
    "energy_equipment_and_solutions",
    "energy_supplier",
    "engine_rebuilding_service",
    "engineer",
    "engineer_station",
    "engineering_consultant",
    "engineering_school",
    "english_language_camp",
    "english_language_instructor",
    "english_language_school",
    "english_restaurant",
    "engraver",
    "entertainer",
    "entertainment_agency",
    "envelope_supplier",
    "environment_office",
    "environment_renewable_natural_resources",
    "environmental_consultant",
    "environmental_engineer",
    "environmental_health_service",
    "environmental_organization",
    "environmental_protection_organization",
    "episcopal_church",
    "equestrian_club",
    "equestrian_facility",
    "equestrian_store",
    "equipment_exporter",
    "equipment_importer",
    "equipment_rental_agency",
    "equipment_supplier",
    "eritrean_restaurant",
    "erotic_massage",
    "escape_room_center",
    "escrow_service",
    "espresso_bar",
    "establishment_poi",
    "establishment_service",
    "estate_appraiser",
    "estate_liquidator",
    "estate_planning_attorney",
    "ethiopian_restaurant",
    "ethnic_restaurant",
    "ethnographic_museum",
    "european_institution",
    "european_restaurant",
    "evangelical_church",
    "evening_class",
    "evening_dress_rental_service",
    "evening_school",
    "event_management_company",
    "event_planner",
    "event_technology_service",
    "event_ticket_seller",
    "event_venue",
    "excavating_contractor",
    "executive_search_firm",
    "executive_suite_rental_agency",
    "executor",
    "exhibit",
    "exhibition_and_trade_center",
    "exhibition_planner",
    "exporter",
    "extended_stay_hotel",
    "extraction_supplier_company",
    "extremadurian_restaurant",
    "eye_care_center",
    "eyebrow_bar",
    "eyelash_salon",
    "fabric_product_manufacturer",
    "fabric_store",
    "fabric_wholesaler",
    "fabrication_engineer",
    "facial_spa",
    "factory_equipment_supplier",
    "faculty_of_arts",
    "faculty_of_law",
    "faculty_of_pharmacy",
    "faculty_of_psychology",
    "faculty_of_science",
    "faculty_of_sports",
    "fair_trade_organization",
    "fairground",
    "falafel_restaurant",
    "family_counselor",
    "family_day_care_service",
    "family_law_attorney",
    "family_planning_center",
    "family_planning_counselor",
    "family_practice_physician",
    "family_restaurant",
    "family_service_center",
    "farm",
    "farm_bureau",
    "farm_equipment_repair_service",
    "farm_equipment_supplier",
    "farm_household_tour",
    "farm_school",
    "farm_shop",
    "farmers_market",
    "farmstay",
    "farrier_service",
    "fashion_accessories_store",
    "fashion_design_school",
    "fashion_designer",
    "fast_food_restaurant",
    "fastener_supplier",
    "favela",
    "fax_service",
    "federal_agency_for_technical_relief",
    "federal_credit_union",
    "federal_government_office",
    "federal_police",
    "federal_reserve_bank",
    "feed_manufacturer",
    "feed_store",
    "felt_boots_store",
    "fence_contractor",
    "fence_supply_store",
    "fencing_salon",
    "fencing_school",
    "feng_shui_consultant",
    "feng_shui_shop",
    "ferrari_dealer",
    "ferris_wheel",
    "ferry_service",
    "fertility_clinic",
    "fertility_physician",
    "fertilizer_supplier",
    "festival",
    "festival_hall",
    "fiat_dealer",
    "fiber_optic_products_supplier",
    "fiberglass_repair_service",
    "fiberglass_supplier",
    "figurine_shop",
    "filipino_grocery_store",
    "filipino_restaurant",
    "film_and_photograph_library",
    "film_production_company",
    "filtration_plant",
    "finance_broker",
    "financial_advisor",
    "financial_audit",
    "financial_consultant",
    "financial_institution",
    "financial_planner",
    "fine_dining_restaurant",
    "fingerprinting_service",
    "finishing_materials_supplier",
    "finnish_restaurant",
    "fire_alarm_supplier",
    "fire_damage_restoration_service",
    "fire_department_equipment_supplier",
    "fire_fighters_academy",
    "fire_protection_consultant",
    "fire_protection_equipment_supplier",
    "fire_protection_service",
    "fire_protection_system_supplier",
    "fire_station",
    "firearms_academy",
    "fireplace_manufacturer",
    "fireplace_store",
    "firewood_supplier",
    "fireworks_store",
    "fireworks_supplier",
    "first_aid",
    "fish_and_chips_restaurant",
    "fish_and_chips_takeaway",
    "fish_farm",
    "fish_processing",
    "fish_restaurant",
    "fish_spa",
    "fish_store",
    "fisherman",
    "fishing_camp",
    "fishing_charter",
    "fishing_club",
    "fishing_pier",
    "fishing_pond",
    "fishing_store",
    "fitness_center",
    "fitness_equipment_store",
    "fitness_equipment_wholesaler",
    "fitted_furniture_supplier",
    "fixed_base_operator",
    "flag_store",
    "flamenco_dance_store",
    "flamenco_school",
    "flamenco_theater",
    "flavours_fragrances_and_aroma_supplier",
    "flea_market",
    "flight_school",
    "floating_market",
    "floor_refinishing_service",
    "floor_sanding_and_polishing_service",
    "flooring_contractor",
    "flooring_store",
    "floridian_restaurant",
    "florist",
    "flour_mill",
    "flower_delivery",
    "flower_designer",
    "flower_market",
    "fmcg_goods_wholesaler",
    "fmcg_manufacturer",
    "foam_rubber_producer",
    "foam_rubber_supplier",
    "folk_high_school",
    "fondue_restaurant",
    "food_and_beverage_consultant",
    "food_and_beverage_exporter",
    "food_bank",
    "food_broker",
    "food_court",
    "food_machinery_supplier",
    "food_manufacturer",
    "food_manufacturing_supply",
    "food_processing_company",
    "food_processing_equipment",
    "food_producer",
    "food_products_supplier",
    "food_seasoning_manufacturer",
    "foot_bath",
    "foot_care",
    "foot_massage_parlor",
    "football_club",
    "football_field",
    "ford_dealer",
    "foreclosure_service",
    "foreign_consulate",
    "foreign_exchange_students_organization",
    "foreign_languages_program_school",
    "foreign_trade_consultant",
    "foreman_builders_association",
    "forensic_consultant",
    "forestry_service",
    "forklift_dealer",
    "forklift_rental_service",
    "formal_clothing_store",
    "fortress",
    "fortune_telling_services",
    "foster_care_service",
    "foundation",
    "foundry",
    "fountain_contractor",
    "foursquare_church",
    "franconian_restaurant",
    "fraternal_organization",
    "free_clinic",
    "free_parking_lot",
    "freestyle_wrestling",
    "freight_forwarding_service",
    "french_language_school",
    "french_restaurant",
    "french_steakhouse_restaurant",
    "fresh_food_market",
    "fried_chicken_takeaway",
    "friends_church",
    "frituur",
    "frozen_dessert_supplier",
    "frozen_food_manufacturer",
    "frozen_food_store",
    "frozen_yogurt_shop",
    "fruit_and_vegetable_processing",
    "fruit_and_vegetable_store",
    "fruit_and_vegetable_wholesaler",
    "fruit_parlor",
    "fruit_wholesaler",
    "fruits_wholesaler",
    "fu_jian_restaurant",
    "fuel_supplier",
    "fugu_restaurant",
    "full_dress_rental_service",
    "full_gospel_church",
    "function_room_facility",
    "fund_management_company",
    "funeral_celebrant_service",
    "funeral_director",
    "funeral_home",
    "fur_coat_shop",
    "fur_manufacturer",
    "fur_service",
    "furnace_parts_supplier",
    "furnace_repair_service",
    "furnace_store",
    "furnished_apartment_building",
    "furniture_accessories",
    "furniture_accessories_supplier",
    "furniture_maker",
    "furniture_manufacturer",
    "furniture_rental_service",
    "furniture_repair_shop",
    "furniture_store",
    "furniture_wholesaler",
    "fusion_restaurant",
    "futon_store",
    "futsal_field",
    "galician_restaurant",
    "gambling_house",
    "gambling_instructor",
    "game_store",
    "garage_builder",
    "garage_door_supplier",
    "garbage_collection_service",
    "garbage_dump",
    "garbage_dump_service",
    "garden",
    "garden_building_retail",
    "garden_center",
    "garden_furniture_store",
    "garden_machinery",
    "gardener",
    "garment_exporter",
    "gas_company",
    "gas_cylinders_supplier",
    "gas_engineer",
    "gas_installation_service",
    "gas_logs_supplier",
    "gas_shop",
    "gas_station",
    "gasfitter",
    "gasket_manufacturer",
    "gastroenterologist",
    "gastrointestinal_surgeon",
    "gastropub",
    "gated_community",
    "gay_and_lesbian_organization",
    "gay_bar",
    "gay_night_club",
    "gay_sauna",
    "gazebo_builder",
    "gemologist",
    "genealogist",
    "general_contractor",
    "general_education_school",
    "general_hospital",
    "general_practice_attorney",
    "general_practitioner",
    "general_register_office",
    "general_store",
    "generator_shop",
    "genesis_dealer",
    "geography_and_history_faculty",
    "geological_research_company",
    "geological_service",
    "geologist",
    "georgian_restaurant",
    "geotechnical_engineer",
    "geriatrician",
    "german_language_school",
    "german_restaurant",
    "ghost_town",
    "gift_basket_store",
    "gift_shop",
    "gift_wrap_store",
    "girl_bar",
    "girls_secondary_school",
    "glass_and_mirror_shop",
    "glass_block_supplier",
    "glass_blower",
    "glass_cutting_service",
    "glass_engraving",
    "glass_etching_service",
    "glass_industry",
    "glass_manufacturer",
    "glass_merchant",
    "glass_repair_service",
    "glass_shop",
    "glasses_repair_service",
    "glassware_manufacturer",
    "glassware_store",
    "glassware_wholesaler",
    "glazier",
    "gluten_free_restaurant",
    "gmc_dealer",
    "go_kart_track",
    "goan_restaurant",
    "gold_dealer",
    "gold_mining_company",
    "goldfish_store",
    "goldsmith",
    "golf_cart_dealer",
    "golf_club",
    "golf_course",
    "golf_course_builder",
    "golf_driving_range",
    "golf_instructor",
    "golf_shop",
    "gospel_church",
    "gourmet_grocery_store",
    "government_college",
    "government_economic_program",
    "government_hospital",
    "government_office",
    "government_ration_shop",
    "government_school",
    "gps_supplier",
    "graduate_school",
    "graffiti_removal_service",
    "grain_elevator",
    "grammar_school",
    "granite_supplier",
    "graphic_designer",
    "gravel_pit",
    "gravel_plant",
    "greco_roman_wrestling",
    "greek_orthodox_church",
    "greek_restaurant",
    "green_energy_supplier",
    "green_grocers",
    "greenhouse",
    "greeting_card_shop",
    "greyhound_stadium",
    "grill_store",
    "grocery_delivery_service",
    "grocery_store",
    "ground_self_defense_force",
    "group_accommodation",
    "group_home",
    "guardia_civil",
    "guardia_di_finanza_police",
    "guatemalan_restaurant",
    "guest_house",
    "gui_zhou_restaurant",
    "guitar_instructor",
    "guitar_store",
    "gujarati_restaurant",
    "gun_club",
    "gun_shop",
    "guts_barbecue_restaurant",
    "gutter_cleaning_service",
    "gym",
    "gymnasium_cz",
    "gymnasium_school",
    "gymnastics_center",
    "gymnastics_club",
    "gynecologist",
    "gynecologist_only",
    "gypsum_product_supplier",
    "gyro_restaurant",
    "haberdashery",
    "hair_extension_technician",
    "hair_extensions_supplier",
    "hair_removal_service",
    "hair_replacement_service",
    "hair_salon",
    "hair_transplantation_clinic",
    "haitian_restaurant",
    "hakka_restaurant",
    "halal_restaurant",
    "haleem_restaurant",
    "halfway_house",
    "ham_shop",
    "hamburger_restaurant",
    "hammam",
    "hand_surgeon",
    "handbags_shop",
    "handball_club",
    "handball_court",
    "handicapped_transportation_service",
    "handicraft",
    "handicraft_exporter",
    "handicraft_fair",
    "handicraft_museum",
    "handicraft_school",
    "handicrafts_wholesaler",
    "handyman",
    "hang_gliding_center",
    "hardware_store",
    "hardware_training_institute",
    "harley_davidson_dealer",
    "hat_shop",
    "haunted_house",
    "hauptschule",
    "haute_couture_fashion_house",
    "haute_french_restaurant",
    "hawaiian_goods_store",
    "hawaiian_restaurant",
    "hawker_centre",
    "hawker_stall",
    "hay_supplier",
    "head_start_center",
    "health_and_beauty_shop",
    "health_consultant",
    "health_counselor",
    "health_food_restaurant",
    "health_food_store",
    "health_insurance_agency",
    "health_resort",
    "health_spa",
    "hearing_aid_repair_service",
    "hearing_aid_store",
    "heart_hospital",
    "heat_plant",
    "heating_contractor",
    "heating_equipment_supplier",
    "heating_oil_supplier",
    "height_works",
    "helicopter_charter",
    "helicopter_tour_agency",
    "heliport",
    "helium_gas_supplier",
    "helpline",
    "hematologist",
    "hepatologist",
    "herb_shop",
    "herbal_medicine_store",
    "herbalist",
    "heritage_building",
    "heritage_museum",
    "heritage_preservation",
    "heritage_railroad",
    "high_ropes_course",
    "high_school",
    "higher_secondary_school",
    "highway_patrol",
    "hiking_area",
    "hindu_priest",
    "hindu_temple",
    "hip_hop_dance_class",
    "hispanic_church",
    "historical_landmark",
    "historical_place_museum",
    "historical_society",
    "history_museum",
    "hiv_testing_center",
    "hoagie_restaurant",
    "hobby_club",
    "hobby_store",
    "hockey_club",
    "hockey_field",
    "hockey_rink",
    "hockey_supply_store",
    "holding_company",
    "holiday_accommodation_service",
    "holiday_apartment_rental",
    "holiday_home",
    "holiday_park",
    "holistic_medicine_practitioner",
    "home_automation_company",
    "home_builder",
    "home_cinema_installation",
    "home_goods_store",
    "home_hairdresser",
    "home_health_care_service",
    "home_help",
    "home_help_service_agency",
    "home_improvement_store",
    "home_inspector",
    "home_insurance_agency",
    "home_theater_store",
    "homekill_service",
    "homeless_service",
    "homeless_shelter",
    "homeopath",
    "homeopathic_pharmacy",
    "homeowners_association",
    "honda_dealer",
    "honduran_restaurant",
    "honey_farm",
    "hong_kong_style_cafe",
    "hong_kong_style_fast_food_restaurant",
    "hookah_bar",
    "hookah_store",
    "horse_boarding_stable",
    "horse_breeder",
    "horse_rental_service",
    "horse_riding_field",
    "horse_riding_school",
    "horse_trailer_dealer",
    "horse_trainer",
    "horseback_riding_service",
    "horsebox_specialist",
    "horseshoe_smith",
    "horsestable_studfarm",
    "hose_supplier",
    "hospice",
    "hospital",
    "hospital_and_equipment_supplies",
    "hospital_department",
    "hospitality_and_tourism_school",
    "hospitality_high_school",
    "host_club",
    "hostel",
    "hot_bedstone_spa",
    "hot_dog_restaurant",
    "hot_dog_stand",
    "hot_pot_restaurant",
    "hot_spring_hotel",
    "hot_tub_repair_service",
    "hot_tub_store",
    "hot_water_system_supplier",
    "hotel",
    "hotel_management_school",
    "hotel_supply_store",
    "house_cleaning_service",
    "house_clearance_service",
    "house_sitter",
    "house_sitter_agency",
    "houseboat_rental_service",
    "household_chemicals_supplier",
    "household_goods_wholesaler",
    "housing_association",
    "housing_authority",
    "housing_complex",
    "housing_cooperative",
    "housing_development",
    "housing_society",
    "housing_utility_company",
    "hua_gong_shop",
    "hua_niao_market_place",
    "hub_cap_supplier",
    "huissier",
    "human_ressource_consulting",
    "hunan_style_restaurant",
    "hungarian_restaurant",
    "hunting_and_fishing_store",
    "hunting_area",
    "hunting_club",
    "hunting_preserve",
    "hunting_store",
    "hvac_contractor",
    "hyderabadi_restaurant",
    "hydraulic_engineer",
    "hydraulic_equipment_supplier",
    "hydraulic_repair_service",
    "hydroelectric_power_plant",
    "hydroponics_equipment_supplier",
    "hygiene_articles_wholesaler",
    "hygiene_station",
    "hyperbaric_medicine_physician",
    "hypermarket",
    "hypnotherapy_service",
    "hyundai_dealer",
    "ice_cream_equipment_supplier",
    "ice_cream_shop",
    "ice_hockey_club",
    "ice_skating_club",
    "ice_skating_instructor",
    "ice_skating_rink",
    "ice_supplier",
    "icelandic_restaurant",
    "icse_school",
    "idol_manufacturer",
    "ikan_bakar_restaurant",
    "image_consultant",
    "imax_theater",
    "immigration_and_naturalization_service",
    "immigration_attorney",
    "immigration_detention_center",
    "immunologist",
    "impermeabilization_service",
    "import_export_company",
    "importer",
    "incense_supplier",
    "incineration_plant",
    "income_protection_insurance",
    "income_tax_help_association",
    "independent_school",
    "indian_grocery_store",
    "indian_motorcycle_dealer",
    "indian_muslim_restaurant",
    "indian_restaurant",
    "indian_sizzler_restaurant",
    "indian_sweets_shop",
    "indian_takeaway",
    "indonesian_restaurant",
    "indoor_cycling",
    "indoor_golf_course",
    "indoor_lodging",
    "indoor_playground",
    "indoor_snowcenter",
    "indoor_swimming_pool",
    "industrial_area",
    "industrial_chemicals_wholesaler",
    "industrial_consultant",
    "industrial_design_company",
    "industrial_door_supplier",
    "industrial_engineer",
    "industrial_engineers_association",
    "industrial_equipment_supplier",
    "industrial_framework_supplier",
    "industrial_gas_supplier",
    "industrial_real_estate_agency",
    "industrial_supermarket",
    "industrial_technical_engineers_association",
    "industrial_vacuum_equipment_supplier",
    "infectious_disease_physician",
    "infiniti_dealer",
    "information_bureau",
    "information_services",
    "inn",
    "insolvency_service",
    "institute_of_geography_and_statistics",
    "instrumentation_engineer",
    "insulation_contractor",
    "insulation_materials_store",
    "insulator_supplier",
    "insurance_agency",
    "insurance_attorney",
    "insurance_broker",
    "insurance_company",
    "insurance_school",
    "intellectual_property_registry",
    "intensivist",
    "interior_architect_office",
    "interior_construction_contractor",
    "interior_decorator",
    "interior_designer",
    "interior_door",
    "interior_fitting_contractor",
    "interior_plant_service",
    "internal_medicine_ward",
    "international_airport",
    "international_school",
    "international_trade_consultant",
    "internet_cafe",
    "internet_marketing_service",
    "internet_service_provider",
    "internet_shop",
    "internist",
    "investment_bank",
    "investment_company",
    "investment_service",
    "invitation_printing_service",
    "irish_goods_store",
    "irish_pub",
    "irish_restaurant",
    "iron_steel_contractor",
    "iron_ware_dealer",
    "iron_works",
    "irrigation_equipment_supplier",
    "israeli_restaurant",
    "isuzu_dealer",
    "italian_grocery_store",
    "italian_restaurant",
    "iup",
    "iut",
    "jaguar_dealer",
    "jain_temple",
    "jamaican_restaurant",
    "janitorial_equipment_supplier",
    "janitorial_service",
    "japanese_authentic_restaurant",
    "japanese_cheap_sweets_shop",
    "japanese_confectionery_shop",
    "japanese_curry_restaurant",
    "japanese_delicatessen",
    "japanese_grocery_store",
    "japanese_high_quality_restaurant",
    "japanese_inns",
    "japanese_izakaya_restaurant",
    "japanese_language_instructor",
    "japanese_regional_restaurant",
    "japanese_restaurant",
    "japanese_steakhouse",
    "japanese_sweets_restaurant",
    "japanized_western_food_restaurant",
    "javanese_restaurant",
    "jazz_club",
    "jeep_dealer",
    "jehovahs_witness_church",
    "jeweler",
    "jewelry_appraiser",
    "jewelry_buyer",
    "jewelry_designer",
    "jewelry_engraver",
    "jewelry_equipment_supplier",
    "jewelry_exporter",
    "jewelry_manufacturer",
    "jewelry_repair_service",
    "jewelry_store",
    "jewish_restaurant",
    "jiang_su_restaurant",
    "joiner",
    "judicial_auction",
    "judicial_scrivener",
    "judo_club",
    "judo_school",
    "juice_shop",
    "jujitsu_school",
    "junior_college",
    "junk_dealer",
    "junk_store",
    "junkyard",
    "justice_department",
    "jute_exporter",
    "jute_mill",
    "juvenile_detention_center",
    "kabaddi_club",
    "kaiseki_restaurant",
    "kalle_pache_restaurant",
    "karaoke_bar",
    "karaoke_equipment_rental_service",
    "karate_club",
    "karate_school",
    "karma_dealer",
    "karnataka_restaurant",
    "kashmiri_restaurant",
    "kazakhstani_restaurant",
    "kebab_shop",
    "kennel",
    "kerala_restaurant",
    "kerosene_supplier",
    "key_duplication_service",
    "kia_dealer",
    "kickboxing_school",
    "kilt_shop_and_hire",
    "kimono_store",
    "kindergarten",
    "kinesiologist",
    "kinesiotherapist",
    "kiosk",
    "kitchen_furniture_store",
    "kitchen_remodeler",
    "kitchen_supply_store",
    "kite_shop",
    "knife_manufacturing",
    "knife_store",
    "knit_shop",
    "knitting_instructor",
    "knitwear_manufacturer",
    "kofta_restaurant",
    "konkani_restaurant",
    "korean_barbecue_restaurant",
    "korean_beef_restaurant",
    "korean_church",
    "korean_grocery_store",
    "korean_restaurant",
    "korean_rib_restaurant",
    "koshari_restaurant",
    "kosher_grocery_store",
    "kosher_restaurant",
    "kung_fu_school",
    "kushiyaki_restaurant",
    "kyoto_cuisine_restaurant",
    "labor_relations_attorney",
    "labor_union",
    "laboratory",
    "laboratory_equipment_supplier",
    "labour_club",
    "ladder_supplier",
    "lamborghini_dealer",
    "laminating_equipment_supplier",
    "lamination_service",
    "lamp_repair_service",
    "lamp_shade_supplier",
    "lancia_dealer",
    "land_allotment",
    "land_planning_authority",
    "land_reform_institute",
    "land_rover_dealer",
    "land_surveying_office",
    "land_surveyor",
    "landscape_architect",
    "landscape_designer",
    "landscape_lighting_designer",
    "landscaper",
    "landscaping_supply_store",
    "language_school",
    "laotian_restaurant",
    "lapidary",
    "laser_cutting_service",
    "laser_equipment_supplier",
    "laser_hair_removal_service",
    "laser_tag_center",
    "lasik_surgeon",
    "latin_american_restaurant",
    "laundromat",
    "laundry",
    "laundry_service",
    "law_book_store",
    "law_firm",
    "law_library",
    "law_school",
    "lawn_bowls_club",
    "lawn_care_service",
    "lawn_equipment_rental_service",
    "lawn_irrigation_equipment_supplier",
    "lawn_mower_repair_service",
    "lawn_mower_store",
    "lawn_sprinkler_system_contractor",
    "lawyer",
    "lawyers_association",
    "leagues_club",
    "learner_driver_training_area",
    "learning_center",
    "leasing_service",
    "leather_cleaning_service",
    "leather_coats_store",
    "leather_exporter",
    "leather_goods_manufacturer",
    "leather_goods_store",
    "leather_goods_supplier",
    "leather_goods_wholesaler",
    "leather_repair_service",
    "leather_wholesaler",
    "lebanese_restaurant",
    "lechon_restaurant",
    "legal_affairs_bureau",
    "legal_aid_office",
    "legal_services",
    "legally_defined_lodging",
    "leisurecentre",
    "lexus_dealer",
    "library",
    "license_bureau",
    "license_plate_frames_supplier",
    "lido",
    "life_coach",
    "life_insurance_agency",
    "light_bulb_supplier",
    "lighting_consultant",
    "lighting_contractor",
    "lighting_manufacturer",
    "lighting_store",
    "lighting_wholesaler",
    "ligurian_restaurant",
    "limousine_service",
    "lincoln_mercury_dealer",
    "line_marking_service",
    "linens_store",
    "lingerie_manufacturer",
    "lingerie_store",
    "lingerie_wholesaler",
    "linoleum_store",
    "liquidator",
    "liquor_store",
    "liquor_wholesaler",
    "literacy_program",
    "lithuanian_restaurant",
    "little_league_club",
    "little_league_field",
    "live_music_bar",
    "live_music_venue",
    "livery_company",
    "livestock_auction_house",
    "livestock_breeder",
    "livestock_dealer",
    "livestock_producer",
    "loan_agency",
    "local_government_office",
    "local_historic_archive",
    "local_history_museum",
    "local_medical_services",
    "lock_store",
    "locks_supplier",
    "locksmith",
    "lodge",
    "log_cabins",
    "log_home_builder",
    "logging_contractor",
    "logical_border",
    "logistics_service",
    "lombardian_restaurant",
    "loss_adjuster",
    "lost_property_office",
    "lottery_retailer",
    "lottery_shop",
    "lounge",
    "love_hotel",
    "low_emission_zone",
    "low_income_housing_program",
    "lpg_conversion",
    "luggage_repair_service",
    "luggage_storage_facility",
    "luggage_store",
    "luggage_wholesaler",
    "lumber_store",
    "lunch_restaurant",
    "lutheran_church",
    "lyceum",
    "lymph_drainage",
    "machine_construction",
    "machine_knife_supplier",
    "machine_maintenance",
    "machine_repair_service",
    "machine_shop",
    "machine_workshop",
    "machinery_parts_manufacturer",
    "machining_manufacturer",
    "macrobiotic_restaurant",
    "madrilian_restaurant",
    "magazine_store",
    "magic_store",
    "magician",
    "mah_jong_house",
    "mailbox_rental_service",
    "mailbox_supplier",
    "mailing_machine_supplier",
    "mailing_service",
    "main_customs_office",
    "majorcan_restaurant",
    "makerspace",
    "makeup_artist",
    "malaysian_restaurant",
    "maltese_restaurant",
    "mammography_service",
    "manado_restaurant",
    "management_school",
    "mandarin_restaurant",
    "manor_house",
    "manufactured_home_transporter",
    "manufacturer",
    "maori_organization",
    "map_store",
    "mapping_service",
    "marae",
    "marathi_restaurant",
    "marble_contractor",
    "marble_supplier",
    "marche_restaurant",
    "marina",
    "marine_engineer",
    "marine_self_defense_force",
    "marine_supply_store",
    "marine_surveyor",
    "maritime_museum",
    "market",
    "market_operator",
    "market_researcher",
    "marketing_agency",
    "marketing_consultant",
    "markmens_clubhouse",
    "marquee_hire",
    "marriage_celebrant",
    "marriage_counselor",
    "marriage_license_bureau",
    "martial_arts_club",
    "martial_arts_school",
    "martial_arts_supply_store",
    "maserati_dealer",
    "masonic_building",
    "masonry_contractor",
    "masonry_supply_store",
    "massage_school",
    "massage_spa",
    "massage_supply_store",
    "massage_therapist",
    "match_box_manufacturer",
    "material_handling_equipment_supplier",
    "maternity_hospital",
    "maternity_store",
    "mathematics_school",
    "mattress_store",
    "mausoleum_builder",
    "maybach_dealer",
    "mazda_dealer",
    "mclaren_dealer",
    "meal_delivery",
    "meal_takeaway",
    "measuring_instruments_supplier",
    "meat_packer",
    "meat_processor",
    "meat_products",
    "meat_restaurant",
    "meat_wholesaler",
    "mechanic",
    "mechanical_contractor",
    "mechanical_engineer",
    "mechanical_plant",
    "media_and_information_sciences_faculty",
    "media_company",
    "media_consultant",
    "media_house",
    "mediation_service",
    "medical_billing_service",
    "medical_book_store",
    "medical_center",
    "medical_certificate_service",
    "medical_clinic",
    "medical_diagnostic_imaging_center",
    "medical_equipment_manufacturer",
    "medical_equipment_supplier",
    "medical_examiner",
    "medical_group",
    "medical_lab",
    "medical_lawyer",
    "medical_office",
    "medical_school",
    "medical_spa",
    "medical_supply_store",
    "medical_technology_manufacturer",
    "medical_transcription_service",
    "medicine_exporter",
    "meditation_center",
    "meditation_instructor",
    "mediterranean_restaurant",
    "meeting_planning_service",
    "mehandi_class",
    "mehndi_designer",
    "memorial",
    "memorial_estate",
    "memorial_park",
    "mennonite_church",
    "mens_clothing_store",
    "mens_health_physician",
    "mens_tailor",
    "mental_health_clinic",
    "mental_health_service",
    "mercantile_development",
    "mercedes_benz_dealer",
    "messianic_synagogue",
    "metal_construction_company",
    "metal_detecting_equipment_supplier",
    "metal_fabricator",
    "metal_finisher",
    "metal_heat_treating_service",
    "metal_industry_suppliers",
    "metal_machinery_supplier",
    "metal_polishing_service",
    "metal_processing_company",
    "metal_stamping_service",
    "metal_supplier",
    "metal_working_shop",
    "metal_workshop",
    "metallurgy_company",
    "metalware_dealer",
    "metalware_producer",
    "metaphysical_supply_store",
    "methodist_church",
    "metropolitan_train_company",
    "mexican_goods_store",
    "mexican_grocery_store",
    "mexican_restaurant",
    "mexican_torta_restaurant",
    "meyhane",
    "mfr",
    "mg_dealer",
    "microbiologist",
    "microwave_oven_repair_service",
    "mid_atlantic_us_restaurant",
    "middle_eastern_restaurant",
    "middle_school",
    "midwife",
    "militar_archive",
    "militar_residence",
    "military_barrack",
    "military_base",
    "military_board",
    "military_cemetery",
    "military_hospital",
    "military_recruiting_office",
    "military_school",
    "military_town",
    "milk_delivery_service",
    "mill",
    "millwork_shop",
    "mine",
    "mineral_water_company",
    "mineral_water_wholesale",
    "mini_dealer",
    "miniature_golf_course",
    "miniatures_store",
    "minibus_taxi_service",
    "mining_company",
    "mining_consultant",
    "mining_engineer",
    "mining_equipment",
    "ministry_of_education",
    "mirror_shop",
    "miso_cutlet_restaurant",
    "missing_persons_organization",
    "mission",
    "mitsubishi_dealer",
    "mobile_catering",
    "mobile_disco",
    "mobile_hairdresser",
    "mobile_home_dealer",
    "mobile_home_park",
    "mobile_home_rental_agency",
    "mobile_home_supply_store",
    "mobile_money_agent",
    "mobile_network_operator",
    "mobile_phone_repair_shop",
    "mobility_equipment_supplier",
    "model_car_play_area",
    "model_design_company",
    "model_portfolio_studio",
    "model_train_store",
    "modeling_agency",
    "modeling_school",
    "modern_art_museum",
    "modern_british_restaurant",
    "modern_european_restaurant",
    "modern_french_restaurant",
    "modern_indian_restaurant",
    "modular_home_builder",
    "modular_home_dealer",
    "mohel",
    "mold_maker",
    "molding_supplier",
    "momo_restaurant",
    "monastery",
    "money_order_service",
    "money_transfer_service",
    "mongolian_barbecue_restaurant",
    "monja_restaurant",
    "monogramming_service",
    "montessori_school",
    "monument_maker",
    "moped_dealer",
    "moravian_church",
    "mordern_izakaya_restaurants",
    "moroccan_restaurant",
    "mortgage_broker",
    "mortgage_lender",
    "mortuary",
    "mosque",
    "motel",
    "motor_scooter_dealer",
    "motor_scooter_repair_shop",
    "motor_vehicle_dealer",
    "motorcycle_dealer",
    "motorcycle_driving_school",
    "motorcycle_insurance_agency",
    "motorcycle_parts_store",
    "motorcycle_rental_agency",
    "motorcycle_repair_shop",
    "motorcycle_shop",
    "motoring_club",
    "motorsports_store",
    "mountain_cable_car",
    "mountain_hut",
    "mountaineering_class",
    "movie_rental_kiosk",
    "movie_rental_store",
    "movie_studio",
    "movie_theater",
    "moving_and_storage_service",
    "moving_company",
    "moving_supply_store",
    "mri_center",
    "muay_thai_boxing_gym",
    "muffler_shop",
    "mughlai_restaurant",
    "mulch_supplier",
    "multimedia_and_electronic_book_publisher",
    "municipal_administration_office",
    "municipal_corporation",
    "municipal_department_agricultural_development",
    "municipal_department_agriculture_food_supply",
    "municipal_department_civil_defense",
    "municipal_department_communication",
    "municipal_department_finance",
    "municipal_department_housing_and_urban_development",
    "municipal_department_of_culture",
    "municipal_department_of_sports",
    "municipal_department_of_tourism",
    "municipal_department_science_technology",
    "municipal_department_social_defense",
    "municipal_guard",
    "municipal_health_department",
    "municipal_office_education",
    "municipal_social_development",
    "murtabak_restaurant",
    "musalla",
    "museum",
    "museum_of_space_history",
    "museum_of_zoology",
    "music_box_store",
    "music_college",
    "music_conservatory",
    "music_instructor",
    "music_management_and_promotion",
    "music_producer",
    "music_publisher",
    "music_school",
    "music_store",
    "musical_club",
    "musical_instrument_manufacturer",
    "musical_instrument_rental_service",
    "musical_instrument_repair_shop",
    "musical_instrument_store",
    "musician",
    "musician_and_composer",
    "mutton_barbecue_restaurant",
    "nail_salon",
    "nanotechnology_engineer",
    "nasi_goreng_restaurant",
    "nasi_restaurant",
    "nasi_uduk_restaurant",
    "national_forest",
    "national_health_foundation",
    "national_library",
    "national_museum",
    "national_park",
    "national_reserve",
    "native_american_goods_store",
    "native_american_restaurant",
    "natural_foods_store",
    "natural_history_museum",
    "natural_rock_climbing_area",
    "natural_stone_exporter",
    "natural_stone_supplier",
    "natural_stone_wholesaler",
    "nature_preserve",
    "naturopathic_practitioner",
    "naval_base",
    "navarraise_restaurant",
    "neapolitan_restaurant",
    "needlework_shop",
    "neon_sign_shop",
    "neonatal_physician",
    "nepalese_restaurant",
    "nephrologist",
    "netball_club",
    "neurologist",
    "neurophysiologist",
    "neuropsychologist",
    "neurosurgeon",
    "new_age_church",
    "new_england_restaurant",
    "new_us_american_restaurant",
    "new_years_tree_market",
    "new_zealand_restaurant",
    "news_service",
    "newspaper_advertising_department",
    "newspaper_distribution",
    "newspaper_publisher",
    "newsstand",
    "nicaraguan_restaurant",
    "night_club",
    "night_market",
    "nissan_dealer",
    "non_denominational_church",
    "non_governmental_organization",
    "non_profit_organization",
    "non_smoking_holiday_home",
    "non_vegetarian_restaurant",
    "noodle_shop",
    "north_african_restaurant",
    "north_eastern_indian_restaurant",
    "north_indian_restaurant",
    "northern_italian_restaurant",
    "norwegian_restaurant",
    "notaries_association",
    "notary_public",
    "notions_store",
    "novelties_wholesaler",
    "novelty_store",
    "nuclear_engineer",
    "nuclear_power_company",
    "nuclear_power_plant",
    "nudist_club",
    "nudist_park",
    "nuevo_latino_restaurant",
    "numerologist",
    "nunnery",
    "nurse_practitioner",
    "nursery_school",
    "nursing_agency",
    "nursing_association",
    "nursing_home",
    "nursing_school",
    "nut_store",
    "nutritionist",
    "nyonya_restaurant",
    "oaxacan_restaurant",
    "obanzai_cuisine",
    "observation_deck",
    "observatory",
    "obstetrics_gynecology_clinic",
    "occupational_health_service",
    "occupational_medical_physician",
    "occupational_safety_and_health",
    "occupational_therapist",
    "oden_restaurant",
    "odia_restaurant",
    "off_road_race_track",
    "off_roading_area",
    "off_track_betting_shop",
    "offal_pot_cooking",
    "office_accessories_wholesaler",
    "office_administration_service",
    "office_equipment_rental_service",
    "office_equipment_repair_service",
    "office_equipment_supplier",
    "office_furniture_store",
    "office_of_vital_records",
    "office_refurbishment_service",
    "office_space_rental_agency",
    "office_supply_store",
    "office_supply_wholesaler",
    "oil_and_gas_exploration_service",
    "oil_change_service",
    "oil_company",
    "oil_field_equipment_supplier",
    "oil_refinery",
    "oil_store",
    "oil_wholesaler",
    "oilfield",
    "okonomiyaki_restaurant",
    "oldsmobile_dealer",
    "olive_oil_bottling_company",
    "olive_oil_cooperative",
    "olive_oil_manufacturer",
    "oncologist",
    "onsen",
    "opel_dealer",
    "open_air_museum",
    "open_bread_store",
    "open_university",
    "opera_company",
    "opera_house",
    "ophthalmologist",
    "ophthalmology_clinic",
    "optical_instrument_repair_service",
    "optical_products_manufacturer",
    "optical_wholesaler",
    "optician",
    "optometrist",
    "oral_maxillofacial_surgeon",
    "oral_surgeon",
    "orchard",
    "orchestra",
    "orchid_farm",
    "orchid_grower",
    "organ_donation_and_tissue_bank",
    "organic_drug_store",
    "organic_farm",
    "organic_food_store",
    "organic_restaurant",
    "organic_store",
    "oriental_goods_store",
    "oriental_medicine_clinic",
    "oriental_medicine_store",
    "oriental_rug_store",
    "orphan_asylum",
    "orphanage",
    "orthodontist",
    "orthodox_church",
    "orthodox_synagogue",
    "orthopedic_clinic",
    "orthopedic_shoe_store",
    "orthopedic_supplies_store",
    "orthopedic_surgeon",
    "orthoptist",
    "orthotics_and_prosthetics_service",
    "osteopath",
    "otolaryngologist",
    "otolaryngology_clinic",
    "outboard_motor_store",
    "outdoor_activity_organizer",
    "outdoor_bath",
    "outdoor_clothing_and_equipment_shop",
    "outdoor_equestrian_facility",
    "outdoor_furniture_store",
    "outdoor_movie_theatre",
    "outdoor_sports_store",
    "outdoor_swimming_pool",
    "outerwear_store",
    "outlet_mall",
    "outlet_store",
    "oxygen_cocktail_spot",
    "oxygen_equipment_supplier",
    "oyster_bar_restaurant",
    "oyster_supplier",
    "paan_shop",
    "pachinko",
    "pacific_rim_restaurant",
    "package_locker",
    "packaging_company",
    "packaging_machinery",
    "packaging_supply_store",
    "padang_restaurant",
    "padel_club",
    "padel_court",
    "pagoda",
    "pain_control_clinic",
    "pain_management_physician",
    "paint_manufacturer",
    "paint_store",
    "paint_stripping_company",
    "paintball_center",
    "paintball_store",
    "painter",
    "painting",
    "painting_lessons",
    "painting_studio",
    "paintings_store",
    "paisa_restaurant",
    "pakistani_restaurant",
    "palatine_restaurant",
    "pallet_supplier",
    "pan_asian_restaurant",
    "pan_latin_restaurant",
    "pancake_house",
    "panipuri_shop",
    "paper_bag_supplier",
    "paper_distributor",
    "paper_exporter",
    "paper_mill",
    "paper_shredding_machine_supplier",
    "paper_store",
    "paraguayan_restaurant",
    "paralegal_services_provider",
    "parapharmacy",
    "parasailing_ride_service",
    "parish",
    "park",
    "park_and_ride",
    "parking_garage",
    "parking_lot",
    "parking_lot_for_bicycles",
    "parking_lot_for_motorcycle",
    "parkour_spot",
    "parochial_church_council",
    "parochial_school",
    "parsi_restaurant",
    "parsi_temple",
    "part_time_daycare",
    "party_equipment_rental_service",
    "party_planner",
    "party_store",
    "passport_agent",
    "passport_office",
    "passport_photo_processor",
    "pasta_shop",
    "pastry_shop",
    "patent_attorney",
    "patent_office",
    "paternity_testing_service",
    "pathologist",
    "patient_association",
    "patients_support_association",
    "patio_enclosure_supplier",
    "patisserie",
    "paving_contractor",
    "paving_materials_supplier",
    "pawn_shop",
    "pay_by_weight_restaurant",
    "payroll_service",
    "pecel_lele_restaurant",
    "pedestrian_zone",
    "pediatric_cardiologist",
    "pediatric_clinic",
    "pediatric_dentist",
    "pediatric_dermatologist",
    "pediatric_endocrinologist",
    "pediatric_gastroenterologist",
    "pediatric_hematologist",
    "pediatric_nephrologist",
    "pediatric_neurologist",
    "pediatric_oncologist",
    "pediatric_ophthalmologist",
    "pediatric_orthopedic_surgeon",
    "pediatric_pulmonologist",
    "pediatric_rheumatologist",
    "pediatric_surgeon",
    "pediatric_urologist",
    "pediatrician",
    "pedorthist",
    "pempek_restaurant",
    "pen_store",
    "pennsylvania_dutch_restaurant",
    "pension_company",
    "pension_office",
    "pensioners_club",
    "pentecostal_church",
    "performing_arts_group",
    "performing_arts_theater",
    "perfume_store",
    "perinatal_center",
    "periodontist",
    "permanent_make_up_clinic",
    "persian_restaurant",
    "personal_chef_service",
    "personal_injury_lawyer",
    "personal_trainer",
    "personal_watercraft_dealer",
    "peruvian_restaurant",
    "pest_control_service",
    "pet_adoption_service",
    "pet_boarding_service",
    "pet_cemetery",
    "pet_friendly_accommodation",
    "pet_funeral_services",
    "pet_groomer",
    "pet_moving_service",
    "pet_sitter",
    "pet_store",
    "pet_supply_store",
    "pet_trainer",
    "petrochemical_engineer",
    "petroleum_products_company",
    "peugeot_dealer",
    "pharmaceutical_company",
    "pharmaceutical_lab",
    "pharmaceutical_products_wholesaler",
    "pharmacy",
    "philharmonic_hall",
    "pho_restaurant",
    "phone_repair_service",
    "photo_agency",
    "photo_booth",
    "photo_lab",
    "photo_restoration_service",
    "photo_shop",
    "photocopiers_supplier",
    "photographer",
    "photography_class",
    "photography_school",
    "photography_service",
    "photography_studio",
    "physiatrist",
    "physical_examination_center",
    "physical_fitness_program",
    "physician_assistant",
    "physician_referral_service",
    "physiotherapist",
    "physiotherapy_center",
    "physiotherapy_equip_supplier",
    "piadina_restaurant",
    "piano_bar",
    "piano_instructor",
    "piano_maker",
    "piano_moving_service",
    "piano_repair_service",
    "piano_store",
    "piano_tuning_service",
    "pick_your_own_farm_produce",
    "pickleball_court",
    "picnic_ground",
    "picture_frame_shop",
    "pie_shop",
    "piedmontese_restaurant",
    "pig_farm",
    "pilaf_restaurant",
    "pilates_studio",
    "pile_driver",
    "pilgrim_hostel",
    "pilgrimages_place",
    "pinatas_supplier",
    "pinball_machine_supplier",
    "pine_furniture_shop",
    "pipe_supplier",
    "pizza_delivery_service",
    "pizza_restaurant",
    "pizzatakeaway",
    "place_of_worship",
    "planetarium",
    "plant_and_machinery_hire",
    "plant_nursery",
    "plast_window_store",
    "plaster_contractor",
    "plastic_bag_supplier",
    "plastic_bags_wholesaler",
    "plastic_fabrication_company",
    "plastic_injection_molding_service",
    "plastic_products_supplier",
    "plastic_resin_manufacturer",
    "plastic_surgeon",
    "plastic_surgery_clinic",
    "plastic_wholesaler",
    "plating_service",
    "play_school",
    "playground",
    "playground_equipment_supplier",
    "playgroup",
    "plumber",
    "plumbing_supply_store",
    "plus_size_clothing_store",
    "plywood_supplier",
    "pneumatic_tools_supplier",
    "po_boys_restaurant",
    "podiatrist",
    "poke_bar",
    "police_academy",
    "police_officers_housing",
    "police_supply_store",
    "polish_restaurant",
    "political_party",
    "polo_club",
    "polygraph_service",
    "polymer_supplier",
    "polynesian_restaurant",
    "polytechnic_school",
    "polythene_and_plastic_sheeting_supplier",
    "pond_contractor",
    "pond_fish_supplier",
    "pond_supply_store",
    "pontiac_dealer",
    "pony_club",
    "pony_ride_service",
    "pool_academy",
    "pool_billard_club",
    "pool_cleaning_service",
    "pool_hall",
    "popcorn_store",
    "pork_cutlet_rice_bowl_restaurant",
    "porridge_restaurant",
    "porsche_dealer",
    "port_authority",
    "port_operating_company",
    "portable_building_manufacturer",
    "portable_toilet_supplier",
    "portrait_studio",
    "portuguese_restaurant",
    "post_office",
    "poster_store",
    "pottery_classes",
    "pottery_manufacturer",
    "pottery_store",
    "poultry_farm",
    "poultry_store",
    "powder_coating_service",
    "power_plant",
    "power_plant_consultant",
    "power_plant_equipment_supplier",
    "powersports_vehicle_dealer",
    "pozole_restaurant",
    "prawn_fishing",
    "pre_gymnasium_school",
    "precision_engineer",
    "prefabricated_house_companies",
    "prefecture",
    "prefecture_government_office",
    "pregnancy_care_center",
    "preparatory_school",
    "presbyterian_church",
    "preschool",
    "press_advisory",
    "pressure_washing_service",
    "pretzel_store",
    "priest",
    "primary_school",
    "print_shop",
    "printed_music_publisher",
    "printer_ink_refill_store",
    "printer_repair_service",
    "printing_equipment_and_supplies",
    "printing_equipment_supplier",
    "prison",
    "private_college",
    "private_equity_firm",
    "private_golf_course",
    "private_guest_room",
    "private_hospital",
    "private_investigator",
    "private_school",
    "private_sector_bank",
    "private_tutor",
    "private_university",
    "probation_office",
    "process_server",
    "proctologist",
    "produce_market",
    "produce_wholesaler",
    "producteur_de_foie_gras",
    "professional_and_hobby_associations",
    "professional_organizer",
    "promenade",
    "promotional_products_supplier",
    "propane_supplier",
    "propeller_shop",
    "property_administrator",
    "property_investment",
    "property_maintenance",
    "property_management_company",
    "property_registry",
    "prosthetics",
    "prosthodontist",
    "protective_clothing_supplier",
    "protestant_church",
    "provence_restaurant",
    "provincial_council",
    "psychiatric_hospital",
    "psychiatrist",
    "psychic",
    "psychoanalyst",
    "psychologist",
    "psychomotor_therapist",
    "psychoneurological_specialized_clinic",
    "psychopedagogy_clinic",
    "psychosomatic_medical_practitioner",
    "psychotherapist",
    "pub",
    "public_amenity_house",
    "public_bath",
    "public_bathroom",
    "public_defenders_office",
    "public_female_bathroom",
    "public_golf_course",
    "public_health_department",
    "public_housing",
    "public_library",
    "public_male_bathroom",
    "public_medical_center",
    "public_parking_space",
    "public_prosecutors_office",
    "public_relations_firm",
    "public_safety_office",
    "public_sauna",
    "public_school",
    "public_sector_bank",
    "public_swimming_pool",
    "public_university",
    "public_webcam",
    "public_wheelchair_accessible_bathroom",
    "public_works_department",
    "publisher",
    "pueblan_restaurant",
    "puerto_rican_restaurant",
    "pulmonologist",
    "pump_supplier",
    "pumping_equipment_and_service",
    "pumpkin_patch",
    "punjabi_restaurant",
    "puppet_theater",
    "pvc_industry",
    "pvc_windows_supplier",
    "pyrotechnician",
    "qing_fang_market_place",
    "quaker_church",
    "quantity_surveyor",
    "quarry",
    "quebecois_restaurant",
    "quilt_shop",
    "race_car_dealer",
    "race_course",
    "racing_car_parts_store",
    "raclette_restaurant",
    "racquetball_club",
    "radiator_repair_service",
    "radiator_shop",
    "radio_broadcaster",
    "radiologist",
    "radiotherapist",
    "raft_trip_outfitter",
    "rafting",
    "rail_museum",
    "railing_contractor",
    "railroad_company",
    "railroad_contractor",
    "railroad_equipment_supplier",
    "railroad_ties_supplier",
    "railway_services",
    "rainwater_tank_supplier",
    "rajasthani_restaurant",
    "ram_dealer",
    "ramen_restaurant",
    "ranch",
    "rare_book_store",
    "raw_food_restaurant",
    "ready_mix_concrete_supplier",
    "real_estate_agency",
    "real_estate_agents",
    "real_estate_appraiser",
    "real_estate_attorney",
    "real_estate_auctioneer",
    "real_estate_consultant",
    "real_estate_developer",
    "real_estate_fair",
    "real_estate_rental_agency",
    "real_estate_school",
    "real_estate_surveyor",
    "realschule",
    "reclamation_centre",
    "record_company",
    "record_storage_facility",
    "record_store",
    "recording_studio",
    "recreation_center",
    "recruiter",
    "rectory",
    "recycling_center",
    "recycling_drop_off_location",
    "reenactment_site",
    "reflexologist",
    "reform_synagogue",
    "reformed_church",
    "refrigerated_transport_service",
    "refrigerator_repair_service",
    "refrigerator_store",
    "refugee_camp",
    "regional_airport",
    "regional_council",
    "regional_government_office",
    "registered_general_nurse",
    "registration_chamber",
    "registration_office",
    "registry_office",
    "rehabilitation_center",
    "rehearsa_studio",
    "reiki_therapist",
    "religious_book_store",
    "religious_destination",
    "religious_goods_store",
    "religious_institution",
    "religious_lodging",
    "religious_organization",
    "religious_school",
    "remodeler",
    "renault_dealer",
    "renters_insurance_agency",
    "repair_service",
    "reproductive_health_clinic",
    "reptile_store",
    "research_and_product_development",
    "research_engineer",
    "research_foundation",
    "research_institute",
    "resident_registration_office",
    "residential_college",
    "residents_association",
    "resort_hotel",
    "rest_stop",
    "restaurant",
    "restaurant_brasserie",
    "restaurant_supply_store",
    "resume_service",
    "retail_space_rental_agency",
    "retaining_wall_supplier",
    "retirement_community",
    "retirement_home",
    "retreat_center",
    "rheumatologist",
    "rice_cake_shop",
    "rice_cracker_shop",
    "rice_mill",
    "rice_restaurant",
    "rice_shop",
    "rice_wholesaler",
    "river_port",
    "road_camera",
    "road_construction_company",
    "road_construction_machine_repair_service",
    "road_cycling",
    "road_monitor",
    "road_monitor_zone",
    "road_safety_town",
    "roads_ports_and_canals_engineers_association",
    "rock_climbing",
    "rock_climbing_centre",
    "rock_climbing_instructor",
    "rock_music_club",
    "rock_shop",
    "rodeo",
    "rolled_metal_products_supplier",
    "roller_coaster",
    "roller_skating_club",
    "roller_skating_rink",
    "rolls_royce_dealer",
    "roman_restaurant",
    "romanian_restaurant",
    "roofing_contractor",
    "roofing_supply_store",
    "roommate_referral_service",
    "rowing_area",
    "rowing_club",
    "rsl_club",
    "rubber_products_supplier",
    "rubber_stamp_store",
    "rug_store",
    "rugby",
    "rugby_club",
    "rugby_field",
    "rugby_league_club",
    "rugby_store",
    "running_store",
    "russian_grocery_store",
    "russian_orthodox_church",
    "russian_restaurant",
    "rustic_furniture_store",
    "rv_dealer",
    "rv_park",
    "rv_rental_agency",
    "rv_repair_shop",
    "rv_storage_facility",
    "rv_supply_store",
    "saab_dealer",
    "sacem",
    "saddlery",
    "safe_and_vault_shop",
    "safety_equipment_supplier",
    "sailing_club",
    "sailing_event_area",
    "sailing_school",
    "sailmaker",
    "sake_brewery",
    "salad_shop",
    "salsa_bar",
    "salsa_classes",
    "salvadoran_restaurant",
    "salvage_dealer",
    "salvage_yard",
    "samba_school",
    "sambo_school",
    "sambodrome",
    "sand_and_gravel_supplier",
    "sand_plant",
    "sandblasting_service",
    "sandwich_shop",
    "sanitary_inspection",
    "sanitation_service",
    "sardinian_restaurant",
    "saree_shop",
    "satay_restaurant",
    "satellite_communication_service",
    "saturn_dealer",
    "sauna",
    "sauna_club",
    "sauna_store",
    "savings_bank",
    "saw_mill",
    "saw_sharpening_service",
    "scaffolder",
    "scaffolding_rental_service",
    "scale_model_club",
    "scale_model_shop",
    "scale_repair_service",
    "scale_supplier",
    "scandinavian_restaurant",
    "scenic_spot",
    "scenography_company",
    "school",
    "school_administrator",
    "school_bus_service",
    "school_center",
    "school_dentist",
    "school_district_office",
    "school_for_the_deaf",
    "school_house",
    "school_lunch_center",
    "school_supply_store",
    "science_museum",
    "scientific_equipment_supplier",
    "scooter_rental_service",
    "scooter_repair_shop",
    "scottish_restaurant",
    "scout_hall",
    "scout_home",
    "scouting",
    "scrap_metal_dealer",
    "scrapbooking_store",
    "screen_printer",
    "screen_printing_shop",
    "screen_printing_supply_store",
    "screen_repair_service",
    "screen_store",
    "screw_supplier",
    "scuba_instructor",
    "scuba_tour_agency",
    "sculptor",
    "sculpture",
    "sculpture_museum",
    "seafood_donburi",
    "seafood_farm",
    "seafood_market",
    "seafood_restaurant",
    "seafood_wholesaler",
    "seal_shop",
    "seaplane_base",
    "seasonal_goods_store",
    "seat_dealer",
    "seblak_restaurant",
    "second_hand_shop",
    "secondary_school",
    "secondary_school_three",
    "security_guard_service",
    "security_service",
    "security_system_installer",
    "security_system_supplier",
    "seed_supplier",
    "seitai",
    "self_catering_accommodation",
    "self_defense_school",
    "self_service_car_wash",
    "self_service_health_station",
    "self_service_restaurant",
    "self_storage_facility",
    "semi_conductor_supplier",
    "seminary",
    "senior_citizen_center",
    "senior_citizens_care_service",
    "senior_high_school",
    "septic_system_service",
    "serbian_restaurant",
    "serviced_accommodation",
    "serviced_apartment",
    "seventh_day_adventist_church",
    "sewage_disposal_service",
    "sewage_treatment_plant",
    "sewing_company",
    "sewing_machine_repair_service",
    "sewing_machine_store",
    "sewing_shop",
    "sexologist",
    "seychelles_restaurant",
    "sfiha_restaurant",
    "shabu_shabu_and_sukiyaki_restaurant",
    "shabu_shabu_restaurant",
    "shan_dong_restaurant",
    "shanghainese_restaurant",
    "shared_use_commercial_kitchen",
    "sharpening_service",
    "shawarma_restaurant",
    "shed_builder",
    "sheep_shearer",
    "sheepskin_and_wool_products_supplier",
    "sheepskin_coat_store",
    "sheet_metal_contractor",
    "sheet_music_store",
    "shelter",
    "sheltered_housing",
    "shelving_store",
    "sheriffs_department",
    "shinkin_bank",
    "shinto_shrine",
    "ship_building",
    "shipbuilding_and_repair_company",
    "shipping_and_mailing_service",
    "shipping_company",
    "shipping_equipment_industry",
    "shipping_service",
    "shipyard",
    "shochu_brewery",
    "shoe_factory",
    "shoe_repair_shop",
    "shoe_shining_service",
    "shoe_store",
    "shoe_wholesaler",
    "shogi_lesson",
    "shooting_event_area",
    "shooting_range",
    "shop_supermarket_furniture_store",
    "shopfitter",
    "shopping_center",
    "short_term_apartment_rental_agency",
    "shower_door_shop",
    "showroom",
    "shredding_service",
    "shrimp_farm",
    "shrine",
    "sichuan_restaurant",
    "sicilian_restaurant",
    "siding_contractor",
    "sightseeing_tour_agency",
    "sign_shop",
    "sikh_temple",
    "silk_plant_shop",
    "silk_store",
    "silversmith",
    "singaporean_restaurant",
    "singing_telegram_service",
    "single_sex_secondary_school",
    "singles_organization",
    "sixth_form_college",
    "skate_sharpening_service",
    "skate_shop",
    "skateboard_park",
    "skateboard_shop",
    "skating_instructor",
    "skeet_shooting_range",
    "skewer_deep_frying",
    "ski_club",
    "ski_rental_service",
    "ski_repair_service",
    "ski_resort",
    "ski_school",
    "ski_store",
    "skin_care_clinic",
    "skin_care_products_vending_machine",
    "skittle_club",
    "skoda_dealer",
    "skydiving_center",
    "skylight_contractor",
    "slaughterhouse",
    "sleep_clinic",
    "small_appliance_repair_service",
    "small_claims_assistance_service",
    "small_engine_repair_service",
    "small_plates_restaurant",
    "smart_car_dealer",
    "smart_dealer",
    "smart_shop",
    "smog_inspection_station",
    "smoke_shop",
    "snow_removal_service",
    "snowboard_rental_service",
    "snowboard_shop",
    "snowmobile_dealer",
    "snowmobile_rental_service",
    "soapland",
    "soba_noodle_shop",
    "soccer_club",
    "soccer_field",
    "soccer_practice",
    "soccer_store",
    "social_and_health_school",
    "social_club",
    "social_security_attorney",
    "social_security_financial_department",
    "social_security_office",
    "social_services_organization",
    "social_welfare_center",
    "social_worker",
    "societe_de_flocage",
    "sod_supplier",
    "sofa_store",
    "soft_drinks_shop",
    "soft_shelled_turtle_dish_restaurant",
    "softball_club",
    "softball_field",
    "software_company",
    "software_training_institute",
    "soil_testing_service",
    "sokol_house",
    "solar_energy_company",
    "solar_energy_contractor",
    "solar_energy_equipment_supplier",
    "solar_hot_water_system_supplier",
    "solar_photovoltaic_power_plant",
    "solid_fuel_company",
    "solid_waste_engineer",
    "soondae_restaurant",
    "soto_ayam_restaurant",
    "soto_restaurant",
    "soul_food_restaurant",
    "soup_kitchen",
    "soup_restaurant",
    "soup_shop",
    "south_african_restaurant",
    "south_american_restaurant",
    "south_asia_restaurant",
    "south_east_asian_restaurant",
    "south_indian_restaurant",
    "south_sulawesi_restaurant",
    "southern_italian_restaurant",
    "southern_us_restaurant",
    "southwest_french_restaurant",
    "southwestern_us_restaurant",
    "souvenir_manufacturer",
    "souvenir_store",
    "soy_sauce_maker",
    "spa",
    "spa_and_health_club",
    "spa_garden",
    "spa_town",
    "space_of_remembrance",
    "spanish_restaurant",
    "special_education_school",
    "specialist",
    "specialist_psychiatry",
    "specialized_clinic",
    "specialized_hospital",
    "speech_pathologist",
    "speed_camera_zone",
    "speed_control_zone",
    "sperm_bank",
    "spice_store",
    "spices_exporter",
    "spices_wholesalers",
    "spiritist_center",
    "sport_tour_agency",
    "sporting_goods_store",
    "sports_accessories_wholesaler",
    "sports_bar",
    "sports_card_store",
    "sports_club",
    "sports_complex",
    "sports_equipment_rental_service",
    "sports_massage_therapist",
    "sports_medicine_clinic",
    "sports_medicine_physician",
    "sports_memorabilia_store",
    "sports_nutrition_store",
    "sports_school",
    "sportswear_store",
    "sportwear_manufacturer",
    "spring_supplier",
    "squash_club",
    "squash_court",
    "sri_lankan_restaurant",
    "stable",
    "stadium",
    "stage",
    "stage_lighting_equipment_supplier",
    "stained_glass_studio",
    "stainless_steel_plant",
    "stair_contractor",
    "stall_installation_service",
    "stamp_collectors_club",
    "stamp_shop",
    "stand_bar",
    "staple_food_package",
    "state_archive",
    "state_department_agricultural_development",
    "state_department_agriculture_food_supply",
    "state_department_civil_defense",
    "state_department_communication",
    "state_department_finance",
    "state_department_for_social_development",
    "state_department_housing_and_urban_development",
    "state_department_of_environment",
    "state_department_of_tourism",
    "state_department_of_transportation",
    "state_department_science_technology",
    "state_department_social_defense",
    "state_dept_of_culture",
    "state_dept_of_sports",
    "state_employment_department",
    "state_government_office",
    "state_liquor_store",
    "state_office_of_education",
    "state_owned_farm",
    "state_park",
    "state_parliament",
    "state_police",
    "state_social_development",
    "stationery_manufacturer",
    "stationery_store",
    "stationery_wholesaler",
    "statuary",
    "std_clinic",
    "std_testing_service",
    "steak_house",
    "steamboat_restaurant",
    "steamed_bun_shop",
    "steel_construction_company",
    "steel_distributor",
    "steel_drum_supplier",
    "steel_erector",
    "steel_fabricator",
    "steel_framework_contractor",
    "steelwork_design_company",
    "steelwork_manufacturer",
    "stereo_rental_store",
    "stereo_repair_service",
    "stereo_store",
    "sticker_manufacturer",
    "stitching_class",
    "stock_broker",
    "stock_exchange_building",
    "stone_carving",
    "stone_cutter",
    "stone_supplier",
    "storage_facility",
    "store",
    "store_equipment_supplier",
    "stove_builder",
    "stringed_intrument_maker",
    "structural_engineer",
    "stucco_contractor",
    "student_career_counseling_office",
    "student_dormitory",
    "student_housing_center",
    "student_union",
    "students_parents_association",
    "students_support_association",
    "study_at_home_school",
    "studying_center",
    "stylist",
    "subaru_dealer",
    "suburban_train_line",
    "sugar_factory",
    "sugar_shack",
    "sukiyaki_restaurant",
    "summer_camp",
    "summer_toboggan_run",
    "sundae_restaurant",
    "sundanese_restaurant",
    "sunglasses_store",
    "sunroom_contractor",
    "super_public_bath",
    "superannuation_consultant",
    "superfund_site",
    "supermarket",
    "support_group",
    "surf_lifesaving_club",
    "surf_school",
    "surf_shop",
    "surgeon",
    "surgical_center",
    "surgical_oncologist",
    "surgical_products_wholesaler",
    "surgical_supply_store",
    "surinamese_restaurant",
    "surplus_store",
    "surveyor",
    "sushi_restaurant",
    "sushi_takeaway",
    "suzuki_dealer",
    "swabian_restaurant",
    "swedish_restaurant",
    "swim_club",
    "swimming_basin",
    "swimming_competition",
    "swimming_facility",
    "swimming_instructor",
    "swimming_lake",
    "swimming_pool",
    "swimming_pool_contractor",
    "swimming_pool_repair_service",
    "swimming_pool_supply_store",
    "swimming_school",
    "swimwear_store",
    "swiss_restaurant",
    "synagogue",
    "syrian_restaurant",
    "t_shirt_store",
    "tabascan_restaurant",
    "table_tennis_club",
    "table_tennis_facility",
    "table_tennis_supply_store",
    "tacaca_restaurant",
    "tack_shop",
    "taco_restaurant",
    "tae_kwon_do_comp_area",
    "taekwondo_school",
    "tag_agency",
    "tai_chi_school",
    "tailor",
    "taiwanese_restaurant",
    "takoyaki_stand",
    "talent_agency",
    "tamale_shop",
    "tannery",
    "tanning_studio",
    "taoist_temple",
    "tapas_bar",
    "tapas_restaurant",
    "tata_motors_dealer",
    "tatami_store",
    "tattoo_and_piercing_shop",
    "tattoo_artist",
    "tattoo_removal_service",
    "tattoo_shop",
    "tax_assessor",
    "tax_attorney",
    "tax_collectors_office",
    "tax_consultant",
    "tax_department",
    "tax_preparation",
    "tax_preparation_service",
    "taxi_service",
    "taxi_stand",
    "taxidermist",
    "tb_clinic",
    "tea_exporter",
    "tea_house",
    "tea_manufacturer",
    "tea_market_place",
    "tea_store",
    "tea_wholesaler",
    "teacher_college",
    "teachers_housing",
    "technical_school",
    "technical_service",
    "technical_university",
    "technology_museum",
    "technology_park",
    "teeth_whitening_service",
    "tegal_restaurant",
    "telecommunication_school",
    "telecommunications_contractor",
    "telecommunications_engineer",
    "telecommunications_equipment_supplier",
    "telecommunications_service_provider",
    "telemarketing_service",
    "telephone_answering_service",
    "telephone_company",
    "telephone_exchange",
    "telescope_store",
    "television_repair_service",
    "television_station",
    "temaki_restaurant",
    "temp_agency",
    "tempura_bowl_restaurants",
    "tempura_dish_restaurant",
    "tenant_ownership",
    "tenants_union",
    "tennis_club",
    "tennis_court",
    "tennis_court_construction_company",
    "tennis_instructor",
    "tennis_store",
    "tent_rental_service",
    "teppan_grill_restaurant",
    "terminal_point",
    "tesla_showroom",
    "tex_mex_restaurant",
    "textile_engineer",
    "textile_exporter",
    "textile_mill",
    "thai_massage_therapist",
    "thai_restaurant",
    "theater_company",
    "theater_production",
    "theater_supply_store",
    "theatrical_costume_supplier",
    "theme_park",
    "thermal_baths",
    "thermal_energy_company",
    "thread_supplier",
    "threads_and_yarns_wholesaler",
    "three_d_printing_service",
    "thrift_store",
    "thuringian_restaurant",
    "tibetan_restaurant",
    "tiffin_center",
    "tiki_bar",
    "tile_contractor",
    "tile_manufacturer",
    "tile_store",
    "timber_trade",
    "timeshare_agency",
    "tire_manufacturer",
    "tire_repair_shop",
    "tire_shop",
    "title_company",
    "toast_restaurant",
    "tobacco_exporter",
    "tobacco_shop",
    "tobacco_supplier",
    "tofu_restaurant",
    "tofu_shop",
    "toiletries_store",
    "toll_road_rest_stop",
    "toll_station",
    "toner_cartridge_supplier",
    "tongue_restaurant",
    "tonkatsu_restaurant",
    "tool_and_die_shop",
    "tool_grinding_service",
    "tool_manufacturer",
    "tool_rental_service",
    "tool_repair_shop",
    "tool_store",
    "tool_wholesaler",
    "toolroom",
    "topography_company",
    "topsoil_supplier",
    "tortilla_shop",
    "tour_agency",
    "tour_operator",
    "tourist_attraction",
    "tourist_information_center",
    "tower_communication_service",
    "towing_equipment_provider",
    "towing_service",
    "townhouse_complex",
    "toy_and_game_manufacturer",
    "toy_library",
    "toy_manufacturer",
    "toy_museum",
    "toy_store",
    "toyota_dealer",
    "tractor_dealer",
    "tractor_repair_shop",
    "trade_education_center",
    "trade_fair_construction_company",
    "trade_school",
    "trading_card_store",
    "traditional_costume_club",
    "traditional_kostume_store",
    "traditional_market",
    "traditional_restaurant",
    "traditional_teahouse",
    "traditional_us_american_restaurant",
    "traffic_officer",
    "trailer_dealer",
    "trailer_manufacturer",
    "trailer_rental_service",
    "trailer_repair_shop",
    "trailer_supply_store",
    "train_depot",
    "train_repairing_center",
    "train_ticket_agency",
    "train_ticket_counter",
    "train_yard",
    "training_center",
    "training_school",
    "transcription_service",
    "transit_depot",
    "translator",
    "transmission_shop",
    "transplant_surgeon",
    "transportation_escort_service",
    "transportation_service",
    "travel_agency",
    "travel_clinic",
    "travel_lounge",
    "travellers_lodge",
    "tree_farm",
    "tree_service",
    "trial_attorney",
    "tribal_headquarters",
    "trophy_shop",
    "tropical_fish_store",
    "truck_accessories_store",
    "truck_dealer",
    "truck_farmer",
    "truck_parts_supplier",
    "truck_rental_agency",
    "truck_repair_shop",
    "truck_stop",
    "truck_topper_supplier",
    "trucking_company",
    "trucking_school",
    "truss_manufacturer",
    "trust_bank",
    "tsukigime_parking_lot",
    "tune_up_supplier",
    "tuning_automobile",
    "tunisian_restaurant",
    "turf_supplier",
    "turkish_restaurant",
    "turkmen_restaurant",
    "turnery",
    "tuscan_restaurant",
    "tutoring_service",
    "tuxedo_shop",
    "typewriter_repair_service",
    "typewriter_supplier",
    "typing_service",
    "udon_noodle_shop",
    "ukrainian_restaurant",
    "unagi_restaurant",
    "underwear_store",
    "unemployment_office",
    "unfinished_furniture_store",
    "uniform_store",
    "unisex_hairdresser",
    "unitarian_universalist_church",
    "united_church_of_canada",
    "united_church_of_christ",
    "united_methodist_church",
    "united_states_armed_forces_base",
    "unity_church",
    "university",
    "university_department",
    "university_hospital",
    "university_library",
    "upholstery_cleaning_service",
    "upholstery_shop",
    "urban_planning_department",
    "urgent_care_center",
    "urologist",
    "urology_clinic",
    "uruguayan_restaurant",
    "us_indian_reservation",
    "us_pacific_northwest_restaurant",
    "used_appliance_store",
    "used_auto_parts_store",
    "used_bicycle_shop",
    "used_book_store",
    "used_car_dealer",
    "used_cd_store",
    "used_clothing_store",
    "used_computer_store",
    "used_furniture_store",
    "used_game_store",
    "used_motorcycle_dealer",
    "used_musical_instrument_store",
    "used_office_furniture_store",
    "used_store_fixture_supplier",
    "used_tire_shop",
    "used_truck_dealer",
    "utility_contractor",
    "utility_trailer_dealer",
    "uyghur_cuisine_restaurant",
    "uzbek_restaurant",
    "vacation_appartment",
    "vacation_home_rental_agency",
    "vacuum_cleaner_repair_shop",
    "vacuum_cleaner_store",
    "vacuum_cleaning_system_supplier",
    "valencian_restaurant",
    "valet_parking_service",
    "van_rental_agency",
    "vaporizer_store",
    "variety_store",
    "vascular_surgeon",
    "vastu_consultant",
    "vcr_repair_service",
    "vegan_restaurant",
    "vegetable_wholesale_market",
    "vegetable_wholesaler",
    "vegetarian_cafe_and_deli",
    "vegetarian_restaurant",
    "vehicle_examination_office",
    "vehicle_exporter",
    "vehicle_inspection",
    "vehicle_shipping_agent",
    "vehicle_wrapping_service",
    "velodrome",
    "vending_machine_supplier",
    "venereologist",
    "venetian_restaurant",
    "venezuelan_restaurant",
    "ventilating_equipment_manufacturer",
    "venture_capital_company",
    "veterans_affairs_department",
    "veterans_center",
    "veterans_hospital",
    "veterans_organization",
    "veterinarian",
    "veterinary_pharmacy",
    "video_arcade",
    "video_camera_repair_service",
    "video_conferencing_equipment_supplier",
    "video_conferencing_service",
    "video_duplication_service",
    "video_editing_service",
    "video_equipment_repair_service",
    "video_game_rental_kiosk",
    "video_game_rental_service",
    "video_game_rental_store",
    "video_game_store",
    "video_karaoke",
    "video_production_service",
    "video_store",
    "vietnamese_restaurant",
    "villa",
    "village_hall",
    "vineyard",
    "vineyard_church",
    "vintage_clothing_store",
    "vinyl_sign_shop",
    "violin_shop",
    "virtual_office_rental",
    "visa_and_passport_office",
    "visa_consultant",
    "visitor_center",
    "vitamin_and_supplements_store",
    "vocal_instructor",
    "vocational_college",
    "vocational_gymnasium_school",
    "vocational_school_one",
    "vocational_secondary_school",
    "vocational_training_school",
    "volkswagen_dealer",
    "volleyball_club",
    "volleyball_court",
    "volleyball_instructor",
    "volunteer_organization",
    "volvo_dealer",
    "voter_registration_office",
    "vuggestue",
    "waldorf_kindergarten",
    "waldorf_school",
    "walk_in_clinic",
    "wallpaper_installer",
    "wallpaper_store",
    "war_museum",
    "warehouse",
    "warehouse_club",
    "warehouse_store",
    "washer_and_dryer_repair_service",
    "washer_and_dryer_store",
    "waste_management_service",
    "watch_manufacturer",
    "watch_repair_service",
    "watch_store",
    "water_cooler_supplier",
    "water_damage_restoration_service",
    "water_filter_supplier",
    "water_jet_cutting_service",
    "water_mill",
    "water_park",
    "water_polo_pool",
    "water_pump_supplier",
    "water_purification_company",
    "water_ski_shop",
    "water_skiing_club",
    "water_skiing_instructor",
    "water_skiing_service",
    "water_softening_equipment_supplier",
    "water_sports_equipment_rental_service",
    "water_tank_cleaning_service",
    "water_testing_service",
    "water_treatment_plant",
    "water_treatment_supplier",
    "water_utility_company",
    "water_works",
    "water_works_equipment_supplier",
    "waterbed_repair_service",
    "waterbed_store",
    "waterproofing_company",
    "wax_museum",
    "wax_supplier",
    "waxing_hair_removal_service",
    "weather_forecast_service",
    "weaving_mill",
    "web_hosting_service",
    "website_designer",
    "wedding_bakery",
    "wedding_buffet",
    "wedding_chapel",
    "wedding_dress_rental_service",
    "wedding_photographer",
    "wedding_planner",
    "wedding_service",
    "wedding_souvenir_shop",
    "wedding_store",
    "wedding_venue",
    "weigh_station",
    "weight_loss_service",
    "weightlifting_area",
    "weir",
    "welder",
    "welding_gas_supplier",
    "welding_supply_store",
    "well_drilling_contractor",
    "wellness_center",
    "wellness_hotel",
    "wellness_program",
    "welsh_restaurant",
    "wesleyan_church",
    "west_african_restaurant",
    "western_apparel_store",
    "western_restaurant",
    "whale_watching_tour_agency",
    "wheel_alignment_service",
    "wheel_store",
    "wheelchair_rental_service",
    "wheelchair_repair_service",
    "wheelchair_store",
    "wholesale_bakery",
    "wholesale_drugstore",
    "wholesale_florist",
    "wholesale_food_store",
    "wholesale_grocer",
    "wholesale_jeweler",
    "wholesale_market",
    "wholesale_plant_nursery",
    "wholesaler",
    "wholesaler_household_appliances",
    "wi_fi_spot",
    "wicker_store",
    "wig_shop",
    "wildlife_and_safari_park",
    "wildlife_park",
    "wildlife_refuge",
    "wildlife_rescue_service",
    "willow_basket_manufacturer",
    "wind_farm",
    "wind_turbine_builder",
    "window_cleaning_service",
    "window_installation_service",
    "window_supplier",
    "window_tinting_service",
    "window_treatment_store",
    "windsurfing_store",
    "wine_bar",
    "wine_cellar",
    "wine_club",
    "wine_storage_facility",
    "wine_store",
    "wine_wholesaler",
    "winemaking_supply_store",
    "winery",
    "wing_chun_school",
    "wok_restaurant",
    "womens_clothing_store",
    "womens_college",
    "womens_organization",
    "womens_personal_trainer",
    "womens_protection_service",
    "womens_shelter",
    "wood_and_laminate_flooring_supplier",
    "wood_floor_installation_service",
    "wood_floor_refinishing_service",
    "wood_frame_supplier",
    "wood_stove_shop",
    "wood_supplier",
    "wood_working_class",
    "woodworker",
    "woodworking_supply_store",
    "wool_store",
    "work_clothes_store",
    "workers_club",
    "working_womens_hostel",
    "wrestling_school",
    "x_ray_equipment_supplier",
    "x_ray_lab",
    "yacht_broker",
    "yacht_club",
    "yakatabune",
    "yakiniku_restaurant",
    "yakisoba_restaurant",
    "yakitori_restaurant",
    "yarn_store",
    "yemenite_restaurant",
    "yeshiva",
    "yoga_instructor",
    "yoga_retreat_center",
    "yoga_studio",
    "youth_care",
    "youth_center",
    "youth_clothing_store",
    "youth_club",
    "youth_groups",
    "youth_hostel",
    "youth_organization",
    "youth_social_services_organization",
    "yucatan_restaurant",
    "zhe_jiang_restaurant",
    "zoo"
];
