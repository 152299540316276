import * as R from 'ramda';
import * as mp from "../../../../mappers/path";
import { getGlobalstyleClassNameForRadium } from "../../../RenderGlobalstyles/getGlobalstyleClassName";
import makeSpec from '../../../../mappers/makeSpec/index';
import { baseCssConfig, fontCssConfig, highlightConfig } from '../../../../mappers/block/index';
import type { TableCellStylesheet } from '../../../Workspace/epics/stylesheets/flowTypes';
import { SpecConfig } from '../../../../mappers/makeSpec/flowTypes';

const MIN_FONT_SIZE = 9,
    MAX_FONT_SIZE = 20,

    MIN_PADDING = 0,
    MAX_PADDING = 8,

    TextGlobalstyles = ['.textnormal', '.textheading1', '.textheading2', '.textheading3'];

const
    cellCssConfig: SpecConfig = {
        ...baseCssConfig(mp.block),
        verticalAlign: [mp.verticalAlign, R.identity],
        backgroundClip: [mp.verticalAlign, () => 'padding-box'],
        textAlign: [mp.horizontalAlign, R.identity]
    },
    makeCellBlockSpec = makeSpec(cellCssConfig),
    makeCellTextSpec = makeSpec(fontCssConfig),
    makeCellHighlightTextSpec = makeSpec(highlightConfig),
    makeTextSpecForDropdownOption = makeSpec({
        ...fontCssConfig,
        borderColor: [mp.blockBorderColor, () => 'transparent'],
        borderStyle: [mp.blockBorderStyle, () => 'none'],
        borderWidth: [mp.blockBorderWidth, () => '0px'],
        fontSize: [mp.textSize, fs => `${R.clamp(MIN_FONT_SIZE, MAX_FONT_SIZE)(fs)}px`],
        padding: [mp.blockPadding, padding => padding.map(p => `${R.clamp(MIN_PADDING, MAX_PADDING)(p)}px`).join(' ')]
    }),
    cellSpec = (tablePrefix, tableTextPrefix) => ({
        [tablePrefix]: makeCellBlockSpec([]),
        [`${tablePrefix} .textnormal`]: makeCellTextSpec([]),
        [tableTextPrefix]: makeCellHighlightTextSpec([]),
        [`${tablePrefix}${tablePrefix}-ddo`]: makeTextSpecForDropdownOption([])
    }),
    mapper = (stylesheet: TableCellStylesheet): Record<string, any> => {
        const tablePrefix = getGlobalstyleClassNameForRadium(stylesheet),
            tableTextPrefix = TextGlobalstyles.map(textGS => `${tablePrefix} ${textGS}`).join(', ');

        return R.applySpec(cellSpec(tablePrefix, tableTextPrefix))(stylesheet);
    };

export { makeCellBlockSpec, mapper as default };
