import React from 'react';
import cx from 'classnames';
import styles from './DropZone.css';
import Msg from "../../../intl/Msg";

type Props = { dropZonePosition: number, isDroppable: boolean, hasHorizontalScroll: boolean }

const SectionDropZoneHeight = 106,
    SectionDropZone = ({ dropZonePosition, isDroppable, hasHorizontalScroll }: Props) => {
        const style = { height: SectionDropZoneHeight, top: dropZonePosition };
        return <div style={style} className={cx(styles.sectionsPlaceholder, { [styles.showVerticalScroll]: !hasHorizontalScroll })}>
            <div className={styles.container}>
                <span className={styles.message}>
                    {
                        isDroppable ?
                            <Msg k="common.section.dropZone.onDrag.message">Drop section to add it here</Msg> :
                            <Msg k="common.section.dropZone.message">Drag section here</Msg>
                    }
                </span>
            </div>
        </div>;
    };

export {
    SectionDropZone,
    SectionDropZoneHeight
};
