/* eslint-disable max-len */
import React from 'react';
import cx from "classnames";
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import * as Actions from '../../actionTypes';
import * as pagesIds from "./ids";
import pagesTitles from "./titles";
import type { Props } from '../flowTypes';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import injectIntl from '../../../../../view/intl/injectIntl';
import * as styles from '../styles.css';
import * as configDialogStyles from '../../view/ConfigurationDialog/ConfigurationDialog.css';
import OwnerEmails from '../../../../OwnerEmails/View/index';
import { ValidatedInput } from "../../../../../view/common/Input/ValidatedInputField";
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';
import InputField from '../../../../../view/common/Input/InputField';
import { isValidEmail } from "../../../../../utils/validation";

const
    id = pagesIds.EMAILSETTINGS,
    title = pagesTitles[id],
    view = injectIntl(({ intl, dispatch, selectedComponent,
            allowArbitraryEmail,
            ownerEmailIds,
            partnerIsSiteTemplates }: Props) => {
        const [displayEmail, setDisplayEmail] = React.useState(selectedComponent.recipientEmail || '');
        const subjectPlaceHolder = "msg: component.contactForm.writeASubjectOptional {Write a subject (optional)}",
            onChangeSubject = (value) => {
                dispatch({
                    type: Actions.CONTACT_FORM_SET_SUBJECT_PP,
                    payload: { subject: value }
                });
            },
            onChangeEmail = (email) => {
                if (allowArbitraryEmail) {
                    const isEmailValid = isValidEmail(email);
                    if (isEmailValid) {
                        dispatch({
                            type: Actions.CONTACT_FORM_SET_EMAIL_PP,
                            payload: email
                        });
                    }
                    setDisplayEmail(email);
                }
            },
            renderEmailInput = () => {
                if (partnerIsSiteTemplates) {
                    // disable email selection for site templates
                    return (
                        <InputField
                            className={styles.inputBox}
                            value={selectedComponent.recipientEmail}
                            disabled
                        />
                    );
                }
                if (allowArbitraryEmail) {
                    return (
                        <ValidatedInput
                            className={styles.inputBox}
                            placeholder={`${intl.msgJoint("msg: common.email {Email}")}`}
                            value={displayEmail}
                            invalidInputClass={configDialogStyles.errorMessage}
                            validationMsg="msg: common.emailInvalid {Check that the email is valid.}"
                            isInvalid={!isValidEmail(displayEmail)}
                            onChange={onChangeEmail}
                            intl={intl}
                            usePropsValue
                        />
                    );
                } else {
                    return (
                        <OwnerEmails
                            //@ts-ignore
                            ownerEmailIds={ownerEmailIds}
                            onChangeAction={Actions.CONTACT_FORM_SET_EMAIL_PP}
                            selectedEmail={selectedComponent.recipientEmail}
                            dispatch={dispatch}
                        />
                    );
                }
            };

        return (
            <Page>
                <VerticalSpacer />
                <PropertyContainer
                    label="msg: component.contactForm.config.subTitle {The form responses will be sent to this email address. You can change it in the contact form settings at any time.}"
                    className={styles.pointer}
                >
                    <PropertyContainer
                        label="msg: common.email {Email}"
                        className={cx(styles.emailContainer, styles.emailLabel)}
                    >
                        {renderEmailInput()}
                    </PropertyContainer>
                    <VerticalSpacer />
                    <PropertyContainer
                        label="msg: component.contact.form.config.emailSubject {Email subject (optional)}"
                        className={cx(styles.subjectContainer, styles.subjectLabel)}
                    >
                        <InputField
                            className={styles.subjectInput}
                            value={selectedComponent.subject}
                            onChange={onChangeSubject}
                            placeholder={subjectPlaceHolder}
                            title={selectedComponent.subject || ''}
                            titleLanguageDependent={!selectedComponent.subject}
                        />
                    </PropertyContainer>
                </PropertyContainer>
            </Page>
        );
    });

export { id, title, view };
