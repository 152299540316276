import {
    makeCombineReducer,
    makeComponentBaseReducers,
    makeDefaultStateReducers,
} from "../../../redux/makeReducer/index";
import kind from './kind';
import { DRAG_AND_DROP_HEIGHT, DRAG_AND_DROP_WIDTH } from "./constants/index";

export default makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(kind, DRAG_AND_DROP_WIDTH, DRAG_AND_DROP_HEIGHT),
        ...makeDefaultStateReducers({})
    }
});

