import cx from "classnames";
import getCenteredDialog from "../../../DialogManager/getCenteredDialogConfig";
import { configurationDialogFactory } from '../configurationDialogFactory';
import { FacebookDialogHeight, DialogWidth } from '../constants';
import { WIDGETS_FACEBOOK_SET_LINK } from "./actionTypes";
import { validateLinkOrCode } from '../utils';
import { parseFacebookCode } from './utils/parseFacebookCode';
import Icons from '../view/Icons.css';

const validateFacebookData = validateLinkOrCode([], parseFacebookCode);

const
    configurationDialogId = "WIDGETS_FACEBOOK_CONFIG_DIALOG",
    configurationDialogView = configurationDialogFactory({
        saveAction: WIDGETS_FACEBOOK_SET_LINK,
        dialogIconClass: cx(Icons.facebook, Icons.dialog),
        dialogTitle: "msg: widgets.facebook.label {Facebook}",
        info: "msg: widgets.facebook.config.info {Add a Facebook post or video to your page.}",
        subInfo: "msg: widgets.facebook.configDialog.copyPaste {Go to Facebook and select \'…\' on the post or video you want to share. Select \'Embed code\' or \'Copy link\', and paste it below. }", // eslint-disable-line max-len
        inputPlaceHolder: "msg: widgets.facebook.configDialog.placeHolder {Paste Facebook embed code or link}",
        learnMore: "msg: widgets.facebook.config.learnMore {https://help.one.com/hc/en-us/articles/10146174078993}",
        isLinkValid: validateFacebookData,
        // eslint-disable-next-line max-len
        termsOfService: "msg: widgets.facebook.config.termsOfService {By using this widget, you agree to Facebook\'s {termsOfServiceLink}.}",
        termsOfServiceLink: "msg: widgets.facebook.config.termsOfServiceLink {https://developers.facebook.com/terms#privacypolicy}",
    }),
    configurationDialog = getCenteredDialog({
        width: DialogWidth,
        height: FacebookDialogHeight,
        component: configurationDialogView
    });

export { configurationDialogId, configurationDialog };
