import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { InputField } from "../Common/InputField";
import { validatePhoneField } from "../validations";
import { DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA } from "../../../Epic/actionTypes";

export const PhoneField = (props) => {
    const dispatch = useDispatch();
    const { phone, setPhone } = props;

    useEffect(() => {
        dispatch({
            type: DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA,
            payload: { path: "contactInfo.phone", value: phone.value }
        });
    }, [phone]);

    return (
        <InputField
            label="msg: common.phoneNumber.optional {Phone number (optional)}"
            error={phone.showError}
            errorMsg="msg: onboarding.dynamic.error.phoneNumber {Enter a phone number with at least 7 and maximum 15 digits.}"
            value={phone.value}
            onChange={(e) => {
                const v = e.target.value;
                setPhone({ value: v, showError: false });
            }}
            onFocus={() => {
                dispatch({
                    type: DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA,
                    payload: { path: "contactInfo.focusedField", value: 'phone' }
                });
            }}
            onBlur={() => {
                setPhone({
                    ...phone,
                    showError: !validatePhoneField(phone.value)
                });
                dispatch({
                    type: DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA,
                    payload: { path: "contactInfo.focusedField", value: null }
                });
            }}
            data-testid="dynamic-onboarding-phone-field"
        />
    );
};
