export const
    DND_ADD_SVG = 'DND_ADD_SVG',
    SVG_COLOR_SWATCH_ITEM_CHANGED = 'SVG_COLOR_SWATCH_ITEM_CHANGED',
    SVG_COLOR_SWATCH_ITEM_CHANGED_AUTO_COLOR = 'SVG_COLOR_SWATCH_ITEM_CHANGED_AUTO_COLOR',
    SVG_EMPTY_SRC = 'SVG_EMPTY_SRC',
    SVG_MOBILE_CLEAR_FORMATTING = 'SVG_MOBILE_CLEAR_FORMATTING',
    SVG_MOBILE_SIZE_CHANGE = 'SVG_MOBILE_SIZE_CHANGE',
    SVG_PP_ON_CLICK_ACTION_CHANGED = 'SVG_PP_ON_CLICK_ACTION_CHANGED',
    SVG_RAW_ASSET_REQUEST = 'SVG_RAW_ASSET_REQUEST',
    SVG_RAW_ASSET_SUCCESS_FOR_MISSING_ASSETS = 'SVG_RAW_ASSET_SUCCESS_FOR_MISSING_ASSETS',
    SVG_RAW_ASSET_SUCCESS_FOR_SET_SVG = 'SVG_RAW_ASSET_SUCCESS_FOR_SET_SVG',
    SVG_RAW_ASSET_SUCCESS_FOR_REPLACE_SVG = 'SVG_RAW_ASSET_SUCCESS_FOR_REPLACE_SVG',
    SVG_RAW_ASSET_PROCESSED_FOR_REPLACE_SVG = 'SVG_RAW_ASSET_PROCESSED_FOR_REPLACE_SVG',
    SVG_RAW_ASSET_FAILURE = 'SVG_RAW_ASSET_FAILURE',
    SVG_REPLACE_SVG = 'SVG_REPLACE_SVG',
    SVG_RESET_COLORS = 'SVG_RESET_COLORS',
    SVG_RESET_COLORS_AUTO_COLOR = 'SVG_RESET_COLORS_AUTO_COLOR',
    SVG_SELECTED_FOR_REPLACE = 'SVG_SELECTED_FOR_REPLACE',
    SVG_SET_LINK = 'SVG_SET_LINK',
    SVG_SET_OPACITY = 'SVG_SET_OPACITY',
    SVG_SET_SEO = 'SVG_SET_SEO',
    SVG_SET_SVG = 'SVG_SET_SVG',
    SVG_UPDATE_ON_REPLACE = 'SVG_UPDATE_ON_REPLACE',
    SVG_UPDATE_ON_REPLACE_MISSING = 'SVG_UPDATE_ON_REPLACE_MISSING';
