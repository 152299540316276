import valueActionType from "./valueActionType";
import makeEpic from "../../../../epics/makeEpic";
import mobileViewEditorVAT from "../reorder/valueActionType";
import splitData from "./utils";

export default makeEpic({
    defaultState: {
        data: {},
        mobileDownData: {}
    },
    valueActionType,
    updaters: [
        {
            conditions: [
                mobileViewEditorVAT
            ],
            reducer: ({ values: [{ data, wrappedCmpsMap, mdStartFromId, root }] }) => ({
                state: splitData({ data, wrappedCmpsMap, mdStartFromId, templateId: root })
            })
        }
    ]
});
