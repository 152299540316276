import type { InputValidationFunction, InputValidationResult } from "../flowTypes";

export type UrlPathInputValidation = InputValidationFunction<any>;

const
    REASON = 'INVALID_URL_PATH',
    MESSAGE = 'msg: validation.urlPath {Sorry, the {_name} cannot contain invalid characters.}',
    PATTERN = '^[^/\\\\?*:|"<>]*$';
const urlPathInputValidation: UrlPathInputValidation = (value: string): InputValidationResult => {
    const isValid = new RegExp(PATTERN).test(value);
    return isValid ? { isValid } : { isValid: false, error: { reason: REASON, msg: MESSAGE } };
};

export {
    urlPathInputValidation as default,
    REASON as URL_PATH_INPUT_VALIDATION_REASON
};
