import * as R from "ramda";
import { applyMappers, makePlainMappers } from "../../utils";
import * as colorMapper from '../Base/color';
import * as fontFamilyMapper from '../Base/fontFamily';
import type { WebshopComponent } from '../../../../src/components/oneweb/WebShop/flowTypes';
import {
    WEBSHOP_MIN_WIDTH, WEBSHOP_MIN_HEIGHT, CommonMapperKeys, WebshopThemeMapperKeys
} from '../../../../src/components/oneweb/WebShop/constants';
import * as selectors from '../../../../src/components/Workspace/epics/stylesheets/selectors';
import { reducerDefaultData } from '../../../../src/components/oneweb/WebShop/reducer/reducerDefaultData';
import styleText from '../../../../src/components/oneweb/Text/globalStyle/kind';
import type { ToDeps } from "../index";

const plainPropsMapper = makePlainMappers({
    category: "category",
    ...CommonMapperKeys,
    ...WebshopThemeMapperKeys
});

export function styleMapperTo(component: any): any {
    const updatedStyle = component.style ? {
        ...[
            'focusColor',
            'labelBgColor',
            'labelTextColor',
            'hoverColor',
            'fontColor'
        ].reduce((d, prop: string) => {
            let styleProp = component.style[prop];

            if (!styleProp) {
                return reducerDefaultData[prop] ? { ...d, [prop]: reducerDefaultData[prop] } : d;
            }

            // WBTGEN-8814, WBTGEN-10315
            if (['_red', '_green', '_blue', '_alpha'].every(prop => styleProp.hasOwnProperty(prop))) {
                const { _red, _green, _blue, _alpha } = styleProp;
                styleProp = ["RGB", _red, _green, _blue, _alpha];
            }

            const map = d;
            let hslConverted = colorMapper.toHsl(styleProp);
            // WBTGEN:5185  Some webshop templates gets opacity to set gray color for
            // fieldBorderColor when imported in old website builder.
            // Template team will fix this issue for future. But we need to
            // override opacity to 1 for customers already loaded any such
            // template.
            if (hslConverted && prop === 'borderColor' && hslConverted[4] < 1) {
                hslConverted.splice(4, 1, 1);
            }
            map[prop] = hslConverted;
            return map;
        }, {}),
        ...{
            font: fontFamilyMapper.to(component.style.font),
            buttonId: component.style.buttonId,
            buyNowButtonId: component.style.buyNowButtonId,
        }
    } : {};

    // WBTGEN-9005 - Drop the unused props
    return R.omit(['bgColor', 'fieldTextColor', 'borderColor'], updatedStyle);
}

function styleMapperBack(component: WebshopComponent): any {
    return {
        style: {
            ...[
                'focusColor',
                'labelBgColor',
                'labelTextColor',
                'hoverColor',
                'fontColor'
            ].reduce((d, prop) => {
                if (!component[prop]) {
                    return d;
                }

                const map = d;
                map[prop] = colorMapper.toRgb(component[prop]);
                return map;
            }, {}),
            ...{
                font: fontFamilyMapper.back(component.font),
                buttonId: component.buttonId,
                buyNowButtonId: component.buyNowButtonId,
                labelPosition: component.labelPosition
            }
        }
    };
}

export function to(componentData: any): WebshopComponent {
    return applyMappers(
        componentData,
        plainPropsMapper.to,
        styleMapperTo
    );
}

export function afterMappingTo(component: WebshopComponent, { stylesheets: { styles } }: ToDeps): WebshopComponent {
    // If width or height is lesser than minimum after calculation using
    // bbox then correcting width and height.
    const
        textGlobalStyle = selectors.getStylesByType(styleText)(styles),
        normalStyle = R.pipe(R.filter(style => style.ref === 'normal'), R.head)(textGlobalStyle);
    const y = {
        ...component,
        width: Math.max(component.width, WEBSHOP_MIN_WIDTH),
        height: Math.max(component.height, WEBSHOP_MIN_HEIGHT),
        font: !component.font ? normalStyle.font : component.font,
        fontColor: !component.fontColor ? normalStyle.color : component.fontColor
    };

    return y;
}

export function back(component: WebshopComponent): any {
    return applyMappers(
        component,
        plainPropsMapper.back,
        styleMapperBack
    );
}
