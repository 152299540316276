import { makeApplyEditorChangesReducer } from "./helpers/helpers";
import * as editorCommands from "../editorUtils/editorCommands";
import { TINY_MCE_REMOVE_FORMAT } from "../actionTypes";
import { getSelectedNodes } from "../editorUtils/utils/nodeUtils/getSelectedNodes";
import { isValidContentBlock } from "../editorUtils/utils/nodeUtils/utils";
import { isEmptyNode } from '../../../../../utils/dom';

const onRemoveFormatUpdater = {
    conditions: [TINY_MCE_REMOVE_FORMAT],
    reducer: makeApplyEditorChangesReducer(({ state, scope, editor }) => {
        const selectedNodes = getSelectedNodes(editor);
        const bookmark = editor.selection.getBookmark();
        selectedNodes.forEach(node => {
            if (isValidContentBlock(node)) {
                if (isEmptyNode(node) && node.lastChild && node.lastChild.nodeName === 'BR') {
                    node.removeChild(node.lastChild);
                }
            }
        });

        editor.selection.moveToBookmark(bookmark);
        editor.execCommand(editorCommands.REMOVE_FORMAT, false, null);

        return {
            state,
            scope
        };
    })
};

export {
    onRemoveFormatUpdater
};
