import React from "react";
import cx from "classnames";
import styles from "./styles.css";
import VerticalSpacer from "../../../../view/common/VerticalSpacer";

type SectionBlockErrorProps = {
    containerClass?: string;
    iconClass?: string;
    children: any;
}

class SectionBlockError extends React.Component<SectionBlockErrorProps> {
    render() {
        const { containerClass, iconClass, children } = this.props;
        return (
            <div className={cx(styles.errorMessage, containerClass)}>
                <div className={styles.errorMessageContent}>
                    <div className={cx(styles.errorMessageImg, iconClass)} />
                    <VerticalSpacer y={20} />
                    {children}
                </div>
            </div>
        );
    }
}

export default SectionBlockError;
