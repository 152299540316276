export const
    WINDOW_NAVIGATE_TO_LOCATION = 'WINDOW_NAVIGATE_TO_LOCATION',
    WINDOW_REPLACE_LOCATION = 'WINDOW_REPLACE_LOCATION',
    WINDOW_NAVIGATE_TO_BLANK_LOCATION_ACTION = 'WINDOW_NAVIGATE_TO_LOCATION_BLANK',
    WINDOW_NAVIGATE_TO_POPUP_LOCATION_ACTION = 'WINDOW_NAVIGATE_TO_POPUP_LOCATION_ACTION',
    WINDOW_NAVIGATE_TO_SELF = 'WINDOW_NAVIGATE_TO_SELF',
    WINDOW_OPEN = 'WINDOW_OPEN',
    WINDOW_DELETE_DOM_BY_ID = 'WINDOW_DELETE_DOM_BY_ID',
    WINDOW_DELETE_GLOBAL_OBJECTS = 'WINDOW_DELETE_GLOBAL_OBJECTS',
    WINDOW_REMOVE_SCRIPT_TAGS = 'WINDOW_REMOVE_SCRIPT_TAGS',
    WINDOW_MEASURE_DOM_ELEMENT = 'WINDOW_MEASURE_DOM_ELEMENT',
    WINDOW_BATCH_MEASURE_DOM_ELEMENTS = 'WINDOW_BATCH_MEASURE_DOM_ELEMENTS',
    DOM_ELEMENT_MEASURE = 'DOM_ELEMENT_MEASURE',
    // TODO WBTGEN-7743 Remove AFTER_DOM_ELEMENT_MEASURE once text wrapping implemented in syncronus way
    AFTER_DOM_ELEMENT_MEASURE = 'AFTER_DOM_ELEMENT_MEASURE',
    BATCH_DOM_ELEMENT_MEASURE = 'BATCH_DOM_ELEMENT_MEASURE',
    WINDOW_SCROLL_INTO_VIEW = 'WINDOW_SCROLL_INTO_VIEW',
    WINDOW_LISTEN_FOR_UPGRADE_COMPLETE = 'WINDOW_LISTEN_FOR_UPGRADE_COMPLETE',
    WINDOW_LOAD_CHAT = 'WINDOW_LOAD_CHAT',
    WINDOW_SHOW_WIDGET = 'WINDOW_SHOW_WIDGET',
    WINDOW_CHAT_ADD_TAG = 'WINDOW_CHAT_ADD_TAG',
    WINDOW_CHAT_REMOVE_TAG = 'WINDOW_CHAT_REMOVE_TAG',
    WINDOW_FORCE_REPAINT_REFLOW = 'WINDOW_FORCE_REPAINT_REFLOW',
    GOOGLE_TAG_MANAGER_EVENT = 'GOOGLE_TAG_MANAGER_EVENT',

    LOCATION_RELOAD = 'LOCATION_RELOAD',

    SET_WINDOW_TITLE = 'SET_WINDOW_TITLE';
