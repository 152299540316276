import React from "react";
import { DemoLoginMessages } from '../../main/messages';
import styles from "./EmailAlreadyVerifiedPage.css";
import Button from "../../../../../src/view/common/Button/Button";
import VerticalSpacer from "../../../../../src/view/common/VerticalSpacer";
import type { EmailAreadyVerifiedPagePropTypes } from "../../types";
import { showSignInPage } from "../../signUp/actions";

const EmailAlreadyVerifiedPage = ({
    intl,
    dispatch,
}: EmailAreadyVerifiedPagePropTypes) => {
    const {
        doneLabel,
        resetPasswordSuccessHeaderMsg,
        trialUserEmailAlreadyVerifiedMessage,
        returnToLoginLabel,
    } = DemoLoginMessages;

    const emailAlreadyVerifiedPage = () => {
        return (
            [
                <div>
                    <div className={styles.headerLabel}>
                        {intl.msgJoint(doneLabel)}
                    </div>
                    <div className={styles.headerMsg}>
                        <div>
                            {intl.msgJoint(resetPasswordSuccessHeaderMsg)}
                        </div>
                    </div>
                </div>,
                <VerticalSpacer y={49} />,
                <div>
                    <div>
                        <div className={styles.doneLabelContainer}>
                            <span className={styles.checkBoxIcon} />
                            <span className={styles.checkInboxLabel}>
                                {intl.msgJoint(trialUserEmailAlreadyVerifiedMessage)}
                            </span>
                        </div>
                    </div>
                </div>,
                <div>
                    <div className={styles.actionsContainer}>
                        <div className={styles.returnToLoginContainer}>
                            <VerticalSpacer y={284} />
                            <Button
                                onClick={() => dispatch(showSignInPage())}
                                className={styles.returnToLoginButton}
                            >
                                {intl.msgJoint(returnToLoginLabel)}
                            </Button>
                        </div>
                    </div>
                </div>]
        );
    };

    return (
        <div>
            {emailAlreadyVerifiedPage()}
        </div>
    );
};

export {
    EmailAlreadyVerifiedPage
};
