import { diff } from 'objectdiff';
import { isObject } from '../../utils/object';

export type PartialObjectsAreDiffOptions = Record<'include' | 'exclude', string[]>;

export const
    objectsAreDiff = (o1: Record<string, any>, o2: Record<string, any>): boolean => {
        if (!isObject(o1) || !isObject(o2)) {
            throw new Error(`Cannot compare non-objects`);
        }

        return diff(o1, o2).changed === 'object change';
    },
    objectsAreEqual = (o1: Record<string, any>, o2: Record<string, any>): boolean =>
        !objectsAreDiff(o1, o2),
    partialObjectsAreDiff = (ino1: Record<string, any>, ino2: Record<string, any>, options: PartialObjectsAreDiffOptions) => {
        const
            reduce = (o: Record<string, any>) =>
                Object.keys(o).reduce((acc, k) => (
                    (
                        (options.include && options.include.indexOf(k) !== -1)
                        || (options.exclude && options.exclude.indexOf(k) === -1)
                    )
                        ? { ...acc, [k]: o[k] }
                        : acc
                ), {}),
            o1 = reduce(ino1),
            o2 = reduce(ino2);

        if (!Object.keys(o1).length || !Object.keys(o2).length) {
            throw new Error(`Some objects are empty for options: ${JSON.stringify(options)}`);
        }

        return objectsAreDiff(o1, o2);
    },
    partialObjectsAreEqual = (o1: Record<string, any>, o2: Record<string, any>, options: PartialObjectsAreDiffOptions) =>
        !partialObjectsAreDiff(o1, o2, options),
    isDeepEqual = (o1: Record<string, any>, o2: Record<string, any>, objPreProcessor ?: Function) => {
        const isObject = (object) => {
            return object != null && typeof object === "object";
        };
        const isObjsEqual = (obj1, obj2) => {
            let object1 = obj1, object2 = obj2;
            if (objPreProcessor) {
                object1 = objPreProcessor(obj1);
                object2 = objPreProcessor(obj2);
            }
            const objKeys1 = Object.keys(object1);
            if (objKeys1.length !== Object.keys(object2).length) return false;

            for (let i = 0; i < objKeys1.length; i++) {
                const key = objKeys1[i],
                    value1 = object1[key],
                    value2 = object2[key],
                    isObjects = isObject(value1) && isObject(value2);
                if ((isObjects && !isObjsEqual(value1, value2)) || (!isObjects && value1 !== value2)) return false;
            }
            return true;
        };
        return isObjsEqual(o1, o2);
    },
    objectCollectionsAreDiff = (c1: Record<string, any>[], c2: Record<string, any>[], objPreProcessor ?: Function): boolean => {
        if (c1.length !== c2.length) return true;

        const c1Map = c1.reduce((acc, itm) => ({ [itm.id]: itm, ...acc }), {});
        for (let i = 0; i < c2.length; i++) {
            const
                c2o = c2[i],
                c1o = c1Map[c2o.id];

            // added item
            if (!c1o) return true;

            // updated item
            if (!isDeepEqual(c1o, c2o, objPreProcessor)) return true;
        }

        return false;
    };
