import type { AppStore } from '../../redux/modules/flowTypes';
import { AUTH_COOKIE_EXISTS } from '../App/epics/auth/actionTypes';
import {
    GET_PREFERENCES_FAILURE_ACTION,
    SAVE_PREFERENCES_ACTION,
    GET_PREFERENCES_SUCCESS_ACTION,
    getPreferencesAction,
    putPreferencesAction,
    setDefaultPreferencesAction,
    mergePreferencesAction,
} from './actions';
import { DalErrorName } from '../../../dal/constants';
import { getDAL } from '../../../dal/index';
import { preferencesAppSel } from './selectors';
import { sanitizeSavePreferences } from './functions/sanitizeSavePreferences';
import { allowWsbMocks } from '../../debug/allowWsbMocks';
import { getWindowLocationMock } from '../../debug/getWindowLocationMock';
import isTestEnv from '../../debug/isTestEnv';

export const preferencesMiddleware = (store: AppStore) => {
    const mergeMockedPreferences = (res: Object) => {
        if (allowWsbMocks()) {
            const mockPreferences = getWindowLocationMock('preferences');
            if (mockPreferences) {
                if (mockPreferences.whatsNewLastSeenDate === null) {
                    mockPreferences.whatsNewLastSeenTimestamp = null;
                    delete mockPreferences.whatsNewLastSeenDate;
                } else if (mockPreferences.whatsNewLastSeenDate) {
                    mockPreferences.whatsNewLastSeenTimestamp = new Date(
                        mockPreferences.whatsNewLastSeenDate
                    ).getTime();
                    delete mockPreferences.whatsNewLastSeenDate;
                }
                return store.dispatch(mergePreferencesAction(mockPreferences));
            }
        }
        return res;
    };

    return (next: Dispatch) => (action: AnyAction) => {
        if (action.type === AUTH_COOKIE_EXISTS) {
            store.dispatch(getPreferencesAction());
        }

        if (action.type === GET_PREFERENCES_SUCCESS_ACTION) {
            return mergeMockedPreferences(next(action));
        }

        if (action.type === GET_PREFERENCES_FAILURE_ACTION) {
            const { payload } = action;

            if (payload && payload.error === DalErrorName.NOT_FOUND) {
                return mergeMockedPreferences(
                    store.dispatch(setDefaultPreferencesAction(getDAL().getDomain())),
                );
            }
        }

        if (action.type === SAVE_PREFERENCES_ACTION) {
            const preferences = preferencesAppSel(store.getState());

            if (!isTestEnv() && !preferences) {
                throw new Error('Preferences are empty.');
            }

            const savePreferences = {
                ...sanitizeSavePreferences({ ...preferences, ...action.payload }),
                // TODO: in test preferences are null for some reason ...
                _rev: preferences && preferences._rev,
            };

            return store.dispatch(putPreferencesAction(savePreferences));
        }

        return next(action);
    };
};
