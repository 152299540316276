import epicsRegistry from './epicsRegistry';
import executeAction from './executeAction';
import { warn } from '../../utils/log';
import type { EpicsState, Epic } from './flowTypes';
import { mergeEpics } from "./mergeEpics";
import { registerException } from "../debug";

const defaultState: EpicsState = epicsRegistry
    .filter(epic => epic.defaultState !== undefined)
    .reduce((epicsState, epic) => {
        const
            action = [{
                type: epic.valueActionType,
                payload: epic.defaultState
            }],
            epicsUpdate = {},
            actionsToDispatch = [],
            errors: Array<any> = [];

        // @ts-ignore
        executeAction(action, epicsState, epicsUpdate, actionsToDispatch, errors, () => null, true, true);

        if (actionsToDispatch.length) {
            warn('epics should not dispatch on initializing default state', actionsToDispatch);
        }

        if (errors.length) {
            errors.forEach(registerException);
            // This can happen only in dev time
            throw new Error(`[${errors.map(({ valueActionType }) => valueActionType).join(', ')}] epics failed to process default state initialization. Application failed to start.`); // eslint-disable-line max-len
        }

        return mergeEpics(epicsState, epicsUpdate);
    }, epicsRegistry.reduce((state, epic: Epic<any, any, any>) => {
        return {
            ...state,
            [epic.valueActionType]: {
                updatersState: epic.updaters.map((updater) => ({
                    receivedActions: updater.conditions.reduce((acc, cond) => ({
                        ...acc,
                        [cond.toString()]: null
                    }), {}),
                    executionParams: [],
                    isFullfilled: false
                })),
                state: epic.defaultState,
                scope: epic.defaultScope
            }
        };
    }, {}));

export default defaultState;
