import * as path from '../path';
import configMappers from '../configMappers';
import fontMapperConfig from '../block/font';
import * as colorMapper from '../color';
import * as shadowMapper from '../shadow';
import { SpecConfig } from '../makeSpec/flowTypes';

const
    font = configMappers(fontMapperConfig);

export const cssConfig: SpecConfig = {
    color: [path.color, colorMapper.toCss],
    textDecoration: [path.underline, font.underline.toCss],
    textShadow: [path.shadow, shadowMapper.toCss],
    backgroundColor: [path.highlight, colorMapper.toCss]
};
