import type { FileManagerState } from "../flowTypes";
import pathUtil from '../../../../../../utils/path';
import getNavigateDirectoryState from "../utils/getNavigateDirectoryState";

export default (state: FileManagerState, action: any) => {
    const
        { payload: { subDirectory } } = action,
        currentPath = pathUtil.build([state.currentPath, subDirectory]);

    return getNavigateDirectoryState(state, currentPath);
};
