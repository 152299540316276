import makePages from '../../../PropertiesPanel/view/makePages';
import * as main from './pages/main';
import * as links from './pages/links';
import * as style from './pages/style';

const propertiesPanelConfig = {
    pages: makePages(
        main,
        style,
        links,
    ),
    defaultPageId: main.id
};

export default propertiesPanelConfig;
