import { EventTrackingIds, EventTrackingProps } from "../../EventTracking/constants";
import { PUT_NEW_TEMPLATE_SUCCESS, TEMPLATE_SELECTOR_SHOW_PREVIEW,
    TRACK_TEMPLATE_PREVIEW_EVENT } from "../../TemplateSelector_DEPRECATED/actionTypes";
import { selectedTemplateDesignSelector } from "../../TemplateSelector_DEPRECATED/epics/importTemplate/selectors";
import { DYNAMIC_PREVIEW_EDIT_OPTION_CHANGED, DYNAMIC_TEMPLATE_GET_SECTION_CONTENT, DYNAMIC_TEMPLATE_START_ONBOARDING,
    GET_GENERATED_AI_CONTENT_SUCCESS,
    TRACK_EVENT_NEXT_GEN_API_FAILURE, TRACK_WIZARD_STEP_CHANGED } from "./Epic/actionTypes";
import type { AppState } from '../../../redux/modules/flowTypes';
import { TRIAL_PRE_SELECTED_TEMPLATES, WIZARD_STEP_ID, TRIAL_ORIGIN } from "./constants";
import { dynamicOnboardingStateSelector } from "./Epic/selectors";
import { templateSelectorStateSelector } from "../../TemplateSelector_DEPRECATED/epics/templatesList/selector";
import { selectedColorPickerTypeSelector } from "../../TemplateSelector_DEPRECATED/epics/preview/selector";
import { subscriptionTypeStateSel } from "../../App/epics/subscriptionData/selectors";
import { isTrialSubscription } from "../../App/epics/subscriptionData/utils";
import { trialUserEmailSel, isControlPanelTrialUserSel } from '../../../../demo/modules/selectors';
import localStorage from "../../../utils/localStorage";
import { shouldShowEditPreview } from "../domainHashUtils";

const TrackingProps = {
    templateValue: 'Template value',
    dynamicTemplate: 'Dynamic template',
    categoryMatched: 'Category matched',
    categoryValue: 'Category value',
    errorType: 'Error type',
    websiteTitle: 'Website title',
    keywordsFilled: 'Keywords filled in',
    keywordsValue: 'Keywords value',
    email: 'Email filled in',
    phone: 'Phone filled in',
    address: 'Address filled in',
    pronounWe: 'Pronoun we',
    websiteLanguage: 'Website language',
    attributes: 'Attribute value',
    searchUsed: 'Search used',
    categoriesUsed: 'Categories used',
    timeOnPage: 'Time on the page',
    colorPaletteType: 'Color palette type',
    source: 'Source',
    onboardingTemplateSelected: 'Onboarding template selected',
    onboardingTemplateValue: 'Onboarding template value',
    selectedTemplateConfirmed: 'Selected template confirmed',
    quickEditSwitchVisible: 'Quick edit switch visible',
    sectionTitle: 'Section title',
};

const API_FAILURE = 'WSB onboarding - API failure',
    CATEGORY_STEP = 'WSB onboarding - Category step',
    ATTRIBUTES_STEP = 'WSB onboarding - Attributes step',
    BUSINESS_STEP = 'WSB onboarding - Business name step',
    KEYWORDS_STEP = 'WSB onboarding - Keywords step',
    CONTACT_STEP = 'WSB onboarding - Contacts step',
    LANGUAGE_STEP = 'WSB onboarding - Language step',
    PRONOUNS_STEP = 'WSB onboarding - Pronouns step',
    PREVIEW_TO_TEMPLATE = 'WSB onboarding - Preview step switched to templates',
    CATEGORY_TO_TEMPLATE = 'WSB onboarding - Category step switched to templates',
    QUICK_EDITS_TURNED_ON = 'WSB onboarding - Quick edits turned on',
    PREVIEW_GENERATED = 'WSB onboarding - Preview generated',
    SECTION_TEXT_GENERATED = 'WSB section text regenerated';

const NextGenTrackingEvents = {
    API_FAILURE,
    CATEGORY_STEP,
    ATTRIBUTES_STEP,
    BUSINESS_STEP,
    KEYWORDS_STEP,
    CONTACT_STEP,
    LANGUAGE_STEP,
    PRONOUNS_STEP,
    PREVIEW_TO_TEMPLATE,
    CATEGORY_TO_TEMPLATE,
    QUICK_EDITS_TURNED_ON,
};

const APIErrorMap = {
    DYNAMIC_TEMPLATES_CONNECTION_ERROR: 'Connection error',
    DYNAMIC_TEMPLATES_HIGH_TRAFFIC_ERROR: 'High traffic error',
    DYNAMIC_TEMPLATES_LIMIT_ERROR: 'Individual limit error',
    DYNAMIC_TEMPLATES_INAPPROPRIATE_LANGUAGE_ERROR: 'Moderation error',
};

const TrialSource = {
    controlPanel: 'Control panel',
    freeTrial: 'Free trial'
};

const GPT = 'GPT';

const getTrialSource = (appState) => {
    const isCpUser = isControlPanelTrialUserSel(appState);
    const trialOrigin = localStorage.get(TRIAL_ORIGIN);
    if (isCpUser) {
        return TrialSource.controlPanel;
    }
    if (trialOrigin) {
        return trialOrigin;
    }
    return TrialSource.freeTrial;
};

const onBoardingCompleteEventPropsHandler = (appState: AppState) => {
    const [, selectedColor] = (selectedColorPickerTypeSelector(appState) || '').split('_');
    const { name, accountName } = selectedTemplateDesignSelector(appState) || {};
    const isDynamicTemplate = !name;
    const { gmbKey, isNewGmbKey, concept, keywords, language: selectedLanguage,
        contactInfo: { email, phone, address }, purpose, isGroup, businessName, isGptDemo } = dynamicOnboardingStateSelector(appState);
    const { name: conceptName } = concept || {};

    const isTrial = isTrialSubscription(subscriptionTypeStateSel(appState));
    let trialProps = {};
    let sourceProps = {};
    if (isTrial) {
        const trialEmail = trialUserEmailSel(appState);
        const selectedTemplate = (localStorage.get(TRIAL_PRE_SELECTED_TEMPLATES) || {})[trialEmail];
        const trialSource = getTrialSource(appState);

        trialProps = {
            [TrackingProps.onboardingTemplateValue]: selectedTemplate,
            [TrackingProps.onboardingTemplateSelected]: !!selectedTemplate,
            [TrackingProps.selectedTemplateConfirmed]: selectedTemplate === accountName
        };

        sourceProps = {
            [TrackingProps.source]: trialSource,
        };
    }

    if (isGptDemo) {
        sourceProps[TrackingProps.source] = GPT;
    }

    return {
        [EventTrackingProps.version]: 'v2',
        [EventTrackingProps.selectedTemplate]: name || accountName,
        [TrackingProps.dynamicTemplate]: isDynamicTemplate,
        [TrackingProps.categoryValue]: gmbKey,
        [TrackingProps.templateValue]: conceptName,
        [TrackingProps.attributes]: purpose.join(', '),
        [TrackingProps.keywordsFilled]: !!keywords.trim(),
        [TrackingProps.keywordsValue]: keywords,
        [TrackingProps.categoryMatched]: !isNewGmbKey,
        [TrackingProps.email]: !!email.trim(),
        [TrackingProps.phone]: !!phone.trim(),
        [TrackingProps.address]: !!address.trim(),
        [TrackingProps.colorPaletteType]: selectedColor,
        [TrackingProps.websiteTitle]: businessName,
        [TrackingProps.pronounWe]: concept.group === undefined ? isGroup : undefined,
        [TrackingProps.websiteLanguage]: selectedLanguage,
        [TrackingProps.quickEditSwitchVisible]: shouldShowEditPreview(),
        ...trialProps,
        ...sourceProps
    };
};

const trackNextGenStepEvent = () => {
    const StepToEventNameMap = {
        [WIZARD_STEP_ID.GMB_SELECTION]: NextGenTrackingEvents.CATEGORY_STEP,
        [WIZARD_STEP_ID.WEBSITE_PURPOSE]: NextGenTrackingEvents.ATTRIBUTES_STEP,
        [WIZARD_STEP_ID.NAME]: NextGenTrackingEvents.BUSINESS_STEP,
        [WIZARD_STEP_ID.DESCRIPTION]: NextGenTrackingEvents.KEYWORDS_STEP,
        [WIZARD_STEP_ID.CONTACT_INFO]: NextGenTrackingEvents.CONTACT_STEP,
        [WIZARD_STEP_ID.LANGUAGE]: NextGenTrackingEvents.LANGUAGE_STEP,
        [WIZARD_STEP_ID.PRONOUNS]: NextGenTrackingEvents.PRONOUNS_STEP,
    };

    const propsHandler = (appState, currentStepId) => {
        let props = {};
        const {
            gmbKey, isNewGmbKey, keywords, contactInfo,
            purpose, isGroup, businessName, concept, language: selectedLanguage
        } = dynamicOnboardingStateSelector(appState);
        switch (currentStepId) {
            case WIZARD_STEP_ID.GMB_SELECTION: {
                props = {
                    [TrackingProps.categoryValue]: gmbKey,
                    [TrackingProps.categoryMatched]: !isNewGmbKey
                };
                break;
            }
            case WIZARD_STEP_ID.WEBSITE_PURPOSE:
                props = {
                    [TrackingProps.attributes]: purpose.join(', ')
                };
                break;

            case WIZARD_STEP_ID.NAME:
                props = {
                    [TrackingProps.websiteTitle]: businessName,
                    [TrackingProps.templateValue]: concept?.name
                };
                break;

            case WIZARD_STEP_ID.DESCRIPTION:
                props = {
                    [TrackingProps.keywordsFilled]: !!keywords.trim(),
                    [TrackingProps.keywordsValue]: keywords
                };
                break;

            case WIZARD_STEP_ID.CONTACT_INFO: {
                const { email, phone, address } = contactInfo;
                props = {
                    [TrackingProps.email]: !!email.trim(),
                    [TrackingProps.phone]: !!phone.trim(),
                    [TrackingProps.address]: !!address.trim()
                };
                break;
            }
            case WIZARD_STEP_ID.LANGUAGE: {
                props = {
                    [TrackingProps.websiteLanguage]: selectedLanguage,
                };
                break;
            }
            case WIZARD_STEP_ID.PRONOUNS:
                props = {
                    [TrackingProps.pronounWe]: isGroup
                };
                break;

            default:
                break;
        }
        return props;
    };

    return {
        getEventName: (_, currentStepId) => StepToEventNameMap[currentStepId],
        propsHandler
    };
};

const trackTemplatePreviewEvent = () => {
    const getEventName = (appState) => {
        const { lastStepOpened } = dynamicOnboardingStateSelector(appState);
        return lastStepOpened ?
            NextGenTrackingEvents.PREVIEW_TO_TEMPLATE :
            NextGenTrackingEvents.CATEGORY_TO_TEMPLATE;
    };
    const propsHandler = (appState, { time }) => {
        const { searched, filtered } = templateSelectorStateSelector(appState);
        return {
            [TrackingProps.searchUsed]: searched,
            [TrackingProps.categoriesUsed]: filtered,
            [TrackingProps.timeOnPage]: isNaN(time) ? undefined : time
        };
    };
    return {
        getEventName,
        propsHandler
    };
};

const initiateV2OnboardingEvent = {
    eventName: EventTrackingIds.INITIATE_WSB_ONBOARDING,
    propsHandler: (appState) => {
        const isTrial = isTrialSubscription(subscriptionTypeStateSel(appState));
        let trialProps = {};
        if (isTrial) {
            const trialSource = getTrialSource(appState);
            const trialEmail = trialUserEmailSel(appState);
            const selectedTemplate = (localStorage.get(TRIAL_PRE_SELECTED_TEMPLATES) || {})[trialEmail];

            trialProps = {
                [TrackingProps.onboardingTemplateValue]: selectedTemplate,
                [TrackingProps.source]: trialSource,
                [TrackingProps.onboardingTemplateSelected]: !!selectedTemplate,
            };
        }
        return {
            [EventTrackingProps.version]: 'v2',
            ...trialProps
        };
    }
};

const DynamicTemplateTrackingEvents = {
    [TEMPLATE_SELECTOR_SHOW_PREVIEW]: initiateV2OnboardingEvent,
    [DYNAMIC_TEMPLATE_START_ONBOARDING]: initiateV2OnboardingEvent,
    [PUT_NEW_TEMPLATE_SUCCESS]: {
        eventName: EventTrackingIds.COMPLETE_WSB_ONBOARDING,
        propsHandler: onBoardingCompleteEventPropsHandler
    },
    [TRACK_EVENT_NEXT_GEN_API_FAILURE]: {
        eventName: NextGenTrackingEvents.API_FAILURE,
        propsHandler: (_, errorType) => ({
            [TrackingProps.errorType]: APIErrorMap[errorType] || APIErrorMap.DYNAMIC_TEMPLATES_CONNECTION_ERROR
        })
    },
    [TRACK_WIZARD_STEP_CHANGED]: trackNextGenStepEvent(),
    [GET_GENERATED_AI_CONTENT_SUCCESS]: {
        eventName: PREVIEW_GENERATED,
        propsHandler: () => ({ [TrackingProps.quickEditSwitchVisible]: shouldShowEditPreview() })
    },
    [TRACK_TEMPLATE_PREVIEW_EVENT]: trackTemplatePreviewEvent(),

    [DYNAMIC_PREVIEW_EDIT_OPTION_CHANGED]: { getEventName: (_, payload) => (payload ? NextGenTrackingEvents.QUICK_EDITS_TURNED_ON : null) },
    [DYNAMIC_TEMPLATE_GET_SECTION_CONTENT]: {
        eventName: SECTION_TEXT_GENERATED,
        propsHandler: (_, { filterSections }) => ({ [TrackingProps.sectionTitle]: filterSections?.[0] })
    }
};

export {
    DynamicTemplateTrackingEvents
};
