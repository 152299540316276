import { TYPE_SORT_PRIORITY, STYLE_SORT_PRIORITY, GLOBAL_NAME_SORT_PRIORITY_REGEX } from '../constants';
import { PARAGRAPH_ELEMENT_IGNORED_PROPS } from '../parsers/constants';
import { except } from './setOperations';

const positionComparer = (a: Record<string, any>, b: Record<string, any>): number => (
    b.start - a.start || a.end - b.end
);

const typeComparer = (a: Record<string, any>, b: Record<string, any>): number => {
    const aIndex = TYPE_SORT_PRIORITY.indexOf(a.atype);
    const bIndex = TYPE_SORT_PRIORITY.indexOf(b.atype);

    return bIndex - aIndex;
};
// todo
const globalComparer = (a: Record<string, any>, b: Record<string, any>): number => {
    if (a.globalName && !b.globalName) {
        return 1;
    } else if (!a.globalName && b.globalName) {
        return -1;
    }
    return 0;
};

const globalNameComparer = (a: Record<string, any>, b: Record<string, any>): number => {
    const aIndex = GLOBAL_NAME_SORT_PRIORITY_REGEX
        .findIndex(regex => regex.test(a.globalName ? a.globalName.toLowerCase() : a.globalName));
    const bIndex = GLOBAL_NAME_SORT_PRIORITY_REGEX
        .findIndex(regex => regex.test(b.globalName ? b.globalName.toLowerCase() : b.globalName));

    if (aIndex === -1 || bIndex === -1) {
        return 0;
    } else if (aIndex < bIndex) {
        return 1;
    } else if (aIndex > bIndex) {
        return -1;
    }

    return 0;
};

const propCountComparer = (() => {
    const ignore = except(PARAGRAPH_ELEMENT_IGNORED_PROPS);
    return (a: Record<string, any>, b: Record<string, any>): number => {
        return Object.keys(ignore(b)).length - Object.keys(ignore(a)).length;
    };
})();

const styleComparer = (a: Record<string, any>, b: Record<string, any>): number => {
    let aIndex = -1, bIndex = -1;
    STYLE_SORT_PRIORITY.forEach(function (style, index) {
        if (aIndex === -1 && a[style]) {
            aIndex = index;
        }
        if (bIndex === -1 && b[style]) {
            bIndex = index;
        }
    });

    return bIndex - aIndex;
};

const makeSort = (comparers) => (a, b) => comparers.reduce((result, comparer) => result || comparer(a, b), 0);
const sort = makeSort([
    positionComparer,
    globalNameComparer,
    typeComparer,
    styleComparer,
    globalComparer,
    propCountComparer
]);

export default (...args: Array<any>) => {
    return Array.prototype.concat.apply([], args).sort(sort).reverse();
};
