import * as R from 'ramda';
import { DefaultCellContentOnDrop } from "./constants";
import { tableNormalGlobalstyle } from "../../Workspace/epics/stylesheets/selectors";
import type { TableComponent, TableComponentDependsOn } from './flowTypes';

export default (defaultState: TableComponent, { stylesheets }: TableComponentDependsOn): TableComponent => {
    const style = tableNormalGlobalstyle(stylesheets);

    return R.evolve({
        cells: R.map(
            R.evolve({
                content: () => DefaultCellContentOnDrop,
                style: {
                    globalId: R.always(style.id),
                    globalName: R.always(style.name)
                }
            })
        )
    }, defaultState);
};
