// @ts-nocheck
/* eslint-disable object-shorthand */

export const calendar = {
    lastWeek: function () {
        return this.format(
            this.day() === 0 || this.day() === 6
                ? '[Último] dddd [às] LT' // Saturday + Sunday
                : '[Última] dddd [às] LT' // Monday - Friday
        );
    },
    lastDay: '[Ontem às] LT',
    sameDay: '[Hoje às] LT',
    nextDay: '[Amanhã às] LT',
    nextWeek: 'dddd [às] LT',
    sameElse: 'LL [às] LT',
};
