
import { calcGalleryHeight } from "./calcGalleryHeight";
import { MinGalleryDimensions } from "./constants/index";

import type {
    AdjustmentHook,
    AdjustmentHookConfig,
    ShouldCallAdjustmentHook
} from "../../Workspace/epics/componentsEval/flowTypes";
import type { InstagramGalleryComponent, InstagramGalleryComponentDependsOn, GalleryComponentExtension } from './flowTypes';
import { calcGalleryMaxThumbnailDimensions } from "./calcRenderProps";
import { getMediaAssetsToBeShown } from "./utils";

type AdjustmentData = Record<string, any> // { 'caption-height-of-image-0': 10, 'caption-height-of-image-1': 20 }
type Hook = AdjustmentHook<InstagramGalleryComponent, InstagramGalleryComponentDependsOn, GalleryComponentExtension, AdjustmentData>
type HookConfig =
    AdjustmentHookConfig<InstagramGalleryComponent, InstagramGalleryComponentDependsOn, GalleryComponentExtension, AdjustmentData>
type GalleryShouldCallAdjustmentHook =
    ShouldCallAdjustmentHook<InstagramGalleryComponent, InstagramGalleryComponentDependsOn, GalleryComponentExtension, AdjustmentData>

const
    setHighestCaptionPxIfChanged = (extension) => {
        return { ...extension };
    },
    setHeightAndCaptionMinHeightIfChanged = (component: InstagramGalleryComponent, height: number) => {
        if (component.height === height) {
            return component;
        }

        return { ...component, height };
    },
    hook: Hook = ({
        originalComponent,
        component,
        componentExtension = { highestCaptionPx: 0 },
        componentDependencies,
    }) => {
        const
            totalColumns = component.columns,
            totalRows = component.rows,
            instagramAssetUrls = getMediaAssetsToBeShown(
                // @ts-ignore
                componentDependencies.socialAccounts.instagram.instagramAssetUrls, totalRows, totalColumns
            );
        if (!instagramAssetUrls.length) {
            let finalComponent = component;
            if (originalComponent) {
                if (component.width !== originalComponent.width) {
                    finalComponent = { ...component, width: originalComponent.width };
                }
                if (component.height !== originalComponent.height) {
                    finalComponent = { ...finalComponent, height: originalComponent.height };
                }
            }
            return [finalComponent, componentExtension];
        }
        const
            { maxThumbnailWidth } = calcGalleryMaxThumbnailDimensions(component),
            { columns, spacingPx } = component,
            componentWithAdjustedWidth = {
                ...component,
                width: Math.round((maxThumbnailWidth * columns) + (spacingPx * (columns - 1)))
            },
            calculatedHeight = instagramAssetUrls.length === 0 ?
                MinGalleryDimensions.height :
                calcGalleryHeight({ component: componentWithAdjustedWidth, instagramMediaAssets: instagramAssetUrls });
        return [
            setHeightAndCaptionMinHeightIfChanged(componentWithAdjustedWidth, calculatedHeight),
            setHighestCaptionPxIfChanged(componentExtension)
        ];
    },
    shouldCallHook: GalleryShouldCallAdjustmentHook = (prevProps, { adjustmentData, component }) =>
        // @ts-ignore
        Object.keys(prevProps.componentsMap).length && component && prevProps.component !== component &&
        (
            !!adjustmentData ||
            !component.captionsEnabled ||
            // @ts-ignore
            prevProps.componentDependencies.socialAccounts.instagram.instagramConnected ||
            // @ts-ignore
            prevProps.componentDependencies.socialAccounts.instagram.instagramAssetUrls.length > 0 ||
            !component.captionsEnabled
        ),
    hookConfig: HookConfig = {
        hook,
        shouldCallHook
    };

export {
    hookConfig as default
};
