import React from 'react';
import cx from 'classnames';
import styles from './PreviewDocumentComponent.css';
import viewStyles from '../view/DocumentComponent.css';
import type { PreviewDocumentComponentProps } from "./flowTypes";
import LoadingIndicator from "../../../../view/common/LoadingIndicator/index";

export const PreviewDocumentComponent = ({ url }: PreviewDocumentComponentProps) => (
    <div className={cx(styles.container, viewStyles.container)}>
        <iframe
            className={viewStyles.iframe}
            src={url}
        />
        <LoadingIndicator className={cx(viewStyles.loadingIndicator, viewStyles.hidden)} />
    </div>
);
