import { makeEpic } from "../../epics/makeEpic";
import { preferencesReducer } from "./reducer/preferencesReducer";
import {
    GET_PREFERENCES_SUCCESS_ACTION,
    SET_DEFAULT_PREFERENCES_ACTION,
    PUT_PREFERENCES_SUCCESS_ACTION,
    MERGE_PREFERENCES_ACTION,
} from "./actions";
import { PREFERENCES_EPIC_VALUE_VAT } from './preferencesVat';

export const preferencesEpic = makeEpic({
    valueActionType: PREFERENCES_EPIC_VALUE_VAT,
    defaultState: {},
    updaters: [
        GET_PREFERENCES_SUCCESS_ACTION,
        SET_DEFAULT_PREFERENCES_ACTION,
        PUT_PREFERENCES_SUCCESS_ACTION,
        MERGE_PREFERENCES_ACTION,
    ].map((actionType) => ({
        keepFullActions: true,
        conditions: [actionType],
        reducer: ({ state, values: [action] }) => {
            return {
                state: preferencesReducer(state, action),
                updateReason: actionType
            };
        },
    })),
});
