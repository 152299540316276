import { put } from 'redux-saga/effects'; // eslint-disable-line node/file-extension-in-import
import Resource from "../../Resource";
import { _FC_SET_RESOURCES } from "../../actionTypes";
import { fileChooserResourcesSelector } from "../../../../selectors";
import selectGen from "../../../../../../utils/saga/selectGen";

export default function* (resources: Array<Resource>): Generator<any, Array<Resource>, void> {
    yield put({ type: _FC_SET_RESOURCES, payload: { resources } });
    return yield* selectGen(fileChooserResourcesSelector);
}
