import CALL_API from "../../../../redux/middleware/api/CALL_API";
import { googleReviewsDataActionTypes } from "./actionTypes";

export const
    fetchGoogleReviewData = {
        [CALL_API]: {
            types: googleReviewsDataActionTypes,
            endpoint: 'fetchGoogleReviewsData'
        },
        amendToPrevious: true,
    };

