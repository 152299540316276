import makeStyles from "@mui/styles/makeStyles";

import { parseSize } from "./utils";

type CalcSliderTransform = {
    container: HTMLDivElement | null;
    selected: number;
    total: number;
    width: string;
    gap: string;
    fitAvailable: boolean;
};

const calcSlideWidth = ({ container, total, width, gap, fitAvailable }: CalcSliderTransform): string => {
    if (!container) {
        return width;
    }

    const availableWidth = container.clientWidth;
    const slideGap = parseSize(gap, availableWidth);
    const _totalGapsWidth = slideGap * (total - 1);
    const _slideWidth = parseSize(width, availableWidth);
    const _totalSlidesWidth = _slideWidth * total;
    const _contentWidth = _totalSlidesWidth + _totalGapsWidth;
    const _expandSlides = fitAvailable && _contentWidth < availableWidth;
    const slideWidth = _expandSlides ? (availableWidth - _totalGapsWidth) / total : _slideWidth;

    return `${slideWidth}px`;
};

const calcSliderTransform = ({ container, selected, total, width, gap, fitAvailable }: CalcSliderTransform): string => {
    if (!container) {
        const contentWidth = `calc(${total} * ${width} + (${total} - 1) * ${gap})`;
        const alignMiddle = `calc((100% - ${contentWidth}) / 2)`;
        return fitAvailable ? "none" : `translateX(max(0px, ${alignMiddle}))`;
    }

    const availableWidth = container.clientWidth;
    const slideGap = parseSize(gap, availableWidth);
    const _totalGapsWidth = slideGap * (total - 1);
    const _slideWidth = parseSize(width, availableWidth);
    const _totalSlidesWidth = _slideWidth * total;
    const _contentWidth = _totalSlidesWidth + _totalGapsWidth;
    const _expandSlides = fitAvailable && _contentWidth < availableWidth;
    const slideWidth = _expandSlides ? (availableWidth - _totalGapsWidth) / total : _slideWidth;
    const contentWidth = _expandSlides ? availableWidth : _contentWidth;

    if (availableWidth >= contentWidth) {
        return `translateX(${(availableWidth - contentWidth) / 2}px)`;
    }

    if (selected === 0) {
        return "none";
    }

    if (selected === (total - 1)) {
        return `translateX(${availableWidth - contentWidth}px)`;
    }

    const alignSlideLeft = -1 * selected * (slideWidth + slideGap);
    const viewedSlidesWidth = slideWidth * (selected + 1) + slideGap * selected;
    const alignSlideRight = availableWidth - viewedSlidesWidth;
    const alignSlideMiddle = (alignSlideRight + alignSlideLeft) / 2;
    const alignRight = availableWidth - contentWidth;
    const alignMiddle = Math.max(Math.min(0, alignSlideMiddle), alignRight);

    return `translateX(${alignMiddle}px)`;
};

const calcSliderIndicatorsDisplay = ({ container, total, width, gap }: CalcSliderTransform) => {
    if (!container || total <= 1) {
        return "none";
    }

    const availableWidth = container.clientWidth;
    const slideGap = parseSize(gap, availableWidth);
    const _totalGapsWidth = slideGap * (total - 1);
    const _slideWidth = parseSize(width, availableWidth);
    const _totalSlidesWidth = _slideWidth * total;
    const _contentWidth = _totalSlidesWidth + _totalGapsWidth;

    if (_contentWidth <= availableWidth) {
        return "none";
    }

    return "flex";
};

export const useStyles = makeStyles(
    (theme) => ({
        sliderRoot: {},
        sliderContainer: {
            width: "100%",
            overflow: "hidden"
        },
        sliderView: {
            display: "flex",
            flexDirection: "row",
            margin: "auto",
            transition: "all 200ms ease-out",
            gap: ({ gap }: CalcSliderTransform) => gap,
            transform: calcSliderTransform
        },
        slide: {
            width: calcSlideWidth,
            minWidth: calcSlideWidth,
            maxWidth: calcSlideWidth,
            margin: "0px",
        },
        sliderIndicators: {
            display: calcSliderIndicatorsDisplay,
            justifyContent: "center",
            alignItems: "center",
            gap: "30px",
            marginTop: "20px",
            [theme.breakpoints.up("sm")]: {
                gap: "14px",
                marginTop: "14px"
            }
        },
        sliderIndicator: {
            display: "inline-block",
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            cursor: "pointer",
            backgroundColor: theme.palette.custom.colorGray_bb,
            [theme.breakpoints.up("sm")]: {
                width: "10px",
                height: "10px"
            }
        },
        activeIndicator: {
            backgroundColor: theme.palette.custom.colorScienceBlue
        }
    }),
    { name: "Slider" }
);
