
export const
    defaultState = {
        link: "",
        autoplay: false,
        loop: false,
        showTextLink: false,
        showVideoDesc: false,
        portrait: false,
        title: false,
        byline: false,
        mobileHide: false,
    },
    DefaultAllowed = "autoplay; fullscreen; picture-in-picture",
    DEFAULT_WIDTH = 540,
    DEFAULT_HEIGHT = 360,
    DATA_TEST_ID = 'vimeo-widget';
