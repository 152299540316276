import React from 'react';
import NavigationGroup from '../../../../PropertiesPanel/view/IntlNavigationGroup';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import pagesTitles from "./titles";
import type { Props } from '../flowTypes';
import * as pagesIds from "./ids";
import injectIntl from "../../../../../view/intl/injectIntl";
import { id as mobileViewPageId } from '../../../../PropertiesPanel/view/MobileView/page';

const
    id: any = pagesIds.MAIN,
    title = pagesTitles[id],
    view = injectIntl(({
        navigateToPage
    }: Props) => {
        return (
            <Page>
                <NavigationGroup
                    navigateToPage={navigateToPage}
                    pagesTitles={pagesTitles}
                    targetPagesIds={[
                        pagesIds.Link,
                        pagesIds.TEXT_EFFECTS,
                        pagesIds.ADV_FORMATTING,
                        mobileViewPageId
                    ]}
                />
            </Page>
        );
    });

export { id, title, view };
