export const
    DEMO_SHOW_VERIFICATION_EMAIL_REQUIRED_SUCCESS_PAGE_ACTION = 'DEMO_SHOW_VERIFICATION_EMAIL_REQUIRED_SUCCESS_PAGE_ACTION',
    showVerificationEmailRequiredSuccessPageAction = () => ({
        type: DEMO_SHOW_VERIFICATION_EMAIL_REQUIRED_SUCCESS_PAGE_ACTION
    });

export const
    DEMO_SHOW_VERIFICATION_EMAIL_REQUIRED_PAGE_ACTION = 'DEMO_SHOW_VERIFICATION_EMAIL_REQUIRED_PAGE_ACTION',
    showVerificationEmailRequiredPageAction = () => ({
        type: DEMO_SHOW_VERIFICATION_EMAIL_REQUIRED_PAGE_ACTION
    });

export const
    DEMO_CLEAN_UP_VERIFICATION_EMAIL_REQUIRED_SCREEN_ACTION = 'DEMO_CLEAN_UP_VERIFICATION_EMAIL_REQUIRED_SCREEN_ACTION',
    cleanUpVerficationEmailRequiredScreenAction = () => ({
        type: DEMO_CLEAN_UP_VERIFICATION_EMAIL_REQUIRED_SCREEN_ACTION
    });
