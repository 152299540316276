/* eslint-disable max-len */

import * as R from "ramda";
import {
    getNumRowsAndCols,
    getRowAndColumnForAddOrRemoveAction,
    fixRowAndColumnIndices,
    cloneCellWithEmptyText
} from "../utils";
import type { TableComponent, TableComponentDependsOn } from "../flowTypes";

export const addRowReducer =
    (component: TableComponent, action: AnyAction, dependencies: TableComponentDependsOn): TableComponent => {
        const
            { numRows, numCols } = getNumRowsAndCols(component.cells),
            { rowIndex: rowIndexToCopy } = getRowAndColumnForAddOrRemoveAction(
                { numRows, numCols },
                dependencies.tableEditModeState.selectedCellsIndexes[0]
            ),
            copyStartIndex = numCols * rowIndexToCopy,
            newCells: any = [];

        for (let i = 0; i < numCols; i++) {
            newCells.push(cloneCellWithEmptyText(component.cells[copyStartIndex + i]));
        }

        return R.evolve({
            cells: R.pipe(
                R.insertAll((copyStartIndex + numCols), newCells),
                fixRowAndColumnIndices({ numRows: numRows + 1, numCols })
            )
        }, component);
    };
