import { equals } from 'ramda';
import makeEpic from '../../../../../epics/makeEpic';
import { receiveOnly } from '../../../../../epics/makeCondition';
import { TEXT_AUTO_COLOR_CONTROLS_DEPENDENCIES_VAT } from './valueActionType';
import { getParentThemeMap } from '../../../../ThemeGlobalData/utils/getParentThemeMap';
import {
    ComponentsMapSelector, MakeEditModeComponentSelectorByKind,
} from '../../../../Workspace/epics/componentsEval/selectorActionTypes';
import { TemplateSelectedThemeActionType } from '../../../Template/epics/template/selectorActionTypes';
import { TextComponentKind } from '../../kind';
import { COLOR_THEME_SITE_SETTINGS_EPIC_VALUE } from '../../../../SiteSettings/ColorThemeData/colorThemeSiteSettingsVAT';

const defaultState = null;

export const textAutoColorControlsDependenciesEpic = makeEpic({
    defaultState,
    valueActionType: TEXT_AUTO_COLOR_CONTROLS_DEPENDENCIES_VAT,
    updaters: [
        {
            conditions: [
                MakeEditModeComponentSelectorByKind(TextComponentKind),
                receiveOnly(ComponentsMapSelector),
                receiveOnly(TemplateSelectedThemeActionType),
                receiveOnly(COLOR_THEME_SITE_SETTINGS_EPIC_VALUE),
            ],
            reducer: ({ state, values: [component, componentsMap, selectedTheme, { autoColorMode }] }) => {
                if (autoColorMode && selectedTheme && component) {
                    const selectedParentTheme = getParentThemeMap(componentsMap, selectedTheme)[component.id];
                    const newState = {
                        autoColorMode: true,
                        selectedParentTheme,
                        themeOverrideColor: component.themeOverrideColor,
                        themeHighlightColor: component.themeHighlightColor,
                        themeShadowBlurRadius: component.themeShadowBlurRadius,
                        themeShadowColor: component.themeShadowColor,
                        themeShadowOffsetX: component.themeShadowOffsetX,
                        themeShadowOffsetY: component.themeShadowOffsetY,
                    };
                    return equals(state, newState) ? { state } : { state: newState };
                }
                return { state: defaultState };
            },
        }
    ]
});
