import React from "react";
import * as R from 'ramda';
import resizeStyles from './ResizeDecorations.css';
import { Border } from '../../../components/presentational/Border/Border';
import { SelectionFrameBorderWidth } from '../../../constants/app';
import type { SnappingDecorations } from "../../../components/Workspace/epics/snappingDecos/index";
import { makeBorderProps } from "./makeBorderProps";
import { red270 } from '../../../components/variables.css';

type Props = {
    state: SnappingDecorations
}

const lineTypes = {
    top: 'left',
    bottom: 'left',
    left: 'top',
    right: 'top',
    middle: 'left',
    center: 'top'
};

const typeMargins = {
    top: -SelectionFrameBorderWidth,
    bottom: 0,
    left: -SelectionFrameBorderWidth,
    right: 0,
    middle: 0,
    center: 0
};

export default ({ state }: Props) => {
    const
        bboxes: React.JSX.Element[] = [],
        lines: React.JSX.Element[] = [],
        { workspaceBBox, snappedPoints } = state,
        filteredSnappingPoints = R.filter(({ isEquidistant }) => (!isEquidistant), snappedPoints);

    let counter = 0;

    R.map(({ bbox, isSectionSnapping, sectionBBox }) => {
        let bBox = isSectionSnapping && sectionBBox ? sectionBBox : bbox;
        const borderProps = {
            bBox,
            opacity: 0.5,
            color: red270
        };
        counter++;
        return bboxes.push(<Border {...makeBorderProps(borderProps)} key={counter} />);
    }, filteredSnappingPoints);

    R.mapObjIndexed(({ point }, type) => {
        counter++;
        const lineStyle: Style = {
            left: lineTypes[type] === 'top' ? point + typeMargins[type] : workspaceBBox.left,
            top: lineTypes[type] === 'top' ? workspaceBBox.top : point + typeMargins[type]
        };
        if (lineTypes[type] === 'top') {
            lineStyle.height = workspaceBBox.bottom;
        } else {
            lineStyle.width = workspaceBBox.right + Math.abs(workspaceBBox.left);
        }
        lines.push(
            <div className={resizeStyles['measurementLine-' + lineTypes[type]]} style={lineStyle} key={counter} />
        );
    }, filteredSnappingPoints);

    return (
        <div>
            {bboxes}
            {lines}
        </div>
    );
};

