import { LcTabName } from "../constants";
import type { LinkChooserLinkType } from "../flowTypes";
import Url from "../../../../../../utils/Url";
import { DataSite } from "../../../../../../dal/model/index";
import Resource from '../../../../../redux/modules/children/fileChooser/Resource';
import { JS_PROTOCOL_PATTERN } from '../../../../../constants';
import { getLinkName } from "./getLinkName";
import type { SectionComponents } from '../../../../../components/PagesTree/sectionComponents/epic/sectionComponentsEpic';

// eslint-disable-next-line max-len
export default (link: LinkChooserLinkType, site: DataSite, sectionComponents: SectionComponents, globalVariables: Record<string, any>): string => {
    const { type, value } = link;
    let name;

    if (type === LcTabName.FILE) {
        name = Resource.getBaseName(value.href);// value.getName(); WBTGEN-3315
    } else if (type === LcTabName.URL && !JS_PROTOCOL_PATTERN.test(value)) {
        name = Url(value).getRelative();
    } else {
        name = getLinkName(link, site, sectionComponents, globalVariables);
    }

    return name;
};
