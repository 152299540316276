import makeStateSelectorReducer from "../../../../epics/makeStateSelectorReducer";
import VAT from './valueActionType';
import epic from './index';

export const pageDataSetTemplateInfoSelectorReducer = makeStateSelectorReducer(
    epic.reducer,
    VAT,
    state => state && {
        id: state.template.id,
        name: state.template.name
    }
);
