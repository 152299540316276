import { pathSelector } from '../utils/pathSelector';
import { Lit } from '../lit';
import type { PathSelectorPath } from '../utils/pathSelector';
import { ActionType } from "./makeCondition";
import { isStr } from "../isType";

export const
    epicsAppSel = (path: PathSelectorPath = []) => pathSelector([Lit.epics, ...path]),
    singleEpicAppSel = (vat: ActionType | string, path: PathSelectorPath = []) =>
        epicsAppSel([isStr(vat) ? vat : vat.toString(), ...path]),
    epicStateAppSel = (vat: ActionType | string, path: PathSelectorPath = []) =>
        singleEpicAppSel(vat, [Lit.state, ...path]);
