// @ts-nocheck
export const
    customMouseEvents = (
        onMouseDown: boolean = true,
        onMouseMove: boolean = true,
        onMouseUp: boolean = true
    ): Record<string, any> => ({
        onMouseDown: e => (onMouseDown ? e.stopPropagation() : {}),
        onMouseMove: e => (onMouseMove ? e.stopPropagation() : {}),
        onMouseUp: e => (onMouseUp ? e.stopPropagation() : {})
    }),
    NoMouseUpDownAndMove = customMouseEvents(true, true, true),
    OnlyMouseUp = customMouseEvents(true, true, false),
    NoMouseDownAndUp = customMouseEvents(true, false, true);

export default customMouseEvents();
