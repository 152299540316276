/* eslint-disable max-len */
import React from "react";
import { Flex } from "../../../../../view/reflexbox/index";
import Page from "../../../../PropertiesPanel/view/PropertiesPage";
import * as selectors from "../../selectors";
import * as pagesIds from "./ids";
import pagesTitles from "./titles";
import { getAllStylesheets, getStyleById, menuDropdownWidth } from "../../../../Workspace/epics/stylesheets/selectors";
import optionMapper from "../../globalStyle/options";
import GlobalstyleSelector from "../../../../presentational/GlobalstyleSelector/index";
import { GlobalstyleDialogInvoker } from "../../../../presentational/GlobalstyleDialogInvoker/index";
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import PropertyContainer from "../../../../../view/common/PropertyContainer/index";
import injectIntl from "../../../../../view/intl/injectIntl";
import { DROPDOWN_SELECTOR_WIDTH } from "../../../../Globalstyles/Menu/index";
import type { MenuComponent } from "../../flowTypes";
import type { Stylesheets } from "../../../../Workspace/epics/stylesheets/flowTypes";
import {
    MENU_PROP_PANEL_GLOBAL_STYLE_UPDATED,
    MENU_PROP_PANEL_MENU_GLOBAL_STYLE_BTN_PRESSED,
    MENU_PROP_PANEL_SUBMENU_GLOBAL_STYLE_BTN_PRESSED,
    MENU_PROP_PANEL_MENU_GLOBAL_THEME_STYLE_BTN_PRESSED,
    MENU_PROP_PANEL_SUBMENU_GLOBAL_THEME_STYLE_BTN_PRESSED,
    MENU_PROP_PANEL_SUBMENU_GLOBAL_STYLE_UPDATED,
    MENU_PROP_PANEL_DROPDOWN_WIDTH_CHANGE,
    MENU_PROP_PANEL_GLOBAL_THEME_STYLE_UPDATED,
    MENU_PROP_PANEL_SUBMENU_GLOBAL_THEME_STYLE_UPDATED
} from "../../actionTypes";
import styles from "../../../../Globalstyles/GlobalstylesDialog.css";
import { RadioButtonGroup } from "../../../../../view/common/RadioButton/index";
import Combobox from "../../../../../view/common/Combobox/index";
import { DropdownWidthOptions } from "../../../../Globalstyles/Menu/constants";
import type { ThemeSiteSettingsDataType } from "../../../../ThemeGlobalData/flowTypes";
import ThemeStyleMenuType from "../../globalStyle/themeColorKind";

type Props = {
    selectedComponent: MenuComponent;
    stylesheets: Stylesheets;
    dispatch: Dispatch;
    intl: any;
    themeSettingsData: ThemeSiteSettingsDataType;
};
let oldDropDownWidth = 200;

const renderDropDownRadioOptions = ({ dropdownWidthOptions, dropdownWidth, dispatch, menuStyleIds, stylesheets }) => (
    <div className={styles.dropdownWidthRadioButtonContainer}>
        <RadioButtonGroup
            optionsConfig={dropdownWidthOptions}
            value={(dropdownWidth != null).toString()}
            onChange={changedValue => {
                if (changedValue !== dropdownWidth) {
                    dispatch({
                        type: MENU_PROP_PANEL_DROPDOWN_WIDTH_CHANGE,
                        payload: {
                            width: changedValue === "false" ? null : oldDropDownWidth,
                            updatedStylesheetIds: menuStyleIds,
                            stylesheets
                        }
                    });
                }
            }}
            labelClassName={styles.dropdownWidthOptionLabel}
            labelTextClassName={styles.dropdownWidthOptionLabelText}
        />
        {dropdownWidth && (
            <div className={styles.dropdownWidthSelector}>
                <Combobox
                    value={dropdownWidth}
                    searchable={false}
                    options={DropdownWidthOptions}
                    onChange={({ value }) => {
                        if (value !== dropdownWidth) {
                            oldDropDownWidth = value;
                            dispatch({
                                type: MENU_PROP_PANEL_DROPDOWN_WIDTH_CHANGE,
                                payload: {
                                    width: value,
                                    updatedStylesheetIds: menuStyleIds,
                                    stylesheets
                                }
                            });
                        }
                    }}
                    dropDownClassName={styles.dropDownLimitedWidthOptionsContainer}
                    style={{
                        marginTop: 6,
                        marginLeft: 22,
                        width: DROPDOWN_SELECTOR_WIDTH
                    }}
                />
            </div>
        )}
    </div>
);

const id = pagesIds.STYLE,
    DropDownOptionCaption = injectIntl(({ intl, caption }) => (
        <div className={styles.dropdownWidthRadioButtonSubtitles}>{intl.msgJoint(caption)}</div>
    )),
    dropdownWidthOptions = [
        {
            label: "msg: gs.menu.dropDownWidth.option.unlimitedWidth {Unlimited width}",
            value: "false",
            caption: "msg: gs.menu.dropDownWidth.unlimitedWidth.subTitle {Text will always show on one line.}",
            sideChildren: (
                <DropDownOptionCaption caption="msg: gs.menu.dropDownWidth.unlimitedWidth.subTitle {Text will always show on one line.}" />
            )
        },
        {
            label: "msg: gs.menu.dropDownWidth.option.limitedWidth {Limited width}",
            value: "true",
            caption: "msg: gs.menu.dropDownWidth.limitedWidth.subTitle {Text will split into two lines if longer than this width.}",
            sideChildren: (
                <DropDownOptionCaption caption="msg: gs.menu.dropDownWidth.limitedWidth.subTitle {Text will split into two lines if longer than this width.}" />
            )
        }
    ],
    view = injectIntl(({ selectedComponent, stylesheets, dispatch, intl, themeSettingsData }: Props) => {
        let styles = getAllStylesheets(stylesheets),
            styleGlobalId,
            submenuStyleGlobalId,
            menuStyleIds,
            mainMenuOnChangeAction,
            subMenuOnChangeAction,
            autoColorMode = (themeSettingsData && themeSettingsData.autoColorMode) || false,
            styleType = "";

        if (autoColorMode && selectedComponent.themeStyles) {
            menuStyleIds = optionMapper(styles, intl, ThemeStyleMenuType).map(style => style.value);
            styleType = ThemeStyleMenuType;
            styleGlobalId = selectedComponent.themeStyles.mainMenu.id;
            submenuStyleGlobalId = selectedComponent.themeStyles.submenu.id;
            mainMenuOnChangeAction = MENU_PROP_PANEL_GLOBAL_THEME_STYLE_UPDATED;
            subMenuOnChangeAction = MENU_PROP_PANEL_SUBMENU_GLOBAL_THEME_STYLE_UPDATED;
        } else {
            styleGlobalId = selectors.styleGlobalId(selectedComponent);
            submenuStyleGlobalId = selectors.submenuStyleGlobalId(selectedComponent);
            menuStyleIds = optionMapper(styles, intl).map(style => style.value);
            mainMenuOnChangeAction = MENU_PROP_PANEL_GLOBAL_STYLE_UPDATED;
            subMenuOnChangeAction = MENU_PROP_PANEL_SUBMENU_GLOBAL_STYLE_UPDATED;
        }

        const currentStylesheet = getStyleById(styleGlobalId)(styles),
            dropdownWidth = menuDropdownWidth(currentStylesheet);
        return (
            <Page>
                <VerticalSpacer />
                <PropertyContainer label="msg: gs.menu.globalStyle.label {Main menu styling}">
                    <Flex align="center" justify="space-between">
                        { /* @ts-ignore */ }
                        <GlobalstyleSelector
                            selected={styleGlobalId}
                            onChangeAction={mainMenuOnChangeAction}
                            options={optionMapper(styles, intl, styleType)}
                            dispatch={dispatch}
                        />
                        <GlobalstyleDialogInvoker
                            onClick={() =>
                                dispatch({
                                    type:
                                        (autoColorMode && MENU_PROP_PANEL_MENU_GLOBAL_THEME_STYLE_BTN_PRESSED) ||
                                        MENU_PROP_PANEL_MENU_GLOBAL_STYLE_BTN_PRESSED
                                })}
                        />
                    </Flex>
                </PropertyContainer>
                <VerticalSpacer />
                <PropertyContainer label="msg: gs.menu.globalStyle.sublabel {Submenu styling}">
                    <Flex align="center" justify="space-between">
                        { /* @ts-ignore */ }
                        <GlobalstyleSelector
                            selected={submenuStyleGlobalId}
                            onChangeAction={subMenuOnChangeAction}
                            options={optionMapper(styles, intl, styleType)}
                            dispatch={dispatch}
                        />
                        <GlobalstyleDialogInvoker
                            onClick={() =>
                                dispatch({
                                    type:
                                        (autoColorMode && MENU_PROP_PANEL_SUBMENU_GLOBAL_THEME_STYLE_BTN_PRESSED) ||
                                        MENU_PROP_PANEL_SUBMENU_GLOBAL_STYLE_BTN_PRESSED
                                })}
                        />
                    </Flex>
                </PropertyContainer>
                <VerticalSpacer />
                <PropertyContainer label="msg: gs.menu.dropDownWidth {Dropdown width}" isGroupLabelRequired={false}>
                    {renderDropDownRadioOptions({
                        dropdownWidthOptions,
                        dropdownWidth,
                        dispatch,
                        menuStyleIds,
                        stylesheets
                    })}
                </PropertyContainer>
            </Page>
        );
    }),
    title = pagesTitles[id];
export { id, view, title };
