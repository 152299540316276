import React from 'react';
import {
    WIDGETS_GOOGLEMAPS_SHOW_UPDATE_LINK_DIALOG,
} from './actionTypes';
import { ctaOnClickFactory } from '../utils';
import { MctaCheckbox } from '../../../componentMainActions/index';
import HorizontalSpacer from '../../../../view/common/HorizontalSpacer';
import injectIntl from '../../../../view/intl/injectIntl';
import * as actions from './actionTypes';

const EditView = injectIntl(props => {
    const { selectedComponent, dispatch } = props;
    return (
        <div>
            <HorizontalSpacer x={5} />
            <MctaCheckbox
                isChecked={selectedComponent.stretch}
                label="msg: common.fullWidth {Full width}"
                onClick={() => dispatch({ type: actions.WIDGETS_GOOGLE_MAPS_FULL_WIDTH_TOGGLE })}
            />
            <HorizontalSpacer x={5} />
        </div>
    );
});

const componentMainActions = {
    editView: EditView,
    editButtonText: 'msg: common.settings {Settings}',
    ctaButtonText: 'msg: widgets.googlemaps.cta.changeMap {Change map}',
    ctaOnClick: ctaOnClickFactory(
        WIDGETS_GOOGLEMAPS_SHOW_UPDATE_LINK_DIALOG,
        ({ addressText, addressLocation, addressUrl }) => ({ addressText, addressLocation, addressUrl })
    ),
};

export { componentMainActions };
