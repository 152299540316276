import { StyleBlock } from '../Globalstyles/types';
import * as backgroundMapper from './background';
import * as borderMapper from "./border";
import type { StyleIn } from "./flowTypes";
import type { Style } from '../../../../src/mappers/flowTypes';

export function to(style: null | undefined | StyleIn): null | undefined | Style {
    return style && {
        border: borderMapper.to(style.border),
        background: backgroundMapper.to(style.background)
    };
}

export function back(style: null | undefined | Style): null | undefined | StyleIn {
    return style && {
        type: StyleBlock,
        border: borderMapper.back(style.border),
        background: backgroundMapper.back(style.background)
    };
}
