/* eslint-disable */
import { RenderType } from "photoeditorsdk/js/PhotoEditorSDK";
import bowser from 'bowser';
import { Intl } from "../../../intl";

const
    PHOTOEDITORSDK_ASSETS_PATH = '/photoeditorsdk/assets',
    PHOTOEDITORSDK_UPDATED_ASSETS_PATH = '/photoeditorsdk/updatedAssets',
    TRANSFORM_CONTROL_PATH = '/ui/desktop/editor/controls/transform/',
    CANVAS_HEADER_PATH = '/ui/desktop/editor/canvas-header/',
    CANVAS_OVERLAY_CONTROLS_PATH = '/ui/desktop/editor/controls/shared/canvas-overlay-controls/',
    SHARED_MODALS_PATH = '/ui/desktop/shared/modals/',
    updatedAssetsMap = {
        [`${PHOTOEDITORSDK_ASSETS_PATH}${TRANSFORM_CONTROL_PATH}rotate.png`]:       `${PHOTOEDITORSDK_UPDATED_ASSETS_PATH}${TRANSFORM_CONTROL_PATH}rotate.svg`,
        [`${PHOTOEDITORSDK_ASSETS_PATH}${TRANSFORM_CONTROL_PATH}rotate@2x.png`]:    `${PHOTOEDITORSDK_UPDATED_ASSETS_PATH}${TRANSFORM_CONTROL_PATH}rotate.svg`,
        [`${PHOTOEDITORSDK_ASSETS_PATH}${TRANSFORM_CONTROL_PATH}flip.png`]:         `${PHOTOEDITORSDK_UPDATED_ASSETS_PATH}${TRANSFORM_CONTROL_PATH}flip.svg`,
        [`${PHOTOEDITORSDK_ASSETS_PATH}${TRANSFORM_CONTROL_PATH}flip@2x.png`]:      `${PHOTOEDITORSDK_UPDATED_ASSETS_PATH}${TRANSFORM_CONTROL_PATH}flip.svg`,
        [`${PHOTOEDITORSDK_ASSETS_PATH}${CANVAS_HEADER_PATH}redo.png`]:             `${PHOTOEDITORSDK_UPDATED_ASSETS_PATH}${CANVAS_HEADER_PATH}redo.svg`,
        [`${PHOTOEDITORSDK_ASSETS_PATH}${CANVAS_HEADER_PATH}redo@2x.png`]:          `${PHOTOEDITORSDK_UPDATED_ASSETS_PATH}${CANVAS_HEADER_PATH}redo.svg`,
        [`${PHOTOEDITORSDK_ASSETS_PATH}${CANVAS_HEADER_PATH}undo.png`]:             `${PHOTOEDITORSDK_UPDATED_ASSETS_PATH}${CANVAS_HEADER_PATH}undo_image_editor.svg`,
        [`${PHOTOEDITORSDK_ASSETS_PATH}${CANVAS_HEADER_PATH}undo@2x.png`]:          `${PHOTOEDITORSDK_UPDATED_ASSETS_PATH}${CANVAS_HEADER_PATH}undo_image_editor.svg`,
        [`${PHOTOEDITORSDK_ASSETS_PATH}${CANVAS_OVERLAY_CONTROLS_PATH}to-front.png`]: `${PHOTOEDITORSDK_UPDATED_ASSETS_PATH}${CANVAS_OVERLAY_CONTROLS_PATH}to-front.svg`,
        [`${PHOTOEDITORSDK_ASSETS_PATH}${CANVAS_OVERLAY_CONTROLS_PATH}to-front@2x.png`]: `${PHOTOEDITORSDK_UPDATED_ASSETS_PATH}${CANVAS_OVERLAY_CONTROLS_PATH}to-front.svg`,
        [`${PHOTOEDITORSDK_ASSETS_PATH}${CANVAS_OVERLAY_CONTROLS_PATH}duplicate.png`]: `${PHOTOEDITORSDK_UPDATED_ASSETS_PATH}${CANVAS_OVERLAY_CONTROLS_PATH}duplicate.svg`,
        [`${PHOTOEDITORSDK_ASSETS_PATH}${CANVAS_OVERLAY_CONTROLS_PATH}duplicate@2x.png`]: `${PHOTOEDITORSDK_UPDATED_ASSETS_PATH}${CANVAS_OVERLAY_CONTROLS_PATH}duplicate.svg`,
        [`${PHOTOEDITORSDK_ASSETS_PATH}${CANVAS_OVERLAY_CONTROLS_PATH}delete.png`]: `${PHOTOEDITORSDK_UPDATED_ASSETS_PATH}${CANVAS_OVERLAY_CONTROLS_PATH}delete.svg`,
        [`${PHOTOEDITORSDK_ASSETS_PATH}${CANVAS_OVERLAY_CONTROLS_PATH}flip-horizontal.png`]: `${PHOTOEDITORSDK_UPDATED_ASSETS_PATH}${CANVAS_OVERLAY_CONTROLS_PATH}flip.svg`,
        [`${PHOTOEDITORSDK_ASSETS_PATH}${CANVAS_OVERLAY_CONTROLS_PATH}flip-horizontal@2x.png`]: `${PHOTOEDITORSDK_UPDATED_ASSETS_PATH}${CANVAS_OVERLAY_CONTROLS_PATH}flip.svg`,
        [`${PHOTOEDITORSDK_ASSETS_PATH}${CANVAS_OVERLAY_CONTROLS_PATH}delete@2x.png`]: `${PHOTOEDITORSDK_UPDATED_ASSETS_PATH}${CANVAS_OVERLAY_CONTROLS_PATH}delete.svg`,
        [`${PHOTOEDITORSDK_ASSETS_PATH}${SHARED_MODALS_PATH}icon.png`]: `${PHOTOEDITORSDK_UPDATED_ASSETS_PATH}${SHARED_MODALS_PATH}icon.svg`,
        [`${PHOTOEDITORSDK_ASSETS_PATH}${SHARED_MODALS_PATH}icon@2x.png`]: `${PHOTOEDITORSDK_UPDATED_ASSETS_PATH}${SHARED_MODALS_PATH}icon.svg`,
    };
export const conf = {
    responsive: true,
    preloader: false,
    editor: {
        displayResizeMessage: true,
        displayCloseButton: false,
        enableExport: false,
        maxMegaPixels: {
            desktop: 64
        },
        tools: [
            'transform',
            'filter',
            'adjustments',
            'focus',
            'text',
            'textdesign',
            'sticker',
            'brush',
            'frame',
            'overlay'
        ],
        export: {
            quality: 1,
            download: false,
            type: bowser.msie || bowser.msedge ? RenderType.MSBLOB : RenderType.BLOB
        }
    },
    assets: {
        // This should be the absolute path to your `assets` directory
        baseUrl: '/photoeditorsdk/assets',
        resolver: (path: string) => {
            return updatedAssetsMap[path] || path;
        }

    }
};

export const getLocalizationObject = (intl: Intl) => {
    return {
        "pesdk": {
            "common": {
                "title": {
                    "error": intl.msgJoint("msg: imageEditor.common.error {Error}")
                },
                "text": {
                    "loading": intl.msgJoint("msg: imageEditor.common.loading {Loading...}"),
                    "updating": intl.msgJoint("msg: imageEditor.common.updating {Updating...}")
                },
                "button": {
                    "cancel": intl.msgJoint("msg: imageEditor.common.cancel {Cancel}")
                }
            },
            "editor": {
                "button": {
                    "export": intl.msgJoint("msg: imageEditor.editorButton.export {Export}"),
                    "save": intl.msgJoint("msg: imageEditor.editorButton.save {Save}"),
                    "load": intl.msgJoint("msg: imageEditor.editorButton.load {Load}"),
                    "close": intl.msgJoint("msg: imageEditor.editorButton.close {Close}"),
                    "newImageChangesLostWarningYes": intl.msgJoint("msg: imageEditor.editorButton.yes {Yes}"),
                    "newImageChangesLostWarningNo": intl.msgJoint("msg: imageEditor.editorButton.no {No}"),
                    "discardChangesWarningKeep": intl.msgJoint("msg: imageEditor.editorButton.keepChanges {Keep changes}"),
                    "discardChangesWarningDiscard": intl.msgJoint("msg: imageEditor.editorButton.discardChanges {Discard changes}")
                },
                "title": {
                    "newImageChangesLostWarning": intl.msgJoint("msg: imageEditor.editorTitle.newImageChangesLostWarning {New Image}"),
                    "imageResizedWarning_maxMegaPixels": intl.msgJoint("msg: imageEditor.editorTitle.imageResizedWarning {Image resized}"),
                    "imageResizedWarning_maxDimensions": intl.msgJoint("msg: imageEditor.editorTitle.imageResizedWarning {Image resized}"),
                    "fontLoadingError": intl.msgJoint("msg: imageEditor.editorTitle.fontLoadingError {Failed to load font}"),
                    "discardChangesWarning": intl.msgJoint("msg: imageEditor.editorTitle.discardChangesWarning {Discard changes?}")
                },
                "text": {
                    "newImageChangesLostWarning": intl.msgJoint("msg: imageEditor.editorText.newImageChangesLostWarning {Any unsaved changes will be lost. Do you want to continue?}"),
                    //"imageResizedWarning_maxMegaPixels": intl.msgJoint("msg: imageEditor.editorText.exceedMaxSize {Your image exceeds the maximum size of \${maxMegaPixels} megapixels and has therefore been resized to \${width}x\${height} pixels.}"),
                    //"imageResizedWarning_maxDimensions": intl.msgJoint("msg: imageEditor.editorText.hardwareLimitResized {Due to hardware limitations your image has been resized to ${width}x${height} pixels.}"),
                    "renderingError": intl.msgJoint("msg: imageEditor.editorText.renderingError {An error has occurred while rendering the image.}"),
                    "exporting": intl.msgJoint("msg: imageEditor.editorText.exporting {Exporting...}"),
                    "saving": intl.msgJoint("msg: imageEditor.editorText.saving {Saving...}"),
                    "loading": intl.msgJoint("msg: imageEditor.editorText.loading {Loading...}"),
                    "resizing": intl.msgJoint("msg: imageEditor.editorText.resizing {Resizing...}"),
                    "loadingFonts": intl.msgJoint("msg: imageEditor.editorText.loadingFonts {Loading fonts...}"),
                    "fontLoadingError": intl.msgJoint("msg: imageEditor.editorText.fontLoadingError {The following fonts could not be loaded: ${fonts}.}"),
                    "webcamUnavailableError": intl.msgJoint("msg: imageEditor.editorText.webcamUnavailableError {Unable to display webcam image (Error: ${error})}"),
                    "invalidFileTypeError": intl.msgJoint("msg: imageEditor.editorText.invalidFileTypeError {The file type ${fileType} is not supported.}"),
                    "imageLoadingError": intl.msgJoint("msg: imageEditor.editorText.imageLoadingError {Failed to load image. This can have multiple reasons, e.g. the file is corrupted or the file type is not supported.}"),
                    "discardChangesWarning": intl.msgJoint("msg: imageEditor.editorText.discardChangesWarning {You have unsaved changes. Are you sure you want to discard the changes?}")
                }
            },
            "library": {
                "title": {
                    "name": intl.msgJoint("msg: imageEditor.libraryTitle.name {Library}")
                },
                "button": {
                    "fileDropZone": intl.msgJoint("msg: imageEditor.libraryButton.fileDropZone {Drag image here or click to browse for one.}"),
                    "fileDropZoneHovered": intl.msgJoint("msg: imageEditor.libraryButton.fileDropZoneHovered {Drop to upload.}")
                },
                "placeholder": {
                    "search": intl.msgJoint("msg: imageEditor.libraryPlaceholder.search {Search Library}")
                },
                "text": {
                    "noResults": intl.msgJoint("msg: imageEditor.libraryText.noResults {No results}")
                }
            },
            "transform": {
                "title": {
                    "name": intl.msgJoint("msg: imageEditor.transformTitle.name {Transform}")
                },
                "dimensions": {
                    "lock": intl.msgJoint("msg: imageEditor.transformTitle.lockResolution {Lock resolution}")
                },
                "button": {
                    "reset": intl.msgJoint("msg: imageEditor.transformButton.reset {Reset Default}")
                },
                "asset": { //todo
                    "imgly_transforms_common": {
                        "name": intl.msgJoint("msg: imageEditor.asset.imglyTransformsCommon.name {Common Crops}"),
                        "asset": {
                            "imgly_transform_common_custom": intl.msgJoint("msg: imageEditor.asset.imglyTransformsCommon.custom {Custom}"),
                            "imgly_transform_common_square": intl.msgJoint("msg: imageEditor.asset.imglyTransformsCommon.square {Square}"),
                            "imgly_transform_common_4-3": intl.msgJoint("msg: imageEditor.asset.imglyTransformsCommon.fourByThree {4:3}"),
                            "imgly_transform_common_16-9": intl.msgJoint("msg: imageEditor.asset.imglyTransformsCommon.sixteenByNine {16:9}")
                        }
                    },
                    "imgly_transforms_facebook": {
                        "name": intl.msgJoint("msg: imageEditor.asset.imglyTransformsFacebook.name {Facebook}"),
                        "asset": {
                            "imgly_transform_facebook_ad": intl.msgJoint("msg: imageEditor.asset.imglyTransformsFacebook.ad {Ad}"),
                            "imgly_transform_facebook_post": intl.msgJoint("msg: imageEditor.asset.imglyTransformsFacebook.post {Post}"),
                            "imgly_transform_facebook_cover": intl.msgJoint("msg: imageEditor.asset.imglyTransformsFacebook.cover {Cover}"),
                            "imgly_transform_facebook_profile": intl.msgJoint("msg: imageEditor.asset.imglyTransformsFacebook.profilePic {Profile Pic}")
                        }
                    }
                },
                "placeholder": {
                    "width": intl.msgJoint("msg: imageEditor.placeholder.width {w}"),
                    "height": intl.msgJoint("msg: imageEditor.placeholder.height {h}")
                }
            },
            "filter": {
                "asset": {
                    "identity": intl.msgJoint("msg: imageEditor.filterAsset.none {None}"),
                    "imgly_lut_celsius": intl.msgJoint("msg: imageEditor.filterAsset.celsius {Celsius}"),
                    "imgly_lut_chest": intl.msgJoint("msg: imageEditor.filterAsset.chest {Chest}"),
                    "imgly_lut_fixie": intl.msgJoint("msg: imageEditor.filterAsset.fixie {Fixie}"),
                    "imgly_lut_fridge": intl.msgJoint("msg: imageEditor.filterAsset.fridge {Fridge}"),
                    "imgly_lut_front": intl.msgJoint("msg: imageEditor.filterAsset.front {Front}"),
                    "imgly_lut_k2": intl.msgJoint("msg: imageEditor.filterAsset.k2 {K2}"),
                    "imgly_lut_mellow": intl.msgJoint("msg: imageEditor.filterAsset.mellow {Mellow}"),
                    "imgly_lut_sin": intl.msgJoint("msg: imageEditor.filterAsset.sin {Sin}"),
                    "imgly_lut_texas": intl.msgJoint("msg: imageEditor.filterAsset.texas {Texas}"),
                    "imgly_lut_ad1920": intl.msgJoint("msg: imageEditor.filterAsset.AD1920 {1920 A.D.}"),
                    "imgly_lut_ancient": intl.msgJoint("msg: imageEditor.filterAsset.ancient {Ancient}"),
                    "imgly_lut_bleached": intl.msgJoint("msg: imageEditor.filterAsset.bleached {Bleached}"),
                    "imgly_lut_bleachedblue": intl.msgJoint("msg: imageEditor.filterAsset.bleachedBlue {Bleached Blue}"),
                    "imgly_lut_blues": intl.msgJoint("msg: imageEditor.filterAsset.blues {Blues}"),
                    "imgly_lut_blueshadows": intl.msgJoint("msg: imageEditor.filterAsset.blueShadows {Blue Shadows}"),
                    "imgly_lut_breeze": intl.msgJoint("msg: imageEditor.filterAsset.breeze {Breeze}"),
                    "imgly_lut_bw": intl.msgJoint("msg: imageEditor.filterAsset.blackAndWhite {B & W}"),
                    "imgly_lut_classic": intl.msgJoint("msg: imageEditor.filterAsset.classic {Classic}"),
                    "imgly_lut_colorful": intl.msgJoint("msg: imageEditor.filterAsset.colorful {Colourful}"),
                    "imgly_lut_cool": intl.msgJoint("msg: imageEditor.filterAsset.cool {Cool}"),
                    "imgly_lut_cottoncandy": intl.msgJoint("msg: imageEditor.filterAsset.cottonCandy {Cotton Candy}"),
                    "imgly_lut_creamy": intl.msgJoint("msg: imageEditor.filterAsset.creamy {Creamy}"),
                    "imgly_lut_eighties": intl.msgJoint("msg: imageEditor.filterAsset.eighties {Eighties}"),
                    "imgly_lut_elder": intl.msgJoint("msg: imageEditor.filterAsset.elder {Elder}"),
                    "imgly_lut_evening": intl.msgJoint("msg: imageEditor.filterAsset.evening {Evening}"),
                    "imgly_lut_fall": intl.msgJoint("msg: imageEditor.filterAsset.fall {Fall}"),
                    "imgly_lut_food": intl.msgJoint("msg: imageEditor.filterAsset.food {Food}"),
                    "imgly_lut_glam": intl.msgJoint("msg: imageEditor.filterAsset.glam {Glam}"),
                    "imgly_lut_gobblin": intl.msgJoint("msg: imageEditor.filterAsset.gobblin {Gobblin}"),
                    "imgly_lut_highcarb": intl.msgJoint("msg: imageEditor.filterAsset.highCarb {High Carb}"),
                    "imgly_lut_highcontrast": intl.msgJoint("msg: imageEditor.filterAsset.highContrast {High Contrast}"),
                    "imgly_lut_k1": intl.msgJoint("msg: imageEditor.filterAsset.k1 {K1}"),
                    "imgly_lut_k6": intl.msgJoint("msg: imageEditor.filterAsset.k6 {K6}"),
                    "imgly_lut_kdynamic": intl.msgJoint("msg: imageEditor.filterAsset.kDynamic {KDynamic}"),
                    "imgly_lut_keen": intl.msgJoint("msg: imageEditor.filterAsset.keen {Keen}"),
                    "imgly_lut_lenin": intl.msgJoint("msg: imageEditor.filterAsset.lenin {Lenin}"),
                    "imgly_lut_litho": intl.msgJoint("msg: imageEditor.filterAsset.litho {Litho}"),
                    "imgly_lut_lomo100": intl.msgJoint("msg: imageEditor.filterAsset.lomo100 {Lomo 100}"),
                    "imgly_lut_lucid": intl.msgJoint("msg: imageEditor.filterAsset.lucid {Lucid}"),
                    "imgly_lut_neat": intl.msgJoint("msg: imageEditor.filterAsset.neat {Neat}"),
                    "imgly_lut_nogreen": intl.msgJoint("msg: imageEditor.filterAsset.noGreen {No Green}"),
                    "imgly_lut_orchid": intl.msgJoint("msg: imageEditor.filterAsset.orchid {Orchid}"),
                    "imgly_lut_pale": intl.msgJoint("msg: imageEditor.filterAsset.pale {Pale}"),
                    "imgly_lut_pitched": intl.msgJoint("msg: imageEditor.filterAsset.pitched {Pitched}"),
                    "imgly_lut_plate": intl.msgJoint("msg: imageEditor.filterAsset.plate {Plate}"),
                    "imgly_lut_pola669": intl.msgJoint("msg: imageEditor.filterAsset.pola669 {Pola 669}"),
                    "imgly_lut_polasx": intl.msgJoint("msg: imageEditor.filterAsset.polaSX {Pola SX}"),
                    "imgly_lut_pro400": intl.msgJoint("msg: imageEditor.filterAsset.pro400 {Pro 400}"),
                    "imgly_lut_quozi": intl.msgJoint("msg: imageEditor.filterAsset.quozi {Quozi}"),
                    "imgly_lut_sepiahigh": intl.msgJoint("msg: imageEditor.filterAsset.sepiaHigh {Sepia High}"),
                    "imgly_lut_settled": intl.msgJoint("msg: imageEditor.filterAsset.settled {Settled}"),
                    "imgly_lut_seventies": intl.msgJoint("msg: imageEditor.filterAsset.seventies {Seventies}"),
                    "imgly_lut_soft": intl.msgJoint("msg: imageEditor.filterAsset.soft {Soft}"),
                    "imgly_lut_steel": intl.msgJoint("msg: imageEditor.filterAsset.steel {Steel}"),
                    "imgly_lut_summer": intl.msgJoint("msg: imageEditor.filterAsset.summer {Summer}"),
                    "imgly_lut_sunset": intl.msgJoint("msg: imageEditor.filterAsset.sunset {Sunset}"),
                    "imgly_lut_tender": intl.msgJoint("msg: imageEditor.filterAsset.tender {Tender}"),
                    "imgly_lut_twilight": intl.msgJoint("msg: imageEditor.filterAsset.twilight {Twilight}"),
                    "imgly_lut_winter": intl.msgJoint("msg: imageEditor.filterAsset.winter {Winter}"),
                    "imgly_lut_x400": intl.msgJoint("msg: imageEditor.filterAsset.x400 {X400}"),
                    "imgly_duotone_desert": intl.msgJoint("msg: imageEditor.filterAsset.desert {Desert}"),
                    "imgly_duotone_peach": intl.msgJoint("msg: imageEditor.filterAsset.Peach {Peach}"),
                    "imgly_duotone_clash": intl.msgJoint("msg: imageEditor.filterAsset.clash {Clash}"),
                    "imgly_duotone_plum": intl.msgJoint("msg: imageEditor.filterAsset.plum {Plum}"),
                    "imgly_duotone_breezy": intl.msgJoint("msg: imageEditor.filterAsset.breezy {Breezy}"),
                    "imgly_duotone_deepblue": intl.msgJoint("msg: imageEditor.filterAsset.deepBlue {Deep blue}"),
                    "imgly_duotone_frog": intl.msgJoint("msg: imageEditor.filterAsset.frog {Frog}"),
                    "imgly_duotone_sunset": intl.msgJoint("msg: imageEditor.filterAsset.sunset {Sunset}")
                },
                "title": {
                    "name": intl.msgJoint("msg: imageEditor.filterTitle.filters {Filters}")
                }
            },
            "adjustments": {
                "button": {
                    "reset": intl.msgJoint("msg: imageEditor.adjustments.resetDefaults {Reset Default}")
                },
                "title": {
                    "name": intl.msgJoint("msg: imageEditor.adjustments.title.adjust {Adjust}"),
                    "basics": intl.msgJoint("msg: imageEditor.adjustments.title.basics {Basics}"),
                    "refinements": intl.msgJoint("msg: imageEditor.adjustments.title.refinements {Refinements}")
                },
                "text": {
                    "brightness": intl.msgJoint("msg: imageEditor.adjustments.text.brightness {Brightness}"),
                    "saturation": intl.msgJoint("msg: imageEditor.adjustments.text.saturation {Saturation}"),
                    "contrast": intl.msgJoint("msg: imageEditor.adjustments.text.contrast {Contrast}"),
                    "gamma": intl.msgJoint("msg: imageEditor.adjustments.text.gamma {Gamma}"),
                    "clarity": intl.msgJoint("msg: imageEditor.adjustments.text.clarity {Clarity}"),
                    "exposure": intl.msgJoint("msg: imageEditor.adjustments.text.exposure {Exposure}"),
                    "shadows": intl.msgJoint("msg: imageEditor.adjustments.text.shadows {Shadows}"),
                    "highlights": intl.msgJoint("msg: imageEditor.adjustments.text.highlights {Highlights}"),
                    "whites" : intl.msgJoint("msg: imageEditor.adjustments.text.whites {Whites}"),
                    "blacks" : intl.msgJoint("msg: imageEditor.adjustments.text.blacks {Blacks}"),
                    "temperature" : intl.msgJoint("msg: imageEditor.adjustments.text.temperature {Temperature}"),
                    "sharpness": intl.msgJoint("msg: imageEditor.adjustments.text.sharpness {Sharpness}"),
                }
            },
            "focus": {
                "title": {
                    "name": intl.msgJoint("msg: imageEditor.focus.title {Focus}")
                },
                "button": {
                    "none": intl.msgJoint("msg: imageEditor.focus.button.none {None}"),
                    "radial": intl.msgJoint("msg: imageEditor.focus.button.radial {Radial}"),
                    "mirrored": intl.msgJoint("msg: imageEditor.focus.button.mirrored {Mirrored}"),
                    "linear": intl.msgJoint("msg: imageEditor.focus.button.linear {Linear}"),
                    "gaussian": intl.msgJoint("msg: imageEditor.focus.button.gaussian {Gaussian}")
                }
            },
            "text": {
                "title": {
                    "name": intl.msgJoint("msg: imageEditor.text.title.name {Text}"),
                    "font": intl.msgJoint("msg: imageEditor.text.title.font {Font}"),
                    "size": intl.msgJoint("msg: imageEditor.text.title.size {Size}"),
                    "spacing": intl.msgJoint("msg: imageEditor.text.title.spacing {Spacing}"),
                    "line": intl.msgJoint("msg: imageEditor.text.title.line {Line}"),
                    "background": intl.msgJoint("msg: imageEditor.text.title.background {Background}")
                },
                "placeholder": {
                    "defaultText": intl.msgJoint("msg: imageEditor.text.placeholder.defaultText {Double-click to edit!}")
                },
                "button": {
                    "new": intl.msgJoint("msg: imageEditor.text.button.newText {New Text}")
                }
            },
            "textdesign": {
                "title": {
                    "name": intl.msgJoint("msg: imageEditor.textdesign.title.name {Text Design}"),
                    "input": intl.msgJoint("msg: imageEditor.textdesign.title.input {Text}")
                },
                "button": {
                    "invert": intl.msgJoint("msg: imageEditor.textdesign.button.textAsMask {Text as mask}"),
                    "new": intl.msgJoint("msg: imageEditor.textdesign.button.newTextDesign {New text design}")
                }
            },
            "sticker": {
                "title": {
                    "name": intl.msgJoint("msg: imageEditor.sticker.title.name {Stickers}"),
                    "opacity": intl.msgJoint("msg: imageEditor.sticker.title.opacity {Opacity}")
                },
                "text": {
                    "stickerLoadingError": intl.msgJoint("msg: imageEditor.sticker.text.stickerLoadingError {Failed to load sticker ${path}.}")
                },
                "button": {
                    "replace": intl.msgJoint("msg: imageEditor.sticker.button.replace {Replace}"),
                    "new": intl.msgJoint("msg: imageEditor.sticker.button.newSticker {New Sticker}"),
                    "fill": intl.msgJoint("msg: imageEditor.sticker.button.fill {Fill}")
                },
                "asset": {
                    "imgly_sticker_emoticons": intl.msgJoint("msg: imageEditor.sticker.asset.emoticons {Emoticons}"),
                    "imgly_sticker_emoticons_alien": intl.msgJoint("msg: imageEditor.sticker.asset.alien {Alien}"),
                    "imgly_sticker_emoticons_angel": intl.msgJoint("msg: imageEditor.sticker.asset.angel {Angel}"),
                    "imgly_sticker_emoticons_angry": intl.msgJoint("msg: imageEditor.sticker.asset.angry {Angry}"),
                    "imgly_sticker_emoticons_anxious": intl.msgJoint("msg: imageEditor.sticker.asset.anxious {Anxious}"),
                    "imgly_sticker_emoticons_asleep": intl.msgJoint("msg: imageEditor.sticker.asset.asleep {Asleep}"),
                    "imgly_sticker_emoticons_attention": intl.msgJoint("msg: imageEditor.sticker.asset.attention {Attention}"),
                    "imgly_sticker_emoticons_baby_chicken": intl.msgJoint("msg: imageEditor.sticker.asset.babyChicken {Baby Chicken}"),
                    "imgly_sticker_emoticons_batman": intl.msgJoint("msg: imageEditor.sticker.asset.batman {Batman}"),
                    "imgly_sticker_emoticons_beer": intl.msgJoint("msg: imageEditor.sticker.asset.beer {Beer}"),
                    "imgly_sticker_emoticons_black": intl.msgJoint("msg: imageEditor.sticker.asset.black {Black}"),
                    "imgly_sticker_emoticons_blue": intl.msgJoint("msg: imageEditor.sticker.asset.blue {Blue}"),
                    "imgly_sticker_emoticons_blush": intl.msgJoint("msg: imageEditor.sticker.asset.blush {Blush}"),
                    "imgly_sticker_emoticons_boxer": intl.msgJoint("msg: imageEditor.sticker.asset.boxer {Boxer}"),
                    "imgly_sticker_emoticons_business": intl.msgJoint("msg: imageEditor.sticker.asset.business {Business}"),
                    "imgly_sticker_emoticons_chicken": intl.msgJoint("msg: imageEditor.sticker.asset.chicken {Chicken}"),
                    "imgly_sticker_emoticons_cool": intl.msgJoint("msg: imageEditor.sticker.asset.cool {Cool}"),
                    "imgly_sticker_emoticons_cry": intl.msgJoint("msg: imageEditor.sticker.asset.cry {Cry}"),
                    "imgly_sticker_emoticons_deceased": intl.msgJoint("msg: imageEditor.sticker.asset.deceased {Deceased}"),
                    "imgly_sticker_emoticons_devil": intl.msgJoint("msg: imageEditor.sticker.asset.devil {Devil}"),
                    "imgly_sticker_emoticons_duckface": intl.msgJoint("msg: imageEditor.sticker.asset.duckface {Duckface}"),
                    "imgly_sticker_emoticons_furious": intl.msgJoint("msg: imageEditor.sticker.asset.furious {Furious}"),
                    "imgly_sticker_emoticons_grin": intl.msgJoint("msg: imageEditor.sticker.asset.grin {Grin}"),
                    "imgly_sticker_emoticons_guitar": intl.msgJoint("msg: imageEditor.sticker.asset.guitar {Guitar}"),
                    "imgly_sticker_emoticons_harry_potter": intl.msgJoint("msg: imageEditor.sticker.asset.harryPotter {Harry Potter}"),
                    "imgly_sticker_emoticons_hippie": intl.msgJoint("msg: imageEditor.sticker.asset.hippie {Hippie}"),
                    "imgly_sticker_emoticons_hitman": intl.msgJoint("msg: imageEditor.sticker.asset.hitman {Hitman}"),
                    "imgly_sticker_emoticons_humourous": intl.msgJoint("msg: imageEditor.sticker.asset.humourous {Humourous}"),
                    "imgly_sticker_emoticons_idea": intl.msgJoint("msg: imageEditor.sticker.asset.idea {Idea}"),
                    "imgly_sticker_emoticons_impatient": intl.msgJoint("msg: imageEditor.sticker.asset.impatient {Impatient}"),
                    "imgly_sticker_emoticons_kiss": intl.msgJoint("msg: imageEditor.sticker.asset.kiss {Kiss}"),
                    "imgly_sticker_emoticons_kisses": intl.msgJoint("msg: imageEditor.sticker.asset.kisses {Kisses}"),
                    "imgly_sticker_emoticons_laugh": intl.msgJoint("msg: imageEditor.sticker.asset.laugh {Laugh}"),
                    "imgly_sticker_emoticons_loud_cry": intl.msgJoint("msg: imageEditor.sticker.asset.loudCry {Loud Cry}"),
                    "imgly_sticker_emoticons_loving": intl.msgJoint("msg: imageEditor.sticker.asset.loving {Loving}"),
                    "imgly_sticker_emoticons_masked": intl.msgJoint("msg: imageEditor.sticker.asset.masked {Masked}"),
                    "imgly_sticker_emoticons_music": intl.msgJoint("msg: imageEditor.sticker.asset.music {Music}"),
                    "imgly_sticker_emoticons_nerd": intl.msgJoint("msg: imageEditor.sticker.asset.nerd {Nerd}"),
                    "imgly_sticker_emoticons_ninja": intl.msgJoint("msg: imageEditor.sticker.asset.ninja {Ninja}"),
                    "imgly_sticker_emoticons_not_speaking_to_you": intl.msgJoint("msg: imageEditor.sticker.asset.notSpeaking {Not speaking to you}"),
                    "imgly_sticker_emoticons_pig": intl.msgJoint("msg: imageEditor.sticker.asset.pig {Pig}"),
                    "imgly_sticker_emoticons_pumpkin": intl.msgJoint("msg: imageEditor.sticker.asset.pumpkin {Pumpkin}"),
                    "imgly_sticker_emoticons_question": intl.msgJoint("msg: imageEditor.sticker.asset.question {Question}"),
                    "imgly_sticker_emoticons_rabbit": intl.msgJoint("msg: imageEditor.sticker.asset.rabbit {Rabbit}"),
                    "imgly_sticker_emoticons_sad": intl.msgJoint("msg: imageEditor.sticker.asset.sad {Sad}"),
                    "imgly_sticker_emoticons_sick": intl.msgJoint("msg: imageEditor.sticker.asset.sick {Sick}"),
                    "imgly_sticker_emoticons_skateboard": intl.msgJoint("msg: imageEditor.sticker.asset.skateboard {Skateboard}"),
                    "imgly_sticker_emoticons_skull": intl.msgJoint("msg: imageEditor.sticker.asset.skull {Skull}"),
                    "imgly_sticker_emoticons_sleepy": intl.msgJoint("msg: imageEditor.sticker.asset.sleepy {Sleepy}"),
                    "imgly_sticker_emoticons_smile": intl.msgJoint("msg: imageEditor.sticker.asset.smile {Smile}"),
                    "imgly_sticker_emoticons_smoking": intl.msgJoint("msg: imageEditor.sticker.asset.smoking {Smoking}"),
                    "imgly_sticker_emoticons_sobbing": intl.msgJoint("msg: imageEditor.sticker.asset.sobbing {Sobbing}"),
                    "imgly_sticker_emoticons_star": intl.msgJoint("msg: imageEditor.sticker.asset.star {Star}"),
                    "imgly_sticker_emoticons_steaming_furious": intl.msgJoint("msg: imageEditor.sticker.asset.steamingFurious {Steaming Furious}"),
                    "imgly_sticker_emoticons_sunbathing": intl.msgJoint("msg: imageEditor.sticker.asset.sunbathing {Sunbathing}"),
                    "imgly_sticker_emoticons_tired": intl.msgJoint("msg: imageEditor.sticker.asset.tired {Tired}"),
                    "imgly_sticker_emoticons_tongue_out_wink": intl.msgJoint("msg: imageEditor.sticker.asset.tongueOutWink {Tongue out wink}"),
                    "imgly_sticker_emoticons_wave": intl.msgJoint("msg: imageEditor.sticker.asset.wave {Wave}"),
                    "imgly_sticker_emoticons_wide_grin": intl.msgJoint("msg: imageEditor.sticker.asset.wideGrin {Wide Grin}"),
                    "imgly_sticker_emoticons_wink": intl.msgJoint("msg: imageEditor.sticker.asset.wink {Wink}"),
                    "imgly_sticker_emoticons_wrestler": intl.msgJoint("msg: imageEditor.sticker.asset.wrestler {Wrestler}"),
                    "imgly_sticker_shapes": intl.msgJoint("msg: imageEditor.sticker.asset.shapes {Shapes}"),
                    "imgly_sticker_shapes_arrow_02": intl.msgJoint("msg: imageEditor.sticker.asset.arrow1 {Arrow 1}"),
                    "imgly_sticker_shapes_arrow_03": intl.msgJoint("msg: imageEditor.sticker.asset.arrow2 {Arrow 2}"),
                    "imgly_sticker_shapes_badge_01": intl.msgJoint("msg: imageEditor.sticker.asset.badge1 {Badge 1}"),
                    "imgly_sticker_shapes_badge_11": intl.msgJoint("msg: imageEditor.sticker.asset.badge5 {Badge 5}"),
                    "imgly_sticker_shapes_badge_12": intl.msgJoint("msg: imageEditor.sticker.asset.badge6 {Badge 6}"),
                    "imgly_sticker_shapes_badge_13": intl.msgJoint("msg: imageEditor.sticker.asset.badge7 {Badge 7}"),
                    "imgly_sticker_shapes_badge_15": intl.msgJoint("msg: imageEditor.sticker.asset.badge8 {Badge 8}"),
                    "imgly_sticker_shapes_badge_18": intl.msgJoint("msg: imageEditor.sticker.asset.badge9 {Badge 9}"),
                    "imgly_sticker_shapes_badge_19": intl.msgJoint("msg: imageEditor.sticker.asset.badge10 {Badge 10}"),
                    "imgly_sticker_shapes_badge_20": intl.msgJoint("msg: imageEditor.sticker.asset.badge11 {Badge 11}"),
                    "imgly_sticker_shapes_badge_28": intl.msgJoint("msg: imageEditor.sticker.asset.badge12 {Badge 12}"),
                    "imgly_sticker_shapes_badge_32": intl.msgJoint("msg: imageEditor.sticker.asset.badge13 {Badge 13}"),
                    "imgly_sticker_shapes_badge_35": intl.msgJoint("msg: imageEditor.sticker.asset.badge14 {Badge 14}"),
                    "imgly_sticker_shapes_badge_36": intl.msgJoint("msg: imageEditor.sticker.asset.badge15 {Badge 15}"),
                    "imgly_sticker_shapes_badge_04": intl.msgJoint("msg: imageEditor.sticker.asset.badge2 {Badge 2}"),
                    "imgly_sticker_shapes_badge_06": intl.msgJoint("msg: imageEditor.sticker.asset.badge3 {Badge 3}"),
                    "imgly_sticker_shapes_badge_08": intl.msgJoint("msg: imageEditor.sticker.asset.badge4 {Badge 4}"),
                    "imgly_sticker_shapes_spray_01": intl.msgJoint("msg: imageEditor.sticker.asset.spray1 {Spray 1}"),
                    "imgly_sticker_shapes_spray_03": intl.msgJoint("msg: imageEditor.sticker.asset.spray2 {Spray 2}"),
                    "imgly_sticker_shapes_spray_04": intl.msgJoint("msg: imageEditor.sticker.asset.spray3 {Spray 3}")
                }
            },
            "brush": {
                "title": {
                    "name": intl.msgJoint("msg: imageEditor.brushTitle.name {Brush}"),
                    "width": intl.msgJoint("msg: imageEditor.brushTitle.width {Width}"),
                    "hardness": intl.msgJoint("msg: imageEditor.brushTitle.hardness {Hardness}"),
                    "settings": intl.msgJoint("msg: imageEditor.brushTitle.brushSettings {Brush Settings}")
                }
            },
            "frame": {
                "title": {
                    "name": intl.msgJoint("msg: imageEditor.frameTitle.frames {Frames}"),
                    "opacity": intl.msgJoint("msg: imageEditor.frameTitle.opacity {Opacity}"),
                    "width": intl.msgJoint("msg: imageEditor.frameTitle.width {Width}")
                },
                "button": {
                    "fill": intl.msgJoint("msg: imageEditor.frameButton.fill {Fill}"),
                    "replace": intl.msgJoint("msg: imageEditor.frameButton.replace {Replace}"),
                    "none": intl.msgJoint("msg: imageEditor.frameButton.none {None}")
                },
                "asset": {
                    "imgly_frame_dia": intl.msgJoint("msg: imageEditor.frameAsset.dia {Dias}"),
                    "imgly_frame_art_decor": intl.msgJoint("msg: imageEditor.frameAsset.artDecor {Art Decor}"),
                    "imgly_frame_black_passepartout": intl.msgJoint("msg: imageEditor.frameAsset.blackPassepartout {Black Frame}"),
                    "imgly_frame_lowpoly_shadow": intl.msgJoint("msg: imageEditor.frameAsset.lowPoly {Low Poly}"),
                    "imgly_frame_wood_passepartout": intl.msgJoint("msg: imageEditor.frameAsset.woodPassepartout {Wood Frame}")
                }
            },
            "overlay": {
                "title": {
                    "name": intl.msgJoint("msg: imageEditor.overlayTitle.name {Overlays}"),
                    "blending": intl.msgJoint("msg: imageEditor.overlayTitle.blending {Blending}"),
                    "none": intl.msgJoint("msg: imageEditor.overlayTitle.none {None}"),
                    "normal": intl.msgJoint("msg: imageEditor.overlayTitle.normal {Normal}"),
                    "overlay": intl.msgJoint("msg: imageEditor.overlayTitle.overlay {Overlay}"),
                    "hardLight": intl.msgJoint("msg: imageEditor.overlayTitle.hardLight {Hard Light}"),
                    "softLight": intl.msgJoint("msg: imageEditor.overlayTitle.softLight {Soft Light}"),
                    "multiply": intl.msgJoint("msg: imageEditor.overlayTitle.multiply {Multiply}"),
                    "darken": intl.msgJoint("msg: imageEditor.overlayTitle.darken {Darken}"),
                    "lighten": intl.msgJoint("msg: imageEditor.overlayTitle.lighten {Lighten}"),
                    "screen": intl.msgJoint("msg: imageEditor.overlayTitle.screen {Screen}"),
                    "colorBurn": intl.msgJoint("msg: imageEditor.overlayTitle.colorBurn {Colour Burn}")
                },
                "button": {
                    "none": intl.msgJoint("msg: imageEditor.overlayButton.none {None}")
                },
                "asset": {
                    "imgly_overlay_bokeh": intl.msgJoint("msg: imageEditor.overlayAsset.bokeh {Bokeh}"),
                    "imgly_overlay_chop": intl.msgJoint("msg: imageEditor.overlayAsset.chop {Chop}"),
                    "imgly_overlay_clouds": intl.msgJoint("msg: imageEditor.overlayAsset.clouds {Clouds}"),
                    "imgly_overlay_golden": intl.msgJoint("msg: imageEditor.overlayAsset.golden {Golden}"),
                    "imgly_overlay_grain": intl.msgJoint("msg: imageEditor.overlayAsset.grain {Grain}"),
                    "imgly_overlay_hearts": intl.msgJoint("msg: imageEditor.overlayAsset.hearts {Hearts}"),
                    "imgly_overlay_lightleak1": intl.msgJoint("msg: imageEditor.overlayAsset.lightLeak1 {Light Leak 1}"),
                    "imgly_overlay_lightleak2": intl.msgJoint("msg: imageEditor.overlayAsset.lightLeak2 {Light Leak 2}"),
                    "imgly_overlay_metal": intl.msgJoint("msg: imageEditor.overlayAsset.metal {Metal}"),
                    "imgly_overlay_mosaic": intl.msgJoint("msg: imageEditor.overlayAsset.mosaic {Mosaic}"),
                    "imgly_overlay_painting": intl.msgJoint("msg: imageEditor.overlayAsset.painting {Painting}"),
                    "imgly_overlay_paper": intl.msgJoint("msg: imageEditor.overlayAsset.paper {Paper}"),
                    "imgly_overlay_rain": intl.msgJoint("msg: imageEditor.overlayAsset.rain {Rain}"),
                    "imgly_overlay_vintage": intl.msgJoint("msg: imageEditor.overlayAsset.vintage {Vintage}"),
                    "imgly_overlay_wall1": intl.msgJoint("msg: imageEditor.overlayAsset.wall {Wall}"),
                    "imgly_overlay_wall2": intl.msgJoint("msg: imageEditor.overlayAsset.wall2 {Wall 2}"),
                    "imgly_overlay_wood": intl.msgJoint("msg: imageEditor.overlayAsset.wood {Wood}")
                }
            }
        }
    };
};
