import { TINY_MCE_FOCUS_LAST_NODE } from "../actionTypes";
import { makeApplyEditorChangesReducer } from "./helpers/helpers";
import { putCursorAtTheEndOfEditor } from "../editorUtils/methods/helpers/selection";

import type { TinyMceEpicUpdater } from "../flowTypes";

const onFocusLastNodeUpdater: TinyMceEpicUpdater = {
    conditions: [TINY_MCE_FOCUS_LAST_NODE],
    reducer: makeApplyEditorChangesReducer(({ state, scope, editor }) => {
        putCursorAtTheEndOfEditor(editor);

        return { state, scope };
    })
};

export {
    onFocusLastNodeUpdater
};
