import { always, evolve } from 'ramda';
import type { $Keys } from "utility-types";

import kind from './kind';
import * as actionTypes from './actionTypes';
import { DEFAULT_HEIGHT, DEFAULT_WIDTH, defaultState } from './defaultState';

import { makeCombineReducer, makeDefaultStateReducers, makeComponentBaseReducers } from "../../../redux/makeReducer/index";
import { PROP_PANEL_CHANGE_COMPONENT_HEIGHT, PROP_PANEL_CHANGE_COMPONENT_WIDTH } from '../../Workspace/actionTypes';
import { toHex } from '../../../mappers/color';
import { TOGGLE_MOBILE_DOWN_ACTION } from "../../PropertiesPanel/view/MobileView/actions";
import mobileDownReducer from '../../PropertiesPanel/view/MobileView/mobileDownReducer';

import type { SvgComponent } from './flowTypes';

export default makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(kind, DEFAULT_WIDTH, DEFAULT_HEIGHT),
        ...makeDefaultStateReducers(defaultState)
    },
    handleActions: {
        [actionTypes.SVG_COLOR_SWATCH_ITEM_CHANGED]: (component, { payload: { fromColor, color } }) => ({
            ...component,
            colors: component.colors.map(item => (item.fromColor === fromColor ? { ...item, toColor: toHex(color) } : item))
        }),
        [actionTypes.SVG_COLOR_SWATCH_ITEM_CHANGED_AUTO_COLOR]: (component, { payload: { fromColor, themeColor } }) => ({
            ...component,
            colors: component.colors.map(item => (item.fromColor === fromColor ? { ...item, toThemeColor: themeColor } : item)),
            useOriginalColors: false,
        }),
        [actionTypes.SVG_EMPTY_SRC]: evolve({
            asset: { url: always(defaultState.asset.url) },
            colors: always(defaultState.colors),
            rawSvg: always(defaultState.rawSvg),
            svgJson: always(defaultState.svgJson)
        }),
        [actionTypes.SVG_MOBILE_CLEAR_FORMATTING]: removeProperty('widthInMobile'),
        [actionTypes.SVG_MOBILE_SIZE_CHANGE]: setPropertyToPayload('widthInMobile'),
        [actionTypes.SVG_PP_ON_CLICK_ACTION_CHANGED]: setPropertyTo('linkAction', null),
        [actionTypes.SVG_RESET_COLORS]: (component) => ({
            ...component, colors: component.colors.map(({ fromColor }) => ({ fromColor, toColor: fromColor }))
        }),
        [actionTypes.SVG_RESET_COLORS_AUTO_COLOR]: (component) => ({
            ...component,
            colors: component.colors.map(({ fromColor, toColor }) => ({ fromColor, toColor })),
            useOriginalColors: true,
        }),
        [actionTypes.SVG_SET_LINK]: setPropertyToPayloadProperty('linkAction'),
        [actionTypes.SVG_SET_OPACITY]: setPropertyToPayload('opacity'),
        [actionTypes.SVG_SET_SEO]: setPropertyToPayload('seo'),
        [actionTypes.SVG_UPDATE_ON_REPLACE]: (component, { payload }) => {
            return { ...component, ...payload, height: (component.width * (payload.asset.height / payload.asset.width)) };
        },
        [actionTypes.SVG_UPDATE_ON_REPLACE_MISSING]: (component, { payload }) => {
            if (component.asset.url === payload.asset.url) {
                return { ...component, ...payload, height: (component.width * (payload.asset.height / payload.asset.width)) };
            }
            return component;
        },
        [PROP_PANEL_CHANGE_COMPONENT_HEIGHT]: (component: SvgComponent, { payload: { value } }: any): SvgComponent => {
            const { height, width } = component.asset;
            return { ...component, width: (value * (width / height)) };
        },
        [PROP_PANEL_CHANGE_COMPONENT_WIDTH]: (component: SvgComponent, { payload: { value } }: any): SvgComponent => {
            const { height, width } = component.asset;
            return { ...component, height: (value * (height / width)) };
        },
        [TOGGLE_MOBILE_DOWN_ACTION]: mobileDownReducer,
    }
});

export function setPropertyTo(propName: $Keys<SvgComponent>, value: any) {
    return (component: SvgComponent) => ({ ...component, [propName]: value });
}

export function setPropertyToPayload(propName: $Keys<SvgComponent>) {
    return (component: SvgComponent, { payload }: Action<any>) => ({ ...component, [propName]: payload });
}

export function setPropertyToPayloadProperty(propName: $Keys<SvgComponent>) {
    return (component: SvgComponent, { payload }: Action<any>) => ({ ...component, [propName]: payload[propName] });
}

export function removeProperty(propName: $Keys<SvgComponent>) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return ({ [propName]: _, ...rest }: SvgComponent) => rest;
}
