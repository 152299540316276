import type { CmpSpecificStyles } from '../../Preview/flowTypes';
import type { BackgroundComponent } from './flowTypes';
import { getScaledStylesForContainers } from "../../../utils/getScaledStyles";
import { BackgroundSize } from "../../presentational/BackgroundImageSettings/options";

export const makeGetBackgroundSpecificStyles = () =>
    ({ component }: CmpSpecificStyles<BackgroundComponent>): string => {
        const scaledStyles = getScaledStylesForContainers(component);
        let bgSpecificStyle = `${scaledStyles}`;

        if (component.mobileSettings) {
            if (component.mobileSettings.size !== BackgroundSize.FILL) {
                const mobileSize = component.mobileSettings.size === BackgroundSize.ZOOM
                    ? 'auto' : component.mobileSettings.size;
                bgSpecificStyle += `.mobileV div[data-id='${component.id}'] > div > div > div[data-opacity] {
                    background-size: ${mobileSize} !important;
                }`;
                bgSpecificStyle += `.mobileV div[data-id='${component.id}'] > div > div > div > video {
                    object-fit: ${mobileSize} !important;
                    opacity: 1 !important;
                }`;
            } else {
                const mobileBgPosition = component.mobileSettings.position;
                if (mobileBgPosition && mobileBgPosition.length === 2) {
                    const [x, y] = component.mobileSettings.position;
                    bgSpecificStyle += ` .mobileV div[data-id='${component.id}'] > div > div > div[data-opacity]:not([data-mve="true"]) {
                        background-position: ${x} ${y} !important;
                    }`;
                    bgSpecificStyle += ` .mobileV div[data-id='${component.id}'] > div > div > div > video:not([data-mve="true"]) {
                        object-position: ${x} ${y} !important;
                    }`;
                }
                bgSpecificStyle += ` .mobileV div[data-id='${component.id}'] > div > div > div > video {
                    opacity: 1 !important;
                }`;
            }
        } else {
            bgSpecificStyle += `.mobileV div[data-id='${component.id}'] > div > div > div > video {
                opacity: 1 !important;
            }`;
        }
        return `${bgSpecificStyle}`;
    };
