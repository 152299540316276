// TODO: the logic should be refactored into presentational/Tooltip

import * as React from 'react';

const AUTO_HIDE_DEFAULT_TIMEOUT = 3000;

type RenderProps = {
    show: boolean,
    onClick: React.MouseEventHandler,
    onMouseEnter: React.MouseEventHandler,
    onMouseLeave: React.MouseEventHandler,
};
type Render = (props: RenderProps) => React.ReactNode;

type Props = {
    render: Render,
    autoHide?: boolean | number,
    show?: boolean, // eslint-disable-line react/no-unused-prop-types
    hide?: Function,
};

type State = {
    show: boolean,
    propShow?: boolean,
};

export class DemoTipHandler extends React.Component<Props, State> {
    static defaultProps = {
        autoHide: AUTO_HIDE_DEFAULT_TIMEOUT,
    };

    static getDerivedStateFromProps({ show: nextPropShow }: Props, state: State) {
        if (nextPropShow === undefined) return state;

        const { propShow: prevPropShow } = state;
        return nextPropShow === prevPropShow
            ? state
            : { show: nextPropShow, propShow: nextPropShow };
    }

    state: State;
    timer: null | undefined | ReturnType<typeof setTimeout>;

    constructor(props: Props) {
        super(props);
        this.state = {
            show: false,
            propShow: false,
        };

        if (props.show !== undefined && ('hide' in props && props.hide === undefined)) {
            throw new Error('DemoTipHandler expects hide() to be passed when show is controlled via props');
        }
    }

    isPropShow(): boolean {
        return this.props.show !== undefined;
    }

    isNotPropShow(): boolean {
        return !this.isPropShow();
    }

    show = () => {
        if (this.isNotPropShow()) {
            this.setState({ show: true });
        }
    }

    hide = () => {
        if (this.isPropShow() && ('hide' in this.props) && this.props.hide) {
            this.props.hide();
        } else {
            this.setState({ show: false });
        }
    }

    setAutoHide() {
        const { autoHide } = this.props;
        if (!autoHide) return;

        // @ts-ignore why boolean is assigned to timeout ?
        const duration: number = Number.isInteger(autoHide) ? autoHide : AUTO_HIDE_DEFAULT_TIMEOUT;

        this.timer = setTimeout(() => {
            if (this.timer && this.state.show) {
                this.hide();
                this.clearAutoHide();
            }
        }, duration);
    }

    clearAutoHide() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }

    onClick = (e: React.MouseEvent<any>) => {
        e.stopPropagation();
    }

    onMouseEnter = () => {
        this.clearAutoHide();
    }

    onMouseLeave = () => {
        this.setAutoHide();
    }

    componentDidMount() {
        if (this.isNotPropShow()) {
            window.addEventListener('click', this.hide);
        }
    }

    componentWillUnmount() {
        if (this.isNotPropShow()) {
            window.removeEventListener('click', this.hide);
        }
    }

    componentDidUpdate() {
        if (this.state.show) {
            this.setAutoHide();
        }
    }

    render() {
        const { show } = this.state;

        return this.props.render({
            show,
            onClick: this.onClick,
            onMouseEnter: this.onMouseEnter,
            onMouseLeave: this.onMouseLeave,
        });
    }
}
