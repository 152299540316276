// @ts-nocheck
/* eslint-disable object-shorthand */
/* eslint-disable no-nested-ternary */

export const calendar = {
    lastWeek: function () {
        switch (this.day()) {
            case 0:
                return this.format(
                    '[La scorsa] dddd [a' +
                    (this.hour() > 1
                        ? 'lle '
                        : this.hour() === 0
                        ? ' '
                        : "ll'") +
                    ']LT'
                );
            default:
                return this.format(
                    '[Lo scorso] dddd [a' +
                    (this.hour() > 1
                        ? 'lle '
                        : this.hour() === 0
                        ? ' '
                        : "ll'") +
                    ']LT'
                );
        }
    },
    lastDay: function () {
        return this.format(
            '[Ieri a' +
            (this.hour() > 1 ? 'lle ' : this.hour() === 0 ? ' ' : "ll'") +
            ']LT'
        );
    },
    sameDay: function () {
        return this.format(
            '[Oggi a' +
            (this.hour() > 1 ? 'lle ' : this.hour() === 0 ? ' ' : "ll'") +
            ']LT'
        );
    },
    nextDay: function () {
        return this.format(
            '[Domani a' +
            (this.hour() > 1 ? 'lle ' : this.hour() === 0 ? ' ' : "ll'") +
            ']LT'
        );
    },
    nextWeek: function () {
        return this.format(
            'dddd [a' +
            (this.hour() > 1 ? 'lle ' : this.hour() === 0 ? ' ' : "ll'") +
            ']LT'
        );
    },
    sameElse: function () {
        return this.format(
            'LL [a' +
            (this.hour() > 1 ? 'lle ' : this.hour() === 0 ? ' ' : "ll'") +
            ']LT'
        );
    },
};
