import React from 'react';
import styles from './Panel.css';
import { injectIntl } from "../../../view/intl/index";

type Props = {
    expanded: boolean,
    intl: any,
    onClick: (arg: any) => any
}

export default injectIntl(({ onClick, expanded, intl }: Props) => {
    const
        expandedTitle = 'msg: common.collapseSideBar {Collapse sidebar}',
        collapsedTitle = 'msg: common.expandSideBar {Expand sidebar}',
        title = expanded ? expandedTitle : collapsedTitle;

    return (
        <div className={styles.resizeHandleContainer} data-title={intl.msgJoint(title)}>
            <div className={styles.resizeHandle} onClick={onClick} onMouseDown={e => e.stopPropagation()}>
                <div />
                <div />
                <div />
            </div>
        </div>
    );
});
