import React, { useEffect, useState } from "react";
import cx from "classnames";

import { Box } from "../../view/reflexbox";
import type { StaticMapsPropsType } from "./flowTypes";
import LazyImage from "../../view/common/LazyImage";
import { geocode } from "./googleMaps";

import styles from "./googleMaps.css";

export const StaticMaps = React.forwardRef((props: StaticMapsPropsType, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { containerSize, downSizeScale, staticMapInputs, className } = props;

    const [coordinatesByGeocode, setCoordinatesByGeocode] = useState({ latitude: 0, longitude: 0 });

    let transformStyles: object = { height: "100%" };

    if (downSizeScale) {
        transformStyles = {
            ...transformStyles,
            transform: `scale(${downSizeScale})`,
            transformOrigin: "0 0"
        };
    }

    const key = "AIzaSyBgnsRB_RRCPik8THe6GC2NttX5WthC8mU",
        maptype = "roadmap",
        height = staticMapInputs.height || 600,
        width = staticMapInputs.width || 600,
        zoom = staticMapInputs.zoom || 16,
        latitude = staticMapInputs.latLng[0] || coordinatesByGeocode.latitude,
        longitude = staticMapInputs.latLng[1] || coordinatesByGeocode.longitude,
        mapSrc =
            latitude &&
            longitude &&
            `https://maps.googleapis.com/maps/api/staticmap?` +
                `zoom=${zoom}` +
                `&size=${width}x${height}` +
                `&maptype=${maptype}` +
                `&markers=color:red%7C${latitude},${longitude}` +
                `&key=${key}`;

    useEffect(() => {
        if (!staticMapInputs.latLng[0] && !staticMapInputs.latLng[1] && staticMapInputs.address) {
            geocode(staticMapInputs.address)
                .then(place => {
                    if (place && place.addressLocation) {
                        const addressLocation = place.addressLocation;
                        setCoordinatesByGeocode({ latitude: addressLocation.lat, longitude: addressLocation.lng });
                    }
                })
                .catch(() => {
                    /* do nothing */
                });
        }
    }, [staticMapInputs]);

    return typeof mapSrc === "string" ? (
        <Box
            ref={ref}
            className={cx(styles.staticMapContainer, className)}
            style={{ width: containerSize.width, height: containerSize.height }}
        >
            <Box style={transformStyles}>
                <LazyImage src={mapSrc} width={width} height={height} />
            </Box>
        </Box>
    ) : null;
});
