import onecolor from 'onecolor';
import { toHsl } from "../../../../../dal/pageMapAdapter/mappers/Base/color";
import * as fontFamilyMapper from "../../../../../dal/pageMapAdapter/mappers/Base/fontFamily";
import type { Shadow } from './flowTypes';
import {
    fromSuperscriptFontSize
} from "../../../App/epics/tinyMceEpic/editorUtils/utils/helpers/convertSuperscriptFontSize";
import { Color } from '../../../../mappers/flowTypes';

const getFontFromStyle = (nodeStyle: { fontFamily: string }): string | number | null => {
    return fontFamilyMapper.back(nodeStyle.fontFamily.split(',')[0].replace(/'/g, '').trim());
};

const isTransparent = (color: string): boolean => {
    if (color === 'transparent') {
        // 'transparent' is not parsed properly by onecolor
        return true;
    } else {
        try {
            return onecolor(color).alpha() === 0;
        } catch (e) {
            return false;
        }
    }
};

const isColor = (string: string): boolean => {
    return /^#/.test(string) || /^rgba?\([\d,]+\)/i.test(string);
};

const getTextShadowFromStyle = (nodeStyle: { textShadow: string }): Shadow => {
    const textShadowParts = nodeStyle.textShadow.replace(/, /g, ',').split(' ');
    const shadow: Shadow = { type: 'web.data.styles.TextShadow' };

    // From: https://developer.mozilla.org/en/docs/Web/CSS/text-shadow
    // text-shadow can have 2 or 3 length parts followed or preceded by an optional color

    // If first or last value is a color, set shadow.color with it and remove from textShadowParts
    if (isColor(textShadowParts[0])) {
        shadow.color = toHsl(textShadowParts.shift()) as Color;
    } else if (isColor(textShadowParts[textShadowParts.length - 1])) {
        shadow.color = toHsl(textShadowParts.pop()) as Color;
    }

    // Remaining values in textShadowParts represent offset-x, offset-y, and optional blur radius -- in that order
    ['left', 'top', 'blur'].forEach(prop => {
        // @ts-ignore
        shadow[prop] = parseInt(textShadowParts.shift() || 0, 10);
    });

    return shadow;
};

export default (inheritedStyle: any, inheritedMceStyle: any = {}) => {
    const style: Style = { atype: 'style' };

    if (inheritedStyle.globalId && inheritedStyle.globalName && !/link/i.test(inheritedStyle.globalName)) {
        style.globalId = inheritedStyle.globalId;
        style.globalName = inheritedStyle.globalName;
    }

    if (inheritedStyle.fontWeight === 'bold') {
        style.bold = true;
    } else if (inheritedStyle.fontWeight === 'normal') {
        style.bold = false;
    }
    if (inheritedStyle.textDecoration === 'underline') {
        style.underline = true;
    }
    if (inheritedStyle.fontStyle === 'italic') {
        style.italic = true;
    } else if (inheritedStyle.fontStyle === 'normal') {
        style.italic = false;
    }
    if (inheritedMceStyle.fontFamily) {
        style.font = getFontFromStyle(inheritedMceStyle);
    }
    if (!style.font && inheritedStyle.fontFamily) {
        style.font = getFontFromStyle(inheritedStyle);
    }
    if (inheritedStyle.fontSize) {
        style.size = parseInt(inheritedStyle.fontSize, 10);
    }
    if (inheritedStyle.color) {
        style.color = toHsl(inheritedStyle.color);
    }
    if (inheritedStyle.backgroundColor) {
        if (!isTransparent(inheritedStyle.backgroundColor)) {
            style.highlight = toHsl(inheritedStyle.backgroundColor);
        }
    }
    if (inheritedStyle.textShadow) {
        if (inheritedStyle.textShadow === 'none') {
            style.shadow = false;
        } else {
            style.shadow = getTextShadowFromStyle(inheritedStyle);
        }
    }
    if (inheritedStyle.verticalAlign) {
        if (inheritedStyle.verticalAlign === 'super') {
            style.superscript = true;
        } else if (inheritedStyle.verticalAlign === 'sub') {
            style.subscript = true;
        }

        // Old WSB rendered super/subscript font size to 60% of saved font size
        if (inheritedStyle.fontSize && (style.superscript || style.subscript)) {
            const fontSize = inheritedStyle.fontSize.trim().replace(/[^\d.]+/g, '');
            style.size = fromSuperscriptFontSize(fontSize);
        }
    }
    if (inheritedStyle.letterSpacing) {
        if (inheritedStyle.letterSpacing === 'normal') {
            style.letterspacing = 0;
        } else {
            // letterSpacing is 10:1 of CSS letter-spacing value
            const letterSpacing = parseFloat(inheritedStyle.letterSpacing) * 10;

            if (isNaN(letterSpacing)) {
                style.letterspacing = 0;
            } else {
                style.letterspacing = Math.floor(letterSpacing + 0.5);
            }
        }
    }

    // No need to push "uninteresting" styles that don't add any styling
    return (Object.keys(style).length > 1) ? style : null;
};
