import StripKind from './Strip/kind';
import SectionKind from './Section/kind';
import BackgroundKind from './Background/kind';
import HoverBoxKind from './HoverBox/kind';

const map = {
    [StripKind]: true,
    [SectionKind]: true,
    [BackgroundKind]: true,
    [HoverBoxKind]: true,
};

export const isBackgroundComponentKind = (kind: string) => map[kind] || false;
