export const
    BG_BACKGROUND_COLOR_SET = 'BG_BACKGROUND_COLOR_SET',
    BG_BACKGROUND_COLOR_SET_AUTO_COLOR = "BG_BACKGROUND_COLOR_SET_AUTO_COLOR",
    BG_BACKGROUND_COLOR_UNSET = 'BG_BACKGROUND_COLOR_UNSET',
    BG_GRADIENT_COLOR_SET = 'BG_GRADIENT_COLOR_SET',
    BG_GRADIENT_COLOR_SET_AUTO_COLOR = 'BG_GRADIENT_COLOR_SET_AUTO_COLOR',
    BG_GRADIENT_COLOR_SET_IF_UNSET = 'BG_GRADIENT_COLOR_SET_IF_UNSET',
    BG_GRADIENT_COLOR_UNSET = 'BG_GRADIENT_COLOR_UNSET',
    BG_GRADIENT_COLOR_UNSET_AUTO_COLOR = 'BG_GRADIENT_COLOR_UNSET_AUTO_COLOR',
    BG_GRADIENT_DIRECTION_CHANGE = 'BG_GRADIENT_DIRECTION_CHANGE',
    BG_GRADIENT_FADE_POINT_CHANGE = 'BG_GRADIENT_FADE_POINT_CHANGE',
    BG_OPACITY_CHANGE = 'BG_OPACITY_CHANGE',
    BG_OPACITY_CHANGE_AUTO_COLOR = 'BG_OPACITY_CHANGE_AUTO_COLOR',
    BG_OPACITY_SET_IF_ZERO = 'BG_OPACITY_SET_IF_ZERO',
    BG_BORDER_STYLE_CHANGE = 'BG_BORDER_STYLE_CHANGE',
    BG_BORDER_STYLE_CHANGE_AUTO_COLOR = 'BG_BORDER_STYLE_CHANGE_AUTO_COLOR',
    BG_BORDER_COLOR_CHANGE = 'BG_BORDER_COLOR_CHANGE',
    BG_BORDER_COLOR_CHANGE_AUTO_COLOR = 'BG_BORDER_COLOR_CHANGE_AUTO_COLOR',
    BG_BORDER_OPACITY_CHANGE = 'BG_BORDER_OPACITY_CHANGE',
    BG_BORDER_WIDTH_CHANGE = 'BG_BORDER_WIDTH_CHANGE',
    BG_BORDER_CORNERS_CHANGE = 'BG_BORDER_CORNERS_CHANGE',
    BG_IMAGE_OPACITY_CHANGE = 'BG_IMAGE_OPACITY_CHANGE',
    BG_CHANGE_ACTION = 'BG_CHANGE_ACTION',
    BG_REMOVE_ACTION = 'BG_REMOVE_ACTION',
    BG_REPEAT_CHANGE_ACTION = 'BG_REPEAT_CHANGE_ACTION',
    BG_OVERLAY_CHANGE_ACTION = 'BG_OVERLAY_CHANGE_ACTION',
    BG_POSITION_CHANGE_ACTION = 'BG_POSITION_CHANGE_ACTION',
    BG_SIZE_CHANGE_ACTION = 'BG_SIZE_CHANGE_ACTION',
    BG_MOBILE_IMAGE_SIZE_CHANGE_ACTION = 'BG_MOBILE_IMAGE_SIZE_CHANGE_ACTION',
    BG_IMAGE_SCROLL_EFFECT_CHANGE = 'BG_IMAGE_SCROLL_EFFECT_CHANGE';
