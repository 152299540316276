import React from 'react';
import { GenerateHtml } from './generateHtml';
import styles from "./Preview.css";
import * as customStyles from './CustomGenerateHtmlStyles.css';
import type { StylesheetsIdToNameMap } from '../../Workspace/epics/stylesheets/flowTypes';
import type { ComponentDependencies } from "../../PropertiesPanel/flowTypes";
import type { ComponentsMap } from "../../../redux/modules/children/workspace/flowTypes";
import type { Attachments } from "../../Workspace/epics/componentAttachements/flowTypes";
import type { DynamicHeightsComponentsInfoMap } from "../flattening/util";
import type { ParentCmpsHeightUpdatesMap } from "./flowTypes";
import type { WebShopMHFEpicState } from "../../oneweb/WebShopMHF/flowTypes";

type Props = {
    show?: boolean,
    data?: Record<string, any> | null,
    mobileData: Record<string, any>,
    globalStyles: Record<string, any>,
    stylesheetsIdToNameMap: StylesheetsIdToNameMap,
    siteMap: any,
    siteSettings: any,
    page: any,
    template: any,
    fonts: any,
    isServerPreview?: boolean,
    domain: string,
    flagPublish?: boolean,
    hdImages?: boolean,
    userHasEcommerceSubscription?: boolean,
    userHasPremiumSubscription?: boolean,
    gaAllowed?: boolean,
    fbPixelAllowed?: boolean,
    componentsDependencies: ComponentDependencies,
    componentsMap: ComponentsMap,
    menuState: Record<string, any>,
    renderViewForTheseComponentsKind?: Record<string, any>,
    isForInserter?: boolean,
    parentCmpsHeightUpdatesMap?: ParentCmpsHeightUpdatesMap,
    dynamicHeightsCmpInfo: DynamicHeightsComponentsInfoMap,
    dynamicHeightComponentAttachments: Attachments,
    childToParentMap: Record<string, any>,
    webshopMHFData: WebShopMHFEpicState;
    config?: Record<string, any>
}

class CustomRender extends GenerateHtml {
    render() {
        return (

            <div
                className={`${styles.body} ${customStyles.pushDownPreviewContainer}`}
                style={{ minWidth: this.props.componentsDependencies?.GALLERY.workspaceBBoxWidth }}
            >
                {this.root ? this.generateHtml(this.root, true) : null}
            </div>
        );
    }
}

export default (props: Props) => {
    if (!props.show) {
        return null;
    }
    return (
        <div className={customStyles.container}>
            <CustomRender {...props} />
        </div>
    );
};
