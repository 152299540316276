import * as R from 'ramda';
import type { Path } from "../mappers/path";
import { getByPath } from "../utils/ramdaEx";
import p from "../utils/pipePath";
import * as mp from "../mappers/path"; // eslint-disable-line
import { getAlpha } from "../components/oneweb/Background/utils/index";

const
    getBackgroundAlpha = (backgroundPath: Path, data: any) => {
        const
            prevSolidColor = getByPath(p(backgroundPath, mp.colorData, mp.color), data),
            prevGradientColor = getByPath(p(backgroundPath, mp.colorData, mp.gradient, mp.color), data);

        let alpha = 0;
        if (prevSolidColor) {
            alpha = getAlpha(prevSolidColor);
        } else if (prevGradientColor) {
            alpha = getAlpha(prevGradientColor);
        }

        if (alpha === 0) {
            alpha = 1;
        }

        return alpha;
    },
    getBackgroundSolidColor = R.curry((backgroundPath: Path, data: any) => {
        return getByPath(p(backgroundPath, mp.colorData, mp.color), data);
    }),
    getBackgroundSolidThemeColor = R.curry((backgroundPath: Path, data: any) => {
        return getByPath(p(backgroundPath, mp.colorData, 'themeColor'), data);
    }),
    getBackgroundOpacity = R.curry((backgroundPath: Path, data: any) => {
        // solid color
        const solidColorPath = p(backgroundPath, mp.colorData, mp.colorAlpha);
        if (typeof getByPath(solidColorPath, data) === 'number') {
            return getByPath(solidColorPath, data);
        }

        // gradient color
        const gradientColorPath = p(backgroundPath, mp.colorData, mp.gradient, mp.colorAlpha);
        if (typeof getByPath(gradientColorPath, data) === 'number') {
            return getByPath(gradientColorPath, data);
        }

        return null;
    }),
    getAsset = (
        backgroundPath: Path,
        data: any
    ) => {
        return getByPath(p(backgroundPath, mp.assetData), data);
    },
    getAssetRepeat = (backgroundPath: Path, data: any) =>
        getByPath(p(backgroundPath, mp.assetData, mp.repeat), data),
    getAssetPosition = (backgroundPath: Path, data: any) =>
        getByPath(p(backgroundPath, mp.assetData, mp.position), data),
    getAssetSize = (backgroundPath: Path, data: any) =>
        getByPath(p(backgroundPath, mp.assetData, mp.size), data);

export {
    getBackgroundAlpha,
    getBackgroundSolidColor,
    getBackgroundSolidThemeColor,
    getBackgroundOpacity,
    getAsset,
    getAssetRepeat,
    getAssetPosition,
    getAssetSize
};
