import { getPadding } from "./getPadding";
import { getFontSize } from "./getFontSize";

import type { TinyMceEditor } from "../../../flowTypes";

export const canBeIndented = (editor: TinyMceEditor, editorWidth: number): boolean => {
    const
        leftSpacing = getPadding(editor)[3],
        fontSize = getFontSize(editor);

    return leftSpacing + fontSize + 40 < editorWidth;
};
