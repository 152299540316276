import React from 'react';

import { SettingsViewContainer } from './SettingsViewContainer';
import { GoogleAdsSettingsViewBodyTextWithSwitch } from './GoogleAdsSettingsViewBodyTextWithSwitch';
import type { GoogleAdsSettingsTextViewProps } from './GoogleAdsSettingsTextView';

type GoogleAdsSettingsTextWithSwitchViewProps = GoogleAdsSettingsTextViewProps & {
    isCampaignEnabled: boolean;
    handleCampaignStatusChange: (enabled: boolean) => void;
};

export const GoogleAdsSettingsTextWithSwitchView = (props: GoogleAdsSettingsTextWithSwitchViewProps) => (
    <SettingsViewContainer
        bgColor={props.bgColor}
        header={props.header}
        actionButton={props.actionButton}
    >
        <GoogleAdsSettingsViewBodyTextWithSwitch
            text={props.text}
            switchEnabled={props.isCampaignEnabled}
            handleSwitchStatusChange={props.handleCampaignStatusChange}
        />
    </SettingsViewContainer>
);
