import React from "react";
export const SvgSnapchatCircleFillMulticolor = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none" fillRule="evenodd">
            <circle cx={24} cy={24} r={24} fill="#f5ea30" fillRule="nonzero" />
            <path
                fill="#ffffff"
                d="M24.28 13.5c.916 0 4.017.255 5.477 3.534.492 1.103.374 2.978.28 4.484l-.005.063c-.01.165-.02.322-.028.475a.848.848 0 00.371.092c.277-.012.598-.103.954-.27a1.02 1.02 0 01.424-.087c.16 0 .324.032.462.089.41.147.668.443.675.774.008.422-.369.786-1.119 1.083a5.291 5.291 0 01-.321.11c-.424.134-1.064.337-1.237.747-.09.212-.055.485.106.811a.368.368 0 01.01.023c.057.13 1.41 3.223 4.42 3.72a.46.46 0 01.383.478.612.612 0 01-.05.208c-.225.526-1.177.913-2.911 1.182-.058.078-.118.354-.155.523-.037.17-.074.337-.127.514a.499.499 0 01-.511.384h-.026c-.122 0-.29-.02-.5-.062a5.871 5.871 0 00-1.18-.125c-.274 0-.558.024-.845.071-.56.093-1.034.43-1.586.82-.788.558-1.68 1.19-3.038 1.19-.06 0-.118-.002-.162-.004a1.52 1.52 0 01-.112.004c-1.357 0-2.25-.632-3.038-1.19-.55-.39-1.026-.726-1.585-.82a5.183 5.183 0 00-.846-.071c-.495 0-.89.077-1.18.134a2.79 2.79 0 01-.5.07c-.348 0-.484-.213-.536-.391a7.291 7.291 0 01-.127-.52c-.037-.169-.097-.446-.155-.525-1.734-.27-2.687-.656-2.912-1.185a.62.62 0 01-.05-.206.46.46 0 01.384-.48c3.008-.495 4.363-3.588 4.42-3.72l.01-.021c.16-.327.196-.6.106-.812-.174-.41-.814-.613-1.237-.748a5.105 5.105 0 01-.321-.11c-1.025-.405-1.168-.87-1.107-1.19.084-.443.624-.74 1.075-.74a.82.82 0 01.351.071c.386.181.728.273 1.018.273a.88.88 0 00.43-.094c-.01-.178-.021-.362-.033-.54-.094-1.505-.212-3.377.28-4.48 1.46-3.277 4.554-3.532 5.467-3.532l.382-.004h.055z"
            />
        </g>
    </svg>
);
