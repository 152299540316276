import React from 'react';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import NavigationGroup from '../../../../PropertiesPanel/view/IntlNavigationGroup';
import { MOBILE_VIEW } from "../../../../PropertiesPanel/view/MobileView/constants";
import * as pageIds from '../pageIds';
import pageTitles from '../pageTitles';
import type { PageProps } from '../../../../PropertiesPanel/flowTypes';
import type { ImageSliderComponent } from '../../flowTypes';

const
    id = pageIds.MAIN,
    title = pageTitles[id],
    view = ({ navigateToPage }: PageProps<ImageSliderComponent>) => (
        <Page>
            <NavigationGroup
                navigateToPage={navigateToPage}
                pagesTitles={pageTitles}
                targetPagesIds={[pageIds.CONTROLS, pageIds.ON_CLICK, pageIds.CAPTIONS, pageIds.FULL_WIDTH, MOBILE_VIEW]}
            />
        </Page>
    );

export { id, title, view };
