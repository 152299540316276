/* @flow */
const INSTAGRAM_CONNECTED_COOKIE_NAME = 'instagramConnected',
    INSTAGRAM_ACCESS_TOKEN_COOKIE_NAME = 'instagramAccessToken',
    INSTAGRAM_ACCOUNT_DOMAIN_NAME = 'instagramAccountDomainName',
    INSTAGRAM_CONNECTED_COOKIE_MAXAGE = 172800000; // 2 days in milliseconds

module.exports = {
    INSTAGRAM_CONNECTED_COOKIE_NAME,
    INSTAGRAM_ACCESS_TOKEN_COOKIE_NAME,
    INSTAGRAM_CONNECTED_COOKIE_MAXAGE,
    INSTAGRAM_ACCOUNT_DOMAIN_NAME,
}
