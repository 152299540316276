import * as R from 'ramda';
import { memoMaxOne } from '../../../utils/memo';
import { getComponentsBBox, doBBoxTouch, getComponentBBox } from '../componentsMap/index';
import * as handleKinds from '../handle/kinds';
import type {
    BBox, AnyComponent, ComponentsMap, ComponentsIds,
    Dimensions
} from '../../redux/modules/children/workspace/flowTypes';
import type { Position } from '../../redux/modules/flowTypes';
import {
    getComponentsMapNoGhosts
} from "../../components/oneweb/Code/getComponentsMapNoGhosts";
import { getComponentsBBoxWithoutStretchLeftAndRight } from "../componentsMap/getComponentsBBox";
import { isSectionKind } from "../../components/oneweb/componentKinds";
import { getCmpRect } from "../../convertToSections/util";
import isStretchComponentKind from "../../components/oneweb/isStretchComponentKind";
import { isIntersecting } from "../../components/Workspace/epics/componentsEval/utils";

function getViewportBBox(browserDimensions, scrollTop, workspaceBBox) {
    return {
        top: scrollTop.y,
        left: workspaceBBox.left,
        right: workspaceBBox.right,
        bottom: scrollTop.y + browserDimensions.height
    };
}

type BBoxOptional<T> = {
    top?: T,
    left?: T,
    right?: T,
    bottom?: T,
    center?: T,
    middle?: T
}

type ExcludeEdge = BBoxOptional<boolean>

type ExcludeEdgeMap = {
    [handleKind: string]: ExcludeEdge
}

type SnappedPoint = {
    bbox?: BBox,
    point?: number,
    isSectionSnapping?: boolean,
    sectionBBox?: BBox | null,
    boxes?: Record<string, any>,
    isEquidistant?: boolean
};

export type SnappedPoints = BBoxOptional<SnappedPoint>

type SnappedRegion = {
    snappedBBox: BBox,
    snappedPoints: SnappedPoints
}

type AdjustSnapping = {
    x: number,
    y: number,
    snappedPoints: SnappedPoints
}

const excludeEdgeMap: ExcludeEdgeMap = R.map((excludeEdgesInverted: string[]) => {
    const excludeEdges = {};
    ['top', 'right', 'bottom', 'left', 'middle', 'center'].forEach(name => {
        excludeEdges[name] = excludeEdgesInverted.indexOf(name) === -1;
    });
    return excludeEdges;
}, {
    [handleKinds.ResizeN]: ['top', 'middle', 'center'],
    [handleKinds.ResizeNE]: ['top', 'right', 'middle', 'center'],
    [handleKinds.ResizeE]: ['right', 'middle', 'center'],
    [handleKinds.ResizeSE]: ['bottom', 'right', 'middle', 'center'],
    [handleKinds.ResizeS]: ['bottom', 'middle', 'center'],
    [handleKinds.ShiftBarBottomSelection]: ['bottom', 'middle'],
    [handleKinds.ShiftBarTopSelection]: ['top'],
    [handleKinds.ResizeSW]: ['bottom', 'left', 'middle', 'center'],
    [handleKinds.ResizeW]: ['left', 'middle', 'center'],
    [handleKinds.ResizeNW]: ['top', 'left', 'middle', 'center']
});

export function filterViewportComponents(viewPortBBox: BBox,
    workspaceBBox: BBox,
    excludeComponents: AnyComponent[],
    componentsMap: ComponentsMap): AnyComponent[] {
    return R.filter((item: AnyComponent) => {
        return doBBoxTouch(viewPortBBox, getComponentsBBox([item], workspaceBBox))
            && excludeComponents.indexOf(item) === -1;
    }, R.values(componentsMap));
}

const snappingAddedDistance = 0;
const widthAllowedDeviation = 0.01;

export const SNAPPING_PROXIMITY_THRESHOLD = 10;

const ascendingSorter = (prop) => (a, b) => a[prop] - b[prop],
    descendingSorter = (prop) => (a, b) => b[prop] - a[prop],
    ascendingTopSorter = ascendingSorter('top'),
    descendingTopSorter = descendingSorter('top'),
    ascendingLeftSorter = ascendingSorter('left'),
    descendingLeftSorter = descendingSorter('left');

const isChildInsideParent = (parent, child, isStretchKind) => (parent.top <= child.top &&
    (isStretchKind ||
        (parent.left <= child.left &&
        parent.right >= child.right)
    ) &&
    parent.bottom >= child.bottom);

const getComponentsOnSection = (section, cmps) => {
    const sectionBottom = section.top + section.height,
        cmpsInSection = cmps.filter(c => {
            const { top, height, kind } = c;
            const center = top + height / 2;
            return center >= section.top && center <= sectionBottom && !isSectionKind(kind);
        });
    return cmpsInSection.filter(c => {
        const cBox = getCmpRect(c);
        return !cmpsInSection.find(pCmp => {
            const pBox = getCmpRect(pCmp);
            return c.id !== pCmp.id && isChildInsideParent(pBox, cBox, isStretchComponentKind(pCmp.kind, pCmp.stretch));
        });
    });
};

const divideIntoGroups = (lP, hP) => (boxes: any) => {
    let groups: Record<string, any> = [], group: Record<string, any> = {};
    boxes.forEach(box => {
        const lV = box[lP],
            hV = box[hP];
        if (isNaN(group[hP])) {
            group = { [lP]: lV, [hP]: hV, boxes: [box] };
        } else if (lV > group[hP]) {
            groups.push(group);
            group = { [lP]: lV, [hP]: hV, boxes: [box] };
        } else {
            group[hP] = Math.max(group[hP], hV);
            group.boxes.push(box);
        }
    });
    if (!isNaN(group[hP])) {
        groups.push(group);
    }
    return groups;
};

const groupVertically = divideIntoGroups('top', 'bottom');
const groupHorizontally = divideIntoGroups('left', 'right');

const getVerticallyProjectedComponents = (region, cmpsBBox) => {
    const projectedBBoxes = cmpsBBox.filter(cBox => !(cBox.left > region.right || cBox.right < region.left));
    const topBoxGroups = groupVertically(projectedBBoxes.filter(cBox => cBox.bottom < region.top)).sort(descendingTopSorter);
    const bottomBoxGroups = groupVertically(projectedBBoxes.filter(cBox => cBox.bottom > region.top));
    return [topBoxGroups, bottomBoxGroups];
};

const getHorizontalProjectedComponents = (region, cmpsBBox) => {
    const projectedBBoxes = cmpsBBox.filter(cBox => !(cBox.top > region.bottom || cBox.bottom < region.top));
    const leftBoxGroups = groupHorizontally(projectedBBoxes.filter(cBox => cBox.right < region.left)).sort(descendingLeftSorter);
    const rightBoxGroups = groupHorizontally(projectedBBoxes.filter(cBox => cBox.right > region.left));
    return [leftBoxGroups, rightBoxGroups];
};

const composeBboxFromGrp = (lP, hP) => (bboxGroups, closestCmp, distance) => {
    let prevBox = closestCmp, boxes = [closestCmp];
    for (let group of bboxGroups) { // eslint-disable-line
        const box = { ...group.boxes[0], [lP]: group[lP], [hP]: group[hP] },
            d = prevBox ? Math.abs(prevBox[lP] - box[hP]) : 0;
        if (prevBox && d !== distance) { break; }
        boxes.push(box);
        prevBox = box;
    }
    return boxes;
};

const getSnappingPointBeforeRegion = (lP, hP) => (region, bBoxGroups) => {
    const [firstClosestGrp, secondClosestGrp] = bBoxGroups;
    const { cmp: firstClosestBBox } = firstClosestGrp.boxes.reduce((acc, cmp) => {
        const val = Math.abs((cmp[lP] - secondClosestGrp[hP]) - (region[lP] - firstClosestGrp[hP]));
        if (val < acc.val) { return { val, cmp }; }
        return acc;
    }, { val: Number.MAX_SAFE_INTEGER });
    const firstCmpBox = {
        ...firstClosestBBox,
        [hP]: firstClosestGrp[hP],
    };
    const distance = (firstClosestBBox[lP] - secondClosestGrp[hP]),
        boxes = composeBboxFromGrp(lP, hP)(bBoxGroups.slice(1), firstCmpBox, distance);
    return {
        value: firstClosestGrp[hP] + distance,
        components: boxes.reverse(),
    };
};

const getSnappingPointAfterRegion = (lP, hP) => (region, bBoxGroups) => {
    const [firstClosestGrp, secondClosestGrp] = bBoxGroups;
    const { cmp: firstClosestBBox } = firstClosestGrp.boxes.reduce((acc, cmp) => {
        const val = Math.abs((secondClosestGrp[lP] - cmp[hP]) - (firstClosestGrp[lP] - region[hP]));
        if (val < acc.val) { return { val, cmp }; }
        return acc;
    }, { val: Number.MAX_SAFE_INTEGER });
    const firstCmpBox = {
        ...firstClosestBBox,
        [lP]: firstClosestGrp[lP],
    };
    const distance = (secondClosestGrp[lP] - firstClosestBBox[hP]),
        boxes = composeBboxFromGrp(hP, lP)(bBoxGroups.slice(1).map(grp => ({ ...grp, boxes: grp.boxes.slice(-1) })), firstCmpBox, distance);
    return {
        value: firstClosestGrp[lP] - (secondClosestGrp[lP] - firstClosestBBox[hP]),
        components: boxes,
    };
};

const getTopSnappingPoint = getSnappingPointBeforeRegion('top', 'bottom'),
    getLeftSnappingPoint = getSnappingPointBeforeRegion('left', 'right');

const getBottomSnappingPoint = getSnappingPointAfterRegion('top', 'bottom'),
    getRightSnappingPoint = getSnappingPointAfterRegion('left', 'right');

const getVerticalSnappingPoints = (region, cmpsBBox) => {
    const [topBoxGroups = [], bottomBoxGroups = []] = getVerticallyProjectedComponents(region, cmpsBBox);
    let vSnappingPoints: Record<string, any> = {};
    if (topBoxGroups.length >= 2) {
        const { value, components } = getTopSnappingPoint(region, topBoxGroups);
        vSnappingPoints.top = value;
        vSnappingPoints.topComponents = components;
    }
    if (bottomBoxGroups.length >= 2) {
        const { value, components } = getBottomSnappingPoint(region, bottomBoxGroups);
        vSnappingPoints.bottom = value;
        vSnappingPoints.bottomComponents = components;
    }

    if (bottomBoxGroups.length && topBoxGroups.length) {
        vSnappingPoints.middle = Math.round(topBoxGroups[0].bottom + (bottomBoxGroups[0].top - topBoxGroups[0].bottom) / 2);
        vSnappingPoints.middleComponents = [topBoxGroups[0].boxes.slice(-1)[0], bottomBoxGroups[0].boxes[0]];
    }
    return vSnappingPoints;
};

const getHorizontalSnappingPoints = (region, cmpsBBox) => {
    const [leftBoxGroups = [], rightBoxGroups = []] = getHorizontalProjectedComponents(region, cmpsBBox);
    let hSnappingPoints: Record<string, any> = {};
    if (leftBoxGroups.length >= 2) {
        const { value, components } = getLeftSnappingPoint(region, leftBoxGroups);
        hSnappingPoints.left = value;
        hSnappingPoints.leftComponents = components;
    }
    if (rightBoxGroups.length >= 2) {
        const { value, components } = getRightSnappingPoint(region, rightBoxGroups);
        hSnappingPoints.right = value;
        hSnappingPoints.rightComponents = components;
    }

    if (leftBoxGroups.length && rightBoxGroups.length) {
        hSnappingPoints.center = Math.round(leftBoxGroups[0].right + (rightBoxGroups[0].left - leftBoxGroups[0].right) / 2);
        hSnappingPoints.centerComponents = [leftBoxGroups[0].boxes.slice(-1)[0], rightBoxGroups[0].boxes[0]];
    }
    return hSnappingPoints;
};

const getSectionBBoxFromSectionBoxes = (sectionBboxes): BBox | null => {
    let top = Number.MAX_SAFE_INTEGER,
        left = Number.MAX_SAFE_INTEGER,
        bottom = -1,
        right = -1;
    if (!sectionBboxes) {
        return null;
    }
    sectionBboxes.forEach(box => {
        top = Math.min(top, box.top);
        left = Math.min(left, box.left);
        right = Math.max(right, box.right);
        bottom = Math.max(bottom, box.bottom);
    });
    return { top, left, right, bottom };
};

export function calculateSnappedRegion(region: BBox,
    items: AnyComponent[],
    templateWidth: number,
    workspaceBBox: BBox,
    handleKind: string,
    sectionBboxes: Array<BBox>): SnappedRegion {
    const
        proximityDistance: number = SNAPPING_PROXIMITY_THRESHOLD,
        proximityDistanceMiddle: number = Math.round(SNAPPING_PROXIMITY_THRESHOLD / 2),
        height: number = region.bottom - region.top,
        width = roundWithTolerance(region.right - region.left),
        regionMiddle: number = Math.round((region.top + region.bottom) / 2),
        regionCenter: number = Math.round((region.left + region.right) / 2),
        sectionBBox = getSectionBBoxFromSectionBoxes(sectionBboxes);

    // difference vertical/horizontal minimum
    let verticalDifference: number = proximityDistance,
        horizontalDifference: number = proximityDistance,
        middleDifference: number = proximityDistanceMiddle,
        centerDifference: number = proximityDistanceMiddle,
        snap: BBox = ({ ...region } as any),
        itemBBoxes: BBox[] = items.map(item => (getComponentsBBox([item], workspaceBBox))),
        excludeEdge: ExcludeEdge = excludeEdgeMap[handleKind] || {},
        snappedPoints: SnappedPoints = {},
        setSnapPoints = (itemBBox: BBox, isSectionSnapping: boolean = false, sectionBBox: BBox | null) => {
            // snap opposit sides; top and bottom.
            let differenceTopBottom: number = Math.round(Math.abs(itemBBox.bottom - region.top)),
                differenceBottomTop: number = Math.round(Math.abs(itemBBox.top - region.bottom));

            if (!excludeEdge.top && differenceTopBottom <= verticalDifference && !isSectionSnapping) {
                // TODO switch off line for not working snapping. Remove when that is done
                snap.top = itemBBox.bottom + snappingAddedDistance;
                verticalDifference = differenceTopBottom;
                snappedPoints.top = {
                    bbox: itemBBox,
                    point: itemBBox.bottom,
                    isSectionSnapping,
                    sectionBBox
                };
                if (!excludeEdge.bottom) {
                    snap.bottom = snap.top + height;
                }
                // TODO: avoid this overcomplexity in https://jira.one.com/browse/WBTGEN-1153
                // It is necessary to make middle and center snapping lines less sensitive by decreasing a proximity to 5.
                middleDifference = 0;
            }
            if (!excludeEdge.bottom && differenceBottomTop <= verticalDifference && !isSectionSnapping) {
                snap.bottom = itemBBox.top - snappingAddedDistance;
                verticalDifference = differenceBottomTop;

                snappedPoints.bottom = {
                    bbox: itemBBox,
                    point: itemBBox.top,
                    isSectionSnapping,
                    sectionBBox
                };
                if (!excludeEdge.top) {
                    snap.top = snap.bottom - height;
                }
                middleDifference = 0;
            }

            // snap same sides; top and bottom.
            let differenceTopTop: number = Math.round(Math.abs(itemBBox.top - region.top)),
                differenceBottomBottom: number = Math.round(Math.abs(itemBBox.bottom - region.bottom));

            if (!excludeEdge.top && differenceTopTop <= verticalDifference) {
                snap.top = itemBBox.top;
                verticalDifference = differenceTopTop;
                snappedPoints.top = {
                    bbox: itemBBox,
                    point: itemBBox.top,
                    isSectionSnapping,
                    sectionBBox
                };
                if (!excludeEdge.bottom) {
                    snap.bottom = snap.top + height;
                }
                middleDifference = 0;
            }
            if (!excludeEdge.bottom && differenceBottomBottom <= verticalDifference) {
                snap.bottom = itemBBox.bottom;
                verticalDifference = differenceBottomBottom;

                snappedPoints.bottom = {
                    bbox: itemBBox,
                    point: itemBBox.bottom,
                    isSectionSnapping,
                    sectionBBox
                };
                if (!excludeEdge.top) {
                    snap.top = snap.bottom - height;
                }
                middleDifference = 0;
            }
            // snap opposite sides; left and right.
            let differenceLeftRight: number = Math.round(Math.abs(itemBBox.right - region.left)),
                differenceRightLeft: number = Math.round(Math.abs(itemBBox.left - region.right));
            if (!excludeEdge.left && differenceLeftRight <= horizontalDifference && !isSectionSnapping) {
                snap.left = itemBBox.right;
                horizontalDifference = differenceLeftRight;

                snappedPoints.left = {
                    bbox: itemBBox,
                    point: itemBBox.right,
                    isSectionSnapping,
                    sectionBBox
                };
                if (!excludeEdge.right) {
                    snap.right = snap.left + width;
                }
                centerDifference = 0;
            }
            if (!excludeEdge.right && differenceRightLeft <= horizontalDifference && !isSectionSnapping) {
                snap.right = itemBBox.left;
                horizontalDifference = differenceRightLeft;

                snappedPoints.right = {
                    bbox: itemBBox,
                    point: itemBBox.left,
                    isSectionSnapping,
                    sectionBBox
                };
                if (!excludeEdge.left) {
                    snap.left = snap.right - width;
                }
                centerDifference = 0;
            }

            // snap same sides; left and right.
            let differenceLeftLeft: number = Math.round(Math.abs(itemBBox.left - region.left)),
                differenceRightRight: number = Math.round(Math.abs(itemBBox.right - region.right));
            if (!excludeEdge.left && differenceLeftLeft <= horizontalDifference) {
                snap.left = itemBBox.left;
                horizontalDifference = differenceLeftLeft;

                snappedPoints.left = {
                    bbox: itemBBox,
                    point: itemBBox.left,
                    isSectionSnapping,
                    sectionBBox
                };
                if (!excludeEdge.right) {
                    snap.right = snap.left + width;
                }
                centerDifference = 0;
            }
            if (!excludeEdge.right && differenceRightRight <= horizontalDifference) {
                snap.right = itemBBox.right;
                horizontalDifference = differenceRightRight;
                snappedPoints.right = {
                    bbox: itemBBox,
                    point: itemBBox.right,
                    isSectionSnapping,
                    sectionBBox
                };
                if (!excludeEdge.left) {
                    snap.left = snap.right - width;
                }
                centerDifference = 0;
            }

            if (isSectionSnapping) return;

            const itemBBoxMiddle: number = Math.round((itemBBox.top + itemBBox.bottom) / 2),
                itemBBoxCenter: number = Math.round((itemBBox.left + itemBBox.right) / 2);

            // snap middle; vertical center.
            let differenceMiddleMiddle: number = Math.round(Math.abs(itemBBoxMiddle - regionMiddle));

            if (!excludeEdge.middle &&
                differenceMiddleMiddle < (middleDifference || Math.round(verticalDifference / 2))) {
                verticalDifference = differenceMiddleMiddle;

                if (excludeEdge.bottom) {
                    snap.top = (snappedPoints.top && snappedPoints.top.bbox === itemBBox) ?
                        itemBBox.top : (itemBBoxMiddle * 2) - region.bottom;
                    snappedPoints.middle = {
                        bbox: itemBBox,
                        point: itemBBoxMiddle
                    };
                } else if (excludeEdge.top) {
                    snap.bottom = (snappedPoints.bottom && snappedPoints.bottom.bbox === itemBBox) ?
                        itemBBox.bottom : (itemBBoxMiddle * 2) - region.top;
                    snappedPoints.middle = {
                        bbox: itemBBox,
                        point: itemBBoxMiddle
                    };
                } else {
                    snap.top = itemBBoxMiddle - Math.round(height / 2);
                    snap.bottom = snap.top + height;
                    snappedPoints.middle = {
                        bbox: sectionBBox || itemBBox,
                        point: itemBBoxMiddle
                    };
                }
            }

            // snap horizontal center
            let differenceCenterCenter: number = Math.round(Math.abs(itemBBoxCenter - regionCenter));
            if (!excludeEdge.center &&
                differenceCenterCenter < (centerDifference || Math.round(horizontalDifference / 2))) {
                horizontalDifference = differenceCenterCenter;

                if (excludeEdge.right) {
                    snap.left = (snappedPoints.left && snappedPoints.left.bbox === itemBBox) ?
                        itemBBox.left : (itemBBoxCenter * 2) - region.right;
                    snappedPoints.center = {
                        bbox: itemBBox,
                        point: itemBBoxCenter
                    };
                } else if (excludeEdge.left) {
                    snap.right = (snappedPoints.right && snappedPoints.right.bbox === itemBBox) ?
                        itemBBox.right : (itemBBoxCenter * 2) - region.left;
                    snappedPoints.center = {
                        bbox: itemBBox,
                        point: itemBBoxCenter
                    };
                } else {
                    snap.left = itemBBoxCenter - Math.round(width / 2);
                    snap.right = snap.left + width;
                    snappedPoints.center = {
                        bbox: sectionBBox || itemBBox,
                        point: itemBBoxCenter
                    };
                }
            }
        };

    itemBBoxes.forEach(item => setSnapPoints(item, false, sectionBBox));

    if (sectionBboxes && sectionBboxes.length) {
        sectionBboxes.forEach(item => setSnapPoints(item, true, sectionBBox));
    }

    const parentSection = items.find(c => {
        const center = region.top + (region.bottom - region.top) / 2;
        const cBBox = getComponentBBox(c, workspaceBBox);
        return isSectionKind(c.kind) && center >= cBBox.top && center <= cBBox.bottom;
    });

    const isRegionOverlapping = items.some(c => {
        const cBBox = getComponentBBox(c, workspaceBBox);
        if (isIntersecting(cBBox, region) && (parentSection || {}).id !== c.id) {
            return true;
        }
        return false;
    });

    if (parentSection && !isRegionOverlapping) {
        const cmpsBBox = getComponentsOnSection(parentSection, items).map(c => getComponentBBox(c, workspaceBBox));
        const vSnappingPoints = getVerticalSnappingPoints(region, cmpsBBox.sort(ascendingTopSorter));
        const differenceTop: number = Math.round(Math.abs(region.top - vSnappingPoints.top)),
            differenceBottom: number = Math.round(Math.abs(vSnappingPoints.bottom - region.bottom)),
            differenceMiddle: number = Math.round(Math.abs(vSnappingPoints.middle - regionMiddle));

        const isEquidistant = true;

        if (!excludeEdge.top && differenceTop <= verticalDifference) {
            snap.top = vSnappingPoints.top;
            verticalDifference = differenceTop;
            snappedPoints.top = {
                bbox: { top: 0, left: 0, right: 0, bottom: 0 },
                point: vSnappingPoints.top,
                boxes: vSnappingPoints.topComponents,
                isEquidistant,
            };
            if (!excludeEdge.bottom) {
                snap.bottom = snap.top + height;
            }
            middleDifference = 0;
        }
        if (!excludeEdge.bottom && differenceBottom <= verticalDifference) {
            snap.bottom = vSnappingPoints.bottom;
            verticalDifference = differenceBottom;
            snappedPoints.bottom = {
                bbox: { top: 0, left: 0, right: 0, bottom: 0 },
                point: vSnappingPoints.bottom,
                boxes: vSnappingPoints.bottomComponents,
                isEquidistant
            };
            if (!excludeEdge.top) {
                snap.top = snap.bottom - height;
            }
            middleDifference = 0;
        }
        if (!excludeEdge.middle && differenceMiddle < (middleDifference || (verticalDifference + 1))) {
            verticalDifference = differenceMiddle;

            snappedPoints.middle = {
                bbox: { top: 0, left: 0, right: 0, bottom: 0 },
                point: vSnappingPoints.middle,
                boxes: vSnappingPoints.middleComponents,
                isEquidistant
            };

            if (excludeEdge.bottom) {
                const cmpHeight = (region.bottom - vSnappingPoints.middle) * 2;
                snap.top = region.bottom - cmpHeight;
            } else if (excludeEdge.top) {
                const cmpHeight = (vSnappingPoints.middle - region.top) * 2;
                snap.bottom = region.top + cmpHeight;
            } else {
                snap.top = vSnappingPoints.middle - Math.round(height / 2);
                snap.bottom = snap.top + height;
            }
        }

        const hSnappingPoints = getHorizontalSnappingPoints(region, cmpsBBox.sort(ascendingLeftSorter));
        const differenceLeft: number = Math.round(Math.abs(region.left - hSnappingPoints.left)),
            differenceRight: number = Math.round(Math.abs(hSnappingPoints.right - region.right)),
            differenceCenter: number = Math.round(Math.abs(hSnappingPoints.center - regionCenter));

        if (!excludeEdge.left && differenceLeft <= horizontalDifference) {
            snap.left = hSnappingPoints.left;
            horizontalDifference = differenceLeft;
            snappedPoints.left = {
                bbox: { top: 0, left: 0, right: 0, bottom: 0 },
                point: hSnappingPoints.left,
                boxes: hSnappingPoints.leftComponents,
                isEquidistant
            };
            if (!excludeEdge.right) {
                snap.right = snap.left + width;
            }
            centerDifference = 0;
        }
        if (!excludeEdge.right && differenceRight <= horizontalDifference) {
            snap.right = hSnappingPoints.right;
            horizontalDifference = differenceLeft;
            snappedPoints.right = {
                bbox: { top: 0, left: 0, right: 0, bottom: 0 },
                point: hSnappingPoints.right,
                boxes: hSnappingPoints.rightComponents,
                isEquidistant
            };
            if (!excludeEdge.left) {
                snap.left = snap.right - width;
            }
            centerDifference = 0;
        }
        if (!excludeEdge.center && differenceCenter < (centerDifference || (horizontalDifference + 1))) {
            horizontalDifference = differenceCenter;
            snappedPoints.center = {
                bbox: { top: 0, left: 0, right: 0, bottom: 0 },
                point: hSnappingPoints.center,
                boxes: hSnappingPoints.centerComponents,
                isEquidistant
            };

            if (excludeEdge.right) {
                const cmpWidth = (region.right - hSnappingPoints.center) * 2;
                snap.left = region.right - cmpWidth;
            } else if (excludeEdge.left) {
                const cmpWidth = (hSnappingPoints.center - region.left) * 2;
                snap.right = region.left + cmpWidth;
            } else {
                snap.left = hSnappingPoints.center - Math.round(width / 2);
                snap.right = snap.left + width;
            }
        }
    }

    const filteredSnappedPoints: SnappedPoints = {};
    R.mapObjIndexed((v, key) => {
        let snapPoint = Math.round(snap[key]);
        if (key === 'center') {
            snapPoint = Math.round((snap.left + snap.right) / 2);
        } else if (key === 'middle') {
            snapPoint = Math.round((snap.top + snap.bottom) / 2);
        }
        if (snapPoint === Math.round(v.point)) {
            filteredSnappedPoints[key] = v;
        }
    }, snappedPoints);

    // Remove middle lines if corner lines are directed to the same component
    if (filteredSnappedPoints.middle) {
        if ((filteredSnappedPoints.top || filteredSnappedPoints.bottom)
            && (filteredSnappedPoints.top || filteredSnappedPoints.bottom)?.bbox === filteredSnappedPoints.middle.bbox) {
            delete filteredSnappedPoints.middle;
        }
    }

    if (filteredSnappedPoints.center) {
        if ((filteredSnappedPoints.left || filteredSnappedPoints.right)
            && (filteredSnappedPoints.left || filteredSnappedPoints.right)?.bbox === filteredSnappedPoints.center.bbox) {
            delete filteredSnappedPoints.center;
            snap.left = Math.round(snap.left);
            snap.right = Math.round(snap.right);
        }
    }

    // When all calculated parameters are rounded we rely only on incoming data which can pass to this point unrounded
    // but it hasn't been used in calculations, so round it at the end

    return {
        snappedBBox: R.evolve({
            left: roundWithTolerance,
            top: roundWithTolerance,
            right: roundWithTolerance,
            bottom: roundWithTolerance
        }, snap),
        snappedPoints: filteredSnappedPoints
    };
}

export function roundWithTolerance(value: number) {
    if (Math.round(value) - widthAllowedDeviation < value) {
        return Math.round(value);
    }
    return value;
}

const snapComponents = memoMaxOne(
    (componentsMap: ComponentsMap,
        componentsIds: string[],
        browserDimensions: any,
        scrollTop: Position,
        templateWidth: number,
        workspaceBBox: BBox,
        handleKind: string,
        excludeComponentIds: string[] = [],
        sectionBboxes: Array<BBox>): AdjustSnapping => {
        const
            componentsMapNoGhosts = getComponentsMapNoGhosts(componentsMap),
            selectedComponents: AnyComponent[] = componentsIds.map(id => componentsMapNoGhosts[id]),
            currentBBox: BBox = getComponentsBBoxWithoutStretchLeftAndRight(selectedComponents, workspaceBBox),
            viewPortBBox: BBox = getViewportBBox(browserDimensions, scrollTop, workspaceBBox),
            filteredComponents: AnyComponent[] =
                filterViewportComponents(
                    viewPortBBox,
                    workspaceBBox,
                    selectedComponents,
                    componentsMapNoGhosts
                )
                    .filter(item => excludeComponentIds.indexOf(item.id) === -1),
            { snappedBBox, snappedPoints } = calculateSnappedRegion(
                currentBBox,
                filteredComponents,
                templateWidth,
                workspaceBBox,
                handleKind,
                sectionBboxes
            ),
            dt = snappedBBox.top - roundWithTolerance(currentBBox.top),
            dr = snappedBBox.right - roundWithTolerance(currentBBox.right),
            db = snappedBBox.bottom - roundWithTolerance(currentBBox.bottom),
            dl = snappedBBox.left - roundWithTolerance(currentBBox.left),
            dv = (dt && db) ? (Math.abs(db) > Math.abs(dt) ? dt : db) : (dt || db), // eslint-disable-line
            dh = (dl && dr) ? (Math.abs(dr) > Math.abs(dl) ? dl : dr) : (dl || dr); // eslint-disable-line

        return {
            x: dh,
            y: dv,
            snappedPoints
        };
    }
);

export const SNAPPING_HAPPENED_ACTION_TYPE = 'SNAPPING_HAPPENED';

type SnappingProps = {
    componentsMap: ComponentsMap,
    componentsIds: ComponentsIds,
    handleKind: string,
    templateWidth: number,
    browserDimensions: Dimensions,
    scrollTop: number,
    mousePosition: Position,
    workspaceBBox: BBox,
    excludeComponentIds?: ComponentsIds,
    sectionSnappingBboxes: Array<BBox>
}

type SnapResult = {
    snapped: true,
    snappedPoints: SnappedPoints,
    newMousePosition: Position,
    mousePositionDeviation: Position
} | { snapped: false }

function snap({
    componentsMap,
    componentsIds,
    handleKind,
    templateWidth,
    browserDimensions,
    scrollTop,
    mousePosition,
    workspaceBBox,
    excludeComponentIds,
    sectionSnappingBboxes
}: SnappingProps): SnapResult {
    const
        mousePositionDeviation = snapComponents(
            componentsMap,
            componentsIds,
            browserDimensions,
            scrollTop,
            templateWidth,
            workspaceBBox,
            handleKind,
            excludeComponentIds,
            sectionSnappingBboxes
        ),
        newMousePosition = {
            x: mousePosition.x + mousePositionDeviation.x,
            y: mousePosition.y + mousePositionDeviation.y
        },
        { snappedPoints } = mousePositionDeviation;
    if (!R.equals(snappedPoints, {})) {
        return {
            snapped: true,
            snappedPoints,
            newMousePosition,
            mousePositionDeviation
        };
    }
    return {
        snapped: false
    };
}

export {
    snapComponents,
    snap
};
