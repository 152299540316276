import parser from './parser/index';
import toHtml from './html/index';
import type { Stylesheet, DataSite, HtmlWriterData, AnyComponent } from './flowTypes';

const htmlWriter = (
    data: HtmlWriterData,
    defaultGlobalStyles: Array<Stylesheet>,
    site: null | undefined | DataSite,
    wrappedItems: Record<number, AnyComponent>,
    componentsRenderer?: null | Function,
    editMode: boolean = true,
    shouldKeepSpan: boolean = false
) => {
    const { nodes, wrappedItems: updatedWrappedItems } =
        parser(data, defaultGlobalStyles, site, wrappedItems, editMode);
    return toHtml(nodes, defaultGlobalStyles, updatedWrappedItems, componentsRenderer, editMode, shouldKeepSpan);
};

export default htmlWriter;

