import { applyMappers, makePlainMappers } from "../../utils";
import type { FeaturedProductsComponent } from "../../../../src/components/oneweb/FeaturedProducts/flowTypes";
import { FeaturedProductsThemeMapperKeys } from "../../../../src/components/oneweb/FeaturedProducts/constants";

const plainPropsMapper = makePlainMappers({
    mobileHide: 'mobileHide',
    mobileDown: 'mobileDown',
    products: 'products',
    font: 'font',
    fontColor: 'fontColor',
    focusColor: 'focusColor',
    hoverColor: 'hoverColor',
    temporaryHoverOpacity: 'temporaryHoverOpacity',
    imageRatio: 'imageRatio',
    cropImages: 'cropImages',
    productsPerRow: 'productsPerRow',
    productsPerRowInMobile: 'productsPerRowInMobile',
    spacingItemsInMobile: 'spacingItemsInMobile',
    labelBgColor: 'labelBgColor',
    labelTextColor: 'labelTextColor',
    labelPosition: 'labelPosition',
    productListStyle: 'productListStyle',
    viewDetailsShowButton: 'viewDetailsShowButton',
    viewDetailsButtonText: 'viewDetailsButtonText',
    viewDetailsButtonPosition: 'viewDetailsButtonPosition',
    viewDetailsButtonClassname: 'viewDetailsButtonClassname',
    isSingleProduct: 'isSingleProduct',
    isWebshopSetupDone: 'isWebshopSetupDone',
    viewDetailsButtonId: 'viewDetailsButtonId',
    viewDetailsButtonThemeSelected: 'viewDetailsButtonThemeSelected',
    enableBuyNowButton: 'enableBuyNowButton',
    buyNowButtonId: 'buyNowButtonId',
    buyNowButtonThemeSelected: 'buyNowButtonThemeSelected',
    buyNowButtonClassName: 'buyNowButtonClassName',
    buyNowButtonText: 'buyNowButtonText',
    ...FeaturedProductsThemeMapperKeys
});

export function to(componentData: any): FeaturedProductsComponent {
    return applyMappers(
        componentData,
        plainPropsMapper.to
    );
}

export function back(componentData: FeaturedProductsComponent): any {
    return applyMappers(
        componentData,
        plainPropsMapper.back
    );
}
