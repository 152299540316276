import { makeApplyFormatUpdater } from "./makeApplyFormatUpdater";
import { TINY_MCE_SET_FONT_FAMILY } from "../actionTypes";
import { FontFamiliesFallback } from "../../../../presentational/FontFamily/fontFamilies";

const wrapInQuotes = (v: string) => {
    // wrapping in quotes is required for font names which contain number preceded by a space
    // either of the three ways: elt.style.fontFamily, elt.style[font-family], and elt.style.setProperty()
    // don't work for font families with name <Slabo 27px> (unless quoted)
    // but will work for <Times New Roman> even without quotes
    //
    // at the moment <v> attribute here will always be a single font name, like: <Times New Roman>
    // and not a combination, like <Times New Roman, serif>
    // in the latter case, words like serif, sans-serif, monospace, etc; must not be quoted
    // so if that is implemented in future, a skip list will be required of the above mentioned values
    return (/"/.test(v) ? v : `"${v}"`);
};

const onSetTextFontFamilyUpdater = makeApplyFormatUpdater({
    triggerActionType: TINY_MCE_SET_FONT_FAMILY,
    formatterName: 'fontFamily',
    propToSet: 'fontFamily',
    toEditorValueMapper: (val: string) => (FontFamiliesFallback[val] ? FontFamiliesFallback[val] : wrapInQuotes(val))
});

export {
    onSetTextFontFamilyUpdater
};
