import type { LocaleData } from "../../../../dal/flowTypes";
import { getCookie, setCookie } from "../../../services/cookie";
import {
    ONE_LANG_COOKIE_KEY,
    COOKIE_LOCALE_PT,
    COOKIE_LOCALE_PT_BR,
    COOKIE_LOCALE_TO_DROP
} from "../../../constants/app";
import { getSupportedRenderLocales } from "../../../renderLocales";
import { getDefultLocaleId } from "../../../components/Locale/getDefaultLocale";
import { getAppConfig } from "../../../components/App/epics/appConfig/appConfig";
import { PartnerName } from '../../../../../server/shared/partners/PartnerName';
import isTestEnv from '../../../debug/isTestEnv';

const DEFAULT_LOCALE = getDefultLocaleId();

const supportedRenderLocals = getSupportedRenderLocales();

type Bootstrap = (arg0: LocaleData) => void;

// correct locale cookie
const locale = getCookie(ONE_LANG_COOKIE_KEY);

if (!locale || !supportedRenderLocals[locale]) {
    let defaultLangToSet = DEFAULT_LOCALE;
    if (locale === COOKIE_LOCALE_PT_BR) {
        defaultLangToSet = COOKIE_LOCALE_PT;
    }
    if (COOKIE_LOCALE_TO_DROP[locale] !== undefined) {
        defaultLangToSet = DEFAULT_LOCALE;
    }
    setCookie(ONE_LANG_COOKIE_KEY, defaultLangToSet);
}

const checkTranslationsEnabled = () => {
    const appConfig = getAppConfig();

    const
        url = new URL(window.location.href),
        checkTranslations = url.searchParams.has('checkTranslations');

    return !isTestEnv() && appConfig.i18n.enableCheckTranslations && checkTranslations;
};

const getLocaleFile = () => {
    const
        locale = getCookie(ONE_LANG_COOKIE_KEY),
        { partnerName } = getAppConfig();

    return window
        .fetch('/wbtgen/static/locale.js', { method: 'GET' })
        .then(res => res.json())
        .then(body => ({ body }))
        .then(
            // partner specific messages patch/override
            // TODO: To be removed by https://group-one.atlassian.net/browse/WBTGEN-23021
            (baseData) => {
                if (partnerName === PartnerName.ONECOM) {
                    return baseData;
                }

                return window
                    .fetch(
                        `/wbtgen/static/partners/${partnerName}.locale.${locale}.json`,
                        { method: 'GET' },
                    )
                    .then(res => res.json().then((partnerData) => ({
                        ...baseData,
                        body: {
                            ...baseData.body,
                            messages: {
                                ...baseData.body.messages,
                                ...partnerData.messages,
                            },
                        },
                    })))
                    .catch(() => baseData);
            }
        );
};

const getLocaleCheckFile = () => {
    const locale = getCookie(ONE_LANG_COOKIE_KEY);

    return window
        .fetch('/wbtgen/static/check.json', { method: 'GET' })
        .then(res => res.json())
        .then(messages => ({ body: { locale, messages } }));
};

const getLocaleData = () => {
    if (checkTranslationsEnabled()) {
        return getLocaleCheckFile();
    }
    return getLocaleFile();
};

export const loadLocaleData = (bootstrap: Bootstrap) => {
    return getLocaleData()
        .then(res => bootstrap(res.body))
        .catch(e => {
            // eslint-disable-next-line no-console
            console.log(`===Failed to boot:`, e);
        });
};
