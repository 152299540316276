import { STRIP_PIN_SET, STRIP_TITLE_CHANGE } from "../../oneweb/Strip/actionTypes";
import PinComponentConfigDialog from "../dialogId";
import { openDialogAC } from "../../App/actionCreators/openDialog";
import { makeActionForwardToSelectedComponent } from "../../../redux/forwardTo";
import { PIN_TO_SCREEN } from "../actionTypes";

const pinPosition = {
    TOP: 1,
    BOTTOM: -1,
    UNPIN: 0
};

const
    componentPinAC = (pin: number) => makeActionForwardToSelectedComponent({
        type: STRIP_PIN_SET,
        payload: pin
    });

export const
    componentPinToTopAC = () => (componentPinAC(pinPosition.TOP)),
    componentPinToBottomAC = () => (componentPinAC(pinPosition.BOTTOM)),
    componentUnpinAC = () => (componentPinAC(pinPosition.UNPIN)),
    openPinDialogAC = (componentId: string) =>
        openDialogAC(PinComponentConfigDialog, { componentId }),
    pinToScreenAC = (componentId: string) => ({
        type: PIN_TO_SCREEN,
        payload: { componentId }
    }),
    changeStripTitleAC = (title: string) => makeActionForwardToSelectedComponent({
        type: STRIP_TITLE_CHANGE,
        payload: title,
        amendToSelf: true
    });

