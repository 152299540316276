import { BorderStyles } from "../BorderStyle/constants";
import formats from "../../ColorPicker/formats";

export const
    DEFAULT_BORDER_STYLE = BorderStyles.NONE,
    DefaultBorderData = {
        style: DEFAULT_BORDER_STYLE,
        color: [formats.HSL, 0, 0, 0, 1],
        width: [1, 1, 1, 1]
    };
