import valueActionType from './valueActionType';
import makeEpic from "../../../../../epics/makeEpic";
import { SITE_SUBSCRIPTION_DATA_LOAD_SUCCESS } from "../../../../App/actionTypes";
import { SubscriptionStatus } from "../../constants";
import { isEcommerceSubscription } from "../../../../../../../server/shared/subscriptionStatus/utils";

const defaultState = {
    loading: true,
    status: SubscriptionStatus.NONE
};
export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                SITE_SUBSCRIPTION_DATA_LOAD_SUCCESS
            ],
            reducer: ({ state, values: [{ subscriptionType }] }) => {
                let newStatus = state.status;
                if (isEcommerceSubscription(subscriptionType)) {
                    newStatus = SubscriptionStatus.PAID;
                }
                return {
                    state: {
                        ...state,
                        status: newStatus,
                        loading: false,
                    }
                };
            }
        }
    ]
});
