import React from "react";
import { WidgetsBookingsKind } from "../../oneweb/Widgets/Bookings/kind";
import { ComponentNames } from "../../oneweb/constants";
import * as Icon from './Icons.css';
import { BookingsTemplateView } from "../../oneweb/Widgets/Bookings/expandedPanel/expandedPanelContent";
import type { ExtendedPanelContentProps } from "../view/inserter";

export default {
    kind: WidgetsBookingsKind,
    shortCut: {
        iconClassName: Icon.bookings,
        text: ComponentNames[WidgetsBookingsKind]
    },
    extendedPanelData: {
        title: 'msg: extendedPanel.bookingsTitle {Bookings}',
        content: (props: ExtendedPanelContentProps) => <BookingsTemplateView {...props} />
    }
};
