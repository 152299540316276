import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
    (theme) => ({
        root: {
            padding: "30px 0px",
            marginTop: "10px",
            [theme.breakpoints.up('md')]: {
                marginTop: "56px",
                padding: "0px",
            },
        },
        icon: {
            display: "inline-flex",
            alignItems: "center",
            margin: "0px 6px -2px 0px",
        },
        label: {
            fontSize: 14,
            lineHeight: "24px",
        },
        labelBlue: {
            color: theme.palette.custom.colorScienceBlue
        },
        labelGrey: {
            color: theme.palette.custom.colorGray_8a
        },
        bordered: {
            display: "inline-flex",
            alignItems: "center",
            width: "1px",
            height: "14px",
            border: `1px solid ${theme.palette.custom.colorGray_8a}`,
            margin: "0 7.5px 0 7px",
        }
    }),
    { name: "WebisteSettingsSecurity" }
);
