/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useIntl } from '../../../../../../../view/intl';
import { FIELD_LABELS } from "../constants";

import { ValidateInput } from "../Components/ValidateInput";

import {
    AI_TEXT_TOPIC_CHANGED
} from "../../../../epics/AITextEpic/actionTypes";

import { selectErrorFields, selectTopic } from '../../../../epics/AITextEpic/selectors';

export const CustomTopicField = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const aiTextTopic = useSelector(selectTopic);
    const errorFields = useSelector(selectErrorFields);

    const fieldKey = FIELD_LABELS.topic;
    const [topic, setTopic] = useState(aiTextTopic);

    useEffect(() => {
        setTopic('');
        dispatch({ type: AI_TEXT_TOPIC_CHANGED, payload: '' });
    }, [dispatch]);

    const isInvalidField = errorFields.includes(fieldKey);
    const [isLong, setIsLong] = useState(false);

    const onChange = (key, value, errorflag) => {
        setIsLong(errorflag);
        dispatch({ type: AI_TEXT_TOPIC_CHANGED, payload: value });
    };

    return (
        <ValidateInput
            fieldKey={fieldKey}
            fieldLabel={intl.msgJoint("msg: component.text.createAIText.dialog.textBuilder.topic.custom.label {Briefly describe your own topic}")}
            placeholder="msg: component.text.createAIText.dialog.textBuilder.topic.placeholder {e.g. FAQ}"
            fieldValue={topic || ''}
            errorFields={errorFields}
            isInvalid={isInvalidField || isLong}
            onChange={onChange}
            validationMsg={isLong ?
                intl.msgJoint("msg: common.error.longInput {The entered text is too long.}") :
                intl.msgJoint("msg: common.error.requiredField {This field is required.}")}
            dataTestId="ai-text-custom-topic-field"
        />);
};
