import { v4 as uuidv4 } from 'uuid';
import * as path from '../../../../src/mappers/path';
import * as colorMapper from '../Base/color';
import maybe from '../../../../src/utils/maybe';
import { overPath } from '../../../../src/utils/ramdaEx';
import type { Color } from '../../../../src/mappers/flowTypes';
import type { LinkStylesheet } from '../../../../src/components/Workspace/epics/stylesheets/flowTypes';
import { StyleLink } from './types';

const
    defaultRef: string = 'link.1',
    rootKeys = [path.inactive, path.hover, path.visited, path.press],
    colorMap = {
        to: overPath([
            [rootKeys, path.color], [rootKeys, ...path.shadowColor], [rootKeys, path.highlight]
        ])(maybe(colorMapper.toHsl)),
        back: overPath([
            [rootKeys, path.color], [rootKeys, ...path.shadowColor], [rootKeys, path.highlight]
        ])(color => color)
    };

export const
    to = colorMap.to,
    back = colorMap.back,
    createGlobalstyleWithDefaults = (): LinkStylesheet => {
        const linkColor: Color = colorMapper.toHsl('#1A0DAB');
        return {
            id: uuidv4(),
            type: StyleLink,
            name: `[${defaultRef}]`,
            ref: defaultRef,
            hover: { color: linkColor, underline: true },
            inactive: { color: linkColor, underline: false },
            press: { color: linkColor, underline: true },
            visited: { color: linkColor, underline: false },
        };
    };
