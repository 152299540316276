import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import STICKY_TOOLTIP_VAT from '../../stickyTooltip/epics/valueActionType';
import TOOLTIP_VAT from '../tooltip/valueActionType';
import { ls as LocalStorage } from "../../../../utils/localStorage";
import { PANEL_ITEM_CLICK } from "../../../Panel/actionTypes";
import { CLOSE_TOOLTIP } from "../../actionTypes";
import { receiveOnly } from "../../../../epics/makeCondition";
import { SectionShortcutInfoTooltip } from "../../ids";

export const CHECK_STICKY_TOOLTIP_AFTER_QUICK_TOUR = 'CHECK_STICKY_TOOLTIP_AFTER_QUICK_TOUR';
const SHOW_SECTION_INFO_TOOLTIP = 'SHOW_SECTION_INFO_TOOLTIP';
const HIDE_SECTION_INFO_TOOLTIP = 'HIDE_SECTION_INFO_TOOLTIP';
export const showSectionInfoTooltipAC = () => ({ type: SHOW_SECTION_INFO_TOOLTIP });
export const hideSectionInfoTooltipAC = () => ({ type: HIDE_SECTION_INFO_TOOLTIP });
const NO_SECTION_TOOLTIP = 'NO_SECTION_TOOLTIP';
const defaultState = { show: false };
export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                STICKY_TOOLTIP_VAT,
                CHECK_STICKY_TOOLTIP_AFTER_QUICK_TOUR
            ],
            reducer: ({ values: [{ show }], state }) => {
                const actionToDispatch = !show ? showSectionInfoTooltipAC() : null;
                return {
                    state,
                    actionToDispatch
                };
            }
        },
        {
            conditions: [SHOW_SECTION_INFO_TOOLTIP],
            reducer: ({ state }) => {
                const noSectionTooltip = LocalStorage.get(NO_SECTION_TOOLTIP, true) === 'yes';

                if (noSectionTooltip) {
                    return { state: { show: false } };
                }

                LocalStorage.set(NO_SECTION_TOOLTIP, 'yes', true);

                return {
                    state: {
                        ...state,
                        show: true
                    }
                };
            }
        },
        {
            conditions: [HIDE_SECTION_INFO_TOOLTIP],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        show: false
                    }
                };
            }
        },
        {
            // close info tooltip when click inserter shortcuts
            conditions: [receiveOnly(TOOLTIP_VAT), PANEL_ITEM_CLICK],
            reducer: ({ values: [{ id: tooltipId }], state }) => {
                if (tooltipId === SectionShortcutInfoTooltip) {
                    return {
                        state,
                        actionToDispatch: { type: CLOSE_TOOLTIP }
                    };
                }
                return { state };
            }
        }
    ]
});
