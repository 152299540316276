/* eslint-disable max-len */

import React from 'react';
import cx from 'classnames';
import InputField from '../../../../../../view/common/Input/InputField';
import { MVE_WEBSITE_TITLE_MAX_LENGTH } from '../../../../../MobileViewEditor/header/propertiesPanel/pages/main/constants';
import {
    GENERAL_INFO_WEBSITE_TITLE_INPUT_CHANGED_FROM_GMB_CREATE_STEP,
    GENERAL_INFO_WEBSITE_TITLE_CHANGED_ON_BLUR,
    GENERAL_INFO_CONTACT_EMAIL_INPUT_CHANGED,
    GENERAL_INFO_CONTACT_EMAIL_CHANGED_ON_BLUR
} from '../../../../General/actionTypes';
import VerticalSpacer from "../../../../../../view/common/VerticalSpacer";
import { QuestionTip } from "../../../../../presentational/Tooltip/questionTip/QuestionTip";
import { TooltipPosition } from "../../../../../presentational/Tooltip/constants";
import { ValidatedInput } from '../../../../../../view/common/Input/ValidatedInputField';
import { isValidEmail } from '../../../../../../utils/validation';
import { GmbCategoriesInput } from '../../../../../Onboarding/view/GmbCategoriesInput';
import { GENERAL_GLOBAL_DATA_UPDATE_GMB_CATEGORIES } from '../../../../General/generalGlobalDataEpic/actionTypes';
import { Intl } from "../../../../../../view/intl/index";

import styles from './styles.css';
import stepStyles from './BusinessInformationStep.css';
import { getDomainLink } from "../../getDomainLink";

type BusinessInformationStepProps = {
    intl: Intl;
    dispatch: Dispatch;
    websiteTitle: string | null | undefined;
    gmbCategories: string;
    contactEmail: string | null | undefined;
    publishWithSSL: boolean;
    attemptedInvalidSubmit: boolean;
};

const
    tipPart1 = "msg: BusinessListing.createListing.businessCategory.tip.part1 { Categories help your customers find accurate, specific results for services that are interesting to them. }",
    tipPart2 = "msg: BusinessListing.createListing.businessCategory.tip.part2 { To keep your business information accurate and live, make sure that you use as few categories as possible to describe your overall core business from the provided list. }",
    tipPart3 = "msg: BusinessListing.createListing.businessCategory.tip.part3 { Choose categories that are as specific as possible but representative of your primary business. }";

const BusinessInformationStep = (props: BusinessInformationStepProps) => {
    const { intl, dispatch, websiteTitle, gmbCategories, contactEmail, publishWithSSL, attemptedInvalidSubmit } = props;

    const
        domainLink = getDomainLink(publishWithSSL),
        isContactEmailValid = !contactEmail || isValidEmail(contactEmail);

    return (
        <div className={styles.container}>
            <div className={styles.field}>
                <div className={styles.name}>
                    {intl.msgJoint("msg: BusinessListing.createListing.companyName {Company Name}")}
                    <span>{intl.msgJoint("msg: BusinessListing.createListing.companyNameDescription {This is the name of your business}")}</span>
                </div>
                <InputField
                    className={styles.inputField}
                    placeholder={intl.msgJoint('msg: onboarding.websiteTitle.placeHolder {Eg: "Gilbert\'s Bakery"}')}
                    value={websiteTitle || ""}
                    onChange={payload => dispatch({ type: GENERAL_INFO_WEBSITE_TITLE_INPUT_CHANGED_FROM_GMB_CREATE_STEP, payload })}
                    usePropsValue
                    maxLength={MVE_WEBSITE_TITLE_MAX_LENGTH}
                    changedByUserOnBlur={payload => dispatch({ type: GENERAL_INFO_WEBSITE_TITLE_CHANGED_ON_BLUR, payload })}
                />
            </div>
            <VerticalSpacer y={29} />
            <div className={styles.hr} />
            <VerticalSpacer y={37} />
            <div className={styles.field}>
                <div className={styles.name}>
                    {intl.msgJoint("msg: BusinessListing.createListing.websiteUrl {Website URL}")}
                    <span>{intl.msgJoint("msg: BusinessListing.createListing.websiteUrlDescription {The URL address of your business website}")}</span>
                </div>
                <InputField
                    className={styles.inputField}
                    value={domainLink}
                    disabled
                />
            </div>
            <VerticalSpacer y={29} />
            <div className={styles.hr} />
            <VerticalSpacer y={37} />
            <div className={styles.field}>
                <div className={styles.name}>
                    {intl.msgJoint("msg: BusinessListing.createListing.businessEmail {Business email}")}
                    <span>{intl.msgJoint("msg: BusinessListing.createListing.businessEmailDescription {The email address of your business}")}</span>
                </div>
                <div className={styles.inputWithValidation}>
                    <ValidatedInput
                        className={cx(styles.inputField, styles.validatedInput)}
                        placeholder="msg: common.emailExample {e.g. hi@example.com}"
                        value={contactEmail || ""}
                        isInvalid={!isContactEmailValid}
                        invalidInputClass={styles.validationLabel}
                        validationMsg="msg: generalInfoTab.emailInvalid {Check that the email is valid.}"
                        onChange={payload => dispatch({ type: GENERAL_INFO_CONTACT_EMAIL_INPUT_CHANGED, payload })}
                        intl={intl}
                        changedByUserOnBlur={() => dispatch({ type: GENERAL_INFO_CONTACT_EMAIL_CHANGED_ON_BLUR })}
                    />
                </div>
            </div>
            <VerticalSpacer y={29} />
            <div className={styles.hr} />
            <VerticalSpacer y={37} />
            <div className={styles.field}>
                <div className={styles.name}>
                    <div className={stepStyles.tipWithName}>
                        {intl.msgJoint("msg: BusinessListing.createListing.businessCategory {Business Category}")}
                        <QuestionTip
                            position={TooltipPosition.TOP}
                            distance={2}
                            theme={{ tipInfo: styles.questionTipInfo, container: styles.questionTipContainer }}
                            className={stepStyles.questionTip}
                        >
                            <React.Fragment>
                                <div className={styles.tipHeader}>
                                    {intl.msgJoint("msg: BusinessListing.createListing.businessCategory {Business Category}")}
                                </div>
                                <VerticalSpacer y={6} />
                                <div>
                                    {intl.msgJoint(tipPart1)}
                                    <VerticalSpacer y={16} />
                                    {intl.msgJoint(tipPart2)}
                                    <VerticalSpacer y={16} />
                                    {intl.msgJoint(tipPart3)}
                                </div>
                            </React.Fragment>
                        </QuestionTip>
                    </div>
                    <span>{intl.msgJoint("msg: BusinessListing.createListing.businessCategoryDescription {Choose the categories that best reflect your business}")}</span>
                </div>
                <div className={stepStyles.gmbCategories}>
                    <GmbCategoriesInput
                        min={attemptedInvalidSubmit ? 1 : 0}
                        gmbCategories={gmbCategories}
                        action={GENERAL_GLOBAL_DATA_UPDATE_GMB_CATEGORIES}
                        dispatch={dispatch}
                        intl={intl}
                    />
                </div>
            </div>
        </div>
    );
};

export default BusinessInformationStep;
