import { getComputedStylesForMenu } from "./utils";

export default ({
    component,
    componentExtension,
    componentDependencies: {
        currentPageId,
        globalVariables,
        siteData,
        stylesheetsIdToNameMap,
        themeSettingsData,
        globalstyles,
        menuState,
        templateStyle
    },
    componentId,
    isWorkspace,
    selectedParentTheme,
    parentSection
}) => {
    return {
        currentPageId,
        siteData,
        component,
        componentExtension,
        componentId,
        isWorkspace,
        stylesheetsIdToNameMap,
        globalVariables,
        selectedParentTheme,
        themeSettingsData,
        menuState,
        computedStyles: getComputedStylesForMenu(parentSection, themeSettingsData.autoColorMode, component, globalstyles, templateStyle)
    };
};
