/* @flow */

const SsoTrialCookie = {
    USERNAME: '__tssousr',
    ACCESS_TOKEN: '__tssoacctkn',
    REFRESH_TOKEN: '__tssorfshtkn',
    ID_TOKEN: '__tssoidtkn',
};

module.exports = { SsoTrialCookie };
