import lengthInputValidation from './lengthInputValidation';
import type { LengthInputValidation } from "./lengthInputValidation"; // eslint-disable-line
import urlPathInputValidation from './urlPathInputValidation';
import type { UrlPathInputValidation } from "./urlPathInputValidation"; // eslint-disable-line
import blackListInputValidation from './blackListInputValidation';
import type { BlackListInputValidation } from './blackListInputValidation'; // eslint-disable-line
import urlInputValidation from './urlInputValidation';
import type { UrlInputValidation } from './urlInputValidation'; // eslint-disable-line

// Types
export type CommonInputValidations =
    LengthInputValidation
    | UrlPathInputValidation
    | BlackListInputValidation
    | UrlInputValidation;
export type CommonInputValidationNameEnum =
    'lengthInputValidation'
    | 'urlPathInputValidation'
    | 'blackListInputValidation'
    | 'urlInputValidation';

// Validations
const CommonInputValidationName = {
    LENGTH: lengthInputValidation.name,
    URL_PATH: urlPathInputValidation.name,
    BLACK_LIST: blackListInputValidation.name,
    URL: urlInputValidation
};

export {
    CommonInputValidationName,
    lengthInputValidation,
    urlPathInputValidation,
    blackListInputValidation,
    urlInputValidation
};
