import * as R from "ramda";
import { v4 as uuidv4 } from 'uuid';
import * as path from "../../../../src/mappers/path";
import * as colorMapper from "../Base/color";
import * as backgroundMapper from "../Common/background";
import { overPath } from "../../../../src/utils/ramdaEx";
import * as fontFamilyMapper from "../Base/fontFamily";
import maybe from "../../../../src/utils/maybe";
import { valueRepeater } from '../../utils';
import { StyleButton } from './types';
import type { ButtonStateStyle } from '../../../../src/mappers/button/flowTypes';

const
    defaultRef = 'button.1',
    cornersObjectToArray = corners => [corners.topLeft, corners.bottomLeft, corners.topRight, corners.bottomRight],
    cornersArrayToObject = ([topLeft, bottomLeft, topRight, bottomRight]) => ({
        topLeft, bottomLeft, topRight, bottomRight
    }),
    cornersMapper = {
        to: corners => corners ? cornersObjectToArray(corners) : null, // eslint-disable-line no-confusing-arrow
        back: corners => corners ? cornersArrayToObject(corners) : null // eslint-disable-line no-confusing-arrow
    };

const
    rootKeys = [path.disabled, path.hover, path.inactive, path.press, path.visited],
    deDuplicate = {
        to: overPath([
            [rootKeys, ...path.blockBorderStyle],
            [rootKeys, ...path.blockBorderColor]
        ])(maybe(R.head)),
        back: overPath([
            [[path.inactive, path.hover], ...path.blockBorderStyle],
            [[path.inactive, path.hover], ...path.blockBorderColor]
        ])(maybe(valueRepeater))
    },
    colorMap = {
        to: overPath([[rootKeys, ...path.textColor], [rootKeys, ...path.blockBorderColor]])(maybe(colorMapper.toHsl)),
        back: overPath([[rootKeys, ...path.textColor]])(R.identity)
    },
    setFontFamilyToNullIfKeyIsMissing = overPath([[rootKeys, path.text]])(objText => ({
        ...objText,
        font: objText.font || null
    })),
    fontFamilyMap = {
        to: overPath([[rootKeys, ...path.textFont]])(fontFamilyMapper.to),
        back: overPath([[rootKeys, ...path.textFont]])(fontFamilyMapper.back)
    },
    backgroundMap = {
        to: overPath([[rootKeys, ...path.blockBackground]])(backgroundMapper.to),
        back: overPath([[rootKeys, ...path.blockBackground]])(backgroundMapper.back)
    },
    cornersMap = {
        to: overPath([[rootKeys, ...path.blockBorderRadius]])(cornersMapper.to),
        back: overPath([[rootKeys, ...path.blockBorderRadius]])(cornersMapper.back)
    };

export const
    btnDefaultRef = defaultRef,
    to = R.pipe(
        deDuplicate.to,
        colorMap.to,
        setFontFamilyToNullIfKeyIsMissing,
        fontFamilyMap.to,
        backgroundMap.to,
        cornersMap.to
    ),
    back = R.pipe(deDuplicate.back, colorMap.back, fontFamilyMap.back, backgroundMap.back, cornersMap.back),
    createGlobalstyleWithDefaults = ({ block, text, verticalAlign, horizontalAlign }: ButtonStateStyle) => ({
        id: uuidv4(),
        type: StyleButton,
        name: `[${defaultRef}]`,
        ref: defaultRef,
        inactive: { block, text, verticalAlign, horizontalAlign },
        visited: { block, text, verticalAlign, horizontalAlign },
        hover: { block, text, verticalAlign, horizontalAlign },
        press: { block, text, verticalAlign, horizontalAlign },
        disabled: { block, text, verticalAlign, horizontalAlign }
    });
