import type { AppStore } from "../../../src/redux/modules/flowTypes";
import {
    LOAD_TRIAL_IMPORT_AVAILABILITY_SUCCESS_ACTION,
    TRIAL_IMPORT_DATA_SUCCESS_ACTION,
    setTrialImportAvailabilityDataAction,
} from "./actions";
import { default as loadSiteDataAction } from '../../../src/components/App/actionCreators/loadSiteData';
import { loadSiteSettings } from "../../../src/components/App/epics/siteSettings/actionCreators";
import localStorage from "../../../src/utils/localStorage";
import { showWelcomeMessageOnTemplateImportKey } from "../../../src/components/WelcomeMessage/epic/index";

export const trialImportAvailabilityMiddleware = (store: AppStore) => {
    return (next: Dispatch) => (action: Action) => {
        if (action.type === LOAD_TRIAL_IMPORT_AVAILABILITY_SUCCESS_ACTION) {
            store.dispatch(setTrialImportAvailabilityDataAction(action.payload));
        }

        if (action.type === TRIAL_IMPORT_DATA_SUCCESS_ACTION) {
            localStorage.set(showWelcomeMessageOnTemplateImportKey, "yes", true);
            store.dispatch(loadSiteDataAction());
            store.dispatch(loadSiteSettings());
        }

        return next(action);
    };
};
