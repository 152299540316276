/* eslint-disable max-len */
import React from 'react';
import { useDispatch } from "react-redux";

import { getBottomOrTopRightDialogPosition } from "../../../../../DialogManager/utility";

import { Dialog, DialogTitleBox, DialogBody } from '../../../../../../view/common/dialogs/baseDialog/index';
import { StripTypes } from "../../../../../../view/common/dialogs/baseDialog/Dialog";

import { UserInputPanel } from "./UserInputPanel";
import { ResultsPanel } from './ResultsPanel/ResultsPanel';

import {
    AI_TEXT_CLOSE_DIALOG
} from "../../../epics/AITextEpic/actionTypes";

import styles from './styles.css';

const
    width = 824,
    height = 552,
    CreateAITextDialog = () => {
        const dispatch = useDispatch();
        const onClose = () => {
            dispatch({ type: AI_TEXT_CLOSE_DIALOG });
        };

        return (
            <Dialog
                onClose={onClose}
                stripType={StripTypes.NONE}
                className={styles.dialog}
                containerClassName={styles.dialogContainer}
                closeBtnClassName={styles.closeButton}
            >
                <DialogTitleBox
                    title="msg: component.text.createAIText.dialog.title {Writing Assistant}"
                    className={styles.dialogTitleBox}
                    titleClass={styles.dialogTitle}
                    subTitle="msg: component.text.createAIText.dialog.subTitle {(Powered by OpenAI)}"
                    subTitleClass={styles.dialogSubTitle}
                    titleContainerClass={styles.dialogTitleContainer}
                />

                <DialogBody className={styles.dialogBody}>
                    <div className={styles.contentWrapper} data-testid="create-ai-text-dialog-content">
                        <UserInputPanel />
                        <ResultsPanel />
                    </div>
                </DialogBody>
            </Dialog>
        );
    },
    config = {
        confFactory: (browserDimensions, payload) => {
            return {
                position: getBottomOrTopRightDialogPosition(
                    browserDimensions,
                    width,
                    height,
                    payload
                ),
                dimensions: { width, height },
                modal: true
            };
        },
        component: CreateAITextDialog
    };

const CREATE_AI_TEXT_DIALOG_ID = "CREATE_AI_TEXT_DIALOG_ID";

export {
    CREATE_AI_TEXT_DIALOG_ID,
    config,
    CreateAITextDialog
};
