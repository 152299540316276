const removeUnwantedVersion = (filename) => {
    return filename.split('(')[0];
};

export function getUnsplashFileNameFromAsset(asset: Record<string, any>): string {
    let filename = '';
    if (asset && asset.image && asset.image.Copyright
        && (typeof asset.image.Copyright === 'string')
        && asset.image.Copyright.includes('unsplash')
    ) {
        filename = asset.image.Copyright;
    } else if (asset && asset.url) {
        const urlData = asset.url.split(':');
        const isWebspace = urlData[0] === 'webspace';
        if (isWebspace) {
            filename = urlData[1].split('/').pop();
            if (filename.includes('unsplash')) {
                filename = removeUnwantedVersion(filename.split('.')[0]);
            } else {
                filename = '';
            }
        }
    }
    return filename;
}
