import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import { valueActionType as siteDataValueActionType } from '../siteData/valueActionType';
import currentPageIdValueActionType from '../currentPageId/valueActionType';

type ReducerInput = {
    values: Tuple10Any;
};

export default makeEpic({
    valueActionType,
    updaters: [
        {
            conditions: [siteDataValueActionType, currentPageIdValueActionType],
            reducer: ({ values: [siteData, currentPageId] }: ReducerInput) => {
                const page = currentPageId && siteData.getPageRefByPageId(currentPageId);

                if (page) {
                    return { state: page.name };
                }

                return { state: null };
            }
        }
    ]
});
