type OverrideProperties<T extends Record<string, any>, K extends Record<keyof T, any>> = {
    [P in keyof T]: K[P]
}

export default <T extends Record<string, any>, K extends Record<keyof T, any>>(a: T, b: K): OverrideProperties<T, K> => {
    return Object.keys(a).reduce((obj, prop) => {
        if (b.hasOwnProperty(prop) && a[prop] !== b[prop]) {
            return {
                ...obj,
                [prop]: a[prop]
            };
        }
        return obj;
    }, {} as OverrideProperties<T, K>);
};

