import React from 'react';
import Page from '../../../../../PropertiesPanel/view/PropertiesPage';
import VerticalSpacer from '../../../../../../view/common/VerticalSpacer';
import PropertyContainer from '../../../../../../view/common/PropertyContainer/index';
import ComboBox from '../../../../../../view/common/Combobox/index';
import { intlCreateOptions } from '../../../../../../view/common/Combobox/utils';
import { WIDGET_PAYPAL_LAYOUT_CHANGE } from '../../actionTypes';
import { supportedLayouts } from '../../constants';
import { injectIntl } from '../../../../../../view/intl';

const
    id = 'LAYOUT',
    title = 'msg: common.layout {Layout}',
    view = injectIntl(({ dispatch, selectedComponent, intl }) => {
        const layout = selectedComponent.layout;
        return (
            <Page>
                <VerticalSpacer />
                <PropertyContainer label={title}>
                    <ComboBox
                        searchable={false}
                        value={layout}
                        options={intlCreateOptions(supportedLayouts, intl)}
                        onChange={({ value: layout }) => dispatch({ type: WIDGET_PAYPAL_LAYOUT_CHANGE, payload: layout })}
                    />
                </PropertyContainer>
            </Page>
        );
    });

export { id, title, view };
