import React from 'react';
import classNames from 'classnames';
import Icons from '../../../view/Icons/index';
import { GLOBAL_STYLES_DIALOG_OPENED } from './actionTypes';
import type { GlobalstyleDialogInvokerPropTypes, GlobalstyleDialogInvokerPropTypesDeprecated } from './flowTypes';
import styles from './GlobalstyleDialogInvoker.css';
import TEXT_GLOBAL_STYLES_KIND from "../../oneweb/Text/globalStyle/kind";

const
    openGlobalStylesDialogAC = (stylesheetId: string, kind: string) => ({
        type: GLOBAL_STYLES_DIALOG_OPENED,
        payload: { stylesheetId, kind }
    }),
    openTextGlobalStylesDialogAction = () => openGlobalStylesDialogAC('', TEXT_GLOBAL_STYLES_KIND),

    GlobalstyleDialogInvokerDeprecated = // TODO WBTGEN-3063 remove deprecated usages
        ({ style, dispatch, disabled = false, ...rest }: GlobalstyleDialogInvokerPropTypesDeprecated) => {
            const
                emptyFn = () => { },
                // @ts-ignore
                realFn = () => dispatch(openGlobalStylesDialogAC(rest.stylesheetId, rest.kind)),
                onClick = disabled ? emptyFn : realFn,
                cogClasses = classNames({
                    [styles.cog]: true,
                    [styles.disabled]: disabled
                }),
                Icon = disabled ? Icons.COG_DISABLED : Icons.BLUE_COG;
            return (
                <Icon
                    border
                    style={style}
                    onClick={onClick}
                    className={cogClasses}
                />
            );
        },

    GlobalstyleDialogInvoker =
        ({ disabled = false, onClick }: GlobalstyleDialogInvokerPropTypes) => {
            const cogClasses = classNames({
                [styles.cog]: true,
                [styles.disabled]: disabled
            });

            return (
                <Icons.BLUE_COG
                    border
                    onClick={onClick}
                    className={cogClasses}
                />
            );
        };

export {
    GlobalstyleDialogInvokerDeprecated as default,
    GlobalstyleDialogInvoker,
    openGlobalStylesDialogAC,
    openTextGlobalStylesDialogAction,
};
