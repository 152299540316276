/* eslint-disable max-len */

import React from 'react';
import AceEditor from 'react-ace';
import 'brace';
import 'brace/mode/html';
import 'brace/ext/searchbox';
import 'brace/theme/textmate';
import { injectIntl } from '../../../../view/intl/index';
import ComponentConfigurationDialog from '../../../../view/common/dialogs/ComponentConfigurationDialog/index';
import getCenteredDialogConfig from '../../../DialogManager/getCenteredDialogConfig';
import { getCenterPositionForDialog } from '../../../DialogManager/utility';
import closeDialogAC from '../../../App/actionCreators/closeDialog';
import { DESIGN_SET_LOCALIZATION } from '../../../App/epics/designLocalization/actionTypes';

type State = {
    code: string
};

type Props = State & {
    dispatch: Function,
    dimensions: { width: number, height: number },
};

const DialogHeaderHeightAndFooterHeight = 100;

class EditLocalizationsDialog extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = { code: props.code };
        this.setCode = this.setCode.bind(this);
    }

    setCode(code) {
        this.setState({ code });
    }

    render() {
        const { state: { code = '' }, props: { dimensions, dispatch } } = this;
        return (
            <ComponentConfigurationDialog
                title="msg: template.editLocalizations {Edit localizations}"
                onSave={() => {
                    dispatch({ type: DESIGN_SET_LOCALIZATION, payload: JSON.parse(code) });
                    dispatch(closeDialogAC());
                }}
                disabled={code.length === 0}
                noTopBorderForFooter={false}
            >
                <AceEditor
                    mode="json"
                    theme="textmate"
                    name="edit_template_styles"
                    editorProps={{ $blockScrolling: true }}
                    focus
                    showPrintMargin={false}
                    height={`${dimensions.height - DialogHeaderHeightAndFooterHeight}px`}
                    width="100%"
                    value={code}
                    markers={[]}
                    onChange={this.setCode}
                />
            </ComponentConfigurationDialog>
        );
    }
}

const getDialogDimensions = (browserWidth, browserHeight) => ({
    width: 0.8 * browserWidth,
    height: Math.max(0.8 * browserHeight, 254)
});

export const editLocalizationsDialogConfig = {
    ...getCenteredDialogConfig(800, 600, injectIntl(EditLocalizationsDialog)),
    confFactory: ({ browserWidth, browserHeight }) => {
        const { width, height } = getDialogDimensions(browserWidth, browserHeight);
        return {
            position: getCenterPositionForDialog(width, height, browserWidth, browserHeight),
            modal: true,
            dimensions: { width, height }
        };
    },
    updateOnBrowserDimensionsChanged: (config, { width, height }) => {
        return {
            ...config,
            dimensions: getDialogDimensions(width, height)
        };
    }
};
