import React from 'react';
import styles from './QuestionTip.css';
import type { QuestionTipProps } from "./QuestionTip";
import { QuestionTip } from "./QuestionTip";
import { Msg } from "../../../../view/intl/index";

export const LabeledQuestionTip = ({ children, hideHeader = false, ...props }: QuestionTipProps) => (

    <QuestionTip {...props}>
        {!hideHeader && <div className={styles.header}>
            <span className={styles.headerHintIcn} />
            <Msg k="tip" className={styles.headerLabel}>Tip:</Msg>
        </div>}
        <div className={styles.body}>
            {/* $FlowFixMe */}
            {children}
        </div>
    </QuestionTip>
);
