import React from "react";
import { pure } from 'recompose';
import { openDialogAC } from "../../../App/actionCreators/openDialog";
import Msg from '../../../../view/intl/Msg';
import { ConnectToGoogleDialogId } from "../dialog/dialogIds";
import styles from './styles.css';
import {
    MctaCombobox,
    MctaPropertyContainer
} from "../../../componentMainActions/index";
import { UPDATE_COMPONENT_LAYOUT } from "../actionTypes";
import { getLayoutOptions } from "../utils";

const MCTAView = pure((props) => {
    const {
        selectedComponent,
        dispatch
    } = props;
    return (
        <div>
            <MctaPropertyContainer label="msg: common.layoutColon {Layout:}">
                <MctaCombobox
                    className={styles.reviewLayoutOptions}
                    searchable={false}
                    value={selectedComponent.layoutType}
                    options={getLayoutOptions()}
                    onChange={({ value }) => dispatch({ type: UPDATE_COMPONENT_LAYOUT, payload: value })}
                />
            </MctaPropertyContainer>
        </div>
    );
});

export default {
    editButtonText: 'msg: component.googleReviews.reviewSettings {Review settings}',
    editView: MCTAView,
    isCtaVisible: ({ isComponentPremiumCompatible }) => isComponentPremiumCompatible,
    CtaButtonTextView: () => (
        <span className={styles.primaryButton}>
            <Msg k="component.googleReviews.changeReviews">Change reviews</Msg>
        </span>),
    ctaOnClick: ({ dispatch }) => dispatch(openDialogAC(ConnectToGoogleDialogId, { changeReviews: true }))
};
