/* eslint-disable max-len */
import React from "react";
import { useSelector } from "react-redux";
import { errorTypeSelector } from "./Dynamic/Epic/selectors";
import { ErrorModel } from "./Dynamic/view/ErrorModels";
import OnBoardWizard from "./Dynamic/view/DynamicOnboarding";

const OnboardingFlow = () => {
    const errorType = useSelector(errorTypeSelector);
    if (errorType === null) {
        return <OnBoardWizard />;
    } else {
        return <ErrorModel />;
    }
};

export { OnboardingFlow };
