/* eslint-disable max-len */

import cx from "classnames";
import React from "react";
import {
    validateMinimumLength,
    validateNoSpaces,
    isPasswordValid
} from '../validators';
import styles from "./PasswordChecker.css";
import type { PasswordCheckerPropTypes } from "../types";
import { DemoLoginMessages } from "./messages";

const PasswordChecker = ({
    intl,
    password
}: PasswordCheckerPropTypes) => {
    const { sevenCharactersMinimumPasswordChecker, noSpaceAllowedPasswordChecker } = DemoLoginMessages;
    const
        passwordCheckerLeftMapOptions = [
            {
                label: sevenCharactersMinimumPasswordChecker,
                validator: validateMinimumLength
            },
        ],
        passwordCheckerRightMapOptions = [
            {
                label: noSpaceAllowedPasswordChecker,
                validator: validateNoSpaces,
                showOnlyWhenInvalid: true,
            }
        ];

    const

        renderPasswordChecklistItem = (label, isValid, index, showErrorStyle = false) => {
            return <li
                className={cx(styles.passwordCheckerOptionsBullet, {
                    [styles.grayPasswordOptions]: isValid,
                    [styles.redPasswordOptions]: showErrorStyle
                })}
                key={index}
            >
                <span
                    className={cx(styles.passwordCheckerOptionsLabel, {
                        [styles.grayPasswordOptions]: isValid,
                        [styles.redPasswordOptions]: showErrorStyle
                    })}
                >
                    {intl.msgJoint(label)}
                </span>
            </li>;
        },

        renderPasswordCheckerMapOptions = (passwordChecker) => {
            return passwordChecker.map(({ label, validator, showOnlyWhenInvalid }, i: number) => {
                const toBeShownWhenInvalid = showOnlyWhenInvalid === true;
                let isValid = validator(password);
                if (toBeShownWhenInvalid && !isValid) {
                    return (
                        renderPasswordChecklistItem(label, isValid, i, true)
                    );
                } else if (!toBeShownWhenInvalid) {
                    return (
                        renderPasswordChecklistItem(label, isValid, i)
                    );
                }
                return '';
            });
        },
        passwordValidMsg = "msg:demo.login.password.validation.success.msg {Your password is secure and you’re all set}";

    return (
        <div className={styles.passwordCheckerContainer}>
            { !isPasswordValid(password) && [
                <div className={styles.passwordCheckLeftContainer} key={1}>
                    <ul className={styles.passwordCheckerOptionsBulletContainer}>
                        {renderPasswordCheckerMapOptions(passwordCheckerLeftMapOptions)}
                    </ul>
                </div>,
                <div className={styles.passwordCheckRightContainer} key={2}>
                    <ul className={styles.passwordCheckerOptionsBulletContainer} id="passwordCheckerOptions_demo">
                        {renderPasswordCheckerMapOptions(passwordCheckerRightMapOptions)}
                    </ul>
                </div>
            ]}
            {isPasswordValid(password) && <div className={styles.validPasswordMessageContainer} id="passwordCheckerOptions_demo">
                <span className={styles.checkBoxIcon} />
                <span className={styles.validPasswordMessage}>
                    {intl.msgJoint(passwordValidMsg)}!
                </span>
            </div>}
        </div>
    );
};

export {
    PasswordChecker
};
