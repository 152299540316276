import { DOM } from 'tinymce';
import {
    normalizeHtmlAccordingToTinyMCEHtmlSchema
} from '../components/oneweb/Text/normalizeHtmlAccordingToTinyMCEHtmlSchema';
import { makeDefaultContentOnDrop } from '../components/oneweb/Text/constants';
import textReducer from '../components/oneweb/Text/reducers/index';
import { getDefaultReducerState } from '../redux/makeReducer/index';
import { filterWordContent, isWordContent } from '../components/oneweb/Text/tinyMcePaste';
import { getOffScreenEditor } from '../components/App/epics/tinyMceEpic/updaters/getOffScreenEditor';
import { insertBrIntoEmptyParagraphs } from '../components/oneweb/Text/insertBrIntoEmptyParagraphs';
import { makeWidgetsFromClipboard } from '../components/oneweb/Widgets/makeWidgetsFromClipboard';
import type { Position } from '../redux/modules/flowTypes';
import { removeInvalidURLfromContent } from "../components/oneweb/Text/utils/removeInvalidURLfromContent";

function genericIndexOf(arrLikeItem, matchWith) {
    if (typeof arrLikeItem === 'string' || Array.isArray(arrLikeItem)) {
        // Do nothing
    } else if (typeof arrLikeItem === 'object') {
        arrLikeItem = Array.prototype.slice.call(arrLikeItem, 0); // eslint-disable-line no-param-reassign
    }

    return arrLikeItem.indexOf(matchWith);
}

export const parseClipboardData = (clipboardData: any) => {
    let plainText;
    let clipboardHtml = "";
    let componentJson;

    if (typeof clipboardData.getData === 'function') {
        plainText = clipboardData.getData('text');
        // REFERENCE: https://www.w3.org/TR/clipboard-apis/#clipboard-event-paste
        if (clipboardData.types && genericIndexOf(clipboardData.types, 'text/html') > -1) {
            clipboardHtml = clipboardData.getData('text/html');
        }
    } else {
        plainText = clipboardData;
    }

    try {
        const json = JSON.parse(plainText);
        if (typeof json === 'object') {
            componentJson = json;
        }
    } catch (e: any) {} // eslint-disable-line

    return { plainText, clipboardHtml, componentJson };
};

const makeComponentsFromClipboard = (
    clipboardData: any,
    scroll: Position = { x: 0, y: 0 }
) => {
    const { plainText, clipboardHtml, componentJson } = parseClipboardData(clipboardData);

    // Copied content is a component JSON
    if (componentJson) {
        return { plaintext: false, selectedComponents: componentJson };
    }

    // Parse for Widgets
    const widgetsData = makeWidgetsFromClipboard(plainText, scroll);
    if (widgetsData) {
        return widgetsData;
    }

    // Since nothing worked, hence we will convert content to text component
    let content;

    if (clipboardHtml.length) {
        content = clipboardHtml;

        if (isWordContent(content)) {
            content = filterWordContent(getOffScreenEditor(), content);
        }
        content = insertBrIntoEmptyParagraphs(
            removeInvalidURLfromContent(
                normalizeHtmlAccordingToTinyMCEHtmlSchema(content)
            )
        );
    } else {
        content = makeDefaultContentOnDrop(DOM.encode(plainText));
    }

    return {
        plaintext: true,
        selectedComponents: [{
            ...getDefaultReducerState(textReducer),
            top: scroll.y,
            content
        }]
    };
};

export default makeComponentsFromClipboard;
