import React from "react";
export const SvgVimeoCircleFillMulticolor = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <circle cx={24} cy={24} r={24} fill="#26b7ea" />
            <path
                fill="#ffffff"
                d="M35.578 18.813c-.108 2.344-1.746 5.558-4.913 9.633-3.275 4.253-6.046 6.385-8.314 6.385-1.402 0-2.593-1.294-3.562-3.887-.65-2.376-1.294-4.752-1.944-7.129-.72-2.592-1.491-3.886-2.32-3.886-.178 0-.809.38-1.891 1.134l-1.134-1.46a291.352 291.352 0 003.515-3.133c1.586-1.37 2.776-2.09 3.571-2.164 1.873-.18 3.03 1.1 3.464 3.844.466 2.96.79 4.8.974 5.52.54 2.456 1.134 3.684 1.783 3.684.504 0 1.261-.795 2.273-2.39 1.007-1.591 1.548-2.805 1.623-3.638.146-1.374-.395-2.066-1.623-2.066-.579 0-1.172.132-1.784.396 1.186-3.878 3.445-5.76 6.786-5.652 2.47.066 3.637 1.67 3.496 4.81z"
            />
        </g>
    </svg>
);
