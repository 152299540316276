import Auth from "@aws-amplify/auth";
import type { AwsServiceMapT } from './types';
import { getWbtgenTrialStorage } from "../../../demo/services/wbtgenTrialStorage";
import { getWbtgenTrialDb } from "../../../demo/storage/wbtgenTrialDb";

export const AwsServiceName = {
    AUTH: 'auth',
    DYNAMO_DB: 'dynamoDb',
    S3: 's3',
};

export const AwsServiceMap: AwsServiceMapT = {
    [AwsServiceName.AUTH]: {
        service: Auth,
        promisify: false,
    },
    [AwsServiceName.DYNAMO_DB]: {
        service: getWbtgenTrialDb,
        fetchCredentialBeforeCall: true,
        promisify: false,
    },
    [AwsServiceName.S3]: {
        service: getWbtgenTrialStorage,
        fetchCredentialBeforeCall: true,
        promisify: false,
    },
};
