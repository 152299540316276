import * as ActionTypes from "../../../actionTypes";
import type { SectionLinkFormState, SectionLinkFormCombinedProps } from "../../../../sectionLinkForm/flowTypes";
import sectionLinkFormCalcRenderProps from "../../../../sectionLinkForm/view/sectionLinkFormCalcRenderProps";
import { DataSite, DataSectionLink } from "../../../../../../../dal/model";
import type { SectionComponents } from "../../../../sectionComponents/epic/sectionComponentsEpic";

const actions = {
    nameOnChangeAction: ActionTypes.SECTION_LINK_INFO_NAME_CHANGE,
    pageOnChangeAction: ActionTypes.SECTION_LINK_INFO_PAGE_CHANGE,
    sectionOnChangeAction: ActionTypes.SECTION_LINK_INFO_SECTION_CHANGE,
    hiddenOnChangeAction: ActionTypes.SECTION_LINK_INFO_HIDDEN_TOGGLE,
    resetAction: ActionTypes.SECTION_LINK_INFO_RESET
};

type Input = {
    props: {
        page: DataSectionLink;
    };
    dependencies: {
        site: DataSite;
        currentPageId: string;
        sectionComponents: SectionComponents;
    };
    state: SectionLinkFormState;
};

export default ({
    props: { page },
    dependencies,
    state
}: Input): SectionLinkFormCombinedProps & { sectionLinkId: string; sectionLink: DataSectionLink } => ({
    ...sectionLinkFormCalcRenderProps({
        dependencies,
        state,
        actions
    }),
    sectionLinkId: page.id,
    sectionLink: page
});
