import React from "react";
import cx from 'classnames';
import * as styles from './Options.css';
import * as Actions from "../../../../actionTypes";
import Msg from '../../../../../../../view/intl/Msg';
import { showRed } from '../../../ContactForm.css';
import injectIntl from '../../../../../../../view/intl/injectIntl';
import { AtleastOneOptionError, MultipleOptionsError } from "../../../../constants";

export default injectIntl(({ field, values, minOptionsCount, dispatch, intl }) => {
    const errorMessage = minOptionsCount === 1 ? AtleastOneOptionError : MultipleOptionsError;

    return (
        <div className={styles.container}>
            <Msg k="component.contactForm.fieldProps.options">Options</Msg>:
            {minOptionsCount > 0 && <div className={styles.error}>{intl.msgJoint(errorMessage)}</div>}
            <div className={styles.optionsContainer}>
                {values.map(({ displayValue }, index) =>
                    <div key={index} className={styles.optionConatiner}>
                        <input
                            type="text"
                            value={displayValue}
                            className={cx({ [showRed]: displayValue && !displayValue.trim() })}
                            onChange={e => dispatch({ type: Actions.CONTACT_FORM_FIELD_OPTION_CHANGED,
                                payload: { field, option: e.target.value, index } })}
                        />
                        <div
                            className={styles.iconContainer}
                            onClick={() => dispatch({
                                type: Actions.CONTACT_FORM_FIELD_OPTION_DELETED,
                                payload: { field, index }
                            })}
                        >
                            <span className={styles.deleteIcon} />
                        </div>
                    </div>)}
            </div>
            <div
                className={styles.addOption}
                onClick={() => dispatch({ type: Actions.CONTACT_FORM_FIELD_OPTION_ADDED, payload: { field } })}
            >
                <Msg k="component.contactForm.fieldProps.addOption">Add option</Msg>
            </div>
        </div>
    );
});
