import typeCheck from "../../utils/typeCheck";

type SingleResult = Action | Promise<any>
type JoinDisaptchResults = (...args: Array<Array<SingleResult> | SingleResult>) => Array<SingleResult>
export const joinDisaptchResults: JoinDisaptchResults = function () {
    const results: any = [];

    for (let i = 0; i < arguments.length; ++i) {
        const arg = arguments[i];

        if (typeCheck(arg).isArray()) {
            arg.forEach(result => results.push(result));
        } else {
            results.push(arg);
        }
    }

    return results;
};
