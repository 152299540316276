import * as editorCommands from '../editorUtils/editorCommands';
import { makeApplyEditorChangesReducer } from "./helpers/helpers";

import { TINY_MCE_SET_SUBSCRIPT, TINY_MCE_SET_SUPERSCRIPT } from "../actionTypes";

const
    subscriptUpdater = {
        conditions: [TINY_MCE_SET_SUBSCRIPT],
        reducer: makeApplyEditorChangesReducer(({ editor, state, scope }) => {
            editor.execCommand(editorCommands.SUBSCRIPT);
            return { state, scope };
        })
    },
    superscriptUpdater = {
        conditions: [TINY_MCE_SET_SUPERSCRIPT],
        reducer: makeApplyEditorChangesReducer(({ editor, state, scope }) => {
            editor.execCommand(editorCommands.SUPERSCRIPT);
            return { state, scope };
        })
    };

export {
    subscriptUpdater,
    superscriptUpdater
};
