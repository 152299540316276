import UnsavedChangesDialog from "./UnsavedChangesDialog";
import { getCenterPositionForDialog } from "../../DialogManager/utility";
import { APP_STANDARD_ERROR_DIALOG_WIDTH, APP_STANDARD_ERROR_DIALOG_HEIGHT } from "../../App/dialogs/constants";
import getCenteredDialogConfig from "../../DialogManager/getCenteredDialogConfig";

const UnsavedChangesDialogId = 'UnsavedChangesDialogId';

const HEIGHT = APP_STANDARD_ERROR_DIALOG_HEIGHT,
    WIDTH = APP_STANDARD_ERROR_DIALOG_WIDTH,
    dialog = {
        ...getCenteredDialogConfig(WIDTH, HEIGHT, UnsavedChangesDialog),
        confFactory: ({ browserWidth, browserHeight }) => {
            return {
                position: getCenterPositionForDialog(WIDTH, HEIGHT, browserWidth, browserHeight),
                modal: true,
                dimensions: { width: WIDTH, height: HEIGHT, minHeight: HEIGHT }
            };
        },
        moveToCenter: true,
    };

export { dialog as default, UnsavedChangesDialogId };
