/* eslint-disable max-len */

export const
    PROVINCE_REQUIRED = "msg: BusinessListing.createListing.addressError.province.required {Province is required}",
    INVALID_PROVINCE = "msg: BusinessListing.createListing.addressError.province.invalid {Invalid province}",
    INVALID_POSTAL_CODE = "msg: BusinessListing.createListing.addressError.postalCode {Invalid postal code}",
    INVALID_LATLNG = "msg: BusinessListing.createListing.addressError.latlng {Enter an address and a location marker that specify the same place}",
    LOCALITY_NOT_REQUIRED_MSG = "msg: BusinessListing.createListing.addressError.locality {City is not required for the current province}",
    LOCALITY_REQUIRED_MSG = "msg: BusinessListing.createListing.addressError.locality.required {City is required for the current province}",
    OOPS_SOMETHING_WENT_WRONG = "msg: common.oopsSomethingWentWrong {Oops, it looks like something went wrong}";
