export const
    DOCUMENT_IFRAME_RETRY_INTERVAL = 4000,
    DOCUMENT_IFRAME_RETRY_NR = 5,
    supportedDocumentTypes = [
        'JPEG', 'PNG', 'GIF', 'TIFF', 'BMP', 'JPG',
        'WebM', 'MPEG4', '3GPP', 'MPEG', 'MOV', 'AVI', 'MPEGPS', 'WMV', 'FLV', '.MP3',
        'TXT', 'CSS', 'HTML', 'PHP', 'C', 'CPP', 'H', 'HPP', 'JS',
        'DOC', 'DOCX', 'XLS', 'XLSX', 'PPT', 'PPTX', 'PDF', 'PAGES',
        'PSD', 'TIFF', 'DXF', 'SVG', 'EPS', 'PS', 'TTF', 'XPS', 'ZIP', 'RAR'
    ],
    supportedDocumentRegex = new RegExp(`.(?:${supportedDocumentTypes.join('|')})$`, 'i'),
    MIN_DIMENSIONS = { width: 100, height: 100 };
