import React, { useState } from "react";
import { connect } from 'react-redux';
import cx from "classnames";
import { getDAL } from "../../../../dal/index";
import { Flex } from "../../../view/reflexbox/index";
import { injectIntl } from "../../../view/intl/index";
import { InputField } from "../../../view/common/Input/InputField";
import ComponentConfigurationDialog from "../../../view/common/dialogs/ComponentConfigurationDialog/index";
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import inputStyles from "../../../view/common/Input/InputField.css";
import { WidgetsGoogleMapsKind } from "./GoogleMaps/kind";
import { StripTypes } from "../../../view/common/dialogs/baseDialog";
import { GOOGLE_MAPS_ZOOM_LEVEL } from "./GoogleMaps/constants";
import { GoogleMapsWidget } from "./GoogleMaps/view/googleMapsWidget";
import { WIDGET_ADDED } from "./epics/index";
import { ownerEmailSelector } from '../../App/epics/ownerData/selectors';
import { type ConfigDialogParams, type ConfigurationDialogProps } from "./types";
import styles from "./view/Widgets.css";
import { WidgetsTwitterKind } from "./Twitter/kind";

const configurationDialogFactory = (config: ConfigDialogParams) => {
    const {
        saveAction,
        dialogTitle,
        dialogIconClass,
        info,
        subInfo,
        learnMore,
        isLinkValid,
        inputPlaceHolder,
        widgetType,
        registerHere,
        registerHereLink,
        serviceProviderText,
        serviceProviderLink,
        termsOfService,
        termsOfServiceLink,
        developerAgreement,
        developerPolicy
    } = config;

    const configurationDialogView = injectIntl((props: ConfigurationDialogProps) => {
        const {
            link = "",
            replace = false,
            intl,
            dispatch,
            zoom = GOOGLE_MAPS_ZOOM_LEVEL,
            addressText,
            addressLocation,
            addressUrl,
            ownerEmail,
        } = props;

        const domain = getDAL().getDomain();

        let learnMoreUrl = "";

        // check if learnMore is a function
        if (typeof learnMore === "function") {
            learnMoreUrl = learnMore(domain, ownerEmail);
        } else if (learnMore) {
            learnMoreUrl = intl.msgJoint(learnMore);
        }

        const LearnMore = () => (
            <a target="_blank" className={cx(styles.info, styles.learnMore)} href={learnMoreUrl}>
                {intl.msgJoint("msg: common.learnMore {Learn more}")}
            </a>
        );

        let registerHereUrl = "";
        // check if registerHereLink is a function
        if (typeof registerHereLink === "function") {
            registerHereUrl = registerHereLink(domain, ownerEmail);
        } else if (registerHereLink) {
            registerHereUrl = intl.msgJoint(registerHereLink);
        }

        let serviceProviderUrl = "";
        // check if serviceProviderLink is a function
        if (typeof serviceProviderLink === "function") {
            serviceProviderUrl = serviceProviderLink(domain, ownerEmail);
        } else if (serviceProviderLink) {
            serviceProviderUrl = intl.msgJoint(serviceProviderLink);
        }

        const RegisterHere = () => (
            registerHere && registerHereUrl ?
                (<div className={styles.registerHere}>
                    {intl.msgJoint([registerHere, {
                        registerHereLink:
                        (<a target="_blank" className={cx(styles.registerHereLink)} href={registerHereUrl}>
                            {intl.msgJoint("msg: common.registerForFreeTrial {Register for a free trial.}")}
                        </a>)
                    }])}
                </div>) : null);

        const TermsOfService = () => {
            return termsOfService && termsOfServiceLink ?
                (<div className={styles.footerTextContent}>
                    {intl.msgJoint([termsOfService, {
                        termsOfServiceLink:
                        // @ts-ignore
                        (<a target="_blank" className={cx(styles.info, styles.learnMore)} href={intl.msgJoint(termsOfServiceLink)}>
                            {intl.msgJoint("msg: common.termsOfUse {API Terms of Use.}")}
                        </a>)
                    }])}
                </div>) : null;
        };

        const DeveloperAgreementAndPolicy = () => (
            termsOfService && developerAgreement && developerPolicy ?
                (<div className={styles.footerTextContent}>
                    {intl.msgJoint([termsOfService, {
                        developerAgreement:
                            // @ts-ignore
                            (<a target="_blank" className={cx(styles.info, styles.learnMore)} href={intl.msgJoint(developerAgreement)}>
                                {intl.msgJoint("msg: common.developerAgreement {Developer Agreement}")}
                            </a>),
                        developerPolicy:
                            // @ts-ignore
                            (<a target="_blank" className={cx(styles.info, styles.learnMore)} href={intl.msgJoint(developerPolicy)}>
                                {intl.msgJoint("msg: common.developerPolicy {Developer Policy.}")}
                            </a>)
                    }])}
                </div>) : null
        );

        const [widgetLink, setWidgetLink] = useState(link);
        const [zoomLevel, setZoomLevel] = useState(zoom);
        const [addressTextState, setAddressTextState] = useState(addressText || props?.dependencies?.generalData?.addressText || "");
        const [addressLocationState, setAddressLocationState] = useState(
            addressLocation || props?.dependencies?.generalData?.addressLocation
        );
        const [addressUrlState, setAddressUrlState] = useState(addressUrl || props?.dependencies?.generalData?.addressUrl);
        const valid = isLinkValid(widgetType === WidgetsGoogleMapsKind ? addressUrlState : widgetLink),
            onSave = () => {
                dispatch({
                    type: saveAction,
                    payload: {
                        replace,
                        link: widgetLink,
                        zoom: zoomLevel,
                        addressText: addressTextState,
                        addressLocation: addressLocationState,
                        addressUrl: addressUrlState
                    }
                });
                if (!replace) dispatch({ type: WIDGET_ADDED });
            };

        return (
            <ComponentConfigurationDialog
                onSaveText="msg: common.ok {OK}"
                onSave={onSave}
                autoFocus={false}
                disabled={!addressLocationState && !valid}
                stripType={StripTypes.NONE}
                footerV2
                titleBoxClassName={styles.smallHeader}
                footerContent={widgetType && widgetType === WidgetsTwitterKind ? <DeveloperAgreementAndPolicy /> : <TermsOfService />}
                footerContentClassName={widgetType && widgetType === WidgetsTwitterKind ?
                    styles.footerContentTwitter :
                    styles.footerContent}
            >
                <Flex className={styles.mlr32} column>
                    <div className={dialogIconClass} />
                    {dialogTitle && (
                        <React.Fragment>
                            <VerticalSpacer y={16} />
                            <div className={styles.dialogTitle}>{intl.msgJoint(dialogTitle)}</div>
                        </React.Fragment>
                    )}
                    {info && (
                        <React.Fragment>
                            <VerticalSpacer y={16} />
                            <div className={styles.info}>
                                {serviceProviderText ? intl.msgJoint([info, {
                                    serviceProviderLink:
                                    (<a target="_blank" className={cx(styles.info, styles.serviceProviderLink)} href={serviceProviderUrl}>
                                        {intl.msgJoint(serviceProviderText)}
                                    </a>)
                                }]) : intl.msgJoint(info)}
                                &nbsp;
                                {!subInfo && learnMore && <LearnMore />}
                            </div>
                        </React.Fragment>
                    )}
                    {subInfo && (
                        <React.Fragment>
                            <VerticalSpacer y={12} />
                            <div className={styles.info}>
                                {intl.msgJoint(subInfo)}
                                &nbsp;
                                {learnMore && <LearnMore />}
                            </div>
                        </React.Fragment>
                    )}
                    <VerticalSpacer y={28} />
                    {widgetType && widgetType === WidgetsGoogleMapsKind ? (
                        // @ts-ignore
                        <GoogleMapsWidget
                            zoom={zoomLevel}
                            setZoom={setZoomLevel}
                            addressText={addressTextState}
                            setAddressText={setAddressTextState}
                            addressLocation={addressLocationState}
                            setAddressLocation={setAddressLocationState}
                            addressUrl={addressUrlState}
                            setAddressUrl={setAddressUrlState}
                        />
                    ) : (
                        <React.Fragment>
                            <InputField
                                value={widgetLink}
                                placeholder={inputPlaceHolder}
                                onChange={(value: string) => {
                                    setWidgetLink(value);
                                }}
                                onEnterPress={valid && onSave}
                                className={cx(styles.inputBox, { [`${inputStyles.invalidInput}`]: !valid })}
                            />
                            {registerHere && registerHereLink && <RegisterHere />}
                        </React.Fragment>
                    )}
                </Flex>
            </ComponentConfigurationDialog>
        );
    });

    const mapStateToProps = (appState) => ({ ownerEmail: ownerEmailSelector(appState) });
    const configurationDlgView = connect(mapStateToProps)(configurationDialogView);

    return configurationDlgView;
};

export { configurationDialogFactory };
