import { getReduceFactor } from "../Gallery/utils";
import { ForcedHDImagePixelRatios, ImagePixelRatios, JpegCompressionQuality } from './constants';
import { getAssetUrl } from "../../../utils/assetUtils";

import type { ImageComponent } from './flowTypes';

type Config = {
    hdImages: boolean;
    w: number;
    h: number;
    innerWidth: number;
    innerHeight: number;
    left: number;
    top: number;
};

const makeApplyTransformsFitStretchMode =
    (forceHD: boolean) => (image: ImageComponent, config: Config, domain?: string): Object => {
        let minPixelRatioURL,
            srcSet,
            cssUrls = {},
            { hdImages, innerWidth, innerHeight } = config,
            pixelRatios,
            reduceFactor,
            w,
            h,
            asset = image.asset;

        if (!hdImages) {
            pixelRatios = [1];
        } else if (forceHD) {
            pixelRatios = ForcedHDImagePixelRatios;
        } else {
            pixelRatios = ImagePixelRatios;
        }

        pixelRatios.forEach(function (pixelRatio) {
            let processParams: Record<string, any> = {};

            if (image.rotation !== 0) {
                processParams.rotate = image.rotation;
            }
            w = innerWidth;
            h = innerHeight;
            if (w !== asset.width || h !== asset.height) {
                // TODO: Check if "maxDimension" fix can be improved.
                //       "maxDimension" fix added is same as the commit c938f07a for ONEWEB-10472
                if (hdImages) {
                    w = w * pixelRatio;
                    h = h * pixelRatio;
                }
                reduceFactor = getReduceFactor(w, h, asset.width, asset.height);
                w = Math.max(1, Math.round(w * reduceFactor));
                h = Math.max(1, Math.round(h * reduceFactor));
                processParams.ignoreAspectRatio = null; // so that we just put query parameter with no value
                processParams.resize = w + '+' + h;
            }

            if (Object.keys(processParams).length > 0) {
                if (asset.contentType === 'image/jpeg') {
                    processParams.quality = JpegCompressionQuality;
                }
            }

            let url = getAssetUrl(asset, processParams, domain);

            if (hdImages) {
                srcSet = srcSet || [];
                srcSet.push(url + ' ' + pixelRatio + 'x');
            }
            if (hdImages || pixelRatio === 1) {
                cssUrls[pixelRatio] = url;
            }
            minPixelRatioURL = minPixelRatioURL || url;
        });

        return {
            src: minPixelRatioURL,
            srcSet: srcSet && srcSet.join(', '),
            cssUrls
        };
    };

export const applyTransformsFitStretchMode = makeApplyTransformsFitStretchMode(false);
export const applyTransformsFitStretchModeHD = makeApplyTransformsFitStretchMode(true);
