import {
    FREE_UPGRADE_APPROVED_ACTION,
    PLANS_OVERVIEW_DIALOG_OPENED_ACTION,
    UPGRADE_BUTTON_CLICKED_ACTION,
    UPGRADE_CANCELED_ACTION,
    UPGRADE_COMPLETED_ACTION,
    UPGRADE_DIALOG_OPENED_ACTION
} from '../../../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions';
import { EventTrackingIds, EventTrackingProps, UpgradeOffer } from '../../../../EventTracking/constants';

export const UpgradeEvents = {
    [UPGRADE_DIALOG_OPENED_ACTION]: {
        eventName: EventTrackingIds.WSB_UPGRADE_PLANS_DIALOG_OPENED,
        propsHandler: (_, payload) => {
            return {
                [EventTrackingProps.source]: payload.source
            };
        }
    },
    [PLANS_OVERVIEW_DIALOG_OPENED_ACTION]: {
        eventName: EventTrackingIds.WSB_UPGRADE_PLANS_DIALOG_VIEWED,
        propsHandler: (_, payload) => {
            return {
                [EventTrackingProps.source]: payload.source
            };
        }
    },
    [UPGRADE_BUTTON_CLICKED_ACTION]: {
        eventName: EventTrackingIds.WSB_UPGRADE_BUTTON_CLICKED,
        propsHandler: (_, payload) => {
            return {
                [EventTrackingProps.source]: payload.source,
                [EventTrackingProps.upgradedsubscriptionType]: payload.upgradeType,
                [EventTrackingProps.upgradeOffer]: payload.freeUpgrade ? UpgradeOffer.Free : UpgradeOffer.Paid,
            };
        }
    },
    [UPGRADE_CANCELED_ACTION]: {
        eventName: EventTrackingIds.WSB_UPGRADE_CANCELED,
        propsHandler: (_, payload) => {
            return {
                [EventTrackingProps.upgradecancelReason]: payload.cancelReason
            };
        }
    },
    [FREE_UPGRADE_APPROVED_ACTION]: {
        eventName: EventTrackingIds.WSB_FREE_UPGRADE_APPROVED
    },
    [UPGRADE_COMPLETED_ACTION]: {
        eventName: EventTrackingIds.WSB_UPGRADE_SUCCESSFUL,
        propsHandler: (_, payload) => {
            return {
                [EventTrackingProps.subscriptionType]: payload.fromSubscriptionType,
                [EventTrackingProps.upgradedsubscriptionType]: payload.toSubscriptionType
            };
        }
    },
};
