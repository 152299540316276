import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly } from "../../../../epics/makeCondition";
import valueActionType from './valueActionType';
import * as Actions from "../../actionTypes";
import { SHOW_STICKY_TOOLTIP } from "../../../Tooltip/stickyTooltip/actionTypes";
import { SHOW_TOOLTIP_FOR_SECTION_ONBOARDING } from "../../../Tooltip/actionTypes";
import { SavingYourSite, TipsSequence, FULL_TOUR, SECTIONS_TOUR, TipsData, FULL_TOUR_WITH_TUTORIAL } from "../../constants";
import { PANEL_RESIZE_HANDLE_CLICKED } from "../../../Panel/actionTypes";
import panelExpandedValueActionType from '../../../Panel/epics/expanded/valueActionType';
import {
    GET_HELP_BTN_CLIENT_RECT,
    HELP_BTN_CLIENT_RECT, PAGES_TREE_CONTAINER_CLIENT_RECT, SAVE_BTN_CLIENT_RECT,
    TOP_BAR_CLOSE_POPUP,
    TUTORIAL_BTN_CLIENT_RECT
} from "../../../TopBar/actionTypes";
import browserDimensionsValueActionType from '../../../App/epics/browserDimensions/valueActionType';
import { calcTipTop, PointerDimensions } from "./calcTipStyles";
import { FindTips, FindVideoTipOnSkip } from "../../../Tooltip/ids";
import { closeDialog } from "../../../App/actionCreators/index";
import { LEFT_PRESS, RIGHT_PRESS } from "../../../App/kbdActionTypes";
import { ESC_KEY_PRESSED } from "../../../App/actionTypes";
import { CHECK_STICKY_TOOLTIP_AFTER_QUICK_TOUR } from "../../../Tooltip/epics/sectionInfoTooltip/index";
import {
    ReceiveOnlyComponentsMap,
    ReceiveOnlySelectedComponentsSelector
} from "../../../Workspace/epics/componentsEval/selectorActionTypes";
import { getSecondPageSection } from "../../../oneweb/Section/utils";
import workspaceScrollValueActionType from '../../../Workspace/epics/scroll/valueActionType';
import templateOffsetVAT from '../../../oneweb/Template/epics/templateOffset/valueActionType';
import { componentsPanelActionsVAT } from "../../../Workspace/epics/componentPanelActions/valueActionType";
import { workspaceHandlesValueActionType } from "../../../Workspace/epics/handles/valueActionType";
import { WORKSPACE_SCROLL_TO_SELECTED_COMPONENT } from "../../../Workspace/actionTypes";
import { SET_SELECTED_COMPONENTS } from "../../../Workspace/epics/componentsEval/actionTypes";
import { SECTION_INTRO_TOASTER_DELAY } from "../../../Tooltip/epics/tooltip/index";
import {
    ROCodeComponentsRendererHeadHeightSelector
} from "../../../Workspace/CodeComponentsRenderer/epic/selectorActionTypes";
import { ROLastPublished } from '../../../TopBar/epics/checkPublishStatus/selectorActionTypes';
import { shouldShowTutorial } from '../../../TopBar/view/TopBarItemMenu/TopBarTutorial';
import languagesVAT from '../../../TopBar/epics/languages/valueActionType';

const
    defaultStyles = {
        tipStyle: { opacity: 0 },
        pointerStyle: { ...PointerDimensions },
        spotLightStyles: {}
    },
    defaultState = {
        show: false,
        tipNumber: -1,
        helpBtnGetClientRect: false,
        pagesTreeContainerGetClientRect: false,
        saveBtnGetClientRect: false,
        tutorialBtnGetClientRect: false,
        ...defaultStyles
    },
    nextAndPreviousUpdaters = [
        Actions.SHOW_NEXT_TIP,
        Actions.SHOW_PREVIOUS_TIP,
        LEFT_PRESS,
        RIGHT_PRESS
    ].map(action => ({
        conditions: [action],
        reducer: ({ state }) => {
            const { show, tipNumber } = state,
                next = (action === Actions.SHOW_NEXT_TIP || action === RIGHT_PRESS);
            const tipSequence = state.tourType === FULL_TOUR_WITH_TUTORIAL ? TipsSequence[FULL_TOUR] :
                TipsSequence[FULL_TOUR].filter(type => type !== "VideoTutorial");
            if (!show || (next && tipNumber === tipSequence.length - 1) || (!next && tipNumber === 0)) {
                return { state };
            }
            const newTipNumber = next ? (tipNumber + 1) : (tipNumber - 1);
            return {
                state: {
                    ...state,
                    ...defaultStyles,
                    tipNumber: newTipNumber,
                    showPreviousTipBtn: newTipNumber > 0,
                    showNextTipBtn: newTipNumber < tipSequence.length - 1,
                    showSkipTour: newTipNumber === 0,
                    showStartBuilding: state.tourType === FULL_TOUR && newTipNumber === tipSequence.length - 1,
                    showWatchVideo: state.tourType === FULL_TOUR_WITH_TUTORIAL && newTipNumber === tipSequence.length - 1,
                    showLetsGo: state.tourType === SECTIONS_TOUR && newTipNumber === tipSequence.length - 1,
                    saveBtnGetClientRect: tipSequence[newTipNumber] === SavingYourSite,
                    tutorialBtnGetClientRect: state.tourType === FULL_TOUR_WITH_TUTORIAL
                }
            };
        }
    })),
    closeUpdaters = [Actions.STOP_QUICK_TOUR, ESC_KEY_PRESSED].map(action => ({
        conditions: [ROLastPublished, action],
        reducer: ({ state, values: [lastPublished], dispatchAsync }) => {
            if (state.show) {
                let { left, width } = state.helpBtnClientRect;
                const tutorialBtnRect = state.tutorialBtnClientRect;
                let id = FindTips;

                if (tutorialBtnRect && !lastPublished && state.tipNumber < 5 && state.tourType === FULL_TOUR_WITH_TUTORIAL) {
                    left = tutorialBtnRect.left;
                    width = tutorialBtnRect.width;
                    id = FindVideoTipOnSkip;
                }

                if (state.showSectionIntroToaster) {
                    setTimeout(() => {
                        dispatchAsync({ type: SHOW_TOOLTIP_FOR_SECTION_ONBOARDING });
                    }, SECTION_INTRO_TOASTER_DELAY);
                }

                return {
                    state: { ...defaultState },
                    multipleActionsToDispatch: [
                        {
                            type: SHOW_STICKY_TOOLTIP,
                            payload: {
                                position: {
                                    x: left + (width / 2),
                                    y: 20
                                },
                                id,
                                showIcon: false,
                                textStyle: { textAlign: 'center' }
                            }
                        },
                        { type: CHECK_STICKY_TOOLTIP_AFTER_QUICK_TOUR }
                    ]
                };
            }
            return { state };
        }
    })),
    getTourState = ({ values: [expanded, componentsMap, sectionPayload] }, tourType) => {
        let secondPageSection = getSecondPageSection(componentsMap);
        let result = {
            state: {
                ...defaultState,
                show: true,
                tipNumber: 0,
                showPreviousTipBtn: false,
                showNextTipBtn: true,
                showSkipTour: true,
                helpBtnGetClientRect: true,
                pagesTreeContainerGetClientRect: true,
                saveBtnGetClientRect: false,
                tutorialBtnGetClientRect: true,
                tourType,
                showSectionIntroToaster: sectionPayload && sectionPayload.showSectionIntroToaster,
            },
            multipleActionsToDispatch: [
                { type: TOP_BAR_CLOSE_POPUP },
                closeDialog(),
            ]
        };
        if (tourType === SECTIONS_TOUR && secondPageSection) {
            const payload: Array<string> = [secondPageSection.id];
            // @ts-ignore
            result.multipleActionsToDispatch.push({ type: SET_SELECTED_COMPONENTS, payload });
            result.multipleActionsToDispatch.push({ type: WORKSPACE_SCROLL_TO_SELECTED_COMPONENT });
        }
        if (!expanded) {
            result.multipleActionsToDispatch.push({ type: PANEL_RESIZE_HANDLE_CLICKED });
        }
        return result;
    };

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                receiveOnly(panelExpandedValueActionType),
                ReceiveOnlyComponentsMap,
                receiveOnly(languagesVAT),
                ROLastPublished,
                Actions.START_QUICK_TOUR
            ],
            reducer: ({ values: [
                expanded,
                componentsMap,
                language,
                lastPublished
            ] }) => (getTourState({ values: [expanded, componentsMap, null] },
                !lastPublished && shouldShowTutorial(language.current) ? FULL_TOUR_WITH_TUTORIAL : FULL_TOUR))
        },
        {
            conditions: [
                receiveOnly(panelExpandedValueActionType),
                ReceiveOnlyComponentsMap,
                Actions.START_SECTION_TOUR
            ],
            reducer: (epicReducerValues) => (getTourState(epicReducerValues, SECTIONS_TOUR))
        },
        {
            conditions: [
                receiveOnly(browserDimensionsValueActionType),
                ReceiveOnlySelectedComponentsSelector,
                receiveOnly(workspaceScrollValueActionType),
                receiveOnly(templateOffsetVAT),
                receiveOnly(componentsPanelActionsVAT),
                receiveOnly(workspaceHandlesValueActionType),
                ROCodeComponentsRendererHeadHeightSelector,
                Actions.TIP_HEIGHT_CHANGED
            ],
            reducer: ({ values:
                [browserDimensions,
                    selectedComponents,
                    scroll,
                    templateOffset,
                    componentPanel,
                    handles,
                    codeComponentsRendererHeadHeight,
                    tipClientRect], state }) => {
                const tipSequence = state.tourType === FULL_TOUR_WITH_TUTORIAL ? TipsSequence[FULL_TOUR] :
                    TipsSequence[FULL_TOUR].filter(type => type !== "VideoTutorial");
                if (state.show && TipsData[tipSequence[state.tipNumber]].calcTipStyles) {
                    const selectedComponent = selectedComponents[0];
                    const {
                        helpBtnClientRect,
                        pagesTreeContainerClientRect,
                        saveBtnClientRect,
                        tutorialBtnClientRect
                    } = state;
                    return {
                        state: {
                            ...state,
                            ...defaultStyles,
                            ...TipsData[tipSequence[state.tipNumber]].calcTipStyles({
                                tipClientRect,
                                browserDimensions,
                                helpBtnClientRect,
                                pagesTreeContainerClientRect,
                                saveBtnClientRect,
                                tutorialBtnClientRect,
                                selectedComponent,
                                scroll,
                                templateOffset,
                                componentPanel,
                                handles,
                                codeComponentsRendererHeadHeight,
                            })
                        }
                    };
                }
                return { state };
            }
        },
        {
            conditions: [
                browserDimensionsValueActionType,
                receiveOnly(Actions.TIP_HEIGHT_CHANGED)
            ],
            reducer: ({ values: [{ height }, tipClientRect], state }) => {
                const { tipNumber } = state;
                if (tipNumber === 0) {
                    return {
                        state: {
                            ...state,
                            tipStyle: {
                                ...state.tipStyle,
                                top: calcTipTop(height, tipClientRect.height)
                            }
                        }
                    };
                }
                return { state };
            }
        }, {
            conditions: [GET_HELP_BTN_CLIENT_RECT],
            reducer: ({ state }) => ({ state: { ...state, helpBtnGetClientRect: true } })
        },
        {
            conditions: [HELP_BTN_CLIENT_RECT],
            reducer: ({ values: [helpBtnClientRect], state }) => ({
                state: { ...state, helpBtnClientRect, helpBtnGetClientRect: false }
            })
        },
        {
            conditions: [TUTORIAL_BTN_CLIENT_RECT],
            reducer: ({ values: [tutorialBtnClientRect], state }) => ({
                state: { ...state, tutorialBtnClientRect }
            })
        },
        {
            conditions: [PAGES_TREE_CONTAINER_CLIENT_RECT],
            reducer: ({ values: [pagesTreeContainerClientRect], state }) =>
                ({ state: { ...state, pagesTreeContainerClientRect, pagesTreeContainerGetClientRect: false } })
        },
        {
            conditions: [SAVE_BTN_CLIENT_RECT],
            reducer: ({ values: [saveBtnClientRect], state }) =>
                ({ state: { ...state, saveBtnClientRect, saveBtnGetClientRect: false } })
        },
        ...nextAndPreviousUpdaters,
        ...closeUpdaters
    ]
});
