import * as React from 'react';
import styles from './Products.css';
import SearchBox from './SearchBox';
import ProductList from './ProductList';

type Props = {
    applyFilters: Function
};

const ProductsContainer = ({ applyFilters, ...props }: Props) => {
    return (
        <div className={styles.container}>
            <SearchBox onSearch={str => applyFilters({ productName: str })} />
            { /* @ts-ignore */ }
            <ProductList {...props} />
        </div>
    );
};

export default ProductsContainer;
