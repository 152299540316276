import { WidgetsTwitterKind } from './kind';
import { componentMainActions } from './componentMainAction';
import { propertiesPanel } from './propertiesPanel/index';
import { reducer } from './reducer';
import { configurationDialogId } from './configurationDialog';
import { calcRenderProps } from './calcRenderProps';
import { TwitterWorkspace } from './view/workspace';
import { TwitterView } from './view/index';
import { WidgetsTwitterConfig } from '../constants';
import { getMVEHiddenElementData } from '../mobileEditorConfig';
import { adjustmentHookConfig } from './adjustmentHookConfig';

const WidgetsTwitter = {
    kind: WidgetsTwitterKind,
    label: WidgetsTwitterConfig.title,
    shortcutIconName: 'widgetsTwitter',
    workspace: TwitterWorkspace,
    view: TwitterView,
    requireConfigurationOnDrop: () => true,
    configurationDialog: configurationDialogId,
    calcRenderProps,
    componentMainActions,
    propertiesPanel,
    reducer,
    adjustmentHookConfig,
    mobileEditorConfig: {
        kind: WidgetsTwitterKind,
        calcProps: calcRenderProps,
        view: TwitterView,
        getHiddenElementData: getMVEHiddenElementData(WidgetsTwitterConfig),
    },
};

export { WidgetsTwitter };

