import { sizeIsNotValid } from '../../../../../utils/FileListValidator';
import type { ResourceMetadata } from '../flowTypes';

type ErrorKind = 'CORRUPTED_IMAGE_ERROR' | 'MAX_IMAGE_SIZE_ERROR'

const
    UNSUPPORTED_IMAGE_FORMAT_ERROR = 'Input buffer contains unsupported image format',
    CORRUPTED_IMAGE_ERROR = 'CORRUPTED_IMAGE_ERROR',
    MAX_IMAGE_SIZE_ERROR = 'MAX_IMAGE_SIZE_ERROR',
    codeToMsg = {
        MAX_IMAGE_SIZE_ERROR: 'msg: fc.metadataValidation.err.maxImgSize {Sorry, these images are too big. Please add images smaller than 64 megapixels. }', // eslint-disable-line
        CORRUPTED_IMAGE_ERROR: 'msg: fc.metadataValidation.err.corruptedImg {Sorry, it looks like there is something wrong with these images. Try again or choose some other images. }' // eslint-disable-line
    },

    getMessageForErrorKind = (errorKind: ErrorKind) => {
        return codeToMsg[errorKind];
    },
    getValidationErrorKind = (metadata: ResourceMetadata | false) => {
        if (!metadata) {
            return CORRUPTED_IMAGE_ERROR;
        }

        if (sizeIsNotValid(metadata.width, metadata.height)) {
            return MAX_IMAGE_SIZE_ERROR;
        }

        return null; // no error
    };

export {
    getValidationErrorKind as default,
    getMessageForErrorKind,
    MAX_IMAGE_SIZE_ERROR,
    CORRUPTED_IMAGE_ERROR,
    UNSUPPORTED_IMAGE_FORMAT_ERROR
};
