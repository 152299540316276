
/* eslint-disable max-len */
import React from 'react';
import { connect } from "react-redux";
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import * as pageIds from '../pageIds';
import pagesTitles from '../pageTitles';
import { Msg, injectIntl, Intl } from '../../../../../view/intl/index';
import * as styles from '../StripPropertiesPanel.css';
import PropertyContainer from "../../../../../view/common/PropertyContainer/index";
import InputField from "../../../../../view/common/Input/PropertiesInputField";
import lengthInputValidation from "../../../../inputControls/input/commonValidations/lengthInputValidation";
import type { AppState } from "../../../../../redux/modules/flowTypes";
import { workspaceComponentsMapSelector } from "../../../../Workspace/selectors";
import { getStripComponents } from "../../../../Workspace/epics/componentsEval/selectors";
import { changeStripTitleAC } from "../../../../PinComponentDialog/actionCreators/updateComponentPin";
import memo from "../../../../../../utils/memo";
import { SHOW_TOOLTIP_FOR_STRIP_TITLE, CLOSE_TOOLTIP } from "../../../../Tooltip/actionTypes";
import { StripTitleErrorTip } from "../../../../Tooltip/ids";
import type { StripComponent } from "../../flowTypes";
import type { AnyComponent } from "../../../flowTypes";
import { isStripKind } from "../../../componentKinds";

const STRIP_TITLE_MAX_LENGTH = 50,
    STRIP_TITLE_MIN_LENGTH = 1;

const
    id = pageIds.TITLE,
    title = pagesTitles[id],
    sectionLinksDocURL = 'msg: sectionLink.learnMoreLink {https://help.one.com/hc/en-us/articles/360005542937}',
    invalidStripTitleRegex = /[!@#$%^&*(),.?"':{}|<>]/,
    emptyStripTitleRegex = /^$/,
    emptyErrorTitle = 'msg: component.section.pp.title.empty.error {Add a title for the section.}',
    invalidStripTitle = 'msg: component.section.pp.title.splCharacters.error {Add a title without any special characters}',
    uniqueStripTitle = 'msg: component.section.pp.title.unique.error {This section title is already used. Try with another title.}',

    emptyStripTitleValidator = (value) => !emptyStripTitleRegex.test(value),
    stripTitleValidator = (value) => !invalidStripTitleRegex.test(value);

type Props = {
    dispatch: Dispatch;
    stripComponents: Array<StripComponent>;
    selectedComponent: AnyComponent;
    additionalPayload?: Object;
    intl: Intl;
}

class TitlePageComponent extends React.Component<Props, null> {
    validators = [
        { validator: emptyStripTitleValidator, message: emptyErrorTitle },
        { validator: stripTitleValidator, message: invalidStripTitle },
    ];

    validateStripName(stripName: string) {
        const { stripComponents, selectedComponent } = this.props,
            uniqueStrip = (value) =>
                !stripComponents.find((cmp) => cmp.id !== selectedComponent.id && value === cmp.title);

        return [
            ...this.validators,
            { validator: uniqueStrip, message: uniqueStripTitle },
        ].reduce((result, obj) => result || (!obj.validator(stripName) ? obj.message : result), '');
    }

    showOrHideError(stripName: string): boolean {
        const validationErrorMsg = this.validateStripName(stripName);
        if (validationErrorMsg) {
            // @ts-ignore
            const { top, width, height, left } = this.refs.inputField.getBoundingClientRect();
            this.props.dispatch({ type: SHOW_TOOLTIP_FOR_STRIP_TITLE,
                payload: {
                    position: {
                        x: left + (width / 2),
                        y: top + height
                    },
                    elementDimensions: { top, width, height, left },
                    id: StripTitleErrorTip,
                    customClass: styles.validationToolTip,
                    msg: { text: validationErrorMsg },
                } });
            return true;
        }
        this.props.dispatch({ type: CLOSE_TOOLTIP });
        return false;
    }

    handleOnChange(stripName: string, input: HTMLInputElement) {
        if (!this.showOrHideError(stripName)) {
            const finalStripName = stripName.replace(/\s/g, '-');
            this.props.dispatch(changeStripTitleAC(finalStripName));
            if (finalStripName !== stripName) {
                const { selectionStart, selectionEnd } = input;
                setTimeout(() => {
                    input.selectionStart = selectionStart; // eslint-disable-line
                    input.selectionEnd = selectionEnd; // eslint-disable-line
                });
            }
        }
    }
    render() {
        const
            { selectedComponent, stripComponents, additionalPayload = {}, intl } = this.props,
            // @ts-ignore
            { autoFocus = false } = additionalPayload,
            disabled = isStripKind(selectedComponent.kind),
            uniqueStrip = (value) =>
                !stripComponents.find((cmp) => cmp.id !== selectedComponent.id && value === cmp.title),
            sectionLink = (
                <a target="_blank" href={intl.msgJoint(sectionLinksDocURL)} className={styles.anchorLink}>
                    <Msg k="common.sectionLinks">
                        section links
                    </Msg>
                </a>
            );

        let titleLabelKey, titleDesc;
        if (isStripKind(selectedComponent.kind)) {
            titleLabelKey = 'msg: component.strip.pp.title.label {Strip title}';
            titleDesc = (<Msg k="component.strip.pp.title.description" className={styles.stripTitleDesc}>
                Use the strip title to easily distinguish between different strips when creating section links.
            </Msg>);
        } else {
            titleLabelKey = 'msg: component.section.pp.title.label {Section title}';
            titleDesc = (
                <Msg
                    k="component.section.pp.title.description"
                    className={styles.stripTitleDesc}
                    params={{ sectionLink }}
                >
                    {`Give the section a title relevant for its purpose on your page - e.g. \"Contact-section\". This helps you when creating {sectionLink}.`}
                </Msg>
            );
        }

        return <Page>
            <VerticalSpacer />
            <PropertyContainer label={titleLabelKey}>
                <div ref="inputField">
                    <InputField
                        value={selectedComponent.title || ''}
                        onChange={(stripName, isValid, input) => this.handleOnChange(stripName, input)}
                        validators={[
                            ...this.validators,
                            { validator: uniqueStrip, message: uniqueStripTitle },
                        ]}
                        inputValidations={[{
                            validation: lengthInputValidation,
                            options: { min: STRIP_TITLE_MIN_LENGTH, max: STRIP_TITLE_MAX_LENGTH },
                        }]}
                        disabled={disabled}
                        onFocus={({ v: stripName }) => { this.showOrHideError(stripName); }}
                        onBlur={() => this.props.dispatch({ type: CLOSE_TOOLTIP })}
                        autoFocus={autoFocus}
                        errorTitle={value => (!value ? emptyErrorTitle : null)}
                        maxLength={STRIP_TITLE_MAX_LENGTH}
                        counter={{ maxLength: STRIP_TITLE_MAX_LENGTH }}
                    />
                </div>
            </PropertyContainer>
            <VerticalSpacer y={10} />
            {titleDesc}
        </Page>;
    }
}

const memoizeStripCmps = memo(getStripComponents);

const mapStateToProps = (appState: AppState) => ({
    stripComponents: memoizeStripCmps(workspaceComponentsMapSelector(appState))
});

// @ts-ignore
export default { id, title, view: injectIntl(connect(mapStateToProps)(TitlePageComponent)) };
