export const
    FACEBOOK_FEED_GALLERY_SET_IMAGES_ON_COMPONENT_DROPPED = 'FACEBOOK_FEED_GALLERY_SET_IMAGES_ON_COMPONENT_DROPPED',
    FACEBOOK_FEED_GALLERY_PP_COLUMNS_CHANGED = 'FACEBOOK_FEED_GALLERY_PP_COLUMNS_CHANGED',
    FACEBOOK_FEED_GALLERY_PP_SPACING_CHANGED = 'FACEBOOK_FEED_GALLERY_PP_SPACING_CHANGED',
    FACEBOOK_FEED_GALLERY_PP_CAPTIONS_ENABLED_CHECKBOX_CLICKED = 'FACEBOOK_FEED_GALLERY_PP_CAPTIONS_ENABLED_CHECKBOX_CLICKED',
    FACEBOOK_FEED_GALLERY_ON_CLICK_ACTION_CHANGED = 'FACEBOOK_FEED_GALLERY_ON_CLICK_ACTION_CHANGED',
    FACEBOOK_FEED_GALLERY_PP_ROWS_CHANGED = 'FACEBOOK_FEED_GALLERY_PP_ROWS_CHANGED',
    FACEBOOK_FEED_GALLERY_PP_SHOW_MORE_BTN_ENABLED = 'FACEBOOK_FEED_GALLERY_PP_SHOW_MORE_BTN_ENABLED',
    FACEBOOK_FEED_GALLERY_PP_SHOW_MORE_BTN_STYLE_CHANGED_AUTO_COLOR = 'FACEBOOK_FEED_GALLERY_PP_SHOW_MORE_BTN_STYLE_CHANGED_AUTO_COLOR',
    FACEBOOK_FEED_GALLERY_PP_SHOW_MORE_BTN_STYLE_CHANGED = 'FACEBOOK_FEED_GALLERY_PP_SHOW_MORE_BTN_STYLE_CHANGED',
    FACEBOOK_FEED_GALLERY_PP_SHOW_MORE_BTN_FONTSIZE_CHANGED = 'FACEBOOK_FEED_GALLERY_PP_SHOW_MORE_BTN_FONTSIZE_CHANGED',
    FACEBOOK_FEED_GALLERY_PP_DISCONNECT_BTN_PRESSED = 'FACEBOOK_FEED_GALLERY_PP_DISCONNECT_BTN_PRESSED',
    FACEBOOK_FEED_GALLERY_MOBILE_COLUMN_CHANGE = 'FACEBOOK_FEED_GALLERY_MOBILE_COLUMN_CHANGE',
    FACEBOOK_FEED_GALLERY_MOBILE_SPACING_CHANGE = 'FACEBOOK_FEED_GALLERY_MOBILE_SPACING_CHANGE',
    FACEBOOK_FEED_GALLERY_CHECK_CONNECTED_COOKIE_VALUE = 'FACEBOOK_FEED_GALLERY_CHECK_CONNECTED_COOKIE_VALUE',
    FACEBOOK_FEED_GALLERY_CAPTIONS_ENABLED_CHECKBOX_CLICKED = 'FACEBOOK_FEED_GALLERY_CAPTIONS_ENABLED_CHECKBOX_CLICKED',
    FACEBOOK_FEED_GALLERY_CAPTIONS_ALIGN_CHANGED = 'FACEBOOK_FEED_GALLERY_CAPTIONS_ALIGN_CHANGED',
    FACEBOOK_FEED_GALLERY_PP_CAPTIONS_STYLE = 'FACEBOOK_FEED_GALLERY_PP_CAPTIONS_STYLE',
    FACEBOOK_FEED_GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_FONT_FAMILY_CHANGE = 'FACEBOOK_FEED_GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_FONT_FAMILY_CHANGE',// eslint-disable-line
    FACEBOOK_FEED_GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_FONT_SIZE_CHANGE = 'FACEBOOK_FEED_GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_FONT_SIZE_CHANGE',// eslint-disable-line
    FACEBOOK_FEED_GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_FONT_COLOR_CHANGE = 'FACEBOOK_FEED_GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_FONT_COLOR_CHANGE',// eslint-disable-line
    FACEBOOK_FEED_GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_FONT_COLOR_CHANGE_AUTO_COLOR = 'FACEBOOK_FEED_GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_FONT_COLOR_CHANGE_AUTO_COLOR',// eslint-disable-line
    FACEBOOK_FEED_GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_TOGGLE_BOLD = 'FACEBOOK_FEED_GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_TOGGLE_BOLD',
    FACEBOOK_FEED_GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_TOGGLE_ITALIC = 'FACEBOOK_FEED_GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_TOGGLE_ITALIC',
    FACEBOOK_FEED_GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_TOGGLE_UNDERLINE = 'FACEBOOK_FEED_GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_TOGGLE_UNDERLINE',// eslint-disable-line
    FACEBOOK_FEED_GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_LINE_HEIGHT_CHANGE = 'FACEBOOK_FEED_GALLERY_PP_CAPTIONS_DESCRIPTION_STYLE_LINE_HEIGHT_CHANGE',// eslint-disable-line
    FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BORDER_STYLE = 'FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BORDER_STYLE',
    FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BORDER_COLOR = 'FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BORDER_COLOR',
    FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BORDER_COLOR_AUTO_COLOR = 'FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BORDER_COLOR_AUTO_COLOR',
    FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BORDER_OPACITY = 'FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BORDER_OPACITY',
    FACEBOOK_FEED_GALLERY_PP_CAPTION_BOX_BORDER_WIDTH = 'GALLERY_PP_CAPTION_BOX_BORDER_WIDTH',
    FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_COLOR_SET = 'FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_COLOR_SET',
    FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_COLOR_SET_AUTO_COLOR = 'FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_COLOR_SET_AUTO_COLOR',// eslint-disable-line
    FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_COLOR_SET_IF_UNSET = 'FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_COLOR_SET_IF_UNSET',// eslint-disable-line
    FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_COLOR_UNSET = 'FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_COLOR_UNSET',
    FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_COLOR_UNSET_AUTO_COLOR = 'FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_COLOR_UNSET_AUTO_COLOR',// eslint-disable-line
    FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_OPACITY_CHANGE = 'FACEBOOK_FEED_GALLERY_PP_CAPTION_TILE_BACKGROUND_OPACITY_CHANGE',
    FACEBOOK_FEED_GALLERY_PP_PROFILE_IMAGE_CHANGE = 'FACEBOOK_FEED_GALLERY_PP_PROFILE_IMAGE_CHANGE',
    FACEBOOK_FEED_GALLERY_PP_PROFILE_IMAGE_CLEAR = 'FACEBOOK_FEED_GALLERY_PP_PROFILE_IMAGE_CLEAR',
    TOGGLE_FB_PAGE_SHOW_HIDE_ACTION = "TOGGLE_FB_PAGE_SHOW_HIDE_ACTION";
