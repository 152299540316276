export default (
    state: any, action: any
) => {
    const generic = state.generic || {},
        textStyle = generic.textStyle || {};
    return {
        ...state,
        generic: {
            ...generic,
            textStyle: {
                ...textStyle,
                color: action.payload.color
            }
        }
    };
};
