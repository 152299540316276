import type { SideBarItemDef } from '../../SideBar/types';

import imageStyles from "../../Images/imageStyle.css";

export const COOKIE_CONSENT_PROVIDER_TERMS_LINK = "https://termly.io/our-terms-of-use/";

export const CONSENT_BANNER_PAGE_SIDE_BAR_ITEM_ID = 'CONSENT_BANNER_PAGE_SIDE_BAR_ITEM_ID';

export const ConsentBannerSideBarItem: SideBarItemDef = {
    id: CONSENT_BANNER_PAGE_SIDE_BAR_ITEM_ID,
    icon: imageStyles.consentBanner,
    title: 'msg: consentBanner.title {Consent banner}',
    header: {
        title: "msg: consentBanner.title {Consent banner}",
        description: "msg: consentBanner.description {Add a GDPR compliant consent banner to your site in seconds.}"
    }
};
