import React from "react";
import { connect } from "react-redux";
import getCenteredDialog from "../../../../DialogManager/getCenteredDialogConfig";
import { injectIntl, Intl } from "../../../../../view/intl/index";
import styles from './FacebookMultiplePagesDialog.css';
import { StripTypes } from "../../../../../view/common/dialogs/baseDialog/index";
import { DialogHeight, DialogWidth, DialogMessages } from "./constants";
import { closeDialog } from "../../../../App/actionCreators/index";
import { StandardDialog } from "../../../../../view/common/dialogs/StandardDialog/index";
import type { AppState } from "../../../../../redux/modules/flowTypes";
import { socialAccountsFacebookFeedManagedPages, socialAccountsFacebookFeedSelectedPageValue } from "../selectors";
import ComboBox from "../../../../../view/common/Combobox/index";
import PropertyContainer from "../../../../../view/common/PropertyContainer/index";
import { exchangeForFacebookLongAccessTokenAction, setFbPagesValue } from "../actions";
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import type { FacebookPage } from "../types";

type Props = {
    dispatch: Dispatch,
    intl: Intl,
    managedFbPages: Array<FacebookPage>,
    selectedPageValue: string,
}

// eslint-disable-next-line max-len
class FacebookMultiplePagesDialog extends React.Component<Props> {
    render() {
        const { props: { dispatch, intl, managedFbPages, selectedPageValue } } = this;

        const pageOptions = managedFbPages.map((pageData) => {
            return {
                value: pageData.access_token,
                label: pageData.name
            };
        });

        return (
            <StandardDialog
                title={DialogMessages.TITLE}
                iconClass={styles.facebookIcon}
                autoFocus={false}
                stripType={StripTypes.NONE}
                mctaText={DialogMessages.RECONNECT_LABEL}
                sctaText={DialogMessages.CANCEL_LABEL}
                titleBoxClassName={styles.titleBoxClassName}
                sctaBtnClass={styles.cancelBtn}
                primaryBtnClass={styles.primaryBtnClass}
                mctaHandler={
                    () => {
                        // @ts-ignore
                        dispatch(exchangeForFacebookLongAccessTokenAction(selectedPageValue, true));
                        dispatch(closeDialog());
                    }
                }
                noTopBorderForFooter
                disabled={false}
                sctaHandler={null}
            >
                <VerticalSpacer y={20} />
                <div className={styles.message}>{intl.msgJoint(DialogMessages.MESSAGE)}</div>

                <VerticalSpacer y={20} />
                <PropertyContainer label="msg: component.facebookFeed.facebookPage {Facebook page}">
                    <ComboBox
                        searchable={false}
                        value={selectedPageValue}
                        options={pageOptions}
                        onChange={
                            ({ value }) => {
                                dispatch(setFbPagesValue(value));
                            }
                        }
                    />
                </PropertyContainer>
            </StandardDialog>
        );
    }
}

const mapStateToProps = (appState: AppState) => ({
    managedFbPages: socialAccountsFacebookFeedManagedPages(appState),
    selectedPageValue: socialAccountsFacebookFeedSelectedPageValue(appState)
});

export default getCenteredDialog({
    width: DialogWidth,
    height: DialogHeight,
    component: connect(mapStateToProps)(injectIntl(FacebookMultiplePagesDialog)),
});
