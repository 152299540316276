import * as actionTypes from '../actions';
import { makeValueReducer } from "../../../../../src/redux/makeReducer/index";
import type { ResetPasswordPagePropTypes } from "../../types";
import { NOT_AUTHORIZED_EXCEPTION } from "../../../../constants";

export const defaultResetPasswordState = {
    loading: false,
    password: '',
    confirmPassword: '',
    error: null,
    userDisabledError: false,
    invalidPasswordError: false,
    showPasswordEnabled: false,
    isResetPasswordSuccessPage: false,
    isResetPasswordPage: true,
    username: '',
    verificationCode: ''
};

export const resetPasswordReducer = makeValueReducer({
    defaultState: defaultResetPasswordState,
    handleActions: {
        [actionTypes.DEMO_RESET_PASSWORD_FAILURE_ACTION]: (state: ResetPasswordPagePropTypes, action: Record<string, any>) => {
            const newState = state;
            const errorCode = action.payload.code;
            if (errorCode === NOT_AUTHORIZED_EXCEPTION) {
                newState.userDisabledError = true;
            } else {
                newState.error = action.payload.message;
            }
            return {
                ...newState,
                loading: false
            };
        },
        [actionTypes.DEMO_UPDATE_RESET_CONFIRM_PASSWORD_VALUE_ACTION]: (state: ResetPasswordPagePropTypes, action: Record<string, any>) => { //eslint-disable-line max-len
            return {
                ...state,
                confirmPassword: action.payload.confirmPasswordValue
            };
        },
        [actionTypes.DEMO_UPDATE_RESET_PASSWORD_VALUE_ACTION]: (state: ResetPasswordPagePropTypes, action: Record<string, any>) => {
            return {
                ...state,
                password: action.payload.passwordValue
            };
        },
        [actionTypes.DEMO_TOGGLE_SHOW_RESET_PASSWORD_VALUE_ACTION]: (state: ResetPasswordPagePropTypes) => {
            let reversePasswordShow = !state.showPasswordEnabled;
            return {
                ...state,
                showPasswordEnabled: reversePasswordShow
            };
        },
        [actionTypes.DEMO_RESET_PASSWORD_SUCCESS_ACTION]: (state: ResetPasswordPagePropTypes) => {
            return {
                ...state,
                isResetPasswordSuccessPage: true,
                isResetPasswordPage: false
            };
        },
        [actionTypes.DEMO_RESET_PASSWORD_CLEAN_ALL_ERRORS_ACTION]: (state: ResetPasswordPagePropTypes) => {
            return {
                ...state,
                userDisabledError: false,
                invalidPasswordError: false,
                error: null,
                codeExpiredError: false,
            };
        },
        [actionTypes.DEMO_CLEAN_UP_RESET_PASSWORD_SCREEN_ACTIONS]: () => {
            return defaultResetPasswordState;
        },
        [actionTypes.DEMO_RESET_PASSWORD_LOADING_ACTION]: (state: ResetPasswordPagePropTypes) => {
            return {
                ...state,
                loading: true
            };
        },
        [actionTypes.DEMO_RESET_PASSWORD_SHOW_INVALID_PASSWORD_ERROR_ACTION]: (state: ResetPasswordPagePropTypes) => {
            return {
                ...state,
                invalidPasswordError: true
            };
        }
    }
});

