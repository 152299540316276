import * as R from 'ramda';
import p from "../../utils/pipePath";
import maybe from '../../utils/maybe';
import type { SpecConfig } from './flowTypes';

export default (config: SpecConfig) =>
    (...pathModifiers: Array<Path>) =>
        R.mapObjIndexed(
            ([path, mapper]) => R.pipe(R.path(p(...pathModifiers, path)), maybe(mapper))
        )(config);
