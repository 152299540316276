import { arrayToTrueMap } from "../../../../utils/arrayToTrueMap";

export const
    REPLACE_MISSING_ASSETS = 'REPLACE_MISSING_ASSETS',
    DELETE_MISSING_ASSETS = 'DELETE_MISSING_ASSETS',
    STYLE_UPDATED = 'STYLE_UPDATED',
    STYLE_DUPLICATED = 'STYLE_DUPLICATED',
    STYLE_DELETED = 'STYLE_DELETED',
    TEXT_STYLE_CHANGED = 'TEXT_STYLE_CHANGED',
    LOGO_STYLE_CHANGED = 'LOGO_STYLE_CHANGED',
    TABLE_STYLE_CHANGED = 'TABLE_STYLE_CHANGED',
    TEXT_CHARACTER_SPACING_CHANGED = 'TEXT_CHARACTER_SPACING_CHANGED',
    TEXT_LINE_HEIGHT_CHANGED = 'TEXT_LINE_HEIGHT_CHANGED',
    TEXT_FONT_CHANGED_CHANGED = 'TEXT_FONT_CHANGED_CHANGED',
    TEXT_FONT_FAMILY_CHANGED = 'TEXT_FONT_FAMILY_CHANGED',
    TEXT_BOLD_CHANGED = 'TEXT_BOLD_CHANGED',
    TEXT_FONT_SIZE_CHANGED = 'TEXT_FONT_SIZE_CHANGED',
    DROPDOWN_WIDTH_CHANGED = 'DROPDOWN_WIDTH_CHANGED',
    NEW_STYLE_ADDED = 'NEW_STYLE_ADDED',
    COLOR_THEME_ENABLED = "COLOR_THEME_ENABLED",

    stylesheetUpdateReasonsThatMayAffectTextHeightMap = arrayToTrueMap([
        TEXT_CHARACTER_SPACING_CHANGED,
        TEXT_FONT_CHANGED_CHANGED,
        TEXT_FONT_FAMILY_CHANGED,
        TEXT_BOLD_CHANGED,
        TEXT_FONT_SIZE_CHANGED
    ]);
