import React from 'react';
import VerticalSpacer from '../../../view/common/VerticalSpacer';
import { Dialog, DialogBody } from '../../../view/common/dialogs/baseDialog/index';
import { getCenterPositionForDialog } from '../../DialogManager/utility';
import type { FourFieldState, FourFieldDialogActionType, FourFieldDialogProps } from './flowTypes';
import type { BrowserDimensions } from '../../../redux/modules/children/dimensions/index';

const
    width = 312,
    height = 164;

export default (configurationDialogId: string, FourFieldDialog: AnyReactComponent) => ({
    component: ({ state: values, onChangeAction, dispatch, ...props }: FourFieldDialogProps) => (
        <Dialog>
            <DialogBody>
                <VerticalSpacer y={30} />
                {/* @ts-ignore */}
                <FourFieldDialog
                    // @ts-ignore
                    values={values}
                    onChangeAction={onChangeAction}
                    dispatch={dispatch}
                    {...props}
                />
            </DialogBody>
        </Dialog>
    ),
    confFactory: ({ browserWidth, browserHeight }: BrowserDimensions) => ({
        configurationDialogId,
        position: getCenterPositionForDialog(width, height, browserWidth, browserHeight),
        modal: false,
        dimensions: { width, height }
    }),
    reducer: (state: null | undefined | FourFieldState, action: FourFieldDialogActionType, props: FourFieldDialogProps) => {
        if (state === undefined) {
            return props.values;
        } else if (action.type === props.onChangeAction) {
            return action.payload;
        }
        return state;
    }
});
