import type { SvgCalcRenderProps } from './flowTypes';

export const svgCalcRenderProps: SvgCalcRenderProps = ({
    component,
    componentDependencies,
    isWorkspace,
    previewBackupTime,
    selectedParentTheme,
}) => {
    const { missingAssetUrls, site, themeColorsData, themeSettingsData } = componentDependencies;
    return {
        component,
        isAssetMissing: missingAssetUrls.includes(component.asset.url),
        isWorkspace,
        previewBackupTime,
        site,
        selectedParentTheme,
        themeColorsData,
        themeSettingsData,
    };
};
