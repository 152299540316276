import React from 'react';
import cx from 'classnames';
import styles from './styles.css';
import type { RadioButtonPropType, RadioButtonGroupPropType, RadioButtonConfigType } from "./flowTypes";
import { injectIntl } from "../../intl/index";

const RadioButton = injectIntl(({
    label,
    description,
    value,
    isChecked,
    onChange,
    disabled,
    className,
    sideChildren,
    isHtmlLabel = false,
    labelClassName = '',
    labelWrapperClassName = '',
    descriptionTextClassName = '',
    labelTextClassName = '',
    radioButtonContainerClassName = '',
    checkedRadioButtonContainerClassName = '',
    inputClass = '',
    intl
}: RadioButtonPropType) => {
    const radioBtnId = value.replace(/\s/g, '');
    return (
        <div
            className={cx(
                className,
                styles.container,
                radioButtonContainerClassName,
                {
                    [styles.checked]: isChecked,
                    [checkedRadioButtonContainerClassName]: isChecked
                }
            )}
        >
            <label htmlFor={radioBtnId} className={cx(styles.label, labelClassName)}>
                <span className={cx(styles.inputWrapper, inputClass)}>
                    <input
                        id={radioBtnId}
                        type="radio"
                        disabled={disabled}
                        onChange={() => onChange(value)}
                        checked={isChecked}
                        className={styles.input}
                    />
                </span>
                <span className={labelWrapperClassName}>
                    <span className={cx(styles.labelText, labelTextClassName)}>{isHtmlLabel ? label : intl.msgJoint(label)}</span>
                    {description &&
                        <span className={cx(styles.descriptionText, descriptionTextClassName)}>
                            {isHtmlLabel ? description : intl.msgJoint(description)}</span>}
                </span>
                {sideChildren}
            </label>
        </div>
    );
});

const RadioButtonGroup = ({
    optionsConfig,
    onChange,
    value: selectedValue,
    radioButtonClassName,
    className,
    labelClassName = '',
    labelTextClassName = '',
    checkedRadioButtonContainerClassName = '',
    radioButtonContainerClassName = '',
}: RadioButtonGroupPropType) => {
    const doNothingEventHandler = (e) => { e.preventDefault(); return false; };
    return (
        <form
            onSubmit={doNothingEventHandler}
            className={cx(
                styles.radioButtonGroup,
                className
            )}
        >
            {
                optionsConfig.map(({ label, value, sideChildren }: RadioButtonConfigType, index: number) => (
                    <RadioButton
                        key={index}
                        label={label}
                        value={value}
                        onChange={onChange}
                        isChecked={value === selectedValue}
                        className={radioButtonClassName}
                        sideChildren={sideChildren}
                        labelClassName={labelClassName}
                        labelTextClassName={labelTextClassName}
                        checkedRadioButtonContainerClassName={checkedRadioButtonContainerClassName}
                        radioButtonContainerClassName={radioButtonContainerClassName}
                    />
                ))
            }
        </form>
    );
};

export {
    RadioButton,
    RadioButtonGroup,
};
