import React from 'react';
import cx from 'classnames';
import { connect } from "react-redux";
import { CLOSE_DIALOG } from "../../../../redux/modules/actionTypes";
import styles from './Dialog.css';

type PropTypes = {
    stripType?: string,
    applyOldStyleForCloseBtn?: boolean,
    className?: string,
    defaultOnClick: () => void,
    onClick?: () => void,
}

class DialogCloseBtn extends React.Component<PropTypes> {
    render() {
        const
            stripType = this.props.stripType,
            onClick = this.props.onClick || this.props.defaultOnClick,
            stopPropagation = e => e.stopPropagation();

        return (
            <div
                className={
                    cx(
                        {
                            [styles.closeBtnOldStyles]: this.props.applyOldStyleForCloseBtn,
                            [styles.closeBtnNewStyles]: !this.props.applyOldStyleForCloseBtn,
                            [styles.closeBtnWithStrip]: stripType,
                            [styles.closeBtnWithoutStrip]: !stripType
                        },
                        styles.closeBtn,
                        this.props.className,
                    )
                }
                onClick={onClick}
                onMouseDown={stopPropagation}
                onMouseUp={stopPropagation}
            />
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    defaultOnClick: () => dispatch({ type: CLOSE_DIALOG })
});

export default connect(null, mapDispatchToProps)(DialogCloseBtn);
