import React from "react";
import styles from "./ThemeDeactivationDialog.css";
import { Dialog, DialogBody, DialogFooter, StripTypes } from "../../../../view/common/dialogs/baseDialog/index";
import { Msg } from "../../../../view/intl/index";
import { closeDialog } from "../../../App/actionCreators/index";
import { AUTO_COLOR_MODE_DEACTIVATED } from "../../../ThemeGlobalData/actionTypes";

type Props = {
    dispatch: Dispatch;
};

class ThemeDeactivationDialog extends React.Component<Props> {
    render() {
        const { dispatch } = this.props,
            onDeactivate = () => {
                dispatch(closeDialog());
                dispatch({
                    type: AUTO_COLOR_MODE_DEACTIVATED
                });
            };

        return (
            <Dialog stripType={StripTypes.NONE} className={styles.dialogMain} closeBtnClassName={styles.closeBtn}>
                <DialogBody className={styles.dialogBody}>
                    <div className={styles.title}>
                        <Msg k="theme.deactivation.colourThemeOffHeader">Are you sure you want to switch colour theme off?</Msg>
                    </div>
                    <div className={styles.description}>
                        <Msg k="theme.deactivation.colourThemeDescription">
                            If you switch it off, your site will look the same as it currently does, but you will need to edit colours of
                            every item on your site manually going forward.
                        </Msg>
                    </div>
                </DialogBody>
                <DialogFooter
                    className={styles.footerContainer}
                    footerButtonContainerClassName={styles.footerButtonContainer}
                    mctaText="msg: common.yesSwitchOff {Yes, switch off}"
                    sctaText="msg: common.noKeepIt {No, keep it}"
                    mctaHandler={onDeactivate}
                    noTopBorder
                    primaryBtnClass={styles.footerPrimaryBtn}
                    sctaBtnClass={styles.footerSctaBtn}
                    sctaHandler={() => dispatch(closeDialog())}
                    autoFocus
                />
            </Dialog>
        );
    }
}

export default ThemeDeactivationDialog;
