import React from "react";
export const SvgSnapchatRegularMulticolor = props => (
    <svg viewBox="0 0 46 43" {...props}>
        <path
            fill="#ffffff"
            fillRule="evenodd"
            stroke="#000000"
            strokeWidth={1.18}
            d="M23.006.59h-.107c-.09.002-.09.002-.369.004h-.023l-.395.004c-4.221 0-8.376 1.838-10.61 6.854-.556 1.247-.746 3.128-.676 5.748a75.088 75.088 0 00.157 3.112l.02.334a288.594 288.594 0 01.047.77l.021.37-.323.18c-.3.165-.688.265-1.165.265-.688 0-1.468-.209-2.326-.612a1.09 1.09 0 00-.465-.09c-.74 0-1.513.495-1.614 1.03-.106.558.345 1.157 1.895 1.77.14.055.28.102.617.208.446.142.668.217.946.326.987.385 1.65.842 1.942 1.532.26.614.165 1.34-.227 2.137l-.004.008-.003.006-.006.013c-.12.282-.327.7-.624 1.217a16.585 16.585 0 01-1.716 2.446c-1.909 2.25-4.272 3.803-7.118 4.272a.348.348 0 00-.29.362.671.671 0 00.054.222c.347.814 2.151 1.548 5.487 2.066l.24.037.144.196a.985.985 0 01.08.129c.088.17.16.402.24.733.03.128.052.224.098.433.109.5.163.73.248 1.02.075.255.207.374.53.374.188 0 .387-.031.905-.133 1.027-.201 1.647-.284 2.52-.284.594 0 1.207.052 1.822.154.638.106 1.246.347 1.91.73.436.252.774.48 1.567 1.041 2.424 1.717 3.723 2.319 5.855 2.319.066 0 .128-.002.253-.007.107.004.207.007.305.007 2.133 0 3.431-.602 5.855-2.318.804-.569 1.142-.797 1.582-1.05.659-.379 1.263-.617 1.897-.722a11.14 11.14 0 011.821-.154c.874 0 1.597.086 2.52.267.392.076.7.115.915.115h.042c.263 0 .403-.105.478-.36.085-.29.146-.545.248-1.008.043-.201.066-.303.097-.43.08-.328.15-.56.239-.73a.95.95 0 01.081-.13l.144-.196.24-.037c3.335-.518 5.14-1.251 5.486-2.062a.658.658 0 00.054-.223.346.346 0 00-.289-.361c-2.846-.47-5.21-2.022-7.118-4.274a16.571 16.571 0 01-1.716-2.445 12.23 12.23 0 01-.625-1.218l-.007-.017c-.397-.804-.492-1.53-.231-2.145.386-.913 1.182-1.316 2.887-1.857.333-.106.478-.154.617-.21 1.327-.524 1.919-1.097 1.909-1.648-.008-.406-.361-.81-.972-1.03l-.042-.015a1.918 1.918 0 00-.715-.135c-.22 0-.438.037-.613.12-.793.371-1.52.578-2.224.607-.41-.02-.75-.115-1.015-.262l-.326-.18.021-.37c.016-.277.033-.562.06-.969l.007-.132c.307-4.863.249-7.144-.519-8.867C31.401 2.43 27.238.59 23.006.59z"
        />
    </svg>
);
