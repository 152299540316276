import { makeFlagsToEnumMapper } from '../../utils';
import * as layoutTypes from '../../../../src/components/oneweb/Menu/constants/layoutType';

const { to, back } = makeFlagsToEnumMapper({
    toPropName: 'layoutType',
    toInputMapper: ({ vertical, accordion, cascade }) => [vertical, accordion, cascade],
    tupleToOldVal: ([vertical, accordion, cascade]) => ({ vertical, accordion, cascade }),
    newFromOldMap: {
        [layoutTypes.HORIZONTAL_SINGLE_LEVEL]: [false, 0, 0],
        [layoutTypes.HORIZONTAL_DROPDOWN]: [false, 0, 9],
        [layoutTypes.VERTICAL_SINGLE_LEVEL]: [true, 0, 0],
        [layoutTypes.VERTICAL_DROPDOWN]: [true, 0, 9],
        [layoutTypes.VERTICAL_TREE]: [true, 9, 0]
    }
});

export { to, back };
