export const makeExistingParentTagSelector = (root: HTMLElement, tagNames: string[]) => (node: HTMLElement) => {
    // recursive selector function which stops when parent tag is found
    const findAncestorMatchingTagNames = node => {
        // $FlowFixMe: WBTGEN-9962: can replace tagName by nodeName, which returns tagName for HTMLElements
        if (tagNames.includes(node.tagName)) {
            return node;
        }

        if (root === node) {
            return null;
        }

        if (node.parentNode) {
            return findAncestorMatchingTagNames(node.parentNode);
        }

        return null;
    };

    return findAncestorMatchingTagNames(node);
};
