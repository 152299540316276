export const
    SELECT_MVE_HEADER_ACTION = 'SELECT_MVE_HEADER_ACTION',
    selectMveHeaderAction = () => ({
        type: SELECT_MVE_HEADER_ACTION,
    });

export const
    SET_MVE_OPEN_MENU_ACTION = 'SET_MVE_OPEN_MENU_ACTION',
    setMveOpenMenuAction = (value: boolean) => ({
        type: SET_MVE_OPEN_MENU_ACTION,
        payload: value
    });
