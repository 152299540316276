export const
    DEFAULT_WIDTH = 500,
    DEFAULT_HEIGHT = 679,
    EmbedTypes = ['instagram', 'profile'],
    defaultState = {
        link: "",
        mobileHide: false,
    },
    InstagramSrcLink = "https://www.instagram.com/p/",
    DATA_TEST_ID = 'instagram-widget';
