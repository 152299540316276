import type { WebshopCalcRenderProps } from "./flowTypes";
/* TODO: Move any calculations done in view here. */
/* CalcRenderProps is created to pass selectedParentTheme for now.*/

export default (props: WebshopCalcRenderProps) => {
    const locale = props.componentDependencies.localeDetails.current;
    return {
        ...props,
        ...props.componentDependencies,
        locale
    };
};
