import React from 'react';
import * as Icon from './Icons.css';
import WebShopKind from "../../oneweb/WebShop/kind";
import { ComponentNames } from "../../oneweb/constants";
import type { ExtendedPanelContentProps } from "../view/inserter";
import OnlineShopExpandedContent from "../../oneweb/WebShop/expandedPanel/expandedPanelContent";

const Content = (props: ExtendedPanelContentProps) => {
    const { intl, dispatch } = props;

    return (
        <OnlineShopExpandedContent
            // @ts-ignore
            dispatch={dispatch}
            intl={intl}
        />
    );
};

export default {
    kind: WebShopKind,
    shortCut: {
        iconClassName: Icon.onlineShop,
        text: ComponentNames[WebShopKind]
    },
    extendedPanelData: {
        title: ComponentNames[WebShopKind],
        content: (props: ExtendedPanelContentProps) => <Content {...props} />
    }
};
