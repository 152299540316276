import React from "react";
import cx from "classnames";
import type { StylesheetsIdToNameMap } from "../../../Workspace/epics/stylesheets/flowTypes";
import type { ThemeBackgroundType } from "../../../ThemeGlobalData/flowTypes";

const optionRenderer = ({ value, label, stylesheetsIdToNameMap, selectedParentTheme }: {
    value: string,
    label: string,
    stylesheetsIdToNameMap: StylesheetsIdToNameMap,
    selectedParentTheme: ThemeBackgroundType|null,
}) => (
    <div className={cx(stylesheetsIdToNameMap[value], `${stylesheetsIdToNameMap[value]}-ddo`, selectedParentTheme)}>
        {label}
    </div>
);

export { optionRenderer };
