import { getEditorContent } from "../../editorUtils/methods/getters/getEditorContent";
import { type TinyMceEditor } from "../../flowTypes";
import { type AnyKindOfActionOrNull, type EpicUpdaterReducer } from "../../../../../../epics/flowTypes";
import { type ActionType } from "../../../../../../epics/makeCondition";
import { getOffScreenEditor } from "../getOffScreenEditor";
import { mergeNodes } from '../../editorUtils/methods/helpers/mergeNodes';

type EpicUpdaterReducerReturnTypeNoSingleActionDispatch<State, Scope, UpdateReason> = {
    state: State;
    scope?: Scope;
    multipleActionsToDispatch?: Array<AnyKindOfActionOrNull>;
    updateReason?: UpdateReason;
};

type EpicUpdaterReducerWithEditor<
    State extends AnyValue,
    Scope extends AnyValue,
    UpdateReason extends string,
    Values extends any = any
> = (config: {
    values: Values;
    state: State;
    scope: Scope;
    conditionActionType: string | ActionType;
    editor: TinyMceEditor;
}) => EpicUpdaterReducerReturnTypeNoSingleActionDispatch<
    State,
    Scope,
    UpdateReason
>;

type MakeApplyEditorChangesReducer<
    State extends AnyValue,
    Scope extends AnyValue,
    UpdateReason extends string,
    Values extends any = any
> = (
    arg0: EpicUpdaterReducerWithEditor<State, Scope, UpdateReason, Values>,
) => EpicUpdaterReducer<State, Scope, UpdateReason>;

const
    makeApplyEditorChangesReducer: MakeApplyEditorChangesReducer<any, any, any, any> = (updaterReducer) =>
        ({ values, state, scope, conditionActionType }) => {
            let editor;

            // editor returns empty paragraph when tinyMceExist is false
            // so we shouldn'y try to process editor content when tinyMceExist is false
            if (state.tinyMceExist) {
                editor = window.tinyMCE.activeEditor;
            }

            if (scope.processingMultipleEditors) {
                /* $FlowFixMe */
                return updaterReducer({
                    values,
                    state,
                    scope,
                    conditionActionType,
                    editor: getOffScreenEditor()
                });
            }

            if (!editor || editor.removed) {
                return { state, scope };
            }

            const { handleEditorChangeActionType } = scope;

            if (!handleEditorChangeActionType) {
                throw new Error(`handleEditorChangeActionType should be initialized`);
            }

            Array.from(editor.getBody().children).forEach((childElt: any) => {
                if (editor) mergeNodes(editor, childElt);
            });

            const
                result = updaterReducer({ values, state, scope, conditionActionType, editor }),
                newContent = getEditorContent(editor);

            return {
                state: result.state,
                scope: result.scope,
                multipleActionsToDispatch: [
                    ...(result.multipleActionsToDispatch || []),
                    ({
                        type: handleEditorChangeActionType,
                        payload: {
                            content: newContent,
                            componentId: scope.lastEditModeComponentId
                        }
                    } as AnyAction)
                ]
            };
        };

export {
    makeApplyEditorChangesReducer
};
