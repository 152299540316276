export default ({ padding, paddingTop, paddingRight, paddingBottom, paddingLeft }): Array<number> => {
    let top = 0;
    let right = 0;
    let bottom = 0;
    let left = 0;

    if (padding) {
        // CSS padding property has 1, 2, 3, or 4 values so we set top, right, bottom, and left
        // depending on number of properties.
        let paddingShorthandSplit = padding.split(/\s+/);

        paddingShorthandSplit = paddingShorthandSplit.map(side => parseInt(side, 10));
        if (paddingShorthandSplit.length === 1) {
            top = paddingShorthandSplit[0];
            right = paddingShorthandSplit[0];
            bottom = paddingShorthandSplit[0];
            left = paddingShorthandSplit[0];
        } else if (paddingShorthandSplit.length === 2) {
            top = paddingShorthandSplit[0];
            bottom = paddingShorthandSplit[0];
            left = paddingShorthandSplit[1];
            right = paddingShorthandSplit[1];
        } else if (paddingShorthandSplit.length === 3) {
            top = paddingShorthandSplit[0];
            right = paddingShorthandSplit[1];
            left = paddingShorthandSplit[1];
            bottom = paddingShorthandSplit[2];
        } else {
            [top, right, bottom, left] = paddingShorthandSplit;
        }
    } else {
        top = parseInt(paddingTop || 0, 10);
        right = parseInt(paddingRight || 0, 10);
        bottom = parseInt(paddingBottom || 0, 10);
        left = parseInt(paddingLeft || 0, 10);
    }

    return [top, right, bottom, left];
};
