export const
    DialogWidth = 470,
    DialogHeight = 216,
    PopupWindowWidth = 973,
    PopupWindowHeight = 798,
    DialogMessages = {
        TITLE: 'msg: component.facebookFeed.noPages {No Facebook pages chosen}',
        MESSAGE: 'msg: component.facebookFeed.noPages.message {You have not selected a Facebook page when connecting. Connect again and choose a page to add your feed to your site.}', // eslint-disable-line max-len
        RECONNECT_LABEL: 'msg: component.facebookFeed.noPages.reconnectLabel {Connect Facebook}',
        CANCEL_LABEL: 'msg: component.facebookFeed.noPages.cancel {Cancel}'
    };
