import React from 'react';
import cx from 'classnames';
import styles from './FileChooserButton.css';
import { Intl, injectIntl } from "../../../../../view/intl/index";
import type { MsgJointInput } from "../../../../../view/intl/injectIntl";
import type { AppDispatch } from "../../../../../redux/modules/flowTypes";
import Label from '../../../../../view/common/Label';
import LongTextTip from '../../../../../view/common/LongTextTip/index';
import getStyleIntValue from "../../../../../utils/getStyleIntValue";
import { decodeWebspaceUri } from "../../../../../../dal/utils/webspaceUriTransformers";
import type { FactoryProps as ButtonFactoryProps } from '../../../../presentational/Button/flowTypes';
import ButtonFactory from '../../../../presentational/Button/factory';

const LABEL_MAX_WIDTH = getStyleIntValue(styles, 'labelMaxWidth');

export type FileChooserButtonProps = {
    filename: null | undefined | string,
    fileChangeAction: Record<string, any>,
    dispatch: AppDispatch,
    intl: Intl,
    chooseLabel?: MsgJointInput,
    removeAction?: Record<string, any> | null,
    headLabel?: MsgJointInput,
    headlessMode?: true,
    removeButtonTitle?: string,
    labelMaxWidth?: null | undefined | number,
    tabIndex?: number,
    showAssetInfo?: boolean,
    onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>,
    className ?: string
};

class FileChooserButtonComponent extends React.Component<FileChooserButtonProps> {
    constructor() {
        // @ts-ignore
        super();
        this.onChooserClick = this.onChooserClick.bind(this);
        this.onRemoveClick = this.onRemoveClick.bind(this);
    }

    onChooserClick(e: React.MouseEvent<any>) {
        e.stopPropagation();
        this.props.dispatch(this.props.fileChangeAction);
    }

    onRemoveClick(e: React.MouseEvent<any>) {
        e.preventDefault();
        e.stopPropagation();

        const { removeAction, dispatch } = this.props;
        if (!removeAction) return;

        dispatch(removeAction);
    }

    render() {
        const
            {
                filename,
                chooseLabel,
                headLabel,
                removeAction,
                removeButtonTitle,
                intl,
                tabIndex,
                onKeyDown,
                className,
                showAssetInfo
            } = this.props,
            isBlank = !filename,
            isRemovable = showAssetInfo && !!removeAction,
            containerClassName = cx(styles.chooserContainer, className, { [styles.blank]: isBlank }),
            labelClassName = cx(styles.label, { [styles.default]: isBlank }),
            // @ts-ignore
            label = (showAssetInfo && (filename && decodeWebspaceUri(filename))) || intl.msgJoint(chooseLabel),
            ellipsisClassName = cx(styles.ellipsis, { [styles.default]: isBlank }),
            btnStyles = { button: styles.removeBtn, disabled: '', error: '', selected: '' },
            buttonFactoryConfig: ButtonFactoryProps = { btnStyles, containerType: 'span', containerExtraPropNames: [] },
            RemoveButton = ButtonFactory({ ...buttonFactoryConfig });

        return (
            <div>
                {headLabel && <Label className={styles.headLabel}>{headLabel}</Label>}
                <button
                    onKeyDown={onKeyDown}
                    type="button"
                    className={containerClassName}
                    onClick={this.onChooserClick}
                    tabIndex={tabIndex}
                >
                    <div className={styles.chooser}>
                        <LongTextTip
                            className={labelClassName}
                            maxWidth={this.props.labelMaxWidth || LABEL_MAX_WIDTH}
                        >
                            {label}
                        </LongTextTip>
                        {
                            isRemovable
                            && <RemoveButton
                                onClick={this.onRemoveClick}
                                title={removeButtonTitle}
                            />
                        }
                    </div>
                    {!this.props.headlessMode && <span className={ellipsisClassName} />}
                </button>
            </div>
        );
    }
}

export const FileChooserButton = injectIntl(FileChooserButtonComponent);
