export const parseLineHeight = (value: string) => {
    let lineHeight = parseFloat(value);

    if (typeof lineHeight === 'number' && !isNaN(lineHeight)) {
        if (/%$/.test(value)) {
            return Math.floor(((lineHeight / 100) + 0.05) * 10) / 10;
        } else {
            return Math.floor((lineHeight + 0.05) * 10) / 10;
        }
    }

    return null;
};
