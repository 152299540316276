import * as R from 'ramda';
import { makeApplyEditorChangesReducer } from "./helpers/helpers";
import { applyFormatValue } from "../editorUtils/methods/helpers/applyFormatValue";
import type { FormatKeys } from "../../../../oneweb/Text/editorSetup";

type Props<V> = {
    triggerActionType: string;
    formatterName: FormatKeys;
    propToSet: string;
    valueFromPayloadGetter?: (arg0: AnyValue) => V;
    toEditorValueMapper?: (arg0: V) => AnyValue;
};

const makeApplyFormatUpdater = <V>({
    triggerActionType,
    formatterName,
    propToSet,
    valueFromPayloadGetter = R.identity,
    toEditorValueMapper = R.identity
}: Props<V>) => ({
        conditions: [triggerActionType],
        reducer: makeApplyEditorChangesReducer(({ values: [payload], state, scope, editor }) => {
            const value = valueFromPayloadGetter(payload);
            applyFormatValue(editor, formatterName, toEditorValueMapper(value));

            return {
                state: R.evolve({ [propToSet]: () => value }, state),
                scope
            };
        })
    });

export {
    makeApplyFormatUpdater
};
