// importing browser unsupported check firstly. Please don't load anything before this.
import './initBrowserUnsupportedPolyfills';

import './initCrashReporter';
import { loadLocaleData } from "./view/intl/init/loadLocaleData";
import { bootstrap } from "./bootstrap";
import { initTrackingAnalytics } from './trackingAnalytics';

if (!global.Intl) {
    require('intl');
}

initTrackingAnalytics();

loadLocaleData(bootstrap);
