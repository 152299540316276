import React from "react";
export const SvgEmailCircleFillMulticolor = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <circle cx={24} cy={24} r={24} fill="#999999" />
            <path
                fill="#ffffff"
                d="M16.635 15.863a2.272 2.272 0 00-2.272 2.272V29.04a2.272 2.272 0 002.272 2.272h15.45a2.272 2.272 0 002.271-2.272V18.135a2.272 2.272 0 00-2.272-2.272H16.635zm0-1.363h15.45a3.635 3.635 0 013.634 3.635V29.04a3.635 3.635 0 01-3.635 3.635H16.635A3.635 3.635 0 0113 29.04V18.135a3.635 3.635 0 013.635-3.635zm9.722 10.232l-1.501 1.595a.682.682 0 01-.993 0l-1.501-1.595-4.79 4.79a.682.682 0 01-.964-.964l4.82-4.82-4.835-5.136a.682.682 0 01.993-.934l6.774 7.197 6.773-7.197a.682.682 0 01.993.934l-4.834 5.137 4.82 4.82a.682.682 0 01-.964.963l-4.79-4.79z"
            />
        </g>
    </svg>
);
