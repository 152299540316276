export const
    FIX_ALL_HEADER_SECTION_REQUEST = 'FIX_ALL_HEADER_SECTION_REQUEST',
    FIX_ALL_HEADER_SECTION_SUCCESS = 'FIX_ALL_HEADER_SECTION_SUCCESS',
    FIX_ALL_HEADER_SECTION_FAILURE = 'FIX_ALL_HEADER_SECTION_FAILURE',

    RequestFixAllHeaderSectionActionTypes = [
        FIX_ALL_HEADER_SECTION_REQUEST,
        FIX_ALL_HEADER_SECTION_SUCCESS,
        FIX_ALL_HEADER_SECTION_FAILURE
    ];

