import * as React from 'react';
import cx from 'classnames';
import Label from '../../../view/common/Label';
import styles from './MctaPropertyContainer.css';

type Props = {
    label?: MsgJointInput;
    labelStyle?: Record<string, any>;
    style?: Record<string, any>;
    className?: string;
    childrenContainerClassName?: string;
    children?: React.ReactElement<any>;
    disabled?: boolean;
}

export default ({ label, labelStyle, style, className, childrenContainerClassName, disabled, children }: Props) => (
    <div style={style} className={cx(styles.propertyContainer, className)}>
        <div className={styles.labelContainer}>
            {
                label &&
                <Label disabled={disabled} className={styles.label} style={labelStyle}>{label}</Label>
            }
        </div>
        <div className={cx(styles.childrenContainer, childrenContainerClassName)}>
            {children}
        </div>
    </div>
);
