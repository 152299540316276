
export type ExecuteActionConfig = {
    dispatch: Dispatch;
    action: Action;
    actionsToDispatch?: Action[];
    next?: Dispatch;
};

export const executeAction = (config: ExecuteActionConfig): Action => {
    const { dispatch, action, actionsToDispatch } = config;

    const next = config.next || dispatch;

    const dispatchActions = () => {
        if (Array.isArray(actionsToDispatch) && actionsToDispatch.length > 0) {
            actionsToDispatch.forEach((action) => dispatch(action));
        }
    };

    const result = next(action);

    dispatchActions();

    return result;
};
