import makeStyles from "@mui/styles/makeStyles";
import { DASHBOARD_PAGE_CONTENT_MAX_WIDTH } from "../constants";

export const useStyles = makeStyles(
    theme => ({
        rootContainer: {
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: DASHBOARD_PAGE_CONTENT_MAX_WIDTH,
            margin: "0 auto",
            padding: "0 24px",
            [theme.breakpoints.up("sm")]: {
                padding: "0 32px"
            },
            [theme.breakpoints.up("md")]: {
                padding: "0 48px"
            }
        },
        actionsContainer: {
            [theme.breakpoints.down("sm")]: {
                flex: 1,
                display: "flex"
            },
            "& > *": {
                flex: 1
            },
            "& > *:not(:first-child)": {
                marginLeft: "24px"
            }
        },
    }),
    { name: "Footer" }
);
