import backgroundViewFactory from '../Background/view/backgroundViewFactory';
import kind from "./kind";
import calcRenderProps from "../Background/calcRenderProps";
import * as bgStyles from '../Background/view/BackgroundPreview.css';
import * as hoverBoxStyles from './view/HoverBoxPreview.css';
import { makeGetBackgroundSpecificStyles } from '../Background/makeGetBackgroundSpecificStyles';
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";
import type { CalcProps } from "../../Preview/flowTypes";
import type { HoverBoxComponent } from "./flowTypes";
import { HoverEffect } from "./constants";

export const componentDependencies = {
    missingAssetUrls: [],
    workspaceBBoxWidth: 100,
    colorPickerOpened: false
};

export default {
    kind,
    view: backgroundViewFactory({ ...bgStyles, ...hoverBoxStyles }),
    calcProps: ({
        component, siteSettings: { themeSettingsData }, globalStyles, paddingBottom
    }: CalcProps<HoverBoxComponent>) => {
        let calcProps = calcRenderProps({
            component,
            paddingBottom,
            componentDependencies: {
                ...componentDependencies, themeSettingsData, themeColorsData: getThemeColorsFromStylesheet(globalStyles)
            }
        });
        return {
            ...calcProps,
            className: (component.hover && component.hover.animation) || HoverEffect.instant
        };
    },
    getSpecificStyles: makeGetBackgroundSpecificStyles(),
    js: ['/renderStatic/hoverbox/dynamicEffect.js'],
};
