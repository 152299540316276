import { equals } from 'ramda';
import { LcTabName } from "../constants";
import type { LinkChooserInterchangeType } from "../flowTypes";

export default (result: LinkChooserInterchangeType, input: LinkChooserInterchangeType) => {
    const
        { link: resultLink, openInNewWindow: resultOpenInNewWindow } = result,
        { link: inputLink, openInNewWindow: inputOpenInNewWindow } = input;

    if (resultOpenInNewWindow !== inputOpenInNewWindow) return false;

    if (resultLink.type === LcTabName.FILE && inputLink.type === LcTabName.FILE) {
        return resultLink.value.id === inputLink.value.id;
    }

    if (resultLink.type === LcTabName.PAGE && inputLink.type === LcTabName.PAGE) {
        return resultLink.value === inputLink.value;
    }

    if (resultLink.type === LcTabName.URL && inputLink.type === LcTabName.URL) {
        return resultLink.value === inputLink.value;
    }

    if (resultLink.type === LcTabName.PHONE && inputLink.type === LcTabName.PHONE) {
        return false;
    }

    if (resultLink.type === LcTabName.EMAIL && inputLink.type === LcTabName.EMAIL) {
        return false;
    }

    if (resultLink.type === LcTabName.LOCATION && inputLink.type === LcTabName.LOCATION) {
        return false;
    }

    if (resultLink.type === LcTabName.SECTION_LINK && inputLink.type === LcTabName.SECTION_LINK) {
        return equals(resultLink.value, inputLink.value);
    }

    return false;
};
