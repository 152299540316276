import { pure } from 'recompose';
import * as React from "react";
import { connect, useSelector } from 'react-redux';
import platform from "platform";
import cx from 'classnames';
import * as actionCreators from '../actionCreators/index';
import * as styles from './TopBar.css';
import { Popups } from "../constants";
import { TopBarUserProfile } from './TopBarUserProfile/index';
import TopBarPagesTree from './TopBarPagesTree/index';
import TopBarItemContainer from './TopBarItemContainer';
import type { TopBarProps } from "../flowTypes";
import type { AppState } from "../../../redux/modules/flowTypes";
import SavePreviewPublishBtns from './SavePreviewPublishBtns/index';
import TopBarHelp from "./TopBarItemMenu/TopBarHelp/index";
import TopBarUpgrade from "./TopBarItemMenu/TopBarUpgrade/index";
import ErrorBoundary from "../../../redux/recoverAfterException/ErrorBoundary/ErrorBoundary";
import { injectIntl } from "../../../view/intl/index";
import { BACKUP_CLICKED } from "../../Backup/actionTypes";
import { openMobileViewEditorClicked, closeMobileViewEditorClicked } from "../../MobileViewEditor/actionCreators";
import { DemoTip } from '../../../../demo/modules/tip/DemoTip';
import { TooltipPosition } from '../../presentational/Tooltip/constants';
import { whatsNewCountAppSel } from '../../WhatsNew/selectors';
import { MacSymbol } from "../../ContextMenu/view/MacSymbols";
import { Separator } from "./Separator";
import Support from '../Support/view/index';
import { OnecomLogo } from "../../../view/Logo/index";
import { Flex } from "../../../view/reflexbox/index";
import { isBasicSubscription, isNormalSubscription, isPremiumSubscription } from "../../App/epics/subscriptionData/utils";
import { makeEpicStateSelector } from "../../../epics/makeEpic";
import AUTO_COLOR_LEFT_PANEL_EPIC_VALUE from "../../AutoColorLeftPanel/epic/valueActionType";
import { colorThemeDataEpicStateFromAppStateSelector } from '../../SiteSettings/ColorThemeData/selectorActionTypes';
import {
    BACKUP_RESTORE_MOUSE_ENTER, BACKUP_RESTORE_MOUSE_LEAVE,
    MOBILE_EDITOR_MOUSE_ENTER,
    MOBILE_EDITOR_MOUSE_LEAVE
} from "../../ModernLayouts/actionTypes";
import { isEcommerceSubscriptionTypeEnabled } from "../../ComponentTierManager/utils";
import TopBarTutorial, { shouldShowTutorial } from './TopBarItemMenu/TopBarTutorial';
import { showTutorialButtonOnTopBarSelector } from '../epics/checkPublishStatus/selectors';
import getFeatureManager from '../../../getFeatureManager';

const
    undoRedoButtonFactory = ({
        additionalIconClassName,
        title
    }: { additionalIconClassName?: string, title: string }) =>
        injectIntl(pure(({ disabled, onClick, intl }) => {
            const disabledEnabledStyle = disabled ? styles.disabled : styles.enabled;
            return (
                <Flex
                    align="center"
                    className={cx(styles.undoRedo, disabledEnabledStyle)}
                    onClick={onClick}
                    data-title={intl.msgJoint(title)}
                >
                    <div className={cx(styles.undoRedoIcon, disabledEnabledStyle, additionalIconClassName)} />
                </Flex>
            );
        })),
    UndoButton = undoRedoButtonFactory({
        title: 'msg: topbar.undoBtnTitle {Undo your latest action}'
    }),
    RedoButton = undoRedoButtonFactory({
        title: 'msg: topbar.redoBtnTitle {Redo the latest undone action}',
        additionalIconClassName: styles.redo
    }),
    TopBar = injectIntl((props: TopBarProps) => {
        if (!props.state) {
            return <div className={styles.topBar} />;
        }

        const
            {
                dispatch,
                state: {
                    popup,
                    pagesTree,
                    undoRedoButtons,
                    publishStatus,
                    subscriptionData,
                    upgradeStatus,
                    chatStatus,
                    languages,
                    helpBtnGetClientRect,
                    pagesTreeContainerGetClientRect,
                    saveBtnGetClientRect,
                    tutorialBtnGetClientRect,
                    showMobileEditor,
                    publishWithSSL,
                    support,
                    componentTierManagerState
                },
                site,
                showAutosavePopup,
                autoColorLeftPanelOpen,
                themeSettings,
                whatsNewCount,
            } = props,
            isPagesTreeShown = popup && popup.id === Popups.PAGES,
            isUserProfilePopupShown = popup && popup.id === Popups.USER_PROFILE,
            isHelpPopupShown = popup && popup.id === Popups.HELP,
            showTutorialBtn = useSelector(showTutorialButtonOnTopBarSelector) && shouldShowTutorial(languages.current),
            isTutorialVideoIntroPopupShown = popup && popup.id === Popups.TUTORIAL_VIDEO_INTRO,
            saveStatus = pagesTree.saveStatus,
            isShowUpgrade = (
                getFeatureManager().isUpgradeButtonShown() && (
                    isBasicSubscription(subscriptionData.subscriptionType) ||
                    isNormalSubscription(subscriptionData.subscriptionType) ||
                    (isPremiumSubscription(subscriptionData.subscriptionType) && isEcommerceSubscriptionTypeEnabled())
                )
            ),
            prefixTextStyle = platform.os.family === 'OS X' ? MacSymbol.commandK : 'Ctrl',
            backupTooltipTitle =
                'msg: tooltip.backupsIcon {Backups - Restore your website project to a previous version}',
            desktopEditorToolTip = ['msg: tooltip.showDesktopEditor {Switch back to desktop ({prefixTextStyle} + J)}',
                { prefixTextStyle }],
            mobileEditorToolTip = ['msg: tooltip.showMobileEditor {Edit your site for mobile ({prefixTextStyle} + J)}',
                { prefixTextStyle }],
            showMobileEditorTooltipTitle = showMobileEditor ? desktopEditorToolTip : mobileEditorToolTip,
            clickedMobileEditor = showMobileEditor ? closeMobileViewEditorClicked :
                openMobileViewEditorClicked,
            mobileEditorIcon = showMobileEditor ? styles.desktopEditorBtnIcon :
                styles.mobileEditorBtnIcon,
            noPagesMarkedForPublish = site.getPageIdsNotMarkedAsDontPublish().length === 0,
            { isUpgrading } = upgradeStatus,
            isThemeEditMode = themeSettings.autoColorMode && autoColorLeftPanelOpen;

        return (
            <div className={styles.topBar}>
                <TopBarItemContainer left>
                    <OnecomLogo />
                </TopBarItemContainer>
                <TopBarItemContainer left disableMouseDown>
                    <ErrorBoundary>
                        <TopBarPagesTree
                            subscriptionType={subscriptionData.subscriptionType}
                            pagesTreeContainerGetClientRect={pagesTreeContainerGetClientRect}
                            isShown={isPagesTreeShown}
                            themeEditMode={isThemeEditMode}
                            state={pagesTree}
                            dispatch={dispatch}
                        />
                    </ErrorBoundary>
                </TopBarItemContainer>
                <ErrorBoundary invisible>
                    {
                        isShowUpgrade && (
                            <React.Fragment>
                                <TopBarUpgrade
                                    componentTierManagerState={componentTierManagerState}
                                    pagesTree={pagesTree}
                                    subscriptionData={subscriptionData}
                                    upgradeStatus={upgradeStatus}
                                    dispatch={dispatch}
                                    isUpgrading={isUpgrading}
                                />
                                <TopBarItemContainer left extraClassName={styles.displayHelp}>
                                    <Separator />
                                </TopBarItemContainer>
                            </React.Fragment>
                        )
                    }
                </ErrorBoundary>
                <TopBarItemContainer left disableMouseDown extraClassName={styles.displayHelp}>
                    <TopBarHelp
                        isShown={isHelpPopupShown}
                        chatLoaded={chatStatus.loaded}
                        getClientRect={helpBtnGetClientRect}
                        showQuickTour
                        showTutorial={!useSelector(showTutorialButtonOnTopBarSelector) && shouldShowTutorial(languages.current)}
                        dispatch={dispatch}
                    />
                </TopBarItemContainer>
                {
                    showTutorialBtn && <React.Fragment>
                        <TopBarItemContainer left extraClassName={styles.displayTutorial}>
                            <Separator />
                        </TopBarItemContainer>
                        <TopBarItemContainer left disableMouseDown extraClassName={styles.displayTutorial}>
                            <TopBarTutorial
                                tutorialBtnGetClientRect={tutorialBtnGetClientRect}
                                showTutorial={isTutorialVideoIntroPopupShown}
                            />
                        </TopBarItemContainer>
                    </React.Fragment>
                }
                <TopBarItemContainer disableMouseDown extraClassName={styles.displayUserProfile}>
                    <TopBarUserProfile
                        isShown={isUserProfilePopupShown}
                        languages={languages}
                        subscriptionData={subscriptionData}
                        whatsNewCount={whatsNewCount}
                        publishWithSSL={publishWithSSL}
                        shouldShowSettingsLink
                        dispatch={dispatch}
                    />
                </TopBarItemContainer>
                <TopBarItemContainer>
                    <SavePreviewPublishBtns
                        showAutosavePopup={showAutosavePopup}
                        saveBtnGetClientRect={saveBtnGetClientRect}
                        publishStatus={publishStatus}
                        noPagesMarkedForPublish={noPagesMarkedForPublish}
                        saveStatus={saveStatus}
                        subscriptionData={subscriptionData}
                        dispatch={dispatch}
                    />
                </TopBarItemContainer>
                <TopBarItemContainer extraClassName={styles.displayUndoRedo}>
                    <ErrorBoundary>
                        <Flex align="center" justify="center" className={styles.rightPartContainer}>
                            <UndoButton
                                disabled={undoRedoButtons.undoDisabled}
                                onClick={() => dispatch(actionCreators.undoButtonPressed())}
                            />
                            <Separator />
                            <RedoButton
                                disabled={undoRedoButtons.redoDisabled}
                                onClick={() => dispatch(actionCreators.redoButtonPressed())}
                            />
                            <Separator />
                            {/* @ts-ignore */}
                            <DemoTip position={TooltipPosition.BOTTOM}>
                                {/* Do not replace with <Flex> */}
                                <div
                                    className={cx(styles.undoRedo, styles.withRestoreBtn)}
                                    onClick={() => {
                                        dispatch({ type: BACKUP_CLICKED });
                                    }}
                                    onMouseEnter={() => dispatch({ type: BACKUP_RESTORE_MOUSE_ENTER })}
                                    onMouseLeave={() => dispatch({ type: BACKUP_RESTORE_MOUSE_LEAVE })}
                                    data-title={props.intl.msgJoint(backupTooltipTitle)}
                                >
                                    <div className={cx(styles.restoreBtn, styles.restoreAndEditorIcons)} />
                                </div>
                            </DemoTip>
                            <Separator />
                            <Flex
                                className={cx(styles.undoRedo, styles.withRestoreBtn, styles.editorBtn)}
                                onClick={() => {
                                    dispatch(clickedMobileEditor());
                                }}
                                onMouseEnter={() => dispatch({ type: MOBILE_EDITOR_MOUSE_ENTER })}
                                onMouseLeave={() => dispatch({ type: MOBILE_EDITOR_MOUSE_LEAVE })}
                                data-title={props.intl.msgJoint(showMobileEditorTooltipTitle as [string, AnyMap])}
                            >
                                <div className={cx(mobileEditorIcon, styles.restoreAndEditorIcons)} />
                            </Flex>
                        </Flex>
                    </ErrorBoundary>
                </TopBarItemContainer>
                <TopBarItemContainer>
                    <Support support={support || {}} dispatch={dispatch} />
                </TopBarItemContainer>
            </div>
        );
    });

const mapStateToProps = (appState: AppState) => ({
    state: appState.topBar,
    site: appState.siteMap,
    showAutosavePopup: appState.autosave.show,
    whatsNewCount: whatsNewCountAppSel(appState),
    autoColorLeftPanelOpen: makeEpicStateSelector(AUTO_COLOR_LEFT_PANEL_EPIC_VALUE)(appState).show,
    themeSettings: colorThemeDataEpicStateFromAppStateSelector(appState),
});

export default connect(mapStateToProps)(TopBar);
