import * as R from 'ramda';
import { arrayMove } from 'react-sortable-hoc';
import makeEpic from '../../epics/makeEpic';
import { when, receiveOnly } from '../../epics/makeCondition';
import makeUuid from '../../../utils/makeUuid';
import defaultImageData from './defaultImageData';
import { openImageChooserAction } from "../FileManager/imageChooser/actions";
import * as actionTypes from './actionTypes';
import openLinkChooserDialog from "../../view/common/dialogs/LinkChooserDialog/actionCreators/openLinkChooserDialog";
import sortingWindowDialogId from './dialogId';
import * as kbdActionTypes from "../App/kbdActionTypes";
import userFocusValueActionType from '../App/epics/userFocus/valueActionType';
import { makeActionForwardToSelectedComponent } from '../../redux/forwardTo';
import { OPEN_DIALOG, CLOSE_DIALOG } from '../../redux/modules/actionTypes';
import { openDialog } from '../App/actionCreators/index';
import * as focusKind from '../App/epics/userFocus/kind';
import { ReceiveOnlySelectedComponentSelector } from "../Workspace/epics/componentsEval/selectorActionTypes";
import { makeReplaceAssetsUpdaters } from "../Workspace/epics/componentsEval/makeReplaceAssetsUpdaters";
import type { AssetsReplacements } from "../../utils/assetUtils";
import { getImageNameWithoutExtension } from "./utils";
import {
    DEFAULT_MULTI_SELECT_VALIDATION_TITLE,
} from "../../view/common/FileChooser/constants";
import { MaxMultiSelectWarningDialog } from "../../view/common/FileChooser/dialogIds";
import { LIMIT } from "../oneweb/Gallery/constants/index";
import { FcContentTypes } from "../../redux/modules/children/fileChooser/FcContentTypes";
import { customSendReport } from "../../customSendCrashReport";
import { uploadFilesAction } from "../FileUploader/actionCreators";
import { fcSaveAction } from "../../redux/modules/children/fileChooser/actionCreators/index";
import {
    FU_UPLOADING_FILE_SUCCESS,
    FU_SELECTED_FILES,
    FU_UPLOAD_COMPLETED,
    FU_UPLOAD_CANCELED,
    FU_UPLOADING_FILE_FAILED,
    FU_UPLOADING_FILE
} from "../FileUploader/actions";

const
    defaultState = {
        images: [],
        activeNodeIndex: -1,
        maxImagesLimit: 0,
        uploading: false,
        ghostImages: [],
        label: null,
        message: null,
        tempImages: null,
        captionsEnabled: false
    },
    makeSetActiveNodeIndexUpdater = actionType => ({
        conditions: [actionType],
        reducer: ({ values: [index], state }) => ({
            state: {
                ...R.assoc('activeNodeIndex', index, state)
            }
        })
    }),
    mapAssetToImage = asset => ({
        ...defaultImageData,
        asset: { ...asset, id: makeUuid() },
        title: (getImageNameWithoutExtension(asset.url) || '').substr(0, LIMIT.title.hard)
    }),
    makeActiveImagePropSetter = prop =>
        (activeNodeIndex, value, state) => R.assocPath(['images', activeNodeIndex, prop], value, state),
    setActiveImageAction = makeActiveImagePropSetter('action'),
    setActiveImageCaption = makeActiveImagePropSetter('caption'),
    setActiveImageAltText = makeActiveImagePropSetter('altText'),
    setActiveImageTitle = makeActiveImagePropSetter('title'),
    setActiveImageAsset = makeActiveImagePropSetter('asset'),
    makeInsertAssets = assets => {
        if (assets && Array.isArray(assets)) {
            return R.evolve({
                images: R.concat(R.__, assets.map(mapAssetToImage))
            });
        }

        customSendReport({
            message: "SORT_IMAGE_DIALOG_ERROR",
            additionalInfo: {
                response: JSON.stringify(assets)
            }
        });

        return R.evolve({
            images: R.concat(R.__, [])
        });
    },
    deleteUpdater = (imageToRemoveIndex, state) => ({
        state: R.evolve({
            images: R.remove(imageToRemoveIndex, 1),
            activeNodeIndex: activeNodeIndex => {
                if (activeNodeIndex === imageToRemoveIndex) {
                    if (activeNodeIndex === state.images.length - 1) {
                        return state.images.length - 2;
                    }
                    return activeNodeIndex;
                } else if (activeNodeIndex > imageToRemoveIndex) {
                    return activeNodeIndex - 1;
                }
                return activeNodeIndex;
            }
        }, state)
    }),
    deleteByKbdUpdaterFactory = triggerActionType =>
        ({
            conditions: [
                triggerActionType,
                receiveOnly(userFocusValueActionType)
            ],
            reducer: ({ values: [, focus], state }) => {
                if (state.activeNodeIndex !== -1
                    && focus.kind === focusKind.DIALOG
                    && focus.data.dialogId === sortingWindowDialogId
                ) {
                    return deleteUpdater(state.activeNodeIndex, state);
                }
                return { state };
            }
        }),
    replaceAssetsReducer = (state: Record<string, any>, assetsReplacements: AssetsReplacements): Object => {
        if (!state.images.length) return state;

        let updated = false;
        const nextImages = state.images.map(img => {
            const newAsset = assetsReplacements[img.asset.url];
            if (newAsset) {
                updated = true;
                return { ...img, asset: { ...img.asset, ...newAsset } };
            }
            return img;
        });

        return updated ? { ...state, images: nextImages } : state;
    };

export default makeEpic({
    defaultState,
    valueActionType: "SORITING_IMAGES_DIALOG_STATE_VALUE_ACTION_TYPE",
    updaters: [
        {
            conditions: [actionTypes.SORT_IMAGES_DIALOG_FC_IMAGES_ADDED],
            reducer: ({ values: [{ assets }], state }) =>
                ({ state: { ...makeInsertAssets(assets)(state), ghostImages: [], uploading: false } })
        },
        makeSetActiveNodeIndexUpdater(actionTypes.SORT_IMAGES_DIALOG_IMAGE_SELECTED_TO_SORT),
        {
            conditions: [actionTypes.SORT_IMAGES_DIALOG_SORTING_ENDED],
            reducer: ({ values: [{ oldIndex, newIndex }], state }) => ({
                state: R.evolve({
                    images: () => arrayMove(state.images, oldIndex, newIndex),
                    activeNodeIndex: () => newIndex
                }, state)
            })
        },
        {
            conditions: [actionTypes.SORT_IMAGES_DIALOG_IMAGE_DELETE_ICON_CLICKED],
            reducer: ({ values: [imageToRemoveIndex], state }) => deleteUpdater(imageToRemoveIndex, state)
        },
        deleteByKbdUpdaterFactory(kbdActionTypes.BACKSPACE_BTN_PRESS),
        deleteByKbdUpdaterFactory(kbdActionTypes.DEL_BTN_PRESS),
        {
            conditions: [actionTypes.SORT_IMAGES_DIALOG_SET_IMAGE_LINK_BTN_CLICKED],
            reducer: ({ state }) => ({
                state,
                actionToDispatch: state.activeNodeIndex !== -1
                    ? openLinkChooserDialog({
                        setLinkAction: actionTypes.SORT_IMAGES_DIALOG_SET_IMAGE_LINK,
                        input: state.images[state.activeNodeIndex].action
                    })
                    : null
            })
        },
        {
            conditions: [actionTypes.SORT_IMAGES_DIALOG_SET_IMAGE_LINK],
            reducer: ({ values: [{ linkAction }], state }) => ({
                state: setActiveImageAction(state.activeNodeIndex, linkAction, state)
            })
        },
        {
            conditions: [actionTypes.SORT_IMAGES_DIALOG_UNSET_IMAGE_ACTION_CLICK],
            reducer: ({ state }) => ({
                state: setActiveImageAction(state.activeNodeIndex, null, state)
            })
        },
        {
            conditions: [actionTypes.SORT_IMAGES_DIALOG_UPDATE_IMAGE_CAPTION],
            reducer: ({ values: [caption], state }) => {
                return {
                    state: {
                        ...setActiveImageCaption(
                            state.activeNodeIndex,
                            caption.substr(0, LIMIT.caption.max),
                            state
                        )
                    }
                };
            }
        },
        {
            conditions: [actionTypes.SORT_IMAGES_DIALOG_UPDATE_IMAGE_ALT_TEXT],
            reducer: ({ values: [altText], state }) => ({
                state: setActiveImageAltText(state.activeNodeIndex, altText, state)
            })
        },
        {
            conditions: [actionTypes.SORT_IMAGES_DIALOG_UPDATE_IMAGE_TITLE],
            reducer: ({ values: [title], state }) => {
                const
                    newTitle = title.substr(0, LIMIT.title.hard),
                    newState = setActiveImageAltText( // update alt text first
                        state.activeNodeIndex,
                        newTitle,
                        state
                    );
                return {
                    state: {
                        ...setActiveImageTitle( // update title with updated alt text
                            state.activeNodeIndex,
                            newTitle,
                            newState
                        )
                    }
                };
            }
        },
        {
            conditions: [actionTypes.SORT_IMAGES_DIALOG_ADD_IMAGES_BTN_CLICKED],
            reducer: ({ state }) => {
                const newLimit = state.maxImagesLimit - state.images.length;
                if (newLimit <= 0) {
                    return {
                        state,
                        actionToDispatch: openDialog(MaxMultiSelectWarningDialog, {
                            title: DEFAULT_MULTI_SELECT_VALIDATION_TITLE,
                            label: state.label,
                            limit: state.maxImagesLimit,
                            message: state.message,
                            remaniningLimit: newLimit
                        })
                    };
                }

                return ({
                    state: { ...state, uploading: false },
                    actionToDispatch: openImageChooserAction({
                        onSaveAction: actionTypes.SORT_IMAGES_DIALOG_FC_IMAGES_ADDED,
                        isMultiSelect: true,
                        forwardToComponent: true,
                        maxMultiSelectValidation: {
                            title: DEFAULT_MULTI_SELECT_VALIDATION_TITLE,
                            label: state.label,
                            limit: state.maxImagesLimit,
                            message: state.message,
                            remaniningLimit: newLimit
                        },
                    })
                });
            }
        },
        {
            conditions: [
                ReceiveOnlySelectedComponentSelector,
                when(OPEN_DIALOG, ({ payload: { dialogId } }) => dialogId === sortingWindowDialogId)
            ],
            reducer: ({
                values: [
                    { images, captionsEnabled },
                    { props: { limit, label, message } }
                ]
            }) => ({
                state: R.evolve({
                    captionsEnabled: () => captionsEnabled,
                    tempImages: () => images,
                    maxImagesLimit: () => limit,
                    label: () => label, // either Gallery or Slider label :/
                    message: () => message,
                    activeNodeIndex: () => (images.length ? 0 : -1)
                }, defaultState),
                /* todo remove this hack - move DialogManager from reducer to epic */
                actionToDispatch: { type: actionTypes.SORT_IMAGES_DIALOG_SHOW_IMAGES, dedicatedRender: true }
            })
        },
        {
            conditions: [actionTypes.SORT_IMAGES_DIALOG_SHOW_IMAGES],
            reducer: ({ state }) => ({ state: R.assoc('images', state.tempImages, state) })
        },
        {
            conditions: [actionTypes.SORT_IMAGES_DIALOG_SAVE_BTN_CLICKED],
            reducer: ({ state }) => ({
                state,
                actionToDispatch: makeActionForwardToSelectedComponent({
                    type: actionTypes.SORT_IMAGES_DIALOG_SAVE_RESULT,
                    payload: {
                        images: state.images,
                        captionsEnabled: state.captionsEnabled
                    }
                })
            })
        },
        {
            conditions: [actionTypes.SORT_IMAGES_DIALOG_SAVE_RESULT],
            reducer: () => ({ state: defaultState, actionToDispatch: { type: CLOSE_DIALOG } })
        },
        {
            conditions: [actionTypes.SORT_IMAGES_DIALOG_TOGGLE_CAPTION],
            reducer: ({ state }) => ({ state: R.evolve({ captionsEnabled: R.not })(state) })
        },
        {
            conditions: [actionTypes.SORT_IMAGES_DIALOG_REPLACE_WITH_EDITED_IMAGE],
            reducer: ({ state, values: [{ asset, index }] }) => ({
                state: setActiveImageAsset(index, asset, state)
            })
        },
        {
            conditions: [actionTypes.SORT_IMAGES_UPLOAD_FILES],
            reducer: ({ values: [files], state }) => {
                if ((state.images.length + files.length) > state.maxImagesLimit) {
                    return {
                        state,
                        actionToDispatch: openDialog(MaxMultiSelectWarningDialog, {
                            title: DEFAULT_MULTI_SELECT_VALIDATION_TITLE,
                            label: state.label,
                            limit: state.maxImagesLimit,
                            message: state.message
                        })
                    };
                }
                return {
                    state: { ...state, uploading: true, ghostImages: [] },
                    actionToDispatch: uploadFilesAction({
                        isMultiSelect: true,
                        contentTypes: FcContentTypes.SUPPORTED_IMAGE,
                        files
                    })
                };
            }
        },
        ...[
            FU_UPLOADING_FILE,
            FU_UPLOADING_FILE_SUCCESS,
            FU_UPLOADING_FILE_FAILED,
            FU_SELECTED_FILES
        ].map((type) => ({
            conditions: [type],
            reducer: ({ values: [{ resources }], state }) => {
                if (state.uploading) {
                    return {
                        state: { ...state, ghostImages: resources },
                    };
                }
                return { state };
            }
        })),
        {
            conditions: [FU_UPLOAD_COMPLETED],
            reducer: ({ values: [{ files }], state }) => {
                if (state.uploading) {
                    return {
                        state,
                        actionToDispatch: fcSaveAction({
                            onSaveAction: actionTypes.SORT_IMAGES_DIALOG_FC_IMAGES_ADDED,
                            selection: files,
                            isMultiSelect: true,
                            softSave: true
                        })
                    };
                }
                return { state };
            }
        },
        {
            conditions: [FU_UPLOAD_CANCELED],
            reducer: ({ state }) => {
                if (state.uploading) {
                    return {
                        state: { ...state, ghostImages: [], uploading: false },
                    };
                }
                return { state };
            }
        },
        ...makeReplaceAssetsUpdaters(replaceAssetsReducer)
    ]
});

