import type { FileManagerState } from "../flowTypes";
import defaultState from '../defaultState';
import { resourceFilesSelector } from "../selectors";

export default (state: FileManagerState) => {
    const
        resources = resourceFilesSelector(state),
        { isMultiSelect, maxMultiSelectValidation } = state;
    let { allIsSelected } = state;

    let { selection } = state,
        isMaxMultiSelect = false;

    if (!allIsSelected) {
        // select
        if (isMultiSelect && maxMultiSelectValidation && resources.length > maxMultiSelectValidation.remaniningLimit) {
            isMaxMultiSelect = true;
        } else {
            selection = resources;
        }
    } else {
        selection = defaultState.selection;
    }

    if (!isMaxMultiSelect) allIsSelected = !allIsSelected;

    return { ...state, selection, isMaxMultiSelect, allIsSelected };
};
