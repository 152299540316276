import React from "react";
import styles, { borderThickness } from './ShiftBarTopDecorations.css';
import type { ShiftBarDecorationsState } from '../../../components/Workspace/epics/shiftBarDecos/index';
import { Border } from '../../../components/presentational/Border/Border';
import { makeBorderProps } from "./makeBorderProps";
import { red270 } from '../../../components/variables.css';

type Props = {
    state: ShiftBarDecorationsState
}

export default ({ state: { bBox, isVisible } }: Props) => {
    if (!isVisible) {
        return null;
    }
    const
        borderProps = makeBorderProps({
            bBox,
            opacity: isVisible ? 0.5 : 0,
            color: red270
        }),
        width = bBox.right - bBox.left,

        height = bBox.bottom - bBox.top,
        spacingStyle = {
            top: bBox.top,
            left: bBox.left - 1,
            width: width + 2,
            height,
            opacity: isVisible ? 1 : 0
        },
        labelWidth = 50,
        labelHeight = 35,
        topBarAdjustment = 2,   // Adjust by a few more pixels so that it looks vertically middle aligned
        labelStyle = {
            top: (height / 2) - (labelHeight / 2) + topBarAdjustment,
            left: (width / 2) - (labelWidth / 2)
        },
        lineStyle = {
            top: 0,
            left: width / 2,
            height: height - borderThickness
        };

    return (
        <div>
            <div style={spacingStyle} className={styles.spacing}>
                <div className={styles.label} style={labelStyle}>{Math.round(height)}px</div>
                <div className={styles.measurementLine} style={lineStyle} />
            </div>
            <Border {...borderProps} />
        </div>
    );
};
