/* eslint-disable max-len */
import { STEP_NAMES, FIELD_NAMES } from "../constants";

const HOBBY_INTERESTS = "personalHobbyInterests";
const TRIP = "personalTrip";
const PERSONAL_WEBSITE_DEFAULT = "personalWebsite";
const PERSONAL_WEBSITE_CATCHALL_DEFAULT = "personalWebsiteCatchall";

const gmbKeyGroup = {
    [HOBBY_INTERESTS]: [
        "animal_activist",
        "anime_enthusiast",
        "aquarium_hobbyist",
        "art_enthusiast",
        "craft_hobbyist",
        "cycling_enthusiast",
        "diy_home_improvement_hobbyist",
        "fashion_enthusiast",
        "film_tvseries_binging",
        "fishing_enthusiast",
        "fishing_hobbyist",
        "fitness_freak",
        "food_cooking_enthusiast",
        "gamer",
        "gardening_enthusiast",
        "health_fitness_enthusiast",
        "hiking_enthusiast",
        "hobby",
        "hobby_interest",
        "language_enthusiast",
        "music_hobbyist",
        "pet_keeping_hobbyist",
        "podcaster",
        "reading_enthusiast",
        "scrapbooking",
        "space_enthusiast",
        "star_gazing_hobbyist",
        "tech_coding_enthusiast",
    ],
    [TRIP]: [
        "family_trip",
        "trip",
        "travel_enthusiast"
    ],
};

const stepKeysBasedOnGmbKey = {
    [STEP_NAMES.BUSINESS_NAME]: {
        [FIELD_NAMES.TITLE]: {
            project: "msg: onboarding.dynamic.step2.project.title {What’s the name of your project?}"
        },
        [FIELD_NAMES.DESC]: {
            // PERSONAL_WEBSITE_DEFAULT and PERSONAL_WEBSITE_CATCHALL_DEFAULT is of type project
            project: "msg: onboarding.dynamic.step2.personalWebsite.description {This is how you or your project will be called across your website texts.}",
        },
        [FIELD_NAMES.PLACEHOLDER]: {
            [HOBBY_INTERESTS]: "msg: onboarding.dynamic.step2.hobbyInterests.placeHolder {e.g. DIY home improvements}",
            [TRIP]: "msg: onboarding.dynamic.step2.trip.placeHolder {e.g. Italy summer 2023}",
            [PERSONAL_WEBSITE_DEFAULT]: "msg: onboarding.dynamic.step2.personalWebsite.placeHolder {e.g. Charlie the Golden Retriever}",
            [PERSONAL_WEBSITE_CATCHALL_DEFAULT]: "msg: onboarding.dynamic.step2.personalWebsite.placeHolder {e.g. Charlie the Golden Retriever}",
        }
    },
    [STEP_NAMES.ABOUT_KEYWORDS]: {
        [FIELD_NAMES.TITLE]: {
            project: "msg: onboarding.dynamic.step3.project.title {Tell us about yourself or your project}",
        },
        [FIELD_NAMES.PLACEHOLDER]: {
            [HOBBY_INTERESTS]: "msg: onboarding.dynamic.step3.hobbyInterests.placeHolder {e.g. upcycle furniture, colour suggestions, low-cost materials.}",
            [TRIP]: "msg: onboarding.dynamic.step3.trip.placeHolder {e.g. family vacation, Naples, pizza recommendations.}",
            [PERSONAL_WEBSITE_DEFAULT]: "msg: onboarding.dynamic.step3.personalWebsite.placeHolder {e.g. 3 years old, loves walks, dog training tips.}",
            [PERSONAL_WEBSITE_CATCHALL_DEFAULT]: "msg: onboarding.dynamic.step3.personalWebsite.placeHolder {e.g. 3 years old, loves walks, dog training tips.}",
        }
    }
};

export {
    gmbKeyGroup,
    stepKeysBasedOnGmbKey
};

