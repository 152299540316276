import { clamp } from 'ramda';

const
    TweetMinWidth = 333,
    TweetMaxWidth = 550,
    TwitterMinWidth = 220,
    TwitterMaxWidth = 1200;

const hook = ({
    component,
    componentExtension
}) => {
    const { timelineLink, listLink } = component;
    const minWidth = (timelineLink || listLink) ? TwitterMinWidth : TweetMinWidth;
    const maxWidth = (timelineLink || listLink) ? TwitterMaxWidth : TweetMaxWidth;

    const updatedComponent = {
        ...component,
        width: clamp(minWidth, maxWidth, component.width),
    };

    return [updatedComponent, componentExtension];
};

const adjustmentHookConfig = {
    hook,
    shouldCallHook: () => true,
};

export { adjustmentHookConfig };
