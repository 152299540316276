import cheerio from "cheerio";
import { setComponentsMap } from "../../Workspace/epics/componentsEval/setters";
import { GROUP_TEXT_COMPONENTS_INTO_ONE, REMOVE_EMPTY_STRIPS_OVERLAPPING_WITH_HEADER } from "./actions";
import { getCmps } from "../../Workspace/epics/componentAttachements/util";
import { deselectAllComponents } from "../../Workspace/epics/componentsEval/selectedComponentsIds";
import { ReceiveOnlyTemplateWidthActionType } from "../../oneweb/Template/epics/template/selectorActionTypes";
import { updateComponentsRelIns } from "../../Workspace/epics/relations/updateComponentsRelIns";
import { isBoxKind, isSectionKind, TEXT } from "../../oneweb/componentKinds";
import { divideInToGroups } from "../../../convertToSections/util";
import { COMPONENTS_DELETED } from "../../Workspace/actionTypes";

const MAX_TEXT_COMPONENT_HEIGHT = 5000;
const MIN_GAP_BETWEEN_TEXT_COMPONENTS = 30;

const getTextCmpWithoutStyle = (content) => {
    const $dom = cheerio.load(`<div class="tempWrapper">${content}</div>`);
    $dom(".tempWrapper").children().removeAttr("style");
    return $dom(".tempWrapper").html();
};

export default {
    conditions: [
        ReceiveOnlyTemplateWidthActionType,
        GROUP_TEXT_COMPONENTS_INTO_ONE
    ],
    reducer: ({ values: [templateWidth, _groupTextMinGap], state: epicState }) => {
        let { state: { componentsMap } } = epicState,
            newComponentsMap = { ...componentsMap },
            textCmpsContent = '';
        const componentIdsToDelete: Array<string> = [],
            groupTextMinGap = parseInt(_groupTextMinGap, 10) || MIN_GAP_BETWEEN_TEXT_COMPONENTS,
            cmpsArr = getCmps(newComponentsMap),
            pageSections = cmpsArr.filter(x => isSectionKind(x.kind) && !x.inTemplate),
            backupTextCmpsArr = [...cmpsArr.filter(x => x.kind === TEXT && !x.inTemplate)];

        pageSections.forEach(pageSection => {
            const { top: sectionTop, height: sectionHeight } = pageSection;

            let isGroupCmpFound = true;

            while (isGroupCmpFound) {
                const cmps = getCmps(newComponentsMap);
                const filteredCmps = cmps.filter(x => !isSectionKind(x.kind) && !isBoxKind(x.kind) &&
                x.top >= sectionTop && (x.top + x.height) <= sectionTop + sectionHeight);
                const groups: any = divideInToGroups(filteredCmps, false, true);
                for (let index = 0; index < groups.length; index++) {
                    let group = groups[index];
                    if (index === groups.length - 1) {
                        isGroupCmpFound = false;
                        continue;
                    }
                    if (group.cmps.length !== 1 || groups[index + 1].cmps.length !== 1) {
                        continue;
                    }
                    const prevCmp = { ...group.cmps[0] };
                    const nextCmp = groups[index + 1].cmps[0];
                    if (prevCmp.kind !== TEXT || nextCmp.kind !== TEXT) {
                        continue;
                    }
                    if (prevCmp.height > MAX_TEXT_COMPONENT_HEIGHT) {
                        continue;
                    }
                    if (prevCmp.top + prevCmp.height + groupTextMinGap < nextCmp.top) {
                        continue;
                    }
                    const $dom = cheerio.load(`<div class="tempWrapper">${nextCmp.content}</div>`);
                    const paddingTop = nextCmp.top - prevCmp.top - prevCmp.height;
                    $dom(".tempWrapper").children().first().css("padding-top", `${paddingTop}px`);
                    const nextContent = $dom(".tempWrapper").html();
                    prevCmp.content = `${prevCmp.content}\n${nextContent}`;
                    prevCmp.text = `${prevCmp.text}\n${nextCmp.text}`;
                    prevCmp.height = Math.max(nextCmp.top + nextCmp.height - prevCmp.top);
                    newComponentsMap[prevCmp.id] = prevCmp;
                    delete newComponentsMap[nextCmp.id];
                    componentIdsToDelete.push(nextCmp.id);
                    break;
                }
            }
        });
        newComponentsMap = updateComponentsRelIns(newComponentsMap, templateWidth);

        // verify that all text component data still exists in the page
        getCmps(newComponentsMap).forEach(cmp => {
            if (cmp.kind === TEXT && !cmp.inTemplate) {
                textCmpsContent = textCmpsContent + getTextCmpWithoutStyle(cmp.content);
            }
        });
        let isAllTextFound = true;
        backupTextCmpsArr.forEach(cmp => {
            if (textCmpsContent.indexOf(getTextCmpWithoutStyle(cmp.content)) === -1) {
                isAllTextFound = false;
            }
        });
        if (!isAllTextFound) {
            alert("Some text components were not found in the page. Not updating the page.");
            return {
                state: epicState
            };
        }

        return {
            state: deselectAllComponents(setComponentsMap(newComponentsMap, epicState)),
            actionToDispatch: {
                type: COMPONENTS_DELETED,
                payload: componentIdsToDelete
            },
            updateReason: REMOVE_EMPTY_STRIPS_OVERLAPPING_WITH_HEADER
        };
    }
};
