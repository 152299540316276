import { makeEpic } from "../../../../epics/makeEpic";
import { ComponentsMapSelector } from '../../../Workspace/epics/componentsEval/selectorActionTypes';
import { getHeaderSection, getFooterSection } from "../utils";
import { Lit } from '../../../../lit';
import type { AnyComponent } from "../../flowTypes";
import { getAllCmpsInAContainer } from "../../../ModernLayouts/preview_utils";

export type ComponentsInHeaderFooterSectionsEpic = {
    headerSection: {
        id: string,
        sectionCmp: AnyComponent,
        cmpsInSection: Array<AnyComponent>
    },
    footerSection: {
        id: string,
        sectionCmp: AnyComponent,
        cmpsInSection: Array<AnyComponent>
    }
}

const defaultState = {
    [Lit.headerSection]: {},
    [Lit.footerSection]: {},
};

export const componentsInHeaderFooterSectionVAT = "COMPONENTS_IN_HEADER_FOOTER_SECTIONS";

export const componentsInHeaderFooterSectionsEpic = makeEpic({
    defaultState,
    valueActionType: "COMPONENTS_IN_HEADER_FOOTER_SECTIONS",
    updaters: [
        {
            conditions: [ComponentsMapSelector],
            reducer: ({ values: [componentsMap] }) => {
                const headerSection = getHeaderSection(componentsMap),
                    footerSection = getFooterSection(componentsMap);
                if (!headerSection || !footerSection) {
                    return { state: defaultState };
                }
                return {
                    state: {
                        [Lit.headerSection]: {
                            id: headerSection.id,
                            sectionCmp: headerSection,
                            cmpsInSection: getAllCmpsInAContainer(componentsMap, headerSection.id)
                        },
                        [Lit.footerSection]: {
                            id: footerSection.id,
                            sectionCmp: footerSection,
                            cmpsInSection: getAllCmpsInAContainer(componentsMap, footerSection.id)
                        }
                    }
                };
            },
        },
    ],
});
