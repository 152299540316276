import React from 'react';
import { OPEN_DIALOG } from '../../../redux/modules/actionTypes';
import TextIconButton from '../TextIconButton/index';
import Icons from '../../../view/Icons/index';
import { injectIntl } from '../../../view/intl/index';
import type { TextShadowButtonPropTypes } from './flowTypes';

export default injectIntl(({ dialogId, dispatch, intl, ...rest }: TextShadowButtonPropTypes) => (
    <TextIconButton
        large
        text={intl.msgJoint('msg: common.textShadow {Text shadow}')}
        Icon={Icons.BOTTOM}
        onClick={() => dispatch({ type: OPEN_DIALOG, payload: { dialogId, props: { ...rest, dispatch } } })}
    />
));
