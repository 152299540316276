import { WIZARD_STEP_ID } from "../../constants";

export const BUSINESS_NAME_CLIP_LENGTH = 32;

const MARKER_INITIAL_VALUE = 0;
export const STEP_MARKERS = {
    [WIZARD_STEP_ID.GMB_SELECTION]: {
        gmb_start_point: MARKER_INITIAL_VALUE,
        gmb_key_typing: MARKER_INITIAL_VALUE,
        gmb_key_selected: MARKER_INITIAL_VALUE,
        concept_loading: MARKER_INITIAL_VALUE,
    },
    [WIZARD_STEP_ID.WEBSITE_PURPOSE]: {
        concept_loading: MARKER_INITIAL_VALUE,
        website_purpose_start_point: MARKER_INITIAL_VALUE,
        website_purpose_one_added: MARKER_INITIAL_VALUE,
        website_purpose_one_added_scroll_up: MARKER_INITIAL_VALUE,
        website_purpose_two_added: MARKER_INITIAL_VALUE,
        website_purpose_two_added_scroll_up: MARKER_INITIAL_VALUE,
        website_purpose_end_point: MARKER_INITIAL_VALUE,
    },
    [WIZARD_STEP_ID.NAME]: {
        website_purpose_one_added: MARKER_INITIAL_VALUE,
        website_purpose_one_added_scroll_up: MARKER_INITIAL_VALUE,
        website_purpose_two_added: MARKER_INITIAL_VALUE,
        website_purpose_two_added_scroll_up: MARKER_INITIAL_VALUE,
        website_purpose_end_point: MARKER_INITIAL_VALUE,
        business_name_start_point: MARKER_INITIAL_VALUE,
        business_name_disappears: MARKER_INITIAL_VALUE,
        business_name_appears: MARKER_INITIAL_VALUE,
    },
    [WIZARD_STEP_ID.DESCRIPTION]: {
        business_name_appears: MARKER_INITIAL_VALUE,
        description_start_point: MARKER_INITIAL_VALUE,
        description_one_word_entered: MARKER_INITIAL_VALUE,
        description_two_words_entered: MARKER_INITIAL_VALUE,
        description_end_point: MARKER_INITIAL_VALUE,
    },
    [WIZARD_STEP_ID.CONTACT_INFO]: {
        description_end_point: MARKER_INITIAL_VALUE,
        contact_info_start_point: MARKER_INITIAL_VALUE,
        contact_info_email_focused: MARKER_INITIAL_VALUE,
        contact_info_phone_focused: MARKER_INITIAL_VALUE,
        contact_info_end_point: MARKER_INITIAL_VALUE
    },
    [WIZARD_STEP_ID.PRONOUNS]: {
        language_end_point: MARKER_INITIAL_VALUE,
        pronouns_start_point: MARKER_INITIAL_VALUE,
        pronouns_added: MARKER_INITIAL_VALUE
    },
    [WIZARD_STEP_ID.LANGUAGE]: {
        language_start_point: MARKER_INITIAL_VALUE,
        language_changed: MARKER_INITIAL_VALUE,
    },
    TEMPLATE_LOADER: {
        pronouns_added: MARKER_INITIAL_VALUE,
        template_loader_start_point: MARKER_INITIAL_VALUE,
        template_loader_end_point: MARKER_INITIAL_VALUE,
    }
};
