import React from "react";
export const SvgLinkedinSquareFillDark = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <path fill="#3c3c3c" d="M0 0h48v48H0z" />
            <path
                fill="#ffffff"
                d="M21.524 19.51h4.165v2.132h.06c.579-1.038 1.997-2.132 4.11-2.132 4.396 0 5.21 2.733 5.21 6.286v7.238h-4.342v-6.416c0-1.53-.031-3.5-2.258-3.5-2.26 0-2.605 1.668-2.605 3.389v6.527h-4.34V19.51zm-7.524 0h4.515v13.524H14zm4.515-3.756a2.256 2.256 0 01-2.258 2.254A2.256 2.256 0 0114 15.754a2.256 2.256 0 012.257-2.254 2.256 2.256 0 012.258 2.254z"
            />
        </g>
    </svg>
);
