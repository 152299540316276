import React from 'react';
import ImagePreview, { imagePreview } from '../../Image/view/preview';
import { LogoTextModeView } from './LogoTextModeView';
import type { Props } from './LogoTextModeView';

export const LogoPreviewView = (props: Props | imagePreview) => {
    if ("websiteTitle" in props) {
        return <LogoTextModeView {...props} />;
    }

    return <ImagePreview {...props} />;
};
