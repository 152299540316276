export { APP_STATUS_READY } from "../../../app/actionTypes";

export const
    OPEN_BOOKINGS_WEBSITE = "OPEN_BOOKINGS_WEBSITE",
    BOOKINGS_TRIAL_LEARN_MORE_CLICK = 'BOOKINGS_TRIAL_LEARN_MORE_CLICK';

export const openBookingsWebsiteAction = () => ({ type: OPEN_BOOKINGS_WEBSITE });
export const bookingsTrialLearnMoreClickAction = (source: string) => ({
    type: BOOKINGS_TRIAL_LEARN_MORE_CLICK,
    payload: { source }
});
