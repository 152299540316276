import cx from 'classnames';
import React from 'react';
import { Cell as ReactCell } from 'fixed-data-table-one.com';
import styles from '../FileChooserList.css';
import type { ListCellPropTypes } from "../../flowTypes";
import LongTextTip from '../../../LongTextTip/index';
import { SortColumns } from '../../../../../redux/modules/children/fileChooser/sortFunctions';

export default (props: ListCellPropTypes) => {
    const { column = '', children, onClick, isSelected, title, className } = props;

    return (
        <ReactCell
            onClick={onClick}
            className={cx(styles.cell, styles[column], { [styles.selected]: isSelected }, className)}
            title={title}
        >
            {
                column === SortColumns.NAME
                    ? <LongTextTip>{children}</LongTextTip>
                    : children
            }
        </ReactCell>
    );
};
