import * as R from "ramda";
import * as path from "../../../../src/mappers/path";
import * as colorMapper from "../Base/color";
import * as backgroundMapper from "../Common/background";
import * as borderMapper from "../Common/border";
import * as paddingMapper from "../Common/padding";
import { overPath } from "../../../../src/utils/ramdaEx";
import TextTransform from '../../../../src/components/Globalstyles/Menu/TextTransformTypes';
import * as fontFamilyMapper from "../Base/fontFamily";
import maybe from "../../../../src/utils/maybe";

export const
    rootKeys = [path.item, path.selected, path.expandable, path.expanded],
    subRootKeys = [path.disabled, path.hover, path.inactive, path.press, path.visited];

const
    colorMap = {
        to: overPath([[rootKeys, subRootKeys, ...path.textColor]])(maybe(colorMapper.toHsl)),
        back: overPath([[rootKeys, subRootKeys, ...path.textColor]])(R.identity)
    },

    backgroundMap = {
        to: overPath([
            [rootKeys, subRootKeys, ...path.blockBackground], [path.divider, path.background], path.blockBackground
        ])(backgroundMapper.to),
        back: overPath([
            [rootKeys, subRootKeys, ...path.blockBackground], [path.divider, path.background], path.blockBackground
        ])(backgroundMapper.back)
    },

    borderMap = {
        to: overPath([
            [rootKeys, subRootKeys, ...path.blockBorder], [path.divider, path.border], path.blockBorder
        ])(borderMapper.to),
        back: overPath([
            [rootKeys, subRootKeys, ...path.blockBorder], [path.divider, path.border], path.blockBorder
        ])(borderMapper.back)
    },

    paddingMap = {
        to: overPath([[rootKeys, subRootKeys, ...path.blockPadding], [path.divider, path.padding]])(paddingMapper.to),
        back: overPath([[rootKeys, subRootKeys, ...path.blockPadding], [path.divider, path.padding]])(paddingMapper.back) // eslint-disable-line max-len
    },

    fontFamilyMap = {
        to: overPath([[rootKeys, subRootKeys, ...path.textFont]])(fontFamilyMapper.to),
        back: overPath([[rootKeys, subRootKeys, ...path.textFont]])(fontFamilyMapper.back)
    },

    textMap = {
        to: overPath([[rootKeys, subRootKeys, path.text]])(
            R.evolve({
                transform: tt => R.or(tt, TextTransform.none),
                shadow: shadow => {
                    if (R.is(Object, shadow)) {
                        return R.evolve({
                            color: colorMapper.toHsl
                        }, shadow);
                    }

                    return shadow;
                }
            })
        ),
        back: overPath([[rootKeys, subRootKeys, path.text]])(R.evolve({ shadow: R.identity }))
    },

    addDefaultDividerPadding = R.evolve({
        [path.divider]: (divider) => {
            if (!Array.isArray(divider.padding)) {
                return { ...divider, padding: R.repeat(0, 4) };
            }

            return divider;
        }
    });

const addDividerDefaultIfMissing = componentData => ({
    ...componentData,
    divider: componentData.divider || {}
});

export const
    to = R.pipe(
        addDividerDefaultIfMissing,
        colorMap.to,
        backgroundMap.to,
        borderMap.to,
        paddingMap.to,
        fontFamilyMap.to,
        textMap.to,
        addDefaultDividerPadding
    ),
    back = R.pipe(
        colorMap.back,
        backgroundMap.back,
        borderMap.back,
        paddingMap.back,
        fontFamilyMap.back,
        textMap.back
    );
