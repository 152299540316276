import { WidgetsTiktokKind } from './kind';
import { componentMainActions } from './componentMainAction';
import { propertiesPanel } from './propertiesPanel/index';
import { reducer } from './reducer';
import { configurationDialogId } from './configurationDialog';
import { calcRenderProps } from './calcRenderProps';
import { TiktokWorkspace } from './view/workspace';
import { TiktokView } from './view/index';
import { getMVEHiddenElementData } from '../mobileEditorConfig';
import { WidgetsTiktokConfig } from '../constants';
import { adjustmentHookConfig } from './adjustmentHookConfig';

const WidgetsTiktok = {
    kind: WidgetsTiktokKind,
    label: WidgetsTiktokConfig.title,
    shortcutIconName: 'widgetsTiktok',
    workspace: TiktokWorkspace,
    view: TiktokView,
    requireConfigurationOnDrop: () => true,
    configurationDialog: configurationDialogId,
    calcRenderProps,
    componentMainActions,
    propertiesPanel,
    reducer,
    adjustmentHookConfig,
    mobileEditorConfig: {
        kind: WidgetsTiktokKind,
        calcProps: calcRenderProps,
        view: TiktokView,
        getHiddenElementData: getMVEHiddenElementData(WidgetsTiktokConfig),
    },
};

export { WidgetsTiktok };

