import React from 'react';
import cx from 'classnames';
import { Button } from '../../../view/common/Button/index';
import { BACK_TO_EDITOR } from '../../App/actionTypes';
import { openDialog } from '../../App/actionCreators/index';
import { injectIntl, Msg } from "../../../view/intl/index";
import PreviewSwitcher from '../../Preview/View/PreviewToolbar/previewSwitcher';
import { restoreDialogId, upgradeDialogId } from '../dialogs/index';
import { timestampToDateTime } from "../../../utils/date";
import { BACKUP_SET_PREVIEW_RECORD, BACKUP_DESKTOP_PREVIEW, BACKUP_MOBILE_PREVIEW } from "../actionTypes";
import LoadingIndicator from '../../../view/common/LoadingIndicator/index';
import styles from './Backup.css';
import previewStyles from '../../Preview/View/PreviewToolbar/PreviewToolbar.css';
import { OnecomLogo } from '../../../view/Logo';

const
    backToEditorButtonStyle = { position: 'absolute', top: 'auto', right: '90px' },
    previewSwitcherStyle = { position: 'absolute', top: 0, right: 0 };

export default injectIntl(({
    isMobileView,
    timestamp,
    timeline,
    hasPages,
    intl,
    isBackupAndRestoreAllowed,
    loading,
    dispatch
}) => {
    const currentIndex = timeline.findIndex(t => parseInt(timestamp, 10) === t.time),
        isNextExists = timeline[currentIndex + 1],
        isPrevExists = currentIndex > 0,
        previewPreviousBackup = intl.msgJoint('msg: web.panels.Preview.OlderTooltip {Preview previous saved version}'),
        previewNextBackup = intl.msgJoint('msg: web.panels.Preview.NewerTooltip {Preview next saved version}');

    return (
        <div className={previewStyles.previewToolbar}>
            <OnecomLogo />
            <div className={styles['cursor-container']}>
                <div className={styles.cursor}>
                    <div
                        className={
                            cx(styles.cursorArrow, styles.cursorArrow__left, { [styles.cursorArrow__disabled]: !isPrevExists })
                        }
                        data-title={isPrevExists ? previewPreviousBackup : undefined}
                        onClick={
                            () => {
                                if (isPrevExists) {
                                    dispatch({ type: BACKUP_SET_PREVIEW_RECORD, payload: timeline[currentIndex - 1].time });
                                }
                            }
                        }
                    />
                    {loading && <LoadingIndicator className={styles.toolBarLoading} />}
                    {!loading && <div className={styles.cursorText}>{timestampToDateTime(timestamp, intl)}</div>}
                    <div
                        className={cx(styles.cursorArrow, { [styles.cursorArrow__disabled]: !isNextExists })}
                        data-title={isNextExists ? previewNextBackup : undefined}
                        onClick={
                            () => {
                                if (isNextExists) {
                                    dispatch({ type: BACKUP_SET_PREVIEW_RECORD, payload: timeline[currentIndex + 1].time });
                                }
                            }
                        }
                    />
                </div>
                <Button
                    className={
                        cx(styles.restoreButton, previewStyles.button, { [styles.restoreDisabled]: !hasPages })
                    }
                    onClick={() => {
                        if (!isBackupAndRestoreAllowed) {
                            dispatch(openDialog(upgradeDialogId));
                        } else if (hasPages) {
                            dispatch(openDialog(restoreDialogId, { timestamp }));
                        }
                    }}
                >
                    <Msg k="preview.toolbar.restore">Restore</Msg>
                </Button>
            </div>
            <div className={styles.buttonsContainer}>
                <Button
                    className={cx(previewStyles.button, previewStyles.buttonBack, styles.buttonBack)}
                    onClick={() => dispatch({ type: BACK_TO_EDITOR })}
                    style={backToEditorButtonStyle}
                >
                    <Msg k="preview.toolbar.backToEditor">Back to editor</Msg>
                </Button>
                <PreviewSwitcher
                    style={previewSwitcherStyle}
                    mobileView={isMobileView}
                    onMobilePreview={() => dispatch({ type: BACKUP_MOBILE_PREVIEW })}
                    onDesktopPreview={() => dispatch({ type: BACKUP_DESKTOP_PREVIEW })}
                />
            </div>
        </div>
    );
});
