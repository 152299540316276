import type { ImageComponent } from './flowTypes';
import * as OnClickActions from "../Gallery/constants/onClickActions";

export default (component: ImageComponent): string => {
    const { lightBoxEnabled, openLink } = component;
    if (lightBoxEnabled) {
        return OnClickActions.OPEN_LIGHT_BOX;
    } else if (openLink) {
        return OnClickActions.OPEN_LINK;
    }
    return OnClickActions.NOTHING;
};
