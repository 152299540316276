import React from "react";
import * as R from "ramda";
import cx from "classnames";
import { Flex } from "../../../view/reflexbox/index";
import { injectIntl } from '../../../view/intl/index';
import Button from "../Button/index";
import type { Props } from "./flowTypes";
import styles from '../../buttons.css';

const nullFn = R.always(null);

export default injectIntl(({ buttonGroup, dispatch, intl }: Props) => {
    const
        buttons = buttonGroup.map(({
            icon: Icon,
            iconClassName,
            onClickAction,
            disabled,
            title,
            className,
            isSelected,
            ...rest
        }, index) => {
            const
                btnProps = {
                    onClick: (disabled ? nullFn : () => dispatch({ type: onClickAction, payload: !isSelected })),
                    title: title ? intl.msgJoint(title) : '',
                    disabled,
                    isSelected,
                    ...rest
                };
            return (
                <Button key={index} className={cx(styles.buttonGroup, className)} {...btnProps}>
                    <Icon className={iconClassName} />
                </Button>
            );
        });

    return (
        <Flex align="center" justify="space-between">
            {buttons}
        </Flex>
    );
});
