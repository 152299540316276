// @ts-nocheck
import * as styles from './customTooltip.css';
import { isIE11OrEdge } from '../../utils/browser';

const CustomTooltip = (function ($, window, inIframe = false) {
    const document = window.document,
        titleCustomAttr = 'data-title',
        errorTitleAttr = 'data-title-error',
        errorTitleDataAttr = 'title-error',
        warningTitleDataAttr = 'title-warning',
        delayTitleAttr = 'data-title-delay',
        tooltipContainer = document.createElement('div'),
        tipTextContainer = document.createElement('span'),
        arrowContainer = document.createElement('div'),
        $arrowContainer = $(arrowContainer),
        $tipTextContainer = $(tipTextContainer),
        TOP = 'top',
        LEFT = 'left',
        RIGHT = 'right',
        BOTTOM = 'bottom',
        arrowSize = isIE11OrEdge() ? styles.arrowSizeIE : styles.arrowSize,
        negativeArrowSize = styles.negativeArrowSize,
        arrowTopContainerTop = isIE11OrEdge() ? styles.arrowTopContainerTopIE : styles.arrowTopContainerTop;

    $(document.body).append(tooltipContainer);

    const $tooltipContainer = $(tooltipContainer);
    let toolTipTimeOut;
    let customClass = '';

    $tooltipContainer.append(tipTextContainer);
    $arrowContainer.addClass(styles.arrowContainer);
    $tooltipContainer.append(arrowContainer);
    $tooltipContainer.addClass(styles.customTooltipContainer);
    $tooltipContainer.on('mouseenter', () => $tooltipContainer.hide()); // in case tooltip stack on screen for some unknown reason will remove it once mouse over it

    if (isIE11OrEdge()) {
        $tooltipContainer.addClass(styles.modernIE);
    }

    let
        targetExistenceCheckTimeoutId,
        $targetEl;

    function hideAndCleanup(e) {
        clearTimeout(toolTipTimeOut);
        if (e.type === 'click' && this.getAttribute(errorTitleAttr)) {
            return;
        }
        $tooltipContainer.hide();
        clearTimeout(targetExistenceCheckTimeoutId);
        targetExistenceCheckTimeoutId = null;
        $tipTextContainer.removeClass(`${TOP} ${RIGHT} ${BOTTOM} ${LEFT}`);
        $tooltipContainer.removeClass(`${TOP} ${RIGHT} ${BOTTOM} ${LEFT} ${customClass}`);
        $tooltipContainer.removeAttr('style');
        customClass = '';
        if ($targetEl) {
            $targetEl.off('mouseleave', hideAndCleanup);
            $targetEl = null;
        }
    }

    function targetExistenceCheckLoop(e) {
        if (targetExistenceCheckTimeoutId || !$targetEl) {
            return;
        }
        const targetElRemoved = $targetEl.parents('body').toArray().length === 0;
        if (targetElRemoved) {
            $tooltipContainer.hide();
            hideAndCleanup(e);
            return;
        }
        targetExistenceCheckTimeoutId = setTimeout(() => {
            targetExistenceCheckTimeoutId = null;
            targetExistenceCheckLoop(e);
        }, 100);
    }
    const getPosition = ({ windowWidth, windowHeight, width, height, targetElPosition,
        customTooltipPosition, isWarningTitle }) => {
        const
            tooltipWidth = $tooltipContainer.outerWidth(),
            tooltipHeight = $tooltipContainer.outerHeight(),
            offset = 15,
            isLeftMost = (targetElPosition.left - ((tooltipWidth - width + offset) / 2)) <= 0,
            isRightMost = (targetElPosition.left + ((tooltipWidth + width + offset) / 2)) >= windowWidth,
            isBottomMost = ((targetElPosition.top + height) + tooltipHeight + offset) >= windowHeight,
            isTopMost = ((targetElPosition.top) - ((tooltipHeight + offset) / 2)) <= 0,
            centerTop = targetElPosition.top + (height / 2) - (tooltipHeight / 2),
            centerLeft = targetElPosition.left + (width / 2) - (tooltipWidth / 2),
            toolTipPosition = {
                right: {
                    left: targetElPosition.left + width + offset,
                    top: centerTop,
                    arrowTipClass: LEFT,
                    arrowLeft: isWarningTitle ? 0 : negativeArrowSize,
                    arrowTop: `calc(50% - ${arrowSize})`
                },
                left: {
                    left: targetElPosition.left - offset - tooltipWidth,
                    top: centerTop,
                    arrowTipClass: RIGHT,
                    arrowLeft: isWarningTitle ? '100%' : `calc(100% + ${arrowSize})`,
                    arrowTop: `calc(50% - ${arrowSize})`
                },
                bottom: {
                    left: centerLeft,
                    top: targetElPosition.top + height + offset,
                    arrowTipClass: TOP,
                    arrowLeft: '50%',
                    arrowTop: isWarningTitle ? negativeArrowSize : arrowTopContainerTop
                },
                top: {
                    left: centerLeft,
                    top: targetElPosition.top - offset - tooltipHeight,
                    arrowTipClass: BOTTOM,
                    arrowLeft: '50%',
                    arrowTop: isWarningTitle ? `calc(100% - ${arrowSize})` : (isIE11OrEdge() ? `calc(100% - 1px)` : '100%') //eslint-disable-line
                }
            };

        if (isRightMost &&
                ((customTooltipPosition === 'top' && !isTopMost) ||
                (customTooltipPosition === 'bottom' && !isBottomMost))) {
            const position = { ...toolTipPosition[customTooltipPosition] };
            position.left = position.left - (tooltipWidth / 2) + (width / 2);
            position.arrowRight = `${width / 2}px`;
            delete position.arrowLeft;
            return position;
        }
        if (isLeftMost &&
                ((customTooltipPosition === 'top' && !isTopMost) ||
                (customTooltipPosition === 'bottom' && !isBottomMost))) {
            const position = { ...toolTipPosition[customTooltipPosition] };
            position.left = offset / 2;
            position.arrowLeft = (tooltipWidth + position.left) >= (targetElPosition.left + width / 2) ?
                `${(targetElPosition.left + (width - offset) / 2)}px` :
                `${((width / 2) - (offset / 4))}px`;
            delete position.arrowRight;
            return position;
        }
        if (isLeftMost) {
            return toolTipPosition.right;
        } else if (isRightMost) {
            return toolTipPosition.left;
        } else if (isBottomMost) {
            return toolTipPosition.top;
        } else if (isTopMost) {
            return toolTipPosition.bottom;
        } else if (toolTipPosition[customTooltipPosition]) {
            return toolTipPosition[customTooltipPosition];
        }

        return toolTipPosition.bottom;
    };

    const attachCustomTooltip = text => {
        let customTooltipPosition = $targetEl.data('title-position');
        customClass = $targetEl.data('title-class');
        let isErrorTitle = $targetEl.data(errorTitleDataAttr);
        let isWarningTitle = $targetEl.data(warningTitleDataAttr);

        if (!customTooltipPosition) {
            customTooltipPosition = $targetEl.parents('[data-title-position]').data('title-position');
        }
        $tipTextContainer.html(text);
        if (isErrorTitle) {
            $tipTextContainer.addClass('error');
            $tooltipContainer.addClass('error');
        } else {
            $tipTextContainer.removeClass('error');
            $tooltipContainer.removeClass('error');
        }
        if (isWarningTitle) {
            $tipTextContainer.addClass('warning');
            $tooltipContainer.addClass('warning');
        } else {
            $tipTextContainer.removeClass('warning');
            $tooltipContainer.removeClass('warning');
        }
        tooltipContainer.classList.add(customClass);
        const clientRect = $targetEl[0].getBoundingClientRect();
        const { arrowTipClass, left, top, arrowLeft, arrowRight, arrowTop, arrowBottom } = getPosition({
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
            width: $targetEl.outerWidth(),
            height: $targetEl.outerHeight(),
            targetElPosition: {
                top: clientRect.top + (inIframe ? window.scrollY : 0),
                left: clientRect.left + (inIframe ? window.scrollX : 0),
            },
            customTooltipPosition,
            isWarningTitle,
        });

        $tooltipContainer.css({ left: left + 'px', top: top + 'px' });
        $arrowContainer.css({
            left: (arrowLeft || ''),
            right: (arrowRight || ''),
            top: (arrowTop || ''),
            bottom: (arrowBottom || '')
        });
        $tooltipContainer.addClass(arrowTipClass);

        $tooltipContainer.show();
    };
    const initializeTooltip = (e) => {
        if (!$targetEl || $targetEl.parents('body').toArray().length === 0) {
            return;
        }
        const titleText = $targetEl.attr(titleCustomAttr);
        if (!titleText) {
            return;
        }
        attachCustomTooltip(titleText);
        targetExistenceCheckLoop(e);
    };

    const registerTooltip = (element, selector?: string) => {
        $(element).on("mouseenter", selector || `[${titleCustomAttr}], [${errorTitleAttr}]`, function (e) {
            const that = this;
            $targetEl = $(that);
            const delay = $targetEl.attr(delayTitleAttr);
            clearTimeout(toolTipTimeOut);
            if (delay) {
                toolTipTimeOut = setTimeout(() => {
                    initializeTooltip(e);
                }, delay);
            } else {
                initializeTooltip(e);
            }
            $targetEl.on('mouseleave', hideAndCleanup);
            $targetEl.one('click', hideAndCleanup);
        });
    };
    const unregisterTooltip = (element, selector?: string) => {
        $(element).off('mouseenter', selector);
    };
    return {
        registerTooltip,
        unregisterTooltip
    };
});

export {
    CustomTooltip
};
