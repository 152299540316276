import { makeValueReducer } from "../../redux/makeReducer/index";
import {
    FONT_LOADING_FOR_PREVIEW_IN_PROGRESS,
    FONT_LOADING_FOR_PREVIEW_SUCCESS,
    FONT_LOADING_FOR_PREVIEW_FAILED
} from './actionTypes';

export default makeValueReducer({
    defaultState: '',
    handleActions: {
        [FONT_LOADING_FOR_PREVIEW_IN_PROGRESS]: () => FONT_LOADING_FOR_PREVIEW_IN_PROGRESS,
        [FONT_LOADING_FOR_PREVIEW_SUCCESS]: () => FONT_LOADING_FOR_PREVIEW_SUCCESS,
        [FONT_LOADING_FOR_PREVIEW_FAILED]: () => FONT_LOADING_FOR_PREVIEW_FAILED
    }
});
