import React from "react";
import { Msg } from "../../../view/intl/index";
import WarningDialog from "../../../view/common/dialogs/WarningDialog/index";
import { errorDialogStandardWidth, errorDialogStandardHeight } from "../../../view/common/dialogs/constants";
import getCenteredDialogConfig from "../../DialogManager/getCenteredDialogConfig";
import { LOCATION_RELOAD } from "../../../redux/middleware/window/actionTypes";

const
    UserDataMigrationFailedDialog = ({ dispatch }) => (
        <WarningDialog
            title="msg: userDataMigration.failed.title {Moving failed}"
            mctaText="msg: common.ok {OK}"
            mctaHandler={() => dispatch({ type: LOCATION_RELOAD })}
        >
            <Msg k="userDataMigration.failed.message">
                Moving your site to the new Website Builder failed
            </Msg>.
            <Msg k="common.tryAgainOrContactSupport">
                Please try again later or contact our support for further assistance.
            </Msg>
        </WarningDialog>
    );

export default getCenteredDialogConfig(errorDialogStandardWidth, errorDialogStandardHeight, UserDataMigrationFailedDialog); // eslint-disable-line max-len
