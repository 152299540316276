// @ts-nocheck
import * as React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { injectIntl, isMsgJointInput } from "../../intl/index";
import type { InputFieldPropTypes } from "./flowTypes";
import styles from './InputField.css';
import focusHandler from '../../../utils/inputFocusSelect';
import { INPUT_FOCUSED, INPUT_BLUR } from "../../../components/App/actionTypes";
import type { ReactElementRef } from "../../../globalTypes";
import { InputCounter } from "./counter/InputCounter";
import lengthInputValidation from "../../../components/inputControls/input/commonValidations/lengthInputValidation";
import { ENTER } from "../../../components/App/epics/isKeyPressed/keyboardKeys";

const
    BATCH_CHANGE_TIMEOUT = 100,
    InputCom = React.forwardRef(
        (
            {
                placeholder,
                appendContent,
                title,
                titleLanguageDependent,
                onFocus,
                inputRef,
                intl,
                style,
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                forwardedRef,
                ...props
            },
            ref,
        ) => {
            const inputHTML =
                <input
                    style={appendContent ? { ...style, paddingRight: '38px' } : style}
                    ref={inputRef || ref}
                    placeholder={placeholder && (isMsgJointInput(placeholder) ? intl.msgJoint(placeholder) : placeholder)}
                    data-title={titleLanguageDependent ? (title && intl.msgJoint(title)) : title}
                    onFocus={e => {
                        if (onFocus) {
                            onFocus(e);
                        }
                        focusHandler(e);
                    }}
                    {...props}
                />;

            return appendContent
                ? <span style={{ position: 'relative' }}>
                    {inputHTML}
                    <div className={styles.append}>{appendContent}</div>
                </span>
                : inputHTML;
        },
    ),
    Input = injectIntl(InputCom, { forwardRef: true });

type State = {
    value: string | number,
    isValid: boolean,
    isPasting: boolean,
};

class InputField<Props = InputFieldPropTypes> extends React.Component<Props, State> {
    static defaultProps = {
        highlightFocus: false
    };

    inputRef: ReactElementRef<any>;

    batchTimer: null | undefined | ReturnType<typeof setTimeout>;

    valueOnFocus: null | undefined | string | number;
    isFocused!: boolean;

    constructor(props: Props) {
        super(props);
        this.inputRef = React.createRef();
        const value = props.value !== null ? props.value : '';
        this.state = {
            value,
            isValid: this.validateValue(value),
            isPasting: false,
        };

        this.batchTimer = null;
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onPaste = this.onPaste.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (this.props !== nextProps && this.props.value !== nextProps.value) {
            const isValid = this.validateValue(nextProps.value);
            this.setState({
                value: nextProps.value,
                isValid
            });
        }
    }

    onFocus(e: React.FocusEvent<any>) {
        this.dispatch({ type: INPUT_FOCUSED });
        this.isFocused = true;
        const { onFocus, value } = this.props;

        this.valueOnFocus = value;

        if (onFocus) {
            onFocus({ v: this.parseValue(this.state.value), e });
        }
    }

    onBlur() {
        this.dispatch({ type: INPUT_BLUR });
        this.isFocused = false;

        const { onBlur, value, changedByUserOnBlur } = this.props;

        if (changedByUserOnBlur && this.valueOnFocus !== value) {
            let type = 'changed';

            if (!value) {
                type = 'deleted';
            } else if (!this.valueOnFocus) {
                type = 'added';
            }

            changedByUserOnBlur(type);
        }

        if (onBlur) {
            onBlur(this.parseValue(this.state.value));
        }
    }

    onPaste(e: Event) {
        this.setState({ isPasting: true });

        // NOTE: This is required since window also handles paste event
        e.stopPropagation();

        const { onAfterPaste, onPaste } = this.props;

        if (onPaste) {
            onPaste(e);
        }

        if (onAfterPaste) {
            window.setTimeout(() => {
                const value = this.parseValue(this.state.value);
                onAfterPaste(value);
                this.setState({ value });
            }, BATCH_CHANGE_TIMEOUT);
        }
    }

    getValue(): any {
        return this.state.value;
    }

    parseValue(value: any): any { // eslint-disable-line class-methods-use-this
        return value;
    }

    validateValue(value: any): boolean {
        const
            { validators, inputValidations, trimValueOnPaste } = this.props,
            isPasting = this.state && this.state.isPasting;

        if (validators) {
            return validators.every(({ validator }) => validator(value));
        }

        if (inputValidations) {
            return inputValidations.every(({ validation, options }) => {
                if (isPasting && trimValueOnPaste && validation === lengthInputValidation && options.max) {
                    return true;
                }

                return validation(value, options).isValid;
            });
        }

        return true;
    }

    onChange(e: Event) {
        const value = this.parseValue(e.target.value);
        this.updateValue(value, e.target);
    }

    updateValue(inValue: any, target: any) {
        const
            isValid = this.validateValue(inValue),
            isPasting = this.state.isPasting;

        let value = '';

        if (this.props.rejectInvalidValue && !isValid) {
            value = this.props.usePropsValue ? this.props.value : this.state.value;
        } else {
            value = inValue;
        }

        if (isPasting && this.props.trimValueOnPaste) {
            const lengthInputValidation = this.getLengthInputValidation();
            if (lengthInputValidation && lengthInputValidation.options && lengthInputValidation.options.max) {
                const maxLength = lengthInputValidation.options.max;
                value = `${value}`.substring(0, maxLength);
            }
        }

        this.setState({ isPasting: false });

        if (!this.props.usePropsValue) {
            this.setState({ value, isValid });
        }

        const { onChange, onBatchChange, batchChangeTimeout } = this.props;

        if (onChange) {
            onChange(value, isValid, target);
        }

        if (onBatchChange) {
            if (this.batchTimer) window.clearTimeout(this.batchTimer);

            this.batchTimer = setTimeout(() => {
                onBatchChange(value, isValid);
                this.batchTimer = null;
            }, batchChangeTimeout || BATCH_CHANGE_TIMEOUT);
        }
    }

    getLengthInputValidation() {
        const { inputValidations } = this.props;
        return inputValidations && inputValidations.find(({ validation }) => validation === lengthInputValidation);
    }

    onKeyPress(e: any) {
        if (e.key === ENTER && this.props.onEnterPress) this.props.onEnterPress(e);
    }

    onKeyDown(e: any): void {
        if (this.props.stopOnKeyDownEventPropagation) {
            e.stopPropagation();
        }

        const { onKeyDown } = this.props;
        if (onKeyDown) {
            onKeyDown(e.key, this.parseValue(this.state.value));
        }
    }

    dispatch(action: Record<string, any>) {
        if (this.context && this.context.store && this.context.store.dispatch) {
            this.context.store.dispatch(action);
        }
    }

    componentDidMount() {
        const { props, inputRef } = this;
        if (props.focusOnLoad && inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }

    componentWillUnmount() {
        if (this.isFocused) {
            this.dispatch({ type: INPUT_BLUR });
        }
    }

    render() {
        const
            {
                disabled,
                className = '',
                style = {},
                highlightFocus,
                name,
                placeholder,
                appendContent,
                title,
                titleLanguageDependent = true,
                autoFocus,
                maxLength,
                inputRef: propInputRef,
                id,
                counter,
                countBeyondMax = false,
                autocompleteValue,
                type = "text",
                tabIndex,
            } = this.props,
            inputRef = propInputRef || this.inputRef,
            { isValid, value } = this.state,
            // NOTE: The name "inputField" is used in the file "wbtgen/src/components/oneweb/Table/epics/tableEditMode/index.ts"
            // Name should be changed at both places together.
            finalClassName = cx(styles.inputField, className, {
                [styles.invalid]: this.props.isInvalid !== undefined ? this.props.isInvalid : !isValid,
                [styles.focus]: highlightFocus,
                [styles.disabled]: disabled
            });

        return (
            <React.Fragment>
                <Input
                    type={type}
                    className={finalClassName}
                    style={style}
                    placeholder={placeholder}
                    appendContent={appendContent}
                    value={value}
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onPaste={this.onPaste}
                    onKeyPress={this.onKeyPress}
                    onKeyDown={this.onKeyDown}
                    ref="input"
                    inputRef={inputRef}
                    name={name}
                    data-title={title}
                    titleLanguageDependent={titleLanguageDependent}
                    disabled={disabled}
                    autoFocus={autoFocus}
                    maxLength={maxLength}
                    id={id}
                    tabIndex={tabIndex}
                />
                {counter && <InputCounter {...counter} inputRef={inputRef} countBeyondMax={countBeyondMax} />}
                {
                    autocompleteValue && <span className={styles.autocompleteText} style={style}>
                        <span style={{ visibility: 'hidden' }}>
                            {autocompleteValue.substr(0, value.toString().length)}
                        </span>
                        {autocompleteValue.substr(value.toString().length)}
                    </span>
                }
            </React.Fragment>
        );
    }
}

InputField.contextTypes = { store: PropTypes.object }; // eslint-disable-line react/forbid-prop-types

export default InputField;

export {
    InputField
};

