import React from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';

import { useIntl } from '../../../../../../../wbtgen/src/view/intl';

import { getScoreTrend, SCORE_TRENDS } from "../Report/utils";
import { marketgooPreferencesSelector } from "../../../../common/selectors";

import imageStyles from "../../../Images/imageStyle.css";
import { RenderWhen } from "../../../Helpers/RenderWhen";

const useStyles = makeStyles(
    {
        statusText: {
            fontSize: "12px",
            lineHeight: "12px",
        },
    },
    { name: "SeoScoreProgressStatus" }
);

type SeoScoreStatusProps = {
    isLoading?: boolean;
};

export const SeoScoreStatus = ({ isLoading }: SeoScoreStatusProps) => {
    const intl = useIntl();
    const classes = useStyles();

    const marketgooPreferences = useSelector(marketgooPreferencesSelector);
    const scoreChange = getScoreTrend(marketgooPreferences);

    if (isLoading || !scoreChange) {
        // to prevent ui content shifting
        return <Box height="26px" />;
    }

    return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ gap: "2px" }}>
            <Box display="flex" justifyContent="center" alignItems="center" style={{ gap: "2px" }}>
                <RenderWhen when={scoreChange === SCORE_TRENDS.DECREASED}>
                    <Box width="12px" height="12px" className={imageStyles.arrowDownOrange} />
                    <Typography className={classes.statusText}>
                        {intl.msgJoint("msg: common.decreased {Decreased}")}
                    </Typography>
                </RenderWhen>

                <RenderWhen when={scoreChange === SCORE_TRENDS.UNCHANGED}>
                    <Box width="12px" height="12px" className={imageStyles.arrowForwardBlue} />
                    <Typography className={classes.statusText}>
                        {intl.msgJoint("msg: common.unchanged {Unchanged}")}
                    </Typography>
                </RenderWhen>

                <RenderWhen when={scoreChange === SCORE_TRENDS.INCREASED}>
                    <Box width="12px" height="12px" className={imageStyles.arrowUpGreen} />
                    <Typography className={classes.statusText}>
                        {intl.msgJoint("msg: common.improved {Improved}")}
                    </Typography>
                </RenderWhen>
            </Box>

            <Typography className={classes.statusText}>
                {intl.msgJoint("msg: common.sinceLastScan {since last scan}")}
            </Typography>
        </Box>
    );
};
