import React from "react";
export const SvgPinterestCircleFillLight = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <circle cx={24} cy={24} r={24} fill="#ffffff" />
            <path
                fill="#3c3c3c"
                d="M24.01 12.5c-6.349 0-11.51 5.161-11.51 11.51 0 6.347 5.161 11.508 11.51 11.508 6.347 0 11.508-5.16 11.508-11.509 0-6.348-5.16-11.509-11.509-11.509zm0 21.48c-1.03 0-2.02-.157-2.955-.444.405-.661 1.016-1.75 1.241-2.612l.62-2.37c.324.62 1.278 1.147 2.285 1.147 3.007 0 5.174-2.765 5.174-6.204 0-3.296-2.688-5.76-6.146-5.76-4.302 0-6.586 2.891-6.586 6.034 0 1.461.778 3.282 2.023 3.862.19.09.288.05.333-.135.031-.14.202-.814.279-1.133a.307.307 0 00-.068-.288c-.413-.499-.742-1.42-.742-2.275 0-2.198 1.664-4.33 4.5-4.33 2.45 0 4.164 1.669 4.164 4.056 0 2.698-1.362 4.568-3.134 4.568-.98 0-1.713-.81-1.474-1.803.283-1.187.827-2.464.827-3.318 0-.764-.41-1.403-1.263-1.403-1.003 0-1.803 1.034-1.803 2.424 0 .88.296 1.479.296 1.479s-.989 4.176-1.168 4.954c-.203.863-.122 2.073-.036 2.86a9.96 9.96 0 01-6.34-9.28c0-5.507 4.465-9.971 9.972-9.971s9.972 4.464 9.972 9.971-4.465 9.972-9.972 9.972z"
            />
        </g>
    </svg>
);
