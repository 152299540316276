export const UnsplashCategoryMessages = {
    "-1": "msg: fm.unsplash.category.all {All}",
    "Mr49Evh5Sks": "msg: fm.unsplash.category.greenerCities {Greener Cities}",
    "bo8jQKTaE0Y": "msg: fm.unsplash.category.wallpapers {Wallpapers}",
    "CDwuwXJAbEw": "msg: fm.unsplash.category.3dRenders {3D renders}",
    "iUIsnVtjB0Y": "msg: fm.unsplash.category.texturesAndPatterns {Textures & patterns}",
    "rnSKDHwwYUk": "msg: fm.unsplash.category.architecture {Architecture}",
    "BJJMtteDJA4": "msg: fm.unsplash.category.currentEvents {Current events}",
    "qPYsDzvJOYc": "msg: fm.unsplash.category.experimental {Experimental}",
    "6sMVjTLSkeQ": "msg: fm.unsplash.category.nature {Nature}",
    "S4MKLAsBB74": "msg: fm.unsplash.category.fashion {Fashion}",
    "hmenvQhUmxM": "msg: fm.unsplash.category.film {Film}",
    "xjPR4hlkBGA": "msg: fm.unsplash.category.foodAndDrink {Food & drink}",
    "_hb-dl4Q-4U": "msg: fm.unsplash.category.healthAndWellness {Health & wellness}",
    "towJZFskpGg": "msg: fm.unsplash.category.people {People}",
    "R_Fyn-Gwtlw": "msg: fm.unsplash.category.interiors {Interiors}",
    "xHxYTMHLgOc": "msg: fm.unsplash.category.streetPhotography {Street photography}",
    "Fzo3zuOHN6w": "msg: fm.unsplash.category.travel {Travel}",
    "Jpg6Kidl-Hk": "msg: fm.unsplash.category.animals {Animals}",
    "_8zFHuhRhyo": "msg: fm.unsplash.category.spirituality {Spirituality}",
    "bDo48cUhwnY": "msg: fm.unsplash.category.artsAndCulture {Arts & culture}",
    "dijpbw99kQQ": "msg: fm.unsplash.category.history {History}",
    "aeu6rL-j6ew": "msg: fm.unsplash.category.businessAndWork {Business & work}",
    "Bn-DjrcBrwo": "msg: fm.unsplash.category.athletics {Athletics}",
    "c7USHrQ0Ljw": "msg: fm.unsplash.category.covid19 {COVID-19}",
    "M8jVbLbTRws": "msg: fm.unsplash.category.architectureAndInterior {Architecture & interior}"
};
