import type { FetchResponse } from "./types";

type Body = Record<string, any> | string;

type Options = {
    headers?: Record<string, any>,
    statusText?: string,
};

export class FetchError extends Error {
    constructor(status: number, body: Body, options: Options = {}) {
        const message = makeMessage(status, body, options);

        super(message);

        this.name = this.constructor.name;

        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = (new Error(message)).stack;
        }

        this.status = status;
        this.body = body;
        // @ts-ignore
        this.headers = options.headers;
    }

    static fromResponse(res: FetchResponse)/*: FetchError */ {
        return new FetchError(res.status, res.bodyData, {
            headers: res.headers,
            statusText: res.statusText,
        });
    }

    status: number;
    body: Body;
    headers: Record<string, any>;
}

/*** Private ***/

function makeMessage(stats, body, options) {
    let msg = `${stats}`;

    if (options.statusText) {
        msg += ` ${options.statusText}`;
    }

    if (typeof body === 'string') {
        msg += ` ${body}`;
    }

    return msg;
}
