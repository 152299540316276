import { assocPath } from 'ramda';
import {
    makeCombineReducer,
    makeDefaultStateReducers,
    makeComponentBaseReducers
} from "../../../../redux/makeReducer/index";
import handleScaleChange from '../propertyChangeHandler/scale';
import { rotateRight, rotateLeft } from '../propertyChangeHandler/rotate';
import handleScaleStrategy from '../propertyChangeHandler/scaleStrategy';
import updateOnReplaceImage from '../propertyChangeHandler/updateOnReplaceImage';
import updateOnReplaceMissingAsset from '../propertyChangeHandler/updateOnReplaceMissingAsset';
import * as ActionTypes from '../actionTypes';
import kind from '../kind';
import {
    TOGGLE_MOBILE_DOWN_ACTION
} from "../../../PropertiesPanel/view/MobileView/actions";
import mobileDownReducer from "../../../PropertiesPanel/view/MobileView/mobileDownReducer";
import * as componentReducers from "../../componentReducers";
import type { ImageComponent } from '../flowTypes';
import {
    PROP_PANEL_CHANGE_COMPONENT_HEIGHT,
    PROP_PANEL_CHANGE_COMPONENT_WIDTH
} from '../../../Workspace/actionTypes';
import { heightChanged, widthChanged } from '../propertyChangeHandler/sizeChange';
import setBorderWidth from '../propertyChangeHandler/borderWidth';
import onClickActionChanged from '../propertyChangeHandler/onClickAction';
import setLink from '../propertyChangeHandler/setLink';
import borderStyle from '../propertyChangeHandler/borderStyle';
import emptyImageSrc from "../propertyChangeHandler/emptyImageSrc";
import { COMPONENTS_MAP_REPLACE_MISSING_ASSETS } from "../../../Workspace/epics/componentsEval/actionTypes";
import { MCTA_RESIZE_OPTIONS } from "../../../componentMainActions/constants";

export const
    DEFAULT_WIDTH = 300,
    DEFAULT_HEIGHT = 200;

type ImageReducerFn = (c: ImageComponent, action: Action) => ImageComponent;
type ImageCombineReducer = {
    combineReducers: ImageComponent,
    handleActions: Record<string, ImageReducerFn>
};

const imageReducer: ImageCombineReducer = {
    combineReducers: {
        ...makeComponentBaseReducers(kind, DEFAULT_WIDTH, DEFAULT_HEIGHT),
        ...makeDefaultStateReducers({
            title: "",
            scale: 1,
            logoTitleScale: 1,
            rotation: 0,
            scaleStrategy: 'crop',
            logoHorizontalAlignment: 'left',
            cropTop: 0,
            cropLeft: 0,
            mobileHide: false,
            mobileDown: false,

            asset: {
                id: '',
                height: 1,
                width: 1,
                etag: '',
                url: ''
            },

            linkAction: null,
            style: null,
            lightBoxEnabled: false,
            openLink: false
        })
    },
    handleActions: {
        [ActionTypes.IMAGE_PROP_PANEL_SCALE_STRATEGY]: handleScaleStrategy,
        [ActionTypes.IMAGE_PROP_PANEL_SCALE_CHANGE]: handleScaleChange('scale'),
        [ActionTypes.LOGO_TITLE_SCALE_CHANGED]: handleScaleChange('logoTitleScale'),
        [ActionTypes.IMAGE_PROP_PANEL_ROTATE_RIGHT]: rotateRight,
        [ActionTypes.IMAGE_PROP_PANEL_ROTATE_LEFT]: rotateLeft,
        [ActionTypes.IMAGE_PROP_PANEL_ALT_TEXT]: setProperty('title'),
        [ActionTypes.IMAGE_PROP_PANEL_BORDER_CORNER]: componentReducers.makeComponentStyleBorderCornersReducer(),
        [ActionTypes.IMAGE_PROP_PANEL_BORDER_WIDTH]: setBorderWidth,
        [ActionTypes.IMAGE_PROP_PANEL_BORDER_OPACITY]: componentReducers.makeComponentStyleBorderOpacityReducer(),
        [ActionTypes.IMAGE_PROP_PANEL_BORDER_TYPE]: borderStyle,
        [ActionTypes.IMAGE_PROP_PANEL_BORDER_COLOR]: componentReducers.makeComponentStyleBorderColorReducer(),
        [ActionTypes.IMAGE_PROP_PANEL_BORDER_COLOR_AUTO_COLOR]: (component: ImageComponent, { payload }) => (
            assocPath(['style', 'border', 'themeColor'], payload.themeColor, component)
        ),
        [ActionTypes.IMAGE_PROP_PANEL_ON_CLICK_ACTION_CHANGED]: onClickActionChanged,
        [TOGGLE_MOBILE_DOWN_ACTION]: mobileDownReducer,
        [ActionTypes.IMAGE_SET_LINK]: setLink,
        [ActionTypes.IMAGE_REMOVE_LINK]: removeProperty('linkAction'),
        [ActionTypes.IMAGE_UPDATE_ON_REPLACE]: updateOnReplaceImage,
        [ActionTypes.IMAGE_EMPTY_SRC]: emptyImageSrc,
        [PROP_PANEL_CHANGE_COMPONENT_WIDTH]: widthChanged,
        [PROP_PANEL_CHANGE_COMPONENT_HEIGHT]: heightChanged,
        [COMPONENTS_MAP_REPLACE_MISSING_ASSETS]: updateOnReplaceMissingAsset,
        [ActionTypes.IMAGE_MCTA_HORIZONTAL_ALIGNMENT_CHANGED]: (state, action) =>
            ({ ...state, logoHorizontalAlignment: action.payload }),
        [ActionTypes.LOGO_MCTA_RESIZE_CHANGED]: (state, action) => {
            let logoSize = action.payload;
            let width = MCTA_RESIZE_OPTIONS[logoSize].width;
            let height = MCTA_RESIZE_OPTIONS[logoSize].height;

            return { ...state, logoSize, width, height };
        }
    }
};
export default makeCombineReducer(imageReducer);

export function setProperty(propName: string) {
    return (component: ImageComponent, { payload }: Action<any>) => ({ ...component, [propName]: payload });
}

export function setPayloadProperty(propName: string) {
    return (component: ImageComponent, { payload }: Action<any>) => ({ ...component, [propName]: payload[propName] });
}

export function removeProperty(propName: string) {
    return (component: ImageComponent) => {
        let changedComponent = { ...component };
        delete changedComponent[propName];
        return changedComponent;
    };
}
