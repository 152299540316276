import * as React from 'react';
import styles from './Shutterstock.css';
import type { SstockImage } from '../../../../../dal/flowTypes';
import { HoverBoxCom } from '../../../presentational/HoverBox/index';
import { ShutterstockImagePreview } from "./ShutterstockImagePreview";
import { SstockImageModel } from "../../../../../dal/model/SstockImageModel";
import type { ShutterstockKindT } from "../types";
import { ThirdPartyImageKind } from "../../unsplash/components/constants";
import { ShutterstockKind } from '../constants';

type Props = {
    kind: ShutterstockKindT,
    image: SstockImage,
    selected: boolean,
    locked: boolean,
    onImageClick: (...args: Array<any>) => any,
    isDemo: boolean,
    children: React.ReactElement<any>,
};

export class ShutterstockImage extends React.Component<Props> {
    render() {
        const
            { kind, image, locked, selected, onImageClick, isDemo, children } = this.props,
            model = new SstockImageModel(image),
            bgSrc = model.getThumb().url,
            showLock = locked || (isDemo && ![ThirdPartyImageKind.UNSPLASH, ShutterstockKind.FREE].includes(kind)),
            userProfileImageUrl = model.getUserProfileImageUrl();

        return (
            <div className={styles.freeOneComImageContainer}>
                <HoverBoxCom
                    bgSrc={bgSrc}
                    theme={{
                        box: styles.image,
                        bg: styles.imageBg
                    }}
                    selected={selected}
                    onClick={onImageClick}
                >
                    <ShutterstockImagePreview image={image} />
                    {children && React.cloneElement(children, { id: image.id })}
                    {userProfileImageUrl && <div className={styles.imageUserDetails}>
                        <span><img className={styles.userProfileImage} src={userProfileImageUrl} /></span>
                        <span className={styles.userProfileName}>{model.getUserName()}</span>
                    </div>}
                </HoverBoxCom>
                {selected && <div className={styles.imageSelectedCornerTick} onClick={onImageClick} />}
                {showLock && <div className={styles.locked} />}
            </div>
        );
    }
}
