import React from 'react';
import { Dispatch } from "redux";
import { red260 } from "./HoverBoxModes/HoverBoxModes.css";
import { Border } from "../../../../components/presentational/Border/Border";
import { makeBorderProps } from "../makeBorderProps";
import { HoverBoxOptions } from "./HoverBoxModes/HoverBoxModes";
import { selectHoverBoxAndSetState } from "./HoverBoxModes/actions";
import type { HoverBoxDecoration as HoverDecorationProps } from "./flowTypes";

type Props = {
    dispatch: Dispatch,
    hoverBoxDecorations: Array<HoverDecorationProps>
}

const HoverBoxDecoration = ({ dispatch, hoverBoxDecorations }: Props) => {
    return hoverBoxDecorations.map((decoration: HoverDecorationProps) => {
        if (!decoration) {
            return null;
        }
        const { component, bBox, isHoverBox } = decoration,
            { id: hoverBoxId } = component,
            borderProps = makeBorderProps({ bBox, color: red260 });
        let props: any = {
            component,
            dispatch
        };
        props = {
            ...props,
            onHoverModeClicked: () => dispatch(selectHoverBoxAndSetState(hoverBoxId, true)),
            onDefaultModeClicked: () => dispatch(selectHoverBoxAndSetState(hoverBoxId, false)),
        };

        return [
            <HoverBoxOptions {...props} key={`${component.id}_options`} />,
            isHoverBox ? null : <Border {...borderProps} key={`${component.id}_border`} />
        ];
    });
};

export {
    HoverBoxDecoration
};
