import { WidgetsFacebookKind } from './kind';
import { calcRenderProps } from './calcRenderProps';
import { FacebookView } from './view/index';

const WidgetsFacebookPreviewConfig = {
    kind: WidgetsFacebookKind,
    view: FacebookView,
    calcProps: ({ component }) => calcRenderProps({
        component,
        componentExtension: { isTransient: false },
    }),
};

export { WidgetsFacebookPreviewConfig };
