import React from "react";
import { DesktopPreview } from "../../../Preview/View/Desktop/index";
import { getOrientationStyles, MobilePreviewContainer } from "../../../Preview/View/Mobile/index";
import * as Actions from "../../actionTypes";
import { MobileViewOrientations } from "../../../Preview/View/Mobile/constants";
import { ViewTypes } from "../../constants";
import * as styles from './Preview.css';

// Do not include this DesktopPreview import in Preview/index, this is leading to circular dependency

class MobilePreview extends DesktopPreview {
    render() {
        const { viewType, dispatch, showPremium, onScroll, hidePremiumBar } = this.props,
            orientation = (viewType === ViewTypes.mobileHorizontal)
                ? MobileViewOrientations.LANDSCAPE
                : MobileViewOrientations.PORTRAIT,
            { orientationClassName, contentProps } = getOrientationStyles(orientation),
            finalProps = {
                allowFullScreen: true,
                ...contentProps,
                frameBorder: "0",
                type: "text/html"
            },
            Contents = <iframe ref={this.setIframeRef} {...finalProps} />,
            props = {
                Contents,
                orientation,
                onClickOrientation: () => dispatch({ type: Actions.TEMPLATE_SELECTOR_MOBILE_ORIENTATION_TOGGLE }),
                orientationClassName,
                onScroll
            };

        return <MobilePreviewContainer
            {...props}
            extraContainerClassName={{
                [styles.mobilePreviewPremium]: showPremium,
                [styles.mobilePreviewPremiumHide]: hidePremiumBar
            }}
        />;
    }
}

export default MobilePreview;
