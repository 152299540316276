
import * as React from "react";
import cx from 'classnames';
import Image from '../../Image/view/Image';
import type { InstagramGalleryImageRenderProps, Props } from '../flowTypes';
import * as styles from '../InstagramGallery.css';
import * as imageStyles from '../../Image/view/Image.css';
import { Msg } from "../../../../view/intl/index";
import textNormalClassName from '../../Text/globalStyle/textNormalClassName';
import { replaceTagsWithContent } from '../../Text/view/replaceTagsWithContent';
import { LoadingIndicator } from "../../../../view/common/LoadingIndicator/index";
import { buttonViewFactory } from "../../Button/view/index";
import buttonStyles from '../../Button/view/Button.css';
import { INSTAGRAM_CAROUSEL_ALBUM, INSTAGRAM_VIDEO } from "../constants/index";
import { SAVE } from "../../../App/actionTypes";
import { INSTAGRAM_GALLERY_PP_SHOW_MORE_BTN_ENABLED } from "../actionTypes";

// used to apply script in preview and publish
const
    staticInstagramGalleryClassName = 'instagram-gallery',
    staticInstagramGalleryCellToPopulate = 'instagram-gallery-cell-to-populate',
    staticInstagramGalleryPublished = 'instagram-gallery-container',
    staticInstagramGalleryCellClassName = 'instagram-gallery-cell',
    staticInstagramGalleryImageContainerClassName = 'img-container',
    staticInstagramCellToShowOnShowMore = 'instagram-gallery-cell-on-show-more',
    staticInstagramGalleryShowMoreBtn = 'instagram-gallery-show-more-btn',
    staticInstagramGalleryVideoIconClassName = 'video-type-post',
    staticInstagramGalleryCarouselIconClassName = 'carousel-type-post';

class InstagramGallery extends React.PureComponent<Props> {
    // @ts-ignore TODO: fix TS
    getImageEl({ divStyle = {}, imageAvailable, img, mediaType }: InstagramGalleryImageRenderProps) {
        const
            { isWorkspace, isServerPreview } = this.props;
        const postIconHeightWidth = Math.min(divStyle.height * 0.1, divStyle.width * 0.1);
        return (
            <div
                className={cx(
                    staticInstagramGalleryImageContainerClassName,
                )}
                style={{ height: divStyle.height, width: divStyle.width, position: "relative", float: "left" }}
            >
                <Image
                    imageAvailable={imageAvailable}
                    divStyle={divStyle}
                    divClassName={cx(
                        imageStyles.imageComponent,
                        styles.image,
                    )}
                    imageAttributes={{ ...img, role: "presentation" }}
                    width={img.style.width}
                    height={img.style.height}
                    isWorkspace={isWorkspace}
                />
                <div
                    className={cx(
                        styles.instagramPostIconContainer,
                    )}
                >
                    {(mediaType === INSTAGRAM_VIDEO || isServerPreview) &&
                    <div
                        className={cx(
                            styles.instagramVideoIcon,
                            staticInstagramGalleryVideoIconClassName,
                        )}
                        style={{
                            height: postIconHeightWidth,
                            width: postIconHeightWidth,
                            ...(isServerPreview && { display: 'none' }),
                        }}
                    />}
                    {(mediaType === INSTAGRAM_CAROUSEL_ALBUM || isServerPreview) &&
                    <div
                        className={cx(
                            styles.instagramCarouselIcon,
                            staticInstagramGalleryCarouselIconClassName,
                        )}
                        style={{
                            height: postIconHeightWidth,
                            width: postIconHeightWidth,
                            ...(isServerPreview && { display: 'none' }),
                        }}
                    />}
                </div>
            </div>
        );
    }

    getImageCells() {
        const
            {
                images,
                globalVariables,
                isServerPreview,
                isWorkspace,
                columns,
                rows,
            } = this.props,
            maxVisibleImagesBeforeShowMore = columns * rows,
            imageCells: React.ReactElement<any>[] = images.map((image: InstagramGalleryImageRenderProps, index) => {
                const isCellToBeShownAfterShowMoreBtnClick = (index + 1) > maxVisibleImagesBeforeShowMore;
                if (isWorkspace && isCellToBeShownAfterShowMoreBtnClick) {
                    return '';
                }
                const anchorProps = image.a;
                if (anchorProps && anchorProps.href) {
                    anchorProps.href = replaceTagsWithContent(anchorProps.href, { globalVariables }, this.props.isWorkspace);
                }
                return (
                    <div
                        key={index}
                        data-index={index}
                        className={cx(
                            styles.instagramGalleryCell,
                            staticInstagramGalleryCellClassName,
                            {
                                [staticInstagramGalleryCellToPopulate]: isServerPreview,
                                [staticInstagramCellToShowOnShowMore]: isCellToBeShownAfterShowMoreBtnClick,
                            }
                        )}
                        {...image.container}
                        style={{
                            ...image.container.style,
                            ...(isServerPreview && { display: 'none' }),
                            ...(isCellToBeShownAfterShowMoreBtnClick && { display: 'none' }),
                        }}
                    >
                        <a {...anchorProps}>
                            {this.getImageEl(image)}
                        </a>
                    </div>
                );
            });

        return imageCells;
    }

    getMobileStyles() {
        const { mobileSettings, componentId: id } = this.props;
        if (mobileSettings) {
            let
                { columns, spacingPx } = mobileSettings;
            if (columns === 1) {
                return `
                .mobileV div[data-id='${id}'] .instagram-gallery .instagram-gallery-cell {
                    padding: 0 0 ${spacingPx}px 0 !important;
                    width: 100% !important;
                }
                .mobileV div[data-id='${id}'] .instagram-gallery .instagram-gallery-cell:last-child {
                    padding-bottom: 0 !important;
                }`;
            }
            spacingPx = spacingPx / columns; //spacing should be shared between columns
            return `
                .mobileV div[data-id='${id}'] .instagram-gallery .instagram-gallery-cell:nth-child(2n+1) {
                    padding: ${spacingPx}px ${spacingPx}px ${spacingPx}px 0 !important;
                }
                .mobileV div[data-id='${id}'] .instagram-gallery .instagram-gallery-cell:nth-child(2n+2) {
                    padding: ${spacingPx}px 0 ${spacingPx}px ${spacingPx}px !important;
                }
                .mobileV div[data-id='${id}'] .instagram-gallery .instagram-gallery-cell:first-child {
                    padding: 0 ${spacingPx}px ${spacingPx}px 0 !important;
                }
                .mobileV div[data-id='${id}'] .instagram-gallery .instagram-gallery-cell:nth-child(2) {
                    padding: 0 0 ${spacingPx}px ${spacingPx}px !important;
                }
                .mobileV div[data-id='${id}'] .instagram-gallery {
                    margin-bottom: 0px !important;
                }`;
        }
        return '';
    }

    render() {
        const
            {
                captionsEnabled,
                isWorkspace,
                measureRef,
                isSocialAccountsInstagramAssetsLoading,
                isServerPreview,
                showMoreBtn,
                globalStyleClass,
                fontSizePx,
                images,
                showMoreTxt,
                dispatch,
                mobileSettings = {}
            } = this.props,
            {
                columns: mobileColumns = 0,
                spacingPx: mobileSpacing = 0
            } = mobileSettings,
            imagesCells = this.getImageCells(),
            zeroImages = imagesCells.length === 0,
            cn = cx(
                staticInstagramGalleryClassName,
                styles.instagramGallery,
                textNormalClassName,
                {
                    [styles.zeroImages]: zeroImages,
                    [staticInstagramGalleryPublished]: isServerPreview,
                }
            );

        // Fix show more button txt state which do not have any value set.
        if (dispatch && !showMoreTxt && showMoreBtn) {
            dispatch({
                type: INSTAGRAM_GALLERY_PP_SHOW_MORE_BTN_ENABLED,
                payload: {
                    isEnabled: showMoreBtn,
                }
            });
            dispatch({
                type: SAVE
            });
        }

        if (zeroImages && !isWorkspace) {
            return <div />;
        }

        if (isSocialAccountsInstagramAssetsLoading) {
            return <div className={styles.loadingIcon}><LoadingIndicator /></div>;
        }

        const ButtonView = buttonViewFactory({ style: { fontSize: fontSizePx, minWidth: 130, minHeight: 50 } });
        const autoColorModeProps = (
            this.props.themeSettingsData.autoColorMode
                ? {
                    autoColorMode: true,
                    selectedParentTheme: this.props.selectedParentTheme,
                    buttonThemeSelected: this.props.buttonThemeSelected,
                }
                : {}
        );

        return ([
            <style key={0} type="text/css" dangerouslySetInnerHTML={{ __html: this.getMobileStyles() }} />,
            <div
                key={1}
                className={cn}
                ref={measureRef}
                data-captionenabled={!!captionsEnabled}
                data-mobile-columns={mobileColumns}
                data-mobile-spacing={mobileSpacing}
            >
                {
                    zeroImages ?
                        <Msg k="component.instagramGallery.noImagesAvailable">No images available</Msg> : imagesCells
                }
                {
                    showMoreBtn && images.length > 0 &&
                        <div
                            className={cx(
                                styles.buttonPreview,
                                staticInstagramGalleryShowMoreBtn,
                            )}
                            style={{
                                ...(isServerPreview && { display: 'none' })
                            }}
                        >
                            <ButtonView
                                text={showMoreTxt || 'Show more'}
                                styles={buttonStyles}
                                globalStyleClass={globalStyleClass}
                                {...autoColorModeProps}
                            />
                        </div>
                }
            </div>
        ]);
    }
}

export default InstagramGallery;
