import { getCookie, unsetCookie, setCookie } from "../../../services/cookie";
import {
    COOKIE_RESULT,
    DOMAIN_FROM_AUTH_COOKIE_VALUE,
    GET_COOKIE,
    GET_DOMAIN_FROM_AUTH_COOKIE,
    GET_EMAIL_FROM_AUTH_COOKIE,
    EMAIL_FROM_AUTH_COOKIE_VALUE,
    UNSET_COOKIE,
    SET_COOKIE
} from "./actionTypes";
import { joinDisaptchResults } from "../joinDispatchResults";
import getLogin from "../../../components/App/epics/auth/utils/getLogin";
import { AUTH_COOKIE_KEY } from "../../../components/App/epics/auth/constants";

export default (store: Store) => (next: Dispatch) => (action: Action) => {
    if (action.type === GET_COOKIE) {
        const { callerId, cookieKey } = action.payload;

        if (cookieKey === AUTH_COOKIE_KEY) {
            throw new Error(`${AUTH_COOKIE_KEY} should never be requested directly, use GET_DOMAIN_FROM_AUTH_COOKIE or GET_EMAIL_FROM_AUTH_COOKIE.`); // eslint-disable-line
        }

        return joinDisaptchResults(
            store.dispatch({ type: COOKIE_RESULT, payload: { callerId, cookieKey, value: getCookie(cookieKey) } }),
            next(action)
        );
    }
    if (action.type === UNSET_COOKIE) {
        const { key, useTld = false } = action.payload;
        unsetCookie(key, null, !useTld);
    }

    if (action.type === SET_COOKIE) {
        const { key, value, options } = action.payload;
        setCookie(key, value, options);
    }

    if (
        action.type === GET_DOMAIN_FROM_AUTH_COOKIE
        || action.type === GET_EMAIL_FROM_AUTH_COOKIE
    ) {
        let
            domain: string | null = null,
            email: string | null | undefined = null;

        const authCookie = getCookie(AUTH_COOKIE_KEY);

        if (authCookie) {
            const login = getLogin(authCookie);
            if (login) {
                domain = login.domain;
                email = login.email;
            }
        }
        if (action.type === GET_DOMAIN_FROM_AUTH_COOKIE) {
            store.dispatch({ type: DOMAIN_FROM_AUTH_COOKIE_VALUE, payload: domain });
        }
        if (action.type === GET_EMAIL_FROM_AUTH_COOKIE) {
            store.dispatch({ type: EMAIL_FROM_AUTH_COOKIE_VALUE, payload: email });
        }
    }

    return next(action);
};
