import React from "react";
import Box from "@mui/material/Box";
import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(
    (theme) => ({
        container: {
            border: `1px solid ${theme.palette.custom.colorGray_ed}`,
            padding: "30px"
        },
        rightDescription: {
            height: "8px",
            width: "20%",
            float: "right"
        },
        title: {
            marginTop: "24px",
            marginBottom: "8px",
            borderRadius: "4px"
        },
        description: {
            marginTop: "4px",
            borderRadius: "4px"
        },
        action: {
            marginTop: "21px",
            borderRadius: "4px"
        }
    }),
    { name: "GoogleAdsLoader" }
);

export const GoogleAdsLoader = () => {
    const classes = useStyles();
    return (
        <Box className={classes.container}>
            <Skeleton
                variant="rectangular"
                height={18}
                className={classes.rightDescription}
            />
            <Skeleton
                variant="text"
                height={42}
                className={classes.title}
            />
            <Skeleton
                variant="text"
                height={24}
                className={classes.description}
            />
            <Skeleton
                variant="text"
                height={24}
                className={classes.description}
                width="60%"
            />
            <Skeleton
                variant="rectangular"
                width={110}
                height={36}
                className={classes.action}
            />
        </Box>
    );
};
