import React from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Hidden } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import cx from "classnames";

import { useIntl } from '../../../../../../../wbtgen/src/view/intl';

import { openShopCreateFirstProductAction } from "../../OnlineShop/actions";

import imageStyles from "../../../Images/imageStyle.css";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        gap: "18px",
        padding: "24px",
        backgroundColor: theme.palette.custom.colorWhite,
        [theme.breakpoints.up("sm")]: {
            gap: "48px",
            padding: "0px",
            flexDirection: "row",
            alignItems: "center"
        },
    },
    title: {
        fontSize: "22px",
        lineHeight: "30px",
        [theme.breakpoints.up("sm")]: {
            fontSize: "24px",
        },
    },
    description: {
        fontSize: "14px",
        lineHeight: "22px",
        marginTop: "8px",
        [theme.breakpoints.up("sm")]: {
            marginTop: "15px",
        },
    },
    productBtn: {
        marginTop: "26px",
        padding: "10px 30px",
        [theme.breakpoints.only("xs")]: {
            marginTop: "16px",
            width: "100%"
        },
    },
    shopIcon: {
        width: "48px",
        height: "48px",
    }
}),
{ name: "OnlineShopOnboarding" });

export const OnlineShopOnboarding = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const classes = useStyles();

    return (
        <Box className={classes.root} data-testid="online-shop-onboarding">
            <Hidden smUp>
                <div className={cx(imageStyles.onlineShopSmall, classes.shopIcon)} />
            </Hidden>
            <Box>
                <Box className={classes.title}>
                    {intl.msgJoint("msg: onlineShop.sellOnline.title {Start selling online now!}")}
                </Box>
                <Box className={classes.description}>
                    {intl.msgJoint("msg: onlineShop.sellOnline.description {Do you know that our Online Shop is included in your subscription? Simply create your first product and start selling online today. Make use of great features and integrations like Stripe, Shipmondo, Google listings and more.}")}
                </Box>
                <Button
                    aria-label="Create your first product"
                    variant="outlined"
                    color="primary"
                    className={classes.productBtn}
                    onClick={() => dispatch(openShopCreateFirstProductAction())}
                    data-testid="product-btn"
                >
                    {intl.msgJoint("msg: onlineShop.sellOnline.productBtn {Create your first product}")}
                </Button>
            </Box>
            <Hidden smDown>
                <div className={imageStyles.onlineShopOnboarding} />
            </Hidden>
        </Box>
    );
};
