import * as React from 'react';
import { Dialog, StripTypes, DialogTitleBox, DialogBody, DialogFooter } from '../baseDialog/index';
import styles from '../WarningDialog/WarningDialog.css';
import { closeDialog } from "../../../../components/App/actionCreators/index";
import { injectIntl, isMsgJointInput } from "../../../intl/index";
import type { MsgJointInput } from "../../../intl/index";

type Props = {
    title: MsgJointInput;
    confirmLabel?: string;
    onConfirm: SimpleFunction;
    dispatch: Dispatch;
    intl: Intl;
    cancelLabel?: string;
    onCancel?: SimpleFunction;
    onClose?: SimpleFunction;
    children?: React.ReactNode | MsgJointInput;
    bodyClassName?: string,
};

export const WarningConfirmationDialog = injectIntl(
    (props: Props) => {
        const close = () => props.dispatch(closeDialog());
        const
            {
                title,
                confirmLabel = 'msg: common.ok {OK}',
                onConfirm,
                cancelLabel = 'msg: common.cancel {Cancel}',
                onCancel = close,
                onClose = close,
                children: propChildren,
                bodyClassName,
                intl,
            } = props,
            children = isMsgJointInput(propChildren) ? intl.msgJoint(propChildren) : propChildren;

        return (
            <Dialog
                stripType={StripTypes.WARNING}
                className={styles.dialog}
                onClose={onClose}
            >
                <DialogTitleBox title={title} iconClass={styles.iconWarning} className={styles.warningDialogTitle} />
                <DialogBody className={bodyClassName}>
                    {children}
                </DialogBody>
                <DialogFooter
                    mctaText={confirmLabel}
                    mctaHandler={onConfirm}
                    sctaText={cancelLabel}
                    sctaHandler={onCancel}
                    noTopBorder
                    className={styles.warningDialogFooter}
                />
            </Dialog>
        );
    }
);
