import { assoc, isEmpty } from 'ramda';
import type { WrapUpdater } from '../flowTypes';

import {
    UPDATE_WRAP_REQUEST, HIDE_UNWRAPED_TIP
} from '../actionTypes';

import {
    removeWrappedNode,
    getComponentsChanges
} from '../../../../../utils/htmlWriter/html/render/wrapper/wrapperNodeUtils';

import {
    COMPONENTS_DELETED,
    SELECTED_COMPONENT_UNWRAP
} from '../../../actionTypes';

import {
    ComponentsMovedByMouseOrKeyboardOrMovedToTemplateSelector,
    ReceiveOnlyComponentsMap, ReceiveOnlyComponentsMapFromFullAction,

    ReceiveOnlySelectedComponentIdSelector,
    ROEditModeComponentIdSelectorFromFullAction,
} from '../../componentsEval/selectorActionTypes';

import { unwrapComponent, getWrapperComponent } from '../helpers';
import ImageKind from '../../../../oneweb/Image/kind';
import { Lit } from '../../../../../lit';
import { CODE_UPDATE_PLACEMENT } from '../../../../oneweb/Code/actionTypes';
import { optional } from '../../../../../epics/makeCondition';
import { ReceiveOnlyAttachments } from '../../componentAttachements/selectorActionTypes';

const setUnwrapedTipVisible = assoc(Lit.unwrapedTipVisible);

const unwrapComponents = (componentsIdsToUnwrap, componentsMap, shouldForceComputeWrapperComponent, attachments) => {
    const changedTextComponents = {};
    let componentsChanges = {};
    let textChanges = {};

    componentsIdsToUnwrap.forEach(wrappedComponentId => {
        let wrapperId;
        if (shouldForceComputeWrapperComponent || !componentsMap[wrappedComponentId]) {
            const wrapperComponent = getWrapperComponent(wrappedComponentId, componentsMap);
            if (wrapperComponent) {
                wrapperId = wrapperComponent.id;
            } else {
                return;
            }
        } else {
            wrapperId = componentsMap[wrappedComponentId].relIn.id;
        }

        const
            wrapperComponent = changedTextComponents[wrapperId] || componentsMap[wrapperId],
            newContent = removeWrappedNode(
                wrapperComponent.content,
                wrappedComponentId
            );

        changedTextComponents[wrapperId] = {
            ...wrapperComponent,
            content: newContent
        };

        componentsChanges[wrappedComponentId] = unwrapComponent();
    });

    Object.keys(changedTextComponents).forEach(wrapperId => {
        const
            wrapperComponent = changedTextComponents[wrapperId];

        componentsChanges = {
            ...componentsChanges,
            ...getComponentsChanges(
                wrapperComponent,
                componentsMap,
                attachments,
            )
        };

        textChanges[wrapperId] = {
            content: wrapperComponent.content
        };
    });

    return { textChanges, componentsChanges };
};

export const unwrapUpdater: WrapUpdater = {
    conditions: [
        ReceiveOnlyComponentsMap,
        ReceiveOnlySelectedComponentIdSelector,
        ReceiveOnlyAttachments,
        SELECTED_COMPONENT_UNWRAP
    ],
    // This sets wrap to false and removes relPara from the selectedComponent.
    reducer: ({ values: [componentsMap, wrappedComponentId, attachments], state, scope }) => {
        const { textChanges, componentsChanges } =
            unwrapComponents([wrappedComponentId], componentsMap, false, attachments);

        return {
            state: setUnwrapedTipVisible(true, state),
            scope,
            actionToDispatch: {
                type: UPDATE_WRAP_REQUEST,
                payload: {
                    textComponentUpdateInfo: textChanges,
                    wrappedComponentsUpdateInfo: componentsChanges,
                    explicit: true
                }
            }
        };
    }
};

const isCropImageInEditMode = (component, editModeComponentId) => component.kind === ImageKind
    && component.id === editModeComponentId
    && component.scaleStrategy === 'crop';

export const unwrapOnComponentMoveUpdater: WrapUpdater = {
    keepFullActions: true,
    conditions: [
        ReceiveOnlyComponentsMapFromFullAction,
        ROEditModeComponentIdSelectorFromFullAction,
        ComponentsMovedByMouseOrKeyboardOrMovedToTemplateSelector,
        ReceiveOnlyAttachments,
        optional(CODE_UPDATE_PLACEMENT)
    ],
    reducer: ({ values: [componentsMap, editModeComponentId, moved, attachments],
        state, scope, conditionActionType }) => {
        const { lastSelectedComponents } = scope;

        if (
            (moved && lastSelectedComponents.length)
            || conditionActionType.actionType === CODE_UPDATE_PLACEMENT
        ) {
            const componentsIdsToUnwrap =
                lastSelectedComponents.filter(id => {
                    const component = componentsMap[id];
                    return component.wrap
                        && (!component.relIn || !lastSelectedComponents.includes(component.relIn.id))
                        && !isCropImageInEditMode(component, editModeComponentId);
                });

            if (componentsIdsToUnwrap.length) {
                const { textChanges, componentsChanges } =
                    unwrapComponents(componentsIdsToUnwrap, componentsMap, true, attachments);

                return {
                    state: setUnwrapedTipVisible(true, state),
                    scope,
                    actionToDispatch: {
                        type: UPDATE_WRAP_REQUEST,
                        payload: {
                            textComponentUpdateInfo: textChanges,
                            wrappedComponentsUpdateInfo: componentsChanges
                        }
                    }
                };
            }
        }

        return { state, scope };
    }
};

export const unwrapOnDeleteUpdater: WrapUpdater = {
    conditions: [
        ReceiveOnlyComponentsMap,
        ReceiveOnlyAttachments,
        COMPONENTS_DELETED
    ],
    reducer: ({ values: [componentsMap, attachments, deletedComponentsIds], state, scope }) => {
        const { textChanges, componentsChanges } =
            unwrapComponents(deletedComponentsIds, componentsMap, false, attachments);

        if (!isEmpty(textChanges) || !isEmpty(componentsChanges)) {
            return {
                state,
                scope,
                actionToDispatch: {
                    type: UPDATE_WRAP_REQUEST,
                    payload: {
                        textComponentUpdateInfo: textChanges,
                        wrappedComponentsUpdateInfo: componentsChanges
                    }
                }
            };
        }

        return { state, scope };
    }
};

export const hideUnwrapedTip: WrapUpdater = {
    conditions: [HIDE_UNWRAPED_TIP],
    reducer: ({ state, scope }) => {
        return {
            state: setUnwrapedTipVisible(false, state),
            scope
        };
    }
};

export const
    unwrapUpdaters = [
        unwrapUpdater,
        unwrapOnComponentMoveUpdater,
        unwrapOnDeleteUpdater,
        hideUnwrapedTip
    ];
