export const
    PAGE_DATA_LOAD_REQUEST = 'PAGE_DATA_LOAD_REQUEST',
    PAGE_DATA_LOAD_SUCCESS = 'PAGE_DATA_LOAD_SUCCESS',
    PAGE_DATA_LOAD_FAILURE = 'PAGE_DATA_LOAD_FAILURE',

    PAGE_PUT_REQUEST = 'PAGE_PUT_REQUEST',
    PAGE_PUT_SUCCESS = 'PAGE_PUT_SUCCESS',
    PAGE_PUT_FAILURE = 'PAGE_PUT_FAILURE',

    REPOSITORY_PAGE_DATA_LOAD_REQUEST = 'REPOSITORY_PAGE_DATA_LOAD_REQUEST',
    REPOSITORY_PAGE_DATA_LOAD_SUCCESS = 'REPOSITORY_PAGE_DATA_LOAD_SUCCESS',
    REPOSITORY_PAGE_DATA_LOAD_FAILURE = 'REPOSITORY_PAGE_DATA_LOAD_FAILURE',

    PAGE_TREE_LOAD_PAGE = 'PAGE_TREE_LOAD_PAGE',
    UPDATE_SITE_DATA_SAGA_AFTER_SAVE = 'UPDATE_SITE_DATA_SAGA_AFTER_SAVE';

