import { RECOVER_AFTER_EXCEPTION } from "../recoverAfterException/actionTypes";
import { sanitizeAppState } from "../../reportError";
import { registerException } from "../../debug/index";
import isTestEnv from "../../debug/isTestEnv";
import { tracePerf } from "../../utils/isDebug";

const subscriptions: ((e: Error) => any)[] = [];
/* TODO remove after redux-saga will be dropped */
export const subscribeOnError = (callback: (e: Error) => any) => {
    subscriptions.push(callback);
};

const _tracePerf = tracePerf();

export default (exceptionType: 'REDUCER_EXCEPTION' | 'MIDDLEWARE_EXCEPTION') =>
    (store: Store) => (next: Dispatch) => (action: Action): any => {
        if (_tracePerf) {
            if (exceptionType === 'MIDDLEWARE_EXCEPTION') {
                console.time('all_middlewares_' + action.type); // eslint-disable-line
            }
            if (exceptionType === 'REDUCER_EXCEPTION') {
                console.timeEnd('all_middlewares_' + action.type); // eslint-disable-line
            }
        }
        const stateBeforePossibleException = store.getState();
        if (isTestEnv()) { // fail fast in tests
            return next(action);
        }

        try {
            if (_tracePerf && exceptionType === 'REDUCER_EXCEPTION') {
                console.time('redux_reducer_' + action.type); // eslint-disable-line
            }
            const nextResult = next(action);
            if (_tracePerf && exceptionType === 'REDUCER_EXCEPTION') {
                console.timeEnd('redux_reducer_' + action.type); // eslint-disable-line
            }
            return nextResult;
        } catch (exception: any) {
            registerException({
                type: exceptionType,
                stateBeforeException: sanitizeAppState(stateBeforePossibleException),
                action,
                exception
            });
            store.dispatch({ type: RECOVER_AFTER_EXCEPTION, payload: { type: exceptionType } });
            subscriptions.forEach(s => s(exception));
            return Promise.reject(exception);
        }
    };
