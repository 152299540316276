import React from "react";
import type { YoutubeVideoContainerProps } from "../flowTypes";
import Msg from '../../../../../view/intl/Msg';
import { YOUTUBE_VIDEO_PREVIEW, VIDEO_SELECTED } from "../../actionTypes";
import * as styles from './YoutubeVideoDialog.css';

export default ({ item, dispatch }: YoutubeVideoContainerProps) => {
    const { videoId, author, description, published, thumbnailSrc, title } = item,
        onPreviewClick = e => {
            e.stopPropagation();
            dispatch({ type: YOUTUBE_VIDEO_PREVIEW, payload: { videoId } });
        };

    return (
        <table
            className={styles.youtubeVideosListItem}
            onClick={() => dispatch({ type: VIDEO_SELECTED, payload: videoId })}
        >
            <tbody>
                <tr>
                    <td className={styles.videoThumb}>
                        <div style={{ backgroundImage: 'url(' + thumbnailSrc + ')' }} />
                    </td>
                    <td className={styles.videoListText}>
                        <div className={styles.videoTitle}>{title}</div>
                        <div className={styles.videoDescription}>{description}</div>
                        <div className={styles.videoFooter}>{author} | {published}</div>
                    </td>
                    <td>
                        <button onClick={onPreviewClick} className={styles.previewButton}>
                            <Msg k="common.preview">Preview</Msg>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
