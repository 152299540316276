/* eslint-disable max-len */

import React from 'react';
import { connect } from "react-redux";
import StandardDialog from '../../../../../view/common/dialogs/StandardDialog/index';
import Msg from "../../../../../view/intl/Msg";
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import { seamlessUpgradeAction } from "../actionCreator";
import { StripTypes } from "../../../../../view/common/dialogs/baseDialog/index";
import { closeDialog } from "../../../../App/actionCreators/index";
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import { injectIntl, Intl } from "../../../../../view/intl/index";
import PrimaryButton from "../../../../../view/common/Button/PrimaryButton";
import { findOutMoreLabel } from "../../../../ComponentTierManager/view/findOutMoreLabel";
import { getPageLimitBySubscriptionType, getSectionLinksLimitBySubscriptionType, isEcommerceSubscription } from "../../../../App/epics/subscriptionData/utils";
import { siteDataValueActionType } from "../../../../App/epics/siteData/valueActionType";
import { makeEpicStateSelector } from "../../../../../epics/makeEpic";
import styles from './SeamlessUpgradeDialog.css';
import type { BrowserDimensions } from "../../../../../redux/modules/children/dimensions/index";
import type { AvailableUpgrade, AppDispatch, SubscriptionData, ChargeInfo } from "../../../../../redux/modules/flowTypes";
import OpenUpgradePlanDialog from "../UpgradePlansDialog/OpenUpgradePlanDialog";
import { getPlanPricePerMonth } from '../utils';
import { getPlanPricing } from './utils';
import { componentTierManagerEpic } from "../../../../ComponentTierManager/epics/index";
import type { ComponentTierManagerStateType } from "../../../../ComponentTierManager/epics/index";
import subscriptionDataEpicVat from "../../../../App/epics/subscriptionData/valueActionType";
import { getEcommercePackageNameFromMetadata, getPackageNameFromMetadata } from "../../../../ComponentTierManager/utils";
import { isPagesOrSectionLinksLimitExceededGivenSiteDataAndSubscriptionType } from '../../../../App/epics/siteData/utils/pages';
import { upgradeButtonClickedAction, upgradeCanceledAction, upgradeDialogOpenedAction } from '../../../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions';
import { UpgradeCancelReason } from '../../../../EventTracking/constants';
import { getSubscriptionTierTitle } from './getSubscriptionTierTitle';
import { UpgradePlansDialogId } from '../../dialogIds';

type SeamlessUpgradeDialogProps = {
    availableUpgrades: Array<AvailableUpgrade>;
    isFreeUpgrade: boolean;
    chargesForUpgrade: ChargeInfo;
    dispatch: AppDispatch;
    maxPagesLimit: number;
    maxSectionLinksLimit: number;
    pagesOrSectionLinksLimitExceeded: boolean;
    intl: Intl;
    source: string;
    upgradeContentBodyMsg?: React.ReactElement<any, any>;
    componentTierState: ComponentTierManagerStateType,
    subscriptionData: SubscriptionData;
    componentKindOrFeature: string;
}

const
    UpgradeContent = (props: SeamlessUpgradeDialogProps) => {
        const
            {
                availableUpgrades,
                dispatch,
                isFreeUpgrade,
                chargesForUpgrade,
                intl,
                upgradeContentBodyMsg,
                componentTierState,
                maxPagesLimit,
                maxSectionLinksLimit,
                pagesOrSectionLinksLimitExceeded,
                subscriptionData,
                componentKindOrFeature
            } = props,
            {
                freeTierUpgradeSubscriptionType,
                isIncompatibleComponentPresent
            } = componentTierState,
            isEcommerceUpgradeType = isEcommerceSubscription(freeTierUpgradeSubscriptionType),
            isPremiumComponentUpgradeType = isIncompatibleComponentPresent && !pagesOrSectionLinksLimitExceeded,
            { metadata: subscriptionMetadata } = subscriptionData,
            eCommercePackageNameFromMetadata = getEcommercePackageNameFromMetadata(subscriptionMetadata),
            packageName = eCommercePackageNameFromMetadata || getSubscriptionTierTitle(intl, freeTierUpgradeSubscriptionType),
            componentsUsedTier = <Msg
                k="common.package_name"
                params={{ packageName }}
            >{`
                    {packageName}
                `}</Msg>,
            findOutMore = findOutMoreLabel({ dispatch, trackingSource: componentKindOrFeature, subscriptionData }),
            label = isFreeUpgrade ? "msg: seamlessUpgrade.UpgradeButtonFree {Free Upgrade *}" : "msg: common.upgrade {Upgrade}";

        let pricePerMonth = '',
            bodyMsg = '' as unknown as React.ReactElement<any, any>;

        if (upgradeContentBodyMsg) {
            bodyMsg = upgradeContentBodyMsg;
        } else if (isEcommerceUpgradeType || isPremiumComponentUpgradeType) {
            bodyMsg = (
                <Msg
                    k="publish.publishFailed.upgradeDialog.body.msg.new"
                    params={{ componentsUsedTier, findOutMore }}
                >
                    {`You'll need to upgrade to publish with the {componentsUsedTier} components you have added to your site. Upgrading will also add other great features to your Website builder. {findOutMore}.`}
                </Msg>
            );
        } else if (pagesOrSectionLinksLimitExceeded) {
            if (maxPagesLimit === 1) {
                bodyMsg = (
                    <Msg
                        className={styles.upgradeMsg}
                        k="publish.publishFailed.pagesOrSectionsExceededBy1.body.info"
                        params={{ pagesCount: maxPagesLimit, sectionLinksCount: maxSectionLinksLimit, findOutMore }}
                    >
                        {`You’ll need to upgrade to publish your site with more than 1 page or {sectionLinksCount} section links. Upgrading will also add other great features to your Website Builder. {findOutMore}.`}
                    </Msg>
                );
            } else {
                bodyMsg = (
                    <Msg
                        className={styles.upgradeMsg}
                        k="publish.publishFailed.pagesOrSectionsExceeded.body.info"
                        params={{ pagesCount: maxPagesLimit, sectionLinksCount: maxSectionLinksLimit, findOutMore }}
                    >
                        {`You’ll need to upgrade to publish your site with more than {pagesCount} pages or {sectionLinksCount} section links. Upgrading will also add other great features to your Website Builder. {findOutMore}.`}
                    </Msg>
                );
            }
        }

        if (chargesForUpgrade) {
            pricePerMonth = getPlanPricePerMonth(
                getPlanPricing(availableUpgrades, freeTierUpgradeSubscriptionType)
            );
        }

        return (
            <React.Fragment>
                <div className={styles.premiumUpgradeDialogIllustration} />
                <VerticalSpacer y={30} />
                <div className={styles.premiumUpgradeDialogBodyMsg}>
                    {bodyMsg}
                </div>
                <div
                    className={styles.premiumUpgradeDialogUpgradeButtonContainer}
                    onClick={() => {
                        dispatch(upgradeButtonClickedAction({
                            feature: componentKindOrFeature,
                            freeUpgrade: isFreeUpgrade,
                            // for paid upgrades, the value will be the expected
                            // subscription type, user might select a different
                            // type from the plans overview dialog.
                            upgradeType: freeTierUpgradeSubscriptionType
                        }));

                        if (isFreeUpgrade) {
                            dispatch(closeDialog());
                            dispatch(seamlessUpgradeAction(freeTierUpgradeSubscriptionType));
                        } else {
                            dispatch(closeDialog());
                            dispatch(OpenUpgradePlanDialog(subscriptionData, 'PremiumUpgradeDialog:UpgradeBtn'));
                            dispatch(upgradeDialogOpenedAction({
                                feature: componentKindOrFeature,
                                dialogId: UpgradePlansDialogId
                            }));
                        }
                    }}
                >
                    { /* @ts-ignore */ }
                    <PrimaryButton className={styles.premiumUpgradeDialogUpgradeButton}>
                        {intl.msgJoint(label)}
                    </PrimaryButton>
                </div>
                {isFreeUpgrade && <div className={styles.freeUpgradeBtnMsgContainer}>
                    <Msg k="wsb.premium.info2" params={{ pricePerMonth }} className={styles.freeUpgradeBtnMsg}>
                        {`* You will get access to the services, free of charge for the rest of your subscription period. After that, it is just {pricePerMonth} /month. You can disable Website Builder Premium at anytime during the trial period.`}
                    </Msg>
                </div>}
            </React.Fragment>
        );
    },

    SeamlessUpgradeMessageDialog = injectIntl((props: SeamlessUpgradeDialogProps) => {
        const { dispatch, source, intl, componentTierState: { freeTierUpgradeSubscriptionType }, subscriptionData } = props;
        const { metadata: subscriptionMetadata } = subscriptionData;

        let upgradeDialogHeader;

        if (["publish", "componentToolbar", "TrackingTab", "TRY_NOW_DIALOG"].some(text => source.indexOf(text) > -1)) {
            const subscriptionTierTitle = getSubscriptionTierTitle(intl, freeTierUpgradeSubscriptionType);
            upgradeDialogHeader = intl.msgJoint([`msg: upgrade.approve.dialog.title {Upgrade to {upgradeToType}}`, {
                upgradeToType: subscriptionMetadata ? getPackageNameFromMetadata(subscriptionTierTitle.toUpperCase(), subscriptionMetadata) : subscriptionTierTitle
            }]);
        } else {
            upgradeDialogHeader = "msg: shutterstock.upgradeToUnliDownloads.link {Upgrade}";
        }

        return (
            <StandardDialog
                title={upgradeDialogHeader}
                titleExtraClassName={styles.premiumUpgradeDialogTitleContainer}
                titleClassName={styles.premiumUpgradeDialogTitle}
                iconClass={styles.premiumUpgradeDialogFeatureIcon}
                onClose={() => {
                    dispatch(closeDialog());
                    dispatch(upgradeCanceledAction(UpgradeCancelReason.UserCancelled));
                }}
                stripType={StripTypes.NONE}
                closeBtnClassName={styles.closeBtnClassName}
            >
                <UpgradeContent {...props} />
            </StandardDialog>
        );
    });

const siteDataSelector = makeEpicStateSelector(siteDataValueActionType),
    componentTierStateSelector = makeEpicStateSelector(componentTierManagerEpic.valueActionType),
    subscriptionDataSelector = makeEpicStateSelector(subscriptionDataEpicVat);

const mapStateToProps = appState => {
    const siteData = siteDataSelector(appState),
        componentTierState = componentTierStateSelector(appState),
        subscriptionData = subscriptionDataSelector(appState),
        upgradeData = subscriptionData.availableUpgrades.find((upgrade) => (
            upgrade.addonProductToUpgrade === componentTierState.freeTierUpgradeSubscriptionType
        ));

    const props = {
        pagesOrSectionLinksLimitExceeded: isPagesOrSectionLinksLimitExceededGivenSiteDataAndSubscriptionType(siteData, subscriptionData.subscriptionType),
        maxPagesLimit: getPageLimitBySubscriptionType(subscriptionData.subscriptionType),
        maxSectionLinksLimit: getSectionLinksLimitBySubscriptionType(subscriptionData.subscriptionType),
        componentTierState,
        availableUpgrades: subscriptionData.availableUpgrades,
        isFreeUpgrade: upgradeData?.freeUpgradeAvailable,
        chargesForUpgrade: upgradeData?.chargesForUpgrade,
        subscriptionData
    };
    return props;
};

export default {
    ...getCenteredDialogConfig({
        width: 580,
        height: 490,
        component: connect(mapStateToProps)(SeamlessUpgradeMessageDialog),
        dialogClassName: styles.dialogContainerClassName,
    }),
    updateConfFn: (config: any, dimensions: BrowserDimensions, props: Record<string, any>) => {
        // In case of paid upgrade, since the contents are less, hence reduce the height of the dialog
        const updatedConfig = { ...config, props: { ...config.props, ...props } };
        updatedConfig.dimensions.height = props.isFreeUpgrade ? 580 : updatedConfig.dimensions.height;

        return updatedConfig;
    }
};
