import { assocPath, pathOr } from 'ramda';
import * as React from 'react';
import cx from 'classnames';
import styles from './MobileHeader.css';
import type { MobileStyle } from "../style/types";
import { toCss } from "../../../mappers/color";
import type { ThemeColorDataType } from '../../ThemeGlobalData/flowTypes';

type CommonProps = {
    mobileStyle: MobileStyle,
    isMenuOpen?: boolean,
};

type PropsWhenAutoColorModeIsOn = CommonProps & {
    autoColorMode: true,
    themeColorsData: ThemeColorDataType,
};

type PropsWhenAutoColorModeIsOff = CommonProps & {
    autoColorMode: false,
};

type Props = PropsWhenAutoColorModeIsOn | PropsWhenAutoColorModeIsOff;

export const MobileHeaderBurgerMenuIcon = (props: Props) => {
    const { mobileStyle, isMenuOpen } = props;
    const convertToCss = (color) => (color ? toCss(color) : undefined),
        inActiveBrickStyle = {
            backgroundColor: (
                props.autoColorMode
                    ? toCss(
                        assocPath(
                            [4],
                            pathOr(1, ['menu', 'closed', 'iconColor', 4])(mobileStyle)
                        )(props.themeColorsData[mobileStyle.menu.closed.iconThemeColor])
                    )
                    : convertToCss(mobileStyle.menu.closed.iconColor)
            ),
        }, activeBrickStyle = {
            backgroundColor: (
                props.autoColorMode
                    ? toCss(
                        assocPath(
                            [4],
                            pathOr(1, ['menu', 'open', 'iconColor', 4])(mobileStyle)
                        )(props.themeColorsData[mobileStyle.menu.open.iconThemeColor])
                    )
                    : convertToCss(mobileStyle.menu.open.iconColor)
            ),
        },
        id = "MobileHeader_burgerMenuIcon",
        brickStyle = isMenuOpen ? activeBrickStyle : inActiveBrickStyle;

    return (
        <div
            className={cx(styles.menuIconContainer, { 'on': isMenuOpen, 'off': !isMenuOpen })}
            id={id}
            data-active-bgcolor={activeBrickStyle.backgroundColor}
            data-inactive-bgcolor={inActiveBrickStyle.backgroundColor}
        >
            {Array(3).fill(undefined).map((_, i) => (
                <span style={brickStyle} key={`${i}`} />
            ))}
        </div>
    );
};

