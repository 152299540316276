import React from 'react';
import { Typography, Button, Box, Stack, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import cx from 'classnames';

import { RenderWhen } from '../../../Helpers/RenderWhen';

const useStyles = makeStyles(
    theme => ({
        root: {
            backgroundColor: theme.palette.custom.colorWhite,
            userSelect: "none"
        },
        illustrationWrapper: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "31px 40px 39px 40px",
            backgroundColor: (props: { illustrationBackground: string }) => props.illustrationBackground
        },
        illustration: {
            width: "144px",
            height: "144px"
        },
        title: {
            fontSize: "18px",
            lineHeight: "24px",
            letterSpacing: "0.25px",
            fontWeight: 500,
            [theme.breakpoints.up("sm")]: {
                fontSize: "20px",
                lineHeight: "24px",
                letterSpacing: "0.15px",
                fontWeight: 400
            }
        },
        description: {
            fontSize: "14px",
            lineHeight: "22px",
            letterSpacing: "0.3px",
            fontWeight: 500,
            [theme.breakpoints.up("sm")]: {
                fontSize: "14px",
                lineHeight: "22px",
                letterSpacing: "0.25px",
                fontWeight: 400
            }
        },
        actionLink: {
            fontSize: "14px",
            lineHeight: "22px",
            letterSpacing: "0.25px",
            fontWeight: 500,
            padding: "0px",
            "&, &:hover": {
                background: "white"
            }
        }
    }),
    { name: "CardWidget" }
);

type WidgetProps = {
    illustration: {
        className: string;
        backgroundColor: string;
    };
    title: string;
    description: string;
    link: {
        text: string;
        href?: string;
        handler: () => void;
    }
};

export const CardWidget = ({ illustration, title, description, link }: WidgetProps) => {
    const classes = useStyles({ illustrationBackground: illustration.backgroundColor });

    const actionButton = (
        <Button
            variant="link"
            className={classes.actionLink}
            onClick={link.handler}
        >
            {link.text}
        </Button>
    );

    return (
        <Stack spacing="24px" className={classes.root}>
            <Box className={classes.illustrationWrapper}>
                <div className={cx(illustration.className, classes.illustration)} />
            </Box>

            <Stack spacing={{ xs: "8px", sm: "16px" }} alignItems="flex-start">
                <Stack spacing="8px">
                    <Typography className={classes.title}>
                        {title}
                    </Typography>
                    <Typography className={classes.description}>
                        {description}
                    </Typography>
                </Stack>

                <RenderWhen when={Boolean(link.href)}>
                    <Link href={link.href} target="_blank" underline="none" className={classes.actionLink}>
                        {link.text}
                    </Link>
                </RenderWhen>

                <RenderWhen when={Boolean(!link.href)}>
                    {actionButton}
                </RenderWhen>
            </Stack>
        </Stack>
    );
};
