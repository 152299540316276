import * as PagesIds from './ids';
import * as mobileView from '../../../../PropertiesPanel/view/MobileView/page';
import TEXT from "../../kind";
import { ComponentNames } from "../../../constants";

export default {
    [PagesIds.MAIN]: ComponentNames[TEXT],
    [PagesIds.ADV_FORMATTING]: "msg: text.propPanel.advformatting.title {More formatting}",
    [PagesIds.TEXT_EFFECTS]: "msg: text.propPanel.texteffects.title {Highlight & shadow}",
    [PagesIds.Link]: "msg: text.propPanel.link.title {Link}",
    [mobileView.id]: mobileView.title
};
