import React from 'react';
import globalStyles from '../../global.css';
import { errorDialogStandardHeight, errorDialogStandardWidth } from '../dialogs/constants';
import WarningDialog from '../dialogs/WarningDialog/index';
import injectIntl from "../../intl/injectIntl";
import getCenteredDialogConfig from "../../../components/DialogManager/getCenteredDialogConfig";

const
    width = errorDialogStandardWidth,
    height = errorDialogStandardHeight,
    title = 'msg: fc.metadataFetch.failed.title {Unable to fetch file data.}',
    contactSupportMsg = 'msg: fc.metadataFetch.failed.contactSupport {Please try again later or contact our support for further assistance.}', // eslint-disable-line
    mctaText = 'msg: common.ok {OK}';

const MetadataFetchFailedErrorDialog = injectIntl(({ intl }: { intl: Intl }) => (
    <WarningDialog title={title} mctaText={mctaText}>
        <div className={globalStyles.bold}>{intl.msgJoint(title)}</div>
        <div>{intl.msgJoint(contactSupportMsg)}</div>
    </WarningDialog>
));

export default getCenteredDialogConfig(width, height, MetadataFetchFailedErrorDialog);
