import * as R from 'ramda';
import { makeApplyEditorChangesReducer } from "./helpers/helpers";
import type { TinyMceEditor } from "../flowTypes";

type Props = {
    triggerActionType: string;
    propToToggle: string;
    doWithEditor: (arg0: TinyMceEditor, value: boolean) => any;
};

const makeToggleUpdater = ({ triggerActionType, propToToggle, doWithEditor }: Props) => ({
    conditions: [triggerActionType],
    reducer: makeApplyEditorChangesReducer(({ state, scope, editor }) => {
        doWithEditor(editor, state[propToToggle]);

        return {
            state: R.evolve({ [propToToggle]: R.not }, state),
            scope
        };
    })
});

export {
    makeToggleUpdater
};
