import type { Dimensions } from "../../../../redux/modules/children/workspace/flowTypes";
import { HeaderHeight } from '../../view/PropertiesPanel.css';
import { MaxPropertiesPanelOffset, DefaultDimensions } from "../constants";
import { TopBarHeight } from "../../../TopBar/constants";

const HeaderHeightInt = parseInt(HeaderHeight, 10);

export const
    getMaxHeight = (browserHeight: number): number => browserHeight - MaxPropertiesPanelOffset - TopBarHeight,
    _getDimensions = (
        maxHeight: number,
        pageHeight: number,
        width: number,
        headerHeight: number
    ): Dimensions => ({ width, height: Math.min(maxHeight, (headerHeight + pageHeight)) }),
    getDimensions = (maxHeight: number, pageHeight: number) =>
        _getDimensions(maxHeight, pageHeight, DefaultDimensions.width, HeaderHeightInt);
