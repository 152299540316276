import * as R from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly } from '../../../../epics/makeCondition';
import { componentAttachmentsVAT as valueActionType } from "./valueActionType";
import {
    COMPONENTS_MAP_INITIALIZED,
    COMPONENTS_PASTED,
    COMPONENTS_ORDER_CHANGED,
    COMPONENTS_RESIZED,
    COMPONENTS_DUPLICATED,
    PROCESS_CMPS_MAP_AFTER_ATTACHMENTS_UPDATE,
    COMPONENTS_WRAPPED,
} from "../componentsEval/actionTypes";
import { createAttachments, deleteCmpIdsFromAttachments, orderIndexSorterAsc, removeDuplicatesFromAnArrayOfStrings,
    getAttachedContainerCmpId, getSiblingAttachedComponentsIds, areAttachmentsEqual,
    updateAttachments } from "./util";
import {
    COMPONENTS_DELETED, MOVE_SELECTED_COMPONENTS_TO_PAGE, WORKSPACE_NEW_COMPONENT_ADDED, WORKSPACE_NEW_SECTION_OR_BLOCK_INSERTED
} from "../../actionTypes";
import * as undoManagerUpdateReasons from "../../../../epics/undoManager/updateReasons";
import { MOUSE_DOWN_ON_HANDLE, IDLE } from '../componentsEval/userInteractionMutations/interactionModes';
import {
    UserInteractionModeSelector,
    ReceiveOnlyComponentsMap,
    SelectedComponentIdSelector,
    ResizedComponentIdsSelector,
    ReceiveOnlyComponentsMapFromFullAction,
    selectedComponentsIdsSelector,
    userInteractionModeSelector,
    componentsMapSelector, ReceiveOnlySelectedComponentSelector,
} from "../componentsEval/selectorActionTypes";
import workspaceBBoxValueActionType from '../workspaceBBox/valueActionType';
import { getComponentsBBox } from "../../../../utils/componentsMap/index";
import topMostHandleVAT from '../topMostHandle/valueActionType';
import { isResizingHandle, isShiftBarBottom } from '../../../../utils/handle/index';
import { isContainer } from '../../../../utils/component';
import { CHANGE_SCOPE, MOVED_TO_TEMPLATE_OR_PAGE, MOVED_BY_MOUSE, MOVED_BY_KEYBOARD } from "../componentsEval/updateReasons";
import {
    NEW_COMPONENT_DROPPED_IS_ADDED_TO_ATTACHMENTS,
    ATTACHMENTS_UPDATED_AFTER_MOVING,
    ATTACHMENTS_UPDATED_AFTER_UNDO
} from "./actionTypes";
import {
    SECTION_FOOTER_RESIZED_BY_ADDING_COMPONENT
} from "../../../oneweb/Section/actionTypes";
import { REDO, UNDO } from "../../../../epics/undoManager/actionTypes";
import componentsEvalVAT from "../componentsEval/valueActionType";
import { CODE_UPDATE_PLACEMENT } from "../../../oneweb/Code/actionTypes";
import { BOX_SIZE_CHANGED } from "./updateReasons";
import getIntersectingComponentsForBBox from "../../../../utils/componentsMap/getIntersectingComponentsInBBox";
import { BBox } from '../../../App/flowTypes';

export default makeEpic({
    defaultState: { attachments: {} },
    undo: {
        isUndoableChange: ({ updateReason }) => BOX_SIZE_CHANGED !== updateReason,
        undoablePaths: [['attachments']]
    },
    valueActionType,
    updaters: [
        {
            conditions: [COMPONENTS_MAP_INITIALIZED],
            reducer: ({ values: [{ componentsMap }] }) => {
                return {
                    state: { attachments: createAttachments({ componentsMap }) },
                    updateReason: undoManagerUpdateReasons.UNDO_INITIAL_STATE
                };
            }
        },
        ...[UNDO, REDO].map(actionType => ({
            conditions: [ReceiveOnlyComponentsMap, actionType],
            reducer: ({ values: [componentsMap] }) => {
                return {
                    state: { attachments: createAttachments({ componentsMap }) },
                    actionToDispatch: { type: ATTACHMENTS_UPDATED_AFTER_UNDO },
                    updateReason: actionType
                };
            }
        })),
        {
            conditions: [ReceiveOnlyComponentsMap, COMPONENTS_DELETED],
            reducer: ({ values: [componentsMap, cmpIdsToDel], state }) => {
                const { attachments, changed } =
                    deleteCmpIdsFromAttachments(state.attachments, cmpIdsToDel, componentsMap);
                return {
                    state: changed ? { ...state, attachments } : state,
                    updateReason: COMPONENTS_DELETED
                };
            }
        },
        {
            keepFullActions: true,
            conditions: [
                componentsEvalVAT,
            ],
            reducer: ({ values: [{ epicUpdateReason, payload: cmpEvalState }], state }) => {
                if (userInteractionModeSelector(cmpEvalState) !== IDLE) {
                    return { state };
                }
                if (epicUpdateReason === MOVED_BY_MOUSE || epicUpdateReason === MOVED_BY_KEYBOARD) {
                    const componentsMap = componentsMapSelector(cmpEvalState),
                        componentsId = selectedComponentsIdsSelector(cmpEvalState),
                        movedCmps = componentsId.map(cmpId => componentsMap[cmpId]),
                        { attachments } =
                            deleteCmpIdsFromAttachments(state.attachments, componentsId, componentsMap, false),
                        newAttachments = createAttachments({
                            componentsMap, newCmps: movedCmps, attachmentsOld: attachments
                        });
                    let multipleActionsToDispatch: Array<Action> = [];
                    if (epicUpdateReason === MOVED_BY_MOUSE) {
                        multipleActionsToDispatch.push({
                            type: ATTACHMENTS_UPDATED_AFTER_MOVING,
                            payload: { componentsId }
                        });
                    }
                    if (areAttachmentsEqual(newAttachments, state.attachments)) {
                        return { state, updateReason: CHANGE_SCOPE, multipleActionsToDispatch };
                    }
                    multipleActionsToDispatch.push({
                        type: PROCESS_CMPS_MAP_AFTER_ATTACHMENTS_UPDATE,
                        payload: {
                            componentsId,
                            oldAttachments: state.attachments,
                            newAttachments,
                        }
                    });
                    return { state: { attachments: newAttachments }, updateReason: epicUpdateReason, multipleActionsToDispatch };
                }
                return { state };
            }
        },
        ...[COMPONENTS_WRAPPED,
            WORKSPACE_NEW_SECTION_OR_BLOCK_INSERTED].map(action => ({
            conditions: [
                ReceiveOnlyComponentsMap,
                action
            ],
            reducer: ({ values: [componentsMap, { componentsId }], state }) => {
                const movedCmps = componentsId.map(cmpId => componentsMap[cmpId]),
                    { attachments } =
                        deleteCmpIdsFromAttachments(state.attachments, componentsId, componentsMap, false),
                    newAttachments = createAttachments({
                        componentsMap, newCmps: movedCmps, attachmentsOld: attachments
                    });
                if (areAttachmentsEqual(newAttachments, state.attachments)) {
                    return { state, updateReason: CHANGE_SCOPE };
                }
                return { state: { attachments: newAttachments }, updateReason: action };
            }
        })),
        {
            conditions: [
                ReceiveOnlySelectedComponentSelector,
                ReceiveOnlyComponentsMap,
                CODE_UPDATE_PLACEMENT
            ],
            reducer: ({ values: [selectedComponent, componentsMap], state }) => {
                const affectedCmps = [selectedComponent],
                    { attachments } =
                        deleteCmpIdsFromAttachments(state.attachments, [selectedComponent.id], componentsMap, false),
                    newAttachments = createAttachments({
                        componentsMap, newCmps: affectedCmps, attachmentsOld: attachments
                    });
                if (areAttachmentsEqual(newAttachments, state.attachments)) {
                    return { state, updateReason: CHANGE_SCOPE };
                }
                return { state: { attachments: newAttachments }, updateReason: CODE_UPDATE_PLACEMENT };
            }
        },
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                COMPONENTS_ORDER_CHANGED
            ],
            reducer: ({ values: [componentsMap, { componentsId }], state }) => {
                let affectedCmpIds: Array<string> = [];
                componentsId.forEach(cmpId => {
                    affectedCmpIds.push(...getSiblingAttachedComponentsIds(cmpId, state.attachments));
                    affectedCmpIds.push(...Object.keys(R.filter(c => c.relIn && c.relIn.id === cmpId, componentsMap)));
                });
                affectedCmpIds = removeDuplicatesFromAnArrayOfStrings([...componentsId, ...affectedCmpIds]);
                const movedCmps = affectedCmpIds.map(cmpId => componentsMap[cmpId]),
                    { attachments } =
                        deleteCmpIdsFromAttachments(state.attachments, affectedCmpIds, componentsMap, false),
                    newAttachments = createAttachments({
                        componentsMap, newCmps: movedCmps, attachmentsOld: attachments
                    });

                return {
                    state: {
                        attachments: newAttachments
                    },
                    actionToDispatch: {
                        type: PROCESS_CMPS_MAP_AFTER_ATTACHMENTS_UPDATE,
                        payload: {
                            componentsId,
                            oldAttachments: state.attachments,
                            newAttachments,
                        }
                    },
                    updateReason: COMPONENTS_ORDER_CHANGED
                };
            }
        },
        ...[COMPONENTS_RESIZED, ATTACHMENTS_UPDATED_AFTER_MOVING].map(actionType => ({
            conditions: [
                ReceiveOnlyComponentsMap,
                receiveOnly(workspaceBBoxValueActionType),
                actionType
            ],
            reducer: ({ values: [componentsMap, workspaceBBox, { componentsId }], state }) => {
                let affectedCmpIds: Array<string> = [];
                componentsId.forEach(cmpId => {
                    affectedCmpIds.push(cmpId);
                    if (state.attachments[cmpId]) {
                        affectedCmpIds.push(...state.attachments[cmpId]);
                    }
                    const bBox = getComponentsBBox([componentsMap[cmpId]], workspaceBBox);
                    affectedCmpIds.push(...getIntersectingComponentsForBBox(componentsMap, bBox, workspaceBBox));
                });
                affectedCmpIds = removeDuplicatesFromAnArrayOfStrings(affectedCmpIds);
                const
                    updatedCmps = affectedCmpIds.map(cmpId => componentsMap[cmpId]),
                    { attachments } =
                        deleteCmpIdsFromAttachments(state.attachments, affectedCmpIds, componentsMap, false),
                    newAttachments = updateAttachments(createAttachments({
                        componentsMap, newCmps: updatedCmps, attachmentsOld: attachments
                    }), componentsMap);

                if (areAttachmentsEqual(state.attachments, newAttachments)) {
                    return { state };
                }

                return {
                    state: {
                        attachments: newAttachments
                    },
                    actionToDispatch: {
                        type: PROCESS_CMPS_MAP_AFTER_ATTACHMENTS_UPDATE,
                        payload: {
                            componentsId,
                            oldAttachments: state.attachments,
                            newAttachments,
                        }
                    },
                    updateReason: COMPONENTS_RESIZED
                };
            }
        })),
        ...[COMPONENTS_PASTED, COMPONENTS_DUPLICATED, SECTION_FOOTER_RESIZED_BY_ADDING_COMPONENT].map(action => ({
            conditions: [
                ReceiveOnlyComponentsMap,
                action
            ],
            reducer: ({ values: [componentsMap, { newComponentsIds }], state }) => {
                const newAttachments = createAttachments({
                    componentsMap,
                    newCmps: newComponentsIds.map(id => componentsMap[id]).sort(orderIndexSorterAsc),
                    attachmentsOld: state.attachments
                });
                return {
                    state: {
                        attachments: newAttachments
                    },
                    actionToDispatch: {
                        type: PROCESS_CMPS_MAP_AFTER_ATTACHMENTS_UPDATE,
                        payload: {
                            componentsId: newComponentsIds,
                            oldAttachments: state.attachments,
                            newAttachments,
                            skipInTemplateUpdate: true,
                        }
                    },
                    updateReason: action
                };
            }
        })),
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                WORKSPACE_NEW_COMPONENT_ADDED
            ],
            reducer: ({ state, values: [componentsMap, { id, newCmpIds = [id] }] }) => {
                const actionToDispatch = { type: NEW_COMPONENT_DROPPED_IS_ADDED_TO_ATTACHMENTS, payload: { id, newCmpIds } };
                if (id) {
                    return {
                        state: {
                            attachments: createAttachments({
                                componentsMap,
                                newCmps: newCmpIds.map(id => componentsMap[id]),
                                attachmentsOld: state.attachments
                            })
                        },
                        actionToDispatch,
                        updateReason: WORKSPACE_NEW_COMPONENT_ADDED
                    };
                }
                return { state, actionToDispatch, updateReason: CHANGE_SCOPE };
            }
        },
        {
            conditions: [
                UserInteractionModeSelector,
                receiveOnly(topMostHandleVAT),
                ReceiveOnlyComponentsMap,
                receiveOnly(SelectedComponentIdSelector),
                receiveOnly(workspaceBBoxValueActionType)
            ],
            reducer: ({
                values: [interactionMode,
                    topMostHandle,
                    componentsMap,
                    selectedComponentId,
                    workspaceBBox
                ], state
            }) => {
                if (interactionMode === MOUSE_DOWN_ON_HANDLE && topMostHandle &&
                    (isResizingHandle(topMostHandle.kind) || isShiftBarBottom(topMostHandle.kind))
                ) {
                    const { attachments } = state;
                    let childrenBBox: BBox | null = null;
                    if (selectedComponentId &&
                        isContainer(componentsMap[selectedComponentId].kind) &&
                        attachments[selectedComponentId] &&
                        attachments[selectedComponentId].length > 0) {
                        const attachedCmps = attachments[selectedComponentId].map(id => componentsMap[id]);
                        childrenBBox = getComponentsBBox(attachedCmps, workspaceBBox);
                        return {
                            state: {
                                ...state,
                                childrenBBox: {
                                    id: selectedComponentId,
                                    minBBox: childrenBBox
                                }
                            },
                            updateReason: BOX_SIZE_CHANGED
                        };
                    } else if (state.childrenBBox) {
                        return { state: { ...state, childrenBBox: null }, updateReason: MOUSE_DOWN_ON_HANDLE };
                    }
                }
                return { state, updateReason: CHANGE_SCOPE };
            }
        },
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                MOVED_TO_TEMPLATE_OR_PAGE
            ],
            reducer: ({ state, values: [componentsMap, { selectedComponentIds, oldCmpsMap }] }) => {
                let cmpIdsToUpdate: Array<string> = [];
                const { attachments } =
                    deleteCmpIdsFromAttachments(state.attachments, selectedComponentIds, componentsMap),
                    possibleOldAttachments = createAttachments({
                        componentsMap: oldCmpsMap
                    });
                selectedComponentIds.forEach(id => {
                    cmpIdsToUpdate.push(id);
                    cmpIdsToUpdate.push(...(possibleOldAttachments[id] || []));
                });
                return {
                    state: {
                        ...state,
                        attachments: createAttachments({
                            componentsMap,
                            newCmps: cmpIdsToUpdate.map(id => componentsMap[id]),
                            attachmentsOld: attachments
                        })
                    },
                    updateReason: MOVE_SELECTED_COMPONENTS_TO_PAGE
                };
            }
        },
        {
            keepFullActions: true,
            conditions: [
                ReceiveOnlyComponentsMapFromFullAction,
                ResizedComponentIdsSelector
            ],
            reducer: ({ state, values: [componentsMap, changedDimensionsComponentIds] }) => {
                const attachmentsToUpdate: Array<string> = [];
                changedDimensionsComponentIds.forEach(cmpId => {
                    if (getAttachedContainerCmpId(cmpId, state.attachments)) {
                        attachmentsToUpdate.push(cmpId);
                    }
                });
                if (!attachmentsToUpdate.length) {
                    return { state, updateReason: CHANGE_SCOPE };
                }
                const resizedCmps = attachmentsToUpdate.map(cmpId => componentsMap[cmpId]),
                    { attachments } =
                        deleteCmpIdsFromAttachments(state.attachments, attachmentsToUpdate, componentsMap, false),
                    newAttachments = createAttachments({
                        componentsMap, newCmps: resizedCmps, attachmentsOld: attachments
                    });
                if (areAttachmentsEqual(newAttachments, state.attachments)) {
                    return { state, updateReason: CHANGE_SCOPE };
                }
                return {
                    state: { ...state, attachments: newAttachments },
                    actionToDispatch: {
                        type: PROCESS_CMPS_MAP_AFTER_ATTACHMENTS_UPDATE,
                        payload: {
                            componentsId: attachmentsToUpdate,
                            oldAttachments: state.attachments,
                            newAttachments,
                        }
                    },
                    updateReason: COMPONENTS_RESIZED
                };
            }
        }
    ]
});
