import React from "react";
import cx from "classnames";
import { useDispatch } from "react-redux";
import { Msg } from "../../../../view/intl/index";
import { OnboardingTopBar } from "../../../TopBar/view/OnboardingTopBar";
import styles from "../../OnboardingFlow.css";
import { PrimaryButton } from "../../../../view/common/Button/index";
import VerticalSpacer from "../../../../view/common/VerticalSpacer";
import { DYNAMIC_TEMPLATE_START_ONBOARDING } from "../Epic/actionTypes";

const WelcomeOnboardingView = ({ showShopWelcome }) => {
    const dispatch = useDispatch();
    const breakTag = <span><br /></span>;

    return (
        <div className={cx(styles.topContainer, { [styles.topShopContainer]: showShopWelcome })}>
            <div className={cx(styles.topBar)}>
                <OnboardingTopBar {...({ enableLogoLink: true, showHelp: false })} />
            </div>
            <div className={styles.contentContainer}>
                <div className={cx(styles.leftContentContainer)}>
                    <div className={cx(styles.leftContent)}>
                        <div className={cx(styles.welcomeText)}>
                            { showShopWelcome ?
                                <Msg k="onboarding.welcomeShopTitle">Build your Online Shop with Website Builder</Msg>
                                : <Msg k="onboarding.welcomeTitle.withBr" params={{ br: breakTag }}>Welcome to Website Builder!</Msg> }
                        </div>
                        <VerticalSpacer y={16} />
                        <div className={cx(styles.tip)}>
                            <Msg k="onboarding.welcomeTip.line1">
                                {`We are here to help you start building the website you\'ll love.`}
                            </Msg>
                            <span><br /></span>
                            <Msg k="onboarding.welcomeTip.line2">
                                Answer a few questions and get a website tailored to your needs.
                            </Msg>
                        </div>
                        <VerticalSpacer y={40} />
                        <PrimaryButton
                            className={styles.getStarted}
                            onClick={() => {
                                dispatch({ type: DYNAMIC_TEMPLATE_START_ONBOARDING });
                            }}
                        >
                            <Msg k="common.getStarted">Get started</Msg>
                        </PrimaryButton>
                    </div>
                </div>
                <div className={cx(styles.rightContentContainer)}>
                    <div className={cx(styles.animationContainer, { [styles.shopAnimationContainer]: showShopWelcome })} />
                </div>
            </div>
        </div>
    );
};

export {
    WelcomeOnboardingView
};
