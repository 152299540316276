import { REPLACE_DIALOG } from "../../../../../redux/modules/actionTypes";
import { UploadRenameFileDialog } from "../../dialogIds";

export const
    openRenameDialogAction = (fileName: string, existingFileNames: Array<string>) => ({
        type: REPLACE_DIALOG,
        payload: { dialogId: UploadRenameFileDialog, props: { fileName, existingFileNames } }
    }),

    CHANGE_RENAME_UPLOAD_FILENAME_ACTION = 'CHANGE_RENAME_UPLOAD_FILENAME_ACTION';
