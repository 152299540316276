import React from "react";
export const SvgTumblrCircleFillDark = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <circle cx={24} cy={24} r={24} fill="#3c3c3c" />
            <path
                fill="#ffffff"
                d="M25.31 22.615v4.765c0 1.209-.016 1.906.112 2.248.127.341.446.695.794.9.461.276.987.414 1.58.414 1.056 0 1.679-.14 2.722-.825v3.133a11.84 11.84 0 01-2.387.832 10.34 10.34 0 01-2.341.253c-.953 0-1.515-.12-2.247-.36a5.474 5.474 0 01-1.872-1.028c-.518-.446-.876-.92-1.075-1.42-.2-.502-.3-1.23-.3-2.183v-7.306H17.5v-2.95c.818-.266 1.733-.647 2.315-1.143a5.705 5.705 0 001.406-1.79c.354-.694.597-1.58.73-2.655h3.359v5.209h5.208v3.906H25.31z"
            />
        </g>
    </svg>
);
