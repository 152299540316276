import type { GeneralGlobalData } from "./generalGlobalDataEpic/generalGlobalDataEpic";
import type { MsgJointInput } from "../../../view/intl/index";
import {
    PRIMARY_PHONE,
    ADMINISTRATIVE_AREA_INVALID,
    ADMINISTRATIVE_AREA_REQUIRED,
    POSTAL_CODE,
    LATLNG,
    ADDRESS,
    LOCALITY_REQUIRED,
    LOCALITY_NOT_REQUIRED, STREET_ADDRESS
} from "../BusinessListing/GoogleMyBusiness/Mappers/gmbErrorFields";

const getWebsiteTitle = (generalGlobalData: GeneralGlobalData): string => {
    if (generalGlobalData && generalGlobalData.websiteTitle) {
        return generalGlobalData.websiteTitle;
    }
    return '';
};

/* Mapping google api errors to custom. */
const classifyAddressErrors = (errField: string, errMessage: string, errCode: number): string => {
    if (errField.includes('phone_numbers.primary_phone')) {
        return PRIMARY_PHONE;
    } else if (errField === 'address.administrative_area') {
        if (errMessage.includes('invalid')) {
            return ADMINISTRATIVE_AREA_INVALID;
        } else {
            return ADMINISTRATIVE_AREA_REQUIRED;
        }
    } else if (errField === 'address.postal_code') {
        return POSTAL_CODE;
    } else if (errField === ('latlng')) {
        return LATLNG;
    } else if (errField.includes('locality')) {
        if (errCode === 3) { // Localities aren't used in AE
            return LOCALITY_NOT_REQUIRED;
        } else { // code === 2, address.locality is required
            return LOCALITY_REQUIRED;
        }
    } else if (errField.includes('address_lines')) {
        return STREET_ADDRESS;
    } else if (errField.includes('address')) {
        return ADDRESS;
    }
    return '';
};

const placeholderFieldFormatMap:
    // @ts-ignore
    { [CountryFormat]: { city: MsgJointInput, stateProvince?: MsgJointInput, street: MsgJointInput, zip: MsgJointInput } } = {
        CountryFormat1: {
            city: "msg: generalInfo.city.format1.placeholder {e.g. Copenhagen}",
            street: "msg: generalInfo.street.format1.placeholder {e.g. Street 12, 3rd floor}",
            zip: "msg: generalInfo.zip.format1.placeholder {e.g. 1234}",
        },
        CountryFormat2: {
            city: "msg: generalInfo.city.format2.placeholder {e.g. London}",
            street: "msg: generalInfo.street.format2.placeholder {e.g. 12 Street, 3rd floor}",
            zip: "msg: generalInfo.zip.format2.placeholder {e.g. EC1Y 8SY}",
        },
        CountryFormat3: {
            city: "msg: generalInfo.city.format3.placeholder {e.g. Beverly Hills}",
            stateProvince: "msg: generalInfo.state.placeholder {e.g. California}",
            street: "msg: generalInfo.street.format3.placeholder {e.g. 1234 Street Blvd}",
            zip: "msg: generalInfo.zip.format3.placeholder {e.g. 90210}",
        },
        CountryFormat4: {
            city: "msg: generalInfo.city.format4.placeholder {e.g. Beijing}",
            stateProvince: "msg: generalInfo.province.placeholder {Province}",
            street: "msg: generalInfo.street.format4.placeholder {e.g. 12 Street}",
            zip: "msg: generalInfo.zip.format4.placeholder {e.g. 123456}",
        },
        CountryFormat5: {
            city: "msg: generalInfo.city.format3.placeholder {e.g. Beverly Hills}",
            stateProvince: "msg: generalInfo.state.placeholder {e.g. California}",
            street: "msg: generalInfo.street.format3.placeholder {e.g. 1234 Street Blvd}",
            zip: "msg: generalInfo.zip.format3.placeholder {e.g. 90210}",
        },
    };

export {
    getWebsiteTitle,
    placeholderFieldFormatMap,
    classifyAddressErrors,
};
