/* eslint-disable max-len */

import { makeDefaultStateReducer } from '../../redux/makeReducer/index';
import type { DialogManagerRegistry, DialogManagerRegistryRecord } from './flowTypes';
import { MVE_WELCOME_MODAL_DIALOG_ID } from "../MobileViewEditor/ids";
import { mveWelcomeModalDialog } from "../MobileViewEditor/welcomeModalDialog/view/welcomeModalDialog";
import { SiteSettingsDialogId } from "../SiteSettings/SiteSettingsDialog/constants";
import { siteSettingsDialog } from "../SiteSettings/SiteSettingsDialog/config";
import { editLocalizationDialogId } from '../oneweb/Template/editLocalizationsDialog/editLocalizationDialogId';
import { editLocalizationsDialogConfig } from '../oneweb/Template/editLocalizationsDialog/EditLocalizationsDialog';
import { allInDialog } from "../../debug/webDevAllInOne/allInOneDialog/allInOneDialog";
import { allInOneDialogId } from "../../debug/webDevAllInOne/allInOneDialog/allInOneDialogId";
import { sectionIntroDialogId } from "../oneweb/Section/dialog/sectionIntroDialogId";
import { sectionIntroDialogConfig } from "../oneweb/Section/dialog/sectionIntroDialog";
import { ThemeOnboardingDialogId } from "../AutoColorLeftPanel/ThemeOnboardingDialog/constants";
import { themeOnboardingDialog } from "../AutoColorLeftPanel/ThemeOnboardingDialog/config";
import { themeDeactivationDialog } from "../AutoColorLeftPanel/ThemeDeactivationDialog/config";
import { ThemeDeactivationDialogId } from "../AutoColorLeftPanel/ThemeDeactivationDialog/constants";
import { contrastAdjustDialog } from "../AutoColorLeftPanel/ContrastAdjustDialog/config";
import { ContrastAdjustDialogId } from "../AutoColorLeftPanel/ContrastAdjustDialog/constants";
import { createListingDialog } from "../SiteSettings/BusinessListing/GoogleMyBusiness/CreateListingDialog/config";
import { CreateListingDialogId } from "../SiteSettings/BusinessListing/GoogleMyBusiness/CreateListingDialog/constants";
import {
    CongratsOrErrorHandlerDialogs,
    CongratsOrErrorHandlerDialogsId
} from "../SiteSettings/BusinessListing/GoogleMyBusiness/CongratsOrErrorHandlerDialogs/config";
import { promoPageDialog } from "../SiteSettings/BusinessListing/GoogleMyBusiness/PromoPageDialog/config";
import { PromoPageDialogId } from "../SiteSettings/BusinessListing/GoogleMyBusiness/PromoPageDialog/constants";
import { disconnectListingDialog } from "../SiteSettings/BusinessListing/GoogleMyBusiness/DisconnectListingDialog/config";
import { DisconnectListingDialogId } from "../SiteSettings/BusinessListing/GoogleMyBusiness/DisconnectListingDialog/constants";
import { GmbPublishDialogId, gmbPublishDialog } from "../SiteSettings/BusinessListing/GoogleMyBusiness/GmbPublishDialog/config";
import { MHFDeactivationDialogId } from '../ModernLayouts/dialogs/MHFDeactivationDialog/constants';
import { mhfDeactivationDialog } from '../ModernLayouts/dialogs/MHFDeactivationDialog/config';
import { WidgetsDialogRegistry } from '../oneweb/Widgets/dialogManagerRegistry';
import { GoogleReviewWidgetDialogRegistry } from '../oneweb/GoogleReviews/dialog/dialogManagerRegistry';

const dialogs = [
    [
        require('../oneweb/Document/configurationDialog/constants').DOCUMENT_CONFIG_DIALOG_ID,
        require('../oneweb/Document/configurationDialog/index').default
    ], [
        require('../../view/common/FileChooser/dialogIds').FileNameConflictDialog,
        require('../../view/common/FileChooser/FileChooserUpload/fileNameConflictDialog').default
    ], [
        require('../../view/common/FileChooser/dialogIds').UploadRenameFileDialog,
        require('../../view/common/FileChooser/FileChooserUpload/renameDialog/index').default
    ], [
        require('../../view/common/FileChooser/dialogIds').ExternalUrlUploadDialog,
        require('../../view/common/FileChooser/FileChooserUpload/urlDialog').default
    ], [
        require('../../view/common/FileChooser/dialogIds').FailedErrorDialog,
        require('../../view/common/FileChooser/FileChooserUpload/errorDialogs/failedErrorDialog').default
    ], [
        require('../../view/common/FileChooser/dialogIds').ImageSizeErrorDialogId,
        require('../../view/common/FileChooser/FileChooserUpload/errorDialogs/imageSizeErrorDialog').default
    ], [
        require('../../view/common/FileChooser/dialogIds').SvgSizeErrorDialogId,
        require('../../view/common/FileChooser/FileChooserUpload/errorDialogs/svgSizeErrorDialog').default
    ], [
        require('../../view/common/FileChooser/dialogIds').InvalidErrorDialogId,
        require('../../view/common/FileChooser/FileChooserUpload/errorDialogs/invalidErrorDialog').default
    ], [
        require('../../view/common/FileChooser/dialogIds').UnsupportedErrorDialogId,
        require('../../view/common/FileChooser/FileChooserUpload/errorDialogs/unsupportedErrorDialog').default
    ], [
        require('../../view/common/FileChooser/dialogIds').MultipleErrorsDialog,
        require('../../view/common/FileChooser/FileChooserUpload/errorDialogs/multipleErrorsDialog').default
    ], [
        require('../../view/common/FileChooser/dialogIds').MaxMultiSelectWarningDialog,
        require('../../view/common/FileChooser/maxMultiSelectWarningDialog').default
    ], [
        require('../../view/common/FileChooser/dialogIds').MetadataFetchFailedErrorDialog,
        require('../../view/common/FileChooser/metadataFetchFailedErrorDialog').default
    ], [
        require('../../view/common/FileChooser/dialogIds').FileChooserDeleteConfirmationDialog,
        require('../../view/common/FileChooser/FileChooserDelete/fileChooserDeleteConfirmationDialog').fileChooserDeleteConfirmationDialog
    ], [
        require('../../view/common/FileChooser/dialogIds').FileChooserDeleteErrorDialog,
        require('../../view/common/FileChooser/FileChooserDelete/fileChooserDeleteErrorDialog').fileChooserDeleteErrorDialog
    ], [
        require('../../view/common/dialogs/LinkChooserDialog/constants').LinkChooserDialogId,
        require('../../view/common/dialogs/LinkChooserDialog/config').default
    ], [
        require('../../view/Workspace/Decorations/ComponentPanelActions/cmpPanelDeleteConfirmationDialogId').CmpPanelDeleteConfirmationDialogId,
        require('../../view/Workspace/Decorations/ComponentPanelActions/cmpPanelDeleteConfirmationDialog').CmpPanelDeleteConfirmationDialog
    ], [
        SiteSettingsDialogId,
        siteSettingsDialog
    ], [
        CreateListingDialogId,
        createListingDialog
    ], [
        PromoPageDialogId,
        promoPageDialog
    ], [
        DisconnectListingDialogId,
        disconnectListingDialog
    ], [
        GmbPublishDialogId,
        gmbPublishDialog
    ], [
        ThemeOnboardingDialogId,
        themeOnboardingDialog
    ], [
        ThemeDeactivationDialogId,
        themeDeactivationDialog
    ], [
        ContrastAdjustDialogId,
        contrastAdjustDialog
    ], [
        MHFDeactivationDialogId,
        mhfDeactivationDialog,
    ], [
        CongratsOrErrorHandlerDialogsId,
        CongratsOrErrorHandlerDialogs
    ], [
        require('../oneweb/Facebook/configurationDialog').configurationDialogId,
        require('../oneweb/Facebook/configurationDialog').default
    ], [
        require('../oneweb/Video/dialogIds').YoutubeConfigDialogId,
        require('../oneweb/Video/view/YoutubeVideoDialog/index').default
    ], [
        require('../oneweb/Video/dialogIds').PreviewDialogId,
        require('../oneweb/Video/view/PreviewDialog/index').default
    ], [
        require('../oneweb/Video/dialogIds').VideoUploadLimitDialogId,
        require('../oneweb/Video/view/VideoUploadLimitDialog/index').default,
    ], [
        require('../oneweb/Video/dialogIds').VideoSizeLimitExceededDialogId,
        require('../oneweb/Video/view/VideoSizeLimitExceededDialog/index').default,
    ], [
        require('../oneweb/ContactForm/dialogIds').ContactFormComponentConfigDialogId,
        require('../oneweb/ContactForm/view/ConfigurationDialog/index').default
    ], [
        require('../oneweb/ContactForm/dialogIds').MissingEmailWarningDialogId,
        require('../oneweb/ContactForm/view/MissingEmailWarning/index').default
    ], [
        require('../oneweb/ContactForm/dialogIds').DisableCaptchaWarningDialogId,
        require('../oneweb/ContactForm/view/Captcha/DisableCaptchaWarning/index').default
    ], [
        require('../oneweb/Code/configurationDialogId').default,
        require('../oneweb/Code/CodeEditorDialog').default
    ], [
        require('../presentational/FourFields/FourFieldBorders/configurationDialogId').default,
        require('../presentational/FourFields/FourFieldBorders/configurationDialog').default
    ], [
        require('../presentational/FourFields/FourFieldCorners/configurationDialogId').default,
        require('../presentational/FourFields/FourFieldCorners/configurationDialog').default
    ], [
        require('../PagesTree/NewPageDialog/newPageDialogIds').NewPageDialogId,
        require('../PagesTree/NewPageDialog/config').default
    ], [
        require('../Globalstyles/dialogId').default,
        require('../Globalstyles/dialog').default
    ], [
        require('../Globalstyles/deleteGlobalstyleDialogId').default,
        require('../Globalstyles/deleteGlobalstyleDialog').default
    ], [
        require('../Globalstyles/Text/normalCharacterSpacingConfigurationDialogId').default, // eslint-disable-line
        require('../Globalstyles/Text/normalCharacterSpacingConfigurationDialog').default
    ], [
        require('../Globalstyles/Text/heading1CharacterSpacingConfigurationDialogId').default, // eslint-disable-line
        require('../Globalstyles/Text/heading1CharacterSpacingConfigurationDialog').default // eslint-disable-line
    ], [
        require('../Globalstyles/Text/heading2CharacterSpacingConfigurationDialogId').default, // eslint-disable-line
        require('../Globalstyles/Text/heading2CharacterSpacingConfigurationDialog').default // eslint-disable-line
    ], [
        require('../Globalstyles/Text/heading3CharacterSpacingConfigurationDialogId').default, // eslint-disable-line
        require('../Globalstyles/Text/heading3CharacterSpacingConfigurationDialog').default // eslint-disable-line
    ], [
        require('../Globalstyles/Text/logoCharacterSpacingConfigurationDialogId').default, // eslint-disable-line
        require('../Globalstyles/Text/logoCharacterSpacingConfigurationDialog').default // eslint-disable-line
    ], [
        require('../Globalstyles/Text/normalTextShadowConfigurationDialogId').default,
        require('../Globalstyles/Text/normalTextShadowConfigurationDialog').default
    ], [
        require('../Globalstyles/Text/heading1TextShadowConfigurationDialogId').default,
        require('../Globalstyles/Text/heading1TextShadowConfigurationDialog').default
    ], [
        require('../Globalstyles/Text/heading2TextShadowConfigurationDialogId').default,
        require('../Globalstyles/Text/heading2TextShadowConfigurationDialog').default
    ], [
        require('../Globalstyles/Text/heading3TextShadowConfigurationDialogId').default,
        require('../Globalstyles/Text/heading3TextShadowConfigurationDialog').default
    ], [
        require('../Globalstyles/Text/logoTextShadowConfigurationDialogId').default,
        require('../Globalstyles/Text/logoTextShadowConfigurationDialog').default
    ], [
        require('../Globalstyles/Link/inactiveTextShadowConfigurationDialogId').default,
        require('../Globalstyles/Link/inactiveTextShadowConfigurationDialog').default
    ], [
        require('../Globalstyles/Link/hoverTextShadowConfigurationDialogId').default,
        require('../Globalstyles/Link/hoverTextShadowConfigurationDialog').default
    ], [
        require('../Globalstyles/Menu/borderStyleConfigurationDialogId').default,
        require('../Globalstyles/Menu/borderStyleConfigurationDialog').default
    ], [
        require('../Globalstyles/Menu/spacingConfigurationDialogId').default,
        require('../presentational/FourFields/FourFieldSpacing/configurationDialog').default
    ], [
        require('../Globalstyles/Menu/normalBackgroundConfigurationDialogId').default,
        require('../Globalstyles/Menu/normalBackgroundConfigurationDialog').default
    ], [
        require('../Globalstyles/Menu/mouseOverBackgroundConfigurationDialogId').default,
        require('../Globalstyles/Menu/mouseOverBackgroundConfigurationDialog').default
    ], [
        require('../Globalstyles/Menu/selectedBackgroundConfigurationDialogId').default,
        require('../Globalstyles/Menu/selectedBackgroundConfigurationDialog').default
    ], [
        require('../Globalstyles/Menu/expandableBackgroundConfigurationDialogId').default,
        require('../Globalstyles/Menu/expandableBackgroundConfigurationDialog').default
    ], [
        require('../Globalstyles/Menu/expandedBackgroundConfigurationDialogId').default,
        require('../Globalstyles/Menu/expandedBackgroundConfigurationDialog').default
    ], [
        require('../Globalstyles/Menu/dividerBackgroundConfigurationDialogId').default,
        require('../Globalstyles/Menu/dividerBackgroundConfigurationDialog').default
    ], [
        require('../Globalstyles/Menu/levelContainerBackgroundConfigurationDialogId').default,
        require('../Globalstyles/Menu/levelContainerBackgroundConfigurationDialog').default
    ], [
        require('../Globalstyles/Menu/expandableSpacingConfigurationDialogId').default,
        require('../presentational/FourFields/FourFieldSpacing/configurationDialog').default
    ], [
        require('../Globalstyles/Menu/dividerSpacingConfigurationDialogId').default,
        require('../presentational/FourFields/FourFieldSpacing/configurationDialog').default
    ], [
        require('../Globalstyles/Menu/levelContainerSpacingConfigurationDialogId').default,
        require('../presentational/FourFields/FourFieldSpacing/configurationDialog').default
    ], [
        require('../Globalstyles/Menu/normalTextShadowConfigurationDialogId').default,
        require('../Globalstyles/Menu/normalTextShadowConfigurationDialog').default
    ], [
        require('../Globalstyles/Menu/mouseOverTextShadowConfigurationDialogId').default,
        require('../Globalstyles/Menu/mouseOverTextShadowConfigurationDialog').default
    ], [
        require('../Globalstyles/Menu/selectedTextShadowConfigurationDialogId').default,
        require('../Globalstyles/Menu/selectedTextShadowConfigurationDialog').default
    ], [
        require('../Globalstyles/Menu/expandableTextShadowConfigurationDialogId').default,
        require('../Globalstyles/Menu/expandableTextShadowConfigurationDialog').default
    ], [
        require('../Globalstyles/Menu/expandedTextShadowConfigurationDialogId').default,
        require('../Globalstyles/Menu/expandedTextShadowConfigurationDialog').default
    ], [
        require('../Globalstyles/Menu/dividerBorderStyleConfigurationDialogId').default,
        require('../Globalstyles/Menu/dividerBorderStyleConfigurationDialog').default
    ], [
        require('../Globalstyles/Menu/levelContainerBorderStyleConfigurationDialogId').default,
        require('../Globalstyles/Menu/levelContainerBorderStyleConfigurationDialog').default
    ], [
        require('../Globalstyles/Button/inactiveBackgroundConfigurationDialogId').default,
        require('../Globalstyles/Button/inactiveBackgroundConfigurationDialog').default
    ], [
        require('../Globalstyles/Button/hoverBackgroundConfigurationDialogId').default,
        require('../Globalstyles/Button/hoverBackgroundConfigurationDialog').default
    ], [
        require('../Globalstyles/Button/inactiveBorderStyleConfigurationDialogId').default,
        require('../Globalstyles/Button/inactiveBorderStyleConfigurationDialog').default
    ], [
        require('../Globalstyles/Button/hoverBorderStyleConfigurationDialogId').default,
        require('../Globalstyles/Button/hoverBorderStyleConfigurationDialog').default
    ], [
        require('../Globalstyles/Table/dialogs/TableNormalBorderDialogId').default,
        require('../Globalstyles/Table/dialogs/TableNormalBorderDialog').default
    ], [
        require('../Globalstyles/Table/dialogs/TableHeading1BorderDialogId').default,
        require('../Globalstyles/Table/dialogs/TableHeading1BorderDialog').default
    ], [
        require('../Globalstyles/Table/dialogs/TableHeading2BorderDialogId').default,
        require('../Globalstyles/Table/dialogs/TableHeading2BorderDialog').default
    ], [
        require('../Globalstyles/Table/dialogs/TableAlternateBorderDialogId').default,
        require('../Globalstyles/Table/dialogs/TableAlternateBorderDialog').default
    ], [
        require('../Globalstyles/Table/dialogs/TableNormalCellSpacingDialogId').default,
        require('../Globalstyles/Table/dialogs/TableNormalCellSpacingDialog').default
    ], [
        require('../Globalstyles/Table/dialogs/TableHeading1CellSpacingDialogId').default,
        require('../Globalstyles/Table/dialogs/TableHeading1CellSpacingDialog').default
    ], [
        require('../Globalstyles/Table/dialogs/TableHeading2CellSpacingDialogId').default,
        require('../Globalstyles/Table/dialogs/TableHeading2CellSpacingDialog').default
    ], [
        require('../Globalstyles/Table/dialogs/TableAlternateCellSpacingDialogId').default,
        require('../Globalstyles/Table/dialogs/TableAlternateCellSpacingDialog').default
    ], [
        require('../Globalstyles/Table/dialogs/TableNormalTextShadowDialogId').default,
        require('../Globalstyles/Table/dialogs/TableNormalTextShadowDialog').default
    ], [
        require('../Globalstyles/Table/dialogs/TableHeading1TextShadowDialogId').default,
        require('../Globalstyles/Table/dialogs/TableHeading1TextShadowDialog').default
    ], [
        require('../Globalstyles/Table/dialogs/TableHeading2TextShadowDialogId').default,
        require('../Globalstyles/Table/dialogs/TableHeading2TextShadowDialog').default
    ], [
        require('../Globalstyles/Table/dialogs/TableAlternateTextShadowDialogId').default,
        require('../Globalstyles/Table/dialogs/TableAlternateTextShadowDialog').default
    ], [
        require('../Globalstyles/Table/dialogs/TableNormalBackgroundDialogId').default,
        require('../Globalstyles/Table/dialogs/TableNormalBackgroundDialog').default
    ], [
        require('../Globalstyles/Table/dialogs/TableHeading1BackgroundDialogId').default,
        require('../Globalstyles/Table/dialogs/TableHeading1BackgroundDialog').default
    ], [
        require('../Globalstyles/Table/dialogs/TableHeading2BackgroundDialogId').default,
        require('../Globalstyles/Table/dialogs/TableHeading2BackgroundDialog').default
    ], [
        require('../Globalstyles/Table/dialogs/TableAlternateBackgroundDialogId').default,
        require('../Globalstyles/Table/dialogs/TableAlternateBackgroundDialog').default
    ], [
        require('../presentational/FontFamily/addGoogleFontConfigurationDialogId').default,
        require('../presentational/FontFamily/addGoogleFontConfigurationDialog').default
    ], [
        require('../presentational/FontFamily/fontSelectionConfigurationDialogId').default,
        require('../presentational/FontFamily/fontSelectionConfigurationDialog').default
    ], [
        require('../oneweb/ContactForm/dialogIds').ContactFormComponentConfigDialogId,
        require('../oneweb/ContactForm/view/ConfigurationDialog/index').default
    ], [
        require('../oneweb/ContactForm/dialogIds').FormElementsDialogId,
        require('../oneweb/ContactForm/view/EditFieldsDialog/index').default
    ], [
        require('../ComponentsAddNotAllowedDialog/dialogId').default,
        require('../ComponentsAddNotAllowedDialog/index').default
    ], [
        require('../PagesTree/NewPageDialog/newPageDialogIds').NewPageDialogId,
        require('../PagesTree/index').NewPageDialog
    ], [
        require('../PagesTree/NewPageDialog/newPageDialogIds').NewPageNameDialogId,
        require('../PagesTree/index').NewPageDialogName
    ], [
        require('../PagesTree/NewPageDialog/newPageDialogIds').NewSectionLinkNameDialogId,
        require('../PagesTree/index').NewSectionLinkDialogName
    ], [
        require('../PagesTree/PageTemplateSelector/dialogId').PAGE_TEMPLATE_SELECTOR_DIALOG_ID,
        require('../PagesTree/PageTemplateSelector/dialog').default
    ], [
        require('../PagesTree/AddOnecomTemplate/dialogId').ADD_ONECOM_TEMPLATE_DIALOG_ID,
        require('../PagesTree/AddOnecomTemplate/dailog').default
    ], [
        require('../PagesTree/PageTemplateSelector/dialogs/errorDialog/pageTemplateSelectorErrorDialog')
            .PAGE_TEMPLATE_SELECTOR_ERROR_DIALOG_ID,
        require('../PagesTree/PageTemplateSelector/dialogs/errorDialog/pageTemplateSelectorErrorDialog').default
    ], [
        require('../PagesTree/PageTemplateSelector/dialogs/deleteConfirmDialog/pageTemlateSelectorDeleteConfirmDialog')
            .PAGE_TEMPLATE_SELECTOR_DELETE_CONFIRM_DIALOG_ID,
        require('../PagesTree/PageTemplateSelector/dialogs/deleteConfirmDialog/pageTemlateSelectorDeleteConfirmDialog')
            .default
    ], [
        require('../PagesTree/dialogs/pagesTreeDialogIds').PageTemplateSaveFailureDialogId,
        require('../PagesTree/index').PageTemplateSaveFailureDialog
    ], [
        require('../PagesTree/dialogs/pagesTreeDialogIds').PageLoadFailureDialogId,
        require('../PagesTree/dialogs/PageLoadFailureDialog').default
    ], [
        require('../PagesTree/dialogs/pagesTreeDialogIds').PageTemplatesLoadFailureDialogId,
        require('../PagesTree/dialogs/PageTemplatesLoadFailureDialog').default
    ], [
        require('../PagesTree/Tree/dialogs/ids').DeletePageAndSubPagesDialogId,
        require('../PagesTree/Tree/dialogs/DeletePageAndSubPagesDialog').default
    ], [
        require('../PagesTree/Tree/dialogs/ids').DeleteHomePageAndSubPagesDialogId,
        require('../PagesTree/Tree/dialogs/DeleteHomePageAndSubPagesDialog').default
    ], [
        require('../PagesTree/Tree/dialogs/ids').DeleteLinkPageDialogId,
        require('../PagesTree/Tree/dialogs/DeleteLinkPageDialog').default
    ], [
        require('../PagesTree/Tree/dialogs/ids').DeleteSectionLinkDialogId,
        require('../PagesTree/Tree/dialogs/DeleteSectionLinkDialog').default
    ], [
        require('../PagesTree/Tree/dialogs/ids').PageInfoDialogId,
        require('../PagesTree/Tree/dialogs/PageInfoDialog').default
    ], [
        require('../PagesTree/Tree/dialogs/PageInfoDialog/unsavedChangesDialog/dialogId')
            .PAGE_INFO_UNSAVED_CHANGES_DIALOG_ID,
        require('../PagesTree/Tree/dialogs/PageInfoDialog/unsavedChangesDialog/dialog').default
    ], [
        require('../PagesTree/Tree/dialogs/ids').LinkPageInfoDialogId,
        require('../PagesTree/Tree/dialogs/LinkPageInfoDialog/index').default
    ], [
        require('../PagesTree/Tree/dialogs/ids').SectionLinkInfoDialogId,
        require('../PagesTree/Tree/dialogs/SectionLinkInfoDialog/index').default
    ], [
        require('../App/dialogs/appDialogIds').SiteDataSaveFailureDialogId,
        require('../App/dialogs/SiteDataSaveFailureDialog').default
    ], [
        require('../App/dialogs/appDialogIds').ProgressDialogId,
        require('../App/dialogs/ProgressDialog/index').default
    ], [
        require('../UnsavedChanges/dialog/index').UnsavedChangesDialogId,
        require('../UnsavedChanges/dialog/index').default
    ], [
        require('../SortImagesDialog/dialogId').default,
        require('../SortImagesDialog/dialog').default
    ], [
        require('../PinComponentDialog/dialogId').default,
        require('../PinComponentDialog/dialog').default
    ], [
        require('../MissingAssets/dialogId').default,
        require('../MissingAssets/dialog').default
    ], [
        require('../oneweb/Template/templatePagesDialog/templatePagesDialogId').TemplatePagesDialogId,
        require('../oneweb/Template/templatePagesDialog/index').default
    ], [
        editLocalizationDialogId,
        editLocalizationsDialogConfig
    ], [
        require('../oneweb/Template/templateWidthDialog/TemplateWidthDialogId').default,
        require('../oneweb/Template/templateWidthDialog/TemplateWidthDialog').default
    ], [
        require('../TopBar/view/dialogIds').PublishConfirmationDialogId,
        require('../TopBar/view/Publish/PublishConfirmation').default
    ], [
        require('../oneweb/Social/SocialEditLinkDialog/socialEditLinkDialogId').SocialEditLinkDialogId,
        require('../oneweb/Social/SocialEditLinkDialog/socialEditLinkDialog').SocialEditLinkDialog
    ], [
        require('../oneweb/Social/SocialRemoveLinkConfirmationDialog/socialDeleteLinkConfirmationDialogId').SocialDeleteLinkConfirmationDialogId,
        require('../oneweb/Social/SocialRemoveLinkConfirmationDialog/socialDeleteLinkConfirmationDialog').SocialRemoveLinkConfirmationDialog
    ], [
        require('../TopBar/view/dialogIds').SaveAndPublishConfirmationDialogId,
        require('../TopBar/view/Publish/SaveAndPublishConfirmation/index').default
    ], [
        require('../TopBar/view/dialogIds').ContactFormEmailValidationFailed,
        require('../TopBar/view/Publish/PublishErrorDialogs/ContactFormEmailValidationFailed').default
    ], [
        require('../TopBar/view/dialogIds').OverwriteAssetsDialogId,
        require('../TopBar/view/Publish/PublishErrorDialogs/OverwriteAssets').default
    ], [
        require('../TopBar/view/dialogIds').OverwriteClobberedAssetsDialogId,
        require('../TopBar/view/Publish/PublishErrorDialogs/OverwriteClobberedAssets').default
    ], [
        require('../TopBar/view/dialogIds').PublishingFailedDialogId,
        require('../TopBar/view/Publish/PublishErrorDialogs/PublishingFailed').default
    ], [
        require('../TopBar/view/dialogIds').PublishingFailedInvalidLinksDialogId,
        require('../TopBar/view/Publish/PublishErrorDialogs/InvalidLinks').default
    ], [
        require('../TopBar/view/dialogIds').PublishWarningNoStartPageDialogId,
        require('../TopBar/view/Publish/PublishWarningNoStartPage/index').default
    ], [
        require('../TopBar/view/dialogIds').UpgradeFailureDialogId,
        require('../TopBar/view/Upgrade/UpgradeFailedPopup').default
    ], [
        require('../WelcomeMessage/dialogId').default,
        require('../WelcomeMessage/dialog').default
    ], [
        require('../AutoColorLeftPanel/colorChangesDialog/dialogId').default,
        require('../AutoColorLeftPanel/colorChangesDialog/dialog').default
    ], [
        require('../TopBar/view/dialogIds').SeamlessUpgradeDialogId,
        require('../TopBar/view/Upgrade/SeamlessUpgradeDialog/index').default
    ], [
        require('../TopBar/view/dialogIds').KeyboardShortcutsId,
        require('../TopBar/view/KeyboardShortcutsDialog/index').default
    ],
    [
        require('../TopBar/view/dialogIds').TutorialVideoDialogId,
        require('../TopBar/view/TutorialVideoDialog/index').default
    ], [
        require('../AutoColorLeftPanel/ApplyingThemingDialog/ApplyingThemingDialog').ApplyingThemingDialogId,
        require('../AutoColorLeftPanel/ApplyingThemingDialog/ApplyingThemingDialog').ApplyingThemingDialog
    ], [
        require('../UnsupportedMessagesDialogs/dialogIds').UnSupportedDimensionDialogId,
        require('../UnsupportedMessagesDialogs/UnsupportedDimensionDialog').dialog
    ], [
        require('../UnsupportedMessagesDialogs/dialogIds').UpgradeBrowserDialogId,
        require('../UnsupportedMessagesDialogs/UpgradeBrowserDialog').dialog
    ], [
        require('../UnsupportedMessagesDialogs/dialogIds').NoTouchScreenSupportDialogId,
        require('../UnsupportedMessagesDialogs/NoTouchScreenSupport').dialog
    ], [
        require('../UnsupportedMessagesDialogs/dialogIds').UseAnotherBrowserDialogId,
        require('../UnsupportedMessagesDialogs/UseAnotherBrowser').dialog
    ], [
        require('../UnsupportedMessagesDialogs/dialogIds').DeprecatedBrowserDialogId,
        require('../UnsupportedMessagesDialogs/DeprecatedBrowserDialog').dialog
    ], [
        require('../Backup/dialogs/index').introDialogId,
        require('../Backup/dialogs/index').introDialog
    ], [
        require('../Backup/dialogs/index').restoreDialogId,
        require('../Backup/dialogs/index').restoreDialog
    ], [
        require('../Backup/dialogs/index').restoreSuccessDialogId,
        require('../Backup/dialogs/index').restoreSuccessDialog
    ], [
        require('../Backup/dialogs/index').restoreFailedDialogId,
        require('../Backup/dialogs/index').restoreFailedDialog
    ], [
        require('../Backup/dialogs/index').upgradeDialogId,
        require('../Backup/dialogs/index').upgradeDialog
    ], [
        require('../Backup/dialogs/index').loadErrorDialogId,
        require('../Backup/dialogs/index').loadErrorDialog
    ], [
        require('../TemplateSelector_DEPRECATED/view/dialogs/importErrorDialog').ImportErrorDialogId,
        require('../TemplateSelector_DEPRECATED/view/dialogs/importErrorDialog').ImportErrorDialog
    ], [
        require('../App/dialogs/appDialogIds').HighPageCountDialogId,
        require('../App/dialogs/HighPageCountWarning').default
    ], [
        require('../../view/common/dialogs/ImageEditorDialog').dialogId,
        require('../../view/common/dialogs/ImageEditorDialog').default
    ], [
        require('../../redux/middleware/api/errorHandler/commonDialog/dialogId').API_ERROR_HANDLER_DIALOG_ID,
        require('../../redux/middleware/api/errorHandler/commonDialog/dialog').default,
    ], [
        require(
            '../../redux/middleware/api/errorHandler/conflictDialog/dialogId'
        ).API_ERROR_HANDLER_CONFLICT_DIALOG_ID,
        require('../../redux/middleware/api/errorHandler/conflictDialog/dialog').default,
    ], [
        require(
            '../../../demo/modules/uploadLimitReachedDialog/dialogId'
        ).TRIAL_UPLOAD_LIMIT_REACHED_DIALOG_ID,
        require('../../../demo/modules/uploadLimitReachedDialog/dialog').default,
    ], [
        require('../FileManager/dialog/dialogId').FM_DIALOG_ID,
        require('../FileManager/dialog/dialog').fileManageDialog
    ], [
        require('../FileManager/shutterstock/components/dialogs/ids').SHUTTERSTOCK_QUOTA_LIMIT_DIALOG_ID,
        require('../FileManager/shutterstock/components/dialogs/quotaLimitDialog').shutterstockQuotaLimitDialog
    ], [
        require('../FileManager/shutterstock/components/dialogs/ids').SHUTTERSTOCK_UPLOADING_DIALOG_ID,
        require('../FileManager/shutterstock/components/dialogs/shutterstockUploadingDialog').shutterstockUploadingDialog
    ], [
        require('../FileManager/shutterstock/components/dialogs/ids').SHUTTERSTOCK_PARTIAL_DOWNLOADS_DIALOG_ID,
        require(
            '../FileManager/shutterstock/components/dialogs/shutterstockPartialDownloadDialog',
        ).shutterstockPartialDownloadDialog,
    ], [
        require('../Preview/View/dialog/dialogIds').DisableMobileOptionDialogId,
        require('../Preview/View/dialog/disableMobileOptionWarningDialog').disableMobileOptionWarningDialog
    ], [
        MVE_WELCOME_MODAL_DIALOG_ID,
        mveWelcomeModalDialog
    ], [
        require('../DetectTableWrap/dialog/id').default,
        require('../DetectTableWrap/dialog/index').default
    ], [
        require('../UserDataMigration/dialog/migrationFailedId').default,
        require('../UserDataMigration/dialog/migrationFailed').default
    ],
    [
        require('../UserDataMigration/dialog/migrationProlonged').UserDataMigrationProlongedDialogId,
        require('../UserDataMigration/dialog/migrationProlonged').default
    ], [
        require('../WhatsNew/dialog/dialogId').WHATS_NEW_DIALOG_ID,
        require('../WhatsNew/dialog').whatsNewDialogConfig,
    ], [
        require('../WhatsNew/dialog/WhatsNewPopupDialog').WHATS_NEW_POPUP_DIALOG_ID,
        require('../WhatsNew/dialog/WhatsNewPopupDialog').default,
    ], [
        require('../TopBar/view/Publish/PublishSitewit/PublishSiteWitDialogId').default,
        require('../TopBar/view/Publish/PublishSitewit/index').default
    ], [
        require('../DndFilesIntoWorkspace/dialogs/dialogIds').UploadFilesLimitExceedDialogId,
        require('../DndFilesIntoWorkspace/dialogs/UploadFilesLimitExceedDialog').default,
    ], [
        require('../DndFilesIntoWorkspace/dialogs/dialogIds').FilesUploadingDialogId,
        require('../DndFilesIntoWorkspace/dialogs/FilesUploadingDialog').default,
    ], [
        allInOneDialogId,
        allInDialog
    ], [
        sectionIntroDialogId,
        sectionIntroDialogConfig
    ], [
        require('../oneweb/WebShop/WebshopAdminDialog/dialogId').default,
        require('../oneweb/WebShop/WebshopAdminDialog/dialog').default
    ], [
        require('../oneweb/FeaturedProducts/SelectorDialog/dialogId').default,
        require('../oneweb/FeaturedProducts/SelectorDialog/dialog').default
    ], [
        require('../oneweb/ProductWidget/ProductSelectionDialog/dialogId').default,
        require('../oneweb/ProductWidget/ProductSelectionDialog/dialog').default
    ], [
        require('../oneweb/InstagramGallery/configurationDialog/configurationDialogId').default,
        require('../oneweb/InstagramGallery/configurationDialog/configurationDialog').default
    ], [
        require('../SocialAccounts/instagram/instagramDisconnectedDialog/instagramDisconnectedDialogId').default,
        require('../SocialAccounts/instagram/instagramDisconnectedDialog/instagramDisconnectedDialog').default
    ], [
        require('../oneweb/FacebookChat/configurationDialog/configurationDialogId').default,
        require('../oneweb/FacebookChat/configurationDialog/configurationDialog').default
    ], [
        require('../SocialAccounts/Facebook/FacebookChat/FacebookChatNoPagesDialog/FacebookChatNoPagesDialogId').default,
        require('../SocialAccounts/Facebook/FacebookChat/FacebookChatNoPagesDialog/FacebookChatNoPagesDialog').default
    ], [
        require('../SocialAccounts/Facebook/FacebookChat/FacebookChatMultiplePagesDialog/FacebookChatMultiplePagesDialogId').default,
        require('../SocialAccounts/Facebook/FacebookChat/FacebookChatMultiplePagesDialog/FacebookChatMultiplePagesDialog').default
    ], [
        require('../oneweb/FacebookChat/premiumComponentDialog/configurationDialogId').default,
        require('../oneweb/FacebookChat/premiumComponentDialog/configurationDialog').default
    ], [
        require('../oneweb/FacebookFeedGallery/configurationDialog/configurationDialogId').default,
        require('../oneweb/FacebookFeedGallery/configurationDialog/configurationDialog').default
    ], [
        require('../SocialAccounts/Facebook/FacebookFeed/FacebookMultiplePagesDialog/FacebookMultiplePagesDialogId').default,
        require('../SocialAccounts/Facebook/FacebookFeed/FacebookMultiplePagesDialog/FacebookMultiplePagesDialog').default
    ], [
        require('../SocialAccounts/Facebook/FacebookFeed/FacebookNoPagesDialog/FacebookNoPagesDialogId').default,
        require('../SocialAccounts/Facebook/FacebookFeed/FacebookNoPagesDialog/FacebookNoPagesDialog').default
    ], [
        require('../SocialAccounts/Facebook/FacebookFeed/FacebookFeedDisconnectedDialog/facebookFeedDisconnectedDialogId').default,
        require('../SocialAccounts/Facebook/FacebookFeed/FacebookFeedDisconnectedDialog/facebookFeedDisconnectedDialog').default
    ], [
        require('../oneweb/FacebookFeedGallery/premiumComponentDialog/configurationDialogId').default,
        require('../oneweb/FacebookFeedGallery/premiumComponentDialog/configurationDialog').default
    ], [
        require('../oneweb/InstagramGallery/premiumComponentDialog/configurationDialogId').default,
        require('../oneweb/InstagramGallery/premiumComponentDialog/configurationDialog').default
    ], [
        require('../TopBar/view/dialogIds').UpgradePlansDialogId,
        require('../TopBar/view/Upgrade/UpgradePlansDialog/index').default
    ], [
        require('../TopBar/view/dialogIds').UpgradeSuccessfulDialogId,
        require('../TopBar/view/Upgrade/UpgradeSuccessfulDialog/index').default
    ], [
        require('../oneweb/ProductWidget/OnboardingSuccessDialog/dialogId').default,
        require('../oneweb/ProductWidget/OnboardingSuccessDialog/dialog').default
    ], [
        require('../TopBar/view/dialogIds').UpgradeApproveDialogId,
        require('../TopBar/view/Upgrade/UpgradePlansDialog/AvailableSubscriptionTypes/UpgradeApproveDialog/UpgradeApproveDialog').default
    ], [
        require("../SiteSettings/chatWidgetTabData/ChatWidgetSetDialogId").default,
        require('../SiteSettings/chatWidgetTabData/ChatWidgetSetDialog').default
    ], [
        require("../SiteSettings/chatWidgetTabData/ChatWidgetDisconnectDialogId").default,
        require("../SiteSettings/chatWidgetTabData/ChatWidgetDisconnectDialog").default
    ], [
        require("../oneweb/Text/view/Dialog/TextCharacterLimitExceededDialogId").default,
        require("../oneweb/Text/view/Dialog/TextCharacterLimitExceededDialog").default
    ],
    ...GoogleReviewWidgetDialogRegistry,
    ...WidgetsDialogRegistry,
    [
        require("../SocialAccounts/Facebook/FacebookAppDisableModal/FacebookDisableInfo").FacebookDisableInfoDialogId,
        require("../SocialAccounts/Facebook/FacebookAppDisableModal/FacebookDisableInfo").dialog
    ],
    [
        require("../SocialAccounts/instagram/InstagramDisabledModal/InstagramDisabledModal").InstagramDisabledModalId,
        require("../SocialAccounts/instagram/InstagramDisabledModal/InstagramDisabledModal").dialog
    ],
    [
        require("../oneweb/Text/AIText/Dialogs/CreateAIText/CreateAITextDialog").CREATE_AI_TEXT_DIALOG_ID,
        require("../oneweb/Text/AIText/Dialogs/CreateAIText/CreateAITextDialog").config,
    ],
    [
        require("../oneweb/Text/AIText/Dialogs/EditAIText/EditAITextDialog").EDIT_AI_TEXT_DIALOG_ID,
        require("../oneweb/Text/AIText/Dialogs/EditAIText/EditAITextDialog").config,
    ],
    [
        require("../Onboarding/Dynamic/view/ErrorModels/ContinueToTemplatesDialog").CONTINUE_TO_TEMPLATES_DIALOG,
        require("../Onboarding/Dynamic/view/ErrorModels/ContinueToTemplatesDialog").config,
    ],
    [
        require("../Onboarding/Dynamic/view/ErrorModels/ContinueToATemplateDialog").CONTINUE_TO_A_TEMPLATE_DIALOG,
        require("../Onboarding/Dynamic/view/ErrorModels/ContinueToATemplateDialog").config,
    ]
];

const
    defaultReducer = makeDefaultStateReducer({}),
    registry: DialogManagerRegistry = dialogs.reduce((result, [id, config]) => {
        if (typeof id !== 'string') {
            throw Error(`${id} must be string`);
        }

        return {
            ...result,
            [id]: {
                id,
                ...config,
                reducer: config.reducer || defaultReducer
            }
        };
    }, {}),
    getDialogFromRegistry = (id: string): DialogManagerRegistryRecord => {
        const record = registry[id];
        if (!record) {
            throw new Error(`Dialog registry record with id ${id} not found`);
        }
        return record;
    };

export { getDialogFromRegistry };
export default registry;
