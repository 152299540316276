import { boxTop } from "./constants";
import type { Groups, Relations, Sequence } from "./flowTypes";
import { getTopLevelMobileDownCmps } from "./epics/getMdStartFromId";
import type { ComponentsMap } from "../../redux/modules/children/workspace/flowTypes";

const extractRelations = (seq, allRelations) => {
    let relations: any[] = [];
    allRelations.forEach((relationObj) => {
        const { id } = relationObj;
        if (seq.indexOf(id) !== -1) {
            relations.push(relationObj);
        }
    });
    return relations;
};

// approach1 => arranging based on relations
export const fixSequences = (seq: Array<string>, relations: Relations) => {
    let newSeq = [...seq];
    relations.forEach(({ id, top, bottom }) => {
        let index = newSeq.indexOf(id),
            topIndex = -1,
            bottomIndex = -1;
        if (top) {
            if (top === boxTop) {
                newSeq.splice(index, 1);
                newSeq.unshift(id);
            } else {
                topIndex = newSeq.indexOf(top);
                if (topIndex !== -1) {
                    newSeq.splice(index, 1);
                    if (index < topIndex) {
                        topIndex--;
                    }
                    newSeq.splice(topIndex + 1, 0, id);
                }
            }
        }
        if ((!top || (top !== boxTop && topIndex === -1)) && bottom) {
            index = newSeq.indexOf(id);
            bottomIndex = newSeq.indexOf(bottom);
            if (bottomIndex !== -1) {
                newSeq.splice(index, 1);
                if (index < bottomIndex) {
                    bottomIndex--;
                }
                newSeq.splice(bottomIndex, 0, id);
            }
        }
    });
    return newSeq;
};

export default (
    defaultSequence: Sequence,
    relations: Relations,
    componentsMap: ComponentsMap,
    templateId: string,
    groups: Groups
) => {
    let finalSeq = defaultSequence;

    if (relations && relations.length) {
        Object.keys(defaultSequence).forEach(cmpId => {
            let seqRelations: any[] = [], followDefaultSeq = false;
            if (cmpId === templateId) {
                const mobileDownCmps = getTopLevelMobileDownCmps(defaultSequence[cmpId], componentsMap, groups);
                if (mobileDownCmps.length) {
                    const otherCmps = defaultSequence[cmpId].filter(id => mobileDownCmps.indexOf(id) === -1);
                    seqRelations = extractRelations(otherCmps, relations);
                    const firstSeq = fixSequences(otherCmps, seqRelations);
                    seqRelations = extractRelations(mobileDownCmps, relations);
                    const secondSeq = fixSequences(mobileDownCmps, seqRelations);
                    finalSeq[cmpId] = firstSeq.concat(secondSeq);
                } else {
                    followDefaultSeq = true;
                }
            } else {
                followDefaultSeq = true;
            }
            if (followDefaultSeq) {
                seqRelations = extractRelations(defaultSequence[cmpId], relations);
                finalSeq[cmpId] = fixSequences(defaultSequence[cmpId], seqRelations);
            }
        });
    }
    return finalSeq;
};
