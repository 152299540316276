/* eslint-disable max-len */

import * as R from "ramda";
import {
    getNumRowsAndCols,
    getRowAndColumnForAddOrRemoveAction,
    fixRowAndColumnIndices,
    cloneCellWithEmptyText
} from "../utils";
import type { TableComponent } from "../flowTypes";

const insertCellsAndCopyStyles = ({ selectedCellColumnIndex }) => cells => {
        const
            updatedCells = [...cells],
            { numRows, numCols } = getNumRowsAndCols(cells),
            maxLoopIndex = numRows * (numCols + 1);

        for (let copyIndex = selectedCellColumnIndex; copyIndex < maxLoopIndex; copyIndex += numCols + 1) {
            updatedCells.splice(copyIndex + 1, 0, cloneCellWithEmptyText(updatedCells[copyIndex]));
        }

        return updatedCells;
    },
    fixCellsFlexWidth = ({ selectedCellColumnIndex }) => cells => {
        const newFlexWidthSum = 1 + cells[selectedCellColumnIndex].cellInfo.flexWidth;

        return cells.map(
            cell => R.evolve({
                cellInfo: {
                    flexWidth: (fw) => fw / newFlexWidthSum
                }
            }, cell)
        );
    };

export const addColumnReducer =
    (component: TableComponent, { payload }: AnyAction): TableComponent => {
        const
            { cells } = component,
            { numRows, numCols } = getNumRowsAndCols(cells),
            { columnIndex: selectedCellColumnIndex } =
                getRowAndColumnForAddOrRemoveAction(
                    { numRows, numCols },
                    payload.firstSelectedCellIndex
                ),
            { cellInfo: { flexWidth: firsSelectedCellFlexWidth } } = cells[selectedCellColumnIndex];

        return R.evolve({
            width: (width) => width * (1 + firsSelectedCellFlexWidth),
            cells: R.pipe(
                insertCellsAndCopyStyles({ selectedCellColumnIndex }),
                fixRowAndColumnIndices({ numRows, numCols: (numCols + 1) }),
                fixCellsFlexWidth({ selectedCellColumnIndex })
            )
        }, component);
    };
