import React from 'react';
import styles from './PageTemplateSelector.css';
import type { AppDispatch } from "../../../../redux/modules/flowTypes";
import { DataPageTemplateRef } from "../../../../../dal/model";
import { HoverBoxCom } from "../../../presentational/HoverBox";
import { parseIntDec } from "../../../../../utils/number";
import LongTextTip from '../../../../view/common/LongTextTip';
import { injectIntl, Intl } from "../../../../view/intl";
import {
    HoverBoxDuplicateBtnCom,
    HoverBoxModalBtnCom
} from "../../../presentational/HoverBoxControls";
import {
    selectPageTemplateAction,
    duplicatePageTemplateAction,
    deletePageTemplateAction, showPageTemplatePreviewAction, hidePageTemplatePreviewAction,
    selectPageTemplatePreviewAction
} from "../actions";

const
    CELL_SIZE = parseIntDec(styles.cellSize),
    PREVIEW_WIDTH = parseIntDec(styles.previewWidth),
    PREVIEW_HEIGHT = parseIntDec(styles.previewHeight),
    PAGES_STATS_MSG = 'msg: usedByNrPages {Used by {pagesCount, plural, one {1 page} other {{pagesCount} pages}}}';

type HoverBtnClickHandlerAc = (action: string) => Record<string, any>;

type Props = {
    template: DataPageTemplateRef,
    isSelected: boolean,
    isLoading: boolean,
    isProxy: boolean,
    onHold: boolean,
    showPreview: boolean,
    dispatch: AppDispatch,
    intl: Intl,
};

class PageTemplateSelectorCellClass extends React.Component<Props> {
    constructor(props) {
        super(props);

        this.onSelectClick = this.onSelectClick.bind(this);
        this.onDuplicateClick = this.onHoverBtnClickHandler.bind(this, duplicatePageTemplateAction);
        this.onDeleteClick = this.onHoverBtnClickHandler.bind(this, deletePageTemplateAction);
        this.onPreviewClick = this.onHoverBtnClickHandler.bind(this, showPageTemplatePreviewAction);
        this.onPreviewBackClick = this.onPreviewBackClick.bind(this);
        this.onPreviewSelectClick = this.onPreviewSelectClick.bind(this);
    }

    onSelectClick() {
        const { template: { id }, dispatch } = this.props;
        dispatch(selectPageTemplateAction(id));
    }

    // TODO: figure out what happens with flow here
    onDuplicateClick: any;
    onDeleteClick: any;
    onPreviewClick: any;

    onPreviewBackClick() {
        this.props.dispatch(hidePageTemplatePreviewAction());
    }

    onPreviewSelectClick() {
        const { template: { id }, dispatch } = this.props;
        dispatch(selectPageTemplatePreviewAction(id));
    }

    onHoverBtnClickHandler(actionCreator: HoverBtnClickHandlerAc, e: React.SyntheticEvent<any>) {
        e.stopPropagation();

        const { onHold, template: { id }, dispatch } = this.props;

        if (onHold) return;

        dispatch(actionCreator(id));
    }

    getPagesStatsMsg(): string {
        const
            { template, intl } = this.props,
            params = { pagesCount: template.pages.length };

        return intl.msgJoint([PAGES_STATS_MSG, params]);
    }

    render() {
        const
            template: DataPageTemplateRef = this.props.template,
            { isLoading, isProxy, isSelected, onHold, showPreview } = this.props,
            bgSrc = template.getPreviewUrl(CELL_SIZE, CELL_SIZE),
            bgShouldLoad = !isLoading && !isProxy,
            pagesStatsMsg = this.getPagesStatsMsg(),
            previewSrc = template.getPreviewUrl(PREVIEW_WIDTH, PREVIEW_HEIGHT);

        return (
            <HoverBoxCom
                bgSrc={bgSrc}
                bgShouldLoad={bgShouldLoad}
                selected={isSelected}
                onHold={onHold}
                theme={{
                    box: styles.template
                }}
                onClick={this.onSelectClick}
            >
                <header>
                    <LongTextTip className={styles.templateLabel}>{template.name}</LongTextTip>
                    <span>{pagesStatsMsg}</span>
                </header>
                <footer>
                    <HoverBoxDuplicateBtnCom
                        onClick={this.onDuplicateClick}
                    />
                    <HoverBoxModalBtnCom
                        show={showPreview}
                        title={template.name}
                        subTitle={pagesStatsMsg}
                        previewWidth={PREVIEW_WIDTH}
                        previewHeight={PREVIEW_HEIGHT}
                        src={previewSrc}
                        isSelected={isSelected}
                        onClick={this.onPreviewClick}
                        onBackClick={this.onPreviewBackClick}
                        onSelectClick={this.onPreviewSelectClick}
                    />
                </footer>
                {template.hasNoPages() && !isSelected && (
                    <span className={styles.deleteBtn} onClick={this.onDeleteClick} />
                )}
            </HoverBoxCom>
        );
    }
}

export const PageTemplateSelectorCellCom = injectIntl(PageTemplateSelectorCellClass);
