import * as R from "ramda";
import makeEpic from '../../../../epics/makeEpic';
import valueActionType from "./valueActionType";
import { fetchWebShopMHFData, fetchWebShopMHFDataOnPageLoad } from "./webShopMHFDataApi";
import { WEBSHOP_MHF_DATA_SUCCESS, WEBSHOP_MHF_DATA_FAILURE,
    POPULATE_WEBSHOP_FOOTER_CMPS_BACKUP_DATA } from "./actionTypes";
import { siteSettingsValueActionType } from "../../../App/epics/siteSettings/valueActionType";
import type { WebShopMHFEpicState } from "../flowTypes";
import { receiveOnly, optional } from "../../../../epics/makeCondition";
import { ReceiveOnlyComponentsMap } from "../../../Workspace/epics/componentsEval/selectorActionTypes";
import { ADD_REMOVE_WEBSHOP_STRIP } from "../../../ModernLayouts/actionTypes";
import { HIDE_WEBSHOP_ADMIN_DIALOG } from "../../WebShop/WebshopAdminDialog/actionTypes";
import { webShopMHFMockData } from "./webshopMHFMockData";
import { COMPONENTS_MAP_INITIALIZED } from "../../../Workspace/epics/componentsEval/actionTypes";

const webShopDefaultData = {
    policies: [
    ],
    paymentMethods: [
    ]
};

const defaultState: WebShopMHFEpicState = {
    data: { ...webShopDefaultData },
    privacyPolicyContent: "",
    showSpecialFooterStrip: false,
    isWebShopExistsInWebsite: false,
    isFetchSuccess: false,
    isPageLoad: null,
    webShopFooterCmpsBackupData: {}
};
/**
 * showSpecialFooterStrip will be true when
 * sitesettngs -> privacy policy -> content is true
 * OR data exist in shopinfo API (other than privacy policy)
 */

// const removeWebShopMHFDataAction = { type: REMOVE_WEBSHOP_MHF_DATA, amendToPrevious: true };
const addOrRemoveWebShopStripAction = { type: ADD_REMOVE_WEBSHOP_STRIP, amendToPrevious: true };

export default makeEpic({
    valueActionType,
    defaultState,
    updaters: [
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                optional(siteSettingsValueActionType),
                optional(COMPONENTS_MAP_INITIALIZED),
            ],
            reducer: ({ values: [cmpsMap, siteSettings], state, conditionActionType }) => {
                const { current: { featuredProductsPageIds, webshopPageIds, privacyPolicy }, loaded } = siteSettings;
                if (!loaded || !Object.keys(cmpsMap).length) {
                    return { state };
                }
                if (window.location && window.location.href && window.location.href.indexOf('mockWebshopMHF') !== -1) {
                    return {
                        state: {
                            ...state,
                            data: { ...webShopMHFMockData },
                            showSpecialFooterStrip: true,
                            amendToPrevious: true,
                            isPageLoad: false
                        },
                        actionToDispatch: addOrRemoveWebShopStripAction
                    };
                }
                const isWebShopExistsInWebsite = featuredProductsPageIds.length > 0 || webshopPageIds.length > 0;
                const privacyPolicyContent = privacyPolicy?.content;
                let isPageLoad;
                if (state.isPageLoad === null) {
                    isPageLoad = true;
                } else {
                    isPageLoad = isWebShopExistsInWebsite === state.isWebShopExistsInWebsite ? state.isPageLoad : false;
                }
                if (
                    state.isFetchSuccess &&
                    state.isPageLoad === isPageLoad &&
                    isWebShopExistsInWebsite === state.isWebShopExistsInWebsite
                ) {
                    return {
                        state,
                        actionToDispatch: addOrRemoveWebShopStripAction
                    };
                }
                let actionToDispatch;
                if (isWebShopExistsInWebsite) {
                    actionToDispatch = conditionActionType.actionType === COMPONENTS_MAP_INITIALIZED ?
                        fetchWebShopMHFDataOnPageLoad :
                        fetchWebShopMHFData;
                    return {
                        state: {
                            ...state,
                            isPageLoad,
                            isWebShopExistsInWebsite,
                            privacyPolicyContent
                        },
                        amendToPrevious: true,
                        actionToDispatch
                    };
                }
                actionToDispatch = addOrRemoveWebShopStripAction;
                return {
                    state: {
                        ...state,
                        data: { ...webShopDefaultData },
                        isWebShopExistsInWebsite,
                        privacyPolicyContent,
                        showSpecialFooterStrip: !!privacyPolicyContent,
                        isPageLoad
                    },
                    amendToPrevious: true,
                    actionToDispatch
                };
            }
        },
        {
            conditions: [
                receiveOnly(siteSettingsValueActionType),
                WEBSHOP_MHF_DATA_SUCCESS,
            ],
            reducer: ({ values: [siteSettings, mhfData], state }) => {
                const { current: { privacyPolicy } } = siteSettings;
                const privacyPolicyContent = privacyPolicy?.content;
                const filterOutPrivacyPolicy = R.reject(R.propEq('wsid', 'privacy-policy'));

                const data = R.ifElse(
                    R.always(privacyPolicyContent), // Check if privacyPolicyContent is truthy
                    R.over(R.lensProp('policies'), filterOutPrivacyPolicy),
                    R.identity // If false, return mhfData as is
                )(mhfData);

                const isWebShopDataEmpty = !data || !data.isShopPublished || !data.policies || !data.paymentMethods
                    || (!data.policies.length && !data.paymentMethods.length);

                const showSpecialFooterStrip = !isWebShopDataEmpty || privacyPolicyContent;
                const dataToPutInState = isWebShopDataEmpty ? { ...webShopDefaultData } : data;

                if (!isWebShopDataEmpty && state.isFetchSuccess && R.equals(dataToPutInState, state.data)) {
                    return { state };
                }
                return {
                    state: {
                        ...state,
                        data: dataToPutInState,
                        showSpecialFooterStrip,
                        isFetchSuccess: true
                    },
                    actionToDispatch: addOrRemoveWebShopStripAction
                };
            }
        },
        {
            conditions: [
                receiveOnly(siteSettingsValueActionType),
                WEBSHOP_MHF_DATA_FAILURE
            ],
            reducer: ({ values: [siteSettings], state }) => {
                const privacyPolicyContent = !!siteSettings?.current.privacyPolicy?.content;
                return {
                    state: {
                        ...state,
                        data: { ...webShopDefaultData },
                        showSpecialFooterStrip: !!privacyPolicyContent
                    },
                    actionToDispatch: addOrRemoveWebShopStripAction
                };
            }
        },
        {
            conditions: [
                receiveOnly(siteSettingsValueActionType),
                HIDE_WEBSHOP_ADMIN_DIALOG
            ],
            reducer: ({ values: [siteSettings], state }) => {
                const { current: { featuredProductsPageIds, webshopPageIds, privacyPolicy }, loaded } = siteSettings;
                if (!loaded) {
                    return { state };
                }
                const isWebShopExistsInWebsite = featuredProductsPageIds.length > 0 || webshopPageIds.length > 0;
                if (isWebShopExistsInWebsite) {
                    return {
                        state: {
                            ...state,
                        },
                        amendToPrevious: true,
                        actionToDispatch: fetchWebShopMHFData
                    };
                }
                const privacyPolicyContent = privacyPolicy?.content;
                return {
                    state: {
                        ...state,
                        data: { ...webShopDefaultData },
                        showSpecialFooterStrip: !!privacyPolicyContent
                    },
                };
            }
        },
        {
            conditions: [
                POPULATE_WEBSHOP_FOOTER_CMPS_BACKUP_DATA
            ],
            reducer: ({ values: [webShopFooterCmpsBackupData], state }) => {
                return {
                    state: {
                        ...state,
                        webShopFooterCmpsBackupData: {
                            ...state.webShopFooterCmpsBackupData,
                            ...webShopFooterCmpsBackupData
                        }
                    }
                };
            }
        },
    ]
});
