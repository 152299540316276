import * as actionTypes from "./actionTypes";
import CALL_API from '../../../../redux/middleware/api/CALL_API';
import type { ApiAction } from '../../../../redux/middleware/api/flowTypes';

export const fetchConnectedBusinessData = (): ApiAction => ({
    [CALL_API]: {
        types: [
            actionTypes.GOOGLE_MY_BUSINESS_FETCH_REQUEST,
            actionTypes.GOOGLE_MY_BUSINESS_FETCH_SUCCESS,
            actionTypes.GOOGLE_MY_BUSINESS_FETCH_FAILURE
        ],
        endpoint: 'getConnectedBusinessData'
    }
});
