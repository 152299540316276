/* global $ */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogTitleBox, StripTypes } from "../baseDialog/index";
import LoadingIndicator from '../../LoadingIndicator/index';
import * as styles from './ImageEditor.css';
import { MessageCodes, Messages, MessageTypes } from '../../../../components/ImageEditor/Messages';
import { updateImageEditorMessage } from '../../../../components/ImageEditor/actionCreators';
import Msg from "../../../intl/Msg";
import PrimaryButton from "../../Button/PrimaryButton";
import SecondaryButton from "../../Button/SecondaryButton";
import { conf as imageEditorConf, getLocalizationObject } from './imageEditorConf';
import { TooltipPosition } from '../../../../components/presentational/Tooltip/constants';
import { DemoTip } from '../../../../../demo/modules/tip/DemoTip';
import { loadImage } from "./utils";
import { HiddenEditedImageNameSuffix, editedImageNameSuffix } from "../../../../components/ImageEditor/constants";
import { Message, Props, MessageInfo, State } from './flowTypes';
import { EditMessage, headerHeight, imageEditorContainer, imageEditorWrapper, LoadingMessage } from './constants';
import ImageAsset from "./ImageAsset";
import ImageEditor from "./ImageEditor";
import {
    addToMyImagesClickedAC,
    closeImageEditorAC,
    dispatchImageEditorError,
    imageEditorRedoAC,
    imageEditorUndoAC,
    saveClickedAC,
    saveEditedImageAC
} from "./actionCreators";
import { decodeWebspaceUri } from '../../../../../dal/utils/webspaceUriTransformers';

class ImageEditorComponent extends PureComponent<Props, State> {
    titleRef: any;
    imageEditorInstance: null | ImageEditor;
    isOriginalImageMissing: boolean;

    constructor(props: Props) {
        super(props);
        this.titleRef = React.createRef();
        this.state = {
            editorLoaded: false,
            savingStatus: true,
            savingToMyImages: true,
        };

        this.imageEditorInstance = null;
        this.isOriginalImageMissing = false;
        this.onSaveClicked = this.onSaveClicked.bind(this);
        this.addToMyImages = this.addToMyImages.bind(this);
    }

    getImageEditorConfig(image: HTMLImageElement, format: string) {
        const
            {
                imageEditorConfig: { license },
                language,
                fonts,
                intl,
            } = this.props;
        return {
            ...imageEditorConf,
            language: language.toLowerCase() === 'de' ? language : 'en', // photoeditorsdk only support de and en
            license,
            container: $(`#${imageEditorContainer}`)[0],
            editor: {
                ...imageEditorConf.editor,
                image,
                export: {
                    ...imageEditorConf.editor.export,
                    format
                },
                controlsOptions: {
                    text: {
                        fonts
                    }
                }
            },
            extensions: {
                languages: {
                    en: getLocalizationObject(intl)
                }
            }
        };
    }

    setEditorLoaded() {
        this.setState({ editorLoaded: true, savingStatus: false, savingToMyImages: false });
    }

    componentDidMount() {
        const { asset, showInitError, undoDispatch, redoDispatch } = this.props,
            imageAsset = new ImageAsset(asset as any),
            hasSerializedData = imageAsset.hasSerializedData(),
            imgUrl = hasSerializedData ? imageAsset.getSerializedAssetUrl() : imageAsset.getAssetUrl();

        loadImage(imgUrl)
            .catch((err) => {
                // if the original image is failed to load bc it is missing
                // load the edited image
                if (hasSerializedData) {
                    this.isOriginalImageMissing = true;
                    return loadImage(imageAsset.getAssetUrl());
                }
                return Promise.reject(err);
            })
            .then((imageData: HTMLImageElement) => {
                const
                    imageEditorConfig = this.getImageEditorConfig(imageData, imageAsset.getContentType());

                this.imageEditorInstance = new ImageEditor(imageEditorConfig);
                this.imageEditorInstance.onReady(() => {
                    if (!this.isOriginalImageMissing && hasSerializedData && this.imageEditorInstance) {
                        this.imageEditorInstance
                            .deserialize(imageAsset.getSerializeData())
                            .finally(this.setEditorLoaded.bind(this));
                    } else {
                        this.setEditorLoaded();
                    }
                });
                this.imageEditorInstance.onUndo(undoDispatch);
                this.imageEditorInstance.onRedo(redoDispatch);
            })
            .catch(showInitError);
    }

    componentWillUnmount() {
        if (this.imageEditorInstance) this.imageEditorInstance.dispose();
    }

    getMessageInfo(message: Message): MessageInfo {
        const
            { code } = message,
            { intl } = this.props,
            messageInfo = Messages[code];
        return {
            type: messageInfo.type,
            text: intl.msgJoint(messageInfo.text)
        };
    }

    toggleAddToImagesState() {
        this.setState((state: State) => ({ savingToMyImages: !state.savingToMyImages }));
    }

    addToMyImages() {
        if (!this.imageEditorInstance) return;

        const
            { addToMyImagesClicked, showImageEditorError, asset } = this.props,
            forwardToSelectedComponent = false;

        this.toggleAddToImagesState();

        addToMyImagesClicked();

        this.imageEditorInstance.export()
            .then(this.saveToMyImages.bind(this, forwardToSelectedComponent, editedImageNameSuffix, asset))
            .catch(showImageEditorError)
            .finally(this.toggleAddToImagesState.bind(this));
    }

    saveToMyImages(forwardToSelectedComponent: boolean, fileNameSuffix: string, asset: Record<string, any>, blob: any) {
        const {
            replaceWithEditedImageAction,
            extraPayload,
            saveEditedImage
        } = this.props;

        saveEditedImage({
            asset,
            blob,
            replaceWithEditedImageAction,
            extraPayload,
            fileNameSuffix,
            forwardToSelectedComponent
        });
    }

    onSaveClicked() {
        if (this.imageEditorInstance) {
            this.setState({ savingStatus: true });

            const { dispatchSaveClicked, showImageEditorError } = this.props;

            dispatchSaveClicked();

            this.imageEditorInstance.export()
                .then(this.saveSerializeEditedImage.bind(this))
                .catch(showImageEditorError);
        }
    }

    saveSerializeEditedImage(blob: any) {
        let asset = {
            ...this.props.asset
        };

        if (this.isOriginalImageMissing && asset.data) {
            delete asset.data;
        }

        const imageAsset = new ImageAsset(asset as any);

        if (this.imageEditorInstance) {
            this.imageEditorInstance.serialize({ image: false })
                .then((data) => {
                    this.saveToMyImages(
                        true,
                        HiddenEditedImageNameSuffix,
                        imageAsset.mergeAssetWithSerializedData(data),
                        blob
                    );
                })
                .catch(this.props.showImageEditorError);
        }
    }

    render() {
        const
            { intl, state: { message }, closeImageEditor } = this.props,
            { editorLoaded } = this.state,
            height = this.props.dimensions.height - headerHeight,
            showMessage = editorLoaded && message,
            messageInfo = showMessage ? this.getMessageInfo(message) : null;
        return (
            <Dialog
                stripType={StripTypes.NONE}
                onClose={closeImageEditor}
                className={styles.imageEditorDialog}
                closeBtnClassName={styles.imageEditorCloseBtn}
            >
                <div ref={this.titleRef}>
                    <DialogTitleBox
                        title={EditMessage}
                        titleClass={styles.imageEditorTitle}
                    >
                        { /* @ts-ignore */ }
                        {editorLoaded && <div className={styles.imageEditorBtnWrapper}>
                            { /* @ts-ignore */ }
                            <DemoTip position={TooltipPosition.BOTTOM}>
                                <SecondaryButton
                                    onClick={this.addToMyImages}
                                    disabled={this.state.savingToMyImages}
                                    className={styles.downloadBtn}
                                >
                                    <Msg k="imageEditor.addToMyImages">Add to my images</Msg>
                                </SecondaryButton>
                                <PrimaryButton
                                    onClick={this.onSaveClicked}
                                    disabled={this.state.savingStatus}
                                >
                                    <Msg k="common.save">Save</Msg>
                                </PrimaryButton>
                            </DemoTip>
                        </div>}
                    </DialogTitleBox>
                </div>
                <div id={imageEditorWrapper} className={styles.imageEditorWrapperCls} style={{ height: `${height}px` }}>
                    <div
                        key={`${imageEditorContainer}-key`}
                        id={imageEditorContainer}
                        className={styles.imageEditorContainerCls}
                    />
                    {
                        !editorLoaded &&
                        <div className={styles.messageContainer}>
                            <div className={styles.message}>
                                <LoadingIndicator key="loadingIndicator" />
                                <div className={styles.loadingMessage}>
                                    {intl.msgJoint(LoadingMessage)}
                                </div>
                            </div>
                        </div>
                    }
                    {
                        showMessage && messageInfo && messageInfo.type === MessageTypes.INFO &&
                        <div className={styles.messageContainer}>
                            <div className={styles.message}>
                                <LoadingIndicator key="loadingIndicator" />
                                <div className={styles.messageText}>
                                    {messageInfo.text}
                                    <span className={styles.messageSuffix}>
                                        {(message && message.suffix) ? (' ' +
                                            decodeWebspaceUri(message.suffix)) : ''}
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        showMessage && messageInfo && messageInfo.type === MessageTypes.ERROR &&
                        <div className={styles.messageContainer}>
                            <div className={`${styles.message} ${styles.errorMessage}`}>
                                <div>{messageInfo.text}</div>
                            </div>
                        </div>
                    }
                </div>
            </Dialog>
        );
    }
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
    showImageEditorError: () => dispatch(dispatchImageEditorError()),
    showInitError: () => dispatch(updateImageEditorMessage(MessageCodes.GENERAL_ERROR)),
    dispatchSaveClicked: () => dispatch(saveClickedAC()),
    closeImageEditor: () => dispatch(closeImageEditorAC()),
    saveEditedImage: (payload: Object) => dispatch(saveEditedImageAC(payload)),
    undoDispatch: () => dispatch(imageEditorUndoAC()),
    redoDispatch: () => dispatch(imageEditorRedoAC()),
    addToMyImagesClicked: () => dispatch(addToMyImagesClickedAC())
});
export default connect(null, mapDispatchToProps)(ImageEditorComponent);
