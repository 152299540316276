import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useIntl } from "../../../../../../wbtgen/src/view/intl/injectIntl";

import { SettingsSectionInfo } from '../../SettingsControls';
import { InfoSectionConfig } from './constants';
import { googleAdsCampaignEnabledSelector } from '../../../common/selectors';

export const GoogleAdsSettingsInfo = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const isCampaignEnabled = useSelector(googleAdsCampaignEnabledSelector);

    return (
        <SettingsSectionInfo
            iconClassname={InfoSectionConfig.iconClass(isCampaignEnabled)}
            label={intl.msgJoint(InfoSectionConfig.header)}
            description={intl.msgJoint(InfoSectionConfig.description)}
            link={{
                label: intl.msgJoint(InfoSectionConfig.learMoreActionLink.label),
                url: intl.msgJoint(InfoSectionConfig.learMoreActionLink.url),
                clickHandler: () => dispatch(InfoSectionConfig.learMoreActionLink.action)
            }}
        />
    );
};
