/* eslint-disable max-len */

import { TemplateCategory } from "./constants";

export const
    TemplateCategoryName = {
        [TemplateCategory.BUSINESS_AND_SERVICES]: 'msg: web.ui.TemplateCategories.List.business_and_services {Business & Services}',
        [TemplateCategory.PORTFOLIOS]: 'msg: web.ui.TemplateCategories.List.portfolios {Portfolio & CV}',
        [TemplateCategory.FAMILY_AND_RECREATION]: 'msg: web.ui.TemplateCategories.List.family_and_recreation {Family & Recreation}',
        [TemplateCategory.FOOD_AND_HOSPITALITY]: 'msg: web.ui.TemplateCategories.List.food_and_hospitality {Food & Hospitality}',
        [TemplateCategory.EVENTS]: 'msg: web.ui.TemplateCategories.List.events {Events}',
        [TemplateCategory.MUSIC]: 'msg: web.ui.TemplateCategories.List.music {Music & Art}',
        [TemplateCategory.ONLINE_STORE]: 'msg: web.ui.TemplateCategories.List.online_store {Online Shop}',
        [TemplateCategory.ONE_PAGE]: 'msg: web.ui.TemplateCategories.List.onePage {One page}',
        [TemplateCategory.NEW]: 'msg: web.ui.TemplateCategories.List.new {New}',
    },
    PREMIUM_TEMPLATE_CATEGORY_NAME = 'msg: common.premium {Premium}';
