import React from 'react';

import { TextHorizontalAlignmentButtonsGroup } from '../../../../presentational/IconButtonsGroup/TextAlignmentButtonGroup/index'; // eslint-disable-line max-len

import { Flex } from '../../../../../view/reflexbox/index';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';

import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import PropertiesCheckBox from '../../../../../view/common/CheckBox/PropertiesCheckBox';
import PropertyContainer from '../../../../../view/common/PropertyContainer/index';

import * as pagesIds from '../pagesIds';
import pagesTitles from '../pagesTitles';
import * as actionTypes from '../../actionTypes';

import type { Props } from '../flowTypes';
import NavigationGroup from '../../../../PropertiesPanel/view/IntlNavigationGroup';
import CaptionStyle from '../../../../presentational/CaptionStyle/index';
import { CaptionStyles } from '../../../../presentational/CaptionStyle/constants';

const
    id = pagesIds.CAPTIONS,
    title = pagesTitles[id],
    view = (props: Props) => {
        const { selectedComponent: { captionsEnabled, captionsAlignment, captionStyle }, dispatch } = props;
        return (
            <Page>
                <PropertiesCheckBox
                    isChecked={captionsEnabled}
                    label="msg: component.gallery.propPanel.thumbnails.showCaptions {Show captions}"
                    onClick={() => dispatch({ type: actionTypes.FACEBOOK_FEED_GALLERY_CAPTIONS_ENABLED_CHECKBOX_CLICKED })}
                />
                {
                    captionsEnabled &&
                    <div>
                        <VerticalSpacer />
                        <PropertyContainer
                            label="msg: component.gallery.propPanel.thumbnails.captionsAlignment {Caption text alignment}"
                            disabled={!captionsEnabled}
                        >
                            <Flex>
                                <TextHorizontalAlignmentButtonsGroup
                                    value={captionsAlignment}
                                    onBtnClick={alignment => dispatch({
                                        type: actionTypes.FACEBOOK_FEED_GALLERY_CAPTIONS_ALIGN_CHANGED,
                                        payload: alignment
                                    })}
                                    disabled={!captionsEnabled}
                                    isPropertiesPanel
                                />
                            </Flex>
                        </PropertyContainer>
                        <VerticalSpacer y={10} />
                        <CaptionStyle
                            value={captionStyle}
                            onChangeAction={actionTypes.FACEBOOK_FEED_GALLERY_PP_CAPTIONS_STYLE}
                            dispatch={dispatch}
                        />
                        {
                            captionStyle === CaptionStyles.CUSTOM &&
                            <VerticalSpacer y={14} />
                        }
                        {
                            captionStyle === CaptionStyles.CUSTOM &&
                            <NavigationGroup
                                {...props}
                                pagesTitles={pagesTitles}
                                targetPagesIds={[
                                    pagesIds.CAPTIONS_DESCRIPTION_STYLE,
                                    pagesIds.CAPTIONS_BOX_STYLE,
                                ]}
                            />
                        }
                    </div>
                }
            </Page>
        );
    };

export { id, view, title };
