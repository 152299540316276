import * as React from 'react';
import styles from './Shutterstock.css';
import shutterstockDownloadStyles from './ShutterstockDownloads.css';
import LoadingIndicator from '../../../../view/common/LoadingIndicator/index';
import { ShutterstockGrid } from './ShutterstockGrid';
import { ShutterstockDownloads } from './ShutterstockDownloads';
import { ImagesCategories } from './ImagesCategories';
import { ImagesSearch } from './ImagesSearch';
import { isDemoSubscriptionType } from '../../../App/epics/subscriptionData/isDemoSubscriptionType';
import { isNormalSubscription, isPremiumSubscription } from "../../../App/epics/subscriptionData/utils";
import type { SstockQuotaConfig } from '../../../../../../server/shared/config/types';
import type { ShutterstockKindT, ShutterstockState } from '../types';
import { ThirdPartyImageKind } from '../../unsplash/components/constants';

type Props = {
    kind: ShutterstockKindT,
    state: ShutterstockState,
    isMultiSelect?: boolean,
    subscriptionType: string,
    quotaConf?: SstockQuotaConfig,
    dispatch: Dispatch,
    cellChildren?: React.ReactNode,
    getCellChildren?: Function,
    showDownloadBanners?: boolean,
};

export class Shutterstock extends React.Component<Props> {
    render() {
        const
            {
                kind,
                state: {
                    isTypeImages,
                    isTypeIllustrations,
                    isPortraitMode,
                    isLandscapeMode,
                    categories,
                    images,
                    imagesLoading,
                    imagesPageLoading,
                    selectedImages,
                    downloads,
                    downloadsLoading,
                    gridScrollTop,
                    filters: {
                        category,
                        search,
                    },
                    emptySearchResults,
                    showPremiumDownloadsMsg,
                    categoriesLoading
                },
                subscriptionType,
                isMultiSelect,
                quotaConf,
                dispatch,
                cellChildren,
                getCellChildren = () => cellChildren,
                showDownloadBanners,
            } = this.props,
            showSearchPaneBorder = isPremiumSubscription(subscriptionType) && !showPremiumDownloadsMsg,
            isDemo = isDemoSubscriptionType(subscriptionType);
        let lockImages = isNormalSubscription(subscriptionType) && quotaConf && quotaConf.standard === downloads.length;
        if (ThirdPartyImageKind.UNSPLASH === kind && (isNormalSubscription(subscriptionType))) {
            lockImages = true;
        }

        return (
            <div className={styles.container}>
                <ImagesSearch
                    kind={kind}
                    search={search}
                    isTypeImages={isTypeImages}
                    isTypeIllustrations={isTypeIllustrations}
                    isPortraitMode={isPortraitMode}
                    isLandscapeMode={isLandscapeMode}
                    showBorder={showSearchPaneBorder}
                    dispatch={dispatch}
                    selectedCategoryId={category}
                />
                {showDownloadBanners && (
                    downloadsLoading && isNormalSubscription(subscriptionType)
                        ? <LoadingIndicator className={shutterstockDownloadStyles.subscriptionHeaderWrapper} />
                        : quotaConf && (
                            <ShutterstockDownloads
                                kind={kind}
                                numberOfDownload={downloads.length}
                                subscriptionType={subscriptionType}
                                quota={quotaConf.standard}
                                showUpgradeMsg={showPremiumDownloadsMsg}
                            />
                        )
                )}
                <div className={styles.imagesContainer}>
                    <ImagesCategories
                        kind={kind}
                        categories={categories}
                        selectedCategoryId={category}
                        categoriesLoading={categoriesLoading}
                        dispatch={dispatch}
                    />
                    <ShutterstockGrid
                        kind={kind}
                        getCellChildren={getCellChildren}
                        images={images}
                        isLoading={imagesLoading || downloadsLoading}
                        imagesPageLoading={imagesPageLoading}
                        isMultiSelect={isMultiSelect}
                        selectedImages={selectedImages}
                        gridScrollTop={gridScrollTop}
                        selectedCategoryId={category}
                        searchTerm={search.value}
                        downloads={downloads}
                        lockImages={!!lockImages}
                        emptySearchResults={emptySearchResults}
                        isDemo={isDemo}
                        dispatch={dispatch}
                    />
                </div>
            </div>
        );
    }
}
