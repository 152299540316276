import { getCmps } from "../Workspace/epics/componentAttachements/util";
import isStretchComponentKind from "../oneweb/isStretchComponentKind";
import type { ComponentsMap } from "../../redux/modules/children/workspace/flowTypes";

export default (cmpsMap: ComponentsMap, templateWidth: number) => {
    const cmps = getCmps(cmpsMap).filter(({ kind, stretch = false }) => isStretchComponentKind(kind, stretch));
    let changed = false, newCmpsMap = { ...cmpsMap };
    cmps.forEach(cmp => {
        const { id, left, width } = cmp;
        if ((left + width) < templateWidth) {
            changed = true;
            newCmpsMap[id] = {
                ...newCmpsMap[id],
                width: templateWidth + Math.abs(left) + 20// 10px extra on each side -left and right
            };
        }
    });
    return changed ? newCmpsMap : cmpsMap;
};
