import {
    ACTIVATE_MODERN_FOOTER,
    ACTIVATE_MODERN_HEADER,
    DEACTIVATE_MODERN_FOOTER,
    DEACTIVATE_MODERN_HEADER,
    MODERN_FOOTER_ACTIVATED,
    MODERN_FOOTER_ACTIVATED_NEW_CMPS_ADDED, MODERN_FOOTER_DEACTIVATED,
    MODERN_FOOTER_DEACTIVATED_OLD_CMPS_RESTORED,
    MODERN_HEADER_ACTIVATED,
    MODERN_HEADER_ACTIVATED_NEW_CMPS_ADDED,
    MODERN_HEADER_DEACTIVATED,
    MODERN_HEADER_DEACTIVATED_OLD_CMPS_RESTORED,
} from "./actionTypes";
import type { Box } from './flowTypes';
import { MENU, TEXT, EMAIL_KIND, PHONE_KIND,
    ADDRESS_KIND, SOCIAL_KIND, BUTTON,
    WEBSHOP_PAYMENT_METHODS, WEBSHOP_POLICIES } from "../oneweb/componentKinds";
import { topColGapInMHF } from '../Preview/View/PreviewConstants.css';

export const headerFooterTitle = 'msg: modernLayout.onBoardingPanel.welcomeTitle {Header & footer layouts}',
    welcomeSubTitle = 'msg: modernLayout.onBoardingPanel.welcomeSubTitle {Get modern and responsive layouts for your header and footer to make them look good on any screen size.}'; //eslint-disable-line

export const sectionNames = {
    header: 'header',
    footer: 'footer'
};
export const modernLayoutComponentKindsWithoutPP: Record<string, boolean> = {
    [MENU]: true,
    [TEXT]: true,
    [EMAIL_KIND]: true,
    [PHONE_KIND]: true,
    [ADDRESS_KIND]: true,
};

export const expandableCmpKindInMHF = {
    [MENU]: true,
    [BUTTON]: true,
    [ADDRESS_KIND]: true,
    [PHONE_KIND]: true,
    [EMAIL_KIND]: true,
    [WEBSHOP_PAYMENT_METHODS]: true,
    [WEBSHOP_POLICIES]: true,
};

export const modernLayoutComponentKindsExceptionPP: Record<string, string> = {
    [SOCIAL_KIND]: 'LINKS',
};

export const MHFADActionTypes = {
    header: {
        activate: {
            srcAction: ACTIVATE_MODERN_HEADER,
            newCmpsAddedActionType: MODERN_HEADER_ACTIVATED_NEW_CMPS_ADDED,
            activatedActionType: MODERN_HEADER_ACTIVATED,
        },
        deactivate: {
            srcAction: DEACTIVATE_MODERN_HEADER,
            oldCmpsRestoredActionType: MODERN_HEADER_DEACTIVATED_OLD_CMPS_RESTORED,
            deactivatedActionType: MODERN_HEADER_DEACTIVATED
        }
    },
    footer: {
        activate: {
            srcAction: ACTIVATE_MODERN_FOOTER,
            newCmpsAddedActionType: MODERN_FOOTER_ACTIVATED_NEW_CMPS_ADDED,
            activatedActionType: MODERN_FOOTER_ACTIVATED
        },
        deactivate: {
            srcAction: DEACTIVATE_MODERN_FOOTER,
            oldCmpsRestoredActionType: MODERN_FOOTER_DEACTIVATED_OLD_CMPS_RESTORED,
            deactivatedActionType: MODERN_FOOTER_DEACTIVATED
        }
    }
};

export const MHFCurrentActionSteps = {
    ACTIVATE_LAYOUT_STEP: 'ACTIVATE_LAYOUT_STEP',
    SWITCH_LAYOUT_STEP: 'SWITCH_LAYOUT_STEP',
    DEACTIVATE_LAYOUT_STEP: 'DEACTIVATE_LAYOUT_STEP',
    RESTORE_LAYOUT_STEP: 'RESTORE_LAYOUT_STEP',
};

export const HEADER_FOOTER_LAYOUT_STEP = 'HEADER_FOOTER_LAYOUT_STEP',
    SELECT_HEADER_LAYOUT_STEP = 'SELECT_HEADER_LAYOUT_STEP',
    TOGGLE_HEADER_LAYOUT_STEP = 'TOGGLE_HEADER_LAYOUT_STEP',
    SAVING_HEADER_LAYOUT_STEP = 'SAVING_HEADER_LAYOUT_STEP',
    SELECT_FOOTER_LAYOUT_STEP = 'SELECT_FOOTER_LAYOUT_STEP',
    TOGGLE_FOOTER_LAYOUT_STEP = 'TOGGLE_FOOTER_LAYOUT_STEP',
    STEP_NUM_0 = 0,
    STEP_NUM_1 = 1,
    STEP_NUM_2 = 2,
    STEP_NUM_3 = 3,
    STEP_NUM_4 = 4,
    STEP_NUM_5 = 5;

export const
    ONBOARDING_STEPS = {
        [STEP_NUM_0]: HEADER_FOOTER_LAYOUT_STEP,
        [STEP_NUM_1]: SELECT_HEADER_LAYOUT_STEP,
        [STEP_NUM_2]: TOGGLE_HEADER_LAYOUT_STEP,
        [STEP_NUM_3]: SAVING_HEADER_LAYOUT_STEP,
        [STEP_NUM_4]: SELECT_FOOTER_LAYOUT_STEP,
        [STEP_NUM_5]: TOGGLE_FOOTER_LAYOUT_STEP
    };

export const
    RESTORE_LAYOUT_TITLES = {
        title: headerFooterTitle,
        subTitle: welcomeSubTitle
    };

export const
    ONBOARDING_TITLES = {
        [HEADER_FOOTER_LAYOUT_STEP]: headerFooterTitle,
        [SELECT_HEADER_LAYOUT_STEP]: 'msg: modernLayout.onBoardingPanel.selectHeaderTitle {Select header layout}',
        [TOGGLE_HEADER_LAYOUT_STEP]: 'msg: modernLayout.onBoardingPanel.defineHeaderTitle {Define header components}',
        [SAVING_HEADER_LAYOUT_STEP]: 'msg: modernLayout.onBoardingPanel.savingHeaderTitle {Saving header layout}',
        [SELECT_FOOTER_LAYOUT_STEP]: 'msg: modernLayout.onBoardingPanel.selectFooterTitle {Select footer layout}',
        [TOGGLE_FOOTER_LAYOUT_STEP]: 'msg: modernLayout.onBoardingPanel.defineFooterTitle {Define footer components}',
    };

export const
    ONBOARDING_SUB_TITLES = {
        [SELECT_HEADER_LAYOUT_STEP]: 'msg: modernLayout.onBoardingPanel.selectHeaderSubTitle {Select a layout for your header. You can define the components you want for it afterwards.}', //eslint-disable-line
        [TOGGLE_HEADER_LAYOUT_STEP]: 'msg: modernLayout.onBoardingPanel.defineHeaderSubTitle {Define which components you want to be shown in your header. You can always make changes later.}', //eslint-disable-line
        [SELECT_FOOTER_LAYOUT_STEP]: 'msg: modernLayout.onBoardingPanel.selectFooterSubTitle {Select a layout for your footer. You can define the components you want for it afterwards.}', //eslint-disable-line
        [TOGGLE_FOOTER_LAYOUT_STEP]: 'msg: modernLayout.onBoardingPanel.defineFooterSubTitle {Define which components you want to be shown in your footer. You can always make changes later.}', //eslint-disable-line
    };

export const
    ONBOARDING_STEPS_TOTAL_COUNT = 4,
    ONBOARDING_STEP_NUMBERS = {
        [SELECT_HEADER_LAYOUT_STEP]: 1,
        [TOGGLE_HEADER_LAYOUT_STEP]: 2,
        [SELECT_FOOTER_LAYOUT_STEP]: 3,
        [TOGGLE_FOOTER_LAYOUT_STEP]: 4,
    },
    HIDE_FOOTER_IN_ONBOARDING_PANEL = {
        [HEADER_FOOTER_LAYOUT_STEP]: true,
        [SAVING_HEADER_LAYOUT_STEP]: true,
    },
    LAST_STEP_IN_ONBOARDING_PANEL = {
        [TOGGLE_FOOTER_LAYOUT_STEP]: true
    };

export const RowEdgeGapInPercent = 5,
    responsiveRowPadding = 30,
    MinGapBtwCols = parseInt(topColGapInMHF, 10) || 70,
    minTemplateOffsetX = 50,
    minMHFSectionWidth = 500,
    V_ALIGN = {
        middle: 'middle',
        top: 'top',
        bottom: 'bottom'
    },
    H_ALIGN = {
        center: 'center',
        left: 'left',
        right: 'right'
    };

export const defaultMargin: Box = Object.freeze({ top: 0, left: 0, right: 0, bottom: 0 });

export const maxMHFWidth = 2000;

export const invalidMHFStateReasons = {
    MODERN_LAYOUT_ACTIVE_NOT_IN_SYNC: 'MODERN_LAYOUT_ACTIVE_NOT_IN_SYNC',
    MODERN_LAYOUT_COMPONENT_NOT_IN_TEMPLATE: 'MODERN_LAYOUT_COMPONENT_NOT_IN_TEMPLATE',
    MODERN_LAYOUT_COMPONENT_IS_ON_AND_NOT_AVAILABLE: 'MODERN_LAYOUT_COMPONENT_IS_ON_AND_NOT_AVAILABLE'
};
