import makeEpic from '../../../../../epics/makeEpic';
import { COMPONENT_CONFIGURATION_COMPLETE } from "../../../../../redux/modules/children/workspace/actionTypes";
import { makeActionForwardToSelectedComponent } from '../../../../../redux/forwardTo';
import valueActionType from './valueActionType';
import {
    PRODUCT_WIDGET_SET_ITEM,
    PRODUCT_WIDGET_UPDATE_PRODUCT,
    SHOW_PRODUCT_WIDGET_PRODUCT_SELECTOR_DIALOG,
    HIDE_PRODUCT_WIDGET_PRODUCT_SELECTOR_DIALOG,
    PRODUCT_WIDGET_CTA_CLICKED
} from '../../actionTypes';
import {
    FEATURED_PRODUCTS_UPDATE_PRODUCTS_PROPERTY,
    FEATURED_PRODUCTS_UPDATE_TIMESTAMP
} from '../../../FeaturedProducts/actionTypes';
import { openDialog, closeDialog } from '../../../../App/actionCreators/index';
import ProductWidgetSelectorDialogId from '../../ProductSelectionDialog/dialogId';
import kind from '../../kind';
import { ReceiveOnlySelectedComponentSelector } from "../../../../Workspace/epics/componentsEval/selectorActionTypes";

const defaultState = {
    products: ['']
};

const addComponentAction = products => ({ type: COMPONENT_CONFIGURATION_COMPLETE, payload: { products } });
const updateComponentAction = products => ({ type: PRODUCT_WIDGET_UPDATE_PRODUCT, payload: { products } });
const replaceProductsAction = (selectedComponent) => {
    let products = [];
    if (selectedComponent && selectedComponent.kind === kind && selectedComponent.products) {
        products = selectedComponent.products.split(',')
            .map((productEntry) => {
                return productEntry
                    .replace('product:', '')
                    .replace(/'/g, '')
                    .trim();
            });
    }
    return {
        type: SHOW_PRODUCT_WIDGET_PRODUCT_SELECTOR_DIALOG,
        payload: {
            products,
            isWebshopSetupDone: selectedComponent.isWebshopSetupDone,
            componentId: selectedComponent.id
        }
    };
};

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [PRODUCT_WIDGET_SET_ITEM],
            reducer: ({ values: [{ isUpdate, products }] }) => {
                let actionToDispatch: AnyAction = isUpdate ?
                    makeActionForwardToSelectedComponent(updateComponentAction(products)) :
                    addComponentAction(products);
                return {
                    state: defaultState,
                    multipleActionsToDispatch: [
                        { type: FEATURED_PRODUCTS_UPDATE_TIMESTAMP, payload: { timestamp: Date.now() } },
                        actionToDispatch
                    ]
                };
            }
        }, {
            conditions: [PRODUCT_WIDGET_SET_ITEM],
            reducer: () => ({ state: defaultState, actionToDispatch: closeDialog() })
        }, {
            conditions: [SHOW_PRODUCT_WIDGET_PRODUCT_SELECTOR_DIALOG],
            reducer: ({ state, values: [{ products, isWebshopSetupDone, componentId }] }) => {
                return ({
                    state,
                    actionToDispatch: openDialog(
                        ProductWidgetSelectorDialogId,
                        {
                            products,
                            isWebshopSetupDone,
                            componentId
                        }
                    )
                });
            }
        }, {
            conditions: [HIDE_PRODUCT_WIDGET_PRODUCT_SELECTOR_DIALOG],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state
                    },
                    actionToDispatch: closeDialog()
                };
            }
        },
        {
            conditions: [
                ReceiveOnlySelectedComponentSelector,
                PRODUCT_WIDGET_CTA_CLICKED
            ],
            reducer: ({ state, values: [selectedComponent] }) => {
                return { state, actionToDispatch: replaceProductsAction(selectedComponent) };
            }
        },
        {
            conditions: [
                ReceiveOnlySelectedComponentSelector,
                PRODUCT_WIDGET_UPDATE_PRODUCT
            ],
            reducer: ({ state, values: [selectedComponent, { products }] }) => {
                if (selectedComponent && selectedComponent.kind === kind) {
                    const actionToDispatch = {
                        type: FEATURED_PRODUCTS_UPDATE_PRODUCTS_PROPERTY,
                        payload: { componentId: selectedComponent.id, products }
                    };
                    return {
                        state,
                        actionToDispatch
                    };
                }
                return { state: null };
            }
        }
    ]
});
