import * as R from 'ramda';
import type { AnyComponent, AnyComponentRenderProps } from '../oneweb/flowTypes';
import type { ComponentsMap } from "../../redux/modules/children/workspace/flowTypes";
import type { Path } from '../../mappers/path';
import type { AppState } from '../../redux/modules/flowTypes';
import { getComponentsMap } from "./epics/componentsEval/getters";
import { makeEpicStateSelector } from "../../epics/makeEpic";
import { ComponentsEvalValueActionType } from "./epics/componentsEval/valueActionType";
import { someComponentsPlacedInModernSection } from "../ModernLayouts/utils";
import { someComponentsPlacedInWebShopFooterStrip } from '../ModernLayouts/layoutsData/webshopMHFDataUtils';

type ComponentSelector = (componentsMap: ComponentsMap) => AnyComponent;

const
    nKeySelector = index => R.pipe(R.keys, R.drop(index), R.take(1)),
    makeNEntitySelector = index => mapSelector => R.pipe(
        mapSelector,
        componentsMap => componentsMap[nKeySelector(index)(componentsMap)]
    ),
    makeFirstEntitySelector = makeNEntitySelector(0),
    makeSecondEntitySelector = makeNEntitySelector(1);

export const
    fromAppStateSelector = R.path(['selectedWorkspace']),
    componentsMapSelector = R.path(['components', 'componentsMap']),
    workspaceComponentsMapSelector = R.pipe(fromAppStateSelector, R.path(['components', 'componentsMap'])),
    componentsRenderPropsSelector = R.path(['componentsProps']),
    lastKeySelector = R.pipe(R.keys, R.last),
    firstComponentSelector = makeFirstEntitySelector(componentsMapSelector),
    firstComponentRenderPropsSelector = makeFirstEntitySelector(componentsRenderPropsSelector),
    secordComponentRenderPropsSelector = makeSecondEntitySelector(componentsRenderPropsSelector),
    lastComponentSelector = R.pipe( // TODO remove selectors that used only in tests WBTGEN-3064
        componentsMapSelector,
        componentsMap => componentsMap[lastKeySelector(componentsMap)]
    ),
    fromFirstComponentRenderPropsSelectorFactory =
        (selector: (renderProps: AnyComponentRenderProps) => AnyValue) =>
            R.pipe(firstComponentRenderPropsSelector, selector),
    firstComponentRenderPropsDimensionsSelector = fromFirstComponentRenderPropsSelectorFactory(componentProps => ({
        width: componentProps.base.width,
        height: componentProps.base.height
    })),
    firstComponentRenderPropsPositionSelector = fromFirstComponentRenderPropsSelectorFactory(componentProps => ({
        left: componentProps.base.left,
        top: componentProps.base.top
    })),
    getComponentsOfKindLength = (kind: string) => R.pipe(
        componentsMapSelector,
        R.filter(c => c.kind === kind),
        R.keys,
        R.length
    ),
    makeComponentDataSelector = (componentSelector: ComponentSelector, dataPath: Path) => R.pipe(
        componentSelector,
        R.path(dataPath)
    ),
    makeFirstComponentDataSelector = (dataPath: Path) => makeComponentDataSelector(
        R.pipe(fromAppStateSelector, firstComponentSelector),
        dataPath
    ),
    someSelectedComponentsPlacedInModernLayout = (appState: AppState) => {
        const selectedCmpIds = appState.selectedWorkspace.selectedComponentsIds;
        if (!selectedCmpIds.length) {
            return false;
        }
        const componentsMap = getComponentsMap(makeEpicStateSelector(ComponentsEvalValueActionType)(appState));
        return someComponentsPlacedInModernSection(selectedCmpIds, componentsMap);
    },
    someSelectedComponentsPlacedInWebShopFooterStrip = (appState: AppState) => {
        const selectedCmpIds = appState.selectedWorkspace.selectedComponentsIds;
        if (!selectedCmpIds.length) {
            return false;
        }
        const componentsMap = getComponentsMap(makeEpicStateSelector(ComponentsEvalValueActionType)(appState));
        return someComponentsPlacedInWebShopFooterStrip(selectedCmpIds, componentsMap, true);
    },
    selectedComponentPropsSelector = (appState: AppState) => ((
        appState.selectedWorkspace.selectedComponentsIds
                && appState.selectedWorkspace.selectedComponentsIds.length === 1
                // Text reads DOM computed values to get color, hence it does not need access to componentProps in MCTA or PP
                // Also text is the only component that is sensitive to MCTA rerenders, since it works in conjunction with TinyMCE,
                // and TinyMCE sometimes sends updates that break React functionality (example is mouse down on Bold button may cause 'blur' in TinyMCE,
                // which will cause text update event (from TinyMCE side, we can not control that))
                // which will rerender MCTA, during the mouse is down on button, hence when mouse up is happening,
                // React do not remember where mouse down was, and not send click event on button
                && (appState.selectedWorkspace.componentsProps[appState.selectedWorkspace.selectedComponentsIds[0]])
                && (appState.selectedWorkspace.componentsProps[appState.selectedWorkspace.selectedComponentsIds[0]].base.kind !== 'TEXT')
    )
        ? appState.selectedWorkspace.componentsProps[appState.selectedWorkspace.selectedComponentsIds[0]]
        : null);

