
/* eslint-disable max-len */

import React from 'react';
import { isPremiumSubscription,
    isNormalSubscription } from "../../../../../App/epics/subscriptionData/utils";
import type { SubscriptionTypeT } from "../../../../../App/epics/subscriptionData/flowTypes";
import RenderStandardPlanImages from "./RenderStandardPlanImages";
import RenderPremiumPlanImages from "./RenderPremiumPlanImages";

type UpgradePlansDialogImagesProps = {
    subscriptionType: SubscriptionTypeT;
}

const UpgradePlansDialogImages = (props: UpgradePlansDialogImagesProps) => {
    const { subscriptionType } = props,
        isNormalUser = isNormalSubscription(subscriptionType),
        isPremiumUser = isPremiumSubscription(subscriptionType);

    return (
        <React.Fragment>
            {isNormalUser && <RenderStandardPlanImages />}
            {isPremiumUser && <RenderPremiumPlanImages />}
        </React.Fragment>
    );
};

export default UpgradePlansDialogImages;
