import React from 'react';
import DirectoryCell from './DirectoryCell';
import ResourceCell from './ResourceCell';
import CheckBox from '../../../CheckBox/CheckBox';
import Resource from "../../../../../redux/modules/children/fileChooser/Resource";
import type { ListBaseCellPropTypes } from "../../flowTypes";

export default (props: ListBaseCellPropTypes) => {
    const
        resource: Resource = props.resource,
        cbProps = { ...props, column: 'checkbox' };

    return resource.isDirectory()
        ? <DirectoryCell {...cbProps} />
        // @ts-ignore
        : <ResourceCell {...cbProps}><CheckBox isChecked={props.isSelected} /></ResourceCell>;
};
