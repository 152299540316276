import React from "react";
import * as pageIds from "../pageIds";
import pagesTitles from "../pagesTitles";
import Page from "../../../../PropertiesPanel/view/PropertiesPage";
import NavigationGroup from "../../../../PropertiesPanel/view/IntlNavigationGroup";
import { MOBILE_VIEW } from "../../../../PropertiesPanel/view/MobileView/constants";
import type { PageProps } from '../../../../PropertiesPanel/flowTypes';
import type { TableComponent } from '../../flowTypes';

const
    id = pageIds.MAIN,
    title = pagesTitles[id],
    view = ({ navigateToPage }: PageProps<TableComponent>) => (
        <Page>
            <NavigationGroup
                navigateToPage={navigateToPage}
                pagesTitles={pagesTitles}
                targetPagesIds={[
                    pageIds.SizeAndSpacing, pageIds.Border, pageIds.Background, pageIds.TextStyle, MOBILE_VIEW
                ]}
            />
        </Page>
    );

export { id, title, view };
