/* eslint-disable max-len */
import { getFooterSectionId, getHeaderSectionId, isSectionComponent } from "../../../oneweb/Section/utils";
import { getTopMostParentId } from "../componentAttachements/util";
import { defaultState } from "./index";

export const hoveredComponentInsideTemplateSectionUpdater = ({ values: [topMostHandle, componentsMap, { attachments }] }: Record<string, any>) => {
    if (topMostHandle) {
        const [cmpId] = topMostHandle.componentsIds;

        if (cmpId && componentsMap[cmpId] && !isSectionComponent(componentsMap[cmpId])) {
            const topParentId = getTopMostParentId(cmpId, attachments);
            return {
                state: {
                    isInsideHeaderOrFooter: getHeaderSectionId(componentsMap) === topParentId ||
                        getFooterSectionId(componentsMap) === topParentId
                }
            };
        }
    }
    return { state: { ...defaultState } };
};
