import React from 'react';
import cx from 'classnames';
import styles from './LayoutStepStyles.css';
import { RadioButton } from '../../../../../../view/common/RadioButton/index';
import { Layouts } from '../../../constants';

const GoogleReviewsLayoutStep = ({ selectedLayout, onClick }) => {
    return (
        <div className={styles.layoutContainer}>
            {Layouts.map((image) => (
                <div key={image.id} className={cx(styles.layoutImageContainer, { [styles.selected]: selectedLayout === image.id })}>
                    <img
                        src={image.src}
                        alt={image.alt}
                        onClick={() => onClick(image.id)}
                        className={styles.layoutImage}
                    />
                    <RadioButton
                        key={image.id}
                        label={image.label}
                        value={image.id}
                        onChange={onClick}
                        isChecked={selectedLayout === image.id}
                        labelClassName={styles.labelClassName}
                        labelTextClassName={styles.labelTextClassName}
                        radioButtonContainerClassName={styles.radioButtonContainerClassName}
                    />
                </div>
            ))}
        </div>
    );
};

export default GoogleReviewsLayoutStep;
