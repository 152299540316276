import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cx from "classnames";

import { useIntl } from "../../../../../../../../wbtgen/src/view/intl";

const useStyles = makeStyles(
    (theme) => ({
        root: {
            padding: "16px",
            backgroundColor: theme.palette.custom.colorDoeskin,
        },
        content: {
            fontSize: "14px",
            lineHeight: "22px",
            letterSpacing: "0.25px",
        },
        tip: {
            fontWeight: "bold",
        },
    }),
    { name: "TaskTip" }
);

export const TaskTip = ({ tip }: { tip: string; }) => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Box className={cx(classes.root, classes.content)}>
            <span className={classes.tip}>{intl.msgJoint("msg: tip {Tip:}")}</span>
            {" "}
            <span>{intl.msgJoint(tip)}</span>
        </Box>
    );
};
