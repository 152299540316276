/* @flow */

const { inspect } = require('util');
const { matchAll } = require("../utils/matchAll.js");
const { ABT_ORIGINAL_VARIANT } = require("./constants.js");

const
    ErrorInspectPattern = {
        get value() { return '%i' },
        get regex() { return `${this.value}\\b` },
    },
    VariantPattern = /^(?:[A-Z]?\d{1,2})$/,
    MvtOriginalVariantPattern = /\b([A-Z])0\b/,
    MvtComboOperator = {
        AND: '&',
        OR: '|',
    };

function AbTest(input/*: typeof undefined | string[] */) {
    /*** Constructor ***/

    const inputVariants = input === undefined ? [ABT_ORIGINAL_VARIANT] : input;

    validateVariants(inputVariants);

    /*** Public ***/

    this.isOriginal = ()/*: boolean | typeof undefined */ => testVariant(ABT_ORIGINAL_VARIANT);

    // $FlowFixMe
    Object.defineProperty(this, 'variants', { get: () => inputVariants });

    const ab = {};

    ab.isVariant = (v/*: string */)/*: boolean | typeof undefined */ => testVariant(v);

    Object.freeze(ab);
    // $FlowFixMe
    Object.defineProperty(this, 'ab', { get: () => ab });

    const mvt = {};

    mvt.hasVariant = (v/*: string */)/*: boolean | typeof undefined */ => testVariant(v);

    mvt.hasCombination = (combo/*: string */)/*: boolean | typeof undefined */ => {
        const operators = Object.values(MvtComboOperator);

        for (let i = 0; i < operators.length; i++) {
            // $FlowFixMe
            const res = testMvtCombo(combo, operators[i]);

            if (typeof res === 'boolean') {
                return res;
            }
        }

        error('Invalid test variant combination: %i', combo);

        return undefined;
    };

    function testMvtCombo(combo/*: string */, operator/*: string */) {
        if (typeof combo !== 'string') {
            return undefined;
        }

        const testVariants = combo.split(operator).map(v => v.trim());

        if (testVariants.length < 2 || testVariants.some(isInvalidVariant)) {
            return undefined;
        }

        // pad with original variants
        const
            origTestVariants = matchAll(combo, MvtOriginalVariantPattern).map(v => v[0]),
            finalVariants = variants(origTestVariants);

        const
            reduceFn = operator === MvtComboOperator.AND
                ? (acc, v) => acc && finalVariants.indexOf(v) > -1
                : (acc, v) => acc || finalVariants.indexOf(v) > -1,
            initAcc = operator === MvtComboOperator.AND;

        return testVariants.reduce(reduceFn, initAcc)
    }

    Object.freeze(mvt);
    // $FlowFixMe
    Object.defineProperty(this, 'mvt', { get: () => mvt });

    /*** Private ***/

    function validateVariants(input) {
        if (Array.isArray(input) && input.length && !input.some(v => isInvalidVariant(v))) {
            return;
        }
        error(`Invalid constructor variants: ${inspect(input)}`);
    }

    function isInvalidVariant(v) {
        return typeof v !== 'string' || !VariantPattern.test(v);
    }

    function testVariant(v) {
        if (isInvalidVariant(v)) {
            error('Invalid test variant: %i', v);
            return undefined;
        }
        return variants([v]).indexOf(v) > -1;
    }

    function variants(testVariants/*: string[] */) {
        const res = [...inputVariants];
        testVariants.forEach(tv => {
            if (tv.length > 1 && !res.some(v => tv[0] === v[0])) {
                res.push(tv);
            }
        });
        return res;
    }

    function error(msg, ...params) {
        // Expand %i (inspect)

        let finalMsg = msg;

        const regex = new RegExp(ErrorInspectPattern.regex, 'g');
        let i = 0;
        while (regex.exec(finalMsg)) {
            if (params[i] !== undefined) {
                finalMsg = finalMsg.replace(ErrorInspectPattern.value, inspect(params[i]));
                i++;
            }
        }

        // Error into console gracefully
        console.error(`[AbTest] error: ${finalMsg}`);
    }

    Object.freeze(this);

    /*:: return this */
}

module.exports = { AbTest };
