import React from 'react';
import DirectoryCell from './DirectoryCell';
import ResourceCell from './ResourceCell';
import Resource from "../../../../../redux/modules/children/fileChooser/Resource";
import type { ListDataCellPropTypes } from "../../flowTypes";

const ucFirst = str => str[0].toUpperCase() + str.substring(1);

export default ({ getter, ...props }: ListDataCellPropTypes) => {
    const
        resource: Resource = props.resource,
        getterFn = 'get' + ucFirst(getter),
        data = resource[getterFn]();

    return resource.isDirectory()
        ? <DirectoryCell {...props}>{data}</DirectoryCell>
        // @ts-ignore
        : <ResourceCell {...props}>{data}</ResourceCell>;
};
