import {
    CreateListingDialogId
} from "./CreateListingDialog/constants";
import { replaceWithSiteSettingsDialog } from "../../SiteSettingsDialog/actionCreators";
import { SiteSettingsTabName } from "../../SiteSettingsDialog/constants";
import { CongratsOrErrorHandlerDialogsId } from "./CongratsOrErrorHandlerDialogs/config";
import { replaceDialog, openDialog } from "../../../App/actionCreators";
import { PromoPageDialogId } from "./PromoPageDialog/constants";
import { DisconnectListingDialogId } from "./DisconnectListingDialog/constants";
import type { PromoPageDialogProps } from "./PromoPageDialog";
import * as actionTypes from "./actionTypes";
import type { GmbDataType } from "../flowTypes";

type replaceConnectHandlerDialogsProps = {
    actionType: string,
    gmbDataList?: Array<GmbDataType>,
    accountName?: string
};

const
    replaceConnectHandlerDialogs = (props: replaceConnectHandlerDialogsProps) => replaceDialog(CongratsOrErrorHandlerDialogsId, props),
    replaceWithConnectHandlerDialogs = () => replaceDialog(CongratsOrErrorHandlerDialogsId),
    replaceWithBusinessListTabDialog = (): Action => replaceWithSiteSettingsDialog({ activeTabKey: SiteSettingsTabName.BUSINESS_LISTINGS }),
    replaceWithPromoPageDialog = (props: PromoPageDialogProps) => replaceDialog(PromoPageDialogId, props),
    replaceWithDisconnectListingDialog = () => replaceDialog(DisconnectListingDialogId),
    copyGmbDataToGeneralData = (props: GmbDataType) => ({
        type: actionTypes.COPY_GMB_DATA_TO_GENERAL_DATA, payload: props
    }),
    replaceWithCreateListingDialog = () => replaceDialog(CreateListingDialogId),
    openCreateYourListingDialogAction = () => openDialog(CreateListingDialogId);

export {
    replaceWithCreateListingDialog,
    replaceWithBusinessListTabDialog,
    replaceConnectHandlerDialogs,
    replaceWithConnectHandlerDialogs,
    replaceWithPromoPageDialog,
    replaceWithDisconnectListingDialog,
    copyGmbDataToGeneralData,
    openCreateYourListingDialogAction,
};
