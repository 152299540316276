import * as R from 'ramda';
import { createSelector } from 'reselect';
import type { AppState } from './flowTypes';
import type { ContextMenuViewProps } from "../../components/ContextMenu/flowTypes";
import type {
    Workspace,
    AnyComponent,
    ComponentsMap, AnyComponentKind
} from './children/workspace/flowTypes';

import * as workspaceSelectors from './children/workspace/selectors';

import {
    getContextMenuGroupsForPagesTreeItem,
    getContextMenuGroupsForLeftPanelComponentsTab
} from '../../components/ContextMenu/view/utils';
import * as requestSelectors from '../../epics/request/selectors';
import type { RequestState } from '../../epics/request/flowTypes';
import { currentPathSelector, resourcesSelector } from "./children/fileChooser/selectors";
import epicsMap from "../../epics/epicsMap";

export const
    selectedWorkspaceSelector = ({ selectedWorkspace: workspace }: AppState): Workspace => workspace,
    contextMenu = (state: AppState): ContextMenuViewProps => state.contextMenu,
    isPageModeSelector = createSelector(
        selectedWorkspaceSelector,
        (workspace: Workspace): boolean => workspaceSelectors.isPageModeSelector(workspace)
    ),
    componentsMap = createSelector(
        selectedWorkspaceSelector,
        (workspace: Workspace): ComponentsMap => workspaceSelectors.componentsMap(workspace)
    ),
    dimensions = (appState: AppState) => appState.dimensions,
    getUndoStackLength = (): boolean => true,       // TODO: To be implemented
    getRedoStackLength = (): boolean => true,       // TODO: To be implemented
    pageModeSelector = createSelector(
        selectedWorkspaceSelector,
        selectedWorkspace => workspaceSelectors.pageModeSelector(selectedWorkspace)
    ),
    selectableComponentsMap = createSelector(
        componentsMap,
        isPageModeSelector,
        (compMap, isPageMode) => R.filter(c => c.inTemplate === !isPageMode, compMap)
    ),
    selectedComponentsSelector = createSelector(
        selectedWorkspaceSelector,
        (selectedWorkspace: Workspace) => {
            const
                selectedComponentIds = workspaceSelectors.selectedComponentsIds(selectedWorkspace),
                componentsMap = workspaceSelectors.componentsMap(selectedWorkspace);

            return selectedComponentIds.map((componentId: string): AnyComponent => componentsMap[componentId]);
        }
    ),
    workspaceComponentsCountAppStateSel = createSelector(
        selectedWorkspaceSelector,
        (selectedWorkspace: Workspace) => {
            const componentsMap = workspaceSelectors.componentsMap(selectedWorkspace);

            return Object.keys(componentsMap).length;
        }
    ),
    workspaceKindComponentsAppSel = (kind: AnyComponentKind) => (appState: AppState) => {
        const
            selectedWorkspace = selectedWorkspaceSelector(appState),
            componentsMap = workspaceSelectors.componentsMap(selectedWorkspace);

        return Object
            .keys(componentsMap)
            .reduce((acc, id) => (
                componentsMap[id].kind === kind
                    ? [...acc, componentsMap[id]]
                    : acc
            ), [] as any);
    },
    workspaceNthKindComponentAppSel = (kind: AnyComponentKind, idx: number) => (appState: AppState) =>
        workspaceKindComponentsAppSel(kind)(appState)[idx],
    workspaceKindComponentsCountAppStateSel = (kind: AnyComponentKind) => (appState: AppState) =>
        workspaceKindComponentsAppSel(kind)(appState).length,
    templateWidthSelector = createSelector(
        selectedWorkspaceSelector,
        (workspace: Workspace): number => workspaceSelectors.templateWidthSelector(workspace)
    ),
    browserWidthSelector = createSelector(dimensions, (({ browserWidth }) => browserWidth)),
    getLeftPanelPagesTabContextMenuGroups = getContextMenuGroupsForPagesTreeItem,
    getDefaultContextMenuGroups = getContextMenuGroupsForLeftPanelComponentsTab,
    siteMapRequestSelector = (appState: AppState) => appState.loadSiteMapRequest,
    isSiteMapLoadingSelector = createSelector(
        siteMapRequestSelector,
        (request: RequestState) => requestSelectors.isLoadingSelector(request)
    ),
    isSiteMapLoadedSelector = createSelector(
        siteMapRequestSelector,
        (request: RequestState) => requestSelectors.isLoadedSelector(request)
    ),
    selectedComponentSelector = createSelector(
        selectedWorkspaceSelector,
        (workspace: Workspace): AnyComponent => workspaceSelectors.selectedComponent(workspace)
    ),
    fileChooserResourcesSelector = (appState: AppState) => resourcesSelector(appState.fileChooser),
    fileChooserBasePathSelector = (appState: AppState) => currentPathSelector(appState.fileChooser),
    getEpicStateFromAppStateSelector = (appState: AppState, valueActionType: string) => {
        if (!epicsMap[valueActionType]) {
            throw new Error(`Epic ${valueActionType} not registered.`);
        }

        return appState.epics[valueActionType].state;
    };
