import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: '#E6F2FA',
        padding: 0
    },
    container: {
        padding: "32px"
    },
    closeButton: {
        position: "absolute",
        right: "0",
        top: "0",
        cursor: "pointer",
    },
    tooltipContent: {
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
        },
    },
    tooltipText: {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        color: "#3C3C3C"
    },
    title: {
        fontSize: "20px",
        lineHeight: "24px"
    },
    description: {
        fontSize: "14px",
        lineHeight: "22px"
    },
    learnMoreBtn: {
        fontSize: "14px",
        lineHeight: "40px",
        fontWeight: 600,
        padding: "0px 30px",
        width: "fit-content",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: "0px",
        },
    }
}), { name: "BookingsTrialTooltip" });
