import * as imageSelectors from "./componentSelectors";
import type { ImageComponent } from "./flowTypes";
import { ImageKind } from "./kind";
import { ImageSliderKind } from "../ImageSlider/kind";
import { GalleryKind } from "../Gallery/kind";
import { repositoryTransparentImageMetadata } from "../../../constants/components";
import { customSendReport } from "../../../customSendCrashReport";
import type { ImageAsset } from "../../App/flowTypes";
import Resource from "../../../redux/modules/children/fileChooser/Resource";
import { ScaledWebspaceImage } from "../../presentational/ScaledWebspaceImage/ScaledWebspaceImage";
import { JpegCompressionQuality, JPEG_CONTENT_TYPE, PNG_CONTENT_TYPE, SVG_CONTENT_TYPES, GIF_CONTENT_TYPE } from "./constants";

interface AssetUrlParams {
    quality?: number;
    withoutEnlargement?: null | boolean;
    resize?: number;
}

const
    calculateImageScale = (component: ImageComponent) =>
        Math.max(component.scale, imageSelectors.getMinZoom(component)),
    ComponentsWithRequiredAssets = {
        [ImageKind]: ImageKind,
        [GalleryKind]: GalleryKind,
        [ImageSliderKind]: ImageSliderKind,
    },
    fixNullAsset = (componentsMap: Record<string, any>, shouldSendReport: boolean) => {
        const
            componentIdsWithNullAsset = Object.keys(componentsMap)
                .filter(id => ComponentsWithRequiredAssets[componentsMap[id].kind])
                .filter(id => {
                    const
                        component = componentsMap[id],
                        asset = component.asset,
                        images = component.images;

                    if (component.kind === ImageKind) {
                        return !asset;
                    }
                    return images && images.some(img => !img.asset);
                }),
            componentsWithNullAsset = componentIdsWithNullAsset
                .reduce((newComponentsMap, id) => {
                    const
                        transparentGifMetaData = {
                            ...repositoryTransparentImageMetadata
                        },
                        component = componentsMap[id];
                    if (component.kind === ImageKind) {
                        return {
                            ...newComponentsMap,
                            [id]: {
                                ...component,
                                asset: transparentGifMetaData
                            }
                        };
                    } else {
                        return {
                            ...newComponentsMap,
                            [id]: {
                                ...component,
                                images: component.images.map(img => ({
                                    ...img,
                                    asset: img.asset === null ? transparentGifMetaData : img.asset
                                }))
                            }
                        };
                    }
                }, {});

        if (componentIdsWithNullAsset.length && shouldSendReport) {
            customSendReport({
                crashReportId: 'SAVING_WITH_IMAGE_ASSET_NULL',
                message: 'Saving with image asset null in components map',
                additionalInfo: {
                    componentIdsWithNullAsset
                }
            });
        }
        return {
            ...componentsMap,
            ...componentsWithNullAsset
        };
    },
    getImageSrc = (logoAsset: ImageAsset | null | undefined, height: number, width: number, ignoreAspectRatio: boolean = false) => {
        if (!logoAsset) return { src: '', srcSet: '' };

        const resource: Resource = Resource.fromWebspaceUrl(logoAsset.url),
            path = resource._getFullPath();

        return ScaledWebspaceImage.getResolutionState({
            path, width, height, scale: [1, 2], ignoreAspectRatio
        });
    },
    isJpeg = (contentType: string = '') => contentType === JPEG_CONTENT_TYPE,
    isPng = (contentType: string = '') => contentType === PNG_CONTENT_TYPE,
    isSVG = (contentType: string = '') => SVG_CONTENT_TYPES.includes(contentType),
    isGif = (contentType: string = '') => contentType === GIF_CONTENT_TYPE,
    getQualityAssetUrlParam = (asset: ImageAsset = {} as any, quality: number = JpegCompressionQuality) => {
        const { contentType = '' } = asset;
        return isJpeg(contentType) || isPng(contentType) ? { quality } : {};
    },
    getResizeAssetQuery = (asset: ImageAsset = {} as any, resize: number = 2500) => {
        let assetUrlParam: AssetUrlParams = { ...getQualityAssetUrlParam(asset) },
            { contentType = '', height, width } = asset,
            canBeResized = width > resize && ((resize / width) * height) > 1;
        if ((isJpeg(contentType) || isPng(contentType)) && canBeResized) {
            assetUrlParam = {
                withoutEnlargement: null,
                resize,
                ...getQualityAssetUrlParam(asset)
            };
        }
        return assetUrlParam;
    };

const getMobileSettingScale = (mobileSettings: Record<string, any> = {}) => (mobileSettings.scale || 100) / 100;

export {
    calculateImageScale,
    fixNullAsset,
    getMobileSettingScale,
    getImageSrc,
    isJpeg,
    isSVG,
    isGif,
    getResizeAssetQuery,
    getQualityAssetUrlParam
};
