import { isTrialSubscription } from "./utils";
import { isObj, isStr } from '../../../../isType';
import type { SubscriptionData } from '../../../../redux/modules/flowTypes';

export const isDemoSubscriptionType = (subscription: SubscriptionData | string | null | undefined): boolean => {
    if (!subscription) {
        return false;
    }

    if (isStr(subscription)) {
        return isTrialSubscription(subscription);
    }

    // @ts-ignore
    if (isObj(subscription) && subscription.subscriptionType) {
        // @ts-ignore
        return isTrialSubscription(subscription.subscriptionType);
    }

    throw new Error(`Invalid input given: ${JSON.stringify(subscription)}`);
};
