import makeEpic from '../../../../epics/makeEpic';
import { GALLERY_VAT } from "./valueActionType";
import {
    GALLERY_PP_CAPTION_BOX_BACKGROUND_OPACITY_SET_IF_UNSET, GALLERY_PP_CAPTION_BOX_BACKGROUND_COLOR_SET_AUTO_COLOR
} from "../actionTypes";
import { ROThemeColorSelector } from '../../../Workspace/epics/stylesheets/selectors';

export const galleryEpic = makeEpic({
    valueActionType: GALLERY_VAT,
    defaultState: null,
    updaters: [{
        conditions: [
            GALLERY_PP_CAPTION_BOX_BACKGROUND_COLOR_SET_AUTO_COLOR,
            ROThemeColorSelector,
        ],
        reducer: ({ state, values: [{ themeColor }, themeColorsData] }) => {
            if (themeColor) {
                return {
                    state,
                    actionToDispatch: {
                        type: GALLERY_PP_CAPTION_BOX_BACKGROUND_OPACITY_SET_IF_UNSET,
                        payload: { color: [...themeColorsData[themeColor]] },
                    }
                };
            } else {
                return { state };
            }
        },
    }]
});
