/* eslint-disable max-len */
import { memoMax } from '../../../utils/memo';
import type { AllCmpsMapData, ModernLayoutCol, ModernLayoutMap, BoxSubset, ModernLayoutOptions } from "./flowTypes";
import type {
    ComponentsMap,
    AnyComponent,
} from "../../redux/modules/children/workspace/flowTypes";
import {
    getFooterSection,
    getHeaderAndFooterSection, getHeaderSection
} from "../oneweb/Section/utils";
import {
    getParentChildrenMap,
} from "../Workspace/epics/componentsEval/userInteractionMutations/getParentChildrenMap";
import type { ParentChildrenMap } from "../Workspace/epics/componentsEval/userInteractionMutations/flowTypes";
import type { SectionComponent } from '../oneweb/Section/flowTypes';
import { defaultMargin, expandableCmpKindInMHF } from './constants';
import { isStripKind, WEBSHOP_POLICIES, WEBSHOP_PAYMENT_METHODS } from '../oneweb/componentKinds';

type ObjectOfStringsWithBooleanValue = { [id: string]: boolean };

const
    isModernLayoutSection = (component: AnyComponent): boolean => {
        return !!component && !!(component.modernLayout || {}).active;
    },
    convertLayoutStyleToStyleObject = (layoutStyle: Record<string, any> = {}) => {
        return ['padding', 'margin'].reduce((result, k) => {
            const style = layoutStyle[k];
            if (style) {
                return {
                    ...result,
                    ...Object.keys(style).reduce((o, p) => {
                        return { ...o, [k + p.charAt(0).toUpperCase() + p.slice(1)]: style[p] };
                    }, {})
                };
            }
            return result;
        }, {});
    },
    getIsModernLayoutActivated = (componentsMap: ComponentsMap): boolean => {
        const { footer, header } = getHeaderAndFooterSection(componentsMap);
        return (header && isModernLayoutSection(header) &&
            footer && isModernLayoutSection(footer)) || false;
    },
    getAllCmpsInAContainer = (
        cmpsMap: ComponentsMap,
        containerId: string,
        parentChildrenMapInp?: ParentChildrenMap
    ): Array<AnyComponent> => {
        let parentChildrenMap = parentChildrenMapInp || getParentChildrenMap(cmpsMap),
            result: string[] = [],
            children = parentChildrenMap[containerId] || [];
        for (let i = 0; i < children.length; i++) {
            result.push(children[i]);
            children.push(...(parentChildrenMap[children[i]] || []));
        }
        return result.map(id => cmpsMap[id]);
    },
    getCmpIdsMapInCol = (col: ModernLayoutCol, layout: ModernLayoutMap) => {
        let cmpIdsMap = {};
        if (col.cmp) {
            cmpIdsMap[col.cmp.id] = true;
        } else if (col.cmps) {
            col.cmps.forEach(cmp => {
                cmpIdsMap[cmp.id] = true;
                cmpIdsMap = {...cmpIdsMap, ...getCmpIdsMapInContainer(cmp.id, layout)}; // eslint-disable-line
            });
        }
        return cmpIdsMap;
    },
    getCmpIdsMapInRow = (row: Record<string, any>, layout: Record<string, any>) => {
        let cmpIdsMap = {};
        if (row.cols) {
            row.cols.forEach(col => {
                cmpIdsMap = { ...cmpIdsMap, ...getCmpIdsMapInCol(col, layout) };
            });
        }
        return cmpIdsMap;
    },
    getCmpIdsMapInContainer = (id, layout) => {
        let cmpIdsMap = {};
        if (layout[id] && layout[id].rows) {
            layout[id].rows.forEach(row => {
                cmpIdsMap = { ...cmpIdsMap, ...getCmpIdsMapInRow(row, layout) };
            });
        }
        return cmpIdsMap;
    },
    getAllCmpIdsMapInLayout = memoMax((layout: Record<string, any>, parentId: string): ObjectOfStringsWithBooleanValue => {
        if (!layout) {
            return {};
        }
        return getCmpIdsMapInContainer(parentId, layout);
    }, 2),
    getVResponsiveCmpIdsMapInLayout = (rootId: string, layoutMap: ModernLayoutMap): ObjectOfStringsWithBooleanValue => {
        const { rows, vResponsive: layoutResponsive } = layoutMap[rootId];
        if (layoutResponsive) {
            return getAllCmpIdsMapInLayout(layoutMap, rootId);
        }
        return rows.reduce((acc, row) => {
            const { vResponsive: rowVResponsive } = row;
            if (rowVResponsive) {
                return { ...acc, ...getCmpIdsMapInRow(row, layoutMap) };
            }
            return acc;
        }, {});
    },
    getOldCmpsInSection = (section, componentsMap) => {
        if (section && section.modernLayout) {
            const sectionLayout = section.modernLayout.layout,
                sectionCmps = getAllCmpsInAContainer(componentsMap, section.id),
                cmpIdsMapInSection = getAllCmpIdsMapInLayout(sectionLayout, section.id),
                oldSectionCmpsMap = {};

            sectionCmps.forEach(cmp => {
                // $FlowFixMe: the code enters here only if section.modernLayout is truthy value
                if (section.modernLayout.active) {
                    if (!cmpIdsMapInSection.hasOwnProperty(cmp.id)) {
                        oldSectionCmpsMap[cmp.id] = cmp;
                    }
                } else if (cmpIdsMapInSection.hasOwnProperty(cmp.id)) {
                    oldSectionCmpsMap[cmp.id] = cmp;
                }
            });
            return oldSectionCmpsMap;
        }
        return {};
    },
    findCmpkindIsExpandableInMHF = (kind: string): boolean => (expandableCmpKindInMHF[kind] || false),
    getMarginOrPaddingFromObj = (margin: Record<string, any> = {}): BoxSubset => {
        let result = { };
        ['top', 'left', 'right', 'bottom'].forEach(k => {
            if (margin[k] === undefined) { return; }
            const v = margin[k];
            result[k] = (v && !isNaN(v) && v > 0) ? v : defaultMargin[k];
        });
        return result;
    },
    getFlattenedOptions = (options: ModernLayoutOptions = []): ModernLayoutOptions => {
        return options.reduce((acc, option) => {
            const { children = [], ...rest } = option;
            return [...acc, { ...rest, children: [] }, ...getFlattenedOptions(children)];
        }, [] as ModernLayoutOptions);
    },
    getToggleOffCmpsMap = (section: AnyComponent, componentsMap: ComponentsMap) => {
        const toggleOffCmpsMap = {};
        if (section && section.modernLayout && section.modernLayout.active) {
            const options = getFlattenedOptions(section.modernLayout.options);
            if (options && options.length) {
                const sectionCmps = getAllCmpsInAContainer(componentsMap, section.id);
                const toggleOffCmpIds = options.filter(option => !option.show).map(option => option.id);
                sectionCmps.forEach(cmp => {
                    if (toggleOffCmpIds.includes(cmp.id)) {
                        toggleOffCmpsMap[cmp.id] = cmp;
                    }
                });
            }
        }
        return toggleOffCmpsMap;
    },
    extractOldHeaderOrFooterCmps = (componentsMap: ComponentsMap): AllCmpsMapData => {
        const { header, footer } = getHeaderAndFooterSection(componentsMap),
            oldHeaderCmpsMap = getOldCmpsInSection(header, componentsMap),
            oldFooterCmpsMap = getOldCmpsInSection(footer, componentsMap),
            toggleOffHeaderCmpsMap = getToggleOffCmpsMap(header, componentsMap),
            toggleOffFooterCmpsMap = getToggleOffCmpsMap(footer, componentsMap),
            cmpIdsToDel = [...Object.keys(oldHeaderCmpsMap), ...Object.keys(oldFooterCmpsMap),
                ...Object.keys(toggleOffHeaderCmpsMap), ...Object.keys(toggleOffFooterCmpsMap)];
        if (cmpIdsToDel.length) {
            let newCmpsMap = { ...componentsMap };
            cmpIdsToDel.forEach(id => {
                delete newCmpsMap[id];
            });
            return {
                componentsMap: newCmpsMap,
                oldHeaderCmpsMap,
                oldFooterCmpsMap,
                toggleOffHeaderCmpsMap,
                toggleOffFooterCmpsMap,
            };
        }
        return {
            componentsMap,
            oldHeaderCmpsMap: null,
            oldFooterCmpsMap: null,
            toggleOffHeaderCmpsMap: null,
            toggleOffFooterCmpsMap: null,
        };
    },
    getComponentIdsInRow = (row: Record<string, any>, layoutMap: Record<string, any>) => {
        const { cols = [] } = row;
        return cols.reduce((acc, col) => [...acc, ...getComponentIdsInColumn(col, layoutMap)], []) // eslint-disable-line
    },
    getComponentIdsInLayout = memoMax((id: string, layoutMap: Record<string, any>) => {
        const layout = layoutMap[id];
        if (!layout) { return []; }
        const { rows = [] } = layout;

        return rows.reduce((acc, row) => [...acc, ...getComponentIdsInRow(row, layoutMap)], []);
    }, 4),
    getComponentIdsInColumn = (col: Record<string, any>, layoutMap: Record<string, any>) => {
        const { cmps = [] } = col;
        return cmps
            .reduce((acc, { id, bindingId }) => {
                if (id === bindingId) {
                    return acc;
                }
                return [...acc, id, ...getComponentIdsInLayout(id, layoutMap)];
            }, []);
    },
    getAllComponentIdsInModernSection = (section: SectionComponent, componentsMap: ComponentsMap) => {
        if (section && isModernLayoutSection(section)) {
            const { layout = {} } = section.modernLayout || {};
            return getComponentIdsInLayout(section.id, layout)
                .filter(id => !!componentsMap[id]);
        }
        return [];
    },
    getAllComponentsIdsInModernHeader = (componentsMap: ComponentsMap) => {
        const header = getHeaderSection(componentsMap);
        return getAllComponentIdsInModernSection(header, componentsMap);
    },
    getAllComponentsIdsInModernFooter = (componentsMap: ComponentsMap) => {
        const footer = getFooterSection(componentsMap);
        return getAllComponentIdsInModernSection(footer, componentsMap);
    },
    getAllComponentsIdsInModernHeaderAndFooter = (componentsMap: ComponentsMap) => {
        return [
            ...getAllComponentsIdsInModernHeader(componentsMap),
            ...getAllComponentsIdsInModernFooter(componentsMap)
        ];
    },
    getTrueMapOfAllCmpIdsInModernSection = (sectionId, componentsMap) => {
        const section = componentsMap[sectionId];
        if (section && section.modernLayout && isModernLayoutSection(section)) {
            const { layout } = section.modernLayout;
            return getAllCmpIdsMapInLayout(layout, sectionId);
        }
        return {};
    },
    getAllCmpIdsInModernHeaderFooter = (componentsMap: ComponentsMap) => {
        const { header = {}, footer = {} } = getHeaderAndFooterSection(componentsMap);
        return {
            ...getTrueMapOfAllCmpIdsInModernSection(header.id, componentsMap),
            ...getTrueMapOfAllCmpIdsInModernSection(footer.id, componentsMap)
        };
    },
    createRelInGivenParentAndChild = (parent: AnyComponent, child: AnyComponent) => {
        return {
            id: parent.id,
            left: child.left,
            top: child.top - parent.top,
            right: parent.stretch ? -Infinity : (child.left + child.width) - (parent.left + parent.width),
            bottom: (child.top + child.height) - (parent.top + parent.height)
        };
    },
    createRelInIdsAsPerLayout = (componentsMap: ComponentsMap, layout: any) => {
        let newCmpsMap = { ...componentsMap };
        Object.keys(layout).forEach(layoutId => {
            layout[layoutId].rows.forEach(row => {
                row.cols.forEach(col => {
                    col.cmps.forEach(({ id: cmpId }) => {
                        if (!newCmpsMap[cmpId]) {
                            return;
                        }
                        if (!newCmpsMap[cmpId].relIn || newCmpsMap[cmpId].relIn.id !== layoutId) {
                            newCmpsMap[cmpId] = {
                                ...newCmpsMap[cmpId],
                                relIn: createRelInGivenParentAndChild(
                                    newCmpsMap[layoutId],
                                    newCmpsMap[cmpId]
                                )
                            };
                        }
                    });
                });
            });
        });
        return newCmpsMap;
    },
    getRootId = (id, sectionId, cmpId) => {
        let rootId = id;
        rootId = id === 'root' ? sectionId : cmpId;
        return rootId || id;
    },
    isWebShopFooterStrip = (cmp: AnyComponent, rows: Array<any>) => {
        if (!cmp || !rows || !rows.length) { return false; }
        if (!isStripKind(cmp.kind)) { return false; }
        let isWebShopFooterStrip = false;
        rows.forEach(row => {
            if (row.columns && row.columns.length) {
                row.columns.forEach(col => {
                    const colCmp = col.component;
                    if (colCmp && colCmp.relIn && colCmp.relIn.id === cmp.id &&
                        (colCmp.kind === WEBSHOP_PAYMENT_METHODS || colCmp.kind === WEBSHOP_POLICIES)) {
                        isWebShopFooterStrip = true;
                    }
                });
            }
        });
        return isWebShopFooterStrip;
    },
    replaceComponentIdsInLayout = (layoutMap: Record<string, any>, oldToNewIdMap: Record<string, any>, sectionId?: string): Record<string, any> => {
        return Object.keys(layoutMap).reduce((acc, id) => {
            const { rows } = layoutMap[id];
            const newId = getRootId(id, sectionId, oldToNewIdMap[id]);
            const processRow = (row) => {
                const { cols } = row;
                return {
                    ...row,
                    cols: cols.map(col => {
                        const { cmps, rows } = col;
                        let newCol = { ...col };
                        if (cmps) {
                            newCol.cmps = cmps.map(c => ({ ...c, id: oldToNewIdMap[c.bindingId] || c.id }));
                        }
                        if (rows) {
                            return { ...newCol, rows: rows.map(processRow) };
                        }
                        return newCol;
                    })
                };
            };
            const updatedRows = rows.map(processRow);
            return { ...acc, [newId]: { ...layoutMap[id], rows: updatedRows } };
        }, {});
    };
export {
    convertLayoutStyleToStyleObject,
    isModernLayoutSection,
    getIsModernLayoutActivated,
    getVResponsiveCmpIdsMapInLayout,
    extractOldHeaderOrFooterCmps,
    getToggleOffCmpsMap,
    getAllCmpIdsMapInLayout,
    getAllCmpsInAContainer,
    getCmpIdsMapInCol,
    getCmpIdsMapInRow,
    getComponentIdsInLayout,
    getComponentIdsInColumn,
    getComponentIdsInRow,
    getAllComponentIdsInModernSection,
    getAllComponentsIdsInModernHeader,
    getAllComponentsIdsInModernFooter,
    getAllComponentsIdsInModernHeaderAndFooter,
    getFlattenedOptions,
    getMarginOrPaddingFromObj,
    findCmpkindIsExpandableInMHF,
    replaceComponentIdsInLayout,
    createRelInIdsAsPerLayout,
    createRelInGivenParentAndChild,
    isWebShopFooterStrip,
    getAllCmpIdsInModernHeaderFooter
};
