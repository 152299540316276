import React, { useRef, useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { Grid } from "@mui/material";

import { FaviconSettingsImagePreview } from "./FaviconSettingsImagePreview";
import { FaviconSettingsImageChooser } from "./FaviconSettingsImageChooser";

import { uploadFromComputerAction, fetchImageContents, getResourceMetaData } from "../../../../ImageChooser/actions";
import { imageChooserAssetKeyMap } from "../../../../ImageChooser/config";
import { selectImageChooserData, selectFaviconAsset } from "../../../../../common/selectors";
import { setFaviconAction } from "./actions";

import type { AppState } from "../../../../../common/types";
import type { ImageAsset } from "../../../../../../../../wbtgen/src/components/App/flowTypes";

type FaviconSettingsFormProps = {
    favicon: ImageAsset | null | undefined;
    isResourcesLoaded: Boolean;
    isAssetMetaDataLoaded: Boolean;
};

export const FaviconSettingsFormComponent = (props: FaviconSettingsFormProps) => {
    const { favicon, isResourcesLoaded, isAssetMetaDataLoaded } = props;
    const dispatch = useDispatch();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [file, setFile] = useState(null);

    const onFileChange = (e) => {
        const files = e.target.files;
        if (files.length) {
            setFile(files[0]);
        }
        dispatch(uploadFromComputerAction(files, imageChooserAssetKeyMap.favicon));
    };

    const onChooserClicked = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const discardFile = () => {
        setFile(null);

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const onRemove = (e) => {
        e.preventDefault();
        e.stopPropagation();
        discardFile();

        // @ts-ignore this can be a bug
        dispatch(setFaviconAction(null));
    };

    useEffect(() => {
        if (!isResourcesLoaded) {
            dispatch(fetchImageContents());
        }
    }, []);

    useEffect(() => {
        if (file && isResourcesLoaded && !isAssetMetaDataLoaded) {
            dispatch(getResourceMetaData(file));
        }
    }, [isResourcesLoaded]);

    useEffect(discardFile, [favicon]);

    return (
        <Grid container spacing={3} data-testid="favicon-settings">
            <Grid item xs={12} md={6}>
                <FaviconSettingsImagePreview
                    asset={favicon}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FaviconSettingsImageChooser
                    asset={favicon}
                    hintWarning=""
                    onClick={onChooserClicked}
                    onRemove={onRemove}
                />
            </Grid>
            <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={onFileChange}
            />
        </Grid>
    );
};

const mapStateToProps = (state: AppState) => ({
    favicon: selectFaviconAsset(state),
    isResourcesLoaded: selectImageChooserData(state).isResourcesLoaded,
    isAssetMetaDataLoaded: selectImageChooserData(state).isAssetMetaDataLoaded,
});

export const FaviconSettingsForm = connect(mapStateToProps)(FaviconSettingsFormComponent);
