import type { AdjustmentHook, AdjustmentHookConfig, ShouldCallAdjustmentHook } from "../../Workspace/epics/componentsEval/flowTypes"; // eslint-disable-line max-len
import type { Dimensions } from '../../../redux/modules/children/workspace/flowTypes';
import type { ContactFormComponent } from './flowTypes';

type AdjustmentData = {
    minDimensions: Dimensions,
}
type Hook = AdjustmentHook<ContactFormComponent, null, null, AdjustmentData>
type HookConfig = AdjustmentHookConfig<ContactFormComponent, null, null, AdjustmentData>
type ContactFormShouldCallAdjustmentHook = ShouldCallAdjustmentHook<ContactFormComponent, null, null, AdjustmentData>

const
    hook: Hook = ({ component, componentExtension, adjustmentData, isTransient }) => {
        const { minDimensions: { height, width } } = adjustmentData || { minDimensions: { height: 0, width: 0 } };
        if (!isTransient && (component.height < height || component.width < width)) {
            return [{
                ...component,
                height: Math.max(height, component.height),
                width: Math.max(width, component.width)
            }, componentExtension];
        }
        return [component, componentExtension];
    },
    shouldCallHook: ContactFormShouldCallAdjustmentHook = (prevProps, nextProps) => prevProps.adjustmentData !== nextProps.adjustmentData, // eslint-disable-line max-len
    hookConfig: HookConfig = {
        hook,
        shouldCallHook
    };

export default hookConfig;
