import * as React from 'react';
import { configurationDialogId } from './configurationDialog';
import workspace from "./view/workspace";
import calcRenderProps from './calcRenderProps';
// Commenting out the following piece of code for WBTGEN-3550.
// Shall bring it back when we implement WBTGEN-2110
// import contextMenu from "./contextMenu/index";
import propertiesPanel from "./propertiesPanel/index";
import reducer from './reducer/index';
import kind from './kind';
import adjustmentHookConfig from './adjustmentHookConfig';
import componentMainActions from './componentMainActions/index';
import { localeReducer as locale } from '../Template/epics/template/index';
import previewConfig from "./previewConfig";
import FacebookPageLocaleWrapper from './view/FacebookPageLocaleWrapper';
import type { CalcProps } from '../../Preview/flowTypes';
import type { FacebookPageComponent } from './flowTypes';
import { ComponentNames } from "../constants";
import Icons from '../../../view/Icons/index';

type PreviewCalcProps = CalcProps<FacebookPageComponent> & {
    renderedWidth?: number
};

export default {
    workspace,
    kind,
    label: ComponentNames[kind],
    shortcutIconName: 'facebook',
    calcRenderProps,
    // Commenting out the following piece of code for WBTGEN-3550.
    // Shall bring it back when we implement WBTGEN-2110
    // contextMenu,
    propertiesPanel,
    requireConfigurationOnDrop: () => true,
    configurationDialog: configurationDialogId,
    componentMainActions,
    reducer,
    dependsOn: {
        locale
    },
    adjustmentHookConfig,
    mobileEditorConfig: {
        kind,
        calcProps: (props: PreviewCalcProps) => {
            return {
                ...previewConfig.calcProps(props),
                width: props.renderedWidth,
                isTransient: true
            };
        },
        view: FacebookPageLocaleWrapper,
        getHiddenElementData: (props: FacebookPageComponent) => {
            const Icon = Icons.FACEBOOK_LOGO;
            return { content: props.pageURL, icon: <Icon /> };
        }
    }
};
