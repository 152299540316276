import { FACEBOOK_API_BASE_URL, HTTP_PREFIX, PAGE_PREFIX, TABS_TIMELINE, LocaleMap } from "./constants";
import type { FacebookPageComponent } from './flowTypes';
import { getDefultLocaleId } from "../../Locale/getDefaultLocale";

const DEFAULT_LOCALE = getDefultLocaleId();

export const
    // appId = 486613084714993, // IMP: Retained for future use, strictly do not remove.
    minComponentHeighWithSmallHeader = 70,
    minComponentHeight = 130,
    pagePostsHeight = 200,
    friendsFacesHeight = 85,
    fixPageURL = (pageURL: string): string => pageURL.replace(/^https?:\/\/www\./, '').replace(PAGE_PREFIX, ''),
    getFullPageURL = (pageURL: string): string => `${HTTP_PREFIX}${PAGE_PREFIX}${pageURL}`,
    isPagePostVisible = (tabs: Array<string>): boolean => (tabs.indexOf(TABS_TIMELINE) > -1),
    getFacebookPagePluginApiUrl = (locale: string = DEFAULT_LOCALE): string =>
        `${FACEBOOK_API_BASE_URL}/${LocaleMap[locale]}/sdk.js#xfbml=1&version=v17.0&autoLogAppEvents=1`,
    getMinComponentHeight = ({ smallHeader, showFacepile, tabs }: FacebookPageComponent) => {
        let minHeight = smallHeader ? minComponentHeighWithSmallHeader : minComponentHeight;

        if (showFacepile && tabs.length === 0) {
            minHeight += friendsFacesHeight;
        }

        if (tabs && tabs.length > 0) {
            minHeight += pagePostsHeight;
        }

        return minHeight;
    };
