/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from "react";
import cx from 'classnames';
import * as styles from './Properties.css';
import { showRed } from '../../ContactForm.css';
import type { FieldPropertiesType } from "../../flowTypes";
import FieldIcons from '../../fieldIcons';
import { extractKey } from "../../../utility";
import Label from '../../../../../../view/common/Label';
import { Flex } from '../../../../../../view/reflexbox/index';
import CheckBoxSlider from '../../../../../../view/common/CheckBox/CheckBoxSlider';
import { MinOptions, FieldLabels, FieldDescriptions } from "../../../constants";
import * as Actions from "../../../actionTypes";
import Msg from '../../../../../../view/intl/Msg';
import Options from './Options/index';
import injectIntl from "../../../../../../view/intl/injectIntl";
import focusHandler from '../../../../../../utils/inputFocusSelect';
import Textarea from "../../../../../../view/common/Input/Textarea";
import PropertyContainer from "../../../../../../view/common/PropertyContainer/index";

export default injectIntl(({ element, field, minOptionsCount, dispatch, intl }: FieldPropertiesType) => {
    const { displayName, inputType, values, isRequired, displayErrorMessage, hasCustomErrMsg } = element,
        key = extractKey(field),
        Icon = FieldIcons[key],
        optionsProps = { field, values, minOptionsCount, dispatch };

    const onDelete = (field) => {
        dispatch({ type: Actions.CONTACT_FORM_DELETE_FIELD, payload: field });
    };

    return (
        <Flex column className={styles.container}>
            <div className={styles.header}>
                <div className={styles.headerTitleCtn}>
                    <Icon style={{ fill: "none" }} />
                    <div className={styles.headerTitle}>
                        <span className={styles.headerTitlePrimaryLabel}>{intl.msgJoint(FieldLabels[key]) + (isRequired ? " *" : "")}</span>
                        <span>{intl.msgJoint(FieldDescriptions[key])}</span>
                    </div>
                </div>
                <div onClick={() => onDelete(field)} className={styles.removeTextCtn}>
                    <Label className={styles.removeText}>{"msg: component.contactForm.remove {Remove}"}</Label>
                </div>
            </div>
            <div className={styles.fieldPropsCtn}>
                <div className={styles.requiredContainer}>
                    <CheckBoxSlider
                        isChecked={isRequired}
                        isGreen={false}
                        className={styles.requiredBtn}
                        onClick={() => dispatch({
                            type: Actions.CONTACT_FORM_FIELD_REQUIRED_TOGGLED,
                            payload: field
                        })}
                    />
                    <Msg className={styles.fieldPropTitle} k="component.contactForm.fieldProps.required">Required</Msg>
                </div>
                <div>
                    <PropertyContainer
                        label="msg: component.contactForm.fieldProps.title {Title:}"
                        valueClassName={styles.fieldPropValue}
                        vsHeight={0}
                    >
                        <input
                            autoFocus
                            type="text"
                            value={displayName}
                            className={cx(styles.title, { [showRed]: !(displayName) }, styles.fieldPropInput)}
                            onChange={e => dispatch({
                                type: Actions.CONTACT_FORM_FIELD_TITLE_CHANGED,
                                payload: { field, title: e.target.value }
                            })}
                            onFocus={e => {
                                e.persist();
                                e.target.value = "";
                                setTimeout(() => {
                                    e.target.value = displayName;
                                    focusHandler(e);
                                }, 1);
                            }}
                        />
                        {
                            !displayName &&
                            <Label className={styles.titleMissingCtn}>
                                {"msg: component.contactForm.titlemissing {This field needs a title}"}
                            </Label>
                        }
                    </PropertyContainer>
                </div>
                {MinOptions[inputType] && <Options {...optionsProps} />}
                {key === "fileupload" && <Flex column className={styles.fileTypeContainer}>
                    <PropertyContainer
                        label="msg: component.contactForm.fieldProps.fileType {Select the types of files that can be uploaded:}"
                        valueClassName={styles.fieldPropValue}
                        vsHeight={0}
                    >
                        <div className={styles.fileTypeOptCtn}>
                            <div className={styles.fileTypeRadioCtn}>
                                <input
                                    type="radio"
                                    name="fileType"
                                    value="all"
                                    checked={inputType === "all"}
                                    className={styles.fileTypeRadio}
                                    onChange={e => dispatch({
                                        type: Actions.CONTACT_FORM_FIELD_FILE_TYPE_CHANGED,
                                        payload: { field, fileType: e.target.value }
                                    })}
                                />
                                {/* eslint-disable-next-line max-len */}
                                <Msg className={styles.fileTypeRadioLabel} k="component.contactForm.fileType.all">All files</Msg>
                            </div>
                            <div className={styles.fileTypeRadioCtn}>
                                <input
                                    type="radio"
                                    name="fileType"
                                    value="images"
                                    checked={inputType === "images"}
                                    className={styles.fileTypeRadio}
                                    onChange={e => dispatch({
                                        type: Actions.CONTACT_FORM_FIELD_FILE_TYPE_CHANGED,
                                        payload: { field, fileType: e.target.value }
                                    })}
                                />
                                <Msg className={styles.fileTypeRadioLabel} k="component.contactForm.fileType.images">Only images</Msg>
                            </div>
                            <div className={styles.fileTypeRadioCtn}>
                                <input
                                    type="radio"
                                    name="fileType"
                                    value="documents"
                                    checked={inputType === "documents"}
                                    className={styles.fileTypeRadio}
                                    onChange={e => dispatch({
                                        type: Actions.CONTACT_FORM_FIELD_FILE_TYPE_CHANGED,
                                        payload: { field, fileType: e.target.value }
                                    })}
                                />
                                <Msg className={styles.fileTypeRadioLabel} k="component.contactForm.fileType.documents">Only documents</Msg>
                            </div>
                        </div>
                    </PropertyContainer>
                    <Label className={styles.fileUploadNote}>
                        {
                            "msg: component.contactForm.fileuploadnote {Note: Each file can be maximum 5 MB. Maximum upload of 5 files and 25 MB in total possible.}"
                        }
                    </Label>
                </Flex>}
                { key !== "fileupload" && <div className={styles.errMsgToggleCtn}>
                    <CheckBoxSlider
                        disabled={!isRequired}
                        isGreen={false}
                        isChecked={hasCustomErrMsg}
                        className={styles.errMsgBtn}
                        onClick={() => dispatch({
                            type: Actions.CONTACT_FORM_FIELD_ERR_MSG_TOGGLED,
                            payload: { field, isRequired }
                        })}
                    />
                    <Msg className={styles.fieldPropTitle} k="component.contactForm.fieldProps.customErrorMessage">
                        Custom error message
                    </Msg>
                </div>}
                {
                    key !== "fileupload" && isRequired && hasCustomErrMsg && <div className={styles.errorMessageContainer}>
                        <PropertyContainer
                            label="msg: component.contactForm.fieldProps.errorMessage {Error message}"
                            vsHeight={0}
                        >
                            <Textarea
                                value={displayErrorMessage}
                                className={cx(styles.errorMessage, { [showRed]: !displayErrorMessage.trim() },
                                    styles.fieldPropInput, styles.fieldPropTextarea)}
                                onChange={message => dispatch({
                                    type: Actions.CONTACT_FORM_FIELD_ERROR_MESSAGE_CHANGED,
                                    payload: { field, message }
                                })}
                                disabled={!hasCustomErrMsg}
                            />
                        </PropertyContainer>
                    </div>
                }
            </div>
        </Flex>
    );
});
