import { parse } from 'flatted';
import type { ExData, EpicPutExData, EpicUpdaterEvalExData } from './flowTypes';

import { SET_APP_STATE } from '../redux/modules/actionTypes';
import epicsMap from '../epics/epicsMap';
import { trace, warn } from '../../utils/log';
import registerDebugModule from './registerDebugModule';
import { getExceptionDataById } from './storage';

const
    divider = `-----------------------------------------------------------------------------------------------------`,
    issueFixedMessage = 'There is no more exception.';

let _store;
function init(store: any) {
    _store = store;
}

function reproduceEpicPut(exData: EpicPutExData) {
    trace('It was "epic put" exception.');
    trace('Usually it comes from react render code, but also can be in redux reducer.');

    const { stateBeforeException, action } = exData;
    trace(
        'I will set application state to state before action that cause exception was dispatched',
        stateBeforeException
    );
    _store.dispatch({
        type: SET_APP_STATE,
        payload: {
            storeState: stateBeforeException
        }
    });
    trace('Now i will dispatch action that was causing an error and error should happen', action);
    action.dontCatch = true;
    _store.dispatch(action);

    return issueFixedMessage;
}

function reproduceEpicUpdaterEval(exData: EpicUpdaterEvalExData) {
    trace('It was "epic updater reducer evaluation" exception.');

    const reducer = epicsMap[exData.valueActionType].updaters[exData.reducerIndex].reducer;

    trace(
        "I'll call epic updater reducer again with this arguments",
        ...exData.reducerParams,
        exData.epicState
    );
    const { state, scope, actionToDispatch } = reducer({
        values: exData.reducerParams,
        state: exData.epicState,
        scope: exData.epicScope
    });
    if (state === undefined && exData.epicState !== undefined) {
        throw new Error('It still return state as undefined');
    }
    if (scope === undefined && exData.epicScope !== undefined) {
        throw new Error('It still return scope as undefined');
    }
    trace('Awesome, here is new epic state ', state, ' and action to dispatch ', actionToDispatch);

    return issueFixedMessage;
}

/* TODO WBTGEN-1026 - Reproduce exception */

function doReproduceExceptionFromData(exData) {
    switch (exData.type) {
        case 'EPIC_UPDATER_EVAL':
            return reproduceEpicUpdaterEval(exData);
        case 'RENDER':
            return reproduceEpicPut(exData);
        default:
            warn(`I don't know how to reproduce ${exData.type}.
                 Please implement me :) This this your exception data`, exData);
            return `Possible not implemented for kind ${exData.type}?`;
    }
}

function doReproduceException(exceptionId) {
    const exData: ExData = parse(getExceptionDataById(exceptionId));

    if (!exData) {
        return `exception record with id ${exceptionId} not found`;
    }

    trace('exData', exData);

    return doReproduceExceptionFromData(exData);
}

function reproduceException(exceptionId) {
    trace(`${divider}
reproducing exception ${exceptionId}
${divider}`);
    const result = doReproduceException(exceptionId);
    return `Done. Result: ${result}`;
}

function reproduceExceptionFromData(exData) {
    trace(`${divider}
reproducing exception from data
${divider}`);
    const result = doReproduceExceptionFromData(exData);
    return `Done. Result: ${result}`;
}

registerDebugModule('reproduceException', reproduceException);
registerDebugModule('reproduceExceptionFromData', reproduceExceptionFromData);

export {
    init,
    getExceptionDataById
};
