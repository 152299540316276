export const
    loadImage = (src: string): Promise<HTMLImageElement> => {
        //
        return new Promise((resolve, reject) => {
            // Create an image
            const img = document.createElement('img');

            img.onload = () => {
                resolve(img);
            };

            img.onerror = reject;

            img.src = src;
        });
    };
