import { updateComponentsRelIns } from "../relations/updateComponentsRelIns";
import { getNonGhostCmps } from "../componentAttachements/util";
import { depthSorter } from "../../../Preview/flattening/util";
import { divideInToGroups } from "../../../oneweb/Section/epics/splitSectionDecoration/divideInToGroups";
import { getPageSections, getHeaderAndFooterSection } from "../../../oneweb/Section/utils";
import { ContainerKinds } from "../../../../utils/containerKinds";
import isStretchComponentKind from "../../../oneweb/isStretchComponentKind";
import type { ComponentsMap } from "../../../../redux/modules/children/workspace/flowTypes";
import { isIntersecting, getCmpRect } from "./utils";

const SpacingOptions = {
    maxWhiteSpace: 250,
    minGapToMaintain: 250,
    allowedEmptyContainerHeight: 500,
    maxHeightOfContainer: 10000,
};

const isChild = (r1, r2, isStreachKind) => {
    return (
        (r1.top >= r2.top && (isStreachKind || (r1.left >= r2.left && r1.right <= r2.right)) && r1.bottom <= r2.bottom) ||
        (r1.top <= r2.top && (isStreachKind || (r1.left <= r2.left && r1.right >= r2.right)) && r1.bottom >= r2.bottom)
    );
};

const hasOverlappingComponents = (cmp, cmps, componentsMap) => {
    const bbox = getCmpRect(cmp);
    const parentCmpId = (cmp.relIn || {}).id;
    for (let i = 0; i < cmps.length; i++) {
        const cmpId = cmps[i].id;
        const cBbox = getCmpRect(componentsMap[cmpId]),
            isStreachComponent = isStretchComponentKind(componentsMap[cmpId].kind, componentsMap[cmpId].stretch)
                || isStretchComponentKind(cmp.kind, cmp.stretch);
        if (cmpId !== cmp.id && isIntersecting(cBbox, bbox) && !isChild(cBbox, bbox, isStreachComponent)) {
            return true;
        }
        if (parentCmpId === cmpId) {
            return false;
        }
    }
    return false;
};

export const removeLargeWhiteSpaces = (
    componentsMap: ComponentsMap,
    templateWidth: number,
    options: Record<string, any> = SpacingOptions
) => {
    try {
        const {
            maxWhiteSpace,
            minGapToMaintain,
            allowedEmptyContainerHeight,
            maxHeightOfContainer,
        } = options;

        let newComponentsMap: Record<string, any> = { ...componentsMap };
        let
            parentChildsMap = {},
            parentChild = {},
            cmps = getNonGhostCmps(newComponentsMap).sort(depthSorter).reverse();

        const containerHeightExceedsLimit = cmps.some(cmp => ContainerKinds[cmp.kind] && cmp.height > maxHeightOfContainer);
        if (!containerHeightExceedsLimit) {
            return componentsMap;
        }

        const process = (inpCmp) => {
            let cmp = newComponentsMap[inpCmp.id];

            if (hasOverlappingComponents(cmp, cmps, newComponentsMap)) {
                return;
            }

            let childs = cmps.filter(c => c.relIn && c.relIn.id === cmp.id).map(c => newComponentsMap[c.id]);
            if (childs.length) {
                parentChild[cmp.id] = childs;
                let
                    groups = divideInToGroups(childs),
                    totalDiff = 0;
                for (let groupIndex = 0; groupIndex < groups.length; groupIndex++) {
                    let diff = 0, group = groups[groupIndex];
                    if (groupIndex === 0) {
                        diff = (group.top - cmp.top > maxWhiteSpace) ? (group.top - cmp.top - minGapToMaintain) : 0;
                    } else if (group.top - groups[groupIndex - 1].bottom > maxWhiteSpace) {
                        diff = group.top - groups[groupIndex - 1].bottom - minGapToMaintain;
                    }
                    totalDiff += diff;
                    if (diff) {
                        for (let x = groupIndex; x < groups.length; x++) {
                            // eslint-disable-next-line no-loop-func
                            groups[x].cmps.forEach(c => {
                                const cmpInGroup = newComponentsMap[c.id];
                                newComponentsMap[c.id] = {
                                    ...cmpInGroup,
                                    top: cmpInGroup.top - diff
                                };
                                let allChilds = (parentChildsMap[c.id] || []).map(id => newComponentsMap[id]);
                                for (let m = 0; m < allChilds.length; m++) {
                                    const childId = allChilds[m].id,
                                        child = newComponentsMap[childId];
                                    newComponentsMap[childId] = {
                                        ...child,
                                        top: child.top - diff
                                    };
                                    // eslint-disable-next-line no-loop-func
                                    const children = (parentChildsMap[childId] || []).map(id => newComponentsMap[id]);
                                    allChilds.push(...children);
                                }
                            });
                        }
                        // eslint-disable-next-line no-loop-func
                        groups = divideInToGroups(childs.map(c => newComponentsMap[c.id]));
                    }
                }
                newComponentsMap[cmp.id] = {
                    ...newComponentsMap[cmp.id],
                    height: newComponentsMap[cmp.id].height - totalDiff
                };
                let childsBottom = 0;
                childs.forEach(({ id }) => {
                    const cmp = newComponentsMap[id];
                    childsBottom = Math.max(cmp.top + cmp.height, childsBottom);
                });
                cmp = newComponentsMap[cmp.id];
                if (cmp.top + cmp.height > childsBottom + minGapToMaintain) {
                    cmp.height = childsBottom + minGapToMaintain - cmp.top;
                }
            } else if (cmp.height > maxHeightOfContainer) {
                newComponentsMap[cmp.id] = {
                    ...newComponentsMap[cmp.id],
                    height: allowedEmptyContainerHeight
                };
            }
        };

        cmps.forEach(c => {
            let cmp = newComponentsMap[c.id];
            if (ContainerKinds[cmp.kind] && cmp.height > maxHeightOfContainer) {
                process(cmp);
            }
            if (cmp.relIn) {
                const parent = cmp.relIn.id;
                parentChildsMap[parent] = [
                    ...(parentChildsMap[parent] || []),
                    cmp.id
                ];
            }
        });

        const pageSections = getPageSections(newComponentsMap).sort((a, b) => a.top - b.top),
            { header, footer } = getHeaderAndFooterSection(newComponentsMap);

        const sections = [header, ...pageSections, footer];

        sections.forEach((s, i) => {
            if (i > 0) {
                const prevSection = sections[i - 1];
                const previewSectionBottom = newComponentsMap[prevSection.id].top + newComponentsMap[prevSection.id].height;
                if (previewSectionBottom !== newComponentsMap[s.id].top) {
                    let diff = newComponentsMap[s.id].top - previewSectionBottom;
                    cmps.forEach(cmp => {
                        let c = newComponentsMap[cmp.id];
                        if (c.top + (c.height / 2) >= previewSectionBottom) {
                            newComponentsMap[c.id] = {
                                ...newComponentsMap[c.id],
                                top: newComponentsMap[c.id].top - diff
                            };
                        }
                    });
                }
            }
        });

        newComponentsMap = updateComponentsRelIns(newComponentsMap, templateWidth);
        const isValidCmpMap = Object.keys(parentChildsMap).every(parent => {
            const children = parentChildsMap[parent];
            return children.every(child => {
                const cmp = newComponentsMap[child];
                return !!cmp.relIn && cmp.relIn.id === parent;
            });
        });
        if (!isValidCmpMap) {
            return componentsMap;
        }
        return newComponentsMap;
    } catch (e) {
        console.error(e);
        return componentsMap;
    }
};
