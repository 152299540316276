import * as R from 'ramda';
import {
    moveComponentsDown,
    moveComponentsToBottom,
    moveComponentsToTop,
    moveComponentsUp
} from "../../../../redux/modules/children/workspace/reducers/utils/moveComponents";
import { receiveOnly } from '../../../../epics/makeCondition';
import * as workspaceActionTypes from '../../actionTypes';
import isPageModeValueActionType from '../isPageMode/valueActionType';
import workspaceBBoxValueActionType from '../workspaceBBox/valueActionType';
import * as updateReasons from './updateReasons';
import type { ComponentsEvalEpicUpdater } from './flowTypes';
import { getSelectedComponentIdsAlongWithWrappedIds } from "./selectors";
import { componentAttachmentsVAT } from '../componentAttachements/valueActionType';
import { getAllAttachmentsForCmpIds, getTopMostParentId } from '../componentAttachements/util';
import { COMPONENTS_ORDER_CHANGED } from './actionTypes';
import { ReceiveOnlySelectedComponentSelector } from "./selectorActionTypes";
import { isHoverBoxKind } from "../../../oneweb/componentKinds";
import { getMaxOrderIndex } from "../../../../redux/modules/children/workspace/reducers/utils/index";
import { setComponentsMap } from "./setters";
import { PROPERTY_CHANGE } from "./updateReasons";
import { isContainer } from "../../../../utils/component";
import { getHoverBoxAttachments } from "../../../oneweb/HoverBox/utils";
import { isOverLapping } from "./utils";

const
    makeUpdater = (updateActionType: string, componentsMapUpdater): ComponentsEvalEpicUpdater => ({
        conditions: [
            receiveOnly(isPageModeValueActionType),
            receiveOnly(workspaceBBoxValueActionType),
            receiveOnly(componentAttachmentsVAT),
            updateActionType,
        ],
        reducer: ({ values: [pageMode, workspaceBBox, { attachments }], state: epicState }) => {
            const
                selectedComponentsIds = getSelectedComponentIdsAlongWithWrappedIds(epicState),
                attachedComponentsIds =
                    getAllAttachmentsForCmpIds(attachments, selectedComponentsIds, selectedComponentsIds),
                newEpicState = R.evolve({
                    state: {
                        componentsMap: componentsMap =>
                            componentsMapUpdater(componentsMap,
                                selectedComponentsIds,
                                attachedComponentsIds,
                                pageMode,
                                workspaceBBox,
                                attachments)
                    }
                }, epicState);

            return {
                state: newEpicState,
                actionToDispatch: {
                    type: COMPONENTS_ORDER_CHANGED,
                    payload: { componentsId: [...selectedComponentsIds, ...attachedComponentsIds] }
                },
                updateReason: updateReasons.ORDER_CHANGED
            };
        }
    }),
    getOverlappingComponent = (childComponents, potentialOverlappingComponents, checkFn) => {
        let overlappingCmps = [];
        childComponents.forEach(cmp => {
            overlappingCmps = potentialOverlappingComponents.reduce((acc, potentialOverlapCmp) => {
                return checkFn(cmp, potentialOverlapCmp) && isOverLapping(cmp, potentialOverlapCmp)
                    ? [...acc, potentialOverlapCmp] : acc;
            }, []);
        });
        return overlappingCmps;
    },
    getNestedOverlapCmps = (startingCmp, otherCmps) => {
        let cmpsToBeAdjusted = { [startingCmp.id]: { ...startingCmp } };
        const overLappingCmps: any = getOverlappingComponent(
            [startingCmp],
            otherCmps,
            (cmp, cmp2) => (cmp.orderIndex < cmp2.orderIndex)
        );

        if (overLappingCmps.length) {
            overLappingCmps.forEach(cmp => {
                if (!cmpsToBeAdjusted[cmp.id]) {
                    cmpsToBeAdjusted = {
                        ...cmpsToBeAdjusted,
                        ...getNestedOverlapCmps(cmp, otherCmps)
                    };
                }
            });
        }

        return cmpsToBeAdjusted;
    },
    adjustCmpsOrderIndex = (cmpsToBeAdjusted, componentsMap) => {
        let newCmpsMap = { ...componentsMap };
        const maxOrderIndex = getMaxOrderIndex(newCmpsMap);
        Object.keys(cmpsToBeAdjusted)
            .map(id => newCmpsMap[id])
            .sort((cmp1, cmp2) => {
                return cmp1.orderIndex - cmp2.orderIndex;
            })
            .forEach((cmp, index) => {
                newCmpsMap = {
                    ...newCmpsMap,
                    [cmp.id]: {
                        ...cmp,
                        orderIndex: maxOrderIndex + index + 1
                    }
                };
            });

        return newCmpsMap;
    },
    getOverlapCmpsToBeAdjusted = (hoverboxes, attachments, attachedCmpsInSection, componentsMap) => {
        return hoverboxes.reduce((cmpsToBeAdjusted, hoverbox) => {
            const hoverBoxChildren = getHoverBoxAttachments(hoverbox.id, attachments).map(id => componentsMap[id]);
            const nonHoverboxChildComponents = attachedCmpsInSection.filter(cmp =>
                !hoverBoxChildren.some(hoverBoxChild => hoverBoxChild.id === cmp.id) &&
                cmp.orderIndex > hoverbox.orderIndex &&
                cmp.id !== hoverbox.id &&
                !cmp.onHover);
            // get overlapping components with current hoverbox children
            const overlappingComponents = getOverlappingComponent(
                hoverBoxChildren,
                // filter out none hover cmps below current hoverbox and filter out current hoverbox from the list
                nonHoverboxChildComponents,
                (cmp, potentialOverlapCmp) => (cmp.orderIndex > potentialOverlapCmp.orderIndex)
            );
            return {
                ...cmpsToBeAdjusted,
                // get overlapping components with the current overlap component
                ...overlappingComponents.reduce((acc, overlapCmp) => {
                    return {
                        ...acc,
                        ...getNestedOverlapCmps(overlapCmp, nonHoverboxChildComponents)
                    };
                }, {})
            };
        }, {});
    },
    getAllContainerCmpIds = (cmpId, attachments, componentsMap) => {
        const componentIds = attachments[cmpId] || [];
        return componentIds.reduce((acc, id) => {
            acc.push(id);
            if (isContainer(componentsMap[id].kind)) {
                acc.push(...(getAllContainerCmpIds(id, attachments, componentsMap) || []));
            }
            return acc;
        }, []);
    };
export const
    bringToFrontUpdater = makeUpdater(
        workspaceActionTypes.SELECTED_COMPONENT_BRING_TO_FRONT,
        moveComponentsToTop
    ),
    moveUpUpdater = makeUpdater(
        workspaceActionTypes.SELECTED_COMPONENT_SEND_FORWARD,
        moveComponentsUp
    ),
    moveDownUpdater = makeUpdater(
        workspaceActionTypes.SELECTED_COMPONENT_SEND_BACKWARDS,
        moveComponentsDown
    ),
    sendToBackUpdater = makeUpdater(
        workspaceActionTypes.SELECTED_COMPONENT_SEND_TO_BACK,
        moveComponentsToBottom
    ),
    hoverboxFloatingComponentsOrderIndexUpdater = {
        conditions: [
            ReceiveOnlySelectedComponentSelector,
            componentAttachmentsVAT
        ],
        reducer: ({ values: [selectedComponent, { attachments }], state: epicState }) => {
            if (!selectedComponent) return { state: epicState };

            let componentsMap = { ...epicState.state.componentsMap };
            const
                parentSectionId = getTopMostParentId(selectedComponent.id, attachments),
                attachedComponentsIds = getAllContainerCmpIds(parentSectionId, attachments, componentsMap) || [],
                hoverboxes = attachedComponentsIds
                    .filter(id => isHoverBoxKind(componentsMap[id].kind))
                    .map(id => componentsMap[id]),
                attachedCmpsInSection = attachedComponentsIds
                    .map(id => componentsMap[id]);

            const cmpsToBeAdjusted = getOverlapCmpsToBeAdjusted(
                hoverboxes,
                attachments,
                attachedCmpsInSection,
                componentsMap
            );

            if (Object.keys(cmpsToBeAdjusted).length) {
                componentsMap = adjustCmpsOrderIndex(cmpsToBeAdjusted, componentsMap);

                return {
                    state: R.compose(
                        setComponentsMap(componentsMap)
                    )(epicState),
                    updateReason: PROPERTY_CHANGE
                };
            }

            return { state: epicState };
        }
    };
