import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly } from '../../../../epics/makeCondition';
import valueActionType from './valueActionType';
import * as ActionTypes from '../../actionTypes';
import type { OnBoardingPanelEpicState } from '../../flowTypes';
import type { EpicUpdaterConfig } from '../../../../epics/flowTypes';
import { headerSectionIdVAT } from '../../../oneweb/Section/epics/headerSectionIdEpic';
import { footerSectionIdVAT } from '../../../oneweb/Section/epics/footerSectionIdEpic';
import { WORKSPACE_SCROLL_TO_COMPONENT } from '../../../Workspace/actionTypes';
import { ONBOARDING_STEPS, HEADER_FOOTER_LAYOUT_STEP, SELECT_HEADER_LAYOUT_STEP,
    SAVING_HEADER_LAYOUT_STEP, SELECT_FOOTER_LAYOUT_STEP, TOGGLE_HEADER_LAYOUT_STEP } from '../../constants';
import { PREFERENCES_EPIC_VALUE_VAT } from "../../../Preferences/preferencesVat";
import { savePreferencesAction } from "../../../Preferences/actions";
import { showModernLayoutInfoTooltipAC } from "../../../Tooltip/epics/modernLayoutInfoTooltip/index";
import { undoManagerValueActionType } from '../../../../epics/undoManager/valueActionType';
import { RESET_UNDO_STACK_INDEX_AND_APPLY } from '../../../../epics/undoManager/actionTypes';
import { APP_RIGHT_MOUSE_DOWN } from "../../../App/actionTypes";
import { APP_LEFT_MOUSE_DOWN } from "../../../../redux/modules/actionTypes";
import { TOP_BAR_OPEN_POPUP } from "../../../TopBar/actionTypes";
import makeStateSelectorReducer from '../../../../epics/makeStateSelectorReducer';
import isTestEnv from "../../../../debug/isTestEnv";

const defaultState = {
    show: false,
    currentStep: 0,
    undoStackIndex: -1,
    isModernHeaderActivated: false,
    activatedHeaderId: null,
    activatedFooterId: null,
    isModernFooterActivated: false,
    isNextBtnActivated: true
};

const SAVING_HEADER_LAYOUT_STEP_DELAY = 1000;

const epic = makeEpic({
    defaultState,
    valueActionType,
    updaters: ([
        {
            conditions: [
                receiveOnly(undoManagerValueActionType),
                receiveOnly(PREFERENCES_EPIC_VALUE_VAT),
                ActionTypes.SHOW_MODERN_LAYOUT_ONBOARDING_PANEL,
            ],
            reducer: ({ state, values: [{ undoState }, preferences] }) => {
                if (state.show) {
                    return { state };
                }
                const multipleActionsToDispatch = [showModernLayoutInfoTooltipAC()];
                if (!isTestEnv() && preferences && !preferences.modernLayoutOnboardingPanelSeenTimestamp) {
                    multipleActionsToDispatch.push(savePreferencesAction({
                        modernLayoutOnboardingPanelSeenTimestamp: new Date().getTime()
                    }));
                }
                return {
                    state: {
                        ...state,
                        show: true,
                        activatedHeaderId: null,
                        activatedFooterId: null,
                        undoStackIndex: undoState.commandsStackIndex,
                    },
                    multipleActionsToDispatch
                };
            },
        },
        ...[
            TOP_BAR_OPEN_POPUP,
            APP_LEFT_MOUSE_DOWN,
            APP_RIGHT_MOUSE_DOWN,
        ]
            .map(action => ({
                conditions: [action],
                reducer: ({ state }) => {
                    if (state.currentStep !== 0) {
                        return { state };
                    }
                    return {
                        state: {
                            ...defaultState,
                        },
                        actionToDispatch: { type: ActionTypes.MODERN_LAYOUT_ONBOARDING_PANEL_IS_CLOSED }
                    };
                },
            })),
        {
            conditions: [
                ActionTypes.HIDE_MODERN_LAYOUT_ONBOARDING_PANEL,
            ],
            reducer: () => ({
                state: {
                    ...defaultState,
                },
                actionToDispatch: { type: ActionTypes.MODERN_LAYOUT_ONBOARDING_PANEL_IS_CLOSED }
            }),
        },
        {
            conditions: [
                ActionTypes.CLOSE_MODERN_LAYOUT_ONBOARDING_PANEL,
            ],
            reducer: ({ state }) => {
                const multipleActionsToDispatch: Array<any> = [
                    { type: RESET_UNDO_STACK_INDEX_AND_APPLY, payload: state.undoStackIndex },
                    { type: ActionTypes.HIDE_MODERN_LAYOUT_ONBOARDING_PANEL },
                    { type: ActionTypes.MODERN_LAYOUT_ONBOARDING_TOGGLE_CMPS_UNSET }];
                return {
                    state: {
                        ...state
                    },
                    multipleActionsToDispatch
                };
            },
        },
        {
            conditions: [
                receiveOnly(headerSectionIdVAT),
                receiveOnly(footerSectionIdVAT),
                ActionTypes.GOTO_NEXT_STEP_ONBOARDING_PANEL,
            ],
            reducer: ({ values: [headerId, footerId], state, dispatchAsync }) => {
                let actionToDispatch: Action | null = null,
                    isNextBtnActivated = false;
                if (ONBOARDING_STEPS[state.currentStep] === HEADER_FOOTER_LAYOUT_STEP) {
                    actionToDispatch = { type: WORKSPACE_SCROLL_TO_COMPONENT, payload: headerId };
                    isNextBtnActivated = state.isModernHeaderActivated ? true : isNextBtnActivated;
                } else if (ONBOARDING_STEPS[state.currentStep] === TOGGLE_HEADER_LAYOUT_STEP) {
                    actionToDispatch = { type: WORKSPACE_SCROLL_TO_COMPONENT, payload: footerId };
                    setTimeout(() => {
                        dispatchAsync({ type: ActionTypes.GOTO_NEXT_STEP_ONBOARDING_PANEL });
                    }, SAVING_HEADER_LAYOUT_STEP_DELAY);
                } else if (ONBOARDING_STEPS[state.currentStep] === SAVING_HEADER_LAYOUT_STEP ||
                     ONBOARDING_STEPS[state.currentStep] === SELECT_FOOTER_LAYOUT_STEP) {
                    isNextBtnActivated = state.isModernFooterActivated ? true : isNextBtnActivated;
                } else if (ONBOARDING_STEPS[state.currentStep] === SELECT_HEADER_LAYOUT_STEP) {
                    isNextBtnActivated = state.isModernHeaderActivated ? true : isNextBtnActivated;
                }
                return {
                    state: {
                        ...state,
                        currentStep: state.currentStep + 1,
                        isNextBtnActivated
                    },
                    actionToDispatch,
                };
            },
        },
        {
            conditions: [
                receiveOnly(headerSectionIdVAT),
                ActionTypes.GOTO_PREV_STEP_ONBOARDING_PANEL,
            ],
            reducer: ({ values: [headerId], state }) => {
                let actionToDispatch: Action | null = null,
                    nextStep = state.currentStep - 1;
                if (ONBOARDING_STEPS[state.currentStep] === SELECT_HEADER_LAYOUT_STEP) {
                    actionToDispatch = { type: RESET_UNDO_STACK_INDEX_AND_APPLY, payload: state.undoStackIndex };
                    return {
                        state: {
                            ...state,
                            currentStep: nextStep,
                            isModernHeaderActivated: false,
                            activatedHeaderId: null,
                            isModernFooterActivated: false,
                            isNextBtnActivated: true
                        },
                        actionToDispatch,
                    };
                }
                if (ONBOARDING_STEPS[state.currentStep] === SELECT_FOOTER_LAYOUT_STEP) {
                    nextStep = nextStep - 1;
                    actionToDispatch = { type: WORKSPACE_SCROLL_TO_COMPONENT, payload: headerId };
                }
                return {
                    state: {
                        ...state,
                        currentStep: nextStep,
                        isNextBtnActivated: true
                    },
                    actionToDispatch,
                };
            },
        },
        ...[
            ActionTypes.ACTIVATE_MODERN_HEADER,
            ActionTypes.SWITCH_MODERN_HEADER,
        ]
            .map(action => ({
                conditions: [action],
                reducer: ({ state, values: [headerId] }) => {
                    return {
                        state: {
                            ...state,
                            activatedHeaderId: headerId,
                        },
                    };
                },
            })),
        {
            conditions: [
                ActionTypes.MODERN_HEADER_ACTIVATED
            ],
            reducer: ({ state }) => ({
                state: {
                    ...state,
                    isModernHeaderActivated: true,
                    isNextBtnActivated: true
                },
            }),
        },
        ...[
            ActionTypes.ACTIVATE_MODERN_FOOTER,
            ActionTypes.SWITCH_MODERN_FOOTER,
        ]
            .map(action => ({
                conditions: [action],
                reducer: ({ state, values: [footerId] }) => {
                    return {
                        state: {
                            ...state,
                            activatedFooterId: footerId,
                        },
                    };
                },
            })),
        {
            conditions: [
                ActionTypes.MODERN_FOOTER_ACTIVATED
            ],
            reducer: ({ state }) => ({
                state: {
                    ...state,
                    isModernFooterActivated: true,
                    isNextBtnActivated: true,
                },
            }),
        }
    ] as Array<EpicUpdaterConfig<OnBoardingPanelEpicState, any, any>>)
});

const isModernLayoutOnBoardingPanelOpenReducer = makeStateSelectorReducer(
    epic.reducer,
    valueActionType,
    (epicState: Record<string, any>) => epicState.show
);

export {
    isModernLayoutOnBoardingPanelOpenReducer,
    epic as default
};
