import React from 'react';
import cx from 'classnames';
import { connect } from "react-redux";
import styles from './FileChooserGrid.css';
import {
    resourcesSelector, ghostResourcesSelector,
    lastSelectedResourceIndexSelector
} from "../../../../redux/modules/children/fileChooser/selectors";
import Resource from "../../../../redux/modules/children/fileChooser/Resource";
import Grid from '../../GridLayout/index';
import Cell from './Cell/Cell';
import getStyleIntValue from "../../../../utils/getStyleIntValue";
import { GRID_ROW_SIZE, GUTTER } from "../constants";
import type { AppState } from "../../../../redux/modules/flowTypes";
import type { FileChooserGridProps } from "../flowTypes";

type State = { scrollToCell: null | undefined | number };

const ImagesPerPage = 60;

function Pagination(limit = 30) {
    let currentPage = 1;
    // @ts-ignore
    this.getCurrentPage = () => currentPage;
    // @ts-ignore
    this.goToNextPage = () => { currentPage++; };
    // @ts-ignore
    this.getData = (dataset: Array<any>) => dataset.slice(0, currentPage * limit);
}

class FileChooserGrid extends React.Component<FileChooserGridProps, State> {
    pagination: Record<string, any>;

    constructor() {
        // @ts-ignore
        super();
        this.state = { scrollToCell: null };
        this.pagination = new Pagination(ImagesPerPage);
        this.onScroll = this.onScroll.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.setState({ scrollToCell: this.props.lastSelectedResourceIndex });
    }

    UNSAFE_componentWillReceiveProps() {
        this.setState({ scrollToCell: null });
    }

    onScroll(val) {
        const { scrollHeight, clientHeight, scrollTop } = val;

        // when browser is zoomed then the scroll top has some pixel difference
        if ((scrollHeight - clientHeight) <= (scrollTop + 1)) {
            this.pagination.goToNextPage();
            this.forceUpdate();
        }
    }

    render() {
        const getUniqueResources = (res) => {
            const uniqueResIds: string[] = [],
                uniqueRes = res.filter(r => {
                const isDuplicate = uniqueResIds.includes(r.id);
                if (!isDuplicate) {
                    uniqueResIds.push(r.id);
                    return true;
                }
                return false;
            });
            return uniqueRes;
        };
        const
            {
                resources,
                ghostResources,
                onResourceDoubleClick,
                gutter: propGutter,
                rowSize: propRowSize,
                containerClassName,
                height: propHeight,
                isFreeOneComVideoFileChooser
            } = this.props,
            height = propHeight || styles.height,
            allResources = ghostResources ? getUniqueResources([...resources, ...ghostResources]) : resources;
            const dataSet = this.pagination.getData(allResources).map((resource: Resource) => ({
                resource,
                onResourceDoubleClick
            })),
            gutter = propGutter || GUTTER,
            rowSize = propRowSize || GRID_ROW_SIZE;

        return (
            <div className={cx(styles.grid, containerClassName)}>
                <Grid
                    height={height}
                    rowSize={rowSize}
                    dataSet={dataSet}
                    onScroll={this.onScroll}
                    cell={Cell}
                    isFreeOneComVideo={isFreeOneComVideoFileChooser || false}
                    cellHeight={getStyleIntValue(styles, 'cellHeight')}
                    gutterWidth={gutter}
                    gutterHeight={gutter}
                    isLazy
                    scrollToCell={this.state.scrollToCell}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ fileChooser }: AppState) => ({
    resources: resourcesSelector(fileChooser),
    ghostResources: ghostResourcesSelector(fileChooser),
    lastSelectedResourceIndex: lastSelectedResourceIndexSelector(fileChooser)
});

export default connect(mapStateToProps)(FileChooserGrid);
