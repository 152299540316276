import React from 'react';
import type { CodeComponent } from '../flowTypes';
import { ComponentMaskBackground } from '../../../presentational/ComponentMask/constants';
import ComponentMask from '../../../presentational/ComponentMask/index';
import BaseComponent from '../../../../view/oneweb/BaseComponent';
import View from "./index";

type Props = {
    skipWrapInBaseComponent ?: boolean,
    inEditMode: boolean,
    inSelectedMode: boolean,
    componentTierData: Record<string, any>,
    component: CodeComponent
}

const MaskedComponent = (props: Props) => {
    return (
        <ComponentMask
            showPreviewOnlyLayer={false}
            style={{ height: props.component.height }}
            maskStyle={ComponentMaskBackground.TRANSPARENT}
            inEditMode={props.inEditMode}
            inSelectedMode={props.inSelectedMode}
            // @ts-ignore
            componentTierData={props.componentTierData}
            componentWidth={props.component.width}
        >
            { /* @ts-ignore */ }
            <View {...props} />
        </ComponentMask>
    );
};

export default (props: Props) => {
    if (props.skipWrapInBaseComponent) {
        return (<MaskedComponent {...props} />);
    }

    return (
        <BaseComponent {...props}>
            <MaskedComponent {...props} />
        </BaseComponent>
    );
};
