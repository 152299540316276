import React, { useEffect } from 'react';
import { loadScript } from '../../../../../utils/loadScript';
import '../../view/Preview.css';
import { DATA_TEST_ID } from '../constants';

function initPayPalButton(ref, props) {
    const element = ref.current;
    window.paypal.Buttons({
        style: {
            shape: props.shape,
            color: props.color,
            layout: props.layout,
            label: props.label,
        },
    }).render(element);
}

export const PaypalView = props => {
    const ref = React.useRef<HTMLDivElement>(null);
    useEffect(() => {
        loadScript(props.scriptLink)
        .then(() => {
            if (ref.current) {
                ref.current.innerHTML = '';
            }
            initPayPalButton(ref, props);
        });
    }, [
        props.scriptLink,
        props.label,
        props.shape,
        props.color,
        props.layout
    ]);

    return (
        <div data-testid={DATA_TEST_ID} className="widget" style={{ width: props.width }}>
            <div
                ref={ref}
                id="paypal-button-container"
                className="paypal-button-container"
                style={{ width: props.width }}
                data-label={props.label}
                data-color={props.color}
                data-shape={props.shape}
                data-layout={props.layout}
                data-purchase-unit={props.purchaseUnit}
                data-scriptlink={encodeURI(props.scriptLink)}
            />
        </div>
    );
};
