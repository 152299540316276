import ButtonKind from "../oneweb/Button/globalStyle/kind";
import LinkKind from '../oneweb/Link/globalStyle/kind';
import MenuKind from "../oneweb/Menu/globalStyle/kind";
import TextKind from '../oneweb/Text/globalStyle/kind';
import TableKind from "../oneweb/Table/globalStyle/kind";

export const
    height = 855,
    width = 1000,
    GlobalStyleTabs = [
        TextKind,
        LinkKind,
        MenuKind,
        ButtonKind,
        TableKind
    ],
    GLOBAL_STYLES_DIALIG_MIN_HEIGHT = 350;
