import type { AwsActionParams, InstanceAwsActionParams } from './types';
import { AwsServiceName } from '../../../services/aws/awsServiceRegistry';

export const AWS_SERVICE_ACTION = 'AWS_SERVICE_ACTION';
export const AWS_AUTHENTICATION_UNSUCCESSFUL = 'AWS_AUTHENTICATION_UNSUCCESSFUL';

const awsServiceAction = (params: AwsActionParams) => ({
    type: AWS_SERVICE_ACTION,
    payload: params,
});

export const awsAuthAction = (params: InstanceAwsActionParams) => awsServiceAction({
    serviceName: AwsServiceName.AUTH,
    ...params,
});

export const awsDynamoDbAction = (params: InstanceAwsActionParams) => awsServiceAction({
    serviceName: AwsServiceName.DYNAMO_DB,
    ...params,
});

export const awsS3Action = (params: InstanceAwsActionParams) => awsServiceAction({
    serviceName: AwsServiceName.S3,
    ...params,
});

export const awsAuthenticationUnsuccessful = () => ({
    type: AWS_AUTHENTICATION_UNSUCCESSFUL
});
