import { PARAGRAPH_TAGS } from '../constants';
import { isEmptyTextNode } from './isEmptyTextNode';
import Node from './Node';

const getEmptyTextNode = (node: Node): null | undefined | Node => {
    if (isEmptyTextNode(node)) {
        return node;
    }

    if (node.childNodes.length !== 1) {
        return null;
    }
    const childNode = node.childNodes[0];
    return getEmptyTextNode(childNode);
};

// todo make it smarter
// empty paragraph has <span>&#65279;</span> childs;
export default (node: Node): boolean => {
    if (PARAGRAPH_TAGS.indexOf(node.tag) < 0) {
        return false;
    }

    if (node.childNodes.length !== 1) {
        return false;
    }
    return Boolean(getEmptyTextNode(node.childNodes[0]));
};
