import VIDEO from "./kind";
import { ComponentNames } from "../constants";

export const
    DefaultVideoCdnUrl = "repository:/WSB-Default-Video.mp4",
    FreeOneComVideosCdnUrl = "repository:/videos",
    VideoComponentConfigDialogConfig = {
        dimensions: { width: 634, height: 331 },
        modal: true
    },
    YoutubeVideoComponentConfigDialogConfig = {
        dimensions: { width: 634, height: 331 },
        modal: true
    },
    ConfigDialogSettings = {
        TITLE: ComponentNames[VIDEO]
    },
    VideoSettings = {
        href: "href",
        showInfo: "showInfo",
        autoPlay: "autoPlay",
        repeat: "repeat",
        showControls: "showControls",
        muted: "muted",
        fit: "fit"
    },
    VideoTypes = {
        YOUTUBE: 'web.data.components.widgets.Youtube',
        FILE: 'Video_File'
    },
    TRANSCODE_STATUS = {
        DEFAULT: 'default',
        UPLOADED: 'uploaded',
        PROGRESSING: 'progressing',
        COMPLETED: 'completed',
        ERROR: 'error'
    },
    YoutubeUrlPattern = '(?:http|https)://www\\.youtube\\.com/watch\\?v=([^&]+).*',
    VideosContainerHeight = 393,
    YoutubeVideosContainerHeight = 393,
    VideoUrlPrefix = 'http://',
    VideoUrlPrefixWithS = 'https://',
    VideoUrlPrefixPattern = VideoUrlPrefix + '(?:http|https)://',
    MIN_DIMENSIONS = { width: 200, height: 200 },
    defaultSettings = [
        {
            ref: VideoSettings.showInfo,
            value: true
        },
        {
            ref: VideoSettings.autoPlay,
            value: false
        },
        {
            ref: VideoSettings.repeat,
            value: false
        },
        {
            ref: VideoSettings.showControls,
            value: true
        },
        {
            ref: VideoSettings.fit,
            value: false
        }
    ],
    getDefultSettingValue = (setting: string) => {
        const obj = defaultSettings.find(obj => obj.ref === setting);
        return obj ? obj.value : null;
    },
    VideoExtensions = [
        'webm', 'mkv', 'flv', 'f4v', 'f4p', 'f4a', 'f4b', 'vob', 'ogv', 'ogg',
        'drc', 'avi', 'mov', 'qt', 'wmv', 'amv', '3gp', '3g2',
        'mp2', 'mp4', 'm4p', 'm2v', 'm4v', 'mpg', 'mpeg', 'mpe', 'mpv'
    ],
    videoExtMimeTypeMap = {
        'flv': 'video/x-flv',
        'mp4': 'video/mp4',
        'm3u8': 'application/x-mpegURL',
        'ts': 'video/MP2T',
        '3gp': 'video/3gpp',
        'mov': 'video/quicktime',
        'avi': 'video/x-msvideo',
        'wmv': 'video/x-ms-wmv'
    },
    getDefaultVideoFileAsset = () => ({
        "isGhost": false,
        "isLoading": false,
        "isTrial": false,
        "basePath": "onewebmedia",
        "contentType": "video/mp4",
        "href": "", // <video_name>
        "resourceType": "file",
        "size": 15598407, // To be changed once we have default video
        "createdDate": 1646826652000, // To be changed once we have default video
        "modifiedDate": 1646826652000, // To be changed once we have default video
        "etag": "\"ee0347-5d9c7b5a20397\"", // To be changed once we have default video
        "id": "8e138d2e7026b66f01d4c1db6b7190975ced9e47", // To be changed once we have default video
        "url": DefaultVideoCdnUrl
    });
