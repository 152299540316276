import React from 'react';
import * as onClickActions from './constants/onClickActions';
import ComboBox from '../../../view/common/Combobox/index';
import { intlCreateOptions } from '../../../view/common/Combobox/utils';
import PropertyContainer from '../../../view/common/PropertyContainer/index';
import injectIntl, { Intl } from "../../../view/intl/injectIntl";

type Props = {
    actionType: string,
    value: string,
    intl: Intl,
    dispatch: Dispatch
}

const
    onClickOptions = {
        [onClickActions.NOTHING]: 'msg: component.common.pp.onClick.actions.doNothing {Do nothing}',
        [onClickActions.OPEN_LIGHT_BOX]: 'msg: component.common.pp.onClick.actions.showLargeImage {Show large image}',
        [onClickActions.OPEN_LINK]: 'msg: component.common.pp.onClick.actions.openLink {Open link}'
    },
    View = ({ actionType, value, intl, dispatch }: Props) => (
        <PropertyContainer label="msg: component.common.pp.onClickOfAnImage {On click of an image}">
            <ComboBox
                searchable={false}
                value={value}
                options={intlCreateOptions(onClickOptions, intl)}
                onChange={
                    ({ value }) => dispatch(
                        {
                            type: actionType,
                            payload: value
                        }
                    )
                }
            />
        </PropertyContainer>
    );

export default injectIntl(View);
