export const
    TINY_MCE_INITIALIZED = 'TINY_MCE_INITIALIZED',
    TINY_MCE_DESTROYED = 'TINY_MCE_DESTROYED',
    TINY_MCE_QUERY_STATE_FOR_GIVEN_CONTENT = 'TINY_MCE_QUERY_STATE_FOR_GIVEN_CONTENT',
    TINY_MCE_SELECTION_CHANGED = 'TINY_MCE_SELECTION_CHANGED',
    TINY_MCE_PICK_LINKED_INPUT_TRIGGER_CHAR_ENTERED = 'TINY_MCE_PICK_LINKED_INPUT_TRIGGER_CHAR_ENTERED',
    TINY_MCE_FOCUS_LAST_NODE = 'TINY_MCE_FOCUS_LAST_NODE',
    TINY_MCE_SET_TEXT_COLOR = 'TINY_MCE_SET_TEXT_COLOR',
    TINY_MCE_SET_SHADOW_COLOR = 'TINY_MCE_SET_SHADOW_COLOR',
    TINY_MCE_SET_SHADOW_BLUR_RADIUS = 'TINY_MCE_SET_SHADOW_BLUR_RADIUS',
    TINY_MCE_SET_SHADOW_OFFSET_X = 'TINY_MCE_SET_SHADOW_OFFSET_X',
    TINY_MCE_SET_SHADOW_OFFSET_Y = 'TINY_MCE_SET_SHADOW_OFFSET_Y',
    TINY_MCE_REMOVE_SHADOW = 'TINY_MCE_REMOVE_SHADOW',
    TINY_MCE_SET_LINK_IN_CONTENT_BLOCK = 'TINY_MCE_SET_LINK_IN_CONTENT_BLOCK',
    TINY_MCE_SET_LINK_ACROSS_CONTENT_BLOCKS = 'TINY_MCE_SET_LINK_ACROSS_CONTENT_BLOCKS',
    TINY_MCE_REMOVE_LINK = 'TINY_MCE_REMOVE_LINK',
    TINY_MCE_TOGGLE_BOLD = 'TINY_MCE_TOGGLE_BOLD',
    TINY_MCE_TOGGLE_ITALIC = 'TINY_MCE_TOGGLE_ITALIC',
    TINY_MCE_TOGGLE_UNDERLINE = 'TINY_MCE_TOGGLE_UNDERLINE',
    TINY_MCE_SET_HIGHLIGHT_COLOR = 'TINY_MCE_SET_HIGHLIGHT_COLOR',
    TINY_MCE_REMOVE_HIGHLIGHT_COLOR = 'TINY_MCE_REMOVE_HIGHLIGHT_COLOR',
    TINY_MCE_SET_HORIZONTAL_ALIGNMENT = 'TINY_MCE_SET_HORIZONTAL_ALIGNMENT',
    TINY_MCE_SET_FONT_FAMILY = 'TINY_MCE_SET_FONT_FAMILY',
    TINY_MCE_TOGGLE_ORDERED_LIST = 'TINY_MCE_TOGGLE_ORDERED_LIST',
    TINY_MCE_TOGGLE_UNORDERED_LIST = 'TINY_MCE_TOGGLE_UNORDERED_LIST',
    TINY_MCE_INDENT = 'TINY_MCE_INDENT',
    TINY_MCE_INSERT_CONTENT = 'TINY_MCE_INSERT_CONTENT',
    TINY_MCE_OUTDENT = 'TINY_MCE_OUTDENT',
    TINY_MCE_SET_SUBSCRIPT = 'TINY_MCE_SET_SUBSCRIPT',
    TINY_MCE_SET_SUPERSCRIPT = 'TINY_MCE_SET_SUPERSCRIPT',
    TINY_MCE_REMOVE_GLOBAL_STYLES_AND_FORMATS = 'TINY_MCE_REMOVE_GLOBAL_STYLES_AND_FORMATS',
    TINY_MCE_SET_GLOBAL_STYLE = 'TINY_MCE_SET_GLOBAL_STYLE',
    TINY_MCE_SET_LINK_GLOBAL_STYLE = 'TINY_MCE_SET_LINK_GLOBAL_STYLE',
    TINY_MCE_SET_CHARACTER_SPACING = 'TINY_MCE_SET_CHARACTER_SPACING',
    TINY_MCE_SET_LINE_SPACING = 'TINY_MCE_SET_LINE_SPACING',
    TINY_MCE_SET_FONT_SIZE = 'TINY_MCE_SET_FONT_SIZE',
    TINY_MCE_REMOVE_FORMAT = 'TINY_MCE_REMOVE_FORMAT',
    TINY_MCE_SET_PARAGRAPH_SPACING = 'TINY_MCE_SET_PARAGRAPH_SPACING',
    TINY_MCE_SET_CONTENT = 'TINY_MCE_SET_CONTENT',
    TINY_MCE_APPLY_CHANGE_FOR_MULTIPLE_INSTANCES = 'TINY_MCE_APPLY_CHANGE_FOR_MULTIPLE_INSTANCES',
    TINY_MCE_CHANGE_FOR_MULTIPLE_INSTANCES_APPLIED = 'TINY_MCE_CHANGE_FOR_MULTIPLE_INSTANCES_APPLIED',
    TINY_MCE_MULTI_PROCESSING_STEP = 'TINY_MCE_MULTI_PROCESSING_STEP',
    TINY_MCE_MULTI_PROCESSING_CANCELED = 'TINY_MCE_MULTI_PROCESSING_CANCELED',
    TINY_MCE_PUT_CURSOR_AT_THE_END_OF_EDITOR = 'TINY_MCE_PUT_CURSOR_AT_THE_END_OF_EDITOR',

    TYNY_MCE_ENTER_KEY_DOWN = 'TYNY_MCE_ENTER_KEY_DOWN',
    TINY_MCE_PICK_LINKED_VALUE_CTX_MENU_MOUSE_LEAVE = 'TINY_MCE_PICK_LINKED_VALUE_CTX_MENU_MOUSE_LEAVE',
    TINY_MCE_PICK_LINKED_VALUE_CTX_MENU_ITEM_CLICK = 'TINY_MCE_PICK_LINKED_VALUE_CTX_MENU_ITEM_CLICK',
    TINY_MCE_PICK_LINKED_VALUE_CTX_MENU_ITEM_HOVER = 'TINY_MCE_PICK_LINKED_VALUE_CTX_MENU_ITEM_HOVER',
    TINY_MCE_SYNC_CONTENT = 'TINY_MCE_SYNC_CONTENT';

