import * as main from './pages/main';
import * as reviewButton from './pages/reviewButton';
import * as headerText from './pages/text';
import makePages from '../../../PropertiesPanel/view/makePages';

const googleReviewPropertiesPanelConfig = {
    pages: makePages(main, reviewButton, headerText),
    defaultPageId: main.id,
};

export {
    googleReviewPropertiesPanelConfig as default,
    googleReviewPropertiesPanelConfig,
};
