import React from "react";
import cx from "classnames";
import type { SideBarItemDef } from "../../SideBar/types";
import imageStyles from "../../Images/imageStyle.css";
import { bookingsTrialLearnMoreClickAction, openBookingsWebsiteAction } from "./actions";
import { isBookingsEnabledFromConfig } from "../../../utils/configUtils";
import { RightSideIcon } from "./RightSideIcon";
import { savePreferencesAction } from "../../Preferences/actions";
import { HIDE_BOOKINGS_TRIAL_DIALOG } from "./BookingsTrialDialog";
import { HIDE_BOOKINGS_TRIAL_TOOLTIP } from "./BookingsTrialTooltip";
import { AppState } from "../../../common/types";

export const BOOKINGS_PAGE_SIDE_BAR_ITEM_ID = "BOOKINGS_PAGE_SIDE_BAR_ITEM_ID";

export const ONE_COM = "one.com";

export const BookingsSideBarItem: SideBarItemDef = {
    id: BOOKINGS_PAGE_SIDE_BAR_ITEM_ID,
    icon: cx(imageStyles.bookingsMenuIcon, imageStyles.regularImageStyles),
    rightSideIcon: <RightSideIcon />,
    title: "msg: bookings {Bookings}",
    header: {
        title: "msg: seoLong {SEO (Search Engine Optimization)}",
        description: "msg: dashboard.bookings.page.description {Manage how this page shows up in search engine results.}"
    },
    clickHandler: ({ dispatch, handleClose }) => {
        dispatch(openBookingsWebsiteAction());
        dispatch(bookingsTrialLearnMoreClickAction(`'Bookings' in navigation`));
        dispatch(savePreferencesAction({ [HIDE_BOOKINGS_TRIAL_DIALOG]: true, [HIDE_BOOKINGS_TRIAL_TOOLTIP]: true }));

        if (typeof handleClose === "function") {
            setTimeout(handleClose, 320);
        }
    },
    disabled: (appState?: AppState) => !isBookingsEnabledFromConfig(appState),
    testId: "bookings-sidebar-item"
};
