import * as R from 'ramda';
import registry from '../../../../view/oneweb/registry/index';
import WORKSPACE_COMPONENT_KIND from './WORKSPACE_COMPONENT_KIND';
import { SELECTED_COMPONENT } from '../../../../redux/forwardTo';
import { WINDOW_MOUSE_MOVE } from '../../../App/actionTypes';
import type {
    ComponentsMap,
    ComponentsMapExtension,
    ComponentsDependencies
} from '../../../../redux/modules/children/workspace/flowTypes';
import { wrapComponentReducer } from '../wrap/reducer';

const ignoreActionTypes = {
    [WINDOW_MOUSE_MOVE]: true
};

type StateType = {
    componentsMap: ComponentsMap,
    componentsMapExtension: ComponentsMapExtension
};

type Props = {
    state: StateType,
    action: AnyAction,
    selectedComponentId: string | null | undefined,
    componentsDependencies: ComponentsDependencies
}

export default ({
    state,
    action,
    selectedComponentId,
    componentsDependencies
}: Props): StateType => {
    if (ignoreActionTypes[action.type]) {
        return state;
    }

    const
        { componentsMap, componentsMapExtension } = state,
        newState = Object.keys(componentsMap)
            .filter(componentId => {
                if (!action.forwardTo) {
                    return true;
                }

                return (
                    (action.forwardTo.kind === SELECTED_COMPONENT && selectedComponentId === componentId) ||
                    (action.forwardTo.kind === WORKSPACE_COMPONENT_KIND && action.forwardTo.id === componentId)
                );
            })
            .reduce(([changedComponentsMap, changedComponentsMapExtension], key) => {
                const
                    component = componentsMap[key],
                    componentExtension = componentsMapExtension[key],
                    { reducer, reducerForExtension = R.identity } = registry[component.kind],
                    possiblyChangedComponent = R.pipe(
                        // @ts-ignore
                        component => wrapComponentReducer(component, action),
                        component => reducer(component, action, componentsDependencies[component.kind])
                    )(component),
                    possiblyChangedComponentExtension =
                        reducerForExtension(componentExtension, { ...action, componentId: key, component });
                if (possiblyChangedComponent !== component) {
                    changedComponentsMap[key] = possiblyChangedComponent; //eslint-disable-line
                }
                if (possiblyChangedComponentExtension && possiblyChangedComponentExtension !== componentExtension) {
                    changedComponentsMapExtension[key] = possiblyChangedComponentExtension; //eslint-disable-line
                }
                return [changedComponentsMap, changedComponentsMapExtension];
            }, [{}, {}]),
        changedComponentsMap = newState[0],
        changedComponentsMapExtension = newState[1];

    if (Object.keys(changedComponentsMap).length === 0 && Object.keys(changedComponentsMapExtension).length === 0) {
        return state;
    }

    let
        newComponentsMap = componentsMap,
        newComponentsMapExtension = componentsMapExtension;

    if (Object.keys(changedComponentsMap).length > 0) {
        newComponentsMap = {
            ...componentsMap,
            ...changedComponentsMap
        };
    }

    if (Object.keys(changedComponentsMapExtension).length > 0) {
        newComponentsMapExtension = {
            ...componentsMapExtension,
            ...changedComponentsMapExtension
        };
    }

    return { componentsMap: newComponentsMap, componentsMapExtension: newComponentsMapExtension };
};
