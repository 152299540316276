import React from "react";

import type { TextComponentProps } from "./flowTypes";

import TextWrapper from "./TextWrapper";
import { TEXT_HANDLE_EDITOR_CHANGE } from "../actionTypes";
import { addComponentAdjustmentDataEntry } from "../../../Workspace/epics/componentsEval/adjustmentDataDispatchCache";

export const TextWrapperAdapter = (props: TextComponentProps) => {
    return <TextWrapper
        inEditMode={props.inEditMode}
        content={props.content}
        className={props.className}
        height={props.height}
        dispatch={props.dispatch}
        onHeightChanged={
            height => addComponentAdjustmentDataEntry(
                props.componentId,
                { minDimensions: { height: Math.round(height) }, height: Math.round(height) },
                props.inEditMode
            )
        }
        handleEditorChangeActionType={TEXT_HANDLE_EDITOR_CHANGE}
        handleEditorChangeIdentificationData={{ componentId: props.componentId }}
        occupy100PctOfHeight
        globalVariables={props.globalVariables}
        site={props.site}
        verticalAlignment={props.verticalAlignment}
        selectedParentTheme={props.selectedParentTheme}
        themeOverrideColor={props.themeOverrideColor}
        themeSettingsData={props.themeSettingsData}
        themeColorsData={props.themeColorsData}
        themeHighlightColor={props.themeHighlightColor}
        themeShadowBlurRadius={props.themeShadowBlurRadius}
        themeShadowColor={props.themeShadowColor}
        themeShadowOffsetX={props.themeShadowOffsetX}
        themeShadowOffsetY={props.themeShadowOffsetY}
    />;
};
