import {
    FC_COMPUTER_UPLOAD_REQUEST,
    FC_COMPUTER_UPLOAD_SUCCESS,
    FC_COMPUTER_UPLOAD_FAILURE
} from '../actionTypes';

import type { ApiAction } from '../../../../middleware/api/flowTypes';
import { DalErrorName } from "../../../../../../dal/constants";
import { apiAction } from "../../../../middleware/api/actions";
import { TRIAL_UPLOAD_LIMIT_REACHED_DIALOG_ID } from '../../../../../../demo/modules/uploadLimitReachedDialog/dialogId';

export default function (file: File, webSpacePath: string): ApiAction {
    return apiAction({
        types: [
            FC_COMPUTER_UPLOAD_REQUEST,
            FC_COMPUTER_UPLOAD_SUCCESS,
            FC_COMPUTER_UPLOAD_FAILURE,
        ],
        endpoint: 'uploadFromFileVideo',
        endpointParams: [file, webSpacePath],
        errorHandler: {
            dialog: {
                [DalErrorName.WebspaceUploadLimitReached]: TRIAL_UPLOAD_LIMIT_REACHED_DIALOG_ID,
            }
        }
    });
}
