import React from 'react';
import Page from "../../../../PropertiesPanel/view/PropertiesPage";
import ColorProperty from '../../../../presentational/ColorProperty/index';
import * as actionTypes from '../../actionTypes';
import injectIntl from '../../../../../view/intl/injectIntl';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import * as pagesIds from './ids';
import pagesTitles from './titles';
import type { PropertiesPanelStyleViewProps } from '../../flowTypes';
import { THEME_COLOR_PICKER } from "../../../../ColorPicker/constants";
import { getThemeRulesForWebshop } from "../../../../ThemeGlobalData/themeRules";
import { getThemeColorsFromStylesheet } from "../../../../Workspace/epics/stylesheets/selectors";
import Label from "../../../../../view/common/Label";

const id: any = pagesIds.PROMO,
    title = pagesTitles[pagesIds.PROMO],
    view = injectIntl((
        {
            dispatch,
            selectedComponent,
            selectedComponentProps,
            globalStyles,
            themeSettingsData: {
                autoColorMode
            },
        }: PropertiesPanelStyleViewProps
    ) => {
        const themeColorsData = getThemeColorsFromStylesheet(globalStyles);
        return (
            <Page key="promotion">
                <VerticalSpacer />
                <label>
                    <Label>{"msg: component.promotion.promotionProductRibbonLabel {Product ribbon}"}</Label>
                </label>
                <VerticalSpacer />
                {
                    autoColorMode
                        ? <React.Fragment>
                            <ColorProperty
                                colorPickerKind={THEME_COLOR_PICKER}
                                themeColorsData={themeColorsData}
                                themeColor={
                                    selectedComponent.labelBgColorTheme
                                }
                                defaultThemeColor={getThemeRulesForWebshop(
                                    selectedComponentProps.selectedParentTheme,
                                    themeColorsData
                                ).labelBgColorName}
                                label="msg: component.webshop.labelColor {Label colour}"
                                onChangeAction={actionTypes.FEATURED_PRODUCTS_LABEL_BG_COLOR_CHANGED_AUTO_COLOR}
                                dispatch={dispatch}
                            />
                            <VerticalSpacer />
                            <ColorProperty
                                colorPickerKind={THEME_COLOR_PICKER}
                                themeColorsData={themeColorsData}
                                themeColor={
                                    selectedComponent.labelTextColorTheme
                                }
                                defaultThemeColor={getThemeRulesForWebshop(
                                    selectedComponentProps.selectedParentTheme,
                                    themeColorsData
                                ).labelTextColorName}
                                label="msg: component.webshop.labelTextColor {Label text colour}"
                                onChangeAction={actionTypes.FEATURED_PRODUCTS_LABEL_TEXT_COLOR_CHANGED_AUTO_COLOR}
                                dispatch={dispatch}
                            />
                        </React.Fragment> :
                        <React.Fragment>
                            <ColorProperty
                                onRemoveAction=""
                                label="msg: component.webshop.labelColor {Label colour}"
                                color={selectedComponent.labelBgColor}
                                onChangeAction={actionTypes.FEATURED_PRODUCTS_LABEL_BG_COLOR_CHANGED}
                                dispatch={dispatch}
                            />
                            <VerticalSpacer />
                            <ColorProperty
                                onRemoveAction=""
                                label="msg: component.webshop.labelTextColor {Label text colour}"
                                color={selectedComponent.labelTextColor}
                                onChangeAction={actionTypes.FEATURED_PRODUCTS_LABEL_TEXT_COLOR_CHANGED}
                                dispatch={dispatch}
                            />
                        </React.Fragment>
                }
                <VerticalSpacer />
                <React.Fragment>
                    <VerticalSpacer />
                    <label>
                        <Label>{"msg: component.promotion.promotionDiscountRibbonLabel {Discount ribbon}"}</Label>
                    </label>
                    <VerticalSpacer />
                    {autoColorMode
                        ? [
                            <ColorProperty
                                colorPickerKind={THEME_COLOR_PICKER}
                                themeColorsData={themeColorsData}
                                themeColor={selectedComponent.promoRibbonBgColorTheme}
                                defaultThemeColor={
                                    getThemeRulesForWebshop(selectedComponentProps.selectedParentTheme, themeColorsData)
                                        .labelBgColorName
                                }
                                label="msg: component.webshop.labelColor {Label colour}"
                                onChangeAction={actionTypes.WEBSHOP_PROMOTION_RIBBON_LABEL_BG_COLOR_CHANGED_AUTO_COLOR}
                                dispatch={dispatch}
                            />,
                            <VerticalSpacer />,
                            <ColorProperty
                                colorPickerKind={THEME_COLOR_PICKER}
                                themeColorsData={themeColorsData}
                                themeColor={selectedComponent.promoRibbonTextColorTheme}
                                defaultThemeColor={
                                    getThemeRulesForWebshop(selectedComponentProps.selectedParentTheme, themeColorsData)
                                        .labelTextColorName
                                }
                                label="msg: component.webshop.labelTextColor {Label text colour}"
                                onChangeAction={actionTypes.WEBSHOP_PROMOTION_RIBBON_LABEL_TEXT_COLOR_CHANGED_AUTO_COLOR}
                                dispatch={dispatch}
                            />
                        ]
                        : [
                            <ColorProperty
                                onRemoveAction=""
                                label="msg: component.webshop.labelColor {Label colour}"
                                color={selectedComponent.promoRibbonBgColor}
                                onChangeAction={actionTypes.WEBSHOP_PROMOTION_RIBBON_LABEL_BG_COLOR_CHANGED}
                                dispatch={dispatch}
                            />,
                            <VerticalSpacer />,
                            <ColorProperty
                                onRemoveAction=""
                                label="msg: component.webshop.labelTextColor {Label text colour}"
                                color={selectedComponent.promoRibbonTextColor}
                                onChangeAction={actionTypes.WEBSHOP_PROMOTION_RIBBON_LABEL_TEXT_COLOR_CHANGED}
                                dispatch={dispatch}
                            />
                        ]}
                </React.Fragment>
            </Page>
        );
    });

export { id, title, view };
