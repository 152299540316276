import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import { getCenterPositionForDialog } from "../../../../DialogManager/utility";
import { CongratsOrErrorHandlerDialogsView } from './view/index';

const
    WIDTH = 1217,
    HEIGHT = 697,
    CongratsOrErrorHandlerDialogs = {
        ...getCenteredDialogConfig({
            WIDTH,
            HEIGHT,
            component: CongratsOrErrorHandlerDialogsView,
        }),
        confFactory: ({ browserWidth, browserHeight }) => {
            return {
                position: getCenterPositionForDialog(WIDTH, HEIGHT, browserWidth, browserHeight),
                modal: true,
                dimensions: { width: WIDTH, height: HEIGHT }
            };
        },
        moveToCenter: true,
    };

const CongratsOrErrorHandlerDialogsId = 'CongratsOrErrorHandlerDialogsId';

export {
    CongratsOrErrorHandlerDialogs,
    CongratsOrErrorHandlerDialogsId
};
