import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import LoadingIndicator from '../../../../../view/common/LoadingIndicator/index';
import { getDAL } from "../../../../../../dal/index";
import injectIntl from "../../../../../view/intl/injectIntl";
import styles from './Products.css';
import { isIconImage } from '../../utils';
import { getAppConfig } from '../../../../App/epics/appConfig/appConfig';
import AppConfig from "../../../../../utils/AppConfig";

export const getImageURL = (url: string, enableImproService: Boolean, improUrl: string) => {
    let imageUrl = url;
    if (/^webshop:\//.test(url)) {
        const currentImageUrl = `${url.replace("webshop:/", "")}?&withoutEnlargement&resize=240,9999`;
        if (improUrl && enableImproService) {
            imageUrl = `${improUrl}${currentImageUrl}`.replace(/\{domainName\}/g, getDAL().getDomain());
        } else {
            imageUrl = `${location.protocol}//${getDAL().getDomain()}/____impro/1/${currentImageUrl}`;
        }
    }
    return imageUrl;
};

type PricePropTypes = {
    intl: Intl,
    currency: string,
    minRegularPrice: number,
    minSalePrice: number,
    hasVariedPrice: boolean,
    soldOut: boolean
};

const ProductPrice = injectIntl(({
    intl,
    currency,
    minRegularPrice,
    minSalePrice,
    hasVariedPrice,
    soldOut
}: PricePropTypes) => {
    const isMinRegularPrice = minRegularPrice > 0;
    const isMinSalePrice = minSalePrice > 0;
    return (
        <div className={styles.itemPriceWrap}>
            {isMinRegularPrice && (
                <div className={cx(styles.itemPrice, styles.itemOldPrice)}>{intl.currency(minRegularPrice, currency)}</div>
            )}
            {(hasVariedPrice || isMinSalePrice) && (<div className={styles.itemPrice}>
                {hasVariedPrice && (
                    <React.Fragment>
                        <span className="ws-product-price__from">
                            {intl.msgJoint('msg: component.webshop.featuredProducts.priceFrom {From}')}
                        </span>
                        {' '}
                    </React.Fragment>
                )}
                <span>{intl.currency(minSalePrice, currency)}</span>
            </div>)}
            {soldOut && <span>{intl.msgJoint('msg: component.webshop.featuredProducts.soldOut {Sold out}')}</span>}
        </div>
    );
});

type ImgPropTypes = {
    children: React.ReactNode,
    imageURL?: string
}

const Img = ({ children, imageURL }: ImgPropTypes) => {
    const [imageError, setImageError] = useState(false);
    const [imageLoading, setImageLoading] = useState(!!imageURL);

    useEffect(() => {
        if (imageURL) {
            const img = new Image();
            img.onload = () => {
                setImageLoading(false);
            };
            img.onerror = () => {
                setImageError(true);
                setImageLoading(false);
            };
            img.src = imageURL;
            // @ts-ignore
            if (img.complete) img.onload();
        }
    }, []);

    return (
        <div
            className={cx(styles.itemThumb, { [styles.itemIconThumb]: isIconImage(imageURL) })}
            style={imageURL ? { backgroundImage: `url('${imageURL}')` } : undefined}
        >
            {imageLoading && <LoadingIndicator className={styles.imageLoadingIndicator} />}
            {!imageLoading && (!imageURL || imageError) && <React.Fragment>{children}</React.Fragment>}
        </div>
    );
};

type Props = {
    selected: boolean,
    product: Record<string, any>,
    toggleSelected: Function
};

const ProductItem = ({ product, selected, toggleSelected }: Props) => {
    const appConfig = AppConfig(getAppConfig());
    const enableImproService = Boolean(appConfig.getOptional('oneWeb.webshop.enableImproService'));
    const improUrl = appConfig.getOptional('oneWeb.webshop.improUrl');
    const imageURL =
        Array.isArray(product.images) && product.images.length > 0 ?
            getImageURL(product.images[0].url, enableImproService, improUrl) : undefined;

    return (
        <div
            className={cx(styles.item, { [styles.selected]: selected })}
            onClick={() => toggleSelected(product.id)}
        >
            <div className={styles.itemThumbCard}>
                <div className={styles.itemOverlay} />
                <Img imageURL={imageURL}>
                    <div className={styles.itemImagePlaceholderText}>{product.name}</div>
                </Img>
                {selected && <div className={styles.imageSelectedCornerTick} />}
            </div>
            <p className={styles.itemTitle}>{product.name}</p>
            {product.priceDetails && <ProductPrice {...product.priceDetails} />}
        </div>
    );
};

export default ProductItem;
