import { makePlainMappers } from '../../utils';
import { to as bBoxTo, back as bBoxBack } from '../Base/bBox';
import DataPageTemplate from "../../../model/DataPageTemplate";
import type { TemplateComponent } from "../../../../src/components/oneweb/Template/flowTypes";

const plainPropsMapper = makePlainMappers({
    id: 'id',
    type: 'type',
    width: 'width',
    height: 'height',
    name: 'name',
    selectedTheme: 'selectedTheme',
    selectedGradientTheme: 'selectedGradientTheme',
    showShopStripInFooter: 'showShopStripInFooter',
    stylesheetId: 'stylesheetId',
    align: 'align',
    verticalScroll: 'verticalScroll',
    mobile: 'mobile',
    locale: 'locale',
    categories: 'categories',
    time: 'time',
    etag: 'etag',
    rev: 'rev',
    i18n: 'i18n'
});

const addMobileDataKey = (template) => ({ mobileData: template.mobileData || { relations: [], styles: {} } });

export const to = (template: DataPageTemplate) => ({
    ...plainPropsMapper.to(template),
    ...bBoxTo(template),
    ...addMobileDataKey(template)
});

export const back = (template: TemplateComponent) => {
    const
        base = plainPropsMapper.back(template);

    return { ...base, items: [], ...bBoxBack(template), ...addMobileDataKey(template) };
};
