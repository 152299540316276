/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import cx from "classnames";
import * as Icon from './Icons.css';
import * as styles from '../view/Inserter.css';
import { Youtube } from "./constants";
import EPSeparator from "../view/EPSeparator";
import Msg from "../../../view/intl/Msg";
import VideoKind from '../../oneweb/Video/kind';
import VideoFileKind from '../../oneweb/Video/VideoFile/kind';
import { ComponentNames } from "../../oneweb/constants";
import ComponentTypes from '../../../view/oneweb/registry/ComponentTypes';
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import type { ExtendedPanelContentProps } from "../view/inserter";
import { DroppableCmpShortcut, ExtendedPanelShortCut } from "../view/DroppableCmpShortcut";
import { checkSubscriptionCompatibilityFromKind } from '../../ComponentTierManager/utils';
import { isTrialSubscription } from '../../App/epics/subscriptionData/utils';
import { PremiumIcon } from '../../../view/common/PremiumIcon/index';
import { Flex } from '../../../view/reflexbox';
import HelpTip from '../view/HelpTip';
import { VideoIsDisabledInTrial } from '../../oneweb/Video/VideoFile/errorMessagesOnAdd';
import { makeEpicStateSelector } from '../../../epics/makeEpic';
import ComponentsDisabledReasonMapVAT from '../epics/componentsDisabledReasonMap/valueActionType';
import { VIDEO_WIDGET_DISABLED_TIP, VIDEO_WIDGET_HELP_TIP } from '../../oneweb/Video/VideoFile/translations';
import { WidgetsVimeoConfig } from '../../oneweb/Widgets/constants';
import { WidgetDroppableCmpShortCut } from '../../oneweb/Widgets/view/DroppableCmpShortcut';
import * as WidgetIcons from '../../oneweb/Widgets/view/Icons.css';
import { AppState } from '../../../redux/modules/flowTypes';

const SelfHostedVideo = (props) => {
    const
        [disabled, setDisabled] = useState(false),
        { componentsDisabledReasonMap } = props,
        videoIsPremiumForUser = checkSubscriptionCompatibilityFromKind(ComponentTypes.VIDEO_FILE, props.subscriptionType),
        showPremiumIconForVideo = !videoIsPremiumForUser || isTrialSubscription(props.subscriptionType),
        droppableCmpDispatch = (!disabled) ? props.dispatch : () => {};

    useEffect(() => {
        if (componentsDisabledReasonMap && componentsDisabledReasonMap.hasOwnProperty(VideoFileKind)) {
            setDisabled(componentsDisabledReasonMap[VideoFileKind] === VideoIsDisabledInTrial);
        }
    }, [componentsDisabledReasonMap]);

    return (
        <React.Fragment>
            <VerticalSpacer y={17} />
            <Flex justify="space-between">
                <div className={styles.subTitle}><Msg k="component.video.inserterPanelTitle">Self-hosted</Msg></div>
                <Flex>
                    <HelpTip
                        msg={(!disabled) ? VIDEO_WIDGET_HELP_TIP : VIDEO_WIDGET_DISABLED_TIP}
                        helpTipClassName="inserterInfoIconTip"
                        dispatch={props.dispatch}
                    />
                </Flex>
            </Flex>
            <VerticalSpacer y={12} />
            <div className={styles.shortCutsContainer}>
                <DroppableCmpShortcut {...props} kind={VideoFileKind} dispatch={droppableCmpDispatch}>
                    {showPremiumIconForVideo && <PremiumIcon iconCustomClass={styles.premiumSocialMediaIconOnWidget} /> }
                    <ExtendedPanelShortCut iconClassName={Icon.videoSelfHosted} text={ComponentNames[VideoFileKind]} />
                </DroppableCmpShortcut>
            </div>
            <VerticalSpacer y={24} />
        </React.Fragment>
    );
};

const ExpandedContent = (props: Record<string, any>) => {
    const
        // @ts-ignore
        vimeoIsPremiumForUser = checkSubscriptionCompatibilityFromKind(WidgetsVimeoConfig.kind, props.subscriptionType),
        showPremiumIconForVimeo = !vimeoIsPremiumForUser || isTrialSubscription(props.subscriptionType);

    return (
        <div>
            <SelfHostedVideo {...props} />
            <EPSeparator />
            <VerticalSpacer y={22} />
            <div className={styles.subTitle}>Embedded</div>
            <VerticalSpacer y={12} />
            <div className={styles.shortCutsContainer}>
                { /* @ts-ignore */ }
                <DroppableCmpShortcut {...props} payload={{ dialogProps: { type: Youtube } }} kind={VideoKind}>
                    <ExtendedPanelShortCut iconClassName={cx(WidgetIcons.youtube, WidgetIcons.inserter)} text={ComponentNames.YOUTUBE} />
                </DroppableCmpShortcut>
                <WidgetDroppableCmpShortCut {...props} showPremiumIcon={showPremiumIconForVimeo} widgetConfig={WidgetsVimeoConfig} />
            </div>
        </div>
    );
};

const Content = connect((appState: AppState) => {
    return {
        componentsDisabledReasonMap: makeEpicStateSelector(ComponentsDisabledReasonMapVAT)(appState)
    };
})(ExpandedContent);

export default {
    kind: VideoKind,
    shortCut: {
        iconClassName: Icon.videoSelfHosted,
        text: ComponentNames[VideoKind]
    },
    extendedPanelData: {
        title: 'msg: extendedPanel.moreVideo {Video}',
        content: (props: ExtendedPanelContentProps) => <Content {...props} />
    }
};
