import React from 'react';
import cx from 'classnames';

import ReviewGrid from '../ReviewGrid/ReviewGrid';
import * as styles from './Review.css';
import ReviewSlider from "../ReviewSlider/ReviewSlider";
import { ReviewViewType } from "../../constants";
import { ReviewButton } from "../ReviewButton";

const ReviewHeader = ({ headerProps }) => {
    const { headerText, headerStyles, headerClass } = headerProps;
    return headerText ? <div className={`${styles.reviewHeader} ${headerClass}`}>
        <span className="reviewHeader" style={headerStyles}>{headerText}</span>
    </div> : null;
};

const ReviewFooter = ({ reviewButtonProps, googleReviewsText }) => {
    const { classList, reviewButtonText,
            leaveReviewLink, showReviewButton, storeLink } = reviewButtonProps,
        showButton = showReviewButton && reviewButtonText;
    return (
        <div className={cx(styles.reviewFooter, { [styles.isCentered]: !showButton })}>
            <a href={storeLink} target="_blank" className={styles.googleIconWrapper}>
                <div className={styles.googleIcon} />
                <div className={styles.googleReviewsText}>
                    {googleReviewsText}
                </div>
            </a>
            {showButton && <ReviewButton
                classList={classList}
                reviewButtonText={reviewButtonText}
                leaveReviewLink={leaveReviewLink}
            /> }
        </div>
    );
};

const Review = ({ type, headerProps, reviews, isWorkspace, reviewButtonProps, component }) => {
    const { seeMoreText, seeLessText, googleReviewsText, reviewTimeText } = component;
    return (
        <div
            className={`googleReviews ${styles.reviewContainer}`}
            style={{ minHeight: component.height }}
            // eslint-disable-next-line react/no-unknown-property
            review-time-text={JSON.stringify(reviewTimeText)}
        >
            <ReviewHeader headerProps={headerProps} />
            {
                ReviewViewType.SLIDER === type && reviews.length > 1 ?
                    <ReviewSlider
                        reviews={reviews}
                        isWorkspace={isWorkspace}
                        seeMoreText={seeMoreText}
                        seeLessText={seeLessText}
                        reviewTimeText={reviewTimeText}
                    /> :
                    <ReviewGrid
                        type={type}
                        reviews={reviews}
                        seeMoreText={seeMoreText}
                        seeLessText={seeLessText}
                        reviewTimeText={reviewTimeText}
                    />
            }
            <ReviewFooter reviewButtonProps={reviewButtonProps} googleReviewsText={googleReviewsText} />
        </div>
    );
};

export default Review;
