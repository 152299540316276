import React from 'react';
import { useSelector } from "react-redux";

import { setAddressAreaAction } from "./action";
import { selectAddressArea, selectAddressCountryCode } from "../../../../../common/selectors";
import { placeholderFieldFormatMap } from "../../../../../../../../wbtgen/src/components/SiteSettings/General/utils";
import {
    PROVINCE
} from "../../../../../../../../wbtgen/src/components/SiteSettings/BusinessListing/GoogleMyBusiness/Mappers/gmbErrorFields";
import { AddressSettingsCommonForm } from "./AddressSettingsCommonForm";

const { getCountryFormat } = require('../../../../../../../../server/shared/address/getCountryFormat.js');

export const AddressSettingsAreaForm = () => {
    const countryFormat = getCountryFormat(useSelector(selectAddressCountryCode));
    const label = countryFormat === 'CountryFormat4' ?
        'msg: generalInfo.province.label {Province / region (Optional)}' :
        'msg: generalInfo.state.label {State / Province}';

    return (
        <AddressSettingsCommonForm
            label={label}
            placeholder={placeholderFieldFormatMap[countryFormat].stateProvince}
            selector={selectAddressArea}
            handleChangeAction={setAddressAreaAction}
            errorField={PROVINCE}
        />
    );
};
