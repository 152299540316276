import * as R from "ramda";
import componentsRegistry from "../../../../view/oneweb/registry/index";
import { getDefaultReducerState } from "../../../../redux/makeReducer/index";
import { findMostRelatedColor } from '../../../../utils/colorUtils';
import { getThemeFromBackgroundColor } from '../../../ThemeGlobalData/themeRules';
import type { ThemeColorDataType, ThemeColorTypes } from '../../../ThemeGlobalData/flowTypes';
import type { BackgroundComponent } from '../flowTypes';
import type { Color } from "../../../../mappers/flowTypes";

export const getDefaultState = (kind: string) => {
    const { reducer } = componentsRegistry[kind];
    return R.omit(['selectedTheme'], getDefaultReducerState(reducer));
};

type ThemeOverridesFromCmpObject = {
    selectedTheme?: string;
    selectedGradientTheme?: string;
    selectedBorderTheme?: string;
}

export const getThemeOverridesFromComponentData = (
    component: BackgroundComponent, autoColorMode: boolean, themeColorsData: ThemeColorDataType
) => {
    const overrides: ThemeOverridesFromCmpObject = {};
    if (autoColorMode) {
        let backgroundColorName;
        if (R.path(['style', 'background', 'colorData', 'color'], component)) {
            backgroundColorName = findMostRelatedColor(component.style.background.colorData.color, themeColorsData);
            overrides.selectedTheme = getThemeFromBackgroundColor(backgroundColorName);
        }
        if (R.path(['style', 'background', 'colorData', 'gradient', 'color'], component)) {
            overrides.selectedGradientTheme = findMostRelatedColor(
                component.style.background.colorData.gradient.color,
                Object.keys(themeColorsData).reduce((acc, k) => ({
                    ...acc,
                    ...(k === backgroundColorName ? {} : { [k]: themeColorsData[k] })
                }), {} as { [k in ThemeColorTypes]: Color })
            );
        }
        if (R.path(['style', 'border', 'color'], component)) {
            overrides.selectedBorderTheme = findMostRelatedColor(component.style.border.color, themeColorsData);
        }
    }
    return overrides;
};
