export const
    CHECK_COMPONENT_WRAP_TABLE_REQUEST = 'CHECK_COMPONENT_WRAP_TABLE_REQUEST',
    CHECK_COMPONENT_WRAP_TABLE_SUCCESS = 'CHECK_COMPONENT_WRAP_TABLE_SUCCESS',
    CHECK_COMPONENT_WRAP_TABLE_FAILURE = 'CHECK_COMPONENT_WRAP_TABLE_FAILURE',

    CheckComponentWrapInTableActionTypes = [
        CHECK_COMPONENT_WRAP_TABLE_REQUEST,
        CHECK_COMPONENT_WRAP_TABLE_SUCCESS,
        CHECK_COMPONENT_WRAP_TABLE_FAILURE
    ];
