import { getHeaderSection, getFooterSection } from "../../../oneweb/Section/utils";
import type { ComponentsMap } from "../../../../redux/modules/children/workspace/flowTypes";
import { SET_DEFAULT_HEIGHT_HEADER_FLAG, SET_DEFAULT_HEIGHT_FOOTER_FLAG,
    RESET_DEFAULT_HEIGHT_HEADER_FLAG, RESET_DEFAULT_HEIGHT_FOOTER_FLAG } from './actionTypes';

const defaultHeaderHeight = 100;

export const setDefaultHeightForHeaderFooter = (componentsMap: ComponentsMap) => {
    let newComponentsMap = { ...componentsMap },
        actionsToDispatch: Array<Action> = [];
    const headerSection = getHeaderSection(componentsMap),
        footerSection = getFooterSection(componentsMap);
    if (headerSection && headerSection.height <= 1) {
        let diff = defaultHeaderHeight - headerSection.height;
        Object.keys(newComponentsMap).forEach(cmpId => {
            if (headerSection.id === cmpId) {
                newComponentsMap[cmpId] = {
                    ...newComponentsMap[cmpId],
                    height: defaultHeaderHeight
                };
            } else {
                newComponentsMap[cmpId] = {
                    ...newComponentsMap[cmpId],
                    top: newComponentsMap[cmpId].top + diff
                };
            }
        });
        actionsToDispatch.push({
            type: SET_DEFAULT_HEIGHT_HEADER_FLAG
        });
    } else {
        actionsToDispatch.push({
            type: RESET_DEFAULT_HEIGHT_HEADER_FLAG
        });
    }
    if (footerSection && footerSection.height <= 1) {
        newComponentsMap[footerSection.id] = {
            ...newComponentsMap[footerSection.id],
            height: defaultHeaderHeight
        };
        actionsToDispatch.push({
            type: SET_DEFAULT_HEIGHT_FOOTER_FLAG
        });
    } else {
        actionsToDispatch.push({
            type: RESET_DEFAULT_HEIGHT_FOOTER_FLAG
        });
    }
    return { componentsMap: newComponentsMap, actionsToDispatch };
};

export const resetDefaultHeightForHeaderFooter =
    (componentsMap: ComponentsMap, { isDefaultHeaderHeightSet, isDefaultFooterHeightSet }: Record<string, any> = {}) => {
        let newComponentsMap = { ...componentsMap };
        if (!isDefaultHeaderHeightSet && !isDefaultFooterHeightSet) {
            return newComponentsMap;
        }
        if (isDefaultHeaderHeightSet) {
            const headerSection = getHeaderSection(newComponentsMap);
            Object.keys(newComponentsMap).forEach(cmpId => {
                if (cmpId === headerSection.id) {
                    newComponentsMap[cmpId] = {
                        ...newComponentsMap[cmpId],
                        height: 0
                    };
                } else {
                    newComponentsMap[cmpId] = {
                        ...newComponentsMap[cmpId],
                        top: newComponentsMap[cmpId].top - defaultHeaderHeight
                    };
                }
            });
        }
        if (isDefaultFooterHeightSet) {
            const footerSection = getFooterSection(newComponentsMap);
            newComponentsMap[footerSection.id] = {
                ...newComponentsMap[footerSection.id],
                height: 0
            };
        }
        return newComponentsMap;
    };
