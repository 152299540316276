import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(
    theme => ({
        root: {
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            [theme.breakpoints.down("sm")]: {
                padding: "24px",
                backgroundColor: theme.palette.custom.colorWhite
            }
        },
        title: {
            fontSize: "22px",
            lineHeight: "30px",
            [theme.breakpoints.up("sm")]: {
                fontSize: "24px"
            }
        },
        containers: {
            display: "flex",
            gap: "24px",
            width: "100%",
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                gap: "16px"
            }
        },
        boxContainer: {
            display: "flex",
            width: "100%",
            border: `1px solid ${theme.palette.custom.colorGray_ce}`,
            padding: "24px",
            flexDirection: "column",
            gap: "8px"
        },
        boxContainerTop: {
            display: "flex",
            justifyContent: "space-between"
        },
        name: {
            fontSize: "16px",
            lineHeight: "24px",
            color: theme.palette.custom.colorDarkSilver,
            [theme.breakpoints.down("sm")]: {
                lineHeight: "22px"
            }
        },
        value: {
            fontSize: "20px",
            lineHeight: "24px",
            [theme.breakpoints.down("sm")]: {
                fontSize: "18px"
            }
        },
        timeSelection: {
            color: theme.palette.custom.colorDarkSilver,
            fontSize: "14px",
            lineHeight: "22px"
        },
        errorRoot: {
            padding: "8px 10px"
        },
        errorContent: {
            gap: "0px"
        }
    }),
    { name: "OnlineShopStats" }
);
