import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly } from "../../../../epics/makeCondition";
import valueActionType from "./valueActionType";
import { LOGOUT } from "../../actionTypes";
import { UNSET_COOKIE } from '../../../../redux/middleware/cookie/actionTypes';
import { REDIRECT_TO_LOGIN_ACTION } from '../../../../redux/middleware/api/actions';
import unsavedChangesVAT from "../../../UnsavedChanges/epic/valueActionType";
import {
    CANCEL_UNSAVED_CHANGES,
    UNSAVED_CHANGES_CHECKED
} from "../../../UnsavedChanges/actionTypes";
import { checkUnsavedChangesAction } from "../../../UnsavedChanges/actionCreators";
import subscriptionDataVAT from "../../../App/epics/subscriptionData/valueActionType";
import { demoSignOutAction } from "../../../../../demo/actions";
import { isTrialSubscription } from "../../../App/epics/subscriptionData/utils";
import { HAS_SEEN_CI_COOKIE_BANNER_MIGRATION_DIALOG } from '../../../SiteSettings/cookieBannerGlobalData/constants';
import CookieKeys from '../../../../../../server/shared/cookieKeys';

// TODO: Merge Dashboard and Editor login/logout functionality later.
const unsetBoneAuthCookieObj = { type: UNSET_COOKIE, payload: { key: 'BoneAuth' } };
const unsetClusterCookieObj = { type: UNSET_COOKIE, payload: { key: CookieKeys._WSBCLUSTERINFO } };

export default makeEpic({
    defaultState: {
        checkUnsavedChanges: false
    },
    valueActionType,
    updaters: [
        {
            conditions: [LOGOUT],
            reducer: ({ values: [{ fromTemplateSelector }], state }) => {
                if (fromTemplateSelector) {
                    window.sessionStorage.removeItem(HAS_SEEN_CI_COOKIE_BANNER_MIGRATION_DIALOG);
                    return {
                        state,
                        multipleActionsToDispatch: [
                            unsetBoneAuthCookieObj,
                            unsetClusterCookieObj,
                            {
                                type: REDIRECT_TO_LOGIN_ACTION
                            }
                        ]
                    };
                }

                let actionToDispatch = checkUnsavedChangesAction();
                return {
                    state: {
                        ...state,
                        checkUnsavedChanges: true
                    },
                    actionToDispatch
                };
            }
        },
        {
            conditions: [CANCEL_UNSAVED_CHANGES],
            reducer: ({ state }) => {
                if (state.checkUnsavedChanges) {
                    return {
                        state: {
                            ...state,
                            checkUnsavedChanges: false
                        }
                    };
                } else {
                    return { state };
                }
            }
        },
        {
            conditions: [
                receiveOnly(subscriptionDataVAT),
                receiveOnly(unsavedChangesVAT),
                UNSAVED_CHANGES_CHECKED
            ],
            reducer: ({ values: [subscriptionData, hasChanges], state }) => {
                if (!state.checkUnsavedChanges) {
                    return { state };
                }

                // TODO: `hasChanges` seems to be always `false`, refactor if that's the case
                if (!hasChanges) {
                    let multipleActionsToDispatch: Array<Action> = [];
                    window.sessionStorage.removeItem(HAS_SEEN_CI_COOKIE_BANNER_MIGRATION_DIALOG);

                    if (isTrialSubscription(subscriptionData.subscriptionType)) {
                        multipleActionsToDispatch = [demoSignOutAction()];
                    } else {
                        multipleActionsToDispatch = [
                            unsetBoneAuthCookieObj,
                            unsetClusterCookieObj,
                            { type: REDIRECT_TO_LOGIN_ACTION }
                        ];
                    }

                    return ({
                        state: {
                            ...state,
                            checkUnsavedChanges: false
                        },
                        multipleActionsToDispatch
                    });
                }

                // TODO: this part seems to be a dead code since `hasChanges` is always `false`
                return {
                    state: {
                        ...state,
                        checkUnsavedChanges: false
                    }
                };
            }
        }
    ]
});
