import * as R from 'ramda';
import { updateIf } from "../Text/adjustmentHookConfig";

import type { AdjustmentHook } from '../../Workspace/epics/componentsEval/flowTypes';
import type { TableComponent } from "./flowTypes";

// TODO remove this hook after WBTGEN-7344
const
    hook: AdjustmentHook<TableComponent, Record<string, any>, Record<string, any>, Record<string, any>> =
        ({ component, componentExtension, adjustmentData }) => {
            const { minDimensions: { height } } = adjustmentData || { minDimensions: { height: 0 } };

            return [
                updateIf(height, R.gt, 'height')(component),
                componentExtension
            ];
        },
    tableAdjustmentHookConfig = {
        hook,
        shouldCallHook: (prev: any, { adjustmentData }: Record<string, any>) => !!adjustmentData
    };

export {
    tableAdjustmentHookConfig
};
