import * as React from 'react';
import cx from 'classnames';
import styles from './HoverBoxControls.css';
import { HoverBoxBtnCom } from "./HoverBoxBtnCom";

type Props = {
    onClick?: React.MouseEventHandler,
    title?: boolean,
    className?: string,
};

export const HoverBoxViewBtnCom = React.forwardRef(
    ({ title = true, onClick, className }: Props, ref: React.ForwardedRef<HTMLSpanElement>) => (
        <HoverBoxBtnCom
            title={title ? 'msg: view {View}' : undefined}
            className={cx(styles.view, className)}
            icoClassName={styles.view}
            onClick={onClick}
            ref={ref}
        />
    )
);

