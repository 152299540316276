import { pick } from 'ramda';
import type { MaxMultiSelectValidation } from "../../view/common/FileChooser/flowTypes";
import type { FcContentTypePattern } from "../../redux/modules/children/fileChooser/utils/testFcContentType";
import { openDialog } from "../App/actionCreators/index";
import { makeSvgChooserConfigDialog } from "./makeSvgChooserConfigDialog";

export type ImageChooserDialogProps = {
    onSaveAction: string,
    preSelection?: null | undefined | string,
    extraImageContentTypes?: FcContentTypePattern,
    isMultiSelect?: boolean,
    maxMultiSelectValidation?: MaxMultiSelectValidation,
    forwardToComponent?: boolean,
    headlessMode?: true,
};

export const openSvgChooserAction = (props: ImageChooserDialogProps) => {
    const { dialogId, dialogProps } = makeSvgChooserConfigDialog(pick(['onSaveAction', 'forwardToComponent'], props));
    return openDialog(dialogId, dialogProps);
};
