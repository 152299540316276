import * as actionTypes from './actionTypes';
import * as euh from './epicUpdateHelpers';
import { types } from './constants';
import { SAVE_GOOGLE_FONT } from '../../Fonts/actionTypes';
import { LOGO_STYLE_CHANGED } from '../../Workspace/epics/stylesheets/updateReasons';
import type { Stylesheets } from '../../Workspace/epics/stylesheets/flowTypes';
import type { ThemeColorDataType } from '../../ThemeGlobalData/flowTypes';

export default [
    ...euh.googleFontEpicUpdater([
        { action: SAVE_GOOGLE_FONT, ref: types.NORMAL }
    ]),
    ...euh.getFontFamilyEpicUpdaters([
        { action: actionTypes.GS_TEXT_NORMAL_FONT_FAMILY_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TEXT_HEADING1_FONT_FAMILY_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TEXT_HEADING2_FONT_FAMILY_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TEXT_HEADING3_FONT_FAMILY_CHANGED, ref: types.HEADING3 },
        { action: actionTypes.GS_TEXT_LOGO_FONT_FAMILY_CHANGED, ref: types.LOGO },
    ]),
    ...euh.getFontBoldToggleEpicUpdaters([
        { action: actionTypes.GS_TEXT_NORMAL_FONT_TOGGLE_BOLD, ref: types.NORMAL },
        { action: actionTypes.GS_TEXT_HEADING1_FONT_TOGGLE_BOLD, ref: types.HEADING1 },
        { action: actionTypes.GS_TEXT_HEADING2_FONT_TOGGLE_BOLD, ref: types.HEADING2 },
        { action: actionTypes.GS_TEXT_HEADING3_FONT_TOGGLE_BOLD, ref: types.HEADING3 },
        { action: actionTypes.GS_TEXT_LOGO_FONT_TOGGLE_BOLD, ref: types.LOGO }
    ]),
    ...euh.getFontItalicToggleEpicUpdaters([
        { action: actionTypes.GS_TEXT_NORMAL_FONT_TOGGLE_ITALIC, ref: types.NORMAL },
        { action: actionTypes.GS_TEXT_HEADING1_FONT_TOGGLE_ITALIC, ref: types.HEADING1 },
        { action: actionTypes.GS_TEXT_HEADING2_FONT_TOGGLE_ITALIC, ref: types.HEADING2 },
        { action: actionTypes.GS_TEXT_HEADING3_FONT_TOGGLE_ITALIC, ref: types.HEADING3 },
        { action: actionTypes.GS_TEXT_LOGO_FONT_TOGGLE_ITALIC, ref: types.LOGO }
    ]),
    ...euh.getFontUnderlineToggleEpicUpdaters([
        { action: actionTypes.GS_TEXT_NORMAL_FONT_TOGGLE_UNDERLINE, ref: types.NORMAL },
        { action: actionTypes.GS_TEXT_HEADING1_FONT_TOGGLE_UNDERLINE, ref: types.HEADING1 },
        { action: actionTypes.GS_TEXT_HEADING2_FONT_TOGGLE_UNDERLINE, ref: types.HEADING2 },
        { action: actionTypes.GS_TEXT_HEADING3_FONT_TOGGLE_UNDERLINE, ref: types.HEADING3 },
        { action: actionTypes.GS_TEXT_LOGO_FONT_TOGGLE_UNDERLINE, ref: types.LOGO }
    ]),
    ...euh.getFontSizeEpicUpdaters([
        { action: actionTypes.GS_TEXT_NORMAL_FONT_SIZE_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TEXT_HEADING1_FONT_SIZE_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TEXT_HEADING2_FONT_SIZE_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TEXT_HEADING3_FONT_SIZE_CHANGED, ref: types.HEADING3 },
        { action: actionTypes.GS_TEXT_LOGO_FONT_SIZE_CHANGED, ref: types.LOGO }
    ]),
    ...euh.getCharacterSpacingEpicUpdaters([
        { action: actionTypes.GS_TEXT_NORMAL_TEXT_CHARACTER_SPACING_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TEXT_HEADING1_TEXT_CHARACTER_SPACING_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TEXT_HEADING2_TEXT_CHARACTER_SPACING_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TEXT_HEADING3_TEXT_CHARACTER_SPACING_CHANGED, ref: types.HEADING3 },
        { action: actionTypes.GS_TEXT_LOGO_TEXT_CHARACTER_SPACING_CHANGED, ref: types.LOGO }
    ]),
    ...euh.getLineHeightUpdaters([
        { action: actionTypes.GS_TEXT_NORMAL_LINE_HEIGHT_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TEXT_HEADING1_LINE_HEIGHT_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TEXT_HEADING2_LINE_HEIGHT_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TEXT_HEADING3_LINE_HEIGHT_CHANGED, ref: types.HEADING3 },
        { action: actionTypes.GS_TEXT_LOGO_LINE_HEIGHT_CHANGED, ref: types.LOGO },
    ]),
    ...euh.getShadowEpicUpdaters([
        { action: actionTypes.GS_TEXT_NORMAL_TEXT_SHADOW_REMOVED, ref: types.NORMAL },
        { action: actionTypes.GS_TEXT_HEADING1_TEXT_SHADOW_REMOVED, ref: types.HEADING1 },
        { action: actionTypes.GS_TEXT_HEADING2_TEXT_SHADOW_REMOVED, ref: types.HEADING2 },
        { action: actionTypes.GS_TEXT_HEADING3_TEXT_SHADOW_REMOVED, ref: types.HEADING3 },
    ]),
    ...euh.getShadowColorEpicUpdaters([
        { action: actionTypes.GS_TEXT_NORMAL_TEXT_SHADOW_COLOR_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TEXT_HEADING1_TEXT_SHADOW_COLOR_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TEXT_HEADING2_TEXT_SHADOW_COLOR_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TEXT_HEADING3_TEXT_SHADOW_COLOR_CHANGED, ref: types.HEADING3 },
    ]),
    ...euh.getShadowBlurRadiusEpicUpdaters([
        { action: actionTypes.GS_TEXT_NORMAL_TEXT_SHADOW_BLUR_RADIUS_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TEXT_HEADING1_TEXT_SHADOW_BLUR_RADIUS_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TEXT_HEADING2_TEXT_SHADOW_BLUR_RADIUS_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TEXT_HEADING3_TEXT_SHADOW_BLUR_RADIUS_CHANGED, ref: types.HEADING3 },
    ]),
    ...euh.getShadowHorizontalOffsetEpicUpdaters([
        { action: actionTypes.GS_TEXT_NORMAL_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TEXT_HEADING1_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TEXT_HEADING2_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TEXT_HEADING3_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED, ref: types.HEADING3 },
    ]),
    ...euh.getShadowVerticalOffsetEpicUpdaters([
        { action: actionTypes.GS_TEXT_NORMAL_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TEXT_HEADING1_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TEXT_HEADING2_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TEXT_HEADING3_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED, ref: types.HEADING3 },
    ]),
    ...euh.getFontColorEpicUpdates([
        { action: actionTypes.GS_TEXT_NORMAL_FONT_COLOR_CHANGED, ref: types.NORMAL },
        { action: actionTypes.GS_TEXT_HEADING1_FONT_COLOR_CHANGED, ref: types.HEADING1 },
        { action: actionTypes.GS_TEXT_HEADING2_FONT_COLOR_CHANGED, ref: types.HEADING2 },
        { action: actionTypes.GS_TEXT_HEADING3_FONT_COLOR_CHANGED, ref: types.HEADING3 },
        { action: actionTypes.GS_TEXT_LOGO_FONT_COLOR_CHANGED, ref: types.LOGO }
    ]),
    {
        conditions: [actionTypes.GS_TEXT_LOGO_FONT_COLOR_CHANGED_AUTO_COLOR],
        reducer: ({
            state: stylesheets, values: [{ themeColor }]
        }: { state: Stylesheets, values: [{ themeColor: ThemeColorDataType }] }) => {
            return {
                state: euh.updateGlobalstyles(types.LOGO, stylesheets, { themeColor }),
                updateReason: LOGO_STYLE_CHANGED
            };
        }
    },
    ...euh.getFontColorRemoveEpicUpdates([
        { action: actionTypes.GS_TEXT_NORMAL_FONT_COLOR_REMOVED, ref: types.NORMAL },
        { action: actionTypes.GS_TEXT_HEADING1_FONT_COLOR_REMOVED, ref: types.HEADING1 },
        { action: actionTypes.GS_TEXT_HEADING2_FONT_COLOR_REMOVED, ref: types.HEADING2 },
        { action: actionTypes.GS_TEXT_HEADING3_FONT_COLOR_REMOVED, ref: types.HEADING3 },
        { action: actionTypes.GS_TEXT_LOGO_FONT_COLOR_REMOVED, ref: types.LOGO }
    ])
];
