
import { GoogleReviewsComponent, GoogleReviewsComponentExtension } from "./flowTypes";
import { getUpdatedDimension } from "./utils";

type AdjustmentHookProps = {
    component: GoogleReviewsComponent,
    componentExtension: GoogleReviewsComponentExtension
}

export default {
    hook: ({ component, componentExtension }: AdjustmentHookProps) => {
        const { height, width } = component;
        const showHeader = !!component.headerText;
        let updatedComponent = component;
        const { minDimensions } = getUpdatedDimension(component.layoutType, component.reviews.length, showHeader);
        let updatedWidth = Math.max(width, minDimensions.width);
        let updatedHeight = Math.max(height, minDimensions.height);
        let updatedExtensions = componentExtension;
        if (updatedWidth !== width || updatedHeight !== height) {
            updatedComponent = {
                ...updatedComponent,
                height: updatedHeight,
                width: updatedWidth
            };
        }
        if (
            updatedExtensions?.minDimensions?.width !== minDimensions.width ||
            updatedExtensions?.minDimensions?.height !== minDimensions.height) {
            updatedExtensions = {
                ...updatedExtensions,
                minDimensions
            };
        }

        return [updatedComponent, updatedExtensions];
    },
    shouldCallHook: () => true
};
