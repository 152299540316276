import React from 'react';
import styles from './Shutterstock.css';
import type { Intl } from "../../../../view/intl/injectIntl";
import injectIntl from "../../../../view/intl/injectIntl";
import { ShutterstockCategoryMessages } from '../messages';
import { AllCategoryId } from "./ImagesCategories";
import Msg from "../../../../view/intl/Msg";

type Props = {
    selectedCategoryId: string,
    searchTerm: string,
    intl: Intl,
};

const ShutterStockSearchNoResultComClass = (props: Props) => {
    const
        { searchTerm, selectedCategoryId, intl } = props,
        hasAllCategory = selectedCategoryId === AllCategoryId;

    if (hasAllCategory) {
        const params = { searchTerm };
        return (
            <div className={styles.noResultsFound}>
                <Msg k="shutterstock.noSearchResultAllCategory" params={params}>
                    {`No results found for \"{searchTerm}\".`}
                </Msg>
            </div>
        );
    }

    const params = {
        searchTerm,
        category: intl.msgJoint(ShutterstockCategoryMessages[selectedCategoryId])
    };
    return (
        <div className={styles.noResultsFound}>
            <Msg k="shutterstock.noSearchResult" params={params}>
                {`No results found for \"{searchTerm}\" in \"{category}\".`}
            </Msg>
        </div>
    );
};

export const ShutterstockSearchNoResult = injectIntl(ShutterStockSearchNoResultComClass);
