import {
    DEFAULT_BLUR_RADIUS,
    DEFAULT_SHADOW_OFFSET_X,
    DEFAULT_SHADOW_OFFSET_Y
} from "../../../../oneweb/Text/constants";
import type {
    ThemeLinkAccentClassType, ThemeLinkUnderlinedClassType, ThemeLinkTextLikeClassType
} from '../../../../ThemeGlobalData/flowTypes';

export const SHADOW_TEXT_REGEX: RegExp = /(-?\d+px)|(rgba?\(.+\)|(#([a-z]|[A-Z]|\d)+))/g;   //NOSONAR
export const SELECTED_GLOBAL_STYLE_REGEX: RegExp = /textnormal|textheading1|textheading2|textheading3/g;
export const SELECTED_GLOBAL_ELEMENT_REGEX: RegExp = /P|H1|H2|H3/g;
export const LINK_CLASS_NAME_REGEX: RegExp = /^link[\d]+$/;
export const themeaccent = 'themeaccent';
export const themeunderlined = 'themeunderlined';
export const themetextlike = 'themetextlike';
export const THEME_LINK_CLASS_LIST: [ThemeLinkAccentClassType, ThemeLinkUnderlinedClassType, ThemeLinkTextLikeClassType] =
    [themeaccent, themeunderlined, themetextlike];
export const MCT_CONTENT_BODY_REGEX: RegExp = /mce-content-body/;
export const MAKE_FUNC_REGEX: RegExp = /^make/;
export const TRANSPARENT_RGBA_COLOR: string = 'rgba(0,0,0,0)';

export const DEFAULT_SHADOW = {
    blurRadius: DEFAULT_BLUR_RADIUS,
    shadowOffsetX: DEFAULT_SHADOW_OFFSET_X,
    shadowOffsetY: DEFAULT_SHADOW_OFFSET_Y
};

export const DEFAULT_GLOBAL_STYLE: string = 'textnormal';
export const DEFAULT_LINE_HEIGHT = 1.2;
export const DEFAULT_LETTER_SPACING = 0;
