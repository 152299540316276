import CALL_API from "../../../redux/middleware/api/CALL_API";

export const
    LOAD_SECTION_COMPONENTS_FOR_PAGE = 'LOAD_SECTION_COMPONENTS_FOR_PAGE',

    CHECK_COMPONENT_TITLE_REQUEST = 'CHECK_COMPONENT_TITLE_REQUEST',
    CHECK_COMPONENT_TITLE_SUCCESS = 'CHECK_COMPONENT_TITLE_SUCCESS',
    CHECK_COMPONENT_TITLE_FAILURE = 'CHECK_COMPONENT_TITLE_FAILURE',

    CheckComponentTitleActionTypes = [
        CHECK_COMPONENT_TITLE_REQUEST,
        CHECK_COMPONENT_TITLE_SUCCESS,
        CHECK_COMPONENT_TITLE_FAILURE
    ],

    getComponentsTitlesByPageAndKind = (pageId) => ({
        [CALL_API]: {
            types: CheckComponentTitleActionTypes,
            endpoint: "getPageDataSet",
            endpointParams: pageId,
        }
    });
