import type { ImageComponent } from '../flowTypes';

export default function (component: ImageComponent, { payload: { linkAction } }: any): ImageComponent {
    return {
        ...component,
        linkAction,
        openLink: true,
        lightBoxEnabled: false
    };
}
