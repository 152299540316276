/* eslint-disable max-len */

import React from 'react';
import { injectIntl } from '../../view/intl/index';
import { Dialog, DialogBody } from '../../view/common/dialogs/baseDialog/index';
import getCenteredDialogConfig from '../DialogManager/getCenteredDialogConfig';
import { UPGRADE_BROWSER } from './actionTypes';
import DialogTitleBox from "../../view/common/dialogs/baseDialog/DialogTitleBox";
import HorizontalSpacer from "../../view/common/HorizontalSpacer";
import { StripTypes } from "../../view/common/dialogs/baseDialog/Dialog";
import warningStyles from '../../view/common/dialogs/WarningDialog/WarningDialog.css';
import styles from './styles.css';
import DialogFooter from "../../view/common/dialogs/baseDialog/DialogFooter";

const
    width = 480,
    height = 340,
    unsupportedBrowserTitle = 'msg: unsupported.browser.title {Unsupported browser}',
    getUnsupportedBrowserMessage = intl => intl.msgJoint('msg: unsupported.browser.message {Your browser is not supported or may be out of date. For the best experience using Website Builder, please upgrade your browser.}'),
    getDialogFooterProps = (dispatch) => {
        return {
            mctaText: "msg: common.upgrade {Upgrade}",
            mctaHandler: () => dispatch({ type: UPGRADE_BROWSER })
        };
    },
    upgradeBrowserDialogComponent = injectIntl(({ intl, dispatch }) => {
        return (
            <Dialog stripType={StripTypes.WARNING} showCloseBtn={false}>
                <DialogTitleBox
                    title={unsupportedBrowserTitle}
                    iconClass={warningStyles.iconWarning}
                    titleClass={styles.unsupportedBrowserTitle}
                    className={warningStyles.warningDialogTitle}
                />
                <DialogBody>
                    <p>{getUnsupportedBrowserMessage(intl)}</p>
                    <HorizontalSpacer />
                </DialogBody>
                <DialogFooter
                    {...getDialogFooterProps(dispatch)}
                    noTopBorder
                    className={warningStyles.warningDialogFooter}
                />
            </Dialog>
        );
    }),
    upgradeBrowserDialog = {
        ...getCenteredDialogConfig(width, height, upgradeBrowserDialogComponent)
    };

export const dialog = upgradeBrowserDialog;
