import React from 'react';
import { connect } from "react-redux";
import * as R from 'ramda';
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import * as InserterStyles from "../../../../Panel/view/Inserter.css";
import * as styles from "../styles.css";
import { DroppableCmpShortcut } from "../../../../Panel/view/DroppableCmpShortcut";
import BackgroundKind from "../../kind";
import HoverBoxKind from "../../../HoverBox/kind";
import type { AppState } from "../../../../../redux/modules/flowTypes";
import calcRenderProps from "../../calcRenderProps";
import View from "../../view/index";
import { boxTemplates, boxesConfig, boxesStylesMap, boxesLabelMap, hoverBoxTemplates } from './config';
import { getDefaultState, getThemeOverridesFromComponentData } from "../utils";
import type { BackgroundCalcRenderPropsResult } from "../../flowTypes";

const boxesLabel = 'msg: common.Boxes {Boxes}',
    BoxWidth = 340,
    BoxHeight = 340;

const Box = (props) => {
    const { type, componentsDependencies, kind } = props;
    const componentDependencies = componentsDependencies[kind],
        boxDefaultState = getDefaultState(kind),
        component = R.mergeDeepRight(boxDefaultState, R.mergeDeepRight(boxesConfig, boxesStylesMap[type])),
        themeOverrides = getThemeOverridesFromComponentData(
            component, componentDependencies.themeSettingsData.autoColorMode, componentDependencies.themeColorsData
        ),
        cmpViewProps: BackgroundCalcRenderPropsResult =
            calcRenderProps({ isWorkspace: false, component: R.mergeDeepRight(component, themeOverrides), componentDependencies }),
        payload = {
            style: component.style,
            width: BoxWidth,
            height: BoxHeight,
            ...(componentDependencies.themeSettingsData.autoColorMode ? R.omit(['selectedBorderTheme'], themeOverrides) : {}),
        };

    const view = View({
        ...cmpViewProps,
        className: styles.box
    });

    return <DroppableCmpShortcut className={styles.boxWrapper} {...props} payload={payload} kind={kind} isOverlayExtended>
        {view}
        <span className={styles.boxLabel}>{props.intl.msgJoint(boxesLabelMap[type])}</span>
    </DroppableCmpShortcut>;
};

const boxesComponent = (props) => {
    const cmpTemplates = props.kind === HoverBoxKind ? hoverBoxTemplates : boxTemplates;
    return <div>
        {!props.hideSubTitle && <div className={InserterStyles.subTitle}>{props.intl.msgJoint(boxesLabel)}</div>}
        <VerticalSpacer y={20} />
        <div className={`${InserterStyles.shortCutsContainer} ${styles.boxShortcutContainer}`}>
            {cmpTemplates.map((type, index) => <Box {...props} type={type} key={index} />)}
        </div>
        <VerticalSpacer y={20} />
    </div>;
};

const mapBoxStateToProps = (appState: AppState) => ({
    componentsDependencies: appState.selectedWorkspace.componentsDependencies,
    kind: BackgroundKind
});

export const Boxes = connect(mapBoxStateToProps)(boxesComponent);

const mapHoverBoxStateToProps = (appState: AppState) => ({
    componentsDependencies: appState.selectedWorkspace.componentsDependencies,
    kind: HoverBoxKind,
    hideSubTitle: true
});
export const PreDefinedHoverBoxes = connect(mapHoverBoxStateToProps)(boxesComponent);
