import type { AppStore } from "../../../redux/modules/flowTypes";
import { GLOBAL_STYLES_DIALOG_OPENED } from "./actionTypes";
import openDialogAC from '../../App/actionCreators/openDialog';
import GLOBAL_STYLES_DIALOG_ID from '../../Globalstyles/dialogId';

export const globalStyleDialogInvokerMiddleware = (store: AppStore) => (next: Dispatch) => (action: AnyAction) => {
    if (action.type === GLOBAL_STYLES_DIALOG_OPENED) {
        const { payload } = action;
        store.dispatch(openDialogAC(GLOBAL_STYLES_DIALOG_ID, payload));
    }
    return next(action);
};
