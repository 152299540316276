// @ts-nocheck
import React from 'react';
import { injectIntl, Intl } from '../../../../view/intl/index';
import { getDAL } from "../../../../../dal/index";
import Scrollbar from '../../../../view/common/Scrollbar/index';
import LoadingIndicator from '../../../../view/common/LoadingIndicator/index';
import { openApiErrorHandlerDialog } from '../../../../redux/middleware/api/errorHandler/actions';
import { ProductItem } from './ProductItem';
import NoResults from '../../FeaturedProducts/ProductSelector/components/NoResults';
import styles from './styles.css';

type Props = {
    dispatch: Function,
    selectedProductId: string,
    setSelectedProductId: Function,
    searchText: string,
    intl: Intl,
    hasOnlyDigitalShop: Boolean
}

type SearchResult = {
    products: [],
    categoriesExist: boolean
};

const FETCH_STATUS = {
    INITIAL: 'initial',
    IN_PROGRESS: 'inprogress',
    COMPLETED: 'completed',
};

const getCategoryName = (category: string, intl: Intl) => {
    if (category === 'webshop.no-category') {
        return intl.msgJoint("msg: component.ProductWidget.NoCategory {Single products}");
    }
    return category;
};

const PAGE_SIZE = 20;

export const ProductsList = injectIntl(({
    dispatch,
    selectedProductId,
    setSelectedProductId,
    searchText,
    intl,
    hasOnlyDigitalShop
}: Props) => {
    const scrollRef = React.useRef<Element>(null);

    const [products, setProducts] = React.useState<Record<string, any>[]>([]);

    const [categoriesExist, setCategoriesExist] = React.useState(false);

    const [fetchStatus, setFetchStatus] = React.useState(FETCH_STATUS.INITIAL);

    const [scrollPosition, setScrollPosition] = React.useState(0);

    const getProducts = async (searchStr: string, limit: number, disableFetchStatus: boolean = false): Promise<SearchResult> => {
        let searchResult: SearchResult = { products: [], categoriesExist: false };
        if (!disableFetchStatus) {
            setFetchStatus(FETCH_STATUS.IN_PROGRESS);
        }
        const dal = getDAL();
        let url = `/record/products/search?limit=${limit}${hasOnlyDigitalShop ? '&type=alldigital' : ''}`;

        if (searchStr) {
            url += `&searchText=${searchStr}`;
        }
        try {
            const response = await dal.getWebshopData(url);
            if (response.body && !response.body.error) {
                searchResult = response.body;
            }
            if (!disableFetchStatus) {
                setFetchStatus(FETCH_STATUS.COMPLETED);
            }
        } catch (err: any) {
            if (!disableFetchStatus) {
                setFetchStatus(FETCH_STATUS.COMPLETED);
            }
            dispatch(openApiErrorHandlerDialog({
                messages: 'msg: component.webshop.featuredProducts.fetchError {Sorry, loading failed.}',
            }));
        }
        return searchResult;
    };

    const fetchProductsAndScroll = async (searchStr, limit, disableFetchStatus = false) => {
        // get scroll position before getting products
        const el = scrollRef.current;
        if (el) {
            // @ts-ignore
            const scrollTop = el.getScrollTop();
            if (scrollTop > 0) {
                setScrollPosition(scrollTop);
            }
        }

        // fetch products
        const searchResult = await getProducts(searchStr, limit, disableFetchStatus);
        setProducts(searchResult.products);
        setCategoriesExist(searchResult.categoriesExist);

        // set the scroll position back
        setTimeout(() => {
            if (el && scrollPosition > 0) {
                // @ts-ignore
                el.scrollTop(scrollPosition - 5);
            }
        }, 50);
    };

    React.useEffect(() => {
        setProducts([]);
        fetchProductsAndScroll(searchText, PAGE_SIZE);
    }, [searchText]);

    let currentCategoryName = '';
    let rowSize = 0;

    const hasScrollReachedEnd = (el) => {
        let scrollAtEnd = false;
        if (el) {
            const scrollHeight = el.getScrollHeight();
            const scrollTop = el.getScrollTop();
            const clientHeight = el.getClientHeight();
            scrollAtEnd = (scrollHeight - Math.abs(scrollTop)) === clientHeight;
        }
        return scrollAtEnd;
    };

    return (
        <Scrollbar
            scrollRef={scrollRef}
            height={450}
            onScroll={() => {
                const el = scrollRef.current;
                if (el && hasScrollReachedEnd(el)) {
                    fetchProductsAndScroll(searchText, products.length + PAGE_SIZE, true);
                }
            }}
            className={styles.productListContainer}
        >
            {fetchStatus === FETCH_STATUS.COMPLETED &&
                products.length > 0 &&
                <div className={styles.categoriesContainer}>
                    {products.map((product, productIndex) => {
                        const categoryName = getCategoryName(product.categoryName, intl);
                        const isCurrentCategory = currentCategoryName === categoryName;
                        if (!searchText && !isCurrentCategory && categoriesExist) {
                            currentCategoryName = categoryName;
                            rowSize = 0;
                        }
                        if (rowSize === 3) {
                            rowSize = 0;
                        }
                        const isRowSizeZero = rowSize === 0;
                        rowSize += 1;
                        return (
                            <React.Fragment key={'row-' + productIndex}>
                                {!searchText && !isCurrentCategory && categoriesExist &&
                                    <div className={styles.categoryName}>
                                        {currentCategoryName}
                                    </div>}
                                {isRowSizeZero && <div className={styles.newRow} />}
                                <div className={styles.rowItem}>
                                    <ProductItem
                                        product={product}
                                        selected={product.productId === selectedProductId}
                                        toggleSelected={(productId) => {
                                            setSelectedProductId(productId);
                                        }}
                                    />
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>}
            {fetchStatus === FETCH_STATUS.COMPLETED &&
                products.length === 0 &&
                !!searchText &&
                <div className={styles.noResults}>
                    <NoResults
                        searchTerm={searchText}
                        category=""
                    />
                </div>}
            {fetchStatus === FETCH_STATUS.IN_PROGRESS && (
                <div className={styles.loadingIndicator}>
                    <LoadingIndicator />
                </div>
            )}
        </Scrollbar>
    );
});
