import * as R from 'ramda';
import * as updateReasons from './updateReasons';
import { setSelectedComponentsIds } from "./selectedComponentsIds";
import { GHOST_CODE_COMPONENTS_SORT_END } from "../../CodeComponentsRenderer/CodeComponentsRendererActionTypes";
import { receiveOnly } from "../../../../epics/makeCondition";
import { codeComponentsRendererValueActionType } from "../../CodeComponentsRenderer/epic/valueActionType";
import type { ComponentsEvalEpicUpdater } from "./flowTypes";

const
    setOrderIndex = R.assoc('orderIndex'),
    // TODO WBTGEN-5871 cover swapOrderIndexesOfComponents by unit tests
    // before ['c', 3], ['b', 4], ['a', 6], ['m', 8], ['k', 10], ['f', 12]

    // FORWARD b => k
    // from = 'b'
    // to = 'k'
    // between = ['a', 'm']
    // after ['c', 3], ['a', 4], ['m', 6], ['b', 8], ['k', 10], ['f', 12]

    // REVERSE k => b
    // from = 'k'
    // to = 'b'
    // between = ['a', 'm']
    // after ['c', 3], ['k', 4], ['b', 6], ['a', 8], ['m', 10],  ['f', 12]
    moveFirstElemToLast = (arr) => [...R.drop(1, arr), R.head(arr)],
    moveLastElemToFirst = (arr) => [R.last(arr), ...R.dropLast(1, arr)],
    swapOrderIndexesOfComponents = (oldIndex, newIndex, componentsOfKind) => componentsMap => {
        const
            minIndex = R.min(oldIndex, newIndex),
            maxIndex = R.max(oldIndex, newIndex),
            movingForward = () => minIndex === oldIndex,
            // picking components that participate in sorting
            chainOfComponents = R.pipe(
                R.drop(minIndex),
                R.take(maxIndex - minIndex + 1),
            )(componentsOfKind),
            // sequence of order indexes will remain same, so will keep it to lookup after components ids sorting
            orderIndexesSequence = chainOfComponents.map(R.prop('orderIndex')),
            initialIdsSequence = chainOfComponents.map(R.prop('id')),
            newIdsSequence = R.ifElse(movingForward, moveFirstElemToLast, moveLastElemToFirst)(initialIdsSequence),
            // creating a transformation for components map, from new order of components ids and old order of order indexes
            orderIndexTransformation = newIdsSequence.reduce((acc, id, index) => {
                acc[id] = setOrderIndex(orderIndexesSequence[index]);
                return acc;
            }, {});

        return R.evolve(orderIndexTransformation, componentsMap);
    },
    onGhostComponentDragEndUpdater: ComponentsEvalEpicUpdater = {
        conditions: [receiveOnly(codeComponentsRendererValueActionType), GHOST_CODE_COMPONENTS_SORT_END],
        reducer: ({ values: [{ codeComponents }, { kind, oldIndex, newIndex }], state: epicState }) => {
            const
                componentsOfKind = codeComponents[kind],
                fromComponentId = componentsOfKind[oldIndex].id,
                onlySelectionUpdate = oldIndex === newIndex;

            return {
                state: R.pipe(
                    setSelectedComponentsIds([fromComponentId]),
                    oldIndex === newIndex ? R.identity : R.evolve({
                        state: {
                            componentsMap: swapOrderIndexesOfComponents(
                                oldIndex,
                                newIndex,
                                componentsOfKind
                            )
                        }
                    })
                )(epicState),
                updateReason: onlySelectionUpdate ? updateReasons.CHANGE_SCOPE : updateReasons.ORDER_CHANGED
            };
        }
    };

export {
    onGhostComponentDragEndUpdater
};
