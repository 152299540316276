import workspace from "./view/workspace";
import reducer from "./reducer/index";
import propertiesPanel from "./propertiesPanel/index";
import { ContactFormComponentConfigDialogId } from './dialogIds';
import kind from './kind';
import buttonDefaultStyleIdEpic from '../commonEpics/buttonDefaultStyleId';
import adjustComponentOnAdd from './adjustComponentOnAdd';
import adjustmentHookConfig from "./adjustmentHookConfig";
import validateComponentAdd from './validateComponentAdd';
import { ErrorMsgsOnAdd } from "./constants";
import contextMenu from './contextMenu';
import {
    contactFormAllowArbitraryEmailReducer,
    isSiteTemplatePartnerReducer
} from "../../App/epics/appConfig/index";
import ownerEmailsEpic from '../../OwnerEmails/epic/index';
import setEmailEpic from './epics/setEmail/index';
import siteFontsReducer from '../../Fonts/siteFontsReducer';
import fontStatusReducer from '../../Fonts/fontStatusReducer';
import currentPageNameEpic from '../../App/epics/currentPageName/index';
import stylesheetsEpic from "../../Workspace/epics/stylesheets/index";
import stylesheetsIdToNameMapEpic from '../../Workspace/epics/stylesheets/idToNameMap';
import componentMainActions from './componentMainActions/index';
import previewConfig from "./previewConfig";
import { ComponentNames } from "../constants";
import type { ContactFormComponent } from "./flowTypes";
import { globalVariablesEpic } from "../../App/epics/globalVariablesEpic";
import siteDataEpic from "../../App/epics/siteData/index";
import { replaceTagsWithContent } from "../Text/view/replaceTagsWithContent";
import type { ChangeOldComponentDataBeforeImportProps } from "../../../view/oneweb/flowTypes";
import { colorThemeSiteSettingsEpic } from "../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import type { CalcRenderer } from "../../Workspace/epics/componentsProps/flowTypes";
import type { ThemeSiteSettingsDataType } from "../../ThemeGlobalData/flowTypes";
import type { ContactFormUIProps } from "./view/flowTypes";
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";
import type { Stylesheets } from "../../Workspace/epics/stylesheets/flowTypes";
import { marketingConsent } from "./reducer/marketingConsent";

type ComponentDependencies = {
    themeSettingsData: ThemeSiteSettingsDataType,
    globalStyles: Stylesheets
};

type ContactFormCalcRenderProps =
    CalcRenderer<ContactFormComponent, null, ComponentDependencies, ContactFormUIProps>;

// TODO contact form field names should be localizible
export default {
    kind,
    workspace,
    label: ComponentNames[kind],
    shortcutIconName: 'contactForm',
    requireConfigurationOnDrop: () => true,
    configurationDialog: ContactFormComponentConfigDialogId,
    dependsOn: {
        buttonDefaultStyleId: buttonDefaultStyleIdEpic.reducer,
        globalStyles: stylesheetsEpic.reducer,
        siteFonts: siteFontsReducer,
        fontStatus: fontStatusReducer,
        pageName: currentPageNameEpic.reducer,
        partnerIsSiteTemplates: isSiteTemplatePartnerReducer,
        allowArbitraryEmail: contactFormAllowArbitraryEmailReducer,
        ownerEmailIds: ownerEmailsEpic.reducer,
        setEmailState: setEmailEpic.reducer,
        stylesheetsIdToNameMap: stylesheetsIdToNameMapEpic.reducer,
        globalVariables: globalVariablesEpic.reducer,
        site: siteDataEpic.reducer,
        themeSettingsData: colorThemeSiteSettingsEpic.reducer,
        privacyPolicy: marketingConsent
    },
    calcRenderProps: ({
        component,
        componentDependencies,
        componentId,
        selectedParentTheme
    }) : ContactFormCalcRenderProps => {
        const { themeSettingsData, globalStyles } = componentDependencies,
            themeColorsData = getThemeColorsFromStylesheet(globalStyles),
            { autoColorMode } = themeSettingsData;
        return {
            ...componentDependencies,
            themeColorsData,
            componentId,
            component,
            selectedParentTheme: autoColorMode && selectedParentTheme,
        };
    },
    contextMenu,
    componentMainActions,
    adjustComponentOnAdd,
    propertiesPanel,
    reducer,
    adjustmentHookConfig,
    validateComponentAdd,
    errorMsgsOnAdd: ErrorMsgsOnAdd,
    mobileEditorConfig: {
        ...previewConfig,
        getHiddenElementData: (props: ContactFormComponent) => ({ content: props.subject }),
    },
    changeOldComponentDataBeforeImport: ({
        component,
        globalVariables,
        site
    }: ChangeOldComponentDataBeforeImportProps<ContactFormComponent>) => {
        return {
            ...component,
            formElements: Object.keys(component.formElements).reduce((acc, type) => {
                const elm = component.formElements[type];

                const { values, name, errorMessage } = elm;

                acc[type] = {
                    ...elm,
                    name: name ? replaceTagsWithContent(name, { site, globalVariables }) : name,
                    values: values ? values.map(v => replaceTagsWithContent(v, { site, globalVariables })) : values,
                    errorMessage: errorMessage ?
                        replaceTagsWithContent(errorMessage, { site, globalVariables }) : errorMessage
                };
                return acc;
            }, {}),
            successMessage: replaceTagsWithContent(component.successMessage, { site, globalVariables }),
            submitBtn: replaceTagsWithContent(component.submitBtn, { site, globalVariables }),
            marketingConsentCheckBoxText: replaceTagsWithContent(component.marketingConsentCheckBoxText, { site, globalVariables }),
            readPrivacyPolicyText: replaceTagsWithContent(component.readPrivacyPolicyText, { site, globalVariables })
        };
    }
};
