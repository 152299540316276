import React from 'react';
import { openDocumentConfigDialogAction } from "../../actions";
import { PageIds, PageTitles } from "../constants";
import type { AppDispatch } from "../../../../../redux/modules/flowTypes";
import { FileChooserButton } from "../../../../PropertiesPanel/view/components/FileChooserButton/FileChooserButton";
import Resource from '../../../../../redux/modules/children/fileChooser/Resource';

type Props = {
    src: string,
    dispatch: AppDispatch,
};

const getFilename = (src: string): string => (
    Resource.isWebspaceUrl(src)
        ? Resource.fromWebspaceUrl(src).getName()
        : src
);

export const DocumentFileChooserButton = (props: Props) => {
    const
        { src, dispatch } = props,
        fileChooserProps = {
            filename: getFilename(src),
            fileChangeAction: openDocumentConfigDialogAction(src),
            headLabel: PageTitles[PageIds.MAIN],
            showAssetInfo: true,
            dispatch
        };

    return (
        <FileChooserButton {...fileChooserProps} />
    );
};
