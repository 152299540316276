/* eslint-disable react/jsx-fragments */
/* eslint-disable no-confusing-arrow */
/* eslint-disable max-len */
import React from 'react';

import { ADDRESS_KIND } from './kind';
import { ComponentNames } from '../../constants';
import {
    addressUrlReducer,
    addressNameReducer,
    addressStreetAddressReducer,
    addressZipReducer,
    addressCityReducer,
    addressAreaReducer,
    addressCountryCodeReducer,
} from '../../../App/epics/globalVariablesEpic';
import { formatMessage } from '../../../../view/intl/CurrentLocale';
import PropertiesCheckBox from '../../../../view/common/CheckBox/PropertiesCheckBox';
import VerticalSpacer from '../../../../view/common/VerticalSpacer';
import InputField from '../../../../view/common/Input/InputField';
import styles from '../textLike.css';
import { getAddressPlaceholder } from "../placeholders";
import { isAddressSet } from '../../../../constants';
import { textLikeWorkspaceConfigFactory } from "../factories/textLikeWorkspaceConfigFactory";
import { addressComponentView } from "./addressComponentView";
import { getPlaceholderTextForAddress } from "./getPlaceholderTextForAddress";
import { computeComponentDependenciesForPreviewForAddress } from "./computeComponentDependenciesForPreviewForAddress";

const ADDRESS_SHOW_WEBSITE_TITLE_BEFORE_ADDRESS_TOGGLE = 'ADDRESS_SHOW_WEBSITE_TITLE_BEFORE_ADDRESS_TOGGLE';
const ADDRESS_SHOW_COUNTRY = 'ADDRESS_SHOW_COUNTRY';

const { config: addressComponentConfig } = textLikeWorkspaceConfigFactory({
    View: addressComponentView,
    label: ComponentNames[ADDRESS_KIND],
    kind: ADDRESS_KIND,
    dependsOn: {
        addressUrl: addressUrlReducer,
        addressName: addressNameReducer,
        addressStreetAddress: addressStreetAddressReducer,
        addressZip: addressZipReducer,
        addressCity: addressCityReducer,
        addressArea: addressAreaReducer,
        addressCountryCode: addressCountryCodeReducer,
    },
    // @ts-ignore
    isValueSet: isAddressSet,
    reducer: (state = { showWebsiteTitleBeforeAddress: true, showCountry: true, placeholder: {} }, action) => {
        if (action.type === ADDRESS_SHOW_WEBSITE_TITLE_BEFORE_ADDRESS_TOGGLE) {
            return { ...state, showWebsiteTitleBeforeAddress: !state.showWebsiteTitleBeforeAddress };
        }
        if (action.type === ADDRESS_SHOW_COUNTRY) {
            return { ...state, showCountry: !state.showCountry };
        }
        return state;
    },
    pp: {
        showLineSpacingOptionForSingleLineMode: true,
        mainPageIntroText: 'msg: address.pp.mainPageIntroText {This component needs an address stored in website settings.}',
        mainPageFooterText: 'msg: address.pp.mainPageFooterText {You will get more styling options as soon as you have added an address.}',
        addValueBtnLabel: 'msg: address.pp.addValueBtn {Add address}',
        updateValueBtnLabel: 'msg: address.pp.updateValueBtn {Update address}',
        valueStyleLabel: 'msg: common.address {Address}',
        mainPageRender: props => <React.Fragment>
            <PropertiesCheckBox
                containerClassName={styles.checkboxOverride}
                isChecked={props.component.generic.showCustomTitleFirst}
                label="msg: textlike.showCustomTitleFirst {Show custom title first}"
                onClick={() => props.dispatch({ type: props.TEXT_LIKE_SHOW_CUSTOM_TITLE_FIRST_TOGGLE })}
            />
            {
                props.component.generic.showCustomTitleFirst &&
                <React.Fragment>
                    <VerticalSpacer y={5} />
                    <InputField
                        value={props.component.generic.customPrefixText}
                        placeholder="msg: address.pp.addressPrefixPlaceholder {e.g. Address}"
                        onChange={payload => props.dispatch({ type: props.TEXT_LIKE_CUSTOM_PREFIX_TEXT_CHANGED, payload })}
                        maxLength={100}
                    />
                    <VerticalSpacer y={2} />
                </React.Fragment>
            }
            <PropertiesCheckBox
                containerClassName={styles.checkboxOverride}
                isChecked={props.component.specific.showCountry}
                label="msg: address.pp.showCountry {Show country}"
                onClick={() => props.dispatch({ type: ADDRESS_SHOW_COUNTRY })}
            />
            <PropertiesCheckBox
                containerClassName={styles.checkboxOverride}
                isChecked={!props.component.generic.showOnOneLine}
                label="msg: address.pp.showOnMultipleLines {Show on multiple lines}"
                onClick={() => props.dispatch({ type: props.TEXT_LIKE_SHOW_ON_ONE_LINE_TOGGLE })}
            />
        </React.Fragment>
    },
    mcta: {
        changeValueLabel: 'msg: common.changeAddress {Change address}',
        addValueLabel: 'msg: common.addAddress {Add address}',
    },
    getPrefixInitialValue: () => formatMessage({
        id: 'common.address',
        defaultMessage: 'Address'
    }),
    getStateAdjustmentsOnComponentAdd: () => {
        return {
            placeholder: getAddressPlaceholder(),
        };
    },
    getPlaceholderText: getPlaceholderTextForAddress,
    computeComponentDependenciesForPreview: computeComponentDependenciesForPreviewForAddress,
    sequenceNumberUnderMore: 300,
    defaultLineSpacing: 0.5,
    defaultHeight: 150,
});

export {
    addressComponentConfig
};

