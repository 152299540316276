import {
    InstagramGallerySquareType,
} from "./kind";
import { IMAGE_RATIO } from "../../presentational/ImageRatioComboBox/constants";

const TypePropsConfig = {
    [InstagramGallerySquareType]: {
        imageRatio: IMAGE_RATIO.SQUARE
    },
};

export default TypePropsConfig;
