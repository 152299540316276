import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useIntl } from '../../../../../../view/intl/index';

import { AI_TEXT_INPUT_CHANGED } from '../../../epics/AITextEpic/actionTypes';

import { GeneralSettings } from "./GeneralSettings/GeneralSettings";
import { TextGenerator } from './TextGenerator/TextGenerator';
import { FooterSection } from './FooterSection';

import styles from './styles.css';
import { gmbCategories as GMB_CATEGORIES } from '../../../../Template/gmbCategories';
import {
    generalAITextGlobalDataSelector,
    selectIsFirstTimeUser,
    selectLanguage,
    selectWebsiteCategories,
    selectWebsiteTitle
} from '../../../epics/AITextEpic/selectors';

export const UserInputPanel = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const
        gmbCategories = useSelector(selectWebsiteCategories),
        language = useSelector(selectLanguage),
        websiteTitle = useSelector(selectWebsiteTitle),
        generalAITextGlobalData = useSelector(generalAITextGlobalDataSelector),
        isFirstTimeUser = useSelector(selectIsFirstTimeUser);

    const [aiData, setAiData] = useState({});
    const [showGeneralPreferences, setShowGeneralPreferences] = useState(isFirstTimeUser);

    const onGeneralPreferencesChangeAction = (val) => {
        setShowGeneralPreferences(val);
    };

    useEffect(() => {
        if (Object.keys(generalAITextGlobalData).length) {
            setAiData(generalAITextGlobalData);
        } else {
            let gmbKey = (gmbCategories || '').split(',')[0];
            if (gmbKey && GMB_CATEGORIES[gmbKey]) {
                gmbKey = intl.msgJoint(GMB_CATEGORIES[gmbKey]);
            }
            setAiData({
                gmbKey,
                language,
                websiteTitle
            });
        }
    }, [generalAITextGlobalData, language, websiteTitle, gmbCategories, intl]);

    const onChangeAction = (name, value) => {
        dispatch({ type: AI_TEXT_INPUT_CHANGED, payload: { name, value } });
        setAiData((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    };

    return (
        <div className={styles.userInputPanel}>
            <div className={showGeneralPreferences ? styles.userInputScrollBar : styles.userInputNoScroll}>
                <div className={styles.userInputPanelContainer}>
                    <GeneralSettings
                        aiData={aiData}
                        isFirstTimeUser={isFirstTimeUser}
                        onChangeAction={onChangeAction}
                        showGeneralPreferences={showGeneralPreferences}
                        onGeneralPreferencesChangeAction={onGeneralPreferencesChangeAction}
                    />
                    <TextGenerator />
                </div>
            </div>
            <FooterSection
                aiData={aiData}
            />
        </div>
    );
};
