import Preview from "./view/preview";
import kind from "./kind";
import type { CodeComponent } from "./flowTypes";
import type { CmpSpecificStyles } from "../../Preview/flowTypes";

export default {
    view: Preview,
    kind,
    getSpecificStyles: ({ component: { id, mobileHide } }: CmpSpecificStyles<CodeComponent>): null | undefined | string => {
        if (mobileHide) return null;// preview takes care of hiding

        return `
            .mobileV div[data-id='${id}'] {
                display: block !important;
            }`;
    }
};
