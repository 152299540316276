import type { Element } from '../../../flowTypes';
import { executeOnAnyMatch } from './executeOnMatch';

/**
 * Splice one type of elements over another
 * Expects that both the types of elements passed are sorted
 *
 * Cases:
 *
 * <a>Text<b>Text</b>Text</a>
 * Consider the text inside b itself
 *
 * <b>te<a>xt</b>text</a>
 * Consider the common text inside a and b, remaining will be considered in prev a
 *
 * <a>Text<b>Text</a>Text</b>
 * Consider the common text inside b and a, remaining will be considered in next a
 *
 * <b>Text<a>Text</a>Text</b>
 * Consider the text inside a, remaining will be considered in prev and next a
 */

const spliceOverToDifferentLists = (
    A: Array<Element>,
    B: Array<Element>,
    extraProps: null | undefined | Record<string, any> = {}
): { match: Array<Element>, noMatch: Array<Element> } => {
    const match: Array<Element> = [], noMatch: Array<Element> = [];
    executeOnAnyMatch(A, B, (a, b) => {
        match.push({
            ...a,
            ...extraProps,
            // @ts-ignore
            start: Math.max(b.start, a.start),
            // @ts-ignore
            end: Math.min(b.end, a.end)
        });
    }, (a) => { noMatch.push(a); });
    return { match, noMatch };
};

const spliceOverToCombinedList = (
    A: Array<Element>,
    B: Array<Element>,
    extraProps: null | Record<string, any> = {}
): Array<Element> => {
    const { match, noMatch } = spliceOverToDifferentLists(A, B, extraProps);
    return [...match, ...noMatch];
};

export {
    spliceOverToCombinedList,
    spliceOverToDifferentLists,
};
