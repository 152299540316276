import React from "react";
import cx from "classnames";

import { useIntl } from "../../../../../view/intl";

import { PrimaryButton, SecondaryButton } from "../../../../../view/common/Button";
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";

import styles from "./ErrorModel.css";

export type Props = {
    imageClass?: any,
    title?: string,
    description: string,
    subDescription?: string,
    primaryButtonText: string,
    secondaryButtonText: string,
    primaryButtonAction: () => void,
    secondaryButtonAction: () => void
}

export const ErrorModel = (props: Props) => {
    const intl = useIntl();
    const {
        imageClass,
        title,
        description,
        subDescription,
        primaryButtonText,
        secondaryButtonText,
        primaryButtonAction,
        secondaryButtonAction
    } = props;

    return (
        <div className={styles.container}>
            <div className={styles.dialogContentWrapper}>
                <div className={styles.dialogContent}>
                    <div className={imageClass} />
                    <VerticalSpacer y={24} />
                    {
                        title && (
                            <div className={styles.title}>
                                {intl.msgJoint(title)}
                            </div>
                        )
                    }
                    <div className={styles.description}>
                        {intl.msgJoint(description)}
                    </div>
                    {
                        subDescription && (
                            <div className={styles.description}>
                                {intl.msgJoint(subDescription)}
                            </div>
                        )
                    }
                </div>
                <div className={styles.buttonContainer}>
                    <SecondaryButton
                        className={cx(styles.button, styles.secondaryButton)}
                        onClick={secondaryButtonAction}
                    >
                        {intl.msgJoint(secondaryButtonText)}
                    </SecondaryButton>
                    <PrimaryButton
                        className={styles.button}
                        onClick={primaryButtonAction}
                    >
                        {intl.msgJoint(primaryButtonText)}
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
};
