import * as constants from './constants';

const getPanelWidth = (expanded: boolean): number => {
    if (expanded) {
        return constants.PANEL_WIDTH;
    }
    return constants.PANEL_COLLAPSED_WIDTH;
};

export {
    getPanelWidth as default,
    getPanelWidth
};
