import { calcGalleryMaxThumbnailDimensions } from "./calcRenderProps";
import type { FacebookFeedGalleryComponent } from "./flowTypes";
import type { FacebookPostAsset } from "../../SocialAccounts/Facebook/FacebookFeed/types";
import {
    SHOW_MORE_BTN_HEIGHT,
    CAPTION_CONTAINER_HEIGHT_MAP,
} from './constants/index';

export const calcGalleryHeight =
    // eslint-disable-next-line max-len
    ({ component, facebookFeedMediaAssets }: { component: FacebookFeedGalleryComponent, facebookFeedMediaAssets: Array<FacebookPostAsset> }) => {
        const
            rows = Math.ceil(facebookFeedMediaAssets.length / component.columns),
            { maxThumbnailHeight } = calcGalleryMaxThumbnailDimensions(component);

        let tileHeight = maxThumbnailHeight;
        if (component.captionsEnabled) {
            tileHeight = maxThumbnailHeight + CAPTION_CONTAINER_HEIGHT_MAP[component.columns];
        }

        let
            calculatedHeight = Math.round(((
                tileHeight
            ) * rows) + (component.spacingPx * (rows - 1)));
        calculatedHeight += 5; // Adding 5 more pixels at the end of the component to have a complete box shadow effect
        if (component.showMoreBtn && facebookFeedMediaAssets.length) {
            calculatedHeight += SHOW_MORE_BTN_HEIGHT; // adding explicit height if show more btn is enabled
        }
        return calculatedHeight;
    };
