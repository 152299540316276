import React from 'react';
import { connect } from "react-redux";
import * as R from 'ramda';
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import * as InserterStyles from "../../../../Panel/view/Inserter.css";
import * as styles from "../styles.css";
import StripKind from "../../../Strip/kind";
import { DroppableCmpShortcut } from "../../../../Panel/view/DroppableCmpShortcut";
import { getDefaultReducerState } from "../../../../../redux/makeReducer/index";
import componentsRegistry from "../../../../../view/oneweb/registry/index";
import type { AppState } from "../../../../../redux/modules/flowTypes";
import calcRenderProps from "../../calcRenderProps";
import View from "../../../Strip/view/index";
import { stripsConfig, stripsStylesMap, stripTemplates, stripsLabelMap } from "./config";
import { getThemeOverridesFromComponentData } from '../utils';
import type { BackgroundCalcRenderPropsResult } from "../../flowTypes";

const stripsLabel = 'msg: common.Strips {Strips}',
    { reducer } = componentsRegistry[StripKind],
    ComponentDefaultState = R.omit(['selectedTheme'], getDefaultReducerState(reducer));

const Strip = ({ type, ...props }) => {
    const componentDependencies = props.componentsDependencies[StripKind],
        component = R.mergeDeepRight(ComponentDefaultState, R.mergeDeepRight(stripsConfig, stripsStylesMap[type])),
        themeOverrides = getThemeOverridesFromComponentData(
            component, componentDependencies.themeSettingsData.autoColorMode, componentDependencies.themeColorsData
        ),
        cmpViewProps: BackgroundCalcRenderPropsResult =
            calcRenderProps({ isWorkspace: false, component: R.mergeDeepRight(component, themeOverrides), componentDependencies }),
        payload = {
            style: component.style,
            ...(componentDependencies.themeSettingsData.autoColorMode ? R.omit(['selectedBorderTheme'], themeOverrides) : {}),
        };

    const view = View({
        ...cmpViewProps,
        className: styles.strip
    });
    return (
        <DroppableCmpShortcut
            {...props}
            kind={StripKind}
            className={styles.stripWrapper}
            payload={payload}
            dispatch={props.dispatch}
            isOverlayExtended
        >
            {view}
            <span className={styles.stripLabel}>{props.intl.msgJoint(stripsLabelMap[type])}</span>
        </DroppableCmpShortcut>
    );
};

const stripsComponent = (props) => {
    return <div>
        <div className={InserterStyles.subTitle}>{props.intl.msgJoint(stripsLabel)}</div>
        {stripTemplates.map((type: string) => [
            <VerticalSpacer y={20} key="space" />,
            <Strip key="view" {...props} type={type} />
        ])}
        <VerticalSpacer y={20} />
    </div>;
};

const mapStateToProps = (appState: AppState) => ({
    componentsDependencies: appState.selectedWorkspace.componentsDependencies
});

export const Strips = connect(mapStateToProps)(stripsComponent);
