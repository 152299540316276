/* @flow */

/*::
    type TrackingData = {
        enableGoogleAds: boolean
    };

    type SiteSettings = {
        trackingData: TrackingData
    };
 */

const shouldAddGoogleAdsCode = (trackingScript /*: string */, siteSettings /*: SiteSettings */) /*: boolean */ => {
    return !!(trackingScript && (!siteSettings || !siteSettings.trackingData || siteSettings.trackingData.enableGoogleAds));
};

module.exports = {
    shouldAddGoogleAdsCode
};
