import React from "react";
export const SvgEmailRoundedFillDark = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <rect width={48} height={48} fill="#3c3c3c" rx={6} />
            <g fill="#ffffff">
                <path d="M16.635 15.863a2.272 2.272 0 00-2.272 2.272v10.906a2.272 2.272 0 002.272 2.272h15.45a2.272 2.272 0 002.272-2.272V18.135a2.272 2.272 0 00-2.272-2.272h-15.45zm0-1.363h15.45a3.635 3.635 0 013.636 3.635v10.906a3.635 3.635 0 01-3.636 3.635h-15.45A3.635 3.635 0 0113 29.041V18.135a3.635 3.635 0 013.635-3.635z" />
                <path d="M26.358 24.732l-1.501 1.595a.682.682 0 01-.993 0l-1.501-1.595-4.791 4.791a.682.682 0 11-.964-.964l4.82-4.82-4.835-5.137a.682.682 0 01.993-.934l6.774 7.198 6.775-7.198a.682.682 0 11.992.934l-4.834 5.137 4.82 4.82a.682.682 0 11-.964.964l-4.791-4.79z" />
            </g>
        </g>
    </svg>
);
