import React from 'react';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import * as actionTypes from '../../actionTypes';
import PpOnClickActionComboBox from '../../PpOnClickActionComboBox';
import * as pagesIds from '../pagesIds';
import pagesTitles from '../pagesTitles';
import type { Props } from '../flowTypes';

const
    id = pagesIds.ONCLICK,
    title = pagesTitles[id],
    view = ({ selectedComponent: { onClickAction }, socialAccounts, dispatch }: Props) => {
        const { facebookFeed: { isFbPageMode } } = socialAccounts;

        return (
            <Page>
                <VerticalSpacer />
                <PpOnClickActionComboBox
                    actionType={actionTypes.FACEBOOK_FEED_GALLERY_ON_CLICK_ACTION_CHANGED}
                    value={onClickAction}
                    dispatch={dispatch}
                    isPageMode={isFbPageMode}
                />
            </Page>
        );
    };

export { id, view, title };
