import * as R from 'ramda';
import browser from "bowser";
import makeEpic from '../../epics/makeEpic';
import * as actionTypes from './actionTypes';
import { receiveOnly } from "../../epics/makeCondition";
import { WINDOW_RESIZED } from "../../redux/modules/actionTypes";
import { APP_MOUNT } from "../App/actionTypes";
import openDialog from "../App/actionCreators/openDialog";
import * as dialogIds from './dialogIds';
import closeDialog from "../App/actionCreators/closeDialog";
import { isTouchDevice, isBrowserUnsupported, isBrowserDeprecated } from './constants';
import { DOMAIN_FROM_AUTH_COOKIE_VALUE } from "../../redux/middleware/cookie/actionTypes";
import { Intl } from "../../view/intl/injectIntl";
import { sendShownErrorEventToAecAC } from '../App/actionCreators/sendEventToAecActionCreator';
import { ROOpenedDialogsIdsSelector } from '../DialogManager/epic/selectorActionTypes';
import { isWsbDemo } from "../../debug/isWsbDemo";
import { isMobileDevice } from "../../../demo/modules/login/validators";
import { SKIP_WINDOW_WIDTH_AND_TOUCH_SCREEN_CHECK } from "../../../../src/dashboard/src/components/SideBar/constants";

const
    NoTouchSupportInfo = 'NoTouchSupportInfo',
    NoBrowserSupportInfo = 'NoBrowserSupportInfo',
    defaultState = { doNotShowAgain: false },
    localStorageGetItem = (item, fallback = null) => {
        return window.localStorage && (window.localStorage.getItem(item) || fallback);
    },
    localStorageSetItem = (item, value) => {
        return window.localStorage && window.localStorage.setItem(item, JSON.stringify(value));
    },
    doesUserPreferToViewUnsupportedTouchScreenDialogAgain = () => {
        return localStorageGetItem(NoTouchSupportInfo) !== "true";
    },
    doesUserPreferToViewUpgradeBrowserDialogAgain = () => {
        return localStorageGetItem(NoBrowserSupportInfo) !== "true";
    },
    continueToApplicationStartAliasAC = () => ({ type: actionTypes.UNSUPPORTED_MESSAGES_COMPLETED });

const unsupportedMessageDialogEpic = makeEpic({
    defaultState,
    valueActionType: "UNSUPPORTED_BROWSER_EPIC",
    updaters: [
        {
            conditions: [
                receiveOnly(WINDOW_RESIZED),
                receiveOnly(APP_MOUNT),
                DOMAIN_FROM_AUTH_COOKIE_VALUE
            ],
            reducer: ({ values: [{ width, height }], state }) => {
                const multipleActionsToDispatch: Action[] = [];

                if (!isWsbDemo() || !isMobileDevice()) {
                    const skipWindowWidthAndTouchScreenCheck = window.localStorage.getItem(SKIP_WINDOW_WIDTH_AND_TOUCH_SCREEN_CHECK);

                    if (isBrowserUnsupported) {
                        // @ts-ignore
                        const isIE = !!document.documentMode,
                            cannotUpgradeToIE11 =
                                navigator.appVersion && !navigator.appVersion.match(/(Windows NT 6|Windows NT 10)/);
                        if (isIE && cannotUpgradeToIE11) {
                            multipleActionsToDispatch.push(
                                openDialog(dialogIds.UseAnotherBrowserDialogId),
                                sendShownErrorEventToAecAC("use another browser")
                            );
                        } else if (doesUserPreferToViewUpgradeBrowserDialogAgain()) {
                            multipleActionsToDispatch.push(openDialog(dialogIds.UpgradeBrowserDialogId));
                        }
                    }
                    if (isTouchDevice && doesUserPreferToViewUnsupportedTouchScreenDialogAgain() && !skipWindowWidthAndTouchScreenCheck) {
                        multipleActionsToDispatch.push(
                            openDialog(dialogIds.NoTouchScreenSupportDialogId),
                            { type: actionTypes.TRACK_UNSUPPORTED_TOUCH_SCREEN_DIALOG_SHOW },
                            sendShownErrorEventToAecAC("unsupported touch screen")
                        );
                    }
                    if (isBrowserDeprecated) {
                        multipleActionsToDispatch.push(openDialog(dialogIds.DeprecatedBrowserDialogId));
                    }
                    if ((width < 980 || height < 450) && !skipWindowWidthAndTouchScreenCheck) {
                        multipleActionsToDispatch.push(
                            openDialog(dialogIds.UnSupportedDimensionDialogId),
                            sendShownErrorEventToAecAC("browser window too small")
                        );
                    }
                    if (R.isEmpty(multipleActionsToDispatch)) {
                        multipleActionsToDispatch.push({ type: actionTypes.CONTINUE_TO_APP_START });
                    }

                    if (skipWindowWidthAndTouchScreenCheck) {
                        window.localStorage.removeItem(SKIP_WINDOW_WIDTH_AND_TOUCH_SCREEN_CHECK);
                    }
                }
                return { state, multipleActionsToDispatch };
            }

        },
        {
            conditions: [actionTypes.NO_TOUCH_SCREEN_SUPPORT_TOGGLE_DONT_SHOW_AGAIN],
            reducer: ({ state }) => {
                localStorageSetItem(NoTouchSupportInfo, !state.doNotShowAgain);
                return { state: { ...state, doNotShowAgain: !state.doNotShowAgain } };
            }
        },
        {
            conditions: [actionTypes.HIDE_NO_TOUCH_SCREEN_SUPPORT_DIALOG],
            reducer: () => ({
                state: defaultState,
                multipleActionsToDispatch: [
                    closeDialog(),
                    { type: actionTypes.CONTINUE_TO_APP_START },
                    { type: actionTypes.TRACK_UNSUPPORTED_TOUCH_SCREEN_CONTINUE }
                ]
            }),
        },
        {
            conditions: [actionTypes.HIDE_DEPRECATED_BROWSER_DIALOG],
            reducer: () => ({
                state: defaultState,
                multipleActionsToDispatch: [
                    closeDialog(),
                    { type: actionTypes.CONTINUE_TO_APP_START },
                ]
            }),
        },
        {
            conditions: [actionTypes.HIDE_UNSUPPORTED_DIMENSION_MESSAGE],
            reducer: ({ state }) => ({
                state,
                multipleActionsToDispatch: [
                    closeDialog(),
                    { type: actionTypes.CONTINUE_TO_APP_START },
                ]
            })
        },
        {
            conditions: [ROOpenedDialogsIdsSelector, actionTypes.CONTINUE_TO_APP_START],
            reducer: ({ state, values: [dialogIds] }) => {
                const actionToDispatch = R.isEmpty(dialogIds) ? continueToApplicationStartAliasAC() : undefined;
                return { state, actionToDispatch };
            }
        },
        {
            conditions: [actionTypes.UPGRADE_BROWSER],
            reducer: ({ state }) => {
                let href = '';

                const intl = new Intl();
                const { chrome, chromium, firefox, msie, safari, opera } = browser;

                if (msie) {
                    href = intl.msg("browser.links.edge", "https://www.microsoft.com/en-us/windows/microsoft-edge");
                }
                if (chrome || chromium) {
                    href = intl.msg("browser.links.chrome", "https://www.google.com/chrome/");
                }
                if (firefox) {
                    href = intl.msg("browser.links.firefox", "https://www.mozilla.org/en-US/firefox/");
                }
                if (opera) {
                    href = intl.msg("browser.links.opera", "https://www.opera.com/");
                }
                if (safari) {
                    href = intl.msg("browser.links.safari", "https://support.apple.com/en-us/HT204416");
                }

                // when not matching, leaving behavior as in old WE. Unreached scenario i think :D
                document.location.href = href;

                return { state };
            }
        }
    ]
});

export {
    unsupportedMessageDialogEpic as default,
    unsupportedMessageDialogEpic
};
