import type { Groups, Settings } from "./flowTypes";
import { getGroupTypeById, isSmallText } from "./util";
import { GroupTypes } from "./constants";
import type { ComponentsMap } from "../../redux/modules/children/workspace/flowTypes";

export default (groups: Groups, settings: Settings, componentsMap: ComponentsMap) => {
    let result = {};
    Object.keys(groups).forEach(id => {
        if (settings[id]) {
            result[id] = settings[id];
        }
        if (getGroupTypeById(id) === GroupTypes.textAndText) {
            groups[id].forEach(itemId => {
                if (componentsMap[itemId] && isSmallText(componentsMap[itemId])) {
                    const style = { [itemId]: { minWidth: componentsMap[itemId].width + 'px' } };
                    result[id] = result[id] ? { ...result[id], style } : { style };
                }
            });
        }
    });
    return result;
};
