/* eslint-disable max-len */

import React from "react";
import { Msg } from "../../../view/intl/index";
import StandardDialog from "../../../view/common/dialogs/StandardDialog/index";
import { StripTypes } from "../../../view/common/dialogs/baseDialog/index";
import { errorDialogStandardWidth, errorDialogStandardHeight } from "../../../view/common/dialogs/constants";
import getCenteredDialogConfig from "../../DialogManager/getCenteredDialogConfig";

const
    UserDataMigrationFailedDialog = () => (
        <StandardDialog
            title="msg: userDataMigration.prolonged.title {Upgrading to our new Website Builder}"
            mctaText="msg: common.ok {OK}"
            sctaText="msg: common.ok {OK}"
            stripType={StripTypes.ADVISORY}
        >
            <Msg k="userDataMigration.prolonged.message">
                We\'re sorry, but this takes longer than expected. In the meantime, we\'ll continue to move your site. Please try again in 30 minutes.
            </Msg>
        </StandardDialog>
    );

export const UserDataMigrationProlongedDialogId = 'UserDataMigrationProlongedDialog';
export default getCenteredDialogConfig(errorDialogStandardWidth, errorDialogStandardHeight, UserDataMigrationFailedDialog);
