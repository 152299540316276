import CALL_API from "../../../redux/middleware/api/CALL_API";
import {
    OWNER_INFO_FAILURE, OWNER_INFO_REQUEST, OWNER_INFO_SUCCESS
} from "../actionTypes";
import type { ApiAction } from "../../../redux/middleware/api/flowTypes";

export default (): ApiAction => ({
    [CALL_API]: {
        types: [
            OWNER_INFO_REQUEST,
            OWNER_INFO_SUCCESS,
            OWNER_INFO_FAILURE
        ],
        endpoint: 'getDomainOwnerData'
    }
});
