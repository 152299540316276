import React from 'react';
import * as pageIds from '../pageIds';
import pagesTitles from '../pageTitles';
import Page from "../../../../../PropertiesPanel/view/PropertiesPage";

const
    id = pageIds.MAIN,
    title = pagesTitles[id],
    view = () => (
        <Page>
            <div />
        </Page>
    );

export { id, title, view };
