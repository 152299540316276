/* eslint-disable max-len */
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { WizardFooter } from "../../WizardFooter";
import { StepDescription } from "../Common/StepDescription";
import { DynamicTemplateContext } from "../../Context";
import { DYNAMIC_TEMPLATE_UPDATE_CONTACT_INFO, DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA } from "../../../Epic/actionTypes";
import { validateEmailField, validatePhoneField } from "../validations";
import { EmailField } from "./EmailField";
import { PhoneField } from "./PhoneField";
import { AddressField } from "./AddressField";
import { DEFAULT_COMPLETE_ADDRESS, DEFAULT_ADDRESS_LOCATION } from "../../../constants";

import stepStyles from "../steps.css";
import styles from "./styles.css";

const getDesc = (type) => {
    let description;
    switch (type) {
        case 'event':
        case 'project':
            description = "msg: onboarding.dynamic.step4.contactInfo.visitors.description {These details will be added to your website to ensure that visitors know how to reach you.}";
            break;

        default:
            description = "msg: onboarding.dynamic.step4.contactInfo.description {These details will be added to your website to ensure that customers know how to reach you.}";
            break;
    }
    return description;
};

export const ContactInfoStep = () => {
    const dispatch = useDispatch();
    const { wizardState: { goNext: _goNext }, concept: { type }, contactInfo } = useContext(DynamicTemplateContext);
    const title = "msg: onboarding.dynamic.step4.contactInfo.title {Add your contact information}",
        description = getDesc(type);
    const [email, setEmail] = useState({ value: contactInfo.email, showError: false });
    const [phone, setPhone] = useState({ value: contactInfo.phone, showError: false });
    const [address, setAddress] = useState({ value: contactInfo.address, showError: false });
    const [completeAddress, setCompleteAddress] = useState(contactInfo.completeAddress);
    const isValidInputFields = validateEmailField(email.value) && validatePhoneField(phone.value);
    const isAllFieldsEmpty = !email.value && !phone.value && !address.value;

    const goNext = () => {
        if (isValidInputFields) {
            dispatch({
                type: DYNAMIC_TEMPLATE_UPDATE_CONTACT_INFO,
                payload: {
                    email: email.value || "",
                    phone: phone.value || "",
                    address: address.value || "",
                    completeAddress
                }
            });
            _goNext();
        }
    };

    const skipStep = () => {
        dispatch({
            type: DYNAMIC_TEMPLATE_UPDATE_CONTACT_INFO,
            payload: {
                email: "",
                phone: "",
                address: "",
                completeAddress: { ...DEFAULT_COMPLETE_ADDRESS, ...DEFAULT_ADDRESS_LOCATION }
            }
        });
        dispatch({
            type: DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA,
            payload: {
                path: "contactInfo",
                value: {
                    email: '',
                    phone: '',
                    address: '',
                    focusedField: null
                }
            }
        });
        _goNext();
    };

    const emailFieldProps = {
        email,
        setEmail
    };

    const phoneFieldProps = {
        phone,
        setPhone
    };

    const addressFieldProps = {
        address,
        setAddress,
        setCompleteAddress
    };

    return (
        <React.Fragment>
            <div className={stepStyles.stepContainer} data-testid="dynamic-onboarding-contact-info">
                <StepDescription title={title} description={description} />
                <div className={styles.inputFieldsContainer}>
                    <EmailField {...emailFieldProps} />
                    <PhoneField {...phoneFieldProps} />
                    <AddressField {...addressFieldProps} />
                </div>
            </div>
            <WizardFooter disableButton={isAllFieldsEmpty || !isValidInputFields} goNext={goNext} skipStep={skipStep} />
        </React.Fragment>
    );
};
