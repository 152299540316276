import React from 'react';
import cx from "classnames";

import { useIntl } from '../../../../../../../view/intl/index';

import { ProjectTitleField } from "./ProjectTitleField";
import { AboutField } from './AboutField';
import { TargetAudienceField } from './TargetAudienceField';
import { LanguageField } from './LanguageField';

import styles from '../styles.css';
import ICONS from "../../../../../../../view/Icons/index";

export const GeneralSettings = ({ aiData, isFirstTimeUser, onChangeAction, showGeneralPreferences, onGeneralPreferencesChangeAction }) => {
    const intl = useIntl();
    const { gmbKey, websiteTitle, targetAudience, language } = aiData;

    return (
        <div className={cx(styles.settingsContainer, { [styles.collapseContainer]: !showGeneralPreferences })}>
            <div className={styles.settingsBody}>
                <div
                    className={styles.headerContainer}
                    onClick={() => !isFirstTimeUser && onGeneralPreferencesChangeAction(!showGeneralPreferences)}
                >
                    <span className={styles.headerTitle}>
                        {intl.msgJoint('msg: component.text.createAIText.dialog.userInputPanel.generalPreferences {General preferences}')}
                    </span>
                    {
                        !isFirstTimeUser && (showGeneralPreferences ?
                            <ICONS.EXPAND_LESS /> :
                            <ICONS.EXPAND_MORE />)
                    }
                </div>
                {showGeneralPreferences &&
                    <div className={styles.fieldsContainer}>
                        <AboutField
                            gmbKey={gmbKey}
                            onChangeAction={onChangeAction}
                        />
                        <ProjectTitleField
                            websiteTitle={websiteTitle}
                            onChangeAction={onChangeAction}
                        />
                        <TargetAudienceField
                            targetAudience={targetAudience}
                            onChangeAction={onChangeAction}
                        />
                        <LanguageField
                            aiTextLanguage={language}
                            onChangeAction={onChangeAction}
                        />
                    </div>}
            </div>
            <div className={styles.settingsDivider} />
        </div>
    );
};
