/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */

import React from 'react';
import cx from 'classnames';
import { Intl, Msg, injectIntl } from '../../../../view/intl/index';
import { StripTypes } from "../../../../view/common/dialogs/baseDialog/index";
import StandardDialog from "../../../../view/common/dialogs/StandardDialog/index";
import VerticalSpacer from '../../../../view/common/VerticalSpacer';
import getCenteredDialog from "../../../DialogManager/getCenteredDialogConfig";
import { tryUpgradeDialogCompleted, tryUpgradeDialogCancelled } from "../../../ComponentTierManager/actionCreators";
import subscriptionDataEpic from '../../../App/epics/subscriptionData/index';
import { getPremiumPackageNameFromMetadata, getComponentTierDataFromComponentKind } from '../../../ComponentTierManager/utils';
import { PremiumIcon } from '../../../../view/common/PremiumIcon/index';
import { FindMoreAboutUpgrade } from '../../../../view/common/LearnMore';
import OpenUpgradePlanDialog from "../../../TopBar/view/Upgrade/UpgradePlansDialog/OpenUpgradePlanDialog";
import VideoFileKind from '../../Video/VideoFile/kind';
import * as styles from './dialogs.css';
import { isDemoSubscriptionType } from "../../../App/epics/subscriptionData/isDemoSubscriptionType";
import { type SubscriptionData } from "../../../../redux/modules/flowTypes";
import { upgradeDialogOpenedAction } from '../../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions';
import { StandardTierComponents, PremiumTierComponents, EcommerceTierComponents } from '../../../../view/oneweb/registry/ComponentTierTypes';
import { UpgradePlansDialogId } from '../../../TopBar/view/dialogIds';
import type { AnyComponentKind } from "../../../../redux/modules/children/workspace/flowTypes";

type WidgetsPremiumDialogProps = {
    componentType: AnyComponentKind;
    dispatch: Dispatch;
    intl: Intl;
    subscriptionData: SubscriptionData;
};

type MCTAProps = {
    mctaText?: string,
    mctaHandler?: (...e: any) => void
}

const VideoMessage = () => (
    <Msg k="video.premiumFeature.dialog.message">
        Videos keep people on your website longer and increase engagement with your content. You need to upgrade to add this in your site.
    </Msg>
);

const WidgetMessage = () => (
    <Msg k="widgets.premiumFeature.dialog.message">
        Widgets are a great way to add extra features and functionality to your site. You can try it out now but you will have to upgrade before publishing your site with it.
    </Msg>
);

const WidgetsPremiumDialog = (props: WidgetsPremiumDialogProps) => {
    const
        { componentType, subscriptionData, dispatch, intl } = props,
        { metadata: subscriptionMetadata } = subscriptionData,
        premiumPackageNameFromMetadata = getPremiumPackageNameFromMetadata(subscriptionMetadata);

    const mctaProps: MCTAProps = {};

    if (!(componentType === VideoFileKind && isDemoSubscriptionType(subscriptionData))) {
        mctaProps.mctaText = "msg: common.tryIt {Try it!}";
        mctaProps.mctaHandler = e => {
            e.stopPropagation();
            const upgradeDialogMsg = getComponentTierDataFromComponentKind(componentType).upgradeDialogMsg({
                dispatch,
                subscriptionData
            });
            dispatch(tryUpgradeDialogCompleted(componentType, upgradeDialogMsg));
        };
    }
    const getComponentPackageName = (componentType) => {
        if (StandardTierComponents[componentType]) {
            return intl.msgJoint('msg: common.starter {Starter}');
        }
        if (PremiumTierComponents[componentType]) {
            return intl.msgJoint('msg: common.premium {Premium}');
        }
        if (EcommerceTierComponents[componentType]) {
            return intl.msgJoint('msg: common.business_ecommerce {Business + Ecommerce}');
        }
        return "";
    };

    return (
        <StandardDialog
            sctaHandler={null}
            onClose={() => dispatch(tryUpgradeDialogCancelled(componentType))}
            stripType={StripTypes.NONE}
            footerClassName={styles.footerClass}
            bodyClassName={styles.dialogBodyWidePadding}
            primaryBtnClass={styles.tryNow}
            titleBoxClassName={styles.header50px}
            footerV2
            {...mctaProps}
        >
            <PremiumIcon large iconCustomClass={styles.premiumIconLarge} />
            <VerticalSpacer y={30} />
            <div className={cx(styles.common, styles.title)}>
                {
                    intl.msgJoint(
                        [
                            "msg: common.premiumFeature {{packageName} Feature}",
                            { packageName: premiumPackageNameFromMetadata || getComponentPackageName(componentType) }
                        ]
                    )
                }
            </div>
            <VerticalSpacer y={20} />
            <div className={cx(styles.common, styles.message)}>
                {componentType === VideoFileKind ? <VideoMessage /> : <WidgetMessage />}
            </div>
            <VerticalSpacer y={20} />
            {isDemoSubscriptionType(subscriptionData) || (
                <FindMoreAboutUpgrade
                    onClick={() => {
                        dispatch(OpenUpgradePlanDialog(subscriptionData, 'Widgets:TryIt'));
                        dispatch(upgradeDialogOpenedAction({
                            feature: componentType,
                            dialogId: UpgradePlansDialogId
                        }));
                    }}
                />
            )}
            <VerticalSpacer y={28} />
        </StandardDialog>
    );
};

export const WidgetsPremiumComponentDialog = {
    ...getCenteredDialog({ width: 576, height: 495, component: injectIntl(WidgetsPremiumDialog) }),
    dependsOn: {
        subscriptionData: subscriptionDataEpic.reducer,
    }
};
