import * as R from 'ramda';
import {
    DataPageSet,
    DataPageTemplateSet,
    DataPageStylesheet,
    DataPage,
} from "../../../../dal/model";
import { adjustPageItemsToTemplate } from "./adjustPageItemsToTemplate";
import { ItemsFrame } from "./ItemsFrame";
import { OldComponentTypes } from "../../../../dal/pageMapAdapter/componentTypesMap";
import * as selectors from '../../Workspace/epics/stylesheets/selectors';
import styleButton from '../../oneweb/Button/globalStyle/kind';
import { getGSNormalTextColor, getDefaultButtonBGColor, getGlobalStyleButtonId } from '../../oneweb/WebShop/utils';
import { to as gsButtonToMapper } from '../../../../dal/pageMapAdapter/mappers/Globalstyles/StyleButton';

const findMatchingTemplateGlobalStyle = (
    templateStylesheet: DataPageStylesheet,
    globalStyle: Record<string, any>
): Record<string, any> | null => {
    const { type, ref, globalName: name } = globalStyle;

    if (!type && !ref && !name) throw new Error(`Unknown component global style with no type, ref or name`);

    if (!ref && !name) {
        return templateStylesheet.getFirstGlobalStyleByType(type);
    }

    if (ref) {
        const style = templateStylesheet.getGlobalStyleByRef(ref, type);
        if (style) return style;
    }

    const symbol = ref || name;
    if (symbol) {
        const style = templateStylesheet.getGlobalStyleBySymbol(symbol, type);
        if (style) return style;
    }

    return null;
};

/**
 * TODO: deep copies are expensive; do we really need them ?
 */
export default (
    layoutSet: DataPageSet,
    templateSet: DataPageTemplateSet,
    newPageId: string,
    headerPageCmps: Array<Record<string, any>> = [],
    footerPageCmps: Array<Record<string, any>> = []
): DataPageSet => {
    const newLayoutSet = new DataPageSet(R.clone(layoutSet));

    // set template data
    newLayoutSet.page.templateId = templateSet.getId();
    newLayoutSet.template = R.clone(templateSet.template);
    newLayoutSet.stylesheet = R.clone(templateSet.stylesheet);

    // copy styles and "reference" global styles
    const globalStyles = newLayoutSet.getComponentsGlobalStyles();
    globalStyles.forEach(globalStyle => {
        // console.log('fromId:', id); // DEBUG
        const templateGlobalStyle = findMatchingTemplateGlobalStyle(templateSet.stylesheet, globalStyle);
        if (templateGlobalStyle && !templateGlobalStyle.id !== globalStyle.globalId) {
            // console.log('toId:', templateGlobalStyle.id); // DEBUG
            globalStyle.globalId = templateGlobalStyle.id; // eslint-disable-line
        }
    });

    // define template height
    if (newLayoutSet.template.items.length) {
        const
            tiframe = new ItemsFrame(newLayoutSet.template.items),
            templateHeight = Math.max(tiframe.height(), 700);
        newLayoutSet.template.height = templateHeight;
    }

    // Setting fontColor and focusColor for Webshop using Global Style colors
    // focusColor will be set if default button bg color opacity is 1
    // fontColor will be set with the normal text color
    const updateWebShopDataWithGStyleData = (item) => {
        if (item.type === OldComponentTypes.WEBSHOP) {
            item.style.font = "";            // eslint-disable-line
            const defaultButtonStyle = selectors.getFirstStylesheetByType(styleButton)(newLayoutSet.stylesheet),

                // Global style Button mapper is applied to make default button bg color path consistent
                // with application and can be used in common function in Webshop/utils.js
                defaultButtonStylePostMapper = gsButtonToMapper(defaultButtonStyle),
                buttonIdFromStyleSheet = getGlobalStyleButtonId(defaultButtonStylePostMapper);

            // Focuscolor is set using utils.js function. Same is used in "adjustComponentOnAdd" to set template
            // default button bg color for Webshop
            item.style.focusColor = getDefaultButtonBGColor(defaultButtonStylePostMapper) || item.style.focusColor;  // eslint-disable-line
            item.style.fontColor = getGSNormalTextColor(newLayoutSet.stylesheet); // eslint-disable-line
            item.style.buttonId = buttonIdFromStyleSheet; // eslint-disable-line
            item.buttonId = buttonIdFromStyleSheet; // eslint-disable-line
        }
        return item;
    };

    const pageItems = newLayoutSet.page.items.map(updateWebShopDataWithGStyleData);

    if (!layoutSet.template.items) {
        layoutSet.template.items = []; // eslint-disable-line no-param-reassign
    }

    // adjust page items to template
    const { page: newPage, template: newTemplate } = adjustPageItemsToTemplate({
        from: {
            page: new DataPage({ ...newLayoutSet.page, items: pageItems, id: newPageId }),
            template: layoutSet.template,
        },
        toTemplate: newLayoutSet.template,
    }, headerPageCmps, footerPageCmps);
    newLayoutSet.page = newPage;
    newLayoutSet.template = newTemplate;

    return newLayoutSet;
};
