import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(
    (theme) => ({
        actionButton: {
            position: "relative",
            overflow: "hidden",
            height: "46px",
            padding: "0 30px"
        },
        publishProgress: {
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            background: theme.palette.info.light
        },
        publishText: {
            zIndex: 1
        }
    }),
    { name: "PublishButton" }
);
