import React from 'react';
import { debounce } from 'lodash';
import cx from 'classnames';
import { injectIntl, Intl } from '../../../../view/intl/index';
import styles from './styles.css';

type Props = {
    onSearch: any,
    intl: Intl
}

export const SearchBox = injectIntl(({ intl, onSearch }: Props) => {
    const debouncedSearch = debounce(onSearch, 100);
    const [value, setValue] = React.useState('');

    return (
        <div className={styles.searchContainer}>
            <div className={cx(styles.searchBox)}>
                <div className={styles.searchBoxInputContainer}>
                    <input
                        type="text"
                        value={value}
                        placeholder={
                            intl.msgJoint("msg: component.ProductWidget.searchBox.placeholder {Search by category or product name}")
                        }
                        className={styles.searchBoxInput}
                        onChange={({ target }) => {
                            setValue(target.value);
                            debouncedSearch(target.value);
                        }}
                    />
                </div>
                <span className={styles.searchBoxIcon} onClick={() => { }} />
            </div>
        </div>
    );
});
