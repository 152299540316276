/* eslint-disable max-len */

import * as colorMapper from "../../../../dal/pageMapAdapter/mappers/Base/color";
import { Color } from "../../../mappers/flowTypes";
import { setColorOpacity } from "./utils/setColorOpacity";

export const
    HoverBoxAnimationTypes = {
        FADE: 'fade',
        INSTANT: 'instant',
        DYNAMIC: 'dynamic'
    },
    ImageOpacityLabel = 'msg: common.imageOpacity {Image opacity}',
    VideoOpacityLabel = 'msg: common.videoOpacity {Video opacity}',
    TooltipTextForBgColorPicker = 'msg: component.box.themeColorTooltip {Box styles direct the colours used for the background and components of a box. The styles are based on the colour theme of your website.}',
    BACKGROUND_CMP_DEFAULT_COLOR_VALUE = colorMapper.toHsl('#eeeeee') as Color,
    BACKGROUND_CMP_DEFAULT_OPACITY = 100,
    BACKGROUND_CMP_DEFAULT_COLOR = setColorOpacity(BACKGROUND_CMP_DEFAULT_COLOR_VALUE, BACKGROUND_CMP_DEFAULT_OPACITY),
    BACKGROUND_CMP_TRANSPARENT_DEFAULT_COLOR =
        setColorOpacity(BACKGROUND_CMP_DEFAULT_COLOR_VALUE, 0),
    BackgroundCmpMinDimensions = {
        width: 20,
        height: 20,
    };

