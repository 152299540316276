
import * as PagesIds from './ids';
import { SCROLL_EFFECTS, scrollEffectsTitle } from "../../../commonView/ScrollEffects/constants";

export default {
    [PagesIds.MAIN]: "msg: template.settingsTitle {Template settings}",
    [PagesIds.BACKGROUND]: "msg: common.background {Background}",
    [SCROLL_EFFECTS]: scrollEffectsTitle,
    [PagesIds.CATEGORIES]: "msg: template.categoriesTitle {Template Categories}",
};
