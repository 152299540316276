import { makeInputDefaultState } from "../../../inputControls/input/reducerFactory/makeInputDefaultState";
import { Lit } from "../../../../lit";
import { AllCategoryId } from "../components/ImagesCategories";

export const ShutterstockInitialState = {
    [Lit.initialized]: false,
    [Lit.isTypeImages]: false,
    [Lit.isTypeIllustrations]: false,
    [Lit.isPortraitMode]: false,
    [Lit.isLandscapeMode]: false,
    [Lit.filters]: {
        [Lit.search]: makeInputDefaultState({
            value: '',
        }),
        [Lit.category]: AllCategoryId,
        [Lit.page]: 1,
        [Lit.perPage]: 15,
    },
    [Lit.downloads]: [],
    [Lit.downloadsLoading]: false,
    [Lit.partialDownloads]: [],
    [Lit.categories]: [],
    [Lit.categoriesLoading]: false,
    [Lit.images]: [],
    [Lit.imagesLoading]: false,
    [Lit.imagesPageLoading]: false,
    [Lit.showPremiumDownloadsMsg]: true,
    [Lit.selectedImages]: [],
    [Lit.gridScrollTop]: 0,
    [Lit.isUploading]: false,
    [Lit.emptySearchResults]: false,
    [Lit.mctaDisabled]: false,
    [Lit.imagePrice]: null,
    [Lit.skipComponentActionPostDownload]: false
};
