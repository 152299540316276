import * as R from "ramda";
import styles from "./ExpandedPanelMenu.css";
import { getAllStylesheets, getStylesByType } from "../../../Workspace/epics/stylesheets/selectors";
import MenuGlobalStyleKind from "../globalStyle/kind";
import { makeMenuComponentProps, makePageState } from "../../../Globalstyles/Menu/menuPreview";
import * as path from "../../../../mappers/path";
import layouts from "../view/layouts/index";
import * as LAYOUT_TYPE from "../constants/layoutType";
import type { StylesheetsIdToNameMap, Stylesheets } from "../../../Workspace/epics/stylesheets/flowTypes";
import { Intl } from "../../../../view/intl/index";
import { BACKGROUND_THEME_WHITE } from "../../../ThemeGlobalData/constants";
import menuStyleThemekind from "../globalStyle/themeColorKind";

export type AlignmentToString = {
    vertical: string;
    horizontal: string;
};
export const HorizontalMenuWidth = 550,
    MenuAlignmentConfigMap: AlignmentToString = {
        vertical: LAYOUT_TYPE.VERTICAL_SINGLE_LEVEL,
        horizontal: LAYOUT_TYPE.HORIZONTAL_DROPDOWN
    },
    MenuClassMap: AlignmentToString = {
        vertical: styles.vertical,
        horizontal: styles.horizontal
    },
    MenuLayoutsMap = {
        vertical: layouts[LAYOUT_TYPE.VERTICAL_SINGLE_LEVEL],
        horizontal: layouts[LAYOUT_TYPE.HORIZONTAL_DROPDOWN]
    };
type MenuComponentConfig = {
    horizontalAlign: string;
    layoutType: any;
    type: string;
    autoColorMode: boolean;
};
export const makeMenuPreviewProps = (componentConfig: MenuComponentConfig, intl: Intl) => {
        const commonPages = [
            { ...makePageState("Home", intl.msgJoint("msg: inserter.menuPreview.home {Home}"), [], true) },
            { ...makePageState("About", intl.msgJoint("msg: inserter.menuPreview.about {About}"), []) }
        ];
        let pages;

        if (componentConfig.autoColorMode && componentConfig.type === "horizontal") {
            pages = [...commonPages, { ...makePageState("More", intl.msgJoint("msg: common.More {More}"), [], false, true) }];
        } else {
            pages = [
                ...commonPages,
                { ...makePageState("Shop", intl.msgJoint("msg: inserter.menuPreview.shop {Shop}"), []) },
                { ...makePageState("Contact", intl.msgJoint("msg: inserter.menuPreview.contact {Contact}"), []) }
            ];
        }

        return { ...makeMenuComponentProps(componentConfig), pages };
    },
    makeMenuProps = (
        currentStylesheetId: string,
        stylesheetsIdToNameMap: StylesheetsIdToNameMap,
        config: MenuComponentConfig,
        intl: Intl
    ) =>
        R.pipe(
            makeMenuPreviewProps,
            R.assocPath(["component", ...path.styleGlobalId], currentStylesheetId),
            R.assocPath(["stylesheetsIdToNameMap"], stylesheetsIdToNameMap),
            R.assocPath(["selectedParentTheme"], BACKGROUND_THEME_WHITE)
        )(config, intl),
    getAllMenuStyles = (styleSheets: Stylesheets, autoColorMode?: boolean) => {
        return R.pipe(getAllStylesheets, getStylesByType(autoColorMode ? menuStyleThemekind : MenuGlobalStyleKind))(styleSheets);
    };
