import React, { useEffect, useRef } from 'react';
import cx from "classnames";

import { VALIDATION_FIELDS } from "../constants";
import { ValidatedInput as ValidatedInputField } from '../../../../../../../view/common/Input/ValidatedInputField';

import styles from "../styles.css";

export const ValidateInput = ({
    fieldKey,
    fieldLabel,
    placeholder,
    fieldValue,
    errorFields,
    onChange,
    isInvalid,
    validationMsg,
    dataTestId
}) => {
    const ref = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const scrollIntoView = () => {
            const isFirstErrorField = errorFields && errorFields.indexOf(fieldKey) === 0;
            return isFirstErrorField && ref?.current?.scrollIntoView({ behavior: 'smooth' });
        };

        scrollIntoView();
    }, [errorFields, fieldKey]);

    const { max: maxLength } = VALIDATION_FIELDS[fieldKey];
    const countAboveMaxLength = true;

    return (
        <div className={styles.fieldWrapper} ref={ref} data-testid={dataTestId}>
            <div className={cx(styles.fieldLabel, isInvalid && styles.errorLabel)}>
                {fieldLabel}
            </div>
            <div>
                { /* @ts-ignore */ }
                <ValidatedInputField
                    placeholder={placeholder}
                    value={fieldValue}
                    onChange={value => {
                        const errorflag = value?.trim().length > maxLength;
                        onChange(fieldKey, value, errorflag);
                    }}
                    isInvalid={isInvalid}
                    validationMsg={validationMsg}
                    counter={{ maxLength }}
                    countBeyondMax={countAboveMaxLength}
                    className={styles.inputField}
                    invalidInputClass={styles.invalidInputError}
                />
            </div>
        </div>
    );
};
