import React from "react";
import Button from "@mui/material/Button";
import cx from "classnames";
import styles from "./TrialSocialSignup.css";
import { injectIntl } from "../../../../src/view/intl/index";
import type { TrialSocialSignupProps } from "../types";
import VerticalSpacer from "../../../../src/view/common/VerticalSpacer";
import { SOCIAL_SIGNUP_GOOGLE } from "../../../../../server/shared/trial/constants";
import { LOCAL_STORAGE_LANDING_PAGE_SOCIAL_SIGNUP } from "../constants";
import { demoLoginAction } from "../signIn/actions";
import { makeTrialRedirectUrl } from "../../../../../server/shared/trial/utils/makeTrialRedirectUrl";

export const TrialSocialSignup = injectIntl(
    ({
        dispatch,
        intl,
        appConfig,
        mailCampaignAgreed = "true",
        isSignIn = false,
        isTrialLandingPage = false,
    }: TrialSocialSignupProps) => {
        const makeSocialSignInURL = (identityProvider: string) => {
            const trialCognitoConfig = appConfig.oneWeb.trial.awsConfig.cognito;
            // eslint-disable-next-line max-len
            return `https://${trialCognitoConfig.appDomain}/oauth2/authorize?identity_provider=${identityProvider}&response_type=CODE&client_id=${trialCognitoConfig.appClientId}&redirect_uri=https://${window.location.hostname}/trial/social_sso&state=${mailCampaignAgreed}`;
        };

        // const isFacebookDisabled = disableFBTrialSignUp(appConfig);
        const initiateSocialSignup = (identityProvider: string) => {
            const socialSSOUrl = makeSocialSignInURL(identityProvider);
            const popupWindow = window.open(
                socialSSOUrl,
                `Connect to ${identityProvider}`,
            );
            const checkInterval = setInterval(function () {
                if (popupWindow && popupWindow.closed) {
                    clearInterval(checkInterval);

                    if (dispatch) {
                        dispatch(demoLoginAction());
                    } else {
                        window.location.href = makeTrialRedirectUrl(
                            window.location.href,
                        );
                    }
                }
            }, 500);
        };

        if (!isSignIn && isTrialLandingPage) {
            window.localStorage.setItem(
                LOCAL_STORAGE_LANDING_PAGE_SOCIAL_SIGNUP,
                "true",
            );
        }

        return (
            <React.Fragment>
                <div className={cx(styles.textBarContainer)}>
                    <div className={cx(styles.borderLine)} />
                    <span className={cx(styles.signupTextMessage)}>
                        {isSignIn
                            ? intl.msgJoint(
                                "msg: demo.socialSignup.signInMessage {or sign in with}",
                            )
                            : intl.msgJoint(
                                "msg: demo.socialSignup.signUpMessage {or sign up with}",
                            )}
                    </span>
                    <div className={cx(styles.borderLine)} />
                </div>

                <VerticalSpacer y={20} />

                <div className={styles.signupButtonsContainer}>
                    <Button
                        className={cx(
                            styles.socialSignupButtonContainer,
                            styles.googleButton,
                        )}
                        onClick={() => {
                            initiateSocialSignup(SOCIAL_SIGNUP_GOOGLE);
                        }}
                    >
                        <span className={styles.googleLogo} />
                        <span className={cx(styles.signupText)}>
                            {intl.msgJoint(
                                "msg: demo.socialSignup.google {Google}",
                            )}
                        </span>
                    </Button>
                </div>
            </React.Fragment>
        );
    },
);
