import { makeEpic } from "../../../epics/makeEpic";
import { SAVE_SITE_SETTINGS } from "../../App/epics/siteSettings/actionTypes";
import { OPEN_AI_TERMS_UPDATE } from "./actionTypes";

export const termsAndConditionsGlobalDataVAT = "TERMS_AND_CONDITIONS_GLOBAL_DATA_EPIC";

export const termsAndConditionsEpic = makeEpic({
    defaultState: {
        openAI: false
    },
    valueActionType: termsAndConditionsGlobalDataVAT,
    saveIntoSiteSettings: { key: 'termsAndConditions' },
    undo: false,
    updaters: [
        {
            conditions: [OPEN_AI_TERMS_UPDATE],
            reducer: ({ state, values: [payload] }) => ({
                state: {
                    ...state,
                    openAI: payload
                },
                actionToDispatch: { type: SAVE_SITE_SETTINGS },
                updateReason: OPEN_AI_TERMS_UPDATE
            })
        }
    ]
});
