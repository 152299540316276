/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import cx from "classnames";
import { Box, Flex } from "../../../view/reflexbox/index";
import * as selectors from "../../Workspace/epics/stylesheets/selectors";
import * as path from "../../../mappers/path";
import { injectIntl, Msg } from '../../../view/intl/index';
import Scrollbar from '../../../view/common/Scrollbar/index';
import HorizontalSpacer from "../../../view/common/HorizontalSpacer";
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import BaseComponentPreview from "../../../view/oneweb/BaseComponentPreview";
import { button as BorderWidthButton } from "../../presentational/BorderWidth/index";
import { button as BorderCornerButton } from "../../presentational/BorderCorner/index";
import TextFormatButtonGroup from "../../presentational/IconButtonsGroup/TextFormat";
import { button as BorderStyleButton } from "../../presentational/BorderStyle/index";
import { button as BackgroundButton } from '../../presentational/Background/index';
import ColorProperty from "../../presentational/ColorProperty/index";
import GrayBgContainer from "../../presentational/GrayBgContainer";
import FontSizeSelector from "../../presentational/FontSize/index";
import FontFamilySelector from "../../presentational/FontFamily/index";

import { buttonViewFactory } from "../../oneweb/Button/view/index";
import optionMapper from '../../oneweb/Button/globalStyle/options';
import ButtonGlobalStyleKind from '../../oneweb/Button/globalStyle/kind';
import * as actionTypes from "./actionTypes";
import {
    buttonTabLabel,
    backgroundDialogConfigurationDialogId,
    borderStyleDialogConfigurationDialogId
} from './constants';
import TopBar from '../TopBar';
import type { ButtonGlobalstyleDialogProps } from '../flowTypes';
import buttonStyles from '../../oneweb/Button/view/Button.css';
import styles from "../GlobalstylesDialog.css";
import {
    BACKGROUND_THEME_WHITE,
    BUTTON_THEME_PRIMARY,
    BUTTON_THEME_ALTERNATE,
    BUTTON_THEME_SECONDARY,
    THEME_BUTTON_CLASS,
} from "../../ThemeGlobalData/constants";

export const
    buttonPreviewText = <Msg k="common.buttons">Button</Msg>,
    Tab = injectIntl(({ intl }) => (<div id="button-globalstyles-tab">{intl.msgJoint(buttonTabLabel)}</div>)),
    TabPanel = injectIntl((props: ButtonGlobalstyleDialogProps) => {
        const
            {
                scrollHeight,
                previewScrollHeight,
                stylesheets,
                stylesheetsIdToNameMap,
                siteFonts,
                intl,
                dispatch,
                themeSettingsData,
            } = props,
            { autoColorMode } = themeSettingsData,
            currentStylesheet = autoColorMode ? selectors.getFirstButtonStyle(stylesheets) : props.currentStylesheet,
            currentStylesheetId = selectors.getStyleId(currentStylesheet),
            getThemeButtonPreview = (
                buttonThemeSelected,
                buttonTextNode
            ) => {
                return <Flex className={styles.preview} align="center" justify="center">
                    <BaseComponentPreview
                        className={cx(styles.buttonPreview, buttonThemeSelected, BACKGROUND_THEME_WHITE, THEME_BUTTON_CLASS)}
                        globalStyleIds={[stylesheetsIdToNameMap[currentStylesheetId]]}
                    >
                        <ButtonView
                            text={buttonTextNode}
                            styles={buttonStyles}
                        />
                    </BaseComponentPreview>
                </Flex>;
            },

            bold = selectors.getFontBold(path.inactive)(currentStylesheet),
            italic = selectors.getFontItalic(path.inactive)(currentStylesheet),
            fontSize = selectors.getFontSize(path.inactive)(currentStylesheet),
            fontFamily = selectors.getFontFamily(path.inactive)(currentStylesheet),

            borderWidth = selectors.getBorderWidth(path.inactive)(currentStylesheet),
            borderRadius = selectors.getBorderRadius(path.inactive)(currentStylesheet),

            inactiveTextColor = selectors.getFontColor(path.inactive)(currentStylesheet),
            hoverTextColor = selectors.getFontColor(path.hover)(currentStylesheet),

            ButtonView = buttonViewFactory({ style: { minWidth: '130px', minHeight: '50px' } }),
            disableBorderStyle = `${borderWidth.every((size) => size === 0) ? styles.borderStyleDisplay : ''}`,
            renderCommonStyle = ({
                title,
                subTitle,
                backgroundDialogConfigurationDialogId,
                colorGradientSettingsActions,
                backgroundSettingsActions,
                colorPropertyLabel,
                color,
                onColorChangeAction,
                borderStyleDialogId,
                borderStyleChangeAction,
                borderColorChangeAction,
                borderColorRemoveAction,
                borderColorOpacityChangeAction,
                last,
                intl,
                dispatch
            }) => (
                <Flex className={cx(styles.otherCommonStyles, styles.defaultStyles)}>
                    <Box><HorizontalSpacer /></Box>
                    <Box>
                        <Box className={cx(styles.borderLeft, styles.borderBottom)}>
                            <HorizontalSpacer />
                            <VerticalSpacer y={125} />
                        </Box>
                        {
                            !last &&
                            <Box className={styles.borderLeft}>
                                <HorizontalSpacer />
                                <VerticalSpacer y={31} />
                            </Box>
                        }
                    </Box>

                    <Box>
                        <VerticalSpacer y={28} />
                        <div className={styles.title}>{intl.msgJoint(title)}</div>
                        <VerticalSpacer y={10} />
                        <div className={styles.subtitle}>{intl.msgJoint(subTitle)}.</div>
                        <VerticalSpacer y={10} />
                        <GrayBgContainer className={styles.globalStylesButtonGrayBox}>
                            <Flex align="center">
                                <BackgroundButton
                                    dialogId={backgroundDialogConfigurationDialogId}
                                    // @ts-ignore
                                    colorGradientSettingsActions={colorGradientSettingsActions}
                                    backgroundSettingsActions={backgroundSettingsActions}
                                    dispatch={dispatch}
                                />
                                <HorizontalSpacer x={58} />
                                <ColorProperty
                                    style={{ width: 132 }}
                                    label={colorPropertyLabel}
                                    color={color}
                                    onChangeAction={onColorChangeAction}
                                    dispatch={dispatch}
                                />
                            </Flex>
                            <VerticalSpacer y={10} />
                            <Flex align="center" className={disableBorderStyle}>
                                { /* @ts-ignore */ }
                                <BorderStyleButton
                                    dialogId={borderStyleDialogId}
                                    borderStyleChangeAction={borderStyleChangeAction}
                                    borderColorChangeAction={borderColorChangeAction}
                                    borderColorRemoveAction={borderColorRemoveAction}
                                    borderColorOpacityChangeAction={borderColorOpacityChangeAction}
                                    dispatch={dispatch}
                                />
                            </Flex>
                        </GrayBgContainer>
                    </Box>
                    <Box><HorizontalSpacer x={30} /></Box>
                </Flex>
            );
        return (
            <Box className={styles.tabPanelContents}>
                { (!autoColorMode && <TopBar
                    messages={{
                        select: 'msg: gs.selectToStyle.button {Select a button style to edit}',
                        duplicate: 'msg: gs.duplicateStyle.button {New button style (Duplicate)}',
                        delete: 'msg: gs.deleteStyle.button {Delete this button style}'
                    }}
                    globalStyleSelectorChangeAction={actionTypes.GS_BTN_GLOBAL_STYLE_SELECTED}
                    optionMapper={optionMapper}
                    stylesheets={stylesheets}
                    currentStylesheetId={currentStylesheetId}
                    dispatch={dispatch}
                    intl={intl}
                />) ||
                    <Flex className={cx(styles.borderBottom, styles.infoBar)} align="center" justify="flex-start">
                        <Msg k="common.gs.common.button.overview">
                            Create predefined button styles to use across your pages.
                        </Msg>
                    </Flex>}
                <Flex className={cx(styles.tabPanel, styles.styleSelectorsStylePreview)}>
                    <div className={styles.styleSelectors}>
                        <Scrollbar height={`${scrollHeight}px`}>
                            <GrayBgContainer className={cx(styles.borderBottom, styles.mainStyleSelectors)}>
                                <Flex align="center">
                                    <FontFamilySelector
                                        selected={fontFamily}
                                        siteFonts={siteFonts}
                                        onChangeAction={actionTypes.GS_BTN_FONT_FAMILY_CHANGED}
                                        additionalPayload={{ source: ButtonGlobalStyleKind }}
                                    />
                                    <HorizontalSpacer x={10} />
                                    <TextFormatButtonGroup
                                        bold={{ isSelected: bold, onClickAction: actionTypes.GS_BTN_TOGGLE_BOLD }}
                                        italic={{ isSelected: italic, onClickAction: actionTypes.GS_BTN_TOGGLE_ITALIC }}
                                        dispatch={dispatch}
                                    />
                                    <HorizontalSpacer x={10} />
                                    <FontSizeSelector
                                        selected={fontSize}
                                        onChangeAction={actionTypes.GS_BTN_FONT_SIZE_CHANGED}
                                        dispatch={dispatch}
                                        searchable={false}
                                    />
                                </Flex>
                                <VerticalSpacer y={10} />
                                <Flex align="center" justify="flex-start">
                                    { !autoColorMode && <React.Fragment>
                                        <BorderWidthButton
                                            borderWidth={borderWidth}
                                            borderWidthChangeAction={actionTypes.GS_BTN_BORDER_WIDTH_CHANGED}
                                            dispatch={dispatch}
                                        />
                                        <HorizontalSpacer />
                                    </React.Fragment>}
                                    <BorderCornerButton
                                        corners={borderRadius}
                                        cornerChangeAction={actionTypes.GS_BTN_BORDER_RADIUS_CHANGED}
                                        dispatch={dispatch}
                                    />
                                </Flex>
                            </GrayBgContainer>
                            { !autoColorMode && <div>
                                {
                                    renderCommonStyle({
                                        title: 'msg: gs.button.default.title {Default}',
                                        subTitle: 'msg: gs.button.default.subTitle {These additional styling options are applied to the default button state}', // eslint-disable-line max-len
                                        backgroundDialogConfigurationDialogId: backgroundDialogConfigurationDialogId[path.inactive], // eslint-disable-line max-len
                                        colorGradientSettingsActions: {
                                            colorChangeAction: actionTypes.GS_BTN_INACTIVE_BG_COLOR_CHANGED,
                                            colorRemoveAction: actionTypes.GS_BTN_INACTIVE_BG_COLOR_REMOVED,
                                            gradientColorChangeAction: actionTypes.GS_BTN_INACTIVE_GRADIENT_CHANGED,
                                            gradientColorRemoveAction: actionTypes.GS_BTN_INACTIVE_GRADIENT_CANCELLED,
                                            gradientDirectionChangeAction: actionTypes.GS_BTN_INACTIVE_GRADIENT_DIRECTION_CHANGED, // eslint-disable-line max-len
                                            gradientFadePointChangeAction: actionTypes.GS_BTN_INACTIVE_GRADIENT_FADEPOINT_CHANGED, // eslint-disable-line max-len
                                            opacityChangeAction: actionTypes.GS_BTN_INACTIVE_BG_COLOR_OPACITY_CHANGED
                                        },
                                        backgroundSettingsActions: {
                                            assetChangeAction: actionTypes.GS_BTN_INACTIVE_ASSET_CHANGED,
                                            assetRemoveAction: actionTypes.GS_BTN_INACTIVE_ASSET_REMOVED,
                                            assetPositionChangeAction: actionTypes.GS_BTN_INACTIVE_ASSET_POSITION_CHANGED,
                                            assetRepeatChangeAction: actionTypes.GS_BTN_INACTIVE_ASSET_REPEAT_CHANGED,
                                            assetSizeChangeAction: actionTypes.GS_BTN_INACTIVE_ASSET_SIZE_CHANGED
                                        },
                                        colorPropertyLabel: "msg: common.textColor {Text colour}",
                                        color: inactiveTextColor,
                                        onColorChangeAction: actionTypes.GS_BTN_DEFAULT_TEXT_COLOR_CHANGED,
                                        borderStyleDialogId: borderStyleDialogConfigurationDialogId[path.inactive],
                                        borderStyleChangeAction: actionTypes.GS_BTN_INACTIVE_BORDER_STYLE_CHANGED,
                                        borderColorChangeAction: actionTypes.GS_BTN_INACTIVE_BORDER_COLOR_CHANGED,
                                        borderColorRemoveAction: actionTypes.GS_BTN_INACTIVE_BORDER_COLOR_REMOVED,
                                        borderColorOpacityChangeAction: actionTypes.GS_BTN_INACTIVE_BORDER_COLOR_OPACITY_CHANGED, // eslint-disable-line max-len
                                        last: false,
                                        intl,
                                        dispatch
                                    })
                                }
                                {
                                    renderCommonStyle({
                                        title: 'msg: gs.button.mouseOver.title {Mouse over}',
                                        subTitle: 'msg: gs.button.mouseOver.subTitle {These additional styling options are activated when you hover over a button}', // eslint-disable-line max-len
                                        backgroundDialogConfigurationDialogId: backgroundDialogConfigurationDialogId[path.hover], // eslint-disable-line max-len
                                        colorGradientSettingsActions: {
                                            colorChangeAction: actionTypes.GS_BTN_HOVER_BG_COLOR_CHANGED,
                                            colorRemoveAction: actionTypes.GS_BTN_HOVER_BG_COLOR_REMOVED,
                                            gradientColorChangeAction: actionTypes.GS_BTN_HOVER_GRADIENT_CHANGED,
                                            gradientColorRemoveAction: actionTypes.GS_BTN_HOVER_GRADIENT_CANCELLED,
                                            gradientDirectionChangeAction: actionTypes.GS_BTN_HOVER_GRADIENT_DIRECTION_CHANGED, // eslint-disable-line max-len
                                            gradientFadePointChangeAction: actionTypes.GS_BTN_HOVER_GRADIENT_FADEPOINT_CHANGED, // eslint-disable-line max-len
                                            opacityChangeAction: actionTypes.GS_BTN_HOVER_BG_COLOR_OPACITY_CHANGED
                                        },
                                        backgroundSettingsActions: {
                                            assetChangeAction: actionTypes.GS_BTN_HOVER_ASSET_CHANGED,
                                            assetRemoveAction: actionTypes.GS_BTN_HOVER_ASSET_REMOVED,
                                            assetPositionChangeAction: actionTypes.GS_BTN_HOVER_ASSET_POSITION_CHANGED,
                                            assetRepeatChangeAction: actionTypes.GS_BTN_HOVER_ASSET_REPEAT_CHANGED,
                                            assetSizeChangeAction: actionTypes.GS_BTN_HOVER_ASSET_SIZE_CHANGED
                                        },
                                        colorPropertyLabel: "msg: common.textColor {Text colour}",
                                        color: hoverTextColor,
                                        onColorChangeAction: actionTypes.GS_BTN_HOVER_TEXT_COLOR_CHANGED,
                                        borderStyleDialogId: borderStyleDialogConfigurationDialogId[path.hover],
                                        borderStyleChangeAction: actionTypes.GS_BTN_HOVER_BORDER_STYLE_CHANGED,
                                        borderColorChangeAction: actionTypes.GS_BTN_HOVER_BORDER_COLOR_CHANGED,
                                        borderColorRemoveAction: actionTypes.GS_BTN_HOVER_BORDER_COLOR_REMOVED,
                                        borderColorOpacityChangeAction: actionTypes.GS_BTN_HOVER_BORDER_COLOR_OPACITY_CHANGED, // eslint-disable-line max-len
                                        last: true,
                                        intl,
                                        dispatch
                                    })
                                }
                            </div> as any}
                        </Scrollbar>
                    </div>
                    <Box className={cx(styles.stylePreview, styles.borderLeft)} onClick={e => e.stopPropagation()}>
                        <div className={styles.previewLabel}>{intl.msgJoint('msg: common.preview.noun {Preview}')}</div>
                        {!autoColorMode ?
                            <Scrollbar height={`${previewScrollHeight}px`}>
                                <Flex className={styles.preview} align="center" justify="center">
                                    <BaseComponentPreview
                                        className={styles.buttonPreview}
                                        globalStyleIds={[stylesheetsIdToNameMap[currentStylesheetId]]}
                                    >
                                        <ButtonView
                                            text={buttonPreviewText}
                                            styles={buttonStyles}
                                        />
                                    </BaseComponentPreview>
                                </Flex>
                            </Scrollbar> :
                            <Scrollbar height={`${previewScrollHeight - 4}px`}>
                                <div className={styles.themeButtonPreview}>
                                    <VerticalSpacer y={2} />
                                    {getThemeButtonPreview(BUTTON_THEME_PRIMARY, <Msg k="common.primary">Primary</Msg>)}
                                    {getThemeButtonPreview(BUTTON_THEME_SECONDARY, <Msg k="common.secondary">Secondary</Msg>)}
                                    {getThemeButtonPreview(BUTTON_THEME_ALTERNATE, <Msg k="common.alternate">Alternate</Msg>)}
                                    <VerticalSpacer y={2} />
                                </div>
                            </Scrollbar>}
                    </Box>
                </Flex>
            </Box>
        );
    });
