import * as mobileViewPage from "../../../PropertiesPanel/view/MobileView/page";
import DOCUMENT from "../kind";
import { ComponentNames } from "../../constants";

export const
    PageIds = {
        MAIN: 'main',
        MOBILE_VIEW: mobileViewPage.id
    },
    PageTitles = {
        [PageIds.MAIN]: ComponentNames[DOCUMENT],
        [mobileViewPage.id]: mobileViewPage.title
    };
