import { getDAL } from '../../../dal';
import { getLoginEmail } from '../../../utils/loginData';
import isTestEnv from '../../debug/isTestEnv';
import { trackingClientFactory } from '../../services/trackingClientFactory';
import AppConfig from '../../utils/AppConfig';
import { getAppConfig } from '../App/epics/appConfig/appConfig';
import { isTrialSubscription } from '../App/epics/subscriptionData/utils';
import { MixpanelSlackErrorType } from './constants';
import type { EventFactoryMiddlewareFn, EventsRegistryType, FilterPropsFnType, FrontClientEvent, TrackEventMethodProps } from './types';

const appConfig = AppConfig(getAppConfig());
const isTrackingEnabled = appConfig.getOptional('server.eventTracking.enable');
let cachedEvents: Array<FrontClientEvent> = [];

export const getSanitizeProperties = (properties, propsToFilter) => {
    let sanitizedProperties = {};
    Object.keys(properties).forEach((key) => {
        const value = properties[key];
        if (value === null || value === undefined || value === '' || propsToFilter[key]) { return; }
        sanitizedProperties[key] = value;
    });
    return sanitizedProperties;
};

export const trackEvents = ({ events, factoryProps, propsToFilter, userSessionId }: TrackEventMethodProps) => {
    if (events.length === 0) { return; }
    const eventTrackingClient = trackingClientFactory({ ...factoryProps, userSessionId });
    const updatedEvents = events.map(({ name, properties }) => ({
        name,
        properties: getSanitizeProperties({ ...factoryProps, ...properties }, propsToFilter)
    }));
    eventTrackingClient.trackEvents(updatedEvents);
};

export const EventTrackerMiddlewareFactory = (
    registry: EventsRegistryType,
    middlewareFn: EventFactoryMiddlewareFn,
    ownerInfoHandler: EventFactoryMiddlewareFn,
    filterPropsFn?: FilterPropsFnType,
) => (appStore: Store) => (next: Dispatch) => (action: AnyAction): any => {
    const { type, payload } = action;
    try {
        if (!isTrackingEnabled || isTestEnv()) {
            return next(action);
        }
        if (!ownerInfoHandler || !middlewareFn) {
            console.error('The ownerInfoHandler & middlewareFn is not found');
            return next(action);
        }

        const eventTracker = registry[type];
        const appState = appStore.getState();
        const { loading, failed, successType, failureType, retryType } = ownerInfoHandler(appState);
        if (!eventTracker && type !== successType && type !== failureType) {
            return next(action);
        }

        const factoryProps = middlewareFn(appState);
        const propsToFilter = filterPropsFn ? filterPropsFn() : {};

        // Generate the user session id.
        let userSessionId = null;
        const { trialEmail, email, adminId, subscriptionType } = factoryProps;
        const isTrial = isTrialSubscription(subscriptionType);
        if (isTrial) {
            userSessionId = trialEmail || email || getLoginEmail();
        } else {
            userSessionId = adminId;
        }

        // Send out the cached events as soon as the session id is available.
        if (userSessionId && cachedEvents.length > 0) {
            trackEvents({ events: cachedEvents, factoryProps, propsToFilter, userSessionId });
            cachedEvents = [];
        }

        // Check if the current action is registered in the event tracker.
        // When there is no session id:
        // - throw error if in trial mode.
        // - otherwise, throw error if owner info fetch failed.
        // - otherwise, initialize tracking.
        if (!userSessionId) {
            if (isTrial) {
                throw new Error('Trial Email is not found, can not initialize tracking');
            }
            if (failed) {
                throw new Error('No AdminId / Email found, can not initialize tracking');
            }
        }

        // Push event to the cache.
        if (!eventTracker) {
            return next(action);
        }
        let { eventName, propsHandler, getEventName, isDisabled } = eventTracker;
        if (isDisabled && isDisabled(appState)) {
            return next(action);
        }
        eventName = getEventName ? getEventName(appState, payload) : eventName;
        if (!eventName) {
            return next(action);
        }
        cachedEvents.push({
            name: eventName,
            properties: (propsHandler && propsHandler(appState, payload)) || {}
        });

        // Send out the cached events when there is session id.
        if (userSessionId) {
            trackEvents({ events: cachedEvents, factoryProps, propsToFilter, userSessionId });
            cachedEvents = [];
        }

        // When there is no session id, and owner info is not loading, dispatch an action to fetch it.
        // Note: we already checked above to ensure that it is not in trial / owner info fetch has not failed.
        if (!userSessionId && !loading) {
            appStore.dispatch({ type: retryType });
        }
    } catch (e: any) {
        const sendSlackAlert = (getDAL() || {}).sendSlackAlert;
        if (sendSlackAlert) {
            sendSlackAlert({
                type: MixpanelSlackErrorType,
                message: `${e.message} \nEvent data:\n ${JSON.stringify(cachedEvents, null, 2)}`
            });
        }
        console.error(e.message);
    }
    return next(action);
};
