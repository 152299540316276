import React from "react";
export const SvgFacebookRegularMulticolor = props => (
    <svg viewBox="0 0 45 45" {...props}>
        <g fill="none">
            <path
                fill="#1877f2"
                d="M44.506 22.23C44.506 9.951 34.543 0 22.253 0 9.963 0 0 9.952 0 22.23c0 11.094 8.138 20.29 18.776 21.958V28.655h-5.65V22.23h5.65v-4.897c0-5.571 3.322-8.649 8.405-8.649 2.435 0 4.981.435 4.981.435v5.47h-2.806c-2.764 0-3.626 1.714-3.626 3.472v4.17h6.172l-.987 6.425H25.73V44.19c10.638-1.668 18.776-10.864 18.776-21.96"
            />
            <path
                fill="#ffffff"
                d="M30.915 28.655l.987-6.426H25.73v-4.17c0-1.757.862-3.47 3.626-3.47h2.806V9.117s-2.546-.435-4.98-.435c-5.084 0-8.406 3.078-8.406 8.649v4.897h-5.65v6.426h5.65V44.19a22.443 22.443 0 006.954 0V28.655h5.185"
            />
        </g>
    </svg>
);
