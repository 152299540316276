import u from 'updeep';
import scaleStrategies from '../scaleStrategy';
import { handleFitResize } from '../reducers/adjustmentHookConfig';
import type { ImageComponent } from '../flowTypes';

export const widthChanged = (component: ImageComponent,
    { payload: { value: newWidth } }: any): ImageComponent => {
    if (component.scaleStrategy === scaleStrategies.FIT) {
        return handleFitResize(u({ width: newWidth }, component), component);
    }
    return component;
};
export const heightChanged = (component: ImageComponent,
    { payload: { value: newHeight } }: any): ImageComponent => {
    if (component.scaleStrategy === scaleStrategies.FIT) {
        return handleFitResize(u({ height: newHeight }, component), component);
    }
    return component;
};
