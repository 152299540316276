import localStorage from '../../../utils/localStorage';

// needs to function for tests
const
    key = 'lastPageId',
    getLastPageId = () => localStorage.get(key, true),
    setLastPageId = (lastPageId: string | null) => localStorage.set(key, lastPageId, true);

export {
    getLastPageId,
    setLastPageId
};
