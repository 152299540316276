import React from "react";
export const SvgYoutubeRegularMulticolor = props => (
    <svg viewBox="0 0 48 34" {...props}>
        <g fill="none">
            <path
                fill="#f44336"
                d="M46.724 7.168s-.461-3.257-1.881-4.686C43.045.6 41.035.59 40.113.48 33.514 0 23.603 0 23.603 0h-.019s-9.91 0-16.512.48C6.15.59 4.14.6 2.341 2.482.922 3.912.472 7.168.472 7.168S0 10.987 0 14.815v3.58c0 3.819.47 7.647.47 7.647S.931 29.3 2.342 30.73C4.14 32.61 6.5 32.546 7.55 32.749c3.78.36 16.05.47 16.05.47s9.92-.018 16.522-.489c.922-.11 2.931-.12 4.73-2.001 1.42-1.43 1.88-4.687 1.88-4.687s.47-3.819.47-7.647v-3.58c-.009-3.819-.48-7.647-.48-7.647z"
            />
            <path fill="#ffffff" d="M18.715 22.74V9.465l12.751 6.66z" />
        </g>
    </svg>
);
