
import makePages from '../../../PropertiesPanel/view/makePages';
import reducer from '../reducer';
import * as main from './pages/main';
import * as layout from './pages/layout';
import * as onClick from './pages/onClick';
import * as account from './pages/account';
import addGoogleFont from "../../../PropertiesPanel/view/AddGoogleFont/index";

const propertiesPanelConfig = {
    pages: makePages(
        main,
        layout,
        onClick,
        addGoogleFont,
        account,
    ),
    defaultPageId: main.id,
    reducer
};

export default propertiesPanelConfig;
