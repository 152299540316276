/* eslint-disable react/no-children-prop */

import React from 'react';
import { pure } from 'recompose';
import Icons from "../../../view/Icons/index";
import factory from '../../presentational/Button/factory';
import styles from './styles.css';
import { injectIntl, Intl } from '../../../view/intl/index';

type Props = {
    icon: string,
    onClick?: (e: React.MouseEvent<any>) => void,
    onMouseDown?: (e: React.MouseEvent<any>) => void,
    onMouseEnter?: (e: React.MouseEvent<any>) => void,
    selected?: boolean,
    disabled?: boolean,
    className?: string,
    btnClass?: string,
    isErrorTitle?: boolean,
    title?: string,
    titlePosition?: string,
    intl: Intl
};

export default pure(injectIntl((
    {
        icon,
        onClick,
        onMouseDown,
        onMouseEnter,
        selected,
        className,
        btnClass,
        title,
        isErrorTitle,
        disabled,
        intl,
        ...rest
    }: Props
) => {
    const
        Icon = Icons[icon],
        buttonClassname = styles.mctaButton + (selected ? ' ' + styles.selected : '') +
            (Icon ? ' ' + styles.mctaButtonIcon : ''),
        MctaButton = factory({
            containerType: 'div',
            containerExtraPropNames: [],
            additionalClassName: buttonClassname
        });

    let props = {
        onMouseDown,
        onMouseEnter,
        disabled,
        isErrorTitle,
        className: btnClass,
        children: <Icon className={className} />,
        ...rest
    };
    if (!disabled) {
        // @ts-ignore
        props.onClick = onClick;
    }
    if (title) {
        // @ts-ignore
        props.title = intl.msgJoint(title);
    }

    return (<MctaButton {...props} />);
}));
