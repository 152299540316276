/* eslint-disable no-useless-escape */
import React from "react";
import cx from "classnames";
import Page from "../../../../PropertiesPanel/view/PropertiesPage";
import pagesTitles from "./titles";
import * as pagesIds from "./ids";
import * as actionTypes from "../../actionTypes";
import styles from "../PropertiesPanelMenu.css";
import * as VERTICAL_ALIGN from "../../constants/verticalAlign";
import * as HORIZONTAL_ALIGN from "../../constants/horizontalAlign";
import { isAligmentButtonActive } from "./helpers";
import Icons from "../../../../../view/Icons/index";
import PropertyContainer from "../../../../../view/common/PropertyContainer/index";
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import TdIconButton from "../../../../presentational/TdIconButton/index";
import CheckBox from "../../../../../view/common/CheckBox/PropertiesCheckBox";
import InputField from "../../../../../view/common/Input/PropertiesInputField";
import { injectIntl } from "../../../../../view/intl/index";
import Separator from "../../../../presentational/PropertiesPanel/Separator";
import stylePropertyContainer from "../../../../../view/common/PropertyContainer/PropertyContainer.css";
import type { Props } from "../flowTypes";

const aligmentButtonsConfigs = [
        [
            [Icons.LEFT_TOP, VERTICAL_ALIGN.TOP, HORIZONTAL_ALIGN.LEFT],
            [Icons.TOP, VERTICAL_ALIGN.TOP, HORIZONTAL_ALIGN.CENTER],
            [Icons.RIGHT_TOP, VERTICAL_ALIGN.TOP, HORIZONTAL_ALIGN.RIGHT]
        ],
        [
            [Icons.LEFT, VERTICAL_ALIGN.MIDDLE, HORIZONTAL_ALIGN.LEFT],
            [Icons.CENTER_BOX, VERTICAL_ALIGN.MIDDLE, HORIZONTAL_ALIGN.CENTER],
            [Icons.RIGHT, VERTICAL_ALIGN.MIDDLE, HORIZONTAL_ALIGN.RIGHT]
        ],
        [
            [Icons.LEFT_BOTTOM, VERTICAL_ALIGN.BOTTOM, HORIZONTAL_ALIGN.LEFT],
            [Icons.BOTTOM, VERTICAL_ALIGN.BOTTOM, HORIZONTAL_ALIGN.CENTER],
            [Icons.RIGHT_BOTTOM, VERTICAL_ALIGN.BOTTOM, HORIZONTAL_ALIGN.RIGHT]
        ]
    ],
    id = pagesIds.ADVANCED,
    horizontalAlignFitBtnPressedAction = {
        type: actionTypes.MENU_PROP_PANEL_HORIZONTAL_ALIGN_FIT_BTN_PRESSED
    },
    AligmentTableBlock = ({ dispatch, selectedComponent, intl }) => {
        const onAligmentBtnClick = (verticalAlign, horizontalAlign) =>
                dispatch({
                    type: actionTypes.MENU_PROP_PANEL_ALIGMENT_BTN_PRESSED,
                    payload: {
                        verticalAlign,
                        horizontalAlign
                    }
                }),
            renderCell = ([Icon, verticalAlign, horrizontalAlign], index) => {
                const isSelected = isAligmentButtonActive(verticalAlign, horrizontalAlign, selectedComponent);
                return (
                    <TdIconButton
                        key={index}
                        title={intl.msgJoint(VERTICAL_ALIGN.intlDict[verticalAlign])}
                        onClick={() => onAligmentBtnClick(verticalAlign, horrizontalAlign)}
                        isSelected={isSelected}
                        Icon={Icon}
                        iconClassName={styles.iconClassName}
                        className={cx({
                            [styles.iconButtonSelectedPropertiesPanel]: isSelected
                        })}
                    />
                );
            },
            renderRow = (configsRow, index) => <tr key={index}>{configsRow.map(renderCell)}</tr>,
            aligmentLabel = `${intl.msgJoint(VERTICAL_ALIGN.intlDict[selectedComponent.verticalAlign])}, ${intl.msgJoint(
                HORIZONTAL_ALIGN.intlDict[selectedComponent.horizontalAlign]
            )}`;

        // eslint-disable-line max-len
        return (
            <div>
                <PropertyContainer
                    label="msg: component.menu.propPanel.aligmentLabel {Alignment:}"
                    valueLabel={aligmentLabel}
                    valueClassName={styles.alignmentValueLabel}
                >
                    <table className={cx(styles.table, styles.alignmentTable)}>
                        <tbody>
                            {aligmentButtonsConfigs.map(renderRow)}
                            <tr>
                                <TdIconButton
                                    title="fit"
                                    onClick={() => dispatch(horizontalAlignFitBtnPressedAction)}
                                    isSelected={selectedComponent.horizontalAlign === HORIZONTAL_ALIGN.FIT}
                                    Icon={Icons.FULL_WIDTH}
                                    colSpan={3}
                                    iconClassName={styles.iconClassName}
                                    className={cx({
                                        [styles.iconButtonSelectedPropertiesPanel]:
                                            selectedComponent.horizontalAlign === HORIZONTAL_ALIGN.FIT
                                    })}
                                />
                            </tr>
                        </tbody>
                    </table>
                </PropertyContainer>
            </div>
        );
    },
    moreBtnTextActionCreator = text => ({
        type: actionTypes.MENU_PROP_PANEL_MORE_BTN_TEXT_CHANGED,
        payload: text,
        amendToSelf: true
    }),
    // eslint-disable-next-line max-len
    moreButtonDescription = `msg: component.menu.propPanel.moreButtonDescription {If your pages don\'t all fit in the menu, they will list themselves under this button}`,
    // eslint-disable-line
    view = injectIntl((props: Props) => {
        const moreButtonEditDisabled = props.selectedComponentExtension && props.selectedComponentExtension.moreButtonEditDisabled,
            moreButtonTextEditDisabled = props.selectedComponentExtension && props.selectedComponentExtension.moreButtonTextEditDisabled;
        return (
            <Page>
                <VerticalSpacer />
                <CheckBox
                    containerClassName={styles.moreBtnContainer}
                    label={'msg: component.menu.propPanel.enableMoreButton {Enable "More" button}'}
                    isChecked={props.selectedComponent.moreButtonEnabled}
                    onClick={() =>
                        props.dispatch({
                            type: actionTypes.MENU_PROP_PANEL_ENABLE_MORE_BUTTON_TOGGLED
                        })}
                    disabled={moreButtonEditDisabled}
                />
                <div className={styles.moreBtnDescription}>{props.intl.msgJoint(moreButtonDescription)}</div>
                <VerticalSpacer />
                <PropertyContainer
                    label="msg: component.menu.propPanel.moreBtnText {Button text}"
                    disabled={moreButtonTextEditDisabled}
                    className={stylePropertyContainer.pointer}
                >
                    <InputField
                        disabled={moreButtonTextEditDisabled}
                        value={props.selectedComponent.moreText}
                        onChange={text => props.dispatch(moreBtnTextActionCreator(text))}
                        placeholder="msg: component.menu.propPanel.moreBtnTextPlaceholder {Enter text here}"
                    />
                </PropertyContainer>
                <Separator />
                <AligmentTableBlock {...props} />
            </Page>
        );
    }),
    title = pagesTitles[id];

export { id, view, title };
