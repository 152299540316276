import React from 'react';
import makeAppStandardErrorDialog from "../../../App/dialogs/makeAppStandardErrorDialog";
import { PAGE_TREE_PAGE_DELETE_CONFIRM } from "../actionTypes";
import { WarningConfirmationDialog } from '../../../../view/common/dialogs/WarningConfirmationDialog';
import { Msg } from '../../../../view/intl';

type Props = {
    pageName: string;
    dispatch: Dispatch;
};

export default makeAppStandardErrorDialog(({ pageName, dispatch }: Props) => (
    <WarningConfirmationDialog
        title="msg: deleteStartPageAndSubpages {Delete start page and subpages}"
        confirmLabel="msg: delete {Delete}"
        onConfirm={() => dispatch({ type: PAGE_TREE_PAGE_DELETE_CONFIRM })}
        dispatch={dispatch}
    >
        <Msg k="confirmDeletePageAndSubpages" params={{ pageName }}>
            {`Are you sure you want to delete "{pageName}" and subpages?`}
        </Msg>
        &nbsp;
        <Msg k="websiteWontDisplayAfterNextPublication">
            Your website will not display a Website Builder page after your next publication.
        </Msg>
        &nbsp;
        <Msg k="assignAnotherStartPage">
            To assign another as start page, click the gear next to a page and open Page info.
        </Msg>
    </WarningConfirmationDialog>
));
