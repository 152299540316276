import * as R from "ramda";
import p from "../utils/pipePath";
import * as mp from "../mappers/path";
import { getByPath } from "../utils/ramdaEx";
import { opacityToAlpha } from "../components/oneweb/Background/utils/index";
import type { Path } from "../mappers/path";

export const
    getBorderStyle = R.curry((path: Path, data: AnyValue): AnyValue => getByPath(p(path, mp.style), data)),
    getBorderColor = R.curry((path: Path, data: AnyValue): AnyValue => getByPath(p(path, mp.color), data)),
    getBorderThemeColor = R.curry((path: Path, data: AnyValue): AnyValue => getByPath(p(path, 'themeColor'), data)),
    getBorderOpacity = R.curry((path: Path, data: AnyValue) => {
        const color = getBorderColor(path, data);
        return color ? color[4] : 1;
    }),
    getBorderAlpha = R.curry((path: Path, data: AnyValue): AnyValue => opacityToAlpha(getBorderAlpha(path, data))),
    getBorderWidth = R.curry((path: Path, data: AnyValue): AnyValue => getByPath(p(path, mp.width), data));
