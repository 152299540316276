import { applyMappers, makePlainMappers } from "../../utils";
import type { AnyComponentData } from "../../../../src/components/App/flowTypes";
import type { GoogleReviewsComponent } from "../../../../src/components/oneweb/GoogleReviews/flowTypes";

const plainPropsMapper = makePlainMappers({
    layoutType: 'layoutType',
    reviews: 'reviews',
    headerText: 'headerText',
    showReviewButton: 'showReviewButton',
    reviewButtonText: 'reviewButtonText',
    buttonThemeSelected: "buttonThemeSelected",
    styleButton: 'styleButton',
    storeData: 'storeData',
    seeMoreText: 'seeMoreText',
    seeLessText: 'seeLessText',
    googleReviewsText: 'googleReviewsText',
    reviewTimeText: 'reviewTimeText',
    mobileHide: 'mobileHide',
});

export function to(componentData: AnyComponentData): GoogleReviewsComponent {
    return applyMappers(
        componentData,
        plainPropsMapper.to
    );
}

export function back(component: GoogleReviewsComponent): AnyComponentData {
    return applyMappers(
        component,
        plainPropsMapper.back
    );
}
