import React from 'react';
import VerticalSpacer from '../../../view/common/VerticalSpacer';
import { dropdown as BorderStyle } from '../BorderStyle/index';
import { DEFAULT_BORDER_STYLE } from "./constants";
import ColorProperty from '../ColorProperty/index';
import OpacitySlider from '../OpacitySlider/index';
import FourFieldBorders from '../FourFields/FourFieldBorders/index';
import FourFieldCorners from '../FourFields/FourFieldCorners/index';
import type { PropTypes } from './flowtypes';
import styles from '../Border/Border.css';
import { DEFAULT_COLOR_PICKER, THEME_COLOR_OVERRIDE_COLOR_PICKER } from '../../ColorPicker/constants';

// TODO create issue to dispatch single compund action
const BorderSettings = (props: PropTypes) => {
    const
        {
            dispatch,
            styleChangeAction,
            opacity,
            opacityChangeAction,
            widths,
            widthChangeAction,
            colorChangeAction,
            colorOnTheFlyChange,
            showOpacity = true,
            selectedComponent,
        } = props,
        style = props.style || DEFAULT_BORDER_STYLE,
        isDisabled = style === DEFAULT_BORDER_STYLE,
        makeOnChange = type => value => dispatch({ type, payload: value, amendToSelf: true }),
        colorRemoveOpts = props.colorRemoveAction ? { onRemoveAction: props.colorRemoveAction } : {};

    return (
        <div>
            <VerticalSpacer />
            <BorderStyle
                value={style}
                onChangeAction={styleChangeAction}
                disabled={false}
                dispatch={dispatch}
            />
            <div className={`${styles.borderSettingsFullView} ${isDisabled ? styles.hidden : ''}`}>
                <VerticalSpacer />
                {
                    props.autoColorMode
                        ? <ColorProperty
                            colorPickerKind={THEME_COLOR_OVERRIDE_COLOR_PICKER}
                            themeColor={props.themeColor}
                            themeColorsData={props.themeColorsData}
                            label="msg: common.borderColor {Border colour:}"
                            dispatch={dispatch}
                            onChangeAction={colorChangeAction}
                            disabled={isDisabled}
                            {...colorRemoveOpts}
                        />
                        : <ColorProperty
                            colorPickerKind={DEFAULT_COLOR_PICKER}
                            label="msg: common.borderColor {Border colour:}"
                            color={props.color}
                            preserveColorBoxOpacity
                            dispatch={dispatch}
                            onChangeAction={colorChangeAction}
                            onTheFlyChange={colorOnTheFlyChange}
                            disabled={isDisabled}
                            {...colorRemoveOpts}
                        />
                }
                <VerticalSpacer />
                {showOpacity && <OpacitySlider
                    // @ts-ignore
                    opacity={opacity}
                    label="msg: common.borderOpacity {Border opacity}"
                    onChange={newOpacity => {
                        if (newOpacity !== opacity) {
                            makeOnChange(opacityChangeAction)(newOpacity);
                        }
                    }}
                    onAfterChange={makeOnChange(opacityChangeAction)}
                    disabled={isDisabled}
                />}
                {showOpacity && <VerticalSpacer />}
                <FourFieldBorders
                    values={widths}
                    onChangeAction={widthChangeAction}
                    disabled={isDisabled}
                    dispatch={dispatch}
                    selectedComponent={selectedComponent}
                    showTopAndBottomElements={props.showTopAndBottomElements || true}
                    showLeftAndRightElements={props.showLeftAndRightElements || true}

                />
            </div>
            {
                (props.showCorners !== false) && <React.Fragment>
                    <VerticalSpacer key={1} />
                    <FourFieldCorners
                        key={2}
                        // @ts-ignore
                        values={props.corners}
                        onChangeAction={props.cornerChangeAction}
                        dispatch={dispatch}
                    />
                </React.Fragment>
            }
        </div>
    );
};

const id = 'Border',
    title = 'msg: common.border {Border}',
    view = BorderSettings;

export default { id, view, title };
