import * as React from 'react';
import cx from 'classnames';
import styles from './QuestionTip.css';
import { TooltipProps, TooltipCom } from "../TooltipCom";
import type { ReactElementRef } from "../../../../globalTypes";

export type QuestionTipProps = Omit<TooltipProps, 'show' | 'targetRef'| 'onClick'| 'onMouseEnter'| 'onMoue'| 'intl'> & {
    hideHeader?: boolean,
    className?: string,
    preserveWhenHover?: boolean,
};

type State = {
    show: boolean,
};

export class QuestionTip extends React.Component<QuestionTipProps, State> {
    timer: undefined | ReturnType<typeof setTimeout> = undefined;

    ref:ReactElementRef<HTMLSpanElement>;

    componentWillUnmount() {
        // $FlowFixMe: incompatible TimeoutID / IntervalID usage
        clearInterval(this.timer);
    }

    constructor() {
        // @ts-ignore
        super();
        this.state = { show: false };
        this.ref = React.createRef();
    }

    onQuestionMouseOver = () => {
        this.timer = undefined;
        this.setState({ show: true });
    };

    onQuestionMouseOut = () => {
        if (!this.props.preserveWhenHover) {
            this.hideTip();
            return;
        }

        this.timer = setTimeout(() => {
            if (this.timer) {
                this.hideTip();
            }
        }, 500);
    };

    onTipMouseEnter = () => {
        this.timer = undefined;
    };

    onTipMouseLeave = () => {
        this.hideTip();
    };

    hideTip = () => this.setState({ show: false });

    render() {
        const
            { show } = this.state,
            {
                className,
                preserveWhenHover = false,
                ...restProps
            } = this.props,
            tooltipProps = {
                isSmallTipInfo: false,
                ...restProps,
                show,
                targetRef: this.ref,
                onMouseEnter: preserveWhenHover ? this.onTipMouseEnter : null,
                onMouseLeave: preserveWhenHover ? this.onTipMouseLeave : null,
            },
            questionClassName = cx(styles.questionIcn, className);

        return (
            <React.Fragment>
                <span
                    className={questionClassName}
                    onMouseOver={this.onQuestionMouseOver}
                    onMouseOut={this.onQuestionMouseOut}
                    ref={this.ref}
                />
                <TooltipCom {...tooltipProps} />
            </React.Fragment>
        );
    }
}
