import { MAXED_OUT_MOBILE_SIZE_VALUE } from './constants';
import type { SvgComponent } from "./flowTypes";
import type { CmpSpecificStyles } from "../../Preview/flowTypes";

export const getSpecificStyles = ({ component: { id, width, widthInMobile } }: CmpSpecificStyles<SvgComponent>): string => (
    `.mobileV div[data-id='${id}'] img {
        height: 100%;
        max-width: 100%;
        width: ${widthInMobile === MAXED_OUT_MOBILE_SIZE_VALUE ? '100%' : `${widthInMobile || width}px`} !important;
    }`
);
