import { UPGRADE } from "../App/actionTypes";
import * as actions from "./actionTypes";
import type { UpgradePayloadType } from './flowTypes';

export const tryUpgradeDialogCompleted = (componentType: string, upgradeDialogMsg: string = ""): Action => ({
    type: actions.TRY_UPGRADE_DIALOG_COMPLETED,
    payload: { componentType, upgradeDialogMsg },
});

export const tryUpgradeDialogCancelled = (componentType: string): Action => ({
    type: actions.TRY_UPGRADE_DIALOG_CANCELLED,
    payload: { componentType },
});

export const upgrade = (payload: UpgradePayloadType): PlainAction | AnyAction => {
    return payload ? { type: UPGRADE, payload } : { type: UPGRADE };
};

export const calculateUpgradeTierBeforeSeamlessUpgrade = (
    source: string,
    componentKindOrFeature: string,
    upgradeContentBodyMsg: string = "",
    doSeamlessUpgrade: boolean = true,
) => {
    return {
        type: actions.CALCULATE_UPGRADE_TIER_BEFORE_SEAMLESS_UPGRADE,
        payload: { source, componentKindOrFeature, upgradeContentBodyMsg, doSeamlessUpgrade }
    };
};
