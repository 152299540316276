import * as ActionTypes from "./actionTypes";

export const getDomainFromAuthCookieAction = () => ({
    type: ActionTypes.GET_DOMAIN_FROM_AUTH_COOKIE,
});

export const domainFromAuthCookieAction = (domain: string | null | undefined) => ({
    type: ActionTypes.DOMAIN_FROM_AUTH_COOKIE_VALUE,
    payload: domain,
});

export const getEmailFromAuthCookieAction = () => ({
    type: ActionTypes.GET_EMAIL_FROM_AUTH_COOKIE,
});

export const emailFromAuthCookieAction = (email: string | null | undefined) => ({
    type: ActionTypes.EMAIL_FROM_AUTH_COOKIE_VALUE,
    payload: email
});

export const getCookieAction = (callerId: string, cookieKey: string) => ({
    type: ActionTypes.GET_COOKIE,
    payload: { callerId, cookieKey },
});

export const cookieResultAction = (callerId: string, cookie: string) => ({
    type: ActionTypes.COOKIE_RESULT,
    payload: { callerId, cookie },
});

export const authCookieExistsAction = (domain: string) => ({
    type: ActionTypes.AUTH_COOKIE_EXISTS,
    payload: domain,
});

export const authCookieMissingAction = () => ({
    type: ActionTypes.AUTH_COOKIE_MISSING,
});

export const setCookieAction = (key: string, value: string, options?: any) => ({
    type: ActionTypes.SET_COOKIE,
    payload: { key, value, options },
});

export const unsetCookieAction = (key: string, useTld: boolean = false) => ({
    type: ActionTypes.UNSET_COOKIE,
    payload: { key, useTld },
});

export const loginRedirectAction = () => ({
    type: ActionTypes.REDIRECT_TO_LOGIN,
});

export const reloadPageAction = () => ({
    type: ActionTypes.RELOAD_PAGE,
});

export const logoutAction = () => ({
    type: ActionTypes.LOGOUT_ACTION,
});

export const forceLogoutAction = () => ({
    type: ActionTypes.FORCE_LOGOUT_ACTION,
});
