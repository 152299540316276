/* eslint-disable max-len */

export const PageInfoTemplateTabMessages = {
    widget: {
        settings: {
            title: 'msg: pageInfo.templateTab.settings.title {Template settings}',
            desc: 'msg: pageInfo.templateTab.settings.desc {Edit the background and other settings of your template.}'
        },
        styles: {
            title: 'msg: pageInfo.templateTab.styles.title {Template styles}',
            desc: 'msg: pageInfo.templateTab.styles.desc {Edit the styling of the template to give it your personal touch.}'
        },
        content: {
            title: 'msg: pageInfo.templateTab.content.title {Template content}',
            desc: 'msg: pageInfo.templateTab.content.desc {Drag & drop components that are shared across pages using this template.}'
        }
    },
    currentTemplate: {
        prefix: 'msg: pageInfo.templateTab.currentTemplate.prefix {Current template:}',
        change: 'msg: pageInfo.templateTab.currentTemplate.change {Change template for this page}'
    },
    switchPage: {
        p1: 'msg: pageInfo.templateTab.switchPage.p1 {You can only edit templates for a page shown on your workspace. }',
        p2: 'msg: pageInfo.templateTab.switchPage.p2 {To edit the template for the page \"<b>{pageName}</b>\", you need to show this page on your workspace first.}',
        p3: 'msg: pageInfo.templateTab.switchPage.p3 {Do you want to switch to \"<b>{pageName}</b>\" and access the template settings here?}',
        btn: 'msg: pageInfo.templateTab.switchPage.btn {Yes, switch page}'
    }
};
