import React from 'react';
import { connect } from "react-redux";
import { Dialog, StripTypes, DialogTitleBox, DialogBody, DialogFooter } from '../baseDialog/index';
import * as styles from './WarningDialog.css';
import { CLOSE_DIALOG } from "../../../../redux/modules/actionTypes";

type PropTypes = {
    title: string,
    style ?: React.CSSProperties,
    children ?: any,
    onClose ?: () => void,
    mctaText: string,
    mctaHandler?: () => void,
    sctaText: string,
    sctaHandler ?: () => void,
    noTopBorderForFooter?: boolean,
    bodyClassName?: string,
    primaryBtnRed?: string,
    stripType?: string,
}

const WarningDialog = props => {
    const {
        title,
        style,
        mctaHandler,
        onClose = props.defaultOnClose,
        sctaHandler,
        noTopBorderForFooter = true,
        mctaText,
        sctaText,
        children,
        bodyClassName,
        primaryBtnRed,
        stripType = StripTypes.WARNING,
    }: PropTypes = props;

    const footerProps = {
        mctaText,
        sctaText,
        mctaHandler: mctaHandler || onClose,
        sctaHandler: sctaHandler || (sctaText && onClose) || null,
        noTopBorder: noTopBorderForFooter
    };
    const primaryBtnClass = primaryBtnRed ? styles.redBtn : '';

    return (
        <Dialog
            stripType={stripType}
            className={styles.dialog}
            style={style}
            onClose={onClose}
        >
            <DialogTitleBox title={title} iconClass={styles.iconWarning} className={styles.warningDialogTitle} />
            <DialogBody className={bodyClassName}>
                {children}
            </DialogBody>
            <DialogFooter {...footerProps} className={styles.warningDialogFooter} primaryBtnClass={primaryBtnClass} />
        </Dialog>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    defaultOnClose: () => dispatch({ type: CLOSE_DIALOG })
});

export default connect(null, mapDispatchToProps)(WarningDialog);
