import type {
    EpicsState, EpicsStateUpdate, EpicState, EpicStateUpdate,
    EpicUpdatersState, EpicUpdatersStateUpdate, UpdaterState, UpdaterStateReceivedActions,
    UpdaterStateUpdate, UpdaterStateUpdateReceivedActions
} from "./flowTypes";

function mergeReceivedActions(epicReceivedActions: UpdaterStateReceivedActions,
                              updaterStateUpdateReceivedActions: UpdaterStateUpdateReceivedActions) { // eslint-disable-line
    const resultEpicReceivedActions = {};
    for (const key in epicReceivedActions) { // eslint-disable-line
        const receivedActionUpdate = updaterStateUpdateReceivedActions[key];

        if (receivedActionUpdate === undefined) {
            resultEpicReceivedActions[key] = epicReceivedActions[key];
        } else {
            resultEpicReceivedActions[key] = updaterStateUpdateReceivedActions[key];
        }
    }

    return resultEpicReceivedActions;
}

function mergeUpdaterState(epicUpdaterState: UpdaterState, epicUpdaterStateUpdate: UpdaterStateUpdate) {
    const
        isFullfilled = epicUpdaterStateUpdate.isFullfilled === undefined ?
            epicUpdaterState.isFullfilled : epicUpdaterStateUpdate.isFullfilled,
        result: UpdaterState = {
            receivedActions: mergeReceivedActions(
                epicUpdaterState.receivedActions,
                epicUpdaterStateUpdate.receivedActions
            ),
            executionParams: epicUpdaterStateUpdate.executionParams,
            isFullfilled
        };

    return result;
}

function mergeUpdatersState(epicUpdatersState: EpicUpdatersState, epicUpdatersStateUpdate: EpicUpdatersStateUpdate): EpicUpdatersState { // eslint-disable-line max-len
    return epicUpdatersState.map((epicUpdterState, num) => {
        const epicUpdaterStateUpdate = epicUpdatersStateUpdate[num];

        if (epicUpdaterStateUpdate === undefined) {
            return epicUpdterState;
        } else {
            return mergeUpdaterState(epicUpdterState, epicUpdaterStateUpdate);
        }
    });
}

function mergeEpic(epicState: EpicState, epicStateUpdate: EpicStateUpdate) {
    const result: EpicState = {
        updatersState: mergeUpdatersState(epicState.updatersState, epicStateUpdate.updatersState),
        state: epicStateUpdate.state === undefined ? epicState.state : epicStateUpdate.state,
        scope: epicStateUpdate.scope === undefined ? epicState.scope : epicStateUpdate.scope,
        lastUpdateReason: epicStateUpdate.lastUpdateReason === undefined ?
            epicState.lastUpdateReason : epicStateUpdate.lastUpdateReason
    };

    return result;
}

function mergeEpics(epicsState: EpicsState, epicsStateUpdate: EpicsStateUpdate) {
    const resultEpicsState = {};
    for (const key in epicsState) { // eslint-disable-line
        const updateEpic = epicsStateUpdate[key];

        if (updateEpic === undefined) {
            resultEpicsState[key] = epicsState[key];
        } else {
            resultEpicsState[key] = mergeEpic(epicsState[key], epicsStateUpdate[key]);
        }
    }

    return resultEpicsState;
}

export {
    mergeEpics
};
