import * as mobileView from '../../../../PropertiesPanel/view/MobileView/page';
import * as main from './pages/main';
import * as special from './pages/special';
import makePages from "../../../../PropertiesPanel/view/makePages";

const propertiesPanel = {
    pages: makePages(main, special, mobileView),
    defaultPageId: main.id,
};

export { propertiesPanel };
