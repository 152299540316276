import { createSelector } from 'reselect';
import * as RequestStatus from './constants/status';
import type { RequestState } from './flowTypes';

export const
    statusSelector = (state: RequestState) => state.status,
    isLoadingSelector = createSelector(
        statusSelector,
        status => status === RequestStatus.IN_PROGRESS
    ),
    isLoadedSelector = createSelector(
        statusSelector,
        status => status === RequestStatus.SUCCESS
    );
