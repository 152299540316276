import type { ApiAction, ApiActionTypes } from '../../../redux/middleware/api/flowTypes';
import CALL_API from '../../../redux/middleware/api/CALL_API';
import type { UpdateSiteDocumentsInput } from '../../../../dal/flowTypes';

export default (types: ApiActionTypes) => (docs: UpdateSiteDocumentsInput): ApiAction => {
    return ({
        [CALL_API]: {
            types,
            endpoint: 'updateSiteDocuments',
            endpointParams: docs
        }
    });
};
