import { applyMappers } from "../../utils";
import type { AnyComponentData } from "../../../../src/components/App/flowTypes";
import type { ComponentBaseWithKind } from "../../../../src/components/oneweb/flowTypes";
import { logoInitialState } from "../../../../src/components/oneweb/Logo/logoInitialState";
import { imagePlainPropsMapper } from "../Image/index";

export function to(
    componentData: AnyComponentData
): ComponentBaseWithKind {
    return applyMappers(
        componentData,
        imagePlainPropsMapper.to,
        ({ horizontalAlignment }) => (horizontalAlignment ? ({ logoHorizontalAlignment: horizontalAlignment }) : {}),
        ({ logoTitleScale }) => (logoTitleScale ? {} : { logoTitleScale: logoInitialState.logoTitleScale }),
    );
}

export function back(component: ComponentBaseWithKind): AnyComponentData {
    return applyMappers(
        component,
        imagePlainPropsMapper.back
    );
}
