import { GOOGLE_PREFIX } from '../../../../src/components/presentational/AddGoogleFont/constants';
import { isNumeric } from "../../../../utils/number";
import fontFamilies from "../../../../src/components/presentational/FontFamily/fontFamilies";

const isValidGoogleFont = fontFamily => /^[a-zA-Z0-9 _-]+$/.test(fontFamily);

export const
    gpRegexp = new RegExp(GOOGLE_PREFIX, 'ig'),
    to = (fontFamily: string|null): string|null => {
        if (fontFamily === null) {
            return fontFamilies[0];
        }
        const value = parseInt(fontFamily, 10);

        if (!isNaN(value)) {
            return fontFamilies[value];
        }

        let sanitizedFont = String(fontFamily);

        if (sanitizedFont.indexOf(GOOGLE_PREFIX) !== -1) {
            sanitizedFont = sanitizedFont.replace(gpRegexp, '').replace(/'/g, '');
            if (!isValidGoogleFont(sanitizedFont)) {
                sanitizedFont = fontFamilies[0];
            }
        }

        return sanitizedFont;
    },
    back = (fontFamily: string|null): string|number|null => {
        if (fontFamily === null || typeof fontFamily === 'undefined') {
            return null;
        } else if (isNumeric(fontFamily)) {
            return fontFamily;
        } else if (fontFamilies.indexOf(fontFamily) > -1) {
            return fontFamilies.indexOf(fontFamily);
        } else if (fontFamily.indexOf(GOOGLE_PREFIX) === -1) {
            if (!isValidGoogleFont(fontFamily)) {
                return null;
            }
            return `${GOOGLE_PREFIX}${fontFamily}`;
        } else if (fontFamily.indexOf(GOOGLE_PREFIX) > -1) {
            return GOOGLE_PREFIX + fontFamily.replace(gpRegexp, '');
        } else {
            return fontFamily;
        }
    };
