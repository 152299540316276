import React from "react";
import cx from "classnames";
import demoPageStyles from "../../main/demoLoginPage/DemoLoginPage.css";
import { DemoLoginMessages } from '../../main/messages';
import styles from "./ResetPassword.css";
import InputField from "../../../../../src/view/common/Input/InputField";
import Button from "../../../../../src/view/common/Button/Button";
import { PasswordChecker } from "../../main/PasswordChecker";
import { demoResetPasswordAction,
    updatePasswordValue,
    updateConfirmPasswordValue,
    toggleShowPassword,
    cleanUpResetPasswordScreen,
    cleanUpAllErrors,
    resetPasswordLoading,
    showInvalidPasswordError } from "../actions";
import { showSignInPage } from "../../signUp/actions";
import VerticalSpacer from "../../../../../src/view/common/VerticalSpacer";
import type { ResetPasswordPagePropTypes } from "../../types";
import { validateNoSpaces, isCompactScreen, isPasswordValid } from "../../validators";
import { getWbtgenTrialHostClientMetadata } from "../../common/getWbtgenTrialHostClientMetadata";

const ResetPassword = ({
    intl,
    dispatch,
    loading,
    password,
    confirmPassword,
    error,
    invalidPasswordError,
    showPasswordEnabled,
    isResetPasswordSuccessPage,
    isResetPasswordPage,
    username,
    verificationCode
}: ResetPasswordPagePropTypes) => {
    const
        scanForErrors = () => {
            const isPasswordsEqual = (password === confirmPassword);
            if (password && confirmPassword && (isPasswordsEqual) && isPasswordValid(password)) {
                return true;
            }
            if (!isPasswordValid(password)) {
                dispatch(showInvalidPasswordError());
            }
            return false;
        },
        initiateResetPassword = () => {
            if (scanForErrors()) {
                dispatch(cleanUpAllErrors());
                dispatch(resetPasswordLoading());
                dispatch(demoResetPasswordAction({
                    username: decodeURIComponent(username),
                    password,
                    verificationCode,
                    clientMetadata: getWbtgenTrialHostClientMetadata(),
                }));
            }
        },
        getVerticalSpacerValues = () => {
            let compactScreen = isCompactScreen();
            let
                VERTICAL_SPACE_BEFORE_EMAIL = compactScreen ? 20 : 49,
                VERTICAL_SPACE_BEFORE_RETURN_TO_LOGIN_BUTTON = compactScreen ? 142 : 284,
                VERTICAL_SPACE_AFTER_PASSWORD_CONTAINER = compactScreen ? 30 : 65;

            return {
                VERTICAL_SPACE_BEFORE_EMAIL,
                VERTICAL_SPACE_BEFORE_RETURN_TO_LOGIN_BUTTON,
                VERTICAL_SPACE_AFTER_PASSWORD_CONTAINER
            };
        };

    const
        {
            VERTICAL_SPACE_BEFORE_EMAIL,
            VERTICAL_SPACE_AFTER_PASSWORD_CONTAINER,
            VERTICAL_SPACE_BEFORE_RETURN_TO_LOGIN_BUTTON
        } = getVerticalSpacerValues();

    const {
        resetPasswordHeaderMsg,
        resetPasswordHeaderLabel,
        resetPasswordSuccessHeaderLabel,
        resetPasswordSuccessHeaderMsg,
        showLabel,
        hideLabel,
        confirmPasswordLabel,
        newPasswordLabel,
        passwordUpdatedLabel,
        returnToLoginLabel,
        resetPasswordLabel,
        unmatchedPasswordsError,
        customError,
    } = DemoLoginMessages;

    const resetPasswordPage = () => {
        return (
            <React.Fragment>
                <div>
                    <div className={styles.headerLabel}>
                        {intl.msgJoint(resetPasswordHeaderLabel)}
                    </div>
                    <div className={styles.headerMsg}>

                        {intl.msgJoint(resetPasswordHeaderMsg)}
                    </div>
                </div>
                <VerticalSpacer y={VERTICAL_SPACE_BEFORE_EMAIL} />
                <div>
                    <div className={styles.passwordContainer}>
                        <div className={styles.passwordLabelContainer}>
                            <div className={styles.inputLabel}>
                                {intl.msgJoint(newPasswordLabel)}
                            </div>
                            <div
                                className={styles.showLabel}
                                onClick={() => {
                                    dispatch(toggleShowPassword());
                                }}
                            >
                                {/* eslint-disable-next-line max-len */}
                                <span className={cx({ [styles.hideIcon]: showPasswordEnabled, [styles.showIcon]: !showPasswordEnabled })} />
                                {intl.msgJoint(showPasswordEnabled ? hideLabel : showLabel)}
                            </div>
                        </div>

                        <InputField
                            autoFocus
                            type={showPasswordEnabled ? 'text' : 'password'}
                            value={password}
                            onChange={(updatedPasswordValue) => {
                                dispatch(cleanUpAllErrors());
                                dispatch(updatePasswordValue(updatedPasswordValue));
                            }}
                            className={styles.inputField}
                            isInvalid={!validateNoSpaces(password) || invalidPasswordError}
                        />
                    </div>
                </div>
                <PasswordChecker
                    intl={intl}
                    password={password}
                />
                <div>
                    <div className={styles.actionsContainer}>
                        <div>
                            <div className={styles.passwordLabelContainer}>
                                <div className={styles.inputLabel}>
                                    {intl.msgJoint(confirmPasswordLabel)}
                                </div>
                            </div>
                            <div className={styles.confirmPasswordContainer}>
                                <InputField
                                    type={showPasswordEnabled ? 'text' : 'password'}
                                    value={confirmPassword}
                                    onChange={
                                        (updatedConfirmPasswordValue) => {
                                            dispatch(cleanUpAllErrors());
                                            dispatch(updateConfirmPasswordValue(updatedConfirmPasswordValue));
                                        }
                                    }
                                    onEnterPress={initiateResetPassword}
                                    className={styles.inputField}
                                />
                                { confirmPassword && password !== confirmPassword &&
                                <span className={styles.invalidEmailError}>
                                    {intl.msgJoint(unmatchedPasswordsError)}
                                </span>}
                                { (error) && <span className={styles.invalidEmailError}>
                                    {(error != null) && intl.msgJoint(customError)}
                                </span>}
                            </div>

                        </div>
                        <VerticalSpacer y={VERTICAL_SPACE_AFTER_PASSWORD_CONTAINER} />
                        <Button
                            onClick={initiateResetPassword}
                            className={cx(styles.resetPasswordButton, {
                                [styles.loadingButton]: loading
                            })}
                        >
                            {loading && <div className={demoPageStyles.loader} />}
                            {!loading && intl.msgJoint(resetPasswordLabel)}
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const resetPasswordSuccessPage = () => {
        return (
            <React.Fragment>
                <div>
                    <div className={styles.headerLabel}>
                        {intl.msgJoint(resetPasswordSuccessHeaderLabel)}
                    </div>
                    <div className={styles.headerMsg}>
                        <div>
                            {intl.msgJoint(resetPasswordSuccessHeaderMsg)}
                        </div>
                    </div>
                </div>
                <VerticalSpacer y={VERTICAL_SPACE_BEFORE_EMAIL} />
                <div>
                    <div>
                        <div className={styles.doneLabelContainer}>
                            <span className={styles.checkBoxIcon} />
                            <span className={styles.checkInboxLabel}>
                                {intl.msgJoint(passwordUpdatedLabel)}
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.actionsContainer}>
                        <div className={styles.returnToLoginContainer}>
                            <VerticalSpacer y={VERTICAL_SPACE_BEFORE_RETURN_TO_LOGIN_BUTTON} />
                            <Button
                                onClick={() => {
                                    dispatch(cleanUpResetPasswordScreen());
                                    dispatch(showSignInPage());
                                }}
                                className={styles.resetPasswordButton}
                            >
                                {intl.msgJoint(returnToLoginLabel)}
                            </Button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    return (
        <div>
            {isResetPasswordPage && resetPasswordPage()}
            {isResetPasswordSuccessPage && resetPasswordSuccessPage()}
        </div>
    );
};
export {
    ResetPassword
};
