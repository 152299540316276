export const GOOGLE_REVIEWS_REQUEST = 'GOOGLE_REVIEWS_REQUEST',
    GOOGLE_REVIEWS_SUCCESS = 'GOOGLE_REVIEWS_SUCCESS',
    GOOGLE_REVIEWS_FAILURE = 'GOOGLE_REVIEWS_FAILURE',
    ADD_GOOGLE_REVIEWS_COMPONENT = 'ADD_GOOGLE_REVIEWS_COMPONENT',
    REVIEW_WIDGET_CONFIG_SUCCESS = 'REVIEW_WIDGET_CONFIG_SUCCESS',
    RESET_REVIEW_WIDGET_CONFIG = 'RESET_REVIEW_WIDGET_CONFIG',
    CANCEL_REVIEW_WIDGET_CONFIG = 'CANCEL_REVIEW_WIDGET_CONFIG';

export const googleReviewsDataActionTypes = [
    GOOGLE_REVIEWS_REQUEST,
    GOOGLE_REVIEWS_SUCCESS,
    GOOGLE_REVIEWS_FAILURE
];
