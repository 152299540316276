import makeUuid from "../../../../../utils/makeUuid";
import { DataLinkPage, DataSite } from "../../../../../dal/model";
import type { LinkPageFormResult } from "../flowTypes";
import makeLinkPageUrl from "./makeLinkPageUrl";

export default (input: LinkPageFormResult, site: DataSite): DataLinkPage => {
    const
        { linkData: { link, openInNewWindow }, name, isHidden } = input,
        { url, linkId } = makeLinkPageUrl(link, site);
    return new DataLinkPage({
        id: makeUuid(),
        type: 'web.data.links.LinkExternal',
        name,
        url,
        hidden: isHidden,
        linkId,
        target: openInNewWindow ? '_blank' : '_self'
    });
};
