import { $Diff } from "utility-types";
import React from 'react';
import type { FontFamilySelectorProps } from "../../../presentational/FontFamily/flowTypes";
import dispatchFunctions from "../dispatchFunctions";
import addGoogleFontView from "../AddGoogleFont/index";
import FontFamilySelector from "../../../presentational/FontFamily/index";

type Props = $Diff<FontFamilySelectorProps, {
    connectedSiteFonts: Array<string>,
    onAddFontClicked?: Function,
}>
export const PropertiesPanelFontFamilySelector = (props: Props) => {
    const
        { navigateToPage } = dispatchFunctions(props.dispatch),
        onAddFontClicked = () => navigateToPage(
            addGoogleFontView.id,
            { source: props.onChangeAction },
        );

    return (
        <FontFamilySelector onAddFontClicked={onAddFontClicked} {...props} />
    );
};

