import * as R from 'ramda';
import defaultUserInteractionState from "./userInteractionMutations/defaultUserInteractionState";
import { componentsMapExtensionPath, componentsMapFromTransientMutationHandlerPath,
    componentsMapPath, movingComponentsPath,
    stateBeforeTransientChangesPath, userInteractionComponentsIdsPath, userInteractionModePath,
    userInteractionPath, userInteractionPayloadNewMousePosition, userInteractionPayloadPath, pathToPushDownHappened,
    userInteractionComponentDuplicatedPath,
    sectionInsertionPath,
    selectedComponentsIdsPath,
    sectionInsertionProgressPath,
    mhfCmpDataPath } from "./paths";
import { componentsEvalDefaultState } from "./constants";

const
    takeDefault = (path) => R.path(path, componentsEvalDefaultState);

export const
    setComponentsMap = R.assocPath(componentsMapPath),
    setComponentsMapFromTransientMutationHandler = R.assocPath(componentsMapFromTransientMutationHandlerPath),
    setPushDownHappened = R.assocPath(pathToPushDownHappened),
    setComponentsMapExtension = R.assocPath(componentsMapExtensionPath),
    setStateBeforeTransientChanges = R.assocPath(stateBeforeTransientChangesPath),
    setSelectedComponentsIds = R.assocPath(selectedComponentsIdsPath),
    setUserInteraction = R.assocPath(userInteractionPath),
    setUserInteractionMode = R.assocPath(userInteractionModePath),
    setUserInteractionPayload = R.assocPath(userInteractionPayloadPath),
    setUserInteractionPayloadNewMousePosition = R.assocPath(userInteractionPayloadNewMousePosition),
    setUserInteractionComponentsIds = R.assocPath(userInteractionComponentsIdsPath),
    setSectionInsertion = R.assocPath(sectionInsertionPath),
    setSectionInsertionProgressState = R.assocPath(sectionInsertionProgressPath),
    setUserInteractionComponentsDuplicated = R.assocPath(userInteractionComponentDuplicatedPath, true),
    setDefaultUserInteraction = setUserInteraction(defaultUserInteractionState),
    setMHFCmpsData = R.assocPath(mhfCmpDataPath),
    resetScope = R.assoc('scope', componentsEvalDefaultState ? componentsEvalDefaultState.scope : {}),
    resetComponentsMapFromTransientMutationHandler = setComponentsMapFromTransientMutationHandler(
        takeDefault(componentsMapFromTransientMutationHandlerPath)
    ),
    resetStateBeforeTransientChanges = setStateBeforeTransientChanges(
        takeDefault(stateBeforeTransientChangesPath)
    ),
    setMovingComponents = R.assocPath(movingComponentsPath);
