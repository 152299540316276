import * as R from 'ramda';
import queryMap from "../reducer/queryMap.json";
import { makePlainMappers, applyMappers } from '../../../../../dal/pageMapAdapter/utils';
import * as colorMapper from '../../../../mappers/color';
import fontMap from './fontMap.json';
import type { QueryData, getQueryDataFunctionParams } from '../flowTypes';
import { getFontName, isGoogleFont } from "../../../presentational/AddGoogleFont/utils";
import { GOOGLE_PREFIX } from "../../../presentational/AddGoogleFont/constants";
import { CommonMapperKeys } from "../constants";

const plainMapper = makePlainMappers({ ...queryMap, ...CommonMapperKeys });

function isGoogleFontWithoutPrefix(font, siteFonts) {
    return siteFonts.indexOf(font) > -1;
}

function getPreviewFontName(font, siteFonts) {
    if (isGoogleFont(font)) {
        return font;
    }
    if (isGoogleFontWithoutPrefix(font, siteFonts)) {
        return GOOGLE_PREFIX + font;
    }

    return fontMap[font] || font;
}

function makeColorFontMapper(siteFonts) {
    return (component) => {
        return R.filter(v => v, {
            ...[
                'focusColor',
                'labelBgColor',
                'labelTextColor',
                'promoRibbonBgColor',
                'promoRibbonTextColor',
                'fontColor'
            ].reduce((d, prop) => {
                if (!component[prop]) {
                    return d;
                }
                const map = d;
                map[prop] = colorMapper.toHex(component[prop]).replace('#', '');
                return map;
            }, {}),
            ...{
                font: getPreviewFontName(component.font, siteFonts),
                hoverColor: component.hoverColor ? colorMapper.toCss(component.hoverColor) : null,
                labelPosition: component.labelPosition
            }
        });
    };
}

export default function getQueryData(
    {
        component,
        buttonStyle,
        buyNowButtonStyle,
        linkStyle,
        locale,
        siteFonts,
        mobileView,
        buttonClassname,
        buyNowButtonClassName,
        linkClassName,
        buyButtonText,
        buyNowButtonText,
        enableBuyNowButton,
        isWorkspace,
        isPaginationEnabled,
        timestamp = 0
    }: getQueryDataFunctionParams
): QueryData {
    return applyMappers(
        {
            ...component,
            buttonClassname,
            buyNowButtonClassName,
            linkClassName,
            buyButtonText,
            buyNowButtonText,
            enableBuyNowButton,
            isPaginationEnabled,
            buttonStyle,
            buyNowButtonStyle,
            linkStyle,
            locale,
            mobileView,
            isWorkspace,
            timestamp,
            siteFonts
        },
        plainMapper.to,
        makeColorFontMapper(siteFonts.map(font => getFontName(font)))
    );
}
