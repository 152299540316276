import React from "react";
import cx from "classnames";
import styles, { curveDistanceValue } from "./BorderLines.css";

const calculateBorderStyles = (containerSize: number, gradientSize: number): Record<string, number> => {
    const diff = Math.round((((containerSize / gradientSize) % 1) - 0.5) * gradientSize),
        scale = 1 + (diff / containerSize);
    return {
        alterSize: diff,
        scale
    };
};

type BorderProps = {
    width: number,
    height: number,
    children?: any,
    className?: string,
    size?: number,
    color?: string,
    zIndex?: number,
    curved?: boolean
}

const getBorderStyles = (props) => {
    let verticalStyles = {}, horizontalStyles = {};
    const { height, width, color = styles.gradientColor, size, curved } = props;
    const gradientSize = parseInt(size || styles.borderBGSize, 10),
        change = curved ? curveDistanceValue * 2 : 0;
    if (width && height) {
        const vertical = calculateBorderStyles(height - change, gradientSize);
        verticalStyles = {
            height: `calc(100% - ${vertical.alterSize}px - ${change}px)`,
            transform: `scale(1, ${vertical.scale})`,
            backgroundSize: `100% ${gradientSize}px`,
            backgroundImage: `linear-gradient(to bottom, ${color} 50%, transparent 50%)`
        };

        const horizontal = calculateBorderStyles(width - change, gradientSize);
        horizontalStyles = {
            width: `calc(100% - ${horizontal.alterSize}px - ${change}px)`,
            transform: `scale(${horizontal.scale}, 1)`,
            backgroundSize: `${gradientSize}px 100%`,
            backgroundImage: `linear-gradient(to right, ${color} 50%, transparent 50%)`
        };
    }
    return { verticalStyles, horizontalStyles };
};

export const BorderLines = React.forwardRef(
    (props: BorderProps, ref: React.ForwardedRef<any>) => {
        const { zIndex, curved = false } = props;
        let { verticalStyles, horizontalStyles } = getBorderStyles(props);

        return <div ref={ref} className={cx(styles.container, props.className, { [styles.curved]: curved })} style={{ zIndex }}>
            <div className={cx(styles.horizontal, styles.top)} style={horizontalStyles} />
            <div className={cx(styles.vertical, styles.right)} style={verticalStyles} />
            <div className={cx(styles.horizontal, styles.bottom)} style={horizontalStyles} />
            <div className={cx(styles.vertical, styles.left)} style={verticalStyles} />
            {props.children}
        </div>;
    }
);
