import React from 'react';

import gstyles from '../../../view/global.css';
import WarningDialog from '../../../view/common/dialogs/WarningDialog';
import { Msg } from '../../../view/intl';
import makeAppStandardErrorDialog from "../../App/dialogs/makeAppStandardErrorDialog";

const PageTemplatesLoadFailureDialog = () => (
    <WarningDialog
        title="msg: common.errorOccurred {An error occurred}"
        mctaText="msg: common.ok {OK}"
    >
        <div>
            <Msg k="common.unableToFetchTemplates" className={gstyles.bold}>
                Unable to get templates.
            </Msg>
        </div>
        <div>
            <Msg k="common.tryAgainOrContactSupport">
                Please try again later or contact our support for further assistance.
            </Msg>
        </div>
    </WarningDialog>
);

export default makeAppStandardErrorDialog(PageTemplatesLoadFailureDialog);
