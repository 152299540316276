import { makeEpic } from "../../../../epics/makeEpic";
import { ComponentsMapSelector } from '../../../Workspace/epics/componentsEval/selectorActionTypes';
import { getHeaderSection, getFooterSection } from "../utils";
import { Lit } from '../../../../lit';

export type HeaderFooterSectionsPosition = {
    headerSection: {
        top: number,
        height: number,
    },
    footerSection: {
        top: number,
        height: number,
    }
}

const defaultState = {
    [Lit.headerSection]: {},
    [Lit.footerSection]: {},
};

export const headerFooterSectionsPositionVAT = "HEADER_FOOTER_SECTIONS_POSITION";

export const headerFooterSectionsPositionEpic = makeEpic({
    defaultState,
    valueActionType: headerFooterSectionsPositionVAT,
    updaters: [
        {
            conditions: [ComponentsMapSelector],
            reducer: ({ values: [componentsMap] }) => {
                const headerSection = getHeaderSection(componentsMap),
                    footerSection = getFooterSection(componentsMap);
                if (!headerSection || !footerSection) {
                    return { state: defaultState };
                }
                return {
                    state: {
                        [Lit.headerSection]: {
                            top: headerSection.top,
                            height: headerSection.height,
                        },
                        [Lit.footerSection]: {
                            top: footerSection.top,
                            height: footerSection.height,
                        }
                    }
                };
            },
        },
    ],
});
