import { COMPONENTS_ADD_NOT_ALLOWED } from "../actionTypes";
import type { ComponentAddError } from "../flowTypes";

const getAddComponentsNotAllowedAction = (failedComponentsErrors: Array<ComponentAddError>) => ({
    type: COMPONENTS_ADD_NOT_ALLOWED,
    payload: failedComponentsErrors
});

export {
    getAddComponentsNotAllowedAction,
    getAddComponentsNotAllowedAction as default
};
