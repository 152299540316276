import type { Groups, Sequence } from "./flowTypes";
import type { ComponentsMap } from "../../redux/modules/children/workspace/flowTypes";
import { getGroupTypeById } from "./util";

const updateSequenceWithNewGroups = (sequence, matchesNewToOld) => {
    let newSequence = {};
    const getMatchingId = (id) => matchesNewToOld[id] || id;
    Object.keys(sequence).forEach(parentId => {
        const newParentId = getMatchingId(parentId);
        newSequence[newParentId] = [];
        sequence[parentId].forEach(childId => {
            newSequence[newParentId].push(getMatchingId(childId));
        });
    });
    return newSequence;
};

const isOldAndNewMatch = (oldGroups, newGroups, oldGroupId, newGroupId, componentsMap) => {
    let deletedCmpsCount = 0, matchedCmpCount = 0;
    oldGroups[oldGroupId].forEach(cmpId => {
        if (!componentsMap[cmpId]) {
            deletedCmpsCount++;
        }
        if (newGroups[newGroupId].indexOf(cmpId) > -1) {
            matchedCmpCount++;
        }
    });
    return (matchedCmpCount > 0) && ((deletedCmpsCount + matchedCmpCount) === oldGroups[oldGroupId].length);
};

// new group matches with old if and only if all cmps in the old group is in new(sequence can be different)
// with 2 exceptions
// 1. some cmps are missing and they are missing from cmpsmap
// 2. some extra cmps may come

export default (oldGroups: Groups, newGroups: Groups, componentsMap: ComponentsMap, defaultSequence: Sequence) => {
    let matchesNewToOld = {}, updatedNewGroups = {};

    Object.keys(oldGroups).forEach(oldGroupId => {
        Object.keys(newGroups).forEach(newGroupId => {
            if (
                !matchesNewToOld[newGroupId] &&
                getGroupTypeById(newGroupId) === getGroupTypeById(oldGroupId) &&
                isOldAndNewMatch(oldGroups, newGroups, oldGroupId, newGroupId, componentsMap)
            ) {
                matchesNewToOld[newGroupId] = oldGroupId;
            }
        });
    });
    Object.keys(newGroups).forEach(newGroupId => {
        if (matchesNewToOld[newGroupId]) {
            updatedNewGroups[matchesNewToOld[newGroupId]] = newGroups[newGroupId];
            return;
        }
        updatedNewGroups[newGroupId] = newGroups[newGroupId];
    });
    return {
        newGroups: updatedNewGroups,
        newSequence: updateSequenceWithNewGroups(defaultSequence, matchesNewToOld)
    };
};
