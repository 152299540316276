/* eslint-disable */

import React from "react";
import BaseComponent from "../../../../view/oneweb/BaseComponent";
import FacebookPageLocaleWrapper from './FacebookPageLocaleWrapper';
import type { FacebookPageCalcRenderPropsResult } from "../flowTypes";

export default (props: FacebookPageCalcRenderPropsResult) => (
    <BaseComponent {...props}>
        <FacebookPageLocaleWrapper {...props}/>
    </BaseComponent>
)
