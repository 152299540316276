import {
    SAVE_DESIGN_LOCALIZATIONS_REQUEST,
    SAVE_DESIGN_LOCALIZATIONS_SUCCESS,
    SAVE_DESIGN_LOCALIZATIONS_FAILURE
} from "./actionTypes";
import CALL_API from "../../../../redux/middleware/api/CALL_API";

export const saveDesignLocalizationsAC = (i18n: Record<string, any>) => ({
    [CALL_API]: {
        types: [
            SAVE_DESIGN_LOCALIZATIONS_REQUEST,
            SAVE_DESIGN_LOCALIZATIONS_SUCCESS,
            SAVE_DESIGN_LOCALIZATIONS_FAILURE
        ],
        endpoint: 'saveDesignLocalizations',
        endpointParams: i18n
    }
});
