import React from "react";
import cx from "classnames";
import { Flex } from "../reflexbox/index";
import styles from "./logo.css";

type OnecomLogoProps = {
    large ?: true;
    className?: string;
}

export const OnecomLogo = (props: OnecomLogoProps) => {
    const { large = false, className } = props,
        containerCN = large ? styles.logoContainerLarge : styles.logoContainer,
        logoCN = large ? styles.large : styles.normal;

    return (
        <Flex align="center" justify="center" className={cx(containerCN, className)}>
            <div className={cx(styles.logo, logoCN)} />
        </Flex>
    );
};
