import { getAppConfig } from "./components/App/epics/appConfig/appConfig";
import { FeatureManager } from "../../server/shared/featureManager/FeatureManager";

let featureManager: FeatureManager = null;

const getFeatureManager = () => {
    if (featureManager) return featureManager;
    const rawAppConfig = getAppConfig();
    const featuresConfig = rawAppConfig.features;
    featureManager = new FeatureManager(featuresConfig);

    return featureManager;
};

export default getFeatureManager;
