import { $Diff } from 'utility-types';

import {
    getListOfPageLayoutsAction,
    // getRepositorySiteDataApiAction,
    getTemplateDataSetApiAction,
    getTemplatesWithPagesApiAction,
} from '../../../redux/middleware/api/commonApiActions';
import type { PageTemplateSelectorDialogProps } from "../PageTemplateSelector/types";
import { openPageTemplateSelectorAction } from "../PageTemplateSelector/actions";

export const
    NEW_PAGE_DIALOG_OPEN = 'NEW_PAGE_DIALOG_OPEN',
    NEW_PAGE_SWITCH_TAB = 'NEW_PAGE_SWITCH_TAB',
    NEW_PAGE_LAYOUT_ADD = 'NEW_PAGE_LAYOUT_ADD',
    NEW_PAGE_LAYOUT_PAGE_CREATED = 'NEW_PAGE_LAYOUT_PAGE_CREATED',
    NEW_PAGE_LAYOUT_SELECTED = 'NEW_PAGE_LAYOUT_SELECTED',
    NEW_PAGE_TEMPLATE_SAVED = 'NEW_PAGE_TEMPLATE_SAVED',
    NEW_PAGE_TEMPLATE_CANCELLED = 'NEW_PAGE_TEMPLATE_CANCELLED',
    NEW_PAGE_LAYOUT_NAME_ENTERED = 'NEW_PAGE_LAYOUT_NAME_ENTERED',
    NEW_PAGE_LINK_ADD = 'NEW_PAGE_LINK_ADD',
    NEW_PAGE_LINK_SET_LINK_TO_FILE_OR_PAGE = 'NEW_PAGE_LINK_SET_LINK_TO_FILE_OR_PAGE',
    NEW_PAGE_LINK_UNSET_LINK_TO_FILE_OR_PAGE = 'NEW_PAGE_LINK_UNSET_LINK_TO_FILE_OR_PAGE',
    NEW_PAGE_LINK_OPEN_IN_NEW_WINDOW_TOGGLE = 'NEW_PAGE_LINK_OPEN_IN_NEW_WINDOW_TOGGLE',
    NEW_PAGE_LINK_URL_CHANGE = 'NEW_PAGE_LINK_URL_CHANGE',
    NEW_PAGE_LINK_NAME_CHANGE = 'NEW_PAGE_LINK_NAME_CHANGE',
    NEW_PAGE_LINK_IS_HIDDEN_TOGGLE = 'NEW_PAGE_LINK_IS_HIDDEN_TOGGLE';

export const
    NEW_SECTION_LINK_ADD = 'NEW_SECTION_LINK_ADD',
    NEW_SECTION_LINK_NAME_CHANGE = 'NEW_SECTION_LINK_NAME_CHANGE',
    NEW_SECTION_LINK_PAGE_CHANGE = 'NEW_SECTION_LINK_PAGE_CHANGE',
    NEW_SECTION_LINK_SECTION_CHANGE = 'NEW_SECTION_LINK_SECTION_CHANGE';

export const
    LOAD_PAGE_LAYOUTS_SITE_REQUEST_ACTION = 'LOAD_PAGE_LAYOUTS_SITE_REQUEST_ACTION',
    LOAD_PAGE_LAYOUTS_SITE_SUCCESS_ACTION = 'LOAD_PAGE_LAYOUTS_SITE_SUCCESS_ACTION',
    LOAD_PAGE_LAYOUTS_SITE_FAILURE_ACTION = 'LOAD_PAGE_LAYOUTS_SITE_FAILURE_ACTION',

    // LOAD_NEW_PAGE_LAYOUT_REPOSITORY_SITE_REQUEST_ACTION = 'LOAD_NEW_PAGE_LAYOUT_REPOSITORY_SITE_REQUEST_ACTION',
    // LOAD_NEW_PAGE_LAYOUT_REPOSITORY_SITE_SUCCESS_ACTION = 'LOAD_NEW_PAGE_LAYOUT_REPOSITORY_SITE_SUCCESS_ACTION',
    // LOAD_NEW_PAGE_LAYOUT_REPOSITORY_SITE_FAILURE_ACTION = 'LOAD_NEW_PAGE_LAYOUT_REPOSITORY_SITE_FAILURE_ACTION',

    // loadNewPageLayoutRepositorySiteAction = () => getRepositorySiteDataApiAction({
    //     types: [
    //         LOAD_NEW_PAGE_LAYOUT_REPOSITORY_SITE_REQUEST_ACTION,
    //         LOAD_NEW_PAGE_LAYOUT_REPOSITORY_SITE_SUCCESS_ACTION,
    //         LOAD_NEW_PAGE_LAYOUT_REPOSITORY_SITE_FAILURE_ACTION
    //     ]
    // });

    loadPageLayoutsSiteAction = () => getListOfPageLayoutsAction({
        types: [
            LOAD_PAGE_LAYOUTS_SITE_REQUEST_ACTION,
            LOAD_PAGE_LAYOUTS_SITE_SUCCESS_ACTION,
            LOAD_PAGE_LAYOUTS_SITE_FAILURE_ACTION
        ]
    });

export const
    LOAD_NEW_PAGE_LAYOUT_TEMPLATES_REQUEST_ACTION = 'LOAD_NEW_PAGE_LAYOUT_TEMPLATES_REQUEST_ACTION',
    LOAD_NEW_PAGE_LAYOUT_TEMPLATES_SUCCESS_ACTION = 'LOAD_NEW_PAGE_LAYOUT_TEMPLATES_SUCCESS_ACTION',
    LOAD_NEW_PAGE_LAYOUT_TEMPLATES_FAILURE_ACTION = 'LOAD_NEW_PAGE_LAYOUT_TEMPLATES_FAILURE_ACTION',
    loadNewPageLayoutTemplatesApiAction = () => getTemplatesWithPagesApiAction({
        types: [
            LOAD_NEW_PAGE_LAYOUT_TEMPLATES_REQUEST_ACTION,
            LOAD_NEW_PAGE_LAYOUT_TEMPLATES_SUCCESS_ACTION,
            LOAD_NEW_PAGE_LAYOUT_TEMPLATES_FAILURE_ACTION
        ]
    });

export const
    LOAD_NEW_PAGE_LAYOUT_TEMPLATE_SET_REQUEST_ACTION = 'LOAD_NEW_PAGE_LAYOUT_TEMPLATE_SET_REQUEST_ACTION',
    LOAD_NEW_PAGE_LAYOUT_TEMPLATE_SET_SUCCESS_ACTION = 'LOAD_NEW_PAGE_LAYOUT_TEMPLATE_SET_SUCCESS_ACTION',
    LOAD_NEW_PAGE_LAYOUT_TEMPLATE_SET_FAILURE_ACTION = 'LOAD_NEW_PAGE_LAYOUT_TEMPLATE_SET_FAILURE_ACTION',
    loadNewPageLayoutTemplateSetApiAction = (templateId: string) => getTemplateDataSetApiAction({
        types: [
            LOAD_NEW_PAGE_LAYOUT_TEMPLATE_SET_REQUEST_ACTION,
            LOAD_NEW_PAGE_LAYOUT_TEMPLATE_SET_SUCCESS_ACTION,
            LOAD_NEW_PAGE_LAYOUT_TEMPLATE_SET_FAILURE_ACTION
        ],
        templateId
    });

export const
    NEW_PAGE_TEMPLATE_SELECTED_ACTION = 'NEW_PAGE_TEMPLATE_SELECTED_ACTION',
    newPageTemplateSelectedAction = (id: string) => ({
        type: NEW_PAGE_TEMPLATE_SELECTED_ACTION,
        payload: id
    });

export const
    openAddLayoutPageTemplateSelectorAction = (
        params: $Diff<PageTemplateSelectorDialogProps, { onSaveAction: string }> = {}
    ) =>
        openPageTemplateSelectorAction({
            ...params,
            onSaveAction: NEW_PAGE_TEMPLATE_SAVED
        });

export const
    CLOSE_NEW_PAGE_NAME_DIALOG_ACTION = 'CLOSE_NEW_PAGE_NAME_DIALOG_ACTION',
    closeNewPageNameDialogAction = () => ({
        type: CLOSE_NEW_PAGE_NAME_DIALOG_ACTION
    });
