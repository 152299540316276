import CALL_API from '../../../redux/middleware/api/CALL_API';
import { siteSubscriptionStatusBackupRestoreDataLoadActionTypes } from '../actionTypes';

export default function (): any {
    return {
        [CALL_API]: {
            types: siteSubscriptionStatusBackupRestoreDataLoadActionTypes,
            endpoint: 'getSubscriptionStatusBackupRestore'
        }
    };
}
