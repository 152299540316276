import { findRelIn } from "./calcRelations";
import type { ComponentsMap, AnyComponent } from "../../../../redux/modules/children/workspace/flowTypes";
import { getCmpsTreeByCmpsMap } from "./rBushTree";
import { maxLeftOrRightForStretchedKind } from "./constants";

export const
    calcRelIn = (
        selectedComponents: Array<AnyComponent>,
        componentsMap: ComponentsMap,
        state: any,
        templateWidth: number,
        allComponents?: boolean,
        onlyPageComponents: boolean = false,
    ) => {
        let newComponentsMap = componentsMap;
        if (onlyPageComponents) {
            newComponentsMap = Object.keys(componentsMap).reduce((acc, id) => {
                if (!componentsMap[id].inTemplate) {
                    acc[id] = componentsMap[id];
                }
                return acc;
            }, {});
        }
        const { tree } = getCmpsTreeByCmpsMap(
                newComponentsMap,
                maxLeftOrRightForStretchedKind * -1,
                maxLeftOrRightForStretchedKind + templateWidth
            ),
            { changes, relInChanged } = findRelIn(
                tree,
                selectedComponents,
                state,
                newComponentsMap,
                allComponents,
                templateWidth,
            );
        return relInChanged ? { changes } : state;
    };
