import { DataSite } from "../../../../../dal/model";
import type { DataSiteItem } from "../../../../../dal/model/utils/dataSiteItemUtils"; // eslint-disable-line
import { isPageRef } from "../../../../../dal/model/utils/dataSiteItemUtils";

export default (site: DataSite, targetPage: DataSiteItem): DataSite => {
    const newSite = new DataSite(site); // clone site
    let page;
    const findPage = (pages) => {
        pages.forEach(p => {
            if (page) {
                return;
            }
            if (p.id === targetPage.id) {
                page = p;
            } else if (isPageRef(p)) {
                findPage(p.items);
            }
        });
    };

    findPage(newSite.getItems());
    page.hidden = !targetPage.hidden;
    if (!page.hidden) {
        page.public = true;
    }
    return newSite;
};
