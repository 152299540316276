import * as R from 'ramda';
import p from "../utils/pipePath";
import * as mp from "../mappers/path";
import { getByPath } from "../utils/ramdaEx";
import type { Path } from "../mappers/path"; // eslint-disable-line

export const
    getGradientDirection = R.curry((gradientPath: Path, data: any) =>
        getByPath(p(gradientPath, mp.degree), data)),
    getGradientFadePoint = R.curry((gradientPath: Path, data: any) =>
        getByPath(p(gradientPath, mp.fadePoint), data)),
    getBackgroundGradientThemeColor = R.curry((backgroundPath: Path, data: any) => {
        return getByPath(p(backgroundPath, mp.colorData, mp.gradient, 'themeColor'), data);
    }),
    getBackgroundGradientColor = R.curry((backgroundPath: Path, data: any) => {
        return getByPath(p(backgroundPath, mp.colorData, mp.gradient, mp.color), data);
    });

