import * as pagesIds from './pagesIds';
import * as mobileView from '../../../PropertiesPanel/view/MobileView/page';
import GALLERY from "../kind";
import { ComponentNames } from "../../constants";

export default {
    [pagesIds.MAIN]: ComponentNames[GALLERY],
    [pagesIds.ONCLICK]: "msg: component.gallery.propPanel.onClick.title {On click}",
    [pagesIds.CAPTIONS]: "msg: component.gallery.propPanel.captions.title {Captions}",
    [pagesIds.CAPTIONS_TITLE_STYLE]: "msg: common.titleStyle {Title style}",
    [pagesIds.CAPTIONS_DESCRIPTION_STYLE]: "msg: component.gallery.propPanel.captionsDescriptionStyle.title {Description style}", // eslint-disable-line
    [pagesIds.CAPTIONS_BOX_STYLE]: "msg: component.gallery.propPanel.captions.captionBox {Caption box}",
    [pagesIds.IMAGE_STYLES]: 'msg: component.gallery.propPanel.imageStyle.title {Image style}',
    [pagesIds.FULL_WIDTH]: 'msg: common.fullWidth {Full width}',
    [mobileView.id]: mobileView.title
};
