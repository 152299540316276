import siteDataEpic from "../../App/epics/siteData/index";
import currentPageIdEpic from "../../App/epics/currentPageId/index";
import stylesheetsEpic from "../../Workspace/epics/stylesheets/index";
import stylesheetsIdToNameMapEpic from "../../Workspace/epics/stylesheets/idToNameMap";
import kind from "./kind";
import reducer from "./reducer";
import workspace from "./view/workspace";
import propertiesPanel from "./propertiesPanel/index";
import defaultStyleIdEpic from "./epics/defaultStyleId";
import adjustComponentOnAdd from "./adjustComponentOnAdd";
import adjustComponentOnPaste from "./adjustComponentOnPaste";
import adjustmentHookConfig from "./adjustmentHookConfig";
import componentMainActions from "./componentMainActions/index";
import { ComponentNames } from "../constants";
import { globalVariablesEpic } from "../../App/epics/globalVariablesEpic";
import type { ChangeOldComponentDataBeforeImportProps } from "../../../view/oneweb/flowTypes";
import "../../../view/oneweb/flowTypes";
import type { MenuComponent } from "./flowTypes";
import "./flowTypes";
import calcRenderProps from "./calcRenderProps";
import { colorThemeSiteSettingsEpic } from "../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import { componentsInHeaderFooterSectionsEpic } from "../Section/epics/componentsInHeaderFooterSectionsEpic";
import { selectedComponentIsInsideHeaderOrFooterEpic } from "../../Workspace/epics/selectedComponentIsInsideHeaderOrFooterEpic/index";
import { isModernLayoutActivatedEpic } from "../../Workspace/epics/isModernLayoutActivatedEpic/index";
import makeStateSelectorReducer from "../../../epics/makeStateSelectorReducer";
import templateEpic from "../Template/epics/template/index";
import templateEpicValueActionType from "../Template/epics/template/valueActionType"; // TODO menu more button should be localizible
import MenuEpic from "./epics/service";

export default {
    kind,
    workspace,
    label: ComponentNames[kind],
    shortcutIconName: "menu",
    propertiesPanel,
    reducer,
    dependsOn: {
        menuState: MenuEpic.reducer,
        siteData: siteDataEpic.reducer,
        currentPageId: currentPageIdEpic.reducer,
        defaultStyleId: defaultStyleIdEpic.reducer,
        globalstyles: stylesheetsEpic.reducer,
        stylesheetsIdToNameMap: stylesheetsIdToNameMapEpic.reducer,
        globalVariables: globalVariablesEpic.reducer,
        themeSettingsData: colorThemeSiteSettingsEpic.reducer,
        templateStyle: makeStateSelectorReducer(
            templateEpic.reducer,
            templateEpicValueActionType,
            template =>
                (template && {
                    ...template.style,
                    selectedTheme: template.selectedTheme,
                    selectedGradientTheme: template.selectedGradientTheme
                }) ||
                null
        ),
        componentsInHeaderFooterSections: componentsInHeaderFooterSectionsEpic.reducer,
        isModernLayoutActivated: isModernLayoutActivatedEpic.reducer,
        selectedComponentIsInsideHeaderOrFooter: selectedComponentIsInsideHeaderOrFooterEpic.reducer
    },
    adjustComponentOnAdd,
    adjustComponentOnPaste,
    adjustmentHookConfig,
    componentMainActions,
    calcRenderProps,
    changeOldComponentDataBeforeImport: ({ component, globalVariables }: ChangeOldComponentDataBeforeImportProps<MenuComponent>) => {
        const result = { ...component, moreText: globalVariables[component.moreText] || component.moreText };
        return result;
    }
};
