import React from "react";
import { useDispatch } from "react-redux";

import { useIntl } from "../../../../../view/intl";

import { Dialog } from '../../../../../view/common/dialogs/baseDialog';
import { StripTypes } from "../../../../../view/common/dialogs/baseDialog/Dialog";

import { PrimaryButton, SecondaryButton } from "../../../../../view/common/Button";

import { closeDialog } from "../../../../App/actionCreators";
import { SEE_ALL_TEMPLATES_CLICKED } from "../../../actionTypes";
import getCenteredDialog from "../../../../DialogManager/getCenteredDialogConfig";

import styles from "./ErrorModel.css";

export const ContinueToTemplatesDialog = () => {
    const dispatch = useDispatch();
    const intl = useIntl();

    const handleClose = () => {
        dispatch(closeDialog());
    };

    return (
        <Dialog
            stripType={StripTypes.NONE}
            containerClassName={styles.standardContainer}
            onClose={handleClose}
        >
            <div className={styles.standardDialogContent}>
                <div className={styles.continueToTemplatesLogo} />
                <div className={styles.smTitle}>
                    {intl.msgJoint("msg: onboarding.dynamic.resetOnboarding.title {Reset progress?}")}
                </div>
                <div className={styles.smDescription}>
                    <p>{
                        // eslint-disable-next-line max-len
                        intl.msgJoint("msg: onboarding.dynamic.resetOnboarding.description {If you proceed to the template list and select a different template, we won\'t be able to generate customised texts for you.}")
                    }</p>
                    <p className={styles.smSubDescription}>{
                        // eslint-disable-next-line max-len
                        intl.msgJoint("msg: onboarding.dynamic.resetOnboarding.confirmation {Are you sure you want to continue?}")
                    }</p>
                </div>
            </div>

            <div className={styles.standardButtonContainer}>
                <SecondaryButton
                    className={styles.button}
                    onClick={handleClose}
                >
                    {intl.msgJoint("msg: common.cancel {Cancel}")}
                </SecondaryButton>
                <PrimaryButton
                    className={styles.button}
                    onClick={() => {
                        dispatch(closeDialog());
                        dispatch({ type: SEE_ALL_TEMPLATES_CLICKED });
                    }}
                >
                    {intl.msgJoint("msg: common.continue {Continue}")}
                </PrimaryButton>
            </div>

        </Dialog>
    );
};

const config = getCenteredDialog({
    width: 700,
    height: 364,
    component: ContinueToTemplatesDialog,
    dragEnabled: false
});

const CONTINUE_TO_TEMPLATES_DIALOG = "CONTINUE_TO_TEMPLATES_DIALOG";

export {
    config,
    CONTINUE_TO_TEMPLATES_DIALOG
};
