import makeEpic from '../../../epics/makeEpic';
import * as actionTypes from './actionTypes';

export type SeoGlobalDataStateType = {
    enableSeo: boolean
}

export const seoGlobalDataDefaultState: SeoGlobalDataStateType = {
    enableSeo: true
};

const seoGlobalDataEpic = makeEpic({
    defaultState: seoGlobalDataDefaultState,
    valueActionType: 'SEO_GLOBAL_DATA_EPIC_VALUE',
    saveIntoSiteSettings: { key: 'seoData' },
    undo: true,
    updaters: [
        {
            conditions: [actionTypes.ENABLE_SEO_TOGGLE],
            reducer: ({ state }) => {
                const
                    { enableSeo: currentSeoVal } = state,
                    enableSeo = !currentSeoVal;

                return {
                    state: { ...state, enableSeo },
                    updateReason: actionTypes.ENABLE_SEO_TOGGLE
                };
            }
        },
    ]
});

export {
    seoGlobalDataEpic
};
