import { VideoSettings, VideoUrlPrefixWithS, VideoUrlPrefixPattern } from './constants';
import {
    settingsSelector,
    videoIdSelector,
    getSettingValue
} from "./selector";
import type { VideoComponent } from "./flowTypes";

export const youtubeHostUrl = 'https://www.youtube.com';

export const previewUrl = (videoId: string): string =>
    youtubeHostUrl + '/embed/' + videoId + '?wmode=transparent&autoplay=1&rel=0';

export const youtubeUrl = (videoId: string): string =>
    youtubeHostUrl + '/watch?v=' + videoId + '&rel=0';

export const buildWorkspaceVideoUrl = (component: VideoComponent, workspaceMode: boolean = true): string => {
    const settings = settingsSelector(component),
        videoId = videoIdSelector(component),
        showControlsSetting = getSettingValue(settings, VideoSettings.showControls),
        autoPlaySetting = getSettingValue(settings, VideoSettings.autoPlay),
        repeatSetting = getSettingValue(settings, VideoSettings.repeat);

    return youtubeHostUrl + '/embed/' + videoId + '?wmode=transparent&enablejsapi=1&rel=0' +
        ((!workspaceMode && autoPlaySetting) ? '&autoplay=1&mute=1' : '') +
        (repeatSetting ? ('&loop=1&playlist=' + videoId) : '') +
        (showControlsSetting ? '' : '&controls=0');
};

export const adjustUrl = (url: string): string => {
    const matches = url.match(new RegExp(VideoUrlPrefixPattern));
    if (matches) {
        return VideoUrlPrefixWithS + url.split(matches[0])[1];
    }
    return url;
};
