import React from 'react';
import * as R from 'ramda';
import { Box } from '../../../view/reflexbox/index';
import VerticalSpacer from '../../../view/common/VerticalSpacer';
import { BorderStyles } from './constants';
import { dropdown as BorderStyleDD } from './index';
import ColorProperty from '../ColorProperty/index';
import OpacitySlider from '../OpacitySlider/index';
import FourFieldBorders from '../FourFields/FourFieldBorders/index';
import type { BorderStyleDialogViewPropTypes, BorderStyleDialogFullViewPropTypes } from './flowTypes';
import styles from "../Border/Border.css";

const
    validateBorderStyle = borderStyle => (borderStyle || BorderStyles.NONE),
    isBorderStyleNone = borderStyle => borderStyle === BorderStyles.NONE,
    borderStyleView = ({ style, styleChangeAction, disabled, dispatch }) => (
        <BorderStyleDD
            value={style}
            onChangeAction={styleChangeAction}
            disabled={disabled}
            dispatch={dispatch}
        />
    ),
    colorPropertyView = ({ color, colorChangeAction, colorRemoveAction, dispatch }) => (
        <ColorProperty
            color={color}
            label="msg: common.borderColor {Border colour:}"
            onChangeAction={colorChangeAction}
            onRemoveAction={colorRemoveAction}
            dispatch={dispatch}
        />
    ),
    opacitySliderView = ({ colorOpacity, onChangeAction, dispatch }) => (
        <OpacitySlider
            label="msg: common.borderOpacity {Border opacity}"
            opacity={colorOpacity}
            onChange={opacity => dispatch({ type: onChangeAction, payload: opacity })}
            onAfterChange={opacity => dispatch({ type: onChangeAction, payload: opacity })}
        />
    ),
    borderWidthView = ({ width, widthChangeAction, dispatch, selectedComponent }) => {
        return (
            <FourFieldBorders
                values={width}
                onChangeAction={widthChangeAction}
                dispatch={dispatch}
                selectedComponent={selectedComponent}
            />
        );
    },
    borderStyleDialogView = ({
        borderStyle,
        borderStyleChangeAction,
        borderColor,
        borderColorChangeAction,
        borderColorRemoveAction,
        borderColorOpacity,
        borderColorOpacityChangeAction,
        dispatch
    }: BorderStyleDialogViewPropTypes) => {
        const validatedBorderStyle = validateBorderStyle(borderStyle),
            className = `${styles.borderSettingsView} ${isBorderStyleNone(validatedBorderStyle)
                ? styles.hidden : ''}`;
        return (
            <Box>
                {borderStyleView({
                    style: validatedBorderStyle,
                    styleChangeAction: borderStyleChangeAction,
                    disabled: false,
                    dispatch
                })}
                <VerticalSpacer />
                <div className={className}>
                    {colorPropertyView({
                        color: borderColor,
                        colorChangeAction: borderColorChangeAction,
                        colorRemoveAction: borderColorRemoveAction,
                        dispatch
                    })}
                    <VerticalSpacer />
                    {opacitySliderView({
                        colorOpacity: borderColorOpacity,
                        onChangeAction: borderColorOpacityChangeAction,
                        // @ts-ignore
                        disabled: R.isNil(borderColor),
                        dispatch
                    })}
                </div>
            </Box>
        );
    },
    borderStyleDialogFullView = ({
        borderStyle,
        borderStyleChangeAction,
        borderColor,
        borderColorChangeAction,
        borderColorRemoveAction,
        borderColorOpacity,
        borderColorOpacityChangeAction,
        borderWidth,
        borderWidthChangeAction,
        dispatch,
        selectedComponent
    }: BorderStyleDialogFullViewPropTypes) => {
        const
            validatedBorderStyle = validateBorderStyle(borderStyle),
            className = `${styles.borderSettingsFullView} ${isBorderStyleNone(validatedBorderStyle)
                ? styles.hidden : ''}`;
        return (
            <Box>
                {borderStyleView({
                    style: validatedBorderStyle,
                    styleChangeAction: borderStyleChangeAction,
                    disabled: false,
                    dispatch
                })}
                <VerticalSpacer />
                <div className={className}>
                    {colorPropertyView({
                        color: borderColor,
                        colorChangeAction: borderColorChangeAction,
                        colorRemoveAction: borderColorRemoveAction,
                        dispatch
                    })}
                    <VerticalSpacer />
                    {opacitySliderView({
                        colorOpacity: borderColorOpacity,
                        onChangeAction: borderColorOpacityChangeAction,
                        // @ts-ignore
                        disabled: R.isNil(borderColor),
                        dispatch
                    })}
                    <VerticalSpacer />
                    {borderWidthView({
                        width: borderWidth,
                        widthChangeAction: borderWidthChangeAction,
                        dispatch,
                        selectedComponent
                    })}
                </div>
            </Box>
        );
    };

export { borderStyleDialogView, borderStyleDialogFullView };
