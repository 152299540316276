import * as colorMapper from "../Base/color";
import type { GradientIn, GradientStopIn } from "./flowTypes";
import type { Gradient } from "../../../../src/mappers/background/flowTypes";
import { toint } from "../../../../utils/type";
import { GradientOrigin } from "../../../../src/mappers/background/flowTypes";

const stopsToFadePoint = (stops: [GradientStopIn, GradientStopIn]) => {
    const
        from = toint(stops[0][1]),
        to = toint(stops[1][1]);

    if (from === 0) return to / 2;
    return (from / 2) + 50;
};

const fadePointToStops = (fadePoint: number): [string, string] => {
    const stops: [string, string] = ['', ''];

    if (fadePoint < 50) {
        stops[0] = '0%';
        stops[1] = (fadePoint * 2) + '%';
    } else {
        stops[0] = ((fadePoint - 50) * 2) + '%';
        stops[1] = '100%';
    }

    return stops;
};

const to = (gradient: null | undefined | GradientIn): null | undefined | Gradient => {
    if (gradient) {
        const degree = gradient.origin === GradientOrigin.LEFT ? 270 : 0;
        return {
            color: colorMapper.toHsl(gradient.stops[1][0]),
            fadePoint: stopsToFadePoint(gradient.stops),
            degree: gradient.degree || degree
        };
    }
    return gradient;
};

const back = (gradient: null | undefined | Gradient): null | undefined | GradientIn => {
    return gradient && {
        type: 'web.data.styles.Gradient',
        gradType: 'linear',
        degree: gradient.degree,
        ellipse: true,
        size: 'fartherst-corner',
        stops: [[['HSL', 0, 0, 0, 0], '0%'], [['HSL', 0, 0, 0, 0], '100%']] // to make flow happy
    };
};

export { to, back, fadePointToStops };
