import { assocPath, path } from 'ramda';

import { SVG_KIND as kind } from './kind';
import { svgWorkspaceView as workspace } from './view/index';
import { ComponentNames } from "../constants";
import propertiesPanel from "./propertiesPanel/index";
import reducer from "./reducer";
import adjustmentHookConfig from './adjustmentHookConfig';
import componentMainActions from './componentMainActions/index';
import { SVG_SET_SVG } from './actionTypes';
import { svgCalcRenderProps } from './calcRenderProps';
import previewConfig from './previewConfig';

import siteDataEpic from "../../App/epics/siteData/index";
import { globalVariablesEpic } from "../../App/epics/globalVariablesEpic";
import { replaceTagsWithContent } from "../Text/view/replaceTagsWithContent";
import { makeSvgChooserConfigDialog } from "../../FileManager/makeSvgChooserConfigDialog";
import { missingAssetUrls } from '../../MissingAssets/epic/index';
import stylesheetsEpic from '../../Workspace/epics/stylesheets/index';
import makeStateSelectorReducer from "../../../epics/makeStateSelectorReducer";
import { colorThemeSiteSettingsEpic } from "../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";

import type { SvgComponent } from './flowTypes';
import type { ChangeOldComponentDataBeforeImportProps } from "../../../view/oneweb/flowTypes";

const svgComponentConfig = {
    kind,
    view: workspace,
    label: ComponentNames[kind],
    shortcutIconName: 'svg',
    propertiesPanel,
    reducer,
    dependsOn: {
        site: siteDataEpic.reducer,
        globalVariables: globalVariablesEpic.reducer,
        missingAssetUrls,
        themeColorsData: makeStateSelectorReducer(
            stylesheetsEpic.reducer,
            stylesheetsEpic.valueActionType,
            getThemeColorsFromStylesheet
        ),
        themeSettingsData: colorThemeSiteSettingsEpic.reducer,
    },
    mobileEditorConfig: {
        ...previewConfig,
    },
    calcRenderProps: svgCalcRenderProps,
    shouldKeepAspectRatio: (): boolean => true,
    adjustmentHookConfig,
    componentMainActions,
    requireConfigurationOnDrop: () => true,
    configurationDialog: makeSvgChooserConfigDialog({ onSaveAction: SVG_SET_SVG }),
    changeOldComponentDataBeforeImport: ({ component, globalVariables, site }: ChangeOldComponentDataBeforeImportProps<SvgComponent>) => {
        const pathToUrlkey = ['action', 'link', 'url'],
            url = path(pathToUrlkey, component);
        if (url) {
            return assocPath(pathToUrlkey, replaceTagsWithContent(url, { globalVariables, site }, true), component);
        }
        return component;
    },
};

export {
    svgComponentConfig as default,
    svgComponentConfig
};
