import { textLikeComponentFactory } from '../factories/textLikeComponentFactory';
import { ADDRESS_KIND } from './kind';
import { addressComponentView } from "./addressComponentView";
import { getPlaceholderTextForAddress } from "./getPlaceholderTextForAddress";
import { computeComponentDependenciesForPreviewForAddress } from "./computeComponentDependenciesForPreviewForAddress";

const {
    mappers: { to: addressComponentMapperTo, back: addressComponentMapperBack },
    epic: addressComponentEpic,
    previewConfig: addressPreviewConfig
} = textLikeComponentFactory({
    View: addressComponentView,
    kind: ADDRESS_KIND,
    getPlaceholderText: getPlaceholderTextForAddress,
    computeComponentDependenciesForPreview: computeComponentDependenciesForPreviewForAddress
});

export {
    addressComponentMapperTo,
    addressComponentMapperBack,
    addressComponentEpic,
    addressPreviewConfig,
};

