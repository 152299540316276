import { identity } from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import * as workspaceActionTypes from '../../../Workspace/actionTypes';
import COPY_TO_CLIPBOARD from '../../../../redux/middleware/clipboard/actionTypes';
import registry from "../../../../view/oneweb/registry/index";
import {
    ReceiveOnlySelectedComponentsIdsSelector,
    ReceiveOnlyUserInteractionModeSelector,
    ReceiveOnlyComponentsMap
} from "../../../Workspace/epics/componentsEval/selectorActionTypes";
import { isTransient } from "../../../Workspace/epics/componentsEval/userInteractionMutations/interactionModes";
import { getNotSelectedWrappedIdsInsideSelectedComponents } from "../../../Workspace/epics/componentsEval/selectors";
import { unwrapComponent } from "../../../Workspace/epics/wrap/helpers";
import { receiveOnly } from "../../../../epics/makeCondition";
import userFocusValueActionType from "../userFocus/valueActionType";
import * as focusKind from "../userFocus/kind";
import { componentAttachmentsVAT } from "../../../Workspace/epics/componentAttachements/valueActionType";
import {
    getAllAttachmentsForCmpIds,
    removeDuplicatesFromAnArrayOfStrings
} from "../../../Workspace/epics/componentAttachements/util";
import { isHeaderOrFooterSection } from "../../../oneweb/Section/utils";
// TODO: refactoring of this epic depends on wbtgen/src/components/Workspace/epics/componentsEval/pasteComponents.js

const
    defaultState = [],
    makeUpdater = (action, deleteComponents) => ({
        conditions: [
            receiveOnly(componentAttachmentsVAT),
            ReceiveOnlyComponentsMap,
            ReceiveOnlySelectedComponentsIdsSelector,
            ReceiveOnlyUserInteractionModeSelector,
            receiveOnly(userFocusValueActionType),
            action
        ],
        reducer: ({
            values: [
                { attachments },
                componentsMap,
                selectedComponentsIds,
                interactionMode,
                focus
            ], state
        }) => {
            if (isTransient(interactionMode) || focus.kind === focusKind.MOBILE_EDITOR) {
                return { state };
            }
            let selectedCmpsIdsWithoutSections = selectedComponentsIds
                .filter(id => !isHeaderOrFooterSection(componentsMap[id]));

            let componentIdsToProcess: Array<string> = getNotSelectedWrappedIdsInsideSelectedComponents(
                selectedCmpsIdsWithoutSections,
                componentsMap
            );

            componentIdsToProcess = removeDuplicatesFromAnArrayOfStrings([
                ...componentIdsToProcess,
                ...getAllAttachmentsForCmpIds(attachments, componentIdsToProcess.concat(selectedCmpsIdsWithoutSections)),
                ...selectedCmpsIdsWithoutSections
            ]);

            const
                components = componentIdsToProcess.reduce(
                    (components: any, componentId: string) => {
                        const
                            component = componentsMap[componentId],
                            shouldUnwrap = component.wrap && !selectedCmpsIdsWithoutSections.includes(component.relIn.id);

                        return [
                            ...components,
                            (shouldUnwrap ? unwrapComponent : identity)(
                                registry[component.kind].copyHook(component)
                            )
                        ];
                    }, []
                );

            return {
                state: components,
                actionToDispatch: {
                    type: COPY_TO_CLIPBOARD,
                    payload: {
                        contents: JSON.stringify(components),
                        deleteComponents
                    }
                }
            };
        }
    });

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        makeUpdater(workspaceActionTypes.COPY_SELECTED_COMPONENTS, false),
        makeUpdater(workspaceActionTypes.CUT_SELECTED_COMPONENTS, true)
    ]
});
