import React from 'react';
import styles from './PageTemplateSelector.css';
import StandardDialog from '../../../../view/common/dialogs/StandardDialog';
import type { PageTemplateSelectorDialogProps, PageTemplateSelectorState } from "../types";
import type { AppDispatch } from "../../../../redux/modules/flowTypes";
import { DataPageTemplateRef } from "../../../../../dal/model";
import { savePageTemplateSelectionAction } from "../actions";
import LoadingCom from "../../../../view/common/LoadingIndicator";
import GridCom from '../../../../view/common/GridLayout';
import { parseIntDec } from "../../../../../utils/number";
import { PageTemplateSelectorCellCom } from "./PageTemplateSelectorCellCom";
import { Msg, Intl } from "../../../../view/intl";
import injectIntl from "../../../../view/intl/injectIntl";

const
    ROW_SIZE = 5,
    GRID_HEIGHT = parseIntDec(styles.gridHeight),
    CELL_HEIGHT = parseIntDec(styles.cellSize),
    GUTTER_WIDTH = parseIntDec(styles.gutterWidth),
    GUTTER_HEIGHT = parseIntDec(styles.gutterHeight);

type Props = PageTemplateSelectorDialogProps & {
    state: PageTemplateSelectorState,
    dispatch: AppDispatch,
    intl: Intl
};

export const PageTemplateSelectorCom = injectIntl(class PageTemplateSelectorComClass extends React.Component<Props> {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this);
    }

    onSave() {
        const { state: { selectedId }, onSaveAction, dispatch } = this.props;
        if (selectedId) {
            dispatch(savePageTemplateSelectionAction(onSaveAction, selectedId));
        }
    }

    isDisabled() {
        const { state: { isLoading, isEqToInitialState, onHold } } = this.props;
        return isLoading || isEqToInitialState || onHold;
    }

    renderGrid() {
        const
            { state: { templates, selectedId, isLoading, itemsOnHold, previewId }, dispatch } = this.props,
            dataSet = templates.map((t: DataPageTemplateRef) => ({
                template: t,
                isSelected: t.id === selectedId,
                isLoading,
                onHold: itemsOnHold.indexOf(t.id) !== -1,
                showPreview: t.id === previewId,
                dispatch
            }));

        return (
            <GridCom
                rowSize={ROW_SIZE}
                height={GRID_HEIGHT}
                dataSet={dataSet}
                cell={PageTemplateSelectorCellCom}
                cellHeight={CELL_HEIGHT}
                gutterWidth={GUTTER_WIDTH}
                gutterHeight={GUTTER_HEIGHT}
                isLazy
                scrollbarTheme={{
                    trackVertical: styles.scrollbar
                }}
            />
        );
    }

    renderFooterTip() { // eslint-disable-line class-methods-use-this
        const { intl } = this.props;
        return (
            <div className={styles.footer}>
                <Msg k="tip" className={styles.footerTip}>Tip:</Msg>
                &nbsp;
                <span className={styles.footerTipMessage}>
                    {/* eslint-disable-next-line max-len */}
                    <Msg k="pageTemplateSelector.footerTip">We recommend using one template for all pages. Add more templates, if you need a different header, menu or footer on some of your pages.</Msg>
                    <a
                        target="_blank"
                        className={styles.learnMore}
                        href={intl.msgJoint("msg: help.links.pageTemplateModalMoreLink {https://help.one.com/hc/en-us/articles/360002073377}")} // eslint-disable-line max-len
                    >
                        <Msg k="common.learnMore">Learn more</Msg>
                    </a>
                </span>
            </div>
        );
    }

    render() {
        const { state: { isLoading } } = this.props;

        return (
            <StandardDialog
                title="msg: pageTemplateSelector.title {Select template}"
                subTitle="msg: pageTemplateSelector.subTitle {The template holds the style and content added to the page.}" // eslint-disable-line max-len
                mctaText="msg: common.save {Save}"
                mctaHandler={this.onSave}
                className={styles.dialog}
                bodyClassName={styles.dialogBody}
                disabled={this.isDisabled()}
                footerContents={this.renderFooterTip()}
            >
                {(isLoading && <LoadingCom />) || this.renderGrid()}
            </StandardDialog>
        );
    }
});
