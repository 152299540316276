
import makeEpic from '../../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import scrollValueActionType from '../../../../Workspace/epics/scroll/valueActionType';
import browserDimensionsValueActionType from '../../../../App/epics/browserDimensions/valueActionType';
import leftPanelWidthValueActionType from '../../../../Panel/epics/width/valueActionType';
import { ContentDimensionsSelector } from '../../../../Workspace/epics/componentsEval/selectorActionTypes';
import { TemplateWidthActionType } from '../template/selectorActionTypes';
import { calcTemplateOffset } from "../../../../App/utility/index";
import { TopBarHeight } from "../../../../TopBar/constants";
import { isEven } from "../../../../../utils/ramdaEx";
import { receiveOnly } from "../../../../../epics/makeCondition";
import { TEMPLATE_LEFT_OFFSET_CHANGED_WITHOUT_SCROLLING } from "../../../../ModernLayouts/actionTypes";

const reducer = ({
    values: [
        scroll,
        { width: browserWidth },
        leftPanelWidth,
        templateWidth,
        contentDimensions
    ]
}, dispatchTemplateLeftOffsetChangedWithOutScrollAction) => {
    const
        workspaceWidth = browserWidth - leftPanelWidth,
        templateOffsetXWithoutScroll = calcTemplateOffset(templateWidth, leftPanelWidth, browserWidth),
        // Odd browser width cause 1px template offset render difference from mouse position with respect
        // to template area
        xAdjust = isEven(browserWidth) ? 0 : -1,
        updates = dispatchTemplateLeftOffsetChangedWithOutScrollAction ? {
            actionToDispatch: {
                type: TEMPLATE_LEFT_OFFSET_CHANGED_WITHOUT_SCROLLING,
                payload: templateOffsetXWithoutScroll
            }
        } : {};

    if (contentDimensions.width > workspaceWidth) {
        const
            howMuchContentBiggerThanWorkspace = contentDimensions.width - workspaceWidth,
            half = Math.round(howMuchContentBiggerThanWorkspace / 2);

        return {
            state: {
                x: templateOffsetXWithoutScroll + half - scroll.x + xAdjust,
                y: TopBarHeight - scroll.y
            },
            ...updates
        };
    }
    return {
        state: {
            x: templateOffsetXWithoutScroll + xAdjust,
            y: TopBarHeight - scroll.y
        },
        ...updates
    };
};

export default makeEpic({
    valueActionType,
    updaters: [
        {
            conditions: [
                receiveOnly(scrollValueActionType),
                browserDimensionsValueActionType,
                leftPanelWidthValueActionType,
                TemplateWidthActionType,
                ContentDimensionsSelector
            ],
            reducer: (data) => reducer(data, true)
        },
        {
            conditions: [
                scrollValueActionType,
                receiveOnly(browserDimensionsValueActionType),
                receiveOnly(leftPanelWidthValueActionType),
                receiveOnly(TemplateWidthActionType),
                receiveOnly(ContentDimensionsSelector)
            ],
            // @ts-ignore
            reducer
        }
    ]
});
