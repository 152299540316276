import { setToPath, toggleValue } from "../utils/ramdaEx";
import p from "../utils/pipePath";
import * as mp from "../mappers/path"; // eslint-disable-line
import type { Color } from '../mappers/flowTypes';
import type { Path } from "../mappers/path"; // eslint-disable-line no-duplicate-imports

export const
    toggleBold = (path: Path, data: any) => toggleValue(p(path, mp.bold))(data),
    toggleItalic = (path: Path, data: any) => toggleValue(p(path, mp.italic))(data),
    toggleUnderline = (path: Path, data: any) => toggleValue(p(path, mp.underline))(data),
    setFontFamily = (path: Path, fontFamily: string, data: any) => setToPath(p(path, mp.font), fontFamily, data),
    setFontSize = (path: Path, fontSize: number, data: any) => setToPath(p(path, mp.size), fontSize, data),
    setTextColor = (path: Path, color: Color, data: any) => setToPath(p(path, mp.color), color, data),
    unsetTextColor = (path: Path, data: any) => setToPath(p(path, mp.color), null, data);
