import React from 'react';
import scrollEffectsPPPage from '../../../commonView/ScrollEffects/scrollEffectsPPPage';
import type { StripPropertiesPanelPageType } from "../../flowTypes";
import { BG_IMAGE_SCROLL_EFFECT_CHANGE } from "../../../Background/actionTypes";

const scrollEffectText = "msg: common.scrollEffects.description {Add modern 3D effects to a {componentKind} when scrolling. Preview or publish your site to see them in action.}";// eslint-disable-line max-len

export default {
    ...scrollEffectsPPPage,
    view: ({ selectedComponent, dispatch }: StripPropertiesPanelPageType) => {
        const { style: { background: { assetData } }, kind } = selectedComponent,
            PageView = scrollEffectsPPPage.view;
        return <PageView
            kind={kind}
            hasImage={!!assetData}
            scrollEffect={assetData && assetData.scrollEffect}
            description={scrollEffectText}
            actionOnScrollEffectChange={BG_IMAGE_SCROLL_EFFECT_CHANGE}
            dispatch={dispatch}
        />;
    }
};
