import { WidgetsBookingsKind } from './kind';
import { calcRenderProps } from './calcRenderProps';
import { BookingsView } from './view/index';

const WidgetsBookingsPreviewConfig = {
    kind: WidgetsBookingsKind,
    view: BookingsView,
    calcProps: ({ component, isServerPreview }) => calcRenderProps({
        component,
        componentExtension: { isTransient: false },
        isServerPreview,
    }),
};

export { WidgetsBookingsPreviewConfig };
