export const
    TOGGLE_MOBILE_SIDE_BAR_ACTION = 'TOGGLE_MOBILE_SIDE_BAR_ACTION',
    SELECT_SIDE_BAR_TAB_ACTION = 'SELECT_SIDE_BAR_TAB_ACTION',

    SIDE_BAR_ITEM_CLICKED_ACTION = 'SIDE_BAR_ITEM_CLICKED_ACTION';

export const
    toggleMobileSideBarAction = () => ({ type: TOGGLE_MOBILE_SIDE_BAR_ACTION }),
    selectSideBarTabAction = (id: string, data?: any) => ({ type: SELECT_SIDE_BAR_TAB_ACTION, payload: { id, data } }),

    sideBarItemClickedAction = (id: string, options?: any) => ({
        type: SIDE_BAR_ITEM_CLICKED_ACTION,
        payload: { id, options }
    });
