import {
    makeCombineReducer,
    makeDefaultStateReducers,
    makeComponentBaseReducers
} from "../../../../redux/makeReducer/index";
import kind from '../kind';
import * as actionTypes from '../actionTypes';
import updateComponentReducer from './updateComponent';
import changeGlobalStyle from './changeGlobalStyle';
import {
    TOGGLE_MOBILE_DOWN_ACTION
} from "../../../PropertiesPanel/view/MobileView/actions";
import mobileDownReducer from '../../../PropertiesPanel/view/MobileView/mobileDownReducer';
import { setPayloadProperty, removeProperty } from "../../Image/reducers/componentChange";
import { MOBILE_EDITOR_ALIGNMENT_CHANGE } from "../../../MobileViewEditor/actionTypes";
import { mobileSettingsReducer } from "../../../PropertiesPanel/view/MobileView/mobileSettingsReducer";
import { ALIGNMENT_TYPES } from "../../../../constants";
import { MCTA_RESIZE_OPTIONS } from "../constants";

export default makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(kind),
        ...makeDefaultStateReducers({
            text: 'Button',
            width: 130,
            height: 50,
            mobileSettings: {
                align: ALIGNMENT_TYPES.ALIGN_CENTER
            },
            mobileHide: false,
            mobileDown: false,
            linkAction: null,
            style: null,
            buttonThemeSelected: null,
        })
    },
    handleActions: {
        [actionTypes.BUTTON_SET_LINK]: setPayloadProperty('linkAction'),
        [actionTypes.BUTTON_REMOVE_LINK]: removeProperty('linkAction'),
        [actionTypes.BUTTON_GLOBAL_STYLE_UPDATED]: changeGlobalStyle,
        [actionTypes.BUTTON_UPDATE_COMPONENT]: updateComponentReducer,
        [actionTypes.BUTTON_UPDATE_COMPONENT_ON_RESIZE]: updateComponentReducer,
        [TOGGLE_MOBILE_DOWN_ACTION]: mobileDownReducer,
        [MOBILE_EDITOR_ALIGNMENT_CHANGE]: mobileSettingsReducer('align'),
        [actionTypes.BUTTON_THEME_SELECTED]: (state, action) => {
            const { payload: buttonThemeSelected } = action;
            return { ...state, buttonThemeSelected };
        },
        [actionTypes.BUTTON_MCTA_CHANGE_SIZE]: (state, action) => {
            const size = action.payload;
            const width = MCTA_RESIZE_OPTIONS[size].width;
            const height = MCTA_RESIZE_OPTIONS[size].height;
            const fontSize = MCTA_RESIZE_OPTIONS[size].fontSize;
            return {
                ...state,
                width,
                height,
                modernLayoutOptions: { ...state.modernLayoutOptions, size },
                style: {
                    ...state.style,
                    text: { ...state.style.text, size: fontSize }
                }
            };
        }
    }
});
