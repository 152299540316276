/* eslint-disable max-len */
import { makeStyles } from '@mui/styles';
import { extraThemeConfig } from '../../../../../../../src/dashboard/src/app/theme';

type StyleProps = { noTextAlert: boolean };

export const marketingConsentStyles = makeStyles((theme) => ({
    description: {
        fontSize: '14px',
        lineHeight: '22px',
        letterSpacing: '0.25px',
    },
    noTextAlert: {
        color: theme.palette.custom.colorVenetianRed,
    },
    setPPBtn: {
        color: theme.palette.custom.colorVenetianRed,
        border: `1px solid ${theme.palette.custom.colorGray_bb}`,
        width: "100%"
    },
    EditPPBtn: {
        color: theme.palette.primary.main
    },
    root: {
        width: "100%",
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: ({ noTextAlert }: StyleProps) => (noTextAlert ? theme.palette.custom.colorVenetianRed : theme.palette.primary.main),
                borderWidth: '1px',
                transition: theme.transitions.create(['border-color', 'box-shadow']),
            }
        },
        '& .MuiOutlinedInput-root:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: ({ noTextAlert }: StyleProps) => (noTextAlert ? theme.palette.custom.colorVenetianRed : theme.palette.custom.colorGray_bb),
            }
        },
        '& .MuiOutlinedInput-root': {
            height: "auto",
            fontSize: "14px",
            lineHeight: "22px",
            letterSpacing: "0.25px",
            borderRadius: `${extraThemeConfig.borderRadius2}px`,
            padding: "10px 20px",
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: ({ noTextAlert }: StyleProps) => (noTextAlert ? theme.palette.custom.colorVenetianRed : theme.palette.custom.colorGray_bb),
            },
            "& > textarea": {
                borderRadius: `${extraThemeConfig.borderRadius2}px`
            }
        }
    }
}), { name: "marketingConsentPropertiesPanel" });
