import type { AdjustmentHookProps } from '../../Workspace/epics/componentsEval/flowTypes';

export default {
    hook: (
        { component }: AdjustmentHookProps<any, any, any, any>,
    ) => {
        let height = component.height;
        if (component.newHeight > 0) {
            height = component.newHeight;
        }
        return [
            {
                ...component,
                newHeight: 0,
                height
            }
        ];
    },
    shouldCallHook: () => true
};
