import { all, fork, select, takeLatest } from 'redux-saga/effects'; // eslint-disable-line node/file-extension-in-import
import * as R from 'ramda';
import { captureAppState } from '../utils/isDebug';
import { registerException } from '../debug/index';
import * as resolveWhenFulfilledGeneratorsRegistry from '../debug/resolveWhenFulfilledGeneratorsRegistry';
import { trace, error } from '../../utils/log';
import actionTypeDoesntMatchAction from "./actionTypeDoesntMatchAction";
import { ActionType } from "./makeCondition";
import { sanitizeAppState } from "../reportError";

function validateArgs(args: Array<any>) {
    R.forEach(arg => {
        if (typeof arg !== 'string' && !(arg instanceof ActionType)) {
            trace('actionType should be string or object, but it was', arg);
            throw new Error(`actionType should be string`);
        }
    }, R.dropLast(1, args));
}

type Scope = {
    receivedActions: {
        [actionType: string]: null | undefined | Action<AnyValue>
    }
}

// TODO discount WBTGEN-6210
const factory = (extractPayload: boolean, scope: Scope = { receivedActions: {} }) =>
        (...args: Array<any>) => {
            validateArgs(args);

            return function* (): any {
                const
                    receivedActions = scope.receivedActions,
                    lastExecutionParams = {},
                    actionTypesCount = args.length - 1,
                    actionTypes = args.slice(0, actionTypesCount),
                    generator = args[actionTypesCount],
                    actionSagas = R.range(0, actionTypesCount).map(actionTypeIndex => {
                        const
                            actionType = args[actionTypeIndex],
                            actionTypeString = actionType.toString();

                        return fork(function* (): any {
                            yield takeLatest(actionTypeString, function* (action: Action<any>): any {
                                if (actionTypeDoesntMatchAction(actionType, action)) return;

                                if (actionType.reset) {
                                    const actionTypesAfterMe = args.slice(actionTypeIndex + 1, actionTypesCount);
                                    actionTypesAfterMe.forEach(actionTypeToReset => {
                                        receivedActions[actionTypeToReset] = undefined;
                                    });
                                }
                                receivedActions[actionTypeString] = action;
                                const isFulfilled = R.all(
                                    actionType => (
                                        actionType.isOptional || receivedActions[actionType.toString()] !== undefined
                                    ),
                                    actionTypes
                                );
                                if (isFulfilled && !actionType.receiveOnly) {
                                    const
                                        actionTypeToParamMapper = (actionType): any => {
                                            const action = receivedActions[actionType];
                                            if (extractPayload) {
                                                if (action) {
                                                    if (actionType.selector) {
                                                        return actionType.selector(action.payload);
                                                    } else {
                                                        return action.payload;
                                                    }
                                                } else {
                                                    return null;
                                                }
                                            } else if (actionType.selector) {
                                                return actionType.selector(action);
                                            } else {
                                                return action;
                                            }
                                        },
                                        generatorParams: any = actionTypes.map(actionTypeToParamMapper);

                                    const actionValueIsSame =
                                        generatorParams[actionTypeIndex] === lastExecutionParams[actionTypeIndex];

                                    if (actionType.selector && actionValueIsSame) {
                                        return;
                                    }

                                    let prevAppState: any;

                                    if (captureAppState()) {
                                        prevAppState = yield select(R.identity);
                                    }

                                    try {
                                        yield* generator(...generatorParams);
                                        Object.assign(lastExecutionParams, generatorParams);
                                    } catch (exception: any) {
                                        error(exception);
                                        registerException({
                                            type: 'RESOLVE_WHEN_FULFILLED',
                                            actionTypes,
                                            receivedActions,
                                            generatorParams,
                                            fulfilledAction: action,
                                            stateBeforeException: sanitizeAppState((prevAppState as any)),
                                            exception,
                                            action
                                        });
                                    }
                                }

                                if (actionType.actionTypesToResetAfterRun) {
                                    actionType.actionTypesToResetAfterRun.forEach(actionTypeToReset => {
                                        receivedActions[actionTypeToReset] = undefined;
                                    });
                                }
                            });
                        });
                    });

                resolveWhenFulfilledGeneratorsRegistry.register(actionTypes, generator);

                yield all([...actionSagas]);
            };
        },
    makeResolveWhenFulfilledSagaExtractPayloadFromActions = factory(true);

export {
    factory,
    makeResolveWhenFulfilledSagaExtractPayloadFromActions as default
};
