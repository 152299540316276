import type { InstagramGalleryComponent } from "../../../../src/components/oneweb/InstagramGallery/flowTypes";
import type { InstagramGalleryIn } from './flowTypes';

const
    adjustScale = scale => {
        const
            minPossibleScale = 0.1,
            maxPosibleScale = 0.999;

        if (scale < minPossibleScale) {
            return minPossibleScale;
        }
        if (scale > maxPosibleScale) {
            return maxPosibleScale;
        }

        return scale;
    },
    to = (galleryIn: InstagramGalleryIn) => {
        const
            { bbox, spacingPx, relIn, relTo } = galleryIn,
            { left, top, right, bottom } = bbox,
            // @ts-ignore this can cause a bug
            spaceBetweenRows = spacingPx / 2,
            newLeft = left + spaceBetweenRows,
            newTop = top + spaceBetweenRows,
            newRight = right - spaceBetweenRows,
            newBottom = bottom - spaceBetweenRows,
            newRelIn = relIn && {
                id: relIn.id,
                left: relIn.left + spaceBetweenRows,
                top: relIn.top + spaceBetweenRows,
                right: relIn.right - spaceBetweenRows,
                bottom: relIn.bottom - spaceBetweenRows
            },
            newRelTo = relTo && {
                id: relTo.id,
                below: relTo.below + spaceBetweenRows
            };

        return {
            left: newLeft,
            top: newTop,
            width: newRight - newLeft,
            height: newBottom - newTop,
            columns: galleryIn.columns,
            relIn: newRelIn,
            relTo: newRelTo,
            spacingPx,
            rows: galleryIn.rows,
            showMoreBtn: galleryIn.showMoreBtn,
            style: galleryIn.style,
            fontSizePx: galleryIn.fontSizePx,
            showMoreTxt: galleryIn.showMoreTxt
        };
    },
    back = (gallery: InstagramGalleryComponent) => {
        const
            { spacingPx,
                columns,
                relIn,
                relTo,
                rows,
                showMoreBtn,
                style,
                fontSizePx,
                showMoreTxt } = gallery,
            columnsScale = 1 / gallery.columns,
            spacingScale = gallery.spacingPx / (gallery.width + gallery.spacingPx),
            scale = columnsScale - spacingScale,
            halfSpacingPx = (gallery.spacingPx / 2),
            left = Math.floor(gallery.left - halfSpacingPx),
            top = Math.floor(gallery.top - halfSpacingPx),
            right = left + gallery.width + gallery.spacingPx,
            bottom = top + gallery.height + gallery.spacingPx,
            newRelIn = relIn && {
                id: relIn.id,
                left: relIn.left - halfSpacingPx,
                top: relIn.top - halfSpacingPx,
                right: relIn.right + halfSpacingPx,
                bottom: relIn.bottom + halfSpacingPx
            },
            newRelTo = relTo && {
                id: relTo.id,
                below: relTo.below - halfSpacingPx
            };

        return {
            bbox: { left, top, right, bottom },
            /*
             * If we round scale to available in old webeditor we will lose percision (spacingPx)
             * it is not possible to make 1px spacing in old webeditor
             * so we will save scale with maximum possible percision to old webeditor
             * */
            // scale: roundScaleToNearestAvailableInOldWebEditor(scale),
            scale: adjustScale(scale),
            spacingPx,
            relIn: newRelIn,
            relTo: newRelTo,
            columns,
            rows,
            showMoreBtn,
            style,
            fontSizePx,
            showMoreTxt
        };
    };

export { to, back };
