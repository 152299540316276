import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        padding: 0,
    },
    welcomeTitle: {
        fontSize: 24,
        lineHeight: '30px',
    },
    welcomeMessage: {
        fontSize: 14,
        lineHeight: '22px',
    },
    msgContainer: {
        marginTop: 18,
        textAlign: 'center'
    },
    btn: {
        padding: "12px 24px",
        fontSize: 14,
        lineHeight: '22px',
        width: "fit-content"
    },
    shopMainBtns: {
        display: "flex",
        gap: "16px"
    },
    noThanksLink: {
        fontSize: 14,
        lineHeight: '22px',
        textDecoration: 'underline',
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.8,
        },
    },
    welcomeMessageDialog: {
        borderRadius: 5,
        boxShadow: `0 0 12px ${theme.palette.custom.colorBlack_a40}`,
    },
    welcomeMessageDialogCloseButton: {
        top: 17,
        right: 16,
    },
    leftContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    wrapper: {
        padding: '48px'
    },
    backgroundClassName: {
        backgroundColor: theme.palette.custom.colorWhite_a80,
    },
}), { name: "welcomeDialog" });
