import type { ImageComponent } from '../flowTypes';
import * as componentReducers from "../../componentReducers";
import ScaleStrategies from '../scaleStrategy';
import { fixHeightForFitMode } from './fixHeightForFitMode';
import { getMinZoom } from '../componentSelectors';
import { DEFAULT_BORDER_STYLE } from "../../../presentational/BorderSettings/constants";
import { getBorderStyle } from "../../../../view/oneweb/commonComponentSelectors";

const styleChangeReducer = componentReducers.makeComponentStyleBorderStyleReducer();

export default (component: ImageComponent, action: Action<any>): ImageComponent => {
    const
        changedComponent = styleChangeReducer(component, action),
        { scaleStrategy } = changedComponent;
    if (ScaleStrategies.FIT === scaleStrategy) {
        if (
            getBorderStyle(component) === DEFAULT_BORDER_STYLE || // was none
            getBorderStyle(changedComponent) === DEFAULT_BORDER_STYLE // became none
        ) {
            return fixHeightForFitMode(changedComponent); // if none keep width and adjust height
        }
    } else if (ScaleStrategies.CROP === scaleStrategy) {
        changedComponent.scale = Math.max(changedComponent.scale, getMinZoom(changedComponent));
    }

    return changedComponent;
};
