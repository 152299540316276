import React from 'react';
import * as R from 'ramda';
import cx from "classnames";
import * as path from "../../../mappers/path";
import menuDefaultPageState from "../../oneweb/Menu/menuDefaultPageState";
import * as HORIZONTAL_ALIGN from "../../oneweb/Menu/constants/horizontalAlign";
import * as VERTICAL_ALIGN from "../../oneweb/Menu/constants/verticalAlign";
import * as LAYOUT_TYPE from "../../oneweb/Menu/constants/layoutType";
import layouts from '../../oneweb/Menu/view/layouts/index';
import { ComponentMaskBackground } from '../../presentational/ComponentMask/constants';
import ComponentMask from '../../presentational/ComponentMask/index';
import BaseComponentPreview from "../../../view/oneweb/BaseComponentPreview";
import menuStyles from '../../oneweb/Menu/view/Menu.css';
import { Intl } from "../../../view/intl/index";
import GrayBgContainer from '../../presentational/GrayBgContainer';

const
    makePageState = (id: string, name: string, items: Array<any>, isCurrentPage: boolean = false, hasChildren: boolean = false) => ({
        ...menuDefaultPageState, id, name, items, isCurrentPage, hasChildren
    }),
    makeComponentData = ({ layoutType, horizontalAlign }) => ({
        left: 0,
        width: 380,
        height: 20,
        top: 0,
        orderIndex: 1,
        inTemplate: false,
        transition: '',
        stretch: false,
        startLevel: 1,
        layoutType,
        horizontalAlign,
        verticalAlign: VERTICAL_ALIGN.MIDDLE,
        moreButtonEnabled: true,
        moreText: 'more',
        mobileHide: false,
        style: null,
        subStyle: null,
        themeStyles: null
    }),
    makePropsBase = (componentConfig: {layoutType: string, horizontalAlign: string}) => ({
        component: makeComponentData(componentConfig),
        currentPageId: 'Selected',
        componentExtension: {},
        stylesheet: {}
    }),
    makeMenuPreviewProps = (componentConfig, intl) => ({
        ...makePropsBase(componentConfig),
        pages: [
            { ...makePageState('Normal', intl.msgJoint('msg: gs.menuPreview.normal {Normal}'), []) },
            { ...makePageState('Selected', intl.msgJoint('msg: gs.menuPreview.selected {Selected}'), [], true) },
            { ...makePageState('Expandable',
                intl.msgJoint('msg: gs.menuPreview.expandable {Expandable}'), [], false, true) },
            {
                ...makePageState(
                    'Expanded',
                    intl.msgJoint('msg: gs.menuPreview.expanded {Expanded}'),
                    [makePageState('Expanded child',
                        intl.msgJoint('msg: gs.menuPreview.expandedChild {Expanded child}'), [], true)],
                    false,
                    true
                )
            },
            { ...makePageState('Mouse over', intl.msgJoint('msg: gs.menuPreview.mouseOver {Mouse over}'), []) }
        ]
    }),

    makeProps = (currentStylesheetId, stylesheetsIdToNameMap, config, intl, autoColorMode) => {
        const mainMenuStylepath = (autoColorMode && ['themeStyles', 'mainMenu', 'id']) || path.styleGlobalId;

        return R.pipe(
            makeMenuPreviewProps,
            R.assocPath(['component', ...mainMenuStylepath], currentStylesheetId),
            R.assocPath(['stylesheetsIdToNameMap'], stylesheetsIdToNameMap)
        )(config, intl);
    },
    makePreivew = (
        Layout,
        currentStylesheetId,
        stylesheetsIdToNameMap,
        globalStyleIds,
        horizontalAlign,
        intl,
        selectedParentTheme,
        autoColorMode,
        computedStyles,
    ) => {
        const props = makeProps(
            currentStylesheetId,
            stylesheetsIdToNameMap,
            {
                horizontalAlign,
                layoutType: Layout
            },
            intl,
            autoColorMode,
        );
        return (
            <BaseComponentPreview globalStyleIds={globalStyleIds} wrap={false}>
                <ComponentMask showPreviewOnlyLayer={false} maskStyle={ComponentMaskBackground.TRANSPARENT}>
                    <GrayBgContainer>
                        <div className={cx(menuStyles.hackAnchor, { 'menuPreview': autoColorMode })}>
                            <Layout
                                {...props}
                                selectedParentTheme={selectedParentTheme}
                                autoColorMode={autoColorMode}
                                computedStyles={computedStyles}
                            />
                        </div>
                    </GrayBgContainer>
                </ComponentMask>
            </BaseComponentPreview>
        );
    },

    makeVerticalMenuPreview = (
        currentStylesheetId: string,
        stylesheetsIdToNameMap: MapT<string>,
        globalStyleIds: Array<string>,
        intl: Intl,
        selectedParentTheme: string,
        autoColorMode: boolean,
        computedStyles?: Record<string, any>,
    ) => makePreivew(
        layouts[LAYOUT_TYPE.VERTICAL_TREE],
        currentStylesheetId,
        stylesheetsIdToNameMap,
        globalStyleIds,
        HORIZONTAL_ALIGN.FIT,
        intl,
        selectedParentTheme,
        autoColorMode,
        computedStyles,
    ),

    makeHorizontalMenuPreview = (
        currentStylesheetId: string,
        stylesheetsIdToNameMap: MapT<string>,
        globalStyleIds: Array<string>,
        intl: Intl,
        selectedParentTheme: string,
        autoColorMode: boolean,
        computedStyles?: Record<string, any>,
    ) => makePreivew(
        layouts[LAYOUT_TYPE.HORIZONTAL_DROPDOWN],
        currentStylesheetId,
        stylesheetsIdToNameMap,
        globalStyleIds,
        HORIZONTAL_ALIGN.LEFT,
        intl,
        selectedParentTheme,
        autoColorMode,
        computedStyles,
    );

export {
    makePageState,
    makePropsBase as makeMenuComponentProps,
    makeVerticalMenuPreview,
    makeHorizontalMenuPreview
};
