import * as R from 'ramda';
import { put, takeLatest, call } from "redux-saga/effects"; // eslint-disable-line node/file-extension-in-import
import ImageResource from "../ImageResource";
import { FC_SAVE } from "../actionTypes";
import { closeDialogGen, openDialogGen } from "../../../../../utils/saga/index";
import getResourceMetadataSaga from "./getResourceMetadataSaga";
import { MultipleErrorsDialog } from "../../../../../view/common/FileChooser/dialogIds";
import VideoResource from "../VideoResource";
import { openProgressDialogAction, closeDialog } from "../../../../../components/App/actionCreators/index";
import getMetadataValidationErrorKind, { getMessageForErrorKind } from '../utils/getMetadataValidationErrorKind';
import { fcResetAction } from "../actionCreators/index";
import { makeActionForwardToSelectedComponent } from "../../../../forwardTo";
import { ResourceMetadata } from '../flowTypes';

const
    saveErrorTitle = 'msg: fc.save.errDialog.title {Saving failed}',
    saveErrorInstructions = 'msg: fc.save.errDialog.instructions {Please choose another file.}';

export function* fcSave(action: Action<any>): Generator<any, any, any> {
    const { onSaveAction, isMultiSelect, selection, forwardToComponent, softSave, closeModalOnSave = true, headlessMode } = action.payload;

    if (!softSave) {
        yield put(openProgressDialogAction(
            'msg: fc.metadataFetch.request.title {Please wait}',
            'msg: fc.metadataFetch.request.fetching {Fetching file information...}'
        ));
    }

    const resources = yield selection.map(resource => {
        return call(function* () {
            if (resource.isVideo()) {
                return new VideoResource(resource.basePath, resource);
            }
            const result: false | ResourceMetadata = yield* getResourceMetadataSaga(resource.getWebspacePath(), true /* isForSave */);
            const validationFailKind = getMetadataValidationErrorKind(result);

            if (validationFailKind) {
                return {
                    error: true,
                    errorKind: validationFailKind,
                    resource
                };
            }
            // etag of metadata is not same as resource etag.
            // @ts-ignore
            result.etag = resource.etag || result.etag;
            return new ImageResource(resource.basePath, resource, result);
        });
    });

    const errors = resources.filter(res => res.error),
        errorGroups = R.pipe(
            R.groupBy(R.prop('errorKind')),
            R.mapObjIndexed((errors, errorKind) => {
                return {
                    message: getMessageForErrorKind(errorKind),
                    fileNames: errors.map(({ resource }) => resource.getName())
                };
            }),
            R.values
        )(errors);

    if (!softSave) {
        yield* closeDialogGen();
    }

    if (errors.length) {
        if (headlessMode) {
            yield put(fcResetAction());
            yield put(closeDialog());
        }
        yield* openDialogGen(MultipleErrorsDialog, {
            title: saveErrorTitle,
            instuctions: saveErrorInstructions,
            groups: errorGroups
        });
    } else {
        const payload: Record<string, any> = {};
        if (isMultiSelect) {
            payload.assets = resources.map(r => r.getAssetMetadata());
        } else {
            payload.asset = resources[0].getAssetMetadata();
        }

        let saveAction: Action = { type: onSaveAction, payload };
        if (forwardToComponent) {
            saveAction = makeActionForwardToSelectedComponent(saveAction);
        }

        yield put(saveAction);
        yield put(fcResetAction());
        if (!softSave && closeModalOnSave) {
            yield put(closeDialog());
        }
    }
}

export default function* (): ListenerSaga {
    yield takeLatest(FC_SAVE, fcSave);
}
