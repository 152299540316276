import { WidgetsBingMapsKind } from './kind';
import { componentMainActions } from './componentMainActions';
import { configurationDialogId } from './configurationDialog';
import { propertiesPanel } from './propertiesPanel/index';
import { reducer } from './reducer';
import { BingMapsView } from './view/index';
import { BingMapsWorkspaceView } from './view/workspace';
import { calcRenderProps } from './calcRenderProps';
import { WidgetsBingMapsConfig } from '../constants';
import { getMVEHiddenElementData } from '../mobileEditorConfig';

const WidgetsBingMaps = {
    kind: WidgetsBingMapsKind,
    label: WidgetsBingMapsConfig.title,
    shortcutIconName: 'widgetsBingMaps',
    view: BingMapsView,
    workspace: BingMapsWorkspaceView,
    requireConfigurationOnDrop: () => true,
    configurationDialog: configurationDialogId,
    calcRenderProps,
    componentMainActions,
    propertiesPanel,
    reducer,
    mobileEditorConfig: {
        kind: WidgetsBingMapsKind,
        calcProps: calcRenderProps,
        view: BingMapsView,
        getHiddenElementData: getMVEHiddenElementData(WidgetsBingMapsConfig),
    },
};

export { WidgetsBingMaps };
