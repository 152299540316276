import React from "react";
import { connect } from 'react-redux';
import * as styles from './QuickTour.css';
import TipContent from './TipContent/index';
import { STOP_QUICK_TOUR } from "../actionTypes";
import { FULL_TOUR, FULL_TOUR_WITH_TUTORIAL, TipsData, TipsSequence } from "../constants";

const QuickTour = ({ quickTour, dispatch }) => {
    let { show, tipNumber, spotLightStyles, tourType } = quickTour;
    if (tourType === FULL_TOUR_WITH_TUTORIAL) {
        tourType = FULL_TOUR;
    }
    return show ? (
        <div className={styles.base} onClick={() => dispatch({ type: STOP_QUICK_TOUR })}>
            <div
                className={`${styles.spotLight} ${TipsData[TipsSequence[tourType][tipNumber]].classNames.spotLight}`}
                style={spotLightStyles}
            />
            <TipContent {...quickTour} dispatch={dispatch} />
        </div>
    ) : null;
};

const mapStateToProps = ({ quickTour }) => ({ quickTour });

export default connect(mapStateToProps)(QuickTour);
