import React from 'react';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';

import common from "../../../../../../../wbtgen/src/view/Theme/common.css";

export const styles = makeStyles(
    (theme) => ({
        navigation: {
            cursor: "pointer",
            "&:before": {
                content: '""',
                position: "relative",
                display: "block",
                width: "10px",
                height: "10px",
                border: `solid ${theme.palette.custom.colorGray_3c}`,
                borderWidth: "0 1px 1px 0",
            }
        },
        prevNavigation: {
            "&:before": {
                transform: "rotate(135deg)",
            }
        },
        nextNavigation: {
            "&:before": {
                transform: "rotate(-45deg)",
            }
        },
        disabled: {
            "&:before": {
                border: `1px solid ${common.colorGray_bb}`,
                borderWidth: "0 1px 1px 0"
            }
        },
        btn: {
            minWidth: "20px"
        }
    }),
    { name: "Stepper" }
);

export const Stepper = ({ activeStepIndex, handleNext, handleBack, totalSteps }) => {
    const classes = styles();
    const isNextBtnDisabled = activeStepIndex === totalSteps - 1;
    const isBackBtnDisabled = activeStepIndex === 0;

    return (
        <MobileStepper
            variant="dots"
            steps={totalSteps}
            position="static"
            activeStep={activeStepIndex}
            sx={{ flexGrow: 1, padding: "16px 0px" }}
            nextButton={
                <Button
                    size="small"
                    onClick={handleNext}
                    disabled={isNextBtnDisabled}
                    className={classes.btn}
                >
                    <span className={cx(classes.navigation, classes.nextNavigation, isNextBtnDisabled && classes.disabled)} />
                </Button>
            }
            backButton={
                <Button
                    size="small"
                    onClick={handleBack}
                    disabled={isBackBtnDisabled}
                    className={classes.btn}
                >
                    <span className={cx(classes.navigation, classes.prevNavigation, isBackBtnDisabled && classes.disabled)} />
                </Button>
            }
        />
    );
};
