import { getGlobalstyleClassNameFromStyle } from '../../../../components/RenderGlobalstyles/getGlobalstyleClassName';
import type { Stylesheet } from '../../flowTypes';
import Node from '../../parser/tree/Node';

export default (node: Node, globalStyles: Array<Stylesheet>): string => {
    const {
        ref,
        globalId
    } = node;

    let globalStyle = globalStyles.find(style => style.id === globalId);

    if (!globalStyle) {
        globalStyle = globalStyles.find(style => style.ref === ref);
    }

    return globalStyle ? getGlobalstyleClassNameFromStyle(globalStyle) : '';
};
