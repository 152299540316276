import * as R from "ramda";
import React from 'react';
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import currentOpenedStylesheetEpic from '../../Workspace/epics/currentOpenedStylesheet/index';
import stylesheetsEpic from "../../Workspace/epics/stylesheets/index";
import { getCenterPositionForDialog } from '../../DialogManager/utility';
import { Dialog, DialogBody } from "../../../view/common/dialogs/baseDialog/index";
import { dialogView as BackgroundDialogView } from './index';
import * as selectors from '../../Workspace/epics/stylesheets/selectors';
import type { BrowserDimensions } from '../../../redux/modules/children/dimensions/index';
import type { BackgroundDialogPropTypes } from "./flowTypes";
import type { Stylesheets } from "../../Workspace/epics/stylesheets/flowTypes";

type TableBackgroundDialogPropTypes = {
    autoColorMode: boolean,
    stylesheets: Stylesheets,
    colorGradientSettingsActions: Record<string, any>,
    backgroundSettingsActions: Record<string, any>,
    dispatch: Dispatch
}

const
    DIALOG_WIDTH_WHEN_SHOW_COLOR_SETTINGS_IS_OFF = 320,
    width = 618,
    height = 464,
    getMenuDividerBackgroundColorGradientData = (type, stylesheet) => ({
        color: selectors.menuDividerBackgroundColor(stylesheet),
        gradient: selectors.menuDividerBackgroundGradient(stylesheet),
        opacity: selectors.menuDividerBackgroundOpacity(stylesheet)
    }),
    getBackgroundColorGradientData = (type, stylesheet) => ({
        color: selectors.getBackgroundColor(type)(stylesheet),
        gradient: selectors.getBackgroundGradient(type)(stylesheet),
        opacity: selectors.getBackgroundOpacity(type, stylesheet)
    }),
    getMenuDividerBackgroundAssetData = (type, stylesheet) => ({
        asset: selectors.menuDividerBackgroundAsset(stylesheet),
        position: selectors.menuDividerBackgroundPosition(stylesheet),
        repeat: selectors.menuDividerBackgroundRepeat(stylesheet),
        size: selectors.menuDividerBackgroundSize(stylesheet)
    }),
    getBackgroundAssetData = (type, stylesheet) => ({
        asset: selectors.getBackgroundAsset(type)(stylesheet),
        position: selectors.getBackgroundPosition(type)(stylesheet),
        repeat: selectors.getBackgroundRepeat(type)(stylesheet),
        overlay: selectors.getBackgroundOverlay(type)(stylesheet),
        size: selectors.getBackgroundSize(type)(stylesheet)
    }),
    configurationDialogFactory = (
        type: Array<string>,
        configurationDialogId: string,
        colorGradientFetcherFn: Function,
        assetFetcherFn: Function
    ) => ({
        component: ({
            stylesheet,
            colorGradientSettingsActions,
            backgroundSettingsActions,
            dispatch,
            showColorSettings
        }: BackgroundDialogPropTypes) => (
            <Dialog>
                <DialogBody>
                    <VerticalSpacer y={40} />
                    <BackgroundDialogView
                        showColorSettings={showColorSettings}
                        colorGradientSettings={{
                            ...colorGradientFetcherFn(type, stylesheet),
                            ...colorGradientSettingsActions
                        }}
                        backgroundSettings={{
                            ...assetFetcherFn(type, stylesheet),
                            ...backgroundSettingsActions
                        }}
                        dispatch={dispatch}
                    />
                </DialogBody>
            </Dialog>
        ),
        confFactory: ({ browserWidth, browserHeight }: BrowserDimensions) => ({
            configurationDialogId,
            position: getCenterPositionForDialog(width, height, browserWidth, browserHeight),
            modal: false,
            dimensions: { width, height }
        }),
        dependsOn: {
            stylesheet: currentOpenedStylesheetEpic.reducer
        }
    }),
    menuDividerBackgroundConfigurationDialogFactory = (configurationDialogId: string, type: Array<string>) =>
        configurationDialogFactory(
            type, configurationDialogId, getMenuDividerBackgroundColorGradientData, getMenuDividerBackgroundAssetData
        ),
    backgroundConfigurationDialogFactory = (configurationDialogId: string, type: Array<string>) =>
        configurationDialogFactory(
            type, configurationDialogId, getBackgroundColorGradientData, getBackgroundAssetData
        ),
    tableBackgroundConfigurationDialogFactory = (configurationDialogId: string, type: string, ref: string) => ({
        component: ({
            stylesheets,
            colorGradientSettingsActions,
            backgroundSettingsActions,
            autoColorMode,
            dispatch
        }: TableBackgroundDialogPropTypes) => {
            const
                stylesheet = R.pipe(
                    selectors.getAllStylesheets,
                    selectors.getStylesByType(type),
                    selectors.getStyleByRef(ref)
                )(stylesheets);

            return (
                <Dialog>
                    <DialogBody>
                        <VerticalSpacer y={40} />
                        <BackgroundDialogView
                            showColorSettings={!autoColorMode}
                            // @ts-ignore
                            colorGradientSettings={{
                                ...getBackgroundColorGradientData([], stylesheet),
                                ...colorGradientSettingsActions
                            }}
                            // @ts-ignore
                            backgroundSettings={{
                                ...getBackgroundAssetData([], stylesheet),
                                ...backgroundSettingsActions
                            }}
                            dispatch={dispatch}
                        />
                    </DialogBody>
                </Dialog>
            );
        },
        confFactory: ({ browserWidth, browserHeight }: BrowserDimensions, { autoColorMode }: TableBackgroundDialogPropTypes) => {
            const DIALOG_WIDTH = autoColorMode ? DIALOG_WIDTH_WHEN_SHOW_COLOR_SETTINGS_IS_OFF : width;
            return {
                configurationDialogId,
                position: getCenterPositionForDialog(DIALOG_WIDTH, height, browserWidth, browserHeight),
                modal: false,
                dimensions: { width: DIALOG_WIDTH, height }
            };
        },
        dependsOn: {
            stylesheets: stylesheetsEpic.reducer
        }
    });

export {
    backgroundConfigurationDialogFactory,
    menuDividerBackgroundConfigurationDialogFactory,
    tableBackgroundConfigurationDialogFactory
};
