import makeEpic from '../../../../epics/makeEpic';
import { templateEdgeDecosEpicVAT } from './valueActionType';
import {
    ReceiveOnlyUserInteractionModeSelector
} from "../../epics/componentsEval/selectorActionTypes";
import type { BBox } from "../../../../redux/modules/children/workspace/flowTypes";
import workspaceBBoxValueActionType from "../../epics/workspaceBBox/valueActionType";
import { DistanceBBoxSelector } from "../../epics/resizeDecos/selectors";
import { MOVING_COMPONENTS_BY_ARROW_KEYS } from "../../epics/componentsEval/userInteractionMutations/interactionModes";
import { zeroBBox } from "../../../../utils/componentsMap/index";
import { ReceiveOnlyTemplateWidthActionType } from "../../../oneweb/Template/epics/template/selectorActionTypes";
import { receiveOnly } from "../../../../epics/makeCondition";

export type TemplateEdgeDecorationsState = {
    templateEdgeCoordinate: BBox,
    isVisible: boolean
}

const templateEdgeDecosDefaultState: TemplateEdgeDecorationsState = {
    templateEdgeCoordinate: zeroBBox,
    isVisible: false
};

export const templateEdgeDecosEpic = makeEpic({
    defaultState: templateEdgeDecosDefaultState,
    valueActionType: templateEdgeDecosEpicVAT,
    updaters: [
        {
            conditions: [
                ReceiveOnlyUserInteractionModeSelector,
                receiveOnly(workspaceBBoxValueActionType),
                DistanceBBoxSelector,
                ReceiveOnlyTemplateWidthActionType
            ],
            reducer: (
                { values: [
                    userInteractionMode,
                    workspaceBBox,
                    distanceBBoxState,
                    templateWidth
                ] }
            ) => {
                const { distanceBBox, selectionBBox, isVisible } = distanceBBoxState;

                if (!isVisible || userInteractionMode !== MOVING_COMPONENTS_BY_ARROW_KEYS) { // Make sure it will not react for mouse key up/down events.
                    return {
                        state: templateEdgeDecosDefaultState
                    };
                }

                const
                    templateEdgeCoordinate: BBox = {
                        bottom: workspaceBBox.bottom,
                        left: 0,
                        right: templateWidth,
                        top: 0
                    };

                if (isVisible &&
                    ((distanceBBox.right === 0 && selectionBBox.right === templateWidth) ||
                        (distanceBBox.left === 0 && selectionBBox.left === 0))) {
                    return {
                        state: { templateEdgeCoordinate, isVisible: true }
                    };
                }
                return {
                    state: templateEdgeDecosDefaultState
                };
            }
        }
    ]
});
