import React from 'react';
import styles from './BrokenMultimedia.css';
import { injectIntl } from '../../view/intl/index';

const BROKEN_MULTIMEDIA_TEXT_STYLE_LINE_HEIGHT = 20;
const MIN_HEIGHT = 150;
const MIN_WIDTH = 200;

export const BrokenMultimedia = injectIntl(({ width, height, text, intl }) => {
    const canFitText = (width >= MIN_WIDTH && height >= MIN_HEIGHT),
        canShowText = (text && canFitText),
        imgHeight = canShowText ? (height - BROKEN_MULTIMEDIA_TEXT_STYLE_LINE_HEIGHT) : height;
    return (
        <div className={styles.brokenMultimediaWrapper}>
            <img className={styles.brokenMultimediaImg} style={{ width, height: imgHeight }} />
            {canShowText && <div className={styles.brokenMultimediaText}>{intl.msgJoint(text)}</div>}
        </div>
    );
});
