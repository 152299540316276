import React from "react";
import cx from "classnames";
import debounce from "lodash/debounce"; // eslint-disable-line node/file-extension-in-import
import Swiper from 'swiper/dist/js/swiper';

import 'swiper/dist/css/swiper.css';
import { default as BaseComponent, StretchBase as StretchBaseComponent } from "../../../../view/oneweb/BaseComponent";
import View from './index';
import type { Props } from '../flowTypes';
import { ComponentMaskBackground } from "../../../presentational/ComponentMask/constants";
import ComponentMask from "../../../presentational/ComponentMask/index";
import sliderNavStyle from './workspace.css';
import { Msg } from "../../../../view/intl/index";

const navigationDotsBottomOffset = 14;

const
    mountSwiper = (el, json, adjustmentRequired, cb) => {
        if (el.swiper && el.swiper.destroyed) el.swiper.destroyed = false; // eslint-disable-line no-param-reassign
        //set flag to false because we already destroyed the slider
        const
            adjustNavigationDots = function () {
                const
                    // @ts-ignore
                    sliderContainer = this.el,
                    // @ts-ignore
                    currentSlideNode = this.slides[this.activeIndex],
                    dotsContainer = sliderContainer.querySelector(".navigationIconsTopMostWrapper .swiper-pagination");
                if (!currentSlideNode) return;
                const captionBoxNode = currentSlideNode.querySelector(".captionTitleContainer");
                if (!captionBoxNode) {
                    dotsContainer.style.bottom = navigationDotsBottomOffset + "px";
                    return;
                }
                dotsContainer.style.bottom = (captionBoxNode.clientHeight + navigationDotsBottomOffset) + "px";
            },
            newJson = {
                ...json,
                autoplay: false,
                draggable: false,
                on: {
                    init: function () { // eslint-disable-line
                        const
                            slides = el.swiper.slides,
                            duplicateFirstSlide = slides[el.swiper.slides.length - 1];

                        if (duplicateFirstSlide && duplicateFirstSlide.querySelector(".swiper-lazy")) {
                            duplicateFirstSlide.querySelector(".swiper-lazy").classList.remove("swiper-lazy-loading");
                        }
                        el.swiper.lazy.loadInSlide(slides.length - 1);
                        if (adjustmentRequired) adjustNavigationDots.apply(this);
                    },
                    slideChange: function () { // eslint-disable-line
                        if (adjustmentRequired) adjustNavigationDots.apply(this);
                    }
                }
            };

        const slider = new Swiper(el, newJson);
        cb(slider);
    },
    debounceMountSwiper = debounce(mountSwiper, 10),
    onMouseDown = (e) => {
        e.preventDefault();
        e.stopPropagation();
    },
    unMountSwiper = debounce(el => el.swiper && el.swiper.destroy(false), 10); // do not distory swiper object so that we can explicitly set destroyed flag to false while mounting because in some cases
    //swiper js gives destroyed flag value true after mounting.

type SliderState = {
    currentSlide: number,
    id: number
};

export class ImageSliderView extends React.PureComponent<Props, SliderState> {
    slider: any = null;

    getActionableDOMRef() {
        const carousel: React.ReactInstance = this.refs.carousel as Element;
        return carousel.childNodes[0];
    }

    constructor(props: Props) {
        super(props);
        this.state = {
            currentSlide: 0,
            id: Date.now()
        };
        this.slideNext = this.slideNext.bind(this);
        this.slidePrev = this.slidePrev.bind(this);
    }

    initSwiper(isDebounce: boolean = false) {
        const {
            images,
            json,
            captionsEnabled
        } = this.props;
        if (images.length !== 0) {
            const
                initialSlide = images.length <= this.state.currentSlide
                    ? images.length - 1 : this.state.currentSlide,
                newJson = {
                    ...json,
                    initialSlide
                };
            if (images.length <= this.state.currentSlide) {
                this.setState({
                    currentSlide: initialSlide
                });
            }
            const cb = (slider) => {
                this.slider = slider;
                this.slider.on('slideChange', () => {
                    this.setState({
                        currentSlide: this.slider.realIndex
                    });
                });
            };
            if (isDebounce) {
                debounceMountSwiper(this.getActionableDOMRef(), newJson, captionsEnabled, cb.bind(this));
            } else {
                mountSwiper(this.getActionableDOMRef(), newJson, captionsEnabled, cb.bind(this));
            }
        }
    }

    slideNext() {
        this.slider.slideNext();
    }

    slidePrev() {
        this.slider.slidePrev();
    }

    cleanupSwiper() {
        if (this.props.images.length !== 0) {
            unMountSwiper(this.getActionableDOMRef());
        }
    }

    componentDidMount() {
        this.initSwiper();
    }

    componentWillUnmount() {
        this.cleanupSwiper();
    }

    componentDidUpdate(prevProps: Props, prevState: SliderState) {
        // filter out change in the current slide
        if ((this.props.shouldComponentUpdate && prevState.currentSlide === this.state.currentSlide)
            || this.props.templateWidth !== prevProps.templateWidth
            || this.props.width !== prevProps.width) {
            // change in crop, animation, dimension, caption etc should re-initialize swiper
            this.cleanupSwiper();
            this.initSwiper(true);
        }
    }

    getSpecificStyle() {
        const { fullWidthOption, templateWidth, isStretch } = this.props;
        if (isStretch) {
            // needed to put style because inline style is getting removed when unmounting slider
            // removing inline style is needed to reset any styles made by a specific slider effect
            return (
                <style>
                    {`div[data-id='${this.state.id}'] > div.swiper-container {
                    max-width: ${fullWidthOption.maxWidth}px;
                    width: ${100 - (fullWidthOption.margin * 2)}%;
                    min-width: ${templateWidth}px;
                }`}
                </style>
            );
        }
        return null;
    }

    render() {
        const
            isStretch = this.props.isStretch,
            workspaceNavCntrlstyle = isStretch ? { left: `calc(((100% - ${this.props.templateWidth}px) / 2) + 20px)` } : undefined;
        return (
            <div className={sliderNavStyle.slideWrapper}>
                {this.getSpecificStyle()}
                {!!this.props.images.length && <div ref="carousel" style={{ width: 'inherit', height: 'inherit' }} data-id={this.state.id}>
                    <View
                        width={this.props.width}
                        captionOnTop={this.props.captionOnTop}
                        images={this.props.images}
                        isMVEFocus={this.props.isMVEFocus}
                        containerStyle={this.props.containerStyle}
                        captionsEnabled={this.props.captionsEnabled}
                        captionTitleBoxClasses={this.props.captionTitleBoxClasses}
                        containerClassname={this.props.containerClassname}
                        isWorkspace={this.props.isWorkspace}
                        globalVariables={this.props.globalVariables}
                        crop={this.props.crop}
                        originalSize={this.props.originalSize}
                        site={this.props.site}
                        isStretch={isStretch}
                        fullWidthOption={this.props.fullWidthOption}
                        templateWidth={this.props.templateWidth}
                    />
                    <div
                        className={
                            cx(sliderNavStyle.navigatorWrapper, { [sliderNavStyle.show]: this.props.isSelected })
                        }
                        style={workspaceNavCntrlstyle}
                    >
                        <div className={sliderNavStyle.navigator}>
                            <div
                                className={cx(sliderNavStyle.arrow, sliderNavStyle.left)}
                                onMouseDown={onMouseDown}
                                onClick={this.slidePrev}
                            />
                            <div className={sliderNavStyle.info}>
                                <Msg
                                    k="component.imageSlider.counter"
                                    params={{
                                        currentSlide: this.state.currentSlide + 1,
                                        totalImages: this.props.images.length
                                    }}
                                >
                                    {`{currentSlide, number} of {totalImages, number}`}
                                </Msg>
                            </div>
                            <div
                                className={cx(sliderNavStyle.arrow, sliderNavStyle.right)}
                                onClick={this.slideNext}
                                onMouseDown={onMouseDown}
                            />
                        </div>
                    </div>
                </div>}
            </div>
        );
    }
}

export default (props: Props) => {
    if (props.base.stretch) {
        return (
            <StretchBaseComponent {...props}>
                <ComponentMask maskStyle={ComponentMaskBackground.LIGHT}>

                    <ImageSliderView {...props} />
                </ComponentMask>
            </StretchBaseComponent>
        );
    }
    return (
        <BaseComponent {...props}>
            <ComponentMask maskStyle={ComponentMaskBackground.LIGHT}>

                <ImageSliderView {...props} />
            </ComponentMask>
        </BaseComponent>
    );
};
