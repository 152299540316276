const Cryptojs = require("crypto-js");
const AES = require("crypto-js/aes");

const encryptStr = (
    secret/*: string */,
    str/*: string */
)/*: string*/ => {
    let encrypted = AES.encrypt(
        str, secret
    ).toString();
    const b64 = Cryptojs.enc.Base64.parse(encrypted);
    encrypted = b64.toString(Cryptojs.enc.Hex);
    return encrypted;
};

module.exports = {
    encryptStr,
};
