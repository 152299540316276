import React from 'react';
import View from './index';
import { StretchBase as StretchBaseComponent } from '../../../../view/oneweb/BaseComponent';
import type { StripViewProps } from '../flowTypes';

export default (props: StripViewProps) => (
    <StretchBaseComponent {...props}>
        <View {...props} />
    </StretchBaseComponent>
);
