import cx from 'classnames';
import getCenteredDialog from "../../../DialogManager/getCenteredDialogConfig";
import { configurationDialogFactory } from '../configurationDialogFactory';
import { DialogWidth, BingMapsWidgetDialogHeight } from '../constants';
import { WIDGETS_BINGMAPS_SET_LINK } from "./actionTypes";
import { validateLinkOrCode } from '../utils';
import { isBingMapsLinkValid, parseBingMapsCode } from './utils';
import Icons from '../view/Icons.css';

const validateBingMapsData = validateLinkOrCode([isBingMapsLinkValid], parseBingMapsCode);

const
    configurationDialogId = "WIDGETS_BINGMAPS_CONFIG_DIALOG",
    configurationDialogView = configurationDialogFactory({
        saveAction: WIDGETS_BINGMAPS_SET_LINK,
        dialogIconClass: cx(Icons.bingMaps, Icons.dialog),
        dialogTitle: "msg: widgets.bingmaps.label {Bing Maps}",
        info: "msg: widgets.bingmaps.config.addBingMaps {Add a Bing Map to your page.}",
        subInfo: "msg: widgets.bingmaps.config.info {Go to Bing Maps and find the location you want to share. Select the share icon, copy the link and paste it below. }", // eslint-disable-line max-len
        learnMore: "msg: widgets.bingmaps.config.learnMore {https://help.one.com/hc/en-us/articles/11406192794513}", // TODO: Fix this
        inputPlaceHolder: "msg: widgets.bingmaps.configDialog.placeHolder {Paste Bing Maps link}",
        isLinkValid: validateBingMapsData,
        termsOfService: "msg: widgets.bingmaps.config.termsOfService {By using this widget, you agree to Bing\'s {termsOfServiceLink}.}",
        termsOfServiceLink: "msg: widgets.bingmaps.config.termsOfServiceLink {https://www.microsoft.com/en-us/maps/product }",
    }),
    configurationDialog = getCenteredDialog({
        width: DialogWidth,
        height: BingMapsWidgetDialogHeight,
        component: configurationDialogView,
    });

export { configurationDialogId, configurationDialog };

