import { withSelector } from "../../../../epics/makeCondition";
import resizeDecosValueActionType from "./valueActionType";
import { memoMaxOne } from "../../../../../utils/memo";

const
    isResizingVisibleSelector = (state: Record<string, any>): boolean => !!(state && state.isVisible),
    getResizeDecosMemoized = memoMaxOne(
        (distanceBBox, selectionBBox, isVisible) => ({ distanceBBox, selectionBBox, isVisible })
    ),
    distanceBBoxSelector = (
        { state: { distanceBBox, selectionBBox, isVisible } }: Record<string, any>
    ): Record<string, any> => getResizeDecosMemoized(distanceBBox, selectionBBox, isVisible),
    DistanceBBoxSelector = withSelector(resizeDecosValueActionType, distanceBBoxSelector);

export {
    isResizingVisibleSelector,
    DistanceBBoxSelector
};
