/* eslint-disable max-len */
import * as R from "ramda";
import React from "react";
import { pure } from "recompose";
import {
    MctaVerticalBar,
    MctaButton,
    MctaCombobox,
    MctaPropertyContainer,
    MctaInput,
} from "../../../componentMainActions/index";
import { MctaColor } from "../../../componentMainActions/MctaColor/index";
import * as layoutTypes from "../constants/layoutType";
import * as actionTypes from "../actionTypes";
import HorizontalSpacer from "../../../../view/common/HorizontalSpacer";
import type { DataSiteItem } from "../../../../../dal/model/utils/dataSiteItemUtils";
import { LinkPage } from "../../../../utils/page";
import styles from "./menuMCTA.css";
import HelpTip from "../../../Panel/view/HelpTip";
import { DEFAULT_COLOR_PICKER } from "../../../ColorPicker/constants";
import { getDefaultAccentColor } from "../utils";
import openTopBarPopup from "../../../TopBar/actionCreators/openTopBarPopup";
import { Popups } from "../../../TopBar/constants";
import type { CTAViewParams } from "../../../../view/Workspace/Decorations/ComponentMainActions/flowTypes";
import type { MenuComponent } from "../flowTypes";

function getNestingLevel(page: DataSiteItem) {
    if (page.type === LinkPage && page.items.length > 0) {
        return 1 + getPagesMaxNestingLevel(page.items);
    } else {
        return 1;
    }
}

function getPagesMaxNestingLevel(pages: Array<DataSiteItem>) {
    return Math.max(...pages.map(getNestingLevel));
}

const levelMsg =
    "msg: component.menu.pagesOnLevel {Pages on {level, plural, =1 {first} =2 {second} =3 {third} =4 {fourth} =5 {fifth} =6 {sixth} =7 {seventh} =8 {eighth} =9 {ninth} =10 {tenth} other {{level}}} level}",
    // eslint-disable-line max-len
    layoutButtonsIcons = {
        [layoutTypes.VERTICAL_TREE]: "MENU_VERTICAL_TREE",
        [layoutTypes.HORIZONTAL_SINGLE_LEVEL]: "MENU_HORIZONTAL_SINGLE_LEVEL",
        [layoutTypes.VERTICAL_DROPDOWN]: "MENU_VERTICAL_DROPDOWN",
        [layoutTypes.HORIZONTAL_DROPDOWN]: "MENU_HORIZONTAL_DROPDOWN",
        [layoutTypes.VERTICAL_SINGLE_LEVEL]: "MENU_VERTICAL_SINGLE_LEVEL"
    },
    EditView = pure(
        ({
            dispatch,
            selectedComponent,
            siteData: {
                folder: { items }
            },
            isInsideHeaderOrFooter,
            isModernLayoutActivated,
            children,
            colorPickerOpened,
            globalstyles,
            themeSettingsData: { autoColorMode }
        }) => {
            const pagesMaxNestingLevel = getPagesMaxNestingLevel(items),
                availableMaxNestingLevels = R.range(1, pagesMaxNestingLevel + 1).map(level => ({
                    value: level,
                    label: [
                        levelMsg,
                        {
                            level
                        }
                    ]
                })),
                layoutButtonsProps = R.mapObjIndexed(
                    (icon, key) => ({
                        icon,
                        className: styles.menuIcons,
                        title: layoutTypes.intlDict[key],
                        onClick: () =>
                            dispatch({
                                type: actionTypes.MENU_PROP_PANEL_LAYOUT_BTN_PRESSED,
                                payload: key
                            })
                    }),
                    layoutButtonsIcons
                ),
                accentColor = (selectedComponent.style && selectedComponent.style.accentColor) || getDefaultAccentColor(globalstyles),
                renderLayoutTypeButton = layoutType => (
                    <div className={styles.menuIconContainer}>
                        <MctaButton {...layoutButtonsProps[layoutType]} selected={selectedComponent.layoutType === layoutType} />
                    </div>
                ),
                moreBtnTextActionCreator = text => ({
                    type: actionTypes.MENU_PROP_PANEL_MORE_BTN_TEXT_CHANGED,
                    payload: text,
                    amendToSelf: true
                }),
                renderHtml =
                    isInsideHeaderOrFooter && isModernLayoutActivated ? (
                        <div>
                            {!autoColorMode && <HorizontalSpacer x={5} />}
                            {!autoColorMode && (
                                <MctaColor
                                    colorPickerKind={DEFAULT_COLOR_PICKER}
                                    color={accentColor}
                                    onColorChangeAction={actionTypes.MENU_PROP_PANEL_ACCENT_COLOR_CHANGE}
                                    colorPickerOpened={colorPickerOpened}
                                    onTheFlyChange
                                    isAccentColour
                                    dispatch={dispatch}
                                />
                            )}
                            <HorizontalSpacer x={5} />
                            <MctaInput
                                value={selectedComponent.moreText}
                                className={styles.moreBtnInput}
                                onChange={text => dispatch(moreBtnTextActionCreator(text))}
                                label="msg: component.menu.propPanel.titleMoreBtnText {Title on “More” button}"
                                placeholder="msg: component.menu.propPanel.moreTitle {More}"
                            />
                            <HorizontalSpacer x={5} />
                            <HelpTip
                                msg="msg: component.menu.propPanel.moreBtnHelp {If your pages don’t all fit in the menu, they will be listed under this button.}" // eslint-disable-line max-len
                                dispatch={dispatch}
                            />
                        </div>
                    ) : (
                        <div>
                            <HorizontalSpacer x={5} />
                            <MctaPropertyContainer label="msg: component.menu.propPanel.startLevel {Start level:}">
                                <MctaCombobox
                                    searchable={false}
                                    options={availableMaxNestingLevels}
                                    value={selectedComponent.startLevel}
                                    className={styles.startLevelCombobox}
                                    onChange={({ value }) =>
                                        dispatch({
                                            type: actionTypes.MENU_PROP_PANEL_START_LEVEL_CHANGED,
                                            payload: value
                                        })}
                                />
                            </MctaPropertyContainer>
                            <HorizontalSpacer x={5} />
                            <MctaVerticalBar />
                            <HorizontalSpacer x={5} />
                            {renderLayoutTypeButton(layoutTypes.VERTICAL_TREE)}
                            <HorizontalSpacer x={5} />
                            <MctaVerticalBar />
                            <HorizontalSpacer x={5} />
                            {renderLayoutTypeButton(layoutTypes.HORIZONTAL_SINGLE_LEVEL)}
                            <HorizontalSpacer x={5} />
                            <MctaVerticalBar />
                            <HorizontalSpacer x={5} />
                            {renderLayoutTypeButton(layoutTypes.VERTICAL_DROPDOWN)}
                            <HorizontalSpacer x={5} />
                            <MctaVerticalBar />
                            <HorizontalSpacer x={5} />
                            {renderLayoutTypeButton(layoutTypes.HORIZONTAL_DROPDOWN)}
                            <HorizontalSpacer x={5} />
                            <MctaVerticalBar />
                            <HorizontalSpacer x={5} />
                            {renderLayoutTypeButton(layoutTypes.VERTICAL_SINGLE_LEVEL)}
                            {children && <HorizontalSpacer x={5} />}
                            {children}
                        </div>
                    );

            return renderHtml;
        }
    );
export default {
    editView: EditView,
    editButtonText: "msg: component.menu.mcta.editMenu {Edit menu}",
    ctaButtonText: "msg: component.menu.mcta.manageMenu {Manage menu}",
    ctaOnClick: ({ dispatch }: CTAViewParams<MenuComponent>) => {
        return dispatch(
            openTopBarPopup({
                id: Popups.PAGES
            })
        );
    }
};
