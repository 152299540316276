import React from 'react';
import cx from 'classnames';
import { Box } from '../../../view/reflexbox/index';
import VerticalSpacer from '../../../view/common/VerticalSpacer';
import type { BackgroundSettingPropTypes } from './flowTypes';
import styles from './BackgroundSetting.css';
import injectIntl from "../../../view/intl/injectIntl";
import { VideoChooserBtnPP } from "../../FileManager/videoChooser/videoChooserBtnPP";
import { VideoUtils } from '../../../utils/fileUtils';

const getLabelFromAsset = asset => asset.url.split('/').slice(-1)[0];

export default injectIntl(({
    asset,
    assetChangeAction,
    assetRemoveAction,
    className,
    dispatch,
    hideHeadLabel,
    disabled,
    selectTitle = "msg: video {Video}",
    selectPlaceholderLabel = "msg: addVideo {Add video}"
}: BackgroundSettingPropTypes) => {
    return (
        <Box className={cx(className, { [styles.disabled]: disabled })}>
            {/* @ts-ignore */}
            <VideoChooserBtnPP
                asset={asset}
                headLabel={hideHeadLabel ? undefined : selectTitle}
                chooseLabel={selectPlaceholderLabel}
                changeAction={assetChangeAction}
                removeAction={assetRemoveAction}
                dispatch={dispatch}
                hideFreeOneComImagesTab={false}
                hideShutterstockImagesTab
                hideMyImagesTab
            />
            {
                !disabled && asset && VideoUtils.isVideoFile(getLabelFromAsset(asset)) &&
                <div>
                    <VerticalSpacer y={10} />
                </div>
            }
            {disabled && <div className={styles.disabledOverlay} />}
        </Box>
    );
});
