/**
 * This file has to be updated for every new version for the mve modal popup.
 * Increment version for every modal popup changes. Increment version to 2 for next change;
 */

const
    version = 1,
    mobileViewEditorVersion = "mve_v" + version;

export {
    mobileViewEditorVersion as default,
    mobileViewEditorVersion
};
