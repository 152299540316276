import { DataSite } from "../../../../../dal/model/index";
import { DATA_PAGE_REF_TYPE, DATA_LINK_PAGE_TYPE, DATA_LINK_SECTION_TYPE } from '../../../../../dal/model/utils/dataSiteItemTypes';
import type { DataSiteItem } from '../../../../../dal/model/utils/dataSiteItemUtils';
import getPageHref from "../../../oneweb/Menu/getPageHref";
import type { MenuItems } from '../types';
import { filterHiddenPagesRecursive, makeSetCurrentPageIdAndHasChildren } from '../../../oneweb/Menu/utils';
import { selectPagesFromLevel } from '../../../oneweb/Menu/selectors';

const
    filterHiddenItems = items => items.filter(item => !item.hidden);

const generateMenuItems =
    (currentPageId: string, previewBackupTime?: number, homePageId?: string,) =>
        (items: Array<DataSiteItem>, topLevelItems: Array<DataSiteItem>): [MenuItems, boolean] => {
            if (!items || items.length === 0) {
                return [[], false];
            }
            const menuItems: MenuItems = [];

            let hasExpanded = false;

            items.forEach(item => {
                if (item.hidden) {
                    return;
                }

                const
                    itemItems = item.type === DATA_PAGE_REF_TYPE ? filterHiddenItems(item.items) : [],
                    isCurrent = item.type === DATA_PAGE_REF_TYPE ? item.pageId === currentPageId : false,
                    target = item.type === DATA_LINK_PAGE_TYPE && item.target !== '_self' ?
                        item.target : '',
                    href = getPageHref(item, topLevelItems, previewBackupTime),
                    [children, childrenHasExpanded] = generateMenuItems(
                        currentPageId,
                        previewBackupTime,
                        homePageId,
                    )(itemItems, topLevelItems),
                    isExpanded = childrenHasExpanded || isCurrent,
                    name = item.name,
                    id = item.id,
                    sectionId = item.type === DATA_LINK_SECTION_TYPE ? item.sectionId : undefined,
                    pageId = item.type !== DATA_LINK_PAGE_TYPE ? item.pageId : undefined;

                hasExpanded = hasExpanded || isExpanded;
                menuItems.push({

                    isHome: item.pageId === homePageId,
                    type: item.type,
                    isExpanded,
                    isCurrent,
                    children,
                    href,
                    target,
                    name,
                    id,
                    sectionId,
                    pageId,
                });
            });

            return [menuItems, hasExpanded];
        };

export const getMenuItems = (
    siteData: DataSite,
    currentPageId: string,
    level: number,
    previewBackupTime?: number,
): MenuItems => {
    if (!siteData) {
        return [];
    }
    let { folder } = siteData;

    const preparePages = makeSetCurrentPageIdAndHasChildren(currentPageId),
        topLevelPages = preparePages(folder.items);
    const pages = filterHiddenPagesRecursive(selectPagesFromLevel({
        pages: topLevelPages,
        level
    }));

    return generateMenuItems(currentPageId, previewBackupTime, siteData.homePageId)(pages, topLevelPages)[0];
};

export const getHomeMenuItem = (
    siteData: DataSite,
) => {
    if (!siteData) {
        return null;
    }
    const { folder, homePageId } = siteData;
    const homePage = folder.items.find((item: DataSiteItem) =>
        (item.type === DATA_PAGE_REF_TYPE && item.public && item.pageId === homePageId));
    if (!homePage) {
        return null;
    }
    return {
        isHome: true,
        type: homePage.type,
        href: getPageHref(homePage, folder.items),
        name: homePage.name,
        id: homePage.id,
        hidden: homePage.hidden
    };
};
