import React from 'react';
import Icons from '../../../view/Icons/index';
import IconButtonsGroup from './index';
import styles from '../../buttons.css';

type PropTypes = {
    onPrevClicked: Function,
    onNextClicked: Function,
    dispatch: Dispatch
}

const
    buttons = [
        { title: 'msg: common.previous {Previous}', icon: Icons.LEFT, onClick: 'onPrevClicked' },
        { title: 'msg: common.next {Next}', icon: Icons.RIGHT, onClick: 'onNextClicked' }
    ],
    PrevNextButtonGroup = (props: PropTypes) => {
        const buttonGroup = buttons.map(btnCfg => ({
            ...btnCfg,
            onClick: props[btnCfg.onClick],
            className: styles.buttonSelect
        }));

        return (
            <IconButtonsGroup buttonGroup={buttonGroup} dispatch={props.dispatch} />
        );
    };

export default PrevNextButtonGroup;
