import { UPGRADE } from "../../App/actionTypes";

export const
    InstagramNotAllowedInNormalWSB = 'InstagramNotAllowedInNormalWSB';

export const
    ErrorMsgsOnAdd = {
        [InstagramNotAllowedInNormalWSB]: {
            header: 'msg: component.instagramGallery.premiumFeature {{premiumPackageName} feature}',
            // eslint-disable-next-line max-len
            text: 'msg: component.instagramGallery.notAllowedInNormalWSB {Your current Website Builder does not include Instagram galleries. Upgrade to {premiumPackageName} to get this and other great features.}',
            linkText: 'msg: common.learnMore {Learn more}',
            linkCustomActionDispatch: {
                actionType: UPGRADE,
                actionPayload: "Workspace:InstagramPremiumComponent"
            }
        }
    };
