import * as editorCommands from "../editorUtils/editorCommands";
import { makeApplyEditorChangesReducer } from "./helpers/helpers";
import { TINY_MCE_SET_HORIZONTAL_ALIGNMENT } from "../actionTypes";

const
    aligmentToCmdMap = {
        left: editorCommands.ALIGN_LEFT,
        right: editorCommands.ALIGN_RIGHT,
        center: editorCommands.ALIGN_CENTER,
        justify: editorCommands.ALIGN_FULL
    },
    setHorizontalAlignmentUpdater = {
        conditions: [TINY_MCE_SET_HORIZONTAL_ALIGNMENT],
        reducer: makeApplyEditorChangesReducer(({ values: [alignment], state, scope, editor }) => {
            editor.execCommand(aligmentToCmdMap[alignment]);
            return {
                state: {
                    ...state,
                    alignment
                },
                scope
            };
        })
    };

export {
    setHorizontalAlignmentUpdater
};
