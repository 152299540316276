import React from "react";
export const SvgFacebookRoundedFillMulticolor = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <rect width={48} height={48} fill="#1877f2" rx={6} />
            <path
                fill="#ffffff"
                d="M28.312 24.629l.572-3.742h-3.578V18.46c0-1.023.5-2.02 2.102-2.02h1.627v-3.186S27.56 13 26.148 13c-2.947 0-4.873 1.792-4.873 5.036v2.851H18v3.742h3.275v9.045a12.953 12.953 0 004.031 0v-9.045h3.006"
            />
        </g>
    </svg>
);
