import {
    ACTIVATE_MODERN_HEADER,
    ACTIVATE_MODERN_FOOTER,
    HIDE_MODERN_LAYOUT_LEFT_PANEL
} from "./actionTypes";
import type { ComponentsIds } from "../../redux/modules/children/workspace/flowTypes";
import type { AnyComponent } from "../oneweb/flowTypes";
import { WORKSPACE_NEW_COMPONENT_ADDED } from "../Workspace/actionTypes";

export const
    activateModernHeaderAction = (layoutId: string) => ({
        type: ACTIVATE_MODERN_HEADER,
        payload: layoutId
    }),
    activateModernFooterAction = (layoutId: string) => ({
        type: ACTIVATE_MODERN_FOOTER,
        payload: layoutId
    }),
    componentsAddedToWorkspaceAction = (id: string, component: AnyComponent, newCmpIds: ComponentsIds = []) => ({
        type: WORKSPACE_NEW_COMPONENT_ADDED,
        payload: { id, component, newCmpIds }
    }),
    hideModernLayoutAC = () => ({ type: HIDE_MODERN_LAYOUT_LEFT_PANEL });
