/* eslint-disable */
import React from "react";
import injectIntl from "../../../view/intl/injectIntl";
import styles from './NoPages.css';
import cx from 'classnames'

export default injectIntl(({ intl, className, style }) => (
    <div className={cx(styles.noPagesContainer, className)} style={style}>
        <div className={styles.noPagesText}>
            {intl.msgJoint('msg: web.panels.Preview.NoPagesAtThisPointLine1 {You didn\'t have any pages at this point.}')}
            <br/>
            {intl.msgJoint('msg: web.panels.Preview.NoPagesAtThisPointLine2 {Please select another saved backup.}')}
        </div>
    </div>
));
