import { overlapsVertically } from "../Preview/flattening/util";
import { getBBox } from "../../utils/bBox";
import isStretchComponentKind from '../oneweb/isStretchComponentKind';
import { normalKindsMap, backgroundKindsMap, topMarginDefaults, cmpTypes } from './constants';
import getId from "./getId";
import type { Groups, Sequence } from "./flowTypes";
import type { ComponentsMap } from "../../redux/modules/children/workspace/flowTypes";
import getCmpTypeById from "./getCmpTypeById";
import { isOverlappingHorizontally } from "./util";
import { isBoxKind } from "../oneweb/componentKinds";
import GalleryKind from "../oneweb/Gallery/kind";

const
    isContainerComponent = ({ id, kind }, data) => !!data[id] || backgroundKindsMap.hasOwnProperty(kind),
    isEmptyContainer = ({ id, kind }, data, actualId) => id === actualId && isStretchComponentKind(kind) && !data[id],
    isComponentsTouching = (aboveCmpBBox, belowCmpBBox) => (
        ((belowCmpBBox.top === aboveCmpBBox.bottom) && overlapsVertically(aboveCmpBBox, belowCmpBBox))
        || ((aboveCmpBBox.right === belowCmpBBox.left) && isOverlappingHorizontally(aboveCmpBBox, belowCmpBBox))
    ),
    roundBbox = ({ left, right, top, bottom }) => ({
        left: Math.round(left),
        right: Math.round(right),
        top: Math.round(top),
        bottom: Math.round(bottom)
    }),
    getCmpId = (id, groups) => {
        if (getCmpTypeById(id) === cmpTypes.group) {
            return groups[id][0];
        }
        return getId(id);
    };

const calcTopMargin = ({ aboveCmp, belowCmp, data }) => {
    if (isStretchComponentKind(aboveCmp.kind, aboveCmp.stretch) && isStretchComponentKind(belowCmp.kind, belowCmp.stretch)) {
        return 0;
    }
    const aboveCmpBBox = roundBbox(getBBox(aboveCmp)),
        belowCmpBBox = roundBbox(getBBox(belowCmp));
    if (isComponentsTouching(aboveCmpBBox, belowCmpBBox) || isComponentsTouching(belowCmpBBox, aboveCmpBBox)) {
        return 0;
    }
    const isAboveCmpAContainer = isContainerComponent(aboveCmp, data),
        isBelowCmpAContainer = isContainerComponent(belowCmp, data);

    if (isAboveCmpAContainer && isBelowCmpAContainer) {
        return topMarginDefaults.small;
    }
    if (!isAboveCmpAContainer && !isBelowCmpAContainer) {
        return topMarginDefaults.medium;
    }

    return topMarginDefaults.large;
};

const getFirstOrLastCmpMargins = ({ kind, stretch = false }) => {
    // TODO: fix comparison (boolean === string ???)
    // @ts-ignore
    if (isStretchComponentKind(kind, stretch) && !kind === GalleryKind) {
        return 0;
    }
    return normalKindsMap.hasOwnProperty(kind)
        ? topMarginDefaults.firstChildNormalCmp
        : topMarginDefaults.firstChildSmall;
};

const updateStyleOfFirstLastCmp = (cmp, styles, prop) => {
    if (isBoxKind(cmp.kind)) {
        styles[cmp.id][prop] = topMarginDefaults.firstChildSmall; // eslint-disable-line no-param-reassign
    } else if (!isStretchComponentKind(cmp.kind, cmp.stretch)) {
        styles[cmp.id][prop] = topMarginDefaults.large; // eslint-disable-line no-param-reassign
    }
};

const getPrevComponent = (id: string, sequence: Array<string>, data: Sequence, groups: Groups, componentsMap: ComponentsMap) => {
    const index = sequence.indexOf(id) - 1;
    if (index >= 0) {
        const compId = sequence[index],
            prevCmp = componentsMap[getCmpId(compId, groups)];
        if (isStretchComponentKind(prevCmp.kind) && !data[compId]) {
            return getPrevComponent(compId, sequence, data, groups, componentsMap);
        }
        return prevCmp;
    }
    return null;
};

export default (root: string, data: Sequence, componentsMap: ComponentsMap, groups: Groups) => {
    let styles = {};
    const fillStyle = (parentId) => {
        let prevCmp, sequence, firstCmp, lastCmp, firstCmpIndex = 0;
        sequence = data[parentId];
        if (!sequence || !sequence.length) {
            return;
        }
        styles[parentId] = styles[parentId] || {};
        sequence.forEach((id, i) => {
            const cmpId = getCmpId(id, groups),
                cmp = componentsMap[cmpId];
            if (i !== 0) {
                styles[id] = styles[id] || {};
                prevCmp = getPrevComponent(id, sequence, data, groups, componentsMap);
                if (prevCmp) {
                    styles[id].marginTop = calcTopMargin({
                        aboveCmp: prevCmp,
                        belowCmp: cmp,
                        data
                    });
                } else {
                    firstCmpIndex = i;
                }
            }
            if (data[id]) {
                fillStyle(id);
            }
            if (isEmptyContainer(cmp, data, id)) {
                const lastCmpId = getCmpId(sequence[sequence.length - 1], groups);
                if (lastCmpId === id) {
                    styles[id] = { ...styles[id], visibility: 'hidden', height: 0 };
                } else {
                    styles[id] = { visibility: 'hidden', height: 0 };
                }
            }
        });
        firstCmp = componentsMap[getCmpId(sequence[firstCmpIndex], groups)];
        styles[firstCmp.id] = styles[firstCmp.id] || {};
        lastCmp = componentsMap[getCmpId(sequence[sequence.length - 1], groups)];
        styles[lastCmp.id] = styles[lastCmp.id] || {};
        if (parentId === root) {
            updateStyleOfFirstLastCmp(firstCmp, styles, 'marginTop');
            updateStyleOfFirstLastCmp(lastCmp, styles, 'marginBottom');
        } else {
            styles[firstCmp.id].marginTop = getFirstOrLastCmpMargins(firstCmp);
            styles[lastCmp.id].marginBottom = getFirstOrLastCmpMargins(lastCmp);
        }
        if (getCmpTypeById(sequence[0]) === cmpTypes.group) {
            styles[sequence[0]] = { ...styles[firstCmp.id] };
            delete styles[firstCmp.id];
        }
        if (getCmpTypeById(sequence[sequence.length - 1]) === cmpTypes.group) {
            const groupId = sequence[sequence.length - 1];
            styles[groupId] = { ...(styles[groupId] || {}), ...styles[lastCmp.id] };
            delete styles[lastCmp.id];
        }
    };
    fillStyle(root);
    return Object.keys(styles).reduce((acc, id) => {
        if (Object.keys(styles[id]).length) {
            acc[id] = styles[id];
        }
        return acc;
    }, {});
};
