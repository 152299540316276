import { isEcommerceSubscription } from "../../../../../../wbtgen/src/components/App/epics/subscriptionData/utils";
import common from "../../../../../../wbtgen/src/view/Theme/common.css";

enum SeoScoreRange {
    LOW = "LOW",
    AVERAGE = "AVERAGE",
    GOOD = "GOOD",
    GREAT = "GREAT",
}

const SEO_SCORE_RANGE_CONFIG = {
    [SeoScoreRange.LOW]: {
        color: common.colorSpanishOrange,
        title: "msg: seoScan.widget.seoScoreLow.title {Your SEO score is low}",
        description: "msg: seoScan.widget.seoScoreLow.description {Look at our list of tasks that you can complete today to make sure your website shows up in search engine results.}",
    },
    [SeoScoreRange.AVERAGE]: {
        color: common.colorScienceBlue,
        title: "msg: seoScan.widget.seoScoreAverage.title {Your SEO score could be improved}",
        description: "msg: seoScan.widget.seoScoreAverage.description {Check which tasks you can complete to optimise how your website ranks in search engine results.}",
    },
    [SeoScoreRange.GOOD]: {
        color: common.colorZundaGreen,
        title: "msg: seoScan.widget.seoScoreGood.title {Your SEO score is good}",
        description: "msg: seoScan.widget.seoScoreGood.description {Complete just a few more tasks to ensure that your target audience easily finds your website.}",
    },
    [SeoScoreRange.GREAT]: {
        color: common.colorZundaGreen,
        title: "msg: seoScan.widget.seoScoreGreat.title {Well done! Your SEO score is great}",
        description: "msg: seoScan.widget.seoScoreGreat.description {Your website is performing well in search engine results. Continue optimising your site to ensure that your SEO score stays high.}",
    },
};

const SEO_SCORE_UPGRADE_MESSAGE = "msg: seoScan.widget.upgrade_new {Upgrade to the {packageName} plan to get valuable tips on how to improve it.}";

const getSeoScoreRange = (score: number): SeoScoreRange => {
    if (score > 85) return SeoScoreRange.GREAT;
    if (score > 66) return SeoScoreRange.GOOD;
    if (score > 33) return SeoScoreRange.AVERAGE;
    return SeoScoreRange.LOW;
};

export const getScoreProgressColor = (score: number) => {
    return SEO_SCORE_RANGE_CONFIG[getSeoScoreRange(score)].color;
};

export const getScoreTextTitle = (score: number): string => {
    return SEO_SCORE_RANGE_CONFIG[getSeoScoreRange(score)].title;
};

export const getScoreTextDescription = (score: number, subscriptionType: string, packageName: string): MsgJointInput => {
    if (isEcommerceSubscription(subscriptionType)) {
        return SEO_SCORE_RANGE_CONFIG[getSeoScoreRange(score)].description;
    }
    return [SEO_SCORE_UPGRADE_MESSAGE, { packageName }];
};
