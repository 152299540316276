import { flip, pickBy, values } from 'ramda';
import type { ComponentBase } from '../../components/oneweb/flowTypes';

import type {
    ComponentsMap,
    ComponentsTreeNode,
    ComponentsTreeLinkedNode,
    ComponentsTreeLinkedNodesMap,
    AnyComponent,
    BBox
} from "../../redux/modules/children/workspace/flowTypes";

import {
    getComponentsBBox,
    doBBoxIntersect, traverseRelInMap
} from "./index";

import {
    SpaceAdjusterMinimumBetweenComponentsPx
} from "../../constants/app";

import { isShiftBarTop, isShiftBarBottom } from '../handle/index';
import ImageComponentKind from "../../components/oneweb/Image/kind";
import isStretchComponentKind from "../../components/oneweb/isStretchComponentKind";
import { componentIsGhost } from "../../components/oneweb/Code/getComponentsMapNoGhosts";
import { isBoxKind, isHoverBoxKind } from "../../components/oneweb/componentKinds";
import { isContainer } from "../component";
import type { HoverBoxComponent } from "../../components/oneweb/HoverBox/flowTypes";
import { isModernHeaderOrFooter } from "../../components/ModernLayouts/utils";

type ComponentDistance = { node: ComponentsTreeLinkedNode | ComponentsTreeNode, distance: number }
type Line = {
    top: number,
    left: number,
    right: number
}

function sortComponentsByTop(componentWithIdA: ComponentsTreeNode, componentWithIdB: ComponentsTreeNode): number {
    if (componentWithIdA.top - componentWithIdB.top === 0) {
        if (componentWithIdA.containerToSelectedNodes) {
            return -1;
        }
        if (componentWithIdB.containerToSelectedNodes) {
            return 1;
        }
    }
    return componentWithIdA.top - componentWithIdB.top;
}

export function areNodesIntersectedByX(nodeA: ComponentsTreeNode | Line,
    nodeB: ComponentsTreeNode | Line): boolean {
    return nodeA.right > nodeB.left && nodeB.right > nodeA.left;
}

function intersectsBaseLine(currentNode, parentCandidate, baseLine) {
    if (currentNode.intersectsBaseLine) {
        return true;
    }
    if (areNodesIntersectedByX(baseLine, currentNode)) {
        return true;
    }
    // Pass intersection property in sequence
    if (parentCandidate.intersectsBaseLine && parentCandidate.underBaseLine) {
        // Within shrinking containers node must intersect base line
        if (parentCandidate.containerToSelectedNodes) {
            if (areNodesIntersectedByX(baseLine, currentNode)) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }
    return false;
}

function shouldIncludeIntoTree(currentNode: ComponentsTreeNode, parentCandidate: ComponentsTreeNode) {
    if (currentNode.baseLine) {
        return true;
    }

    if (currentNode.intersectsBaseLine && (currentNode.selectedComponent || currentNode.containerToSelectedNodes)) {
        return true;
    }
    // Need to include components below moving child
    if (currentNode.underBaseLine && !parentCandidate.isLine && parentCandidate.baseLine) {
        if (parentCandidate.containerToSelectedNodes) {
            return currentNode.top > parentCandidate.bottom;
        }
        return true;
    }
    return false;
}
export function getTree(orderedNodes: Array<ComponentsTreeNode>,
    top: number,
    left: number,
    right: number,
    workspaceBBox: BBox,
    numberOfParents: number = 100): Array<ComponentsTreeLinkedNode> {
    // Changing the numberOfParents to 100. Previously it was 10 for code optimization.
    // We need to rewrite this if there is some bug for performance.
    const
        baseLine: Line = {
            top,
            left,
            right
        },
        parentCandidates: Array<ComponentsTreeNode> = [
            {
                id: '',
                isLine: true,
                intersectsBaseLine: false,
                underBaseLine: false,
                containerToSelectedNodes: false,
                overlappedWithMultiSelection: false,
                baseLine: false,
                top: workspaceBBox.top,
                left: workspaceBBox.left,
                right: workspaceBBox.right,
                bottom: workspaceBBox.top,
                kind: ''
            },
            {
                id: '',
                isLine: true,
                top,
                left,
                right,
                bottom: top,
                intersectsBaseLine: true,
                overlappedWithMultiSelection: false,
                underBaseLine: false,
                containerToSelectedNodes: false,
                baseLine: false,
                kind: ''
            }
        ],
        nodesToScan: Array<ComponentsTreeNode> = [...orderedNodes],
        treeLinkedNodes: ComponentsTreeLinkedNodesMap = {},
        topLevelNodes: Array<ComponentsTreeLinkedNode> = [];

    function getLinkedNode(currentNode: ComponentsTreeNode): ComponentsTreeLinkedNode {
        return treeLinkedNodes[currentNode.id] ||
            {
                ...currentNode,
                children: [],
                parentsNotBaseLine: [],
                parentsBaseLine: []
            };
    }

    function pushToParentCandidates(node: ComponentsTreeNode) {
        if (parentCandidates.indexOf(node) === -1) {
            parentCandidates.push(node);
        }
    }

    while (nodesToScan.length > 0) {
        const currentNode = nodesToScan.shift() as ComponentsTreeNode;
        // linkedNode scope starts here despite it is created below
        let counterParents: number = 0,
            parentIsFoundForLinkedNode = false;

        for (let i = parentCandidates.length - 1; i >= 0; i--) {
            const parentCandidate: ComponentsTreeNode = parentCandidates[i];
            if (areNodesIntersectedByX(parentCandidate, currentNode)) {
                currentNode.intersectsBaseLine = intersectsBaseLine(currentNode, parentCandidate, baseLine);
                currentNode.baseLine = shouldIncludeIntoTree(currentNode, parentCandidate);

                const linkedNode: ComponentsTreeLinkedNode = getLinkedNode(currentNode),
                    // Means the element was already treated.
                    parentNode: ComponentsTreeLinkedNode = treeLinkedNodes[parentCandidate.id];

                if (currentNode.baseLine) {
                    linkedNode.baseLine = true;
                }
                // In case the parent is in the moving group
                if (parentNode && parentNode.baseLine) {
                    // In order to find the closest parent further and max movement distance
                    linkedNode.parentsBaseLine.push(parentNode);
                    parentIsFoundForLinkedNode = true;

                    // Closest group parent reached in this loop. Must be unique. Only moved nodes are added into group.
                    if (linkedNode.parentsBaseLine.length === 1 && linkedNode.baseLine) {
                        parentNode.children.push(linkedNode);
                    }
                }
                if (numberOfParents) counterParents++;

                // Looking for top level group nodes. It is found when no group parent discovered withing 5 iterations.
                // Or when parentCandidate.isLine is met - indicates the end of a parent chain.
                if (!parentIsFoundForLinkedNode && linkedNode.baseLine
                    && (counterParents >= numberOfParents || parentCandidate.isLine)) {
                    topLevelNodes.push(linkedNode);
                    parentIsFoundForLinkedNode = true;
                }
                // Distinguish group parents and regular ones
                // Not group parents, blocking ones, are kept here.
                if ((!parentNode || (parentNode && !parentNode.baseLine)) && !parentCandidate.isLine) {
                    linkedNode.parentsNotBaseLine.push(parentCandidate);
                }
                // Code for keeping iteration
                pushToParentCandidates(currentNode);
                // All nodes must be unique. Keep them in the map.
                if (!treeLinkedNodes[linkedNode.id]) treeLinkedNodes[linkedNode.id] = linkedNode;
                // Too many iterations - 5 - quit if reached.
                if (counterParents >= numberOfParents) break;
            }
        }
    }

    return topLevelNodes;
}

function doComponentsPartiallyOverlap(cmp1, cmp2, workspaceBBox) {
    return doBBoxIntersect(getComponentsBBox([cmp1], workspaceBBox), getComponentsBBox([cmp2], workspaceBBox))
        && !isParentFor(cmp1, cmp2) && !isParentFor(cmp2, cmp1);
}

export function isParentFor(child: ComponentBase, parent: ComponentBase, strict: boolean = false): boolean {
    const childBottom = child.top + child.height,
        childRight = child.left + child.width,
        parentBottom = parent.top + parent.height,
        parentRight = parent.left + parent.width,

        { kind: parentKind } = parent;

    if (strict) {
        return ((parentKind && isStretchComponentKind(parentKind)) ? true : (child.left > parent.left && childRight < parentRight))
            && child.top > parent.top && childBottom < parentBottom;
    }
    return ((parentKind && isStretchComponentKind(parentKind)) ? true : (child.left >= parent.left && childRight <= parentRight))
        && child.top >= parent.top && childBottom <= parentBottom;
}

function getClosestParentFromSet(toNodeId: string,
    fromNodes: Array<ComponentsTreeLinkedNode | ComponentsTreeNode>,
    componentsMap: ComponentsMap): ComponentsTreeLinkedNode | ComponentsTreeNode {
    const nodeComponent = componentsMap[toNodeId];
    let sortedDistances: Array<ComponentDistance> = fromNodes.map(
        (node: ComponentsTreeLinkedNode | ComponentsTreeNode): ComponentDistance => {
            return { node, distance: getDistanceToParent(nodeComponent, componentsMap[node.id], node) };
        }
    ).sort((a: ComponentDistance, b: ComponentDistance): number => a.distance - b.distance);

    // Ignore components from other mode of hoverBox while calculating closest parent.
    const parentHoverBox = sortedDistances.find(({ node: { kind } }) => isHoverBoxKind(kind));
    if (parentHoverBox &&
        getAllChildren(parentHoverBox.node as ComponentsTreeLinkedNode, componentsMap)
            .find(({ id }) => id === nodeComponent.id)
    ) {
        const isHoverMode = (componentsMap[parentHoverBox.node.id] as HoverBoxComponent).hoverMode;
        let stopFilter = false;
        sortedDistances = sortedDistances.filter(({ node: { id: nodeId } }) => {
            if (stopFilter) return stopFilter;
            const cmp = componentsMap[nodeId];
            stopFilter = parentHoverBox.node.id === nodeId;
            return stopFilter || isHoverMode === (cmp.onHover && cmp.onHover.show);
        });
    }
    return sortedDistances[0] && sortedDistances[0].node;
}

function getDistanceToParent(nodeComponent: AnyComponent,
    parentComponent: AnyComponent,
    parentTreeNode: ComponentsTreeLinkedNode | ComponentsTreeNode): number {
    const
        ClosestParentComponentBottom = parentComponent.top + parentComponent.height;

    if (isParentFor(nodeComponent, parentComponent)) {
        return nodeComponent.top - parentComponent.top;
    }
    // containers for other elements mustn't influence other components. Can be moved from here.
    if (parentTreeNode.containerToSelectedNodes && !isParentFor(nodeComponent, parentComponent)) {
        return NaN;
    }

    return nodeComponent.top - ClosestParentComponentBottom;
}

function getMaxMovementDistanceRelInCandidate(node: ComponentsTreeLinkedNode, componentsMap: ComponentsMap, nodesWithDistanceSet) {
    const nodeComponent: AnyComponent = componentsMap[node.id];

    const componentsChildren: Array<AnyComponent> = getAllChildren(node, componentsMap),
        nodeBottom: number = nodeComponent.top + nodeComponent.height,

        lowestBottom: number = componentsChildren.reduce(
            (lowestBottom: number, component: AnyComponent): number => {
                let childMaxMovableDistance = 0;
                //considering the 'maxMovableDistance' of the child for the calculation of parent's maxMovableDistance
                if (nodesWithDistanceSet[component.id]) {
                    childMaxMovableDistance = nodesWithDistanceSet[component.id].maxMovementDistance;
                }
                if ((component.top + component.height + childMaxMovableDistance) > lowestBottom) {
                    return component.top + component.height + childMaxMovableDistance;
                }
                return lowestBottom;
            },
            -1000
        );
    // Restriction to shrink up to lowest component
    // Since maxMovementDistance can be negative only, put any positive here. 0 means the component is fixed or is following parent

    return lowestBottom - nodeBottom;
}

function getMaxMovementDistance(node: ComponentsTreeLinkedNode,
    closestParentFromTree: ComponentsTreeLinkedNode | ComponentsTreeNode,
    componentsMap: ComponentsMap) {
    const
        closestParentId: string = closestParentFromTree.id,
        nodeComponent: AnyComponent = componentsMap[node.id],
        closestParentComponent: AnyComponent = componentsMap[closestParentId],
        distanceToClosestParent: number = getDistanceToParent(
            nodeComponent,
            closestParentComponent,
            closestParentFromTree
        );

    let
        maxMovementDistance: number = -distanceToClosestParent + SpaceAdjusterMinimumBetweenComponentsPx;
    if (closestParentFromTree.baseLine && closestParentFromTree.changeType !== 'diffHeight') {
        maxMovementDistance = (closestParentFromTree as ComponentsTreeLinkedNode).maxMovementDistance;
    } else
    // Node must stop if tree parent stops. Should not be concerned for height changing.
    if (!node.selectedComponent && node.baseLine && node.parentsBaseLine[0] && node.changeType !== 'diffHeight') {
        maxMovementDistance = node.parentsBaseLine[0].maxMovementDistance > maxMovementDistance
            ? node.parentsBaseLine[0].maxMovementDistance : maxMovementDistance;
    }

    // For resized components out of selected components, minimum height is kept 5px
    if (node.changeType === 'diffHeight') {
        maxMovementDistance = -nodeComponent.height + 5;
    }
    return maxMovementDistance;
}

export function getAllChildren(node: ComponentsTreeLinkedNode, componentsMap: ComponentsMap): Array<AnyComponent> {
    const nodeComponent: AnyComponent = componentsMap[node.id],
        nodeBottom: number = nodeComponent.top + nodeComponent.height,
        nodeRight: number = nodeComponent.left + nodeComponent.width;

    return values(flip(pickBy)(componentsMap, (component: AnyComponent, key: string): boolean => {
        const componentBottom: number = component.top + component.height,
            componentRight: number = component.left + component.width;

        return (
            !componentIsGhost(component)
            && (
                (isStretchComponentKind(nodeComponent.kind) && isStretchComponentKind(component.kind)) ||
                (component.left >= nodeComponent.left && componentRight <= nodeRight)
            )
            && component.top >= nodeComponent.top && componentBottom <= nodeBottom
            // NOTE: Keeping this for future reference: WBTGEN-18895, WBTGEN-11781
            // && (node.children.filter((childNode, index) => childNode.id === key || !index).length <= 0)
            && node.id !== key
        );
    }));
}

function filterChildrenForParentComponent(childrenIds: Array<string>,
    parent: AnyComponent,
    componentsMap: ComponentsMap): Array<string> {
    return childrenIds.filter(childId => isParentFor(componentsMap[childId], parent));
}

function setMaxMovementDistanceForContainersToSelectedNodes(nodes, componentsMap) {
    let result = [...nodes];
    /**
    * get the container-to-selected-nodes in the order of containment
    * it is already in the top to bottom sorted order of containment
    **/
    let containerNodes = result.filter(n => n.containerToSelectedNodes);

    let nodesWithDistanceCalculated = result.filter(n => n.maxMovementDistance || n.maxMovementDistance === 0)
        .reduce((acc, n) => {
            acc[n.id] = n;
            return acc;
        }, {});

    //set the maxMovementDistance in the bottom to top order of the containers
    while (containerNodes.length > 0) {
        let node = containerNodes.pop();
        //The child's 'maxMovementDistance' values are set as required at this point
        // since it is processed bottom to top and required for the calculation of the parent
        node.maxMovementDistance = getMaxMovementDistanceRelInCandidate(node, componentsMap, nodesWithDistanceCalculated);
        nodesWithDistanceCalculated[node.id] = node;
    }

    return result;
}

function flattenTree(topLevelNodes, selectedComponentsIds, componentsMap, draggableKind, workspaceBBox) {
    const
        result: ComponentsTreeLinkedNode[] = [],
        _isShiftBarTop = isShiftBarTop(draggableKind),
        _isShiftBarBottom = isShiftBarBottom(draggableKind);

    let nodesToScan = [...topLevelNodes];

    while (nodesToScan.length > 0) {
        const node: ComponentsTreeLinkedNode = nodesToScan.shift(),
            nodeComponent: AnyComponent = componentsMap[node.id];
        // if (node.id === '480F4648-6677-40F8-86FA-2D882C23CF0A') debugger
        const parentsMoved = node.parentsBaseLine.filter((nodeParent: ComponentsTreeLinkedNode): any => {
            if (nodeParent.changeType) {
                // filtering parents which we will work with

                if ( // Looking for parents who changes its height
                    !node.containerToSelectedNodes
                && isParentFor(componentsMap[node.id], componentsMap[nodeParent.id])) {
                    return true;
                }
                // the case when parent is just overlapping
                if (node.containerToSelectedNodes && nodeParent.changeType === 'diffTop') {
                    // debugger
                    return true;
                }
            }
            // check if parent is not baselined

            return nodeParent.containerToSelectedNodes;
        });
        const parentsNotMoved: Array<ComponentsTreeNode> = node.parentsNotBaseLine
        // see to which parents a distance can matter
            .filter((nodeParent: ComponentsTreeNode): any => {
                // regular and not overlapping parents - cmps that are simply above
                return (
                    // selectedComponentsIds.indexOf(nodeParent.id) === -1 &&
                    !doComponentsPartiallyOverlap(
                        componentsMap[node.id],
                        componentsMap[nodeParent.id],
                        workspaceBBox
                    )
                );
            });

        node.changeType = (node.containerToSelectedNodes
        // whether node is parent for any of selected components
        && filterChildrenForParentComponent(selectedComponentsIds, nodeComponent, componentsMap).length > 0)
        // Whether node is selected and bottom shift bar
        || (selectedComponentsIds.indexOf(node.id) > -1 && _isShiftBarBottom)
            ? 'diffHeight' : 'diffTop';

        if (node.changeType === 'diffHeight'
            && node.kind !== ImageComponentKind
            && !isBoxKind(node.kind)
            && !isStretchComponentKind(node.kind)
            && selectedComponentsIds.indexOf(node.id) === -1) {
            node.changeType = 'unspecified';
        }

        // Get closest nodes differently for moved and not
        // TODO: Split
        const closestParentFromTree: ComponentsTreeLinkedNode | ComponentsTreeNode = getClosestParentFromSet(node.id,
            // @ts-ignore
            parentsNotMoved.concat(parentsMoved), componentsMap) || {},
            closestParentId = closestParentFromTree.id;
        if (closestParentId && !node.containerToSelectedNodes) {
            node.maxMovementDistance = getMaxMovementDistance(node, closestParentFromTree, componentsMap);
            node.closestParent = closestParentFromTree;
        }

        // Set maxMoveDistance to the top of the workspace. It will prevent the negative top for components.
        if (_isShiftBarTop && !node.maxMovementDistance && node.maxMovementDistance !== 0) {
            node.maxMovementDistance = -node.top;
        }

        // Reduce the height of the container until the max bottom of it child id reached
        if (_isShiftBarBottom && isContainer(nodeComponent.kind) && selectedComponentsIds.includes(node.id)
            && !isModernHeaderOrFooter(componentsMap[nodeComponent.id])) {
            const children = getAllChildren(node, componentsMap),
                bBox = getComponentsBBox(children.map(({ id }) => componentsMap[id]), workspaceBBox);
            node.maxMovementDistance = Math.max(bBox.bottom - node.bottom, node.maxMovementDistance);
        }

        nodesToScan = nodesToScan.concat(node.children); // eslint-disable-line no-param-reassign
        result.push(node);
    }
    return setMaxMovementDistanceForContainersToSelectedNodes(result, componentsMap);
}

const intersectsShiftBarLineByY = function (shiftbarKind, component, shiftbarY) {
    return (isShiftBarBottom(shiftbarKind) ?
        (component.top + component.height >= shiftbarY)
        : (component.top + component.height > shiftbarY)) && component.top <= shiftbarY;
};

const intersectsShiftBarLineByX = function (component, shiftbarLeft, shiftbarRight) {
    return shiftbarRight > component.left && (component.left + component.width) > shiftbarLeft;
};

const isSelectedComponent = (componentId, selectedComponentsIds) => selectedComponentsIds.indexOf(componentId) > -1;
const isMultiselection = (selectedComponentsIds) => selectedComponentsIds.length > 1;
const isComponentOverlappingWithAnyComponents = (componentsIds, component, componentsMap, workspaceBBox) =>
    componentsIds.some(selectedId =>
        doComponentsPartiallyOverlap(component, componentsMap[selectedId], workspaceBBox));

const isParent = (componentId, relationsMap, potentialParentId) =>
    traverseRelInMap(componentId, relationsMap).indexOf(potentialParentId) > -1;

const isParentForAny = (componentsIds, relationsMap, potentialParentId) =>
    componentsIds.some((componentId) => isParent(componentId, relationsMap, potentialParentId));

function getSortedTreeNodes(componentsMap: ComponentsMap,
    top,
    left,
    right,
    selectedComponentsIds, workspaceBBox,
    shiftbarKind, relationsMap): Array<ComponentsTreeNode> {
    const
        componentsBelowBaseLine: ComponentsTreeNode[] = [];

    Object.keys(componentsMap).forEach((id: string) => {
        const
            component = componentsMap[id],
            componentWithIdTree: ComponentsTreeNode = {
                id,
                intersectsBaseLine: false,
                underBaseLine: false,
                containerToSelectedNodes: false,
                baseLine: false,
                overlappedWithMultiSelection: false,
                isLine: false,
                left: component.left,
                right: component.left + component.width,
                top: component.top,
                bottom: component.top + component.height,
                selectedComponent: selectedComponentsIds.indexOf(id) > -1,
                relIn: relationsMap[id],
                kind: component.kind
            };
        let push = false;
        if (selectedComponentsIds.indexOf(id) > -1) {
            componentWithIdTree.intersectsBaseLine = true;
            push = true;
        }
        // Intersects shift bar line by Y
        if (intersectsShiftBarLineByY(shiftbarKind, component, top)) {
            push = true;
            componentWithIdTree.intersectsBaseLine = true;
            if (!isSelectedComponent(id, selectedComponentsIds)
                && intersectsShiftBarLineByX(component, left, right)) {
                if (isMultiselection(selectedComponentsIds)) {
                    // if overlaps with selection bbox but not with components inside
                    if (!isComponentOverlappingWithAnyComponents(selectedComponentsIds, component,
                        componentsMap, workspaceBBox)) {
                        // Required for decorations with multiselection shifting
                        componentWithIdTree.overlappedWithMultiSelection = true;
                    }
                    if (isParentForAny(selectedComponentsIds, relationsMap, componentWithIdTree.id)) {
                        // containerToSelectedNodes also can be overlapped thus check for relIns
                        componentWithIdTree.containerToSelectedNodes = true;
                    }
                } else if (isParent(selectedComponentsIds[0], relationsMap, componentWithIdTree.id)) {
                    componentWithIdTree.containerToSelectedNodes = true;
                }
            }
        }
        if (component.top >= top) {
            componentWithIdTree.underBaseLine = true;
            push = true;
        }
        if (component.top < top
            && !componentWithIdTree.containerToSelectedNodes && selectedComponentsIds.indexOf(id) === -1) {
            push = true;
        }

        push = push && !componentIsGhost(component);

        if (push) {
            componentsBelowBaseLine.push(componentWithIdTree);
        }
    });
    componentsBelowBaseLine.sort(sortComponentsByTop);
    return componentsBelowBaseLine;
}

export function getTreeFromAllComponents(componentsMap: ComponentsMap,
    top: number,
    left: number,
    right: number,
    selectedComponentsIds: Array<string>,
    draggableKind: string,
    workspaceBBox: BBox,
    relationsMap: Record<string, any>): Array<ComponentsTreeLinkedNode> {
    // Needed to get find components which are potential parents.
    const treeNodes: Array<ComponentsTreeNode> = getSortedTreeNodes(componentsMap, top,
        left,
        right, selectedComponentsIds, workspaceBBox, draggableKind, relationsMap);

    return flattenTree(getTree(treeNodes, top,
        left,
        right, workspaceBBox),
    selectedComponentsIds, componentsMap, draggableKind, workspaceBBox);
}
