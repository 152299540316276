import Url from "../../../../../utils/Url";
import { defineTargetDomain, defineUserName } from "./utils/index";
import { getLoginRedirectUrl as getLoginRedirectUrlFromConfig } from "../../../../../../server/shared/auth/getLoginRedirectUrl";

export const getLoginRedirectUrl = ({ authConfig, domain, email }: Record<string, any>): string => {
    const
        requireTicketRedirectAuth = authConfig.get('requireTicketRedirectAuth'),
        loginUrl = authConfig.get('loginUrl'),
        config = { requireTicketRedirectAuth, loginUrl },
        location = window.location,
        url = Url(location.href),
        targetDomain = defineTargetDomain(url, domain) || '',
        username = defineUserName(url, email) || '',
        loginTarget = location.host;

    return getLoginRedirectUrlFromConfig({ config, targetDomain, username, loginTarget });
};
