/* eslint-disable max-len */

import * as R from "ramda";
import { setCells } from "./setAllCells";
import { MIN_CELL_HEIGHT } from "../constants";
import { getNumRowsAndCols, getRowAndColumnForAddOrRemoveAction } from "../utils";
import type { TableComponent, TableComponentDependsOn } from "../flowTypes";

export const setCellHeightReducer =
    (component: TableComponent, { payload: height }: any, dependencies: TableComponentDependsOn): TableComponent => {
        const intHeight = parseInt(height, 10);

        if (intHeight >= MIN_CELL_HEIGHT) {
            const
                { numRows, numCols } = getNumRowsAndCols(component.cells),
                { tableEditModeState: { selectedCellsIndexes } } = dependencies,
                cellIndexesToProcess = selectedCellsIndexes.length ? selectedCellsIndexes : R.range(0, component.cells.length),
                selectedCellsRowIndexes = cellIndexesToProcess.map(index => {
                    const { rowIndex } = getRowAndColumnForAddOrRemoveAction({ numRows, numCols }, index);
                    return rowIndex;
                }),
                setCellHeight = (cell) => {
                    return selectedCellsRowIndexes.indexOf(cell.cellInfo.rowIndex) > -1
                        ? R.evolve({
                            cellInfo: {
                                minHeight: () => intHeight
                            }
                        }, cell)
                        : cell;
                };

            return setCells(
                {
                    cellUpdater: setCellHeight,
                    selectedCellsIndexes: []
                },
                component
            );
        }

        return component;
    };
