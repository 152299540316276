import React from 'react';
import {
    IMAGE_PROP_PANEL_ALT_TEXT
} from '../../actionTypes';
import * as pageIds from './ids';
import pagesTitles from './titles';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import InputField from '../../../../../view/common/Input/PropertiesInputField';
import PropertyContainer from "../../../../../view/common/PropertyContainer/index";
import lengthInputValidation from "../../../../inputControls/input/commonValidations/lengthInputValidation";
import stylePropertyContainer from '../../../../../view/common/PropertyContainer/PropertyContainer.css';
import { IMAGE_ALT_MAX_LENGTH } from '../../constants';
import type { ImageComponent } from '../../flowTypes';
import styles from "../propertiesPanel.css";

type Props = {
    selectedComponent: ImageComponent,
    dispatch: Function
}

const view = ({ selectedComponent, dispatch }: Props) => (
    <Page>
        <VerticalSpacer />
        <PropertyContainer
            label="msg: component.image.imageTitleAlt {Image title (alt)}"
            className={stylePropertyContainer.pointer}
            labelIcon={{
                icon: styles.captionIcon,
                popup: {
                    message: 'msg: common.image.title.helpText {This title will also be used as “alt” text for the image. Add \"alt\" texts to all your images to push pages higher up on search results and boost your SEO.}', // eslint-disable-line
                }
            }}
        >
            <InputField
                value={selectedComponent.title || ''}
                onChange={alt => dispatch({ type: IMAGE_PROP_PANEL_ALT_TEXT, payload: alt })}
                placeholder="msg: common.enterTitleHere {Enter title here}"
                inputValidations={[
                    {
                        validation: lengthInputValidation,
                        options: { max: IMAGE_ALT_MAX_LENGTH },
                    }
                ]}
                rejectInvalidValue
                trimValueOnPaste
                counter={{
                    maxLength: IMAGE_ALT_MAX_LENGTH,
                }}
            />
        </PropertyContainer>
    </Page>
);

const
    id: string = pageIds.SEO,
    title = pagesTitles[id];

export default { id, view, title };
