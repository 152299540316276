import { take, put, call } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { DataPageSet } from '../../../dal/model/index';
import { getFontName } from '../presentational/AddGoogleFont/utils';
import WebFont from '../../webfontloader';
import * as actionTypes from "./actionTypes";
import getPageGoogleFonts from './getPageGoogleFonts';
import DataSite from "../../../dal/model/DataSite";

const
    webFontsList = require('../../../googleWebFontsList.json'),
    fontFamiliesMetadata = {},
    fontFamilyList = webFontsList.items.map(fontMetadata => {
        const { family } = fontMetadata;
        fontFamiliesMetadata[family] = fontMetadata;
        return family;
    }, []).sort(),
    // FIXME: variants should be managed properly, this is a quick fix to ONEWEB-2115
    variantsCheat = "100,100italic,200,200italic,300,300italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic,italic,regular"; // eslint-disable-line

const
    loadedFamilies = {},
    WebFontLoadCallFamilies: Array<Array<string>> = []; // used to load fonts inside iframe of text component

type LoadFontsType = {
    fonts: Array<string>,
    forPreview ?: boolean,
    successAction ?: string,
    failureAction ?: string
};

function* _loadFonts({ fonts, forPreview, successAction, failureAction }: LoadFontsType): any {
    const families: Array<string> = [];

    if (fonts && fonts.length) {
        let detail;

        fonts.forEach(font => {
            font = getFontName(font); // eslint-disable-line no-param-reassign
            detail = fontFamiliesMetadata[font];
            let familyWithVariants = font + ':' + (forPreview ? '400' : detail ? optimizeVariants(detail.variants).join() : variantsCheat); // eslint-disable-line
            if (!loadedFamilies[familyWithVariants]) {
                families.push(familyWithVariants);
            }
        });

        yield* loadFamilies({ families, forPreview, successAction, failureAction });
    }
}

function optimizeVariants(variants) {
    const
        normals: Array<string | number> = [],
        italics: Array<string | number> = [],
        results: Array<string | number> = [];

    let
        normal,
        italic,
        bold,
        boldItalic;

    // create a list of sizes seperatly for normal and italic.
    variants.forEach(variant => {
        if (variant.match('italic')) {
            italics.push(variant === 'italic' ? 400 : parseInt(variant, 10));
        } else {
            normals.push(variant === 'regular' ? 400 : parseInt(variant, 10));
        }
    });

    // find a good regular weight for normal and italic.
    [400, 300, 500, 200, 600, 100, 700, 800, 900].forEach(size => {
        if (!normal && normals.indexOf(size) !== -1) {
            normal = size;
            results.push(normal === 400 ? "regular" : normal);
        }
        if (!italic && italics.indexOf(size) !== -1) {
            italic = size;
            results.push((italic === 400 ? "" : normal) + "italic");
        }
    });

    // find a good bold weight for normal and italic.
    [700, 800, 600, 900, 500].forEach(size => {
        if (!bold && normal && size > normal && normals.indexOf(size) !== -1) {
            bold = size;
            results.push(bold);
        }
        if (!boldItalic && italic && size > italic && italics.indexOf(size) !== -1) {
            boldItalic = size;
            results.push(boldItalic + "italic");
        }
    });

    return results;
}

type LoadFamilies = {
    families: Array<string>,
    forPreview ?: boolean,
    successAction ?: string,
    failureAction ?: string
}

function* loadFamilies({ families, forPreview, successAction, failureAction }: LoadFamilies): any {
    if (families.length > 0) {
        families.forEach(family => {
            loadedFamilies[family] = true;
        });

        // add subset
        const doAllFamiliesHaveLatinSubset = families.every(f => {
            const font = f.split(':')[0];
            // TODO: WBTGEN-19357: remove this line, and uncomment the next one, when WBTGEN-19357 is fixed
            return fontFamiliesMetadata[font] && fontFamiliesMetadata[font].subsets.includes('latin');
            // return fontFamiliesMetadata[font].subsets.includes('latin');
        });
        families.push(`&subset=${(forPreview && doAllFamiliesHaveLatinSubset) ? 'latin' : 'all'}`);

        function createFontLoadingChannel() { // eslint-disable-line
            return eventChannel(emit => {
                const
                    successHandler = () => successAction && emit(successAction),
                    failureHandler = () => failureAction && emit(failureAction);

                WebFontLoadCallFamilies.push(families);
                WebFont.load({
                    google: { families },
                    active: successHandler,
                    // inactive here, will be called for fonts that don't support latin subset
                    // unless, we configure 'text' along with 'families' under 'google'
                    // in which case, the text should only be using the characters that the font can support
                    // for example, Arabic text for Almarai font family
                    //
                    // ignoring it, like below, will cause the preview text to display using the fallback font (serif etc)
                    // which is the font family, it will actually render with too, when used with unsupported subsets
                    inactive: (forPreview && !doAllFamiliesHaveLatinSubset) ? successHandler : failureHandler
                });

                const unsubscribe = () => null;

                return unsubscribe;
            });
        }

        function* watchOnFontLoading() { // eslint-disable-line
            const
                fontLoadingChannel = yield call(createFontLoadingChannel),
                eventType = yield take(fontLoadingChannel);

            fontLoadingChannel.close();
            yield put({ type: eventType });
        }

        yield* watchOnFontLoading();
    } else if (successAction) {
        yield put({ type: successAction });
    }
}

type LoadFontsEntryFn = {
    page: DataPageSet,
    siteData: DataSite
};
function* loadFonts({ page, siteData }: LoadFontsEntryFn): any {
    yield put({ type: actionTypes.FONTS_LOADING_IN_PROGRESS });
    const fonts = getPageGoogleFonts(page);

    if (fonts.length) {
        yield* _loadFonts({
            fonts,
            successAction: actionTypes.FONTS_LOADING_SUCCESS,
            failureAction: actionTypes.FONTS_LOADING_FAILED
        });
    } else {
        yield put({ type: actionTypes.NO_PAGE_FONTS_TO_LOAD });
    }

    yield* _loadFonts({
        fonts: siteData.fonts,
        forPreview: true
    });
}

type OnlyFont = {
    font: string
};

function* loadFontForPreview({ font }: OnlyFont): any {
    yield put({ type: actionTypes.FONT_LOADING_FOR_PREVIEW_IN_PROGRESS });
    yield* _loadFonts({
        fonts: [font],
        forPreview: true,
        successAction: actionTypes.FONT_LOADING_FOR_PREVIEW_SUCCESS,
        failureAction: actionTypes.FONT_LOADING_FOR_PREVIEW_FAILED
    });
}

const loadFontsToIframe = (iframe: HTMLIFrameElement, success: () => any, failure: () => any) => {
    WebFontLoadCallFamilies.forEach(families => {
        WebFont.load({
            google: { families },
            active: success,
            inactive: failure,
            context: iframe
        });
    });
};

export default loadFonts;
export { fontFamilyList, loadFontForPreview, getPageGoogleFonts, loadFontsToIframe };
