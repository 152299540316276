import * as React from 'react';
import dependsOn from '../Background/dependsOn';
import bgCalcRenderProps from '../Background/calcRenderProps';
import componentMainActions from './componentMainActions/index';
import kind from "./kind";
import reducer from './reducer/index';
import workspace from './view/workspace';
import propertiesPanel from './propertiesPanel/index';
import adjustComponentOnAdd from './adjustComponentOnAdd';
import { adjustmentHookConfig } from "../Background/adjustmentHookConfig";
import previewConfig from "./previewConfig";
import { getSpecificStyles } from "../Background/mobileEditorGetSpecificStyles";
import { ComponentNames } from "../constants";
import Icons from '../../../view/Icons/index';
import { makeGetBackgroundSpecificStyles } from '../Background/makeGetBackgroundSpecificStyles';
import { bgMobileCalcProps } from "../Background/bgMobileCalcProps";

export default {
    kind,
    label: ComponentNames[kind],
    shortcutIconName: 'strip',
    calcRenderProps: bgCalcRenderProps,
    componentMainActions,
    adjustComponentOnAdd,
    adjustComponentOnPaste: adjustComponentOnAdd,
    workspace,
    propertiesPanel,
    reducer,
    dependsOn,
    adjustmentHookConfig,
    mobileEditorConfig: {
        ...previewConfig,
        getSpecificStyles: data =>
            makeGetBackgroundSpecificStyles()(data) +
                getSpecificStyles(data),
        getHiddenElementData: () => {
            const Icon = Icons.MINUS;
            return { icon: <Icon /> };
        },
        calcProps: bgMobileCalcProps
    }
};
