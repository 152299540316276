import React from 'react';
import cx from "classnames";
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select as MuiSelect } from '@mui/material/';

import { WIDTH, HEIGHT } from "./constants";
import imageStyles from "../Images/imageStyle.css";
import { useIntl } from "../../../../../wbtgen/src/view/intl/injectIntl";

type StyleProps = {
    height: string,
    width: string,
}

const useStyles = makeStyles(
    (theme: Theme) => createStyles({
        label: {
            fontSize: "20px",
            lineHeight: "24px",
            whiteSpace: "normal",
            textWrap: "wrap",
            overflow: "visible",
            [theme.breakpoints.down('sm')]: {
                fontSize: "18px",
                lineHeight: "26px",
            }
        },
        formControl: {
            width: (props) => props.width,
            margin: "12px 0",
        },
        select: {
            fontSize: "16px",
            lineHeight: "24px",
            width: (props: StyleProps) => props.width,
            height: (props: StyleProps) => props.height,
            border: `1px solid ${theme.palette.custom.colorGray_bb}`,
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
            },
            "&:before": {
                content: "none",
                borderBottom: 'none',
            },
            "&:after": {
                content: "none",
                borderBottom: 'none',
            },
            "& > div": {
                paddingLeft: "20px",
            }
        },
        dropdownIcon: {
            position: "absolute",
            width: "24px",
            height: "24px",
            right: "20px",
        }
    })
);

export const Select = (props) => {
    const {
        width = WIDTH,
        height = HEIGHT,
        disabled,
        fullWidth = false,
        label,
        value,
        data,
        handleChange,
        labelStyles,
        labelClasses,
        formControlStyles,
        inputStyles,
        inputClasses,
        classes: classesProps
    } = props;

    const intl = useIntl();

    const styleProps = {
        width: fullWidth ? "100%" : width,
        height,
    };

    const classes = useStyles(styleProps);

    return (
        <div>
            {
                label &&
                <InputLabel
                    className={classes.label}
                    style={labelStyles}
                    classes={labelClasses}
                >
                    {intl.msgJoint(label)}
                </InputLabel>
            }
            <FormControl style={formControlStyles} className={classes.formControl}>
                <MuiSelect
                    id="select"
                    disabled={disabled}
                    value={value}
                    onChange={handleChange}
                    style={inputStyles}
                    className={classes.select}
                    classes={inputClasses}
                    IconComponent={() => (
                        <div className={cx(imageStyles.chevronDownIcon, classes.dropdownIcon, classesProps?.dropdownIcon)} />
                    )}
                >
                    {
                        data.map((item) => {
                            return <MenuItem key={item.value} value={item.value}>
                                {typeof item.label === 'function' ? item.label(intl) : item.label}
                            </MenuItem>;
                        })
                    }
                </MuiSelect>
            </FormControl>
        </div>
    );
};
