import { getCurrentPage, initiateNavigation } from "../utils/navigation";
import { getInitialPosition } from "../utils/position";
import type { TemplateSettingsContext, StateHolder } from "../../flowTypes";
import { getMaxHeight, getDimensions } from "../utils/dimensions";
import registry from "../../registryWithTemplate";
import { id as backgroundPageId } from '../../../oneweb/Template/propertiesPanel/pages/background';
import Template from '../../../oneweb/Template/kind';
import type { ThemeColorDataType, ThemeSiteSettingsDataType } from "../../../ThemeGlobalData/flowTypes";

type Params = {
    context: TemplateSettingsContext,
    epicState: StateHolder,
    templateBackground?: boolean,
    preserveCurrentNavigation?: boolean,
    isRepositoryDomain?: boolean,
    themeSettingsData?: ThemeSiteSettingsDataType,
    themeColorsData?: ThemeColorDataType
};

export default (
    {
        isRepositoryDomain,
        context,
        epicState,
        templateBackground = false,
        preserveCurrentNavigation = false,
        themeSettingsData,
        themeColorsData
    }: Params
): StateHolder => {
    const
        { state, scope } = epicState,
        { selectedComponentWithId, browserDimensions, stylesheets } = context,
        { component: { kind } } = selectedComponentWithId,
        isTemplateSettingsShown = state.show && kind === Template;

    if (isTemplateSettingsShown &&
        ((!templateBackground && state.navigation.activePageIndex === 0) ||
        ((templateBackground || preserveCurrentNavigation) && state.navigation.activePageIndex === 1))) {
        return epicState;
    }

    let navigation;
    if (templateBackground) {
        navigation = {
            sequence: [registry[kind].propertiesPanel.defaultPageId, backgroundPageId],
            activePageIndex: 1,
            direction: null
        };
    } else {
        navigation = initiateNavigation(kind);
    }

    const
        currentPage = getCurrentPage(navigation, kind),
        maxHeight = getMaxHeight(browserDimensions.height),
        dimensions = getDimensions(maxHeight, currentPage.height),
        initialPosition = isTemplateSettingsShown ? state.position : getInitialPosition(dimensions, browserDimensions);

    return {
        scope: { ...scope, maxHeight, actualPosition: initialPosition },
        state: {
            isRepositoryDomain,
            show: true,
            opacity: 1,
            navigation,
            currentPage,
            dimensions,
            position: initialPosition,
            animateMovement: false,
            stylesheets,
            selectedComponentWithId,
            mobileEnablement: {
                enableMobileDown: false
            },
            themeSettingsData,
            themeColorsData,
        }
    };
};
