import type { SvgComponent } from './flowTypes';
import type { AdjustmentHook, AdjustmentHookConfig, ShouldCallAdjustmentHook } from "../../Workspace/epics/componentsEval/flowTypes";

type AdjustmentData = { }
type Hook = AdjustmentHook<SvgComponent, undefined, undefined, AdjustmentData>
type HookConfig = AdjustmentHookConfig<SvgComponent, undefined, undefined, AdjustmentData>
type SvgShouldCallAdjustmentHook = ShouldCallAdjustmentHook<SvgComponent, undefined, undefined, AdjustmentData>

const componentDimensionsAreSame = (refComponent: SvgComponent, resizedComponent: SvgComponent) => (
        refComponent.width === resizedComponent.width && refComponent.height === resizedComponent.height
    ),
    hook: Hook = ({
        component: resizedComponent,
        componentExtension,
        originalComponent,
    }, {
        component: previousComponent,
    }) => {
        const beforeComponent = originalComponent || previousComponent,
            { height: h, width: w } = resizedComponent.asset;
        let component;
        if (beforeComponent.height.toFixed(3) !== resizedComponent.height.toFixed(3)) { // height has changed
            component = { ...resizedComponent, width: (resizedComponent.height * (w / h)) }; // so set width as per the aspect ratio
        } else { // width has changed, so set height as per the aspect ratio
            component = { ...resizedComponent, height: (resizedComponent.width * (h / w)) };
        }
        return [component, componentExtension];
    },
    shouldCallHook: SvgShouldCallAdjustmentHook = ({
        component: resizedComponent,
        originalComponent,
    }, {
        component: previousComponent,
    }) => Boolean( // do not call the hook if
        resizedComponent
        && (
            (originalComponent && !componentDimensionsAreSame(originalComponent, resizedComponent))
            || (previousComponent && !componentDimensionsAreSame(previousComponent, resizedComponent))
        )
    ),
    hookConfig: HookConfig = {
        hook,
        shouldCallHook
    };

export default hookConfig;
