import makeStyles from "@mui/styles/makeStyles";

export const FOOTER_BAR_HEIGHT = 110;

export const useStyles = makeStyles(
    {
        rootContainer: {
            maxWidth: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            position: "relative"
        },
        footerContainer: {
            maxWidth: "100vw",
            height: `${FOOTER_BAR_HEIGHT}px`,
            flex: `0 0 ${FOOTER_BAR_HEIGHT}px`,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "stretch",
            zIndex: 1000,
            background: "white"
        },
        errorRoot: {
            flex: "1 0 100%",
        },
    },
    { name: "Pages" }
);
