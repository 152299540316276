import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Lottie from "react-web-lottie";

import animationData from "./animation.json";
import { getStepMarkers, LOTTIE_DEFAULT_OPTIONS } from "../../../view/LottieAnimations/constant";

import { WIZARD_STEP_ID } from "../../constants";
import { BUSINESS_NAME_CLIP_LENGTH, STEP_MARKERS } from "./constants";

import { businessNameSelector, purposeSelector } from "../../Epic/selectors";

const markers = animationData.markers;
const stepMarkers = getStepMarkers(markers, WIZARD_STEP_ID.NAME, STEP_MARKERS[WIZARD_STEP_ID.NAME]);

export const BusinessNameAnimation = () => {
    const businessName = useSelector(businessNameSelector);
    const purpose = useSelector(purposeSelector);
    const animationRef = useRef(null);

    let addSegment = [stepMarkers.website_purpose_end_point, stepMarkers.business_name_start_point];
    if (purpose.length === 1) {
        addSegment = [stepMarkers.website_purpose_one_added, stepMarkers.website_purpose_one_added_scroll_up];
    } else if (purpose.length === 2) {
        addSegment = [stepMarkers.website_purpose_two_added, stepMarkers.website_purpose_two_added_scroll_up];
    }

    const [startPoint, setStartPoint] = useState(stepMarkers.business_name_start_point);
    const [stopPoint, setStopPoint] = useState(stepMarkers.business_name_appears);

    const initialSegment = [startPoint, stopPoint];

    const defaultOptions = {
        ...LOTTIE_DEFAULT_OPTIONS,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid meet"
        },
        initialSegment,
        animationData
    };

    useEffect(() => {
        // @ts-ignore
        animationRef.current.playSegments([[startPoint, stopPoint]], true);
        // @ts-ignore
        if (animationRef.current?.renderer) {
            // @ts-ignore
            animationRef.current.renderer.elements[4].elements[3].updateDocumentData(
                {
                    t: businessName.length > BUSINESS_NAME_CLIP_LENGTH ?
                        businessName.slice(0, BUSINESS_NAME_CLIP_LENGTH - 3) + '...' :
                        businessName
                }
            );
        }
    }, [businessName]);

    useEffect(() => {
        // @ts-ignore
        animationRef.current.playSegments([addSegment, [startPoint, stopPoint]], true);
        setStartPoint(stepMarkers.business_name_appears);
        setStopPoint(stepMarkers.business_name_appears + 1);
    }, []);

    return (
        <Lottie lottiRef={animationRef} options={defaultOptions} isClickToPauseDisabled />
    );
};
