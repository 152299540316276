import { WidgetsSpotifyKind } from './kind';
import { componentMainActions } from './componentMainActions';
import { configurationDialogId } from './configurationDialog';
import { propertiesPanel } from './propertiesPanel/index';
import { reducer } from './reducer';
import { SpotifyView } from './view/index';
import { SpotifyWorkspaceView } from './view/workspace';
import { calcRenderProps } from './calcRenderProps';
import { WidgetsSpotifyConfig } from '../constants';
import { getMVEHiddenElementData } from '../mobileEditorConfig';

const WidgetsSpotify = {
    kind: WidgetsSpotifyKind,
    label: WidgetsSpotifyConfig.title,
    shortcutIconName: 'widgetsSpotify',
    view: SpotifyView,
    workspace: SpotifyWorkspaceView,
    requireConfigurationOnDrop: () => true,
    configurationDialog: configurationDialogId,
    calcRenderProps,
    componentMainActions,
    propertiesPanel,
    reducer,
    mobileEditorConfig: {
        kind: WidgetsSpotifyKind,
        calcProps: calcRenderProps,
        view: SpotifyView,
        getHiddenElementData: getMVEHiddenElementData(WidgetsSpotifyConfig),
    },
};

export { WidgetsSpotify };
