import * as R from 'ramda';
import type { ComponentsEvalEpicUpdater } from "./flowTypes";
import {
    ReceiveOnlyUserInteractionComponentsIdsWithWrap,
    ReceiveOnlyUserInteractionModeSelector,
} from "./selectorActionTypes";
import { MOVED_BY_MOUSE } from "./updateReasons";
import { COMPONENTS_MOVED_BY_MOUSE } from "./actionTypes";
import { MOVING_COMPONENTS } from './userInteractionMutations/interactionModes';
import { WINDOW_MOUSE_UP } from "../../../App/actionTypes";
import { ATTACHMENTS_UPDATED_AFTER_MOVING } from "../componentAttachements/actionTypes";
import { getAttachedContainerCmpId, getAllAttachmentsForCmpIds } from "../componentAttachements/util";
import { setMovingComponents } from "./setters";

const afterComponentsMoved: ComponentsEvalEpicUpdater = {
    conditions: [
        ReceiveOnlyUserInteractionComponentsIdsWithWrap,
        ReceiveOnlyUserInteractionModeSelector,
        WINDOW_MOUSE_UP
    ],
    reducer: ({ values: [componentsId, userInterActionMode], state: epicState }) => {
        if (componentsId.length > 0 && userInterActionMode === MOVING_COMPONENTS) {
            const multipleActionsToDispatch = [
                {
                    type: COMPONENTS_MOVED_BY_MOUSE,
                    payload: { componentsId }
                }
            ];

            return {
                state: { ...epicState },
                multipleActionsToDispatch,
                updateReason: MOVED_BY_MOUSE
            };
        } else {
            return { state: epicState };
        }
    }
};
const afterAttachmentsUpdate = {
    conditions: [
        ATTACHMENTS_UPDATED_AFTER_MOVING
    ],
    // $FlowFixMe
    reducer: ({ values: [{ componentsId }], state: epicState }) => {
        let newState = epicState;
        const movingComponents = epicState.scope.movingComponents,
            attachments = epicState.scope.attachments,
            updateOrderIndex = (cmpId, orderIndex) => {
                const pathToUpdate = ['state', 'componentsMap', cmpId, 'orderIndex'];
                newState = R.assocPath(pathToUpdate, orderIndex, newState);
            };
        componentsId.forEach(cmpId => {
            const movingCmp = movingComponents[cmpId];
            if (movingCmp) {
                const parentId = getAttachedContainerCmpId(cmpId, attachments);

                if (movingCmp.parentId === parentId &&
                        (
                            !parentId ||
                            !movingComponents[parentId] ||
                            movingComponents[parentId].orderIndex === epicState.state.componentsMap[parentId].orderIndex
                        )
                ) {
                    updateOrderIndex(cmpId, movingCmp.orderIndex);
                    getAllAttachmentsForCmpIds(attachments, [cmpId]).forEach(attachedId => {
                        if (movingComponents[attachedId]) {
                            updateOrderIndex(attachedId, movingComponents[attachedId].orderIndex);
                        }
                    });
                }
            }
        });
        newState = setMovingComponents({})(newState);
        return { state: newState, updateReason: ATTACHMENTS_UPDATED_AFTER_MOVING };
    }
};

export const
    onComponentMovedUpdater = [
        afterComponentsMoved,
        afterAttachmentsUpdate
    ];
