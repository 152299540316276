import React from "react";
import * as R from "ramda";
import AlignmentControls from "../../../../componentMainActions/MctaAlignmentControls/alignmentControls";
import {
    MOBILE_EDITOR_ALIGNMENT_CHANGE,
    MOBILE_MCTA_ALIGN_MOUSE_ENTER,
    MOBILE_MCTA_ALIGN_MOUSE_LEAVE
} from "../../../actionTypes";
import { cmpTypes } from "../../../constants";
import type { MobileGroupMCTAProps } from "../../../flowTypes";

const Alignment = (props: MobileGroupMCTAProps) => {
    const { dispatch, id, mobileMCTA, settings, defaultAlign } = props,
        { mctaHorizontalAlignControlExpanded, availableSpace } = mobileMCTA,
        align = R.pathOr(defaultAlign, [id, 'align'], settings);
    return <AlignmentControls
        actionTypeToDispatch={MOBILE_EDITOR_ALIGNMENT_CHANGE}
        availableSpace={availableSpace}
        onMouseEnterCollapsedViewActionType={MOBILE_MCTA_ALIGN_MOUSE_ENTER}
        onMouseLeaveExpandedViewActionType={MOBILE_MCTA_ALIGN_MOUSE_LEAVE}
        alignment={align}
        expanded={mctaHorizontalAlignControlExpanded}
        dispatch={dispatch}
        kind={cmpTypes.group}
        selectedCmpId={id}
    />;
};

export default Alignment;
