import React from "react";
import View from "./index";
import { ComponentMaskBackground } from "../../../presentational/ComponentMask/constants";
import ComponentMask from "../../../presentational/ComponentMask/index";
import BaseComponent from "../../../../view/oneweb/BaseComponent";
import styles from "./Menu.css";
import type { Props } from "../flowTypes";

export default (props: Props) => (
    <BaseComponent {...props} extraClass={styles.overflowhidden}>
        <ComponentMask showPreviewOnlyLayer={false} maskStyle={ComponentMaskBackground.TRANSPARENT}>
            {React.createElement(View, { ...props, isWorkspace: true })}
        </ComponentMask>
    </BaseComponent>
);
