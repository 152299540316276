import type { LinkChooserInterchangeType } from "../flowTypes";
import { LcTabName } from "../constants";
import {
    makeInputDefaultState,
} from '../../../../../components/inputControls/input/reducerFactory/makeInputDefaultState';
import { Lit } from "../../../../../lit";

export default (input: LinkChooserInterchangeType) => {
    const
        { link: { type, value }, openInNewWindow } = input,
        isInputType = type === LcTabName.URL || type === LcTabName.EMAIL,
        isValid = isInputType && value;

    let finalValue;
    if (type === LcTabName.PHONE) {
        finalValue = { input: makeInputDefaultState({ value }) };
    } else if (type === LcTabName.URL) {
        finalValue = { selection: value, isValid };
    } else if (type === LcTabName.EMAIL) {
        finalValue = {
            selection: value[Lit.selection],
            subject: makeInputDefaultState({ value: value[Lit.subject] === null ? '' : value[Lit.subject] }),
        };
    } else if (type === LcTabName.LOCATION) {
        finalValue = { selection: value[Lit.selection], name: value[Lit.name] };
    } else if (type === LcTabName.SECTION_LINK) {
        finalValue = {
            selection: value[Lit.pageId],
            isValid,
            sectionId: value[Lit.sectionId],
        };
    } else {
        finalValue = { selection: value };
    }

    return {
        [type]: finalValue,
        activeTabName: type,
        openInNewWindow,
        currentResult: { link: { type, value }, openInNewWindow }
    };
};
