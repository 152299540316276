import { isNotEmpty } from '../../../../utils/ramdaEx';
import { INSERT_LINK, UNLINK } from './editorUtils/editorCommands';
import { getSelectedLinkNodes } from './editorUtils/methods/getters/getLinkFromEditorSelection';
import { addClass } from "./editorUtils/methods/helpers/addClass";
import { makeRemoveFormatter } from './editorUtils/methods/helpers/makeRemoveFormatter';
import { LINK_GLOBAL_STYLE_FORMATS } from "../../../oneweb/Text/constants";
import { type TextLink } from '../../../oneweb/Text/flowTypes';
import { fixGlobalStyles } from "./editorUtils/methods/helpers/fixGlobalStyles";
import type { TinyMceEditor } from "./flowTypes";
import { LINK_CLASS_NAME_REGEX } from './editorUtils/constants';
import { removeMatchingClassNames } from '../../../../utils/dom';

export const setLink = (
    editor: TinyMceEditor,
    link: TextLink,
    linkGlobalStyle: string,
    shouldRemoveFormatting: boolean,
    limitToOneContentBlock: boolean
) => {
    editor.undoManager.transact(() => {
        if (shouldRemoveFormatting) {
            const removeFormatFromEditorSelection = makeRemoveFormatter(editor);
            LINK_GLOBAL_STYLE_FORMATS.forEach(formatStyle => removeFormatFromEditorSelection(formatStyle));
        }
        let linkNodeList: HTMLAnchorElement[] = getSelectedLinkNodes(editor, limitToOneContentBlock),
            stylesToPreserve: string | null | undefined;
        if (linkNodeList.length === 1) {
            stylesToPreserve = linkNodeList[0].getAttribute('style');
        }

        editor.execCommand(INSERT_LINK, false, link);

        linkNodeList = getSelectedLinkNodes(editor, limitToOneContentBlock);
        if (isNotEmpty(linkNodeList)) {
            if (linkNodeList.length === 1 && stylesToPreserve) {
                linkNodeList[0].setAttribute('style', stylesToPreserve);
            }
            linkNodeList.forEach((linkNode: HTMLElement) => {
                removeMatchingClassNames(linkNode, LINK_CLASS_NAME_REGEX);
                addClass(editor, linkGlobalStyle, linkNode);
            });
            fixGlobalStyles(editor, [], false, true);
        }
    });
};

export const removeLink = (editor: TinyMceEditor) => editor.execCommand(UNLINK, false, '');
