import {
    UPDATE_WRAP_REQUEST
} from '../actionTypes';

import {
    removeWrappedNode,
    getComponentsChanges,
    updateWrappedNodeDimensions
} from '../../../../../utils/htmlWriter/html/render/wrapper/wrapperNodeUtils';

import {
    ResizedComponentIdsSelector,
    ReceiveOnlyComponentsMapFromFullAction
} from '../../componentsEval/selectorActionTypes';

import textKind from '../../../../oneweb/Text/kind';
import { unwrapComponent, getWrapperComponent } from '../helpers';
import type { WrapUpdater } from '../flowTypes';
import { ReceiveOnlyAttachmentsFromFullAction } from '../../componentAttachements/selectorActionTypes';

const resizeSelectedComponents = (selectedComponentsIds, componentsMap, attachments) => {
    const changedTextComponents = {};
    let componentsChanges = {};
    let textChanges = {};

    selectedComponentsIds.forEach(wrappedComponentId => {
        const wrappedComponent = componentsMap[wrappedComponentId];

        let
            wrapperId,
            wrapperComponent = getWrapperComponent(wrappedComponentId, componentsMap);

        if (wrapperComponent) {
            wrapperId = wrapperComponent.id;
        } else {
            return;
        }

        wrapperComponent = changedTextComponents[wrapperId] || componentsMap[wrapperId];

        let newContent;
        if (wrappedComponent.width > wrapperComponent.width) {
            newContent = removeWrappedNode(
                wrapperComponent.content,
                wrappedComponentId
            );

            componentsChanges[wrappedComponentId] = unwrapComponent();
        } else {
            newContent = updateWrappedNodeDimensions(
                wrapperComponent.content,
                wrappedComponent
            );
        }

        changedTextComponents[wrapperId] = {
            ...wrapperComponent,
            content: newContent
        };
    });

    Object.keys(changedTextComponents).forEach(wrapperId => {
        const
            wrapperComponent = changedTextComponents[wrapperId];

        componentsChanges = {
            ...componentsChanges,
            ...getComponentsChanges(
                wrapperComponent,
                componentsMap,
                attachments,
            )
        };

        textChanges[wrapperId] = {
            content: wrapperComponent.content
        };
    });

    return { textChanges, componentsChanges };
};

export const onResizeUpdater: WrapUpdater = {
    keepFullActions: true,
    conditions: [
        ReceiveOnlyComponentsMapFromFullAction,
        ReceiveOnlyAttachmentsFromFullAction,
        ResizedComponentIdsSelector
    ],
    reducer: ({ values: [componentsMap, attachments, changedDimensionsComponentIds], state, scope }) => {
        if (changedDimensionsComponentIds.length) {
            let
                textComponentUpdateInfo = {},
                wrappedComponentsUpdateInfo = {};

            const wrappedComponentsIds =
                changedDimensionsComponentIds.filter(id => componentsMap[id].wrap);

            if (wrappedComponentsIds.length) {
                const { textChanges, componentsChanges } =
                    resizeSelectedComponents(wrappedComponentsIds, componentsMap, attachments);

                textComponentUpdateInfo = textChanges;
                wrappedComponentsUpdateInfo = componentsChanges;
            }

            const wrapperComponentIds =
                changedDimensionsComponentIds.filter(id => componentsMap[id].kind === textKind);

            if (wrapperComponentIds.length) {
                wrapperComponentIds.forEach(wrapperComponentId => {
                    const wrapperComponent = componentsMap[wrapperComponentId];
                    wrappedComponentsUpdateInfo = {
                        ...wrappedComponentsUpdateInfo,
                        ...getComponentsChanges(wrapperComponent, componentsMap, attachments)
                    };
                });
            }

            return {
                state,
                scope,
                actionToDispatch: {
                    type: UPDATE_WRAP_REQUEST,
                    payload: {
                        textComponentUpdateInfo,
                        wrappedComponentsUpdateInfo
                    }
                }
            };
        }

        return { state, scope };
    }
};

export const
    onResizeUpdaters = [
        onResizeUpdater
    ];
