import getInterestingStyle from "./getInterestingStyle";

export default (
    text: string,
    content: string,
    styles: Array<Style>,
    inheritedStyle: Object,
    inheritedMceStyle: Object
) => {
    const textLength = text.length;

    // Add style if this text node has interesting styles that did not get included.
    // This will happen in some nesting cases. eg: <a>Text<b>1234</b>unstyled</a>. "unstyled" may not get a style
    const interestingStyle = getInterestingStyle(inheritedStyle, inheritedMceStyle);
    const endingStyles = styles.filter(({ start, end }) => {
        return (typeof start !== 'undefined' && typeof end === 'undefined');
    });
    let styleAssigned = false;

    if (endingStyles.length) {
        endingStyles.forEach(style => {
            if (interestingStyle) {
                Object.assign(style, interestingStyle);
            }

            styleAssigned = true;
            style.end = textLength; // eslint-disable-line no-param-reassign
        });
    }

    if (interestingStyle && !styleAssigned) {
        const style = {
            ...interestingStyle,
            atype: 'style',
            start: textLength - content.length,
            end: textLength
        };

        if (interestingStyle.globalId && interestingStyle.globalName) {
            // @ts-ignore
            style.globalId = interestingStyle.globalId;
            // @ts-ignore
            style.globalName = interestingStyle.globalName;
        }

        styles.push(style);
    }
};
