import type { DialogManagerScope, DialogManagerState, DialogManagerUserInteraction } from "../flowTypes";
import { idleUIMode } from "./interactionModes";
import { getDefaultReducerState } from "../../../redux/makeReducer/index";
import { dialogManagerDependsOnReducer } from "./dependsOnReducer";

const userInteractionDefaultState: DialogManagerUserInteraction = {
    mode: idleUIMode
};

export const
    dialogManagerDefaultState: DialogManagerState = {
        computed: {
            renderProps: [],
            topMostModalIndex: 0
        }
    },
    dialogManagerDefaultScope: DialogManagerScope = {
        openedDialogConfigs: [],
        userInteraction: userInteractionDefaultState,
        dialogsDependencies: getDefaultReducerState(dialogManagerDependsOnReducer),
        persistentStates: {},
        browserDimensions: { width: 0, height: 0 }
    };
