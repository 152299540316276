import { SIZE_TYPES } from "../../../constants";

export const
    MIN_ICON_SIZE = 16,
    MAX_ICON_SIZE = 96,
    MIN_ICON_SPACING = 0,
    MAX_ICON_SPACING = 100;

export const TextLike = 'TEXT_LIKE',
    TEXT_LIKE_COMPONENT_TOOLTIP_GUTTER = 9,
    TEXT_LIKE_COMPONENT_TOOLTIP_POINTER_HEIGHT = 10,
    TEXT_LIKE_COMPONENT_TOOLTIP_WIDTH = 289,
    MCTA_RESIZE_OPTIONS = {
        [SIZE_TYPES.SIZE_SMALL]: { defaultSize: 16, mobileSize: 32 },
        [SIZE_TYPES.SIZE_MEDIUM]: { defaultSize: 24, mobileSize: 40 },
        [SIZE_TYPES.SIZE_LARGE]: { defaultSize: 32, mobileSize: 50 }
    };
