import { all, fork } from "redux-saga/effects"; // eslint-disable-line node/file-extension-in-import
import loadPageDataSaga from './loadPageDataSaga';
import newPageSaga from '../NewPageDialog/saga';
import treeSaga from '../Tree/saga';

export default function* (): ForksSaga {
    yield all([
        fork(loadPageDataSaga),
        fork(treeSaga),
        fork(newPageSaga)
    ]);
}
