import { makeApplyEditorChangesReducer } from "./helpers/helpers";
import { applyFormatValue } from "../editorUtils/methods/helpers/applyFormatValue";
import { TINY_MCE_SET_FONT_SIZE } from "../actionTypes";

const onSetFontSizeUpdater = {
    conditions: [TINY_MCE_SET_FONT_SIZE],
    reducer: makeApplyEditorChangesReducer(({ state, scope, values: [fontSize], editor }) => {
        applyFormatValue(editor, 'fontSize', `${fontSize}px`);

        return {
            state: {
                ...state,
                fontSize
            },
            scope
        };
    })
};

export {
    onSetFontSizeUpdater
};
