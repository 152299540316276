import React from 'react';
import '../../view/Preview.css';
import { DATA_TEST_ID, SoundcloudSrcLink } from '../constants';

export const SoundcloudView = props => (
    <div data-testid={DATA_TEST_ID} className="widget" style={{ width: props.width }}>
        <iframe
            allow="autoplay"
            src={`${SoundcloudSrcLink}${props.link}&auto_play=${props.autoplay}&color=%23${props.color}`}
            width="100%"
            height={props.height}
            style={{ border: 'none', overflow: 'hidden' }}
        />
    </div>
);
