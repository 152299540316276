import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import TableRow from "@mui/material/TableRow";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        border: `1px solid ${theme.palette.custom.colorGray_ce}`,
        textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: `1px solid ${theme.palette.custom.colorGray_ce}`,
        borderBottom: "0px",
        borderTop: "0px",
        textAlign: "center",
    },
    [`&:first-of-type`]: {
        textAlign: "left",
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        backgroundColor: theme.palette.custom.colorGray_f7,
    },
    "&:last-child td, &:last-child th": {
        border: `1px solid ${theme.palette.custom.colorGray_ce}`,
        borderBottom: "0px",
        borderTop: "0px",
    },
    [`&:last-child`]: {
        borderBottom: `1px solid ${theme.palette.custom.colorGray_ce}`,
    },
}));

export const StyledTableSpecialRow = styled(TableRow)(({ theme }) => ({
    "&:first-of-type td": {
        border: `1px solid ${theme.palette.custom.colorGray_ce}`,
        borderBottom: "0px",
        padding: "16px 24px 0px"
    },
    "&:last-child td": {
        padding: "8px 24px 24px"
    },
    verticalAlign: "baseline"
}));

export const StyledTableSpecialCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: `1px solid ${theme.palette.custom.colorGray_ce}`,
        borderBottom: "0px",
        borderTop: "0px",
        textAlign: "left",
        padding: "8px 24px"
    },
}));

export const TopTableCell = styled(TableCell)(({ theme }) => ({
    // 4 plans + 1 feature name
    width: "20%",
    [theme.breakpoints.down('md')]: {
        // 2 plans + 1 feature name
        width: "33.33%"
    },
    [theme.breakpoints.down('sm')]: {
        // Only 1 plan
        width: "100%"
    },
}));

export const useStyles = makeStyles(
    (theme) => ({
        table: {
            width: "calc(100% - 2px)",
            margin: "1px"
        },
        recommended: {
            color: theme.palette.custom.colorWhite,
            fontSize: "12px",
            lineHeight: "18px",
            padding: "4px 0px",
            background: theme.palette.custom.colorZundaGreen,
            border: `1px solid ${theme.palette.custom.colorZundaGreen}`,
            textAlign: "center",
            fontWeight: 600
        },
        hideRecommended: {
            background: theme.palette.custom.colorWhite,
            border: `1px solid ${theme.palette.custom.colorWhite}`,
            borderBottom: `1px solid ${theme.palette.custom.colorGray_ce}`

        },
        planName: {
            fontSize: "18px",
            lineHeight: "28px",
            fontWeight: 600,
            [theme.breakpoints.down('md')]: {
                fontSize: "16px",
                lineHeight: "22px",
            },
        },
        pice: {
            fontSize: "32px",
            lineHeight: "40px",
            [theme.breakpoints.down('md')]: {
                fontSize: "16px",
                lineHeight: "22px",
            },
        },
        perMonth: {
            fontSize: "14px",
            lineHeight: "22px",
            letterSpacing: "0.25px"
        },
        info: {
            fontSize: "12px",
            lineHeight: "18px",
            letterSpacing: "0.4px",
            borderRadius: "4px",
            background: theme.palette.custom.colorLinkWater,
            padding: "0px 8px",
            width: "fit-content"
        },
        downgradeInfo: {
            padding: "0px",
            background: "inherit",
            maxWidth: "240px",
            [theme.breakpoints.down('sm')]: {
                maxWidth: "100%"
            },
        },
        renew: {
            fontSize: "12px",
            lineHeight: "18px",
            letterSpacing: "0.4px",
            color: theme.palette.custom.colorDarkSilver,
        },
        hideRenew: {
            visibility: "hidden"
        },
        actionBtn: {
            fontSize: "14px",
            lineHeight: "22px",
            letterSpacing: "0.25px",
            fontWeight: 500,
            padding: "12px 24px",
            width: "100%",
        },
        sectionName: {
            fontSize: "18px",
            lineHeight: "28px",
            fontWeight: 600,
            [theme.breakpoints.down('md')]: {
                fontSize: "14px",
                lineHeight: "22px",
            },
        }
    }),
    { name: "upgradePlansFullTable" }
);
