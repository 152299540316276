import {
    getAnalyticsDependenciesApiAction,
    analyticsGoalAchievedApiAction
} from '../../../../redux/middleware/api/commonApiActions';

export const
    GET_ANALYTICS_DEPS_REQUEST_ACTION = 'GET_ANALYTICS_DEPS_REQUEST_ACTION',
    GET_ANALYTICS_DEPENDENCIES_SUCCESS_ACTION = 'GET_ANALYTICS_DEPENDENCIES_SUCCESS_ACTION',
    GET_ANALYTICS_DEPS_FAILURE_ACTION = 'GET_ANALYTICS_DEPS_FAILURE_ACTION',
    getAnalyticsDependenciesActionAC = () => getAnalyticsDependenciesApiAction({
        types: [
            GET_ANALYTICS_DEPS_REQUEST_ACTION,
            GET_ANALYTICS_DEPENDENCIES_SUCCESS_ACTION,
            GET_ANALYTICS_DEPS_FAILURE_ACTION
        ],
    });

export const
    ANALYTICS_GOAL_ACHIEVED_REQUEST_ACTION = 'ANALYTICS_GOAL_ACHIEVED_REQUEST_ACTION',
    ANALYTICS_GOAL_ACHIEVED_SUCCESS_ACTION = 'ANALYTICS_GOAL_ACHIEVED_SUCCESS_ACTION',
    ANALYTICS_GOAL_ACHIEVED_FAILURE_ACTION = 'ANALYTICS_GOAL_ACHIEVED_FAILURE_ACTION',
    analyticsGoalAchievedAC = (goalId: string) => analyticsGoalAchievedApiAction({
        types: [
            ANALYTICS_GOAL_ACHIEVED_REQUEST_ACTION,
            ANALYTICS_GOAL_ACHIEVED_SUCCESS_ACTION,
            ANALYTICS_GOAL_ACHIEVED_FAILURE_ACTION
        ],
        endpointParams: [goalId]
    });

export const
    REMEMBER_GOAL_ACHIEVED = 'REMEMBER_GOAL_ACHIEVED';

export const
    ANALYTICS_GOAL_WSB_TRIAL_CONVERTED_LOGIN = 'ANALYTICS_GOAL_WSB_TRIAL_CONVERTED_LOGIN';
