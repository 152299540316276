/* eslint-disable max-len */
import React from "react";
import styles from "./styles.css";
import { ViewTypes } from "../../TemplateSelector_DEPRECATED/constants";
import { processDataForThemePreview } from "../../ThemeGlobalData/utils/processDataForThemePreview";
import ThemePagePreview from "../../ThemeGlobalData/view/ThemePagePreview";
import getPageAndTemplateMobileData from "../../MobileViewEditor/getPageAndTemplateMobileData";
import LoadingCom from "../../../view/common/LoadingIndicator/index";
import { getOrientationStyles } from "../../Preview/View/Mobile/index";
import { MobileViewOrientations } from "../../Preview/View/Mobile/constants";
import type { GeneralGlobalData } from "../../SiteSettings/General/generalGlobalDataEpic/generalGlobalDataEpic";

type RightSideTemplatePreviewViewProps = {
    themingTemplatePreviewMode: string;
    themePreviewDataForOnboarding: Record<string, any>;
    changeThemePreviewPage: (...args: Array<any>) => any;
    generalData?: GeneralGlobalData;
    uiLocale: string;
    dynamicTemplateSelected?: boolean;
};
export class RightSideTemplatePreviewView extends React.Component<RightSideTemplatePreviewViewProps> {
    render() {
    const { themingTemplatePreviewMode, themePreviewDataForOnboarding, changeThemePreviewPage, generalData, uiLocale, dynamicTemplateSelected } = this.props,
        {
            uuidOfHomePage,
            designPageName,
            computedThemeColorsForNewOnBoarding,
            computedDataPageSetForNewOnBoarding,
            siteMap,
            siteSettings,
            localizationsByTemplateId
        } = themePreviewDataForOnboarding;

    const getThemePagePreview = () => {
        if (computedDataPageSetForNewOnBoarding) {
            const { pages: dataPages, template: dataTemplate, stylesheet: dataStylesheet } = computedDataPageSetForNewOnBoarding,
                { page, componentsMap, modifiedSiteSettings, modifiedStylesheets, template } = processDataForThemePreview({
                    dataPages,
                    dataTemplate,
                    dataStylesheet,
                    currentPageId: uuidOfHomePage,
                    siteSettings,
                    themeColors: computedThemeColorsForNewOnBoarding,
                    localizationsByTemplateId,
                    generalData
                });
            const conditionalProps: { expectedWidth?: string, orientationClassName?: string, contentProps?: Object } = {};

            if (themingTemplatePreviewMode === ViewTypes.desktop) {
                conditionalProps.expectedWidth = "100%";
            } else {
                const { orientationClassName, contentProps } = getOrientationStyles(
                    themingTemplatePreviewMode === ViewTypes.mobileVertical
                        ? MobileViewOrientations.PORTRAIT
                        : MobileViewOrientations.LANDSCAPE
                );
                conditionalProps.orientationClassName = orientationClassName;
                conditionalProps.contentProps = contentProps;
            }

            return (
                <ThemePagePreview
                    {...conditionalProps}
                    stylesheets={modifiedStylesheets}
                    currentPageId={uuidOfHomePage}
                    currentPageName={designPageName}
                    template={template}
                    componentsMap={componentsMap}
                    siteSettings={modifiedSiteSettings}
                    siteMap={siteMap}
                    mobileData={getPageAndTemplateMobileData(page.mobileData, template.mobileData)}
                    isTemplateSelector
                    localizationsByTemplateId={localizationsByTemplateId}
                    changeThemePreviewPage={changeThemePreviewPage}
                    themingTemplatePreviewMode={themingTemplatePreviewMode}
                    uiLocale={uiLocale}
                    dynamicTemplateSelected={dynamicTemplateSelected}
                /*messages={messages}*/
                />
            );
        }

        return <LoadingCom />;
    };

    return <div className={styles.onboardingTemplateColorThemePreview}>{getThemePagePreview()}</div>;
}
}
