import React from "react";
import cx from 'classnames';
import * as R from 'ramda';
import * as path from "../../../../../../mappers/path";
import styles from './View.css';
import { replaceTagsWithContent } from "../../../../Text/view/replaceTagsWithContent";
import { type FieldView } from "../../flowTypes";
import { THEME_BUTTON_CLASS } from '../../../../../ThemeGlobalData/constants';

const InputTypeView = ({ name, type, site, globalVariables, autoComplete }: FieldView) => {
    let props: any = {
        type: (type === 'number' ? 'text' : type),
        className: styles.input,
        value: '',
        name: replaceTagsWithContent(name, { site, globalVariables }),
        autoComplete,
        onChange: () => {}
    };
    if (type === 'number') {
        props.ctype = 'number';
    }
    return <input {...props} />;
};

const TextAreaView = ({ name }: FieldView) => <textarea className={cx(styles.input, styles.textArea)} name={name} />;

const MultipleInputTypeView = ({ name, type, values, textStyle, site, globalVariables }: FieldView) => <div>
    {values && Array.isArray(values) && values.map((value, i) => {
        const processedValue = replaceTagsWithContent(value, { site, globalVariables });
        return (
            <div key={i + processedValue + ''} style={textStyle}>
                <input className={styles.multipleInput} type={type} value={processedValue} name={name} id={`id-${name}-${i}`} />
                <label className={styles.multipleInputLabel} htmlFor={`id-${name}-${i}`}>{processedValue}</label>
            </div>
        );
    })}
</div>;

const DropDownView = ({ values, site, globalVariables }: FieldView) => {
    return <select className={styles.select} name="Dropdown">
        <option value=""> -- </option>
        {values && Array.isArray(values) && values.map((value, idx) => {
            const processedValue = replaceTagsWithContent(value, { site, globalVariables });
            return (<option key={idx} value={processedValue}>{processedValue}</option>);
        })}
    </select>;
};

const FileUploadView = ({ type, ...fuButtonProps }: FieldView) => {
    const docType = ".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    const isFileUploadBtnStyled = fuButtonProps.component.fileUploadButtonStyle && fuButtonProps.component.fileUploadButtonStyle.text;
    const isFileUploadBtnThemed = fuButtonProps.component.fuButtonThemeSelected;
    let globalStyleClassName = '';
    if (isFileUploadBtnThemed) {
        globalStyleClassName = fuButtonProps.autoColorMode ?
            fuButtonProps.stylesheetsIdToNameMap[fuButtonProps.firstButtonStylesheetId] :
            fuButtonProps.stylesheetsIdToNameMap[R.path(path.fileUploadButtonStyleGlobalId, fuButtonProps.component)];
    }
    const uploadBtnStyle = isFileUploadBtnStyled ? fuButtonProps.fuButtonStyle : {};
    const classList = fuButtonProps.autoColorMode && isFileUploadBtnThemed ?
        [fuButtonProps.component.fuButtonThemeSelected, THEME_BUTTON_CLASS, fuButtonProps.selectedParentTheme] : [];
    return (
        <div className="oneWebCntFormFileUpload">
            <div className={`${styles.fileUploadListCtn} fileUploadListCtn`}>
                <div className={`${styles.fileUploadListCtnEmtTxt} fileUploadListCtnEmptyTxt`}>
                    <label>
                        <input
                            type="file"
                            className="oneWebCntFormFileUploadInput"
                            multiple
                            // eslint-disable-next-line no-nested-ternary
                            accept={type === "images" ? "image/*" : ((type === "documents") ? docType : "*/*")}
                        />
                        <span>Click to upload</span>
                    </label>
                </div>
                <div className="fileList" />
            </div>
            <div className="fileUploadErrMsg" />
            <div className={cx('no-padding-override', styles.fileUploadBtnCtn)}>
                <label
                    className={cx(
                        globalStyleClassName,
                        styles.fileUploadBtn,
                        'no-padding-override oneWebCntFormFileUploadInput fileUploadBtn ',
                        classList
                    )}
                    style={uploadBtnStyle}
                >
                    <input
                        type="file"
                        multiple
                        // eslint-disable-next-line no-nested-ternary
                        accept={type === "images" ? "image/*" : ((type === "documents") ? docType : "*/*")}
                    />
                    <span className="contact-form-upload-files-text">Upload files</span>
                </label>
                <label className={styles.fileCount}>
                    <span className="uploadedFileCount">0</span>
                    <span>/5 files</span>
                </label>
            </div>
        </div>);
};

export default {
    text: InputTypeView,
    email: InputTypeView,
    textarea: TextAreaView,
    checkbox: MultipleInputTypeView,
    radio: MultipleInputTypeView,
    dropdown: DropDownView,
    url: InputTypeView,
    number: InputTypeView,
    tel: InputTypeView,
    all: FileUploadView,
    images: FileUploadView,
    documents: FileUploadView
};
