import type { MenuComponent } from "./flowTypes";
import type { CmpSpecificStyles } from "../../Preview/flowTypes";
import { isMediumLight, toHex } from "../../../mappers/color";
import { getDefaultAccentColor } from "./utils";
import { WHITE_COLOR, BLACK_COLOR } from "./constants/colors";

export const getSpecificStyles = ({
    autoColorMode,
    component: { id, style },
    globalStyles: stylesheets
}: CmpSpecificStyles<MenuComponent>): string => {
    if (autoColorMode) {
        return "";
    }

    const accentColorHSL = style.accentColor || getDefaultAccentColor(stylesheets);

    if (!accentColorHSL) {
        return "";
    }

    const accentColor = toHex(accentColorHSL);
    const dropdownItemColor = isMediumLight(accentColorHSL) ? BLACK_COLOR : WHITE_COLOR;
    return `div[data-id='${id}'] .modernLayoutMenu ul li a.level-0:hover {
        color: ${accentColor} !important
    }
    div[data-id='${id}'].modernLayoutMenu.dropdown ul {
        background-color:  ${accentColor} !important
    }
    div[data-id='${id}'].modernLayoutMenu.jsdropdown ul li a:hover {
        font-weight: bold !important;
    }
    div[data-id='${id}'].modernLayoutMenu.jsdropdown ul li a {
        color: ${dropdownItemColor} !important;
    }
    `;
};
