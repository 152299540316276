import React from "react";
import Measure from 'react-measure';
import { pure } from 'recompose';
import * as registry from '../../../oneweb/registry/index';
import { dispatchForwardToSelectedComponent } from '../../../../redux/forwardTo';
import injectIntl from '../../../intl/injectIntl';
import NoMouseEventsPropagationContainer from '../ComponentMainActions/noMouseEventsPropagationContainer';
import { COMPONENT_PANEL_RESIZED } from "./actionTypes";
import type { ComponentPanelActionsConfig, ViewProps } from './flowTypes';
import styles from './ComponentPanelActions.css';

class PanelView extends React.Component<ViewProps> {
    render() {
        const
            { componentPanelActions, dispatch, intl } = this.props,
            {
                dependencies,
                component,
                panelTop,
                panelLeft,
                hideActions,
                disableActions,
                isSectionTourVisible,
            } = componentPanelActions;

        const { kind } = component,
            componentPanelActionsConfig: ComponentPanelActionsConfig =
                registry.getComponentPanelActionsConfig(kind),
            { view, show } = componentPanelActionsConfig;

        if (!show) {
            return null;
        }
        const View = view,
            viewProps = {
                selectedComponent: component,
                hideActions,
                disableActions,
                isSectionTourVisible,
                panelTop,
                ...dependencies,
                ...componentPanelActionsConfig,
                dispatch: dispatchForwardToSelectedComponent(dispatch)
            };
        const mctaStyle: React.CSSProperties = {
            visibility: typeof panelTop === 'undefined' ? 'hidden' : undefined,
            top: panelTop,
            left: panelLeft
        };

        return (
            <div>
                <Measure
                    bounds
                    onResize={({ bounds: { width, height } }) => dispatch({
                        type: COMPONENT_PANEL_RESIZED,
                        payload: {
                            width: Math.ceil(width),
                            height: Math.ceil(height)
                        }
                    })}
                >
                    {({ measureRef }) => (
                        <div
                            ref={measureRef}
                            style={mctaStyle}
                            className={styles.panelContainer}
                        >
                            <div className={styles.panelInnerContainer}>
                                <NoMouseEventsPropagationContainer>
                                    <View intl={intl} {...viewProps} editCta />
                                </NoMouseEventsPropagationContainer>
                            </div>
                        </div>
                    )}
                </Measure>
            </div>
        );
    }
}

export default pure(injectIntl(PanelView));
