export const alignComponentsToCenterBasedOnTemplateWidthChange = (
    cmpItems: Array<Record<string, any>>,
    templateWidthChange: number
): Array<Record<string, any>> => {
    if (templateWidthChange <= 1) {
        return cmpItems;
    }
    return cmpItems.map((c) => {
        const { bbox, bbox: { left, right } } = c;
        if (c.stretch) {
            return c;
        }
        const change = Math.round((templateWidthChange / 2));
        return {
            ...c,
            bbox: { ...bbox, left: left + change, right: right + change }
        };
    });
};
