import React from "react";
import styles from "./VerificationEmailRequiredPage.css";
import { DemoLoginMessages } from "../../main/messages";
import Button from "../../../../../src/view/common/Button/Button";
import {
    cleanUpVerficationEmailRequiredScreenAction,
    showVerificationEmailRequiredSuccessPageAction
} from "../actions";
import { showSignInPage } from "../../signUp/actions";
import VerticalSpacer from "../../../../../src/view/common/VerticalSpacer";
import type { VerificationEmailRequiredPagePropTypes } from "../../types";
import { cleanUpAllErrors, demoResendConfirmationCodeAction } from "../../signIn/actions";
import { normalizeEmail } from "../../../../../../server/shared/utils/normalizeEmail";
import { makeBoldIntlTag, Msg } from "../../../../../src/view/intl/index";
import { demoSignOutAction } from "../../../../actions";

const VerificationEmailRequiredPage = ({
    intl,
    dispatch,
    verificationEmailRequired,
    verificationEmailRequiredSuccess,
    email,
}: VerificationEmailRequiredPagePropTypes) => {
    const {
        verificationEmailSentMsg,
        resetEmailSuccessHeaderLabel,
        resetEmailSuccessHeaderMsg,
        checkInboxMsg,
        returnToLoginLabel,
        userEmailVerificationRequiredHeader,
        userEmailVerificationRequiredResendLabel,
    } = DemoLoginMessages;
    const verificationEmailRequiredPage = () => {
        return (
            <React.Fragment>
                <VerticalSpacer y={35} />
                <div>
                    <div className={styles.emailIcon} />
                    <VerticalSpacer y={25} />
                    <div className={styles.headerLabel}>
                        {intl.msgJoint(userEmailVerificationRequiredHeader)}
                    </div>
                </div>
                <VerticalSpacer y={15} />

                <div className={styles.helperMsg}>
                    <div>
                        <Msg
                            k="demo.login.error.userEmailVerificationRequired.notifyMsg"
                            params={{
                                email,
                                b: makeBoldIntlTag,
                            }}
                        >
                            {`We’ve sent an email with a link to <b>{email}</b>.`}
                        </Msg>

                        <br />

                        <Msg
                            k="demo.login.error.userEmailVerificationRequired.notifyMsg.2"
                        >
                            You’ll need to verify the link before you can log in.
                        </Msg>

                        <VerticalSpacer y={10} />
                        <Msg
                            k="demo.login.error.userEmailVerificationRequired.notifyMsg.3"
                        >
                            If the email doesn’t show up, try checking your spam folder
                        </Msg>

                        <br />

                        <a
                            className={styles.resendEmailLink}
                            href="#"
                            onClick={() => {
                                dispatch(demoResendConfirmationCodeAction(normalizeEmail(email)));
                                dispatch(cleanUpAllErrors());
                                dispatch(showVerificationEmailRequiredSuccessPageAction());
                            }}
                        >
                            {intl.msgJoint(userEmailVerificationRequiredResendLabel)}
                        </a>
                    </div>
                </div>
                <div className={styles.bottomButtonContainer}>
                    <div className={styles.actionsContainer}>
                        <div className={styles.returnToLoginContainer}>
                            <Button
                                onClick={() => {
                                    dispatch(cleanUpAllErrors());
                                    dispatch(demoSignOutAction());
                                    dispatch(showSignInPage());
                                }}
                                className={styles.returnToLoginButton}
                            >
                                {intl.msgJoint(returnToLoginLabel)}
                            </Button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const verificationEmailRequiredSuccessPage = () => {
        return (
            <React.Fragment>
                <div>
                    <div className={styles.headerLabel}>
                        {intl.msgJoint(resetEmailSuccessHeaderLabel)}!
                    </div>
                    <div className={styles.headerMsg}>
                        <div>
                            {intl.msgJoint(resetEmailSuccessHeaderMsg)}...
                        </div>
                    </div>
                </div>
                <VerticalSpacer y={49} />
                <div>
                    <div>
                        <div className={styles.checkInboxLabelContainer}>
                            <span className={styles.checkBoxIcon} />
                            <span className={styles.checkInboxLabel}>
                                {intl.msgJoint(verificationEmailSentMsg)}
                            </span>
                        </div>
                        <div className={styles.checkInboxMsg}>
                            {intl.msgJoint(checkInboxMsg)}
                        </div>
                    </div>
                </div>
                <VerticalSpacer y={249} />
                <div>
                    <div className={styles.returnToLoginContainer}>
                        <Button
                            onClick={() => {
                                dispatch(cleanUpVerficationEmailRequiredScreenAction());
                                dispatch(showSignInPage());
                            }}
                            className={styles.returnToLoginButton}
                        >
                            {intl.msgJoint(returnToLoginLabel)}
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    return (
        <div>
            {verificationEmailRequired && verificationEmailRequiredPage()}
            {verificationEmailRequiredSuccess && verificationEmailRequiredSuccessPage()}
        </div>
    );
};
export {
    VerificationEmailRequiredPage
};
