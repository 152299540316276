// TODO: remove; not used anymore

import isDevEnv from "../../debug/isDevEnv";
import { APP_MOUNT } from "../../components/App/actionTypes";
import Url from "../../../utils/Url";
import { getDAL, init as initDAL } from '../../../dal/index';
import { getCookie } from '../../services/cookie';
import { AUTH_COOKIE_KEY } from '../../components/App/epics/auth/constants';
import getLogin from '../../components/App/epics/auth/utils/getLogin';
import DataPageTemplateSet from '../../../dal/model/DataPageTemplateSet';
import mergePageLayoutWithTemplate from '../../components/PagesTree/pagesLogic/mergePageLayoutWithTemplate';
import makeUuid from '../../../utils/makeUuid';
import { makeNewPageRef } from '../../components/PagesTree/utils/index';
import { DataPageStylesheet, DataPageTemplate, DataSite } from '../../../dal/model/index';
import { preparePersistentModel, prepareNewPersistentModel } from '../../../dal/model/utils/index';
import { PageLayoutCategoryId } from "../../../dal/model/DataPageLayout";

const responseHandler = cb => ({ response, body }) => {
    if (!response.ok) {
        console.log('===devInitNewDomain Error response', response);
        throw new Error('devInitNewDomain request failed :(');
    }

    cb(body);
};

export default () => (next: Dispatch) => (action: Action) => {
    const url = Url(window.location.href);
    if (isDevEnv() && action.type === APP_MOUNT && url.query.get('devInitNewDomain')) {
        const login = getLogin(getCookie(AUTH_COOKIE_KEY));
        // @ts-ignore
        initDAL(login?.domain);

        getDAL().getSiteData().then(responseHandler(site => {
            getDAL()
                .getRepositoryPageDataSet('87D84783-643E-445E-AE86-BC7B0CBB1F89')
                .then(responseHandler(layoutData => {
                    const
                        template = new DataPageTemplate({ ...layoutData.template, id: makeUuid() }),
                        stylesheet = new DataPageStylesheet({ ...layoutData.stylesheet, id: makeUuid() }),
                        templateData = new DataPageTemplateSet({ template, stylesheet }),
                        newLayoutData = mergePageLayoutWithTemplate(layoutData, templateData, layoutData.page.id),
                        page = newLayoutData.page;

                    template.stylesheetId = stylesheet.id;
                    page.id = makeUuid();
                    page.name = 'Home';

                    const
                        pageRef = makeNewPageRef(
                            page,
                            site,
                            null,
                            undefined,
                            { name: page.name, title: page.name, layoutCategory: PageLayoutCategoryId.BLANK_PAGE, hidden: false },
                            {}
                        ),
                        newSite = new DataSite({
                            ...site,
                            folder: {
                                ...site.folder,
                                items: [pageRef]
                            },
                            homePageId: page.id,
                            fonts: []
                        });

                    const batch = {
                        update: [preparePersistentModel(newSite)],
                        add: [
                            prepareNewPersistentModel(template),
                            prepareNewPersistentModel(stylesheet),
                            prepareNewPersistentModel(page)
                        ]
                    };

                    getDAL()
                        .devBatchPost(batch)

                        .then(responseHandler(() => console.log('=== devInitNewDomain Success !!!')));
                }));
        }));
    }

    return next(action);
};
