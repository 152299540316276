/* eslint-disable max-len */

import React from "react";
import { connect } from "react-redux";
import VerticalSpacer from '../../../../view/common/VerticalSpacer';
import getCenteredDialog from "../../../DialogManager/getCenteredDialogConfig";
import { ConfigDialogSettings, DialogHeight, DialogWidth, } from "../constants/configurationDialog";
import type { ConnectFacebookChatAccountDialogPropTypes } from "../flowTypes";
import { Msg } from "../../../../view/intl/index";
import StandardDialog from "../../../../view/common/dialogs/StandardDialog/index";
import styles from './configurationDialog.css';
import { StripTypes } from "../../../../view/common/dialogs/baseDialog/index";
import type { AppState } from "../../../../redux/modules/flowTypes";
import { socialAccountsFacebookChatConnected, socialAccountsFacebookFeedConnected } from "../selectors";
import { COMPONENT_CONFIGURATION_CANCELED } from "../../../../redux/modules/children/workspace/actionTypes";
import { facebookSdkLoginAction } from "../../../SocialAccounts/Facebook/facebookSdkActions";
import { FB_CHAT_LOGIN } from "../../../SocialAccounts/Facebook/constants";
import PrimaryButton from "../../../../view/common/Button/PrimaryButton";
import { replaceWithSiteSettingsDialog } from '../../../SiteSettings/SiteSettingsDialog/actionCreators';
import { SiteSettingsTabName } from '../../../SiteSettings/SiteSettingsDialog/constants';
import { closeDialog } from "../../../App/actionCreators/index";
import { makeEpicStateSelector } from "../../../../epics/makeEpic";
import subscriptionDataVat from "../../../App/epics/subscriptionData/valueActionType";
import {
    isEcommerceSubscription
} from "../../../App/epics/subscriptionData/utils";

const MessageBasedOnConnectionStatus = ({ isFacebookFeedConnected, isEcommerceTierUser }) => {
    let msgOnConnectionStatus: React.ReactElement;

    if (isEcommerceTierUser) {
        if (isFacebookFeedConnected) {
            msgOnConnectionStatus = (
                <Msg className={styles.connectDlgBodyMsg} k="facebookChat.connect.dialog.body.msg">
                    We can see you’ve already given Website Builder permission to show your Facebook gallery. We just need extra permission to add Messenger chat.
                </Msg>
            );
        } else {
            msgOnConnectionStatus = (
                <Msg className={styles.connectDlgBodyMsg} k="facebookChat.notconnect.dialog.body.msg">
                    Connect with Facebook so that Website Builder can add Messenger chat to your site.
                </Msg>
            );
        }
    } else {
        msgOnConnectionStatus = (
            <Msg className={styles.connectDlgBodyMsg} k="facebookChat.notconnect.nonbne.dialog.body.msg">
                Connect with Facebook so that Website Builder can add Messenger chat to your site. You can only connect an existing business page and not a personal account.
            </Msg>
        );
    }

    return msgOnConnectionStatus;
};
class ConnectFacebookChatAccountDialog extends React.Component<ConnectFacebookChatAccountDialogPropTypes> {
    render() {
        const { props: { dispatch, isFacebookChatConnected, isFacebookFeedConnected, subscriptionType } } = this,
            isEcommerceTierUser = isEcommerceSubscription(subscriptionType);
        if (isFacebookChatConnected) {
            dispatch(replaceWithSiteSettingsDialog({ activeTabKey: SiteSettingsTabName.MESSENGER_CHAT }));
            return '';
        } else {
            return (
                <StandardDialog
                    title={ConfigDialogSettings.TITLE}
                    titleExtraClassName={styles.chatConnectDlgTitleContainer}
                    titleClassName={styles.chatConnectDlgTitle}
                    stripType={StripTypes.NONE}
                    closeBtnClassName={styles.closeBtnClassName}
                    onClose={() => {
                        dispatch({ type: COMPONENT_CONFIGURATION_CANCELED });
                    }}
                >
                    <div className={styles.rootContainer}>
                        <VerticalSpacer y={30} />
                        <div className={styles.messengerConnectIcon} />
                        <VerticalSpacer y={30} />
                        <MessageBasedOnConnectionStatus isFacebookFeedConnected={isFacebookFeedConnected} isEcommerceTierUser={isEcommerceTierUser} />
                        <VerticalSpacer y={20} />
                        { isEcommerceTierUser &&
                            <Msg className={styles.connectDlgBodyMsg} k="facebookChat.dialog.body.submsg">
                                You can only connect an existing business page and not a personal account.
                            </Msg>}
                        <VerticalSpacer y={30} />
                        <PrimaryButton
                            className={styles.facebookChatConnectBtn}
                            onClick={() => {
                                dispatch(facebookSdkLoginAction(FB_CHAT_LOGIN));
                                dispatch(closeDialog());
                            }}
                        >
                            <Msg k="common.connect">
                                Connect
                            </Msg>
                        </PrimaryButton>
                    </div>
                </StandardDialog>
            );
        }
    }
}

const subscriptionDataSelector = makeEpicStateSelector(subscriptionDataVat);

const mapStateToProps = (appState: AppState) => {
    const subscriptionData = subscriptionDataSelector(appState);
    return {
        subscriptionType: subscriptionData.subscriptionType,
        isFacebookChatConnected: socialAccountsFacebookChatConnected(appState),
        isFacebookFeedConnected: socialAccountsFacebookFeedConnected(appState)
    };
};

export default getCenteredDialog({
    width: DialogWidth,
    height: DialogHeight,
    component: connect(mapStateToProps)(ConnectFacebookChatAccountDialog)
});
