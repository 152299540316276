
import { calcGalleryMaxThumbnailDimensions } from "./calcRenderProps";
import type { InstagramGalleryComponent } from "./flowTypes";
import type { InstagramMediaAsset } from "../../SocialAccounts/instagram/types";
import { SHOW_MORE_BTN_HEIGHT } from './constants/index';

export const calcGalleryHeight =
    // eslint-disable-next-line max-len
    ({ component, instagramMediaAssets }: { component: InstagramGalleryComponent, instagramMediaAssets: Array<InstagramMediaAsset> }) => {
        const
            rows = Math.ceil(instagramMediaAssets.length / component.columns),
            { maxThumbnailHeight } = calcGalleryMaxThumbnailDimensions(component);

        let
            calculatedHeight = Math.round(((
                maxThumbnailHeight
            ) * rows) + (component.spacingPx * (rows - 1)));
        if (component.showMoreBtn && instagramMediaAssets.length) {
            calculatedHeight += SHOW_MORE_BTN_HEIGHT; // adding explicit height if show more btn is enabled
        }
        return calculatedHeight;
    };
