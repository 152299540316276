import React from "react";
export const SvgFoursquareCircleFillDark = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <circle cx={24} cy={24} r={24} fill="#3c3c3c" />
            <path
                fill="#ffffff"
                d="M34.563 13.187a1.686 1.686 0 00-1.358-.687H17.186c-.931 0-1.686.755-1.686 1.686v20.236a1.686 1.686 0 002.878 1.193l6.251-6.252h4.36c.738 0 1.39-.48 1.61-1.183l4.215-13.49c.16-.513.067-1.07-.251-1.503zm-4.706 6.058h-5.926a1.686 1.686 0 000 3.373h4.872L27.75 25.99h-3.82c-.446 0-.875.177-1.192.494l-3.866 3.866V15.873h12.039l-1.054 3.372z"
            />
        </g>
    </svg>
);
