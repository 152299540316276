import FACEBOOK from "./kind";
import { ComponentNames } from "../constants";

import { defaultFacebookPage } from '../../../utils/AppConfigHelpers/defaultFacebookPage';

export const
    HTTP_PREFIX = 'https://www.',
    PAGE_PREFIX = 'facebook.com/',
    DEFAULT_PAGE = defaultFacebookPage,
    DEFAULT_WIDTH = 340,
    DEFAULT_HEIGHT = 130,
    TABS_TIMELINE = 'timeline',
    MIN_WIDTH = 180,
    MAX_WIDTH = 500,
    MIN_HEIGHT = 70,
    SMOOTH_VERTICAL_HEIGHT = 600,
    FACEBOOK_API_BASE_URL = `https://connect.facebook.net`,
    DialogWidth = 500,
    DialogHeight = 340,
    ConfigDialogSettings = {
        TITLE: ComponentNames[FACEBOOK],
        MESSAGE: 'msg: component.facebook.configDialog.message {Enter the URL of a Facebook page below.}',
        SUBMESSAGE: 'msg: component.facebook.configDialog.subMessage {Please note: this will not work with your personal timeline / Facebook profile.}', // eslint-disable-line max-len
        ADD_SAVE_LABEL: 'msg: component.facebook.configDialog.addSaveLabel {Add Facebook page}',
        UPDATE_SAVE_LABEL: 'msg: component.facebook.configDialog.updateSaveLabel {Update settings}'
    },
    LocaleMap = {
        cs: 'cs_CZ',
        da: 'da_DK',
        de: 'de_DE',
        en_gb: 'en_GB',
        en_us: 'en_US',
        en: 'en_US',
        es: 'es_LA',
        fr: 'fr_FR',
        it: 'it_IT',
        nl: 'nl_NL',
        nb: 'nb_NO',
        pl: 'pl_PL',
        pt: 'pt_PT',
        pt_br: 'pt_BR',
        ru: 'ru_RU',
        fi: 'fi_FI',
        sv: 'sv_SE'
    },
    defaultState = {
        pageURL: DEFAULT_PAGE,
        tabs: [],
        hideCover: false,
        showFacepile: false,
        showCTA: false,
        smallHeader: false,
        adaptContainerWidth: true,
        mobileHide: false,
        mobileDown: false
    };

/* ContextMenu constants */
export const
    ChangePageURLConfig = {
        title: 'Change Page URL',
        shortcut: 'Ctrl + L'
    };
