/* @flow */

const TrialImproCookieKey = {
    TOKEN: '__timprtkn',
    ACCESS_KEY: '__timpraccssk',
    SECRET_KEY: '__timprscrtk',
    SESSION_ID: '__timprssid',
};

module.exports = {
    TrialImproCookieKey,
};
