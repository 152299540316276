/* eslint-disable max-len */
import { textLikeComponentFactory } from '../factories/textLikeComponentFactory';
import { EMAIL_KIND } from './kind';
import { emailComponentView } from "./emailComponentView";
import { computeComponentDependenciesForPreviewForEmail } from "./computeComponentDependenciesForPreviewForEmail";
import { getPlaceholderTextForEmail } from "./getPlaceholderTextForEmail";

const {
    mappers: { to: emailComponentMapperTo, back: emailComponentMapperBack },
    epic: emailComponentEpic,
    previewConfig: emailPreviewConfig
} = textLikeComponentFactory({
    View: emailComponentView,
    kind: EMAIL_KIND,
    computeComponentDependenciesForPreview: computeComponentDependenciesForPreviewForEmail,
    getPlaceholderText: getPlaceholderTextForEmail,
});

export {
    emailComponentMapperTo,
    emailComponentMapperBack,
    emailComponentEpic,
    emailPreviewConfig,
};

