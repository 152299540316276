import * as R from 'ramda';
import type { TooltipStyles, TooltipPositionFnProps, PositionAndDimensions } from "../../flowTypes";
import { PointerDirections, ToolTipDefaults, gapToScreenEnd } from "../../constants";
import registry from "../../registry";
import { TopBarHeight } from "../../../TopBar/constants";

function getLeftForLeftTooltip(x, pdhl, gap, w) {
    return x - pdhl - gap - w;
}

function getPointerStyleForLeftTooltip(pointerStyle, pdhl, w) {
    return {
        ...pointerStyle,
        left: w - pdhl + 1 // TODO: WBTGEN-7710: Need to find out why this correction of 1 pixel is required.
    };
}

export default ({
    x, y, browserWidth, browserHeight, elementDimensions,
    leftPanelWidth, tooltipProps, id, pointerDirection, floorTooltipBbox
}: TooltipPositionFnProps): TooltipStyles => {
    const
        toolTipWidth = registry[id].dynamicWidth ? tooltipProps.width : R.pathOr(ToolTipDefaults.width, ['props', 'width'], registry[id]),
        finalPointerDirection = pointerDirection || registry[id].pointerDirection || PointerDirections.Left,
        finalTooltipProps = { ...ToolTipDefaults, ...(registry[id].props || {}), ...tooltipProps, width: toolTipWidth };
    // @ts-ignore
    let { width: w, height: h, minHeight, gap, pointerEdge, pointerAdjustement } = finalTooltipProps,
        // pdhl - pointer diagonal half length
        pdhl = (Math.ceil(Math.sqrt(2) * pointerEdge) / 2);

    h = (minHeight && h < minHeight) ? minHeight : h;

    let
        left,
        top,
        pointerStyle: PositionAndDimensions = {
            width: pointerEdge,
            height: pointerEdge,
            opacity: 1
        } as PositionAndDimensions,
        diff = 0;
    if (finalPointerDirection === PointerDirections.Top || finalPointerDirection === PointerDirections.Bottom) {
        pointerStyle = {
            ...pointerStyle,
            left: x,
            top: pointerAdjustement - pdhl
        };

        left = x - (w / 2);
        if ((left + w + gapToScreenEnd) > browserWidth) {
            left = browserWidth - w - gapToScreenEnd;
        }
        pointerStyle.left = x - left - pdhl + pointerAdjustement;

        top = y + pdhl + gap;
        if ((top + h) > browserHeight || finalPointerDirection === PointerDirections.Bottom) {
            top = y - h - pdhl - gap - (elementDimensions ? elementDimensions.height : 0);
            pointerStyle.top = h - pdhl;
        }

        return { position: { x, y }, style: { left, top, width: w, opacity: 1 }, pointerStyle };
    }
    pointerStyle = {
        ...pointerStyle,
        left: pointerAdjustement - pdhl,
        top: ((h / 2) - pdhl) + pointerAdjustement
    };

    left = x + pdhl + gap;
    left = left < leftPanelWidth ? leftPanelWidth : left;
    if ((left + w) > browserWidth) {
        left = x - (gap + pdhl) - w + pointerAdjustement;
        pointerStyle.left = w - pdhl;
    }

    top = y - (h / 2);

    /**
     * If pointer is on right, then the tooltip should come on left side of the reference element.
     */
    if (finalPointerDirection === PointerDirections.Right) {
        left = getLeftForLeftTooltip(x, pdhl, gap, w);
        pointerStyle = getPointerStyleForLeftTooltip(pointerStyle, pdhl, w);
    }

    /**
     * TODO: WBTGEN-7710
     * Code to align top, bottom, left or right of tooltip to any other BBOX.
     * This needs to be extended for bottom, left and right. Implementing only for top now.
     * Need to refactor the code.
     */
    if (floorTooltipBbox) {
        const floorTooltipBboxTop = floorTooltipBbox.top;
        if (floorTooltipBboxTop) {
            pointerStyle.top = pointerStyle.top - (floorTooltipBboxTop - top);
            pointerStyle.top = pointerStyle.top < pdhl ? pdhl : pointerStyle.top;
            top = floorTooltipBboxTop;
        }
    }

    if (top < TopBarHeight) {
        pointerStyle.top = pointerStyle.top - (TopBarHeight - top);
        pointerStyle.top = pointerStyle.top < pdhl ? pdhl : pointerStyle.top;
        top = TopBarHeight;
    }
    if ((top + h) > browserHeight) {
        diff = top + h - browserHeight;
        pointerStyle.top = pointerStyle.top + diff;
        top = top - diff;
    }

    return { position: { x, y }, style: { left, top, width: w, opacity: 1 }, pointerStyle };
};
