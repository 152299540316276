import { head, last, subtract } from "ramda";
import { DAYS_OF_THE_WEEK_STARTING_FROM_SUNDAY, getMidnightToday, OPEN_24_HOURS_VALUE, TWENTY_FOUR_HOUR_TIME_OPTIONS } from "./constants";
import { createOptions } from "../../../view/common/Combobox/utils";
import { Intl } from "../../../view/intl/injectIntl";
import type {
    DayOfTheWeek,
    GmbOpeningHour,
    OpeningHour,
    OpeningHoursMap,
    SiteSettingsOpeningHoursMap,
    SiteSettingsOpeningHoursMapValue
} from "./flowTypes";

type OpeningHoursMapType = OpeningHoursMap | SiteSettingsOpeningHoursMap;
export const getDaysOfTheWeek = (startingFrom: DayOfTheWeek) => {
    const startDayIndex = DAYS_OF_THE_WEEK_STARTING_FROM_SUNDAY.indexOf(startingFrom);
    return [
        ...DAYS_OF_THE_WEEK_STARTING_FROM_SUNDAY.slice(startDayIndex),
        ...DAYS_OF_THE_WEEK_STARTING_FROM_SUNDAY.slice(0, startDayIndex)
    ];
};

const prev = (list, item) => list[list.indexOf(item) - 1];

export const getPrevDay = (day: DayOfTheWeek) =>
    prev(DAYS_OF_THE_WEEK_STARTING_FROM_SUNDAY, day) || last(DAYS_OF_THE_WEEK_STARTING_FROM_SUNDAY);

const next = (list, item) => list[list.indexOf(item) + 1];

export const getNextDay = (day: DayOfTheWeek) =>
    next(DAYS_OF_THE_WEEK_STARTING_FROM_SUNDAY, day) || head(DAYS_OF_THE_WEEK_STARTING_FROM_SUNDAY);

const sortByField = (list, field) => {
    return list.sort((a, b) => a[field] - b[field]);
};

const sortByTime = list => {
    return sortByField(sortByField(list, "closeTime"), "openTime");
};

export const areOpeningHoursBreakFree = (openingHours: OpeningHour[]): boolean => {
    // @ts-ignore
    return DAYS_OF_THE_WEEK_STARTING_FROM_SUNDAY.reduce((acc, day) => {
        const openingHoursForTheDay = sortByTime(openingHours.filter(({ openDay }) => openDay === day));
        let isBreakFree = true;
        let fromEntry = null;
        const openingHoursForThePrevDay = sortByTime(openingHours.filter(({ openDay }) => openDay === getPrevDay(day)));

        if (
            openingHoursForTheDay.length > 1 && // multiple entries exist for the current day
            openingHoursForTheDay[0].openTime === 0 && // first entry for the current day is 00:00
            openingHoursForTheDay[0].closeTime !== 0 &&
            openingHoursForThePrevDay.length && // not closed on previous day
            last(openingHoursForThePrevDay).closeTime === 0 && // previous day ends at 00:00 // previous day doesn't start at 00:00
            (last(openingHoursForThePrevDay).openTime >= openingHoursForTheDay[0].closeTime || openingHoursForThePrevDay.length > 1) // previous day has multiple entries
        ) {
            fromEntry = openingHoursForTheDay[1];
        } else if (
            openingHoursForTheDay.length &&
            (openingHoursForTheDay[0].openTime !== 0 ||
                openingHoursForTheDay[0].closeTime === 0 ||
                openingHoursForThePrevDay.length === 0 ||
                last(openingHoursForThePrevDay).closeTime !== 0 ||
                openingHoursForThePrevDay[0].openTime === 0 ||
                openingHoursForTheDay[0].closeTime > openingHoursForThePrevDay[0].openTime)
        ) {
            fromEntry = openingHoursForTheDay[0];
        }

        let toEntry = null;
        const openingHoursForTheNextDay = sortByTime(openingHours.filter(({ openDay }) => openDay === getNextDay(day)));

        if (
            openingHoursForTheNextDay.length && // entry exist for the next day
            openingHoursForTheNextDay[0].openTime === 0 && // first entry for the next day is 00:00
            openingHoursForTheDay.length && // not closed on the current day
            last(openingHoursForTheDay).closeTime === 0 && // the current day ends at 00:00 // the current day doesn't start at 00:00
            (last(openingHoursForTheDay).openTime !== 0 || // the current day has only one entry that doesn't start at 00:00
                openingHoursForTheDay.length > 1) && // the current day has multiple entries
            last(openingHoursForTheDay).openTime >= head(openingHoursForTheNextDay).closeTime
        ) {
            toEntry = openingHoursForTheNextDay[0];
        } else if (openingHoursForTheDay.length) {
            toEntry = last(openingHoursForTheDay);
            isBreakFree = fromEntry === toEntry;
        }

        return acc && isBreakFree;
    }, true);
};
export const openingHoursToOpeningHoursMap = (openingHours: OpeningHour[]): OpeningHoursMap => {
    return DAYS_OF_THE_WEEK_STARTING_FROM_SUNDAY.reduce((acc, day) => {
        const openingHoursForTheDay = sortByTime(openingHours.filter(({ openDay }) => openDay === day));
        let from = null;
        const openingHoursForThePrevDay = sortByTime(openingHours.filter(({ openDay }) => openDay === getPrevDay(day)));

        if (
            openingHoursForTheDay.length > 1 && // multiple entries exist for the current day
            openingHoursForTheDay[0].openTime === 0 && // first entry for the current day is 00:00
            openingHoursForTheDay[0].closeTime !== 0 &&
            openingHoursForThePrevDay.length && // not closed on previous day
            last(openingHoursForThePrevDay).closeTime === 0 && // previous day ends at 00:00 // previous day doesn't start at 00:00
            (last(openingHoursForThePrevDay).openTime >= openingHoursForTheDay[0].closeTime || openingHoursForThePrevDay.length > 1) // previous day has multiple entries
        ) {
            from = openingHoursForTheDay[1].openTime;
        } else if (
            openingHoursForTheDay.length &&
            (openingHoursForTheDay[0].openTime !== 0 ||
                openingHoursForTheDay[0].closeTime === 0 ||
                openingHoursForThePrevDay.length === 0 ||
                last(openingHoursForThePrevDay).closeTime !== 0 ||
                openingHoursForThePrevDay[0].openTime === 0 ||
                openingHoursForTheDay[0].closeTime > openingHoursForThePrevDay[0].openTime)
        ) {
            from = openingHoursForTheDay[0].openTime;
        }

        let to = null;
        const openingHoursForTheNextDay = sortByTime(openingHours.filter(({ openDay }) => openDay === getNextDay(day)));

        if (
            openingHoursForTheNextDay.length && // entry exist for the next day
            openingHoursForTheNextDay[0].openTime === 0 && // first entry for the next day is 00:00
            openingHoursForTheNextDay[0].closeTime !== 0 &&
            openingHoursForTheDay.length && // not closed on the current day
            last(openingHoursForTheDay).closeTime === 0 && // the current day ends at 00:00 // the current day doesn't start at 00:00
            (last(openingHoursForTheDay).openTime !== 0 || // the current day has only one entry that doesn't start at 00:00
                openingHoursForTheDay.length > 1) && // the current day has multiple entries
            last(openingHoursForTheDay).openTime >= head(openingHoursForTheNextDay).closeTime
        ) {
            to = openingHoursForTheNextDay[0].closeTime;
        } else if (openingHoursForTheDay.length) {
            if (openingHoursForTheDay[0].openTime === 0 && openingHoursForTheDay[0].closeTime === 0) {
                to = head(openingHoursForTheDay).closeTime;
            } else if (
                openingHoursForTheDay[0].closeTime === 0 &&
                openingHoursForTheNextDay.length &&
                openingHoursForTheNextDay[0].openTime === 0 &&
                openingHoursForTheNextDay[0].closeTime === 0
            ) {
                to = last(openingHoursForTheNextDay).closeTime;
            } else {
                to = last(openingHoursForTheDay).closeTime;
            }
        }

        return {
            ...acc,
            [day]:
                from !== null && to !== null
                    ? {
                        from,
                        to
                    }
                    : undefined
        };
    }, ({} as any) as OpeningHoursMap);
};
export const siteSettingsOpeningHoursToOpeningHoursMap = (openingHours: OpeningHour[]): SiteSettingsOpeningHoursMap => {
    let tempOpeningHourMap = openingHoursToOpeningHoursMap(openingHours);
    return Object.entries(tempOpeningHourMap).reduce((acc, [day, value]) => {
        return { ...acc, [day]: value ? { ...value, isSet: true } : undefined };
    }, ({} as any) as SiteSettingsOpeningHoursMap);
};
export const msTimeToGmbTime = (gmbTime: number, isToTime: boolean = false): any => { // NEW-CODE
// export const msTimeToGmbTime = (gmbTime: number, isToTime: boolean = false): string => { // OLD-CODE
    const date = new Date(getMidnightToday() + gmbTime);

    // NEW-CODE
    const hours = (date.getHours() === 0 && isToTime) ? 24 : date.getHours(); // NEW-CODE : TODO: Verify that 24 is applicable in new API as well
    const minutes = date.getMinutes();
    return {
        hours,
        minutes
    };
    // OLD-CODE
    // const hours = date.getHours() === 0 && isToTime ? "24" : String(date.getHours()).padStart(2, "0");
    // const minutes = String(date.getMinutes()).padStart(2, "0");
    // return `${hours}:${minutes}`;
};
export const openingHoursWithMsTimeToGmbTime = (openingHours: OpeningHour[]): GmbOpeningHour[] => {
    return openingHours.map(({ openTime, closeTime, ...rest }) => {
        return { ...rest, openTime: msTimeToGmbTime(openTime), closeTime: msTimeToGmbTime(closeTime) };
    });
};
export const gmbTimeToMsTime = (gmbTime: any): number => { // NEW-CODE
// export const gmbTimeToMsTime = (gmbTime: string): number => { // OLD-CODE
    const { hours = 0, minutes = 0 } = gmbTime; // NEW-CODE
    // const [hours, minutes] = gmbTime.split(":"); // OLD-CODE

    return (Number(hours) % 24) * (60 * 60 * 1000) + Number(minutes) * (60 * 1000);
};
export const openingHoursWithGmbTimeToMsTime = (openingHours: GmbOpeningHour[]): OpeningHour[] => {
    return openingHours.map(({ openTime, closeTime, ...rest }) => {
        return { ...rest, openTime: gmbTimeToMsTime(openTime), closeTime: gmbTimeToMsTime(closeTime) };
    });
};

const processOpeningHoursMapToOpeningHours = (openingHoursMap: OpeningHoursMapType, day: DayOfTheWeek): OpeningHour[] => {
    const dayEntries: OpeningHour[ ] = [];

    if (openingHoursMap[day]) {
        // @ts-ignore
        const { from, to } = openingHoursMap[day];

        if (to !== 0 && from > to) {
            dayEntries.push(
                {
                    openDay: day,
                    closeDay: day,
                    openTime: from,
                    closeTime: 0
                },
                {
                    openDay: getNextDay(day),
                    closeDay: getNextDay(day),
                    openTime: 0,
                    closeTime: to
                }
            );
        } else {
            dayEntries.push({
                openDay: day,
                closeDay: day,
                openTime: from,
                closeTime: to
            });
        }
    }

    return dayEntries;
};

export const openingHoursMapToOpeningHours = (openingHoursMap: OpeningHoursMap): OpeningHour[] => {
    // @ts-ignore
    return Object.keys(openingHoursMap).reduce((acc, day) => {
        // @ts-ignore
        const dayEntries = processOpeningHoursMapToOpeningHours(openingHoursMap, day);
        return [...acc, ...dayEntries];
    }, []);
};
export const siteSettingsOpeningHoursMapToOpeningHours = (openingHoursMap: SiteSettingsOpeningHoursMap): OpeningHour[] => {
    // @ts-ignore
    return Object.keys(openingHoursMap).reduce((acc, day) => {
        const dayEntries =
            // @ts-ignore
            openingHoursMap[day] && openingHoursMap[day].isSet ? processOpeningHoursMapToOpeningHours(openingHoursMap, day) : [];
        return [...acc, ...dayEntries];
    }, []);
};
export const isInvalidOpeningHour = isNaN;

const makeTimeOptionsWithCurrent = current => {
    if (TWENTY_FOUR_HOUR_TIME_OPTIONS.includes(current)) {
        return TWENTY_FOUR_HOUR_TIME_OPTIONS;
    } else if (isInvalidOpeningHour(current)) {
        return [...TWENTY_FOUR_HOUR_TIME_OPTIONS, current];
    } else {
        return [...TWENTY_FOUR_HOUR_TIME_OPTIONS, current].sort(subtract);
    }
};

const createOpeningHourTimeOptions = (options, intl: Intl) => [
    {
        value: OPEN_24_HOURS_VALUE,
        label: intl.msgJoint("msg: component.openingHours.open24Hours {Open 24 hours}")
    },
    ...createOptions(options).map(({ value, label }) => {
        let intlLabel;

        if (isInvalidOpeningHour(label as number)) {
            intlLabel = "";
        } else if (label === OPEN_24_HOURS_VALUE) {
            intlLabel = intl.msgJoint("msg: component.openingHours.open24Hours {Open 24 hours}");
        } else {
            intlLabel = intl.time(new Date(getMidnightToday() + (label as number)), {
                second: undefined
            });
        }

        return {
            value,
            label: intlLabel
        };
    })
];

export const createFromOpeningHourTimeOptions = (openingHoursForTheDay: SiteSettingsOpeningHoursMapValue, intl: Intl) => {
    const timeOptions = makeTimeOptionsWithCurrent(openingHoursForTheDay.from);
    return createOpeningHourTimeOptions(timeOptions, intl);
};

const makeToTimeOptions = (options: number[], from: number): number[] => {
    if (isInvalidOpeningHour(options[options.length - 1])) {
        return [...options.slice(options.indexOf(from) + 1, -1), ...options.slice(0, options.indexOf(from)), NaN];
    } else {
        return [...options.slice(options.indexOf(from) + 1), ...options.slice(0, options.indexOf(from))];
    }
};

export const createToOpeningHourTimeOptions = (openingHoursForTheDay: SiteSettingsOpeningHoursMapValue, intl: Intl) => {
    const timeOptions = makeTimeOptionsWithCurrent(openingHoursForTheDay.to);
    const sortedTimeOptions = makeToTimeOptions(timeOptions, openingHoursForTheDay.from);
    return createOpeningHourTimeOptions(sortedTimeOptions, intl);
};
export const areOpeningHoursValid = (openingHours: OpeningHoursMapType) => {
    return Object.keys(openingHours).every(day => {
        if (!openingHours[day]) return true;
        const { from, to } = openingHours[day];
        return !isInvalidOpeningHour(from) && !isInvalidOpeningHour(to);
    });
};
