/* eslint-disable max-len */

import * as R from "ramda";
import p from "../../../../utils/pipePath";
import * as mp from "../../../../mappers/path";
import {
    setBackgroundGradientThemeColor,
    setBackgroundGradientColor,
    setBackgroundOpacity,
    setBackgroundSolidThemeColor,
    setBackgroundSolidColor
} from "../../../../setters/backgroundSetter";
import { setGradientDirection, setGradientFadePoint } from "../../../../setters/gradientSetter";
import { getBackgroundOpacity, getBackgroundSolidColor, getBackgroundSolidThemeColor } from "../../../../getters/backgroundGetters";
import {
    getBackgroundGradientThemeColor,
    getBackgroundGradientColor,
    getGradientDirection,
    getGradientFadePoint
} from "../../../../getters/gradientGetter";
import {
    getBackgroundColor as getGSBackgroundColor,
    getBackgroundColorAlpha as getGSBackgroundColorAlpha,
    getBackgroundGradientColor as getGSBackgroundGradientColor,
    getBackgroundGradientDirection as getGSBackgroundGradientDirection,
    getBackgroundGradientFadePoint as getGSBackgroundGradientFadePoint
} from "../../../Workspace/epics/stylesheets/selectors";
import type { Color } from "../../../../mappers/flowTypes";
import type { TableComponent, TableComponentDependsOn } from "../flowTypes";
import { makeCellsUpdater } from "./makeCellsUpdater";
import type { ThemeColorTypes } from '../../../ThemeGlobalData/flowTypes';

const
    backgroundPath = p(mp.style, mp.blockBackground),
    backgroundGradientPath = p(backgroundPath, mp.colorData, mp.gradient),
    executeGsGetter = cell => {
        const color = R.path(p(mp.style, mp.blockBackgroundColor))(cell),
            gradient = R.path(p(mp.style, mp.blockBackgroundGradient))(cell);

        return R.isNil(color) && R.isNil(gradient);
    },
    propUpdatersMap = {
        solidColor: {
            getter: getBackgroundSolidColor(backgroundPath),
            executeGsGetter,
            gsGetter: getGSBackgroundColor([]),
            setter: setBackgroundSolidColor(backgroundPath)
        },
        solidThemeColor: {
            getter: getBackgroundSolidThemeColor(backgroundPath),
            executeGsGetter: R.F,
            gsGetter: R.always(null),
            setter: setBackgroundSolidThemeColor(backgroundPath)
        },
        opacity: {
            getter: getBackgroundOpacity(backgroundPath),
            gsGetter: getGSBackgroundColorAlpha([]),
            setter: setBackgroundOpacity(backgroundPath)
        },
        gradientColor: {
            getter: getBackgroundGradientColor(backgroundPath), // TODO rename getBackgroundGradientColor to getGradientColor
            executeGsGetter,
            gsGetter: getGSBackgroundGradientColor([]),
            setter: setBackgroundGradientColor(backgroundPath) // TODO rename setBackgroundGradientColor to setGradientColor
        },
        gradientThemeColor: {
            getter: getBackgroundGradientThemeColor(backgroundPath),
            executeGsGetter: R.F,
            gsGetter: R.always(null),
            setter: setBackgroundGradientThemeColor(backgroundPath)
        },
        gradientDirection: {
            getter: getGradientDirection(backgroundGradientPath),
            gsGetter: getGSBackgroundGradientDirection([]),
            setter: setGradientDirection(backgroundGradientPath)
        },
        gradientFadePoint: {
            getter: getGradientFadePoint(backgroundGradientPath),
            gsGetter: getGSBackgroundGradientFadePoint([]),
            setter: setGradientFadePoint(backgroundGradientPath)
        }
    };

type CellBackgroundPropToUpdate
    = { type: 'solidColor', value: null | undefined | Color }
    | { type: 'solidThemeColor', value: ThemeColorTypes | null }
    | { type: 'opacity', value: number }
    | { type: 'gradientColor', value: null | undefined | Color }
    | { type: 'gradientThemeColor', value: ThemeColorTypes | null }
    | { type: 'gradientDirection', value: number }
    | { type: 'gradientFadePoint', value: number };

const
    makeCellsBackgroundReducer =
        (propToUpdateMaker: (AnyAction) => CellBackgroundPropToUpdate) =>
            (component: TableComponent, action: AnyAction, dependencies: TableComponentDependsOn) =>
                makeCellsUpdater({
                    propUpdatersMap,
                    propToUpdate: propToUpdateMaker(action),
                    globalStyles: dependencies.stylesheets,
                    component,
                    selectedCellsIndexes: dependencies.tableEditModeState.selectedCellsIndexes
                });

export {
    makeCellsBackgroundReducer
};
