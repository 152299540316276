export const circlesConfig = {
        width: 99,
        height: 99,
        style: {
            border: {
                style: null,
                corners: [999, 999, 999, 999]
            },
            background: {
                colorData: {
                    color: null,
                    gradient: null
                },
                assetData: null
            }
        }
    },

    WHITE_CIRCLE = 'whiteCircle',
    DARK_CIRCLE = 'darkCircle',
    BORDER_CIRCLE = 'borderCircle',
    IMAGE_CIRCLE = 'imageCircle',

    circlesStylesMap = {
        [WHITE_CIRCLE]: { style: { background: { colorData: { color: ["HSL", 0.5645, 0, 1, 1] } } } },
        [DARK_CIRCLE]: { style: { background: { colorData: { color: ["HSL", 0.5645, 0, 0.2, 1] } } } },
        [BORDER_CIRCLE]: {
            style: {
                border: {
                    style: 'solid',
                    color: ["HSL", 0.3992, 0, 0.2, 0.77],
                    width: [1, 1, 1, 1],
                },
                background: { colorData: { color: null } }
            }
        },
        [IMAGE_CIRCLE]: {
            style: {
                background: {
                    assetData: {
                        asset: {
                            contentType: 'image/jpeg',
                            width: 3839,
                            height: 3838,
                            animated: false,
                            recommendedFormat: null,
                            url: 'repository:/circleBgImage.png'
                        },
                        repeat: [false, false],
                        position: ["50%", "100%"],
                        size: 'cover',
                        fixed: false,
                        opacity: 1
                    }
                }
            }
        }
    },
    circlesLabelMap = {
        [WHITE_CIRCLE]: 'msg: leftPanel.containers.circle.whiteCircle {White circle}',
        [DARK_CIRCLE]: 'msg: leftPanel.containers.circle.darkCircle {Dark circle}',
        [BORDER_CIRCLE]: 'msg: leftPanel.containers.circle.borderCircle {Circle with border}',
        [IMAGE_CIRCLE]: 'msg: leftPanel.containers.circle.imageCircle {Circle with image}',
    },
    circleTemplates = [WHITE_CIRCLE, DARK_CIRCLE, BORDER_CIRCLE, IMAGE_CIRCLE];
