import * as main from './pages/main';
import * as color from './pages/color';
import * as layout from './pages/layout';
import * as shape from './pages/shape';
import makePages from "../../../../PropertiesPanel/view/makePages";

const propertiesPanel = {
    pages: makePages(main, shape, color, layout),
    defaultPageId: main.id,
};

export { propertiesPanel };
