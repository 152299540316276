/* eslint-disable max-len */
import { STEP_NAMES, FIELD_NAMES } from "../constants";

const nextGenText = {
    dineIn: 'msg: nextGen.common.dineIn {Eat-in}',
    takeaway: 'msg: nextGen.common.takeaway {Takeaway}',
    delivery: 'msg: nextGen.common.delivery {Delivery}',
    eatIn: 'msg: nextGen.common.eatIn {Eat-in}',
    nonProfit: 'msg: nextGen.common.nonProfitOrCharity {Non-profit / Charity}',
    commercial: 'msg: nextGen.common.commercial {Commercial}',
    physicalStore: 'msg: nextGen.common.physicalPlace {Physical place}',
    onlineShop: 'msg: nextGen.common.onlineShop {Online shop}',
    offerPricing: 'msg: nextGen.common.offerPricing {Yes, show a price list}',
    getQuotation: 'msg: nextGen.common.getQuotation {No, but allow clients to contact me for price information}',
    free: 'msg: nextGen.common.free {Free access}',
    paid: 'msg: nextGen.common.paid {Paid access}',
    online: 'msg: nextGen.common.online {Online}',
    offline: 'msg: nextGen.common.offline {Offline}'
};

const optionSetDescText = {
    pricing: {
        title: "msg: onboarding.dynamic.step1.pricing.title {Do you want to show a price list?}",
        description: "msg: onboarding.dynamic.step1.pricing.description {Choose how you want to inform website visitors about your prices.}"
    },
    organisation: {
        title: "msg: onboarding.dynamic.step1.organization.title {What type of organisation are you?}",
        // eslint-disable-next-line max-len
        description: "msg: onboarding.dynamic.step1.organization.description {Pick the organisation type that fits best.}",
    },
    business: {
        title: "msg: onboarding.dynamic.step1.business.title {What type of business are you?}",
        description: "msg: onboarding.dynamic.step1.business.description {Choose whether your business is physical, online or both.}",
    },
    event: {
        title: "msg: onboarding.dynamic.step1.event.title {Is your event free or paid?}",
        description: "msg: onboarding.dynamic.step1.event.description {Choose which information you want to show on your website.}",
    },
    shop: {
        title: "msg: onboarding.dynamic.step1.shop.title {What type of business are you?}",
        description: "msg: onboarding.dynamic.step1.shop.description {Choose whether your business is physical, online or both.}",
    },
    restaurants: {
        title: "msg: onboarding.dynamic.step1.cafeOrRestaurants.title {Which services do you offer?}",
        description: "msg: onboarding.dynamic.step1.cafeOrRestaurants.description {Pick one or more services that your business provides.}",
    },
    onlineOffline: {
        title: "msg: onboarding.dynamic.step1.onlineOffline.title {Is your business online or offline?}",
        description: "msg: onboarding.dynamic.step1.onlineOffline.description {Select if your business works online, offline or both.}",
    },
    travelService: {
        title: "msg: onboarding.dynamic.step1.business.title {What type of business are you?}",
        description: "msg: onboarding.dynamic.step1.business.description {Choose whether your business is physical, online or both.}",
    },
    default: {
        title: "msg: onboarding.dynamic.step1.service.title {Which services do you offer?}",
        description: "msg: onboarding.dynamic.step1.service.description {Pick one or more services that your restaurant provides.}"
    }
};

const shopBusinessName = "msg: onboarding.dynamic.step2.shops.placeHolder {e.g. Green Products}";
const shopAboutKeyword = "msg: onboarding.dynamic.step3.shops.placeHolder {e.g. sustainable products, delivery within 3 working days, on a mission to reduce waste.}";

const onboardingStepsText = {
    [STEP_NAMES.BUSINESS_NAME]: {
        [FIELD_NAMES.TITLE]: {
            project: "msg: onboarding.dynamic.step2.project.title {What’s the name of your project?}",
            organisation: "msg: onboarding.dynamic.step2.organisation.title {What’s the name of your organisation?}",
            event: "msg: onboarding.dynamic.step2.event.title {What’s the name of your event?}",
            publicAreas: "msg: onboarding.dynamic.step2.publicAreas.title {What is the name of your website?}",
            cv: "msg: onboarding.dynamic.step2.cv.title {What’s your name?}",
            default: "msg: onboarding.dynamic.step2.business.title {What’s the name of your business?}",
        },
        [FIELD_NAMES.DESC]: {
            project: "msg: onboarding.dynamic.step2.project.description {This is how your event or project will be called across your website texts.}",
            organisation: "msg: onboarding.dynamic.step2.organisation.description {This is how your organisation will be called across your website texts.}",
            event: "msg: onboarding.dynamic.step2.event.description {This is how your event will be called across your website texts.}",
            cv: "msg: onboarding.dynamic.step2.cv.description {Your name will be used across your website texts.}",
            default: "msg: onboarding.dynamic.step2.business.description {This name will be used in the texts on your site}",
        },
        [FIELD_NAMES.PLACEHOLDER]: {
            project: "msg: onboarding.dynamic.step2.project.placeHolder {e.g. Kim’s CV}",
            organisation: "msg: onboarding.dynamic.step2.organisation.placeHolder {e.g. Children\'s foundation}",
            event: "msg: onboarding.dynamic.step2.event.placeHolder {e.g. Kim’s birthday}",
            businessProf: "msg: onboarding.dynamic.step2.businessProf.placeHolder {e.g. Darby & Hartman’s}",
            serviceProf: "msg: onboarding.dynamic.step2.serviceProf.placeHolder {e.g. London helpers}",
            creativeProf: "msg: onboarding.dynamic.step2.creativeProf.placeHolder {e.g. Berlin studios}",
            cv: "msg: onboarding.dynamic.step2.cv.placeHolder {e.g. Max Miller}",
            shops: shopBusinessName,
            onlineShopCatchall: shopBusinessName,
            physicalStoreCatchall: shopBusinessName,
            commercialRecreation: "msg: onboarding.dynamic.step2.commercialRecreation.placeHolder {e.g. Bluewave Waterpark}",
            publicAreas: "msg: onboarding.dynamic.step2.publicAreas.placeHolder {e.g. Oakwood Community Park}",
            default: "msg: onboarding.dynamic.step2.business.placeHolder {e.g. The Corner Café}",
        }
    },
    [STEP_NAMES.ABOUT_KEYWORDS]: {
        [FIELD_NAMES.TITLE]: {
            project: "msg: onboarding.dynamic.step3.project.title {Tell us about yourself or your project}",
            event: "msg: onboarding.dynamic.step3.event.title {Tell us about your event}",
            publicAreas: "msg: onboarding.dynamic.step3.publicAreas.title {Tell us about your venue}",
            cv: "msg: onboarding.dynamic.step3.cv.title {Tell us about yourself}",
            default: "msg: onboarding.dynamic.step3.business.title {Tell us about your business}"
        },
        [FIELD_NAMES.PLACEHOLDER]: {
            project: "msg: onboarding.dynamic.step3.project.placeHolder {e.g. 10 years experience, interested in sustainability, self-taught.}",
            organisation: "msg: onboarding.dynamic.step3.organisation.placeHolder {e.g. founded in 2003, global cooperation partners, 500 dedicated volunteers.}",
            event: "msg: onboarding.dynamic.step3.event.placeHolder {e.g. for friends and family, dinner and drinks included, casual setting.}",
            businessProf: "msg: onboarding.dynamic.step3.businessProf.placeHolder {e.g. founded in 2003, family business, offices in 5 cities.}",
            serviceProf: "msg: onboarding.dynamic.step3.serviceProf.placeHolder {e.g. founded in 2003, working in West London, 24-hour emergency service.}",
            creativeProf: "msg: onboarding.dynamic.step3.creativeProf.placeHolder {e.g. 10 years of experience, recipient of “Best of 2020” award, customer support is key.}",
            shops: shopAboutKeyword,
            onlineShopCatchall: shopAboutKeyword,
            physicalStoreCatchall: shopAboutKeyword,
            commercialRecreation: "msg: onboarding.dynamic.step3.commercialRecreation.placeHolder {e.g. largest waterpark in Europe, 20 slides, 10 pools.}",
            publicAreas: "msg: onboarding.dynamic.step3.publicAreas.placeHolder {e.g. holding events every Friday, free parking, playgrounds}",
            default: "msg: onboarding.dynamic.step3.business.placeHolder {e.g. founded in 2003, family business, focus on sustainability.}",
        }
    }
};

export {
    nextGenText,
    optionSetDescText,
    onboardingStepsText
};
