import DataPage from "../DataPage";
import { makeUuid } from "../../../utils/makeUuid";
import { isObject } from "../../../utils/object";
import { isString } from "../../../utils/string";

const alterItemIds = (val: any, map: MapT<string>): Record<string, any> => {
    let nextVal;

    if (isObject(val)) {
        nextVal = Object
            .keys(val)
            .reduce((acc, k) => {
                const nextK = map[k] ? map[k] : k;
                return { ...acc, [nextK]: alterItemIds(val[k], map) };
            }, {});
    } else if (Array.isArray(val)) {
        nextVal = val.map(v => alterItemIds(v, map));
    } else if (isString(val)) {
        if (map[val]) {
            nextVal = map[val];
        } else {
            nextVal = Object.keys(map).reduce((val, k) => val.replace(k, map[k]), val);
        }
    } else {
        nextVal = val;
    }

    return nextVal;
};

export type DataPageCopy = {
    copy: DataPage,
    srcId: string,
    itemIds: MapT<string>,
};

type Patch = {
    name?: string,
};

export const copyPage = (src: DataPage, patch?: Patch): DataPageCopy => {
    const
        itemIds = src.items.reduce((acc, itm) => Object.assign(acc, { [itm.id]: makeUuid() }), {}),
        copy = new DataPage({
            ...alterItemIds(src, itemIds),
            ...patch,
            id: makeUuid(),
        });

    return { copy, srcId: src.id, itemIds };
};
