import React from "react";
import { connect } from "react-redux";
import { ALL_CONTAINERS_FILTER_KEY, ContainersExtendedPanelViews } from "./constants";
import VerticalSpacer from "../../../../view/common/VerticalSpacer";
import { EPSeparator } from "../EPSeparator";
import { makeEpicStateSelector } from "../../../../epics/makeEpic";
import CONTAINERS_FILTER_VALUE_ACTION_TYPE from "../../epics/containers/valueActionType";

const Content = ({ intl, dispatch, filterKey }) => {
    const viewKeys = Object.keys(ContainersExtendedPanelViews)
        .filter(k => filterKey === ALL_CONTAINERS_FILTER_KEY || filterKey === k);
    return (
        <div>
            <VerticalSpacer y={27} />
            {
                viewKeys.map((k, index) => {
                    const
                        View = ContainersExtendedPanelViews[k],
                        lastEntry = index === viewKeys.length - 1;

                    return (
                        <React.Fragment key={k}>
                            <View intl={intl} dispatch={dispatch} />
                            {!lastEntry && <EPSeparator />}
                            <VerticalSpacer y={27} />
                        </React.Fragment>
                    );
                })
            }
        </div>
    );
};

const mapStateToProps = (appState) => ({
    filterKey: (makeEpicStateSelector(CONTAINERS_FILTER_VALUE_ACTION_TYPE)(appState)).filterKey
});

export const ContainerExtendedPanelContentView = connect(mapStateToProps)(Content);
