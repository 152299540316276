import React from 'react';
import HorizontalSpacer from '../../../../view/common/HorizontalSpacer';
import { MctaVerticalBar, MctaCheckbox } from '../../../componentMainActions/index';
import { ctaOnClickFactory, linkExtractor } from '../utils';
import * as actions from './actions';

const EditView = props => {
    const { selectedComponent: { autoplay, loop, showTextLink }, children, dispatch } = props;
    return (
        <div>
            <HorizontalSpacer x={5} />
            <MctaCheckbox
                isChecked={autoplay}
                label="msg: component.vimeo.pp.special.autoplay {Autoplay}"
                onClick={() => dispatch({ type: actions.WIDGETS_VIMEO_TOGGLE_AUTOPLAY })}
            />
            <HorizontalSpacer x={12} />
            <MctaVerticalBar />
            <HorizontalSpacer x={12} />
            <MctaCheckbox
                isChecked={loop}
                label="msg: component.vimeo.pp.special.loop {Loop this video}"
                onClick={() => dispatch({ type: actions.WIDGETS_VIMEO_TOGGLE_LOOP })}
            />
            <HorizontalSpacer x={12} />
            <MctaVerticalBar />
            <HorizontalSpacer x={12} />
            <MctaCheckbox
                isChecked={showTextLink}
                label="msg: component.vimeo.pp.special.showLink {Show link}"
                onClick={() => dispatch({ type: actions.WIDGETS_VIMEO_TOGGLE_SHOW_TEXT_LINK })}
            />
            {children}
        </div>
    );
};

const componentMainActions = {
    editView: EditView,
    editButtonText: 'msg: common.settings {Settings}',
    ctaButtonText: 'msg: widgets.vimeo.cta.changeVideo {Change video}',
    ctaOnClick: ctaOnClickFactory(
        actions.WIDGETS_VIMEO_SHOW_UPDATE_LINK_DIALOG,
        component => ({ link: linkExtractor(component) })
    ),
};

export { componentMainActions };

