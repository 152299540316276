import backgroundReducerFactory from "../../Background/reducer/backgroundReducerFactory";
import * as ActionTypes from "../actionTypes";
import p from "../../../../utils/pipePath";
import * as mp from "../../../../mappers/path";
import { setToPath } from "../../../../utils/ramdaEx";
import { BACKGROUND_CMP_DEFAULT_COLOR } from "../../Background/constants";
import kind from '../kind';
import { HoverEffect } from "../constants";

const
    solidColorPath = p(mp.styleBackground, mp.colorData, mp.color),
    defaultState = {
        ...setToPath(
            solidColorPath,
            BACKGROUND_CMP_DEFAULT_COLOR,
            null
        ),
        hover: {
            animation: HoverEffect.instant,
        },
        mobileHide: false,
        selectedGradientTheme: null
    },
    hoverBoxHandlers = {
        [ActionTypes.SET_HOVER_BOX_EFFECT]: (state, action) => {
            const { payload: hoverEffect } = action;
            return {
                ...state,
                hover: {
                    ...state.hover,
                    animation: hoverEffect
                }
            };
        },
        [ActionTypes.SET_HOVER_BOX_MODE]: (state, action) => {
            const { payload: show } = action;
            return {
                ...state,
                hoverMode: show
            };
        }
    };

export default backgroundReducerFactory(kind, defaultState, undefined, hoverBoxHandlers);
