import * as R from 'ramda';
import { cartGlobalDataVat } from './valueActionType';
import makeEpic, { fullEpicUndoablePath } from "../../../../../epics/makeEpic";
import type { CartDataProps } from "../../flowTypes";
import actionTypes from '../../reducer/actionTypes';
import updateReasons from './updateReasons';
import { whenWithSelector, receiveOnly } from "../../../../../epics/makeCondition";
import { ComponentsEvalValueActionType } from "../../../../Workspace/epics/componentsEval/valueActionType";
import { findWebshopComponent } from "../../utils";
import {
    isTransient as isTransientUserInteractionMode
} from "../../../../Workspace/epics/componentsEval/userInteractionMutations/interactionModes";
import { arrayToTrueMap } from "../../../../../utils/arrayToTrueMap";
import { toHex } from "../../../../../mappers/color";
import { getParentThemeMap } from "../../../../ThemeGlobalData/utils/getParentThemeMap";
import templateVAT from "../../../Template/epics/template/valueActionType";
import { colorThemeSiteSettingsEpic } from "../../../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import { getThemeRulesForWebshop } from "../../../../ThemeGlobalData/themeRules";
import stylesheetVAT from "../../../../Workspace/epics/stylesheets/valueActionType";
import { getThemeColorsFromStylesheet } from "../../../../Workspace/epics/stylesheets/selectors";
import { BLACK_THEME_COLOR, WHITE_THEME_COLOR } from "../../../../ThemeGlobalData/constants";
import { findFeaturedProductComponent } from "../../../FeaturedProducts/utils";

export const
    cartDataDefaultState: CartDataProps = {
        focusColor: ["HSL", 0.5680555555555555, 0.6249999999999999, 0.6235294117647059, 1],
        focusColorTheme: BLACK_THEME_COLOR,
        isCartWebshopPageOnly: false,
        overlayPosition: 25,
        showMobileCart: true,
        mobileBackgroundColor: ["HSL", 0.568055555555555, 0, 1, 1],
        mobileBackgroundColorTheme: WHITE_THEME_COLOR,
        /*
         * This is needed to handle case when default background is applied to webshop and cart do not know what is
         * the background of webshop. So we are saving that data in cart always.
         */
        mobileBackgroundColorThemeDefault: WHITE_THEME_COLOR
    };

const
    NonTransientComponentsMapSelector = whenWithSelector(
        ComponentsEvalValueActionType,
        a => a.payload.state.componentsMap,
        a => !isTransientUserInteractionMode(a.payload.scope.userInteraction.mode)
    ),
    notUndoableReasonsMap = arrayToTrueMap([
        updateReasons.WEBSHOP_CART_FOCUS_COLOUR
    ]);

export default makeEpic({
    defaultState: cartDataDefaultState,
    valueActionType: cartGlobalDataVat,
    saveIntoSiteSettings: { key: 'cartData' },
    undo: {
        isUndoableChange: ({ updateReason }) => !notUndoableReasonsMap[updateReason],
        undoablePaths: fullEpicUndoablePath
    },
    updaters: [
        {
            conditions: [actionTypes.WEBSHOP_CART_POSITION_CHANGED],
            reducer: ({ values: [isCartWebshopPageOnly], state }) => {
                if (state.isCartWebshopPageOnly === isCartWebshopPageOnly) {
                    return { state };
                }

                return {
                    state: R.assoc('isCartWebshopPageOnly', isCartWebshopPageOnly)(state),
                    updateReason: updateReasons.WEBSHOP_CART_POSITION_CHANGED
                };
            }
        },
        {
            conditions: [actionTypes.WEBSHOP_CART_VERTICAL_POSITION],
            reducer: ({ values: [overlayPosition], state }) => {
                return {
                    state: R.assoc('overlayPosition', overlayPosition)(state),
                    updateReason: updateReasons.WEBSHOP_CART_VERTICAL_POSITION
                };
            }
        },
        {
            conditions: [actionTypes.WEBSHOP_CART_SHOW_MOBILE],
            reducer: ({ values: [showMobileCart], state }) => {
                return {
                    state: R.assoc('showMobileCart', showMobileCart)(state),
                    updateReason: updateReasons.WEBSHOP_CART_SHOW_MOBILE
                };
            }
        },
        {
            conditions: [actionTypes.WEBSHOP_CART_MOBILE_BACKGROUND_COLOUR],
            reducer: ({ values: [{ color }], state }) => {
                return {
                    state: R.assoc('mobileBackgroundColor', color)(state),
                    updateReason: updateReasons.WEBSHOP_CART_MOBILE_BACKGROUND_COLOUR
                };
            }
        },
        {
            conditions: [actionTypes.WEBSHOP_CART_MOBILE_BACKGROUND_COLOUR_AUTO_COLOR],
            reducer: ({ values: [{ themeColor }], state }) => {
                return {
                    state: R.assoc('mobileBackgroundColorTheme', themeColor)(state),
                    updateReason: updateReasons.WEBSHOP_CART_MOBILE_BACKGROUND_COLOUR_AUTO_COLOR
                };
            }
        },
        {
            keepFullActions: true,
            conditions: [
                NonTransientComponentsMapSelector,
                receiveOnly(templateVAT),
                receiveOnly(colorThemeSiteSettingsEpic.valueActionType),
                receiveOnly(stylesheetVAT)
            ],
            reducer: ({ values: [
                componentsMap,
                { payload: { selectedTheme } },
                { payload: { autoColorMode } },
                { payload: stylesheets },
            ],
            state }) => {
                const webshopComponent = findWebshopComponent(componentsMap);
                const featuredProductComponent = findFeaturedProductComponent(componentsMap);
                if (!R.isEmpty(webshopComponent) || !R.isEmpty(featuredProductComponent)) {
                    const
                        component = (!R.isEmpty(webshopComponent)) ? webshopComponent[Object.keys(webshopComponent)[0]]
                            : featuredProductComponent[Object.keys(featuredProductComponent)[0]],
                        id = component.id,
                        focusColorFromWebshop = component.focusColor;
                    let
                        focusColorThemeFromWebshop = component.focusColorTheme,
                        mobileBackgroundColorTheme = state.mobileBackgroundColorTheme;
                    if ((!focusColorThemeFromWebshop || !mobileBackgroundColorTheme) && autoColorMode && selectedTheme) {
                        const
                            parentThemeMap = getParentThemeMap(componentsMap, selectedTheme),
                            selectedParentTheme = parentThemeMap[id],
                            themeColorsData = getThemeColorsFromStylesheet(stylesheets),
                            { focusColorName, mobileBackgroundColorName } =
                                getThemeRulesForWebshop(selectedParentTheme, themeColorsData);
                        focusColorThemeFromWebshop = focusColorThemeFromWebshop || focusColorName;
                        mobileBackgroundColorTheme = mobileBackgroundColorTheme || mobileBackgroundColorName;
                    }
                    if (
                        toHex(state.focusColor) !== toHex(focusColorFromWebshop) ||
                        focusColorThemeFromWebshop !== state.focusColorTheme ||
                        mobileBackgroundColorTheme !== state.mobileBackgroundColorTheme
                    ) {
                        const focusColor = R.clone(focusColorFromWebshop);
                        return {
                            state: {
                                ...state,
                                focusColor,
                                focusColorTheme: focusColorThemeFromWebshop,
                                mobileBackgroundColorTheme,
                                mobileBackgroundColorThemeDefault: mobileBackgroundColorTheme
                            },
                            updateReason: updateReasons.WEBSHOP_CART_FOCUS_COLOUR
                        };
                    }
                }
                return { state };
            }
        }
    ]
});
