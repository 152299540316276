import { makeInputReducer } from "./index";
import urlInputValidation from "../commonValidations/urlInputValidation";
import type { InputReducerFactoryArgs } from "../flowTypes";

export default ({ actionType, defaultState }: InputReducerFactoryArgs) =>
    makeInputReducer({
        actionType,
        validations: [{ validation: urlInputValidation, options: { omitSchema: true } }],
        defaultState,
    });
