import React from "react";
import FourFieldCornersConfigurationDialogId from '../FourFields/FourFieldCorners/configurationDialogId';
import TextIconButton from '../TextIconButton/factory';
import type { CornersButton } from "./flowTypes";

export default ({ corners, cornerChangeAction, dispatch }: CornersButton) => (
    <TextIconButton
        text="msg: common.corners {Corners}"
        dialogId={FourFieldCornersConfigurationDialogId}
        dialogProps={{ values: corners, onChangeAction: cornerChangeAction }}
        dispatch={dispatch}
    />
);
