import { COMPONENTS_ADD_COMPONENT } from "../actionTypes";
import type { AnyComponentKind } from "../../../../../redux/modules/children/workspace/flowTypes";
import { COMPONENT_CONFIGURATION_COMPLETE } from "../../../../../redux/modules/children/workspace/actionTypes";

type AddComponentParams = {
    kind: AnyComponentKind,
    left: number,
    top: number,
    topmostHandleKind?: string,
    sectionOrBlockId?: string
};

type AddComponentDDParams = {
    kind: AnyComponentKind,
    asset?: Object
};
export const addComponentAction = (params: AddComponentParams) => ({
    type: COMPONENTS_ADD_COMPONENT,
    payload: params
});

export const addComponentDDAction = (params: AddComponentDDParams) => ({
    type: COMPONENT_CONFIGURATION_COMPLETE,
    payload: params
});
