import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";

import { useIntl } from "../../../../../../wbtgen/src/view/intl";
import { publishButtonClickedAction } from "../../../../../../wbtgen/src/components/TopBar/actionTypes";
import { checkPublishDone, checkPublishInProgress } from "../../../../../../wbtgen/src/components/TopBar/epics/checkPublishStatus/utils";

import { Tooltip } from "../../Tooltip";
import { useStyles } from "./styles";
import {
    hasNoPublishablePages as hasNoPublishablePagesSelector,
    selectPublishCurrentProgress,
    selectPublishStatus
} from "../../../common/selectors";

type PublishButtonProps = {
    classes?: string;
    variant?: "outlined" | "contained";
} & Record<string, any>;

const publishText = (publishStatus: string) => {
    if (checkPublishInProgress(publishStatus)) {
        return "msg: publish.publishing {Publishing...}";
    } else if (checkPublishDone(publishStatus)) {
        return "msg: publish.published {Published}";
    } else {
        return "msg: publish.publishText {Publish}";
    }
};

const PublishButtonComponent = React.forwardRef(
    // props can also be forwarded from Tooltip (mouse events) component used below
    ({ classes: classNames, variant = "outlined", ...forwardedProps }: PublishButtonProps, ref: React.Ref<any>) => {
        const intl = useIntl();
        const dispatch = useDispatch();

        const classes = useStyles();

        const hasNoPublishablePages = useSelector(hasNoPublishablePagesSelector);
        const publishStatus = useSelector(selectPublishStatus);
        const publishProgress = useSelector(selectPublishCurrentProgress);

        const publishInProgress = checkPublishInProgress(publishStatus),
            publishIsDone = checkPublishDone(publishStatus),
            disablePublishButton = hasNoPublishablePages || publishInProgress || publishIsDone;

        const onPublishClickHandler = () => {
            if (forwardedProps?.onClick) {
                forwardedProps.onClick();
            }

            if (!disablePublishButton) {
                dispatch(publishButtonClickedAction());
            }
        };

        return (
            <Button
                {...forwardedProps}
                ref={ref}
                size="medium"
                variant={publishInProgress ? "outlined" : variant}
                disabled={disablePublishButton}
                className={classNames}
                sx={{ overflow: "hidden" }}
                onClick={onPublishClickHandler}
                data-testid="publish-button"
            >
                {publishInProgress && (
                    <Box
                        width={`${publishProgress}%`}
                        className={classes.publishProgress}
                        style={{ transition: "width 300ms ease-out" }}
                        role="progressbar"
                    />
                )}
                <Box className={classes.publishText}>{intl.msgJoint(publishText(publishStatus))}</Box>
            </Button>
        );
    }
);

export const PublishButton = (props: PublishButtonProps) => {
    const intl = useIntl();
    const theme = useTheme();

    const hasNoPublishablePages = useSelector(hasNoPublishablePagesSelector);
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

    if (hasNoPublishablePages) {
        return (
            <Tooltip
                arrow
                open={tooltipOpen}
                background={theme.palette.custom.colorGray_55}
                title={
                    <Box
                        padding="2px"
                        paddingBottom="4px"
                        position="relative"
                        fontSize="12px"
                        lineHeight="15px"
                        fontWeight={400}
                        style={{ pointerEvents: "auto", userSelect: "none" }}
                    >
                        {intl.msgJoint(
                            "msg: publish.noPagesMarkedForPublish {All pages are marked as 'Don't publish this page'. Please ensure that at least one page is enabled to publish and try again.}"
                        )}
                    </Box>
                }
            >
                <Box
                    component="span"
                    onClick={() => setTooltipOpen(!tooltipOpen)}
                    onMouseEnter={() => setTooltipOpen(true)}
                    onMouseLeave={() => setTooltipOpen(false)}
                    display="inline-flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="stretch"
                >
                    <PublishButtonComponent {...props} />
                </Box>
            </Tooltip>
        );
    }

    return <PublishButtonComponent {...props} />;
};
