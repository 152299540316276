export default {
    no: '@no',
    move: 'move',
    rowResize: 'row-resize',
    nsResize: 'ns-resize',
    ewResize: 'ew-resize',
    neswResize: 'nesw-resize',
    nwseResize: 'nwse-resize',
    default: 'default',
    grabbing: 'grabbing',
    pointer: 'pointer',
    none: 'none'
};
