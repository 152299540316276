export const
    GS_MENU_GLOBAL_STYLE_SELECTED = 'GS_MENU_GLOBAL_STYLE_SELECTED',

    GS_MENU_FONT_FAMILY_CHANGED = 'GS_MENU_FONT_FAMILY_CHANGED',
    GS_MENU_FONT_SIZE_CHANGED = 'GS_MENU_FONT_SIZE_CHANGED',

    GS_MENU_TOGGLE_BOLD = 'GS_MENU_TOGGLE_BOLD',
    GS_MENU_TOGGLE_ITALIC = 'GS_MENU_TOGGLE_ITALIC',

    GS_MENU_TEXT_ALIGN_CHANGED = 'GS_MENU_TEXT_ALIGN_CHANGED',

    GS_MENU_TEXT_TRANSFORM_LOWERCASE = 'GS_MENU_TEXT_TRANSFORM_LOWERCASE',
    GS_MENU_TEXT_TRANSFORM_UPPERCASE = 'GS_MENU_TEXT_TRANSFORM_UPPERCASE',

    GS_MENU_BORDER_COLOR_CHANGED = 'GS_MENU_BORDER_COLOR_CHANGED',
    GS_MENU_BORDER_COLOR_OPACITY_CHANGED = 'GS_MENU_BORDER_COLOR_OPACITY_CHANGED',
    GS_MENU_BORDER_COLOR_REMOVED = 'GS_MENU_BORDER_COLOR_REMOVED',
    GS_MENU_BORDER_STYLE_CHANGED = 'GS_MENU_BORDER_STYLE_CHANGED',
    GS_MENU_BORDER_WIDTH_CHANGED = 'GS_MENU_BORDER_WIDTH_CHANGED',

    GS_MENU_CELL_SPACING_CHANGED = 'GS_MENU_CELL_SPACING_CHANGED',

    /* Normal settings */
    GS_MENU_NORMAL_TOGGLE_UNDERLINE = 'GS_MENU_NORMAL_TOGGLE_UNDERLINE',

    GS_MENU_NORMAL_TEXT_COLOR_CHANGED = 'GS_MENU_NORMAL_TEXT_COLOR_CHANGED',
    GS_MENU_NORMAL_TEXT_COLOR_OPACITY_CHANGED = 'GS_MENU_NORMAL_TEXT_COLOR_OPACITY_CHANGED',
    GS_MENU_NORMAL_TEXT_COLOR_REMOVED = 'GS_MENU_NORMAL_TEXT_COLOR_REMOVED',

    GS_MENU_NORMAL_TEXT_SHADOW_COLOR_CHANGED = 'GS_MENU_NORMAL_TEXT_SHADOW_COLOR_CHANGED',
    GS_MENU_NORMAL_TEXT_SHADOW_COLOR_OPACITY_CHANGED = 'GS_MENU_NORMAL_TEXT_SHADOW_COLOR_OPACITY_CHANGED',
    GS_MENU_NORMAL_TEXT_SHADOW_COLOR_REMOVED = 'GS_MENU_NORMAL_TEXT_SHADOW_COLOR_REMOVED',
    GS_MENU_NORMAL_TEXT_SHADOW_BLUR_RADIUS_CHANGED = 'GS_MENU_NORMAL_TEXT_SHADOW_BLUR_RADIUS_CHANGED',
    GS_MENU_NORMAL_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED = 'GS_MENU_NORMAL_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED',
    GS_MENU_NORMAL_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED = 'GS_MENU_NORMAL_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED',

    GS_MENU_NORMAL_BG_COLOR_CHANGED = 'GS_MENU_NORMAL_BG_COLOR_CHANGED',
    GS_MENU_NORMAL_BG_COLOR_OPACITY_CHANGED = 'GS_MENU_NORMAL_BG_COLOR_OPACITY_CHANGED',
    GS_MENU_NORMAL_BG_COLOR_REMOVED = 'GS_MENU_NORMAL_BG_COLOR_REMOVED',

    GS_MENU_NORMAL_BG_GRADIENT_CHANGED = 'GS_MENU_NORMAL_BG_GRADIENT_CHANGED',
    GS_MENU_NORMAL_BG_GRADIENT_OPACITY_CHANGED = 'GS_MENU_NORMAL_BG_GRADIENT_OPACITY_CHANGED',
    GS_MENU_NORMAL_BG_GRADIENT_CANCELLED = 'GS_MENU_NORMAL_BG_GRADIENT_CANCELLED',
    GS_MENU_NORMAL_BG_GRADIENT_DIRECTION_CHANGED = 'GS_MENU_NORMAL_BG_GRADIENT_DIRECTION_CHANGED',
    GS_MENU_NORMAL_BG_GRADIENT_FADEPOINT_CHANGED = 'GS_MENU_NORMAL_BG_GRADIENT_FADEPOINT_CHANGED',

    GS_MENU_NORMAL_BG_ASSET_CHANGED = 'GS_MENU_NORMAL_BG_ASSET_CHANGED',
    GS_MENU_NORMAL_BG_ASSET_REMOVED = 'GS_MENU_NORMAL_BG_ASSET_REMOVED',
    GS_MENU_NORMAL_BG_ASSET_POSITION_CHANGED = 'GS_MENU_NORMAL_BG_ASSET_POSITION_CHANGED',
    GS_MENU_NORMAL_BG_ASSET_REPEAT_CHANGED = 'GS_MENU_NORMAL_BG_ASSET_REPEAT_CHANGED',
    GS_MENU_NORMAL_BG_ASSET_SIZE_CHANGED = 'GS_MENU_NORMAL_BG_ASSET_SIZE_CHANGED',

    GS_MENU_NORMAL_BORDER_RADUIS_CHANGED = 'GS_MENU_NORMAL_BORDER_RADUIS_CHANGED',

    /* Mouse over settings */
    GS_MENU_MOUSE_OVER_TOGGLE_UNDERLINE = 'GS_MENU_MOUSE_OVER_TOGGLE_UNDERLINE',
    GS_MENU_MOUSE_OVER_TEXT_COLOR_CHANGED = 'GS_MENU_MOUSE_OVER_TEXT_COLOR_CHANGED',
    GS_MENU_MOUSE_OVER_TEXT_COLOR_OPACITY_CHANGED = 'GS_MENU_MOUSE_OVER_TEXT_COLOR_OPACITY_CHANGED',
    GS_MENU_MOUSE_OVER_TEXT_COLOR_REMOVED = 'GS_MENU_MOUSE_OVER_TEXT_COLOR_REMOVED',

    GS_MENU_MOUSE_OVER_TEXT_SHADOW_COLOR_CHANGED = 'GS_MENU_MOUSE_OVER_TEXT_SHADOW_COLOR_CHANGED',
    GS_MENU_MOUSE_OVER_TEXT_SHADOW_COLOR_OPACITY_CHANGED = 'GS_MENU_MOUSE_OVER_TEXT_SHADOW_COLOR_OPACITY_CHANGED',
    GS_MENU_MOUSE_OVER_TEXT_SHADOW_COLOR_REMOVED = 'GS_MENU_MOUSE_OVER_TEXT_SHADOW_COLOR_REMOVED',
    GS_MENU_MOUSE_OVER_TEXT_SHADOW_BLUR_RADIUS_CHANGED = 'GS_MENU_MOUSE_OVER_TEXT_SHADOW_BLUR_RADIUS_CHANGED',
    GS_MENU_MOUSE_OVER_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED =
        'GS_MENU_MOUSE_OVER_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED',
    GS_MENU_MOUSE_OVER_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED = 'GS_MENU_MOUSE_OVER_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED',

    GS_MENU_MOUSE_OVER_BG_COLOR_CHANGED = 'GS_MENU_MOUSE_OVER_BG_COLOR_CHANGED',
    GS_MENU_MOUSE_OVER_BG_COLOR_OPACITY_CHANGED = 'GS_MENU_MOUSE_OVER_BG_COLOR_OPACITY_CHANGED',
    GS_MENU_MOUSE_OVER_BG_COLOR_REMOVED = 'GS_MENU_MOUSE_OVER_BG_COLOR_REMOVED',

    GS_MENU_MOUSE_OVER_BG_GRADIENT_CHANGED = 'GS_MENU_MOUSE_OVER_BG_GRADIENT_CHANGED',
    GS_MENU_MOUSE_OVER_BG_GRADIENT_OPACITY_CHANGED = 'GS_MENU_MOUSE_OVER_BG_GRADIENT_OPACITY_CHANGED',
    GS_MENU_MOUSE_OVER_BG_GRADIENT_CANCELLED = 'GS_MENU_MOUSE_OVER_BG_GRADIENT_CANCELLED',
    GS_MENU_MOUSE_OVER_BG_GRADIENT_DIRECTION_CHANGED = 'GS_MENU_MOUSE_OVER_BG_GRADIENT_DIRECTION_CHANGED',
    GS_MENU_MOUSE_OVER_BG_GRADIENT_FADEPOINT_CHANGED = 'GS_MENU_MOUSE_OVER_BG_GRADIENT_FADEPOINT_CHANGED',

    GS_MENU_MOUSE_OVER_BG_ASSET_CHANGED = 'GS_MENU_MOUSE_OVER_BG_ASSET_CHANGED',
    GS_MENU_MOUSE_OVER_BG_ASSET_REMOVED = 'GS_MENU_MOUSE_OVER_BG_ASSET_REMOVED',
    GS_MENU_MOUSE_OVER_BG_ASSET_POSITION_CHANGED = 'GS_MENU_MOUSE_OVER_BG_ASSET_POSITION_CHANGED',
    GS_MENU_MOUSE_OVER_BG_ASSET_REPEAT_CHANGED = 'GS_MENU_MOUSE_OVER_BG_ASSET_REPEAT_CHANGED',
    GS_MENU_MOUSE_OVER_BG_ASSET_SIZE_CHANGED = 'GS_MENU_MOUSE_OVER_BG_ASSET_SIZE_CHANGED',

    GS_MENU_MOUSE_OVER_BORDER_RADUIS_CHANGED = 'GS_MENU_MOUSE_OVER_BORDER_RADUIS_CHANGED',

    /* Selected settings */
    GS_MENU_SELECTED_TOGGLE_UNDERLINE = 'GS_MENU_SELECTED_TOGGLE_UNDERLINE',
    GS_MENU_SELECTED_TEXT_COLOR_CHANGED = 'GS_MENU_SELECTED_TEXT_COLOR_CHANGED',
    GS_MENU_SELECTED_TEXT_COLOR_OPACITY_CHANGED = 'GS_MENU_SELECTED_TEXT_COLOR_OPACITY_CHANGED',
    GS_MENU_SELECTED_TEXT_COLOR_REMOVED = 'GS_MENU_SELECTED_TEXT_COLOR_REMOVED',

    GS_MENU_SELECTED_TEXT_SHADOW_COLOR_CHANGED = 'GS_MENU_SELECTED_TEXT_SHADOW_COLOR_CHANGED',
    GS_MENU_SELECTED_TEXT_SHADOW_COLOR_OPACITY_CHANGED = 'GS_MENU_SELECTED_TEXT_SHADOW_COLOR_OPACITY_CHANGED',
    GS_MENU_SELECTED_TEXT_SHADOW_COLOR_REMOVED = 'GS_MENU_SELECTED_TEXT_SHADOW_COLOR_REMOVED',
    GS_MENU_SELECTED_TEXT_SHADOW_BLUR_RADIUS_CHANGED = 'GS_MENU_SELECTED_TEXT_SHADOW_BLUR_RADIUS_CHANGED',
    GS_MENU_SELECTED_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED = 'GS_MENU_SELECTED_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED',
    GS_MENU_SELECTED_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED = 'GS_MENU_SELECTED_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED',

    GS_MENU_SELECTED_BG_COLOR_CHANGED = 'GS_MENU_SELECTED_BG_COLOR_CHANGED',
    GS_MENU_SELECTED_BG_COLOR_OPACITY_CHANGED = 'GS_MENU_SELECTED_BG_COLOR_OPACITY_CHANGED',
    GS_MENU_SELECTED_BG_COLOR_REMOVED = 'GS_MENU_SELECTED_BG_COLOR_REMOVED',

    GS_MENU_SELECTED_BG_GRADIENT_CHANGED = 'GS_MENU_SELECTED_BG_GRADIENT_CHANGED',
    GS_MENU_SELECTED_BG_GRADIENT_OPACITY_CHANGED = 'GS_MENU_SELECTED_BG_GRADIENT_OPACITY_CHANGED',
    GS_MENU_SELECTED_BG_GRADIENT_CANCELLED = 'GS_MENU_SELECTED_BG_GRADIENT_CANCELLED',
    GS_MENU_SELECTED_BG_GRADIENT_DIRECTION_CHANGED = 'GS_MENU_SELECTED_BG_GRADIENT_DIRECTION_CHANGED',
    GS_MENU_SELECTED_BG_GRADIENT_FADEPOINT_CHANGED = 'GS_MENU_SELECTED_BG_GRADIENT_FADEPOINT_CHANGED',

    GS_MENU_SELECTED_BG_ASSET_CHANGED = 'GS_MENU_SELECTED_BG_ASSET_CHANGED',
    GS_MENU_SELECTED_BG_ASSET_REMOVED = 'GS_MENU_SELECTED_BG_ASSET_REMOVED',
    GS_MENU_SELECTED_BG_ASSET_POSITION_CHANGED = 'GS_MENU_SELECTED_BG_ASSET_POSITION_CHANGED',
    GS_MENU_SELECTED_BG_ASSET_REPEAT_CHANGED = 'GS_MENU_SELECTED_BG_ASSET_REPEAT_CHANGED',
    GS_MENU_SELECTED_BG_ASSET_SIZE_CHANGED = 'GS_MENU_SELECTED_BG_ASSET_SIZE_CHANGED',

    GS_MENU_SELECTED_BORDER_RADUIS_CHANGED = 'GS_MENU_SELECTED_BORDER_RADUIS_CHANGED',

    /* Expandable settings */
    GS_MENU_EXPANDABLE_TOGGLE_UNDERLINE = 'GS_MENU_EXPANDABLE_TOGGLE_UNDERLINE',
    GS_MENU_EXPANDABLE_TEXT_COLOR_CHANGED = 'GS_MENU_EXPANDABLE_TEXT_COLOR_CHANGED',
    GS_MENU_EXPANDABLE_TEXT_COLOR_OPACITY_CHANGED = 'GS_MENU_EXPANDABLE_TEXT_COLOR_OPACITY_CHANGED',
    GS_MENU_EXPANDABLE_TEXT_COLOR_REMOVED = 'GS_MENU_EXPANDABLE_TEXT_COLOR_REMOVED',

    GS_MENU_EXPANDABLE_TEXT_SHADOW_COLOR_CHANGED = 'GS_MENU_EXPANDABLE_TEXT_SHADOW_COLOR_CHANGED',
    GS_MENU_EXPANDABLE_TEXT_SHADOW_COLOR_OPACITY_CHANGED = 'GS_MENU_EXPANDABLE_TEXT_SHADOW_COLOR_OPACITY_CHANGED',
    GS_MENU_EXPANDABLE_TEXT_SHADOW_COLOR_REMOVED = 'GS_MENU_EXPANDABLE_TEXT_SHADOW_COLOR_REMOVED',
    GS_MENU_EXPANDABLE_TEXT_SHADOW_BLUR_RADIUS_CHANGED = 'GS_MENU_EXPANDABLE_TEXT_SHADOW_BLUR_RADIUS_CHANGED',
    GS_MENU_EXPANDABLE_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED =
        'GS_MENU_EXPANDABLE_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED',
    GS_MENU_EXPANDABLE_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED = 'GS_MENU_EXPANDABLE_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED',

    GS_MENU_EXPANDABLE_BG_COLOR_CHANGED = 'GS_MENU_EXPANDABLE_BG_COLOR_CHANGED',
    GS_MENU_EXPANDABLE_BG_COLOR_OPACITY_CHANGED = 'GS_MENU_EXPANDABLE_BG_COLOR_OPACITY_CHANGED',
    GS_MENU_EXPANDABLE_BG_COLOR_REMOVED = 'GS_MENU_EXPANDABLE_BG_COLOR_REMOVED',

    GS_MENU_EXPANDABLE_BG_GRADIENT_CHANGED = 'GS_MENU_EXPANDABLE_BG_GRADIENT_CHANGED',
    GS_MENU_EXPANDABLE_BG_GRADIENT_OPACITY_CHANGED = 'GS_MENU_EXPANDABLE_BG_GRADIENT_OPACITY_CHANGED',
    GS_MENU_EXPANDABLE_BG_GRADIENT_CANCELLED = 'GS_MENU_EXPANDABLE_BG_GRADIENT_CANCELLED',
    GS_MENU_EXPANDABLE_BG_GRADIENT_DIRECTION_CHANGED = 'GS_MENU_EXPANDABLE_BG_GRADIENT_DIRECTION_CHANGED',
    GS_MENU_EXPANDABLE_BG_GRADIENT_FADEPOINT_CHANGED = 'GS_MENU_EXPANDABLE_BG_GRADIENT_FADEPOINT_CHANGED',

    GS_MENU_EXPANDABLE_BG_ASSET_CHANGED = 'GS_MENU_EXPANDABLE_BG_ASSET_CHANGED',
    GS_MENU_EXPANDABLE_BG_ASSET_REMOVED = 'GS_MENU_EXPANDABLE_BG_ASSET_REMOVED',
    GS_MENU_EXPANDABLE_BG_ASSET_POSITION_CHANGED = 'GS_MENU_EXPANDABLE_BG_ASSET_POSITION_CHANGED',
    GS_MENU_EXPANDABLE_BG_ASSET_REPEAT_CHANGED = 'GS_MENU_EXPANDABLE_BG_ASSET_REPEAT_CHANGED',
    GS_MENU_EXPANDABLE_BG_ASSET_SIZE_CHANGED = 'GS_MENU_EXPANDABLE_BG_ASSET_SIZE_CHANGED',

    GS_MENU_EXPANDABLE_PADDING_CHANGED = 'GS_MENU_EXPANDABLE_PADDING_CHANGED',

    GS_MENU_EXPANDABLE_SHOW_DROPDOWN_CHECKBOX_PRESSED = 'GS_MENU_EXPANDABLE_SHOW_DROPDOWN_CHECKBOX_PRESSED',

    /* Expanded settings */
    GS_MENU_EXPANDED_TOGGLE_UNDERLINE = 'GS_MENU_EXPANDED_TOGGLE_UNDERLINE',
    GS_MENU_EXPANDED_TEXT_COLOR_CHANGED = 'GS_MENU_EXPANDED_TEXT_COLOR_CHANGED',
    GS_MENU_EXPANDED_TEXT_COLOR_OPACITY_CHANGED = 'GS_MENU_EXPANDED_TEXT_COLOR_OPACITY_CHANGED',
    GS_MENU_EXPANDED_TEXT_COLOR_REMOVED = 'GS_MENU_EXPANDED_TEXT_COLOR_REMOVED',

    GS_MENU_EXPANDED_TEXT_SHADOW_COLOR_CHANGED = 'GS_MENU_EXPANDED_TEXT_SHADOW_COLOR_CHANGED',
    GS_MENU_EXPANDED_TEXT_SHADOW_COLOR_OPACITY_CHANGED = 'GS_MENU_EXPANDED_TEXT_SHADOW_COLOR_OPACITY_CHANGED',
    GS_MENU_EXPANDED_TEXT_SHADOW_COLOR_REMOVED = 'GS_MENU_EXPANDED_TEXT_SHADOW_COLOR_REMOVED',
    GS_MENU_EXPANDED_TEXT_SHADOW_BLUR_RADIUS_CHANGED = 'GS_MENU_EXPANDED_TEXT_SHADOW_BLUR_RADIUS_CHANGED',
    GS_MENU_EXPANDED_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED = 'GS_MENU_EXPANDED_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED',
    GS_MENU_EXPANDED_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED = 'GS_MENU_EXPANDED_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED',

    GS_MENU_EXPANDED_BG_COLOR_CHANGED = 'GS_MENU_EXPANDED_BG_COLOR_CHANGED',
    GS_MENU_EXPANDED_BG_COLOR_OPACITY_CHANGED = 'GS_MENU_EXPANDED_BG_COLOR_OPACITY_CHANGED',
    GS_MENU_EXPANDED_BG_COLOR_REMOVED = 'GS_MENU_EXPANDED_BG_COLOR_REMOVED',

    GS_MENU_EXPANDED_BG_GRADIENT_CHANGED = 'GS_MENU_EXPANDED_BG_GRADIENT_CHANGED',
    GS_MENU_EXPANDED_BG_GRADIENT_OPACITY_CHANGED = 'GS_MENU_EXPANDED_BG_GRADIENT_OPACITY_CHANGED',
    GS_MENU_EXPANDED_BG_GRADIENT_CANCELLED = 'GS_MENU_EXPANDED_BG_GRADIENT_CANCELLED',
    GS_MENU_EXPANDED_BG_GRADIENT_DIRECTION_CHANGED = 'GS_MENU_EXPANDED_BG_GRADIENT_DIRECTION_CHANGED',
    GS_MENU_EXPANDED_BG_GRADIENT_FADEPOINT_CHANGED = 'GS_MENU_EXPANDED_BG_GRADIENT_FADEPOINT_CHANGED',

    GS_MENU_EXPANDED_BG_ASSET_CHANGED = 'GS_MENU_EXPANDED_BG_ASSET_CHANGED',
    GS_MENU_EXPANDED_BG_ASSET_REMOVED = 'GS_MENU_EXPANDED_BG_ASSET_REMOVED',
    GS_MENU_EXPANDED_BG_ASSET_POSITION_CHANGED = 'GS_MENU_EXPANDED_BG_ASSET_POSITION_CHANGED',
    GS_MENU_EXPANDED_BG_ASSET_REPEAT_CHANGED = 'GS_MENU_EXPANDED_BG_ASSET_REPEAT_CHANGED',
    GS_MENU_EXPANDED_BG_ASSET_SIZE_CHANGED = 'GS_MENU_EXPANDED_BG_ASSET_SIZE_CHANGED',

    /* Divider settings */
    GS_MENU_DIVIDER_BORDER_COLOR_CHANGED = 'GS_MENU_DIVIDER_BORDER_COLOR_CHANGED',
    GS_MENU_DIVIDER_BORDER_COLOR_OPACITY_CHANGED = 'GS_MENU_DIVIDER_BORDER_COLOR_OPACITY_CHANGED',
    GS_MENU_DIVIDER_BORDER_COLOR_REMOVED = 'GS_MENU_DIVIDER_BORDER_COLOR_REMOVED',
    GS_MENU_DIVIDER_BORDER_STYLE_CHANGED = 'GS_MENU_DIVIDER_BORDER_STYLE_CHANGED',
    GS_MENU_DIVIDER_BORDER_WIDTH_CHANGED = 'GS_MENU_DIVIDER_BORDER_WIDTH_CHANGED',

    GS_MENU_DIVIDER_PADDING_CHANGED = 'GS_MENU_DIVIDER_PADDING_CHANGED',

    GS_MENU_DIVIDER_BG_COLOR_CHANGED = 'GS_MENU_DIVIDER_BG_COLOR_CHANGED',
    GS_MENU_DIVIDER_BG_COLOR_OPACITY_CHANGED = 'GS_MENU_DIVIDER_BG_COLOR_OPACITY_CHANGED',
    GS_MENU_DIVIDER_BG_COLOR_REMOVED = 'GS_MENU_DIVIDER_BG_COLOR_REMOVED',

    GS_MENU_DIVIDER_BG_GRADIENT_CHANGED = 'GS_MENU_DIVIDER_BG_GRADIENT_CHANGED',
    GS_MENU_DIVIDER_BG_GRADIENT_OPACITY_CHANGED = 'GS_MENU_DIVIDER_BG_GRADIENT_OPACITY_CHANGED',
    GS_MENU_DIVIDER_BG_GRADIENT_CANCELLED = 'GS_MENU_DIVIDER_BG_GRADIENT_CANCELLED',
    GS_MENU_DIVIDER_BG_GRADIENT_DIRECTION_CHANGED = 'GS_MENU_DIVIDER_BG_GRADIENT_DIRECTION_CHANGED',
    GS_MENU_DIVIDER_BG_GRADIENT_FADEPOINT_CHANGED = 'GS_MENU_DIVIDER_BG_GRADIENT_FADEPOINT_CHANGED',

    GS_MENU_DIVIDER_BG_ASSET_CHANGED = 'GS_MENU_DIVIDER_BG_ASSET_CHANGED',
    GS_MENU_DIVIDER_BG_ASSET_REMOVED = 'GS_MENU_DIVIDER_BG_ASSET_REMOVED',
    GS_MENU_DIVIDER_BG_ASSET_POSITION_CHANGED = 'GS_MENU_DIVIDER_BG_ASSET_POSITION_CHANGED',
    GS_MENU_DIVIDER_BG_ASSET_REPEAT_CHANGED = 'GS_MENU_DIVIDER_BG_ASSET_REPEAT_CHANGED',
    GS_MENU_DIVIDER_BG_ASSET_SIZE_CHANGED = 'GS_MENU_DIVIDER_BG_ASSET_SIZE_CHANGED',

    GS_MENU_DIVIDER_BORDER_RADUIS_CHANGED = 'GS_MENU_DIVIDER_BORDER_RADUIS_CHANGED',

    /* Menu level container settings */
    GS_MENU_LEVEL_CONTAINER_BORDER_COLOR_CHANGED = 'GS_MENU_LEVEL_CONTAINER_BORDER_COLOR_CHANGED',
    GS_MENU_LEVEL_CONTAINER_BORDER_COLOR_OPACITY_CHANGED = 'GS_MENU_LEVEL_CONTAINER_BORDER_COLOR_OPACITY_CHANGED',
    GS_MENU_LEVEL_CONTAINER_BORDER_COLOR_REMOVED = 'GS_MENU_LEVEL_CONTAINER_BORDER_COLOR_REMOVED',
    GS_MENU_LEVEL_CONTAINER_BORDER_STYLE_CHANGED = 'GS_MENU_LEVEL_CONTAINER_BORDER_STYLE_CHANGED',
    GS_MENU_LEVEL_CONTAINER_BORDER_WIDTH_CHANGED = 'GS_MENU_LEVEL_CONTAINER_BORDER_WIDTH_CHANGED',

    GS_MENU_LEVEL_CONTAINER_PADDING_CHANGED = 'GS_MENU_LEVEL_CONTAINER_PADDING_CHANGED',

    GS_MENU_LEVEL_CONTAINER_BG_COLOR_CHANGED = 'GS_MENU_LEVEL_CONTAINER_BG_COLOR_CHANGED',
    GS_MENU_LEVEL_CONTAINER_BG_COLOR_OPACITY_CHANGED = 'GS_MENU_LEVEL_CONTAINER_BG_COLOR_OPACITY_CHANGED',
    GS_MENU_LEVEL_CONTAINER_BG_COLOR_REMOVED = 'GS_MENU_LEVEL_CONTAINER_BG_COLOR_REMOVED',

    GS_MENU_LEVEL_CONTAINER_BG_GRADIENT_CHANGED = 'GS_MENU_LEVEL_CONTAINER_BG_GRADIENT_CHANGED',
    GS_MENU_LEVEL_CONTAINER_BG_GRADIENT_OPACITY_CHANGED = 'GS_MENU_LEVEL_CONTAINER_BG_GRADIENT_OPACITY_CHANGED',
    GS_MENU_LEVEL_CONTAINER_BG_GRADIENT_CANCELLED = 'GS_MENU_LEVEL_CONTAINER_BG_GRADIENT_CANCELLED',
    GS_MENU_LEVEL_CONTAINER_BG_GRADIENT_DIRECTION_CHANGED = 'GS_MENU_LEVEL_CONTAINER_BG_GRADIENT_DIRECTION_CHANGED',
    GS_MENU_LEVEL_CONTAINER_BG_GRADIENT_FADEPOINT_CHANGED = 'GS_MENU_LEVEL_CONTAINER_BG_GRADIENT_FADEPOINT_CHANGED',

    GS_MENU_LEVEL_CONTAINER_BG_ASSET_CHANGED = 'GS_MENU_LEVEL_CONTAINER_BG_ASSET_CHANGED',
    GS_MENU_LEVEL_CONTAINER_BG_ASSET_REMOVED = 'GS_MENU_LEVEL_CONTAINER_BG_ASSET_REMOVED',
    GS_MENU_LEVEL_CONTAINER_BG_ASSET_POSITION_CHANGED = 'GS_MENU_LEVEL_CONTAINER_BG_ASSET_POSITION_CHANGED',
    GS_MENU_LEVEL_CONTAINER_BG_ASSET_REPEAT_CHANGED = 'GS_MENU_LEVEL_CONTAINER_BG_ASSET_REPEAT_CHANGED',
    GS_MENU_LEVEL_CONTAINER_BG_ASSET_SIZE_CHANGED = 'GS_MENU_LEVEL_CONTAINER_BG_ASSET_SIZE_CHANGED',

    GS_MENU_LEVEL_CONTAINER_BORDER_RADUIS_CHANGED = 'GS_MENU_LEVEL_CONTAINER_BORDER_RADUIS_CHANGED',

    /* Multiple level settings */
    GS_MENU_TREE_MENU_TEXT_INDENT_CHANGED = 'GS_MENU_TREE_MENU_TEXT_INDENT_CHANGED';

