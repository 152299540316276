import React from "react";
import cx from 'classnames';
import * as R from "ramda";
import { replaceLineBreaksWithBr } from '../../../presentational/utils';
import Image from '../../Image/view/Image';
import imgStyles from '../../Image/view/Image.css';
import styles from './ImageSlider.css';
import { bold } from "../../../../mappers/path";
import LoadingIndicator from '../../../../view/common/LoadingIndicator/index';
import { LIMIT } from "../../Gallery/constants/index";
import kind from '../kind';
import { getTestingWithScreenshotPreview } from '../../constants';
import { replaceTagsWithContent } from "../../Text/view/replaceTagsWithContent";

import type { ViewProps } from '../flowTypes';
import type { ReactElementRef } from '../../../../globalTypes';
import type { Dimensions } from "../../../../redux/modules/children/workspace/flowTypes";
import { ImageSliderImageRenderProps } from "../flowTypes";
import { ua } from "../../../../utils/user-agent/UserAgent";

type ImageProps = {
    caption?: string,
    attributes: Record<string, any>,
    index: number,
    containerStyle: Dimensions,
    captionsEnabled: boolean,
    captionTitleBoxClasses: string,
    isWorkspace: boolean,
    globalVariables: Record<string, any>;
    captionOnTop: boolean,
    crop: boolean,
    originalSize: boolean
}

const minHeight = 40,
    CHROME = 'Chrome',
    CHROMIUM = 'Chromium';

const
    isChromeBrowser = () => {
        let isChrome = false;
        if (ua().parser?.getBrowser()?.name) {
            const browserName = ua().parser.getBrowser().name;
            isChrome = browserName.includes(CHROME) || browserName.includes(CHROMIUM);
        }
        return isChrome;
    },
    getBackgroundSize = (crop, compactView) => {
        if (crop) {
            return 'cover';
        } else if (compactView) {
            return 'auto';
        }
        return 'contain';
    },
    getObjectFit = (crop, compactView) => {
        if (crop) {
            return 'cover';
        } else if (compactView && isChromeBrowser()) {
            return 'scale-down';
        }
        return 'contain';
    },
    prepareImageForRender = (
        { caption, a, imageAvailable, style = {}, src, srcSet, stretch, title,
            isAssetDimensionLessThanContainer, ...rest }: Record<string, any>,
        { width, height }: Dimensions,
        isWorkspace,
        crop,
        originalSize
    ) => ({
        caption,
        imageAvailable,
        divClassName: cx(imgStyles.imageComponent, { [`${imgStyles.imgNotFound}`]: !imageAvailable }),
        a,
        imageAttributes: {
            ...rest,
            role: "presentation",
            className: "swiper-lazy",
            "data-src": src,
            "data-srcset": srcSet,
            style: {
                ...style,
                objectFit: getObjectFit(crop, originalSize),
                display: imageAvailable ? 'block' : 'none',
                marginLeft: 'auto',
                marginRight: 'auto'
            },
            stretch,
            title,
            isAssetDimensionLessThanContainer
        },
        width,
        height,
        isWorkspace
    }),
    renderTitle = (title) => {
        return title && <div
            style={{ fontWeight: bold }}
            className={styles.captionTitle}
        >
            {title}
        </div>;
    },

    renderCaption = (caption) => {
        return caption && <div className={styles.captionBody}>
            {
                replaceLineBreaksWithBr(caption.substr(0, LIMIT.caption.hard))
            }
        </div>;
    };

class RenderImage extends React.Component<ImageProps> {
    captionBox: ReactElementRef<HTMLDivElement>;
    captionBoxChild: ReactElementRef<HTMLDivElement>;

    constructor(props) {
        super(props);
        this.captionBox = React.createRef();
        this.captionBoxChild = React.createRef();
    }

    componentDidMount() {
        if (!this.props.captionsEnabled) return;

        if (
            this.captionBox.current
            && this.captionBoxChild.current
            && this.captionBoxChild.current.offsetHeight > minHeight
        ) {
            this.captionBox.current.classList.add('hasLargeText');
        }
    }

    componentDidUpdate() {
        if (!this.props.captionsEnabled) return;

        if (this.captionBox.current) this.captionBox.current.classList.remove('hasLargeText');

        if (
            this.captionBox.current
            && this.captionBoxChild.current
            && this.captionBoxChild.current.offsetHeight > minHeight
        ) {
            this.captionBox.current.classList.add('hasLargeText');
        }
    }

    render() {
        const {
            caption,
            attributes,
            containerStyle,
            captionsEnabled,
            captionTitleBoxClasses,
            isWorkspace,
            globalVariables,
            crop,
            originalSize
        } = this.props;
        const imageProps = prepareImageForRender({ caption, ...attributes }, containerStyle, isWorkspace, crop, originalSize);
        const { imageAttributes } = imageProps;
        const { stretch, isAssetDimensionLessThanContainer, ...restOfImageAttributes } = imageAttributes;
        if (stretch && !isWorkspace) {
            const { href, ...restHyperLinkProps } = imageProps.a || {},
                hrefWithContentReplaced =
                    href && globalVariables ? replaceTagsWithContent(href, { globalVariables }, isWorkspace) : href;
            return (
                <div
                    data-original-width={containerStyle.width}
                    data-original-height={containerStyle.height}
                    className="wbSlideContainer swiper-slide"
                >
                    <div className={styles.textAlignCenter}>
                        <div className="imageContainer">

                            <a href={hrefWithContentReplaced} {...restHyperLinkProps} style={{ width: 'inherit' }}>
                                <div
                                    data-original-width={containerStyle.width}
                                    data-original-height={containerStyle.height}
                                    data-background={imageAttributes['data-src']}
                                    className={imageAttributes.className}
                                    style={{
                                        ...imageAttributes.style,
                                        backgroundSize: getBackgroundSize(crop, originalSize
                                            && isAssetDimensionLessThanContainer),
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat'
                                    }}
                                    role={imageAttributes.role}
                                    title={imageAttributes.title}
                                >
                                    {
                                        imageProps.imageAvailable && <LoadingIndicator className="swiper-lazy-custom-preloader" />
                                    }
                                </div>
                            </a>
                        </div>
                        {
                            captionsEnabled && (attributes.title || caption) &&
                            <div className={`${captionTitleBoxClasses} captionTitleContainer`} ref={this.captionBox}>
                                <div className={styles.captionTitleInherit} ref={this.captionBoxChild}>
                                    <div className="collapseExpandIcon" />
                                    {!caption && renderTitle(attributes.title)}
                                    {captionsEnabled && renderCaption(caption)}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            );
        }
        return <div
            data-original-width={containerStyle.width}
            data-original-height={containerStyle.height}
            className="wbSlideContainer swiper-slide"
        >
            <div className={styles.textAlignCenter}>
                <div className="imageContainer">

                    <Image
                        {...imageProps}
                        imageAttributes={restOfImageAttributes}
                        globalVariables={globalVariables}
                    />
                    <LoadingIndicator className="swiper-lazy-custom-preloader" />
                </div>
                {
                    captionsEnabled && (attributes.title || caption) &&
                        <div className={`${captionTitleBoxClasses} captionTitleContainer`} ref={this.captionBox}>
                            <div className={styles.captionTitleInherit} ref={this.captionBoxChild}>
                                <div className="collapseExpandIcon" />
                                {!caption && renderTitle(attributes.title)}
                                {captionsEnabled && renderCaption(caption)}
                            </div>
                        </div>
                }
            </div>
        </div>;
    }
}

const
    renderImages = ({
        images,
        containerStyle,
        captionsEnabled,
        captionTitleBoxClasses,
        isWorkspace,
        globalVariables,
        captionOnTop,
        crop,
        originalSize
    }) => images.map(
        ({ caption, ...attributes }, index) => <RenderImage
            key={`${attributes.src}-${index}`}
            caption={caption}
            attributes={attributes}
            index={index}
            containerStyle={containerStyle}
            captionsEnabled={captionsEnabled}
            captionTitleBoxClasses={captionTitleBoxClasses}
            isWorkspace={isWorkspace}
            globalVariables={globalVariables}
            captionOnTop={captionOnTop}
            crop={crop}
            originalSize={originalSize}
        />
    ),
    NormalSliderView = ({
        json,
        images,
        containerStyle,
        captionsEnabled,
        captionTitleBoxClasses,
        containerClassname,
        isWorkspace,
        globalVariables,
        captionOnTop,
        width,
        crop,
        children,
        originalSize
    }: ViewProps) => {
        if (children) {
            children.push(
                <div className="swiper-wrapper" key="1">
                    {
                        renderImages({
                            images,
                            containerStyle,
                            captionsEnabled,
                            captionTitleBoxClasses,
                            isWorkspace,
                            globalVariables,
                            captionOnTop,
                            crop,
                            originalSize
                        })
                    }
                </div>
            );
        }
        return (
            <div
                style={{ width: isWorkspace ? 'inherit' : width }}
                data-swiper={JSON.stringify(json)}
                data-original-width={containerStyle.width}
                data-original-height={containerStyle.height}
                data-captiontop={captionOnTop}
                data-captionenabled={captionsEnabled}
                className={cx(containerClassname, 'swiper-container', { preview: !isWorkspace })}
            >
                {children}
            </div>
        );
    },
    FullWidthSliderView = ({
        json,
        images,
        containerStyle,
        captionsEnabled,
        captionTitleBoxClasses,
        containerClassname,
        isWorkspace,
        globalVariables,
        captionOnTop,
        crop,
        originalSize,
        children,
        templateWidth
    }: ViewProps) => {
        return (
            <div
                data-original-width={templateWidth}
                data-original-height={containerStyle.height}
                data-swiper={JSON.stringify(json)}
                data-captiontop={captionOnTop}
                data-captionenabled={captionsEnabled}
                className={cx(containerClassname, 'swiper-container full-width', { preview: !isWorkspace })}
            >
                <div className="swiper-wrapper">
                    {
                        renderImages({
                            images: images.map(img => ({ ...img, style: { ...img.style, width: '100%' } })),
                            containerStyle,
                            captionsEnabled,
                            captionTitleBoxClasses,
                            isWorkspace,
                            globalVariables,
                            captionOnTop,
                            crop,
                            originalSize
                        })
                    }
                </div>
                {children}
            </div>
        );
    },
    View = ({
        json,
        images,
        containerStyle,
        captionsEnabled,
        captionTitleBoxClasses,
        containerClassname,
        isWorkspace,
        globalVariables,
        captionOnTop,
        isMVEFocus,
        width,
        crop,
        originalSize,
        testingWithScreenshots,
        site,
        isStretch,
        fullWidthOption,
        templateWidth
    }: ViewProps) => {
        const imagesWithLocalizedCaptions = images.map(image => ({
                ...image,
                alt: replaceTagsWithContent(image.alt, { globalVariables, site }),
                caption: replaceTagsWithContent(image.caption, { globalVariables, site }),
                title: replaceTagsWithContent(image.title, { globalVariables, site }),
            })),
            mapStyleHeight: (c: ImageSliderImageRenderProps) => number = R.path(['style', 'height']),
            SliderControls: React.JSX.Element[] = [(<div
                className="navigationIconsTopMostWrapper"
                style={isMVEFocus ? { height: Math.max(...images.map(mapStyleHeight)) } : {}}
                key="0"
            >
                {/* We need this wrapper because on Mobile, height of this container should be equal to gallery height excluding caption box so
                    that all icon should be vertically center aligned
                    */}
                <div className="swiper-container-horizontal">
                    <div className="swiper-pagination" />
                    <div className="arrow prev-arrow"><div className="arrowIcon" /></div>
                    <div className="arrow next-arrow"><div className="arrowIcon" /></div>
                </div>
            </div>)],
            SliderView = isStretch ? FullWidthSliderView : NormalSliderView;
        if (testingWithScreenshots) {
            return getTestingWithScreenshotPreview(kind);
        }

        return (
            <SliderView
                images={imagesWithLocalizedCaptions}
                captionsEnabled={captionsEnabled}
                containerClassname={containerClassname}
                containerStyle={containerStyle}
                isWorkspace={isWorkspace}
                globalVariables={globalVariables}
                captionTitleBoxClasses={captionTitleBoxClasses}
                captionOnTop={captionOnTop}
                crop={crop}
                json={json}
                width={width}
                site={site}
                fullWidthOption={fullWidthOption}
                templateWidth={templateWidth}
                originalSize={originalSize}
            >
                {SliderControls}
            </SliderView>
        );
    };

export default View;
