import CALL_API from "../../../redux/middleware/api/CALL_API";
import { FetchUserDataMigrationActionTypes, RequestUserDataMigrationActionTypes } from "./userDataMigrationActionTypes";

export const
    fetchUserDataMigrationInfo = {
        [CALL_API]: {
            types: FetchUserDataMigrationActionTypes,
            endpoint: 'getUserDataMigrationInfo'
        }
    },
    requestUserDataMigration = {
        [CALL_API]: {
            types: RequestUserDataMigrationActionTypes,
            endpoint: 'migrateUserData'
        }
    };
