import CALL_API from '../../../../redux/middleware/api/CALL_API';

export const
    GENERATE_SCREENSHOT = "GENERATE_SCREENSHOT",
    GENERATE_SCREENSHOT_SUCCESS = "GENERATE_SCREENSHOT_SUCCESS",
    GENERATE_SCREENSHOT_FAILURE = "GENERATE_SCREENSHOT_FAILURE",

    generateScreenshot = () => ({
        [CALL_API]: {
            types: [
                GENERATE_SCREENSHOT,
                GENERATE_SCREENSHOT_SUCCESS,
                GENERATE_SCREENSHOT_FAILURE,
            ],
            endpoint: 'generateScreenshot',
        }
    });
