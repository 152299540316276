import React from 'react';

import { useStyles } from './styles';
import imageStyles from "../../../../Images/imageStyle.css";

import { useIntl } from "../../../../../../../../wbtgen/src/view/intl";
import { getImageSrc } from "../../../../../../../../wbtgen/src/components/oneweb/Image/utils";

import type { ImageAsset } from "../../../../../../../../wbtgen/src/components/App/flowTypes";

type FaviconSettingsImagePreviewProps = {
    asset: ImageAsset | null | undefined;
};

export const FaviconSettingsImagePreview = (props: FaviconSettingsImagePreviewProps) => {
    const
        { asset } = props,
        faviconPlaceholderSize = 28,
        { src: faviconSrc, srcSet: faviconSrcSet } = getImageSrc(asset, faviconPlaceholderSize, faviconPlaceholderSize, true),
        intl = useIntl(),
        classes = useStyles();

    return (
        <div className={classes.previewWrapper}>
            <div className={classes.leftWrapper}>
                <div className={classes.circle} />
                <div className={classes.circle} />
                <div className={classes.circle} />
            </div>
            <div className={classes.middleWrapper}>
                <div className={classes.previewContainer}>
                    <div className={classes.previewContent}>
                        {
                            asset ? (
                                <div className={classes.image}>
                                    <img
                                        src={faviconSrc}
                                        srcSet={faviconSrcSet}
                                        width={faviconPlaceholderSize}
                                        height={faviconPlaceholderSize}
                                    />
                                </div>
                            ) : (
                                <div className={classes.imagePreview} />
                            )
                        }
                        <div className={classes.yourTitle}>{intl.msgJoint('msg: generalInfo.favicon.yourTitle {Your title}')}</div>
                    </div>
                    <div className={classes.previewCloseIcon}>
                        <span className={imageStyles.closeIcon16} />
                    </div>
                </div>
            </div>
            <div className={classes.rightWrapper} />
        </div>
    );
};
