import * as R from 'ramda';
import { memoMaxOne } from '../../../../../../utils/memo';
import { getMouseMovedDiff } from '../../../../../redux/modules/children/workspace/reducers/utils/index';
import {
    ResizeE, ResizeN, ResizeNE, ResizeNW, ResizeS, ResizeSE, ResizeSW, ResizeW
} from "../../../../../utils/handle/kinds";
import { getComponentsBBox, getComponentBBox, getSelectionBBox } from "../../../../../utils/componentsMap/index";
import { getBBoxRatio } from '../../../../../utils/bBox';
import isStretchComponentKind from '../../../../oneweb/isStretchComponentKind';
import { DEFAULT_MIN_COMPONENT_DIMENSIONS, MAX_DIMENSIONS } from './constants';
import registry from '../../../../../view/oneweb/registry/index';
import type { ComponentsMutationsHandler, GetResizingChanges } from './flowTypes';
import type { ComponentChange } from '../../../../../redux/modules/children/workspace/flowTypes';
import {
    getComponentsIdsWithoutWrappedOnes,
} from "../selectors";

import { adjustComponentTops } from '../../../../oneweb/Section/utils';
import { getSectionAtPosition } from '../../isPageMode/utils';
import { SNAPPING_PROXIMITY_THRESHOLD } from '../../../../../utils/snapping/index';
import { isStickyToHeader } from '../isStickyToHeader';
import { isDynamicHeightKind } from "../../../../oneweb/componentKinds";
import { isModernLayoutSection } from '../../../../ModernLayouts/preview_utils';
import { updateModernSectionHeightAndCenterChildComponents } from '../../../../ModernLayouts/utils';

const
    getRatio = memoMaxOne(getBBoxRatio),
    getMinDimensions = (id, map) => R.path([id, 'minDimensions'], map),
    computeMinDimensions = (childrenBbox, handleKind, containerBbox) => {
        const containerWidth = containerBbox.right - containerBbox.left,
            containerHeight = containerBbox.bottom - containerBbox.top,
            containerWidthWRTChildrenBBoxRight = childrenBbox.minBBox.right - containerBbox.left,
            containerWidthWRTChildrenBBoxLeft = containerBbox.right - childrenBbox.minBBox.left,
            containerHeightWRTChildrenBBoxTop = containerBbox.bottom - childrenBbox.minBBox.top,
            containerHeightWRTChildrenBBoxBottom = childrenBbox.minBBox.bottom - containerBbox.top;

        let minDimensions = {};

        switch (handleKind) {
            case ResizeE:
                minDimensions = { width: containerWidthWRTChildrenBBoxRight,
                    height: containerHeight };
                break;
            case ResizeN:
                minDimensions = { width: containerWidth,
                    height: containerHeightWRTChildrenBBoxTop };
                break;
            case ResizeS:
                minDimensions = { width: containerWidth,
                    height: containerHeightWRTChildrenBBoxBottom };
                break;
            case ResizeW:
                minDimensions = { width: containerWidthWRTChildrenBBoxLeft,
                    height: containerHeight };
                break;
            case ResizeNW:
                minDimensions = { width: containerWidthWRTChildrenBBoxLeft,
                    height: containerHeightWRTChildrenBBoxTop };
                break;
            case ResizeNE:
                minDimensions = { width: containerWidthWRTChildrenBBoxRight,
                    height: containerHeightWRTChildrenBBoxTop };
                break;
            case ResizeSW:
                minDimensions = { width: containerWidthWRTChildrenBBoxLeft,
                    height: containerHeightWRTChildrenBBoxBottom };
                break;
            case ResizeSE:
                minDimensions = { width: containerWidthWRTChildrenBBoxRight,
                    height: containerHeightWRTChildrenBBoxBottom };
                break;
            default:
                break;
        }
        return minDimensions;
    },
    getComponentMinRatio = (id, { componentsMap, componentsMapExtension }, componentsAdjustmentData, childrenBbox,
        handleKind, workspaceBBox) => {
        const
            component = componentsMap[id],
            minDimensionsFromAdjustmentData = getMinDimensions(id, componentsAdjustmentData),
            minDimensionsFromComponentsMapExtension = getMinDimensions(id, componentsMapExtension),
            { minDimensions: minDimensionsFromConfig } = registry[component.kind];
        let minDimensions: Record<string, any> = {};
        if (childrenBbox && childrenBbox.minBBox) {
            minDimensions = computeMinDimensions(childrenBbox, handleKind,
                getComponentBBox(componentsMap[childrenBbox.id], workspaceBBox));
        } else {
            minDimensions = R.mergeAll([
                DEFAULT_MIN_COMPONENT_DIMENSIONS,
                minDimensionsFromConfig || {},
                minDimensionsFromAdjustmentData || {},
                minDimensionsFromComponentsMapExtension || {} // componentsMapExtension should have higher priority than AdjustmentData as AdjustmentData is may be used to calculate componentsMapExtension
            ]);
        }
        return [minDimensions.width / component.width, minDimensions.height / component.height];
    },
    /*         -
     *     NW  N  NE
     *   - W       E +
     *     SW  S  SE
     *         +
     *
     *   [top, right, bottom, left]
     *   [N  , E    , S     , W   ]
     *   [0  , 1    , 2     , 3   ]
     *   [-  , +    , +     , -   ] - how change affect dimensions?
     * */
    N = 0,
    E = 1,
    S = 2,
    W = 3,
    sideResizeDiffDirectionMap = {
        [N]: R.negate,
        [E]: R.identity,
        [S]: R.identity,
        [W]: R.negate
    },
    sideResizeRatioOperationSingleSideMap = {
        [N]: R.multiply,
        [E]: R.divide,
        [S]: R.multiply,
        [W]: R.divide
    },
    keepRatioPeerSideMap = {
        [N]: E,
        [E]: S,
        [S]: E,
        [W]: S
    },
    makeDiffObj = bBoxDiffs => (
        { dTop: bBoxDiffs[0], dRight: bBoxDiffs[1], dBottom: bBoxDiffs[2], dLeft: bBoxDiffs[3] }
    ),
    getX = R.prop('x'),
    getY = R.prop('y'),
    sideResizeEffectPropGetters = [getY, getX, getY, getX],
    makeInitialDifferenceArray = () => [0, 0, 0, 0],
    /*
     todo WBTGEN-6762
     heightSidesMap = {
     [N]: true,
     [S]: true
     },
     keepSignPow2 = (input) => (input < 0 ? R.negate : R.identity)(Math.pow(input, 2)), // we have to keep sign of diff, due Math.pow
     smartKeepRatioHandler = (verticalSideIndex, horizontalSideIndex, props) => {
     const
     setYResizeDirection = sideResizeDiffDirectionMap[verticalSideIndex],
     setXResizeDirection = sideResizeDiffDirectionMap[horizontalSideIndex],
     { x, y, initialBBox } = props,
     ratio = getRatio(initialBBox),
     mdy = setYResizeDirection(y),
     mdx = setXResizeDirection(x),
     mdyPow2 = keepSignPow2(mdy),
     mdxPow2 = keepSignPow2(mdx),
     sumOfMouseXYDiff = mdyPow2 + mdxPow2,
     gipotenuse = Math.sqrt(Math.abs(sumOfMouseXYDiff)),
     keepSign = sumOfMouseXYDiff < 0 ? R.negate : R.identity,
     hKatheter = keepSign(gipotenuse / Math.sqrt((1 + Math.pow(ratio, 2)))),
     wKatheter = ratio * hKatheter,
     diffsArray = [verticalSideIndex, horizontalSideIndex].reduce((diffsArray, sideIndex) => {
     const
     katheter = heightSidesMap[sideIndex] ? hKatheter : wKatheter,
     signedDiff = sideResizeDiffDirectionMap[sideIndex](katheter);

     diffsArray[sideIndex] = signedDiff; // eslint-disable-line no-param-reassign
     return diffsArray;
     }, makeInitialDifferenceArray());

     return diffsArray;
     }, */
    floorDTopToWorkspaceTop = (
        sideIndex: number,
        mousePositionChangeForSideIndex: number,
        initialBBoxTop: number
    ) => {
        if (sideIndex === N && initialBBoxTop + mousePositionChangeForSideIndex < 0) {
            return sideResizeDiffDirectionMap[N](initialBBoxTop);
        }
        return null;
    },
    simpleKeepRatioHandler = (verticalSideIndex, horizontalSideIndex, props) => { // ignore input Y
        const
            diffsArray = makeInitialDifferenceArray(),
            inputDiffX = props.x,
            initialBBox = props.initialBBox,
            ratio = getRatio(initialBBox);

        diffsArray[horizontalSideIndex] = inputDiffX;
        diffsArray[verticalSideIndex] = sideResizeDiffDirectionMap[verticalSideIndex](inputDiffX) / ratio;

        if (horizontalSideIndex === W) {
            diffsArray[verticalSideIndex] = -diffsArray[verticalSideIndex];
        }

        const verticalSideChange =
            floorDTopToWorkspaceTop(verticalSideIndex, diffsArray[verticalSideIndex], initialBBox.top);
        if (verticalSideChange !== null) {
            diffsArray[verticalSideIndex] = verticalSideChange;
            diffsArray[horizontalSideIndex] = sideResizeDiffDirectionMap[horizontalSideIndex](initialBBox.top) * ratio;
        }

        return diffsArray;
    },
    followMouseHandler = (verticalSideIndex, horizontalSideIndex, props) => {
        const
            diffsArray = [verticalSideIndex, horizontalSideIndex].reduce((diffsArray, sideIndex) => {
                diffsArray[sideIndex] = sideResizeEffectPropGetters[sideIndex](props); // eslint-disable-line no-param-reassign
                return diffsArray;
            }, makeInitialDifferenceArray());

        const verticalSideChange =
            floorDTopToWorkspaceTop(verticalSideIndex, diffsArray[verticalSideIndex], props.initialBBox.top);
        if (verticalSideChange !== null) {
            diffsArray[verticalSideIndex] = verticalSideChange;
        }

        return diffsArray;
    },
    makeTwoSidesHandlerFactory = (keepRatio: boolean) => (verticalSideIndex, horizontalSideIndex) => props => {
        const
            handler = keepRatio ? simpleKeepRatioHandler /* smartKeepRatioHandler */ : followMouseHandler,
            diffsArray = handler(verticalSideIndex, horizontalSideIndex, props);

        return makeDiffObj(diffsArray);
    },
    makeOneSideHandlerFactory = (keepRatio: boolean) => (sideIndex: number) => props => {
        const
            diffsArray = makeInitialDifferenceArray(),
            effectivePropGetter = sideResizeEffectPropGetters[sideIndex],
            inputDiff = effectivePropGetter(props);

        diffsArray[sideIndex] = inputDiff;

        const verticalSideChange = floorDTopToWorkspaceTop(sideIndex, diffsArray[sideIndex], props.initialBBox.top);
        if (verticalSideChange !== null) {
            diffsArray[sideIndex] = verticalSideChange;
        }

        if (keepRatio) {
            const
                ratio = getRatio(props.initialBBox),
                peerSideIndex = keepRatioPeerSideMap[sideIndex],
                signedDiff = sideResizeDiffDirectionMap[sideIndex](diffsArray[sideIndex]),
                ratioOperation = sideResizeRatioOperationSingleSideMap[sideIndex];

            diffsArray[peerSideIndex] = ratioOperation(signedDiff, ratio);
        }

        return makeDiffObj(diffsArray);
    },
    makeTwoSideHandlerMap = ({ keepRatio }) => {
        const makeTwoSidesHandler = makeTwoSidesHandlerFactory(keepRatio);
        return {
            [ResizeNW]: makeTwoSidesHandler(N, W),
            [ResizeSW]: makeTwoSidesHandler(S, W),
            [ResizeSE]: makeTwoSidesHandler(S, E),
            [ResizeNE]: makeTwoSidesHandler(N, E)
        };
    },
    makeOnSideHandlerMap = ({ keepRatio }) => {
        const makeOneSideHandler = makeOneSideHandlerFactory(keepRatio);
        return {
            [ResizeN]: makeOneSideHandler(N),
            [ResizeE]: makeOneSideHandler(E),
            [ResizeS]: makeOneSideHandler(S),
            [ResizeW]: makeOneSideHandler(W)
        };
    },
    defaultHandlersByHandleKind = {
        ...makeTwoSideHandlerMap({ keepRatio: false }),
        ...makeOnSideHandlerMap({ keepRatio: false })
    },
    invertedHandlersByHandleKind = {
        ...makeTwoSideHandlerMap({ keepRatio: true }),
        ...makeOnSideHandlerMap({ keepRatio: true })
    },
    forceKeepRatioHandlersByHandleKind = {
        ...makeTwoSideHandlerMap({ keepRatio: true }),
        ...makeOnSideHandlerMap({ keepRatio: true })
    },
    getHandlerByKindMap = (forceFixRatio, isShift) => {
        if (forceFixRatio) {
            return forceKeepRatioHandlersByHandleKind;
        }

        if (isShift) {
            return invertedHandlersByHandleKind;
        }

        return defaultHandlersByHandleKind;
    },
    shouldForceFixedRatio = (componentsMap, componentIds) => {
        return componentIds.some((id) => {
            const component = componentsMap[id];
            return registry[component.kind].shouldKeepAspectRatio(component);
        });
    };

export const getResizingChanges: GetResizingChanges<ComponentChange[]> =
    (components, componentsIds, start, current, handleKind, workspaceBBox, componentsAdjustmentData, isShift,
        childrenBBox?: any) => {
        const
            { componentsMap } = components,
            [minWRatio, minHRatio] = componentsIds
                .reduce(([minWRatio, minHRatio], id) => {
                    const [_minWRatio, _minHRatio] = getComponentMinRatio(id, components, componentsAdjustmentData,
                        childrenBBox, handleKind, workspaceBBox);

                    return [Math.max(_minWRatio, minWRatio), Math.max(_minHRatio, minHRatio)];
                }, [0, 0]),

            { x, y } = getMouseMovedDiff(start, current),
            initialBBox = getComponentsBBox(componentsIds.map(id => componentsMap[id]), workspaceBBox),
            selectionWidth = initialBBox.right - initialBBox.left,
            selectionHeight = initialBBox.bottom - initialBBox.top,
            forceFixRatio = shouldForceFixedRatio(componentsMap, componentsIds),
            handlerByKindMap = getHandlerByKindMap(forceFixRatio, isShift),
            keepRatio = forceFixRatio || isShift,
            resizeHandler = handlerByKindMap[handleKind],
            { dTop, dRight, dBottom, dLeft } = resizeHandler({ x, y, initialBBox }),
            maxOfMinRatios = Math.max(minHRatio, minWRatio);

        let
            widthAdjustment = 1,
            heightAdjustment = 1;

        const
            maxSelectionWidthDiff = selectionWidth * (1 - (keepRatio ? maxOfMinRatios : minWRatio)),
            maxSelectionHeightDiff = selectionHeight * (1 - (keepRatio ? maxOfMinRatios : minHRatio)),
            widthDiff = dRight - dLeft,
            heightDiff = dBottom - dTop,
            widthDiffAbs = Math.abs(widthDiff),
            heightDiffAbs = Math.abs(heightDiff),
            isWidthDecreaseDirection = widthDiff < 0,
            isHeightDecreaseDirection = heightDiff < 0;

        if (isWidthDecreaseDirection && maxSelectionWidthDiff < widthDiffAbs) {
            widthAdjustment = maxSelectionWidthDiff / widthDiffAbs;
        }

        if (isHeightDecreaseDirection && maxSelectionHeightDiff < heightDiffAbs) {
            heightAdjustment = maxSelectionHeightDiff / heightDiffAbs;
        }
        const
            newSelectionbox = {
                left: initialBBox.left + (dLeft * widthAdjustment),
                top: initialBBox.top + (dTop * heightAdjustment),
                right: initialBBox.right + (dRight * widthAdjustment),
                bottom: initialBBox.bottom + (dBottom * heightAdjustment)
            };

        const maxHeightGrowsDueToDynamicHeightInSelection = componentsIds.reduce((maxDiff, cid) => {
            const componentAdjustmentData = componentsAdjustmentData[cid];
            if (
                componentAdjustmentData
                && componentAdjustmentData.minDimensions
                && componentAdjustmentData.minDimensions.height
            ) {
                const dynamicMinHeight = componentAdjustmentData.minDimensions.height;
                const componentHeight = componentsMap[cid].height;

                if (dynamicMinHeight > componentHeight) {
                    const diff = dynamicMinHeight - componentHeight;

                    return Math.max(maxDiff, diff);
                }
            }

            return maxDiff;
        }, 0);

        if (maxHeightGrowsDueToDynamicHeightInSelection) {
            newSelectionbox.bottom = newSelectionbox.bottom + maxHeightGrowsDueToDynamicHeightInSelection;
        }

        const
            newSelectionWidth = newSelectionbox.right - newSelectionbox.left,
            newSelectionHeight = newSelectionbox.bottom - newSelectionbox.top,
            nextSelectionWRatio = newSelectionWidth / selectionWidth,
            nextSelectionHRatio = newSelectionHeight / selectionHeight;

        let
            wRatio = nextSelectionWRatio,
            hRatio = nextSelectionHRatio;

        if (keepRatio && (nextSelectionWRatio < maxOfMinRatios || nextSelectionHRatio < maxOfMinRatios)) {
            wRatio = maxOfMinRatios;
            hRatio = maxOfMinRatios;
        } else {
            if (nextSelectionWRatio < minWRatio) {
                wRatio = minWRatio;
            }
            if (nextSelectionHRatio < minHRatio) {
                hRatio = minHRatio;
            }
        }

        const result = componentsIds.map(id => {
            const
                component = componentsMap[id],
                componentKind = component.kind,
                componentBbox = getComponentBBox(component, workspaceBBox),
                isStretch = isStretchComponentKind(componentKind, component.stretch);

            let
                newTop = newSelectionbox.top + ((componentBbox.top - initialBBox.top) * hRatio),
                newHeight = newSelectionbox.top + ((componentBbox.bottom - initialBBox.top) * hRatio) - newTop,
                newLeft,
                newWidth;

            if (isStretch) {
                newLeft = workspaceBBox.left;
                newWidth = workspaceBBox.right - workspaceBBox.left;
            } else {
                newLeft = newSelectionbox.left + ((componentBbox.left - initialBBox.left) * wRatio);
                newWidth = newSelectionbox.left + ((componentBbox.right - initialBBox.left) * wRatio) - newLeft;
            }

            return {
                id,
                value: {
                    left: newLeft,
                    top: newTop,
                    width: Math.min(newWidth, MAX_DIMENSIONS.width),
                    height: isDynamicHeightKind(componentKind) ? newHeight : Math.min(newHeight, MAX_DIMENSIONS.height)
                }
            };
        });

        return result;
    };

export const applyResizingComponentsMutations: ComponentsMutationsHandler =
    (
        components,
        componentsIds,
        start,
        current,
        handleKind,
        workspaceBBox,
        componentsAdjustmentData,
        isShift,
        uiPayload,
        componentsMapFromPrevTransientMutationHandler,
        attachments,
        childrenBBox
    ) => {
        let currentMousePosition = { ...current };

        const { componentsMap } = components;
        const selectionBBox = getSelectionBBox(componentsMap, componentsIds);
        const section = getSectionAtPosition({ y: selectionBBox.top, x: 0 }, componentsMap);
        const firstComponent = componentsMap[componentsIds[0]];
        const fitstComponentIsStickyMenu = firstComponent && isStickyToHeader(firstComponent);

        if (!fitstComponentIsStickyMenu && section) {
            const isBottomHandle = [ResizeS, ResizeSE, ResizeSW].includes(handleKind);
            const availableDistanceTowardsTopOfSection = (isBottomHandle ? selectionBBox.bottom : selectionBBox.top) - section.top;
            const resizedDistanceY = start.y - currentMousePosition.y;

            if (resizedDistanceY > availableDistanceTowardsTopOfSection) {
                // user can not resize beyond the section top
                currentMousePosition.y -= availableDistanceTowardsTopOfSection - resizedDistanceY;
            }
        }
        const
            affectedComponentsMap = getResizingChanges(
                components,
                getComponentsIdsWithoutWrappedOnes(componentsMap, componentsIds),
                start,
                currentMousePosition,
                handleKind,
                workspaceBBox,
                componentsAdjustmentData,
                isShift,
                childrenBBox,
            ).reduce((prev, change) => {
                if (componentsMapFromPrevTransientMutationHandler) {
                    const prevComponentState = componentsMapFromPrevTransientMutationHandler[change.id];
                    if (Object.keys(change.value).every(k => prevComponentState[k] === change.value[k])) {
                        return prev;
                    }
                }
                return {
                    ...prev,
                    [change.id]: {
                        ...componentsMap[change.id],
                        ...change.value
                    }
                };
            }, {});

        let newComponentsMap = { ...componentsMap, ...affectedComponentsMap };

        if (!fitstComponentIsStickyMenu && section) {
            const sectionBottom = section.top + section.height;
            const selectionBBoxAfterResize = getSelectionBBox(newComponentsMap, componentsIds);

            const anyComponentChangedItsHeightBecauseOfDynamicHeightAdjust = componentsIds.some(cid => {
                const heightBefore = componentsMap[cid].height;
                const adjustmentData = componentsAdjustmentData[cid];
                const currentMinHeight = adjustmentData && adjustmentData.minDimensions && adjustmentData.minDimensions.height;

                return currentMinHeight && heightBefore && currentMinHeight > heightBefore;
            });

            const selectionBottomWithSnapping = selectionBBoxAfterResize.bottom
                - ((!uiPayload || anyComponentChangedItsHeightBecauseOfDynamicHeightAdjust) ? 0 : SNAPPING_PROXIMITY_THRESHOLD);
            if (sectionBottom < selectionBottomWithSnapping) {
                const diff = selectionBBoxAfterResize.bottom - sectionBottom;
                newComponentsMap = adjustComponentTops(
                    sectionBottom,
                    diff,
                    newComponentsMap
                );
                newComponentsMap = {
                    ...newComponentsMap,
                    ...affectedComponentsMap, // reseting any adjustemts done to selected components, hence they should not be adjusted by adjustComponentTops
                    [section.id]: { ...section, height: section.height + diff },
                };
            }
        }

        if (
            componentsIds.length === 1 &&
            section && section.id === componentsIds[0] &&
            isModernLayoutSection(section)
        ) {
            const sectionId = section.id;
            const cmpChanges = updateModernSectionHeightAndCenterChildComponents({
                oldHeight: section.height,
                newHeight: newComponentsMap[sectionId].height,
                sectionId,
            }, newComponentsMap);
            Object.keys(cmpChanges).forEach(id => {
                const cmp = newComponentsMap[id];
                const { top = cmp.top, height = cmp.height } = cmpChanges[id];
                newComponentsMap = { ...newComponentsMap, [id]: { ...cmp, top, height } };
            });
        }

        return R.isEmpty(affectedComponentsMap) ? null : newComponentsMap;
    };

export default applyResizingComponentsMutations;
