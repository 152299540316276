import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useIntl } from "../../../../../../../../wbtgen/src/view/intl";

import { SettingsSectionInfo } from "../../../../SettingsControls";
import { gmbListingDisconnectButtonAction } from "../../redux/actions";
import { isGMBLoading } from "../../redux/selectors";

import imageStyles from "../../../../Images/imageStyle.css";

const useStyles = makeStyles(
    theme => ({
        actionButton: {
            fontSize: "16px",
            lineHeight: "20px",
            padding: "14px 30px",
            [theme.breakpoints.up("sm")]: {
                fontSize: "14px",
                lineHeight: "22px",
                padding: "8px 30px"
            }
        }
    }),
    { name: "GMBListingPreviewInfo" },
);

export const GMBListingPreviewInfo = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const classes = useStyles();
    const isLoading = useSelector(isGMBLoading);

    const handleClick = () => {
        dispatch(gmbListingDisconnectButtonAction());
    };

    return (
        <SettingsSectionInfo
            iconClassname={imageStyles.googleMyBusiness}
            label={intl.msgJoint("msg: gmb.googleMyBusiness {Google My Business}")}
            description={(
                <Box component="span" display="inline">
                    {intl.msgJoint("msg: gmb.websiteConnected {Your website is currently connected to your listing.}")}
                </Box>
            )}
            extra={(
                <Box mt="24px">
                    <Button variant="outlined" color="primary" className={classes.actionButton} disabled={isLoading} onClick={handleClick}>
                        {intl.msgJoint("msg: gmb.disconnectListing {Disconnect listing}")}
                    </Button>
                </Box>
            )}
            data-testid="gmb-settings-info"
        />
    );
};
