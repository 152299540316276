import type { CountryFormat, RenderAddressToStringProps } from '../../../../../../server/shared/address/types';

const { ALL_COUNTRIES_CODES } = require('../../../../../../server/shared/address/constants.js');
const { renderAddressToStringSingleline } = require('../../../../../../server/shared/address/utils.js');

export const getAddressAreaTypeByCountryFormat = (countryFormat: CountryFormat) => {
    return (countryFormat === 'CountryFormat4') ? 'PROVINCE' : 'STATE';
};

const customStreetAddressFormatCountries = [
    ALL_COUNTRIES_CODES.FR,
    ALL_COUNTRIES_CODES.LU,
    ALL_COUNTRIES_CODES.US,
    ALL_COUNTRIES_CODES.CA,
    ALL_COUNTRIES_CODES.AU,
    ALL_COUNTRIES_CODES.GB
];

export const renderStreetAddress = ({
    addressStreetName,
    addressStreetNumber,
    addressPremiseAndSubPremise,
    addressCountryCode
}: {
    addressStreetName: string | null | undefined;
    addressStreetNumber: string | null | undefined;
    addressPremiseAndSubPremise: string | null | undefined;
    addressCountryCode: string | null | undefined;
}) => {
    if (customStreetAddressFormatCountries.includes(addressCountryCode)) {
        if (addressStreetName && addressStreetNumber && addressPremiseAndSubPremise) {
            return `${addressStreetNumber}, ${addressPremiseAndSubPremise} ${addressStreetName}`;
        } else if (addressStreetName && addressStreetNumber) {
            return `${addressStreetNumber}, ${addressStreetName}`;
        } else if (addressStreetName && addressPremiseAndSubPremise) {
            return `${addressPremiseAndSubPremise} ${addressStreetName}`;
        } else if (addressStreetName) {
            return addressStreetName;
        } else if (addressPremiseAndSubPremise) {
            return addressPremiseAndSubPremise;
        }

        return '';
    }

    if (addressStreetName && addressStreetNumber && addressPremiseAndSubPremise) {
        return addressStreetName + ' ' + addressStreetNumber + ', ' + addressPremiseAndSubPremise;
    } else if (addressStreetName && addressStreetNumber) {
        return addressStreetName + ' ' + addressStreetNumber;
    } else if (addressStreetName && addressPremiseAndSubPremise) {
        return addressStreetName + ', ' + addressPremiseAndSubPremise;
    } else if (addressStreetName) {
        return addressStreetName;
    } else if (addressPremiseAndSubPremise) {
        return addressPremiseAndSubPremise;
    }

    return '';
};

export const makeAddressUrl = (props: RenderAddressToStringProps) => {
    // Wrapping the address inside quotes to "discourage auto-correct" / "let it work with partial match" for Google Maps
    const addressPart = `"${renderAddressToStringSingleline(props)}"`;

    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(addressPart)}`;
};
