import React from 'react';
import { Box, Flex } from "../../../view/reflexbox/index";
import { Msg } from '../../../view/intl/index';
import VerticalSpacer from '../../../view/common/VerticalSpacer';
import { Dialog, DialogBody } from '../../../view/common/dialogs/baseDialog/index';
import getCenteredDialogConfig from '../../DialogManager/getCenteredDialogConfig';
import styles from '../view/Backup.css';

const
    dialogId = 'BackupRestoreSuccessDialog',
    width = 459,
    height = 268,
    BackupRestoreSuccessDialog = () => (
        <Dialog>
            <DialogBody>
                <Box>
                    <VerticalSpacer y={56} />
                    <Flex align="center" justify="center">
                        <div className={styles.restoreSuccess} />
                    </Flex>
                    <VerticalSpacer y={20} />
                    <Flex align="center" justify="center">
                        <Msg className={styles.restoreSuccessMsg} k="common.success">Success</Msg>
                    </Flex>
                    <VerticalSpacer y={11} />
                    <Flex align="center" justify="center">
                        <Msg k="component.backup.dialog.restoreSuccess.restored">Your website has been restored</Msg>
                    </Flex>
                </Box>
            </DialogBody>
        </Dialog>
    ),
    dialog = getCenteredDialogConfig(width, height, BackupRestoreSuccessDialog);

export { dialogId, dialog };
