
export const
    GENERAL_GLOBAL_DATA_POSTAL_CODE_FETCH_SUCCESS = 'GENERAL_GLOBAL_DATA_POSTAL_CODE_FETCH_SUCCESS',
    GENERAL_GLOBAL_DATA_SET_OPENING_HOURS = 'GENERAL_GLOBAL_DATA_SET_OPENING_HOURS',
    GENERAL_GLOBAL_DATA_ADDRESS_CHANGED = 'GENERAL_GLOBAL_DATA_ADDRESS_CHANGED',
    GENERAL_GLOBAL_DATA_EMAIL_CHANGED = 'GENERAL_GLOBAL_DATA_EMAIL_CHANGED',
    GENERAL_GLOBAL_DATA_PHONE_CHANGED = 'GENERAL_GLOBAL_DATA_PHONE_CHANGED',
    GENERAL_GLOBAL_DATA_SET_CONTACT_EMAIL = 'GENERAL_GLOBAL_DATA_SET_CONTACT_EMAIL',
    GENERAL_GLOBAL_DATA_SET_PHONE_NUMBER = 'GENERAL_GLOBAL_DATA_SET_PHONE_NUMBER',
    SOCIAL_GLOBAL_DATA_ADD_PLATFORM = 'SOCIAL_GLOBAL_DATA_ADD_PLATFORM',
    SOCIAL_GLOBAL_DATA_UPDATE_PLATFORM_URL = 'SOCIAL_GLOBAL_DATA_UPDATE_PLATFORM_URL',
    SOCIAL_GLOBAL_DATA_SET_PLATFORM_URL = 'SOCIAL_GLOBAL_DATA_SET_PLATFORM_URL',
    SOCIAL_GLOBAL_DATA_DELETE_PLATFORM = 'SOCIAL_GLOBAL_DATA_DELETE_PLATFORM',
    SOCIAL_GLOBAL_DATA_SET_LINKS = 'SOCIAL_GLOBAL_DATA_SET_LINKS',
    GENERAL_GLOBAL_DATA_UPDATE_GMB_CATEGORIES = 'GENERAL_GLOBAL_DATA_UPDATE_GMB_CATEGORIES',
    GENERAL_GLOBAL_DATA_TOGGLE_VIRTUAL_ADDRESS = 'GENERAL_GLOBAL_DATA_TOGGLE_VIRTUAL_ADDRESS';
