import * as R from 'ramda';
import isStretchComponentKind from '../../../../oneweb/isStretchComponentKind';
import * as updateReasons from '../updateReasons';
import type { ComponentsEvalEpicUpdater } from '../flowTypes';
import {
    getSelectionBBox,
} from '../../../../../utils/componentsMap/index';
import isKeyPressedValueActionType from '../../../../App/epics/isKeyPressed/valueActionType';
import { keyNamesMap } from "../../../../App/epics/isKeyPressed/index";
import { selectedComponentsIdsSelector, userInteractionModeSelector } from "../selectorActionTypes";
import { USER_INTERACTION_DONE_ON_COMPONENTS, COMPONENTS_MOVED_BY_KEYBOARD } from "../actionTypes";
import { ANIMATION_FRAME } from "../../../../../redux/middleware/raf";
import { MOVING_COMPONENTS_BY_ARROW_KEYS } from "./interactionModes";
import {
    setComponentsMap,
    setDefaultUserInteraction,
    setUserInteractionComponentsIds,
    setUserInteractionMode
} from "../setters";

import {
    userInteractionPayloadSelector,
    getSelectedComponentIdsAlongWithWrappedIds
} from "../selectors";
import { userInteractionPayloadPath } from "../paths";
import { receiveOnly, withSelector } from "../../../../../epics/makeCondition";
import { UserFocusValueActionType } from "../../../../App/epics/userFocus/valueActionType";
import { WORKSPACE } from "../../../../App/epics/userFocus/kind";
import { startMovingAfterTimeStampL } from "../lenses";
import { getAllAttachmentsForCmpIds } from '../../componentAttachements/util';
import { componentAttachmentsVAT } from '../../componentAttachements/valueActionType';
import { ContainerKinds } from '../../../../../utils/containerKinds';
import { getComponentsMap } from "../getters";
import { someComponentIdsAreSections } from "../../../../oneweb/Section/utils";
import { isNonTemplateComponentsInHeaderOrFooter } from "./utils";
import { getSectionAtPosition } from '../../isPageMode/utils';
import { isStickyToHeader } from "../isStickyToHeader";
import { isModernLayoutActivatedVAT } from "../../isModernLayoutActivatedEpic/valueActionType";
import { selectedComponentIsInsideHeaderOrFooterVAT } from "../../selectedComponentIsInsideHeaderOrFooterEpic/valueActionType";

const
    stretchIgnoreProps = {
        left: true,
        top: false
    },
    updateReason = updateReasons.MOVED_BY_KEYBOARD,
    setMovingDirections = R.assocPath([...userInteractionPayloadPath, 'movingDirections']),
    getMovingDirections = epicState => {
        const payload = userInteractionPayloadSelector(epicState);
        return payload ? payload.movingDirections || 0 : 0;
    },
    updaterFactory = (updateActionType, { prop, value }): Array<ComponentsEvalEpicUpdater> => {
        const makeOneMoveTransformation = (epicState, attachments) => {
            const
                componentsMap = epicState.state.componentsMap,
                newComponentsMap = { ...componentsMap },
                selectedComponentsIds = getSelectedComponentIdsAlongWithWrappedIds(epicState),
                updateComponentPosition = (component) => R.assoc(prop, component[prop] + value, component);

            selectedComponentsIds.forEach(id => {
                const component = componentsMap[id];
                if (stretchIgnoreProps[prop] && isStretchComponentKind(component.kind, component.stretch)) {
                    newComponentsMap[id] = component;
                } else {
                    newComponentsMap[id] = updateComponentPosition(component);
                    if (ContainerKinds[component.kind]) {
                        let attachedCmpsToMove = getAllAttachmentsForCmpIds(attachments, [id], selectedComponentsIds);
                        attachedCmpsToMove.forEach(cmpId => {
                            const attachedComponent = componentsMap[cmpId];
                            newComponentsMap[cmpId] = updateComponentPosition(attachedComponent);
                        });
                    }
                }
            });
            const selectedComponentsHasStickyComponent =
                selectedComponentsIds.find(id => isStickyToHeader(componentsMap[id]));

            const selectionBBox = getSelectionBBox(newComponentsMap, selectedComponentsIds);
            const section = getSectionAtPosition({ y: selectionBBox.top, x: 0 }, newComponentsMap);
            if (section && !selectedComponentsHasStickyComponent) {
                const sectionBottom = section.top + section.height;
                if (sectionBottom < selectionBBox.bottom) {
                    return R.identity;
                }
            }

            if (isNonTemplateComponentsInHeaderOrFooter(selectedComponentsIds, attachments, newComponentsMap)) {
                return R.identity;
            }

            return setComponentsMap(newComponentsMap);
        };

        const makeEndInteractionResult = (epicState) => {
            const chain = [setComponentsMap({ ...epicState.state.componentsMap })];
            const movingDirections = getMovingDirections(epicState);
            const multipleActionsToDispatch: Array<Action> = [
                { type: USER_INTERACTION_DONE_ON_COMPONENTS }
            ];
            const userPayLoad = epicState.scope.userInteraction.payload;
            if (userPayLoad) {
                multipleActionsToDispatch.push({ type: COMPONENTS_MOVED_BY_KEYBOARD,
                    payload: { componentsId: userPayLoad.componentsIds } });
            }

            if (movingDirections <= 1) {
                chain.push(setDefaultUserInteraction);
            } else {
                chain.push(setMovingDirections(movingDirections - 1));
            }

            return ({
                updateReason,
                state: R.pipe(...chain)(epicState),
                multipleActionsToDispatch
            });
        };

        return [
            {
                conditions: [
                    receiveOnly(componentAttachmentsVAT),
                    receiveOnly(UserFocusValueActionType),
                    receiveOnly(ANIMATION_FRAME),
                    receiveOnly(isModernLayoutActivatedVAT),
                    receiveOnly(selectedComponentIsInsideHeaderOrFooterVAT),
                    updateActionType
                ],
                reducer: ({ values: [
                    { attachments },
                    userFocus,
                    { ts },
                    isModernLayoutActivated,
                    { isInsideHeaderOrFooter: isSelectedComponentBelongsToModernHeaderFooter },
                    isKeyDown,
                ], state: epicState }) => {
                    // TODO WBTGEN-6501: Disable all handles during MOVING_COMPONENTS_BY_ARROW_KEYS interaction
                    const selectedComponentsIds = selectedComponentsIdsSelector(epicState),
                        componentsMap = getComponentsMap(epicState),
                        hasSection = someComponentIdsAreSections(selectedComponentsIds, componentsMap);
                    if (selectedComponentsIds.length === 0 || hasSection ||
                        (isModernLayoutActivated && isSelectedComponentBelongsToModernHeaderFooter)) {
                        return { state: epicState };
                    }

                    if (userFocus.kind !== WORKSPACE || userFocus.comboBoxOpen || userFocus.inputInFocus) {
                        return { state: epicState };
                    }

                    if (isKeyDown) {
                        const nextState = R.pipe(
                            makeOneMoveTransformation(epicState, attachments),
                            setUserInteractionMode(MOVING_COMPONENTS_BY_ARROW_KEYS),
                            setUserInteractionComponentsIds(selectedComponentsIds),
                            setMovingDirections(getMovingDirections(epicState) + 1),
                            R.set(startMovingAfterTimeStampL, ts + 500)
                        )(epicState);

                        return {
                            state: nextState,
                            updateReason: updateReasons.CHANGE_SCOPE
                        };
                    }

                    return makeEndInteractionResult(epicState);
                }
            },
            {
                conditions: [
                    receiveOnly(componentAttachmentsVAT),
                    receiveOnly(UserFocusValueActionType),
                    receiveOnly(updateActionType),
                    ANIMATION_FRAME],
                reducer: ({ values: [{ attachments }, userFocus, isKeyDown, { ts }], state: epicState }) => {
                    if (!isKeyDown || (ts < R.view(startMovingAfterTimeStampL, epicState))) return { state: epicState };

                    const userInteractionMode = userInteractionModeSelector(epicState);
                    const isMoveInProgress = userInteractionMode === MOVING_COMPONENTS_BY_ARROW_KEYS;
                    if (!isMoveInProgress) {
                        return { state: epicState };
                    } else if (userFocus.kind !== WORKSPACE || userFocus.comboBoxOpen || userFocus.inputInFocus) {
                        return makeEndInteractionResult(epicState);
                    }

                    let actionToDispatch;
                    const userPayload = epicState.scope.userInteraction.payload;
                    if (userPayload) {
                        actionToDispatch = { type: COMPONENTS_MOVED_BY_KEYBOARD,
                            payload: { componentsId: userPayload.componentsIds } };
                    }

                    return {
                        state: makeOneMoveTransformation(epicState, attachments)(epicState),
                        actionToDispatch,
                        updateReason
                    };
                }
            }
        ];
    },
    makeKeyPressedSelector = (...keyCodes) => withSelector(
        isKeyPressedValueActionType,
        state => keyCodes.every(keyCode => state.pressedKeysMap[keyCode])
    ),
    makeMoveUpdaters = ({ howMuchPxToMove, withShift }) => {
        let makeSelector = makeKeyPressedSelector;

        if (withShift) {
            makeSelector = makeSelector.bind(null, keyNamesMap.shift);
        }

        return [
            ...updaterFactory(makeSelector(keyNamesMap.up), { prop: 'top', value: -howMuchPxToMove }),
            ...updaterFactory(makeSelector(keyNamesMap.down), { prop: 'top', value: howMuchPxToMove }),
            ...updaterFactory(makeSelector(keyNamesMap.left), { prop: 'left', value: -howMuchPxToMove }),
            ...updaterFactory(makeSelector(keyNamesMap.right), { prop: 'left', value: howMuchPxToMove })
        ];
    };

export const
    hotkeyReducers = [
        ...makeMoveUpdaters({ howMuchPxToMove: 1, withShift: false }),
        ...makeMoveUpdaters({ howMuchPxToMove: 10, withShift: true })
    ];
