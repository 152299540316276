import type { AppState } from "../../../../redux/modules/flowTypes";
import { siteDataStateAppSel } from "../../../App/epics/siteData/selectors";
import { currentPageIdAppSel } from "../../../App/epics/currentPageId/selectorActionTypes";
import { getMenuItems, getHomeMenuItem } from "./getMenuItems";
import type { MenuState, MobileMenuStateParams } from '../types';
import { memoUnlimited } from "../../../../../utils/memo";
import { getTopMostMenuComponent } from './getTopMostMenuComponent';

const mobileMenuSelMemo = memoUnlimited(
    (
        siteData,
        currentPageId,
        level,
        previewBackupTime,
    ) => ({
        menuItems: getMenuItems(siteData, currentPageId, level, previewBackupTime),
        homeMenuItem: getHomeMenuItem(siteData)
    }),
);

export const
    mobileMenuSel = ({ siteData, currentPageId, previewBackupTime,
        componentsMap }: MobileMenuStateParams): MenuState => {
        const topMostMenuComponent = getTopMostMenuComponent(componentsMap),
            level = topMostMenuComponent ? topMostMenuComponent.startLevel : 1;
        return mobileMenuSelMemo(siteData, currentPageId, level, previewBackupTime);
    };

export const
    mobileMenuAppSel = (appState: AppState): MenuState => {
        const
            siteData = siteDataStateAppSel()(appState),
            currentPageId = currentPageIdAppSel()(appState),
            { selectedWorkspace: { components: { componentsMap } } } = appState;

        return mobileMenuSel({ siteData, currentPageId, componentsMap });
    };

