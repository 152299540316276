import { setComponentsMap } from "../../Workspace/epics/componentsEval/setters";
import { REMOVE_EMPTY_GAPS, REMOVE_EMPTY_GAPS_IN_CONTAINERS } from "./actions";
import { getNonGhostCmps, simpleTopSorter } from "../../Workspace/epics/componentAttachements/util";
import { deselectAllComponents } from "../../Workspace/epics/componentsEval/selectedComponentsIds";
import * as kinds from "../../oneweb/componentKinds";
import { divideInToGroups } from "../../oneweb/Section/epics/splitSectionDecoration/divideInToGroups";
import { depthSorter } from "../../Preview/flattening/util";
import { ContainerKinds } from "../../../utils/containerKinds";
import { SCHEDULE_ACTION } from "../../../redux/middleware/schedule/actionTypes";
import { isSectionKind } from "../../oneweb/componentKinds";
import { getCmpBottom } from "../../../convertToSections/util";
import { updateComponentsRelIns } from "../../Workspace/epics/relations/updateComponentsRelIns";
import { ReceiveOnlyTemplateWidthActionType } from "../../oneweb/Template/epics/template/selectorActionTypes";
import { getFooterSection } from "../../oneweb/Section/utils";

const allKinds = {
    [kinds.TEXT]: true,
    [kinds.MENU]: true,
};

const getPageHeight = (cmps) => {
    let pageHeight = 0;
    cmps.forEach(c => {
        pageHeight = Math.max(pageHeight, getCmpBottom(c));
    });
    return pageHeight;
};

const reduceHeights = (componentsMap, componentsMapExtension, cmpKinds) => {
    let newComponentsMap = { ...componentsMap },
        cmps = getNonGhostCmps(newComponentsMap);
    cmps.forEach(c => {
        if (
            cmpKinds.hasOwnProperty(c.kind) &&
            componentsMapExtension[c.id] &&
            componentsMapExtension[c.id].minDimensions &&
            c.height > componentsMapExtension[c.id].minDimensions.height + 250
        ) {
            newComponentsMap[c.id] = {
                ...c,
                height: componentsMapExtension[c.id].minDimensions.height
            };
        }
    });
    return newComponentsMap;
};

export default [{
    conditions: [
        REMOVE_EMPTY_GAPS
    ],
    reducer: ({ state: epicState }) => {
        let { state: { componentsMap, componentsMapExtension } } = epicState,
            newComponentsMap = reduceHeights(componentsMap, componentsMapExtension, allKinds);

        return {
            state: deselectAllComponents(setComponentsMap(newComponentsMap, epicState)),
            updateReason: REMOVE_EMPTY_GAPS,
            actionToDispatch: {
                type: SCHEDULE_ACTION,
                payload: {
                    timeout: 2000,
                    actionToDispatch: {
                        type: REMOVE_EMPTY_GAPS_IN_CONTAINERS
                    }
                }
            }
        };
    }
},
{
    conditions: [
        ReceiveOnlyTemplateWidthActionType,
        REMOVE_EMPTY_GAPS_IN_CONTAINERS
    ],
    reducer: ({ values: [templateWidth], state: epicState }) => {
        let { state: { componentsMap } } = epicState,
            newComponentsMap: any = { ...componentsMap },
            footer = getFooterSection(newComponentsMap),
            cmpsOld = getNonGhostCmps(newComponentsMap),
            pageHeightOld = getPageHeight(cmpsOld);
        if (footer.top + footer.height < pageHeightOld) {
            newComponentsMap[footer.id] = {
                ...newComponentsMap[footer.id],
                height: pageHeightOld - footer.top
            };
        }
        newComponentsMap = updateComponentsRelIns(newComponentsMap, templateWidth);
        let
            parentChild = {},
            cmps = getNonGhostCmps(newComponentsMap).sort(depthSorter).reverse();
        const process = (inpCmp) => {
            let cmp = newComponentsMap[inpCmp.id],
                childs = cmps.filter(c => c.relIn && c.relIn.id === cmp.id).map(c => newComponentsMap[c.id]);
            if (childs.length) {
                parentChild[cmp.id] = childs;
                let
                    groups = divideInToGroups(childs),
                    totalDiff = 0;
                for (let x = 0; x < groups.length; x++) {
                    let diff = 0, g = groups[x];
                    if (x === 0) {
                        diff = (g.top - cmp.top > 250) ? (g.top - cmp.top - 100) : 0;
                    } else if (g.top - groups[x - 1].bottom > 250) {
                        diff = g.top - groups[x - 1].bottom - 100;
                    }
                    totalDiff += diff;
                    if (diff) {
                        for (let j = x; j < groups.length; j++) {
                            groups[j].cmps.forEach(c => {
                                newComponentsMap[c.id] = {
                                    ...newComponentsMap[c.id],
                                    top: newComponentsMap[c.id].top - diff
                                };
                                let allChilds = parentChild[c.id] || [];
                                for (let m = 0; m < allChilds.length; m++) {
                                    newComponentsMap[allChilds[m].id] = {
                                        ...newComponentsMap[allChilds[m].id],
                                        top: newComponentsMap[allChilds[m].id].top - diff
                                    };
                                    allChilds.push(...(parentChild[allChilds[m].id] || []));
                                }
                            });
                        }
                        groups = divideInToGroups(childs.map(c => newComponentsMap[c.id]));
                    }
                }
                newComponentsMap[cmp.id] = {
                    ...newComponentsMap[cmp.id],
                    height: newComponentsMap[cmp.id].height - totalDiff
                };
                let childsBottom = 0;
                childs.forEach(c => {
                    childsBottom = Math.max(newComponentsMap[c.id].top + newComponentsMap[c.id].height, childsBottom);
                });
                if (newComponentsMap[cmp.id].top + newComponentsMap[cmp.id].height > childsBottom + 100) {
                    newComponentsMap[cmp.id].height = childsBottom + 100 - newComponentsMap[cmp.id].top;
                }
            } else if (newComponentsMap[cmp.id].height > 1000) {
                newComponentsMap[cmp.id] = {
                    ...newComponentsMap[cmp.id],
                    height: 800
                };
            }
        };
        let tmpSections: Array<any> = [], pageSections: Array<any> = [], sections: Array<any> = [];
        cmps.forEach(c => {
            let cmp = newComponentsMap[c.id];
            if (isSectionKind(cmp.kind)) {
                if (cmp.inTemplate) {
                    tmpSections.push(cmp);
                } else {
                    pageSections.push(cmp);
                }
            }
            if (ContainerKinds[cmp.kind]) {
                process(cmp);
            }
        });

        pageSections.sort(simpleTopSorter);
        tmpSections.sort(simpleTopSorter);
        sections.push(tmpSections[0], ...pageSections);
        tmpSections.shift();
        let moreThan1Footer = false;
        if (tmpSections.length > 1) {
            moreThan1Footer = true;
        }
        sections.push(...tmpSections);
        const footerId = tmpSections[0].id;
        let prevSection: any = null;
        sections.forEach((s, i) => {
            if (i > 0) {
                prevSection = sections[i - 1];
                const previewSectionBottom = newComponentsMap[prevSection.id].top + newComponentsMap[prevSection.id].height;
                if (previewSectionBottom !== newComponentsMap[s.id].top) {
                    let diff = newComponentsMap[s.id].top - previewSectionBottom;
                    cmps.forEach(cmp => {
                        let c = newComponentsMap[cmp.id];
                        if (c.top + (c.height / 2) >= previewSectionBottom) {
                            newComponentsMap[c.id] = {
                                ...newComponentsMap[c.id],
                                top: newComponentsMap[c.id].top - diff
                            };
                        }
                    });
                }
            }
        });
        const pageHeight = getPageHeight(getNonGhostCmps(newComponentsMap));
        if (!moreThan1Footer && newComponentsMap[footerId].top + newComponentsMap[footerId].height < pageHeight) {
            newComponentsMap[footerId] = {
                ...newComponentsMap[footerId],
                height: pageHeight - newComponentsMap[footerId].top
            };
        }
        return {
            state: deselectAllComponents(setComponentsMap(newComponentsMap, epicState)),
            updateReason: REMOVE_EMPTY_GAPS_IN_CONTAINERS,
        };
    }
}
];
