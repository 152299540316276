import React from "react";
export const SvgInstagramSquareFillMulticolor = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <path fill="#af359e" d="M0 0h48v48H0z" />
            <g fill="#ffffff">
                <path d="M23.886 15.37c2.775 0 3.104.012 4.195.061 1.014.045 1.562.215 1.927.357.483.187.832.414 1.193.775.365.365.588.71.779 1.193.142.365.312.917.357 1.927.048 1.095.06 1.424.06 4.195 0 2.77-.012 3.103-.06 4.195-.045 1.014-.215 1.562-.357 1.927a3.212 3.212 0 01-.775 1.193c-.365.365-.71.588-1.193.779-.365.142-.917.312-1.927.357-1.095.048-1.424.06-4.195.06-2.77 0-3.104-.012-4.195-.06-1.014-.045-1.562-.215-1.927-.357a3.212 3.212 0 01-1.193-.775 3.255 3.255 0 01-.779-1.193c-.142-.365-.312-.917-.357-1.927-.048-1.096-.06-1.424-.06-4.195 0-2.771.012-3.104.06-4.195.045-1.014.215-1.562.357-1.927.187-.483.414-.832.775-1.193.365-.365.71-.588 1.193-.779.365-.142.917-.312 1.927-.357 1.091-.049 1.42-.06 4.195-.06zm0-1.87c-2.82 0-3.173.012-4.28.06-1.104.05-1.862.228-2.52.484a5.07 5.07 0 00-1.841 1.2 5.089 5.089 0 00-1.201 1.838c-.256.662-.434 1.416-.483 2.52-.049 1.111-.061 1.464-.061 4.284s.012 3.173.06 4.28c.05 1.104.228 1.862.484 2.52a5.07 5.07 0 001.2 1.841 5.077 5.077 0 001.838 1.197c.662.256 1.416.434 2.52.483 1.107.049 1.46.06 4.28.06 2.82 0 3.172-.011 4.28-.06 1.104-.049 1.862-.227 2.52-.483a5.077 5.077 0 001.837-1.197 5.077 5.077 0 001.197-1.837c.256-.662.434-1.416.483-2.52.049-1.107.06-1.46.06-4.28 0-2.82-.011-3.173-.06-4.28-.049-1.104-.227-1.862-.483-2.52a4.865 4.865 0 00-1.189-1.845 5.077 5.077 0 00-1.837-1.197c-.662-.256-1.416-.434-2.52-.483-1.111-.053-1.464-.065-4.284-.065z" />
                <path d="M23.886 18.551a5.336 5.336 0 00-5.335 5.335 5.336 5.336 0 005.335 5.335 5.336 5.336 0 005.335-5.335 5.336 5.336 0 00-5.335-5.335zm0 8.796a3.461 3.461 0 11.001-6.923 3.461 3.461 0 01-.001 6.923zm6.791-9.007a1.246 1.246 0 11-2.49 0 1.246 1.246 0 012.49 0z" />
            </g>
        </g>
    </svg>
);
