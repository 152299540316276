import React from "react";
import getCenteredDialog from "../../../DialogManager/getCenteredDialogConfig";
import type { FeaturedProductsEcommerceDialogPropTypes } from "../../FeaturedProducts/flowTypes";
import { injectIntl } from "../../../../view/intl/index";
import Msg from '../../../../view/intl/Msg';
import styles from './styles.css';
import { StripTypes } from "../../../../view/common/dialogs/baseDialog/index";
import StandardDialog from "../../../../view/common/dialogs/StandardDialog/index";
import { SHOW_WEBSHOP_ADMIN_DIALOG } from "../../WebShop/WebshopAdminDialog/actionTypes";
import { closeDialog } from '../../../App/actionCreators/index';
import { PANEL_ITEM_LEFT_MOUSE_DOWN } from '../../../Panel/actionTypes';
import WebshopKind from '../../WebShop/kind';

class OnboardingSuccessDialog extends React.Component<FeaturedProductsEcommerceDialogPropTypes> {
    render() {
        const { props: { dispatch, intl } } = this;

        const FooterContent = () => {
            return (
                <React.Fragment>
                    <button
                        type="button"
                        onClick={() => {
                            dispatch(closeDialog());
                            dispatch({
                                type: PANEL_ITEM_LEFT_MOUSE_DOWN,
                                payload: { componentKind: WebshopKind }
                            });
                        }}
                        className={styles.backToWorkspaceBtn}
                    >
                        <Msg
                            k="common.BackToWorkspace"
                        >
                            Back to workspace
                        </Msg>
                    </button>
                    <a
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            dispatch(closeDialog());
                            dispatch({ type: SHOW_WEBSHOP_ADMIN_DIALOG });
                        }}
                        className={styles.gotoShopLink}
                    >
                        <Msg
                            k="component.productWidget.GotoShopDashboard"
                        >
                            Go to shop dashboard
                        </Msg>
                    </a>
                </React.Fragment>
            );
        };

        return <StandardDialog
            showCloseBtn={false}
            title={intl.msgJoint("msg: component.productWidget.OnboardingSuccess.title {You're all set!}")}
            titleBoxClassName={styles.titleBoxClassName}
            titleExtraClassName={styles.titleExtraClassName}
            titleClassName={styles.titleClassName}
            containerClassName={styles.dialog}
            mctaText="msg: common.BackToWorkspace {Back to workspace}"
            stripType={StripTypes.NONE}
            sctaHandler={null}
            footerClassName={styles.footerClassName}
            footerContents={<FooterContent />}
            footerContentClassName={styles.footerContentClassName}
        >
            <div className={styles.onboardingSuccessIllustration} />
            <div className={styles.message}>
                {/* eslint-disable-next-line max-len */}
                {intl.msgJoint("msg: component.productWidget.OnboardingSuccess.message {You\'ve successfully set up your first product and made it ready for sale - congratulations!}")}
            </div>
        </StandardDialog>;
    }
}

export default getCenteredDialog({
    width: 476,
    height: 636,
    component: injectIntl(OnboardingSuccessDialog)
});
