import React from 'react';
import { Dispatch } from "redux";
import ModernLayoutItem from '../../LeftPanel/screens/ModernLayoutItem';
import ModernLayoutComponentsToggle from '../../LeftPanel/screens/ModernLayoutComponentsToggle';
import { getHeaderLayoutsMap } from '../../../layoutsData/headerLayouts';
import { Intl } from '../../../../../view/intl/index';
import * as styles from '../onBoardingPanel.css';
import type { Stylesheets } from '../../../../Workspace/epics/stylesheets/flowTypes';
import type { TemplateComponent } from '../../../../oneweb/Template/flowTypes';
import type { SectionComponent } from '../../../../oneweb/Section/flowTypes';
import type { LeftPanelEpicState, OnBoardingPanelEpicState } from '../../../flowTypes';
import type { WebShopMHFEpicState } from "../../../../oneweb/WebShopMHF/flowTypes";

type Props = {
    intl: Intl,
    dispatch: Dispatch,
    leftPanel: LeftPanelEpicState,
    onBoardingPanel: OnBoardingPanelEpicState,
    template: TemplateComponent,
    componentsEval: Object,
    globalVariables: Object,
    styleSheets: Stylesheets,
    headerSection: SectionComponent,
    webshopMHFData: WebShopMHFEpicState,
};

const OnBordingToggleHeader = ({
    intl,
    leftPanel,
    onBoardingPanel,
    template,
    componentsEval,
    globalVariables,
    styleSheets,
    dispatch,
    headerSection,
    webshopMHFData,
}: Props) => {
    const headerLayouts = getHeaderLayoutsMap(),
        activatedHeaderId = onBoardingPanel.activatedHeaderId || '',
        layoutItem = headerLayouts[activatedHeaderId],
        shouldAmendToActivation = true,
        headerMessage = 'msg: modernLayout.leftPanel.headerToggleDisable {You have to have at least one element in your header}';
    return <React.Fragment>
        <div className={styles.layoutTitle}>
            {'"' + intl.msgJoint(layoutItem.title) + '"'}
        </div>
        <div className={styles.layoutCmpToggleContainer}>
            <ModernLayoutItem
                layoutItem={layoutItem}
                leftPanel={leftPanel}
                template={template}
                componentsEval={componentsEval}
                globalVariables={globalVariables}
                styleSheets={styleSheets}
                dispatch={dispatch}
                section={headerSection}
                MHFStoredLayoutsData={{}}
                webshopMHFData={webshopMHFData}
                isForceUpdateRequired
            />
            <ModernLayoutComponentsToggle
                intl={intl}
                dispatch={dispatch}
                section={headerSection}
                disableToggleMsg={headerMessage}
                shouldAmendToActivation={shouldAmendToActivation}
            />
        </div>
    </React.Fragment>;
};

export default OnBordingToggleHeader;
