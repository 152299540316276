import kind from './kind';
import Preview from './view/Preview';
import { MIN_WIDTH } from './constants';
import calcRenderProps from './calcRenderProps';
import { getFacebookPagePluginApiUrl, getMinComponentHeight } from './utils';
import type { CalcProps } from '../../Preview/flowTypes';
import type { FacebookPageComponent } from './flowTypes';
import type { GenerateScriptsProps } from '../flowTypes';

type PreviewCalcProps = CalcProps<FacebookPageComponent>;

export default {
    kind,
    view: Preview,
    calcProps: ({ componentId, component, template: { locale } }: PreviewCalcProps) => calcRenderProps({
        componentId,
        component,
        componentExtension: {
            isTransient: false,
            minDimensions: {
                width: MIN_WIDTH,
                height: getMinComponentHeight(component)
            }
        },
        componentDependencies: {
            locale
        },
        inEditMode: false,
        isWorkspace: false
    }),
    js: [
        '/renderStatic/facebook/resize.js'
    ],
    generateScripts: ({ locale }: GenerateScriptsProps): string[] => [getFacebookPagePluginApiUrl(locale)]
};
