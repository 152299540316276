
import * as R from 'ramda';
import type { GalleryComponent } from "./flowTypes";

type TextStyleReducerParam = {
    propName: string
}

export const makeTextStyleReducers = ({ propName }: TextStyleReducerParam) => {
    const
        setCaptionDescriptionTextStyleFont = R.assocPath([propName, 'font']),
        setCaptionDescriptionTextStyleBold = R.assocPath([propName, 'bold']),
        setCaptionDescriptionTextStyleItalic = R.assocPath([propName, 'italic']),
        setCaptionDescriptionTextStyleUnderline = R.assocPath([propName, 'underline']),
        setCaptionDescriptionTextStyleColor = R.assocPath([propName, 'color']),
        setCaptionDescriptionTextStyleSize = R.assocPath([propName, 'size']),
        setCaptionDescriptionTextStyleLineHeight = R.assocPath([propName, 'lineHeight']);

    const
        fontFamilyReducer = (component: GalleryComponent, { payload }: any): GalleryComponent =>
            setCaptionDescriptionTextStyleFont(payload, component),
        boldReducer = (component: GalleryComponent, { payload }: any): GalleryComponent => (
            setCaptionDescriptionTextStyleBold(payload, component)
        ),
        italicReducer = (component: GalleryComponent, { payload }: any): GalleryComponent => (
            setCaptionDescriptionTextStyleItalic(payload, component)
        ),
        underlineReducer = (component: GalleryComponent, { payload }: any): GalleryComponent => (
            setCaptionDescriptionTextStyleUnderline(payload, component)
        ),
        fontColorReducer = (component: GalleryComponent, { payload }: any): GalleryComponent => (
            setCaptionDescriptionTextStyleColor(payload ? payload.color : null, component)
        ),
        lineHeightReducer = (component: GalleryComponent, { payload }: any): GalleryComponent => (
            setCaptionDescriptionTextStyleLineHeight(payload, component)
        ),
        fontSizeReducer = (component: GalleryComponent, { payload }: any): GalleryComponent => (
            setCaptionDescriptionTextStyleSize(payload, component)
        );

    return {
        fontFamilyReducer,
        boldReducer,
        italicReducer,
        underlineReducer,
        fontColorReducer,
        lineHeightReducer,
        fontSizeReducer
    };
};

export const reducers = makeTextStyleReducers({ propName: 'captionDescriptionTextStyle' });
