import { DEFAULT_WIDTH, DEFAULT_HEIGHT,
    SMALL_BUTTON_WIDTH, SMALL_BUTTON_HEIGHT, MEDIUM_BUTTON_HEIGHT, LARGE_BUTTON_HEIGHT } from '../constants';
import { isGoFundMeLinkValid } from './linkValidator';
import { parseGoFundMePostCode } from "./parseGoFundMePostCode";
import { type GoFundMeComponent } from '../types';

const generateSrc = (code: string): string => {
    const buttonType = code?.split('/').slice(-2)[0];
    if (buttonType === 'small' || buttonType === 'medium' || buttonType === 'large') {
        return `${code.match(/^(?:https:\/\/)?www\.gofundme\.com\/f\/[^\/]+/)}/widget/${buttonType}/`;
    }
    return `${code}/widget/large/`;
};

export const parseGoFundMe = (code: string): GoFundMeComponent => {
    let params = <GoFundMeComponent>{};
    if (code.length) {
        if (isGoFundMeLinkValid(code)) {
            params.link = generateSrc(code);
        } else {
            params = parseGoFundMePostCode(code);
        }
        const buttonType = params.link?.split('/').slice(-2)[0];
        if (buttonType === 'small') {
            params.width = SMALL_BUTTON_WIDTH;
            params.height = SMALL_BUTTON_HEIGHT;
        } else if (buttonType === 'medium') {
            params.width = DEFAULT_WIDTH;
            params.height = MEDIUM_BUTTON_HEIGHT;
        } else if (buttonType === 'large') {
            params.width = DEFAULT_WIDTH;
            params.height = LARGE_BUTTON_HEIGHT;
        } else {
            params.width = DEFAULT_WIDTH;
            params.height = DEFAULT_HEIGHT;
        }
    }
    return params.link ? { ...params, } : {};
};
