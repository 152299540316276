// @ts-nocheck
import { assocPath, omit } from 'ramda';
import { H_ALIGN, V_ALIGN } from "../constants";
import { getMLayoutMessages } from "./mLayoutMessages";
import { COMPONENTS_DELETED } from "../../Workspace/actionTypes";
import { isStripKind, WEBSHOP_POLICIES, WEBSHOP_PAYMENT_METHODS, STRIP } from "../../oneweb/componentKinds";
import { replaceComponentIdsInLayout } from '../preview_utils';
import { getCmpRect, isBoxInsideParentBox } from '../../Workspace/epics/componentsEval/utils';
import { getFooterSection } from '../../oneweb/Section/utils';

const webShopPoliciesPoliciesBindingId = 'Cmp-webShopPolicies',
    webShopPoliciesPaymentMethodsBindingId = 'Cmp-webShopPaymentMethods';

const webShopCmpsData = {
    policy: { id: webShopPoliciesPoliciesBindingId, kind: WEBSHOP_POLICIES },
    paymentMethod: { id: webShopPoliciesPaymentMethodsBindingId, kind: WEBSHOP_PAYMENT_METHODS },
};

export const webShopRowBindingId = 'Row-webshop',
    webShopStripBindingId = 'Cmp-strip-webshop';

const webShopLayoutInRoot = [{
    bindingId: webShopRowBindingId,
    cols: [
        {
            bindingId: 'Col-1',
            hAlign: H_ALIGN.center,
            vAlign: V_ALIGN.middle,
            hPositioned: true,
            cmps: [
                { id: webShopStripBindingId, bindingId: webShopStripBindingId }
            ],
        }
    ],
}];

const getWebShopPolicyCol = () => ({
    bindingId: 'Col-1',
    hAlign: H_ALIGN.left,
    vAlign: V_ALIGN.middle,
    hPositioned: true,
    cmps: [
        { id: webShopCmpsData.policy.id, bindingId: webShopCmpsData.policy.id },
    ],
});

const getWebShopPaymentMethodCol = () => ({
    bindingId: 'Col-2',
    hAlign: H_ALIGN.right,
    vAlign: V_ALIGN.middle,
    hPositioned: true,
    cmps: [
        { id: webShopCmpsData.paymentMethod.id, bindingId: webShopCmpsData.paymentMethod.id },
    ],
});

const getWebShopLayoutCols = (isPoliciesExists, isPaymentMethodExists) => {
    if (isPoliciesExists && isPaymentMethodExists) {
        return [
            getWebShopPolicyCol(isPoliciesExists, isPaymentMethodExists),
            getWebShopPaymentMethodCol(isPoliciesExists, isPaymentMethodExists),
        ];
    }
    if (isPaymentMethodExists) {
        return [
            getWebShopPaymentMethodCol(isPoliciesExists, isPaymentMethodExists),
        ];
    }
    return [
        getWebShopPolicyCol(isPoliciesExists, isPaymentMethodExists)
    ];
};

const getWebShopLayout = (isPoliciesExists, isPaymentMethodExists) => ({
    [webShopStripBindingId]: {
        rows: [{
            bindingId: 'Row-4',
            cols: getWebShopLayoutCols(isPoliciesExists, isPaymentMethodExists)
        }],
        style: { padding: { top: 20, bottom: 20 } }
    }
});

const webShopCmps = {
    [webShopStripBindingId]: {
        "id": webShopStripBindingId,
        "kind": 'STRIP',
        "top": 678,
        "left": 0,
        "width": 600,
        "height": 80,
        "selectedTheme": "Accented",
        "style": {
            "border": null,
            "background": {
                "colorData": {
                    "color": ['HSL', 0.3333333333333333, 0, 0.23529411764705882, 1],
                    "gradient": null
                },
                "assetData": null
            }
        }
    },
    [webShopCmpsData.policy.id]: {
        "id": webShopCmpsData.policy.id,
        "kind": webShopCmpsData.policy.kind,
        "top": 658,
        "left": 100,
        "width": 500,
        "height": 40,
        "mobileDown": true,
        "generic": {
            "textStyle": {
                "color": ['HSL', 0, 0, 1, 1],
            },
            "themeOverrideColor": null
        }
    },
    [webShopCmpsData.paymentMethod.id]: {
        "id": webShopCmpsData.paymentMethod.id,
        "kind": webShopCmpsData.paymentMethod.kind,
        "top": 658,
        "left": 700,
        "width": 500,
        "height": 40,
        "mobileDown": true,
        "generic": {
            "textStyle": {
                "color": ['HSL', 0, 0, 1, 1],
            },
            "themeOverrideColor": null
        }
    },
};

const getWebShopPolicyOption = (mLayoutMessages) => ({
    id: webShopCmpsData.policy.id,
    bindingId: webShopCmpsData.policy.id,
    title: mLayoutMessages.TermsAndPrivacyPolicy,
    show: true
});

const getWebShopPaymentMethodOption = (mLayoutMessages) => ({
    id: webShopCmpsData.paymentMethod.id,
    bindingId: webShopCmpsData.paymentMethod.id,
    title: mLayoutMessages.PaymentMethods,
    show: true
});

const getWebShopChildrenOptions = (mLayoutMessages, isPoliciesExists, isPaymentMethodExists) => {
    if (isPoliciesExists && isPaymentMethodExists) {
        return [
            getWebShopPolicyOption(mLayoutMessages),
            getWebShopPaymentMethodOption(mLayoutMessages),
        ];
    }
    if (isPaymentMethodExists) {
        return [
            getWebShopPaymentMethodOption(mLayoutMessages),
        ];
    }
    return [
        getWebShopPolicyOption(mLayoutMessages),
    ];
};

const getWebShopOptions = (mLayoutMessages, isPoliciesExists, isPaymentMethodExists) => ([
    {
        id: webShopStripBindingId,
        bindingId: webShopStripBindingId,
        title: '',
        hideParentInPanel: true,
        show: true,
        children: getWebShopChildrenOptions(mLayoutMessages, isPoliciesExists, isPaymentMethodExists),
    }
]);

const addWebshopMHFData = (footerLayoutMap, WebshopMHFDataAsBottomStripLayout, isPoliciesExists,
    isPaymentMethodExists) => {
    const footerLayoutMapWithWebshopMHFData = {
        ...footerLayoutMap,
    };
    const mLayoutMessages = getMLayoutMessages();
    Object.keys(footerLayoutMapWithWebshopMHFData).forEach((key) => {
        if (!WebshopMHFDataAsBottomStripLayout.includes(key)) {
            return;
        }
        footerLayoutMapWithWebshopMHFData[key] = {
            ...footerLayoutMapWithWebshopMHFData[key],
            layout: {
                ...footerLayoutMapWithWebshopMHFData[key].layout,
                root: {
                    ...footerLayoutMapWithWebshopMHFData[key].layout.root,
                    rows: [
                        ...footerLayoutMapWithWebshopMHFData[key].layout.root.rows,
                        ...webShopLayoutInRoot,
                    ],
                },
                ...getWebShopLayout(isPoliciesExists, isPaymentMethodExists),
            },
            componentsMap: {
                ...footerLayoutMapWithWebshopMHFData[key].componentsMap,
                ...webShopCmps,
            },
            options: [
                ...footerLayoutMapWithWebshopMHFData[key].options,
                ...getWebShopOptions(mLayoutMessages, isPoliciesExists, isPaymentMethodExists),
            ]
        };
    });
    return footerLayoutMapWithWebshopMHFData;
};

const removeWebshopMHFDataFromLayout = (footerSectionId, componentsMap, webshopMHFData) => {
    if (webshopMHFData && webshopMHFData.showSpecialFooterStrip && webshopMHFData.data.policies
         && webshopMHFData.data.policies.length > 0
         && webshopMHFData.data.paymentMethods && webshopMHFData.data.paymentMethods.length > 0) {
        return [componentsMap, [], []];
    }
    const footerSection = componentsMap[footerSectionId],
        modernLayout = footerSection.modernLayout;
    if (!modernLayout) {
        return [componentsMap, [], []];
    }
    const layout = { ...modernLayout.layout },
        sectionId = footerSection.id;
    const toBeDeletedLayoutIds = [],
        toBeDeletedCmpsIds = [],
        multipleActionsToDispatch: Action[] = [];
    let webShopRowLayoutID,
        newOptions = modernLayout.options && modernLayout.options.length ? [...modernLayout.options] : [];

    if (!webshopMHFData.showSpecialFooterStrip ||
        (!webshopMHFData.data.policies.length && !webshopMHFData.data.paymentMethods.length)) {
        layout[sectionId] = {
            ...layout[sectionId],
            rows: layout[sectionId].rows.filter((row) => {
                if (row.bindingId !== webShopRowBindingId) {
                    return true;
                }
                row.cols.forEach((col) => {
                    col.cmps.forEach((cmp) => {
                        toBeDeletedLayoutIds.push(cmp.id);
                        toBeDeletedCmpsIds.push(cmp.id);
                    });
                });
                return false;
            })
        };

        toBeDeletedLayoutIds.forEach((layoutId) => {
            layout[layoutId].rows.forEach((row) => {
                row.cols.forEach((col) => {
                    col.cmps.forEach((cmp) => {
                        toBeDeletedCmpsIds.push(cmp.id);
                    });
                });
            });
            delete layout[layoutId];
        });

        newOptions = newOptions.filter(option => option.bindingId !== webShopStripBindingId);
    } else if (!webshopMHFData.data.policies.length || !webshopMHFData.data.paymentMethods.length) {
        const webShopPolicyOrPaymentBindingId = !webshopMHFData.data.policies.length ?
            webShopPoliciesPoliciesBindingId : webShopPoliciesPaymentMethodsBindingId;
        layout[sectionId].rows.forEach(row => {
            if (row.bindingId === webShopRowBindingId) {
                row.cols.forEach((col) => {
                    col.cmps.forEach((cmp) => {
                        webShopRowLayoutID = cmp.id;
                    });
                });
            }
        });
        if (!layout[webShopRowLayoutID]) {
            return [componentsMap, [], []];
        }
        let webShopRows = [...layout[webShopRowLayoutID].rows];
        webShopRows.forEach((row, rowIndex) => {
            webShopRows[rowIndex] = {
                ...row,
                cols: row.cols.filter((col) => {
                    let isRemoveCol = false;
                    col.cmps.forEach((cmp) => {
                        if (cmp.bindingId === webShopPolicyOrPaymentBindingId) {
                            toBeDeletedCmpsIds.push(cmp.id);
                            isRemoveCol = true;
                        }
                    });
                    return !isRemoveCol;
                })
            };
            if (webShopRows[rowIndex].cols.length === 1) {
                webShopRows = assocPath([rowIndex, 'cols', 0, 'hAlign'], H_ALIGN.center)(webShopRows);
            }
        });
        layout[webShopRowLayoutID] = {
            ...layout[webShopRowLayoutID],
            rows: webShopRows,
        };
        const webShopOptionIndex = newOptions.findIndex(option => option.bindingId === webShopStripBindingId);
        newOptions[webShopOptionIndex] = {
            ...newOptions[webShopOptionIndex],
            children: newOptions[webShopOptionIndex].children.filter(child => child.bindingId !== webShopPolicyOrPaymentBindingId),
        };
    }

    let newCmps = assocPath(
        [footerSection.id, 'modernLayout'],
        {
            ...modernLayout,
            layout,
            options: newOptions,
        },
    )(componentsMap);

    if (toBeDeletedCmpsIds.length) {
        toBeDeletedCmpsIds.forEach(cmpId => {
            if (newCmps[cmpId] && isStripKind(newCmps[cmpId].kind)) {
                newCmps = assocPath(
                    [footerSection.id, 'height'],
                    footerSection.height - newCmps[cmpId].height,
                )(newCmps);
            }
        });
        newCmps = omit(toBeDeletedCmpsIds, newCmps);
        multipleActionsToDispatch.push(
            {
                type: COMPONENTS_DELETED,
                payload: toBeDeletedCmpsIds
            }
        );
    }
    return [newCmps, multipleActionsToDispatch, toBeDeletedCmpsIds];
};

const getWebShopStripId = (componentsMap) => {
    const modernCmps = Object.keys(componentsMap).map(id => componentsMap[id]);
    const webshopCmp = modernCmps.find(cmp => cmp.kind === WEBSHOP_POLICIES
        || cmp.kind === WEBSHOP_PAYMENT_METHODS);
    if (!webshopCmp) {
        return null;
    }
    const webshopCmpParentRelIn = webshopCmp.relIn && webshopCmp.relIn.id;
    if (!webshopCmpParentRelIn) {
        return null;
    }
    const webshopStrip = componentsMap[webshopCmpParentRelIn];
    if (!webshopStrip || !isStripKind(webshopStrip.kind)) {
        return null;
    }
    return webshopStrip.id;
};

const getWebShopPolicyCmpId = (componentsMap) => {
    const modernCmps = Object.keys(componentsMap).map(id => componentsMap[id]);
    const webshopCmp = modernCmps.find(cmp => cmp.kind === WEBSHOP_POLICIES);
    if (!webshopCmp) {
        return null;
    }
    return webshopCmp.id;
};

const getWebShopPaymentCmpId = (componentsMap) => {
    const modernCmps = Object.keys(componentsMap).map(id => componentsMap[id]);
    const webshopCmp = modernCmps.find(cmp => cmp.kind === WEBSHOP_PAYMENT_METHODS);
    if (!webshopCmp) {
        return null;
    }
    return webshopCmp.id;
};

const getWebShopFooterCmpIdsWithOutStrip = (componentsMap) => {
    return [getWebShopPolicyCmpId(componentsMap), getWebShopPaymentCmpId(componentsMap)].filter(id => !!id);
};

const isCmpsMapHasWebshopFooterCmps = (componentsMap) => {
    const modernCmps = Object.keys(componentsMap).map(id => componentsMap[id]);
    return (modernCmps.some(cmp => cmp.kind === WEBSHOP_POLICIES
        || cmp.kind === WEBSHOP_PAYMENT_METHODS));
};

const getWebShopLayoutWithIds = (componentsMap) => {
    const webshopStripId = getWebShopStripId(componentsMap);
    if (!webshopStripId) {
        return null;
    }
    const policyCmp = componentsMap[getWebShopPolicyCmpId(componentsMap)],
        paymentCmp = componentsMap[getWebShopPaymentCmpId(componentsMap)];
    if (!policyCmp && !paymentCmp) {
        return null;
    }
    const mhfLayout = replaceComponentIdsInLayout(getWebShopLayout(!!policyCmp, !!paymentCmp), {
        [webShopStripBindingId]: webshopStripId,
        [webShopPoliciesPoliciesBindingId]: policyCmp && policyCmp.id,
        [webShopPoliciesPaymentMethodsBindingId]: paymentCmp && paymentCmp.id,
    });
    return mhfLayout;
};

const getWebShopStripCmpIds = (componentsMap) => {
    const modernCmps = Object.keys(componentsMap).map(id => componentsMap[id]);
    const webshopStripId = getWebShopStripId(componentsMap);
    if (!webshopStripId) {
        return [];
    }
    const webshopCmpIds = modernCmps.filter(cmp => cmp.kind === WEBSHOP_POLICIES
        || cmp.kind === WEBSHOP_PAYMENT_METHODS).map(cmp => cmp.id);
    return [webshopStripId, ...webshopCmpIds];
};

const getWebShopStripCmpIdsTrueMap = (componentsMap) => {
    const cmpIds = getWebShopStripCmpIds(componentsMap);
    return cmpIds.reduce((acc, id) => {
        acc[id] = true;
        return acc;
    }, {});
};

const getIsBoxInWebShopFooterStrip = (box, componentsMap) => {
    const webshopStripId = getWebShopStripId(componentsMap);
    if (!webshopStripId) {
        return false;
    }
    return isBoxInsideParentBox(box, getCmpRect(componentsMap[webshopStripId]), true);
};

const someComponentsPlacedInWebShopFooterStrip = (cIds, componentsMap, isIncludeStrip = false) => {
    return cIds.some(cId => {
        const component = componentsMap[cId],
            box = getCmpRect(component),
            webShopStrip = getWebShopStripId(componentsMap);
        if (!webShopStrip) {
            return false;
        }
        if (cIds.includes(webShopStrip)) {
            return isIncludeStrip;
        }
        return getIsBoxInWebShopFooterStrip(box, componentsMap);
    });
};

const getIsBoxBelowWebShopFooterStrip = (box, componentsMap) => {
    const footerSection = getFooterSection(componentsMap);
    if (!footerSection) {
        return false;
    }
    const isWebShopFooterActivated = footerSection && isCmpsMapHasWebshopFooterCmps(componentsMap),
        footerRect = getCmpRect(footerSection);
    return isWebShopFooterActivated && footerRect.bottom < box.bottom;
};

const someComponentsBelowWebShopFooterStrip = (cIds, componentsMap) => {
    const isWebShopFooterActivated = isCmpsMapHasWebshopFooterCmps(componentsMap);
    if (!isWebShopFooterActivated) {
        return false;
    }
    const footerRect = getCmpRect(componentsMap[getWebShopStripId(componentsMap)]);
    return cIds.some(cId => {
        const component = componentsMap[cId],
            box = getCmpRect(component);
        return footerRect.bottom < box.bottom;
    });
};

const webShopFooterCmpSBackupPaths = {
    [STRIP]: [['style'], ['selectedTheme']],
    [WEBSHOP_POLICIES]: [['generic']],
    [WEBSHOP_PAYMENT_METHODS]: [['generic']]
};

export {
    addWebshopMHFData,
    getWebShopLayout,
    getWebShopOptions,
    webShopCmps,
    webShopFooterCmpSBackupPaths,
    getWebShopLayoutWithIds,
    isCmpsMapHasWebshopFooterCmps,
    getWebShopStripId,
    getWebShopStripCmpIds,
    getWebShopStripCmpIdsTrueMap,
    getWebShopFooterCmpIdsWithOutStrip,
    getWebShopPolicyCmpId,
    getWebShopPaymentCmpId,
    getIsBoxInWebShopFooterStrip,
    getIsBoxBelowWebShopFooterStrip,
    someComponentsPlacedInWebShopFooterStrip,
    someComponentsBelowWebShopFooterStrip,
    removeWebshopMHFDataFromLayout
};
