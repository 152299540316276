import * as React from 'react';
import cx from 'classnames';

import styles from './SearchBox.css';
import Input from '../../../../inputControls/input/view/Input';

function reducer(state, action) {
    switch (action.type) {
        case 'change_action':
            return { ...state, value: action.payload };
        case 'clear_action':
            return { ...state, value: '' };
        default:
            return state;
    }
}

const SearchBox = ({ onSearch }) => {
    const [searchBoxInFoucus, setSearchBoxInFocus] = React.useState(false);
    const [state, dispatch] = React.useReducer(reducer, {
        value: '',
        errors: [],
        isTouched: true,
        isValid: true
    });

    const clearSearchText = () => {
        dispatch({ type: 'clear_action' });
        onSearch('');
    };

    return (
        <div className={styles.searchContainer}>
            <div className={cx(styles.searchBox, { [styles.focused]: searchBoxInFoucus })}>
                <Input
                    state={state}
                    onChangeAction="change_action"
                    actionParams={{
                        'FeaturedProducts': 'FeaturedProducts'
                    }}
                    onEnterPress={() => onSearch(state.value)}
                    placeholder="msg: component.webshop.featuredProducts.searchBox.placeholder {Search for product name}"
                    containerClassName={styles.searchBoxInputContainer}
                    inputClassName={styles.searchBoxInput}
                    showErrors={false}
                    onFocus={() => setSearchBoxInFocus(true)}
                    onBlur={() => setSearchBoxInFocus(false)}
                    // @ts-ignore
                    dispatch={dispatch}
                />
                {state.isTouched && state.value && (
                    <span className={styles.searchBoxClearBtn} onClick={() => clearSearchText()} />
                )}
                <span className={styles.searchBoxIco} onClick={() => onSearch(state.value)} />
            </div>
        </div>
    );
};

export default SearchBox;
