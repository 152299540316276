/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Select } from '../Components/Select';

import { useIntl } from '../../../../../../../view/intl';

import {
    AI_TEXT_TOPIC_CHANGED,
    AI_TEXT_TOPIC_TYPE_CHANGED
} from "../../../../epics/AITextEpic/actionTypes";

import { TOPIC_STANDARD_SECTIONS, TOPIC_TYPE_OPTIONS } from "../constants";

import styles from "../styles.css";
import { selectTopic } from '../../../../epics/AITextEpic/selectors';

export const StandardTopicField = ({ onTopicTypeChange }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const aiTopic = useSelector(selectTopic);

    const [topic, setTopic] = useState(aiTopic !== undefined ? aiTopic : TOPIC_STANDARD_SECTIONS[0].value);

    const isStandardTopic = (value) => TOPIC_STANDARD_SECTIONS.filter(el => el.value === value).length;

    useEffect(() => {
        if (aiTopic === undefined) {
            const value = TOPIC_STANDARD_SECTIONS[0].value;
            setTopic(value);
            dispatch({
                type: AI_TEXT_TOPIC_CHANGED,
                payload: value
            });
        } else if (!isStandardTopic(aiTopic)) {
            const value = TOPIC_TYPE_OPTIONS.custom;
            setTopic(value);
        }
    }, [aiTopic, dispatch]);

    const handleChange = (e) => {
        const value = e.target.value;
        setTopic(value);
        if (value === TOPIC_TYPE_OPTIONS.custom) {
            onTopicTypeChange(value);
            dispatch({
                type: AI_TEXT_TOPIC_TYPE_CHANGED,
                payload: TOPIC_TYPE_OPTIONS.custom
            });
        } else {
            dispatch({
                type: AI_TEXT_TOPIC_TYPE_CHANGED,
                payload: TOPIC_TYPE_OPTIONS.standard
            });
            dispatch({
                type: AI_TEXT_TOPIC_CHANGED,
                payload: value
            });
        }
    };

    const makeStandardSections = () => {
        return TOPIC_STANDARD_SECTIONS.map(item => {
            return {
                ...item,
                label: intl.msgJoint(item.label)
            };
        });
    };

    const selectProps = {
        value: topic,
        data: makeStandardSections(),
        handleChange,
        separators: [5],
        iconsMap: {
            [TOPIC_TYPE_OPTIONS.custom]: styles.plusIcon,
        }
    };

    return (
        <div className={styles.fieldWrapper}>
            <div className={styles.fieldLabel}>
                {intl.msgJoint("msg: component.text.createAIText.dialog.textBuilder.topic.standard.label {What is the topic of the text?}")}
            </div>
            <div className={styles.fieldContainer}>
                <Select {...selectProps} />
            </div>
        </div>
    );
};
