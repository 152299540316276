import React from "react";
import { always, identity, ifElse, isNil } from "ramda";
import pagesTitles from "./titles";
import * as pagesIds from "./ids";
import { injectIntl } from "../../../../../view/intl/index";
import * as selectors from "../../../../Workspace/epics/stylesheets/selectors";
import addGoogleFontView from "../../../../PropertiesPanel/view/AddGoogleFont/index";
import Page from "../../../../PropertiesPanel/view/PropertiesPage";
import { Flex } from "../../../../../view/reflexbox/index";
import HorizontalSpacer from "../../../../../view/common/HorizontalSpacer";
import VerticalSpacer from "../../../../../view/common/VerticalSpacer";
import Separator from "../../../../presentational/PropertiesPanel/Separator";
import PropertyContainer from "../../../../../view/common/PropertyContainer/index";
import FontFamilySelector from "../../../../presentational/FontFamily/index";
import PPSlider from "../../../../../view/common/Slider/PPSlider";
import TextFormatButtonGroup from "../../../../presentational/IconButtonsGroup/TextFormat";
import ChangeLetterCaseButtonGroup from "../../../../presentational/IconButtonsGroup/ChangeLetterCase";
import {
    MIN_CHARACTER_SPACING,
    MAX_CHARACTER_SPACING,
    MIN_LINE_HEIGHT,
    MAX_LINE_HEIGHT,
    LINE_HEIGHT_STEP
} from "../../../../presentational/CharacterSpacing/constants";
import { OPENING_HOURS_KIND } from "../../kind";
import { DEFAULT_LETTER_SPACING, DEFAULT_LINE_HEIGHT } from "../../../../App/epics/tinyMceEpic/editorUtils/constants";
import * as actionTypes from "../../actionTypes";
import type { PPPageViewPropTypes } from "../../flowTypes";

const id = pagesIds.STYLE,
    title = pagesTitles[pagesIds.STYLE],
    view = injectIntl((props: PPPageViewPropTypes) => {
        const { dispatch, selectedComponent, siteFonts, navigateToPage, stylesheets } = props;
        const textNormalStyle = selectors.textNormalGlobalstyle(stylesheets);
        const letterSpacing = isNil(selectedComponent.textStyle.letterSpacing)
            ? textNormalStyle.letterspacing || DEFAULT_LETTER_SPACING
            : selectedComponent.textStyle.letterSpacing;
        const lineHeight = isNil(selectedComponent.textStyle.lineHeight)
            ? textNormalStyle.lineHeight || DEFAULT_LINE_HEIGHT
            : selectedComponent.textStyle.lineHeight;
        const daysBold = ifElse(isNil, always(textNormalStyle.bold), identity)(selectedComponent.textStyle.daysBold);
        const daysItalic = ifElse(isNil, always(textNormalStyle.italic), identity)(selectedComponent.textStyle.daysItalic);
        const daysUnderline = ifElse(isNil, always(textNormalStyle.underline), identity)(selectedComponent.textStyle.daysUnderline);
        const hoursBold = ifElse(isNil, always(textNormalStyle.bold), identity)(selectedComponent.textStyle.hoursBold);
        const hoursItalic = ifElse(isNil, always(textNormalStyle.italic), identity)(selectedComponent.textStyle.hoursItalic);
        const hoursUnderline = ifElse(isNil, always(textNormalStyle.underline), identity)(selectedComponent.textStyle.hoursUnderline);

        const makeCaseBtns = ({ lowercase, uppercase }) => (
            <ChangeLetterCaseButtonGroup lowercase={lowercase} uppercase={uppercase} dispatch={dispatch} />
        );

        return (
            <Page>
                <VerticalSpacer y={20} />
                <PropertyContainer dataTestId="opening-hours-font-family" label="msg: common.font {Font}">
                    <FontFamilySelector
                        selected={selectedComponent.textStyle.fontFamily || textNormalStyle.font}
                        onChangeAction={actionTypes.OPENING_HOURS_FONT_FAMILY_CHANGED}
                        siteFonts={siteFonts}
                        onAddFontClicked={() =>
                            navigateToPage(addGoogleFontView.id, {
                                source: OPENING_HOURS_KIND
                            })}
                        isPropertiesPanel
                        forwardToSelectedComponent
                    />
                </PropertyContainer>
                <VerticalSpacer y={19} />
                <PPSlider
                    label="msg: common.characterSpacing {Character spacing}"
                    dataTestId="opening-hours-character-spacing"
                    min={MIN_CHARACTER_SPACING}
                    max={MAX_CHARACTER_SPACING}
                    value={letterSpacing}
                    valueLabel={String(letterSpacing)}
                    onChange={spacing =>
                        dispatch({
                            type: actionTypes.OPENING_HOURS_CHARACTER_SPACING_CHANGED,
                            payload: spacing,
                            amendToSelf: true
                        })}
                />
                <VerticalSpacer y={19} />
                <PPSlider
                    label="msg: common.lineSpacing {Line spacing}"
                    valueLabel={String(lineHeight)}
                    dataTestId="opening-hours-line-spacing"
                    value={lineHeight}
                    min={MIN_LINE_HEIGHT}
                    max={MAX_LINE_HEIGHT}
                    step={LINE_HEIGHT_STEP}
                    onChange={spacing =>
                        dispatch({
                            type: actionTypes.OPENING_HOURS_LINE_SPACING_CHANGED,
                            payload: spacing,
                            amendToSelf: true
                        })}
                />
                <Separator />
                <PropertyContainer dataTestId="opening-hours-days" label="msg: openingHours.days {Days}" vsHeight={6}>
                    <Flex align="center" justify="flex-start">
                        <TextFormatButtonGroup
                            bold={{
                                isSelected: daysBold,
                                onClickAction: actionTypes.OPENING_HOURS_DAYS_BOLD_CHANGED
                            }}
                            italic={{
                                isSelected: daysItalic,
                                onClickAction: actionTypes.OPENING_HOURS_DAYS_ITALIC_CHANGED
                            }}
                            underline={{
                                isSelected: daysUnderline,
                                onClickAction: actionTypes.OPENING_HOURS_DAYS_UNDERLINE_CHANGED
                            }}
                            dispatch={dispatch}
                        />
                        <HorizontalSpacer x={18} />
                        {makeCaseBtns({
                            lowercase: {
                                isSelected: selectedComponent.textStyle.daysCase === "lowercase",
                                onClickAction: actionTypes.OPENING_HOURS_DAYS_LOWERCASE_BTN_CLICKED
                            },
                            uppercase: {
                                isSelected: selectedComponent.textStyle.daysCase === "uppercase",
                                onClickAction: actionTypes.OPENING_HOURS_DAYS_UPPERCASE_BTN_CLICKED
                            }
                        })}
                    </Flex>
                </PropertyContainer>
                <VerticalSpacer y={15} />
                <PropertyContainer dataTestId="opening-hours-hours" label="msg: openingHours.hours {Hours}" vsHeight={6}>
                    <Flex align="center" justify="flex-start">
                        <TextFormatButtonGroup
                            bold={{
                                isSelected: hoursBold,
                                onClickAction: actionTypes.OPENING_HOURS_HOURS_BOLD_CHANGED
                            }}
                            italic={{
                                isSelected: hoursItalic,
                                onClickAction: actionTypes.OPENING_HOURS_HOURS_ITALIC_CHANGED
                            }}
                            underline={{
                                isSelected: hoursUnderline,
                                onClickAction: actionTypes.OPENING_HOURS_HOURS_UNDERLINE_CHANGED
                            }}
                            dispatch={dispatch}
                        />
                        <HorizontalSpacer x={18} />
                        {makeCaseBtns({
                            lowercase: {
                                isSelected: selectedComponent.textStyle.hoursCase === "lowercase",
                                onClickAction: actionTypes.OPENING_HOURS_HOURS_LOWERCASE_BTN_CLICKED
                            },
                            uppercase: {
                                isSelected: selectedComponent.textStyle.hoursCase === "uppercase",
                                onClickAction: actionTypes.OPENING_HOURS_HOURS_UPPERCASE_BTN_CLICKED
                            }
                        })}
                    </Flex>
                </PropertyContainer>
                <VerticalSpacer y={6} />
            </Page>
        );
    });
export { id, view, title };
