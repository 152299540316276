import cx from 'classnames';
import getCenteredDialog from "../../../DialogManager/getCenteredDialogConfig";
import { configurationDialogFactory } from '../configurationDialogFactory';
import { DialogWidth, SpotifyDialogHeight } from '../constants';
import { WIDGETS_SPOTIFY_SET_LINK } from "./actionTypes";
import { validateLinkOrCode } from '../utils';
import { isSpotifyLinkValid, parseSpotifyCode } from './utils';
import Icons from '../view/Icons.css';

const validateSpotifyData = validateLinkOrCode([isSpotifyLinkValid], parseSpotifyCode);

const
    configurationDialogId = "WIDGETS_SPOTIFY_CONFIG_DIALOG",
    configurationDialogView = configurationDialogFactory({
        saveAction: WIDGETS_SPOTIFY_SET_LINK,
        dialogIconClass: cx(Icons.spotify, Icons.dialog),
        dialogTitle: "msg: widgets.spotify.label {Spotify}",
        info: "msg: widgets.spotify.config.addSpotify {Add a Spotify song, album or playlist widget to your page.}",
        subInfo: "msg: widgets.spotify.config.info {In Spotify, click the \'...\' icon, select \'share\', \'copy link\' and paste it below.}", // eslint-disable-line max-len
        learnMore: "msg: widgets.spotify.config.learnMore {https://help.one.com/hc/en-us/articles/9340417793681}",
        inputPlaceHolder: "msg: widgets.spotify.configDialog.placeHolder {Paste Spotify link}",
        isLinkValid: validateSpotifyData,
        termsOfService: "msg: widgets.spotify.config.termsOfService {By using this widget, you agree to Spotify\'s {termsOfServiceLink}.}",
        termsOfServiceLink: "msg: widgets.spotify.config.termsOfServiceLink {https://www.spotify.com/uk/legal/end-user-agreement/ }",
    }),
    configurationDialog = getCenteredDialog({
        width: DialogWidth,
        height: SpotifyDialogHeight,
        component: configurationDialogView,
    });

export { configurationDialogId, configurationDialog };

