import React, { forwardRef } from "react";
import cx from "classnames";
import Measure from "react-measure";
import { always, applySpec, cond, groupWith, propEq, T } from "ramda";
import { GROUPING_OPTIONS, getMidnightToday } from "../constants";
import { getDaysOfTheWeek, openingHoursToOpeningHoursMap } from "../utils";
import styles from "./styles.css";
import { addComponentAdjustmentDataEntry } from "../../../Workspace/epics/componentsEval/adjustmentDataDispatchCache";
import textNormalClassName from "../../Text/globalStyle/textNormalClassName";
import { textNormalGlobalstyle } from "../../../Workspace/epics/stylesheets/selectors";
import type { DayOfTheWeek, OpeningHoursMapValue, OpeningHoursViewProps } from "../flowTypes";
import dayjs from "../../../../dayjs";

type DayHourTuple = [DayOfTheWeek, OpeningHoursMapValue];

const groupOpeningHours = (openingHoursMap, groupDays, daysOfTheWeek): Array<Array<DayHourTuple>> => {
    const groupingFn: (prev: DayHourTuple, next: DayHourTuple) => boolean = ([, openingHour1], [, openingHour2]) => {
        if (groupDays === GROUPING_OPTIONS.DONT_GROUP) {
            // default is GROUP
            return false;
        } else if (openingHour1 === undefined && openingHour2 === undefined) {
            // if "closed" on both the days, group
            return true;
        } else if (openingHour1 === undefined || openingHour2 === undefined) {
            // if "closed" for one of the days but not another, don't group
            return false;
        } else {
            return openingHour1.from === openingHour2.from && openingHour1.to === openingHour2.to;
        }
    };

    return groupWith(
        groupingFn,
        daysOfTheWeek.map(day => [day, openingHoursMap[day]])
    );
};

const makeTimeLabel = (openingHoursForGroup, timeFormatStr, open24HoursLabel, closedLabel) => {
    if (!openingHoursForGroup) {
        return closedLabel;
    } else if (openingHoursForGroup.from === 0 && openingHoursForGroup.to === 0) {
        return open24HoursLabel;
    } else {
        const MIDNIGHT_TODAY = getMidnightToday(),
            from = new Date(MIDNIGHT_TODAY + openingHoursForGroup.from),
            to = new Date(MIDNIGHT_TODAY + openingHoursForGroup.to);
        return `${dayjs(from).format(timeFormatStr)} - ${dayjs(to).format(timeFormatStr)}`;
    }
};

const makeDayTimeLabelStyles = (config, textNormalGS) => {
    const boldEquals = propEq("bold"),
        italicEquals = propEq("italic"),
        underlineEquals = propEq("underline"),
        when = arr => cond(arr.map(([predicate, value]) => [predicate, always(value)])),
        isTextNormalGlobalStyleUnderlined = always(textNormalGS.underline);

    return applySpec({
        fontWeight: when([
            [boldEquals(true), "bold"],
            [boldEquals(false), "normal"],
            [T, undefined]
        ]),
        fontStyle: when([
            [italicEquals(true), "italic"],
            [italicEquals(false), "normal"],
            [T, undefined]
        ]),
        textDecoration: when([
            [underlineEquals(true), "underline"],
            [underlineEquals(false), "none"],
            [isTextNormalGlobalStyleUnderlined, "underline"],
            [T, undefined]
        ])
    })(config);
};

const View = forwardRef(
    (
        {
            component,
            globalVariables: { openingHours = [] },
            rootStyles = {},
            fillerStyles = {},
            stylesheets
        }: OpeningHoursViewProps & {
            rootStyles?: Record<string, any>;
            fillerStyles?: Record<string, any>;
        },
        ref: any
    ) => {
        const { closedLabel, dayLabels, groupDays, hourFormat, open24HoursLabel, startDayOfTheWeek, timeComponentSeparator } = component,
            daysOfTheWeek = getDaysOfTheWeek(startDayOfTheWeek),
            openingHoursMap = openingHoursToOpeningHoursMap(openingHours),
            groupedOpeningHours = groupOpeningHours(openingHoursMap, groupDays, daysOfTheWeek),
            timeFormatStr = `${hourFormat}${timeComponentSeparator}mm${hourFormat === "hh" ? " A" : ""}`,
            localizedOpeningHours = groupedOpeningHours.map(group => {
                const [[, openingHoursForGroup]] = group;
                return group.map(([day]) => [
                    dayLabels[day],
                    makeTimeLabel(openingHoursForGroup, timeFormatStr, open24HoursLabel, closedLabel)
                ]);
            });
        const {
                textStyle: {
                    color,
                    fontFamily,
                    fontSize,
                    letterSpacing,
                    lineHeight,
                    // common styles
                    daysBold,
                    daysCase,
                    daysItalic,
                    daysUnderline,
                    // day label specific styles
                    hoursBold,
                    hoursCase,
                    hoursItalic,
                    hoursUnderline // time data specific styles
                },
                horizontalAlignment
            } = component,
            nullifyTextDecoration = {
                textDecoration: "none"
            },
            // nullification is needed to override it separately for dt and dd
            dlStyles = {
                color,
                fontFamily,
                fontSize,
                letterSpacing,
                lineHeight,
                ...nullifyTextDecoration,
                ...rootStyles
            },
            dtStyles = {
                ...makeDayTimeLabelStyles(
                    {
                        bold: daysBold,
                        italic: daysItalic,
                        underline: daysUnderline
                    },
                    textNormalGlobalstyle(stylesheets)
                ),
                textAlign: (() => {
                    if (groupDays === GROUPING_OPTIONS.DONT_GROUP) {
                        return horizontalAlignment === "center" ? "right" : "left";
                    } else {
                        return horizontalAlignment;
                    }
                })(),
                textTransform: daysCase
            },
            ddStyles = {
                ...makeDayTimeLabelStyles(
                    {
                        bold: hoursBold,
                        italic: hoursItalic,
                        underline: hoursUnderline
                    },
                    textNormalGlobalstyle(stylesheets)
                ),
                textAlign: (() => {
                    if (groupDays === GROUPING_OPTIONS.DONT_GROUP) {
                        return horizontalAlignment === "right" ? "right" : "left";
                    } else {
                        return horizontalAlignment;
                    }
                })(),
                textTransform: hoursCase
            };
        return (
            <div className={styles.container}>
                <dl
                    ref={ref}
                    className={cx(textNormalClassName, styles.openingHours, {
                        [styles.ungrouped]: groupDays === GROUPING_OPTIONS.DONT_GROUP,
                        [styles.grouped]: groupDays !== GROUPING_OPTIONS.DONT_GROUP // default is GROUP
                    })}
                    style={dlStyles}
                >
                    {localizedOpeningHours.map(dayTimeTupleGroup => {
                        const [firstDay, openingHoursForTheGroup] = dayTimeTupleGroup[0],
                            [lastDay] = dayTimeTupleGroup[dayTimeTupleGroup.length - 1];
                        return (
                            <div key={firstDay} className={styles.item}>
                                <dt style={dtStyles}>{firstDay === lastDay ? firstDay : `${firstDay} - ${lastDay}`}</dt>
                                <div className={styles.filler} style={fillerStyles} />
                                <dd style={ddStyles}>{openingHoursForTheGroup}</dd>
                            </div>
                        );
                    })}
                </dl>
            </div>
        );
    }
);

const OffScreenRender = props => {
    return (
        <div className={styles.offScreen}>
            <Measure
                offset
                onResize={
                    props.isWorkspace
                        ? ({ offset }) =>
                            addComponentAdjustmentDataEntry(props.component.id, {
                                minDimensions: {
                                    height: Math.round(offset.height),
                                    width: Math.round(offset.width)
                                }
                            })
                        : null
                }
            >
                {({ measureRef }) => (
                    <View
                        {...props}
                        ref={measureRef}
                        rootStyles={{
                            width: "auto"
                        }}
                        fillerStyles={{
                            width: "auto"
                        }}
                    />
                )}
            </Measure>
        </div>
    );
};

export const openingHoursWorkspaceView = (props: OpeningHoursViewProps) => {
    return (
        <React.Fragment>
            {props.isWorkspace && <OffScreenRender {...props} />}
            <View {...props} />
        </React.Fragment>
    );
};
