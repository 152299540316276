import type { ApiAction } from "../../../../../redux/middleware/api/flowTypes";
import CALL_API from "../../../../../redux/middleware/api/CALL_API";
import {
    LC_LOAD_EMAIL_REQUEST,
    LC_LOAD_EMAIL_SUCCESS,
    LC_LOAD_EMAIL_FAILURE
} from "../actionTypes";

export default (): ApiAction => ({
    [CALL_API]: {
        types: [
            LC_LOAD_EMAIL_REQUEST,
            LC_LOAD_EMAIL_SUCCESS,
            LC_LOAD_EMAIL_FAILURE
        ],
        endpoint: 'getEmailData'
    }
});
