import reducer from "./reducer/index";
import propertiesPanel from "./propertiesPanel/index";
import kind from './kind';
import componentMainActions from './componentMainActions/index';
import workspace from './view/Workspace';
import copyHook from "./copyHook";
import previewConfig from './previewConfig';
import { ComponentNames } from "../constants";
import { MIN_DIMENSIONS } from "./constants";
import calcProps from "./calcProps";
import { videoUploadStatus, uploadFailedVideos } from "./epics/videoComponent/index";
import { YoutubeConfigDialogId } from "./dialogIds";
import { Youtube } from "../../Panel/configs/constants";
import adjustComponentOnAdd from "./adjustComponentOnAdd";
import { missingAssetUrls } from "../../MissingAssets/epic/index";
import { transcodeStatus } from "./epics/checkTranscodeStatus/index";

export default {
    kind,
    workspace,
    label: ComponentNames[kind],
    shortcutIconName: 'video',
    requireConfigurationOnDrop: ({ dialogProps }: Record<string, any>) => ((dialogProps && dialogProps.type) === Youtube),
    calcRenderProps: (props: any) => calcProps(props, true),
    configurationDialog: () => {
        return {
            dialogId: YoutubeConfigDialogId,
            dialogProps: {}
        };
    },
    dependsOn: {
        missingAssetUrls,
        transcodeStatus,
        videoUploadStatus,
        uploadFailedVideos
    },
    adjustComponentOnAdd,
    componentMainActions,
    propertiesPanel,
    copyHook,
    reducer,
    minDimensions: MIN_DIMENSIONS,
    mobileEditorConfig: {
        ...previewConfig,
        calcProps: (props: any): any => {
            const { component: { width, height }, renderedWidth } = props,
                finalProps = calcProps(props, true);
            if (renderedWidth) {
                finalProps.height = (renderedWidth * (height / width));
            }
            return finalProps;
        }
    }
};
