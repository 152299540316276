import * as React from 'react';
import styles from '../../MveHeaderProperteisPanel.css';
import { LabeledQuestionTip } from "../../../../../presentational/Tooltip/questionTip/LabeledQuestionTip";
import { TooltipPosition } from "../../../../../presentational/Tooltip/constants";
import { injectIntl, Msg, Intl } from "../../../../../../view/intl/index";

const Message = {
    LINK_URL: 'msg: tooltip.MobileEditorPageName.linkUrl {https://help.one.com/hc/en-us/articles/360000348297}',
    LINK_TEXT: 'msg: tooltip.MobileEditorPageName.linkText {Learn more}',
};

type Props = {
    intl: Intl,
};

export const MainMveHeaderPpPageTip = injectIntl(
    ({ intl }: Props) => (
        <LabeledQuestionTip
            position={TooltipPosition.RIGHT}
            className={styles.pageTitleTipTarget}
            theme={{
                container: styles.pageTitleTipContainer,
                tipInfo: styles.pageTitleTipInfo,
            }}
            preserveWhenHover
        >
            { /* $FlowFixMe */ }
            <p>
                <Msg k="tooltip.MobileEditorPageName.text">{`
                    Looking for a way to edit the name of this page?
                    Locate the page in the menu in the top bar, and open page options from there.
                    Edit the name and save.
                `}</Msg>
            </p>
            <p>
                <a
                    className={styles.pageTitleTipLearnMoreLink}
                    href={intl.msgJoint(Message.LINK_URL)}
                    target="_blank"
                >
                    {intl.msgJoint(Message.LINK_TEXT)}
                </a>
            </p>
        </LabeledQuestionTip>
    )
);
