import React from 'react';
import { type TwitterComponent } from '../types';

export const Tweet = (props: TwitterComponent) => {
    return (
        <blockquote
            className="twitter-tweet"
            data-lang={props.locale}
            data-theme={props.theme}
            dangerouslySetInnerHTML={{ __html: props.tweetHTML || "" }}
        />
    );
};
