import { WIZARD_STEP_ID } from "./constants";
import { WizardSteps } from "./view/Steps";
import { stepChangeAction } from "./Epic/actionCreators/actions";
import { TRACK_WIZARD_STEP_CHANGED } from "./Epic/actionTypes";

const showOptionSetStepForTrial = (optionSet, shopDisabled) => !optionSet || (shopDisabled && optionSet?.key === 'shop');

const wizardStepRemoveConditions = ({ concept, isNewGmbKey, shopDisabled, isGroup, isGroupSet }) => {
    // if the statement is false only then we show the step
    const { optionSet } = concept;
    return {
        [WIZARD_STEP_ID.WEBSITE_PURPOSE]: showOptionSetStepForTrial(optionSet, shopDisabled),
        [WIZARD_STEP_ID.CATCH_ALL]: !isNewGmbKey,
        [WIZARD_STEP_ID.PRONOUNS]: !(isGroup === undefined || isGroupSet)
    };
};

const getWizardSteps = (options) => {
    let steps = WizardSteps;
    const conditionsMap = wizardStepRemoveConditions(options);

    return steps.filter(step => {
        if (step.id in conditionsMap && conditionsMap[step.id]) {
            return false;
        }
        return true;
    });
};

const getMultiStepWizard = (steps, currentStep, isLoading, dispatch) => {
    const goNext = () => {
        if (isLoading) return;
        const { id: currentStepId } = steps[currentStep] || {};
        const nextStep = currentStep >= steps.length - 1 ? currentStep : currentStep + 1;
        dispatch({ type: TRACK_WIZARD_STEP_CHANGED, payload: currentStepId });
        dispatch(stepChangeAction(nextStep));
    };

    const goBack = () => {
        if (isLoading) return;
        const nextStep = currentStep <= 0 ? currentStep : currentStep - 1;
        dispatch(stepChangeAction(nextStep));
    };

    return {
        currentStep,
        isFirstStep: currentStep === 0,
        step: steps[currentStep],
        isLastStep: currentStep === steps.length - 1,
        totalSteps: steps.length - 1, // since first step is not considered as a step
        steps,
        goNext,
        goBack,
    };
};

const createContextValue = (state, dispatch) => {
    const {
        gmbKey,
        businessName,
        keywords,
        concept,
        conceptMap,
        contactInfo,
        purpose,
        isGroup,
        isGroupSet,
        catchAllConceptKey,
        isNewGmbKey,
        isLoading,
        lastStepOpened,
        agreeToTerms,
        shopDisabled,
        showGenericConcept
    } = state;
    const steps = getWizardSteps(state);

    const {
        isFirstStep, currentStep, isLastStep,
        goBack, goNext, totalSteps,
        step,
        step: { skippable },
    } = getMultiStepWizard(steps, lastStepOpened, isLoading, dispatch);

    return {
        wizardState: { step, isFirstStep, isLastStep, currentStep, goBack, goNext, totalSteps, skippable },
        gmbKey,
        businessName,
        keywords,
        concept,
        conceptMap,
        contactInfo,
        purpose,
        isGroup,
        isGroupSet,
        isNewGmbKey,
        catchAllConceptKey,
        isLoading,
        agreeToTerms,
        shopDisabled,
        showGenericConcept
    };
};

export {
    getWizardSteps,
    getMultiStepWizard,
    createContextValue
};
