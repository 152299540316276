import {
    FacebookFeedGallerySquareType,
} from "./kind";
import { IMAGE_RATIO } from "../../presentational/ImageRatioComboBox/constants";

const TypePropsConfig = {
    [FacebookFeedGallerySquareType]: {
        imageRatio: IMAGE_RATIO.SQUARE
    },
};

export default TypePropsConfig;
