import cx from 'classnames';
import renderWrapHtml from './renderWrapHtml';
import getColumnDimensions from './getColumnDimensions';
import type { AnyComponent } from '../../../flowTypes';

const DEFAULT_RIGHT_MARGIN_OF_LEFT_WRAPPED = 10;
const DEFAULT_LEFT_MARGIN_OF_RIGHT_WRAPPED = 10;
const COMBINED_MARGINS = DEFAULT_RIGHT_MARGIN_OF_LEFT_WRAPPED + DEFAULT_LEFT_MARGIN_OF_RIGHT_WRAPPED;

const styleUpdaters = {
    left: (component) => {
        return {
            'margin-left': `${component.relIn.left}px`,
            'margin-right': 'auto'
        };
    },
    right: (component) => {
        return {
            'margin-left': 'auto',
            'margin-right': `${-component.relIn.right}px`
        };
    },
    center: (component, scope) => {
        const styles = {
            'margin-left': `${component.relIn.left - scope.left}px`,
            'margin-right': `${scope.right - component.relIn.right}px`
        };

        return (styles['margin-left'] === styles['margin-right']) ? {
            'margin-left': 'auto',
            'margin-right': 'auto'
        } : styles;
    }
};

const getStyles = (alignment, component, scope) => {
    const
        // $FlowFixMe: component will have relPara and offset here
        marginTop = component.relPara.offset - scope.height,
        styles = {
            'margin-top': `${marginTop}px`,
            ...styleUpdaters[alignment](component, scope)
        };

    scope.height += component.height + marginTop; // eslint-disable-line
    return JSON.stringify(styles).replace(/['"{}\s]*/g, '').replace(/,/g, ';') + ';';
};

const getHtmlWithWrapper = (alignment: string, width: number, html: string): string => {
    const classAttr = cx('mceNonEditable', { [alignment]: true });

    return (
        `<div class="${classAttr}" style="width:${width}px;">` +
            html +
        `</div>`
    );
};

// Should be used only when relIn is the source of truth
export const getWrapperHtmlWithRelIn = (
    alignment: string,
    components: Array<AnyComponent>,
    paraScope: Record<string, any>
): string => {
    const
        { left, right, width, wrapperWidth } = getColumnDimensions(alignment, components),
        scope = { left, right, height: paraScope.height };

    if (alignment === 'center' ||
        (paraScope.prevWidth && (paraScope.prevWidth + width + COMBINED_MARGINS) >= wrapperWidth)) {
        scope.height = paraScope.prevHeight;
    }

    const wrappedComponentsHtml = components.reduce((html, component) => {
        const styles = getStyles(alignment, component, scope);
        return html + renderWrapHtml(alignment, component, styles);
    }, '');

    if (alignment === 'center') {
        paraScope.height = scope.height; //eslint-disable-line
        paraScope.prevWidth = 0; //eslint-disable-line
        paraScope.prevHeight = 0; //eslint-disable-line
    } else {
        if (!paraScope.prevHeight || (paraScope.prevWidth + width + COMBINED_MARGINS) >= wrapperWidth) {
            paraScope.prevHeight = scope.height; //eslint-disable-line
        } else {
            paraScope.prevHeight = Math.max(paraScope.prevHeight, scope.height); //eslint-disable-line
        }
        paraScope.prevWidth = width; //eslint-disable-line
    }

    return getHtmlWithWrapper(alignment, width, wrappedComponentsHtml);
};

// Should be used when relIn is not the source of truth, used on initial wrap
export const getWrapperHtmlWithoutRelIn = (alignment: string, component: AnyComponent) => {
    const wrappedComponentHtml = renderWrapHtml(alignment, component);
    return getHtmlWithWrapper(alignment, component.width, wrappedComponentHtml);
};
