/* @flow */

const { TRIAL_DOMAIN_SEPARATOR } = require("../constants.js");

const makeTrialDomainString = (userIdentityId/*: string */, trialDomainName/*: string */)/*: string */ => [
    userIdentityId,
    TRIAL_DOMAIN_SEPARATOR,
    trialDomainName,
].join('');

/**
 * Returns [trialUserIdentityId, trialDomain].
 */
const parseTrialDomainString = (domainString/*: string */)/*: [string, string] */ =>
    // $FlowFixMe
    domainString.split(TRIAL_DOMAIN_SEPARATOR);

module.exports = {
    makeTrialDomainString,
    parseTrialDomainString,
};
