import React from 'react';
import getCenteredDialogConfig from "../../../DialogManager/getCenteredDialogConfig";
import ErrorDialog from '../../../../view/common/dialogs/WarningDialog/index';
import { Msg } from "../../../../view/intl/index";
import * as Actions from "../../actionTypes";

const
    ImportErrorDialogId = 'ImportErrorDialogId',
    height = 350,
    width = 480,
    Dialog = ({ dispatch }) => {
        const mctaHandler = () => {
            dispatch({ type: Actions.IMPORT_FAILED_INFO_DIALOG_CLOSED });
        };
        return (
            <ErrorDialog
                title="msg: templateSelector.error.title {Sorry, something went wrong}"
                mctaText="msg: common.ok {OK}"
                mctaHandler={mctaHandler}
                noTopBorderForFooter
            >
                <div>
                    <p>
                        <b>
                            <Msg k="templateSelector.error.unableToRetrieve">
                                Unable to retrieve repository template.
                            </Msg>
                        </b>
                    </p>
                    <p>
                        <Msg k="templateSelector.error.contactSupport">
                            Please try again later or contact our support for further assistance.
                        </Msg>
                    </p>
                </div>
            </ErrorDialog>
        );
    },
    ImportErrorDialog = { ...getCenteredDialogConfig(width, height, Dialog) };

export { ImportErrorDialog, ImportErrorDialogId };
