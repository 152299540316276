import React from "react";
import cx from "classnames";
import styles from './OptionsSet.css';
import CheckBox from "../../../../../../../view/common/CheckBox/PropertiesCheckBox";
import { RadioButton } from "../../../../../../../view/common/RadioButton";
import { OptionSetProps } from "./flowTypes";
import NextGenIcon from "../Icons/NextGenIcons";

const OptionsSet = (props: OptionSetProps) => {
    const { options, selectedOptions = [], onSelectionChange, multiSelect = false, concept } = props;

    const onSelectOrUnselect = (e, option) => {
        const { value } = option;
        if (e && e.stopPropagation) {
            e.stopPropagation();
        }
        if (!multiSelect) {
            onSelectionChange([value]);
            return;
        }
        if (selectedOptions.includes(value)) {
            onSelectionChange(selectedOptions.filter((s) => s !== value));
            return;
        }
        selectedOptions.push(value);
        onSelectionChange(selectedOptions);
    };
    const OptionsView = () => options.map((option, i) => {
        const { label, description, value, noTranslation } = option;
        const isChecked = selectedOptions.includes(value);
        const onClick = (e) => onSelectOrUnselect(e, option);
        return <div className={cx(styles.option, props.optionClass, { [styles.selected]: isChecked })} onClick={onClick} key={i}>
            {
                multiSelect ? <CheckBox
                    label={label}
                    // @ts-ignore
                    value={value}
                    isHtmlLabel={noTranslation}
                    isChecked={isChecked}
                    onClick={onClick}
                    className={styles.label}
                    containerClassName={styles.checkBoxContainer}
                    checkboxClass={cx(styles.checkBox, { [styles.checked]: isChecked })}
                /> : <RadioButton
                    label={label}
                    description={description}
                    isHtmlLabel={noTranslation}
                    value={value}
                    // @ts-ignore
                    onChange={onClick}
                    isChecked={isChecked}
                    checkedRadioButtonContainerClassName={styles.checked}
                    className={styles.radioBtnContainer}
                    labelClassName={styles.radioBtnLabelContainer}
                    labelWrapperClassName={styles.radioBtnLabelWrapper}
                    labelTextClassName={styles.label}
                    descriptionTextClassName={styles.description}
                    inputClass={styles.radioButtonStyles}
                />
            }
            <div className={styles.optionImage}>
                <NextGenIcon iconName={value} concept={concept} />
            </div>
        </div>;
    });
    return (
        <div className={cx(styles.optionSet, props.className)}>
            { OptionsView() }
        </div>
    );
};

export {
    OptionsSet,
};
