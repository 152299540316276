import { DefaultLocale } from '../constants';

export const
    DarkTheme = 'dark',
    LightTheme = 'light',
    TWEET_DEFAULT_WIDTH = 400,
    TWEET_DEFAULT_HEIGHT = 600,
    DEFAULT_WIDTH = 500,
    DEFAULT_HEIGHT = 1000,
    Themes = {
        [`${LightTheme}`]: 'msg: widgets.twitterTimeline.pp.theme.light {Light}',
        [`${DarkTheme}`]: 'msg: widgets.twitterTimeline.pp.theme.dark {Dark}',
    },
    EmbedTypes = ['tweet', 'profile', 'list', 'handle', 'hashtag'],
    TwitterLinks = {
        timelineLink: "",
        listLink: "",
        tweetHTML: "",
    },
    defaultState = {
        ...TwitterLinks,
        theme: null,
        locale: DefaultLocale,
        doNotTrack: false,
        mobileHide: false,
    },
    TwitterTimelineSDK = "https://platform.twitter.com/widgets.js",
    DATA_TEST_ID = 'twitter-widget';

