export const SHOW_PRODUCT_WIDGET_PRODUCT_SELECTOR_DIALOG = 'SHOW_PRODUCT_WIDGET_PRODUCT_SELECTOR_DIALOG';
export const HIDE_PRODUCT_WIDGET_PRODUCT_SELECTOR_DIALOG = 'HIDE_PRODUCT_WIDGET_PRODUCT_SELECTOR_DIALOG';
export const PRODUCT_WIDGET_SET_ITEM = 'PRODUCT_WIDGET_SET_ITEM';
export const PRODUCT_WIDGET_UPDATE_PRODUCT = 'PRODUCT_WIDGET_UPDATE_PRODUCT';
export const PRODUCT_WIDGET_CTA_CLICKED = 'PRODUCT_WIDGET_CTA_CLICKED';
export const PRODUCT_WIDGET_UPDATE_SHOP_SETUP_STATUS = 'PRODUCT_WIDGET_UPDATE_SHOP_SETUP_STATUS';
export const PRODUCT_WIDGET_UPDATE_PRODUCT_SETUP_INSERTER_METHOD = 'PRODUCT_WIDGET_UPDATE_PRODUCT_SETUP_INSERTER_METHOD';
export const PRODUCT_WIDGET_SHOW_PRODUCT_WIDGET_INSERTER_TOOLTIP = 'PRODUCT_WIDGET_SHOW_PRODUCT_WIDGET_INSERTER_TOOLTIP';
export const PRODUCT_WIDGET_SHOW_MANAGE_SHOP_INSERTER_TOOLTIP = 'PRODUCT_WIDGET_SHOW_MANAGE_SHOP_INSERTER_TOOLTIP';
export const PRODUCT_WIDGET_ONBOARDING_SUCCESS = 'PRODUCT_WIDGET_ONBOARDING_SUCCESS';
export const PRODUCT_WIDGET_UPDATE_SETUP_SHOP_STATUS = 'PRODUCT_WIDGET_UPDATE_SETUP_SHOP_STATUS';
export const PRODUCT_WIDGET_UPDATE_PRODUCT_SETUP_WIDGET_METHOD = 'PRODUCT_WIDGET_UPDATE_PRODUCT_SETUP_WIDGET_METHOD';
export const PRODUCT_WIDGET_COMPONENT_DROPPED = 'PRODUCT_WIDGET_COMPONENT_DROPPED';
export const PRODUCT_WIDGET_SETUP_PRODUCT_TOOLTIP_POSITION_UPDATED = 'PRODUCT_WIDGET_SETUP_PRODUCT_TOOLTIP_POSITION_UPDATED';
export const PRODUCT_WIDGET_SHOW_SETUP_PRODUCT_TOOLTIP = 'PRODUCT_WIDGET_SHOW_SETUP_PRODUCT_TOOLTIP';
