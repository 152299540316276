/* eslint-disable max-len */

import * as R from 'ramda';
import * as colorMapper from '../../../../dal/pageMapAdapter/mappers/Base/color';
import type { Color } from "../../../mappers/flowTypes";
import {
    BLACK_THEME_COLOR,
    WHITE_THEME_COLOR,
    THEME_TEXT_CLASS,
} from "../constants";
import { getNeededColorsForColorTheme } from "./getNeededColorsForColorTheme";
import type {
    ThemeColorTypes,
    BlackThemeColorType,
    WhiteThemeColorType,
    ThemeColorDataType,
    ThemeBackgroundType
} from "../flowTypes";
import { depthSorter } from "../../Preview/flattening/util";
import type { ComponentsMap } from "../../../redux/modules/children/workspace/flowTypes";
import { isBackgroundComponentKind } from "../../oneweb/isBackgroundComponentKind";
import { getParentThemeForComponent } from "./getParentThemeMap";
import { findMostRelatedColor, getCorrectedColor, isExactEqualColors } from "../../../utils/colorUtils";
import p from "../../../utils/pipePath";
import { styleBackgroundColor } from "../../../mappers/path";
import { getThemeFromBackgroundColor, getThemeRulesForBackground } from "../themeRules";
import Background from "../../oneweb/Background/kind";
import { ButtonKind } from "../../oneweb/Button/kind";
import { styleGlobalNameSelector } from "../../oneweb/Button/selectors";
import { getMigratedButtonThemeForAutoColorChange } from "../../oneweb/Button/utils";
import { ContactFormKind } from "../../oneweb/ContactForm/kind";
import { styleButtonGlobalNameSelectorForContactForm } from "../../oneweb/ContactForm/selectors";
import { GOOGLE_REVIEWS } from '../../oneweb/componentKinds';

/**
 * Ref: https://webaim.org/resources/contrastchecker/
 * Ref: https://webaim.org/resources/contrastchecker/contrast.js
 */
const
    getsRGB = (c) => {
        // Calculation needed for finding actual L for HSL
        return (c <= 0.03928) ? c / 12.92 : Math.pow(((c + 0.055) / 1.055), 2.4);
    },
    getL = (c) => {
        return ((0.2126 * getsRGB(c._red)) + (0.7152 * getsRGB(c._green)) + (0.0722 * getsRGB(c._blue)));
    },
    findContrastRatio = (c1: any, c2: any): number => {
        const
            c1rgb = colorMapper.toRgb(c1),
            c2rgb = colorMapper.toRgb(c2),
            L1 = getL(c1rgb),
            L2 = getL(c2rgb);
        if (L1 > L2) {
            return (L1 + 0.05) / (L2 + 0.05);
        } else {
            return (L2 + 0.05) / (L1 + 0.05);
        }
    },
    findSuitableTextColor = (interestedColor: Color, c1: Color, c2: Color) => {
        return findContrastRatio(interestedColor, c1) > findContrastRatio(interestedColor, c2) ? c1 : c2;
    },
    findSuitableTextColorName =
        (interestedColorName: ThemeColorTypes, themeColorsData: ThemeColorDataType): BlackThemeColorType|WhiteThemeColorType => {
            if (R.isEmpty(themeColorsData) || R.isNil(themeColorsData)) {
                // TODO: WBTGEN-16602: themeColorsData should not be empty here.
                return BLACK_THEME_COLOR;
            }
            const suitableColor = findSuitableTextColor(themeColorsData[interestedColorName], themeColorsData.whiteColor, themeColorsData.blackColor);
            return suitableColor === themeColorsData.blackColor ? BLACK_THEME_COLOR : WHITE_THEME_COLOR;
        },
    findInverseTextColorName =
        (interestedColorName: BlackThemeColorType|WhiteThemeColorType): BlackThemeColorType|WhiteThemeColorType => {
            return interestedColorName === BLACK_THEME_COLOR ? WHITE_THEME_COLOR : BLACK_THEME_COLOR;
        },
    createSelectedParentThemeWithThemeTextClass = ({ selectedParentTheme }: {
        selectedParentTheme: any
    }): string => {
        return selectedParentTheme ? `${selectedParentTheme} ${THEME_TEXT_CLASS}` : '';
    },
    /*
        baseColor = background color
        currentColor = color which would have applied without seeing contrast ratio
        colorsArray = alternate colors
        escapeColors = colors which should be escaped from colorsArray
    */
    findSuitableContrastColor = ({
        baseColor,
        currentColor,
        colorsArray = [],
        escapeColors = [],
        contrastRatioThreshold = 4,
    }: {
        baseColor: Color,
        currentColor: Color,
        colorsArray?: Array<Color>,
        escapeColors?: Array<Color>,
        contrastRatioThreshold?: number,
    }): Color => {
        let contrastRatio = findContrastRatio(baseColor, currentColor);

        if (contrastRatio > contrastRatioThreshold) {
            return currentColor;
        }

        const
            avoidColors = [...escapeColors, baseColor],
            candidateColors = colorsArray.filter(color => {
                if (!avoidColors.includes(color)) {
                    return true;
                }

                return false;
            });

        let
            maxContrastRatio = contrastRatio,
            maxContrastRatioColor = currentColor;

        for (let i = 0; i < candidateColors.length; i++) {
            contrastRatio = findContrastRatio(baseColor, candidateColors[i]);

            if (contrastRatio >= contrastRatioThreshold) {
                return candidateColors[i];
            }

            if (contrastRatio > maxContrastRatio) {
                maxContrastRatio = contrastRatio;
                maxContrastRatioColor = candidateColors[i];
            }
        }

        return maxContrastRatioColor;
    },
    findSuitableContrastColorName = ({
        baseColorName,
        currentColorName,
        colorsNameArray,
        escapeColorsName = [],
        themeColorsData,
        contrastRatioThreshold = 4,
    }: {
        baseColorName: ThemeColorTypes,
        currentColorName: ThemeColorTypes,
        colorsNameArray: Array<ThemeColorTypes>,
        escapeColorsName?: Array<ThemeColorTypes>,
        themeColorsData: ThemeColorDataType,
        contrastRatioThreshold?: number,
    }): {
        contrastColorName: ThemeColorTypes,
        contrastColor: Color
    } => {
        const
            baseColor = themeColorsData[baseColorName],
            currentColor = themeColorsData[currentColorName],
            colorsArray = colorsNameArray.map((name) => themeColorsData[name]),
            escapeColors = escapeColorsName.map((name) => themeColorsData[name]),
            contrastColor = findSuitableContrastColor({
                baseColor,
                currentColor,
                colorsArray,
                escapeColors,
                contrastRatioThreshold,
            }),
            allColorsNameArray = [currentColorName, ...colorsNameArray];
        const contrastColorName = allColorsNameArray.find((name) => contrastColor === themeColorsData[name]);
        // @ts-ignore: WBTGEN-16368
        return { contrastColorName, contrastColor };
    },
    updateComponentsTheme = ({
        componentsMap: componentsMapIn,
        cmpIds,
        templateSelectedTheme,
        componentsDependencies
    }: {
        componentsMap: ComponentsMap,
        cmpIds: Array<string>,
        templateSelectedTheme: ThemeBackgroundType,
        componentsDependencies: Object
    }) => {
        const
            bgComponentDependencies = componentsDependencies[Background],
            { themeSettingsData: { autoColorMode } } = bgComponentDependencies;

        if (autoColorMode) {
            let componentsMap = { ...componentsMapIn };
            const
                { themeColorsData } = bgComponentDependencies,
                newCmpsMap = cmpIds.map(id => componentsMap[id]),
                newBackgroundCmpsMap = newCmpsMap.filter((cmp) => isBackgroundComponentKind(cmp.kind)),
                newBgCmpsListSorted = newBackgroundCmpsMap.sort(depthSorter);

            /* Background: No color and no image on the bgCmp. Assign the parent theme and make the opacity zero.*/
            /* No color can be no color or color with opacity zero.*/
            /* Identifying text color from image is pending. */
            /* Add the selectedTheme and update in componentsMap. */
            /* WBTGEN-17362: Don't change the existing selected theme of bgCmps if present for preview.
            *  We are not considering existing selected theme for inserters.
            *  The function is used for inserters preview, drag and drop component.*/
            newBgCmpsListSorted.forEach((bgCmp) => {
                let newBgCmp;
                const
                    { id, selectedTheme: existingSelectedTheme } = bgCmp,
                    color = getCorrectedColor(R.path(p(styleBackgroundColor))(bgCmp));
                if (!existingSelectedTheme) {
                    if (!color || !color[4]) {
                        const selectedTheme = getParentThemeForComponent(bgCmp, componentsMap, templateSelectedTheme);
                        const newColor = [...themeColorsData[getThemeRulesForBackground(selectedTheme, themeColorsData).background].slice(0, 4), 0];
                        newBgCmp = R.pipe(
                            R.assocPath(p(styleBackgroundColor), newColor),
                            R.assocPath(['selectedTheme'], selectedTheme)
                        )(bgCmp);
                    } else {
                        // @ts-ignore
                        let matchingColorName: undefined | ThemeColorTypes = Object.keys(themeColorsData).find((key: ThemeColorTypes) => isExactEqualColors(color, themeColorsData[key]));
                        if (!matchingColorName) {
                            matchingColorName = findMostRelatedColor(color, themeColorsData);
                        }
                        // @ts-ignore
                        newBgCmp = R.assocPath(['selectedTheme'], getThemeFromBackgroundColor(matchingColorName))(bgCmp);
                    }
                    componentsMap = {
                        ...componentsMap,
                        [id]: { ...newBgCmp }
                    };
                }
            });
            /* Button, Google Reviews and contact form button */
            const newButtonCmpsMap = newCmpsMap.filter((cmp) => cmp.kind === ButtonKind || cmp.kind === ContactFormKind || cmp.kind === GOOGLE_REVIEWS);
            const modifiedButtonCmpsMap = {};
            newButtonCmpsMap.forEach((btnCmp) => {
                const
                    { id, buttonThemeSelected: buttonThemeSelectedIn } = btnCmp,
                    globalName = styleGlobalNameSelector(btnCmp) || styleButtonGlobalNameSelectorForContactForm(btnCmp);
                if (!buttonThemeSelectedIn) {
                    // TODO WBTGEN-16602: Map the theme based on styleId. Style Global name is corrupted for some buttons in our templates. Eg. Handcrafted KnitWears
                    const
                        { buttonThemeSelected } = getMigratedButtonThemeForAutoColorChange({
                            autoColorMode,
                            buttonThemeSelected: null,
                            globalName
                        });
                    modifiedButtonCmpsMap[id] = { ...btnCmp, buttonThemeSelected };
                }
            });

            componentsMap = {
                ...componentsMap,
                ...modifiedButtonCmpsMap
            };
            return componentsMap;
        }

        return componentsMapIn;
    };

export {
    findContrastRatio,
    getNeededColorsForColorTheme,
    createSelectedParentThemeWithThemeTextClass,
    findSuitableTextColor,
    findSuitableTextColorName,
    findInverseTextColorName,
    findSuitableContrastColor,
    findSuitableContrastColorName,
    updateComponentsTheme,
};
