import React, { useEffect, useRef } from "react";
import cx from "classnames";
import { Msg, Intl } from "../../../view/intl/index";
import { PrimaryButton, ContinueButton } from "../../../view/common/Button/index";
import HorizontalSpacer from "../../../view/common/HorizontalSpacer";
import styles from "./Buttons.css";
import type { ReactElementRef } from "../../../globalTypes";

type ButtonProps = {
    mctaRef?: ReactElementRef<any>;
    mctaDisabled: boolean;
    mctaText?: string;
    mctaHandler: (...args: Array<any>) => any;
    sctaHandler?: (...args: Array<any>) => any;
    tctaHandler?: (...args: Array<any>) => any;
    intl: Intl;
    className?: string;
};
const DefaultMctaText = "msg: common.next {Next}";

const Buttons = (props: ButtonProps) => {
    const { mctaRef, mctaDisabled, mctaHandler, sctaHandler, tctaHandler, intl, className } = props,
        mctaText = props.mctaText || DefaultMctaText,
        isOnClickAvailable = useRef(false),
        onClickHandler = handler => {
            if (isOnClickAvailable.current) {
                handler();
            }
        };

    useEffect(() => {
        setTimeout(() => {
            isOnClickAvailable.current = true;
        }, 0);
    }, []);
    return (
        <div className={cx(styles.nextSteps, className)}>
            {sctaHandler && (
                <React.Fragment>
                    <ContinueButton className={styles.button} onClick={() => onClickHandler(sctaHandler)} label="msg: common.back {Back}" />
                    <HorizontalSpacer x={17} />
                </React.Fragment>
            )}
            <PrimaryButton ref={mctaRef} className={styles.button} disabled={mctaDisabled} onClick={() => onClickHandler(mctaHandler)}>
                {intl.msgJoint(mctaText)}
            </PrimaryButton>
            {tctaHandler && (
                <React.Fragment>
                    <HorizontalSpacer x={17} />
                    {/* eslint-disable-next-line no-script-url */}
                    <a className={styles.later} href="javascript:void(0);" onClick={() => onClickHandler(tctaHandler)}>
                        <Msg k="onboarding.addThisLater">I'll add this later</Msg>
                    </a>
                </React.Fragment>
            )}
        </div>
    );
};

export { Buttons };
