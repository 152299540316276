import kind from './kind';
import propertiesPanel from './propertiesPanel/index';
import { colorThemeSiteSettingsEpic } from "../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import stylesheetsEpic from "../../Workspace/epics/stylesheets/index";
import makeStateSelectorReducer from "../../../epics/makeStateSelectorReducer";
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";

export default {
    kind,
    propertiesPanel,
    controlsDependsOn: {
        themeColorsData: makeStateSelectorReducer(
            stylesheetsEpic.reducer,
            stylesheetsEpic.valueActionType,
            getThemeColorsFromStylesheet
        ),
        themeSettingsData: colorThemeSiteSettingsEpic.reducer,
    }
};
