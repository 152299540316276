import * as R from 'ramda';
import p from '../../../utils/pipePath';
import opacityToAlpha from '../../oneweb/Background/utils/opacityToAlpha';
import { overPathAlways, setToPath } from '../../../utils/ramdaEx';
import { TextTransform } from './constants';
import { DEFAULT_SHADOW } from "../../oneweb/Text/constants";
import MenuGlobalStyleKind from "../../oneweb/Menu/globalStyle/kind";
import * as gradientSetters from '../../../setters/gradientSetter';
import * as bgSetters from '../../../setters/backgroundSetter';
import * as borderSetters from '../../../setters/borderSetter';
import * as path from '../../../mappers/path';
import { GS_MENU_EXPANDABLE_SHOW_DROPDOWN_CHECKBOX_PRESSED } from './actionTypes';
import type { Path } from '../../../mappers/path'; // eslint-disable-line
import type { MenuStylesheet } from '../../Workspace/epics/stylesheets/flowTypes';
import type { MenuStylesheetEpicUpdater } from '../flowTypes';
import * as fontFamilyMapper from "../../../../dal/pageMapAdapter/mappers/Base/fontFamily";
import { BorderStyles } from '../../presentational/BorderStyle/constants';

type EpicUpdaterFactory = (actionType: string, paths: MapT<Path>) => MenuStylesheetEpicUpdater;

type ActionEpicUpdaterFactory = (actionType: string) => MenuStylesheetEpicUpdater
type TransformEpicUpdaterFactory =
    (actionType: string, paths: MapT<Path>, transform: string) => MenuStylesheetEpicUpdater
type OptPathsEpicUpdterFactory = (actionType: string, paths?: MapT<Path> | undefined) => MenuStylesheetEpicUpdater

type Update = {
    paths: Record<string, any>,
    pathPart: Path,
    updaterFn: Function
}

type UpdateInPath = {
    paths: Record<string, any> | undefined,
    updaterFn: Function,
    pathToUpdate: Path,
    dataToUpdate: any,
    stylesheet: MenuStylesheet
}

const
    update = ({ paths, pathPart, updaterFn }: Update) => overPathAlways(
        Object.keys(paths).reduce((prev: Array<any>, key: string) => [...prev, [key, paths[key], ...pathPart]], [])
    )(updaterFn),
    updateTextShadow = (paths, stylesheet, shadowPropToUpdate) => {
        const
            key1 = R.pipe(R.keys, R.head)(paths),
            key2 = R.head(paths[key1]),
            textShadow = R.path(p(key1, key2, path.textShadow))(stylesheet);

        return update({
            paths,
            pathPart: path.textShadow,
            updaterFn: R.always({ ...DEFAULT_SHADOW, ...textShadow, ...shadowPropToUpdate })
        })(stylesheet);
    },
    updateInPaths = ({ paths, updaterFn, pathToUpdate, dataToUpdate, stylesheet }: UpdateInPath) => {
        let ss = stylesheet;

        if (paths) {
            if (R.isNil(dataToUpdate)) {
                Object.keys(paths).forEach(key1 => {
                    paths[key1].forEach(key2 => {
                        ss = updaterFn(p(key1, key2, pathToUpdate), ss);
                    });
                });
            } else {
                Object.keys(paths).forEach(key1 => {
                    paths[key1].forEach(key2 => {
                        ss = updaterFn(p(key1, key2, pathToUpdate), dataToUpdate, ss);
                    });
                });
            }
            return ss;
        } else if (dataToUpdate) {
            return updaterFn(pathToUpdate, dataToUpdate, stylesheet);
        }

        return updaterFn(pathToUpdate, stylesheet);
    };

export const
    googleFontEpicUpdater: EpicUpdaterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [{ googleFont, additionalPayload }], state: stylesheet }) => {
            if (additionalPayload && additionalPayload.source === MenuGlobalStyleKind) {
                return {
                    state: update({
                        paths,
                        pathPart: path.textFont,
                        updaterFn: R.always(fontFamilyMapper.to(googleFont))
                    })(stylesheet)
                };
            }

            return { state: stylesheet };
        }
    }),
    fontFamilyEpicUpdater: EpicUpdaterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [fontFamily], state: stylesheet }) => ({
            state: update({ paths, pathPart: path.textFont, updaterFn: R.always(fontFamily) })(stylesheet)
        })
    }),
    fontSizeEpicUpdater: EpicUpdaterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [size], state: stylesheet }) => ({
            state: update({ paths, pathPart: path.textSize, updaterFn: R.always(size) })(stylesheet)
        })
    }),
    toggleBoldEpicUpdater: EpicUpdaterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: update({ paths, pathPart: path.textBold, updaterFn: R.not })(stylesheet)
        })
    }),
    toggleItalicEpicUpdater: EpicUpdaterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: update({ paths, pathPart: path.textItalic, updaterFn: R.not })(stylesheet)
        })
    }),
    textAlignmentEpicUpdater: EpicUpdaterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [alignment], state: stylesheet }) => ({
            state: update({ paths, pathPart: [path.horizontalAlign], updaterFn: R.always(alignment) })(stylesheet)
        })
    }),
    textTransformEpicUpdater: TransformEpicUpdaterFactory = (action, paths, transform) => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => {
            const
                textTransform = R.path(p(path.item, path.inactive, path.textTransform), stylesheet),
                finalTextTransform = transform === textTransform ? TextTransform.none : transform;

            let ss = stylesheet;
            Object.keys(paths).forEach(path1 => {
                paths[path1].forEach(path2 => {
                    ss = R.assocPath([path1, path2, ...path.textTransform], finalTextTransform, ss);
                });
            });
            return { state: ss };
        }
    }),
    borderStyleEpicUpdater: OptPathsEpicUpdterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [style], state: stylesheet }) => {
            let updatedStylesheet = updateInPaths({
                paths,
                updaterFn: borderSetters.setBorderStyle,
                pathToUpdate: path.blockBorder,
                dataToUpdate: style,
                stylesheet
            });

            if (style !== BorderStyles.NONE) {
                const width = R.pathOr(
                    null,
                    [path.item, path.inactive, path.block, path.border, path.width],
                    updatedStylesheet
                );

                if (width && width.every(item => !item)) {
                    const initialBorderWidth = [1, 1, 1, 1];
                    updatedStylesheet = updateInPaths({
                        paths,
                        updaterFn: borderSetters.setBorderWidths,
                        pathToUpdate: path.blockBorder,
                        dataToUpdate: initialBorderWidth,
                        stylesheet: updatedStylesheet
                    });
                }
            }

            return {
                state: updatedStylesheet
            };
        }
    }),
    borderColorEpicUpdater: OptPathsEpicUpdterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [{ color }], state: stylesheet }) => ({
            state: updateInPaths({
                paths,
                updaterFn: borderSetters.setBorderColor,
                pathToUpdate: path.blockBorder,
                dataToUpdate: color,
                stylesheet
            })
        })
    }),
    borderColorOpacityEpicUpdater: OptPathsEpicUpdterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [opacity], state: stylesheet }) => ({
            state: updateInPaths({
                paths,
                updaterFn: borderSetters.setBorderOpacity,
                pathToUpdate: path.blockBorder,
                dataToUpdate: opacity,
                stylesheet
            })
        })
    }),
    borderColorRemoveEpicUpdater: OptPathsEpicUpdterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: updateInPaths({
                paths,
                updaterFn: borderSetters.unsetBorderColor,
                pathToUpdate: path.blockBorder,
                dataToUpdate: null,
                stylesheet
            })
        })
    }),
    borderWidthEpicUpdater: OptPathsEpicUpdterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [borderWidth], state: stylesheet }) => ({
            state: R.all(R.is(Number), borderWidth)
                ? updateInPaths({
                    paths,
                    updaterFn: borderSetters.setBorderWidths,
                    pathToUpdate: path.blockBorder,
                    dataToUpdate: borderWidth,
                    stylesheet
                })
                : stylesheet
        })
    }),
    borderRadiusEpicUpdater: OptPathsEpicUpdterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [corners], state: stylesheet }) => ({
            state: updateInPaths({
                paths,
                updaterFn: borderSetters.setBorderCorners,
                pathToUpdate: path.blockBorder,
                dataToUpdate: corners,
                stylesheet
            })
        })
    }),
    spacingEpicUpdater: OptPathsEpicUpdterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [spacing], state: stylesheet }) => {
            return paths
                ? { state: update({ paths, pathPart: path.blockPadding, updaterFn: R.always(spacing) })(stylesheet) }
                : { state: R.assocPath(path.blockPadding, spacing, stylesheet) };
        }
    }),
    horizontalSpacingEpicUpdater: OptPathsEpicUpdterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [spacing], state: stylesheet }) => {
            return paths
                ? { state: R.pipe(update({ paths, pathPart: path.blockPaddingLeft, updaterFn: R.always(spacing[3]) }),
                    update({ paths, pathPart: path.blockPaddingRight, updaterFn: R.always(spacing[1]) }))(stylesheet) }
                : { state: R.pipe(R.assocPath(path.blockPaddingLeft, spacing[3]),
                    R.assocPath(path.blockPaddingRight, spacing[1]))(stylesheet) };
        }
    }),
    verticalSpacingEpicUpdater: OptPathsEpicUpdterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [spacing], state: stylesheet }) => {
            return paths
                ? { state: R.pipe(update({ paths, pathPart: path.blockPaddingTop, updaterFn: R.always(spacing[0]) }),
                    update({ paths, pathPart: path.blockPaddingBottom, updaterFn: R.always(spacing[2]) }))(stylesheet) }
                : { state: R.pipe(R.assocPath(path.blockPaddingTop, spacing[0]),
                    R.assocPath(path.blockPaddingBottom, spacing[2]))(stylesheet) };
        }
    }),
    showDropDownPressedUpdater: MenuStylesheetEpicUpdater = {
        conditions: [GS_MENU_EXPANDABLE_SHOW_DROPDOWN_CHECKBOX_PRESSED],
        reducer: ({ state: stylesheet }) => {
            return {
                state: R.assocPath(
                    path.showDropDownArrowPath,
                    !R.path(path.showDropDownArrowPath, stylesheet),
                    stylesheet
                )
            };
        }
    },

    toggleUnderlineEpicUpdater: EpicUpdaterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: update({ paths, pathPart: path.textUnderline, updaterFn: R.not })(stylesheet)
        })
    }),
    textColorEpicUpdater: EpicUpdaterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [{ color }], state: stylesheet }) => ({
            state: update({ paths, pathPart: path.textColor, updaterFn: R.always(color) })(stylesheet)
        })
    }),
    textColorOpacityEpicUpdater: EpicUpdaterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [opacity], state: stylesheet }) => {
            const newOpacity = opacityToAlpha(opacity);
            return { state: update({
                paths,
                pathPart: path.textColorOpacity,
                updaterFn: R.always(newOpacity)
            })(stylesheet) };
        }
    }),
    textColorRemoveEpicUpdater: EpicUpdaterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: update({ paths, pathPart: path.textColor, updaterFn: R.always(null) })(stylesheet)
        })
    }),
    textShadowColorEpicUpdater: EpicUpdaterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [{ color }], state: stylesheet }) => ({
            state: updateTextShadow(paths, stylesheet, { color })
        })
    }),
    textShadowColorRemoveEpicUpdater: EpicUpdaterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: update({ paths, pathPart: path.textShadow, updaterFn: R.always(false) })(stylesheet)
        })
    }),
    textShadowBlurRadiusEpicUpdater: EpicUpdaterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [blur], state: stylesheet }) => ({
            state: updateTextShadow(paths, stylesheet, { blur })
        })
    }),
    textShadowVerticalOffsetEpicUpdater: EpicUpdaterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [top], state: stylesheet }) => ({
            state: updateTextShadow(paths, stylesheet, { top })
        })
    }),
    textShadowHorizontalOffsetEpicUpdater: EpicUpdaterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [left], state: stylesheet }) => ({
            state: updateTextShadow(paths, stylesheet, { left })
        })
    }),
    bgColorEpicUpdater: OptPathsEpicUpdterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [{ color }], state: stylesheet }) => ({
            state: updateInPaths({
                paths,
                updaterFn: bgSetters.setBackgroundSolidColor,
                pathToUpdate: path.blockBackground,
                dataToUpdate: color,
                stylesheet
            })
        })
    }),
    bgColorOpacityEpicUpdater: OptPathsEpicUpdterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [opacity], state: stylesheet }) => ({
            state: updateInPaths({
                paths,
                updaterFn: bgSetters.setBackgroundOpacity,
                pathToUpdate: path.blockBackground,
                dataToUpdate: opacity,
                stylesheet
            })
        })
    }),
    bgColorRemoveEpicUpdater: OptPathsEpicUpdterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: updateInPaths({
                paths,
                updaterFn: bgSetters.unsetBackgroundColor,
                pathToUpdate: path.blockBackground,
                dataToUpdate: null,
                stylesheet
            })
        })
    }),
    bgGradientColorEpicUpdater: OptPathsEpicUpdterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [{ color }], state: stylesheet }) => ({
            state: updateInPaths({
                paths,
                updaterFn: bgSetters.setBackgroundGradientColor,
                pathToUpdate: path.blockBackground,
                dataToUpdate: color,
                stylesheet
            })
        })
    }),
    bgGradientColorOpacityEpicUpdater: EpicUpdaterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [opacity], state: stylesheet }) => ({
            state: updateInPaths({
                paths,
                updaterFn: bgSetters.setBackgroundOpacity,
                pathToUpdate: path.blockBackgroundGradient,
                dataToUpdate: opacity,
                stylesheet
            })
        })
    }),
    bgGradientRemoveEpicUpdater: OptPathsEpicUpdterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: updateInPaths({ paths,
                updaterFn: gradientSetters.unsetGradientColor,
                pathToUpdate: path.blockBackgroundGradient,
                dataToUpdate: null,
                stylesheet })
        })
    }),
    bgGradientDirectionEpicUpdater: OptPathsEpicUpdterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [direction], state: stylesheet }) => ({
            state: updateInPaths({
                paths,
                updaterFn: gradientSetters.setGradientDirection,
                pathToUpdate: path.blockBackgroundGradient,
                dataToUpdate: direction,
                stylesheet
            })
        })
    }),
    bgGradientFadePointEpicUpdater: OptPathsEpicUpdterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [fadePoint], state: stylesheet }) => ({
            state: updateInPaths({
                paths,
                updaterFn: gradientSetters.setGradientFadePoint,
                pathToUpdate: path.blockBackgroundGradient,
                dataToUpdate: fadePoint,
                stylesheet
            })
        })
    }),
    bgAssetEpicUpdater: OptPathsEpicUpdterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [{ asset }], state: stylesheet }) => ({
            state: updateInPaths({
                paths,
                updaterFn: bgSetters.setAsset,
                pathToUpdate: path.blockBackground,
                dataToUpdate: asset,
                stylesheet
            })
        })
    }),
    bgAssetRemoveEpicUpdater: OptPathsEpicUpdterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: updateInPaths({
                paths,
                updaterFn: bgSetters.unsetAsset,
                pathToUpdate: path.blockBackground,
                dataToUpdate: null,
                stylesheet
            })
        })
    }),
    bgAssetRepeatEpicUpdater: OptPathsEpicUpdterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [repeat], state: stylesheet }) => ({
            state: updateInPaths({
                paths,
                updaterFn: bgSetters.setAssetRepeat,
                pathToUpdate: path.blockBackground,
                dataToUpdate: repeat,
                stylesheet
            })
        })
    }),
    bgAssetPositionEpicUpdater: OptPathsEpicUpdterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [position], state: stylesheet }) => ({
            state: updateInPaths({
                paths,
                updaterFn: bgSetters.setAssetPosition,
                pathToUpdate: path.blockBackground,
                dataToUpdate: position,
                stylesheet
            })
        })
    }),
    bgAssetSizeEpicUpdater: OptPathsEpicUpdterFactory = (action, paths) => ({
        conditions: [action],
        reducer: ({ values: [size], state: stylesheet }) => ({
            state: updateInPaths({
                paths,
                updaterFn: bgSetters.setAssetSize,
                pathToUpdate: path.blockBackground,
                dataToUpdate: size,
                stylesheet
            })
        })
    }),

    treeMenuTextIndentEpicUpdater: ActionEpicUpdaterFactory = action => ({
        conditions: [action],
        reducer: ({ values: [indent], state: stylesheet }) => ({
            state: R.assoc(path.accordionTextIndent, indent, stylesheet)
        })
    }),

    menuDividerBorderRadiusEpicUpdater: ActionEpicUpdaterFactory = action => ({
        conditions: [action],
        reducer: ({ values: [corners], state: stylesheet }) => ({
            state: setToPath([path.divider, path.border, path.corners], corners, stylesheet)
        })
    }),
    menuDividerBorderStyleEpicUpdater: ActionEpicUpdaterFactory = action => ({
        conditions: [action],
        reducer: ({ values: [style], state: stylesheet }) => {
            let updatedStylesheet = borderSetters.setBorderStyle([path.divider, path.border], style, stylesheet);

            if (style !== BorderStyles.NONE) {
                const borderWidth = R.pathOr(
                    null,
                    [path.divider, path.border, path.width],
                    updatedStylesheet
                );

                if (borderWidth && borderWidth.every(item => !item)) {
                    const initialBorderWidth = [1, 1, 1, 1];
                    updatedStylesheet = borderSetters.setBorderWidths(
                        [path.divider, path.border],
                        initialBorderWidth,
                        updatedStylesheet
                    ) || updatedStylesheet;
                }
            }
            return {
                state: updatedStylesheet
            };
        }
    }),
    menuDividerBorderColorEpicUpdater: ActionEpicUpdaterFactory = action => ({
        conditions: [action],
        reducer: ({ values: [{ color }], state: stylesheet }) => ({
            state: borderSetters.setBorderColor([path.divider, path.border], color, stylesheet)
        })
    }),
    menuDividerBorderColorRemoveEpicUpdater: ActionEpicUpdaterFactory = action => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: borderSetters.unsetBorderColor([path.divider, path.border], stylesheet)
        })
    }),
    menuDividerBorderColorOpacityEpicUpdater: ActionEpicUpdaterFactory = action => ({
        conditions: [action],
        reducer: ({ values: [opacity], state: stylesheet }) => ({
            state: borderSetters.setBorderOpacity([path.divider, path.border], opacity, stylesheet)
        })
    }),
    menuDividerBorderWidthEpicUpdater: ActionEpicUpdaterFactory = action => ({
        conditions: [action],
        reducer: ({ values: [borderWidth], state: stylesheet }) => ({
            state: R.all(R.is(Number), borderWidth)
                ? borderSetters.setBorderWidths([path.divider, path.border], borderWidth, stylesheet)
                : stylesheet
        })
    }),
    menuDividerSpacingEpicUpdater: ActionEpicUpdaterFactory = action => ({
        conditions: [action],
        reducer: ({ values: [spacing], state: stylesheet }) => ({
            state: setToPath([path.divider, path.padding], spacing, stylesheet)
        })
    }),
    menuDividerBgColorEpicUpdater: ActionEpicUpdaterFactory = action => ({
        conditions: [action],
        reducer: ({ values: [{ color }], state: stylesheet }) => ({
            state: bgSetters.setBackgroundSolidColor([path.divider, path.background], color, stylesheet)
        })
    }),
    menuDividerBgColorRemoveEpicUpdater: ActionEpicUpdaterFactory = action => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: bgSetters.unsetBackgroundColor([path.divider, path.background], stylesheet)
        })
    }),
    menuDividerBgColorOpacityEpicUpdater: ActionEpicUpdaterFactory = action => ({
        conditions: [action],
        reducer: ({ values: [opacity], state: stylesheet }) => ({
            state: bgSetters.setBackgroundOpacity([path.divider, path.background], opacity, stylesheet)
        })
    }),
    menuDividerBgGradientColorEpicUpdater: ActionEpicUpdaterFactory = action => ({
        conditions: [action],
        reducer: ({ values: [{ color }], state: stylesheet }) => ({
            state: bgSetters.setBackgroundGradientColor(p(path.divider, path.background), color, stylesheet)
        })
    }),
    menuDividerGradientRemoveEpicUpdater: ActionEpicUpdaterFactory = action => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: gradientSetters.unsetGradientColor(p(path.divider, path.backgroundGradient), stylesheet)
        })
    }),
    menuDividerBgGradientDirectionEpicUpdater: ActionEpicUpdaterFactory = action => ({
        conditions: [action],
        reducer: ({ values: [direction], state: stylesheet }) => ({
            state: gradientSetters.setGradientDirection(p(path.divider, path.backgroundGradient), direction, stylesheet)
        })
    }),
    menuDividerBgGradientFadePointEpicUpdater: ActionEpicUpdaterFactory = action => ({
        conditions: [action],
        reducer: ({ values: [fadePoint], state: stylesheet }) => ({
            state: gradientSetters.setGradientFadePoint(p(path.divider, path.backgroundGradient), fadePoint, stylesheet)
        })
    }),
    menuDividerBgAssetEpicUpdater: ActionEpicUpdaterFactory = action => ({
        conditions: [action],
        reducer: ({ values: [{ asset }], state: stylesheet }) => ({
            state: bgSetters.setAsset([path.divider, path.background], asset, stylesheet)
        })
    }),
    menuDividerBgAssetRemoveEpicUpdater: ActionEpicUpdaterFactory = action => ({
        conditions: [action],
        reducer: ({ state: stylesheet }) => ({
            state: bgSetters.unsetAsset([path.divider, path.background], stylesheet)
        })
    }),
    menuDividerBgAssetRepeatEpicUpdater: ActionEpicUpdaterFactory = action => ({
        conditions: [action],
        reducer: ({ values: [repeat], state: stylesheet }) => ({
            state: bgSetters.setAssetRepeat([path.divider, path.background], repeat, stylesheet)
        })
    }),
    menuDividerBgAssetPositionEpicUpdater: ActionEpicUpdaterFactory = action => ({
        conditions: [action],
        reducer: ({ values: [position], state: stylesheet }) => ({
            state: bgSetters.setAssetPosition([path.divider, path.background], position, stylesheet)
        })
    }),
    menuDividerBgAssetSizeEpicUpdater: ActionEpicUpdaterFactory = action => ({
        conditions: [action],
        reducer: ({ values: [size], state: stylesheet }) => ({
            state: bgSetters.setAssetSize([path.divider, path.background], size, stylesheet)
        })
    });
