import {
    SmallTextMaxHeightLimit, SmallTextMaxWidthLimit, GroupTypes,
    mobileViewEditorContentAreaPadding,
    LargeTextMaxHeightLimit,
    LargeTextMaxWidthLimit
} from "./constants";
import type { AnyComponent, BBox } from "../../redux/modules/children/workspace/flowTypes";
import type { TextComponent } from "../oneweb/Text/flowTypes";
import { TextComponentKind as TextKind } from "../oneweb/Text/kind";
import Image from "../oneweb/Image/kind";

export const
    isOverlappingHorizontally = (box1: BBox, box2: BBox) => (box1.top < box2.bottom && box1.bottom > box2.top),
    getCmpVerticalCenter = (cmp: AnyComponent) => cmp.top + (cmp.height / 2),
    isSmallText = ({ height, width }: TextComponent) =>
        (height <= SmallTextMaxHeightLimit && width <= SmallTextMaxWidthLimit),
    canBeBigText = (cmp) => (cmp.height <= LargeTextMaxHeightLimit && cmp.width <= LargeTextMaxWidthLimit),
    getGroupTypeById = (id: string) => {
        const type = Object.keys(GroupTypes).find(type => id.search('-' + GroupTypes[type] + '-') > -1);
        return type ? GroupTypes[type] : null;
    },
    getMobileContentAreaWidth = (width: number) => width - (mobileViewEditorContentAreaPadding * 2),
    _groupTypeFns = {
        [GroupTypes.images]: (group: Array<AnyComponent>) => group.every(cmp => cmp.kind === Image),
        [GroupTypes.imageAndText]: (group: Array<AnyComponent>) => {
            return (
                group.length === 2 &&
                ((group[0].kind === Image && group[1].kind === TextKind) ||
                    (group[0].kind === TextKind && group[1].kind === Image))
            );
        },
        [GroupTypes.textAndText]: (group: Array<AnyComponent>) =>
            (group.length === 2) && group.every(cmp => cmp.kind === TextKind)
    },
    getGroupTypeByData = (componentGroup: Array<AnyComponent>) => {
        const typeProperty = Object.keys(GroupTypes).filter(type => _groupTypeFns[GroupTypes[type]](componentGroup))[0];
        return GroupTypes[typeProperty];
    },
    isParentSame = (c1: AnyComponent, c2: AnyComponent): boolean => //$FlowFixMe
        (!c1.relIn && !c2.relIn) || (c1.relIn && c2.relIn && c1.relIn.id === c2.relIn.id);
