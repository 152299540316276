import React from 'react';
import cx from 'classnames';
import Msg from "../../../../view/intl/Msg";
import { EPSeparator } from "../../../Panel/view/EPSeparator";
import VerticalSpacer from "../../../../view/common/VerticalSpacer";
import * as InserterStyles from '../../../Panel/view/Inserter.css';
import HelpTip from "../../../Panel/view/HelpTip";
import { Flex } from "../../../../view/reflexbox/index";
import { DroppableCmpShortcut } from "../../../Panel/view/DroppableCmpShortcut";
import WebshopKind from '../kind';
import { WEBSHOP_ALL_PRODUCTS_WIDGET_HELP_TIP, WEBSHOP_WIDGET_DISABLED_TIP } from '../translations';

import styles from './styles.css';

type Props = {
    dispatch: Function;
    disabled: boolean
};

const SampleProductImages = () => {
    const productImages = [
        [styles.productImage_2, styles.productImage_3, styles.productImage_4, styles.productImage_5],
        [styles.productImage_6, styles.productImage_7, styles.productImage_8, styles.productImage_9],
    ];

    return (
        <Flex column className={styles.productsContainer}>
            {productImages.map((productImagesRow, i) => {
                return (
                    <Flex
                        justify="space-between"
                        align="center"
                        key={'products-row-' + i}
                        className={styles.allProductsRow}
                    >
                        {productImagesRow.map((productImage, j) => {
                            return (
                                <Flex column key={'product-' + j}>
                                    <div className={cx(styles.productImage, styles.allProductImage, productImage)} />
                                    <Msg
                                        k="webshopInserter.singleProduct.ProductTitle"
                                        className={cx(styles.productTitle, styles.allProductsTitle)}
                                    >I'm a product</Msg>
                                    <Msg
                                        k="webshopInserter.featuredProducts.ProductPrice"
                                        className={cx(styles.productPrice, styles.allProductsPrice)}
                                    >EUR 35</Msg>
                                </Flex>
                            );
                        })}
                    </Flex>
                );
            })}
        </Flex>
    );
};

export const AllProducts = ({ dispatch, disabled }: Props) => {
    const droppableCmpDispatch = (!disabled) ? dispatch : () => {};
    return (
        <React.Fragment>
            <VerticalSpacer y={20} />
            <Flex column>
                <Flex justify="space-between">
                    <Msg k="webshopInserter.allProductsGrid" className={InserterStyles.subTitle}>All products grid</Msg>
                    <Flex className={styles.helpTipContainer}>
                        <HelpTip
                            msg={(!disabled) ? WEBSHOP_ALL_PRODUCTS_WIDGET_HELP_TIP : WEBSHOP_WIDGET_DISABLED_TIP}
                            helpTipClassName="inserterInfoIconTip"
                            dispatch={dispatch}
                        />
                    </Flex>
                </Flex>
                <DroppableCmpShortcut
                    kind={WebshopKind}
                    // @ts-ignore
                    dispatch={droppableCmpDispatch}
                    isNoOverlay={disabled}
                >
                    <SampleProductImages />
                </DroppableCmpShortcut>
            </Flex>
            <VerticalSpacer y={25} />
            <EPSeparator />
        </React.Fragment>
    );
};
