/* eslint-disable max-len */

import React from 'react';
import cx from 'classnames';
import Scrollbar from '../../../../../view/common/Scrollbar/index';
import * as styles from '../onBoardingPanel.css';
import {
    GOTO_PREV_STEP_ONBOARDING_PANEL,
    GOTO_NEXT_STEP_ONBOARDING_PANEL,
    HIDE_MODERN_LAYOUT_ONBOARDING_PANEL,
    MODERN_LAYOUT_ONBOARDING_PANEL_CLICKED,
    CLOSE_MODERN_LAYOUT_ONBOARDING_PANEL,
} from '../../../actionTypes';
import { Intl, Msg } from '../../../../../view/intl/index';
import type { OnBoardingPanelEpicState, LeftPanelEpicState } from "../../../flowTypes";
import OnBoardingWelcome from './OnBoardingWelcome';
import OnBoardingSelectHeader from './OnBoardingSelectHeader';
import OnBoardingSelectFooter from './OnBoardingSelectFooter';
import OnBoardingToggleHeader from './OnBoardingToggleHeader';
import OnBoardingToggleFooter from './OnBoardingToggleFooter';
import OnBoardingSaveHeader from './OnBoardingSaveHeader';
import { ONBOARDING_TITLES, ONBOARDING_SUB_TITLES, ONBOARDING_STEPS, ONBOARDING_STEPS_TOTAL_COUNT, ONBOARDING_STEP_NUMBERS,
    HEADER_FOOTER_LAYOUT_STEP, SELECT_HEADER_LAYOUT_STEP, SAVING_HEADER_LAYOUT_STEP, SELECT_FOOTER_LAYOUT_STEP,
    HIDE_FOOTER_IN_ONBOARDING_PANEL, LAST_STEP_IN_ONBOARDING_PANEL, TOGGLE_HEADER_LAYOUT_STEP,
    TOGGLE_FOOTER_LAYOUT_STEP } from '../../../constants';
import type { Stylesheets } from "../../../../Workspace/epics/stylesheets/flowTypes";
import type { SectionComponent } from "../../../../oneweb/Section/flowTypes";
import type { TemplateComponent } from "../../../../oneweb/Template/flowTypes";
import type { WebShopMHFEpicState } from "../../../../oneweb/WebShopMHF/flowTypes";

type Props = {
    intl: Intl,
    dispatch: Dispatch,
    onBoardingPanel: OnBoardingPanelEpicState,
    leftPanel: LeftPanelEpicState,
    template: TemplateComponent,
    componentsEval: Record<string, any>,
    globalVariables: Record<string, any>,
    styleSheets: Stylesheets,
    webshopMHFData: WebShopMHFEpicState,
    headerSection: SectionComponent,
    footerSection: SectionComponent,
};

const StepViewConfigs = {
    [HEADER_FOOTER_LAYOUT_STEP]: OnBoardingWelcome,
    [SELECT_HEADER_LAYOUT_STEP]: OnBoardingSelectHeader,
    [TOGGLE_HEADER_LAYOUT_STEP]: OnBoardingToggleHeader,
    [SAVING_HEADER_LAYOUT_STEP]: OnBoardingSaveHeader,
    [SELECT_FOOTER_LAYOUT_STEP]: OnBoardingSelectFooter,
    [TOGGLE_FOOTER_LAYOUT_STEP]: OnBoardingToggleFooter,
};

const OnBoardingModeOn = (props: Props) => {
    const {
            intl,
            dispatch,
            onBoardingPanel,
        } = props,
        currentStepNumber = onBoardingPanel.currentStep,
        currentStepName = ONBOARDING_STEPS[currentStepNumber],
        uIstepNumber = ONBOARDING_STEP_NUMBERS[currentStepName],
        StepView = StepViewConfigs[currentStepName];

    return (
        <React.Fragment>
            <Scrollbar height={!HIDE_FOOTER_IN_ONBOARDING_PANEL[currentStepName] ? "calc(100vh - 130px)" : "100%"}>
                <div onClick={() => dispatch({ type: MODERN_LAYOUT_ONBOARDING_PANEL_CLICKED })}>
                    <div className={styles.closeIconWrapper}>
                        <div
                            onClick={() => dispatch({ type: CLOSE_MODERN_LAYOUT_ONBOARDING_PANEL })}
                            className={styles.closeIcon}
                        />
                    </div>
                    <div className={cx(styles.stepCountWrapper, { [styles.showStepCounter]: uIstepNumber })}>
                        <div className={cx(styles.stepCount)}>
                            {uIstepNumber} / {ONBOARDING_STEPS_TOTAL_COUNT}
                        </div>
                    </div>
                    <div className={styles.titleWrapper}>
                        <div className={cx(styles.title)}>
                            {intl.msgJoint(ONBOARDING_TITLES[currentStepName])}
                        </div>
                    </div>
                    {ONBOARDING_SUB_TITLES[currentStepName] && <div className={styles.subTitleWrapper}>
                        <div className={cx(styles.subTitle)}>
                            {intl.msgJoint(ONBOARDING_SUB_TITLES[currentStepName])}
                        </div>
                    </div>}
                    <StepView {...props} />
                </div>
            </Scrollbar>
            {!HIDE_FOOTER_IN_ONBOARDING_PANEL[currentStepName] && <div className={cx(styles.footer)}>
                <div
                    className={styles.backBtn}
                    onClick={() => {
                        dispatch({ type: GOTO_PREV_STEP_ONBOARDING_PANEL });
                    }}
                >
                    <Msg k="common.back">
                        Back
                    </Msg>
                </div>
                <button
                    type="button"
                    className={cx(styles.nextBtn, { [styles.deactivedBtn]: !onBoardingPanel.isNextBtnActivated })}
                    onClick={() => {
                        if (!onBoardingPanel.isNextBtnActivated) return;
                        if (LAST_STEP_IN_ONBOARDING_PANEL[currentStepName]) {
                            dispatch({ type: HIDE_MODERN_LAYOUT_ONBOARDING_PANEL });
                        } else {
                            dispatch({ type: GOTO_NEXT_STEP_ONBOARDING_PANEL });
                        }
                    }}
                >
                    {!LAST_STEP_IN_ONBOARDING_PANEL[currentStepName] && <Msg k="common.next">
                        Next
                    </Msg>}
                    {LAST_STEP_IN_ONBOARDING_PANEL[currentStepName] && <Msg k="common.done">
                        Done
                    </Msg>}
                </button>
            </div>}
        </React.Fragment>
    );
};

export { OnBoardingModeOn };
