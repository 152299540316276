import React from 'react';
import BaseComponent from "../../../../view/oneweb/BaseComponent";
import View from './index';
import type { TableViewProps } from '../flowTypes';

export default (props: TableViewProps) => (
    <BaseComponent {...props}>
        <View {...props} />
    </BaseComponent>
);
