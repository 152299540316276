import DisconnectListingDialog from './index';
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import { getCenterPositionForDialog } from "../../../../DialogManager/utility";

const HEIGHT = 697,
    WIDTH = 1218,
    disconnectListingDialog = {
        ...getCenteredDialogConfig(WIDTH, HEIGHT, DisconnectListingDialog),
        confFactory: ({ browserWidth, browserHeight }) => {
            return {
                position: getCenterPositionForDialog(WIDTH, HEIGHT, browserWidth, browserHeight),
                modal: true,
                dimensions: { width: WIDTH, height: HEIGHT }
            };
        },
        moveToCenter: true,
    };

export {
    disconnectListingDialog
};
