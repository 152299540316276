/* eslint-disable max-len */
import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Lottie from "react-web-lottie";

import animationData from "./animation.json";
import { getStepMarkers, LOTTIE_DEFAULT_OPTIONS } from "../../../view/LottieAnimations/constant";

import { WIZARD_STEP_ID } from "../../constants";
import { STEP_MARKERS } from "./constants";

import { isGroupSelector, isGroupSetSelector } from "../../Epic/selectors";

import { SVG_MAP } from "./svgUtils";

const markers = animationData.markers;
const stepMarkers = getStepMarkers(markers, WIZARD_STEP_ID.PRONOUNS, STEP_MARKERS[WIZARD_STEP_ID.PRONOUNS]);

export const PronounsAnimation = () => {
    const isGroup = useSelector(isGroupSelector);
    const isGroupSet = useSelector(isGroupSetSelector);
    const animationRef = useRef(null);

    const [startPoint, setStartPoint] = useState(stepMarkers.language_end_point);
    const [stopPoint, setStopPoint] = useState(stepMarkers.pronouns_start_point);

    const initialSegment = [startPoint, stopPoint];

    const defaultOptions = {
        ...LOTTIE_DEFAULT_OPTIONS,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid meet"
        },
        initialSegment,
        animationData
    };

    useEffect(() => {
        if (isGroup !== null && isGroup !== undefined && isGroupSet) {
            setStartPoint(stopPoint);
            setStopPoint(stepMarkers.pronouns_added);

            let element = document.querySelectorAll(`div[class*="animationContainer"] g[class="png"] image`);
            if (element) {
                if (isGroup) {
                    // @ts-ignore
                    element[3].href.baseVal = "data:image/svg+xml;base64," + btoa(SVG_MAP.group.trim());
                } else {
                    // @ts-ignore
                    element[3].href.baseVal = "data:image/svg+xml;base64," + btoa(SVG_MAP.single.trim());
                }
            }
        }
    }, [isGroup, isGroupSet]);

    useEffect(() => {
        // @ts-ignore
        animationRef.current.playSegments([[startPoint, stopPoint]], true);
    }, [startPoint, stopPoint]);

    return (
        <Lottie lottiRef={animationRef} options={defaultOptions} isClickToPauseDisabled />
    );
};
