import React from "react";
export const SvgInstagramRoundedFillMulticolor = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <rect width="48" height="48" rx="6" fill="#AF359E" />
            <circle cx="23.75" cy="23.75" r="4.25" stroke="white" strokeWidth="2" />
            <circle cx="29.25" cy="18.25" r="1.25" fill="white" />
            <rect x="14.5" y="14.5" width="18.5" height="18.5" rx="5" stroke="white" strokeWidth="2" />
        </g>
    </svg>
);
