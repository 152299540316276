import React from 'react';
import { Flex } from "../../../view/reflexbox/index";
import HorizontalSpacer from '../../../view/common/HorizontalSpacer';
import BackgroundImageSettings from "../BackgroundImageSettings/index";
import type { BackgroundDialogViewPropTypes } from './flowTypes';
import styles from './BackgroundDialog.css';
import ColorGradientSettings from '../ColorGradientSettings/index';

export default ({ colorGradientSettings, showColorSettings = true, backgroundSettings, dispatch }: BackgroundDialogViewPropTypes) => (
    <Flex justify="space-between">
        {
            showColorSettings &&
            <React.Fragment>
                <div className={styles.leftPanel}>
                    <ColorGradientSettings {...colorGradientSettings} dispatch={dispatch} />
                </div>
                <div className={styles.borderRight}>
                    <HorizontalSpacer />
                </div>
            </React.Fragment>
        }
        <div className={styles.rightPanel}>
            <BackgroundImageSettings
                {...backgroundSettings}
                dispatch={dispatch}
            />
        </div>
    </Flex>
);
