/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { StandardTopicField } from "./StandardTopicField";
import { CustomTopicField } from "./CustomTopicField";

import { TOPIC_TYPE_OPTIONS } from '../constants';
import { selectTopicType } from '../../../../epics/AITextEpic/selectors';

export const TopicField = () => {
    const topicType = useSelector(selectTopicType);

    const [isCustomTopicFieldActive, setustomTopicField] = useState(false);

    const onTopicTypeChange = (value) => {
        if (value === TOPIC_TYPE_OPTIONS.custom) {
            setustomTopicField(true);
        } else {
            setustomTopicField(false);
        }
    };

    useEffect(() => {
        onTopicTypeChange(topicType);
    }, [topicType]);

    return (
        <React.Fragment>
            <StandardTopicField onTopicTypeChange={onTopicTypeChange} />
            {
                isCustomTopicFieldActive ?
                    <CustomTopicField /> :
                    null
            }
        </React.Fragment>
    );
};
