import { parseAHrefCode } from "../../utils";
import { DefaultLocale } from '../../constants';
import {
    TWEET_DEFAULT_WIDTH, TWEET_DEFAULT_HEIGHT,
    DEFAULT_WIDTH, DEFAULT_HEIGHT,
} from '../constants';
import { getTweetHTMLFromCode } from './getTweetHTMLFromCode';
import { isTimelineLinkValid, isListLinkValid, isTweetLinkValid } from './linkValidator';
import { parseTweetCode } from "./parseTweetCode";
import { type TwitterComponent } from '../types';

const TweetDefaultParams = {
    locale: DefaultLocale,
    direction: 'ltr',
    width: TWEET_DEFAULT_WIDTH,
    height: TWEET_DEFAULT_HEIGHT,
};

const convertXToTwitter = (xUrl: string): string => xUrl.replace(/^(?:https:\/\/)?(?:www\.)?x\.com/, 'https://twitter.com');

export const parseTwitterCode = (code: string): TwitterComponent => {
    const params = <TwitterComponent>{};

    if (isTweetLinkValid(code)) {
        params.tweetHTML = getTweetHTMLFromCode(convertXToTwitter(code), TweetDefaultParams.locale, TweetDefaultParams.direction);
        return { ...TweetDefaultParams, ...params };
    } else if (isListLinkValid(code)) {
        params.timelineLink = convertXToTwitter(code);
        return params;
    } else if (isTimelineLinkValid(code)) {
        params.listLink = convertXToTwitter(code);
        return params;
    }

    const timelineParams: TwitterComponent = parseAHrefCode(code);
    if (Object.keys(timelineParams).length > 0) {
        const { href, theme, locale, doNotTrack, width, height } = timelineParams;
        if (href) {
            const [link, ] = href.split('?');   // eslint-disable-line array-bracket-spacing
            let flag = false;
            if (isTimelineLinkValid(link)) {
                params.timelineLink = convertXToTwitter(link);
                flag = true;
            } else if (isListLinkValid(link)) {
                params.listLink = convertXToTwitter(link);
                flag = true;
            }

            if (flag) {
                if (theme) params.theme = theme;
                if (width) params.width = width;
                if (height) params.height = height;
                if (locale) params.locale = locale;
                if (doNotTrack) params.doNotTrack = true;
            }

            return { width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT, ...params };
        }
    }

    const tweetParams = parseTweetCode(code);
    if (Object.keys(tweetParams).length > 0) {
        return { ...TweetDefaultParams, ...tweetParams, };
    }

    return params;
};

