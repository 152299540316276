import makeEpic from "../../../epics/makeEpic";
import valueActionType from './valueActionType';
import { workspaceSaveStatusValueActionType } from '../../Workspace/epics/saveStatus/valueActionType';
import {
    CANCEL_UNSAVED_CHANGES,
    CHECK_UNSAVED_CHANGES,
    DONT_SAVE_UNSAVED_CHANGES,
    SAVE_UNSAVED_CHANGES,
    UNSAVED_CHANGES_CHECKED
} from "../actionTypes";
import { SAVE_REQUEST } from "../../App/actionTypes";
import { closeUnsavedChangesDialog, openUnsavedChangesDialog } from "../actionCreators";
import { PAGE_DATASET_SAVE_SUCCESS } from "../../App/epics/pageDataset/actions";
import currentPageNameValueActionType from '../../App/epics/currentPageName/valueActionType';
import { optionalReceiveOnly, receiveOnly } from '../../../epics/makeCondition';
import { SaveStatus } from "../../Workspace/epics/saveStatus/SaveStatus";

const defaultState = false;

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                receiveOnly(workspaceSaveStatusValueActionType),
                optionalReceiveOnly(currentPageNameValueActionType),
                CHECK_UNSAVED_CHANGES
            ],
            reducer: ({ state, values: [saveStatus, currentPageName] }) => {
                if (saveStatus === SaveStatus.CAN_SAVE) {
                    return {
                        state: true,
                        actionToDispatch: openUnsavedChangesDialog(currentPageName)
                    };
                }
                return {
                    state,
                    actionToDispatch: { type: UNSAVED_CHANGES_CHECKED }
                };
            }
        },
        {
            conditions: [SAVE_UNSAVED_CHANGES],
            reducer: () => ({
                state: true,
                actionToDispatch: { type: SAVE_REQUEST }
            })
        },
        {
            conditions: [PAGE_DATASET_SAVE_SUCCESS],
            reducer: ({ state }) => {
                if (state) {
                    return {
                        state: false,
                        // IMPORTANT: order mattes
                        multipleActionsToDispatch: [closeUnsavedChangesDialog(), { type: UNSAVED_CHANGES_CHECKED }]
                    };
                }
                return { state };
            }
        },
        {
            conditions: [DONT_SAVE_UNSAVED_CHANGES],
            reducer: () => ({
                state: false,
                // IMPORTANT: order mattes
                multipleActionsToDispatch: [closeUnsavedChangesDialog(), { type: UNSAVED_CHANGES_CHECKED }]
            })
        },
        {
            conditions: [CANCEL_UNSAVED_CHANGES],
            reducer: () => ({ state: false, actionToDispatch: closeUnsavedChangesDialog() })
        }
    ]
});
