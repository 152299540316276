import { assocPath, pathOr } from 'ramda';
import * as React from 'react';
import cx from 'classnames';
import styles from './MobileHeaderMenu.css';
import type { MenuState } from '../types';
import type { MobileStyle } from '../../style/types';
import { toCss } from "../../../../mappers/color";
import { DataSite } from '../../../../../dal/model/index';
import { DATA_LINK_SECTION_TYPE } from '../../../../../dal/model/utils/dataSiteItemTypes';
import { replaceTagsWithContent } from '../../../oneweb/Text/view/replaceTagsWithContent';
import type { ThemeColorDataType } from '../../../ThemeGlobalData/flowTypes';
import { FontFamiliesFallback } from '../../../presentational/FontFamily/fontFamilies';

type CommonProps = {
    menuState: MenuState,
    isMenuOpen: null | undefined | boolean,
    isMVE: null | undefined | boolean,
    mobileStyle: MobileStyle,
    site: DataSite,
    globalVariables: Record<string, any>,
};

type PropsWhenAutoColorModeIsOn = CommonProps & {
    autoColorMode: true,
    themeColorsData: ThemeColorDataType,
};

type PropsWhenAutoColorModeIsOff = CommonProps & {
    autoColorMode: false,
};

type Props = PropsWhenAutoColorModeIsOn | PropsWhenAutoColorModeIsOff;

export const MobileHeaderMenu = (props: Props) => {
    const { menuState: { menuItems }, isMenuOpen, isMVE, mobileStyle, globalVariables, site } = props;
    let {
            menu: {
                open: {
                    fontFamily,
                    bold,
                    italic,
                    underline,
                    fontSize,
                    lineHeight,
                    themeColor,
                    color,
                    backgroundThemeColor,
                    backgroundColor
                },
                selected: {
                    bold: selectedBold,
                    italic: selectedItalic,
                    underline: selectedUnderline,
                    themeColor: selectedItemThemeColor,
                    color: selectedColor,
                    backgroundThemeColor: selectedItemBackgroundThemeColor,
                    backgroundColor: selectedBgColor
                }
            }
        } = mobileStyle,
        convertToCss = (clr) => (clr ? toCss(clr) : null),
        menuOpenStyles, itemStyles, selectedItemStyles;
    // @ts-ignore Better to rename the variable names
    color = (props.autoColorMode ? toCss(props.themeColorsData[themeColor]) : convertToCss(color));
    // @ts-ignore
    backgroundColor = props.autoColorMode ? toCss(props.themeColorsData[backgroundThemeColor]) : convertToCss(backgroundColor);
    // @ts-ignore
    selectedColor = props.autoColorMode ? toCss(props.themeColorsData[selectedItemThemeColor]) : convertToCss(selectedColor);
    // @ts-ignore
    selectedBgColor = (
        props.autoColorMode
            ? toCss(assocPath([4], pathOr(1, [4])(selectedBgColor))(props.themeColorsData[selectedItemBackgroundThemeColor]))
            : convertToCss(selectedBgColor)
    );

    menuOpenStyles = {
        backgroundColor,
    };
    if (isMVE) {
        menuOpenStyles = {
            ...menuOpenStyles,
            paddingBottom: 100,
            pointerEvents: 'none',
            minHeight: '100%'
        };
    }

    itemStyles = {
        backgroundColor,
        color,
        fontSize,
        lineHeight,

        fontFamily: FontFamiliesFallback[fontFamily || "null"] || fontFamily,
        fontWeight: bold ? 'bold' : null,
        fontStyle: italic ? 'italic' : null,
        textDecoration: underline ? 'underline' : null,
    };
    selectedItemStyles = {
        fontWeight: selectedBold ? 'bold' : null,
        fontStyle: selectedItalic ? 'italic' : null,
        textDecoration: selectedUnderline ? 'underline' : null,
        color: selectedColor,
        backgroundColor: selectedBgColor
    };

    const renderItem = (item) => {
        const currentStyles = item.isCurrent ? { ...itemStyles, ...selectedItemStyles } : itemStyles;
        let sectionId;
        if (item.type === DATA_LINK_SECTION_TYPE) {
            sectionId = item.sectionId;
        }
        return (
            <a
                href={item.href}
                className={cx({ [styles.current]: item.isCurrent })}
                style={currentStyles}
                title={item.name}
                // @ts-ignore
                // eslint-disable-next-line react/no-unknown-property
                sectionid={sectionId}
            >{replaceTagsWithContent(item.name, { globalVariables, site })}</a>
        );
    };

    const renderChildren = (items: any = []) => {
        return items.map((item) => {
            if (!item.children || !item.children.length) {
                return (
                    <li key={item.id}>
                        {renderItem(item)}
                    </li>
                );
            }
            const expandIconStyles = {
                    color: item.isCurrent ? selectedColor : color,
                    height: fontSize / 2,
                    width: fontSize / 2,
                },
                iconMargin = fontSize / 4;
            return (
                <li key={item.id} className={cx({ 'expanded': item.isExpanded })}>
                    <div
                        className={cx(styles.expandCollapseItem, { [styles.current]: item.isCurrent })}
                    >
                        {renderItem(item)}
                        <div
                            className={cx(styles.expandCollapseContainer)}
                            // @ts-ignore
                            style={{ backgroundColor: item.isCurrent ? selectedBgColor : backgroundColor }}
                        >
                            <span
                                className={cx(styles.arrowDown)}
                                // @ts-ignore
                                style={{ ...expandIconStyles, marginTop: -iconMargin }}
                            />
                            <span
                                className={cx(styles.arrowUp)}
                                // @ts-ignore
                                style={{ ...expandIconStyles, marginTop: iconMargin }}
                            />
                        </div>
                    </div>
                    <ul>
                        { renderChildren(item.children) }
                    </ul>
                </li>
            );
        });
    };

    return [
        <div
            key="mm"
            id="mm"
            className={cx(styles.mobileMenu, { 'on': isMenuOpen })}
            style={menuOpenStyles}
        >
            <ul>{ renderChildren(menuItems) }</ul>
        </div>,
        <div
            key="mm-overlay"
            id="mm-overlay"
            className={cx(styles.mobileMenu_overlay, { 'on': isMenuOpen })}
        />
    ];
};

