import React from 'react';
import { useSelector } from "react-redux";

import { setAddressCityAction } from "./action";
import { selectAddressCity, selectAddressCountryCode } from "../../../../../common/selectors";
import { placeholderFieldFormatMap } from "../../../../../../../../wbtgen/src/components/SiteSettings/General/utils";
import {
    LOCALITY
} from "../../../../../../../../wbtgen/src/components/SiteSettings/BusinessListing/GoogleMyBusiness/Mappers/gmbErrorFields";
import { AddressSettingsCommonForm } from "./AddressSettingsCommonForm";

const { getCountryFormat } = require('../../../../../../../../server/shared/address/getCountryFormat.js');

export const AddressSettingsCityForm = () => {
    const countryFormat = getCountryFormat(useSelector(selectAddressCountryCode));
    return (
        <AddressSettingsCommonForm
            label="msg: generalInfo.city.label {City}"
            placeholder={placeholderFieldFormatMap[countryFormat].city}
            selector={selectAddressCity}
            handleChangeAction={setAddressCityAction}
            errorField={LOCALITY}
        />
    );
};
