export const
    UPDATE_THEME_PALETTE = 'UPDATE_THEME_PALETTE',
    DUPLICATE_CURRENT_STYLESHEET = "DUPLICATE_CURRENT_STYLESHEET",
    STYLESHEET_DUPLICATED = 'STYLESHEET_DUPLICATED',
    DELETE_CURRENT_STYLESHEET = "DELETE_CURRENT_STYLESHEET",
    CANCEL_DELETE_CURRENT_STYLESHEET = "CANCEL_DELETE_CURRENT_STYLESHEET",
    STYLESHEET_DELETED = 'STYLESHEET_DELETED',
    STYLESHEET_REPLACE_MISSING_ASSETS = 'STYLESHEET_REPLACE_MISSING_ASSETS',
    STYLESHEET_DELETE_MISSING_ASSETS = 'STYLESHEET_DELETE_MISSING_ASSETS',
    UPDATE_STYLESHEET = 'UPDATE_STYLESHEET',
    CHECK_STYLESHEET_DELETABLE_REQUEST = 'CHECK_STYLESHEET_DELETABLE_REQUEST',
    CHECK_STYLESHEET_DELETABLE_SUCCESS = 'CHECK_STYLESHEET_DELETABLE_SUCCESS',
    CHECK_STYLESHEET_DELETABLE_FAILURE = 'CHECK_STYLESHEET_DELETABLE_FAILURE';
