import { WidgetsVimeoKind } from './kind';
import { VimeoVideoView } from './view/index';
import { calcRenderProps } from './calcRenderProps';

// See wbtgen/src/view/oneweb/registry/preview.js
const WidgetsVimeoPreviewConfig = {
    kind: WidgetsVimeoKind,
    view: VimeoVideoView,
    calcProps: ({ component }) => calcRenderProps({ component, componentExtension: { isTransient: false } }),
};

export { WidgetsVimeoPreviewConfig };
