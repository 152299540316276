import * as Actions from './actionTypes';
import { SessionData } from "../../../../src/dashboard/src/components/Window/windowStorageUtils";

export const
    handleMarketgooTaskAction = (sessionData: SessionData) => ({
        type: Actions.HANDLE_MARKETGOO_TASK_ACTION,
        payload: sessionData
    }),

    openMarketgooTaskBannerAction = (payload: { taskId: string, pageId?: string }) => ({
        type: Actions.OPEN_MARKETGOO_TASK_BANNER_ACTION,
        payload,
    }),
    closeMarketgooTaskBannerAction = () => ({ type: Actions.CLOSE_MARKETGOO_TASK_BANNER_ACTION }),

    dismissMarketgooTaskBannerAction = () => ({ type: Actions.DISMISS_MARKETGOO_TASK_BANNER_ACTION }),
    marketgooTaskDoneAction = () => ({ type: Actions.MARKETGOO_TASK_DONE_ACTION });
