import makeEpic from '../../../../../epics/makeEpic';
import valueActionType from "./valueActionType";
import * as Actions from '../../actionTypes';
import { FormElementsDialogId } from "../../dialogIds";
import openDialog from "../../../../App/actionCreators/openDialog";
import closeDialog from "../../../../App/actionCreators/closeDialog";
import { convertFieldForUI, addDisplayValues, replaceDisplayValues, extractKey } from "../../utility";
import { MinOptions } from "../../constants";
import getDefaultState from "../../getDefaultState";
import { ReceiveOnlySelectedComponentSelector } from "../../../../Workspace/epics/componentsEval/selectorActionTypes";

const getAddFieldsDefaultState = () => ({
    addFields: false,
    isDisplayValEmpty: false,
    allFields: [
        { name: "name", selected: false },
        { name: "website", selected: false },
        { name: "email", selected: false },
        { name: "company", selected: false },
        { name: "phone", selected: false },
        { name: "message", selected: false },
        { name: "text", selected: false },
        { name: "number", selected: false },
        { name: "dropdown", selected: false },
        { name: "checkbox", selected: false },
        { name: "radio", selected: false },
        { name: "fileupload", selected: false }
    ]
});

const getNextKey = (key, formElements) => {
    let keyIndex = -1;
    Object.keys(formElements).forEach(id => {
        if (key === id) {
            keyIndex = Math.max(keyIndex, 0);
        } else if (key === extractKey(id)) {
            keyIndex = Math.max(keyIndex, +id.split(key)[1]);
        }
    });
    return keyIndex < 0 ? key : key + (keyIndex + 1);
};

const getValidValue = (newValue, oldValue, allowSpace) => {
    return ((!allowSpace && newValue) ? newValue.trim() : (newValue || oldValue));
};

const updateField = (state, field, updateObject) => {
    const displayVal = updateObject.displayName;
    return {
        state: {
            ...state,
            minOptionsCount: 0,
            formElements: {
                ...state.formElements,
                [field]: {
                    ...state.formElements[field],
                    ...updateObject
                }
            },
            isDisplayValEmpty: displayVal === ""
        }
    };
};

const getDefaultEpicState = (component) => ({
    selectedComponentId: component.id,
    formElements: addDisplayValues(component.formElements),
    formElementsOrder: component.formElementsOrder,
    selectedField: "",
    minOptionsCount: 0
});

const makeDialogCloseUpdater = conditions => ({
    conditions,
    reducer: () => ({ state: null, actionToDispatch: closeDialog() })
});

export default makeEpic({
    valueActionType,
    updaters: [
        {
            conditions: [
                ReceiveOnlySelectedComponentSelector,
                Actions.CONTACT_FORM_EDIT_FIELDS
            ],
            reducer: ({ values: [component] }) =>
                ({ state: getDefaultEpicState(component), actionToDispatch: openDialog(FormElementsDialogId) })
        },
        {
            conditions: [
                ReceiveOnlySelectedComponentSelector,
                Actions.CONTACT_FORM_ADD_FIELDS
            ],
            reducer: ({ values: [component], state }) => {
                if (!state) {
                    return {
                        state: {
                            ...getDefaultEpicState(component),
                            ...getAddFieldsDefaultState(),
                            addFields: true,
                            selectedField: '',
                        }
                    };
                }
                return { state: { ...state, minOptionsCount: 0, ...getAddFieldsDefaultState(), addFields: true, selectedField: '' } };
            }
        },
        {
            conditions: [Actions.CONTACT_FORM_SELECT_FIELD],
            reducer: ({ values: [field], state }) => ({
                state: {
                    ...state,
                    minOptionsCount: 0,
                    selectedField: field,
                    formElements: replaceDisplayValues(state.formElements, field),
                    addFields: false
                }
            })
        },
        makeDialogCloseUpdater([Actions.CONTACT_FORM_EDIT_FIELDS_SAVE]),
        makeDialogCloseUpdater([Actions.CONTACT_FORM_EDIT_FIELDS_CANCELED]),
        {
            conditions: [Actions.CONTACT_FORM_ADD_FIELDS_SAVE],
            reducer: ({ state }) => {
                const { formElements, formElementsOrder, allFields } = state,
                    defaultState = getDefaultState(),
                    fieldsTobeAdded = allFields.filter(field => field.selected);
                let newElements = {},
                    nextKey,
                    newFormElementsOrder = formElementsOrder.slice(0),
                    newSelectedField = { selectedField: null };

                if (fieldsTobeAdded.length > 0) {
                    fieldsTobeAdded.forEach(({ name }) => {
                        nextKey = getNextKey(name, formElements);
                        newElements[nextKey] = convertFieldForUI(defaultState[name]);
                        newFormElementsOrder.push(nextKey);
                    });
                    newSelectedField.selectedField = newFormElementsOrder[newFormElementsOrder.length - 1];
                    return {
                        state: {
                            ...state,
                            ...getAddFieldsDefaultState(),
                            ...newSelectedField,
                            minOptionsCount: 0,
                            selectedField: '',
                            formElements: { ...state.formElements, ...newElements },
                            formElementsOrder: newFormElementsOrder,
                        }
                    };
                }

                return {
                    state: {
                        ...state,
                        selectedField: '',
                        addFields: false
                    }
                };
            }
        },
        {
            conditions: [Actions.CONTACT_FORM_ADD_FIELDS_DND_SAVE],
            reducer: ({ values: [{ fieldName, dropIdx }], state }) => {
                const { formElements, formElementsOrder, allFields } = state,
                    defaultState = getDefaultState(),
                    fieldsTobeAdded = allFields.filter(field => field.name === fieldName);
                let newElements = {},
                    nextKey,
                    newFormElementsOrder = formElementsOrder.slice(0),
                    newSelectedField = { selectedField: null };

                if (fieldsTobeAdded.length > 0) {
                    fieldsTobeAdded.forEach(({ name }) => {
                        nextKey = getNextKey(name, formElements);
                        newElements[nextKey] = convertFieldForUI(defaultState[name]);
                        newFormElementsOrder.splice(dropIdx, 0, nextKey);
                    });
                    newSelectedField.selectedField = newFormElementsOrder[newFormElementsOrder.length - 1];
                    return {
                        state: {
                            ...state,
                            ...getAddFieldsDefaultState(),
                            ...newSelectedField,
                            minOptionsCount: 0,
                            selectedField: '',
                            formElements: { ...state.formElements, ...newElements },
                            formElementsOrder: newFormElementsOrder,
                            addFields: true
                        }
                    };
                }

                return {
                    state: {
                        ...state,
                        selectedField: '',
                        addFields: false
                    }
                };
            }
        },
        {
            conditions: [Actions.CONTACT_FORM_DELETE_FIELD],
            reducer: ({ values: [field], state }) => {
                const { formElements, formElementsOrder, selectedField } = state;
                let updatedElements = Object.assign({}, formElements), // eslint-disable-line prefer-object-spread
                    newOrder = formElementsOrder.slice(0),
                    newSelectedState = { selectedField: null },
                    addFieldsState;
                const index = newOrder.indexOf(field);
                newOrder.splice(index, 1);
                delete updatedElements[field];
                if (selectedField === field) {
                    newSelectedState.selectedField = newOrder[((index > 0) ? (index - 1) : 0)];
                    if (newSelectedState.selectedField) {
                        updatedElements = replaceDisplayValues(updatedElements, newSelectedState.selectedField);
                    }
                }
                if (!newOrder.length) {
                    addFieldsState = getAddFieldsDefaultState();
                    addFieldsState.addFields = true;
                }
                return {
                    state: {
                        ...state,
                        ...newSelectedState,
                        ...addFieldsState,
                        selectedField: '',
                        minOptionsCount: 0,
                        formElements: updatedElements,
                        formElementsOrder: newOrder
                    }
                };
            }
        },
        {
            conditions: [Actions.CONTACT_FORM_FIELD_TITLE_CHANGED],
            reducer: ({ values: [{ field, title }], state }) => {
                const updateObject = {
                    name: getValidValue(title, state.formElements[field].name, true),
                    displayName: title
                };
                return updateField(state, field, updateObject);
            }
        },
        {
            conditions: [Actions.CONTACT_FORM_FIELD_FILE_TYPE_CHANGED],
            reducer: ({ values: [{ field, fileType }], state }) => {
                const updateObject = {
                    inputType: fileType,
                };
                return updateField(state, field, updateObject);
            }
        },
        {
            conditions: [Actions.CONTACT_FORM_FIELD_REQUIRED_TOGGLED],
            reducer: ({ values: [field], state }) => {
                const updateObject = { isRequired: !state.formElements[field].isRequired };
                return updateField(state, field, updateObject);
            }
        },
        {
            conditions: [Actions.CONTACT_FORM_FIELD_ERR_MSG_TOGGLED],
            reducer: ({ values: [{ field, isRequired }], state }) => {
                if (isRequired) {
                    const updateObject = { hasCustomErrMsg: !state.formElements[field].hasCustomErrMsg };
                    return updateField(state, field, updateObject);
                } else {
                    return { state };
                }
            }
        },
        {
            conditions: [Actions.CONTACT_FORM_FIELD_ERROR_MESSAGE_CHANGED],
            reducer: ({ values: [{ field, message }], state }) => {
                const updateObject = {
                    errorMessage: getValidValue(message, state.formElements[field].errorMessage, false),
                    displayErrorMessage: message
                };
                return updateField(state, field, updateObject);
            }
        },
        {
            conditions: [Actions.CONTACT_FORM_FIELD_OPTION_CHANGED],
            reducer: ({ values: [{ field, option, index }], state }) => {
                let values = state.formElements[field].values.slice(0);
                let allowSpace = false;
                let oldValue = { value: getValidValue(option, values[index].value, allowSpace), displayValue: option };
                values.splice(index, 1, oldValue);
                return updateField(state, field, { values });
            }
        },
        {
            conditions: [Actions.CONTACT_FORM_FIELD_OPTION_DELETED],
            reducer: ({ values: [{ field, index }], state }) => {
                const key = extractKey(field);
                if (key) {
                    let values = state.formElements[field].values.slice(0);
                    if (MinOptions[key] === values.length) {
                        return { state: { ...state, minOptionsCount: values.length } };
                    }
                    values.splice(index, 1);
                    return updateField(state, field, { values });
                }
                return { state };
            }
        },
        {
            conditions: [Actions.CONTACT_FORM_FIELD_OPTION_ADDED],
            reducer: ({ values: [{ field }], state }) => {
                const defaultState = getDefaultState();
                let values = state.formElements[field].values.slice(0);
                values.push({ value: defaultState.newOption, displayValue: defaultState.newOption });
                return updateField(state, field, { values });
            }
        },
        {
            conditions: [Actions.CONTACT_FORM_CHANGED_FIELDS_ORDER],
            reducer: ({ values: [{ formElementsOrder }], state }) => ({ state: { ...state, formElementsOrder } })
        },
        {
            conditions: [Actions.CONTACT_FORM_FIELD_TOGGLED],
            reducer: ({ values: [field], state }) => {
                const { allFields } = state;
                let fields = [],
                    index = allFields.findIndex(fieldObj => fieldObj.name === field);
                if (index !== -1) {
                    fields = allFields.slice(0);
                    //@ts-ignore
                    fields.splice(index, 1, { name: field, selected: !allFields[index].selected });
                    return { state: { ...state, allFields: fields } };
                }
                return { state };
            }
        },
        {
            conditions: [Actions.CONTACT_FORM_ADD_FIELDS_CANCELED],
            reducer: ({ state }) => ({ state: { ...state, ...getAddFieldsDefaultState() } })
        }
    ]
});
