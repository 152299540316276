import * as React from 'react';
import { $ } from 'tinymce';
import cx from 'classnames';
import styles from './styles.css';
import Icons from "../../../../view/Icons/index";
import { Msg } from "../../../../view/intl/index";
import HiddenComponent from "./hiddenComponent";
import type { HiddenComponentsProps } from "../../flowTypes";
import * as Actions from "../../actionTypes";

const HideIcon = Icons.HIDDEN;
const CloseIcon = Icons.CLOSE_LIGHT;
const hiddenElementLabel = <Msg k="mobile.hidden.elements.label">Hidden elements</Msg>;
const emptyHiddenElements = <Msg k="mobile.empty.hidden.elements.label">No hidden elements</Msg>;

type Props = {
    hiddenComponents: HiddenComponentsProps,
    dispatch: Dispatch,
    showAutoColorLeftPanel: boolean
}

const paddingBottom = 35;

export class HiddenComponents extends React.PureComponent <Props> {
    panelRef: React.RefObject<HTMLDivElement>;
    panelBodyRef: React.RefObject<HTMLDivElement>;

    constructor() {
        // @ts-ignore
        super();
        this.panelRef = React.createRef();
        this.panelBodyRef = React.createRef();
    }
    togglePanel(isExpanded: boolean, e: React.SyntheticEvent) {
        e.stopPropagation();
        this.props.dispatch({
            type: Actions.MOBILE_EDITOR_EXPAND_COLLAPSE_HIDDEN_PANEL,
            payload: { isExpanded: !isExpanded }
        });
    }

    calculatePanelBodyHeight() {
        const mobileActiveDiv = $(this.panelRef.current).next('.mobile-view-active')[0]; // mobile view is active ele.
        const panelBody = this.panelBodyRef.current;
        const panel = this.panelRef.current;

        // @ts-ignore this can create a bug if calculatePanelBodyHeight method is called before ref initialization
        return mobileActiveDiv.offsetTop - (panel.offsetTop + panelBody.offsetTop) - paddingBottom;
    }

    componentDidUpdate() {
        if (this.panelBodyRef.current) {
            this.panelBodyRef.current.setAttribute('style', `max-height: ${this.calculatePanelBodyHeight()}px;`);
        }
    }

    render() {
        const { hiddenComponents: { components, isExpanded, latestHiddenCmpId }, showAutoColorLeftPanel } = this.props;
        return (
            <div
                ref={this.panelRef}
                className={
                    cx(styles.panel, { [styles.expanded]: isExpanded, [styles.panelWhenAutoColorLeftPanel]: showAutoColorLeftPanel })
                }
            >
                <div className={styles.panelHeader}>
                    <div className={styles.titleWrapper} onClick={this.togglePanel.bind(this, isExpanded)}>
                        <HideIcon className={styles.hideIcon} />
                        <span className={styles.title}>{hiddenElementLabel}</span>
                    </div>
                    <CloseIcon className={styles.closeIcon} onClick={this.togglePanel.bind(this, isExpanded)} />
                </div>
                {
                    isExpanded &&
                    <div className={styles.panelBodyWrapper}>
                        <div ref={this.panelBodyRef} className={styles.panelBody}>
                            {
                                !components.length &&
                                <div className={styles.emptyBody}>
                                    <span>{emptyHiddenElements}</span>
                                </div>
                            }
                            {
                                components.map((cmp) =>
                                    // @ts-ignore
                                    <HiddenComponent
                                        key={cmp.id}
                                        {...this.props}
                                        cmpProps={cmp}
                                        isLatestHiddenCmp={latestHiddenCmpId === cmp.id}
                                    />)
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }
}
