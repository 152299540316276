import React from 'react';
import * as styles from "./PropertiesPanel.css";
import { injectIntl } from "../../../view/intl/index";
import { openPinDialogAC } from "../../PinComponentDialog/actionCreators/updateComponentPin";
import { NoMouseDownAndUp } from "../../../view/common/NoMouseEventsPropagation";

type Props = {
    intl: Intl,
    dispatch: Dispatch,
    onClick: (e: React.SyntheticEvent) => void,
    componentId: string
}

export const PinSettingsIcon = injectIntl(({ onClick, dispatch, intl, componentId }: Props) => {
    const props = {
        className: styles.settingsIconContainer,
        onClick: onClick || ((e) => {
            e.stopPropagation();
            dispatch(openPinDialogAC(componentId));
        }),
        ...NoMouseDownAndUp,
        "data-title": intl.msgJoint("msg: common.pinSettings {Pin settings}")
    };

    return (
        <div {...props}>
            <span className={styles.pinIcon} />
        </div>
    );
});

