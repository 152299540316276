import * as R from 'ramda';

import normalBackgroundConfigurationDialogId from './normalBackgroundConfigurationDialogId';
import normalTextShadowConfigurationDialogId from './normalTextShadowConfigurationDialogId';

import mouseOverBackgroundConfigurationDialogId from './mouseOverBackgroundConfigurationDialogId';
import mouseOverTextShadowConfigurationDialogId from './mouseOverTextShadowConfigurationDialogId';

import selectedBackgroundConfigurationDialogId from './selectedBackgroundConfigurationDialogId';
import selectedTextShadowConfigurationDialogId from './selectedTextShadowConfigurationDialogId';

import expandableBackgroundConfigurationDialogId from './expandableBackgroundConfigurationDialogId';
import expandableSpacingConfigurationDialogId from './expandableSpacingConfigurationDialogId';
import expandableTextShadowConfigurationDialogId from './expandableTextShadowConfigurationDialogId';

import expandedBackgroundConfigurationDialogId from './expandedBackgroundConfigurationDialogId';
import expandedTextShadowConfigurationDialogId from './expandedTextShadowConfigurationDialogId';

import dividerBackgroundConfigurationDialogId from './dividerBackgroundConfigurationDialogId';
import dividerSpacingConfigurationDialogId from './dividerSpacingConfigurationDialogId';
import dividerBorderStyleConfigurationDialogId from './dividerBorderStyleConfigurationDialogId';

import levelContainerBackgroundConfigurationDialogId from './levelContainerBackgroundConfigurationDialogId';
import levelContainerSpacingConfigurationDialogId from './levelContainerSpacingConfigurationDialogId';
import levelContainerBorderStyleConfigurationDialogId from './levelContainerBorderStyleConfigurationDialogId';

import { createOption } from '../../../view/common/Combobox/utils';
import TextTransformTypes from './TextTransformTypes';

export const
    menuTabLabel = 'msg: common.gs.tab.menu.label {Menu}',
    TextTransform = TextTransformTypes,
    Types = {
        NORMAL: 'NORMAL',
        MOUSE_OVER: 'MOUSE_OVER',
        SELECTED: 'SELECTED',
        EXPANDABLE: 'EXPANDABLE',
        EXPANDED: 'EXPANDED',
        DIVIDER: 'DIVIDER',
        LEVEL_CONTAINER: 'LEVEL_CONTAINER',
        MULTIPLE_LEVELS: 'MULTIPLE_LEVELS'
    },
    Messages = {
        [Types.NORMAL]: {
            title: 'msg: gs.menu.normal.label {Menu items: Normal}',
            subtitle: 'msg: gs.menu.normal.sublabel {Used for menu items with no subpages.}'// eslint-disable-line
        },
        [Types.MOUSE_OVER]: {
            title: 'msg: gs.menu.mouseover.label {Menu items: Mouse over}',
            subtitle: 'msg: gs.menu.mouseover.sublabel {These additional styling options are activated when you move your mouse over an item in the menu.}'// eslint-disable-line
        },
        [Types.SELECTED]: {
            title: 'msg: gs.menu.selected.label {Menu items: Selected}',
            subtitle: 'msg: gs.menu.selected.sublabel {Activated when an item in the menu is selected.}'// eslint-disable-line
        },
        [Types.EXPANDABLE]: {
            title: 'msg: gs.menu.expandable.label {Menu items: Expandable}',
            subtitle: 'msg: gs.menu.expandable.sublabel {Default state for pages having sub pages.}',// eslint-disable-line
            dropDownSubtitle: 'msg: gs.menu.expandable.dropDownTitle {You are not using the default dropdown arrow. The image and colour defined in \"Background\" are used instead.}' // eslint-disable-line
        },
        [Types.EXPANDED]: {
            title: 'msg: gs.menu.expanded.label {Menu items: Expanded}',
            subtitle: 'msg: gs.menu.expanded.sublabel {Mouse over state for pages having sub pages.}'// eslint-disable-line
        },
        [Types.DIVIDER]: {
            title: 'msg: gs.menu.divider.label {Menu divider}',
            subtitle: 'msg: gs.menu.divider.sublabel {These additional styling options create a box between each menu item.}'// eslint-disable-line
        },
        [Types.LEVEL_CONTAINER]: {
            title: 'msg: gs.menu.levelContainer.label {Menu level container}',
            subtitle: 'msg: gs.menu.levelContainer.sublabel {These additional styling options create a box around menu items at each level.}'// eslint-disable-line
        },
        [Types.MULTIPLE_LEVELS]: {
            title: 'msg: gs.menu.multipleLevels.label {Multiple levels}',
            subtitle: 'msg: gs.menu.multipleLevels.sublabel {These additional styling options apply under specific conditions when there are multiple levels showing.}'// eslint-disable-line
        }
    },
    DialogIds = {
        [Types.NORMAL]: {
            background: normalBackgroundConfigurationDialogId,
            textShadow: normalTextShadowConfigurationDialogId,
            spacing: null
        },
        [Types.MOUSE_OVER]: {
            background: mouseOverBackgroundConfigurationDialogId,
            textShadow: mouseOverTextShadowConfigurationDialogId,
            spacing: null
        },
        [Types.SELECTED]: {
            background: selectedBackgroundConfigurationDialogId,
            textShadow: selectedTextShadowConfigurationDialogId,
            spacing: null
        },
        [Types.EXPANDABLE]: {
            background: expandableBackgroundConfigurationDialogId,
            spacing: expandableSpacingConfigurationDialogId,
            textShadow: expandableTextShadowConfigurationDialogId
        },
        [Types.EXPANDED]: {
            background: expandedBackgroundConfigurationDialogId,
            textShadow: expandedTextShadowConfigurationDialogId,
            spacing: null
        },
        [Types.DIVIDER]: {
            background: dividerBackgroundConfigurationDialogId,
            spacing: dividerSpacingConfigurationDialogId,
            borderStyle: dividerBorderStyleConfigurationDialogId
        },
        [Types.LEVEL_CONTAINER]: {
            background: levelContainerBackgroundConfigurationDialogId,
            spacing: levelContainerSpacingConfigurationDialogId,
            borderStyle: levelContainerBorderStyleConfigurationDialogId
        }
    },
    MIN_TREE_MENU_TEXT_INDENT = 0,
    MAX_TREE_MENU_TEXT_INDENT = 101,

    TreeMenuTextIndentOptions = R.range(MIN_TREE_MENU_TEXT_INDENT, MAX_TREE_MENU_TEXT_INDENT).map(createOption),

    MIN_DRODOWN_WIDTH = 50,
    MAX_DROPDOWN_WIDTH = 501,
    DropdownWidthOptions = R.range(MIN_DRODOWN_WIDTH, MAX_DROPDOWN_WIDTH).map(createOption);
