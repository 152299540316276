import * as R from "ramda";
import valueActionType from "./valueActionType";
import makeEpic from "../../../../epics/makeEpic";
import { ReceiveOnlyComponentsMap } from "../../../Workspace/epics/componentsEval/selectorActionTypes";
import * as Actions from "../../actionTypes";
import mobileViewEditorVAT from "../reorder/valueActionType";
import { mobileEditorHideComponentsAC, removeOutlineAndSelectionMobileViewEditorAC } from "../../actionCreators";
import { optional, receiveOnly } from "../../../../epics/makeCondition";
import { cmpTypes } from "../../constants";
import getCmpTypeById from "../../getCmpTypeById";
import type { Groups } from "../../flowTypes";

const getComponentChildren = (cmpId, hiddenComponents, cmpMap?) => {
    let children = hiddenComponents.get(cmpId);
    if (!children) {
        children = Array.from(hiddenComponents.keys()).filter(id => R.path([id, 'relIn', 'id'], cmpMap) === cmpId);
    }
    const getAllChildren = (cmpIds) => {
        cmpIds.forEach((cmpId) => {
            const cmps = hiddenComponents.get(cmpId) || [];
            children = [...children, ...cmps];
            getAllChildren(cmps);
        });
    };
    getAllChildren(children);
    return children;
};

const getChildToParentMap = (hiddenTree) => {
    const map = {};
    hiddenTree.forEach((children, parent) => children.forEach((child) => { map[child] = parent; }));
    return map;
};

const getHiddenCmpList = (cmpMap, hiddenComponents) => {
    const childToParentMap = getChildToParentMap(hiddenComponents);

    return Array.from(hiddenComponents.keys()).reduce(
        (result: any, key: any) => {
            const cmp = cmpMap[key];
            const getHiddenParent = (id) => {
                if (childToParentMap[id]) {
                    return null;
                }
                return id;
            };
            const cmpId = getHiddenParent(key);
            if (cmpId) {
                result.push(getCmpTypeById(cmpId) === cmpTypes.group ? { id: cmpId } : cmp);
            }
            return result;
        },
        []
    );
};

const getParentGroupId = (cmpId: string, allGroups: Groups) => {
    let parentId = cmpId;
    Object.keys(allGroups).forEach(groupId => {
        if (allGroups[groupId].indexOf(cmpId) !== -1) {
            parentId = groupId;
        }
    });
    return parentId;
};

const getParentHoverGroupId = (cmpId: string, groupsForView: Groups, allGroups: Groups) => {
    if (!cmpId) {
        return null;
    }
    let newId = cmpId;
    if (getCmpTypeById(cmpId) === cmpTypes.group) {
        if (groupsForView[cmpId]) {
            return cmpId;
        } else if (allGroups[cmpId]) {
            newId = allGroups[cmpId][0];
        }
    }
    return getParentGroupId(newId, groupsForView);
};

export default makeEpic({
    defaultState: {
        components: [],
        isExpanded: false,
        latestUnhiddenCmpId: null,
        latestHiddenCmpId: null,
        hoverUnhiddenCmpId: null,
        hoverOriginalUnhiddenCmpId: null,
        hoverUnhiddenCmpIdIndex: -1,
        mouseOverHiddenCmpId: null,
        hiddenChildCmpsOverHide: []
    },
    defaultScope: {
        hiddenComponents: null
    },
    valueActionType,
    updaters: [
        {
            conditions: [
                optional(Actions.OPEN_MOBILE_VIEW_EDITOR_CLICKED),
                optional(Actions.MOBILE_EDITOR_COMPONENT_DRAGGING_STARTED),
                optional(Actions.MOBILE_EDITOR_WORKSPACE_UNFOCUSED)
            ],
            reducer: ({ state, scope }) => {
                return {
                    state: {
                        ...state,
                        isExpanded: false
                    },
                    scope
                };
            }
        },
        {
            conditions: [
                mobileViewEditorVAT,
                ReceiveOnlyComponentsMap
            ],
            reducer: ({ values: [{ show, hiddenComponents }, componentsMap], state, scope }) => {
                if (!show || scope.hiddenComponents === hiddenComponents) {
                    return { state, scope };
                }
                let components: any = getHiddenCmpList(componentsMap, hiddenComponents);
                const unhiddenCmpId = state.hoverOriginalUnhiddenCmpId;
                if (unhiddenCmpId) {
                    const cmp = getCmpTypeById(unhiddenCmpId) === cmpTypes.group ?
                        { id: unhiddenCmpId } : componentsMap[unhiddenCmpId];
                    components.splice(state.hoverUnhiddenCmpIdIndex, 0, cmp);
                }
                let actionToDispatch;
                // Unselect the component from MVE, if a component is hidden or unhidden
                if (state.components.length !== components.length) {
                    actionToDispatch = removeOutlineAndSelectionMobileViewEditorAC();
                }
                return {
                    state: {
                        ...state,
                        components,
                        latestUnhiddenCmpId: null,
                        latestHiddenCmpId: null
                    },
                    actionToDispatch,
                    scope: { ...scope, hiddenComponents }
                };
            }
        },
        {
            conditions: [
                Actions.MOBILE_EDITOR_EXPAND_COLLAPSE_HIDDEN_PANEL
            ],
            reducer: ({ values: [{ isExpanded }], state, scope }) => ({
                state: {
                    ...state,
                    isExpanded,
                    latestUnhiddenCmpId: null,
                    latestHiddenCmpId: null,
                    hoverUnhiddenCmpId: null,
                    hoverOriginalUnhiddenCmpId: null
                },
                actionToDispatch: removeOutlineAndSelectionMobileViewEditorAC(),
                scope
            })
        },
        {
            conditions: [
                receiveOnly(mobileViewEditorVAT),
                Actions.MOBILE_EDITOR_UN_HIDE_COMPONENTS
            ],
            reducer: ({ values: [{ allGroups },
                { componentId, unhide, skipUndo, hover }], state, scope }) => {
                let children, hiddenChildCmpsOverHide;
                if (unhide) {
                    if (getCmpTypeById(componentId) === cmpTypes.group && allGroups[componentId]) {
                        children = allGroups[componentId];
                    } else {
                        children = getComponentChildren(componentId, scope.hiddenComponents);
                    }
                    hiddenChildCmpsOverHide = children;
                } else {
                    children = state.hiddenChildCmpsOverHide;
                    hiddenChildCmpsOverHide = [];
                }
                return {
                    state: {
                        ...state,
                        hiddenChildCmpsOverHide
                    },
                    actionToDispatch: {
                        type: Actions.MOBILE_EDITOR_TOGGLE_HIDE,
                        payload: { componentId, children, unhide, skipUndo, hover }
                    },
                    scope
                };
            }
        },
        {
            conditions: [
                receiveOnly(mobileViewEditorVAT),
                Actions.MOBILE_EDITOR_HIDE_COMPONENTS
            ],
            reducer: ({ values: [{ allGroups }, { componentId }], state, scope }) => {
                let children;
                if (getCmpTypeById(componentId) === cmpTypes.group && allGroups[componentId]) {
                    children = allGroups[componentId];
                }
                return {
                    state: {
                        ...state
                    },
                    actionToDispatch: {
                        type: Actions.MOBILE_EDITOR_TOGGLE_HIDE,
                        payload: { componentId, children }
                    },
                    scope
                };
            }
        },
        {
            conditions: [
                Actions.MOBILE_EDITOR_TOGGLE_HIDE,
                receiveOnly(mobileViewEditorVAT)
            ],
            reducer: ({ values: [{ componentId, unhide, hover, skipUndo },
                { allGroups, groupsForView }], state, scope }) => {
                const childToParentMap = getChildToParentMap(scope.hiddenComponents),
                    getHiddenParentId = (cmpId) => {
                        const parentId = childToParentMap[cmpId];
                        if (parentId) {
                            return getHiddenParentId(parentId);
                        }
                        return cmpId;
                    };
                let newState,
                    latestHiddenCmpId = (unhide || skipUndo) ? null : getHiddenParentId(componentId),
                    parentId = getParentHoverGroupId(componentId, groupsForView, allGroups);
                if (hover) {
                    newState = {
                        latestUnhiddenCmpId: null,
                        hoverUnhiddenCmpId:
                            getParentHoverGroupId(state.hoverUnhiddenCmpId, groupsForView, allGroups),
                        mouseOverHiddenCmpId:
                            getParentHoverGroupId(state.mouseOverHiddenCmpId, groupsForView, allGroups),
                    };
                } else {
                    newState = {
                        hoverUnhiddenCmpId: null,
                        hoverOriginalUnhiddenCmpId: null,
                        mouseOverHiddenCmpId: null,
                        latestUnhiddenCmpId: unhide ? parentId : null,
                    };
                }
                return {
                    state: {
                        ...state,
                        ...newState,
                        latestHiddenCmpId,
                        isExpanded: true
                    },
                    scope
                };
            }
        },
        {
            conditions: [
                Actions.MOBILE_EDITOR_RESET_STATUS_FLAGS
            ],
            reducer: ({ state, scope }) => {
                return {
                    state: {
                        ...state,
                        latestUnhiddenCmpId: null,
                        latestHiddenCmpId: null,
                        hoverUnhiddenCmpId: null,
                        hoverOriginalUnhiddenCmpId: null
                    },
                    scope
                };
            }
        },
        {
            conditions: [
                Actions.MOBILE_EDITOR_MOUSE_ACTION_HIDDEN_PANEL,
                receiveOnly(mobileViewEditorVAT)
            ],
            reducer: ({ values: [{ componentId, hover }, { allGroups }], state, scope }) => {
                let
                    multipleActionsToDispatch: Action[] = [{
                        type: Actions.MOBILE_EDITOR_UN_HIDE_COMPONENTS,
                        payload: {
                            componentId, unhide: hover, hover, skipUndo: true
                        }
                    }],
                    newState: Record<string, any> = {
                        mouseOverHiddenCmpId: null,
                        hoverUnhiddenCmpId: null,
                        hoverOriginalUnhiddenCmpId: null,
                        hoverUnhiddenCmpIdIndex: -1
                    },
                    parentId = getParentGroupId(componentId, allGroups);
                if (hover) {
                    newState = {
                        mouseOverHiddenCmpId: parentId,
                        hoverUnhiddenCmpId: parentId,
                        hoverOriginalUnhiddenCmpId: componentId,
                        hoverUnhiddenCmpIdIndex: R.findIndex(R.propEq('id', componentId))(state.components)
                    };
                    multipleActionsToDispatch.push(removeOutlineAndSelectionMobileViewEditorAC());
                }
                return {
                    state: {
                        ...state,
                        ...newState
                    },
                    multipleActionsToDispatch,
                    scope
                };
            }
        },
        {
            conditions: [Actions.MOBILE_EDITOR_ACTION_HIDE_CLICKED],
            reducer: ({ values: [componentId], state, scope }) => ({
                state,
                multipleActionsToDispatch: [
                    mobileEditorHideComponentsAC(componentId),
                    removeOutlineAndSelectionMobileViewEditorAC()
                ],
                scope
            })
        }
    ]
});
