import { getCurrentPage, getInstanceCurrentPage, navigateBackword } from "../utils/navigation";
import type { StateHolder } from "../../flowTypes";
import { getDimensions } from "../utils/dimensions";

export default ({ scope, state }: StateHolder): StateHolder => {
    const
        updatedNavigation = navigateBackword(state.navigation),
        currentPage = state.instance
            ? getInstanceCurrentPage(state.instance.id, updatedNavigation)
            : getCurrentPage(updatedNavigation, state.selectedComponentWithId.component.kind),
        dimensions = getDimensions(scope.maxHeight, currentPage.height);

    return {
        scope,
        state: { ...state, navigation: updatedNavigation, currentPage, dimensions }
    };
};
