import { isEmptyTextNode } from './isEmptyTextNode';
import Node from './Node';

const getLastNode = (parent: Node) => {
    const {
        childNodes
    } = parent;
    if (!childNodes.length || (childNodes.length === 1 && isEmptyTextNode(childNodes[0]))) {
        return parent;
    }
    return getLastNode(childNodes[childNodes.length - 1]);
};

export default getLastNode;
