import { Lit } from "../../../../lit";
import type { SocialAccountsState, FacebookFeedSocialAccountDefaultState } from "./types";
import type { ReducerConfig } from "../../../../../../src/dashboard/src/common/types";
import {
    SET_FACEBOOK_FEED_ACCESS_CREDENTIALS_ACTION,
    UNSET_FACEBOOK_FEED_ACCESS_TOKEN_ACTION,
    DISCONNECT_FACEBOOK_FEED_REQUEST_ACTION,
    DISCONNECT_FACEBOOK_FEED_SUCCESS_ACTION,
    DISCONNECT_FACEBOOK_FEED_FAILURE_ACTION,
    SET_IS_FB_PAGE_MODE_ACTION,
    SET_MANAGED_FB_PAGES_ACTION,
    UNSET_MANAGED_FB_PAGES_ACTION,
    SET_SELECTED_FB_PAGE_VALUE,
    GET_FACEBOOK_FEED_USER_MEDIA_REQUEST_ACTION,
    GET_FACEBOOK_FEED_USER_MEDIA_FAILURE_ACTION,
    GET_FACEBOOK_FEED_USER_MEDIA_SUCCESS_ACTION,
    SET_TOTAL_FACEBOOK_FEED_GALLERY_COMPONENTS,
    SET_TOTAL_CURRENT_PAGE_FACEBOOK_FEED_GALLERY_COMPONENTS,
    SET_TOTAL_OTHER_PAGE_FACEBOOK_FEED_GALLERY_COMPONENTS,
} from "./actions";
import { makeInitialStateReducer } from "../../../../redux/makeReducer/makeInitialStateReducer";
import { getFacebookFeedStockData } from "./getFacebookFeedStockData";
import {
    GET_SOCIAL_MEDIA_COMPONENTS_USAGE_SUCCESS_ACTION,
} from "../../actions";

const facebookFeedSocialAccountDefaultState: FacebookFeedSocialAccountDefaultState = {
    [Lit.facebookFeedAssetsLoading]: false,
    [Lit.facebookFeedConnected]: false,
    [Lit.facebookFeedAccessToken]: undefined,
    // @ts-ignore
    [Lit.facebookFeedAssetUrls]: getFacebookFeedStockData(),
    [Lit.facebookFeedAccountName]: undefined,
    [Lit.facebookFeedAccountId]: undefined,
    [Lit.isFbPageMode]: false,
    [Lit.managedFbPages]: [],
    [Lit.fbSelectedPageValue]: undefined,
    [Lit.totalFacebookFeedGalleryComponents]: 0,
    [Lit.totalCurrentPageFacebookFeedGalleryComponents]: 0,
    [Lit.totalOtherPagesFacebookFeedGalleryComponents]: 0,
};

export const socialAccountsDefaultState: SocialAccountsState = {
    [Lit.facebookFeed]: facebookFeedSocialAccountDefaultState
};

export const socialAccountsFacebookFeedReducer = makeInitialStateReducer(
    // @ts-ignore
    socialAccountsDefaultState[Lit.facebookFeed],
    (state: SocialAccountsState, action: Action) => {
        switch (action.type) {
            case SET_FACEBOOK_FEED_ACCESS_CREDENTIALS_ACTION:
                return {
                    ...state,
                    [Lit.facebookFeedConnected]: true,
                    [Lit.facebookFeedAccessToken]: action.payload.facebookFeedAccessToken,
                    [Lit.facebookFeedAccountName]: action.payload.facebookFeedAccountName,
                    [Lit.isFbPageMode]: action.payload.isPageAccount,
                    [Lit.facebookFeedAccountId]: action.payload.facebookFeedAccountId,
                };
            case UNSET_FACEBOOK_FEED_ACCESS_TOKEN_ACTION:
                return {
                    ...state,
                    [Lit.facebookFeedConnected]: false,
                    [Lit.facebookFeedAccessToken]: null,
                    [Lit.facebookFeedAssetUrls]: getFacebookFeedStockData(),
                    [Lit.facebookFeedAccountName]: null,
                    [Lit.facebookFeedAccountId]: null,
                };
            case DISCONNECT_FACEBOOK_FEED_REQUEST_ACTION:
                return {
                    ...state,
                    [Lit.facebookFeedAssetsLoading]: true,
                };
            case DISCONNECT_FACEBOOK_FEED_SUCCESS_ACTION:
                return {
                    ...state,
                    [Lit.facebookFeedConnected]: false,
                    [Lit.facebookFeedAccessToken]: null,
                    [Lit.facebookFeedAssetUrls]: getFacebookFeedStockData(),
                    [Lit.facebookFeedAccountName]: null,
                    [Lit.facebookFeedAssetsLoading]: false,
                    [Lit.facebookFeedAccountId]: null,
                };
            case DISCONNECT_FACEBOOK_FEED_FAILURE_ACTION:
                return {
                    ...state,
                    [Lit.facebookFeedAssetsLoading]: false,
                };
            case SET_IS_FB_PAGE_MODE_ACTION:
                return {
                    ...state,
                    [Lit.isFbPageMode]: action.payload,
                };
            case SET_MANAGED_FB_PAGES_ACTION:
                return {
                    ...state,
                    [Lit.facebookFeedConnected]: true,
                    [Lit.managedFbPages]: action.payload,
                };
            case UNSET_MANAGED_FB_PAGES_ACTION:
                return {
                    ...state,
                    [Lit.facebookFeedConnected]: false,
                    [Lit.managedFbPages]: [],
                };
            case SET_SELECTED_FB_PAGE_VALUE:
                return {
                    ...state,
                    [Lit.fbSelectedPageValue]: action.payload,
                };
            case GET_FACEBOOK_FEED_USER_MEDIA_REQUEST_ACTION:
                return {
                    ...state,
                    [Lit.facebookFeedAssetUrls]: [],
                    [Lit.facebookFeedAssetsLoading]: true,
                };
            case GET_FACEBOOK_FEED_USER_MEDIA_SUCCESS_ACTION:
                return {
                    ...state,
                    [Lit.facebookFeedAssetUrls]: action.payload,
                    [Lit.facebookFeedAssetsLoading]: false,
                };
            case GET_FACEBOOK_FEED_USER_MEDIA_FAILURE_ACTION:
                return {
                    ...state,
                    [Lit.facebookFeedConnected]: false,
                    [Lit.facebookFeedAccessToken]: null,
                    [Lit.facebookFeedAssetUrls]: getFacebookFeedStockData(),
                    [Lit.facebookFeedAssetsLoading]: false,
                };
            case GET_SOCIAL_MEDIA_COMPONENTS_USAGE_SUCCESS_ACTION:
                return {
                    ...state,
                    [Lit.totalFacebookFeedGalleryComponents]: action.payload.totalFacebookFeedGalleryComponents,
                };
            case SET_TOTAL_FACEBOOK_FEED_GALLERY_COMPONENTS:
                return {
                    ...state,
                    [Lit.totalFacebookFeedGalleryComponents]: action.payload.totalComponents,
                };
            case SET_TOTAL_CURRENT_PAGE_FACEBOOK_FEED_GALLERY_COMPONENTS:
                return {
                    ...state,
                    [Lit.totalCurrentPageFacebookFeedGalleryComponents]: action.payload.totalComponents,
                };
            case SET_TOTAL_OTHER_PAGE_FACEBOOK_FEED_GALLERY_COMPONENTS:
                return {
                    ...state,
                    [Lit.totalOtherPagesFacebookFeedGalleryComponents]: action.payload.totalComponents,
                };
            default:
                return state;
        }
    }
);

export const socialAccountsFacebookFeedReducerConfig: ReducerConfig = { reducer: socialAccountsFacebookFeedReducer };
