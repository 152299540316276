import * as pageIds from './pageIds';
import { MOBILE_VIEW, MobileViewTitle } from "../../../PropertiesPanel/view/MobileView/constants";
import { LANGUAGE, LanguageViewTitle } from '../../../PropertiesPanel/view/Language/constants';

export default {
    [pageIds.MAIN]: 'msg: component.shareButtons.pp.main.title {Share buttons}',
    [pageIds.FACEBOOK_LIKE]: 'msg: component.shareButtons.pp.facebooklike.title {Facebook Like}',
    [pageIds.ALIGNMENT]: 'msg: component.shareButtons.pp.alignment.title {Alignment}',
    [LANGUAGE]: LanguageViewTitle,
    [MOBILE_VIEW]: MobileViewTitle
};
