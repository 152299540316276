import kind from './kind';
import { widthReducer as templateWidth } from "../Template/epics/template/index";
import siteFontsReducer from '../../Fonts/siteFontsReducer';
import defaultStyleIdEpic from '../commonEpics/buttonDefaultStyleId';
import stylesheetsEpic from '../../Workspace/epics/stylesheets/index';
import view from "./view/index";
import reducer from './reducer/index';
import contextMenu from './contextMenu';
import componentMainActions from './componentMainActions/index';
import actionTypes from './reducer/actionTypes';
import propertiesPanel from "./propertiesPanel/index";
import { ErrorMsgsOnAdd } from './errorMessagesOnAdd';
import adjustComponentOnAdd from './adjustComponentOnAdd';
import validateComponentAdd from './validateComponentAdd';
import handleActions from '../../../redux/makeReducer/handleActions/index';
import fontStatusReducer from "../../Fonts/fontStatusReducer";
import { WEBSHOP_MIN_WIDTH, WEBSHOP_MIN_HEIGHT } from "./constants";
import previewConfig from "./previewConfig";
import stylesheetsIdToNameMapEpic from "../../Workspace/epics/stylesheets/idToNameMap";
import cartDataEpic from "./epics/cartGlobalData/index";
import { ComponentNames } from "../constants";
import WebshopAdminModalEpic from "./WebshopAdminDialog/epic/index";
import { colorThemeSiteSettingsEpic } from "../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import calcRenderProps from './calcRenderProps';
import { componentTierManagerEpic } from "../../ComponentTierManager/epics/index";
import selectedLanguageEpic from "../../TopBar/epics/languages/index";
import subscriptionDataEpic from '../../App/epics/subscriptionData/index';

export default {
    kind,
    label: ComponentNames[kind],
    shortcutIconName: 'webshop',
    dependsOn: {
        fontStatus: fontStatusReducer,
        localeDetails: selectedLanguageEpic.reducer,
        templateWidth,
        siteFonts: siteFontsReducer,
        defaultButtonStyleId: defaultStyleIdEpic.reducer,
        subscriptionData: subscriptionDataEpic.reducer,
        globalStyles: stylesheetsEpic.reducer,
        buttonStyle: handleActions({
            [actionTypes.WEBSHOP_BUTTON_STYLES_GENERATED]: (prevstate, { payload }) => payload
        }, ''),
        temporaryHoverOpacity: handleActions({
            [actionTypes.WEBSHOP_HOVER_OPACITY_CHANGING]: (prevstate, { payload }) => payload,
            [actionTypes.WEBSHOP_HOVER_OPACITY_CHANGED]: () => null
        }, ''),
        showNote: handleActions({
            [actionTypes.WEBSHOP_WELCOME_OK_BUTTON]: () => true,
            [actionTypes.WEBSHOP_CLOSE_NOTE]: () => false
        }, ''),
        stylesheetsIdToNameMap: stylesheetsIdToNameMapEpic.reducer,
        themeSettingsData: colorThemeSiteSettingsEpic.reducer,
        webshopAdminDialog: WebshopAdminModalEpic.reducer,
        featuredProduct: handleActions({
            [actionTypes.WEBSHOP_SET_FEATURED_PRODUCT_URL]: (prevstate, { payload }) => payload
        }, ''),
        componentTierData: componentTierManagerEpic.reducer,
        timestamp: handleActions({
            [actionTypes.WEBSHOP_UPDATE_TIMESTAMP_IN_URL]: (prevstate, { payload: { timestamp } }) => timestamp
        }, 0),
    },
    calcRenderProps,
    controlsDependsOn: {
        cartData: cartDataEpic.reducer
    },
    contextMenu,
    view,
    adjustComponentOnAdd,
    propertiesPanel,
    reducer,
    validateComponentAdd,
    errorMsgsOnAdd: ErrorMsgsOnAdd,
    minDimensions: {
        width: WEBSHOP_MIN_WIDTH,
        height: WEBSHOP_MIN_HEIGHT
    },
    componentMainActions,
    mobileEditorConfig: previewConfig
};
