import React, { useState, useEffect, useRef } from 'react';
import styles from '../LinkChooserDialog.css';
import gmbStyles from '../../../../../../components/SiteSettings/BusinessListing/GoogleMyBusiness/CreateListingDialog/view/styles.css';
import { LC_LOCATION_OPTION_CHANGE_ACTION } from '../../actionTypes';
import { injectIntl, Intl, Msg } from '../../../../../intl/index';
import { LinkChooserOption } from '../LinkChooserOption';
import HorizontalSpacer from '../../../../HorizontalSpacer';
import VerticalSpacer from '../../../../VerticalSpacer';
import { openSiteSettingsDialog } from '../../../../../../components/SiteSettings/SiteSettingsDialog/actionCreators';
import { SiteSettingsTabName } from '../../../../../../components/SiteSettings/SiteSettingsDialog/constants';
import { KindToInputId } from '../../../../../../components/oneweb/TextLike/kindToInputId';
import { ADDRESS_KIND } from '../../../../../../components/oneweb/TextLike/Address/kind';
import { GlobalVariableAddressUrlKey } from '../../../../../../constants';
import { GoogleMapsAutoCompleteInput } from '../../../../../../components/inputControls/input/view/GoogleMapsAutoCompleteInput';
import Icons from '../../../../../Icons/index';

import type { SiteSettingsDialogConfigWithInput } from '../../../../../../components/SiteSettings/SiteSettingsDialog/actionCreators';

type Props = {
    intl: Intl,
    dispatch: Dispatch,
    selection: string,
    name: string;
    singleLineAddressFromSiteSettings: string,
    multiLineAddressFromSiteSettings: string,
    isAddressSet: boolean,
};

const STORED_IN_SITE_SETTINGS = 'STORED_IN_SITE_SETTINGS',
    CUSTOM = 'CUSTOM';

export const LocationTab = injectIntl((props: Props) => {
    const {
        selection, name, dispatch, singleLineAddressFromSiteSettings, multiLineAddressFromSiteSettings, isAddressSet
    } = props;
    const [activeOptionKey, setActiveOptionKey] =
        useState(selection === `#${GlobalVariableAddressUrlKey}` ? STORED_IN_SITE_SETTINGS : CUSTOM);
    const [addressSearchString, setAddressSearchString] =
        useState(selection === `#${GlobalVariableAddressUrlKey}` ? '' : name);
    const [addressSearchUrl, setAddressSearchUrl] =
        useState<string | null | undefined>(selection === `#${GlobalVariableAddressUrlKey}` ? '' : selection);
    const focusAddressFieldInSiteSettingsDialogDispatcher = () => dispatch(openSiteSettingsDialog(({
        activeTabKey: SiteSettingsTabName.GENERAL, activateInputWithId: KindToInputId[ADDRESS_KIND],
    } as SiteSettingsDialogConfigWithInput)));

    const customAddressRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if (activeOptionKey === CUSTOM && customAddressRef.current) {
            customAddressRef.current.focus();
        }
    }, [activeOptionKey]);

    return (
        <div className={styles.locationTab}>
            <Msg k="lc.location.introText" className={styles.introText}>Which location should the link go to?</Msg>
            <VerticalSpacer y={23} />
            <div className={styles.optionsWrapper}>
                <LinkChooserOption
                    isActive={activeOptionKey === STORED_IN_SITE_SETTINGS}
                    onClick={() => {
                        setActiveOptionKey(STORED_IN_SITE_SETTINGS);
                        dispatch({
                            type: LC_LOCATION_OPTION_CHANGE_ACTION,
                            payload: {
                                selection: `#${GlobalVariableAddressUrlKey}`,
                                name: singleLineAddressFromSiteSettings,
                            }
                        });
                    }}
                    title={<Msg k="lc.location.option.siteSettings">Address stored in website settings</Msg>}
                    contentRenderer={() => {
                        if (isAddressSet) {
                            return <div style={{ paddingLeft: 23, paddingTop: 12 }}>
                                <div style={{ whiteSpace: 'pre' }}>{multiLineAddressFromSiteSettings}</div>
                                <a onClick={focusAddressFieldInSiteSettingsDialogDispatcher}><Msg k="common.edit">Edit</Msg></a>
                            </div>;
                        } else {
                            return <React.Fragment>
                                <Msg k="lc.location.option.noAddressInSiteSettings">No address stored yet.</Msg>
                                <HorizontalSpacer x={5} inline />
                                <a onClick={focusAddressFieldInSiteSettingsDialogDispatcher}>
                                    <Msg k="common.addAddress">Add address</Msg>
                                </a>
                            </React.Fragment>;
                        }
                    }}
                />
                <VerticalSpacer y={15} />
                <LinkChooserOption
                    isActive={activeOptionKey === CUSTOM}
                    onClick={() => {
                        setActiveOptionKey(CUSTOM);
                        dispatch({
                            type: LC_LOCATION_OPTION_CHANGE_ACTION,
                            payload: {
                                selection: addressSearchUrl,
                                name: addressSearchString,
                            }
                        });
                    }}
                    title={<Msg k="lc.location.option.custom">Custom location</Msg>}
                    contentRenderer={() => {
                        return <React.Fragment>
                            <VerticalSpacer y={6} />
                            <GoogleMapsAutoCompleteInput
                                placeholder="msg: common.searchForLocation {Search for location}"
                                appendContent={<div style={{ fill: 'rgba(63,63,63,0.53)' }}><Icons.MAGNIFIER /></div>}
                                value={addressSearchString}
                                intl={props.intl}
                                onChange={payload => {
                                    setAddressSearchString(payload);
                                    setAddressSearchUrl('');
                                    dispatch({
                                        type: LC_LOCATION_OPTION_CHANGE_ACTION,
                                        payload: { selection: '', name: payload }
                                    });
                                }}
                                onPlaceChanged={payload => {
                                    setAddressSearchString(payload.address || addressSearchString);
                                    setAddressSearchUrl(payload.addressUrl);
                                    dispatch({
                                        type: LC_LOCATION_OPTION_CHANGE_ACTION,
                                        payload: { selection: payload.addressUrl, name: payload.address },
                                    });
                                }}
                                refHandler={(input) => {
                                    // @ts-ignore
                                    customAddressRef.current = input;
                                }}
                                invalidInputClass={gmbStyles.validationLabel}
                                validationMsg="test"
                            />
                        </React.Fragment>;
                    }}
                />
            </div>
        </div>
    );
});
