/* eslint-disable max-len */
/* eslint-disable no-multi-assign */

import Section from '../../oneweb/Section/kind';
import { getFirstPageSection } from '../../oneweb/Section/utils';
import Strip from '../../oneweb/Strip/kind';
import Background from '../../oneweb/Background/kind';
import HoverBox from '../../oneweb/HoverBox/kind';
import Text from '../../oneweb/Text/kind';
import Image from '../../oneweb/Image/kind';
import Code from '../../oneweb/Code/kind';
import WebShop from '../../oneweb/WebShop/kind';
import Template from '../../oneweb/Template/kind';
import type { Column, Row } from '../flowTypes';
import { topSorter,
    isFloating,
    overlapsVertically,
    depthSorter, } from './util';
import type { AnyComponent, ComponentsMap, BBox } from '../../../redux/modules/children/workspace/flowTypes';
import type { TemplateComponent, Template as TemplateType } from "../../oneweb/Template/flowTypes";
import Gallery from '../../oneweb/Gallery/kind';
import isStretchComponentKind from '../../oneweb/isStretchComponentKind';
import CodeLocationTypes from '../../oneweb/Code/locationTypes';
import { getWrappedComponentIds } from '../../../utils/htmlWriter/html/render/wrapper/wrapperNodeUtils';
import { memoMaxOne } from '../../../../utils/memo';
import { customSendReport } from '../../../customSendCrashReport';
import getComponentBBox from "../../../utils/componentsMap/getComponentBBox";
import { zeroBBox } from "../../../utils/componentsMap/makeBBoxMemoized";
import { area } from "../../PagesTree/pagesLogic/EmptyRects";

import { getCmpsMap, simpleTopSorter } from "../../Workspace/epics/componentAttachements/util";
import menuKind from "../../oneweb/Menu/kind";
import { isVerticalMenu } from "../../oneweb/Menu/utils";
import { BLANK_SECTION_HEIGHT } from "../../Panel/constants";
import { isSectionKind } from "../../oneweb/componentKinds";
import {
    convertLayoutStyleToStyleObject,
    isModernLayoutSection,
    getAllCmpIdsInModernHeaderFooter
} from "../../ModernLayouts/preview_utils";
import { addEdgeGapsToWidth } from "../../ModernLayouts/maxWidthUtils";
import { RowEdgeGapInPercent } from "../../ModernLayouts/constants";
import getUpdatedLayoutBasedOnOptions from "../../ModernLayouts/getUpdatedLayoutBasedOnOptions";
import { getWebShopLayoutWithIds, getWebShopStripCmpIdsTrueMap,
    getWebShopStripId } from '../../ModernLayouts/layoutsData/webshopMHFDataUtils';

type FlattenInput = {
    componentsMap: ComponentsMap,
    template: TemplateComponent,
    fixGalleryBBox ?: boolean,
    isForWorkspace?: boolean,
    addExtraPaddingOnSides ?: boolean
};

export const TEXT_INDENT_BLOCK = 'TEXT_INDENT_BLOCK';

export const ContainerComponentKinds = {
        [Template]: true,
        [Strip]: true,
        [Section]: true,
        [Background]: true,
        [HoverBox]: true,
        [Image]: true,
        [TEXT_INDENT_BLOCK]: true
    },
    isRelPageExists = (relPage: Record<string, any>) => relPage && Object.keys(relPage).length,
    relPageDistanceLimit = 200,
    getRelPageDistanceForNewPage = (templateComponent: AnyComponent, templateWidth: number) => {
        let minRelPageDistance = 1000;
        Object.values(templateComponent.relPage).forEach((distance: any) => {
            minRelPageDistance = Math.min(distance, minRelPageDistance);
        });
        if (minRelPageDistance > relPageDistanceLimit) {
            return (templateComponent.width >= (templateWidth * 0.8)) ? 0 : 20;
        }
        return minRelPageDistance;
    },
    HeaderTemplateCmpMaxDistance = 180,
    TotalHeaderRange = 300,
    FooterTemplateCmpMaxDistance = 150;

const
    _getRelInParent = (relIn, componentsMap, template) => {
        return (relIn && (componentsMap[relIn.id] ? relIn.id : template.id)) || template.id;
    },
    _getComponent = (id, componentsMap, template) => (template.id === id ? template : componentsMap[id]),
    _getNewColumnObject = (component: AnyComponent) => {
        const { left, width, top, height } = component;
        return {
            components: [component],
            left,
            right: left + width,
            top,
            bottom: top + height
        };
    },
    isFloated = (block, component, templateWidth) => {
        if (!block) {
            return false;
        }

        if (block.kind === Template) {
            const { top, left, width, height, kind, stretch = false } = component;
            // strip does not float over template, ignore its left & width
            return (
                ((top + height) < 0) ||
                (
                    !isStretchComponentKind(kind, stretch) &&
                    (left < 0 || Math.round(left + width) > Math.round(block.width))
                )
            );
        }

        if (block.kind === Text) {
            return !component.wrap;
        }

        if (!ContainerComponentKinds.hasOwnProperty(block.kind)) {
            return true;
        }

        // block is a container component
        return block && isFloating(block, component, templateWidth);
    },
    _fixColumnBBoxes = (columns, parentTop, parentLeft, parentBottom) => {
        let
            newColumns: Column[] = [],
            col = { ...columns[0] };

        col.left = parentLeft;
        col.top = parentTop;
        col.bottom = parentBottom;
        newColumns.push(col);

        for (let i = 1; i < columns.length; i++) { // when ONLY 1 loop will not be entered
            col = { ...columns[i] };
            col.left = columns[i - 1].right;
            col.top = parentTop;
            col.bottom = parentBottom;
            newColumns.push(col);
        }
        return newColumns;
    },
    columnToBBox = ({ top, left, bottom, right }: Column): BBox => ({ top, left, bottom, right }),
    /*
     * Attempts merge of nth and n-1th row
     * Please note WE NEED to consolidate only second last row. really???
     */
    mergeRows = (rows, parentLeft) => {
        let
            prevRowIndex: number,
            prevRow = {} as Row,
            prevRowColumns: Column[] = [],
            currRowIndex: number,
            currRow = {} as Row,
            currRowColumns: Column[] = [];
        if (rows.length > 1) {
            prevRowIndex = rows.length - 2;
            prevRow = rows[prevRowIndex];
            prevRowColumns = prevRow.columns; // no clone needed as we clone this into newColumns below
            currRowIndex = rows.length - 1;
            currRow = rows[currRowIndex];
            currRowColumns = currRow.columns; // no clone needed as this is only read

            if (prevRowColumns.length === 1 || !currRow.isRowMergeable) {
                prevRow.columns = _fixColumnBBoxes(prevRow.columns, prevRow.top, parentLeft, prevRow.bottom); // we have to fix columns as this row has ended
                prevRow.bottom = Math.round(prevRow.bottom); // eslint-disable-line
                return rows; // no merge required as there is only 1 column in second last row
            }
        }
        if (!(rows.length > 1 && rows[rows.length - 2].columns.length > 1)) {
            return rows;
        }

        let
            i,
            j,
            newColumns: Array<Column> = prevRowColumns.map(c => ({ ...c })); // this holds merged columns for success case and its always being updated

        let newColumnsStartIdx = 0; // optimize compare index in newColumns to merge
        for (i = 0; i < currRowColumns.length; i++) {
            const currRowColumn = currRowColumns[i];

            for (j = newColumnsStartIdx; j < newColumns.length; j++) {
                const
                    prevRowColumn = newColumns[j];

                // if vertically overlaps more than 1 column(prev and current) merge fails
                // if vertically overlaps 1 we combine columns
                // if no overlaps need to insert
                if (currRowColumn.right <= prevRowColumn.left) { // currRowColumn appears before prevRowColumn
                    newColumns.splice(j, 0, currRowColumn);
                    newColumnsStartIdx = j + 1; // array size increased by 1, so we need to point to new index of prevRowColumn
                    break;
                } else if (overlapsVertically(columnToBBox(prevRowColumn), columnToBBox(currRowColumn))) {
                    if (newColumns[j + 1] && overlapsVertically(columnToBBox(newColumns[j + 1]), columnToBBox(currRowColumn))) {
                        // merge fails. We need to form a new row

                        // fix column bboxes
                        prevRow.columns = _fixColumnBBoxes(prevRowColumns, prevRow.top, parentLeft, prevRow.bottom);
                        // its already currRowColumns which has been combined
                        // if (rows.length > 1) {
                        //     // fix rounding of row that cannot be merged
                        //     const lastButOneRow = rows[rows.length - 2];
                        //     lastButOneRow.top = Math.ceil(lastButOneRow.top)
                        //     lastButOneRow.bottom = Math.ceil(lastButOneRow.bottom);
                        // }
                        return rows;
                    } else {
                        newColumnsStartIdx = j; // next currRowColumn could merge into jth index of newColumns

                        // put into same column of prevColumn
                        const col = newColumns[j];
                        col.left = Math.min(col.left, currRowColumn.left);
                        col.right = Math.max(col.right, currRowColumn.right);
                        if (col.components) {
                            col.components = col.components.concat(currRowColumn.components);
                        }
                        break;
                    }
                }
            }
            if (j === newColumns.length) {
                newColumns.push(...currRowColumns.slice(i));
                break;
            }
        }

        // Merge success between previous and current row. We can combine columns of previous and current into 1 row
        rows.pop(); // we need this as we need new rowBottom
        rows.pop(); // pop also the second last row as we are combining 2 rows

        rows.push({
            top: Math.floor(prevRow.top),
            bottom: currRow.bottom,
            columns: newColumns,
            isRowMergeable: true
        });

        return rows;
    },
    /**
     * The function does 2 things
     * 1) put the component(refCol) into existing 'columns' by either inserting new or merging with existing an column
     * 2) check if refCol spans across across more than 1 column in columns. If it does the row should not be merged
     *    with the next row
     *
     * @param columns
     * @param refCol
     * @returns {{columns: *, isMergeable: boolean}}
     */
    spansMultipleColumnsAndUpdateColumns = (columns, refCol) => {
        let
            isRowMergeable = true,
            refColAdded = false;
        for (let i = 0; i < columns.length; i++) {
            let currCol = columns[i];

            if (refCol.right <= currCol.left) {
                columns.splice(i, 0, refCol);
                refColAdded = true;
                break;
            }

            if (refCol.right > currCol.left && refCol.left < currCol.right) {
                // refCol overlaps this currCol, so combine
                currCol.left = Math.min(currCol.left, refCol.left);
                currCol.right = Math.max(currCol.right, refCol.right);
                currCol.components.push(refCol.components[0]);

                const nextCol = (i + 1) < columns.length && columns[i + 1];
                if (nextCol && refCol.right > nextCol.left) {
                    // need to merge the columns being spanned
                    const newColumns: Array<Column> = columns.slice(0, i + 1);

                    let
                        groupingCol = newColumns[newColumns.length - 1],
                        j = i + 1;

                    for (; j < columns.length; j++) {
                        const col = columns[j];
                        if (refCol.right > col.left) {
                            groupingCol.left = Math.min(groupingCol.left, col.left);
                            groupingCol.right = Math.max(groupingCol.right, col.right);
                            groupingCol.components.push(...col.components);
                        } else {
                            break;
                        }
                    }

                    if (j < columns.length) {
                        newColumns.push(...columns.slice(j));
                    }

                    return {
                        columns: newColumns,
                        isRowMergeable: false
                    };
                }
                refColAdded = true;
                break;
            }
        }

        if (!refColAdded) {
            columns.push(refCol); // add refCol after all columns this is on the right of the rightmost column
        }

        return {
            columns,
            isRowMergeable
        };
    },
    getPriorityComponent = (components) => {
        // give priority to strip component. pick first
        const foundStripComponents = components.filter(cmp => isStretchComponentKind(cmp.kind, cmp.stretch));
        if (foundStripComponents.length) {
            return foundStripComponents[0];
        }
        // give priority to text component. pick first
        const foundTextComponents = components.filter(cmp => cmp.kind === Text);
        if (foundTextComponents.length) {
            return foundTextComponents[0];
        }
        // sort components by area
        return components.sort((a, b) => {
            return area(getComponentBBox(b, zeroBBox)) - area(getComponentBBox(a, zeroBBox));
        })[0];
    },
    getWrappedComponentsByWrapperComponentIdMap = memoMaxOne(
        (componentsMap) => Object.keys(componentsMap).reduce((acc, cId) => {
            const component = componentsMap[cId];
            if (component.kind === Text) {
                const wrappedComponentsIds = getWrappedComponentIds(component.content);
                if (wrappedComponentsIds.length > 0) {
                    acc[cId] = [];
                    wrappedComponentsIds.forEach(id => {
                        const wrappedComponent = componentsMap[id];
                        if (!wrappedComponent) {
                            customSendReport({ message: `Text component with id ${component.id} content contains wrap reference to component ${id} that is missing.` }); // eslint-disable-line max-len
                        } else {
                            acc[cId].push(wrappedComponent);
                        }
                    });
                }
            }

            return acc;
        }, {})
    ),
    /**
     *
     * @param components Array of components to be flattened
     * @param block Container component which contains <code>components<code>
     * @param columnTop If container is a column, this should be its top
     * @param columnLeft If container is a column, this should be its left
     * @returns {*}
     */
    getRowsAndColumns = (inpComponents: Array<AnyComponent>,
        block: AnyComponent | null,
        columnTop: number = 0,
        columnLeft: number = 0,
        templateWidth: number,
        componentsMap: ComponentsMap,
        wrappedComponentsByWrapperComponentIdMap: ComponentsMap = getWrappedComponentsByWrapperComponentIdMap(componentsMap),
        floatingComponents: Array<AnyComponent> = []): null | undefined | Record<string, any> => {
        const flattenItemsAndGetColumn = (components): null | undefined | Record<string, any> => {
            if (!components || !components.length) {
                return null;
            }
            components.sort(topSorter); // TODO Try to avoid this sort
            if (
                components.length > 1 &&
                !components[0].inTemplate &&
                components.every(cmp => isSectionKind(cmp.kind))
            ) {
                //Sort goes wrong when there is no header(headercmp 0 height),
                // move first template section(header) to top
                components.unshift(
                    components.splice(components.findIndex(cmp => cmp.inTemplate), 1)[0]
                );
            }

            let
                rows: Record<string, any>[] = [],
                floating: Record<string, any>[] = [],
                parentLeft = block ? 0 : columnLeft,
                rowTop = 0,
                rowBottom = 0, // TODO need to see if this needs to be changed
                columns: Array<Column> = [],
                wrappedComponents = [];

            if (block && block.kind === Text && wrappedComponentsByWrapperComponentIdMap[block.id]) {
                wrappedComponents = wrappedComponentsByWrapperComponentIdMap[block.id];
            }

            components.forEach(component => {
                let { top, height } = component;

                if (component.isStickyToHeader || isFloated(block, component, templateWidth)) {
                    floating.push(component);
                    floatingComponents.push(component);
                    return;
                }

                if (block && block.kind === Text) {
                    return;
                }

                let bottom = top + height;

                if (rowBottom === 0) {
                    rowTop = block ? 0 : columnTop;
                    rowBottom = bottom;
                    columns = [_getNewColumnObject(component)];
                    rows.push({
                        top: rowTop,
                        bottom: rowBottom,
                        columns,
                        isRowMergeable: true
                    });
                    return;
                }

                const col: Column = _getNewColumnObject(component);
                if (top < rowBottom) {
                    const lastRow = rows[rows.length - 1];
                    const { columns: newColumns, isRowMergeable } = spansMultipleColumnsAndUpdateColumns(columns, col);
                    if (lastRow.isRowMergeable && !isRowMergeable) {
                        lastRow.isRowMergeable = false;
                    }
                    lastRow.columns = columns = newColumns;
                    lastRow.bottom = rowBottom = Math.max(rowBottom, bottom);
                } else {
                    rows = mergeRows(rows, parentLeft);

                    rowTop = rowBottom;
                    rowBottom = bottom;
                    // break row with current component
                    columns = [col];
                    rows.push({
                        top: rowTop,
                        bottom: rowBottom,
                        columns,
                        isRowMergeable: true
                    });
                }
            });

            if (block && block.kind === Text) {
                floatingComponents.push(...floating);
                return {
                    floating,
                    wrappedComponents,
                    block,
                };
            }

            if (!rows.length) {
                floatingComponents.push(...floating);
                // here implies all components in this layer are floating
                return {
                    floating,
                    block,
                };
            }

            let lastRow: Record<string, any> | null = null;
            if (rows.length > 1) {
                rows = mergeRows(rows, parentLeft); // merge unconsolidated rows
                lastRow = rows[rows.length - 1];
                lastRow.columns = _fixColumnBBoxes(lastRow.columns, lastRow.top, parentLeft, lastRow.bottom); // last row bboxes have to be consolidated
            } else { // only 1 row
                lastRow = rows[0];
                lastRow.columns = _fixColumnBBoxes(lastRow.columns, lastRow.top, parentLeft, lastRow.bottom); // last row bboxes have to be consolidated
            }

            if (lastRow) {
                lastRow.bottom = Math.round(lastRow.bottom);
            }

            if (components.length > 1 && rows.length === 1 && rows[0].columns.length === 1) {
                // flattening was not possible

                const
                    col: Record<string, any> = rows[0].columns[0],
                    colComponents = col.components;
                colComponents.sort(depthSorter);
                col.component = getPriorityComponent(col.components);
                col.floating = colComponents.filter(component => component !== col.component);
                col.components.length = 0;
                floatingComponents.push(...(col.floating || []));
                return {
                    rows: rows && rows.length ? rows : undefined,
                    floating: floating && floating.length ? floating : undefined,
                    block,
                    allFloated: true,
                };
            }
            for (let i = 0; i < rows.length; i++) {
                const
                    row = rows[i],
                    columns = row.columns;
                for (let j = 0; j < columns.length; j++) {
                    const col: Record<string, any> = columns[j];

                    if (col.components.length === 1) {
                        col.component = col.components[0];
                        col.components.length = 0;
                    } else {
                        // is more than 1
                        const subTree = getRowsAndColumns(
                            col.components,
                            null,
                            col.top,
                            col.left,
                            templateWidth,
                            componentsMap,
                            wrappedComponentsByWrapperComponentIdMap,
                            floatingComponents
                        );

                        if (subTree) {
                            if (subTree.allFloated) {
                            // this is the case where all items are floated and hence we CAN and SHOULD avoid having
                            // an extra nested row/column inside which components are put in
                                col.component = subTree.rows[0].columns[0].component;
                                col.floating = subTree.rows[0].columns[0].floating;
                                col.components.length = 0;
                            } else {
                            // this is the case where a node has both floated and nested items
                                col.rows = subTree.rows;
                                col.floating = subTree.floating;
                            }
                            floatingComponents.push(...(subTree.floating || []));
                        }
                    }
                }
            }

            return {
                rows: rows && rows.length ? rows : undefined,
                floating: floating && floating.length ? floating : undefined,
                block
            };
        };
        let cmps = inpComponents;
        if (block && block.kind === HoverBox) {
            let defaultCmps = cmps.filter(c => (c.onHover ? !c.onHover.show : true)),
                defaultCol = flattenItemsAndGetColumn(defaultCmps),
                hoverCmps = inpComponents.filter(c => c.onHover && c.onHover.show),
                hoverCol = flattenItemsAndGetColumn(hoverCmps),
                bottom = Math.max(
                    (defaultCol && defaultCol.rows && defaultCol.rows.length) ?
                        defaultCol.rows[defaultCol.rows.length - 1].bottom : 0,
                    (hoverCol && hoverCol.rows && hoverCol.rows.length) ?
                        hoverCol.rows[hoverCol.rows.length - 1].bottom : 0
                ),
                colProps = {
                    top: 0,
                    bottom,
                    left: 0,
                    right: block.width,
                    components: [],
                    allFloated: false
                },
                colsFinal: Record<string, any>[] = [];
            if (defaultCol) {
                colsFinal.push({
                    ...colProps,
                    components: [],
                    floating: undefined,
                    rows: defaultCol.rows,
                });
            }
            if (hoverCol) {
                colsFinal.push({
                    ...colProps,
                    hoverCol: true,
                    floating: undefined,
                    rows: hoverCol.rows
                });
            }
            return {
                block,
                floating: defaultCol ? defaultCol.floating : undefined,
                rows: [{
                    isRowMergeable: false,
                    top: 0,
                    bottom,
                    columns: colsFinal
                }]
            };
        }
        return flattenItemsAndGetColumn(cmps);
    },
    getLayers = (components: Array<AnyComponent>, componentsMap: ComponentsMap, template: TemplateComponent | TemplateType) => {
        //gives back layers with lowest layer(block = Template) has zero index in the array
        const
            groupMap = {},
            groups: Record<string, any>[] = [],
            layers: Record<string, any>[][] = [];
        components.forEach(cmp => {
            let
                id = _getRelInParent(cmp.relIn, componentsMap, template),
                index = groupMap[id];

            if (!groups[index]) {
                index = groupMap[id] = groups.length;
                let group: Record<string, any> = { block: _getComponent(id, componentsMap, template), items: [] };

                groups.push(group);

                // get groups into layers
                let
                    tmp = cmp,
                    level = 0;
                // infinite loop robustness
                for (let i = 0; tmp && tmp.relIn && i < 1000; i += 1) {
                    tmp = _getComponent(tmp.relIn.id, componentsMap, template);
                    level = tmp ? level + 1 : level;
                }
                layers[level] = layers[level] || [];
                layers[level].push(group);

                group.level = level;
            }

            groups[index].items.push(cmp);
        });

        return layers;
    },
    /**
     * @param group
     *
     * This function does the below
     * 1) Updates tops of children of block component based on relIn, relTo
     * 2) Compute height of block component
     *
     */
    createGroupsOfHorizontallyOverlappingCmps = (cmps: Array<AnyComponent>) => {
        // it wont give back all cmps, just the groups(more than 1 cmp in a group)
        let cmpGroups: Array<Array<AnyComponent>> = [], range, group: Array<AnyComponent> = [], cmpGroupPositionMap = {}, lastCmps: any[] = [];

        const updateGroups = () => {
            if (group.length > 1) {
                group.forEach(cmp => {
                    cmpGroupPositionMap[cmp.id] = cmpGroups.length + 1;//+1 just to be able to check if it exists easily
                });
                cmpGroups.push(group.slice());
            }
            lastCmps = [];
            group = [];
        };
        cmps.forEach((cmp, i) => {
            const { top, height, relTo } = cmp,
                bottom = top + height;
            if (i === 0) {
                range = { top, bottom };
                // $FlowFixMe: TODO: fix
                group.push({ ...cmp });
                lastCmps = [cmp];
                return;
            }
            if (
                top < range.bottom &&
                bottom > range.top &&
                (!(relTo && lastCmps.find(c => c.id === relTo.id)))
            ) {
                if (bottom === range.bottom) {
                    lastCmps.push(cmp);
                }
                if (bottom > range.bottom) {
                    lastCmps = [cmp];
                }
                range = { top: Math.min(top, range.top), bottom: Math.max(bottom, range.bottom) };
            } else {
                updateGroups();
                range = { top, bottom };
            }
            // $FlowFixMe: TODO: fix
            group.push({ ...cmp });
            lastCmps.push(cmp);
        });
        updateGroups();
        return {
            cmpGroups,
            cmpGroupPositionMap
        };
    },
    getRelToItem = (componentsMap, cmp) => {
        const { relIn, relTo } = cmp;
        let relToCmp = relTo && relTo.id && componentsMap[relTo.id];
        if (relToCmp && ((relIn && relIn.id) !== (relToCmp.relIn && relToCmp.relIn.id))) {
            relToCmp = null;
        }
        return relToCmp;
    },
    minGapForStickyToHeaderCmps = 100,
    fixPositions = (group: Record<string, any>) => {
        let
            cmps = group.items.slice(),
            pageSections: any[] = [],
            templateSections: any[] = [];
        cmps.forEach(cmp => {
            if (cmp.kind === Section) {
                if (cmp.inTemplate) {
                    templateSections.push(cmp);
                } else {
                    pageSections.push(cmp);
                }
            }
        });

        if (pageSections.length || templateSections.length) {
            templateSections.sort(simpleTopSorter);
            let
                allSections = [
                    templateSections[0],
                    ...(pageSections.sort(topSorter)),
                    templateSections[1]
                ];

            // eslint-disable-next-line
            group.items = cmps
                // add ghost code cmps apart from sections in case ghost code cmps do not have relIn
                .filter(cmp => cmp.kind !== Section)
                .concat(allSections.map((section, i) => {
                    if (i === 0) {
                        // eslint-disable-next-line
                        section.top = 0;
                    } else {
                        const prevSection = allSections[i - 1],
                            prevSectionBottom = prevSection.top + prevSection.height;

                        // eslint-disable-next-line
                        if (allSections.length === 2) {
                            let templateVerticalMenus = cmps.filter(cmp => cmp.isStickyToHeader);
                            if (templateVerticalMenus.length) {
                                templateVerticalMenus.sort(topSorter);
                                const diff = (templateVerticalMenus[0].top > (prevSectionBottom + minGapForStickyToHeaderCmps)) ?
                                    (templateVerticalMenus[0].top - (prevSectionBottom + minGapForStickyToHeaderCmps)) : 0;
                                let lastBottom = 0;
                                templateVerticalMenus.forEach(menu => {
                                    // eslint-disable-next-line
                                    menu.top = menu.top - diff;
                                    lastBottom = Math.max(menu.top + menu.height, lastBottom);
                                });
                                if ((lastBottom + 15 - prevSectionBottom) < BLANK_SECTION_HEIGHT) {
                                    // eslint-disable-next-line
                                    section.top = prevSectionBottom + BLANK_SECTION_HEIGHT;
                                } else {
                                    // eslint-disable-next-line
                                    section.top = lastBottom + 15;//TODO Change to constant
                                }
                            } else {
                                // eslint-disable-next-line
                                section.top = prevSectionBottom + BLANK_SECTION_HEIGHT;
                            }
                        } else {
                            // eslint-disable-next-line
                            section.top = prevSectionBottom;
                        }
                    }
                    return section;
                }));
            return;
        }

        let
            block = group.block,
            maxChildBottom = 0,
            maxChildBottomCmp,
            componentsMapPart = getCmpsMap(cmps),
            relInChildren: any = [];

        for (let i = 0; i < cmps.length; i++) {
            let
                cmp = cmps[i],
                { relIn } = cmp;
            if (relIn && relIn.id === block.id) {
                cmp.top = relIn.top;
                if (!isStretchComponentKind(block.kind, block.stretch)) {
                    cmp.left = relIn.left;
                }
            }
        }
        cmps.sort(topSorter);

        for (let i = 0; i < cmps.length; i++) {
            let
                cmp = cmps[i],
                { relIn, relTo } = cmp,
                newTop = 0,
                relToItem = getRelToItem(componentsMapPart, cmp);

            if (relToItem) {
                let minTop = 0;
                newTop = Math.max((relToItem.top + relToItem.height + relTo.below), minTop);
            }

            if (newTop) {
                cmp.top = newTop;
            }

            if (relIn && relIn.id === block.id) {
                relInChildren.push(cmp);
                const cmpBottom = cmp.top + cmp.height;
                if (
                    !maxChildBottomCmp ||
                    cmpBottom > maxChildBottom ||
                    (cmpBottom === maxChildBottom && cmp.relIn.bottom < maxChildBottomCmp.relIn.bottom)
                ) {
                    maxChildBottomCmp = cmp;
                    maxChildBottom = cmpBottom;
                }
            }
        }

        if (block.kind !== Template && maxChildBottomCmp) {
            const newHeight = maxChildBottom - maxChildBottomCmp.relIn.bottom;
            if (ContainerComponentKinds[block.kind]) {
                if (!block.inTemplate || relInChildren.every(c => c.inTemplate === block.inTemplate)) {
                    block.height = newHeight;
                } else if (block.height < maxChildBottom) {
                    if (maxChildBottomCmp.relIn.bottom > 0) {
                        block.height = newHeight;
                    } else {
                        block.height = maxChildBottom;
                    }
                }
            } else {
                block.height = Math.max(block.height, maxChildBottom - maxChildBottomCmp.relIn.bottom);
            }
        }

        group.items = cmps; // eslint-disable-line
    },
    flattenModernSectionorStrip = (modernGroupsByBlockIds, sectionId, layout) => {
        if (!modernGroupsByBlockIds || !modernGroupsByBlockIds[sectionId] || !layout) return {};
        const result = {};
        const
            processBlock = (id) => {
                if (!layout[id]) return;
                let prevRowBottom = 0;
                result[id] = {
                    block: modernGroupsByBlockIds[id].block,
                    rows: [],
                    vResponsive: layout[id].vResponsive,
                    modernLayoutContainer: true,
                    modernLayoutStyles: convertLayoutStyleToStyleObject(layout[id].style)
                };
                layout[id].rows.forEach(row => {
                    let newRow: Record<string, any> = {
                        top: prevRowBottom,
                        bottom: prevRowBottom,
                        columns: [],
                        modernLayoutRowStyle: row.style,
                        vResponsive: row.vResponsive
                    },
                        colRight = 0;
                    row.cols.forEach(col => {
                        let newCol = { ...col, modernColumn: true, components: [] };
                        newCol.top = newRow.top;
                        newCol.left = colRight;
                        newCol.right = colRight;
                        col.cmps.forEach(cmp => {
                            let component = modernGroupsByBlockIds[id].items.find(c => c.id === cmp.id);
                            if (!component) return;
                            if (col.cmps.length === 1) {
                                //strip in modern section will always be separate row
                                newCol.component = component;
                            } else {
                                newCol.components.push(component);
                            }
                            let cmpRight = component.left + component.width,
                                cmpBottom = component.top + component.height;
                            if (cmpRight > newCol.right) {
                                newCol.right = cmpRight;
                            }
                            if (!newCol.bottom || cmpBottom > newCol.bottom) {
                                newCol.bottom = cmpBottom;
                            }
                            processBlock(component.id);
                        });
                        if (newCol.bottom > newRow.bottom) {
                            newRow.bottom = newCol.bottom;
                        }
                        newRow.columns.push(newCol);
                    });
                    prevRowBottom = newRow.bottom;
                    result[id].rows.push(newRow);
                });
            };
        processBlock(sectionId);
        return result;
    },
    flattenModernSections = (modernGroupsByBlockIds, modernSectionIds, componentsMap) => {
        let result = {};
        modernSectionIds.forEach(sectionId => {
            const section = modernGroupsByBlockIds[sectionId].block,
                { newLayout: layout } = getUpdatedLayoutBasedOnOptions(section, componentsMap);
            result = {
                ...result,
                ...flattenModernSectionorStrip(modernGroupsByBlockIds, sectionId, layout)
            };
        });
        return result;
    },
    flatten = ({
        componentsMap: oldComponentsMap,
        template,
        fixGalleryBBox = false,
        isForWorkspace = false,
        addExtraPaddingOnSides = false
    }: FlattenInput) => {
        const componentKeys = Object.keys(oldComponentsMap);
        if (!componentKeys.length) {
            return null;
        }
        const floatingComponents = [];
        const
            table: Record<string, any> = {
                hasWebShop: false,
                root: template && template.id, // TODO will not be needed when make all into pure functions
                structure: {},
                componentKindsMap: {},
                minPageWidth: 0,
                parentToChildrenMap: {},
                templateCodeInHead: [],
                pageCodeInHead: [],
                templateCodeInBodyEnd: [],
                pageCodeInBodyEnd: []
            },
            componentsMap = {},
            stickyMenus: any = [],
            templateWidth = template.width,
            firstPageSection = getFirstPageSection(oldComponentsMap),
            modernSectionIds: string[] = [],
            extraGapOnSides = 100;

        let
            minLeft = Number.MAX_SAFE_INTEGER,
            maxRight = Number.MIN_SAFE_INTEGER;
        const mhfCmpIdsTrueMap = {
            ...getAllCmpIdsInModernHeaderFooter(oldComponentsMap),
            ...getWebShopStripCmpIdsTrueMap(oldComponentsMap)
        };

        let cmps = componentKeys.reduce((prev, cmpId) => {
            // $FlowFixMe: TODO: fix
            const component: AnyComponent = { ...oldComponentsMap[cmpId] },
                { id, left, width, kind, height, inTemplate, relIn, top, stretch = false } = component,
                center = top + (height / 2),
                right = left + width,
                isNotStretch = !isStretchComponentKind(kind, stretch);
            if (isSectionKind(kind) && isModernLayoutSection(component)) {
                modernSectionIds.push(id);
            }
            if (firstPageSection &&
                inTemplate &&
                kind === menuKind &&
                center >= firstPageSection.top &&
                isVerticalMenu((component as any)) &&
                component.isStickyToHeader
            ) {
                stickyMenus.push(component);
                table.componentKindsMap[kind] = true;
                componentsMap[id] = component;
                return prev;
            }

            if (relIn && oldComponentsMap[relIn.id]) {
                const parentId = relIn.id;
                let children = table.parentToChildrenMap[parentId];
                if (!children) {
                    children = table.parentToChildrenMap[parentId] = [];
                }
                children.push(component);
            }

            component.height = Math.round(height);

            if (
                isNotStretch &&
                !mhfCmpIdsTrueMap[component.id] &&
                CodeLocationTypes.BeforeClosingHead !== component.location &&
                CodeLocationTypes.BeforeClosingBody !== component.location
            ) {
                minLeft = Math.min(minLeft, left);
                maxRight = Math.max(maxRight, right);
            }

            table.componentKindsMap[kind] = true;

            if (kind === Code) {
                if (CodeLocationTypes.BeforeClosingHead === component.location) {
                    (inTemplate ? table.templateCodeInHead : table.pageCodeInHead).push(component);
                    return prev;
                } else if (CodeLocationTypes.BeforeClosingBody === component.location) {
                    (inTemplate ? table.templateCodeInBodyEnd : table.pageCodeInBodyEnd).push(component);
                    return prev;
                } // else it participates in row/column calculation
            }

            // TODO make Webshop height to 450 later. Old WE logic is not as per expectations
            // TODO: This issue is now addressed in WBTGEN-10756
            if (kind === WebShop) {
                // if (component.height !== WebShopMaxHeight) {
                //     component.height = WebShopMaxHeight;
                // }
                table.hasWebShop = true;
            }

            if (!isForWorkspace && fixGalleryBBox && kind === Gallery && component.spacingPx) {
                const { spacingPx } = component;
                component.top -= spacingPx / 2;
                component.height += spacingPx;
                component.left -= spacingPx / 2;
                component.width += spacingPx;
            }

            prev.push(component);
            componentsMap[id] = component;
            return prev;
        }, [] as any[]);

        // update width of page
        minLeft = minLeft < 0 ? Math.abs(minLeft) : 0;
        maxRight = maxRight > templateWidth ? (maxRight - templateWidth) : 0;

        const templateWidthWithOffSet = templateWidth + (2 * Math.max(minLeft, maxRight)) + (addExtraPaddingOnSides ? extraGapOnSides : 0),
            maxModernSectionWidth = addEdgeGapsToWidth(
                modernSectionIds.reduce((acc, id) => Math.max((componentsMap[id].modernLayout.minDimensions || {}).width, acc), 0),
                RowEdgeGapInPercent
            ) || 0;

        table.minPageWidth = Math.max(maxModernSectionWidth, templateWidthWithOffSet);

        let modernGroupsByBlockIds = {}, webshopFooterGroupsByBlockIds = {};
        const layers = getLayers(cmps, componentsMap, template);
        const webshopFooterStripId = getWebShopStripId(componentsMap);
        layers.reverse().forEach(groups => {
            groups.forEach(group => {
                if (isForWorkspace) {
                    // adjust top and left since Row/Col formation is based on Block's bbox as 0 reference, i.e relIn
                    const
                        { block: { kind: blockKind, top: blockTop, left: blockLeft, stretch = false } } = group,
                        isNonStripBlock = !isStretchComponentKind(blockKind, stretch),
                        isNonTemplateBlock = blockKind !== Template;
                    if (isNonTemplateBlock) {
                        group.items.forEach(c => {
                            c.top = c.top - blockTop; // eslint-disable-line
                            if (isNonStripBlock) {
                                c.left = c.left - blockLeft; // eslint-disable-line
                            }
                        });
                    }
                } else if (group.block && group.block.kind !== Template) {
                    fixPositions(group);
                }
                const { block, items } = group;
                if (modernSectionIds.includes(block.id) || (block.relIn && modernSectionIds.includes(block.relIn.id))) {
                    //This check should be extended in case we have multilevels in modern sections
                    modernGroupsByBlockIds[block.id] = group;
                    return;
                }
                if (!modernSectionIds.length && block.id === webshopFooterStripId) {
                    webshopFooterGroupsByBlockIds[block.id] = group;
                    return;
                }
                table.structure[block.id] = getRowsAndColumns(
                    items,
                    block,
                    0,
                    0,
                    templateWidth,
                    componentsMap,
                    undefined,
                    floatingComponents
                );
            });
        });
        table.structure = {
            ...table.structure,
            ...flattenModernSectionorStrip(webshopFooterGroupsByBlockIds, webshopFooterStripId,
                getWebShopLayoutWithIds(componentsMap)),
            ...flattenModernSections(modernGroupsByBlockIds, modernSectionIds, oldComponentsMap)
        };
        if (stickyMenus.length) {
            let firstPageRowComponent = table.structure[table.root].rows[1].columns[0].component,
                firstPageRowStructure = table.structure[firstPageRowComponent.id],
                stickyMenusAdjustedTops = stickyMenus.map(cmp => ({ ...cmp, top: cmp.top - firstPageRowComponent.top }));
            if (!firstPageRowStructure) {
                table.structure = {
                    ...table.structure,
                    [firstPageRowComponent.id]: {
                        block: firstPageRowComponent,
                        floating: [...stickyMenusAdjustedTops]
                    }
                };
            } else {
                table.structure[firstPageRowComponent.id] = {
                    ...firstPageRowStructure,
                    floating: [...(firstPageRowStructure.floating || []), ...stickyMenusAdjustedTops]
                };
            }
            stickyMenusAdjustedTops.forEach(cmp => { componentsMap[cmp.id] = cmp; });
        }

        table.componentsMap = componentsMap;
        table.floatingComponents = floatingComponents;
        return table;
    };

export { getRowsAndColumns, getLayers, createGroupsOfHorizontallyOverlappingCmps, fixPositions };
export default flatten;
