import { applyFormatValue } from "./editorUtils/methods/helpers/applyFormatValue";

import type { TinyMceEditor } from "./flowTypes";

type Props = {
    editor: TinyMceEditor;
    shadowColor: string;
    shadowOffsetX?: number;
    shadowOffsetY?: number;
    blurRadius?: number;
};

export const updateTextShadow = ({
    editor,
    shadowColor,
    shadowOffsetX = 1,
    shadowOffsetY = 1,
    blurRadius = 1
}: Props) => {
    const
        textShadowCssValue = `${shadowOffsetX}px ${shadowOffsetY}px ${blurRadius}px ${shadowColor}`;

    applyFormatValue(editor, 'textshadow', textShadowCssValue);
};
