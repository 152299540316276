import * as R from 'ramda';
import * as onecolor from "onecolor";
import makeEpic from '../../../epics/makeEpic';
import { receiveOnly, withSelector } from '../../../epics/makeCondition';
import valueActionType from './valueActionType';
import StylesheetsValueActionType from '../../Workspace/epics/stylesheets/valueActionType';
import {
    ComponentsMapSelector,
    ReceiveOnlyUserInteractionModeSelector
} from '../../Workspace/epics/componentsEval/selectorActionTypes';
import mapper from '../../oneweb/Button/globalStyle/mapper';
import {
    stylesheetByIdSelector,
    getThemeColorsFromStylesheet,
    getFirstButtonStyle
} from '../../Workspace/epics/stylesheets/selectors';
import { WORKSPACE_NEW_COMPONENT_ADDED } from "../../Workspace/actionTypes";
import WEBSHOP_KIND from '../../oneweb/WebShop/kind';
import { IDLE } from "../../Workspace/epics/componentsEval/userInteractionMutations/interactionModes";
import { COLOR_THEME_SITE_SETTINGS_EPIC_VALUE } from "../../SiteSettings/ColorThemeData/colorThemeSiteSettingsVAT";
import { findWebshopComponent } from "../../oneweb/WebShop/utils";
import { BACKGROUND_THEME_WHITE, BUTTON_THEME_PRIMARY, BUTTON_THEME_SECONDARY } from "../../ThemeGlobalData/constants";
import type { ThemeBackgroundType, ThemeButtonTypes } from "../../ThemeGlobalData/flowTypes";
import { getThemeRulesForBackground } from '../../ThemeGlobalData/themeRules';
import { getThemeStyleForButtonWithKnownTheme } from "../../oneweb/Button/utils";
import { GET_PARENT_THEME_MAP_VAT } from "../../ThemeGlobalData/epics/getParentThemeMapVAT";
import { Stylesheets } from '../../Workspace/epics/stylesheets/flowTypes';

export type StylesShadowRendererState = {
    styleProp: Record<string, any>,
    styleId: string
}
const defaultState: StylesShadowRendererState = { styleProp: {}, styleId: '' };

type ReducerInput = {
    values: [Stylesheets, string | undefined];
    state: StylesShadowRendererState;
    autoColorMode?: boolean,
    selectedParentTheme?: ThemeBackgroundType,
    buttonThemeSelected?: ThemeButtonTypes
};

const
    stylesShadowRendererReducer = ({
        values: [globalStyles, buttonStyleId],
        autoColorMode = false,
        selectedParentTheme = BACKGROUND_THEME_WHITE,
        buttonThemeSelected = BUTTON_THEME_PRIMARY
    }: ReducerInput) => {
        if (R.isNil(buttonStyleId) || R.isEmpty(buttonStyleId)) {
            return { state: defaultState };
        }

        const styleId = buttonStyleId;

        const stylesheet = stylesheetByIdSelector(styleId)(globalStyles);

        if (!stylesheet) {
            return { state: defaultState };
        }

        if (autoColorMode) {
            const
                firstButtonStylesheet = getFirstButtonStyle(globalStyles),
                firstButtonStylesheetId = firstButtonStylesheet.id,
                firstButtonStyle = R.mapObjIndexed(R.filter(v => !!v))(mapper(firstButtonStylesheet)),
                themeColorsData = getThemeColorsFromStylesheet(globalStyles);
            const themeStyle = getThemeStyleForButtonWithKnownTheme({ selectedParentTheme, buttonThemeSelected, themeColorsData });
            const hoverBackgroundColor = (
                buttonThemeSelected === BUTTON_THEME_SECONDARY
                    ? onecolor(themeStyle.color).alpha(0.1).cssa()
                    : onecolor(themeStyle.backgroundColor)
                        .mix(themeColorsData[getThemeRulesForBackground(selectedParentTheme, themeColorsData).background])
                        .cssa()
            );
            const styleProp = R.mapObjIndexed((classStyle, selector) => ({
                ...classStyle,
                ...(R.pick(['color', 'border', 'backgroundImage'], themeStyle)),
                backgroundColor: (['hover', 'active'].some(s => selector.includes(s)) ? hoverBackgroundColor : themeStyle.backgroundColor),
            }), firstButtonStyle);
            return { state: { styleProp, styleId: firstButtonStylesheetId } };
        }
        const style = R.mapObjIndexed(R.filter(v => !!v))(mapper(stylesheet));
        return { state: { styleProp: style, styleId } };
    },
    webshopButtonStyleIdSelector: (ComponentsMap: any) => string | undefined = R.pipe(
        R.filter(R.propEq('kind', 'WEBSHOP')),
        R.values,
        R.head,
        R.path(['buttonId'])
    );

const epic = makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                ReceiveOnlyUserInteractionModeSelector,
                ComponentsMapSelector,
                StylesheetsValueActionType,
                receiveOnly(COLOR_THEME_SITE_SETTINGS_EPIC_VALUE),
                receiveOnly(GET_PARENT_THEME_MAP_VAT),
            ],
            reducer: ({ values: [
                userInteractionMode,
                componentsMap,
                globalStyles,
                { autoColorMode },
                { parentThemeMap }
            ], state }) => {
                if (userInteractionMode !== IDLE) {
                    return { state };
                }
                const webshopButtonStyleId = webshopButtonStyleIdSelector(componentsMap);
                let selectedParentTheme = BACKGROUND_THEME_WHITE;
                let buttonThemeSelected = BUTTON_THEME_PRIMARY;
                if (autoColorMode) {
                    const
                        webshopComponentMap = findWebshopComponent(componentsMap),
                        component = webshopComponentMap[Object.keys(webshopComponentMap)[0]];
                    if (component) {
                        selectedParentTheme = parentThemeMap[component.id];
                        buttonThemeSelected = component.buttonThemeSelected;
                    }
                }
                return stylesShadowRendererReducer({
                    values:
                        [globalStyles,
                            webshopButtonStyleId
                        ],
                    state,
                    autoColorMode,
                    selectedParentTheme,
                    buttonThemeSelected
                });
            }
        },
        {
            conditions: [
                receiveOnly(StylesheetsValueActionType),
                receiveOnly(COLOR_THEME_SITE_SETTINGS_EPIC_VALUE),
                receiveOnly(GET_PARENT_THEME_MAP_VAT),
                withSelector(WORKSPACE_NEW_COMPONENT_ADDED, (
                    { component: { kind, buttonId, id, buttonThemeSelected } }
                ) => ({ isWebshop: kind === WEBSHOP_KIND, buttonId, id, buttonThemeSelected }))
            ],
            reducer: ({ values: [
                globalStyles,
                { autoColorMode },
                { parentThemeMap },
                { isWebshop, buttonId, id, buttonThemeSelected },
            ], state }) => {
                return isWebshop ? stylesShadowRendererReducer({
                    values: [globalStyles, buttonId],
                    state,
                    autoColorMode,
                    selectedParentTheme: parentThemeMap[id],
                    buttonThemeSelected
                }) : { state };
            }
        }
    ]
});

export {
    epic as default,
    stylesShadowRendererReducer,
    webshopButtonStyleIdSelector
};
