// @ts-nocheck
export default function (total, increment, job) {
    let
        timer,
        progress = 0,
        working = false;

    this.go = function () {
        if (working) throw new Error('Timer already in progress');
        working = true;

        timer = setInterval(() => {
            progress += increment;
            if (progress >= total) {
                this.finish();
            } else {
                job(percentage(progress));
            }
        }, increment);
    };

    this.finish = function () {
        if (!working) return;

        // reset
        clearInterval(timer);
        timer = null;
        progress = 0;
        working = false;

        job(percentage(total));
    };

    function percentage(progress) {
        return Math.round((progress / total) * 100);
    }
}
