import React from 'react';
import styles from './UpgradeFailedPopup.css';
import WarningDialog from '../../../../view/common/dialogs/WarningDialog/index';
import injectIntl from "../../../../view/intl/injectIntl";
import getCenteredDialogConfig from "../../../DialogManager/getCenteredDialogConfig";
import { errorDialogStandardHeight, errorDialogStandardWidth } from '../../../../view/common/dialogs/constants';

const
    width = errorDialogStandardWidth,
    height = errorDialogStandardHeight,
    title = 'msg: upgradeFailed.title {Upgrading failed}',
    error = 'msg: upgradeFailed.error {Upgrade failed due to a technical error. Please wait a few minutes and try again.}',
    mctaText = 'msg: common.ok {OK}';

const InvalidErrorDialog = injectIntl(({ intl }) => {
    return (
        <WarningDialog title={title} mctaText={mctaText}>
            <div>
                <span className={styles.bold}>{intl.msgJoint(error)}</span>
            </div>
        </WarningDialog>
    );
});

export default getCenteredDialogConfig(width, height, InvalidErrorDialog);
