import * as R from 'ramda';
import * as kinds from "./kinds";
import abstractFactory, { keepMinBBox, minWidth } from "./factory";
import positionsFactory from './positionsFactory';
import type { HandleCreator } from "./flowTypes";
import * as bBoxUtils from "../bBox";
import type { BBox } from "../../redux/modules/children/workspace/flowTypes";
import { HandleHeightPx, HandleWidthPx } from './resize';

export const
    // Impossible to do because integration tests fail
    SelectionShiftBarHandleWidthPx = 14,
    SelectionShiftBarHandleHeightPx = 14,
    HoverShiftBarHandleHeightPx = SelectionShiftBarHandleHeightPx,
    ShiftBarMargin = 30,
    ShiftBarMarginLow = 15,
    ShiftBarMarginWithWidth = ShiftBarMargin + SelectionShiftBarHandleWidthPx;

const shiftBarTopSelectionLeftHandlerMap = {
    // Flows requires to be strings
    '1200': (bBox) => bBox.right - (bBox.right - bBox.left) / 3.3 - SelectionShiftBarHandleWidthPx / 2, // eslint-disable-line
    '148': (bBox) => bBox.right - (bBox.right - bBox.left) / 10 - SelectionShiftBarHandleWidthPx / 2, // eslint-disable-line
    [minWidth]: (bBox) => bBox.right - (bBox.right - bBox.left) / 4 - SelectionShiftBarHandleWidthPx / 2, // eslint-disable-line
    '18': (bBox) => bBox.right + ShiftBarMarginLow, // eslint-disable-line
    '0': (bBox) => bBox.right + ShiftBarMarginLow + (minWidth - (bBox.right - bBox.left)) / 2 // eslint-disable-line
};
const
    { horizontalCenter, horizontalCenterAfter, right, leftAfter } = positionsFactory(HandleWidthPx, HandleHeightPx);
export function getShiftBarTopSelectionTop(bBox: BBox): number {
    return bBox.top - (SelectionShiftBarHandleHeightPx / 2);
}

export function getShiftBarBottomSelectionTop(bBox: BBox): number {
    return bBox.bottom - (SelectionShiftBarHandleHeightPx / 2);
}

export function getShiftBarTopHoverTop(bBox: BBox): number {
    return bBox.top - (SelectionShiftBarHandleHeightPx / 2);
}

export function getShiftBarBottomHoverTop(bBox: BBox): number {
    return bBox.bottom - (SelectionShiftBarHandleHeightPx / 2);
}

export const getShiftBarTopSelectionLeft = R.compose((bBox: BBox): number => {
    return Object.keys(shiftBarTopSelectionLeftHandlerMap)
        .map(v => parseInt(v, 10))
        .sort((a, b) => b - a)
        .reduce((acc, criticalWidth) => {
            const handler = shiftBarTopSelectionLeftHandlerMap[criticalWidth];
            return (bBox.right - bBox.left > criticalWidth && acc === -10000) ? handler(bBox) : acc;
        },
        -10000);
}, keepMinBBox);

export function getShiftBarTopHoverLeft(bBox: BBox): number {
    return bBox.left;
}

const
    firstHalfSelectionHandleCreatorFactory = abstractFactory(
        bbox => (horizontalCenter(bbox) - leftAfter(bbox)),
        () => SelectionShiftBarHandleHeightPx
    ),
    firstHalfStripHandleCreatorFactory = abstractFactory(
        bbox => (horizontalCenter(bbox) - bbox.left),
        () => SelectionShiftBarHandleHeightPx
    ),
    secondHalfSelectionHandleCreatorFactory = abstractFactory(
        bbox => (right(bbox) - horizontalCenterAfter(bbox)),
        () => SelectionShiftBarHandleHeightPx
    ),
    secondHalfStripHandleCreatorFactory = abstractFactory(
        bbox => (bbox.right - horizontalCenter(bbox)),
        () => SelectionShiftBarHandleHeightPx
    ),
    hoverHandleFactory = abstractFactory(
        bBox => bBoxUtils.getWidth(bBox),
        () => HoverShiftBarHandleHeightPx
    ),
    smallSelectionHandleCreatorFactory = abstractFactory(
        () => SelectionShiftBarHandleWidthPx,
        () => SelectionShiftBarHandleHeightPx
    );

const
    selectionCreatorsLarge: Array<HandleCreator> = [
        firstHalfSelectionHandleCreatorFactory(
            getShiftBarTopSelectionTop,
            leftAfter,
            kinds.ShiftBarTopSelection
        ),
        firstHalfSelectionHandleCreatorFactory(
            getShiftBarBottomSelectionTop,
            leftAfter,
            kinds.ShiftBarBottomSelection
        ),
        secondHalfSelectionHandleCreatorFactory(
            getShiftBarTopSelectionTop,
            horizontalCenterAfter,
            kinds.ShiftBarTopSelection
        ),
        secondHalfSelectionHandleCreatorFactory(
            getShiftBarBottomSelectionTop,
            horizontalCenterAfter,
            kinds.ShiftBarBottomSelection
        )
    ],
    stripCreatorsLarge: Array<HandleCreator> = [
        firstHalfStripHandleCreatorFactory(
            getShiftBarTopSelectionTop,
            bbox => bbox.left,
            kinds.ShiftBarTopSelection
        ),
        firstHalfStripHandleCreatorFactory(
            getShiftBarBottomSelectionTop,
            bbox => bbox.left,
            kinds.ShiftBarBottomSelection
        ),
        secondHalfStripHandleCreatorFactory(
            getShiftBarTopSelectionTop,
            horizontalCenterAfter,
            kinds.ShiftBarTopSelection
        ),
        secondHalfStripHandleCreatorFactory(
            getShiftBarBottomSelectionTop,
            horizontalCenterAfter,
            kinds.ShiftBarBottomSelection
        )
    ],
    bottomSelectionCreators: Array<HandleCreator> = [
        firstHalfStripHandleCreatorFactory(
            getShiftBarBottomSelectionTop,
            bbox => bbox.left,
            kinds.ShiftBarBottomSelection
        ),
        secondHalfStripHandleCreatorFactory(
            getShiftBarBottomSelectionTop,
            horizontalCenterAfter,
            kinds.ShiftBarBottomSelection
        )
    ],
    topSelectionCreators: Array<HandleCreator> = [
        firstHalfSelectionHandleCreatorFactory(
            getShiftBarTopSelectionTop,
            leftAfter,
            kinds.ShiftBarTopSelection
        ),
        secondHalfSelectionHandleCreatorFactory(
            getShiftBarTopSelectionTop,
            horizontalCenterAfter,
            kinds.ShiftBarTopSelection
        ),
    ],
    selectionCreatorsSmall: Array<HandleCreator> = [
        smallSelectionHandleCreatorFactory(
            getShiftBarTopSelectionTop,
            getShiftBarTopSelectionLeft,
            kinds.ShiftBarTopSelection
        ),
        smallSelectionHandleCreatorFactory(
            getShiftBarBottomSelectionTop,
            getShiftBarTopSelectionLeft,
            kinds.ShiftBarBottomSelection
        )
    ],
    selectionCreatorsFactory = (
        bBox: BBox,
        containsStrip: boolean,
        {
            onlyTopShiftBar = false,
            onlyBottomShiftBar = false
        }: Record<string, any> = {}
    ): HandleCreator[] => {
        const notEnoghtSpace = (bBox.right - bBox.left) < minWidth;

        if (onlyBottomShiftBar) {
            return bottomSelectionCreators;
        }

        if (onlyTopShiftBar) {
            return topSelectionCreators;
        }

        if (notEnoghtSpace) {
            return selectionCreatorsSmall;
        }

        if (containsStrip) {
            return stripCreatorsLarge;
        }

        return selectionCreatorsLarge;
    },
    headerHoverCreator = [
        hoverHandleFactory(getShiftBarBottomHoverTop, getShiftBarTopHoverLeft, kinds.ShiftBarBottomHover),
    ],
    hoverCreators: Array<HandleCreator> = [
        hoverHandleFactory(getShiftBarTopHoverTop, getShiftBarTopHoverLeft, kinds.ShiftBarTopHover),
        hoverHandleFactory(getShiftBarBottomHoverTop, getShiftBarTopHoverLeft, kinds.ShiftBarBottomHover)
    ];

export {
    selectionCreatorsLarge,
    hoverCreators,
    headerHoverCreator,
    selectionCreatorsFactory
};
