import { bind as bindHotKeys, unbind as unbindHotKeys } from './view/Workspace/hotKeys/index';

export default (store: Store): void => {
    bindHotKeys(store);

    // @ts-ignore
    if (module.hot) {
        // @ts-ignore
        module.hot.accept('./view/Workspace/hotKeys/index', () => {
            unbindHotKeys();
            require('./view/Workspace/hotKeys/index').bind(store);
        });
    }
};
