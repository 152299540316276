import valueActionType from './valueActionType';
import type { ComboboxEpicStateTypes } from '../flowTypes';
import makeEpic from '../../../../epics/makeEpic';
import { COMBO_BOX_OPEN, COMBO_BOX_CLOSE } from '../actionTypes';
import { WORKSPACE_SCROLL } from '../../../../components/Workspace/actionTypes';

const
    defaultState: ComboboxEpicStateTypes = {
        renderComboList: true,
        isComoboOpen: false
    },
    epic = makeEpic({
        defaultState,
        valueActionType,
        updaters: [
            {
                conditions: [COMBO_BOX_OPEN],
                reducer: ({ state }) => ({ state: { ...state, isComoboOpen: true } })
            },
            {
                conditions: [WORKSPACE_SCROLL],
                reducer: ({ state }) => {
                    if (state.isComoboOpen) {
                        return {
                            state: { ...state, renderComboList: false }
                        };
                    }
                    return { state };
                }
            },
            {
                conditions: [COMBO_BOX_CLOSE],
                reducer: () => ({ state: defaultState })
            }
        ]
    });

export {
    epic as default
};
