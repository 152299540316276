import * as R from "ramda";
import {
    makeCombineReducer,
    makePayloadToSetterReducer,
    makeDefaultStateReducers,
    makeComponentBaseReducers
} from "../../../redux/makeReducer/index";
import * as path from "../../../mappers/path";
import * as LAYOUT_TYPE from "./constants/layoutType";
import * as HORIZONTAL_ALIGN from "./constants/horizontalAlign";
import * as VERTICAL_ALIGN from "./constants/verticalAlign";
import * as actionTypes from "./actionTypes";
import kind from "./kind";
import mobileDownReducer from "../../PropertiesPanel/view/MobileView/mobileDownReducer";
import { TOGGLE_MOBILE_DOWN_ACTION } from "../../PropertiesPanel/view/MobileView/actions";

export default makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(kind),
        ...makeDefaultStateReducers({
            layoutType: LAYOUT_TYPE.VERTICAL_SINGLE_LEVEL,
            startLevel: 1,
            horizontalAlign: HORIZONTAL_ALIGN.FIT,
            verticalAlign: VERTICAL_ALIGN.TOP,
            moreButtonEnabled: true,
            moreText: null,
            style: null,
            subStyle: null,
            themeStyles: null,
            isStickyToHeader: false
        })
    },
    handleActions: {
        [actionTypes.MENU_PROP_PANEL_LAYOUT_BTN_PRESSED]: (s, a) => {
            if (s.layoutType === a.payload) {
                return s;
            }

            if (!LAYOUT_TYPE.horizontalLayoutTypesMap[s.layoutType] && LAYOUT_TYPE.horizontalLayoutTypesMap[a.payload]) {
                return R.evolve(
                    {
                        horizontalAlign: () => HORIZONTAL_ALIGN.LEFT,
                        layoutType: () => a.payload
                    },
                    s
                );
            }

            return R.assoc("layoutType", a.payload, s);
        },
        [actionTypes.MENU_PROP_PANEL_START_LEVEL_CHANGED]: makePayloadToSetterReducer("startLevel"),
        [actionTypes.MENU_PROP_PANEL_ALIGMENT_BTN_PRESSED]: (component, { payload }) => R.merge(component, payload),
        [actionTypes.MENU_PROP_PANEL_ENABLE_MORE_BUTTON_TOGGLED]: R.evolve({
            moreButtonEnabled: R.not
        }),
        [actionTypes.MENU_PROP_PANEL_MORE_BTN_TEXT_CHANGED]: (state, { payload }) =>
            R.evolve(
                {
                    moreText: () => R.take(50, payload)
                },
                state
            ),
        [actionTypes.MENU_PROP_PANEL_HORIZONTAL_ALIGN_FIT_BTN_PRESSED]: R.evolve({
            horizontalAlign: R.ifElse(R.equals(HORIZONTAL_ALIGN.FIT), R.always(HORIZONTAL_ALIGN.CENTER), R.always(HORIZONTAL_ALIGN.FIT))
        }),
        [actionTypes.MENU_PROP_PANEL_GLOBAL_STYLE_UPDATED]: (component, { payload: { stylesheetId, stylesheetName } }) => {
            return R.pipe(R.assocPath(path.styleGlobalId, stylesheetId), R.assocPath(path.styleGlobalName, stylesheetName))(component);
        },
        [actionTypes.MENU_PROP_PANEL_SUBMENU_GLOBAL_STYLE_UPDATED]: (component, { payload: { stylesheetId, stylesheetName } }) =>
            R.pipe(
                R.assocPath(path.subMenuStyleGlobalId, stylesheetId),
                R.assocPath(path.subMenuStyleGlobalName, stylesheetName)
            )(component),
        [actionTypes.MENU_PROP_PANEL_GLOBAL_THEME_STYLE_UPDATED]: (component, { payload: { stylesheetId, stylesheetName } }) => {
            return R.pipe(
                R.assocPath(["themeStyles", "mainMenu", "id"], stylesheetId),
                R.assocPath(["themeStyles", "mainMenu", "name"], stylesheetName)
            )(component);
        },
        [actionTypes.MENU_PROP_PANEL_SUBMENU_GLOBAL_THEME_STYLE_UPDATED]: (component, { payload: { stylesheetId, stylesheetName } }) => {
            return R.pipe(
                R.assocPath(["themeStyles", "submenu", "id"], stylesheetId),
                R.assocPath(["themeStyles", "submenu", "name"], stylesheetName)
            )(component);
        },
        [actionTypes.MENU_PROP_PANEL_ACCENT_COLOR_CHANGE]: (component, { payload: { color } }) => {
            return R.pipe(R.assocPath(["style", "accentColor"], color))(component);
        },
        [TOGGLE_MOBILE_DOWN_ACTION]: mobileDownReducer
    }
});
