import React from "react";
import styles from "./ApplyingThemingDialog.css";
import { StripTypes } from "../../../view/common/dialogs/baseDialog/Dialog";
import StandardDialog from "../../../view/common/dialogs/StandardDialog/index";
import LoadingIndicator from "../../../view/common/LoadingIndicator/index";
import Msg from "../../../view/intl/Msg";
import getCenteredDialogConfig from "../../DialogManager/getCenteredDialogConfig";

const width = 470,
    height = 390,
    Dialog = () => {
        return (
            <StandardDialog stripType={StripTypes.NONE} showCloseBtn={false}>
                <div className={styles.container}>
                    <div className={styles.title}>
                        <Msg k="common.applyingColorTheme">Applying colour theme</Msg>
                    </div>
                    <div className={styles.animationContainer}>
                        <LoadingIndicator key="loading-indicator" className={styles.loadingBox} />
                    </div>
                    <div className={styles.pleaseWait}>
                        <Msg k="common.justAMoment">Just a moment...</Msg>
                    </div>
                </div>
            </StandardDialog>
        );
    };

const ApplyingThemingDialog = getCenteredDialogConfig({
    width,
    height,
    component: Dialog,
    forceModal: true
});
const ApplyingThemingDialogId = "ApplyingThemingDialogId";
export { ApplyingThemingDialog, ApplyingThemingDialogId };
