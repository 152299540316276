import React from 'react';
import { DATA_TEST_ID, DefaultAllowed } from '../constants';
import { getLink } from '../utils';
import '../../view/Preview.css';

const SpotifyView = props => {
    const { width, height, link, theme, startTime } = props;
    const iframeSrc = getLink(link, { theme, startTime });

    return (
        <div data-testid={DATA_TEST_ID} className="widget" style={{ width }}>
            <iframe
                style={{ borderRadius: 12 }}
                src={iframeSrc}
                width="100%"
                height={height}
                frameBorder="0"
                allowFullScreen
                allow={DefaultAllowed}
                loading="lazy"
            />
        </div>
    );
};

export { SpotifyView };
