import { getPreferencesApiAction, putPreferencesApiAction } from '../../redux/middleware/api/commonApiActions';
import type { PutPreferencesRequestBody } from '../../../dal/flowTypes';
import type { SavePreferencesPartial } from './types';

export const
    GET_PREFERENCES_REQUEST_ACTION = 'GET_PREFERENCES_REQUEST_ACTION',
    GET_PREFERENCES_SUCCESS_ACTION = 'GET_PREFERENCES_SUCCESS_ACTION',

    GET_PREFERENCES_FAILURE_ACTION = 'GET_PREFERENCES_FAILURE_ACTION',
    getPreferencesAction = () => getPreferencesApiAction({
        types: [
            GET_PREFERENCES_REQUEST_ACTION,
            GET_PREFERENCES_SUCCESS_ACTION,
            GET_PREFERENCES_FAILURE_ACTION,
        ],
    });

export const
    SET_DEFAULT_PREFERENCES_ACTION = 'SET_DEFAULT_PREFERENCES_ACTION',
    setDefaultPreferencesAction = (domain: string) => ({
        type: SET_DEFAULT_PREFERENCES_ACTION,
        payload: domain,
    });

// DO NOT USE THIS ACTION FROM UI !
// Use savePreferencesAction() instead
export const
    PUT_PREFERENCES_REQUEST_ACTION = 'PUT_PREFERENCES_REQUEST_ACTION',
    PUT_PREFERENCES_SUCCESS_ACTION = 'PUT_PREFERENCES_SUCCESS_ACTION',
    PUT_PREFERENCES_FAILURE_ACTION = 'PUT_PREFERENCES_FAILURE_ACTION',
    putPreferencesAction = (data: PutPreferencesRequestBody) => putPreferencesApiAction({
        types: [
            PUT_PREFERENCES_REQUEST_ACTION,
            PUT_PREFERENCES_SUCCESS_ACTION,
            PUT_PREFERENCES_FAILURE_ACTION,
        ],
        data,
    });

export const
    SAVE_PREFERENCES_ACTION = 'SAVE_PREFERENCES_ACTION',
    savePreferencesAction = (payload: SavePreferencesPartial) => ({
        type: SAVE_PREFERENCES_ACTION,
        payload,
    });

export const
    MERGE_PREFERENCES_ACTION = 'MERGE_PREFERENCES_ACTION',
    mergePreferencesAction = (payload: SavePreferencesPartial) => ({
        type: MERGE_PREFERENCES_ACTION,
        payload,
    });
