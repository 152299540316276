import type { PageTemplateSelectorState } from "../types";
import { DataPageTemplateRef } from "../../../../../dal/model";
import { nSort } from '../../../../../utils/nSort';

export const holdPageTemplateSelectorItem = (
    itemId: string,
    state: PageTemplateSelectorState
): PageTemplateSelectorState => {
    const { itemsOnHold } = state;

    if (itemsOnHold.indexOf(itemId) !== -1) {
        throw new Error(`Item '${itemId}' is already on hold`);
    }

    return {
        ...state,
        itemsOnHold: [...itemsOnHold, itemId]
    };
};

export const unholdPageTemplateSelectorItem = (
    itemId: string,
    state: PageTemplateSelectorState
): PageTemplateSelectorState => {
    const
        { itemsOnHold } = state,
        index = itemsOnHold.indexOf(itemId);

    if (index === -1) {
        throw new Error(`Item '${itemId}' is not on hold`);
    }

    const nextItemsOnHold = [...itemsOnHold];
    nextItemsOnHold.splice(index, 1);

    return {
        ...state,
        itemsOnHold: nextItemsOnHold
    };
};

export const sortPageTemplates = (templates: Array<DataPageTemplateRef>): Array<DataPageTemplateRef> =>
    nSort(templates, (a: DataPageTemplateRef, b: DataPageTemplateRef) => [a.name, b.name]);
