import React from "react";
export const SvgEmailCircleFillLight = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <path
                fill="#ffffff"
                d="M24 48c13.255 0 24-10.745 24-24S37.255 0 24 0 0 10.745 0 24s10.745 24 24 24z"
            />
            <g fill="#3c3c3c">
                <path d="M16.636 15.863a2.272 2.272 0 00-2.273 2.273v10.907a2.272 2.272 0 002.273 2.272h15.452a2.272 2.272 0 002.272-2.272V18.136a2.272 2.272 0 00-2.272-2.273H16.636zm0-1.363h15.452a3.636 3.636 0 013.635 3.636v10.907a3.636 3.636 0 01-3.635 3.636H16.636A3.636 3.636 0 0113 29.043V18.136a3.636 3.636 0 013.636-3.636z" />
                <path d="M26.36 24.733l-1.502 1.596a.682.682 0 01-.993 0l-1.501-1.596-4.792 4.792a.682.682 0 01-.964-.964l4.82-4.82-4.834-5.138a.682.682 0 01.993-.934l6.775 7.198 6.775-7.198a.682.682 0 01.992.934l-4.835 5.137 4.821 4.82a.682.682 0 01-.964.965l-4.791-4.792z" />
            </g>
        </g>
    </svg>
);
