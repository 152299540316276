import { GroupTypes } from "../../../constants";
import ImagesMCTA from "./ImagesMCTA";
import ImageAndTextMCTA from "./ImageAndTextMCTA";
import TextAndTextMCTA from "./TextAndTextMCTA";

export default {
    [GroupTypes.images]: ImagesMCTA,
    [GroupTypes.imageAndText]: ImageAndTextMCTA,
    [GroupTypes.textAndText]: TextAndTextMCTA
};
