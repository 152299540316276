import makeUuid from "../../../../../utils/makeUuid";
import { DataSectionLink, DataSite } from "../../../../../dal/model";
import { DATA_LINK_SECTION_TYPE } from "../../../../../dal/model/utils/dataSiteItemTypes";
import type { SectionLinkFormResult } from "../flowTypes";
import { replaceTagsWithContent } from "../../../oneweb/Text/view/replaceTagsWithContent";

export default (input: SectionLinkFormResult, site: DataSite, globalVariables: Object): DataSectionLink => {
    const
        { pageId, sectionId, name, hidden } = input;
    return new DataSectionLink({
        id: makeUuid(),
        type: DATA_LINK_SECTION_TYPE,
        name: replaceTagsWithContent(name, { globalVariables, site }),
        hidden,
        pageId,
        sectionId
    });
};
