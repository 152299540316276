import React from 'react';
import { WidgetWorkspaceView } from '../../view/workspace';
import { TwitterView } from './index';

const TwitterWorkspace = props => (
    <WidgetWorkspaceView {...props}>
        <TwitterView {...props} />
    </WidgetWorkspaceView>
);

export { TwitterWorkspace };
