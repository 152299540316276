/* eslint-disable max-len */
import { pure } from 'recompose';
import * as React from "react";
import cx from "classnames";

import { getDAL } from "../../../../../dal/index";
import { injectIntl, Msg } from "../../../../view/intl/index";
import logout from "../../actionCreators/logout";
import { openExternalLink } from "../../actionCreators/openExternalLink";
import Popup from "../Popup/index";
import TopBarDropdownMenuItem, { TopBarDropdownMenuDivider } from '../TopBarDropdownMenuItem/index';
import type { TopBarUserProfileProps } from "../../flowTypes";
import styles from "./TopBarUserProfile.css";
import { TopBarLanguages } from './TopBarLanguages';
import VerticalSpacer from '../../../../view/common/VerticalSpacer';
import { topBarHideLanguagesAction, topBarShowLanguagesAction } from "../../actionCreators/index";
import topBarStyles from '../TopBar.css';
import getStyleIntValue from "../../../../utils/getStyleIntValue";
import { TooltipPosition } from '../../../presentational/Tooltip/constants';
import { DemoTip } from '../../../../../demo/modules/tip/DemoTip';
import { isDemoSubscriptionType } from '../../../App/epics/subscriptionData/isDemoSubscriptionType';
import { topBarWhatsNewOptionClickedAction } from '../../../WhatsNew/actions';
import type { ReactElementRef } from '../../../../globalTypes';
import { WhatsNewTip } from '../../../WhatsNew/tip/WhatsNewTip';
import { openSiteSettingsDialog } from "../../../SiteSettings/SiteSettingsDialog/actionCreators";
import {
    TOPBAR_ON_MENU_CLICKED,
    GO_TO_DASHBOARD_CLICKED_ACTION
} from '../../actionTypes';
import { isWbtgenTrialOneComCpUser } from "../../../../../demo/utils/isWbtgenTrialOneComCpUser";
import { Flex } from "../../../../view/reflexbox/index";
import { getSubscriptionLabel } from "../utils";
import strUtils from "../../../../../utils/string";
import { isEcommerceSubscription, isPremiumSubscription, isUnknownSubscription } from "../../../App/epics/subscriptionData/utils";
import OpenUpgradePlanDialog from "../Upgrade/UpgradePlansDialog/OpenUpgradePlanDialog";
import getFeatureManager from '../../../../getFeatureManager';
import { isEcommerceSubscriptionTypeEnabled, getExternalPlansLink } from "../../../ComponentTierManager/utils";
import { configHideGoToCPLink, getControlPanelLink } from './utils';
import { upgradeDialogOpenedAction } from '../../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions';
import { FEATURE_BURGER_MENU } from '../Upgrade/tracking/contants';
import { UpgradePlansDialogId } from '../dialogIds';

const TOP_BAR_HEIGHT = getStyleIntValue(topBarStyles, 'topBarHeight');

type State = {
    isTrialOneComCpUser: boolean,
};

class _TopBarUserProfile extends React.Component<TopBarUserProfileProps, State> {
    domainName: string;
    languagesRef: any;
    userProfileButtonRef: ReactElementRef<HTMLAnchorElement>;
    mouseLeaveTimer: ReturnType<typeof setTimeout> | null | undefined;

    constructor(props) {
        super(props);

        // State initialization
        this.state = { isTrialOneComCpUser: false };

        this.domainName = getDAL().getDomain();

        this.languagesItemOnMouseEnter = this.languagesItemOnMouseEnter.bind(this);
        this.languagesItemOnMouseLeave = this.languagesItemOnMouseLeave.bind(this);
        this.menuOnClick = this.menuOnClick.bind(this);
        this.openExternalLink = this.openExternalLink.bind(this);
        this.onLogoutClick = this.onLogoutClick.bind(this);
        this.onSubscriptionLabelClick = this.onSubscriptionLabelClick.bind(this);

        this.userProfileButtonRef = React.createRef();
    }

    languagesItemOnMouseEnter() {
        if (this.mouseLeaveTimer) {
            clearTimeout(this.mouseLeaveTimer);
            this.mouseLeaveTimer = null;
        }

        const top = this.languagesRef.getBoundingClientRect().top - TOP_BAR_HEIGHT;
        this.props.dispatch(topBarShowLanguagesAction(top));
    }

    languagesItemOnMouseLeave() {
        if (this.mouseLeaveTimer) {
            this.mouseLeaveTimer = setTimeout(() => {
                this.props.dispatch(topBarHideLanguagesAction());
                this.mouseLeaveTimer = null;
            }, 250);
        }
    }

    menuOnClick() {
        this.props.dispatch({ type: TOPBAR_ON_MENU_CLICKED });
    }

    openExternalLink() {
        this.props.dispatch(openExternalLink());
    }

    onLogoutClick() {
        const
            { dispatch } = this.props,
            fromTemplateSelector = this.$isDemo() ? false : this.props.fromTemplateSelector,
            action = logout({ fromTemplateSelector });

        dispatch(action);
    }

    onSubscriptionLabelClick(e) {
        const { subscriptionData } = this.props;
        const subscriptionType = subscriptionData?.subscriptionType;
        const externalPlansLink = getExternalPlansLink();
        const userHasHighestSubscription = isEcommerceSubscriptionTypeEnabled() ? this.userHasEcommerceSubscription()
            : this.userHasPremiumSubscription();
        if (!((userHasHighestSubscription && externalPlansLink) || this.$isDemo() || isUnknownSubscription(subscriptionType))) {
            // Prevent click on <a>
            e.preventDefault();
            // Dont' let others respond to click
            e.stopPropagation();
            this.props.dispatch(OpenUpgradePlanDialog(subscriptionData, 'TopBar:BurgerMenuSubscriptionLabel'));
            this.props.dispatch(upgradeDialogOpenedAction({
                feature: FEATURE_BURGER_MENU,
                dialogId: UpgradePlansDialogId
            }));
        }
    }

    onWhatsNewClick = () => {
        this.props.dispatch(topBarWhatsNewOptionClickedAction());
    };

    userHasEcommerceSubscription() {
        const { subscriptionData: { subscriptionType } } = this.props;
        return isEcommerceSubscription(subscriptionType);
    }

    userHasPremiumSubscription() {
        const { subscriptionData: { subscriptionType } } = this.props;
        return isPremiumSubscription(subscriptionType);
    }

    $isDemo() {
        return isDemoSubscriptionType(this.props.subscriptionData);
    }

    $isNotDemo() {
        return !this.$isDemo();
    }

    componentDidMount() {
        if (this.$isDemo()) {
            isWbtgenTrialOneComCpUser().then(res => this.setState({ isTrialOneComCpUser: res }));
        }
    }

    render() {
        const
            {
                subscriptionData: { subscriptionType, metadata: subscriptionMetadata },
                isShown,
                languages,
                dispatch,
                showDomain = true,
                fromTemplateSelector,
                whatsNewCount,
                publishWithSSL,
                intl,
            } = this.props,
            viewSiteText = intl.msgJoint("msg: topbar.profile.viewSite {View site}"),
            hasWhatsNew = !!whatsNewCount,
            isWhatsNewCountPlus = whatsNewCount > 9,
            whatsNewCountValue = isWhatsNewCountPlus ? 9 : whatsNewCount,
            domainLink = (publishWithSSL ? 'https://' : 'http://') + this.domainName, // eslint-disable-line no-unused-vars
            isNotDemo = this.$isNotDemo(),
            showCpLink = !configHideGoToCPLink && (isNotDemo || this.state.isTrialOneComCpUser),
            isDashboardEnabled = getFeatureManager().isDashboardEnabled(),
            showDashboardLink = isDashboardEnabled && isNotDemo,
            showSubscriptionTypelabel = getFeatureManager().isSubscriptionTypeLabelShown(),
            subscriptionTypeLabel = getSubscriptionLabel(subscriptionType, subscriptionMetadata, intl);

        return (
            <div className={styles.userProfileButtonContainer}>
                <React.Fragment>
                    <a
                        className={cx(styles.userProfileButton, {
                            [styles.selected]: isShown
                        })}
                        onClick={this.menuOnClick}
                        ref={this.userProfileButtonRef}
                    >
                        <div className={styles.userProfileButtonIcon} />
                        {hasWhatsNew && (
                            <span className={styles.userProfileWhatsNewIndication} />
                        )}
                    </a>
                    <WhatsNewTip userProfileButtonRef={this.userProfileButtonRef} />
                </React.Fragment>
                {
                    isShown &&
                    <Popup containerClassName={styles.popupContainer} tipClassName={styles.popupTip}>
                        {showDomain && (
                            // @ts-ignore
                            <DemoTip position={TooltipPosition.LEFT}>
                                <a
                                    onClick={this.openExternalLink}
                                    className={styles.domainName}
                                    target="_blank"
                                    href={domainLink}
                                    title={viewSiteText}
                                >
                                    <Flex column align="center" justify="center">
                                        <VerticalSpacer y={22} />
                                        <div className={styles.profileIcon} />
                                        <VerticalSpacer y={6} />
                                        <div className={styles.domainName}>{strUtils.ellipsis(this.domainName, this.$isDemo() ? 23 : 22)}</div>
                                        {showSubscriptionTypelabel &&
                                        <React.Fragment>
                                            <VerticalSpacer y={4} />
                                            <div
                                                className={cx(
                                                    styles.subscription,
                                                    { [styles.nonEcomSubs]: !this.userHasEcommerceSubscription() && !this.$isDemo() }
                                                )}
                                                onClick={this.onSubscriptionLabelClick}
                                                title=""
                                            >
                                                {subscriptionTypeLabel}
                                            </div>
                                        </React.Fragment>}
                                        <VerticalSpacer y={15} />
                                    </Flex>
                                </a>
                            </DemoTip>
                        )}
                        <TopBarDropdownMenuDivider />
                        {showDashboardLink && (
                            <TopBarDropdownMenuItem
                                icon={styles.dashboardIcon}
                                onClick={() => {
                                    this.openExternalLink();
                                    dispatch({ type: GO_TO_DASHBOARD_CLICKED_ACTION });
                                }}
                            >
                                <Msg k="go.to.dashboard">Dashboard</Msg>
                            </TopBarDropdownMenuItem>
                        )}
                        <TopBarDropdownMenuItem
                            icon={styles.whatsNewIcon}
                            itemTextClassName={styles.whatsNewItemText}
                            onClick={this.onWhatsNewClick}
                        >
                            <Msg k="common.whatsNew">What's new</Msg>
                            {hasWhatsNew && (
                                <span className={cx(styles.whatsNewCount, { [styles.plus]: isWhatsNewCountPlus })}>
                                    {whatsNewCountValue}
                                    {isWhatsNewCountPlus && <span>+</span>}
                                </span>
                            )}
                        </TopBarDropdownMenuItem>
                        {this.props.shouldShowSettingsLink && (<TopBarDropdownMenuItem
                            icon={styles.settingsIcon}
                            onClick={() => dispatch(openSiteSettingsDialog())}
                        >
                            <Msg k="go.to.settings">Settings</Msg>
                        </TopBarDropdownMenuItem>)}
                        {showCpLink && (
                            <TopBarDropdownMenuItem
                                icon={styles.controlPanelIcon}
                                href={getControlPanelLink(this.domainName)}
                                target="_blank"
                                onClick={this.openExternalLink}
                            >
                                <Msg k="common.controlPanel">Control Panel</Msg>
                            </TopBarDropdownMenuItem>
                        )}
                        <TopBarDropdownMenuItem
                            icon={styles.languageIcon}
                            className={styles.languageItem}
                            extraChildren={(
                                <TopBarLanguages
                                    state={languages}
                                    dispatch={dispatch}
                                    fromTemplateSelector={fromTemplateSelector}
                                    containerRef={ref => { this.languagesRef = ref; }}
                                />
                            )}
                            onMouseEnter={this.languagesItemOnMouseEnter}
                            onMouseLeave={this.languagesItemOnMouseLeave}
                        >
                            {languages.map[languages.current]}
                        </TopBarDropdownMenuItem>
                        <VerticalSpacer y={6} />
                        <TopBarDropdownMenuDivider />
                        <VerticalSpacer y={6} />
                        <TopBarDropdownMenuItem
                            icon={styles.logoutIcon}
                            onClick={this.onLogoutClick}
                        >
                            <Msg k="common.logout">Log out</Msg>
                        </TopBarDropdownMenuItem>
                        <VerticalSpacer y={6} />
                    </Popup>
                }
            </div>
        );
    }
}

export const TopBarUserProfile = injectIntl(pure(_TopBarUserProfile));
