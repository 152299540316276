export const
    FB_SDK_INIT_ACTION = 'FB_SDK_INIT_ACTION',
    facebookSdkInitAction = () => ({
        type: FB_SDK_INIT_ACTION,
    });

export const
    FB_SDK_LOGIN_ACTION = 'FB_SDK_LOGIN_ACTION',
    facebookSdkLoginAction = (loginType: string) => ({
        type: FB_SDK_LOGIN_ACTION,
        payload: {
            loginType
        }
    });

export const
    FB_SDK_LOGOUT_ACTION = 'FB_SDK_LOGOUT_ACTION',
    facebookSdkLogoutAction = () => ({
        type: FB_SDK_LOGOUT_ACTION,
    });

export const
    FB_SDK_API_ACTION = 'FB_SDK_API_ACTION',
    facebookGraphApiAction = (
        actionPath: string, actionMethod: string, actionParams: Record<string, any>,
        responseSuccessActionType: string, responseFailureActionType: string
    ) => ({
        type: FB_SDK_API_ACTION,
        payload: {
            actionPath,
            actionMethod,
            actionParams,
            responseSuccessActionType,
            responseFailureActionType,
        }
    });

export const
    FB_SDK_NOT_LOADED = 'FB_SDK_NOT_LOADED',
    facebookSdkNotLoaded = () => ({
        type: FB_SDK_NOT_LOADED,
    });
