import React from "react";

import type { TextLikeViewType, CalcRenderPropsResult } from "../types";
import { textLikeComponentViewFactory } from "../factories/textLikeComponentViewFactory";
import { isAddressSet } from '../../../../constants';
import { ReactElement } from "../types";
import { AddressComponentIcon } from "../svgs";

const {
    renderAddressToStringMultiline,
    renderAddressToStringSingleline,
} = require('../../../../../../server/shared/address/utils.js');

const renderForAddress = ({
    generic: { showOnOneLine },
    specific: { showCountry, showPlaceholderValue },
    placeholderText,
    deps
}: CalcRenderPropsResult<any, any>): ReactElement<any> => {
    const render = showOnOneLine ? renderAddressToStringSingleline : renderAddressToStringMultiline;

    return <span>{
        isAddressSet(deps) && !showPlaceholderValue ? render({ ...deps, showCountry }) : placeholderText
    }</span>;
};

const iconRenderForAddress = (
    { style }: any
):React.ReactElement<React.ComponentProps<any>, any> => <AddressComponentIcon style={style} />;

const getHrefForAddress = (props: any): string | null => (isAddressSet(props.deps) ? props.deps.addressUrl : null);

export const addressComponentView: TextLikeViewType<any> = textLikeComponentViewFactory({
    defaultLineSpacing: 0.5,
    getHref: getHrefForAddress,
    openInNewTab: true,
    // @ts-ignore
    render: renderForAddress,
    iconRender: iconRenderForAddress
});
