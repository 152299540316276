/* eslint-disable react/no-unused-prop-types */
import React from 'react';

import { connect } from 'react-redux';
import { $Values } from 'utility-types';

// IMPORTANT: This file contains translation string which are pending. Do not remove the import or delete the file even if it is empty.
import './pendingTranslations';
import cx from 'classnames';
import TopBar from '../components/TopBar/index';
import Panel from '../components/Panel/index';
import Preview from '../components/Preview/View/index';
import ToolTip from '../components/Tooltip/view/index';
import StickyToolTip from '../components/Tooltip/stickyTooltip/view/index';
import QuickTour from '../components/QuickTour/view/index';
import EditingModePanel from "../components/EditingModePanel/index";
import { MarketgooDialog } from "../components/Marketgoo";
import { view as ColorPicker } from "../components/ColorPicker/index";
import { view as ContextMenu } from '../components/ContextMenu/index';
import { PUBLISH_STATUS } from '../components/TopBar/constants';
import { view as PropertiesPanel } from '../components/PropertiesPanel/index';
import * as AppStatusTypes from "../components/App/epics/status/appStatusTypes";
import { OutWorkspaceView as DndAddComponent } from "../components/DndAddComponent/view";
import MousePositionCrossWithRespectToBrowser from '../components/Debug/MousePositionCrossWithRespectToBrowser';
import { showMousePosition } from "../utils/isDebug";
import * as appSelectors from '../redux/modules/selectors';
import Workspace from './Workspace/index';
import CurrentLocale from './intl/CurrentLocale';
import { DialogManager } from '../components/DialogManager/DialogManager';
import LoadingIndicator from './common/LoadingIndicator/index';
import './AppVariables.css';
import * as styles from './App.css';
import ErrorBoundary from "../redux/recoverAfterException/ErrorBoundary/ErrorBoundary";
import { SaveStatus } from "../components/Workspace/epics/saveStatus/SaveStatus";
import { getUserAgentReference } from "../utils/getUserAgentReference";
import { Intl, injectIntl } from "./intl/index";
import Backup from '../components/Backup/index';
import type { AppState } from "../redux/modules/flowTypes";
import type { PublishStatus } from "../components/TopBar/flowTypes";
import type { DndAddComponentState } from "../components/DndAddComponent/flowTypes";
import TemplateSelector from "../components/TemplateSelector_DEPRECATED/view/index";
import templateSelectorValueActionType from "../components/TemplateSelector_DEPRECATED/epics/templatesList/valueActionType";
import appStatusValueActionType from "../components/App/epics/status/valueActionType";
import languageValueActionType from "../components/TopBar/epics/languages/valueActionType";
import { colorThemeServiceSiteSettingsEpic } from "../components/SiteSettings/ColorThemeData/colorThemeServiceSiteSettingsEpic";
import MobileViewEditor from "../components/MobileViewEditor/view/index";
import { WbtgenMigrationView } from "./WbtgenMigration/index";
import { DemoBar } from '../../demo/modules/bottomBar/DemoBar';
import { OnboardingFlow } from '../components/Onboarding/OnboardingFlow';
import { DemoLoginPage } from "../../demo/modules/login/main/demoLoginPage/DemoLoginPage";
import { getWindowLocationQuery } from "../utils/getWindowLocationQuery";
import { VerifyEmailToaster } from "../../demo/modules/tip/VerifyEmailToaster";
import { TrialImportPage } from "../../demo/modules/import/view/TrialImportPage";
import AutoColorLeftPanel from '../components/AutoColorLeftPanel/index';
import { ThemeHistoryPopup } from "../components/AutoColorLeftPanel/ThemeHistory/ThemeHistoryView";
import ModernLayoutLeftPanel from '../components/ModernLayouts/view/LeftPanel/index';
import ModernLayoutOnBoardingPanel from '../components/ModernLayouts/view/onBoardingPanel/index';
import { getIsFileUploadingInProgress } from '../components/FileUploader/epic/selectors';
import { changesInOnboardingSelector } from '../components/Onboarding/Dynamic/Epic/selectors';

type Props = {
    dispatch: Function;
    status: Record<string, any>;
    isRestoring: boolean;
    isWaitingForTurnOnAutoColor: boolean;
    preview: { show: boolean };
    cursor: string;
    workspaceSaveStatus: $Values<typeof SaveStatus>;
    publishStatus: PublishStatus;
    isLoggedOut: boolean;
    dndAddComponent: DndAddComponentState;
    templateSelector: {
        show?: boolean,
        isFirstVisit?: boolean
    };
    intl: Intl;
    languages: Record<string, any>;
    // TODO remove in WBTGEN-4990
    crash: boolean;
    fileUploadingInProgress: boolean;
    changesInOnboarding: boolean;
};

// TODO remove in WBTGEN-4990
const Crash = ({ crash }) => {
    if (crash) {
        throw new Error('render crash message');
    }
    return (
        <div />
    );
};

class AppRoot extends React.PureComponent<Props> {
    componentDidMount() {
        const self = this;
        window.addEventListener("beforeunload", e => {
            if (window.skipUnsavedChangesPrompt) return undefined;

            const {
                workspaceSaveStatus,
                isLoggedOut,
                isRestoring,
                languages,
                isWaitingForTurnOnAutoColor,
                fileUploadingInProgress,
                changesInOnboarding,
            } = self.props;
            if (
                (workspaceSaveStatus === SaveStatus.CAN_SAVE || fileUploadingInProgress || changesInOnboarding) &&
                !isLoggedOut &&
                !isRestoring &&
                !languages.changed &&
                !isWaitingForTurnOnAutoColor
            ) {
                e.preventDefault();
                // message just in case browser supports custom message
                const confirmationMessage = changesInOnboarding ?
                    'Changes that you made may not be saved' :
                    'Do want to reload this site ?';
                e.returnValue = confirmationMessage; // eslint-disable-line no-param-reassign
                return confirmationMessage;
            }
            return undefined;
        });
        // IE user agent reference
        const uaRef = getUserAgentReference();
        if (uaRef) {
            // $FlowFixMe: document should always have documentElement prop
            document.documentElement.setAttribute('data-useragent', uaRef);
        }

        // localize document title
        window.document.title = this.props.intl.msgJoint('msg: title {Website Builder}');
    }

    render() {
        const {
            status,
            preview: { show: isPreviewMode },
            cursor,
            dndAddComponent,
            templateSelector: { show: showTemplateSelector, isFirstVisit },
            crash,
        } = this.props;

        const cursorClass = styles[`cursor-${cursor}`];

        if (status.state === AppStatusTypes.SHOW_WBTGEN_MIGRATION_SCREEN) {
            return (<WbtgenMigrationView />);
        } else if (status.state === AppStatusTypes.TRIAL_IMPORT) {
            return <TrialImportPage />;
        } else if (status.state === AppStatusTypes.ONBOARDING) {
            return (
                <React.Fragment>
                    <OnboardingFlow />,
                    <ErrorBoundary invisible> <DialogManager /> </ErrorBoundary>,
                    <ErrorBoundary invisible> <ColorPicker /> </ErrorBoundary>,
                    <div>
                        <ErrorBoundary invisible> <ThemeHistoryPopup /> </ErrorBoundary>
                    </div>
                </React.Fragment>
            );
        } else if (status.state === AppStatusTypes.DEMO_LOGIN_AUTHENTICATION) {
            // @ts-ignore
            return (<DemoLoginPage
                queryParams={getWindowLocationQuery()}
            />);
        } else if (status.state !== AppStatusTypes.READY) {
            return (
                <React.Fragment>
                    <LoadingIndicator className={styles.loadingBox} isGreen />,
                    <ErrorBoundary invisible> <DialogManager /> </ErrorBoundary>
                </React.Fragment>
            );
        }

        if (showTemplateSelector && isFirstVisit) {
            return (
                <div>
                    <ErrorBoundary>
                        <ErrorBoundary invisible> <CurrentLocale /> </ErrorBoundary>
                        {
                            //@ts-ignore
                            <TemplateSelector />
                        }
                    </ErrorBoundary>
                    <ErrorBoundary invisible> <ColorPicker /> </ErrorBoundary>
                    <ErrorBoundary invisible> <DialogManager /> </ErrorBoundary>
                    <ErrorBoundary invisible> <ThemeHistoryPopup /> </ErrorBoundary>
                </div>
            );
        }
        return (
            <div className={cx({ [cursorClass]: !!cursorClass, [this.props.intl.locale]: true, language: true })}>
                {/* TODO remove in WBTGEN-4990*/ }
                <ErrorBoundary>
                    <Crash crash={crash} />
                </ErrorBoundary>
                <div
                    className={cx('workspace', styles.workspaceWrapper)}
                    style={{ opacity: isPreviewMode ? 0 : 1 }}
                >
                    <ErrorBoundary invisible> <CurrentLocale /> </ErrorBoundary>
                    <ErrorBoundary> <TopBar /> </ErrorBoundary>
                    <div className={styles.leftPanelAndWorkspace}>
                        <ErrorBoundary> <Panel /> </ErrorBoundary>
                        <ErrorBoundary> <AutoColorLeftPanel /> </ErrorBoundary>
                        <ErrorBoundary> <ModernLayoutLeftPanel /> </ErrorBoundary>
                        <ErrorBoundary> <ModernLayoutOnBoardingPanel /> </ErrorBoundary>
                        { /* @ts-ignore */ }
                        <ErrorBoundary> <Workspace /> </ErrorBoundary>
                    </div>
                    { /* @ts-ignore */ }
                    <ErrorBoundary invisible> <ToolTip /> </ErrorBoundary>
                    <ErrorBoundary invisible> <ThemeHistoryPopup /> </ErrorBoundary>
                    { /* @ts-ignore */ }
                    <ErrorBoundary invisible> <StickyToolTip /> </ErrorBoundary>
                    <ErrorBoundary invisible> <QuickTour /> </ErrorBoundary>
                    <ErrorBoundary invisible> <EditingModePanel /> </ErrorBoundary>
                    <ErrorBoundary invisible> <MarketgooDialog /> </ErrorBoundary>
                    <ErrorBoundary invisible> <PropertiesPanel /> </ErrorBoundary>
                    <ErrorBoundary invisible> <ContextMenu /> </ErrorBoundary>
                    <ErrorBoundary invisible> <ColorPicker /> </ErrorBoundary>
                    <ErrorBoundary invisible> <DndAddComponent state={dndAddComponent} /> </ErrorBoundary>
                    { /* @ts-ignore messages is not required, please verify and remove */ }
                </div>
                <ErrorBoundary invisible> <DialogManager /> </ErrorBoundary>
                <ErrorBoundary>
                    <Preview />
                </ErrorBoundary>
                <ErrorBoundary>
                    <MobileViewEditor />
                </ErrorBoundary>
                {
                    showTemplateSelector &&
                        <ErrorBoundary>
                            {
                                // @ts-ignore }
                                <TemplateSelector />
                            }
                        </ErrorBoundary>
                }
                {
                    //@ts-ignore
                    <Backup />
                }
                {showMousePosition() && <MousePositionCrossWithRespectToBrowser />}
                <DemoBar />
                <VerifyEmailToaster />
            </div>
        );
    }
}

function mapStateToProps(appState: AppState) {
    return {
        // TODO WBTGEN-4137 Handle App loading calculations in AppStatus epic
        status: appState.epics[appStatusValueActionType]
        || appSelectors.isSiteMapLoadingSelector(appState)
        || !appState.topBar
        // @ts-ignore
        || appState.topBar.publishStatus === PUBLISH_STATUS.DEFAULT,
        preview: appState.preview,
        cursor: appState.cursor,
        // TODO remove in WBTGEN-4990
        crash: appState.crash,
        workspaceSaveStatus: appState.workspaceSaveStatus,
        isLoggedOut: appState.logout.checkUnsavedChanges,
        isRestoring: appState.isRestoring,
        dndAddComponent: appState.dndAddComponent,
        fileUploadingInProgress: getIsFileUploadingInProgress(appState),
        templateSelector: appState.epics[templateSelectorValueActionType].state,
        languages: appState.epics[languageValueActionType].state,
        isWaitingForTurnOnAutoColor: appState.epics[colorThemeServiceSiteSettingsEpic.valueActionType]
            .state.isWaitingForTurnOnAutoColor,
        changesInOnboarding: changesInOnboardingSelector(appState)
    };
}

export default connect(mapStateToProps)(injectIntl(AppRoot)); // eslint-disable-line
