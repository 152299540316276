import { FM_DIALOG_ID } from "../../../FileManager/dialog/dialogId";
import { videoChooserDialogDefaultProps } from "../../../FileManager/videoChooser/constants";
import { VIDEO_SET } from "../actionTypes";
import { FmTabId } from "../../../FileManager/dialog/constants";

export const getOpenVideoFileConfig = (dialogProps: Record<string, any> = {}) => ({
    dialogId: FM_DIALOG_ID,
    dialogProps: {
        ...videoChooserDialogDefaultProps,
        onSaveAction: VIDEO_SET,
        isMultiSelect: false,
        forwardToComponent: true,
        hideFreeOneComImagesTab: false,
        hideShutterstockImagesTab: true,
        hideMyImagesTab: true,
        activeTabId: FmTabId.MY_VIDEOS,
        ...dialogProps
    }
});
