import { PinterestTypes, PinterestDimensions, DEFAULT_WIDTH, DEFAULT_HEIGHT } from './constants';
import { parseIframeCode, regexpsValidator } from '../utils';
import { type IframeParserOutput, type LinkValidator } from '../types';
import { type PinterestComponent } from './types';

const
    EmbedRegExp = /^https:\/\/assets\.pinterest\.[\w]{2,4}\/ext\/embed\.html\?id=(\d+)/,
    PinRegExp = /^https:\/\/www\.pinterest\.([\w]{2,4})\/pin\/(\d+)(?:\\??.*)$/,    //NOSONAR
    PinRegExp2 = /^https:\/\/in\.pinterest\.com\/pin\/(\d+)\/?$/,   //NOSONAR
    UserRegExp = /^(https:\/\/www\.pinterest\.[\w]{2,4}\/[^\/]+)(?:\\??.*)$/,   //NOSONAR
    BoardRegExp = /^https:\/\/www\.pinterest\.[\w]{2,4}\/[^\/]+\/[^\/]+(?:\\??.*)$/,    //NOSONAR
    PinterestLinkRegExps = [EmbedRegExp, PinRegExp, PinRegExp2, UserRegExp, BoardRegExp];

export const isIframeLink = (link: string): boolean => EmbedRegExp.test(link);

export const isPinterestLinkValid: LinkValidator = regexpsValidator(PinterestLinkRegExps);

export const getEmbedPinDo = (link: string) => {
    // Do not change order
    if (PinRegExp.test(link) || PinRegExp2.test(link) || EmbedRegExp.test(link)) {
        return PinterestTypes.pin;
    } else if (link.endsWith('_saved/') || link.endsWith('_created/') || link.endsWith('_created') || link.endsWith('_saved')) {
        return PinterestTypes.user;
    } else if (BoardRegExp.test(link)) {
        return PinterestTypes.board;
    } else if (UserRegExp.test(link)) {
        return PinterestTypes.user;
    }

    return '__UNKNOWN__';   // This should never happen
};

export const getLink = (link: string, embedType: string) => {
    if (embedType === PinterestTypes.pin) {
        let match: Array<string> = [];

        match = link.match(PinRegExp) || [];
        if (Array.isArray(match) && match.length > 2) {
            return `https://assets.pinterest.${match[1]}/ext/embed.html?id=${match[2]}`;
        }

        match = link.match(PinRegExp2) || [];
        if (Array.isArray(match) && match.length > 1) {
            return `https://assets.pinterest.com/ext/embed.html?id=${match[1]}`;
        }
    } else if (embedType === PinterestTypes.user) {
        const match = link.match(UserRegExp) || [];
        return match[1];
    }

    return link;
};

export const parsePinterestCode = (code): PinterestComponent => {
    const params = <PinterestComponent>{};
    const str = code.trim();

    if (isPinterestLinkValid(str)) {
        const embedType = getEmbedPinDo(str);
        const { width, height } = PinterestDimensions[embedType];
        params.link = getLink(str, embedType);
        params.width = width;
        params.height = height;
    } else {
        const { src, width, height }: IframeParserOutput = parseIframeCode(str, { width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT });
        if (src && isPinterestLinkValid(src)) {
            params.link = src;
            params.width = width;
            params.height = height;
        }
    }

    return params;
};
