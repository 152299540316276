export const
    INSTAGRAM_GALLERY_SET_IMAGES_ON_COMPONENT_DROPPED = 'INSTAGRAM_GALLERY_SET_IMAGES_ON_COMPONENT_DROPPED',
    INSTAGRAM_GALLERY_PP_COLUMNS_CHANGED = 'INSTAGRAM_GALLERY_PP_COLUMNS_CHANGED',
    INSTAGRAM_GALLERY_PP_SPACING_CHANGED = 'INSTAGRAM_GALLERY_PP_SPACING_CHANGED',
    INSTAGRAM_GALLERY_PP_CAPTIONS_ENABLED_CHECKBOX_CLICKED = 'INSTAGRAM_GALLERY_PP_CAPTIONS_ENABLED_CHECKBOX_CLICKED',
    INSTAGRAM_GALLERY_ON_CLICK_ACTION_CHANGED = 'INSTAGRAM_GALLERY_ON_CLICK_ACTION_CHANGED',
    INSTAGRAM_GALLERY_PP_ROWS_CHANGED = 'INSTAGRAM_GALLERY_PP_ROWS_CHANGED',
    INSTAGRAM_GALLERY_PP_SHOW_MORE_BTN_ENABLED = 'INSTAGRAM_GALLERY_PP_SHOW_MORE_BTN_ENABLED',
    INSTAGRAM_GALLERY_PP_SHOW_MORE_BTN_STYLE_CHANGED = 'INSTAGRAM_GALLERY_PP_SHOW_MORE_BTN_STYLE_CHANGED',
    INSTAGRAM_GALLERY_PP_SHOW_MORE_BTN_STYLE_CHANGED_AUTO_COLOR = 'INSTAGRAM_GALLERY_PP_SHOW_MORE_BTN_STYLE_CHANGED_AUTO_COLOR',
    INSTAGRAM_GALLERY_PP_SHOW_MORE_BTN_FONTSIZE_CHANGED = 'INSTAGRAM_GALLERY_PP_SHOW_MORE_BTN_FONTSIZE_CHANGED',
    INSTAGRAM_GALLERY_PP_DISCONNECT_BTN_PRESSED = 'INSTAGRAM_GALLERY_PP_DISCONNECT_BTN_PRESSED',
    INSTAGRAM_GALLERY_MOBILE_COLUMN_CHANGE = 'INSTAGRAM_GALLERY_MOBILE_COLUMN_CHANGE',
    INSTAGRAM_GALLERY_MOBILE_SPACING_CHANGE = 'INSTAGRAM_GALLERY_MOBILE_SPACING_CHANGE',
    INSTAGRAM_GALLERY_CHECK_CONNECTED_COOKIE_VALUE = 'INSTAGRAM_GALLERY_CHECK_CONNECTED_COOKIE_VALUE';
