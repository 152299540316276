import valueActionType from "./valueActionType";
import makeEpic from "../../../../epics/makeEpic";
import * as Actions from "../../actionTypes";
import mobileViewEditorVAT from "../reorder/valueActionType";
import { receiveOnly } from "../../../../epics/makeCondition";
import getCmpTypeById from "../../getCmpTypeById";
import { cmpTypes } from "../../constants";
import getId from "../../getId";

const getCmpChildren = (cmpId, data, groups) => {
    if (getCmpTypeById(cmpId) === cmpTypes.group) {
        return groups[cmpId] || [];
    }
    if (!data[cmpId] || !data[cmpId].length) {
        return [];
    }
    let children: any[] = [];
    data[cmpId].forEach(id => {
        if (!data[id] && getCmpTypeById(id) !== cmpTypes.group) {
            children.push(id);
        } else {
            children = [...children, ...getCmpChildren(id, data, groups)];
        }
    });
    return children;
};

export default makeEpic({
    defaultState: {
        latestLockedCmpId: null,
        latestUnlockedCmpId: null
    },
    valueActionType,
    updaters: [
        {
            conditions: [
                receiveOnly(mobileViewEditorVAT),
                Actions.MOBILE_EDITOR_UNLOCK_COMPONENTS
            ],
            reducer: ({ values: [{ data, groupsForView }, { componentId }], state }) => {
                const children = getCmpChildren(componentId, data, groupsForView);
                return {
                    state: {
                        ...state,
                        latestLockedCmpId: null,
                        latestUnlockedCmpId: getId(componentId),
                    },
                    actionToDispatch: {
                        type: Actions.MOBILE_EDITOR_TOGGLE_BOTTOM_LOCK,
                        payload: { componentId: children.length ? null : componentId, children, unlock: true }
                    }
                };
            }
        },
        {
            conditions: [
                receiveOnly(mobileViewEditorVAT),
                Actions.MOBILE_EDITOR_LOCK_COMPONENTS
            ],
            reducer: ({ values: [{ data, groupsForView }, { componentId }], state }) => {
                const children = getCmpChildren(componentId, data, groupsForView);
                return {
                    state: {
                        ...state,
                        latestLockedCmpId: componentId,
                        latestUnlockedCmpId: null,
                    },
                    actionToDispatch: {
                        type: Actions.MOBILE_EDITOR_TOGGLE_BOTTOM_LOCK,
                        payload: { componentId: children.length ? null : componentId, children, unlock: false }
                    }
                };
            }
        },
        {
            conditions: [
                Actions.MOBILE_EDITOR_RESET_STATUS_FLAGS
            ],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        latestLockedCmpId: null,
                        latestUnlockedCmpId: null,
                    },
                };
            }
        },
    ]
});
