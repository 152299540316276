import { makeFlagsToEnumMapper } from '../../utils';
import * as onClickActions from '../../../../src/components/oneweb/InstagramGallery/constants/onClickActions';

const
    { to, back } = makeFlagsToEnumMapper({
        toPropName: 'onClickAction',
        toInputMapper: ({ lightbox, links }) => [lightbox, links],
        tupleToOldVal: ([lightbox, links]) => ({ lightbox, links }),
        newFromOldMap: {
            [onClickActions.OPEN_LIGHT_BOX]: [true, false],
            [onClickActions.OPEN_LINK]: [false, true],
            [onClickActions.NOTHING]: [false, false]
        }
    });

export { to, back };
