import React from 'react';
import { Box } from '@mui/material';

import { ErrorBoundary } from '../Error/ErrorBoundary';
import { Footer } from './Footer';
import { useStyles } from './styles';
import { RenderWhen } from '../Helpers/RenderWhen';

type PageLayoutProps = {
    withFooterBar?: boolean;
    children: any;
};

export const PageLayout = ({ withFooterBar, children }: PageLayoutProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.rootContainer} data-testid="pageView">
            <ErrorBoundary
                reloadButton
                icon="large"
                classes={{ root: classes.errorRoot }}
            >
                {children}

                <RenderWhen when={withFooterBar}>
                    <Box className={classes.footerContainer}>
                        <Footer />
                    </Box>
                </RenderWhen>
            </ErrorBoundary>
        </Box>
    );
};
