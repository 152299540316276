import { FM_DIALOG_ID } from "../dialog/dialogId";
import { imageChooserDialogDefaultProps } from "./constants";
import type { MaxMultiSelectValidation } from "../../../view/common/FileChooser/flowTypes";

type Params = {
    onSaveAction: string,
    isMultiSelect?: boolean,
    maxMultiSelectValidation?: MaxMultiSelectValidation,
    hideMyVideosTab?: boolean
};

export const makeImageChooserConfigDialog = (params: Params) => ({
    dialogId: FM_DIALOG_ID,
    dialogProps: {
        ...imageChooserDialogDefaultProps,
        ...params,
    },
});
