import React from 'react';
import View from './View';
import { ComponentMaskBackground } from '../../../presentational/ComponentMask/constants';
import ComponentMask from '../../../presentational/ComponentMask/index';
import BaseComponent from '../../../../view/oneweb/BaseComponent';
import type { Props } from './flowTypes';
import * as styles from './Video.css';

export default (props: Props) => {
    const { settings = {} } = props;
    return (
        <BaseComponent {...props}>
            <div className={styles.videoComponent}>
                <ComponentMask maskStyle={ComponentMaskBackground.LIGHT}>
                    <View {...props} settings={{ ...settings, autoPlay: null }} />
                </ComponentMask>
            </div>
        </BaseComponent>
    );
};
