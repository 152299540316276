export const
    IMAGE_SLIDER_SET_IMAGES_ON_COMPONENT_DROP = 'IMAGE_SLIDER_SET_IMAGES_ON_COMPONENT_DROP',
    IMAGE_SLIDER_TOGGLE_AUTO_CROP_TO_FIT = 'IMAGE_SLIDER_TOGGLE_AUTO_CROP_TO_FIT',
    IMAGE_SLIDER_CONTROLS_SET_FWD_BK_METHOD_CHANGED = 'IMAGE_SLIDER_CONTROLS_SET_FWD_BK_METHOD_CHANGED',
    IMAGE_SLIDER_CONTROLS_SHOW_INDICATOR_METHOD_CHANGED = 'IMAGE_SLIDER_CONTROLS_SHOW_INDICATOR_METHOD_CHANGED',
    IMAGE_SLIDER_TRANSITION_METHOD_CHANGED = 'IMAGE_SLIDER_TRANSITION_METHOD_CHANGED',
    IMAGE_SLIDER_TRANSITION_TOGGLE_SHOW_NEXT_IMAGE_METHOD = 'IMAGE_SLIDER_TRANSITION_TOGGLE_SHOW_NEXT_IMAGE_METHOD',
    IMAGE_SLIDER_TRANSITION_SHOW_NEXT_IMAGE_DELAY_CHANGED = 'IMAGE_SLIDER_TRANSITION_SHOW_NEXT_IMAGE_DELAY_CHANGED',
    IMAGE_SLIDER_ON_CLICK_ACTION_CHANGED = 'IMAGE_SLIDER_ON_CLICK_ACTION_CHANGED',
    IMAGE_SLIDER_TOGGLE_CAPTIONS = 'IMAGE_SLIDER_TOGGLE_CAPTIONS',
    IMAGE_SLIDER_CAPTIONS_ALIGNMENT_CHANGED = 'IMAGE_SLIDER_CAPTIONS_ALIGNMENT_CHANGED',
    IMAGE_SLIDER_CAPTIONS_PLACEMENT_CHANGED = 'IMAGE_SLIDER_CAPTIONS_PLACEMENT_CHANGED',

    IMAGE_SLIDER_PP_OPEN_SORTING_WINDOW_CLICK = 'IMAGE_SLIDER_PP_OPEN_SORTING_WINDOW_CLICK',
    IMAGE_SLIDER_PP_FULL_WIDTH = 'IMAGE_SLIDER_PP_FULL_WIDTH',
    IMAGE_SLIDER_PP_FULL_WIDTH_MARGIN = 'IMAGE_SLIDER_PP_FULL_WIDTH_MARGIN',
    IMAGE_SLIDER_PP_FULL_WIDTH_MAX_WIDTH = 'IMAGE_SLIDER_PP_FULL_WIDTH_MAX_WIDTH',
    IMAGE_SLIDER_PP_MANAGE_LINKS_CLICK = 'IMAGE_SLIDER_PP_MANAGE_LINKS_CLICK';
