import React from "react";
import { getWidth, getHeight } from '../../../utils/bBox';
import styles from './Border.css';
import type { BorderProps } from './flowTypes';

const Border = ({
    bBox: { left, top, right, bottom },
    bBox,
    width,
    opacity = 1,
    color = 'black',
    animate,
    makeInvisible,
    dashed = false,
    borderWidth = 1,
}: BorderProps) => {
    const
        bBoxWidth = getWidth(bBox),
        bBoxHeight = getHeight(bBox);
    let commonStyles: Record<string, any> = {
        opacity,
    };
    if (dashed) {
        commonStyles = {
            ...commonStyles,
            border: `${borderWidth}px dashed ${color}`,
        };
    } else {
        commonStyles = {
            ...commonStyles,
            backgroundColor: color,
        };
    }
    const leftStyle = {
            ...commonStyles,
            left,
            top,
            height: bBoxHeight,
            width,
        },
        topStyle = {
            ...commonStyles,
            left,
            top,
            height: width,
            width: bBoxWidth,
        },
        rightStyle = {
            ...commonStyles,
            left: right - width,
            top,
            height: bBoxHeight,
            width,
        },
        bottomStyle = {
            ...commonStyles,
            left,
            top: bottom - width,
            height: width,
            width: bBoxWidth,
        };

    return (
        <div
            className={styles.border + ' ' + (animate ? styles.borderAnimate : '')}
            style={{ display: makeInvisible ? 'none' : 'block' }}
        >
            <div style={leftStyle} />
            <div style={topStyle} />
            <div style={rightStyle} />
            <div style={bottomStyle} />
        </div>
    );
};

export {
    Border
};
