import type { InputAction } from "../flowTypes";

const inputOnChange = (type: string, payload: string, params?: Record<string, any>): InputAction => ({
    type,
    payload,
    params
});

export {
    inputOnChange
};
