/* eslint-disable max-len */

import * as React from "react";
import cx from 'classnames';
import { Msg } from "../../../view/intl/index";
import * as colorMapper from '../../../mappers/color';
import * as parseColor from "../utils/parseColor";
import Label from '../../../view/common/Label';
import styles from "./ColorPicker.css";
import type { Color } from "../../../mappers/flowTypes";
import { ColoredSwatch } from './ColoredSwatch';
import ICONS from "../../../view/Icons/index";
import { findContrastRatio } from "../../ThemeGlobalData/utils/commonUtils";
import { CONTRAST_RATIO_LOWER_LIMIT } from '../../AutoColorLeftPanel/constants';

const EmptySwatch = () => <div className={styles.emptySwatch} />;

export const SuggestedColors = ({
    label,
    colors,
    onChange,
    selectedColor,
    isColorFeedbackOn,
    colorToCompare
}: { label: string, colors: Array<Color | null | undefined>, onChange: Function, selectedColor: Color, isColorFeedbackOn?: boolean, colorToCompare?: Color }) => {
    const [selectedColorBeforeHover, updateSelectedColor] = React.useState(selectedColor);

    const Feedback = () => {
        const isContrastGood = findContrastRatio(selectedColor, colorToCompare) >= CONTRAST_RATIO_LOWER_LIMIT;

        return <div className={styles.feedbackContainer}>
            {
                isContrastGood ?
                    <React.Fragment>
                        <ICONS.TICK_GREEN />
                        <Msg k="autoColor.leftPanel.colorPicker.positiveFeedback" className={cx(styles.feedbackDescription)}>This colour will work well together with your other colours</Msg>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <ICONS.EXCLAMATION_RED />
                        <Msg k="autoColor.leftPanel.colorPicker.warningFeedback" className={cx(styles.feedbackDescription, styles.warning)}>This colour will be difficult to see in combination with your other colours</Msg>
                    </React.Fragment>
            }
        </div>;
    };

    React.useEffect(() => {
        const anySelected = colors.find(color =>
            color && colorMapper.toHex(selectedColor) === colorMapper.toHex(color));

        if (!anySelected) updateSelectedColor(selectedColor);
    }, [selectedColor]);

    return <div className={styles.recentColors}>
        <div className={styles.recentColorsContentWrapper}>
            <Label className={styles.recentColorsHeading}>{label}</Label>
            <div className={styles.swatchesContainer}>
                {
                    colors.map((color, index) => {
                        return color ? <ColoredSwatch
                            key={index}
                            onMouseOut={() => onChange({ hsl: parseColor.toColorPicker(selectedColorBeforeHover) })}
                            selected={colorMapper.toHex(selectedColorBeforeHover) === colorMapper.toHex(color)}
                            color={color}
                            onMouseEnter={c => onChange(c)}
                            onClick={c => {
                                updateSelectedColor(color);
                                onChange(c);
                            }}
                        /> : <EmptySwatch key={index} />;
                    })
                }
            </div>
        </div>
        <div className={styles.separatorLine} />
        {isColorFeedbackOn && <Feedback />}
    </div>;
};
