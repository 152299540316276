import React from "react";
import cx from "classnames";
import { Box, Flex } from "../reflexbox/index";
import { injectIntl } from "../intl/index";
import VerticalSpacer from "../common/VerticalSpacer";
import HorizontalSpacer from "../common/HorizontalSpacer";
import styles from "./WbtgenMigration.css";

const
    introductionTitle = "msg: OldWsbToNewWsb.welcomeMessage {We are updating your Website Builder!}",
    messages = {
        newLook: {
            title: "msg: OldWsbToNewWsb.newLook.title {New look}",
            description: "msg: OldWsbToNewWsb.newLook.message {Enjoy the fresh, modern look of your workspace}",
            className: styles['wbtgenMigration-newLook']
        },
        mobileOptimized: {
            title: "msg: OldWsbToNewWsb.mobileOptimized.title {Optimized for mobile}",
            description: "msg: OldWsbToNewWsb.mobileOptimized.message {Choose how your site looks on mobile devices}",
            className: styles['wbtgenMigration-mobileOptimized']
        },
        freeImages: {
            title: "msg: OldWsbToNewWsb.freeImages.title {Free images}",
            description: "msg: OldWsbToNewWsb.freeImages.message {Get access to thousands of professional images}",
            className: styles['wbtgenMigration-freeImages']
        }
    };

const
    MigrationView = injectIntl(({ title, description, className, intl }: {
        title: string,
        description: string,
        className: string,
        intl: Intl
    }) => (
        <Box>
            <Flex align="center" justify="center">
                <div className={cx(styles['wbtgenMigration-bg'], className)} />
            </Flex>
            <VerticalSpacer y={27} />
            <Flex align="center" justify="center" className={styles['wbtgenMigration-title']}>
                <div>{intl.msgJoint(title)}</div>
            </Flex>
            <VerticalSpacer y={13} />
            <Flex align="center" justify="center">
                <div className={styles['wbtgenMigration-description']}>{intl.msgJoint(description)}</div>
            </Flex>
        </Box>
    )),
    WbtgenMigrationView = injectIntl(({ intl }: { intl: Intl }) => (
        <div data-testid="wbtgenMigrationView">
            <div className={styles['wbtgenMigration-progressBar']}>
                <span className={styles.progressBar} />
            </div>
            <Flex column align="center" justify="center" className={styles.wbtgenMigration}>
                <Flex align="center" justify="center">
                    <div className={styles['wbtgenMigration-updatingToNewWsb']}>
                        {intl.msgJoint(introductionTitle)}
                    </div>
                </Flex>
                <VerticalSpacer y={65} />
                <Flex align="center" justify="center">
                    <MigrationView {...messages.newLook} />
                    <HorizontalSpacer x={31} />
                    <MigrationView {...messages.mobileOptimized} />
                    <HorizontalSpacer x={31} />
                    <MigrationView {...messages.freeImages} />
                </Flex>
            </Flex>
        </div>
    ));

export {
    WbtgenMigrationView
};
