import { ShutterstockKind } from "../shutterstock/constants";
import { ThirdPartyImageKind } from "../unsplash/components/constants";

export const
    FmTabId = {
        MY_IMAGES: 'myImages',
        FREE_ONECOM_IMAGES: ShutterstockKind.FREE,
        PAID_SHUTTERSTOCK: ShutterstockKind.PAID,
        UNSPLASH: ThirdPartyImageKind.UNSPLASH,
        MY_VIDEOS: 'myVideos'
    },
    FM_DIALOG_WIDTH = 1162,
    FM_DIALOG_MIN_HEIGHT = 500,
    FM_DIALOG_MAX_HEIGHT = 723,
    FM_GRID_ROW_SIZE = 6,
    FM_GRID_GUTTER = 18;
