import { $Keys } from "utility-types";

export const WrapPositionMap = {
    left: 'left',
    center: 'center',
    right: 'right',
    above: 'above',
    below: 'below'
};

export type WrapPosition = $Keys<typeof WrapPositionMap>
