import { DefaultAllowed, DEFAULT_WIDTH, DEFAULT_HEIGHT } from "./constants";
import { parseIframeCode, regexpsValidator } from '../utils';
import { type IframeParserOutput, type LinkValidator } from '../types';
import { type VimeoComponent, type LinkParams } from './types';

const BaseRegexp = /^https:\/\/vimeo\.com\/\d+(?:\?.*)?$/;
const wbtgeN26841Url1 = /^https:\/\/vimeo\.com\/\d+\/[\w\d]+(?:\?(.*))?/,
    wbtgeN26841Url2 = /^https:\/\/player\.vimeo\.com\/video\/[\d]+\?h=[\w\d]+/;

const ValidLinkRegexps = [
    BaseRegexp,
    wbtgeN26841Url1,
    wbtgeN26841Url2,
    /^https:\/\/player\.vimeo\.com\/video\/\d+$/,
    /^https:\/\/vimeo\.com\/channels\/[^\/]+\/\d+$/
];

export const isVimeoLinkValid: LinkValidator = regexpsValidator(ValidLinkRegexps);

const
    BaseURL = "https://vimeo.com",
    EmbedURL = "https://player.vimeo.com/video",
    ChannelURL = /^https:\/\/vimeo\.com\/channels\/[^/]+/;

const
    autoplayEnabled = "autoplay=1",
    loopEnabled = "loop=1",
    titleDisabled = "title=0",
    bylineDisabled = "byline=0",
    portraitDisabled = "portrait=0";

export const getVideoEmbedLink = (link: string, flags: LinkParams): string => {
    const { autoplay, loop, portrait, title, byline } = flags;
    let videoEmbedLink = link;

    if (link.startsWith(EmbedURL)) {
        videoEmbedLink = link;
    } else if (ChannelURL.test(link)) {
        videoEmbedLink = link.replace(ChannelURL, EmbedURL);
    } else if (wbtgeN26841Url2.test(link)) {
        videoEmbedLink = link.replace(BaseURL, EmbedURL).replace(/\/[\w\d]+$/, '');
    } else if (wbtgeN26841Url1.test(link)) {
        videoEmbedLink = link.replace(BaseURL, EmbedURL).replace(/\/[\w\d]+$/, `?h=${link.split('/').pop()}`);
    } else {
        videoEmbedLink = link.replace(BaseURL, EmbedURL);
    }

    const params: string[] = [];

    if (autoplay) params.push(autoplayEnabled);
    if (!byline) params.push(bylineDisabled);
    if (loop) params.push(loopEnabled);
    if (!portrait) params.push(portraitDisabled);
    if (!title) params.push(titleDisabled);

    if (params.length) {
        if (videoEmbedLink.includes('?')) {
            videoEmbedLink += ("&" + params.join("&"));
        } else {
            videoEmbedLink += ("?" + params.join("&"));
        }
    }

    return videoEmbedLink;
};

export const parseVimeoCode = (code): VimeoComponent => {
    const params = <VimeoComponent>{};
    const str = code.trim();

    // User just copied the link from browser URL and pasted it
    if (isVimeoLinkValid(str)) {
        params.link = str;
    } else {
        const { src, width, height }: IframeParserOutput = parseIframeCode(str, { width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT });

        if (src) {
            let link = src, query = '';
            if (wbtgeN26841Url2.test(src) === false) {
                [link, query = ''] = src.split('?');
            }

            if (isVimeoLinkValid(link)) {
                params.link = link;
                params.allow = DefaultAllowed;
                params.width = width || DEFAULT_WIDTH;
                params.height = height || DEFAULT_HEIGHT;

                params.loop = query.includes(loopEnabled);
                params.title = !query.includes(titleDisabled);
                params.byline = !query.includes(bylineDisabled);
                params.portrait = !query.includes(portraitDisabled);
                params.autoplay = query.includes(autoplayEnabled);
            }
        }
    }

    return params;
};

export const getDisplayLink = link => link.replace(/^https:\/\/(player\.)?vimeo/, 'https://vimeo').replace(/\/video\//, '/');

