import * as actionTypes from "./actionTypes";
import CALL_API from '../../../../redux/middleware/api/CALL_API';
import type { ApiAction } from '../../../../redux/middleware/api/flowTypes';

export const disconnectGmbListing = (): ApiAction => ({
    [CALL_API]: {
        types: [
            actionTypes.GOOGLE_MY_BUSINESS_DISCONNECT_LISTING_REQUEST,
            actionTypes.GOOGLE_MY_BUSINESS_DISCONNECT_LISTING_SUCCESS,
            actionTypes.GOOGLE_MY_BUSINESS_DISCONNECT_LISTING_FAILURE
        ],
        endpoint: 'disconnectGmbListing'
    }
});
