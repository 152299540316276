// @ts-nocheck
const localeCodeShortTitleMap = {
    "da": "Da",
    "de": "De",
    "en": "En",
    "es": "Es",
    "fi": "Fi",
    "fr": "Fr",
    "it": "It",
    "nb": "No",
    "nl": "Nl",
    "pt": "Pt",
    "sv": "Sv"
};
const localeCodeToShortTitleLocale = function (localeCode) {
    if (localeCodeShortTitleMap[localeCode]) {
        return localeCodeShortTitleMap[localeCode];
    } else {
        return localeCode;
    }
};

export {
    localeCodeToShortTitleLocale
};
