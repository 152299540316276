import type { TableEditModeScope } from "../flowTypes";

const
    tableEditModeEpicDefaultScope: TableEditModeScope = {
        textToInsertAfterTinyMceInitialized: '',
        shouldQueueKeyPressValues: false,
        prevPressedKeysMap: { pressedKeys: 0 },
        selectedTableComponent: null
    };

export {
    tableEditModeEpicDefaultScope
};
