import React from 'react';
import type { ShutterstockState } from "../../shutterstock/types";
import { Shutterstock } from "../../shutterstock/components/Shutterstock";
import type { SstockQuotaConfig } from '../../../../../../server/shared/config/types';
import { ShutterstockKind } from "../../shutterstock/constants";
import styles from "./styles.css";
import { Msg } from "../../../../view/intl/index";
import { isPremiumSubscription } from "../../../App/epics/subscriptionData/utils";
import type { SubscriptionMetadata } from '../../../../redux/modules/flowTypes';
import { getPremiumPackageNameFromMetadata } from '../../../ComponentTierManager/utils';
import { ViewModes } from "../../../../view/common/FileChooser/constants";
import { FM_DIALOG_WIDTH, FM_GRID_GUTTER, FM_GRID_ROW_SIZE } from "../../dialog/constants";
import FileChooser from "../../../../view/common/FileChooser";
import { FileManagerMode } from "../../constants";
import { parseIntDec } from "../../../../../utils/number";
import fcBodyStyles from "../../../../view/common/FileChooser/FileChooserBody/FileChooserBody.css";
import type { FcContentTypePattern } from "../../../../redux/modules/children/fileChooser/utils/testFcContentType";
import Resource from '../../../../redux/modules/children/fileChooser/Resource';
import { MaxMultiSelectValidation } from '../../../../view/common/FileChooser/flowTypes';

type Props = {
    state: ShutterstockState,
    isMultiSelect?: boolean,
    subscriptionType: string,
    subscriptionMetadata?: SubscriptionMetadata,
    quotaConf: SstockQuotaConfig,
    dispatch: Dispatch,
    forwardToComponent?: boolean,
    onSaveAction?: string,
    isVideoFileDialog?: boolean,
    maxMultiSelectValidation?: MaxMultiSelectValidation,
    mode?: string,
    dialogHeight?: number,
    width?: number,
    preSelection?: Resource[],
    contentTypes?: FcContentTypePattern,
    headlessMode?: boolean
};

export class FreeOnecom extends React.Component<Props> {
    getCellChildren = (imageIsLocked?: boolean) => {
        const { subscriptionType, subscriptionMetadata } = this.props,
            premiumPackageNameFromMetadata = getPremiumPackageNameFromMetadata(subscriptionMetadata);

        if (!isPremiumSubscription(subscriptionType) && imageIsLocked) {
            return premiumPackageNameFromMetadata ? <span className={styles.freeButton}>{premiumPackageNameFromMetadata}</span>
                : <Msg className={styles.freeButton} k="common.premium">Premium</Msg>;
        }
        return <Msg className={styles.freeButton} k="fm.dialog.tab.freeOnecom.label">Free</Msg>;
    };

    render() {
        const {
                isMultiSelect = false,
                dialogHeight,
                width: propWidth = FM_DIALOG_WIDTH,
                preSelection,
                contentTypes,
                mode = FileManagerMode.FILE_MANAGER,
                maxMultiSelectValidation,
            } = this.props,
            // @ts-ignore it cab be a bug as dialogHeight is not always passed
            fcHeight = dialogHeight - 253,
            FC_HEIGHT = styles.fcHeight,
            PREVIEW_WIDTH = parseIntDec(fcBodyStyles.previewWidth),
            width = propWidth && propWidth - PREVIEW_WIDTH;
        return (this.props.isVideoFileDialog ?
            // @ts-ignore
            <FileChooser
                isMultiSelect={isMultiSelect}
                preSelection={preSelection}
                hideUploadPane
                isFreeOneComVideoFileChooser
                listTableHeight={fcHeight}
                showPreview
                width={width}
                activeViewMode={ViewModes.GRID}
                gridHeight={FC_HEIGHT}
                gridRowSize={FM_GRID_ROW_SIZE}
                gridGutter={FM_GRID_GUTTER}
                contentTypes={contentTypes}
                theme={{
                    container: styles.fc,
                    headerContainer: styles.fcHeader,
                    bredCrumbsPaneContainer: styles.breadCrumbsPane,
                    gridContainer: styles.fcGrid
                }}
                mode={mode}
                maxMultiSelectValidation={maxMultiSelectValidation}
                headlessMode={this.props.headlessMode}
                onSaveAction={this.props.onSaveAction}
                forwardToComponent={this.props.forwardToComponent}
            /> :
            <Shutterstock
                {...this.props}
                getCellChildren={this.getCellChildren}
                kind={ShutterstockKind.FREE}
                showDownloadBanners
            />
        );
    }
}
