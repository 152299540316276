/* eslint-disable max-len */

import React from "react";
import cx from "classnames";
import u from 'updeep';

import injectIntl from "../../../../../view/intl/injectIntl";
import dialogStyles from '../../../../../view/common/dialogs/baseDialog/Dialog.css';

import InputField from "../../../../../view/common/Input/InputField";
import PrimaryButton from "../../../../../view/common/Button/PrimaryButton";
import { COMPONENT_CONFIGURATION_CANCELED } from "../../../../../redux/modules/children/workspace/actionTypes";
import { Dialog, DialogBody, DialogFooter } from '../../../../../view/common/dialogs/baseDialog/index';
import { YoutubeVideoComponentConfigDialogConfig, YoutubeVideosContainerHeight } from "../../constants";
import type { YoutubeVideoComponentConfigDialogPropTypes, YoutubeVideoComponentConfigDialogState } from "../flowTypes";
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";
import videosEpic from '../../epics/videoSearch/index';
import * as styles from './YoutubeVideoDialog.css';
import LoadingIndicator from '../../../../../view/common/LoadingIndicator/index';
import Scrollbar from '../../../../../view/common/Scrollbar/index';
import { YOUTUBE_VIDEOS_SEARCH, PASTE_YOUTUBE_VIDEO_LINK } from "../../actionTypes";
import Msg from '../../../../../view/intl/Msg';
import { getCenterPositionForDialog } from "../../../../DialogManager/utility";
import YoutubeVideoContainer from './YoutubeVideoContainer';
import PasteYoutubeLink from './PasteYoutubeLink';
import { youtubeHostUrl } from "../../videoUrlsUtility";

const TERMS_MSG = 'msg: common.youtubeTermsOfService {YouTube Terms of Service}';
const TERMS_URL = 'https://www.youtube.com/t/terms';
const PRIVACY_MSG = 'msg: common.googlePrivacyPolicy {Google Privacy Policy}';
const PRIVACY_URL = 'https://policies.google.com/privacy';

const YoutubeVideoDialog = injectIntl(class YoutubeVideoDialog extends React.Component<YoutubeVideoComponentConfigDialogPropTypes, YoutubeVideoComponentConfigDialogState> {
    constructor(props: YoutubeVideoComponentConfigDialogPropTypes) {
        super(props);
        this.state = {
            value: '',
            previousToken: ''
        };
    }

    onYouTubeUploadClick() { // eslint-disable-line class-methods-use-this
        window.open(youtubeHostUrl + '/upload');
    }

    render() {
        const { dispatch, youtubeVideos: { nextPageToken, loading, fullLoad, records, pasteLink }, intl } = this.props,
            { previousToken } = this.state;

        const onSearch = fullLoad => {
            dispatch({ type: YOUTUBE_VIDEOS_SEARCH, payload: { value: this.state.value, fullLoad, nextPageToken } });
        };

        const onScroll = e => {
            if (nextPageToken && (nextPageToken.length > 0) && (nextPageToken !== previousToken) && (e.top === 1)) {
                this.setState({ ...this.state, previousToken: nextPageToken });
                onSearch(false);
            }
        };

        const onPasteVideoLink = () => {
            dispatch({ type: PASTE_YOUTUBE_VIDEO_LINK });
        };

        const linkYouTubeTermsOfService = (
            `<a
                href="${TERMS_URL}"
                class="${dialogStyles.dialogBlueLink}"
                target="_blank"
                rel="nofollow noopener noreferrer"
            >${intl.msgJoint(TERMS_MSG)}</a>`
        );

        const linkGooglePrivacy = (
            `<a
                href="${PRIVACY_URL}"
                class="${dialogStyles.dialogBlueLink}"
                target="_blank"
                rel="nofollow noopener noreferrer"
            >${intl.msgJoint(PRIVACY_MSG)}</a>`
        );

        const FOOTER_MSG = 'msg: video.footerNotice {By inserting a video, you accept {0} and {1}}';

        return (
            <Dialog onClose={() => { dispatch({ type: COMPONENT_CONFIGURATION_CANCELED }); }}>
                <DialogBody className={styles.youtubeDialogBody}>
                    {
                        pasteLink
                            ? <PasteYoutubeLink dispatch={dispatch} />
                            : <div>
                                <div className={cx({ [styles.headerContainerOnSearch]: records })}>
                                    <div className={styles.youtubeIcon} />
                                    <div className={styles.searchInputContainer}>
                                        <InputField
                                            value={this.state.value}
                                            placeholder="msg: component.video.searchVideos {Search videos}"
                                            className={styles.searchInput}
                                            onChange={value => { this.setState({ ...this.state, value }); }}
                                            onEnterPress={() => { onSearch(true); }}
                                        />
                                        <PrimaryButton onClick={() => { onSearch(true); }}>
                                            <Msg k="component.video.searchVideos">Search videos</Msg>
                                        </PrimaryButton>
                                        {!records && <div className={styles.uploadBtnContainer}>
                                            <PrimaryButton
                                                onClick={this.onYouTubeUploadClick.bind(this)}
                                                className={styles.uploadVideo}
                                            >
                                                <Msg k="component.video.uploadVideo">Upload video to YouTube</Msg>
                                            </PrimaryButton>
                                            <PrimaryButton onClick={onPasteVideoLink} className={styles.pasteVideoLink}>
                                                <Msg k="component.video.pasteVideoLink">
                                                    Paste a link to a YouTube video
                                                </Msg>
                                            </PrimaryButton>
                                        </div>}
                                    </div>
                                </div>
                                {
                                    ((!loading) && (records && records.length === 0)) &&
                                    <div className={styles.noResults}>
                                        <Msg k="common.noResults">No results to display</Msg>
                                    </div>
                                }
                                {(loading && fullLoad) && <LoadingIndicator className={styles.loading} />}
                                {
                                    (records && records.length > 0) &&
                                    <Scrollbar
                                        height={YoutubeVideosContainerHeight}
                                        className={styles.scrollBar}
                                        onScroll={onScroll}
                                    >
                                        <div className={styles.youtubeVideosListContainer}>
                                            {records.map((item, index) =>
                                                <YoutubeVideoContainer key={index} item={item} dispatch={dispatch} />)}
                                            {(nextPageToken && loading && !fullLoad) &&
                                            <LoadingIndicator className={styles.loadingItems} />}
                                        </div>
                                    </Scrollbar>
                                }
                            </div>
                    }
                </DialogBody>
                <DialogFooter
                    noTopBorder
                    height="50px"
                >
                    <div style={{ fontSize: 12, marginLeft: 10, marginRight: 10 }}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: intl.msgJoint([
                                    FOOTER_MSG,
                                    {
                                        '0': linkYouTubeTermsOfService,
                                        '1': linkGooglePrivacy
                                    }
                                ])
                            }}
                        />
                    </div>
                </DialogFooter>
            </Dialog>
        );
    }
});

const { dimensions: { width, height } } = YoutubeVideoComponentConfigDialogConfig;

const ConfigDialogHeightOnSearchInt = parseInt(styles.ConfigDialogHeightOnSearch, 10);

const updateConfFn = (config, dimensions) => {
    const { browserWidth, browserHeight } = dimensions,
        { dimensions: { width } } = config;
    return u({
        dimensions: {
            height: ConfigDialogHeightOnSearchInt
        },
        position: getCenterPositionForDialog(width, ConfigDialogHeightOnSearchInt, browserWidth, browserHeight)
    }, config);
};

const config = {
    ...getCenteredDialogConfig(width, height, YoutubeVideoDialog),
    updateConfFn,
    dependsOn: {
        youtubeVideos: videosEpic.reducer
    }
};

export default config;
