/* eslint-disable react/no-unused-prop-types */

import React from 'react';
import { injectIntl, MsgJointInput } from '../../../view/intl/index';
import * as styles from './PropertiesPanel.css';
import { Intl } from "../../../view/intl/injectIntl";
import { NewTag } from "../../../view/common/Tag/index";

type Props = {
    targetPagesIds: Array<string>,
    pagesTitles: { [pageId: string]: MsgJointInput },
    pageTitleEltsInNavigation?: { SCROLL_EFFECTS: React.FC },
    showNewTag?: boolean,
    navigateToPage: (pageId: string) => void,
    intl: Intl
};

const
    NavigationItem = ({ targetPageId, pagesTitles, pageTitleEltsInNavigation = {}, showNewTag, navigateToPage, intl }) => {
        const TitleElt = pageTitleEltsInNavigation[targetPageId];
        return (
            <div className={styles.navigationBar} onClick={() => navigateToPage(targetPageId)}>
                <div className={styles.titleCtn}>
                    {TitleElt ? <TitleElt /> : intl.msgJoint(pagesTitles[targetPageId])}
                    {showNewTag && <NewTag containerClassName={styles.newLabelContainer} labelClassName={styles.newLabelText} />}
                </div>
                <span className={styles.iconRight} />
            </div>
        );
    },
    NavigationGroup = ({ targetPagesIds, ...rest }: Props) => (
        <div className={styles.navigationGroup}>
            {targetPagesIds.map(pageId => (
                <NavigationItem
                    key={pageId}
                    targetPageId={pageId}
                    showNewTag={pageId === "Captcha"}
                    {...rest}
                />
            ))}
        </div>
    );

export default injectIntl(NavigationGroup);
