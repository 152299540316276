import { missingAssetUrls } from '../../MissingAssets/epic/index';
import { workspaceWidthReducer } from "../../Workspace/epics/workspaceBBox/selectors";
import colorPickerEpic from '../../ColorPicker/epic/index';
import colorPickerEpicValueActionType from '../../ColorPicker/epic/valueActionType';
import makeStateSelectorReducer from "../../../epics/makeStateSelectorReducer";
import { colorThemeSiteSettingsEpic } from "../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import stylesheetsEpic from "../../Workspace/epics/stylesheets/index";
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";
import { selectedComponentIsInsideHeaderOrFooterEpic } from "../../Workspace/epics/selectedComponentIsInsideHeaderOrFooterEpic/index";

export default {
    missingAssetUrls,
    workspaceBBoxWidth: workspaceWidthReducer,
    colorPickerOpened: makeStateSelectorReducer(
        colorPickerEpic.reducer,
        colorPickerEpicValueActionType,
        state => state.opened
    ),
    themeColorsData: makeStateSelectorReducer(
        stylesheetsEpic.reducer,
        stylesheetsEpic.valueActionType,
        getThemeColorsFromStylesheet
    ),
    themeSettingsData: colorThemeSiteSettingsEpic.reducer,
    selectedComponentIsInsideHeaderOrFooter: selectedComponentIsInsideHeaderOrFooterEpic.reducer
};
