import type { ApiAction } from "../../../src/redux/middleware/api/flowTypes";
import { apiAction } from "../../../src/redux/middleware/api/actions";

export const
    LOAD_TRIAL_IMPORT_AVAILABILITY_REQUEST_ACTION = 'LOAD_TRIAL_IMPORT_AVAILABILITY_REQUEST',
    LOAD_TRIAL_IMPORT_AVAILABILITY_SUCCESS_ACTION = 'LOAD_TRIAL_IMPORT_AVAILABILITY_SUCCESS_ACTION',
    LOAD_TRIAL_IMPORT_AVAILABILITY_FAILURE_ACTION = 'LOAD_TRIAL_IMPORT_AVAILABILITY_FAILURE_ACTION',
    loadTrialImportAvailabilityAction = (ownerEmail: string): ApiAction => {
        return apiAction({
            types: [
                LOAD_TRIAL_IMPORT_AVAILABILITY_REQUEST_ACTION,
                LOAD_TRIAL_IMPORT_AVAILABILITY_SUCCESS_ACTION,
                LOAD_TRIAL_IMPORT_AVAILABILITY_FAILURE_ACTION,
            ],
            endpoint: 'getTrialImportAvailability',
            endpointParams: ownerEmail,
        });
    };

export const
    SET_TRIAL_IMPORT_AVAILABILITY_DATA_ACTION = 'SET_TRIAL_IMPORT_AVAILABILITY_DATA_ACTION',
    setTrialImportAvailabilityDataAction = (trialAvailabilityData: Record<string, any>) => ({
        type: SET_TRIAL_IMPORT_AVAILABILITY_DATA_ACTION,
        payload: trialAvailabilityData
    });

export const
    TRIAL_IMPORT_DATA_REQUEST_ACTION = 'TRIAL_IMPORT_DATA_REQUEST_ACTION',
    TRIAL_IMPORT_DATA_SUCCESS_ACTION = 'TRIAL_IMPORT_DATA_SUCCESS_ACTION',
    TRIAL_IMPORT_DATA_FAILURE_ACTION = 'TRIAL_IMPORT_DATA_FAILURE_ACTION',
    trialImportDataAction = (ownerEmail: string, trialId: string, token: string): ApiAction => {
        return apiAction({
            types: [
                TRIAL_IMPORT_DATA_REQUEST_ACTION,
                TRIAL_IMPORT_DATA_SUCCESS_ACTION,
                TRIAL_IMPORT_DATA_FAILURE_ACTION,
            ],
            endpoint: 'importTrialData',
            endpointParams: [encodeURIComponent(ownerEmail), encodeURIComponent(trialId), encodeURIComponent(token)],
        });
    };
