import * as React from "react";
import cx from "classnames";
import bowser from "bowser";
import styles from "./TemplateCarousel.css";
import { leftPanelWidth } from "./TemplateSelectorStep.css";

const { safari } = bowser;
type Props = {
    templateWidth: number;
    selectedTemplateIndex: number;
    onChange: (...args: Array<any>) => any;
    items: React.ReactElement<any>[];
    className: string;
};
export const TemplateCarousel = ({ templateWidth, selectedTemplateIndex, onChange, items, className }: Props) => {
    const rightPanelWidth = `(100vw - ${leftPanelWidth})`,
        slideWidth = `min(${templateWidth}px, calc(${rightPanelWidth} * 0.8))`;
    let left;

    if (safari) {
        left = `((${rightPanelWidth} - ${slideWidth}) / 2)`;

        if (selectedTemplateIndex !== 0 && selectedTemplateIndex % 2 === 0) {
            left = `((${rightPanelWidth} - ${slideWidth}) / 1.999999)`;
        }

        if (selectedTemplateIndex !== 0) {
            left += ` - (${selectedTemplateIndex} * ${slideWidth})`;
        }

        left = `calc(${left})`;
    } else {
        left = `calc(((${rightPanelWidth} - ${slideWidth}) / 2) - (${selectedTemplateIndex} * ${slideWidth}))`;
    }

    return (
        <div
            className={cx(className, styles.slider, {
                [styles.canNavPrev]: selectedTemplateIndex !== 0,
                [styles.canNavNext]: selectedTemplateIndex !== items.length - 1
            })}
        >
            {items.map((item, i) => {
                return (
                    <div
                        key={i}
                        className={cx(styles.slide, {
                            [styles.active]: i === selectedTemplateIndex
                        })}
                        style={{
                            left,
                            width: slideWidth
                        }}
                        onClick={() => onChange(i)}
                    >
                        {item}
                    </div>
                );
            })}
        </div>
    );
};
