import React from 'react';
import cx from 'classnames';
import Button from './Button';
import styles from "./Button.css";

type PropTypes = {
    className ?: string,
    style ?: Record<string, any>,
    disabled ?: boolean,
    onClick: () => void,
    children ?: any,
    isIntl?: boolean,
    dataTid?: string,
    isLarge?: boolean
}

export default ({ className, children, isLarge, ...rest }: PropTypes) => (
    <Button
        className={cx(
            styles.button,
            { [styles.buttonSecondary]: !isLarge },
            { [styles.buttonSecondaryLg]: isLarge },
            className,
        )}
        {...rest}
    >
        {children}
    </Button>
);

export const SecondaryButtonType = 'SecondaryButton';
