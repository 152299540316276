import React from "react";
import { connect } from "react-redux";

import { Switch } from "../../../Switch";
import { AppState } from "../../../../common/types";
import { isAnalyticsEnabled, selectAnalyticsSettings } from "../../../../common/selectors";
import { analyticsDashboardToggleAnalyticsAction } from "../actions";

type AnalyticsDashboardSwitchProps = {
    enabled: boolean;
    dispatch: Dispatch;
};

const AnalyticsDashboardSwitchComponent = ({ enabled, dispatch }: AnalyticsDashboardSwitchProps) => {
    return (
        <Switch
            checked={enabled}
            handleChange={() => dispatch(analyticsDashboardToggleAnalyticsAction())}
            label="msg: common.enableAnalytics {Enable analytics}"
        />
    );
};

const mapStateToProps = (state: AppState) => ({
    enabled: isAnalyticsEnabled(selectAnalyticsSettings(state))
});

export const AnalyticsDashboardSwitch = connect(mapStateToProps)(AnalyticsDashboardSwitchComponent);
