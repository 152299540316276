export { APP_MOUNTED } from '../../app/actionTypes';
export { PREFERENCES_READY_ACTION, PUT_PREFERENCES_SUCCESS_ACTION } from '../Preferences/actions';

export const
    WHATS_NEW_DIALOG_LEARN_MORE_LINK_CLICKED_ACTION = 'WHATS_NEW_DIALOG_LEARN_MORE_LINK_CLICKED_ACTION',
    OPEN_SEO_SCAN_FROM_WHATS_NEW = 'OPEN_SEO_SCAN_FROM_WHATS_NEW',
    CLOSE_WHATS_NEW_DIALOG_ACTION = 'CLOSE_WHATS_NEW_DIALOG_ACTION';

export const
    whatsNewDialogLearnMoreLinkClickedAction = () => ({ type: WHATS_NEW_DIALOG_LEARN_MORE_LINK_CLICKED_ACTION }),
    closeWhatsNewDialogAction = () => ({ type: CLOSE_WHATS_NEW_DIALOG_ACTION });
