import React from 'react';

import styles from './HoverBoxControls.css';
import { HoverBoxBtnCom } from "./HoverBoxBtnCom";

type Props = {
    onClick: React.MouseEventHandler,
    title: boolean,
    className?: string,
    onMouseLeave?: React.MouseEventHandler;
};

export const HoverBoxDeleteBtnCom = React.forwardRef(
    ({ onClick, title = true, className, onMouseLeave }: Props, ref: React.ForwardedRef<HTMLSpanElement>) => (
        <HoverBoxBtnCom
            ref={ref}
            title={title ? 'msg: common.delete {Delete}' : undefined}
            className={className}
            icoClassName={styles.delete}
            onClick={onClick}
            onMouseLeave={onMouseLeave}
        />
    )
);
