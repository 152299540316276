import type { InputValidationFunction, InputValidationResult } from "../../../../../inputControls/input/flowTypes";
import { makeInputValidationResult } from "../../../../../inputControls/input/commonValidations/utils";

const
    urlValidationRegex = /^[A-Za-z0-9._-]+$/,
    urlCleanerRegex = /[^A-Za-z0-9._-]/g,
    Reason = 'PAGE_URL_CONTAINS_INVALID_CHARACTERS',
    Message = {
        [Reason]: 'msg: validation.urlContainsInvalidCharacters {Sorry, the URL can contain only letters, numbers, hyphens, underscores and periods.}' // eslint-disable-line max-len
    };

const containsInvalidCharactersInURL: InputValidationFunction<null> = (
    value: string
): InputValidationResult => {
    const isValid = value.length ? urlValidationRegex.test(value) : true;

    return makeInputValidationResult(isValid, Reason, Message[Reason]);
};

export { containsInvalidCharactersInURL, urlCleanerRegex };
