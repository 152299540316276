const
    PublishingPagesText = { text: "msg: publish.tip.publishingPages {Publishing pages...}", time: 2000 },
    CheckingSiteMapText = { text: "msg: publish.tip.checkingSiteMap {Checking sitemap...}", time: 1000 };

export const
    PremiumTexts = [
        PublishingPagesText,
        CheckingSiteMapText,
        { text: "msg: publish.tip.injectingHighResolutionImages {Injecting high-resolution images...}", time: 1000 },
        { text: "msg: publish.tip.acceleratingTheWebsite {Accelerating the website...}", time: 2000 }
    ],
    NonPremiumTexts = [
        PublishingPagesText,
        CheckingSiteMapText
    ],
    lastTimeOut = 100;
