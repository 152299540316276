import { makeEpicStateSelector } from '../../../../../epics/makeEpic';
import languagesVAT from '../../../../TopBar/epics/languages/valueActionType';
import { currentLanguageAppSel } from '../../../../TopBar/epics/languages/selectors';
import { generalDataStateSelector } from "../../../../SiteSettings/General/selectors";
import { generalAITextGlobalDataVAT } from './valueActionType';
import { aITextEpic } from "./index";
import { siteSettingsValueActionType } from '../../../../App/epics/siteSettings/valueActionType';

const aITextEpicSelector = makeEpicStateSelector(aITextEpic.valueActionType);
const languageEpicSelector = makeEpicStateSelector(languagesVAT);
const siteSettingsStateSelector = makeEpicStateSelector(siteSettingsValueActionType);

export const
    generalAITextGlobalDataSelector = (state) => makeEpicStateSelector(generalAITextGlobalDataVAT)(state),
    aiTextSelector = (state) => aITextEpicSelector(state),
    selectContentType = (state) => aiTextSelector(state).contentType,
    selectTopic = (state) => aiTextSelector(state).topic,
    selectTopicType = (state) => aiTextSelector(state).topicType,
    selectExtras = (state) => aiTextSelector(state).extras,
    selectErrorFields = (state) => aiTextSelector(state).errorFields,
    selectIsFirstTimeUser = (state) => aiTextSelector(state).isFirstTimeUser,

    selectLanguages = (state) => languageEpicSelector(state),
    selectLanguage = (state) => currentLanguageAppSel(state),

    selectWebsiteCategories = (state) => generalDataStateSelector(state).gmbCategories || generalDataStateSelector(state).websiteCategories,
    selectWebsiteTitle = (state) => generalDataStateSelector(state).websiteTitle,

    selectGeneralDataSiteSettings = (state) => siteSettingsStateSelector(state).current.generalData,
    selectAiTextDataSiteSettings = (state) => siteSettingsStateSelector(state).current.textAIData;
