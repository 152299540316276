import * as R from 'ramda';
import { UPDATE_WRAP_REQUEST } from './actionTypes';

const extract = (keys, obj) => keys.reduce((a, key) => ({ ...a, [key]: obj[key] }), {});
export const wrapComponentReducer = (component, { type, payload }) => {
    if (type === UPDATE_WRAP_REQUEST) {
        const change = payload.wrappedComponentsUpdateInfo && payload.wrappedComponentsUpdateInfo[component.id];
        if (change && !R.equals(extract(Object.keys(change), component), change)) {
            return { ...component, ...change };
        }
    }

    return component;
};
