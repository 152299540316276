export const
    UPDATE_COMPONENT_LAYOUT = 'UPDATE_COMPONENT_LAYOUT',
    UPDATE_COMPONENT_REVIEWS = 'UPDATE_COMPONENT_REVIEWS',
    CHANGE_REVIEWS_HEADER_TEXT = 'CHANGE_REVIEWS_HEADER_TEXT',
    TOGGLE_LEAVE_REVIEW_BUTTON = 'TOGGLE_LEAVE_REVIEW_BUTTON',
    CHANGE_REVIEW_BUTTON_TEXT = 'CHANGE_REVIEW_BUTTON_TEXT',
    CHANGE_REVIEW_BUTTON_THEME = 'CHANGE_REVIEW_BUTTON_THEME',
    CHANGE_REVIEWS_LINK_TEXT = 'CHANGE_REVIEWS_LINK_TEXT',
    CHANGE_REVIEW_BUTTON_GLOBAL_STYLE = 'CHANGE_REVIEW_BUTTON_GLOBAL_STYLE',
    NO_REVIEWS = 'NO_REVIEWS',
    LISTING_NOT_VERIFIED = 'LISTING_NOT_VERIFIED';
