import { ELEMENT_IGNORED_PROPS, BREAKING_STYLE_TYPES, BREAKING_STYLES, UNNESTABLE_STYLES } from './constants';
import { overridenProperties, sortElements } from "../helpers/index";
import { intersect, difference, except, include } from '../helpers/setOperations';
import type { Style, Element } from '../../flowTypes';

const hasBreakingStyle = (element: Element) =>
    BREAKING_STYLES.some(key => element.hasOwnProperty(key));
const hasUnnestableStyle = (element: Style) =>
    UNNESTABLE_STYLES.some(key => element.hasOwnProperty(key));
const isBreakingStyleType = ({ atype }) =>
    BREAKING_STYLE_TYPES.indexOf(atype) >= 0;

const excludeIgnoredProps = except(ELEMENT_IGNORED_PROPS);
const includeIgnoredProps = include(ELEMENT_IGNORED_PROPS);

// todo refactor
export default (args: Element[]): Array<Element> => {
    const result: Element[] = [];

    // its pure function so create copy of elements to prevent modification of input args;
    const elements = args.map(arg => { return { ...arg }; });

    for (let i = 0; i < elements.length; i++) {
        // ignore properties which don't affect element length
        let element = excludeIgnoredProps(elements[i]);
        // @ts-ignore
        const elementHasBreakingStyle = hasBreakingStyle(element);
        // @ts-ignore
        const elementIsBreakingStyleType = isBreakingStyleType(elements[i]);

        let j;
        for (j = i + 1; j < elements.length; j++) {
            // This is here just to increment the j on first iteration :D
            if (elementIsBreakingStyleType || elementHasBreakingStyle || !Object.keys(element).length) {
                break;
            }

            // If current element end does not match next element start, they don't have anything in common, so close element
            if (elements[i].end < elements[j].start) {
                break;
            }

            // if contaning element ends, close element
            if (elements[j - 1] && elements[j - 1].stop) {
                break;
            }

            // if no common properties close the element
            const commonProps = intersect(element, elements[j]);
            if (!Object.keys(commonProps).length) {
                break;
            }

            const overriddenPropsFromCurrent = overridenProperties(element, elements[j]);
            const usedPropsFromCurrent = { ...commonProps, ...overriddenPropsFromCurrent } as Style;
            const unusedPropsFromCurrent = difference(element, usedPropsFromCurrent) as Style;

            // If element and nextElement have different unnestable styles close the element
            const uncommonPropsFromNext = excludeIgnoredProps(difference(elements[j], element)) as Style;
            if (hasUnnestableStyle(usedPropsFromCurrent) && (
                hasUnnestableStyle(unusedPropsFromCurrent) ||
                hasUnnestableStyle(uncommonPropsFromNext)
            )) {
                break;
            }

            // We have decided to nest so now we can remove properties which were already used
            elements[j] = except(Object.keys(commonProps))(elements[j]) as Element;
            // Move next element inside current element (PS: assuming that the case elements[j].start < elements[i].end should never happen)
            elements[i].end = elements[j].end;

            // If there are properties in element that are not there in nextElement create a new child
            if (elements[i].start < elements[j].start) {
                if (Object.keys(unusedPropsFromCurrent).length) {
                    result.push({
                        ...unusedPropsFromCurrent,
                        ...includeIgnoredProps(elements[i]),
                        end: elements[j].start
                    });
                }

                element = usedPropsFromCurrent;
            }
        }

        // end of element means child elements must ends also at this point
        elements[j - 1].stop = true;

        // if element is not empty add it to result
        if (Object.keys(element).length || elementIsBreakingStyleType) {
            result.push({
                ...element,
                ...includeIgnoredProps(elements[i]) as Element,
            });
        }
    }

    return sortElements(result);
};
