import memo from '../../../../utils/memo';

export default memo(
    (templateWidth: number, leftPanelWidth: number, browserWidth: number): number => {
        const
            workspaceWidth = browserWidth - leftPanelWidth,
            templateOffsetWithRefToWorkspace = (workspaceWidth - templateWidth) / 2;

        return Math.round(leftPanelWidth + templateOffsetWithRefToWorkspace);
    }
);
