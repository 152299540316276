/* eslint-disable max-len */
export const
    VIDEO_ADD = "msg: component.video.addVideo {Add video}",
    VIDEO_REPLACE = "msg: component.video.replaceVide {Replace video}",
    VIDEO_SETTINGS = "msg: component.video.videoSettins {Video settings}",
    VIDEO_AUTO_RESIZE = "msg: component.video.tb.autoResize {Auto-resize}",
    VIDEO_MUTE = "msg: component.video.tb.mute {Mute}",
    VIDEO_LOOP = "msg: component.video.tb.loop {Loop}",
    VIDEO_REPEAT = "msg: component.video.tb.repeat {Repeat}",
    VIDEO_AUTOPLAY = "msg: component.video.tb.autoplay {Autoplay}",
    VIDEO_SHOW_CONTROLS = "msg: component.video.tb.showControls {Show controls}",
    VIDEO_HIDE_CONTROLS = "msg: component.video.tb.hideControls {Hide controls}",
    VIDEO_WIDGET_HELP_TIP = "msg: videoInserter.HelpText {Videos keep people on your website longer and increase engagement with your content.}",
    VIDEO_WIDGET_DISABLED_TIP = "msg: demo.disabledVideoText {The video component is not available in the trial version.}";
