import React, { useState, useRef, useLayoutEffect } from 'react';
import { getImageSrc } from "../../../../../../wbtgen/src/components/oneweb/Image/utils";

import { useStyles } from "./styles";
import imageStyles from "../../Images/imageStyle.css";

import type { SocialShareImagePreviewComponentProps } from "./types";

const ImageHover = ({ onRemove }) => {
    const classes = useStyles();
    const [hover, setHover] = useState(false);

    return (
        <div
            className={classes.imageHoverContainer}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {
                hover &&
                <span
                    className={classes.imageCloseIcon}
                    onClick={(e) => onRemove(e)}
                >
                    <span className={imageStyles.solidCancelIcon} />
                </span>
            }
        </div>
    );
};

export const ImagePreview = (props: SocialShareImagePreviewComponentProps): JSX.Element | null => {
    const classes = useStyles();
    const { asset, onChange, onRemove, intl } = props;
    const [imageSrc, setImageSrc] = useState<string | null>(null);

    const previewContainerRef = useRef<HTMLDivElement>(null);

    const onAssetRemove = (e) => {
        setImageSrc(null);
        onRemove(e);
    };

    useLayoutEffect(() => {
        if (asset && previewContainerRef && previewContainerRef.current) {
            let prewiewWidth = asset.width;
            if (asset.width > previewContainerRef.current.offsetWidth) {
                prewiewWidth = Math.floor(asset.width / (Math.floor(asset.width / previewContainerRef.current.offsetWidth)));
            }
            const previewHeight = Math.floor(asset.height * (prewiewWidth / asset.width));
            const { src } = asset ? getImageSrc(asset, prewiewWidth, previewHeight) : { src: null };
            setImageSrc(src);
        } else if (asset === null) {
            setImageSrc(null);
        }
    }, [asset]);

    return (
        <React.Fragment>
            <div className={classes.imagePreviewLabel}>{intl.msgJoint('msg: socialShare.imagePreview.label {Image preview}')}</div>
            <div
                className={imageSrc ? classes.imageWrapperSelected : classes.imageWrapper}
                onClick={() => onChange()}
                ref={previewContainerRef}
                data-testid="image-preview-container"
            >
                {
                    imageSrc
                        ? (
                            <div className={classes.imagePreviewContainer}>
                                <ImageHover onRemove={onAssetRemove} />
                                <img src={imageSrc} className={classes.image} />
                            </div>
                        ) : (
                            <div className={classes.placeholderPreviewContainer}>
                                <div className={imageStyles.imagePreviewIcon} />
                                <div>{intl.msgJoint("msg: socialShare.imageChooser.placeholder {Choose an image}")}</div>
                            </div>
                        )
                }
            </div>
        </React.Fragment>
    );
};

