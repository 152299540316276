import type { AnyComponent, ComponentsMap, BBox } from "../../flowTypes";
import { getMaxOrderIndex, getMinOrderIndex } from "./index";
import {
    doBBoxTouch,
    getComponentsBBox
} from "../../../../../../utils/componentsMap/index";
import {
    filterLayeringComponents,
    getLayeringMinOrderIndex
} from "../../../../../../components/ContextMenu/view/utils";
import {
    getSiblingAttachedComponentsMap,
    getAllAttachmentsCmpsMapForCmpIds,
    getAttachedContainerCmpId
} from "../../../../../../components/Workspace/epics/componentAttachements/util";
import type { Attachments } from "../../../../../../components/Workspace/epics/componentAttachements/flowTypes";

export function moveComponentsToTop(
    componentsMap: ComponentsMap,
    componentsIds: Array<string>,
    attachedCmpIds: Array<string>,
    pageMode: boolean,
    workspaceBBox: BBox,
    attachments: any,
): ComponentsMap {
    let orderIndex = getMaxOrderIndex(componentsMap);
    componentsIds.forEach(cmpId => {
        const siblingCmps = getSiblingAttachedComponentsMap(cmpId, attachments, componentsMap);
        if (siblingCmps) {
            const cmpsAbove = getComponentsAbove(siblingCmps, [cmpId], false, workspaceBBox, pageMode),
                newIndex = getMaxOrderIndex(cmpsAbove.reduce((acc, cmp) => {
                    acc[cmp.id] = cmp;
                    return acc;
                }, {}));
            orderIndex = orderIndex > newIndex ? newIndex : orderIndex;
        }
    });
    return applyOrderIndex(componentsMap, componentsIds, attachedCmpIds, orderIndex + 1);
}

export function moveComponentsUp(
    componentsMap: ComponentsMap,
    componentsIds: Array<string>,
    attachedCmpIds: Array<string>,
    pageMode: boolean,
    workspaceBBox: BBox,
    attachments: Attachments, // eslint-disable-line no-unused-vars
): ComponentsMap {
    const
        componentsAbove = getComponentsAbove(componentsMap,
            [...componentsIds, ...attachedCmpIds], false, workspaceBBox, pageMode);
    let orderIndex = 0;
    if (componentsAbove.length) {
        const attachedCmpsMapOfCmpAbove =
            getAllAttachmentsCmpsMapForCmpIds(componentsMap, attachments, [componentsAbove[0].id]);
        orderIndex = attachedCmpsMapOfCmpAbove ?
            (getMaxOrderIndex(attachedCmpsMapOfCmpAbove) + 1) :
            (componentsAbove[0].orderIndex + 1);
    } else {
        orderIndex = getMaxOrderIndex(componentsMap);
    }
    return applyOrderIndex(componentsMap, componentsIds, attachedCmpIds, orderIndex);
}

export function moveComponentsDown(
    componentsMap: ComponentsMap,
    componentsIds: Array<string>,
    attachedCmpIds: Array<string>,
    pageMode: boolean,
    workspaceBBox: BBox,
    attachments: Attachments, // eslint-disable-line no-unused-vars
): ComponentsMap {
    const
        componentsBelow = getComponentsBelow(componentsMap,
            [...componentsIds, ...attachedCmpIds], false, workspaceBBox, pageMode);
    let orderIndex = 0;
    if (componentsBelow.length) {
        const belowCmp = componentsBelow.pop();
        const selectedContainerId = getAttachedContainerCmpId(componentsIds[0], attachments);
        let belowCmpContainerId = getAttachedContainerCmpId(belowCmp.id, attachments);
        if (!belowCmpContainerId || selectedContainerId === belowCmpContainerId) {
            orderIndex = belowCmp.orderIndex;
        } else {
            while (belowCmpContainerId && belowCmpContainerId !== selectedContainerId) {
                orderIndex = componentsMap[belowCmpContainerId].orderIndex;
                belowCmpContainerId = getAttachedContainerCmpId(belowCmpContainerId, attachments);
            }
        }
    } else {
        orderIndex = getLayeringMinOrderIndex(componentsMap, componentsIds, pageMode);
    }

    return applyOrderIndex(componentsMap, componentsIds, attachedCmpIds, orderIndex);
}

export function moveComponentsToBottom(
    componentsMap: ComponentsMap,
    componentsIds: Array<string>,
    attachedCmpIds: Array<string>,
    pageMode: boolean,
    workspaceBBox: BBox,
    attachments: Attachments,
): ComponentsMap {
    let orderIndex = 0;
    componentsIds.forEach(cmpId => {
        const siblingCmps = getSiblingAttachedComponentsMap(cmpId, attachments, componentsMap);
        if (siblingCmps) {
            const cmpsBelow = getComponentsBelow(siblingCmps, [cmpId], false, workspaceBBox, pageMode),
                newIndex = getMinOrderIndex(cmpsBelow.reduce((acc, cmp) => {
                    acc[cmp.id] = cmp;
                    return acc;
                }, {}));
            orderIndex = orderIndex > newIndex ? orderIndex : newIndex;
        }
    });
    return applyOrderIndex(componentsMap, componentsIds, attachedCmpIds, orderIndex);
}

export function getComponentsAbove(
    componentsMap: ComponentsMap,
    componentsIds: Array<string>,
    all: boolean,
    workspaceBBox: BBox,
    pageMode: boolean
): Array<AnyComponent> {
    return getComponentsInDirection(componentsMap, componentsIds, all, 1, workspaceBBox, pageMode);
}

export function getComponentsBelow(
    componentsMap: ComponentsMap,
    componentsIds: Array<string>,
    all: boolean,
    workspaceBBox: BBox,
    pageMode: boolean
): Array<AnyComponent> {
    return getComponentsInDirection(componentsMap, componentsIds, all, -1, workspaceBBox, pageMode);
}

function getComponentsInDirection(
    componentsMap: ComponentsMap,
    componentsIds: Array<string>,
    all: boolean,
    direction: number,
    workspaceBBox: BBox,
    isPageMode: boolean
): Array<AnyComponent> {
    const
        selectedComponentsBBox = getComponentsBBox(
            componentsIds.map(
                (componentId: string): AnyComponent => componentsMap[componentId]
            ),
            workspaceBBox
        ),
        lowestSelectedComponentId = componentsIds.reduce(
            (prev: string, next: string): string =>
                (componentsMap[prev].orderIndex < componentsMap[next].orderIndex ? prev : next)
        ),
        lowestSelectedComponent = componentsMap[lowestSelectedComponentId];

    const directionComponents = filterLayeringComponents(
        componentsMap,
        componentsIds,
        isPageMode,
        (component: AnyComponent, componentId: string) => {
            const componentBBox = getComponentsBBox([component], workspaceBBox);
            if (compareOrderIndex(lowestSelectedComponent, component, direction)
                && componentsIds.indexOf(componentId) === -1
            ) {
                if (all || doBBoxTouch(componentBBox, selectedComponentsBBox)) {
                    return true;
                }
            }
            return false;
        }
    );

    return Object.keys(directionComponents)
        .map(k => directionComponents[k])
        .sort(
            (componentA: AnyComponent, componentB: AnyComponent) => (componentA.orderIndex - componentB.orderIndex)
        );
}

function compareOrderIndex(compA: AnyComponent, compB: AnyComponent, direction: number): boolean {
    if (direction === 1) {
        return compA.orderIndex < compB.orderIndex;
    } else if (direction === -1) {
        return compA.orderIndex > compB.orderIndex;
    }
    return compA.orderIndex === compB.orderIndex;
}

function applyOrderIndex(
    componentsMap: ComponentsMap,
    selectedComponentsIds: Array<string>,
    attachedCmpIds: Array<string>,
    newOrderIndex: number
): ComponentsMap {
    let
        orderedCmpIds = [...selectedComponentsIds, ...attachedCmpIds],
        newComponentsMap = {},
        tempComponentsMap = Object.keys(componentsMap).map((componentId: string) => {
            const
                component = componentsMap[componentId],
                { orderIndex } = component;

            return {
                componentId,
                orderIndex,
                component,
                oldOrderIndex: orderIndex
            };
        }),
        tempComponentsMapCopy: Record<string, any>[] = [],
        orderIndexForSelected = newOrderIndex,
        orderIndexForRest = newOrderIndex + orderedCmpIds.length;

    tempComponentsMap.sort((componentA: Record<string, any>, componentB: Record<string, any>): number => {
        return componentA.orderIndex - componentB.orderIndex;
    });

    tempComponentsMap.forEach((tempComponent: Record<string, any>) => {
        let component = tempComponent;

        const inSelectedComponents = (orderedCmpIds.indexOf(tempComponent.componentId) > -1);

        if (inSelectedComponents) {
            component = {
                ...tempComponent,
                orderIndex: orderIndexForSelected
            };
            orderIndexForSelected++;
        } else if (tempComponent.oldOrderIndex >= newOrderIndex) {
            component = {
                ...tempComponent,
                orderIndex: orderIndexForRest
            };
            orderIndexForRest++;
        }

        tempComponentsMapCopy.push(component);
    });

    tempComponentsMapCopy.sort((tempComponentA: Record<string, any>, tempComponentB: Record<string, any>): number => {
        return tempComponentA.orderIndex - tempComponentB.orderIndex;
    });

    tempComponentsMapCopy.forEach((tempComponent: Record<string, any>, idx: number): void => {
        const
            { componentId } = tempComponent,
            component = componentsMap[componentId];

        newComponentsMap[componentId] = {
            ...component,
            orderIndex: (idx + 1)
        };
    });

    return newComponentsMap;
}

