import * as colorMapper from './color';
import type { Shadow } from '../components/Workspace/epics/stylesheets/flowTypes';

export const toCss = (shadow: Shadow) => {
    if (shadow && shadow.color) {
        let { left, top, blur, color } = shadow;

        // @ts-ignore
        top = parseInt(top, 10) || 0;
        // @ts-ignore
        left = parseInt(left, 10) || 0;
        // @ts-ignore
        blur = parseInt(blur, 10) || 0;

        return [(left + 'px'), (top + 'px'), (blur + 'px'), colorMapper.toCss(color)].join(' ');
    }

    return null;
};
