import React from 'react';
import { Flex } from '../../../view/reflexbox/index';
import ColorProperty from '../ColorProperty/index';
import VerticalSpacer from '../../../view/common/VerticalSpacer';
import PropertyContainer from '../../../view/common/PropertyContainer/index';
import Slider from '../../../view/common/Slider/PPSlider';
import addGoogleFontView from '../../PropertiesPanel/view/AddGoogleFont/index';
import FontFamilySelector from "../FontFamily/index";
import type { Props } from './flowTypes';
import { MinFontSize, MaxFontSize, LINE_HEIGHT_PERCENTAGE } from "./constants";
import Gallery from '../../oneweb/Gallery/kind';
import { getCaptionFontStyleSettings } from "../../oneweb/Gallery/utils";
import TextFormatButtonGroup from "../IconButtonsGroup/TextFormat";
import { DEFAULT_COLOR_PICKER, THEME_COLOR_PICKER } from '../../ColorPicker/constants';

const
    CaptionStyleSettings = (props: Props) => {
        const {
            dispatch,
            themeColor,
            defaultThemeColor,
            themeColorsData,
            siteFonts,
            navigateToPage,
            fontFamilyChangeAction,
            fontColorChangeAction,
            fontSizeChangeAction,
            toggleBoldAction,
            toggleItalicAction,
            toggleUnderlineAction,
            lineHeightChangeAction,
            styleSheet,
            defaultStyleSheet
        } = props;
        const
            { font, size, color, bold, italic, underline, lineHeight = LINE_HEIGHT_PERCENTAGE } =
                getCaptionFontStyleSettings(styleSheet, defaultStyleSheet);
        return (
            <div>
                <VerticalSpacer />
                <PropertyContainer label="msg: common.font {Font}">
                    <FontFamilySelector
                        selected={font}
                        onChangeAction={fontFamilyChangeAction}
                        siteFonts={siteFonts}
                        onAddFontClicked={() => navigateToPage(addGoogleFontView.id, {
                            source: Gallery,
                            actionToDispatch: fontFamilyChangeAction
                        })}
                        isPropertiesPanel
                    />
                </PropertyContainer>
                <VerticalSpacer />
                <Flex align="center">
                    <TextFormatButtonGroup
                        bold={{
                            isSelected: bold,
                            onClickAction: toggleBoldAction,
                        }}
                        italic={{
                            isSelected: italic,
                            onClickAction: toggleItalicAction,
                        }}
                        underline={{
                            isSelected: underline,
                            onClickAction: toggleUnderlineAction,
                        }}
                        dispatch={dispatch}
                    />
                </Flex>
                <VerticalSpacer />
                {
                    props.autoColorMode
                        ? <ColorProperty
                            colorPickerKind={THEME_COLOR_PICKER}
                            themeColor={themeColor}
                            defaultThemeColor={defaultThemeColor}
                            themeColorsData={themeColorsData}
                            label="msg: component.contactForm.appearance.fontColor {Text colour}"
                            dispatch={dispatch}
                            onChangeAction={fontColorChangeAction}
                        />
                        : <ColorProperty
                            colorPickerKind={DEFAULT_COLOR_PICKER}
                            label="msg: component.contactForm.appearance.fontColor {Text colour}"
                            dispatch={dispatch}
                            onChangeAction={fontColorChangeAction}
                            color={color}
                        />
                }
                <VerticalSpacer />
                <PropertyContainer label="msg: common.fontSize {Font size}" valueLabel={`${size} px`}>
                    <Slider
                        defaultValue={size}
                        value={size}
                        min={MinFontSize}
                        max={MaxFontSize}
                        onChange={newSize => dispatch({
                            type: fontSizeChangeAction,
                            payload: newSize,
                            amendToSelf: true
                        })}
                    />
                </PropertyContainer>
                <VerticalSpacer />
                <Slider
                    label="msg: text.pp.lineSpacing {Line spacing:}"
                    valueLabel={`${lineHeight}`}
                    value={lineHeight}
                    min={1}
                    max={2.4}
                    step={0.1}
                    onChange={spacing => dispatch({
                        type: lineHeightChangeAction,
                        payload: spacing,
                        amendToSelf: true
                    })}
                />
            </div>
        );
    };

export { CaptionStyleSettings };
