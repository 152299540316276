import { include } from '../../helpers/setOperations';
import type { Style, Element } from '../../../flowTypes';
import { LIST_STYLES } from '../../constants';
import { executeOnStartMatch } from './executeOnMatch';

export default (styles: Array<Style>, listItems: Array<Style>): Array<Element> => {
    const includeListStyles = include(LIST_STYLES);
    return executeOnStartMatch(
        listItems, styles,
        // @ts-ignore
        (listItem, style) => ({ ...listItem, ...includeListStyles(style) })
    );
};
