import {
    WIDGETS_BINGMAPS_SHOW_UPDATE_LINK_DIALOG,
} from './actionTypes';
import { ctaOnClickFactory, linkExtractor } from '../utils';

const componentMainActions = {
    editButtonText: 'msg: common.settings {Settings}',
    ctaButtonText: 'msg: widgets.googlemaps.cta.changeMap {Change map}',
    ctaOnClick: ctaOnClickFactory(
        WIDGETS_BINGMAPS_SHOW_UPDATE_LINK_DIALOG,
        component => ({ link: linkExtractor(component) })
    ),
};

export { componentMainActions };

