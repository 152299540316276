import * as R from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import { memoMaxOne } from "../../../../../utils/memo";
import type {
    BBox, ComponentsMap
} from "../../../../redux/modules/children/workspace/flowTypes";
import valueActionType from './valueActionType';
import { UserInteractionSelector } from "../componentsEval/selectorActionTypes";
import { MOUSE_DOWN_ON_HANDLE, SHIFTBAR_MOVING } from "../componentsEval/userInteractionMutations/interactionModes";
import { isShiftBarTop, isShiftBarBottom } from "../../../../utils/handle/index";
import { receiveOnly } from "../../../../epics/makeCondition";
import { isSectionId } from "../../../oneweb/Section/utils";
import { getNonGhostCmps } from "../componentAttachements/util";
import type { AnyComponent } from "../../../oneweb/flowTypes";
import { ComponentsEvalValueActionType } from "../componentsEval/valueActionType";
import { getComponentsMap } from "../componentsEval/getters";
import { isStickyToHeader } from "../componentsEval/isStickyToHeader";
import { isModernLayoutSection } from "../../../ModernLayouts/preview_utils";

export type ShiftBarDecorationsState = {
    bBox: BBox,
    isVisible: boolean
};

const
    defaultState = {
        bBox: { left: 0, top: 0, right: 0, bottom: 0 },
        isVisible: false
    };

const getClosestComponentBottomFromTop = memoMaxOne((from, componentsMap, filter: Array<string> = []) => {
    const noGhostComponents = getNonGhostCmps(componentsMap).filter(cmp => !isStickyToHeader(cmp));
    return noGhostComponents.reduce((closestTop: number, cmp: AnyComponent) => {
        const { top, height, id } = cmp,
            bottom = top + height;
        return !filter.includes(id) && height > 0 && bottom <= from ?
            Math.max(closestTop, bottom) :
            closestTop;
    }, 0);
});

const getTopShiftBarDecorationState = (userInteraction: any) => {
    const { startPosition, newMousePosition, shiftBar: { maxDistance, initialShiftBar } } = userInteraction;
    const
        moveDiffY = newMousePosition ? startPosition.y - newMousePosition.y : 0,
        bBox: BBox = {
            left: initialShiftBar.left,
            top: initialShiftBar.top + maxDistance,
            right: initialShiftBar.right,
            bottom: initialShiftBar.top - Math.min(moveDiffY, -maxDistance)
        };
    return {
        state: {
            bBox,
            isVisible: bBox.bottom !== bBox.top
        }
    };
};
const getBottomShiftBarDecorationState = (userInteraction: any, componentsMap: ComponentsMap) => {
    const {
            topMostComponentId,
            shiftBar: {
                initialShiftBar: { left, right }
            }
        } = userInteraction,
        section = componentsMap[topMostComponentId],
        { top, height, id } = section,
        bottom = top + height,
        maxTop = getClosestComponentBottomFromTop(bottom, componentsMap, [id]);

    if (maxTop > top && bottom > maxTop) {
        return {
            state: {
                bBox: { left, right, bottom, top: maxTop },
                isVisible: bottom !== top
            }
        };
    }
    return {
        state: defaultState
    };
};

const epic = makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                UserInteractionSelector,
                receiveOnly(ComponentsEvalValueActionType)
            ],
            reducer: ({ values: [{ mode, payload }, cmpEvalState], state }) => {
                if ((mode === MOUSE_DOWN_ON_HANDLE || mode === SHIFTBAR_MOVING)) {
                    const { handleKind, topMostComponentId } = payload,
                        componentsMap = getComponentsMap(cmpEvalState),
                        component = componentsMap[topMostComponentId];
                    if (isModernLayoutSection(component)) {
                        return { state: R.assoc('isVisible', false, state) };
                    }
                    if (isShiftBarTop(handleKind)) {
                        return getTopShiftBarDecorationState(payload);
                    } else if (isSectionId(topMostComponentId, componentsMap) && isShiftBarBottom(handleKind)) {
                        return getBottomShiftBarDecorationState(payload, componentsMap);
                    }
                }

                if (state.isVisible) {
                    return { state: R.assoc('isVisible', false, state) };
                }

                return { state };
            }
        }
    ]
});

export default epic;
