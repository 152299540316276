import React from "react";

import { Msg } from "../../../view/intl/index";

import styles from './TemplateSelector.css';

export const NoMatchModal = ({ searchText }) => {
    return (
        <div className={styles.noMatchContainer}>
            <div className={styles.icon} style={{ width: 144, height: 144, position: 'relative' }} />
            <div className={styles.textContainer}>
                <span className={styles.noMatchText}>
                    <Msg
                        k="templateSelector.noSearchResult.title"
                        params={{ value: searchText }}
                    >
                        {`No results found for \"{value}\"`}
                    </Msg>
                </span>
                <span>
                    <Msg k="templateSelector.noSearchResult.subTitle1">Try searching for a different word.</Msg>
                    <br />
                    <Msg k="templateSelector.noSearchResult.subTitle2">
                        Or choose a template from our categories and edit it to fit your needs.
                    </Msg>
                </span>
            </div>
        </div>
    );
};
