export const
    PageInfoTab = {
        NAMES: 'names' as const,
        SEO: 'seo' as const,
        SOCIAL_SHARE: 'socialShare' as const,
        VISIBILITY: 'visibility' as const,
        TEMPLATE: 'template' as const,
    },
    PAGE_DEFAULT_EXTENSION = 'html',
    PAGE_TYPES = {
        PAGE: 'page',
        SECTION_LINK: 'section_link',
        LINK: 'link',
        HOME: 'home',
    },
    PageOptionsTitles = {
        [PAGE_TYPES.HOME]: 'msg: pt.options.home {This page is your start page}',
        [PAGE_TYPES.PAGE]: 'msg: pt.options.page {This is a page in your menu}',
        locked: 'msg: pt.options.locked {This page will not be published}',
        hidden: 'msg: pt.options.hidden {This page is hidden in menus}',
        [PAGE_TYPES.LINK]: 'msg: pt.options.link {This is a link shown in menus}',
        link_locked: 'msg: pt.options.linkLocked {This link will not be published}',
        [PAGE_TYPES.SECTION_LINK]: 'msg: pt.options.sectionLink {This is a section link in your menu}',
        section_link_hidden: 'msg: pt.options.hiddenSectionLink {This section link is hidden in menus}'
    },
    sectionLinkDialogInfoTip = 'msg:sectionLinkInfoDialog.tipText {Name your sections so you can easily tell them from each other. Click on \"Section title\" in the settings for the section you want to rename.}',  // eslint-disable-line max-len
    PAGES_TREE_DISABLED_PAGE = 'msg: tooltip.pagesTreeDisabledPage {This page belongs to a different colour theme}';
