import { inactive, hover } from '../../../mappers/path';
import * as actionTypes from './actionTypes';
import * as euh from './epicUpdateHelpers';

export default [
    euh.getTextShadowColorChangeEpicUpdater(actionTypes.GS_LINK_INACTIVE_SHADOW_COLOR_CHANGED, inactive),
    euh.getTextShadowRemvoveEpicUpdater(actionTypes.GS_LINK_INACTIVE_SHADOW_REMOVED, inactive),
    euh.getTextShadowBlurRadiusChangeEpicUpdater(actionTypes.GS_LINK_INACTIVE_SHADOW_BLUR_RADIUS_CHANGED, inactive),
    euh.getTextShadowHorizontalOffsetChangeEpicUpdater(
        actionTypes.GS_LINK_INACTIVE_SHADOW_HORIZONTAL_OFFSET_CHANGED,
        inactive
    ),
    euh.getTextShadowVerticalOffsetChangeEpicUpdater(
        actionTypes.GS_LINK_INACTIVE_SHADOW_VERTICAL_OFFSET_CHANGED,
        inactive
    ),
    euh.getToggleUnderlineEpicUpdater(actionTypes.GS_LINK_INACTIVE_TOGGLE_UNDERLINE, inactive),
    euh.getTextColorChangeEpicUpdater(actionTypes.GS_LINK_INACTIVE_TEXT_COLOR_CHANGED, inactive),
    euh.getTextColorRemoveEpicUpdater(actionTypes.GS_LINK_INACTIVE_TEXT_COLOR_REMOVED, inactive),
    euh.getTextHighlightColorChangeEpicUpdater(actionTypes.GS_LINK_INACTIVE_HIGHLIGHT_COLOR_CHANGED, inactive),
    euh.getTextHighlightColorRemoveEpicUpdater(actionTypes.GS_LINK_INACTIVE_HIGHLIGHT_COLOR_REMOVED, inactive),

    euh.getTextShadowColorChangeEpicUpdater(actionTypes.GS_LINK_HOVER_SHADOW_COLOR_CHANGED, hover),
    euh.getTextShadowRemvoveEpicUpdater(actionTypes.GS_LINK_HOVER_SHADOW_REMOVED, hover),
    euh.getTextShadowBlurRadiusChangeEpicUpdater(actionTypes.GS_LINK_HOVER_SHADOW_BLUR_RADIUS_CHANGED, hover),
    euh.getTextShadowHorizontalOffsetChangeEpicUpdater(
        actionTypes.GS_LINK_HOVER_SHADOW_HORIZONTAL_OFFSET_CHANGED,
        hover
    ),
    euh.getTextShadowVerticalOffsetChangeEpicUpdater(actionTypes.GS_LINK_HOVER_SHADOW_VERTICAL_OFFSET_CHANGED, hover),
    euh.getToggleUnderlineEpicUpdater(actionTypes.GS_LINK_HOVER_TOGGLE_UNDERLINE, hover),
    euh.getTextColorChangeEpicUpdater(actionTypes.GS_LINK_HOVER_TEXT_COLOR_CHANGED, hover),
    euh.getTextColorRemoveEpicUpdater(actionTypes.GS_LINK_HOVER_TEXT_COLOR_REMOVED, hover),
    euh.getTextHighlightColorChangeEpicUpdater(actionTypes.GS_LINK_HOVER_HIGHLIGHT_COLOR_CHANGED, hover),
    euh.getTextHighlightColorRemoveEpicUpdater(actionTypes.GS_LINK_HOVER_HIGHLIGHT_COLOR_REMOVED, hover)
];
