import type { AppStateSel } from "../../../src/globalTypes";
import { pathSelector } from "../../../src/utils/pathSelector";
import { Lit } from "../../../src/lit";
import type { PathSelectorPath } from "../../../src/utils/pathSelector";
import { TrialImportAvailabilityStateT } from "./flowTypes";

const baseSel = (path: PathSelectorPath) => pathSelector([Lit.demo, Lit.trialImportAvailability, ...path]);

export const
    trialImportAppSel: AppStateSel<TrialImportAvailabilityStateT> = baseSel([Lit.trialImportAvailabilityData]);
export const
    trialImportAvailableSel: AppStateSel<boolean> = baseSel([Lit.trialImportAvailabilityData, Lit.trialImportAvailable]);
export const
    trialImportedSel: AppStateSel<boolean> = baseSel([Lit.trialImportAvailabilityData, Lit.trialImported]);
export const
    trialImportAvailabilityScreenShotSel: AppStateSel<boolean> = baseSel(
        [Lit.trialImportAvailabilityData, Lit.trialImportAvailabilityScreenShot]
    );
