import * as React from "react";
import { pure } from 'recompose';
import {
    MctaVerticalBar,
    MctaPropertyContainer,
    MctaCheckbox,
    MctaCombobox
} from '../../../componentMainActions/index';
import HorizontalSpacer from '../../../../view/common/HorizontalSpacer';
import * as actionTypes from '../actionTypes';
import { transitionOptions } from '../propertiesPanel/constants';
import type { CTAViewParams } from '../../../../view/Workspace/Decorations/ComponentMainActions/flowTypes';
import type { ImageSliderComponent } from '../flowTypes';
import styles from '../view/ImageSlider.css';
import injectIntl from '../../../../view/intl/injectIntl';
import { IMAGE_SLIDER_PP_FULL_WIDTH } from "../actionTypes";
import { propPanelNavigateToPageAC } from "../../../PropertiesPanel/actionCreators";
import { FULL_WIDTH } from "../propertiesPanel/pageIds";

const
    maxSlideTime = 15;

const EditView = pure(injectIntl(({ selectedComponent: { transition, crop, autoNext, delay, stretch }, dispatch, intl, children }) => {
    const slideTimeOptions = Array(maxSlideTime).fill(1).map((v, i) => v + i)
        .map(v => ({ label: intl.msgJoint(['msg: slider.timeOptions {{sec, number} sec}', { sec: v }]), value: v }));
    return (
        <div>
            <MctaCombobox
                searchable={false}
                value={transition}
                options={transitionOptions}
                className={styles.styleStyle}
                onChange={({ value }) => dispatch({
                    type: actionTypes.IMAGE_SLIDER_TRANSITION_METHOD_CHANGED,
                    payload: value
                })}
            />
            <HorizontalSpacer x={13} />
            <MctaCheckbox
                isChecked={crop}
                label="msg: component.imageSlider.tb.crop {Crop}"
                onClick={() => dispatch({ type: actionTypes.IMAGE_SLIDER_TOGGLE_AUTO_CROP_TO_FIT })}
            />
            {!autoNext && <HorizontalSpacer x={5} />}
            {autoNext &&
            <React.Fragment><HorizontalSpacer x={12} /><MctaVerticalBar /><HorizontalSpacer x={12} /></React.Fragment>}
            {
                autoNext &&
                <MctaPropertyContainer label="msg: component.imageSlider.tb.speed {Speed:}">
                    <MctaCombobox
                        searchable={false}
                        value={delay}
                        options={slideTimeOptions}
                        className={styles.timerOptions}
                        useOptionsAsIs
                        onChange={({ value }) => dispatch({
                            type: actionTypes.IMAGE_SLIDER_TRANSITION_SHOW_NEXT_IMAGE_DELAY_CHANGED,
                            payload: value
                        })}
                    />
                </MctaPropertyContainer>
            }
            <HorizontalSpacer x={12} /><MctaVerticalBar />
            <HorizontalSpacer x={12} />
            <MctaCheckbox
                isChecked={stretch}
                label="msg: common.fullWidth {Full width}"
                onClick={() => {
                    dispatch({
                        type: IMAGE_SLIDER_PP_FULL_WIDTH
                    });
                    if (!stretch) {
                        dispatch(propPanelNavigateToPageAC(FULL_WIDTH));
                    }
                }}
            />
            <HorizontalSpacer />
            {children && <HorizontalSpacer />}
            {children}
        </div>
    );
}));

export default {
    editView: EditView,
    editButtonText: 'msg: component.imageSlider.settings {Image slider settings}',
    ctaButtonText: 'msg: component.imageSlider.changeImages {Change images}',
    ctaOnClick: ({ dispatch }: CTAViewParams<ImageSliderComponent>) =>
        dispatch({ type: actionTypes.IMAGE_SLIDER_PP_OPEN_SORTING_WINDOW_CLICK }),
    getWidth: ({ autoNext }: ImageSliderComponent) => (autoNext ? 534 : 370)
};
