const naturalSort = require('javascript-natural-sort');

/**
 * TODO:
 * - es6 module
 * - tests
 */

export const nSort = (array, getter) => [...array].sort((inA, inB) => {
    let a,
        b;
    if (typeof a !== 'string' || typeof b !== 'string') {
        [a, b] = getter(inA, inB);
    } else {
        [a, b] = [inA, inB];
    }
    return naturalSort(a, b);
});
