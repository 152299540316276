/* eslint-disable react/no-this-in-sfc */

import { connect, useDispatch } from 'react-redux';
import React from "react";
import { type AppState } from "../../../redux/modules/flowTypes";
import styles from './TemplateSelector.css';
import topBarStyles from "../../TopBar/view/TopBar.css";
import TopBarItemContainer from "../../TopBar/view/TopBarItemContainer";
import { Popups } from "../../TopBar/constants";
import { TopBarUserProfile } from "../../TopBar/view/TopBarUserProfile/index";
import TopBarHelp from "../../TopBar/view/TopBarItemMenu/TopBarHelp/index";
import chatLoadedVAT from "../../TopBar/epics/chat/valueActionType";
import topBarPopupVAT from '../../TopBar/epics/popup/valueActionType';
import languagesVAT from '../../TopBar/epics/languages/valueActionType';
import { whatsNewCountAppSel } from '../../WhatsNew/selectors';
import { OnecomLogo } from '../../../view/Logo';

type TopBarPropsType = {
    popupId: string;
    chatLoaded: boolean;
    languages: Object;
    whatsNewCount: number;
    subscriptionData: Object;
}

const TopBarContainer = (props: TopBarPropsType) => {
    const dispatch = useDispatch();
    const {
        popupId,
        chatLoaded,
        languages,
        whatsNewCount,
        subscriptionData,
    } = props;
    const isHelpPopupShown = popupId === Popups.HELP;
    const isUserProfilePopupShown = popupId === Popups.USER_PROFILE;

    return (
        <div className={topBarStyles.topBar}>
            <TopBarItemContainer left>
                <OnecomLogo />
            </TopBarItemContainer>
            <TopBarItemContainer disableMouseDown>
                <TopBarUserProfile
                    languages={languages}
                    isShown={isUserProfilePopupShown}
                    showDomain={false}
                    dispatch={dispatch}
                    fromTemplateSelector
                    subscriptionData={subscriptionData}
                    whatsNewCount={whatsNewCount}
                    shouldShowSettingsLink={false}
                />
            </TopBarItemContainer>
            <TopBarItemContainer disableMouseDown>
                <TopBarHelp
                    isShown={isHelpPopupShown}
                    chatLoaded={chatLoaded}
                    popupContainerClassName={styles.helpPopupContainer}
                    tipClassName={styles.helpPopupTip}
                    className={styles.templateSelectorHelpPadding}
                    showQuickTour={false}
                    dispatch={dispatch}
                />
            </TopBarItemContainer>
        </div>
    );
};

const mapStateToPropsTopBar = (appState: AppState, dispatch): any => {
    const { epics, subscriptionData } = appState;

    return {
        chatLoaded: epics[chatLoadedVAT].state.loaded,
        popupId: epics[topBarPopupVAT].state && epics[topBarPopupVAT].state.id,
        languages: epics[languagesVAT].state,
        whatsNewCount: whatsNewCountAppSel(appState),
        subscriptionData,
        dispatch
    };
};

export const TopBar = connect(mapStateToPropsTopBar, null)(TopBarContainer);
