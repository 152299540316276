import CALL_API from '../../../../../redux/middleware/api/CALL_API';
import * as Actions from "../actionTypes";

export default function (data) {
    return {
        [CALL_API]: {
            types: [Actions.GET_GENERATED_AI_CONTENT_REQUEST,
                Actions.GET_GENERATED_AI_CONTENT_SUCCESS,
                Actions.GET_GENERATED_AI_CONTENT_FAILED],
            endpoint: "getGeneratedAIContent",
            endpointParams: data,
        }
    };
}
