import registry from '../../../../../view/oneweb/registry/index';
import type { ComponentsMap } from "../../../../../redux/modules/children/workspace/flowTypes";

const applyComponentTransientPropertyChanges =
    (componentsMap: ComponentsMap, componentId: string, changeAction: Action<any>) => {
        const
            component = componentsMap[componentId],
            { reducer } = registry[component.kind],
            changedComponent = reducer(component, changeAction);

        if (changedComponent === component) {
            return componentsMap;
        }

        return {
            ...componentsMap,
            [componentId]: changedComponent
        };
    };

export default applyComponentTransientPropertyChanges;
