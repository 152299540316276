import {
    GENERAL_INFO_FAVICON_CHOOSEN
} from "../../../../SiteSettings/actions";

import type { ResourceMetadata } from "../../../../../../../../wbtgen/src/redux/modules/children/fileChooser/flowTypes";

export const
    setFaviconAction = (value: ResourceMetadata): Action => ({
        type: GENERAL_INFO_FAVICON_CHOOSEN,
        payload: value
    });
