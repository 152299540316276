import * as R from 'ramda';
import { DataPage, DataPageTemplate, DataPageSet } from "../../../../dal/model";
import { workspaceStylesheet } from "./workspaceStylesheet.td";
import { mapComponentsMapToPageData, mapPageDataToComponentsMap } from "../../../../dal/pageMapAdapter";
import WebshopComponentKind from '../../oneweb/WebShop/kind';
import webshopReducer from '../../oneweb/WebShop/reducer';
import isDevEnv from "../../../debug/isDevEnv";
import { updateComponentsRelIns } from '../../Workspace/epics/relations/updateComponentsRelIns';

type Result = {
    page: DataPage;
    template: DataPageTemplate;
};

export const updateItemsRelations = (page: DataPage, template: DataPageTemplate): Result => {
    // TODO: move workspaceStylesheet away from test data
    const
        pageDataSet = new DataPageSet({
            page: { items: page.items },
            template,
            stylesheet: workspaceStylesheet
        }),
        componentsMap = mapPageDataToComponentsMap(pageDataSet, undefined, true);

    let newComponentsMap = Object.keys(componentsMap).reduce((acc, cid) => ({
        ...acc,
        [cid]: {
            // Webshop component has default fields initialized inside reducer ...
            ...(componentsMap[cid].kind === WebshopComponentKind
                ? webshopReducer(componentsMap[cid], { type: 'DUMMY_ACTION' })
                : {}
            ),
            ...(isDevEnv() ? R.clone(componentsMap[cid]) : componentsMap[cid]) // WBTGEN-5284 mapPageDataToComponentsMap uses deep so nested relPage was frozen
        }
    }), {});

    //processRelations(newComponentsMap, template, true);
    newComponentsMap = updateComponentsRelIns(newComponentsMap, template.width, true);

    return mapComponentsMapToPageData(newComponentsMap, undefined, undefined, true);
};
