/* @flow */

const { makeUri } = require('@libj/make-uri');

/*::
    import type { CrmPlanT } from '../../lib/services/crm/planPrices/types'
 */

const TrialUrl = {
    /*** Segments ***/

    ROOT: '/trial',
    IMPORT: '/import/:domainName',
    BUY: '/buy',
    DOMAIN_AVAILABLE: '/domainavailable',
    SCREEN_SHOT: '/screenshot',
    PREVIEW_SITE: '/preview_site',
    SSO: '/sso',
    SIGN_IN: '/signin',
    SIGN_UP: '/signup',
    ACCOUNTS: '/:email/accounts',
    SOCIAL_SSO: '/social_sso',
    DASHBOARD: '/dashboard',
    AUTH: '/auth',
    MIGRATE: '/migrate',
    USER: '/user',
    PRICE: '/price/:plan',
    TRIAL_ID: '/:trialId',
    TRACK: '/track',
    GPT_DEMO_DATA: '/gpt-demo-data/:id',

    /*** Getters ***/

    to(...parts/*: string[] */) { return makeUri({ path: [this.ROOT].concat(parts) }); },
    import(domainName/*: string */) { return this.to(this.IMPORT.replace(':domainName', domainName)) },
    buy() { return this.to(this.BUY) },
    sso() { return this.to(this.SSO) },
    domainAvailable() { return this.to(this.DOMAIN_AVAILABLE); },
    signIn() { return this.to(this.SIGN_IN) },
    signUp() { return this.to(this.SIGN_UP) },
    accounts(email/*: string */) { return this.to(this.ACCOUNTS.replace(':email', email)) },
    socialSSO() { return this.to(this.SOCIAL_SSO) },
    price(plan/*: CrmPlanT */) { return this.to(this.PRICE.replace(':plan', plan)); },
    dashboard(...parts/*: string[] */) { return this.to(this.DASHBOARD, ...parts); },
    dashboardAuth() { return this.dashboard(this.AUTH); },
    dashboardMigrate() { return this.dashboard(this.MIGRATE); },
    dashboardGetUsers() { return this.dashboard(this.USER); },
    single(trialId/*: string */) { return this.to(trialId); },
};

module.exports = {
    TrialUrl
};
