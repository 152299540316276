import React from 'react';
import cx from 'classnames';
import { Flex } from "../../../../../view/reflexbox/index";
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import FontFamilySelector from "../../../../presentational/FontFamily/index";
import FourFieldSpacing from '../../../../presentational/FourFields/FourFieldSpacing/index';
import { dialogView as SpacingView } from '../../../../presentational/CharacterSpacing/index';
import IconButtonsGroup from "../../../../presentational/IconButtonsGroup/index";
import * as pagesIds from "./ids";
import pagesTitles from "./titles";
import type { Props } from '../flowTypes';
import * as actionTypes from "../../actionTypes";
import Icons from "../../../../../view/Icons/index";
import addGoogleFontView from '../../../../PropertiesPanel/view/AddGoogleFont/index';
import TextComponentKind from '../../kind';
import HorizontalSpacer from '../../../../../view/common/HorizontalSpacer';
import styles from '../../../../buttons.css';
import subscriptsStyles from '../styles.css';
import {
    TextVerticalAlignmentButtonsGroup
} from '../../../../presentational/IconButtonsGroup/TextAlignmentButtonGroup/VerticalAlignment';

const
    indentationBtnsDefs = {
        increaseIndent: {
            title: "msg: common.increaseIndent {Increase indent}",
            icon: Icons.INDENT,
            onClickAction: actionTypes.TEXT_INDENT_BTN_CLICKED,
            className: styles.btnGroup
        },
        decreaseIndent: {
            title: "msg: common.decreaseIndent {Decrease indent}",
            icon: Icons.OUTDENT,
            onClickAction: actionTypes.TEXT_OUTDENT_BTN_CLICKED,
            className: styles.btnGroup
        }
    },
    subscriptsBtnDefs = {
        superscript: {
            title: "msg: common.superscript {Superscript}",
            icon: Icons.SUPERSCRIPT,
            onClickAction: actionTypes.TEXT_MCTA_SUPERSCRIPT_BTN_CLICKED,
            className: cx(styles.btnGroup, subscriptsStyles["button-subscripts"]),
            iconClassName: subscriptsStyles["icon-superscript"]
        },
        subscript: {
            title: "msg: common.subscript {Subscript}",
            icon: Icons.SUBSCRIPT,
            onClickAction: actionTypes.TEXT_MCTA_SUBSCRIPT_BTN_CLICKED,
            className: cx(styles.btnGroup, subscriptsStyles["button-subscripts"]),
            iconClassName: subscriptsStyles["icon-subscript"]
        }
    };

const
    id = pagesIds.ADV_FORMATTING,
    title = pagesTitles[id],
    view = ({
        dispatch,
        tinyMceState: {
            spacing,
            superscript,
            subscript,
            fontFamily,
            lineHeight,
            characterSpacing,
            isIndented,
            canBeIndented
        },
        selectedComponent: { verticalAlignment = 'top' },
        siteFonts,
        navigateToPage
    }: Props) => {
        const subscriptsBtns = [
            {
                ...subscriptsBtnDefs.superscript,
                isSelected: superscript
            },
            {
                ...subscriptsBtnDefs.subscript,
                isSelected: subscript
            }
        ];

        const indentationBtns = [{
            ...indentationBtnsDefs.increaseIndent,
            disabled: !canBeIndented
        }, {
            ...indentationBtnsDefs.decreaseIndent,
            disabled: !isIndented
        }];

        return (
            <Page>
                <VerticalSpacer />
                <FontFamilySelector
                    selected={fontFamily}
                    onChangeAction={actionTypes.TEXT_FONT_FAMILY_CHANGED}
                    siteFonts={siteFonts}
                    onAddFontClicked={() => navigateToPage(addGoogleFontView.id, { source: TextComponentKind })}
                    isPropertiesPanel
                />
                <VerticalSpacer />
                <Flex align="center" className="btnGroupWrapper">
                    <IconButtonsGroup buttonGroup={indentationBtns} dispatch={dispatch} />
                    <HorizontalSpacer x={10} />
                    <IconButtonsGroup buttonGroup={subscriptsBtns} dispatch={dispatch} />
                    <HorizontalSpacer x={10} />
                    <span style={{ width: 105 }}>
                        <TextVerticalAlignmentButtonsGroup
                            value={verticalAlignment}
                            onBtnClick={payload => dispatch({
                                type: actionTypes.TEXT_VERTICAL_ALIGNMENT_CHANGED,
                                payload
                            })}
                        />
                    </span>
                </Flex>
                <VerticalSpacer />
                <SpacingView
                    spacing={characterSpacing}
                    spacingChangeAction={actionTypes.TEXT_COMPONENT_CHARACTER_SPACING_CHANGED}
                    lineHeight={lineHeight}
                    lineHeightChangeAction={actionTypes.TEXT_COMPONENT_LINE_SPACING_CHANGED}
                    dispatch={dispatch}
                />
                <VerticalSpacer />
                <FourFieldSpacing
                    label="msg: common.paragraphSpacing {Paragraph spacing (px)}"
                    values={spacing}
                    onChangeAction={actionTypes.TEXT_PARAGRAPH_SPACING_CHANGED}
                    dispatch={dispatch}
                />
                <VerticalSpacer />
            </Page>
        );
    };

export {
    id,
    title,
    view
};
