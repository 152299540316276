export const
    SiteSettingsDialogId = 'SiteSettingsDialogId',
    SITE_SETTINGS_ACTIVE_TAB_ID = 'SITE_SETTINGS_ACTIVE_TAB_ID',
    width = 1200,
    height = 800,
    SiteSettingsTabName = {
        COOKIE_BANNER: 'cookieBanner',
        SEO: 'seo',
        TRACKING: 'tracking',
        GENERAL: 'general',
        SECURITY: 'security',
        SOCIAL_SHARE: 'socialShare',
        BUSINESS_LISTINGS: 'businessListings',
        THEME: 'theme',
        MESSENGER_CHAT: 'messengerChat'
    },
    CookieBannerStyles = {
        GREY: 'GREY',
        BLACK: 'BLACK',
        WHITE: 'WHITE'
    },
    // Used in publishing
    cookieBannerthemeMap = {
        GREY: 'cookieBannerGray',
        BLACK: 'cookieBannerBlack',
        WHITE: 'cookieBannerWhite'
    },
    ChatWidgetMap = {
        DISABLED: 'Disabled',
        ENABLED: 'Enabled',
    },
    BannerTextLimit = {
        start: 1,
        soft: 450,
        hard: 500
    },
    LinkTextLimit = {
        start: 1,
        soft: 60,
        hard: 70
    },
    ButtonTextLimit = {
        start: 1,
        soft: 50,
        hard: 60
    },
    googleAdsStateIconsMap = {
        EXCLAMATION: 'exclamation',
        EXCLAMATION_PUBLISH: 'exclamationPublish',
        UPDATE: 'update',
        CHECKBOX: 'checkBox',
        DEACTIVATED: 'deactivated'
    };
