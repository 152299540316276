import * as R from "ramda";
import { setCells, setCommonCellsData } from "./setAllCells";
import { tableNormalGlobalstyle } from "../../../Workspace/epics/stylesheets/selectors";
import type {
    CommonTableComponentCell,
    TableComponent,
    TableComponentCell,
    TableComponentDependsOn
} from "../flowTypes";

export const unsetCellTextStylesReducer =
    (component: TableComponent, payload: any, dependencies: TableComponentDependsOn): TableComponent => {
        const { id, name } = tableNormalGlobalstyle(dependencies.stylesheets),
            { selectedCellsIndexes } = dependencies.tableEditModeState,
            clearCellTextFormatting = (cell: TableComponentCell, commonCellsData?: CommonTableComponentCell) => {
                let updatedCell = { ...cell };
                if (commonCellsData && !cell.style) {
                    updatedCell = { ...updatedCell, ...commonCellsData };
                }
                return R.evolve({
                    style: {
                        globalId: R.always(id),
                        globalName: R.always(name)
                    }
                })(updatedCell);
            };

        return R.pipe(
            (component) => setCells({ cellUpdater: clearCellTextFormatting, selectedCellsIndexes }, component),
            (component) => setCommonCellsData({ cellUpdater: clearCellTextFormatting, selectedCellsIndexes }, component)
        )(component);
    };
