import { getMouseMovedDiff } from "../../../../redux/modules/children/workspace/reducers/utils/index";
import { getCropDimensions } from '../componentSelectors';
import type { ImageComponent } from '../flowTypes';
import type { Position } from '../../../../redux/modules/flowTypes';

const
    getComponentChangeOnEditMove = (component: ImageComponent, xMoveDiff: number, yMoveDiff: number) => {
        const
            {
                imageWidth,
                imageHeight,
                innerWidth: componentWidth,
                innerHeight: componentHeight
            }: Record<string, any> = getCropDimensions(component);

        let
            cropLeft = component.cropLeft - xMoveDiff,
            cropTop = component.cropTop - yMoveDiff;

        if ((cropLeft + componentWidth) > imageWidth) {
            cropLeft = imageWidth - componentWidth; // lock right edge of image to right edge of component
        }

        if (cropLeft < 0) {
            cropLeft = 0; // lock left edge to left edge of component
        }

        if ((cropTop + componentHeight) > imageHeight) {
            cropTop = imageHeight - componentHeight;  // lock top edge of image to top edge of component
        }

        if (cropTop < 0) {
            cropTop = 0; // lock top edge to top edge of component
        }

        return { cropLeft, cropTop };
    },
    onMouseMoveOrUp = (component: ImageComponent, startPos: Position, currentPos: Position) => {
        const { x, y } = getMouseMovedDiff(startPos, currentPos);

        return { ...component, ...getComponentChangeOnEditMove(component, x, y) };
    };

export default onMouseMoveOrUp;
