const CatchAllConcepts = [
    {
        label: 'msg: onboarding.dynamic.concept.portfolio.label {Portfolio}',
        description: 'msg: onboarding.dynamic.concept.portfolio.description {e.g. photography, event planner, design portfolio}',
        value: 'catch_all_portfolio',
    },
    {
        label: 'msg: onboarding.dynamic.concept.service.label {Service}',
        value: 'catch_all_service',
        description: 'msg: onboarding.dynamic.concept.service.description {e.g. mechanic, law firm, consultant}',
    },
    {
        label: 'msg: onboarding.dynamic.concept.event.label {Event}',
        value: 'catch_all_event',
        description: 'msg: onboarding.dynamic.concept.event.description {e.g. wedding, concert, birthday, conference}',
    },
    {
        label: 'msg: onboarding.dynamic.concept.physicalStore.label {Physical store}',
        value: 'catch_all_physical_store',
        description: 'msg: onboarding.dynamic.concept.physicalStore.description {e.g. grocery store, electronics shop}',
    },
    {
        label: 'msg: onboarding.dynamic.concept.onlineShop.label {Online shop}',
        value: 'catch_all_online_shop',
        description: 'msg: onboarding.dynamic.concept.onlineShop.description {e.g. E-commerce, digital products}',
    },
    {
        label: 'msg: onboarding.dynamic.concept.organization.label {Organisation}',
        value: 'catch_all_organisation',
        description: 'msg: onboarding.dynamic.concept.organization.description {e.g. foundation, pet shelter, community}',
    },
    {
        label: 'msg: onboarding.dynamic.concept.project.label {Project}',
        value: 'catch_all_project',
        description: 'msg: onboarding.dynamic.concept.project.description {e.g. school project, art project, startup project}',
    },
    {
        label: 'msg: onboarding.dynamic.concept.personalWebsite.label {Personal}',
        value: 'catch_all_personal_website',
        description: 'msg: onboarding.dynamic.concept.personalWebsite.description {e.g. family website, my pet website}',
    },
];

const GenericCatchAllConcept = {
    label: 'msg: onboarding.dynamic.concept.genericCatchall.label {Simple site}',
    value: 'catch_all_generic',
    description: 'msg: onboarding.dynamic.concept.genericCatchall.description {Website for any business or services}'
};

export {
    CatchAllConcepts,
    GenericCatchAllConcept
};
