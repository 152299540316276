import * as React from "react";
import cx from 'classnames';
import { pure } from 'recompose';
import {
    IMAGE_PROP_PANEL_ROTATE_RIGHT,
    IMAGE_PROP_PANEL_ROTATE_LEFT,
    IMAGE_REPLACE_IMAGE,
    IMAGE_UPDATE_ON_REPLACE,
    IMAGE_SET_LINK,
    IMAGE_PROP_PANEL_SCALE_CHANGE,
    IMAGE_MCTA_HORIZONTAL_ALIGNMENT_CHANGED,
    LOGO_TITLE_SCALE_CHANGED,
    LOGO_MCTA_RESIZE_CHANGED
} from '../actionTypes';
import { MctaVerticalBar, MctaButton } from '../../../componentMainActions/index';
import { MctaSlider } from '../../../componentMainActions/MctaSlider/MctaSlider';
import openLinkChooserDialog from "../../../../view/common/dialogs/LinkChooserDialog/actionCreators/openLinkChooserDialog"; // eslint-disable-line max-len
import HorizontalSpacer from '../../../../view/common/HorizontalSpacer';
import ScaleStrategy from '../scaleStrategy';
import * as selectors from '../componentSelectors';
import styles from '../view/Image.css';
import mctaStyles from '../../../componentMainActions/MctaButton/styles.css';
import type { ImageComponent, ImageComponentDependsOn } from "../flowTypes";
import type { CTAViewParams } from '../../../../view/Workspace/Decorations/ComponentMainActions/flowTypes';
import allowImageEditor from "../../../../view/common/dialogs/ImageEditorDialog/allowImageEditor";
import { editImageAC } from "../../../ImageEditor/actionCreators";
import {
    default as AlignmentControls,
    StatefulAlignmentControls
} from '../../../componentMainActions/MctaAlignmentControls/alignmentControls';
import ImageKind from "../kind";
import { injectIntl, Intl } from "../../../../view/intl/index";
import {
    LOGO_MCTA_HORIZONTAL_ALIGNMENT_MOUSE_LEAVE,
    LOGO_MCTA_HORIZONTAL_ALIGNMENT_MOUSE_ENTER,
    LOGO_ASSET_UPDATE_ON_REPLACE
} from "../../Logo/actionTypes";
import { repositoryTransparentImageMetadata } from "../../../../constants/components";
import type { isCTAUrgentType } from '../../flowTypes';
import { SVGContentType } from '../../constants';

type PropTypes = {
    selectedComponent: ImageComponent;
    globalVariables: Record<string, any>;
    service: { horizontalAlignmentMctaExpanded: boolean };
    children?: any;
    dispatch: Dispatch;
    isInsideHeaderOrFooter: boolean;
    isModernLayoutActivated: boolean;
}

const
    EditView = pure(class Common extends React.Component<PropTypes> {
        render() {
            const
                { selectedComponent, dispatch, children, isInsideHeaderOrFooter, isModernLayoutActivated } = this.props,
                { scaleStrategy } = selectedComponent,
                isLogo = scaleStrategy === ScaleStrategy.LOGO;

            const
                isLogoTitleScale = isLogo && !this.props.globalVariables.logoAsset,
                linkAction = selectedComponent.linkAction,
                disableScale = scaleStrategy !== ScaleStrategy.CROP && !isLogo,
                disableRotation = scaleStrategy === ScaleStrategy.LOGO,
                minZoom:number = selectors.getMinZoom(selectedComponent),
                scale = (((selectedComponent[isLogoTitleScale ? 'logoTitleScale' : 'scale'] || 0) * 10000) | 0) / 100, // eslint-disable-line no-bitwise
                minScaleValue = isLogo ? 25 : ((minZoom * 10000) | 0) / 100, // eslint-disable-line no-bitwise
                maxScaleValue = isLogo ? 100 : ((10000 * selectors.getMaxZoom(selectedComponent)) | 0) / 100, // eslint-disable-line no-bitwise
                titleEditImage = "msg: common.editImage {Edit image}",
                titleLink = linkAction
                    ? "msg: common.changeLink {Change link}"
                    : "msg: common.addLink {Add link}",
                titleRotateLeft = "msg: common.rotateLeft {Rotate left}",
                titleRotateRight = "msg: common.rotateRight {Rotate right}";

            let isImageEditorAllowed = allowImageEditor();
            let isResizeAllowed = false;

            if (isLogo) {
                isImageEditorAllowed =
                    this.props.globalVariables.logoAsset && this.props.globalVariables.logoAsset.contentType !== SVGContentType;
                isResizeAllowed = isInsideHeaderOrFooter && isModernLayoutActivated;
            }

            return isResizeAllowed ?
                <div>
                    {isImageEditorAllowed &&
                        <React.Fragment>
                            <MctaButton
                                title={titleEditImage}
                                icon="EDIT"
                                onMouseDown={e => e.stopPropagation()}
                                onClick={() => dispatch(
                                    editImageAC(
                                        LOGO_ASSET_UPDATE_ON_REPLACE,
                                        this.props.globalVariables.logoAsset
                                    )
                                )}
                            />
                            <HorizontalSpacer x={5} />
                            <MctaVerticalBar />
                        </React.Fragment>}
                    <HorizontalSpacer x={5} />
                    <StatefulAlignmentControls
                        label="msg: common.sizeColon {Size:}"
                        actionTypeToDispatch={LOGO_MCTA_RESIZE_CHANGED}
                        size={selectedComponent.logoSize || 'medium'}
                        dispatch={dispatch}
                        kind={ImageKind}
                    />
                </div> :
                <div>
                    {isImageEditorAllowed &&
                    <MctaButton
                        title={titleEditImage}
                        icon="EDIT"
                        onMouseDown={e => e.stopPropagation()}
                        onClick={() => dispatch(
                            editImageAC(
                                isLogo ? LOGO_ASSET_UPDATE_ON_REPLACE : IMAGE_UPDATE_ON_REPLACE,
                                isLogo ? this.props.globalVariables.logoAsset : selectedComponent.asset
                            )
                        )}
                    />}
                    {isImageEditorAllowed && <HorizontalSpacer x={5} />}
                    {isImageEditorAllowed && <MctaVerticalBar />}
                    {isImageEditorAllowed && <HorizontalSpacer x={5} />}
                    {!isLogo && <MctaButton
                        title={titleLink}
                        icon="LINK"
                        className={cx(styles.link, { [mctaStyles.mctaLinkButtonWithLinkSet]: !!linkAction })}
                        onMouseDown={e => e.stopPropagation()}
                        onClick={() => dispatch(
                            openLinkChooserDialog({
                                setLinkAction: IMAGE_SET_LINK,
                                input: selectedComponent.linkAction
                            })
                        )}
                    />}
                    {
                        isLogo && <React.Fragment>
                            <AlignmentControls
                                actionTypeToDispatch={IMAGE_MCTA_HORIZONTAL_ALIGNMENT_CHANGED}
                                onMouseEnterCollapsedViewActionType={LOGO_MCTA_HORIZONTAL_ALIGNMENT_MOUSE_ENTER}
                                onMouseLeaveExpandedViewActionType={LOGO_MCTA_HORIZONTAL_ALIGNMENT_MOUSE_LEAVE}
                                alignment={selectedComponent.logoHorizontalAlignment || 'left'}
                                expanded={this.props.service.horizontalAlignmentMctaExpanded}
                                dispatch={dispatch}
                                kind={ImageKind}
                            />
                        </React.Fragment>
                    }
                    {!disableRotation && <React.Fragment>
                        <HorizontalSpacer x={5} />
                        <MctaVerticalBar />
                        <HorizontalSpacer x={5} />
                        <MctaButton
                            title={titleRotateLeft}
                            icon="ROTATE_LEFT"
                            onMouseDown={e => e.stopPropagation()}
                            onClick={() => dispatch({ type: IMAGE_PROP_PANEL_ROTATE_LEFT })}
                        />
                    </React.Fragment>}
                    {!disableRotation && <React.Fragment>
                        <HorizontalSpacer x={5} />
                        <MctaVerticalBar />
                        <HorizontalSpacer x={5} />
                        <MctaButton
                            title={titleRotateRight}
                            icon="ROTATE_RIGHT"
                            onMouseDown={e => e.stopPropagation()}
                            onClick={() => dispatch({ type: IMAGE_PROP_PANEL_ROTATE_RIGHT })}
                        />
                    </React.Fragment>}
                    {
                        !disableScale &&
                        <React.Fragment>
                            <HorizontalSpacer x={5} />
                            <MctaVerticalBar />
                            <HorizontalSpacer x={isLogo ? 13 : 5} />
                        </React.Fragment>
                    }
                    {
                        !disableScale && (
                            <MctaSlider
                                label={
                                    isLogo ? "msg: common.scale {Scale}"
                                        : "msg: component.image.scaleImage {Scale image}"
                                }
                                valueLabel={`${Math.round(scale)}%`}
                                step={0.1}
                                min={minScaleValue}
                                max={maxScaleValue}
                                value={scale}
                                defaultValue={scale}
                                onChange={newScale => {
                                    dispatch({
                                        type: isLogoTitleScale ? LOGO_TITLE_SCALE_CHANGED : IMAGE_PROP_PANEL_SCALE_CHANGE,
                                        payload: newScale,
                                        amendToSelf: true
                                    });
                                }}
                            />
                        )
                    }
                    {!disableScale &&
                    (children ? <HorizontalSpacer /> : <HorizontalSpacer x={5} />)}
                    {children}
                </div>;
        }
    });

const
    mctaWidthRegularMode = 292,
    mctaWidthCropMode = 538;

export const makeImageComponentMainActions = (props: Record<string, any>) => ({
    ...props,
    editView: EditView,
    getWidth: (component: ImageComponent) =>
        (component.scaleStrategy === ScaleStrategy.CROP ? mctaWidthCropMode : mctaWidthRegularMode),
});

const isCTAUrgent: isCTAUrgentType<ImageComponent, ImageComponentDependsOn> = (component, dependencies) => {
    const
        { asset: { url } } = component,
        { missingAssetUrls } = dependencies;

    return missingAssetUrls.indexOf(url) > -1 || (url === repositoryTransparentImageMetadata.url);
};

export const imageComponentsMainActions = makeImageComponentMainActions({
    getEditButtonText: () => 'msg: component.image.editImage {Edit image}',
    // @ts-ignore
    CtaButtonTextView: injectIntl((props: { intl: Intl }) =>
        props.intl.msgJoint('msg: component.image.replaceImage {Replace image}')),
    isCTAUrgent,
    ctaOnClick: ({ dispatch, selectedComponent }: CTAViewParams<ImageComponent>) =>
        dispatch({ type: IMAGE_REPLACE_IMAGE, payload: selectedComponent.asset.url }),
});
