import React from 'react';
import ComponentBorderSettings from '../../../../PropertiesPanel/view/components/BorderSettings/ComponentBorderSettings';
import * as ActionTypes from '../../actionTypes';
import type { BackgroundPageProps } from "../flowTypes";
import { getThemeRulesForBackground } from "../../../../ThemeGlobalData/themeRules";
import { BACKGROUND_THEME_WHITE } from '../../../../ThemeGlobalData/constants';

type Options = {
    showCorners?: boolean;
    showOpacity?: boolean;
    showLeftAndRightElements?: boolean;
    showTopAndBottomElements?: boolean;
};

const BackgroundBorderView = (props: BackgroundPageProps) => {
    const { selectedComponent, dispatch,
        showCorners, showOpacity, showTopAndBottomElements, showLeftAndRightElements } = props;

    if (showCorners !== false) {
        if (props.themeSettingsData.autoColorMode) { // showCorners === true && autoColorMode === true
            return <ComponentBorderSettings.view
                autoColorMode
                themeColor={
                    props.selectedComponent.selectedBorderTheme
                    || getThemeRulesForBackground(
                        props.selectedComponent.selectedTheme || BACKGROUND_THEME_WHITE,
                        props.themeColorsData
                    ).text
                }
                themeColorsData={props.themeColorsData}
                styleChangeAction={ActionTypes.BG_BORDER_STYLE_CHANGE_AUTO_COLOR}
                colorChangeAction={ActionTypes.BG_BORDER_COLOR_CHANGE_AUTO_COLOR}
                colorOnTheFlyChange
                opacityChangeAction={ActionTypes.BG_BORDER_OPACITY_CHANGE}
                widthChangeAction={ActionTypes.BG_BORDER_WIDTH_CHANGE}
                cornerChangeAction={ActionTypes.BG_BORDER_CORNERS_CHANGE}
                selectedComponent={selectedComponent}
                dispatch={dispatch}
                showCorners
                showOpacity={showOpacity}
                showTopAndBottomElements={showTopAndBottomElements}
                showLeftAndRightElements={showLeftAndRightElements}
            />;
        } else { // showCorners === true && autoColorMode === false
            return <ComponentBorderSettings.view
                autoColorMode={false}
                styleChangeAction={ActionTypes.BG_BORDER_STYLE_CHANGE}
                colorChangeAction={ActionTypes.BG_BORDER_COLOR_CHANGE}
                colorOnTheFlyChange
                opacityChangeAction={ActionTypes.BG_BORDER_OPACITY_CHANGE}
                widthChangeAction={ActionTypes.BG_BORDER_WIDTH_CHANGE}
                cornerChangeAction={ActionTypes.BG_BORDER_CORNERS_CHANGE}
                selectedComponent={selectedComponent}
                dispatch={dispatch}
                showCorners
                showOpacity={showOpacity}
                showTopAndBottomElements={showTopAndBottomElements}
                showLeftAndRightElements={showLeftAndRightElements}
            />;
        }
    } else if (props.themeSettingsData.autoColorMode) { // showCorners === false && autoColorMode === true
        return <ComponentBorderSettings.view
            autoColorMode
            themeColor={
                props.selectedComponent.selectedBorderTheme
                || getThemeRulesForBackground(
                    props.selectedComponent.selectedTheme || BACKGROUND_THEME_WHITE,
                    props.themeColorsData
                ).text
            }
            themeColorsData={props.themeColorsData}
            styleChangeAction={ActionTypes.BG_BORDER_STYLE_CHANGE_AUTO_COLOR}
            colorChangeAction={ActionTypes.BG_BORDER_COLOR_CHANGE_AUTO_COLOR}
            colorOnTheFlyChange
            opacityChangeAction={ActionTypes.BG_BORDER_OPACITY_CHANGE}
            widthChangeAction={ActionTypes.BG_BORDER_WIDTH_CHANGE}
            selectedComponent={selectedComponent}
            dispatch={dispatch}
            showCorners={false}
            showOpacity={showOpacity}
            showTopAndBottomElements={showTopAndBottomElements}
            showLeftAndRightElements={showLeftAndRightElements}
        />;
    } else { // showCorners === false && autoColorMode === false
        return <ComponentBorderSettings.view
            autoColorMode={false}
            styleChangeAction={ActionTypes.BG_BORDER_STYLE_CHANGE}
            colorChangeAction={ActionTypes.BG_BORDER_COLOR_CHANGE}
            colorOnTheFlyChange
            opacityChangeAction={ActionTypes.BG_BORDER_OPACITY_CHANGE}
            widthChangeAction={ActionTypes.BG_BORDER_WIDTH_CHANGE}
            selectedComponent={selectedComponent}
            dispatch={dispatch}
            showCorners={false}
            showOpacity={showOpacity}
            showTopAndBottomElements={showTopAndBottomElements}
            showLeftAndRightElements={showLeftAndRightElements}
        />;
    }
};

export default (id: string, title: string, options: Options = {}) => {
    const { showCorners, showOpacity, showLeftAndRightElements, showTopAndBottomElements } = options;

    return {
        id,
        title,
        view: (props: BackgroundPageProps) => (
            <BackgroundBorderView
                showCorners={showCorners}
                showOpacity={showOpacity}
                showTopAndBottomElements={showTopAndBottomElements}
                showLeftAndRightElements={showLeftAndRightElements}
                {...props}
            />
        )
    };
};
