import { DOMAIN_FROM_AUTH_COOKIE_VALUE } from "./actionTypes";
import { when, withSelector } from "../../../epics/makeCondition";

export const WhenRepositoryDomainSelector = withSelector(
    when(DOMAIN_FROM_AUTH_COOKIE_VALUE, ({ payload: domain }) => {
        // return domain === 'repository';

        let isRepository = false;
        if (
            domain && // null check is required for some potential test cases
            domain.indexOf('.') === -1 &&
            (
                domain === 'repository' ||
                domain.indexOf('design-') === 0 ||
                domain.indexOf('page-layout-') === 0 ||
                domain.indexOf('component-layout-') === 0
            )
        ) {
            isRepository = true;
        }
        return isRepository;
    }),
    v => !!v
);
