import { SHOW_SECTION_INTRO_DIALOG_VAT } from './valueActionType';
import { makeEpic } from "../../../../../epics/makeEpic";
import {
    FETCH_USER_DATA_MIGRATION_INFO_SUCCESS, USER_DATA_MIGRATION_REQUEST_SUCCESS
} from "../../../../UserDataMigration/migration/userDataMigrationActionTypes";
import { closeDialog, openDialog } from "../../../../App/actionCreators/index";
import { sectionIntroDialogId } from "../../dialog/sectionIntroDialogId";
import {
    CLICK_START_SECTION_TOUR,
    SECTION_INTRO_DIALOG_CLOSE,
    SECTION_INTRO_NOT_NOW, SECTION_INTRO_OPEN,
    SHOW_FIND_TIPS_STICKY_TOOLTIP
} from "./actionTypes";
import { GET_PREFERENCES_SUCCESS_ACTION, savePreferencesAction } from "../../../../Preferences/actions";
import { START_SECTION_TOUR } from "../../../../QuickTour/actionTypes";
import { UserDataMigrationVAT } from "../../../../UserDataMigration/epic/valueActionType";
import { userDataMigratedSuccessfully } from "../../../../UserDataMigration/utils";
import AppStatusVAT from "../../../../App/epics/status/valueActionType";
import * as AppStatus from "../../../../App/epics/status/appStatusTypes";
import { WORKSPACE_SCROLL_TO_SELECTED_COMPONENT } from "../../../../Workspace/actionTypes";
import { ReceiveOnlyComponentsMap } from "../../../../Workspace/epics/componentsEval/selectorActionTypes";
import { SET_SELECTED_COMPONENTS } from "../../../../Workspace/epics/componentsEval/actionTypes";
import { getFirstPageSection } from "../../utils";
import { SHOW_TOOLTIP_FOR_SECTION_ONBOARDING } from "../../../../Tooltip/actionTypes";
import { SECTION_INTRO_TOASTER_DELAY } from "../../../../Tooltip/epics/tooltip/index";
import { SHOW_STICKY_TOOLTIP } from "../../../../Tooltip/stickyTooltip/actionTypes";
import { FindTips } from "../../../../Tooltip/ids";
import { AUTH_COOKIE_MISSING } from "../../../../App/epics/auth/actionTypes";
import { LOGOUT } from "../../../../TopBar/actionTypes";
import { anyOf, receiveOnly } from "../../../../../epics/makeCondition";
import { ls } from '../../../../../utils/localStorage';
import { siteDataValueActionType } from '../../../../App/epics/siteData/valueActionType';

const PRE_SECTION_MIGRATION_VERSION = 45;
const SECTION_MIGRATION_VERSION = 46;
const SECTION_INTRO_SHOWN = 'SECTION_INTRO_SHOWN';

const sectionIntroEpic = makeEpic({
    defaultState: {
        showSectionIntro: false
    },
    defaultScope: {},
    valueActionType: SHOW_SECTION_INTRO_DIALOG_VAT,
    updaters: [
        {
            conditions: [FETCH_USER_DATA_MIGRATION_INFO_SUCCESS],
            reducer: ({ values: [{ currentDataVersionNumber, latestDataVersionNumber }], state, scope }) => {
                return {
                    state,
                    scope: {
                        ...scope,
                        currentDataVersionNumber,
                        latestDataVersionNumber
                    }
                };
            }
        },
        {
            conditions: [USER_DATA_MIGRATION_REQUEST_SUCCESS],
            reducer: ({ values: [{ success, userDataModified }], state, scope }) => {
                return {
                    state,
                    scope: {
                        ...scope,
                        userDataModified: userDataModified && success,
                    }
                };
            }
        },
        {
            conditions: [
                receiveOnly(siteDataValueActionType),
                UserDataMigrationVAT,
                AppStatusVAT
            ],
            reducer: ({ values: [site, userDataMigrationStatus, status], state, scope }) => {
                const { currentDataVersionNumber, latestDataVersionNumber } = scope;
                const isUserDataMigrationSuccessful = userDataMigratedSuccessfully(userDataMigrationStatus);
                const isPagesExists = site && site.folder && site.folder.items && site.folder.items.length > 0;
                if (
                    isPagesExists &&
                    currentDataVersionNumber <= PRE_SECTION_MIGRATION_VERSION
                    && latestDataVersionNumber >= SECTION_MIGRATION_VERSION
                    && isUserDataMigrationSuccessful
                    && !state.showSectionIntro
                    && status === AppStatus.READY
                ) {
                    return {
                        state,
                        scope,
                        multipleActionsToDispatch: [
                            savePreferencesAction({
                                showSectionIntro: true
                            }),
                            { type: SECTION_INTRO_OPEN }
                        ]
                    };
                }
                return {
                    state,
                    scope
                };
            }
        },
        {
            conditions: [
                SHOW_FIND_TIPS_STICKY_TOOLTIP
            ],
            reducer: ({ scope, state }) => {
                return {
                    state,
                    scope,
                    actionToDispatch: {
                        type: SHOW_STICKY_TOOLTIP,
                        payload: {
                            position: {
                                x: 490,
                                y: 20
                            },
                            id: FindTips,
                            showIcon: false,
                            textStyle: { textAlign: 'center' }
                        }
                    }
                };
            }
        },
        {
            conditions: [
                SECTION_INTRO_DIALOG_CLOSE
            ],
            reducer: ({ scope, state }) => {
                const multipleActionsToDispatch = [
                    closeDialog(),
                    { type: SHOW_FIND_TIPS_STICKY_TOOLTIP }
                ];
                if (state.showSectionIntro) {
                    multipleActionsToDispatch.push(savePreferencesAction({
                        showSectionIntro: false
                    }));
                }
                return {
                    state,
                    scope,
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                SECTION_INTRO_NOT_NOW
            ],
            reducer: ({ values: [componentsMap], scope, state, dispatchAsync }) => {
                ls.set(SECTION_INTRO_SHOWN, 'yes', true);
                const multipleActionsToDispatch: Action[] = [
                    closeDialog(),
                    { type: SHOW_FIND_TIPS_STICKY_TOOLTIP }
                ];
                if (!state.showSectionIntro) {
                    multipleActionsToDispatch.push(savePreferencesAction({
                        showSectionIntro: true
                    }));
                }
                if (!state.hideSectionIntroToaster && scope.userDataModified) {
                    let firstPageSection = getFirstPageSection(componentsMap);
                    const payload: Array<string> = [firstPageSection.id];
                    multipleActionsToDispatch.push({ type: SET_SELECTED_COMPONENTS, payload },
                        { type: WORKSPACE_SCROLL_TO_SELECTED_COMPONENT },);
                    setTimeout(() => {
                        dispatchAsync({ type: SHOW_TOOLTIP_FOR_SECTION_ONBOARDING, payload: true });
                    }, SECTION_INTRO_TOASTER_DELAY);
                }
                return {
                    state,
                    scope,
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [GET_PREFERENCES_SUCCESS_ACTION],
            reducer: ({ values: [{ showSectionIntro, hideSectionIntroToaster }], state, scope }) => {
                return {
                    state: {
                        ...state,
                        showSectionIntro,
                        hideSectionIntroToaster
                    },
                    scope
                };
            }
        },
        {
            conditions: [AppStatusVAT],
            reducer: ({ values: [status], state, scope }) => {
                if (status === AppStatus.READY && state.showSectionIntro) {
                    const isSectionIntroShown = ls.get(SECTION_INTRO_SHOWN, true);
                    return {
                        state,
                        scope,
                        actionToDispatch: !isSectionIntroShown ? { type: SECTION_INTRO_OPEN } : null
                    };
                }
                return {
                    state,
                    scope
                };
            }
        },
        {
            conditions: [SECTION_INTRO_OPEN],
            reducer: ({ state, scope }) => {
                return {
                    state,
                    scope,
                    actionToDispatch: openDialog(sectionIntroDialogId)
                };
            }
        },
        {
            conditions: [anyOf(AUTH_COOKIE_MISSING, LOGOUT)],
            reducer: ({ state, scope }) => {
                Object.keys(window.localStorage)
                    .filter(key => key.indexOf(SECTION_INTRO_SHOWN) !== -1)
                    .forEach(key => window.localStorage.removeItem(key));
                return { state, scope };
            }
        },
        {
            conditions: [CLICK_START_SECTION_TOUR],
            reducer: ({ state, scope }) => {
                const multipleActionsToDispatch = [closeDialog(), { type: START_SECTION_TOUR,
                    payload: { showSectionIntroToaster: !state.hideSectionIntroToaster && scope.userDataModified } }
                ];
                if (state.showSectionIntro) {
                    multipleActionsToDispatch.push(savePreferencesAction({
                        showSectionIntro: false
                    }));
                }
                return {
                    state,
                    scope,
                    multipleActionsToDispatch
                };
            }
        },
    ]
});

export {
    sectionIntroEpic
};
