import Url from "../../utils/Url";

type Scheme = 'http' | 'https';

type Options = {
    scheme?: Scheme,
};

type Result = {
    fixed: boolean,
    url: string,
}

const DefaultOptions: Options = {
    scheme: 'http',
};

export const autofixUrlScheme = (url: string, options?: Options): Result => {
    const opts = { ...DefaultOptions, ...options };

    const parsedUrl = Url(url);

    if (!parsedUrl.protocol) {
        return {
            fixed: true,
            url: `${opts.scheme}://${url}`,
        };
    }

    return { fixed: false, url };
};
