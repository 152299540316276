import { SNAP } from "./constants";
import type { BBox } from "../../../App/flowTypes";

const getHighAndLowPoints = (direction) => {
    if ([SNAP.LEFT, SNAP.RIGHT, SNAP.CENTER].includes(direction)) {
        return { hP: SNAP.RIGHT, lP: SNAP.LEFT };
    }
    return { hP: SNAP.BOTTOM, lP: SNAP.TOP };
};

const categoriseBoxPositions = (boxes, region, direction) => {
    const { lP } = getHighAndLowPoints(direction);
    const { before, after } = boxes
        .sort((a, b) => a[lP] - b[lP])
        .reduce((res, box) => {
            const { before, after } = res;
            if (box[lP] > region[lP]) {
                return { before, after: [...after, box] };
            } else if (box[lP] < region[lP]) {
                return { after, before: [...before, box] };
            }
            return res;
        }, { before: [], after: [] });
    return {
        before: [...before, region],
        after: [region, ...after]
    };
};

const getSpacingX = (box, distance, mainBox = box) => ({
    top: mainBox.top,
    left: box.right,
    width: distance,
    height: mainBox.bottom - mainBox.top,
    opacity: 1,
});

const getSpacingY = (box, distance, mainBox = box) => ({
    top: box.bottom,
    left: mainBox.left,
    width: mainBox.right - mainBox.left,
    height: distance,
    opacity: 1,
});

const calculateDistancesAndStyles = (boxes, direction, before = false) => {
    const { lP, hP } = getHighAndLowPoints(direction),
        styleFn = lP === SNAP.TOP ? getSpacingY : getSpacingX,
        defaultValue = { distances: [], style: [] };

    if (boxes.length < 1) { return defaultValue; }

    const pairs = boxes.slice(1).map((b, i) => [boxes[i], b]);
    return pairs.reduce((acc, [firstBox, secondBox]) => {
        const { distances, style } = acc,
            d = Math.abs(secondBox[lP] - firstBox[hP]),
            mainBox = before ? firstBox : secondBox,
            s = styleFn(firstBox, d, mainBox);
        return { distances: [...distances, d], style: [...style, s] };
    }, defaultValue);
};

type Prop = { distances: Array<number>, style: Array<Record<string, any>> }

const getDistancesAndSpacingStyles = (boxPositions, direction): Prop => {
    const { before, after } = boxPositions;
    let res: Prop = { distances: [], style: [] };
    switch (direction) {
        case SNAP.RIGHT:
        case SNAP.BOTTOM:
            res = calculateDistancesAndStyles(after, direction, true);
            break;
        case SNAP.TOP:
        case SNAP.LEFT:
            res = calculateDistancesAndStyles(before, direction);
            break;
        case SNAP.MIDDLE:
        case SNAP.CENTER: {
            const res1 = calculateDistancesAndStyles(before, direction),
                res2 = calculateDistancesAndStyles(after, direction, true);
            res = {
                distances: [...res1.distances, ...res2.distances],
                style: [...res1.style, ...res2.style]
            };
            break;
        }
        default:
            break;
    }
    return res;
};

const makeEquidistantLines = (region: BBox, boxes: BBox[], direction: string) => {
    const boxPositions = categoriseBoxPositions(boxes, region, direction);
    const { distances, style: spacingStyles } = getDistancesAndSpacingStyles(boxPositions, direction),
        // $FlowFixMe
        isEqualDistances = distances.map(() => true),
        // $FlowFixMe
        isHideDistanceLines = distances.map(() => false);
    return {
        distances,
        spacingStyles,
        labelStyles: [],
        lineStyles: [],
        isEqualDistances,
        isHideDistanceLines,
    };
};

export {
    makeEquidistantLines
};
