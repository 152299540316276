import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "../../../../view/intl/index";
import styles from "../../../oneweb/Text/expandedPanel/ExpandedPanelText.css";
import HeaderFilterView from "../../configs/Header";
import { makeEpicStateSelector } from "../../../../epics/makeEpic";
import { SET_CONTAINERS_FILTER } from "../../epics/containers/actionTypes";
import CONTAINERS_FILTER_VALUE_ACTION_TYPE from "../../epics/containers/valueActionType";
import { CONTAINERS_FILTERS } from "./constants";

const Header = injectIntl(({ intl, filterKey, dispatch }) => {
    const props = {
        data: CONTAINERS_FILTERS,
        title: intl.msgJoint('msg: extendedPanel.containersTitle {Containers}'),
        selected: filterKey,
        actionName: SET_CONTAINERS_FILTER,
        dispatch,
        className: styles.textFilters,
        hasError: false
    };

    return <HeaderFilterView {...props} />;
});
const mapStateToProps = (appState) => ({
    filterKey: (makeEpicStateSelector(CONTAINERS_FILTER_VALUE_ACTION_TYPE)(appState)).filterKey
});

export const HeaderView = connect(mapStateToProps)(Header);
