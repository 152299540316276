import React from 'react';
import { MctaPropertyContainer, MctaCombobox } from '../../../componentMainActions/index';
import { intlCreateOptions } from '../../../../view/common/Combobox/utils';
import { injectIntl } from '../../../../view/intl/index';
import { LightTheme, Themes } from './constants';
import { WIDGETS_TWITTER_TIMELINE_SET_THEME, WIDGETS_TWITTER_TIMELINE_SHOW_UPDATE_LINK_DIALOG } from './actionTypes';
import { ctaOnClickFactory } from '../utils';
import { getTweetLink } from "./utils/getTweetHTMLFromCode";
import styles from '../view/Widgets.css';

const EditView = injectIntl(props => {
    const { selectedComponent: { theme }, intl, dispatch } = props;
    return (
        <MctaPropertyContainer label="msg: widgets.twitterTimeline.cma.theme {Theme}">
            <MctaCombobox
                searchable={false}
                options={intlCreateOptions(Themes, intl)}
                useOptionsAsIs
                value={theme || LightTheme}
                className={styles.ctaTheme}
                onChange={({ value: theme }) => {
                    dispatch({ type: WIDGETS_TWITTER_TIMELINE_SET_THEME, payload: theme });
                }}
            />
        </MctaPropertyContainer>
    );
});

const CtaButtonTextView = injectIntl((props) => {
    const { selectedComponent, intl } = props;
    if (selectedComponent.timelineLink) {
        return intl.msgJoint("msg: widgets.twitterTimline.cta.changeTimeline {Change timeline}");
    } else if (selectedComponent.listLink) {
        return intl.msgJoint("msg: widgets.twitterTimline.cta.changeList {Change list}");
    } else if (selectedComponent.tweetHTML) {
        return intl.msgJoint("msg: widgets.twitterTimline.cta.changeTweet {Change post}");
    }

    return intl.msgJoint("msg: widgets.cta.changeWidget {Change widget}");
});

const componentMainActions = {
    editView: EditView,
    editButtonText: 'msg: common.settings {Settings}',
    CtaButtonTextView,
    ctaOnClick: ctaOnClickFactory(
        WIDGETS_TWITTER_TIMELINE_SHOW_UPDATE_LINK_DIALOG,
        (component) => {
            const { timelineLink, listLink, tweetHTML } = component;

            if (timelineLink) {
                return { link: timelineLink };
            } else if (listLink) {
                return { link: listLink };
            } else if (tweetHTML) {
                return { link: getTweetLink(tweetHTML) };
            }

            return { link: "" };
        }
    ),
};

export { componentMainActions };

