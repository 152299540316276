import * as R from 'ramda';
import {
    lastClickedTimeL
} from "./lenses";
import Video from "../../../oneweb/Video/kind";
import { receiveOnly } from '../../../../epics/makeCondition';
import { DOUBLE_CLICK_THRESHOLD } from '../../../../constants/app';
import styleSheetsValueActionType from "../stylesheets/valueActionType";
import { ANIMATION_FRAME } from "../../../../redux/middleware/raf";
import componentsRegistry from '../../../../view/oneweb/registry/index';
import workspaceBBoxEpicValueActionType from "../workspaceBBox/valueActionType";
import * as workspaceActionTypes from "../../actionTypes";
import { setSelectedComponentsIds } from './selectedComponentsIds';
import { getDefaultReducerState } from '../../../../redux/makeReducer/index';
import { PANEL_COMPONENT_SHORTCUT_CLICK } from '../../../Panel/actionTypes';
import { NEW_COMPONENT_DROPED_ON_WORKSPACE, CONTINUE_ADD_COMPONENT_AFTER_TIER_CHECK } from '../../../DndAddComponent/actionTypes';
import {
    COMPONENTS_ADD_COMPONENT,
    COMPONENT_REQUIRES_ADDITIONAL_CONFIGURATION,
    USER_INTERACTION_DONE_ON_COMPONENTS
} from './actionTypes';
import {
    COMPONENT_CONFIGURATION_COMPLETE,
    COMPONENT_CONFIGURATION_CANCELED
} from "../../../../redux/modules/children/workspace/actionTypes";
import isStretchComponentKind from '../../../oneweb/isStretchComponentKind';
import { openDialog } from "../../../App/actionCreators/index";
import * as updateReasons from './updateReasons';
import type { ComponentsEvalEpicUpdater } from './flowTypes';
import { ScrollValueActionType } from "../scroll/valueActionType";
import { ReceiveOnlyComponentsDependenciesSelector, componentsMapSelector } from "./selectorActionTypes";
import { addComponentAction } from "./actionCreators/index";
import ExtendedPanelVAT from "../../../Panel/epics/extendedPanel/valueActionType";
import isTestEnv from "../../../../debug/isTestEnv";
import sectionsBlocksValueActionType from "../../../Panel/epics/sectionsBlocks/valueActionType";
import { GLOBAL_STYLES_DIALOG_OPENED } from "../../../presentational/GlobalstyleDialogInvoker/actionTypes";
import { getCmps } from "../componentAttachements/util";
import {
    ReceiveOnlyTemplateWidthActionType,
    ReceiveOnlyTemplateSelectedThemeActionType
} from "../../../oneweb/Template/epics/template/selectorActionTypes";
import { depthSorter } from "../../../Preview/flattening/util";
import { globalVariablesEpic } from '../../../App/epics/globalVariablesEpic';
import { processSectionBlockComponents, adjustCmpIds } from './addComponentUtils';
import { updateComponentsRelIns } from "../relations/updateComponentsRelIns";
import { updateComponentsTheme } from "../../../ThemeGlobalData/utils/commonUtils";
import { isHoverBoxKind, isSectionKind, SECTION, GALLERY, IMAGE_SLIDER, WEB_SHOP,
    ProductWidgetKind, FeaturedProductsComponentKind } from "../../../oneweb/componentKinds";
import {
    getClosestSectionEndPoint,
    adjustSectionTopsAtPosition,
    removeEmptySpacesBetweenSections,
    getHeaderAndFooterSection, getFooterSection,
    isComponentBelowFooter,
    isFooterSection, isHeaderSection
} from "../../../oneweb/Section/utils";
import { getOnHoverShow } from "../../../oneweb/HoverBox/utils";
import { getSectionInsertion, getComponentsMap } from "./getters";
import { setSectionInsertionProgressState, setComponentsMap } from "./setters";
import { WORKSPACE_NEW_COMPONENT_ADDED, WORKSPACE_SCROLL_AND_SYNC, WORKSPACE_SCROLL } from "../../actionTypes";
import viewportDimensionsValueActionType from "../viewportDimensions/valueActionType";
import { workspaceComponentAttachDecorationsValueActionType } from "../componentAttachDecorations/valueActionType";
import { componentAttachmentsVAT } from "../componentAttachements/valueActionType";
import { NonTemplateComponents } from "../../../oneweb/Template/constants";
import { addMessage } from "../../../Toaster/actionCreators";
import { CANNOT_ADD_COMPONENT_TO_HEADER, CANNOT_ADD_COMPONENT_TO_FOOTER, CANNOT_ADD_TO_TEMPLATE } from "./messages";
import MessageTypes from "../../../Toaster/MessageTypes";
import { getSectionAtPosition } from '../isPageMode/utils';
import { fixSelectionOverlapWithSectionByMovingSelection } from "./userInteractionMutations/utils";
import { PASTE_OFFSET_WHEN_THERE_IS_NOT_ENOUGH_SECTION_HEIGHT } from './constants';
import {
    ROCodeComponentsRendererHeadHeightSelector
} from "../../CodeComponentsRenderer/epic/selectorActionTypes";
import { NEW_COMPONENT_DROPPED_IS_ADDED_TO_ATTACHMENTS } from "../componentAttachements/actionTypes";

import { NEW_COMPONENT_CHECK_COMPONENT_TIER_ON_DROP } from "../../../ComponentTierManager/actionTypes";
import { createNewComponent } from "./createNewComponent";
import {
    someComponentsPlacedInModernSection,
    tryToPlaceComponentInToNonModernSection,
    isComponentPlacedInHeader
} from "../../../ModernLayouts/utils";
import getAddComponentsNotAllowedAction from "./actionCreators/componentsAddNotAllowed";

const
    IsWrapCmpWithSectionKinds = [GALLERY, IMAGE_SLIDER, WEB_SHOP, ProductWidgetKind, FeaturedProductsComponentKind],
    resetComponentsAddedByClickCounter = R.evolve({
        scope: {
            addingComponent: {
                componentAddedByClickCounter: (() => 0)
            }
        }
    }),
    setComponentConfigurationProgress =
        (inProgress, data) => R.evolve({
            scope: {
                addingComponent: {
                    componentConfigurationInProgress: () => inProgress,
                    componentConfigurationData: () => data
                }
            }
        }),
    adjustSectionCmpsPositions = (
        sectionCmpsmap,
        sectionCmps,
        mainParentComponentId,
        templateWidth,
        sectionTemplateWidth
    ) => {
        if (sectionTemplateWidth === templateWidth) {
            return sectionCmpsmap;
        }
        let contentLeft = 5000, contentRight = 0;
        sectionCmps.forEach(cmp => {
            const { id, kind, left, width } = cmp;
            if (id !== mainParentComponentId && !isStretchComponentKind(kind)) {
                contentLeft = Math.round(Math.min(left, contentLeft));
                contentRight = Math.round(Math.max(left + width, contentRight));
            }
        });
        if (contentLeft === 0) {
            return sectionCmpsmap;
        }
        const contentWidth = contentRight - contentLeft,
            isContentCentered = contentLeft === (sectionTemplateWidth - contentRight);
        let leftDiff = 0;
        if (templateWidth > sectionTemplateWidth || contentWidth < templateWidth) {
            leftDiff = isContentCentered ?
                (((templateWidth - contentWidth) / 2) - contentLeft) :
                ((contentLeft * (templateWidth / sectionTemplateWidth)) - contentLeft);
            if (leftDiff === 0) {
                return sectionCmpsmap;
            }
            if ((contentWidth + contentLeft + leftDiff) > templateWidth) {
                leftDiff = leftDiff + (templateWidth - (contentWidth + contentLeft + leftDiff));
                if (Math.abs(leftDiff) > contentLeft) {
                    leftDiff = -1 * contentLeft;
                }
            }
        } else if (contentWidth >= templateWidth) {
            leftDiff = -1 * contentLeft;
        }
        return sectionCmps.reduce((acc, cmp) => {
            acc[cmp.id] = { ...cmp, left: cmp.left + leftDiff };
            return acc;
        }, {});
    },

    addNewSectionOrBlockWithChildren = (
        initialState,
        inPage,
        componentsDependencies,
        workspaceBBox,
        epicState,
        stylesheets,
        sectionsBlocksMap,
        templateWidth,
        globalVariables,
        templateSelectedTheme,
        attachments,
    ) => {
        const { sectionOrBlockId, top, topmostHandleKind, left } = initialState,
            { data } = sectionsBlocksMap[sectionOrBlockId];
        let newComponentsMap = data.componentsMap,
            mainParentContainerTemplateWidth = data.templateWidth,
            sectionOrBlockCmps = getCmps(newComponentsMap),
            mainParentComponentId = sectionOrBlockCmps.sort(depthSorter)[0].id,
            oldToNewIdsMap = {},
            newToOldIdsMap = {},
            { footer, header } = getHeaderAndFooterSection(epicState.state.componentsMap);

        if (!mainParentComponentId) {
            throw new Error('Main component is missing.');
        }

        const mainComponent = newComponentsMap[mainParentComponentId],
            isMainCmpSection = isStretchComponentKind(mainComponent.kind);

        if (isMainCmpSection) {
            newComponentsMap = adjustSectionCmpsPositions(
                newComponentsMap,
                sectionOrBlockCmps,
                mainParentComponentId,
                templateWidth,
                mainParentContainerTemplateWidth
            );
        }

        sectionOrBlockCmps = getCmps(newComponentsMap).sort(depthSorter);

        const { componentExtension, component } = createNewComponent(
            {
                ...mainComponent,
                kind: isMainCmpSection ? SECTION : mainComponent.kind,
                left: isMainCmpSection ? mainComponent.left : left,
                top
            },
            topmostHandleKind,
            epicState.state.componentsMap,
            inPage,
            componentsDependencies,
            true
        );
        oldToNewIdsMap[mainParentComponentId] = component.id;
        newToOldIdsMap[component.id] = mainParentComponentId;
        const newComponentId = component.id;

        let newEpicState = R.assocPath(['state', 'componentsMap', newComponentId], component, epicState);

        const
            componentsMap = componentsMapSelector(newEpicState);

        if (!isComponentBelowFooter(component, footer)) {
            const componentsMapWithFixedOverlaps = fixSelectionOverlapWithSectionByMovingSelection(
                [component.id],
                componentsMap,
                'selection-middle',
                true
            );
            newEpicState = setComponentsMap(componentsMapWithFixedOverlaps, newEpicState);
        }

        if (componentExtension) {
            newEpicState = R.assocPath(
                ['state', 'componentsMapExtension', newComponentId],
                componentExtension[newComponentId],
                epicState
            );
        }

        let { inProgress, placeholderPosition } = getSectionInsertion(epicState);
        let updateComponentMap = newEpicState.state.componentsMap;

        if (isMainCmpSection) {
            const section = component;

            if (!inProgress) {
                const { top, height } = section,
                    center = top + (height / 2);
                placeholderPosition = getClosestSectionEndPoint(center, R.omit(newComponentId, updateComponentMap));
            }
            if (!placeholderPosition || placeholderPosition <= 0) {
                placeholderPosition = header.height;
            }
            if (placeholderPosition > footer.top) {
                placeholderPosition = footer.top;
            }
            updateComponentMap = adjustSectionTopsAtPosition(
                placeholderPosition,
                section.height,
                R.omit([newComponentId], updateComponentMap),
                attachments
            );
            updateComponentMap = { ...updateComponentMap, [newComponentId]: { ...section, top: placeholderPosition } };
        }

        newEpicState = setComponentsMap(updateComponentMap, newEpicState);

        const topDelta = (inProgress ?
            updateComponentMap[newComponentId].top : newEpicState.state.componentsMap[newComponentId].top) - mainComponent.top;
        const leftDelta = (inProgress ?
            updateComponentMap[newComponentId].left : newEpicState.state.componentsMap[newComponentId].left) - mainComponent.left;

        const newCmpIds = [component.id];
        sectionOrBlockCmps.forEach(({ id }) => {
            if (mainParentComponentId !== id) {
                let { componentExtension, component } = createNewComponent(
                    {
                        ...newComponentsMap[id],
                        top: newComponentsMap[id].top + topDelta,
                        left: (newComponentsMap[id].left + leftDelta)
                    },
                    { ...initialState, kind: newComponentsMap[id].kind },
                    newEpicState.state.componentsMap,
                    inPage,
                    componentsDependencies,
                    true
                );
                oldToNewIdsMap[id] = component.id;
                newToOldIdsMap[component.id] = id;

                newEpicState = R.assocPath(
                    ['state', 'componentsMap', component.id],
                    component,
                    newEpicState
                );
                newCmpIds.push(component.id);

                if (componentExtension) {
                    newEpicState = R.assocPath(
                        ['state', 'componentsMapExtension', component.id],
                        componentExtension[component.id],
                        newEpicState
                    );
                }
            }
        });
        newEpicState.state.componentsMap = adjustCmpIds(newEpicState.state.componentsMap, newCmpIds, oldToNewIdsMap);
        newEpicState.state.componentsMap = processSectionBlockComponents(newEpicState.state.componentsMap, newCmpIds,
            stylesheets, globalVariables, componentsDependencies, templateWidth, templateSelectedTheme);
        newEpicState.state.componentsMap = removeEmptySpacesBetweenSections(attachments, newEpicState.state.componentsMap);
        return {
            newEpicState: setSectionInsertionProgressState(false, newEpicState),
            newComponent: newEpicState.state.componentsMap[component.id],
            newCmpIds,
            newComponentsMap,
            oldToNewIdsMap,
        };
    },
    getWorkspaceScrollAction = (newEpicState, newComponent, viewDimensions, scrollPosition) => {
        let scrollAction: Action | null = null;
        const { top: newSectionTop, height: newSectionHeight } = newEpicState.state.componentsMap[newComponent.id],
            newSectionBottom = newSectionTop + newSectionHeight,
            { height: viewHeight } = viewDimensions,
            { y: workspaceTop, x: workspaceLeft } = scrollPosition,
            workspaceBottom = workspaceTop + viewHeight;

        if (newSectionTop < workspaceTop || newSectionBottom > workspaceBottom) {
            const scrollTo = newSectionTop + ((newSectionHeight / 2) - (viewHeight / 2));
            scrollAction = {
                type: WORKSPACE_SCROLL_AND_SYNC,
                payload: {
                    y: scrollTo,
                    x: workspaceLeft
                }
            };
        }
        return scrollAction;
    },
    addNewComponent = (
        initialState,
        inPage,
        componentsDependencies,
        workspaceBBox,
        epicState,
        stylesheets,
        sectionsBlocksMap,
        templateWidth,
        globalVariables,
        templateSelectedTheme,
        scrollPosition,
        viewDimensions,
        attachments
    ) => {
        const { sectionOrBlockId, topmostHandleKind, isWrapWithSection } = initialState;
        if (!isTestEnv() && sectionOrBlockId) {
            const {
                    newEpicState,
                    newComponent,
                    newCmpIds,
                    newComponentsMap,
                    oldToNewIdsMap
                } = addNewSectionOrBlockWithChildren(
                    initialState,
                    inPage,
                    componentsDependencies,
                    workspaceBBox,
                    epicState,
                    stylesheets,
                    sectionsBlocksMap,
                    templateWidth,
                    globalVariables,
                    templateSelectedTheme,
                    attachments
                ),
                updatedComponentsMap = getComponentsMap(newEpicState);

            if (newCmpIds.length && someComponentsPlacedInModernSection(newCmpIds, updatedComponentsMap)) {
                const placedInHeader = isComponentPlacedInHeader(newComponent, updatedComponentsMap);
                return {
                    state: epicState,
                    actionToDispatch: getAddComponentsNotAllowedAction([{
                        componentKind: newComponent.kind,
                        message: placedInHeader ? CANNOT_ADD_COMPONENT_TO_HEADER : CANNOT_ADD_COMPONENT_TO_FOOTER,
                        showToaster: true
                    }]),
                    updateReason: updateReasons.CHANGE_SCOPE
                };
            }

            const multipleActionsToDispatch: Array<Action> = [
                {
                    type: workspaceActionTypes.WORKSPACE_NEW_COMPONENT_ADDED,
                    payload: {
                        id: newComponent.id,
                        component: newComponent,
                        newCmpIds
                    }
                },
                {
                    type: workspaceActionTypes.WORKSPACE_NEW_SECTION_OR_BLOCK_INSERTED,
                    payload: {
                        componentsId: newCmpIds,
                        newComponentsMap,
                        oldToNewIdsMap,
                        parentCmpId: newComponent.id,
                        sectionTitle: initialState.key
                    }
                }
            ];
            const workspaceScrollAction = getWorkspaceScrollAction(newEpicState, newComponent, viewDimensions, scrollPosition);
            if (workspaceScrollAction) {
                multipleActionsToDispatch.push(workspaceScrollAction);
            }
            return {
                state: setSelectedComponentsIds([newComponent.id], newEpicState),
                multipleActionsToDispatch,
                updateReason: updateReasons.COMPONENT_ADDED
            };
        }

        const multipleActionsToDispatch: Array<Action> = [];
        let newEpicState = epicState, component: Record<string, any> = {}, componentExtension;
        let finalInitialState = R.omit(['topmostHandleKind', 'isWrapWithSection'], initialState);
        let blankSectionCmp;
        if (!isTestEnv() && isWrapWithSection) {
            const blankSectionInfo = addNewSectionOrBlockWithChildren(
                {
                    ...finalInitialState,
                    kind: "SECTION",
                    sectionOrBlockId: "blank",
                },
                inPage,
                componentsDependencies,
                workspaceBBox,
                epicState,
                stylesheets,
                sectionsBlocksMap,
                templateWidth,
                globalVariables,
                templateSelectedTheme,
                attachments
            );
            newEpicState = blankSectionInfo.newEpicState;
            blankSectionCmp = blankSectionInfo.newComponent;
            const workspaceScrollAction = getWorkspaceScrollAction(newEpicState, blankSectionCmp, viewDimensions, scrollPosition);
            if (workspaceScrollAction) {
                multipleActionsToDispatch.push(workspaceScrollAction);
            }
            finalInitialState = { ...finalInitialState,
                top: blankSectionCmp.top };
        }
        const componentInfo = createNewComponent(
            finalInitialState,
            topmostHandleKind,
            newEpicState.state.componentsMap,
            inPage,
            componentsDependencies
        );
        component = componentInfo.component;
        if (isWrapWithSection && blankSectionCmp && component.height > blankSectionCmp.height) {
            newEpicState = R.assocPath(['state', 'componentsMap', blankSectionCmp.id, 'height'], component.height, newEpicState);
        }
        newEpicState.state.componentsMap = removeEmptySpacesBetweenSections(attachments, newEpicState.state.componentsMap);
        const componentsMap = getComponentsMap(newEpicState);
        component =
            tryToPlaceComponentInToNonModernSection(componentInfo.component, componentsMap, 10, scrollPosition, viewDimensions.height);
        const newComponentsMap = { ...componentsMap, [component.id]: component };
        if (someComponentsPlacedInModernSection([component.id], newComponentsMap)) {
            const placedInHeader = isComponentPlacedInHeader(component.id, newComponentsMap);
            return {
                state: epicState,
                actionToDispatch: getAddComponentsNotAllowedAction([{
                    componentKind: component.kind,
                    message: placedInHeader ? CANNOT_ADD_COMPONENT_TO_HEADER : CANNOT_ADD_COMPONENT_TO_FOOTER,
                    showToaster: true
                }]),
                updateReason: updateReasons.CHANGE_SCOPE
            };
        }

        componentExtension = componentInfo.componentExtension;
        newEpicState = R.pipe(
            setComponentsMap(newComponentsMap),
            componentExtension ?
                R.assocPath(
                    ['state', 'componentsMapExtension', component.id],
                    componentExtension[component.id]
                ) : R.identity,
            setSelectedComponentsIds([component.id])
        )(newEpicState);

        const footer = getFooterSection(componentsMap);
        if (!isComponentBelowFooter(component, footer)) {
            const componentsMapWithFixedOverlaps = fixSelectionOverlapWithSectionByMovingSelection(
                [component.id],
                componentsMapSelector(newEpicState), 'selection-middle', false, true
            );
            newEpicState = setComponentsMap(componentsMapWithFixedOverlaps, newEpicState);
        }

        newEpicState = R.assocPath(
            ['state', 'componentsMap'],
            updateComponentsRelIns(newEpicState.state.componentsMap, templateWidth),
            newEpicState
        );

        newEpicState = R.assocPath(
            ['state', 'componentsMap'],
            updateComponentsTheme({
                componentsMap: newEpicState.state.componentsMap,
                cmpIds: [component.id],
                templateSelectedTheme,
                componentsDependencies
            }),
            newEpicState
        );

        multipleActionsToDispatch.push({
            type: workspaceActionTypes.WORKSPACE_NEW_COMPONENT_ADDED,
            payload: {
                id: component.id,
                component: componentsMapSelector(newEpicState)[component.id]
            }
        });

        return {
            state: newEpicState,
            multipleActionsToDispatch,
            updateReason: updateReasons.COMPONENT_ADDED
        };
    },
    validateAddComponent = (cmpInitialState, epicState) => {
        let newComponentsMap = getComponentsMap(epicState);
        const { kind, top, isWrapWithSection } = cmpInitialState,
            record = componentsRegistry[kind],
            component = { ...getDefaultReducerState(record.reducer), top };

        if (!isWrapWithSection && NonTemplateComponents[component.kind]) {
            const { header, footer } = getHeaderAndFooterSection(newComponentsMap),
                { top: componentTop, height: componentHeight } = component,
                componentCenter = componentTop + (componentHeight / 2),
                componentBottom = componentTop + componentHeight;

            let cantDrop = header && componentTop < header.height && componentCenter < header.height;
            cantDrop = cantDrop || (footer && componentBottom > footer.top && componentCenter > footer.top);

            if (cantDrop) {
                return {
                    state: epicState,
                    actionToDispatch: addMessage({
                        type: MessageTypes.INFO,
                        text: CANNOT_ADD_TO_TEMPLATE
                    }),
                    updateReason: updateReasons.CHANGE_SCOPE,
                };
            }
        }
        return null;
    };

export const
    onComponentTierChecking = {
        conditions: [
            COMPONENTS_ADD_COMPONENT,
            receiveOnly(ExtendedPanelVAT),
        ],
        // $FlowFixMe
        reducer: ({ state, values: [initialState, { componentConfig }] }) => {
            let actionToDispatch;
            const
                cmpInitialState = { ...initialState, ...componentConfig },
                { kind } = cmpInitialState;

            const invalidProcess = validateAddComponent(cmpInitialState, state);

            if (invalidProcess) {
                return invalidProcess;
            } else {
                actionToDispatch = {
                    type: NEW_COMPONENT_CHECK_COMPONENT_TIER_ON_DROP,
                    payload: {
                        kind
                    }
                };
            }

            return {
                state,
                actionToDispatch
            };
        }
    },
    onAddComponentUpdater: ComponentsEvalEpicUpdater = {
        conditions: [
            CONTINUE_ADD_COMPONENT_AFTER_TIER_CHECK,
            receiveOnly(COMPONENTS_ADD_COMPONENT),
            receiveOnly(ExtendedPanelVAT),
            receiveOnly(workspaceComponentAttachDecorationsValueActionType),
            ReceiveOnlyComponentsDependenciesSelector,
            receiveOnly(workspaceBBoxEpicValueActionType),
            receiveOnly(styleSheetsValueActionType),
            receiveOnly(sectionsBlocksValueActionType),
            ReceiveOnlyTemplateWidthActionType,
            ReceiveOnlyTemplateSelectedThemeActionType,
            receiveOnly(globalVariablesEpic.valueActionType),
            receiveOnly(ScrollValueActionType),
            receiveOnly(viewportDimensionsValueActionType),
            receiveOnly(componentAttachmentsVAT)
        ],
        // @ts-ignore
        reducer: ({
            values: [, initialState, { componentConfig }, { cmpId: parentContainerId }, componentsDependencies,
                workspaceBBox, stylesheets, { sectionsBlocksMap }, templateWidth, templateSelectedTheme,
                globalVariables, scrollPosition, viewDimensions, { attachments }], state: epicState
        }) => {
            const
                componentsMap = getComponentsMap(epicState),
                inPage = !parentContainerId || !componentsMap[parentContainerId].inTemplate,
                cmpInitialState = { ...initialState, ...componentConfig },
                { kind, dialogProps = {} } = cmpInitialState,
                record = componentsRegistry[kind];

            if (typeof record.requireConfigurationOnDrop === 'function' &&
                record.requireConfigurationOnDrop(componentConfig, componentsDependencies[kind])) {
                const
                    newState = setComponentConfigurationProgress(true, cmpInitialState)(epicState),
                    nextAction = {
                        type: COMPONENT_REQUIRES_ADDITIONAL_CONFIGURATION,
                        payload: { componentKind: kind, dialogProps }
                    };

                return {
                    state: newState,
                    actionToDispatch: nextAction,
                    updateReason: updateReasons.CHANGE_SCOPE
                };
            }

            return addNewComponent(cmpInitialState, inPage, componentsDependencies, workspaceBBox,
                epicState, stylesheets, sectionsBlocksMap, templateWidth, globalVariables, templateSelectedTheme,
                scrollPosition, viewDimensions, attachments);
        }
    },
    onAddSectionStartUpdater: ComponentsEvalEpicUpdater = {
        conditions: [workspaceActionTypes.WORKSPACE_NEW_SECTION_OR_BLOCK_INSERTED],
        reducer: ({ values: [payload], state: epicState }) => {
            return {
                state: {
                    state: epicState.state,
                    scope: {
                        ...(epicState.scope || {}),
                        inserterSectionOrBlockData: { ...payload }
                    }
                },
                updateReason: updateReasons.CHANGE_SCOPE
            };
        }
    },
    flushSectionDataUpdaters: Array<ComponentsEvalEpicUpdater> = [
        GLOBAL_STYLES_DIALOG_OPENED,
        USER_INTERACTION_DONE_ON_COMPONENTS
    ].map(action => ({
        conditions: [action],
        reducer: ({ state: epicState }) => {
            if (epicState && epicState.scope && epicState.scope.inserterSectionOrBlockData) {
                return {
                    state: {
                        state: epicState.state,
                        scope: {
                            ...epicState.scope,
                            inserterSectionOrBlockData: null
                        }
                    },
                    updateReason: updateReasons.CHANGE_SCOPE
                };
            }
            return { state: epicState };
        }
    })),
    onAddComponentDropUpdater: ComponentsEvalEpicUpdater = {
        conditions: [NEW_COMPONENT_DROPED_ON_WORKSPACE],
        reducer: ({ values: [{ kind, position: { x, y }, topmostHandleKind, sectionOrBlockId }], state }) => {
            return {
                state,
                actionToDispatch: addComponentAction({
                    kind,
                    left: x,
                    top: y,
                    topmostHandleKind,
                    sectionOrBlockId,
                })
            };
        }
    },
    addByShortcutDblClickUpdater: ComponentsEvalEpicUpdater = {
        conditions: [
            receiveOnly(ScrollValueActionType),
            PANEL_COMPONENT_SHORTCUT_CLICK,
            receiveOnly(ExtendedPanelVAT),
            receiveOnly(ANIMATION_FRAME),
            receiveOnly(viewportDimensionsValueActionType),
            ReceiveOnlyTemplateWidthActionType,
            receiveOnly(sectionsBlocksValueActionType),
            ROCodeComponentsRendererHeadHeightSelector,
        ],
        reducer: ({
            values: [
                { y: scrollTop, x: scrollLeft },
                { componentKind },
                { componentConfig },
                { ts },
                viewportDimensions,
                templateWidth,
                { blocksMap },
                codeComponentsRendererHeadHeight,
            ],
            state: epicState
        }) => {
            const lastClickedTime = R.view(lastClickedTimeL, epicState);
            if ((ts - lastClickedTime) < DOUBLE_CLICK_THRESHOLD) {
                return {
                    state: R.set(lastClickedTimeL, ts, epicState),
                    updateReason: updateReasons.CHANGE_SCOPE
                };
            }
            const record = componentsRegistry[componentKind];
            let { height: newComponentHeight, width: newComponentWidth } = getDefaultReducerState(record.reducer);

            if (componentConfig.height) {
                newComponentHeight = componentConfig.height;
            }
            if (componentConfig.width) {
                newComponentWidth = componentConfig.width;
            }

            const block = blocksMap[componentConfig.sectionOrBlockId];

            if (block) {
                newComponentHeight = block.height;
                newComponentWidth = block.width;
            }

            const newCompLeft = isStretchComponentKind(componentKind)
                ? 0
                : (templateWidth - newComponentWidth) / 2;

            const middleOfViewportY = scrollTop + (viewportDimensions.height / 2);
            let newComponentsMap = componentsMapSelector(epicState);
            const section = getSectionAtPosition({ y: middleOfViewportY, x: 0 }, newComponentsMap);

            let newCompTop = Math.round(scrollTop + (viewportDimensions.height / 2) - (newComponentHeight / 2));

            const multipleActionsToDispatch: Array<Action> = [];
            if (section) {
                if (isSectionKind(componentKind)) {
                    const sectionTop = section.top;
                    const sectionBottom = section.top + section.height;
                    if (isHeaderSection(section)) {
                        newCompTop = section.height;
                    } else if (isFooterSection(section)) {
                        newCompTop = section.top;
                    } else if ((newCompTop - sectionTop) < (sectionBottom - newCompTop)) {
                        newCompTop = sectionTop;
                    } else {
                        newCompTop = sectionBottom;
                    }
                    newCompTop = section.top + ((section.height - newComponentHeight) / 2);
                } else if (section.height < newComponentHeight) {
                    newCompTop = section.top - (newComponentHeight / 2)
                        + PASTE_OFFSET_WHEN_THERE_IS_NOT_ENOUGH_SECTION_HEIGHT; // section expansion and selection alignment will be handled later on in flow
                    const { header } = getHeaderAndFooterSection(newComponentsMap);
                    newCompTop = newCompTop < header.height ? header.height : newCompTop;
                } else if (newCompTop < section.top) {
                    newCompTop = section.top + 10;
                }
            }
            if (newCompTop < scrollTop ||
                ((newCompTop + newComponentHeight + codeComponentsRendererHeadHeight) > (scrollTop + viewportDimensions.height))) {
                const scrollToY = newCompTop + ((newComponentHeight / 2) - (viewportDimensions.height / 2))
                    + codeComponentsRendererHeadHeight;
                multipleActionsToDispatch.push({
                    type: WORKSPACE_SCROLL_AND_SYNC,
                    payload: {
                        y: scrollToY,
                        x: scrollLeft
                    }
                });
            }

            multipleActionsToDispatch.push(addComponentAction({
                kind: componentKind,
                left: newCompLeft,
                top: newCompTop,
                isWrapWithSection: IsWrapCmpWithSectionKinds.includes(componentKind),
                ...componentConfig
            }));

            return {
                state: R.pipe(
                    setComponentsMap(newComponentsMap),
                    R.set(lastClickedTimeL, ts),
                )(epicState),
                multipleActionsToDispatch,
                updateReason: updateReasons.CHANGE_SCOPE
            };
        }
    },
    onComponentReqiresAdditionalConfiguration: ComponentsEvalEpicUpdater = {
        conditions: [COMPONENT_REQUIRES_ADDITIONAL_CONFIGURATION, ReceiveOnlyComponentsDependenciesSelector],
        reducer: ({ values: [{ componentKind, dialogProps: dProps = {} }, componentsDependencies], state }) => {
            const
                configDialog = componentKind === Video ?
                    componentsRegistry[componentKind].configurationDialog(dProps) :
                    componentsRegistry[componentKind].configurationDialog,
                { dialogId, dialogProps } = typeof configDialog === 'string'
                    ? { dialogId: configDialog, dialogProps: {} }
                    : configDialog,
                dependencies = componentsDependencies[componentKind];

            return {
                state,
                actionToDispatch: openDialog(dialogId, { ...dialogProps, ...dProps, dependencies }),
            };
        }
    },
    onComponentConfigurationComplete: ComponentsEvalEpicUpdater = {
        conditions: [
            COMPONENT_CONFIGURATION_COMPLETE,
            receiveOnly(workspaceComponentAttachDecorationsValueActionType),
            ReceiveOnlyComponentsDependenciesSelector,
            receiveOnly(workspaceBBoxEpicValueActionType),
            receiveOnly(styleSheetsValueActionType),
            receiveOnly(sectionsBlocksValueActionType),
            ReceiveOnlyTemplateWidthActionType,
            ReceiveOnlyTemplateSelectedThemeActionType,
            receiveOnly(globalVariablesEpic.valueActionType),
            receiveOnly(ScrollValueActionType),
            receiveOnly(viewportDimensionsValueActionType),
            receiveOnly(componentAttachmentsVAT),
        ],
        // @ts-ignore
        reducer: ({
            values: [
                additionalConfiguration,
                { cmpId: parentContainerId },
                componentsDependencies,
                workspaceBBox,
                stylesheets,
                { sectionsBlocksMap },
                templateWidth,
                templateSelectedTheme,
                globalVariables,
                scrollPosition,
                viewDimensions,
                { attachments }
            ],
            state: epicState
        }) => {
            const
                componentsMap = getComponentsMap(epicState),
                inPage = !parentContainerId || !componentsMap[parentContainerId].inTemplate,
                initialState = {
                    ...epicState.scope.addingComponent.componentConfigurationData,
                    ...additionalConfiguration
                },
                nextState = setComponentConfigurationProgress(false, {})(epicState);

            if (!initialState.kind) {
                return {
                    state: epicState,
                    actionToDispatch: { type: COMPONENT_CONFIGURATION_CANCELED }
                };
            }

            return addNewComponent(initialState, inPage, componentsDependencies, workspaceBBox,
                nextState, stylesheets, sectionsBlocksMap, templateWidth, globalVariables, templateSelectedTheme,
                scrollPosition, viewDimensions, attachments);
        }
    },
    onComponentConfigurationCanceled: ComponentsEvalEpicUpdater = {
        conditions: [COMPONENT_CONFIGURATION_CANCELED],
        reducer: ({ state: epicState }) => ({
            state: setComponentConfigurationProgress(false, {})(epicState),
            updateReason: updateReasons.CHANGE_SCOPE
        })
    },
    adjustInTemplateOnComponentAdded: ComponentsEvalEpicUpdater = {
        conditions: [
            WORKSPACE_NEW_COMPONENT_ADDED
        ],
        reducer: ({ values: [{ id }], state: epicState }) => {
            let componentsMap = getComponentsMap(epicState);
            const newComponent = componentsMap[id];
            const { header, footer } = getHeaderAndFooterSection(componentsMap);

            if (isSectionKind(newComponent.kind) || !header || !footer) return { state: epicState };

            const isInSideSection = section => section.top <= newComponent.top
                && (section.top + section.height) >= (newComponent.top + newComponent.height);
            const inTemplate = isInSideSection(header) || isInSideSection(footer);
            if (inTemplate) {
                componentsMap[id] = {
                    ...newComponent,
                    inTemplate
                };
                return {
                    state: R.pipe(
                        setComponentsMap(componentsMap)
                    )(epicState),
                    updateReason: updateReasons.MOVED_TO_TEMPLATE
                };
            }
            return { state: epicState };
        }
    },
    onWorkspaceScroll: ComponentsEvalEpicUpdater = {
        conditions: [
            WORKSPACE_SCROLL
        ],
        reducer: ({ state }) => {
            return {
                state: R.compose(
                    resetComponentsAddedByClickCounter
                )(state),
                updateReason: updateReasons.CHANGE_SCOPE
            };
        }
    },
    addCmpInHoverboxUpdater: ComponentsEvalEpicUpdater = {
        conditions: [
            receiveOnly(componentAttachmentsVAT),
            NEW_COMPONENT_DROPPED_IS_ADDED_TO_ATTACHMENTS
        ],
        reducer: ({ values: [{ attachments }, { newCmpIds }], state }) => {
            const componentsMap = getComponentsMap(state),
                id = newCmpIds[0];

            if (isHoverBoxKind(componentsMap[id].kind)) return { state };

            const onHoverShow = getOnHoverShow(id, attachments, componentsMap);

            if (onHoverShow === null) return { state };

            const newCmpsMap = { ...componentsMap };

            newCmpIds.forEach(id => {
                newCmpsMap[id] = {
                    ...newCmpsMap[id],
                    onHover: {
                        show: onHoverShow
                    }
                };
            });
            return {
                state: R.compose(
                    setComponentsMap(newCmpsMap)
                )(state),
                updateReason: updateReasons.PROPERTY_CHANGE
            };
        }
    };
