/* eslint-disable max-len */

import makeEpic from '../../../../../epics/makeEpic';
import {
    BUTTON_CHANGE_LINK,
    BUTTON_SET_LINK,
    BUTTON_PROP_PANEL_BUTTON_GLOBAL_STYLE_BTN_PRESSED
} from '../../actionTypes';
import openLinkChooserDialog from "../../../../../view/common/dialogs/LinkChooserDialog/actionCreators/openLinkChooserDialog";
import valueActionType from "./valueActionType";
import { ReceiveOnlySelectedComponentSelector } from "../../../../Workspace/epics/componentsEval/selectorActionTypes";
import gsKind from '../../globalStyle/kind';
import { openGlobalStylesDialogAC } from "../../../../presentational/GlobalstyleDialogInvoker/index";
import { contactFormButtonGlobalStyleIdSelector, styleGlobalIdSelector } from "../../selectors";
import { receiveOnly } from "../../../../../epics/makeCondition";
import { STYLESHEETS_EPIC_VALUE } from "../../../../Workspace/epics/stylesheets/valueActionType";
import { COLOR_THEME_SITE_SETTINGS_EPIC_VALUE } from "../../../../SiteSettings/ColorThemeData/colorThemeSiteSettingsVAT";
import { getFirstButtonStyleId } from "../../../../Workspace/epics/stylesheets/selectors";
import { ContactFormKind } from "../../../ContactForm/kind";

export default makeEpic({
    valueActionType,
    updaters: [
        {
            conditions: [
                ReceiveOnlySelectedComponentSelector,
                BUTTON_CHANGE_LINK
            ],
            reducer: ({ values: [selectedComponent] }) => ({
                state: null,
                actionToDispatch: openLinkChooserDialog({
                    setLinkAction: BUTTON_SET_LINK,
                    input: selectedComponent.linkAction
                })
            })
        },
        {
            conditions: [
                ReceiveOnlySelectedComponentSelector,
                receiveOnly(STYLESHEETS_EPIC_VALUE),
                receiveOnly(COLOR_THEME_SITE_SETTINGS_EPIC_VALUE),
                BUTTON_PROP_PANEL_BUTTON_GLOBAL_STYLE_BTN_PRESSED
            ],
            reducer: ({ values: [selectedComponent, stylesheets, { autoColorMode }] }) => {
                let globalStyleId;
                if (autoColorMode) {
                    globalStyleId = getFirstButtonStyleId(stylesheets);
                } else if (selectedComponent.kind === ContactFormKind) {
                    globalStyleId = contactFormButtonGlobalStyleIdSelector(selectedComponent);
                } else {
                    switch (selectedComponent.kind) {
                        case "WEBSHOP": globalStyleId = selectedComponent.buttonId;
                            break;
                        case "FEATURED_PRODUCTS":
                        case "PRODUCT_WIDGET": globalStyleId = selectedComponent.viewDetailsButtonId;
                            break;

                        case "CONTACTFORM": globalStyleId = selectedComponent.styleButton.globalId;
                            break;

                        default: globalStyleId = styleGlobalIdSelector(selectedComponent);
                    }
                }
                return {
                    state: null,
                    actionToDispatch: openGlobalStylesDialogAC(globalStyleId, gsKind)
                };
            }
        }
    ]
});
