import type { ImageComponent } from '../flowTypes';
import * as OnClickActions from "../../Gallery/constants/onClickActions";

export default function (component: ImageComponent, { payload: onClickAction }: any): ImageComponent {
    let changes;

    if (OnClickActions.OPEN_LIGHT_BOX === onClickAction) {
        changes = { lightBoxEnabled: true, openLink: false };
    } else if (OnClickActions.OPEN_LINK === onClickAction) {
        changes = { lightBoxEnabled: false, openLink: true };
    } else {
        changes = { lightBoxEnabled: false, openLink: false };
    }
    return {
        ...component,
        ...changes
    };
}
