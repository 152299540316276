export const ProductListStyles = {
    Default: null,
    Card: 'card'
};

export const ViewDetailsButtonPostions = {
    LEFT: {
        value: 'left',
        height: 435,
        width: 757,
    },
    RIGHT: {
        value: 'right',
        height: 435,
        width: 757,
    },
    BELOW: {
        value: 'bottom',
        height: 672,
        width: 461,
    }
};

export const CommonMapperKeys = {
    imageRatio: "imageRatio",
    cropImages: "cropImages",
    productsPerRow: "productsPerRow",
    productsPerRowInMobile: "productsPerRowInMobile",
    labelPosition: "labelPosition",
    productListStyle: "productListStyle",
    spacingItemsInMobile: "spacingItemsInMobile",
    productIds: "productIds",
    forceDesktopView: "forceDesktopView",
    timestamp: "timestamp",
    height: "height",
    widgetId: "widgetId",
    isMVEFocus: "isMVEFocus",
    shopComponentExists: "shopComponentExists",
    bodyBackground: "bodyBackground",
    viewDetailsShowButton: "viewDetailsShowButton",
    viewDetailsButtonText: "viewDetailsButtonText",
    viewDetailsButtonStyle: "viewDetailsButtonStyle",
    viewDetailsButtonPosition: "viewDetailsButtonPosition",
    viewDetailsButtonClassname: "viewDetailsButtonClassname",
    isSingleProduct: "isSingleProduct",
    isWebshopSetupDone: "isWebshopSetupDone",
    siteFonts: "siteFonts",
    autoColorMode: "autoColorMode",
    backgroundColor: "backgroundColor",
    fetchProductsByMessageEvent: "fetchProductsByMessageEvent",
    enableBuyNowButton: "enableBuyNowButton",
    buyNowButtonStyle: "buyNowButtonStyle",
    buyNowButtonClassName: "buyNowButtonClassName",
    buyNowButtonText: 'buyNowButtonText',
    promoRibbonBgColor: "promoRibbonBgColor",
    promoRibbonTextColor: "promoRibbonTextColor",
    promoRibbonBgColorTheme: "promoRibbonBgColorTheme",
    promoRibbonTextColorTheme: "promoRibbonTextColorTheme"
};

export const FeaturedProductsThemeMapperKeys = {
    fontColorTheme: "fontColorTheme",
    focusColorTheme: "focusColorTheme",
    hoverColorTheme: "hoverColorTheme",
    labelBgColorTheme: "labelBgColorTheme",
    labelTextColorTheme: "labelTextColorTheme",
    promoRibbonBgColorTheme: "promoRibbonBgColorTheme",
    promoRibbonTextColorTheme: "promoRibbonTextColorTheme"
};

export const MIN_DIMENSIONS = { width: 675, height: 400 }; // width is for 3 products
export const WIDTH_ONE_PRODUCT = 225;
