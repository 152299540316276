import * as Actions from '../../actionTypes';
import {
    SITE_DATA_UPDATE_FAILURE,
} from "../../../App/actionTypes";

export const ImportTemplateErrorActions = [
    Actions.COPY_REPOSITORY_TEMPLATE_FAILURE,
    Actions.PUT_NEW_STYLESHEET_FAILURE,
    Actions.PUT_NEW_TEMPLATE_FAILURE,
    SITE_DATA_UPDATE_FAILURE,
    // Actions.GET_REPOSITORY_PAGE_FAILURE
    Actions.GET_DESIGN_PAGE_FAILURE,
    Actions.GET_DESIGN_LOCALIZATIONS_FAILURE
];
