import u from 'updeep';
import defaultState from "../defaultState";
import type { FileManagerState } from "../flowTypes";

export default (state: FileManagerState, { payload: { state: newState = {} } }: Action<any>) => (
    u(newState, {
        ...state,
        ...defaultState,
        activeTabId: state.activeTabId || defaultState.activeTabId,
        ghostResources: state.ghostResources ? state.ghostResources : [],
        paidShutterstock: {
            ...state.paidShutterstock,
            mctaDisabled: state.paidShutterstock.mctaDisabled
        }
    }));
