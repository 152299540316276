import React from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import styles from './AddPageButton.css';
import { openNewPageDialog } from "../NewPageDialog/actionCreators";
import { Msg, useIntl } from "../../../view/intl";
import { NewPageTab } from "../NewPageDialog/constants";
import { Flex } from "../../../view/reflexbox";

type Props = {
    containerClassName?: string;
};

export default (props: Props) => {
    const { containerClassName } = props;
    const dispatch = useDispatch();
    const intl = useIntl();

    return (
        <div className={containerClassName}>
            <Flex
                align="center"
                justify="center"
                className={
                    cx(
                        styles.container,
                        styles.addPage,
                        styles.blueBg
                    )
                }
                onClick={() => dispatch(openNewPageDialog())}
            >
                <div className={cx(
                    styles.icon,
                    styles.plusIcon

                )}
                />
                <Msg
                    k="addNewPage"
                    className={
                        cx(
                            styles.label,
                            styles.addPageIcn,
                        )
                    }
                >
                    Add new page
                </Msg>
            </Flex>
            <Flex
                align="center"
                justify="center"
                data-title={intl.msgJoint("msg: addNewLink {Add link to site menu}")}
                data-title-position="right"
                className={cx(styles.container, styles.addLink)}
                onClick={() => dispatch(openNewPageDialog(true, NewPageTab.SECTION_LINK))}
            >
                <span className={cx(
                    styles.linkIcnCommon,
                    styles.linkIcn
                )}
                />
            </Flex>
        </div>
    );
};
