import Node from '../Node';
import type { Element } from '../../../flowTypes';

export type BreakNodeArgs = {
    element: Element,
    parent?: Node
};

export default (args: BreakNodeArgs): Node => {
    const {
        element,
        parent
    } = args;
    return new Node({
        atype: 'break',
        text: '',
        start: element.end,
        end: element.end,
        single: true,
        styles: {},
        tag: 'br',
        parent
    });
};
