import {
    LC_FILE_SELECT,
    _LC_RESET_CURRENT_RESULT_VALUE
} from "./actionTypes";
import {
    FC_RESET_SELECTION
} from "../../../../redux/modules/children/fileChooser/actionTypes";

export const
    linkChooserFileSelectAC = (payload) => ({
        type: LC_FILE_SELECT,
        payload,
    }),
    linkChooserValueResetAC = () => ({ type: _LC_RESET_CURRENT_RESULT_VALUE }),
    fileChooserSelectionResetAC = () => ({ type: FC_RESET_SELECTION });
