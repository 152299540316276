import type { Element } from '../../flowTypes';

const NEW_LINE = '\n';

const createParagraphElement = (options: Element): Element => {
    return {
        atype: 'para',
        ...options
    };
};

/**
 * Creates paragraphs from text
 */
export default (text: string): Array<Style> => {
    let txt = text;
    const result: Style[] = [];

    let currentIndex = 0;
    let newLineIndex = txt.indexOf(NEW_LINE);

    // until there are new lines
    while (newLineIndex >= 0) {
        const remainingText = txt.slice(newLineIndex + 1);

        result.push(createParagraphElement({
            start: currentIndex,
            end: currentIndex + newLineIndex
        }));

        txt = remainingText;
        currentIndex += newLineIndex + 1;
        newLineIndex = txt.indexOf(NEW_LINE);
    }

    // add last paragraph
    result.push(createParagraphElement({ start: currentIndex, end: currentIndex + txt.length }));
    return result;
};
