import { cmpCopy, cmpTypes } from "./constants";

export default (id: string) => {
    if (id.split(cmpCopy).length > 1) {
        return cmpTypes.copy;
    }
    if (id.split(cmpTypes.group).length > 1) {
        return cmpTypes.group;
    }
    return cmpTypes.component;
};
