import {
    IMAGE_EDITOR_ADD_TO_MY_IMAGES_CLICKED,
    IMAGE_EDITOR_CLOSE_EDITOR,
    IMAGE_EDITOR_REDO,
    IMAGE_EDITOR_SAVE_CLICKED,
    IMAGE_EDITOR_SHOW_PROCESSING_NEW_IMAGE_ERROR,
    IMAGE_EDITOR_UNDO,
    SAVE_EDITED_IMAGE
} from "../../../../components/ImageEditor/actionTypes";

export const
    dispatchImageEditorError = () => ({ type: IMAGE_EDITOR_SHOW_PROCESSING_NEW_IMAGE_ERROR }),
    saveClickedAC = () => ({ type: IMAGE_EDITOR_SAVE_CLICKED }),
    closeImageEditorAC = () => ({ type: IMAGE_EDITOR_CLOSE_EDITOR }),
    addToMyImagesClickedAC = () => ({ type: IMAGE_EDITOR_ADD_TO_MY_IMAGES_CLICKED }),
    imageEditorUndoAC = () => ({ type: IMAGE_EDITOR_UNDO }),
    imageEditorRedoAC = () => ({ type: IMAGE_EDITOR_REDO }),
    saveEditedImageAC = (payload: Object) => ({ type: SAVE_EDITED_IMAGE, payload });
