import { WIDGETS_BOOKINGS_SHOW_UPDATE_BOOKING_DIALOG } from './actionTypes';
import { ctaOnClickFactory, codeExtractor } from '../utils';

const componentMainActions = {
    editButtonText: 'msg: common.settings {Settings}',
    ctaButtonText: 'msg: widgets.bookings.cta.changeBookings {Change Booking}',
    ctaOnClick: ctaOnClickFactory(
        WIDGETS_BOOKINGS_SHOW_UPDATE_BOOKING_DIALOG,
        component => ({ link: codeExtractor(component) })
    ),
};

export { componentMainActions };

