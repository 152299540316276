import { WidgetsInstagramKind } from './kind';
import { componentMainActions } from './componentMainAction';
import { propertiesPanel } from './propertiesPanel/index';
import { reducer } from './reducer';
import { configurationDialogId } from './configurationDialog';
import { calcRenderProps } from './calcRenderProps';
import { InstagramWorkspace } from './view/workspace';
import { InstagramView } from './view/index';
import { getMVEHiddenElementData } from '../mobileEditorConfig';
import { WidgetsInstagramConfig } from '../constants';

const WidgetsInstagram = {
    kind: WidgetsInstagramKind,
    label: WidgetsInstagramConfig.title,
    shortcutIconName: 'widgetsInstagram',
    workspace: InstagramWorkspace,
    view: InstagramView,
    requireConfigurationOnDrop: () => true,
    configurationDialog: configurationDialogId,
    calcRenderProps,
    componentMainActions,
    propertiesPanel,
    reducer,
    mobileEditorConfig: {
        kind: WidgetsInstagramKind,
        calcProps: calcRenderProps,
        view: InstagramView,
        getHiddenElementData: getMVEHiddenElementData(WidgetsInstagramConfig),
    },
};

export { WidgetsInstagram };

