import React from "react";
export const SvgYoutubeCircleFillDark = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <circle cx={24} cy={24} r={24} fill="#3c3c3c" />
            <path
                fill="#ffffff"
                d="M36.154 18.782s-.243-1.718-.992-2.473c-.949-.993-2.01-.997-2.496-1.056C29.183 15 23.954 15 23.954 15h-.01s-5.23 0-8.713.253c-.486.059-1.547.063-2.495 1.056-.75.755-.988 2.473-.988 2.473s-.248 2.016-.248 4.036v1.889c0 2.015.248 4.035.248 4.035s.243 1.718.988 2.473c.948.993 2.194.959 2.748 1.066 1.995.19 8.47.248 8.47.248s5.234-.01 8.717-.258c.486-.058 1.547-.063 2.496-1.056.749-.755.992-2.473.992-2.473s.248-2.015.248-4.035v-1.89c-.005-2.014-.253-4.035-.253-4.035zM21.375 27v-7.005l6.728 3.515L21.375 27z"
            />
        </g>
    </svg>
);
