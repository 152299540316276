import type { InputValidationFunction } from '../flowTypes';
import { makeInputValidationResult } from './utils/index';

export type PhoneValidationErrorCode = 'TOO_SHORT' | 'TOO_LONG' | 'INVALID' | '';

const
    VISUAL_SEPARATORS = '-.()',
    PATTERN = `^\\+?\\d+(?:\\d|[${VISUAL_SEPARATORS}](?![${VISUAL_SEPARATORS}])|\\s(?!\\s))*\\d$`,
    PhoneNumberLengthRange = {
        MIN: 7,
        MAX: 15,
    },
    TEL_INVALID_MSG = 'msg: validation.tel.invalid {Sorry, looks like this is not a valid phone number.}',
    TOO_SHORT_MSG = 'msg: validation.tel.tooShort {Did you add the whole number? You need at least 7 digits.}';

const telInputValidation: InputValidationFunction<{ ignoreKeyword?: string }> = (val: string, options) => {
    const value = String(val).trim();
    if (options && options.ignoreKeyword && value === options.ignoreKeyword) {
        return makeInputValidationResult(true, "", "");
    }

    const isValid = RegExp(PATTERN).test(value),
        valueWithoutSeparators = value.replace(new RegExp(`[${VISUAL_SEPARATORS}\\s+]`, 'g'), '');
    if (!isValid) {
        return makeInputValidationResult(false, 'INVALID', TEL_INVALID_MSG);
    } else if (valueWithoutSeparators.length < PhoneNumberLengthRange.MIN) {
        return makeInputValidationResult(false, 'TOO_SHORT', TOO_SHORT_MSG);
    } else if (valueWithoutSeparators.length > PhoneNumberLengthRange.MAX) {
        return makeInputValidationResult(false, 'TOO_LONG', TEL_INVALID_MSG);
    } else {
        return makeInputValidationResult(true, "", "");
    }
};

export {
    PhoneNumberLengthRange,
    telInputValidation,
    TEL_INVALID_MSG,
};
