import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, TextField } from "@mui/material";
import cx from "classnames";

import { RenderCategoryOption } from "./RenderCategoryOption";
import {
    getGMBEntry,
    createGMBOption,
    getDefaultGMBOption,
    convertLabelAsPerLanguage
} from "./helpers";
import { NoMatchTag } from "./NoMatchTag";
import { FieldError } from "../Common/FieldError";

import { categoryErrorBannerSelector, gmbKeySelector, synonymsSelector } from "../../../Epic/selectors";
import {
    HIDE_CATEGORY_ERROR_BANNER,
    DYNAMIC_TEMPLATE_GMB_KEY_UPDATED,
    DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA
} from "../../../Epic/actionTypes";

import { useIntl } from "../../../../../../view/intl";

import stepStyles from "../steps.css";
import styles from "./styles.css";

import { TEXT_CHARACTER_LIMIT_ON_STEP_FIELD } from "../../../constants";
import { ErrorBanner } from "./ErrorBanner";
import { DynamicTemplateContext } from "../../Context";
import { searchInWebsiteCategories } from "./searchInWebsiteCategories";

type fieldProp = {
    value: string,
    label: string
}

type Props = {
    options: {
        value: string,
        label: string
    }[],
    showError: boolean,
    setShowError: (val) => void;
    showContinueAnyway?: boolean;
    setShowContinueAnyway?: (val) => void;
}

let prevSearch = "";
let wcSearchResults: Array<any> = [];

export const WebsiteCategorySelector = (props: Props) => {
    const { options, showError, setShowError, setShowContinueAnyway } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const gmbKey = useSelector(gmbKeySelector);
    const showErrorBanner = useSelector(categoryErrorBannerSelector);
    const synonyms = useSelector(synonymsSelector);
    const { shopDisabled } = useContext(DynamicTemplateContext);
    const counterLimit = TEXT_CHARACTER_LIMIT_ON_STEP_FIELD.gmbKey.max;
    const [focused, setFocused] = useState(false);

    const [inputField, setInputFieldValue] = useState<fieldProp>(getDefaultGMBOption(options, gmbKey));

    const gmbEntry = getGMBEntry(inputField.label, shopDisabled, intl);
    const [isNoMatch, setIsNoMatch] = useState(!gmbEntry);
    const exactMatch = inputField && !!gmbEntry;

    const filterOptions = (options, { inputValue }) => {
        if (!inputValue) return [];
        let inpVal = inputValue.trim().toLowerCase(),
            filteredOptions = [];
        if (prevSearch !== inpVal) {
            // eslint-disable-next-line max-len
            wcSearchResults = searchInWebsiteCategories({ options, inputValue: inputValue || inputField.label, websiteCategorySynonyms: synonyms });
            prevSearch = inpVal;
        }
        filteredOptions = convertLabelAsPerLanguage(wcSearchResults, intl);
        return filteredOptions;
    };

    const onCloseHandler = () => {
        dispatch({ type: HIDE_CATEGORY_ERROR_BANNER });
    };

    const showNoMatchTag = inputField.value && ((!focused && !exactMatch) || (isNoMatch && !showError));

    useEffect(() => {
        setShowContinueAnyway!(showNoMatchTag);
    }, [setShowContinueAnyway, showNoMatchTag]);

    return (
        <div className={styles.cateogryFieldContainer} data-testid="dynamic-onboarding-website-category-field">
            <div className={stepStyles.stepFieldContainer}>
                <div className={cx(stepStyles.stepFieldLabel, showError && stepStyles.errorLabel)}>
                    {intl.msgJoint("msg: onboarding.dynamic.step0.label {Category}")}
                </div>
                <Autocomplete
                    freeSolo
                    autoComplete
                    options={options}
                    filterOptions={filterOptions}
                    disableClearable
                    inputValue={inputField.label}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            error={showError}
                            /* eslint-disable max-len */
                            placeholder={inputField.value ? undefined : intl.msgJoint('msg: onboarding.dynamic.step0.placeholder {eg. café, construction company, consulting firm}')}
                        />
                    )}
                    getOptionLabel={(option) => {
                        return typeof option === 'string' ? option : option.label;
                    }}
                    renderOption={(props, option, { inputValue, index }) => {
                        const optionProps = {
                            props,
                            label: option.label,
                            value: inputValue,
                            indices: wcSearchResults[index].indices
                        };

                        return <RenderCategoryOption {...optionProps} />;
                    }}
                    classes={{
                        inputRoot: styles.autoCompleteInputRoot,
                        input: styles.autoCompleteInput,
                        endAdornment: styles.autoCompleteEndAdornment,
                        popper: styles.autoCompletePopper,
                        paper: cx(styles.autoCompletePaper, { [styles.displayNone]: !inputField.value }),
                        listbox: styles.autoCompleteListBox,
                        noOptions: styles.autoCompleteNoOptions
                    }}
                    onInputChange={(_e, _value) => {
                        let r = new RegExp(/[\^!@#$%*()_?.,|\\[\]]/);
                        if (_value.match(r)) {
                            return;
                        }

                        let value = _value;
                        if (!_value.trim().length) {
                            value = _value.trim();
                        }
                        if (value.length > counterLimit) {
                            setShowError(true);
                        } else {
                            setShowError(false);
                        }

                        const option = createGMBOption(value);
                        setInputFieldValue(option);

                        const filteredOptions = filterOptions(options, { inputValue: value || inputField.label });
                        if (filteredOptions.length) {
                            setIsNoMatch(false);
                        } else {
                            setIsNoMatch(true);
                        }

                        dispatch({ type: DYNAMIC_TEMPLATE_GMB_KEY_UPDATED, payload: option.value });
                        dispatch({
                            type: DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA,
                            payload: { path: "gmb", value: { key: value, isTyping: true } }
                        });
                    }}
                    onChange={(_e, el) => {
                        const val = typeof el === 'string' ? el : el.value;

                        if (typeof el !== 'string') {
                            dispatch({ type: DYNAMIC_TEMPLATE_GMB_KEY_UPDATED, payload: val });
                            setInputFieldValue(el);
                        } else {
                            setInputFieldValue(getDefaultGMBOption(options, val));
                        }
                        dispatch({
                            type: DYNAMIC_TEMPLATE_UPDATE_ANIMATION_DATA,
                            payload: { path: "gmb", value: { key: val, isTyping: false } }
                        });
                    }}
                />
                {
                    showError && (
                        <FieldError
                            message="msg: common.error.tooLong {The text you entered is too long.}"
                            counter={{
                                limit: counterLimit,
                                current: inputField.value.length
                            }}
                        />
                    )
                }
            </div>
            <NoMatchTag searchText={inputField.value.replace(/_/g, " ")} hide={!showNoMatchTag} />
            <ErrorBanner
                onClose={onCloseHandler}
                show={showErrorBanner}
            />
        </div>
    );
};
