import * as R from 'ramda';

import type { InputState } from "../../../../../inputControls/input/flowTypes";
import type { PageInfoDialogState } from "../flowTypes";

const mapInputState = ({ value, isValid }: InputState): Record<string, any> => ({ value, isValid });

const map = ({ names, seo, socialShare, visibility, template, isHomePage }: PageInfoDialogState): Record<string, any> => ({
    names: {
        name: mapInputState(names.name),
        url: mapInputState(names.url)
    },
    seo: {
        ...seo,
        title: mapInputState(seo.title),
        customTitle: mapInputState(seo.customTitle),
    },
    socialShare,
    visibility,
    templateIsChanged: template.templateIsChanged,
    isHomePage
});

export const stateIsEqInitialComparator = (state: PageInfoDialogState, initialState: PageInfoDialogState): boolean =>
    R.equals(map(state), map(initialState));
