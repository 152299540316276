import makeEpic from '../../../../epics/makeEpic';
import { SVG_VAT } from "./valueActionType";
import {
    doesFillMakeAnyDifferenceAsync,
    getFillColorMapFromSvgJson,
    getMentionedColorsMapFromSvgJson,
    svgTextToSvgJsonAsync,
} from '../utils';
import getRawAssets from '../actionCreators/getRawAsset';
import * as actionTypes from "../actionTypes";
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from '../defaultState';
import { COMPONENT_CONFIGURATION_COMPLETE } from "../../../../redux/modules/children/workspace/actionTypes";
import { makeSvgChooserConfigDialog } from '../../../FileManager/makeSvgChooserConfigDialog';
import { makeActionForwardToSelectedComponent } from '../../../../redux/forwardTo';
import { MISSING_ASSET_REPLACE_IMAGE } from '../../../MissingAssets/actionTypes';
import { openDialog } from "../../../App/actionCreators/index";
import { COMPONENTS_MAP_REPLACE_MISSING_ASSETS } from "../../../Workspace/epics/componentsEval/actionTypes";
import { SVG_KIND } from '../kind';

const makeReplaceAssetReducer = actionToDispatch => ({ dispatchAsync, state, values: [{ payload: rawSvg, extraPayload: asset }] }) => {
    svgTextToSvgJsonAsync(rawSvg, { readThroughStyleAttributes: true }).then(svgJson => {
        doesFillMakeAnyDifferenceAsync(svgJson).then(doesFillMakeAnyDifference => {
            getMentionedColorsMapFromSvgJson(svgJson, { withProminence: true }).then(([, response]) => { // eslint-disable-line no-unused-vars
                const
                    fillColorMapFromSvgJson = doesFillMakeAnyDifference ? getFillColorMapFromSvgJson(svgJson) : {},
                    defaultFillColor = Object.keys(fillColorMapFromSvgJson)[0] || '';

                const arrOfColors
                    // @ts-ignore
                    = response.prominence
                        .slice(0, 100)
                        .filter(item => !Object.keys(fillColorMapFromSvgJson).includes(item.colorCode))
                        .map(item => item.colorCode);

                const
                    colors = [
                        ...Object.keys(fillColorMapFromSvgJson),
                        ...arrOfColors
                    ].map(c => ({ fromColor: c, toColor: c }));
                dispatchAsync({ type: actionToDispatch, payload: { asset, colors, defaultFillColor, rawSvg, svgJson } });
            });
        });
    });
    return { state };
};

export const svgEpic = makeEpic({
    valueActionType: SVG_VAT,
    defaultState: null,
    updaters: [{
        conditions: [actionTypes.DND_ADD_SVG],
        reducer: ({ state = {}, values: [component] }) => ({
            state: { ...state, ...component, kind: SVG_KIND },
            actionToDispatch: { type: actionTypes.SVG_SET_SVG, payload: { asset: component.asset } },
        }),
    }, {
        conditions: [actionTypes.SVG_SET_SVG],
        reducer: ({ state, values: [{ asset }] }) => ({
            state, actionToDispatch: getRawAssets(asset.url, actionTypes.SVG_RAW_ASSET_SUCCESS_FOR_SET_SVG, asset)
        }),
    }, {
        keepFullActions: true,
        conditions: [actionTypes.SVG_RAW_ASSET_SUCCESS_FOR_SET_SVG],
        reducer: ({ dispatchAsync, state, values: [{ payload: rawSvg, extraPayload: asset }] }) => {
            svgTextToSvgJsonAsync(rawSvg, { readThroughStyleAttributes: true }).then(svgJson => {
                doesFillMakeAnyDifferenceAsync(svgJson).then(doesFillMakeAnyDifference => {
                    getMentionedColorsMapFromSvgJson(svgJson, { withProminence: true }).then(([, response]) => { // eslint-disable-line no-unused-vars
                        const
                            height = asset.height > asset.width ? DEFAULT_HEIGHT : (DEFAULT_HEIGHT * (asset.height / asset.width)),
                            width = asset.width > asset.height ? DEFAULT_WIDTH : (DEFAULT_WIDTH * (asset.width / asset.height)),
                            fillColorMapFromSvgJson = doesFillMakeAnyDifference ? getFillColorMapFromSvgJson(svgJson) : {},
                            defaultFillColor = Object.keys(fillColorMapFromSvgJson)[0] || '';

                        const arrOfColors
                            // @ts-ignore
                            = response.prominence
                                .slice(0, 100)
                                .filter(item => !Object.keys(fillColorMapFromSvgJson).includes(item.colorCode))
                                .map(item => item.colorCode);

                        const
                            colors = [
                                ...Object.keys(fillColorMapFromSvgJson),
                                ...arrOfColors
                            ].map(c => ({ fromColor: c, toColor: c }));
                        dispatchAsync(({
                            type: COMPONENT_CONFIGURATION_COMPLETE,
                            payload: { ...state, asset, colors, defaultFillColor, height, width, rawSvg, svgJson },
                        } as AnyAction));
                    });
                });
            });
            return { state };
        },
    }, {
        conditions: [actionTypes.SVG_REPLACE_SVG],
        reducer: ({ state }) => {
            const { dialogId, dialogProps } = makeSvgChooserConfigDialog({
                onSaveAction: actionTypes.SVG_SELECTED_FOR_REPLACE,
                forwardToComponent: true,
            });
            return { state, actionToDispatch: openDialog(dialogId, dialogProps) };
        },
    }, {
        conditions: [actionTypes.SVG_SELECTED_FOR_REPLACE],
        reducer: ({ state, values: [{ asset }] }) => ({
            state, actionToDispatch: getRawAssets(asset.url, actionTypes.SVG_RAW_ASSET_SUCCESS_FOR_REPLACE_SVG, asset)
        }),
    }, {
        keepFullActions: true,
        conditions: [actionTypes.SVG_RAW_ASSET_SUCCESS_FOR_REPLACE_SVG],
        reducer: makeReplaceAssetReducer(actionTypes.SVG_RAW_ASSET_PROCESSED_FOR_REPLACE_SVG),
    }, {
        conditions: [actionTypes.SVG_RAW_ASSET_PROCESSED_FOR_REPLACE_SVG],
        reducer: ({ state, values: [payload] }) => ({
            state,
            multipleActionsToDispatch: [
                makeActionForwardToSelectedComponent({ type: actionTypes.SVG_EMPTY_SRC }),
                makeActionForwardToSelectedComponent(({
                    type: actionTypes.SVG_UPDATE_ON_REPLACE, payload, amendTo: actionTypes.SVG_EMPTY_SRC,
                } as AnyAction)),
                { type: MISSING_ASSET_REPLACE_IMAGE, payload: { asset: payload.asset } },
            ]
        }),
    }, {
        conditions: [COMPONENTS_MAP_REPLACE_MISSING_ASSETS],
        reducer: ({ dispatchAsync, state, values: [assetUrlMap] }) => {
            Object.keys(assetUrlMap).forEach(oldUrl => {
                if (oldUrl.slice(-3).toLowerCase() === 'svg') {
                    const newAsset = assetUrlMap[oldUrl];
                    dispatchAsync(getRawAssets(newAsset.url, actionTypes.SVG_RAW_ASSET_SUCCESS_FOR_MISSING_ASSETS, newAsset));
                }
            });
            return { state };
        },
    }, {
        keepFullActions: true,
        conditions: [actionTypes.SVG_RAW_ASSET_SUCCESS_FOR_MISSING_ASSETS],
        reducer: makeReplaceAssetReducer(actionTypes.SVG_UPDATE_ON_REPLACE_MISSING),
    }]
});
