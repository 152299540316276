import React from "react";
import * as R from "ramda";
import cx from "classnames";
import Measure from "react-measure";
import * as layoutType from "../../constants/layoutType";
import * as HORIZONTAL_ALIGN from "../../constants/horizontalAlign";
import * as VERTICAL_ALIGN from "../../constants/verticalAlign";
import { isMoreButtonModeSelector } from "../../selectors";
import memo from "../../../../../../utils/memo";
import type { LayoutRenderProps, MenuComponent } from "../../flowTypes";
import type { LayoutFactoryProps, MenuItemProps, RootProps } from "./flowTypes";
import * as path from "../../../../../mappers/path";
import getPageHref from "../../getPageHref";
import { DATA_PAGE_REF_TYPE, DATA_LINK_SECTION_TYPE } from "../../../../../../dal/model/utils/dataSiteItemTypes";
import { Dimensions } from "../../../../../redux/modules/children/workspace/flowTypes";
import { DataSiteItemId } from "../../../../../../dal/constants";

const eqByHorizontalAlign = R.propEq("horizontalAlign"),
    eqByVerticalAlign = R.propEq("verticalAlign"),
    aligmentClassPredicatesMap = {
        menuhorizontalleft: eqByHorizontalAlign(HORIZONTAL_ALIGN.LEFT),
        menuhorizontalcenter: eqByHorizontalAlign(HORIZONTAL_ALIGN.CENTER),
        menuhorizontalright: eqByHorizontalAlign(HORIZONTAL_ALIGN.RIGHT),
        horizontalalignfit: eqByHorizontalAlign(HORIZONTAL_ALIGN.FIT),
        menuverticalmiddle: eqByVerticalAlign(VERTICAL_ALIGN.MIDDLE),
        menuverticalbottom: eqByVerticalAlign(VERTICAL_ALIGN.BOTTOM)
    };

function aligmentMatchingClasses(component) {
    return R.filter(className => aligmentClassPredicatesMap[className](component), R.keys(aligmentClassPredicatesMap));
}

function getMenuClassName(component: MenuComponent, layoutTypeClassName: string, isMenuInModernLayout: boolean) {
    return cx(
        "menu",
        {
            moreEnabled: isMoreButtonModeSelector(component),
            modernLayoutMenu: isMenuInModernLayout
        },
        "menuself",
        layoutTypeClassName,
        ...aligmentMatchingClasses(component)
    );
}

const pageDefaults = {
        type: DATA_PAGE_REF_TYPE,
        url: "",
        isHome: false,
        hidden: false,
        public: true,
        isCurrentPage: false
    },
    moreId = "more-id",
    cartId = "cart-id",
    appendMoreButton = memo((moreText, pages) => {
        return [
            ...pages,
            {
                ...pageDefaults,
                id: moreId,
                name: moreText,
                pageId: "more-button-page-id",
                hasChildren: true,
                items: [{ ...pageDefaults, id: "more-child-id", name: "More child", pageId: "more-button-child-page-id", items: [] }]
            }
        ];
    }),
    appendCart = memo((pages) => {
        const cartSvg = (
            <svg id={cartId} data-version="2" focusable="false" style={{ width: '1em', height: '1em', transform: 'translate(0px, 20%) scale(1.4)' }} viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M3.79976 21.25L4.60333 8.75H19.3967L20.2002 21.25H3.79976Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    transform="matrix(6,0,0,6,0,0)"
                />
                <path
                    d="M15.5 11C15.5 6.89732 15.1411 3 12 3C8.85888 3 8.5 6.89732 8.5 11"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    transform="matrix(6,0,0,6,0,0)"
                />
            </svg>
        );
        return [
            ...pages,
            {
                ...pageDefaults,
                id: cartId,
                name: cartSvg,
                pageId: "cart-page-id",
                hasChildren: false,
                items: []
            }
        ];
    }),
    getShouldMeasureMenuItems = (props: RootProps) => props.isWorkspace && props.isShadowRender && props.level === 0,
    calcGlobalStyleName = (
        component: MenuComponent,
        level: number,
        stylesheetsIdToNameMap: Record<string, string>,
        autoColorMode?: boolean,
        isMenuInModernLayout?: boolean
    ) => {
        if (isMenuInModernLayout) {
            return "modernLayoutGlobalStyles";
        }

        let styleGlobalId = null;

        if (level === 0) {
            styleGlobalId =
                (autoColorMode && R.path(["themeStyles", "mainMenu", "id"], component)) || R.path(path.styleGlobalId, component);
        } else {
            styleGlobalId =
                (autoColorMode && R.path(["themeStyles", "submenu", "id"], component)) || R.path(path.subMenuStyleGlobalId, component);
        }

        return styleGlobalId ? stylesheetsIdToNameMap[styleGlobalId] : "";
    },
    Root = (props: RootProps) => {
        const shouldMeasureMenuItems = R.always(getShouldMeasureMenuItems(props)),
            isMenuInModernLayout = !!props.computedStyles,
            menuItems = props.pages.map((item, index) => {
                const menuItemProps = {
                        stylesheetsIdToNameMap: props.stylesheetsIdToNameMap,
                        calcMenuItemStyling: props.calcMenuItemStyling,
                        componentExtension: props.componentExtension,
                        onMenuItemMeasure: props.onMenuItemMeasure,
                        previewBackupTime: props.previewBackupTime,
                        componentId: props.componentId,
                        component: props.component,
                        dispatch: props.dispatch,
                        isWorkspace: props.isWorkspace,
                        isShadowRender: props.isShadowRender,
                        level: props.level,
                        pages: props.pages,
                        topLevelPages: props.topLevelPages,
                        page: item,
                        key: index,
                        index,
                        autoColorMode: props.autoColorMode,
                        computedStyles: props.computedStyles
                    },
                    onResize =
                        props.level === 0
                            ? ({ bounds: { width, height } }) =>
                                props.onMenuItemMeasure(index, {
                                    width,
                                    height,
                                    isCart: item.id === cartId
                                } as Dimensions)
                            : null;
                return shouldMeasureMenuItems ? (
                    <Measure bounds onResize={onResize} key={index}>
                        { /* eslint-disable-next-line @typescript-eslint/no-use-before-define */ }
                        {({ measureRef }) => <PageMenuItem {...menuItemProps} measureRef={measureRef} />}
                    </Measure>
                ) : (
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    <PageMenuItem {...menuItemProps} key={index} />
                );
            });
        return (
            <ul
                className={calcGlobalStyleName(
                    props.component,
                    props.level,
                    props.stylesheetsIdToNameMap,
                    props.autoColorMode,
                    isMenuInModernLayout
                )}
                ref={props.measureRef}
            >
                {menuItems}
            </ul>
        );
    },
    applyIsCurrentPageStyleing = isCurrentPage => styling => {
        if (isCurrentPage) {
            return { ...styling, a: { ...styling.a, className: cx(R.path(["a", "className"], styling), "selected") } };
        } else {
            return styling;
        }
    },
    applyLevelStyleing = level =>
        R.evolve({
            a: {
                className: className => cx(className, `level-${level}`)
            }
        }),
    hasSubtree = (props: MenuItemProps) => R.path(["page", "items", "length"], props) > 0,
    renderNextRoot = (props: MenuItemProps) => {
        const page = props.page;

        if (!page.items || !hasSubtree(props)) {
            return undefined;
        }

        const rootProps = {
            stylesheetsIdToNameMap: props.stylesheetsIdToNameMap,
            calcMenuItemStyling: props.calcMenuItemStyling,
            onMenuItemMeasure: props.onMenuItemMeasure,
            componentExtension: props.componentExtension,
            componentId: props.componentId,
            component: props.component,
            dispatch: props.dispatch,
            previewBackupTime: props.previewBackupTime,
            isWorkspace: props.isWorkspace,
            isShadowRender: props.isShadowRender,
            pages: page.items,
            topLevelPages: props.topLevelPages,
            level: props.level + 1,
            parentPage: page,
            autoColorMode: props.autoColorMode,
            computedStyles: props.computedStyles
        };
        return <Root {...rootProps} />;
    },
    getInlineItemStyling = (computedStyles, level, isCurrentPage) => {
        let inlineStyle: any = {};

        if (!computedStyles) {
            return inlineStyle;
        }

        if (level === 0) {
            inlineStyle.color = isCurrentPage ? computedStyles.activeColor : computedStyles.inActiveColor;
        }

        if (isCurrentPage) {
            inlineStyle.fontWeight = "bold";
        }

        inlineStyle.fontSize = computedStyles.fontSize;
        inlineStyle.fontFamily = computedStyles.fontFamily;

        if (computedStyles.bold) {
            inlineStyle.fontWeight = "bold";
        }

        if (computedStyles.italic) {
            inlineStyle.fontStyle = "italic";
        }

        return inlineStyle;
    },
    PageMenuItem = (props: MenuItemProps) => {
        const { page, calcMenuItemStyling, index, previewBackupTime, pages, topLevelPages, computedStyles } = props,
            styling = R.pipe(applyIsCurrentPageStyleing(page.isCurrentPage), applyLevelStyleing(props.level))(calcMenuItemStyling(props)),

            href = [moreId, cartId, DataSiteItemId.MORE_CHILD_ID].includes(page.id) ?
                // @ts-ignore
                undefined : getPageHref(page, topLevelPages, previewBackupTime),
            shouldRenderDivider = index < pages.length - 1,
            shouldRenderDividerBeforeInCaseOfPageTree =
                props.component.layoutType === layoutType.VERTICAL_TREE && index === 0 && props.level > 1,
            itemStyle = getInlineItemStyling(computedStyles, props.level, page.isCurrentPage);
        // @ts-ignore
        let aProps = { ...styling.a, href, target: page.target, style: itemStyle };

        if (page.type === DATA_LINK_SECTION_TYPE) {
            // @ts-ignore
            aProps = { ...aProps, sectionid: page.sectionId };
        }

        if (page.id === cartId) {
            const cartLiClass = 'wsb-li-cart';
            let className = styling.li?.className ?? '';
            className = className ? `${className} ${cartLiClass}` : cartLiClass;
            if (styling.li) {
                styling.li.className = className;
            } else {
                styling.li = { className };
            }
        }

        return (
            <li {...styling.li} ref={props.measureRef}>
                {shouldRenderDividerBeforeInCaseOfPageTree && <div className="divider" />}
                <a {...aProps}>
                    <span>{page.name}</span>
                </a>
                {renderNextRoot(props)}
                {shouldRenderDivider && <div className="divider" />}
            </li>
        );
    },
    layoutFactory = (factoryProps: LayoutFactoryProps) => (props: LayoutRenderProps) => {
        const {
                component,
                component: { width, height, moreText },
                dispatch,
                isShadowRender,
                isWorkspace,
                componentId,
                stylesheetsIdToNameMap,
                topLevelPages,
                selectedParentTheme,
                autoColorMode,
                computedStyles,
                isMenuCartAllowed
            } = props,
            onMenuItemMeasure = (index, dimensions: Dimensions) => {
                const { width, height, isCart } = dimensions as any;
                if (factoryProps.onMenuItemDimensionsChange && isShadowRender) {
                    factoryProps.onMenuItemDimensionsChange(dispatch, componentId, index, {
                        width,
                        height,
                        isCart
                    } as Dimensions);
                }
            },
            isHorizontalLayout = [layoutType.HORIZONTAL_SINGLE_LEVEL, layoutType.HORIZONTAL_DROPDOWN].includes(component.layoutType),
            isMenuCartVisible = isMenuCartAllowed && isHorizontalLayout,
            shouldAppendMoreBtn = (isShadowRender || !isWorkspace) && isMoreButtonModeSelector(component),
            pages = shouldAppendMoreBtn ? appendMoreButton(moreText, props.pages) : props.pages,
            pagesWithCart = isMenuCartVisible ? appendCart(pages) : pages,
            rootProps = {
                componentExtension: props.componentExtension,
                componentId: props.componentId,
                component: props.component,
                previewBackupTime: props.previewBackupTime,
                dispatch: props.dispatch,
                isWorkspace,
                isShadowRender: props.isShadowRender,
                calcMenuItemStyling: factoryProps.calcMenuItemStyling,
                pages: factoryProps.pagesSelector(pagesWithCart),
                topLevelPages,
                onMenuItemMeasure,
                stylesheetsIdToNameMap,
                level: 0,
                autoColorMode,
                computedStyles
            },
            isMenuInModernLayout = !!computedStyles,
            rootDivProps = {
                className: getMenuClassName(component, factoryProps.className, isMenuInModernLayout) + " " + (selectedParentTheme || ""),
                style: {
                    width: isMenuInModernLayout ? 'auto' : width,
                    height,

                    /* For preview, menu is initially hidden, to avoid items jumping after more button appears */
                    display: !isWorkspace && isMoreButtonModeSelector(component) ? "none" : undefined
                }
            },
            onResize = ({ bounds: { width, height } }) =>
                factoryProps.onDimensionsChange(dispatch, {
                    width,
                    height
                });

        return (
            <div {...rootDivProps}>
                {props.isWorkspace ? (
                    <Measure bounds onResize={onResize}>
                        {({ measureRef }) => React.createElement(Root, { ...rootProps, measureRef })}
                    </Measure>
                ) : (
                    React.createElement(Root, { ...rootProps })
                )}
            </div>
        );
    };

export { layoutFactory, PageMenuItem };
