import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(
    (theme) => ({
        lastPublished: {
            fontSize: "14px",
            lineHeight: "22px",
            fontWeight: 500,
            letterSpacing: "0.3px",
            marginTop: "16px",
            color: theme.palette.custom.colorGray_8a,
            [theme.breakpoints.up("sm")]: {
                fontWeight: 400,
                letterSpacing: "0.25px",
            }
        },
        notPublished: {
            color: theme.palette.custom.colorMandarin
        },
    }),
    { name: "LastPublished" }
);
