import React from "react";
import cx from "classnames";
import { pipe } from "ramda";
import { connect } from "react-redux";
import { injectIntl, Intl } from "../../view/intl/index";
import { getThemeColorsFromStylesheet } from "../Workspace/epics/stylesheets/selectors";
import { makeEpicStateSelector } from "../../epics/makeEpic";
import stylesheetVAT from "../Workspace/epics/stylesheets/valueActionType";
import AUTO_COLOR_LEFT_PANEL_EPIC_VALUE from "./epic/valueActionType";
import * as styles from "./AutoColorLeftPanel.css";
import { AutoColorModeOff } from "./screens/AutoColorModeOff";
import { AutoColorModeOn } from "./screens/AutoColorModeOn";
import type { EpicState } from "./flowTypes";
import type { ThemeColorDataType, ThemeSiteSettingsDataType } from "../ThemeGlobalData/flowTypes";
import { colorThemeDataEpicStateFromAppStateSelector } from "../SiteSettings/ColorThemeData/selectorActionTypes";

type Props = {
    intl: Intl;
    dispatch: Dispatch;
    leftPanel: EpicState;
    themeColors: ThemeColorDataType;
    themeSettings: ThemeSiteSettingsDataType;
};

const AutoColorLeftPanel = (props: Props) => {
    const { intl, dispatch, leftPanel, themeColors, themeSettings } = props;

    if (!leftPanel.show) {
        return null;
    }

    let View;

    if (themeSettings.autoColorMode) {
        View = <AutoColorModeOn intl={intl} dispatch={dispatch} leftPanel={leftPanel} themeColors={themeColors} />;
    } else {
        View = <AutoColorModeOff />;
    }

    return (
        <div
            className={cx(styles.autoColorLeftPanel, styles.active, {
                [styles.fullHeight]: !themeSettings.autoColorMode
            })}
        >
            {View}
        </div>
    );
};

export const leftPanelDataSelector = makeEpicStateSelector(AUTO_COLOR_LEFT_PANEL_EPIC_VALUE),
    themeColorsDataSelector = pipe(makeEpicStateSelector(stylesheetVAT), getThemeColorsFromStylesheet);

const mapStateToProps = appState => ({
    themeSettings: colorThemeDataEpicStateFromAppStateSelector(appState),
    themeColors: themeColorsDataSelector(appState),
    leftPanel: leftPanelDataSelector(appState)
});

export default injectIntl(connect(mapStateToProps)(AutoColorLeftPanel));
