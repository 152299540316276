import * as R from 'ramda';
import { setCells, setCommonCellsData } from "./setAllCells";
import { getGlobalstyleById } from "../utils";
import { actionsThatHandledByTinyMceInEditModeMap } from "../epics/tableEditMode/index";
import type {
    CommonTableComponentCell,
    TableComponent,
    TableComponentCell,
    TableComponentDependsOn
} from "../flowTypes";
import type { Stylesheets, TableCellStylesheet } from "../../../Workspace/epics/stylesheets/flowTypes";

type CellBaseProps<T> = {
    propToUpdate: T,
    globalStyles: Stylesheets,
    propUpdatersMap: {
        [k: string]: {
            getter: (arg: TableComponentCell) => AnyValue,
            executeGsGetter?: (arg: TableComponentCell) => boolean,
            gsGetter: (arg: TableCellStylesheet) => AnyValue,
            gsFormatter?: (arg: AnyValue) => AnyValue,
            setter: (arg: AnyValue) => (arg: TableComponentCell) => TableComponentCell
        }
    }
}

type MakeCellsUpdaterProps<T> = CellBaseProps<T> & {
    component: TableComponent,
    selectedCellsIndexes: Array<number>
}

type CellsSinglePropUpdater = {
    isActionHandledByTableReducerAsWell?: boolean,
    makeCellPropUpdater: (AnyAction) => (TableComponentCell) => TableComponentCell
}

export const
    makeCellUpdater =
        <T extends { type: any, value: AnyValue }>({
            propUpdatersMap,
            propToUpdate,
            globalStyles
        } : CellBaseProps<T>) => (cell: TableComponentCell, commonCellsData?: CommonTableComponentCell) => {
            const restOfProps = Object.keys(propUpdatersMap).filter(p => p !== propToUpdate.type);
            const updater = (cell) => {
                return R.pipe(
                    ...restOfProps.map(prop => {
                        return (cell) => {
                            const record = propUpdatersMap[prop];
                            const valueInCell = record.getter(cell);
                            if (!valueInCell) {
                                if (!record.executeGsGetter || record.executeGsGetter(cell)) {
                                    let gsValue = R.pipe(
                                        (globalStyles) => getGlobalstyleById(globalStyles)(cell.style.globalId),
                                        record.gsGetter
                                    )(globalStyles);
                                    if (!R.isNil(gsValue) && !isNaN(gsValue)) {
                                        gsValue = (record.gsFormatter || R.identity)(gsValue);
                                        return record.setter(gsValue)(cell);
                                    }
                                }
                            }
                            return cell;
                        };
                    }),
                    propUpdatersMap[propToUpdate.type].setter(propToUpdate.value)
                )(cell);
            };
            if (cell.style) {
                return updater(cell);
            } else if (commonCellsData) {
                return updater({ ...cell, ...commonCellsData });
            }
            return cell;
        },
    makeCellsUpdater =
        <T extends { type: any, value: AnyValue }>(props: MakeCellsUpdaterProps<T>) => {
            const
                { selectedCellsIndexes, propToUpdate, component, globalStyles, propUpdatersMap } = props,
                cellUpdater = makeCellUpdater({
                    propUpdatersMap,
                    propToUpdate,
                    globalStyles
                });

            return R.pipe(
                (component) => setCells({ cellUpdater, selectedCellsIndexes }, component),
                (component) => setCommonCellsData({ cellUpdater, selectedCellsIndexes }, component)
            )(component);
        },
    makeCellsSinglePropUpdater =
        ({ makeCellPropUpdater, isActionHandledByTableReducerAsWell }: CellsSinglePropUpdater) =>
            (component: TableComponent, action: AnyAction, dependencies: TableComponentDependsOn) => {
                const { cellInEditModeIndex, selectedCellsIndexes } = dependencies.tableEditModeState;

                if (
                    cellInEditModeIndex > -1
                    && actionsThatHandledByTinyMceInEditModeMap[action.type]
                    && !isActionHandledByTableReducerAsWell
                ) {
                    return component;
                }

                return R.pipe(
                    (component) => setCells({ cellUpdater: makeCellPropUpdater(action), selectedCellsIndexes }, component),
                    (component) => setCommonCellsData({ cellUpdater: makeCellPropUpdater(action), selectedCellsIndexes }, component)
                )(component);
            };
