import { fileChooserResourcesSelector } from "../../../../selectors";
import resetResourcesSaga from "./resetResourcesSaga";
import removeResourceByName from "../../utils/removeResourceByName";
import Resource from "../../Resource";
import selectGen from "../../../../../../utils/saga/selectGen";

export default function* (resourceName: string, contentType: string = 'X'): Generator<any, Array<Resource>, void> {
    const ghost = {
        isGhost: true,
        href: resourceName,
        contentType,
        size: 0,
        createdDate: new Date().getTime()
    };

    // remove existing resource (in case Overwrite option)
    const resources = removeResourceByName(yield* selectGen(fileChooserResourcesSelector), resourceName);

    // @ts-ignore
    resources.push(ghost);

    return yield* resetResourcesSaga(resources);
}
