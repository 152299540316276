import * as React from 'react';
import { SCROLL_EFFECTS, scrollEffectsTitle } from "./constants";
import { NewTag } from "../../../../view/common/Tag/index";
import { injectIntl } from "../../../../view/intl/index";
import * as styles from './ScrollEffects.css';

export default {
    [SCROLL_EFFECTS]: injectIntl(({ intl }) => <div className={styles.container}>
        {intl.msgJoint(scrollEffectsTitle)}
        <NewTag containerClassName={styles.newTag} />
    </div>)
};
