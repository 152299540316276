import * as R from "ramda";
import { overPath } from '../../../../src/utils/ramdaEx';
import * as path from '../../../../src/mappers/path';
import * as borderMapper from '../Common/border';
import * as backgroundMapper from '../Common/background';

const
    borderMap = {
        to: overPath([[path.border]])(borderMapper.to),
        back: overPath([[path.border]])(borderMapper.back)
    },
    backgroundMap = {
        to: overPath([[path.background]])(backgroundMapper.to),
        back: overPath([[path.background]])(backgroundMapper.back)
    };

export const
    to = R.pipe(borderMap.to, backgroundMap.to),
    back = R.pipe(borderMap.back, backgroundMap.back);
