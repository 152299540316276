import { equals } from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import valueActionType from "./valueActionType";
import undoManagerValueActionType from '../../../../epics/undoManager/valueActionType';
import { undoBorderCheck, redoBorderCheck } from '../../../../epics/undoManager/factory';
import { UserFocusValueActionType } from "../../../App/epics/userFocus/valueActionType";

export type UndoRedoButtonsState = {
    undoDisabled: boolean,
    redoDisabled: boolean,
}

const defaultState: UndoRedoButtonsState = {
    undoDisabled: true,
    redoDisabled: true
};

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                undoManagerValueActionType,
                UserFocusValueActionType,
            ],
            reducer: ({ state, values: [undoManagerState] }) => {
                const newState = {
                    undoDisabled: undoBorderCheck(undoManagerState.undoState),
                    redoDisabled: redoBorderCheck(undoManagerState.undoState)
                };

                return equals(state, newState) ? { state } : { state: newState };
            }
        }
    ]
});
