import * as R from "ramda";
import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import type { EpicUpdaterConfig } from '../../../../epics/flowTypes';
import {
    MODERN_FOOTER_ACTIVATED_NEW_CMPS_ADDED,
    MODERN_FOOTER_DEACTIVATED_OLD_CMPS_RESTORED,
    MODERN_HEADER_ACTIVATED_NEW_CMPS_ADDED,
    MODERN_HEADER_DEACTIVATED_OLD_CMPS_RESTORED,
    MODERN_HEADER_COMPONENTS_INITIALIZED,
    MODERN_FOOTER_COMPONENTS_INITIALIZED,
    MODERN_HEADER_TOGGLE_CMPS_INITIALIZED,
    MODERN_FOOTER_TOGGLE_CMPS_INITIALIZED,
    MODERN_HEADER_FOOTER_TOGGLE_CMPS_SET,
    MODERN_HEADER_FOOTER_TOGGLE_CMPS_UNSET,
    MODERN_LAYOUT_ONBOARDING_TOGGLE_CMPS_UNSET,
} from "../../actionTypes";
import type { MHFDataEpicState } from "../../flowTypes";
import { COMPONENTS_MAP_INITIALIZED } from "../../../Workspace/epics/componentsEval/actionTypes";
import { UNDO_INITIAL_STATE } from "../../../../epics/undoManager/updateReasons";

const defaultToggleOffState = { header: {}, footer: {} },
    getDefaultState = () => ({
        oldData: {
            header: {
                cmpsMap: {},
                cmpsMapExtension: {}
            },
            footer: {
                cmpsMap: {},
                cmpsMapExtension: {}
            }
        },
        newData: { header: {}, footer: {} },
        toggleOffData: defaultToggleOffState,
    }),
    STATE_CHANGED = 'STATE_CHANGED';
export default makeEpic({
    defaultState: getDefaultState(),
    undo: {
        isUndoableChange: R.T,
        undoablePaths: [[]]
    },
    valueActionType,
    updaters: ([
        {
            conditions: [COMPONENTS_MAP_INITIALIZED],
            reducer: () => {
                return {
                    state: getDefaultState(),
                    updateReason: UNDO_INITIAL_STATE,
                };
            }
        },
        {
            conditions: [
                MODERN_HEADER_COMPONENTS_INITIALIZED,
            ],
            reducer: ({ values: [data], state }) => {
                const { active, layoutId, cmpsMap, cmpsMapExtension } = data,
                    cmpsData = { cmpsMap, cmpsMapExtension };
                let path = active ? ['oldData', 'header'] :
                    ['newData', 'header', layoutId];
                return {
                    state: R.assocPath(path, cmpsData, state),
                    updateReason: UNDO_INITIAL_STATE,
                };
            }
        },
        {
            conditions: [
                MODERN_FOOTER_COMPONENTS_INITIALIZED,
            ],
            reducer: ({ values: [data], state }) => {
                const { active, layoutId, cmpsMap, cmpsMapExtension } = data,
                    cmpsData = { cmpsMap, cmpsMapExtension };
                let path = active ? ['oldData', 'footer'] :
                    ['newData', 'footer', layoutId];
                return {
                    state: R.assocPath(path, cmpsData, state),
                    updateReason: UNDO_INITIAL_STATE,
                };
            }
        },
        {
            conditions: [
                MODERN_HEADER_TOGGLE_CMPS_INITIALIZED,
            ],
            reducer: ({ values: [data], state }) => {
                const { toggleOffHeaderCmpsMap, layoutId, initialState } = data;
                let path = ['toggleOffData', 'header', layoutId];
                return {
                    state: R.assocPath(path, toggleOffHeaderCmpsMap, state),
                    updateReason: initialState ? UNDO_INITIAL_STATE : STATE_CHANGED
                };
            }
        },
        {
            conditions: [
                MODERN_FOOTER_TOGGLE_CMPS_INITIALIZED,
            ],
            reducer: ({ values: [data], state }) => {
                const { toggleOffFooterCmpsMap, layoutId, initialState } = data;
                let path = ['toggleOffData', 'footer', layoutId];
                return {
                    state: R.assocPath(path, toggleOffFooterCmpsMap, state),
                    updateReason: initialState ? UNDO_INITIAL_STATE : STATE_CHANGED
                };
            }
        },
        {
            conditions: [
                MODERN_HEADER_FOOTER_TOGGLE_CMPS_SET,
            ],
            reducer: ({ values: [{ layoutId, cmpList, sectionName }], state }) => {
                const path = ['toggleOffData', sectionName, layoutId],
                    currentToggleOffCmps = R.path(path, state) || {},
                    toggleOffCmpsMap = cmpList.reduce((acc, cmp) => ({ ...acc, [cmp.id]: cmp }), currentToggleOffCmps);
                return {
                    state: R.assocPath(path, toggleOffCmpsMap, state),
                    updateReason: STATE_CHANGED
                };
            }
        },
        {
            conditions: [
                MODERN_HEADER_FOOTER_TOGGLE_CMPS_UNSET,
            ],
            reducer: ({ values: [{ layoutId, cmpIdList, sectionName }], state }) => {
                let path = ['toggleOffData', sectionName, layoutId];
                const toggleData = R.clone(R.path(path, state) || {});
                cmpIdList.forEach(cmpId => delete toggleData[cmpId]);
                return {
                    state: R.assocPath(path, toggleData, state),
                    updateReason: STATE_CHANGED
                };
            }
        },
        {
            conditions: [
                MODERN_HEADER_ACTIVATED_NEW_CMPS_ADDED,
            ],
            reducer: ({ values: [oldHeaderData], state }) => ({
                state: R.assocPath(['oldData', 'header'], oldHeaderData, state),
                updateReason: STATE_CHANGED
            })
        },
        {
            conditions: [
                MODERN_FOOTER_ACTIVATED_NEW_CMPS_ADDED,
            ],
            reducer: ({ values: [oldFooterData], state }) => ({
                state: R.assocPath(['oldData', 'footer'], oldFooterData, state),
                updateReason: STATE_CHANGED
            })
        },
        {
            conditions: [MODERN_HEADER_DEACTIVATED_OLD_CMPS_RESTORED],
            reducer: ({ values: [{ layoutId, data }], state }) => {
                const toggleOffCmps = state.toggleOffData.header[layoutId] || {};
                const updatedData = { ...data, cmpsMap: { ...data.cmpsMap, ...toggleOffCmps } };
                return {
                    state: R.pipe(
                        R.assocPath(['newData', 'header', layoutId], updatedData),
                        R.assocPath(['toggleOffData', 'header', layoutId], {}),
                    )(state),
                    updateReason: STATE_CHANGED
                };
            }
        },
        {
            conditions: [MODERN_FOOTER_DEACTIVATED_OLD_CMPS_RESTORED],
            reducer: ({ values: [{ layoutId, data }], state }) => {
                const toggleOffCmps = state.toggleOffData.footer[layoutId] || {};
                const updatedData = { ...data, cmpsMap: { ...data.cmpsMap, ...toggleOffCmps } };
                return {
                    state: R.pipe(
                        R.assocPath(['newData', 'footer', layoutId], updatedData),
                        R.assocPath(['toggleOffData', 'footer', layoutId], {}),
                    )(state),
                    updateReason: STATE_CHANGED
                };
            }
        },
        {
            conditions: [MODERN_LAYOUT_ONBOARDING_TOGGLE_CMPS_UNSET],
            reducer: ({ state }) => ({
                state: { ...state, toggleOffData: defaultToggleOffState },
                updateReason: STATE_CHANGED
            })
        }
    ] as Array<EpicUpdaterConfig<MHFDataEpicState, any, any>>)
});
