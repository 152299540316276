import * as pageIds from './pageIds';
import {
    MOBILE_VIEW,
    MobileViewTitle
} from '../../../PropertiesPanel/view/MobileView/constants';

export default {
    [pageIds.MAIN]: 'msg: component.imageSlider {Image slider}',
    [pageIds.CONTROLS]: 'msg: component.imageSlider.controls {Navigation}',
    [pageIds.TRANSITION]: 'msg: component.imageSlider.transition {Transition}',
    [pageIds.ON_CLICK]: 'msg: component.imageSlider.onClick {On click}',
    [pageIds.CAPTIONS]: 'msg: component.imageSlider.captions {Captions}',
    [pageIds.FULL_WIDTH]: 'msg: common.fullWidth {Full width}',
    [MOBILE_VIEW]: MobileViewTitle
};
