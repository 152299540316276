import { awsAuthAction } from "../../../../src/redux/middleware/aws/actions";

export type ResetPasswordParams = {
    username: string,
    password: string,
    verificationCode: string,
    clientMetadata?: Record<string, any>,
}

export const
    DEMO_RESET_PASSWORD_REQUEST_ACTION = 'DEMO_RESET_PASSWORD_REQUEST_ACTION',
    DEMO_RESET_PASSWORD_SUCCESS_ACTION = 'DEMO_RESET_PASSWORD_SUCCESS_ACTION',
    DEMO_RESET_PASSWORD_FAILURE_ACTION = 'DEMO_RESET_PASSWORD_FAILURE_ACTION',
    demoResetPasswordAction = (params: ResetPasswordParams) => awsAuthAction({
        actionTypes: [
            DEMO_RESET_PASSWORD_REQUEST_ACTION,
            DEMO_RESET_PASSWORD_SUCCESS_ACTION,
            DEMO_RESET_PASSWORD_FAILURE_ACTION,
        ],
        serviceMethod: 'forgotPasswordSubmit',
        serviceMethodParams: [
            params.username,
            params.verificationCode,
            params.password,
            params.clientMetadata,
        ]
    });

export const
    DEMO_UPDATE_RESET_PASSWORD_VALUE_ACTION = 'DEMO_UPDATE_RESET_PASSWORD_VALUE_ACTION',
    updatePasswordValue = (passwordValue: String) => ({
        type: DEMO_UPDATE_RESET_PASSWORD_VALUE_ACTION,
        payload: { passwordValue },
        amendToSelf: true
    });
export const
    DEMO_TOGGLE_SHOW_RESET_PASSWORD_VALUE_ACTION = 'DEMO_TOGGLE_SHOW_RESET_PASSWORD_VALUE_ACTION',
    toggleShowPassword = () => ({
        type: DEMO_TOGGLE_SHOW_RESET_PASSWORD_VALUE_ACTION
    });

export const
    DEMO_UPDATE_RESET_CONFIRM_PASSWORD_VALUE_ACTION = 'DEMO_UPDATE_RESET_CONFIRM_PASSWORD_VALUE_ACTION',
    updateConfirmPasswordValue = (confirmPasswordValue: String) => ({
        type: DEMO_UPDATE_RESET_CONFIRM_PASSWORD_VALUE_ACTION,
        payload: { confirmPasswordValue },
        amendToSelf: true
    });

export const
    DEMO_SHOW_RESET_PASSWORD_PAGE_ACTION = 'DEMO_SHOW_RESET_PASSWORD_PAGE_ACTION',
    showResetPasswordPage = () => ({
        type: DEMO_SHOW_RESET_PASSWORD_PAGE_ACTION
    });
export const
    DEMO_CLEAN_UP_RESET_PASSWORD_SCREEN_ACTIONS = 'DEMO_CLEAN_UP_RESET_PASSWORD_SCREEN_ACTIONS',
    cleanUpResetPasswordScreen = () => ({
        type: DEMO_CLEAN_UP_RESET_PASSWORD_SCREEN_ACTIONS
    });
export const
    DEMO_RESET_PASSWORD_CLEAN_ALL_ERRORS_ACTION = 'DEMO_RESET_PASSWORD_CLEAN_ALL_ERRORS_ACTION',
    cleanUpAllErrors = () => ({
        type: DEMO_RESET_PASSWORD_CLEAN_ALL_ERRORS_ACTION
    });
export const
    DEMO_RESET_PASSWORD_LOADING_ACTION = 'DEMO_RESET_PASSWORD_LOADING_ACTION',
    resetPasswordLoading = () => ({
        type: DEMO_RESET_PASSWORD_LOADING_ACTION
    });
export const DEMO_RESET_PASSWORD_SHOW_INVALID_PASSWORD_ERROR_ACTION = 'DEMO_RESET_PASSWORD_SHOW_INVALID_PASSWORD_ERROR_ACTION',
    showInvalidPasswordError = () => ({
        type: DEMO_RESET_PASSWORD_SHOW_INVALID_PASSWORD_ERROR_ACTION
    });
