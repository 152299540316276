import cx from "classnames";
import React from "react";
import Page from "../../../PropertiesPanel/view/PropertiesPage";
import VerticalSpacer from "../../../../view/common/VerticalSpacer";
import {
    getBackgroundAsset,
    getBackgroundRepeat,
    getBackgroundOverlay,
    getBackgroundPosition,
    getBackgroundSize,
    getBackgroundImageOpacity,
    getBackgroundImageScrollEffect,
} from "../../../../view/oneweb/commonComponentSelectors";
import * as ActionTypes from "../../Background/actionTypes";
import type { SectionComponent } from "../flowTypes";
import BackgroundImageSettings from "../../../presentational/BackgroundImageSettings/index";
import BackgroundVideoSettings from "../../../presentational/BackgroundVideoSettings/index";
import OpacitySlider from "../../../presentational/OpacitySlider/index";
import { makeStripPropertiesPanelConfig } from "../../Strip/propertiesPanel/index";
import { ImageOpacityLabel, VideoOpacityLabel } from "../../Background/constants";
import { bgSetBackgroundImageOpacityAC } from "../../Background/actionCreators";
import PropertyContainer from "../../../../view/common/PropertyContainer/index";
import { RadioButtonGroup } from "../../../../view/common/RadioButton/index";
import styles from "./sectionPropertiesPanel.css";
import { QuestionTip } from "../../../presentational/Tooltip/questionTip/QuestionTip";
import { TooltipPosition } from "../../../presentational/Tooltip/constants";
import { Msg, Intl, injectIntl } from "../../../../view/intl/index";
import { splitSentenceIntoMultipleLines } from "../../../../view/intl/utils";
import { HEADER_SHARE_BG_IMG_WITH_HEADER_TOGGLE } from "../actionTypes";
import { ComponentNames } from "../../constants";
import { SECTION } from "../../componentKinds";
import { VideoUtils } from "../../../../utils/fileUtils";
import CheckBox from "../../../../view/common/CheckBox/PropertiesCheckBox";
import { SHOP_FOOTER } from "../../Strip/propertiesPanel/pageIds";
import pageTitles from "../../Strip/propertiesPanel/pageTitles";
import { TEMPLATE_DISPLAY_SHOP_STRIP_IN_FOOTER } from "../../Template/actionTypes";

type Props = {
    selectedComponent: SectionComponent,
    dispatch: Dispatch,
    selectedComponentIsHeader: boolean,
    shareHeaderAndFirstSectionBgImg: { enabled: boolean },
    topMostPageSectionBackgroundImageIsSet: boolean,
    intl: Intl,
    templateData: { showShopStripInFooter: boolean },
    isPremiumUser: boolean,
    isEcommerceUser: boolean,
};

const getLabelFromAsset = asset => asset.url.split('/').slice(-1)[0];

const makePageTypeOptions = () => [
    {
        label:
            "msg: header.ppImagePage.useFromSectionBelow {Use from section below}",
        value: "true",
        sideChildren: (
            <QuestionTip
                position={TooltipPosition.TOP}
                className={styles.pageTitleTipTarget}
                theme={{
                    container: styles.pageTitleTipContainer,
                    tipInfo: styles.pageTitleTipInfo,
                }}
                preserveWhenHover
            >
                <p>
                    <Msg k="headerSharedBgTip">{`
                        Makes the first section on the current page share its background with your header.
                    `}</Msg>
                </p>
            </QuestionTip>
        ),
    },
    {
        label: "msg: header.ppImagePage.customStyling {Custom styling}",
        value: "false",
    },
];

const imagePage = injectIntl(({
    selectedComponent,
    dispatch,
    selectedComponentIsHeader,
    shareHeaderAndFirstSectionBgImg,
    topMostPageSectionBackgroundImageIsSet,
    isPremiumUser,
    isEcommerceUser,
    intl
}: Props) => {
    const asset = getBackgroundAsset(selectedComponent),
        repeat = getBackgroundRepeat(selectedComponent),
        overlay = getBackgroundOverlay(selectedComponent),
        position = getBackgroundPosition(selectedComponent),
        size = getBackgroundSize(selectedComponent),
        scrollEffect = getBackgroundImageScrollEffect(selectedComponent),
        bgImageOpacity = getBackgroundImageOpacity(selectedComponent),
        opacityChange = (opacity) =>
            dispatch(bgSetBackgroundImageOpacityAC(opacity)),
        imgSelectionDisabled =
            selectedComponentIsHeader &&
            shareHeaderAndFirstSectionBgImg.enabled,
        sharingIsDisabled = !topMostPageSectionBackgroundImageIsSet;

    return (
        <Page>
            <VerticalSpacer y={19} />
            {selectedComponentIsHeader && <PropertyContainer
                label="msg: common.background {Background}"
                isGroupLabelRequired={false}
            >
                <VerticalSpacer y={2} />
                <div className={cx({ [styles.disabled]: sharingIsDisabled })}>
                    <RadioButtonGroup
                        radioButtonContainerClassName={
                            styles.radioButtonContainer
                        }
                        optionsConfig={makePageTypeOptions()}
                        onChange={() =>
                            dispatch({
                                type: HEADER_SHARE_BG_IMG_WITH_HEADER_TOGGLE,
                            })}
                        value={shareHeaderAndFirstSectionBgImg.enabled.toString()}
                    />
                    <div
                        className={cx({
                            [styles.disabledOverlay]: sharingIsDisabled,
                        })}
                    />
                    <div
                        className={cx({
                            [styles.disabledTooltipHandle]: sharingIsDisabled,
                        })}
                        data-title-position="top"
                        data-title={
                            sharingIsDisabled ?
                                splitSentenceIntoMultipleLines(
                                    intl.msgJoint('msg: tooltip.disabledHeaderSharedBgTip {Add a background image to your first section to use this feature}'), // eslint-disable-line max-len
                                    30
                                )
                                : undefined
                        }
                    />
                </div>
            </PropertyContainer>}
            {selectedComponentIsHeader && <VerticalSpacer y={9} />}
            <BackgroundImageSettings
                asset={asset}
                assetChangeAction={ActionTypes.BG_CHANGE_ACTION}
                assetRemoveAction={ActionTypes.BG_REMOVE_ACTION}
                repeat={repeat}
                assetRepeatChangeAction={
                    ActionTypes.BG_REPEAT_CHANGE_ACTION
                }
                position={position}
                assetPositionChangeAction={
                    ActionTypes.BG_POSITION_CHANGE_ACTION
                }
                size={size}
                assetSizeChangeAction={ActionTypes.BG_SIZE_CHANGE_ACTION}
                dispatch={dispatch}
                scrollEffect={scrollEffect}
                hideHeadLabel={selectedComponentIsHeader}
                disabled={imgSelectionDisabled}
                isVideo={false}
            />
            {!imgSelectionDisabled && asset && !VideoUtils.isVideoFile(getLabelFromAsset(asset)) && (
                <div>
                    <VerticalSpacer />
                    <OpacitySlider
                        label={ImageOpacityLabel}
                        opacity={bgImageOpacity}
                        onChange={opacityChange}
                        onAfterChange={opacityChange}
                    />
                </div>
            )}
            {(isPremiumUser || isEcommerceUser) && (
                <div>
                    <VerticalSpacer y={8} />
                    <div style={{
                        display: "block",
                        height: "1px",
                        border: 0,
                        borderTop: "1px solid #ccc",
                        margin: "1em 0",
                        padding: 0
                    }}
                    />
                    <VerticalSpacer y={8} />
                    <BackgroundVideoSettings
                        asset={asset}
                        assetChangeAction={ActionTypes.BG_CHANGE_ACTION}
                        assetRemoveAction={ActionTypes.BG_REMOVE_ACTION}
                        overlay={overlay}
                        assetOverlayChangeAction={
                            ActionTypes.BG_OVERLAY_CHANGE_ACTION
                        }
                        position={position}
                        assetPositionChangeAction={
                            ActionTypes.BG_POSITION_CHANGE_ACTION
                        }
                        size={size}
                        assetSizeChangeAction={ActionTypes.BG_SIZE_CHANGE_ACTION}
                        dispatch={dispatch}
                        scrollEffect={scrollEffect}
                        hideHeadLabel={selectedComponentIsHeader}
                        disabled={imgSelectionDisabled}
                        selectTitle="msg: common.video.select.title {Video}"
                        selectPlaceholderLabel="msg: common.video.select.placeholder.label {Add video}"
                        isVideo
                    />
                    {!imgSelectionDisabled && asset && VideoUtils.isVideoFile(getLabelFromAsset(asset)) && (
                        <div>
                            <VerticalSpacer />
                            <OpacitySlider
                                label={VideoOpacityLabel}
                                opacity={bgImageOpacity}
                                onChange={opacityChange}
                                onAfterChange={opacityChange}
                            />
                        </div>
                    )}
                </div>
            )}
        </Page>
    );
});

const shopStripPage = injectIntl((props: Props) => {
    const { dispatch, templateData } = props;
    return (
        <Page>
            <CheckBox
                isChecked={templateData.showShopStripInFooter}
                label="msg: component.section.propPanel.showShopFooter {Show shop footer}"
                onClick={() => {
                    dispatch({ type: TEMPLATE_DISPLAY_SHOP_STRIP_IN_FOOTER });
                }}
            />
        </Page>
    );
});

const sectionPropertiesPanel = makeStripPropertiesPanelConfig({
    mainPageConfig: {
        title: ComponentNames[SECTION],
    },
    imagePatternPageConfig: {
        view: imagePage,
    },
    shopStripPageConfig: {
        id: SHOP_FOOTER,
        title: pageTitles[SHOP_FOOTER],
        view: shopStripPage
    }
});

export { sectionPropertiesPanel };
