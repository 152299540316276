import u from 'updeep';
import makeUuid from '../../../../utils/makeUuid';
import type { PageItemAction } from "../../../../src/redux/modules/children/workspace/flowTypes";
import { LcTabName } from "../../../../src/view/common/dialogs/LinkChooserDialog/constants";
import type { LinkChooserInterchangeType } from "../../../../src/view/common/dialogs/LinkChooserDialog/flowTypes";
import Resource from "../../../../src/redux/modules/children/fileChooser/Resource";
import { buildMailTo } from "../../../../src/view/common/dialogs/LinkChooserDialog/utils/buildMailTo";
import { parseMailTo } from "../../../../src/view/common/dialogs/LinkChooserDialog/utils/parseMailTo";
import { isTelUrl, makeTelUrl, parseTelUrlPhone } from '../../../../src/utils/TelProtocol';
import { BROKEN_LINK } from "../../../../src/components/oneweb/Text/constants";
import { customSendReport } from '../../../../src/customSendCrashReport';

const to = (action: null | undefined | PageItemAction): null | undefined | LinkChooserInterchangeType => {
    let
        type,
        value,
        openInNewWindow = false;

    if (!action) {
        return null;
    }

    // page
    if ('linkId' in action) {
        type = LcTabName.PAGE;
        value = action.linkId;
        openInNewWindow = !!action.actionType;
    } else {
        if (!action.link) throw new Error('Missing action.link'); // to make flow happy

        const url = action.link.url || '';

        // file
        if (url.includes('webspace:') || url.includes('trial:')) {
            type = LcTabName.FILE;
            try {
                value = Resource.fromWebspaceUrl(url);
            } catch (e: any) {
                customSendReport({
                    message: `Exception while creating Resource form ${url}`,
                    additionalInfo: e.toJSON(),
                });
                value = BROKEN_LINK;
            }
            openInNewWindow = !!action.actionType;
        } // eslint-disable-line brace-style

        // email
        else if (url.indexOf('mailto:') === 0) { // eslint-disable-line
            type = LcTabName.EMAIL;
            const parsedEmail = parseMailTo(url);
            value = { selection: parsedEmail.email, subject: parsedEmail.subject };
        } // eslint-disable-line brace-style

        // location
        else if (url.indexOf('location:') === 0) { // eslint-disable-line
            type = LcTabName.LOCATION;

            // `url.split(':').slice(1).join(':')` is used to support cases like "location:https://maps.google.com/?q=The+Address" and strip "location:" part from there
            value = { selection: url.split(':').slice(1).join(':'), name: action.link.name };
            openInNewWindow = !!action.actionType;
        } // eslint-disable-line brace-style

        // section id
        else if (url.indexOf('sectionLink:') === 0) { // eslint-disable-line
            const parsedContent = url.split(':');
            type = LcTabName.SECTION_LINK;
            value = { pageId: parsedContent[1], sectionId: parsedContent[2] };
            openInNewWindow = !!action.actionType;
        } // eslint-disable-line brace-style

        // email
        else if (isTelUrl(url)) { // eslint-disable-line
            type = LcTabName.PHONE;
            value = parseTelUrlPhone(url);
        } // eslint-disable-line brace-style

        else /*if (url)*/ { // eslint-disable-line
            // if not webspace nor mailto, implies it is url. We should broken link in properties panel Ex: WBTGEN-2873
            type = LcTabName.URL;
            value = url;
            openInNewWindow = !!action.actionType;
        }
    }

    return {
        link: { type, value },
        openInNewWindow
    };
};

const defaultActionMask = {
    type: 'web.data.Action'
};
const defaultLinkMask = {
    type: 'web.data.links.LinkExternal',
    hidden: false,
    linkId: null,
    target: '_self'
};

const back = (input: null | undefined | LinkChooserInterchangeType): null | undefined | PageItemAction => {
    if (!input) return null;

    let _defaultLinkMask = defaultLinkMask;
    const { link, openInNewWindow } = input;
    let result;

    if (link.type === LcTabName.PAGE) {
        result = {
            linkId: link.value
        };
    }

    if (link.type === LcTabName.FILE) {
        let val = link.value;
        if (!val.getWebSpaceProtocolRelativePath) {
            const { basePath, href } = val;
            val = new Resource(basePath, { href });
        }
        const
            resource: Resource = val,
            path = resource.getWebSpaceProtocolRelativePath();

        result = {
            link: {
                name: path,
                url: path
            }
        };
    }

    if (link.type === LcTabName.URL) {
        const url = link.value;
        result = {
            link: {
                name: url,
                url
            }
        };
    }

    if (link.type === LcTabName.PHONE) {
        const url = makeTelUrl(link.value);
        result = {
            link: {
                name: url,
                url,
            }
        };
    }

    if (link.type === LcTabName.EMAIL) {
        const email = buildMailTo({
            email: link.value.selection,
            subject: link.value.subject,
        });
        result = {
            link: {
                name: email,
                url: email
            }
        };
    }

    if (link.type === LcTabName.LOCATION) {
        result = {
            link: { name: link.value.name, url: `location:${link.value.selection}` }
        };
    }

    if (link.type === LcTabName.SECTION_LINK) {
        const sectionLink = `sectionLink:${link.value.pageId}:${link.value.sectionId}`;
        _defaultLinkMask = {
            ..._defaultLinkMask,
            target: openInNewWindow ? '_blank' : '_self',
        };
        result = {
            link: {
                name: link.value.sectionId,
                url: sectionLink,
                ..._defaultLinkMask
            }
        };
    }

    if (!result) throw new Error('Unknown LinkChooser action type: ' + link.type);

    if (link.type !== LcTabName.EMAIL && openInNewWindow) {
        result.actionType = 1;
    }

    result = { ...result, ...defaultActionMask, id: makeUuid() };
    if (result.link) result = u({ link: { ..._defaultLinkMask, id: makeUuid() } }, result);

    return result;
};

export { to, back };
