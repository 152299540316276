// @ts-nocheck
import { identity, isNil } from 'ramda';
import { isFunction, isSymbol } from '../../../utils/ramdaEx';

export default function handleAction(type, reducers, defaultState) {
    const typeValue = isSymbol(type) ? type : type.toString();
    const [nextReducer, throwReducer] = isFunction(reducers)
        ? [reducers, reducers]
        : [reducers.next, reducers.throw].map(reducer => (isNil(reducer) ? identity : reducer));

    return (state = defaultState, action) => {
        if (action.type !== typeValue) {
            return state;
        }

        return (action.payload === true ? throwReducer : nextReducer)(state, action);
    };
}
