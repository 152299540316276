import type { AppStateSel } from "../../../globalTypes";
import { Lit } from "../../../lit";
import { pathSelector } from "../../../utils/pathSelector";

export const
    socialAccountsFacebookChatConnected: AppStateSel<boolean> = pathSelector(
        [Lit.socialAccounts, Lit.facebookChat, Lit.facebookChatConnected]
    ),
    socialAccountsFacebookChatConnectedPageId: AppStateSel<boolean> = pathSelector(
        [Lit.socialAccounts, Lit.facebookChat, Lit.facebookChatAccountId]
    ),
    socialAccountsFacebookFeedConnected: AppStateSel<boolean> = pathSelector(
        [Lit.socialAccounts, Lit.facebookFeed, Lit.facebookFeedConnected]
    );

