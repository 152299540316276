import * as main from './pages/main';
import * as link from './pages/link';
import * as style from './pages/style';
import * as mobileView from '../../../PropertiesPanel/view/MobileView/page';
import makePages from '../../../PropertiesPanel/view/makePages';

const propertiesPanelConfig = {
    pages: makePages(main, link, style, mobileView),
    defaultPageId: main.id
};

export default propertiesPanelConfig;
