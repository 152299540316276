import * as R from 'ramda';
import type { TableComponent } from "../flowTypes";

export const onApplyTextCellChangeReducer =
    (state: TableComponent, { payload: { content, cellIndex } }: AnyAction) => {
        const updatePath = ['cells', cellIndex, 'content'];

        if (R.path(updatePath, state) !== content) {
            return R.assocPath(updatePath, content, state);
        }

        return state;
    };
