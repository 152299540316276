const GoogleReviewsDialogId = 'GoogleReviewsDialogId';
const ConnectToGoogleDialogId = 'ConnectToGoogleDialogId';
const FailedGoogleReviewsDialogId = 'FailedGoogleReviewsDialogId';
const ExceptionGoogleReviewsDialogId = 'ExceptionGoogleReviewsDialogId';
const GoogleReviewsPremiumComponentDialogId = 'GoogleReviewsPremiumComponentDialogId';

export {
    GoogleReviewsDialogId,
    ConnectToGoogleDialogId,
    FailedGoogleReviewsDialogId,
    ExceptionGoogleReviewsDialogId,
    GoogleReviewsPremiumComponentDialogId,
    GoogleReviewsDialogId as default,
};
