import Icons from '../../../../view/Icons/index';

const FieldIcons = {
    name: Icons.NAME_ICON,
    phone: Icons.PHONE_ICON,
    message: Icons.MESSAGE_ICON,
    company: Icons.COMPANY_ICON,
    website: Icons.WEBSITE_ICON,
    text: Icons.TEXT_ICON,
    subject: Icons.TEXT_ICON,
    number: Icons.NUMBER_ICON,
    checkbox: Icons.CHECKBOX_ICON,
    radio: Icons.RADIOBUTTON_ICON,
    dropdown: Icons.DROPDOWN_ICON,
    email: Icons.EMAIL_ICON,
    fileupload: Icons.FILEUPLOAD_ICON,
};

export default FieldIcons;
