import React, { useState } from "react";
import cx from "classnames";

import { TooltipCom } from '../../../presentational/Tooltip/TooltipCom';
import { TooltipPosition } from '../../../presentational/Tooltip/constants';
import { Msg } from '../../../../view/intl';

import styles from './PagesTree.css';

type Props = {
    iconClass?: any
}

export const RenderPremiumIcon = ({ iconClass }: Props) => {
    const ref = React.createRef<HTMLElement>();

    const [show, setShow] = useState(false);

    const tooltipProps = {
        show,
        targetRef: ref,
        position: TooltipPosition.TOP,
        theme: {
            container: styles.tooltipContainer,
            tipInfo: styles.tipInfo,
        }
    };

    return (
        <React.Fragment>
            <span
                ref={ref}
                className={cx(styles.premiumIcon, iconClass)}
                onMouseOver={() => setShow(true)}
                onMouseOut={() => setShow(false)}
            />
            <TooltipCom {...tooltipProps}>
                <Msg k="pagestree.planLimit">
                    Page exceeds the plan limit. An upgrade is required to publish
                </Msg>
            </TooltipCom>
        </React.Fragment>
    );
};
