import React from 'react';
import cx from 'classnames';
import { Intl, injectIntl } from "../../../view/intl/index";
import TdButton from '../../presentational/TdButton/index';
import MctaButton from '../MctaButton/index';
import mctaStyles from './styles.css';
import ButtonKind from '../../oneweb/Button/kind';
import TableKind from '../../oneweb/Table/kind';
import TextKind from '../../oneweb/Text/kind';
import ContactFormKind from '../../oneweb/ContactForm/kind';
import { ALIGNMENT_TYPES, SIZE_TYPES } from '../../../constants';
import { iconForAlignmentTypeMap } from '../constants';
import { SOCIAL_KIND } from '../../oneweb/Social/kind';
import { cmpTypes } from "../../MobileViewEditor/constants";
import { TextLike } from "../../oneweb/TextLike/constants";
import { OpeningHours } from "../../oneweb/OpeningHours/constants";
import ImageKind from '../../oneweb/Image/kind';
import MctaPropertyContainer from '../MctaPropertyContainer/index';
import MctaCharButton from '../MctaButton/MctaCharButton';
import {
    COMMON_OPTIONS,
    BUTTON_OPTIONS,
    TEXT_OPTIONS,
    RESIZE_OPTIONS
} from './constants';

type BaseProps = {
    actionTypeToDispatch: string,
    dispatch: Dispatch,
    kind: string,
    alignment?: string,
    size?: string,
    label?: string,
    selectedCmpId?: string,
    availableSpace?: {
        top: number,
        bottom: number
    },
    onMouseLeave?: Function,
    onMouseEnter?: Function
}
type PropTypes = BaseProps & {
    onMouseEnterCollapsedViewActionType: string,
    onMouseLeaveExpandedViewActionType: string,
    expanded: boolean
};

const availableSpaceNeeded = 57;

const getTop = (availableSpace) => {
    let style = { top: 0 };
    if (availableSpace && availableSpace.top < availableSpaceNeeded) {
        style.top = availableSpaceNeeded;
    } else if (availableSpace && availableSpace.bottom < availableSpaceNeeded) {
        style.top = -1 * availableSpaceNeeded;
    }

    return style;
};

const
    alignmentTypes = [ALIGNMENT_TYPES.ALIGN_LEFT, ALIGNMENT_TYPES.ALIGN_CENTER,
        ALIGNMENT_TYPES.ALIGN_RIGHT, ALIGNMENT_TYPES.ALIGN_FULL], // To ensure order of keys,
    sizeTypes = [SIZE_TYPES.SIZE_SMALL, SIZE_TYPES.SIZE_MEDIUM, SIZE_TYPES.SIZE_LARGE],
    alignmentTitleMap = {
        [ButtonKind]: BUTTON_OPTIONS.hoverMessage,
        [SOCIAL_KIND]: COMMON_OPTIONS.hoverMessage,
        [ImageKind]: COMMON_OPTIONS.hoverMessage,
        [ContactFormKind]: BUTTON_OPTIONS.hoverMessage,
        [TextKind]: TEXT_OPTIONS.hoverMessage,
        [TableKind]: TEXT_OPTIONS.hoverMessage,
        [cmpTypes.group]: COMMON_OPTIONS.hoverMessage,
        [TextLike]: COMMON_OPTIONS.hoverMessage,
        [OpeningHours]: COMMON_OPTIONS.hoverMessage,
    },
    iconKeyForAlignmentTypeMap = {
        [ButtonKind]: BUTTON_OPTIONS.iconKey,
        [SOCIAL_KIND]: COMMON_OPTIONS.iconKey,
        [ImageKind]: COMMON_OPTIONS.iconKey,
        [ContactFormKind]: BUTTON_OPTIONS.iconKey,
        [TextKind]: TEXT_OPTIONS.iconKey,
        [TableKind]: TEXT_OPTIONS.iconKey,
        [cmpTypes.group]: COMMON_OPTIONS.iconKey,
        [TextLike]: COMMON_OPTIONS.iconKey,
        [OpeningHours]: COMMON_OPTIONS.iconKey,
    },
    sizeTitleMap = {
        [ImageKind]: RESIZE_OPTIONS.imageHoverMessage,
        [ButtonKind]: RESIZE_OPTIONS.buttonHoverMessage,
        [SOCIAL_KIND]: RESIZE_OPTIONS.defaultHoverMessage,
        [TextLike]: RESIZE_OPTIONS.defaultHoverMessage,
    },
    sizeLabelMap: any = RESIZE_OPTIONS.optionLabel,
    collapsedView = (onMouseEnterCollapsedViewActionType, dispatch, alignment, size, kind, onMouseEnter, sizeLabel) => (
        alignment ?
            <MctaButton
                className={cx(mctaStyles.alignToolbarClosed)}
                icon={iconKeyForAlignmentTypeMap[kind][alignment]}
                onMouseEnter={onMouseEnter || (() => dispatch({ type: onMouseEnterCollapsedViewActionType }))}
                onClick={() => dispatch({ type: onMouseEnterCollapsedViewActionType })}
            /> :
            <MctaPropertyContainer label={sizeLabel}>
                <MctaCharButton
                    className={cx(mctaStyles.alignToolbarClosed, mctaStyles.resizeToolbarClosed)}
                    msg={sizeLabelMap[size]}
                    onMouseEnter={onMouseEnter || (() => dispatch({ type: onMouseEnterCollapsedViewActionType }))}
                    onClick={onMouseEnter || (() => dispatch({ type: onMouseEnterCollapsedViewActionType }))}
                />
            </MctaPropertyContainer>
    ),
    expandedView = (
        actionTypeToDispatch,
        alignment,
        size,
        onMouseLeaveExpandedViewActionType,
        dispatch,
        intl,
        kind,
        availableSpace,
        selectedCmpId,
        onMouseLeave,
        sizeLabel
    ) => {
        const onClick = (selectionValue) => {
            let payload = selectionValue;
            if (selectedCmpId) {
                payload = {
                    value: selectionValue,
                    id: selectedCmpId
                };
            }
            dispatch({
                type: actionTypeToDispatch,
                payload
            });
        };

        const optionHeight = 35;
        let enabledTypes = sizeTypes;
        let selectedOption = size;
        let titleMap: Record<string, any> = sizeTitleMap[kind];
        if (alignment) {
            enabledTypes = alignmentTypes
                .filter(alignmentType => iconForAlignmentTypeMap[kind][alignmentType]);
            selectedOption = alignment;
            titleMap = alignmentTitleMap[kind];
        }

        return <MctaPropertyContainer label={alignment ? '' : sizeLabel}>
            <div
                className={cx(mctaStyles.alignToolbarWrapper)}
            >
                <table
                    style={{ ...getTop(availableSpace), height: (optionHeight * enabledTypes.length) + 10 }}
                    className={cx(mctaStyles.alignToolbar, mctaStyles.alignmentToolbarVertical,)}
                    onMouseLeave={onMouseLeave || (() => dispatch({ type: onMouseLeaveExpandedViewActionType }))}
                >
                    <tbody>{
                        enabledTypes.map(controlType => {
                            const isSelected: boolean = selectedOption === controlType;
                            const className = cx(mctaStyles.alignButton, {
                                [mctaStyles.selected]: isSelected,
                            });
                            const Icon = alignment && iconForAlignmentTypeMap[kind][controlType];
                            return (
                                <tr key={controlType} className="aligmentGroupItem">
                                    <TdButton
                                        isSelected={isSelected}
                                        className={className}
                                        title={intl.msgJoint(titleMap[controlType])}
                                        data-title-position="right"
                                        onClick={() => onClick(controlType)}
                                    >
                                        {Icon ?
                                            <Icon /> :
                                            <span className={cx(mctaStyles.resizeToolbar)}>
                                                {sizeLabelMap[controlType]}
                                            </span>}
                                    </TdButton>
                                </tr>
                            );
                        })
                    }
                    </tbody>
                </table>
            </div>
        </MctaPropertyContainer>;
    },
    AlignmentControls = injectIntl(({
        actionTypeToDispatch,
        onMouseEnterCollapsedViewActionType,
        onMouseLeaveExpandedViewActionType,
        alignment,
        size,
        expanded,
        dispatch,
        intl,
        kind,
        availableSpace,
        selectedCmpId,
        onMouseEnter,
        onMouseLeave,
        label
    }: PropTypes & { intl: Intl }) => (
        expanded
            ? expandedView(
                actionTypeToDispatch,
                alignment,
                size,
                onMouseLeaveExpandedViewActionType,
                dispatch,
                intl,
                kind,
                availableSpace,
                selectedCmpId,
                onMouseLeave,
                label
            )
            : collapsedView(onMouseEnterCollapsedViewActionType, dispatch, alignment, size, kind, onMouseEnter, label)
    ));

type StatefulAlignmentControlsState = { expanded: boolean }
class StatefulAlignmentControls extends React.Component<BaseProps, StatefulAlignmentControlsState> {
    constructor(props: BaseProps) {
        super(props);
        this.state = { expanded: false };
    }

    render() {
        // @ts-ignore
        return <AlignmentControls
            {...this.props}
            onMouseEnter={() => this.setState({ expanded: true })}
            onMouseLeave={() => this.setState({ expanded: false })}
            expanded={this.state.expanded}
        />;
    }
}

export {
    AlignmentControls as default,
    StatefulAlignmentControls
};
