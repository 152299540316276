import * as R from 'ramda';
import { makeActionForwardToSelectedComponent, SELECTED_COMPONENT } from '../../../../../redux/forwardTo';
import makeEpic from '../../../../../epics/makeEpic';
import { tableEditModeVAT } from "./valueActionType";
import {
    TABLE_CELL_MOUSE_DOWN,
    TABLE_CELL_DBL_CLICKED,
    TABLE_ACTIVE_CELL_TEXT_CHANGED,
    APPLY_TEXT_CELL_CHANGE,
    TABLE_MCTA_ALIGN_MOUSE_ENTER,
    TABLE_MCTA_ALIGN_MOUSE_LEAVE,
    TABLE_TEXT_SHADOW_COLOR_CHANGED,
    TABLE_TEXT_SHADOW_COLOR_REMOVED,
    TABLE_TEXT_BOLD_TOGGLE,
    TABLE_TEXT_ITALIC_TOGGLE,
    TABLE_TEXT_UNDERLINE_TOGGLE,
    TABLE_TEXT_HIGHLIGHT_COLOR_CHANGED,
    TABLE_TEXT_HIGHLIGHT_COLOR_REMOVED,
    TABLE_CELL_HORIZONTAL_ALIGNMENT_CHANGED,
    TABLE_TEXT_FONT_FAMILY_CHANGED,
    TABLE_TEXT_SUBSCRIPT_TOGGLE,
    TABLE_TEXT_SUPERSCRIPT_TOGGLE,
    TABLE_LINK_GLOBAL_STYLE_CHANGED,
    TABLE_TEXT_FONT_SIZE_CHANGED,
    TABLE_TEXT_CLEAR_FORMATTING,
    TABLE_CELL_CLEAR_CONTENT,
    TABLE_TEXT_SET_LINK,
    TABLE_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED,
    TABLE_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED,
    TABLE_TEXT_SHADOW_BLUR_RADIUS_CHANGED,
    TABLE_TEXT_COLOR_CHANGED,
    TABLE_TEXT_GLOBAL_STYLE_CHANGED,
    TABLE_CELL_GLOBAL_STYLE_UPDATED,
    TABLE_CELL_GLOBAL_STYLE_CHANGED,
    TABLE_REMOVE_ROW,
    TABLE_REMOVE_ROW_AFTER_EPIC_UPDATE,
    TABLE_MCTA_ADD_COLUMN_PRESSED,
    TABLE_ADD_COLUMN,
    TABLE_REMOVE_COLUMN,
    TABLE_REMOVE_COLUMN_AFTER_EPIC_UPDATE,
    TABLE_CELL_WIDTH_CHANGED,
    TABLE_CELL_WIDTH_CHANGED_FORWARDED,
    TABLE_CELL_HEIGHT_CHANGED,
    TABLE_CELL_HEIGHT_CHANGED_FORWARDED,
    TABLE_TEXT_REMOVE_GLOBAL_STYLES_AND_FORMATS,
    TABLE_CELL_BACKGROUND_COLOR_CHANGED,
    TABLE_CELL_BACKGROUND_COLOR_CHANGED_AUTO_COLOR,
    TABLE_CELL_BACKGROUND_GRADIENT_CHANGED,
    TABLE_CELL_BACKGROUND_GRADIENT_CHANGED_AUTO_COLOR,
    TABLE_SET_ALL_CELLS_EMPTY
} from "../../actionTypes";
import { DefaultCellContentOnDrop, TABLE_GLOBAL_STYLE_FORMATS } from '../../constants';
import {
    ReceiveOnlyComponentsMap,
    SelectedComponentSelector,
    EditModeComponentIdSelector,
    ReceiveOnlySelectedComponentSelector,
    ReceiveOnlySelectedComponentIdSelector
} from "../../../../Workspace/epics/componentsEval/selectorActionTypes";
import WORKSPACE_COMPONENT_KIND from "../../../../Workspace/epics/componentsEval/WORKSPACE_COMPONENT_KIND";
import { makeTinyMceForwardUpdatersFactory } from "../../../../App/epics/tinyMceEpic/makeTinyMceForwardUpdaters";
import { anyOf, receiveOnly } from "../../../../../epics/makeCondition";
import { arrayToTrueMap } from "../../../../../utils/arrayToTrueMap";
import { getNumRowsAndCols } from "../../utils";
import type { EpicUpdater } from "../../../../../epics/flowTypes";
import {
    TINY_MCE_APPLY_CHANGE_FOR_MULTIPLE_INSTANCES,
    TINY_MCE_CHANGE_FOR_MULTIPLE_INSTANCES_APPLIED, TINY_MCE_MULTI_PROCESSING_CANCELED,
    TINY_MCE_PUT_CURSOR_AT_THE_END_OF_EDITOR, TINY_MCE_QUERY_STATE_FOR_GIVEN_CONTENT,
    TINY_MCE_SET_CONTENT
} from "../../../../App/epics/tinyMceEpic/actionTypes";
import { KEY_DOWN, KEY_PRESS } from '../../../../App/actionTypes';
import * as keyCodes from "../../../../App/epics/isKeyPressed/keyCodes";

import { TinyMceExistSelector } from "../../../../App/epics/tinyMceEpic/selectorActionTypes";
import { ROInputIsInFocus } from "../../../../App/epics/userFocus/selectorActionTypes";
import { UserFocusValueActionType } from "../../../../App/epics/userFocus/valueActionType";
import * as userFocusKind from "../../../../App/epics/userFocus/kind";
import { selectionChangeUpdaters } from "./selectionChangeUpdaters";
import type { TableEditModeScope, TableEditModeState } from './flowTypes';
import {
    tableEditModeEpicDefaultScope
} from "./models/scope";
import {
    disableProcessingOfMultipleTinyMCEInstancesInProgress,
    enableProcessingOfMultipleTinyMCEInstancesInProgress,
    nothingInEditModeIndex,
    tableEditModeEpicDefaultState
} from "./models/state";
import { TableComponentKind } from "../../kind";
import { areHtmlStringsEquivalent } from '../../../../../utils/string';
import { ROOpenedDialogsIdsSelector } from '../../../../DialogManager/epic/selectorActionTypes';
import { ROThemeColorSelector } from '../../../../Workspace/epics/stylesheets/selectors';

const
    getFirstCellContent = (state, scope) =>
        scope.selectedTableComponent.cells[state.selectedCellsIndexes[0] || 0].content,
    makeActionForwardToWorkspaceComponentKind = (action, id) => ({
        ...action,
        forwardTo: { id, kind: WORKSPACE_COMPONENT_KIND }
    }),
    UNUSED = '@@UNUSED',
    makeTinyMceForwardUpdaters = makeTinyMceForwardUpdatersFactory(
        (
            triggerActionType: string | Array<string>,
            targetActionType: string
        ): EpicUpdater<TableEditModeState, TableEditModeScope, any, any> => (
            {
                conditions: [
                    ReceiveOnlySelectedComponentSelector,
                    Array.isArray(triggerActionType) ? anyOf(...triggerActionType) : triggerActionType
                ],
                reducer: ({ state, scope, values: [tableComponent, payload] }) => {
                    if (state.processingOfMultipleTinyMCEInstancesInProgress) {
                        return { state, scope };
                    }

                    let actionToDispatch: Action = { type: targetActionType, payload };

                    if (state.cellInEditModeIndex === -1) {
                        return {
                            state: enableProcessingOfMultipleTinyMCEInstancesInProgress(state),
                            scope,
                            actionToDispatch: {
                                type: TINY_MCE_APPLY_CHANGE_FOR_MULTIPLE_INSTANCES,
                                payload: {
                                    contentsArr: tableComponent.cells
                                        .filter((cell, index) => state.selectedCellsIndexes.length === 0
                                            || state.selectedCellsIndexes.indexOf(index) !== -1)
                                        .map(cell => cell.content),
                                    actionCmd: actionToDispatch
                                }
                            }
                        };
                    }

                    return {
                        state,
                        scope,
                        actionToDispatch
                    };
                }
            }
        )
    ),
    forwardToTinyMceConf = {
        setColor: TABLE_TEXT_COLOR_CHANGED,
        setShadowColor: TABLE_TEXT_SHADOW_COLOR_CHANGED,
        setShadowBlurRadius: TABLE_TEXT_SHADOW_BLUR_RADIUS_CHANGED,
        setShadowOffsetX: TABLE_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED,
        setShadowOffsetY: TABLE_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED,
        removeShadow: TABLE_TEXT_SHADOW_COLOR_REMOVED,
        setLinkInContentBlock: UNUSED,
        setLinkAcrossContentBlocks: TABLE_TEXT_SET_LINK,
        removeLink: UNUSED, // TODO: Find out why text can't use setLink, like table doing
        toggleBold: TABLE_TEXT_BOLD_TOGGLE,
        toggleItalic: TABLE_TEXT_ITALIC_TOGGLE,
        toggleUnderline: TABLE_TEXT_UNDERLINE_TOGGLE,
        setHighlightColor: TABLE_TEXT_HIGHLIGHT_COLOR_CHANGED,
        removeHighlightColor: TABLE_TEXT_HIGHLIGHT_COLOR_REMOVED,
        setHorizontalAlignment: TABLE_CELL_HORIZONTAL_ALIGNMENT_CHANGED,
        setFontFamily: TABLE_TEXT_FONT_FAMILY_CHANGED,
        setOrderedList: UNUSED,
        setUnorderedList: UNUSED,
        indent: UNUSED,
        outdent: UNUSED,
        setSubscript: TABLE_TEXT_SUBSCRIPT_TOGGLE,
        setSuperscript: TABLE_TEXT_SUPERSCRIPT_TOGGLE,
        removeGlobalStylesAndFormats: TABLE_TEXT_REMOVE_GLOBAL_STYLES_AND_FORMATS,
        setGlobalStyle: TABLE_TEXT_GLOBAL_STYLE_CHANGED,
        setLinkGlobalStyle: TABLE_LINK_GLOBAL_STYLE_CHANGED,
        setCharacterSpacing: UNUSED,
        setLineSpacing: UNUSED,
        setFontSize: TABLE_TEXT_FONT_SIZE_CHANGED,
        removeFormat: TABLE_TEXT_CLEAR_FORMATTING,
        setParagraphSpacing: UNUSED
    },
    actionsThatHandledByTinyMceInEditModeMap =
        R.pipe(R.values, R.filter(v => v !== UNUSED), arrayToTrueMap)(forwardToTinyMceConf),
    tableEditModeEpic = makeEpic({
        defaultState: tableEditModeEpicDefaultState,
        defaultScope: tableEditModeEpicDefaultScope,
        valueActionType: tableEditModeVAT,
        afterUpdate: ({ prevState, nextState, nextScope }) => {
            const
                nextCellIsInEditMode = nextState.cellInEditModeIndex
                    !== tableEditModeEpicDefaultState.cellInEditModeIndex,
                cellInEditModeChanged = prevState.cellInEditModeIndex !== nextState.cellInEditModeIndex;

            if (nextCellIsInEditMode && cellInEditModeChanged) {
                return {
                    state: nextState,
                    scope: nextScope,
                    afterUpdateActions: [{ type: TINY_MCE_PUT_CURSOR_AT_THE_END_OF_EDITOR }]
                };
            }

            let afterUpdateActions;

            if (nextScope.selectedTableComponent) {
                const firstNextSelectedCellIndex = nextState.selectedCellsIndexes[0];

                if (prevState.selectedCellsIndexes[0] !== firstNextSelectedCellIndex) {
                    afterUpdateActions = [{
                        type: TINY_MCE_QUERY_STATE_FOR_GIVEN_CONTENT,
                        payload: getFirstCellContent(nextState, nextScope)
                    }];
                }
            }

            return {
                state: nextState,
                scope: nextScope,
                afterUpdateActions
            };
        },
        updaters: [
            {
                conditions: [
                    TABLE_CELL_BACKGROUND_COLOR_CHANGED_AUTO_COLOR,
                    ROThemeColorSelector,
                ],
                reducer: ({ state, scope, values: [{ themeColor }, themeColorsData] }) => {
                    return {
                        state,
                        scope,
                        actionToDispatch: themeColorsData[themeColor] ? (({
                            type: TABLE_CELL_BACKGROUND_COLOR_CHANGED,
                            payload: { color: themeColorsData[themeColor] },
                        }) as Action) : null,
                    };
                },
            },
            {
                conditions: [
                    TABLE_CELL_BACKGROUND_GRADIENT_CHANGED_AUTO_COLOR,
                    ROThemeColorSelector,
                ],
                reducer: ({ state, scope, values: [{ themeColor }, themeColorsData] }) => {
                    return {
                        state,
                        scope,
                        actionToDispatch: themeColorsData[themeColor] ? (({
                            type: TABLE_CELL_BACKGROUND_GRADIENT_CHANGED,
                            payload: { color: themeColorsData[themeColor] },
                        }) as Action) : null,
                    };
                },
            },
            {
                conditions: [TABLE_CELL_GLOBAL_STYLE_UPDATED],
                reducer: ({ state, scope, values: [payload] }) => {
                    return {
                        state,
                        scope,
                        multipleActionsToDispatch: [
                            ({
                                type: TABLE_TEXT_REMOVE_GLOBAL_STYLES_AND_FORMATS,
                                payload: { formats: TABLE_GLOBAL_STYLE_FORMATS },
                            } as Action),
                            ({
                                type: TABLE_CELL_GLOBAL_STYLE_CHANGED,
                                payload,
                                forwardTo: { kind: SELECTED_COMPONENT },
                            } as Action),
                        ],
                    };
                },
            },
            {
                conditions: [TINY_MCE_CHANGE_FOR_MULTIPLE_INSTANCES_APPLIED],
                reducer: ({ state, scope }) => {
                    return {
                        state: disableProcessingOfMultipleTinyMCEInstancesInProgress(state),
                        scope,
                        actionToDispatch: scope.selectedTableComponent ? {
                            type: TINY_MCE_QUERY_STATE_FOR_GIVEN_CONTENT,
                            payload: getFirstCellContent(state, scope)
                        } : null
                    };
                }
            },
            {
                conditions: [TINY_MCE_MULTI_PROCESSING_CANCELED],
                reducer: ({ state, scope }) => {
                    return {
                        state: disableProcessingOfMultipleTinyMCEInstancesInProgress(state),
                        scope
                    };
                }
            },
            {
                conditions: [
                    ReceiveOnlySelectedComponentSelector,
                    TABLE_REMOVE_ROW
                ],
                reducer: ({ values: [component], state, scope }) => {
                    // boundry indexes of following array are 6, 7, 8
                    // 0 1 2
                    // 3 4 5
                    // 6 7 8
                    const
                        { numRows, numCols } = getNumRowsAndCols(component.cells),
                        maxIndex = numRows * numCols,
                        boundaryIndexes = R.range(maxIndex - numCols, maxIndex),
                        boundaryIndexesComparator = index => {
                            return boundaryIndexes.indexOf(index) > -1 ? (index - numCols) : index;
                        },
                        actionToDispatch: Action = makeActionForwardToSelectedComponent({
                            type: TABLE_REMOVE_ROW_AFTER_EPIC_UPDATE,
                            payload: { fisrtSelectedCellIndex: state.selectedCellsIndexes[0] }
                        });

                    return {
                        state: {
                            ...state,
                            cellInEditModeIndex: nothingInEditModeIndex,
                            selectedCellsIndexes: state.selectedCellsIndexes.map(boundaryIndexesComparator)
                        },
                        scope,
                        actionToDispatch
                    };
                }
            },
            {
                conditions: [
                    ReceiveOnlySelectedComponentSelector,
                    TABLE_REMOVE_COLUMN
                ],
                reducer: ({ values: [component], state, scope }) => {
                    // boundry indexes of following array are 2, 5, 8
                    // 0 1 2
                    // 3 4 5
                    // 6 7 8
                    const
                        { numRows, numCols } = getNumRowsAndCols(component.cells),
                        getRowIndex = (index, numCols) => Math.floor(index / numCols),
                        boundaryIndexes = R.range(0, (numRows * numCols)).filter(i => i % numCols === (numCols - 1)),
                        indexInBoundaryIndex = index => Number(boundaryIndexes.some(bi => bi === index)),
                        boundaryIndexesComparator = index => {
                            return index > -1
                                ? (index - getRowIndex(index, numCols)) - indexInBoundaryIndex(index)
                                : index;
                        },
                        actionToDispatch: Action = makeActionForwardToSelectedComponent({
                            type: TABLE_REMOVE_COLUMN_AFTER_EPIC_UPDATE,
                            payload: { fisrtSelectedCellIndex: state.selectedCellsIndexes[0] }
                        });

                    return {
                        state: {
                            ...state,
                            cellInEditModeIndex: nothingInEditModeIndex,
                            selectedCellsIndexes: state.selectedCellsIndexes.map(boundaryIndexesComparator)
                        },
                        scope,
                        actionToDispatch
                    };
                }
            },
            {
                conditions: [
                    ReceiveOnlySelectedComponentSelector,
                    TABLE_MCTA_ADD_COLUMN_PRESSED
                ],
                reducer: ({ values: [component], state, scope }) => {
                    const
                        { numCols } = getNumRowsAndCols(component.cells),
                        updateIndex = (index) => index + (index > numCols ? Math.floor(index / numCols) : 0),
                        actionToDispatch: Action = makeActionForwardToSelectedComponent({
                            type: TABLE_ADD_COLUMN,
                            payload: { firstSelectedCellIndex: state.selectedCellsIndexes[0] }
                        });

                    return {
                        state: R.evolve({
                            selectedCellsIndexes: R.map(updateIndex),
                            cellInEditModeIndex: updateIndex
                        }, state),
                        scope,
                        actionToDispatch
                    };
                }
            },
            {
                conditions: [TABLE_MCTA_ALIGN_MOUSE_ENTER],
                reducer: ({ state, scope }) => ({
                    state: R.evolve({
                        mctaHorizontalAlignControlExpanded: () => true
                    }, state),
                    scope
                })
            },
            {
                conditions: [TABLE_MCTA_ALIGN_MOUSE_LEAVE],
                reducer: ({ state, scope }) => ({
                    state: R.evolve({
                        mctaHorizontalAlignControlExpanded: () => false
                    }, state),
                    scope
                })
            },
            {
                conditions: [TABLE_CELL_MOUSE_DOWN],
                reducer: ({ values: [cellIndex], state, scope }) =>
                    ({
                        state: R.evolve({
                            selectedCellsIndexes: () => [cellIndex],
                            cellInEditModeIndex: () => nothingInEditModeIndex
                        }, state),
                        scope
                    })
            },
            {
                conditions: [TABLE_CELL_DBL_CLICKED],
                reducer: ({ values: [cellIndex], state, scope }) =>
                    ({
                        state: R.evolve({
                            selectedCellsIndexes: () => [cellIndex],
                            cellInEditModeIndex: () => cellIndex
                        }, state),
                        scope
                    })
            },
            {
                conditions: [
                    // we can't use KEY_DOWN for this case as it will be called for non printable characters as well
                    // KEY_PRESS dispatched only for printable characters
                    KEY_PRESS,
                    ROInputIsInFocus,
                    ROOpenedDialogsIdsSelector,
                ],
                reducer: ({
                    values: [{ key: content, ctrlKey, targetClassName }, inputIsInFocus, dialogIdList],
                    state,
                    scope
                }) => {
                    if (dialogIdList.length !== 0) { return { state, scope }; }

                    const { selectedCellsIndexes, cellInEditModeIndex } = state;

                    // The name should be same as wbtgen/src/view/common/Input/InputField.css -> inputField
                    const isKeyPressFromPropertiesPanel = targetClassName?.indexOf("inputField") > 0;

                    if (!ctrlKey && selectedCellsIndexes.length && !inputIsInFocus && !isKeyPressFromPropertiesPanel) {
                        if (cellInEditModeIndex === nothingInEditModeIndex) {
                            const
                                actionToDispatch: Action = makeActionForwardToSelectedComponent({
                                    type: TABLE_CELL_CLEAR_CONTENT,
                                    payload: {
                                        keepStyles: true
                                    }
                                }),
                                cellInEditModeIndex = state.selectedCellsIndexes[0];

                            return {
                                state: R.evolve({
                                    cellInEditModeIndex: () => cellInEditModeIndex,
                                    selectedCellsIndexes: () => [cellInEditModeIndex]
                                }, state),
                                scope: R.evolve({
                                    textToInsertAfterTinyMceInitialized: () => content,
                                    shouldQueueKeyPressValues: () => true
                                }, scope),
                                actionToDispatch
                            };
                        } else if (scope.shouldQueueKeyPressValues) {
                            return {
                                state,
                                scope: R.evolve({
                                    textToInsertAfterTinyMceInitialized: (v) => v + content
                                }, scope)
                            };
                        }
                    }

                    return { state, scope };
                }
            },
            {
                conditions: [TinyMceExistSelector],
                reducer: ({ values: [tinyMceExist], state, scope }) => {
                    let actionToDispatch: Action | null = null;

                    if (tinyMceExist && scope.textToInsertAfterTinyMceInitialized) {
                        actionToDispatch = {
                            type: TINY_MCE_SET_CONTENT,
                            payload: scope.textToInsertAfterTinyMceInitialized
                        };
                    }

                    return {
                        state,
                        scope: R.evolve({
                            textToInsertAfterTinyMceInitialized: () =>
                                tableEditModeEpicDefaultScope.textToInsertAfterTinyMceInitialized,
                            shouldQueueKeyPressValues: () => false
                        }, scope),
                        actionToDispatch
                    };
                }
            },
            {
                conditions: [
                    receiveOnly(UserFocusValueActionType),
                    KEY_DOWN
                ],
                reducer: ({
                    values: [userFocus, { keyCode, ctrlKey, preventDefault, targetClassName }],
                    state,
                    scope
                }) => {
                    const { selectedCellsIndexes, cellInEditModeIndex } = state;
                    const componentInFocus = !userFocus.comboBoxOpen && !userFocus.inputInFocus;
                    const tableInFocus = (
                        componentInFocus &&
                        userFocus.kind === userFocusKind.EDIT_TABLE_COMPONENT &&
                        cellInEditModeIndex === nothingInEditModeIndex
                    );

                    // The name should be same as wbtgen/src/view/common/Input/InputField.css -> inputField
                    const isKeyPressFromPropertiesPanel = targetClassName?.indexOf("inputField") > 0;

                    if (tableInFocus && selectedCellsIndexes.length && !isKeyPressFromPropertiesPanel) {
                        if (keyCode === keyCodes.DELETE || keyCode === keyCodes.BACKSPACE) {
                            const actionToDispatch: Action = makeActionForwardToSelectedComponent({
                                type: TABLE_CELL_CLEAR_CONTENT,
                                payload: {
                                    keepStyles: false
                                }
                            });

                            return {
                                state,
                                scope,
                                actionToDispatch
                            };
                        } else if (keyCode === keyCodes.ENTER || keyCode === keyCodes.F2) {
                            const cellInEditModeIndex = selectedCellsIndexes[0];
                            return {
                                state: R.evolve({
                                    cellInEditModeIndex: () => cellInEditModeIndex,
                                    selectedCellsIndexes: () => [cellInEditModeIndex]
                                }, state),
                                scope
                            };
                        } else if (ctrlKey) {
                            let actionToDispatch;
                            if (keyCode === 66) {
                                // ctrl + B
                                actionToDispatch = makeActionForwardToSelectedComponent({
                                    type: TABLE_TEXT_BOLD_TOGGLE,
                                    payload: null
                                });
                            } else if (keyCode === 73) {
                                // ctrl + I
                                actionToDispatch = makeActionForwardToSelectedComponent({
                                    type: TABLE_TEXT_ITALIC_TOGGLE,
                                    payload: null
                                });
                            } else if (keyCode === 85) {
                                // ctrl + U
                                actionToDispatch = makeActionForwardToSelectedComponent({
                                    type: TABLE_TEXT_UNDERLINE_TOGGLE,
                                    payload: null
                                });
                            }

                            if (actionToDispatch) {
                                return { state, scope, actionToDispatch };
                            }
                        }
                    } else if (
                        tableInFocus &&
                        selectedCellsIndexes.length === 0 &&
                        (keyCode === keyCodes.DELETE || keyCode === keyCodes.BACKSPACE)
                    ) {
                        return {
                            state,
                            scope,
                            actionToDispatch: makeActionForwardToSelectedComponent({
                                type: TABLE_SET_ALL_CELLS_EMPTY
                            })
                        };
                    }

                    const cellInFocus = (
                        componentInFocus &&
                        userFocus.kind === userFocusKind.EDIT_TABLE_COMPONENT_CELL &&
                        cellInEditModeIndex !== nothingInEditModeIndex
                    );

                    if (cellInFocus && keyCode === keyCodes.ESCAPE) {
                        return {
                            state: R.evolve({
                                cellInEditModeIndex: () => nothingInEditModeIndex,
                                selectedCellsIndexes: () => [cellInEditModeIndex]
                            }, state),
                            scope
                        };
                    }

                    if (keyCode === keyCodes.TAB && (cellInFocus || tableInFocus)) {
                        preventDefault();
                    }

                    return { state, scope };
                }
            },
            {
                conditions: [
                    EditModeComponentIdSelector,
                    ReceiveOnlyComponentsMap
                ],
                reducer: ({ values: [editModeComponentId, componentsMap], state, scope }) => {
                    if (editModeComponentId && componentsMap[editModeComponentId].kind === TableComponentKind) {
                        return {
                            state,
                            scope,
                            actionToDispatch: {
                                type: TINY_MCE_QUERY_STATE_FOR_GIVEN_CONTENT,
                                payload: getFirstCellContent(state, scope)
                            }
                        };
                    }

                    // reset only on exit of table edit mode
                    return ({ state: tableEditModeEpicDefaultState, scope });
                }
            },
            {
                conditions: [
                    TABLE_ACTIVE_CELL_TEXT_CHANGED,
                    ReceiveOnlySelectedComponentIdSelector,
                    ReceiveOnlyComponentsMap,
                ],
                reducer: ({
                    values: [payload, selectedComponentId, componentsMap],
                    state,
                    scope
                }) => {
                    let { componentId, cellIndex } = payload;

                    if (!componentId || !R.is(Number, cellIndex)) {
                        // This case will happen when tinyMce epic notify about content change after actions like set bold
                        cellIndex = state.selectedCellsIndexes[0];
                        componentId = selectedComponentId;
                    }

                    if (!componentId || !R.is(Number, cellIndex)) {
                        throw new Error('componentId and cellIndex should be defined to apply text changes');
                    }

                    if (
                        // at times, this action is received after the component is deleted
                        // or the componentsMap is updated, due to page change
                        !componentsMap[componentId]
                        || areHtmlStringsEquivalent(
                            componentsMap[componentId].cells[cellIndex].content,
                            (payload.content || DefaultCellContentOnDrop)
                        )
                    ) {
                        return { state, scope };
                    }

                    const
                        actionToDispatch: Action = makeActionForwardToWorkspaceComponentKind({
                            amendToSelf: true,
                            type: APPLY_TEXT_CELL_CHANGE,
                            payload: { content: payload.content, cellIndex },
                        }, componentId);

                    return { state, scope, actionToDispatch };
                }
            },
            {
                conditions: [TABLE_CELL_WIDTH_CHANGED],
                reducer: ({ state, scope, values: [{ text, componentId }] }) => {
                    return {
                        state,
                        scope,
                        actionToDispatch: makeActionForwardToWorkspaceComponentKind({
                            type: TABLE_CELL_WIDTH_CHANGED_FORWARDED,
                            payload: text,
                        }, componentId)
                    };
                }
            },
            {
                conditions: [TABLE_CELL_HEIGHT_CHANGED],
                reducer: ({ state, scope, values: [{ text, componentId }] }) => {
                    return {
                        state,
                        scope,
                        actionToDispatch: makeActionForwardToWorkspaceComponentKind({
                            type: TABLE_CELL_HEIGHT_CHANGED_FORWARDED,
                            payload: text,
                        }, componentId)
                    };
                }
            },
            {
                conditions: [SelectedComponentSelector],
                reducer: ({ state, scope, values: [selectedComponent] }) => {
                    if (selectedComponent && selectedComponent.kind === TableComponentKind) {
                        return {
                            state,
                            scope: { ...scope, selectedTableComponent: selectedComponent }
                        };
                    }
                    return {
                        state,
                        scope: { ...scope, selectedTableComponent: null }
                    };
                }
            },
            ...selectionChangeUpdaters,
            ...makeTinyMceForwardUpdaters(forwardToTinyMceConf)
        ]
    });

export {
    tableEditModeEpic,
    actionsThatHandledByTinyMceInEditModeMap,
};

