import * as R from 'ramda';
import { valueActionType as siteDataValueActionType } from "../App/epics/siteData/valueActionType";
import pageDataActionType from "../App/epics/pageDataset/valueActionType";
import { makeValueReducer } from "../../redux/makeReducer/index";
import getPageGoogleFonts from "./getPageGoogleFonts";

export default makeValueReducer({
    defaultState: [],
    handleActions: {
        [pageDataActionType]: (_, { payload: pageData }: AnyAction) => R.uniq([..._, ...getPageGoogleFonts(pageData)]),
        [siteDataValueActionType]: (_, { payload: siteData }: AnyAction) => {
            return R.uniq([..._, ...siteData.fonts]).filter(fontName => fontName.indexOf("undefined") === -1);
        }
    }
});
