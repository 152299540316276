import type { ResourceMetadata } from "../../../../../wbtgen/src/redux/modules/children/fileChooser/flowTypes";

export const
    FC_ONEWEBMEDIA_PATH = "onewebmedia",
    WEBSPACE_PREFIX = 'webspace:';

const assetResponseMask = {
    'etag': null,
    'contentType': null,
    'filesize': null,
    'width': null,
    'height': null,
    'alpha': null,
    'bpp': null,
    'animated': false,
    'recommendedFormat': null,
    'image': null
};

export const processAsset = (asset: ResourceMetadata, path: string|null): ResourceMetadata => {
    let finalAsset:ResourceMetadata = Object.keys(assetResponseMask).reduce((acc, k) => ({
        ...acc,
        [k]: asset[k] || assetResponseMask[k]
    }), {} as ResourceMetadata);

    if (finalAsset.image) {
        finalAsset = finalAsset.image.Copyright ?
            { ...finalAsset, image: { Copyright: finalAsset.image.Copyright } } :
            { ...finalAsset, image: null };
    }
    finalAsset.url = WEBSPACE_PREFIX + '/' + path;

    return finalAsset;
};

export const getFileNameFromAssetUrl = (url) => {
    return url.split('/').slice(-1)[0];
};

export const getRelativeUrl = (url: string, baseUrl: string): string => {
    let origin = url.match(/^(webspace|repository)\:\/(.*)$/);

    if (origin) {
        if (origin[1] === 'webspace') {
            return baseUrl + '/webspace/' + origin[2];
        }
        return '/api/v1/repository/webspace/' + origin[2];
    }
    return '';
};
