import type { TinyMceEditor } from "../../../flowTypes";

export const selectAllText = (editor: TinyMceEditor, excludeBr: boolean = false) => {
    // Create range from first to last block element that is not a div
    // This prevents any div/nonMceEditable content at the beginning from being edited

    const
        rootLevelElements = editor.dom.$().children(),
        editableElements = rootLevelElements.filter(':not(.mceNonEditable)');

    if (editableElements.length === rootLevelElements.length) {
        editor.selection.select(editor.getBody(), true);
        return;
    }

    const range = editor.selection.getRng();
    const firstElement = editableElements[0];
    const lastElement = editableElements[editableElements.length - 1];

    range.setStart(firstElement, 0);
    if (excludeBr && lastElement.lastChild && lastElement.lastChild.tagName === 'BR') {
        range.setEnd(lastElement, lastElement.childNodes.length - 1);
    } else {
        range.setEnd(lastElement, lastElement.childNodes.length);
    }

    editor.selection.setRng(range);
};

// Assuming putCursorAtTheEndOfEditor
export const putCursorAtTheEndOfEditor = (editor: TinyMceEditor) => {
    selectAllText(editor, true);
    editor.selection.collapse(false);
};

const WORD_REGEXP = /^\w*$/;
export const selectWord = (editor: TinyMceEditor) => {
    let selection = editor.selection.getSel();
    if (!selection || selection.rangeCount < 1) return;
    let range = selection.getRangeAt(0);
    let node: any = selection.anchorNode;

    if (!node) return;

    // Extend the range backward until it matches word beginning
    while ((range.startOffset > 0) && range.toString().match(WORD_REGEXP)) {
        range.setStart(node, (range.startOffset - 1));
    }
    // Restore the valid word match after overshooting
    if (!range.toString().match(WORD_REGEXP)) {
        range.setStart(node, range.startOffset + 1);
    }

    // Extend the range forward until it matches word ending
    // $FlowFixMe: WBTGEN-9962: length property doesn't exist in Node
    while ((range.endOffset < node.length) && range.toString().match(WORD_REGEXP)) {
        range.setEnd(node, range.endOffset + 1);
    }
    // Restore the valid word match after overshooting
    if (!range.toString().match(WORD_REGEXP)) {
        range.setEnd(node, range.endOffset - 1);
    }

    editor.selection.setRng(range);
};
