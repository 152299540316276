export const
    TABLE_SET_ALL_CELLS_EMPTY = 'TABLE_SET_ALL_CELLS_EMPTY',
    TABLE_MCTA_ALIGN_MOUSE_ENTER = 'TABLE_MCTA_ALIGN_MOUSE_ENTER',
    TABLE_MCTA_ALIGN_MOUSE_LEAVE = 'TABLE_MCTA_ALIGN_MOUSE_LEAVE',

    /* TODO Improve action names */
    TABLE_CELL_GLOBAL_STYLE_CHANGED = 'TABLE_CELL_GLOBAL_STYLE_CHANGED',
    TABLE_CELL_GLOBAL_STYLE_UPDATED = 'TABLE_CELL_GLOBAL_STYLE_UPDATED',

    /* Table cell background */
    TABLE_CELL_BACKGROUND_COLOR_CHANGED = 'TABLE_CELL_BACKGROUND_COLOR_CHANGED',
    TABLE_CELL_BACKGROUND_COLOR_REMOVED = 'TABLE_CELL_BACKGROUND_COLOR_REMOVED',
    TABLE_CELL_BACKGROUND_GRADIENT_CHANGED = 'TABLE_CELL_BACKGROUND_GRADIENT_CHANGED',
    TABLE_CELL_BACKGROUND_GRADIENT_REMOVED = 'TABLE_CELL_BACKGROUND_GRADIENT_REMOVED',
    TABLE_CELL_BACKGROUND_OPACITY_CHANGED = 'TABLE_CELL_BACKGROUND_OPACITY_CHANGED',
    TABLE_CELL_BACKGROUND_GRADIENT_DIRECTION_CHANGED = 'TABLE_CELL_BACKGROUND_GRADIENT_DIRECTION_CHANGED',
    TABLE_CELL_BACKGROUND_GRADIENT_FADE_POINT_CHANGED = 'TABLE_CELL_BACKGROUND_GRADIENT_FADE_POINT_CHANGED',
    TABLE_CELL_BACKGROUND_ASSET_CHANGED = 'TABLE_CELL_BACKGROUND_ASSET_CHANGED',
    TABLE_CELL_BACKGROND_ASSET_REMOVED = 'TABLE_CELL_BACKGROND_ASSET_REMOVED',
    TABLE_CELL_BACKGROUND_ASSET_REPEAT_CHANGED = 'TABLE_CELL_BACKGROUND_ASSET_REPEAT_CHANGED',
    TABLE_CELL_BACKGROUND_ASSET_POSITION_CHANGED = 'TABLE_CELL_BACKGROUND_ASSET_POSITION_CHANGED',
    TABLE_CELL_BACKGROUND_ASSET_FITTING_CHANGED = 'TABLE_CELL_BACKGROUND_ASSET_FITTING_CHANGED',
    TABLE_CELL_BACKGROUND_ASSET_SCALE_CHANGED = 'TABLE_CELL_BACKGROUND_ASSET_SCALE_CHANGED',

    /* Table cell border */

    TABLE_CELL_BORDER_STYLE_CHANGED = 'TABLE_CELL_BORDER_STYLE_CHANGED',
    TABLE_CELL_BORDER_COLOR_CHANGED = 'TABLE_CELL_BORDER_COLOR_CHANGED',
    TABLE_CELL_BORDER_COLOR_REMOVED = 'TABLE_CELL_BORDER_COLOR_REMOVED',
    TABLE_CELL_BORDER_OPACITY_CHANGED = 'TABLE_CELL_BORDER_OPACITY_CHANGED',
    TABLE_CELL_BORDER_WIDTH_CHANGED = 'TABLE_CELL_BORDER_WIDTH_CHANGED',
    TABLE_CELL_CORNER_CHANGED = 'TABLE_CELL_CORNER_CHANGED',

    TABLE_CELL_VERTICAL_ALIGNMENT_CHANGED = 'TABLE_CELL_VERTICAL_ALIGNMENT_CHANGED',
    TABLE_CELL_HORIZONTAL_ALIGNMENT_CHANGED = 'TABLE_CELL_HORIZONTAL_ALIGNMENT_CHANGED',

    TABLE_CELL_WIDTH_CHANGED = 'TABLE_CELL_WIDTH_CHANGED',
    TABLE_CELL_WIDTH_CHANGED_FORWARDED = 'TABLE_CELL_WIDTH_CHANGED_FORWARDED',
    TABLE_CELL_HEIGHT_CHANGED = 'TABLE_CELL_HEIGHT_CHANGED',
    TABLE_CELL_HEIGHT_CHANGED_FORWARDED = 'TABLE_CELL_HEIGHT_CHANGED_FORWARDED',

    TABLE_CELL_SPACING_CHANGED = 'TABLE_CELL_SPACING_CHANGED',

    TABLE_TEXT_GLOBAL_STYLE_CHANGED = 'TABLE_TEXT_GLOBAL_STYLE_CHANGED',
    TABLE_LINK_GLOBAL_STYLE_CHANGED = 'TABLE_LINK_GLOBAL_STYLE_CHANGED',

    TABLE_TEXT_REMOVE_GLOBAL_STYLES_AND_FORMATS = 'TABLE_TEXT_REMOVE_GLOBAL_STYLES_AND_FORMATS',

    TABLE_TEXT_FONT_FAMILY_CHANGED = 'TABLE_TEXT_FONT_FAMILY_CHANGED',
    TABLE_TEXT_FONT_SIZE_CHANGED = 'TABLE_TEXT_FONT_SIZE_CHANGED',
    TABLE_TEXT_COLOR_CHANGED = 'TABLE_TEXT_COLOR_CHANGED',
    TABLE_TEXT_COLOR_REMOVED = 'TABLE_TEXT_COLOR_REMOVED',

    TABLE_TEXT_HIGHLIGHT_COLOR_CHANGED = 'TABLE_TEXT_HIGHLIGHT_COLOR_CHANGED',
    TABLE_TEXT_HIGHLIGHT_COLOR_REMOVED = 'TABLE_TEXT_HIGHLIGHT_COLOR_REMOVED',

    TABLE_TEXT_BOLD_TOGGLE = 'TABLE_TEXT_BOLD_TOGGLE',
    TABLE_TEXT_ITALIC_TOGGLE = 'TABLE_TEXT_ITALIC_TOGGLE',
    TABLE_TEXT_UNDERLINE_TOGGLE = 'TABLE_TEXT_UNDERLINE_TOGGLE',

    TABLE_TEXT_ALIGNMENT_CHANGED = 'TABLE_TEXT_ALIGNMENT_CHANGED',

    TABLE_TEXT_SUPERSCRIPT_TOGGLE = 'TABLE_TEXT_SUPERSCRIPT_TOGGLE',
    TABLE_TEXT_SUBSCRIPT_TOGGLE = 'TABLE_TEXT_SUBSCRIPT_TOGGLE',

    TEXT_SHADOW_COLOR_PICKER_CLICKED = 'TEXT_SHADOW_COLOR_PICKER_CLICKED',
    TABLE_TEXT_SHADOW_COLOR_CHANGED = 'TABLE_TEXT_SHADOW_COLOR_CHANGED',
    TABLE_TEXT_SHADOW_COLOR_REMOVED = 'TABLE_TEXT_SHADOW_COLOR_REMOVED',

    TABLE_TEXT_SHADOW_BLUR_RADIUS_CHANGED = 'TABLE_TEXT_SHADOW_BLUR_RADIUS_CHANGED',
    TABLE_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED = 'TABLE_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED',
    TABLE_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED = 'TABLE_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED',

    TABLE_TEXT_COLOR_CHANGED_AUTO_COLOR = 'TABLE_TEXT_COLOR_CHANGED_AUTO_COLOR',
    TABLE_TEXT_HIGHLIGHT_COLOR_CHANGED_AUTO_COLOR = 'TABLE_TEXT_HIGHLIGHT_COLOR_CHANGED_AUTO_COLOR',
    TABLE_TEXT_SHADOW_COLOR_CHANGED_AUTO_COLOR = 'TABLE_TEXT_SHADOW_COLOR_CHANGED_AUTO_COLOR',
    TABLE_TEXT_SHADOW_BLUR_RADIUS_CHANGED_AUTO_COLOR = 'TABLE_TEXT_SHADOW_BLUR_RADIUS_CHANGED_AUTO_COLOR',
    TABLE_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED_AUTO_COLOR = 'TABLE_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED_AUTO_COLOR',
    TABLE_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED_AUTO_COLOR = 'TABLE_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED_AUTO_COLOR',
    TABLE_CELL_BORDER_COLOR_CHANGED_AUTO_COLOR = 'TABLE_CELL_BORDER_COLOR_CHANGED_AUTO_COLOR',
    TABLE_CELL_BORDER_COLOR_REMOVED_AUTO_COLOR = 'TABLE_CELL_BORDER_COLOR_REMOVED_AUTO_COLOR',
    TABLE_CELL_BACKGROUND_COLOR_CHANGED_AUTO_COLOR = 'TABLE_CELL_BACKGROUND_COLOR_CHANGED_AUTO_COLOR',
    TABLE_CELL_BACKGROUND_COLOR_UNSET_AUTO_COLOR = 'TABLE_CELL_BACKGROUND_COLOR_UNSET_AUTO_COLOR',
    TABLE_CELL_BACKGROUND_GRADIENT_CHANGED_AUTO_COLOR = 'TABLE_CELL_BACKGROUND_GRADIENT_CHANGED_AUTO_COLOR',
    TABLE_CELL_BACKGROUND_GRADIENT_UNSET_AUTO_COLOR = 'TABLE_CELL_BACKGROUND_GRADIENT_UNSET_AUTO_COLOR',
    TABLE_CELL_BACKGROUND_OPACITY_CHANGED_AUTO_COLOR = 'TABLE_CELL_BACKGROUND_OPACITY_CHANGED_AUTO_COLOR',

    TABLE_ADD_ROW = 'TABLE_ADD_ROW',
    TABLE_MCTA_ADD_COLUMN_PRESSED = 'TABLE_MCTA_ADD_COLUMN_PRESSED',
    TABLE_ADD_COLUMN = 'TABLE_ADD_COLUMN',

    TABLE_REMOVE_ROW = 'TABLE_REMOVE_ROW',
    TABLE_REMOVE_ROW_AFTER_EPIC_UPDATE = 'TABLE_REMOVE_ROW_AFTER_EPIC_UPDATE',

    TABLE_REMOVE_COLUMN = 'TABLE_REMOVE_COLUMN',
    TABLE_REMOVE_COLUMN_AFTER_EPIC_UPDATE = 'TABLE_REMOVE_COLUMN_AFTER_EPIC_UPDATE',

    TABLE_CELL_CLEAR_CONTENT = 'TABLE_CELL_CLEAR_CONTENT',
    TABLE_TEXT_SET_LINK = 'TABEL_TEXT_SET_LINK',
    TABLE_TEXT_CLEAR_FORMATTING = 'TABLE_TEXT_CLEAR_FORMATTING',
    TABLE_CELL_DBL_CLICKED = "TABLE_CELL_DBL_CLICKED_IN_EDIT_MODE",
    TABLE_CELL_MOUSE_DOWN = "TABLE_CELL_MOUSE_DOWN",
    TABLE_ACTIVE_CELL_TEXT_CHANGED = 'TABLE_ACTIVE_CELL_TEXT_CHANGED',
    APPLY_TEXT_CELL_CHANGE = 'APPLY_TEXT_CELL_CHANGE';
