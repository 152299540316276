/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useIntl } from '../../../../../../../view/intl/index';

import { FIELD_LABELS } from "../constants";

import { ValidateInput } from '../Components/ValidateInput';

import { selectErrorFields } from '../../../../epics/AITextEpic/selectors';

type Props = {
    websiteTitle: string,
    onChangeAction: Function,
}

export const ProjectTitleField = ({ websiteTitle = '', onChangeAction }: Props) => {
    const intl = useIntl();
    const errorFields = useSelector(selectErrorFields);

    const fieldKey = FIELD_LABELS.websiteTitle;
    const isInvalidField = errorFields.includes(fieldKey);
    const [isLong, setIsLong] = useState(isInvalidField);

    const onChange = (key, value, errorflag) => {
        setIsLong(errorflag);
        onChangeAction(key, value);
    };

    return (
        <ValidateInput
            fieldKey={fieldKey}
            fieldLabel={intl.msgJoint("msg: component.text.createAIText.dialog.generalPreferences.projectTitle.label {What is your website title? (optional)}")}
            placeholder="msg: component.text.createAIText.dialog.generalPreferences.projectTitle.placeholder {e.g. The Corner Café}"
            fieldValue={websiteTitle}
            errorFields={errorFields}
            isInvalid={isLong}
            onChange={onChange}
            validationMsg={intl.msgJoint("msg: common.error.longInput {The entered text is too long.}")}
            dataTestId="ai-text-project-title-field"
        />);
};
