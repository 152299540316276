import { makeEpic } from "../../../epics/makeEpic";
import { withSelector } from "../../../epics/makeCondition";
import {
    APP_MOUNT,
    SITE_SUBSCRIPTION_DATA_LOAD_SUCCESS_FOR_GA_CAMP
} from "../../App/actionTypes";
import { trackingGlobalDataEpic } from "./trackingGlobalDataEpic";
import checkPublishStatusVAT from '../../TopBar/epics/checkPublishStatus/valueActionType';
import type { SubscriptionData } from "../../../redux/modules/flowTypes";
import { isGAIdValid } from "../../../../../src/dashboard/src/utils/isGAIdValid";

export const isValidPixelId = (id: string) => {
    const pattern = /^[^ <>]+$/,
        isValidId = !id || (pattern.test(id) && id.length <= 25);

    return isValidId;
};

export type TrackingServiceStateType = {
    isGaMeasurementIdValid: boolean,
    isSiteWitEnabled: boolean,
    trackingScriptInPublishedPage: boolean,
    isPixelIdValid: boolean
};

const
    trackingServiceState: TrackingServiceStateType = {
        isGaMeasurementIdValid: false,
        isSiteWitEnabled: false,
        trackingScriptInPublishedPage: false,
        isPixelIdValid: false
    };

const
    MOCK_SITEWIT_CAMPAIGN = "mockSiteWitCampaign",
    MOCK_GOOGLE_ADS_IN_PUBLISHED_PAGE = "mockGoogleAdsInPublishedPage",
    setSiteWitEnabledStatus = (state, trackingScript) => {
        const
            { isSiteWitEnabled: oldValue } = state,
            dummyTrackingScriptForTesting = !!(!trackingScript && window.localStorage.getItem(MOCK_SITEWIT_CAMPAIGN)),
            isSiteWitEnabled = !!(trackingScript || dummyTrackingScriptForTesting);

        if (isSiteWitEnabled !== oldValue) {
            return { state: { ...state, isSiteWitEnabled } };
        }
        return { state };
    };

export const trackingServiceEpic = makeEpic({
    defaultState: trackingServiceState,
    valueActionType: 'TRACKING_SERVICE_EPIC_VALUE',
    updaters: [
        {
            conditions: [
                withSelector(
                    trackingGlobalDataEpic.valueActionType,
                    ({ gaMeasurementId }) => gaMeasurementId
                ),
                APP_MOUNT
            ],
            reducer: ({ state, values: [gaMeasurementId] }) => {
                const
                    { isGaMeasurementIdValid: oldIdValue } = state,
                    isGaMeasurementIdValid = isGAIdValid(gaMeasurementId);

                if (isGaMeasurementIdValid !== oldIdValue) {
                    return { state: { ...state, isGaMeasurementIdValid } };
                }
                return { state };
            }
        },
        {
            conditions: [
                withSelector(
                    trackingGlobalDataEpic.valueActionType,
                    ({ pixelId }) => pixelId
                ),
                APP_MOUNT
            ],
            reducer: ({ state, values: [pixelId] }) => {
                const
                    { isPixelIdValid: oldIdValue } = state,
                    isPixelIdValid = isValidPixelId(pixelId);

                if (isPixelIdValid !== oldIdValue) {
                    return { state: { ...state, isPixelIdValid } };
                }
                return { state };
            }
        },
        {
            conditions: [
                SITE_SUBSCRIPTION_DATA_LOAD_SUCCESS_FOR_GA_CAMP
            ],
            reducer: ({ state, values: [subscriptionData] }) => {
                const
                    subscriptionStatusData: SubscriptionData = subscriptionData,
                    { trackingScript } = subscriptionStatusData;

                return setSiteWitEnabledStatus(state, trackingScript);
            }
        },
        {
            conditions: [checkPublishStatusVAT],
            reducer: ({ state, values: [checkPublishStatus] }) => {
                const
                    { trackingScriptInPublishedPage } = checkPublishStatus,
                    dummyTrackingScriptInPublishedPage = !!(!trackingScriptInPublishedPage && window.localStorage.getItem(MOCK_GOOGLE_ADS_IN_PUBLISHED_PAGE)), // eslint-disable-line max-len
                    newValue = (trackingScriptInPublishedPage || dummyTrackingScriptInPublishedPage),
                    { trackingScriptInPublishedPage: oldValue } = state;

                if (newValue !== oldValue) {
                    return { state: { ...state, trackingScriptInPublishedPage: newValue } };
                }
                return { state };
            }
        }
    ]
});
