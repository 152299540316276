export const
    SHOW_MODERN_HEADER_LAYOUT_LEFT_PANEL = 'SHOW_MODERN_HEADER_LAYOUT_LEFT_PANEL',
    SHOW_MODERN_FOOTER_LAYOUT_LEFT_PANEL = 'SHOW_MODERN_FOOTER_LAYOUT_LEFT_PANEL',
    SHOW_MODERN_LAYOUT_ONBOARDING_PANEL = 'SHOW_MODERN_LAYOUT_ONBOARDING_PANEL',
    HIDE_MODERN_LAYOUT_ONBOARDING_PANEL = 'HIDE_MODERN_LAYOUT_ONBOARDING_PANEL',
    MODERN_LAYOUT_ONBOARDING_PANEL_IS_CLOSED = 'MODERN_LAYOUT_ONBOARDING_PANEL_IS_CLOSED',
    MODERN_LAYOUT_ONBOARDING_PANEL_CLICKED = 'MODERN_LAYOUT_ONBOARDING_PANEL_CLICKED',
    MODERN_LAYOUT_ONBOARDING_BKGD_CLICKED = 'MODERN_LAYOUT_ONBOARDING_BKGD_CLICKED',
    MODERN_LAYOUT_ONBOARDING_TOGGLE_CMPS_UNSET = 'MODERN_LAYOUT_ONBOARDING_TOGGLE_CMPS_UNSET',
    CLOSE_MODERN_LAYOUT_ONBOARDING_PANEL = 'CLOSE_MODERN_LAYOUT_ONBOARDING_PANEL',
    GOTO_NEXT_STEP_ONBOARDING_PANEL = 'GOTO_NEXT_STEP_ONBOARDING_PANEL',
    GOTO_PREV_STEP_ONBOARDING_PANEL = 'GOTO_PREV_STEP_ONBOARDING_PANEL',
    HIDE_MODERN_LAYOUT_LEFT_PANEL = 'HIDE_MODERN_LAYOUT_LEFT_PANEL',
    ACTIVATE_MODERN_HEADER_FOOTER = 'ACTIVATE_MODERN_HEADER_FOOTER',
    DEACTIVATE_MODERN_HEADER_FOOTER = 'DEACTIVATE_MODERN_HEADER_FOOTER',
    ACTIVATE_MODERN_HEADER = 'ACTIVATE_MODERN_HEADER',
    ACTIVATE_MODERN_FOOTER = 'ACTIVATE_MODERN_FOOTER',
    SWITCH_MODERN_HEADER = 'SWITCH_MODERN_HEADER',
    SWITCH_MODERN_FOOTER = 'SWITCH_MODERN_FOOTER',
    MOBILE_EDITOR_MOUSE_ENTER = 'MOBILE_EDITOR_MOUSE_ENTER',
    MOBILE_EDITOR_MOUSE_LEAVE = 'MOBILE_EDITOR_MOUSE_LEAVE',
    PREVIEW_MOUSE_ENTER = 'PREVIEW_MOUSE_ENTER',
    PREVIEW_MOUSE_LEAVE = 'PREVIEW_MOUSE_LEAVE',
    BACKUP_RESTORE_MOUSE_ENTER = 'BACKUP_RESTORE_MOUSE_ENTER',
    BACKUP_RESTORE_MOUSE_LEAVE = 'BACKUP_RESTORE_MOUSE_LEAVE',
    MODERN_HEADER_ACTIVATED = 'MODERN_HEADER_ACTIVATED',
    MODERN_FOOTER_ACTIVATED = 'MODERN_FOOTER_ACTIVATED',
    DEACTIVATE_MODERN_HEADER = 'DEACTIVATE_MODERN_HEADER',
    DEACTIVATE_MODERN_FOOTER = 'DEACTIVATE_MODERN_FOOTER',
    MODERN_HEADER_DEACTIVATED = 'MODERN_HEADER_DEACTIVATED',
    MODERN_FOOTER_DEACTIVATED = 'MODERN_FOOTER_DEACTIVATED',
    DESKTOP_PREVIEW_LOADED_IN_SIDEBAR = 'DESKTOP_PREVIEW_LOADED_IN_SIDEBAR',
    MODERN_HEADER_ACTIVATED_NEW_CMPS_ADDED = 'MODERN_HEADER_ACTIVATED_NEW_CMPS_ADDED',
    MODERN_FOOTER_ACTIVATED_NEW_CMPS_ADDED = 'MODERN_FOOTER_ACTIVATED_NEW_CMPS_ADDED',
    MODERN_HEADER_DEACTIVATED_OLD_CMPS_RESTORED = 'MODERN_HEADER_DEACTIVATED_OLD_CMPS_RESTORED',
    MODERN_FOOTER_DEACTIVATED_OLD_CMPS_RESTORED = 'MODERN_FOOTER_DEACTIVATED_OLD_CMPS_RESTORED',
    MODERN_HEADER_COMPONENTS_INITIALIZED = 'MODERN_HEADER_COMPONENTS_INITIALIZED',
    MODERN_FOOTER_COMPONENTS_INITIALIZED = 'MODERN_FOOTER_COMPONENTS_INITIALIZED',
    MODERN_HEADER_TOGGLE_CMPS_INITIALIZED = 'MODERN_HEADER_TOGGLE_CMPS_INITIALIZED',
    MODERN_FOOTER_TOGGLE_CMPS_INITIALIZED = 'MODERN_FOOTER_TOGGLE_CMPS_INITIALIZED',
    MODERN_HEADER_FOOTER_TOGGLE_CMPS_SET = 'MODERN_HEADER_FOOTER_TOGGLE_CMPS_SET',
    MODERN_HEADER_FOOTER_TOGGLE_CMPS_UNSET = 'MODERN_HEADER_FOOTER_TOGGLE_CMPS_UNSET',
    TEMPLATE_LEFT_OFFSET_CHANGED_WITHOUT_SCROLLING = 'TEMPLATE_LEFT_OFFSET_CHANGED_WITHOUT_SCROLLING',
    REMOVE_WEBSHOP_MHF_DATA = 'REMOVE_WEBSHOP_MHF_DATA',
    ADD_REMOVE_WEBSHOP_STRIP = 'ADD_REMOVE_WEBSHOP_STRIP',
    ADD_REMOVE_WEBSHOP_STRIP_SUCCESS = 'ADD_REMOVE_WEBSHOP_STRIP_SUCCESS',
    MHF_COMPONENTS_TOGGLE_COMPLETED = 'MHF_COMPONENTS_TOGGLE_COMPLETED',
    MHF_COMPONENTS_TOGGLE = 'MHF_COMPONENTS_TOGGLE',
    UPGRADE_MODERN_HEADER_FOOTER = 'UPGRADE_MODERN_HEADER_FOOTER',
    UPDATE_MHF_COMPONENTS_POSITION = 'UPDATE_MHF_COMPONENTS_POSITION';
