import React from "react";
export const SvgRedditCircleFillLight = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <circle cx={24} cy={24} r={24} fill="#ffffff" />
            <path
                fill="#3c3c3c"
                d="M17.953 24.905a1.488 1.488 0 112.976 0 1.488 1.488 0 01-2.976 0zm8.929 0a1.488 1.488 0 112.976 0 1.488 1.488 0 01-2.976 0zm.072 3.18a.884.884 0 111.094 1.39c-1.067.84-2.693 1.383-4.143 1.383-1.449 0-3.075-.543-4.142-1.384a.884.884 0 011.094-1.388c.614.484 1.83 1.005 3.048 1.005 1.219 0 2.435-.521 3.05-1.005zm8.857-6.156a2.976 2.976 0 00-5.577-1.446c-1.53-.837-3.394-1.377-5.425-1.502l1.776-3.989 3.4.982a2.233 2.233 0 10.14-1.8l-3.789-1.093a.884.884 0 00-1.052.49l-2.413 5.418c-1.98.14-3.796.675-5.293 1.494A2.975 2.975 0 0012 21.929c0 1.217.73 2.262 1.776 2.723a5.45 5.45 0 00-.288 1.742c0 4.11 4.664 7.44 10.417 7.44 5.754 0 10.418-3.33 10.418-7.44 0-.6-.1-1.183-.288-1.742a2.976 2.976 0 001.776-2.723zm-3.72-7.534a.837.837 0 110 1.674.837.837 0 010-1.674zM13.487 21.93c0-.82.668-1.488 1.488-1.488a1.49 1.49 0 011.345.852c-.777.59-1.422 1.27-1.899 2.017a1.49 1.49 0 01-.934-1.38zm10.417 10.138c-4.777 0-8.65-2.54-8.65-5.673 0-3.134 3.873-5.674 8.65-5.674 4.778 0 8.65 2.54 8.65 5.674 0 3.133-3.872 5.673-8.65 5.673zm9.483-8.757c-.476-.747-1.12-1.427-1.899-2.017a1.49 1.49 0 011.346-.852c.82 0 1.488.668 1.488 1.488a1.49 1.49 0 01-.935 1.38z"
            />
        </g>
    </svg>
);
