import React, { useRef } from "react";
import Lottie from "react-web-lottie";
import animationData from "./templatesSelectorLoader.json";

type TemplateSelectorRightSideViewProps = {
    loop?: boolean;
    setIsLoading: (...args: Array<any>) => any;
};
export default function TemplateSelectorRightSideView({ loop = false, setIsLoading }: TemplateSelectorRightSideViewProps) {
    const animationRef = useRef(null);
    const defaultOptions = {
        animationData,
        autoplay: true,
        loop: true,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid meet"
        }
    };
    if (!loop) {
        setTimeout(() => {
            setIsLoading(false);
        }, 4000);
    }
    return <Lottie lottiRef={animationRef} options={defaultOptions} isClickToPauseDisabled width="25%" height="30%" />;
}
