import type { BBox } from "../../redux/modules/children/workspace/flowTypes";
import { memoMax } from "../../../utils/memo";
import { MaxComponentsOnPage } from "../../constants/app";

/* it's very important to memoize bBox, cause there are a lot of functions working with BBoxes as input,
* and they need simple memoization by bBox reference */
const makeBBox = memoMax((left: number, top: number, width: number, height: number): BBox => ({
        top,
        left,
        right: left + width,
        bottom: top + height
    }), (MaxComponentsOnPage * 3) + 10000), // 3000 is not selected components handles, 10000 safty buffer, red decos / snapping
    zeroBBox = makeBBox(0, 0, 0, 0);

export {
    makeBBox as default,
    zeroBBox
};
