/* eslint-disable max-len */

import * as R from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly } from '../../../../epics/makeCondition';
import valueActionType from './valueActionType';
import type { ComponentPanelActionsEpicState } from './flowTypes';
import workspaceBBoxValueActionType from '../workspaceBBox/valueActionType';
import scrollValueActionType from '../scroll/valueActionType';
import { COMPONENT_PANEL_RESIZED } from '../../../../view/Workspace/Decorations/ComponentPanelActions/actionTypes';
import {
    ReceiveOnlyComponentsDependenciesSelector,
    ReceiveOnlyComponentsMap,
    SelectedComponentsSelector,
} from "../componentsEval/selectorActionTypes";
import { isFirstPageSection, isLastPageSection } from '../../../oneweb/Section/utils';
import { isSectionKind } from "../../../oneweb/componentKinds";
import { MOVE_UP_ACTION, MOVE_DOWN_ACTION, DELETE_ACTION, DUPLICATE_ACTION } from './constants';
import { CMP_PANEL_DELETE_CLICKED, CMP_PANEL_DELETE_DIALOG_CANCEL_PRESSED, CMP_PANEL_DELETE_DIALOG_DELETE_PRESSED } from './actionTypes';
import { CmpPanelDeleteConfirmationDialogId } from "../../../../view/Workspace/Decorations/ComponentPanelActions/cmpPanelDeleteConfirmationDialogId";
import { openDialogAC } from '../../../App/actionCreators/openDialog';
import { closeDialogByIdAction } from '../../../App/actionCreators/index';
import { DELETE_SELECTED_SECTION } from "../../actionTypes";
import { ReceiveOnlyAttachments } from "../componentAttachements/selectorActionTypes";
import { getTopMostParentId } from "../componentAttachements/util";
import quickTourVAT from '../../../QuickTour/epics/quickTour/valueActionType';

const defaultHideActions = {
        [MOVE_UP_ACTION]: false,
        [MOVE_DOWN_ACTION]: false,
        [DELETE_ACTION]: false,
        [DUPLICATE_ACTION]: false
    }, defaultDisableActions = {
        [MOVE_UP_ACTION]: false,
        [MOVE_DOWN_ACTION]: false,
        [DELETE_ACTION]: false,
        [DUPLICATE_ACTION]: false
    };

const
    PANEL_LEFT_PADDING = 40,
    PANEL_TOP_PADDING = 27,
    PANEL_DEFAULT_HEIGHT = 124,
    defaultState: ComponentPanelActionsEpicState = {
        panelHeight: 0,
        panelTop: 0,
        panelLeft: 0,
        panelWidth: 0,
        dependencies: {},
        hideActions: defaultHideActions,
        component: null,
        disableActions: defaultDisableActions,
    },
    getPanelTopLeftFromMCTA = (top, panelHeight, workspaceBBox, scroll) => {
        const panelTop = top + PANEL_TOP_PADDING,
            panelLeft = workspaceBBox.left + PANEL_LEFT_PADDING + scroll.x;
        return {
            panelTop,
            panelLeft
        };
    },
    epic = makeEpic({
        defaultState,
        valueActionType,
        updaters: [
            {
                conditions: [
                    receiveOnly(workspaceBBoxValueActionType),
                    scrollValueActionType,
                    COMPONENT_PANEL_RESIZED,
                ],
                reducer: ({
                    values: [
                        workspaceBBox,
                        scroll,
                        { width, height },
                    ],
                    state: epicState
                }) => {
                    const component = epicState.component;
                    if (component) {
                        return {
                            state: {
                                ...epicState,
                                ...getPanelTopLeftFromMCTA(component.top, height, workspaceBBox, scroll),
                                panelWidth: width,
                                panelHeight: height
                            }
                        };
                    }
                    return { state: defaultState };
                }
            },
            {
                conditions: [
                    receiveOnly(workspaceBBoxValueActionType),
                    ReceiveOnlyComponentsMap,
                    SelectedComponentsSelector,
                    ReceiveOnlyAttachments,
                    ReceiveOnlyComponentsDependenciesSelector,
                    receiveOnly(scrollValueActionType),
                    quickTourVAT,
                ],
                reducer: ({ values: [workspaceBBox, componentsMap, selectedComponents, attachments, allDependencies,
                    scroll, { show }], state: epicState }) => {
                    let hideActions = {},
                        disableActions = {};

                    if (selectedComponents && R.last(selectedComponents)) {
                        const topMostParentId = getTopMostParentId(R.last(selectedComponents).id, attachments),
                            topmostParent = componentsMap[topMostParentId];

                        if (!topmostParent) {
                            return { state: defaultState };
                        }
                        const { id, kind, inTemplate, top } = topmostParent;
                        const dependencies = allDependencies[kind];

                        if (isSectionKind(kind)) {
                            if (inTemplate) {
                                hideActions[DELETE_ACTION] = true;
                                hideActions[DUPLICATE_ACTION] = true;
                                hideActions[MOVE_UP_ACTION] = true;
                                hideActions[MOVE_DOWN_ACTION] = true;
                            } else {
                                const firstPageSection = isFirstPageSection(id, componentsMap),
                                    lastPageSection = isLastPageSection(id, componentsMap);
                                if (firstPageSection) {
                                    disableActions[MOVE_UP_ACTION] = true;
                                }
                                if (lastPageSection) {
                                    disableActions[MOVE_DOWN_ACTION] = true;
                                }
                                if (firstPageSection && lastPageSection) {
                                    disableActions[DELETE_ACTION] = true;
                                }
                            }
                            return {
                                state: {
                                    ...epicState,
                                    hideActions,
                                    disableActions,
                                    component: topmostParent,
                                    dependencies,
                                    isSectionTourVisible: show,
                                    ...getPanelTopLeftFromMCTA(top, PANEL_DEFAULT_HEIGHT, workspaceBBox, scroll)
                                }
                            };
                        }
                    }
                    return {
                        state: defaultState
                    };
                }
            },
            {
                conditions: [
                    CMP_PANEL_DELETE_CLICKED
                ],
                reducer: ({ state, values: [cmpId] }) => ({
                    state: {
                        ...state,
                        deleteCmpId: cmpId
                    },
                    actionToDispatch: openDialogAC(CmpPanelDeleteConfirmationDialogId)
                })
            },
            {
                conditions: [CMP_PANEL_DELETE_DIALOG_CANCEL_PRESSED],
                reducer: ({ state, scope }) => {
                    return ({
                        state: {
                            ...state,
                            deleteCmpId: null,
                        },
                        scope,
                        actionToDispatch: closeDialogByIdAction(CmpPanelDeleteConfirmationDialogId),
                    });
                }
            },
            {
                conditions: [
                    CMP_PANEL_DELETE_DIALOG_DELETE_PRESSED
                ],
                reducer: ({ state }) => {
                    const deleteCmpId: string = state.deleteCmpId;
                    return ({
                        state: {
                            ...state,
                            deleteCmpId: null
                        },
                        multipleActionsToDispatch: [
                            closeDialogByIdAction(CmpPanelDeleteConfirmationDialogId),
                            {
                                type: DELETE_SELECTED_SECTION,
                                payload: { sectionId: deleteCmpId }
                            }
                        ]
                    });
                }
            },
        ]
    });

export {
    epic as default
};
