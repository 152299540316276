import { absoluteUrlToInputLinkOld } from "../../../../view/common/dialogs/LinkChooserDialog/utils/absoluteUrlToInputLink";
import { getLinkUrlForText } from "../getLinkUrl";
import { DataSite } from "../../../../../dal/model/index";

export default (node: HTMLElement, site: DataSite) => {
    Array.from(node.querySelectorAll('a')).forEach((el: HTMLElement) => {
        try {
            const linkUrl = getLinkUrlForText({
                link: absoluteUrlToInputLinkOld(el.getAttribute('href') || '', site),
                openInNewWindow: false
            });
            el.setAttribute('href', linkUrl.href);
        } catch (e) {} //eslint-disable-line
    });
};
