import CALL_API from "../../../../../wbtgen/src/redux/middleware/api/CALL_API";
import { SITE_SETTINGS } from '../../../../../wbtgen/dal/constants';
import type { SiteSettings } from '../../../../../wbtgen/src/components/App/epics/siteSettings/flowTypes';

import * as ActionTypes from "./actions";

// TODO: The default data is wrong. We need to correct it. WBTGEN-22409
export const defaultSiteSettings: SiteSettings = ({
    type: SITE_SETTINGS,
    id: "settings",
}) as any;

export const siteSettingsReadyAction = (siteSettings: SiteSettings) => ({
    type: ActionTypes.SITE_SETTINGS_READY,
    payload: siteSettings,
});

export const siteSettingsErrorAction = (error: any) => ({
    type: ActionTypes.SITE_SETTINGS_ERROR,
    payload: error,
});

export const loadSiteSettingsAction = () => ({
    [CALL_API]: {
        types: [
            ActionTypes.LOAD_SITE_SETTINGS_REQUEST,
            ActionTypes.LOAD_SITE_SETTINGS_SUCCESS,
            ActionTypes.LOAD_SITE_SETTINGS_FAILURE
        ],
        endpoint: 'loadSiteSettings',
    }
} as unknown as Action);

export const createDefaultSiteSettingsAction = () => ({
    [CALL_API]: {
        types: [
            ActionTypes.CREATE_DEFAULT_SITE_SETTINGS_REQUEST,
            ActionTypes.CREATE_DEFAULT_SITE_SETTINGS_SUCCESS,
            ActionTypes.CREATE_DEFAULT_SITE_SETTINGS_FAILURE
        ],
        endpoint: 'createDefaultSiteSettings',
        endpointParams: defaultSiteSettings,
    }
} as unknown as Action);

export const siteSettingsChanged = (options?: { saveImmediately?: true }) => ({
    type: ActionTypes.SITE_SETTINGS_CHANGED,
    payload: { ...options }
});

export const openSiteSettingsTabAction = (tabKey: string) => ({
    type: ActionTypes.OPEN_SITE_SETTINGS_GENERAL_TAB_ACTION,
    payload: {
        activeTabKey: tabKey
    }
});

export const setContactEmail = (email: string) => ({
    type: ActionTypes.GENERAL_GLOBAL_DATA_SET_CONTACT_EMAIL,
    payload: email
});

export const setPhoneNumber = (phoneNumber: string) => ({
    type: ActionTypes.GENERAL_GLOBAL_DATA_SET_PHONE_NUMBER,
    payload: phoneNumber
});

export const changeEnableSeoAction = (enableSeo: Boolean) => ({
    type: ActionTypes.CHANGE_SITE_SETTINGS_ENABLE_SEO,
    payload: enableSeo
});
