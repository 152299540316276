import React from 'react';
import { DATA_TEST_ID } from '../constants';
import '../../view/Preview.css';
import { WHITE_COLOR } from '../../../Menu/constants/colors';

export const MailchimpView = props => (
    <div
        data-testid={DATA_TEST_ID}
        className="widget"
        style={{ minWidth: "300px", overflowX: "scroll", background: WHITE_COLOR }}
    >
        <div dangerouslySetInnerHTML={{ __html: props.link }} />
    </div>
);
