import React from 'react';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import NavigationGroup from "../../../../PropertiesPanel/view/IntlNavigationGroup";
import { MOBILE_VIEW } from "../../../../PropertiesPanel/view/MobileView/constants";
import * as pagesIds from '../ids';
import pagesTitles from '../titles';
import type { Props } from '../flowTypes';

const
    id = pagesIds.MAIN,
    title = pagesTitles[id],
    view = ({ navigateToPage }: Props) => (
        <Page>
            <NavigationGroup
                navigateToPage={navigateToPage}
                pagesTitles={pagesTitles}
                targetPagesIds={[pagesIds.LINK, pagesIds.STYLE, MOBILE_VIEW]}
            />
        </Page>
    );

export { id, title, view };
