import * as R from "ramda";
import React from "react";
import pagesTitles from "../pagesTitles";
import p from "../../../../../utils/pipePath";
import * as pageIds from "../pageIds";
import * as actionTypes from "../../actionTypes";
import * as mp from "../../../../../mappers/path";
import Page from "../../../../PropertiesPanel/view/PropertiesPage";
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import { PropertyInputBoxWithTimer } from "../../../../../view/common/PropertyInputBox/index";
import FourFieldSpacing from "../../../../presentational/FourFields/FourFieldSpacing/index";
import { getCellWidthInPx, getFullCellData, getPropFromGlobalstyle } from "../../utils";
import { noDecimalsValidator } from "../../../../../view/common/Input/commonValidators";
import type { PageProps } from '../../../../PropertiesPanel/flowTypes';
import type { TableComponent } from '../../flowTypes';

type PageViewProps = PageProps<TableComponent> & {
    tableEditModeState: {
        selectedCellsIndexes: Array<number>
    }
}

const
    batchChangeTimeout = 900,
    id = pageIds.SizeAndSpacing,
    title = pagesTitles[id],
    view = ({
        selectedComponent: { id, cells, width, commonCellsData },
        stylesheets,
        tableEditModeState: { selectedCellsIndexes },
        dispatch
    }: PageViewProps) => {
        const
            cellIndex = selectedCellsIndexes.length ? selectedCellsIndexes[0] : 0,
            cell = getFullCellData({ commonCellsData }, cells[cellIndex]),
            cellWidth = Math.round(getCellWidthInPx(cell, width)),
            cellHeight = cell.cellInfo.minHeight,
            cellSpacing = R.path(p(mp.style, mp.blockPadding), cell),
            globalstyleCellSpacing = getPropFromGlobalstyle(stylesheets)(cell.style.globalId, mp.blockPadding),
            spacing = cellSpacing || globalstyleCellSpacing,
            validator = { validator: noDecimalsValidator };

        return (
            <Page>
                <VerticalSpacer y={10} />
                <PropertyInputBoxWithTimer
                    inputLabel="msg: component.table.pp.cellStyle.cellWidth {Cell width}"
                    componentId={id}
                    inputValue={cellWidth}
                    validators={[validator]}
                    batchChangeTimeout={batchChangeTimeout}
                    onChangeAction={actionTypes.TABLE_CELL_WIDTH_CHANGED}
                    dispatch={dispatch}
                />
                <VerticalSpacer y={10} />
                <PropertyInputBoxWithTimer
                    inputLabel="msg: component.table.pp.cellStyle.cellHeight {Cell height}"
                    componentId={id}
                    inputValue={cellHeight}
                    validators={[validator]}
                    batchChangeTimeout={batchChangeTimeout}
                    onChangeAction={actionTypes.TABLE_CELL_HEIGHT_CHANGED}
                    dispatch={dispatch}
                />
                <VerticalSpacer y={10} />
                <FourFieldSpacing
                    values={spacing}
                    lockIconTitle="msg: common.cell.cellSpacing {Link together cell spacing}"
                    onChangeAction={actionTypes.TABLE_CELL_SPACING_CHANGED}
                    dispatch={dispatch}
                />
            </Page>
        );
    };

export { id, title, view };
