import React, { useState } from "react";
import cx from 'classnames';
import { Combobox as ComboBox } from '../../../../view/common/Combobox/index';
import { INTL_DAYS_OF_THE_WEEK, OPEN_24_HOURS_VALUE } from '../../../oneweb/OpeningHours/constants';
import {
    getDaysOfTheWeek,
    siteSettingsOpeningHoursToOpeningHoursMap,
    createFromOpeningHourTimeOptions,
    createToOpeningHourTimeOptions,
    isInvalidOpeningHour,
    areOpeningHoursValid,
} from '../../../oneweb/OpeningHours/utils';
import {
    getStartDayOfTheWeek,
} from '../../../oneweb/OpeningHours/previewUtils';
import CheckBoxWithLabel from "../../../../view/common/CheckBox/CheckBoxWithLabel";
import type { GeneralGlobalData } from '../generalGlobalDataEpic/generalGlobalDataEpic';
import { Intl } from "../../../../view/intl/index";
import * as styles from './styles.css';
import { reducer } from './reducer';
import { toggleOpeningHoursForTheDayAction, updateFromOpeningHoursAction, updateToOpeningHoursAction } from './actions';
import { GENERAL_GLOBAL_DATA_SET_OPENING_HOURS } from '../generalGlobalDataEpic/actionTypes';

type OpeningHoursProps = { generalGlobalData: GeneralGlobalData; intl: Intl; dispatch: Dispatch; };

const OpeningHours = (props: OpeningHoursProps) => {
    const { generalGlobalData, intl, dispatch } = props;

    const [openingHoursMap, setOpeningHoursMap] = useState(siteSettingsOpeningHoursToOpeningHoursMap(generalGlobalData.openingHours || [])); // not using useReducer to avoid 'state update during render' error
    const updateOpeningHoursMap = (action) => {
        const updatedOpeningHoursMap = reducer(openingHoursMap, action);
        setOpeningHoursMap(updatedOpeningHoursMap);
        if (areOpeningHoursValid(updatedOpeningHoursMap)) {
            dispatch({ type: GENERAL_GLOBAL_DATA_SET_OPENING_HOURS, payload: updatedOpeningHoursMap });
        }
    };

    const daysOfTheWeek = getDaysOfTheWeek(getStartDayOfTheWeek());

    const timingFilterFunction = (options, filterStr) => {
        return options.filter((option) => (
            option.label.toLowerCase().indexOf(filterStr.toLowerCase()) === 0
        ));
    };

    return <table className={styles.openingHours}>
        <tbody>
            {
                daysOfTheWeek.map((day, index) => {
                    const openingHoursForTheDay = openingHoursMap[day] && openingHoursMap[day].isSet ? openingHoursMap[day] : false;
                    const isOpen24Hours = (openingHoursForTheDay && openingHoursForTheDay.from === 0 && openingHoursForTheDay.to === 0);
                    return (
                        <tr key={day} data-test-id={day}>
                            <td className={styles.checkbox}>
                                <CheckBoxWithLabel
                                    label={INTL_DAYS_OF_THE_WEEK[day]}
                                    isChecked={!!openingHoursForTheDay}
                                    onClick={() => updateOpeningHoursMap(
                                        toggleOpeningHoursForTheDayAction({ day, prevDay: daysOfTheWeek[index - 1] })
                                    )}
                                />
                            </td>
                            {
                                openingHoursForTheDay
                                    ? (
                                        <React.Fragment>
                                            <td
                                                className={
                                                    cx(styles.from, { [styles.error]: isInvalidOpeningHour(openingHoursForTheDay.from) })
                                                }
                                            >
                                                <ComboBox
                                                    searchable
                                                    value={isOpen24Hours ? OPEN_24_HOURS_VALUE : openingHoursForTheDay.from}
                                                    options={createFromOpeningHourTimeOptions(openingHoursForTheDay, intl)}
                                                    filterOptions={timingFilterFunction}
                                                    onChange={({ value }) => updateOpeningHoursMap(
                                                        updateFromOpeningHoursAction({ openingHoursForTheDay, day, time: value })
                                                    )}
                                                />
                                            </td>
                                            {isOpen24Hours || <React.Fragment>
                                                <td>{intl.msgJoint('msg: common.to {to}')}</td>
                                                <td
                                                    className={
                                                        cx(styles.to, { [styles.error]: isInvalidOpeningHour(openingHoursForTheDay.to) })
                                                    }
                                                >
                                                    <ComboBox
                                                        searchable
                                                        value={openingHoursForTheDay.to}
                                                        options={createToOpeningHourTimeOptions(openingHoursForTheDay, intl)}
                                                        filterOptions={timingFilterFunction}
                                                        onChange={({ value }) => updateOpeningHoursMap(
                                                            updateToOpeningHoursAction({ openingHoursForTheDay, day, time: value })
                                                        )}
                                                    />
                                                </td>
                                            </React.Fragment>}
                                        </React.Fragment>
                                    )
                                    : <td colSpan={3}>{intl.msgJoint('msg: common.closed {Closed}')}</td>
                            }
                        </tr>
                    );
                })
            }
        </tbody>
    </table>;
};

export default OpeningHours;
