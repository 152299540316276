import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Hidden, Typography } from '@mui/material';
import { Optional } from 'utility-types';

import { useIntl } from "../../../../../../wbtgen/src/view/intl";

import type { SideBarItemDefHeader } from "../../SideBar/types";
import { selectSideBarTabAction } from "../../SideBar/actions";
import { DEFAULT_PAGE_SIDE_BAR_ITEM_ID } from "../../../utils/constants";
import { SideBarNavigation } from "../../SideBar/MobileSideBar/SideBarNavigation";
import { useStyles } from './styles';

type HeaderProps = {
    header: Optional<SideBarItemDefHeader, 'description'>;
};

export const Header = ({ header }: HeaderProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const classes = useStyles();

    const backButtonHandler = () => {
        dispatch(selectSideBarTabAction(DEFAULT_PAGE_SIDE_BAR_ITEM_ID));
    };

    return (
        <React.Fragment>
            {/* @ts-ignore */}
            <Hidden mdUp>
                <SideBarNavigation backButtonHandler={backButtonHandler} />
            </Hidden>

            <Box className={classes.titleContainer}>
                <Typography className={classes.title}>{intl.msgJoint(header.title)}</Typography>

                {header.description && (
                    <Typography className={classes.description}>
                        {intl.msgJoint(header.description)}
                    </Typography>
                )}
            </Box>
        </React.Fragment>
    );
};
