import * as R from "ramda";
import * as mp from "../../../../src/mappers/path";
import * as borderMapper from '../Common/border';
import * as colorMapper from "../Base/color";
import * as fontFamilyMapper from "../Base/fontFamily";
import * as backgroundMapper from '../Common/background';
import { overPath } from '../../../../src/utils/ramdaEx';

const
    backgroundMap = {
        to: overPath([mp.blockBackground])(backgroundMapper.to),
        back: overPath([mp.blockBackground])(backgroundMapper.back)
    },
    borderMap = {
        to: overPath([mp.blockBorder])(borderMapper.to),
        back: overPath([mp.blockBorder])(borderMapper.back)
    },
    colorMap = {
        to: overPath([
            [mp.text, mp.color],
            [mp.text, mp.highlight],
            [mp.text, mp.shadow, mp.color]
        ])(colorMapper.toHsl)
    },
    fontFamilyMap = {
        to: overPath([mp.textFont])(fontFamilyMapper.to),
        back: overPath([mp.textFont])(fontFamilyMapper.back)
    };

export const
    to = R.pipe(
        borderMap.to,
        backgroundMap.to,
        colorMap.to,
        fontFamilyMap.to
    ),
    back = R.pipe(
        borderMap.back,
        backgroundMap.back,
        fontFamilyMap.back
    );
