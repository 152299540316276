/* @flow */
const WsbFeature = Object.freeze({
    FacebookChat: 'facebookChat',
    Shutterstock: 'shutterstock',
    ShutterstockFree: 'shutterstock',
    OnlineShop: 'onlineShop',
    Tracking: 'siteSettings.tracking',
    GoogleAds: 'siteSettings.tracking.googleAds',
    GoogleMyBusiness: 'gmb',
    Dashboard: 'dashboard',
    Unsplash: 'unsplash'
});

/*::
    export type WsbFeatureT = $Values<typeof WsbFeature>
 */

module.exports = { WsbFeature };
