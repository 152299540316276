/* eslint-disable max-len */
import * as R from 'ramda';
import menuStyles from '../view/Menu.css';
import makeSpec from "../../../../mappers/makeSpec/index";
import { baseCssConfig } from "../../../../mappers/block/index";
import * as path from "../../../../mappers/path";
import type { MenuStylesheet } from "../../../Workspace/epics/stylesheets/flowTypes";
import { memoUnlimited } from "../../../../../utils/memo";
import { buttonBlockCssConfig } from "../../Button/globalStyle/mapper";

type Props = {
    className: string;
};
type OmitConfigs = Array<string>;
type BlockSpec = (additionalPath: Path, cssPrefix: string, omitConfigs: Array<string>) => Record<string, any>;

const makeOmittedConfigsBlockSpec = (omitConfigs: Array<string> = []) => {
        const finalConfig = omitConfigs.length ? R.omit(omitConfigs, buttonBlockCssConfig) : buttonBlockCssConfig;
        return makeSpec(finalConfig);
    },
    hoverBlockSpec = makeOmittedConfigsBlockSpec(["padding"])(path.item, path.hover),
    makeMenuBlockSpec = ({ includeHoverByMod, includeHoverByClass }) => (
        additionalPath: Path = [],
        cssPrefix: string = "",
        omitConfigs: Array<string> = []
    ) => {
        const result = {
            [cssPrefix]: makeOmittedConfigsBlockSpec(omitConfigs)(additionalPath, path.inactive)
        };

        if (includeHoverByMod) {
            result[`${cssPrefix}:hover`] = hoverBlockSpec;
        }

        if (includeHoverByClass) {
            result[`${cssPrefix}.hover`] = hoverBlockSpec;
        }

        return result;
    },
    menuBlockSpec = makeMenuBlockSpec({
        includeHoverByMod: true,
        includeHoverByClass: false
    }),
    menuNoHoverBlockSpec = makeMenuBlockSpec({
        includeHoverByMod: false,
        includeHoverByClass: false
    }),
    makeDefaultMergeFn = defaultValue => (values: Array<string>) => {
        const valuesToJoin = values.map(x => x || defaultValue);

        if (valuesToJoin.every(x => !x)) {
            return null;
        }

        return valuesToJoin.join(", ");
    },
    mergeFnMap = {
        backgroundImage: R.pipe(R.flatten, makeDefaultMergeFn("none")),
        backgroundRepeat: makeDefaultMergeFn("repeat"),
        backgroundPosition: makeDefaultMergeFn("0% 0%"),
        backgroundSize: makeDefaultMergeFn("auto"),
        backgroundAttachment: makeDefaultMergeFn("scroll")
    },
    // used to have same array obj reference for same content
    make2LevelPathMemoized = memoUnlimited((l1key, l2key) => [l1key, l2key]),
    /* We need to mix .selected and .expanded/.expandable styles to produce merged background-* css rules */
    mixBlockSpecs = (paths: Array<[Path, OmitConfigs]>, cssPrefix: string, blockSpec: BlockSpec) => {
        const // specsToMerge: Array<{ [cssPrefix, string]: { [cssProp: string]: CssValueGetter } }>
            specsToMerge = paths.map(([p, omitConfigs]) => blockSpec(p, cssPrefix, omitConfigs)),
            mergeFields = specsToMerge.reduce((acc, spec) => {
                Object.keys(spec).forEach(cssPrefix => {
                    Object.keys(spec[cssPrefix]).forEach(cssProp => {
                        if (!mergeFnMap[cssProp]) {
                            return;
                        }

                        const path = make2LevelPathMemoized(cssPrefix, cssProp);

                        if (acc.indexOf(path) === -1) {
                            acc.push(path);
                        }
                    });
                });
                return acc;
            }, []),
            resultSpec = mergeFields.reduce((acc, [cssPrefix, cssProp]) => {
                if (!acc[cssPrefix]) {
                    acc[cssPrefix] = {};
                }

                const cssMappers = specsToMerge.map(s => R.path([cssPrefix, cssProp], s)).filter(x => x);

                acc[cssPrefix][cssProp] = stylesheet => {
                    const cssRules = cssMappers.map(mapper => mapper(stylesheet));
                    return mergeFnMap[cssProp](cssRules);
                };

                return acc;
            }, {});
        return resultSpec;
    };

export const expandable = "expandable",
    expanded = "expanded",
    selected = "selected",
    dropdown = "dropdown",
    tree = "tree";
const getCascadeWidth = R.path([path.cascadeWidth]);
const cascadeWidthIsNotSet = R.pipe(getCascadeWidth, R.not);
const shouldShowDropDownArrow = R.path(path.showDropDownArrowPath);
const shouldShowDropDownArrowAndDropdownWidthIsUnset = R.both(shouldShowDropDownArrow, cascadeWidthIsNotSet);
const backgroundKeys = [
        "background",
        "backgroundColor",
        "backgroundAttachment",
        "backgroundImage",
        "backgroundPosition",
        "backgroundRepeat",
        "backgroundSize"
    ],
    borderKeys = [
        "borderBottomLeftRadius",
        "borderBottomRightRadius",
        "borderColor",
        "borderStyle",
        "borderTopLeftRadius",
        "borderTopRightRadius",
        "borderWidth"
    ],
    pickBgAndBorderProps = R.pick([...backgroundKeys, ...borderKeys]),
    pickEverything = R.identity,
    expandedDropdownArrowOmitKeys = [...backgroundKeys, ...borderKeys, "padding"];
export default ({ className: gsClassName }: Props) => {
    const baseCssPrefix = `ul.${gsClassName} > li > a`,
        dropDownPrefix = `.${dropdown} ${baseCssPrefix}`,
        selectedCssPrefix = `${baseCssPrefix}.${selected}`,
        expandableCssPrefix = `${baseCssPrefix}.${expandable}`,
        expandedCssPrefix = `${baseCssPrefix}.${expanded}`,
        expandedDropDownCssPrefix = `${dropDownPrefix}.${expanded}`,
        selectedAndExpandableCssPrefix = `${baseCssPrefix}.${selected}.${expandable}`,
        selectedAndExpandedCssPrefix = `${baseCssPrefix}.${selected}.${expanded}`,
        dividerCssPrefix = `ul.${gsClassName} div.divider`,
        makeDividerSpec = (cssPrefix: string = "") => ({
            [cssPrefix]: makeSpec({ ...baseCssConfig(path.divider) })()
        }),
        makeBlockSpec = (cssPrefix: string = "") => ({
            [cssPrefix]: makeSpec({ ...baseCssConfig(path.block) })()
        }),
        makeExpandedSpec = (stylesheet, cssPrefix = expandedCssPrefix) =>
            menuBlockSpec(path.expanded, cssPrefix, shouldShowDropDownArrow(stylesheet) ? expandedDropdownArrowOmitKeys : []),
        // This hacks for global style window preview
        makePreviewHoverSpec = () => {
            const key = `.${menuStyles.hackAnchor} ul.${gsClassName} > li:last-child > a`;
            return {
                [key]: makeSpec(buttonBlockCssConfig)(path.item, path.hover)
            };
        },
        makePreviewExpandedSpec = stylesheet => {
            const showDropDownArrow = shouldShowDropDownArrow(stylesheet);
            const key = `.${menuStyles.hackAnchor} ul.${gsClassName} > li:nth-last-child(2) > a`;

            if (!showDropDownArrow) {
                return makeExpandedSpec(stylesheet, key);
            } else {
                return {
                    [key]: makeSpec(buttonBlockCssConfig)(path.item, path.hover)
                };
            }
        },
        makeHorizontalFitDividerFixSpec = () => {
            const selector = `.menuhorizontal.horizontalalignfit ul.${gsClassName} li:not(:last-child) a.level-0`;
            return {
                [selector]: stylesheet => {
                    const [, right, , left] = R.path(["divider", "padding"], stylesheet);
                    let horizontalPadding = right + left,
                        width;
                    horizontalPadding = horizontalPadding > 0 ? horizontalPadding - 1 : horizontalPadding;

                    if (!horizontalPadding) {
                        width = `calc(100%)`;
                    } else {
                        width = `calc(100% - ${horizontalPadding}px)`;
                    }

                    return {
                        width: `${width} !important`,
                        marginRight: `${horizontalPadding}px`
                    };
                }
            };
        },
        makeMenuDropdownItemWidthSpec = () => {
            const menuDropdownCssSelector = `.${dropdown} ul.${gsClassName} > li ul`;
            return {
                [menuDropdownCssSelector]: stylesheet => ({
                    width: getCascadeWidth(stylesheet)
                })
            };
        },
        makeAnchorLineHeightSpec = () => {
            const menuDropdownCssSelector = `ul.${gsClassName} li a`;
            return {
                [menuDropdownCssSelector]: stylesheet => {
                    const fontSize = R.path([path.item, path.inactive, path.text, path.size], stylesheet),
                        lineHeight = `${Math.round(fontSize * 1.2)}px`;
                    return {
                        lineHeight
                    };
                }
            };
        },
        makeSpanLineHeightSpec = () => {
            const CssSelector = `ul.${gsClassName} li a.level-0 span`;
            return {
                [CssSelector]: () => ({
                    display: "inline-block"
                })
            };
        },
        makeTextIntendRulesSpec = (howMuchLevelsToGenerate = 10) =>
            R.reduce(
                (acc, level) =>
                    R.assoc(
                        `.${tree} ul.${gsClassName} .level-${level} span`,
                        stylesheet => ({
                            paddingLeft: R.path([path.accordionTextIndent], stylesheet) * level
                        }),
                        acc
                    ),
                {},
                R.range(1, howMuchLevelsToGenerate + 1)
            ),
        arrowMargin = "0.3em",
        arrowStyle = {
            fontFamily: "dropDownMenu !important",
            marginLeft: arrowMargin,
            lineHeight: "1px",
            fontSize: "1.2em",
            textShadow: "none"
        },
        makeDropdownArrowSpec = stylesheet => {
            const _shouldShowDropDownArrowAndDropdownWidthIsUnset = shouldShowDropDownArrowAndDropdownWidthIsUnset(stylesheet);

            const dropdownArrowIsEnabled = !!R.path(path.showDropDownArrowPath, stylesheet);
            const horizontalAlignIsCenter = R.path(["item", "inactive", "horizontalAlign"], stylesheet) === "center";
            const normalHasUnderline = !!R.path([path.item, path.inactive, path.text, path.underline], stylesheet);
            const selectedHasUnderline = !!R.path([path.selected, path.inactive, path.text, path.underline], stylesheet);
            const hoverHasUnderline = !!R.path([path.item, path.hover, path.text, path.underline], stylesheet);
            const result = {};
            const dropdownNotHorizontalRightAnchorSelector = `.jsdropdown:not(.menuhorizontalright) ul.${gsClassName} > li > a, .menu.dropdown:not(.menuhorizontalright) ul.${gsClassName} > li > a`;
            const dropdownHorizontalFitUlSelector = `.jsdropdown.horizontalalignfit ul.${gsClassName}`;
            const dropdownHorizontalAnchorSelector = `.jsdropdown.menuhorizontalright ul.${gsClassName} > li > ul > li ul > li > a,.menu.dropdown.menuhorizontalright ul.${gsClassName} > li > ul > li ul > li > a`;
            const dropdownDividerSelector = `.jsdropdown > ul.${gsClassName} > li ul > li > .divider, .menu.dropdown > ul.${gsClassName} > li ul > li > .divider`;
            const dropdownLiSelector = `.jsdropdown > ul.${gsClassName} > li > ul > li, .jsdropdown > ul.${gsClassName} > li ul > li:hover, .jsdropdown > ul.${gsClassName} > li ul > li:hover > ul > li, .menu.dropdown > ul.${gsClassName} > li ul > li:hover, .menu.dropdown > ul.${gsClassName} > li ul > li:hover > ul > li`;

            if (_shouldShowDropDownArrowAndDropdownWidthIsUnset) {
                result[dropdownHorizontalFitUlSelector] = () => ({
                    width: "auto"
                });

                result[dropdownNotHorizontalRightAnchorSelector] = () => ({
                    width: "100%"
                });

                result[dropdownHorizontalAnchorSelector] = () => ({
                    width: "100%"
                });

                result[dropdownDividerSelector] = () => ({
                    display: "none"
                });

                result[dropdownLiSelector] = () => ({
                    whiteSpace: "nowrap",
                    display: "flex !important"
                });
            } else if (cascadeWidthIsNotSet(stylesheet)) {
                result[dropdownLiSelector] = () => ({
                    whiteSpace: "nowrap"
                });
            }

            if (normalHasUnderline) {
                result[`${expandableCssPrefix} span`] = () => ({
                    textDecoration: "underline"
                });
            }

            if (dropdownArrowIsEnabled) {
                result[`${expandableCssPrefix}:after`] = () => ({ ...arrowStyle, content: `'\\e900'` });

                result[
                    `.menuhorizontalright ${expandableCssPrefix}:not(.level-0):before, .menuvertical.menuhorizontalright ${expandableCssPrefix}:before`
                ] = () => ({ ...arrowStyle, content: `'\\e900'`, marginLeft: 0, marginRight: arrowMargin });

                result[
                    `.menuhorizontalright ${expandableCssPrefix}:not(.level-0):after, .menuvertical.menuhorizontalright ${expandableCssPrefix}:after`
                ] = () => ({
                    content: `'' !important`,
                    marginLeft: 0
                });

                result[`${expandableCssPrefix}:not(.level-0), .menuvertical ${expandableCssPrefix}`] = () => ({
                    display: "flex",
                    alignItems: "center"
                });

                result[`${expandableCssPrefix}:not(.level-0) > span, .menuvertical ${expandableCssPrefix} > span`] = () => ({
                    flex: "1"
                });

                result[
                    `.menu:not(.tree) ${expandableCssPrefix}:not(.level-0):after, .menuvertical:not(.tree) ${expandableCssPrefix}:after`
                ] = () => ({
                    content: `'\\e901'`
                });

                result[
                    `.menuhorizontalright:not(.tree) ${expandableCssPrefix}:not(.level-0):before, .menuhorizontalright.menuvertical:not(.tree) ${expandableCssPrefix}:before`
                ] = () => ({
                    content: `'\\e901'`,
                    transform: "rotate(180deg)"
                });

                result[`${expandableCssPrefix}, ${expandedCssPrefix}`] = () => ({
                    textDecoration: "unset !important"
                });

                if (horizontalAlignIsCenter) {
                    result[`.menuvertical:not(.menuhorizontalright) ${expandableCssPrefix}:before`] = () => ({
                        ...arrowStyle,
                        content: `'\\e900'`,
                        visibility: "hidden"
                    });
                }

                if (normalHasUnderline || selectedHasUnderline) {
                    result[`${expandableCssPrefix}.${selected} span`] = () => ({
                        textDecoration: "underline"
                    });
                }

                if (normalHasUnderline || hoverHasUnderline) {
                    result[`${expandableCssPrefix}:hover span`] = () => ({
                        textDecoration: "underline"
                    });
                }

                if (hoverHasUnderline) {
                    result[`${expandedCssPrefix} span`] = () => ({
                        textDecoration: "underline"
                    });

                    result[`${expandedCssPrefix}:hover span`] = () => ({
                        textDecoration: "underline"
                    });
                }
            }

            return result;
        },
        fixUnderlineSpec = stylesheet => {
            const selectedHasUnderline = !!R.path([path.selected, path.inactive, path.text, path.underline], stylesheet);
            const result = {};

            if (selectedHasUnderline) {
                result[`${selectedCssPrefix} span`] = () => ({
                    textDecoration: "underline"
                });
            }

            return result;
        },
        selectedPathMixConf = [path.selected, ["padding"]],
        selectedAndExpandableMixPath = [selectedPathMixConf, [path.expandable, []]],
        selectedAndExpandedMixPath = [selectedPathMixConf, [path.expanded, []]],
        mixSpec = {
            // @ts-ignore
            ...mixBlockSpecs(selectedAndExpandableMixPath, selectedAndExpandableCssPrefix, menuNoHoverBlockSpec),
            // @ts-ignore
            ...mixBlockSpecs(selectedAndExpandedMixPath, selectedAndExpandedCssPrefix, menuNoHoverBlockSpec)
        },
        // expanded style overwrite hover and selected style
        expandedIsHasHighestPriorityForDropdownSpec = menuNoHoverBlockSpec(
            path.expanded,
            expandedDropDownCssPrefix,
            expandedDropdownArrowOmitKeys
        );

    return (stylesheet: MenuStylesheet): Record<string, any> => {
        const showDropDownArrow = shouldShowDropDownArrow(stylesheet);
        const expandableOmitConfig = ["color"];
        const commonMenuSpec = {
            ...menuBlockSpec(path.item, baseCssPrefix),
            ...(showDropDownArrow ? {} : menuBlockSpec(path.expandable, expandableCssPrefix, expandableOmitConfig)),
            ...(showDropDownArrow ? {} : makeExpandedSpec(stylesheet)),
            ...{
                [`.menu ${expandedCssPrefix}`]: makeSpec((showDropDownArrow ? pickEverything : pickBgAndBorderProps)(buttonBlockCssConfig))(
                    path.item,
                    path.hover
                )
            }, // eslint-disable-line max-len
            ...menuBlockSpec(path.selected, selectedCssPrefix, ["padding"])
        };
        return R.applySpec({
            ...makeDividerSpec(dividerCssPrefix),
            ...commonMenuSpec,
            ...(showDropDownArrow ? {} : expandedIsHasHighestPriorityForDropdownSpec),
            ...(showDropDownArrow ? {} : mixSpec),
            ...makeBlockSpec(`.${gsClassName}`),
            ...makeHorizontalFitDividerFixSpec(),
            ...makeMenuDropdownItemWidthSpec(),
            ...makeTextIntendRulesSpec(),
            ...makePreviewHoverSpec(),
            ...makePreviewExpandedSpec(stylesheet),
            ...makeAnchorLineHeightSpec(),
            ...makeSpanLineHeightSpec(),
            ...makeDropdownArrowSpec(stylesheet),
            ...fixUnderlineSpec(stylesheet)
        })(stylesheet);
    };
};
