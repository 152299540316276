export const splitSentenceIntoMultipleLines = (
    sentence: string,
    minLineCharatersLength: number
) =>
    sentence
        .split(" ")
        .reduce(
            (lines, part) => {
                lines[lines.length - 1] = lines[lines.length - 1] ? `${lines[lines.length - 1]} ${part}` : part; // eslint-disable-line no-param-reassign

                if (lines[lines.length - 1].length >= minLineCharatersLength) {
                    return [...lines, ""];
                }
                return lines;
            },
            [""]
        )
        .filter((x) => x) // remove last empty line if there
        .join("\n");
