import React from 'react';
import IconButtonsGroup from './index';
import Icons from '../../../view/Icons/index';
import type { ChangeLetterCaseButtonGroupProps } from './flowTypes';

// TODO: Co-ordinate with Sam / Tobias for correct icons
const
    changeLetterCaseButtons = {
        uppercase: {
            title: "msg: common.toUpperCase {To upper case}",
            icon: Icons.UPPERCASE
        },
        lowercase: {
            title: "msg: common.toLowerCase {To lower case}",
            icon: Icons.LOWERCASE
        },
    };

export default (props: ChangeLetterCaseButtonGroupProps) => {
    const
        { dispatch } = props,
        buttonGroup = Object.keys(changeLetterCaseButtons).map(key => ({
            ...props[key],
            ...changeLetterCaseButtons[key]
        }));

    return (
        <IconButtonsGroup buttonGroup={buttonGroup} dispatch={dispatch} />
    );
};
