import React from "react";
export const SvgLinkedinCircleFillDark = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <circle cx={24} cy={24} r={24} fill="#3c3c3c" />
            <path
                fill="#ffffff"
                d="M21.525 19.511h4.165v2.132h.06c.58-1.038 1.998-2.132 4.111-2.132 4.397 0 5.21 2.733 5.21 6.287v7.238h-4.342V26.62c0-1.53-.031-3.499-2.258-3.499-2.261 0-2.606 1.667-2.606 3.388v6.528h-4.34V19.511zm-7.525 0h4.515v13.525H14zm4.515-3.757a2.256 2.256 0 01-2.257 2.254A2.256 2.256 0 0114 15.754a2.256 2.256 0 012.258-2.254 2.256 2.256 0 012.257 2.254z"
            />
        </g>
    </svg>
);
