/* eslint-disable max-len */
import React from 'react';

import { useIntl } from "../../../../../../../view/intl";

import styles from "../styles.css";

export const AdjustSettingsBanner = () => {
    const intl = useIntl();

    return (
        <div className={styles.adjustSettingsBannerContainer}>
            <div className={styles.adjustSettingsBanner}>
                <div className={styles.adjustSettingsBannerIcon} />
                <div className={styles.adjustSettingsBannerText}>
                    {intl.msgJoint("msg: component.text.createAIText.dialog.adjustSettings.text {If the generated text does not meet your expectation, please try to adjust your settings or keywords.}")}
                </div>
            </div>
        </div>
    );
};
