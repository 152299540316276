/* eslint-disable react/jsx-props-no-spreading */

import React from "react";
import cx from 'classnames';

import { useDraggable } from "@dnd-kit/core";
import { FieldLabels, UndefinedFields } from "../../constants";
import { getAppConfig } from "../../../../App/epics/appConfig/appConfig";
import AppConfig from "../../../../../utils/AppConfig";
import { PartnerName } from '../../../../../../../server/shared/partners/PartnerName';
import * as styles from './AddContactFormFields.css';
import * as Actions from "../../actionTypes";
import type { AddFieldsProps } from "../flowTypes";
import Label from '../../../../../view/common/Label';
import { injectIntl, Msg } from "../../../../../view/intl/index";
import { selectionBorder } from '../ContactForm.css';
import Icons from "../../../../../view/Icons";

export const DraggableField = injectIntl(({ name, selected, contactDetailsFields, formElementsOrder, dispatch, intl }) => {
    const isContactFieldAlreadyAdded = ((contactDetailsFields.indexOf(name) > -1 && formElementsOrder.indexOf(name) > -1) ||
                    (name === "fileupload" && formElementsOrder.indexOf("fileupload") > -1));
    const {
        attributes,
        listeners,
        setNodeRef
    } = isContactFieldAlreadyAdded ? { attributes: {}, listeners: {}, setNodeRef: null } : useDraggable({ id: name + "_new" });
    const tooltipText = isContactFieldAlreadyAdded ? "This field is only allowed once per form." : "",
        tooltipPosition = isContactFieldAlreadyAdded ? "top" : "";

    return (<div
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        key={name}
        className={cx(styles.field, { [selectionBorder]: selected }, { [styles.disabledFieldCtr]: isContactFieldAlreadyAdded })}
        onClick={(e) => {
            if (isContactFieldAlreadyAdded) {
                return;
            }
            if (e.detail === 1) {
                dispatch({ type: Actions.CONTACT_FORM_FIELD_TOGGLED, payload: name });
            } else if (e.detail === 2) {
                dispatch({ type: Actions.CONTACT_FORM_ADD_FIELDS_SAVE });
            }
        }}
    >
        <div className={styles.draggingHandle}>
            { isContactFieldAlreadyAdded ? <Icons.DRAG_DROP_DISABLED /> : <Icons.DRAG_DROP_ACTIVE /> }
        </div>
        <span
            className={cx(styles.fieldLabel, { [styles.disabledField]: isContactFieldAlreadyAdded })}
            data-title-position={tooltipPosition}
            data-title={tooltipText}
        >
            {intl.msgJoint(FieldLabels[name])}
        </span>
    </div>);
});

const FieldsDisplay = ({ fields, contactDetailsFields, formElementsOrder, dispatch }) => {
    return (<div className={styles.fieldsContainer}>
        {fields.map((field, index) => {
            let { name, selected } = field;
            return <div className={styles.draggableFieldCtn}>
                <DraggableField
                    contactDetailsFields={contactDetailsFields}
                    formElementsOrder={formElementsOrder}
                    key={index}
                    name={name}
                    selected={selected}
                    dispatch={dispatch}
                />
            </div>;
        })}
    </div>);
};

export default ({ dispatch, allFields, formElementsOrder }: AddFieldsProps) => {
    const appConfig = AppConfig(getAppConfig()),
        partnerName = appConfig.getOptional(`partnerName`, PartnerName.ONECOM);
    const contactDetails = allFields.filter(field => UndefinedFields.indexOf(field.name) === -1),
        otherFields = partnerName === PartnerName.ONECOM ?
            allFields.filter(field => UndefinedFields.indexOf(field.name) > -1) :
            allFields.filter(field => UndefinedFields.indexOf(field.name) > -1 && field.name !== "fileupload");

    return (
        <div>
            <div className={styles.titleCtn}>
                <Label className={styles.title}>{"msg: component.contactForm.dragDrop {Drag and drop the fields you need}"}</Label>
            </div>
            <div className={styles.fieldsGroup}>
                <Msg k="contact.form.contactDetails" className={styles.fieldsGroupLabel}>Contact details</Msg>
                <FieldsDisplay
                    contactDetailsFields={contactDetails.map(({ name }) => name)}
                    formElementsOrder={formElementsOrder}
                    fields={contactDetails}
                    dispatch={dispatch}
                />
            </div>
            <div className={styles.fieldsGroup}>
                <Msg k="contact.form.otherFields" className={styles.fieldsGroupLabel}>Other fields</Msg>
                <FieldsDisplay
                    contactDetailsFields={contactDetails}
                    formElementsOrder={formElementsOrder}
                    fields={otherFields}
                    dispatch={dispatch}
                />
            </div>
        </div>
    );
};
