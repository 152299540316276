import React from "react";
export const SvgSnapchatRoundedFillDark = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none" fillRule="evenodd">
            <rect
                width={48}
                height={48}
                fill="#3c3c3c"
                fillRule="nonzero"
                rx={6}
            />
            <path
                fill="#ffffff"
                d="M24.281 13.5c.916 0 4.017.255 5.477 3.534.492 1.104.374 2.978.28 4.484l-.005.064c-.01.164-.02.321-.028.474a.848.848 0 00.371.093c.277-.013.598-.104.954-.271a1.02 1.02 0 01.424-.086c.16 0 .324.031.462.088.41.147.668.443.675.774.008.422-.369.787-1.119 1.083a5.292 5.292 0 01-.321.11c-.424.134-1.064.338-1.237.747-.09.213-.055.486.106.812a.368.368 0 01.01.022c.057.131 1.41 3.224 4.42 3.72a.46.46 0 01.383.48.612.612 0 01-.05.207c-.224.526-1.177.913-2.911 1.182-.058.078-.118.354-.155.523a7.3 7.3 0 01-.126.514.5.5 0 01-.512.384h-.026c-.122 0-.29-.02-.5-.062a5.871 5.871 0 00-1.18-.125c-.274 0-.559.024-.845.071-.56.094-1.034.43-1.586.82-.788.558-1.68 1.19-3.038 1.19-.06 0-.118-.002-.162-.004a1.52 1.52 0 01-.112.004c-1.357 0-2.25-.632-3.038-1.19-.55-.39-1.026-.726-1.586-.82a5.183 5.183 0 00-.846-.071c-.495 0-.89.077-1.18.134a2.79 2.79 0 01-.5.07c-.348 0-.484-.213-.536-.391a7.292 7.292 0 01-.127-.52c-.037-.169-.097-.446-.155-.525-1.734-.27-2.687-.656-2.912-1.185a.62.62 0 01-.05-.206.46.46 0 01.385-.48c3.008-.495 4.362-3.588 4.419-3.72l.01-.022c.16-.326.196-.6.106-.812-.173-.41-.813-.613-1.237-.748a5.105 5.105 0 01-.321-.109c-1.025-.405-1.168-.87-1.107-1.19.084-.444.624-.741 1.076-.741a.82.82 0 01.35.072c.386.18.728.272 1.018.272a.88.88 0 00.43-.094l-.032-.54c-.095-1.504-.212-3.377.279-4.48 1.46-3.277 4.554-3.532 5.467-3.532l.383-.004h.055z"
            />
        </g>
    </svg>
);
