let timeout;
let target;

export default (event: any, singleClick: Function, doubleClick: Function) => {
    if (timeout) {
        clearTimeout(timeout);
    }

    if (!timeout || target !== event.currentTarget) {
        event.persist();

        target = event.currentTarget;
        timeout = setTimeout(() => {
            timeout = null;
            singleClick(event);
        }, 500);
    } else {
        doubleClick(event);

        target = null;
        timeout = null;
    }
};
