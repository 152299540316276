import React from 'react';
import cx from 'classnames';
import Msg from "../../../../view/intl/Msg";
import { EPSeparator } from "../../../Panel/view/EPSeparator";
import { SHOW_WEBSHOP_ADMIN_DIALOG } from "../WebshopAdminDialog/actionTypes";
import { EXTENDED_PANEL_CLOSE } from "../../../Panel/actionTypes";
import { SingleProductComponentTip, ExtendedPanelTooltip } from "../../../Tooltip/ids";
import {
    PRODUCT_WIDGET_SHOW_MANAGE_SHOP_INSERTER_TOOLTIP
} from "../../ProductWidget/actionTypes";
import { AfterSetupTooltips } from './AfterSetupTooltips';
import { SHOW_TOOLTIP_FOR_EXTENDED_PANEL, CLOSE_TOOLTIP } from "../../../Tooltip/actionTypes";

import styles from './styles.css';

type Props = {
    dispatch: Function;
    isTrialUser?: boolean;
};

const openWebshopAdminDialog = (dispatch, isTrialUser) => {
    if (!isTrialUser) {
        return () => {
            dispatch({ type: SHOW_WEBSHOP_ADMIN_DIALOG });
            dispatch({ type: EXTENDED_PANEL_CLOSE });
        };
    }
    return () => null;
};

const calcToRightAndVCenter = ({ top, width, height, left }) => {
    // position relative to the help tip
    return { x: left + width, y: (top + (height / 2)) - 4 };
};

export const ManageShopButton = ({ dispatch, isTrialUser }: Props) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [buttonPosition, setButtonPosition] = React.useState<DOMRect | null>(null);

    const showDisabledTooltip = () => {
        if (isTrialUser && buttonPosition) {
            dispatch({
                type: SHOW_TOOLTIP_FOR_EXTENDED_PANEL,
                payload: {
                    position: calcToRightAndVCenter(buttonPosition),
                    elementDimensions: buttonPosition,
                    id: ExtendedPanelTooltip,
                    customClass: styles.helpTipContainer,
                    msg: { text: 'msg: demo.restrictedFeature'
                    + ' {This feature is not available in the trial version.}' },
                }
            });
        }
    };

    const hideTooltip = () => {
        dispatch({ type: CLOSE_TOOLTIP });
    };

    React.useEffect(() => {
        const clientRect = ref.current && ref.current.getBoundingClientRect();
        setButtonPosition(clientRect);
        const { top, width, height, left } = clientRect || {};
        dispatch({
            type: PRODUCT_WIDGET_SHOW_MANAGE_SHOP_INSERTER_TOOLTIP,
            payload: {
                position: calcToRightAndVCenter({ top, width, height, left }),
                id: SingleProductComponentTip,
                elementDimensions: { top, width, height, left },
                customClass: styles.afterSetupTooltipContainer,
                msg: { text: '' },
                timeout: 0,
                showIcon: false,
                showTipText: false,
                textStyle: { display: 'none' },
                customHTML: <AfterSetupTooltips
                    dispatch={dispatch}
                    manageShopTooltip
                />,
            }
        });
    }, [ref]);

    return (
        <React.Fragment>
            <div className={cx(styles.manageShopButtonContainer)} ref={ref}>
                <button
                    type="button"
                    onClick={openWebshopAdminDialog(dispatch, isTrialUser)}
                    className={isTrialUser ? cx(styles.manageShopButtonDisabled) : cx(styles.manageShopButton)}
                    onMouseEnter={showDisabledTooltip}
                    onMouseLeave={hideTooltip}
                >
                    <Msg k="webshopInserter.manageShop">
                        Manage shop
                    </Msg>
                </button>
            </div>
            <EPSeparator />
        </React.Fragment>
    );
};
