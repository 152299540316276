
import { omit, path } from "ramda";
import siteDataEpic from '../../App/epics/siteData/index';
import { missingAssetUrls } from '../../MissingAssets/epic/index';
import kind from './kind';
import reducer from './reducer';
import view from './view/index';
import workspace from './view/workspace';
import propertiesPanel from './propertiesPanel/index';
import calcRenderProps from "./calcRenderProps";
import adjustmentHookConfig from './adjustmentHookConfig';
import componentMainActions from './componentMainActions/index';
import { MinGalleryDimensions } from "./constants/index";
import previewConfig from './previewConfig';
import type { InstagramGalleryComponent, InstagramGalleryComponentDependsOn } from "./flowTypes";
import stylesheetsEpic from "../../Workspace/epics/stylesheets/index";
import siteFontsReducer from "../../Fonts/siteFontsReducer";
import fontStatusReducer from "../../Fonts/fontStatusReducer";
import { widthReducer as templateWidth } from "../Template/epics/template/index";
import { ComponentNames } from "../constants";
import { globalVariablesEpic } from "../../App/epics/globalVariablesEpic";
import PropsTypeMap from "./galleryTypes";
import stylesheetsIdToNameMapEpic from '../../Workspace/epics/stylesheets/idToNameMap';
import defaultStyleIdEpic from '../commonEpics/buttonDefaultStyleId';
import { colorThemeSiteSettingsEpic } from "../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import configurationDialogId from "./configurationDialog/configurationDialogId";
import { ErrorMsgsOnAdd } from "./errorMessagesOnAdd";
import { socialAccountsReducer } from "../../SocialAccounts/socialAccountsReducer";
import { componentTierManagerEpic } from "../../ComponentTierManager/epics/index";

export default {
    view,
    workspace,
    kind,
    label: ComponentNames[kind],
    shortcutIconName: 'instagramGallery',
    requireConfigurationOnDrop: () => true,
    propertiesPanel,
    configurationDialog: {
        dialogId: configurationDialogId,
        dialogProps: {
            socialAccounts: socialAccountsReducer
        }
    },
    reducer,
    calcRenderProps,
    adjustmentHookConfig,
    shouldKeepAspectRatio: () => true,
    dependsOn: {
        site: siteDataEpic.reducer,
        missingAssetUrls,
        fontStatus: fontStatusReducer,
        globalStyles: stylesheetsEpic.reducer,
        defaultStyleId: defaultStyleIdEpic.reducer,
        stylesheetsIdToNameMap: stylesheetsIdToNameMapEpic.reducer,
        globalVariables: globalVariablesEpic.reducer,
        siteFonts: siteFontsReducer,
        socialAccounts: socialAccountsReducer,
        templateWidth,
        themeSettingsData: colorThemeSiteSettingsEpic.reducer,
        componentTierData: componentTierManagerEpic.reducer,
    },
    minDimensions: MinGalleryDimensions,
    componentMainActions,
    errorMsgsOnAdd: ErrorMsgsOnAdd,
    adjustComponentOnAdd: (
        defaultState: InstagramGalleryComponent & { type: string },
        { templateWidth: width, defaultStyleId, globalStyles: { styles } }: InstagramGalleryComponentDependsOn
    ): InstagramGalleryComponent => {
        const btnStyleId = path(['style', 'globalId'], defaultState) || defaultStyleId,
            style = styles.filter(({ id }) => id === btnStyleId)[0],
            type = defaultState.type,
            specificPropsForType = PropsTypeMap[type] || {};

        return {
            ...omit(['type'], defaultState),
            ...defaultState,
            style: {
                type: style.type,
                globalName: style.name,
                globalId: btnStyleId,
                text: { size: null }
            },
            width,
            left: 0,
            ...specificPropsForType
        };
    },
    mobileEditorConfig: {
        ...previewConfig
    }
};
