/* eslint-disable max-len */
import React from "react";
import type { Intl } from "../../../view/intl/index";
import * as siteSettingsStyles from '../SiteSettingsDialog/view/SiteSettingsDialog.css';
import {
    GENERAL_INFO_ADDRESS_STREET_ADDRESS_INPUT_CHANGED,
    GENERAL_INFO_ADDRESS_STREET_ADDRESS_INPUT_CHANGED_ON_BLUR,
    GENERAL_INFO_ADDRESS_ZIP_INPUT_CHANGED,
    GENERAL_INFO_ADDRESS_ZIP_INPUT_CHANGED_ON_BLUR,
    GENERAL_INFO_ADDRESS_CITY_INPUT_CHANGED,
    GENERAL_INFO_ADDRESS_CITY_INPUT_CHANGED_ON_BLUR,
    GENERAL_INFO_ADDRESS_AREA_INPUT_CHANGED,
    GENERAL_INFO_ADDRESS_AREA_INPUT_CHANGED_ON_BLUR,
    GENERAL_INFO_ADDRESS_COUNTRY_CODE_CHANGED,
} from './actionTypes';
import HorizontalSpacer from '../../../view/common/HorizontalSpacer';
import VerticalSpacer from '../../../view/common/VerticalSpacer';
import { Combobox as ComboBox } from '../../../view/common/Combobox/index';
import { ValidatedInput } from "../../../view/common/Input/ValidatedInputField";
import {
    POSTAL_CODE,
    PROVINCE,
    STREET_ADDRESS,
    LOCALITY,
} from "../BusinessListing/GoogleMyBusiness/Mappers/gmbErrorFields";
import { placeholderFieldFormatMap } from "./utils";
import type { GeneralGlobalData } from "./generalGlobalDataEpic/generalGlobalDataEpic";
import type { gmbErrorType } from "../BusinessListing/flowTypes";

/*:: import type { CountryFormat } from '../../../../../server/shared/address/types' */

const { ALL_COUNTRIES } = require('../../../../../server/shared/address/constants.js');
const { getCountryFormat } = require('../../../../../server/shared/address/getCountryFormat.js');

const countriesOptions = Object.keys(ALL_COUNTRIES).map(k => ({ value: k, label: ALL_COUNTRIES[k] }));

type Props = {
    intl: Intl,
    dispatch: Dispatch,
    generalGlobalData: GeneralGlobalData,
    addressError?: gmbErrorType | null,
    disableCountry?: boolean,
};

const renderAddress = ({
    intl, dispatch, generalGlobalData, addressError, disableCountry
}: Props) => {
    const { field, msg } = addressError || {};
    const zip = countryFormat => (
        <div>
            <div className={siteSettingsStyles.inputLabel}>
                {intl.msgJoint("msg: generalInfo.zip.label {Zip code}")}
            </div>
            <VerticalSpacer y={6} />
            <ValidatedInput
                style={{ flex: 1 }}
                placeholder={placeholderFieldFormatMap[countryFormat].zip}
                value={generalGlobalData.addressZip || ''}
                onChange={value => {
                    dispatch({ type: GENERAL_INFO_ADDRESS_ZIP_INPUT_CHANGED, payload: value, amendToSelf: true });
                }}
                changedByUserOnBlur={() => dispatch({ type: GENERAL_INFO_ADDRESS_ZIP_INPUT_CHANGED_ON_BLUR })}
                isInvalid={field === POSTAL_CODE}
                invalidInputClass={siteSettingsStyles.validationLabel}
                validationMsg={msg}
                intl={intl}
            />
        </div>
    );
    const city = countryFormat => (
        <div style={{ width: ['CountryFormat3', 'CountryFormat5'].includes(countryFormat) ? 182.5 : 380 }}>
            <div className={siteSettingsStyles.inputLabel}>
                {intl.msgJoint("msg: generalInfo.city.label {City}")}
            </div>
            <VerticalSpacer y={6} />
            <ValidatedInput
                placeholder={placeholderFieldFormatMap[countryFormat].city}
                value={generalGlobalData.addressCity || ''}
                onChange={value => {
                    dispatch({ type: GENERAL_INFO_ADDRESS_CITY_INPUT_CHANGED, payload: value, amendToSelf: true });
                }}
                changedByUserOnBlur={() => dispatch({ type: GENERAL_INFO_ADDRESS_CITY_INPUT_CHANGED_ON_BLUR })}
                isInvalid={field === LOCALITY}
                invalidInputClass={siteSettingsStyles.validationLabel}
                validationMsg={msg}
                intl={intl}
            />
        </div>
    );
    const makeStateProvinceField = countryFormat => (
        <div style={{ width: ['CountryFormat3', 'CountryFormat5'].includes(countryFormat) ? 182.5 : 380 }}>
            <div className={siteSettingsStyles.inputLabel}>
                {
                    (countryFormat === 'CountryFormat4')
                        ? intl.msgJoint('msg: generalInfo.province.label {Province / region (Optional)}')
                        : intl.msgJoint('msg: generalInfo.state.label {State / Province}')
                }
            </div>
            <VerticalSpacer y={6} />
            <ValidatedInput
                placeholder={placeholderFieldFormatMap[countryFormat].stateProvince}
                value={generalGlobalData.addressArea || ''}
                onChange={value => {
                    dispatch({ type: GENERAL_INFO_ADDRESS_AREA_INPUT_CHANGED, payload: value, amendToSelf: true });
                }}
                changedByUserOnBlur={() => dispatch({ type: GENERAL_INFO_ADDRESS_AREA_INPUT_CHANGED_ON_BLUR })}
                isInvalid={field === PROVINCE}
                invalidInputClass={siteSettingsStyles.validationLabel}
                validationMsg={msg}
                intl={intl}
            />
        </div>
    );

    const country = (
        <React.Fragment>
            <div className={siteSettingsStyles.inputLabel}>
                {intl.msgJoint("msg: common.country {Country}")}
            </div>
            <VerticalSpacer y={6} />
            <ComboBox
                placeholder={intl.msgJoint("msg: common.select.placeholder {Select...}")}
                style={{ 'fontSize': '13px' }}
                searchable
                value={generalGlobalData.addressCountryCode}
                options={countriesOptions}
                onChange={
                    ({ value }) => {
                        dispatch({
                            type: GENERAL_INFO_ADDRESS_COUNTRY_CODE_CHANGED,
                            payload: value
                        });
                    }
                }
                disabled={disableCountry}
            />
        </React.Fragment>
    );

    const makeStreetField = countryFormat => (
        <div>
            <div className={siteSettingsStyles.inputLabel}>
                {intl.msgJoint("msg: generalInfo.street.label {Street address}")}
            </div>
            <VerticalSpacer y={6} />
            <ValidatedInput
                placeholder={placeholderFieldFormatMap[countryFormat].street}
                value={generalGlobalData.addressStreetAddress || ''}
                onChange={value => {
                    dispatch({ type: GENERAL_INFO_ADDRESS_STREET_ADDRESS_INPUT_CHANGED, payload: value, amendToSelf: true });
                }}
                changedByUserOnBlur={() => dispatch({ type: GENERAL_INFO_ADDRESS_STREET_ADDRESS_INPUT_CHANGED_ON_BLUR })}
                isInvalid={field === STREET_ADDRESS}
                invalidInputClass={siteSettingsStyles.validationLabel}
                validationMsg={msg}
                intl={intl}
            />
        </div>
    );

    // @ts-ignore
    const countryFormat: CountryFormat = getCountryFormat(generalGlobalData.addressCountryCode),
        streetAddress = makeStreetField(countryFormat);
    let specific;
    if (countryFormat === 'CountryFormat1') {
        specific = (
            <React.Fragment>
                <VerticalSpacer y={15} />
                <div style={{ display: 'flex' }}>
                    {zip(countryFormat)}<HorizontalSpacer x={15} />{city(countryFormat)}
                </div>
                <VerticalSpacer y={15} />
                {country}
            </React.Fragment>
        );
    } else if (countryFormat === 'CountryFormat2') {
        specific = (
            <React.Fragment>
                <VerticalSpacer y={15} />
                {city(countryFormat)}
                { /* @ts-ignore this needs to be fixed */ }
                <VerticalSpacer x={15} />
                {zip(countryFormat)}
                <VerticalSpacer y={15} />
                {country}
            </React.Fragment>
        );
    } else if (countryFormat === 'CountryFormat3') {
        specific = (
            <React.Fragment>
                <VerticalSpacer y={15} />
                <div style={{ display: 'flex' }}>
                    {city(countryFormat)}<HorizontalSpacer x={15} />{makeStateProvinceField(countryFormat)}<HorizontalSpacer x={15} />{zip(countryFormat)}
                </div>
                <VerticalSpacer y={15} />
                {country}
            </React.Fragment>
        );
    } else if (countryFormat === 'CountryFormat5') {
        specific = (
            <React.Fragment>
                <VerticalSpacer y={15} />
                <div style={{ display: 'flex' }}>
                    {zip(countryFormat)}<HorizontalSpacer x={15} />{city(countryFormat)}<HorizontalSpacer x={15} />{makeStateProvinceField(countryFormat)}
                </div>
                <VerticalSpacer y={15} />
                {country}
            </React.Fragment>
        );
    } else {
        specific = (
            <React.Fragment>
                <VerticalSpacer y={15} />
                <div style={{ display: 'flex' }}>
                    {zip(countryFormat)}<HorizontalSpacer x={15} />{city(countryFormat)}
                </div>
                <VerticalSpacer y={15} />
                {makeStateProvinceField(countryFormat)}
                <VerticalSpacer y={15} />
                {country}
            </React.Fragment>
        );
    }

    return <React.Fragment>
        {streetAddress}
        {specific}
    </React.Fragment>;
};

export default renderAddress;
