import * as R from 'ramda';
import { receiveOnly, withSelector } from "../../../../epics/makeCondition";
import { codeComponentsRendererValueActionType } from "./valueActionType";

import type { CodeComponentsRendererState } from "./index";
import { makeEpicStateSelector } from "../../../../epics/makeEpic";

const
    codeComponentsRendererHeadHeightSelector = ({ heights: { headTemplate, headPage } }: CodeComponentsRendererState) =>
        headTemplate + headPage,
    codeComponentsRendererBodyHeightSelector = ({ heights: { bodyTemplate, bodyPage } }: CodeComponentsRendererState) =>
        bodyTemplate + bodyPage,
    CodeComponentsRendererHeadHeightSelector = withSelector(
        codeComponentsRendererValueActionType,
        codeComponentsRendererHeadHeightSelector
    ),
    CodeComponentsRendererBodyHeightSelector = withSelector(
        codeComponentsRendererValueActionType,
        codeComponentsRendererBodyHeightSelector
    ),
    ROCodeComponentsRendererHeadHeightSelector = receiveOnly(
        codeComponentsRendererValueActionType,
        codeComponentsRendererHeadHeightSelector
    ),
    codeComponentsRendererEpicStateFromAppStateSelector =
        makeEpicStateSelector(codeComponentsRendererValueActionType),
    codeComponentHeadSectionSizeFromAppStateSelection = R.compose(
        codeComponentsRendererHeadHeightSelector,
        codeComponentsRendererEpicStateFromAppStateSelector
    );

export {
    codeComponentsRendererHeadHeightSelector,
    CodeComponentsRendererHeadHeightSelector,
    ROCodeComponentsRendererHeadHeightSelector,
    CodeComponentsRendererBodyHeightSelector,
    codeComponentsRendererEpicStateFromAppStateSelector,
    codeComponentHeadSectionSizeFromAppStateSelection
};
