// @ts-nocheck
export const isAnyParentWrapped = (component, structure) => {
    const { relIn } = component;
    if (!relIn || (relIn && !structure[relIn.id])) {
        return false;
    }
    if (structure[relIn.id].block.wrap) {
        return true;
    }
    return isAnyParentWrapped(structure[relIn.id].block, structure);
};
