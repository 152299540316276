import type { SiteSettings } from "../../App/epics/siteSettings/flowTypes";
import type { ImageAsset } from "../../App/flowTypes";
import { isSVG } from "../../oneweb/Image/utils";

const getSocialShareAsset = (pageRef: any, siteSettings: SiteSettings): ImageAsset | null | undefined => {
    if (pageRef && pageRef.socialShareAsset && !isSVG(pageRef.socialShareAsset.contentType)) {
        return pageRef.socialShareAsset;
    }
    if (
        siteSettings && siteSettings.socialShare
        && siteSettings.socialShare.socialShareAsset
        && !isSVG(siteSettings.socialShare.socialShareAsset.contentType)
    ) {
        return siteSettings.socialShare.socialShareAsset;
    }
    // If there is no social share image for a page & for the website, then we should fallback to logo if it is available.
    // https://jira.one.com/browse/WBTGEN-15756

    if (
        siteSettings
        && siteSettings.generalData
        && siteSettings.generalData.logoAsset
        && !isSVG(siteSettings.generalData.logoAsset.contentType)
    ) {
        return siteSettings.generalData.logoAsset;
    }
    return null;
};

export {
    getSocialShareAsset,
};
