import * as R from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './idToNameMapValueActionTypes';
import stylesheetValueActionType from './valueActionType';
import { getAllStylesheets } from './selectors';
import { getGlobalstyleClassNameFromStyle } from '../../../RenderGlobalstyles/getGlobalstyleClassName';

export type IdToNameMap = Record<string, string>;

const
    defaultState: IdToNameMap = {},
    generateStylesheetsIdToNameMap = R.pipe(
        getAllStylesheets,
        R.reduce((acc, style) => ({ ...acc, [style.id]: getGlobalstyleClassNameFromStyle(style) }), {})
    ),
    stylesheetsIdToNameMapEpic = makeEpic({
        valueActionType,
        defaultState,
        updaters: [{
            conditions: [stylesheetValueActionType],
            reducer: ({ values: [globalStyles] }) => ({
                state: globalStyles ? generateStylesheetsIdToNameMap(globalStyles) : defaultState
            })
        }]
    });

export {
    stylesheetsIdToNameMapEpic as default,
    stylesheetsIdToNameMapEpic,
    generateStylesheetsIdToNameMap
};
