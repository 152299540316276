import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from '@mui/styles';
import cx from "classnames";

import { useIntl } from '../../../view/intl/index';

import styles from './TemplateSelector.css';
import { TemplateSearchField } from "./TemplateSearchField";

const useStyles = makeStyles((theme) => ({
    descriptionContainer: {
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
            alignItems: "flex-start",
        },
    },
    searchBarContainer: {
        [theme.breakpoints.down('md')]: {
            padding: "24px"
        }
    }
}));

export const TemplateSearchBar = ({ hideHeader, searchText }) => {
    const classnames = useStyles();
    const intl = useIntl();
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(searchText);
    }, [searchText]);

    const searchBarProps = {
        value,
        setValue
    };

    return (
        <Grid
            container
            direction="column"
            alignItems="flex-start"
            className={cx(
                styles.searchBarContainer,
                classnames.searchBarContainer,
                { [styles.reducedHieght]: hideHeader }
            )}
        >
            <Grid
                item
                className={styles.backButtonContainer}
            >
                { hideHeader && <TemplateSearchField {...searchBarProps} /> }
            </Grid>
            {
                !hideHeader && (
                    <Grid item className={cx(styles.descriptionContainer, classnames.descriptionContainer)}>
                        <div className={styles.selectTemplate}>
                            {intl.msgJoint("msg: templateSelector.customisableTemplates {Choose a fully customisable template}")}
                        </div>
                        <TemplateSearchField {...searchBarProps} />
                    </Grid>
                )
            }
        </Grid>
    );
};
