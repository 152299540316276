/* eslint-disable max-len */
import * as React from 'react';
import * as R from "ramda";
import cx from 'classnames';
import ReactDOMServer from 'react-dom/server'; // eslint-disable-line node/file-extension-in-import
import styles from "./Preview.css";
import { getRowsAndColumns, ContainerComponentKinds, TEXT_INDENT_BLOCK } from '../flattening/Flattening';
import {
    getThemeColorsFromStylesheet
} from '../../Workspace/epics/stylesheets/selectors';
import Text from "../../oneweb/Text/kind";
import Code from "../../oneweb/Code/kind";
import { OPENING_HOURS_KIND } from "../../oneweb/OpeningHours/kind";
import StripKind from "../../oneweb/Strip/kind";
import InlineStylesheet from "../../RenderGlobalstyles/view";
import Head from './Head';
import getCmpRegistry from '../getCmpRegistry';
import isStretchComponentKind from '../../oneweb/isStretchComponentKind';
import * as mobileMenu from '../../oneweb/Menu/mobileMenu';
import '../../oneweb/Menu/mobileMenu.css';
import getComponentClass from "./getComponentClass";
import { getComponentZIndex } from "../../Workspace/zIndex";
import { DynamicHeightComponentsWithContainers } from './DynamicHeightCmps';
import { jquery } from '../staticScripts';
import * as cssBackgroundMapper from "../../../mappers/background/index";
import { isString } from '../../../../utils/string';
import { isObject } from '../../../../utils/object';
import {
    BODY_BACKGROUND_CLASS_NAME,
    MOBILE_BACKGROUND_LINER_ID
} from "./constants";
import { processHTMLForHeadCodeComponent } from "./processHtmlForHeadCodeComponent";
import { PAGE_ROBOTS_NOINDEX } from "../../../../dal/model/DataPageRef";
import { DataPageRef } from "../../../../dal/model/index";
import { isSiteSettingsSeoEnabled } from "../../../../../server/shared/utils/isSiteSettingsSeoEnabled";
import { getMveHeaderStateFromSiteSettings } from "../../MobileViewEditor/header/getMveHeaderStateFromSiteSettings";
import { globalVariablesFrom } from '../../App/epics/globalVariablesEpic';
import { templateLocale2Selector } from '../../oneweb/Template/epics/template/selectorActionTypes';

import { getTopMostPageSectionId, getHeaderSectionId, getHeaderSection } from "../../oneweb/Section/utils";
import { SharedBgImgView } from "../../oneweb/Section/SharedBgImgView/SharedBgImgView";
import type { AnyComponent, ComponentsMap } from '../../../redux/modules/children/workspace/flowTypes';
import type { StylesheetsIdToNameMap } from '../../Workspace/epics/stylesheets/flowTypes';
import { getGroupByCmpId, getGroupIdByCmpId } from "./getGroupByCmpId";
import { getQualityAssetUrlParam } from "../../oneweb/Image/utils";
import { scrollEffects } from "../../oneweb/commonView/ScrollEffects/constants";
import { isDropHtmlExtensionEnabled } from "../../SiteSettings/publishConfig/utils";
import { getSocialShareAsset } from "../../SiteSettings/socialShareGlobalData/utils";
import { getWebsiteTitle } from "../../SiteSettings/General/utils";
import { getCanonicalUrl } from "../../../../dal/model/utils/getCanonicalUrl";
import type { ThemeBackgroundType } from '../../ThemeGlobalData/flowTypes';
import type { CalcProps } from '../flowTypes';
import { getParentThemeMap } from "../../ThemeGlobalData/utils/getParentThemeMap";
import type { DynamicHeightsComponentsInfoMap } from "../flattening/util";
import type { Attachments } from "../../Workspace/epics/componentAttachements/flowTypes";
import { getBBox } from "../../../utils/bBox";
import { getThemeRulesForBackground } from "../../ThemeGlobalData/themeRules";
import { processBackgroundForThemeColorAndGradient } from "../../oneweb/Background/makeStyle";
import { isSectionKind } from "../../oneweb/componentKinds";
import { getBorderWidths } from './utils';
import { getConsentBannerSettings, isConsentBannerEnabled } from "../../../../../server/shared/utils/consentBanner";
import { getChatWidgetData } from "../../../../../server/shared/utils/getChatWidgetData";
import { formatMessage } from '../../../view/intl/CurrentLocale';
import { parseMsgJoint } from "../../../../../server/shared/utils/parseMsgJoint";
import { getStartDayOfTheWeek } from '../../oneweb/OpeningHours/previewUtils';
import { INTL_DAYS_OF_THE_WEEK } from '../../oneweb/OpeningHours/constants';
import type { ParentCmpsHeightUpdatesMap } from "./flowTypes";
import { convertLayoutStyleToStyleObject, isModernLayoutSection, findCmpkindIsExpandableInMHF,
    isWebShopFooterStrip, getAllCmpsInAContainer } from '../../ModernLayouts/preview_utils';
import { maxMHFWidth, RowEdgeGapInPercent } from '../../ModernLayouts/constants';
import { addEdgeGapsToWidth } from "../../ModernLayouts/maxWidthUtils";
import ButtonKind from '../../oneweb/Button/kind';
import WebshopKind from '../../oneweb/WebShop/kind';
import FeaturedProductsKind from '../../oneweb/FeaturedProducts/kind';
import { MCTA_RESIZE_OPTIONS as ButtonResizeOptions } from '../../oneweb/Button/constants';
import type { WebShopMHFEpicState } from "../../oneweb/WebShopMHF/flowTypes";
import ProductWidgetComponentKind from "../../oneweb/ProductWidget/kind";
import { VideoUtils } from "../../../utils/fileUtils";
import { GetMobileHtmlProps } from '../../oneweb/Menu/mobileMenu';
import { ADDRESS_KIND } from '../../oneweb/TextLike/Address/kind';
import { PHONE_KIND } from '../../oneweb/TextLike/Phone/kind';
import { EMAIL_KIND } from '../../oneweb/TextLike/Email/kind';
import Menu from '../../oneweb/Menu/kind';
import { getIsMenuCartAllowed } from '../../oneweb/Menu/utils';
import { ConsentBannerPreferencesButton } from './ConsentBanner/ConsentBannerPreferencesButton';
import { MenuState } from '../../Mobile/header/types';
import isNewSeoEnabled from '../../../../../server/lib/utils/publish/isNewSeoEnabled';
import {
    calcDataKind,
    getColStyle,
    getFaviconAsset,
    getGlobalStyleId,
    getLastRowBottomMargin,
    getPageUrl,
    getRandomColor,
    getStripStyles,
    isAnyComponentAStrip,
    isAutoColorModeOn,
    isComponentIdPresent,
    shouldSetBottomStylesForInserterBlock,
    updateSiteSettingsForTemplatePreview
} from './generateHtml/utils';
import { ProcessComponentScriptsAndStylesProps, Props } from './generateHtml/types';
import { getUnsplashBeaconDetails } from './generateHtml/getUnsplashBeaconDetails';
import { getCartComponent } from './generateHtml/getCartComponent';
import { createGtmLibScriptTag, getFBChatScript, getFacebookPixelCode, getGoogleAdsCode, getKlikenTrackingCode, wrapTrackingScriptForConsent } from './generateHtml/scriptUtils';
import PoliciesDialogComponent from './PoliciesDialogComponent';
import { processHTMLForTermlyScript } from './ConsentBanner/utils';
import getFeatureManager from '../../../getFeatureManager';

const DummyPageData = new DataPageRef({
    title: 'Page title',
    description: 'Page description',
    url: 'home',
    pageId: '',
    items: []
});

const ClearFloat = <div style={{ clear: "both" }} />;

export class GenerateHtml extends React.Component<Props> {
    componentKindsMap: Record<string, any>;
    structure!: Record<string, any>;
    root: any;
    topMostPageSectionId: string | null;
    isBtnInModernLayout!: boolean;
    headerSectionId: string | null;
    isModernLayoutActivated: boolean;
    minPageWidth: string;
    parentToChildrenMap: any;
    templateCodeInHead: any;
    pageCodeInHead: any;
    templateCodeInBodyEnd: any;
    pageCodeInBodyEnd: any;
    processedComponentScriptsAndStyles: Record<string, any>;
    globalStyles: Record<string, any>;
    stylesheetsIdToNameMap: StylesheetsIdToNameMap;
    template: any;
    componentsDependencies: any;
    siteMap: any;
    siteSettings: any;
    thirdPartyConnections: any;
    pageId: string;
    pageName: string;
    page: {
        id: string,
        name: string,
        shareHeaderAndFirstSectionBgImg: boolean,
        shareModernHeaderAndFirstSectionBgImg: boolean,
        shareBgImgOffsetTop: number
    };
    fonts: any;
    mobileData: Record<string, any>;
    isServerPreview?: boolean;
    domain: string;
    hdImages?: boolean;
    userHasEcommerceSubscription?: boolean;
    userHasPremiumSubscription?: boolean;
    flagPublish?: boolean;
    gaAllowed?: boolean;
    fbPixelAllowed?: boolean;
    isClusterUser?: boolean;
    cmpSpecificStyles: Record<string, any>;
    cmpSpecificScripts: Record<string, any>[];
    customScripts: React.JSX.Element[];
    mobileHideMap: Record<string, any>;
    templateSelectorPreviewMode: undefined | string;
    registry: Record<string, any>;
    pushDown: null | undefined | boolean;
    previewBackupTime: null | undefined | number;
    uiLocale: null | undefined | string;
    trackingScript: null | undefined | string;
    previewLocale: null | undefined | string;
    viewerDomain: null | undefined | string;
    previewGeneralData: Record<string, any> | null | undefined;
    globalVariables: Record<string, any>;
    testingWithScreenshots: boolean;
    messages: Record<string, any>;
    isForInserter: boolean;
    parentCmpsHeightUpdatesMap: null | undefined | ParentCmpsHeightUpdatesMap;
    renderOnlyCodeContainer: boolean;
    parentThemeMap: { [a: string]: ThemeBackgroundType };
    componentsMap: ComponentsMap;
    childToParentMap: Record<string, any>;
    dynamicHeightsCmpInfo: DynamicHeightsComponentsInfoMap;
    dynamicHeightComponentAttachments: Attachments;
    consentBannerConfig: Record<string, any>;
    paddingBottomMap: { [id: string]: number };
    config?: Record<string, any>;
    webshopMHFData: WebShopMHFEpicState;
    fbPageIdForChatConnection: string;
    format: null | undefined | string;
    publicRootUrl: null | undefined | string;
    dynamicTemplateSelected: null | boolean;
    isPublicPreview?: boolean;
    sharedBgImageFlag: boolean;

    constructor(props: Props) {
        super(props);
        const {
            data, mobileData, globalStyles, stylesheetsIdToNameMap,
            siteMap, siteSettings, thirdPartyConnections, page, template, fonts,
            isServerPreview, domain, hdImages, isPublicPreview,
            flagPublish,
            userHasEcommerceSubscription, userHasPremiumSubscription, gaAllowed, fbPixelAllowed,
            isClusterUser, trackingScript, componentsDependencies, templateSelectorPreviewMode,
            uiLocale, pushDown, previewBackupTime, previewLocale, previewGeneralData, viewerDomain, templateI18n,
            testingWithScreenshots, messages, renderViewForTheseComponentsKind, isForInserter, parentCmpsHeightUpdatesMap,
            componentsMap, renderOnlyCodeContainer = false, dynamicHeightsCmpInfo, childToParentMap, dynamicHeightComponentAttachments,
            consentBannerConfig = {}, config, webshopMHFData, fbPageIdForChatConnection = '', format, publicRootUrl, dynamicTemplateSelected
        } = props;
        this.componentKindsMap = {};
        this.componentsMap = componentsMap;
        if (data) {
            const {
                structure, root, componentKindsMap, minPageWidth, parentToChildrenMap,
                templateCodeInHead, pageCodeInHead, templateCodeInBodyEnd, pageCodeInBodyEnd,
            } = data;
            this.structure = structure;
            this.root = root;
            this.minPageWidth = minPageWidth;
            this.parentToChildrenMap = parentToChildrenMap;
            this.componentKindsMap = componentKindsMap;
            this.templateCodeInHead = templateCodeInHead;
            this.pageCodeInHead = pageCodeInHead;
            this.templateCodeInBodyEnd = templateCodeInBodyEnd;
            this.pageCodeInBodyEnd = pageCodeInBodyEnd;
        } else {
            this.minPageWidth = template.width;
        }
        this.childToParentMap = childToParentMap;
        this.processedComponentScriptsAndStyles = {};
        this.globalStyles = globalStyles;
        this.stylesheetsIdToNameMap = stylesheetsIdToNameMap;
        if (previewLocale) {
            this.template = { ...template, locale: previewLocale };
        } else {
            this.template = template;
        }
        this.componentsDependencies = componentsDependencies;

        // TODO: Use a consistent notation throughout the project (site vs siteMap).
        this.siteMap = siteMap;

        this.siteSettings = siteSettings;
        this.thirdPartyConnections = thirdPartyConnections;
        this.pageId = page.id;
        this.pageName = page.name;
        this.page = page;
        this.fonts = fonts;
        this.mobileData = mobileData;
        this.isServerPreview = isServerPreview;
        this.domain = domain;
        this.hdImages = hdImages;
        this.userHasEcommerceSubscription = userHasEcommerceSubscription;
        this.userHasPremiumSubscription = userHasPremiumSubscription;
        this.flagPublish = flagPublish;
        this.gaAllowed = gaAllowed;
        this.fbPixelAllowed = fbPixelAllowed;
        this.isClusterUser = isClusterUser;
        this.cmpSpecificStyles = [];
        this.cmpSpecificScripts = [];
        this.customScripts = [];
        this.mobileHideMap = {};
        this.templateSelectorPreviewMode = templateSelectorPreviewMode;
        this.registry = getCmpRegistry(pushDown, renderViewForTheseComponentsKind);
        this.pushDown = pushDown;
        this.previewBackupTime = previewBackupTime;
        this.uiLocale = uiLocale;
        this.trackingScript = trackingScript;
        this.previewLocale = previewLocale;
        this.viewerDomain = viewerDomain;
        this.previewGeneralData = previewGeneralData;
        this.testingWithScreenshots = testingWithScreenshots || false;
        this.messages = messages || {};
        this.isForInserter = !!isForInserter;
        this.parentCmpsHeightUpdatesMap = parentCmpsHeightUpdatesMap;
        this.renderOnlyCodeContainer = renderOnlyCodeContainer;
        this.topMostPageSectionId = getTopMostPageSectionId(props.componentsMap);
        this.headerSectionId = getHeaderSectionId(props.componentsMap);
        this.isModernLayoutActivated = !!isModernLayoutSection(props.componentsMap[this.headerSectionId || ""] || {});
        this.dynamicHeightsCmpInfo = dynamicHeightsCmpInfo;
        this.dynamicHeightComponentAttachments = dynamicHeightComponentAttachments;
        this.consentBannerConfig = consentBannerConfig;
        this.fbPageIdForChatConnection = fbPageIdForChatConnection;
        this.paddingBottomMap = {};
        this.globalVariables = globalVariablesFrom({
            // this.siteSettings may be unavailable in restore-backup preview
            generalGlobalData: (
                this.previewGeneralData ||
                (
                    (
                        this.siteSettings &&
                        this.siteSettings.generalData
                    ) ||
                    {}
                )
            ),
            locale: templateLocale2Selector(this.template),
            i18n: templateI18n || {} // TODO repository team can not preview localized template in client side preview because of this WBTGEN-13056
        });
        this.parentThemeMap = getParentThemeMap(this.componentsMap, this.template.selectedTheme);
        if (R.isEmpty(this.globalVariables)) {
            this.globalVariables = R.reduce(
                (acc, { globalVariables }) => ({ ...acc, ...globalVariables }),
                {},
                R.values(this.componentsDependencies)
            );
        }
        this.config = config;
        this.format = format;
        this.webshopMHFData = webshopMHFData || { data: { policies: [], paymentMethods: [] } };
        this.publicRootUrl = publicRootUrl;
        this.dynamicTemplateSelected = dynamicTemplateSelected || false;
        this.sharedBgImageFlag = this.isModernLayoutActivated ?
            page.shareModernHeaderAndFirstSectionBgImg : page.shareHeaderAndFirstSectionBgImg;
        this.isPublicPreview = isPublicPreview;
    }

    textIndentRenderer(textComponent: AnyComponent, extraStyle: string, wrappedComponent: Record<string, any>) {
        const block = {
            top: 0,
            left: 0,
            width: wrappedComponent.width,
            height: wrappedComponent.height,
            kind: TEXT_INDENT_BLOCK
        };

        const
            // top and left should be relative to the wrappedNode, which is already in proper place with proper dimensions
            { rows, floating, hoverCol } = getRowsAndColumns(
                [{ ...wrappedComponent, top: 0, left: 0 }],
                block, 0, 0, this.template.width, this.props.componentsMap
            ) || {},
            { rowNodes, floatingNodes } = this._generateHtml(rows, block, false, floating);

        return (
            <div className={getColStyle(hoverCol)} style={{ minHeight: block.height }}>
                {rowNodes}
                {floatingNodes}
                {ClearFloat}
            </div>
        );
    }

    processComponentScriptsAndStyles({ component, hasChildren, group }: ProcessComponentScriptsAndStylesProps) {
        if (this.processedComponentScriptsAndStyles[component.id]) {
            return;
        }

        this.processedComponentScriptsAndStyles[component.id] = true;

        const
            { getSpecificStyles, getSpecificScript, customScripts } = this.registry[component.kind],
            globalStyles = this.globalStyles.stylesheets;
        if (getSpecificStyles) {
            const
                componentId = component.id,
                mobileData = this.mobileData || {},
                groupId = getGroupIdByCmpId(mobileData.groups, componentId),
                mobileSetting = groupId && mobileData.settings ? mobileData.settings[groupId] : {},
                autoColorMode = isAutoColorModeOn(this.siteSettings),
                selectedParentTheme = this.parentThemeMap[componentId],
                specificStyles = getSpecificStyles({
                    component,
                    hasChildren,
                    globalStyles,
                    template: this.template,
                    group,
                    mobileSetting,
                    autoColorMode,
                    selectedParentTheme,
                });
            if (specificStyles) {
                this.cmpSpecificStyles.push(specificStyles);
            }
        }

        if (customScripts) {
            const
                scripts = customScripts(this.template.locale),
                cxs = scripts.map(({ src, attributes, innerHTML }, idx) => (
                    <script
                        {...attributes}
                        key={`customScripts-${idx}`}
                        src={src}
                        type="text/javascript"
                        dangerouslySetInnerHTML={{ __html: innerHTML }}// eslint-disable-line
                    />
                ));
            this.customScripts = this.customScripts.concat(cxs);
        }

        if (getSpecificScript) {
            const
                componentId = component.id,
                autoColorMode = isAutoColorModeOn(this.siteSettings),
                selectedParentTheme = this.parentThemeMap[componentId];
            this.cmpSpecificScripts.push(getSpecificScript({
                component,
                globalVariables: this.globalVariables,
                site: this.siteMap,
                siteSettings: this.siteSettings,
                template: this.template,
                isServerPreview: this.isServerPreview,
                isClusterUser: this.isClusterUser,
                pageName: this.pageName,
                domain: this.domain,
                config: this.config,
                autoColorMode,
                selectedParentTheme,
                globalStyles,
                viewerDomain: this.viewerDomain,
            }));
        }
    }

    handleWrappedComponentScriptProcessing(component: Record<string, any>) {
        this.processComponentScriptsAndStyles({
            component,
            hasChildren: ContainerComponentKinds[component.kind] && this.structure[component.id]
        });
        if (this.parentToChildrenMap[component.id]) {
            this.parentToChildrenMap[component.id].forEach(cmp => this.handleWrappedComponentScriptProcessing(cmp));
        }
    }

    getComponentView(anyComponent: AnyComponent, children: any = null, extraStyle: any, floats: any) {
        type styleObject = {
            width: string | number,
            minHeight?: number,
            height?: number,
            backgroundColor?: string,
            minWidth?: number,
        }

        let component = anyComponent;
        if (this.templateSelectorPreviewMode && !this.dynamicTemplateSelected) {
            if (anyComponent.kind === OPENING_HOURS_KIND) {
                component = {
                    ...anyComponent,
                    closedLabel: formatMessage({ id: 'component.openingHours.closed', defaultMessage: 'Closed' }),
                    dayLabels: R.map((msgJointInput) => {
                        const [id, defaultMessage] = parseMsgJoint(msgJointInput);
                        return formatMessage({ id, defaultMessage });
                    }, INTL_DAYS_OF_THE_WEEK),
                    hourFormat: formatMessage({ id: 'component.openingHours.hourFormat', defaultMessage: 'HH' }),
                    open24HoursLabel: formatMessage({ id: 'component.openingHours.open24Hours', defaultMessage: 'Open 24 hours' }),
                    startDayOfTheWeek: getStartDayOfTheWeek(),
                    timeComponentSeparator: formatMessage({ id: 'component.openingHours.timeComponentSeparator', defaultMessage: ':' }),
                };
            }
            if (anyComponent.kind === ADDRESS_KIND) {
                component = {
                    ...anyComponent,
                    specific: {
                        placeholder: {
                            addressName: formatMessage({ id: 'common.businessTitle', defaultMessage: 'Business title' }),
                            addressStreetAddress: formatMessage({ id: 'common.streetAddress', defaultMessage: 'Street address' }),
                            addressCity: formatMessage({ id: 'common.city', defaultMessage: 'City' }),
                            addressZip: formatMessage({ id: 'common.zip', defaultMessage: 'Zip code' }),
                            addressAreaState: formatMessage({ id: 'common.state', defaultMessage: 'State' }),
                            addressAreaProvince: formatMessage({ id: 'common.province', defaultMessage: 'Province' }),
                            addressCountry: formatMessage({ id: 'common.country', defaultMessage: 'Country' }),
                        }
                    }
                };
            }
            if (anyComponent.kind === PHONE_KIND) {
                component = {
                    ...anyComponent,
                    specific: {
                        placeholderText: formatMessage({ id: 'phone.placeholder', defaultMessage: '+44 1234 567890' }),
                    }
                };
            }
            if (anyComponent.kind === EMAIL_KIND) {
                component = {
                    ...anyComponent,
                    specific: {
                        placeholderText: formatMessage({ id: 'email.placeholder', defaultMessage: 'your@email.com' }),
                    }
                };
            }
        }

        const
            structure = this.structure,
            { id, height, width, kind, inTemplate, stretch = false } = component,
            cmpConfig = this.registry[kind],
            dynamicHeightsCmpInfo = this.dynamicHeightsCmpInfo,
            childToParentMap = this.childToParentMap;
        let
            title,
            props = {
                renderedWrappedComponentContentsMap: undefined,
                wrappedComponents: undefined,
                component,
                globalStyleClass: getGlobalStyleId(component, this.globalStyles.stylesheets),
                siteMap: this.siteMap,
                siteSettings: this.siteSettings,
                globalVariables: this.globalVariables,
                viewerDomain: this.viewerDomain,
                currentPageId: this.pageId,
                previewBackupTime: this.previewBackupTime,
                globalStyles: this.globalStyles.stylesheets, // TODO InlineStylesheet needs value [stylesheets]
                testingWithScreenshots: this.testingWithScreenshots,
                paddingBottom: this.paddingBottomMap[component.id] || 0,
                hasSharedBg: false
            },
            style: styleObject = { width };

        if (DynamicHeightComponentsWithContainers[kind]) {
            style.minHeight = height;
        } else {
            style.height = height;
        }

        if (kind === ButtonKind) {
            const { width, height } = ButtonResizeOptions[(component.modernLayoutOptions || {}).size] || {};
            style = {
                ...style,
                minHeight: height,
                minWidth: width,
            };
        }

        if (isStretchComponentKind(kind)) {
            title = component.title;

            props.hasSharedBg = !!(this.sharedBgImageFlag
                && this.topMostPageSectionId
                && this.headerSectionId
                && this.props.componentsMap[this.topMostPageSectionId]
                && component.id === this.topMostPageSectionId
                && this.isServerPreview
                && this.siteMap.activateMobileView);
        }

        if (kind === Text) {
            props.wrappedComponents = structure[id] && structure[id].wrappedComponents;
            const { wrappedComponents }: any = props;
            if (wrappedComponents) {
                // Main point is render of all component views(react elements) is called only when renderToStaticMarkup
                // is called. Since wrapped components are encountered only when the 'render' method of the
                // parent Text component is called and only then getComponentView will be called on
                // wrapped components so by that time render of GenerateHtml has already been finished and we
                // wont be able to add the specific scripts of wrapped components and its descendants
                wrappedComponents.forEach(component => this.handleWrappedComponentScriptProcessing(component));

                props.renderedWrappedComponentContentsMap = wrappedComponents.reduce((acc, wrappedComponent) => {
                    acc[wrappedComponent.id] = ReactDOMServer.renderToStaticMarkup(
                        this.textIndentRenderer(component, extraStyle, wrappedComponent)
                    );
                    return acc;
                }, {});
            }
        } else if (isStretchComponentKind(kind, stretch)) {
            style = { ...style,
                ...getStripStyles(this.isServerPreview,
                    this.template,
                    this.siteMap.activateMobileView) };
        }

        if (kind === Code) {
            // @ts-ignore
            props = { ...props, renderOnlyCodeContainer: this.renderOnlyCodeContainer };
        }

        if (kind === WebshopKind || kind === FeaturedProductsKind || kind === ProductWidgetComponentKind) {
            // @ts-ignore
            props = { ...props, config: this.config };
        }

        let isMenuCartAllowed: boolean;
        if (kind === Menu) {
            const headerSection = getHeaderSection(this.componentsMap);
            const cmpsInHeader = getAllCmpsInAContainer(this.componentsMap, headerSection.id);

            isMenuCartAllowed = getIsMenuCartAllowed(this.componentsMap, this.siteSettings, this.siteMap, cmpsInHeader);
        }

        if (cmpConfig) {
            const
                calcProps: (arg: CalcProps<AnyComponent>) => Record<string, any> = cmpConfig.calcProps,
                ComponentView = cmpConfig.view,
                globalStyles = this.globalStyles.stylesheets,
                hasChildren = !!children;
                // mobileSettings = this.mobileData.settings && this.mobileData.settings[id];

            if (calcProps) { // TODO: WBTGEN-15512: remove this if after every component will implement calcRenderProps
                props = {
                    ...props,
                    ...calcProps({
                        componentId: id,
                        component,
                        // @ts-ignore
                        isMenuCartAllowed,
                        siteMap: this.siteMap,
                        template: this.template,
                        page: this.page,
                        globalStyles,
                        stylesheetsIdToNameMap: this.stylesheetsIdToNameMap,
                        hdImages: this.hdImages,
                        // @ts-ignore
                        hasChildren,
                        isServerPreview: this.isServerPreview,
                        componentsDependencies: this.componentsDependencies,
                        domain: this.domain,
                        locale: this.template.locale,
                        ...(this.previewBackupTime ? { previewBackupTime: this.previewBackupTime } : {}),
                        uiLocale: this.uiLocale,
                        templateSelectorPreviewMode: this.templateSelectorPreviewMode,
                        siteSettings: this.siteSettings,
                        globalVariables: this.globalVariables,
                        viewerDomain: this.viewerDomain,
                        testingWithScreenshots: this.testingWithScreenshots,
                        // @ts-ignore
                        messages: this.messages,
                        selectedParentTheme: this.parentThemeMap[id],
                        topMostPageSectionId: this.topMostPageSectionId,
                        headerSectionId: this.headerSectionId,
                        paddingBottom: this.paddingBottomMap[component.id] || 0,
                        isBtnInModernLayout: this.isBtnInModernLayout || false,
                        parentSection: this.componentsMap[this.childToParentMap[id]],
                        isModernLayoutActivated: this.isModernLayoutActivated,
                        webshopMHFData: this.webshopMHFData,
                    })
                };
            }

            this.processComponentScriptsAndStyles({
                component,
                hasChildren,
                group: (getGroupByCmpId(this.mobileData.groups, component.id, this.props.componentsMap) || [])
            });
            this.mobileHideMap[id] = !isComponentIdPresent(this.mobileData, id);
            const dataKind = calcDataKind(id, kind, children, structure);

            let pushDownProps = {};

            if (dynamicHeightsCmpInfo[id]) {
                const bBox = getBBox(component),
                    bottomOverlap = dynamicHeightsCmpInfo[id].bottomOverlap || null;
                pushDownProps = {
                    'data-bbox': JSON.stringify(bBox),
                    'data-parent': childToParentMap[id] || null,
                    'data-bottom-overlap': bottomOverlap
                };
            }

            const showOnHover = component.onHover &&
                (component.onHover.show === false || component.onHover.show === true) && component.onHover.show;
            return (
                <div
                    data-id={id}
                    data-kind={kind}
                    style={{ ...style, ...extraStyle }}
                    className={styles.componentWrapper}
                >
                    <div
                        id={title}
                        data-in-template={inTemplate}
                        data-id={id}
                        data-show-on-hover={showOnHover}
                        data-kind={dataKind}
                        data-specific-kind={kind}
                        data-pin={isStretchComponentKind(kind) ? (component.pin || 0) : null}
                        data-stretch={component.stretch}
                        {...pushDownProps}
                        className={
                            getComponentClass(
                                component,
                                dataKind,
                                this.mobileHideMap[id],
                                structure,
                                this.template.id
                            )
                        }
                    >
                        <ComponentView {...props}>{children}</ComponentView>
                    </div>
                    {floats}
                </div>
            );
        }

        style.backgroundColor = getRandomColor(); // for components not having preview ready

        return (
            <div
                data-id={id}
                data-kind={kind}
                className={styles.component}
                style={{ ...style, ...extraStyle }}
            >
                {children}
            </div>
        );
    }

    getFloatingItems(floating: Array<AnyComponent>, block: Record<string, any>, isTemplate: boolean, isRow?: boolean) {
        if (!floating) {
            return null;
        }

        const structure = this.structure;

        return floating.map((cmp, idx) => {
            const
                { left, top, kind, stretch = false } = cmp,
                children = structure[cmp.id] && this.generateHtml(cmp.id, isTemplate);

            let style = {
                top: isRow ? top : top - block.top,
                left: isRow ? left : left - block.left,
                zIndex: getComponentZIndex(cmp)
            };

            if (isStretchComponentKind(kind, stretch)) {
                style = { ...style,
                    ...getStripStyles(this.isServerPreview, this.template, this.siteMap.activateMobileView) };
            }

            const isContainer = ContainerComponentKinds.hasOwnProperty(kind);

            return (
                <div
                    key={`float-${idx}`}
                    className={`${styles.float} float`}
                    style={style}
                >
                    {this.getComponentView(cmp, isContainer ? children : null, {}, isContainer ? null : children)}
                </div>
            );
        });
    }

    _generateHtml(
        rows: Record<string, any>[] = [],
        block: Record<string, any>,
        isTemplate: boolean,
        specialFloating?: Array<AnyComponent>,
        modernLayoutContainer?: boolean
    ) {
        const
            structure = this.structure,
            isWebShopStrip = isWebShopFooterStrip(block, rows),
            isPolicyShownFromSiteSettings = this.siteSettings &&
                this.siteSettings.privacyPolicy && this.siteSettings.privacyPolicy.content,
            isModernSection = isModernLayoutSection(block) || isWebShopStrip,
            isStripBlockKind = isStretchComponentKind(block.kind, block.stretch),
            blockWidth = block.width ? block.width : block.right - block.left,
            floating = specialFloating || (structure[block.id] ? structure[block.id].floating : []),
            rowNodes: React.JSX.Element[] = [],
            floatingNodes = this.getFloatingItems(floating, block, isTemplate, true);

        let i;

        for (i = 0; i < rows.length; i++) {
            const
                { columns, top, bottom, modernLayoutRowStyle, vResponsive } = rows[i];

            let rowStyle: Record<string, any> = {
                minHeight: isModernSection ? undefined : bottom - top
            };

            if (!modernLayoutContainer) {
                rowStyle.width = isStripBlockKind ? this.template.width : blockWidth;
            }
            if (columns.length === 1 && columns[0].component) {
                let
                    column = columns[0],
                    component = column.component,
                    { id, kind, stretch = false } = component,
                    children = null,
                    floats = null,
                    cmpStyle: { zIndex?: number, marginTop?: number, marginLeft?: number } = column.modernColumn ? {} : {
                        marginTop: component.wrap ? 0 : component.top - column.top,
                        marginLeft: isStretchComponentKind(kind, stretch) ? 0 : component.left - column.left // Strip leftMargin = 0
                    },
                    subStructure = structure[id],
                    isStretchComponent = isStretchComponentKind(kind, stretch);

                if (isStretchComponent) {
                    rowStyle.width = '100%'; // for strip it always be in a row with 1 column
                }

                if (!ContainerComponentKinds[kind]) {
                    floats = subStructure && this.getFloatingItems(subStructure.floating, column, isTemplate, true);
                } else {
                    children = subStructure && this.generateHtml(id, false);
                }

                let allFloatingItems = this.getFloatingItems(column.floating, column, isTemplate) || [],
                    floatingItems: React.JSX.Element[] = [];
                if (isStretchComponent) {
                    let floatingNonStripCmps: React.JSX.Element[] = [], floatingStripCmps: React.JSX.Element[] = [];
                    allFloatingItems.forEach(item => {
                        if (item.props.style.width === '100%') {
                            floatingStripCmps.push(item);
                        } else {
                            floatingNonStripCmps.push(item);
                        }
                    });
                    if (floatingNonStripCmps.length) {
                        floatingItems.push(<div
                            key="NonStripCmps"
                            className={styles.floatWrapper}
                            style={{ width: this.template.width }}
                        >
                            {floatingNonStripCmps}
                        </div>);
                    }
                    if (floatingStripCmps.length) {
                        floatingItems.push(<div
                            key="StripCmps"
                            className={styles.floatWrapper}
                        >
                            {floatingStripCmps}
                        </div>);
                    }
                } else {
                    floatingItems = allFloatingItems;
                }
                if (floatingItems.length && !isSectionKind(component.kind)) {
                    cmpStyle.zIndex = getComponentZIndex(component);
                }

                if (isModernSection) {
                    cmpStyle = { marginTop: cmpStyle.marginTop };
                    rowStyle = {
                        ...rowStyle,
                        ...convertLayoutStyleToStyleObject(modernLayoutRowStyle)
                    };
                }
                if (modernLayoutContainer && !isStretchComponentKind(component.kind)) {
                    rowStyle.maxWidth = addEdgeGapsToWidth(maxMHFWidth, RowEdgeGapInPercent);
                }

                rowNodes.push(
                    <div
                        key={`row-${i}`}
                        className={cx(
                            styles.row,
                            'row',
                            {
                                [styles.flexRowWitNoColumns]: modernLayoutContainer,
                                [styles.isWebShopStrip]: isWebShopStrip,
                                [styles.ppFromWsb]: isPolicyShownFromSiteSettings,
                                vResponsive,
                                [styles.noSideMargin]: isStretchComponentKind(component.kind)
                            }
                        )}
                        style={rowStyle}
                    >
                        {floatingItems}
                        {this.getComponentView(component, children, cmpStyle, floats)}
                    </div>
                );

                continue;
            }

            let j;
            let colNodes: React.JSX.Element[] = [];

            for (j = 0; j < columns.length; j++) {
                let
                    column = columns[j],
                    { rows: colRows, hoverCol } = column,
                    includesButton = [...(column.components || []), column.component].some(c => c && c.kind === ButtonKind);

                if (colRows) {
                    // implies NO component at column
                    const { rowNodes, floatingNodes } = this._generateHtml(colRows, column, false);
                    let colStyle: Record<string, any> = {
                        minHeight: isModernSection ? undefined : column.bottom - column.top,
                        width: column.right - column.left,
                        float: isModernSection ? undefined : 'left'
                    };

                    if (isAnyComponentAStrip(rowNodes) || isAnyComponentAStrip(floatingNodes)) {
                        colStyle.width = '100%';
                    }
                    colNodes.push(
                        <div
                            key={`col-${j}`}
                            className={getColStyle(hoverCol)}
                            style={colStyle}
                        >
                            {rowNodes}
                            {floatingNodes}
                            {!modernLayoutContainer && ClearFloat}
                        </div>
                    );
                } else if (column.component) {
                    let
                        component = column.component,
                        { id, kind, stretch = false } = component,
                        children = null,
                        floats = null,
                        cmpStyle: { zIndex?: number, marginTop?: number, marginLeft?: number } = column.modernColumn ? {} : {
                            marginTop: component.wrap ? 0 : component.top - column.top,
                            marginLeft: (isStretchComponentKind(kind, stretch) || isModernSection) ? 0 : component.left - column.left // Strip leftMargin = 0
                        },
                        colStyle: Record<string, any> = {
                            minHeight: isModernSection ? undefined : column.bottom - column.top,
                            width: !isModernSection ? column.right - column.left : undefined,
                            float: isModernSection ? undefined : 'left',
                        },
                        subStructure = structure[id];

                    if (!ContainerComponentKinds[kind]) {
                        floats = subStructure && this.getFloatingItems(subStructure.floating, column, isTemplate, true);
                    } else {
                        children = subStructure && this.generateHtml(id, false);
                    }
                    const floatingItems = this.getFloatingItems(column.floating, column, isTemplate);

                    if (floatingItems && floatingItems.length) {
                        cmpStyle.zIndex = getComponentZIndex(component);
                    }

                    if (isStretchComponentKind(component.kind, stretch) || isAnyComponentAStrip(floatingItems)) {
                        colStyle.width = '100%';
                    }
                    // some times the layou might not be upgraded so isExpandable value will not be availabel until layout is upgraded.
                    // TODO: Try to upgrade the layout when component is initilized
                    const isExpandableColumn = column.isExpandable || findCmpkindIsExpandableInMHF(component.kind);
                    if (modernLayoutContainer && !isExpandableColumn) {
                        colStyle.maxWidth = component.width;
                    }
                    colNodes.push(
                        <div
                            key={`row-${i}-col-${j}`}
                            className={cx(getColStyle(column.hoverCol),
                                {
                                    [styles.flexColumn]: modernLayoutContainer,
                                    [styles.isWebShopStrip]: isWebShopStrip,
                                    'isButtonCol': isModernSection && includesButton,
                                    'isExpandable': isExpandableColumn
                                })}
                            style={colStyle}
                        >
                            {floatingItems}
                            {this.getComponentView(component, children, cmpStyle, floats)}
                            {!modernLayoutContainer && ClearFloat}
                        </div>
                    );
                } else if (column.modernColumn) {
                    //This is only for multiple cmps in same column
                    let
                        colCmps = column.components,
                        minLeft = colCmps.reduce((acc, cmp) => Math.min(acc, cmp.left), 10000);

                    // some times the layou might not be upgraded so isExpandable value will not be availabel until layout is upgraded.
                    // TODO: Try to upgrade the layout when component is initilized
                    const isExpandableColumn = column.isExpandable || colCmps.some(c => findCmpkindIsExpandableInMHF(c.kind));

                    let colStyle: Record<string, any> = {
                        maxWidth: !isExpandableColumn ? column.right - minLeft : undefined,
                    };

                    colNodes.push(
                        <div
                            key={`row-${i}-col-${j}`}
                            className={cx(
                                getColStyle(column.hoverCol),
                                styles.flexColumn,
                                {
                                    [styles.verticalColumn]: !column.hPositioned,
                                    [styles.isWebShopStrip]: isWebShopStrip,
                                    'isButtonCol': includesButton,
                                    'isExpandable': isExpandableColumn
                                },
                            )}
                            style={colStyle}
                        >
                            {colCmps.map((component, i) => {
                                let cmpStyle = convertLayoutStyleToStyleObject({
                                    margin: (column.cmps[i].style || {}).margin,
                                    padding: (column.cmps[i].style || {}).padding
                                });

                                return this.getComponentView(component, null, cmpStyle, null);
                            })}
                        </div>
                    );
                }
            }

            if (this.isForInserter && i === rows.length - 1
                    && shouldSetBottomStylesForInserterBlock(block, this.parentCmpsHeightUpdatesMap)) {
                rowStyle.marginBottom = `${getLastRowBottomMargin(block, rows)}px`;
                rowStyle.overflowY = 'hidden';
            }
            if (modernLayoutContainer) {
                rowStyle.maxWidth = addEdgeGapsToWidth(maxMHFWidth, RowEdgeGapInPercent);
            }
            if (isModernSection) {
                rowStyle = {
                    ...rowStyle,
                    ...convertLayoutStyleToStyleObject(modernLayoutRowStyle)
                };
            }

            rowNodes.push(
                <div
                    key={`row-${i}`}
                    className={cx(styles.row, 'row', { [styles.flexRow]: modernLayoutContainer, vResponsive })}
                    style={rowStyle}
                >
                    {colNodes}
                    {!modernLayoutContainer && ClearFloat}
                </div>
            );
        }
        if (block && block.height) {
            let minBlockHeight = block.height;
            if (this.parentCmpsHeightUpdatesMap && this.parentCmpsHeightUpdatesMap[block.id]) {
                minBlockHeight = Math.min(block.height, this.parentCmpsHeightUpdatesMap[block.id].oldHeight);
            }
            this.paddingBottomMap[block.id] = !modernLayoutContainer &&
                (rows && rows.length && block.height > rows[rows.length - 1].bottom) ?
                Math.min(minBlockHeight - rows[rows.length - 1].bottom, 100) : 0;
        }

        return { rowNodes, floatingNodes };
    }

    generateHtml(componentId?: string, isTemplate?: boolean) {
        const
            renderMobileView = (this.isServerPreview ? this.siteMap.activateMobileView : true),
            templateProps = {
                className: "template",
                'data-mobile-view': renderMobileView && !this.pushDown
            };
        // This happens when there is no component in workspace
        if (!componentId) {
            return <div {...templateProps} />;
        }
        const node = this.structure[componentId];

        if (!node) {
            return null;
        }

        const
            { block, block: { width }, rows, hoverCol, modernLayoutStyles = {}, modernLayoutContainer, vResponsive } = node,
            { rowNodes, floatingNodes } = this._generateHtml(rows, block, false, undefined, modernLayoutContainer),
            isWebShopStrip = isWebShopFooterStrip(block, rows),
            isModernSection = isModernLayoutSection(block) || isWebShopStrip,
            isVideoBackgroundDull = block && block.style && block.style.background && block.style.background.assetData &&
                block.style.background.assetData.asset && VideoUtils.isVideoFile(block.style.background.assetData.asset.url) &&
                block.style.background.assetData.overlay === "block";

        if (isTemplate) {
            return (
                <div {...templateProps}>
                    <div className={`${styles.row} row`} style={{ width }}>
                        {floatingNodes}
                    </div>
                    {rowNodes}
                </div>
            );
        } else {
            let
                style: React.CSSProperties = {};
            if (this.isForInserter && rowNodes && rowNodes.length) {
                style.overflow = 'hidden';
            }

            if (ContainerComponentKinds[block.kind]) {
                style.minHeight = '100%'; // children container 'col' should have hieght same as border excluded height of parent
                if (isStretchComponentKind(block.kind)) {
                    style.width = this.template.width;
                    style.margin = '0 auto';
                }
                const blockBorderWidths = block && getBorderWidths(block);
                if (blockBorderWidths) {
                    if (blockBorderWidths.top) {
                        style.marginTop = -blockBorderWidths.top;
                    }
                    if (blockBorderWidths.right) {
                        style.marginRight = -blockBorderWidths.right;
                    }
                    if (blockBorderWidths.bottom) {
                        style.marginBottom = -blockBorderWidths.bottom;
                    }
                    if (blockBorderWidths.left) {
                        style.marginLeft = -blockBorderWidths.left;
                    }
                }
                if (
                    isAnyComponentAStrip(rowNodes) ||
                    isAnyComponentAStrip(floatingNodes) ||
                    modernLayoutContainer
                ) {
                    style.width = '100%';
                    if (isSectionKind(block.kind) || modernLayoutContainer) {
                        if (modernLayoutContainer) {
                            style.minWidth = this.minPageWidth;
                            style = {
                                ...modernLayoutStyles,
                                ...style,
                            };
                        }

                        return (
                            <div
                                className={cx(
                                    getColStyle(hoverCol),
                                    {
                                        [`modernLayout ${styles.flexContainer}`]: modernLayoutContainer,
                                        vResponsive: modernLayoutContainer && vResponsive
                                    }
                                )}
                                style={style}
                            >
                                <div className={styles.sectionFloatWrapper} style={{ width: this.template.width }}>
                                    {floatingNodes}
                                </div>
                                {rowNodes}
                                {!modernLayoutContainer && ClearFloat}
                            </div>
                        );
                    }
                }
                if (isModernSection) {
                    style.width = '100%';
                    style.minWidth = this.template.width;
                }
                if (isVideoBackgroundDull) {
                    style.zIndex = 2;
                }
                return (
                    <div className={getColStyle(hoverCol)} style={style}>
                        {rowNodes}
                        {floatingNodes}
                        {!modernLayoutContainer && ClearFloat}
                    </div>
                );
            } else {
                // non container can only have floating nodes
                return (
                    <div className={`${styles.nonContainer}`}>
                        {floatingNodes}
                    </div>
                );
            }
        }
    }

    shouldRenderMobileHeader() {
        const shouldRender = mobileMenu.shouldRender(this.props.menuState as MenuState) ||
            (this.siteSettings && this.siteSettings.mobile &&
                (this.siteSettings.mobile.logo.show || this.siteSettings.mobile.title.show));
        return (shouldRender && this.isServerPreview) ? this.siteMap.activateMobileView : shouldRender;
    }

    shouldRenderMobileMenu() {
        const shouldRender = mobileMenu.shouldRender(this.props.menuState as MenuState);
        return (shouldRender && this.isServerPreview) ? this.siteMap.activateMobileView : shouldRender;
    }

    isBackupRestorePreview() {
        return !!this.previewBackupTime;
    }

    getConsentBannerSettings() {
        return getConsentBannerSettings(this.thirdPartyConnections);
    }

    isConsentBannerEnabled() {
        return isConsentBannerEnabled(this.getConsentBannerSettings());
    }

    isConsentBannerAutoBlockDisabled() {
        const settings = this.getConsentBannerSettings();
        return settings && settings.autoBlock === "off";
    }

    shouldAddConsentBanner() {
        return (
            !this.isBackupRestorePreview() &&
            this.isServerPreview &&
            this.isConsentBannerEnabled()
        );
    }

    makeConsentBannerData() {
        if (this.shouldAddConsentBanner()) {
            return {
                settings: this.getConsentBannerSettings(),
                config: this.consentBannerConfig
            };
        }
        return null;
    }

    makeConsentBannerPreferencesButton() {
        if (this.shouldAddConsentBanner()) {
            return <ConsentBannerPreferencesButton />;
        }
        return null;
    }

    consentWrapper(code: string, category: string, funcName: string) {
        if (!code) return code;

        const wrap = this.shouldAddConsentBanner() && this.isConsentBannerAutoBlockDisabled();

        if (wrap) {
            return wrapTrackingScriptForConsent(code, category, funcName);
        }

        return code;
    }

    getScripts(pinMobileMenu: number = 0) {
        let scriptTags = jquery.map((url, idx) => <script key={`script-jquery-${idx}`} src={url} />);
        const
            { config, componentKindsMap, registry, domain, template: { locale }, isPublicPreview } = this,
            addedScripts = jquery.reduce((acc, url) => ({ ...acc, [url]: true }), {}), // eslint-disable-line
            componentScripts = Object.keys(componentKindsMap).reduce((prev, kind) => {
                const registryConfig = registry[kind];
                if (registryConfig) {
                    const { js = [], generateScripts } = registryConfig;
                    let finalJs = [...js];
                    if (generateScripts) {
                        finalJs = finalJs.concat(generateScripts({
                            domain,
                            locale,
                            config,
                            isPublicPreview
                        }));
                    }
                    if (finalJs.length) {
                        finalJs.forEach((jsConfig, idx) => {
                            const url = isString(jsConfig) ? jsConfig : jsConfig.src;
                            if (url && !addedScripts[url]) {
                                const props: { id?: string, '_data-params'?: string, async ?: true, defer ?: true, src: string, key: string } = {
                                    src: url,
                                    key: `script-${kind}${idx}`
                                };

                                if (isObject(jsConfig)) {
                                    if (jsConfig.id) props.id = jsConfig.id;
                                    if (jsConfig.params) props['_data-params'] = JSON.stringify(jsConfig.params);
                                    if (jsConfig.async) props.async = true;
                                    if (jsConfig.defer) props.defer = true;
                                }

                                addedScripts[url] = true;
                                prev.push(<script {...props} />);
                            }
                        });
                    }
                }
                return prev;
            }, [] as any);

        this.cmpSpecificScripts.forEach((script, idx) => {
            scriptTags.push(<script key={'cmpScript@' + idx} dangerouslySetInnerHTML={{ __html: script }} />);// eslint-disable-line
        });

        scriptTags = scriptTags.concat(componentScripts).concat(this.customScripts);

        scriptTags.push(
            <script key="util-js" src="/renderStatic/util.js" />,
            <script key="page-load" src="/renderStatic/onPageReady.js" />
        );

        if (this.shouldRenderMobileMenu()) {
            scriptTags.push(<script key="mobile-menu" src="/renderStatic/mobileMenu/mobileMenu_wbtgen.js" />);
        }

        if (pinMobileMenu && !this.componentKindsMap.hasOwnProperty(StripKind)) {
            scriptTags.push(<script key="mobile-sticky-header" src="/renderStatic/strip/stickyStrip.js" />);
        }

        if ((this.isServerPreview && this.siteMap.activateMobileView) || !this.isServerPreview) {
            scriptTags.push(<script
                key="mobile-editor-data"
                dangerouslySetInnerHTML={{ __html:
                    `window._mobileEditorData=${JSON.stringify(this.mobileData, null, 0)}` }}
            />);
            scriptTags.push(<script key="mobile-sorter" src="/renderStatic/mobileSort/mobileEditorScript.js" />);
        }

        scriptTags.push(<script key="handle-hover-box" src="/renderStatic/touchDevicesHoverBoxHandlingScript.js" />);
        scriptTags.push(<script key="page-load" src="/renderStatic/scrollIntoViewOnLoad.js" />);
        scriptTags.push(<script key="page-load" src="/renderStatic/pushdown.js" />);

        return scriptTags;
    }

    getTrackingScripts() {
        /**
         * Cookie Information and tracking script are loaded separately.
         * Tracking script is executed directly irrespective of cookie information script.
         * It is the responsibility of cookie information to stop it from rending if enabled.
         * Tracking script is not needed for backupRestore preview and normal preview rendering.
         */
        /**
         * Id is necessary as the complete comment is expected by the regex in server to skip the code from
         * minimizing and optimization.
         */
        const includeTrackingScripts = (
            this.isServerPreview &&
            !this.isBackupRestorePreview() &&
            !this.templateSelectorPreviewMode &&
            !this.testingWithScreenshots &&
            // TODO: should use `isServerPreview` instead if no bad impact
            (!this.format || this.format === 'html')
        );

        if (includeTrackingScripts) {
            // If you copy the code, please ensure to change the ID
            const trackingScriptTagsString = `<!--#begin code 11111111-1111-1111-1111-111111111111-->
                    <script key="g-tag-load-lib-script">
                        ${this.consentWrapper(createGtmLibScriptTag({ gaAllowed: this.gaAllowed, userHasEcommerceSubscription: this.userHasEcommerceSubscription, siteSettings: this.siteSettings }), 'analytics', 'gtmScriptCode')}
                    </script>
                    <script key="f-pixel-script">
                        ${this.consentWrapper(getFacebookPixelCode({ fbPixelAllowed: this.fbPixelAllowed, userHasEcommerceSubscription: this.userHasEcommerceSubscription, siteSettings: this.siteSettings }), 'advertising', 'facebookPixelCode')}
                    </script>
                    <script key="sitWit-script">
                        ${this.consentWrapper(getGoogleAdsCode({ trackingScript: this.trackingScript, siteSettings: this.siteSettings }), 'advertising', 'googleAdsCode')}
                    </script>
                    <script key="kliken-script">
                        ${this.consentWrapper(getKlikenTrackingCode({ thirdPartyConnections: this.thirdPartyConnections }), 'analytics', 'klikenTrackingCode')}
                    </script>
                    <!--#end code 11111111-1111-1111-1111-111111111111-->
           `;
            return (
                <div id="tracking-scripts-container" dangerouslySetInnerHTML={{ __html: trackingScriptTagsString }} />
            );
        }
        return (
            <div />
        );
    }

    shouldRenderFBChatElement() {
        const chatWidgetData = getChatWidgetData(this.siteSettings);
        return this.fbPageIdForChatConnection &&
            chatWidgetData.enableChatWidget &&
            !this.isBackupRestorePreview() &&
            this.isServerPreview;
    }

    getFBChatElementScript() {
        if (this.shouldRenderFBChatElement()) {
            const chatWidgetData = getChatWidgetData(this.siteSettings);
            const fbChatScriptTagsString = `<!--#begin code 22222222-2222-2222-2222-222222222222-->
                <div id="fb-root"></div>
                <script key="fb-chat-script">
                    ${this.consentWrapper(getFBChatScript({ siteSettings: this.siteSettings }), 'essential', 'fBChatScript')}
                </script>
                <div
                    class="fb-customerchat"
                    attribution="biz_inbox"
                    page_id="${this.fbPageIdForChatConnection}"
                    theme_color="${chatWidgetData.themeColor}"
                >
                </div>
            <!--#end code 22222222-2222-2222-2222-222222222222-->
           `;
            return (
                <div dangerouslySetInnerHTML={{ __html: fbChatScriptTagsString }} />
            );
        }
        return (
            <div />
        );
    }

    getGhostCodeComponents(isHeadComponents: boolean) {
        const { view: View } = this.registry[Code];
        const compViews: React.JSX.Element[] = [];

        function process(comps, renderOnlyCodeContainer) {
            if (!comps || !comps.length) return;

            comps.sort((a, b) => (a.orderIndex - b.orderIndex));
            comps.forEach(cmp => compViews.push(
                <View
                    key={cmp.id}
                    component={cmp}
                    renderOnlyCodeContainer={renderOnlyCodeContainer}
                />
            ));
        }

        if (isHeadComponents) {
            process(this.templateCodeInHead, this.renderOnlyCodeContainer);
            process(this.pageCodeInHead, this.renderOnlyCodeContainer);
        } else {
            process(this.templateCodeInBodyEnd, this.renderOnlyCodeContainer);
            process(this.pageCodeInBodyEnd, this.renderOnlyCodeContainer);
        }

        return compViews;
    }

    hasMobileRender() {
        return !this.isServerPreview || (this.isServerPreview && this.siteMap.activateMobileView);
    }

    getMobileViewRelatedStyles() {
        return this.hasMobileRender()
            ? `.template { visibility: hidden }\nbody { overflow-x: hidden }`
            : null;
    }

    getPublishOnlyComponents(): React.ReactElement<any> {
        return (<React.Fragment>
            { !this.isPublicPreview ?
                <div className="publishOnlyComponents">
                    {getCartComponent({
                        siteSettings: this.siteSettings,
                        siteMap: this.siteMap,
                        componentsDependencies: this.componentsDependencies,
                        template: this.template,
                        isServerPreview: this.isServerPreview,
                        domain: this.props.domain,
                        globalStyles: this.globalStyles,
                        config: this.config,
                    })}
                </div>
                : null }
        </React.Fragment>);
    }

    isDialogComponentAdded(): boolean {
        return !!(this.siteSettings && this.siteSettings.privacyPolicy &&
            this.siteSettings.privacyPolicy.content);
    }
    getDialogComponent(): Array<React.ReactElement<any>> {
        const diagComponentsList: Array<React.ReactElement<any>> = [];
        if (this.isDialogComponentAdded()) {
            diagComponentsList.push(<PoliciesDialogComponent />);
        }
        return diagComponentsList;
    }

    render() {
        const
            mobileHtmlProps: GetMobileHtmlProps = {
                hideMenu: !mobileMenu.shouldRender(this.props.menuState as MenuState),
                siteData: this.siteMap,
                currentPageId: this.pageId,
                header: getMveHeaderStateFromSiteSettings({
                    siteSettings: ({
                        current: this.viewerDomain ?
                            updateSiteSettingsForTemplatePreview(this.globalVariables, this.props.siteSettings)
                            : this.props.siteSettings
                    } as any),
                    componentsMap: this.props.componentsMap,
                    stylesheet: this.globalStyles.stylesheets,
                }),
                menuState: this.props.menuState as MenuState,
                globalVariables: this.globalVariables,
                siteSettings: this.siteSettings
            },
            rootNode = this.generateHtml(this.root, true),
            // Here dummy page data is being used because the page id sent is not a real one.
            // See - wbtgen/dal/index#makePageTemplatePreviewUrl
            pageRef = this.templateSelectorPreviewMode ? DummyPageData : this.siteMap.getPageRefByPageId(this.pageId),
            description = pageRef ? pageRef.description : null,
            htmlStyle = { height: 'auto !important' },
            pageURL = (pageRef instanceof DataPageRef) ? getPageUrl({ pageRef, siteMap: this.siteMap, domain: this.domain, siteSettings: this.siteSettings }) : this.domain,
            pinHeader = this.props.siteSettings && this.props.siteSettings.mobile && this.props.siteSettings.mobile.pin || 0;// eslint-disable-line

        if (this.props.mobileStyle) {
            mobileHtmlProps.mobileStyle = this.props.mobileStyle;
        }
        if (this.viewerDomain) {
            mobileHtmlProps.viewerDomain = this.viewerDomain;
        }
        let templateBackground = this.template.style.background;
        const
            templateThemeSelected = this.template.selectedTheme,
            templateGradientThemeSelected = this.template.selectedGradientTheme,
            templateAssetData = templateBackground && templateBackground.assetData,
            mobileBackgroundLinerParams = JSON.stringify({
                bodyBackgroundClassName: BODY_BACKGROUND_CLASS_NAME,
                linerClassName: styles.bodyBackgroundLiner,
                scrollEffect: (templateAssetData && templateAssetData.scrollEffect) || null
            }),
            isBackgroundImageFixed = (
                templateAssetData
                && templateAssetData.scrollEffect && templateAssetData.scrollEffect === scrollEffects.reveal
            ),
            themeColorsData = getThemeColorsFromStylesheet(this.globalStyles.stylesheets),
            autoColorMode = isAutoColorModeOn(this.siteSettings);

        let cssOptions: { assetUrlQuery?: { quality?: number }} = {};
        if (autoColorMode) {
            const
                backgroundColorFromTheme = themeColorsData[getThemeRulesForBackground(templateThemeSelected, themeColorsData).background],
                backgroundGradientColorFromTheme = themeColorsData[templateGradientThemeSelected];
            if (backgroundColorFromTheme) {
                templateBackground = processBackgroundForThemeColorAndGradient(
                    templateBackground,
                    backgroundColorFromTheme,
                    backgroundGradientColorFromTheme
                );
            }
        }
        if (templateAssetData && templateAssetData.asset) {
            cssOptions.assetUrlQuery = getQualityAssetUrlParam(templateAssetData.asset, 80);
        }
        const
            templateBackgroundStyle = cssBackgroundMapper.toCss(
                templateBackground,
                cssOptions
            );

        let canonicalUrl = "";

        if (!this.templateSelectorPreviewMode && this.siteSettings) {
            canonicalUrl = getCanonicalUrl({
                publicRootUrl: this.publicRootUrl,
                siteMap: this.siteMap,
                dropHtmlExtension: isDropHtmlExtensionEnabled(this.siteSettings),
                item: pageRef
            });
        }

        if (isBackgroundImageFixed) {
            htmlStyle.height = '100%';
        }

        let title = this.pageName;
        let websiteTitle = this.props.siteSettings ? getWebsiteTitle(this.props.siteSettings.generalData) : this.domain;
        websiteTitle = websiteTitle || this.domain;

        if (pageRef) {
            const isHomePage = this.siteMap.isHomePageId(this.pageId);
            let defaultPageTitle = websiteTitle;

            if (!isHomePage && !pageRef.customTitle) {
                defaultPageTitle = `${pageRef.name} | ${websiteTitle}`;
            }

            title = pageRef.customTitle || defaultPageTitle;
        }

        const socialShareAsset = getSocialShareAsset(pageRef, this.props.siteSettings);

        let extraBodyStyle = {};
        if (this.props.downSizeScale) {
            // To down size the preview
            extraBodyStyle = {
                pointerEvents: 'none',
                transform: `scale(${this.props.downSizeScale})`,
                transformOrigin: '0 0',
                // Avoiding white spaces at the bottom after transform in firefox.
                position: 'absolute',
                left: 0,
                right: 0,
                margin: '0 auto',
            };
            // Avoiding white spaces at the bottom after transform.
            htmlStyle.height = '100%';
        }

        return (
            /**
             * Ref: https://stackoverflow.com/a/13172336/3649142
             * Setting height to 100% will fix image zoom issues in android mobiles.
             * Ref: WBTGEN-10391, WBTGEN-10225
             */
            // eslint-disable-next-line react/no-unknown-property
            <html prefix="og: http://ogp.me/ns#" style={htmlStyle}>
                <Head
                    domain={this.domain}
                    title={title}
                    description={description}
                    pageURL={pageURL}
                    siteName={websiteTitle}
                    robots={(isSiteSettingsSeoEnabled(this.siteSettings) && pageRef.robots) || PAGE_ROBOTS_NOINDEX}
                    fonts={this.fonts}
                    componentKindsMap={this.componentKindsMap}
                    shouldRenderMobileHeader={this.shouldRenderMobileHeader()}
                    addMobileTags={this.hasMobileRender()}
                    styleTag={this.cmpSpecificStyles.join('\n')}
                    minPageWidth={this.minPageWidth}
                    favIconAsset={getFaviconAsset(this.siteSettings)}
                    isServerPreview={this.isServerPreview}
                    flagPublish={this.flagPublish}
                    codeComponents={this.getGhostCodeComponents(true)}
                    templateBackgroundStyle={templateBackgroundStyle}
                    templateSelectorPreviewMode={this.templateSelectorPreviewMode}
                    socialShareAsset={socialShareAsset}
                    canonicalUrl={canonicalUrl}
                    consentBannerData={this.makeConsentBannerData()}
                />
                <body
                    className={cx(styles.body, BODY_BACKGROUND_CLASS_NAME)}
                    style={{
                        overflowY: this.template.verticalScroll,
                        overflowX: this.hasMobileRender() ? 'hidden' : 'auto',
                        ...extraBodyStyle
                    }}
                    data-attachments={JSON.stringify(this.dynamicHeightComponentAttachments)}
                >
                    {this.makeConsentBannerPreferencesButton()}
                    {this.fbPageIdForChatConnection && (
                        this.getFBChatElementScript()
                    )}
                    {
                        this.shouldRenderMobileHeader() && (
                            <div>
                                <div
                                    data-mobile-pin={pinHeader}
                                    id="wsb-mobile-header"
                                    className="mm mm-mobile-preview"
                                    dangerouslySetInnerHTML={{ __html: mobileMenu.getMobileHtml(mobileHtmlProps) }}// eslint-disable-line
                                />
                            </div>
                        )
                    }
                    {
                        this.hasMobileRender() &&
                            <style
                                type="text/css"
                                // @ts-ignore
                                dangerouslySetInnerHTML={{ __html: this.getMobileViewRelatedStyles() }}
                            />
                    }
                    <InlineStylesheet
                        stylesheets={this.globalStyles.stylesheets}
                        stylesheetsIdToNameMap={this.stylesheetsIdToNameMap}
                        themeSettingsData={this.siteSettings.themeSettingsData}
                        themeColorsData={getThemeColorsFromStylesheet(this.globalStyles.stylesheets)}
                    />
                    {
                        this.sharedBgImageFlag
                        && this.topMostPageSectionId
                        && this.headerSectionId
                        && this.props.componentsMap[this.topMostPageSectionId]
                        && <SharedBgImgView
                            offsetTop={this.props.page.shareBgImgOffsetTop}
                            firstSectionComponent={this.props.componentsMap[this.topMostPageSectionId]}
                            headerHeight={this.props.componentsMap[this.headerSectionId].height}
                        />
                    }
                    {this.isServerPreview && isNewSeoEnabled(this.domain, this.siteMap.createdTimestamp, this.config?.oneWeb) &&
                    <script>
                        window.newSeoEnabled = true;
                    </script>}
                    {rootNode}
                    {this.getPublishOnlyComponents()}
                    {this.getDialogComponent()}
                    {this.getScripts(pinHeader)}
                    {this.getGhostCodeComponents(false)}
                    <script src="/renderStatic/viewChooser.js" />
                    {!this.isPublicPreview && <script src="/renderStatic/webshopMHF.js" />}
                    <script
                        src="/renderStatic/mobileBodyBackground.js"
                        id={MOBILE_BACKGROUND_LINER_ID}
                        data-params={mobileBackgroundLinerParams}
                    />
                    {this.getTrackingScripts()}
                    {
                        this.config
                        && (getFeatureManager().isUnsplashShown() || this.config.freeOneComServiceProvider === "unsplash")
                        && this.isServerPreview
                        && getUnsplashBeaconDetails(this.props.template, this.props.componentsMap)
                    }
                </body>
            </html>
        );
    }
}

export {
    GenerateHtml as DefaultGenerateHtml
};

export default (props: Props) => {
    const generatedHTMLText = '<!doctype html>\n' + ReactDOMServer.renderToStaticMarkup(<GenerateHtml {...props} />);
    return processHTMLForTermlyScript(processHTMLForHeadCodeComponent(generatedHTMLText));
};
