import React from "react";
import { connect } from "react-redux";
import VerticalSpacer from '../../../../view/common/VerticalSpacer';
import getCenteredDialog from "../../../DialogManager/getCenteredDialogConfig";
import {
    ConfigDialogSettings,
    DialogHeight,
    DialogWidth,
} from "../constants/configurationDialog";
import type { ConnectInstagramAccountDialogPropTypes, ConnectInstagramAccountDialogStateTypes } from "../flowTypes";
import { injectIntl, makeBoldIntlTag, Msg } from "../../../../view/intl/index";
import ComponentConfigurationDialog from "../../../../view/common/dialogs/ComponentConfigurationDialog/index";
import styles from './configurationDialog.css';
import { StripTypes } from "../../../../view/common/dialogs/baseDialog/index";
import { INSTAGRAM_GALLERY_SET_IMAGES_ON_COMPONENT_DROPPED } from "../actionTypes";
import type { AppState } from "../../../../redux/modules/flowTypes";
import { socialAccountsInstagramAssets, socialAccountsInstagramConnected } from "../selectors";
import { componentConfigurationCompleteAction } from "../../../../redux/modules/children/workspace/actionCreators";
import { openConnectInstagramAccountAction } from "../../../SocialAccounts/instagram/actions";

// eslint-disable-next-line max-len
class ConnectInstagramAccountDialog extends React.Component<ConnectInstagramAccountDialogPropTypes, ConnectInstagramAccountDialogStateTypes> {
    render() {
        const { props: { dispatch, intl, isInstagramConnected, instagramUserMediaAssets } } = this;

        if (isInstagramConnected) {
            dispatch(componentConfigurationCompleteAction());
            dispatch({
                type: INSTAGRAM_GALLERY_SET_IMAGES_ON_COMPONENT_DROPPED,
                payload: {
                    assets: instagramUserMediaAssets
                }
            });
            return '';
        } else {
            return (
                <ComponentConfigurationDialog
                    title={ConfigDialogSettings.TITLE}
                    iconClass={styles.instagramIcon}
                    autoFocus={false}
                    stripType={StripTypes.NONE}
                    onSaveText={ConfigDialogSettings.CONNECT_LABEL}
                    onSave={
                        () => {
                            dispatch(openConnectInstagramAccountAction());
                            dispatch(componentConfigurationCompleteAction());
                        }
                    }
                    hideCancel
                >
                    <div className={styles.message}>{intl.msgJoint(ConfigDialogSettings.MESSAGE)}</div>
                    <VerticalSpacer y={10} />
                    <div className={styles.message}>{intl.msgJoint(ConfigDialogSettings.MESSAGE2)}</div>
                    <VerticalSpacer y={10} />
                    <Msg
                        className={styles.subMessage}
                        k="component.instagramFeed.configDialog.subMessage"
                        params={{
                            b: makeBoldIntlTag,
                        }}
                    >
                        {`
                             <b>Tip</b>: If the Instagram login pop-up doesn’t open,
                             try turning off your pop-up blocker or updating your browser version
                        `}
                    </Msg>
                </ComponentConfigurationDialog>
            );
        }
    }
}

const mapStateToProps = (appState: AppState) => ({
    isInstagramConnected: socialAccountsInstagramConnected(appState),
    instagramUserMediaAssets: socialAccountsInstagramAssets(appState),
});

export default getCenteredDialog({
    width: DialogWidth,
    height: DialogHeight,
    component: connect(mapStateToProps)(injectIntl(ConnectInstagramAccountDialog))
});
