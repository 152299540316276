import type { GmbDataType } from "../../flowTypes";
import { areOpeningHoursBreakFree, openingHoursWithGmbTimeToMsTime } from "../../../../oneweb/OpeningHours/utils";

type Category = {
    name: string // NEW-CODE
    // categoryId: string // OLD-CODE
}

type GmbServerDataType = {
    name: string,

    phoneNumbers: { primaryPhone: string }, // NEW-CODE
    // primaryPhone: string, // OLD-CODE

    metadata: { mapsUri: string, placeId: string }, // NEW-CODE
    // metadata: { mapsUrl: string }, // OLD-CODE

    title: string, // NEW-CODE
    // locationName: string, // OLD-CODE

    // locationKey: { placeId: string }, // OLD-CODE
    latlng?: { latitude: number, longitude: number },

    storefrontAddress: { // NEW-CODE
    // address: { // OLD-CODE
        locality: string,
        addressLines: Array<string>,
        administrativeArea: string,
        postalCode: string,
        regionCode: string,
    },
    regularHours: any,

    // NEW-CODE
    categories: {
        primaryCategory: Category,
        additionalCategories?: Array<Category>
    }
    // OLD-CODE
    // primaryCategory: Category,
    // additionalCategories?: Array<Category>
};

const getCategoryId = category => category.name.split(':')[1];          // NEW-CODE
// const getCategoryId = category => category.categoryId.split(':')[1]; // OLD-CODE

const getCategories = (primaryCategory, additionalCategories: Array<Category> = []) => {
    const categories: Array<string> = [];

    if (primaryCategory) {
        categories.push(getCategoryId(primaryCategory));
    }

    additionalCategories.forEach(category => {
        categories.push(getCategoryId(category));
    });

    return categories.join(',');
};

const GmbToWsb = (data: GmbServerDataType): GmbDataType => {
    const {
        name,

        phoneNumbers: { primaryPhone: phoneNumber }, // NEW-CODE
        // primaryPhone: phoneNumber, // OLD-CODE

        metadata: { mapsUri, placeId }, // NEW-CODE
        // metadata: { mapsUrl }, // OLD-CODE

        title: locationName, // NEW-CODE
        // locationName, // OLD-CODE

        // locationKey: { placeId }, // OLD-CODE

        storefrontAddress: { // NEW-CODE
        // address: { // OLD-CODE
            locality,
            addressLines,
            administrativeArea,
            postalCode,
            regionCode,
        } = {},

        regularHours = {},

        categories: {
            primaryCategory, // NEW-CODE
            additionalCategories // NEW-CODE
        }
        // primaryCategory, // OLD-CODE
        // additionalCategories // OLD-CODE
    } = data;

    let addressLocation;

    if (data.latlng) {
        addressLocation = { lat: data.latlng.latitude, lng: data.latlng.longitude };
    }

    const gmbCategories = getCategories(primaryCategory, additionalCategories);

    const
        addressFields = {
            address: "",
            addressUrl: mapsUri,
            // audienceLocale: DEFAULT_LOCALE,
            addressName: locationName,
            addressId: '',
            addressPlaceId: placeId,
            addressViewport: null,
            addressLocation,
            addressStreetAddress: (addressLines || []).join(', '),
            addressCity: locality,
            addressArea: administrativeArea,
            addressZip: postalCode,
            addressCountryCode: regionCode,
            addressFloor: '',
        };

    const openingHours = { ...regularHours, periods: regularHours.periods || [], };

    const wsbFields = {
        name,
        addressFields,
        openingHours,
        phoneNumber,
        gmbCategories
    };

    const wsbOpeningHours = openingHoursWithGmbTimeToMsTime(openingHours.periods);

    if (!areOpeningHoursBreakFree(wsbOpeningHours)) {
        wsbFields.openingHours = { periods: [] };
    }

    return wsbFields;
};

export { GmbToWsb };
