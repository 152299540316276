import memoizee from 'memoizee';
import React from 'react';
import cx from 'classnames';
import TableRow from './TableRow';
import ColGroup from './ColGroup';
import TableStyles from './Table.css';
import type { TableProps } from '../flowTypes';
import TextWrapper from "../../Text/view/TextWrapper";
import TextForPreviewAndPublish from "../../Text/view/index";
import { THEME_TABLE_CLASS } from '../../../ThemeGlobalData/constants';

const factory = (TextView) => memoizee(
    ({
        numCols,
        width,
        colsProps,
        rowsProps,
        commonCellsData,
        inEditMode,
        tableEditModeState,
        stylesheetsIdToNameMap,
        dispatch,
        componentId,
        stylesheets,
        globalVariables,
        site,
        themeSettingsData,
        themeColorsData,
        selectedParentTheme,
    }: TableProps) => {
        return (
            <div className="tableContainer">
                <table
                    className={cx([
                        TableStyles.texttable,
                        themeSettingsData.autoColorMode && THEME_TABLE_CLASS,
                        themeSettingsData.autoColorMode && selectedParentTheme,
                    ])}
                    style={{ width }}
                >
                    <ColGroup colsProps={colsProps} />
                    <tbody>
                        {rowsProps.map((rowProps, index) => (
                            <TableRow
                                key={index}
                                firstCellIndex={index * numCols}
                                rowProps={rowProps}
                                commonCellsData={commonCellsData}
                                tableEditModeState={tableEditModeState}
                                stylesheetsIdToNameMap={stylesheetsIdToNameMap}
                                inEditMode={inEditMode}
                                dispatch={dispatch}
                                componentId={componentId}
                                stylesheets={stylesheets}
                                globalVariables={globalVariables}
                                site={site}
                                TextView={TextView}
                                themeSettingsData={themeSettingsData}
                                themeColorsData={themeColorsData}
                                selectedParentTheme={selectedParentTheme}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        );
    },
    {
        normalizer: (args) => {
            const {
                numCols,
                width,
                colsProps,
                rowsProps,
                commonCellsData,
                inEditMode,
                tableEditModeState,
                stylesheetsIdToNameMap,
                dispatch,
                componentId,
                stylesheets,
                globalVariables,
                site,
                themeSettingsData,
                themeColorsData,
                selectedParentTheme,
            } = args[0];
            return JSON.stringify({
                numCols,
                width,
                colsProps,
                rowsProps,
                commonCellsData,
                inEditMode,
                tableEditModeState,
                stylesheetsIdToNameMap,
                dispatch,
                componentId,
                stylesheets,
                globalVariables,
                site,
                themeSettingsData,
                themeColorsData,
                selectedParentTheme,
            });
        },
        max: 100
    }
);

const TableForWorkspace = factory(TextWrapper);
const TableForPreviewAndPublish = factory(TextForPreviewAndPublish);

export {
    TableForWorkspace as default,
    TableForPreviewAndPublish
};
