import React, { useState } from 'react';
import cx from 'classnames';
import { Box, Flex } from "../../../view/reflexbox/index";
import { inactive, hover } from '../../../mappers/path';
import * as selectors from '../../Workspace/epics/stylesheets/selectors';
import { injectIntl, Msg } from '../../../view/intl/index';
import Scrollbar from '../../../view/common/Scrollbar/index';
import BaseComponentPreview from '../../../view/oneweb/BaseComponentPreview';
import VerticalSpacer from '../../../view/common/VerticalSpacer';
import HorizontalSpacer from '../../../view/common/HorizontalSpacer';
import { ComponentMaskBackground } from '../../presentational/ComponentMask/constants';
import ComponentMask from '../../presentational/ComponentMask/index';
import GrayBgContainer from '../../presentational/GrayBgContainer';
import ColorProperty from '../../presentational/ColorProperty/index';
import { button as TextShadowButton } from '../../presentational/TextShadow/index';
import TextFormatButtonGroup from '../../presentational/IconButtonsGroup/TextFormat';
import optionMapper from "../../oneweb/Link/globalStyle/options";
import { getGlobalstyleHoverClassName } from "../../RenderGlobalstyles/getGlobalstyleClassName";
import TopBar from '../TopBar';
import { linkTabLabel, Messages, Labels, ThemeLabelObj, Actions } from './constants';
import { GS_LINK_GLOBAL_STYLE_SELECTED } from './actionTypes';
import type { LinkGlobalstylesDialogPropTypes } from '../flowTypes';
import styles from '../GlobalstylesDialog.css';
import { BACKGROUND_THEME_WHITE } from "../../ThemeGlobalData/constants";

export const
    Tab = injectIntl(({ intl }) => (<div id="link-globalstyles-tab">{intl.msgJoint(linkTabLabel)}</div>)),
    TabPanel = injectIntl(({
        scrollHeight,
        previewScrollHeight,
        stylesheets,
        stylesheetsIdToNameMap,
        currentStylesheet,
        intl,
        dispatch,
        themeSettingsData,
    }: LinkGlobalstylesDialogPropTypes) => {
        const
            getAllActionsForStyle = (path: string): Record<string, any> => ({
                toggleUnderlineAction: Actions.toggleUnderlineAction[path],
                textColorChangeAction: Actions.textColorChangeAction[path],
                highlightColorChangeAction: Actions.highlightColorChangeAction[path],
                highlightColorRemoveAction: Actions.highlightColorRemoveAction[path],
                shadowRemoveAction: Actions.shadowRemoveAction[path],
                shadowColorChangeAction: Actions.shadowColorChangeAction[path],
                blurRadiusChangeAction: Actions.blurRadiusChangeAction[path],
                shadowOffsetXChangeAction: Actions.shadowOffsetXChangeAction[path],
                shadowOffsetYChangeAction: Actions.shadowOffsetYChangeAction[path]
            }),
            textNormalStyleId = selectors.textNormalGlobalstyleId(stylesheets),
            currentStylesheetId = selectors.getStyleId(currentStylesheet),
            { autoColorMode } = themeSettingsData,
            renderStyle = ({
                index,
                label,
                subLabel,
                type,
                underline,
                color,
                highlight,
                toggleUnderlineAction,
                textColorChangeAction,
                highlightColorChangeAction,
                highlightColorRemoveAction,
                shadowColorChangeAction,
                shadowRemoveAction,
                blurRadiusChangeAction,
                shadowOffsetXChangeAction,
                shadowOffsetYChangeAction
            }) => (
                <Flex key={index}>
                    <Box>
                        <VerticalSpacer y={28} />
                        <div className={styles.title}>{intl.msgJoint(label)}</div>
                        <VerticalSpacer y={10} />
                        <div className={styles.subtitle}>{intl.msgJoint(subLabel)}</div>
                        <VerticalSpacer y={10} />
                        <GrayBgContainer className={styles.globalStylesLinkGrayBox}>
                            <Flex align="center">
                                <TextShadowButton
                                    dialogId={Actions.textShadowConfigurationDialogId[type]}
                                    currentStylesheetId={currentStylesheetId}
                                    shadowColorChangeAction={shadowColorChangeAction}
                                    shadowRemoveAction={shadowRemoveAction}
                                    blurRadiusChangeAction={blurRadiusChangeAction}
                                    shadowOffsetXChangeAction={shadowOffsetXChangeAction}
                                    shadowOffsetYChangeAction={shadowOffsetYChangeAction}
                                    dispatch={dispatch}
                                />
                                <HorizontalSpacer />
                                <TextFormatButtonGroup
                                    underline={{ isSelected: underline, onClickAction: toggleUnderlineAction }}
                                    dispatch={dispatch}
                                />
                            </Flex>
                            <VerticalSpacer />
                            <Flex>
                                <Flex justify="flex-start">
                                    <ColorProperty
                                        style={{ width: 125 }}
                                        label="msg: common.textColor {Text colour}"
                                        color={color}
                                        onChangeAction={textColorChangeAction}
                                        dispatch={dispatch}
                                    />
                                </Flex>
                                <HorizontalSpacer x={100} />
                                <Flex justify="flex-end">
                                    <ColorProperty
                                        style={{ width: 170 }}
                                        label="msg: common.highlightColor {Highlight colour}"
                                        color={highlight}
                                        onChangeAction={highlightColorChangeAction}
                                        onRemoveAction={highlightColorRemoveAction}
                                        dispatch={dispatch}
                                    />
                                </Flex>
                            </Flex>
                        </GrayBgContainer>
                    </Box>
                    <Box><HorizontalSpacer x={30} /></Box>
                </Flex>
            ),
            renderPreview = (id, label, stylesheetsIdToNameMap, hover?: boolean) => (
                <Flex className={styles.linkPreview} justify="center">
                    <BaseComponentPreview
                        className={styles.linkPreviewPostion}
                        globalStyleIds={[
                            hover
                                ? getGlobalstyleHoverClassName(stylesheetsIdToNameMap[id])
                                : stylesheetsIdToNameMap[id],
                            stylesheetsIdToNameMap[textNormalStyleId]
                        ]}
                    >
                        {intl.msgJoint(label)}
                    </BaseComponentPreview>
                </Flex>
            ),
            renderLinkPreviewForTheme = (id, labels, stylesheetsIdToNameMap) => (
                labels.map(labelObj => {
                    const [isHovered, setIsHovered] = useState(false);
                    return (<Flex className={cx(styles.linkPreview, BACKGROUND_THEME_WHITE)} justify="center">
                        <BaseComponentPreview
                            // @ts-ignore onMouseEnter and onMouseOut not supported
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseOut={() => setIsHovered(false)}
                            className={styles.linkPreviewPostion}
                            globalStyleIds={[
                                stylesheetsIdToNameMap[textNormalStyleId],
                                isHovered ? getGlobalstyleHoverClassName(labelObj.value) : labelObj.value
                            ]}
                        >
                            {intl.msgJoint(labelObj.globalStyleLabel)}
                        </BaseComponentPreview>
                    </Flex>);
                })
            ),
            getNormalLinkPreview = () => ([
                <ComponentMask
                    showPreviewOnlyLayer={false}
                    maskStyle={ComponentMaskBackground.TRANSPARENT}
                >
                    {renderPreview(currentStylesheetId, Labels[inactive], stylesheetsIdToNameMap)}
                </ComponentMask>,
                <VerticalSpacer y={80} />,
                renderPreview(
                    currentStylesheetId,
                    Labels[hover],
                    stylesheetsIdToNameMap,
                    true
                )
            ]),
            getThemeLinkPreview = () => renderLinkPreviewForTheme(currentStylesheetId, ThemeLabelObj[inactive], stylesheetsIdToNameMap);

        return (
            <Box className={styles.tabPanelContents}>
                { (!autoColorMode && <TopBar
                    messages={{
                        select: 'msg: gs.selectToStyle.link {Select a link style to edit}',
                        duplicate: 'msg: gs.duplicateStyle.link {New link style (Duplicate)}',
                        delete: 'msg: gs.deleteStyle.link {Delete this link style}'
                    }}
                    globalStyleSelectorChangeAction={GS_LINK_GLOBAL_STYLE_SELECTED}
                    optionMapper={optionMapper}
                    stylesheets={stylesheets}
                    currentStylesheetId={currentStylesheetId}
                    dispatch={dispatch}
                    intl={intl}
                />) ||
                    <Flex className={cx(styles.borderBottom, styles.infoBar)} align="center" justify="flex-start">
                        <Msg k="common.gs.common.link.overview">
                            This is an overview of your link styles
                        </Msg>
                    </Flex>}
                <Flex className={cx(
                    styles.tabPanel,
                    { [styles.tabPanelWithoutHeader]: autoColorMode },
                    styles.styleSelectorsStylePreview
                )}
                >
                    <Box className={styles.styleSelectors}>
                        { !autoColorMode && <Scrollbar height={`${scrollHeight}px`}>
                            {
                                [inactive, hover].map((type, index) => renderStyle({
                                    ...currentStylesheet[type],
                                    ...getAllActionsForStyle(type),
                                    ...Messages[type],
                                    stylesheetsIdToNameMap,
                                    type,
                                    index,
                                    dispatch
                                }))
                            }
                        </Scrollbar>}
                    </Box>
                    <Box className={cx(styles.stylePreview, styles.borderLeft)}>
                        <div className={styles.previewLabel}>{intl.msgJoint('msg: common.preview.noun {Preview}')}</div>
                        <Box className={styles.preview} align="stretch" justify="flex-start">
                            <Scrollbar height={`${previewScrollHeight}px`}>
                                <VerticalSpacer y={24} />
                                { !autoColorMode ? getNormalLinkPreview() : getThemeLinkPreview() }
                            </Scrollbar>
                        </Box>
                    </Box>
                </Flex>
            </Box>
        );
    });
