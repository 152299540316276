/* eslint-disable max-len */
import React from 'react';
import { useDispatch } from 'react-redux';

import { useIntl } from "../../../view/intl/index";
import { Dialog, DialogTitleBox, DialogBody } from '../../../view/common/dialogs/baseDialog/index';
import { StripTypes } from "../../../view/common/dialogs/baseDialog/Dialog";

import getCenteredDialogConfig from "../../DialogManager/getCenteredDialogConfig";

import styles from './WhatsNewPopupDialog.css';

import { closeDialogByIdAction } from '../../App/actionCreators';
import { Popups } from '../../TopBar/constants';
import { openTopBarPopup } from '../../TopBar/actionCreators';
import { isPartnerCheckDomain, isPartnerHostnet } from '../../ComponentTierManager/utils';
import { PrimaryButton } from '../../../view/common/Button';

const { WhatsNewList } = require('../../../../notifications/whatsNewList');

const WHATS_NEW_POPUP_DIALOG_ID = "WHATS_NEW_POPUP_DIALOG";

const
    width = 700,
    height = 672,
    WhatsNewListMap = WhatsNewList.reduce((acc, item) => ({ ...acc, [item.id]: item }), {}),
    WhatsNewPopupDialog = ({ whatsNewId, showSVG }) => {
        const intl = useIntl();
        const dispatch = useDispatch();

        const onClose = () => {
            dispatch(closeDialogByIdAction(WHATS_NEW_POPUP_DIALOG_ID));
            //should be removed after Tutorial video gets old
            dispatch(openTopBarPopup({ id: Popups.TUTORIAL_VIDEO_INTRO }));
        };
        let learnMoreLink = WhatsNewListMap[whatsNewId].learnMoreLink
            ? intl.msgJoint(WhatsNewListMap[whatsNewId].learnMoreLink)
            : false;

        if (
            (isPartnerCheckDomain() && parseInt(whatsNewId, 10) === 46) ||
            (WhatsNewListMap[whatsNewId].disableLinkForPartners && (isPartnerHostnet() || isPartnerCheckDomain()))
        ) {
            learnMoreLink = false;
        }

        return (
            <Dialog
                onClose={onClose}
                stripType={StripTypes.NONE}
                className={styles.dialog}
                containerClassName={styles.dialogContainer}
                closeBtnClassName={styles.closeButton}
            >
                <DialogTitleBox
                    title="msg: common.whatsNewExclamation {What's new!}"
                    className={styles.dialogTitleBox}
                    titleClass={styles.dialogTitle}
                />

                <DialogBody className={styles.dialogBody}>
                    {
                        showSVG && (
                            <div className={styles.imageWrapper}>
                                <div className={styles['svgContainer' + whatsNewId]} />
                            </div>
                        )
                    }
                    <div className={styles.contentWrapper}>
                        <div className={styles.title}>
                            {intl.msgJoint(`msg: whatsNew.${whatsNewId}.title {${WhatsNewListMap[whatsNewId].title}}`)}
                        </div>
                        <div className={styles.subTitle}>
                            {intl.msgJoint(`msg: whatsNew.${whatsNewId}.description {${WhatsNewListMap[whatsNewId].description}}`) + " "}
                            {learnMoreLink &&
                            <a href={learnMoreLink} target="_blank">
                                {intl.msgJoint('msg: common.learnMore {Learn more}')}
                            </a>}
                        </div>
                    </div>
                    <PrimaryButton className={styles.button} onClick={onClose}>
                        {intl.msgJoint("msg: common.gotIt.exclamation {Got it!}")}
                    </PrimaryButton>
                </DialogBody>
            </Dialog>
        );
    },
    whatsNewPopupDialogConfig = getCenteredDialogConfig({
        width,
        height,
        component: WhatsNewPopupDialog,
        modal: true,
    });

export {
    WHATS_NEW_POPUP_DIALOG_ID,
    WhatsNewPopupDialog,
    whatsNewPopupDialogConfig as default
};
