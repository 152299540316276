// @ts-nocheck
// TODO: flow: WBTGEN-2412

import React from 'react';
import { injectIntl } from 'react-intl';
import { Intl } from "./injectIntl";

// Does not actually render anything visible.
// We need it to provide access to internationalization for classes
// which are not a React component
class CurrentLocale extends React.Component<{ intl: Intl }> { // eslint-disable-line react/no-unused-prop-types
    static instance = null;

    UNSAFE_componentWillMount() {
        if (!CurrentLocale.instance) {
            CurrentLocale.instance = this;
        }
    }

    render() {
        return <div />;
    }
}

export default injectIntl(CurrentLocale);

export function intl() {
    return CurrentLocale.instance ? CurrentLocale.instance.props.intl : null;
}

export function formatMessage(...args: any) {
    const intlObject = intl();
    return intlObject ? intlObject.formatMessage(...args) : '';
}
