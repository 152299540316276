import Preview from "./view/preview";
import kind from "./kind";
import buildLocalStyle from './buildLocalStyle';
import { getLinkUrl } from "../Text/getLinkUrl";
import type { ButtonComponent } from './flowTypes';
import { replaceTagsWithContent } from '../Text/view/replaceTagsWithContent';
import { getFirstButtonStyle } from "../../Workspace/epics/stylesheets/selectors";
import type { CalcProps } from '../../Preview/flowTypes';
import { getGlobalstyleClassNameFromStyle } from "../../RenderGlobalstyles/getGlobalstyleClassName";

export default {
    view: Preview,
    kind,
    calcProps: ({
        component,
        siteMap,
        previewBackupTime,
        globalVariables,
        siteSettings: { themeSettingsData },
        globalStyles,
        selectedParentTheme,
        isBtnInModernLayout,
    }: CalcProps<ButtonComponent>) => {
        const { autoColorMode } = themeSettingsData;
        let linkProps: Record<string, any> = {};
        if (component.linkAction) {
            linkProps = getLinkUrl(component.linkAction, siteMap, previewBackupTime);
            linkProps.href = replaceTagsWithContent(linkProps.href, { site: siteMap, globalVariables });
        }
        const calcPropsResult = {
            text: replaceTagsWithContent(component.text, { globalVariables, site: siteMap }),
            style: buildLocalStyle(component, isBtnInModernLayout || false),
            linkProps,
            autoColorMode,
            selectedParentTheme,
            buttonThemeSelected: component.buttonThemeSelected,
        };
        if (autoColorMode) {
            return {
                ...calcPropsResult,
                globalStyleClass: getGlobalstyleClassNameFromStyle(getFirstButtonStyle(globalStyles))
            };
        } else {
            return calcPropsResult;
        }
    }
};
