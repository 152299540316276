import React from 'react';
import styles from '../FileChooserList.css';
import Resource from "../../../../../redux/modules/children/fileChooser/Resource";
import DirectoryCell from './DirectoryCell';
import ResourceCell from './ResourceCell';
import type { ListBaseCellPropTypes } from "../../flowTypes";
import { LIST_SHORT_NAME_LENGTH } from "../../constants";
import { SortColumns } from "../../../../../redux/modules/children/fileChooser/sortFunctions";
import { getFcTitleAndLabel } from "../../utils";

export default (props: ListBaseCellPropTypes) => {
    const
        resource: Resource = props.resource,
        { label, title } = getFcTitleAndLabel(resource.getBaseName(), LIST_SHORT_NAME_LENGTH);

    const cellProps = { ...props, title, column: SortColumns.NAME, className: styles.nameCell };

    return resource.isDirectory()
        ? <DirectoryCell {...cellProps}>{label}</DirectoryCell>
        // @ts-ignore
        : <ResourceCell {...cellProps}>{label}</ResourceCell>;
};
