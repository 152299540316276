import React from "react";
import Combobox from "../../../view/common/Combobox/index";
import { intlCreateOptions } from "../../../view/common/Combobox/utils";
import PropertyContainer from "../../../view/common/PropertyContainer/index";
import { BorderStyles } from "./constants";
import type { BorderStylePropTypes } from "./flowTypes";
import injectIntl from "../../../view/intl/injectIntl";

const BorderStyleOptions = {
    [BorderStyles.NONE]: "msg: common.borderStyle.option.noborder {No border}",
    [BorderStyles.SOLID]: "msg: common.borderStyle.option.solid {Solid}",
    [BorderStyles.DASHED]: "msg: common.borderStyle.option.dashed {Dashed}",
    [BorderStyles.DOTTED]: "msg: common.borderStyle.option.dotted {Dotted}"
};

export default injectIntl(({ disabled, value, onChangeAction, dispatch, intl }: BorderStylePropTypes) => (
    <PropertyContainer label="msg: common.borderStyle {Border style}">
        <Combobox
            editable={false}
            placeholder={intl.msgJoint("msg: common.borderStyle.option.selecttype {Select...}")}
            disabled={disabled}
            value={value}
            options={intlCreateOptions(BorderStyleOptions, intl)}
            onChange={({ value }) => dispatch({ type: onChangeAction, payload: value })}
            searchable={false}
        />
    </PropertyContainer>
));
