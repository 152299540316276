import CALL_API from '../../../../../redux/middleware/api/CALL_API';
import * as Actions from "../actionTypes";

export default function (data) {
    return {
        [CALL_API]: {
            types: [Actions.GET_CONCEPT_FOR_GMB_KEY_REQUEST,
                Actions.GET_CONCEPT_FOR_GMB_KEY_SUCCESS,
                Actions.GET_CONCEPT_FOR_GMB_KEY_FAILED],
            endpoint: "getConceptForGmbKey",
            endpointParams: data,
        }
    };
}
