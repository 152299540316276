/* eslint-disable max-len */
import React from "react";

export default props => (
    <svg viewBox="0 0 29 29" {...props}>
        <g
            fill="none"
            fillRule="evenodd"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.7}
        >
            <path d="M18.625 10.2a4 4 0 11-8 .001 4 4 0 018-.001z" />
            <path d="M23.825 10.2c0-5.985-4.92-9-9.5-8.95C9.797 1.2 5.3 4.223 5.3 10.2c0 5.979 8.188 16.677 8.426 17.116.238.438.976.438 1.214 0 5.923-7.41 8.885-13.116 8.885-17.116z" />
        </g>
    </svg>
);
