import parseComponent from './parseComponent';
import type { CalcRenderer } from '../../Workspace/epics/componentsProps/flowTypes';
import type {
    FacebookPageComponent,
    FacebookPageComponentExtension,
    FacebookPageComponentDependsOn,
    FacebookPageCalcRenderPropsResult
} from './flowTypes';

type FacebookPageCalcRenderProps = CalcRenderer<FacebookPageComponent, FacebookPageComponentExtension, FacebookPageComponentDependsOn, FacebookPageCalcRenderPropsResult> // eslint-disable-line max-len

const
    calcRenderProps: FacebookPageCalcRenderProps = ({
        component: { height, width, pageURL, tabs, hideCover, showFacepile, showCTA, smallHeader },
        componentDependencies,
        componentExtension: { isTransient }
    }) => ({
        ...componentDependencies,
        isTransient,
        pageURL,
        height,
        width,
        tabs: tabs.join(','),
        hideCover,
        showFacepile,
        showCTA,
        smallHeader,
        onScriptLoaded: parseComponent
    });

export default calcRenderProps;
