/* eslint-disable max-len */
import React from "react";
import * as R from 'ramda';

import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly } from "../../../../epics/makeCondition";

import { WINDOW_RESIZED_DEBOUNCED, CTRL_0_PRESSED } from "../../../App/actionTypes";
import browserDimensionsValueActionType from '../../../App/epics/browserDimensions/valueActionType';

import leftPanelWidthValueActionType from '../../../Panel/epics/width/valueActionType';

import * as ids from '../../ids';
import registry from '../../registry';
import {
    SHOW_STICKY_TOOLTIP,
    STICKY_TOOLTIP_CLIENT_RECT,
    CLOSE_STICKY_TOOLTIP,
    SHOW_CUSTOM_STICKY_TOOLTIP,
    SHOW_TOOLTIP_FOR_AI_WRITING_ASSISTANT
} from "../actionTypes";

import valueActionType from './valueActionType';
import BrowserZoomResetTipContent from '../../../../view/common/browserZoom/BrowserZoomResetTipContent';
import { isBrowserZoomEnabled, isHideBrowserWarning, setHideBrowserWarning } from '../../../../view/common/browserZoom/utils';
import { showTooltipReducer, getNewTooltipState, tooltipClientRectReducer, buildDefaultState,
    TipsCloseOnActions, MandatoryTipsCloseOnActions } from "../../epics/tooltip/index";
import workspaceBBoxValueActionType from '../../../Workspace/epics/workspaceBBox/valueActionType';
import { TopBarHeight } from "../../../TopBar/constants";
import workspaceScrollValueActionType from '../../../Workspace/epics/scroll/valueActionType';
import { ReceiveOnlyComponentsMap } from "../../../Workspace/epics/componentsEval/selectorActionTypes";
import { COMPONENT_CONVERTED_TO_TEMPLATE, COMPONENTS_MOVED_BY_MOUSE, COMPONENTS_MOVED_BY_KEYBOARD,
    COMPONENTS_RESIZED, COMPONENTS_PASTED, COMPONENTS_DUPLICATED } from "../../../Workspace/epics/componentsEval/actionTypes";
import { componentAttachmentsVAT } from "../../../Workspace/epics/componentAttachements/valueActionType";
import { getTopMostParentId } from "../../../Workspace/epics/componentAttachements/util";
import { isHeaderSection, isFooterSection } from '../../../oneweb/Section/utils';
import { setHeaderFooterTooltipShownInLS, getHeaderFooterTooltipShownInLS, hideAITooltipAC, shouldResetState } from '../../utils';
import { NEW_COMPONENT_DROPPED_IS_ADDED_TO_ATTACHMENTS } from "../../../Workspace/epics/componentAttachements/actionTypes";
import { COMPONENTS_DELETED } from "../../../Workspace/actionTypes";
import { isSectionKind } from "../../../oneweb/componentKinds";
import {
    ROCodeComponentsRendererHeadHeightSelector
} from "../../../Workspace/CodeComponentsRenderer/epic/selectorActionTypes";
import * as MHFActionTypes from "../../../ModernLayouts/actionTypes";
import { CLOSE_TOOLTIP } from "../../actionTypes";
import { SHOW_SPELLCHECK_TOOLTIP } from "../../../oneweb/Text/actionTypes";

const
    defaultState = { show: false, isCloseExplicit: false },
    ModernHeaderFooterActions = Object.keys(MHFActionTypes),
    keepTooltipOnScroll = [
        ids.AIWritingAssistantTooltip
    ];

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                SHOW_STICKY_TOOLTIP,
                receiveOnly(leftPanelWidthValueActionType)
            ],
            reducer: showTooltipReducer
        },
        {
            conditions: [
                STICKY_TOOLTIP_CLIENT_RECT,
                receiveOnly(browserDimensionsValueActionType)
            ],
            reducer: tooltipClientRectReducer
        },
        ...[CLOSE_STICKY_TOOLTIP, ...MandatoryTipsCloseOnActions].map(action => ({
            conditions: [action],
            reducer: () => (
                { state: defaultState })
        })),
        ...TipsCloseOnActions.map(action => ({
            conditions: [action],
            reducer: ({ state }) => {
                if (state.isCloseExplicit ||
                    (action === workspaceScrollValueActionType && keepTooltipOnScroll.includes(state.id))
                ) {
                    return { state };
                }
                return { state: defaultState };
            }
        })),
        ...[COMPONENTS_DELETED].map(action => ({
            conditions: [action],
            reducer: ({ values: [cmpIds], state }) => {
                const closeTootlipIfCmpDeleted = state.cmpId && cmpIds && cmpIds.includes(state.cmpId);

                if (shouldResetState(
                    state.isCloseExplicit,
                    closeTootlipIfCmpDeleted,
                    state.closeOnCmpAction
                )) {
                    return { state: defaultState };
                }
                return { state };
            }
        })),
        ...[COMPONENTS_MOVED_BY_MOUSE, COMPONENTS_MOVED_BY_KEYBOARD, COMPONENTS_RESIZED].map(action => ({
            conditions: [ReceiveOnlyComponentsMap, action],
            reducer: ({ values: [componentsMap, { componentsId }], state }) => {
                if (state.isCloseExplicit && componentsId) {
                    if (state.cmpId && componentsId.includes(state.cmpId)) {
                        return { state: defaultState };
                    }
                    if (componentsId.some(cmpId => isSectionKind(componentsMap[cmpId].kind))) {
                        return { state: defaultState };
                    }
                }
                return { state };
            }
        })),
        ...[COMPONENTS_PASTED, COMPONENTS_DUPLICATED].map(action => ({
            conditions: [
                ReceiveOnlyComponentsMap,
                action
            ],
            reducer: ({ values: [componentsMap, { newComponentsIds }], state }) => {
                const closeTootltipIfSection = newComponentsIds.some(cmpId => isSectionKind(componentsMap[cmpId].kind));
                if (shouldResetState(
                    state.isCloseExplicit,
                    closeTootltipIfSection,
                    state.closeOnCmpAction
                )) {
                    return { state: defaultState };
                }
                return { state };
            }
        })),
        {
            conditions: [
                receiveOnly(leftPanelWidthValueActionType),
                WINDOW_RESIZED_DEBOUNCED
            ],
            reducer: ({ values: [leftPanelWidth], state }) => {
                if (isHideBrowserWarning() || !isBrowserZoomEnabled()) {
                    return { state };
                }
                return {
                    state: {
                        ...buildDefaultState(),
                        msg: registry[ids.BrowserZoomWarn],
                        context: { x: 0, y: 220, leftPanelWidth: leftPanelWidth + 20 },
                        textStyle: {},
                        showIcon: true,
                        id: ids.BrowserZoomWarn,
                        tipInfoStyle: { padding: '30px 30px 20px 30px' },
                        pointerStyle: { display: 'none' },
                        crossIconStyle: { position: 'absolute', top: '13px', right: '13px' },
                        onCrossIconClick: () => { setHideBrowserWarning(); },
                        tipIconSize: 'large',
                        showCrossIcon: true,
                        showPointer: false,
                        timeout: 0,
                        customHTML: <BrowserZoomResetTipContent />,
                        customClass: "zoomTooltip"
                    }
                };
            }
        },
        {
            conditions: [
                SHOW_CUSTOM_STICKY_TOOLTIP
            ],
            reducer: ({ values: [{ id, ...rest }] }) => {
                return {
                    state: {
                        ...buildDefaultState(),
                        id: ids[id],
                        msg: registry[id],
                        textStyle: {},
                        context: { x: 0, y: 0 },
                        tipInfoStyle: { padding: '30px 30px 20px 30px' },
                        crossIconStyle: { position: 'absolute', top: '13px', right: '13px' },
                        tipIconSize: 'large',
                        showTipText: false,
                        showIcon: true,
                        showCrossIcon: true,
                        showPointer: true,
                        timeout: 0,
                        ...rest
                    }
                };
            }
        },
        {
            conditions: [
                CTRL_0_PRESSED
            ],
            reducer: ({ state }) => {
                setHideBrowserWarning();
                return {
                    state,
                    actionToDispatch: {
                        type: CLOSE_STICKY_TOOLTIP
                    }
                };
            }
        },
        {
            conditions: [
                workspaceScrollValueActionType,
                receiveOnly(browserDimensionsValueActionType),
            ],
            reducer: ({ values: [scroll, { width, height }], state }) => {
                if (state.show && state.scroll &&
                    (state.isCloseExplicit || keepTooltipOnScroll.includes(state.id))
                ) {
                    return {
                        state: {
                            ...getNewTooltipState(state.tooltipClientRect, { width, height }, {
                                ...state,
                                context: {
                                    ...state.context,
                                    y: state.context.y - scroll.y + state.scroll.y
                                }
                            }),
                            scroll
                        }
                    };
                } else {
                    return { state: defaultState };
                }
            }
        },
        ...[NEW_COMPONENT_DROPPED_IS_ADDED_TO_ATTACHMENTS, COMPONENT_CONVERTED_TO_TEMPLATE,
            COMPONENTS_PASTED, COMPONENTS_DUPLICATED].map(action => ({
            conditions: [
                receiveOnly(workspaceBBoxValueActionType),
                receiveOnly(leftPanelWidthValueActionType),
                receiveOnly(workspaceScrollValueActionType),
                ReceiveOnlyComponentsMap,
                receiveOnly(componentAttachmentsVAT),
                ROCodeComponentsRendererHeadHeightSelector,
                action
            ],
            reducer: ({ values: [
                workspaceBBox,
                leftPanelWidth,
                scroll,
                componentsMap,
                { attachments },
                codeComponentHeadHeight,
                payload,
            ], state, sourceAction: { type: sourceAction } }) => {
                const cmpId = (action === COMPONENTS_PASTED || action === COMPONENTS_DUPLICATED
                    || action === COMPONENT_CONVERTED_TO_TEMPLATE) ?
                    R.last(payload.newComponentsIds) : payload.id;
                if (getHeaderFooterTooltipShownInLS() || !cmpId || ModernHeaderFooterActions.includes(sourceAction)) {
                    return { state };
                }
                const parentSectionId = getTopMostParentId(cmpId, attachments),
                    parentSection = componentsMap[parentSectionId];
                let id, _isHeaderSection;
                if (isHeaderSection(parentSection) && !getHeaderFooterTooltipShownInLS(true)) {
                    id = ids.HeaderSectionTooltip;
                    _isHeaderSection = true;
                } else if (isFooterSection(parentSection) && !getHeaderFooterTooltipShownInLS(false)) {
                    id = ids.FooterSectionTooltip;
                } else {
                    return {
                        state
                    };
                }
                const component = componentsMap[cmpId],
                    { top, height, left, width } = component,
                    x = (Math.abs(workspaceBBox.left) + leftPanelWidth + left + (width / 2) - 2) - scroll.x;
                let y = TopBarHeight + top - scroll.y + codeComponentHeadHeight;
                y = isHeaderSection(parentSection) ? y + height : y;
                return {
                    state: {
                        ...buildDefaultState(),

                        text2Style: { paddingTop: '10px', opacity: 0.8 },
                        text3Style: { paddingTop: '10px', opacity: 0.8 },

                        tipInfoStyle: { padding: '22px 30px' },
                        context: { x, y },
                        timeout: 0,
                        showCrossIcon: true,
                        showIcon: true,
                        showTipText: false,
                        tipIconSize: 'large',
                        onCrossIconClick: () => { setHeaderFooterTooltipShownInLS(_isHeaderSection); },
                        onActionBtnClick: () => { setHeaderFooterTooltipShownInLS(_isHeaderSection); },
                        id,
                        cmpId,
                        msg: registry[id],
                        crossIconStyle: { position: 'absolute', top: '17px', right: '17px', opacity: 0.1 },
                        isCloseExplicit: true,
                        scroll
                    }
                };
            }
        })),
        {
            conditions: [
                receiveOnly(workspaceScrollValueActionType),
                SHOW_TOOLTIP_FOR_AI_WRITING_ASSISTANT
            ],
            reducer: ({ values: [scroll, { position: { x, y }, elementDimensions,
                customClass, imageClass, customTextClass, buttonCustomClass }] }) => {
                const id = ids.AIWritingAssistantTooltip;
                return {
                    state: {
                        ...buildDefaultState(),
                        tipInfoStyle: { padding: '32px 32px' },
                        context: { x, y: y - 8, elementDimensions },
                        headerStyle: {
                            fontSize: '20px',
                            fontWeight: 'normal',
                            color: '#3c3c3c',
                            lineHeight: '24px',
                            letterSpacing: "0.15px",
                            marginBottom: "16px"
                        },
                        timeout: 0,
                        showCrossIcon: true,
                        showIcon: false,
                        showHeadersWithoutTip: true,
                        showImage: true,
                        imageClass,
                        customClass,
                        textStyle: {},
                        customTextClass,
                        useCustomTextStyle: true,
                        buttonCustomClass,
                        id,
                        msg: registry[id],
                        crossIconStyle: { position: 'absolute', top: '20px', right: '20px' },
                        scroll,
                        closeOnCmpAction: true,
                        onActionBtnClick: () => {
                            return hideAITooltipAC();
                        }
                    }
                };
            }
        },
        {
            conditions: [
                receiveOnly(workspaceScrollValueActionType),
                SHOW_SPELLCHECK_TOOLTIP
            ],
            reducer: ({ values: [scroll, { position: { x, y }, elementDimensions,
                customClass, customTextClass, linkTextCustomClass, buttonCustomClass }] }) => {
                const id = ids.SpellcheckTooltip;
                return {
                    state: {
                        ...buildDefaultState(),
                        tipInfoStyle: { padding: '32px 32px' },
                        context: { x, y, elementDimensions },
                        headerStyle: { fontSize: '18px', fontWeight: '500', color: '#3c3c3c', lineHeight: '28px' },
                        timeout: 0,
                        showCrossIcon: true,
                        showIcon: false,
                        showHeadersWithoutTip: true,
                        customClass,
                        textStyle: { lineHeight: '22px' },
                        customTextClass,
                        linkTextCustomClass,
                        buttonCustomClass,
                        useCustomTextStyle: true,
                        id,
                        msg: registry[id],
                        crossIconStyle: { position: 'absolute', top: '20px', right: '20px', opacity: 0.7 },
                        scroll,
                        closeOnCmpAction: true,
                        onActionBtnClick: () => {
                            return { type: CLOSE_TOOLTIP };
                        }
                    }
                };
            }
        },
    ]
});
