import { $Diff } from "utility-types";
import React from 'react';
import type { LinkTarget } from "../../../components/App/flowTypes";
import { LinkAnchorHash, LinkTargetV } from "../../../globalConstants";
import injectIntl, { Intl } from "../injectIntl";
import { IntlLangMap } from "../IntlLangMap";
import type { IntlTagChunks } from "./types";

const defineHref = (href, hrefMap, intl) => {
    if (!href && !hrefMap) {
        return LinkAnchorHash;
    } else if (href && hrefMap) {
        throw new Error('Either href OR hrefMap is supported');
    }

    const
        lang = intl.normalizedLocale,
        locale = IntlLangMap[lang] || lang,
        params = { lang, locale };

    let res;

    if (href) {
        res = Object
            .keys(params)
            .reduce(
                (acc, k) => acc.replace(`{${k}}`, params[k]),
                href,
            );
    } else if (hrefMap) {
        res = hrefMap[lang] || hrefMap[locale];
    }

    return res;
};

const defineOnClick = (onClick, href) => {
    if (onClick && href && href === LinkAnchorHash) {
        return e => {
            e.nativeEvent.preventDefault();
            onClick(e);
        };
    }

    return onClick;
};

type Props = {
    children: IntlTagChunks;
    intl: Intl,
    href?: string,
    hrefMap?: Record<string, any>,
    target?: LinkTarget,
    className?: string,
    onClick?: React.MouseEventHandler,
}

const LinkIntlTag = injectIntl((props: Props) => {
    const
        { children, href: propHref, target = LinkTargetV.BLANK, className } = props,
        href = defineHref(propHref, props.hrefMap, props.intl),
        onClick = defineOnClick(props.onClick, propHref);

    return (
        <a href={href} className={className} target={target} onClick={onClick}>{children}</a>
    );
});

export type MakePropsLinkIntlTagProps = $Diff<Props, { children: string | string[] }>;

export const makeLinkIntlTag = (props: Omit<MakePropsLinkIntlTagProps, 'intl'>) => (...chunks: string[]) => (
    <LinkIntlTag {...props}>{chunks}</LinkIntlTag>
);
