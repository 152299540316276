import type { TinyMceEditor } from "../../../flowTypes";
import { makeExistingParentStyleSelector } from "../../utils/selectors/makeExistingParentStyleSelector";
import { makeComputedParentStyleSelector } from "../../utils/selectors/makeComputedParentStyleSelector";
import { getSelectedNodesProperties } from "../../utils/nodeUtils/getSelectedNodesProperties";
import { getSelectedNodeProperty } from "../../utils/nodeUtils/getSelectedNodeProperty";

export const getSelectedCharacterSpacing = (editor: TinyMceEditor) => {
    let
        selector = makeExistingParentStyleSelector(editor, 'letter-spacing'),
        letterSpacings = getSelectedNodesProperties(editor, selector);

    if (!letterSpacings.length) {
        const letterSpacing = getSelectedNodeProperty(editor, makeComputedParentStyleSelector('letter-spacing'));
        const fontSize = getSelectedNodeProperty(editor, makeComputedParentStyleSelector('font-size'));
        letterSpacings = [(parseFloat(letterSpacing) / parseFloat(fontSize)).toFixed(1)];
    }

    if (!letterSpacings.length) {
        return 0;
    }

    return Math.min(...letterSpacings
        .map(
            // letterSpacing is 10x the CSS letter-spacing value
            letterSpacing => parseFloat(letterSpacing === 'normal' ? '0' : letterSpacing) * 10
        ));
};
