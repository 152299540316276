import {
    MOUSE_DOWN_ON_PROPERTIES_PANEL_DRAGGABLE_AREA,
    MOUSE_UP_ON_PROPERTIES_PANEL_DRAGGABLE_AREA
} from "../../../../PropertiesPanel/epic/actionTypes";
import {
    ReceiveOnlyComponentsMap,
    ROEditModeComponentIdSelector
} from "../../../../Workspace/epics/componentsEval/selectorActionTypes";
import type { TinyMceEpicUpdater } from "../flowTypes";
import { queryEditor } from "./helpers/queryEditor";

const
    onMouseDownOnPropPanelDraggableAreaUpdater: TinyMceEpicUpdater = {
        conditions: [
            ReceiveOnlyComponentsMap,
            ROEditModeComponentIdSelector,
            MOUSE_DOWN_ON_PROPERTIES_PANEL_DRAGGABLE_AREA
        ],
        reducer: ({ state, scope }) => {
            if (state.tinyMceExist) {
                queryEditor(editor => {
                    const editorBody = editor.getBody();

                    // Set webkitUserSelect because it's only necessary for Safari
                    // $FlowFixMe: WBTGEN-9962: webkitUserSelect is missing in CSSStyleDeclaration, put checks
                    editorBody.style.webkitUserSelect = 'none';
                });
            }

            return { state, scope };
        }
    },
    onMouseUpOnPropPanelDraggableAreaUpdater: TinyMceEpicUpdater = {
        conditions: [
            ReceiveOnlyComponentsMap,
            ROEditModeComponentIdSelector,
            MOUSE_UP_ON_PROPERTIES_PANEL_DRAGGABLE_AREA
        ],
        reducer: ({ state, scope }) => {
            if (state.tinyMceExist) {
                queryEditor(editor => {
                    const editorBody = editor.getBody();

                    // Set webkitUserSelect because it's only necessary for Safari
                    // $FlowFixMe: WBTGEN-9962: webkitUserSelect is missing in CSSStyleDeclaration, put checks
                    editorBody.style.webkitUserSelect = 'text';
                });
            }

            return { state, scope };
        }
    };

export {
    onMouseDownOnPropPanelDraggableAreaUpdater,
    onMouseUpOnPropPanelDraggableAreaUpdater
};
