import * as Actions from './actionTypes';
import CALL_API from "../../redux/middleware/api/CALL_API";
import type { ApiAction } from "../../redux/middleware/api/flowTypes";
import type { ThemeColorDataType } from "./flowTypes";

const
    enableThemingRequest = (themeColors: ThemeColorDataType, templateId: string): ApiAction => {
        return {
            [CALL_API]: {
                types: [
                    Actions.ENABLE_COLOR_THEME_REQUEST,
                    Actions.ENABLE_COLOR_THEME_SUCCESS,
                    Actions.ENABLE_COLOR_THEME_FAILURE
                ],
                endpoint: "enableThemingRequest",
                endpointParams: { themeColors, templateId }
            }
        };
    },
    dynamicOnboardingEnableThemingRequest =
        (themeColors: ThemeColorDataType, templateId: string, returnSiteSettings: Boolean): ApiAction => {
            return {
                [CALL_API]: {
                    types: [
                        Actions.DYNAMIC_ONBOARDING_ENABLE_COLOR_THEME_REQUEST,
                        Actions.DYNAMIC_ONBOARDING_ENABLE_COLOR_THEME_SUCCESS,
                        Actions.DYNAMIC_ONBOARDING_ENABLE_COLOR_THEME_FAILURE
                    ],
                    endpoint: "enableThemingRequest",
                    endpointParams: { themeColors, templateId, returnSiteSettings }
                }
            };
        },
    computeThemeColorsForOneTemplate = (templateId: string): ApiAction => {
        return {
            [CALL_API]: {
                types: [
                    Actions.COMPUTE_COLOR_THEME_REQUEST,
                    Actions.COMPUTE_COLOR_THEME_SUCCESS,
                    Actions.COMPUTE_COLOR_THEME_FAILURE
                ],
                endpoint: "computeThemeColorsForOneTemplate",
                endpointParams: templateId
            }
        };
    },
    computeThemeDataForTemplateSelector = (designTemplateId: string, designName: string): ApiAction => {
        return {
            [CALL_API]: {
                types: [
                    Actions.COMPUTE_COLOR_THEME_TEMPLATE_SELECTOR_REQUEST,
                    Actions.COMPUTE_COLOR_THEME_TEMPLATE_SELECTOR_SUCCESS,
                    Actions.COMPUTE_COLOR_THEME_TEMPLATE_SELECTOR_FAILURE
                ],
                endpoint: "computeThemeDataForTemplateSelector",
                endpointParams: { designTemplateId, designName }
            }
        };
    },
    showWaitingScreenAndInitializeComputeThemeColors = () => ({ type: Actions.SHOW_WAITING_SCREEN_AND_INIT_COMPUTE }),
    autoColorChangeAction = ({
        currentAutoColorMode,
        themeActivatedWorkspaceState
    }: { currentAutoColorMode: boolean, themeActivatedWorkspaceState?: string }) =>
        ({
            type: Actions.AUTO_COLOR_MODE_CHANGE,
            payload: { autoColorMode: !currentAutoColorMode, themeActivatedWorkspaceState }
        }),
    startOnboardingOfExistingCustomers = () => ({ type: Actions.START_ONBOARDING_OF_EXISTING_CUSTOMERS });

export {
    enableThemingRequest,
    dynamicOnboardingEnableThemingRequest,
    computeThemeColorsForOneTemplate,
    computeThemeDataForTemplateSelector,
    showWaitingScreenAndInitializeComputeThemeColors,
    startOnboardingOfExistingCustomers,
    autoColorChangeAction,
};
