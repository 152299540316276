import * as R from 'ramda';
import { Types } from './constants';
import * as path from '../../../mappers/path';
import * as actionTypes from './actionTypes';
import type { MenuStylesheet } from '../../Workspace/epics/stylesheets/flowTypes';

const
    defaultCorners = R.repeat(0, 4),
    getExpandableShowDropdownArrow = R.path([path.expandable, path.showDropdownArrow]),
    getDataForNormalStyle = style => ({
        underline: R.path([path.item, path.inactive, path.text, path.underline], style),
        textColor: R.path([path.item, path.inactive, path.text, path.color], style),
        corners: R.path([path.item, path.inactive, path.block, path.border, path.corners], style) || defaultCorners,
        textShadow: R.path([path.item, path.inactive, path.text, path.shadow], style)
    }),
    getDataForMouseOverStyle = style => ({
        underline: R.path([path.item, path.hover, path.text, path.underline], style),
        textColor: R.path([path.item, path.hover, path.text, path.color], style),
        corners: R.path([path.item, path.hover, path.block, path.border, path.corners], style) || defaultCorners,
        textShadow: R.path([path.item, path.hover, path.text, path.shadow], style)
    }),
    getDataForSelectedStyle = style => ({
        underline: R.path([path.selected, path.inactive, path.text, path.underline], style),
        textColor: R.path([path.selected, path.inactive, path.text, path.color], style),
        corners: R.path([path.selected, path.inactive, path.block, path.border, path.corners], style) || defaultCorners,
        textShadow: R.path([path.selected, path.inactive, path.text, path.shadow], style)
    }),
    getDataForExpandableStyle = style => ({
        underline: R.path([path.expandable, path.inactive, path.text, path.underline], style),
        textColor: R.path([path.expandable, path.inactive, path.text, path.color], style),
        textShadow: R.path([path.expandable, path.inactive, path.text, path.shadow], style),
        spacing: R.path([path.expandable, path.inactive, path.block, path.padding], style),
        showDropdownArrow: getExpandableShowDropdownArrow(style) || false
    }),
    getDataForExpandedStyle = style => ({
        underline: R.path([path.expanded, path.inactive, path.text, path.underline], style),
        textColor: R.path([path.expanded, path.inactive, path.text, path.color], style),
        textShadow: R.path([path.expanded, path.inactive, path.text, path.shadow], style),
        showDropdownArrow: getExpandableShowDropdownArrow(style) || false
    }),
    getDataForDividerStyle = style => ({
        border: R.path([path.divider, path.border, path.border], style),
        spacing: R.path([path.divider, path.padding], style),
        corners: R.path([path.divider, path.border, path.corners], style)
    }),
    getDataForLevelContainerStyle = style => ({
        border: R.path([path.block, path.border, path.style], style),
        spacing: R.path([path.block, path.padding], style),
        corners: R.path([path.block, path.border, path.corners], style)
    });

const
    createActionsObject = (actions, repl) =>
        actions.reduce(
            (actionsObject, action) => ({ ...actionsObject, [action.replace(repl, '')]: action }), {}
        ),
    actionsForNormalStyle = createActionsObject([
        actionTypes.GS_MENU_NORMAL_TOGGLE_UNDERLINE,

        actionTypes.GS_MENU_NORMAL_TEXT_COLOR_CHANGED,
        actionTypes.GS_MENU_NORMAL_TEXT_COLOR_OPACITY_CHANGED,
        actionTypes.GS_MENU_NORMAL_TEXT_COLOR_REMOVED,

        actionTypes.GS_MENU_NORMAL_TEXT_SHADOW_COLOR_CHANGED,
        actionTypes.GS_MENU_NORMAL_TEXT_SHADOW_COLOR_OPACITY_CHANGED,
        actionTypes.GS_MENU_NORMAL_TEXT_SHADOW_COLOR_REMOVED,
        actionTypes.GS_MENU_NORMAL_TEXT_SHADOW_BLUR_RADIUS_CHANGED,
        actionTypes.GS_MENU_NORMAL_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED,
        actionTypes.GS_MENU_NORMAL_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED,

        actionTypes.GS_MENU_NORMAL_BG_COLOR_CHANGED,
        actionTypes.GS_MENU_NORMAL_BG_COLOR_OPACITY_CHANGED,
        actionTypes.GS_MENU_NORMAL_BG_COLOR_REMOVED,

        actionTypes.GS_MENU_NORMAL_BG_GRADIENT_CHANGED,
        actionTypes.GS_MENU_NORMAL_BG_GRADIENT_OPACITY_CHANGED,
        actionTypes.GS_MENU_NORMAL_BG_GRADIENT_CANCELLED,
        actionTypes.GS_MENU_NORMAL_BG_GRADIENT_DIRECTION_CHANGED,
        actionTypes.GS_MENU_NORMAL_BG_GRADIENT_FADEPOINT_CHANGED,

        actionTypes.GS_MENU_NORMAL_BG_ASSET_CHANGED,
        actionTypes.GS_MENU_NORMAL_BG_ASSET_REMOVED,
        actionTypes.GS_MENU_NORMAL_BG_ASSET_POSITION_CHANGED,
        actionTypes.GS_MENU_NORMAL_BG_ASSET_REPEAT_CHANGED,
        actionTypes.GS_MENU_NORMAL_BG_ASSET_SIZE_CHANGED,

        actionTypes.GS_MENU_NORMAL_BORDER_RADUIS_CHANGED
    ], 'GS_MENU_NORMAL_'),
    actionsForMouseOverStyle = createActionsObject([
        actionTypes.GS_MENU_MOUSE_OVER_TOGGLE_UNDERLINE,

        actionTypes.GS_MENU_MOUSE_OVER_TEXT_COLOR_CHANGED,
        actionTypes.GS_MENU_MOUSE_OVER_TEXT_COLOR_OPACITY_CHANGED,
        actionTypes.GS_MENU_MOUSE_OVER_TEXT_COLOR_REMOVED,

        actionTypes.GS_MENU_MOUSE_OVER_TEXT_SHADOW_COLOR_CHANGED,
        actionTypes.GS_MENU_MOUSE_OVER_TEXT_SHADOW_COLOR_OPACITY_CHANGED,
        actionTypes.GS_MENU_MOUSE_OVER_TEXT_SHADOW_COLOR_REMOVED,
        actionTypes.GS_MENU_MOUSE_OVER_TEXT_SHADOW_BLUR_RADIUS_CHANGED,
        actionTypes.GS_MENU_MOUSE_OVER_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED,
        actionTypes.GS_MENU_MOUSE_OVER_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED,

        actionTypes.GS_MENU_MOUSE_OVER_BG_COLOR_CHANGED,
        actionTypes.GS_MENU_MOUSE_OVER_BG_COLOR_OPACITY_CHANGED,
        actionTypes.GS_MENU_MOUSE_OVER_BG_COLOR_REMOVED,

        actionTypes.GS_MENU_MOUSE_OVER_BG_GRADIENT_CHANGED,
        actionTypes.GS_MENU_MOUSE_OVER_BG_GRADIENT_OPACITY_CHANGED,
        actionTypes.GS_MENU_MOUSE_OVER_BG_GRADIENT_CANCELLED,
        actionTypes.GS_MENU_MOUSE_OVER_BG_GRADIENT_DIRECTION_CHANGED,
        actionTypes.GS_MENU_MOUSE_OVER_BG_GRADIENT_FADEPOINT_CHANGED,

        actionTypes.GS_MENU_MOUSE_OVER_BG_ASSET_CHANGED,
        actionTypes.GS_MENU_MOUSE_OVER_BG_ASSET_REMOVED,
        actionTypes.GS_MENU_MOUSE_OVER_BG_ASSET_POSITION_CHANGED,
        actionTypes.GS_MENU_MOUSE_OVER_BG_ASSET_REPEAT_CHANGED,
        actionTypes.GS_MENU_MOUSE_OVER_BG_ASSET_SIZE_CHANGED,

        actionTypes.GS_MENU_MOUSE_OVER_BORDER_RADUIS_CHANGED
    ], 'GS_MENU_MOUSE_OVER_'),
    actionsForSelectedStyle = createActionsObject([
        actionTypes.GS_MENU_SELECTED_TOGGLE_UNDERLINE,
        actionTypes.GS_MENU_SELECTED_TEXT_COLOR_CHANGED,
        actionTypes.GS_MENU_SELECTED_TEXT_COLOR_OPACITY_CHANGED,
        actionTypes.GS_MENU_SELECTED_TEXT_COLOR_REMOVED,

        actionTypes.GS_MENU_SELECTED_TEXT_SHADOW_COLOR_CHANGED,
        actionTypes.GS_MENU_SELECTED_TEXT_SHADOW_COLOR_OPACITY_CHANGED,
        actionTypes.GS_MENU_SELECTED_TEXT_SHADOW_COLOR_REMOVED,
        actionTypes.GS_MENU_SELECTED_TEXT_SHADOW_BLUR_RADIUS_CHANGED,
        actionTypes.GS_MENU_SELECTED_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED,
        actionTypes.GS_MENU_SELECTED_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED,

        actionTypes.GS_MENU_SELECTED_BG_COLOR_CHANGED,
        actionTypes.GS_MENU_SELECTED_BG_COLOR_OPACITY_CHANGED,
        actionTypes.GS_MENU_SELECTED_BG_COLOR_REMOVED,

        actionTypes.GS_MENU_SELECTED_BG_GRADIENT_CHANGED,
        actionTypes.GS_MENU_SELECTED_BG_GRADIENT_OPACITY_CHANGED,
        actionTypes.GS_MENU_SELECTED_BG_GRADIENT_CANCELLED,
        actionTypes.GS_MENU_SELECTED_BG_GRADIENT_DIRECTION_CHANGED,
        actionTypes.GS_MENU_SELECTED_BG_GRADIENT_FADEPOINT_CHANGED,

        actionTypes.GS_MENU_SELECTED_BG_ASSET_CHANGED,
        actionTypes.GS_MENU_SELECTED_BG_ASSET_REMOVED,
        actionTypes.GS_MENU_SELECTED_BG_ASSET_POSITION_CHANGED,
        actionTypes.GS_MENU_SELECTED_BG_ASSET_REPEAT_CHANGED,
        actionTypes.GS_MENU_SELECTED_BG_ASSET_SIZE_CHANGED,

        actionTypes.GS_MENU_SELECTED_BORDER_RADUIS_CHANGED
    ], 'GS_MENU_SELECTED_'),
    actionsForExpandableStyle = createActionsObject([
        actionTypes.GS_MENU_EXPANDABLE_TOGGLE_UNDERLINE,
        actionTypes.GS_MENU_EXPANDABLE_TEXT_COLOR_CHANGED,
        actionTypes.GS_MENU_EXPANDABLE_TEXT_COLOR_OPACITY_CHANGED,
        actionTypes.GS_MENU_EXPANDABLE_TEXT_COLOR_REMOVED,

        actionTypes.GS_MENU_EXPANDABLE_TEXT_SHADOW_COLOR_CHANGED,
        actionTypes.GS_MENU_EXPANDABLE_TEXT_SHADOW_COLOR_OPACITY_CHANGED,
        actionTypes.GS_MENU_EXPANDABLE_TEXT_SHADOW_COLOR_REMOVED,
        actionTypes.GS_MENU_EXPANDABLE_TEXT_SHADOW_BLUR_RADIUS_CHANGED,
        actionTypes.GS_MENU_EXPANDABLE_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED,
        actionTypes.GS_MENU_EXPANDABLE_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED,

        actionTypes.GS_MENU_EXPANDABLE_BG_COLOR_CHANGED,
        actionTypes.GS_MENU_EXPANDABLE_BG_COLOR_OPACITY_CHANGED,
        actionTypes.GS_MENU_EXPANDABLE_BG_COLOR_REMOVED,

        actionTypes.GS_MENU_EXPANDABLE_BG_GRADIENT_CHANGED,
        actionTypes.GS_MENU_EXPANDABLE_BG_GRADIENT_OPACITY_CHANGED,
        actionTypes.GS_MENU_EXPANDABLE_BG_GRADIENT_CANCELLED,
        actionTypes.GS_MENU_EXPANDABLE_BG_GRADIENT_DIRECTION_CHANGED,
        actionTypes.GS_MENU_EXPANDABLE_BG_GRADIENT_FADEPOINT_CHANGED,

        actionTypes.GS_MENU_EXPANDABLE_BG_ASSET_CHANGED,
        actionTypes.GS_MENU_EXPANDABLE_BG_ASSET_REMOVED,
        actionTypes.GS_MENU_EXPANDABLE_BG_ASSET_POSITION_CHANGED,
        actionTypes.GS_MENU_EXPANDABLE_BG_ASSET_REPEAT_CHANGED,
        actionTypes.GS_MENU_EXPANDABLE_BG_ASSET_SIZE_CHANGED,

        actionTypes.GS_MENU_EXPANDABLE_PADDING_CHANGED
    ], 'GS_MENU_EXPANDABLE_'),
    actionsForExpandedStyle = createActionsObject([
        actionTypes.GS_MENU_EXPANDED_TOGGLE_UNDERLINE,
        actionTypes.GS_MENU_EXPANDED_TEXT_COLOR_CHANGED,
        actionTypes.GS_MENU_EXPANDED_TEXT_COLOR_OPACITY_CHANGED,
        actionTypes.GS_MENU_EXPANDED_TEXT_COLOR_REMOVED,

        actionTypes.GS_MENU_EXPANDED_TEXT_SHADOW_COLOR_CHANGED,
        actionTypes.GS_MENU_EXPANDED_TEXT_SHADOW_COLOR_OPACITY_CHANGED,
        actionTypes.GS_MENU_EXPANDED_TEXT_SHADOW_COLOR_REMOVED,
        actionTypes.GS_MENU_EXPANDED_TEXT_SHADOW_BLUR_RADIUS_CHANGED,
        actionTypes.GS_MENU_EXPANDED_TEXT_SHADOW_HORIZONTAL_OFFSET_CHANGED,
        actionTypes.GS_MENU_EXPANDED_TEXT_SHADOW_VERTICAL_OFFSET_CHANGED,

        actionTypes.GS_MENU_EXPANDED_BG_COLOR_CHANGED,
        actionTypes.GS_MENU_EXPANDED_BG_COLOR_OPACITY_CHANGED,
        actionTypes.GS_MENU_EXPANDED_BG_COLOR_REMOVED,

        actionTypes.GS_MENU_EXPANDED_BG_GRADIENT_CHANGED,
        actionTypes.GS_MENU_EXPANDED_BG_GRADIENT_OPACITY_CHANGED,
        actionTypes.GS_MENU_EXPANDED_BG_GRADIENT_CANCELLED,
        actionTypes.GS_MENU_EXPANDED_BG_GRADIENT_DIRECTION_CHANGED,
        actionTypes.GS_MENU_EXPANDED_BG_GRADIENT_FADEPOINT_CHANGED,

        actionTypes.GS_MENU_EXPANDED_BG_ASSET_CHANGED,
        actionTypes.GS_MENU_EXPANDED_BG_ASSET_REMOVED,
        actionTypes.GS_MENU_EXPANDED_BG_ASSET_POSITION_CHANGED,
        actionTypes.GS_MENU_EXPANDED_BG_ASSET_REPEAT_CHANGED,
        actionTypes.GS_MENU_EXPANDED_BG_ASSET_SIZE_CHANGED
    ], 'GS_MENU_EXPANDED_'),
    actionsForDividerStyle = createActionsObject([
        actionTypes.GS_MENU_DIVIDER_BORDER_COLOR_CHANGED,
        actionTypes.GS_MENU_DIVIDER_BORDER_COLOR_OPACITY_CHANGED,
        actionTypes.GS_MENU_DIVIDER_BORDER_COLOR_REMOVED,
        actionTypes.GS_MENU_DIVIDER_BORDER_STYLE_CHANGED,
        actionTypes.GS_MENU_DIVIDER_BORDER_WIDTH_CHANGED,

        actionTypes.GS_MENU_DIVIDER_PADDING_CHANGED,

        actionTypes.GS_MENU_DIVIDER_BG_COLOR_CHANGED,
        actionTypes.GS_MENU_DIVIDER_BG_COLOR_OPACITY_CHANGED,
        actionTypes.GS_MENU_DIVIDER_BG_COLOR_REMOVED,

        actionTypes.GS_MENU_DIVIDER_BG_GRADIENT_CHANGED,
        actionTypes.GS_MENU_DIVIDER_BG_GRADIENT_OPACITY_CHANGED,
        actionTypes.GS_MENU_DIVIDER_BG_GRADIENT_CANCELLED,
        actionTypes.GS_MENU_DIVIDER_BG_GRADIENT_DIRECTION_CHANGED,
        actionTypes.GS_MENU_DIVIDER_BG_GRADIENT_FADEPOINT_CHANGED,

        actionTypes.GS_MENU_DIVIDER_BG_ASSET_CHANGED,
        actionTypes.GS_MENU_DIVIDER_BG_ASSET_REMOVED,
        actionTypes.GS_MENU_DIVIDER_BG_ASSET_POSITION_CHANGED,
        actionTypes.GS_MENU_DIVIDER_BG_ASSET_REPEAT_CHANGED,
        actionTypes.GS_MENU_DIVIDER_BG_ASSET_SIZE_CHANGED,

        actionTypes.GS_MENU_DIVIDER_BORDER_RADUIS_CHANGED
    ], 'GS_MENU_DIVIDER_'),
    actionsForLevelContainerStyle = createActionsObject([
        actionTypes.GS_MENU_LEVEL_CONTAINER_BORDER_COLOR_CHANGED,
        actionTypes.GS_MENU_LEVEL_CONTAINER_BORDER_COLOR_OPACITY_CHANGED,
        actionTypes.GS_MENU_LEVEL_CONTAINER_BORDER_COLOR_REMOVED,
        actionTypes.GS_MENU_LEVEL_CONTAINER_BORDER_STYLE_CHANGED,
        actionTypes.GS_MENU_LEVEL_CONTAINER_BORDER_WIDTH_CHANGED,

        actionTypes.GS_MENU_LEVEL_CONTAINER_PADDING_CHANGED,

        actionTypes.GS_MENU_LEVEL_CONTAINER_BG_COLOR_CHANGED,
        actionTypes.GS_MENU_LEVEL_CONTAINER_BG_COLOR_OPACITY_CHANGED,
        actionTypes.GS_MENU_LEVEL_CONTAINER_BG_COLOR_REMOVED,

        actionTypes.GS_MENU_LEVEL_CONTAINER_BG_GRADIENT_CHANGED,
        actionTypes.GS_MENU_LEVEL_CONTAINER_BG_GRADIENT_OPACITY_CHANGED,
        actionTypes.GS_MENU_LEVEL_CONTAINER_BG_GRADIENT_CANCELLED,
        actionTypes.GS_MENU_LEVEL_CONTAINER_BG_GRADIENT_DIRECTION_CHANGED,
        actionTypes.GS_MENU_LEVEL_CONTAINER_BG_GRADIENT_FADEPOINT_CHANGED,

        actionTypes.GS_MENU_LEVEL_CONTAINER_BG_ASSET_CHANGED,
        actionTypes.GS_MENU_LEVEL_CONTAINER_BG_ASSET_REMOVED,
        actionTypes.GS_MENU_LEVEL_CONTAINER_BG_ASSET_POSITION_CHANGED,
        actionTypes.GS_MENU_LEVEL_CONTAINER_BG_ASSET_REPEAT_CHANGED,
        actionTypes.GS_MENU_LEVEL_CONTAINER_BG_ASSET_SIZE_CHANGED,

        actionTypes.GS_MENU_LEVEL_CONTAINER_BORDER_RADUIS_CHANGED
    ], 'GS_MENU_LEVEL_CONTAINER_');

export const
    getDataForStyle = (style: MenuStylesheet, type: string): Record<string, any> => {
        switch (type) {
            case Types.NORMAL: return getDataForNormalStyle(style);
            case Types.MOUSE_OVER: return getDataForMouseOverStyle(style);
            case Types.SELECTED: return getDataForSelectedStyle(style);
            case Types.EXPANDABLE: return getDataForExpandableStyle(style);
            case Types.EXPANDED: return getDataForExpandedStyle(style);
            case Types.DIVIDER: return getDataForDividerStyle(style);
            case Types.LEVEL_CONTAINER: return getDataForLevelContainerStyle(style);
            default: return {};
        }
    },
    getActionForStyle = (type: string): Record<string, any> => {
        switch (type) {
            case Types.NORMAL: return actionsForNormalStyle;
            case Types.MOUSE_OVER: return actionsForMouseOverStyle;
            case Types.SELECTED: return actionsForSelectedStyle;
            case Types.EXPANDABLE: return actionsForExpandableStyle;
            case Types.EXPANDED: return actionsForExpandedStyle;
            case Types.DIVIDER: return actionsForDividerStyle;
            case Types.LEVEL_CONTAINER: return actionsForLevelContainerStyle;
            default: return {};
        }
    };
