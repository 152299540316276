import { applyMappers, makePlainMappers } from "../../utils";
import type { CodeComponent } from "../../../../src/components/oneweb/Code/flowTypes";
import type { OldCodeData } from './flowTypes';

const plainPropsMapper = makePlainMappers({
    name: 'name',
    location: 'location',
    code: 'code',
    mobileHide: 'mobileHide',
    mobileDown: 'mobileDown'
});

export function to(componentData: OldCodeData): CodeComponent {
    return applyMappers(
        componentData,
        plainPropsMapper.to
    );
}

export function back(componentData: CodeComponent): OldCodeData {
    return applyMappers(
        componentData,
        plainPropsMapper.back
    );
}
