/* eslint-disable max-len */
import React from 'react';

import { useIntl } from '../../../../../../../view/intl';

import styles from './styles.css';

type Props= {
    hasWrappedElements: boolean,
    isInserted: boolean,
    onInsertAction: Function,
    isCopied: boolean,
    onCopyAction: Function
}

export const ActionButtons = ({ hasWrappedElements, isInserted, onInsertAction, isCopied, onCopyAction }: Props) => {
    const intl = useIntl();

    return (
        <div className={styles.actionButtonsContainer} data-testid="ai-use-text-buttons">
            <button
                type="button"
                className={styles.actionButton}
                onClick={() => { onCopyAction(); }}
            >
                <span className={styles.actionButtonText}>
                    {
                        isCopied ?
                            intl.msgJoint('msg: common.copiedLabel {Copied}') :
                            intl.msgJoint('msg: common.copyTextLabel {Copy text}')
                    }
                </span>
                <span className={styles.copyIcon} />
            </button>
            <button
                type="button"
                className={styles.actionButton}
                onClick={() => { onInsertAction(); }}
                disabled={hasWrappedElements || isInserted}
                data-title={
                    hasWrappedElements ?
                        intl.msgJoint("msg: component.text.writingAssistant.useText.disable {Cannot insert the text because another component is wrapped inside the text field. Copy text instead.}") :
                        null
                }
                data-title-position="top"
                data-title-class={styles.useTextTooltip}
            >
                <span className={styles.actionButtonText}>
                    {
                        isInserted ?
                            intl.msgJoint('msg: common.insertedLabel {Inserted}') :
                            intl.msgJoint('msg: common.useTextLabel {Use text}')
                    }
                </span>
                <span className={styles.insertIcon} />
            </button>
        </div>
    );
};
