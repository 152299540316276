import { setComponentsMap } from "../../Workspace/epics/componentsEval/setters";
import { SEPARATE_FOOTER_BY_FOOTER_TMP_CMPS } from "./actions";
import { getCmps } from "../../Workspace/epics/componentAttachements/util";
import { getFooterSection, createSection } from "../../oneweb/Section/utils";
import { deselectAllComponents } from "../../Workspace/epics/componentsEval/selectedComponentsIds";
import { isSectionKind } from "../../oneweb/componentKinds";

export default {
    conditions: [
        SEPARATE_FOOTER_BY_FOOTER_TMP_CMPS
    ],
    reducer: ({ state: epicState }) => {
        let { state: { componentsMap } } = epicState,
            newComponentsMap = { ...componentsMap },
            footer = getFooterSection(newComponentsMap),
            cmps = getCmps(newComponentsMap),
            footerTmpCmps = cmps.filter(cmp => cmp.id !== footer.id && cmp.inTemplate && (cmp.top + (cmp.height / 2)) >= footer.top)
                .sort((a, b) => a.top - b.top),
            lastPageCmpBottom = footer.top,
            lastOrderIndex = 0;
        if (footerTmpCmps.length) {
            cmps.forEach(cmp => {
                if (isSectionKind(cmp.kind)) {
                    lastOrderIndex = Math.max(lastOrderIndex, cmp.orderIndex);
                }
                if (cmp.top > footer.top && !cmp.inTemplate) {
                    lastPageCmpBottom = Math.max(cmp.top + cmp.height, lastPageCmpBottom);
                }
            });
            if (lastPageCmpBottom) {
                const newPageSection = createSection({
                    top: footer.top,
                    bottom: lastPageCmpBottom,
                    inTemplate: false,
                    orderIndex: lastOrderIndex + 1
                });
                newComponentsMap[newPageSection.id] = newPageSection;
                let diff = 0, lastTmpBottom = 0;
                if (footerTmpCmps[0].top < footer.top) {
                    diff = footer.top - footerTmpCmps[0].top;
                }
                diff += lastPageCmpBottom - footer.top;
                footerTmpCmps.forEach(cmp => {
                    newComponentsMap[cmp.id] = {
                        ...cmp,
                        top: cmp.top + diff
                    };
                    lastTmpBottom = Math.max(lastTmpBottom, newComponentsMap[cmp.id].top + cmp.height);
                });
                newComponentsMap[footer.id] = {
                    ...footer,
                    top: lastPageCmpBottom,
                    height: lastTmpBottom - lastPageCmpBottom,
                    relTo: {
                        id: newPageSection.id,
                        below: 0
                    }
                };
            }
        }
        return {
            state: deselectAllComponents(setComponentsMap(newComponentsMap, epicState)),
            updateReason: SEPARATE_FOOTER_BY_FOOTER_TMP_CMPS
        };
    }
};
