import { connect } from 'react-redux';
import React from "react";
import styles from "./Preview.css";
import type { AppState } from "../../../redux/modules/flowTypes";
import PreviewToolbar from "./PreviewToolbar/PreviewToolbar";
import DesktopPreview from "./Desktop/index";
import MobilePreview from "./Mobile/index";
import {
    PREVIEW_LOADED,
    MOBILE_PREVIEW_ORIENTATION_TOGGLE,
    CTRL_P_PRESSED,
} from "../../App/actionTypes";
import { SET_PREVIEW_PAGE_TO_LOAD } from "../actionTypes";
import { clickedToggleMobileOptionAC } from "./actionCreators";
import { mobileStyleAppSel } from "../../Mobile/style/selectors";
import { mobileMenuSel } from '../../Mobile/header/menu/selectors';

const Preview = props => {
    const { preview: { mobileView, show, hiddenRender } } = props;

    if (!hiddenRender || !show) {
        return null;
    }

    return (
        <div className={styles.fullScreen} style={{ opacity: show ? 1 : 0 }}>
            {mobileView.show ? <MobilePreview {...props} /> : <DesktopPreview {...props} />}
            { /* @ts-ignore */ }
            <PreviewToolbar mobileView={mobileView.show} />
        </div>
    );
};

const mapStateToProps = (appState: AppState) => {
    const { preview, subscriptionData, siteMap } = appState;
    const { currentPageId, componentsMap } = preview;

    return {
        preview,
        siteMap,
        globalStyles: preview.globalStyles,
        stylesheetsIdToNameMap: preview.stylesheetsIdToNameMap,
        componentsDependencies: preview.componentsDependencies,
        subscriptionData,
        mobileStyle: mobileStyleAppSel(appState),
        menuState: mobileMenuSel({ siteData: siteMap, currentPageId, componentsMap })
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    previewLoaded: (html: string) => dispatch({ type: PREVIEW_LOADED, payload: html }),
    onClickOrientation: () => dispatch({ type: MOBILE_PREVIEW_ORIENTATION_TOGGLE }),
    onToggleMobileVersion: () => dispatch(clickedToggleMobileOptionAC()),
    setPreviewPageToLoad: (id: string) => dispatch({ type: SET_PREVIEW_PAGE_TO_LOAD, payload: id }),
    togglePreview: () => dispatch({ type: CTRL_P_PRESSED }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
