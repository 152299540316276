import React from 'react';

import StandardDialog from '../../../../../../view/common/dialogs/StandardDialog';
import { LinkPageFormLinkButton, LinkPageFormIsHiddenCheckBox, LinkPageForm } from '../../../../linkPageForm/view';
import type { LinkPageInfoDialogProps } from "../flowTypes";
import { LINK_PAGE_INFO_SAVE } from "../../../actionTypes";
import { StripTypes } from '../../../../../../view/common/dialogs/baseDialog';

export default (props: LinkPageInfoDialogProps) => {
    const { button, form, checkBox, result, isFormValid, linkPageId, dispatch } = props;

    return (
        <StandardDialog
            title="msg: linkPageInfoDialog.updateLink {Update link in site menu}"
            titleContents={
                <LinkPageFormLinkButton
                    {...button}
                    dispatch={dispatch}
                />
            }
            footerContents={<LinkPageFormIsHiddenCheckBox {...checkBox} dispatch={dispatch} />}
            mctaText="msg: common.done {Done}"
            mctaHandler={() => dispatch({ type: LINK_PAGE_INFO_SAVE, payload: { linkPageId, data: result } })}
            disabled={!isFormValid}
            stripType={StripTypes.NONE}
        >
            <LinkPageForm {...form} dispatch={dispatch} />
        </StandardDialog>
    );
};
