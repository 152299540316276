import kind from './kind';
import config from '../FeaturedProducts/index';
import requireConfigurationOnDrop from './requireConfigurationOnDrop';
import previewConfig from './previewConfig';
import propertiesPanel from "./propertiesPanel/index";
import configurationDialog from './ProductSelectionDialog/dialogId';
import componentMainActions from './componentMainActions';
import reducer from './reducer/index';
import adjustComponentOnAdd from './adjustComponentOnAdd';

export default {
    ...config,
    kind,
    reducer,
    propertiesPanel,
    mobileEditorConfig: previewConfig,
    adjustComponentOnAdd,
    configurationDialog,
    requireConfigurationOnDrop,
    componentMainActions,
};
