import React from 'react';
import cx from 'classnames';
import * as pageIds from './ids';
import pagesTitles from './titles';
import NavigationGroup from '../../../../PropertiesPanel/view/IntlNavigationGroup';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import { id as mobileViewPageId } from '../../../../PropertiesPanel/view/MobileView/page';
import { ComponentNames, SVGContentType } from '../../../constants';
import { LOGO_KIND } from '../../kind';
import { Msg, injectIntl } from '../../../../../view/intl/index';
import {
    LOGO_PP_WEBSITE_TITLE_CHANGED, LOGO_PP_LOGO_ASSET_CHOOSEN, LOGO_PP_REMOVE_LOGO_ASSET_PRESSED
} from '../../actionTypes';
import { ImageChooserBtnPP } from '../../../../FileManager/imageChooser/ImageChooserBtnPP';
import styles from './style.css';
import PropertyContainer from "../../../../../view/common/PropertyContainer/index";
import InputField from '../../../../../view/common/Input/PropertiesInputField';
import lengthInputValidation from '../../../../inputControls/input/commonValidations/lengthInputValidation';
import { MVE_WEBSITE_TITLE_MAX_LENGTH } from '../../../../MobileViewEditor/header/propertiesPanel/pages/main/constants';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import { FcContentTypes } from "../../../../../redux/modules/children/fileChooser/FcContentTypes";

const websiteTitleField = (generalGlobalData, dispatch) => {
    return (
        <div>
            <div className={cx(styles.text, styles.or)}><Msg k="common.or">or</Msg></div>
            <VerticalSpacer y={15} />
            <PropertyContainer label="msg: logo.pp.main.logoText.label {Use this text as logo}">
                <InputField
                    value={generalGlobalData.websiteTitle || ''}
                    onChange={payload => dispatch({ type: LOGO_PP_WEBSITE_TITLE_CHANGED, payload, amendToSelf: true })}
                    usePropsValue
                    stopOnKeyDownEventPropagation
                    inputValidations={[{
                        validation: lengthInputValidation, options: { max: MVE_WEBSITE_TITLE_MAX_LENGTH },
                    }]}
                    rejectInvalidValue
                    trimValueOnPaste
                    placeholder="msg: mve.header.websiteTitle.placeholder {Add your website title}"
                    counter={{ maxLength: MVE_WEBSITE_TITLE_MAX_LENGTH }}
                />
            </PropertyContainer>
            <VerticalSpacer y={20} />
        </div>
    );
};

class Main extends React.Component<any> {
    render() {
        const { generalGlobalData, dispatch, navigateToPage, intl } = this.props,
            allowUpdatingWebsiteTitle = !generalGlobalData.logoAsset;

        let targetPagesIds = [mobileViewPageId];
        if (allowUpdatingWebsiteTitle && generalGlobalData.websiteTitle) {
            targetPagesIds = [pageIds.TITLE_STYLE, ...targetPagesIds];
        }

        return (
            <Page>
                <VerticalSpacer y={15} />
                <Msg className={styles.text} k="logo.pp.main.encouragement">
                    Add a logo and make your site look more professional.
                </Msg>
                <VerticalSpacer y={15} />
                <PropertyContainer label="msg: common.logoImage {Logo image}">
                    <ImageChooserBtnPP
                        asset={generalGlobalData.logoAsset}
                        chooseLabel="msg: addImage {Add image}"
                        changeAction={LOGO_PP_LOGO_ASSET_CHOOSEN}
                        removeAction={LOGO_PP_REMOVE_LOGO_ASSET_PRESSED}
                        removeButtonTitle={intl.msgJoint("msg: logo.pp.deleteLogo {Delete logo}")}
                        dispatch={dispatch}
                        extraImageContentTypes={FcContentTypes.SVG}
                        isImageEditorAllowed={!!generalGlobalData.logoAsset && generalGlobalData.logoAsset.contentType !== SVGContentType}
                    />
                    <VerticalSpacer y={8} />
                    <Msg className={styles.text} k="mve.header.showLogo.helpText">
                        Get the best result by using a .png with transparent background.
                    </Msg>
                </PropertyContainer>
                <VerticalSpacer y={18} />
                {allowUpdatingWebsiteTitle && websiteTitleField(generalGlobalData, dispatch)}
                <NavigationGroup
                    navigateToPage={navigateToPage}
                    pagesTitles={pagesTitles}
                    targetPagesIds={targetPagesIds}
                />
            </Page>
        );
    }
}

const id = pageIds.MAIN;

export const logoPpMainPageConfig = {
    id,
    view: injectIntl(Main),
    title: ComponentNames[LOGO_KIND]
};
