import * as R from 'ramda';
import { getCenterPositionForDialog } from "./utility";

export default function (...args) {
    let
        width,
        height,
        position,
        minHeight,
        component,
        dependsOn = {},
        updateStateDependencies,
        reducer,
        makeDefaultState,
        addIsEqToInitialStateFlag,
        stateIsEqInitialComparator,
        persistentState,
        calcRenderProps,
        modal,
        forceModal,
        dialogClassName,
        dragEnabled,
        onModalClickAction,
        draggableClassName,
        offsetTop,
        dialogBackgroundClassName;

    if (args.length === 1) {
        ({
            width,
            height,
            position,
            minHeight,
            component,
            dependsOn,
            updateStateDependencies,
            reducer,
            makeDefaultState,
            addIsEqToInitialStateFlag,
            stateIsEqInitialComparator,
            persistentState,
            calcRenderProps,
            modal = true,
            forceModal = false,
            dialogClassName = '',
            dragEnabled = undefined,
            dialogBackgroundClassName = '',
            draggableClassName = '',
            onModalClickAction,
            offsetTop
        } = args[0]);
    } else {
        [width, height, component, modal = true] = args;
    }

    return {
        component,
        confFactory: ({ browserWidth, browserHeight }, props) => {
            const _width = props && !R.isNil(props.width) ? props.width : width,
                _height = props && !R.isNil(props.height) ? props.height : height;

            const _position = position || getCenterPositionForDialog(
                _width, _height || minHeight, browserWidth, browserHeight
            );

            if (offsetTop) {
                _position.top = Math.max(20, _position.top + offsetTop);
            }
            return ({
                position: _position,
                modal: props && !R.isNil(props.modal) ? props.modal : modal,
                dimensions: {
                    width: _width,
                    height: _height,
                    minHeight
                }
            });
        },
        dependsOn,
        updateStateDependencies,
        reducer,
        makeDefaultState,
        addIsEqToInitialStateFlag,
        stateIsEqInitialComparator,
        persistentState,
        calcRenderProps,
        dialogClassName,
        forceModal,
        dragEnabled,
        onModalClickAction,
        draggableClassName,
        dialogBackgroundClassName
    };
}
