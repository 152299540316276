import type { ApiAction } from '../../../redux/middleware/api/flowTypes';
import CALL_API from '../../../redux/middleware/api/CALL_API';
import {
    SITE_DATA_UPDATE_REQUEST,
    SITE_DATA_UPDATE_SUCCESS,
    SITE_DATA_UPDATE_FAILURE
} from "../actionTypes";
import type { UpdateSiteDataInput } from "../../../../dal/flowTypes";

export default function (input: UpdateSiteDataInput): ApiAction {
    return {
        [CALL_API]: {
            types: [
                SITE_DATA_UPDATE_REQUEST,
                SITE_DATA_UPDATE_SUCCESS,
                SITE_DATA_UPDATE_FAILURE
            ],
            endpoint: 'updateSiteData',
            endpointParams: input
        }
    };
}
