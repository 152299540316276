/* eslint-disable max-len */
import React from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import { Intl } from "../../../../view/intl/index";
import { CUSTOM } from "../../../../constants/app";
import { ContinueButton, PrimaryButton } from "../../../../view/common/Button/index";
import styles from "./OnboardingTheming.css";
import { ADD_THEME_HISTORY } from "../../../AutoColorLeftPanel/actionTypes";
import type { FullPaletteHsl } from "../../../ThemesAccordion/flowTypes";
import { isNextGenPreviewSelector } from "../../../TemplateSelector_DEPRECATED/epics/preview/selector";
import { dynamicPreviewSectionsLoadingSelector } from "../../Dynamic/Epic/selectors";

type OnboardingThemingFooterProps = {
    selectedColorPickerTheme: string;
    onboardingThemingFooterCustomMctaHandler: string;
    onboardingThemingFooterCustomSctaHandler: string;
    onboardingThemingFooterDefaultMctaHandler: string;
    onboardingThemingFooterDefaultSctaHandler: string;
    intl: Intl;
    dispatch: Dispatch;
    currentPalette: FullPaletteHsl;
};

export const OnboardingThemingFooter = (props: OnboardingThemingFooterProps) => {
    const
        {
            selectedColorPickerTheme,
            onboardingThemingFooterCustomMctaHandler,
            onboardingThemingFooterCustomSctaHandler,
            onboardingThemingFooterDefaultMctaHandler,
            onboardingThemingFooterDefaultSctaHandler,
            intl,
            dispatch,
            currentPalette
        } = props,
        isNextGenPreview = useSelector(isNextGenPreviewSelector),
        isCustomTheme = selectedColorPickerTheme === CUSTOM,
        sectionLoading = useSelector(dynamicPreviewSectionsLoadingSelector),
        onboardingThemingFooterSCTAText = "msg: common.back {Back}";

    let onboardingThemingFooterMCTAText;
    if (isCustomTheme) {
        onboardingThemingFooterMCTAText = "msg: common.save {Save}";
    } else if (isNextGenPreview) {
        onboardingThemingFooterMCTAText = "msg: common.apply {Apply}";
    } else {
        onboardingThemingFooterMCTAText = "msg: common.done {Done}";
    }

    const
        onboardingThemingFooterSCTAHandler = isCustomTheme
            ? () => {
                dispatch({
                    type: onboardingThemingFooterCustomSctaHandler
                });
            }
            : () => {
                dispatch({
                    type: onboardingThemingFooterDefaultSctaHandler
                });
            },
        onboardingThemingFooterMCTAHandler = isCustomTheme
            ? () => {
                dispatch({
                    type: onboardingThemingFooterCustomMctaHandler
                });
                dispatch({
                    type: ADD_THEME_HISTORY,
                    payload: currentPalette
                });
            }
            : () => {
                dispatch({
                    type: onboardingThemingFooterDefaultMctaHandler
                });
            };

    return (
        <div className={styles.onboardingThemingFooterContainer}>
            <ContinueButton
                className={cx(styles.hMargin0, styles.hPadding0, { [styles.nextGenButton]: isNextGenPreview })}
                onClick={onboardingThemingFooterSCTAHandler}
                label={onboardingThemingFooterSCTAText}
            />
            <PrimaryButton
                className={cx(styles.hMargin0, { [styles.nextGenButton]: isNextGenPreview })}
                onClick={onboardingThemingFooterMCTAHandler}
                disabled={sectionLoading}
            >
                {intl.msgJoint(onboardingThemingFooterMCTAText)}
            </PrimaryButton>
        </div>
    );
};
