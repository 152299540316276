import * as R from 'ramda';

/* Only for epic updater reducers
 * Returns state if new state is equals old one
 * If state is undefined, returns null */
export default (fun: Function) => {
    return (...args: Array<any>) => {
        const
            state = R.last(args),
            result = fun(...args),
            { state: newState, actionToDispatch } = result;

        if (R.equals(newState, state)) {
            return { state, actionToDispatch };
        } else {
            return result;
        }
    };
};
