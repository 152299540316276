/* eslint-disable max-len */

import validation from 'one-validation';
import type { SocialLinksConfig } from './flowTypes';

import { SvgEmailCircleFillDark } from './svgs/EmailCircleFillDark';
import { SvgEmailCircleFillLight } from './svgs/EmailCircleFillLight';
import { SvgEmailCircleFillMulticolor } from './svgs/EmailCircleFillMulticolor';
import { SvgEmailCircleKnockoutDark } from './svgs/EmailCircleKnockoutDark';
import { SvgEmailCircleKnockoutLight } from './svgs/EmailCircleKnockoutLight';
import { SvgEmailCircleKnockoutMulticolor } from './svgs/EmailCircleKnockoutMulticolor';
import { SvgEmailRegularDark } from './svgs/EmailRegularDark';
import { SvgEmailRegularLight } from './svgs/EmailRegularLight';
import { SvgEmailRegularMulticolor } from './svgs/EmailRegularMulticolor';
import { SvgEmailRoundedFillDark } from './svgs/EmailRoundedFillDark';
import { SvgEmailRoundedFillLight } from './svgs/EmailRoundedFillLight';
import { SvgEmailRoundedFillMulticolor } from './svgs/EmailRoundedFillMulticolor';
import { SvgEmailRoundedKnockoutDark } from './svgs/EmailRoundedKnockoutDark';
import { SvgEmailRoundedKnockoutLight } from './svgs/EmailRoundedKnockoutLight';
import { SvgEmailRoundedKnockoutMulticolor } from './svgs/EmailRoundedKnockoutMulticolor';
import { SvgEmailSquareFillDark } from './svgs/EmailSquareFillDark';
import { SvgEmailSquareFillLight } from './svgs/EmailSquareFillLight';
import { SvgEmailSquareFillMulticolor } from './svgs/EmailSquareFillMulticolor';
import { SvgEmailSquareKnockoutDark } from './svgs/EmailSquareKnockoutDark';
import { SvgEmailSquareKnockoutLight } from './svgs/EmailSquareKnockoutLight';
import { SvgEmailSquareKnockoutMulticolor } from './svgs/EmailSquareKnockoutMulticolor';

import { SvgFacebookCircleFillDark } from './svgs/FacebookCircleFillDark';
import { SvgFacebookCircleFillLight } from './svgs/FacebookCircleFillLight';
import { SvgFacebookCircleFillMulticolor } from './svgs/FacebookCircleFillMulticolor';
import { SvgFacebookCircleKnockoutDark } from './svgs/FacebookCircleKnockoutDark';
import { SvgFacebookCircleKnockoutLight } from './svgs/FacebookCircleKnockoutLight';
import { SvgFacebookCircleKnockoutMulticolor } from './svgs/FacebookCircleKnockoutMulticolor';
import { SvgFacebookRegularDark } from './svgs/FacebookRegularDark';
import { SvgFacebookRegularLight } from './svgs/FacebookRegularLight';
import { SvgFacebookRegularMulticolor } from './svgs/FacebookRegularMulticolor';
import { SvgFacebookRoundedFillDark } from './svgs/FacebookRoundedFillDark';
import { SvgFacebookRoundedFillLight } from './svgs/FacebookRoundedFillLight';
import { SvgFacebookRoundedFillMulticolor } from './svgs/FacebookRoundedFillMulticolor';
import { SvgFacebookRoundedKnockoutDark } from './svgs/FacebookRoundedKnockoutDark';
import { SvgFacebookRoundedKnockoutLight } from './svgs/FacebookRoundedKnockoutLight';
import { SvgFacebookRoundedKnockoutMulticolor } from './svgs/FacebookRoundedKnockoutMulticolor';
import { SvgFacebookSquareFillDark } from './svgs/FacebookSquareFillDark';
import { SvgFacebookSquareFillLight } from './svgs/FacebookSquareFillLight';
import { SvgFacebookSquareFillMulticolor } from './svgs/FacebookSquareFillMulticolor';
import { SvgFacebookSquareKnockoutDark } from './svgs/FacebookSquareKnockoutDark';
import { SvgFacebookSquareKnockoutLight } from './svgs/FacebookSquareKnockoutLight';
import { SvgFacebookSquareKnockoutMulticolor } from './svgs/FacebookSquareKnockoutMulticolor';

import { SvgFoursquareCircleFillDark } from './svgs/FoursquareCircleFillDark';
import { SvgFoursquareCircleFillLight } from './svgs/FoursquareCircleFillLight';
import { SvgFoursquareCircleFillMulticolor } from './svgs/FoursquareCircleFillMulticolor';
import { SvgFoursquareCircleKnockoutDark } from './svgs/FoursquareCircleKnockoutDark';
import { SvgFoursquareCircleKnockoutLight } from './svgs/FoursquareCircleKnockoutLight';
import { SvgFoursquareCircleKnockoutMulticolor } from './svgs/FoursquareCircleKnockoutMulticolor';
import { SvgFoursquareRegularDark } from './svgs/FoursquareRegularDark';
import { SvgFoursquareRegularLight } from './svgs/FoursquareRegularLight';
import { SvgFoursquareRegularMulticolor } from './svgs/FoursquareRegularMulticolor';
import { SvgFoursquareRoundedFillDark } from './svgs/FoursquareRoundedFillDark';
import { SvgFoursquareRoundedFillLight } from './svgs/FoursquareRoundedFillLight';
import { SvgFoursquareRoundedFillMulticolor } from './svgs/FoursquareRoundedFillMulticolor';
import { SvgFoursquareRoundedKnockoutDark } from './svgs/FoursquareRoundedKnockoutDark';
import { SvgFoursquareRoundedKnockoutLight } from './svgs/FoursquareRoundedKnockoutLight';
import { SvgFoursquareRoundedKnockoutMulticolor } from './svgs/FoursquareRoundedKnockoutMulticolor';
import { SvgFoursquareSquareFillDark } from './svgs/FoursquareSquareFillDark';
import { SvgFoursquareSquareFillLight } from './svgs/FoursquareSquareFillLight';
import { SvgFoursquareSquareFillMulticolor } from './svgs/FoursquareSquareFillMulticolor';
import { SvgFoursquareSquareKnockoutDark } from './svgs/FoursquareSquareKnockoutDark';
import { SvgFoursquareSquareKnockoutLight } from './svgs/FoursquareSquareKnockoutLight';
import { SvgFoursquareSquareKnockoutMulticolor } from './svgs/FoursquareSquareKnockoutMulticolor';

import { SvgInstagramCircleFillDark } from './svgs/InstagramCircleFillDark';
import { SvgInstagramCircleFillLight } from './svgs/InstagramCircleFillLight';
import { SvgInstagramCircleFillMulticolor } from './svgs/InstagramCircleFillMulticolor';
import { SvgInstagramCircleKnockoutDark } from './svgs/InstagramCircleKnockoutDark';
import { SvgInstagramCircleKnockoutLight } from './svgs/InstagramCircleKnockoutLight';
import { SvgInstagramCircleKnockoutMulticolor } from './svgs/InstagramCircleKnockoutMulticolor';
import { SvgInstagramRegularDark } from './svgs/InstagramRegularDark';
import { SvgInstagramRegularLight } from './svgs/InstagramRegularLight';
import { SvgInstagramRegularMulticolor } from './svgs/InstagramRegularMulticolorPatched';
import { SvgInstagramRoundedFillDark } from './svgs/InstagramRoundedFillDark';
import { SvgInstagramRoundedFillLight } from './svgs/InstagramRoundedFillLight';
import { SvgInstagramRoundedFillMulticolor } from './svgs/InstagramRoundedFillMulticolor';
import { SvgInstagramRoundedKnockoutDark } from './svgs/InstagramRoundedKnockoutDark';
import { SvgInstagramRoundedKnockoutLight } from './svgs/InstagramRoundedKnockoutLight';
import { SvgInstagramRoundedKnockoutMulticolor } from './svgs/InstagramRoundedKnockoutMulticolor';
import { SvgInstagramSquareFillDark } from './svgs/InstagramSquareFillDark';
import { SvgInstagramSquareFillLight } from './svgs/InstagramSquareFillLight';
import { SvgInstagramSquareFillMulticolor } from './svgs/InstagramSquareFillMulticolor';
import { SvgInstagramSquareKnockoutDark } from './svgs/InstagramSquareKnockoutDark';
import { SvgInstagramSquareKnockoutLight } from './svgs/InstagramSquareKnockoutLight';
import { SvgInstagramSquareKnockoutMulticolor } from './svgs/InstagramSquareKnockoutMulticolor';

import { SvgLinkedinCircleFillDark } from './svgs/LinkedinCircleFillDark';
import { SvgLinkedinCircleFillLight } from './svgs/LinkedinCircleFillLight';
import { SvgLinkedinCircleFillMulticolor } from './svgs/LinkedinCircleFillMulticolor';
import { SvgLinkedinCircleKnockoutDark } from './svgs/LinkedinCircleKnockoutDark';
import { SvgLinkedinCircleKnockoutLight } from './svgs/LinkedinCircleKnockoutLight';
import { SvgLinkedinCircleKnockoutMulticolor } from './svgs/LinkedinCircleKnockoutMulticolor';
import { SvgLinkedinRegularDark } from './svgs/LinkedinRegularDark';
import { SvgLinkedinRegularLight } from './svgs/LinkedinRegularLight';
import { SvgLinkedinRegularMulticolor } from './svgs/LinkedinRegularMulticolor';
import { SvgLinkedinRoundedFillDark } from './svgs/LinkedinRoundedFillDark';
import { SvgLinkedinRoundedFillLight } from './svgs/LinkedinRoundedFillLight';
import { SvgLinkedinRoundedFillMulticolor } from './svgs/LinkedinRoundedFillMulticolor';
import { SvgLinkedinRoundedKnockoutDark } from './svgs/LinkedinRoundedKnockoutDark';
import { SvgLinkedinRoundedKnockoutLight } from './svgs/LinkedinRoundedKnockoutLight';
import { SvgLinkedinRoundedKnockoutMulticolor } from './svgs/LinkedinRoundedKnockoutMulticolor';
import { SvgLinkedinSquareFillDark } from './svgs/LinkedinSquareFillDark';
import { SvgLinkedinSquareFillLight } from './svgs/LinkedinSquareFillLight';
import { SvgLinkedinSquareFillMulticolor } from './svgs/LinkedinSquareFillMulticolor';
import { SvgLinkedinSquareKnockoutDark } from './svgs/LinkedinSquareKnockoutDark';
import { SvgLinkedinSquareKnockoutLight } from './svgs/LinkedinSquareKnockoutLight';
import { SvgLinkedinSquareKnockoutMulticolor } from './svgs/LinkedinSquareKnockoutMulticolor';

import { SvgPinterestCircleFillDark } from './svgs/PinterestCircleFillDark';
import { SvgPinterestCircleFillLight } from './svgs/PinterestCircleFillLight';
import { SvgPinterestCircleFillMulticolor } from './svgs/PinterestCircleFillMulticolor';
import { SvgPinterestCircleKnockoutDark } from './svgs/PinterestCircleKnockoutDark';
import { SvgPinterestCircleKnockoutLight } from './svgs/PinterestCircleKnockoutLight';
import { SvgPinterestCircleKnockoutMulticolor } from './svgs/PinterestCircleKnockoutMulticolor';
import { SvgPinterestRegularDark } from './svgs/PinterestRegularDark';
import { SvgPinterestRegularLight } from './svgs/PinterestRegularLight';
import { SvgPinterestRegularMulticolor } from './svgs/PinterestRegularMulticolor';
import { SvgPinterestRoundedFillDark } from './svgs/PinterestRoundedFillDark';
import { SvgPinterestRoundedFillLight } from './svgs/PinterestRoundedFillLight';
import { SvgPinterestRoundedFillMulticolor } from './svgs/PinterestRoundedFillMulticolor';
import { SvgPinterestRoundedKnockoutDark } from './svgs/PinterestRoundedKnockoutDark';
import { SvgPinterestRoundedKnockoutLight } from './svgs/PinterestRoundedKnockoutLight';
import { SvgPinterestRoundedKnockoutMulticolor } from './svgs/PinterestRoundedKnockoutMulticolor';
import { SvgPinterestSquareFillDark } from './svgs/PinterestSquareFillDark';
import { SvgPinterestSquareFillLight } from './svgs/PinterestSquareFillLight';
import { SvgPinterestSquareFillMulticolor } from './svgs/PinterestSquareFillMulticolor';
import { SvgPinterestSquareKnockoutDark } from './svgs/PinterestSquareKnockoutDark';
import { SvgPinterestSquareKnockoutLight } from './svgs/PinterestSquareKnockoutLight';
import { SvgPinterestSquareKnockoutMulticolor } from './svgs/PinterestSquareKnockoutMulticolor';

import { SvgRedditCircleFillDark } from './svgs/RedditCircleFillDark';
import { SvgRedditCircleFillLight } from './svgs/RedditCircleFillLight';
import { SvgRedditCircleFillMulticolor } from './svgs/RedditCircleFillMulticolor';
import { SvgRedditCircleKnockoutDark } from './svgs/RedditCircleKnockoutDark';
import { SvgRedditCircleKnockoutLight } from './svgs/RedditCircleKnockoutLight';
import { SvgRedditCircleKnockoutMulticolor } from './svgs/RedditCircleKnockoutMulticolor';
import { SvgRedditRegularDark } from './svgs/RedditRegularDark';
import { SvgRedditRegularLight } from './svgs/RedditRegularLight';
import { SvgRedditRegularMulticolor } from './svgs/RedditRegularMulticolor';
import { SvgRedditRoundedFillDark } from './svgs/RedditRoundedFillDark';
import { SvgRedditRoundedFillLight } from './svgs/RedditRoundedFillLight';
import { SvgRedditRoundedFillMulticolor } from './svgs/RedditRoundedFillMulticolor';
import { SvgRedditRoundedKnockoutDark } from './svgs/RedditRoundedKnockoutDark';
import { SvgRedditRoundedKnockoutLight } from './svgs/RedditRoundedKnockoutLight';
import { SvgRedditRoundedKnockoutMulticolor } from './svgs/RedditRoundedKnockoutMulticolor';
import { SvgRedditSquareFillDark } from './svgs/RedditSquareFillDark';
import { SvgRedditSquareFillLight } from './svgs/RedditSquareFillLight';
import { SvgRedditSquareFillMulticolor } from './svgs/RedditSquareFillMulticolor';
import { SvgRedditSquareKnockoutDark } from './svgs/RedditSquareKnockoutDark';
import { SvgRedditSquareKnockoutLight } from './svgs/RedditSquareKnockoutLight';
import { SvgRedditSquareKnockoutMulticolor } from './svgs/RedditSquareKnockoutMulticolor';

import { SvgSnapchatCircleFillDark } from './svgs/SnapchatCircleFillDark';
import { SvgSnapchatCircleFillLight } from './svgs/SnapchatCircleFillLight';
import { SvgSnapchatCircleFillMulticolor } from './svgs/SnapchatCircleFillMulticolor';
import { SvgSnapchatCircleKnockoutDark } from './svgs/SnapchatCircleKnockoutDark';
import { SvgSnapchatCircleKnockoutLight } from './svgs/SnapchatCircleKnockoutLight';
import { SvgSnapchatCircleKnockoutMulticolor } from './svgs/SnapchatCircleKnockoutMulticolor';
import { SvgSnapchatRegularDark } from './svgs/SnapchatRegularDark';
import { SvgSnapchatRegularLight } from './svgs/SnapchatRegularLight';
import { SvgSnapchatRegularMulticolor } from './svgs/SnapchatRegularMulticolor';
import { SvgSnapchatRoundedFillDark } from './svgs/SnapchatRoundedFillDark';
import { SvgSnapchatRoundedFillLight } from './svgs/SnapchatRoundedFillLight';
import { SvgSnapchatRoundedFillMulticolor } from './svgs/SnapchatRoundedFillMulticolor';
import { SvgSnapchatRoundedKnockoutDark } from './svgs/SnapchatRoundedKnockoutDark';
import { SvgSnapchatRoundedKnockoutLight } from './svgs/SnapchatRoundedKnockoutLight';
import { SvgSnapchatRoundedKnockoutMulticolor } from './svgs/SnapchatRoundedKnockoutMulticolor';
import { SvgSnapchatSquareFillDark } from './svgs/SnapchatSquareFillDark';
import { SvgSnapchatSquareFillLight } from './svgs/SnapchatSquareFillLight';
import { SvgSnapchatSquareFillMulticolor } from './svgs/SnapchatSquareFillMulticolor';
import { SvgSnapchatSquareKnockoutDark } from './svgs/SnapchatSquareKnockoutDark';
import { SvgSnapchatSquareKnockoutLight } from './svgs/SnapchatSquareKnockoutLight';
import { SvgSnapchatSquareKnockoutMulticolor } from './svgs/SnapchatSquareKnockoutMulticolor';

import { SvgTwitterCircleFillDark } from './svgs/TwitterCircleFillDark';
import { SvgTwitterCircleFillLight } from './svgs/TwitterCircleFillLight';
import { SvgTwitterCircleFillMulticolor } from './svgs/TwitterCircleFillMulticolor';
import { SvgTwitterCircleKnockoutDark } from './svgs/TwitterCircleKnockoutDark';
import { SvgTwitterCircleKnockoutLight } from './svgs/TwitterCircleKnockoutLight';
import { SvgTwitterCircleKnockoutMulticolor } from './svgs/TwitterCircleKnockoutMulticolor';
import { SvgTwitterRegularDark } from './svgs/TwitterRegularDark';
import { SvgTwitterRegularLight } from './svgs/TwitterRegularLight';
import { SvgTwitterRegularMulticolor } from './svgs/TwitterRegularMulticolor';
import { SvgTwitterRoundedFillDark } from './svgs/TwitterRoundedFillDark';
import { SvgTwitterRoundedFillLight } from './svgs/TwitterRoundedFillLight';
import { SvgTwitterRoundedFillMulticolor } from './svgs/TwitterRoundedFillMulticolor';
import { SvgTwitterRoundedKnockoutDark } from './svgs/TwitterRoundedKnockoutDark';
import { SvgTwitterRoundedKnockoutLight } from './svgs/TwitterRoundedKnockoutLight';
import { SvgTwitterRoundedKnockoutMulticolor } from './svgs/TwitterRoundedKnockoutMulticolor';
import { SvgTwitterSquareFillDark } from './svgs/TwitterSquareFillDark';
import { SvgTwitterSquareFillLight } from './svgs/TwitterSquareFillLight';
import { SvgTwitterSquareFillMulticolor } from './svgs/TwitterSquareFillMulticolor';
import { SvgTwitterSquareKnockoutDark } from './svgs/TwitterSquareKnockoutDark';
import { SvgTwitterSquareKnockoutLight } from './svgs/TwitterSquareKnockoutLight';
import { SvgTwitterSquareKnockoutMulticolor } from './svgs/TwitterSquareKnockoutMulticolor';

import { SvgTumblrCircleFillDark } from './svgs/TumblrCircleFillDark';
import { SvgTumblrCircleFillLight } from './svgs/TumblrCircleFillLight';
import { SvgTumblrCircleFillMulticolor } from './svgs/TumblrCircleFillMulticolor';
import { SvgTumblrCircleKnockoutDark } from './svgs/TumblrCircleKnockoutDark';
import { SvgTumblrCircleKnockoutLight } from './svgs/TumblrCircleKnockoutLight';
import { SvgTumblrCircleKnockoutMulticolor } from './svgs/TumblrCircleKnockoutMulticolor';
import { SvgTumblrRegularDark } from './svgs/TumblrRegularDark';
import { SvgTumblrRegularLight } from './svgs/TumblrRegularLight';
import { SvgTumblrRegularMulticolor } from './svgs/TumblrRegularMulticolor';
import { SvgTumblrRoundedFillDark } from './svgs/TumblrRoundedFillDark';
import { SvgTumblrRoundedFillLight } from './svgs/TumblrRoundedFillLight';
import { SvgTumblrRoundedFillMulticolor } from './svgs/TumblrRoundedFillMulticolor';
import { SvgTumblrRoundedKnockoutDark } from './svgs/TumblrRoundedKnockoutDark';
import { SvgTumblrRoundedKnockoutLight } from './svgs/TumblrRoundedKnockoutLight';
import { SvgTumblrRoundedKnockoutMulticolor } from './svgs/TumblrRoundedKnockoutMulticolor';
import { SvgTumblrSquareFillDark } from './svgs/TumblrSquareFillDark';
import { SvgTumblrSquareFillLight } from './svgs/TumblrSquareFillLight';
import { SvgTumblrSquareFillMulticolor } from './svgs/TumblrSquareFillMulticolor';
import { SvgTumblrSquareKnockoutDark } from './svgs/TumblrSquareKnockoutDark';
import { SvgTumblrSquareKnockoutLight } from './svgs/TumblrSquareKnockoutLight';
import { SvgTumblrSquareKnockoutMulticolor } from './svgs/TumblrSquareKnockoutMulticolor';

import { SvgTripadvisorCircleFillDark } from './svgs/TripadvisorCircleFillDark';
import { SvgTripadvisorCircleFillLight } from './svgs/TripadvisorCircleFillLight';
import { SvgTripadvisorCircleFillMulticolor } from './svgs/TripadvisorCircleFillMulticolor';
import { SvgTripadvisorCircleKnockoutDark } from './svgs/TripadvisorCircleKnockoutDark';
import { SvgTripadvisorCircleKnockoutLight } from './svgs/TripadvisorCircleKnockoutLight';
import { SvgTripadvisorCircleKnockoutMulticolor } from './svgs/TripadvisorCircleKnockoutMulticolor';
import { SvgTripadvisorRegularDark } from './svgs/TripadvisorRegularDark';
import { SvgTripadvisorRegularLight } from './svgs/TripadvisorRegularLight';
import { SvgTripadvisorRegularMulticolor } from './svgs/TripadvisorRegularMulticolor';
import { SvgTripadvisorRoundedFillDark } from './svgs/TripadvisorRoundedFillDark';
import { SvgTripadvisorRoundedFillLight } from './svgs/TripadvisorRoundedFillLight';
import { SvgTripadvisorRoundedFillMulticolor } from './svgs/TripadvisorRoundedFillMulticolor';
import { SvgTripadvisorRoundedKnockoutDark } from './svgs/TripadvisorRoundedKnockoutDark';
import { SvgTripadvisorRoundedKnockoutLight } from './svgs/TripadvisorRoundedKnockoutLight';
import { SvgTripadvisorRoundedKnockoutMulticolor } from './svgs/TripadvisorRoundedKnockoutMulticolor';
import { SvgTripadvisorSquareFillDark } from './svgs/TripadvisorSquareFillDark';
import { SvgTripadvisorSquareFillLight } from './svgs/TripadvisorSquareFillLight';
import { SvgTripadvisorSquareFillMulticolor } from './svgs/TripadvisorSquareFillMulticolor';
import { SvgTripadvisorSquareKnockoutDark } from './svgs/TripadvisorSquareKnockoutDark';
import { SvgTripadvisorSquareKnockoutLight } from './svgs/TripadvisorSquareKnockoutLight';
import { SvgTripadvisorSquareKnockoutMulticolor } from './svgs/TripadvisorSquareKnockoutMulticolor';

import { SvgVimeoCircleFillDark } from './svgs/VimeoCircleFillDark';
import { SvgVimeoCircleFillLight } from './svgs/VimeoCircleFillLight';
import { SvgVimeoCircleFillMulticolor } from './svgs/VimeoCircleFillMulticolor';
import { SvgVimeoCircleKnockoutDark } from './svgs/VimeoCircleKnockoutDark';
import { SvgVimeoCircleKnockoutLight } from './svgs/VimeoCircleKnockoutLight';
import { SvgVimeoCircleKnockoutMulticolor } from './svgs/VimeoCircleKnockoutMulticolor';
import { SvgVimeoRegularDark } from './svgs/VimeoRegularDark';
import { SvgVimeoRegularLight } from './svgs/VimeoRegularLight';
import { SvgVimeoRegularMulticolor } from './svgs/VimeoRegularMulticolor';
import { SvgVimeoRoundedFillDark } from './svgs/VimeoRoundedFillDark';
import { SvgVimeoRoundedFillLight } from './svgs/VimeoRoundedFillLight';
import { SvgVimeoRoundedFillMulticolor } from './svgs/VimeoRoundedFillMulticolor';
import { SvgVimeoRoundedKnockoutDark } from './svgs/VimeoRoundedKnockoutDark';
import { SvgVimeoRoundedKnockoutLight } from './svgs/VimeoRoundedKnockoutLight';
import { SvgVimeoRoundedKnockoutMulticolor } from './svgs/VimeoRoundedKnockoutMulticolor';
import { SvgVimeoSquareFillDark } from './svgs/VimeoSquareFillDark';
import { SvgVimeoSquareFillLight } from './svgs/VimeoSquareFillLight';
import { SvgVimeoSquareFillMulticolor } from './svgs/VimeoSquareFillMulticolor';
import { SvgVimeoSquareKnockoutDark } from './svgs/VimeoSquareKnockoutDark';
import { SvgVimeoSquareKnockoutLight } from './svgs/VimeoSquareKnockoutLight';
import { SvgVimeoSquareKnockoutMulticolor } from './svgs/VimeoSquareKnockoutMulticolor';

import { SvgYoutubeCircleFillDark } from './svgs/YoutubeCircleFillDark';
import { SvgYoutubeCircleFillLight } from './svgs/YoutubeCircleFillLight';
import { SvgYoutubeCircleFillMulticolor } from './svgs/YoutubeCircleFillMulticolor';
import { SvgYoutubeCircleKnockoutDark } from './svgs/YoutubeCircleKnockoutDark';
import { SvgYoutubeCircleKnockoutLight } from './svgs/YoutubeCircleKnockoutLight';
import { SvgYoutubeCircleKnockoutMulticolor } from './svgs/YoutubeCircleKnockoutMulticolor';
import { SvgYoutubeRegularDark } from './svgs/YoutubeRegularDark';
import { SvgYoutubeRegularLight } from './svgs/YoutubeRegularLight';
import { SvgYoutubeRegularMulticolor } from './svgs/YoutubeRegularMulticolor';
import { SvgYoutubeRoundedFillDark } from './svgs/YoutubeRoundedFillDark';
import { SvgYoutubeRoundedFillLight } from './svgs/YoutubeRoundedFillLight';
import { SvgYoutubeRoundedFillMulticolor } from './svgs/YoutubeRoundedFillMulticolor';
import { SvgYoutubeRoundedKnockoutDark } from './svgs/YoutubeRoundedKnockoutDark';
import { SvgYoutubeRoundedKnockoutLight } from './svgs/YoutubeRoundedKnockoutLight';
import { SvgYoutubeRoundedKnockoutMulticolor } from './svgs/YoutubeRoundedKnockoutMulticolor';
import { SvgYoutubeSquareFillDark } from './svgs/YoutubeSquareFillDark';
import { SvgYoutubeSquareFillLight } from './svgs/YoutubeSquareFillLight';
import { SvgYoutubeSquareFillMulticolor } from './svgs/YoutubeSquareFillMulticolor';
import { SvgYoutubeSquareKnockoutDark } from './svgs/YoutubeSquareKnockoutDark';
import { SvgYoutubeSquareKnockoutLight } from './svgs/YoutubeSquareKnockoutLight';
import { SvgYoutubeSquareKnockoutMulticolor } from './svgs/YoutubeSquareKnockoutMulticolor';

import { SvgYelpCircleFillDark } from './svgs/YelpCircleFillDark';
import { SvgYelpCircleFillLight } from './svgs/YelpCircleFillLight';
import { SvgYelpCircleFillMulticolor } from './svgs/YelpCircleFillMulticolor';
import { SvgYelpCircleKnockoutDark } from './svgs/YelpCircleKnockoutDark';
import { SvgYelpCircleKnockoutLight } from './svgs/YelpCircleKnockoutLight';
import { SvgYelpCircleKnockoutMulticolor } from './svgs/YelpCircleKnockoutMulticolor';
import { SvgYelpRegularDark } from './svgs/YelpRegularDark';
import { SvgYelpRegularLight } from './svgs/YelpRegularLight';
import { SvgYelpRegularMulticolor } from './svgs/YelpRegularMulticolor';
import { SvgYelpRoundedFillDark } from './svgs/YelpRoundedFillDark';
import { SvgYelpRoundedFillLight } from './svgs/YelpRoundedFillLight';
import { SvgYelpRoundedFillMulticolor } from './svgs/YelpRoundedFillMulticolor';
import { SvgYelpRoundedKnockoutDark } from './svgs/YelpRoundedKnockoutDark';
import { SvgYelpRoundedKnockoutLight } from './svgs/YelpRoundedKnockoutLight';
import { SvgYelpRoundedKnockoutMulticolor } from './svgs/YelpRoundedKnockoutMulticolor';
import { SvgYelpSquareFillDark } from './svgs/YelpSquareFillDark';
import { SvgYelpSquareFillLight } from './svgs/YelpSquareFillLight';
import { SvgYelpSquareFillMulticolor } from './svgs/YelpSquareFillMulticolor';
import { SvgYelpSquareKnockoutDark } from './svgs/YelpSquareKnockoutDark';
import { SvgYelpSquareKnockoutLight } from './svgs/YelpSquareKnockoutLight';
import { SvgYelpSquareKnockoutMulticolor } from './svgs/YelpSquareKnockoutMulticolor';

import { SocialColorSourceType, SocialStyleType } from './constants';

export const SocialPlatformTypes = {
    YOUTUBE: 'YOUTUBE',
    FACEBOOK: 'FACEBOOK',
    PINTEREST: 'PINTEREST',
    EMAIL: 'EMAIL',
    INSTAGRAM: 'INSTAGRAM',
    LINKEDIN: 'LINKEDIN',
    TWITTER: 'TWITTER',
    FOURSQUARE: 'FOURSQUARE',
    REDDIT: 'REDDIT',
    SNAPCHAT: 'SNAPCHAT',
    TRIPADVISOR: 'TRIPADVISOR',
    TUMBLR: 'TUMBLR',
    VIMEO: 'VIMEO',
    YELP: 'YELP',
};

// eslint-disable-next-line max-len
export const socialEditUrlDialogDescription = 'msg: social.editUrlDialog.description {Your social links may appear in more than one place on your website. Edit a link here and, it will be updated everywhere.}';

export const socialLinksConfigs: Array<SocialLinksConfig> = [
    {
        kind: SocialPlatformTypes.EMAIL,
        svgCmpsByColorByType: {
            // @ts-ignore
            [SocialColorSourceType.DARK]: {
                [SocialStyleType.REGULAR]: SvgEmailRegularDark,
                [SocialStyleType.CIRCLE_FILL]: SvgEmailCircleFillDark,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgEmailCircleKnockoutDark,
                [SocialStyleType.SQUARE_FILL]: SvgEmailSquareFillDark,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgEmailSquareKnockoutDark,
                [SocialStyleType.ROUNDED_FILL]: SvgEmailRoundedFillDark,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgEmailRoundedKnockoutDark,
            },
            // @ts-ignore
            [SocialColorSourceType.LIGHT]: {
                [SocialStyleType.REGULAR]: SvgEmailRegularLight,
                [SocialStyleType.CIRCLE_FILL]: SvgEmailCircleFillLight,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgEmailCircleKnockoutLight,
                [SocialStyleType.SQUARE_FILL]: SvgEmailSquareFillLight,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgEmailSquareKnockoutLight,
                [SocialStyleType.ROUNDED_FILL]: SvgEmailRoundedFillLight,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgEmailRoundedKnockoutLight,
            },
            // @ts-ignore
            [SocialColorSourceType.MULTI_COLOR]: {
                [SocialStyleType.REGULAR]: SvgEmailRegularMulticolor,
                [SocialStyleType.CIRCLE_FILL]: SvgEmailCircleFillMulticolor,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgEmailCircleKnockoutMulticolor,
                [SocialStyleType.SQUARE_FILL]: SvgEmailSquareFillMulticolor,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgEmailSquareKnockoutMulticolor,
                [SocialStyleType.ROUNDED_FILL]: SvgEmailRoundedFillMulticolor,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgEmailRoundedKnockoutMulticolor,
            },
        },
        title: 'Email',
        urlPlaceholder: 'mailto:',
        color: 'gray',
        linkRegex: new RegExp(validation.email, 'i'),
        editUrlDialogTitle: 'msg: social.editUrlDialogTitle.email {Edit email link}',
        deleteUrlDialogTitle: 'msg: social.deleteUrlDialogTitle.email {Delete email link?}',
        editInputLabel: 'msg: common.emailAddress {Email address}',
        editValidationErrMsg: 'msg: social.editUrl.validationErr.email {Add a valid email address and try again.}'
    },
    {
        kind: SocialPlatformTypes.FACEBOOK,
        svgCmpsByColorByType: {
            // @ts-ignore
            [SocialColorSourceType.DARK]: {
                [SocialStyleType.REGULAR]: SvgFacebookRegularDark,
                [SocialStyleType.CIRCLE_FILL]: SvgFacebookCircleFillDark,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgFacebookCircleKnockoutDark,
                [SocialStyleType.SQUARE_FILL]: SvgFacebookSquareFillDark,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgFacebookSquareKnockoutDark,
                [SocialStyleType.ROUNDED_FILL]: SvgFacebookRoundedFillDark,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgFacebookRoundedKnockoutDark,
            },
            // @ts-ignore
            [SocialColorSourceType.LIGHT]: {
                [SocialStyleType.REGULAR]: SvgFacebookRegularLight,
                [SocialStyleType.CIRCLE_FILL]: SvgFacebookCircleFillLight,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgFacebookCircleKnockoutLight,
                [SocialStyleType.SQUARE_FILL]: SvgFacebookSquareFillLight,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgFacebookSquareKnockoutLight,
                [SocialStyleType.ROUNDED_FILL]: SvgFacebookRoundedFillLight,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgFacebookRoundedKnockoutLight,
            },
            // @ts-ignore
            [SocialColorSourceType.MULTI_COLOR]: {
                [SocialStyleType.REGULAR]: SvgFacebookRegularMulticolor,
                [SocialStyleType.CIRCLE_FILL]: SvgFacebookCircleFillMulticolor,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgFacebookCircleKnockoutMulticolor,
                [SocialStyleType.SQUARE_FILL]: SvgFacebookSquareFillMulticolor,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgFacebookSquareKnockoutMulticolor,
                [SocialStyleType.ROUNDED_FILL]: SvgFacebookRoundedFillMulticolor,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgFacebookRoundedKnockoutMulticolor,
            },
        },
        title: 'Facebook',
        urlPlaceholder: 'https://facebook.com/',
        color: '#1976d2',
        linkRegex: /^(https?:\/\/)?(www\.)?(facebook|fb)\.com\/[^\s]+$/i,
        editUrlDialogTitle: 'msg: social.editUrlDialogTitle.facebook {Edit Facebook link}',
        deleteUrlDialogTitle: 'msg: social.deleteUrlDialogTitle.facebook {Delete Facebook link?}',
        editInputLabel: 'msg: social.editInputLabel.facebook {Facebook URL}',
        editValidationErrMsg: 'msg: social.editUrl.validationErr.facebook {Add a valid Facebook link and try again.}'
    },
    {
        kind: SocialPlatformTypes.FOURSQUARE,
        svgCmpsByColorByType: {
            // @ts-ignore
            [SocialColorSourceType.DARK]: {
                [SocialStyleType.REGULAR]: SvgFoursquareRegularDark,
                [SocialStyleType.CIRCLE_FILL]: SvgFoursquareCircleFillDark,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgFoursquareCircleKnockoutDark,
                [SocialStyleType.SQUARE_FILL]: SvgFoursquareSquareFillDark,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgFoursquareSquareKnockoutDark,
                [SocialStyleType.ROUNDED_FILL]: SvgFoursquareRoundedFillDark,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgFoursquareRoundedKnockoutDark,
            },
            // @ts-ignore
            [SocialColorSourceType.LIGHT]: {
                [SocialStyleType.REGULAR]: SvgFoursquareRegularLight,
                [SocialStyleType.CIRCLE_FILL]: SvgFoursquareCircleFillLight,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgFoursquareCircleKnockoutLight,
                [SocialStyleType.SQUARE_FILL]: SvgFoursquareSquareFillLight,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgFoursquareSquareKnockoutLight,
                [SocialStyleType.ROUNDED_FILL]: SvgFoursquareRoundedFillLight,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgFoursquareRoundedKnockoutLight,
            },
            // @ts-ignore
            [SocialColorSourceType.MULTI_COLOR]: {
                [SocialStyleType.REGULAR]: SvgFoursquareRegularMulticolor,
                [SocialStyleType.CIRCLE_FILL]: SvgFoursquareCircleFillMulticolor,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgFoursquareCircleKnockoutMulticolor,
                [SocialStyleType.SQUARE_FILL]: SvgFoursquareSquareFillMulticolor,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgFoursquareSquareKnockoutMulticolor,
                [SocialStyleType.ROUNDED_FILL]: SvgFoursquareRoundedFillMulticolor,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgFoursquareRoundedKnockoutMulticolor,
            },
        },
        title: 'Foursquare',
        urlPlaceholder: 'https://foursquare.com/',
        color: '#f94877',
        linkRegex: /^(http(s)?:\/\/)?(.*\.)?foursquare\.com\/[^\s]+\/?/i,
        editUrlDialogTitle: 'msg: social.editUrlDialogTitle.foursquare {Edit Foursquare link}',
        deleteUrlDialogTitle: 'msg: social.deleteUrlDialogTitle.foursquare {Delete Foursquare link?}',
        editInputLabel: 'msg: social.editInputLabel.foursquare {Foursquare URL}',
        editValidationErrMsg: 'msg: social.editUrl.validationErr.foursquare {Add a valid Foursquare link and try again.}'
    },
    {
        kind: SocialPlatformTypes.INSTAGRAM,
        svgCmpsByColorByType: {
            // @ts-ignore
            [SocialColorSourceType.DARK]: {
                [SocialStyleType.REGULAR]: SvgInstagramRegularDark,
                [SocialStyleType.CIRCLE_FILL]: SvgInstagramCircleFillDark,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgInstagramCircleKnockoutDark,
                [SocialStyleType.SQUARE_FILL]: SvgInstagramSquareFillDark,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgInstagramSquareKnockoutDark,
                [SocialStyleType.ROUNDED_FILL]: SvgInstagramRoundedFillDark,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgInstagramRoundedKnockoutDark,
            },
            // @ts-ignore
            [SocialColorSourceType.LIGHT]: {
                [SocialStyleType.REGULAR]: SvgInstagramRegularLight,
                [SocialStyleType.CIRCLE_FILL]: SvgInstagramCircleFillLight,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgInstagramCircleKnockoutLight,
                [SocialStyleType.SQUARE_FILL]: SvgInstagramSquareFillLight,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgInstagramSquareKnockoutLight,
                [SocialStyleType.ROUNDED_FILL]: SvgInstagramRoundedFillLight,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgInstagramRoundedKnockoutLight,
            },
            // @ts-ignore
            [SocialColorSourceType.MULTI_COLOR]: {
                [SocialStyleType.REGULAR]: SvgInstagramRegularMulticolor,
                [SocialStyleType.CIRCLE_FILL]: SvgInstagramCircleFillMulticolor,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgInstagramCircleKnockoutMulticolor,
                [SocialStyleType.SQUARE_FILL]: SvgInstagramSquareFillMulticolor,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgInstagramSquareKnockoutMulticolor,
                [SocialStyleType.ROUNDED_FILL]: SvgInstagramRoundedFillMulticolor,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgInstagramRoundedKnockoutMulticolor,
            },
        },
        title: 'Instagram',
        urlPlaceholder: 'https://instagram.com/',
        color: '#833ab4',
        // eslint-disable-next-line max-len
        linkRegex: /^(https?:\/\/)?(www\.)?instagram\.com\/[^\s]+\/?/i,
        editUrlDialogTitle: 'msg: social.editUrlDialogTitle.instagram {Edit Instagram link}',
        deleteUrlDialogTitle: 'msg: social.deleteUrlDialogTitle.instagram {Delete Instagram link?}',
        editInputLabel: 'msg: social.editInputLabel.instagram {Instagram URL}',
        editValidationErrMsg: 'msg: social.editUrl.validationErr.instagram {Add a valid Instagram link and try again.}'
    },
    {
        kind: SocialPlatformTypes.LINKEDIN,
        svgCmpsByColorByType: {
            // @ts-ignore
            [SocialColorSourceType.DARK]: {
                [SocialStyleType.REGULAR]: SvgLinkedinRegularDark,
                [SocialStyleType.CIRCLE_FILL]: SvgLinkedinCircleFillDark,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgLinkedinCircleKnockoutDark,
                [SocialStyleType.SQUARE_FILL]: SvgLinkedinSquareFillDark,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgLinkedinSquareKnockoutDark,
                [SocialStyleType.ROUNDED_FILL]: SvgLinkedinRoundedFillDark,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgLinkedinRoundedKnockoutDark,
            },
            // @ts-ignore
            [SocialColorSourceType.LIGHT]: {
                [SocialStyleType.REGULAR]: SvgLinkedinRegularLight,
                [SocialStyleType.CIRCLE_FILL]: SvgLinkedinCircleFillLight,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgLinkedinCircleKnockoutLight,
                [SocialStyleType.SQUARE_FILL]: SvgLinkedinSquareFillLight,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgLinkedinSquareKnockoutLight,
                [SocialStyleType.ROUNDED_FILL]: SvgLinkedinRoundedFillLight,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgLinkedinRoundedKnockoutLight,
            },
            // @ts-ignore
            [SocialColorSourceType.MULTI_COLOR]: {
                [SocialStyleType.REGULAR]: SvgLinkedinRegularMulticolor,
                [SocialStyleType.CIRCLE_FILL]: SvgLinkedinCircleFillMulticolor,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgLinkedinCircleKnockoutMulticolor,
                [SocialStyleType.SQUARE_FILL]: SvgLinkedinSquareFillMulticolor,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgLinkedinSquareKnockoutMulticolor,
                [SocialStyleType.ROUNDED_FILL]: SvgLinkedinRoundedFillMulticolor,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgLinkedinRoundedKnockoutMulticolor,
            },
        },
        title: 'LinkedIn',
        urlPlaceholder: 'https://linkedin.com/in/',
        color: '#0077b5',
        linkRegex: /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/[a-z]+\/[^\s]+\/?/i,
        editUrlDialogTitle: 'msg: social.editUrlDialogTitle.linkedIn {Edit LinkedIn link}',
        deleteUrlDialogTitle: 'msg: social.deleteUrlDialogTitle.linkedIn {Delete LinkedIn link?}',
        editInputLabel: 'msg: social.editInputLabel.linkedin {LinkedIn URL}',
        editValidationErrMsg: 'msg: social.editUrl.validationErr.linkedin {Add a valid LinkedIn link and try again.}'
    },
    {
        kind: SocialPlatformTypes.PINTEREST,
        svgCmpsByColorByType: {
            // @ts-ignore
            [SocialColorSourceType.DARK]: {
                [SocialStyleType.REGULAR]: SvgPinterestRegularDark,
                [SocialStyleType.CIRCLE_FILL]: SvgPinterestCircleFillDark,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgPinterestCircleKnockoutDark,
                [SocialStyleType.SQUARE_FILL]: SvgPinterestSquareFillDark,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgPinterestSquareKnockoutDark,
                [SocialStyleType.ROUNDED_FILL]: SvgPinterestRoundedFillDark,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgPinterestRoundedKnockoutDark,
            },
            // @ts-ignore
            [SocialColorSourceType.LIGHT]: {
                [SocialStyleType.REGULAR]: SvgPinterestRegularLight,
                [SocialStyleType.CIRCLE_FILL]: SvgPinterestCircleFillLight,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgPinterestCircleKnockoutLight,
                [SocialStyleType.SQUARE_FILL]: SvgPinterestSquareFillLight,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgPinterestSquareKnockoutLight,
                [SocialStyleType.ROUNDED_FILL]: SvgPinterestRoundedFillLight,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgPinterestRoundedKnockoutLight,
            },
            // @ts-ignore
            [SocialColorSourceType.MULTI_COLOR]: {
                [SocialStyleType.REGULAR]: SvgPinterestRegularMulticolor,
                [SocialStyleType.CIRCLE_FILL]: SvgPinterestCircleFillMulticolor,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgPinterestCircleKnockoutMulticolor,
                [SocialStyleType.SQUARE_FILL]: SvgPinterestSquareFillMulticolor,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgPinterestSquareKnockoutMulticolor,
                [SocialStyleType.ROUNDED_FILL]: SvgPinterestRoundedFillMulticolor,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgPinterestRoundedKnockoutMulticolor,
            },
        },
        title: 'Pinterest',
        urlPlaceholder: 'https://pinterest.com/',
        color: '#d32f2f',
        linkRegex: /^(http(s)?:\/\/)?((w){3}.)?pinterest.com?\/.+/i,
        editUrlDialogTitle: 'msg: social.editUrlDialogTitle.pinterest {Edit Pinterest link}',
        deleteUrlDialogTitle: 'msg: social.deleteUrlDialogTitle.pinterest {Delete Pinterest link?}',
        editInputLabel: 'msg: social.editInputLabel.pinterest {Pinterest URL}',
        editValidationErrMsg: 'msg: social.editUrl.validationErr.pinterest {Add a valid Pinterest link and try again.}'
    },
    {
        kind: SocialPlatformTypes.REDDIT,
        svgCmpsByColorByType: {
            // @ts-ignore
            [SocialColorSourceType.DARK]: {
                [SocialStyleType.REGULAR]: SvgRedditRegularDark,
                [SocialStyleType.CIRCLE_FILL]: SvgRedditCircleFillDark,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgRedditCircleKnockoutDark,
                [SocialStyleType.SQUARE_FILL]: SvgRedditSquareFillDark,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgRedditSquareKnockoutDark,
                [SocialStyleType.ROUNDED_FILL]: SvgRedditRoundedFillDark,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgRedditRoundedKnockoutDark,
            },
            // @ts-ignore
            [SocialColorSourceType.LIGHT]: {
                [SocialStyleType.REGULAR]: SvgRedditRegularLight,
                [SocialStyleType.CIRCLE_FILL]: SvgRedditCircleFillLight,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgRedditCircleKnockoutLight,
                [SocialStyleType.SQUARE_FILL]: SvgRedditSquareFillLight,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgRedditSquareKnockoutLight,
                [SocialStyleType.ROUNDED_FILL]: SvgRedditRoundedFillLight,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgRedditRoundedKnockoutLight,
            },
            // @ts-ignore
            [SocialColorSourceType.MULTI_COLOR]: {
                [SocialStyleType.REGULAR]: SvgRedditRegularMulticolor,
                [SocialStyleType.CIRCLE_FILL]: SvgRedditCircleFillMulticolor,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgRedditCircleKnockoutMulticolor,
                [SocialStyleType.SQUARE_FILL]: SvgRedditSquareFillMulticolor,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgRedditSquareKnockoutMulticolor,
                [SocialStyleType.ROUNDED_FILL]: SvgRedditRoundedFillMulticolor,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgRedditRoundedKnockoutMulticolor,
            },
        },
        title: 'Reddit',
        urlPlaceholder: 'https://reddit.com/',
        color: '#ff4500',
        linkRegex: /^(http(s)?:\/\/)?([\w]+\.)?reddit\.com\/[^\s]+\/?/i,
        editUrlDialogTitle: 'msg: social.editUrlDialogTitle.reddit {Edit Reddit link}',
        deleteUrlDialogTitle: 'msg: social.deleteUrlDialogTitle.reddit {Delete Reddit link?}',
        editInputLabel: 'msg: social.editInputLabel.reddit {Reddit URL}',
        editValidationErrMsg: 'msg: social.editUrl.validationErr.reddit {Add a valid Reddit link and try again.}'
    },
    {
        kind: SocialPlatformTypes.SNAPCHAT,
        svgCmpsByColorByType: {
            // @ts-ignore
            [SocialColorSourceType.DARK]: {
                [SocialStyleType.REGULAR]: SvgSnapchatRegularDark,
                [SocialStyleType.CIRCLE_FILL]: SvgSnapchatCircleFillDark,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgSnapchatCircleKnockoutDark,
                [SocialStyleType.SQUARE_FILL]: SvgSnapchatSquareFillDark,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgSnapchatSquareKnockoutDark,
                [SocialStyleType.ROUNDED_FILL]: SvgSnapchatRoundedFillDark,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgSnapchatRoundedKnockoutDark,
            },
            // @ts-ignore
            [SocialColorSourceType.LIGHT]: {
                [SocialStyleType.REGULAR]: SvgSnapchatRegularLight,
                [SocialStyleType.CIRCLE_FILL]: SvgSnapchatCircleFillLight,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgSnapchatCircleKnockoutLight,
                [SocialStyleType.SQUARE_FILL]: SvgSnapchatSquareFillLight,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgSnapchatSquareKnockoutLight,
                [SocialStyleType.ROUNDED_FILL]: SvgSnapchatRoundedFillLight,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgSnapchatRoundedKnockoutLight,
            },
            // @ts-ignore
            [SocialColorSourceType.MULTI_COLOR]: {
                [SocialStyleType.REGULAR]: SvgSnapchatRegularMulticolor,
                [SocialStyleType.CIRCLE_FILL]: SvgSnapchatCircleFillMulticolor,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgSnapchatCircleKnockoutMulticolor,
                [SocialStyleType.SQUARE_FILL]: SvgSnapchatSquareFillMulticolor,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgSnapchatSquareKnockoutMulticolor,
                [SocialStyleType.ROUNDED_FILL]: SvgSnapchatRoundedFillMulticolor,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgSnapchatRoundedKnockoutMulticolor,
            },
        },
        title: 'Snapchat',
        urlPlaceholder: 'https://snapchat.com/',
        color: '#fffc00',
        linkRegex: /^(http(s)?:\/\/)?(.*\.)?snapchat\.com\/[^\s]+\/?/i,
        editUrlDialogTitle: 'msg: social.editUrlDialogTitle.snapchat {Edit Snapchat link}',
        deleteUrlDialogTitle: 'msg: social.deleteUrlDialogTitle.snapchat {Delete Snapchat link?}',
        editInputLabel: 'msg: social.editInputLabel.snapchat {Snapchat URL}',
        editValidationErrMsg: 'msg: social.editUrl.validationErr.snapchat {Add a valid Snapchat link and try again.}'
    },
    {
        kind: SocialPlatformTypes.TWITTER,
        svgCmpsByColorByType: {
            // @ts-ignore
            [SocialColorSourceType.DARK]: {
                [SocialStyleType.REGULAR]: SvgTwitterRegularDark,
                [SocialStyleType.CIRCLE_FILL]: SvgTwitterCircleFillDark,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgTwitterCircleKnockoutDark,
                [SocialStyleType.SQUARE_FILL]: SvgTwitterSquareFillDark,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgTwitterSquareKnockoutDark,
                [SocialStyleType.ROUNDED_FILL]: SvgTwitterRoundedFillDark,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgTwitterRoundedKnockoutDark,
            },
            // @ts-ignore
            [SocialColorSourceType.LIGHT]: {
                [SocialStyleType.REGULAR]: SvgTwitterRegularLight,
                [SocialStyleType.CIRCLE_FILL]: SvgTwitterCircleFillLight,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgTwitterCircleKnockoutLight,
                [SocialStyleType.SQUARE_FILL]: SvgTwitterSquareFillLight,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgTwitterSquareKnockoutLight,
                [SocialStyleType.ROUNDED_FILL]: SvgTwitterRoundedFillLight,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgTwitterRoundedKnockoutLight,
            },
            // @ts-ignore
            [SocialColorSourceType.MULTI_COLOR]: {
                [SocialStyleType.REGULAR]: SvgTwitterRegularMulticolor,
                [SocialStyleType.CIRCLE_FILL]: SvgTwitterCircleFillMulticolor,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgTwitterCircleKnockoutMulticolor,
                [SocialStyleType.SQUARE_FILL]: SvgTwitterSquareFillMulticolor,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgTwitterSquareKnockoutMulticolor,
                [SocialStyleType.ROUNDED_FILL]: SvgTwitterRoundedFillMulticolor,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgTwitterRoundedKnockoutMulticolor,
            },
        },
        title: 'Twitter',
        urlPlaceholder: 'https://x.com/',
        color: '#03a9f4',
        linkRegex: /^(?:https?:\/\/)?(?:twitter|x)\.com\/\w+/i,
        editUrlDialogTitle: 'msg: social.editUrlDialogTitle.twitter {Edit X link}',
        deleteUrlDialogTitle: 'msg: social.deleteUrlDialogTitle.twitter {Delete X link?}',
        editInputLabel: 'msg: social.editInputLabel.twitter {X URL}',
        editValidationErrMsg: 'msg: social.editUrl.validationErr.twitter {Add a valid X embed code and try again.}'
    },
    {
        kind: SocialPlatformTypes.TUMBLR,
        svgCmpsByColorByType: {
            // @ts-ignore
            [SocialColorSourceType.DARK]: {
                [SocialStyleType.REGULAR]: SvgTumblrRegularDark,
                [SocialStyleType.CIRCLE_FILL]: SvgTumblrCircleFillDark,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgTumblrCircleKnockoutDark,
                [SocialStyleType.SQUARE_FILL]: SvgTumblrSquareFillDark,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgTumblrSquareKnockoutDark,
                [SocialStyleType.ROUNDED_FILL]: SvgTumblrRoundedFillDark,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgTumblrRoundedKnockoutDark,
            },
            // @ts-ignore
            [SocialColorSourceType.LIGHT]: {
                [SocialStyleType.REGULAR]: SvgTumblrRegularLight,
                [SocialStyleType.CIRCLE_FILL]: SvgTumblrCircleFillLight,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgTumblrCircleKnockoutLight,
                [SocialStyleType.SQUARE_FILL]: SvgTumblrSquareFillLight,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgTumblrSquareKnockoutLight,
                [SocialStyleType.ROUNDED_FILL]: SvgTumblrRoundedFillLight,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgTumblrRoundedKnockoutLight,
            },
            // @ts-ignore
            [SocialColorSourceType.MULTI_COLOR]: {
                [SocialStyleType.REGULAR]: SvgTumblrRegularMulticolor,
                [SocialStyleType.CIRCLE_FILL]: SvgTumblrCircleFillMulticolor,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgTumblrCircleKnockoutMulticolor,
                [SocialStyleType.SQUARE_FILL]: SvgTumblrSquareFillMulticolor,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgTumblrSquareKnockoutMulticolor,
                [SocialStyleType.ROUNDED_FILL]: SvgTumblrRoundedFillMulticolor,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgTumblrRoundedKnockoutMulticolor,
            },
        },
        title: 'Tumblr',
        urlPlaceholder: 'https://tumblr.com/',
        color: '#35465d',
        linkRegex: /^(http(s)?:\/\/)?(.*\.)?tumblr\.com/i,
        editUrlDialogTitle: 'msg: social.editUrlDialogTitle.tumblr {Edit Tumblr link}',
        deleteUrlDialogTitle: 'msg: social.deleteUrlDialogTitle.tumblr {Delete Tumblr link?}',
        editInputLabel: 'msg: social.editInputLabel.tumblr {Tumblr URL}',
        editValidationErrMsg: 'msg: social.editUrl.validationErr.tumblr {Add a valid Tumblr link and try again.}'
    },
    {
        kind: SocialPlatformTypes.TRIPADVISOR,
        svgCmpsByColorByType: {
            // @ts-ignore
            [SocialColorSourceType.DARK]: {
                [SocialStyleType.REGULAR]: SvgTripadvisorRegularDark,
                [SocialStyleType.CIRCLE_FILL]: SvgTripadvisorCircleFillDark,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgTripadvisorCircleKnockoutDark,
                [SocialStyleType.SQUARE_FILL]: SvgTripadvisorSquareFillDark,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgTripadvisorSquareKnockoutDark,
                [SocialStyleType.ROUNDED_FILL]: SvgTripadvisorRoundedFillDark,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgTripadvisorRoundedKnockoutDark,
            },
            // @ts-ignore
            [SocialColorSourceType.LIGHT]: {
                [SocialStyleType.REGULAR]: SvgTripadvisorRegularLight,
                [SocialStyleType.CIRCLE_FILL]: SvgTripadvisorCircleFillLight,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgTripadvisorCircleKnockoutLight,
                [SocialStyleType.SQUARE_FILL]: SvgTripadvisorSquareFillLight,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgTripadvisorSquareKnockoutLight,
                [SocialStyleType.ROUNDED_FILL]: SvgTripadvisorRoundedFillLight,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgTripadvisorRoundedKnockoutLight,
            },
            // @ts-ignore
            [SocialColorSourceType.MULTI_COLOR]: {
                [SocialStyleType.REGULAR]: SvgTripadvisorRegularMulticolor,
                [SocialStyleType.CIRCLE_FILL]: SvgTripadvisorCircleFillMulticolor,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgTripadvisorCircleKnockoutMulticolor,
                [SocialStyleType.SQUARE_FILL]: SvgTripadvisorSquareFillMulticolor,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgTripadvisorSquareKnockoutMulticolor,
                [SocialStyleType.ROUNDED_FILL]: SvgTripadvisorRoundedFillMulticolor,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgTripadvisorRoundedKnockoutMulticolor,
            },
        },
        title: 'TripAdvisor',
        urlPlaceholder: 'https://tripadvisor.com/',
        color: '#00af87',
        linkRegex: /^(http(s)?:\/\/)?(.*\.)?tripadvisor\.com\/[^\s]+\/?/i,
        editUrlDialogTitle: 'msg: social.editUrlDialogTitle.tripadvisor {Edit TripAdvisor link}',
        deleteUrlDialogTitle: 'msg: social.deleteUrlDialogTitle.tripadvisor {Delete TripAdvisor link?}',
        editInputLabel: 'msg: social.editInputLabel.tripadvisor {TripAdvisor URL}',
        editValidationErrMsg: 'msg: social.editUrl.validationErr.tripadvisor {Add a valid TripAdvisor link and try again.}'
    },
    {
        kind: SocialPlatformTypes.VIMEO,
        svgCmpsByColorByType: {
            // @ts-ignore
            [SocialColorSourceType.DARK]: {
                [SocialStyleType.REGULAR]: SvgVimeoRegularDark,
                [SocialStyleType.CIRCLE_FILL]: SvgVimeoCircleFillDark,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgVimeoCircleKnockoutDark,
                [SocialStyleType.SQUARE_FILL]: SvgVimeoSquareFillDark,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgVimeoSquareKnockoutDark,
                [SocialStyleType.ROUNDED_FILL]: SvgVimeoRoundedFillDark,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgVimeoRoundedKnockoutDark,
            },
            // @ts-ignore
            [SocialColorSourceType.LIGHT]: {
                [SocialStyleType.REGULAR]: SvgVimeoRegularLight,
                [SocialStyleType.CIRCLE_FILL]: SvgVimeoCircleFillLight,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgVimeoCircleKnockoutLight,
                [SocialStyleType.SQUARE_FILL]: SvgVimeoSquareFillLight,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgVimeoSquareKnockoutLight,
                [SocialStyleType.ROUNDED_FILL]: SvgVimeoRoundedFillLight,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgVimeoRoundedKnockoutLight,
            },
            // @ts-ignore
            [SocialColorSourceType.MULTI_COLOR]: {
                [SocialStyleType.REGULAR]: SvgVimeoRegularMulticolor,
                [SocialStyleType.CIRCLE_FILL]: SvgVimeoCircleFillMulticolor,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgVimeoCircleKnockoutMulticolor,
                [SocialStyleType.SQUARE_FILL]: SvgVimeoSquareFillMulticolor,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgVimeoSquareKnockoutMulticolor,
                [SocialStyleType.ROUNDED_FILL]: SvgVimeoRoundedFillMulticolor,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgVimeoRoundedKnockoutMulticolor,
            },
        },
        title: 'Vimeo',
        urlPlaceholder: 'https://vimeo.com/',
        color: '#1ab7ea',
        linkRegex: /^(http(s)?:\/\/)?(.*\.)?vimeo\.com\/[^\s]+\/?/i,
        editUrlDialogTitle: 'msg: social.editUrlDialogTitle.vimeo {Edit Vimeo link}',
        deleteUrlDialogTitle: 'msg: social.deleteUrlDialogTitle.vimeo {Delete Vimeo link?}',
        editInputLabel: 'msg: social.editInputLabel.vimeo {Vimeo URL}',
        editValidationErrMsg: 'msg: social.editUrl.validationErr.vimeo {Add a valid Vimeo link and try again.}'
    },
    {
        kind: SocialPlatformTypes.YOUTUBE,
        svgCmpsByColorByType: {
            // @ts-ignore
            [SocialColorSourceType.DARK]: {
                [SocialStyleType.REGULAR]: SvgYoutubeRegularDark,
                [SocialStyleType.CIRCLE_FILL]: SvgYoutubeCircleFillDark,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgYoutubeCircleKnockoutDark,
                [SocialStyleType.SQUARE_FILL]: SvgYoutubeSquareFillDark,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgYoutubeSquareKnockoutDark,
                [SocialStyleType.ROUNDED_FILL]: SvgYoutubeRoundedFillDark,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgYoutubeRoundedKnockoutDark,
            },
            // @ts-ignore
            [SocialColorSourceType.LIGHT]: {
                [SocialStyleType.REGULAR]: SvgYoutubeRegularLight,
                [SocialStyleType.CIRCLE_FILL]: SvgYoutubeCircleFillLight,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgYoutubeCircleKnockoutLight,
                [SocialStyleType.SQUARE_FILL]: SvgYoutubeSquareFillLight,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgYoutubeSquareKnockoutLight,
                [SocialStyleType.ROUNDED_FILL]: SvgYoutubeRoundedFillLight,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgYoutubeRoundedKnockoutLight,
            },
            // @ts-ignore
            [SocialColorSourceType.MULTI_COLOR]: {
                [SocialStyleType.REGULAR]: SvgYoutubeRegularMulticolor,
                [SocialStyleType.CIRCLE_FILL]: SvgYoutubeCircleFillMulticolor,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgYoutubeCircleKnockoutMulticolor,
                [SocialStyleType.SQUARE_FILL]: SvgYoutubeSquareFillMulticolor,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgYoutubeSquareKnockoutMulticolor,
                [SocialStyleType.ROUNDED_FILL]: SvgYoutubeRoundedFillMulticolor,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgYoutubeRoundedKnockoutMulticolor,
            },
        },
        title: 'YouTube',
        urlPlaceholder: 'https://youtube.com/',
        color: '#f44336',
        linkRegex: /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/i,
        editUrlDialogTitle: 'msg: social.editUrlDialogTitle.youtube {Edit YouTube link}',
        deleteUrlDialogTitle: 'msg: social.deleteUrlDialogTitle.youtube {Delete YouTube link?}',
        editInputLabel: 'msg: social.editInputLabel.youtube {YouTube URL}',
        editValidationErrMsg: 'msg: social.editUrl.validationErr.youtube {Add a valid YouTube link and try again.}'
    },
    {
        kind: SocialPlatformTypes.YELP,
        svgCmpsByColorByType: {
            // @ts-ignore
            [SocialColorSourceType.DARK]: {
                [SocialStyleType.REGULAR]: SvgYelpRegularDark,
                [SocialStyleType.CIRCLE_FILL]: SvgYelpCircleFillDark,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgYelpCircleKnockoutDark,
                [SocialStyleType.SQUARE_FILL]: SvgYelpSquareFillDark,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgYelpSquareKnockoutDark,
                [SocialStyleType.ROUNDED_FILL]: SvgYelpRoundedFillDark,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgYelpRoundedKnockoutDark,
            },
            // @ts-ignore
            [SocialColorSourceType.LIGHT]: {
                [SocialStyleType.REGULAR]: SvgYelpRegularLight,
                [SocialStyleType.CIRCLE_FILL]: SvgYelpCircleFillLight,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgYelpCircleKnockoutLight,
                [SocialStyleType.SQUARE_FILL]: SvgYelpSquareFillLight,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgYelpSquareKnockoutLight,
                [SocialStyleType.ROUNDED_FILL]: SvgYelpRoundedFillLight,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgYelpRoundedKnockoutLight,
            },
            // @ts-ignore
            [SocialColorSourceType.MULTI_COLOR]: {
                [SocialStyleType.REGULAR]: SvgYelpRegularMulticolor,
                [SocialStyleType.CIRCLE_FILL]: SvgYelpCircleFillMulticolor,
                [SocialStyleType.CIRCLE_KNOCKOUT]: SvgYelpCircleKnockoutMulticolor,
                [SocialStyleType.SQUARE_FILL]: SvgYelpSquareFillMulticolor,
                [SocialStyleType.SQUARE_KNOCKOUT]: SvgYelpSquareKnockoutMulticolor,
                [SocialStyleType.ROUNDED_FILL]: SvgYelpRoundedFillMulticolor,
                [SocialStyleType.ROUNDED_KNOCKOUT]: SvgYelpRoundedKnockoutMulticolor,
            },
        },
        title: 'Yelp',
        urlPlaceholder: 'https://yelp.com/',
        color: '#c41200',
        linkRegex: /^(http(s)?:\/\/)?(.*\.)?yelp\.com\/[^\s]+\/?/i,
        editUrlDialogTitle: 'msg: social.editUrlDialogTitle.yelp {Edit Yelp link}',
        deleteUrlDialogTitle: 'msg: social.deleteUrlDialogTitle.yelp {Delete Yelp link?}',
        editInputLabel: 'msg: social.editInputLabel.yelp {Yelp URL}',
        editValidationErrMsg: 'msg: social.editUrl.validationErr.yelp {Add a valid Yelp link and try again.}'
    },
];

export const socialLinksConfigsMap: Record<string, SocialLinksConfig> =
    socialLinksConfigs.reduce((m, c) => ({ ...m, [c.kind]: c }), {});
