import type { AnyComponentData } from "../../../../src/components/App/flowTypes";
import type { AnyComponent } from "../../../../src/components/oneweb/flowTypes";
import correctRelationsOfCodeCmpInHead from "./correctRelationsOfCodeCmpInHead";

const relInMapper = {
    to: (componentData: AnyComponentData) => ({
        relIn: correctRelationsOfCodeCmpInHead(componentData, componentData.relIn) || null
    }),
    back: (component: AnyComponent) => ({ relIn: component.relIn }),
};

export default relInMapper;
