
export const
    PinterestSDK = "https://assets.pinterest.com/js/pinit.js",
    defaultState = {
        link: "",
        mobileHide: false,
    },
    PinterestTypes = {
        pin: 'embedPin',
        user: 'embedUser',
        board: 'embedBoard',
    },
    PinterestDimensions = {
        [PinterestTypes.pin]: { width: 240, height: 420, },
        [PinterestTypes.user]: { width: 730, height: 290, },
        [PinterestTypes.board]: { width: 420, height: 420, },
    },
    // Should be defaulting to pin
    DEFAULT_WIDTH = 240,
    DEFAULT_HEIGHT = 420;
