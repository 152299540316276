import getCenteredDialogConfig from "../../DialogManager/getCenteredDialogConfig";
import { pageTemplateSelectorReducer } from "./reducer/pageTemplateSelectorReducer";
import { makeDefaultState } from "./reducer/makeDefaultState";
import { PageTemplateSelectorCom } from "./components/PageTemplateSelectorCom";
import { stateIsEqInitialComparator } from "./reducer/stateIsEqInitialComparator";

const
    WIDTH = 900,
    HEIGHT = 600;

export default getCenteredDialogConfig({
    width: WIDTH,
    height: HEIGHT,
    component: PageTemplateSelectorCom,
    reducer: pageTemplateSelectorReducer,
    makeDefaultState,
    addIsEqToInitialStateFlag: true,
    stateIsEqInitialComparator
});
