import * as React from 'react';
import cx from 'classnames';
import { injectIntl, MsgJointInput, Intl } from "../../../intl/index";
import { PrimaryButton, SecondaryButton } from "../../Button/index";
import noMouseEventPropagation from "../../NoMouseEventsPropagation";
import styles from './Dialog.css';
import { border } from '../dialogs.css';
import { DemoTip } from "../../../../../demo/modules/tip/DemoTip";

export type PropTypes = {
    intl: Intl,
    mctaHandler ?: () => void,
    mctaText ?: MsgJointInput,
    sctaHandler?: (() => void) | null,
    sctaText?: MsgJointInput,
    tctaHandler?: () => void,
    tctaText?: MsgJointInput,
    disabled?: boolean,
    contentClassName?: string;
    footerButtonContainerClassName?: string;
    tctaButtonClassName?: string;
    noTopBorder?: boolean;
    height?: string;
    justifyCenter?: boolean;
    autoFocus?: boolean;
    primaryBtnClass?: string;
    sctaBtnClass?: string;
    className?: string;
    children?: React.ReactNode,
    isRestrictedForDemo?: boolean,
    isBtnLarge?: boolean,
    footerV2 ?: boolean,
}

export default injectIntl(({
    mctaHandler,
    sctaHandler,
    tctaHandler,
    mctaText,
    sctaText = 'msg: common.cancel {Cancel}',
    tctaText = '',
    disabled,
    contentClassName,
    noTopBorder,
    height,
    justifyCenter,
    children,
    primaryBtnClass,
    sctaBtnClass,
    className,
    autoFocus = true,
    footerButtonContainerClassName,
    tctaButtonClassName,
    isRestrictedForDemo,
    isBtnLarge = false,
    footerV2 = false,
    intl,
}: PropTypes) => {
    let style: React.CSSProperties = {};
    if (!footerV2 && !noTopBorder) {
        style.borderTop = border;
    }
    if (justifyCenter) {
        style.justifyContent = 'center';
    }
    if (height) {
        style.height = height;
    }

    return (
        <div className={cx(styles.footer, className, { [`${styles.footerV2}`]: footerV2 })} style={style}>
            <div className={cx(styles.footerContent, contentClassName)}>{children}</div>
            <div className={cx(styles.footerButtonContainer, footerButtonContainerClassName)}>
                {
                    tctaHandler && tctaText.length &&
                    <SecondaryButton
                        onClick={tctaHandler}
                        {...noMouseEventPropagation}
                        className={tctaButtonClassName}
                        isLarge={isBtnLarge}
                    >
                        {intl.msgJoint(tctaText)}
                    </SecondaryButton>
                }
                {
                    sctaHandler &&
                    <SecondaryButton
                        onClick={sctaHandler}
                        {...noMouseEventPropagation}
                        className={cx(styles.footerButton, sctaBtnClass, { [`${styles.secondaryBtnV2}`]: footerV2 })}
                        isLarge={isBtnLarge}
                    >
                        {intl.msgJoint(sctaText)}
                    </SecondaryButton>
                }
                {mctaHandler && (
                    // @ts-ignore
                    <DemoTip isDisabled={!isRestrictedForDemo}>
                        <PrimaryButton
                            onClick={mctaHandler}
                            disabled={disabled}
                            {...noMouseEventPropagation}
                            className={cx(styles.footerButton, primaryBtnClass)}
                            autoFocus={autoFocus}
                            isLarge={isBtnLarge}
                        >
                            { /* @ts-ignore */ }
                            {intl.msgJoint(mctaText)}
                        </PrimaryButton>
                    </DemoTip>
                )}
            </div>
        </div>
    );
});
