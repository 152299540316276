import { DataSite, DataPageRef } from "../../../../dal/model";
import { isLinkPage, isSectionLink } from "../../../../dal/model/utils/dataSiteItemUtils";
import type { DataSiteItem } from "../../../../dal/model/utils/dataSiteItemUtils"; // eslint-disable-line

function insertTo(this: DataSiteItem, page: DataSiteItem): DataSiteItem {
    if (isLinkPage(page) || isSectionLink(page)) return page;

    let parent = new DataPageRef(page); // copy object

    // @ts-ignore
    if (this.parentPageId === parent.pageId) {
        // @ts-ignore
        parent.items.push(this.newPage);
    }
    parent.items = parent.items.map(insertTo, this);

    return parent;
}

export default (page: DataSiteItem, siteData: DataSite, parentPageId: string | null | undefined): DataSite => {
    let newSiteData = new DataSite(siteData);

    if (parentPageId) {
        const that = { newPage: page, parentPageId };
        newSiteData.folder.items = siteData.folder.items.map(insertTo, that);
    } else {
        newSiteData.folder.items.push(page);
    }

    return newSiteData;
};
