import React from "react";
export const SvgRedditRoundedFillLight = props => (
    <svg viewBox="0 0 48 48" {...props}>
        <g fill="none">
            <rect width={48} height={48} fill="#ffffff" rx={6} />
            <path
                fill="#3c3c3c"
                d="M17.952 24.904a1.488 1.488 0 112.976 0 1.488 1.488 0 01-2.976 0zm8.928 0a1.488 1.488 0 112.976 0 1.488 1.488 0 01-2.976 0zm.072 3.18a.883.883 0 111.094 1.388c-1.067.84-2.693 1.384-4.142 1.384-1.449 0-3.075-.543-4.142-1.384a.884.884 0 011.094-1.388c.614.484 1.83 1.005 3.048 1.005s2.434-.52 3.048-1.005zm8.856-6.156a2.976 2.976 0 00-5.576-1.446c-1.53-.837-3.394-1.377-5.424-1.502l1.776-3.988 3.399.981a2.233 2.233 0 10.14-1.799l-3.788-1.093a.883.883 0 00-1.052.49l-2.413 5.417c-1.98.14-3.796.675-5.293 1.494A2.975 2.975 0 0012 21.928c0 1.216.73 2.261 1.776 2.723a5.45 5.45 0 00-.288 1.741c0 4.11 4.663 7.44 10.416 7.44s10.416-3.33 10.416-7.44c0-.6-.1-1.182-.287-1.741a2.976 2.976 0 001.775-2.723zm-3.72-7.533a.837.837 0 110 1.674.837.837 0 010-1.674zm-18.6 7.533c0-.82.668-1.488 1.488-1.488a1.49 1.49 0 011.345.852c-.778.59-1.422 1.27-1.899 2.017a1.49 1.49 0 01-.934-1.381zm10.416 10.137c-4.777 0-8.649-2.54-8.649-5.673s3.872-5.673 8.65-5.673c4.776 0 8.648 2.54 8.648 5.673s-3.872 5.673-8.649 5.673zm9.482-8.756c-.477-.747-1.121-1.427-1.899-2.017a1.49 1.49 0 011.345-.852c.82 0 1.488.668 1.488 1.488a1.49 1.49 0 01-.934 1.38z"
            />
        </g>
    </svg>
);
