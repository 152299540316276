import React, { useRef, useState, useLayoutEffect } from 'react';
import { Box, FormControl, Tooltip } from "@mui/material";

import { useStyles } from "./styles";
import imageStyles from "../../../../Images/imageStyle.css";

import { useIntl } from "../../../../../../../../wbtgen/src/view/intl";
import type { ImageAsset } from "../../../../../../../../wbtgen/src/components/App/flowTypes";
import { getImageSrc } from "../../../../../../../../wbtgen/src/components/oneweb/Image/utils";

type LogoSettingsImagePreviewProps = {
    asset: ImageAsset | null | undefined,
    onChange: Function,
    onRemove: Function,
}

const ImageHover = ({ onRemove }) => {
    const classes = useStyles();
    const [hover, setHover] = useState(false);

    return (
        <div
            className={classes.imageHoverContainer}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {
                hover &&
                <span
                    className={classes.imageCloseIcon}
                    onClick={(e) => onRemove(e)}
                >
                    <span className={imageStyles.solidCancelIcon} />
                </span>
            }
        </div>
    );
};

export const LogoSettingsImagePreview = (props: LogoSettingsImagePreviewProps) => {
    const
        { asset, onChange, onRemove } = props,
        classes = useStyles(),
        intl = useIntl(),
        previewContainerRef = useRef<HTMLDivElement>(null),
        [imageSrc, setImageSrc] = useState<string | null>(null);

    const onAssetRemove = (e) => {
        setImageSrc(null);
        onRemove(e);
    };

    useLayoutEffect(() => {
        if (asset && previewContainerRef && previewContainerRef.current) {
            let previewHeight = previewContainerRef.current.offsetHeight;
            let previewWidth = asset.width * (previewHeight / asset.height);
            const { src } = asset ? getImageSrc(asset, previewHeight, previewWidth) : { src: null };
            setImageSrc(src);
        } else if (asset === null) {
            setImageSrc(null);
        }
    }, [asset]);

    return (
        <Box>
            <FormControl fullWidth>
                <div
                    className={imageSrc ? classes.imageWrapperSelected : classes.imageWrapper}
                    onClick={() => onChange()}
                    ref={previewContainerRef}
                >
                    {
                        imageSrc ? (
                            <div className={classes.imagePreviewContainer}>
                                <ImageHover onRemove={onAssetRemove} />
                                <img src={imageSrc} className={classes.image} />
                            </div>
                        ) : (
                            <div className={classes.placeholderPreviewContainer}>
                                <div className={imageStyles.imagePreviewIcon} />
                                <div className={classes.imagePreviewText}>{intl.msgJoint("msg: common.selectFile {Select a file}")}</div>

                                <Tooltip
                                    arrow
                                    title={(
                                        <React.Fragment>
                                            {intl.msgJoint("msg: settings.logo.fileTypeRecommendation {For best results we recommend using a transparent .png file which is at least 200kb.}")}
                                            <br />
                                            {intl.msgJoint("msg: settings.logo.fileTypeOptions {You can use .jpg, .gif and .svg files.}")}
                                        </React.Fragment>
                                    )}
                                    enterTouchDelay={0}
                                    leaveTouchDelay={4000}
                                    classes={{
                                        tooltip: classes.tooltip,
                                        arrow: classes.tooltipArrow
                                    }}
                                >
                                    <div className={classes.imagePreviewSupportText}>{intl.msgJoint("msg: common.seeRecommendedFileTypes {See recommended file types}")}</div>
                                </Tooltip>
                            </div>
                        )
                    }
                </div>
            </FormControl>
        </Box>
    );
};
