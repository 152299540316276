import * as R from 'ramda';
import { MENU } from '../oneweb/componentKinds';
import type { SectionComponent } from "../oneweb/Section/flowTypes";
import type { ComponentsMap } from "../../redux/modules/children/workspace/flowTypes";
import { changeMenuHorizontalAlignment } from '../oneweb/Menu/changeMenuHorizontalAlignment';
import { H_ALIGN } from "./constants";
import { getFlattenedOptions, getMarginOrPaddingFromObj, findCmpkindIsExpandableInMHF } from './preview_utils';
import { memoMaxFixedNumOfArgs } from '../../../utils/memo';

const removeItemsFromArray = (arr, indexArr) => arr.filter((val, index) => (indexArr.indexOf(index) === -1)),
    cmpHAlignFnMap = {
        [MENU]: changeMenuHorizontalAlignment
    },
    getCmpHAlignFn = (kind: string) => cmpHAlignFnMap[kind] || (({ component }) => component);
const adjustComponentAlignment = (cmpId: string, position: string, componentsMap: ComponentsMap) => {
    let component = componentsMap[cmpId];
    if (component) {
        return { ...componentsMap, [cmpId]: getCmpHAlignFn(component.kind)({ position, component }) };
    }
    return componentsMap;
};

const getUpdatedLayoutBasedOnOptions = (section: SectionComponent, componentsMap: ComponentsMap = {}, updateCmpsMap: boolean = true) => {
    if (!section || !section.modernLayout) {
        return { newLayout: {}, cmpsMap: componentsMap };
    }
    if (!section.modernLayout.options) {
        return { newLayout: section.modernLayout.layout, cmpsMap: componentsMap };
    }
    const { layout, options } = section.modernLayout,
        flattenedOptions = getFlattenedOptions(options);
    let newLayout = R.clone(layout),
        cmpsMap = updateCmpsMap ? { ...componentsMap } : componentsMap;

    const processLayout = (rootId) => {
        const deleteRowsIndexes: any[] = [];
        if (!newLayout[rootId]) {
            return;
        }
        newLayout[rootId].rows.forEach((row, rowIndex) => {
            const deleteColsIndexes: any[] = [];
            row.cols.forEach((col, index) => {
                const cmps = R.clone(col.cmps),
                    { hPositioned } = col;
                let isExpandable = false;
                if (!cmps.length) {
                    return;
                }
                const filteredCmps = cmps.filter(({ id }) => flattenedOptions.filter(option => option.show)
                    .map(option => option.id).includes(id));
                if (filteredCmps.length) {
                    col.cmps = filteredCmps.map((cmp, i) => { // eslint-disable-line
                        if (i === 0) {
                            const { style = {} } = cmp;
                            const margin = getMarginOrPaddingFromObj(style.margin),
                                defaultMargin = {
                                    top: hPositioned ? margin.top : 0,
                                    left: hPositioned ? 0 : margin.left,
                                };
                            return {
                                ...cmp,
                                style: {
                                    margin: {
                                        ...margin,
                                        ...defaultMargin
                                    }
                                }
                            };
                        }
                        return cmp;
                    });
                    isExpandable = col.cmps.some(({ id: cmpId }) => {
                        const component = componentsMap[cmpId];
                        return !!component && findCmpkindIsExpandableInMHF(component.kind);
                    });
                } else {
                    deleteColsIndexes.push(index);
                }
                col.isExpandable = isExpandable; // eslint-disable-line
            });
            row.cols = removeItemsFromArray(row.cols, deleteColsIndexes); // eslint-disable-line
            if (row.cols.length === 1) {
                row.cols[0].hAlign = H_ALIGN.center; // eslint-disable-line
            } else if (row.cols.length === 2) {
                row.cols[0].hAlign = H_ALIGN.left; // eslint-disable-line
                row.cols[1].hAlign = H_ALIGN.right; // eslint-disable-line
            } else if (!row.cols.length) {
                deleteRowsIndexes.push(rowIndex);
            }
            row.cols.forEach(col => {
                col.cmps.forEach(({ id }) => {
                    if (updateCmpsMap) {
                        cmpsMap = adjustComponentAlignment(id, col.hAlign, cmpsMap);
                    }
                    processLayout(id);
                });
            });
        });
        newLayout[rootId].rows = removeItemsFromArray(newLayout[rootId].rows, deleteRowsIndexes);
        newLayout[rootId].rows = newLayout[rootId].rows.map((row, i) => {
            if (i === 0) {
                const { style = {} } = row,
                    margin = getMarginOrPaddingFromObj(style.margin);
                return { ...row, style: { ...style, margin: { ...margin, top: 0 } } };
            }
            return row;
        });
    };

    processLayout(section.id);

    return { newLayout, cmpsMap };
};

const memoGetUpdatedLayoutBasedOnOptions = memoMaxFixedNumOfArgs(getUpdatedLayoutBasedOnOptions, 3, 1);

export {
    getUpdatedLayoutBasedOnOptions as default,
    memoGetUpdatedLayoutBasedOnOptions
};
