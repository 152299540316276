import React from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { useIntl } from "../../../../../../wbtgen/src/view/intl/injectIntl";
import { UpdateStatusButton } from './UpdateStatusButton';

const useStyles = makeStyles(
    (theme) => ({
        actionButton: {
            padding: '6px 30px',
            color: theme.palette.custom.colorScienceBlue,
            transition: 'none',
            whiteSpace: 'break-spaces'
        },
    }),
    { name: "SettingsViewContainer" }
);

type SettingsViewContainerProps = {
    bgColor: string;
    header: {
        iconClass: string;
        text: string;
    }
    children: React.ReactElement | React.ReactElement[];
    actionButton: {
        label: string;
        url: string;
        action: Action;
    };
};

export const SettingsViewContainer = (props: SettingsViewContainerProps) => {
    const classes = useStyles();
    const intl = useIntl();
    const dispatch = useDispatch();

    return (
        <Box
            p="30px"
            borderRadius="5px"
            bgcolor={props.bgColor}
        >
            <Box display="flex" justifyContent="flex-end">
                <UpdateStatusButton />
            </Box>
            <Box
                marginTop="10px"
                display="flex"
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                flexDirection={{ xs: 'row', sm: 'row' }}
            >
                <Box
                    className={props.header.iconClass}
                    marginTop={{ xs: '3px' }}
                />
                <Box
                    component="span"
                    fontSize="18px"
                    fontWeight="400"
                    lineHeight="26px"
                    marginLeft={{ xs: '8px', sm: '8px' }}
                    marginTop={{ xs: '0px', sm: '0px' }}
                >
                    {intl.msgJoint(props.header.text)}
                </Box>
            </Box>
            {props.children}
            <Box
                marginTop="28px"
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                justifyContent="flex-start"
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                flexWrap="wrap"
            >
                <Button
                    variant="outlined"
                    className={classes.actionButton}
                    href={props.actionButton.url}
                    target="_blank"
                    onClick={() => dispatch(props.actionButton.action)}
                >
                    {intl.msgJoint(props.actionButton.label)}
                </Button>
            </Box>
        </Box>
    );
};
