import React from 'react';
import { ComponentMaskBackground } from "../../../presentational/ComponentMask/constants";
import ComponentMask from "../../../presentational/ComponentMask/index";
import parseComponent from '../parseComponent';
import FacebookPageBase from './FacebookPageBase';
import View from './index';

export default class FacebookPage extends FacebookPageBase {
    _fbPageWidget: any;

    componentDidUpdate() {
        parseComponent(this._fbPageWidget, this.props.width);
    }

    render() {
        const style = this.props.isMVEFocus ? { pointerEvents: 'none' } : {};
        return (
            <ComponentMask forceShowMask={false} maskStyle={ComponentMaskBackground.LIGHT}>
                { /* @ts-ignore */ }
                <div ref={(el) => { this._fbPageWidget = el; }} style={style}>
                    <View {...this.props} />
                </div>
            </ComponentMask>
        );
    }
}
