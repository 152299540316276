import React from 'react';

import styles from '../NewPageNameDialog.css';
import getCenteredDialogConfig from "../../../DialogManager/getCenteredDialogConfig";
import StandardDialog from '../../../../view/common/dialogs/StandardDialog';
import { newPageLayoutNameEnteredAction } from "../actionCreators";
import { Msg } from "../../../../view/intl";
import { makeInputReducer } from "../../../inputControls/input/reducerFactory";
import { NEW_PAGE_NAME_ON_CHANGE } from "./actionTypes";
import type { InputState } from "../../../inputControls/input/flowTypes";
import { Input } from "../../../inputControls/input/view";
import { closeNewPageNameDialogAction } from "../actions";
import { closeDialog } from "../../../App/actionCreators";
import { StripTypes } from '../../../../view/common/dialogs/baseDialog';
import { PAGE_INDEX_URL, PAGE_INDEX_HTML_URL } from "../../../../../dal/model/DataPageRef";
import blackListInputValidation from "../../../inputControls/input/commonValidations/blackListInputValidation";
import { containsHtmlExtensionInURL } from "../../Tree/dialogs/PageInfoDialog/reducer/containsHtmlExtensionInURL";
import { lengthInputValidation } from "../../../inputControls/input/commonValidations/lengthInputValidation";

    type Props = {
        state: InputState,
        dispatch: Dispatch;
    };

const NewPageNameDialog = ({ state, dispatch }: Props) => {
    const
        disabled = !state.isValid,
        mctaHandler = () => {
            if (disabled) return;
            dispatch(newPageLayoutNameEnteredAction(state.value));
        },
        sctaHandler = () => {
            // TODO: never do two dispatches !!! should be refactored into epic
            dispatch(closeNewPageNameDialogAction());
            dispatch(closeDialog());
        };

    return (
        <StandardDialog
            title="msg: common.newPage {New Page}"
            mctaText="msg: common.ok {OK}"
            mctaHandler={mctaHandler}
            sctaHandler={sctaHandler}
            onClose={sctaHandler}
            disabled={disabled}
            stripType={StripTypes.NONE}
            noTopBorderForFooter
        >
            <div className={styles.labelContainer}>
                <Msg k="pt.newPageNameTitle">Enter a name for the new page:</Msg>
            </div>
            <Input
                state={state}
                onChangeAction={NEW_PAGE_NAME_ON_CHANGE}
                dispatch={dispatch}
                autoFocus
                onEnterPress={mctaHandler}
            />
        </StandardDialog>
    );
};

export default getCenteredDialogConfig({
    width: 483,
    height: 308,
    component: NewPageNameDialog,
    reducer: makeInputReducer({
        actionType: NEW_PAGE_NAME_ON_CHANGE,
        validations: [
            {
                validation: blackListInputValidation,
                options: { list: [PAGE_INDEX_URL, PAGE_INDEX_HTML_URL] },
            },
            {
                validation: containsHtmlExtensionInURL
            },
            {
                validation: lengthInputValidation,
                options: { min: 1, max: 100 }
            }
        ]
    })
});
