import * as R from "ramda";
import { setToPath } from "../utils/ramdaEx";
import p from "../utils/pipePath";
import * as mp from "../mappers/path";
import type { Path } from "../mappers/path"; // eslint-disable-line

export const
    setPaddingTopStyle = R.curry((paddingPath: Path, padding: number, data: any) =>
        setToPath(p(paddingPath, mp.top), padding, data)),
    setPaddingBottomStyle = R.curry((paddingPath: Path, padding: number, data: any) =>
        setToPath(p(paddingPath, mp.bottom), padding, data)),
    setPaddingLeftStyle = R.curry((paddingPath: Path, padding: number, data: any) =>
        setToPath(p(paddingPath, mp.left), padding, data)),
    setPaddingRightStyle = R.curry((paddingPath: Path, padding: number, data: any) =>
        setToPath(p(paddingPath, mp.right), padding, data));
