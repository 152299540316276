import React from "react";
import { DemoLoginMessages } from '../../main/messages';
import styles from "./UserNotFoundPage.css";
import Button from "../../../../../src/view/common/Button/Button";
import VerticalSpacer from "../../../../../src/view/common/VerticalSpacer";
import type { UserNotFoundPagePropTypes } from "../../types";
import { showSignUpPage } from "../../signIn/actions";

const UserNotFoundPage = ({
    intl,
    dispatch,
}: UserNotFoundPagePropTypes) => {
    const {
        userNotFoundLabel,
        trialUserNotFoundLabel,
        invalidEmailCredentialsError,
        goToSignUpLabel,
    } = DemoLoginMessages;

    const userNotFoundPage = () => {
        return (
            [
                <div>
                    <div className={styles.headerLabel}>
                        {intl.msgJoint(userNotFoundLabel)}
                    </div>
                    <div className={styles.headerMsg}>
                        <div>
                            {intl.msgJoint(invalidEmailCredentialsError)}
                        </div>
                    </div>
                </div>,
                <VerticalSpacer y={49} />,
                <div>
                    <div>
                        <div className={styles.doneLabelContainer}>
                            <span className={styles.checkBoxIcon} />
                            <span className={styles.checkInboxLabel}>
                                {intl.msgJoint(trialUserNotFoundLabel)}
                            </span>
                        </div>
                    </div>
                </div>,
                <div>
                    <div className={styles.actionsContainer}>
                        <div className={styles.returnToLoginContainer}>
                            <VerticalSpacer y={284} />
                            <Button
                                onClick={() => dispatch(showSignUpPage())}
                                className={styles.returnToLoginButton}
                            >
                                {intl.msgJoint(goToSignUpLabel)}
                            </Button>
                        </div>
                    </div>
                </div>]
        );
    };

    return (
        <div>
            {userNotFoundPage()}
        </div>
    );
};

export {
    UserNotFoundPage
};
