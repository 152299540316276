import makeEpic, { fullEpicUndoablePath } from '../../../epics/makeEpic';
import type { ThemeSiteSettingsDataType } from "../../ThemeGlobalData/flowTypes";
import {
    UNSAVED_CHANGES_CHECKED,
    CANCEL_UNSAVED_CHANGES
} from "../../UnsavedChanges/actionTypes";
import { checkUnsavedChangesAction } from "../../UnsavedChanges/actionCreators";
import {
    AUTO_COLOR_MODE_CHANGE,
    AUTO_COLOR_MODE_DEACTIVATED,
    AUTO_COLOR_MODE_ACTIVATED
} from '../../ThemeGlobalData/actionTypes';
import { startOnboardingOfExistingCustomers } from "../../ThemeGlobalData/actionCreators";
import * as updateReasons from "./updateReasons";
import { arrayToTrueMap } from "../../../utils/arrayToTrueMap";
import { COLOR_THEME_SITE_SETTINGS_EPIC_VALUE } from './colorThemeSiteSettingsVAT';
import { openThemeDeactivationDialog } from "../../AutoColorLeftPanel/ThemeDeactivationDialog/actionCreators";

export const colorThemeDefaultState: ThemeSiteSettingsDataType = {
    autoColorMode: false
};

const undoableReasonsMap = arrayToTrueMap([
    updateReasons.AUTO_COLOR_MODE_CHANGE_OFF,
    updateReasons.AUTO_COLOR_MODE_CHANGE_ON
]);

const colorThemeSiteSettingsEpic = makeEpic({
    defaultState: colorThemeDefaultState,
    defaultScope: {
        isUnsavedCheckingInProgress: false
    },
    undo: {
        isUndoableChange: ({ updateReason }) => undoableReasonsMap[updateReason],
        undoablePaths: fullEpicUndoablePath
    },
    valueActionType: COLOR_THEME_SITE_SETTINGS_EPIC_VALUE,
    saveIntoSiteSettings: { key: 'themeSettingsData' },
    updaters: [
        {
            conditions: [AUTO_COLOR_MODE_CHANGE],
            reducer: ({ state, scope, values: [{ autoColorMode }] }) => {
                if (autoColorMode) {
                    return {
                        state,
                        scope: {
                            isUnsavedCheckingInProgress: true
                        },
                        actionToDispatch: checkUnsavedChangesAction()
                    };
                }

                return {
                    state,
                    scope,
                    actionToDispatch: openThemeDeactivationDialog()
                };
            }
        },
        {
            conditions: [AUTO_COLOR_MODE_ACTIVATED],
            reducer: ({ state, scope }) => {
                return {
                    state: { ...state, autoColorMode: true },
                    scope,
                    updateReason: updateReasons.AUTO_COLOR_MODE_CHANGE_ON,
                };
            }
        },
        {
            conditions: [AUTO_COLOR_MODE_DEACTIVATED],
            reducer: ({ state, scope }) => {
                return {
                    state: { ...state, autoColorMode: false },
                    scope,
                    updateReason: updateReasons.AUTO_COLOR_MODE_CHANGE_OFF,
                };
            }
        },
        {
            conditions: [
                UNSAVED_CHANGES_CHECKED,
            ],
            reducer: ({ state, scope }) => {
                if (scope.isUnsavedCheckingInProgress) {
                    return {
                        state,
                        scope: {
                            isUnsavedCheckingInProgress: false
                        },
                        multipleActionsToDispatch: [
                            startOnboardingOfExistingCustomers()
                        ]
                    };
                }

                return {
                    state,
                    scope
                };
            }
        },
        {
            conditions: [
                CANCEL_UNSAVED_CHANGES,
            ],
            reducer: ({ state, scope }) => {
                if (scope.isUnsavedCheckingInProgress) {
                    return {
                        state,
                        scope: {
                            isUnsavedCheckingInProgress: false
                        },
                    };
                }

                return {
                    state,
                    scope
                };
            }
        },
    ]
});

export {
    colorThemeSiteSettingsEpic,
};
