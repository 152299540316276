import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import { receiveOnly } from '../../../../epics/makeCondition';
import { REMEMBER_GOAL_ACHIEVED, ANALYTICS_GOAL_WSB_TRIAL_CONVERTED_LOGIN } from '../analyticsDependencies/actions';
import analyticsDependenciesVat from '../analyticsDependencies/valueActionType';
import { ANIMATION_FRAME } from '../../../../redux/middleware/raf';
import { SubscriptionTypeSelector } from '../subscriptionData/selectorActionTypes';
import { isValidSubscription } from '../subscriptionData/utils';
import { TEMPLATE_SELECTOR_DID_MOUNT } from '../../../TemplateSelector_DEPRECATED/actionTypes';
import { WORKSPACE_DID_MOUNT } from '../../../Workspace/actionTypes';
import { PREVIEW_LOADED } from '../../actionTypes';
import { OPEN_MOBILE_VIEW_EDITOR_CLICKED } from '../../../MobileViewEditor/actionTypes';
import { TRACK_RESERVED_CONTENT_NOT_IN_WEBSITE } from '../../../Workspace/epics/saveStatus/actionTypes';
import type { NotificationActionsInputType } from "../../../../epics/flowTypes";
import { DEMO_BUY_BUTTON_TRACK_ACTION } from "../../../../../demo/modules/actions";
import { DEMO_SIGNUP_SUCCESS_ACTION } from "../../../../../demo/modules/login/signUp/actions";
import { TRACK_EMAIL_VERIFICATION_GOAL } from "../../../../../demo/modules/login/emailVerification/actions";
import { stepConfig } from '../../../Onboarding/constants';
import { USER_PUBLISH_ACTION_SUCCESSFUL } from "../../../TopBar/actionTypes";

export type GoalDefinition = {
    goalId: string;
    triggerOnActionType: string;
    releasedOn: string;
};

const OneSecondMilliseconds = 1000;
const OneDayMilliseconds = OneSecondMilliseconds * 60 * 60 * 24;
const SixMonthMilliseconds = (365 / 2) * OneDayMilliseconds;

export const getNotificationActions = ({
    goalId,
}: NotificationActionsInputType) => {
    return [
        // TODO send event to AEC as well
        {
            type: REMEMBER_GOAL_ACHIEVED,
            payload: { goalId }
        },
    ];
};

// WARNING
// If you changing goalId, you should update releasedOn date.
// Use next day of release + 1 for releasedOn to start firing analytics a bit later, but having more accurate results.
const goalDefinitions: Array<GoalDefinition> = [
    ...(Object.values(stepConfig).filter(({ goal }: any) => !!goal).map(({ goal }: any) => goal)),
    {
        goalId: 'seen_template_selector',
        triggerOnActionType: TEMPLATE_SELECTOR_DID_MOUNT,
        releasedOn: '2019-11-29'
    },
    {
        goalId: 'seen_workspace',
        triggerOnActionType: WORKSPACE_DID_MOUNT,
        releasedOn: '2019-11-29'
    },
    {
        goalId: 'clicked_on_mobile_view_editor',
        triggerOnActionType: OPEN_MOBILE_VIEW_EDITOR_CLICKED,
        releasedOn: '2019-11-29'
    },
    {
        goalId: 'seen_preview',
        triggerOnActionType: PREVIEW_LOADED,
        releasedOn: '2019-11-29'
    },
    {
        goalId: 'published',
        triggerOnActionType: USER_PUBLISH_ACTION_SUCCESSFUL,
        releasedOn: '2019-11-29'
    },
    {
        goalId: 'published_with_own_content',
        triggerOnActionType: TRACK_RESERVED_CONTENT_NOT_IN_WEBSITE,
        releasedOn: '2019-11-29'
    },
    {
        goalId: 'WSB_accessed_after_converted_trial',
        triggerOnActionType: ANALYTICS_GOAL_WSB_TRIAL_CONVERTED_LOGIN,
        releasedOn: '2020-03-10'
    },
    {
        goalId: 'days_clicked_buy_trial',
        triggerOnActionType: DEMO_BUY_BUTTON_TRACK_ACTION,
        releasedOn: '2020-03-08'
    },
    {
        goalId: 'signed_up_trial',
        triggerOnActionType: DEMO_SIGNUP_SUCCESS_ACTION,
        releasedOn: '2020-03-08'
    },
    {
        goalId: 'days_seen_verified_email_trial',
        triggerOnActionType: TRACK_EMAIL_VERIFICATION_GOAL,
        releasedOn: '2020-03-08'
    }
];

const goalReleasedTimestampMap = {};
goalDefinitions.forEach(({ goalId, releasedOn }) => {
    if (!/^[a-zA-Z_$][0-9a-zA-Z_$]*$/.test(goalId)) {
        throw new Error(`Invalid goalId ${goalId}`);
    }

    const releasedDate = new Date(`${releasedOn}T00:00:00Z`);

    if (releasedDate.toString() === 'Invalid Date') {
        throw new Error(`Invalid releasedOn "${releasedOn}" for ${goalId}`);
    }
    goalReleasedTimestampMap[goalId] = releasedDate.getTime();
});

const FLUSH_GOALS = 'FLUSH_GOALS';

const epicConfig = {
    defaultState: {
        completedGoalsPendingFlushMap: []
    },
    valueActionType,
    updaters: [
        ...goalDefinitions.map(({ goalId, triggerOnActionType }) => {
            return {
                conditions: [
                    receiveOnly(ANIMATION_FRAME),
                    triggerOnActionType
                ],
                reducer: ({
                    state,
                    values: [
                        { ts: nowTimestamp },
                    ]
                }) => {
                    return {
                        state: {
                            ...state,
                            completedGoalsPendingFlushMap: {
                                ...state.completedGoalsPendingFlushMap,
                                [goalId]: { ts: nowTimestamp }
                            },
                        },
                        actionToDispatch: { type: FLUSH_GOALS }
                    };
                }
            };
        }),
        {
            conditions: [
                analyticsDependenciesVat,
                SubscriptionTypeSelector,
                FLUSH_GOALS
            ],
            reducer: ({
                state,
                values: [
                    { goalsTimestamps, oldestAvailableTimestampForSiteData, computedUserCreationClientTimestamp },
                    subscriptionType,
                ]
            }) => {
                const invalidComputedUserCreationServerTimestamp = !oldestAvailableTimestampForSiteData;
                const isValidSubscriptionType = isValidSubscription(subscriptionType);

                if (invalidComputedUserCreationServerTimestamp || !isValidSubscriptionType) {
                    // analytics deps or subscription status are not loaded yet
                    return { state };
                }

                const {
                    multipleActionsToDispatch,
                    completedGoalsPendingFlushMap
                } = Object.keys(state.completedGoalsPendingFlushMap).reduce((a: any, goalId: string) => {
                    const goalAlreadyCompleted = !!goalsTimestamps[goalId];
                    const userWasCreatedBeforeGoalWasReleased =
                            oldestAvailableTimestampForSiteData < goalReleasedTimestampMap[goalId];
                    const millisecondsSinceComputedUserCreationClientTimestamp =
                        state.completedGoalsPendingFlushMap[goalId].ts - computedUserCreationClientTimestamp;
                    const goalTookMoreThan6MonthToComplete =
                                millisecondsSinceComputedUserCreationClientTimestamp > SixMonthMilliseconds;

                    const nextCompletedGoalsPendingFlushMap = { ...a.completedGoalsPendingFlushMap };
                    delete nextCompletedGoalsPendingFlushMap[goalId];

                    if (
                        goalAlreadyCompleted
                        || userWasCreatedBeforeGoalWasReleased
                        || goalTookMoreThan6MonthToComplete
                    ) {
                        return { ...a, completedGoalsPendingFlushMap: nextCompletedGoalsPendingFlushMap };
                    }

                    const actions = getNotificationActions({
                        goalId,
                        millisecondsSinceComputedUserCreationClientTimestamp,
                        subscriptionType
                    });

                    const multipleActionsToDispatch = [...a.multipleActionsToDispatch, ...actions];

                    return { multipleActionsToDispatch, completedGoalsPendingFlushMap: nextCompletedGoalsPendingFlushMap };
                }, { multipleActionsToDispatch: [], completedGoalsPendingFlushMap: state.completedGoalsPendingFlushMap });

                return {
                    state: { ...state, completedGoalsPendingFlushMap },
                    multipleActionsToDispatch: [
                        ...multipleActionsToDispatch,
                    ]
                };
            }
        }
    ]
};

export default makeEpic(epicConfig);
