import { getExternalPlansLink } from '../../../../../../wbtgen/src/components/ComponentTierManager/utils';

export const CheckForExternalUpgradeLink = (func: () => void) => {
    const externalPlansLink = getExternalPlansLink();

    if (externalPlansLink) {
        window.open(externalPlansLink, '_blank');
    } else {
        func();
    }
};

export const getCapitalizedCaseName = (name) => {
    return name
        .split(' ')
        .map(word => (word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()))
        .join(' ');
};
