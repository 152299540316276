import type { InputValidationFunction, InputValidationResult } from "../../../../../inputControls/input/flowTypes";
import { makeInputValidationResult } from "../../../../../inputControls/input/commonValidations/utils";

type Options = {
    pageURL: string
}

const Reason = 'PAGE_URL_CONTAINS_HTML_EXTENSION',
    Message = {
        [Reason]: 'msg: validation.urlContainsHtmlExtension {Sorry, the text cannot end with .htm or .html}'
    };

const containsHtmlExtensionInURL: InputValidationFunction<Options> = (
    value: string
): InputValidationResult => {
    let isValid = true;

    const matches = value.match(/(.html?)$/);
    if (Array.isArray(matches) && (matches[0] === '.html' || matches[0] === '.htm')) {
        isValid = false;
    }

    return makeInputValidationResult(isValid, Reason, Message[Reason]);
};

export { containsHtmlExtensionInURL };
