import { NewPageTab } from "../constants";
import linkPageDefaultState from "../../linkPageForm/reducer/linkPageDefaultState";
import sectionLinkDefaultState from "../../sectionLinkForm/reducer/sectionLinkDefaultState";
import type { NewPageDialogState } from "../flowTypes";

const state: NewPageDialogState = {
    activeTab: NewPageTab.LAYOUT_PAGE,
    isLayoutLoading: false,
    templates: [],
    templatesAreLoading: false,
    [NewPageTab.LAYOUT_PAGE]: {
        recommendedLayouts: null,
        repositorySiteData: null,
        selectedLayout: null,
        selectedTemplate: null
    },
    [NewPageTab.LINK_PAGE]: linkPageDefaultState,
    [NewPageTab.SECTION_LINK]: sectionLinkDefaultState,
};

export default state;
