export const
    FIT = 'fit',
    LEFT = '0%',
    CENTER = '50%',
    RIGHT = '100%',
    intlDict = {
        [LEFT]: 'msg: component.menu.horizontalAlign.left {Left}',
        [CENTER]: 'msg: component.menu.horizontalAlign.center {Center}',
        [RIGHT]: 'msg: component.menu.horizontalAlign.right {Right}',
        [FIT]: 'msg: common.fit {Fit}'
    };
