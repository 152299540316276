import * as R from 'ramda';
import React from 'react';
import { pure } from 'recompose';
import { Border } from '../../../components/presentational/Border/Border';
import TopHoverShiftBar from './ShiftBar/TopHover';
import BottomHoverShiftBar from './ShiftBar/BottomHover';
import type {
    ComponentHoverDecorationState
} from '../../../components/Workspace/epics/componentHoverDecorations/flowTypes';
import * as HandleKinds from '../../../utils/handle/kinds';
import { makeBorderProps } from "./makeBorderProps";
import { red260 } from '../../../components/variables.css';

type Props = {
    state: ComponentHoverDecorationState
}

const sortByKey = R.sortBy(({ key }) => key);
export default pure(({
    state: {
        isVisible, workspaceBBox,
        makeInvisible, topmostHandlesQueue, componentBBox
    }
}: Props) => {
    const
        topMostHandle = topmostHandlesQueue[0],
        borderProps = makeBorderProps({
            bBox: componentBBox,
            opacity: isVisible ? 0.5 : 0,
            animate: true,
            makeInvisible,
            color: red260
        }),
        topShiftBars = [],
        bottomShiftBars = [];

    topmostHandlesQueue.forEach(handle => {
        if (!handle) {
            return;
        }
        let bars: any = bottomShiftBars,
            Bar = BottomHoverShiftBar;
        if (handle.kind === HandleKinds.ShiftBarTopHover) {
            bars = topShiftBars;
            Bar = TopHoverShiftBar;
        } else if (handle.kind !== HandleKinds.ShiftBarBottomHover) {
            return;
        }

        const
            isVisible = !!(topMostHandle
                && topMostHandle.componentsIds[0] === handle.componentsIds[0]
                && topMostHandle.kind === handle.kind),
            key = handle.componentsIds[0] + handle.kind;

        bars.push({
            key,
            view: <Bar
                workspaceBBox={workspaceBBox}
                bBox={handle.bBox}
                isVisible={isVisible}
                key={handle.componentsIds[0] + handle.kind}
            />
        });
    });

    // we need to sort them to avoid transition cancellation when elements in dom reordered
    const allShiftBars = sortByKey([...topShiftBars, ...bottomShiftBars]).map(({ view }) => view);
    return !makeInvisible ? (
        <div>
            {allShiftBars}
            <Border {...borderProps} />
        </div>
    ) : <div />;
});
