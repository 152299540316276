import type { Dimensions } from "../../../redux/modules/children/workspace/flowTypes";
import type { OpeningHoursComponent } from "./flowTypes";
import "./flowTypes";
import type { AdjustmentHook, AdjustmentHookConfig, ShouldCallAdjustmentHook } from "../../Workspace/epics/componentsEval/flowTypes";

type AdjustmentData = {
    minDimensions: Dimensions;
};
type Hook = AdjustmentHook<OpeningHoursComponent, undefined, undefined, AdjustmentData>;
type HookConfig = AdjustmentHookConfig<OpeningHoursComponent, undefined, undefined, AdjustmentData>;
type OpeningHoursShouldCallAdjustmentHook = ShouldCallAdjustmentHook<OpeningHoursComponent, undefined, undefined, AdjustmentData>;

const hook: Hook = ({ component, componentExtension, adjustmentData }) => {
    if (adjustmentData) {
        const {
            minDimensions: { height, width }
        } = adjustmentData;
        let nextComponent = component;

        if (nextComponent.height < height) {
            nextComponent = { ...nextComponent, height };
        }

        if (nextComponent.width < width) {
            nextComponent = { ...nextComponent, width };
        }

        return [nextComponent, componentExtension];
    } else {
        return [component, componentExtension];
    }
};

const shouldCallHook: OpeningHoursShouldCallAdjustmentHook = (prev, next) => !!next.adjustmentData;

export const adjustmentHookConfig: HookConfig = {
    hook,
    shouldCallHook
};
