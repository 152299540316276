import React from 'react';
import * as Languages from '../../../../../PropertiesPanel/view/Language/page';

const
    id = Languages.id,
    title = Languages.title,
    view = ({ dispatch, selectedComponent: { locale } }) => (
        // @ts-ignore
        <Languages.view dispatch={dispatch} locale={locale} />
    );

export { id, title, view };
