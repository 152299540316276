import React from "react";
import { pure } from 'recompose';
import ComponentRenderer from "./ComponentRenderer";
import WORKSPACE_COMPONENT_KIND from "../../../components/Workspace/epics/componentsEval/WORKSPACE_COMPONENT_KIND";
import forwardTo from "../../../redux/forwardTo";
import type { Components, ComponentsDependencies } from '../../../redux/modules/children/workspace/flowTypes';
import { getComponentsMapNoGhosts } from "../../../components/oneweb/Code/getComponentsMapNoGhosts";
import type { Attachments } from "../../../components/Workspace/epics/componentAttachements/flowTypes";
import { getAllHiddenComponentsInHoverBox } from "../../../components/oneweb/HoverBox/utils";

type Props = {
    attachments: Attachments,
    components: Components,
    componentsProps: ObjectMap,
    componentsDependencies: ComponentsDependencies,
    dispatch: Dispatch,
    editModeComponentId: string | null,
    subscriptionType: string,
    selectedComponentsIds: Array<string>
}

const defaultComponentsExtension = {};

export default pure((props: Props) => {
    const {
        components: { componentsMap, componentsMapExtension },
        componentsProps,
        attachments,
        componentsDependencies,
        editModeComponentId,
        subscriptionType,
        selectedComponentsIds
    } = props;

    const
        componentsMapNoGhosts =
            getComponentsMapNoGhosts(componentsMap),
        hiddenComponents = getAllHiddenComponentsInHoverBox(componentsMap, attachments),
        elements = Object.keys(componentsMapNoGhosts)
            .map(componentId => {
                const
                    component = componentsMapNoGhosts[componentId],
                    componentExtension = componentsMapExtension[componentId] || defaultComponentsExtension,
                    componentDependencies = componentsDependencies[component.kind],
                    renderProps = componentsProps[componentId],
                    inSelectedMode = selectedComponentsIds.includes(componentId),
                    inEditMode = editModeComponentId === componentId,
                    isHiddenComponent = hiddenComponents.includes(componentId);

                return (
                    <ComponentRenderer
                        key={componentId}
                        inEditMode={inEditMode}
                        inSelectedMode={inSelectedMode}
                        component={component}
                        componentExtension={componentExtension}
                        componentId={componentId}
                        componentDependencies={componentDependencies}
                        renderProps={renderProps}
                        isHiddenComponent={isHiddenComponent}
                        dispatch={forwardTo(WORKSPACE_COMPONENT_KIND, componentId, props.dispatch)}
                        subscriptionType={subscriptionType}
                    />
                );
            });

    return <div>{elements}</div>;
});
