import { getGlobalStyle } from "../nodeUtils/utils";
import { type TinyMceEditor } from "../../../flowTypes";
import { windowGetComputedStyleSafe } from "../../../../../../../utils/dom";

export const getStyleFromGlobalStyle = (root: HTMLElement, className: string, property: string) => {
    // create temporary element
    const tempElementWithClass = document.createElement('span');
    tempElementWithClass.className = className;
    root.appendChild(tempElementWithClass);
    const computedStyleForClass = windowGetComputedStyleSafe(tempElementWithClass);
    if (!computedStyleForClass) {
        // EDGE 17, 18 behaving wierdly, i was able to identify this place using error stack trace
        // not sure what is going wrong here WBTGEN-9814
        root.removeChild(tempElementWithClass);
        return null;
    }
    const styleWithClass = computedStyleForClass.getPropertyValue(property);
    root.removeChild(tempElementWithClass);

    const tempElementWithoutClass = document.createElement('span');
    root.appendChild(tempElementWithoutClass);
    const computedStyleWithoutClass = windowGetComputedStyleSafe(tempElementWithoutClass);
    if (!computedStyleWithoutClass) {
        // EDGE 17, 18 behaving wierdly, i was able to identify this place using error stack trace
        // not sure what is going wrong here WBTGEN-9814
        root.removeChild(tempElementWithoutClass);
        return null;
    }
    const styleWithoutClass = computedStyleWithoutClass.getPropertyValue(property);
    root.removeChild(tempElementWithoutClass);

    if (styleWithClass === styleWithoutClass) {
        return null;
    } else if (!['letter-spacing', 'line-height'].includes(property)) {
        return styleWithClass;
    } else {
        const fontSizeWithClass = getStyleFromGlobalStyle(root, className, 'font-size');
        return (parseFloat(styleWithClass) / parseFloat(fontSizeWithClass)).toFixed(1);
    }
};

export const makeExistingParentStyleSelector = (editor: TinyMceEditor, property: string) => {
    const root = editor.getBody();

    // recursive selector function which stops when style is found
    const findExistingStyle = (node: Node | null) => {
        if (!node || node === root) {
            return '';
        }

        if (node instanceof HTMLElement && node.style.getPropertyValue(property)) {
            return node.style.getPropertyValue(property);
        } else if (node instanceof HTMLElement && node.className) {
            const globalStyle = getGlobalStyle(node);
            const style = getStyleFromGlobalStyle(root, globalStyle, property);
            if (style) return style;
        }

        return findExistingStyle(node.parentNode);
    };
    return findExistingStyle;
};
