import type { LinkPageFormState, LinkPageFormCombinedProps } from "../flowTypes";
import { linkStateToLinkData, makeLinkPageUrl } from "../utils";
import { DataSite } from "../../../../../dal/model";
import { LcTabName } from "../../../../view/common/dialogs/LinkChooserDialog/constants";

type Input = {
    dependencies: {
        site: DataSite;
    };
    state: LinkPageFormState;
    actions: {
        setLinkAction: string;
        unsetLinkAction: string;
        urlOnChangeAction: string;
        nameOnChangeAction: string;
        openInNewWindowToggleAction: string;
        isHiddenToggleAction: string;
    };
};

export default ({ dependencies, state, actions }: Input): LinkPageFormCombinedProps => {
    const
        { site } = dependencies,
        { linkTo, openInNewWindow, name, isHidden, origPageRefUrl } = state,
        {
            setLinkAction,
            unsetLinkAction,
            urlOnChangeAction,
            nameOnChangeAction,
            openInNewWindowToggleAction,
            isHiddenToggleAction
        } = actions;

    const formLinkTo = {
        url: linkTo.url,
        resource: linkTo.resource
            ? makeLinkPageUrl(linkTo.resource, site).url
            : null
    };
    // handle case for missing resource
    if (linkTo.resource && linkTo.resource.type === LcTabName.PAGE && !formLinkTo.resource) {
        formLinkTo.resource = origPageRefUrl;
    }

    const
        linkData = linkStateToLinkData(state),
        button = {
            linkData,
            setLinkAction
        },
        form = {
            linkTo: formLinkTo,
            openInNewWindow,
            name,
            unsetLinkAction,
            urlOnChangeAction,
            nameOnChangeAction,
            openInNewWindowToggleAction
        },
        checkBox = {
            isChecked: isHidden,
            isHiddenToggleAction
        },
        result = {
            linkData: {
                link: linkTo.resource ? linkTo.resource : { type: LcTabName.URL, value: linkTo.url.value },
                openInNewWindow
            },
            name: name.value,
            isHidden
        };
    let isFormValid = name.isValid;
    if (isFormValid && !linkTo.resource) {
        isFormValid = isFormValid && linkTo.url.isValid;
    }

    return {
        button,
        form,
        checkBox,
        result,
        isFormValid
    };
};
