import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
    (theme: Theme) => ({
        rootContainer: {
            // 1250px = banner is at small distance from footer buttons
            [theme.breakpoints.between('xs', 1250)]: {
                right: "24px",
                bottom: "134px",
            },
            // 634px = rootContent.width (586px) + 2 x MuiSnackbar.right (24px)
            [theme.breakpoints.down(634)]: {
                right: 0,
                bottom: 0,
                left: 0,
            },
        },
        rootContent: {
            background: theme.palette.custom.primaryImpactColor,
            borderRadius: "0px",
            width: "586px",
            minHeight: "230px",
            position: "relative",
            padding: "0px",
            userSelect: "none",
            // 634px = rootContent.width (586px) + 2 x MuiSnackbar.right (24px)
            [theme.breakpoints.down(634)]: {
                width: "100%",
                minHeight: "0px",
            },
        },
        contentMessage: {
            padding: "0px",
        },
        controlButton: {
            position: "absolute",
            top: "26px",
            right: "26px",
            zIndex: 10,
            cursor: "pointer",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            [theme.breakpoints.up('sm')]: {
                top: "18px",
                right: "18px",
            },
        },
        closeButton: {
            width: "18px",
            height: "18px",
            backgroundSize: "18px",
            [theme.breakpoints.up('sm')]: {
                width: "10px",
                height: "10px",
                backgroundSize: "10px",
            },
            [theme.breakpoints.down(634)]: {
                display: "none",
            },
        },
        expandButton: {
            width: "30px",
            height: "30px",
            backgroundSize: "20px 10px",
            top: "26px",
            [theme.breakpoints.up(634)]: {
                display: "none",
            },
        },
        contentWrapper: {
            padding: "24px",
            color: theme.palette.custom.primaryTextColor,
            [theme.breakpoints.up(634)]: {
                padding: "44px 60px 46px 40px",
            },
        },
        contentHeader: {
            fontSize: "22px",
            lineHeight: "30px",
            letterSpacing: "0.25px",
            marginRight: "36px",
            [theme.breakpoints.up('sm')]: {
                fontSize: "24px",
                lineHeight: "30px",
            },
        },
        contentBody: {
            fontSize: "14px",
            lineHeight: "22px",
            marginTop: "8px",
            [theme.breakpoints.up('sm')]: {
                fontSize: "14px",
                lineHeight: "20px",
                marginTop: "10px",
            },
        },
        link: {
            "&, &:hover": {
                color: "white",
                textDecorationColor: "white",
                cursor: "pointer",
            },
        },
        actionsContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "stretch",
            marginTop: "20px",
            [theme.breakpoints.up('sm')]: {
                flexDirection: "row",
            },
        },
        actionButton: {
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "20px",
            boxSizing: "border-box",
            border: `1px solid ${theme.palette.custom.colorWhite}`,
            padding: "9px 20px",
            cursor: "pointer",
        },
        primaryAction: {
            color: theme.palette.custom.allText || theme.palette.custom.primaryImpactColor,
            background: theme.palette.custom.colorWhite,
            borderColor: theme.palette.custom.primaryImpactColor,
            "&:hover": {
                color: theme.palette.custom.allText || theme.palette.custom.primaryImpactColor,
                background: theme.palette.custom.colorWhite,
                borderColor: theme.palette.custom.primaryImpactColor,
            },
        },
        secondaryAction: {
            color: theme.palette.custom.primaryTextColor,
            background: theme.palette.custom.primaryImpactColor,
            borderColor: theme.palette.custom.colorWhite,
            "&:hover": {
                color: theme.palette.custom.primaryTextColor,
                background: theme.palette.custom.primaryImpactColor,
                borderColor: theme.palette.custom.colorGray_ed,
            },
        },
    }),
    { name: 'MarketgooTaskBanner' }
);
