export const
    MENU_PROP_PANEL_LAYOUT_BTN_PRESSED = 'MENU_PROP_PANEL_LAYOUT_BTN_PRESSED',
    MENU_PROP_PANEL_START_LEVEL_CHANGED = 'MENU_PROP_PANEL_START_LEVEL_CHANGED',
    MENU_PROP_PANEL_ALIGMENT_BTN_PRESSED = 'MENU_PROP_PANEL_ALIGMENT_BTN_PRESSED',
    MENU_PROP_PANEL_ENABLE_MORE_BUTTON_TOGGLED = 'MENU_PROP_PANEL_ENABLE_MORE_BUTTON_TOGGLED',
    MENU_PROP_PANEL_MORE_BTN_TEXT_CHANGED = 'MENU_PROP_PANEL_MORE_BTN_TEXT_CHANGED',
    MENU_PROP_PANEL_GLOBAL_STYLE_UPDATED = 'MENU_PROP_PANEL_GLOBAL_STYLE_UPDATED',
    MENU_PROP_PANEL_SUBMENU_GLOBAL_STYLE_UPDATED = 'MENU_PROP_PANEL_SUBMENU_GLOBAL_STYLE_UPDATED',
    MENU_PROP_PANEL_GLOBAL_THEME_STYLE_UPDATED = 'MENU_PROP_PANEL_GLOBAL_THEME_STYLE_UPDATED',
    MENU_PROP_PANEL_SUBMENU_GLOBAL_THEME_STYLE_UPDATED = 'MENU_PROP_PANEL_SUBMENU_GLOBAL_THEME_STYLE_UPDATED',
    MENU_PROP_PANEL_ACCENT_COLOR_CHANGE = 'MENU_PROP_PANEL_ACCENT_COLOR_CHANGE',
    MENU_PROP_PANEL_HORIZONTAL_ALIGN_FIT_BTN_PRESSED = 'MENU_PROP_PANEL_HORIZONTAL_ALIGN_FIT_BTN_PRESSED',
    MENU_RENDER_DIMENSIONS_CHANGED = 'MENU_RENDER_DIMENSIONS_CHANGED',
    MENU_PROP_PANEL_MENU_GLOBAL_STYLE_BTN_PRESSED = 'MENU_PROP_PANEL_GLOBAL_STYLE_BTN_PRESSED',
    MENU_PROP_PANEL_SUBMENU_GLOBAL_STYLE_BTN_PRESSED = 'MENU_PROP_PANEL_SUBMENU_GLOBAL_STYLE_BTN_PRESSED',
    MENU_PROP_PANEL_MENU_GLOBAL_THEME_STYLE_BTN_PRESSED = 'MENU_PROP_PANEL_MENU_GLOBAL_THEME_STYLE_BTN_PRESSED',
    MENU_PROP_PANEL_SUBMENU_GLOBAL_THEME_STYLE_BTN_PRESSED = 'MENU_PROP_PANEL_SUBMENU_GLOBAL_THEME_STYLE_BTN_PRESSED',
    MENU_PROP_PANEL_DROPDOWN_WIDTH_CHANGE = 'MENU_PROP_PANEL_DROPDOWN_WIDTH_CHANGE';
