import type { AnyComponent } from "../components/oneweb/flowTypes";
import { getByPath } from "./ramdaEx";
import * as p from "../mappers/path";
import type { GalleryComponent } from "../components/oneweb/Gallery/flowTypes";
import type { InstagramGalleryComponent } from "../components/oneweb/InstagramGallery/flowTypes";
import type { FacebookFeedGalleryComponent } from "../components/oneweb/FacebookFeedGallery/flowTypes";

const
    maxRadius = 60,
    maxBorder = 15,
    getRadiusStyleMap = (styleBorder: Record<string, any>) => ({
        'border-top-left-radius': getByPath(p.cornersTopLeft, styleBorder),
        'border-top-right-radius': getByPath(p.cornersTopRight, styleBorder),
        'border-bottom-right-radius': getByPath(p.cornersBottomRight, styleBorder),
        'border-bottom-left-radius': getByPath(p.cornersBottomLeft, styleBorder)
    }),
    getBorderWidthStyleMap = (styleBorder: Record<string, any>) => ({
        'border-top-width': getByPath(p.borderTopWidth, styleBorder),
        'border-right-width': getByPath(p.borderRightWidth, styleBorder),
        'border-bottom-width': getByPath(p.borderBottomWidth, styleBorder),
        'border-left-width': getByPath(p.borderLeftWidth, styleBorder)
    }),
    scaleStyle = (styleMap: Record<string, any> = {}, scaleFn: Function): string => (
        Object.keys(styleMap)
            .filter((styleKey: string) => styleMap[styleKey])
            .reduce((currentStyle: string, styleKey: string) => {
                const newStyleValue = scaleFn(styleMap[styleKey]);
                const newStyle = `${currentStyle}${styleKey}: ${newStyleValue}px !important;\n`;
                return newStyle;
            }, '')
    );

export const getScaledGalleryImageStyles = (component: GalleryComponent | InstagramGalleryComponent | FacebookFeedGalleryComponent) => {
    const
        { id } = component,
        styleBorder = getByPath(p.styleBorder, { style: component.imageStyle }),
        borderWidthMap = getBorderWidthStyleMap(styleBorder),
        scaledBorderWidth = scaleStyle(borderWidthMap, (val: number) => Math.min(val, maxBorder));

    if (scaledBorderWidth) {
        return `.mobileV div[data-id='${id}'] .img-wrapper {${scaledBorderWidth}}`;
    }
    return '';
};

export const getScaledGalleryCaptionStyles = (component: GalleryComponent) => {
    const
        { id } = component,
        captionBoxStyleBorder = getByPath(p.styleBorder, { style: component.captionBoxStyle }),
        captionBoxBorderWidthMap = getBorderWidthStyleMap(captionBoxStyleBorder),
        captionBoxScaledBorderWidth = scaleStyle(captionBoxBorderWidthMap, (val: number) => Math.min(val, maxBorder)),
        captionBoxBorderRadiusMap = getRadiusStyleMap(captionBoxStyleBorder),
        captionBoxScaledBorderRadius = scaleStyle(captionBoxBorderRadiusMap, (val: number) => Math.min(val, maxRadius));

    if (captionBoxScaledBorderWidth || captionBoxScaledBorderRadius) {
        return `.mobileV div[data-id='${id}'] .gallery-title-description-wrapper {${captionBoxScaledBorderWidth}${captionBoxScaledBorderRadius}}`; // eslint-disable-line
    }
    return '';
};

export const getScaledStyles = (component: AnyComponent) => {
    const
        { id } = component,
        styleBorder = getByPath(p.styleBorder, component),
        borderWidthMap = getBorderWidthStyleMap(styleBorder),
        scaledBorderWidth = scaleStyle(borderWidthMap, (val: number) => Math.min(val, maxBorder));

    if (scaledBorderWidth) {
        return `.mobileV div[data-id='${id}'] > div:first-child {${scaledBorderWidth}}`;
    }
    return '';
};

export const getScaledStylesForContainers = (component: AnyComponent) => {
    const
        { id } = component,
        styleBorder = getByPath(p.styleBorder, component),
        borderRadiusMap = getRadiusStyleMap(styleBorder),
        borderWidthMap = getBorderWidthStyleMap(styleBorder),
        scaledBorderRadius = scaleStyle(borderRadiusMap, (val: number) => Math.min(val, maxRadius)),
        scaledBorderWidth = scaleStyle(borderWidthMap, (val: number) => Math.min(val, maxBorder));

    if (scaledBorderRadius || scaledBorderWidth) {
        return `.mobileV div[data-id='${id}'] > div:first-child {${scaledBorderRadius}${scaledBorderWidth}}`;
    }
    return '';
};
