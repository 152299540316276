/* eslint-disable max-len */
import { partnerUpgradePlansDialog } from "./DialogComponents/partnerUpgradePlansDialog";
import { onecomUpgradePlansDialog } from "./DialogComponents/onecomUpgradePlansDialog";
import { getExternalPlansLink } from '../../../../ComponentTierManager/utils';

let externalPlansLink = '';
try {
    externalPlansLink = getExternalPlansLink();
} catch {
    // do nothing
}

const upgradePlansDialog = (() => {
    return externalPlansLink ? partnerUpgradePlansDialog() : onecomUpgradePlansDialog();
})();

export default upgradePlansDialog;
