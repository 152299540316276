import * as R from 'ramda';
import { CLOSE_ALL_DIALOGS, MOUSE_DOWN_ON_DIALOGS } from "../actionTypes";
import { dialogManagerDefaultScope, dialogManagerDefaultState } from "./constants";
import { COMPONENT_CONFIGURATION_COMPLETE } from "../../../redux/modules/children/workspace/actionTypes";
import {
    CLOSE_DIALOG,
    CLOSE_DIALOG_BY_ID,
    CLOSE_PERSISTENT_DIALOG,
    CLOSE_DIALOG_BY_ID_ONLY_IF_TOP_MOST
} from "../../../redux/modules/actionTypes";
import { isMouseDownOutsideDialog } from "../utility";

import type { DialogManagerEpicUpdater } from "../flowTypes";
import { propNotEq } from "../../../utils/ramdaEx";
import { getDialogFromRegistry } from "../registry";
import initAction from "../../../redux/initAction";
import { scopeTopMostDialogSelector } from "./selectors";
import { dropLastConfig } from "./shared";

const
    onCloseAllDialogsUpdater: DialogManagerEpicUpdater =
        {
            conditions: [CLOSE_ALL_DIALOGS],
            reducer: () => ({ state: dialogManagerDefaultState, scope: dialogManagerDefaultScope })
        },
    closeTopmostDialogReducer = ({ state, scope }) => {
        return {
            state,
            scope: dropLastConfig(scope)
        };
    },
    onDialogClosedUpdater: DialogManagerEpicUpdater = {
        conditions: [CLOSE_DIALOG],
        reducer: closeTopmostDialogReducer
    },
    onComponentConfigurationCompleteUpdater: DialogManagerEpicUpdater = {
        conditions: [COMPONENT_CONFIGURATION_COMPLETE],
        reducer: closeTopmostDialogReducer
    },
    onMouseDownOnDialogsUpdater: DialogManagerEpicUpdater = {
        conditions: [MOUSE_DOWN_ON_DIALOGS],
        reducer: ({ values: [payload], state, scope }) => {
            const lastDialog = scopeTopMostDialogSelector(scope);

            if (!lastDialog.modal && isMouseDownOutsideDialog(lastDialog, payload)) {
                return {
                    state,
                    scope: dropLastConfig(scope)
                };
            }

            return {
                state,
                scope
            };
        }
    },
    onCloseDialogByIdUpdater: DialogManagerEpicUpdater = {
        conditions: [CLOSE_DIALOG_BY_ID],
        reducer: ({ values: [dialogIdToClose], state, scope }) => {
            return {
                state,
                scope: R.evolve({ openedDialogConfigs: R.filter(propNotEq('id', dialogIdToClose)) }, scope)
            };
        }
    },
    onCloseDialogByIdOnlyIfTopmostUpdater: DialogManagerEpicUpdater = {
        conditions: [CLOSE_DIALOG_BY_ID_ONLY_IF_TOP_MOST],
        reducer: ({ values: [dialogIdToClose], state, scope }) => {
            if (R.prop('id', R.last(R.prop('openedDialogConfigs', scope))) === dialogIdToClose) {
                return closeTopmostDialogReducer({ state, scope });
            }
            return { state, scope };
        }
    },
    onClosePersistentDialogUpdater: DialogManagerEpicUpdater = {
        conditions: [CLOSE_PERSISTENT_DIALOG],
        reducer: ({ state, scope }) => {
            const
                topDialog = scopeTopMostDialogSelector(scope),
                record = getDialogFromRegistry(topDialog.id),
                clearPersistentState = R.evolve({
                    persistentStates: {
                        [record.id]: () => record.reducer(undefined, initAction())
                    }
                });

            return {
                state,
                scope: R.pipe(dropLastConfig, clearPersistentState)(scope)
            };
        }
    };

export {
    onCloseAllDialogsUpdater,
    onDialogClosedUpdater,
    onComponentConfigurationCompleteUpdater,
    onMouseDownOnDialogsUpdater,
    onCloseDialogByIdUpdater,
    onClosePersistentDialogUpdater,
    onCloseDialogByIdOnlyIfTopmostUpdater
};
