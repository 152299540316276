import { LINK_CLASS_NAME_REGEX, THEME_LINK_CLASS_LIST } from '../editorUtils/constants';
import { makeApplyEditorChangesReducer } from "./helpers/helpers";
import { getSelectedLinkNodes } from "../editorUtils/methods/getters/getLinkFromEditorSelection";
import { addClass } from "../editorUtils/methods/helpers/addClass";
import { makeRemoveFormatter } from '../editorUtils/methods/helpers/makeRemoveFormatter';
import { LINK_GLOBAL_STYLE_FORMATS } from "../../../../oneweb/Text/constants";
import { TINY_MCE_SET_LINK_GLOBAL_STYLE } from "../actionTypes";
import { removeMatchingClassNames } from '../../../../../utils/dom';
import { getGlobalstyleClassNameFromStyle } from '../../../../RenderGlobalstyles/getGlobalstyleClassName';

const onLinkGlobalStyleSelectedUpdater = {
    conditions: [
        TINY_MCE_SET_LINK_GLOBAL_STYLE
    ],
    reducer: makeApplyEditorChangesReducer(
        ({ state, scope, values: [{ stylesheetId }], editor }) => {
            const linkNodes = getSelectedLinkNodes(editor, false),
                { stylesheets, autoColorMode } = scope,
                removeFormatFromEditorSelection = makeRemoveFormatter(editor);

            linkNodes.forEach(node => {
                if (autoColorMode) {
                    node.classList.remove(...THEME_LINK_CLASS_LIST);
                    LINK_GLOBAL_STYLE_FORMATS.forEach(formatStyle => removeFormatFromEditorSelection(formatStyle, node));
                    addClass(editor, stylesheetId, node);
                } else {
                    const linkStyle = stylesheets.styles.find(({ id }) => id === stylesheetId);
                    if (linkStyle) { // condition to please flow, linkStyle would never be undefined, as we get stylesheetId from reducer params
                        removeMatchingClassNames(node, LINK_CLASS_NAME_REGEX);
                        LINK_GLOBAL_STYLE_FORMATS.forEach(formatStyle => removeFormatFromEditorSelection(formatStyle, node));
                        addClass(editor, getGlobalstyleClassNameFromStyle(linkStyle), node);
                    }
                }
            });

            // do not delete the line below, when removed it causes weird selection behavior
            // when some elements in the "selection" are removed as part of removing formatting
            //
            // to reproduce, create a link with some text, and put formatting from color/highlight/shadow
            // put underline formatting on some of the formatted text as well
            // place cursor in the middle of the underlined text without any selection
            // change the link style, and then change the link style again without interacting with the text
            // one would expect the second link style change to work, but it does not
            //
            // this change also ensures that after link style changes, if the user continues typing
            // then the new text is entered right where the user put cursor before changing link styles
            editor.selection.getBookmark();

            return {
                state: {
                    ...state,
                    selectedLinkGlobalStyle: stylesheetId
                },
                scope
            };
        }
    )
};

export {
    onLinkGlobalStyleSelectedUpdater
};
