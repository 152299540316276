/* We need a way to transfer new epics state to reducers inside redux tree
 * To make that reducers pure update inforamtion should be in action, but we don't want to put it to an action
 * So reducers are impure and thay access this manager to get new epic state once they see action.epicsUpdate.updatedActionTypes[myValueActionType] === true
 * They do getGetEpicsState(updateNum)[myValueActionType].state to get their new state
 * This manager handles multiple updates at same moment of time, because we don't want to make epicsState as a singleton
 * There are few reasons why it should not be a singleton
 * - Publishing implementation will suffer
 * - Tests can't run asyncronosly
 * - Hot reloading of code is much more difficult to implement */
import type { EpicsState } from './flowTypes';

type Updates = {
    [updateNum: number]: EpicsState
}
const updates: Updates = {};
let nextUpdateNum = 0;

export function putUpdate(epicsState: EpicsState) {
    const updateNum = nextUpdateNum;
    updates[updateNum] = epicsState;
    nextUpdateNum += 1;
    return {
        updateNum,
        dispose: () => {
            if ('hot' in module && module.hot) {
                // don't clean up so hot reload can work
            } else {
                // production case
                delete updates[updateNum];
            }
        }
    };
}
export function getUpdatedEpicsState(action: AnyAction | PlainAction) {
    const { epicsUpdate } = action;

    if (!epicsUpdate) {
        // should never happen
        throw new Error('Action should have epicsUpdate');
    }

    const { num, nextEpicsState } = epicsUpdate;

    if (nextEpicsState) {
        return nextEpicsState;
    }

    if (!updates[num]) {
        // should never happen
        throw new Error(`There is no such update: ${num}`);
    }

    return updates[num];
}
