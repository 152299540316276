import { map } from "ramda";
import { INTL_DAYS_OF_THE_WEEK } from "./constants";
import { getStartDayOfTheWeek } from "./previewUtils";
import { formatMessage } from "../../../view/intl/CurrentLocale";
import { parseMsgJoint } from "../../../../../server/shared/utils/parseMsgJoint";
import type { OpeningHoursComponent } from "./flowTypes";

export const adjustComponentOnAdd = (defaultState: OpeningHoursComponent): OpeningHoursComponent => ({
    ...defaultState,
    closedLabel: formatMessage({
        id: "component.openingHours.closed",
        defaultMessage: "Closed"
    }),
    dayLabels: map(msgJointInput => {
        const [id, defaultMessage] = parseMsgJoint(msgJointInput);
        return formatMessage({
            id,
            defaultMessage
        });
    }, INTL_DAYS_OF_THE_WEEK),
    hourFormat: formatMessage({
        id: "component.openingHours.hourFormat",
        defaultMessage: "HH"
    }),
    open24HoursLabel: formatMessage({
        id: "component.openingHours.open24Hours",
        defaultMessage: "Open 24 hours"
    }),
    startDayOfTheWeek: getStartDayOfTheWeek(),
    timeComponentSeparator: formatMessage({
        id: "component.openingHours.timeComponentSeparator",
        defaultMessage: ":"
    })
});
