/* eslint-disable max-len */

import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import {
    GENERAL_INFO_PHONE_NUMBER_INPUT_CHANGED,
    GENERAL_INFO_PHONE_NUMBER_CHANGED_ON_BLUR,
    GENERAL_INFO_ADDRESS_CHANGED,
    GENERAL_INFO_ADDRESS_GOOGLE_PLACE_RESPONSE
} from '../../../../General/actionTypes';
// import { GENERAL_GLOBAL_DATA_TOGGLE_VIRTUAL_ADDRESS } from '../../../../General/generalGlobalDataEpic/actionTypes';

import VerticalSpacer from "../../../../../../view/common/VerticalSpacer";
import { ValidatedInput } from '../../../../../../view/common/Input/ValidatedInputField';
import { GoogleMapsAutoCompleteInput } from '../../../../../inputControls/input/view/GoogleMapsAutoCompleteInput';
// import CheckBoxWithLabel from "../../../../../../view/common/CheckBox/CheckBoxWithLabel";
import { telInputValidation } from '../../../../../inputControls/input/commonValidations/telInputValidation';
import { Intl } from "../../../../../../view/intl/index";
// import { QuestionTip } from "../../../../../presentational/Tooltip/questionTip/QuestionTip";
// import { TooltipPosition } from "../../../../../presentational/Tooltip/constants";

import styles from './styles.css';
import stepStyles from './ContactDetailsStep.css';
import { usePrevious } from "../../../../../../utils/react/effects/usePrevious";
import renderAddress from "../../../../General/renderAddress";
import type { GeneralGlobalData } from "../../../../General/generalGlobalDataEpic/generalGlobalDataEpic";
import type { gmbErrorType } from "../../../flowTypes";

type ContactDetailsStepProps = {
    intl: Intl;
    dispatch: Dispatch;
    address: string;
    addressUrl: string;
    phoneNumber: string | null | undefined;
    // isVirtualAddress: boolean;
    attemptedInvalidSubmit: boolean;
    errorData: [string, gmbErrorType | null, string];
    generalGlobalData: GeneralGlobalData,
    setIsOneField: (isOneField: boolean) => void,
    isOneField: boolean
};

const ContactDetailsStep = (props: ContactDetailsStepProps) => {
    const {
        intl,
        dispatch,
        address,
        phoneNumber,
        // isVirtualAddress,
        addressUrl,
        attemptedInvalidSubmit,
        errorData: [phoneError, addressError, generalError],
        generalGlobalData,
        setIsOneField,
        isOneField,
    } = props;

    const [skipPhoneError, setSkipPhoneError] = useState(false);
    const prevPhoneNumber = usePrevious(phoneNumber);
    useEffect(
        () => {
            if (prevPhoneNumber !== undefined && prevPhoneNumber !== phoneNumber) {
                setSkipPhoneError(true);
            }
        },
        [phoneNumber],
    );

    const getPhoneValidationResult = (phoneNumber, phoneError) => {
        if (phoneNumber) {
            const telInputValidationResult = telInputValidation(phoneNumber);
            if (!telInputValidationResult.isValid) {
                return telInputValidationResult;
            }
        }
        if (!skipPhoneError && phoneError) {
            return { isValid: false, error: { msg: phoneError } };
        }
        return { isValid: true };
    };
    const phoneValidationResult = getPhoneValidationResult(phoneNumber, phoneError),
        // @ts-ignore
        phoneValidationErrorMsg = !phoneValidationResult.isValid ? phoneValidationResult.error.msg : '';

    return (
        <div className={styles.container}>
            <div className={styles.field}>
                <div className={styles.name}>
                    {intl.msgJoint("msg: BusinessListing.createListing.phoneNumber {Phone number (optional)}")}
                    <span>{intl.msgJoint("msg: BusinessListing.createListing.phoneNumberDescription {This is the phone number for your business}")}</span>
                </div>
                <div className={styles.inputWithValidation}>
                    <ValidatedInput
                        className={cx(styles.inputField, styles.validatedInput)}
                        placeholder="msg: common.phoneExample {e.g. +44 1234 567890}"
                        value={phoneNumber || ''}
                        isInvalid={!!phoneValidationErrorMsg}
                        invalidInputClass={styles.validationLabel}
                        validationMsg={phoneValidationErrorMsg}
                        onChange={payload => dispatch({ type: GENERAL_INFO_PHONE_NUMBER_INPUT_CHANGED, payload })}
                        intl={intl}
                        changedByUserOnBlur={() => dispatch({ type: GENERAL_INFO_PHONE_NUMBER_CHANGED_ON_BLUR })}
                    />
                </div>
            </div>
            <VerticalSpacer y={29} />
            <div className={styles.hr} />
            <VerticalSpacer y={37} />
            <div className={styles.field}>
                <div className={styles.name}>
                    {intl.msgJoint("msg: common.address {Address}")}
                    <span>{intl.msgJoint("msg: BusinessListing.createListing.AddressDescription {This is the physical address of your business}")}</span>
                    {/* <div className={stepStyles.checkbox}>
                        <CheckBoxWithLabel
                            label="msg: BusinessListing.createListing.onlineBusiness { My business is online only }"
                            isChecked={isVirtualAddress}
                            isSliderStyle
                            onClick={() => dispatch({ type: GENERAL_GLOBAL_DATA_TOGGLE_VIRTUAL_ADDRESS })}
                        />
                    </div> */}
                </div>
                {/* {
                    !isVirtualAddress && */}
                <div className={stepStyles.addressDiv}>
                    {
                        isOneField ?
                            <div>
                                <GoogleMapsAutoCompleteInput
                                    className={cx(styles.inputField, stepStyles.addressInput, { [styles.error]: attemptedInvalidSubmit && !addressUrl })}
                                    placeholder={intl.msgJoint("msg: common.address {Address}")}
                                    value={address}
                                    intl={intl}
                                    onChange={payload => dispatch({ type: GENERAL_INFO_ADDRESS_CHANGED, payload })}
                                    onPlaceChanged={payload => { dispatch({ type: GENERAL_INFO_ADDRESS_GOOGLE_PLACE_RESPONSE, payload }); setIsOneField(false); }}
                                    usePropsValue
                                    isInvalid={!!(addressError || generalError)}
                                    invalidInputClass={styles.validationLabel}
                                    validationMsg={((addressError && addressError.msg) || generalError)}
                                />
                            </div> :
                            <React.Fragment>
                                {renderAddress({ intl, dispatch, generalGlobalData, addressError, disableCountry: true })}
                                <a className={stepStyles.searchAgain} onClick={() => setIsOneField(true)}>{intl.msgJoint("msg: common.searchBusinessAddressAgain {Search business address again}")}</a>
                            </React.Fragment>
                    }
                    {/* <div className={stepStyles.tipWithName}>
                        {intl.msgJoint("msg: BusinessListing.createListing.CannotFindBusinessAddress {Can\'t find your business address?}")}
                        <QuestionTip
                            position={TooltipPosition.RIGHT}
                            distance={2}
                            theme={{ tipInfo: styles.questionTipInfo, container: styles.questionTipContainer }}
                            className={styles.questionTip}
                        >
                            <React.Fragment>
                                <div className={styles.tipHeader}>
                                    {intl.msgJoint("msg: BusinessListing.createListing.CannotFindBusinessAddress.tipHeader {Adding a business address}")}
                                </div>
                                <VerticalSpacer y={6} />
                                <div>
                                    {intl.msgJoint("msg: BusinessListing.createListing.CannotFindBusinessAddress.tip {It means your address doesn\'t exist on Google yet. No worries, you can add it later on the Google My Business site. }")}
                                </div>
                            </React.Fragment>
                        </QuestionTip>
                    </div> */}
                </div>
                {/* } */}
            </div>
        </div>
    );
};

export default ContactDetailsStep;
