/* eslint-disable max-len */
import type {
    OnboardingWelcomeStepType,
    OnboardingWebsiteTitleStepType,
    OnboardingLogoStepType,
    OnboardingContactStepType,
    OnboardingGmbCategoriesStepType,
    OnboardingTemplateSelectorStepType,
    OnboardingThemingStepType
} from "../../flowTypes";
import { getImageSrc } from "../../../oneweb/Image/utils";
import type { ImageAsset } from "../../../App/flowTypes";
import "../../../App/flowTypes";

type markerType = {
    cm: string;
    tm: number;
};
type stepType =
    | OnboardingWelcomeStepType
    | OnboardingWebsiteTitleStepType
    | OnboardingLogoStepType
    | OnboardingContactStepType
    | OnboardingGmbCategoriesStepType
    | string ;

type updateTextDataProps = {
    animationRef: Record<string, any>;
    elementIndex?: number;
    frameIndex?: number;
    textData?: {
        t: string;
    };
};
type updateStaticTextDataProps = {
    animationData: Record<string, any>;
    layerIndex?: number;
    text?: string;
};
export const ONBOARDING_STEPS: {
    WELCOME: OnboardingWelcomeStepType;
    WEBSITE_TITLE: OnboardingWebsiteTitleStepType;
    LOGO: OnboardingLogoStepType;
    CONTACT: OnboardingContactStepType;
    GMB_CATEGORIES: OnboardingGmbCategoriesStepType;
    TEMPLATE_SELECTOR: OnboardingTemplateSelectorStepType;
    THEMING: OnboardingThemingStepType;
} = {
    WELCOME: "WELCOME",
    WEBSITE_TITLE: "WEBSITE_TITLE",
    LOGO: "LOGO",
    CONTACT: "CONTACT",
    GMB_CATEGORIES: "GMB_CATEGORIES",
    TEMPLATE_SELECTOR: "TEMPLATE_SELECTOR",
    THEMING: "THEMING"
};
const MARKER_INITIAL_VALUE = 0;
const STEPS_MARKERS = {
    [ONBOARDING_STEPS.WELCOME]: {
        intro_start_of_step: MARKER_INITIAL_VALUE,
        intro_stop_point: MARKER_INITIAL_VALUE
    },
    [ONBOARDING_STEPS.WEBSITE_TITLE]: {
        title_start_of_step: MARKER_INITIAL_VALUE,
        title_stop_point: MARKER_INITIAL_VALUE
    },
    [ONBOARDING_STEPS.LOGO]: {
        logo_start_of_step: MARKER_INITIAL_VALUE,
        logo_stop_point: MARKER_INITIAL_VALUE
    },
    [ONBOARDING_STEPS.CONTACT]: {
        contact_start_of_step: MARKER_INITIAL_VALUE,
        contact_stop_point: MARKER_INITIAL_VALUE
    },
    [ONBOARDING_STEPS.GMB_CATEGORIES]: {
        gmb_start_of_step: MARKER_INITIAL_VALUE,
        gmb_stop_point: MARKER_INITIAL_VALUE,
        gmb_category1_selected: MARKER_INITIAL_VALUE,
        gmb_category2_selected: MARKER_INITIAL_VALUE,
        gmb_category3_selected: MARKER_INITIAL_VALUE
    }
};
export const ELEMENT_INDEXES = {
    title: 0,
    email: 3,
    phone: 2,
    address: 1,
    logoImage: 0
};
export const getStepMarkers = (markers: Array<markerType>, step: stepType, stepMarkers = STEPS_MARKERS[step]): Record<string, any> => {
    const updatedMarkers = {};
    markers.forEach(marker => {
        if (stepMarkers[marker.cm] === 0) {
            updatedMarkers[marker.cm] = marker.tm;
        }
    });
    return updatedMarkers;
};
export const updateStaticTextData = ({ animationData, layerIndex = 0, text = "" }: updateStaticTextDataProps) => {
    const element = animationData.layers[1].layers[0].layers[layerIndex];
    element.t.d.k[0].s.t = text;
};
export const updateTextData = ({
    animationRef,
    elementIndex = 0,
    frameIndex = 0,
    textData = {
        t: ""
    }
}: updateTextDataProps) => {
    const element = animationRef.current.renderer.elements[1].elements[0].elements[elementIndex];
    element.updateDocumentData(textData, frameIndex);
};
export const LOTTIE_DEFAULT_OPTIONS = {
    loop: false,
    autoplay: false,
    rendererSettings: {
        preserveAspectRatio: "xMaxYMax meet"
    }
};
export const LOGO_PREVIEW_HEIGHT = 54,
    LOGO_PREVIEW_MAX_WIDTH = 346;
export const updateLogoImage = (animationData: Record<string, any>, logoAsset: ImageAsset) => {
    const logoImageRatio = logoAsset.width / logoAsset.height,
        { src } = getImageSrc(logoAsset, logoAsset.height, logoAsset.width),
        animationJson = animationData,
        logoPreviewWidth = Math.min(logoImageRatio * LOGO_PREVIEW_HEIGHT, LOGO_PREVIEW_MAX_WIDTH);
    animationJson.assets[ELEMENT_INDEXES.logoImage].p = src;
    animationJson.assets[ELEMENT_INDEXES.logoImage].w = logoPreviewWidth;
    animationJson.assets[ELEMENT_INDEXES.logoImage].h = LOGO_PREVIEW_HEIGHT;
};
export const BROKEN_IMAGE_BASE_64 =
    "data:image/svg+xml;base64,PHN2ZyBzdHlsZT0iZmlsbDojOTk5OTk5IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMCAzMCI+PGcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTU3MCAtODApIj48Y2lyY2xlIGN4PSI1NzkiIGN5PSI5MiIgcj0iMiIvPjxwYXRoIGQ9Ik01OTAuOTA4IDg2bC0uNTkzIDJINTk1djE1aC05LjEyOWwtLjU5MyAySDU5N1Y4NnoiLz48cGF0aCBkPSJNNTg2LjE2NyAxMDJINTk0di0yLjg1N2MtMS45OTctMi43NzYtMi45NTQtNi42NTctNC44ODMtNy4wOThsLTIuOTUgOS45NTV6bTEuODc0LTIwLjI4NEw1ODYuNzcxIDg2SDU3M3YxOWg4LjE0M2wtMS4xMDIgMy43MTYgMS45MTguNTY4IDgtMjctMS45MTgtLjU2OHptLTQuNzMxIDE1Ljk2NmMtLjY2OC0uODYxLTEuMzQtMS4zOTYtMi4wNi0xLjM5Ni0xLjk1NSAwLTIuNjc0IDQuMTU3LTUuMjUgNC45OTlWMTAyaDYuMDMxbC0uMjk2IDFINTc1Vjg4aDExLjE4bC0yLjg3IDkuNjgyeiIvPjwvZz48L3N2Zz4=";
