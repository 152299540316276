import makeEpic from '../../../../../epics/makeEpic';
import valueActionType from "./valueActionType";
import {
    CONTACT_FORM_EMAIL_CHANGED,
    CONTACT_FORM_SUBJECT_CHANGED,
    CONTACT_FORM_SET_EMAIL_CLICKED,
    CONTACT_FORM_SET_EMAIL,
    CONTACT_FORM_SET_MAIL_CANCELED,
    CONTACT_FORM_CONFIGURATION_DIALOG_MOUNTED
} from "../../actionTypes";
import openDialog from "../../../../App/actionCreators/openDialog";
import { ContactFormComponentConfigDialogId } from "../../dialogIds";
import {
    COMPONENT_CONFIGURATION_CANCELED
} from "../../../../../redux/modules/children/workspace/actionTypes";
import closeDialog from "../../../../App/actionCreators/closeDialog";
import { ReceiveOnlySelectedComponentSelector } from "../../../../Workspace/epics/componentsEval/selectorActionTypes";
import { getAppConfig } from "../../../../App/epics/appConfig/appConfig";
import AppConfig from "../../../../../utils/AppConfig";

const
    appConfig = AppConfig(getAppConfig()),
    allowContactFormWithOutEmail = appConfig.getOptional('oneWeb.contactForm.allowContactFormWithOutEmail') || false,
    getDefaultState = () => ({
        email: '',
        subject: '',
        isValidEmail: true,
        saveDisabled: true,
        allowContactFormWithOutEmail,
        setEmail: false
    });

const validateInput = (email, allowContactFormWithOutEmail) => {
    const isValidEmail = allowContactFormWithOutEmail || !!email;
    return { isValidEmail, saveDisabled: !isValidEmail };
};

const closeUpdaters = [
    CONTACT_FORM_SET_EMAIL,
    COMPONENT_CONFIGURATION_CANCELED,
    CONTACT_FORM_SET_MAIL_CANCELED
].map(action => ({
    conditions: [action],
    reducer: () => ({ state: getDefaultState(), actionToDispatch: closeDialog() })
}));

export default makeEpic({
    defaultState: getDefaultState(),
    valueActionType,
    updaters: [
        {
            conditions: [
                ReceiveOnlySelectedComponentSelector,
                CONTACT_FORM_CONFIGURATION_DIALOG_MOUNTED
            ],
            reducer: ({ values: [component], state }) => {
                const { recipientEmail = "", subject } = component || { recipientEmail: "", subject: "" },
                    { isValidEmail } = validateInput(recipientEmail, state.allowContactFormWithOutEmail);

                // For WSB Trial, recipientEmail in the ContactForm component defaults to empty string in Template data,
                // but is converted to NULL when being stored in AWS DynamoDB. Hence the email edit dialog for the contact
                // form components fails. Thus the recipientEmail must always be of type string only.

                // When the dialog opens, the initial state of "Save" button should always be disabled.
                return {
                    state: {
                        ...state,
                        email: (recipientEmail || ""),
                        subject,
                        isValidEmail,
                        saveDisabled: true
                    }
                };
            }
        },
        {
            conditions: [CONTACT_FORM_EMAIL_CHANGED],
            reducer: ({ values: [email], state }) => ({
                state: {
                    ...state,
                    email,
                    ...validateInput(email, state.allowContactFormWithOutEmail)
                }
            })
        },
        {
            conditions: [CONTACT_FORM_SUBJECT_CHANGED],
            reducer: ({ values: [payload], state }) => ({
                // eslint-disable-next-line max-len
                state: { ...state, subject: payload.subject, saveDisabled: !validateInput(payload.email, state.allowContactFormWithOutEmail).isValidEmail }
            })
        },
        {
            conditions: [CONTACT_FORM_SET_EMAIL_CLICKED],
            reducer: ({ state }) => {
                return {
                    state: { ...state, setEmail: true },
                    actionToDispatch: openDialog(ContactFormComponentConfigDialogId)
                };
            }
        },
        ...closeUpdaters
    ]
});
