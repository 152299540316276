export const
    TOP_BAR_OPEN_POPUP = 'TOP_BAR_OPEN_POPUP',
    TOP_BAR_CLOSE_POPUP = 'TOP_BAR_CLOSE_POPUP',
    TOP_BAR_PAGE_TREE_ON_EXPAND = 'TOP_BAR_PAGE_TREE_ON_EXPAND',
    TOP_BAR_PAGE_TREE_SCROLL_TOP = 'TOP_BAR_PAGE_TREE_SCROLL_TOP',
    TOP_BAR_SELECT_LANGUAGE = 'TOP_BAR_SELECT_LANGUAGE',
    TOP_BAR_SHOW_LANGUAGES = 'TOP_BAR_SHOW_LANGUAGES',
    TOP_BAR_HIDE_LANGUAGES = 'TOP_BAR_HIDE_LANGUAGES',
    TOP_BAR_LANGUAGE_HAS_SELECTED = 'TOP_BAR_LANGUAGE_IS_SELECTED',
    TOP_BAR_HELP_PRESSED = 'TOP_BAR_HELP_PRESSED',
    TOPBAR_ON_MENU_CLICKED = 'TOPBAR_ON_MENU_CLICKED',
    LOGOUT = 'LOGOUT',
    UNDO_BUTTON_PRESSED = 'UNDO_BUTTON_PRESSED',
    REDO_BUTTON_PRESSED = 'REDO_BUTTON_PRESSED',
    PUBLISH_BUTTON_CLICKED = 'PUBLISH_BUTTON_CLICKED',
    CHECK_PUBLISH_STATUS_REQUEST = 'CHECK_PUBLISH_STATUS_REQUEST',
    CHECK_PUBLISH_STATUS_SUCCESS = 'CHECK_PUBLISH_STATUS_SUCCESS',
    SHOW_PUBLISHED_POPUP = 'SHOW_PUBLISHED_POPUP',
    USER_PUBLISH_ACTION_SUCCESSFUL = 'USER_PUBLISH_ACTION_SUCCESSFUL',
    CHECK_PUBLISH_STATUS_FAILURE = 'CHECK_PUBLISH_STATUS_FAILURE',
    PUBLISH_SITE_REQUEST = "PUBLISH_SITE_REQUEST",
    PUBLISH_SITE_SUCCESS = "PUBLISH_SITE_SUCCESS",
    PUBLISH_SITE_FAILURE = "PUBLISH_SITE_FAILURE",
    PUBLISH_STATUS_UPDATER = "PUBLISH_STATUS_UPDATER",
    CLOSE_PUBLISHING_PROGRESS_POPUP = 'CLOSE_PUBLISHING_PROGRESS_POPUP',
    PUBLISHING_TIP_READ_MORE_CLICKED = 'PUBLISHING_TIP_READ_MORE_CLICKED',
    CLOSE_PUBLISHING_SUCCESS_POPUP = 'CLOSE_PUBLISHING_SUCCESS_POPUP',
    SEAMLESS_UPGRADE_CLICKED = "SEAMLESS_UPGRADE_CLICKED",
    SEAMLESS_UPGRADE_REQUEST = "SEAMLESS_UPGRADE_REQUEST",
    SEAMLESS_UPGRADE_SUCCESS = "SEAMLESS_UPGRADE_SUCCESS",
    SEAMLESS_UPGRADE_FAILURE = "SEAMLESS_UPGRADE_FAILURE",
    CLICKED_EXTERNAL_LINK = "CLICKED_EXTERNAL_LINK",
    HELP_BTN_CLIENT_RECT = 'HELP_BTN_CLIENT_RECT',
    GET_HELP_BTN_CLIENT_RECT = 'GET_HELP_BTN_CLIENT_RECT',
    TUTORIAL_BTN_CLIENT_RECT = 'TUTORIAL_BTN_CLIENT_RECT',
    PAGES_TREE_CONTAINER_CLIENT_RECT = 'PAGES_TREE_CONTAINER_CLIENT_RECT',
    KEYBOARD_SHORTCUTS_CLICKED = 'KEYBOARD_SHORTCUTS_CLICKED',
    SAVE_BTN_CLIENT_RECT = 'SAVE_BTN_CLIENT_RECT',
    GO_TO_DASHBOARD_CLICKED_ACTION = 'GO_TO_DASHBOARD_CLICKED_ACTION',
    PUBLISH_CANCELLED_AFTER_ERROR_DIALOG = 'PUBLISH_CANCELLED_AFTER_ERROR_DIALOG',
    TUTORIAL_VIDEO_CLICKED = 'TUTORIAL_VIDEO_CLICKED';

export const
    publishButtonClickedAction = () => ({ type: PUBLISH_BUTTON_CLICKED });
