type Login = {
    md5Hex: string;
    timestamp: Date;
    domain: string;
    email: string | null | undefined;
};

export default (authCookie: string): Login | null | undefined => {
    let
        cookie = authCookie,
        result: any = null;

    // See if the cookie has the magic suffix that specifies the email address that the user originally logged in with
    // eslint-disable-next-line no-control-regex
    let match = cookie.match(/\x1e(.*)$/), //NOSONAR
        email: any = null;

    if (match) {
        email = match[1];
        cookie = cookie.replace(match[0], '');
    }

    // decode other info
    let decoded = new Buffer(cookie, 'base64').toString(), // eslint-disable-line no-buffer-constructor
        data = decoded.match(/^([0-9a-f]{32})([0-9a-f]{8})(.+)$/);

    if (data) {
        result = {
            md5Hex: data[1],
            timestamp: new Date(1000 * parseInt(data[2], 16)),
            domain: data[3],
            email
        };
    }

    return result;
};
