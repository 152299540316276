import * as React from 'react';
import styles from './dialog.css';
import { Msg } from "../../../../../view/intl/index";
import {
    InlineLoadingIndicatorCom,
    InlineLoadingIndicatorSize
} from "../../../../../view/common/LoadingIndicator/InlineLoadingIndicatorCom";
import getCenteredDialogConfig from "../../../../DialogManager/getCenteredDialogConfig";

const
    WIDTH = 371,
    HEIGHT = 173;

export type ShutterStockUploadingDialogProps = {
    plural: boolean,
    videos: boolean
};

const ShutterStockcomUploadingDialogCom = ({ plural, videos }: ShutterStockUploadingDialogProps) => (
    <React.Fragment>
        <header>
            <h1>
                {
                    videos ?
                        <Msg k="fm.uploading.video.title" params={{ plural, videos }}>
                            {`We\'re busy getting {plural, select, false {your video} true {your videos}}`}
                        </Msg> :
                        <Msg k="fm.uploading.title" params={{ plural, videos }}>
                            {`We\'re busy getting your {plural, select, false {image} true {images}}`}
                        </Msg>
                }
            </h1>
            <h2>
                {
                    videos ?
                        <Msg k="common.pleaseWaitVideos">Please wait, it could take a few minutes.</Msg> :
                        <Msg k="common.pleaseWait">Please wait.</Msg>
                }
            </h2>
        </header>
        <InlineLoadingIndicatorCom size={InlineLoadingIndicatorSize.LARGE} />
    </React.Fragment>
);

export const shutterstockUploadingDialog = getCenteredDialogConfig({
    width: WIDTH,
    height: HEIGHT,
    component: ShutterStockcomUploadingDialogCom,
    dialogClassName: styles.uploadingDialog,
    forceModal: true
});
