import React from "react";
import Page from "../../../../PropertiesPanel/view/PropertiesPage";
import injectIntl from "../../../../../view/intl/injectIntl";
import * as pagesIds from "../../../FeaturedProducts/propertiesPanel/pages/ids";
import NavigationGroup from "../../../../PropertiesPanel/view/IntlNavigationGroup";
import pagesTitles from "../../../FeaturedProducts/propertiesPanel/pages/titles";

const id: any = pagesIds.MAIN,
    title = pagesTitles[pagesIds.PRODUCT_WIDGET],
    view = injectIntl(({ navigateToPage }) => {
        return (
            <Page>
                <NavigationGroup
                    navigateToPage={navigateToPage}
                    pagesTitles={pagesTitles}
                    targetPagesIds={[pagesIds.STYLE, pagesIds.BUTTON, pagesIds.PROMO]}
                />
            </Page>
        );
    });

export { id, title, view };
