type Set = {
    [key: number]: string,
};

export class ImgResolutionSrcSet {
    /*::#*/set: Set;

    constructor(set: Set) {
        this./*::#*/set = set;
    }

    toString() {
        return Object
            .keys(this./*::#*/set)
            .sort()
            .map(scale => `${this./*::#*/set[scale]} ${scale}x`)
            .join(', ');
    }
}
