import { makeEpic } from "../../../../../epics/makeEpic";
import { SAVE_SITE_SETTINGS } from "../../../../App/epics/siteSettings/actionTypes";
import {
    GENERAL_AI_TEXT_GLOBAL_DATA_UPDATE
} from "./actionTypes";
import { GENERAL_AI_TEXT_DATA_UPDATE } from "./updateReasons";
import { generalAITextGlobalDataVAT } from "./valueActionType";

export const generalAITextGlobalDataEpic = makeEpic({
    defaultState: {},
    valueActionType: generalAITextGlobalDataVAT,
    saveIntoSiteSettings: { key: 'textAIData' },
    undo: false,
    updaters: [
        {
            conditions: [GENERAL_AI_TEXT_GLOBAL_DATA_UPDATE],
            reducer: ({ state, values: [payload] }) => ({
                state: {
                    ...state,
                    ...payload
                },
                actionToDispatch: { type: SAVE_SITE_SETTINGS },
                updateReason: GENERAL_AI_TEXT_DATA_UPDATE
            })
        }
    ]
});
