import workspace from "./view/workspace";
import propertiesPanel from "./propertiesPanel/index";
import kind from './kind';
import componentMainActions from "./componentMainActions/index";
import reducer from './reducers/index';
import adjustmentHookConfig from './adjustmentHookConfig';
import siteDataEpic from "../../App/epics/siteData/index";
import stylesheetsEpic from '../../Workspace/epics/stylesheets/index';
import stylesheetsIdToNameMapEpic from '../../Workspace/epics/stylesheets/idToNameMap';
import adjustComponentOnAdd from './adjustComponentOnAdd';
import defaultStyleIdEpic from './epics/defaultStyleId';
import siteFontsReducer from '../../Fonts/siteFontsReducer';
import { textCalcRenderProps } from "./calcRenderProps";
import colorPickerEpic from '../../ColorPicker/epic/index';
import colorPickerEpicValueActionType from '../../ColorPicker/epic/valueActionType';
import makeStateSelectorReducer from "../../../epics/makeStateSelectorReducer";
import { selectedTextStateEpic } from "./epics/selectedTextStateEpic/index";
import { tinyMceStateEpic } from "../../App/epics/tinyMceEpic/tinyMceStateEpic";
import fontStatusReducer from "../../Fonts/fontStatusReducer";
import Text from "./view/TextInMobileViewEditor";
import type { TextComponent } from "./flowTypes";
import { ComponentNames } from "../constants";
import {
    PERFORM_ALL_WRAPPED_COMPONENTS_POSITION_ADJUSTMENT_ON_PAGE_LOAD
} from '../../Workspace/epics/wrap/actionTypes';
import { globalVariablesEpic } from "../../App/epics/globalVariablesEpic";
import { replaceTagsWithContentDOM } from "./view/replaceTagsWithContentDOM";
import type { ChangeOldComponentDataBeforeImportProps } from "../../../view/oneweb/flowTypes";
import { sectionComponentsEpic } from '../../PagesTree/sectionComponents/epic/sectionComponentsEpic';
import { getGroupCmpPositionStylesForMobile } from "../getGroupCmpPositionStylesForMobile";
import type { ExtendCmpPropsForGroupItemProps } from "../../MobileViewEditor/flowTypes";
import { colorThemeSiteSettingsEpic } from "../../SiteSettings/ColorThemeData/colorThemeSiteSettingsEpic";
import { textAutoColorControlsDependenciesEpic } from './epics/selectedTextComponentControlsDependencies/index';
import previewConfig from './previewConfig';
import { isModernLayoutActivatedEpic } from "../../Workspace/epics/isModernLayoutActivatedEpic/index";
import templateOffsetEpic from "../Template/epics/templateOffset/index";
import leftPanelWidthEpic from '../../Panel/epics/width/index';
import { preferencesEpic } from "../../Preferences/preferencesEpic";

export default {
    kind,
    workspace,
    label: ComponentNames[kind],
    shortcutIconName: 'text',
    propertiesPanel,
    componentMainActions,
    reducer,
    dependsOn: {
        stylesheetsIdToNameMap: stylesheetsIdToNameMapEpic.reducer,
        defaultStyleId: defaultStyleIdEpic.reducer,
        globalVariables: globalVariablesEpic.reducer,
        site: siteDataEpic.reducer,
        sectionComponents: sectionComponentsEpic.reducer,
        stylesheets: stylesheetsEpic.reducer,
        isModernLayoutActivated: isModernLayoutActivatedEpic.reducer,
        themeSettingsData: colorThemeSiteSettingsEpic.reducer,
        generalPreferences: preferencesEpic.reducer
    },
    controlsDependsOn: {
        colorPickerOpened: makeStateSelectorReducer(
            colorPickerEpic.reducer,
            colorPickerEpicValueActionType,
            state => state.opened
        ),
        siteFonts: siteFontsReducer,
        selectedTextState: selectedTextStateEpic.reducer,
        tinyMceState: tinyMceStateEpic.reducer,
        fontStatus: fontStatusReducer,
        stylesheets: stylesheetsEpic.reducer,
        autoColor: textAutoColorControlsDependenciesEpic.reducer,
        templateOffset: templateOffsetEpic.reducer,
        leftPanelWidth: leftPanelWidthEpic.reducer
    },
    adjustComponentOnAdd,
    adjustmentHookConfig,
    calcRenderProps: textCalcRenderProps,
    mobileEditorConfig: {
        kind,
        view: Text,
        calcProps: previewConfig.calcProps,
        getHiddenElementData: (props: TextComponent) => ({ content: props.text }),
        extendCmpPropsForGroupItem: ({
            componentProps,
            group
        }: ExtendCmpPropsForGroupItemProps) => {
            return {
                ...componentProps,
                mobileStyles: getGroupCmpPositionStylesForMobile(group, componentProps.component).mobileStyles
            };
        }
    },
    nonUndoableActionTypes: [PERFORM_ALL_WRAPPED_COMPONENTS_POSITION_ADJUSTMENT_ON_PAGE_LOAD],
    changeOldComponentDataBeforeImport: ({
        component,
        globalVariables,
        site,
    }: ChangeOldComponentDataBeforeImportProps<{ content: string }>) => (component.content ? ({
        ...component,
        content: replaceTagsWithContentDOM(component.content, { globalVariables, site }, true)
    }) : component)
};
