import React from "react";
import * as R from 'ramda';
import cx from 'classnames';
import * as styles from './SubmitButton.css';
import type { SubmitButtonProps } from "../flowTypes";
import * as path from "../../../../../mappers/path";
import { submitBtnTextSelector } from "../../selectors";
import { replaceTagsWithContent } from "../../../Text/view/replaceTagsWithContent";

export default ({
    classList = [],
    disabled,
    buttonStyle,
    stylesheetsIdToNameMap = {},
    component,
    site,
    globalVariables,
    globalSubmitBtnClassName,
    autoColorMode,
    firstButtonStylesheetId,
}: SubmitButtonProps) => {
    const globalStyleClassName = autoColorMode ? stylesheetsIdToNameMap[firstButtonStylesheetId] :
            stylesheetsIdToNameMap[R.path(path.styleButtonGlobalId, component)],
        submitBtnText = replaceTagsWithContent(submitBtnTextSelector(component), { site, globalVariables });
    return (
        <React.Fragment>
            <div key={1} className={`${styles.container} submitButtonContainer`}>
                <input
                    className={cx(
                        styles.submitButton, globalStyleClassName, globalSubmitBtnClassName, { 'submit-btn-disabled': disabled }, classList
                    )}
                    type="submit"
                    value={submitBtnText}
                    style={buttonStyle}
                    data-title={submitBtnText}
                />
            </div>
            <div key={2} className={styles.spacer} />
        </React.Fragment>
    );
};
