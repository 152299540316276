import React from "react";
export const SvgInstagramRegularLight = props => (
    <svg viewBox="0 0 43 43" {...props}>
        <defs>
            <path
                id="instagram_regular_light_svg__a"
                d="M.049 12.263C.049 5.506 5.524.028 12.28.028h18.15c6.754 0 12.23 5.478 12.23 12.235V30.19c0 6.757-5.475 12.234-12.23 12.234H12.28C5.525 42.424.05 36.946.05 30.19V12.263zm3.945 0V30.19a8.287 8.287 0 008.286 8.287h18.15a8.286 8.286 0 008.285-8.287V12.263a8.287 8.287 0 00-8.285-8.288H12.28a8.286 8.286 0 00-8.286 8.288zm17.417 20.351c-6.195 0-11.217-5.023-11.217-11.219s5.022-11.219 11.217-11.219c6.194 0 11.216 5.023 11.216 11.22 0 6.195-5.022 11.218-11.216 11.218zm0-3.946a7.272 7.272 0 007.27-7.273 7.272 7.272 0 10-14.541 0 7.272 7.272 0 007.27 7.273zM32.74 12.6a2.706 2.706 0 110-5.411 2.706 2.706 0 010 5.411z"
            />
        </defs>
        <use fill="#ffffff" xlinkHref="#instagram_regular_light_svg__a" />
    </svg>
);
