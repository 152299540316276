import React from 'react';
import cx from 'classnames';
import styles from './TextArea.css';
import type { TextAreaProps } from "./flowTypes";
import focusHandler from "../../../utils/inputFocusSelect";
import { injectIntl } from "../../../view/intl/index";
import { Intl } from "../../../view/intl/injectIntl";

export default injectIntl((props: TextAreaProps & { intl: Intl; }) => {
    const { value, onChangeAction, dispatch, className, onFocus, placeholder, intl } = props;

    return (
        <textarea
            value={value}
            placeholder={placeholder && intl.msgJoint(placeholder)}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => dispatch({ type: onChangeAction, payload: e.target.value })}
            className={cx(styles.textarea, className)}
            onFocus={(e: React.FocusEvent<HTMLTextAreaElement>) => { if (onFocus) { onFocus(e); } focusHandler(e); }}
        />
    );
});
