import React from 'react';
import factory from "../factory";
import { getFacebookPagePluginApiUrl } from "../utils";
import FacebookPageBase from './FacebookPageBase';
import FacebookPageView from './FacebookPageView';
import type { FacebookPageCalcRenderPropsResult } from '../flowTypes';

export default class FacebookPageLocaleWrapper extends FacebookPageBase {
    RenderComponent: any;

    constructor(props: FacebookPageCalcRenderPropsResult) {
        super(props);
        this.RenderComponent = factory(getFacebookPagePluginApiUrl(this.props.locale))(FacebookPageView);
    }

    UNSAFE_componentWillReceiveProps(nextProps: FacebookPageCalcRenderPropsResult) {
        if (nextProps.locale !== this.props.locale) {
            this.RenderComponent = factory(getFacebookPagePluginApiUrl(nextProps.locale))(FacebookPageView);
        }
    }

    render() {
        const { RenderComponent } = this;
        return (<RenderComponent {...this.props} />);
    }
}
