import React from 'react';
import cx from 'classnames';
import styles from './CheckBox.css';
import injectIntl from "../../intl/injectIntl";
import CheckBox from './CheckBox';
import CheckBoxSlider from './CheckBoxSlider';
import type { CheckBoxWithLabelPropTypes } from "./flowTypes";

export default (baseContainerClassName: string) => injectIntl((props: CheckBoxWithLabelPropTypes) => {
    const {
            isChecked,
            label,
            intl,
            isHtmlLabel = false,
            checkBoxRight,
            containerClassName = '',
            checkboxClass = '',
            isGreen,
            disabled,
            className,
            isSliderStyle,
            disabledLabelClassName,
            checkboxProps,
            showWhiteCheckMark,
            error
        } = props,
        labelClassNameDisabledSpec = disabledLabelClassName
            ? {
                [styles.disabled]: disabled,
                [disabledLabelClassName]: disabled
            }
            : { [styles.disabled]: disabled },
        labelClassName = cx(
            styles.label,
            labelClassNameDisabledSpec,
            className
        ),
        onClick = disabled ? () => null : props.onClick,
        children = [
            isSliderStyle ?
                <CheckBoxSlider isChecked={isChecked} onClick={onClick} key="box" disabled={disabled} /> :
                <CheckBox
                    checkboxClass={checkboxClass}
                    isGreen={isGreen}
                    isChecked={isChecked}
                    onClick={onClick}
                    key="box"
                    disabled={disabled}
                    showWhiteCheckMark={showWhiteCheckMark}
                    checkboxProps={checkboxProps}
                    error={error}
                />,
            <label
                className={labelClassName}
                onClick={onClick}
                key="label"
            >
                {!isHtmlLabel && intl.msgJoint(label)}
                {isHtmlLabel && label}
            </label>
        ].filter(c => c !== undefined);

    if (checkBoxRight) children.reverse();

    return (
        <div className={cx(baseContainerClassName, containerClassName)}>{children}</div>
    );
});
