import * as R from "ramda";
import { applyMappers, makePlainMappers } from '../../utils';
import { TABS_TIMELINE } from '../../../../src/components/oneweb/Facebook/constants';
import { fixPageURL, getFullPageURL } from '../../../../src/components/oneweb/Facebook/utils';
import type { FacebookPageComponent } from '../../../../src/components/oneweb/Facebook/flowTypes';

const
    plainPropsMapper = makePlainMappers({
        mobileDown: 'mobileDown',
        showFriendsFaces: 'showFacepile',
        useSmallHeader: 'smallHeader',
        hideCoverPhoto: 'hideCover'
    }),
    mapShowPagePosts = {
        to: ({ showPagePosts, ...rest }) => ({ ...rest, tabs: showPagePosts ? [TABS_TIMELINE] : [] }),
        back: ({ tabs, ...rest }) => ({ ...rest, showPagePosts: tabs.length > 0 })
    },
    mapPageURL = {
        to: ({ href = '', ...rest }) => ({ ...rest, pageURL: fixPageURL(href) }), // TODO Handle null href
        back: ({ pageURL, ...rest }) => ({ ...rest, href: getFullPageURL(pageURL) })
    },
    to = (componentData: any): FacebookPageComponent => {
        const mappedData = applyMappers(
            componentData,
            mapShowPagePosts.to,
            mapPageURL.to,
            plainPropsMapper.to
        );
        return R.omit(['showFriendsFaces', 'useSmallHeader', 'hideCoverPhoto', 'showPagePosts'], mappedData);
    },
    back = (component: FacebookPageComponent): any => {
        const mappedData = applyMappers(
            component,
            mapShowPagePosts.back,
            mapPageURL.back,
            plainPropsMapper.back
        );

        return R.omit(['showFacepile', 'smallHeader', 'hideCover', 'tabs'], mappedData);
    };

export { to, back };
