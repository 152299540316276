import { apiAction } from './actions';
import { SHUTTERSTOCK_CRASH_REPORT_ID } from '../../../components/FileManager/shutterstock/crashReport';
import { FREE_ONECOM_CRASH_REPORT_ID } from '../../../components/FileManager/freeOnecom/crashReport';
import { PAID_SHUTTERSTOCK_REPORT_ID } from '../../../components/FileManager/paidShutterstock/crashReport';
import { DalErrorName } from '../../../../dal/constants';

import type {
    GetImagesQuery,
    PostThirdPartyImageDownloadsRequest,
    PutPreferencesRequestBody, SstockCollectionT,
} from '../../../../dal/flowTypes';
import type { CommonApiActionParams, TemplateApiActionParams } from './flowTypes';

export const getPaidShutterstockImagePriceApiAction = (params: CommonApiActionParams) =>
    apiAction({
        ...params,
        endpoint: 'getPaidShutterstockImagePrice',
        errorHandler: {
            dialog: 'msg: common.failedToGetImagePrice {Sorry, we couldn\'t get the price of the image.}',
            customCrashReport: {
                crashReportId: PAID_SHUTTERSTOCK_REPORT_ID,
            },
        },
    });

export const getShutterstockCategoriesApiAction = (
    { collection, ...params }: CommonApiActionParams & { collection: SstockCollectionT },
) =>
    apiAction({
        ...params,
        endpoint: 'getShutterstockCategories',
        endpointParams: [collection],
        errorHandler: {
            dialog: 'msg: common.failedToGetShutterstockCategories {Sorry, we couldn\'t get the categories.}',
            customCrashReport: {
                crashReportId: SHUTTERSTOCK_CRASH_REPORT_ID,
            },
        },
    });

export const getAnalyticsDependenciesApiAction = (params: CommonApiActionParams) =>
    apiAction({
        ...params,
        endpoint: 'getAnalyticsDependencies',
        errorHandler: {
            customCrashReport: {
                crashReportId: 'GetAnalyticsDependencies',
            },
        },
    });

export const analyticsGoalAchievedApiAction = (params: CommonApiActionParams) =>
    apiAction({
        ...params,
        endpoint: 'analyticsGoalAchieved',
        errorHandler: {
            customCrashReport: {
                crashReportId: 'analyticsGoalAchieved',
            },
        },
    });

export const getFreeShutterstockDownloadedImagesApiAction = (params: CommonApiActionParams) =>
    apiAction({
        ...params,
        endpoint: 'getFreeShutterstockDownloadedImages',
        errorHandler: {
            dialog: 'msg: common.failedToGetShutterstockDownloadedImages {Sorry, we couldn\'t get the downloaded free images.}', // eslint-disable-line max-len
            customCrashReport: {
                crashReportId: FREE_ONECOM_CRASH_REPORT_ID,
            },
        },
    });

export const getRepositorySiteDataApiAction = function (params: CommonApiActionParams) {
    return apiAction({
        ...params,
        endpoint: 'getRepositorySiteData',
        errorHandler: {
            dialog: 'msg: common.failedToLoadSite {Failed to load site.}',
        },
    });
};

export const getSectionsListDataApiAction = function (params: CommonApiActionParams) {
    return apiAction({
        ...params,
        endpoint: 'getSectionsBlocksListData'
    });
};

export const getRepositoryTemplateRefListApiAction = (params: CommonApiActionParams) =>
    apiAction({
        ...params,
        endpoint: 'getRepositoryTemplateRefList',
        errorHandler: {
            dialog: 'msg: common.failedToLoadTemplates {Failed to load templates.}',
        },
    });

export const getTemplateDataSetApiAction = function ({ templateId, ...params }: TemplateApiActionParams) {
    return apiAction({
        ...params,
        endpoint: 'getTemplateDataSet',
        endpointParams: [templateId],
        errorHandler: {
            dialog: 'msg: common.failedToLoadTemplateData {Sorry, we couldn\'t load the template data.}',
        },
    });
};

export const getTemplatePagesApiAction = ({ templateId, ...params }: TemplateApiActionParams) =>
    apiAction({
        ...params,
        endpoint: 'getTemplatePages',
        endpointParams: [templateId],
        errorHandler: {
            dialog: 'msg: common.failedToLoadTemplatePages {Sorry, we couldn\'t load the template pages.}',
        },
    });

export const getTemplatesWithPagesApiAction = (params: CommonApiActionParams) =>
    apiAction({
        ...params,
        endpoint: 'getTemplatesWithPages',
        errorHandler: {
            dialog: 'msg: common.failedToLoadTemplates {Failed to load templates.}',
        },
    });

export const getListOfPageLayoutsAction = (params: CommonApiActionParams) => {
    return apiAction({
        ...params,
        endpoint: 'getPageLayoutsData',
        errorHandler: {
            dialog: 'msg: common.failedToLoadSite {Failed to load site.}',
        },
    });
};

type SearchShutterstockImagesParams = CommonApiActionParams & {
    collection: SstockCollectionT,
    query?: GetImagesQuery,
};

export const searchShutterstockImagesApiAction = ({ collection, query, ...params }: SearchShutterstockImagesParams) =>
    apiAction({
        ...params,
        endpoint: 'searchShutterstockImages',
        endpointParams: [collection, query],
        errorHandler: {
            dialog: 'msg: common.failedToSearchShutterstockImages {Sorry, we couldn\'t search the free images.}',
        },
    });

type DownloadShutterstockImagesParams = CommonApiActionParams & {
    request: PostThirdPartyImageDownloadsRequest,
};

export const downloadFreeShutterstockImagesApiAction = ({ request, ...params }: DownloadShutterstockImagesParams) =>
    apiAction({
        ...params,
        endpoint: 'downloadFreeShutterstockImages',
        endpointParams: [request],
    });

export const copyPageTemplateRefApiAction = ({ templateId, ...params }: TemplateApiActionParams) =>
    apiAction({
        ...params,
        endpoint: 'copyPageTemplateRef',
        endpointParams: [templateId],
    });

export const deletePageTemplateAllApiAction = ({ templateId, ...params }: TemplateApiActionParams) =>
    apiAction({
        ...params,
        endpoint: 'deletePageTemplateAll',
        endpointParams: [templateId],
    });

export const getPreferencesApiAction = (params: CommonApiActionParams) =>
    apiAction({
        ...params,
        endpoint: 'getPreferences',
        errorHandler: {
            dialog: 'msg: common.failedToLoadPreferences {Sorry, loading preferences failed.}',
            errorMatcher: {
                skipErrors: [DalErrorName.NOT_FOUND],
            },
        },
    });

export const putPreferencesApiAction = (
    { data, ...params }: CommonApiActionParams & { data: PutPreferencesRequestBody },
) =>
    apiAction({
        ...params,
        endpoint: 'putPreferences',
        endpointParams: data,
        errorHandler: {
            dialog: 'msg: common.failedToLoadSavePreferences {Sorry, saving preferences failed.}',
        },
    });

export const getSelectedTemplateAction = function (params: CommonApiActionParams) {
    return apiAction({
        ...params,
        endpoint: 'getSelectedTemplate'
    });
};
