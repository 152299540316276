import React from 'react';
import View from './index';
import { ComponentMaskBackground } from '../../../presentational/ComponentMask/constants';
import ComponentMask from '../../../presentational/ComponentMask/index';
import BaseComponent from '../../../../view/oneweb/BaseComponent';

// TODO TYPES
export default (props: any) => {
    return (
        <BaseComponent {...props}>
            <ComponentMask
                showPreviewOnlyLayer={false}
                style={{ height: props.base.height }}
                maskStyle={ComponentMaskBackground.TRANSPARENT}
            >
                {React.createElement(View, { ...props, isWorkspace: true })}
            </ComponentMask>
        </BaseComponent>
    );
};
