import React from 'react';
import {
    MctaVerticalBar,
    MctaPropertyContainer,
    MctaCheckbox,
    MctaCombobox,
    MctaColumnGrid
} from '../../../componentMainActions/index';
import { MctaSlider } from '../../../componentMainActions/MctaSlider/MctaSlider';
import mctaStyles from './componentMainActions.css';
import * as actionTypes from '../actionTypes';
import { imageRatioOptions } from '../../WebShop/options';
import type {
    FeaturedProductsViewProps,
    FeaturedProductsComponent
} from '../flowTypes';
import HorizontalSpacer from '../../../../view/common/HorizontalSpacer';
import { ProductListStyles } from '../constants';
import { SHOW_WEBSHOP_ONBOARDING_DIALOG } from "../../WebShop/WebshopAdminDialog/actionTypes";
import { Intl } from "../../../../view/intl/injectIntl";
import {
    PRODUCT_WIDGET_CTA_CLICKED,
    PRODUCT_WIDGET_UPDATE_PRODUCT_SETUP_WIDGET_METHOD
} from '../../ProductWidget/actionTypes';
import {
    CtaButtonTextView,
    getWebshopSetupStatus,
} from './CtaButtonTextView';
import type { Stylesheets } from '../../../Workspace/epics/stylesheets/flowTypes';
import type {
    ThemeSiteSettingsDataType,
} from "../../../ThemeGlobalData/flowTypes";
import { getButtonStyleGlobalPropertiesSelector } from '../utils';
import GlobalstyleSelector from '../../../presentational/GlobalstyleSelector/index';
import { DEFAULT_MCTA_COMBOBOX_MENUSTYLE } from '../../../componentMainActions/constants';
import buttonKind from '../../Button/globalStyle/kind';

type Props = {
    intl: Intl;
    dispatch: Dispatch;
    selectedComponentProps: FeaturedProductsViewProps;
    stylesheetsIdToNameMap: MapT<string>;
    globalStyles: Stylesheets;
    themeSettingsData: ThemeSiteSettingsDataType;
    isSubscriptionTypeCompatible: boolean;
    selectedComponent: FeaturedProductsComponent;
};

const EditView = (props: Props) => {
    const {
        intl,
        dispatch,
        selectedComponentProps,
        stylesheetsIdToNameMap,
        globalStyles,
        themeSettingsData
    } = props;
    const { autoColorMode } = themeSettingsData;
    const { component, selectedParentTheme } = selectedComponentProps;
    const productAppearanceChangedPayload =
        (component.productListStyle) === ProductListStyles.Default ?
            ProductListStyles.Card : ProductListStyles.Default; // eslint-disable-line max-len

    const {
        buttonStyleOptRenderer,
        buttonStyleOtions,
        buttonStyleColorThemeOnChangeAction,
        buttonStyleOnChangeAction,
        buttonStyleCurrentStylesheetId
    } = getButtonStyleGlobalPropertiesSelector(
        dispatch,
        intl,
        component,
        selectedParentTheme,
        globalStyles,
        autoColorMode,
        stylesheetsIdToNameMap
    );

    const { viewDetailsButtonThemeSelected } = component;
    let { viewDetailsButtonId } = component;

    if (!autoColorMode && !viewDetailsButtonId) {
        viewDetailsButtonId = `${buttonStyleOtions[0].value}`;
    }

    return (
        <div>
            <MctaPropertyContainer label="msg: webshop.ImageRatioMCTA {Image ratio:}">
                <MctaCombobox
                    options={imageRatioOptions}
                    value={component.imageRatio}
                    className={mctaStyles.imageStyle}
                    onChange={
                        ({ value }) => { dispatch({ type: actionTypes.FEATURED_PRODUCTS_IMAGE_RATIO_CHANGED, payload: value }); }
                    }
                    searchable={false}
                />
            </MctaPropertyContainer>
            {component.isSingleProduct &&
                <React.Fragment>
                    <MctaVerticalBar />
                    <HorizontalSpacer x={12} />
                    <GlobalstyleSelector
                        selected={(autoColorMode) ? viewDetailsButtonThemeSelected : viewDetailsButtonId}
                        selectedKind={buttonKind}
                        onChangeAction={buttonStyleOnChangeAction}
                        forwardToSelectedComponent
                        options={buttonStyleOtions}
                        optionRenderer={buttonStyleOptRenderer}
                        dispatch={dispatch}
                        style={{ width: 185 }}
                        menuStyle={DEFAULT_MCTA_COMBOBOX_MENUSTYLE}
                        mcta
                        showEditLink
                        colorThemeOnChangeAction={buttonStyleColorThemeOnChangeAction}
                        currentStylesheetId={buttonStyleCurrentStylesheetId}
                    />
                    <HorizontalSpacer x={12} />
                </React.Fragment>}
            <MctaVerticalBar />
            {component.imageRatio !== imageRatioOptions[3].value && (
                <React.Fragment>
                    <HorizontalSpacer x={12} />
                    <div className={mctaStyles.imageStyleMCTACrop}>
                        <MctaCheckbox
                            label="msg: webshop.Crop {Crop}"
                            onClick={() => dispatch({ type: actionTypes.FEATURED_PRODUCTS_CROP_IMAGES_CHANGED })}
                            isChecked={component.cropImages}
                        />
                        <HorizontalSpacer x={12} />
                    </div>
                </React.Fragment>
            )}
            <MctaVerticalBar />
            <HorizontalSpacer x={12} />
            <div className={mctaStyles.imageStyleMCTACrop}>
                <MctaCheckbox
                    label="msg: component.webshop.ProductListStyles.Card {Card}"
                    onClick={() => dispatch({
                            type: actionTypes.FEATURED_PRODUCTS_PRODUCT_APPEARANCE_CHANGED,
                            payload: productAppearanceChangedPayload
                        })}
                    isChecked={component.productListStyle === ProductListStyles.Card}
                />
                <HorizontalSpacer x={12} />
            </div>
            {!component.isSingleProduct &&
                <React.Fragment>
                    <MctaVerticalBar />
                    <HorizontalSpacer x={12} />
                    <MctaSlider
                        min={1}
                        max={5}
                        label="msg: webshop.ProductsPerRow {Products per row:}"
                        valueLabel
                        value={component.productsPerRow}
                        defaultValue={component.productsPerRow}
                        onChange={value => dispatch({
                                type: actionTypes.FEATURED_PRODUCTS_PRODUCTS_PER_ROW_CHANGED,
                                payload: value,
                                amendToSelf: true
                            })}
                        sliderContainerClassName={mctaStyles.sliderContainerClassName}
                    />
                </React.Fragment>}
        </div>
    );
};

class MobileViewEditorV extends React.PureComponent<Props> {
    bindOnRowCountChange: Function;
    constructor(props: Props) {
        super(props);
        this.bindOnRowCountChange = this.onRowCountChange.bind(this);
    }

    onRowCountChange(count: number) {
        this.props.dispatch({
            type: actionTypes.FEATURED_PRODUCTS_PRODUCTS_PER_ROW_IN_MOBILE_CHANGED,
            payload: count
        });
    }
    render() {
        let productsPerRow;
        const { selectedComponent } = this.props;
        if (selectedComponent) {
            productsPerRow = selectedComponent && selectedComponent.productsPerRowInMobile;
        }
        return <div>
            <MctaColumnGrid
                count={1}
                selected={productsPerRow === 1}
                onClick={this.bindOnRowCountChange}
            />
            <HorizontalSpacer x={12} />
            <MctaVerticalBar />
            <HorizontalSpacer x={12} />
            <MctaColumnGrid
                count={2}
                selected={productsPerRow === 2}
                onClick={this.bindOnRowCountChange}
            />
            <HorizontalSpacer x={12} />
            <MctaVerticalBar />
            <HorizontalSpacer x={12} />
            <MctaSlider
                    label="msg: common.spacing {Spacing}"
                    min={0}
                    max={20}
                    sliderContainerClassName={mctaStyles.mobileMctaWebshopSlider}
                    value={selectedComponent.spacingItemsInMobile}
                    valueLabel="px"
                onChange={spacing => this.props.dispatch({
                            type: actionTypes.FEATURED_PRODUCTS_SPACING_ITEMS_IN_MOBILE_CHANGED,
                            payload: spacing,
                            amendToSelf: true
                        })}
            />
        </div>;
    }
}

export default {
    editView: EditView,
    mobileViewEditorV: (props: Props) => <MobileViewEditorV {...props} />,
    getEditButtonText: (props: Props) => {
        const { selectedComponentProps } = props;
        const { component } = selectedComponentProps;
        if (component.isSingleProduct) {
            return 'msg: component.webshop.productWidget.editSingleProduct {Edit product widget}';
        }
        return 'msg: component.webshop.featuredProducts.editFeaturedProduct {Edit featured product}';
    },
    CtaButtonTextView: (props: Props) => <CtaButtonTextView {...props} />,
    ctaOnClick: async (props: Props) => {
        const { selectedComponentProps, isSubscriptionTypeCompatible } = props;
        const { component } = selectedComponentProps;
        const { dispatch } = props;
        if (component.isSingleProduct) {
            const isWebshopSetupDone = await getWebshopSetupStatus();
            if (!isWebshopSetupDone) {
                if (isSubscriptionTypeCompatible) {
                    const componentId = component.id;
                    dispatch({
                        type: PRODUCT_WIDGET_UPDATE_PRODUCT_SETUP_WIDGET_METHOD,
                        payload: { componentId }
                    });
                    dispatch({ type: SHOW_WEBSHOP_ONBOARDING_DIALOG });
                }
            } else {
                dispatch({ type: PRODUCT_WIDGET_CTA_CLICKED });
            }
        } else {
            dispatch({ type: actionTypes.FEATURED_PRODUCTS_CTA_CLICKED });
        }
    }
};
