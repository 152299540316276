import React, { useState } from "react";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";

import { useIntl } from "../../../../../../wbtgen/src/view/intl";

import { saveButtonClickedAction } from '../actionTypes';
import { canSave as canSaveSelector } from "../../../common/selectors";
import { useStyles } from "./styles";

export const SaveButton = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const classes = useStyles();

    const canSave = useSelector(canSaveSelector);
    const [isSaved, setIsSaved] = useState(false);

    const onSaveClickHandler = () => {
        dispatch(saveButtonClickedAction());
        setIsSaved(true);
    };

    return (
        <Button
            color="primary"
            variant="contained"
            disabled={!canSave}
            className={classes.actionButton}
            onClick={onSaveClickHandler}
        >
            {isSaved && !canSave
                ? intl.msgJoint('msg: common.saved {Saved}')
                : intl.msgJoint('msg: common.save {Save}')}
        </Button>
    );
};
