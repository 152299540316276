import makePages from '../../../PropertiesPanel/view/makePages';
import * as main from './pages/main';
import * as style from '../../FeaturedProducts/propertiesPanel/pages/style';
import * as promo from '../../FeaturedProducts/propertiesPanel/pages/promotion';
import * as Pages from '../../FeaturedProducts/propertiesPanel/pages/ids';
import * as button from '../../FeaturedProducts/propertiesPanel/pages/button';
import addGoogleFont from "../../../PropertiesPanel/view/AddGoogleFont/index";

export default {
    pages: makePages(main, style, button, promo, addGoogleFont),
    defaultPageId: Pages.MAIN
};
