import * as mappers from '../../html/mappers/cssMapper/mappers';
import type { Element } from '../../flowTypes';

export default (element: Element): Record<string, any> => {
    // map only styles which has cssMapper
    return Object.keys(mappers)
        .reduce((styles, style) => {
            return !element.hasOwnProperty(style) ? styles : {
                ...styles,
                [style]: element[style]
            };
        }, {});
};
