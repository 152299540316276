import * as R from 'ramda';
import * as path from "../../../mappers/path";
import type { ButtonComponent } from "./flowTypes";

export const
    styleGlobalIdSelector = R.path(path.styleGlobalId),
    styleGlobalNameSelector = R.path(path.styleGlobalName),
    getButtonText = ({ text }: ButtonComponent): string => text,
    getLocalFontSize = (component: ButtonComponent) => R.path(path.styleTextSize, component),
    contactFormButtonGlobalStyleIdSelector = R.path(path.styleButtonGlobalId);
