
import React from 'react';
import { Flex } from '../../../../../view/reflexbox/index';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import PropertiesCheckBox from '../../../../../view/common/CheckBox/PropertiesCheckBox';
import { TooltipPosition } from "../../../../presentational/Tooltip/constants";
import { QuestionTip } from "../../../../presentational/Tooltip/questionTip/QuestionTip";
import Page from '../../../../PropertiesPanel/view/PropertiesPage';

import * as actionTypes from '../../actionTypes';
import * as OnClickActions from '../../constants/onClickActions';
import PpOnClickActionComboBox from '../../PpOnClickActionComboBox';

import * as pagesIds from '../pagesIds';
import pagesTitles from '../pagesTitles';

import type { Props } from '../flowTypes';
import styles from "./styles.css";

const
    id = pagesIds.ONCLICK,
    title = pagesTitles[id],
    showCaptionsTipMsg = "msg: component.instagram.pp.onClick.showCaption.tipMsg {Show the caption for each Instagram post.}",
    view = ({ selectedComponent: { onClickAction, captionsEnabled }, dispatch }: Props) => (
        <Page>
            <VerticalSpacer />
            <PpOnClickActionComboBox
                actionType={actionTypes.INSTAGRAM_GALLERY_ON_CLICK_ACTION_CHANGED}
                value={onClickAction}
                dispatch={dispatch}
            />
            {
                onClickAction === OnClickActions.OPEN_LIGHT_BOX &&
                <Flex justify="space-between" align="center">
                    <PropertiesCheckBox
                        isChecked={captionsEnabled}
                        label="msg: component.instagram.pp.showCaptions {Show captions}"
                        onClick={() => dispatch({ type: actionTypes.INSTAGRAM_GALLERY_PP_CAPTIONS_ENABLED_CHECKBOX_CLICKED })}
                    />
                    <QuestionTip
                        position={TooltipPosition.TOP}
                        className={styles.tipInfoIcnPos}
                        theme={{
                            container: styles.infoTipContainer,
                            tipInfo: styles.tipInfoColor
                        }}
                    >{showCaptionsTipMsg}</QuestionTip>
                </Flex>
            }
        </Page>
    );

export { id, view, title };
