import * as main from "./pages/main";
import * as layout from "./pages/advanced";
import * as styling from "./pages/style";
import reducer from "../reducer";
import makePages from "../../../PropertiesPanel/view/makePages";

const propertiesPanelConfig = {
    pages: makePages(main, layout, styling),
    defaultPageId: main.id,
    reducer
};
export default propertiesPanelConfig;
