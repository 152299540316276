import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import cx from 'classnames';

import { injectIntl } from '../../view/intl';
import { Dialog, DialogBody } from '../../view/common/dialogs/baseDialog';
import getCenteredDialogConfig from '../DialogManager/getCenteredDialogConfig';
import { HIDE_WELCOME_MESSAGE, setupMyShopAction } from './actionTypes';
import { START_QUICK_TOUR } from "../QuickTour/actionTypes";
import styles from './view/WelcomeMessage.css';
import subscriptionDataEpic from '../App/epics/subscriptionData';
import { isDemoSubscriptionType } from '../App/epics/subscriptionData/isDemoSubscriptionType';
import { trialImportAvailabilityReducer } from "../../../demo/modules/import/reducer/trialImportAvailabilityReducer";
import { StripTypes } from "../../view/common/dialogs/baseDialog/Dialog";
import { isTrialImported } from "../../../demo/modules/import/utils/isTrialImported";
import { useStyles } from './styles';
import VerticalSpacer from '../../view/common/VerticalSpacer';
import { isShopOnboardingStateSelector } from '../Onboarding/selectorActionTypes';

const
    width = 1000,
    height = 500,
    DEFAULT_TITLE = 'msg: welcomeToBeta.title {Welcome to your new Website Builder}',
    DEMO_TITLE = 'msg: demo.welcomeDialog.title {Welcome to the trial version of Website Builder}',
    WSB_IMPORTED_TITLE = 'msg: demo.welcomeDialog.websiteImported {Website imported!}',
    DEFAULT_MSG = `msg: welcomeToBeta.message {Let\'s show you around before you start building.}`,
    DEFAULT_SKIP_TOUR_LABEL = 'msg: welcomeToBeta.noThanksStartBuilding {No thanks, start building}',
    DEMO_SKIP_TOUR_LABEL = 'msg: demo.welcomeDialog.skipTourMsg {Skip tour}',
    SHOP_TITLE = 'msg: welcomeDialog.shop.title {Welcome to your new Online Shop}',
    SHOP_DESCRIPTION = 'msg: welcomeDialog.shop.description {Your Online Shop template is ready to go. What would you like to do next?}',
    SHOP_START_SETUP = 'msg: welcomeDialog.shop.setup {Set up my shop}',
    SHOW_ME_AROUND = 'msg: welcomeToBeta.showMeAround {Show me around}',
    TAKE_THE_TOUR = 'msg: welcomeDialog.takeTheTour {Take the tour}',
    NO_THANKS_GO_TO_EDITOR = 'msg: welcomeDialog.noThanksGoToEditor {Go to editor}',

    WelcomeMessageDialog = injectIntl(({ subscriptionData, trialImportAvailability, intl, dispatch }) => {
        const
            isDemo = isDemoSubscriptionType(subscriptionData),
            trialImported = isTrialImported(trialImportAvailability.trialImportAvailabilityData),
            skipTourLabel = isDemo || trialImported ? DEMO_SKIP_TOUR_LABEL : DEFAULT_SKIP_TOUR_LABEL,
            isShopOnboarding = useSelector(isShopOnboardingStateSelector),
            onClose = () => dispatch({ type: HIDE_WELCOME_MESSAGE }),
            classes = useStyles();
        let title = DEFAULT_TITLE;

        if (isDemo) {
            title = DEMO_TITLE;
        } else if (trialImported) {
            title = WSB_IMPORTED_TITLE;
        }

        const getShopContent = () => {
            return <React.Fragment>
                <span className={classes.welcomeTitle}>
                    {intl.msgJoint(SHOP_TITLE)}
                </span>
                <VerticalSpacer y={16} />
                <span className={classes.welcomeMessage}>
                    {intl.msgJoint(SHOP_DESCRIPTION)}
                </span>
                <VerticalSpacer y={24} />
                <div className={classes.shopMainBtns}>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.btn}
                        onClick={() => {
                            onClose();
                            dispatch({ type: START_QUICK_TOUR });
                        }}
                    >
                        {intl.msgJoint(TAKE_THE_TOUR)}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.btn}
                        onClick={() => {
                            dispatch(setupMyShopAction());
                        }}
                    >
                        {intl.msgJoint(SHOP_START_SETUP)}
                    </Button>
                </div>
                <VerticalSpacer y={24} />
                <span className={classes.noThanksLink} onClick={onClose}>
                    {intl.msgJoint(NO_THANKS_GO_TO_EDITOR)}
                </span>
            </React.Fragment>;
        };

        const getEditorContent = () => {
            return <React.Fragment>
                <span className={classes.welcomeTitle}>
                    {intl.msgJoint(title)}
                </span>
                <VerticalSpacer y={16} />
                <span className={classes.welcomeMessage}>
                    {intl.msgJoint(DEFAULT_MSG)}
                </span>
                <VerticalSpacer y={24} />
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.btn}
                    onClick={() => {
                        onClose();
                        dispatch({ type: START_QUICK_TOUR });
                    }}
                >
                    {intl.msgJoint(SHOW_ME_AROUND)}
                </Button>
                <VerticalSpacer y={24} />
                <span className={classes.noThanksLink} onClick={onClose}>
                    {intl.msgJoint(skipTourLabel)}
                </span>
            </React.Fragment>;
        };

        return (
            <Dialog
                onClose={onClose}
                stripType={StripTypes.NONE}
                className={classes.welcomeMessageDialog}
                closeBtnClassName={classes.welcomeMessageDialogCloseButton}
            >
                <DialogBody className={classes.container}>
                    <div className={classes.leftContainer}>
                        <div className={classes.wrapper}>
                            {isShopOnboarding ? getShopContent() : getEditorContent()}
                        </div>
                    </div>
                    <div className={cx(styles.rightContainer, isShopOnboarding ? styles.onlineShop : styles.wsb)} />
                </DialogBody>
            </Dialog>
        );
    }),
    config = getCenteredDialogConfig({
        width,
        height,
        component: WelcomeMessageDialog,
        dependsOn: {
            subscriptionData: subscriptionDataEpic.reducer,
            trialImportAvailability: trialImportAvailabilityReducer,
        },
        dialogBackgroundClassName: styles.backgroundClassName
    });

export default config;
