import * as React from 'react';
import styles from '../../MveHeaderProperteisPanel.css';
import type {
    InstancePropertiesPanelProps,
    InstancePropertiesPanelRegistryPage,
} from "../../../../../PropertiesPanel/instance/types";
import { MveHeaderPpPageId } from "../MveHeaderPpPageId";
import { TitleStyleMveHeaderPpPage } from '../TitleStyleMveHeaderPpPage';
import NavigationGroup from '../../../../../PropertiesPanel/view/IntlNavigationGroup';
import CheckBox from '../../../../../../view/common/CheckBox/PropertiesCheckBox';
import type { MobileHeaderState } from "../../../../../Mobile/header/types";
import {
    changeMveWebsiteTitleAction,
    SET_MVE_HEADER_LOGO_ACTION,
    setMveTitleTypeAction,
    toggleMveFixedHeaderAction,
    toggleMveShowLogoAction,
    toggleMveShowTitleAction,
    toggleMveUseCustomStyleAction,
    UNSET_MVE_HEADER_LOGO_ACTION,
} from "../../actions";
import VerticalSpacer from '../../../../../../view/common/VerticalSpacer';
import { ImageChooserBtnPP } from "../../../../../FileManager/imageChooser/ImageChooserBtnPP";
import { RadioButtonGroup } from "../../../../../../view/common/RadioButton/index";
import { MveHeaderTitleType } from "../../../constants";
import type { RadioButtonConfigType } from "../../../../../../view/common/RadioButton/flowTypes";
import { MainMveHeaderPpPageTip } from "./MainMveHeaderPpPageTip";
import InputField from '../../../../../../view/common/Input/InputField';
import lengthInputValidation from "../../../../../inputControls/input/commonValidations/lengthInputValidation";
import { MenuStyleMveHeaderPpPage } from "../MenuStyleMveHeaderPpPage";
import { BackgroundMveHeaderPpPage } from "../BackgroundMveHeaderPpPage";
import { MVE_WEBSITE_TITLE_MAX_LENGTH } from "./constants";
import { PropertiesPanelTextSection } from '../../../../../PropertiesPanel/view/components/Section/PropertiesPanelTextSection';
import { FcContentTypes } from "../../../../../../redux/modules/children/fileChooser/FcContentTypes";
import { SVGContentType } from "../../../../../oneweb/constants";

const PageTypeOptions: Array<RadioButtonConfigType> = [
    {
        label: 'msg: common.pageTitle {Page title}',
        value: MveHeaderTitleType.PAGE,
        sideChildren: <MainMveHeaderPpPageTip />,
    },
    {
        label: 'msg: common.websiteTitle {Website title}',
        value: MveHeaderTitleType.WEBSITE,
    },
];

class MainMvePpPageClass extends React.Component<InstancePropertiesPanelProps> {
    onShowLogoClick = () => this.props.dispatch(toggleMveShowLogoAction());

    onShowTitleClick = () => this.props.dispatch(toggleMveShowTitleAction());

    onFixedHeaderClick = () => {
        const { pin } = this.props.state.instance!.state;
        this.props.dispatch(toggleMveFixedHeaderAction(pin ? 0 : 1));
    };

    onTitleGroupChange = value => this.props.dispatch(setMveTitleTypeAction(value));

    onWebsiteTitleChange = value => this.props.dispatch(changeMveWebsiteTitleAction(value));

    onUseAutomaticStylingClick = () => this.props.dispatch(toggleMveUseCustomStyleAction());

    getNavigationProps = () => {
        const { navigateToPage } = this.props,
            targetPagesIds: string[] = [],
            { title: { show: showTitle } } =
                this.props.state.instance?.state as MobileHeaderState;
        let pagesTitles = {};

        if (showTitle) {
            targetPagesIds.push(MveHeaderPpPageId.TITLE_STYLE);
            pagesTitles[MveHeaderPpPageId.TITLE_STYLE] = TitleStyleMveHeaderPpPage.title;
        }
        targetPagesIds.push(MveHeaderPpPageId.MENU_STYLE, MveHeaderPpPageId.BACKGROUND);
        pagesTitles = {
            ...pagesTitles,
            [MveHeaderPpPageId.MENU_STYLE]: MenuStyleMveHeaderPpPage.title,
            [MveHeaderPpPageId.BACKGROUND]: BackgroundMveHeaderPpPage.title,
        };

        return {
            targetPagesIds,
            pagesTitles,
            navigateToPage,
        };
    };

    render() {
        const
            {
                logo: {
                    show: showLogo,
                    asset: logoAsset,
                },
                title: {
                    show: showTitle,
                    type: titleType,
                    value: titleValue,
                },
                pin: fixedHeader,
                useCustomStyle,
            } = this.props.state.instance?.state as MobileHeaderState,
            { dispatch, state: { isModernLayoutActivated } } = this.props;

        return (
            <div>
                <CheckBox
                    label="msg: common.showLogo {Show logo}"
                    isChecked={showLogo}
                    onClick={this.onShowLogoClick}
                />
                {showLogo && (
                    <React.Fragment>
                        <VerticalSpacer y={10} />
                        <ImageChooserBtnPP
                            asset={logoAsset}
                            chooseLabel="msg: addImage {Add image}"
                            changeAction={SET_MVE_HEADER_LOGO_ACTION}
                            removeAction={UNSET_MVE_HEADER_LOGO_ACTION}
                            dispatch={dispatch}
                            extraImageContentTypes={FcContentTypes.SVG}
                            isImageEditorAllowed={!!logoAsset && logoAsset.contentType !== SVGContentType}
                        />
                        <PropertiesPanelTextSection containerClassName={styles.logoHelpText}>{
                            `msg: mve.header.showLogo.helpText {Get the best result by using a .png with transparent background.}` // eslint-disable-line
                        }</PropertiesPanelTextSection>
                    </React.Fragment>
                )}
                <CheckBox
                    label="msg: common.showTitle {Show title}"
                    isChecked={showTitle}
                    onClick={this.onShowTitleClick}
                />
                {showTitle && (
                    <React.Fragment>
                        <VerticalSpacer y={10} />
                        <RadioButtonGroup
                            optionsConfig={PageTypeOptions}
                            value={titleType}
                            className={styles.titleRadioButtonGroup}
                            radioButtonClassName={styles.pageTitleRadioButton}
                            onChange={this.onTitleGroupChange}
                        />
                        { /* TODO: Refactor the mess in PP where each and every has it's own spacing ... */ }
                        {titleType === MveHeaderTitleType.WEBSITE && <VerticalSpacer y={10} />}
                    </React.Fragment>
                )}
                {showTitle && titleType === MveHeaderTitleType.WEBSITE && (
                    <div>
                        <InputField
                            value={titleValue || ''}
                            onChange={this.onWebsiteTitleChange}
                            usePropsValue
                            stopOnKeyDownEventPropagation
                            inputValidations={[
                                {
                                    validation: lengthInputValidation,
                                    options: { max: MVE_WEBSITE_TITLE_MAX_LENGTH },
                                }
                            ]}
                            rejectInvalidValue
                            trimValueOnPaste
                            placeholder="msg: mve.header.websiteTitle.placeholder {Add your website title}"
                            counter={{
                                maxLength: MVE_WEBSITE_TITLE_MAX_LENGTH,
                            }}
                        />
                    </div>
                )}
                <CheckBox
                    label="msg: common.fixedHeader {Fixed header}"
                    isChecked={!!fixedHeader}
                    onClick={this.onFixedHeaderClick}
                />
                {!isModernLayoutActivated &&
                <CheckBox
                    label="msg: common.useCustomStyling {Use custom styling}"
                    isChecked={useCustomStyle}
                    onClick={this.onUseAutomaticStylingClick}
                />}
                <VerticalSpacer y={10} />
                {!isModernLayoutActivated && useCustomStyle && <NavigationGroup {...this.getNavigationProps()} />}
            </div>
        );
    }
}

export const MainMveHeaderPpPage: InstancePropertiesPanelRegistryPage = {
    id: MveHeaderPpPageId.MAIN,
    title: 'msg: common.header {Header}',
    View: MainMvePpPageClass,
    isDefault: true,
};
