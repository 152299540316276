import browser from 'bowser';

export function isSafari() {
    return !!browser.safari;
}
export function isIE11() {
    const { msie, version } = browser;
    return !!(msie && version.toString().indexOf("11") !== -1);
}

export function isIE11OrEdge() {
    const { msedge } = browser;
    return !!(msedge || isIE11());
}
