import * as handleKinds from '../../../../utils/handle/kinds';

export const
    DIRECTIONS = {
        LEFT: 'left',
        RIGHT: 'right',
        TOP: 'top',
        BOTTOM: 'bottom'
    },
    oppositeDirections = {
        top: 'bottom',
        bottom: 'top',
        left: 'right',
        right: 'left'
    },
    filteringBBoxValues = {
        [handleKinds.ResizeE]: ['right'],
        [handleKinds.ResizeN]: ['top'],
        [handleKinds.ResizeNE]: ['top', 'right'],
        [handleKinds.ResizeNW]: ['top', 'left'],
        [handleKinds.ResizeS]: ['bottom'],
        [handleKinds.ResizeSE]: ['bottom', 'right'],
        [handleKinds.ResizeSW]: ['bottom', 'left'],
        [handleKinds.ResizeW]: ['left']
    },
    filterKindsForEasierWS = {
        [handleKinds.ResizeE]: ['right', 'left'],
        [handleKinds.ResizeN]: ['top', 'bottom'],
        [handleKinds.ResizeNE]: ['top', 'right', 'left', 'bottom'],
        [handleKinds.ResizeNW]: ['top', 'left', 'right', 'bottom'],
        [handleKinds.ResizeS]: ['bottom', 'top'],
        [handleKinds.ResizeSE]: ['bottom', 'right', 'left', 'top'],
        [handleKinds.ResizeSW]: ['bottom', 'left', 'right', 'top'],
        [handleKinds.ResizeW]: ['left', 'right']
    },
    handleKindsForNEWS = [
        handleKinds.ResizeN, handleKinds.ResizeE, handleKinds.ResizeW, handleKinds.ResizeS
    ],
    SNAP = {
        LEFT: 'left',
        RIGHT: 'right',
        TOP: 'top',
        BOTTOM: 'bottom',
        CENTER: 'center',
        MIDDLE: 'middle',
    };
