import React from 'react';
import * as pageIds from '../pageIds';
import pageTitles from '../pageTitles';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import { getComponentColorGradientSettings } from "../../../../../view/oneweb/commonComponentSelectors";
import ColorGradientSettings from '../../../../presentational/ColorGradientSettings/index';
import type { BackgroundPageProps } from "../flowTypes";
import * as ActionTypes from "../../actionTypes";
import { THEME_BG_OVERRIDE_COLOR_PICKER, DEFAULT_COLOR_PICKER } from '../../../../ColorPicker/constants';
import { BACKGROUND_THEME_WHITE } from "../../../../ThemeGlobalData/constants";

type Options = {
    tooltipTop?: string
};

const
    id = pageIds.GRADIENT_SETTINGS,
    title = pageTitles[id];

const BaseView = ({
    selectedComponent,
    dispatch,
    themeColorsData,
    themeSettingsData,
    tooltipTop
}: BackgroundPageProps) => {
    const { color, gradient, opacity } = getComponentColorGradientSettings(selectedComponent, themeSettingsData.autoColorMode);

    return (
        <Page>
            <VerticalSpacer />
            {
                themeSettingsData.autoColorMode ?
                    <ColorGradientSettings
                        colorPickerKind={THEME_BG_OVERRIDE_COLOR_PICKER}
                        themeColorsData={themeColorsData}
                        backgroundTheme={selectedComponent.selectedTheme || BACKGROUND_THEME_WHITE}
                        gradientTheme={selectedComponent.selectedGradientTheme || null}
                        colorChangeAction={ActionTypes.BG_BACKGROUND_COLOR_SET_AUTO_COLOR}
                        gradient={gradient}
                        gradientColorChangeAction={ActionTypes.BG_GRADIENT_COLOR_SET_AUTO_COLOR}
                        gradientColorRemoveAction={ActionTypes.BG_GRADIENT_COLOR_UNSET_AUTO_COLOR}
                        gradientDirectionChangeAction={ActionTypes.BG_GRADIENT_DIRECTION_CHANGE}
                        gradientFadePointChangeAction={ActionTypes.BG_GRADIENT_FADE_POINT_CHANGE}
                        opacity={opacity}
                        opacityChangeAction={ActionTypes.BG_OPACITY_CHANGE_AUTO_COLOR}
                        dispatch={dispatch}
                        tooltipTop={tooltipTop}
                    /> :
                    <ColorGradientSettings
                        colorPickerKind={DEFAULT_COLOR_PICKER}
                        color={color}
                        colorChangeAction={ActionTypes.BG_BACKGROUND_COLOR_SET}
                        colorRemoveAction={ActionTypes.BG_BACKGROUND_COLOR_UNSET}
                        colorOnTheFlyChange
                        gradient={gradient}
                        gradientColorChangeAction={ActionTypes.BG_GRADIENT_COLOR_SET}
                        gradientColorRemoveAction={ActionTypes.BG_GRADIENT_COLOR_UNSET}
                        gradientDirectionChangeAction={ActionTypes.BG_GRADIENT_DIRECTION_CHANGE}
                        gradientFadePointChangeAction={ActionTypes.BG_GRADIENT_FADE_POINT_CHANGE}
                        opacity={opacity}
                        opacityChangeAction={ActionTypes.BG_OPACITY_CHANGE}
                        dispatch={dispatch}
                    />
            }
        </Page>
    );
};

export default (options: Options = {}) => {
    const { tooltipTop } = options;

    return {
        id,
        title,
        view: (props: BackgroundPageProps) => (
            <BaseView
                // @ts-ignore
                tooltipTop={tooltipTop}
                {...props}
            />
        )
    };
};
