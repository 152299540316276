import { Lit } from "../../../lit";
import type { SocialAccountsState, InstagramSocialAccountDefaultState } from "./types";
import type { ReducerConfig } from "../../../../../src/dashboard/src/common/types";
import {
    SET_INSTAGRAM_ACCESS_CREDENTIALS_ACTION,
    UNSET_INSTAGRAM_ACCESS_TOKEN_ACTION,
    GET_INSTAGRAM_USER_MEDIA_REQUEST_ACTION,
    GET_INSTAGRAM_USER_MEDIA_SUCCESS_ACTION,
    GET_INSTAGRAM_USER_MEDIA_FAILURE_ACTION,
    DISCONNECT_INSTAGRAM_SUCCESS_ACTION,
    DISCONNECT_INSTAGRAM_FAILURE_ACTION,
    DISCONNECT_INSTAGRAM_REQUEST_ACTION,
    SET_TOTAL_INSTAGRAM_GALLERY_COMPONENTS,
    SET_TOTAL_CURRENT_PAGE_INSTAGRAM_GALLERY_COMPONENTS,
    SET_TOTAL_OTHER_PAGE_INSTAGRAM_GALLERY_COMPONENTS,
} from "./actions";
import { makeInitialStateReducer } from "../../../redux/makeReducer/makeInitialStateReducer";
import { getInstagramStockImagesData } from "./getInstagramStockImagesData";
import { GET_SOCIAL_MEDIA_COMPONENTS_USAGE_SUCCESS_ACTION } from "../actions";

const instagramSocialAccountDefaultState: InstagramSocialAccountDefaultState = {
    [Lit.instagramAssetsLoading]: false,
    [Lit.instagramConnected]: false,
    [Lit.instagramAccessToken]: undefined,
    [Lit.instagramAssetUrls]: getInstagramStockImagesData(),
    [Lit.instagramAccountName]: undefined,
    [Lit.totalInstagramGalleryComponents]: 0,
    [Lit.totalCurrentPageInstagramGalleryComponents]: 0,
    [Lit.totalOtherPagesInstagramGalleryComponents]: 0
};

export const socialAccountsDefaultState: SocialAccountsState = {
    [Lit.instagram]: instagramSocialAccountDefaultState
};

export const socialAccountsInstagramReducer = makeInitialStateReducer(
    // @ts-ignore
    socialAccountsDefaultState[Lit.instagram],
    (state: SocialAccountsState, action: Action) => {
        switch (action.type) {
            case SET_INSTAGRAM_ACCESS_CREDENTIALS_ACTION:
                return {
                    ...state,
                    [Lit.instagramConnected]: true,
                    [Lit.instagramAccessToken]: action.payload.instagramAccessToken,
                    [Lit.instagramAccountName]: action.payload.instagramAccountName
                };
            case UNSET_INSTAGRAM_ACCESS_TOKEN_ACTION:
                return {
                    ...state,
                    [Lit.instagramConnected]: false,
                    [Lit.instagramAccessToken]: null,
                    [Lit.instagramAssetUrls]: getInstagramStockImagesData(),
                    [Lit.instagramAccountName]: null,
                };
            case GET_INSTAGRAM_USER_MEDIA_REQUEST_ACTION:
                return {
                    ...state,
                    [Lit.instagramAssetUrls]: [],
                    [Lit.instagramAssetsLoading]: true,
                };
            case GET_INSTAGRAM_USER_MEDIA_SUCCESS_ACTION:
                return {
                    ...state,
                    [Lit.instagramAssetUrls]: action.payload,
                    [Lit.instagramAssetsLoading]: false,
                };
            case GET_INSTAGRAM_USER_MEDIA_FAILURE_ACTION:
                return {
                    ...state,
                    [Lit.instagramConnected]: false,
                    [Lit.instagramAccessToken]: null,
                    [Lit.instagramAssetUrls]: getInstagramStockImagesData(),
                    [Lit.instagramAssetsLoading]: false,
                };
            case DISCONNECT_INSTAGRAM_REQUEST_ACTION:
                return {
                    ...state,
                    [Lit.instagramAssetsLoading]: true,
                };
            case DISCONNECT_INSTAGRAM_SUCCESS_ACTION:
                return {
                    ...state,
                    [Lit.instagramConnected]: false,
                    [Lit.instagramAccessToken]: null,
                    [Lit.instagramAssetUrls]: getInstagramStockImagesData(),
                    [Lit.instagramAccountName]: null,
                    [Lit.instagramAssetsLoading]: false,
                };
            case DISCONNECT_INSTAGRAM_FAILURE_ACTION:
                return {
                    ...state,
                    [Lit.instagramAssetsLoading]: false,
                };
            case GET_SOCIAL_MEDIA_COMPONENTS_USAGE_SUCCESS_ACTION:
                return {
                    ...state,
                    [Lit.totalInstagramGalleryComponents]: action.payload.totalInstagramGalleryComponents,
                };
            case SET_TOTAL_INSTAGRAM_GALLERY_COMPONENTS:
                return {
                    ...state,
                    [Lit.totalInstagramGalleryComponents]: action.payload.totalComponents,
                };
            case SET_TOTAL_CURRENT_PAGE_INSTAGRAM_GALLERY_COMPONENTS:
                return {
                    ...state,
                    [Lit.totalCurrentPageInstagramGalleryComponents]: action.payload.totalComponents,
                };
            case SET_TOTAL_OTHER_PAGE_INSTAGRAM_GALLERY_COMPONENTS:
                return {
                    ...state,
                    [Lit.totalOtherPagesInstagramGalleryComponents]: action.payload.totalComponents,
                };
            default:
                return state;
        }
    }
);

export const socialAccountsInstagramReducerConfig: ReducerConfig = { reducer: socialAccountsInstagramReducer };
