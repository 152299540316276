import { SHADOW_TEXT_REGEX } from '../../constants';
import type { TinyMceEditor } from "../../../flowTypes";
import { getSelectedNodeProperty } from "../../utils/nodeUtils/getSelectedNodeProperty";
import { toHsl } from "../../../../../../../../dal/pageMapAdapter/mappers/Base/color";
import {
    DEFAULT_BLUR_RADIUS,
    DEFAULT_SHADOW_OFFSET_X,
    DEFAULT_SHADOW_OFFSET_Y
} from "../../../../../../oneweb/Text/constants";
import { isTextNode, windowGetComputedStyleSafe } from '../../../../../../../utils/dom';

const TEXT_SHADOW_INDEX = 0;
const TEXT_SHADOW_OFFSET_X_INDEX = 1;
const TEXT_SHADOW_OFFSET_Y_INDEX = 2;
const TEX_SHADOW_BLUR_RADIUS_INDEX = 3;

const getShadows = (node: HTMLElement) => {
    if (!node) {
        return '';
    }
    const computedStyle = windowGetComputedStyleSafe(node);

    // EDGE 17, 18 behaving wierdly, i was able to identify this place using error stack trace
    // not sure what is going wrong here WBTGEN-9814
    if (!computedStyle) {
        return '';
    }

    return computedStyle.getPropertyValue('text-shadow').match(SHADOW_TEXT_REGEX);
};

const makeGetShadowProperty = (index: number) => (node) => {
    const textShadows = !isTextNode(node) ? getShadows(node) : '';

    // In IE Edge order of text-shadow values is "offsetX offsetY blurRadius color"
    if (textShadows && /^rgb/i.test(textShadows[TEX_SHADOW_BLUR_RADIUS_INDEX])) {
        // @ts-ignore
        textShadows.unshift(textShadows.pop());
    }

    return textShadows ? textShadows[index] : '';
};

const makeSelector = (index: number) => {
    const getShadowProperty = makeGetShadowProperty(index);
    return (node) => {
        const nodeTextShadows = getShadowProperty(node);
        if (nodeTextShadows) {
            return nodeTextShadows;
        }
        return getShadowProperty(node.parentNode);
    };
};

const getShadow = (editor: TinyMceEditor, index: number, parser: Function) => {
    return parser(getSelectedNodeProperty(editor, makeSelector(index)));
};

export const getTextShadow = (editor: TinyMceEditor) => getShadow(
    editor,
    TEXT_SHADOW_INDEX,
    val => {
        return val && val !== 'none' ? toHsl(val) : null;
    }
);

export const getShadowOffsetX = (editor: TinyMceEditor) => getShadow(
    editor,
    TEXT_SHADOW_OFFSET_X_INDEX,
    val => {
        return val ? parseInt(val, 10) : DEFAULT_SHADOW_OFFSET_X;
    }
);

export const getShadowOffsetY = (editor: TinyMceEditor) => getShadow(
    editor,
    TEXT_SHADOW_OFFSET_Y_INDEX,
    val => {
        return val ? parseInt(val, 10) : DEFAULT_SHADOW_OFFSET_Y;
    }
);

export const getBlurRadius = (editor: TinyMceEditor) => getShadow(
    editor,
    TEX_SHADOW_BLUR_RADIUS_INDEX,
    val => {
        return val ? parseInt(val, 10) : DEFAULT_BLUR_RADIUS;
    }
);
