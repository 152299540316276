import * as actionTypes from './actionTypes';
import type { ImageAsset } from "../App/flowTypes";
import { editImageAC } from "../ImageEditor/actionCreators";
import { FC_SET_CONTENT_TYPES } from "../../redux/modules/children/fileChooser/actionTypes";

type SortingEndedPayload = {
    oldIndex: number,
    newIndex: number,
    collection: number
}

export const
    setContentTypeAction = (contentType: string) => ({
        type: FC_SET_CONTENT_TYPES,
        payload: {
            contentTypes: contentType
        }
    }),
    imageSelectedToSort = (index: number) => ({
        type: actionTypes.SORT_IMAGES_DIALOG_IMAGE_SELECTED_TO_SORT,
        payload: index
    }),
    sortingEnded = (payload: SortingEndedPayload) => ({
        type: actionTypes.SORT_IMAGES_DIALOG_SORTING_ENDED,
        payload
    }),
    updateImageCaption = (caption: string) => ({
        type: actionTypes.SORT_IMAGES_DIALOG_UPDATE_IMAGE_CAPTION,
        payload: caption
    }),
    updateAltText = (altText: string) => ({
        type: actionTypes.SORT_IMAGES_DIALOG_UPDATE_IMAGE_ALT_TEXT,
        payload: altText
    }),
    updateTitle = (title: string) => ({
        type: actionTypes.SORT_IMAGES_DIALOG_UPDATE_IMAGE_TITLE,
        payload: title
    }),
    deleteImageIconClick = (index: number) => ({
        type: actionTypes.SORT_IMAGES_DIALOG_IMAGE_DELETE_ICON_CLICKED,
        payload: index
    }),
    onImagePreviewEditClick = (asset: ImageAsset, index: number) => (
        editImageAC(
            actionTypes.SORT_IMAGES_DIALOG_REPLACE_WITH_EDITED_IMAGE,
            asset,
            { index }
        )
    ),
    onImageListItemEditClick = (asset: ImageAsset, index: number) => (
        editImageAC(
            actionTypes.SORT_IMAGES_DIALOG_REPLACE_WITH_EDITED_IMAGE,
            asset,
            { index }
        )
    );
