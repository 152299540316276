import React, { useEffect } from 'react';
import { Flex } from '../../../../../view/reflexbox';
import { Msg } from '../../../../../view/intl/index';
import { loadGoogleMapsScript } from "../../../../googleMaps/googleMaps";
import { type GoogleMapsViewProps, type GeocodeInput } from '../types';
import styles from './googleMaps.css';
import { DATA_TEST_ID } from '../constants';

export const GoogleMapsView = (props: GoogleMapsViewProps) => {
    const { zoom, setZoom, addressLocation, onPlaceChanged, height } = props;
    const width = props.stretch ? '100%' : props.width;
    const ref = React.useRef<HTMLDivElement>(null);

    const geocodePlaceId = async (geocoder: google.maps.Geocoder, geocodeInput: GeocodeInput) => {
        const { results } = await geocoder.geocode(geocodeInput);
        return results[0];
    };

    const renderMap = (mapElement, geocoder, addressLocationCenter) => {
        const { Map, Marker, event: MapEvent } = google.maps;

        const map = new Map(mapElement, {
            zoom,
            center: addressLocationCenter,
            mapTypeControl: false,
            streetViewControl: false,
            zoomControl: false,
            fullscreenControl: false,
            keyboardShortcuts: false,
            backgroundColor: styles.colorGray_e5
        });

        new Marker({ position: addressLocationCenter, map, }); // eslint-disable-line no-new

        MapEvent.addListener(map, 'click', function (event) {
            if (event.placeId) {
                geocodePlaceId(geocoder, { placeId: event.placeId })
                    .then(result => {
                        onPlaceChanged({
                            addressLocation: {
                                lat: result.geometry.location.lat(),
                                lng: result.geometry.location.lng(),
                            },
                            address: result.formatted_address,
                        });
                    });
            }
        });

        MapEvent.addListener(map, 'zoom_changed', () => setZoom(map.getZoom()));
    };

    useEffect(() => {
        loadGoogleMapsScript()
            .then(() => {
                const mapElement = ref.current;
                if (google && mapElement) {
                    const geocoder = new google.maps.Geocoder();
                    renderMap(ref.current, geocoder, addressLocation);
                }
            });
    }, [addressLocation]);

    return (
        <React.Fragment>
            {addressLocation && <div ref={ref} style={{ width, height }} data-testid={DATA_TEST_ID} />}
            {!addressLocation &&
                <Flex style={{ width, height, backgroundColor: styles.colorGray_e5 }} justify="center" align="center">
                    <Msg className={styles.mapEmptyText} k="widgets.googleMaps.dialog.mapPreviewHere">
                        Map preview will show here
                    </Msg>
                </Flex>}
        </React.Fragment>
    );
};
