
import getCenteredDialogConfig from "../../../DialogManager/getCenteredDialogConfig";
import { TemplatePagesDialog } from "./TemplatePagesDialog";
import siteDataEpic from '../../../App/epics/siteData/index';
import currentPageIdEpic from '../../../App/epics/currentPageId/index';
import templateEpic from '../epics/template/index';
import templateEpicValueActionType from '../epics/template/valueActionType';
import makeStateSelectorReducer from "../../../../epics/makeStateSelectorReducer";
import calcRenderProps from "./calcRenderProps";
import reducer from "./reducer";
import subscriptionDataEpic from '../../../App/epics/subscriptionData/index';

export default getCenteredDialogConfig({
    width: 740,
    height: 575,
    component: TemplatePagesDialog,
    dependsOn: {
        site: siteDataEpic.reducer,
        subscriptionData: subscriptionDataEpic.reducer,
        currentPageId: currentPageIdEpic.reducer,
        pages: makeStateSelectorReducer(
            templateEpic.reducer,
            templateEpicValueActionType,
            state => (state && state.pages) || []
        )
    },
    reducer,
    calcRenderProps
});
