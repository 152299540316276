import * as R from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly } from '../../../../epics/makeCondition';
import valueActionType from './valueActionType';
import menuComponentViewKind from '../../../oneweb/Menu/view/ShadowRender/viewKind';
import menuKind from '../../../oneweb/Menu/kind';
import memo from '../../../../../utils/memo';
import * as menuSelectors from '../../../oneweb/Menu/selectors';
import {
    ComponentsMapAndDeps,
    ReceiveOnlyUserInteractionModeSelector
} from "../../../Workspace/epics/componentsEval/selectorActionTypes";
import { IDLE } from "../../../Workspace/epics/componentsEval/userInteractionMutations/interactionModes";
import { getComputedStylesForMenu } from '../../../oneweb/Menu/utils';
import { ReceiveOnlyAttachments } from "../../../Workspace/epics/componentAttachements/selectorActionTypes";
import { getTopMostParentId } from "../../../Workspace/epics/componentAttachements/util";
import templateValueActionType from '../../../oneweb/Template/epics/template/valueActionType';

const
    MinimalComponentWidth = 20,
    MinimalComponentHeight = 20,
    WidthForMoreButtonModeCase = 50000,
    setComponentDimensions = memo(component => R.evolve({
        width: R.ifElse(
            R.always(menuSelectors.isMoreButtonModeSelector(component)),
            R.always(WidthForMoreButtonModeCase),
            R.always(MinimalComponentWidth)
        ),
        height: R.always(MinimalComponentHeight),
        left: R.always(0),
        top: R.always(0)
    })(component));

export default makeEpic({
    defaultState: {},
    valueActionType,
    updaters: [
        {
            conditions: [
                ReceiveOnlyUserInteractionModeSelector,
                ReceiveOnlyAttachments,
                receiveOnly(templateValueActionType),
                ComponentsMapAndDeps
            ],
            reducer: ({ values: [userInteractionMode, attachments, template, { componentsMap, componentsDependencies }], state }) => {
                if (userInteractionMode !== IDLE) {
                    return { state };
                }
                const newState = R.pipe(
                    R.filter(R.propEq('kind', menuKind)),
                    R.mapObjIndexed((component) => {
                        const componentDeps = componentsDependencies[menuKind];
                        return ({
                            kind: menuComponentViewKind,
                            props: {
                                component: setComponentDimensions(component),
                                ...componentDeps,
                                pages: componentDeps.siteData.folder.items,
                                computedStyles: getComputedStylesForMenu(componentsMap[getTopMostParentId(component.id, attachments)],
                                    componentDeps.themeSettingsData.autoColorMode, component, componentDeps.globalstyles,
                                    template && template.style)
                            }
                        });
                    })
                )(componentsMap);

                return { state: R.equals(state, newState) ? state : newState };
            }
        }
    ]
});
