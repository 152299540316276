export const
    repositoryTransparentImageMetadata = {
        animated: false,
        channels: 2,
        contentType: "image/gif",
        etag: "W/\"40-759092673\"",
        url: "repository:/transparent.gif",
        filesize: 64,
        format: "gif",
        hasAlpha: true,
        hasProfile: false,
        height: 1,
        space: "b-w",
        width: 1
    };
