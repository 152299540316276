import { combineReducers } from "../../../../src/dashboard/src/utils/combineReducers";
import { socialAccountsInstagramReducerConfig } from "./instagram/socialAccountsInstagramReducer";
import { Lit } from "../../lit";
import { socialAccountsFacebookFeedReducerConfig } from "./Facebook/FacebookFeed/socialAccountsFacebookFeedReducer";
import { socialAccountsFacebookChatReducerConfig } from "./Facebook/FacebookChat/socialAccountsFacebookChatReducer";

import type { ReducerConfig } from "../../../../src/dashboard/src/common/types";

export const socialAccountsReducer = combineReducers({
    [Lit.instagram]: socialAccountsInstagramReducerConfig,
    [Lit.facebookFeed]: socialAccountsFacebookFeedReducerConfig,
    [Lit.facebookChat]: socialAccountsFacebookChatReducerConfig,
});

export const socialAccountsReducerConfig: ReducerConfig = { reducer: socialAccountsReducer };
