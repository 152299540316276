import makeEpic from '../../../../epics/makeEpic';
import { COMPONENT_CONFIGURATION_COMPLETE } from "../../../../redux/modules/children/workspace/actionTypes";
import { makeActionForwardToSelectedComponent } from '../../../../redux/forwardTo';
import { openDialog, closeDialog } from '../../../App/actionCreators/index';
import valueActionType from './valueActionType';
import { configurationDialogId } from '../configurationDialog';
import { ConfigDialogSettings } from '../constants';
import * as actionTypes from '../actionTypes';

const
    defaultState = null,
    addComponentAction = pageURL => ({ type: COMPONENT_CONFIGURATION_COMPLETE, payload: { pageURL } }),
    updateComponentAction = pageURL => ({ type: actionTypes.FACEBOOKPAGE_UPDATE_PAGE_URL, payload: pageURL });

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [actionTypes.FACEBOOKPAGE_SET_PAGE_URL],
            reducer: ({ values: [{ pageURL, replace }] }) => {
                const
                    tmpAction: AnyAction = replace ? updateComponentAction(pageURL) : addComponentAction(pageURL),
                    actionToDispatch: AnyAction = replace ? makeActionForwardToSelectedComponent(tmpAction) : tmpAction;

                return { state: defaultState, actionToDispatch };
            }
        },
        {
            conditions: [actionTypes.FACEBOOKPAGE_SHOW_UPDATE_PAGE_URL_DIALOG],
            reducer: ({ values: [props] }) => ({
                state: defaultState,
                actionToDispatch: openDialog(
                    configurationDialogId,
                    { ...props, replace: true, onSaveText: ConfigDialogSettings.UPDATE_SAVE_LABEL }
                )
            })
        },
        {
            conditions: [actionTypes.FACEBOOKPAGE_UPDATE_PAGE_URL],
            reducer: () => ({ state: defaultState, actionToDispatch: closeDialog() })
        }
    ]
});
