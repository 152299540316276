/* eslint-disable max-len */

import { path } from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import * as Actions from '../../actionTypes';
import { ViewTypes } from "../../constants";
import { TemplateSelectorTemplateLocale2Selector } from '../templateSelectorTemplateLocaleEpic';
import {
    generalGlobalDataEpic
} from '../../../SiteSettings/General/generalGlobalDataEpic/generalGlobalDataEpic';
import { DOMAIN_FROM_AUTH_COOKIE_VALUE } from '../../../../redux/middleware/cookie/actionTypes';
import { isWsbDemo } from "../../../../debug/isWsbDemo";
import { LOAD_TEMPLATE_PAGES_FAILURE_ACTION } from "../../../oneweb/Template/actionTypes";
import { receiveOnly } from "../../../../epics/makeCondition";
import { ONBOARDING_VAT } from "../../../Onboarding/onboardingVat";
import { computeThemeDataForTemplateSelector, dynamicOnboardingEnableThemingRequest } from "../../../ThemeGlobalData/actionCreators";
import { DataSite } from "../../../../../dal/model/DataSite";
import { DEFAULT, CUSTOM, RECOMMENDED } from '../../../../constants/app';
import {
    THEME_COLORS,
    operationDetailsLocalizationsByTemplateIdPath,
    operationDetailsSiteSettingsPath,
    operationDetailsSiteMapPath,
    operationDetailsDataPageSetPath,
    getThemeColorIndex
} from "../../../ThemeGlobalData/constants";
import { getThemeColorsFromStylesheet } from "../../../Workspace/epics/stylesheets/selectors";
import { clickTemplateSelectorImport } from '../../actionTypes';
import {
    COMPUTE_COLOR_THEME_TEMPLATE_SELECTOR_SUCCESS,
    DYNAMIC_ONBOARDING_ENABLE_COLOR_THEME_SUCCESS,
    DYNAMIC_ONBOARDING_ENABLE_COLOR_THEME_FAILURE,
    AUTO_COLOR_MODE_ACTIVATED
} from '../../../ThemeGlobalData/actionTypes';
import { siteSettingsValueActionType } from '../../../App/epics/siteSettings/valueActionType';
import {
    SET_SELECTED_PALETTE_FOR_TEMPLATE_SELECTOR,
    ADD_THEME_HISTORY
} from "../../../AutoColorLeftPanel/actionTypes";
import { DYNAMIC_TEMPLATE_EPIC } from '../../../Onboarding/Dynamic/Epic/valueActionType';
import { SEE_ALL_TEMPLATES_CLICKED } from '../../../Onboarding/actionTypes';
import { openDialog } from '../../../App/actionCreators';
import { CONTINUE_TO_A_TEMPLATE_DIALOG } from '../../../Onboarding/Dynamic/view/ErrorModels/ContinueToATemplateDialog';
import { COLOR_THEME_SITE_SETTINGS_EPIC_VALUE } from "../../../SiteSettings/ColorThemeData/colorThemeSiteSettingsVAT";
import { SITE_SETTINGS_DOCUMENTS_UPDATE_SUCCESS } from '../../../App/actionTypes';

const
    sanitizeAsset = (asset) => {
        if (asset) {
            // Removing extra data associated with image to reduce the data sent through URI. Eg. Exif data
            // eslint-disable-next-line no-param-reassign
            asset.image = null;
            //asset.data is used only in workspace by image editor. It is not used for preview.
            // eslint-disable-next-line no-param-reassign
            asset.data = null;
        }
    },
    computePreviewSrc = (state) => {
        sanitizeAsset(path(['generalGlobalData', 'logoAsset'], state));
        sanitizeAsset(path(['generalGlobalData', 'favicon'], state));
        return ({
            ...state,
            src: `/api/v1/${state.template.accountName}/preview-wbtgen/${state.homePageId}.html?templateselectorpreview=${state.viewType}&locale=${state.locale2}&_q=${state.queryCounter}`

                + `&generalData=${encodeURIComponent(JSON.stringify(state.generalGlobalData))}&viewerDomain=${state.domain}&isTrial=${isWsbDemo()}`
        });
    },
    getPreviewStateForOnboarding = ({
        state,
        result,
        template,
        currentSiteSettings,
        isNormalType = false,
        backButtonActionType,
        autoColor
    }) => {
        const computedDataPageSetForNewOnBoarding = path(operationDetailsDataPageSetPath)(result) || null,
            siteMap = path(operationDetailsSiteMapPath)(result) || null,
            siteSettings = path(operationDetailsSiteSettingsPath)(result) || null,
            localizationsByTemplateId = path(operationDetailsLocalizationsByTemplateIdPath)(result) || null;

        let palette;
        if (computedDataPageSetForNewOnBoarding) {
            const themeColors = getThemeColorsFromStylesheet(computedDataPageSetForNewOnBoarding.stylesheet);
            palette = [themeColors.accentColor, themeColors.mainColor, themeColors.whiteColor, themeColors.blackColor];
        } else {
            palette = state.selectedColorPickerPalette.palette;
        }

        let autoColorMode = currentSiteSettings.themeSettingsData.autoColorMode;
        if (typeof autoColor === 'boolean') {
            autoColorMode = autoColor;
        }

        const newState = computePreviewSrc({
            ...state,
            autoColorMode,
            show: true,
            homePageId: template.uuidOfHomePage,
            viewType: ViewTypes.desktop,
            template,
            isNormalType,
            loading: false,
            backButtonActionType
        });

        return {
            state: {
                ...newState,
                selectedColorPickerTheme: DEFAULT,
                selectedColorPickerPalette: { type: state.isNextGenPreview ? RECOMMENDED : DEFAULT, palette },
                themePreviewDataForOnboarding: {
                    uuidOfHomePage: template.uuidOfHomePage,
                    designPageName: template.name,
                    computedThemeColorsForNewOnBoarding: null,
                    computedDataPageSetForNewOnBoarding,
                    siteMap: new DataSite(siteMap),
                    siteSettings,
                    localizationsByTemplateId,
                }
            }
        };
    },

    createImportTemplatePayload = (state) => {
        return {
            design: state.template,
            previewData: state.themePreviewDataForOnboarding.computedDataPageSetForNewOnBoarding,
            designSiteMap: state.themePreviewDataForOnboarding.siteMap,
            designSiteSettings: state.themePreviewDataForOnboarding.siteSettings,
            localizationsByTemplateId: state.themePreviewDataForOnboarding.localizationsByTemplateId,
            themeColors: state.themePreviewDataForOnboarding.computedThemeColorsForNewOnBoarding
        };
    },

    defaultState = {
        autoColorMode: false,
        show: false,
        homePageId: null,
        src: null,
        viewType: ViewTypes.desktop,
        isNormalType: true,
        pageId: null,
        queryCounter: 0, // used to refresh iframe url when refresh required,
        loading: false,
        backButtonActionType: null,
        isNextGenPreview: false,
        dynamicTemplatesPreviewShown: false,
        isSelectedFromTrial: false
    };

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                Actions.DYNAMIC_TEMPLATE_UPDATE_PAGE_DATA
            ],
            reducer: ({ state, values: [components] }) => {
                const newCmpsMap = components.reduce((acc, cmp) => ({ ...acc, [cmp.id]: cmp }), {});
                const items = path(['themePreviewDataForOnboarding', 'computedDataPageSetForNewOnBoarding', 'pages', 0, 'items'], state);
                let newState = state;
                if (items) {
                    const newItems = items.map(item => {
                        if (newCmpsMap[item.id]) {
                            return newCmpsMap[item.id];
                        }
                        return item;
                    });
                    newState = {
                        ...state,
                        themePreviewDataForOnboarding: {
                            ...state.themePreviewDataForOnboarding,
                            computedDataPageSetForNewOnBoarding: {
                                ...state.themePreviewDataForOnboarding.computedDataPageSetForNewOnBoarding,
                                pages: [
                                    {
                                        ...state.themePreviewDataForOnboarding.computedDataPageSetForNewOnBoarding.pages[0],
                                        items: newItems
                                    }
                                ]
                            }
                        }
                    };
                }
                return {
                    state: computePreviewSrc(newState),
                    actionToDispatch: { type: Actions.DYNAMIC_TEMPLATE_AFTER_PAGE_DATA_UPDATE }
                };
            }
        },
        {
            conditions: [DOMAIN_FROM_AUTH_COOKIE_VALUE],
            reducer: ({ values: [domain], state }) => {
                return {
                    state: {
                        ...state,
                        domain
                    }
                };
            }
        },
        {
            conditions: [
                Actions.HIDE_TEMPLATE_LIST
            ],
            reducer: ({ state }) => {
                return {
                    state: { ...state, show: false },
                };
            }
        },
        {
            conditions: [
                Actions.BACK_TO_TEMPLATE_SELECTOR,
            ],
            reducer: ({ state, values: [backButtonActionToDispatch] }) => {
                const actionToDispatch = backButtonActionToDispatch && backButtonActionToDispatch.backButtonActionType
                    ? { type: backButtonActionToDispatch.backButtonActionType }
                    : null;

                return {
                    state: { ...state, show: false },

                    actionToDispatch
                };
            }
        },
        {
            conditions: [
                TemplateSelectorTemplateLocale2Selector
            ],
            reducer: ({ state, values: [locale2] }) => ({
                state: {
                    ...state,
                    locale2
                }
            })
        },
        {
            conditions: [
                generalGlobalDataEpic.valueActionType
            ],
            reducer: ({ state, values: [generalGlobalData] }) => ({
                state: {
                    ...state,
                    generalGlobalData
                }
            }),
        },
        {
            conditions: [
                Actions.CHANGE_TEMPLATE_SELECTOR_PREVIEW_MODE
            ],
            reducer: ({ state, values: [previewMode] }) => ({
                state: computePreviewSrc({
                    ...state,
                    viewType: previewMode,
                })
            })
        },
        {
            conditions: [Actions.TEMPLATE_SELECTOR_SHOW_PREVIEW],
            reducer: ({ values: [{ template, isNormalType, backButtonActionType, autoColorMode = false, isSelectedFromTrial = false }], state }) => {
                if (isSelectedFromTrial) {
                    return {
                        state: {
                            ...state,
                            homePageId: template.uuidOfHomePage,
                            isNextGenPreview: false,
                            isNormalType,
                            isSelectedFromTrial
                        },
                        actionToDispatch: computeThemeDataForTemplateSelector(template.templateIds[0], template.accountName),
                    };
                }

                if (autoColorMode) {
                    return {
                        state,
                        actionToDispatch: computeThemeDataForTemplateSelector(template.templateIds[0], template.accountName),
                    };
                }

                return {
                    state: computePreviewSrc({
                        ...state,
                        autoColorMode,
                        show: true,
                        homePageId: template.uuidOfHomePage,
                        viewType: ViewTypes.desktop,
                        template,
                        isNormalType,
                        loading: false,
                        backButtonActionType,
                        isNextGenPreview: false
                    })
                };
            }
        },
        {
            conditions: [
                COMPUTE_COLOR_THEME_TEMPLATE_SELECTOR_SUCCESS,
                TemplateSelectorTemplateLocale2Selector,
                receiveOnly(Actions.TEMPLATE_SELECTOR_SHOW_PREVIEW),
                receiveOnly(siteSettingsValueActionType),
            ],
            reducer: ({
                state,
                values: [
                    result,
                    locale2,
                    { template, isNormalType, backButtonActionType, isSelectedFromTrial },
                    { current: currentSiteSettings },
                ],
            }) => {
                let autoColor;
                if (isSelectedFromTrial) {
                    return {
                        ...getPreviewStateForOnboarding({
                            state: {
                                ...state,
                                locale2,
                                show: true
                            },
                            result,
                            template,
                            currentSiteSettings,
                            isNormalType,
                            backButtonActionType,
                            autoColor
                        }),
                        actionToDispatch: {
                            type: Actions.PRE_SELECTED_TEMPLATE_PREVIEW_CLICKED
                        }
                    };
                }

                return getPreviewStateForOnboarding({
                    state: {
                        ...state,
                        isNextGenPreview: false,
                        locale2
                    },
                    result,
                    template,
                    currentSiteSettings,
                    isNormalType,
                    backButtonActionType,
                    autoColor
                });
            },
        },
        {
            conditions: [
                Actions.DYNAMIC_TEMPLATE_SHOW_PREVIEW_INIT_FAILED,
            ],
            reducer: ({ state }) => ({
                state: {
                    ...state,
                    dynamicTemplatesPreviewShown: false
                }
            })
        },
        {
            conditions: [
                Actions.DYNAMIC_TEMPLATE_SHOW_PREVIEW,
                receiveOnly(DYNAMIC_TEMPLATE_EPIC),
                receiveOnly(siteSettingsValueActionType),
            ],
            reducer: ({ values: [
                { previewData, designData, backButtonActionType },
                { businessName, gmbKey, contactInfo, language },
                { current: currentSiteSettings },
            ], state }) => {
                let newGeneralData = {
                    ...state.generalGlobalData,
                    websiteTitle: businessName,
                    gmbCategories: gmbKey
                };
                if (contactInfo) {
                    newGeneralData = {
                        ...newGeneralData,
                        phoneNumber: contactInfo.phone,
                        contactEmail: contactInfo.email,
                        address: contactInfo.address,
                        ...(contactInfo.completeAddress || {}),
                    };
                }
                if (businessName.trim()) {
                    newGeneralData.mobile = {
                        ...(newGeneralData.mobile || {}),
                        title: {
                            show: true,
                            type: 'website'
                        }
                    };
                }
                return getPreviewStateForOnboarding({
                    state: {
                        ...state,
                        generalGlobalData: newGeneralData,
                        isNextGenPreview: true,
                        dynamicTemplatesPreviewShown: true,
                        locale2: language
                    },
                    autoColor: true,
                    isNormalType: false,
                    result: previewData,
                    template: designData,
                    currentSiteSettings,
                    backButtonActionType
                });
            }
        },
        {
            conditions: [Actions.CHANGE_SELECTED_COLOR_PALETTE_ADD_TEMPLATE],
            reducer: ({ state, values: [selectedColorPickerPalette] }) => {
                return {
                    state: {
                        ...state,
                        selectedColorPickerPalette,
                        themePreviewDataForOnboarding: {
                            ...state.themePreviewDataForOnboarding,
                            computedThemeColorsForNewOnBoarding: THEME_COLORS.reduce((acc, prop, index) => (
                                { ...acc, [prop]: selectedColorPickerPalette.palette[index] }
                            ), {}),
                        },
                    },
                };
            },
        },
        {
            conditions: [SET_SELECTED_PALETTE_FOR_TEMPLATE_SELECTOR],
            reducer: ({ state, values: [palette] }) => {
                const multipleActionsToDispatch = [
                    {
                        type: Actions.CHANGE_SELECTED_COLOR_PALETTE_ADD_TEMPLATE,
                        payload: {
                            type: CUSTOM,
                            palette
                        }
                    },
                    {
                        type: ADD_THEME_HISTORY,

                        payload: palette
                    }
                ];
                return {
                    state,
                    multipleActionsToDispatch
                };
            },
        },
        {
            conditions: [Actions.CHANGE_COLOR_PICKER_THEME_METHOD_ADD_TEMPLATE],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        selectedColorPickerTheme: state.selectedColorPickerTheme === DEFAULT ? CUSTOM : DEFAULT,
                        temporarySelectedColorPickerPalette: (
                            state.selectedColorPickerTheme === DEFAULT ? state.selectedColorPickerPalette : null
                        )
                    },
                };
            },
        },
        {
            conditions: [Actions.CHANGE_COLOR_PICKER_CUSTOM_THEME_COLOR_ADD_TEMPLATE],
            reducer: ({ values: [{ color, themeColorName }], state }) => {
                const selectedPalette = state.selectedColorPickerPalette.palette;
                const colorIndex = getThemeColorIndex(themeColorName);
                const newSelectedPalette = selectedPalette.map(palette => palette.slice());

                newSelectedPalette[colorIndex] = color.slice();

                const actionToDispatch = {
                    type: Actions.CHANGE_SELECTED_COLOR_PALETTE_ADD_TEMPLATE,
                    payload: {
                        type: CUSTOM,
                        palette: newSelectedPalette
                    }
                };
                return {
                    state,
                    actionToDispatch
                };
            }
        },
        {
            conditions: [Actions.ONBOARDING_THEMING_BACK_BUTTON_PRESSED_ADD_TEMPLATE],
            reducer: ({ state }) => ({ state: { ...state, show: false } }),
        },
        {
            conditions: [Actions.CANCEL_COLOR_PICKER_CUSTOM_THEME_COLOR_ADD_TEMPLATE],
            reducer: ({ state }) => {
                return {
                    state: { ...state, selectedColorPickerPalette: state.temporarySelectedColorPickerPalette },
                    multipleActionsToDispatch: [
                        { type: Actions.CHANGE_COLOR_PICKER_THEME_METHOD_ADD_TEMPLATE },
                        {
                            type: Actions.CHANGE_SELECTED_COLOR_PALETTE_ADD_TEMPLATE,
                            payload: { type: DEFAULT, palette: state.temporarySelectedColorPickerPalette.palette },
                        },
                    ]
                };
            }
        },
        {
            conditions: [Actions.TEMPLATE_SELECTOR_CHANGE_THEME_PREVIEW_PAGE_ADD_TEMPLATE],
            reducer: ({ values: [{ pageId }], state }) => {
                return {
                    state: {
                        ...state,
                        themePreviewDataForOnboarding: {
                            ...state.themePreviewDataForOnboarding,
                            uuidOfHomePage: pageId
                        }
                    }
                };
            }
        },
        {
            conditions: [Actions.ONBOARDING_TEMPLATE_SELECTED_ADD_TEMPLATE],
            reducer: ({ values: [payload], state }) => {
                if (!(payload && payload.skipWarningDialog) && state.dynamicTemplatesPreviewShown) {
                    return {
                        state,
                        actionToDispatch: openDialog(CONTINUE_TO_A_TEMPLATE_DIALOG)
                    };
                }
                return {
                    state,
                    actionToDispatch: clickTemplateSelectorImport(
                        state.template,
                        state.themePreviewDataForOnboarding.computedThemeColorsForNewOnBoarding
                    )
                };
            }
        },
        {
            conditions: [
                receiveOnly(ONBOARDING_VAT),
                Actions.TEMPLATE_SELECTOR_CALCULATE_PREVIEW
            ],
            reducer: ({ values: [{ themingTemplatePreviewMode }, { template, isNormalType, backButtonActionType }], state }) => {
                return {
                    state: computePreviewSrc({
                        ...state,
                        show: false,
                        homePageId: template.uuidOfHomePage,
                        viewType: themingTemplatePreviewMode,
                        template,
                        isNormalType,
                        loading: false,
                        backButtonActionType
                    })
                };
            }
        },
        {
            conditions: [
                Actions.TEMPLATE_SELECTOR_MOBILE_ORIENTATION_TOGGLE
            ],
            reducer: ({ state }) => {
                const viewType = (state.viewType === ViewTypes.mobileVertical)
                    ? ViewTypes.mobileHorizontal
                    : ViewTypes.mobileVertical;
                return {
                    state: computePreviewSrc({
                        ...state,
                        viewType,
                    })
                };
            }
        },
        {
            conditions: [Actions.REFRESH_TEMPLATE_PREVIEW],
            reducer: ({ state }) => ({ state: computePreviewSrc({ ...state, queryCounter: state.queryCounter + 1 }) })
        },
        ...[Actions.COPY_REPOSITORY_TEMPLATE_FAILURE, LOAD_TEMPLATE_PAGES_FAILURE_ACTION].map(action => ({
            conditions: [action],
            reducer: ({ state }) => ({ state: { ...state, loading: false } })
        })),
        ...[Actions.COPY_REPOSITORY_TEMPLATE_SUCCESS, Actions.PUT_NEW_TEMPLATE_SUCCESS, Actions.PUT_NEW_STYLESHEET_SUCCESS].map(action => ({
            conditions: [action],
            reducer: ({ state }) => ({ state: { ...state, dynamicTemplatesPreviewShown: false } })
        })),
        {
            conditions: [
                receiveOnly(COLOR_THEME_SITE_SETTINGS_EPIC_VALUE),
                Actions.DYNAMIC_TEMPLATE_IMPORT_TEMPLATE_CLICK
            ],
            reducer: ({ state, values: [{ autoColorMode }] }) => {
                let actionToDispatch;
                let isNextGenPreview = true;
                if (!autoColorMode) {
                    // @ts-ignore
                    actionToDispatch = dynamicOnboardingEnableThemingRequest(null, null, true);
                } else {
                    isNextGenPreview = false;
                    actionToDispatch = {
                        type: Actions.DYNAMIC_TEMPLATE_IMPORT_TEMPLATE,
                        payload: createImportTemplatePayload(state),
                    };
                }
                return {
                    state: {
                        ...state,
                        isNextGenPreview,
                        loading: true,
                    },
                    actionToDispatch
                };
            }
        },
        {
            conditions: [
                DYNAMIC_ONBOARDING_ENABLE_COLOR_THEME_SUCCESS
            ],
            reducer: ({ state, values: [{ operationDetails }] }) => {
                const multipleActionsToDispatch: Action[] = [{
                    type: SITE_SETTINGS_DOCUMENTS_UPDATE_SUCCESS,
                    payload: operationDetails
                }];
                multipleActionsToDispatch.push({
                    type: Actions.DYNAMIC_TEMPLATE_IMPORT_TEMPLATE,
                    payload: createImportTemplatePayload(state),
                });
                multipleActionsToDispatch.push({
                    type: AUTO_COLOR_MODE_ACTIVATED
                });
                return {
                    state: {
                        ...state,
                        isNextGenPreview: false,
                        loading: true,
                    },
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [
                DYNAMIC_ONBOARDING_ENABLE_COLOR_THEME_FAILURE
            ],
            reducer: ({ state }) => {
                const actionToDispatch = {
                    type: Actions.DYNAMIC_TEMPLATE_IMPORT_TEMPLATE,
                    payload: createImportTemplatePayload(state),
                };
                return {
                    state: {
                        ...state,
                        isNextGenPreview: false,
                        loading: true,
                    },
                    actionToDispatch
                };
            }
        },
        {
            conditions: [
                Actions.LOAD_TEMPLATE_SELECTOR_REPOSITORY_SITE_SUCCESS_ACTION
            ],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        loading: false
                    }
                };
            }
        },
        {
            conditions: [
                SEE_ALL_TEMPLATES_CLICKED
            ],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state,
                        show: false
                    }
                };
            }
        }
    ]
});
