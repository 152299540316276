
import React from 'react';
import { pure } from 'recompose';
import {
    MctaPropertyContainer, MctaVerticalBar, MctaCombobox, MctaColumnGrid
} from '../../../componentMainActions/index';
import { MctaSlider } from '../../../componentMainActions/MctaSlider/MctaSlider';
import * as actionTypes from '../actionTypes';
import HorizontalSpacer from '../../../../view/common/HorizontalSpacer';
import type { InstagramGalleryComponent } from "../flowTypes";
import {
    columnOptions,
    rowOptions,
    SLIDER_MAX_SIZE,
    SLIDER_MAX_SIZE_MVE
} from '../constants/index';
import Msg from '../../../../view/intl/Msg';
import { propPanelNavigateToPageAC } from '../../../PropertiesPanel/actionCreators';
import * as pageIds from '../propertiesPanel/pagesIds';
import { OPEN_PROPERTIES_PANEL } from '../../../Workspace/actionTypes';

type MobileMCTAProps = {
    dispatch: Dispatch;
    id: string;
    selectedComponent: InstagramGalleryComponent,
    children?: any
};

const
    EditView = pure(({ selectedComponent: { columns, rows, spacingPx }, dispatch, children }) => (
        <div>
            <HorizontalSpacer x={5} />
            <MctaPropertyContainer label="msg: component.gallery.propPanel.thumbnails.columns {Columns:}">
                <MctaCombobox
                    useOptionsAsIs
                    searchable={false}
                    value={columns}
                    options={columnOptions}
                    onChange={({ value }) => dispatch({
                        type: actionTypes.INSTAGRAM_GALLERY_PP_COLUMNS_CHANGED,
                        payload: value,
                        amendToSelf: true
                    })}
                />
            </MctaPropertyContainer>
            <HorizontalSpacer x={10} />
            <MctaVerticalBar />
            <HorizontalSpacer x={10} />
            <MctaPropertyContainer label="msg: component.instagram.mcta.layout.rows {Rows:}">
                <MctaCombobox
                    useOptionsAsIs
                    searchable={false}
                    value={rows}
                    options={rowOptions}
                    onChange={({ value }) => dispatch({
                        type: actionTypes.INSTAGRAM_GALLERY_PP_ROWS_CHANGED,
                        payload: value,
                        amendToSelf: true
                    })}
                />
            </MctaPropertyContainer>
            <HorizontalSpacer x={13} />
            <MctaSlider
                label="msg: component.gallery.propPanel.thumbnails.spacing {Spacing}"
                min={0}
                max={SLIDER_MAX_SIZE}
                value={spacingPx}
                valueLabel="px"
                defaultValue={spacingPx}
                onChange={spacing => dispatch({
                    type: actionTypes.INSTAGRAM_GALLERY_PP_SPACING_CHANGED,
                    payload: spacing,
                    amendToSelf: true
                })}
            />
            <HorizontalSpacer x={13} />
            {children}
        </div>
    ));
class MobileViewEditorV extends React.PureComponent<MobileMCTAProps> {
    bindMobileColumnChange: Function;
    constructor(props: MobileMCTAProps) {
        super(props);
        this.bindMobileColumnChange = this.mobileColumnChange.bind(this);
    }

    mobileColumnChange(count: number) {
        this.props.dispatch({
            type: actionTypes.INSTAGRAM_GALLERY_MOBILE_COLUMN_CHANGE,
            payload: {
                count
            }
        });
    }
    render() {
        const
            { dispatch, selectedComponent: { mobileSettings: { columns, spacingPx } } } = this.props,
            oneColumn = 1,
            twoColumn = 2;
        return <div>
            <MctaColumnGrid
                count={oneColumn}
                selected={columns === oneColumn}
                onClick={this.bindMobileColumnChange}
            />
            <HorizontalSpacer x={5} />
            <MctaVerticalBar />
            <HorizontalSpacer x={5} />
            <MctaColumnGrid
                count={twoColumn}
                selected={columns === twoColumn}
                onClick={this.bindMobileColumnChange}
            />
            <HorizontalSpacer x={5} />
            <MctaVerticalBar />
            <HorizontalSpacer x={10} />
            <MctaSlider
                label="msg: component.gallery.propPanel.thumbnails.spacing {Spacing}"
                min={0}
                max={SLIDER_MAX_SIZE_MVE}
                value={spacingPx}
                valueLabel="px"
                onChange={spacing => dispatch({
                    type: actionTypes.INSTAGRAM_GALLERY_MOBILE_SPACING_CHANGE,
                    payload: {
                        spacing
                    },
                    amendToSelf: true
                })}
                forMve
            />
            <HorizontalSpacer x={5} />
        </div>;
    }
}
export default {
    editView: EditView,
    mobileViewEditorV: (props: MobileMCTAProps) => <MobileViewEditorV {...props} />,
    editButtonText: 'msg: component.instagramGallery.settings {Settings}',
    CtaButtonTextView: ({ socialAccounts }: Record<string, any>) => {
        const { instagram: { instagramConnected } } = socialAccounts;

        return (
            instagramConnected ?
                <Msg k="component.instagramGallery.disconnectInstagramAccount">Disconnect Instagram account</Msg> :
                <Msg k="component.instagramGallery.connectInstagramAccount">Connect Instagram account</Msg>
        );
    },
    ctaOnClick: (props: MobileMCTAProps) => {
        const { dispatch } = props;
        dispatch({ type: OPEN_PROPERTIES_PANEL });
        dispatch(propPanelNavigateToPageAC(pageIds.ACCOUNT, props));
    },
};
