export const
    DialogWidth = 470,
    DialogHeight = 220,
    PopupWindowWidth = 973,
    PopupWindowHeight = 798,
    DialogMessages = {
        TITLE: 'msg: component.instagramFeed.accountDisconnected {Instagram account disconnected}',
        MESSAGE: 'msg: component.instagramFeed.accountDisconnected.message {Your account has been disconnected by Instagram, who require all users to reauthorise every 60 days. Simply reconnect again to continue showing your feed on your site.}', // eslint-disable-line max-len
        RECONNECT_LABEL: 'msg: component.instagramFeed.accountDisconnected.reconnectLabel {Reconnect Instagram}',
        CANCEL_LABEL: 'msg: component.instagramFeed.accountDisconnected.cancel {Cancel}'
    };
