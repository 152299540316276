/* eslint-disable max-len */

import { makeCombineReducer, getDefaultReducerState } from '../../../makeReducer';
import { makeInitialStateReducer } from '../../../makeReducer/makeInitialStateReducer';
import statusEpic from '../../../../components/Workspace/epics/status/index';
import isEditingModePanelMinimized from './children/isEditingModePanelMinimized/index';
import { reducer as browserDimensions } from "./reducers/windowResized";
import isPageModeEpic from "../../../../components/Workspace/epics/isPageMode/index";
import renderGlobalstyles from '../../../../components/RenderGlobalstyles/reducer/index';
import shadowRendererStateEpic from '../../../../components/ShadowRenderer/epics/state/index';
import stylesShadowRendererEpic from '../../../../components/StylesShadowRenderer/epics/index';
import * as componentsEval from '../../../../components/Workspace/epics/componentsEval/index';
import topMostHandleEpic from '../../../../components/Workspace/epics/topMostHandle/index';
import componentHoverDecorationsEpic from '../../../../components/Workspace/epics/componentHoverDecorations/index';
import componentAttachDecorationsEpic from '../../../../components/Workspace/epics/componentAttachDecorations/index';
import componentsSelectionDecorationEpic from '../../../../components/Workspace/epics/componentsSelectionDecoration/index';
import templateRenderOffsetEpic from '../../../../components/oneweb/Template/epics/templateRenderOffset/index';
import shiftBarDecorations from '../../../../components/Workspace/epics/shiftBarDecos/index';
import selectionFrameDecorationEpic from '../../../../components/Workspace/epics/selectionFrameDecorationEval/index';
import componentsPropsReducer from '../../../../components/Workspace/epics/componentsProps/reducer';
import componentMainActionsEpic from '../../../../components/Workspace/epics/componentMainActions/index';
import componentPanelActionsEpic from '../../../../components/Workspace/epics/componentPanelActions/index';
import isMouseOverWorkspaceEpic from '../../../../components/Workspace/epics/isMouseOverWorkspace/index';
import isMouseOverMCTAEpic from '../../../../components/Workspace/epics/isMouseOverMCTA/index';
import scrollEpic from '../../../../components/Workspace/epics/scroll/index';
import handlesEpic from '../../../../components/Workspace/epics/handles/index';
import templateEpic, { widthReducer as templateWidth } from '../../../../components/oneweb/Template/epics/template/index';
import redDecorations from '../../../../components/Workspace/epics/redDecorations/index';
import resizeDecos from '../../../../components/Workspace/epics/resizeDecos/index';
import snappingDecosEpic from '../../../../components/Workspace/epics/snappingDecos/index';
import templateLines from '../../../../components/oneweb/Template/epics/templateLines/index';
import stylesheetsIdToNameMapEpic from '../../../../components/Workspace/epics/stylesheets/idToNameMap';
import viewportDimensionsEpic from "../../../../components/Workspace/epics/viewportDimensions/index";
import mousePositionWithRespectToTemplateAreaEpic from '../../../../components/Workspace/epics/mousePositionWithRespectToTemplateArea/index';
import { showMousePositionWithRespectToTemplateArea } from "../../../../utils/isDebug";
import templateEpicValueActionType from '../../../../components/oneweb/Template/epics/template/valueActionType';
import makeStateSelectorReducer from "../../../../epics/makeStateSelectorReducer";
import type { Workspace } from './flowTypes';
import { WORKSPACE_READY } from '../../../../components/Workspace/actionTypes';
import { Lit } from '../../../../lit';
import {
    topMostPageSectionIdEpic
} from '../../../../components/oneweb/Section/epics/topMostPageSectionIdEpic';
import {
    headerSectionIdEpic
} from '../../../../components/oneweb/Section/epics/headerSectionIdEpic';
import {
    shareHeaderAndFirstSectionBgImgEpic
} from '../../../../components/oneweb/Section/epics/shareHeaderAndFirstSectionBgImgEpic';
import { isModernLayoutOnBoardingPanelOpenReducer } from '../../../../components/ModernLayouts/epics/onBoardingPanel/index';

const reducer = makeCombineReducer({
    combineReducers: {
        isPageMode: isPageModeEpic.reducer,
        isEditingModePanelMinimized,
        selectedComponentsIds: componentsEval.selectedComponentsIdsReducer,
        attachments: componentsEval.attachmentsReducer,
        status: statusEpic.reducer,
        scroll: scrollEpic.reducer,
        components: componentsEval.reducer,
        contentDimensions: componentsEval.contentDimensionsReducer,
        userInteraction: componentsEval.userInteractionReducer,
        sectionInsertion: componentsEval.sectionInsertion,
        viewportDimensions: viewportDimensionsEpic.reducer,
        componentsProps: componentsPropsReducer,
        templateWidth,
        templateStyle: makeStateSelectorReducer(
            templateEpic.reducer,
            templateEpicValueActionType,
            template => (template && {
                ...template.style,
                selectedTheme: template.selectedTheme,
                selectedGradientTheme: template.selectedGradientTheme
            }) || null
        ),
        templateRenderOffset: templateRenderOffsetEpic.reducer,
        componentMainActions: componentMainActionsEpic.reducer,
        componentPanelActions: componentPanelActionsEpic.reducer,
        browserDimensions,
        componentsDependencies: componentsEval.componentsDependenciesReducer,
        renderGlobalstyles,
        stylesheetsIdToNameMap: stylesheetsIdToNameMapEpic.reducer,
        shadowRenderer: shadowRendererStateEpic.reducer,
        stylesShadowRenderer: stylesShadowRendererEpic.reducer,
        topMostHandle: topMostHandleEpic.reducer,
        handles: handlesEpic.reducer,
        componentHoverDecorations: componentHoverDecorationsEpic.reducer,
        componentAttachDecorations: componentAttachDecorationsEpic.reducer,
        componentsSelectionDecoration: componentsSelectionDecorationEpic.reducer,
        selectionFrameDecoration: selectionFrameDecorationEpic.reducer,
        redDecorations: redDecorations.reducer,
        editModeComponentId: componentsEval.editModeComponentIdReducer,
        resizeDecos: resizeDecos.reducer,
        shiftBarDecorations: shiftBarDecorations.reducer,
        snappingDecos: snappingDecosEpic.reducer,
        templateLines: templateLines.reducer,
        isMouseOverWorkspace: isMouseOverWorkspaceEpic.reducer,
        isMouseOverMCTA: isMouseOverMCTAEpic.reducer,
        topMostPageSectionId: topMostPageSectionIdEpic.reducer,
        shareHeaderAndFirstSectionBgImg: shareHeaderAndFirstSectionBgImgEpic.reducer,
        headerSectionId: headerSectionIdEpic.reducer,
        isModernLayoutOnBoardingPanelOpen: isModernLayoutOnBoardingPanelOpenReducer,
        mousePositionWithRespectToTemplateArea: showMousePositionWithRespectToTemplateArea() &&
        mousePositionWithRespectToTemplateAreaEpic.reducer,
        // @ts-ignore
        [Lit.firstLoaded]: makeInitialStateReducer(false, (state: Workspace, action: Action) => (
            action.type === WORKSPACE_READY ? true : state
        )),
    }
});

export const defaultState = getDefaultReducerState(reducer);

export default reducer;
