import { WidgetsSpotifyKind } from './kind';
import { SpotifyView } from './view/index';
import { calcRenderProps } from './calcRenderProps';

// See wbtgen/src/view/oneweb/registry/preview.js
const WidgetsSpotifyPreviewConfig = {
    kind: WidgetsSpotifyKind,
    view: SpotifyView,
    calcProps: ({ component }) => calcRenderProps({ component, componentExtension: { isTransient: false } }),
};

export { WidgetsSpotifyPreviewConfig };
