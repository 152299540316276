
import CALL_API from "../../../../../../wbtgen/src/redux/middleware/api/CALL_API";

import type {
    KlikenSiteStatsSummaryRequest,
    KlikenSiteStatsSummaryType,
    KlikenAccountDetailsRequest,
    KlikenAccountDetailsType,
} from "../../../../../../wbtgen/dal/flowTypes";
import { AnalyticsSettingsType } from "../../ThirdPartyConnections/types";
import { ApiAction } from "../../../../../../wbtgen/src/redux/middleware/api/flowTypes";

export { APP_STATUS_READY } from "../../../app/actionTypes";

export {
    ANALYTICS_STATS_WIDGET_GET_STARTED_ACTION,
    ANALYTICS_STATS_WIDGET_LOAD_STATS_SUMMARY_ACTION,
    ANALYTICS_STATS_WIDGET_SEE_MORE_ACTION,
    ANALYTICS_STATS_WIDGET_GO_TO_ANALYTICS_ACTION
} from "../Home/AnalyticsWidget/actions";

export const
    ANALYTICS_GET_STARTED_ACTION = 'ANALYTICS_GET_STARTED_ACTION',
    ANALYTICS_OPEN_TERMS_LINK_ACTION = 'ANALYTICS_OPEN_TERMS_LINK_ACTION',
    ANALYTICS_ACCOUNT_ID_RECEIVED_ACTION = 'ANALYTICS_ACCOUNT_ID_RECEIVED_ACTION',
    ENABLE_ANALYTICS_SETTINGS_ACTION = 'ENABLE_ANALYTICS_SETTINGS_ACTION',
    SET_ANALYTICS_SETTINGS_ACTION = 'SET_ANALYTICS_SETTINGS_ACTION',
    ANALYTICS_DASHBOARD_TOGGLE_ANALYTICS_ACTION = 'ANALYTICS_DASHBOARD_TOGGLE_ANALYTICS_ACTION',
    DISABLE_ANALYTICS_SETTINGS_ACTION = 'DISABLE_ANALYTICS_SETTINGS_ACTION',

    LOAD_ANALYTICS_DATA_ACTION = 'LOAD_ANALYTICS_DATA_ACTION',
    LOAD_ANALYTICS_DATA_ERROR_ACTION = 'LOAD_ANALYTICS_DATA_ERROR_ACTION',

    ANALYTICS_STATS_SUMMARY_LOAD_REQUEST_ACTION = 'ANALYTICS_STATS_SUMMARY_LOAD_REQUEST_ACTION',
    ANALYTICS_STATS_SUMMARY_LOAD_SUCCESS_ACTION = 'ANALYTICS_STATS_SUMMARY_LOAD_SUCCESS_ACTION',
    ANALYTICS_STATS_SUMMARY_LOAD_FAIL_ACTION = 'ANALYTICS_STATS_SUMMARY_LOAD_FAIL_ACTION',

    SET_ANALYTICS_STATS_SUMMARY_ACTION = 'SET_ANALYTICS_STATS_SUMMARY_ACTION',
    SET_ANALYTICS_STATS_SUMMARY_ERROR_ACTION = 'SET_ANALYTICS_STATS_SUMMARY_ERROR_ACTION',

    ANALYTICS_AUTH_TOKEN_LOAD_REQUEST_ACTION = 'ANALYTICS_AUTH_TOKEN_LOAD_REQUEST_ACTION',
    ANALYTICS_AUTH_TOKEN_LOAD_SUCCESS_ACTION = 'ANALYTICS_AUTH_TOKEN_LOAD_SUCCESS_ACTION',
    ANALYTICS_AUTH_TOKEN_LOAD_FAIL_ACTION = 'ANALYTICS_AUTH_TOKEN_LOAD_FAIL_ACTION',

    SET_ANALYTICS_AUTH_TOKEN_ACTION = 'SET_ANALYTICS_AUTH_TOKEN_ACTION',

    ANALYTICS_DIALOG_GET_STARTED_ACTION = 'ANALYTICS_DIALOG_GET_STARTED_ACTION',

    ANALYTICS_ACCOUNT_LOAD_ACCOUNT_DETAILS_ACTION = 'ANALYTICS_ACCOUNT_LOAD_ACCOUNT_DETAILS_ACTION',

    ANALYTICS_ACCOUNT_DETAILS_LOAD_REQUEST_ACTION = 'ANALYTICS_ACCOUNT_DETAILS_LOAD_REQUEST_ACTION',
    ANALYTICS_ACCOUNT_DETAILS_LOAD_SUCCESS_ACTION = 'ANALYTICS_ACCOUNT_DETAILS_LOAD_SUCCESS_ACTION',
    ANALYTICS_ACCOUNT_DETAILS_LOAD_FAIL_ACTION = 'ANALYTICS_ACCOUNT_DETAILS_LOAD_FAIL_ACTION',

    SET_ANALYTICS_ACCOUNT_DETAILS_ACTION = 'SET_ANALYTICS_ACCOUNT_DETAILS_ACTION',
    SET_ANALYTICS_ACCOUNT_DETAILS_ERROR_ACTION = 'SET_ANALYTICS_ACCOUNT_DETAILS_ERROR_ACTION';

export const
    analyticsGetStartedAction = () => ({ type: ANALYTICS_GET_STARTED_ACTION }),
    analyticsOpenTermsLinkAction = () => ({ type: ANALYTICS_OPEN_TERMS_LINK_ACTION }),
    analyticsDashboardToggleAnalyticsAction = () => ({ type: ANALYTICS_DASHBOARD_TOGGLE_ANALYTICS_ACTION }),
    analyticsAccountIdReceivedAction = (accountId: string) => ({
        type: ANALYTICS_ACCOUNT_ID_RECEIVED_ACTION,
        payload: accountId
    }),
    enableAnalyticsSettingsAction = () => ({
        type: ENABLE_ANALYTICS_SETTINGS_ACTION,
    }),
    disableAnalyticsSettingsAction = () => ({
        type: DISABLE_ANALYTICS_SETTINGS_ACTION,
    }),
    setAnalyticsSettingsAction = (settings: AnalyticsSettingsType) => ({
        type: SET_ANALYTICS_SETTINGS_ACTION,
        payload: settings
    }),

    loadAnalyticsDataAction = () => ({
        type: LOAD_ANALYTICS_DATA_ACTION
    }),
    loadAnalyticsDataErrorAction = () => ({
        type: LOAD_ANALYTICS_DATA_ERROR_ACTION
    }),

    loadAnalyticsStatsSummaryAction = (params: KlikenSiteStatsSummaryRequest): ApiAction => ({
        [CALL_API]: {
            types: [
                ANALYTICS_STATS_SUMMARY_LOAD_REQUEST_ACTION,
                ANALYTICS_STATS_SUMMARY_LOAD_SUCCESS_ACTION,
                ANALYTICS_STATS_SUMMARY_LOAD_FAIL_ACTION
            ],
            endpoint: 'getKlikenSiteStatsSummary',
            endpointParams: params
        }
    }),

    setAnalyticsStatsSummaryAction = (stats: KlikenSiteStatsSummaryType) => {
        return {
            type: SET_ANALYTICS_STATS_SUMMARY_ACTION,
            payload: stats
        };
    },
    setAnalyticsStatsSummaryErrorAction = () => ({ type: SET_ANALYTICS_STATS_SUMMARY_ERROR_ACTION }),

    loadAnalyticsAuthTokenAction = (onSuccessAction?: Action, onFailAction?: Action) => ({
        [CALL_API]: {
            types: [
                ANALYTICS_AUTH_TOKEN_LOAD_REQUEST_ACTION,
                ANALYTICS_AUTH_TOKEN_LOAD_SUCCESS_ACTION,
                ANALYTICS_AUTH_TOKEN_LOAD_FAIL_ACTION
            ],
            endpoint: 'getKlikenApiAuthToken',
            extraPayload: { onSuccessAction, onFailAction }
        }
    } as unknown as Action),

    setAnalyticsAuthTokenAction = (token: string) => ({
        type: SET_ANALYTICS_AUTH_TOKEN_ACTION,
        payload: token
    }),

    analyticsDialogGetStartedAction = () => ({
        type: ANALYTICS_DIALOG_GET_STARTED_ACTION
    }),

    analyticsAccountLoadAccountDetailsAction = () => ({
        type: ANALYTICS_ACCOUNT_LOAD_ACCOUNT_DETAILS_ACTION
    }),

    loadAnalyticsAccountDetailsAction = (params: KlikenAccountDetailsRequest) : ApiAction => ({
        [CALL_API]: {
            types: [
                ANALYTICS_ACCOUNT_DETAILS_LOAD_REQUEST_ACTION,
                ANALYTICS_ACCOUNT_DETAILS_LOAD_SUCCESS_ACTION,
                ANALYTICS_ACCOUNT_DETAILS_LOAD_FAIL_ACTION
            ],
            endpoint: 'getKlikenAccountDetails',
            endpointParams: params
        }
    }),

    setAnalyticsAccountDetailsAction = (account: KlikenAccountDetailsType) => ({
        type: SET_ANALYTICS_ACCOUNT_DETAILS_ACTION,
        payload: account
    }),
    setAnalyticsAccountDetailsErrorAction = () => ({
        type: SET_ANALYTICS_ACCOUNT_DETAILS_ERROR_ACTION
    });
