import * as R from "ramda";
import valueActionType from "./valueActionType";
import makeEpic from "../../../../epics/makeEpic";
import * as ActionTypes from "../../actionTypes";
import { ReceiveOnlyComponentsMap } from "../../../Workspace/epics/componentsEval/selectorActionTypes";
import { TextComponentKind } from "../../../oneweb/Text/kind";

export default makeEpic({
    defaultState: {},
    valueActionType,
    updaters: [
        {
            conditions: [
                ReceiveOnlyComponentsMap,
                ActionTypes.MOBILE_EDITOR_UPDATE_COMPONENT_EXTENSION_PROPS
            ],
            reducer: ({ values: [componentsMap, { id, currentTextSize, minSize, maxSize, textAlign }], state }) => {
                let newState = state;
                if (componentsMap[id].kind === TextComponentKind) {
                    newState = R.assocPath([id], { currentTextSize, minSize, maxSize, textAlign }, state);
                }
                return {
                    state: newState,
                };
            }
        },
        {
            conditions: [
                ActionTypes.MOBILE_EDITOR_REMOVE_COMPONENT_EXTENSION_PROPS
            ],
            reducer: ({ values: [{ id }], state }) => {
                return {
                    state: R.dissocPath([id], state),
                };
            }
        },
        {
            conditions: [
                ActionTypes.BACK_TO_DESKTOP_EDITOR_FROM_MOBILE_EDITOR
            ],
            reducer: () => {
                return { state: {} };
            }
        }
    ]
});
