/* eslint-disable no-console */

import { stringify } from 'flatted';
import * as R from 'ramda';
import { error } from '../../../utils/log';
import isApiAction from "./api/isApiAction";
import CALL_API from "./api/CALL_API";
import { arrayToTrueMap } from "../../utils/arrayToTrueMap";
import { WINDOW_MOUSE_MOVE } from "../../components/App/actionTypes";
import { ANIMATION_FRAME } from "./raf";
import {
    SET_COMPONENT_ADJUSTMENT_DATA,
    WORKSPACE_SCROLL
} from "../../components/Workspace/actionTypes";
import {
    MENU_RENDER_DIMENSIONS_CHANGED
} from "../../components/oneweb/Menu/actionTypes";

type RecordType = string | number
type RecordCount = number
type TraceRecord = [RecordType, RecordCount] | [RecordType, RecordCount, Action]

const
    frequentActionTypes = arrayToTrueMap([
        WINDOW_MOUSE_MOVE,
        ANIMATION_FRAME,
        WORKSPACE_SCROLL,
        SET_COMPONENT_ADJUSTMENT_DATA,
        MENU_RENDER_DIMENSIONS_CHANGED
    ]),
    MAX_TRACE_STACK_LENGTH = 200,
    traceStack: Array<TraceRecord> = [],
    push = (action) => {
        let
            actionType,
            actionPayload;

        if (isApiAction(action)) {
            // $FlowFixMe: flow does not understand isApiAction() check
            const { [CALL_API]: { types, apiTag, endpointParams } } = action;
            actionType = types[0];
            actionPayload = { endpointParams, apiTag };
        } else {
            const { type, ...payload } = action;
            actionType = type;
            actionPayload = payload;
        }

        if (!actionType) {
            error('Invalid action with empty type', action);
            return;
        }
        if (!actionPayload) {
            actionPayload = {};
        }

        actionPayload = { ...actionPayload };

        delete actionPayload.epicsUpdate;

        const
            last = R.last(traceStack),
            actionPayloadHasKeys = Object.keys(actionPayload).length > 0,
            pushInitialRecord = () => {
                if (actionPayloadHasKeys) {
                    traceStack.push([actionType, 1, actionPayload]);
                } else {
                    traceStack.push([actionType, 1]);
                }
            };
        if (last && last[0] === actionType) {
            last[1]++;
        } else if (frequentActionTypes[actionType]) {
            let foundFrequentActionsInOneOfLastActions = false;
            for (let i = traceStack.length - 2; i >= 0; i--) { // -2 because we already checked last one
                const
                    currentRecord = traceStack[i],
                    currentRecordType = currentRecord[0];

                if (!frequentActionTypes[currentRecordType]) {
                    break;
                }
                if (currentRecordType === actionType) {
                    currentRecord[1]++;
                    if (actionPayloadHasKeys) {
                        currentRecord[2] = actionPayload;
                    }
                    foundFrequentActionsInOneOfLastActions = true;
                    break;
                }
            }
            if (!foundFrequentActionsInOneOfLastActions) {
                pushInitialRecord();
            }
        } else {
            pushInitialRecord();
        }

        if (traceStack.length > MAX_TRACE_STACK_LENGTH) {
            traceStack.shift();
        }
    },
    crashTraceCollectorMiddleware = () => (next: Dispatch) => (action: Action) => {
        try {
            push(action);
        } catch (e: any) {
            // in case there is a logical error in trace collector it should not break app
            setTimeout(() => {
                // but we will report this error via TraceKit
                throw e;
            });
        }
        return next(action);
    },
    getCrashTrace = () => R.reverse(traceStack),
    KB = 1024,
    getCrashTraceStringified = () => {
        const
            crashTrace = getCrashTrace(),
            crashTraceWithoutHugeActions = crashTrace.map((record) => {
                const
                    [type, count, action] = record;

                if (action) {
                    const
                        stringifiedAction = stringify(action);
                    if (stringifiedAction.length > KB) {
                        return [type, count, { type, stringify_err: 'too_big' }];
                    }
                }

                return record;
            });

        return JSON.stringify(crashTraceWithoutHugeActions);
    },
    getCrashTraceNoActionStringified = () => getCrashTrace()
        .map(([type, count]) => `${count === 1 ? '' : `${count}*`}${type}`)
        .join(' < ');

export {
    crashTraceCollectorMiddleware,
    getCrashTrace,
    getCrashTraceStringified,
    getCrashTraceNoActionStringified
};
