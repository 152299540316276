// @ts-nocheck
import React from 'react';
import ColorProperty from '../ColorProperty/index';
import VerticalSpacer from '../../../view/common/VerticalSpacer';
import { FadePoint } from "./constants";
import Slider from '../../../view/common/Slider/PPSlider';
import GradientDirection from "../GradientDirection/index";
import OpacitySlider from '../OpacitySlider/index';
import toGradientSettingsProps, { getDefaultGradientColor } from "./utils/toGradientSettingsProps";
import type { ColorGradientSettingsPropTypes } from "./flowTypes";
import styles from "./ColorGradientSettings.css";
import { THEME_BG_OVERRIDE_COLOR_PICKER, THEME_COLOR_OVERRIDE_COLOR_PICKER, DEFAULT_COLOR_PICKER } from '../../ColorPicker/constants';

export default (props: ColorGradientSettingsPropTypes) => {
    const {
        colorChangeAction,

        gradient,
        gradientColorChangeAction,
        gradientColorRemoveAction,
        gradientDirectionChangeAction,
        gradientFadePointChangeAction,

        opacity,
        opacityChangeAction,

        dispatch
    } = props;

    const
        {
            color: gradientColor,
            degree: gradientDirection,
            fadePoint: gradientFadePoint,
        } = toGradientSettingsProps(gradient),
        onOpacityChange = opacity => {
            // Dispatch payload as object in future if we need at more places and correct all reducers listening to it.
            const payload = (
                props.colorPickerKind === THEME_BG_OVERRIDE_COLOR_PICKER || props.colorPickerKind === THEME_COLOR_OVERRIDE_COLOR_PICKER
                    ? { opacity, themeColorsData: props.themeColorsData }
                    : opacity
            );
            return dispatch({ type: opacityChangeAction, payload, amendToSelf: true });
        },
        areGradientOptionsVisible = (() => {
            if (props.colorPickerKind === THEME_BG_OVERRIDE_COLOR_PICKER || props.colorPickerKind === THEME_COLOR_OVERRIDE_COLOR_PICKER) {
                return props.gradientTheme && gradient;
            } else {
                return gradient;
            }
        })(),
        isOpacitySliderDisabled = (() => {
            if (props.colorPickerKind === THEME_BG_OVERRIDE_COLOR_PICKER || props.colorPickerKind === THEME_COLOR_OVERRIDE_COLOR_PICKER) {
                return false;
            } else {
                return !props.color && !props.gradient;
            }
        })();

    return (
        <div>
            {(() => {
                switch (props.colorPickerKind) {
                    case THEME_COLOR_OVERRIDE_COLOR_PICKER: return <ColorProperty
                        colorPickerKind={THEME_COLOR_OVERRIDE_COLOR_PICKER}
                        themeColorsData={props.themeColorsData}
                        themeColor={props.themeColor}
                        label="msg: common.solidColor {Fill colour}"
                        onChangeAction={colorChangeAction}
                        onRemoveAction={props.colorRemoveAction}
                        dispatch={dispatch}
                    />;
                    case THEME_BG_OVERRIDE_COLOR_PICKER: return <ColorProperty
                        colorPickerKind={THEME_BG_OVERRIDE_COLOR_PICKER}
                        themeColorsData={props.themeColorsData}
                        backgroundTheme={props.backgroundTheme}
                        label="msg: common.solidColor {Fill colour}"
                        onChangeAction={colorChangeAction}
                        dispatch={dispatch}
                        tooltipTop={props.tooltipTop}
                    />;
                    default: return <ColorProperty
                        colorPickerKind={DEFAULT_COLOR_PICKER}
                        label="msg: common.solidColor {Fill colour}"
                        color={props.color && [props.color[0], props.color[1], props.color[2], props.color[3], 1]}
                        onChangeAction={colorChangeAction}
                        onRemoveAction={props.colorRemoveAction}
                        onTheFlyChange={props.colorOnTheFlyChange}
                        dispatch={dispatch}
                    />;
                }
            })()}
            <VerticalSpacer />
            {
                (props.colorPickerKind === THEME_BG_OVERRIDE_COLOR_PICKER || props.colorPickerKind === THEME_COLOR_OVERRIDE_COLOR_PICKER)
                    ? <ColorProperty
                        colorPickerKind={THEME_COLOR_OVERRIDE_COLOR_PICKER}
                        themeColorsData={props.themeColorsData}
                        themeColor={props.gradientTheme}
                        label="msg: common.gradientColor {Gradient colour}"
                        onChangeAction={gradientColorChangeAction}
                        onRemoveAction={gradientColorRemoveAction}
                        dispatch={dispatch}
                    />
                    : <ColorProperty
                        colorPickerKind={DEFAULT_COLOR_PICKER}
                        label="msg: common.gradientColor {Gradient colour}"
                        color={gradientColor}
                        defaultColor={getDefaultGradientColor(props.color)}
                        preserveColorBoxOpacity
                        onChangeAction={gradientColorChangeAction}
                        onRemoveAction={gradientColorRemoveAction}
                        onTheFlyChange={props.colorOnTheFlyChange}
                        dispatch={dispatch}
                    />
            }
            <div className={`${styles.gradientGroup} ${areGradientOptionsVisible ? '' : styles.hidden}`}>
                <VerticalSpacer />
                <GradientDirection
                    label="msg: common.direction {Direction}"
                    value={gradientDirection}
                    onChange={degree => dispatch({
                        type: gradientDirectionChangeAction,
                        payload: degree,
                        amendToSelf: true
                    })}
                />
                <VerticalSpacer />
                <Slider
                    label="msg: component.bg.fadePoint {Fade point}"
                    valueLabel={`${gradientFadePoint}%`}
                    value={gradientFadePoint}
                    min={FadePoint.MIN}
                    max={FadePoint.MAX}
                    onChange={fadePoint => dispatch({
                        type: gradientFadePointChangeAction,
                        payload: fadePoint,
                        amendToSelf: true
                    })}
                />
            </div>
            <VerticalSpacer />
            <OpacitySlider
                opacity={opacity}
                onChange={onOpacityChange}
                onAfterChange={onOpacityChange}
                disabled={isOpacitySliderDisabled}
            />
        </div>
    );
};
