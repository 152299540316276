import React from "react";
import type { CTAViewParams } from "../../../../view/Workspace/Decorations/ComponentMainActions/flowTypes";
import { openDocumentConfigDialogAction } from "../actions";
import type { DocumentComponent } from '../flowTypes';

type PropsType = {
    children: any,
}

export default {
    editView: ({ children }: PropsType) => (
        <React.Fragment>
            {children && <div>
                {children}
            </div>}
        </React.Fragment>
    ),
    editButtonText: 'msg: component.common.Settings {Settings}',
    ctaButtonText: 'msg: document.changeDocument {Change document}',
    ctaOnClick: ({ selectedComponent, dispatch }: CTAViewParams<DocumentComponent>) =>
        dispatch(openDocumentConfigDialogAction(selectedComponent.settings.src)),
    isNoSettingInEditMode: () => true
};
