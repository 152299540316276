import ThemeDeactivationDialog from "./view/index";
import getCenteredDialogConfig from "../../DialogManager/getCenteredDialogConfig";
import { getCenterPositionForDialog } from "../../DialogManager/utility";

const HEIGHT = 386,
    WIDTH = 600,
    themeDeactivationDialog = {
        ...getCenteredDialogConfig(WIDTH, HEIGHT, ThemeDeactivationDialog),
        confFactory: ({ browserWidth, browserHeight }) => {
            return {
                position: getCenterPositionForDialog(WIDTH, HEIGHT, browserWidth, browserHeight),
                modal: true,
                dimensions: {
                    width: WIDTH,
                    height: HEIGHT,
                    minHeight: 386
                }
            };
        },
        moveToCenter: true
    };
export { themeDeactivationDialog };
