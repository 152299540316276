import { getSectionsOrderdByTop, isSectionComponent } from "../../../oneweb/Section/utils";
import type { ComponentsMap } from "../../../../redux/modules/children/workspace/flowTypes";
import { getNonGhostCmps } from "../../../Workspace/epics/componentAttachements/util";
import { makeKey } from "../../../../utils/localStorage";
import {
    getCrashTraceNoActionStringified
} from "../../../../redux/middleware/crashTraceCollector";
import { customSendReport } from "../../../../customSendCrashReport";
import { isDALInitialized } from '../../../../../dal/index';

const REASONS = {
    TEMPLATE_SECTIONS_NOT_EQUAL_TO_2: 'TEMPLATE_SECTIONS_NOT_EQUAL_TO_2',
    PAGE_SECTION_DOES_NOT_EXISTS: 'PAGE_SECTION_DOESNOT_EXISTS',
    HEADER_DOESNOT_EXISTS: 'HEADER_DOESNOT_EXISTS',
    FOOTER_DOESNOT_EXISTS: 'FOOTER_DOESNOT_EXISTS',
    GAP_BETWEEN_SECTIONS: 'GAP_BETWEEN_SECTIONS',
    COMPONENT_OUT_OF_SECTIONS: 'COMPONENT_OUT_OF_SECTIONS',
    COMPONENT_DOES_NOT_HAVE_RELIN: 'COMPONENT_DOES_NOT_HAVE_RELIN',
    TEMPLATE_COMPONENT_IN_PAGE_SECTION: 'TEMPLATE_COMPONENT_IN_PAGE_SECTION',
    SECTION_COMPONENT_HEIGHT_IS_NEGATIVE: 'SECTION_COMPONENT_HEIGHT_IS_NEGATIVE',
    TEMPLATE_SECTIONS_TOP_ARE_EQUAL: 'TEMPLATE_SECTIONS_TOP_ARE_EQUAL',
};

export const isCmpsMapValidBeforeSave = (componentsMap: ComponentsMap, isForSave: boolean): Record<string, any> => {
    if (!componentsMap || !Object.keys(componentsMap).length) {
        return {
            isValid: true,
            data: {}
        };
    }
    const sections = getSectionsOrderdByTop(componentsMap);
    const templateSections = sections.filter(({ inTemplate }) => inTemplate);
    if (templateSections.length !== 2) {
        return {
            isValid: false,
            data: {
                reason: REASONS.TEMPLATE_SECTIONS_NOT_EQUAL_TO_2
            }
        };
    }
    if (templateSections[0].top === templateSections[1].top) {
        return {
            isValid: false,
            data: {
                reason: REASONS.TEMPLATE_SECTIONS_TOP_ARE_EQUAL
            }
        };
    }
    if (sections.length < 3) {
        return {
            isValid: false,
            data: {
                reason: REASONS.PAGE_SECTION_DOES_NOT_EXISTS
            }
        };
    }

    const headerSection = sections.find(({ inTemplate, top }) => (inTemplate && !top));
    if (!headerSection) {
        return {
            isValid: false,
            data: {
                reason: REASONS.HEADER_DOESNOT_EXISTS
            }
        };
    }
    const footerSection = sections.find(({ inTemplate, top }) => (inTemplate && top));
    if (!footerSection) {
        return {
            isValid: false,
            data: {
                reason: REASONS.FOOTER_DOESNOT_EXISTS
            }
        };
    }

    const footerStartingAt = footerSection && footerSection.top;
    const components = getNonGhostCmps(componentsMap);
    for (let i = 0; i < components.length; i++) {
        const cmp = components[i];

        if (isSectionComponent(cmp) && cmp.height < 0) {
            return {
                isValid: false,
                data: {
                    reason: REASONS.SECTION_COMPONENT_HEIGHT_IS_NEGATIVE,
                    cmpId: cmp.id
                }
            };
        }
        if (isSectionComponent(cmp)) {
            continue;
        }
        if (!isForSave && footerSection && cmp.top > (footerStartingAt + footerSection.height)) {
            return {
                isValid: false,
                data: {
                    reason: REASONS.COMPONENT_OUT_OF_SECTIONS,
                    cmpId: cmp.id
                }
            };
        }
    }
    return {
        isValid: true,
        data: {}
    };
};

const key = 'isCmpsMapDataValid';

export const setSessionStorageIsCmpsMapValid = (cmpsMap: ComponentsMap) => {
    try {
        const data = isCmpsMapValidBeforeSave(cmpsMap, true);
        if (!data.isValid) {
            window.sessionStorage.setItem(makeKey(key), JSON.stringify(data));
        }
    } catch (e) {
        // Do nothing
    }
};

export const getSessionStorageIsCmpsMapValid = (): Object => {
    let data = window.sessionStorage.getItem(makeKey(key));
    if (data) {
        return JSON.parse(data);
    }
    return {
        isValid: true,
        data: {}
    };
};

const onChangeKey = 'isCmpsMapDataValidOnChange';

export const getSessionStorageIsCmpsMapValidOnChange = (): Record<string, any> => {
    let data = window.sessionStorage.getItem(makeKey(onChangeKey));
    if (data) {
        return JSON.parse(data);
    }
    return {
        isValid: true,
        data: {}
    };
};

const getCmpDetails = (cmp) => {
    const { kind, orderIndex, inTemplate, relIn, top, left, height, width } = cmp;
    return {
        kind,
        orderIndex,
        inTemplate,
        relIn,
        top,
        left,
        height,
        width,
    };
};

export const isCmpsDataHasValidOnChange = (props: any) => {
    if (!isDALInitialized() || props.nextState.scope.userInteraction.mode !== 'IDLE') {
        return;
    }
    const prevData = getSessionStorageIsCmpsMapValidOnChange(),
        cmpsMap = props.nextState.state.componentsMap,
        data = isCmpsMapValidBeforeSave(cmpsMap, false);
    window.sessionStorage.setItem(makeKey(onChangeKey), JSON.stringify(data));
    if (!data.isValid && data.isValid !== prevData.isValid) {
        const oldCmpsMap = props.prevState.state.componentsMap,
            nextCmpIds = Object.keys(cmpsMap),
            prevCmpIds = Object.keys(oldCmpsMap),
            newCmpMapIds = nextCmpIds.filter(id => !prevCmpIds.includes(id)),
            deletedCmpMapIds = prevCmpIds.filter(id => !nextCmpIds.includes(id)),
            selectedComponentsIds = props.nextState.scope.selectedComponentsIds;
        const additionalData = {
            pageId: props.nextState.scope.currentPageId,
            sourceAction: props.sourceAction && props.sourceAction.type,
            updateReason: props.updateReason,
            triggerSubscriptionActionType: props.triggerSubscriptionActionType,
            next: {
                userInteraction: props.nextState.scope.userInteraction,
                movingComponents: props.nextState.scope.movingComponents,
                sectionInsertion: props.nextState.scope.sectionInsertion,
            },
            prev: {
                userInteraction: props.prevState.scope.userInteraction,
                movingComponents: props.prevState.scope.movingComponents,
                sectionInsertion: props.prevState.scope.sectionInsertion,
            },
            selectedComponentsIds,
            selectedComponents: selectedComponentsIds.map(id => {
                const newCmp = cmpsMap[id] || {}, oldCmp = oldCmpsMap[id] || {};
                return {
                    id,
                    old: getCmpDetails(oldCmp),
                    new: getCmpDetails(newCmp),
                };
            }),
            newCmpMaps: newCmpMapIds.map(id => {
                const newCmp = cmpsMap[id] || {};
                return {
                    id,
                    ...getCmpDetails(newCmp),
                };
            }),
            deletedCmpMapIds
        };
        customSendReport({
            message: "CMPS_CHANGED_WITH_WRONG_DATA",
            additionalInfo: {
                response: {
                    data: {
                        releaseDate: '0310',
                        ...data,
                        ...additionalData,
                    },
                    actionsTraceStr: getCrashTraceNoActionStringified()
                },
            }
        });
    }
};
