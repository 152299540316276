import cx from 'classnames';
import React from 'react';
import styles from './Dialog.css';

type PropTypes = {
    className?: string,
    children: null | undefined | any
}
export default ({ className, children, ...props }: PropTypes) => (
    <div {...props} className={cx(styles.body, className)}>{children}</div>
);
