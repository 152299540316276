import React from 'react';
import type { LabeledTextAreaProps } from "./flowTypes";
import Label from '../../../view/common/Label';
import { TextArea } from './index';

export default (props: LabeledTextAreaProps) => {
    const { label, isLabelValue, ...textAreaProps } = props;

    return (
        <div>
            <Label isValue={isLabelValue}>{label}</Label>
            <TextArea {...textAreaProps} />
        </div>
    );
};
