type Registry = {
    [key: string]: () => any
};

const registry: Registry = {};

function makeKey(actionTypes: Array<string>) {
    return actionTypes.join('-');
}

function register(actionTypes: Array<string>, generator: () => any) {
    registry[makeKey(actionTypes)] = generator;
}

function get(actionTypes: Array<string>) {
    return registry[makeKey(actionTypes)];
}

export { register, get };
