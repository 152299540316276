// @ts-nocheck
import { receiveOnly } from "../../../../epics/makeCondition";
import valueActionType from "./valueActionType";

const getOldHFCmpsDataBeforeMHF = ({ oldData }) => oldData;

const ReceiveOnlyHFCmpsBeforeMHF = receiveOnly(valueActionType, getOldHFCmpsDataBeforeMHF);

export {
    ReceiveOnlyHFCmpsBeforeMHF,
};
