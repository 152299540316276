import { getAddressAreaTypeByCountryFormat } from "./addressFormats";
import { getLocalization } from "../getLocalization";
import type { GetPlaceholderTextType } from "../types";

const { singleLineAddressSeparator, multiLineAddressSeparator } = require('../../../../../../server/shared/address/constants.js');
const {
    renderAddressToString,
} = require('../../../../../../server/shared/address/utils.js');
const { getCountryFormat } = require('../../../../../../server/shared/address/getCountryFormat.js');

export const getPlaceholderTextForAddress = ({
    component: {
        specific,
        generic: { showOnOneLine }
    },
    componentDependencies: { addressCountryCode },
    viewerDomain,
    messages,
    locale
}: GetPlaceholderTextType<any>): string => {
    const countryFormat = getCountryFormat(addressCountryCode);
    const addressAreaType = getAddressAreaTypeByCountryFormat(countryFormat);

    let {
        addressName,
        addressStreetAddress,
        addressCity,
        addressZip,
        addressAreaState,
        addressAreaProvince,
        addressCountry,
    } = specific.placeholder;

    if (viewerDomain && locale) {
        addressName = getLocalization(messages, locale, 'common.businessTitle');
        addressStreetAddress = getLocalization(messages, locale, 'common.streetAddress');
        addressCity = getLocalization(messages, locale, 'common.city');
        addressZip = getLocalization(messages, locale, 'common.zip');
        addressAreaState = getLocalization(messages, locale, 'common.state');
        addressAreaProvince = getLocalization(messages, locale, 'common.province');
        addressCountry = getLocalization(messages, locale, 'common.country');
    }

    return renderAddressToString({
        addressName,
        addressStreetAddress,
        addressCity,
        addressZip,
        addressArea: addressAreaType === 'STATE' ? addressAreaState : addressAreaProvince,
        addressCountry: specific.showCountry ? addressCountry : null,
        countryFormat,
        separator: (showOnOneLine) ? singleLineAddressSeparator : multiLineAddressSeparator,
    });
};
