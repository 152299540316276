import React from "react";
import ellipsis from "text-ellipsis";
import cx from "classnames";
import LazyImage from "../../../view/common/LazyImage";
import styles from "./styles.css";
import { openImageChooserAction } from "../../FileManager/imageChooser/actions";
import { injectIntl } from "../../../view/intl";
import type { FactoryProps as ButtonFactoryProps } from "../../presentational/Button/flowTypes";
import ButtonFactory from "../../presentational/Button/factory";
import Icons from "../../../view/Icons";
import { getImageSrc } from "../../oneweb/Image/utils";
import { FileUploader } from "../../FileUploader";
import { FcContentTypes } from "../../../redux/modules/children/fileChooser/FcContentTypes";
import { FileManagerMode } from "../../FileManager/constants";
import { uploadFilesAction } from "../../FileUploader/actionCreators";
import Label from "../../../view/common/Label";
import Scrollbar from "../../../view/common/Scrollbar";
import { Props, State } from '../types';

const RemoveIcon = injectIntl(({ onClick, intl }) => {
    const btnStyles = {
            button: styles.removeSocialShareImageIcon,
            disabled: "",
            error: "",
            selected: "",
        },
        buttonFactoryConfig: ButtonFactoryProps = {
            btnStyles,
            containerType: "span",
            containerExtraPropNames: [],
        },
        IconButton = ButtonFactory({ ...buttonFactoryConfig });
    return (
        <div className={styles.removeSocialShareImageIconWrapper}>
            <IconButton
                onClick={onClick}
                title={intl.msgJoint(
                    "msg: socialShare.deleteImage {Delete social share image}",
                )}
            >
                <Icons.TRASHCAN />
            </IconButton>
        </div>
    );
});
export class SocialShareImagePreview extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isHoveringOnSocialShareImage: false,
        };
        this.removeSocialShareImage = this.removeSocialShareImage.bind(this);
    }

    removeSocialShareImage(e: Event) {
        e.stopPropagation();
        this.props.dispatch({
            type: this.props.removeSocialShareActionType,
        });
    }

    render() {
        const borderWidth = 1,
            {
                socialShareImageAsset,
                alt,
                height,
                width,
                dispatch,
                headLabel,
                className,
                domainUrl,
                websiteTitle,
                socialShareDescription,
            } = this.props,
            { src } = getImageSrc(
                socialShareImageAsset,
                height - borderWidth * 2,
                width - borderWidth * 2,
            );
        let classNames = [styles.socialSharePreviewContainer, className];

        if (socialShareImageAsset) {
            classNames = [...classNames, styles.notEmpty];
        }

        return (
            <FileUploader
                noOverlay
                onDrop={(e, asset) => {
                    dispatch(
                        uploadFilesAction({
                            closeModalOnSave: false,
                            isMultiSelect: false,
                            forwardToComponent: true,
                            headlessMode: true,
                            contentTypes: FcContentTypes.SUPPORTED_IMAGE,
                            mode: FileManagerMode.IMAGE_CHOOSER,
                            onSaveAction: this.props
                                .socialShareImageChoosenActionType,
                            files: [asset[0]] as any,
                        }),
                    );
                }}
            >
                {headLabel && (
                    <Label className={styles.headLabel}>{headLabel}</Label>
                )}
                <div
                    onClick={() =>
                        dispatch(
                            openImageChooserAction({
                                onSaveAction: this.props
                                    .socialShareImageChoosenActionType,
                                isMultiSelect: false,
                                forwardToComponent: true,
                            }),
                    )}
                    onMouseEnter={() =>
                        this.setState({
                            isHoveringOnSocialShareImage: true,
                    })}
                    onMouseLeave={() =>
                        this.setState({
                            isHoveringOnSocialShareImage: false,
                    })}
                >
                    <div
                        className={cx(...classNames)}
                        style={{
                            borderWidth,
                            height,
                            overflow: "hidden",
                            position: "relative",
                        }}
                    >
                        {this.state.isHoveringOnSocialShareImage &&
                            socialShareImageAsset && (
                                <RemoveIcon
                                    onClick={this.removeSocialShareImage}
                                />
                            )}
                        {src && (
                            <LazyImage
                                src={src}
                                shouldLoad
                                showTransparency
                                className={styles.socialShareImageContainer}
                                alt={alt}
                                doNotUseDataUriForSafari
                            />
                        )}
                    </div>
                </div>
                <div className={styles.socialSharePreviewDetailsContainer}>
                    <div className={styles.domainUrlContainer}>
                        {domainUrl && (
                            <span className={styles.domainUrl}>
                                {domainUrl}
                            </span>
                        )}
                    </div>
                    <div>
                        {websiteTitle && (
                            <span className={styles.websiteTitle}>
                                {websiteTitle}
                            </span>
                        )}
                    </div>
                    <Scrollbar height="70px">
                        <div>
                            {socialShareDescription && (
                                <span
                                    className={
                                        styles.socialShareDescriptionContainer
                                    }
                                >
                                    {socialShareDescription.length > 300
                                        ? ellipsis(socialShareDescription, 300)
                                        : socialShareDescription}
                                </span>
                            )}
                        </div>
                    </Scrollbar>
                </div>
            </FileUploader>
        );
    }
}
