import * as R from 'ramda';
import {
    BACKGROUND_THEME_BLACK,
    BACKGROUND_THEME_WHITE,
    BACKGROUND_THEME_MAIN,
    BACKGROUND_THEME_ACCENTED,
    BUTTON_THEME_PRIMARY,
    BUTTON_THEME_ALTERNATE,
    BUTTON_THEME_SECONDARY,
    LINK_THEME_ACCENT,
    LINK_THEME_STYLE_ACCENT,
    LINK_THEME_UNDERLINED,
    LINK_THEME_STYLE_UNDERLINED,
    LINK_THEME_TEXTLIKE,
    LINK_THEME_STYLE_TEXTLIKE,
    MAIN_THEME_COLOR,
    BLACK_THEME_COLOR,
    WHITE_THEME_COLOR,
    ACCENT_THEME_COLOR,
    TRANSPARENT_COLOR,
    LINK_THEME_GLOBALSTYLE_ACCENT,
    LINK_THEME_GLOBALSTYLE_TEXTLIKE,
    LINK_THEME_GLOBALSTYLE_UNDERLINED
} from "./constants";
import {
    findSuitableContrastColor,
    findSuitableTextColorName,
    findInverseTextColorName,
    findSuitableContrastColorName
} from './utils/commonUtils';
import type {
    ThemeColorTypes,
    ThemeBackgroundType,
    ThemeColorDataType,
    GetThemeRulesForButtonRT,
    GetThemeRulesForBackgroundRT,
    ThemeRulesBackgroundForLinkColorRT,
    GetColorsFromBackgroundThemeRT,
    GetThemeRulesForWebshopRT
} from './flowTypes';
import { memoMax } from "../../../utils/memo";
import type { Color } from "../../mappers/flowTypes";
import { types as tableCellGlobalStyleRef } from '../Globalstyles/Table/constants';
import type { SvgColorObject } from '../oneweb/Svg/flowTypes';
import { toHex } from '../../mappers/color';

export const
    getThemeRulesForBackground =
        (backgroundTheme: ThemeBackgroundType, themeColorsData: ThemeColorDataType): GetThemeRulesForBackgroundRT => {
            const
                textColorNameForAccentThemeColor = findSuitableTextColorName(ACCENT_THEME_COLOR, themeColorsData),
                textColorNameForMainThemeColor = findSuitableTextColorName(MAIN_THEME_COLOR, themeColorsData);
            switch (backgroundTheme) {
                case BACKGROUND_THEME_BLACK:
                    return {
                        background: BLACK_THEME_COLOR, text: WHITE_THEME_COLOR
                    };
                case BACKGROUND_THEME_WHITE:
                    return {
                        background: WHITE_THEME_COLOR, text: BLACK_THEME_COLOR
                    };
                case BACKGROUND_THEME_MAIN:
                    return {
                        background: MAIN_THEME_COLOR, text: textColorNameForMainThemeColor
                    };
                case BACKGROUND_THEME_ACCENTED:
                    return {
                        background: ACCENT_THEME_COLOR, text: textColorNameForAccentThemeColor
                    };
                default:
                    return {
                        background: BLACK_THEME_COLOR, text: WHITE_THEME_COLOR
                    };
            }
        },
    getThemeFromBackgroundColor = (backgroundColor: ThemeColorTypes): ThemeBackgroundType => {
        switch (backgroundColor) {
            case BLACK_THEME_COLOR:
                return BACKGROUND_THEME_BLACK;
            case WHITE_THEME_COLOR:
                return BACKGROUND_THEME_WHITE;
            case MAIN_THEME_COLOR:
                return BACKGROUND_THEME_MAIN;
            case ACCENT_THEME_COLOR:
                return BACKGROUND_THEME_ACCENTED;
            default:
                return BACKGROUND_THEME_BLACK;
        }
    },
    getThemeRulesForButton =
        (backgroundTheme: ThemeBackgroundType, themeColorsData: ThemeColorDataType): GetThemeRulesForButtonRT => {
            const textColorNameForAccentThemeColor = findSuitableTextColorName(ACCENT_THEME_COLOR, themeColorsData);
            switch (backgroundTheme) {
                case BACKGROUND_THEME_BLACK:
                    return {
                        [BUTTON_THEME_PRIMARY]: {
                            background: ACCENT_THEME_COLOR,
                            text: textColorNameForAccentThemeColor
                        },
                        [BUTTON_THEME_ALTERNATE]: { background: WHITE_THEME_COLOR, text: BLACK_THEME_COLOR },
                        [BUTTON_THEME_SECONDARY]: { background: TRANSPARENT_COLOR, text: WHITE_THEME_COLOR, border: WHITE_THEME_COLOR },
                    };
                case BACKGROUND_THEME_WHITE:
                    return {
                        [BUTTON_THEME_PRIMARY]: { background: ACCENT_THEME_COLOR, text: textColorNameForAccentThemeColor },
                        [BUTTON_THEME_ALTERNATE]: { background: BLACK_THEME_COLOR, text: WHITE_THEME_COLOR },
                        [BUTTON_THEME_SECONDARY]: { background: TRANSPARENT_COLOR, text: BLACK_THEME_COLOR, border: BLACK_THEME_COLOR },
                    };
                case BACKGROUND_THEME_MAIN: {
                    const textColorNameForMainThemeColor = findSuitableTextColorName(MAIN_THEME_COLOR, themeColorsData);
                    const alternateThemeBackground = findSuitableContrastColorName({
                        baseColorName: MAIN_THEME_COLOR,
                        currentColorName: findInverseTextColorName(textColorNameForMainThemeColor),
                        colorsNameArray: [ACCENT_THEME_COLOR, textColorNameForMainThemeColor],
                        themeColorsData,
                        contrastRatioThreshold: 2,
                    }).contrastColorName;
                    const alternateThemeText = findSuitableContrastColorName({
                        baseColorName: alternateThemeBackground,
                        currentColorName: MAIN_THEME_COLOR,
                        colorsNameArray: [ACCENT_THEME_COLOR, BLACK_THEME_COLOR, WHITE_THEME_COLOR],
                        themeColorsData,
                        contrastRatioThreshold: 2,
                    }).contrastColorName;
                    return {
                        [BUTTON_THEME_PRIMARY]: {
                            background: textColorNameForMainThemeColor,
                            text: findInverseTextColorName(textColorNameForMainThemeColor)
                        },
                        [BUTTON_THEME_ALTERNATE]: { background: alternateThemeBackground, text: alternateThemeText },
                        [BUTTON_THEME_SECONDARY]: {
                            background: TRANSPARENT_COLOR, text: textColorNameForMainThemeColor, border: textColorNameForMainThemeColor
                        },
                    };
                }
                default: { // BACKGROUND_THEME_ACCENTED
                    const alternateThemeBackground = findSuitableContrastColorName({
                        baseColorName: ACCENT_THEME_COLOR,
                        currentColorName: findInverseTextColorName(textColorNameForAccentThemeColor),
                        colorsNameArray: [MAIN_THEME_COLOR, textColorNameForAccentThemeColor],
                        themeColorsData,
                        contrastRatioThreshold: 2,
                    }).contrastColorName;
                    const alternateThemeText = findSuitableContrastColorName({
                        baseColorName: alternateThemeBackground,
                        currentColorName: ACCENT_THEME_COLOR,
                        colorsNameArray: [MAIN_THEME_COLOR, BLACK_THEME_COLOR, WHITE_THEME_COLOR],
                        themeColorsData,
                        contrastRatioThreshold: 2,
                    }).contrastColorName;
                    return {
                        [BUTTON_THEME_PRIMARY]: {
                            background: textColorNameForAccentThemeColor,
                            text: findInverseTextColorName(textColorNameForAccentThemeColor)
                        },
                        [BUTTON_THEME_ALTERNATE]: { background: alternateThemeBackground, text: alternateThemeText },
                        [BUTTON_THEME_SECONDARY]: {
                            background: TRANSPARENT_COLOR, text: textColorNameForAccentThemeColor, border: textColorNameForAccentThemeColor
                        },
                    };
                }
            }
        },
    getColorsFromBackgroundTheme = memoMax((
        backgroundTheme: ThemeBackgroundType, themeColorsData: ThemeColorDataType
    ): GetColorsFromBackgroundThemeRT | {} => {
        if (!backgroundTheme) { return {}; }
        const {
            background: buttonBackgroundColor,
            text: buttonTextColor
        } = getThemeRulesForButton(backgroundTheme, themeColorsData)[BUTTON_THEME_PRIMARY];
        return {
            backgroundColor: getThemeRulesForBackground(backgroundTheme, themeColorsData).background,
            textColor: findSuitableTextColorName(
                getThemeRulesForBackground(backgroundTheme, themeColorsData).background,
                themeColorsData
            ),
            buttonBackgroundColor,
            buttonTextColor
        };
    }, 6),
    THEME_LINK_LIST = [
        { value: `theme${LINK_THEME_ACCENT}`, label: LINK_THEME_STYLE_ACCENT, globalStyleLabel: LINK_THEME_GLOBALSTYLE_ACCENT },
        { value: `theme${LINK_THEME_UNDERLINED}`, label: LINK_THEME_STYLE_UNDERLINED, globalStyleLabel: LINK_THEME_GLOBALSTYLE_UNDERLINED },
        { value: `theme${LINK_THEME_TEXTLIKE}`, label: LINK_THEME_STYLE_TEXTLIKE, globalStyleLabel: LINK_THEME_GLOBALSTYLE_TEXTLIKE }
    ],
    themeLinkAccentBase = {
        "textDecoration": "none !important",
        "textShadow": "none !important",
        "backgroundColor": "unset !important"
    },
    themeLinkUnderlinedBase = {
        "textDecoration": "underline !important",
        "textShadow": "none !important",
        "backgroundColor": "unset !important"
    },
    themeLinkTextlikeBase = {
        "textDecoration": "none !important",
        "textShadow": "none !important",
        "backgroundColor": "unset !important"
    },
    themeLinkRules = {
        themeaccent: {
            ".themeaccent": themeLinkAccentBase,
            ".themeaccent-hover": themeLinkAccentBase,
            ".themeaccent:hover": themeLinkAccentBase
        },
        themeunderlined: {
            ".themeunderlined[class*=link]": themeLinkUnderlinedBase,
            ".themeunderlined-hover": themeLinkUnderlinedBase,
            ".themeunderlined:hover": themeLinkUnderlinedBase
        },
        themetextlike: {
            ".themetextlike": themeLinkTextlikeBase,
            ".themetextlike-hover": themeLinkTextlikeBase,
            ".themetextlike:hover": themeLinkTextlikeBase
        }
    },
    themeRulesBackgroundForLinkColor = (themeColorsData: ThemeColorDataType): ThemeRulesBackgroundForLinkColorRT => {
        const
            textColorNameForAccentThemeColor = findSuitableTextColorName(ACCENT_THEME_COLOR, themeColorsData),
            textColorNameForMainThemeColor = findSuitableTextColorName(MAIN_THEME_COLOR, themeColorsData);
        return {
            themeaccent: {
                [BACKGROUND_THEME_MAIN]: findSuitableContrastColorName({
                    baseColorName: getThemeRulesForBackground(BACKGROUND_THEME_MAIN, themeColorsData).background,
                    currentColorName: ACCENT_THEME_COLOR,
                    colorsNameArray: [BLACK_THEME_COLOR, WHITE_THEME_COLOR],
                    themeColorsData,
                    contrastRatioThreshold: 2,
                }).contrastColorName,
                [BACKGROUND_THEME_ACCENTED]: findSuitableContrastColorName({
                    baseColorName: getThemeRulesForBackground(BACKGROUND_THEME_ACCENTED, themeColorsData).background,
                    currentColorName: MAIN_THEME_COLOR,
                    colorsNameArray: [BLACK_THEME_COLOR, WHITE_THEME_COLOR],
                    themeColorsData,
                    contrastRatioThreshold: 2,
                }).contrastColorName,
                [BACKGROUND_THEME_WHITE]: findSuitableContrastColorName({
                    baseColorName: getThemeRulesForBackground(BACKGROUND_THEME_WHITE, themeColorsData).background,
                    currentColorName: ACCENT_THEME_COLOR,
                    colorsNameArray: [MAIN_THEME_COLOR, BLACK_THEME_COLOR],
                    themeColorsData,
                    contrastRatioThreshold: 2,
                }).contrastColorName,
                [BACKGROUND_THEME_BLACK]: findSuitableContrastColorName({
                    baseColorName: getThemeRulesForBackground(BACKGROUND_THEME_BLACK, themeColorsData).background,
                    currentColorName: ACCENT_THEME_COLOR,
                    colorsNameArray: [MAIN_THEME_COLOR, WHITE_THEME_COLOR],
                    themeColorsData,
                    contrastRatioThreshold: 2,
                }).contrastColorName,
            },
            themeunderlined: {
                [BACKGROUND_THEME_MAIN]: textColorNameForMainThemeColor,
                [BACKGROUND_THEME_ACCENTED]: textColorNameForAccentThemeColor,
                [BACKGROUND_THEME_WHITE]: BLACK_THEME_COLOR,
                [BACKGROUND_THEME_BLACK]: WHITE_THEME_COLOR,
            },
            themetextlike: {
                [BACKGROUND_THEME_MAIN]: textColorNameForMainThemeColor,
                [BACKGROUND_THEME_ACCENTED]: textColorNameForAccentThemeColor,
                [BACKGROUND_THEME_WHITE]: BLACK_THEME_COLOR,
                [BACKGROUND_THEME_BLACK]: WHITE_THEME_COLOR,
            },
        };
    },
    getThemeRulesForSvg = (
        backgroundTheme: ThemeBackgroundType, colorMapList: SvgColorObject[], themeColorsData: ThemeColorDataType
    ): SvgColorObject[] => {
        if (colorMapList.length === 1) {
            const { fromColor, toThemeColor } = colorMapList[0];
            const themeColor = toThemeColor || findSuitableContrastColorName({
                baseColorName: getThemeRulesForBackground(backgroundTheme, themeColorsData).background,
                currentColorName: ACCENT_THEME_COLOR,
                colorsNameArray: [MAIN_THEME_COLOR, BLACK_THEME_COLOR, WHITE_THEME_COLOR],
                themeColorsData
            }).contrastColorName;
            return [{ fromColor, toColor: toHex(themeColorsData[themeColor]), toThemeColor: themeColor }];
        } else {
            return colorMapList.map(({ fromColor, toColor, toThemeColor }) => ({
                fromColor, toColor: toThemeColor ? toHex(themeColorsData[toThemeColor]) : toColor, toThemeColor
            }));
        }
    },
    /* Only for webshop we are sending the actual colors. All others it is the color name. */
    /* In future, everything can be changed to colors. */
    getThemeRulesForWebshop =
        (backgroundTheme: ThemeBackgroundType, themeColorsData: ThemeColorDataType): GetThemeRulesForWebshopRT => {
            const
                { background, text } = getThemeRulesForBackground(backgroundTheme, themeColorsData),
                backgroundThemeColor = themeColorsData[background],
                fontColorName = text,
                fontColor = themeColorsData[fontColorName],
                hoverColorName = fontColorName,
                hoverColor = R.assocPath([4], 0.2, fontColor),
                mobileBackgroundColorName = background,
                mobileBackgroundColor = backgroundThemeColor,
                {
                    contrastColor: suitableContrastColorForAccent,
                    contrastColorName: suitableContrastColorNameForAccent
                } = findSuitableContrastColorName({
                    baseColorName: background,
                    currentColorName: ACCENT_THEME_COLOR,
                    colorsNameArray: [
                        MAIN_THEME_COLOR,
                        BLACK_THEME_COLOR,
                        WHITE_THEME_COLOR
                    ],
                    themeColorsData
                }),
                { text: labelTextColorName } = getThemeRulesForBackground(
                    getThemeFromBackgroundColor(suitableContrastColorNameForAccent), themeColorsData
                ),
                labelTextColor = themeColorsData[labelTextColorName],
                {
                    contrastColor: suitableContrastColorForMain,
                    contrastColorName: suitableContrastColorNameForMain
                } = findSuitableContrastColorName({
                    baseColorName: background,
                    currentColorName: MAIN_THEME_COLOR,
                    colorsNameArray: [
                        background === BLACK_THEME_COLOR ? WHITE_THEME_COLOR : BLACK_THEME_COLOR,
                    ],
                    themeColorsData
                });
            switch (backgroundTheme) {
                case BACKGROUND_THEME_BLACK:
                    return {
                        fontColor,
                        fontColorName,
                        focusColor: suitableContrastColorForAccent,
                        focusColorName: suitableContrastColorNameForAccent,
                        labelBgColor: suitableContrastColorForAccent,
                        labelBgColorName: suitableContrastColorNameForAccent,
                        labelTextColor,
                        labelTextColorName,
                        hoverColor,
                        hoverColorName,
                        mobileBackgroundColor,
                        mobileBackgroundColorName
                    };
                case BACKGROUND_THEME_WHITE:
                    return {
                        fontColor,
                        fontColorName,
                        focusColor: suitableContrastColorForAccent,
                        focusColorName: suitableContrastColorNameForAccent,
                        labelBgColor: suitableContrastColorForAccent,
                        labelBgColorName: suitableContrastColorNameForAccent,
                        labelTextColor,
                        labelTextColorName,
                        hoverColor,
                        hoverColorName,
                        mobileBackgroundColor,
                        mobileBackgroundColorName
                    };
                case BACKGROUND_THEME_MAIN: {
                    const {
                            contrastColor: focusColor,
                            contrastColorName: focusColorName
                        } = findSuitableContrastColorName({
                            baseColorName: background,
                            currentColorName: BLACK_THEME_COLOR,
                            colorsNameArray: [
                                WHITE_THEME_COLOR
                            ],
                            themeColorsData
                        }),
                        labelBgColorName = ACCENT_THEME_COLOR,
                        labelBgColor = themeColorsData[labelBgColorName],
                        { text: labelTextColorName } = getThemeRulesForBackground(
                            getThemeFromBackgroundColor(labelBgColorName), themeColorsData
                        ),
                        labelTextColor = themeColorsData[labelTextColorName];
                    return {
                        fontColor,
                        fontColorName,
                        focusColor,
                        focusColorName,
                        labelBgColor,
                        labelBgColorName,
                        labelTextColor,
                        labelTextColorName,
                        hoverColor,
                        hoverColorName,
                        mobileBackgroundColor,
                        mobileBackgroundColorName
                    };
                }
                case BACKGROUND_THEME_ACCENTED: {
                    const
                        {
                            contrastColor: focusColor,
                            contrastColorName: focusColorName
                        } = findSuitableContrastColorName({
                            baseColorName: background,
                            currentColorName: BLACK_THEME_COLOR,
                            colorsNameArray: [
                                WHITE_THEME_COLOR
                            ],
                            themeColorsData
                        }),
                        labelBgColorName = MAIN_THEME_COLOR,
                        labelBgColor = themeColorsData[labelBgColorName],
                        { text: labelTextColorName } = getThemeRulesForBackground(
                            getThemeFromBackgroundColor(labelBgColorName), themeColorsData
                        ),
                        labelTextColor = themeColorsData[labelTextColorName];
                    return {
                        fontColor,
                        fontColorName,
                        focusColor,
                        focusColorName,
                        labelBgColor,
                        labelBgColorName,
                        labelTextColor,
                        labelTextColorName,
                        hoverColor,
                        hoverColorName,
                        mobileBackgroundColor,
                        mobileBackgroundColorName
                    };
                }
                default:
                    return {
                        fontColor,
                        fontColorName,
                        focusColor: suitableContrastColorForAccent,
                        focusColorName: suitableContrastColorNameForAccent,
                        labelBgColor: suitableContrastColorForMain,
                        labelBgColorName: suitableContrastColorNameForMain,
                        labelTextColor,
                        labelTextColorName,
                        hoverColor,
                        hoverColorName,
                        mobileBackgroundColor,
                        mobileBackgroundColorName
                    };
            }
        };

export const getTableThemeRulesFromBackgroundTheme =
    (backgroundTheme: ThemeBackgroundType, themeColorsData: ThemeColorDataType): { [k: string]: ThemeColorTypes } => {
        const textThemeColor = getThemeRulesForBackground(backgroundTheme, themeColorsData).text,
            tableThemeRules = {
                [tableCellGlobalStyleRef.NORMAL]: textThemeColor,
                [tableCellGlobalStyleRef.HEADING1]: textThemeColor,
                [tableCellGlobalStyleRef.HEADING2]: textThemeColor,
                [tableCellGlobalStyleRef.ALTERNATE]: ACCENT_THEME_COLOR,
            };
        // @ts-ignore
        if ([BACKGROUND_THEME_ACCENTED, BACKGROUND_THEME_MAIN].includes(backgroundTheme)) {
            // @ts-ignore
            tableThemeRules[tableCellGlobalStyleRef.ALTERNATE] = findSuitableContrastColorName({
                baseColorName: getThemeRulesForBackground(backgroundTheme, themeColorsData).background,
                currentColorName: findInverseTextColorName(textThemeColor),
                colorsNameArray: [BLACK_THEME_COLOR, WHITE_THEME_COLOR],
                themeColorsData
            }).contrastColorName;
        } else { // BACKGROUND_THEME_BLACK, BACKGROUND_THEME_WHITE
            // @ts-ignore
            tableThemeRules[tableCellGlobalStyleRef.ALTERNATE] = findSuitableContrastColorName({
                baseColorName: getThemeRulesForBackground(backgroundTheme, themeColorsData).background,
                currentColorName: ACCENT_THEME_COLOR,
                colorsNameArray: [BLACK_THEME_COLOR, WHITE_THEME_COLOR],
                themeColorsData
            }).contrastColorName;
        }
        return tableThemeRules;
    };

export const getMenuThemeRulesFromBackgroundTheme = (backgroundTheme: ThemeBackgroundType, themeColorsData: ThemeColorDataType): {
    menu: {
        normalTextColor: Color;
        normalBackgroundColor: 'transparent';
        hoverTextColor: Color;
        hoverBackgroundColor: 'transparent';
        selectedTextColor: Color;
        selectedBackgroundColor: 'transparent';
    };
    submenu: {
        normalTextColor: Color;
        normalBackgroundColor: Color;
        hoverTextColor: Color;
        hoverBackgroundColor: Color;
        selectedTextColor: Color;
        selectedBackgroundColor: Color;
    };
} => {
    const
        themeRules = getThemeRulesForBackground(backgroundTheme, themeColorsData),
        textColorName = themeRules.text,
        inverseTextColorName = findInverseTextColorName(textColorName),
        textColor = themeColorsData[textColorName],
        backgroundColor = themeColorsData[themeRules.background],
        {
            [ACCENT_THEME_COLOR]: accentColor,
            [MAIN_THEME_COLOR]: mainColor,
            [WHITE_THEME_COLOR]: whiteColor,
            [BLACK_THEME_COLOR]: blackColor,
        } = themeColorsData;

    let menuNormalTextColor,
        menuHoverTextColor,
        menuSelectedTextColor,
        submenuNormalTextColor,
        submenuNormalBackgroundColor = backgroundColor,
        submenuHoverTextColor,
        submenuHoverBackgroundColor,
        submenuSelectedTextColor,
        submenuSelectedBackgroundColor = backgroundColor;

    // @ts-ignore
    if ([BACKGROUND_THEME_MAIN].includes(backgroundTheme)) {
        menuNormalTextColor = findSuitableContrastColor({
            baseColor: backgroundColor,
            currentColor: textColor,
            colorsArray: [accentColor, blackColor, whiteColor],
        });

        // Improvement in rule as it was not visible
        menuSelectedTextColor = findSuitableContrastColor({
            baseColor: backgroundColor,
            currentColor: themeColorsData[inverseTextColorName],
            colorsArray: [accentColor, blackColor, whiteColor],
            escapeColors: [menuNormalTextColor]
        });
        menuHoverTextColor = menuSelectedTextColor;

        submenuNormalTextColor = findSuitableContrastColor({
            baseColor: backgroundColor,
            currentColor: textColor,
            colorsArray: [accentColor, blackColor, whiteColor],
        });
        submenuSelectedTextColor = menuSelectedTextColor;
        submenuHoverTextColor = findSuitableContrastColor({
            baseColor: backgroundColor,
            currentColor: textColor,
            colorsArray: [accentColor, blackColor, whiteColor],
            escapeColors: [submenuNormalTextColor]
        });

        submenuHoverBackgroundColor = themeColorsData[inverseTextColorName];
        // @ts-ignore
    } else if ([BACKGROUND_THEME_BLACK, BACKGROUND_THEME_WHITE].includes(backgroundTheme)) {
        menuNormalTextColor = textColor;
        menuSelectedTextColor = findSuitableContrastColor({
            baseColor: backgroundColor,
            currentColor: accentColor,
            colorsArray: [blackColor, whiteColor, mainColor],
            escapeColors: [textColor]
        });
        menuHoverTextColor = menuSelectedTextColor;

        submenuNormalTextColor = textColor;
        submenuSelectedTextColor = findSuitableContrastColor({
            baseColor: backgroundColor,
            currentColor: accentColor,
            colorsArray: [blackColor, whiteColor, mainColor],
            escapeColors: [submenuNormalTextColor]
        });

        submenuHoverBackgroundColor = accentColor;
        submenuHoverTextColor = findSuitableContrastColor({
            baseColor: submenuHoverBackgroundColor,
            currentColor: textColor,
            colorsArray: [blackColor, whiteColor]
        });
    } else { // BACKGROUND_THEME_ACCENTED
        menuNormalTextColor = findSuitableContrastColor({
            baseColor: backgroundColor,
            currentColor: textColor,
            colorsArray: [mainColor, blackColor, whiteColor],
        });

        menuSelectedTextColor = findSuitableContrastColor({
            baseColor: backgroundColor,
            currentColor: themeColorsData[inverseTextColorName],
            colorsArray: [mainColor, blackColor, whiteColor],
            escapeColors: [menuNormalTextColor]
        });

        menuHoverTextColor = menuSelectedTextColor;

        submenuNormalTextColor = findSuitableContrastColor({
            baseColor: backgroundColor,
            currentColor: textColor,
            colorsArray: [mainColor, blackColor, whiteColor],
        });

        submenuSelectedTextColor = findSuitableContrastColor({
            baseColor: backgroundColor,
            currentColor: themeColorsData[inverseTextColorName],
            colorsArray: [mainColor, blackColor, whiteColor],
            escapeColors: [submenuNormalTextColor]
        });

        submenuHoverTextColor = findSuitableContrastColor({
            baseColor: backgroundColor,
            currentColor: textColor,
            colorsArray: [mainColor, blackColor, whiteColor],
            escapeColors: [submenuNormalTextColor]
        });

        submenuHoverBackgroundColor = submenuSelectedTextColor;
    }

    return {
        menu: {
            normalTextColor: menuNormalTextColor,
            normalBackgroundColor: TRANSPARENT_COLOR,
            hoverTextColor: menuHoverTextColor,
            hoverBackgroundColor: TRANSPARENT_COLOR,
            selectedTextColor: menuSelectedTextColor,
            selectedBackgroundColor: TRANSPARENT_COLOR,
        },
        submenu: {
            normalTextColor: submenuNormalTextColor,
            normalBackgroundColor: submenuNormalBackgroundColor,
            hoverTextColor: submenuHoverTextColor,
            hoverBackgroundColor: submenuHoverBackgroundColor,
            selectedTextColor: submenuSelectedTextColor,
            selectedBackgroundColor: submenuSelectedBackgroundColor,
        },
    };
};
