import dayjs from "../../../dayjs";
import type { LocaleData } from "../../../../dal/flowTypes";
import { parseLocale } from "./parseLocale";

export const initLocaleData = (localeData: LocaleData): LocaleData => {
    const
        { locale: rawLocale, messages } = localeData,
        [intlLocale, intlFileLocale] = parseLocale(rawLocale);

    dayjs.locale(intlFileLocale);

    require('@formatjs/intl-relativetimeformat/polyfill'); // eslint-disable-line import/no-dynamic-require

    require(`@formatjs/intl-relativetimeformat/locale-data/${intlFileLocale}`); // eslint-disable-line import/no-dynamic-require

    return { locale: intlLocale, messages };
};
