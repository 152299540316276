import React from 'react';
import cx from 'classnames';
import { Intl, Msg } from '../../../../../view/intl/index';
import * as styles from '../ModernLayoutLeftPanel.css';
import * as onBoardingStyles from '../../onBoardingPanel/onBoardingPanel.css';
import { ACTIVATE_MODERN_HEADER_FOOTER, HIDE_MODERN_LAYOUT_LEFT_PANEL } from '../../../actionTypes';
import { RESTORE_LAYOUT_TITLES } from '../../../constants';

type ModernLayoutRestreProps = {
    intl: Intl,
    dispatch: Dispatch
};

const ModernLayoutRestore = ({
    intl,
    dispatch
}: ModernLayoutRestreProps) => {
    return <React.Fragment>
        <div className={onBoardingStyles.closeIconWrapper}>
            <div
                onClick={() => dispatch({ type: HIDE_MODERN_LAYOUT_LEFT_PANEL })}
                className={onBoardingStyles.closeIcon}
            />
        </div>
        <div className={styles.restoreLayoutsContainer}>
            <div className={onBoardingStyles.titleWrapper}>
                <div className={cx(onBoardingStyles.title)}>
                    {intl.msgJoint(RESTORE_LAYOUT_TITLES.title)}
                </div>
            </div>
            <div className={onBoardingStyles.welcomeImage} />
            <div className={onBoardingStyles.welcomeSubTitleWrapper}>
                <div className={cx(onBoardingStyles.welcomeSubTitle)}>
                    {intl.msgJoint(RESTORE_LAYOUT_TITLES.subTitle)}
                </div>
            </div>
            <div className={onBoardingStyles.tryItBtnWrapper}>
                <button
                    type="button"
                    className={onBoardingStyles.tryItBtn}
                    onClick={() => {
                        dispatch({ type: ACTIVATE_MODERN_HEADER_FOOTER });
                    }}
                >
                    <Msg k="common.restoreLayouts">
                        Restore layouts
                    </Msg>
                </button>
            </div>
            {/* <div className={onBoardingStyles.learnMoreWrapper}>
                <a
                    className={onBoardingStyles.learnMoreLink}
                    target="_blank"
                >
                    {intl.msgJoint("msg: common.learnMore {Learn more}")}
                </a>
            </div> */}
        </div>
    </React.Fragment>;
};

export { ModernLayoutRestore };
