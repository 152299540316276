import type { SubscriptionTypeT } from '../../../../redux/modules/flowTypes';
import { isEcommerceSubscription } from "../../../App/epics/subscriptionData/utils";
import { isSeamlessUpgradeDisabledByConfig } from "../../../ComponentTierManager/utils";

export const isFreeUpgradeAvailable = (subscriptionData: SubscriptionTypeT) => {
    const { subscriptionType, availableUpgrades } = subscriptionData;

    if (
        isSeamlessUpgradeDisabledByConfig() ||
        isEcommerceSubscription(subscriptionType) ||
        !Array.isArray(availableUpgrades)
    ) {
        return false;
    }

    return availableUpgrades.some(availableUpgrade => {
        return availableUpgrade.freeUpgradeAvailable;
    });
};
