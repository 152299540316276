export const webShopMHFMockData = {
    "isShopPublished": true,
    "paymentMethods": [
        {
            "key": "Paypal",
            "images": [
                "https://webshopbackend-static.cdn-one.com/paypal.3bd17ec4.svg"
            ],
            "name": null
        },
        {
            "key": "Stripe",
            "name": null
        },
        {
            "images": null,
            "key": "Manual",
            "name": "Bank Transfer"
        },
        {
            "key": "Ideal",
            "images": [
                "https://webshopbackend-static.cdn-one.com/ideal.ce7159f4.svg"
            ],
            "name": null
        },
        {
            "key": "Giropay",
            "images": [
                "https://webshopbackend-static.cdn-one.com/giropay.ebff196a.svg"
            ],
            "name": null
        },
        {
            "key": "Sofort",
            "images": [
                "https://webshopbackend-static.cdn-one.com/sofort.96ebc62d.svg"
            ],
            "name": null
        },
        {
            "key": "CreditCard",
            "images": [
                "https://webshopbackend-static.cdn-one.com/visa.0ebb52d9.svg",
                "https://webshopbackend-static.cdn-one.com/master-card.063b13c0.svg",
                "https://webshopbackend-static.cdn-one.com/american-express.444731bb.svg"
            ],
            "name": null
        }
    ],
    "policies": [
        {
            "name": "Privacy policy"
        },
        {
            "name": "Sales terms & conditions"
        },
        {
            "name": "Refund policy"
        }
    ]
};
