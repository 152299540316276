import CALL_API from '../../../../../redux/middleware/api/CALL_API';
import * as Actions from '../actionTypes';
import type { ApiAction } from '../../../../../redux/middleware/api/flowTypes';

export default function (): ApiAction {
    return {
        [CALL_API]: {
            types: [
                Actions.LOAD_PAID_FEATURES_DOC_REQUEST,
                Actions.LOAD_PAID_FEATURES_DOC_SUCCESS,
                Actions.LOAD_PAID_FEATURES_DOC_FAILURE
            ],
            endpoint: 'getPaidFeaturesDoc'
        }
    };
}
