import { getByPath } from "../../../../utils/ramdaEx";
import { styleTextSize } from '../../../../mappers/path';
import { makeValueReducer } from "../../../../redux/makeReducer/index";
import * as actionTypes from '../actionTypes';

export default makeValueReducer({
    defaultState: null,
    handleActions: {
        [actionTypes.BUTTON_MCTA_CHANGE_TEXT]:
            (componentExtension, { payload: text, component }) => ({
                ...componentExtension,
                offScreenRendererText: text,
                fontSize: getByPath(styleTextSize, component)
            }),
        [actionTypes.BUTTON_MCTA_CHANGE_FONT_SIZE]:
            (componentExtension, { payload: fontSize, component }) => ({
                ...componentExtension,
                fontSize,
                offScreenRendererText: component.text
            }),
        [actionTypes.BUTTON_UPDATE_COMPONENT_ON_RESIZE]:
            (componentExtension, { payload: { width, isBtnInModernLayout } }) => {
                return componentExtension ? {
                    ...componentExtension,
                    offScreenRenderTextWidth: width,
                    isBtnInModernLayout
                } : componentExtension;
            }

    }
});
