import type { Position } from '../../../redux/modules/flowTypes';

export type PositionsOnDrop = {
    positionWithRespectToBrowser: Position,
    mousePosition: Position,
    mousePositionWithRespectToTemplateArea: Position
};

export const
    getCmpPositionOnDrop = ({
        positionWithRespectToBrowser,
        mousePosition,
        mousePositionWithRespectToTemplateArea
    }: PositionsOnDrop): Position => {
        const
            xDiff = mousePosition.x - mousePositionWithRespectToTemplateArea.x,
            yDiff = mousePosition.y - mousePositionWithRespectToTemplateArea.y;
        return {
            x: positionWithRespectToBrowser.x - xDiff,
            y: positionWithRespectToBrowser.y - yDiff
        };
    };
