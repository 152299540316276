import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles(
    theme => ({
        root: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: theme.palette.custom.colorGray_f7,
            padding: "8px 24px",
            userSelect: "none",
            zIndex: 100,
            gap: "24px",
            [theme.breakpoints.up("sm")]: {
                padding: "8px 48px",
            }
        },
        content: {
            display: "flex",
            flexDirection: ({ inline }: { inline?: boolean }) => (inline ? "row" : "column"),
            justifyContent: "center",
            alignItems: "center",
            gap: "8px"
        },
        icon: {},
        message: {
            maxWidth: ({ inline }: { inline?: boolean }) => (inline ? "none" : "400px"),
            textAlign: "center",
            fontSize: "14px",
            lineHeight: "22px",
            letterSpacing: "0.25px"
        },
        reloadButton: {
            width: "100%",
            maxWidth: "312px",
            fontSize: "14px",
            lineHeight: "22px",
            letterSpacing: "0.25px",
            fontWeight: 500,
            padding: "12px 24px",
            [theme.breakpoints.up("sm")]: {
                width: "auto"
            }
        }
    }),
    { name: "ErrorBoundary" }
);
