import { takeSuccessFromApiActionGen, openDialogGen } from "../../../utils/saga";
import { DataPageSet } from "../../../../dal/model";
import loadRepositoryPageDataSet from "../actionCreators/loadRepositoryPageDataSet";
import { PageLoadFailureDialogId } from "../dialogs/pagesTreeDialogIds";

export default function* (accountName: string, pageId: string, config: any = {}): Generator<any, DataPageSet, any> {
    return yield* takeSuccessFromApiActionGen(
        loadRepositoryPageDataSet(
            accountName,
            pageId,
            {
                flagAutoColorActive: config.flagAutoColorActive
            }
        ),
        function* () {
            yield* openDialogGen(PageLoadFailureDialogId);
        }
    );
}
