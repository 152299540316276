import React, { useEffect, useState, useRef } from 'react';
import { getDAL } from "../../../../../dal/index";
import type { FeaturedProductsViewProps } from '../flowTypes';
import getQueryData from './getQueryData';
import { FEATURED_PRODUCTS_UPDATE_CMP_HEIGHT_BY_IMAGE_RATIO } from '../actionTypes';

export const FrameComponent = (viewProps: FeaturedProductsViewProps) => {
    const { isMVEFocus, dispatch, component, buyNowButtonStyle } = viewProps;
    const { products } = component;

    const [componentHeight, setComponentHeight] = useState(0);
    const [iframeComponent, setIframeComponent] = useState<HTMLIFrameElement | null>(null);
    const oldBuyButtonStyles = useRef<string>(null);
    const dummyParams = useRef(0);

    const updateComponentHeight = (event: any) => {
        const eventData = event.data.data;
        const widgetHeight = eventData.widgetHeight;
        const widgetId = eventData.widgetId;
        if (iframeComponent && iframeComponent.contentDocument
            && iframeComponent.contentDocument.getElementById(widgetId)) {
            dispatch({
                type: FEATURED_PRODUCTS_UPDATE_CMP_HEIGHT_BY_IMAGE_RATIO,
                payload: {
                    componentId: widgetId.replace('featured-products-widget-', ''),
                    height: widgetHeight
                }
            });
            setComponentHeight(widgetHeight);
        }
    };

    const sendProductIds = (event: any) => {
        const eventData = event.data.data;
        const widgetId = eventData.widgetId;
        // echo products
        if (iframeComponent
            && iframeComponent.contentWindow
            && typeof iframeComponent.contentWindow.postMessage === 'function') {
            iframeComponent.contentWindow.postMessage(
                {
                    type: 'RECEIVE_PRODUCT_IDS_FROM_CMP',
                    data: { widgetId, productIds: products }
                },
                new URL(iframeComponent.src).origin
            );
        }
    };

    const sendBuyNowButtonStyles = (event: any) => {
        const eventData = event.data.data;
        const widgetId = eventData.widgetId;
        if (iframeComponent && iframeComponent.contentWindow && typeof iframeComponent.contentWindow.postMessage === "function") {
            iframeComponent.contentWindow.postMessage(
                {
                    type: "RECEIVE_BUY_NOW_BUTTON_STYLES_FROM_CMP",
                    data: { widgetId, buyNowButtonStyle }
                },
                new URL(iframeComponent.src).origin
            );
        }
    };

    const handleMessageEvents = (event: any) => {
        if (event && event.data && event.data.type) {
            const eventType = event.data.type;
            if (eventType === 'UPDATE_FEATURED_PRODUCTS_HEIGHT') {
                updateComponentHeight(event);
            } else if (eventType === 'FETCH_PRODUCT_IDS_FROM_CMP') {
                sendProductIds(event);
            } else if (eventType === "FETCH_BUY_NOW_BUTTON_STYLES_FROM_CMP") {
                sendBuyNowButtonStyles(event);
            }
        }
    };

    useEffect(() => {
        window.addEventListener("message", handleMessageEvents, false);
        return () => {
            window.removeEventListener("message", handleMessageEvents, false);
        };
    });

    if (buyNowButtonStyle !== oldBuyButtonStyles.current) {
        dummyParams.current = dummyParams.current + 1;
    }
    // @ts-ignore This should not be used in this way
    oldBuyButtonStyles.current = buyNowButtonStyle;

    const queryData = { ...getQueryData(viewProps), dummyParam: dummyParams.current };

    return (
        <iframe
            src={getDAL().makeFeaturedProductsPreviewUrl(queryData)}
            frameBorder={0}
            width="100%"
            height={componentHeight || "300px"}
            ref={ref => {
                if (ref && ref.contentDocument) {
                    setIframeComponent(ref);
                }
                if (ref && isMVEFocus) {
                    // @ts-ignore
                    ref.contentWindow.newWSBMVE = true; // eslint-disable-line no-param-reassign
                }
            }}
        />
    );
};
