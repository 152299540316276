/* eslint-disable max-len */

import React from "react";
import cx from 'classnames';
import * as styles from './UpgradeOption.css';
import { Msg } from "../../../../view/intl/index";
import Popup from "../Popup/index";
import OpenUpgradePlanDialog from "../Upgrade/UpgradePlansDialog/OpenUpgradePlanDialog";
import { upgradeDialogOpenedAction } from "../../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions";
import { FEATURE_TOP_BAR } from "../Upgrade/tracking/contants";
import { UpgradePlansDialogId } from "../dialogIds";
import { SubscriptionData } from "../../../../redux/modules/flowTypes";

type UpgradeOptionProps = {
    onMouseOver: React.MouseEventHandler<HTMLDivElement>;
    onMouseOut: React.MouseEventHandler<HTMLDivElement>;
    dispatch: Function;
    upgradeMsg: React.ReactElement<React.ComponentProps<any>, any>;
    subscriptionData: SubscriptionData
};

type UpgradePopupProps = {
    onClick: React.MouseEventHandler<HTMLAnchorElement>;
    upgradeMsg: React.ReactElement<React.ComponentProps<any>, any>;
};

const
    UpgradeToPremium = (props: UpgradePopupProps) => {
        return (
            <Popup
                containerClassName={cx(styles.container, styles.upgradeToPremium)}
                tipClassName={styles.tip}
                bodyClassName={styles.upgradeBody}
            >
                <div className={styles.upgradeHeader}>
                    <Msg k="upgradeOption.upgradeWsb">
                        Upgrade Website Builder
                    </Msg>
                </div>
                <div className={styles.upgradeIcon} />
                <div className={styles.upgradeMsg}>
                    {props.upgradeMsg}
                </div>
                <div className={styles.upgradeDialogLearnMore}>
                    <a href="#" onClick={props.onClick}>
                        <Msg k="common.learnMore" className={styles.learnMoreText}>
                            Learn more
                        </Msg>
                    </a>
                </div>
            </Popup>
        );
    };
class UpgradeOption extends React.PureComponent<UpgradeOptionProps> {
    constructor(props: UpgradeOptionProps) {
        super(props);
        this.onClickHandler = this.onClickHandler.bind(this);
    }

    onClickHandler() {
        const { dispatch, subscriptionData } = this.props;

        dispatch(OpenUpgradePlanDialog(subscriptionData, 'Workspace:TopBlueBarBtn'));
        dispatch(upgradeDialogOpenedAction({
            feature: FEATURE_TOP_BAR,
            dialogId: UpgradePlansDialogId
        }));
    }

    render() {
        const { onMouseOver, onMouseOut, upgradeMsg } = this.props;

        return (
            <div onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
                <UpgradeToPremium
                    onClick={this.onClickHandler}
                    upgradeMsg={upgradeMsg}
                />
            </div>
        );
    }
}

export default UpgradeOption;
