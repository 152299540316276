import makeEpic from '../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import { WORKSPACE_LEFT_MOUSE_DOWN, WORKSPACE_CLICK } from "../../actionTypes";
import { WINDOW_MOUSE_UP } from "../../../App/actionTypes";
import type { Position } from '../../../../redux/modules/flowTypes';

type EpicState = {
    downTimestamp: number,
    downPosition: Position | null
}

const defaultState: EpicState = {
    downTimestamp: 0,
    downPosition: null
};

export const
    FuzzyClickDistance = 3,
    calcPositionDiff = (p1: Position, p2: Position) => {
        const
            positionDiffX = p1.x - p2.x,
            positionDiffY = p1.y - p2.y;

        return Math.sqrt((positionDiffX * positionDiffX) + (positionDiffY * positionDiffY));
    };

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [WORKSPACE_LEFT_MOUSE_DOWN],
            reducer: ({ values: [position] }) => ({ state: { downTimestamp: Date.now(), downPosition: position } })
        },
        {
            conditions: [WINDOW_MOUSE_UP],
            reducer: ({ values, state }) => {
                if (state.downTimestamp === 0) {
                    return { state };
                }

                const
                    position: Position = values[0],
                    positionDiff = calcPositionDiff(position, state.downPosition);

                if (positionDiff < FuzzyClickDistance) {
                    const actionToDispatch: Action = { type: WORKSPACE_CLICK, payload: position };
                    return { state: defaultState, actionToDispatch };
                }
                return { state: defaultState };
            }
        }
    ]
});
