import kind from "../kind";
import backgroundReducerFactory from "./backgroundReducerFactory";
import p from '../../../../utils/pipePath';
import * as mp from '../../../../mappers/path';
import { setToPath } from "../../../../utils/ramdaEx";
import { BACKGROUND_CMP_DEFAULT_COLOR } from "../constants";

const
    solidColorPath = p(mp.styleBackground, mp.colorData, mp.color),
    defaultState = {
        ...setToPath(
            solidColorPath,
            BACKGROUND_CMP_DEFAULT_COLOR,
            null
        )
    };

export default backgroundReducerFactory(kind, defaultState);
