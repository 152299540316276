import * as R from "ramda";
import makeUuid from "../../utils/makeUuid";
import { mapToComponent, mapBackFromComponent } from "./mappers/index";
import type { ComponentsMap } from "../../src/redux/modules/children/workspace/flowTypes";
import DataPageSet from "../model/DataPageSet";
import type { Stylesheets } from "../../src/components/Workspace/epics/stylesheets/flowTypes";
import { DataSite } from "../model/index";
import type { BackDeps } from "./mappers/index";
import {
    getWrappedComponentIds,
    removeWrappedNode
} from "../../src/utils/htmlWriter/html/render/wrapper/wrapperNodeUtils";
import { TextComponentKind } from "../../src/components/oneweb/Text/kind";
import CodeComponentKind from "../../src/components/oneweb/Code/kind";
import { arrayToTrueMap } from "../../src/utils/arrayToTrueMap";
import { mapIndexed } from '../../src/utils/ramdaEx';
import { MaxComponentsOnPage } from '../../src/constants/app';
import cmpRoundDecimalValues from "../../src/utils/componentsMap/cmpRoundDecimalValues";
import { getNonGhostCmps, simpleTopSorter } from "../../src/components/Workspace/epics/componentAttachements/util";
import { isHeaderSection } from "../../src/components/oneweb/Section/utils";
import { isSectionKind } from "../../src/components/oneweb/componentKinds";

// orderIndex may be undefined or same index may be shared between multiple components
// so far only 0 shared value was observed in WBTGEN-4542
// we have spaces in between sequences (ex. 0, 1, 2, 27)
export function fixOrderIndex(components: Array<{ orderIndex: number | void, id: string }>) {
    let atLeastOneChange = false;
    const
        newComponents = R.pipe(
            mapIndexed((component, index) =>
                ({ component, sortIndex: ((component.orderIndex || 0) * MaxComponentsOnPage) + index })),
            R.sort((c1, c2) => c1.sortIndex - c2.sortIndex),
            mapIndexed(({ component }, index) => {
                if (component.orderIndex === index) {
                    return component;
                }
                atLeastOneChange = true;
                return { ...component, orderIndex: index };
            })
        )(components);

    if (atLeastOneChange) {
        return components.map(c => newComponents.find(({ id }) => c.id === id));
    }
    return components;
}

// Some customers has invalid wrap flag set to true while component is not wrapped
function sanitizeWrapFlag(componentsMap, throwException) {
    const
        wrappedComponentsIds = R.pipe(
            R.values,
            R.filter(({ kind }) => kind === TextComponentKind),
            R.map(R.pipe(R.prop('content'), getWrappedComponentIds)),
            R.flatten,
            R.uniq // in case multiple text components are telling that they wrapping same component, deduplicate
        )(componentsMap),
        wrappedComponentsIdsMap = arrayToTrueMap(wrappedComponentsIds),
        wrappedComponentIdsThatDoesNotExist = wrappedComponentsIds.filter(id => !componentsMap[id]); // due to some bug text component contain referece to component that is not exist

    if (throwException && wrappedComponentIdsThatDoesNotExist.length) {
        setTimeout(() => {
            throw new Error(`Some of text components contain wrappedComponentIdsThatDoesNotExist: `
                + wrappedComponentIdsThatDoesNotExist.join(','));
        });
    }

    return R.evolve(
        R.map(({ id }) => R.evolve({
            wrap: (wrap) => {
                if (wrap === undefined && !wrappedComponentsIdsMap[id]) {
                    return undefined;
                }
                return !!wrappedComponentsIdsMap[id];
            },
            content: content => {
                return wrappedComponentIdsThatDoesNotExist.reduce(removeWrappedNode, content);
            }
        }), componentsMap),
        componentsMap
    );
}

const isValidComponent = (component) => {
    const { width, height, kind } = component;
    if (isSectionKind(kind)) {
        return true;
    }
    return !(width <= 0 || height <= 0 || (width === 1 && height === 1 && kind !== CodeComponentKind));
};

const fixSections = (componentsMap) => {
    const newComponentsMap = { ...componentsMap },
        cmps = getNonGhostCmps(componentsMap);
    let header = cmps.find(cmp => isHeaderSection(cmp));
    if (header) {
        return componentsMap;
    }
    let templateSections = cmps.filter(cmp => cmp.inTemplate && isSectionKind(cmp.kind)).sort(simpleTopSorter),
        possibleHeader = (templateSections.length === 2) && templateSections[0];
    if (possibleHeader) {
        newComponentsMap[possibleHeader.id] = {
            ...possibleHeader,
            top: 0
        };
        header = newComponentsMap[possibleHeader.id];
        if (header.height >= 1000) {
            let relInCmps: any = [], headerHeight = 0;
            cmps.forEach(cmp => {
                if (cmp.relIn && cmp.relIn.id === header.id) {
                    relInCmps.push(cmp);
                }
            });
            relInCmps.forEach(cmp => {
                headerHeight = Math.max(cmp.relIn.top + cmp.height, headerHeight);
            });
            if (header.height > (headerHeight + 50)) {
                header.height = headerHeight + 50;
                relInCmps.forEach(cmp => {
                    newComponentsMap[cmp.id] = {
                        ...cmp,
                        relIn: {
                            ...cmp.relIn,
                            bottom: cmp.relIn.top + cmp.height - header.height
                        }
                    };
                });
            }
        }
    }
    return newComponentsMap;
};

export function mapPageDataToComponentsMap(
    response: DataPageSet,
    site?: null | undefined | DataSite,
    calledFromUpdateItemsRelations: boolean = false,
    throwException: boolean = true,
    globalVariables: Record<string, any> = {},
    isPreview: boolean = false,
): ComponentsMap {
    const
        componentsMap = {},
        pageItems = (response.page.items || []),
        templateItems = (response.template.items || []),
        { stylesheet, template } = response,
        /* WBTGEN-11755 - id is missing from component and hence the app is misbehaving. */
        /* Check for components which don't have id and generate them fresh. */
        fixMissingComponentIds = R.map(item => ({
            ...item,
            id: item.id || makeUuid(),
            items: fixMissingComponentIds(item.items || []),
        })),
        getMapper = inTemplate => item => {
            const
                deps = {
                    stylesheets: stylesheet as unknown as Stylesheets,
                    componentsData: [...pageItems, ...templateItems],
                    site,
                    templateId: template.id,
                    globalVariables,
                    isPreview,
                },
                // @ts-ignore
                { component, id } = mapToComponent({ ...item, inTemplate }, deps, calledFromUpdateItemsRelations); // TODO mapToComponent should only return a component
            if (isValidComponent(component)) {
                componentsMap[id] = cmpRoundDecimalValues(component);
            }
        };

    // WBTGEN-11755
    let allComponents = fixMissingComponentIds([
            ...pageItems,
            ...(templateItems.map(cmp => ({ ...cmp, inTemplate: true })))
        ]),
        sections: any = [], strips: any = [], fixedPageItems: any = [], fixedTemplateItems: any = [];
    allComponents.forEach(component => {
        if (isSectionKind(component.type)) {
            sections.push(component);
        } else if (component.stretch) {
            strips.push(component);
        } else if (component.inTemplate) {
            fixedTemplateItems.push(component);
        } else {
            fixedPageItems.push(component);
        }
    });
    fixOrderIndex(sections).forEach(section => getMapper(section.inTemplate)(section));
    fixOrderIndex(strips).forEach(strip => getMapper(strip.inTemplate)(strip));
    fixOrderIndex(fixedTemplateItems).forEach(getMapper(true));
    fixOrderIndex(fixedPageItems).forEach(getMapper(false));

    if (calledFromUpdateItemsRelations) {
        return componentsMap;
    }
    return fixSections(sanitizeWrapFlag(componentsMap, throwException));
}

export function mapComponentsMapToPageData(
    componentsMap: ComponentsMap,
    stylesheets?: Stylesheets,
    site?: DataSite,
    calledFromUpdateItemsRelations?: true
): any {
    const
        page = { items: [] },
        template = { items: [] },
        deps: BackDeps = {
            stylesheets,
            site
        };

    Object.keys(componentsMap).forEach(id => {
        const component = mapBackFromComponent(componentsMap[id], deps, calledFromUpdateItemsRelations);

        if (!component) {
            return; // happens when there is no mapper
        }

        const { items }: any = component.inTemplate ? template : page;
        items.push(component);
    });

    return { page, template };
}
