import * as actions from '../actionTypes';
import { WIDGETS_PAYPAL_EPIC_VAT } from "./valueActionType";
import { configurationDialogId } from '../configurationDialog';
import { parsePaypalCode } from '../utils/parsePaypalCode';
import { epicFactory } from '../../epics/factory';
import { linkExtractor } from '../../utils';

const WidgetsPaypalEpic = epicFactory({
    defaultState: null,
    valueActionType: WIDGETS_PAYPAL_EPIC_VAT,
    configurationDialogId,
    codeParser: parsePaypalCode,
    payloadExtractor: linkExtractor,
    setLinkAction: actions.WIDGETS_PAYPAL_SET_LINK,
    updatePropsAction: actions.WIDGETS_PAYPAL_UPDATE_PROPS,
    showUpdateLinkDialogAction: actions.WIDGETS_PAYPAL_SHOW_UPDATE_LINK_DIALOG,
    closeDialogActions: [actions.WIDGETS_PAYPAL_UPDATE_LINK, actions.WIDGETS_PAYPAL_UPDATE_PROPS],
});

export { WidgetsPaypalEpic };

