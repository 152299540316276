import p from "../utils/pipePath";

export type Path = Array<number|string>;

// todo always export array WBTGEN-1835
export const
    id = 'id',
    align = 'align',
    block = 'block',
    background = 'background',
    asset = 'asset',
    scrollEffect = 'scrollEffect',
    height = 'height',
    colorData = 'colorData',
    assetData = 'assetData',
    width = 'width',
    color = 'color',
    theme = 'theme',
    colors = 'colors',
    direction = 'direction',
    gradient = 'gradient',
    stops = 'stops',
    origin = 'origin',
    degree = 'degree',
    position = 'position',
    repeat = 'repeat',
    overlay = 'overlay',
    padding = 'padding',
    paddingTop = p(padding, 0),
    paddingRight = p(padding, 1),
    paddingBottom = p(padding, 2),
    paddingLeft = p(padding, 3),
    inactive = 'inactive',
    hover = 'hover',
    visited = 'visited',
    press = 'press',
    disabled = 'disabled',
    item = 'item',
    items = 'items',
    divider = 'divider',
    selected = 'selected',
    expandable = 'expandable',
    expanded = 'expanded',
    font = 'font',
    border = 'border',
    corners = 'corners',
    images = 'images',
    cornersTopLeft = p(corners, 0),
    cornersBottomLeft = p(corners, 3),
    cornersTopRight = p(corners, 1),
    cornersBottomRight = p(corners, 2),
    borderTopWidth = p(width, 0),
    borderRightWidth = p(width, 1),
    borderBottomWidth = p(width, 2),
    borderLeftWidth = p(width, 3),
    bold = 'bold',
    italic = 'italic',
    underline = 'underline',
    size = 'size',
    style = 'style',
    captionBoxStyle = 'captionBoxStyle',
    opacity = 'opacity',
    styleButton = 'styleButton',
    fileUploadButtonStyle = 'fileUploadButtonStyle',
    text = 'text',
    fadePoint = 'fadePoint',
    shadow = 'shadow',
    left = 'left',
    right = 'right',
    top = 'top',
    bottom = 'bottom',
    blur = 'blur',
    letterSpacing = 'letterspacing',
    lineHeight = 'lineHeight',
    highlight = 'highlight',
    globalId = 'globalId',
    globalName = 'globalName',
    state = 'state',
    settings = 'settings',
    value = 'value',
    transform = 'transform',
    horizontalAlign = 'horizontalAlign',
    verticalAlign = 'verticalAlign',
    accordionTextIndent = 'accordionTextIndent',
    cascadeWidth = 'cascadeWidth',
    url = 'url',
    bbox = 'bbox',
    etag = 'etag',
    mobileDown = 'mobileDown',
    mobileHide = 'mobileHide',
    subscript = 'subscript',
    superscript = 'superscript',
    mobileSettings = 'mobileSettings',
    showDropdownArrow = 'showDropdownArrow';

export const
    colorAlpha = p(color, 4),
    shadowColor = p(shadow, color),
    shadowBlur = p(shadow, blur),
    shadowHorizontalOffset = p(shadow, left),
    shadowVerticalOffset = p(shadow, top);

export const
    backgroundColor = p(background, colorData, color),
    backgroundGradient = p(background, colorData, gradient),
    backgroundPosition = p(background, assetData, position),
    backgroundRepeat = p(background, assetData, repeat),
    backgroundOverlay = p(background, assetData, overlay),
    backgroundAsset = p(background, assetData, asset),
    backgroundSize = p(background, assetData, size),
    backgroundOpacity = p(background, assetData, opacity);

export const
    styleBackground = p(style, background),
    styleBackgroundColor = p(style, backgroundColor),
    styleBackgroundGradient = p(style, background, colorData, gradient),
    styleBackgroundAssetDataScrollEffect = p(style, background, assetData, scrollEffect),
    styleBackgroundAsset = p(style, background, assetData, asset),
    styleBackgroundOpacity = p(style, background, assetData, opacity),
    styleBackgroundSize = p(style, backgroundSize),
    styleBorder = p(style, border);

export const
    captionBoxStyleBackgroundColor = p(captionBoxStyle, backgroundColor),
    captionBoxStyleBackgroundColorAlpha = p(captionBoxStyleBackgroundColor, 4);

export const
    blockBackground = p(block, background),
    blockBackgroundColor = p(block, backgroundColor),
    inActiveBlockBackgroundColorDataColor = p(inactive, block, background, colorData, color),
    blockBackgroundColorOpacity = p(blockBackgroundColor, 4),
    blockBackgroundGradient = p(block, backgroundGradient),
    blockBackgroundAsset = p(block, backgroundAsset),
    blockBackgroundPosition = p(block, backgroundPosition),
    blockBackgroundRepeat = p(block, backgroundRepeat),
    blockBackgroundOverlay = p(block, backgroundOverlay),
    blockBackgroundSize = p(block, backgroundSize),
    blockPadding = p(block, padding),
    blockPaddingLeft = p(block, paddingLeft),
    blockPaddingRight = p(block, paddingRight),
    blockPaddingTop = p(block, paddingTop),
    blockPaddingBottom = p(block, paddingBottom);

export const
    itemStyleBackgroundColor = p(style, background, color),
    gsStyleBackgroundColor = p(background, color);

export const
    // Text styles
    textFont = p(text, font),
    textColor = p(text, color),
    textColorOpacity = p(textColor, 4),
    textHighlight = p(text, highlight),
    textShadow = p(text, shadow),
    textShadowColor = p(text, shadowColor),
    textShadowColorOpacity = p(textShadowColor, 4),
    textShadowBlur = p(text, shadowBlur),
    textShadowHorizontalOffset = p(text, shadowHorizontalOffset),
    textShadowVerticalOffset = p(text, shadowVerticalOffset),
    textSize = p(text, size),
    textBold = p(text, bold),
    textItalic = p(text, italic),
    textUnderline = p(text, underline),
    textTransform = p(text, transform),
    inactiveTextTransform = p(inactive, textTransform),
    inactiveTextBold = p(inactive, textBold),
    inactiveTextSize = p(inactive, textSize),
    inactiveBlockBackgroundColor = p(inactive, block, background, color),
    inactiveButtonTextFontColor = p(inactive, text, color),
    inactiveLinkTextFontColor = p(inactive, color),
    styleTextSize = p(style, textSize),
    inactiveTextItalic = p(inactive, textItalic),
    inactiveTextUnderline = p(inactive, textUnderline);

export const
    borderColor = p(border, color),
    borderStyle = p(border, style),
    borderWidth = p(border, width);

export const
    blockBorder = p(block, border),

    // Border style
    blockBorderStyle = p(block, borderStyle),

    // Border width
    blockBorderWidth = p(block, borderWidth),

    // Border colors
    blockBorderColor = p(block, borderColor),
    blockBorderColorOpacity = p(block, border, color, 4),

    // Border radius
    blockBorderRadius = p(block, border, corners);

export const
    styleGlobalId = p(style, globalId),
    styleGlobalName = p(style, globalName),
    styleButtonGlobalName = p(styleButton, globalName),
    subMenuStyleGlobalId = p('subStyle', globalId),
    subMenuStyleGlobalName = p('subStyle', globalName);

export const
    stateSettingsVideoUrlValue = p(state, settings, 0, value);

export const
    styleButtonGlobalId = p(styleButton, globalId),
    fileUploadButtonStyleGlobalId = p(fileUploadButtonStyle, globalId);

export const
    stylePadding = p(style, padding);
export const showDropDownArrowPath = [expandable, showDropdownArrow];

export const themeColors = p(theme, colors);

export const backgroundSizeMobileSettings = p(mobileSettings, size);

export const
    themeMainMenuIdPath = ['themeStyles', 'mainMenu', 'id'],
    themeMainMenuNamePath = ['themeStyles', 'mainMenu', 'name'],
    themeSubmenuIdPath = ['themeStyles', 'submenu', 'id'],
    themeSubmenuNamePath = ['themeStyles', 'submenu', 'name'];
