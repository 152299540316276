import React from 'react';
import * as R from 'ramda';
import { pure } from 'recompose';
import {
    MctaPropertyContainer, MctaVerticalBar, MctaCheckbox, MctaCombobox, MctaColumnGrid
} from '../../../componentMainActions/index';
import { MctaSlider } from '../../../componentMainActions/MctaSlider/MctaSlider';
import * as actionTypes from '../actionTypes';
import type { CTAViewParams } from '../../../../view/Workspace/Decorations/ComponentMainActions/flowTypes';
import HorizontalSpacer from '../../../../view/common/HorizontalSpacer';
import { IMAGE_RATIO } from "../../../presentational/ImageRatioComboBox/constants";
import type { GalleryComponent } from "../flowTypes";
import styles from '../../../componentMainActions/MctaSlider/MctaSlider.css';
import { propPanelNavigateToPageAC } from "../../../PropertiesPanel/actionCreators";
import { FULL_WIDTH } from "../propertiesPanel/pagesIds";

type MobileMCTAProps = {
    dispatch: Dispatch;
    id: string;
    selectedComponent: GalleryComponent
};

const
    maxSpacingInFullWidth = 190,
    columnOptions = R.range(1, 10).map(v => ({ value: v, label: v })),
    EditView = pure(({ selectedComponent: { columns, spacingPx, crop, imageRatio, stretch, id, compactView }, dispatch, children }) => {
        let timeoutId: number | null = null;
        return (
            <div>
                <HorizontalSpacer x={5} />
                <MctaPropertyContainer label="msg: component.gallery.propPanel.thumbnails.columns {Columns:}">
                    <MctaCombobox
                        useOptionsAsIs
                        searchable={false}
                        value={columns}
                        options={columnOptions}
                        onChange={({ value }) => dispatch({
                            type: actionTypes.GALLERY_PP_COLUMNS_CHANGED,
                            payload: value,
                            amendToSelf: true
                        })}
                    />
                </MctaPropertyContainer>
                <HorizontalSpacer x={13} />
                <MctaSlider
                    label="msg: component.gallery.propPanel.thumbnails.spacing {Spacing}"
                    min={0}
                    max={stretch ? maxSpacingInFullWidth : 250}
                    value={stretch ? Math.min(spacingPx, maxSpacingInFullWidth) : spacingPx}
                    sliderClassName={styles.gallerySpacingSlider}
                    valueLabel="px"
                    defaultValue={stretch ? Math.min(spacingPx, maxSpacingInFullWidth) : spacingPx}
                    onChange={spacing => {
                        if (timeoutId) {
                            clearTimeout(timeoutId);
                            timeoutId = null;
                        }
                        timeoutId = window.setTimeout(() => {
                            dispatch({
                                type: actionTypes.GALLERY_PP_SPACING_CHANGED,
                                payload: spacing
                            });
                        }, 50);
                    }}
                />
                {
                    imageRatio !== IMAGE_RATIO.ACTUAL_RATIO && [
                        <HorizontalSpacer key={0} x={13} />,
                        <MctaVerticalBar key={1} />,
                        <HorizontalSpacer key={2} x={13} />,
                        <MctaCheckbox
                            key={3}
                            isChecked={crop}
                            label="msg: component.gallery.propPanel.crop {Crop}"
                            onClick={() => dispatch({ type: actionTypes.GALLERY_IS_CROP_CHECKBOX_CLICKED })}
                        />
                    ]
                }
                <HorizontalSpacer x={13} />
                <MctaVerticalBar />
                <HorizontalSpacer x={13} />
                <MctaCheckbox
                    isChecked={compactView}
                    label="msg: common.gallery.compactView {Compact view}"
                    onClick={() => {
                        dispatch({ type: actionTypes.GALLERY_COMPACT_VIEW, payload: { id } });
                        if (stretch) {
                            dispatch({ type: actionTypes.GALLERY_FULL_WIDTH, payload: { id } });
                        }
                    }}
                />
                {
                    !compactView && [
                        <HorizontalSpacer key={0} x={13} />,
                        <MctaVerticalBar key={1} />,
                        <HorizontalSpacer key={2} x={13} />,
                        <MctaCheckbox
                            key={3}
                            isChecked={stretch}
                            label="msg: common.fullWidth {Full width}"
                            onClick={() => {
                                dispatch({ type: actionTypes.GALLERY_FULL_WIDTH, payload: { id } });
                                if (!stretch) {
                                    dispatch(propPanelNavigateToPageAC(FULL_WIDTH));
                                }
                            }}
                        />
                    ]
                }
                {children ? <HorizontalSpacer /> : <HorizontalSpacer x={5} />}
                {children}
            </div>
        );
    });
class MobileViewEditorV extends React.PureComponent<MobileMCTAProps> {
    bindMobileColumnChange: Function;
    constructor(props: MobileMCTAProps) {
        super(props);
        this.bindMobileColumnChange = this.mobileColumnChange.bind(this);
    }

    mobileColumnChange(count: number) {
        this.props.dispatch({
            type: actionTypes.GALLERY_MOBILE_COLUMN_CHANGE,
            payload: {
                count
            }
        });
    }
    render() {
        const
            { dispatch, selectedComponent: { mobileSettings: { columns, spacingPx } } } = this.props,
            oneColumn = 1,
            twoColumn = 2;
        return <div>
            <MctaColumnGrid
                count={oneColumn}
                selected={columns === oneColumn}
                onClick={this.bindMobileColumnChange}
            />
            <HorizontalSpacer x={5} />
            <MctaVerticalBar />
            <HorizontalSpacer x={5} />
            <MctaColumnGrid
                count={twoColumn}
                selected={columns === twoColumn}
                onClick={this.bindMobileColumnChange}
            />
            <HorizontalSpacer x={5} />
            <MctaVerticalBar />
            <HorizontalSpacer x={10} />
            <MctaSlider
                label="msg: component.gallery.propPanel.thumbnails.spacing {Spacing}"
                min={0}
                max={20}
                sliderClassName={styles.gallerySpacingSlider}
                value={spacingPx}
                valueLabel="px"
                onChange={spacing => dispatch({
                    type: actionTypes.GALLERY_MOBILE_SPACING_CHANGE,
                    payload: {
                        spacing
                    },
                    amendToSelf: true
                })}
                forMve
            />
            <HorizontalSpacer x={5} />
        </div>;
    }
}

export default {
    editView: EditView,
    mobileViewEditorV: (props: MobileMCTAProps) => <MobileViewEditorV {...props} />,
    editButtonText: 'msg: component.gallery.settings {Gallery settings}',
    ctaButtonText: 'msg: component.gallery.changeImages {Change images}',
    ctaOnClick: ({ dispatch }: CTAViewParams<GalleryComponent>) => dispatch({ type: actionTypes.GALLERY_MCTA_OPEN_SORTING_WINDOW_CLICKED })
};
