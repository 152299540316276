import React from "react";
import { connect } from "react-redux";
import StandardDialog from '../StandardDialog/index';
import { COMPONENT_CONFIGURATION_CANCELED } from "../../../../redux/modules/children/workspace/actionTypes";
import { StripTypes } from "../baseDialog/index";

const ComponentConfigurationDialog = props => {
    const {
        title,
        onSave,
        onSaveText = 'msg: common.save {Save}',
        hideCancel = false,
        children,
        disabled = false,
        autoFocus = true,
        noTopBorderForFooter = true,
        onCancel,
        stripType = StripTypes.INFO,
        iconClass,
        titleBoxClassName,
        footerV2 = false,
        footerContent,
        footerContentClassName,
    } = props;

    return (
        <StandardDialog
            title={title}
            autoFocus={autoFocus}
            disabled={disabled}
            mctaText={onSaveText}
            sctaText="msg: common.cancel {Cancel}"
            mctaHandler={onSave}
            sctaHandler={hideCancel ? undefined : onCancel}
            noTopBorderForFooter={noTopBorderForFooter}
            stripType={stripType}
            iconClass={iconClass}
            titleBoxClassName={titleBoxClassName}
            footerV2={footerV2}
            footerContents={footerContent}
            footerClassName={footerContentClassName}
        >
            {children}
        </StandardDialog>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onCancel: () => dispatch({ type: COMPONENT_CONFIGURATION_CANCELED })
});

export default connect(null, mapDispatchToProps)(ComponentConfigurationDialog);
