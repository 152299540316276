import { path as ramdaPathFn } from 'ramda';
import React from 'react';
import Review from '../components/Review/Review';
import { getFirstButtonStyleId } from "../../../Workspace/epics/stylesheets/selectors";
import { getGoogleReviewStyles } from "../fontStyleUtils";
import { THEME_BUTTON_CLASS, THEME_TEXT_CLASS, BACKGROUND_THEME_WHITE } from '../../../ThemeGlobalData/constants';
import * as path from "../../../../mappers/path";
import { generateGoogleStoreUrl, generateLeaveReviewUrl } from '../utils';

const View = (props) => {
    const { component, isMVEFocus, isWorkspace, themeSettingsData,
            globalStyles, stylesheetsIdToNameMap } = props,
        { buttonThemeSelected, headerText, storeData: { placeId }, showReviewButton, reviews, layoutType } = component,
        { autoColorMode } = themeSettingsData;

    const leaveReviewLink = generateLeaveReviewUrl(placeId),
        storeLink = generateGoogleStoreUrl(placeId),
        reviewButtonText = component.reviewButtonText,
        firstButtonStylesheetId = getFirstButtonStyleId(globalStyles),
        globalStyleClassName = autoColorMode ? stylesheetsIdToNameMap[firstButtonStylesheetId] :
            stylesheetsIdToNameMap[ramdaPathFn(path.styleButtonGlobalId, component)];

    const { headerStyles } = getGoogleReviewStyles({ globalStyles, });

    let classList = [globalStyleClassName];
    let headerClass = '';
    if (autoColorMode) {
        classList = [...classList, buttonThemeSelected, BACKGROUND_THEME_WHITE, THEME_BUTTON_CLASS];
        headerClass = `${THEME_TEXT_CLASS} White`;
    }

    const reviewButtonProps = {
        classList,
        leaveReviewLink,
        reviewButtonText,
        showReviewButton,
        storeLink
    };
    const headerProps = {
        headerStyles,
        headerText,
        headerClass
    };

    return (
        <Review
            component={component}
            reviews={reviews}
            type={layoutType}
            isWorkspace={isWorkspace || isMVEFocus}
            reviewButtonProps={reviewButtonProps}
            headerProps={headerProps}
        />
    );
};

export default View;
