import React, { Component } from 'react';
import cx from 'classnames';
import { connect } from "react-redux";
import styles from './TrialLanguageSelector.css';
import topBarLangStyles from '../../../../src/components/TopBar/view/TopBarUserProfile/TopBarUserProfile.css';
import { TopBarLanguages } from "../../../../src/components/TopBar/view/TopBarUserProfile/TopBarLanguages";
import TopBarDropdownMenuItem from '../../../../src/components/TopBar/view/TopBarDropdownMenuItem/index';
import type { TrialLanguageSelectorPropTypes } from '../types';

type State = {
    showLanguagesList: false | true,
};

class TrialLanguageSelector extends Component <TrialLanguageSelectorPropTypes, State> {
    state: State;
    containerRef: React.RefObject<HTMLDivElement>;

    constructor() {
        // @ts-ignore
        super();
        this.containerRef = React.createRef();
        this.state = {
            showLanguagesList: false,
        };

        this.languagesContainerOnClick = this.languagesContainerOnClick.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = event => {
        if (this.containerRef.current && !this.containerRef.current.contains(event.target)) {
            this.setState({
                showLanguagesList: false,
            });
        }
    };

    languagesContainerOnClick() {
        const { showLanguagesList } = this.state;
        this.setState({ showLanguagesList: !showLanguagesList });
    }

    render() {
        const {
            dispatch,
            languages,
        } = this.props;
        const { showLanguagesList } = this.state;
        return (
            <div className={styles.trialLanguageContainer} ref={this.containerRef}>
                <TopBarDropdownMenuItem
                    icon={topBarLangStyles.languageIcon}
                    className={cx({ [topBarLangStyles.trialLanguageItem]: this.state.showLanguagesList }, styles.languageContainer)}
                    extraChildren={(
                        <TopBarLanguages
                            className={styles.languagesPopupContainer}
                            state={languages}
                            dispatch={dispatch}
                        />
                    )}
                    onClick={this.languagesContainerOnClick}
                >
                    {languages.map[languages.current]}
                </TopBarDropdownMenuItem>
                <span
                    className={
                        cx(styles.langToggleIcon,
                            {
                                [styles.upArrowIcon]: !showLanguagesList,
                                [styles.downArrowIcon]: showLanguagesList
                            })
                    }
                    onClick={this.languagesContainerOnClick}
                />
            </div>
        );
    }
}

export default connect(null)(TrialLanguageSelector);
