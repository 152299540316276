import React from "react";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';

import { useIntl } from '../../../../../../../wbtgen/src/view/intl';

const useStyles = makeStyles(
    {
        description: {
            fontSize: "14px",
            lineHeight: "22px",
            letterSpacing: "0.25px",
        }
    },
    { name: "WidgetDescription" }
);

type WidgetDescriptionProps = {
    description: MsgJointInput;
};

export const WidgetDescription = ({ description }: WidgetDescriptionProps) => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Typography className={classes.description}>
            {intl.msgJoint(description)}
        </Typography>
    );
};
