import React from 'react';
import cx from 'classnames';
import { Flex } from '../../../../../view/reflexbox/index';
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import * as pagesIds from '../pagesIds';
import pagesTitles from '../pagesTitles';
import type { Props } from '../flowTypes';
import injectIntl from '../../../../../view/intl/injectIntl';
import { PropertiesButton } from '../../../../../view/common/Button/index';
import styles from "./styles.css";
import openDialogAC from '../../../../App/actionCreators/openDialog';
import connectFacebookFeedDialogId from '../../configurationDialog/configurationDialogId';
import Msg from "../../../../../view/intl/Msg";
import * as actionTypes from "../../actionTypes";
import { FacebookDisableInfoDialogId } from "../../../../SocialAccounts/Facebook/FacebookAppDisableModal/FacebookDisableInfo";
import disableFBComponents from '../../../../../utils/disableFBComponents';

const
    id = pagesIds.ACCOUNT,
    title = pagesTitles[id],
    view = injectIntl(({
        intl,
        socialAccounts,
        dispatch
    }: Props) => {
        const {
            facebookFeed: {
                facebookFeedConnected,
                facebookFeedAccountName,
                facebookFeedAccountId,
                managedFbPages,
                isFbPageMode
            }
        } = socialAccounts;

        const renderOnFacebookFeedNotConnected = () => {
            const
                addFacebookFeedMessage = "msg: component.facebookFeed.pp.account.addFacebookFeed {Add your Facebook feed to your site and share it with your visitors.}", // eslint-disable-line max-len
                connectAccount = "msg: component.facebookFeed.pp.account.connect {Connect account}",
                noAccountMessage = "msg: component.facebookFeed.pp.account.noAccount {Don\'t have an account?}",
                createAccountLink = 'https://www.facebook.com/r.php';
            return (
                <div>
                    <div className={cx(styles.positionItems, styles.facebookFeedIcon)} />
                    <VerticalSpacer />
                    <div className={cx(styles.positionItems, styles.accountPage, styles.addFacebookFeedMsg)}>
                        {intl.msgJoint(addFacebookFeedMessage)}
                    </div>
                    <VerticalSpacer />
                    <PropertiesButton
                        className={cx(styles.positionItems, styles.accountBtn, styles.connectAccountBtn)}
                        text={connectAccount}
                        onClick={() => dispatch(
                            disableFBComponents() ? openDialogAC(FacebookDisableInfoDialogId, { isFbFeed: true }) :
                                openDialogAC(connectFacebookFeedDialogId)
                        )}
                        // onClick={() => dispatch(openDialogAC(connectFacebookFeedDialogId))}
                    />
                    <VerticalSpacer y={14} />
                    <div className={cx(styles.positionItems, styles.accountPage, styles.noAccountMsg)}>
                        {intl.msgJoint(noAccountMessage)}
                    </div>
                    <div className={cx(styles.positionItems, styles.accountPage, styles.createAccount)}>
                        <a target="_blank" href={createAccountLink}>
                            <Msg k="component.facebookFeed.pp.account.createAccount">
                                Create one here
                            </Msg>
                        </a>
                    </div>
                </div>
            );
        };

        const userAccountModeConnected = () => {
            const
                disconnect = "msg: component.facebookFeed.pp.account.disconnect {Disconnect}",
                facebookFeedRootUrl = "https://www.facebook.com/",
                connectedAs = "msg: component.facebookFeed.pp.account.connectedAs {Connected as:}",
                openFacebookFeedAccountLink = `${facebookFeedRootUrl}${facebookFeedAccountId}/`;
            const disconnectInfo = "msg: component.facebookFeed.pp.account.disconnectInfo {Disconnect to log out or to sign in to another account.}";   // eslint-disable-line max-len

            return (
                <div>
                    <div className={cx(styles.positionItems, styles.accountPage, styles.connectedAs)}>{intl.msgJoint(connectedAs)}</div>
                    <VerticalSpacer y={7} />
                    <Flex align="center">
                        <div className={
                            cx(styles.positionItems, styles.accountPage, styles.connectedAccountName)
                        }
                        >
                            {facebookFeedAccountName}
                        </div>
                        <a
                            className={cx(styles.positionItems, styles.connectedAccountLink)}
                            target="_blank"
                            href={openFacebookFeedAccountLink}
                        >
                            {}
                        </a>
                    </Flex>
                    <VerticalSpacer />
                    <PropertiesButton
                        className={cx(styles.positionItems, styles.accountBtn, styles.disconnectBtn)}
                        text={disconnect}
                        onClick={() => dispatch({ type: actionTypes.FACEBOOK_FEED_GALLERY_PP_DISCONNECT_BTN_PRESSED })}
                    />
                    <VerticalSpacer y={14} />
                    <div className={cx(styles.positionItems, styles.accountPage, styles.noAccountMsg)}>{intl.msgJoint(disconnectInfo)}</div>
                    <VerticalSpacer />
                </div>
            );
        };

        const renderFbPage = (fbPage) => {
            return (
                <div className={styles.pageNameContainer}>
                    <Flex align="center">
                        <div className={cx(
                            styles.fbPageName,
                            {
                                [styles.disabledPageName]: !fbPage.isVisible,
                            }
                        )}
                        >
                            {fbPage.name}
                        </div>
                        <div
                            className={
                                cx(
                                    styles.fbPageActions,
                                    {
                                        [styles.fbPageHide]: !fbPage.isVisible,
                                        [styles.fbPageShow]: fbPage.isVisible,
                                    }
                                )
                            }
                            onClick={() => {
                                dispatch({
                                    type: actionTypes.TOGGLE_FB_PAGE_SHOW_HIDE_ACTION,
                                    payload: {
                                        pageId: fbPage.id,
                                        isPageVisible: !fbPage.isVisible
                                    }
                                });
                            }}
                        />
                    </Flex>
                </div>
            );
        };

        const renderPages = () => {
            return managedFbPages.map((fbPage) => {
                return renderFbPage(fbPage);
            });
        };

        const fbPageModeConnected = () => {
            const
                pageModeHeader = "msg: component.facebookFeed.pp.account.pageModeHeader {Choose which accounts to show on your site.}",
                disconnect = "msg: component.facebookFeed.pp.account.disconnectAllAccounts {Disconnect all accounts}",
                disconnectInfo = "msg: component.facebookFeed.pp.account.disconnectAllAccountsInfo {Disconnect to log out all of your accounts.}", // eslint-disable-line max-len
                connectedAccounts = "msg: component.facebookFeed.pp.account.connectedAccounts {Connected accounts:}";

            return (
                <div>
                    <div className={
                        cx(styles.positionItems, styles.accountPageAsFbPage, styles.connectedAsPage)
                    }
                    >
                        {intl.msgJoint(pageModeHeader)}
                    </div>
                    <VerticalSpacer y={30} />
                    <div
                        className={
                            cx(
                                styles.positionItems,
                                styles.accountPageAsFbPage,
                                styles.connectedAsPage,
                                styles.connectedAsPageSubHeader
                            )
                        }
                    >
                        {intl.msgJoint(connectedAccounts)}
                    </div>
                    <VerticalSpacer y={7} />
                    {renderPages()}
                    <VerticalSpacer />
                    <PropertiesButton
                        className={cx(styles.positionItems, styles.accountBtn, styles.disconnectBtnPage)}
                        text={disconnect}
                        onClick={() => dispatch({ type: actionTypes.FACEBOOK_FEED_GALLERY_PP_DISCONNECT_BTN_PRESSED })}
                    />
                    <VerticalSpacer y={14} />
                    <div className={cx(styles.positionItems, styles.accountPage, styles.noAccountMsg)}>{intl.msgJoint(disconnectInfo)}</div>
                    <VerticalSpacer />
                </div>
            );
        };

        const renderOnFacebookFeedConnected = () => {
            if (isFbPageMode) {
                return fbPageModeConnected();
            } else {
                return userAccountModeConnected();
            }
        };

        return (
            <Page>
                <VerticalSpacer />
                {
                    facebookFeedConnected &&
                    renderOnFacebookFeedConnected()
                }
                {
                    !facebookFeedConnected &&
                    renderOnFacebookFeedNotConnected()
                }
            </Page>
        );
    });

export { id, view, title };
