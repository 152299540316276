import componentTypesMap, {
    defaultComponentType,
    defaultOldComponentType,
    OldComponentTypes
} from "../../componentTypesMap";
import ComponentTypes from "../../../../src/view/oneweb/registry/ComponentTypes";
import { isSectionKind, isStripKind } from "../../../../src/components/oneweb/componentKinds";
import { fullWidthCmpMap } from "../../../../src/components/oneweb/isStretchComponentKind";

export function to(component: any): any {
    const { type, stretch } = component,
        kind = componentTypesMap[type] || defaultComponentType;
    if (stretch) {
        if (!isSectionKind(type) && !fullWidthCmpMap.hasOwnProperty(kind)) {
            return { kind: ComponentTypes.STRIP };
        }
    }

    return { kind };
}

export function back(component: any): any {
    const type = isStripKind(component.kind)
        ? OldComponentTypes.BLOCK
        : OldComponentTypes[component.kind] || defaultOldComponentType;

    return { type };
}
