import type { LinkChooserInterchangeType } from "../../../view/common/dialogs/LinkChooserDialog/flowTypes";
import { DataSite } from "../../../../dal/model/index";
import type { Link, TextLink } from "./flowTypes";
import { BROKEN_LINK } from "./constants";
import { LcTabName } from "../../../view/common/dialogs/LinkChooserDialog/constants";
import getLinkAbsoluteUrl from "../../../view/common/dialogs/LinkChooserDialog/utils/getLinkAbsoluteUrl";
import { encodeWebspaceUri } from "../../../../dal/utils/webspaceUriTransformers";

const brokenLink = {
    type: LcTabName.PAGE,
    value: BROKEN_LINK
};

const defaultLinkData = {
    link: brokenLink,
    openInNewWindow: false
};

export const getLinkUrlForText = (linkData: LinkChooserInterchangeType | null | undefined): TextLink => {
    let { link: { type, value }, openInNewWindow } = linkData || defaultLinkData;

    if (type === LcTabName.FILE && typeof value !== 'string') {
        value = `${value.basePath},${value.href}`;
    } else if (type === LcTabName.EMAIL) {
        let nextValue = value.selection;
        if (value.subject) {
            nextValue += `?subject=${encodeWebspaceUri(value.subject)}`;
        }
        value = nextValue;
    } else if (type === LcTabName.LOCATION) {
        let nextValue = value.selection;
        if (value.name) {
            nextValue += `##${encodeWebspaceUri(value.name)}`;
        }
        value = nextValue;
    } else if (type === LcTabName.SECTION_LINK) {
        value = `${value.pageId}:${value.sectionId}`;
    }

    return {
        href: type === LcTabName.URL ? value : `${type}:${value}`,
        target: openInNewWindow ? '_blank' : ''
    };
};

export const getLinkUrl = (linkData: LinkChooserInterchangeType | null | undefined, site: DataSite, previewBackupTime?: number): Link => {
    const { link, openInNewWindow } = linkData || defaultLinkData;
    return {
        href: getLinkAbsoluteUrl(link, site, previewBackupTime),
        target: openInNewWindow ? '_blank' : '',
        // @ts-ignore
        sectionid: link && link.value && link.value.sectionId
    };
};
