import { openDialog } from '../App/actionCreators/index';
import { WHATS_NEW_DIALOG_ID } from './dialog/dialogId';

export const
    TOP_BAR_WHATS_NEW_OPTION_CLICKED_ACTION = 'TOP_BAR_WHATS_NEW_OPTION_CLICKED_ACTION',
    topBarWhatsNewOptionClickedAction = () => ({
        type: TOP_BAR_WHATS_NEW_OPTION_CLICKED_ACTION,
    });

export const openWhatsNewDialogAction = () => openDialog(WHATS_NEW_DIALOG_ID);

export const
    CLOSE_WHATS_NEW_DIALOG_ACTION = 'CLOSE_WHATS_NEW_DIALOG_ACTION',
    closeWhatsNewDialogAction = () => ({
        type: CLOSE_WHATS_NEW_DIALOG_ACTION,
    });

export const
    SHOW_WHATS_NEW_TIP_ACTION = 'SHOW_WHATS_NEW_TIP_ACTION',
    showWhatsNewTipAction = () => ({ type: SHOW_WHATS_NEW_TIP_ACTION });

export const
    HIDE_WHATS_NEW_TIP_ACTION = 'HIDE_WHATS_NEW_TIP_ACTION',
    hideWhatsNewTipAction = () => ({ type: HIDE_WHATS_NEW_TIP_ACTION });
