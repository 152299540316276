import { SHOW_COLOR_PICKER } from "./actionTypes";
import {
    DEFAULT_COLOR_PICKER,
    THEME_COLOR_PICKER,
    THEME_BG_OVERRIDE_COLOR_PICKER,
    THEME_COLOR_OVERRIDE_COLOR_PICKER,
} from './constants';
import type { ShowColorPickerActionParams, ShowColorPickerParams } from './flowTypes';

export const
    showColorPickerAction = (props: ShowColorPickerActionParams): { type: 'SHOW_COLOR_PICKER', payload: ShowColorPickerParams } => {
        const {
            position,
            onChangeAction,
            additionalPayload,
        } = props;
        const commonShowColorPickerParams = {
            position,
            onChangeCompleteAction: onChangeAction,
            additionalPayload,
        };
        switch (props.colorPickerKind) {
            case THEME_COLOR_PICKER: {
                return {
                    type: SHOW_COLOR_PICKER,
                    payload: {
                        ...commonShowColorPickerParams,
                        colorPickerKind: THEME_COLOR_PICKER,
                        themeColor: props.themeColor,
                        themeColorsData: props.themeColorsData,
                        defaultThemeColor: props.defaultThemeColor,
                    },
                };
            }
            case THEME_BG_OVERRIDE_COLOR_PICKER: {
                return {
                    type: SHOW_COLOR_PICKER,
                    payload: {
                        ...commonShowColorPickerParams,
                        colorPickerKind: THEME_BG_OVERRIDE_COLOR_PICKER,
                        backgroundTheme: props.backgroundTheme,
                        themeColorsData: props.themeColorsData,
                        tooltipTop: props.tooltipTop,
                    },
                };
            }
            case THEME_COLOR_OVERRIDE_COLOR_PICKER: {
                return {
                    type: SHOW_COLOR_PICKER,
                    payload: {
                        ...commonShowColorPickerParams,
                        colorPickerKind: THEME_COLOR_OVERRIDE_COLOR_PICKER,
                        themeColor: props.themeColor,
                        themeColorsData: props.themeColorsData,
                    },
                };
            }
            default: {
                return {
                    type: SHOW_COLOR_PICKER,
                    payload: {
                        ...commonShowColorPickerParams,
                        colorPickerKind: DEFAULT_COLOR_PICKER,
                        color: props.color,
                        onTheFlyChange: props.onTheFlyChange,
                        onChangeCompleteDebounced: props.onChangeDebounced,
                        ...(
                            // @ts-ignore
                            props.suggestedColors
                                ? {
                                    // @ts-ignore
                                    suggestedColorsLabel: props.suggestedColorsLabel,
                                    // @ts-ignore
                                    suggestedColors: props.suggestedColors,
                                }
                                : {}
                        ),
                    }
                };
            }
        }
    };
