import React from "react";
import { Dialog, IconButton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import { closeDialogAction } from "../Dialog/actions";
import { CloseIcon } from "../../common/icons";

type HelpVideoDialogProps = {
    dispatch: Dispatch;
    dialogProps: {
        url: string;
    };
}

const useStyles = makeStyles(
    (theme) => ({
        paper: {
            overflow: "hidden",
            borderRadius: "8px",
            background: "transparent",
            userSelect: "none",
        },
        videoIframe: {
            width: "100%",
            borderRadius: "8px",
            height: "340px",
            [theme.breakpoints.up("sm")]: {
                height: "460px",
            },
            [theme.breakpoints.up("md")]: {
                height: "670px",
            },
        },
        closeButton: {
            position: "fixed",
            top: "48px",
            right: "24px",
            padding: "6px",
            backgroundColor: "transparent",
            [theme.breakpoints.up("sm")]: {
                right: "48px",
            },
        },
        closeIcon: {
            fill: theme.palette.custom.colorWhite,
        },
    }),
    { name: "HelpVideoDialog" }
);

export const HelpVideoDialog = ({ dispatch, dialogProps: { url } }: HelpVideoDialogProps) => {
    const classes = useStyles();

    const onClose = () => {
        dispatch(closeDialogAction());
    };

    return (
        <Dialog
            open
            fullWidth
            maxWidth="md"
            classes={{ paper: classes.paper }}
        >
            <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
                size="large"
            >
                <CloseIcon className={classes.closeIcon} />
            </IconButton>

            <iframe
                frameBorder="0"
                allowFullScreen
                allow="autoplay"
                src={url}
                className={classes.videoIframe}
            />
        </Dialog>
    );
};

export const HELP_VIDEO_DIALOG = "HELP_VIDEO_DIALOG";
