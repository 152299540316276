const
    UPLOADING_FILE = 'UPLOADING_FILE',
    OPTIMIZING_VIDEO = 'OPTIMIZING_VIDEO',
    UPLOAD_COMPLETE = 'UPLOAD_COMPLETE',
    UPLOAD_FAILED = 'UPLOAD_FAILED',
    MUTED_ON_AUTOPLAY = 'MUTED_ON_AUTOPLAY';

export {
    UPLOADING_FILE,
    OPTIMIZING_VIDEO,
    UPLOAD_COMPLETE,
    UPLOAD_FAILED,
    MUTED_ON_AUTOPLAY
};
