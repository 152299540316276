import * as R from 'ramda';
import {
    pathToEditModeComponentId,
    userInteractionPayloadPath,
    pathToTemplateSelectedTheme
} from "./paths";
import { getWrappedComponentIds } from "../../../../utils/htmlWriter/html/render/wrapper/wrapperNodeUtils";
import TextKind from "../../../oneweb/Text/kind";
import { componentsMapSelector } from "./selectorActionTypes";
import { arrayToTrueMap } from "../../../../utils/arrayToTrueMap";

import type { EpicState } from "./flowTypes";
import type { ComponentsMap } from "../../../../redux/modules/children/workspace/flowTypes";
import isStretchComponentKind from "../../../oneweb/isStretchComponentKind";
import type { StripComponent } from "../../../oneweb/Strip/flowTypes";
import { getComponentZIndex } from "../../zIndex";

export const
    templateSelectedThemeSelector = R.pipe(R.path(pathToTemplateSelectedTheme), R.defaultTo(null)),
    editModeComponentIdSelector = R.pipe(R.path(pathToEditModeComponentId), R.defaultTo(null)),
    editModeComponentSelector = (epicState: EpicState) => {
        const editModeComponentId = R.path(pathToEditModeComponentId, epicState);
        return componentsMapSelector(epicState)[editModeComponentId];
    },
    makeEditModeComponentSelectorByKind = (kind: string) => (epicState: EpicState) => {
        const editModeComponent = editModeComponentSelector(epicState);
        return (editModeComponent && editModeComponent.kind === kind) ? componentsMapSelector(epicState)[editModeComponent.id] : null;
    },
    selectedComponentsIdsSelector = ({ scope: { selectedComponentsIds } }: EpicState) => selectedComponentsIds,
    userInteractionPayloadSelector = R.path(userInteractionPayloadPath),
    userInteractionComponentsIdsPath = ['scope', 'userInteraction', 'payload', 'componentsIds'];

const
    getWrappedIds = R.reduce((a, component) => {
        return a.concat(getWrappedComponentIds(component.content));
    }, []),
    getSelectedTextComponents = (selectedComponentsIds, componentsMap) => R.props(selectedComponentsIds, componentsMap)
        .filter(({ kind }) => kind === TextKind);

export const
    getSelectedWrappedIdsInsideSelectedComponents = (
        selectedComponentsIds: string[],
        componentsMap: ComponentsMap
    ): string[] => getWrappedIds(getSelectedTextComponents(selectedComponentsIds, componentsMap)),
    getNotSelectedWrappedIdsInsideSelectedComponents = (
        selectedComponentsIds: string[],
        componentsMap: ComponentsMap
    ): string[] => {
        // Find components wrapped by any text components in componentsIds. Wrapped components should also move.
        const wrappedIds = getWrappedIds(getSelectedTextComponents(selectedComponentsIds, componentsMap));

        return R.difference(wrappedIds, selectedComponentsIds);
    },
    getSelectedComponentIdsAlongWithWrappedIds = (
        epicState: EpicState
    ): string[] => {
        const
            componentsMap = componentsMapSelector(epicState),
            selectedComponentsIds = selectedComponentsIdsSelector(epicState);

        const wrappedIds = getWrappedIds(getSelectedTextComponents(selectedComponentsIds, componentsMap));
        return R.difference(selectedComponentsIds, wrappedIds).concat(wrappedIds);
    },
    getComponentWrappedIds = (
        selectedComponentsIds: Array<string>,
        componentsMap: Object
    ): string[] => {
        const wrappedIds = getWrappedIds(getSelectedTextComponents(selectedComponentsIds, componentsMap));
        return R.difference(selectedComponentsIds, wrappedIds).concat(wrappedIds);
    },
    getComponentsIdsWithoutWrappedOnes =
        (componentsMap: ComponentsMap, componentsIds: Array<string>): Array<string> => {
            const
                wrappedComponentIdsMap = arrayToTrueMap(
                    getSelectedWrappedIdsInsideSelectedComponents(componentsIds, componentsMap)
                ),
                componentsIdsWithoutWrappedOnes = componentsIds
                    .filter(componentId => !wrappedComponentIdsMap[componentId]);

            return componentsIdsWithoutWrappedOnes;
        },
    getStripComponents = (componentsMap: ComponentsMap): Array<StripComponent> => {
        return R.pipe(
            R.values,
            R.filter((cmp) => isStretchComponentKind(cmp.kind)),
            R.sort((a, b) => {
                const value = a.top - b.top;
                if (value === 0) { return getComponentZIndex(a) - getComponentZIndex(b); }
                return value;
            })
        )(componentsMap);
    };

/* TODO WBTGEN-6434 move all selectors of components eval to selectors.js */
