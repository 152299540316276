import { WidgetsGoFundMeKind } from './kind';
import { componentMainActions } from './componentMainAction';
import { propertiesPanel } from './propertiesPanel/index';
import { reducer } from './reducer';
import { configurationDialogId } from './configurationDialog';
import { calcRenderProps } from './calcRenderProps';
import { GoFundMeWorkspace } from './view/workspace';
import { GoFundMeView } from './view/index';
import { getMVEHiddenElementData } from '../mobileEditorConfig';
import { WidgetsGoFundMeConfig } from '../constants';

const WidgetsGoFundMe = {
    kind: WidgetsGoFundMeKind,
    label: WidgetsGoFundMeConfig.title,
    shortcutIconName: 'widgetsGoFundMe',
    workspace: GoFundMeWorkspace,
    view: GoFundMeView,
    requireConfigurationOnDrop: () => true,
    configurationDialog: configurationDialogId,
    calcRenderProps,
    componentMainActions,
    propertiesPanel,
    reducer,
    mobileEditorConfig: {
        kind: WidgetsGoFundMeKind,
        calcProps: calcRenderProps,
        view: GoFundMeView,
        getHiddenElementData: getMVEHiddenElementData(WidgetsGoFundMeConfig),
    },
};

export { WidgetsGoFundMe };

