import {
    BACKGROUND_THEME_ACCENTED,
    BUTTON_THEME_PRIMARY,
    BUTTON_THEME_ALTERNATE,
    BUTTON_THEME_SECONDARY,
    BACKGROUND_THEME_BLACK, BACKGROUND_THEME_MAIN,
    BACKGROUND_THEME_WHITE
} from "../ThemeGlobalData/constants";
import {
    COMMON_OPTIONS,
    BUTTON_OPTIONS,
    TEXT_OPTIONS
} from './MctaAlignmentControls/constants';
import type { ThemeBackgroundType } from '../ThemeGlobalData/flowTypes';
import { SIZE_TYPES } from "../../constants";
import ButtonKind from "../oneweb/Button/kind";
import { SOCIAL_KIND } from "../oneweb/Social/kind";
import ImageKind from "../oneweb/Image/kind";
import ContactFormKind from "../oneweb/ContactForm/kind";
import TextKind from "../oneweb/Text/kind";
import TableKind from "../oneweb/Table/kind";
import { cmpTypes } from "../MobileViewEditor/constants";
import { TextLike } from "../oneweb/TextLike/constants";
import { OpeningHours } from "../oneweb/OpeningHours/constants";

export const
    DEFAULT_MCTA_COMBOBOX_MENUSTYLE = {
        fontSize: '13px'
    },
    MCTA_THEME_OPTIONS_BACKGROUND: Array<{ value: ThemeBackgroundType, label: string }> = [
        { value: BACKGROUND_THEME_BLACK, label: "Black" },
        { value: BACKGROUND_THEME_WHITE, label: "White" },
        { value: BACKGROUND_THEME_MAIN, label: "Primary" },
        { value: BACKGROUND_THEME_ACCENTED, label: "Accented" },
    ],
    MCTA_THEME_OPTIONS_BUTTON = [
        { value: BUTTON_THEME_PRIMARY, label: "Primary" },
        { value: BUTTON_THEME_SECONDARY, label: "Secondary" },
        { value: BUTTON_THEME_ALTERNATE, label: "Alternate" },
    ],
    MCTA_RESIZE_OPTIONS = {
        [SIZE_TYPES.SIZE_SMALL]: { width: 50, height: 50 },
        [SIZE_TYPES.SIZE_MEDIUM]: { width: 80, height: 80 },
        [SIZE_TYPES.SIZE_LARGE]: { width: 105, height: 105 }
    },
    MCTA_ALIGNMENT_ICON_HEIGHT = 38,
    iconForAlignmentTypeMap = {
        [ButtonKind]: BUTTON_OPTIONS.icon,
        [SOCIAL_KIND]: COMMON_OPTIONS.icon,
        [ImageKind]: COMMON_OPTIONS.icon,
        [ContactFormKind]: BUTTON_OPTIONS.icon,
        [TextKind]: TEXT_OPTIONS.icon,
        [TableKind]: TEXT_OPTIONS.icon,
        [cmpTypes.group]: COMMON_OPTIONS.icon,
        [TextLike]: COMMON_OPTIONS.icon,
        [OpeningHours]: COMMON_OPTIONS.icon,
    };

