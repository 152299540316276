import { parseDOM } from "../../../../../utils/parseDOM";
import { type GoFundMeComponent } from '../types';

export const parseGoFundMePostCode = (code: string): GoFundMeComponent => {
    let params = <GoFundMeComponent>{};

    const configs = [
        {
            nodeName: 'DIV',
            validator: node => {
                return (
                    node.classList.contains("gfm-embed")
                    && node.attributes['data-url']
                    && node.attributes['data-url'].nodeValue
                    && node.attributes['data-url'].nodeValue.startsWith("https://www.gofundme.com/")
                );
            },
            attributes: {
                'data-url': 'link'
            }
        },
        {
            nodeName: 'SCRIPT',
            validator: node => node.src === 'https://www.gofundme.com/static/js/embed.js',
        }
    ];

    params = parseDOM(configs, code);

    return params;
};
