export default {
    block: 'data-mobile-block',
    order: 'data-mobile-order',
    blockId: 'data-mobile-block-id'
};

export const
    BODY_BACKGROUND_CLASS_NAME = 'bodyBackground',
    DESKTOP_VIEW = 'desktopV',
    MOBILE_BACKGROUND_LINER_ID = 'mobileBackgroundLiner',
    CART_TYPE_OVERLAY = 'overlay',
    CART_TYPE_INSIDESHOP = 'insideShop';
