import Button from "../oneweb/Button/kind";
import Text from "../oneweb/Text/kind";
import Background from "../oneweb/Background/kind";
import HoverBox from "../oneweb/HoverBox/kind";
import Strip from "../oneweb/Strip/kind";
import Section from "../oneweb/Section/kind";
import ContactForm from "../oneweb/ContactForm/kind";
import Webshop from "../oneweb/WebShop/kind";
import Gallery from "../oneweb/Gallery/kind";
import Video from "../oneweb/Video/kind";
import Facebook from "../oneweb/Facebook/kind";
import ShareButtons from "../oneweb/ShareButtons/kind";
import Document from "../oneweb/Document/kind";
import Code from "../oneweb/Code/kind";
import ImageSlider from "../oneweb/ImageSlider/kind";
import Image from "../oneweb/Image/kind";
import Table from '../oneweb/Table/kind';
import Menu from '../oneweb/Menu/kind';
import { SOCIAL_KIND } from "../oneweb/Social/kind";
import { ALIGNMENT_TYPES } from "../../constants";
import { EMAIL_KIND } from '../oneweb/TextLike/Email/kind';
import { PHONE_KIND } from '../oneweb/TextLike/Phone/kind';
import { ADDRESS_KIND } from '../oneweb/TextLike/Address/kind';
import { SVG_KIND } from '../oneweb/Svg/kind';
import FeaturedProductsKind from '../oneweb/FeaturedProducts/kind';
import { OPENING_HOURS_KIND } from "../oneweb/OpeningHours/kind";
import type { ImageComponent } from "../oneweb/Image/flowTypes";
import ProductWidgetKind from '../oneweb/ProductWidget/kind';
import { WEBSHOP_PAYMENT_METHODS, WEBSHOP_POLICIES } from "../oneweb/componentKinds";

export const boxTop = 'boxTop',
    UndoRedoTimeGap = 1000,
    normalKindsMap = {
        [Button]: true,
        [Text]: true,
        [Code]: true,
        [ContactForm]: true,
        [Facebook]: true,
        [ShareButtons]: true,
        [Table]: true
    },
    mobileHiddenBackgroundKindsMap = {
        [Background]: true,
        [HoverBox]: true,
    },
    backgroundKindsMap = {
        [Background]: true,
        [HoverBox]: true,
        [Strip]: true,
        [Section]: true
    },
    topMarginDefaults = {
        //Topmargin is always applicable to below item
        small: 10,
        medium: 20,
        large: 35,
        firstChildSmall: 18,
        firstChildNormalCmp: 35
    },
    CmpsKindsWrappedAndNeedsMoreSpace = {
        [Video]: true,
        [ImageSlider]: true,
        [Facebook]: true,
        [ShareButtons]: true,
        [Document]: true,
        [Webshop]: true,
        [Code]: true,
        [ContactForm]: true,
        [Gallery]: true,
        [FeaturedProductsKind]: true,
        [ProductWidgetKind]: true,
    },
    CmpsKindsWrappedView = {
        [Facebook]: true,
        [ShareButtons]: true,
        [Document]: true,
        [ImageSlider]: true,
    },
    CmpsBorderGetSpecificStyles = {
        [Background]: true,
        [HoverBox]: true,
        [Image]: true,
        [Strip]: true,
        [Section]: true,
        [SOCIAL_KIND]: true,
        [EMAIL_KIND]: true,
        [PHONE_KIND]: true,
        [ADDRESS_KIND]: true,
        [Gallery]: true,
        [Table]: true,
        [SVG_KIND]: true,
        [OPENING_HOURS_KIND]: true
    },
    CmpsKindsHideIconNA = {
        [Webshop]: true,
        [Menu]: true,
        [FeaturedProductsKind]: true,
        [ProductWidgetKind]: true,
    },
    CmpsKindUnlockIconNA = {
        [WEBSHOP_PAYMENT_METHODS]: true,
        [WEBSHOP_POLICIES]: true,
    },
    CmpsKindsHideDisabledMsgs = {
        [Webshop]: {
            msgKey: "mve.hide.disabled.webshop",
            defaultMsg: "msg: mve.hide.disabled.webshop {The online shop can't be hidden in mobile view. To delete components, go to the desktop view.}" // eslint-disable-line max-len
        },
        [FeaturedProductsKind]: {
            msgKey: "mve.hide.disabled.webshop",
            defaultMsg: "msg: mve.hide.disabled.webshop {The online shop can't be hidden in mobile view. To delete components, go to the desktop view.}" // eslint-disable-line max-len
        },
        [ProductWidgetKind]: {
            msgKey: "mve.hide.disabled.webshop",
            defaultMsg: "msg: mve.hide.disabled.webshop {The online shop can't be hidden in mobile view. To delete components, go to the desktop view.}" // eslint-disable-line max-len
        },
    },
    mobileContentWidth = 339,
    cmpCopy = '_copy',
    cmpTypes = {
        group: 'Group',
        copy: 'Copy',
        component: 'Component'
    },
    mobileDownMask = "MobileDownMask",
    MobileDownLockedDefaults = {
        right: 16,
        top: 14
    },
    HiddenPanelMouseOverDelay = 500,
    mobileViewEditorContentAreaPadding = 18,
    marginLeftRightDraggableItem = {
        marginLeft: mobileViewEditorContentAreaPadding,
        marginRight: mobileViewEditorContentAreaPadding
    },
    GroupTypes = {
        images: Image,
        imageAndText: 'imageAndText',
        textAndText: 'textAndText'
    },
    ImageSpaceLimit = 60,
    ImageTextSpaceLimit = 25,
    TextAndTextSpaceLimit = 25,
    SmallTextMaxWidthLimit = 100,
    SmallTextMaxHeightLimit = 30,
    LargeTextMinWidthLimit = 100,
    LargeTextMinHeightLimit = 15,
    LargeTextMaxWidthLimit = 400,
    LargeTextMaxHeightLimit = 200,
    _imageAndTextGroupDefaultSettings = {
        align: ALIGNMENT_TYPES.ALIGN_LEFT,
        font: 0,
        scale: 100
    },
    getImgAndTextGroupDefaultSettings = () => ({ ..._imageAndTextGroupDefaultSettings }),
    _textAndTextGroupDefaultSettings = {
        align: ALIGNMENT_TYPES.ALIGN_LEFT,
        font: 0
    },
    IsContactParagraphKind = (kind: string) => {
        return [EMAIL_KIND, PHONE_KIND, ADDRESS_KIND].includes(kind);
    },
    getTextAndTextGroupDefaultSettings = () => ({ ..._textAndTextGroupDefaultSettings }),
    ImageSizeLimit = 75,
    isSmallImage = (cmp: ImageComponent): boolean => (cmp.height <= ImageSizeLimit && cmp.width <= ImageSizeLimit);
