import type { InputReducer, InputReducerFactoryArgs } from "../flowTypes";
import makeAllValidator from "./makeAllValidator";
import { lengthInputValidation } from "../commonValidations/index";
import inputDefaultState from "./inputDefaultState";

const DefaultValidations = [
    { validation: lengthInputValidation }
];

export default ({
    actionType,
    validations = DefaultValidations,
    defaultState = inputDefaultState,
    rejectInvalidValue = false,
}: InputReducerFactoryArgs): InputReducer => {
    const allValidator = makeAllValidator(validations);
    return (state, action) => {
        if (state === undefined) {
            return defaultState;
        }

        if (action.type === actionType) {
            const
                { isValid, errors } = allValidator(action.payload, action.params),
                nextValue = (!isValid && rejectInvalidValue) ? state.value : action.payload;

            return { value: nextValue, isValid, errors, isTouched: !!state };
        }

        return state;
    };
};
