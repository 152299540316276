/* @flow */

/**
 * Formats:
 * - noParamsMsg: "msg:\s*<key>\s*{<defaultMsg>}": String
 * - paramsMsg: [<noParamsMsg>, <params>]: Array[<noParamsMsg>, Object{string: string}]
 *
 * Examples:
 * - <Foo bar="msg: foo.bar {Hey, foo}" />
 * - <Foo bar={['msg: foo.bar {Hey, {greeting}}', {greeting: 'sepo'}]} />
 */
const parseMsgJoint = (input/*: any*/)/*: [string, string, Object]*/ => {
    function parseNoPrams(inputStr) {
        const str = inputStr.trim();
        if (str.indexOf('msg:') === 0) {
            const i = str.indexOf('{');
            if (i !== -1) {
                const
                    k = str.substring(4, i).trim(),
                    defaultMsg = str.substring(i + 1, str.lastIndexOf('}')).trim();
                return [k, defaultMsg];
            }
        }

        return inputError();
    }

    function inputError() {
        throw new Error(`Invalid injectIntl msg joint input given: ${JSON.stringify(input)}`);
    }

    let k = '',
        defaultMsg,
        params = {};

    if (typeof input === 'string') {
        [k, defaultMsg] = parseNoPrams(input);
    } else if (Array.isArray(input) && typeof input[0] === 'string' && typeof input[1] === 'object') {
        [k, defaultMsg] = parseNoPrams(input[0]);
        params = input[1];
    }

    // validate result
    if (!k || !defaultMsg) {
        inputError();
    }

    // sanitize
    k = k && k.trim();
    // $FlowFixMe
    defaultMsg = defaultMsg.trim();

    return [k, defaultMsg, params];
};

module.exports = {
    parseMsgJoint,
};
