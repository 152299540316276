export const IntlLangMap = {
    cs: 'cs-cz',
    da: 'da-dk',
    de: 'de-de',
    en: 'en-us',
    es: 'es-es',
    fi: 'fi-fi',
    fr: 'fr-fr',
    it: 'it-it',
    nb: 'nb-no',
    nl: 'nl-nl',
    pl: 'pl-pl',
    pt: 'pt-pt',
    sv: 'sv-se',
};
