import { $Values } from "utility-types";
import React from 'react';
import cx from 'classnames';
import { composeStylesTheme } from '@sepo27/react-redux-lib';
import styles from './LoadingIndicator.css';

export const InlineLoadingIndicatorSize = {
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large'
};

type Theme = {
    inlineContainer?: string,
    dot?: string,
};

type Props = {
    size?: $Values<typeof InlineLoadingIndicatorSize>,
    theme?: Theme,
};

export const InlineLoadingIndicatorCom = (
    { size = InlineLoadingIndicatorSize.SMALL, theme: propsTheme = {} }: Props
) => {
    const
        theme: Theme = composeStylesTheme(styles, propsTheme),
        dotClassName = cx(theme.dot, styles[size]);

    return (
        <span className={theme.inlineContainer}>
            {[1, 2, 3].map(key => (
                <span className={dotClassName} key={key} />
            ))}
        </span>
    );
};

