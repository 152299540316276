import { WidgetsBookingsKind } from './kind';
import { componentMainActions } from './componentMainAction';
import { propertiesPanel } from './propertiesPanel/index';
import { reducer } from './reducer';
import { configurationDialogId } from './configurationDialog';
import { calcRenderProps } from './calcRenderProps';
import { BookingsWorkspace } from './view/workspace';
import { BookingsView } from './view/index';
import { WidgetsBookingsConfig } from '../constants';
import { getMVEHiddenElementData } from '../mobileEditorConfig';
import { adjustmentHookConfig } from './adjustmentHookConfig';

const WidgetsBookings = {
    kind: WidgetsBookingsKind,
    label: WidgetsBookingsConfig.title,
    shortcutIconName: 'widgetsBookings',
    adjustmentHookConfig,
    workspace: BookingsWorkspace,
    view: BookingsView,
    requireConfigurationOnDrop: () => true,
    configurationDialog: configurationDialogId,
    calcRenderProps,
    componentMainActions,
    propertiesPanel,
    reducer,
    mobileEditorConfig: {
        kind: WidgetsBookingsKind,
        calcProps: calcRenderProps,
        view: BookingsView,
        getHiddenElementData: getMVEHiddenElementData(WidgetsBookingsConfig),
    },
};

export { WidgetsBookings };

