import React from 'react';
import cx from 'classnames';
import styles from './Button.css';
import Button from './Button';
import { injectIntl } from "../../intl/index";
import type { Intl } from "../../intl/index";
import type { MsgJointInput } from "../../intl/injectIntl";

type Props = {
    label: MsgJointInput;
    onClick: SimpleFunction;
    intl: Intl;
    className?: string;
    disabled?: boolean;
};

export default injectIntl(({ label, onClick, className, disabled, intl }: Props) => (
    <Button
        className={cx(styles.button, styles.buttonContinue, className, { [styles.disabled]: disabled })}
        onClick={onClick}
        disabled={disabled}
    >
        {intl.msgJoint(label)}
    </Button>
));
