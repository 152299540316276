import type { AnyComponentData } from "../../../../src/components/App/flowTypes";
import type { AnyComponent } from "../../../../src/redux/modules/children/workspace/flowTypes";
import DataPageTemplate from "../../../model/DataPageTemplate";
import type { TemplateComponent } from "../../../../src/components/oneweb/Template/flowTypes";

export function to(component: AnyComponentData|DataPageTemplate): any {
    const { bbox } = component,
        newWidth = (bbox.right - bbox.left) || 1,
        newHeight = (bbox.bottom - bbox.top) || 1;

    return {
        left: bbox.left,
        top: bbox.top,

        // Note: Components of 1x1 size get automatically deleted by "isValidComponent" in "wbtgen/dal/pageMapAdapter/index.js"
        width: newWidth > 0 ? newWidth : 1,
        height: newHeight > 0 ? newHeight : 1
    };
}

type compType = AnyComponent|TemplateComponent;

export function back(component: compType): any {
    const { left, top, width, height } = component;

    return {
        bbox: {
            left,
            top,
            right: left + width,
            bottom: top + height
        },
        width,
        height
    };
}
