const { getCountryFormat } = require('../../server/shared/address/getCountryFormat.js');

export const
    JS_PROTOCOL_PATTERN = /^javascript:.*$/,
    JS_VOID_PATTERN = /^javascript:\s*void\(0\);?$/,
    JS_VOID_VALUE = 'javascript:void(0)', // eslint-disable-line no-script-url
    ROOT_CONTAINER_ID = 'root',
    ROOT_VIEWS_CONTAINER_ID = 'rootViews',
    BLOCK_NATIVE_CONTEXT_MENU_CLASS = 'blockNativeContextMenu',
    SHOW_NATIVE_CONTEXT_MENU_CLASS = 'showNativeContextMenu',
    SIZE_TYPES = {
        SIZE_SMALL: 'small',
        SIZE_MEDIUM: 'medium',
        SIZE_LARGE: 'large'
    },
    ALIGNMENT_TYPES = {
        ALIGN_RIGHT: 'right',
        ALIGN_LEFT: 'left',
        ALIGN_CENTER: 'center',
        ALIGN_FULL: 'justify'
    },
    BROKEN_LINK = {
        hidden: false,
        id: "483DD5E6-CDAF-4EC4-9955-41D476E92115",
        linkId: null,
        name: JS_VOID_VALUE,
        target: "_self",
        type: "web.data.links.LinkExternal",
        url: JS_VOID_VALUE
    },
    GlobalVariableAddressNameKey = 'addressName',
    GlobalVariableAddressStreetAddressKey = 'addressStreetAddress',
    GlobalVariableAddressZipKey = 'addressZip',
    GlobalVariableAddressCityKey = 'addressCity',
    GlobalVariableAddressAreaKey = 'addressArea',
    GlobalVariableAddressCountryCodeKey = 'addressCountryCode',
    GlobalVariableAddressUrlKey = 'addressUrl',
    GlobalVariableContactEmailKey = 'contactEmail',
    GlobalVariablePhoneNumberKey = 'phoneNumber';

export const isAddressSet = ({ addressStreetAddress, addressCity, addressZip, addressArea, addressCountryCode }: {
    addressStreetAddress: string,
    addressCity: string,
    addressZip: string,
    addressArea: string | null,
    addressCountryCode: string,
}) => {
    return (
        !!addressStreetAddress
        || !!addressCity
        || !!addressZip
        || (
            !!addressArea &&
            ['CountryFormat3', 'CountryFormat4', 'CountryFormat5'].includes(getCountryFormat(addressCountryCode))
        )
    );
};
