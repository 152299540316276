/* eslint-disable max-len */

import React from "react";
import WarningDialog from "../../../common/dialogs/WarningDialog/index";
import { StripTypes } from '../../../common/dialogs/baseDialog/index';
import getCenteredDialogConfig from "../../../../components/DialogManager/getCenteredDialogConfig";
import { injectIntl } from "../../../intl/index";
import {
    CMP_PANEL_DELETE_DIALOG_DELETE_PRESSED,
    CMP_PANEL_DELETE_DIALOG_CANCEL_PRESSED
} from "../../../../components/Workspace/epics/componentPanelActions/actionTypes";

const deleteSectionDialogTitle = "msg: section.deleteSectionTitle {Delete section?}";
const deleteSectionDialogDescription = "msg: section.deleteSectionDescription {Are you sure you want to delete the section from your site?}";

const _dialog = injectIntl(({ dispatch, intl }: { dispatch: Dispatch, intl: Intl }) => {
    return (
        <WarningDialog
            title={intl.msgJoint(deleteSectionDialogTitle)}
            mctaText="msg: common.delete.yes {Yes, delete}"
            mctaHandler={() => dispatch({ type: CMP_PANEL_DELETE_DIALOG_DELETE_PRESSED })}
            sctaHandler={() => dispatch({ type: CMP_PANEL_DELETE_DIALOG_CANCEL_PRESSED })}
            stripType={StripTypes.NONE}
            noTopBorderForFooter
            primaryBtnRed
        >
            <div style={{ whiteSpace: 'pre-line', fontSize: '13px' }}>
                {intl.msgJoint(deleteSectionDialogDescription)}
            </div>
        </WarningDialog>
    );
});

const CmpPanelDeleteConfirmationDialog = getCenteredDialogConfig({
    width: 470,
    height: 240,
    component: _dialog,
});

export {
    CmpPanelDeleteConfirmationDialog
};

