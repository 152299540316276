import React from "react";
import cx from 'classnames';
import btnStyles from "../view/Button.css";
import { THEME_BUTTON_CLASS } from "../../../ThemeGlobalData/constants";

const themeColoroptionRenderer = (
    value: string,
    label: string,
    selectedParentTheme: string,
    btnName: string
) => (
    <div
        className={
            cx(
                THEME_BUTTON_CLASS,
                value,
                selectedParentTheme,
                btnName,
                `${btnName}-ddo`,
                'ddo'
            )
        }
    >
        <div className={btnStyles.textContainer}>{label}</div>
    </div>
);

export { themeColoroptionRenderer };
