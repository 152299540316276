export const
    DEFAULT_WIDTH = 500,
    TRACK_DEFAULT_HEIGHT = 166,
    PLAYLIST_DEFAULT_HEIGHT = 470,
    EmbedTypes = ['soundcloud', 'profile'],
    defaultState = {
        link: "",
        color: "ff5500",
        autoplay: false,
        mobileHide: false,
    },
    SoundcloudSrcLink = "https://w.soundcloud.com/player/?url=",
    DATA_TEST_ID = 'soundcloud-widget',
    Default = 'ff5500',
    Light = 'bdbdbd',
    Dark = '424242',
    ColorOptions = {
        [Default]: 'msg: widgets.soundcloud.pp.color.default {Default}',
        [Light]: 'msg: widgets.soundcloud.pp.color.light {Light}',
        [Dark]: 'msg: widgets.soundcloud.pp.color.dark {Dark}',
    };

export enum PostType {
    TRACK = 'track',
    PLAYLIST = 'playlist',
}

export enum Keyword {
    SETS = 'sets',
    PLAYLISTS = 'playlists',
}
