/* eslint-disable max-len */

import React, { useState } from 'react';
import { Intl, Msg, injectIntl } from '../../../view/intl/index';
import ComboBox from "../../../view/common/Combobox/index";
import getCenteredDialogConfig from "../../DialogManager/getCenteredDialogConfig";
import { getCenterPositionForDialog } from "../../DialogManager/utility";
import ComponentConfigurationDialog from '../../../view/common/dialogs/ComponentConfigurationDialog/index';
import { CODE_SET_CODE_CONTENT } from "./actionTypes";
import CodeEditor from "./CodeEditor";
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import CodeComponentLocationTypes from './locationTypes';
import { getOptions } from './propertiesPanel/pages/placement';
import styles from './view/Code.css';
import type { Dimensions } from "../../../redux/modules/children/workspace/flowTypes";

type State = {
    code: string
};

type Props = {
    intl: Intl;
    dispatch: Dispatch;
    location: string;
    dimensions: Dimensions;
    replace: boolean;
    onExpandedCodeEditorAdd?: Function;
} & State;

const DialogHeaderHeightAndFooterHeight = 207;

const CodeEditorDialog = injectIntl((props: Props) => {
    const { replace, dimensions, dispatch, onExpandedCodeEditorAdd } = props;
    const [code, setCode] = useState(props.code || "");
    const [location, setLocation] = useState(props.location || CodeComponentLocationTypes.Body);

    return (
        <ComponentConfigurationDialog
            title="msg: component.code.embedCode {Embed code}"
            onSave={() => (onExpandedCodeEditorAdd
                ? onExpandedCodeEditorAdd(code, replace, location)
                : dispatch({ type: CODE_SET_CODE_CONTENT, payload: { code, replace, location } }))}
            disabled={code.length === 0}
            noTopBorderForFooter={false}
            onSaveText={onExpandedCodeEditorAdd && 'msg: common.add {Add}'}
        >
            <Msg k="component.code.dialog.placement">Placement of code</Msg>
            <VerticalSpacer y={10} />
            <ComboBox
                searchable={false}
                className={styles.placementDD}
                value={location}
                options={getOptions(props.intl)}
                onChange={({ value }) => setLocation(value)}
            />
            <VerticalSpacer y={10} />
            <Msg k="component.code.insertCode">
                Insert any HTML code including JavaScript.
            </Msg>
            <VerticalSpacer />
            <CodeEditor
                height={dimensions.height - DialogHeaderHeightAndFooterHeight}
                code={code}
                onChange={setCode}
            />
        </ComponentConfigurationDialog>
    );
});

const
    getDialogDimensions = (browserWidth, browserHeight) => ({
        width: 0.8 * browserWidth,
        height: Math.max(0.8 * browserHeight, 254)
    });

export const
    dialogId = 'CodeEditorDialodId',
    dialog = {
        ...getCenteredDialogConfig(800, 600, CodeEditorDialog),
        confFactory: ({ browserWidth, browserHeight }) => {
            const { width, height } = getDialogDimensions(browserWidth, browserHeight);
            return {
                position: getCenterPositionForDialog(width, height, browserWidth, browserHeight),
                modal: true,
                dimensions: { width, height }
            };
        },
        updateOnBrowserDimensionsChanged: (config, { width, height }) => {
            return {
                ...config,
                dimensions: getDialogDimensions(width, height)
            };
        }
    };

export default dialog;
