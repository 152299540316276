import { getByPath } from "./ramdaEx";

export type PathSelectorPath = string | Array<string | number>;

export const pathSelector = (path: PathSelectorPath, defaultValue: any = undefined) => (state: AnyValue): AnyValue =>
    getByPath(
        Array.isArray(path) ? path : path.split('.'),
        state,
        defaultValue,
    );
