import { $Call } from 'utility-types';
import {
    TOGGLE_OPENING_HOURS_FOR_THE_DAY_ACTION,
    UPDATE_FROM_OPENING_HOURS_ACTION,
    UPDATE_TO_OPENING_HOURS_ACTION,
    toggleOpeningHoursForTheDayAction,
    updateFromOpeningHoursAction,
    updateToOpeningHoursAction,
} from './actions';
import {
    OPEN_24_HOURS_VALUE,
    INVALID_OPENING_HOURS_VALUE,
    DEFAULT_FROM_TIME,
    DEFAULT_TO_TIME,
} from '../../../oneweb/OpeningHours/constants';
import { getNextDay, getPrevDay } from '../../../oneweb/OpeningHours/utils';
import type {
    DayOfTheWeek,
    SiteSettingsOpeningHoursMap,
    SiteSettingsOpeningHoursMapValue,
} from '../../../oneweb/OpeningHours/flowTypes';

const updateOpeningHoursMap = (
    openingHoursMap: SiteSettingsOpeningHoursMap,
    openingHoursForTheDay: SiteSettingsOpeningHoursMapValue,
    updatedDay: DayOfTheWeek
) => {
    const updatedOpeningHours: SiteSettingsOpeningHoursMap = { ...openingHoursMap, [updatedDay]: openingHoursForTheDay };

    const openingHoursForThePreviousDay = openingHoursMap[getPrevDay(updatedDay)];
    if (
        openingHoursForThePreviousDay
        && openingHoursForThePreviousDay.from > openingHoursForThePreviousDay.to
        && openingHoursForThePreviousDay.to >= openingHoursForTheDay.from
        && openingHoursForTheDay.from !== 0
    ) {
        openingHoursForThePreviousDay.to = INVALID_OPENING_HOURS_VALUE;
    }

    const openingHoursForTheNextDay = openingHoursMap[getNextDay(updatedDay)];
    if (
        openingHoursForTheNextDay
        && openingHoursForTheDay.from > openingHoursForTheDay.to
        && openingHoursForTheDay.to >= openingHoursForTheNextDay.from
        && openingHoursForTheNextDay.from !== 0
    ) {
        openingHoursForTheNextDay.from = INVALID_OPENING_HOURS_VALUE;
    }

    return updatedOpeningHours;
};

export const reducer = (
    state: SiteSettingsOpeningHoursMap,
    action: $Call<typeof toggleOpeningHoursForTheDayAction>
        | $Call<typeof updateFromOpeningHoursAction>
        | $Call<typeof updateToOpeningHoursAction>
) => {
    let updatedState = state;
    switch (action.type) {
        case TOGGLE_OPENING_HOURS_FOR_THE_DAY_ACTION: {
            const { day } = action.payload;
            let prevDay;

            if ('prevDay' in action.payload) {
                prevDay = action.payload.prevDay;
            }

            if (state[day]) {
                updatedState = updateOpeningHoursMap(state, { ...state[day], isSet: !state[day].isSet }, day);
            } else if (state[prevDay] && state[prevDay].isSet) {
                updatedState = updateOpeningHoursMap(state, { ...state[prevDay], isSet: true }, day);
            } else {
                updatedState = updateOpeningHoursMap(state, { from: DEFAULT_FROM_TIME, to: DEFAULT_TO_TIME, isSet: true }, day);
            }
            break;
        }
        case UPDATE_FROM_OPENING_HOURS_ACTION: {
            const { day } = action.payload;
            let time,
                to;

            if ('time' in action.payload) {
                time = action.payload.time;
            }

            if ('openingHoursForTheDay' in action.payload) {
                to = action.payload.openingHoursForTheDay.to;
            }

            if (time === OPEN_24_HOURS_VALUE) {
                updatedState = updateOpeningHoursMap(state, { from: 0, to: 0, isSet: true }, day);
            } else if (time === to) {
                updatedState = updateOpeningHoursMap(state, { from: time, to: INVALID_OPENING_HOURS_VALUE, isSet: true }, day);
            } else {
                updatedState = updateOpeningHoursMap(state, { from: time, to, isSet: true }, day);
            }
            break;
        }
        case UPDATE_TO_OPENING_HOURS_ACTION: {
            const { day } = action.payload;
            let time,
                from;

            if ('time' in action.payload) {
                time = action.payload.time;
            }

            if ('openingHoursForTheDay' in action.payload) {
                from = action.payload.openingHoursForTheDay.from;
            }

            if (time === OPEN_24_HOURS_VALUE) {
                updatedState = updateOpeningHoursMap(state, { from: 0, to: 0, isSet: true }, day);
            } else if (time === from) {
                updatedState = updateOpeningHoursMap(state, { from: INVALID_OPENING_HOURS_VALUE, to: time, isSet: true }, day);
            } else {
                updatedState = updateOpeningHoursMap(state, { from, to: time, isSet: true }, day);
            }
            break;
        }
        default: break;
    }
    return updatedState;
};
