import * as editorCommands from "./editorUtils/editorCommands";
import { TINY_MCE_TOGGLE_ORDERED_LIST, TINY_MCE_TOGGLE_UNORDERED_LIST } from "./actionTypes";

export const andChar = '&';
export const LIST = {
    ORDERED: {
        editorCmd: editorCommands.ORDERED_LIST,
        listStyle: { 'list-style-type': 'decimal' },
        propToToggle: 'orderedList',
        triggerActionType: TINY_MCE_TOGGLE_ORDERED_LIST,
    },
    UNORDERED: {
        editorCmd: editorCommands.UNORDERED_LIST,
        listStyle: { 'list-style-type': 'disc' },
        propToToggle: 'unorderedList',
        triggerActionType: TINY_MCE_TOGGLE_UNORDERED_LIST,
    },
};
