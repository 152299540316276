import React from 'react';
import { injectIntl } from '../../../view/intl/index';
import { Dialog, DialogBody } from '../../../view/common/dialogs/baseDialog/index';
import getCenteredDialogConfig from '../../DialogManager/getCenteredDialogConfig';
import styles from './ChatWidgetTab.css';
import { StripTypes } from "../../../view/common/dialogs/baseDialog/Dialog";
import { closeDialog } from "../../App/actionCreators/index";
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import Button from "../../../view/common/Button/Button";
import { disconnectFacebookChatAction } from "../../SocialAccounts/Facebook/FacebookChat/actions";
import * as actionTypes from "./actionTypes";

const
    width = 600,
    height = 320,
    HEADER = 'msg: chatWidget.chat.disconnectDialog.header { Turning Messenger chat off }',
    // eslint-disable-next-line max-len
    MSG = 'msg: chatWidget.chat.disconnectDialog.msg {If you turn Messenger chat off  you’ll need to re-authorise to add it back to your site again in the future. Remember to publish to make these changes live.}',

    ChatWidgetDisconnectDialog = injectIntl(({ intl, dispatch }) => {
        const
            onClose = () => dispatch(closeDialog());

        return (
            <Dialog
                onClose={onClose}
                stripType={StripTypes.NONE}
                className={styles.chatWidgetDisconnectDialog}
                closeBtnClassName={styles.chatWidgetDisconnectDialogCloseButton}
            >
                <DialogBody className={styles.chatWidgetDisconnectDialogContainer}>
                    <div className={styles.chatWidgetDisconnectDialogHeader}>
                        {intl.msgJoint(HEADER)}
                    </div>
                    <VerticalSpacer y={30} />
                    <div className={styles.chatWidgetDisconnectDialogMsg}>
                        {intl.msgJoint(MSG)}
                    </div>
                    <VerticalSpacer y={30} />
                    <div className={styles.chatWidgetDisconnectDialogBtnContainer}>
                        <div
                            className={styles.chatWidgetDisconnectDialogCancelBtn}
                            onClick={() => {
                                dispatch(closeDialog());
                            }}
                        >
                            {intl.msgJoint("msg: common.cancel {Cancel}")}
                        </div>
                        <Button
                            className={styles.chatWidgetDisconnectDialogOkBtn}
                            onClick={() => {
                                dispatch(disconnectFacebookChatAction());
                                dispatch({ type: actionTypes.ENABLE_CHAT_WIDGET_TOGGLE });
                                dispatch(closeDialog());
                            }}
                        >
                            {intl.msgJoint("msg: common.Ok {Ok}")}
                        </Button>
                    </div>

                </DialogBody>
            </Dialog>
        );
    }),
    config = getCenteredDialogConfig({
        width,
        height,
        component: ChatWidgetDisconnectDialog,
        dialogBackgroundClassName: styles.backgroundClassName
    });

export default config;
