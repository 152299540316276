/* global $ */

import React from 'react';
import Measure from "react-measure";
import { connect } from 'react-redux';
import cx from 'classnames';
import type { AppState } from "../../../redux/modules/flowTypes";
import mobileViewReorderValueActionType from '../epics/reorder/valueActionType';
import MCTAValueActionType from '../epics/componentMainActions/valueActionType';
import * as styles from './MobileViewEditor.css';
import Editor from './Editor';
import MCTAView from "./componentMainActions";
import * as Actions from "../actionTypes";
import * as Constants from "../constants";
import { MveHeader } from "../header/MveHeader/MveHeader";
import currentPageNameVAT from '../../App/epics/currentPageName/valueActionType';
import { valueActionType as siteDataValueActionType } from "../../App/epics/siteData/valueActionType";
import { makeEpicStateSelector } from "../../../epics/makeEpic";
import templateValueActionType from '../../oneweb/Template/epics/template/valueActionType';
import { MobileOptions } from "../../Preview/View/Mobile/index";
import { clickedToggleMobileOptionAC } from "../../Preview/View/actionCreators";
import MobileEditorActions from './MobileEditorActions';
import {
    deleteKeyPressedAC,
    unFocusMveWorkspaceAC
} from "../actionCreators";
import mobileEditorMobileDownVAT from "../epics/splitData/valueActionType";
import hoveringAndSelectionVAT from "../epics/hoveringAndSelection/valueActionType";
import { Msg } from '../../../view/intl/index';
import Icons from "../../../view/Icons/index";
import type { EditorsContainerProps } from "../flowTypes";
import * as StatusTypes from "../../Workspace/epics/status/types";
import { LoadingIndicator } from "../../../view/common/LoadingIndicator/index";
import { MobileEditorReorderTip } from "../../Tooltip/ids";
import { getPosition, isRightClick } from "../../../utils/mouse";
import { MobileViewOrientations } from "../../Preview/View/Mobile/constants";
import getBrowserScrollbarWidth from '../../../redux/modules/getBrowserScrollbarWidth';
import { MveBackground } from "./MveBackground";
import type { ComponentMainActionsConfig } from '../../../view/Workspace/Decorations/ComponentMainActions/flowTypes';
import * as registry from '../../../view/oneweb/registry/index';
import { BLOCK_NATIVE_CONTEXT_MENU_CLASS } from '../../../constants';
import type { ComponentsMap } from "../../../redux/modules/children/workspace/flowTypes";
import { HiddenComponents } from "../hiddenComponents/view/hiddenComponents";
import hiddenComponents from "../epics/hiddenComponents/valueActionType";
import mobileLock from "../epics/mobileLock/valueActionType";
import componentExtensions from "../epics/componentExtension/valueActionType";
import { mobileMenuAppSel } from '../../Mobile/header/menu/selectors';
import { dispatchForwardToSelectedComponent } from '../../../redux/forwardTo';
import { getLatestLockedCmpId } from '../calcLockToBottomEnablement';
import getCmpTypeById from "../getCmpTypeById";
import GroupsMCTA from "./Groups/MCTA/index";
import { siteSettingsValueActionType } from "../../App/epics/siteSettings/valueActionType";
import { globalVariablesEpic } from '../../App/epics/globalVariablesEpic';
import { getGroupTypeById } from "../util";
import {
    getControlsDependenciesForSelectedComponentEpicStateFromAppState
} from '../../Workspace/epics/controlsDependenciesForSelectedComponent/index';
import {
    selectedComponentIsInsideHeaderOrFooterVAT,
} from '../../Workspace/epics/selectedComponentIsInsideHeaderOrFooterEpic/valueActionType';
import isStretchComponentKind from "../../oneweb/isStretchComponentKind";
import { GET_PARENT_THEME_MAP_VAT } from "../../ThemeGlobalData/epics/getParentThemeMapVAT";
import { getThemeColorsFromStylesheet } from "../../Workspace/epics/stylesheets/selectors";
import AUTO_COLOR_LEFT_PANEL_EPIC_VALUE from '../../AutoColorLeftPanel/epic/valueActionType';
import { BACKGROUND } from "../../oneweb/componentKinds";
import { getAssetSize } from "../../../getters/backgroundGetters";
import * as mp from "../../../mappers/path";
import webShopMHFValueActionType from "../../oneweb/WebShopMHF/epic/valueActionType";

const getCoordinates = (node, parentNode) => {
    if (!node || !parentNode) return null;

    const nodeBoundingRect = node.getBoundingClientRect();
    // const editorContainerBoundingRect = editorContainerRef.getBoundingClientRect();

    return {
        left: nodeBoundingRect.left,
        top: nodeBoundingRect.top,
        width: nodeBoundingRect.width,
        height: nodeBoundingRect.height,
        offsetLeft: nodeBoundingRect.left - parentNode.getBoundingClientRect().left // this will be relative to mobile view port means mobile view editor container
    };
};

const getCmpId = (node) => {
    const id = node.id || $(node).parents('div[data-id]:first').data('id');
    return id || null;
};

const getElementDom = (id) => {
    if (!id) return null;
    return $('.' + styles.draggableItem + ' div[data-id="' + id + '"]')[0];
};

const getDraggableElementRect = (id) => {
    const elt = getElementDom(id);
    return elt && elt.getBoundingClientRect();
};

const getElementReorderArrowsPayload = (elemRect) => {
    return { compTop: elemRect.top, compHeight: elemRect.bottom - elemRect.top };
};

const isPageLoading = (status) => {
    switch (status) {
        case StatusTypes.UNINITIALIZED:
        case StatusTypes.LOADING:
        case StatusTypes.FONTS_LOADING_IN_PROGRESS:
        case StatusTypes.PREPARING:
            return true;
        case StatusTypes.FONTS_LOADING_FAILED:
        case StatusTypes.READY:
            return false;
        default:
            throw new Error(`unknown workspace status: ${status}`);
    }
};
const isCmpGroupType = (cmpId = '') => getCmpTypeById(cmpId) === Constants.cmpTypes.group;
const getMCTAView = (cmpId: null | undefined | string, cmpsMap: ComponentsMap): any => {
    if (!cmpId) return null;

    const component = cmpsMap[cmpId];
    if (component) {
        const kind = component.kind;
        const componentMainActionsConfig: ComponentMainActionsConfig = registry.getComponentMainActionsConfig(kind);
        const assetSize = getAssetSize(mp.styleBackground, component);
        if ((kind === BACKGROUND || isStretchComponentKind(kind)) && !assetSize) {
            return null;
        }
        return componentMainActionsConfig.mobileViewEditorV;
    }
    const isGroup = isCmpGroupType(cmpId);
    if (isGroup) {
        const groupType = getGroupTypeById(cmpId);
        return groupType ? GroupsMCTA[groupType] : null;
    }
    return null;
};

type EditorContainerState = {
    editorContainerWidth: number,
    scrollBarWidth: number
};

class MobileViewEditor extends React.Component<EditorsContainerProps, EditorContainerState> {
    contentContainerRef!: HTMLDivElement | null;
    editorContentRef: React.RefObject<HTMLDivElement>;
    editorContainerRef: React.RefObject<HTMLDivElement>;
    mobileDownEditorContainerRef!: HTMLDivElement | null;

    constructor(props) {
        super(props);
        this.state = {
            editorContainerWidth: 0,
            scrollBarWidth: getBrowserScrollbarWidth()
        };
        this.editorContentRef = React.createRef();
        this.editorContainerRef = React.createRef();
    }

    getSelectionData(cmpId: string) {
        const { componentsMap, mobileView: { groupsForView } } = this.props;
        return isCmpGroupType(cmpId) ?
            { selectedItems: groupsForView[cmpId].map(id => componentsMap[id]) } :
            { selectedComponent: componentsMap[cmpId] };
    }

    selectCmp = (cmpId) => {
        const { dispatch, mobileData } = this.props;
        if (cmpId) {
            const elemRect = getDraggableElementRect(cmpId);
            if (elemRect) {
                dispatch({
                    type: Actions.MOBILE_EDITOR_COMPONENT_SELECT,
                    payload: {
                        selectedCmpRect: getElementReorderArrowsPayload(elemRect),
                        cmpId,
                        mobileData,
                        componentRect: getCoordinates(getElementDom(cmpId), this.editorContentRef.current)
                    }
                });
            }
        }
    }

    updateNewScrollTop = (isAnimate?: boolean, selectCmpId?: any) => {
        const { hoveringAndSelection: { newScrollTopValue } } = this.props;
        const ele = $('.' + styles.content)[0];
        if (ele && (ele.scrollTop !== newScrollTopValue)) {
            if (isAnimate) {
                $(ele).animate({
                    scrollTop: newScrollTopValue
                }, () => this.selectCmp(selectCmpId));
            } else {
                ele.scrollTop = newScrollTopValue;
                this.selectCmp(selectCmpId);
            }
        } else {
            this.selectCmp(selectCmpId);
        }
        this.props.dispatch({
            type: Actions.MOBILE_EDITOR_UPDATED_SCROLL_TOP,
            payload: { newScrollTopValue, updateScrollTop: false }
        });
    }

    onKeyDownHandler = (e) => {
        const { hoveringAndSelection: { selectedCmpId }, dispatch } = this.props;
        //supports backspace and delete keycodes
        if (selectedCmpId && (e.keyCode === 46 || e.keyCode === 8)) {
            dispatch(deleteKeyPressedAC());
        }
    }

    onToggleMobileVersion = () => {
        this.props.dispatch(clickedToggleMobileOptionAC());
    }

    componentDidUpdate(prevProps: EditorsContainerProps) {
        const { dispatch,
                hiddenComponents: { latestUnhiddenCmpId, hoverUnhiddenCmpId },
                mobileLock: { latestLockedCmpId, latestUnlockedCmpId },
                hoveringAndSelection: { updateScrollTop },
                mobileView: { groupsForView },
                mobileData: { mobileDownData } } = this.props,
            lockedCmpId = getLatestLockedCmpId(latestLockedCmpId, latestUnlockedCmpId, mobileDownData, groupsForView),
            scrollCmpId = latestUnhiddenCmpId || hoverUnhiddenCmpId || lockedCmpId,
            selectCmpId = latestUnhiddenCmpId || lockedCmpId,
            isAnimate = !!hoverUnhiddenCmpId || !!lockedCmpId;
        if (scrollCmpId) {
            // If a component is unhidden, update the scroll first and then select the component
            if (!updateScrollTop) {
                dispatch({ type: Actions.MOBILE_EDITOR_UPDATE_SCROLL_FOR_CMP,
                    payload: { cmpId: scrollCmpId } });
            } else {
                this.updateNewScrollTop(isAnimate, selectCmpId);
                dispatch({ type: Actions.MOBILE_EDITOR_RESET_STATUS_FLAGS });
            }
        } else if (!prevProps.hoveringAndSelection.updateScrollTop && updateScrollTop) {
            this.updateNewScrollTop();
        }
        if (
            this.editorContainerRef.current
            && !this.state.editorContainerWidth
            && this.props.mobileView.show
        ) {
            this.setState({ editorContainerWidth: this.editorContainerRef.current.clientWidth }); // eslint-disable-line react/no-did-update-set-state
        }
    }

    onMveScroll = (event, isDragging, contentScrollTop, selectedCmpId) => {
        if (isDragging) {
            $('.' + styles.content)[0].scrollTop = contentScrollTop;
        }
        if (selectedCmpId) {
            const elemRect = getDraggableElementRect(selectedCmpId);
            if (elemRect) {
                this.props.dispatch({
                    type: Actions.MOBILE_EDITOR_UPDATE_ARROW_FOR_SCROLL,
                    payload: {
                        ...getElementReorderArrowsPayload(elemRect),
                        elemRect: getCoordinates(getElementDom(selectedCmpId), this.editorContentRef.current)
                    }
                });
            }
        }

        this.props.dispatch({
            type: Actions.MOBILE_EDITOR_VIEW_SCROLLED,
            payload: {
                boundingClientRect: this.mobileDownEditorContainerRef ? this.mobileDownEditorContainerRef.getBoundingClientRect() : {} // eslint-disable-line max-len
            }
        });

        this.props.dispatch({
            type: Actions.UPDATE_SCROLL_TOP_VALUE,
            payload: {
                contentScrollTop: event.currentTarget.scrollTop
            }
        });
    }

    mobileReorderTooltipHandler(e) {
        this.props.dispatch({
            type: Actions.MOBILE_EDITOR_CMP_REORDER_TIP,
            payload: {
                id: MobileEditorReorderTip,
                verticalPosition: getPosition(e).y,
                boundingClientRect: this.contentContainerRef ? this.contentContainerRef.getBoundingClientRect() : {} // eslint-disable-line max-len
            }
        });
    }

    mobileDownMaskOnMouseOverHandler = () => {
        this.props.dispatch({
            type: Actions.MOBILE_DOWN_ON_MOUSE_OVER,
            payload: {
                boundingClientRect: this.mobileDownEditorContainerRef ? this.mobileDownEditorContainerRef.getBoundingClientRect() : {} // eslint-disable-line max-len
            }
        });
    }

    mobileDownMaskOnMouseOutHandler = () => {
        this.props.dispatch({
            type: Actions.MOBILE_DOWN_ON_MOUSE_OUT
        });
    }

    render() {
        if (!this.props.mobileView.show) {
            return null;
        }
        const
            {
                status,
                mobileView: {
                    styles: cmpStyles,
                    mdStartFromId,
                    componentsSpecificStyles,
                    settings,
                    mobileEltExtension
                },
                mobileData: { data, mobileDownData },
                componentExtensions,
                hoveringAndSelection: {
                    selectedCmpId,
                    isDragging,
                    reorderTipPosLeft,
                    contentScrollTop,
                    showMobileDownMask,
                    maskBorderStyle,
                    showCmpReorderTip,
                    isHeaderSelected,
                    isMenuOpen,
                    isMVEEditMode,
                },
                template,
                currentPageName,
                dispatch,
                mobileMCTA,
                componentsMap,
                menuState,
                siteMap,
                globalVariables,
                siteSettings: { themeSettingsData: { autoColorMode } },
                globalStyles: { stylesheets },
                autoColorLeftPanel: { show: showAutoColorLeftPanel },
                selectedComponentIsInsideHeaderOrFooter: { isInsideHeaderOrFooter },
                siteSettings
            } = this.props,
            hasItems = !!(Object.keys(data).length && data[Object.keys(data)[0]].length),
            mveBackgroundProps = {
                topContainer: true,
                hasItems,
                template,
                autoColorMode,
                themeColorsData: getThemeColorsFromStylesheet(stylesheets)
            },
            selectionData = selectedCmpId ? this.getSelectionData(selectedCmpId) : null,
            compomentMainActionSelectCompV = getMCTAView(selectedCmpId, componentsMap),
            reorderTxt = <Msg k="mve.leftSideTip.reorder">To reorder a section or component, click and drag it into position.</Msg>, // eslint-disable-line max-len
            tipTxt = <Msg k="tip">Tip:</Msg>,
            ReArrangeIcon = Icons.RE_ARRANGE_ILLUSTRATION,
            mobileDownTreeStyle = mdStartFromId ? cmpStyles[mdStartFromId] : {},
            mobileDownTreeTop = (mobileDownTreeStyle && mobileDownTreeStyle.marginTop) || 0,
            browserScrollbarWidth = getBrowserScrollbarWidth();

        return ([
            <style key="mobileSpecificStyle">
                {componentsSpecificStyles}
            </style>,
            <div
                key="editorContainer"
                className={`${styles.container} mobileV`}
                onMouseDown={(e) => {
                    if (!isRightClick(e) && typeof (e.target as HTMLDivElement).className === 'string'
                        && ((e.target as HTMLDivElement).className.indexOf(styles.container) !== -1)) {
                        dispatch(unFocusMveWorkspaceAC());
                    }
                }}
                onMouseMove={(e) => {
                    if (typeof (e.target as HTMLDivElement).className === 'string' &&
                        ((e.target as HTMLDivElement).className.indexOf(styles.container) !== -1)) {
                        dispatch({ type: Actions.MOBILE_EDITOR_REMOVE_OUTLINE });
                    }
                }}
                onMouseUp={(e) => {
                    if (typeof (e.target as HTMLDivElement).className === 'string' &&
                        ((e.target as HTMLDivElement).className.indexOf(styles.container) !== -1)) {
                        this.props.dispatch({
                            type: Actions.MOBILE_EDITOR_COMPONENT_MOUSE_UP_OUTSIDE
                        });
                    }
                }}
                onWheel={(e) => {
                    const editorContainer = this.editorContentRef.current;

                    if (e.currentTarget === e.target && editorContainer) {
                        if (editorContainer.scrollBy) {
                            editorContainer.scrollBy(e.deltaX, e.deltaY);
                        } else { // support for IE
                            editorContainer.scrollLeft = editorContainer.scrollLeft + e.deltaX;
                            editorContainer.scrollTop = editorContainer.scrollTop + e.deltaY;
                        }
                    }
                }}
            >
                <div className={styles.reArrangeContainer} style={{ left: reorderTipPosLeft }}>
                    <div className={styles.tipTxt}>{tipTxt}</div>
                    <div className={styles.reArrangeBodyContainer}>
                        <div>
                            <ReArrangeIcon className={styles.reArrangeIcon} />
                        </div>
                        <div className={styles.reorderTxt}>{reorderTxt}</div>
                    </div>
                </div>
                {template && <HiddenComponents {...this.props} showAutoColorLeftPanel={showAutoColorLeftPanel} />}
                <MobileOptions
                    activateMobileView={siteMap.activateMobileView}
                    onToggleMobileVersion={this.onToggleMobileVersion}
                    orientation={MobileViewOrientations.PORTRAIT}
                    showAutoColorLeftPanel={showAutoColorLeftPanel}
                />
                <div className={styles.mobileContainer} style={{ width: styles.mobileContainerWidth }}>
                    <div
                        className={styles.contentContainer}
                        ref={ref => {
                            this.contentContainerRef = ref;
                        }}
                    >
                        <div className={styles.topContainer}>
                            <div className={styles.topLine} />
                        </div>
                        <div className={cx({ [styles.showMobileDownMask]: showMobileDownMask })}>
                            <div
                                style={{
                                    display: maskBorderStyle.showCustomTop ? 'block' : 'none',
                                    right: browserScrollbarWidth // Margin for scroll bar
                                }}
                                className={cx(styles.mobileDownMaskBorderOnlyTop)}
                            />
                            <div
                                style={{
                                    display: maskBorderStyle.showCustomTop ? 'block' : 'none',
                                    right: browserScrollbarWidth + Constants.MobileDownLockedDefaults.right // Margin for scroll bar
                                }}
                                className={cx(styles.mobileDownLocked, styles.mobileDownLockedOnlyTop)}
                            />
                        </div>
                        <Measure
                            offset
                            innerRef={this.editorContentRef}
                        >
                            {({ measureRef }) => {
                                return <div
                                    ref={measureRef}
                                    className={cx(styles.content, BLOCK_NATIVE_CONTEXT_MENU_CLASS, { [styles.openMenu]: isMenuOpen })}
                                    onScroll={
                                        (event) => {
                                            this.onMveScroll(event, isDragging, contentScrollTop, selectedCmpId);
                                        }
                                    }
                                    onWheel={(e) => e.stopPropagation()}
                                >
                                    <MveHeader
                                        pageName={currentPageName}
                                        isSelected={isHeaderSelected}
                                        isMenuOpen={isMenuOpen}
                                        isMVE
                                        menuState={menuState}
                                        site={siteMap}
                                        globalVariables={globalVariables}
                                        scrollBarWidth={this.state.scrollBarWidth}
                                        siteSettings={siteSettings}
                                    />
                                    {
                                        compomentMainActionSelectCompV
                                            && selectedCmpId
                                            && mobileMCTA.visible
                                            && mobileMCTA.position ?
                                            MCTAView({
                                                ...mobileMCTA.position,
                                                parentStyle: compomentMainActionSelectCompV.parentStyle,
                                                children: compomentMainActionSelectCompV({
                                                    dispatch: dispatchForwardToSelectedComponent(this.props.dispatch, true),
                                                    id: selectedCmpId,
                                                    settings,
                                                    ...selectionData,
                                                    componentExtensions,
                                                    mobileMCTA,
                                                    mobileEltExtension,
                                                    ...(
                                                        selectionData && selectionData.selectedComponent ?
                                                            this.props.componentsDependencies[(selectionData.selectedComponent).kind] : {}
                                                    ),
                                                    ...this.props.controlsDependenciesForSelectedComponent,
                                                    isInsideHeaderOrFooter
                                                }),
                                                // @ts-ignore
                                                dispatch: dispatchForwardToSelectedComponent(this.props.dispatch, true),
                                                isMobileV: true
                                            })
                                            : null
                                    }
                                    {isPageLoading(status) ? (
                                        <div className={styles.loadingIcn}>
                                            <LoadingIndicator />
                                        </div>
                                    ) : (
                                        <div
                                            ref={this.editorContainerRef}
                                            className={cx(styles.editorContainer, 'mveEditorContainer')}
                                            onMouseMove={(e) => {
                                                const cmpId = getCmpId(e.target) || '',
                                                    { kind } = componentsMap[cmpId] || {};
                                                if (isDragging || (isStretchComponentKind(kind) && !data[cmpId])) {
                                                    if (showCmpReorderTip) {
                                                        this.mobileReorderTooltipHandler(e);
                                                    }
                                                    return;
                                                }
                                                this.props.dispatch({
                                                    type: Actions.MOBILE_EDITOR_COMPONENT_MOUSE_MOVE,
                                                    payload: {
                                                        cmpId,
                                                        position: { x: e.clientX, y: e.clientY }
                                                    }
                                                });
                                            }}
                                            onMouseDown={(e) => {
                                                const id = getCmpId(e.target) || '',
                                                    node = getElementDom(id),
                                                    { kind } = componentsMap[id] || {};

                                                if (isStretchComponentKind(kind) && !data[id]) {
                                                    return;
                                                }

                                                this.props.dispatch({
                                                    type: Actions.MOBILE_EDITOR_COMPONENT_MOUSE_DOWN,
                                                    payload: {
                                                        cmpId: id,
                                                        position: { x: e.clientX, y: e.clientY },
                                                        componentRect: getCoordinates(
                                                            node,
                                                            this.editorContentRef.current
                                                        )
                                                    }
                                                });
                                            }}
                                            onMouseUp={(e) => {
                                                // needed to reposition arrows at the end of drag
                                                if ($(e.target).hasClass(`${styles.editorContainer}`) ||
                                                    $(e.target).parents(`.${styles.editorContainer}`).length) {
                                                    setTimeout(() => this.selectCmp(selectedCmpId), 300);
                                                }
                                                this.props.dispatch({
                                                    type: Actions.MOBILE_EDITOR_COMPONENT_MOUSE_UP,
                                                });
                                            }}
                                            onKeyDown={this.onKeyDownHandler}
                                            tabIndex={0}     // eslint-disable-line
                                        >
                                            {/* @ts-ignore */}
                                            <Editor
                                                {...this.props}
                                                data={data}
                                                disableSort={isMVEEditMode}
                                                editorContainerWidth={this.state.editorContainerWidth}
                                            />
                                            {mdStartFromId ? <div
                                                className={styles.mobileDownEditorContainer}
                                                onMouseOver={this.mobileDownMaskOnMouseOverHandler}
                                                onMouseOut={this.mobileDownMaskOnMouseOutHandler}
                                                ref={ref => { this.mobileDownEditorContainerRef = ref; }}
                                            >
                                                {/* @ts-ignore */}
                                                <Editor
                                                    {...this.props}
                                                    disableSort
                                                    data={mobileDownData}
                                                    editorContainerWidth={this.state.editorContainerWidth}
                                                />
                                                <div className={cx({
                                                    [styles.showMobileDownMask]: showMobileDownMask
                                                })}
                                                >
                                                    <div
                                                        className={cx(
                                                            styles.mobileDownMask,
                                                            styles.left
                                                        )}
                                                        style={{ top: mobileDownTreeTop }}
                                                    />
                                                    <div
                                                        className={cx(
                                                            styles.mobileDownMask,
                                                            styles.right
                                                        )}
                                                        style={{ top: mobileDownTreeTop }}
                                                    />
                                                    <div
                                                        className={cx(
                                                            styles.mobileDownMask,
                                                            styles.top
                                                        )}
                                                        style={{ top: mobileDownTreeTop }}
                                                    />
                                                    <div
                                                        className={cx(
                                                            styles.mobileDownMask,
                                                            styles.bottom
                                                        )}
                                                    />
                                                    <div
                                                        style={{
                                                            display:
                                                                maskBorderStyle.showCustomTop ? 'none' : 'block',
                                                            top: mobileDownTreeTop
                                                                + Constants.MobileDownLockedDefaults.top
                                                        }}
                                                        className={styles.mobileDownLocked}
                                                    />
                                                </div>
                                            </div> : null}
                                            <MveBackground
                                                {...mveBackgroundProps}
                                                topContainer={false}
                                            />
                                        </div>
                                    )}
                                    <MveBackground {...mveBackgroundProps} />
                                </div>;
                            }}
                        </Measure>
                        {isMenuOpen &&
                            [<div key="menuOpenOutline_1" className={cx(styles.menuOpenOutline, styles.top)} />,
                                <div key="menuOpenOutline_2" className={cx(styles.menuOpenOutline, styles.right)} />,
                                <div key="menuOpenOutline_3" className={cx(styles.menuOpenOutline, styles.bottom)} />,
                                <div key="menuOpenOutline_4" className={cx(styles.menuOpenOutline, styles.left)} />]}
                        <div className={cx({ [styles.showMobileDownMask]: showMobileDownMask })}>
                            <div
                                style={{
                                    display: maskBorderStyle.hideCustomBottom ? 'none' : 'block',
                                    right: browserScrollbarWidth // Margin for scroll bar
                                }}
                                className={cx(styles.mobileDownMaskBorderOnlyBottom)}
                            />
                        </div>
                        <div className={styles.bottomContainer}>
                            <div className={styles.bottomDot} />
                        </div>
                    </div>
                </div>
                <MobileEditorActions {...this.props} onMouseLeave={this.selectCmp} />
            </div>
        ]);
    }
}

const globalVariablesFromAppStateSelector = makeEpicStateSelector(globalVariablesEpic.valueActionType);

const mapStateToProps = (state: AppState) => {
    const
        {
            selectedWorkspace: {
                status,
                renderGlobalstyles,
                stylesheetsIdToNameMap,
                componentsDependencies,
                components: { componentsMap }
            },
            subscriptionData
        } = state;

    return {
        status,
        mobileView: makeEpicStateSelector(mobileViewReorderValueActionType)(state),
        siteMap: makeEpicStateSelector(siteDataValueActionType)(state),
        siteSettings: makeEpicStateSelector(siteSettingsValueActionType)(state).current,
        template: makeEpicStateSelector(templateValueActionType)(state),
        mobileMCTA: makeEpicStateSelector(MCTAValueActionType)(state),
        mobileData: makeEpicStateSelector(mobileEditorMobileDownVAT)(state),
        hoveringAndSelection: makeEpicStateSelector(hoveringAndSelectionVAT)(state),
        hiddenComponents: makeEpicStateSelector(hiddenComponents)(state),
        mobileLock: makeEpicStateSelector(mobileLock)(state),
        componentExtensions: makeEpicStateSelector(componentExtensions)(state),
        globalStyles: renderGlobalstyles,
        stylesheetsIdToNameMap,
        componentsDependencies,
        componentsMap,
        subscriptionData,
        currentPageName: makeEpicStateSelector(currentPageNameVAT)(state),
        parentThemeMap: makeEpicStateSelector(GET_PARENT_THEME_MAP_VAT)(state).parentThemeMap,
        menuState: mobileMenuAppSel(state),
        globalVariables: globalVariablesFromAppStateSelector(state),
        controlsDependenciesForSelectedComponent:
            getControlsDependenciesForSelectedComponentEpicStateFromAppState(state),
        autoColorLeftPanel: makeEpicStateSelector(AUTO_COLOR_LEFT_PANEL_EPIC_VALUE)(state),
        webshopMHFData: makeEpicStateSelector(webShopMHFValueActionType)(state),
        selectedComponentIsInsideHeaderOrFooter: makeEpicStateSelector(selectedComponentIsInsideHeaderOrFooterVAT)(state)
    };
};

export default connect(mapStateToProps)(MobileViewEditor);
