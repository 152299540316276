import * as TextActions from "./actionTypes";

const TextEventNames = {
    spellingFix: 'WSB spellcheck suggestion applied',
};

const TextMixpanelEvents = {
    [TextActions.SPELLING_FIX_APPLIED]: {
        eventName: TextEventNames.spellingFix,
    },
};

export {
    TextMixpanelEvents
};
