import React from 'react';
import { connect } from 'react-redux';
import { $Keys } from 'utility-types';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import * as styles from './CodeComponentsRenderer.css';
import {
    GHOST_CODE_COMPONENTS_SORT_END,
    GHOST_CODE_COMPONENT_SORT_START, GHOST_CODE_COMPONENT_RIGHT_CLICK
} from "./CodeComponentsRendererActionTypes";
import { CodeComponentHostKinds } from "./codeComponentsHostKinds";
import CodeView from '../../oneweb/Code/view/workspace';
import {
    ghostCodeComponentHeight,
    ghostCodeComponentMarginTop
} from "./epic/index";
import { codeComponentsRendererEpicStateFromAppStateSelector } from "./epic/selectorActionTypes";

import type { CodeComponent } from "../../oneweb/Code/flowTypes";
import * as mouseUtils from "../../../utils/mouse";
import { SET_SELECTED_COMPONENTS } from "../epics/componentsEval/actionTypes";
import {
    getComponentTierDataFromComponentKind,
    checkComponentSubscriptionCompatibility
} from "../../ComponentTierManager/utils";
import type { SubscriptionTypeT } from "../../App/epics/subscriptionData/flowTypes";

type FactoryProps = {
    kind: $Keys<typeof CodeComponentHostKinds>
}

type Props = {
    subscriptionType: SubscriptionTypeT,
    codeComponents: Array<CodeComponent>,
    dispatch: Dispatch,
    height: number,
    selectedComponentId: string | null,
}

const
    SortableItem =
        SortableElement<any>(
            ({ component, onMaskClick, isSelected, isDisabled, onRightMouseDown, dispatch, onClick, subscriptionType }) => {
                // TODO: Refactor along with wbtgen/src/view/Workspace/TemplateArea/ComponentRenderer.js
                const componentTierData = getComponentTierDataFromComponentKind(component.kind);
                const isCompatible = checkComponentSubscriptionCompatibility(componentTierData.componentTierType, subscriptionType);

                return (
                    <li
                        style={{ paddingTop: ghostCodeComponentMarginTop, listStyle: 'none' }}
                        onMouseDown={e => mouseUtils.onMouseDown(
                            e,
                            (p, e) => e.stopPropagation(),
                            (p, e) => { e.stopPropagation(); onRightMouseDown(p); }
                        )}
                        onClick={onClick}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: ghostCodeComponentHeight,
                                outline: isSelected ? '1px solid rgb(224, 133, 119)' : undefined,
                                position: 'relative'
                            }}
                        >
                            <CodeView
                                skipWrapInBaseComponent
                                component={component}
                                componentTierData={{ ...componentTierData, isCompatible }}
                                inEditMode={false}
                                inSelectedMode={isSelected}
                                // @ts-ignore
                                dispatch={dispatch}
                            />
                            {
                                isDisabled && <div
                                    className={isDisabled}
                                    onClick={onMaskClick}
                                />
                            }
                        </div>
                    </li>
                );
            }
        );

const SortableList = SortableContainer<any>(({
    components,
    selectedComponentId,
    disabled,
    kind,
    onRightMouseDown,
    subscriptionType,
    dispatch
}) => {
    return (
        <ul>
            {
                components.map((codeComponent: CodeComponent, index) => (
                    <SortableItem
                        key={`item-${index}`}
                        index={index}
                        collection={kind}
                        component={codeComponent}
                        disabled={disabled}
                        isDisabled={disabled}
                        isSelected={codeComponent.id === selectedComponentId}
                        onRightMouseDown={(position) => onRightMouseDown({ position, componentId: codeComponent.id })}
                        dispatch={dispatch}
                        onClick={() => dispatch({ type: SET_SELECTED_COMPONENTS, payload: [codeComponent.id] })}
                        subscriptionType={subscriptionType}
                    />
                ))
            }
        </ul>
    );
});

const
    makeCodeComponentsRenderer = ({ kind }: FactoryProps) => {
        const
            mapStateToProps = appState => {
                const
                    state = codeComponentsRendererEpicStateFromAppStateSelector(appState);

                return {
                    subscriptionType: appState.subscriptionData.subscriptionType,
                    codeComponents: state.codeComponents[kind],
                    height: state.heights[kind],
                    selectedComponentId: state.selectedComponentId,
                };
            },
            Comp = ({ dispatch, codeComponents, height, selectedComponentId, subscriptionType }: Props) => {
                return (
                    <div
                        className={styles.container}
                        style={{
                            height,
                            display: codeComponents.length ? 'block' : 'none'
                        }}
                    >
                        <SortableList
                            components={codeComponents}
                            subscriptionType={subscriptionType}
                            onSortEnd={
                                ({ oldIndex, newIndex }) => {
                                    dispatch({
                                        type: GHOST_CODE_COMPONENTS_SORT_END,
                                        payload: {
                                            kind,
                                            oldIndex,
                                            newIndex
                                        }
                                    });
                                }
                            }
                            onSortStart={() => dispatch({ type: GHOST_CODE_COMPONENT_SORT_START })}
                            selectedComponentId={selectedComponentId}
                            kind={kind}
                            onRightMouseDown={
                                (payload) => dispatch({ type: GHOST_CODE_COMPONENT_RIGHT_CLICK, payload })
                            }
                            distance={5}
                            dispatch={dispatch}
                        />
                    </div>
                );
            };

        return connect(mapStateToProps)(Comp);
    };

export {
    makeCodeComponentsRenderer
};
