import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

import { PreviewSearchProps } from "./types";

import imageStyles from "../../Images/imageStyle.css";

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: "15px",
        lineHeight: "24px"
    },
    root: {
        marginTop: "10px",
        borderColor: theme.palette.text.primary,
        background: theme.palette.custom.colorGray_f7,
        fontSize: "16px"
    },
    cardContent: {
        padding: "46px",
        [theme.breakpoints.down('md')]: {
            padding: "24px",
            "&:last-child": {
                paddingBottom: "24px"
            },
        },
    },
    url: {
        lineHeight: "18px"
    },
    title: {
        fontSize: "22px",
        margin: "10px 0px",
        lineHeight: "25px",
        color: theme.palette.custom.colorNeonBlue,
        wordBreak: "break-word",
    },
    description: {
        color: theme.palette.custom.colorDavysGrey,
        fontSize: "16px",
        lineHeight: "21px"
    },
    hiddenWarning: {
        marginTop: "10px"
    },
    hiddenWarningMessage: {
        marginLeft: "7px",
        color: theme.palette.custom.colorBloodOrange2,
        fontSize: "15px",
        lineHeight: "24px",
        letterSpacing: "0.1px",
        flex: 1,
    }
}), { name: "PreviewInSearch" });

export const PreviewInSearch = ({ url, intl, title, description, includeInIndexing }: PreviewSearchProps) => {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid item className={classes.heading}>
                {intl.msgJoint("msg: dashboard.onlinePresence.previewInSearch.title {Preview in search results}")}
            </Grid>
            <Grid>
                <Card variant="outlined" className={classes.root}>
                    <CardContent className={classes.cardContent}>
                        <Typography className={classes.url}>
                            {url}
                        </Typography>
                        <Typography className={classes.title} variant="h5">{title}</Typography>
                        <Typography className={classes.description} component="p">
                            {description || intl.msgJoint("msg: dashboard.onlinePresence.previewInSearch.newDescription {This text is taken from the page description. If there is none, search engines will automatically fill this in based on the content of your site.}")}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            {!includeInIndexing &&
                <Grid container item className={classes.hiddenWarning}>
                    <Grid item className={imageStyles.warningIconInverted} />
                    <Typography className={classes.hiddenWarningMessage}>
                        {intl.msgJoint("msg: dashboard.onlinePresence.previewInSearch.warning {Hidden in search engines}")}
                    </Typography>
                </Grid>}
        </React.Fragment>
    );
};
