import { Dispatch } from "redux";
import type { AppStore } from "../../../redux/modules/flowTypes";
import {
    FB_SDK_INIT_ACTION,
    FB_SDK_LOGIN_ACTION,
    FB_SDK_API_ACTION,
    facebookSdkNotLoaded,
} from "./facebookSdkActions";
import AppConfig from "../../../utils/AppConfig";
import { getAppConfig } from "../../App/epics/appConfig/appConfig";
import { initiateExchangeTokenAction } from "./FacebookFeed/actions";
import { initiateFBChatExchangeTokenAction } from "./FacebookChat/actions";
import { socialAccountsFacebookFeedPageMode } from "./FacebookFeed/selectors";
import { FACEBOOK_PAGE_ACCESS_PERMS, FACEBOOK_USER_ACCESS_PERMS } from "./FacebookFeed/constants";
import { FB_FEED_LOGIN, FB_CHAT_LOGIN } from "./constants";

const
    appConfig = AppConfig(getAppConfig()),
    facebookFeedAppID = appConfig.getOptional('oneWeb.facebook.clientId');

export const facebookSdkMiddleware = (store: AppStore) => (next: Dispatch) => (action: Action) => {
    if (action.type === FB_SDK_INIT_ACTION) {
        // eslint-disable-next-line no-unused-expressions
        if (window.FB) {
            window.FB.init({
                appId: facebookFeedAppID,
                status: true,
                xfbml: true,
                version: 'v17.0'
            });
        } else {
            store.dispatch(facebookSdkNotLoaded());
        }
    }

    if (action.type === FB_SDK_LOGIN_ACTION) {
        const { loginType } = action.payload;

        const execeuteFBLogin = (isPageMode, exchangeTokenAction) => {
            // eslint-disable-next-line no-unused-expressions
            if (window.FB) {
                window.FB.login(function (response) {
                    if (response && response.authResponse && response.authResponse.accessToken) {
                        store.dispatch(exchangeTokenAction());
                    }
                }, {
                    scope: isPageMode ? FACEBOOK_PAGE_ACCESS_PERMS : FACEBOOK_USER_ACCESS_PERMS,
                    return_scopes: true,
                    enable_profile_selector: true,
                    auth_type: "reauthorize",
                });
            } else {
                store.dispatch(facebookSdkNotLoaded());
            }
        };

        if (loginType === FB_FEED_LOGIN) {
            const isPageMode = socialAccountsFacebookFeedPageMode(store.getState());
            execeuteFBLogin(isPageMode, initiateExchangeTokenAction);
        } else if (loginType === FB_CHAT_LOGIN) {
            execeuteFBLogin(true, initiateFBChatExchangeTokenAction);
        }
    }

    if (action.type === FB_SDK_API_ACTION) {
        const {
            actionPath,
            actionMethod,
            actionParams,
            responseSuccessActionType,
            responseFailureActionType,
        } = action.payload;
        // eslint-disable-next-line no-unused-expressions
        if (window.FB) {
            window.FB.api(actionPath, actionMethod, actionParams, (response) => {
                if (response.error) {
                    store.dispatch({
                        type: responseFailureActionType,
                        payload: response.error
                    });
                } else {
                    store.dispatch({
                        type: responseSuccessActionType,
                        payload: response
                    });
                }
            });
        } else {
            store.dispatch(facebookSdkNotLoaded());
        }
    }

    return next(action);
};
