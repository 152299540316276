import React from "react";
import Button from "@mui/material/Button";
import makeStyles from '@mui/styles/makeStyles';

import { useIntl } from '../../../../../../../wbtgen/src/view/intl';

const useStyles = makeStyles(
    (theme) => ({
        actionButton: {
            height: "46px",
            padding: "12px 24px",
            fontSize: "14px",
            lineHeight: "22px",
            letterSpacing: "0.25px",
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                width: "auto",
            },
        }
    }),
    { name: "WidgetButton" }
);

type WidgetButtonProps = {
    label: string;
    clickHandler: () => void;
};

export const WidgetButton = ({ label, clickHandler }: WidgetButtonProps) => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Button
            variant="outlined"
            color="primary"
            className={classes.actionButton}
            onClick={clickHandler}
        >
            {intl.msgJoint(label)}
        </Button>
    );
};
