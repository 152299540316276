import { path, pathOr } from 'ramda';
import React from 'react';
import BorderSettings from "../../../../presentational/BorderSettings"; // eslint-disable-line
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import type { PageProps } from "../../../../PropertiesPanel/flowTypes";
import type { GalleryComponent } from "../../flowTypes";
import * as ActionTypes from '../../actionTypes';
import * as pageIds from '../pagesIds';
import pageTitles from '../pagesTitles';
import {
    getBorderColor,
    getBorderOpacity,
    getBorderStyle,
    getBorderWidth, getComponentColorGradientSettings,
    getCorner,
    getPaddingTop,
    getPaddingBottom,
    getPaddingLeft,
    getPaddingRight
} from "../../../../../view/oneweb/commonComponentSelectors";
import { calcGalleryMaxThumbnailDimensions } from "../../calcRenderProps";
import VerticalSpacer from '../../../../../view/common/VerticalSpacer';
import OpacitySlider from '../../../../presentational/OpacitySlider/index';
import ColorProperty from '../../../../presentational/ColorProperty/index';
import Slider from '../../../../../view/common/Slider/PPSlider';
import styles from "./styles.css";
import Msg from "../../../../../view/intl/Msg";
import { captionDefaults, Padding } from "../../constants";
import { DEFAULT_COLOR_PICKER, THEME_COLOR_PICKER } from '../../../../ColorPicker/constants';
import { getThemeRulesForBackground } from '../../../../ThemeGlobalData/themeRules';
import { getCaptionBoxBorderColorForTheme } from '../../utils';
import { Color } from '../../../../../mappers/flowTypes';

const
    getCaptionBoxDimension = (component: GalleryComponent) => {
        const
            maxThumbnailDimensions =
                calcGalleryMaxThumbnailDimensions(component),
            maxThumbnailWidth = maxThumbnailDimensions.maxThumbnailWidth,
            maxThumbnailHeight = maxThumbnailDimensions.maxThumbnailHeight,
            row = Math.ceil(component.images.length / component.columns),
            maxHeightCaption = (component.height / row) - maxThumbnailHeight;

        return {
            width: maxThumbnailWidth,
            height: maxHeightCaption
        };
    },
    id = pageIds.CAPTIONS_BOX_STYLE,
    title = pageTitles[id],
    view = (props: PageProps<GalleryComponent>) => {
        const
            { selectedComponent, dispatch } = props,
            styleObj = {
                style: {
                    ...captionDefaults.box[selectedComponent.previousCaptionStyle],
                    ...selectedComponent.captionBoxStyle
                }
            },
            captionBoxBorderStyle = {
                style: getBorderStyle(styleObj),
                opacity: getBorderOpacity(styleObj),
                widths: getBorderWidth(styleObj),
                corners: getCorner(styleObj)
            },
            backgroundStyle = getComponentColorGradientSettings(styleObj, props.themeSettingsData.autoColorMode),
            captionBoxPaddingTop = getPaddingTop(styleObj) ||
                captionDefaults.box[selectedComponent.previousCaptionStyle].padding?.top,
            captionBoxPaddingBottom = getPaddingBottom(styleObj) ||
                captionDefaults.box[selectedComponent.previousCaptionStyle].padding?.bottom,
            captionBoxPaddingLeft = getPaddingLeft(styleObj) ||
                captionDefaults.box[selectedComponent.previousCaptionStyle].padding?.left,
            captionBoxPaddingRight = getPaddingRight(styleObj) ||
                captionDefaults.box[selectedComponent.previousCaptionStyle].padding?.right,
            { color } = backgroundStyle,
            fillColor: Color = color && [color[0], color[1], color[2], color[3], 1],
            onOpacityChange = opacity => dispatch({
                type: ActionTypes.GALLERY_PP_CAPTION_BOX_BACKGROUND_OPACITY_CHANGE,
                payload: opacity,
                amendToSelf: true
            }),
            imageStyleLink = (
                <a onClick={() => props.navigateToPage(pageIds.IMAGE_STYLES)}>
                    <Msg k="component.gallery.propPanel.imageStyle.title">Image style</Msg>
                </a>
            );
        return (
            <Page>
                <div>
                    <VerticalSpacer />
                    {
                        props.themeSettingsData.autoColorMode
                            ? <ColorProperty
                                colorPickerKind={THEME_COLOR_PICKER}
                                themeColorsData={props.themeColorsData}
                                themeColor={
                                    props.selectedComponent.captionBoxStyle.background
                                    && props.selectedComponent.captionBoxStyle.background.colorData
                                    && props.selectedComponent.captionBoxStyle.background.colorData.themeColor
                                }
                                defaultThemeColor={getThemeRulesForBackground(
                                    props.selectedComponentProps.selectedParentTheme,
                                    props.themeColorsData
                                ).background}
                                label="msg: common.solidColor {Fill colour}"
                                onChangeAction={ActionTypes.GALLERY_PP_CAPTION_BOX_BACKGROUND_COLOR_SET_AUTO_COLOR}
                                dispatch={dispatch}
                            />
                            : <ColorProperty
                                colorPickerKind={DEFAULT_COLOR_PICKER}
                                label="msg: common.solidColor {Fill colour}"
                                color={fillColor}
                                onChangeAction={ActionTypes.GALLERY_PP_CAPTION_BOX_BACKGROUND_COLOR_SET}
                                onRemoveAction={ActionTypes.GALLERY_PP_CAPTION_BOX_BACKGROUND_COLOR_UNSET}
                                dispatch={dispatch}
                            />
                    }
                    <VerticalSpacer />
                    {(
                        fillColor || path(['selectedComponent', 'captionBoxStyle', 'background', 'colorData', 'themeColor'], props)
                    ) && <React.Fragment>
                        <OpacitySlider
                            opacity={backgroundStyle.opacity}
                            onChange={onOpacityChange}
                            onAfterChange={onOpacityChange}
                        />
                        <VerticalSpacer />
                    </React.Fragment>}
                    <Slider
                        label="msg: component.gallery.captionBox.paddingTop {Padding top}"
                        valueLabel={`${captionBoxPaddingTop}px`}
                        value={captionBoxPaddingTop}
                        min={Padding.MIN}
                        max={Padding.MAX}
                        onChange={paddingTop => dispatch({
                            type: ActionTypes.GALLERY_PP_CAPTION_BOX_PADDING_TOP_CHANGE,
                            payload: paddingTop,
                            amendToSelf: true
                        })}
                    />
                    <VerticalSpacer />
                    <Slider
                        label="msg: component.gallery.captionBox.paddingBottom {Padding bottom}"
                        valueLabel={`${captionBoxPaddingBottom}px`}
                        value={captionBoxPaddingBottom}
                        min={Padding.MIN}
                        max={Padding.MAX}
                        onChange={paddingBottom => dispatch({
                            type: ActionTypes.GALLERY_PP_CAPTION_BOX_PADDING_BOTTOM_CHANGE,
                            payload: paddingBottom,
                            amendToSelf: true
                        })}
                    />
                    <VerticalSpacer />
                    <Slider
                        label="msg: component.gallery.captionBox.paddingLeft {Padding left}"
                        valueLabel={`${captionBoxPaddingLeft}px`}
                        value={captionBoxPaddingLeft}
                        min={Padding.MIN}
                        max={Padding.MAX}
                        onChange={paddingLeft => dispatch({
                            type: ActionTypes.GALLERY_PP_CAPTION_BOX_PADDING_LEFT_CHANGE,
                            payload: paddingLeft,
                            amendToSelf: true
                        })}
                    />
                    <VerticalSpacer />
                    <Slider
                        label="msg: component.gallery.captionBox.paddingRight {Padding right}"
                        valueLabel={`${captionBoxPaddingRight}px`}
                        value={captionBoxPaddingRight}
                        min={Padding.MIN}
                        max={Padding.MAX}
                        onChange={paddingRight => dispatch({
                            type: ActionTypes.GALLERY_PP_CAPTION_BOX_PADDING_RIGHT_CHANGE,
                            payload: paddingRight,
                            amendToSelf: true
                        })}
                    />
                </div>
                {
                    props.themeSettingsData.autoColorMode
                        ? <BorderSettings.view
                            autoColorMode={props.themeSettingsData.autoColorMode}
                            themeColor={pathOr(
                                getCaptionBoxBorderColorForTheme(
                                    props.selectedComponent.captionStyle,
                                    props.selectedComponent.previousCaptionStyle,
                                    path(['selectedComponent', 'captionBoxStyle', 'border'], props),
                                    props.selectedComponentProps.selectedParentTheme,
                                    props.themeColorsData
                                ),
                                ['selectedComponent', 'captionBoxStyle', 'border', 'themeColor'],
                                props
                            )}
                            themeColorsData={props.themeColorsData}
                            styleChangeAction={ActionTypes.GALLERY_PP_CAPTION_BOX_BORDER_STYLE}
                            colorChangeAction={ActionTypes.GALLERY_PP_CAPTION_BOX_BORDER_COLOR_AUTO_COLOR}
                            opacityChangeAction={ActionTypes.GALLERY_PP_CAPTION_BOX_BORDER_OPACITY}
                            widthChangeAction={ActionTypes.GALLERY_PP_CAPTION_BOX_BORDER_WIDTH}
                            cornerChangeAction={ActionTypes.GALLERY_PP_CAPTION_BOX_BORDER_CORNER}
                            dispatch={props.dispatch}
                            {...captionBoxBorderStyle}
                            selectedComponent={getCaptionBoxDimension(selectedComponent)}
                        />
                        : <BorderSettings.view
                            autoColorMode={props.themeSettingsData.autoColorMode}
                            color={getBorderColor(styleObj)}
                            styleChangeAction={ActionTypes.GALLERY_PP_CAPTION_BOX_BORDER_STYLE}
                            colorChangeAction={ActionTypes.GALLERY_PP_CAPTION_BOX_BORDER_COLOR}
                            opacityChangeAction={ActionTypes.GALLERY_PP_CAPTION_BOX_BORDER_OPACITY}
                            widthChangeAction={ActionTypes.GALLERY_PP_CAPTION_BOX_BORDER_WIDTH}
                            cornerChangeAction={ActionTypes.GALLERY_PP_CAPTION_BOX_BORDER_CORNER}
                            dispatch={props.dispatch}
                            {...captionBoxBorderStyle}
                            selectedComponent={getCaptionBoxDimension(selectedComponent)}
                        />
                }
                <VerticalSpacer />
                <Msg
                    className={styles.message}
                    k="component.gallery.pp.imageStyle.navigation.helpText"
                    params={{ imageStyleLink }}
                >
                    {`Do you want to edit the border and corners of an image? Go up one level to {imageStyleLink}`}
                </Msg>
            </Page>
        );
    };

export { id, title, view };
