import React from 'react';
import DirectoryCell from './DirectoryCell';
import ResourceCell from './ResourceCell';
import styles from '../FileChooserList.css';
import Resource from "../../../../../redux/modules/children/fileChooser/Resource";
import type { ListBaseCellPropTypes } from "../../flowTypes";
import LazyImage from "../../../LazyImage/index";
import { getResourceImageIconPath } from "../../utils";

const LazyImageProxy = () => {
    return <span className={styles.imageProxy} />;
};

export default (props: ListBaseCellPropTypes) => {
    const
        resource: Resource = props.resource,
        iconProps = { ...props, column: 'icon' };

    return resource.isDirectory()
        ? <DirectoryCell {...iconProps}><span className={styles.directoryIcn} /></DirectoryCell>
        // @ts-ignore
        : <ResourceCell {...iconProps}>
            <div className={styles.iconContainer}>
                <LazyImage
                    src={getResourceImageIconPath(resource)}
                    shouldLoad={!resource.isGhost}
                    proxy={LazyImageProxy}
                />
            </div>
        </ResourceCell>;
};
