import React from 'react';
import type { ShutterstockState } from "../../shutterstock/types";
import { Shutterstock } from "../../shutterstock/components/Shutterstock";
import { ShutterstockKind } from "../../shutterstock/constants";
import { ShutterstockBuyButton } from "./ShutterstockBuyButton";

type Props = {
    state: ShutterstockState,
    isMultiSelect?: boolean,
    subscriptionType: string,
    dispatch: Dispatch,
    onSaveAction?: string
};

export class PaidShutterstock extends React.Component<Props> {
    render() {
        return (
            <Shutterstock
                {...this.props}
                isMultiSelect={false}
                kind={ShutterstockKind.PAID}
                cellChildren={<ShutterstockBuyButton
                    onSaveAction={this.props.onSaveAction}
                    hide={this.props.state.mctaDisabled}
                    price={this.props.state.imagePrice}
                    isMultiSelect={this.props.isMultiSelect}
                />}
            />
        );
    }
}
