/* eslint max-len: 0 */
// IMPORTANT: This file contains translation string which are pending. Do not remove
//            its import or delete the file even if it is empty.

// TODO: Delete this file after verifying that the contents have been translated and utilized in code. The relevant file
//       has now been moved to "./wbtgen/src/pendingTranslations/" (w.r.t. project root)

import React from 'react';

import { injectIntl } from "./intl/index";

const DummyComponent = injectIntl(() => {
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    const arrSimpleTranslations = [
        // Expected to be released on or before Mar-2022 ; Check to clean up after 01-May-2022 // Team Rigel
        "msg: common.applyingColorTheme {Applying colour theme}",
        "msg: common.justAMoment {Just a moment...}",
        "msg: OldWsbToNewWsb.welcomeMessage {We are updating your Website Builder!}",
        "msg: OldWsbToNewWsb.newLook.title {New look}",
        "msg: OldWsbToNewWsb.newLook.message {Enjoy the fresh, modern look of your workspace}",
        "msg: OldWsbToNewWsb.mobileOptimized.title {Optimized for mobile}",
        "msg: OldWsbToNewWsb.mobileOptimized.message {Choose how your site looks on mobile devices}",
        "msg: OldWsbToNewWsb.freeImages.title {Free images}",
        "msg: OldWsbToNewWsb.freeImages.message {Get access to thousands of professional images}",
        "msg: common.backgroundGradient {Background gradient}",
        "msg: common.backgroundOpacity {Background opacity}",
        "msg: common.gs.colorTheme.baseColor {Base colour}",
        "msg: common.gs.colorTheme.baseColor.applyTooltip {Apply as base colour }",
        "msg: common.gs.colorTheme.baseColor.colorPickerHelperText {The base colour could be your logo or brand colour. Use it to guide the colour direction for your colour palette.}",
        "msg: common.gs.colorTheme.secondColor {Second colour}",
        "msg: common.gs.colorTheme.secondColor.applyTooltip {Apply as second colour }",
        "msg: common.gs.colorTheme.secondColor.colorPickerHelperText {Choose a second colour that compliments your base colour - like a colour in the same nuance.}",
        "msg: common.gs.colorTheme.thirdColor {Third colour}",
        "msg: common.gs.colorTheme.thirdColor.applyTooltip {Apply as third colour}",
        "msg: common.gs.colorTheme.thirdColor.colorPickerHelperText {Choose a third colour that compliments your base colour - like a colour in the same nuance.}",
        "msg: common.gs.colorTheme.accentColor {Accent colour}",
        "msg: common.gs.colorTheme.accentColor.applyTooltip {Apply as accent colour }",
        "msg: common.gs.colorTheme.accentColor.colorPickerHelperText {The accent colour is often used for items you want to stand out - like buttons. Choose a colour that deviates from your base colour.}",
        "msg: common.gs.colorTheme.intro.title {Welcome to your colour theme}",
        "msg: common.gs.colorTheme.intro.description {Your colour theme consists of the below colour palette that makes it quick and easy to style your website. Changing your colour theme in here will automatically update your website so it aligns with the changes you make. Try it out!}",
        "msg: common.revert {Revert}",
        "msg: common.editColors {Edit colours}",
        "msg: common.keepItActivated {Keep it activated}",
        "msg: common.yesImSure {Yes, I\'m sure}",
        "msg: common.nMore {+{count, number} more}",
        "msg: common.noUseTemplateColors {No, use template colours}",
        "msg: common.yesApplyColorTheme {Yes, apply colour theme}",
        "msg: common.tryItOut {Try it out}",
        "msg: common.notNow {Not now}",
        "msg: common.feedback.thanksForSharing {Thanks for sharing!}",
        "msg: common.gs.colorTheme.linkToSiteSettings {Manage colour theme preferences in <link></link>}",
        "msg: common.gs.colorTheme.nonEditableColorTooltip {Making text readable - cannot be edited}",
        "msg: common.gs.colorTheme.colorThemeAppliesToAllTemplates {The colour theme applies to all templates used on your website}",
        "msg: common.gs.common.colorThemeIsOn.label {Colour theme is on:}",
        "msg: common.gs.common.colorThemeIsOn.button.previews {The previews here are examples of how the colours will often apply to your button styles.}",
        "msg: common.gs.common.colorThemeIsOn {Colour theme is on: the colours of your template styles are dynamic and directed by your colour theme. The previews here are examples of how the colours will often apply to your template styles.}",
        "msg: common.gs.common.colorThemeIsOn.manageColorTheme {Manage colour theme}",
        "msg: common.gs.common.link.overview {This is an overview of your link styles}",
        "msg: common.gs.common.button.overview {Create predefined button styles to use across your pages.}",
        "msg: common.gs.common.MainMenu {Main menu}",
        "msg: common.gs.common.alternateMenu {Alternate menu}",
        "msg: colorPicker.chooseFromColorThemes.tip2 {The colour theme is set by you and can be edited in template settings}",
        "msg: colorPicker.followCurrentStyle.button.tip {The current button style is based on the selected strip style.}",
        "msg: common.primaryLink {Primary link}",
        "msg: common.underlinedLink {Underlined link}",
        "msg: common.textlikeLink {Textlike link}",
        "msg: common.alternative {Alternative}",
        "msg: common.apply.allCaps {APPLY}",
        "msg: common.reset {Reset}",
        "msg: common.showMore {Show more}",
        "msg: common.loadMore {Load more}",
        "msg: common.showLess {Show less}",
        "msg: common.newColorTheme {New colour theme}",
        "msg: common.saveChangesToContinue.label {Save changes to continue}",
        "msg: common.saveChangesToContinue.description {You have unsaved changes on your website. You need to save your changes before you can continue.}",
        "msg: common.theme.onboarding.multipleTemplates.useColorTheme.label {Use colour theme for all templates?}",
        "msg: common.theme.onboarding.multipleTemplates.useColorTheme.desc1 {It looks like you are using more than one template on your website.}",
        "msg: common.theme.onboarding.multipleTemplates.useColorTheme.desc2 {Would you like to use the colour theme for all added templates so your colours match across pages?}",
        "msg: common.theme.onboarding.multipleTemplates.useColorTheme.footer.allTemplates {Yes, use for all templates}",
        "msg: component.section.themeColorTooltip {Section styles direct the colours used for the background and components of a section. The styles are based on the colour theme of your website.}",
        "msg: siteSettings.colorTheme.useOnAllTemplates.label {Use active colour theme for all templates}",
        "msg: siteSettings.colorTheme.useOnAllTemplates.description {Applies your colour theme to all templates used on your website. This ensures a coherent look for your site as all pages will be using the same colours.}",
        "msg: siteSettings.colorTheme.useOnAllTemplates.popup1.title {Manage template colours seperately}",
        "msg: siteSettings.colorTheme.useOnAllTemplates.popup1.text.line1 {By deactivating this feature, your different templates will no longer follow a shared colour theme. Instead you will need to manage theme colours separately for each template.}",
        "msg: siteSettings.colorTheme.useOnAllTemplates.popup1.text.line2 {Are you sure you want to deactivate this feature?}",
        "msg: siteSettings.colorTheme.useOnAllTemplates.popup2.title {Use this colour theme for all templates}",
        "msg: siteSettings.colorTheme.useOnAllTemplates.popup2.text {The colour theme used for the template \"{name}\" will be applied to the following templates on your website:}",
        "msg: siteSettings.colorTheme.applyToNewTemplate.title {Apply colour theme to new template}",
        "msg: siteSettings.colorTheme.applyToNewTemplate.text.line1 {Applying your active colour theme to the new template ensures a coherent look for your site as all pages are then styled with the same colours.}",
        "msg: siteSettings.colorTheme.applyToNewTemplate.text.line2 {Would you like to apply your active colour theme to the template \"{name}\"?}",
        "msg: colorTheme.introducingColorTheme {Introducing colour theme!}",
        "msg: colorTheme.introducingColorTheme.description {It\'s now easier than ever before to manage your website colours and stay colour consistent across pages. With the new colour theme feature, you can:}",
        "msg: colorTheme.introducingColorTheme.feature1 {Set and update your website colours from one single place}",
        "msg: colorTheme.introducingColorTheme.feature2 {Style entire groups of components in just one click}",
        "msg: colorTheme.introducingColorTheme.feature3 {Choose from a selection of complementary colour themes to spice up your website}",
        "msg: colorTheme.introducingColorTheme {Introducing colour theme!}",
        "msg: colorTheme.ws.tooltip.title {Alright - find it here}",
        "msg: colorTheme.ws.tooltip.text {If you want to check out the feature later go to settings and click the \"Colour theme\" tab.}",
        "msg: colorTheme.ws.tooltip.text2 {Click here if you want to check out colour theming later on.}",
        "msg: component.openingHours.open24Hours {Open 24 hours}",
        "msg: BusinessListing.createListing.Address {Address (optional)}",
        "msg: BusinessListing.createListing.CannotFindBusinessAddress {Can\'t find your business address?}",
        "msg: BusinessListing.createListing.CannotFindBusinessAddress.tip {It means your address doesn\'t exist on Google yet. No worries, you can add it later on the Google My Business site.}",
        "msg: BusinessListing.createListing.onlineBusiness {My business is online only}",
        "msg: gmb.createAListing {Create a listing}",
        "msg: adjustColorDialog.title {Have you thought about contrast?}",
        "msg: adjustColorDialog.description {The colour combination you selected will make some of the elements on your site hard to see.}",
        "msg: adjustColorDialog.primaryButtonText {Adjust colours}",
        "msg: adjustColorDialog.secondaryButtonText {Keep as it is}",
        "msg: autoColor.leftPanel.adjustThemeColor.animationText {Adjusting your theme colours}",
        // new welcome dialog
        "msg: dashboard.welcome.description.default {Your Website Builder dashboard is full of helpful tools and settings to ensure your website is configured to your liking and performing at its best.}",
        "msg: dashboard.welcome.title.migrated {Welcome to your one.com Website Builder!}",
        "msg: dashboard.welcome.description.migrated {This is your Website Builder dashboard which is full of helpful tools and settings to ensure your website is configured to your liking and performing at its best. To edit your website, go into the editor by clicking 'Edit site'.}",
    ];

    return (
        <div />
    );
});

export default {
    DummyComponent
};
