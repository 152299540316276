/* eslint-disable camelcase */
/* eslint-disable max-len */
import tinymce from "tinymce";
import { $Keys } from "utility-types";
import {
    normalizeHtmlAccordingToTinyMCEHtmlSchema
} from './normalizeHtmlAccordingToTinyMCEHtmlSchema';
import { stripWrappedComponents } from '../../../utils/htmlWriter/html/render/wrapper/wrapperNodeUtils';
import type { TinyMceEditor } from "../../App/epics/tinyMceEpic/flowTypes";
import {
    ROOT_BLOCK_TAG,
    ROOT_BLOCK_ATTRS,
    TEXT_GLOBAL_STYLE_RULES,
    linkedValueTag,
    linkedValueAttr
} from './constants';
import { noop } from '../../../utils/ramdaEx';
import { removeInvalidURLfromContent } from "./utils/removeInvalidURLfromContent";

const getGlobalStyle = className => {
    return {
        inline: 'span',
        classes: className,
        remove_similar: true,
        clear_child_styles: true,
        remove_styles: TEXT_GLOBAL_STYLE_RULES,
        copy_to_content_block: true
    };
};

type Format = {
    selector?: string,
    inline?: 'span' | 'a' | 'linked-value',

    classes?: string,
    styles?: Object,
    attributes?: string[],

    links?: true,
    remove_similar?: true,
    clear_child_styles?: true,
    remove_styles?: string[],
    copy_to_content_block?: 'LI' | 'P' | true,

    remove?: string,
    toggle?: boolean,
    expand?: boolean,
    block_expand?: boolean,
    split?: boolean,
    exact?: boolean,
    deep?: boolean
};

type Formats = Record<string, Format | Array<Format>>;

const formats: Formats = {
    // @ts-ignore
    textnormal: getGlobalStyle('textnormal'),
    // @ts-ignore
    textheading1: getGlobalStyle('textheading1'),
    // @ts-ignore
    textheading2: getGlobalStyle('textheading2'),
    // @ts-ignore
    textheading3: getGlobalStyle('textheading3'),

    lineHeight: {
        selector: 'p,li',
        styles: { 'line-height': '%value' }
    },
    characterSpacing: {
        inline: 'span',
        styles: { 'letter-spacing': '%value' },
        copy_to_content_block: 'LI'
    },

    bold: {
        inline: 'span',
        styles: { 'font-weight': 'bold' },
        clear_child_styles: true,
        copy_to_content_block: 'LI'
    },
    // Required to force normal if globalstyle has bold
    not_bold: {
        inline: 'span',
        styles: { 'font-weight': 'normal' },
        clear_child_styles: true,
        copy_to_content_block: 'LI'
    },

    italic: {
        inline: 'span',
        styles: { 'font-style': 'italic' },
        clear_child_styles: true,
        copy_to_content_block: 'LI'
    },
    // Required to force normal if globalstyle has italic
    not_italic: {
        inline: 'span',
        styles: { 'font-style': 'normal' },
        clear_child_styles: true,
        copy_to_content_block: 'LI'
    },

    underline: [{
        inline: 'span',
        styles: { textDecoration: 'underline' },
        exact: true
    }, {
        inline: 'a',
        styles: { textDecoration: 'underline' },
        exact: true
    }],

    textshadow: {
        inline: 'span',
        styles: { 'text-shadow': '%value' },
        links: true,
        remove_similar: true,
        clear_child_styles: true
    },
    color: {
        inline: 'span',
        styles: { color: '%value' },
        links: true,
        remove_similar: true,
        clear_child_styles: true,
        copy_to_content_block: 'LI'
    },
    hilitecolor: {
        inline: 'span',
        styles: { 'background-color': '%value' },
        links: true,
        remove_similar: true,
        clear_child_styles: true
    },

    fontSize: {
        inline: 'span',
        styles: { 'font-size': '%value' },
        toggle: false,
        remove_similar: true,
        clear_child_styles: true,
        copy_to_content_block: true
    },
    fontFamily: {
        inline: 'span',
        styles: { 'font-family': '%value' },
        toggle: false,
        remove_similar: true,
        clear_child_styles: true,
        copy_to_content_block: 'LI'
    },

    removeformat: [
        {
            selector: 'b,strong,em,i,font,u,strike,sub,sup,dfn,code,samp,kbd,var,cite,mark,q,del,ins',
            remove: 'all',
            split: true,
            expand: false,
            block_expand: true,
            deep: true
        },
        {
            selector: 'span',
            attributes: ['style'],
            remove: 'empty',
            split: true,
            expand: false,
            deep: true
        },
        {
            selector: '*',
            attributes: ['style'],
            split: false,
            expand: false,
            deep: true
        }
    ]
};

export const listItemFromats: string[] = ['line-height'];

export type FormatKeys = $Keys<typeof formats>;
export type getEditorConfigType = (
    arg0: {
        iframeMode?: boolean;
        onUndo?: () => void;
        onRedo?: () => void;
        onCtrlShiftA?: () => void;
        onCtrlShiftE?: () => void;
        onCtrlShiftP?: () => void;
        onCtrlShiftW?: () => void;
        validElements?: string;
    } | void,
) => any;

const
    validElementsThatAreAllowedInEditor = (() => {
        // https://www.tiny.cloud/docs/configure/content-filtering/#valid_elements
        const
            allowedClassNames = ['textnormal', 'textheading1', 'textheading2', 'textheading3'],
            a = `a[class|style|href|target]`,
            pAndSpanAllowedAttributes = `[class<${allowedClassNames.join('?')}|style]`,
            span = `span${pAndSpanAllowedAttributes}`,
            div = `div[class|style]`, // used to store wapped components containers
            p = `p${pAndSpanAllowedAttributes}`,
            ol = `ol[style]`,
            ul = `ul[style]`,
            li = `li[class|style]`,
            linkedValue = `${linkedValueTag}[${linkedValueAttr}]`,
            elementsSchemes = [a, span, div, p, ol, ul, li, 'br', 'sup', 'sub', linkedValue];

        return elementsSchemes.join(',');
    })(),
    validElementsThatAreRequiredForEditorToRetainFormattingOnReplacingText =
        validElementsThatAreAllowedInEditor + ',font[*],b,i,u',
    getEditorConfig: getEditorConfigType = ({
        iframeMode = false, onUndo = noop, onRedo = noop,
        onCtrlShiftA = noop, onCtrlShiftE = noop, onCtrlShiftP = noop, onCtrlShiftW = noop,
        validElements = validElementsThatAreRequiredForEditorToRetainFormattingOnReplacingText,
    } = {}) => {
        return {
            browser_spellcheck: true,
            plugins: 'link image table advlist colorpicker lists paste noneditable',
            menubar: false,
            statusbar: false,
            autoresize_bottom_margin: 0,
            table_grid: false,
            toolbar: false,
            min_height: 80,
            inline: !iframeMode,
            indentation: '40px',
            skin: false,
            nonbreaking_force_tab: true,
            formats,
            forced_root_block: ROOT_BLOCK_TAG,
            forced_root_block_attrs: ROOT_BLOCK_ATTRS,
            entity_encoding: 'raw',
            valid_elements: validElements,
            extended_valid_elements: `${linkedValueTag}[${linkedValueAttr}]`,
            custom_elements: `~${linkedValueTag}`,
            valid_children: `${linkedValueTag}[#text]`,
            remove_trailing_brs: false,
            relative_urls: false,
            // URLs will be returned in this format: 'http://example.com/dir/file.htm' instead of the default format: '/dir/file.htm'
            remove_script_host: false,
            allow_script_urls: true,
            init_instance_callback: (editor: TinyMceEditor) => {
                editor.shortcuts.add('meta+z', 'Undo', onUndo);
                editor.shortcuts.add('meta+y,meta+shift+z', 'Redo', onRedo);
                editor.shortcuts.add('ctrl+shift+a', 'CtrlShiftA', onCtrlShiftA);
                editor.shortcuts.add('ctrl+shift+e', 'CtrlShiftE', onCtrlShiftE);
                editor.shortcuts.add('ctrl+shift+p', 'CtrlShiftP', onCtrlShiftP);
                editor.shortcuts.add('ctrl+shift+w', 'CtrlShiftW', onCtrlShiftW);
            },
            paste_preprocess: (_: any, event: any) => {
                // args.wordContent lets us know that the pasted content came from a Word doc
                // We can trust that it's not component data
                if (event.wordContent) {
                    event.content = normalizeHtmlAccordingToTinyMCEHtmlSchema(event.content); // eslint-disable-line no-param-reassign
                    return;
                }

                // eslint-disable-next-line no-param-reassign
                event.content = removeInvalidURLfromContent(event.content);

                // Don't paste component data into editor
                // TinyMCE already uses getData('text/html') on clipboardData so we have to clean that up to
                // determine if it's component data
                const pastedContentWithHtmlStripped = event.content
                    .replace(/^<!DOCTYPE html>/, '') // For safari
                    .replace(/^<meta(?:(['"]).*?[^\\]\1|[^>])*?>/, '')  //NOSONAR
                    .replace(/^<span(?:(['"]).*?[^\\]\1|[^>])*?>/, '')  //NOSONAR
                    .replace(/<\/span>$/, '');

                let plaintext;
                try {
                    JSON.parse(pastedContentWithHtmlStripped);
                    plaintext = false;
                } catch (e) {
                    plaintext = true;
                }

                if (!plaintext) {
                    event.preventDefault();
                    return;
                }

                if (!event.internal) {
                    event.content = normalizeHtmlAccordingToTinyMCEHtmlSchema(event.content); // eslint-disable-line no-param-reassign
                } else {
                    event.content = stripWrappedComponents(event.content); // eslint-disable-line no-param-reassign
                }
            }
        };
    };

const
    editorConfig = getEditorConfig({ validElements: validElementsThatAreAllowedInEditor }),
    tinyMceHtmlSchema = new tinymce.html.Schema(editorConfig),
    tinyMceDomParser = new tinymce.html.DomParser(editorConfig, tinyMceHtmlSchema),
    // $FlowFixMe: WBTGEN-9970: Serializer constructor is not mentioned to accept null in the docs
    tinyMceHtmlSerializer = new tinymce.html.Serializer(null, tinyMceHtmlSchema);

export {
    getEditorConfig,
    tinyMceDomParser,
    tinyMceHtmlSchema,
    tinyMceHtmlSerializer,
};
