export const
    FileNameConflictDialog = "FileNameConflictDialog",
    UploadRenameFileDialog = "UploadRenameFileDialog",
    ImageSizeErrorDialogId = "ImageSizeErrorDialog",
    SvgSizeErrorDialogId = "SvgSizeErrorDialog",
    ExternalUrlUploadDialog = "ExternalUrlUploadDialog",
    FailedErrorDialog = "FailedErrorDialog",
    InvalidErrorDialogId = "InvalidErrorDialog",
    MaxMultiSelectWarningDialog = "MaxMultiSelectWarningDialog",
    UnsupportedErrorDialogId = "UnsupportedErrorDialog",
    MultipleErrorsDialog = "MultipleErrorsDialog",
    MetadataFetchFailedErrorDialog = "MetadataFetchFailedErrorDialog",
    FileChooserDeleteConfirmationDialog = "FileChooserDeleteConfirmationDialog",
    FileChooserDeleteErrorDialog = "FileChooserDeleteErrorDialog",
    InsufficientStorageErrorDialog = "InsufficientStorageErrorDialog";

