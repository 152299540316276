import ctaFactory from './ctaFactory';
import styles from './ComponentMainActions.css';
import type { MCTAEditViewParams } from './flowTypes';

const defaultEditCTAText = 'msg: component.common.settings {Settings}';

export default (params: MCTAEditViewParams<React.ComponentType<any>>): React.JSX.Element => ctaFactory({
    className: styles.editButton,
    buttonText: (params.getEditButtonText && params.getEditButtonText(params))
        || params.editButtonText
        || defaultEditCTAText,
    ...params
});
