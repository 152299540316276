import { BUSINESS_LISTING_SITE_SETTINGS_EPIC_VALUE } from './businessListingSiteSettingsVAT';
import { makeSiteSettingsChildEpic } from "../../../App/epics/siteSettings/makeSiteSettingsChildEpic";

const businessListingSiteSettingsEpic = makeSiteSettingsChildEpic({
    defaultState: {},
    valueActionType: BUSINESS_LISTING_SITE_SETTINGS_EPIC_VALUE,
    key: 'businessListingData',
    undo: true,
    updaters: []
});

export {
    businessListingSiteSettingsEpic,
};
