import React from "react";
import cx from "classnames";
import { Flex } from "../../reflexbox/index";
import InputField from "./InputField";
import styles from "./InputField.css";
import type { PrefixedInputFieldPropTypes } from "./flowTypes";

export default ({ className, prefixText, Icon, isValid, ...rest }: PrefixedInputFieldPropTypes) => (
    <Flex align="center" className={cx(styles.prefixedField)}>
        <div className={cx({ [styles.prefix]: true, [styles.invalidInput]: !isValid })}>{prefixText}</div>
        <InputField {...rest} className={cx(styles.input, className)} />
        {/* @ts-ignore */}
        {Icon && <Icon className={styles.iconSuffix} />}
    </Flex>
);
