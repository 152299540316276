/* Memoization should not be used in this file since this is used in server */

import * as R from "ramda";
import type { TransformComponentType } from "../flowTypes";
import {
    isStretchComponentKindOrServerStretch,
    isStripComponentKindOrServerType,
    isSectionComponentKind
} from "../../oneweb/isStretchComponentKind";

function intersectingComponents(currentComponent, componentsMap): Array<TransformComponentType> {
    let
        belowComponents: any[] = [],
        stripComponents: any[] = [],
        sectionComponents: any[] = [],
        intersectingCmps: any[] = [];

    const
        { orderIndex, x1: Ax1, x2: Ax2, y1: Ay1, y2: Ay2 } = currentComponent;

    /* As of now there cannot be any below component for SECTION */
    if (!isSectionComponentKind({ kind: currentComponent.kind })) {
        /* Strip / Section needs to be added irrespective of the order index. Strip / Section is treated always as below. */
        belowComponents = componentsMap.filter(component => (
            component.orderIndex < orderIndex ||
            isStretchComponentKindOrServerStretch({ kind: component.kind, stretch: component.stretch })
        ));
        if (currentComponent.onHoverProp) {
            const cmpOnHoverShow = currentComponent.onHoverShow;
            belowComponents = belowComponents.filter(({ onHoverProp, onHoverShow }) => (
                !onHoverProp || onHoverShow === cmpOnHoverShow
            ));
        }
    }

    intersectingCmps = belowComponents.filter(belowComponent => {
        const { x1: Bx1, x2: Bx2, y1: By1, y2: By2 } = belowComponent;

        if (isIntersecting({ Ax1, Ay1, Ax2, Ay2 }, { Bx1, By1, Bx2, By2 })) {
            return true;
        }

        if (isStretchComponentKindOrServerStretch({ kind: belowComponent.kind, stretch: belowComponent.stretch }) &&
            isIntersecting({ Ax1, Ay1, Ax2, Ay2 }, { Bx1: Ax1, By1, Bx2: Ax2, By2 })
        ) {
            return true;
        }

        return false;
    });
    intersectingCmps.sort((a, b) => b.orderIndex - a.orderIndex);

    // STRIP / SECTION is special case having highest orderIndex being in background, so move it in last
    stripComponents = intersectingCmps.filter(({ kind, stretch }) => isStripComponentKindOrServerType({ kind, stretch }));
    sectionComponents = intersectingCmps.filter(({ kind }) => isSectionComponentKind({ kind }));

    intersectingCmps = intersectingCmps.filter(({ kind, stretch }) => !isStretchComponentKindOrServerStretch({ kind, stretch }));
    /* currentComponent is added for the sake of overlapping with template.  */
    intersectingCmps = [...intersectingCmps, ...stripComponents, ...sectionComponents, currentComponent];

    return intersectingCmps;
}

function isIntersecting({ Ax1, Ay1, Ax2, Ay2 }, { Bx1, By1, Bx2, By2 }) {
    const
        aLeftOfB = Ax2 < Bx1,
        aRightOfB = Ax1 > Bx2,
        aAboveB = Ay1 > By2,
        aBelowB = Ay2 < By1;

    return !(aLeftOfB || aRightOfB || aAboveB || aBelowB);
}

function fixXValuesWithRespectToComponent(
    cmp: TransformComponentType,
    componentsMap: Array<TransformComponentType>
): Array<TransformComponentType> {
    /* stretch is need for server side components. */
    return R.map(({ id, kind, x1, x2, y1, y2, orderIndex, selectedTheme, stretch, onHoverProp, onHoverShow }) => ({
        id,
        kind,
        x1: isStretchComponentKindOrServerStretch({ kind, stretch }) ? cmp.x1 : x1,
        x2: isStretchComponentKindOrServerStretch({ kind, stretch }) ? cmp.x2 : x2,
        y1,
        y2,
        orderIndex,
        selectedTheme,
        stretch,
        onHoverProp,
        onHoverShow,
    }))(componentsMap);
}
function getParentThemeComponent(
    cmp: TransformComponentType,
    componentsMap: Array<TransformComponentType>
): null | undefined | TransformComponentType {
    const
        maxOverlapped: {percent: number, component: null | undefined | TransformComponentType} = {
            percent: 0,
            component: null
        },
        commonSections: any[] = [],
        cmpMapsWithXValueCorrected = fixXValuesWithRespectToComponent(cmp, componentsMap),
        intersectingCmps = intersectingComponents(cmp, cmpMapsWithXValueCorrected);

    if (intersectingCmps.length) {
        const cmpArea = getComponentArea(cmp);
        let
            overlappedArea,
            overlappedPercentage,
            area;

        intersectingCmps.some(intersectingCmp => {
            overlappedArea = getOverlappedArea(cmp, intersectingCmp);

            overlappedPercentage = (overlappedArea * 100) / cmpArea;

            // if 1st component is covering good enough
            if (maxOverlapped.percent === 0 && overlappedPercentage >= 50) {
                maxOverlapped.component = intersectingCmp;
                return true;
            }

            if (overlappedPercentage > maxOverlapped.percent) {
                area = 0;

                // Add up already covered area by other components
                for (let i = 0; i < commonSections.length; i++) {
                    area += getOverlappedArea(commonSections[i], intersectingCmp);

                    // Remove common areas
                    for (let j = i + 1; j < commonSections.length; j++) {
                        area = area - getOverlappedArea(commonSections[i], commonSections[j]);
                    }
                }

                overlappedArea = overlappedArea - area;
                overlappedPercentage = (overlappedArea * 100) / cmpArea;

                if (overlappedPercentage > maxOverlapped.percent) {
                    maxOverlapped.component = intersectingCmp;
                    maxOverlapped.percent = overlappedPercentage;
                }
            }

            commonSections.push(getCommonAreaCoordinates(cmp, intersectingCmp));

            return false;
        });
    }
    /* Cmp is overlapping more with template if overlapped component is same as cmp.*/
    return maxOverlapped.component !== cmp ? maxOverlapped.component : null;
}

function getCommonAreaCoordinates(cmp1, cmp2) {
    return {
        x1: Math.max(cmp1.x1, cmp2.x1),
        y1: Math.max(cmp1.y1, cmp2.y1),
        x2: Math.min(cmp1.x2, cmp2.x2),
        y2: Math.min(cmp1.y2, cmp2.y2)
    };
}

function getOverlappedArea(cmp1, cmp2) {
    const
        length = Math.max(0, Math.min(cmp1.x2, cmp2.x2) - Math.max(cmp1.x1, cmp2.x1)),
        width = Math.max(0, Math.min(cmp1.y2, cmp2.y2) - Math.max(cmp1.y1, cmp2.y1));

    return length * width;
}

function getComponentArea(component) {
    const
        length = Math.abs(component.x2 - component.x1),
        width = Math.abs(component.y2 - component.y1);

    return length * width;
}

export default getParentThemeComponent;
