import { equals, findIndex } from "ramda";
import React, { MouseEventHandler, ReactNode, useState } from 'react';
import { useSelector } from "react-redux";
import cx from "classnames";
import { Msg } from "../../view/intl/index";
import styles from "./ThemesAccordion.css";
import { toCss, isDark } from "../../mappers/color";
import injectIntl, { Intl } from "../../view/intl/injectIntl";
import { blackColorValueHSL, whiteColorValueHSL } from "../ThemeGlobalData/constants";
import ICONS, { customizedIcon } from "../../view/Icons/index";
import { CATEGORIES_INPUT, makeColorCategoryFromConceptPallete } from './constants';
import { conceptSelector } from "../Onboarding/Dynamic/Epic/selectors";
import type { CategoryType, MiniPaletteHsl, FullPaletteHsl } from './flowTypes';
import { CurrentThemeColors } from "../AutoColorLeftPanel/screens/Components/CurrentThemeColors";
import VerticalSpacer from "../../view/common/VerticalSpacer";
import { isNextGenPreviewSelector } from "../TemplateSelector_DEPRECATED/epics/preview/selector";

const CheckmarkIcon = customizedIcon(ICONS.CHECKMARK, { style: { height: "16px", width: "15px", fill: "#0078C8" } });
const CheckmarkLightIcon = customizedIcon(ICONS.CHECKMARK, { style: { height: "16px", width: "15px", fill: "rgba(0, 120, 200, 0.4)" } });

type Props = {
    disabled?: boolean,
    currentPalette: FullPaletteHsl,
    onPaletteSelected: (palette: FullPaletteHsl, key: string, index: number) => void,
    intl: Intl,
    titleClassname?: string,
    titleComponent?: ReactNode,
    categoryContainerClass?: string,
    categoryTitleClass?: string,
    categories?: Array<CategoryType<MiniPaletteHsl>>,
    selectedColorPickerTheme?: string,
    changeColorPickerThemeMethodActionType?: string;
};

type PalleteAccordianProps = Omit<Props, 'categories'> & {
    categories: Array<CategoryType<FullPaletteHsl>>,
    isNextGenPreview: boolean,
    selectedCategory: any,
    changeSelectedCategory: (v: any) => any;
};

type PalleteListProps = {
    disabled?: boolean;
    categoryKey?: string;
    palettes: Array<FullPaletteHsl>;
    selectedPaletteIndex: number;
    onPaletteSelected: Function;
    paletteColorStyles?: string;
    onPaletteColorSelected?: Function;
};

type SinglePaletteColorElementViewProps = {
    disabled?: boolean,
    palette: FullPaletteHsl,
    paletteColorStyles?: Object,
    onPaletteColorSelected?: Function
};

type PaletteSelectorProps = SinglePaletteColorElementViewProps & {
    intl: Intl,
    isSelected: boolean,
    onSelectBtnClick: MouseEventHandler<HTMLDivElement>,
};

export const SinglePaletteColorElementView = ({
    disabled,
    palette,
    paletteColorStyles = "",
    onPaletteColorSelected
}: SinglePaletteColorElementViewProps) => {
    return (
        <React.Fragment>
            {palette.map((color, index) => (
                <div // TODO WBTGEN-17202 create generic component for this
                    key={index}
                    className={cx(styles.paletteColor, paletteColorStyles, {
                        [styles.dark]: isDark(color),
                    })}
                    style={{ backgroundColor: toCss(color) }}
                    onClick={() => {
                        if (!disabled && onPaletteColorSelected) {
                            return onPaletteColorSelected({ color, index });
                        }
                        return null;
                    }}
                />
            ))}
        </React.Fragment>
    );
};

const PaletteSelector = injectIntl(
    ({ palette, disabled, isSelected, onSelectBtnClick, paletteColorStyles, onPaletteColorSelected }: PaletteSelectorProps) => {
        return (
            <div
                className={cx(styles.paletteSelectorContainer, {
                    [styles.selectedPaletteSelector]: isSelected,
                    [styles.disabledPalette]: disabled
                })}
                onClick={disabled ? undefined : onSelectBtnClick}
            >
                <SinglePaletteColorElementView
                    disabled={disabled}
                    palette={palette}
                    paletteColorStyles={paletteColorStyles}
                    onPaletteColorSelected={onPaletteColorSelected}
                />
                {isSelected ? (
                    <div className={cx(styles.checkMark)}>
                        <CheckmarkIcon />
                    </div>
                ) : (
                    <div className={cx(styles.checkMark, styles.selectIt)}>
                        <CheckmarkLightIcon />
                    </div>
                )}
            </div>
        );
    }
);

export const PaletteList = ({
    disabled,
    categoryKey,
    palettes,
    selectedPaletteIndex = -1,
    onPaletteSelected,
    paletteColorStyles,
    onPaletteColorSelected
}: PalleteListProps) => {
    return (
        <ul className={styles.paletteList}>
            {palettes.map((palette: FullPaletteHsl, index) => (
                <li key={index}>
                    <PaletteSelector
                        palette={palette}
                        disabled={disabled}
                        isSelected={index === selectedPaletteIndex}
                        onSelectBtnClick={() => onPaletteSelected(palette, categoryKey, index)}
                        paletteColorStyles={paletteColorStyles}
                        onPaletteColorSelected={onPaletteColorSelected}
                    />
                </li>
            ))}
        </ul>
    );
};

export const Accordion = (props : PalleteAccordianProps) => {
    const {
        disabled,
        categoryContainerClass,
        categoryTitleClass,
        currentPalette,
        categories,
        onPaletteSelected,
        selectedCategory,
        changeSelectedCategory,
        isNextGenPreview
    } = props;

    return (
        <React.Fragment>
            {categories.map(category => (
                <div key={category.key}>
                    {category.title && (
                        <div
                            className={cx(styles.categoryContainer, categoryContainerClass, {
                                [styles.noMargin]: isNextGenPreview,
                                [styles.disabledPalette]: disabled
                            })}
                            onClick={disabled ? undefined : () => changeSelectedCategory(category.key)}
                        >
                            <span className={cx(styles.categoryTitle, categoryTitleClass, isNextGenPreview && styles.categoryTitleLarge)}>
                                {props.intl.msgJoint(category.title)}
                            </span>
                            {selectedCategory === category.key ? (
                                <ICONS.EXPAND_LESS className={styles.categoryIcon} />
                            ) : (
                                <ICONS.EXPAND_MORE className={styles.categoryIcon} />
                            )}
                        </div>
                    )}
                    {selectedCategory === category.key || category.key === "concept" ? (
                        <PaletteList
                            disabled={disabled}
                            categoryKey={category.key}
                            palettes={category.palettes}
                            onPaletteSelected={onPaletteSelected}
                            selectedPaletteIndex={findIndex(equals(currentPalette), category.palettes)}
                        />
                    ) : null}
                </div>
            ))}
        </React.Fragment>
    );
};

const CustomThemeColorsAccordion = (props) => {
    const {
        disabled,
        currentPalette,
        changeColorPickerThemeMethodActionType,
        selectedCategory,
        changeSelectedCategory,
        categoryContainerClass,
        categoryTitleClass,
        isNextGenPreview
    } = props;

    return (
        <div>
            <div
                className={cx(styles.categoryContainer, categoryContainerClass, {
                    [styles.noMargin]: isNextGenPreview,
                    [styles.disabledPalette]: disabled,
                })}
                onClick={disabled ? undefined : () => changeSelectedCategory("custom")}
            >
                <span className={cx(styles.categoryTitle, styles.categoryTitleLarge, categoryTitleClass)}>
                    {props.intl.msgJoint("msg: imageEditor.asset.imglyTransformsCommon.custom {Custom}")}
                </span>
                {selectedCategory === "custom" ? (
                    <ICONS.EXPAND_LESS className={styles.categoryIcon} />
                ) : (
                    <ICONS.EXPAND_MORE className={styles.categoryIcon} />
                )}
            </div>
            <VerticalSpacer y={8} />
            {selectedCategory === "custom" && (
                <CurrentThemeColors themeColors={currentPalette} turnOnEditModeActionType={changeColorPickerThemeMethodActionType} />
            )}
        </div>
    );
};

export const transformCategories = (categories: Array<CategoryType<MiniPaletteHsl>>): Array<CategoryType<FullPaletteHsl>> => {
    return categories.map((ci, idx) => {
        const { key = String(idx), title, palettes } = ci;

        return {
            key,
            title,
            palettes: palettes.map((p) => [...p, whiteColorValueHSL, blackColorValueHSL])
        };
    });
};

export const ThemesAccordion = injectIntl((props: Props) => {
    const concept = useSelector(conceptSelector);
    const isNextGenPreview = useSelector(isNextGenPreviewSelector);
    const isConceptThemesFound = concept?.colorThemes;

    let categoriesFromConcept;
    let transformedCategoriesFromConcept;

    const [showOtherColours, setShowOtherColours] = useState(false);

    const {
        disabled,
        currentPalette,
        titleClassname,
        titleComponent,
        categories = CATEGORIES_INPUT,
        changeColorPickerThemeMethodActionType
    } = props;

    const transformedCategories = transformCategories(categories);

    if (isConceptThemesFound && isNextGenPreview) {
        categoriesFromConcept = makeColorCategoryFromConceptPallete(concept.colorThemes);
        transformedCategoriesFromConcept = transformCategories(categoriesFromConcept);
    }

    const categoriesToFindCategoryKey = isNextGenPreview ? transformedCategoriesFromConcept : transformedCategories;

    let categoryKey = categoriesToFindCategoryKey[0].key;

    if (isNextGenPreview) {
        transformedCategoriesFromConcept.forEach(category => {
            let paletteIndex = findIndex(equals(currentPalette), category.palettes);
            if (paletteIndex > -1) {
                categoryKey = category.key;
                return;
            }
        });
    }

    transformedCategories.forEach(category => {
        let paletteIndex = findIndex(equals(currentPalette), category.palettes);
        if (paletteIndex > -1) {
            categoryKey = category.key;
            return;
        }
    });

    let [selectedCategory, setSelectedCategory] = useState(categoryKey);
    const changeSelectedCategory = key => {
        let updatedKey = key;
        if (key === selectedCategory) {
            updatedKey = null;
        }
        setSelectedCategory(updatedKey);
    };

    const AccordionProps = {
        ...props,
        selectedCategory,
        changeSelectedCategory,
        isNextGenPreview
    };

    return (
        <React.Fragment>
            {isNextGenPreview ? (
                <React.Fragment>
                    <Accordion
                        {...AccordionProps}
                        categories={transformedCategoriesFromConcept}
                    />
                    <div className={styles.colorPickerOnboardingThemingSeparaterLine} />
                </React.Fragment>
            ) : null}
            {isNextGenPreview ? (
                <div
                    className={cx(styles.title, styles.titleLarge, titleClassname, {
                        [styles.disabledPalette]: disabled
                    })}
                    onClick={() => {
                        if (disabled) return;
                        setShowOtherColours(!showOtherColours);
                        changeSelectedCategory(transformedCategories[0].key);
                    }}
                >
                    <Msg k="themeAccordion.otherColoursTitle">Other colours</Msg>
                    {showOtherColours ? (
                        <ICONS.EXPAND_LESS className={styles.categoryIcon} />
                    ) : (
                        <ICONS.EXPAND_MORE className={styles.categoryIcon} />
                    )}
                </div>
            ) : (
                <div className={cx(styles.title, titleClassname)}>
                    {
                        titleComponent ||
                        <Msg k="themeAccordion.popularThemesTitle">
                            Popular Colour themes
                        </Msg>
                    }
                </div>
            )}
            <div className={isNextGenPreview && styles.accordionContainer}>
                {
                    !isNextGenPreview || showOtherColours ?
                        <Accordion
                            {...AccordionProps}
                            categories={transformedCategories}
                        /> :
                        null
                }
                {
                    isNextGenPreview && changeColorPickerThemeMethodActionType && showOtherColours ?
                        <CustomThemeColorsAccordion
                            {
                                ...AccordionProps
                            }
                            themeColors={currentPalette}
                            turnOnEditModeActionType={changeColorPickerThemeMethodActionType}
                        /> :
                        null
                }
            </div>
        </React.Fragment>
    );
});
