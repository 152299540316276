import * as R from 'ramda';
import { withSelector } from "../../../../epics/makeCondition";
import { topMostHandleVAT } from "./valueActionType";

import type { Handle } from "../../../../redux/modules/children/workspace/flowTypes";

const
    topmostHandleKindSelector = (topMostHandle: Handle | null) => R.path(['kind'], topMostHandle) || null,
    TopmostHandleKindSelector = withSelector(topMostHandleVAT, topmostHandleKindSelector);

export {
    TopmostHandleKindSelector
};
