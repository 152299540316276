/* eslint-disable max-len */

import React, { useState } from 'react';

import View from './index';
import { ComponentMaskBackground } from '../../../presentational/ComponentMask/constants';
import ComponentMask from '../../../presentational/ComponentMask';
import BaseComponent from '../../../../view/oneweb/BaseComponent';
import { Banner } from './banner';
import type { Props } from '../flowTypes';
import { useIntl } from "../../../../view/intl";
import * as styles from '../FacebookFeedGallery.css';
import localStorage from '../../../../utils/localStorage';

const HideFacebookSdkLoadingFailedBanner = "hideFacebookSdkLoadingFailedBanner";

export default (props: Props) => {
    const intl = useIntl();

    const isSdkLoaded = Boolean(window.FB);
    const isSelected = props.inEditMode || props.inSelectedMode;

    const hideBannerConfig = Boolean(localStorage.get(HideFacebookSdkLoadingFailedBanner));
    const [hideErrorBanner, setHideErrorBanner] = useState(hideBannerConfig);

    const showSdkBanner = !isSdkLoaded && isSelected && !props.isServerPreview && !hideErrorBanner;

    const handleClose = () => {
        setHideErrorBanner(true);
        localStorage.set(HideFacebookSdkLoadingFailedBanner, true);
    };

    return (
        <BaseComponent {...props}>
            {showSdkBanner && (
                <Banner
                    iconClass={styles.bannerCloseIcon}
                    title={intl.msgJoint("msg: messengerChat.banner.sdkLoadingFailed.title {Login to Facebook blocked}")}
                    description={intl.msgJoint("msg: messengerChat.banner.sdkLoadingFailed.description {If you are using a privacy extension on your browser, this may be blocking the window to sign in to Facebook. Disable your privacy extension and try again.}")}
                    onClose={handleClose}
                />
            )}

            <ComponentMask
                showPreviewOnlyLayer={false}
                style={{ height: props.base.height }}
                maskStyle={ComponentMaskBackground.TRANSPARENT}
                inEditMode={props.inEditMode}
                inSelectedMode={props.inSelectedMode}
                componentTierData={props.componentTierData}
                componentWidth={props.width}
            >
                {/* $FlowFixMe */}
                {React.createElement(View, { ...props, isWorkspace: true })}
            </ComponentMask>
        </BaseComponent>
    );
};
