/* eslint-disable max-len */
import {
    calcEditYourSiteTipStyles,
    calcAddingContentTipStyles,
    calcManagePagesTipsStyles,
    calcSaveTipStyles,
    calcChangingColorsTipStyles,
    calcAddSectionStyles,
    calcMoveSectionStyles,
    calcDuplicateSectionStyles,
    calcDeleteSectionStyles,
    calcSplitSectionStyles,
    calcvideoTutorialTipStyles
} from './epics/quickTour/calcTipStyles';
import * as quickTourStyles from './view/QuickTour.css';
import * as styles from './view/TipContent/TipContent.css';
import type { CalcTipStyleProps } from './flowTypes';

export const
    EditYourWebsite = 'EditYourWebsite',
    AddingContent = 'AddingContent',
    ManageYourPages = 'ManageYourPages',
    SavingYourSite = 'SavingYourSite',
    AddSection = 'AddSection',
    MoveSection = 'MoveSection',
    DuplicateSection = 'DuplicateSection',
    DeleteSection = 'DeleteSection',
    SplitSection = 'SplitSection',
    ChangingColors = 'ChangingColors',
    VideoTutorial = 'VideoTutorial',
    FULL_TOUR = 'FULL_TOUR',
    FULL_TOUR_WITH_TUTORIAL = 'FULL_TOUR_WITH_TUTORIAL',
    SECTIONS_TOUR = 'SECTIONS_TOUR',
    TipsSequence = {
        [FULL_TOUR]: [
            EditYourWebsite,
            AddingContent,
            ManageYourPages,
            SavingYourSite,
            ChangingColors,
            VideoTutorial
        ],
        [SECTIONS_TOUR]: [
            AddSection,
            MoveSection,
            DuplicateSection,
            DeleteSection,
            SplitSection,
        ],
    },
    TipsData = {
        [EditYourWebsite]: {
            title: 'msg: tips.editYourWebsite.title {Edit your website}',
            subTitle: 'msg: tips.editYourWebsite.subTitle {Click & drag}',
            text: 'msg: tips.editYourWebsite.text {Click on components to edit, drag or resize them. Right click to arrange components above or below each other.}',
            classNames: { pointer: styles.pointerRight, spotLight: quickTourStyles.editYouWebsite, container: styles.editYouWebsite },
            calcTipStyles: calcEditYourSiteTipStyles,
        },
        [AddingContent]: {
            title: 'msg: tips.addingContent.title {Adding content}',
            subTitle: 'msg: tips.addingContent.subTitle {Build with components}',
            text: 'msg: tips.addingContent.text {To add new content, simply drag a component into your website.}',
            classNames: { pointer: styles.pointerLeft, spotLight: quickTourStyles.addingContent, container: styles.addingContent, image: styles.addingContentImage },
            calcTipStyles: calcAddingContentTipStyles,
        },
        [ManageYourPages]: {
            title: 'msg: tips.manageYourPages.title {Manage your pages}',
            subTitle: 'msg: tips.manageYourPages.subTitle {Create and switch pages}',
            text: 'msg: tips.manageYourPages.text {Click on the page tab to switch to another page that you want to edit. You can also add, move and delete your pages here.}',
            classNames: { pointer: styles.pointerTop, spotLight: quickTourStyles.manageYourPages, container: styles.manageYourPages },
            calcTipStyles: calcManagePagesTipsStyles,
        },
        [SavingYourSite]: {
            title: 'msg: tips.savingYourSite.title {Saving your site}',
            subTitle: 'msg: tips.savingYourSite.subTitle {Save, preview and publish}',
            text: 'msg: tips.savingYourSite.text {Always make sure to save your hard work! By clicking \"Preview\" you see how your website looks to visitors. Once you are ready, press \"Publish\" to go live.}',
            classNames: { pointer: styles.pointerTop, spotLight: quickTourStyles.savingYourSite, container: styles.savingYourSite },
            calcTipStyles: calcSaveTipStyles,
        },
        [ChangingColors]: {
            title: 'msg: tips.changingColors.title {Changing Colours}',
            text: 'msg: tips.changingColors.text {To edit the colour theme for your website, click here. Then select a colour theme from the sidebar or define your own by clicking the pencil-icon.}',
            classNames: { pointer: styles.pointerRight, spotLight: quickTourStyles.changingColors, container: styles.changingColors, image: styles.changingColorsImage },
            calcTipStyles: calcChangingColorsTipStyles,
        },
        [VideoTutorial]: {
            title: 'msg: common.videoTutorial {Video Tutorial}',
            text: 'msg: tutorialVideoIntroPopup.msg {Watch our step-by-step video tutorial on how to use Website Builder and edit your website at the same time.}',
            classNames: { pointer: styles.pointerTop, spotLight: quickTourStyles.videoTutorial, container: styles.videoTutorial },
            calcTipStyles: calcvideoTutorialTipStyles,
        },
        [AddSection]: {
            title: 'msg: tips.addingASection.title {Adding a section}',
            text: 'msg: tips.addingASection.text {To add a new section, click on the plus at the top or bottom, depending on where you want to place it.}',
            classNames: { pointer: styles.pointerTop, spotLight: quickTourStyles.addingASection, container: styles.addingASection, image: styles.addingASectionImage },
            calcTipStyles: calcAddSectionStyles,
        },
        [MoveSection]: {
            title: 'msg: tips.movingASection.title {Moving a section}',
            text: 'msg: tips.movingASection.text {To move a section up or down on the page, click on these arrows.}',
            classNames: { pointer: styles.pointerLeft, spotLight: quickTourStyles.movingASection, container: styles.movingASection, image: styles.movingASectionImage },
            calcTipStyles: (calcTipStyleProps: CalcTipStyleProps) => calcMoveSectionStyles(calcTipStyleProps),
        },
        [DuplicateSection]: {
            title: 'msg: tips.duplicatingASection.title {Duplicating a section}',
            text: 'msg: tips.duplicatingASection.text {To duplicate a section, click on the duplicate icon.}',
            classNames: { pointer: styles.pointerLeft, spotLight: quickTourStyles.duplicatingASection, container: styles.duplicatingASection, image: styles.duplicatingASectionImage },
            calcTipStyles: (calcTipStyleProps: CalcTipStyleProps) => calcDuplicateSectionStyles(calcTipStyleProps),
        },
        [DeleteSection]: {
            title: 'msg: tips.deletingYourSite.title {Deleting a section}',
            text: 'msg: tips.deletingYourSite.text {To delete a section, click on the trash icon.}',
            classNames: { pointer: styles.pointerLeft, spotLight: quickTourStyles.deletingASection, container: styles.deletingASection, image: styles.deletingASectionImage },
            calcTipStyles: (calcTipStyleProps: CalcTipStyleProps) => calcDeleteSectionStyles(calcTipStyleProps),
        },
        [SplitSection]: {
            title: 'msg: tips.splittingYourSection.title {Splitting a Section}',
            text: 'msg: tips.splittingYourSection.text {To split a section, right-click on the Section, select \"Split section\" in the dropdown and then select where you would like to split it.}',
            classNames: { pointer: styles.pointerLeft, spotLight: quickTourStyles.splittingASection, container: styles.splittingASection, image: styles.splittingASectionImage },
            calcTipStyles: calcSplitSectionStyles,
        },
    },
    NextTip = 'msg: tips.nextTip.title {Next tip}',
    Previous = 'msg: tips.previous.title {Previous}',
    Tip = 'msg: tips.tip {Tip}',
    letsGo = 'msg: tips.letsGo {Let’s go!}',
    StartBuilding = 'msg: tips.startBuilding {Start building}',
    SkipTour = 'msg: tips.skipTour {Skip tour}',
    WatchVideo = 'msg: common.watchVideo {Watch Video}';
