/* eslint-disable max-len */

import React from 'react';
import { connect } from "react-redux";
import cx from 'classnames';
import styles from './styles.css';
import { Dialog, DialogBody, StripTypes } from "../../../../../view/common/dialogs/baseDialog/index";
import { injectIntl, Intl, Msg } from "../../../../../view/intl/index";
import {
    PrimaryButton,
    SecondaryButton,
} from '../../../../../view/common/Button/index';
import { replaceWithBusinessListTabDialog } from '../actionCreators';
import { disconnectGmbListing } from '../disconnectGmbListing';
import LoadingIndicator from "../../../../../view/common/LoadingIndicator/index";
import { makeEpicStateSelector } from '../../../../../epics/makeEpic';
import { BUSINESS_LISTING_SITE_SETTINGS_SERVICE_EPIC_VALUE } from '../businessListingSiteSettingsServiceVAT';

export type DisconnectListingDialogProps = {
    intl: Intl;
    dispatch: Dispatch;
    isWaitingForApi: boolean;
}

const DisconnectListingDialog = (props: DisconnectListingDialogProps) => {
    const { intl, dispatch, isWaitingForApi } = props;

    return (
        <Dialog stripType={StripTypes.NONE}>
            <DialogBody className={styles.dialogBody}>
                {
                    isWaitingForApi ?
                        <LoadingIndicator /> :
                        <React.Fragment>
                            <div className={styles.disconnectListingTitle}>
                                { intl.msgJoint("msg: gmb.disconnectListingTitle {Are you sure you want to disconnect your listing?}") }
                            </div>
                            <div className={styles.DisconectIcon} />
                            <div className={styles.disconnectListingDescription}>
                                { intl.msgJoint("msg: gmb.disconnectListingDescription {Disconnecting your Google My Business listing from your website means you will have to update them separately.}") }
                            </div>
                            <PrimaryButton className={cx(styles.btn, styles.disconnectBtn)} onClick={() => dispatch(disconnectGmbListing())}>
                                <Msg k="gmb.disconnectMyListing">Disconnect my listing</Msg>
                            </PrimaryButton>
                            <SecondaryButton className={cx(styles.btn, styles.keepConnectedBtn)} onClick={() => dispatch(replaceWithBusinessListTabDialog())}>
                                <Msg k="gmb.keepMyListing">No, keep my listing connected</Msg>
                            </SecondaryButton>
                        </React.Fragment>
                }
            </DialogBody>
        </Dialog>
    );
};

const mapStateToProps = state => {
    const GMB = makeEpicStateSelector(BUSINESS_LISTING_SITE_SETTINGS_SERVICE_EPIC_VALUE)(state);

    return {
        isWaitingForApi: GMB.isWaitingForApi
    };
};

export default injectIntl(connect(mapStateToProps)(DisconnectListingDialog));
