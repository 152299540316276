/* eslint-disable max-len */
import React from "react";
import cx from "classnames";
import { injectIntl } from "../../../view/intl/index";
import { Dialog, DialogBody, DialogFooterBare } from "../../../view/common/dialogs/baseDialog/index";
import getCenteredDialogConfig from "../../DialogManager/getCenteredDialogConfig";
import styles from "./colorChangesDialog.css";
import { StripTypes } from "../../../view/common/dialogs/baseDialog/Dialog";
import { PrimaryButton, SecondaryButton } from "../../../view/common/Button/index";
import { HIDE_AUTO_COLOR_LEFT_PANEL, DONE_AUTO_COLOR_LEFT_PANEL } from "../actionTypes";
import { UPDATE_THEME_PALETTE } from "../../Workspace/epics/stylesheets/actionTypes";
import { closeDialogAC } from "../../App/actionCreators/closeDialog";
import { RESET_UNDO_STACK_INDEX_AND_FLUSH } from "../../../epics/undoManager/actionTypes";

const width = 470,
    minHeight = 216;
const ColorChangesDialog = injectIntl(({ intl, dispatch, initialPalette, undoStackIndex }) => {
    const proceedToWorkspace = intl.msgJoint(
            "msg: colorChangesDialog.proceedTo.Workspace {Would you like to save the changes you made to your colour theme before returning to the workspace?}"
        ),
        closeAutoColorLeftPanel = () =>
            dispatch({
                type: HIDE_AUTO_COLOR_LEFT_PANEL
            });

    return (
        <Dialog stripType={StripTypes.NONE} closeBtnClassName={styles.closeButton} className={styles.dialog}>
            <DialogBody className={cx(styles.dialogBody)}>
                <div className={styles.title}>{intl.msgJoint("msg: colorChangesDialog.title {Save colour changes?}")}</div>
                <div className={styles.description}>{proceedToWorkspace}</div>
            </DialogBody>
            <DialogFooterBare>
                <SecondaryButton
                    onClick={() => {
                        dispatch({
                            type: UPDATE_THEME_PALETTE,
                            payload: initialPalette
                        });
                        dispatch({
                            type: RESET_UNDO_STACK_INDEX_AND_FLUSH,
                            payload: undoStackIndex
                        });
                        closeAutoColorLeftPanel();
                        dispatch(closeDialogAC());
                    }}
                >
                    {intl.msgJoint("msg: colorChangesDialog.doNotSaveChanges {Don’t save changes}")}
                </SecondaryButton>
                <PrimaryButton
                    onClick={() => {
                        dispatch({
                            type: DONE_AUTO_COLOR_LEFT_PANEL
                        });
                        closeAutoColorLeftPanel();
                        dispatch(closeDialogAC());
                    }}
                >
                    {intl.msgJoint("msg: colorChangesDialog.saveChanges {Save changes}")}
                </PrimaryButton>
            </DialogFooterBare>
        </Dialog>
    );
});
const config = getCenteredDialogConfig({
    width,
    minHeight,
    component: ColorChangesDialog
});
export default config;
