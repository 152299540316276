import * as kinds from "./kinds";
import factory from "./factory";
import positionsFactory from "./positionsFactory";

import type { HandleCreator } from './flowTypes';

export const
    HandleWidthPx = 19,
    HandleHeightPx = 19;
/*
*         -
*     NW  N  NE
*   - W       E +
*     SW  S  SE
*         +
*/

const
    isEWHandle = kind => [kinds.ResizeE, kinds.ResizeW].indexOf(kind) > -1,
    getBigHeight = bbox => (bbox.bottom - bbox.top - HandleWidthPx + 1),
    getHeight = (bbox, HandleKind) => (isEWHandle(HandleKind) ? getBigHeight(bbox) : HandleWidthPx),
    handleCreatorFactory = factory(() => HandleWidthPx, getHeight),
    {
        horizontalCenter, top,
        left, right, bottom, topBelow
    } = positionsFactory(HandleWidthPx, HandleHeightPx),
    resizeHandleCreators: Array<HandleCreator> = [
        handleCreatorFactory(top, horizontalCenter, kinds.ResizeN),
        handleCreatorFactory(top, right, kinds.ResizeNE),
        handleCreatorFactory(topBelow, right, kinds.ResizeE),
        handleCreatorFactory(bottom, right, kinds.ResizeSE),
        handleCreatorFactory(bottom, horizontalCenter, kinds.ResizeS),
        handleCreatorFactory(bottom, left, kinds.ResizeSW),
        handleCreatorFactory(topBelow, left, kinds.ResizeW),
        handleCreatorFactory(top, left, kinds.ResizeNW)
    ],
    resizeHandleCreatorsForStrip: Array<HandleCreator> = [
        handleCreatorFactory(top, horizontalCenter, kinds.ResizeN),
        handleCreatorFactory(bottom, horizontalCenter, kinds.ResizeS)
    ];

export {
    resizeHandleCreators,
    resizeHandleCreatorsForStrip
};
