import React from "react";
import { connect } from "react-redux";
import cx from 'classnames';
import { Msg, injectIntl } from "../../../view/intl/index";
import { currentLanguageAppSel } from "../../TopBar/epics/languages/selectors";
import styles from "./view.css";
import type { PremiumFeatureComponentMaskLabelPropType } from "../flowTypes";
import { isEcommerceTier, getPremiumPackageNameFromMetadata, getEcommercePackageNameFromMetadata } from "../utils";

const localeWidthMap = {
    "da": 135,
    "de": 140,
    "en_us": 130,
    "en_gb": 130,
    "es": 130,
    "fr": 180,
    "it": 170,
    "nl": 110,
    "nb": 140,
    "pt": 130,
    "fi": 160,
    "sv": 130
};

const leftPadding = 20,
    rightPadding = 20,
    iconWidth = 15;

const ComponentTierLabel = injectIntl(({ className, componentTierType, subscriptionMetadata, intl }) => {
    const eCommercePackageNameFromMetadata = getEcommercePackageNameFromMetadata(subscriptionMetadata);
    if (isEcommerceTier(componentTierType)) {
        return (
            <Msg
                className={className}
                k="componentTier.ecommerceTier.businessEcommerceFeature"
                params={{ packageName: eCommercePackageNameFromMetadata ||
                    intl.msgJoint('msg: common.business_ecommerce {Business + Ecommerce}') }}
            >{`
                {packageName} feature
            `}
            </Msg>
        );
    }

    const premiumPackageNameFromMetadata = getPremiumPackageNameFromMetadata(subscriptionMetadata);
    return (
        <Msg
            className={className}
            k="componentTier.premium.premiumFeature"
            params={{ packageName: premiumPackageNameFromMetadata || intl.msgJoint('msg: common.premium {Premium}') }}
        >{`
            {packageName} Feature
        `}</Msg>
    );
});

const PremiumComponentMaskLabel = ({
    width,
    msgWidth,
    componentTierType,
    subscriptionMetadata,
    intl
}: PremiumFeatureComponentMaskLabelPropType) => {
    let iconClassName = '', messageClassname = '';

    if (width > (leftPadding + iconWidth + msgWidth + rightPadding)) {
        // Hide nothing, enough space
    } else if (width > (leftPadding + msgWidth + rightPadding)) {
        // Hide icon
        iconClassName = styles.hidden;
    } else /*if (width > (leftPadding + iconWidth + rightPadding))*/ {
        // Hide message and show icon always
        messageClassname = styles.hidden;
    }

    return (
        <div className={styles.incompatibleComponentUnselectedStrip}>
            <span className={cx(styles.toolBarUpgradeIcon, iconClassName)} />
            <ComponentTierLabel
                className={messageClassname}
                componentTierType={componentTierType}
                subscriptionMetadata={subscriptionMetadata}
                intl={intl}
            />
        </div>
    );
};

const mapStateToProps = appState => {
    const locale = currentLanguageAppSel(appState);
    return {
        msgWidth: localeWidthMap[locale],
        subscriptionMetadata: appState.subscriptionData.metadata,
    };
};

const PremiumFeatureComponentMaskLabel = connect(mapStateToProps)(PremiumComponentMaskLabel);

export { PremiumFeatureComponentMaskLabel };
