import makeEpic from '../../../../../epics/makeEpic';
import { COMPONENT_CONFIGURATION_COMPLETE } from "../../../../../redux/modules/children/workspace/actionTypes";
import { makeActionForwardToSelectedComponent } from '../../../../../redux/forwardTo';
import valueActionType from './valueActionType';
import * as actionTypes from '../actionTypes';
import { FEATURED_PRODUCTS_UPDATE_PRODUCTS } from '../../actionTypes';
import { openDialog, closeDialog } from '../../../../App/actionCreators/index';
import FeaturedProductsSelectorDialogId from '../dialogId';
import {
    getProductsCount,
    getHeight,
    getProductsPerRow
} from '../../utils';

const defaultState = {
    featuredProducts: []
};

const addComponentAction = (products, newHeight) => ({ type: COMPONENT_CONFIGURATION_COMPLETE, payload: { products, newHeight } });
const updateComponentAction = products => ({ type: FEATURED_PRODUCTS_UPDATE_PRODUCTS, payload: { products } });

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [actionTypes.FEATURED_PRODUCTS_SET_ITEMS],
            reducer: ({ values: [{ isUpdate, products }] }) => {
                let actionToDispatch: AnyAction;
                if (isUpdate) {
                    actionToDispatch = makeActionForwardToSelectedComponent(updateComponentAction(products));
                } else {
                    const productsCount = getProductsCount(products);
                    const productsPerRow = getProductsPerRow(productsCount);
                    const newHeight = getHeight(productsCount, productsPerRow);
                    actionToDispatch = addComponentAction(products, newHeight);
                }

                return { state: defaultState, actionToDispatch };
            }
        }, {
            conditions: [actionTypes.FEATURED_PRODUCTS_SET_ITEMS],
            reducer: () => ({ state: defaultState, actionToDispatch: closeDialog() })
        }, {
            conditions: [actionTypes.SHOW_FEATURED_PRODUCTS_SELECTOR_DIALOG],
            reducer: ({ state, values: [{ featuredProducts }] }) => {
                return ({
                    state,
                    actionToDispatch: openDialog(FeaturedProductsSelectorDialogId, { featuredProducts })
                });
            }
        }, {
            conditions: [actionTypes.HIDE_FEATURED_PRODUCTS_SELECTOR_DIALOG],
            reducer: ({ state }) => {
                return {
                    state: {
                        ...state
                    },
                    actionToDispatch: closeDialog()
                };
            }
        }
    ]
});
