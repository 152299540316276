import makeEpic from '../../../epics/makeEpic';
import localStorage from '../../../utils/localStorage';

import { DASHBOARD_SHOP_ROUTE } from '../../../../../server/shared/routes';

import { WORKSPACE_READY } from '../../Workspace/actionTypes';
import { openDialog, closeDialog } from '../../App/actionCreators/index';
import * as actionTypes from '../actionTypes';
import welcomeMessageDialogId from '../dialogId';
import valueActionType from './valueActionType';
import { showSectionInfoTooltipAC } from "../../Tooltip/epics/sectionInfoTooltip/index";
import { siteDataValueActionType } from "../../App/epics/siteData/valueActionType";
import { PREFERENCES_EPIC_VALUE_VAT } from "../../Preferences/preferencesVat";
import { receiveOnly } from "../../../epics/makeCondition";
import { savePreferencesAction } from "../../Preferences/actions";
import { navigateToLocationAction } from '../../../redux/middleware/window/actionCreators';
import { ONBOARDING_VAT } from "../../Onboarding/onboardingVat";
import { SAVE_REQUEST } from "../../App/actionTypes";

export const showBetaWelcomeMessageKey = 'showBetaWelcomeMessage',
    showWelcomeMessageOnTemplateImportKey = 'showWelcomeMessageOnTemplateImport';

export default makeEpic({
    defaultState: null,
    valueActionType,
    updaters: [
        {
            conditions: [
                receiveOnly(siteDataValueActionType),
                receiveOnly(PREFERENCES_EPIC_VALUE_VAT),
                WORKSPACE_READY
            ],
            reducer: ({ values: [siteData, preferencesData], state }) => {
                let actionToDispatch: Action | null = null,
                    showWelcomeMessageDialog = false;

                const
                    showBetaWelcomeMessage = localStorage.get(showBetaWelcomeMessageKey, true),
                    showWelcomeMessageOnTemplateImport = localStorage.get(showWelcomeMessageOnTemplateImportKey, true);
                showWelcomeMessageDialog = showBetaWelcomeMessage === 'yes' || showWelcomeMessageOnTemplateImport === 'yes';
                if (siteData &&
                    siteData.isSimpleSiteCustomer() &&
                    !showWelcomeMessageDialog &&
                    (!preferencesData || !preferencesData.editorWelcomeModalSeen)
                ) {
                    showWelcomeMessageDialog = true;
                }
                actionToDispatch = showWelcomeMessageDialog ? { type: actionTypes.SHOW_WELCOME_MESSAGE } : showSectionInfoTooltipAC();

                localStorage.remove(showBetaWelcomeMessageKey);

                return { state, actionToDispatch };
            }
        },
        {
            conditions: [
                receiveOnly(ONBOARDING_VAT),
                actionTypes.SHOW_WELCOME_MESSAGE
            ],
            reducer: ({ values: [{ isShopOnboarding }], state }) => {
                const multipleActionsToDispatch: Array<any> = [];
                if (isShopOnboarding) {
                    multipleActionsToDispatch.push({ type: SAVE_REQUEST });
                }
                multipleActionsToDispatch.push(openDialog(welcomeMessageDialogId));
                return {
                    state,
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [
                receiveOnly(siteDataValueActionType),
                receiveOnly(PREFERENCES_EPIC_VALUE_VAT),
                actionTypes.HIDE_WELCOME_MESSAGE
            ],
            reducer: ({ values: [siteData, preferencesData], state }) => {
                const multipleActionsToDispatch = [
                    closeDialog(),
                    showSectionInfoTooltipAC(),
                ];
                if (siteData &&
                    siteData.isSimpleSiteCustomer() &&
                    (Object.keys(preferencesData).length && !preferencesData.editorWelcomeModalSeen)
                ) {
                    multipleActionsToDispatch.push(savePreferencesAction({
                        editorWelcomeModalSeen: true
                    }));
                }
                return {
                    state,
                    multipleActionsToDispatch
                };
            }
        },
        {
            conditions: [actionTypes.SETUP_MY_SHOP_ACTION],
            reducer: ({ state }) => ({
                state,
                actionToDispatch: navigateToLocationAction(DASHBOARD_SHOP_ROUTE)
            })
        },
    ]
});
