/* global $ */

import React from "react";
import { updateTextView } from '../../../../oneweb/Text/view/TextInMobileViewEditor';
import View from '../../../../oneweb/Text/view/index';
import {
    MOBILE_EDITOR_UPDATE_VIEW_DATA_TEXT_SIZE_IN_IMG_AND_TEXT_GROUP,
    MOBILE_EDITOR_UPDATE_VIEW_DATA_TEXT_SIZE_IN_TEXT_AND_TEXT_GROUP,
    MOBILE_EDITOR_REMOVE_VIEW_DATA,
} from "../../../actionTypes";
import { GroupTypes } from "../../../constants";
import type { TextInMVE } from "../../../../oneweb/Text/flowTypes";

const TextSizeUpdateActions = {
    [GroupTypes.imageAndText]: MOBILE_EDITOR_UPDATE_VIEW_DATA_TEXT_SIZE_IN_IMG_AND_TEXT_GROUP,
    [GroupTypes.textAndText]: MOBILE_EDITOR_UPDATE_VIEW_DATA_TEXT_SIZE_IN_TEXT_AND_TEXT_GROUP
};

type Props = TextInMVE & {
    mobileEltExtension: Record<string, any>,
    groupId: string,
    font: number,
    settings: any,
    textId: string,
    mobileStyles: any
};

class Text extends React.Component<Props> {
    shouldComponentUpdate(nextProps: Props) {
        const { selectedCmpId, mobileEltExtension, groupId } = this.props;
        return !mobileEltExtension[groupId] ||
            (selectedCmpId === groupId && mobileEltExtension[groupId].oldFont !== nextProps.settings.font);
    }

    componentDidMount() {
        this.updateSettings(true);
    }

    componentDidUpdate() {
        this.updateSettings();
    }

    updateSettings(onMount?: boolean) {
        const { settings: { font }, mobileEltExtension, groupId, textId, groupType, dispatch } = this.props,
            oldFont = (mobileEltExtension[groupId] || { oldFont: 0 }).oldFont;
        if (onMount || oldFont !== font) {
            const componentEle = $(`[data-group-text-id='${textId}']`).children(),
                { minSize, maxSize } = updateTextView(componentEle, font - oldFont);
            dispatch({
                type: TextSizeUpdateActions[groupType!],
                payload: {
                    groupId,
                    minSize,
                    maxSize,
                    oldFont: font
                }
            });
        }
    }

    componentWillUnmount() {
        const { groupId, dispatch } = this.props;
        dispatch({ type: MOBILE_EDITOR_REMOVE_VIEW_DATA, payload: groupId });
    }

    render() {
        const { textId, mobileStyles = {} } = this.props;
        return (
            <div data-group-text-id={textId} style={mobileStyles}>
                {/* @ts-ignore */}
                <View {...this.props} />
            </div>
        );
    }
}

export default Text;
