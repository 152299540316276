import * as R from 'ramda';
import { getDialogFromRegistry } from "../registry";
import { UPDATE_DIALOG } from "../../../redux/modules/actionTypes";

import type { DialogConfig, DialogManagerEpicUpdater, DialogManagerRegistryRecord } from "../flowTypes";
import { MatchAnyActionType } from "../../../epics/constants";
import { browserDimensionsVAT } from "../../App/epics/browserDimensions/valueActionType";
import { openedDialogIdsSelector } from "./selectors";
import { getCenterPositionForDialog } from "../utility";
import { dialogManagerDependsOnReducer } from "./dependsOnReducer";

const
    onUpdateDialogUpdater: DialogManagerEpicUpdater = {
        conditions: [UPDATE_DIALOG],
        reducer: ({ values: [{ dialogId, props }], state, scope }) => {
            const
                record = getDialogFromRegistry(dialogId),
                newOpenedDialogsConfigs = scope.openedDialogConfigs.map((config) => {
                    if (config.id === dialogId && record.updateConfFn) {
                        return record.updateConfFn(config, { // TODO remove dimensions structure mapping WBTGEN-6411
                            browserWidth: scope.browserDimensions.width,
                            browserHeight: scope.browserDimensions.height
                        }, props);
                    }
                    return config;
                });

            return {
                state,
                scope: R.assoc('openedDialogConfigs', newOpenedDialogsConfigs, scope)
            };
        }
    },
    updateStateInConfigIfChanged = (
        config: DialogConfig,
        action: AnyAction,
    ) => {
        const
            record: DialogManagerRegistryRecord = getDialogFromRegistry(config.id),
            nextState = record.reducer(config.state, action, config.props);

        if (nextState !== config.state) {
            return {
                ...config,
                state: nextState
            };
        }

        return config;
    },
    onAnyActionUpdater: DialogManagerEpicUpdater = {
        keepFullActions: true,
        conditions: [MatchAnyActionType],
        reducer: ({ values: [action], state, scope }) => {
            let
                configsChanged = false,
                persistentStatesChanged = false;

            const
                newConfigs = scope.openedDialogConfigs.map(config => {
                    const updatedConfig = updateStateInConfigIfChanged(config, action);

                    if (updatedConfig !== config) {
                        configsChanged = true;
                        const record = getDialogFromRegistry(config.id);
                        if (record.addIsEqToInitialStateFlag) {
                            const isEq = record.stateIsEqInitialComparator
                                ? record.stateIsEqInitialComparator
                                : R.equals;

                            return {
                                ...updatedConfig,
                                state: {
                                    ...updatedConfig.state,
                                    isEqToInitialState: isEq(updatedConfig.state, updatedConfig.initialState)
                                }
                            };
                        }

                        return updatedConfig;
                    }

                    return config;
                }),
                newDependencies = dialogManagerDependsOnReducer(scope.dialogsDependencies, action),
                updatedPersistentStates = R.mapObjIndexed((persistentState, dialogId) => {
                    if (openedDialogIdsSelector(state).indexOf(dialogId) !== -1) {
                        // if dialog is opened persistent state is updated by dialog it self
                        return persistentState;
                    }
                    // so we update persistent state here only if dialog is not opened
                    const
                        record = getDialogFromRegistry(dialogId),
                        nextPersistentState = record.reducer(persistentState, action);

                    if (nextPersistentState !== persistentState) {
                        persistentStatesChanged = true;
                    }
                    return nextPersistentState;
                }, scope.persistentStates);

            return {
                state,
                scope: R.pipe(
                    R.when(
                        () => configsChanged,
                        R.pipe(
                            R.assoc('openedDialogConfigs', newConfigs),
                            R.evolve({
                                persistentStates: (persistentStates) => {
                                    const newPersistentStates = newConfigs.reduce((acc, config: DialogConfig) => {
                                        const dialogRecord = getDialogFromRegistry(config.id);
                                        if (dialogRecord.persistentState) {
                                            acc[config.id] = config.state;
                                        }
                                        return acc;
                                    }, {});

                                    return {
                                        ...persistentStates,
                                        ...newPersistentStates
                                    };
                                }
                            })
                        )
                    ),
                    R.when(
                        () => newDependencies !== scope.dialogsDependencies,
                        R.assoc('dialogsDependencies', newDependencies)
                    ),
                    R.when(
                        () => persistentStatesChanged,
                        R.assoc('persistentStates', updatedPersistentStates)
                    )
                )(scope)
            };
        }
    },
    onWindowResizeUpdater: DialogManagerEpicUpdater = {
        conditions: [browserDimensionsVAT],
        reducer: ({ values: [browserDimensions], state, scope }) => {
            let somethingChanged = false;
            const
                newDialogConfigs = scope.openedDialogConfigs.map((dialogConfig) => {
                    const
                        { id: dialogId } = dialogConfig,
                        record: DialogManagerRegistryRecord = getDialogFromRegistry(dialogId);

                    if (record.updateOnBrowserDimensionsChange === false) {
                        return dialogConfig;
                    }

                    const
                        updatedConfig = record.updateOnBrowserDimensionsChanged
                            ? record.updateOnBrowserDimensionsChanged(dialogConfig, browserDimensions)
                            : dialogConfig,
                        { dimensions } = updatedConfig,
                        finalConfig = R.evolve({
                            position: () => getCenterPositionForDialog(
                                dimensions.width,
                                dimensions.height,
                                browserDimensions.width,
                                browserDimensions.height
                            )
                        }, updatedConfig);

                    if (finalConfig !== dialogConfig) {
                        somethingChanged = true;
                    }

                    return finalConfig;
                });

            return ({
                state,
                scope: R.pipe(
                    R.assoc('browserDimensions', browserDimensions),
                    R.when(() => somethingChanged, R.assoc('openedDialogConfigs', newDialogConfigs))
                )(scope)
            });
        }
    };

export {
    onUpdateDialogUpdater,
    onAnyActionUpdater,
    onWindowResizeUpdater
};
