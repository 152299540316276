import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly } from '../../../../epics/makeCondition';
import valueActionType from './valueActionType';
import * as appActionTypes from '../../../App/actionTypes';
import mousePositionWithRespectToTemplateAreaValueActionType from '../mousePositionWithRespectToTemplateArea/valueActionType';

export default makeEpic({
    valueActionType,
    updaters: [
        {
            conditions: [
                receiveOnly(mousePositionWithRespectToTemplateAreaValueActionType),
                appActionTypes.APP_RIGHT_MOUSE_DOWN
            ],
            reducer: ({ values: [position] }) => ({ state: position })
        }
    ]
});
