import React from "react";
import type { PropertiesPanelProps } from "../flowTypes";
import { GenericPropertiesPanel } from "./GenericPropertiesPanel";
import dispatchFunctions from "./dispatchFunctions";
import { dispatchForwardToSelectedComponent } from "../../../redux/forwardTo";
import Content from './Content';
import { shouldShowSettings } from "../epic/utils/navigation";

export const CompoundPropertiesPanel = (props: PropertiesPanelProps) => {
    const
        {
            state,
            dispatch,
            controlsDependenciesForSelectedComponent,
            selectedComponentProps,
            selectedCmpPosition,
            isModernLayoutActive,
            templateWidth
        } = props,
        {
            currentPage,
            dimensions,
            navigation,
            selectedComponentWithId,
            componentDependencies,
            stylesheets,
            mobileEnablement,
            additionalPayload,
            position,
            opacity,
            isRepositoryDomain,
            themeSettingsData,
            themeColorsData
        } = state,
        selectedComponent = selectedComponentWithId.component,
        selectedComponentExtension = selectedComponentWithId.componentExtension,
        { navigateToPage } = dispatchFunctions(dispatch),
        forwardToDispatch = dispatchForwardToSelectedComponent(dispatch),
        pageProps = {
            selectedComponentProps,
            selectedComponent,
            selectedComponentExtension,
            navigateToPage,
            dispatch: forwardToDispatch,
            currentPage,
            componentDependencies,
            controlsDependenciesForSelectedComponent,
            stylesheets,
            mobileEnablement,
            additionalPayload,
            isRepositoryDomain,
            themeSettingsData,
            themeColorsData,
            templateWidth
        },
        { component: { kind } } = selectedComponentWithId,
        shouldShowSettingsContainer = shouldShowSettings(navigation, kind),
        { activePageIndex } = navigation,
        settings = shouldShowSettingsContainer && {
            activePageIndex,
            selectedComponent,
            dispatch: forwardToDispatch,
        };

    return (
        <GenericPropertiesPanel
            title={currentPage.title}
            dimensions={dimensions}
            navigation={navigation}
            draggable
            position={position}
            // @ts-ignore
            settings={settings}
            opacity={opacity}
            dispatch={dispatch}
            isModernLayoutActive={isModernLayoutActive}
            selectedCmpPosition={selectedCmpPosition}
        >
            {/* @ts-ignore */}
            <Content {...pageProps} />
        </GenericPropertiesPanel>
    );
};
