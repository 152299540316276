import React, { useContext, useMemo } from "react";
import { useDispatch } from "react-redux";
import { WizardFooter } from "../../WizardFooter";
import { StepDescription } from "../Common/StepDescription";
import stepStyles from "../steps.css";
import { DynamicTemplateContext } from "../../Context";
import { DYNAMIC_TEMPLATE_UPDATE_WEBSITE_PURPOSE } from "../../../Epic/actionTypes";
import { validateWebsitePurposeStep } from "../validations";
import { OptionsSet } from "../Common/OptionSet/OptionsSet";
import { getOptionsFromOptionSet, getStaticDataForOptionSet } from "../utils";

const WebsitePurposeStep = () => {
    const dispatch = useDispatch();
    const { concept, wizardState, purpose } = useContext(DynamicTemplateContext);
    const [selectedOptions, setSelectedOptions] = React.useState(purpose);
    const { optionSet, type } = concept;
    const options = useMemo(() => (optionSet ? getOptionsFromOptionSet(optionSet) : []), [optionSet]);
    const { title, description } = getStaticDataForOptionSet(type, optionSet);

    const onSelectionChange = (option) => {
        setSelectedOptions(option);

        dispatch({ type: DYNAMIC_TEMPLATE_UPDATE_WEBSITE_PURPOSE, payload: option });
    };
    const goNext = () => {
        if (!validateWebsitePurposeStep(selectedOptions)) {
            return;
        }
        wizardState.goNext();
    };
    return (
        <React.Fragment>
            <div className={stepStyles.stepContainer}>
                <StepDescription title={title} description={description} />
                {optionSet && <OptionsSet
                    options={options}
                    selectedOptions={selectedOptions}
                    onSelectionChange={onSelectionChange}
                    multiSelect={optionSet.multiSelect}
                    concept={optionSet.key}
                />}
            </div>
            <WizardFooter goNext={goNext} disableButton={!selectedOptions.length} />
        </React.Fragment>
    );
};

export {
    WebsitePurposeStep,
    WebsitePurposeStep as default
};
