import React from "react";
import cx from "classnames";
import { connect } from 'react-redux';
import { injectIntl, Intl, Msg } from "../../../view/intl/index";
import CheckBoxWithLabel from "../../../view/common/CheckBox/CheckBoxWithLabel";
import { toggleEnableHttps, toggleEnableUrlWihoutHtmlExt } from "./actions";
import VerticalSpacer from '../../../view/common/VerticalSpacer';
import HorizontalSpacer from "../../../view/common/HorizontalSpacer";
import { makeEpicStateSelector } from "../../../epics/makeEpic";
import { publishConfigEpic } from "./publishConfigGlobalDataEpic";
import appConfigEpicVAT from "../../App/epics/appConfig/valueActionType";
import { getDAL } from "../../../../dal/index";
import { getDomainNameToRenderForSSL, getDomainNameToRenderForBeautifulURL } from "./utils";
import * as styles from "./SecurityTab.css";
import type { PublishConfig } from "../../App/epics/siteSettings/flowTypes";

type Props = {
    sslEnableUI: boolean,
    publishConfig: PublishConfig,
    intl: Intl,
    dispatch: Dispatch
}

class SecurityTab extends React.Component<Props> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.domain = getDAL().getDomain();
    }

    domain: string;

    renderSSLContents() {
        const
            { props, domain } = this,
            { publishConfig, intl, dispatch } = props,
            { publishWithSSL } = publishConfig,
            link = 'msg: securityTab.https.learnMore {https://help.one.com/hc/en-us/articles/360000715429-Always-use-HTTPS-on-your-Website-Builder-site}';

        const enableHttpsLabel = "msg: securityTab.enableHttps {Use HTTPS on my website}",
            learnMoreMsg = "msg: common.learnMore {Learn more}",
            enableHttpsDesc = "msg: securityTab.enableHttps.description {SSL allows your visitors to use your website and submit information securely via HTTPS.}"; // eslint-disable-line max-len

        let contents: React.ReactElement,
            domainToRender = getDomainNameToRenderForSSL(domain, publishWithSSL);

        if (publishWithSSL) {
            contents = (
                <div className={styles.info}>
                    <HorizontalSpacer x={11} />
                    <div className={styles.blueLock} />
                    <HorizontalSpacer x={5.5} />
                    <span className={cx(styles.label, styles.labelBlue)}>https</span>
                    <span className={cx(styles.label, styles.labelGray)}>://{domainToRender}</span>
                </div>
            );
        } else {
            contents = (
                <div className={styles.info}>
                    <HorizontalSpacer x={11} />
                    <div className={styles.blueInfo} />
                    <HorizontalSpacer x={5.5} />
                    <Msg k="securityTab.notSecure" className={cx(styles.label, styles.labelBlue)}>Not Secure</Msg>
                    <div className={styles.bordered}>&nbsp;</div>
                    <span className={cx(styles.label, styles.labelBlue)}>http</span>
                    <span className={cx(styles.label, styles.labelGray)}>://{domainToRender}</span>
                </div>
            );
        }

        return (
            <div className={styles.bodyContainer}>
                <div className={styles.leftContent}>
                    <div className={styles.settingsTitle}>
                        {intl.msgJoint('msg: securityTab.ssl.title {SSL/HTTPS}')}
                    </div>
                    <VerticalSpacer y={13} />
                    <div className={styles.showABannerOnYou}>
                        <div>
                            {intl.msgJoint(enableHttpsDesc)}
                        </div>
                        <VerticalSpacer y={10} />
                        <a className={styles.learnMoreLink} href={intl.msgJoint(link)} target="_blank">
                            {intl.msgJoint(learnMoreMsg)}
                        </a>
                    </div>
                    <VerticalSpacer y={38.5} />
                    <div className={styles.separator}>&nbsp;</div>
                </div>
                <div>
                    <VerticalSpacer y={33} />
                    <CheckBoxWithLabel
                        label={enableHttpsLabel}
                        isChecked={publishWithSSL}
                        onClick={() => dispatch(toggleEnableHttps())}
                        isSliderStyle
                        className={styles.checkboxLabel}
                    />
                    <VerticalSpacer y={15} />
                    {contents}
                </div>
            </div>
        );
    }

    renderBeautifyMyURLContents() {
        const
            { props, domain } = this,
            { publishConfig, intl, dispatch } = props,
            { publishWithSSL, dropHtmlExtension } = publishConfig;

        const enableBeautifulUrlLabel = "msg: securityTab.beautifulUrl {Beautify my URLs}",
            enableBeautifulUrlDesc = "msg: securityTab.beautifulUrl.description {Google ranks secure & easy-to-read addresses more favourably, so we recommend that you keep these settings enabled!}"; // eslint-disable-line max-len

        let contents: React.ReactElement,
            domainToRender = getDomainNameToRenderForBeautifulURL(domain, publishWithSSL, dropHtmlExtension);

        if (publishWithSSL) {
            contents = (
                <div className={styles.info}>
                    <HorizontalSpacer x={11} />
                    <div className={styles.grayLock} />
                    <HorizontalSpacer x={5.5} />
                    <span className={cx(styles.label, styles.labelGray)}>https</span>
                    <span className={cx(styles.label, styles.labelGray)}>://{domainToRender}/contact</span>
                    {!dropHtmlExtension && <span className={cx(styles.label, styles.labelBlue)}>.html</span>}
                </div>
            );
        } else {
            contents = (
                <div className={styles.info}>
                    <HorizontalSpacer x={11} />
                    <div className={styles.grayInfo} />
                    <HorizontalSpacer x={5.5} />
                    <Msg k="securityTab.notSecure" className={cx(styles.label, styles.labelGray)}>Not Secure</Msg>
                    <div className={styles.bordered}>&nbsp;</div>
                    <span className={cx(styles.label, styles.labelGray)}>http</span>
                    <span className={cx(styles.label, styles.labelGray)}>://{domainToRender}/contact</span>
                    {!dropHtmlExtension && <span className={cx(styles.label, styles.labelBlue)}>.html</span>}
                </div>
            );
        }

        return (
            <div className={styles.bodyContainer}>
                <div className={styles.leftContent}>
                    <div className={styles.settingsTitle}>
                        {intl.msgJoint('msg: securityTab.url.title {URL}')}
                    </div>
                    <VerticalSpacer y={13} />
                    <div className={styles.showABannerOnYou}>
                        {intl.msgJoint(enableBeautifulUrlDesc)}
                    </div>
                    <VerticalSpacer y={38} />
                    <div className={styles.separator}>&nbsp;</div>
                </div>
                <div>
                    <VerticalSpacer y={33} />
                    <CheckBoxWithLabel
                        label={enableBeautifulUrlLabel}
                        isChecked={dropHtmlExtension}
                        onClick={() => dispatch(toggleEnableUrlWihoutHtmlExt())}
                        isSliderStyle
                        className={styles.checkboxLabel}
                    />
                    <VerticalSpacer y={15} />
                    {contents}
                </div>
            </div>
        );
    }

    render() {
        const { sslEnableUI } = this.props;

        return (
            <div className={styles.container}>
                {sslEnableUI && <VerticalSpacer y={55} />}
                {sslEnableUI && this.renderSSLContents()}
                <VerticalSpacer y={31.5} />
                {this.renderBeautifyMyURLContents()}
            </div>
        );
    }
}

const publishConfigEpicStateSelector = makeEpicStateSelector(publishConfigEpic.valueActionType),
    appConfigEpicStateSelector = makeEpicStateSelector(appConfigEpicVAT);

const mapStateToProps = (appState) => {
    const publishConfig = publishConfigEpicStateSelector(appState),
        appConfig = appConfigEpicStateSelector(appState),
        { oneWeb: { publish } } = appConfig;

    return {
        publishConfig,
        sslEnableUI: publish && !publish.sslDisableUI
    };
};

export default injectIntl(connect(mapStateToProps)(SecurityTab));
