import * as React from 'react';

import { Msg, useIntl } from '../../../../../../view/intl';
import { UpgradeMessageBanner } from '../../UpgradeMessageBanner';
import VerticalSpacer from '../../../../../../view/common/VerticalSpacer';
import { FEATURE_ADD_SECTION_LINK } from "../../../../../TopBar/view/Upgrade/tracking/contants";

import styles from './SectionLinkTab.css';

const sectionLinksDocURL = 'msg: sectionLink.learnMoreLink {https://help.one.com/hc/en-us/articles/360005542937}';

type Props = {
    newPageLink?: boolean;
    showUpgradeMsg?: boolean;
};

export const SectionLinkTabSubtitle = ({ newPageLink = false, showUpgradeMsg = false }: Props) => {
    const intl = useIntl();

    return (
        <div>
            {
                showUpgradeMsg ? (
                    <React.Fragment>
                        <UpgradeMessageBanner trackingSource={FEATURE_ADD_SECTION_LINK} />
                        <VerticalSpacer y={10} />
                    </React.Fragment>
                ) : undefined
            }
            <div className={styles.description}>
                {
                    newPageLink ?
                        <Msg k="newPage.sectionLink.subTitle.description">
                            Lead visitors to a specific section on your website directly from the menu.
                        </Msg> :
                        <Msg k="newLink.sectionLink.subTitle">
                            This generates a link that leads visitors to a specific section on your website.
                        </Msg>
                }
                &nbsp;
                <a target="_blank" href={intl.msgJoint(sectionLinksDocURL)} className={styles.anchorLink}>
                    <Msg k="common.learn.sectionLinks">
                        Learn more about section links
                    </Msg>
                </a>
            </div>
        </div>
    );
};

