import Resource from "../Resource";

export default (inResources: Array<Resource>, resourceName: string) => {
    const resources = [...inResources];
    for (let i = 0; i < resources.length; i++) {
        if (resources[i].getName() === resourceName) {
            resources.splice(i, 1);
            break;
        }
    }
    return resources;
};
