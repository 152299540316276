import { assocPath, clone, path, pathOr } from 'ramda';
import * as backgroundMapper from "../../../mappers/background/index";
import { makeComponentBorderStyle } from '../../../mappers/border/index';
import type { BackgroundComponent } from "./flowTypes";
import type { GalleryComponent } from "../Gallery/flowTypes";
import type { Color } from "../../../mappers/flowTypes";
import type { Background } from "../../../mappers/background/flowTypes";
import type { FacebookFeedGalleryComponent } from "../FacebookFeedGallery/flowTypes";

type Options = {
    backgroundImageQuery?: Object,
    backgroundColorFromTheme?: Color,
    backgroundGradientColorFromTheme?: Color,
    borderColorFromTheme?: Color,
};

type CssOptions = {
    assetUrlQuery?: Object
}

type Style = {
    borderStyle?: string
    backgroundClip?: string
    backgroundImage?: string
    backgroundSize?: string
}

export const processBackgroundForThemeColorAndGradient =
    (backgroundIn: Background, backgroundColorFromTheme: Color, backgroundGradientColorFromTheme?: Color): Background => {
        let background = backgroundIn;
        const backgroundColorFromThemeWithOpacityInherited =
            assocPath([4], pathOr(1, ['colorData', 'color', 4], background), backgroundColorFromTheme);
        background = assocPath(['colorData', 'color'], backgroundColorFromThemeWithOpacityInherited, background);
        if (backgroundGradientColorFromTheme) {
            const backgroundGradientColorFromThemeWithOpacityInherited = assocPath(
                [4], pathOr(1, ['colorData', 'gradient', 'color', 4], background), backgroundGradientColorFromTheme
            );
            background =
                assocPath(['colorData', 'gradient', 'color'], backgroundGradientColorFromThemeWithOpacityInherited, background);
        } else {
            // @ts-ignore
            background.colorData.gradient = null; // null would mean no gradient
        }
        return background;
    };
export default (component: BackgroundComponent|GalleryComponent|FacebookFeedGalleryComponent, options: Options = {}) => {
    const
        { backgroundColorFromTheme, backgroundGradientColorFromTheme, borderColorFromTheme } = options;
    let style: Style = {};

    // background
    let background = clone(path(['style', 'background'])(component));
    if (background) {
        let cssOptions: CssOptions = {};

        if (options.backgroundImageQuery) {
            cssOptions.assetUrlQuery = options.backgroundImageQuery;
        }

        if (backgroundColorFromTheme) {
            background = processBackgroundForThemeColorAndGradient(
                background,
                backgroundColorFromTheme,
                backgroundGradientColorFromTheme
            );
        } else if (backgroundColorFromTheme === null) { // null would mean transparent theme color
            background = null;
        }

        style = { ...backgroundMapper.toCss(background, cssOptions) };
    }

    // border
    style = { ...style, ...makeComponentBorderStyle(component, borderColorFromTheme) };

    /**
     * WBTGEN-6718: Applying background clip only when needed (ONEWEB-7410).
     * To prevent subpixel rendering issue.
     */
    const borderStyle = style.borderStyle;
    if (borderStyle && borderStyle !== "none") {
        style = { ...style, backgroundClip: 'padding-box' };
    }

    return style;
};
