import React from 'react';
import { connect } from "react-redux";
import { Msg } from '../../../view/intl/index';
import { Flex } from '../../../view/reflexbox/index';
import { MoreKind, More } from "./constants";
import { LOGO_KIND as LogoKind } from "../../oneweb/Logo/kind";
import CodeComponentKind from "../../oneweb/Code/kind";
import TableKind from "../../oneweb/Table/kind";
import MenuKind from "../../oneweb/Menu/kind";
import DocumentKind from "../../oneweb/Document/kind";
import { ComponentNames } from "../../oneweb/constants";
import { DroppableCmpShortcut, ExtendedPanelShortCut } from "../view/DroppableCmpShortcut";
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import EPSeparator from "../view/EPSeparator";
import { generalGlobalDataEpic } from "../../SiteSettings/General/generalGlobalDataEpic/generalGlobalDataEpic";
import { makeEpicStateSelector } from "../../../epics/makeEpic";
import { OpenSiteSettings } from "../view/EditExtendedPanelContent";
import { MenuAlignmentConfigMap, HorizontalMenuWidth } from "../../oneweb/Menu/expandedPanel/utils";
import { checkSubscriptionCompatibilityFromKind } from "../../ComponentTierManager/utils";
import { WidgetCodeConfig } from '../../oneweb/Widgets/constants';
import { WidgetDroppableCmpShortCut } from '../../oneweb/Widgets/view/DroppableCmpShortcut';
import type { ExtendedPanelContentProps } from "../view/inserter";
import * as Icon from './Icons.css';
import * as styles from '../view/Inserter.css';

type LogoContentProps = {
    logoAsset?: Record<string, any>,
} & ExtendedPanelContentProps;

const setLogoText = 'msg: extendedPanel.setLogo {Set logo}',
    replaceLogoText = 'msg: extendedPanel.replaceLogo {Replace logo}',
    mapStateToProps = (appState) => ({
        logoAsset: makeEpicStateSelector(generalGlobalDataEpic.valueActionType)(appState).logoAsset,
    });

const
    HorizontalMenuPayload = {
        layoutType: MenuAlignmentConfigMap.horizontal,
        width: HorizontalMenuWidth,
    },
    VerticalMenuPayload = {
        layoutType: MenuAlignmentConfigMap.vertical,
    };

const Content = connect(mapStateToProps)((props: LogoContentProps) => {
    const
        { subscriptionType, intl, dispatch, logoAsset } = props,
        codeComponentIsAllowed = checkSubscriptionCompatibilityFromKind(CodeComponentKind, subscriptionType),
        text = logoAsset ? replaceLogoText : setLogoText;

    return (
        <div>
            <VerticalSpacer y={17} />
            <Flex justify="space-between" align="center">
                {intl.msgJoint(ComponentNames[LogoKind])}
                <OpenSiteSettings dispatch={dispatch} intl={intl} text={text} />
            </Flex>
            <VerticalSpacer y={10} />
            <DroppableCmpShortcut {...props} kind={LogoKind}>
                <ExtendedPanelShortCut iconClassName={Icon.logoLarge} text={ComponentNames[LogoKind]} />
            </DroppableCmpShortcut>
            <VerticalSpacer y={24} />
            {/* Menu */}
            <EPSeparator />
            <VerticalSpacer y={19} />
            {intl.msgJoint(ComponentNames[MenuKind])}
            <VerticalSpacer y={10} />
            <div className={styles.shortCutsContainer}>
                <DroppableCmpShortcut {...props} kind={MenuKind} payload={HorizontalMenuPayload}>
                    <ExtendedPanelShortCut iconClassName={Icon.menuHorizontal} text={"msg: common.horizontal {Horizontal}"} />
                </DroppableCmpShortcut>
                <DroppableCmpShortcut {...props} kind={MenuKind} payload={VerticalMenuPayload}>
                    <ExtendedPanelShortCut iconClassName={Icon.menuVertical} text={"msg: common.vertical {Vertical}"} />
                </DroppableCmpShortcut>
            </div>
            <VerticalSpacer y={24} />
            <EPSeparator />
            {/* Misc */}
            <VerticalSpacer y={19} />
            <Msg k="fm.category.miscellaneous">Miscellaneous</Msg>
            <VerticalSpacer y={10} />
            <div className={styles.shortCutsContainer}>
                <WidgetDroppableCmpShortCut {...props} showPremiumIcon={!codeComponentIsAllowed} widgetConfig={WidgetCodeConfig} />
                <DroppableCmpShortcut {...props} kind={TableKind}>
                    <ExtendedPanelShortCut iconClassName={Icon.table} text={ComponentNames[TableKind]} />
                </DroppableCmpShortcut>
                <DroppableCmpShortcut {...props} kind={DocumentKind}>
                    <ExtendedPanelShortCut iconClassName={Icon.document} text={ComponentNames[DocumentKind]} />
                </DroppableCmpShortcut>
            </div>
        </div>
    );
});

export default {
    kind: MoreKind,
    shortCut: {
        iconClassName: Icon.more,
        text: More
    },
    extendedPanelData: {
        title: 'msg: extendedPanel.moreTitle {More}',
        content: (props: ExtendedPanelContentProps) => <Content {...props} />
    }
};
