import { ProductListStyles } from "../constants";
import { BUTTON_THEME_PRIMARY, BUTTON_THEME_SECONDARY } from "../../../ThemeGlobalData/constants";

export const reducerDefaultData = {
    font: '',
    fontColor: ["HSL", 0, 0, 0, 1],
    fontColorTheme: null,
    focusColor: ["HSL", 0.5680555555555555, 0.6249999999999999, 0.6235294117647059, 1],
    focusColorTheme: null,
    buttonId: "",
    buttonThemeSelected: BUTTON_THEME_SECONDARY,
    hoverColor: ["HSL", 0, 0, 1, 0.2],
    hoverColorTheme: null,
    temporaryHoverOpacity: 0,
    showCategory: true,
    showSearchBar: true,
    imageRatio: "square",
    categoriesPosition: "default",
    cropImages: true,
    productsPerRow: 5,
    productsPerRowInMobile: 2,
    spacingItemsInMobile: 20,
    productsPerPage: 50,
    userCanSortProducts: false,
    showWelcomeMessage: true,
    labelBgColor: ["HSL", 0.5680555555555555, 0.6249999999999999, 0.6235294117647059, 1],
    labelBgColorTheme: null,
    labelTextColor: ["HSL", 0, 0, 1, 1],
    labelTextColorTheme: null,
    labelPosition: "topLeft",
    buyButtonText: '',
    productListStyle: ProductListStyles.Default,
    featuredProductUrl: '',
    enableBuyNowButton: true,
    buyNowButtonText: '',
    buyNowButtonId: '',
    buyNowButtonThemeSelected: BUTTON_THEME_PRIMARY,
    dtLanguage: '',
    promoRibbonBgColor: ["HSL", 0.5680555555555555, 0.6249999999999999, 0.6235294117647059, 1],
    promoRibbonBgColorTheme: null,
    promoRibbonTextColor: ["HSL", 0, 0, 1, 1],
    promoRibbonTextColorTheme: null,
};
