import ScaleStrategy from '../scaleStrategy';
import type { ImageComponent } from '../flowTypes';
import { fixHeightForFitMode, fixWidthForFitMode } from '../propertyChangeHandler/fixHeightForFitMode';

import type {
    AdjustmentHook,
    ShouldCallAdjustmentHook
} from "../../../Workspace/epics/componentsEval/flowTypes";
import { calculateImageScale } from "../utils";

function componentDimensionsAreSame(originalComponent, resizedComponent) {
    return originalComponent.width === resizedComponent.width
        && originalComponent.height === resizedComponent.height;
}

function handleCropResize(resizedComponent: ImageComponent,
    originalComponent: ImageComponent): ImageComponent {
    let updatedComponent = { ...resizedComponent };
    if (originalComponent.left !== resizedComponent.left) {
        updatedComponent.cropLeft = Math.max(
            0,
            originalComponent.cropLeft + resizedComponent.left - originalComponent.left
        );
    }

    if (originalComponent.top !== resizedComponent.top &&     // Adjust top margin only for top
        originalComponent.height !== resizedComponent.height  // resize handler and not for top shiftbar
    ) {
        updatedComponent.cropTop = Math.max(
            0,
            originalComponent.cropTop + resizedComponent.top - originalComponent.top
        );
    }

    // resizing does not change the scale, this hook does it so its okay to use resizedComponent.scale even for undo/redo
    updatedComponent.scale = calculateImageScale(resizedComponent);

    return updatedComponent;
}

function getFixedNumber(num: any): number {
    return num.toFixed(3);
}

export function handleFitResize(resizedComponent: ImageComponent,
    originalComponent: ImageComponent): ImageComponent {
    const
        oldWidth = getFixedNumber(originalComponent.width),
        oldHeight = getFixedNumber(originalComponent.height),
        newWidth = getFixedNumber(resizedComponent.width),
        newHeight = getFixedNumber(resizedComponent.height),
        hasWidthChanged = oldWidth !== newWidth,
        hasHeightChanged = oldHeight !== newHeight;

    // all aspect ratio maintaining is done by Image component itself
    let updatedComponent = { ...resizedComponent };
    if (hasWidthChanged && hasHeightChanged) {
        return fixHeightForFitMode(resizedComponent);
    } else if (hasWidthChanged) {
        return fixHeightForFitMode(resizedComponent);
    } else if (hasHeightChanged) {
        return fixWidthForFitMode(resizedComponent);
    } else if (
        originalComponent.scaleStrategy !== ScaleStrategy.FIT &&
        resizedComponent.scaleStrategy === ScaleStrategy.FIT
    ) {
        return fixHeightForFitMode(resizedComponent);
    }

    return updatedComponent;
}

const
    hook: AdjustmentHook<ImageComponent, Object, AnyValue, null> = ({
        component: resizedComponent,
        componentExtension,
        originalComponent
    }, { component: previousComponent }) => {
        const { scaleStrategy, rotation } = resizedComponent,
            beforeComponent = originalComponent || previousComponent;
        let newComponent = resizedComponent;

        if (beforeComponent.rotation !== rotation) {
            newComponent = {
                ...newComponent,
                height: beforeComponent.width
            };
        }

        if (scaleStrategy === ScaleStrategy.CROP) {
            return [handleCropResize(newComponent, beforeComponent), componentExtension];
        } else if (scaleStrategy === ScaleStrategy.FIT) {
            return [handleFitResize(newComponent, beforeComponent), componentExtension];
        }

        return [newComponent, componentExtension];
    },
    shouldCallHook: ShouldCallAdjustmentHook<ImageComponent, Object, AnyValue, null> = (
        { component: resizedComponent, originalComponent }, { component: previousComponent }
    ) => {
        // No need to resize if
        return Boolean(
            resizedComponent &&
            (
                (previousComponent && previousComponent.rotation !== resizedComponent.rotation) ||
                (previousComponent && previousComponent.scaleStrategy !== resizedComponent.scaleStrategy) ||
                (originalComponent && !componentDimensionsAreSame(originalComponent, resizedComponent)) ||
                (previousComponent && !componentDimensionsAreSame(previousComponent, resizedComponent))
            )
        );
    },
    hookConfig = {
        hook,
        shouldCallHook
    };

export default hookConfig;
