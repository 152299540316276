import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles(
    (theme) => ({
        accordionRoot: {
            padding: "24px 8px !important",
            [theme.breakpoints.up("sm")]: {
                padding: "24px !important",
            },
        },
        accordionContent: {
            width: "100%",
        },
        accordionSummaryContainer: {
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
        },
        summaryHeaderContainer: {
            display: "flex",
            alignItems: "center",
        },
        accordionTitle: {
            fontSize: "16px",
            lineHeight: "24px",
            [theme.breakpoints.up("sm")]: {
                whiteSpace: "nowrap",
            },
        },
        keywordChip: {
            maxWidth: "480px",
            height: "auto",
            minHeight: "30px",
            border: `1px solid ${theme.palette.custom.colorLinkWater}`,
            borderRadius: "4px",
            fontSize: "14px",
            lineHeight: "22px",
            letterSpacing: "0.25px",
            padding: "4px 14px",
            margin: "0px",
            overflow: "hidden",
            cursor: "pointer",

            "&, &:focus": {
                backgroundColor: theme.palette.custom.colorLavenderWeb,
            },

            "& .MuiChip-label": {
                margin: "0px",
                padding: "0px",
                userSelect: "none",
            },

            [theme.breakpoints.only("xs")]: {
                marginTop: "12px",
            },
            [theme.breakpoints.up("sm")]: {
                marginLeft: "24px",
            },
        },
    }),
    { name: "TaskSummary" }
);
