import * as actionTypes from '../actions';
import { makeValueReducer } from "../../../../../src/redux/makeReducer/index";
import type { ForgotPasswordPagePropTypes } from "../../types";
import {
    ATTEMPT_LIMIT_EXCEEDED_EXCEPTION,
    USERNAME_NOT_EXISTS_EXCEPTION
} from "../../../../constants";

export const defaultForgotPasswordState = {
    loading: false,
    email: '',
    error: null,
    expiredPasswordLinkError: false,
    limitExceededError: false,
    userNotExistsError: false,
    invalidEmailErrorMessage: false,
    isResetEmailSuccessPage: false,
    isResetEmailPage: true,
    isResendVerificationEmailSent: false
};

export const forgotPasswordReducer = makeValueReducer({
    defaultState: defaultForgotPasswordState,
    handleActions: {
        [actionTypes.DEMO_FORGOT_PASSWORD_EMAIL_SUCCESS_ACTION]: (state: ForgotPasswordPagePropTypes) => {
            return {
                ...state,
                isResetEmailSuccessPage: true,
                isResetEmailPage: false
            };
        },
        // eslint-disable-next-line max-len
        [actionTypes.DEMO_FORGOT_PASSWORD_EMAIL_FAILURE_ACTION]: (state: ForgotPasswordPagePropTypes, action: Record<string, any>) => {
            const newState = state;
            const errorCode = action.payload.code;
            if (errorCode === USERNAME_NOT_EXISTS_EXCEPTION) {
                newState.userNotExistsError = true;
            } else if (errorCode === ATTEMPT_LIMIT_EXCEEDED_EXCEPTION) {
                newState.limitExceededError = true;
            } else {
                newState.error = action.payload.message;
            }
            return {
                ...newState,
                loading: false
            };
        },
        [actionTypes.DEMO_UPDATE_FORGOT_PASSWORD_EMAIL_VALUE_ACTION]: (state: ForgotPasswordPagePropTypes, action: Record<string, any>) => { //eslint-disable-line max-len
            return {
                ...state,
                email: action.payload.emailValue
            };
        },
        [actionTypes.DEMO_FORGOT_PASSWORD_SHOW_INVALID_EMAIL_ERROR_ACTION]: (state: ForgotPasswordPagePropTypes) => {
            return {
                ...state,
                invalidEmailErrorMessage: true
            };
        },
        [actionTypes.DEMO_FORGOT_PASSWORD_CLEAN_ALL_ERRORS_ACTION]: (state: ForgotPasswordPagePropTypes) => {
            return {
                ...state,
                limitExceededError: false,
                invalidEmailErrorMessage: false,
                userNotExistsError: false,
                expiredPasswordLinkError: false,
                error: null
            };
        },
        [actionTypes.DEMO_CLEAN_UP_FORGOT_PASSWORD_SCREEN_ACTIONS]: () => {
            return defaultForgotPasswordState;
        },
        [actionTypes.DEMO_FORGOT_PASSWORD_LOADING_ACTION]: (state: ForgotPasswordPagePropTypes) => {
            return {
                ...state,
                loading: true,
            };
        },
        [actionTypes.DEMO_SHOW_EXPIRED_PASSWORD_ERROR]: (state: ForgotPasswordPagePropTypes) => {
            return {
                ...state,
                expiredPasswordLinkError: true
            };
        },
        [actionTypes.DEMO_RESEND_VERIFICATION_EMAIL_SENT_ACTION]: (state: ForgotPasswordPagePropTypes) => {
            return {
                ...state,
                isResendVerificationEmailSent: true
            };
        }
    }
});
