import { ApiActionTypes } from "../../redux/middleware/api/flowTypes";

export const
    OPEN_IMAGE_EDITOR_DIALOG = "OPEN_IMAGE_EDITOR_DIALOG",
    SAVE_EDITED_IMAGE = "SAVE_EDITED_IMAGE",
    IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_REQUEST = "IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_REQUEST",
    IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_SUCCESS = "IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_SUCCESS",
    IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_FAILURE = "IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_FAILURE",
    IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_ACTIONS: ApiActionTypes = [
        IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_REQUEST,
        IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_SUCCESS,
        IMAGE_EDITOR_FETCH_WEBSPACE_CONTENTS_FAILURE
    ],
    IMAGE_EDITOR_UPLOAD_IMAGE_REQUEST = "IMAGE_EDITOR_UPLOAD_IMAGE_REQUEST",
    IMAGE_EDITOR_UPLOAD_IMAGE_SUCCESS = "IMAGE_EDITOR_UPLOAD_IMAGE_SUCCESS",
    IMAGE_EDITOR_UPLOAD_IMAGE_FAILURE = "IMAGE_EDITOR_UPLOAD_IMAGE_FAILURE",
    IMAGE_EDITOR_UPLOAD_IMAGE_ACTIONS: ApiActionTypes = [
        IMAGE_EDITOR_UPLOAD_IMAGE_REQUEST,
        IMAGE_EDITOR_UPLOAD_IMAGE_SUCCESS,
        IMAGE_EDITOR_UPLOAD_IMAGE_FAILURE
    ],
    IMAGE_EDITOR_MESSAGE = "IMAGE_EDITOR_MESSAGE",
    IMAGE_CLEAR_EDITOR_MESSAGE = "IMAGE_CLEAR_EDITOR_MESSAGE",
    IMAGE_EDITOR_CLOSE_EDITOR = "IMAGE_EDITOR_CLOSE_EDITOR",
    IMAGE_EDITOR_SERIALIZED_SAVE_CLOSE_EDITOR = "IMAGE_EDITOR_SERIALIZED_SAVE_CLOSE_EDITOR",
    IMAGE_EDITOR_SHOW_REPLACED_IMAGE_MSG = "IMAGE_EDITOR_SHOW_REPLACED_IMAGE_MSG",
    IMAGE_EDITOR_SHOW_PROCESSING_NEW_IMAGE_ERROR = "IMAGE_EDITOR_SHOW_PROCESSING_NEW_IMAGE_ERROR",
    IMAGE_EDITOR_EDIT_IMAGE_CLICKED = 'IMAGE_EDITOR_EDIT_IMAGE_CLICKED',
    IMAGE_EDITOR_TRACK_ERROR = 'IMAGE_EDITOR_TRACK_ERROR',
    IMAGE_EDITOR_SAVE_CLICKED = 'IMAGE_EDITOR_SAVE_CLICKED',
    IMAGE_EDITOR_ADD_TO_MY_IMAGES_CLICKED = 'IMAGE_EDITOR_ADD_TO_MY_IMAGES_CLICKED',
    IMAGE_EDITOR_UNDO = 'IMAGE_EDITOR_UNDO',
    IMAGE_EDITOR_REDO = 'IMAGE_EDITOR_REDO';

type SaveEditedImageActionPayload = {
    asset: Object,
    blob: Blob,
    replaceWithEditedImageAction: string,
    extraPayload?: Object,
};

export const saveEditedImageAction = (payload: SaveEditedImageActionPayload) => ({
    type: SAVE_EDITED_IMAGE,
    payload,
});
