import React from 'react';
import { Msg } from '../../../../view/intl/index';
import type { Dimensions } from '../../../../redux/modules/children/workspace/flowTypes';
import styles from './Image.css';

const
    MIN_WIDTH = 200,
    MIN_HEIGHT = 150;

export default ({ width, height }: Dimensions) => {
    if (width >= MIN_WIDTH && height >= MIN_HEIGHT) {
        return (<Msg className={styles.brokenTextCls} k="common.brokenImage.label">Broken image</Msg>);
    }
    return null;
};
