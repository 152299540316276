import * as R from 'ramda';
import makeEpic from '../../../../epics/makeEpic';
import { receiveOnly } from '../../../../epics/makeCondition';
import valueActionType from './valueActionType';
import mousePositionWithRespectToTemplateAreaValueActionType from '../mousePositionWithRespectToTemplateArea/valueActionType';
import topMostHandleValueActionType from "../topMostHandle/valueActionType";
import { zeroBBox } from '../../../../utils/componentsMap/index';
import { WINDOW_MOUSE_UP } from '../../../../redux/modules/actionTypes';
import { SELECT_COMPONENTS_INSIDE_BBOX, WORKSPACE_LEFT_MOUSE_DOWN } from '../../actionTypes';
import { getSelectionBBox } from '../../../../utils/bBox';

import type { Position } from '../../../../redux/modules/flowTypes';
import type { SelectionFrameDecorationState } from './flowTypes';
import type { Epic } from '../../../../epics/flowTypes';
import {
    ReceiveOnlyIsCtrlLikePressedActionType,
    ReceiveOnlyIsShiftPressedActionType
} from "../../../App/epics/isKeyPressed/selectorActionTypes";
import {
    ReceiveOnlyComponentsMapNoGhostsSelector,
    ReceiveOnlySelectedComponentsIdsNoGhostsSelector
} from "../componentsEval/selectorActionTypes";
import { isWorkspaceRelatedHandle } from "../../../../utils/handle/index";
import { MIN_DRAG_DISTANCE } from "../componentsEval/userInteractionMutations/constants";

type EpicState = {
    scope: {
        startPosition?: Position
    },
    state: SelectionFrameDecorationState
}

const
    defaultState = {
        scope: {
            startPosition: undefined
        },
        state: {
            bBox: zeroBBox,
            isVisible: false,
            ctrlLikePressedOnMouseDown: false,
            shiftPressedOnMouseDown: false
        }
    };

const
    epic: Epic<EpicState, void, string> = makeEpic({
        defaultState,
        valueActionType,
        updaters: [
            {
                conditions: [
                    receiveOnly(mousePositionWithRespectToTemplateAreaValueActionType),
                    receiveOnly(topMostHandleValueActionType),
                    ReceiveOnlyIsShiftPressedActionType,
                    ReceiveOnlyIsCtrlLikePressedActionType,
                    ReceiveOnlyComponentsMapNoGhostsSelector,
                    ReceiveOnlySelectedComponentsIdsNoGhostsSelector,
                    WORKSPACE_LEFT_MOUSE_DOWN
                ],
                reducer: ({
                    values: [
                        mousePosition,
                        topMostHandle,
                        shiftPressed,
                        ctrlLikePressed,
                        componentsMap,
                        selectedComponentsIds
                    ],
                    state: epicState
                }) => {
                    if (!topMostHandle) {
                        return { state: defaultState };
                    }

                    const
                        topMostComponentId = topMostHandle.componentsIds[0],
                        canStartSelection = isWorkspaceRelatedHandle(
                            topMostHandle.kind,
                            topMostComponentId ? componentsMap[topMostComponentId].kind : "",
                            selectedComponentsIds,
                            topMostComponentId,
                            true,
                        );

                    if (canStartSelection) {
                        return {
                            state: R.evolve({
                                scope: {
                                    startPosition: () => mousePosition
                                },
                                state: {
                                    ctrlLikePressedOnMouseDown: () => ctrlLikePressed,
                                    shiftPressedOnMouseDown: () => shiftPressed
                                }
                            }, epicState)
                        };
                    } else {
                        return { state: defaultState };
                    }
                }
            },
            {
                conditions: [mousePositionWithRespectToTemplateAreaValueActionType],
                reducer: ({ values: [mousePosition], state }) => {
                    if (state.scope.startPosition) {
                        const { x, y } = state.scope.startPosition;
                        return {
                            state: R.evolve(
                                {
                                    state: {
                                        bBox: R.always(getSelectionBBox(x, y, mousePosition.x, mousePosition.y)),
                                        isVisible: R.T
                                    }
                                },
                                state
                            )
                        };
                    } else {
                        return { state };
                    }
                }
            },
            {
                conditions: [WINDOW_MOUSE_UP],
                reducer: ({ state: epicState }) => {
                    const
                        { ctrlLikePressedOnMouseDown, shiftPressedOnMouseDown } = epicState.state,
                        { left, right, top, bottom } = epicState.state.bBox,
                        isSelection = Math.max(bottom - top, right - left) > MIN_DRAG_DISTANCE,
                        payload = {
                            bBox: epicState.state.bBox,
                            ctrlLikePressedOnMouseDown,
                            shiftPressedOnMouseDown
                        },
                        selectComponentsInsideBBoxAction = epicState.state.isVisible && isSelection ?
                            { type: SELECT_COMPONENTS_INSIDE_BBOX, payload } : null;

                    return { state: defaultState, actionToDispatch: selectComponentsInsideBBoxAction };
                }
            }
        ]
    });

export {
    epic as default
};
