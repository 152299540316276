import { parseDOM } from "../../../../../utils/parseDOM";
import { type TiktokComponent } from '../types';

export const parseTiktokPostCode = (code: string): TiktokComponent => {
    let params = <TiktokComponent>{};

    const configs = [
        {
            nodeName: 'BLOCKQUOTE',
            validator: node => node.classList.contains("tiktok-embed")
                && node.attributes.cite.nodeValue.startsWith("https://www.tiktok.com"),
            attributes: {
                cite: 'link'
            }
        }
    ];

    params = parseDOM(configs, code);

    return params;
};
