import OneValidation from 'one-validation';

const
    extractContent = (s) => {
        let span = document.createElement('span');
        span.innerHTML = s;
        return span.textContent || span.innerText;
    },
    removeInvalidURLfromContent = (content: string) => {
        let replacedContent = content.replace(/[^\S ]+/g, '');
        const regexWithoutHref = /<a(?:(?!\bhref=)[^>])*>(.*?)<\/a>/g;
        const regexWithHref = /<a\s+(?:[^>]*?\s+)?href=(\\?["'])(.*?)\1\s*(?:[^>]*?\s*)?>(?:.*?)<\/a>/g;    //NOSONAR

        let m;

        // eslint-disable-next-line no-cond-assign
        while ((m = regexWithoutHref.exec(replacedContent)) !== null) {
            const
                match = m[0],
                text = extractContent(match);

            if (match) {
                regexWithoutHref.lastIndex = 0;
                replacedContent = replacedContent.replace(match, `<span>${text}</span>`);
            }
        }

        // eslint-disable-next-line no-cond-assign
        while ((m = regexWithHref.exec(replacedContent)) !== null) {
            const
                match = m[0],
                text = extractContent(match);

            let url = m[2];

            if (/^(mailto:).*/.test(url)) {
                replacedContent = replacedContent.replace(url, url.replace('mailto:', 'email:'));
            } else if (/^(tel:).*/.test(url)) {
                replacedContent = replacedContent.replace(url, url.replace('tel:', 'phone:'));
                // eslint-disable-next-line max-len
            } else if (!(/^(?:javascript:.*|sms:.*|page:.*|sectionLink:.*|file:.*|url:.*|location:.*|email:.*|phone:.*)|(api\/).*/.test(url))) {
                const valid = /^https?:\/\//.test(url) && OneValidation.httpUrl.test(url) === true;

                if (!valid) {
                    // Regex will skip one index when a match is removed.
                    // So starting from beginning.
                    regexWithHref.lastIndex = 0;
                    replacedContent = replacedContent.replace(match, `<span>${text}</span>`);
                }
            }
        }

        return replacedContent;
    };

export {
    removeInvalidURLfromContent
};
