import * as R from 'ramda';
import type { AdjustmentHook } from '../../Workspace/epics/componentsEval/flowTypes';
import { updateComponentBaseOnStretch } from "../fullWidthUtils";

const
    componentProps = [
        'width', 'height',
        'autoNext',
        'captionsEnabled', 'captionsPlacement', 'captionsAlignment',
        'images',
        'crop',
        'delay',
        'indicator',
        'navigator',
        'stretch',
        'fullWidthOption',
        'onClickAction',
        'transition'
    ],
    componentChanged = (prevComponent, nextComponent) =>
        prevComponent && componentProps.some(
            (prop) => !R.equals(prevComponent[prop], nextComponent[prop])
        ),
    componentDependenciesChanged = (prevComponentDependencies, nextComponentDependencies) =>
        prevComponentDependencies && nextComponentDependencies &&
            prevComponentDependencies.missingAssetUrls !== nextComponentDependencies.missingAssetUrls,
    hook: AdjustmentHook<any, Object, AnyValue, null> = (
        { component: nextComponent, componentDependencies: nextComponentDependencies },
        { component: prevComponent, componentDependencies: prevComponentDependencies }
    ) => {
        let updatedNextComponent = updateComponentBaseOnStretch(
            // @ts-ignore
            nextComponent, prevComponent, nextComponentDependencies, prevComponentDependencies
        );
        return [
            // WBTGEN-5381: Decimals in width or height causes the images in mobile view / preview (crop enabled)
            //              to be stretched, distorting the view. Hence it is important to round off width & height.
            //              - Vishal Raj
            R.evolve({ width: Math.round, height: Math.round }, updatedNextComponent),
            {
                shouldComponentUpdate: componentChanged(prevComponent, updatedNextComponent) ||
                    componentDependenciesChanged(prevComponentDependencies, nextComponentDependencies)
            }
        ];
    },
    hookConfig = {
        hook,
        shouldCallHook: () => true
    };

export default hookConfig;
