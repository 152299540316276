export const
    AUTHENTICATING = 'AUTHENTICATING',
    LOADING = 'LOADING',
    TRIAL_IMPORT = 'TRIAL_IMPORT',
    ONBOARDING = 'ONBOARDING',
    REDIRECTING_TO_LOGIN = 'REDIRECTING_TO_LOGIN',
    CHECKING_SERVER_STATUS = 'CHECKING_SERVER_STATUS',
    READY = 'READY',
    RELOADING = 'RELOADING',
    SHOW_WBTGEN_MIGRATION_SCREEN = 'SHOW_WBTGEN_MIGRATION_SCREEN',
    MIGRATING_USER_DATA = 'MIGRATING_USER_DATA',
    DEMO_LOGIN_AUTHENTICATION = 'DEMO_LOGIN_AUTHENTICATION';
