import React from "react";
import * as R from "ramda";
import { getCenterPositionForDialog } from "../../DialogManager/utility";
import FourFieldSpacing from "../FourFields/FourFieldSpacing/index";
import { Dialog, DialogBody } from "../../../view/common/dialogs/baseDialog/index";
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import stylesheetsEpic from "../../Workspace/epics/stylesheets/index";
import * as selectors from "../../Workspace/epics/stylesheets/selectors";
import { blockPadding } from "../../../mappers/path";
import type { BrowserDimensions } from "../../../redux/modules/children/dimensions/index";
import type { Stylesheets } from "../../Workspace/epics/stylesheets/flowTypes";

type CellSpacingDialogProps = {
    stylesheets: Stylesheets,
    onChangeAction: string,
    dispatch: Dispatch
}

export const
    width = 312,
    height = 200,
    configurationDialogFactory = (
        configurationDialogId: string,
        type: string,
        ref: string
    ) => ({
        component: (props: CellSpacingDialogProps) => {
            const { stylesheets, onChangeAction, dispatch } = props,
                stylesheet = R.pipe(
                    selectors.getAllStylesheets,
                    selectors.getStylesByType(type),
                    selectors.getStyleByRef(ref)
                )(stylesheets),
                spacing = R.path(blockPadding, stylesheet),
                cellSpacingDialogProps = {
                    values: spacing,
                    onChangeAction,
                    dispatch
                };

            return (
                <Dialog>
                    <DialogBody>
                        <VerticalSpacer y={40} />
                        <FourFieldSpacing
                            {...cellSpacingDialogProps}
                            lockIconTitle="msg: common.cell.cellSpacing {Link together cell spacing}"
                        />
                    </DialogBody>
                </Dialog>
            );
        },
        confFactory: ({ browserWidth, browserHeight }: BrowserDimensions) => ({
            configurationDialogId,
            position: getCenterPositionForDialog(width, height, browserWidth, browserHeight),
            modal: false,
            dimensions: { width, height }
        }),
        dependsOn: {
            stylesheets: stylesheetsEpic.reducer
        }
    });
