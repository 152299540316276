import type { SideBarItemDef } from '../../SideBar/types';

export const CONNECTIONS_PAGE_SIDE_BAR_ITEM_ID = 'CONNECTIONS_PAGE_SIDE_BAR_ITEM_ID';

export const ConnectionsSideBarItem: SideBarItemDef = {
    id: CONNECTIONS_PAGE_SIDE_BAR_ITEM_ID,
    title: 'msg: common.connections {Connections}',
    header: {
        title: 'msg: common.connections {Connections}',
        description: 'msg: connections.header.label {Get to know your audience and their behaviour on your website}'
    }
};
