import makeEpic from "../../../epics/makeEpic";
import { when } from "../../../epics/makeCondition";
import { isIE11OrEdge } from "../../../../utils/browser";

const defaultState = {
    isIE11OrEdge: isIE11OrEdge()
};

export const environmentEpic = makeEpic({
    valueActionType: 'ENVIRONTMENT_EPIC_VALUE',
    defaultState,
    updaters: []
});

export const
    WhenIE11OrEdge = when(environmentEpic.valueActionType, ({ payload: { isIE11OrEdge } }) => isIE11OrEdge);
