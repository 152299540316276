import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

type Props = {
    size: number;
    xs?: number;
    sm?: number;
};

export const VerticalSpacer = ({ size, xs, sm }: Props) => {
    const useStyles = makeStyles((theme) => {
        const rootStyles = {
            display: 'block',
            width: '100%',
            height: `${size}px`,
        };

        if (typeof xs === 'number') {
            rootStyles[theme.breakpoints.only('xs')] = {
                height: `${xs}px`,
            };
        }

        if (typeof sm === 'number') {
            rootStyles[theme.breakpoints.only('sm')] = {
                height: `${sm}px`,
            };
        }

        return { root: rootStyles };
    });

    const classes = useStyles();

    return <div className={classes.root} />;
};

VerticalSpacer.propTypes = {
    size: PropTypes.number.isRequired,
    xs: PropTypes.number,
};
