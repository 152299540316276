import React from 'react';

import styles from './LinkPageForm.css';
import type { LinkPageFormLinkButtonProps } from "../flowTypes";
import { Msg } from '../../../../view/intl';
import openLinkChooserDialog from "../../../../view/common/dialogs/LinkChooserDialog/actionCreators/openLinkChooserDialog";
import { LcTabName } from "../../../../view/common/dialogs/LinkChooserDialog/constants";

type Props = LinkPageFormLinkButtonProps & {
    dispatch: Dispatch;
};

export default (props: Props) => {
    const
        { setLinkAction, linkData, dispatch } = props,
        onClick = () => dispatch(openLinkChooserDialog({
            setLinkAction,
            input: linkData,
            tabs: [LcTabName.PAGE, LcTabName.FILE]
        }));

    // div wrap to reset flex
    return (
        <div>
            <Msg
                k="linkToFileOrPage"
                onClick={onClick}
                className={styles.linkToFileOrPage}
            >Link to file or page</Msg>
        </div>
    );
};
