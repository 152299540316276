import React from 'react';

export function ConsentBannerPreferencesButton() {
    return (
        <button
            type="button"
            id="consent-banner-prefs-button"
            aria-label="renew-consent"
            data-termly-onclick="displayPreferenceModal();"
        />
    );
}
