import { COMPONENT_CONFIGURATION_COMPLETE, TEMPLATE_SETTINGS, COMPONENT_EDIT } from "./actionTypes";

export const
    componentEditAction = () => ({ type: COMPONENT_EDIT }),
    componentConfigurationCompleteAction = (componentData?: Record<string, any>) => ({
        type: COMPONENT_CONFIGURATION_COMPLETE,
        payload: componentData
    }),
    showTemplateSettingsAction = (preserveNav: boolean = false) => ({
        type: TEMPLATE_SETTINGS,
        payload: { preserveNav }
    });
