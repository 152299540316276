import { evolve } from 'ramda';
import makeEpic from "../../../../epics/makeEpic";
import { receiveOnly, optionalReceiveOnly } from "../../../../epics/makeCondition";
import { ComponentsEvalValueActionType } from '../../../Workspace/epics/componentsEval/valueActionType';
import { WORKSPACE_NEW_COMPONENT_ADDED } from '../../../Workspace/actionTypes';
import { COMPONENTS_PASTED, COMPONENTS_DUPLICATED } from '../../../Workspace/epics/componentsEval/actionTypes';
import { openDialog, closeDialog } from '../../../App/actionCreators/index';
import { CLOSE_DIALOG } from '../../../../redux/modules/actionTypes';
import { TooManyWidgetsDialogId } from '../dialogs/tooManyWidgetsId';
import { CountToShowTooManyWidgetsWarning } from '../constants';
import { getWidgetsCount } from '../utils';

const WIDGET_ADDED = 'WIDGET_ADDED';
const CLOSE_TOO_MANY_WIDGETS_WARNING_DIALOG = 'CLOSE_TOO_MANY_WIDGETS_WARNING_DIALOG';

const showTooManyWidgetsAddedDialogHandler = arrActions => arrActions.map(actions => ({
    conditions: [
        receiveOnly(ComponentsEvalValueActionType),
        optionalReceiveOnly(CLOSE_DIALOG),
        ...actions
    ],
    reducer: ({ state, values: [componentsEvalState] }) => {
        const { dontShowAgain } = state;

        if (!dontShowAgain) {
            const { state: { componentsMap } } = componentsEvalState,
                widgetsCount = getWidgetsCount(componentsMap);

            if (widgetsCount >= CountToShowTooManyWidgetsWarning) {
                return {
                    state,
                    actionToDispatch: openDialog(TooManyWidgetsDialogId, { widgetsCount })
                };
            }
        }

        return { state };
    }
}));

const valueActionType = "WIDGETS_COMMON_EPIC";
const WidgetsEpic = makeEpic({
    valueActionType,
    defaultState: {
        dontShowAgain: false
    },
    updaters: [
        {
            conditions: [CLOSE_TOO_MANY_WIDGETS_WARNING_DIALOG],
            reducer: ({ state, values: [dontShowAgain] }) => {
                return {
                    state: evolve({
                        dontShowAgain: () => dontShowAgain
                    }, state),
                    actionToDispatch: closeDialog()
                };
            }
        },
        ...showTooManyWidgetsAddedDialogHandler([
            [WORKSPACE_NEW_COMPONENT_ADDED, WIDGET_ADDED], [COMPONENTS_PASTED], [COMPONENTS_DUPLICATED]
        ])
    ]
});

export {
    WIDGET_ADDED,
    CLOSE_TOO_MANY_WIDGETS_WARNING_DIALOG,
    WidgetsEpic
};
