/* eslint-disable max-len */

import cx from 'classnames';
import StringUtils from '../../../../../utils/string';
import {
    HorizontalCountStyle,
    VerticalCountStyle,
    WithoutCountStyle,
    ExtraInfoStyle,
    RoundStyle,
    SquareStyle,

    LocaleMap,
    VerbToDisplayLike
} from '../constants';
import type { ShareButtonsCalcRenderPropsResult } from '../flowTypes';
import styles from './ShareButtons.css';

export const
    getFacebookWidgetHtml = (props: ShareButtonsCalcRenderPropsResult) => {
        if (!props.showFacebook) return "";

        const
            { showFriends, styleInfo, verbToDisplay } = props,
            likeClass = styles.withoutCountFblikeCntnr,
            recClass = styles.withoutCountFbRecCntnr,
            strSF = StringUtils.toString(showFriends),
            hClass = styles.fblikeHCntnr,
            vClass = styles.fbrecomendHCntnr;

        switch (styleInfo) {
            case RoundStyle:
            case SquareStyle:
                return null;
            case WithoutCountStyle:
                return ("<li class='" + cx(verbToDisplay === VerbToDisplayLike ? likeClass : recClass) + "'>" +
                    "<div class='fb-like' data-action='like' data-size='small' data-show-faces='true' data-share='false' data-layout='button' data-action='" + verbToDisplay + "'></div>" +
                "</li>");
            case ExtraInfoStyle:
                return ("<li class='" + cx(styles.fbLikeExtraInfoCls) + "'>" +
                    "<div class='fb-like' data-action='like' data-size='small' data-show-faces='true' data-share='false' data-action='" + verbToDisplay + "' data-show-faces='" + strSF + "'>" +
                    "</div>" +
                "</li>");
            case VerticalCountStyle:
                return ("<li class='" + cx(verbToDisplay === VerbToDisplayLike ? '' : styles.fbrecomendVCntnr) + "'>" +
                    "<div class='fb-like' data-action='like' data-size='small' data-show-faces='true' data-share='false' data-layout='box_count' data-action='" + verbToDisplay + "'></div>" +
                "</li>");
            case HorizontalCountStyle:
            default:
                return ("<li class='" + cx(verbToDisplay === VerbToDisplayLike ? hClass : vClass) + "'>" +
                    "<div class='fb-like' data-action='like' data-size='small' data-show-faces='true' data-share='false' data-layout='button_count' data-action='" + verbToDisplay + "'></div>" +
                "</li>");
        }
    },

    getFacebookShareWidgetHtml = ({ showFacebookShare, styleInfo }: ShareButtonsCalcRenderPropsResult) => {
        if (!showFacebookShare) return "";

        // data-mobile-iframe='false' ensures that the share button works without appId for Facebook Share (If it is set as data-mobile-iframe='true',
        // it may not work on mobile devices for some cases). See WBTGEN-7469 for more details.
        const getFbDomContent = dataType =>
            `<div class='fb-share-button' data-size='small' data-mobile-iframe='false' data-type='${dataType}' />`;

        switch (styleInfo) {
            case RoundStyle:
                return ("<li>" +
                    "<a target='_blank' href='https://www.facebook.com/sharer/sharer.php?u='>" +
                        "<div class='" + cx(styles.icon, styles.shareButtonsFbIcon1) + "'></div>" +
                    "</a>" +
                "</li>");
            case SquareStyle:
                return ("<li>" +
                    "<a target='_blank' href='https://www.facebook.com/sharer/sharer.php?u='>" +
                        "<div class='" + cx(styles.icon, styles.shareButtonsFbIcon2) + "'></div>" +
                    "</a>" +
                "</li>");
            case WithoutCountStyle:
                return (`<li class='${cx(styles.withoutCountFblikeCntnr)}'>${getFbDomContent('button')}</li>`);
            case ExtraInfoStyle:
                return (`<li class='${cx(styles.fbLikeExtraInfoCls)}'>${getFbDomContent('button')}</li>`);
            case VerticalCountStyle:
                return (`<li>${getFbDomContent('box_count')}</li>`);
            case HorizontalCountStyle:
            default:
                return (`<li class='${cx(styles.fblikeHCntnr)}'>${getFbDomContent('button_count')}</li>`);
        }
    },

    getLinkedInWidgetHtml = ({ showLinkedIn, styleInfo }: ShareButtonsCalcRenderPropsResult) => {
        if (!showLinkedIn) return "";

        switch (styleInfo) {
            case RoundStyle:
                return ("<li>" +
                    "<a target='_blank' href='https://www.linkedin.com/shareArticle?mini=true&url='>" +
                        "<div class='" + cx(styles.icon, styles.shareButtonsInIcon1) + "'></div>" +
                    "</a>" +
                "</li>");
            case SquareStyle:
                return ("<li>" +
                    "<a target='_blank' href='https://www.linkedin.com/shareArticle?mini=true&url='>" +
                        "<div class='" + cx(styles.icon, styles.shareButtonsInIcon2) + "'></div>" +
                    "</a>" +
                "</li>");
            case WithoutCountStyle:
                return ("<li><script type='IN/Share' >IN</script></li>");
            case ExtraInfoStyle:
                return ("<li class='" + cx(styles.linkedInCls) + "'>" +
                    "<script type='IN/Share' >IN</script>" +
                "</li>");
            case VerticalCountStyle:
                return ("<li>" +
                    "<script type='IN/Share' data-showzero='false' data-counter='top'>IN</script>" +
                "</li>");
            case HorizontalCountStyle:
            default:
                return ("<li class='" + cx(styles.linkedInCls) + "'>" +
                    "<script type='IN/Share' data-counter='right' data-showzero='true'>IN</script>" +
                "</li>");
        }
    },

    getTwitterWidgetHtml = ({ showTwitter, styleInfo, locale }: ShareButtonsCalcRenderPropsResult) => {
        if (!showTwitter) return "";

        const twitterLocale = LocaleMap.twitter[locale];

        switch (styleInfo) {
            case RoundStyle:
                return ("<li>" +
                    "<a target='_blank' href='https://twitter.com/share?url='>" +
                        "<div class='" + cx(styles.icon, styles.shareButtonsTwttrIcon1) + "'></div>" +
                    "</a>" +
                "</li>");
            case SquareStyle:
                return ("<li>" +
                    "<a target='_blank' href='https://twitter.com/share?url='>" +
                        "<div class='" + cx(styles.icon, styles.shareButtonsTwttrIcon2) + "'></div>" +
                    "</a>" +
                "</li>");
            case WithoutCountStyle:
            case ExtraInfoStyle:
                return ("<li class='" + cx(styles.twtrCls) + "'>" +
                    "<a class='twitter-share-button' data-count='none' data-lang='" + twitterLocale + "'></a>" +
                "</li>");
            case VerticalCountStyle:
            case HorizontalCountStyle:
            default:
                return ("<li>" +
                    "<a class='twitter-share-button'  data-count='horizontal' data-lang='" + twitterLocale + "'></a>" +
                "</li>");
        }
    };
