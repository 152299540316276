import { path } from 'ramda';
import {
    makeCombineReducer,
    makeComponentBaseReducers,
    makeDefaultStateReducers
} from "../../../../redux/makeReducer/index";
import * as ActionTypes from '../actionTypes';
import * as mp from "../../../../mappers/path";
import * as gradientSetters from "../../../../setters/gradientSetter";
import * as bgSetters from "../../../../setters/backgroundSetter";
import * as componentReducers from '../../componentReducers';
import type { AssetData } from "../../../../mappers/background/flowTypes";
import {
    getComponentColorGradientSettings,
    getBackgroundPosition,
} from "../../../../view/oneweb/commonComponentSelectors";
import * as VerticalAlign from "../../../presentational/AlignmentTable/VerticalAlign";
import { getThemeRulesForBackground } from "../../../ThemeGlobalData/themeRules";
import { BackgroundSize } from "../../../presentational/BackgroundImageSettings/options";
import { HandleActionsConfig } from '../../../../redux/makeReducer/flowTypes';

const gradientColorSetReducer = (state, action) => {
    const { payload: { color } } = action;
    let newState = bgSetters.setBackgroundGradientColor(mp.styleBackground, color, state);
    return bgSetters.unsetAsset(mp.styleBackground, newState);
};

export default (
    kind: string,
    defaultState: Record<string, any> = {},
    defaultImageAssetData?: AssetData,
    handlers: HandleActionsConfig = {}
) => makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(kind),
        ...makeDefaultStateReducers({
            style: null,
            ...defaultState,
            mobileSettings: { size: BackgroundSize.FILL },
            /**
             * This needs to added since combineReducers will index the keys and set the value only for keys at initial load.
             * wbtgen/src/redux/makeReducer/combineReducers.js
             * The value should be null. Only those background components without having selectedTheme or with selectedTheme with "null" values will be migrated for theming.
             */
            selectedTheme: null,
        })
    },
    handleActions: {
        [ActionTypes.BG_BACKGROUND_COLOR_SET]: (state, action) => {
            const { payload: { color } } = action;
            return bgSetters.setBackgroundSolidColor(mp.styleBackground, color, state);
        },
        [ActionTypes.BG_BACKGROUND_COLOR_SET_AUTO_COLOR]: (state, action) => ({ ...state, selectedTheme: action.payload.backgroundTheme }),
        [ActionTypes.BG_BACKGROUND_COLOR_UNSET]: state => bgSetters.unsetBackgroundColor(mp.styleBackground, state),
        [ActionTypes.BG_GRADIENT_COLOR_SET]: gradientColorSetReducer,
        [ActionTypes.BG_GRADIENT_COLOR_SET_AUTO_COLOR]: (state, action) => {
            let newState = { ...state, selectedGradientTheme: action.payload.themeColor };
            return bgSetters.unsetAsset(mp.styleBackground, newState);
        },
        [ActionTypes.BG_GRADIENT_COLOR_SET_IF_UNSET]: (state, action) => (
            path(['style', 'background', 'colorData', 'gradient'])(state) ? state : gradientColorSetReducer(state, action)
        ),
        [ActionTypes.BG_GRADIENT_COLOR_UNSET]: state =>
            gradientSetters.unsetGradientColor(mp.styleBackgroundGradient, state),
        [ActionTypes.BG_GRADIENT_COLOR_UNSET_AUTO_COLOR]: state => ({ ...state, selectedGradientTheme: null }),
        [ActionTypes.BG_GRADIENT_DIRECTION_CHANGE]: (state, action) => {
            const { payload: direction } = action;
            return { ...state, ...gradientSetters.setGradientDirection(mp.styleBackgroundGradient, direction, state) };
        },
        [ActionTypes.BG_GRADIENT_FADE_POINT_CHANGE]: (state, action) => {
            const { payload: fadePoint } = action;
            return gradientSetters.setGradientFadePoint(mp.styleBackgroundGradient, fadePoint, state);
        },
        [ActionTypes.BG_OPACITY_CHANGE]: (state, action) => {
            const { payload: opacity } = action;
            return { ...state, ...bgSetters.setBackgroundOpacity(mp.styleBackground, opacity, state) };
        },
        [ActionTypes.BG_OPACITY_CHANGE_AUTO_COLOR]: (state, action) => {
            const
                { selectedTheme } = state,
                { payload: { opacity, themeColorsData } } = action,
                newStateWithSolidColor = bgSetters.setBackgroundSolidColorIfNotSet(
                    mp.styleBackground,
                    themeColorsData[getThemeRulesForBackground(selectedTheme, themeColorsData).background],
                    state
                );
            return { ...state, ...bgSetters.setBackgroundOpacity(mp.styleBackground, opacity, newStateWithSolidColor) };
        },
        [ActionTypes.BG_OPACITY_SET_IF_ZERO]: (state, action) => {
            const
                { payload: { autoColorMode, selectedTheme, themeColorsData } } = action,
                newStateWithSolidColor = bgSetters.setBackgroundSolidColorIfNotSet(
                    mp.styleBackground,
                    themeColorsData[getThemeRulesForBackground(selectedTheme, themeColorsData).background],
                    state
                );
            if (state !== newStateWithSolidColor) {
                // New color set and it has opacity 100
                return { ...state, ...newStateWithSolidColor };
            }
            // Else check if opacity is zero
            const { opacity } = getComponentColorGradientSettings(state, autoColorMode);
            if (opacity === 0) {
                return { ...state, ...bgSetters.setBackgroundOpacity(mp.styleBackground, 100, newStateWithSolidColor) };
            }
            return state;
        },
        [ActionTypes.BG_IMAGE_OPACITY_CHANGE]: (state, action) => {
            const { payload: opacity } = action;
            return { ...state, ...bgSetters.setBackgroundImageOpacity(mp.styleBackgroundOpacity, opacity, state) };
        },
        [ActionTypes.BG_BORDER_STYLE_CHANGE]: componentReducers.makeComponentStyleBorderStyleReducer(),
        [ActionTypes.BG_BORDER_STYLE_CHANGE_AUTO_COLOR]: (state, action) => ({
            ...(componentReducers.makeComponentStyleBorderStyleReducer()(state, action)),
        }),
        [ActionTypes.BG_BORDER_COLOR_CHANGE]: componentReducers.makeComponentStyleBorderColorReducer(),
        [ActionTypes.BG_BORDER_COLOR_CHANGE_AUTO_COLOR]: (state, { payload: { themeColor } }) => {
            return { ...state, selectedBorderTheme: themeColor };
        },
        [ActionTypes.BG_BORDER_OPACITY_CHANGE]: componentReducers.makeComponentStyleBorderOpacityReducer(),
        [ActionTypes.BG_BORDER_WIDTH_CHANGE]: componentReducers.makeComponentStyleBorderWidthReducer(),
        [ActionTypes.BG_BORDER_CORNERS_CHANGE]: componentReducers.makeComponentStyleBorderCornersReducer(),
        [ActionTypes.BG_CHANGE_ACTION]: (state, { payload: { asset } }) => {
            let newState = bgSetters.setAsset(mp.styleBackground, asset, state, defaultImageAssetData);
            newState = gradientSetters.unsetGradientColor(mp.styleBackgroundGradient, newState);
            newState = { ...newState, selectedGradientTheme: null };
            return newState;
        },
        [ActionTypes.BG_REMOVE_ACTION]: state => bgSetters.unsetAsset(mp.styleBackground, state),
        [ActionTypes.BG_REPEAT_CHANGE_ACTION]: (state, { payload: repeat }) =>
            bgSetters.setAssetRepeat(mp.styleBackground, repeat, state),
        [ActionTypes.BG_OVERLAY_CHANGE_ACTION]: (state, { payload: overlay }) =>
            bgSetters.setAssetOverlay(mp.styleBackground, overlay, state),
        [ActionTypes.BG_POSITION_CHANGE_ACTION]: (state, { payload: position }) =>
            bgSetters.setAssetPosition(mp.styleBackground, position, state),
        [ActionTypes.BG_SIZE_CHANGE_ACTION]: (state, { payload: size }) =>
            bgSetters.setAssetSize(mp.styleBackground, size, state),
        [ActionTypes.BG_MOBILE_IMAGE_SIZE_CHANGE_ACTION]: (state, { payload: size }) =>
            bgSetters.setMobileAssetSize(mp.backgroundSizeMobileSettings, size, state),
        [ActionTypes.BG_IMAGE_SCROLL_EFFECT_CHANGE]: (state, { payload }) => {
            const newState = bgSetters.setAssetScrollEffect(mp.styleBackground, payload, state),
                position = getBackgroundPosition(state);
            if (payload && position &&
                (position[1] === VerticalAlign.TOP || position[1] === VerticalAlign.BOTTOM)) {
                return bgSetters.setAssetPosition(mp.styleBackground, [position[0], VerticalAlign.MIDDLE], newState);
            }
            return newState;
        },
        ...handlers,
    }
});
