import React, { useState, useMemo } from "react";
import cx from "classnames";
import createFilterOptions from "react-select-fast-filter-options";
import { ONBOARDING_GMB_CATEGORY_CHOSEN } from "../actionTypes";
import { Msg, Intl } from "../../../view/intl/index";
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import Combobox from "../../../view/common/Combobox/index";
import { intlCreateOptions } from "../../../view/common/Combobox/utils";
import { GMB_CATEGORY_LIST } from "../../TemplateSelector_DEPRECATED/constants";
import styles from "./GmbCategoriesInput.css";

export type gmbCategoriesInputProps = {
    min?: number;
    max?: number;
    gmbCategories: string;
    action?: string;
    dispatch: Dispatch;
    intl: Intl;
};

const getGmbCatCount = gmbCategories => (gmbCategories ? gmbCategories.split(",").length : 0);

export const GmbCategoriesInput = (props: gmbCategoriesInputProps) => {
    const { min = 0, max = 3, gmbCategories, action = ONBOARDING_GMB_CATEGORY_CHOSEN, dispatch, intl } = props,
        hasLessItemsThanMin = getGmbCatCount(gmbCategories) < min,
        [isBeingEdited, setIsBeingEdited] = useState(false),
        [hasTriedAddingMoreThanMax, setHasTriedAddingMoreThanMax] = useState(false);

    const onFocus = () => {
            setIsBeingEdited(true);
            setHasTriedAddingMoreThanMax(false);
        },
        onBlur = () => {
            setIsBeingEdited(false);
            setHasTriedAddingMoreThanMax(false);
        },
        onSelect = payload => {
            if (getGmbCatCount(payload) > max) {
                setHasTriedAddingMoreThanMax(true);
                return;
            } else {
                setHasTriedAddingMoreThanMax(false);
            }

            dispatch({
                type: action,
                payload
            });
        };

    const { gmbCategoryOptions, filterOptions } = useMemo(() => {
        const gmbCategoryOptions = intlCreateOptions(GMB_CATEGORY_LIST, intl);
        gmbCategoryOptions.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }

            if (a.label > b.label) {
                return 1;
            }

            return 0;
        });
        const filterOptions = createFilterOptions({
            options: gmbCategoryOptions
        });
        return {
            gmbCategoryOptions,
            filterOptions
        };
    }, [intl]);
    return (
        <React.Fragment>
            <div className={styles.dropDown}>
                <Combobox
                    multi
                    joinValues
                    simpleValue
                    openOnClick={false}
                    arrowRenderer={null}
                    value={gmbCategories}
                    searchable
                    noResultsText={intl.msgJoint("msg: common.noResults {No results to display}")}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    className={cx({
                        [styles.error]: !isBeingEdited && (hasLessItemsThanMin || hasTriedAddingMoreThanMax)
                    })}
                    options={gmbCategoryOptions}
                    filterOptions={filterOptions}
                    optionRenderer={({ label }) => label}
                    onSelect={onSelect}
                    placeholder={intl.msgJoint('msg: onboarding.gmbCategory.placeholder {Eg: "Sushi restaurant" or "Hairdresser"}')}
                    dropDownClassName={styles.ddOptions}
                    style={{
                        fontSize: "14px"
                    }}
                />
            </div>
            <VerticalSpacer y={8} />
            <Msg
                k="common.selectUpToError"
                params={{
                    maxSelectionPermitted: max
                }}
                className={cx(styles.selectUpToError, {
                    [styles.invisible]: !hasTriedAddingMoreThanMax
                })}
            >
                {`You can only select up to {maxSelectionPermitted, number} options`}
            </Msg>
        </React.Fragment>
    );
};
