import { ThemeOptions } from "@mui/material/styles";

import isTestEnv from "../../../../../wbtgen/src/debug/isTestEnv";
import { SubscriptionType } from "../../../../../server/shared/subscriptionStatus/constants";
import { getHeroBgKey, getSubscriptionBgKey, getSubscriptionColorKey } from "./utils";
import { breakpoints } from "./breakpoints";

import commonStyles from "../../../../../wbtgen/src/view/Theme/common.css";
import partnerStyles from "../../../../../wbtgen/src/view/Theme/hostnet.css";

const noTransitionsConfig: Pick<ThemeOptions, "transitions"> = {
    transitions: {
        create: () => {
            return "none";
        }
    }
};

const transitionsConfig: Pick<ThemeOptions, "transitions"> = isTestEnv() ? noTransitionsConfig : {};

export const extraConfig = {
    borderRadius: 4,
    borderRadius2: 4,
    fontWeight: 500
};

const palette = {
    primary: {
        light: partnerStyles.wsbt_primaryBtnDisabled,
        main: partnerStyles.primaryImpactColor,
        dark: partnerStyles.primaryImpactHoverColor,
        contrastText: commonStyles.colorWhite
    },
    info: {
        light: partnerStyles.primaryInfoColor,
        main: partnerStyles.primaryImpactColor,
    },
    text: {
        primary: commonStyles.colorGray_3c,
        disabled: commonStyles.colorGray_8a
    },
    custom: {
        ...commonStyles,
        ...partnerStyles,
        [getSubscriptionBgKey(SubscriptionType.BASIC)]: partnerStyles.wsbt_subscriptionBackgroundColor,
        [getSubscriptionBgKey(SubscriptionType.NORMAL)]: partnerStyles.wsbt_subscriptionBackgroundColor,
        [getSubscriptionBgKey(SubscriptionType.PREMIUM)]: partnerStyles.wsbt_subscriptionBackgroundColor,
        [getSubscriptionBgKey(SubscriptionType.ECOMMERCE)]: partnerStyles.wsbt_subscriptionBackgroundColor,
        [getSubscriptionColorKey()]: partnerStyles.primaryImpactColor,
        [getHeroBgKey(SubscriptionType.BASIC)]: commonStyles.colorGray_f7,
        [getHeroBgKey(SubscriptionType.NORMAL)]: commonStyles.colorGray_f7,
        [getHeroBgKey(SubscriptionType.PREMIUM)]: commonStyles.colorGray_f7,
        [getHeroBgKey(SubscriptionType.ECOMMERCE)]: commonStyles.colorGray_f7,
    }
};

export const theme: ThemeOptions = {
    ...transitionsConfig,
    breakpoints,
    typography: {
        fontFamily: partnerStyles.defaultFontStack
    },
    palette,
    zIndex: {
        modal: 10000
    },
    components: {
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    background: partnerStyles.primaryImpactColor,
                    height: "3px"
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: "16px",
                    textTransform: "none"
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: "0px"
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    textAlign: "center",
                    padding: "0"
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: "0",
                    fontSize: "15px",
                    lineHeight: "24px",
                    "&:first-of-type": {
                        paddingTop: "0px"
                    }
                }
            }
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    color: commonStyles.colorGray_3c
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: "0",
                    justifyContent: "center"
                },
                spacing: {
                    "& > :not(:first-of-type)": {
                        marginLeft: "24px"
                    }
                }
            }
        },
        MuiUseMediaQuery: {
            defaultProps: {
                noSsr: true
            }
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            }
        },
        MuiButton: {
            defaultProps: {
                disableRipple: true,
                disableElevation: true
            },
            variants: [
                {
                    props: {
                        size: "medium"
                    },
                    style: {
                        height: "48px",
                        padding: "0px 18px",
                    }
                },
                {
                    props: {
                        size: "small"
                    },
                    style: {
                        height: "40px",
                        padding: "0px 18px",
                    }
                },
                {
                    props: {
                        variant: "contained"
                    },
                    style: {
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: palette.primary.main,
                        borderRadius: "4px",

                        "&:hover": {
                            borderColor: palette.primary.dark,
                        },

                        "&.Mui-disabled, &.Mui-disabled:hover": {
                            color: palette.primary.contrastText,
                            backgroundColor: palette.primary.light,
                            borderColor: palette.primary.light
                        },

                        "&, &:hover, &.Mui-disabled, &.Mui-disabled:hover": {
                            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
                        }
                    }
                },
                {
                    props: {
                        variant: "outlined",
                    },
                    style: {
                        color: palette.primary.main,
                        backgroundColor: commonStyles.colorWhite,
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: commonStyles.colorCultured,
                        borderRadius: "4px",

                        "&:hover": {
                            color: palette.primary.contrastText,
                            backgroundColor: palette.primary.main,
                            borderColor: palette.primary.main,
                        },

                        "&.Mui-disabled, &.Mui-disabled:hover": {
                            color: palette.primary.light,
                            backgroundColor: commonStyles.colorWhite,
                            borderColor: commonStyles.colorCultured,
                        },

                        "&, &:hover, &.Mui-disabled, &.Mui-disabled:hover": {
                            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
                        }
                    }
                },
                {
                    props: {
                        variant: "text",
                    },
                    style: {
                        padding: 0,
                        height: "auto",
                        fontSize: "14px",
                        lineHeight: "23px",
                        letterSpacing: "0.34px",

                        "&, &:hover": {
                            color: palette.text.primary,
                            backgroundColor: "transparent",
                            textDecoration: "none",
                            boxShadow: "none",
                        },

                        "&.Mui-disabled, &.Mui-disabled:hover": {
                            color: palette.text.disabled,
                        }
                    }
                },
                {
                    props: {
                        variant: "link",
                    },
                    style: {
                        padding: 0,
                        height: "auto",
                        fontSize: "14px",
                        lineHeight: "23px",
                        letterSpacing: "0.34px",

                        "&, &:hover": {
                            color: palette.primary.main,
                            backgroundColor: "transparent",
                            textDecorationLine: "underline",
                            textDecorationColor: palette.primary.main,
                            textDecorationThickness: "1px",
                            boxShadow: "none",
                        },

                        "&.Mui-disabled, &.Mui-disabled:hover": {
                            color: palette.primary.light,
                        }
                    }
                },
            ],
            styleOverrides: {
                root: {
                    fontSize: "16px",
                    lineHeight: "16px",
                    letterSpacing: "0.3px",
                    fontWeight: 400,
                    textTransform: "none",
                    transition: "none",
                    borderRadius: extraConfig.borderRadius
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    fontSize: "12px",
                    height: "30px",
                    borderRadius: "4px"
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: commonStyles.colorGray_3c
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        backgroundColor: commonStyles.colorGray_f7
                    },
                    "&.Mui-selected, &.Mui-selected.Mui-focusVisible": {
                        backgroundColor: commonStyles.colorGray_ed,
                        "&:hover": {
                            backgroundColor: commonStyles.colorGray_ed
                        }
                    }
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: commonStyles.colorGray_3c,
                    "&.Mui-focused": {
                        color: commonStyles.colorGray_3c
                    }
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    borderRadius: extraConfig.borderRadius
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    borderRadius: extraConfig.borderRadius,
                    "&:focus": {
                        backgroundColor: "inherit"
                    }
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    "&, &:hover": {
                        cursor: "pointer",
                        color: palette.primary.main,
                        textDecorationLine: "underline",
                        textDecorationColor: palette.primary.main,
                        textDecorationThickness: "1px",
                    }
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: "none",
                    "&.Mui-expanded": {
                        margin: 0
                    },
                    "&:before": {
                        height: 0
                    },
                    "&:first-of-type": {
                        borderRadius: 0
                    },
                    "&:last-of-type": {
                        borderRadius: 0
                    }
                }
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    minHeight: 40,
                    "&.Mui-expanded": {
                        minHeight: 40
                    }
                },
                content: {
                    alignItems: "center",
                    margin: 0,
                    "&.Mui-expanded": {
                        margin: 0
                    }
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    "& > svg": {
                        color: commonStyles.colorGray_8a
                    },
                    "&.Mui-checked > svg": {
                        color: partnerStyles.primaryImpactColor
                    }
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    borderRadius: extraConfig.borderRadius
                }
            }
        }
    },
};
