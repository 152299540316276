import { getAppConfig } from "../components/App/epics/appConfig/appConfig";
import { getDAL } from '../../dal/index';
import AppConfig from "./AppConfig";
import getFeatureManager from "../getFeatureManager";

const isInstagramComponentDisabled = () => {
    const appConfig = AppConfig(getAppConfig()),
        isInstagramEnabled = getFeatureManager().isInstagramComponentEnabled();
    if (!isInstagramEnabled) {
        const instaWhitelistedDomains = appConfig.getOptional(`oneWeb.instagram.instaWhitelistedDomains`, []),
            domainName = getDAL().getDomain();
        // Case when whitelisted domains are available then feature should work
        // with whitelisted domains even if disabled
        if (instaWhitelistedDomains.length > 0 &&
            instaWhitelistedDomains.includes(domainName)) {
            return false;
        }
        return true;
    } else {
        return false;
    }
};

export default isInstagramComponentDisabled;
