import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import TopBarItemMenu from "../index";
import Msg from "../../../../../view/intl/Msg";
import styles from "../TopBarItemMenu.css";
import { TUTORIAL_BTN_CLIENT_RECT, TUTORIAL_VIDEO_CLICKED } from "../../../actionTypes";
import TutorialVideoOption from '../../TutorialVideoDialog/TutorialVideoIntroDialog';
import { useIntl } from '../../../../../view/intl';

const shouldShowTutorial = (lang: string) => {
    if (lang.toLowerCase() === 'da') {
        return true;
    }
    return false;
};

const TutorialBtn = (props) => {
    const dispatch = useDispatch();
    const { showTutorial, tutorialBtnGetClientRect } = props;
    const intl = useIntl();
    const eleRef = useRef<HTMLIFrameElement>(null);
    const titleMsg = intl.msgJoint(`msg: tutorialVideoIntroPopup.msg {Watch our step-by-step video tutorial on how to use Website Builder and edit your website at the same time.}`); // eslint-disable-line max-len
    useEffect(() => {
        if (eleRef.current && tutorialBtnGetClientRect) {
            const rect = eleRef.current.getBoundingClientRect();
            dispatch({ type: TUTORIAL_BTN_CLIENT_RECT, payload: rect });
        }
    }, [tutorialBtnGetClientRect, dispatch]);
    return (
        <div className={styles.containerWrapper} ref={eleRef}>
            {showTutorial &&
            <TutorialVideoOption />}
            <TopBarItemMenu
                titleMsg={titleMsg}
                titleClass={styles.videoTitle}
                onClick={() => dispatch({ type: TUTORIAL_VIDEO_CLICKED })}
            >
                <Msg k="common.tutorial">Tutorial</Msg>
            </TopBarItemMenu>
        </div>
    );
};
export {
    TutorialBtn as default,
    shouldShowTutorial
};
