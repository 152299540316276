import React from 'react';
import WarningDialog from '../../../view/common/dialogs/WarningDialog';
import { Msg } from '../../../view/intl';
import makeAppStandardErrorDialog from "./makeAppStandardErrorDialog";
import VerticalSpacer from "../../../view/common/VerticalSpacer";

const HighPageCountDialog = ({ publicPages }) => (
    <WarningDialog
        title="msg: common.aLotOfPages {You have a lot of pages}"
        mctaText="msg: common.ok {OK}"
    >
        <div>
            <Msg k="common.highPageCountRecommendation" params={{ publicPages }}>
                {`Your website has {publicPages} pages and you might experience that it takes longer to publish.
                We recommend deleting some pages.`}
            </Msg>
        </div>
        <VerticalSpacer y={15} />
        <div>
            <Msg k="common.appreciateUseWSB">
                We appreciate that you are using Website Builder!
            </Msg>
        </div>
    </WarningDialog>
);

export default makeAppStandardErrorDialog(HighPageCountDialog);
