/* eslint-disable react/no-unused-prop-types */

import * as React from 'react';
import cx from 'classnames';
import styles from './TabDialog.css';
import { Dialog, DialogBody, DialogFooter } from '../baseDialog/index';
import closeDialog from "../../../../components/App/actionCreators/closeDialog";
import { Tabs, Tab } from "./Tabs";
import type { TabDialogOnTabSwitch as _TabDialogOnTabSwitch } from './flowTypes';
import injectIntl from "../../../intl/injectIntl";

class TabDialog extends Tabs {
    render() {
        const {
                mctaText,
                mctaHandler,
                sctaHandler = () => this.props.dispatch(closeDialog()),
                onClose,
                disabled,
                footer,
                containerClassName,
                dialogbodyClassName,
                stripType,
                closeBtnClassName,
                isFooterRestrictedForDemo,
            } = this.props,
            activeTab = this.getActiveTab();

        return (
            <Dialog
                stripType={stripType}
                containerClassName={containerClassName}
                onClose={onClose}
                className={styles.dialog}
                closeBtnClassName={closeBtnClassName}
            >
                <DialogBody className={cx(styles.container, dialogbodyClassName)}>
                    {super.render()}
                </DialogBody>
                <DialogFooter
                    mctaText={mctaText}
                    mctaHandler={mctaHandler}
                    sctaHandler={sctaHandler}
                    disabled={disabled}
                    isRestrictedForDemo={isFooterRestrictedForDemo}
                >
                    <div className={styles.footer}>{activeTab.props.footer || footer || null}</div>
                </DialogFooter>
            </Dialog>
        );
    }
}
const IntlTabDialog = injectIntl(TabDialog);

export type TabDialogOnTabSwitch = _TabDialogOnTabSwitch;

export { IntlTabDialog as default, Tab };
