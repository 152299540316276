import * as React from 'react';

import { fetchAssets } from "../../../../utils/assetUtils";
import { getUnsplashFileNameFromAsset } from "../../../../../utils/getUnsplashFileNameFromAsset";
import { ImageAsset } from '../../../App/flowTypes';

export const getUnsplashBeaconDetails = (template, componentsMap) => {
    const
        assetsFromComponentsMap: any = Object.keys(componentsMap).reduce(
            (acc, id) => acc.concat(fetchAssets(componentsMap[id])),
            [] as ImageAsset[]
        );
    if (template &&
        template.style &&
        template.style.background &&
        template.style.background.assetData &&
        template.style.background.assetData.asset) {
        assetsFromComponentsMap.push(template.style.background.assetData.asset);
    }
    const photoIds: string[] = [];
    for (let i = 0; i < assetsFromComponentsMap.length; ++i) {
        const asset = assetsFromComponentsMap[i];
        let filename = getUnsplashFileNameFromAsset(asset);
        if (filename) {
            const photoId = filename.substr(9);
            if (photoIds.indexOf(photoId) === -1) {
                photoIds.push(photoId);
            }
        }
    }
    if (photoIds.length) {
        const allIds = photoIds.join();
        const src = `https://views.unsplash.com/v?app_id=24411&photo_id=${allIds}`;
        return <img src={src} style={{ display: "none" }} alt="unsplash" />;
    }
    return (
        <div />
    );
};
