/* @flow */

const
    ONE_LANG_COOKIE_NAME = 'OneLang',
    LocaleCountryMap = {
        da: 'dk',
        de: true,
        en: 'gb',
        es: true,
        fi: true,
        fr: true,
        it: true,
        nb: 'no',
        nl: true,
        pt: true,
        sv: 'se',
    },
    OneComLang = ['en_gb', 'da', 'de', 'es', 'fi', 'fr', 'it', 'nb', 'nl', 'pt', 'sv'],
    DEFAULT_ONE_COM_LANG = 'en_gb';

module.exports = { ONE_LANG_COOKIE_NAME, LocaleCountryMap, OneComLang, DEFAULT_ONE_COM_LANG };
