import type { ComponentsMap, BBox } from "../../redux/modules/children/workspace/flowTypes";
import { getComponentsBBox } from "./getComponentsBBox";
import { isSectionKind, isHoverBoxKind } from "../../components/oneweb/componentKinds";
import { getHiddenComponentsForHoverBox } from "../../components/oneweb/HoverBox/utils";
import type { Attachments } from "../../components/Workspace/epics/componentAttachements/flowTypes";
import { getComponentsMapNoGhosts } from "../../components/oneweb/Code/getComponentsMapNoGhosts";

const filterOutHoverStateCmps = (overlappingComponents, componentId, componentsMap, attachments) => {
    if (!overlappingComponents.length) {
        return overlappingComponents;
    }
    let newOverlappingCmps = [...overlappingComponents];
    [...overlappingComponents, componentId].forEach(cmpId => {
        if (isHoverBoxKind(componentsMap[cmpId] && componentsMap[cmpId].kind)) {
            const excludeCmpIds = getHiddenComponentsForHoverBox(cmpId, attachments, componentsMap);
            newOverlappingCmps = newOverlappingCmps.filter(id => !excludeCmpIds.includes(id));
        }
    });
    return newOverlappingCmps;
};

export function _getOverlappingComponents(
    componentsMap: ComponentsMap,
    componentId: string,
    workspaceBBox: BBox,
    mousePosition: Record<string, any>,
    attachments: Attachments,
): Array<string> {
    const
        component = componentsMap[componentId],
        bBox = getComponentsBBox([component], workspaceBBox),
        { y } = mousePosition;
    let overlappingComponents = getIntersectingComponentsForBBox(componentsMap, bBox, workspaceBBox);
    overlappingComponents = filterOutHoverStateCmps(overlappingComponents, componentId, componentsMap, attachments);
    overlappingComponents.splice(overlappingComponents.indexOf(componentId), 1);
    let overlappingCmpIds = overlappingComponents.sort((componentAId: string, componentBId: string): number =>
            componentsMap[componentBId].orderIndex - componentsMap[componentAId].orderIndex),
        overlappingSectionIds = overlappingCmpIds.filter(id => isSectionKind(componentsMap[id].kind));
    if (overlappingSectionIds.length > 1) {
        let nearestSectionId = overlappingSectionIds.find(id =>
            (componentsMap[id].top <= y && (componentsMap[id].top + componentsMap[id].height) >= y));
        if (nearestSectionId) {
            overlappingSectionIds = [nearestSectionId,
                ...overlappingSectionIds.filter(id => id !== nearestSectionId)];
        }
    }
    overlappingCmpIds = [
        ...overlappingSectionIds,
        ...overlappingCmpIds.filter(id => !isSectionKind(componentsMap[id].kind))
    ];
    return overlappingCmpIds;
}

/**
 * @param componentsMap Array<AnyComponent>
 * @param bBox BBox
 * @returns {Array<AnyComponent>}
 */
export default function getIntersectingComponentsForBBox(
    componentsMap: ComponentsMap,
    bBox: BBox,
    workspaceBBox: BBox
): Array<string> {
    return Object.keys(getComponentsMapNoGhosts(componentsMap)).filter((componentId: string): boolean => {
        const
            component = componentsMap[componentId],
            cbBox: BBox = getComponentsBBox([component], workspaceBBox);
        return _doBBoxIntersect(cbBox, bBox);
    });
}

export function _doBBoxTouch(bBoxA: BBox, bBoxB: BBox) {
    return !(
        bBoxA.left > bBoxB.right ||
        bBoxA.right < bBoxB.left ||
        bBoxA.top > bBoxB.bottom ||
        bBoxA.bottom < bBoxB.top
    );
}

export function _doBBoxIntersect(bBoxA: BBox, bBoxB: BBox) {
    return !(
        bBoxA.left >= bBoxB.right ||
        bBoxA.right <= bBoxB.left ||
        bBoxA.top >= bBoxB.bottom ||
        bBoxA.bottom <= bBoxB.top
    );
}
