export const TEXT_CATEGORY_KEY = 'text';
export const ALL_FILTER_KEY = "all",
    TEXT_STYLES = "textStyles",
    TEXT_LAYOUTS = "text",
    REVIEWS = "text-reviews",
    LISTS = "text-lists",
    TEXT_AND_IMAGE = 'text-and-image';

export const FILTERS = [
    {
        id: ALL_FILTER_KEY,
        title: "msg: common.blocks.all {All}"
    },
    {
        id: TEXT_STYLES,
        showStylesheetStyles: true,
        title: "msg: common.blocks.textStyles {Text styles}"
    },
    {
        id: TEXT_LAYOUTS,
        title: "msg: common.blocks.textLayouts {Text layouts}"
    },
    {
        id: REVIEWS,
        title: "msg: common.blocks.reviews {Reviews}"
    },
    {
        id: LISTS,
        title: "msg: common.blocks.lists {Lists}"
    },
    {
        id: TEXT_AND_IMAGE,
        title: "msg: common.blocks.textAndImage {Text and image}"
    }
];

export const BlocksCategoryMap = {
    [TEXT_LAYOUTS]: true,
    [REVIEWS]: true,
    [LISTS]: true,
    [TEXT_AND_IMAGE]: true
};

export const BlocksCategorySequence = {
    [TEXT_AND_IMAGE]: (blocks: Array<{ key: string }>) => {
        const mapBlocks = blocks.reduce((acc, block) => ({ ...acc, [block.key]: block }), {});
        return {
            left: ["text-and-image-6", "text-and-image-2"].map(id => mapBlocks[id]),
            right: ["text-and-image-1", "text-and-image-3", "text-and-image-4"].map(id => mapBlocks[id])
        };
    },
    [LISTS]: (blocks: Array<{ key: string }>) => {
        const mapBlocks = blocks.reduce((acc, block) => ({ ...acc, [block.key]: block }), {});
        return {
            left: ["text-lists-2", "text-lists-4"].map(id => mapBlocks[id]),
            right: ["text-lists-3"].map(id => mapBlocks[id]),
            row: ["text-lists-1"].map(id => mapBlocks[id])
        };
    }
};

