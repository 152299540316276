import React from 'react';
import { Msg } from '../../../view/intl/index';
import getCenteredDialogConfig from '../../DialogManager/getCenteredDialogConfig';
import { closeDialog } from '../../App/actionCreators/index';
import WarningDialog from "../../../view/common/dialogs/WarningDialog/index";

const
    dialogId = 'BackupsLoadFailedDialog',
    width = 490,
    height = 350,
    BackupLoadFailedDialog = ({ dispatch }) => (
        <WarningDialog
            title="msg: common.errorOccurred {An error occurred}"
            mctaText="msg: common.ok {OK}"
            mctaHandler={() => dispatch(closeDialog())}
        >
            <Msg k="common.apiErrorDialog">
                Sorry, something went wrong. Please contact support.
            </Msg>
        </WarningDialog>
    ),
    dialog = getCenteredDialogConfig(width, height, BackupLoadFailedDialog);

export { dialogId, dialog };
