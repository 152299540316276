/* eslint-disable max-len */

import React from 'react';
import cx from 'classnames';
import Scrollbar from '../../../../../view/common/Scrollbar/index';
import * as styles from '../ModernLayoutLeftPanel.css';
import ModernLayoutsList from './ModernLayoutsList';
import {
    HIDE_MODERN_LAYOUT_LEFT_PANEL,
} from '../../../actionTypes';
import {
    openMHFDeactivationDialogAC
} from '../../../dialogs/MHFDeactivationDialog/actionCreators';
import { Intl, Msg } from '../../../../../view/intl/index';
import { getHeaderLayoutsMap, defaultHeaderId } from '../../../layoutsData/headerLayouts';
import { getFooterLayoutsMap, defaultFooterId } from '../../../layoutsData/footerLayouts';
import type { Stylesheets } from "../../../../Workspace/epics/stylesheets/flowTypes";
import type { SectionComponent } from "../../../../oneweb/Section/flowTypes";
import { getIsModernLayoutActivated } from "../../../preview_utils";
import type { LeftPanelEpicState as ModernLayoutLeftPanel, NewLayoutDataPerSection } from "../../../flowTypes";
import ModernLayoutYourItem from "./ModernLayoutYourItem";
import type { TemplateComponent } from "../../../../oneweb/Template/flowTypes";
import type { WebShopMHFEpicState } from "../../../../oneweb/WebShopMHF/flowTypes";
import { BetaTag } from '../../../../../view/common/Tag';

type Props = {
    intl: Intl,
    dispatch: Dispatch,
    leftPanel: ModernLayoutLeftPanel,
    headerSection: SectionComponent,
    footerSection: SectionComponent,
    template: TemplateComponent,
    componentsEval: Record<string, any>,
    globalVariables: Record<string, any>,
    styleSheets: Stylesheets,
    MHFStoredLayoutsData: NewLayoutDataPerSection,
    webshopMHFData: WebShopMHFEpicState,
};

const headerTitle = 'msg: component.section.headerLayouts {Header layouts}',
    footerTitle = 'msg: component.section.footerLayouts {Footer layouts}',
    disableLayoutText = 'msg: modernLayout.leftPanel.disableLayout {Disable layout}',
    headerSubTitle = 'msg: modernLayout.leftPanel.headerSubTitle {Select a responsive layout for your header.}',
    footerSubTitle = 'msg: modernLayout.leftPanel.footerSubTitle {Select a responsive layout for your footer.}';

const ModernLayoutModeOn = (props: Props) => {
    const {
            intl,
            dispatch,
            leftPanel,
            headerSection,
            footerSection,
            template,
            componentsEval,
            globalVariables,
            styleSheets,
            MHFStoredLayoutsData,
            webshopMHFData,
        } = props,
        isFooter = leftPanel.isFooter,
        section = isFooter ? footerSection : headerSection,
        activeLayoutId = (section.modernLayout && section.modernLayout.layoutId) || (isFooter ? defaultFooterId : defaultHeaderId),
        layoutsMap = isFooter ? getFooterLayoutsMap(webshopMHFData) : getHeaderLayoutsMap(),
        isModernLayoutActivated = getIsModernLayoutActivated(componentsEval.state.componentsMap);

    let layoutTitle = isFooter ? footerTitle : headerTitle,
        layoutDescription = isFooter ? footerSubTitle : headerSubTitle;

    return (
        <React.Fragment>
            <Scrollbar height="calc(100vh - 150px)">
                <div className={cx(styles.container)}>
                    <div className={cx(styles.modernLayoutTitle)}>
                        {intl.msgJoint(layoutTitle)}
                    </div>
                </div>
                <div className={styles.container}>
                    <div className={cx(styles.modernLayoutSubTitle)}>
                        {intl.msgJoint(layoutDescription)}
                    </div>
                </div>
                <div className={styles.container}>
                    <div
                        className={cx(styles.disableLayoutBtn, { [styles.disable]: !isModernLayoutActivated })}
                        onClick={() => {
                            dispatch(openMHFDeactivationDialogAC());
                        }}
                    >
                        {intl.msgJoint(disableLayoutText)}
                    </div>
                    <BetaTag containerClassName={cx(styles.tagContainer)} />
                </div>

                <React.Fragment>
                    <ModernLayoutYourItem
                        intl={intl}
                        leftPanel={leftPanel}
                        layoutItem={layoutsMap[activeLayoutId]}
                        template={template}
                        componentsEval={componentsEval}
                        globalVariables={globalVariables}
                        styleSheets={styleSheets}
                        section={section}
                        MHFStoredLayoutsData={MHFStoredLayoutsData}
                        dispatch={dispatch}
                        isModernLayoutActivated={isModernLayoutActivated}
                        webshopMHFData={webshopMHFData}
                    />
                    <ModernLayoutsList
                        intl={intl}
                        leftPanel={leftPanel}
                        template={template}
                        componentsEval={componentsEval}
                        globalVariables={globalVariables}
                        styleSheets={styleSheets}
                        dispatch={dispatch}
                        section={section}
                        activeLayoutId={activeLayoutId}
                        isModernLayoutActivated={isModernLayoutActivated}
                        webshopMHFData={webshopMHFData}
                    />
                </React.Fragment>
            </Scrollbar>
            <div className={cx(styles.footer)}>
                <button
                    type="button"
                    className={styles.doneBtn}
                    onClick={() => {
                        dispatch({ type: HIDE_MODERN_LAYOUT_LEFT_PANEL });
                    }}
                >
                    <Msg k="common.close">
                        Close
                    </Msg>
                </button>
            </div>
        </React.Fragment>
    );
};

export { ModernLayoutModeOn };
