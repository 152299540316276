import { DefaultMobilePreview } from "../../Preview/View/Mobile/index";
import { injectIntl } from "../../../view/intl/index";

class MobileView extends DefaultMobilePreview {
    setIframeRef: any
    previewIframeRef: any;
    props: any;

    constructor(props) {
        super(props);
        this.setIframeRef = previewIframeRef => { this.previewIframeRef = previewIframeRef; };
    }

    componentDidMount() {
        if (this.props.hasPages) {
            this.previewIframeRef.src = this.props.src;
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.hasPages && this.props.src !== prevProps.src) {
            this.previewIframeRef.src = this.props.src;
        }
    }
}

export default injectIntl(MobileView);
