const
    BackgroundOverlay = {
        NONE: 'none',
        DIM: 'block'
    },
    // BackgroundOverlayOptions = [{
    //     label: 'msg: common.none {None}',
    //     value: BackgroundOverlay.NONE
    // }, {
    //     label: 'msg: common.dim {Dim}',
    //     value: BackgroundOverlay.DIM
    // }],
    BackgroundSize = {
        FIT: 'contain',
        FILL: 'cover',
        ZOOM: 100,
        CROP: 'crop'
    },
    BackgroundSizeOptions = [{
        label: 'msg: common.fitContentToFrame {Fit content to frame}',
        value: BackgroundSize.FIT
    }, {
        label: 'msg: common.background.size.fillFrameProportionally {Fill frame proportionally}',
        value: BackgroundSize.FILL
    }, {
        label: 'msg: common.originalSize {Original size}',
        value: BackgroundSize.ZOOM
    }],
    MobileBackgroundVideoSizeOptions = [{
        label: 'msg: common.background.size.fillFrameProportionally {Fill frame proportionally}',
        value: BackgroundSize.FILL
    }, {
        label: 'msg: common.fitContentToFrame {Fit content to frame}',
        value: BackgroundSize.FIT
    },
    // {
    //     label: 'msg: common.background.size.cropVideo {Crop video}',
    //     value: BackgroundSize.CROP
    // }
    ],
    DefaultAssetData = {
        opacity: 1,
        asset: null,
        repeat: [true, true],
        position: ['0%', '0%'],
        size: 100,
        scrollEffect: null
    },
    BackgroundImagePositionMsgMap = {
        '0,0': 'msg: bg.imagePosition.topLeft {Top, Left}',
        '50,0': 'msg: bg.imagePosition.topCenter {Top, Center}',
        '100,0': 'msg: bg.imagePosition.topRight {Top, Right}',
        '0,50': 'msg: bg.imagePosition.middleLeft {Middle, Left}',
        '50,50': 'msg: bg.imagePosition.middleCenter {Middle, Center}',
        '100,50': 'msg: bg.imagePosition.middleRight {Middle, Right}',
        '0,100': 'msg: bg.imagePosition.bottomLeft {Bottom, Left}',
        '50,100': 'msg: bg.imagePosition.bottomCenter {Bottom, Center}',
        '100,100': 'msg: bg.imagePosition.bottomRight {Bottom, Right}'
    };
export {
    BackgroundOverlay,
    //BackgroundOverlayOptions,
    BackgroundSize,
    BackgroundSizeOptions,
    DefaultAssetData,
    MobileBackgroundVideoSizeOptions,
    BackgroundImagePositionMsgMap
};
