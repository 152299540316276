import * as R from 'ramda';
import makeEpic, { fullEpicUndoablePath } from '../../../../../epics/makeEpic';
import valueActionType from './valueActionType';
import makeStateSelectorReducer from '../../../../../epics/makeStateSelectorReducer';
import pageDatasetLoadedActionType from "../../../../App/epics/pageDataset/pageDatasetLoadedActionType";
import * as undoManagerUpdateReasons from '../../../../../epics/undoManager/updateReasons';

import { LOCALE_CHANGED } from '../../../../presentational/LocaleList/actionTypes';
import { TEMPLATE_WIDTH_CHANGED, TEMPLATE_WIDTH_CHANGE_FINISHED } from '../templateLines/actionTypes';
import { to } from "../../../../../../dal/pageMapAdapter/mappers/Template/index";
import * as updateReasons from './updateReasons';
import TemplateKind from '../../kind';
import * as Actions from "../../actionTypes";
import { SCROLL, AUTO } from "../../constants";
import * as styleMapper from './styleMapper';
import * as bgSetters from "../../../../../setters/backgroundSetter";
import * as gradientSetters from "../../../../../setters/gradientSetter";
import * as mp from "../../../../../mappers/path";
import { arrayToTrueMap } from "../../../../../utils/arrayToTrueMap";
import { SITE_DOCUMENTS_UPDATE_SUCCESS, SITE_DATA_UPDATE_SUCCESS, KEY_PRESS } from "../../../../App/actionTypes";
import type { AssetsReplacements } from "../../../../../utils/assetUtils";
import { makeReplaceAssetsUpdaters } from "../../../../Workspace/epics/componentsEval/makeReplaceAssetsUpdaters";
import { setToPath, getByPath } from "../../../../../utils/ramdaEx";
import { WhenRepositoryDomainSelector } from '../../../../../redux/middleware/cookie/selectorActionTypes';
import {
    ReceiveOnlyIsShiftPressedActionType,
    ReceiveOnlyIsCtrlLikePressedActionType
} from '../../../../App/epics/isKeyPressed/selectorActionTypes';
import { openDialog } from "../../../../App/actionCreators/index";
import TemplateWidthDialogId from "../../templateWidthDialog/TemplateWidthDialogId";
import { DataPageStylesheet, DataPageTemplate } from "../../../../../../dal/model/index";
import { getBackgroundPosition } from "../../../../../view/oneweb/commonComponentSelectors";
import { CENTER as HorizontalCenter } from '../../../../presentational/AlignmentTable/HorizontalAlign';
import { MIDDLE as VerticalCenter } from '../../../../presentational/AlignmentTable/VerticalAlign';
import { ROThemeColorSelector } from '../../../../Workspace/epics/stylesheets/selectors';
import p from "../../../../../utils/pipePath";
import { getDefultLocaleId } from "../../../../Locale/getDefaultLocale";
import { ADD_REMOVE_WEBSHOP_STRIP } from '../../../../ModernLayouts/actionTypes';

const DEFAULT_LOCALE = getDefultLocaleId();

const
    makeStateSetterUpdater = (actionType, stateSetter) => ({
        conditions: [actionType],
        reducer: reducerConfig => ({
            state: stateSetter(reducerConfig),
            updateReason: actionType,
            scope: reducerConfig.scope
        })
    }),
    makeAssetAttributeStateSetterUpdater = (actionType, attributeSetter) => makeStateSetterUpdater(
        actionType,
        ({ state, values: [attributeValue] }) => attributeSetter(mp.styleBackground, attributeValue, state)
    ),
    makeGradientAttributeStateSetterUpdater = (actionType, attributeSetter) => makeStateSetterUpdater(
        actionType,
        ({ state, values: [attributeValue] }) => attributeSetter(mp.styleBackgroundGradient, attributeValue, state)
    );

const
    undoableReasonsMap = arrayToTrueMap([
        updateReasons.LOCALE_CHANGED,
        updateReasons.TEMPLATE_WIDTH_CHANGE_FINISHED,
        updateReasons.TEMPLATE_TITLE_CHANGE,
        updateReasons.TEMPLATE_VERTICAL_SCROLL_TOGGLE,
        Actions.TEMPLATE_ASSET_CHANGE,
        Actions.TEMPLATE_ASSET_UNSET,
        Actions.TEMPLATE_ASSET_CHANGE_REPEAT,
        Actions.TEMPLATE_ASSET_CHANGE_POSITION,
        Actions.TEMPLATE_BG_IMAGE_SCROLL_EFFECT_CHANGE,
        Actions.TEMPLATE_ASSET_CHANGE_SIZE,
        Actions.TEMPLATE_COLOR_CHANGE,
        Actions.TEMPLATE_COLOR_REMOVE,
        Actions.TEMPLATE_GRADIENT_COLOR_CHANGE,
        Actions.TEMPLATE_GRADIENT_COLOR_REMOVE,
        Actions.TEMPLATE_GRADIENT_COLOR_SET_AUTO_COLOR,
        Actions.TEMPLATE_GRADIENT_COLOR_UNSET_AUTO_COLOR,
        Actions.TEMPLATE_GRADIENT_DIRECTION_CHANGE,
        Actions.TEMPLATE_GRADIENT_FADE_POINT_CHANGE,
        Actions.TEMPLATE_OPACITY_CHANGE,
        Actions.TOGGLE_TEMPLATE_CATEGORY_ACTION,
        Actions.TEMPLATE_THEME_CHANGE,
        Actions.TEMPLATE_DISPLAY_SHOP_STRIP_IN_FOOTER
    ]),
    templateIsUndoableChange = ({ updateReason }: { updateReason: string }) => undoableReasonsMap[updateReason],
    replaceAssetReducer = (state: Object, assetsReplacements: AssetsReplacements): Object => {
        const asset: undefined | { url: string } = R.path(mp.styleBackgroundAsset, state);
        if (asset) {
            const replaceAssetUrl = Object.keys(assetsReplacements).find(url => url === asset.url);
            if (replaceAssetUrl) {
                return setToPath(mp.styleBackgroundAsset, { ...asset, ...assetsReplacements[replaceAssetUrl] }, state);
            }
        }

        return state;
    },
    getInitializedTemplate = (template: DataPageTemplate, stylesheet: DataPageStylesheet) => (styleMapper.to({
        kind: TemplateKind,
        ...to(template),
        style: {},
        pages: [],
    }, stylesheet)),
    templateEpic = makeEpic({
        undo: {
            isUndoableChange: templateIsUndoableChange,
            undoablePaths: fullEpicUndoablePath
        },
        valueActionType,
        defaultScope: {
            isInitializing: false,
            templateWidthWarningShown: 0
        },
        updaters: [
            {
                conditions: [pageDatasetLoadedActionType],
                reducer: ({ scope, values: [{ template, stylesheet }] }) => ({
                    state: getInitializedTemplate(template, stylesheet),
                    updateReason: updateReasons.TEMPLATE_INITIALIZING,
                    scope: {
                        ...scope,
                        isInitializing: true
                    },
                    actionToDispatch: Actions.loadTemplateComponentPagesApiAction(template.id)
                })
            },
            {
                conditions: [Actions.LOAD_TEMPLATE_PAGES_SUCCESS_ACTION],
                reducer: ({ state, values: [pages], scope }) => {
                    const { isInitializing } = scope;
                    return {
                        state: { ...state, pages },
                        scope: { ...scope, /*isGettingTemplatePages: false, */isInitializing: false },
                        updateReason: isInitializing
                            ? undoManagerUpdateReasons.UNDO_INITIAL_STATE
                            : updateReasons.TEMPLATE_AFTER_SITE_DATA_CHANGE
                    };
                }
            },
            {
                conditions: [SITE_DATA_UPDATE_SUCCESS],
                reducer: ({ state, scope }) => {
                    if (state) {
                        return {
                            state,
                            scope: { ...scope, isInitializing: false },
                            actionToDispatch: Actions.loadTemplateComponentPagesApiAction(state.id)
                        };
                    }
                    return { state, scope };
                }
            },
            {
                conditions: [SITE_DOCUMENTS_UPDATE_SUCCESS],
                reducer: ({ state, scope, values: [siteDocuments] }) => {
                    if (siteDocuments.template) {
                        const { time, etag } = siteDocuments.template;
                        return {
                            state: R.evolve({
                                etag: () => etag,
                                time: () => time,
                                rev: () => time
                            }, state),
                            scope,
                            updateReason: updateReasons.TEMPLATE_AFTER_SITE_DATA_CHANGE
                        };
                    }

                    return { state, scope };
                }
            },
            {
                conditions: [
                    ReceiveOnlyIsCtrlLikePressedActionType,
                    ReceiveOnlyIsShiftPressedActionType,
                    KEY_PRESS,
                    WhenRepositoryDomainSelector,
                ],
                reducer: ({ values: [isCtrlDown, isShiftDown, { key }], state, scope }) => {
                    const allLocales = ["da", "de", "es", "fi", "fr", "it", "nb", "nl", "pt", "sv"];
                    if (!isCtrlDown || !isShiftDown) return { state, scope };

                    if (key === '4' || key === '$') {
                        return ({
                            state: R.assoc(
                                'locale',
                                allLocales[allLocales.indexOf(state.locale) + 1] || allLocales[0],
                                state
                            ),
                            updateReason: updateReasons.LOCALE_CHANGED,
                            scope
                        });
                    }

                    return { state, scope };
                }
            },
            {
                conditions: [LOCALE_CHANGED],
                reducer: ({ values: [locale = DEFAULT_LOCALE], state: epicState, scope }) => ({
                    state: R.assoc('locale', locale, epicState),
                    updateReason: updateReasons.LOCALE_CHANGED,
                    scope
                })
            },
            {
                conditions: [TEMPLATE_WIDTH_CHANGED],
                reducer: ({ values: [width], state: epicState, scope }) => ({
                    state: R.assoc('width', width, epicState),
                    updateReason: updateReasons.TEMPLATE_WIDTH_CHANGED,
                    scope
                })
            },
            {
                conditions: [TEMPLATE_WIDTH_CHANGE_FINISHED],
                reducer: ({ state: epicState, scope }) => {
                    const { templateWidthWarningShown } = scope;
                    let actionToDispatch: Action | null = null,
                        newScope = scope;
                    if (epicState.width > 1200 && templateWidthWarningShown < 2) {
                        actionToDispatch = openDialog(TemplateWidthDialogId);
                        newScope = {
                            ...scope,
                            templateWidthWarningShown: (templateWidthWarningShown + 1)
                        };
                    }
                    return {
                        state: { ...epicState },
                        updateReason: updateReasons.TEMPLATE_WIDTH_CHANGE_FINISHED,
                        scope: newScope,
                        actionToDispatch
                    };
                }
            },
            {
                conditions: [Actions.TEMPLATE_TITLE_CHANGE],
                reducer: ({ values: [text], state: epicState, scope }) => ({
                    state: { ...epicState, name: text },
                    updateReason: updateReasons.TEMPLATE_TITLE_CHANGE,
                    scope
                })
            },
            {
                conditions: [
                    Actions.TEMPLATE_THEME_CHANGE
                ],
                reducer: ({ values: [{ backgroundTheme: selectedTheme }], state: epicState, scope }) => {
                    return {
                        state: { ...epicState, selectedTheme },
                        updateReason: Actions.TEMPLATE_THEME_CHANGE,
                        scope
                    };
                }
            },
            {
                conditions: [Actions.TEMPLATE_VERTICAL_SCROLL_TOGGLE],
                reducer: ({ state: epicState, scope }) => ({
                    state: { ...epicState, verticalScroll: epicState.verticalScroll === SCROLL ? AUTO : SCROLL },
                    updateReason: updateReasons.TEMPLATE_VERTICAL_SCROLL_TOGGLE,
                    scope
                })
            },
            {
                conditions: [Actions.TEMPLATE_DISPLAY_SHOP_STRIP_IN_FOOTER],
                reducer: ({ state, scope }) => ({
                    state: { ...state, showShopStripInFooter: !state.showShopStripInFooter },
                    updateReason: updateReasons.TEMPLATE_DISPLAY_SHOP_STRIP_IN_FOOTER,
                    actionToDispatch: { type: ADD_REMOVE_WEBSHOP_STRIP },
                    scope
                })
            },
            {
                conditions: [Actions.TEMPLATE_DISPLAY_SHOP_STRIP_IN_FOOTER_SET_TRUE],
                reducer: ({ state, scope }) => ({
                    state: { ...state, showShopStripInFooter: true },
                    updateReason: updateReasons.TEMPLATE_DISPLAY_SHOP_STRIP_IN_FOOTER,
                    scope
                })
            },
            {
                conditions: [Actions.TEMPLATE_DELETE_MISSING_ASSET],
                reducer: ({ values: [replacements], state: epicState, scope }) => {
                    const assetUrlPath = ['style', 'background', 'assetData', 'asset', 'url'];
                    const assetUrl = R.view(R.lensPath(assetUrlPath), epicState);
                    if (assetUrl && replacements.indexOf(assetUrl) > -1) {
                        return {
                            state: epicState,
                            scope,
                            actionToDispatch: { type: Actions.TEMPLATE_ASSET_UNSET }
                        };
                    }
                    return {
                        state: epicState,
                        scope
                    };
                }
            },
            {
                conditions: [Actions.TEMPLATE_REPLACE_MISSING_ASSET],
                reducer: ({ values: [replacements], state: epicState, scope }) => {
                    const assetUrlPath = ['style', 'background', 'assetData', 'asset', 'url'];
                    const assetUrl = R.view(R.lensPath(assetUrlPath), epicState);
                    if (assetUrl && replacements[assetUrl]) {
                        return {
                            state: epicState,
                            scope,
                            actionToDispatch: {
                                type: Actions.TEMPLATE_ASSET_CHANGE,
                                payload: {
                                    asset: replacements[assetUrl]
                                }
                            }
                        };
                    }
                    return {
                        state: epicState,
                        scope
                    };
                }
            },
            makeStateSetterUpdater(
                Actions.TEMPLATE_ASSET_CHANGE,
                ({ state, values: [{ asset }] }) => bgSetters.setAsset(mp.styleBackground, asset, state)
            ),
            makeStateSetterUpdater(
                Actions.TEMPLATE_ASSET_UNSET,
                ({ state }) => bgSetters.unsetAsset(mp.styleBackground, state)
            ),
            makeAssetAttributeStateSetterUpdater(Actions.TEMPLATE_ASSET_CHANGE_REPEAT, bgSetters.setAssetRepeat),
            makeAssetAttributeStateSetterUpdater(Actions.TEMPLATE_ASSET_CHANGE_POSITION, bgSetters.setAssetPosition),
            makeAssetAttributeStateSetterUpdater(Actions.TEMPLATE_ASSET_CHANGE_SIZE, bgSetters.setAssetSize),
            makeStateSetterUpdater(
                Actions.TEMPLATE_BG_IMAGE_SCROLL_EFFECT_CHANGE,
                ({ state, values: [scrollEffect] }) => {
                    let newState = bgSetters.setAssetScrollEffect(mp.styleBackground, scrollEffect, state),
                        bgPosition = getBackgroundPosition(state);
                    if (scrollEffect && (!bgPosition || (bgPosition[1] !== VerticalCenter))) {
                        newState = bgSetters.setAssetPosition(
                            mp.styleBackground,
                            [((bgPosition && bgPosition[0]) || HorizontalCenter), VerticalCenter],
                            newState
                        );
                    }
                    return newState;
                }
            ),
            makeStateSetterUpdater(
                Actions.TEMPLATE_COLOR_CHANGE,
                ({ state, values: [{ color }] }) => bgSetters.setBackgroundSolidColor(mp.styleBackground, color, state)
            ),
            makeStateSetterUpdater(
                Actions.TEMPLATE_COLOR_REMOVE,
                ({ state }) => bgSetters.unsetBackgroundColor(mp.styleBackground, state)
            ),
            makeStateSetterUpdater(
                Actions.TEMPLATE_GRADIENT_COLOR_CHANGE,
                ({ state, values: [{ color }] }) =>
                    bgSetters.setBackgroundGradientColor(mp.styleBackground, color, state)
            ),
            makeStateSetterUpdater(
                Actions.TEMPLATE_GRADIENT_COLOR_REMOVE,
                ({ state }) => gradientSetters.unsetGradientColor(mp.styleBackgroundGradient, state)
            ),
            {
                conditions: [
                    Actions.TEMPLATE_GRADIENT_COLOR_SET_AUTO_COLOR,
                    ROThemeColorSelector
                ],
                reducer: ({
                    state: epicState,
                    scope,
                    values: [{ themeColor: selectedGradientTheme }, themeColorsData]
                }) => {
                    return {
                        state: R.pipe(
                            R.when(
                                (epicState) => R.not(getByPath(p(mp.styleBackground, mp.colorData, mp.gradient), epicState)),
                                bgSetters.setBackgroundGradientColor(mp.styleBackground, themeColorsData[selectedGradientTheme])
                            ),
                            // @ts-ignore
                            (epicState) => ({ ...epicState, selectedGradientTheme })
                        )(epicState),
                        updateReason: Actions.TEMPLATE_GRADIENT_COLOR_SET_AUTO_COLOR,
                        scope
                    };
                }
            },
            makeStateSetterUpdater(
                Actions.TEMPLATE_GRADIENT_COLOR_UNSET_AUTO_COLOR,
                ({ state }) => ({ ...state, selectedGradientTheme: null })
            ),
            makeGradientAttributeStateSetterUpdater(
                Actions.TEMPLATE_GRADIENT_DIRECTION_CHANGE,
                gradientSetters.setGradientDirection
            ),
            makeGradientAttributeStateSetterUpdater(
                Actions.TEMPLATE_GRADIENT_FADE_POINT_CHANGE,
                gradientSetters.setGradientFadePoint
            ),
            makeStateSetterUpdater(
                Actions.TEMPLATE_OPACITY_CHANGE,
                ({ state, values: [opacity] }) => bgSetters.setBackgroundOpacity(mp.styleBackground, opacity, state)
            ),
            makeStateSetterUpdater(
                Actions.TEMPLATE_OPACITY_CHANGE_AUTO_COLOR,
                ({ state, values: [{ opacity }] }) => bgSetters.setBackgroundOpacity(mp.styleBackground, opacity, state)
            ),
            ...makeReplaceAssetsUpdaters(replaceAssetReducer, true /* useUpdateReason */),
            makeStateSetterUpdater(
                Actions.TOGGLE_TEMPLATE_CATEGORY_ACTION,
                ({ state, values: [category] }) => {
                    const
                        { categories } = state,
                        idx = categories.indexOf(category),
                        nextCategories = [...categories];

                    if (idx > -1) nextCategories.splice(idx, 1);
                    else nextCategories.push(category);

                    return { ...state, categories: nextCategories };
                },
            ),
        ]
    }),
    reducerFactory = ({ prop, defaultValue }) => makeStateSelectorReducer(
        templateEpic.reducer,
        valueActionType,
        R.pipe(R.path([prop]), R.defaultTo(defaultValue))
    ),
    widthReducer = reducerFactory({ prop: 'width', defaultValue: 0 }),
    localeReducer = reducerFactory({ prop: 'locale', defaultValue: DEFAULT_LOCALE }),
    nameReducer = reducerFactory({ prop: 'name', defaultValue: '' });

export {
    templateEpic,
    templateEpic as default,
    widthReducer,
    localeReducer,
    nameReducer,
    templateIsUndoableChange,
    getInitializedTemplate,
};
