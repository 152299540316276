import CALL_API from "../../../redux/middleware/api/CALL_API";
import {
    GET_DESIGN_LOCALIZATIONS_REQUEST,
    GET_DESIGN_LOCALIZATIONS_SUCCESS,
    GET_DESIGN_LOCALIZATIONS_FAILURE
} from "../actionTypes";
import type { ApiAction } from "../../../redux/middleware/api/flowTypes";

// TODO Fetch only required language WBTGEN-13052
export const getDesignLocalizations = (designAccountName: string): ApiAction => ({
    [CALL_API]: {
        types: [
            GET_DESIGN_LOCALIZATIONS_REQUEST,
            GET_DESIGN_LOCALIZATIONS_SUCCESS,
            GET_DESIGN_LOCALIZATIONS_FAILURE
        ],
        endpoint: 'getDesignLocalizations',
        endpointParams: designAccountName
    }
});
