// @ts-nocheck
/* eslint-disable max-len */

import React from "react";
import { connect } from 'react-redux';
import cx from "classnames";
import * as styles from './Preview.css';
import { Msg } from "../../../../view/intl/index";
import TopBarItemContainer from "../../../TopBar/view/TopBarItemContainer";
import topBarStyles from "../../../TopBar/view/TopBar.css";
import PreviewSwitcher from '../../../Preview/View/PreviewToolbar/previewSwitcher';
import TopBarHelp from "../../../TopBar/view/TopBarItemMenu/TopBarHelp/index";
import PrimaryButton from '../../../../view/common/Button/PrimaryButton';
import { valueActionType } from "../../../App/epics/siteData/valueActionType";
import chatLoadedVAT from "../../../TopBar/epics/chat/valueActionType";
import topBarPopupVAT from "../../../TopBar/epics/popup/valueActionType";
import { makeEpicStateSelector } from "../../../../epics/makeEpic";
import templateSelectorPreviewVAT from "../../epics/preview/valueActionType";
import templateSelectorStyles from "../TemplateSelector.css";
import { Popups } from "../../../TopBar/constants";
import * as Actions from "../../actionTypes";
import type { AppState } from "../../../../redux/modules/flowTypes";
import { ViewTypes } from "../../constants";

type TopBarPreviewStateType = {
    backBtnContainerHover: boolean;
}
type TopBarPreviewProps = {
    mobileView: boolean;
    popupId: string;
    chatLoaded: boolean;
    template: Record<string, any>;
    siteData: Record<string, any>;
    dispatch: Dispatch;
    importInProgress: boolean;
}

class TopBarPreview extends React.PureComponent<TopBarPreviewProps, TopBarPreviewStateType> {
    constructor(props) {
        super(props);
        this.state = {
            backBtnContainerHover: false
        };
        this.onMouseOver = this.onMouseOver.bind(this);
        this.onMouseOut = this.onMouseOut.bind(this);
    }

    onMouseOver: Function;
    onMouseOver() {
        this.setState({ backBtnContainerHover: true });
    }

    onMouseOut: Function;
    onMouseOut() {
        this.setState({ backBtnContainerHover: false });
    }

    render() {
        const {
                themeColors,
                mobileView,
                popupId,
                chatLoaded,
                template,
                siteData,
                dispatch,
                importInProgress,
                backButtonActionType
            } = this.props,
            { backBtnContainerHover } = this.state;

        const isHelpPopupShown = popupId === Popups.HELP;

        return (
            <div key={0} className={`${topBarStyles.topBar} ${styles.topBar}`}>
                <div
                    onClick={() => dispatch({ type: Actions.BACK_TO_TEMPLATE_SELECTOR, payload: { backButtonActionType } })}
                    onMouseOver={this.onMouseOver}
                    onMouseOut={this.onMouseOut}
                    className={styles.backButtonContainer}
                >
                    <span
                        className={
                            cx(
                                templateSelectorStyles.leftArrowIcon,
                                {
                                    [templateSelectorStyles.leftArrowIconBlue]: backBtnContainerHover,
                                    [templateSelectorStyles.leftArrowIconBlack]: !backBtnContainerHover
                                }
                            )
                        }
                    />
                    <Msg k="common.back" style={{ marginBottom: '.5px' }}>Back</Msg>
                </div>
                {/* </div> */}
                <div className={styles.startWithThisDesign}>
                    <PrimaryButton
                        onClick={() =>
                            dispatch({ type: Actions.TEMPLATE_SELECTOR_IMPORT_TEMPLATE_CLICK, payload: { ...template, themeColors } })}
                        className={styles.buttonStyle}
                    >
                        {
                            siteData.getAllPageRefs().length ?
                                <Msg k="web.panels.TemplateSelector.ImportTemplate">Import template</Msg> :
                                <Msg k="templateSelector.startWithThisDesign">Start with this design</Msg>
                        }
                    </PrimaryButton>
                </div>
                <div>
                    <TopBarItemContainer>
                        <PreviewSwitcher
                            mobileView={mobileView}
                            onMobilePreview={() => dispatch({ type: Actions.CHANGE_TEMPLATE_SELECTOR_PREVIEW_MODE, payload: ViewTypes.mobileVertical })}
                            onDesktopPreview={() => dispatch({ type: Actions.CHANGE_TEMPLATE_SELECTOR_PREVIEW_MODE, payload: ViewTypes.desktop })}
                        />
                    </TopBarItemContainer>
                    <TopBarItemContainer disableMouseDown>
                        <TopBarHelp
                            isShown={isHelpPopupShown}
                            chatLoaded={chatLoaded}
                            popupContainerClassName={styles.helpPopupContainer}
                            tipClassName={styles.helpPopupTip}
                            showQuickTour={false}
                            dispatch={dispatch}
                        />
                    </TopBarItemContainer>
                </div>
                {importInProgress && <div className={styles.disableTopBar} />}
            </div>
        );
    }
}

const templateSelectorPreviewEpicStateSelector = makeEpicStateSelector(templateSelectorPreviewVAT);

const mapStateToProps = ((appState: AppState) => ({
    chatLoaded: appState.epics[chatLoadedVAT].state.loaded,
    popupId: appState.epics[topBarPopupVAT].state && appState.epics[topBarPopupVAT].state.id,
    siteData: appState.epics[valueActionType].state,
    state: templateSelectorPreviewEpicStateSelector(appState),
    importInProgress: appState.epics[templateSelectorPreviewVAT].state.loading
}));

export default connect(mapStateToProps)(TopBarPreview);
