import React from 'react';
import cx from 'classnames';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { BasicDialog } from './BasicDialog';

const useStyles = makeStyles((theme) => ({
    paper: {
        [theme.breakpoints.down('sm')]: {
            position: "absolute",
            bottom: 0,
            maxWidth: "100%",
            margin: 0,
        },
    },
    containerClass: {
        padding: "71px 60px 58px",
    },
    dialogTitle: {
        padding: "0 0 20px 0",
        fontSize: "30px",
        lineHeight: "34px",
    },
    dialogContent: {
        padding: "0 0 30px 0",
    },
    button: {
        height: "50px",
        padding: "0 30px",
        '&:hover': {
            color: theme.palette.custom.colorWhite,
            background: theme.palette.custom.colorScienceBlue,
        },
    }
}), { name: "StandardDialog" });

export const StandardDialog = (props) => {
    const {
        title,
        buttons,
        onClose,
        containerClass,
        contentClass,
        showCloseIcon = true,
        isFullScreenInMobile = false,
        children,
        dialogId
    } = props;
    const classes = useStyles();

    return (
        <BasicDialog
            onClose={onClose}
            showCloseIcon={showCloseIcon}
            isFullScreenInMobile={isFullScreenInMobile}
            classes={{ paper: classes.paper }}
            containerClass={cx(classes.containerClass, containerClass)}
            dialogId={dialogId}
        >
            {
                title && (
                    <DialogTitle className={classes.dialogTitle} id="dialog-title">
                        {title}
                    </DialogTitle>
                )
            }
            <DialogContent className={cx(classes.dialogContent, contentClass)}>
                {children}
            </DialogContent>
            <DialogActions>
                {
                    buttons && buttons.map((button, i) => {
                        return (
                            <Button key={i} variant={button.type} color="primary" className={classes.button} onClick={button.onClick}>
                                {button.text}
                            </Button>
                        );
                    })
                }
            </DialogActions>
        </BasicDialog>
    );
};
