import React from 'react';
import { connect } from 'react-redux';

import { SettingsSectionWrapper } from '../../../../SettingsControls';

import { URLSettingsSection } from "./URLSettingsSection";
import { URLPreviewSection } from "./URLPreviewSection";

import imageStyles from "../../../../Images/imageStyle.css";

import { publishConfigDataSelector } from "../../../../../common/selectors";
import { useIntl } from '../../../../../../../../wbtgen/src/view/intl';

import type { AppState } from '../../../../../common/types';
import type { URLSettingsProps } from "../types";

import { changeDropHtmlExtensionAction } from "../actions";

export const URLSettingsComponent = (props: URLSettingsProps) => {
    const { dispatch, publishConfig } = props;
    const intl = useIntl();

    const clickHandler = (value) => {
        dispatch(changeDropHtmlExtensionAction(value));
    };

    return (
        <SettingsSectionWrapper
            info={
                <URLSettingsSection
                    value={publishConfig.dropHtmlExtension}
                    iconClassname={imageStyles.url}
                    label={intl.msgJoint('msg: securityTab.url.title {URL}')}
                    description={intl.msgJoint('msg: securityTab.beautifulUrl.description {Google ranks secure & easy-to-read addresses more favourably, so we recommend that you keep these settings enabled!}')}
                    form={{
                        label: intl.msgJoint('msg: securityTab.beautifulUrl {Beautify my URLs}')
                    }}
                    clickHandler={clickHandler}
                />
            }
            form={
                <URLPreviewSection intl={intl} publishConfig={publishConfig} />
            }
        />
    );
};

const mapStateToProps = (state: AppState) => ({
    publishConfig: publishConfigDataSelector(state),
});

export const URLSettings = connect(mapStateToProps)(URLSettingsComponent);
