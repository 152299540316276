/* eslint-disable max-len */

import * as R from 'ramda';
import {
    makeCombineReducer,
    makeComponentBaseReducers,
    makeDefaultStateReducers,
    makePayloadToSetterReducers
} from "../../../../redux/makeReducer/index";
import {
    TOGGLE_MOBILE_DOWN_ACTION
} from "../../../PropertiesPanel/view/MobileView/actions";
import mobileDownReducer from '../../../PropertiesPanel/view/MobileView/mobileDownReducer';
import kind from '../kind';
import {
    DefaultAlignment,
    DefaultHeight,
    DefaultStyle,
    DefaultVerbToDisplay,
    DefaultWidth
} from '../constants';
import { evalComponent } from '../utils';
import * as actionTypes from '../actionTypes';

export default makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(kind, DefaultWidth, DefaultHeight),
        ...makeDefaultStateReducers({
            align: DefaultAlignment,
            showFacebook: false,
            showFacebookShare: true,
            showFriends: true,
            showLinkedIn: true,
            showTwitter: true,
            stretch: false,
            styleInfo: DefaultStyle,
            verbToDisplay: DefaultVerbToDisplay,
            mobileHide: false,
            mobileDown: false
        })
    },
    handleActions: {
        [TOGGLE_MOBILE_DOWN_ACTION]: mobileDownReducer,
        [actionTypes.SHAREBUTTONS_STYLE_CHANGED]: (component, { payload: styleInfo }) => evalComponent('styleInfo', R.always(styleInfo))(component),
        [actionTypes.SHAREBUTTONS_TOGGLE_TWITTER]: evalComponent('showTwitter', R.not),
        [actionTypes.SHAREBUTTONS_TOGGLE_LINKEDIN]: evalComponent('showLinkedIn', R.not),
        [actionTypes.SHAREBUTTONS_TOGGLE_FACEBOOK_SHARE]: evalComponent('showFacebookShare', R.not),
        [actionTypes.SHAREBUTTONS_TOGGLE_FACEBOOK]: evalComponent('showFacebook', R.not),
        [actionTypes.SHAREBUTTONS_TOGGLE_FACEBOOK_SHOW_FRIENDS]: evalComponent('showFriends', R.not),
        ...makePayloadToSetterReducers({
            [actionTypes.SHAREBUTTONS_ALIGNMENT_CHANGED]: 'align',
            [actionTypes.SHAREBUTTONS_VERB_TO_DISPLAY_CHANGED]: 'verbToDisplay'
        })
    }
});
