import localStorage from '../utils/localStorage';
import getRecordId from './getRecordId';

function getExceptionDataById(exceptionId: string) {
    return localStorage.getRequired(getRecordId(exceptionId));
}

export {
    getExceptionDataById
};
