import { takeLatest, takeEvery } from 'redux-saga/effects'; // eslint-disable-line node/file-extension-in-import
import * as object from "../../utils/object";
import { ActionType } from "./makeCondition";

type Effect = 'takeLatest' | 'takeEvery';
type ActionTypeInput = string | ActionType;
type WorkerFunction = (...args: any[]) => Generator<any, any, any>;

const
    takeLatestEffect = 'takeLatest',
    takeEveryEffect = 'takeEvery';

const makeTakeEffectSaga = (
    effectType: Effect,
    actionTypeInput: ActionTypeInput,
    worker: WorkerFunction
): WorkerFunction => function* (...sagaArgs: Array<AnyValue>) {
    const
        effect = effectType === 'takeLatest' ? takeLatest : takeEvery,
        actionType = actionTypeInput instanceof ActionType ? actionTypeInput.toString() : actionTypeInput,
        actionTypeWhenReason = actionTypeInput instanceof ActionType ? actionTypeInput.whenReason : null;

    const pattern = (action: AnyAction): boolean => {
        let match = action.type === actionType;
        if (actionTypeWhenReason) match = match && actionTypeWhenReason === action.epicUpdateReason;
        return match;
    };

    // @ts-ignore
    yield effect(pattern, worker, ...sagaArgs);
};

const makeTakeEffectFromPayloadSaga = (
    effectType: Effect,
    action: ActionTypeInput,
    worker: WorkerFunction
): WorkerFunction => makeTakeEffectSaga(effectType, action, function* () {
    const
        args = object.mapToArray(arguments),
        action: AnyAction = args.pop();
    let sagaArgs = [];
    if (args.length) sagaArgs = args;

    yield* worker(...sagaArgs, action.payload);
});

export {
    makeTakeEffectFromPayloadSaga as default,
    makeTakeEffectSaga,
    takeLatestEffect,
    takeEveryEffect
};
