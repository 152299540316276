
import type { ImageComponent } from "../flowTypes";

export default (component: ImageComponent): ImageComponent => ({
    ...component,
    asset: {
        ...component.asset,
        url: ''
    }
});
