
import * as R from 'ramda';

export const
    IMAGE_RATIO_NUM_MAP = {
        SQUARE: 1,
        LANDSCAPE: 6 / 5,
        PORTRAIT: 5 / 6,
        ACTUAL_RATIO: 1
    },
    IMAGE_MAX_DIMENSION = 16384, // todo check image implementation, move to common place
    IMAGE_SCALE_MAX_PIXELS = 16777216,
    errorColor = '#D64949',
    columnOptions = R.range(1, 7).map(v => ({ value: v, label: v })),
    rowOptions = R.range(1, 6).map(v => ({ value: v, label: v })),
    SHOW_MORE_BTN_HEIGHT = 75,
    SLIDER_MAX_SIZE = 120,
    SLIDER_MAX_SIZE_MVE = 20,
    SHOW_MORE_BTN_FONT_MAX_SIZE = 50,
    LIMIT = {
        showTitleCount: (text: string) => {
            return text.length >= (LIMIT.title.hard - 10);
        },
        showCaptionCount: (text: string) => {
            return text.length >= (LIMIT.caption.hard - 10);
        },
        getTitleBorder: (text: string) => {
            return text.length >= (LIMIT.title.hard - 10) ?  // eslint-disable-line no-nested-ternary
                errorColor : "";
        },
        getCaptionBorder: (text: string) => {
            return text.length >= (LIMIT.caption.hard - 10) ?  // eslint-disable-line no-nested-ternary
                errorColor : "";
        },
        title: {
            hard: 70,
            soft: 60
        },
        caption: {
            threshold: 1000,
            hard: 500,
            soft: 180,
            max: 1500
        }
    },
    MinGalleryDimensions = {
        width: 40,
        height: 40
    },
    MinThumbnailSize = {
        width: 10,
        height: 10
    },
    Padding = {
        MIN: 5,
        MAX: 80,
        DEFAULT_TOP: 9,
        DEFAULT_BOTTOM: 14,
        DEFAULT_LEFT: 20,
        DEFAULT_RIGHT: 20
    },
    MAX_COOKIE_CHECK_COUNT = 120,
    INSTAGRAM_IMAGE = 'IMAGE' as const,
    INSTAGRAM_VIDEO = 'VIDEO',
    INSTAGRAM_CAROUSEL_ALBUM = 'CAROUSEL_ALBUM',
    SHINYBOX_VIDEO_MEDIA_TYPE = 'SELF_HOSTED_VIDEO',
    SHINYBOX_ALBUM_MEDIA_TYPE = 'ALBUM',
    DRAG_AND_DROP_WIDTH = 149,
    DRAG_AND_DROP_HEIGHT = 108;

