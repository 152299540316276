import type { FmGridSortOptionT } from './flowTypes';
import {
    FmGridSortOptionValue,
    SortColumns,
    SortDirections
} from '../../../redux/modules/children/fileChooser/sortFunctions';
import { FmGridSortMessages } from './FileChooserHeader/messages';

export const
    DEFAULT_MULTI_SELECT_VALIDATION_TITLE = 'msg: common.maxNumberOfImageReached {You have reached maximum number of images}', // eslint-disable-line max-len
    DEFAULT_MULTI_SELECT_LIMIT = 100,
    ViewModes = {
        LIST: 'LIST',
        GRID: 'GRID',
        COMBINED: 'COMBINED'
    } as const,
    LIST_SHORT_NAME_LENGTH = 33,
    GRID_SHORT_NAME_LENGTH = 20,
    BREAD_CRUMBS_SHORT_NAME_LENGTH = 15,
    GRID_ROW_SIZE = 5,
    GUTTER = 14,
    FmGridSortOptions: Array<FmGridSortOptionT> = [
        {
            label: FmGridSortMessages[FmGridSortOptionValue.NAME],
            value: FmGridSortOptionValue.NAME,
            column: SortColumns.NAME,
            direction: SortDirections.ASC,
        },
        {
            label: FmGridSortMessages[FmGridSortOptionValue.NEWEST],
            value: FmGridSortOptionValue.NEWEST,
            column: SortColumns.ADDED,
            direction: SortDirections.DESC,
        },
        {
            label: FmGridSortMessages[FmGridSortOptionValue.OLDEST],
            value: FmGridSortOptionValue.OLDEST,
            column: SortColumns.ADDED,
            direction: SortDirections.ASC,
        },
    ],
    FM_UPLOAD_MENU_FROM_COMPUTER_VALUE = 'onFileClick',
    FM_UPLOAD_MENU_FROM_LINK = 'onUrlClick',
    FmUploadMenuOptions = [
        {
            label: 'msg: fc.uploadFile.computer {From computer}',
            value: FM_UPLOAD_MENU_FROM_COMPUTER_VALUE,
        },
        {
            label: 'msg: fc.uploadFile.externalUrl {From external URL}',
            value: FM_UPLOAD_MENU_FROM_LINK,
        },
    ];
