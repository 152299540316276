import React from "react";

import makeStyles from '@mui/styles/makeStyles';
import { Button, DialogContent, Hidden } from "@mui/material";

import type { Theme } from '@mui/material/styles';

import VerticalSpacer from "../../../../../../../wbtgen/src/view/common/VerticalSpacer";
import HorizontalSpacer from "../../../../../../../wbtgen/src/view/common/HorizontalSpacer";

import { BasicDialog } from "../../../Dialog/BasicDialog";
import { closeDialogAction } from "../../../Dialog/actions";

import { facebookSdkLoginAction } from "../../../../../../../wbtgen/src/components/SocialAccounts/Facebook/facebookSdkActions";

import { FB_CHAT_LOGIN } from "../../../../../../../wbtgen/src/components/SocialAccounts/Facebook/constants";

const styles = makeStyles((theme: Theme) => ({
    paper: {
        width: 576,
        [theme.breakpoints.down('md')]: {
            position: "absolute",
            bottom: 0,
            width: "100%",
            maxWidth: "100%",
            margin: 0,
            padding: 0,
        },
    },
    dialogTitle: {
        fontSize: "30px",
        lineHeight: "34px",
    },
    dialogSubTitle: {
        fontSize: "18px",
        lineHeight: "26px",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end",
        [theme.breakpoints.down('md')]: {
            justifyContent: "flex-start",
        }

    },
    button: {
        height: 50,
        padding: "0 30px",
        [theme.breakpoints.down('md')]: {
            width: "50%",
        }
    }
}), { name: "FacebookNoPagesSelectedDialog" });

type Props = {
    intl: Intl,
    dispatch: Dispatch,
    config: any,
};

export const FacebookNoPagesSelectedDialog = (props: Props) => {
    const
        { intl, dispatch, config } = props,
        classes = styles(),
        handleClose = () => {
            dispatch(closeDialogAction());
        };

    return (
        <BasicDialog
            onClose={() => handleClose()}
            classes={{ paper: classes.paper }}
            {...config}
        >
            <DialogContent>
                <div className={classes.dialogTitle}>
                    {intl.msgJoint("msg: component.facebookChat.noPages {No page selected}")}
                </div>
                <Hidden mdDown>
                    <VerticalSpacer y={30} />
                </Hidden>
                {/* @ts-ignore */}
                <Hidden mdUp>
                    <VerticalSpacer y={16} />
                </Hidden>
                <div className={classes.dialogSubTitle}>
                    {intl.msgJoint("msg: component.facebookChat.noPages.message {It looks like you didn\'t select a page when connecting to Facebook. Try again and make sure you choose a Facebook  Business page.}")}
                </div>
                <Hidden mdDown>
                    <VerticalSpacer y={30} />
                </Hidden>
                {/* @ts-ignore */}
                <Hidden mdUp>
                    <VerticalSpacer y={38} />
                </Hidden>
                <div className={classes.buttonContainer}>
                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                            dispatch(closeDialogAction());
                        }}
                    >
                        {intl.msgJoint("msg: common.cancel {Cancel}")}
                    </Button>
                    <Hidden mdDown>
                        <HorizontalSpacer x={20} inline />
                    </Hidden>
                    {/* @ts-ignore */}
                    <Hidden mdUp>
                        <HorizontalSpacer x={10} inline />
                    </Hidden>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                            dispatch(facebookSdkLoginAction(FB_CHAT_LOGIN));
                            dispatch(closeDialogAction());
                        }}
                    >
                        {intl.msgJoint("msg: common.tryAgain {Try again}")}
                    </Button>
                </div>
            </DialogContent>

        </BasicDialog>
    );
};

export const FACEBOOK_NO_PAGES_SELECTED_DIALOG_ID = 'FACEBOOK_NO_PAGES_SELECTED_DIALOG_ID';
