import * as R from 'ramda';
import { getBBox, getHeight, getWidth } from "../../../../utils/bBox";
import { borderThickness } from '../../../../view/Workspace/Decorations/ShiftBarTopDecorations.css';
import { getCenterPoint, isIntersectingCmps } from '../SnapEquisistantFromEdgesOfSection/util';
import type { AnyComponent, ComponentsMap } from "../../../../redux/modules/children/workspace/flowTypes";
import type { BBoxContainer, DistanceLine } from './flowTypes';
import type { BBox } from "../../../App/flowTypes";
import type { Attachments } from "../componentAttachements/flowTypes";
import { getAttachedContainerCmpId, getCmps } from "../componentAttachements/util";
import { isSectionKind } from "../../../oneweb/componentKinds";
import * as interactionModes from '../componentsEval/userInteractionMutations/interactionModes';
import { getComponentsBBox } from '../../../../utils/componentsMap/index';
import { filterKindsForEasierWS, filteringBBoxValues, DIRECTIONS, oppositeDirections } from './constants';
import { isResizingHandle } from '../../../../utils/handle/index';

const labelWidth = 42,
    labelHeight = 25;

const getLineStyleX = (distances, cmp1BBox, cmp2BBox) => {
    const heights = [cmp2BBox, cmp2BBox, cmp1BBox, cmp1BBox];
    return distances.map((distance, index) => ({
        top: getHeight(heights[index]) / 2,
        left: 0,
        width: distance - borderThickness
    }));
};

const getLineStyleY = (distances, cmp1BBox, cmp2BBox) => {
    const widths = [cmp1BBox, cmp1BBox, cmp2BBox, cmp2BBox];
    return distances.map((distance, index) => ({
        top: 0,
        left: getWidth(widths[index]) / 2,
        height: distance - borderThickness
    }));
};

const getLineStyle = (direction, distances, selectedBBox, cmpBBox) => {
    let labelStyles;
    switch (direction) {
        case DIRECTIONS.RIGHT:
            labelStyles = getLineStyleX(distances, cmpBBox, selectedBBox);
            break;
        case DIRECTIONS.LEFT:
            labelStyles = getLineStyleX(distances, selectedBBox, cmpBBox);
            break;
        case DIRECTIONS.TOP:
            labelStyles = getLineStyleY(distances, cmpBBox, selectedBBox);
            break;
        case DIRECTIONS.BOTTOM:
            labelStyles = getLineStyleY(distances, selectedBBox, cmpBBox);
            break;
        default:
            break;
    }
    return labelStyles;
};

const getLabelStyleX = (distances, cmp1BBox, cmp2BBox) => {
    const heights = [cmp2BBox, cmp2BBox, cmp1BBox, cmp1BBox];
    return distances.map((distance, index) => ({
        top: (getHeight(heights[index]) / 2) - (labelHeight / 2),
        left: (distance / 2) - (labelWidth / 2)
    }));
};

const getLabelStyleY = (distances, cmp1BBox, cmp2BBox) => {
    const widths = [cmp1BBox, cmp1BBox, cmp2BBox, cmp2BBox];
    return distances.map((distance, index) => ({
        top: (distance / 2) - (labelHeight / 2),
        left: (getWidth(widths[index]) / 2) - (labelWidth / 2)
    }));
};

const getLabelStyle = (direction, distances, selectedBBox, cmpBBox) => {
    let labelStyles;
    switch (direction) {
        case DIRECTIONS.RIGHT:
            labelStyles = getLabelStyleX(distances, cmpBBox, selectedBBox);
            break;
        case DIRECTIONS.LEFT:
            labelStyles = getLabelStyleX(distances, selectedBBox, cmpBBox);
            break;
        case DIRECTIONS.TOP:
            labelStyles = getLabelStyleY(distances, cmpBBox, selectedBBox);
            break;
        case DIRECTIONS.BOTTOM:
            labelStyles = getLabelStyleY(distances, selectedBBox, cmpBBox);
            break;
        default:
            break;
    }
    return labelStyles;
};

const getSpacingStyleX = (distances, cmp1BBox, cmp2BBox, templateWidth) => {
    const lefts = [0, cmp2BBox.right, templateWidth / 2, cmp1BBox.right],
        tops = [cmp2BBox.top, cmp2BBox.top, cmp1BBox.top, cmp1BBox.top],
        heights = [cmp2BBox, cmp2BBox, cmp1BBox, cmp1BBox];
    return distances.map((distance, index) => ({
        top: tops[index],
        width: distance,
        left: lefts[index],
        height: getHeight(heights[index]),
        opacity: 1
    }));
};

const getSpacingStyleY = (distances, cmp1BBox, cmp2BBox, parentContainer) => {
    const parentCenter = parentContainer.top + (parentContainer.height / 2),
        lefts = [cmp1BBox.left, cmp1BBox.left, cmp2BBox.left, cmp2BBox.left],
        tops = [parentContainer.top, cmp1BBox.bottom, parentCenter, cmp2BBox.bottom],
        widths = [cmp1BBox, cmp1BBox, cmp2BBox, cmp2BBox];
    return distances.map((distance, index) => ({
        top: tops[index],
        left: lefts[index] - 1,
        width: getWidth(widths[index]) + 2,
        height: distance,
        opacity: 1,
    }));
};

const getSpacingStyle = (direction, distances, selectedBBox, cmpBBox, templateWidth, parentContainer) => {
    let spacingStyles;
    switch (direction) {
        case DIRECTIONS.RIGHT:
            spacingStyles = getSpacingStyleX(distances, cmpBBox, selectedBBox, templateWidth);
            break;
        case DIRECTIONS.LEFT:
            spacingStyles = getSpacingStyleX(distances, selectedBBox, cmpBBox, templateWidth);
            break;
        case DIRECTIONS.TOP:
            spacingStyles = getSpacingStyleY(distances, cmpBBox, selectedBBox, parentContainer);
            break;
        case DIRECTIONS.BOTTOM:
            spacingStyles = getSpacingStyleY(distances, selectedBBox, cmpBBox, parentContainer);
            break;
        default:
            break;
    }
    return spacingStyles;
};

const getDistanceX = (cmp1BBox, cmp2BBox, templateWidth) => {
    return [cmp1BBox.left,
        (templateWidth / 2) - cmp1BBox.right,
        cmp2BBox.left - (templateWidth / 2),
        templateWidth - (cmp2BBox.right)];
};

const getDistanceY = (cmp1BBox, cmp2BBox, parentContainer) => {
    const parentCenter = parentContainer.top + (parentContainer.height / 2);
    return [cmp2BBox.top - parentContainer.top,
        parentCenter - cmp2BBox.bottom,
        cmp1BBox.top - parentCenter,
        parentContainer.top + parentContainer.height - cmp1BBox.bottom];
};

const getDistance = (direction, selectedBBox, cmpBBox, templateWidth, parentContainer) => {
    let distances: Array<any> = [];
    switch (direction) {
        case DIRECTIONS.RIGHT:
            distances = getDistanceX(selectedBBox, cmpBBox, templateWidth);
            break;
        case DIRECTIONS.LEFT:
            distances = getDistanceX(cmpBBox, selectedBBox, templateWidth);
            break;
        case DIRECTIONS.TOP:
            distances = getDistanceY(selectedBBox, cmpBBox, parentContainer);
            break;
        case DIRECTIONS.BOTTOM:
            distances = getDistanceY(cmpBBox, selectedBBox, parentContainer);
            break;
        default:
            break;
    }
    return distances.map(d => Math.round(d));
};

const getIsEqualDistance = distances => {
    return distances.map((distance, index) => {
        return distance === distances[distances.length - index - 1];
    });
};

const showAllLines = [false, false, false, false],
    hideEdgeLines = [true, false, false, true],
    hideMiddleLines = [false, true, true, false];

const getIsHideDistanceLines = (distances, direction, userInteractionMode, handleKind) => {
    if (userInteractionMode === interactionModes.MOVING_COMPONENTS ||
        userInteractionMode === interactionModes.ADDING_COMPONENT) {
        return distances.map((distance, index) => (distance !== distances[distances.length - index - 1]));
    }
    if (!handleKind || !filteringBBoxValues[handleKind]) {
        return showAllLines;
    }
    if (filteringBBoxValues[handleKind].includes(direction)) {
        return hideEdgeLines;
    }
    return hideMiddleLines;
};

const makeDistancesLines = (limitingEntities: BBoxContainer<string>, selectedBBox: BBox, workspaceBBox: BBox,
    userInteractionMode: string, templateWidth: number, parentContainer: AnyComponent, handleKind?: string): DistanceLine => {
    const distanceLines: DistanceLine = {} as DistanceLine;
    R.forEachObjIndexed((cmp, direction) => {
        const cmpBBox = getBBox(cmp),
            distances = getDistance(direction, selectedBBox, cmpBBox, templateWidth, parentContainer),
            spacingStyles = getSpacingStyle(direction, distances, selectedBBox, cmpBBox, templateWidth, parentContainer),
            labelStyles = getLabelStyle(direction, distances, selectedBBox, cmpBBox),
            lineStyles = getLineStyle(direction, distances, selectedBBox, cmpBBox),
            isEqualDistances = getIsEqualDistance(distances),
            isHideDistanceLines = getIsHideDistanceLines(distances, direction, userInteractionMode, handleKind);
        distanceLines[direction] = {
            distances,
            spacingStyles,
            labelStyles,
            lineStyles,
            isEqualDistances,
            isHideDistanceLines,
        };
    }, limitingEntities);
    return distanceLines;
};

const getCmpLocationsInSection = (selectedCmp: AnyComponent, parentContainer: AnyComponent,
    templateWidth: number): Array<string> => {
    const centerX = templateWidth / 2,
        centerY = parentContainer.top + (parentContainer.height / 2),
        { left, right, top, bottom } = getBBox(selectedCmp),
        locations: Array<string> = [];
    if (left < centerX && right < centerX) {
        locations.push(DIRECTIONS.LEFT);
    }
    if (left > centerX && right > centerX) {
        locations.push(DIRECTIONS.RIGHT);
    }
    if (top < centerY && bottom < centerY) {
        locations.push(DIRECTIONS.TOP);
    }
    if (top > centerY && bottom > centerY) {
        locations.push(DIRECTIONS.BOTTOM);
    }
    return locations;
};

const getClosestCmpToSnapTo = (direction, selectedCmp, cmps, parentContainer, templateWidth) => {
    if (!cmps.length) {
        return null;
    }
    if (cmps.length === 1) {
        return cmps[0];
    }
    let minDiff = Infinity, cmpToSnapTo;
    const selectedBBox = getBBox(selectedCmp);
    cmps.forEach(cmp => {
        const cmpBBox = getBBox(cmp);
        const distances = getDistance(direction, selectedBBox, cmpBBox, templateWidth, parentContainer);
        const diffInEdgeDistances = Math.abs(distances[0] - distances[3]);
        if (diffInEdgeDistances < minDiff) {
            minDiff = diffInEdgeDistances;
            cmpToSnapTo = cmp;
        }
        const diffInCenterDistances = Math.abs(distances[1] - distances[2]);
        if (diffInCenterDistances < minDiff) {
            minDiff = diffInCenterDistances;
            cmpToSnapTo = cmp;
        }
    });
    return cmpToSnapTo;
};

const getIntersectingCmpsinSection = (selectedCmp, cmps, children, templateWidth) => {
    return cmps
        .filter(cmp => cmp.id !== selectedCmp.id)
        .filter(cmp => (cmp.left >= 0 && cmp.left <= templateWidth))
        .filter(cmp => children.includes(cmp.id))
        .filter(cmp => isIntersectingCmps(selectedCmp, cmp));
};

const getCmpToSnapTo = (direction: string, selectedCmp: AnyComponent, cmps: Array<AnyComponent>,
    parentContainer: AnyComponent, children: Array<string>, templateWidth: number): AnyComponent => {
    let cmpsToSnap = getIntersectingCmpsinSection(selectedCmp, cmps, children, templateWidth);
    const sectionBBox = getBBox(parentContainer);
    const sectionCenterY = getCenterPoint({ start: sectionBBox.top, end: sectionBBox.bottom });
    const sectionMiddleX = templateWidth / 2;
    switch (direction) {
        case DIRECTIONS.RIGHT:
            cmpsToSnap = cmpsToSnap.filter(({ left }) => left > sectionMiddleX);
            break;
        case DIRECTIONS.LEFT:
            cmpsToSnap = cmpsToSnap.filter(({ left, width }) => ((left + width) < sectionMiddleX));
            break;
        case DIRECTIONS.TOP:
            cmpsToSnap = cmpsToSnap.filter(({ top, height }) => ((top + height) < sectionCenterY));
            break;
        case DIRECTIONS.BOTTOM:
            cmpsToSnap = cmpsToSnap.filter(({ top }) => top > sectionCenterY);
            break;
        default:
            break;
    }
    return getClosestCmpToSnapTo(direction, selectedCmp, cmpsToSnap, parentContainer, templateWidth);
};

const getCmpsToSnapToWithDirections = (selectedCmp: AnyComponent, componentsMap: ComponentsMap,
    parentContainer: AnyComponent, attachments: Attachments, parentId: string | null,
    templateWidth: number, handleKind?: string) => {
    const cmpLocations = getCmpLocationsInSection(selectedCmp, parentContainer, templateWidth),
        children = parentId ? (attachments[parentId] || []) : [],
        cmps = getCmps(componentsMap);
    let limitingEntities = {};
    cmpLocations.forEach(cmpLocation => {
        const direction = oppositeDirections[cmpLocation],
            filterKinds = handleKind && filterKindsForEasierWS[handleKind];
        if (filterKinds && !filterKinds.includes(direction)) {
            return;
        }
        const cmpToSnapTo = getCmpToSnapTo(direction, selectedCmp, cmps, parentContainer, children, templateWidth);
        if (cmpToSnapTo) {
            limitingEntities[direction] = cmpToSnapTo;
        }
    });
    return limitingEntities;
};

const IsXYEqualForSameSize = (selectedCmp, OtherCmp, direction, limitingEntities) => {
    if (selectedCmp.id === OtherCmp.id) {
        if (direction === DIRECTIONS.LEFT || direction === DIRECTIONS.RIGHT) {
            return !!(limitingEntities[DIRECTIONS.TOP] || limitingEntities[DIRECTIONS.BOTTOM]);
        }
        return !!(limitingEntities[DIRECTIONS.LEFT] || limitingEntities[DIRECTIONS.RIGHT]);
    }
    let cmps: Array<any> = [];
    if (direction === DIRECTIONS.LEFT || direction === DIRECTIONS.RIGHT) {
        cmps = [...(limitingEntities[DIRECTIONS.TOP] || []), ...(limitingEntities[DIRECTIONS.BOTTOM] || [])];
    } else {
        cmps = [...(limitingEntities[DIRECTIONS.LEFT] || []), ...(limitingEntities[DIRECTIONS.RIGHT] || [])];
    }
    return cmps.some(cmp => cmp.id === OtherCmp.id);
};

const getDistanceLinesForSameSize = (direction, selectedCmp, cmps, limitingEntities) => {
    const distances: Array<number> = [],
        spacingStyles: Array<Record<string, any>> = [],
        labelStyles: Array<Record<string, any>> = [],
        lineStyles: Array<Record<string, any>> = [],
        isEqualDistances: Array<boolean> = [],
        isHideDistanceLines: Array<boolean> = [];
    [selectedCmp, ...cmps].forEach(cmp => {
        const isXYEqual = IsXYEqualForSameSize(selectedCmp, cmp, direction, limitingEntities);
        if (direction === DIRECTIONS.LEFT || direction === DIRECTIONS.RIGHT) {
            const distance = Math.round(cmp.width);
            distances.push(distance);
            spacingStyles.push({
                top: cmp.top,
                width: distance,
                left: cmp.left,
                height: cmp.height,
                opacity: 1
            });
            labelStyles.push({
                top: (cmp.height / 2) - (labelHeight / 2),
                left: Math.max((distances[0] / 2) - (labelWidth / 2) - (isXYEqual ? labelWidth : 0), 0)
            });
            lineStyles.push({
                top: (cmp.height / 2),
                left: 0,
                width: distances[0] - borderThickness
            });
        } else {
            const distance = Math.round(cmp.height);
            distances.push(distance);
            spacingStyles.push({
                top: cmp.top,
                left: cmp.left - 1,
                width: cmp.width + 2,
                height: distance,
                opacity: 1,
            });
            labelStyles.push({
                top: Math.max((distance / 2) - (labelHeight / 2) - (isXYEqual ? labelHeight : 0), 0),
                left: (cmp.width / 2) - (labelWidth / 2)
            });
            lineStyles.push({
                top: 0,
                left: cmp.width / 2,
                height: distance - borderThickness
            });
        }
        isEqualDistances.push(true);
        isHideDistanceLines.push(false);
    });
    return {
        distances,
        spacingStyles,
        labelStyles,
        lineStyles,
        isEqualDistances,
        isHideDistanceLines,
    };
};

const makeDistancesLinesForSameSize = (limitingEntities: BBoxContainer<string>, selectedCmp: AnyComponent): DistanceLine => {
    const distanceLines: DistanceLine = {} as DistanceLine;
    R.forEachObjIndexed((cmps, direction) => {
        distanceLines[direction] = getDistanceLinesForSameSize(direction, selectedCmp, cmps, limitingEntities);
    }, limitingEntities);
    return distanceLines;
};

const getCmpsToSnapToForSameSize = (direction: string, selectedCmp: AnyComponent, cmps: Array<AnyComponent>,
    parentContainer: AnyComponent, children: Array<string>, templateWidth: number): Array<AnyComponent> => {
    let cmpsToSnap = getIntersectingCmpsinSection(selectedCmp, cmps, children, templateWidth);
    switch (direction) {
        case DIRECTIONS.RIGHT:
            cmpsToSnap = cmpsToSnap.filter(cmp => cmp.width === selectedCmp.width);
            cmpsToSnap = cmpsToSnap.filter(({ left }) => left >= (selectedCmp.left + selectedCmp.width));
            break;
        case DIRECTIONS.LEFT:
            cmpsToSnap = cmpsToSnap.filter(cmp => cmp.width === selectedCmp.width);
            cmpsToSnap = cmpsToSnap.filter(({ left, width }) => ((left + width) <= selectedCmp.left));
            break;
        case DIRECTIONS.TOP:
            cmpsToSnap = cmpsToSnap.filter(cmp => cmp.height === selectedCmp.height);
            cmpsToSnap = cmpsToSnap.filter(({ top, height }) => ((top + height) < selectedCmp.top));
            break;
        case DIRECTIONS.BOTTOM:
            cmpsToSnap = cmpsToSnap.filter(cmp => cmp.height === selectedCmp.height);
            cmpsToSnap = cmpsToSnap.filter(({ top }) => top > (selectedCmp.top + selectedCmp.height));
            break;
        default:
            break;
    }
    return cmpsToSnap;
};

const getCmpsToSnapToWithDirectionsForSameSize = (selectedCmp: AnyComponent, componentsMap: ComponentsMap,
    parentContainer: AnyComponent, attachments: Attachments, parentId: string | null,
    templateWidth: number, handleKind: string) => {
    const children = parentId ? (attachments[parentId] || []) : [],
        cmps = getCmps(componentsMap);
    const limitingEntities = {};
    filterKindsForEasierWS[handleKind].forEach(direction => {
        const cmpsToSnapTo = getCmpsToSnapToForSameSize(direction, selectedCmp, cmps, parentContainer, children, templateWidth);
        if (cmpsToSnapTo && cmpsToSnapTo.length) {
            limitingEntities[direction] = cmpsToSnapTo;
        }
    });
    return limitingEntities;
};

const easierWSUserInteractions = [interactionModes.MOVING_COMPONENTS_BY_ARROW_KEYS, interactionModes.MOVING_COMPONENTS,
    interactionModes.RESIZE_HANDLE_MOVING, interactionModes.MOUSE_DOWN_ON_HANDLE];

const shouldEasierWSAndSameSizeLinesBeShown = (userInteractionMode: string, userInteractionComponentsIds: Array<string>,
    componentsMap: ComponentsMap, attachments: Attachments, parentContainerId: string | null, workspaceBBox: BBox,
    templateWidth: number) => {
    if (userInteractionComponentsIds.length !== 1) {
        return false;
    }
    const selectedCmpId = userInteractionComponentsIds[0],
        selectedCmp = componentsMap[selectedCmpId],
        selectionBBox = getComponentsBBox([selectedCmp], workspaceBBox);
    if (selectionBBox.left < 0 || selectionBBox.right > templateWidth) {
        return false;
    }
    const parentId = parentContainerId || getAttachedContainerCmpId(selectedCmpId, attachments),
        parentContainer = parentId && componentsMap[parentId];
    if (!parentContainer || !isSectionKind(parentContainer.kind)) {
        return false;
    }
    return true;
};

const shouldSameSizeLinesBeShown = (userInteractionMode: string, userInteractionComponentsIds: Array<string>, componentsMap: ComponentsMap,
    attachments: Attachments, parentContainerId: string | null, workspaceBBox: BBox, templateWidth: number) => {
    if (userInteractionMode !== interactionModes.RESIZE_HANDLE_MOVING) {
        return false;
    }
    return shouldEasierWSAndSameSizeLinesBeShown(userInteractionMode, userInteractionComponentsIds, componentsMap,
        attachments, parentContainerId, workspaceBBox, templateWidth);
};

const shouldEasierWSLinesBeShown = (userInteractionMode: string, userInteractionComponentsIds: Array<string>, componentsMap: ComponentsMap,
    attachments: Attachments, parentContainerId: string | null, workspaceBBox: BBox, templateWidth: number, handleKind?: string) => {
    if (userInteractionMode === interactionModes.MOUSE_DOWN_ON_HANDLE
        && handleKind && !isResizingHandle(handleKind)) {
        return false;
    }
    if (!easierWSUserInteractions.includes(userInteractionMode)) {
        return false;
    }
    if (!shouldEasierWSAndSameSizeLinesBeShown(userInteractionMode, userInteractionComponentsIds, componentsMap,
        attachments, parentContainerId, workspaceBBox, templateWidth)) {
        return false;
    }
    const selectedCmpId = userInteractionComponentsIds[0],
        selectedCmp = componentsMap[selectedCmpId],
        parentId = parentContainerId || getAttachedContainerCmpId(selectedCmpId, attachments),
        parentContainer = parentId && componentsMap[parentId];
    const limitingEntities = getCmpsToSnapToWithDirections(selectedCmp, componentsMap,
        parentContainer, attachments, parentId, templateWidth, handleKind);
    if (Object.keys(limitingEntities).length) {
        return true;
    }
    return false;
};

export {
    makeDistancesLines,
    makeDistancesLinesForSameSize,
    getCmpsToSnapToWithDirections,
    getCmpsToSnapToWithDirectionsForSameSize,
    shouldEasierWSLinesBeShown,
    shouldSameSizeLinesBeShown,
};
