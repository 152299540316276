import React from "react";
import cx from "classnames";
import { Flex } from "../../../../view/reflexbox/index";
import Icons from '../../../../view/Icons/index';
import { injectIntl, Intl } from '../../../../view/intl/index';
import Button from "../../Button/index";
import buttonStyles from '../../../buttons.css';
import styles from '../IconButtonsGroup.css';
import type { HorizontalAlignment } from "../../../Workspace/epics/stylesheets/flowTypes";

type Props = {
    value: HorizontalAlignment;
    onBtnClick: (value: HorizontalAlignment) => void;
    disabled?: boolean;
    isPropertiesPanel?: boolean;
    intl: Intl;
}

const
    textHorizontalAlignmentButtons = { // todo WBTGEN-2272 IconButtonsGroup/TextAlignmentButtonGroup.js titles should use Intl
        left: { title: 'msg: common.left {Left}', icon: Icons.TEXT_ALIGN_LEFT },
        center: { title: 'msg: common.center {Center}', icon: Icons.TEXT_ALIGN_CENTER },
        right: { title: 'msg: common.right {Right}', icon: Icons.TEXT_ALIGN_RIGHT },
        justify: { title: 'msg: common.justify {Justify}', icon: Icons.TEXT_ALIGN_FULL }
    },
    factory = values => injectIntl(({ value, onBtnClick, disabled, isPropertiesPanel, intl }: Props) => (
        <Flex align="center" justify="space-between">
            {
                values
                    .map(key => ({ value: key, ...textHorizontalAlignmentButtons[key] }))
                    .map((item, index) => (
                        <Button
                            key={index}
                            className={cx(styles.iconButton, buttonStyles.buttonGroup, {
                                [styles.selected]: item.value === value,
                                [styles.iconButtonPropertiesPanel]: isPropertiesPanel
                            })}
                            isSelected={item.value === value}
                            title={intl.msgJoint(item.title)}
                            onClick={disabled ? undefined : () => onBtnClick(item.value)}
                            disabled={disabled}
                        >
                            <item.icon />
                        </Button>
                    ))
            }
        </Flex>
    )),
    TextHorizontalAlignmentButtonsGroup = factory(['left', 'center', 'right']),
    TextFullHorizontalAlignmentButtonsGroup = factory(['left', 'center', 'right', 'justify']);

export {
    TextHorizontalAlignmentButtonsGroup,
    TextFullHorizontalAlignmentButtonsGroup
};
