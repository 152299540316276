import React from 'react';
import * as stripPageIds from '../pageIds';
import * as bgPageIds from "../../../Background/propertiesPanel/pageIds";
import pageTitles from '../pageTitles';
import Page from '../../../../PropertiesPanel/view/PropertiesPage';
import NavigationGroup from '../../../../PropertiesPanel/view/IntlNavigationGroup';
import type { StripComponent } from "../../flowTypes";
import ScrollTitleInPPNavigation from "../../../commonView/ScrollEffects/ScrollTitleInPPNavigation";
import { SCROLL_EFFECTS } from "../../../commonView/ScrollEffects/constants";
import { isStripKind } from "../../../componentKinds";
import { isModernLayoutSection } from "../../../../ModernLayouts/preview_utils";
import { WebShopMHFEpicState } from '../../../WebShopMHF/flowTypes';

type PropTypes = {
    selectedComponent: StripComponent,
    navigateToPage: (pageId: string) => void,
    selectedComponentIsFooter: boolean,
    webshopMHFData: WebShopMHFEpicState
};

const
    id = stripPageIds.MAIN,
    title = pageTitles[id],
    view = ({ selectedComponent, navigateToPage, selectedComponentIsFooter, webshopMHFData }: PropTypes) => {
        const targetPagesIds = [
                bgPageIds.GRADIENT_SETTINGS,
                bgPageIds.IMAGE_PATTERN,
            ],
            { kind } = selectedComponent,
            showSpecialFooterStrip = webshopMHFData && webshopMHFData.showSpecialFooterStrip;
        if (!isModernLayoutSection(selectedComponent)) {
            targetPagesIds.push(bgPageIds.BORDER, SCROLL_EFFECTS);
        }
        if (!isStripKind(kind)) {
            targetPagesIds.push(stripPageIds.TITLE);
        }
        if (selectedComponentIsFooter && showSpecialFooterStrip) {
            targetPagesIds.push(stripPageIds.SHOP_FOOTER);
        }
        return (<Page>
            <NavigationGroup
                navigateToPage={navigateToPage}
                pagesTitles={pageTitles}
                pageTitleEltsInNavigation={ScrollTitleInPPNavigation}
                targetPagesIds={targetPagesIds}
            />
        </Page>);
    };

export default { id, title, view };
