// TODO: this normally should be DRYed out with wbtgen/src/services/fetch.js

import { makeUri } from '@libj/make-uri';
import { FetchError } from "./FetchError";
import type { FetchResource, FetchOptions } from "./types";
import { isArr, isObj } from "../../isType";

export const fetchService = (resource: FetchResource, options: FetchOptions = {})/*: Promise<Response> */ => {
    const { resource: finalResource, options: finalOptions } = prepareArgs(resource, options);

    return window
        .fetch(finalResource, finalOptions)
        .then(res => {
            // Fetch body

            const bodyPromise = isJsonRes(res)
                ? res.json()
                : Promise.resolve('');

            return bodyPromise.then(body => {
                // @ts-ignore
                res.bodyData = body;
                return res;
            });
        })
        .then(res => {
            // Throw any non-success response as error

            if (!res.ok) {
                // @ts-ignore
                throw FetchError.fromResponse(res);
            }

            return res;
        });
};

/*** Lib ***/

function prepareArgs(inResource, inOptions) {
    let resource = inResource, options = { ...inOptions };

    if (options.body && (isObj(options.body) || isArr(options.body))) {
        options.headers = { ...options.headers, 'Content-Type': 'application/json' }; // eslint-disable-line no-param-reassign
        options.body = JSON.stringify(options.body); // eslint-disable-line no-param-reassign
    }

    if (options.query) {
        resource = makeUri(resource, { query: options.query });
        delete options.query;
    }

    return { resource, options };
}

function isJsonRes(res) {
    return (
        res.headers.has('content-type')
        && res.headers.get('content-type').indexOf('application/json') > -1
    );
}
