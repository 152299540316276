import React from 'react';
import styles from '../LinkChooserDialog.css';
import ExternalUrl from '../../../../ExternalUrl';
import { LC_URL_CORRECT_SCHEMA, LC_URL_SET } from "../../actionTypes";
import type { UrlTabPropTypes } from "../../flowTypes";

const HTTP_REGEX = new RegExp(/http:\/\/|https:\/\//, 'i');

export default ({ selection, onSave, dispatch }: UrlTabPropTypes) => {
    const
        onChange = (value, isValid) => {
            dispatch({ type: LC_URL_SET, payload: { url: value, isValid } });
        },
        onBlur = value => {
            if (value && value.replace(HTTP_REGEX, '')) {
                dispatch({ type: LC_URL_CORRECT_SCHEMA });
            }
        },
        onKeyDown = (key, value) => {
            if (key === "Enter" && value && value.replace(HTTP_REGEX, '')) {
                dispatch({ type: LC_URL_CORRECT_SCHEMA });
            }
        },
        onFocus = ({ e }) => {
            const { target } = e;
            setTimeout(() => {
                if (target) {
                    target.focus();
                }
            }, 1);
        };

    return (
        <ExternalUrl
            url={selection}
            onChange={onChange}
            onEnter={onSave}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            className={styles.pageTabUrl}
            inputClassName={styles.pageTabUrlInput}
            allowJsProtocol
        />
    );
};
