import { getBorderWidth, getBorderStyle } from "../../../view/oneweb/commonComponentSelectors";
import { BorderStyles } from "../../presentational/BorderStyle/constants";
import {
    MIN_DIMENSIONS
} from "../../Workspace/epics/componentsEval/userInteractionMutations/constants";
import { BackgroundCmpMinDimensions } from './constants';

const getMinimumDimensions = (cmpWidth, cmpHeight, borderWidths) => {
    const
        minWidthForBorders = borderWidths ? borderWidths[1] + borderWidths[3] : 0,
        minHeightForBorders = borderWidths ? borderWidths[0] + borderWidths[2] : 0,

        minWidth = borderWidths
            ? Math.max(minWidthForBorders, BackgroundCmpMinDimensions.width) : BackgroundCmpMinDimensions.width,
        minHeight = borderWidths
            ? Math.max(minHeightForBorders, BackgroundCmpMinDimensions.height) : BackgroundCmpMinDimensions.height,

        //with borders it should not allow to reduce the dimensions beyond the border widths on either side
        //else it can allow up to MIN_DIMENSIONS(which defines the lowest possible dimension across the workspace)
        lowestPossibleWidth = borderWidths ? minWidthForBorders : MIN_DIMENSIONS.width,
        lowestPossibleHeight = borderWidths ? minHeightForBorders : MIN_DIMENSIONS.height;

    return { width: cmpHeight < minHeight ? minWidth : lowestPossibleWidth,
        height: cmpWidth < minWidth ? minHeight : lowestPossibleHeight };
};

const hook = ({ component, componentExtension }) => {
    const
        borderStyle = getBorderStyle(component),
        minDimensions = getMinimumDimensions(component.width, component.height,
            borderStyle && BorderStyles.NONE !== borderStyle ? getBorderWidth(component) : null);

    if (component.width < minDimensions.width || component.height < minDimensions.height) {
        const
            nextWidth = Math.max(component.width, minDimensions.width),
            nextHeight = Math.max(component.height, minDimensions.height);

        return [
            { ...component, width: nextWidth, height: nextHeight },
            { ...componentExtension, minDimensions }
        ];
    }

    return [component, { ...componentExtension, minDimensions }];
};

export const adjustmentHookConfig = {
    hook,
    shouldCallHook: () => true
};
