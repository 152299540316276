import CALL_API from '../../../../redux/middleware/api/CALL_API';
import {
    FEATURED_PRODUCTS_FETCH_PAGE_IDS_REQUEST,
    FEATURED_PRODUCTS_FETCH_PAGE_IDS_SUCCESS,
    FEATURED_PRODUCTS_FETCH_PAGE_IDS_FAILURE
} from './actionTypes';
import type { ApiAction } from '../../../../redux/middleware/api/flowTypes';

export default (): ApiAction => ({
    [CALL_API]: {
        types: [
            FEATURED_PRODUCTS_FETCH_PAGE_IDS_REQUEST,
            FEATURED_PRODUCTS_FETCH_PAGE_IDS_SUCCESS,
            FEATURED_PRODUCTS_FETCH_PAGE_IDS_FAILURE
        ],
        endpoint: 'getFeaturedProductsPageIds'
    }
});
