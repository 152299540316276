import type { DataSiteItem } from "../../dal/model/utils/dataSiteItemUtils";

export const
    LinkPage = 'web.data.links.LinkPage',
    LinkExternal = 'web.data.links.LinkExternal',
    LinkSection = 'web.data.links.LinkSection',

    isLinkPage = (page: DataSiteItem): boolean => page.type === LinkPage,
    isLinkExternal = (page: DataSiteItem): boolean => page.type === LinkExternal,
    isLinkSection = (page: DataSiteItem): boolean => page.type === LinkExternal;
